const ALL_SCHOOL = [{ key:"1", district:"38", Block:"264", label:"A.A.G.M HIGH SCHOOL, SANGARAPANDIAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"2", district:"38", Block:"264", label:"SRI RAO BAHADUR A.K.D.DHARMARAJA BOYS HR SEC SCHOOL, RAJAPALAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"3", district:"38", Block:"264", label:"A.K.D.D.MID SCH, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4", district:"38", Block:"264", label:"ANANDA VIDYALAYA MATRICULATON HSS, RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5", district:"38", Block:"264", label:"CASA DI MIR MATRICULATON HSS, RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6", district:"38", Block:"264", label:"CHINMAYA VID P.A.C.R MAT HSS, RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7", district:"38", Block:"264", label:"GANDHI MIDDLE SCHOOL, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8", district:"38", Block:"264", label:"GHSS (G), CHATRAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9", district:"38", Block:"264", label:"GOVERNMENT HIGH SCHOOL, AYANKOLLANKONDAN", Sch_type:"Government", Category:"High School"},
    { key:"10", district:"38", Block:"264", label:"GOVERNMENT HIGH SCHOOL, MOUNT ZION", Sch_type:"Government", Category:"High School"},
    { key:"11", district:"38", Block:"264", label:"GHSS (B), CHATRAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12", district:"38", Block:"264", label:"GHSS, CHOKKANATHANPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13", district:"38", Block:"264", label:"GHSS, KILAVIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14", district:"38", Block:"264", label:"GHSS, NALLAMANAICKERPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15", district:"38", Block:"264", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SIVALINGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16", district:"38", Block:"264", label:"GOVERNMENT HIGH SCHOOL, THIRUVALLUVAR NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"17", district:"38", Block:"264", label:"GOVT A.D.W HR SEC SCHOOL, SUNDARARAJAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18", district:"38", Block:"264", label:"GOVT HR SEC SCHOOL, SEITHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19", district:"38", Block:"264", label:"HARISH MATRIC HSS, SANGAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20", district:"38", Block:"264", label:"K.H.N.U.P.KAMARAJ. HR SEC SCHOOL, KRISHNAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"21", district:"38", Block:"264", label:"K.P.A. MID SCHOOL, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"22", district:"38", Block:"264", label:"LIVING HOPE MATRIC SCHOOL, MOUNT ZION", Sch_type:"Un-aided", Category:"High School"},
    { key:"23", district:"38", Block:"264", label:"MAHARISHI VID MAT HSS,RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"24", district:"38", Block:"264", label:"M.M.AVUDAI AMMAL MID SCH, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"25", district:"38", Block:"264", label:"M.N.NADAR HIGH SCH, MELAVARAGUNARAMAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"26", district:"38", Block:"264", label:"MURUGAN MID SCH, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"27", district:"38", Block:"264", label:"N.A ANNAPPARAJA MEMORIAL H S S RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"28", district:"38", Block:"264", label:"N.A PAPPURAJA MEMORIAL MIDDLE SCHOOL, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"29", district:"38", Block:"264", label:"NADAR HR SEC SCHOOL, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"30", district:"38", Block:"264", label:"NADAR HSS, ZAMIN KOLLANKONDAN", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"31", district:"38", Block:"264", label:"NADAR MATRIC HSS, RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"32", district:"38", Block:"264", label:"NATARAJA MID SCH, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"33", district:"38", Block:"264", label:"NAVA BHARATH MATRIC HIGHER SECONDARY SCHOOL, S.RAMALINGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"34", district:"38", Block:"264", label:"P.A.C.R AMMANI AMMAL(G) HR SEC SCHOOL, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"35", district:"38", Block:"264", label:"P.A.C.M HR SEC SCHOOL, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"36", district:"38", Block:"264", label:"PALANIAPPA HSS, CHOLAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"37", district:"38", Block:"264", label:"PERUNTHALAIVAR KAMARAJAR HSS MEENATICHIYAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"38", district:"38", Block:"264", label:"PERUNTHALAIVAR KAMARAJAR MATRICULATON HR SEC SCHOOL, THALAVAIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"39", district:"38", Block:"264", label:"P.P.S.A HINDU MIDDLE SCHOOL, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"40", district:"38", Block:"264", label:"P.U.M.S, AMMAIYAPPAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"41", district:"38", Block:"264", label:"P.U.M.S,ASILAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"42", district:"38", Block:"264", label:"P.U.M.S, DEVADANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"43", district:"38", Block:"264", label:"P.U.M.S, GOPALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"44", district:"38", Block:"264", label:"P.U.M.S PANCHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"45", district:"38", Block:"264", label:"P.U.M.S, SOUTH VENGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"46", district:"38", Block:"264", label:"SRI RAMANA VIDYALAYA MONTESSORI MATRIC HSS, RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"47", district:"38", Block:"264", label:"RC HR SEC SCHOOL, MEENACHIPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"48", district:"38", Block:"264", label:"R.V THEVAR M.G HR SEC SCHOOL, CHOLAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"49", district:"38", Block:"264", label:"SAKKA RAJA KOTTAI MID SCH, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"50", district:"38", Block:"264", label:"SIVANTHI ATHITHANAR HIGH SCHOOL, KOVILUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"51", district:"38", Block:"264", label:"CHINMAYA VIDYALAYA SLR MAT HSS, RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"52", district:"38", Block:"264", label:"SRI KRISHNASAMY MID.S, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"53", district:"38", Block:"264", label:"SRI P.S.KUMARASAMY RAJA MEMORIAL GIRLS HIGH SCHOOL, RAJAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"54", district:"38", Block:"264", label:"SRI RAO BAHADUR A.K.D.DHARMARAJA GIRLS HR.SEC.SCHOOL, RAJAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"55", district:"38", Block:"264", label:"SSG(G)HSS,RAJAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"56", district:"38", Block:"264", label:"SSG(B)HSS, RAJAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"57", district:"38", Block:"264", label:"S.S.H.N.HR.SEC.SCHOOL, MUHAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"58", district:"38", Block:"264", label:"ST ANTONYS MATRIC SCHOOL, CHETTIYARPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"59", district:"38", Block:"264", label:"ST JOSEPH MID SCH, SUNDARANATCHIYARPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"60", district:"38", Block:"264", label:"T.D.T.A.M.S, POTTALPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"61", district:"38", Block:"264", label:"T.N.P.M.M.A.N (G).H.S.S, THALAVAIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"62", district:"38", Block:"264", label:"T.N.P.M.MARIMUTHU NADAR HR.SEC.SCHOOL, THALAVAIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"63", district:"38", Block:"264", label:"VANIGA VYSIAR HIGH SCHOOL, RAJAPALAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"64", district:"38", Block:"264", label:"VIII WARD MUNICIPAL MID SCH, RAJAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"65", district:"38", Block:"298", label:"C.M.S. HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"66", district:"38", Block:"298", label:"GOVT ADW HIGH SCHOOL, IDAYANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"67", district:"38", Block:"298", label:"VPMS SESHA MATRICULATION SCHOOL , SRIVILLIPUTHUR , ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"68", district:"38", Block:"298", label:"GOVERNMENT HIGH SCHOOL, THOMBAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"69", district:"38", Block:"298", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PILLAIYARNATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"70", district:"38", Block:"298", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KRISHNANKOVIL - MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"71", district:"38", Block:"298", label:"GOVT ADW HIGHER SECONDARY SCHOOL, KARISALKULAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"72", district:"38", Block:"298", label:"GOVERNMENT HIGH SCHOOL, PANANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"73", district:"38", Block:"298", label:"GOVERNMENT HIGH SCHOOL, T.MANAGASERI", Sch_type:"Government", Category:"High School"},
    { key:"74", district:"38", Block:"298", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ULLOORPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"75", district:"38", Block:"298", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PAPPAYANAICKERPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"76", district:"38", Block:"298", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KEELARAJAKULARAMAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"77", district:"38", Block:"298", label:"GURUGNANA SAMPANDAR HINDU HSS, SRIVILLIPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"78", district:"38", Block:"298", label:"JOTHI HIGH SCHOOL,R.REDDIAPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"79", district:"38", Block:"298", label:"KAMARAJAR  GOVERNMENT MIDDLE SCHOOL, MALLIPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"80", district:"38", Block:"298", label:"P.N.U.P.KAMARAJAR MATRICULATION HR.SEC.SCHOOL, SRIVILLIPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"81", district:"38", Block:"298", label:"MANGAPURAM HINDU HR SEC SCHOOL, SRIVILLIPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"82", district:"38", Block:"298", label:"M.N.R.D.GIRLS HSS, SRIVILLIPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"83", district:"38", Block:"298", label:"KAMMAPATTI MUNICIPAL MIDDLE SCHOOL, SRIVILLIPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"84", district:"38", Block:"298", label:"MUNICIPAL MIDDLE SCHOOL, KRISHNAN KOVIL STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"85", district:"38", Block:"298", label:"METTU STREET MUNICIPAL MIDDLE SCHOOL, SRIVILLIPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"86", district:"38", Block:"298", label:"MUNICIPAL MIDDLE SCHOOL, PERUMALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"87", district:"38", Block:"298", label:"MUNICIPAL TVK HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"88", district:"38", Block:"298", label:"MUNICIPAL MIDDLE SCHOOL, URANIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"89", district:"38", Block:"298", label:"NK GOPALSAMY MIDDLE SCHOOL NACHIYARPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"90", district:"38", Block:"298", label:"PASUMPON THEVAR HIGHER SECONDARY SCHOOL, MAMSAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"91", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, SAMINATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"92", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, POOVANI", Sch_type:"Government", Category:"Middle School"},
    { key:"93", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, KONGALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"94", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, ATHIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"95", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, AKKARAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"96", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHOKKALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"97", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERUMALDEVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"98", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOTHANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"99", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL INAM KARISALKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"100", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, INDRA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"101", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, K.THOTTIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"102", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, NAGAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"103", district:"38", Block:"298", label:"PANCHAYAT UNION MIDDLE SCHOOL, NARAIYANKULAM OTHAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"104", district:"38", Block:"298", label:"RC.MIDDLE.SCHOOL,PATTAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"105", district:"38", Block:"298", label:"R.K.GOVERNMENT HIGHER SECONDARY SCHOOL, P.RAMACHANDRAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"106", district:"38", Block:"298", label:"SACRED HEART GIRLS HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"107", district:"38", Block:"298", label:"SIVANTHIPATTI NADAR GIRLS HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"108", district:"38", Block:"298", label:"SIVANTHIPATTI NADAR HSS, MAMSAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"109", district:"38", Block:"298", label:"SIVARAM HIGH SCHOOL, MAMSAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"110", district:"38", Block:"298", label:"SIVARAM MIDDLE SCHOOL,MAMSAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"111", district:"38", Block:"298", label:"SRIVI LIONS MATRICULATION HSS, SRIVILLIPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"112", district:"38", Block:"298", label:"ST.JOSEPH RC HR SEC SCHOOL, SRIVILLIPUTHUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"113", district:"38", Block:"298", label:"THIYAGARAJA HIGHER SECONDARY SCHOOL., SRIVILLIPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"114", district:"38", Block:"298", label:"VALLUVAR VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"115", district:"38", Block:"298", label:"VENKADASAMY NAIDU MIDDLE SCHOOL, M.P.K.PUDUPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"116", district:"38", Block:"298", label:"VNUP.KALEESWARI HSS, VANNIAMPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"117", district:"38", Block:"298", label:"V.P.M.M.MATRICULATION HSS, SRIVILLIPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"118", district:"38", Block:"396", label:"ST.ANTONY'S HIGH SCHOOL, MELANMARAINADU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"119", district:"38", Block:"396", label:"BHARAT MIDDILE SCHOOL APPAYANAICKERPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"120", district:"38", Block:"396", label:"GOVERNMENT HIGH SCHOOL, K.CHELLAIYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"121", district:"38", Block:"396", label:"GHS K.MADATHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"122", district:"38", Block:"396", label:"GHS MAMSAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"123", district:"38", Block:"396", label:"GHS,PANAYADIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"124", district:"38", Block:"396", label:"GHSS,ALANGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"125", district:"38", Block:"396", label:"GHS PULLAGOUNDANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"126", district:"38", Block:"396", label:"GHSS,SANKARAPANDIAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"127", district:"38", Block:"396", label:"GHSS,SEVALPATTI - MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"128", district:"38", Block:"396", label:"GHSS,SIPPIPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"129", district:"38", Block:"396", label:"GHS SUBRAMANIYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"130", district:"38", Block:"396", label:"GHSS,VEMBAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"131", district:"38", Block:"396", label:"GHSS,VIJAYAKARISALKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"132", district:"38", Block:"396", label:"GHS THULUKANKURUCHI", Sch_type:"Government", Category:"High School"},
    { key:"133", district:"38", Block:"396", label:"GOVT HSS KALLAMANAICKERPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"134", district:"38", Block:"396", label:"HINDU MARAVAR PS ,T.KOTTAIYUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"135", district:"38", Block:"396", label:"HINDU MIDDLE SCHOOL, ETHIRKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"136", district:"38", Block:"396", label:"KRTA GHSS,MELATHAYILPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"137", district:"38", Block:"396", label:"MAHAMAI MS , GUHANPARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"138", district:"38", Block:"396", label:"NADAR GEORGE HS, THAYILPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"139", district:"38", Block:"396", label:"NADAR MAHAMAI GIRLS HIGH SCHOOL,ELAYIRAMPANNAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"140", district:"38", Block:"396", label:"NADAR MAHAMAI HSS ELAYIRAMPANNAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"141", district:"38", Block:"396", label:"R.P.N.M.HINDU MIDDLE SCHOOL, KAKKIVADANPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"142", district:"38", Block:"396", label:"P.U.M.S ELAYIRAMPANNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"143", district:"38", Block:"396", label:"P.U.M.S KANGARSEVAL", Sch_type:"Government", Category:"Middle School"},
    { key:"144", district:"38", Block:"396", label:"P.U.M.S KOTTAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"145", district:"38", Block:"396", label:"P.U.M.S MANJALODAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"146", district:"38", Block:"396", label:"P U M S M.DURAISAMYPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"147", district:"38", Block:"396", label:"P.U.M.S KOTHAINATCHIYARPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"148", district:"38", Block:"396", label:"P U M S NATHIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"149", district:"38", Block:"396", label:"P U M S PARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"150", district:"38", Block:"396", label:"P.U.M.S PERNAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"151", district:"38", Block:"396", label:"P.U.M.S,RAMUTHEVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"152", district:"38", Block:"396", label:"P.U.M.S SOUTH ANAIKUTTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"153", district:"38", Block:"396", label:"P.U.M.S E.RAMANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"154", district:"38", Block:"396", label:"P.U.M.S ETTAKKAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"155", district:"38", Block:"396", label:"PANCHAYAT UNION MIDDLE SCHOOL, AYAN SALVARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"156", district:"38", Block:"396", label:"TANCEM MHSS ALANGULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"157", district:"24", Block:"180", label:"AMALA ANNAI HIGH SCHOOL, RENGASAMDURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"158", district:"24", Block:"150", label:"A P A C M S PULIYANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"159", district:"24", Block:"150", label:"DR VGN MATRIC HSS THIRUVENKADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"160", district:"24", Block:"150", label:"GNANASUNDARIAMMAL MS SWAMINATHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"161", district:"24", Block:"150", label:"GOVT HIGH SCHOOL ALAMANAICKERPATTI", Sch_type:"Government", Category:"High School"},
    { key:"162", district:"24", Block:"150", label:"GOVERNMENT HIGH SCHOOL, SEVALKULAM", Sch_type:"Government", Category:"High School"},
    { key:"163", district:"24", Block:"150", label:"GOVT HR SEC SCHOOL KALINGAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"164", district:"24", Block:"150", label:"GOPAL NAICKER GOVT HR SEC SCHOOL KURUVIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"165", district:"24", Block:"150", label:"GOVT HR SEC SCHOOL THIRUVENGADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"166", district:"24", Block:"150", label:"GOVT HSS SAYAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"167", district:"24", Block:"150", label:"HINDU MS AYYANERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"168", district:"24", Block:"150", label:"HINDU MIDDLE SCHOOL NARANAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"169", district:"24", Block:"150", label:"HINDU MIDDLE SCHOOL PARAIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"170", district:"24", Block:"150", label:"HINDU MS VADAKKUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"171", district:"24", Block:"150", label:"KALAIVANI MATRIC HIGHER SECONDARY SCHOOL  THIRUVENKADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"172", district:"24", Block:"150", label:"MAIPARAI HIGH SCHOOL MAIPARAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"173", district:"24", Block:"150", label:"MPM SWAMY MS ALAGANERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"174", district:"24", Block:"150", label:"P U MS ARAICHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"175", district:"24", Block:"150", label:"PUMS  AYVAIPULIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"176", district:"24", Block:"150", label:"PANCHAYAT UNION MIDDLE SCHOOL KULASEKARAPERI", Sch_type:"Government", Category:"Middle School"},
    { key:"177", district:"24", Block:"150", label:"PANCHAYAT UNION MIDDLE SCHOOL KURUNJAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"178", district:"24", Block:"150", label:"PUMS MELA NALANDULA", Sch_type:"Government", Category:"Middle School"},
    { key:"179", district:"24", Block:"150", label:"PUMS PALANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"180", district:"24", Block:"150", label:"PUMS UMAIYATHALAIVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"181", district:"24", Block:"150", label:"P U MS VARAGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"182", district:"24", Block:"150", label:"RC MS CHIDAMBARAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"183", district:"24", Block:"150", label:"R C M S IILAYARASANANDAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"184", district:"24", Block:"150", label:"THIRU SHANMUGIAH MS AVANIKONENTHAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"185", district:"24", Block:"150", label:"SRI SIVARAMASUBBUSWAMY MIDDLE SCHOOL VELLAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"186", district:"24", Block:"150", label:"ST.PAUL'S MATRICULATION SCHOOL,SEVALKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"187", district:"24", Block:"150", label:"TDTA MS SAYAMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"188", district:"24", Block:"150", label:"TDTA V.M.MIDDLE SCHOOL, VAGAIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"189", district:"24", Block:"180", label:"VEERAPPA HINDU MIDDLE SCHOOL,MALAYANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"190", district:"24", Block:"276", label:"36 GST HSS, SANKARANKOVIL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"191", district:"24", Block:"276", label:"AMC Govt  Hr. Sec. School, Karivalamvanthanallur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"192", district:"24", Block:"276", label:"ANGEL HS, SANKARANKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"193", district:"24", Block:"276", label:"ANGEL MATRICULATION SCHOOL SANKARANKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"194", district:"24", Block:"276", label:"ANNAMALAI MS, SENNIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"195", district:"24", Block:"276", label:"AV HSS , SANKARANKOVIL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"196", district:"24", Block:"276", label:"AVK INTERNATIONAL RESIDENTIAL SCHOOL, ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"197", district:"24", Block:"276", label:"AVK MEMORIAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"198", district:"24", Block:"276", label:"GOMATHI AMBAL GHSS( BOYS), SANKARANKOVIL(MODEL)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"199", district:"24", Block:"276", label:"GHSS VEERASIGAMANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"200", district:"24", Block:"276", label:"Sri Gomathi Ambal Matric Hr. Sec. School, Sankarankovil", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"201", district:"24", Block:"276", label:"GOVT GIRLS HSS, SANKARANKOVIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"202", district:"24", Block:"276", label:"HARIJAN MS, NOCHIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"203", district:"24", Block:"276", label:"HINDU MS ACHAMPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"204", district:"24", Block:"276", label:"HINDU MS ,  PANNAIYOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"205", district:"24", Block:"276", label:"HNUC HSS, ARIYANAYAGIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"206", district:"24", Block:"276", label:"IMAM GAZZALI (RAH) ORIENTAL MATRICULATION SCHOOL SANKARANKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"207", district:"24", Block:"276", label:"JOTHI HR.SEC.SCHOOL, SOLAICHERI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"208", district:"24", Block:"276", label:"MANIMUTHU MS , RAMALINGAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"209", district:"24", Block:"276", label:"MMS EAST SANKARANKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"210", district:"24", Block:"276", label:"MMS , GANDHI NAGAR , SANKARANKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"211", district:"24", Block:"276", label:"MMS KALAIGNAR SALI SANKARANKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"212", district:"24", Block:"276", label:"MMS KRISHNASAMI NAGAR SANKARANKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"213", district:"24", Block:"276", label:"MMS WEST,SANKARANKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"214", district:"24", Block:"276", label:"PUMS, IRUMANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"215", district:"24", Block:"276", label:"PUMS , MANALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"216", district:"24", Block:"276", label:"PUMS PERUMALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"217", district:"24", Block:"276", label:"PUMS, SENTHATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"218", district:"24", Block:"276", label:"PUMS, THIRUVETTANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"219", district:"24", Block:"276", label:"PUMS VADANATHAM PATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"220", district:"24", Block:"276", label:"PUMS, VEERIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"221", district:"24", Block:"276", label:"PUMS, VEPANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"222", district:"24", Block:"276", label:"RC MS, SANKARANKOVIL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"223", district:"24", Block:"276", label:"SENGUNTHAR MIDDLE SCHOOL, SANKARANKOVIL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"224", district:"24", Block:"276", label:"SENGUNTHAR HS, SUBBULAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"225", district:"24", Block:"276", label:"SEVENTHDAY MATRIC HSS, SANKARANKOVIL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"226", district:"24", Block:"276", label:"S.N.R. HR.SEC.SCHOOL SRIGOMATHIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"227", district:"24", Block:"276", label:"SRI MAHALAKSHMI MATRIC. HR. SEC. SCHOOL, SANKARANKOVIL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"228", district:"24", Block:"276", label:"SRI MATHA MATRIC SCHOOL, KARIVALAMVANDANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"229", district:"24", Block:"276", label:"ST. JOSEPH MATRIC HSS, SANKARANKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"230", district:"24", Block:"276", label:"TDTA MS ARUNACHALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"231", district:"24", Block:"276", label:"TDTA MS, MANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"232", district:"24", Block:"276", label:"TDTA MS , PURAKUDAIYANPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"233", district:"24", Block:"276", label:"TDTA MS, SUBBULAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"234", district:"24", Block:"276", label:"SRI VAIYAPURI VIDYALAYA MATRIC HR SEC SCHOOL Sankarankovil", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"235", district:"24", Block:"276", label:"VIVEKANANDA SILVER JUBILEE MATRIC HR.SEC.SCHOOL, VEERASIGAMANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"236", district:"24", Block:"276", label:"VOC MS, KUVALAIKANNI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"237", district:"24", Block:"276", label:"VVS HS, SANKARANKOVIL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"238", district:"30", Block:"174", label:"GHS DEVARKULAM", Sch_type:"Government", Category:"High School"},
    { key:"239", district:"24", Block:"180", label:"GOVERNMENT HIGH SCHOOL, PERIYA KOVILANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"240", district:"24", Block:"180", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, NADUVAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"241", district:"24", Block:"180", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SENDAMARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"242", district:"24", Block:"180", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THIRUMALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"243", district:"30", Block:"174", label:"GHSS VANNICONENDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"244", district:"24", Block:"180", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VELLALANKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"245", district:"30", Block:"174", label:"GHS VELLAPPANERI", Sch_type:"Government", Category:"High School"},
    { key:"246", district:"30", Block:"174", label:"GHS VENKATACHALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"247", district:"24", Block:"180", label:"HINDU HARIJAN MIDDLE SCHOOL, KURUKKALPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"248", district:"24", Block:"180", label:"HINDU NADAR MIDDLE SCHOOL, KADAYALURUTTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"249", district:"30", Block:"174", label:"HINDU MIDDLE SSHOOL CHOKKANACHIYALPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"250", district:"24", Block:"180", label:"MADATHIAMMAL MIDDLE SCHOOL, OOTHANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"251", district:"24", Block:"180", label:"PRAKASH MATRICULATION SCHOOL, PANAVADALICHATRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"252", district:"24", Block:"180", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHINNA KOVILANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"253", district:"24", Block:"180", label:"PANCHAYAT UNION MIDDLE SCHOOL, KO.MARUTHAPPAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"254", district:"24", Block:"180", label:"PANCHAYAT UNION MIDDLE SCHOOL, KULASEKARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"255", district:"30", Block:"174", label:"PUMS PANNEERUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"256", district:"24", Block:"180", label:"PANCHAYAT UNION MIDDLE SCHOOL, SHUNMUGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"257", district:"24", Block:"180", label:"RAMAR MIDDLE SCHOOL, VELLAPPANADAROOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"258", district:"24", Block:"180", label:"RC MIDDLE SCHOOL, KEELANEELITHANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"259", district:"30", Block:"174", label:"SANKARI MATRIC HR. SEC. SCHOOL, DEVARKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"260", district:"24", Block:"180", label:"SHRI BHARATH KANNA MATRICULATION SCHOOL. PERIASAMIAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"261", district:"24", Block:"180", label:"ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL, SENDAMARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"262", district:"24", Block:"180", label:"SUCCESS MATRICULATION HIGHER SECONDARY SCHOOL, THIRUMALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"263", district:"30", Block:"174", label:"TDTA MS DEVARKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"264", district:"30", Block:"174", label:"TDTA MS MELAILANDAIKUALM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"265", district:"30", Block:"174", label:"TDTA MS MOOVIRUNTHALI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"266", district:"30", Block:"174", label:"TDTA MS NORTH ACHAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"267", district:"30", Block:"174", label:"TIHSS MELAILANDAIKUALM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"268", district:"28", Block:"118", label:"ACHARIYA SCHOOL, VANARAMUTTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"269", district:"28", Block:"118", label:"ADI DRAVIDAR AND TRIBAL W ELFARE HS, KARISALKULAM", Sch_type:"Government", Category:"High School"},
    { key:"270", district:"28", Block:"118", label:"GOVT ADW HIGH SCHOOL, THIRUMANGALAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"271", district:"28", Block:"118", label:"BABA MATRICULATION HR.SEC. SCHOOL, KAYATHAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"272", district:"28", Block:"118", label:"GHS, AYYANAROOTHU", Sch_type:"Government", Category:"High School"},
    { key:"273", district:"28", Block:"118", label:"GHS, CHIDAMBARAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"274", district:"28", Block:"118", label:"GHSS, KALAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"275", district:"28", Block:"118", label:"GHS, NORTH ILANTHAIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"276", district:"28", Block:"118", label:"GHSS, KALUGUMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"277", district:"28", Block:"118", label:"GHSS, KAMANAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"278", district:"28", Block:"118", label:"GHSS, KAYATHAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"279", district:"28", Block:"118", label:"GHSS, VANARAMUTTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"280", district:"28", Block:"118", label:"HINDU NADAR KAMARAJ HS, SOLAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"281", district:"28", Block:"118", label:"HINDU NADAR MS, CHETTIKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"282", district:"28", Block:"118", label:"KARM GEORGE MS, KADAMBUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"283", district:"28", Block:"118", label:"MAHA LAKSHMI MS, AKILANDAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"284", district:"28", Block:"118", label:"HINDU NADAR HSS KADAMBUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"285", district:"28", Block:"118", label:"MOTHER THERESA MAT., KAYATHAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"286", district:"28", Block:"118", label:"PU MS, KAYATHAR SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"287", district:"28", Block:"118", label:"PUMS, KOPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"288", district:"28", Block:"118", label:"PUMS, KUMARAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"289", district:"28", Block:"118", label:"PUMS, K. VENKATESWARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"290", district:"28", Block:"118", label:"PUMS, NAGALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"291", district:"28", Block:"118", label:"PUMS PANIKKARKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"292", district:"28", Block:"118", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUNGAVARNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"293", district:"28", Block:"118", label:"PUMS, VAGAITHAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"294", district:"28", Block:"118", label:"PUMS, KARADIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"295", district:"28", Block:"118", label:"KAMMAVAR GIRLS H.S.S.KALUGUMALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"296", district:"28", Block:"118", label:"RC FATIMA HS, KAYATHAR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"297", district:"28", Block:"118", label:"RCMS, PANNIRKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"298", district:"28", Block:"118", label:"RC MS, VELLALANKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"299", district:"28", Block:"118", label:"RC SUSAI HSS, KALUGUMALAI .", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"300", district:"28", Block:"118", label:"SREE KRISHNA HS, KURUMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"301", district:"28", Block:"118", label:"ST ALOYSIOUS HIGH SCHOOL, KAMANAYAKKANPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"302", district:"28", Block:"118", label:"ST LOUISA GIRLS HSS, KALUGUMALAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"303", district:"28", Block:"118", label:"ST.MADONNA'S MATRIC HIGHER SECONDARY  SCHOOL, KALUGUMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"304", district:"28", Block:"118", label:"ST MARYS HIGH SCHOOL, THERKU KONAR KOTTAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"305", district:"28", Block:"118", label:"ST XAVIERS MS, VADAKKU VANDHANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"306", district:"28", Block:"118", label:"TNDTA APPASAMY MS, RAJAPUDHUKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"307", district:"28", Block:"118", label:"VIMAL MATRIC SCHOOL, KALUGUMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"308", district:"28", Block:"138", label:"AV HSS,KOVILPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"309", district:"28", Block:"138", label:"AUXILIUM MATRIC HR.SEC.SCHOOL ,KEELA ERAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"310", district:"28", Block:"138", label:"CKT Matric Hr.Sec.School  KUMARAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"311", district:"28", Block:"138", label:"COUNIAN MAT. HR SEC SCHOOL , KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"312", district:"28", Block:"138", label:"EDUSTAR INTERNATIONAL SCHOOL, KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"313", district:"28", Block:"138", label:"EVA VALLIMUTHU HS, KOVILPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"314", district:"28", Block:"138", label:"EVEREST MARIAPPA NADAR HSS, KOVILPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"315", district:"28", Block:"138", label:"EVEREST MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"316", district:"28", Block:"138", label:"M.B.C.M Govt Girls Hr.Sec. School, ETTAYAPURAM -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"317", district:"28", Block:"138", label:"G(G)HSS, KOVILPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"318", district:"28", Block:"138", label:"GHS, LOYALMILL COLONY", Sch_type:"Government", Category:"High School"},
    { key:"319", district:"28", Block:"138", label:"GHSS, UTHUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"320", district:"28", Block:"138", label:"GHSS, VILLISERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"321", district:"28", Block:"138", label:"GHS, VIJAYAPURI", Sch_type:"Government", Category:"High School"},
    { key:"322", district:"28", Block:"138", label:"ICM MS, KOVILPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"323", district:"28", Block:"138", label:"ILLATHAR MIDDLE SCHOOL , PUDUGRAMAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"324", district:"28", Block:"138", label:"JOHN BOSCO MATRIC HSS, KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"325", district:"28", Block:"138", label:"KAMARAJ MATRIC HSS, KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"326", district:"28", Block:"138", label:"KOVILPATTI NADAR KAMARAJ MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"327", district:"28", Block:"138", label:"Kammavar (Girls) Hr.Sec.School, KOVILPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"328", district:"28", Block:"138", label:"SRI KANNA MATRIC HR SEC SCHOOL, KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"329", district:"28", Block:"138", label:"KRA VIDHYASHRAM MATRIC HSS, KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"330", district:"28", Block:"138", label:"KR SARATHA GOVT HR SEC SCHOOL, NALATINPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"331", district:"28", Block:"138", label:"LAKSHMI SRINIVASA VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"332", district:"28", Block:"138", label:"LAKSHMI MILLS HIGHER SECONDARY SCHOOL KOVILPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"333", district:"28", Block:"138", label:"LOYAL TEXTILE MILLS MS, KOVILPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"334", district:"28", Block:"138", label:"Maharisi Vidyasram Matriculation School, Kovilpatti", Sch_type:"Un-aided", Category:"High School"},
    { key:"335", district:"28", Block:"138", label:"MARIAPPA DVMS, ETTAYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"336", district:"28", Block:"138", label:"MUNICIPAL HS GANDHI NAGAR, KOVILPATTI", Sch_type:"Government", Category:"High School"},
    { key:"337", district:"28", Block:"138", label:"MUNICIPAL MS, NEW ROAD, KOVILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"338", district:"28", Block:"138", label:"MUNICIPAL MS,BHARATHINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"339", district:"28", Block:"138", label:"NADAR HSS, KOVILPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"340", district:"28", Block:"138", label:"NADAR M.S.,KOVILPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"341", district:"28", Block:"138", label:"PARIMELZHAGAR HMS, ERACHI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"342", district:"28", Block:"138", label:"PARVATHI HS,ILLUPPAIYURANI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"343", district:"28", Block:"138", label:"PUMS,KILAVIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"344", district:"28", Block:"138", label:"PUMS, MELAERAL", Sch_type:"Government", Category:"Middle School"},
    { key:"345", district:"28", Block:"138", label:"PUMS, SURAIKAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"346", district:"28", Block:"138", label:"PUMS, THITTANKULAM NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"347", district:"28", Block:"138", label:"PUNITHA OHM MATRICULATION SCHOOL, KOVILPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"348", district:"28", Block:"138", label:"PUMS,MEENATCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"349", district:"28", Block:"138", label:"PUMS, ATHIKINAR", Sch_type:"Government", Category:"Middle School"},
    { key:"350", district:"28", Block:"138", label:"PUMS, NORTH SEMAPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"351", district:"28", Block:"138", label:"PUMS SINTHALAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"352", district:"28", Block:"138", label:"PUMS,URULAIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"353", district:"28", Block:"138", label:"RAJA HSS,THEATRE ROAD, ETTAYAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"354", district:"28", Block:"138", label:"RC MIDDLE SCHOOL, KASAVANKUNTRU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"355", district:"28", Block:"138", label:"RC MIDDLE SCHOOL, LINGAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"356", district:"28", Block:"138", label:"SDA MATRIC HSS, KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"357", district:"28", Block:"138", label:"SENAIAR KALAIMAGAL HS, ERACHI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"358", district:"28", Block:"138", label:"SENGUNTHAR HS, KADALAIYUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"359", district:"28", Block:"138", label:"SENGUNTHAR MS,NADUVIRPATTI, ETTAYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"360", district:"28", Block:"138", label:"SFS, NALATINPUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"361", district:"28", Block:"138", label:"SRMS MATRICULATION HIGHER SECONDARY SCHOOL, SINTHALAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"362", district:"28", Block:"138", label:"ST. ANDREWS MATRICULATION SCHOOL, KOVILPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"363", district:"28", Block:"138", label:"ST. JOSEPH'S HSS, KOVILPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"364", district:"28", Block:"138", label:"St.Paul's Matriculation Higher Secondary School, Kovilpatti", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"365", district:"28", Block:"138", label:"TNDTA MIDDLE SCHOOL, KADALAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"366", district:"28", Block:"138", label:"UP MATRICULATION HR.SEC. SCHOOL, KOVILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"367", district:"28", Block:"138", label:"VAMR HINDU NADAR MIDDLE SCHOOL, MELA ERAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"368", district:"28", Block:"138", label:"VANIGA VAISIA M.S.,KOVILPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"369", district:"28", Block:"138", label:"VEL VELLAICHAMY MS, KEELA IRAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"370", district:"28", Block:"138", label:"VISWAKARMA HS, KOVILPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"371", district:"28", Block:"138", label:"VOC GHSS, KOVILPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"372", district:"33", Block:"403", label:"VIJAYANTHA SR.SEC., AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"373", district:"5", Block:"406", label:"INFANT PREPARATORY MATRICULATION HIGHER SECONDARY SCHOOL-VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"374", district:"3", Block:"259", label:"BALALOK MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"375", district:"3", Block:"237", label:"RAILWAY BALA BHAVAN MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"376", district:"3", Block:"62", label:"DOVETON  BOYS' HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"377", district:"4", Block:"19", label:"K.G. MATRICULATION HR SEC SCHOOL, ANNUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"378", district:"3", Block:"259", label:"Shri M Ct Muthiah Memorial Matriculation Hr Sec School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"379", district:"37", Block:"70", label:"ST.MICHAEL'S MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"380", district:"19", Block:"339", label:"MARY IMMACULATE MATRIC SCHOOL,K.RASIAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"381", district:"3", Block:"270", label:"MARIA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"382", district:"34", Block:"27", label:"ACS MATRIC HSS, ARNI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"383", district:"29", Block:"357", label:"SRI VIDYA MANDIR MATRIC. HR.SEC.SCHOOL,THURAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"384", district:"29", Block:"366", label:"HOLY CROSS GIRLS HSS,TEPPAKULAM , TRICHY-2", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"385", district:"33", Block:"364", label:"PUMS - KRISHNA SAMUTHRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"386", district:"3", Block:"270", label:"ST MARYS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"387", district:"29", Block:"367", label:"St. Philomena's Girls Hr. Sec. School, Melapudur,", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"388", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL ANAIKATTUCHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"389", district:"33", Block:"252", label:"SRI SATHYA MATRICULATION SCHOOL POONAMALLEE", Sch_type:"Un-aided", Category:"High School"},
    { key:"390", district:"3", Block:"259", label:"MORNING STAR MATRIC. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"391", district:"33", Block:"403", label:"PUMS,POOTHAPEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"392", district:"3", Block:"2", label:"ALL ANGELS MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"393", district:"33", Block:"184", label:"BSS SWAMY PRANAVANANDA VIDHYA MANDHIR,MINJUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"394", district:"3", Block:"270", label:"NORTHWICK GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"395", district:"3", Block:"304", label:"GURUJI SRI MURALIDHARA SWAMIGAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"396", district:"3", Block:"304", label:"FATHIMA BASHEER MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"397", district:"34", Block:"125", label:"SACRED HEART MATRIC AVOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"398", district:"33", Block:"260", label:"NEHRU MATRIC, HSC, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"399", district:"3", Block:"304", label:"ANJUMAN MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"400", district:"4", Block:"242", label:"SRI KRISHNAA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"401", district:"33", Block:"403", label:"EBENEZAR MODERN MATRICULATION. SCHOOL, THIRUMULLAIVOYAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"402", district:"33", Block:"252", label:"DHAYA MATRICULATION SCHOOL,AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"403", district:"33", Block:"261", label:"SARASWATHI MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"404", district:"33", Block:"403", label:"OUR ANGEL MATRIC HIGHER SECONDARY SCHOOL,VALASARAVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"405", district:"33", Block:"252", label:"SRI RANI BAI MATRICULATION SCHOOL POONAMALLEE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"406", district:"34", Block:"27", label:"ST MARY'S MATRIC HSS ARNI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"407", district:"33", Block:"225", label:"PUMS, SAVATUR", Sch_type:"Government", Category:"Middle School"},
    { key:"408", district:"33", Block:"403", label:"SRI KRISHNAMMAL MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"409", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL SENEERKUPPAM (NORTH)", Sch_type:"Government", Category:"Middle School"},
    { key:"410", district:"20", Block:"231", label:"ST.MARY'S MATRICULATION HIGH SCHOOL, PARTHIBANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"411", district:"33", Block:"403", label:"SREE MAHA GANESA VIDYASALA MIDDLE SCHOOL. AMBATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"412", district:"3", Block:"195", label:"ST.SAVIO MATRICULATION HIGHER  SECONDARY SCHOOL, VELACHERY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"413", district:"33", Block:"260", label:"PANCHAYAT UNION MIDDLE SCHOOL, MANJAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"414", district:"33", Block:"225", label:"PUMS, KODIVALASAI COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"415", district:"33", Block:"260", label:"DR SIVANTHI ADITANAR MATRIC.HR.SEC.SCHOOL,  PUZHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"416", district:"2", Block:"300", label:"St.Don Bosco Matric HS,Chrompet", Sch_type:"Un-aided", Category:"High School"},
    { key:"417", district:"33", Block:"252", label:"TBM MIDDLE SCHOOL ,POONAMALLEE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"418", district:"3", Block:"259", label:"ST. JOHN'S MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"419", district:"33", Block:"338", label:"CHINNI SRIRAMALU CHEETY VIVEKANENDA VIDYALAYA  MATRICULATION  SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"420", district:"33", Block:"252", label:"ST JOSEPH MATRICULATION SCHOOL AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"421", district:"32", Block:"223", label:"LITERACY MISSION MATRIC HR SEC SCHOOL, SAMALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"422", district:"33", Block:"252", label:"SB VIJAYA REDDIAR MATRIC HR SEC SCHOOL, AVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"423", district:"33", Block:"403", label:"Velammals Memorial Mat Hr Sec School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"424", district:"33", Block:"87", label:"ANNAI SARASWATHI MATRIC HIGHER SECONDARY SCHOOL.,  MANAVALA  NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"425", district:"33", Block:"253", label:"GHS , PLACEPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"426", district:"31", Block:"155", label:"GHS NAYAKANERI", Sch_type:"Government", Category:"High School"},
    { key:"427", district:"33", Block:"338", label:"PUMS, AYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"428", district:"33", Block:"225", label:"PUMS, PANDRAVEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"429", district:"33", Block:"289", label:"PUMS,PADIANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"430", district:"25", Block:"239", label:"DR. J.C.KUMARAPPA CENTENARY VIDYA MANDIR MATRIC  HIGHER SECONDARY SCHOOL - PERAVURANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"431", district:"33", Block:"364", label:"PUMS - K K NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"432", district:"28", Block:"350", label:"GEETHA MATRIC HSS MUTHYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"433", district:"38", Block:"295", label:"VINAYAKAR MATRICULATION HR SEC SCHOOL,SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"434", district:"3", Block:"2", label:"ST PATRICKS AI HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"435", district:"33", Block:"403", label:"KARTHIKEYAN MAT HSS,MADHURAVOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"436", district:"4", Block:"349", label:"GRAHAM STAINES MATRIC HIGHER SECONDARY SCHOOL,SIKKARAYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"437", district:"33", Block:"252", label:"THANGAMANI MATRIC HR SEC SCHOOL, AVADI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"438", district:"33", Block:"260", label:"SKP VIV VID, MADHAVARAM WARD 3", Sch_type:"Un-aided", Category:"High School"},
    { key:"439", district:"33", Block:"403", label:"SPARTAN MATRIC HIGHER SECONDARY SCHOOL,MUGAPAIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"440", district:"4", Block:"302", label:"A.R.C MHSS - KARUMATHAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"441", district:"33", Block:"403", label:"PMR MAT SCH, MUGAPAIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"442", district:"33", Block:"252", label:"D. S. SELVAM MATRICULATION SCHOOL AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"443", district:"13", Block:"78", label:"ACHIEVERS ACADEMY MATRIC.HSS HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"444", district:"3", Block:"62", label:"SIVAKASI HINDU NADAR  MATRICULATION HR.SEC.SCHOOLL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"445", district:"3", Block:"69", label:"LITTLE FLOWERS MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"446", district:"33", Block:"403", label:"MGR ADARSH MAT, MUGAPAIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"447", district:"33", Block:"252", label:"JAYA JAYA SANKARA INTERNATIONAL SCHOOL NAZARATHPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"448", district:"33", Block:"338", label:"PUMS, ARANVOYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"449", district:"33", Block:"403", label:"DAV B SS, MUGAPPAIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"450", district:"4", Block:"302", label:"RATHNAVEL SUBRAMANIAM MATRICULATION HIGHER SECONDARY SCHOOL, SULUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"451", district:"3", Block:"304", label:"AVM RAJESWARI  MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"452", district:"33", Block:"252", label:"DANIEL MATRICULATION SCHOOL, POONAMALLEE", Sch_type:"Un-aided", Category:"High School"},
    { key:"453", district:"33", Block:"252", label:"SUNDAR MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"454", district:"33", Block:"252", label:"ST JOHN'S MATRICULATION  SCHOOL MELMANAMBEDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"455", district:"3", Block:"240", label:"HYDER GARDEN MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"456", district:"33", Block:"403", label:"BHARATHI MAT HSS,MADHURAVOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"457", district:"33", Block:"403", label:"LBEAAR MATRICULATION, HIGHER SECONDARY SCHOOL, NERKUNDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"458", district:"33", Block:"76", label:"RAN MAT SCH KAVARAIPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"459", district:"3", Block:"304", label:"KARTHIKEYAN MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"460", district:"27", Block:"319", label:"MARY MATHA MATRIC HIGHER SECONDARY SCHOOL - THENI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"461", district:"33", Block:"403", label:"PUMS, ADAYALAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"462", district:"33", Block:"225", label:"VAARIAR MATRIC HR,SEC.SCHOOL,PODATURPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"463", district:"30", Block:"174", label:"PUMS PALAMADAI UDAYANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"464", district:"33", Block:"403", label:"EBENEZER MAT KORATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"465", district:"33", Block:"403", label:"EBENEZER MARCUS MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"466", district:"33", Block:"252", label:"JAYA MATR SCHOOL, THIRUNINRAVUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"467", district:"33", Block:"403", label:"PUMS,CMDA MADURAVOYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"468", district:"4", Block:"52", label:"RASAKONDALAR MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"469", district:"3", Block:"270", label:"TST RAJAH GIRLS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"470", district:"3", Block:"240", label:"VEERA SAVARKAR NETHAJI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"471", district:"33", Block:"87", label:"PUMS KOTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"472", district:"3", Block:"69", label:"DON BOSCO MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"473", district:"33", Block:"260", label:"NAVA JYOTHI  VIDYALAYA MATRIC.HR.SEC.SCHOOL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"474", district:"33", Block:"338", label:"MUN. MS, NORTH RAJA ST, TRL", Sch_type:"Government", Category:"Middle School"},
    { key:"475", district:"33", Block:"184", label:"BHARATH MATRIC HIGHER SECONDARY SCHOOL,KOKKUMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"476", district:"33", Block:"338", label:"ST. ANNE'S MATRIC HIGHER SECONDARY  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"477", district:"33", Block:"252", label:"ST ANTHONY MIDDLE SCH, AVADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"478", district:"32", Block:"371", label:"OXFORD HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"479", district:"33", Block:"253", label:"JAI MARUTHI VIDHYALAYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"480", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL THIRUMAZHISAI", Sch_type:"Government", Category:"Middle School"},
    { key:"481", district:"8", Block:"281", label:"BHARATHI MATRICULATION SCHOOL, KEMPANAICKENPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"482", district:"33", Block:"261", label:"TMKV GOVT BOYS  HIGHER SECONDARY SCHOOL, ,AMMAYARKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"483", district:"33", Block:"260", label:"ST. Thomas Silver Jubilee Matriculation school. Mathur", Sch_type:"Un-aided", Category:"High School"},
    { key:"484", district:"33", Block:"403", label:"PANCHAYAT UNION MIDDLE SCHOOL, NOLAMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"485", district:"3", Block:"195", label:"VIDYA RATNA P.T.S MATRIC.HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"486", district:"3", Block:"2", label:"FATHIMA CENTRAL SENIOR SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"487", district:"33", Block:"87", label:"ST PETER MS PINCHIVAKKAM KANDIGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"488", district:"33", Block:"261", label:"PUMS,KATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"489", district:"33", Block:"87", label:"PUMS VENGATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"490", district:"3", Block:"2", label:"VANAVANI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"491", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL KAVALCHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"492", district:"33", Block:"87", label:"RCM MID SCH KALLAMBEDU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"493", district:"33", Block:"252", label:"AVADI MUNICIPAL MIDDLE SCHOOL ANNA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"494", district:"33", Block:"87", label:"PUMS VALASAIVETTIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"495", district:"8", Block:"202", label:"GOVT. HIGH SCHOOL, PATTIMANIAKARANPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"496", district:"30", Block:"46", label:"R.C.MS VEERAVANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"497", district:"33", Block:"184", label:"MODERN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"498", district:"33", Block:"252", label:"JAWAHARLAL NEHRU VIDYALAYA MATRICULATION SCHOOL KUMANANCHAVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"499", district:"33", Block:"403", label:"JOSHUA MODEL MAT HSS, ANNA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"500", district:"4", Block:"302", label:"VIJAYA VIDHYALAYA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"501", district:"33", Block:"403", label:"ASSISI MAT HSS RAMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"502", district:"32", Block:"363", label:"BHARATH MATRICULATION SCHOOL, VEERAPANDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"503", district:"33", Block:"338", label:"MUN. MS, PERIA KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"504", district:"33", Block:"289", label:"KALAIMAGAL MS,PONDAVAKKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"505", district:"33", Block:"260", label:"Don Bosco Matriculation Higher secondary school ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"506", district:"33", Block:"87", label:"PUMS NARASINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"507", district:"33", Block:"338", label:"RM JAIN VIDHYASHRAM SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"508", district:"33", Block:"338", label:"PUMS, KARIKALAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"509", district:"33", Block:"87", label:"PUMS VENGATHUR KANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"510", district:"33", Block:"253", label:"PUMS, TB PURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"511", district:"33", Block:"364", label:"PUMS-SINGARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"512", district:"33", Block:"76", label:"PUMS PATHIRVEDU WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"513", district:"33", Block:"261", label:"PUMS,NEELOTH BALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"514", district:"33", Block:"338", label:"PUMS, KALYANA KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"515", district:"33", Block:"338", label:"PUMS, THOZHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"516", district:"3", Block:"240", label:"DON BOSCO MAT SCHOOL MKB NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"517", district:"3", Block:"240", label:"SEVENTH DAY ADVENTIST MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"518", district:"3", Block:"2", label:"ANNAI VELANKANNI MATRICULATION HIGHER SECONDARY SCHOOL HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"519", district:"33", Block:"87", label:"SUBBA NAIDU MATRICULATION HIGHER SECONDARY SCHOOL,  MANAVALANAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"520", district:"4", Block:"349", label:"SRI SOWDESWARI VIDYALAYA MODEL MATRIC Hr SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"521", district:"33", Block:"403", label:"SREE SARASWATHI MATRICULATION HIGH SCHOOL, KALLIKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"522", district:"33", Block:"252", label:"JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA,MATRICULATION SCHOOL,KAMARAJ  NAGAR, AVADI, CHENNAI-600071", Sch_type:"Un-aided", Category:"High School"},
    { key:"523", district:"33", Block:"403", label:"SRI SARASWATHI VIDHYA MANDIR MATRIC HR SEC SCHOOL, AYANAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"524", district:"4", Block:"245", label:"SREE NARAYANA MISSION MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"525", district:"33", Block:"260", label:"PANCHAYAT UNION MIDDLE SCHOOL, ALINJIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"526", district:"33", Block:"403", label:"CS CENTRAL MAT HSS MANNURPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"527", district:"14", Block:"183", label:"SRI SUNDARESWARA VIDYA SALA MATRIC HR SEC SCHOOL, AATUKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"528", district:"3", Block:"259", label:"SUNSHINE ACADEMY MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"529", district:"33", Block:"87", label:"ST MARYS MATRICULATION HIGHER SECONDARY SCHOOL IRULANCHERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"530", district:"33", Block:"364", label:"DR VGS NAIDU MATRIC HR SEC SCHOOL -TIRUTTANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"531", district:"33", Block:"87", label:"JACOB MATRIC  SCHOOL.  VENGATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"532", district:"33", Block:"64", label:"SRI LAKSHMI VITHYALAYA MATRIC HR SEC SCHOOL, LACHIVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"533", district:"33", Block:"260", label:"ST JOSEPH MATRIC SCHOOL, MADHAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"534", district:"3", Block:"237", label:"ST MARYS BOYS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"535", district:"33", Block:"225", label:"PUMS, RANGANATHAN STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"536", district:"4", Block:"302", label:"KENNEDY MAT HSS SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"537", district:"33", Block:"76", label:"PUMS - NOCHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"538", district:"21", Block:"24", label:"KH MATRIC (G) HSS, MELVISHARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"539", district:"33", Block:"252", label:"DAV MATRIC HR SEC SCHOOL , AVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"540", district:"4", Block:"107", label:"L.E.F. CHRISTIAN MATRICULATION SCHOOL, BEHIND HOUSING UNIT, METTUPALAYAM.", Sch_type:"Un-aided", Category:"High School"},
    { key:"541", district:"33", Block:"64", label:"ST. JOSEPH MATRICULATION SCHOOL, PERIYAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"542", district:"3", Block:"69", label:"DON BOSCO MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"543", district:"33", Block:"225", label:"ST MARY'S MATRICULATION  HIGHER SECONDARY SCHOOL, PALLIPAT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"544", district:"3", Block:"304", label:"SIR M. VENKATASUBBA RAO MAT. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"545", district:"33", Block:"403", label:"ST MARYS MAT SC - KARAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"546", district:"8", Block:"65", label:"SEVENTH DAY ADVENTIST MATRICULATION HS, PATTAKARA", Sch_type:"Un-aided", Category:"High School"},
    { key:"547", district:"13", Block:"376", label:"SRI VIDYA MANDIR MHSS UTHANGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"548", district:"33", Block:"403", label:"PIONEER MAT, VELLAPPANCHAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"549", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOTTUPULLAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"550", district:"33", Block:"289", label:"PUMS, ATTANTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"551", district:"11", Block:"3", label:"GNANADEEPAM MATRIC HIGHER SECONDARY SCHOOL, AGASTHEESWARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"552", district:"33", Block:"225", label:"PUMS, KOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"553", district:"10", Block:"146", label:"Kalashethra MHSS, Iyyappanthangal", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"554", district:"33", Block:"403", label:"PUMS, MUGAPAIR SECTOR-2", Sch_type:"Government", Category:"Middle School"},
    { key:"555", district:"33", Block:"289", label:"ST.MARYS MATRIC HSS,REDHILLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"556", district:"33", Block:"184", label:"ST JAMES MAT SCH, VENPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"557", district:"3", Block:"237", label:"BALAJE  VIDYASHRAM MATRICULATION  SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"558", district:"30", Block:"222", label:"MARY SARGENT HIGHER SECONDARY SCHOOL FOR GIRLS,PALAYAMKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"559", district:"38", Block:"295", label:"GLORY MATRICULATION HR SEC SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"560", district:"33", Block:"260", label:"PUMS, SADAYANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"561", district:"4", Block:"242", label:"IDEAL MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"562", district:"21", Block:"21", label:"DR VGN MATRIC HSS, ARAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"563", district:"33", Block:"289", label:"VELAMMAL MATRICULATION HIGHER SECONDARY SCHOOL PONNERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"564", district:"36", Block:"12", label:"FREEDOM CONCEPT CBSE POIGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"565", district:"17", Block:"165", label:"SRI VINAYAGA MATRIC HIGHER SECONDARY SCHOOL,VAIYAPPAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"566", district:"33", Block:"225", label:"PUMS, KAKKALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"567", district:"33", Block:"403", label:"MUN. MID SCH, DR.MOORTHY NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"568", district:"21", Block:"408", label:"GRACE MATRIC HSS, RANIPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"569", district:"33", Block:"403", label:"RAJIV SCHOOL OF EXCELLENCE MATRIC HIGHER SECONDARY SCHOOL, ALAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"570", district:"33", Block:"403", label:"DAV MATRIC HR SEC SCHOOL, MOGAPPAIR EAST", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"571", district:"33", Block:"260", label:"ST THOMAS MAT HSS, MADHAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"572", district:"38", Block:"28", label:"NOBLE MATRICULATION HIGHER SECONDARY SCHOOL, ARUPPUKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"573", district:"3", Block:"259", label:"MAR THOMA MATRICULATION HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"574", district:"33", Block:"260", label:"ST. THOMAS MATRIC HSS, CHINNA SEKKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"575", district:"8", Block:"65", label:"SHRI JANANI MATRICULATION HIGHER SECONDARY SCHOOL, ELLAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"576", district:"33", Block:"403", label:"SRI BHUVANESWARI MAT, PADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"577", district:"33", Block:"403", label:"BALAR GURUKULAM MAT, NERKUNDRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"578", district:"22", Block:"309", label:"SWAMI VIVEKANANDA MATRIC SCHOOL, VEERAGANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"579", district:"8", Block:"65", label:"VELLALAR GIRLS MATRICULATION HSS, THINDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"580", district:"33", Block:"403", label:"GOODWILL MATRIC HIGHER SECONDARY SCHOOL, THATHANKUPPAM , VILLIVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"581", district:"33", Block:"403", label:"ST JOHNS MATRIC HR SEC SCHOOL, PULIAMBEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"582", district:"3", Block:"237", label:"KULAPATHI Dr.S.Balakrishnan Joshi Gurrukulam Matriculation HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"583", district:"36", Block:"74", label:"Dr.KRISHNASWAMI MATRIC SCHOOL, GUDIYATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"584", district:"33", Block:"184", label:"NATIONAL MATRIC. HR.SEC.SCHOOL,PULICAT", Sch_type:"Un-aided", Category:"High School"},
    { key:"585", district:"3", Block:"304", label:"VELANKANNI MATRIC HSS ASHOK NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"586", district:"35", Block:"193", label:"UAMHSS,SARASWATHI,MUTHUPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"587", district:"35", Block:"340", label:"UAMHSS, NEW BHARATH MATRIC,TIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"588", district:"21", Block:"24", label:"RATHINAGIRI BAGEERATHAN MATRIC HSS, RATHINAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"589", district:"3", Block:"304", label:"VIDYANIKEDAN MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"590", district:"33", Block:"289", label:"CHILDRENS PARADISE MATRIC HR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"591", district:"4", Block:"248", label:"R.V.S MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"592", district:"21", Block:"408", label:"SRI RAMAKRISHNA MATRIC HSS, ARCOT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"593", district:"8", Block:"45", label:"PUMS, SANARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"594", district:"11", Block:"3", label:"CARMEL MATHA MATRIC Hr.Sec.SCHOOL - KANYAKUMARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"595", district:"33", Block:"403", label:"SALVATION MAT TMVOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"596", district:"11", Block:"3", label:"E.D.WILLMOTT MEMORIAL S.D.A.MATRIC HSS VETTOORNIMADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"597", district:"3", Block:"2", label:"SANKARA SR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"598", district:"8", Block:"244", label:"VIJAY VIKAS MATRIC HIGHER SECONDARY SCHOOL,KAMBULIYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"599", district:"8", Block:"65", label:"SRI VASAVI MATRICULATION SCHOOL, ERODE-638316", Sch_type:"Un-aided", Category:"High School"},
    { key:"600", district:"28", Block:"351", label:"DMNS DR SIVANTHI ADITHANAR MATRIC HR SEC", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"601", district:"8", Block:"128", label:"ST.A.M.SCHOOL,SAHAYAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"602", district:"8", Block:"71", label:"RAMAKRISHNA MIDDLE SCHOOL, OLAPPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"603", district:"33", Block:"64", label:"J.N.N MATRIC HIGHER SECONDARY SCHOOL, KANNIGAIPAIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"604", district:"33", Block:"76", label:"MAHARAJA AGRASEN MATRIC HIGHER SECONDARY SCHOOL,ARUN NAGAR, GUMMIDIPOONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"605", district:"8", Block:"10", label:"SRV VIDHYYA VICKAS MATRICULATION SCHOOL , CHITTAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"606", district:"33", Block:"403", label:"THE SCHRAM ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"607", district:"7", Block:"352", label:"RENUGADEVI MATRIC HIGHER SECONDARY SCHOOL, VAGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"608", district:"33", Block:"403", label:"EBINESAR JAGANATH MARCUS MS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"609", district:"33", Block:"403", label:"LAKSHMI MATRICULATION HIGHER SECONDARY SCHOOL,JAGADAMBIGAI NAGAR,PADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"610", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL,  PERUNTHALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"611", district:"33", Block:"364", label:"SUDANDIRA MATHSS-TRT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"612", district:"33", Block:"184", label:"KAMARAJAR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"613", district:"14", Block:"159", label:"GOOD SHEPHERD MATRIC HR SEC SCHOOL, KANAGAVEL COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"614", district:"33", Block:"403", label:"SHARON MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"615", district:"33", Block:"403", label:"SIVANTHI MAT HSS,RAMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"616", district:"33", Block:"261", label:"AYYAN VID,VELLATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"617", district:"8", Block:"65", label:"PANCHAYAT UNION MIDDLE SCHOOL, TEACHERSCOLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"618", district:"8", Block:"65", label:"PUMS, B.P.AGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"619", district:"4", Block:"349", label:"SRI SARGURU MATRICULATION  SCHOOL  THEETIPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"620", district:"8", Block:"65", label:"CMS, RAILWAY COLONY -2", Sch_type:"Government", Category:"Middle School"},
    { key:"621", district:"8", Block:"65", label:"PUMS METTUNASUVAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"622", district:"27", Block:"319", label:"T.M.H.N.U. MATRIC HSS - MUTHUTHEVANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"623", district:"8", Block:"65", label:"PUMS, ATTAIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"624", district:"4", Block:"242", label:"S.V.NAGAR VIDYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"625", district:"21", Block:"409", label:"HINDU VIDYALAYA - CBSE SCHOOL SIPCOT", Sch_type:"Un-aided", Category:"High School"},
    { key:"626", district:"33", Block:"76", label:"SAKUNTHALAMMAL MATRIC SCHOOL THURAPALLAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"627", district:"4", Block:"245", label:"GURUKULAM HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"628", district:"36", Block:"394", label:"LAKSHMI GARDEN MHSS, VELLORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"629", district:"33", Block:"64", label:"SRI SANKARA MATRIC HIGHER SECONDARY SCHOOL, THANDALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"630", district:"8", Block:"65", label:"PUMS, MANICKAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"631", district:"6", Block:"218", label:"DHARMAPURI DIST. CO-OP SURGAR MILLS MATRIC SCHOOL, PALACODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"632", district:"33", Block:"289", label:"VIVEKANANDA MATRIC HIGHER SECONDARY SCHOOL, ATTANTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"633", district:"30", Block:"222", label:"Sri Jayendra Swamigal Silver jubilee MHSS  Maharaja Nagar", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"634", district:"36", Block:"12", label:"SHILOH MATRIC SCHOOL, ANAICUT", Sch_type:"Un-aided", Category:"High School"},
    { key:"635", district:"8", Block:"10", label:"SREE SARAVANA NIKETHAN MATRICULATION HIGHER SECONDARY SCHOOL, NERINJIPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"636", district:"33", Block:"403", label:"PUMS, KONIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"637", district:"36", Block:"105", label:"SEVENTH DAY ADVENTIST MATRIC HSS ,OTTERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"638", district:"33", Block:"403", label:"PUMS, CHETTIYAR AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"639", district:"4", Block:"52", label:"CARMEL GARDEN MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"640", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL NAZARATHPET", Sch_type:"Government", Category:"Middle School"},
    { key:"641", district:"33", Block:"76", label:"PUMS L R MEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"642", district:"27", Block:"241", label:"EDWARD MS - THENKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"643", district:"8", Block:"20", label:"PUMS BRAMDESAM", Sch_type:"Government", Category:"Middle School"},
    { key:"644", district:"33", Block:"403", label:"PUMS, MUGAPAIR SECTOR-4", Sch_type:"Government", Category:"Middle School"},
    { key:"645", district:"33", Block:"87", label:"PUMS KONDANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"646", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, OLAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"647", district:"8", Block:"65", label:"PUMS, EPB NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"648", district:"8", Block:"71", label:"SRI VENKATESHWARA VIDHYALAYAA MATRICULATION SCHOOL, THASAMPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"649", district:"38", Block:"110", label:"NAZIA MATRICULATION HR.SEC.SCHOOL, S.KALLUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"650", district:"32", Block:"363", label:"LEARNING HEARTS HS,15 VELAMPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"651", district:"35", Block:"334", label:"UAMHSS SAIRAM MATRIC SCHOOL,TTP", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"652", district:"33", Block:"261", label:"VETHATHIRI MAHARISHI HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"653", district:"8", Block:"20", label:"PUMS KOVILUR", Sch_type:"Government", Category:"Middle School"},
    { key:"654", district:"3", Block:"62", label:"GOVT HOBART MUSLIM GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"655", district:"8", Block:"310", label:"MARIA DEEPTHI MATRIC HIGH SCHOOL PANAKAHALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"656", district:"3", Block:"69", label:"SEETHAKKATHI MATRIC HR SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"657", district:"8", Block:"20", label:"PUMS ANTHIYUR EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"658", district:"8", Block:"65", label:"THE INDIAN PUBLIC SCHOOL,SENAPATHYPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"659", district:"30", Block:"9", label:"TDTA MS- KEELA SIVANTHIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"660", district:"33", Block:"403", label:"HOLY CROSS MAT,VALASARAVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"661", district:"8", Block:"45", label:"PANCHAYAT UNION MIDDLE SCHOOL, MURUNGATHOZHUVU", Sch_type:"Government", Category:"Middle School"},
    { key:"662", district:"33", Block:"364", label:"PUMS - VEERAKA NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"663", district:"35", Block:"340", label:"UAMHSS RC FATHIMA THIRUVARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"664", district:"4", Block:"245", label:"SBOA MATRICULATION HR SEC SCHOOL,CHOKKAMPUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"665", district:"33", Block:"184", label:"PK MAT SCH,PUNGAMBEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"666", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, MOTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"667", district:"33", Block:"338", label:"BHARATHI MATRICULATION SCHOOL  RAJAJIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"668", district:"33", Block:"338", label:"SRI GNANABANU VIDYALAYA  MATRIC  SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"669", district:"14", Block:"161", label:"S.E.V MATRIC HR SEC SCHOOL, NARAYANAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"670", district:"8", Block:"20", label:"PUMS KUPPANDAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"671", district:"33", Block:"225", label:"ANM MID.SCH, POTHATURPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"672", district:"8", Block:"244", label:"KONGU VELLALAR KALVI NIRUVANAM MATRIC HIGHER SECONDARY SCHOOL,KANJI KOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"673", district:"8", Block:"37", label:"PUMS VAIKKALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"674", district:"8", Block:"10", label:"PUMS MATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"675", district:"8", Block:"185", label:"PUMS,SAMINATHAPURAM PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"676", district:"8", Block:"71", label:"MRS MATRICULATION SCHOOL, KOLAPPALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"677", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, UTHUKULI RAILWAY STATION", Sch_type:"Government", Category:"Middle School"},
    { key:"678", district:"33", Block:"403", label:"VENKATESHWARA MATRIC SCHOOL, THIRUMULLAIVAYOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"679", district:"33", Block:"403", label:"INFANT JESUS MATRIC HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"680", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, AVVAIYARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"681", district:"27", Block:"241", label:"SHRI RENUGA VIDYALAYAM MATRICULATION HR SEC SCHOOL - LAKSHMIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"682", district:"3", Block:"62", label:"SACRED HEART MAT HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"683", district:"8", Block:"37", label:"PUMS SANKARAGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"684", district:"33", Block:"338", label:"PUMS, ARANVOYAL KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"685", district:"18", Block:"236", label:"ST JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL ELAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"686", district:"33", Block:"260", label:"AUXILIUM MATRICULATION SCHOOL, ,THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"687", district:"21", Block:"21", label:"ROYAL MATRIC HSS, THAKKOLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"688", district:"33", Block:"403", label:"PUMS MUGAPPAIR SECTOR - 9", Sch_type:"Government", Category:"Middle School"},
    { key:"689", district:"4", Block:"245", label:"MOTHER LAAND MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"690", district:"32", Block:"223", label:"V.A.T TRUST MATRIC HR SEC SCHOOL, GANAPATHIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"691", district:"32", Block:"371", label:"RGMHSS UDUMALAIPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"692", district:"32", Block:"363", label:"PEM SCHOOL OF EXCELLANCE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"693", district:"32", Block:"32", label:"S.K.L PUBLIC MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"694", district:"32", Block:"363", label:"OXFORD MODERN MATRIC SCHOOL, MUTHANAMPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"695", district:"33", Block:"338", label:"RAJA NATIONAL  MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"696", district:"8", Block:"128", label:"SRI SANKARA VID MATRIC HR SEC SCHOOL SIVAGIRI ERODE ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"697", district:"30", Block:"46", label:"SIVANTHI MATRICULATION HSS CHERANMAHADEVI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"698", district:"33", Block:"252", label:"NAZARETH MATRIC HR  SEC SCHOOL, KAMARAJ NAGAR, AVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"699", district:"32", Block:"363", label:"BALABHAVAN GLOBAL MHSS, KARUMARAMPALAYAM, TIRUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"700", district:"32", Block:"32", label:"MIGROS K.I.D.S MATRIC HR SEC SCHOOL, KANIYAMPOONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"701", district:"24", Block:"307", label:"HILTON MATRIC HR SEC SCHOOL, AYIRAPERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"702", district:"8", Block:"37", label:"PUMS KAVINDAPADIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"703", district:"4", Block:"302", label:"MICHAEL JOB MATRICULATION.HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"704", district:"33", Block:"364", label:"MAHARISHI VIDYA MANDIR MATRICULATION SCHOOL -K.G.KANDIGAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"705", district:"8", Block:"128", label:"PUMS,PERIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"706", district:"8", Block:"281", label:"SRI RAGAVENDARA MATRICULATION HIGHER SECONDARY SCHOOL,  SATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"707", district:"8", Block:"65", label:"KALAIMAGAL KALVI NILAYAM GIRLS' MATRICULATION", Sch_type:"Un-aided", Category:"High School"},
    { key:"708", district:"32", Block:"363", label:"PLATO'S ACADEMY MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"709", district:"33", Block:"87", label:"PUMS PUDUMAVILANGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"710", district:"30", Block:"222", label:"SRI LALITHA VIDHYASHRAM MATRICULATION SCHOOL  PERUMALPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"711", district:"8", Block:"185", label:"PUMS, METTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"712", district:"14", Block:"160", label:"SRI VIDYA BHAVAN MATRIC HIGHER SECONDARY SCHOOL, TEPPAKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"713", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, KONAMOOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"714", district:"33", Block:"184", label:"ST. JOHN'S MATRICULATION SCHOOL,PONNERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"715", district:"33", Block:"403", label:"SWAMY'S MAT HSS,PORUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"716", district:"30", Block:"222", label:"PUSHPALATHA MHSS TIRUNELVELI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"717", district:"19", Block:"22", label:"ANNAI MEENATCHI NACHIAR MATRIC HR SEC SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"718", district:"8", Block:"310", label:"PUMS NEITHALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"719", district:"20", Block:"266", label:"VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, DEVIPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"720", district:"8", Block:"20", label:"PUMS, L.SEMBULICHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"721", district:"32", Block:"73", label:"RGM HSS PEDAPPAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"722", district:"32", Block:"104", label:"GLOBAL  MATRIC HR SEC SCHOOL, KANGAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"723", district:"32", Block:"363", label:"LITTLE FLOWER MAT HSS,ANDIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"724", district:"8", Block:"65", label:"PANCHAYAT UNION MIDDLE SCHOOL , KOORAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"725", district:"33", Block:"252", label:"GANDHIJI MATRIC HR SEC SCHOOL,  AVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"726", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, KUGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"727", district:"33", Block:"403", label:"SIRORATHNAM MAT MANNURPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"728", district:"8", Block:"45", label:"SHRI GANGA MATRICULATION SCHOOL, INGUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"729", district:"33", Block:"87", label:"PUMS KILNALLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"730", district:"24", Block:"6", label:"SRI RAMAKRISHNA MATRIC. HR. SEC. SCHOOL - ALANGULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"731", district:"32", Block:"363", label:"TNSS GANDHI VIDHYALAYA MATRIC HR SEC SCHOOL,VEERAPANDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"732", district:"8", Block:"20", label:"PUMS  ENNAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"733", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUNGAMPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"734", district:"8", Block:"45", label:"PUMS, CHENNIYANGIRIVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"735", district:"4", Block:"242", label:"DR. P.G.V MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"736", district:"33", Block:"403", label:"GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"737", district:"32", Block:"363", label:"V A T T MHSS, TIRUPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"738", district:"36", Block:"393", label:"V V N K M ENG (CBSE) SCHOOL, ALLAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"739", district:"32", Block:"363", label:"NYRUTHI VIDHYA BHAVAN MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"740", district:"32", Block:"363", label:"ST.JOSEPH'S MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"741", district:"32", Block:"32", label:"PALANIAPPA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"742", district:"33", Block:"252", label:"ANGEL MATRIC HR SEC SCHOOL,THIRUNINRAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"743", district:"4", Block:"52", label:"BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"744", district:"32", Block:"363", label:"VELAVAN MHS,TIRUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"745", district:"32", Block:"363", label:"CENTURY FOUNDATION MHSS,TIRUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"746", district:"33", Block:"184", label:"EMMANUEL MATRIC HIGHER SECONDARY SCHOOL,ATHIPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"747", district:"8", Block:"20", label:"PUMS THATTAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"748", district:"32", Block:"371", label:"SRINIVASA VIDHYALAYA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"749", district:"33", Block:"403", label:"SHRI KRISHNASWAMY MATRICULATION SCHOOL,AYAPAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"750", district:"32", Block:"371", label:"LOURDE MATHA CONVENT MATRIC  HSS KURALKUTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"751", district:"4", Block:"19", label:"ST. ANTONY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"752", district:"19", Block:"167", label:"M.H. MATRIC HR. SEC. SCHOOL, KOTTAIPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"753", district:"26", Block:"72", label:"IDEAL MATRIC HIGHER SECONDARY SCHOOL, T.K.PET, GUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"754", district:"4", Block:"248", label:"VIDYA NETHRRA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"755", district:"4", Block:"279", label:"ADWAITH G.N.S MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"756", district:"32", Block:"363", label:"AZAD MHSS,,NACHIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"757", district:"36", Block:"74", label:"ST JOHNS MHSS GUDIYATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"758", district:"10", Block:"146", label:"LALAJI MEMORIAL OMEGA INTERNATIONALSCHOOL  KOLAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"759", district:"8", Block:"281", label:"THE NEST MATRICULATION HIGHER SECONDARY SCHOOL, PUTHUVADAVALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"760", district:"30", Block:"92", label:"NATIONAL MATRIC HR SEC SCHOOL KALAKAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"761", district:"14", Block:"160", label:"AMALI MATRIC HR SEC SCHOOL, NEW RAMNAD ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"762", district:"32", Block:"223", label:"BHARATHI MATRIC HR SEC SCHOOL, P.VADUGAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"763", district:"3", Block:"2", label:"MAK MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"764", district:"27", Block:"49", label:"MPL MS, MAIN STREET - CHINNAMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"765", district:"4", Block:"52", label:"ALVERNIA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"766", district:"8", Block:"65", label:"ERODE HINDU KALVI NILAYAM MATRICULATION HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"767", district:"32", Block:"392", label:"BHARAT MATRICULATION SCHOOL,OLAPPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"768", district:"27", Block:"31", label:"S.K.A. MAT HSS, AUNDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"769", district:"33", Block:"184", label:"VIVEKANANDA MATRICULATION  SCHOOL VALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"770", district:"20", Block:"99", label:"RAHMANIA MATRIC HIGHER SECONDARY SCHOOL,KAMUTHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"771", district:"32", Block:"371", label:"SRIGVG VISHALAKSHI MHSS UDUMALPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"772", district:"8", Block:"71", label:"AMALA MATRIC. HIGHER SEC. SCHOOL, GOBI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"773", district:"30", Block:"222", label:"MANIVASAKAR ARULNERI MS PALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"774", district:"8", Block:"10", label:"MAM EXEL MATRICULATION HIGHER SECONDARY SCHOOL,  GURUVAREDDIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"775", district:"8", Block:"281", label:"BANNARIAMMAN VIDYA NIKETAN MATRIC, ALATHUKOMBAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"776", district:"33", Block:"289", label:"SRI KALAIVANI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL.,", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"777", district:"11", Block:"3", label:"PIONEER HSS NAGERCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"778", district:"33", Block:"403", label:"ST JOHNS HIGHER SECONDARY SCHOOL ,PORUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"779", district:"33", Block:"252", label:"ST PAULS MATRICULATION SCHOOL, AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"780", district:"33", Block:"364", label:"PUMS - PERIYAKADAMBUR H/C", Sch_type:"Government", Category:"Middle School"},
    { key:"781", district:"14", Block:"160", label:"SOURASHTRA GIRLS HR SEC SCHOOL, ANUPPANADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"782", district:"4", Block:"279", label:"PERKS MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"783", district:"30", Block:"222", label:"HAMEEM MS MELAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"784", district:"33", Block:"76", label:"PUMS MADHARPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"785", district:"30", Block:"204", label:"JN MS SUBRAMANIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"786", district:"4", Block:"242", label:"PANCHAYAT UNION MIDDLE SCHOOL SAMICHETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"787", district:"33", Block:"289", label:"PUMS,NEDUVARAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"788", district:"32", Block:"223", label:"PUMS ALLALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"789", district:"33", Block:"76", label:"PUMS NATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"790", district:"33", Block:"76", label:"PUMS PETHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"791", district:"33", Block:"76", label:"PUMS GOPAL REDDY KANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"792", district:"33", Block:"87", label:"PUMS SATHARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"793", district:"23", Block:"79", label:"PUMS SATHANI", Sch_type:"Government", Category:"Middle School"},
    { key:"794", district:"27", Block:"40", label:"PANKAJAM MIDDLE SCHOOL -  BODI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"795", district:"33", Block:"403", label:"PUMS, ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"796", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL VARADHARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"797", district:"24", Block:"388", label:"S.M.M MS, SUBRAMANIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"798", district:"32", Block:"223", label:"JAYANTHI MATRIC HR SEC SCHOOL, ARULPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"799", district:"33", Block:"260", label:"KAVI BHARATHI VID, THIRUVOTRIYUR 40", Sch_type:"Un-aided", Category:"High School"},
    { key:"800", district:"32", Block:"363", label:"THE FRONT LINE ACADEMY MAT HSS,PERUNTHOLUVU ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"801", district:"32", Block:"32", label:"M.S.VIDYALAYA  MAT KARUNAIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"802", district:"33", Block:"403", label:"ST JOSEPH'S MATRIC  HSS,PORUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"803", district:"32", Block:"104", label:"JAYCEES MATRIC HR SEC SCHOOL, SIVANMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"804", district:"32", Block:"363", label:"VIGNESWARA VIDYALAYA MHSS,TIRUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"805", district:"11", Block:"354", label:"RUBEN MATRIC. HR. SEC. SCHOOL - THADIKARANKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"806", district:"8", Block:"65", label:"PUMS, INDIRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"807", district:"32", Block:"223", label:"PUMS MANICKAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"808", district:"20", Block:"86", label:"AL-AMEEN MATRICULATION HIGH SCHOOL, VALINOKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"809", district:"32", Block:"104", label:"SRI RAJARAJESWARI MATRIC HR SEC SCHOOL, KANGAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"810", district:"33", Block:"403", label:"SREE AADHITHYA MATRICULATION HIGH SCHOOL, VANAGARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"811", district:"33", Block:"403", label:"EXCEL MAT., TMVOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"812", district:"3", Block:"304", label:"VIDYAKSHETHRAM MATRIC SCH", Sch_type:"Un-aided", Category:"High School"},
    { key:"813", district:"32", Block:"363", label:"SRI SAI MATRIC HR.SEC. SCHOOL , RAM NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"814", district:"32", Block:"363", label:"DHEVA MATRICULATION HR.SEC.SCHOOL,K.CHETTIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"815", district:"3", Block:"304", label:"SRI KRISHNASWAMY MHSS KK NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"816", district:"11", Block:"149", label:"JOSEPH MATRIC- SASTHANKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"817", district:"4", Block:"302", label:"PUMS S.L.V.NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"818", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, MEVANI", Sch_type:"Government", Category:"Middle School"},
    { key:"819", district:"11", Block:"3", label:"S.M.MATRIC SCHOOL,MYLAUDY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"820", district:"8", Block:"10", label:"PUMS MARAVAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"821", district:"4", Block:"242", label:"JAYCEE HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"822", district:"30", Block:"385", label:"ST. Annes Metric Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"823", district:"32", Block:"363", label:"KGS MHS,", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"824", district:"19", Block:"22", label:"CRESCENT MATRIC SCHOOL, RAJENDRAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"825", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"826", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"827", district:"4", Block:"242", label:"CHINKUMAR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"828", district:"8", Block:"45", label:"MOTTAIYAPPAR AMS, VALLIPURATHAMPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"829", district:"11", Block:"3", label:"VIVE.KEN.MAT. HSS-KANYAKUMARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"830", district:"4", Block:"247", label:"SUBASH VIDHYA MANDIR MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"831", district:"19", Block:"339", label:"ADR MATRIC HR.SEC.SCHOOL,KAIKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"832", district:"20", Block:"169", label:"ALLWIN MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"833", district:"33", Block:"403", label:"SWAAMI VIVEKANANDA MATRIC HSS , RAMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"834", district:"28", Block:"217", label:"CMML MS NARAIKINARU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"835", district:"19", Block:"25", label:"SRI SIVAAKAMALAM MATRIC ARIMALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"836", district:"32", Block:"145", label:"R G MATRIC HR SEC SCHOOL MANOORPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"837", district:"8", Block:"310", label:"PUMS BALAPPADUGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"838", district:"8", Block:"281", label:"MUNICIPAL MIDDLE SSCHOOL, SATHYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"839", district:"8", Block:"65", label:"AL AMEEN MATRICULATION HSS, CAUVERY ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"840", district:"8", Block:"37", label:"SARASWATHI VIDHYASHRAM MATRIC HIGHER SECONDARY SCHOOL KAVUNDAPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"841", district:"32", Block:"363", label:"Saradha Vidhyalaya Matric HSS, Pandiyan Nagar", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"842", district:"11", Block:"263", label:"HOLY MOTHER MATRIC SCHOOL, PARVATHIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"843", district:"3", Block:"368", label:"CHS CHINTADRIPET", Sch_type:"Government", Category:"High School"},
    { key:"844", district:"32", Block:"363", label:"VIDHYAA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL-KULATHUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"845", district:"27", Block:"241", label:"VICTORY MATRIC HS - GANDHINAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"846", district:"33", Block:"338", label:"JACOB MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"847", district:"19", Block:"18", label:"PONMARI VID MATRIC,POMMADIMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"848", district:"32", Block:"363", label:"SRI RAMAKRISHNA VIDYALAYA MATRIC HR SEC SCHOOL,AMMAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"849", district:"3", Block:"270", label:"ST. COLUMBAN'S A I Hr Sec School", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"850", district:"32", Block:"363", label:"MMS, ARANMANAIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"851", district:"33", Block:"87", label:"GANCHIOR MATRICULATION HIGHER SECONDARY SCHOOL KARANAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"852", district:"4", Block:"52", label:"SUGUNA RIP V MATRICULATION  HIGHER.SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"853", district:"11", Block:"263", label:"EVANS MATRIC HR. SEC. SCHOOL, NGO COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"854", district:"30", Block:"229", label:"DEEPA MATRIC HIGHER SECONDARY SCHOOL,MUKKUDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"855", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, PALAYAKALAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"856", district:"11", Block:"354", label:"ST. FRANCIS ASSISI MATRIC SCHOOL- THITTUVILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"857", district:"33", Block:"260", label:"MALLIKARJUNA MATRICS, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"858", district:"8", Block:"37", label:"PUMS PERUMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"859", district:"20", Block:"266", label:"CRESCENT MATRICULATION HIGH SCHOOL, VELIPATTINAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"860", district:"32", Block:"363", label:"Kathiravan Matric. Hr. Sec. School,K.V.R NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"861", district:"3", Block:"304", label:"HOLY ANGELS ANGLO INDIAN HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"862", district:"32", Block:"32", label:"VENKATESWARA VIDYALAYA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"863", district:"32", Block:"363", label:"PUMS ATHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"864", district:"8", Block:"65", label:"SENGUNTHAR VIDHYALAYA MATRICULATION SCHOOL, ERODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"865", district:"33", Block:"338", label:"MUN. MS, NGGO COLONY, TRL", Sch_type:"Government", Category:"Middle School"},
    { key:"866", district:"32", Block:"154", label:"PUMS METRATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"867", district:"32", Block:"223", label:"PUMS RAYARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"868", district:"3", Block:"259", label:"VALLIAMMAL MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"869", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, SAIBANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"870", district:"21", Block:"409", label:"CSI VELLORE DIOCESE MATRIC HSS , SIPCOT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"871", district:"8", Block:"244", label:"PUMS, VEERANAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"872", district:"4", Block:"349", label:" CHINMAYA VIDHYALAYA CBSE SENIOR SECONDARY SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"873", district:"33", Block:"403", label:"ST THOMAS VIDYALAYAM MATRIC HSS  VANAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"874", district:"33", Block:"337", label:"SRI SATHYA SAI MAT. SCHOOL, PUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"875", district:"32", Block:"363", label:"VIDHYA VIKASHNI MATRIC.HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"876", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, S.GANAPATHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"877", district:"8", Block:"202", label:"KOMARASAMY GOUNDER MATRIC HSS, VETTAIYAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"878", district:"33", Block:"403", label:"RAJALAKSHMI VIDHYASHRAM MATRICULATION HIGHER SECONDARY SCHOOL,RAMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"879", district:"3", Block:"195", label:"MP ANAND MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"880", district:"27", Block:"49", label:"GAYATHRI MATRIC HSS - CHINNAMANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"881", district:"32", Block:"223", label:"PUMS ANUPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"882", district:"32", Block:"363", label:"JAI SARADHA MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"883", district:"8", Block:"20", label:"MANGALAM MATRICULATION SCHOOL, ANTHIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"884", district:"33", Block:"338", label:"MUN. MS, LAKSHMIPURAM, TRL", Sch_type:"Government", Category:"Middle School"},
    { key:"885", district:"8", Block:"185", label:"PUMS,VETTUKATUVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"886", district:"32", Block:"363", label:"PUMS,NEHRU NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"887", district:"32", Block:"250", label:"PUMS MADAPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"888", district:"33", Block:"260", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUZHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"889", district:"3", Block:"195", label:"MWA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"890", district:"32", Block:"32", label:"T.E.A PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL,AVINASHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"891", district:"14", Block:"328", label:"ST.MARY OF LEUCA MATRIC HR SEC SCHOOL, BASTIAN NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"892", district:"32", Block:"223", label:"PUMS PETHAMPOOCHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"893", district:"32", Block:"363", label:"PUMS, KUPPANDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"894", district:"8", Block:"37", label:"PUMS JAMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"895", district:"8", Block:"10", label:"PUMS ALANGARIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"896", district:"8", Block:"45", label:"PUMS, PERUNDURAI R.S", Sch_type:"Government", Category:"Middle School"},
    { key:"897", district:"11", Block:"3", label:"KUMARI MATRICULATION HR. SEC. SCHOOL, KOTTAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"898", district:"3", Block:"237", label:"EVERWIN MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"899", district:"8", Block:"71", label:"SRI VENKATESHWARA INTERNATIONALSCHOOL,OTHAKUTHIRAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"900", district:"32", Block:"32", label:"TAKKO MatricSCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"901", district:"32", Block:"363", label:"VIDYA MANDIR MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"902", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, SATHUMUGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"903", district:"32", Block:"73", label:"PUMS PONNERI", Sch_type:"Government", Category:"Middle School"},
    { key:"904", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, THIRUMANATHAM PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"905", district:"19", Block:"326", label:"M.R.P MATRIC SCHOOL, P.ALAGAPURI", Sch_type:"Un-aided", Category:"High School"},
    { key:"906", district:"33", Block:"403", label:"MUN.MID.SCH ORAKADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"907", district:"32", Block:"392", label:"JAYAM VIDHYA BHAVAN MATRIC HR.SEC.SCHOOL,VELLAKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"908", district:"33", Block:"289", label:"ST.JOSEPH MATRIC SCHOOL,MONDIAMMAN NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"909", district:"27", Block:"40", label:"SOWNDEESWARI MIDDLE SCHOOL - BODI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"910", district:"8", Block:"10", label:"PUMS  VEDIKARANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"911", district:"33", Block:"364", label:"PUMS - TIRUTTANI MAIN", Sch_type:"Government", Category:"Middle School"},
    { key:"912", district:"27", Block:"241", label:"TRIUMPH  MS -  THENKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"913", district:"33", Block:"260", label:"REVOOR PADMANABHA CHETTY'S MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"914", district:"32", Block:"363", label:"PUMS, THIRU KUMARAN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"915", district:"32", Block:"223", label:"LAKSHMI MILLS MIDDLE SCHOOL(AIDED)", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"916", district:"4", Block:"248", label:"P.L.M.School,POLLACHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"917", district:"33", Block:"260", label:"MUNICIPAL MIDDLE SCHOOL,  ALL INDIA RADIO NAGAR, ENNORE", Sch_type:"Government", Category:"Middle School"},
    { key:"918", district:"32", Block:"223", label:"ST. JOSEPH'S MIDDLE SCHOOL PALLAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"919", district:"33", Block:"403", label:"ST ANNAL MS,PORUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"920", district:"32", Block:"223", label:"PUMS MADHESWARANNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"921", district:"32", Block:"154", label:"PUMS,POLARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"922", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL,  PUDHUKUYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"923", district:"32", Block:"363", label:"VEERA SIVAJI VIDHYALAYA MATRIC HR .SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"924", district:"8", Block:"128", label:"RAMALINGAM A.M.SCHOOL,KILAMBADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"925", district:"33", Block:"87", label:"VISHWAKSENA VIDYA VIKAS MATRIC HIGHER SECONDARY SCHOOL, POLLIVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"926", district:"3", Block:"259", label:"ALLWYN MATRIC HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"927", district:"27", Block:"49", label:"NALLI MATRIC SCHOOL - CHINNAMANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"928", district:"20", Block:"169", label:"NATIONAL ACADEMY MONTESSORI MATRIC  HIGHER SECONDARY SCHOOL, PATTANAMKATHAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"929", district:"11", Block:"355", label:"VICTORY MATRIC HIGHER SECONDARY SCHOOL,MYLODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"930", district:"20", Block:"335", label:"ISLAMIC MODEL MATRICULATION HIGHER SECONDARY SCHOOL, THONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"931", district:"8", Block:"185", label:"PUMS, A.UNJAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"932", district:"19", Block:"22", label:"SELECTION MATRIC HR. SEC. SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"933", district:"20", Block:"169", label:"KALAIVANI MATRICULATION SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"934", district:"28", Block:"351", label:"CARPENTER STREET MS THOOTHUKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"935", district:"8", Block:"20", label:"GTRMS KAKAYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"936", district:"32", Block:"190", label:"PUMS ARIKKARANVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"937", district:"33", Block:"261", label:"PUMS,CHANDRAVILASAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"938", district:"32", Block:"223", label:"PUMS AMMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"939", district:"36", Block:"394", label:"GGHSS EVRN MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"940", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, KONDAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"941", district:"3", Block:"270", label:"ST FRANCIS XAVIER AI HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"942", district:"20", Block:"266", label:"BUKHARIYA MATRICULATION HIGHER SECONDARY SCHOOL, DEVIPATTINAM SOUTH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"943", district:"8", Block:"20", label:"PUMS OSAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"944", district:"3", Block:"259", label:"DANIEL THOMAS MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"945", district:"3", Block:"62", label:"PN DHAWAN ADARSH VIDYALAYA MAT HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"946", district:"8", Block:"37", label:"PUMS, PERAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"947", district:"32", Block:"32", label:"CAMBRIDGE MATRIC PANTHAMPALAYM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"948", district:"14", Block:"160", label:"SRI SARADA VIDYAVANAM MATRIC GIRLS HR SEC SCHOOL, SIMMAKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"949", district:"19", Block:"405", label:"PARUMALA ST.GREGORIOS MATRIC  SCHOOL, MATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"950", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"951", district:"33", Block:"338", label:"GNANA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"952", district:"32", Block:"73", label:"PUMS SOMAVARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"953", district:"8", Block:"185", label:"PUMS,PONNATHA VALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"954", district:"32", Block:"363", label:"SUBBIAH MATRIC HR.SEC.SCHOOL, TIRUPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"955", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL,  VADUGAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"956", district:"32", Block:"363", label:"SUBASH MATRIC HSS, MURUGAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"957", district:"32", Block:"363", label:"PUMS, PALAVANJIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"958", district:"21", Block:"210", label:"SRI SARASWATHI MATRIC HSS , REDDIVALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"959", district:"8", Block:"310", label:"PUMS  METTALAVADY", Sch_type:"Government", Category:"Middle School"},
    { key:"960", district:"32", Block:"363", label:"KIDS CLUB MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"961", district:"8", Block:"10", label:"PUMS CHITTHAR", Sch_type:"Government", Category:"Middle School"},
    { key:"962", district:"8", Block:"37", label:"MMS VARNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"963", district:"8", Block:"71", label:"KAMBAN KALVI NILAYAM MATRIC. HS SCHOOL, GOBI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"964", district:"33", Block:"184", label:"VENKATESWARA VIDYALAYA  MS,NANDIAMBAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"965", district:"33", Block:"289", label:"ELITE MAT.HSS, M.A.NAGAR, REDHILLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"966", district:"8", Block:"37", label:"PUMS BOMMANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"967", district:"8", Block:"65", label:"U.R.C. PALANIAMMAL MATRICULATION HSS, THINDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"968", district:"32", Block:"223", label:"PUMS KARAIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"969", district:"33", Block:"260", label:"VEERA SAVARKAR NETHAJI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"970", district:"30", Block:"46", label:"ST. ANTONY'S MATRICULATION HSS, KALLIDAIKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"971", district:"33", Block:"260", label:"ILAHI MATRICULATION HIGH SCHOOL,REDHILLS", Sch_type:"Un-aided", Category:"High School"},
    { key:"972", district:"8", Block:"128", label:"PUMS,MALAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"973", district:"20", Block:"266", label:"HOUSING BOARD H.O.W.A.MATRICULATION HIGH SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"974", district:"32", Block:"154", label:"PUMS MADATHUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"975", district:"3", Block:"2", label:"INFANT JESUS MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"976", district:"3", Block:"259", label:"JGVV MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"977", district:"33", Block:"403", label:"FAITH HOME MID SCH,PORUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"978", district:"33", Block:"403", label:"NEW CENTURY MATRIC.HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"979", district:"8", Block:"20", label:"PUMS SANTHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"980", district:"8", Block:"244", label:"KONGU VELLALAR MATRIC HIGHER SECONDARY SCHOOL, PERUNDURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"981", district:"8", Block:"185", label:"PUMS CHILLANGATTUPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"982", district:"8", Block:"20", label:"MANGALAM CENTRAL SCHOOL, ANTHIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"983", district:"19", Block:"25", label:"SASTHAA MATRICULATION SCHOOL, KEELANILAIKKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"984", district:"20", Block:"266", label:"LOUIS LEVAIL MATRICULATION SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"985", district:"33", Block:"261", label:"PUMS,CHELLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"986", district:"8", Block:"65", label:"VELALAR VIDYALAYAA SENIOR SECONDARY SCHOOL,THINDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"987", district:"8", Block:"37", label:"MMS BHAVANI(EAST)", Sch_type:"Government", Category:"Middle School"},
    { key:"988", district:"3", Block:"259", label:"GOOD HOPE MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"989", district:"24", Block:"388", label:"SDA MATRIC HR SEC SCHOOL,T.N.PUTHUKUDI,PULIANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"990", district:"32", Block:"190", label:"PUMS M.KARUPPANVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"991", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL VAYALANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"992", district:"20", Block:"86", label:"BANU MATRIC HIGHER SECONDARY SCHOOL, SIKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"993", district:"8", Block:"244", label:"PUMS, PERIYAVEERASANGILI", Sch_type:"Government", Category:"Middle School"},
    { key:"994", district:"14", Block:"159", label:"SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, MAHABOOBPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"995", district:"32", Block:"371", label:"MMS KANAKKAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"996", district:"11", Block:"124", label:"SEVENTH DAY ADVENTIST MATRIC HIGH SCHOOL VIRICODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"997", district:"11", Block:"3", label:"SNM HINDU VIDYALAYA MATRIC HSS, KRISHNANCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"998", district:"8", Block:"65", label:"PUMS MANAKKATUR", Sch_type:"Government", Category:"Middle School"},
    { key:"999", district:"13", Block:"78", label:"CSI CHRIST MATRIC. HSS HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1000", district:"29", Block:"367", label:"Mannar Memorial Mat. School, Col. Off. Road.", Sch_type:"Un-aided", Category:"High School"},
    { key:"1001", district:"32", Block:"371", label:"R.V.G. MATRIC HSS KURICHIKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1002", district:"32", Block:"371", label:"R K R GRKS MAT HSS, PERIYAKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1003", district:"27", Block:"241", label:"HINDU MS - DEVADANAPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1004", district:"8", Block:"10", label:"PUMS KUNDHUBAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1005", district:"33", Block:"260", label:"VICTORY MATRIC, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1006", district:"32", Block:"378", label:"SRI KUMARAN MATRICULATION HIGHER SECONDARY SCHOOL, CHENGAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1007", district:"8", Block:"202", label:"KUMUTHA MATRIC HIGHER SEC SCHOOL, NAMBIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1008", district:"11", Block:"191", label:"ST. ANTONY'S MATRIC HIGHER SECONDARY SCHOOL, MANAVILA KIRATHOOR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1009", district:"20", Block:"231", label:"DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1010", district:"14", Block:"158", label:"SARACENS MATRIC HR SEC SCHOOL, MELAMADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1011", district:"32", Block:"363", label:"WISDOM MHSS,,IDUVAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1012", district:"28", Block:"351", label:"STAR MATRIC HSS RENGANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1013", district:"32", Block:"223", label:"TELC MS EAST PALLADAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1014", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, NANJAPPA GOUNEN PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1015", district:"20", Block:"266", label:"INFANT JESUS MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1016", district:"27", Block:"194", label:"PUMS - PONNAMMALPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1017", district:"35", Block:"334", label:"UAMHSS ST.ANTONY'S MATRIC,TTP", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1018", district:"24", Block:"307", label:"BHARATH MONTESSORI MATRIC HR SEC SCHOOL, ILANJI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1019", district:"33", Block:"364", label:"ST MARY'S MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1020", district:"8", Block:"37", label:"PUMS SIRAIMEETANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1021", district:"4", Block:"245", label:"CMS VIDYA MANDIR MATRIC HR SEC SCHOOL, MANIAKARAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1022", district:"32", Block:"363", label:"PUMS ,A CHETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1023", district:"8", Block:"71", label:"MMS, (TOWN) GOBI", Sch_type:"Government", Category:"Middle School"},
    { key:"1024", district:"32", Block:"363", label:"PUMS,T.MANNARAI,TIRUPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1025", district:"20", Block:"266", label:"SYED AMMAL MATRIC (BOYS) HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1026", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, KANAKKAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1027", district:"33", Block:"76", label:"PUMS PUDUVOYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1028", district:"8", Block:"20", label:"PUMS A.SEMPULICHAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1029", district:"20", Block:"169", label:"NABEESA AMMAL MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1030", district:"32", Block:"223", label:"PUMS KUNNANGALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1031", district:"33", Block:"338", label:"SRI RAM VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1032", district:"33", Block:"403", label:"PRIYA MATRICULATION HIGHER SECONDARY SCHOOL, MUGAPAIR WEST", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1033", district:"32", Block:"223", label:"PUMS PARUVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1034", district:"20", Block:"188", label:"SRI KANNA MATRICULATION HIGHER SECONDARY SCHOOL, MUDUKULATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1035", district:"8", Block:"185", label:"PUMS,PUDHUVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"1036", district:"20", Block:"169", label:"THE INTERNATIONAL MATRICULATION SCHOOL, IRUMENI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1037", district:"30", Block:"92", label:"RANI MATRIC HR SEC SCHOOL ERUVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1038", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KALIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1039", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, KUNDIPOMMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1040", district:"32", Block:"223", label:"KANNAMMAL NATIONAL MATRIC HR SEC SCHOOL, PALLADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1041", district:"8", Block:"185", label:"PUMS,SENAPATHYPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1042", district:"32", Block:"223", label:"PUMS CHINNIYAGOUNDENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1043", district:"33", Block:"289", label:"PUMS,SOTHUPERUMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"1044", district:"33", Block:"253", label:"PUMS, ATTRAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1045", district:"32", Block:"378", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KUNNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"1046", district:"30", Block:"221", label:"TIME MATRIC HIGHER SECONDARY SCHOOL, ARAIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1047", district:"32", Block:"363", label:"MMS, KARUMARAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1048", district:"19", Block:"167", label:"CRESCENT MAT. SCHOOL, AMMAPATTINAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1049", district:"8", Block:"20", label:"PUMS KOOTHAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"1050", district:"19", Block:"108", label:"GUTHOOS MATRIC SCHOOL, KARAMBAKKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1051", district:"3", Block:"69", label:"ECI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1052", district:"19", Block:"22", label:"UMAIYALACHI MATRIC HR. SEC. SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1053", district:"3", Block:"259", label:"BENTINCK HR.SEC.SCHOOL FOR GIRLS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"1054", district:"27", Block:"194", label:"PUMS - KOVILPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1055", district:"32", Block:"73", label:"PUMS V.LINGAMANAICKENPATTI PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1056", district:"32", Block:"223", label:"PUMS SEDAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1057", district:"32", Block:"32", label:"SHANTHI VIDHYALAYA MAT CHEYUR ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1058", district:"19", Block:"251", label:"IDHAYA MATRICULATION HIGHER SECONDARY SCHOOL , KARAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1059", district:"8", Block:"38", label:"SRC MEMORIAL MATRIC. HR.SEC.SCHOOL,NALLUR(PO),PULIAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1060", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL,   KONDAPPANAIKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1061", district:"33", Block:"338", label:"BHARATHIDASAN  MATRICULATION HIGHER SECONDARY SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1062", district:"32", Block:"154", label:"PUMS MADATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1063", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUTHUVALLIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1064", district:"4", Block:"245", label:"VASAVI VIDYALAYA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1065", district:"4", Block:"242", label:"PUMS EDAYARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1066", district:"32", Block:"392", label:"PUNITHA AMALA ANNAI MHSS,VELLAKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1067", district:"30", Block:"385", label:"KINGS MAT. HSS, PUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1068", district:"24", Block:"388", label:"TDTA MS, T.N. PUTHUGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1069", district:"33", Block:"364", label:"SRI RAJA RAJESWARI MATRICULATION SCHOOL MELKASAWARAJAPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"1070", district:"33", Block:"403", label:"JANET MATRIC HSS, THIRUVERKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1071", district:"8", Block:"37", label:"SSV MHSS SANKARAGOUNDANPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1072", district:"8", Block:"128", label:"PUMS.MURUGAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1073", district:"33", Block:"403", label:"GK SHETTY VIV VID JR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1074", district:"32", Block:"145", label:"PUMS GOUNDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1075", district:"24", Block:"89", label:"GHS, POTTALPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"1076", district:"32", Block:"104", label:"OXFORD MATRICULATION SCHOOL, NATHAKKADAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1077", district:"4", Block:"242", label:"EXCEL MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1078", district:"32", Block:"363", label:"PUMS, BHARATHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1079", district:"24", Block:"89", label:"Adarsh Vidyalaya Matric. Hr, Sec. School, Vasagiri nagar, Kadayam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1080", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1081", district:"20", Block:"169", label:"RAJA MATRIC HIGHER SECONDARY SCHOOL, KUNJARVALASAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1082", district:"13", Block:"139", label:"DON BOSCO MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1083", district:"33", Block:"403", label:"T.I. MATRICULATION HIGHER SECONDARY SCHOOL AMBATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1084", district:"33", Block:"252", label:"JOY BELL MATRICULATION SCHOOL ,POONAMALLEE", Sch_type:"Un-aided", Category:"High School"},
    { key:"1085", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, RAMAPAYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1086", district:"30", Block:"385", label:"TDTA MS PERPILANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1087", district:"8", Block:"37", label:"ADHARSH VIDHYALAYA MHSS PARUVACHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1088", district:"28", Block:"320", label:"TNDTA MIDDLE SCHOOL VALLIVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1089", district:"30", Block:"385", label:"RVR NURSERY & PRIMARY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1090", district:"33", Block:"76", label:"PUMS SIRUPUZHALPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1091", district:"30", Block:"46", label:"RC MS CHERANMAHADEVI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1092", district:"4", Block:"245", label:"PRESENTATION CONVENT MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1093", district:"33", Block:"76", label:"PUMS PERIYAOBULAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1094", district:"8", Block:"128", label:"THAMARAI MATRIC HSS THAMARAIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1095", district:"33", Block:"87", label:"PUMS THIRUMANIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1096", district:"20", Block:"169", label:"HOLY ISLAND LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL, RAMESWARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1097", district:"33", Block:"64", label:"PUMS, SOOLAIMENI", Sch_type:"Government", Category:"Middle School"},
    { key:"1098", district:"32", Block:"378", label:"GHS, CHENGAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"1099", district:"33", Block:"76", label:"PUMS NELVOY", Sch_type:"Government", Category:"Middle School"},
    { key:"1100", district:"28", Block:"297", label:"PUMS VELLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1101", district:"33", Block:"261", label:"PUMS,GOPALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1102", district:"28", Block:"297", label:"PUMS ESAVANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1103", district:"32", Block:"363", label:"MMS.KADERPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1104", district:"32", Block:"145", label:"PUMS PUTHURUTHRAVATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"1105", district:"19", Block:"255", label:"S.F.S. MATRIC HR.SEC.SCHOOL,PUDUKKOTTAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1106", district:"32", Block:"223", label:"PUMS K.AYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1107", district:"32", Block:"154", label:"PUMS SAMARAYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1108", district:"33", Block:"253", label:"PUMS, ODAPPAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1109", district:"32", Block:"223", label:"PUMS KALLAMPALAYAM PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1110", district:"32", Block:"363", label:"MMS,PALAYANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1111", district:"27", Block:"194", label:"PUMS - KAMANKALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1112", district:"24", Block:"388", label:"PARAMANANTHA MS, PULIANGUDI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"1113", district:"20", Block:"86", label:"V.V.S.M. NATIONAL ACADEMY MATRIC HIGHER SECONDARY SCHOOL, SAYALGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1114", district:"30", Block:"92", label:"KAILASAPATHI HINDU MS SINGIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1115", district:"19", Block:"22", label:"ST. JOHN'S MATRIC HR. SEC. SCHOOL, RETHINAKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1116", district:"20", Block:"231", label:"BHARATHIYAR MIDDLE SCHOOL, PARAMAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1117", district:"33", Block:"403", label:"LITTLE HOLY ANGELS M.S,KORATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1118", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, EKKATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1119", district:"23", Block:"79", label:"PUMS NENJATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1120", district:"4", Block:"279", label:"LITTLE FLOWER MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1121", district:"30", Block:"92", label:"HINDU MS DEVANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1122", district:"33", Block:"337", label:"PUMS, HARICHANDRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1123", district:"33", Block:"364", label:"PUMS - KASINATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1124", district:"4", Block:"247", label:"PANCHAYAT UNION MIDDLE SCHOOL ERIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1125", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, NEELAGOUNDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1126", district:"21", Block:"408", label:"MANGALAMBAL AIDED MS WALAJAH", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1127", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL PARIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1128", district:"33", Block:"364", label:"PUMS - MGR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1129", district:"32", Block:"363", label:"PUMS, SIDCO", Sch_type:"Government", Category:"Middle School"},
    { key:"1130", district:"30", Block:"385", label:"NEHRU NATIONAL MS VADAKANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1131", district:"33", Block:"184", label:"PUMS, NEITHAVOYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1132", district:"33", Block:"338", label:"PUMS, PERUMALPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"1133", district:"32", Block:"190", label:"PUMS NATHAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1134", district:"33", Block:"338", label:"SREE MAHALAKSHMI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1135", district:"33", Block:"76", label:"PUMS KURUVATTUCHERRI", Sch_type:"Government", Category:"Middle School"},
    { key:"1136", district:"32", Block:"73", label:"PUMS MURUNGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1137", district:"28", Block:"320", label:"PUMS KOMBUTHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1138", district:"33", Block:"364", label:"PUMS - MADDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1139", district:"33", Block:"260", label:"ST JOHN'S MATRICULATION SCHOOL ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1140", district:"33", Block:"87", label:"PUMS POLIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1141", district:"27", Block:"194", label:"PUMS - VALIPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1142", district:"33", Block:"225", label:"PUMS, KEELAPUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"1143", district:"33", Block:"260", label:"MUN.MS,TSM NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1144", district:"33", Block:"76", label:"PUMS NETHAJI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1145", district:"32", Block:"190", label:"PUMS KURNAICKALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1146", district:"32", Block:"145", label:"PUMS MANURPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1147", district:"33", Block:"225", label:"ANM MS, KONEDAMPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1148", district:"32", Block:"363", label:"NATIONAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1149", district:"3", Block:"368", label:"CAMBRIDGE MAT HS", Sch_type:"Un-aided", Category:"High School"},
    { key:"1150", district:"33", Block:"76", label:"PUMS KANNAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1151", district:"32", Block:"145", label:"PUMS MUTHALIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1152", district:"33", Block:"260", label:"St.JOSEPH'S ACADEMY MATRICULATION HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1153", district:"33", Block:"252", label:"OXFORD MATRICULATION  HR SEC SCHOOL, THIRUNINRAVUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1154", district:"22", Block:"273", label:"ST. VINCENT PALLOTI MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1155", district:"32", Block:"223", label:"PUMS PALLADAM- WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"1156", district:"33", Block:"403", label:"SETHU BHASKARA MATRIC HR SEC SCH KALLIKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1157", district:"8", Block:"65", label:"C.S ACADEMY, KAVANDACHIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1158", district:"32", Block:"363", label:"VIKAS VIDYALAYA MHSS,KOOLIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1159", district:"33", Block:"403", label:"ST PAULS HSS, KARAMPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1160", district:"33", Block:"252", label:"ST CLARET MATRIC HIGHER SECONDARY SCHOOL NADUKUTHAGAI, THIRUNINRAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1161", district:"32", Block:"145", label:"PUMS VENGIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1162", district:"4", Block:"279", label:"ANNAI VELANKANNI MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1163", district:"14", Block:"306", label:"THAAI MATRIC HIGHER SECONDARY SCHOOL, T.VADIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1164", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, ALATHUKOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1165", district:"33", Block:"253", label:"PUMS, NAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1166", district:"20", Block:"169", label:"VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, RAMESWARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1167", district:"20", Block:"169", label:"MOHAMED DASTAGIR MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1168", district:"8", Block:"37", label:"OPTIMUS PUBLIC SCHOOL SEVAGOUNDANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1169", district:"20", Block:"169", label:"PURATCHI THALAIVAR DR MGR MATRIC HIGHER SECONDARY SCHOOL, UCHIPULI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1170", district:"32", Block:"392", label:"AANOOR VIDYALAYA MATRIC H.S.S, MUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1171", district:"8", Block:"244", label:"PUMS,PERUNDURAI SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"1172", district:"4", Block:"242", label:"SRI A.RAMASAMY MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1173", district:"8", Block:"10", label:"PUMS SEMBADAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1174", district:"32", Block:"363", label:"PUMS, PALLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1175", district:"32", Block:"363", label:"MMS,MUTHUPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1176", district:"8", Block:"281", label:"RANK INTERNATIONAL SCHOOL, ARIYAPPAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1177", district:"3", Block:"304", label:"LITTLE OXFORD MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1178", district:"8", Block:"71", label:"C.K.K. MATRIC HS SCHOOL, GOBI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1179", district:"20", Block:"169", label:"UNITED MATRICULATION HIGH SCHOOL, PATTANAMKATHAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"1180", district:"8", Block:"244", label:"SHRI VIDYAA BHAVAN MATRICULATION SCHOOL,PULAVARPALAYAM, VIJAYAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1181", district:"11", Block:"263", label:"SRI RAMJI MATRIC HSS, GANAPATHIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1182", district:"32", Block:"363", label:"PUMS, BHARATHIPURAM,", Sch_type:"Government", Category:"Middle School"},
    { key:"1183", district:"4", Block:"349", label:"CHINMAYA VIDYALAYA MATRICULATION HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1184", district:"29", Block:"343", label:"JAMAL MOHAMED COLLEGE AYESHA GIRLS MATRIC HR SEC SCHOOL,KAJANAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1185", district:"32", Block:"154", label:"PUMS SALARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1186", district:"8", Block:"10", label:"PUMS KOOTHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1187", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL,  ARASUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1188", district:"32", Block:"363", label:"AVP TRUST MATRICULATION HR SEC SCHOOL, GANDHI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1189", district:"33", Block:"289", label:"PUMS,AMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"1190", district:"33", Block:"225", label:"SAAISRI MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1191", district:"8", Block:"20", label:"PUMS SELLAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1192", district:"33", Block:"252", label:"ST JOHNS INTERNATIONAL RESIDENTIAL SCHOOL PAZHANJUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1193", district:"22", Block:"61", label:"WISDOM MATRICULATION HR. SEC. SCHOOL, IDAPPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1194", district:"32", Block:"145", label:"PUMS THALAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1195", district:"33", Block:"261", label:"PUMS,RAJANAGARAM WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"1196", district:"4", Block:"242", label:"PUMS KASTHURINAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1197", district:"32", Block:"250", label:"PUMS THUTHARIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1198", district:"32", Block:"363", label:"PUMS CHERANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"1199", district:"33", Block:"260", label:"GODSON MATRICULATION HSS , SURAPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1200", district:"24", Block:"6", label:"St. Joseph Mat. HSS, Alangulam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1201", district:"33", Block:"252", label:"GRACE PARK CONVENT MATRIC HR SEC SCHOOL, THIRUNINRAVUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1202", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL,  IRUTTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1203", district:"8", Block:"65", label:"BHARATHI VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL, THINDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1204", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, KARALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1205", district:"21", Block:"21", label:"BHARATHIDHASANAR MATRIC HR SEC SCHOOL, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1206", district:"11", Block:"354", label:"CAROL MATRICULATION HIGHER SECONDARY SCHOOL - THITTUVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1207", district:"19", Block:"405", label:"VIVEGA MATRIC HIGHER SECONDARY SCHOOL. VIRALIMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1208", district:"32", Block:"363", label:"SRI KALAIMAGAL VIDHYALAYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1209", district:"3", Block:"195", label:"BHARATH SR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1210", district:"20", Block:"86", label:"INDIAN MATRIC HIGHER SECONDARY SCHOOL, SIKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1211", district:"4", Block:"52", label:"SREE NARAYANA MISSION MHS SAIBABA COLONY", Sch_type:"Un-aided", Category:"High School"},
    { key:"1212", district:"36", Block:"105", label:"SUN MATRIC HSS , VALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1213", district:"33", Block:"253", label:"PUMS, ANANTHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"1214", district:"4", Block:"126", label:"PUMS SINGAIYANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1215", district:"28", Block:"320", label:"SRI RAMAIAH BHAGAVATHER MEMORIAL SENTHIL MURUGAN MIDDLE SCHOOL, TIRUCHENDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1216", district:"32", Block:"363", label:"SRI KAMATCHI AMMAN MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1217", district:"8", Block:"244", label:"PUMS,LAKSHMIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1218", district:"4", Block:"279", label:"ARM MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1219", district:"33", Block:"289", label:"PUMS,JANAPPAN CHATRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1220", district:"30", Block:"222", label:"ST.JOSEPH'S MATRICULATION SCHOOL SENTHILNAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1221", district:"17", Block:"165", label:"PUMS PARUTHIPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"1222", district:"32", Block:"371", label:"PUMS SELLAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1223", district:"3", Block:"259", label:"MOHAMED SATHAK MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1224", district:"32", Block:"363", label:"MMS GOLDEN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1225", district:"33", Block:"403", label:"CHRIST MATRIC HIGHER SECONDARY SCHOOL, PADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1226", district:"8", Block:"37", label:"PUMS MINNAVETTUVAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1227", district:"14", Block:"161", label:"XAVIER MATRIC HR SEC SCHOOL, IYER BUNGALOW", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1228", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, THOPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1229", district:"32", Block:"363", label:"PUMS CHINNANDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1230", district:"33", Block:"289", label:"PUMS, VELLIVOYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1231", district:"11", Block:"191", label:"SAHAYAMATHA MATRICULATION SCHOOL VAVARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1232", district:"4", Block:"107", label:"NATIONAL MATRIC  HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1233", district:"3", Block:"259", label:"PADMA SARANGAPANI MATRIC HR.SE.SCHOOL, VIRUGAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1234", district:"6", Block:"58", label:"SRI VIJAY VIDYALAYA GIRLS MHSS, GANDHI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1235", district:"33", Block:"364", label:"PUMS - B N KANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1236", district:"20", Block:"99", label:"KSHATRIYA NADAR MATRIC HIGHER SECONDARY SCHOOL.,KAMUTHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1237", district:"8", Block:"20", label:"PUMS KARATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1238", district:"8", Block:"37", label:"PUMS KADAIYAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1239", district:"4", Block:"52", label:"ST. THERESA GIRLS AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1240", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, KULLAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1241", district:"29", Block:"343", label:"AROCKIAMATHA MATRIC HR SEC SCHOOL, KARUMANDAPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1242", district:"33", Block:"260", label:"MUN. MIDDLE SCHOOL, KATHIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1243", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, THENEESWARANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1244", district:"33", Block:"64", label:"PUMS, PERAMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1245", district:"19", Block:"326", label:"SRI LAKSHMI NARAYANA MATRIC SCHOOL, KURUMBAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1246", district:"4", Block:"107", label:"AMBAL MATRIC. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1247", district:"33", Block:"289", label:"PUMS, THIRUNILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1248", district:"32", Block:"57", label:"SINDHU MATRIC HSS DHARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1249", district:"32", Block:"363", label:"PUMS SULTHANPET", Sch_type:"Government", Category:"Middle School"},
    { key:"1250", district:"13", Block:"139", label:"TRINITY MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1251", district:"4", Block:"52", label:"V.C.V SHISHU VIDYODAYA MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1252", district:"17", Block:"232", label:"KONGU MATRIC HSS VELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1253", district:"33", Block:"261", label:"ANM MS,AMMAIYARKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1254", district:"20", Block:"188", label:"KAMARAJAR MATRICULATION HIGHER SECONDARY SCHOOL, MUDUKULATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1255", district:"32", Block:"57", label:"VISHNU LAKSHMI MHSS DHARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1256", district:"19", Block:"33", label:"POPULAR MATRIC HR. SEC. SCHOOL MIMISAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1257", district:"33", Block:"64", label:"PUMS, SENNANKARANI", Sch_type:"Government", Category:"Middle School"},
    { key:"1258", district:"8", Block:"37", label:"PUMS SEVAGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1259", district:"8", Block:"38", label:"MAMAHARISHI EASWARAYA GURUKULAM MATRICULATION HR.SEC.SCHOOL, THABOVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1260", district:"8", Block:"20", label:"PUMS SETHUNAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1261", district:"17", Block:"85", label:"PUMS CHINNASOLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1262", district:"33", Block:"338", label:"PUMS, THANEERKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1263", district:"8", Block:"65", label:"SRI SARASWATHI VIDHYALAYA Mat.HSS,MAYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1264", district:"30", Block:"221", label:"SARATHA MHS SANKARNAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1265", district:"20", Block:"41", label:"R.K.SAMY MATRICULATION HIGH SCHOOL, SEYYALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1266", district:"21", Block:"116", label:"PUMS-PUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1267", district:"32", Block:"190", label:"PUMS MULANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1268", district:"32", Block:"363", label:"PUMS, ANDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1269", district:"8", Block:"37", label:"PUMS VAIRAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1270", district:"19", Block:"326", label:"ST.JOSEPH MATRIC SCHOOL, VIRACHILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1271", district:"8", Block:"65", label:"ASHRAM MATRICULATION HSS, KOLLAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1272", district:"8", Block:"71", label:"BHARATHI VIDHYALAYA  MATRIC HSS, GOBI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1273", district:"19", Block:"255", label:"GUTHOOS MATRIC.HR SECONDARY SCHOOL, PUDUKKOTTAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1274", district:"17", Block:"200", label:"PUMS ARIYAGOUNDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1275", district:"32", Block:"57", label:"PONNU MATRIC HSS DHARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1276", district:"11", Block:"191", label:"SREE KRISHNA HINDU VIDYALAYA M.H.S.S. KANCHINAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1277", district:"33", Block:"253", label:"PUMS, KUNNAVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1278", district:"11", Block:"124", label:"SVK MATRICULATION SCHOOL VIRICODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"1279", district:"33", Block:"289", label:"M.B.S.VIVEKANANDA VIDHYALAYA, ARANI NORTH", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1280", district:"33", Block:"76", label:"MADANLAL KHEMANI VIVEKANANDA VIDYALAYA , PETHIKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1281", district:"30", Block:"385", label:"ANNAI MATRIC. HR. SEC. SCHOOL , VALLIOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1282", district:"32", Block:"363", label:"PUMS, SIRUPOOLUVAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1283", district:"33", Block:"403", label:"PANCHAYAT UNION MIDDLE SCHOOL, NEW KANNIYAMMAN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1284", district:"8", Block:"10", label:"PUMS UNJAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1285", district:"24", Block:"307", label:"JOY MATRIC. HR. SEC, SCHOOL , VADAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1286", district:"11", Block:"341", label:"ST. THOMAS MARTHOMA MATRICULATION HIGHER SECONDARY SCHOOL, KOLLARAI, KULASEKHARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1287", district:"8", Block:"244", label:"PUMS, AYEEGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1288", district:"33", Block:"403", label:"PUMS, MELAPEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"1289", district:"8", Block:"37", label:"ST.MARY'S MHSS KURUPPANAIKKENPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1290", district:"33", Block:"403", label:"SRI VIDHYA NIKETHAN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1291", district:"36", Block:"84", label:"SRI VIDHYALAKSHMI MATRIC HSS, CHENNANGKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1292", district:"28", Block:"320", label:"ST.MARY'S MIDDLE SCHOOL, ADAIKALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1293", district:"33", Block:"403", label:"MAHARISHI SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1294", district:"14", Block:"160", label:"ROSE MATRIC HR SEC SCHOOL, KANSAMETTU STREET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1295", district:"17", Block:"232", label:"PUMS JANGAMANAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1296", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOMBAITHOTTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1297", district:"32", Block:"223", label:"PARK MATRIC HR SEC SCHOOL, CHINNAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1298", district:"32", Block:"223", label:"BLUE BIRD MATRIC HR SEC SCHOOL, PALLADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1299", district:"33", Block:"260", label:"PADMA SEENIVASAN MEMORIAL VIDYALAYA MATRICULATION HIGH SCHOOL, MADHAVARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1300", district:"8", Block:"65", label:"CMS, SKC ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"1301", district:"8", Block:"37", label:"PANCHAYAT UNION MIDDLE SCHOOL, SEMBOOTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1302", district:"8", Block:"65", label:"KONGU NATIONAL MATRICULATION HSS, NANJANAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1303", district:"8", Block:"65", label:"CARMEL MATRICULATION HSS, KOLLAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1304", district:"8", Block:"128", label:"PUMS.VALLIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1305", district:"27", Block:"319", label:"T.K.S. MATRIC HR. SEC. SCHOOL - THENI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1306", district:"33", Block:"403", label:"SRI VENKATESHWARA MATRIC HIGHER SECONDARY SCHOOL,THIRUMULLAIVOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1307", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, PASUVAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1308", district:"11", Block:"355", label:"ST.MICHAEL'S MATRIC HIGHER SECONDARY SCHOOL,KATTATHURAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1309", district:"33", Block:"260", label:"HOLY CHILD MATRIC HR SEC SCHOOL,REDHILLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1310", district:"8", Block:"20", label:"VISHWESVARAIYAH MHSS THOPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1311", district:"27", Block:"241", label:"PUMS - E.PUTHUKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1312", district:"33", Block:"253", label:"CHENNAI VIDYALAYA MATRICULATION  SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1313", district:"3", Block:"195", label:"SRI SANKARA VIDYASHRAMAM MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1314", district:"32", Block:"145", label:"SWAMY VIVEKANANDA VIDYALAYA MAT.HR.SEC.SCHOOL, KODUVAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1315", district:"8", Block:"128", label:"PUMS,NALLASELLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1316", district:"8", Block:"37", label:"PUMS THIPPICHETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1317", district:"8", Block:"71", label:"PUMS, PERIYAKORAVAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1318", district:"17", Block:"285", label:"PUMS PACHUDAYAMPATTI PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1319", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, L.SANARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1320", district:"4", Block:"245", label:"ASHRAM MATRICULATION HR SEC SCHOOL KOVAIPUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1321", district:"4", Block:"349", label:"ISHA VIDHYA MATRIC HIGHER SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1322", district:"20", Block:"86", label:"SRI SANGEEDHA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, K.KARUNKULAM, KADALADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1323", district:"33", Block:"403", label:"GRACE MATRICULATION HIGHER SECONDARY SCHOOL , KARAMBAKKAM,PORUR,CHENNAI-600116", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1324", district:"20", Block:"231", label:"YADAVA MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1325", district:"8", Block:"37", label:"TNK MHSS KAVINDAPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1326", district:"8", Block:"37", label:"IDEAL MHS", Sch_type:"Un-aided", Category:"High School"},
    { key:"1327", district:"33", Block:"225", label:"GOVT.MS, KUMARAJUPET", Sch_type:"Government", Category:"Middle School"},
    { key:"1328", district:"32", Block:"223", label:"PUMS EAST PALLADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1329", district:"28", Block:"8", label:"MEERANIA MS ALAGIAMANAVALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1330", district:"8", Block:"71", label:"SARATHA MATRIC HSS, MODACHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1331", district:"11", Block:"3", label:"PONJESLY PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL - NAGERCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1332", district:"33", Block:"338", label:"CALAVALA CUNNAN CHETTY''S HINDU MATRICULATION HIGHER SECONDARY SCHOOL, KAKKALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1333", district:"8", Block:"20", label:"PUMS ONTHANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1334", district:"10", Block:"146", label:"Little Flower MHSS Moulivakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1335", district:"33", Block:"364", label:"PUMS - VEERA KAVERI RAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1336", district:"4", Block:"52", label:"CMS MNG STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"1337", district:"3", Block:"195", label:"MANUELMONY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1338", district:"33", Block:"261", label:"PUMS,C G N KANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1339", district:"32", Block:"363", label:"PUMS, CHANDRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1340", district:"33", Block:"252", label:"SRI KRISHNA MOOLCHAND VIVEKANANDHA VIDYALAYA MHSS THIRUNINRAVUR.", Sch_type:"Un-aided", Category:"High School"},
    { key:"1341", district:"8", Block:"37", label:"PUMS CHINNAPERICHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1342", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, ERVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"1343", district:"33", Block:"403", label:"GREEN VALLEY MAT MUGAPAIR WEST", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1344", district:"3", Block:"368", label:"THE CLARKE SCHOOL FOR THE DEAF", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1345", district:"33", Block:"64", label:"VICTORIA MATRIC HIGHER SECONDARY SCHOOL, PERIYAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1346", district:"27", Block:"319", label:"BAGYA MATRICULATION SCHOOL - ALLINAGARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1347", district:"8", Block:"65", label:"PUMS SENGUNTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1348", district:"8", Block:"65", label:"NANDHA CENTRAL SCHOOL, KOORAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1349", district:"20", Block:"266", label:"SRI KUMARAN MIDDLE SCHOOL , RAMANATHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1350", district:"24", Block:"91", label:"MASOOD THAIKA MIDDLE SCHOOL KADAYANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1351", district:"35", Block:"173", label:"UAMHS ST.JOSEPH MATRIC.HIGH SCHOOL,MANNARGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1352", district:"8", Block:"65", label:"PUMS, SUKKIRAMANIAVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"1353", district:"19", Block:"33", label:"ST.JOSEPHS MATRIC SCHOOL AVUDAIYARKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1354", district:"29", Block:"343", label:"CHELLAMMAL MATRIC. BOYS HR SEC SCHOOL, VENGUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1355", district:"4", Block:"302", label:"M. NANJAPPA CHETTIAR MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1356", district:"12", Block:"23", label:"CRESCENT MATRIC HSS,PALLAPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1357", district:"8", Block:"20", label:"GTRMS SOLAKANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1358", district:"4", Block:"242", label:"AVB MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1359", district:"3", Block:"62", label:"AGARWAL VIDYALAYA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1360", district:"32", Block:"363", label:"MMS,MANNARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1361", district:"33", Block:"403", label:"MR MAT HSS, NERKUNDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1362", district:"20", Block:"231", label:"V.O.C.MATRICULATION HIGHER SECONDARY SCHOOL, N.VALAYANENTHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1363", district:"35", Block:"193", label:"GHSS EDAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"1364", district:"14", Block:"324", label:"ST FRANCIS MATRIC HR SEC SCHOOL, THIRUMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1365", district:"33", Block:"64", label:"DAYSPRING MATRICULATION HIGHER SECONDARY SCHOOL, VANIANCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1366", district:"11", Block:"263", label:"BELFIELD MATRIC HSS, ASARIPALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1367", district:"8", Block:"65", label:"S.V.N. MATRICULATION HSS, CHITTODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1368", district:"34", Block:"238", label:"SURAJ MATRICULATION HR SEC SCHOOL MELATHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1369", district:"19", Block:"339", label:"ABIRAMI MATRICULATION SCHOOL ALANGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1370", district:"25", Block:"314", label:"MAXWELL MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1371", district:"33", Block:"403", label:"AIYEESHA RAZZAC MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1372", district:"8", Block:"37", label:"PUMS  ORICHERIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1373", district:"8", Block:"65", label:"GEETHAANJALI ALL INDIA SR.SEC. SCHOOL, THINDAL,ERODE-638012", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1374", district:"32", Block:"145", label:"PUMS EDAYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1375", district:"14", Block:"158", label:"MRR.MAVMM MATRIC HR SEC SCHOOL, SATHAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1376", district:"36", Block:"393", label:"HOLY CROSS MATRIC HSS, SATHUVACHARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1377", district:"4", Block:"349", label:"PREMIER VIDYAA VIKASH MATRIC HIGHER SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1378", district:"27", Block:"241", label:"SRI VALLI VARATHARAJ MAT HSS  - VARATHARAJ NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1379", district:"8", Block:"65", label:"PUMS,JAVULI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1380", district:"22", Block:"68", label:"SWAMY VIVEKANANDA VIDYALAYA MATRIC SCHOOL, SENDARAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1381", district:"3", Block:"195", label:"SARASWATHY VENKATARAMAN MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1382", district:"8", Block:"71", label:"JAYARAM MUNCIPAL MIDDLE SCHOOL, GOBI", Sch_type:"Government", Category:"Middle School"},
    { key:"1383", district:"19", Block:"251", label:"AMALA ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, PON-PUDUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1384", district:"21", Block:"408", label:"GANGAADARAA MATRIC HSS, RANIPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1385", district:"20", Block:"231", label:"HARISH VARMA MATRICULATION HIGHER SECONDARY SCHOOL, ARIYANENDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1386", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, VANDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1387", district:"22", Block:"316", label:"SRI VIDHYA MANDIR MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1388", district:"33", Block:"337", label:"PUMS, LAKSHMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1389", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, ANAIKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1390", district:"17", Block:"132", label:"PUMS ARIYUR SOLAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"1391", district:"8", Block:"10", label:"ST. THERESA'S MATRICULATION SCHOOL, OLAGADAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1392", district:"8", Block:"65", label:"RAILWAY SENIOR SECONDARY SCHOOL(CBSE), RAILWAY COLONY,ERODE", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"1393", district:"34", Block:"365", label:"MARY MEMORIAL MATRIC TIRUVANNAMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1394", district:"17", Block:"186", label:"PUMS ARUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1395", district:"33", Block:"76", label:"MAHARISHI VID MANTHIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1396", district:"14", Block:"160", label:"CORPORATION MASATHIYAR GIRLS HR SEC SCHOOL, MANJANAKARA STREET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"1397", district:"27", Block:"194", label:"PUMS -  SOLAITHEVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1398", district:"32", Block:"363", label:"PUMS, VATTAKATTUPUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1399", district:"8", Block:"128", label:"PUMS, VALLANTHANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1400", district:"8", Block:"65", label:"THE B.V.B.SCHOOL, THERKUPALLAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1401", district:"8", Block:"20", label:"PUMS DHOLLI", Sch_type:"Government", Category:"Middle School"},
    { key:"1402", district:"3", Block:"304", label:"BABA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1403", district:"25", Block:"42", label:"SIR SIVASWAMI BALAVIDHYALAYA  MATRICULATION HIGHER SECONDARY SCHOOL -THIRUKKATTUPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1404", district:"19", Block:"67", label:"VIDYA VIKAS MATRIC HIGHER SEC SCHOOL, PUDUNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1405", district:"4", Block:"279", label:"G.RAMASWAMY NAIDU MATRIC.HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1406", district:"4", Block:"245", label:"SRI SOWDESWARI VIDYALAYA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1407", district:"33", Block:"403", label:"SRI VISA MAT,MADURAVOYAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1408", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, KADAGANALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"1409", district:"27", Block:"319", label:"BHARATHI VIDYA MANTHIR MATRIC HSS - NAGALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1410", district:"11", Block:"3", label:"SACRED HEART MATRIC HR SEC SCHOOL- AZHAGAPPAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1411", district:"24", Block:"119", label:"RAJA MS-KALUNEERKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1412", district:"4", Block:"52", label:"ST. JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL,COIMBATORE-641018", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1413", district:"33", Block:"252", label:"AMUTHA MATRICULATION SCHOOL, KRISHNANAGAR, THIRUNINRAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1414", district:"17", Block:"132", label:"PUMS ARIYUR KIZHAKKU VALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"1415", district:"27", Block:"49", label:"PUMS - MUTHULAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1416", district:"13", Block:"78", label:"SRI VIJAY VIDYALAYA MHSS HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1417", district:"4", Block:"302", label:"PUM SCHOOL, UTHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1418", district:"33", Block:"184", label:"S.DYANANTHA MS,POLATCHIAMMANKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1419", district:"20", Block:"332", label:"HAMEEDIAH MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1420", district:"33", Block:"225", label:"PUMS, KOTHAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1421", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, PILIAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1422", district:"20", Block:"335", label:"AL-HILAL MATRICULATION HIGHER SECONDARY SCHOOL , THONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1423", district:"17", Block:"200", label:"PUMS NARAIKINARUPIRIVU", Sch_type:"Government", Category:"Middle School"},
    { key:"1424", district:"4", Block:"279", label:"CMS UPPILIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1425", district:"32", Block:"363", label:"PALANIAMMALMGHSS, TIRUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"1426", district:"8", Block:"45", label:"PANCHAYAT UNION MIDDLE SCHOOL,MELAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1427", district:"20", Block:"266", label:"SCHWARTZ MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1428", district:"8", Block:"37", label:"MMS KAMARAJ NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1429", district:"33", Block:"184", label:"ADARSH MAT HR.SEC, THIRUVAYARPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1430", district:"27", Block:"319", label:"MADURAI CEOA MATRICULATION  HR SEC SCHOOL -  PALANICHETTIPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1431", district:"33", Block:"76", label:"R M K MAT HS KAVARAIPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1432", district:"3", Block:"368", label:"EMMA FOULGER MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1433", district:"27", Block:"49", label:"PUMS - AYYAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1434", district:"8", Block:"65", label:"PUMS,PERUMALMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1435", district:"8", Block:"65", label:"PANCHAYAT UNION MIDDLE SCHOOL, ODAKKATTUVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"1436", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, GANDHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1437", district:"13", Block:"139", label:"SRI VIJAY VIDYALAYA MHSS ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1438", district:"27", Block:"241", label:"ST.ANNES JC MATRICULATION HR. SEC.  SCHOOL -  PERIYAKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1439", district:"27", Block:"55", label:"NALANDHA MATRIC SCHOOL - CUMBUM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1440", district:"17", Block:"200", label:"PUMS CHINNA VARAGURCOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1441", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, VELLAKOILPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1442", district:"4", Block:"302", label:"KOVAI MARUTHAMALAI MATRICULATION SCHOOL.", Sch_type:"Un-aided", Category:"High School"},
    { key:"1443", district:"21", Block:"408", label:"OUR LADY'S MATRIC SCHOOL, RANIPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"1444", district:"36", Block:"74", label:"S.D.A MATRIC HSS, GUDIYATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1445", district:"32", Block:"363", label:"MMS. CHANDRAKAVI", Sch_type:"Government", Category:"Middle School"},
    { key:"1446", district:"14", Block:"183", label:"SUBRAMANIA BHARATHI MATRIC HR SEC SCHOOL, MELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1447", district:"13", Block:"35", label:"ST.PAULS MATRIC. BARGUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1448", district:"17", Block:"232", label:"PUMS SULTHANPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1449", district:"33", Block:"76", label:"ST MARYS MAT SC - ARAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1450", district:"11", Block:"341", label:"MOUNT CARMEL MATRIC HIGHER SECONDARY SCHOOL KOLVEL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1451", district:"20", Block:"169", label:"KING OF KINGS MATRIC HIGHER SECONDARY SCHOOL, MEYYAMPULI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1452", district:"3", Block:"259", label:"SBOA (M) HSS ANNA NAGAR WEST", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1453", district:"17", Block:"165", label:"PUMS KALIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1454", district:"33", Block:"403", label:"Sathyalok Matriculation Hr. Sec.  School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1455", district:"25", Block:"228", label:"JACK & JILL MATRIC HIGH SCHOOL - KABISTHALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1456", district:"8", Block:"310", label:"PUMS MALLANGULI", Sch_type:"Government", Category:"Middle School"},
    { key:"1457", district:"3", Block:"270", label:"MODEL MATRICULATION HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1458", district:"32", Block:"363", label:"PUMS AGRAHARAPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1459", district:"8", Block:"65", label:"PUMS, ASHOKAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1460", district:"21", Block:"408", label:"SRI VENKATESWARA MATRIC SCHOOL, WALAJAPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"1461", district:"3", Block:"2", label:"ST FRANCIS SAVIO MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1462", district:"17", Block:"201", label:"PUMS MARUTHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1463", district:"20", Block:"169", label:"NAZIYA MATRIC HIGHER SECONDARY SCHOOL, ALAGANKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1464", district:"24", Block:"119", label:"ST ASSISI MATRIC HR.SEC.SCH. PAVOORCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1465", district:"20", Block:"332", label:"ISLAMIAH MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1466", district:"32", Block:"363", label:"PUMS KANCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1467", district:"19", Block:"108", label:"REENA MERCY MATRIC SCHOOL, KARAMBAKKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1468", district:"8", Block:"45", label:"PUMS,KAVUNDACHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1469", district:"20", Block:"86", label:"ROJA MATRIC HIGHER SECONDARY SCHOOL, SAYALGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1470", district:"3", Block:"270", label:"T.M.M.S SHUNMUGA SUNDARAM & A.P. SAMY MATRICULATION SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"1471", district:"17", Block:"397", label:"PUMS NACHIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1472", district:"31", Block:"362", label:"PUMS THALUGAN VATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1473", district:"19", Block:"22", label:"THAAYAGAM MATRIC HR. SEC. SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1474", district:"27", Block:"319", label:"SANTINIKETAN MATRIC HSS - MUTHUTHEVANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1475", district:"33", Block:"403", label:"MKM MAT SCHOOL PORUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1476", district:"32", Block:"371", label:"MMS PADDY BAZAAR STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"1477", district:"21", Block:"24", label:"HAKEEM MATRIC SCHOOL , MELVISHARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1478", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, MADHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1479", district:"19", Block:"22", label:"NATIONAL MATRIC HR SEC SCHOOL,ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1480", district:"11", Block:"149", label:"CARMEL JYOTHI MATRIC HIGHER SECONDARY SCHOOL, VELLIYAKULAM, COLACHEL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1481", district:"17", Block:"224", label:"PUMS ALAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1482", district:"4", Block:"242", label:"BHARATIYA VIDYA BHAVAN MATRIC HIGHER SECONDARY SCHOOL, AJJANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1483", district:"4", Block:"242", label:"PUMS KALAPPANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1484", district:"33", Block:"260", label:"PANCHAYAT UNION MIDDLE SCHOOL, SURAPET", Sch_type:"Government", Category:"Middle School"},
    { key:"1485", district:"3", Block:"69", label:"ST JOSEPH MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1486", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUTHARAIYAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1487", district:"27", Block:"49", label:"PUMS - THENPALANI", Sch_type:"Government", Category:"Middle School"},
    { key:"1488", district:"3", Block:"62", label:"VIDYODAYA GIRLS'HR.SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"1489", district:"28", Block:"8", label:"GDG MIDDLE SCHOOL ALWARTHIRUNAGARI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1490", district:"20", Block:"169", label:"NATIONAL ACADEMY MATRIC HIGHER SECONDARY SCHOOL, UCHIPULI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1491", district:"8", Block:"38", label:"AMMA MATRICULATION HIGHER SECONDARY SCHOOL, PUNJAI PULIAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1492", district:"33", Block:"261", label:"PUMS,R N KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1493", district:"23", Block:"79", label:"PUMS S.KARAIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"1494", district:"4", Block:"52", label:"KAMARAJAR MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1495", district:"4", Block:"279", label:"SRI RAMAKRISHNA MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1496", district:"17", Block:"232", label:"PUMS VAVIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1497", district:"31", Block:"103", label:"SRI VIJAY SHANTHI JAIN MATRIC HSS, THIRUPATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1498", district:"33", Block:"289", label:"PUMS,PANCHETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1499", district:"18", Block:"236", label:"ANNAI PARUVATHAMMAL MATRICULATION HIGH SCHOOL PERAMBALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1500", district:"17", Block:"186", label:"PUMS VALLIYAPPAMPATTIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1501", district:"14", Block:"158", label:"TAGORE VIDYALAYAM MATRIC HR SEC SCHOOL, SADASIVA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1502", district:"33", Block:"364", label:"TKVINAYAGAM MAT - TRT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1503", district:"25", Block:"313", label:" KAMALA SUBRAMANIAM MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1504", district:"33", Block:"260", label:"ST.ANTONY'S MATRIC HSS, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1505", district:"33", Block:"260", label:"SARATHI SARASWATHI MHSS, MATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1506", district:"33", Block:"403", label:"VAILANKANNI MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1507", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, JJ NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1508", district:"17", Block:"200", label:"PUMS VELLAKKALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1509", district:"17", Block:"285", label:"PUMS K.VELLALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1510", district:"33", Block:"403", label:"SBIOA MODEL MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1511", district:"3", Block:"237", label:"DHANISH MATRIC. HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1512", district:"8", Block:"37", label:"PUMS PALAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1513", district:"30", Block:"229", label:"BOO VIJESH MAT  MUKKUDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1514", district:"27", Block:"319", label:"PUMS - MUTHUDEVANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1515", district:"17", Block:"186", label:"PUMS THOLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1516", district:"17", Block:"285", label:"PUMS MALAIVEPPANKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1517", district:"30", Block:"174", label:"PUMS,PATHINALAMPERI", Sch_type:"Government", Category:"Middle School"},
    { key:"1518", district:"32", Block:"363", label:"MMS, KANGEYAMPALAYAM PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1519", district:"17", Block:"165", label:"AKV MATRICULATION HIGHER SECONDARY SCHOOL , SURIYAGOUNDAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1520", district:"19", Block:"326", label:"ST.JOSEPH MATRIC SCHOOL, THIRUMAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1521", district:"20", Block:"86", label:"ELITE MATRIC HIGHER SECONDARY SCHOOL, ERVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1522", district:"33", Block:"87", label:"PANCHAYATH UNION MIDDLE SCHOOL THODUKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"1523", district:"27", Block:"319", label:"HINDU MS - SEELAYAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1524", district:"20", Block:"169", label:"VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL, PAMBAN", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1525", district:"32", Block:"363", label:"BHARATHI KIDS KSHETHRALAYA SCHOOL, TIRUPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1526", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, MALLIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1527", district:"32", Block:"363", label:"SRI VASAVI VIDYALAYA MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1528", district:"17", Block:"200", label:"PUMS NAMAGIRIPETTAI-I", Sch_type:"Government", Category:"Middle School"},
    { key:"1529", district:"17", Block:"224", label:"PUMS ELANTHAKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1530", district:"17", Block:"254", label:"KAMARAAJAR MATRICULATION SCHOOL,THALAMBADY", Sch_type:"Un-aided", Category:"High School"},
    { key:"1531", district:"17", Block:"186", label:"PUMS LADDUVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"1532", district:"11", Block:"354", label:"ISLAMIC SCHOOL-THITTUVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1533", district:"27", Block:"375", label:"SRI KANNIKA PARAMESWARI MIDDLE SCHOOL - KOMBAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1534", district:"33", Block:"260", label:"MANIYAMMAI MATRIC SCHOOL, P.MEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"1535", district:"3", Block:"304", label:"JUSTICE BASHEER AHMD MEM.MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1536", district:"4", Block:"242", label:"VIDYA VIKASINI MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1537", district:"3", Block:"195", label:"CHETTINAD VIDYASHRAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1538", district:"33", Block:"364", label:"GMS - S AGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1539", district:"8", Block:"244", label:"PUMS,PERUNDURAI EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"1540", district:"32", Block:"363", label:"KONGU MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1541", district:"36", Block:"74", label:"PUMS MUNAFF DIBBO ( U )", Sch_type:"Government", Category:"Middle School"},
    { key:"1542", district:"3", Block:"259", label:"J.M.Matriculation Girls Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1543", district:"17", Block:"224", label:"PUMS THOTTIKARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1544", district:"13", Block:"78", label:"SRI SHARADA VIDYA MANDIR MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1545", district:"4", Block:"242", label:"BRINDAVAN VIDHYALAYA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1546", district:"21", Block:"409", label:"VEDAVALLI VIDYALAYA SENIOR SECONDARY SCHOOL, WALAJAPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1547", district:"27", Block:"40", label:"PITCHANDI MIDDLE SCHOOL -  BODI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1548", district:"31", Block:"362", label:"AL-AMEEN MATRIC HSS, TIRUPATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1549", district:"33", Block:"403", label:"SHAMROCK MAT HSS MUGAPAIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1550", district:"33", Block:"76", label:"ELITE MAT.HSS GUMMIDIPOONDI - 601 201", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1551", district:"31", Block:"208", label:"SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL, HINDUPET", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1552", district:"33", Block:"76", label:"NETHAJI MS PATHIRVEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"1553", district:"17", Block:"186", label:"KKMS NANSEI EDAIYAR(AIDED)", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1554", district:"8", Block:"37", label:"PUMS SUKKANAICKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1555", district:"8", Block:"20", label:"VIVEKANANDHA VIDHYALAYA MHS ANTHIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1556", district:"8", Block:"20", label:"PUMS THOTTAKUDIAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1557", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL VELLAIYAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"1558", district:"32", Block:"378", label:"KONGU MATRICULATION HIGHER SECONDARY SCHOOL, UTHUKULI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1559", district:"11", Block:"341", label:"ISLAMIC  MODEL M.S-KULASEKHARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1560", district:"32", Block:"73", label:"PUMS ADIVALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"1561", district:"36", Block:"113", label:"ST.MARKS MATRIC HSS, KATPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1562", district:"4", Block:"245", label:"CMS MATRICULATION HR SEC SCHOOL, GANAPATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1563", district:"3", Block:"270", label:"KALAIMAGAL VIDYALAYA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1564", district:"11", Block:"124", label:"BETHLAHEM MATRIC & HR.SEC. SCHOOL,KARUNGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1565", district:"27", Block:"31", label:"PADMA RAMASAMY MAT HSS, S.RENGANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1566", district:"8", Block:"38", label:"HOLY REDEEMER'S MATRIC SCHOOL, BHAVANISAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1567", district:"4", Block:"248", label:"PUMS RANGASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1568", district:"36", Block:"393", label:"SNEHA DEEPAM MATRIC HSS, ALAMELUMANGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1569", district:"11", Block:"3", label:"MALICK DHINAR MATRICULATION  SCHOOL- KOTTAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1570", district:"8", Block:"202", label:"SHANGUINE MATRIC HIGHER SECONDARY SCHOOL, KURUMANDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1571", district:"8", Block:"128", label:"PUMS,PERIYAVATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1572", district:"32", Block:"363", label:"PUMS, PONNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1573", district:"33", Block:"403", label:"VELAMMAL MAT SCHOOL, MU WEST", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1574", district:"33", Block:"64", label:"PUMS, PANAYANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"1575", district:"4", Block:"247", label:"PUMS KONDEGOUNDEN PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1576", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, SINGIRIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1577", district:"17", Block:"66", label:"PUMS KOOLIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1578", district:"3", Block:"62", label:"ANITA METHODIST MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1579", district:"33", Block:"261", label:"PUMS,KRISHNAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1580", district:"17", Block:"85", label:"ST.JOSEPH R.C MID SCHOOL PERUNKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1581", district:"8", Block:"65", label:"A.E.T.MATRICULATION HSS, KATHIRAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1582", district:"32", Block:"250", label:"SRI DEVI MATRIC HR SEC SCHOOL, VELAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1583", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, ACHANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1584", district:"33", Block:"64", label:"VIVEKANANDA MATRIC HR SEC SCHOOL, UTHUKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1585", district:"33", Block:"184", label:"ISRAEL MISSION MAT HR SEC SCHOOL, MINJUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1586", district:"17", Block:"186", label:"PUMS MANAPPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"1587", district:"32", Block:"57", label:"CENTTWIN MATRIC HSS DHARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1588", district:"28", Block:"320", label:"PUMS KANTHANKUDIERUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"1589", district:"11", Block:"124", label:"GMS MANKANTUVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1590", district:"33", Block:"76", label:"PUMS CHINNAOBULAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1591", district:"33", Block:"364", label:"PUMS - SIRUKUMI H/C", Sch_type:"Government", Category:"Middle School"},
    { key:"1592", district:"4", Block:"13", label:"PUMS ARTHANARIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1593", district:"33", Block:"337", label:"PUMS, NALLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1594", district:"25", Block:"313", label:"PUMS - VALLAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1595", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, PONNAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1596", district:"33", Block:"403", label:"St.MOSES MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1597", district:"17", Block:"397", label:"PUMS R.PUDUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1598", district:"14", Block:"328", label:"YADAVA MATRIC HR SEC SCHOOL, SUBRAMANIAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1599", district:"4", Block:"302", label:"PANCHAYAT UNION MIDDLE SCHOOL ELACHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1600", district:"33", Block:"260", label:"JAIN VIDYAASHRAM, PUZHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1601", district:"32", Block:"145", label:"PUMS VERUVEDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1602", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, IRUGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1603", district:"33", Block:"260", label:"BOSCO ACADEMY MAT HSS, MADHAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1604", district:"32", Block:"57", label:"LIONS MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1605", district:"24", Block:"89", label:"PUMS CHOCKKANATHANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1606", district:"32", Block:"190", label:"PUMS PATTUTHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1607", district:"17", Block:"360", label:"PANCHAYAT UNION MIDDLE SCHOOL, V.MOREPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1608", district:"33", Block:"76", label:"PUMS A N KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1609", district:"8", Block:"185", label:"PUMS,KAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1610", district:"33", Block:"252", label:"CHRIST MATRIC HIGHER SECONDARY SCHOOL SENNEERKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1611", district:"8", Block:"310", label:"PUMS DODDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1612", district:"17", Block:"132", label:"PUMS ASAKKATTUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1613", district:"17", Block:"285", label:"PUMS THUTHIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1614", district:"36", Block:"243", label:"NEW WISDOM MATRIC  HSS, EARIGUTHIMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1615", district:"3", Block:"259", label:"NATIONAL STAR MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1616", district:"8", Block:"281", label:"SARU MATRIC HIGHER SECONDARY SCHOOL, KOTTUVERAMPALAYAM,", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1617", district:"33", Block:"252", label:"NABI CRESCENT MATRICULATION  SCHOOL, AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1618", district:"31", Block:"362", label:"PUMS,  PAZHAYA ATHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1619", district:"3", Block:"237", label:"DAVIDSON MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1620", district:"4", Block:"107", label:"MADONNA MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1621", district:"8", Block:"65", label:"GRACE MATRIC HSS, GRACE NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1622", district:"3", Block:"69", label:"SRISHTI INTERNATIONAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1623", district:"33", Block:"403", label:"LAKE VIEW MS, KALLIKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1624", district:"30", Block:"92", label:"K.A.M.P. MEERANIA MS KALAKAD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1625", district:"11", Block:"3", label:"ST TRESA'S MATRIC HR.SEC.SCHOOL- VAZHUKKAMPARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1626", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, KUTHIRAIKALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1627", district:"27", Block:"49", label:"GOODSAM MATRIC HSS - SUKKANGALPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1628", district:"33", Block:"289", label:"DON BOSCO MATRIC HIGHER SECONDARY SCHOOL, KARANODAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1629", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, UNJAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1630", district:"33", Block:"403", label:"UCCK MATRIC HR.SEC.SCHOOL,AYANAMPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1631", district:"33", Block:"364", label:"PUMS - PATTABIRAMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1632", district:"30", Block:"221", label:"IIPE LAXMI RAMAN MATRIC HIGHER SECONDARY SCHOOL UTHAMAPANDIANKULAM 627351", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1633", district:"27", Block:"31", label:"PUMS - VARATHARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1634", district:"21", Block:"21", label:"VIVEKANANDA VIDYALAYA MATRIC HSS,ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1635", district:"8", Block:"244", label:"PUMS,CINNAMALLAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1636", district:"32", Block:"392", label:"KURINCHI  MATRIC SCHOOL, VELLAKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1637", district:"21", Block:"359", label:"ARCOT SRI MAHALAKSHMI MATRIC HSS, VILAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1638", district:"32", Block:"73", label:"PUMS-ILUPPANAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1639", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, KAVAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1640", district:"8", Block:"10", label:"LOYOLA MATRICULATION SCHOOL, AMMAPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1641", district:"27", Block:"241", label:"PUMS -  D.VADIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1642", district:"17", Block:"397", label:"PUMS NADUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1643", district:"33", Block:"364", label:"PUMS - SRINIVASAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1644", district:"4", Block:"242", label:"ST.PAULS MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1645", district:"32", Block:"145", label:"PUMS ELLAPALAYAM PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1646", district:"30", Block:"92", label:"TDTA MS KEELA SADAYAMANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1647", district:"33", Block:"260", label:" KATHIVAKKAMMUNICIPAL MIDDLESCHOOL NETTUKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1648", district:"14", Block:"161", label:"KARPAGAM MATRIC HR SEC SCHOOL, PARAVAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1649", district:"17", Block:"186", label:"PUMS PARALI", Sch_type:"Government", Category:"Middle School"},
    { key:"1650", district:"20", Block:"266", label:"A.V.M.S. MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1651", district:"4", Block:"52", label:"ATHAR JAMATH MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1652", district:"17", Block:"232", label:"SIVABAKIYAM MUTHUSAMY MATRIC HSS MADESAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1653", district:"21", Block:"21", label:"JOTHI VALLALAR MIDDLE SCHOOL ARAKKONAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1654", district:"24", Block:"119", label:"JAWAHARLAL MS SURANDAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1655", district:"33", Block:"403", label:"C.S.CENTRAL MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1656", district:"28", Block:"320", label:"SRI SARAVANA IYER MS, TIRUCHINDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1657", district:"20", Block:"169", label:"NATIONAL MATRICULATION HIGHER SECONDARY SCHOOL,  RAMESWARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1658", district:"3", Block:"304", label:"NAVABHARATH MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1659", district:"33", Block:"76", label:"PUMS EDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1660", district:"33", Block:"64", label:"PUMS, PERANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1661", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHITHIRANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"1662", district:"27", Block:"40", label:"T.V.MS - B. RENGANATHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1663", district:"4", Block:"302", label:"AMIRTHA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1664", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, GANAPATHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1665", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, OPPILAN", Sch_type:"Government", Category:"Middle School"},
    { key:"1666", district:"31", Block:"362", label:"PUMS KATHIRIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1667", district:"32", Block:"190", label:"PANCHAYAT  UNION  MIDDLE  SCHOOL  SOMANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1668", district:"28", Block:"217", label:"TNDTA MS KOLLAMPARAMBU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1669", district:"27", Block:"241", label:"PUMS - A.MEENAKSHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1670", district:"28", Block:"351", label:"MUNICIPAL MS NBK STREET  THOOTHUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"1671", district:"33", Block:"364", label:"ANM MS-SARASWATHI NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1672", district:"33", Block:"403", label:"VASANTHA VIDHYA. KARAMPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1673", district:"33", Block:"76", label:"PUMS PANAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1674", district:"20", Block:"266", label:"SRI SARASWATHI MIDDLE SCHOOL, VELIPATTINAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1675", district:"32", Block:"145", label:"PUMS MUTHUGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1676", district:"29", Block:"176", label:"PUMS,Yagapuram", Sch_type:"Government", Category:"Middle School"},
    { key:"1677", district:"19", Block:"251", label:"ST.JOSEPH MATRICULATION SCHOOL, VENTHANPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1678", district:"8", Block:"65", label:"PUMS,SSP NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1679", district:"24", Block:"388", label:"R.M.M.S VASUDEVANALLUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"1680", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL VEERARAGAVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1681", district:"31", Block:"362", label:"PUMS PICHANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"1682", district:"8", Block:"244", label:"PUMS,KARUMANDISELLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1683", district:"28", Block:"320", label:"CARMEL MIDDLE SCHOOL, ALANTHALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1684", district:"4", Block:"242", label:"PUMS VEERAKERALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1685", district:"30", Block:"92", label:"ST JOHNS MATRIC HR SEC SCHOOL PADALAIYARKULAM, KALAKAD.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1686", district:"30", Block:"174", label:"PUMS,NELLAITHIRUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"1687", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL PANAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1688", district:"20", Block:"86", label:"SRI RAMAKRISHNA SRI SARADHA MIDDLE SCHOOL, APPANOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1689", district:"23", Block:"79", label:"PUMS, S.VANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1690", district:"20", Block:"41", label:"PANCHAYAT UNION MIDDLE SCHOOL, MANJAKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1691", district:"17", Block:"186", label:"PUMS RAMUDAIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1692", district:"17", Block:"66", label:"PUMS M METTUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1693", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, KADAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1694", district:"28", Block:"217", label:"PUMS,PULIYAMARATHUARASADI", Sch_type:"Government", Category:"Middle School"},
    { key:"1695", district:"32", Block:"371", label:"PUMS VENJAMADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1696", district:"24", Block:"388", label:"SWAMI RENUGAL MS, VISWANATHAPERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1697", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KASTHURIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1698", district:"33", Block:"261", label:"PUMS,DEVALAMBAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1699", district:"20", Block:"266", label:"POPE JOHN PAUL II MIDDLE SCHOOL, PASUMPON NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1700", district:"33", Block:"76", label:"PUMS ERUKKUVOY", Sch_type:"Government", Category:"Middle School"},
    { key:"1701", district:"17", Block:"132", label:"PUMS NARIYANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"1702", district:"36", Block:"74", label:"GGHSS NADUPET, GUDIYATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"1703", district:"33", Block:"261", label:"PUMS,S B KANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1704", district:"24", Block:"119", label:"MARAVA MS-KALUNEERKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1705", district:"11", Block:"191", label:"SR MAT SCHOOL KANJAMPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1706", district:"25", Block:"330", label:"PUMS  KANNARAKKUDI   ", Sch_type:"Government", Category:"Middle School"},
    { key:"1707", district:"33", Block:"338", label:"PUMS, KILANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1708", district:"4", Block:"247", label:"PUMS THOPPAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1709", district:"27", Block:"194", label:"PUMS - UPPUTHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1710", district:"33", Block:"253", label:"PUMS, NELVOY", Sch_type:"Government", Category:"Middle School"},
    { key:"1711", district:"33", Block:"289", label:"PUMS, MADHAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1712", district:"17", Block:"397", label:"PUMS KUTTALADAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1713", district:"28", Block:"350", label:"PUMS BHARATHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1714", district:"33", Block:"260", label:"MUNICIPAL MIDDLE SCHOOL,RAMANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1715", district:"21", Block:"409", label:"RISHI MATRIC HSS, SEEKARAJAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1716", district:"20", Block:"86", label:"NEW MIDDLE SCHOOL, SAYALGUDI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"1717", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, ROJMANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1718", district:"30", Block:"361", label:"STC Branch MS, Sindupoonthurai", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1719", district:"28", Block:"297", label:"TNDTA MS SEBATHIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1720", district:"27", Block:"194", label:"PUMS - SIRAPPARAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"1721", district:"33", Block:"76", label:"PUMS ANNAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1722", district:"20", Block:"41", label:"PANCHAYAT UNION MIDDLE SCHOOL, MENNANTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"1723", district:"33", Block:"337", label:"PUMS, CHINNAMANDALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"1724", district:"17", Block:"285", label:"PUMS PALLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1725", district:"33", Block:"260", label:"SR PARTHASARATHY N & P, WARD 6 KATHIVAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1726", district:"33", Block:"76", label:"PUMS POOVALAI AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1727", district:"32", Block:"145", label:"PUMS SURIYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1728", district:"4", Block:"242", label:"PUMS KONDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1729", district:"33", Block:"64", label:"PUMS, THARATCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"1730", district:"8", Block:"20", label:"PUMS PERIYASENGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1731", district:"11", Block:"263", label:"ST.MARY'S MS PILLAITHOPPU", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"1732", district:"27", Block:"375", label:"PUMS - THEVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1733", district:"27", Block:"319", label:"PUMS - NAGALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1734", district:"33", Block:"261", label:"PUMS,AMMANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"1735", district:"27", Block:"31", label:"PUMS - VANDIMOOPANPATTI @ PALAYAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1736", district:"30", Block:"262", label:"HAVARD HI-TECH MATRIC HIGHER SECONERY  SCHOOL ULAGARATCHAGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1737", district:"3", Block:"195", label:"SRI SANKARA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1738", district:"17", Block:"397", label:"PUMS MASAKALIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1739", district:"26", Block:"72", label:"SACRED HEART MHSS KAYYUNNI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1740", district:"11", Block:"341", label:"S.R.K.B.V. MAT. HSS., KULASEKHARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1741", district:"34", Block:"27", label:"BEST MATRIC HIGHER SECONDARY SCHOOL  ARNI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1742", district:"32", Block:"371", label:"PUMS SS PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1743", district:"3", Block:"195", label:"CSI MONAHAN GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"1744", district:"17", Block:"186", label:"PUMS UNJAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1745", district:"17", Block:"132", label:"PUMS KUNDUNINADU", Sch_type:"Government", Category:"Middle School"},
    { key:"1746", district:"33", Block:"260", label:"VOC MATRIC HIGHER SECONDARY SCHOOL ENNORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1747", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, E.CHETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1748", district:"33", Block:"184", label:"Dr.V.GENGUSWAMY NAIDU MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1749", district:"8", Block:"202", label:"PUMS, ELATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1750", district:"3", Block:"62", label:"DOVETON  GIRLS' HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"1751", district:"20", Block:"266", label:"VELUMANICKAM MONTESSORI MATRICULATION HIGHER SECONDARY SCHOOL, VANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1752", district:"33", Block:"338", label:"KAMARAJ MATRICULATION SCHOOL, TIRUVALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1753", district:"33", Block:"260", label:"SAGAYA MATHA MATRIC, R.K.NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1754", district:"33", Block:"403", label:"KOIL PILLAI MATRICULATION HIGHER SECONDARY SCHOOL,RAMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1755", district:"28", Block:"351", label:"ST CHARLES MS, SMPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1756", district:"8", Block:"37", label:"PUMS ERUSANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1757", district:"33", Block:"338", label:"SIDHAARTHA MATRIC HIGHER SECONDARY SCHOOL, SEVVAPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1758", district:"3", Block:"2", label:"GHSS MODEL SAIDAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"1759", district:"20", Block:"86", label:"RC MIDDLE SCHOOL, MOOKKAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1760", district:"14", Block:"161", label:"ST.JOSEPH MATRIC HR SEC SCHOOL, KOODAL NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1761", district:"33", Block:"403", label:"SATHYA MAT HSS,VALASARAVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1762", district:"33", Block:"403", label:"KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL, SENTHIL NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1763", district:"27", Block:"319", label:"PUMS - THADICHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"1764", district:"32", Block:"190", label:"BHARATHI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL,MULANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1765", district:"35", Block:"193", label:"UAMHSS BRILLIANT ,MUTHUPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1766", district:"34", Block:"256", label:"ST. ANTONY'S MATRICULATION SCHOOL KANJI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1767", district:"22", Block:"34", label:"ROYAL PARK MATRIC HR. SEC. SCHOOL, KUMARIGIRI PET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1768", district:"32", Block:"363", label:"PUMS, KETHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1769", district:"32", Block:"363", label:"PUMS, ANUPPARPALAYAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1770", district:"13", Block:"139", label:"D.K.SAMY MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1771", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, KANAKKARASAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1772", district:"6", Block:"58", label:"SRI VIDYA MANDIR MATRIC SCHOOL, PIDAMANERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1773", district:"8", Block:"65", label:"CMS, CAUVERY ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"1774", district:"8", Block:"65", label:"PUMS, ELLAPPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1775", district:"26", Block:"72", label:"GOOD SHEPHERD MATRIC SCHOOL MASINAGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1776", district:"32", Block:"250", label:"PUMS VAVIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1777", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL,  PERIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1778", district:"32", Block:"378", label:"KONGU MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1779", district:"27", Block:"55", label:"RAM JAYAM VIDYAA MANDIR MATRIC HR SEC SCHOOL -  CUMBUM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1780", district:"8", Block:"45", label:"KONGU VELLALAR MATRIC HSS,MUTHAIYAN KOVIL,CHENNIMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1781", district:"33", Block:"260", label:"INFANT JESUS MATRIC HR.SEC SCHOOL,LAKSHMIPURAM,KOLATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1782", district:"29", Block:"357", label:"SOWDAMBIKAA MATRIC BOYS HR. SEC.SCHOOL, THURAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1783", district:"33", Block:"64", label:"HARIHARA VIDYALAYA MATRICULATION SCHOOL, THAMARAIPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1784", district:"3", Block:"259", label:"C.S.I. EWART MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1785", district:"27", Block:"55", label:"NAGAMANI AMMAL MEMORIAL MATRIC HR SEC SCHOOL - CUMBUM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1786", district:"30", Block:"9", label:"Aroma Matric HS - Ambasamudram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1787", district:"8", Block:"20", label:"PUMS OOSIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1788", district:"3", Block:"304", label:"Sri Ramakrishna Mission Sarada Vidyalaya Matriculation Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1789", district:"14", Block:"328", label:"SBOA MATRIC HR SEC SCHOOL, NAGAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1790", district:"17", Block:"267", label:"SRI VIDYA MANDIR MATRICULATION HIGHER  SECONDARY SCHOOL,GURUSAMIPALAYAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1791", district:"33", Block:"338", label:"SRI VENKATESWARA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1792", district:"3", Block:"259", label:"ST. VINCENT'S MATRICULATION HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1793", district:"33", Block:"289", label:"GREEN FIELD MAT SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1794", district:"8", Block:"244", label:"PUMS,PERUNDURAI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"1795", district:"11", Block:"124", label:"DONBOSCO MATRIC HR.SEC.SCHOOL-MIDALAKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1796", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, THALAIKKOMBUPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1797", district:"8", Block:"65", label:"PUMS, VAIRAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1798", district:"24", Block:"91", label:"KING UNIVERSE MATRIC HIGHER SECONDARY SCHOOL, KUMANTHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1799", district:"8", Block:"37", label:"ADHARSH VIDHYALAYA PUBLIC SCHOOL, PARUVATCHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1800", district:"11", Block:"182", label:"KIDS OXFORD MATRIC HR SEC SCHOOL KUZHITHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1801", district:"33", Block:"76", label:"PUMS RAKAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1802", district:"32", Block:"363", label:"MMS,VELLIANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"1803", district:"38", Block:"407", label:"MSPN MUNICIPAL MIDDLE SCHOOL, VIRUDHUNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1804", district:"13", Block:"117", label:"NATIONAL MS VARATTAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1805", district:"31", Block:"362", label:"PUMS JOLLAGOUNDANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"1806", district:"17", Block:"132", label:"PUMS BAILNADU", Sch_type:"Government", Category:"Middle School"},
    { key:"1807", district:"33", Block:"252", label:"EMMANUEL MATRICULATION SCHOOL, AVADI.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1808", district:"8", Block:"244", label:"ROOTS MATRIC HIGHER  SECONDARY SCHOOL, MOONGILPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1809", district:"17", Block:"397", label:"PUMS AKKARAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"1810", district:"23", Block:"79", label:"PUMS AIYANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"1811", district:"30", Block:"92", label:"SANTHOSHA VITHYALAYA DONAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1812", district:"11", Block:"149", label:" CHRISTIAN MATRICULATION HIGHER SECONDARY SCHOOL,  KALLUKOOTTAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1813", district:"36", Block:"113", label:"SHRISHTI MATRIC HSS, BRAMMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1814", district:"14", Block:"328", label:"DOLPHIN MATRIC HR SEC SCHOOL, PONMENI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1815", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL , VANI", Sch_type:"Government", Category:"Middle School"},
    { key:"1816", district:"3", Block:"304", label:"SRI SITARAM VIDYALAYA MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1817", district:"33", Block:"403", label:"AMUTHA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1818", district:"4", Block:"242", label:"KEARTIMAN ACADEMY MATRIC &HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1819", district:"20", Block:"332", label:"P.S.S.J. NADAR MATRICULATION SCHOOL, KILAKARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1820", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, BAGUTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1821", district:"30", Block:"174", label:"K.M.P VIDYALAYA MATRIC. HR.SEC.SCHOOL, MANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1822", district:"14", Block:"160", label:"SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, ANUPPANADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1823", district:"17", Block:"85", label:"PUMS SELUR SELLAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1824", district:"8", Block:"20", label:"PUMS THALAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1825", district:"8", Block:"10", label:"PUMS THANDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1826", district:"33", Block:"252", label:"RCM MATRICULATION SCHOOL, KAMARAJ NAGAR, AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1827", district:"8", Block:"244", label:"PUMS,SELLAPPAGOUNDANVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"1828", district:"11", Block:"191", label:"M.M.K.M. H.S. VARUTHATTU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"1829", district:"36", Block:"393", label:"ETHIRAJ MATRIC HSS, SATHUVACHARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1830", district:"30", Block:"222", label:"DON BOSCO MHSS-XAVIERCOLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1831", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, NEHRUNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1832", district:"27", Block:"375", label:"THE CRESCENT MATRIC HIGHER SECONDARY SCHOOL - UTHAMAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1833", district:"19", Block:"251", label:"NEHRU MATRIC HIGHER SECONDARY SCHOOL, M.USILAMPATTI,MELATHANIYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1834", district:"31", Block:"362", label:"MUNICIPAL OLD MUSLIM GIRLS' MIDDLE SCHOOL, MOSQUE STREET,", Sch_type:"Government", Category:"Middle School"},
    { key:"1835", district:"4", Block:"242", label:"YUVABHARATHI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1836", district:"30", Block:"92", label:"PUMS KALAKAD", Sch_type:"Government", Category:"Middle School"},
    { key:"1837", district:"11", Block:"263", label:"SACCER MATRICULATION SCHOOL, EAST PULLUVILAI, ERUMBUKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"1838", district:"26", Block:"72", label:"NILGIRI MATRICULATION HIGHER SECONDARY SCHOOL ERUMAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1839", district:"29", Block:"15", label:"SEVAI SHANTHI MATRICULATION HIGHER SECONDARY SCHOOL,PETTAVAITHALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1840", district:"3", Block:"2", label:"OXFORD MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1841", district:"17", Block:"85", label:"PUMS CHEYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1842", district:"36", Block:"12", label:"SHRI VARDHAMAN JAIN MATRIC HSS,VIRINCHIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1843", district:"25", Block:"344", label:"THE NEW LIGHT MATRICULATION SCHOOL-  THIRUVIDAIMARUTHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1844", district:"11", Block:"124", label:"SGS MHSS KARUNGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1845", district:"11", Block:"3", label:"FATIMA CONVENT MATRICULATION SCHOOL-RAMAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1846", district:"11", Block:"355", label:"DEVI MATRIC SCHOOL RAMANPARAMBU", Sch_type:"Un-aided", Category:"High School"},
    { key:"1847", district:"3", Block:"368", label:"EVE MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1848", district:"21", Block:"290", label:"SRI DIVYA CHAITANYA MATRIC HSS SHOLINGHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1849", district:"13", Block:"376", label:"VIDYA VIKAS MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1850", district:"33", Block:"403", label:"LEO MAT HSS AMBATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1851", district:"33", Block:"403", label:"KALKI RANGANATHAN MONTFORD MAT SCH", Sch_type:"Un-aided", Category:"High School"},
    { key:"1852", district:"8", Block:"10", label:"PUMS REDDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1853", district:"6", Block:"218", label:"St. LUCY'S MHSS, PALACODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1854", district:"34", Block:"412", label:"CLUNY MATRIC DEVIGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1855", district:"32", Block:"223", label:"INFANT JESUS MATRIC HR SEC SCHOOL, SUKKAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1856", district:"14", Block:"306", label:"VENKATASALAPATHY MATRIC HIGH SCHOOL, T VADIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1857", district:"24", Block:"119", label:"RC MS SURANDAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1858", district:"4", Block:"248", label:"MUNICIPAL MIDDLE SCHOOL,,ABT ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"1859", district:"4", Block:"52", label:"ST MICHAEL'S MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1860", district:"8", Block:"37", label:"PUMS  APPAKUDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1861", district:"14", Block:"328", label:"K.N.U NACHIAPPANADAR GURUVAMMAL MATRIC HIGH SCHOOL, VILLAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1862", district:"4", Block:"126", label:"PUMS SOKKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1863", district:"21", Block:"210", label:"SHIRNE VELANKANNI MATRIC SCHOOL, PARAMESWARAMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"1864", district:"3", Block:"304", label:"FATIMA MATRICULATION HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1865", district:"34", Block:"358", label:"PUMS MARUTHUVAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"1866", district:"33", Block:"289", label:"PUMS,ATHIPEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"1867", district:"4", Block:"279", label:"VENKATALAKSHMI MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1868", district:"17", Block:"85", label:"PUMS THIDUMAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1869", district:"21", Block:"210", label:"DR. NATARAJAN MATRIC HSS, VETTANKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1870", district:"37", Block:"100", label:"SRI KRISHNA MATRIC HIGHER SECONDARY SCHOOL PANAMALAI MADHURA, METTUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1871", district:"27", Block:"194", label:"PUMS - GANESHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1872", district:"4", Block:"242", label:"VEL INTERNATIONAL PUBLIC SCHOOL, KNG PUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1873", district:"26", Block:"370", label:"DR. KJG MEMORIAL MATRICULATION SCHOOL BALACOLA", Sch_type:"Un-aided", Category:"High School"},
    { key:"1874", district:"28", Block:"350", label:"SIVAKASI N. MAT. SCHOOL, MAPPILAIYURANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1875", district:"34", Block:"249", label:"PINKZ PUBLIC SCHOOL, KASTHAMBADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1876", district:"8", Block:"244", label:"KALAIVANI KALVI NILAYAM THINGALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1877", district:"33", Block:"87", label:"CHRIST KING MAT. HR .SEC.SCHOOL,MANAVALA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1878", district:"8", Block:"45", label:"VIMALA MATRI. HR SEC.SCHOOL, THOPPUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1879", district:"33", Block:"64", label:"PUMS, DR. AMBEDKAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1880", district:"32", Block:"154", label:"PUMS,MYVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"1881", district:"33", Block:"403", label:"ST.MARYS MATRICULATION HIGHER SECONDARY SCHOOL, ATHIPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1882", district:"11", Block:"263", label:"CMC MATRIC HR. SEC. SCHOOL-THAMMATHUKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1883", district:"19", Block:"251", label:"MEIYAPPA  VALLIAMMAI  KANCHI  SANKARA VIDYALAYA MATRICULATION  SCHOOL,VALAYAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1884", district:"33", Block:"184", label:"ST. MARY'S MAT SCHOOL, MINJUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1885", district:"31", Block:"155", label:"GRACIOUS MATRIC SCHOOL, AMBUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1886", district:"8", Block:"310", label:"PUMS AREPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1887", district:"34", Block:"412", label:"VELS VIDHYASHRAM MATRIC HSS, KANNAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1888", district:"32", Block:"363", label:"PUMS,SAMATHUVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1889", district:"8", Block:"71", label:"DIAMOND JUBILEE MATRIC. HS, GOBI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1890", district:"33", Block:"64", label:"VISWESHWARA MATRICULATION SCHOOL, UTHUKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1891", district:"8", Block:"37", label:"SRI VIDHYA MANDIR MS", Sch_type:"Un-aided", Category:"High School"},
    { key:"1892", district:"30", Block:"361", label:"Adaikalamatha Mat. HS, Tvl", Sch_type:"Un-aided", Category:"High School"},
    { key:"1893", district:"33", Block:"76", label:"PUMS PALLAVADA", Sch_type:"Government", Category:"Middle School"},
    { key:"1894", district:"34", Block:"238", label:"PUMS MELSATHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1895", district:"32", Block:"154", label:"PUMS KRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1896", district:"8", Block:"10", label:"PUMS KONARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1897", district:"31", Block:"5", label:"PUMS VILLANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1898", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KATTUVALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"1899", district:"30", Block:"385", label:"KEINS MATRIC HR SEC SCHOOL, VALLIOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1900", district:"8", Block:"45", label:"PUMS,CHENNIMALAI TOWN", Sch_type:"Government", Category:"Middle School"},
    { key:"1901", district:"11", Block:"263", label:"LMS MIDDLE SCHOOL EATHAMOZHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1902", district:"3", Block:"62", label:"UNION CHRISTIAN MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1903", district:"30", Block:"221", label:"SRI JEYANDRA SARASWATHI SWAMIGAL GOLDEN JMHSS SANKARNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1904", district:"8", Block:"244", label:"PUMS,PERIYAVETUVAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1905", district:"36", Block:"393", label:"SHANTHINIKETHAN MATRIC HSS, SATHUVACHARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1906", district:"30", Block:"222", label:"AUPET CHINMAYA VID MHSS PALAY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1907", district:"25", Block:"228", label:"Abidean Matric High School, Thiruppalathurai", Sch_type:"Un-aided", Category:"High School"},
    { key:"1908", district:"8", Block:"10", label:"PUMS  JARTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1909", district:"4", Block:"242", label:"VIDYA VANAM SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1910", district:"32", Block:"363", label:"INFANT JESUS MHSS, KUMAR NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1911", district:"4", Block:"52", label:"SRI VISWESWARA VIDHYALYA  MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1912", district:"33", Block:"76", label:"PUMS VALLAMEDU KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1913", district:"33", Block:"260", label:"SRF VIDYALAYA MATRICULTION SCHOOL, MANALI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1914", district:"33", Block:"364", label:"PUMS-CHERUKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1915", district:"34", Block:"238", label:"PUMS KADAPPANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1916", district:"13", Block:"78", label:"ALPHA ELITE HIGH", Sch_type:"Un-aided", Category:"High School"},
    { key:"1917", district:"8", Block:"310", label:"PUMS KADUBASUVANMALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1918", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, KURUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1919", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, OTTARKARATTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1920", district:"33", Block:"403", label:"BALDWIN'S MAT SCH, NERKUNDRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1921", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, SITHANKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"1922", district:"27", Block:"49", label:"ST. FRANCIS ASSISI MATRIC SCHOOL - CHINNAMANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1923", district:"4", Block:"349", label:"NAGINI VIDYALAYA MATRIC HR SEC SCHOOL, KALIKKANAICKENPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1924", district:"17", Block:"186", label:"PUMS NEIKARANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"1925", district:"24", Block:"119", label:"SRI JEYANDRA MATRIC HIGHER SECONDARY SCHOOL,  SURANDAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1926", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, KURUVIKITHIYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"1927", district:"8", Block:"244", label:"PUMS,TANNERPANDALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1928", district:"8", Block:"45", label:"PUMS, THOPPUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1929", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, KUNNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1930", district:"30", Block:"222", label:"ROSE MARY MHSS MAIGANAST , PALAYAMKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1931", district:"8", Block:"10", label:"TALENT MATRICULATION SCHOOL, AMMAPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1932", district:"25", Block:"313", label:"FR. ANTONY MATRIC HR.SEC.SCHOOL,VALLAM,THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1933", district:"34", Block:"48", label:"OXFORD MATRIC SCHOOL CHEYYAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1934", district:"28", Block:"351", label:"TDTA MS, VADAKKUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1935", district:"30", Block:"262", label:"DANIEL THOMAS MATRIC HSS TISAIYANVILLAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1936", district:"3", Block:"69", label:"TEMPLE PARK MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1937", district:"36", Block:"113", label:"DR.VGN MATRIC SCHOOL, PONNAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1938", district:"28", Block:"8", label:"TNDTA MS ALWARTHIRUNAGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"1939", district:"24", Block:"288", label:"SHRIRAM VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL ELATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1940", district:"8", Block:"65", label:"GRACE INTERNATIONAL SCHOOL, ELAVAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1941", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, UKKARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1942", district:"8", Block:"65", label:"KONGU KALVINILAYAM MATRICULATION HSS, RANGAMPALAY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1943", district:"11", Block:"3", label:"ROCK FHELLAR MATRIC.SCHOOL,VADASERY", Sch_type:"Un-aided", Category:"High School"},
    { key:"1944", district:"17", Block:"224", label:"PUMS KADACHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1945", district:"8", Block:"244", label:"KARUNYA VIDHYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL,PERUNDURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1946", district:"14", Block:"158", label:"FUSCO'S MATRIC HIGH SCHOOL, ANNA NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1947", district:"3", Block:"259", label:"SINDHI MODEL MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1948", district:"33", Block:"403", label:"SINTHEA MODEL MAT SCHOOL,KORATTUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"1949", district:"30", Block:"174", label:"PUMS THALAYOOTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"1950", district:"11", Block:"3", label:"SREE NARAYANA GURU MHSS KOTTAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1951", district:"11", Block:"182", label:"THE WINNER'S RESIDENTIAL MATRIC HIGHER SECONDARY SCHOOL, KILATHOOR, KALIYAL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1952", district:"31", Block:"362", label:"PUMS JAMMANNAPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1953", district:"33", Block:"76", label:"PUMS THANDALACHERRI", Sch_type:"Government", Category:"Middle School"},
    { key:"1954", district:"4", Block:"242", label:"GANDHI ADIGAL KALVI NIRUVANAM MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1955", district:"33", Block:"403", label:"SACRED HEART MAT.HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1956", district:"33", Block:"252", label:"KARTHIK MATRICULATION HIGHER SECONDARY SCHOOL POONAMALLEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1957", district:"24", Block:"119", label:"PUMS CHELLATHAYARPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1958", district:"22", Block:"34", label:"JAY MATRIC HR. SEC. SCHOOL, VAIKKALPATTARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1959", district:"11", Block:"354", label:"ANNE'S MATRIC SCHOOL ARALKURUSADY", Sch_type:"Un-aided", Category:"High School"},
    { key:"1960", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOTHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1961", district:"33", Block:"337", label:"VIDYA SETHRA, LAKSHMAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"1962", district:"11", Block:"149", label:"ATHITHIYA MATRIC HSS- AMMANDIVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1963", district:"24", Block:"119", label:"PUMS THIRAVIANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"1964", district:"20", Block:"231", label:"AGNES MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1965", district:"33", Block:"87", label:"PUMS KADAMBATHUR NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"1966", district:"34", Block:"412", label:"SRI VIJAY MATRIC HR SEC SCHOOL VANNANKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1967", district:"38", Block:"295", label:"KPATDR SIVAKASI JAYCEES MATRICULATION HR SEC SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1968", district:"33", Block:"338", label:"PUMS, PUNNAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1969", district:"33", Block:"403", label:"MUN.MID.SCH. ,MUTHAPUTHUPET", Sch_type:"Government", Category:"Middle School"},
    { key:"1970", district:"4", Block:"126", label:"KIDS PARK MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1971", district:"33", Block:"64", label:"SREE SAI MATRICULATION SCHOOL , VENGAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"1972", district:"4", Block:"302", label:"PUMS K.RAYARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1973", district:"8", Block:"310", label:"PUMS CHIKKANANDHI", Sch_type:"Government", Category:"Middle School"},
    { key:"1974", district:"22", Block:"203", label:" SRI SAKTHI MATRICULATION SCHOOL,VANAVASI", Sch_type:"Un-aided", Category:"High School"},
    { key:"1975", district:"17", Block:"232", label:"GANDHI MATRICULATION HIGHER SECONDARY SCHOOL, KANDAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1976", district:"8", Block:"281", label:"BANNARIAMMAN PUBLIC SCHOOL(CBSE),SATHYAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1977", district:"32", Block:"363", label:"PUMS,PERUMANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1978", district:"8", Block:"128", label:"PUMS,RATHINAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"1979", district:"33", Block:"403", label:"CHILDREN'S ACADEMY MATRIC HIGH SCHOOL  ,PORUR CHENNAI 600116", Sch_type:"Un-aided", Category:"High School"},
    { key:"1980", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL,  N.VELLALAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1981", district:"4", Block:"279", label:"GRG MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1982", district:"8", Block:"65", label:"PUMS, KONGAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1983", district:"25", Block:"144", label:"KALYANASUNDARAM CBSE -CHOZHANMALIGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1984", district:"22", Block:"30", label:"BHARATHIYAR MATRIC HIGHER SECONDARY SCHOOL, ATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1985", district:"14", Block:"161", label:"FATIMA MATRIC HR SEC SCHOOL, AATHIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1986", district:"33", Block:"252", label:"BHARATHI MATRICULATION SCHOOL, AVADI.", Sch_type:"Un-aided", Category:"High School"},
    { key:"1987", district:"31", Block:"362", label:"PUMS MAKKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"1988", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, MARAYEPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1989", district:"8", Block:"20", label:"PUMS BEJILPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1990", district:"27", Block:"319", label:"VELAMMAL MATRICULATION HR. SEC. SCHOOL, MUTHUTHEVANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1991", district:"27", Block:"319", label:"PAALAM MATRIC HR. SEC.  SCHOOL - UNJAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1992", district:"3", Block:"69", label:"DR RADHAKRISHNAN MAT HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1993", district:"19", Block:"255", label:"SRI VENKATESWARA MAT.HR.SEC.SCHOOL, THIRUKOKARNAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1994", district:"8", Block:"20", label:"PUMS DHURUSANAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"1995", district:"22", Block:"34", label:"A.R.R.S. CBSE SCHOOL, VALASAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1996", district:"33", Block:"252", label:"HOLY CRESENT MATRIC HR SEC SCHOOL POONAMALLEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1997", district:"20", Block:"265", label:"HOLY ANGELS MATRICULATION HIGHER SECONDARY SCHOOL, R.S.MANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1998", district:"11", Block:"182", label:"CHRISTHURAJA MA H S S MARTHANDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"1999", district:"24", Block:"307", label:"ST.JOHNS MATRIC HR SEC SCHOOL, TENKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2000", district:"34", Block:"238", label:"PUMS VILANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2001", district:"8", Block:"244", label:"PUMS,PERUNDURAI WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"2002", district:"25", Block:"228", label:"AL-MUBEEN MATRICULATION HIGHER SECONDARY SCHOOL -AYYYAMPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2003", district:"4", Block:"279", label:"SRI JAYENDRA SARASWATHY VIDYALAYA MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2004", district:"14", Block:"158", label:"MODERN MAT.S OTHAKADAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2005", district:"8", Block:"45", label:"YOUNG INDIA MATRIC HSS,VEPPILI PIRIVU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2006", district:"11", Block:"263", label:"OUR LADY OF MIRACLE BETHANY MATRIC HIGHER SECONDARY SCHOOL, ANNAI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2007", district:"4", Block:"156", label:"ST.ANN'S MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2008", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, SUNDAKKAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2009", district:"8", Block:"244", label:"PUMS,CINNIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2010", district:"25", Block:"11", label:"DR.V.GENGUSAMY MAT. -MELATTUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2011", district:"17", Block:"165", label:"PUMS EAST MUNJANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2012", district:"8", Block:"45", label:"PANCHAYAT UNION MIDDLE SCHOOL MYILADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2013", district:"33", Block:"289", label:"PUMS, OLD ERUMAI VETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2014", district:"14", Block:"159", label:"S.R.W.W.O MATRIC HIGH SCHOOL, RAILWAY COLONY   ", Sch_type:"Un-aided", Category:"High School"},
    { key:"2015", district:"25", Block:"228", label:"STAR LIONS MATRIC HIGHER SECONDARY SCHOOL-AYYAMPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2016", district:"27", Block:"319", label:"PUMS - ARANMANAIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2017", district:"33", Block:"403", label:"NABI CRESCENT MATRICULATION SCHOOL, KOLLUMEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"2018", district:"11", Block:"191", label:"GOVERNMENT MIDDLE SCHOOL  ADAIKKAKUZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"2019", district:"30", Block:"222", label:"BELL ENGLISH MHSS PALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2020", district:"14", Block:"328", label:"B.M.S VIDHYALAYAM MATRIC HIGHER SECONDARY SCHOOL, AVANIYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2021", district:"8", Block:"45", label:"PUMS,KUMARAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"2022", district:"12", Block:"140", label:"PUMS, KOVAGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2023", district:"3", Block:"2", label:"Anna Gem Science Park Mat HSS.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2024", district:"4", Block:"242", label:"GSR MATRIC HR SEC SCHOOL KAMATCHIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2025", district:"14", Block:"161", label:"C.E.O.A. MATRIC HR SEC SCHOOL, A KOSAKULAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2026", district:"8", Block:"71", label:"SHREE VIDYALAYA MATRIC HS SCHOOL, GOBI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2027", district:"33", Block:"64", label:"PUMS, AKKARAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2028", district:"33", Block:"289", label:"PUMS, GANDHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2029", district:"30", Block:"174", label:"PUMS,METTU PIRANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"2030", district:"20", Block:"231", label:"SOURASHTRA DESIA MIDDLE SCHOOL, SOMANATHAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2031", district:"8", Block:"65", label:"CHRISTHU JYOTHI MATRICULATION HSS, B.P. AGRAHARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2032", district:"30", Block:"221", label:"ROSEMARY MHSS, V.M.CHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2033", district:"28", Block:"8", label:"ST.MARY'S MDL.SCH. PRAKASAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2034", district:"3", Block:"2", label:"KUMARARANI MEENA MUTHIAH MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2035", district:"14", Block:"159", label:"ANANTH MEMORIAL MATRIC HR SEC SCHOOL ARAPPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2036", district:"25", Block:"336", label:"AMALRAJ MATRIC HSS THIRUVAIYARU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2037", district:"18", Block:"7", label:"LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL CHETTIKULAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2038", district:"31", Block:"362", label:"PUMS,AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2039", district:"4", Block:"302", label:"KALAIVANI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2040", district:"30", Block:"174", label:"TDTAMS,PARVATHIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2041", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, S.KALLUPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2042", district:"17", Block:"254", label:"SRI SAKTHI MATRIC HR.SEC SCHOOL, PUDANCHANTHAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2043", district:"8", Block:"185", label:"PUMS,RASAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2044", district:"17", Block:"85", label:"PUMS NETTAIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2045", district:"24", Block:"307", label:"ST.JOSEPH MAT.HSS, PANPOLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2046", district:"21", Block:"359", label:"PUMS ANAIMALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2047", district:"30", Block:"46", label:"ALMIGHTY MATRICULATION  Hr. Sec. SCHOOL CHERANMAHADEVI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2048", district:"25", Block:"313", label:"LITTLE SCHOLARS MATRICULATION HIGHER SECONDARY SCHOOL- THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2049", district:"33", Block:"403", label:"BHARATH MATRICULATION HIGHER SECONDARY SCHOOL AMBATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2050", district:"34", Block:"238", label:"PUMS KOZHIPULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2051", district:"25", Block:"336", label:"IMMACULATE MATRIC HS THIRUVAIYARU", Sch_type:"Un-aided", Category:"High School"},
    { key:"2052", district:"8", Block:"310", label:"PUMS KOTTADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2053", district:"3", Block:"368", label:"CHRIST CHURCH ANGLO-INDIAN HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"2054", district:"33", Block:"403", label:"BHARATH MAT, TMVOYAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2055", district:"17", Block:"85", label:"PUMS VENGARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2056", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL , S.R.N.PALANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2057", district:"33", Block:"184", label:"Smt.Gangadevi Bajranglal Chokhani Vivekananda Vidyalaya Matriculation Hr. Sec. School, Minjur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2058", district:"4", Block:"279", label:"HINDUSTHAN MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2059", district:"17", Block:"165", label:"PUMS   MAMUNDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2060", district:"6", Block:"77", label:"PUMS KOOKADAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2061", district:"24", Block:"89", label:"SRI RAMANARAI NADAR MS, PUNGAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2062", district:"11", Block:"3", label:"ALPHONSA MATRICULATION HIGHER SECONDARY SCHOOL- KOTTAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2063", district:"24", Block:"388", label:"VIVEKA MAT.HSS, SIVAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2064", district:"32", Block:"145", label:"PUMS N.KASILINGAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2065", district:"8", Block:"45", label:"VIVEKANANTHA MHSS, V.VELLODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2066", district:"14", Block:"161", label:"C.E.O.A. MATRIC HIGHER SECONDARY SCHOOL, MEENAMBALPURAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2067", district:"31", Block:"362", label:"MUNICIPAL OLD MUSLIM BOYS' MIDDLE SCHOOL, MOSQUE STREET ", Sch_type:"Government", Category:"Middle School"},
    { key:"2068", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, KARATTUPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2069", district:"11", Block:"191", label:"ST MARY'S MATRIC. HR. SEC. SCHOOL  MARIAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2070", district:"24", Block:"307", label:"AG MATRIC HSS,TENKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2071", district:"8", Block:"65", label:"THONDAR SEER PARAVUVAR THANGAVELANAAR ILAIGAR KALVI NILAYAM MIDDLE SCHOOL, ERODE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2072", district:"3", Block:"195", label:"SRI VENUGOPAL VIDYALAYA MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2073", district:"21", Block:"21", label:"MARY'S VISION MHSS, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2074", district:"33", Block:"364", label:"PUMS - SOORIYA NAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2075", district:"28", Block:"351", label:"MUNICIPAL MS,  MELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2076", district:"34", Block:"27", label:"ST. JOSEPH'S MATRIC HR SEC SCHOOL  ARNI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2077", district:"8", Block:"20", label:"LITTLE FLOWER MHS KARATTUR MEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"2078", district:"33", Block:"403", label:"DR VIMALA MS,RAMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2079", district:"17", Block:"232", label:"PUMS MARAVAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2080", district:"17", Block:"232", label:"MALAR MATRIC HSS PARAMATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2081", district:"30", Block:"222", label:"MEGDALIN MHSS RAHMATH NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2082", district:"4", Block:"245", label:"INFANT JESUS MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2083", district:"33", Block:"260", label:"REDEEMER MATRIC, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2084", district:"33", Block:"289", label:"VELAMMAL INTERNATIONAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2085", district:"28", Block:"217", label:"TNDTA MIDDLE SCHOOL,ODAIPATTIPUTHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2086", district:"24", Block:"388", label:"NEW CRESCENT MATRIC HSS, PULIANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2087", district:"28", Block:"217", label:"HINDU MIDDLE SCHOOL,SAMINATHAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2088", district:"33", Block:"87", label:"DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL PANNUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2089", district:"32", Block:"363", label:"NIRMALA MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2090", district:"3", Block:"237", label:"PERIYAR MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2091", district:"11", Block:"191", label:"JANET MATRIC HIGHER SECONDARY SCHOOL  MUNCHIRAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2092", district:"17", Block:"254", label:"VIVEKANANDA VIDYALLAYA MATRIC HR SEC SCHOOL, PUDUCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2093", district:"33", Block:"225", label:"PUMS, KUDIYANAVAR STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"2094", district:"33", Block:"403", label:"SRI RAMAKRISHNA MATRIC HSS,TMVOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2095", district:"12", Block:"112", label:"TNPL MATRICULATION HR.SEC.SCHOOL, KAGITHAAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2096", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, EMMAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2097", district:"8", Block:"310", label:"PANCHAYAT UNION MIDDLE SCHOOL, BHARATHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2098", district:"17", Block:"132", label:"PUMS NADUVALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"2099", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, KURUMANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2100", district:"20", Block:"99", label:"HINDU MIDDLE SCHOOL, KAVADIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2101", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEELASELVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"2102", district:"25", Block:"216", label:"PUMS-PEIKARUMBANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2103", district:"30", Block:"9", label:"EXCELLENT MHS", Sch_type:"Un-aided", Category:"High School"},
    { key:"2104", district:"8", Block:"10", label:"PUMS NATHAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2105", district:"25", Block:"11", label:"PUMS - SALIAYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2106", district:"20", Block:"332", label:"MUHAIIDHEENIA MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2107", district:"8", Block:"20", label:"PUMS SUNDAPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2108", district:"20", Block:"86", label:"I.L.M. MATRICULATION HIGH SCHOOL, NARIPPAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2109", district:"30", Block:"385", label:"Vivekananda kendra vidyalaya matriculation higher secondary school, Vallioor", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2110", district:"17", Block:"132", label:"PUMS PERUMPARAPPUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2111", district:"24", Block:"307", label:"SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, TENKASI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2112", district:"8", Block:"310", label:"PUMS KALBANDIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2113", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, MARANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"2114", district:"4", Block:"301", label:"PUMS PERIYAKUYILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2115", district:"33", Block:"260", label:"HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2116", district:"8", Block:"244", label:"PUMS NEELA GOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2117", district:"4", Block:"107", label:"PUMS KARAMADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2118", district:"11", Block:"263", label:"GMS THAMMATHUKONAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2119", district:"35", Block:"141", label:"PUMS, VADUKAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2120", district:"19", Block:"326", label:"ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, N.PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2121", district:"28", Block:"280", label:"PUMS, THATCHANVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2122", district:"20", Block:"231", label:"SUNDARAM MIDDLE SCHOOL, ARIYANENDAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2123", district:"32", Block:"378", label:"ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, UTHUKULI RAILWAY STATION", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2124", district:"20", Block:"41", label:"PANCHAYAT UNION MIDDLE SCHOOL, POTTITHATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2125", district:"11", Block:"341", label:"GMS. ANDOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"2126", district:"4", Block:"242", label:"PUMS SUBRAMANIAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2127", district:"17", Block:"132", label:"PUMS THIRUPULINADU", Sch_type:"Government", Category:"Middle School"},
    { key:"2128", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, BOMMANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2129", district:"32", Block:"363", label:"PUMS PERIYAR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"2130", district:"4", Block:"245", label:"SANGAMAM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2131", district:"34", Block:"358", label:"SRGDS MATRIC HSS VADAANDAPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2132", district:"27", Block:"40", label:"PUMS-SOOLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2133", district:"28", Block:"350", label:"HOLY FAMILY TAMIL MIDDLE SCHOOL CALDWELL COLONY", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2134", district:"4", Block:"13", label:"K.M.G MATRIC. SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2135", district:"20", Block:"169", label:"ST. ANNE'S MATRICULATION HIGHER SECONDARY SCHOOL, AKKALMADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2136", district:"4", Block:"242", label:"ST JOHN'S MATRIC HR SEC SCHOOL, PRESS COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2137", district:"21", Block:"21", label:"SMS VIMAL MHSS, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2138", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, SAVANDAPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2139", district:"30", Block:"385", label:"Pulliman Matric Higher Secondary School, Panagudi", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2140", district:"8", Block:"20", label:"PUMS THAMMUREDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2141", district:"36", Block:"74", label:"PANCHAYAT UNION MIDDLE SCHOOL MOONGAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"2142", district:"27", Block:"55", label:"SRI.SAKTHI.VINAYAKAR MATRIC SCHOOL - CUMBUM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2143", district:"27", Block:"49", label:"MPL MS, EAST STREET -  CHINNAMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2144", district:"32", Block:"250", label:"PUMS NACHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2145", district:"8", Block:"244", label:"SAGAR INTERNATIONAL SENIOR SECONDARY SCHOOL,SOLIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2146", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2147", district:"11", Block:"3", label:"GOOD SHEPHERD. MATRICULATION HIGHER SECONDARY SCHOOL - NAGERCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2148", district:"3", Block:"195", label:"SRI MYLAI KARPAGAVALLI MATRICUATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2149", district:"8", Block:"65", label:"PUMS, THANNEER PANDHAL PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2150", district:"33", Block:"260", label:"SRI SATHYA SAI VIDYALA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2151", district:"11", Block:"124", label:"ROSE OF SHARON THOZHICODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"2152", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, KAVANDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2153", district:"22", Block:"61", label:"RELIANCE MATRIC HR. SEC. SCHOOL,IDAPPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2154", district:"8", Block:"65", label:"CMS, THIRUNAGAR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"2155", district:"30", Block:"361", label:"Nivedita Mat. HS, Palaya Pettai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2156", district:"17", Block:"360", label:"PANCHAYAT UNION MIDDLE SCHOOL, SIRUMOLASI", Sch_type:"Government", Category:"Middle School"},
    { key:"2157", district:"21", Block:"210", label:"VIVEKANANDHA MATRIC.HR.SEC.SCHOOL SAYANAPURAM-NEMILI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2158", district:"32", Block:"104", label:"PUMS KANGAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2159", district:"22", Block:"61", label:"AMALA MATRIC  HR. SEC. SCHOOL,MORASAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2160", district:"32", Block:"145", label:"VSV MAT. HR.SEC.SCHOOL METTUKKADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2161", district:"4", Block:"349", label:"SRI RAGAVENDRA VIDYALAYA MATRIC. HR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2162", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, S.T.SETHURAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2163", district:"28", Block:"297", label:"TNDTA MS AGARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2164", district:"30", Block:"222", label:"GOOD SAMARITTAN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2165", district:"33", Block:"403", label:"SATHEESH BALAJI MS,MADHANAKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2166", district:"3", Block:"237", label:"ST MARY'S MATRICULATION GIRLS HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2167", district:"31", Block:"103", label:"PUMS, THATHANKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2168", district:"4", Block:"248", label:"GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2169", district:"4", Block:"52", label:"CHINMAYA VIDYALA MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2170", district:"20", Block:"99", label:"SAINT JOSEPH'S RC MIDDLE SCHOOL, KAMUTHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2171", district:"33", Block:"64", label:"PUMS, MELMALIGAIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"2172", district:"3", Block:"304", label:"SRM NIGHTINGALE MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2173", district:"33", Block:"253", label:"PUMS, VELLATHUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2174", district:"22", Block:"90", label:"GOLDEN RAYS MATRIC SCHOOL, KADAYAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"2175", district:"27", Block:"375", label:"BENEDICT MATRIC HIGHER SECONDARY SCHOOL - ANAIMALAYANPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2176", district:"36", Block:"393", label:"OM SAKTHI NARAYANI MATRIC HSS, SRIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2177", district:"11", Block:"355", label:"GMS THIRUVITHANCODE", Sch_type:"Government", Category:"Middle School"},
    { key:"2178", district:"33", Block:"403", label:"MUN.MID.SCH MANGALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2179", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERIYAKALLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2180", district:"17", Block:"165", label:"VIDYAA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL, KOOTHANATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2181", district:"33", Block:"260", label:"VELAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, SURAPET , CHENNAI -600066", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2182", district:"38", Block:"28", label:"SRI SOWDAMBIKA CONVENT MATRICULATION HSSCHOOL, CHETTIKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2183", district:"38", Block:"282", label:"KCAD CHIDAMBARAM GNANAGIRI MATRICULATION HIGHER SECONDARY SCHOOL, SATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2184", district:"11", Block:"191", label:"MARGREGORIOS MATRIC HSS KIRATHOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2185", district:"30", Block:"222", label:"CORPN NEW MS VANNARPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2186", district:"14", Block:"158", label:"MAHATMA MONTESSORI MATRIC HR SEC SCHOOL, K.K NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2187", district:"19", Block:"18", label:"INFANTJESUS MAT.HSS.ANNAVASAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2188", district:"14", Block:"158", label:"ANNAMALAIYAAR MATRIC HR SEC SCHOOL, ANNA NAGAR EAST", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2189", district:"28", Block:"320", label:"PUMS SELVARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2190", district:"3", Block:"2", label:"GURU NANAK MATRIC. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2191", district:"8", Block:"244", label:"PUMS, VETTAIYANKINAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2192", district:"11", Block:"149", label:"KALAIVANI MATRIC SCHOOL ,CHETHOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2193", district:"31", Block:"362", label:"CSI GIRLS MATRIC HSS, TIRUPATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2194", district:"30", Block:"174", label:"PUMS VADAKKIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"2195", district:"24", Block:"119", label:"SHALOM MATRICULATION HIGHER SECONDARY SCHOOL, ,PAVOORCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2196", district:"31", Block:"208", label:"ARSHIYA FATHIMA MATRIC SCHOOL RAMANAICKENPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"2197", district:"29", Block:"343", label:"BHARATHI MATRIC HR SEC SCHOOL, KK NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2198", district:"30", Block:"204", label:"ST. FRANCIS MATRICULATION  HIGHER SECONDARY SCHOOL, NAMBINAGAR, NANGUNERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2199", district:"30", Block:"222", label:"THE GOLDEN JUBILEE MATRIC HSS MELAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2200", district:"38", Block:"282", label:"OXFORD MATRICULATION SCHOOL, SATTUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2201", district:"28", Block:"351", label:"BHARAT RATNA KAMARAJAR  MATRIC HR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2202", district:"28", Block:"297", label:"TNDTA MS ERAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2203", district:"17", Block:"224", label:"SSRI VALLIAPPA VIDHYALAYAM MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2204", district:"4", Block:"245", label:"ELLAPPA MATRICULATION SCHOOL, KUNIAMUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2205", district:"33", Block:"289", label:"PUMS,MONDIAMMAN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2206", district:"30", Block:"222", label:"C.S.I. MHSS PALAYAMKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2207", district:"30", Block:"222", label:"SRI SATHYA SAI GURUGULAM MHSS", Sch_type:"Un-aided", Category:"High School"},
    { key:"2208", district:"8", Block:"65", label:"INDU INTERNATIONAL SCHOOL, VANNANKATTU VALASU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2209", district:"28", Block:"320", label:"ST JOSEPH'S CONVENT MATRIC HR.SEC.SCHOOL, VIRAPANDIANPATNAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2210", district:"36", Block:"113", label:"VIDYANIKETAN MATRIC HSS, GANDHINAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2211", district:"25", Block:"239", label:"MUVENDAR MATRICULATION HIGHER SECONDARY SCHOOL - PERAVURANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2212", district:"27", Block:"319", label:"GOVT MS - THAPPUKUNDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2213", district:"4", Block:"248", label:"PUMS, KUNJIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2214", district:"22", Block:"163", label:"JAYAM MATRIC. HR. SEC. SCHOOL, NALLANAMPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2215", district:"33", Block:"403", label:"MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2216", district:"33", Block:"403", label:"GOOD SHEPHERD MAT AMBATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2217", district:"11", Block:"263", label:"SWAMY VIVEKANANDA MATRICULATION SCHOOL ATHIKATTUVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2218", district:"34", Block:"238", label:"CARMEL MATRIC HIGHER SECONDARY SCHOOL KOLLAPPALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2219", district:"32", Block:"371", label:"PUMS CHINNAVEERAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2220", district:"17", Block:"267", label:"AVM AIDED MIDDLE SCHOOL,RASIPURAM.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2221", district:"26", Block:"72", label:"TEWS MAT HR SEC SCHOOL PANDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2222", district:"34", Block:"238", label:"PUMS NAMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2223", district:"31", Block:"362", label:"PUMS PASALIKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2224", district:"31", Block:"155", label:"IELC HR SEC SCHOOL FOR THE DEAF -AMBUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"2225", district:"17", Block:"232", label:"SKV MATRIC HIGHER SECONDARY SCHOOL, M. KANDAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2226", district:"3", Block:"304", label:"VADAPALANI MAT HR SEC SCH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2227", district:"34", Block:"365", label:"GANDHI NAGAR MATRIC HSS -SO.KILNACHIPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2228", district:"25", Block:"11", label:"UKKADAI A.VISWANATHA DEVAR MATRICULATION SCHOOL, AMMAPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2229", district:"4", Block:"107", label:"SRSI MATRIC. HR SEC SCHOOL, KARAMADAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2230", district:"4", Block:"245", label:"T V SEKHARAN MEMORIAL MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2231", district:"28", Block:"369", label:"PUMS SATHYA NAGAR, UDANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2232", district:"8", Block:"20", label:"PUMS PUDUMETTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2233", district:"4", Block:"52", label:"HOLY FAMILY CONVENT MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2234", district:"11", Block:"355", label:"CSI PUBLIC MATRIC HR SEC SCHOOL, AZHAGIAMANDAPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2235", district:"4", Block:"245", label:"MEWS MATRICULATION SCHOOL POTHANUR MAIN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2236", district:"17", Block:"200", label:"PUMS MOOLAPALLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2237", district:"4", Block:"247", label:"PUMS VADAKKIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2238", district:"17", Block:"267", label:"SRI GANAPATHI VILAS GOVERNMENT AIDED MIDDLE SCHOOL,PATTANAM MUNIYAPPAM PALAYAM ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2239", district:"32", Block:"363", label:"SHRI VALLI VIDHYALAYAA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2240", district:"3", Block:"270", label:"SRI VENKATESWARA MAT SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2241", district:"8", Block:"20", label:"PUMS KEELVANI", Sch_type:"Government", Category:"Middle School"},
    { key:"2242", district:"11", Block:"341", label:"GMS. THOTTAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2243", district:"25", Block:"314", label:"T.K.R. MS-AYYANKADAI THERU-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2244", district:"24", Block:"119", label:"A G MAT S SURANDAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2245", district:"14", Block:"328", label:"GRACE MATRIC HR SEC SCHOOL, JAIHINDPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2246", district:"4", Block:"302", label:"PUMS KAMACHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2247", district:"38", Block:"282", label:"DR. RADHAKRISHNAN MATRIC HIGHER SECONDARY SCHOOL, SATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2248", district:"29", Block:"172", label:"DON BOSKO MATRIC HIGH SCHOOL,SOUTH IRUNGALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2249", district:"27", Block:"40", label:"PUMS -  KUNDALNAYAKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2250", district:"32", Block:"250", label:"PUMS SEMALAIGOUNDENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2251", district:"3", Block:"259", label:"ANNA ADARSH MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2252", district:"31", Block:"5", label:"SAINT FRANCIS DE SALES MATRIC HSS, ALANGAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2253", district:"31", Block:"362", label:"PUMS T. KRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2254", district:"4", Block:"156", label:"Dr.V.GENGUSWAMY NAIDU MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2255", district:"8", Block:"20", label:"PUMS EPPATHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2256", district:"28", Block:"350", label:"TNDTA MS SILVERPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2257", district:"27", Block:"49", label:"PUMS - T.PUDHUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2258", district:"28", Block:"351", label:"FATIMA MS, THOOTHUKUDI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2259", district:"3", Block:"237", label:"VEERA SAVARAKAR NETAJI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2260", district:"17", Block:"132", label:"PUMS PERAKKARAINADU", Sch_type:"Government", Category:"Middle School"},
    { key:"2261", district:"4", Block:"349", label:"EVANGELINE MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2262", district:"20", Block:"335", label:"RAJAN MATRICULATION HIGH SCHOOL, THIRUVADANAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2263", district:"22", Block:"274", label:"SRI SARADA BALAMANDIR. BOYS MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2264", district:"8", Block:"65", label:"KONGU VIDHYALAYA MATRICULATION SCHOOL, MANICKAMPALAYAM, ERODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"2265", district:"29", Block:"367", label:"SERVITE MATRICULATION HIGHER SECONDARY SCHOOL,KEELAPUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2266", district:"25", Block:"314", label:"MORNING STAR MATRICULATION SCHOOL THANJAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2267", district:"20", Block:"265", label:"WINNER'S MATRICULATION HIGH SCHOOL, R.S.MANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2268", district:"31", Block:"362", label:"OUR LADY'S MATRICULATION SCHOOL THIRUPATTUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2269", district:"30", Block:"361", label:"Socratees Mat. HS, Tvl", Sch_type:"Un-aided", Category:"High School"},
    { key:"2270", district:"34", Block:"312", label:"VETRI VIKAAS MATRICULATION SCHOOL-VAZHAVACHANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2271", district:"22", Block:"274", label:"BALA BARATHI MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2272", district:"28", Block:"350", label:"BMC MATRIC HR.SEC.SCHOOL, THOOTHUKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2273", district:"32", Block:"73", label:"OXFORD MATRIC HSS-KONGALNAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2274", district:"31", Block:"155", label:"PUMS KADHAVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2275", district:"4", Block:"349", label:"SREE RAMAKRISHNA VIDHYA MANDIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2276", district:"25", Block:"11", label:"SRI LAKSHMI VIDYALAYA MATRICULATION SCHOOL, AMMAPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2277", district:"32", Block:"32", label:"PANCHAYAT UNION MIDDLE SCHOOL  PERANAICKAN PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2278", district:"32", Block:"32", label:"TIRUPUR PUBLIC MATRIC SCHOOL , RAKYAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2279", district:"26", Block:"72", label:"ST THOMAS MATRIC HSS AYYANKOLLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2280", district:"6", Block:"218", label:"SRI VIDYA MANDIR MHSS, PALACODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2281", district:"34", Block:"238", label:"PUMS SEMMAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2282", district:"36", Block:"113", label:"BETHEL MATRIC HSS, VALLIMALAI ROAD ,KATPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2283", district:"32", Block:"250", label:"SUBBA NAIDU VENKITTAMMAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2284", district:"38", Block:"28", label:"SIVANANDA VIDYALAYAM MATRIC HSS AATHIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2285", district:"8", Block:"65", label:"AL AMEEN METRICULATION HSS, VILLARASAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2286", district:"28", Block:"351", label:"ST THOMAS MATRIC HSS, INNACIARPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2287", district:"25", Block:"313", label:"BEST MATRICULATION HIGHER SECONDARY SCHOOL,  MARIYAMMANKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2288", district:"11", Block:"341", label:"Calvary Matriculation Hr. Sec. School, Kolvel", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2289", district:"32", Block:"371", label:"STELLA MARIS MATRIC HSS,RAGALBAVI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2290", district:"14", Block:"328", label:"ROYAL PUBLIC MATRIC HIGH  SCHOOL, BETHANIYAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2291", district:"38", Block:"110", label:"ST.MARYS MATRICULATION SCHOOL KARIAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2292", district:"1", Block:"26", label:"THE ADITYA BIRLA MATRICULATION SCHOOL REDDIPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2293", district:"30", Block:"222", label:"ROSE MARY MHSS MAHARAJANAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2294", district:"25", Block:"11", label:"FATHIMA MATRIC HIGHER SECONDARY SCHOOL - KOVILUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2295", district:"28", Block:"320", label:"CENTRAL MATRIC HR SEC SCHOOL, KAYALPATNAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2296", district:"3", Block:"62", label:"VIDYODAYA MATRICULATION HIGHER SECIONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2297", district:"30", Block:"9", label:"LAKSHMI VIDYA NIKETAN MATRICULATION HIGHER SECONDARY SCHOOL, VAGAIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2298", district:"25", Block:"344", label:"STAR MATRIC HR. SEC. SCHOOL -THIRUBUVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2299", district:"4", Block:"242", label:"BAPTIST ACADEMY MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2300", district:"22", Block:"203", label:"SRI NACHIYAR MATRIC SCHOOL, MALAYAMPALAYAM, JALAGANDAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2301", district:"4", Block:"242", label:"ANGAPPA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2302", district:"21", Block:"408", label:"GANDHI MIS VI AIDED M S WALAJAPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2303", district:"27", Block:"49", label:"THAI MATRIC SCHOOL - CHINNAMANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2304", district:"25", Block:"313", label:"PUMS - KEELAVASTHACHAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2305", district:"33", Block:"403", label:"MUN.MS, CHOLAMPEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2306", district:"8", Block:"10", label:"PUMS MASAGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2307", district:"29", Block:"367", label:"ATMRCM Vasavi Vidhyalaya Matriculation School, Cantonment.", Sch_type:"Un-aided", Category:"High School"},
    { key:"2308", district:"17", Block:"360", label:"MUNICIPAL MIDDLE SCHOOL, T.KOLLAPATTI WARD 30", Sch_type:"Government", Category:"Middle School"},
    { key:"2309", district:"6", Block:"58", label:"PARAMVEER BANASINGH VIDYA ACADEMY OF EXCELLENCE MHSS, AATUKKARANPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2310", district:"24", Block:"307", label:"PUMS, KARISALKUDIYIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"2311", district:"33", Block:"337", label:"PUMS, ATHIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"2312", district:"33", Block:"260", label:"THE BELOVED MATRICULATION HIGHER SECONDARY SCHOOL , MATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2313", district:"28", Block:"217", label:"TNDTA MS, V. KALUKASALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2314", district:"17", Block:"232", label:"KANDASWAMI KANDAR MATRIC HR.SEC.SCHOOL, VELUR,NAMAKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2315", district:"30", Block:"9", label:"MERIT MHSS,AMBASAMUDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2316", district:"34", Block:"312", label:"LOURDU MATHA MATRIC HR SEC SCHOOL THANDRAMPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2317", district:"30", Block:"46", label:"THIRU GNANA SAMBANDAR MIDDLE SCHOOL  VEERAVANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2318", district:"28", Block:"297", label:"ST ANTONYS RC MS MUKKANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2319", district:"25", Block:"345", label:"PUMS-KADUVETTI VIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"2320", district:"22", Block:"61", label:"S.K.T. MATRIC HR. SEC. SCHOOL, POOLAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2321", district:"29", Block:"366", label:"VISCOUNTESS GOSHEN GOVT. GIRLS MUSLIM HSS, TRICHY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"2322", district:"25", Block:"287", label:"PUMS- UMATHANADU", Sch_type:"Government", Category:"Middle School"},
    { key:"2323", district:"14", Block:"161", label:"SDH JAIN VIDYALAYA MATRIC HR SEC SCHOOL, THIRUPPALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2324", district:"22", Block:"273", label:"MODERN MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2325", district:"25", Block:"313", label:"C.S. MATRICULATION HIGHER SECONDARY SCHOOL, MARIYAMMANKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2326", district:"20", Block:"231", label:"T.E.L.C. MIDDLE SCHOOL, PARAMAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2327", district:"28", Block:"320", label:"STARS' MODEL MATRICULATION HIGHER SECONDARY SCHOOL, KUMARAPURAM - TIRUCHENDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2328", district:"25", Block:"228", label:"MANI MATRIC HSS - KABISTHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2329", district:"8", Block:"65", label:"NIRMALA MATHA CONVENT MATRICULATION HSS, MOOLAKA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2330", district:"8", Block:"45", label:"PANCHAYAT UNION MIDDLE SCHOOL, EKKATTAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2331", district:"31", Block:"155", label:"SRI SARASWATHI VIDYALAYA MATRIC HSS, PALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2332", district:"17", Block:"201", label:"KONGUNADU MATRIC HIGHER SECONDARY SCHOOL, NALLIPALAYAM, NAMAKKAL DT - 637003", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2333", district:"4", Block:"13", label:"PUMS NARASIMMAN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2334", district:"18", Block:"236", label:"ARUTHRAA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2335", district:"25", Block:"216", label:"LONDON KRISHNAMOORTHI MHSS-OND", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2336", district:"33", Block:"338", label:"PUMS, AYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2337", district:"6", Block:"230", label:"ANNAI INDIA MHSS, MALLAPURAM, BOMMIDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2338", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, ATHEMCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"2339", district:"11", Block:"263", label:"ST. ANTONY'S MS CHENBAGARAMANPUTHENTHURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2340", district:"8", Block:"281", label:"LITTLE FLOWER MATRIC HIGHER SECONDARY SCHOOL, SATHYAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2341", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2342", district:"25", Block:"157", label:"PUMS - PULAVANCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"2343", district:"34", Block:"395", label:"PUMS IRUMARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2344", district:"22", Block:"273", label:"GLAZE BROOKE MATRIC HR. SEC. SCHOOL, N.M.ADIVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2345", district:"38", Block:"407", label:"VMGRR SARADHA SM HSS, VIRUDHUNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2346", district:"32", Block:"371", label:"PUMS K VALLAKUNDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2347", district:"32", Block:"363", label:"RAJALINGAM KALVI NILAYAM,AIDED MDL SCHOOL, AMARAVATHIPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2348", district:"11", Block:"182", label:"NATIONAL MAT.S. ARUMANAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2349", district:"30", Block:"92", label:"ST XAVIER'S MS XAVIERPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2350", district:"24", Block:"119", label:"TDTA MS KURUMBALAPERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2351", district:"14", Block:"283", label:"R.C.M.S. P.SETTIAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2352", district:"32", Block:"371", label:"MMS EXTENSION", Sch_type:"Government", Category:"Middle School"},
    { key:"2353", district:"3", Block:"368", label:"CMS BALAJI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2354", district:"17", Block:"132", label:"PUMS VASALURPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2355", district:"6", Block:"77", label:"PUMS NAARIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2356", district:"34", Block:"412", label:"PUMS  VANNANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2357", district:"23", Block:"272", label:"MARUTHA MALAIYAN MAT SCHOOL KARIKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2358", district:"8", Block:"10", label:"PUMS KALPAVI", Sch_type:"Government", Category:"Middle School"},
    { key:"2359", district:"3", Block:"237", label:"RADIANCE MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2360", district:"21", Block:"24", label:"ST. PIO MATRIC HIGH  SCHOOL,  UPPUPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2361", district:"31", Block:"155", label:"PUMS CHINNAVARIGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2362", district:"20", Block:"99", label:"T.E.L.C. MIDDLE SCHOOL, ARANMANAIMEDU, KAMUTHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2363", district:"8", Block:"202", label:"PANCHAYAT UNION MIDDLE SCHOOL, ANDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2364", district:"4", Block:"245", label:"EINSTEIN PUBLIC MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2365", district:"30", Block:"204", label:"PUMS PUTHUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2366", district:"32", Block:"363", label:"PUMS,APPIYAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2367", district:"8", Block:"244", label:"ST.JOSEPH'S R.C. MIDDLE SCHOOL,KALLIYAMPUDUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2368", district:"17", Block:"224", label:"PUMS PUDUPALAYAM CAUVERY", Sch_type:"Government", Category:"Middle School"},
    { key:"2369", district:"28", Block:"217", label:"PUMS ANAINTHAMADANPATCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"2370", district:"17", Block:"360", label:"R .VIDYUT MATRIC  HIGHER SEC SCHOOL,", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2371", district:"17", Block:"200", label:"PUMS ESWARAMURTHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2372", district:"8", Block:"65", label:"AMS,LALITHA KALVI NILAYAM, ERODE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2373", district:"34", Block:"14", label:"PUMS MELNEMELI", Sch_type:"Government", Category:"Middle School"},
    { key:"2374", district:"34", Block:"93", label:"PUMS PATHIYAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2375", district:"33", Block:"364", label:"PUMS - PERIAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2376", district:"8", Block:"10", label:"PUMS CHINNAPARUVACHI", Sch_type:"Government", Category:"Middle School"},
    { key:"2377", district:"4", Block:"349", label:"PUMS IRUTTUPALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2378", district:"6", Block:"218", label:"PUMS KADAMADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2379", district:"25", Block:"344", label:"SOWRASTRA MATRIC HS-THIRUBUVANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2380", district:"27", Block:"40", label:"MOOVENDAR MIDDLE SCHOOL - BODI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2381", district:"17", Block:"232", label:"SKV VIDHYAASHRAM SENIOR SECONDARY SCHOOL M.KANDAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2382", district:"34", Block:"27", label:"PUMS POOSIMALAIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2383", district:"32", Block:"104", label:"PUMS SIVIYARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2384", district:"28", Block:"350", label:"SRI KALAIMAGAL VIDHYALAYA MATRICULATION HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2385", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, ABIRAMAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2386", district:"33", Block:"337", label:"PUMS, CHINNAMMAPET", Sch_type:"Government", Category:"Middle School"},
    { key:"2387", district:"33", Block:"337", label:"PUMS, CHAKKARAMALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2388", district:"8", Block:"65", label:"SRI SATHYA SAI MATRICULATION HIGHER SECONDARY SCHOOL, CHITTODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2389", district:"28", Block:"217", label:"PUMS,ELAVELANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2390", district:"20", Block:"99", label:"S.N.ESMAIL MIDDLE SCHOOL, ABIRAMAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2391", district:"11", Block:"182", label:"DEVAKALA NILAYAM MATRIC HSS-MARUTHANCODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2392", district:"34", Block:"238", label:"PUMS ARASAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"2393", district:"32", Block:"371", label:"PUMS SUNDAKKAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2394", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL. VEERAMAACHANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2395", district:"34", Block:"27", label:"PUMS PANAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2396", district:"27", Block:"375", label:"SRI VIKASA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL - UTHAMAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2397", district:"6", Block:"77", label:"PUMS ERUMIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2398", district:"33", Block:"64", label:"PUMS, NEYVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"2399", district:"33", Block:"225", label:"PUMS, KARLAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2400", district:"27", Block:"55", label:"ANNS MATRIC HR. SEC. SCHOOL, CUMBUM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2401", district:"32", Block:"145", label:"PUMS KALLIVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"2402", district:"24", Block:"91", label:"SRI SANKARA MIDDLE SCHOOL ,KRISHNAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2403", district:"30", Block:"174", label:"PUMS,VENKATACHALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2404", district:"38", Block:"282", label:"SRI VENKATESWARA MATRICULATION SCHOOL, SATTUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2405", district:"34", Block:"412", label:"PUMS AMMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2406", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL ANUMANDHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2407", district:"24", Block:"288", label:"GOVERNMENT MIDDLE SCHOOL,THERKUMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2408", district:"32", Block:"363", label:"PUMS, BOYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2409", district:"17", Block:"360", label:"SENGUNTHAR KALVI NILAYAM WARD 6", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2410", district:"11", Block:"3", label:"ST.FRANCIS MATRIC SCHOOL- SARAKKALVILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2411", district:"17", Block:"285", label:"PUMS VADUGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2412", district:"34", Block:"412", label:"PUMS PUDUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2413", district:"34", Block:"238", label:"PUMS ANMARUDAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2414", district:"27", Block:"375", label:"PUMS - GANAMMAN KOVIL STREET, UTHAMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2415", district:"28", Block:"320", label:"AROCKIA ANNAI MIDDLE SCHOOL, ADAIKALAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2416", district:"31", Block:"155", label:"PUMS C.V.PATTARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2417", district:"28", Block:"350", label:"PUMS DEVAR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"2418", district:"34", Block:"14", label:"PUMS ALATHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2419", district:"17", Block:"224", label:"PUMS  VILANKATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2420", district:"34", Block:"412", label:"PUMS AAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2421", district:"36", Block:"74", label:"PUMS RAJAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2422", district:"11", Block:"263", label:"GMS CHOTHAVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2423", district:"17", Block:"254", label:"PUMS LAKKAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2424", district:"12", Block:"140", label:"PUMS, PAPPAKKAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"2425", district:"17", Block:"360", label:"PANCHAYAT UNION MIDDLE SCHOOL, VALRASAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2426", district:"36", Block:"105", label:"PUMS KATTUPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2427", district:"8", Block:"20", label:"PUMS BEJILATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2428", district:"11", Block:"355", label:"GOVERNMENT MIDDILE SCHOOL,,KANCHIKUZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"2429", district:"33", Block:"76", label:"PUMS AYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2430", district:"21", Block:"408", label:"CNR MATRIC SCHOOL, WALAJA", Sch_type:"Un-aided", Category:"High School"},
    { key:"2431", district:"11", Block:"263", label:"GMS PILLAYARVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2432", district:"8", Block:"65", label:"AMS, RAGUPATHINAIKEN PALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2433", district:"31", Block:"362", label:"PUMS KAVARAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2434", district:"25", Block:"314", label:"ST.PETERS HSS-VADAKKUVEETHI-THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"2435", district:"34", Block:"238", label:"PUMS KONAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2436", district:"17", Block:"85", label:"PUMS ZAMIN ELAMPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2437", district:"11", Block:"263", label:"GMS KATTADITHATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"2438", district:"11", Block:"191", label:"GMS MADICHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2439", district:"24", Block:"119", label:"PUMS -ARIYAPPAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2440", district:"33", Block:"289", label:"PUMS,B.SHANTHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2441", district:"34", Block:"395", label:"PUMS THIRUPPANAMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"2442", district:"14", Block:"328", label:"GOOD LUCK MATRIC HIGH SCHOOL, VILLAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2443", district:"34", Block:"412", label:"PUMS KAMAKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2444", district:"33", Block:"253", label:"PUMS, BEEMANTHOPU", Sch_type:"Government", Category:"Middle School"},
    { key:"2445", district:"27", Block:"319", label:"S.B.KALAIMAGAL MS - KUPPINAYAKANPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2446", district:"17", Block:"285", label:"PUMS NADUCOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2447", district:"27", Block:"49", label:"MPL MS, KARUNKATTANKULAM - CHINNAMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2448", district:"31", Block:"362", label:"LIONS MATRIC HSS THIRUPATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2449", district:"17", Block:"254", label:"PUMS KULLAMANAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2450", district:"28", Block:"8", label:"PUMS PEIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2451", district:"8", Block:"45", label:"PUMS,SIRUKKALANJI", Sch_type:"Government", Category:"Middle School"},
    { key:"2452", district:"27", Block:"375", label:"SRI ONNU RAMASWAMY MIDDLE SCHOOL - THEVARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2453", district:"17", Block:"66", label:"PUMS KALICHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2454", district:"31", Block:"362", label:"PUMS THIRUMAL NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2455", district:"32", Block:"145", label:"PUMS PERIYAKUMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2456", district:"22", Block:"203", label:"MINERRVA MATRIC HIGHER SECONDARY SCHOOL, KATTAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2457", district:"28", Block:"350", label:"TNDTA MS MADATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2458", district:"3", Block:"195", label:"CMS THIRUVALLUVARPET", Sch_type:"Government", Category:"Middle School"},
    { key:"2459", district:"32", Block:"371", label:"PUMS KANNAMANAICKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2460", district:"33", Block:"76", label:"PUMS KAMMAVARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2461", district:"30", Block:"46", label:"COMMITTEE MIDDLE SCHOOL CHERANMAHADEVI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2462", district:"32", Block:"378", label:"GOVERNMENT HIGH SCHOOL, ARUGAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"2463", district:"32", Block:"363", label:"PUMS, MURUGAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2464", district:"13", Block:"78", label:"SEVENTHDAY HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2465", district:"29", Block:"171", label:"HOLY CROSS MATRIC HR. SEC. SCHOOL, SOMARASAMPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2466", district:"29", Block:"343", label:"SANTA MARIA MATRICULATION HIGHER SECONDARY SCHOOL, CRAWFORD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2467", district:"4", Block:"107", label:"HOLY ANGELS MAT HSS , SELVAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2468", district:"33", Block:"261", label:"PUMS,SAHASRAPADMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2469", district:"3", Block:"259", label:"TAGORE MAT HS", Sch_type:"Un-aided", Category:"High School"},
    { key:"2470", district:"4", Block:"247", label:"PKD MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2471", district:"4", Block:"245", label:"MJ VINCENT MATRICULATION HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2472", district:"38", Block:"28", label:"SBKKSS THIYAGARAJAN MEMORIAL MATRICULATION SCHOOL, ARUPPUKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2473", district:"24", Block:"89", label:"VEERA ULGAMMAL MS, CHEKKADIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2474", district:"6", Block:"77", label:"PUMS PATTAVARTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"2475", district:"17", Block:"63", label:"SHRI VIDHYABHARATHI MATRIC HIGHER SECONDARY SCHOOL, SAKKARAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2476", district:"11", Block:"355", label:"CONGREGATIONAL CHRISTIAN MATRICULATION SCHOOL ,VALVACHAGOSTAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2477", district:"3", Block:"259", label:"DANIEL THOMAS MAT HSS KOYAMBEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2478", district:"8", Block:"185", label:"NAVARASAM MATRI HSS,PALLIYUTHU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2479", district:"11", Block:"182", label:"MOUNT CARMEL MATRIC HIGHER SECONDARY SCHOOL, MUZHUCODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2480", district:"24", Block:"6", label:"PUMS, Ayyanarkulam", Sch_type:"Government", Category:"Middle School"},
    { key:"2481", district:"24", Block:"388", label:"RAMALINGAM MS, PULIANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2482", district:"30", Block:"222", label:"ST PAULS MATRIC SCHOOL PERUMALPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2483", district:"33", Block:"252", label:"GOOD SHEPHERD MATRICULATION  HIGHER SECONDARY  SCHOOL, AVADI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2484", district:"38", Block:"295", label:"KMKA MATRICULATION HIGHER SECONDARY SCHOOL, THIRUTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2485", district:"4", Block:"242", label:"PUMS BETTADHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2486", district:"6", Block:"218", label:"PUMS CHICKAMARANDAHALLI PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2487", district:"11", Block:"191", label:"ABC MATRIC H.S.S. KOLLEMCODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2488", district:"22", Block:"274", label:"JAYARANI MATRIC HR. SEC. SCHOOL, NETHIMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2489", district:"34", Block:"365", label:"PUMS SU. ANDAPPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"2490", district:"11", Block:"149", label:"Prof.R.K.Parthasarathy Hindu Vidyalaya Matriculation School Thingalnagar", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2491", district:"4", Block:"248", label:"MMS VENKATRAMANAN RD", Sch_type:"Government", Category:"Middle School"},
    { key:"2492", district:"34", Block:"412", label:"PUMS PALAYA EGAMBARANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2493", district:"34", Block:"14", label:"PUMS MADIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2494", district:"32", Block:"392", label:"KONGU VELLALAR MHSS, VELLAKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2495", district:"24", Block:"6", label:"R.C. MS, Uthumalai", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2496", district:"4", Block:"301", label:"PUMS PAPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2497", district:"28", Block:"297", label:"ST MARYS MS PAZHAYAKAYAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2498", district:"28", Block:"8", label:"TNDTA MS KADAYANODAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2499", district:"8", Block:"65", label:"PUMS MOLAGOUNDENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2500", district:"25", Block:"42", label:"REV.FR.LXM HSS -THIRUKKATTUPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2501", district:"11", Block:"263", label:"GMS KIDANGANKARAIVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2502", district:"11", Block:"341", label:"SEVENTH DAY M.HSS., PULIYERANKI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2503", district:"3", Block:"270", label:"KAMARAJ MAT HSS", Sch_type:"Un-aided", Category:"High School"},
    { key:"2504", district:"22", Block:"274", label:"C.S.I. GOOD SHEPHERD MATRIC HR. SEC. SCHOOL,FORT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2505", district:"8", Block:"281", label:"GTRMS, NAGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2506", district:"32", Block:"363", label:"PUMS, 15 VELAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2507", district:"13", Block:"311", label:"INECA MAT.SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2508", district:"6", Block:"187", label:"SRI VINAYAGA VIDHYALAYA MHSS, SILLARAHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2509", district:"3", Block:"259", label:"KANCHEEPURAM ARULMIGU EGAMBARANATHAR MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2510", district:"30", Block:"222", label:"JOSE MHSS POTHIGAI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2511", district:"20", Block:"169", label:"NATIONAL ACADEMY ICSE HIGHER SECONDARY SCHOOL,PATTANAMKATHAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2512", district:"17", Block:"132", label:"PUMS PUNGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2513", district:"4", Block:"247", label:"PUMS KURUMBAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2514", district:"11", Block:"149", label:"ST.ANNE'S MATRIC Hr.Sec. SCHOOL, COLACHEL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2515", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, PARAPPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2516", district:"1", Block:"346", label:"SWAMI GIRLS MATRIC HR SEC SCHOOL KEELAPALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2517", district:"25", Block:"144", label:"AMMANKOIL AIDED MS KUMBAKONAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2518", district:"4", Block:"248", label:"OUR LADY OF LOURDES MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2519", district:"11", Block:"191", label:"BADHARUL ISLAM MAT SCHOOL  KALIAKAVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2520", district:"8", Block:"244", label:"PUMS, PAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2521", district:"4", Block:"248", label:"MUNICIPAL MIDDLE SCHOOL RAMAKRISHNA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2522", district:"20", Block:"169", label:"VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2523", district:"14", Block:"328", label:"ST.JOHN PETER MATRIC HIGH SCHOOL, JAIHINDPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2524", district:"6", Block:"58", label:"SRI VIJAY VIDYALAYA BOYS MHSS, DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2525", district:"17", Block:"267", label:"VIDHYA NIKETHAN MATRIC HIGHER SECONDARY SCHOOL,RASIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2526", district:"8", Block:"244", label:"PUMS, KAVUNDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2527", district:"24", Block:"6", label:"JEEVA MONTESSORI MATRIC HR. SEC. SCHOOL,ALANGULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2528", district:"33", Block:"403", label:"SAMUEL HARRIS MATRIC HIGHER SECONDARY SCHOOL AMBATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2529", district:"17", Block:"254", label:"PUMS VEPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2530", district:"25", Block:"144", label:"PUMS - MANAPADAIVEEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2531", district:"3", Block:"237", label:"ST JOSEPH AI HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"2532", district:"4", Block:"279", label:"SRI RAMAKRISHNA MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2533", district:"6", Block:"218", label:"Dr. KRISHNA VIKAS MHSS, MARANDAHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2534", district:"4", Block:"52", label:"VIVEKALAYA MATRICULATION  HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2535", district:"4", Block:"107", label:"PUM SCHOOL, IRUMBARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2536", district:"28", Block:"320", label:"ANNAMMAL MATRICULATION SCHOOL ARUMUGANERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2537", district:"8", Block:"10", label:"R.C.MS CHINNAPALLAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2538", district:"14", Block:"328", label:"SETHURAJAN PADMA MATRIC HR SEC SCHOOL, JAINHINDPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2539", district:"4", Block:"242", label:"HOLY CROSS MATRICULATION HR SEC SCHOOL. KANUVAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2540", district:"4", Block:"302", label:"SARASWATHI VIDYALAYA MATRIC SCHOOL CHINTHAMANI PUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2541", district:"14", Block:"328", label:"ST.CHARLES MATRIC HR SEC SCHOOL, THIRUVALLUVAR NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2542", district:"27", Block:"241", label:"V.K.S.MEMORIAL MS, SEDAPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2543", district:"25", Block:"344", label:"PKS MATRICULATION SCHOOL -THIRUNARAIYUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2544", district:"34", Block:"93", label:"PUMS ANAIVADI COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"2545", district:"27", Block:"40", label:"PUMS-B.DHARMATHUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"2546", district:"34", Block:"365", label:"PUMS MELATHIKKAN", Sch_type:"Government", Category:"Middle School"},
    { key:"2547", district:"4", Block:"279", label:"CMS KRISHNARAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2548", district:"12", Block:"23", label:"ARUMUGAM ACADEMY MATRIC HR.SEC.SCHOOL, ARAVAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2549", district:"4", Block:"19", label:"SWAMI VIVEKANANDA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2550", district:"4", Block:"279", label:"KALAIMAGAL MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2551", district:"25", Block:"144", label:"PONNIYAH RAMJAYAM PUBLIC SCHOOL-VALAYAPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2552", district:"34", Block:"44", label:"BARATH MAT  HSS CHENGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2553", district:"27", Block:"55", label:"SRI MUKTHI VINAYAGAR MS - CUMBUM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2554", district:"11", Block:"263", label:"ISED MATRIC HSS PARAKKAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2555", district:"22", Block:"129", label:"HOLY ANGELS MATRIC HR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2556", district:"29", Block:"172", label:"ANVARUL MUSLIMEEN M.S", Sch_type:"Un-aided", Category:"High School"},
    { key:"2557", district:"25", Block:"314", label:"GNANAM MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2558", district:"11", Block:"354", label:"LUTH M.S. MARUGAL-THALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2559", district:"20", Block:"332", label:"PEARL MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2560", district:"24", Block:"6", label:"Harijan MS, Keelakalangal", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2561", district:"12", Block:"142", label:"SRI KALAIMAGAL MATRIC HR SEC SCHOOL KULITHALAI KARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2562", district:"6", Block:"218", label:"PUMS SEERIYANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2563", district:"6", Block:"77", label:"PUMS KEELANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2564", district:"17", Block:"360", label:"MUNICIPAL MIDDLE SCHOOL, SEETHARAMPALAYAM ", Sch_type:"Government", Category:"Middle School"},
    { key:"2565", district:"28", Block:"369", label:"SALMA MATRIC HIGHER SECONDARY SCHOOL, UDANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2566", district:"14", Block:"4", label:"ROYAL MAT SCHOOL THANICHIYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2567", district:"11", Block:"263", label:"ST. MARY'S MIDDLE SCHOOL POZHIKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2568", district:"17", Block:"360", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEELERIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2569", district:"4", Block:"279", label:"VICTORY VIDHYALAYA MAT. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2570", district:"4", Block:"126", label:"VIVEK VIDYALAYA MATRICULATION HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2571", district:"14", Block:"158", label:"BPM MATRIC HIGH SCHOOL, ANNA NAGAR   ", Sch_type:"Un-aided", Category:"High School"},
    { key:"2572", district:"25", Block:"42", label:"OUR LADY OF HEALTH MATRIC HR. SEC SCHOOL -BUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2573", district:"8", Block:"45", label:"SRI RAJALAKSHMI MATRICULATION HIGHER SECONDARY SCHOOL, KARATTUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2574", district:"4", Block:"248", label:"MMS,VSRA", Sch_type:"Government", Category:"Middle School"},
    { key:"2575", district:"32", Block:"363", label:"MMS, BALAMURUGAN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2576", district:"7", Block:"59", label:"MVM GIRLS MATRIC HSS, ANGUNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2577", district:"21", Block:"359", label:"PUMS VELLAMBI", Sch_type:"Government", Category:"Middle School"},
    { key:"2578", district:"34", Block:"48", label:"PUMS SIRUVELIYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2579", district:"4", Block:"245", label:"MILTON MATRICULATION SCHOOL, CHOKKAMPUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2580", district:"29", Block:"168", label:"MONTFORT MAT.HSS, MANJAMPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2581", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, 16,VELAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2582", district:"34", Block:"365", label:"PUMS CHINNAKALLAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2583", district:"4", Block:"126", label:"VIGHNESWAR VIDHYA MANDHIR MAT.HR. SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2584", district:"28", Block:"351", label:"GEETHA MATRIC HR.SEC.SCHOOL POLPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2585", district:"25", Block:"42", label:"PUMS-VALAMBAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2586", district:"4", Block:"242", label:"PUMS RAKKIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2587", district:"34", Block:"395", label:"PUMS AZHINJALPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"2588", district:"34", Block:"47", label:"ANSALDO MATRIC MARUTHUVAMBADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2589", district:"34", Block:"365", label:"ADWMS SU.VALAVETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2590", district:"29", Block:"15", label:"CHINMAYA VIDYALAYA MATRIC HSS SRIRANGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2591", district:"17", Block:"85", label:"BRINDHAVAN  MATRICULATION SCHOOL, POTHANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2592", district:"4", Block:"242", label:"PUMS THIPPANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2593", district:"6", Block:"218", label:"PUMS VEPPALAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2594", district:"30", Block:"92", label:"TDTA MS PERUMALKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2595", district:"6", Block:"218", label:"PUMS ENDAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2596", district:"11", Block:"263", label:"GMS ASARIPALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2597", district:"24", Block:"288", label:"GOVERNMENT MIDDLE SCHOOL KAMBILI", Sch_type:"Government", Category:"Middle School"},
    { key:"2598", district:"17", Block:"232", label:"PUMS VEERANAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2599", district:"17", Block:"200", label:"PUMS THANDAGOUNDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2600", district:"30", Block:"92", label:"PUMS ERUVADI CENTRAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2601", district:"27", Block:"55", label:"BROOKES MATRIC SCHOOL - HIGHWAVYS", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2602", district:"6", Block:"218", label:"PUMS ERRANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2603", district:"17", Block:"200", label:"SRI VANI MATRICULATION HIGHER SECONDARY SCHOOL THOPPAPPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2604", district:"17", Block:"186", label:"KAAVERI VIDDHYA BHAVAN MATRIC HR.SEC.SCHOOL, OLAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2605", district:"25", Block:"228", label:"ADW MS - MELA VALUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2606", district:"28", Block:"350", label:"RC MS ANTHONIYARPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2607", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, EZHUVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"2608", district:"30", Block:"92", label:"HINDU MS MAVADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2609", district:"25", Block:"144", label:"LITTLE FLOWER MATRICULATION HIGH SCHOOL, KUMBAKONAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2610", district:"17", Block:"267", label:"MMS BARATHIDHASAN SALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2611", district:"6", Block:"218", label:"PUMS PEDHANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2612", district:"33", Block:"337", label:"PUMS, RANGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2613", district:"6", Block:"77", label:"PUMS SORIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2614", district:"33", Block:"403", label:"CHRISTURAJA MATRICULATION SCHOOL,KORATTUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2615", district:"4", Block:"13", label:"SRI LAXMI VIDYA BAVAN MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2616", district:"33", Block:"87", label:"PUMS PUDUPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"2617", district:"34", Block:"412", label:"PUMS KAMAKKURPALAYAM (WEST)", Sch_type:"Government", Category:"Middle School"},
    { key:"2618", district:"11", Block:"341", label:"GMS. BARATHAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2619", district:"11", Block:"149", label:"GMS MELKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2620", district:"17", Block:"132", label:"PUMS VALAPPURNADU", Sch_type:"Government", Category:"Middle School"},
    { key:"2621", district:"30", Block:"204", label:"PU MS NANGUNERI", Sch_type:"Government", Category:"Middle School"},
    { key:"2622", district:"25", Block:"228", label:"PUMS - PERUMANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2623", district:"6", Block:"198", label:"PUMS BOMMASAMUDHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2624", district:"25", Block:"144", label:"PUMS MP MANDABAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2625", district:"11", Block:"182", label:"Abraham James Mem Matric HSS Gnaramvilai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2626", district:"25", Block:"314", label:"CMS - KARANTHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2627", district:"33", Block:"252", label:"SWAMI VIVEKANANDA GURUKULAM PAPPAN CHATRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2628", district:"30", Block:"174", label:"PUMS,THIRUPANIKARISALKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2629", district:"32", Block:"363", label:"MMS,Thennampalayam,", Sch_type:"Government", Category:"Middle School"},
    { key:"2630", district:"4", Block:"247", label:"PUMS ACHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2631", district:"38", Block:"411", label:"RANGARAO LIONS GIRLS MATRICULATION SCHOOL, WATRAP", Sch_type:"Un-aided", Category:"High School"},
    { key:"2632", district:"11", Block:"149", label:"GMS,KOOTUMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2633", district:"17", Block:"254", label:"SHANMUGANANDA AIDED MIDDLE SCHOOL AMMAPALAYAMPUDUR ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2634", district:"3", Block:"304", label:"SHAKESPEARE MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2635", district:"25", Block:"144", label:"MOTHER CLARAC MATRICULATION HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2636", district:"17", Block:"267", label:"PUMS BODINAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2637", district:"23", Block:"79", label:"PUMS MELAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2638", district:"34", Block:"125", label:"ANNAAMALAI MATRIC HR SEC SCHOOL SO PUDHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2639", district:"30", Block:"92", label:"MUTHIAH HINDU MS KOVILPATHU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2640", district:"4", Block:"245", label:"PUMS PERUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2641", district:"17", Block:"200", label:"PUMS KARIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2642", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELASIRUPOTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"2643", district:"28", Block:"351", label:"SDA MATRIC SCHOOL, SB COLONY", Sch_type:"Un-aided", Category:"High School"},
    { key:"2644", district:"34", Block:"48", label:"PUMS-KILPUDUPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2645", district:"28", Block:"297", label:"HINDU MS ALWARTHOPE WEST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2646", district:"6", Block:"218", label:"PUMS PADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2647", district:"27", Block:"375", label:"VIVEKANANDA VIDHYALAYAM MIDDLE SCHOOL - THEVARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2648", district:"32", Block:"363", label:"GMS KOOLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2649", district:"34", Block:"27", label:"PUMS ONDIGUDISAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2650", district:"17", Block:"224", label:"PUMS SANARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2651", district:"32", Block:"223", label:"KATHIRAVAN MATRIC HR.SEC SCHOOL, POOMALUR, MANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2652", district:"25", Block:"313", label:"PUMS - REDDYPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2653", district:"22", Block:"391", label:"SRI VIDYA MANDIR CBSE SCHOOL, SALEM STEEL PLANT", Sch_type:"Un-aided", Category:"High School"},
    { key:"2654", district:"33", Block:"252", label:"AVADI MUNICIPAL MIDDLE SCHOOL GOVARTHANAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"2655", district:"17", Block:"360", label:"MUNICIPAL  MIDDLE SCHOOL, SANARPALAYAM WARD 29", Sch_type:"Government", Category:"Middle School"},
    { key:"2656", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, THALAIVANAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2657", district:"25", Block:"42", label:"PUMS-PALAYAKARIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2658", district:"28", Block:"297", label:"TNDTA MM MS ARUMUGAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2659", district:"21", Block:"359", label:"PUMS PAZHAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2660", district:"17", Block:"200", label:"PUMS PERIYACOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2661", district:"34", Block:"365", label:"PUMS KALLANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2662", district:"22", Block:"309", label:"JAYA BHARATH MATRIC HR. SEC. SCHOOL,VADA CHENNIMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2663", district:"34", Block:"312", label:"RCM MS S.ANDAPATTU", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2664", district:"11", Block:"263", label:"ST. JUDE'S MIDDLE SCHOOL MELA ASRIPALLAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2665", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, VINOBANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2666", district:"34", Block:"47", label:"PUMS KARUNGALIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2667", district:"27", Block:"40", label:"S.K.L.MS - KODANGIPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2668", district:"33", Block:"64", label:"PUMS, RALLAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2669", district:"32", Block:"363", label:"PUMS,NERUPPERICHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2670", district:"11", Block:"149", label:"GMS KATTIMANCODE", Sch_type:"Government", Category:"Middle School"},
    { key:"2671", district:"4", Block:"302", label:"PUMS THOTTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2672", district:"3", Block:"259", label:"MARY CLUBWALA JADHAV SPECIAL HIGHER SECONDARY SCHOOL FOR THE HEARING IMPAIRED", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"2673", district:"33", Block:"64", label:"PUMS, 43 PANAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2674", district:"23", Block:"79", label:"PUMS NAGARAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2675", district:"28", Block:"351", label:"TNDTA MS, TOOVEYPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2676", district:"6", Block:"218", label:"PUMS PERUNGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"2677", district:"21", Block:"408", label:"MMS SRINIVASANPET RANIPET", Sch_type:"Government", Category:"Middle School"},
    { key:"2678", district:"33", Block:"184", label:"PUMS,ENNORE", Sch_type:"Government", Category:"Middle School"},
    { key:"2679", district:"35", Block:"141", label:"PUMS, SITHADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2680", district:"6", Block:"235", label:"PUMS K.PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2681", district:"27", Block:"319", label:"GOVT HSS, K.LAKSHMIPURAM  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"2682", district:"11", Block:"341", label:"GOVT. TRMS MANALODAI", Sch_type:"Government", Category:"High School"},
    { key:"2683", district:"25", Block:"313", label:"PUMS - COODALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2684", district:"28", Block:"320", label:"ST JOHN DE-BRITTO ANGLO INDIAN MATRIC SCHOOL VIRA", Sch_type:"Un-aided", Category:"High School"},
    { key:"2685", district:"3", Block:"195", label:"CMS NS GARDEN", Sch_type:"Government", Category:"Middle School"},
    { key:"2686", district:"34", Block:"238", label:"PUMS THAVANI", Sch_type:"Government", Category:"Middle School"},
    { key:"2687", district:"20", Block:"41", label:"PANCHAYAT UNION MIDDLE SCHOOL, K.VALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2688", district:"34", Block:"412", label:"PUMS SRINIVASAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2689", district:"31", Block:"82", label:"PUMS, KALNARSAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2690", district:"20", Block:"41", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUTHUVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2691", district:"33", Block:"64", label:"PUMS, MANJANKARANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2692", district:"17", Block:"267", label:"PU MIDDLE SCHOOL,POOSARIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2693", district:"11", Block:"341", label:"ST. JOSEPH'S MIDDLE SCHOOL KUTTAIKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2694", district:"34", Block:"47", label:"PUMS UTHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"2695", district:"11", Block:"354", label:"RCMS-ARAL KURUSADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2696", district:"24", Block:"119", label:"RATNA MS-E.MEENACHIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2697", district:"27", Block:"319", label:"PUMS - KATTUNAYAKKANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"2698", district:"33", Block:"252", label:"CSI MID.SCH,THANDURAI.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2699", district:"20", Block:"41", label:"PANCHAYAT UNION MIDDLE SCHOOL, A.PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2700", district:"31", Block:"5", label:"PUMS, NARASINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2701", district:"34", Block:"47", label:"PUMS NARASINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2702", district:"33", Block:"252", label:"SVT (TELUGU) MIDDLE SCHOOL,KAMARAJ NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2703", district:"34", Block:"27", label:"ST.GEMMA MATRIC.HR.SEC.SCHOOL RANDHAMKORATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2704", district:"33", Block:"403", label:"KV HVF CBSE, AVADY", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"2705", district:"34", Block:"47", label:"PUMS GENGAISOODAMANI", Sch_type:"Government", Category:"Middle School"},
    { key:"2706", district:"6", Block:"198", label:"PUMS KOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2707", district:"27", Block:"31", label:"GKMS - KATHIRNARASINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2708", district:"1", Block:"81", label:"PUMS - SOORIYAMANAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2709", district:"6", Block:"218", label:"PUMS KATTAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2710", district:"32", Block:"363", label:"PUMS,KALIPALAYAMPUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2711", district:"25", Block:"313", label:"PUMS - NAGATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"2712", district:"4", Block:"242", label:"PUMS PAPPANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2713", district:"34", Block:"238", label:"PUMS ANAIBOGI", Sch_type:"Government", Category:"Middle School"},
    { key:"2714", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL MEPPUR THANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2715", district:"25", Block:"228", label:"MANI MATRIC HIGH SCHOOL - GANAPATHY AGRAGARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2716", district:"14", Block:"328", label:"M.T.PKN MATRIC HIGHER SECONDARY  SCHOOL, PASUMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2717", district:"34", Block:"48", label:"MMS-BARITHIPURAM (WEST)", Sch_type:"Government", Category:"Middle School"},
    { key:"2718", district:"12", Block:"140", label:"PUMS, KUPPAMETTUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2719", district:"34", Block:"47", label:"PUMS SEMMIYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2720", district:"4", Block:"126", label:"PUMS SULAKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2721", district:"34", Block:"47", label:"PUMS MARUTHUVAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2722", district:"21", Block:"116", label:"PUMS-KUPPAKKALMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2723", district:"4", Block:"242", label:"PUMS MATHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2724", district:"22", Block:"391", label:"SRI VIDYA MANDIR MATRIC HR. SEC. SCHOOL, ELAMPILLAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2725", district:"14", Block:"159", label:"ST.BRITTO MATRIC HIGH SCHOOL, GNANAOLIVUPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2726", district:"33", Block:"87", label:"PUMS USAIN NAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2727", district:"11", Block:"3", label:"GMS DEVAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2728", district:"4", Block:"302", label:"K.M.M. MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2729", district:"3", Block:"2", label:"ALPHA MATRICULATION  HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2730", district:"28", Block:"217", label:"MAHATMA MATRIC HR.SEC SCHOOL, PUTIYAMPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2731", district:"25", Block:"144", label:"TAN ACADEMY MAT HSS KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2732", district:"34", Block:"365", label:"DR. VGN MATRIC HSS SAMUDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2733", district:"38", Block:"282", label:"LALITHA LAL BAHADUR SHASTRI PUBLIC SCHOOL, SADAYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2734", district:"21", Block:"409", label:"ANNE MARIE MATRIC HSS, THIRUPARKADAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2735", district:"34", Block:"412", label:"PUMS(MUSLIM) KANNAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2736", district:"22", Block:"278", label:"BRINDAVAN MATRIC SCHOOL THEVOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2737", district:"25", Block:"314", label:"NAV BHARAT MATRIC HR SEC SCHOOL M.C.ROAD, THANJAVUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2738", district:"21", Block:"359", label:"PUMS MELNELLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2739", district:"23", Block:"79", label:"IQRA MATRIC SCHOOL,, ILAIYANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2740", district:"30", Block:"222", label:"SRInGERI SARAdA VIDYALAYA MATRIC HR SEC SCHOOL THIYAGARAJANAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2741", district:"28", Block:"297", label:"TNDTA MS ANIYABARANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2742", district:"17", Block:"267", label:"PU MIDDLE SCHOOL, ANAIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2743", district:"25", Block:"144", label:"ST.ANTONYS MAT HR SEC SCHOOL. - SAKKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2744", district:"22", Block:"68", label:"SARASWATHI MATRIC SCHOOL, ULIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2745", district:"8", Block:"244", label:"BHARATHI MATRIC HIGHER SECONDARY SCHOOL,SHANMUGAPURAM, VIJAYAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2746", district:"4", Block:"242", label:"PUMS PERIYANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2747", district:"33", Block:"184", label:"G.R.T.M VIVEK VIDHAYALAYA, MALALI NEWTOWN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2748", district:"13", Block:"122", label:"SRI SARADHA MATRIC", Sch_type:"Un-aided", Category:"High School"},
    { key:"2749", district:"25", Block:"144", label:"CHRIST THE KING(BOYS) MAT HSS KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2750", district:"21", Block:"21", label:"SRI KRISHNA MATRIC HSS, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2751", district:"34", Block:"48", label:"PUMS THOZHUPPEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2752", district:"17", Block:"267", label:"SRI VIDYA MANDIR MATRIC HR.SEC.SCHOOL,RASIPURAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2753", district:"6", Block:"218", label:"PUMS GIDDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2754", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, POOPANDIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2755", district:"31", Block:"103", label:"PUMS, NARIYANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"2756", district:"13", Block:"35", label:"ST.KANAKADASA MHSS AMMERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2757", district:"27", Block:"49", label:"ECN MATRIC SCHOOL - ODAIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"2758", district:"32", Block:"363", label:"M.N. CHICKKANNA CHETTIAR MAT HIGH SCHOOL,, PALAYAKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"2759", district:"23", Block:"271", label:"PUMS VELLIYANKUDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2760", district:"35", Block:"141", label:"AMS, THETHIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2761", district:"17", Block:"267", label:"MMS-KATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2762", district:"4", Block:"248", label:"MMS, MARAPETTAI ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"2763", district:"33", Block:"289", label:"ALPHA MAT HSS,MONDIAMMAN NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2764", district:"6", Block:"218", label:"PUMS SOODANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2765", district:"11", Block:"3", label:"DVD HSS KOTTAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"2766", district:"34", Block:"47", label:"PUMS MATTAPIRAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2767", district:"4", Block:"245", label:"CMS RAMAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2768", district:"27", Block:"375", label:"SRI ARAVINDAR BALAR MATRIC HIGHER SECONDARY SCHOOL- UTHAMAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2769", district:"28", Block:"402", label:"PUMS, AyanVirusampaati", Sch_type:"Government", Category:"Middle School"},
    { key:"2770", district:"6", Block:"218", label:"PUMS CHIKKATHORANABETTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2771", district:"33", Block:"260", label:"PUMS, ANDARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2772", district:"5", Block:"148", label:"PUMS THONDAMANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2773", district:"29", Block:"172", label:"SRI SARAVANABHAVA VIDYALAYA MATRIC HR. SEC. SCHOOL,MANNACHANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2774", district:"25", Block:"234", label:"FIVE STAR MATRIC HSS PATTUKKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2775", district:"33", Block:"260", label:"DHARAM HINDUJA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2776", district:"4", Block:"242", label:"INFANT JESUS CONVENT MATRIC HSS,THEKKUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2777", district:"34", Block:"365", label:"RCM MS ALLIKONDAPATU", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2778", district:"13", Block:"122", label:"ST JOSEPHS MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2779", district:"6", Block:"235", label:"PUMS CHINNAPERMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2780", district:"6", Block:"58", label:"SENTHIL MHSS, DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2781", district:"4", Block:"248", label:"PANCHAYAT UNION MIDDLE SCHOOL SULEESWARANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2782", district:"7", Block:"59", label:"PRASIDDHI VIDYODAYA, SELAPPADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2783", district:"12", Block:"112", label:"CHERAN MATRIC.HIGH.SCHOOL, KARUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2784", district:"7", Block:"60", label:"SMBM MATRIC HSS,GTN ROAD, DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2785", district:"33", Block:"403", label:"BHAGYALAKSHMI MATRICULATION HIGH SCHOOL, MADHURAVOYAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2786", district:"4", Block:"52", label:"MANBAUL ULOOM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2787", district:"7", Block:"59", label:"SHRI GURUMUKI VIDHYASHRAM,PUDHUKAPPLIAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2788", district:"23", Block:"79", label:"M N A S MIDDLE SCHOOL SALAIYOOR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2789", district:"29", Block:"367", label:"ST.JOSEPHS ANGLO INDIAN GIRLS HR. SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"2790", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - KALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2791", district:"14", Block:"328", label:"ANUSHA VIDYALAYA MATRIC HIGH SCHOOL, THIRUNAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2792", district:"30", Block:"385", label:"ST PAUL RC MS KAVALKINARU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2793", district:"28", Block:"351", label:"EDUCATIONAL SOCIETY MS, THOOTHUKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2794", district:"6", Block:"58", label:"DON BOSCO MHSS, GUNDALAPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2795", district:"30", Block:"385", label:"TDTA MIDDLE SCHOOL KALANTHAPANAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2796", district:"11", Block:"263", label:"ST. MARY'S HS KESAVANPUTHENTHURAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"2797", district:"25", Block:"330", label:"SRI KUMARA GURUBARAR MATRICULATION  HIGHER SECONDARY SCHOOL-THIRUPPANANDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2798", district:"30", Block:"222", label:"JESURAJA HITECH MATRICULATION SCHOOL,PALAYAMKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2799", district:"7", Block:"60", label:"ST.JOSEPH'S MATRICULATION HSS, WARD 13, DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2800", district:"17", Block:"165", label:"PUMS PALLAKKULI AGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2801", district:"17", Block:"267", label:"SRI BHARATHIYA VIDYA MANDIR MATRIC HR SEC SCHOOL,GURUSAMIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2802", district:"6", Block:"235", label:"PUMS SIDUMANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2803", district:"13", Block:"78", label:"GREEN VALLEY MAT.HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2804", district:"3", Block:"237", label:"RAILWAY MIXED HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2805", district:"24", Block:"119", label:"SRI MUPPUDATHI AMMAN MAT HR.SEC.SC. ADAIKALAPATANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2806", district:"6", Block:"109", label:"ST. PAUL'S MHSS, VELLICHANDHAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2807", district:"3", Block:"237", label:"ANNAI VAILANKANNI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2808", district:"31", Block:"5", label:"PUMS PUDUR MARIMANIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2809", district:"33", Block:"260", label:"VENKATESWARA MATRIC, ERNAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2810", district:"33", Block:"260", label:"MUN MIDDLE SCHOOL, NEHRU NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2811", district:"34", Block:"365", label:"PUMS NALLAN PILLAI PETRAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2812", district:"6", Block:"218", label:"PUMS SAMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2813", district:"3", Block:"237", label:"BHARATHA VIDYALA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2814", district:"13", Block:"35", label:"SRI VIJAYA VIHAS MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2815", district:"11", Block:"355", label:"Smt.R.P.Rajalakshmi Hindu Vidyalaya Mat.Hr.Sec.School,Thuckalay", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2816", district:"6", Block:"218", label:"PUMS CHIKKARTHANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"2817", district:"21", Block:"408", label:"NATIONAL MATRIC SCHOOL, ARCOT", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2818", district:"3", Block:"304", label:"CLARENCE MAT HR SEC SCH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2819", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL -  CHELLANCHERRY", Sch_type:"Government", Category:"Middle School"},
    { key:"2820", district:"21", Block:"21", label:"VALLUVAR MATRIC HSS, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2821", district:"22", Block:"273", label:"P.V. MATRIC HR. SEC. SCHOOL, SIVADHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2822", district:"17", Block:"360", label:"MUNICIPAL MIDDLE SCHOOL, MALAYADIVARAM WARD 24", Sch_type:"Government", Category:"Middle School"},
    { key:"2823", district:"3", Block:"240", label:"VISWA BHARATHI MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2824", district:"15", Block:"293", label:"PUMS MANIGRAMAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2825", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, THERKUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2826", district:"31", Block:"103", label:"PUMS PULIKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2827", district:"11", Block:"124", label:"ST. JHS VALIYAVILAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"2828", district:"32", Block:"363", label:"MMS,RAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2829", district:"4", Block:"302", label:"PUMS NALLAGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2830", district:"13", Block:"78", label:"VAILANKANNI MHSS HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2831", district:"7", Block:"390", label:"INFANT JESUS MATRICULATION SCHOOL,VEDASANDUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2832", district:"6", Block:"218", label:"PUMS ATHIMUTLU", Sch_type:"Government", Category:"Middle School"},
    { key:"2833", district:"5", Block:"148", label:"PUMS THENKUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"2834", district:"4", Block:"13", label:"PUMS PONGALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2835", district:"3", Block:"304", label:"CSI St.Augustine Matric School", Sch_type:"Un-aided", Category:"High School"},
    { key:"2836", district:"11", Block:"124", label:"ST. HELEN HS ENAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"2837", district:"34", Block:"27", label:"PUMS MGR NAGAR PAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2838", district:"32", Block:"250", label:"PUMS PUTHERICHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2839", district:"7", Block:"380", label:"SAKTHISAI MATRICULATION SCHOOL,THANGAMAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2840", district:"28", Block:"350", label:"ICE - KING OF KINGS  MATRICULATION  HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2841", district:"14", Block:"283", label:"P.U.M.S. E.KOTTAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2842", district:"3", Block:"195", label:"CMS PROF SANJEEVI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"2843", district:"6", Block:"218", label:"PUMS THIMMAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2844", district:"14", Block:"283", label:"BHARATHIYAR MATRICULATION  HIGHER SECONDARY  SCHOOL, ELUMALAI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2845", district:"34", Block:"412", label:"AIM MATRICULATION HSS  AAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2846", district:"27", Block:"319", label:"GGMS - VAYALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2847", district:"33", Block:"76", label:"DONBOSCO MS N S NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2848", district:"30", Block:"174", label:"ST ANTONY HSS,ALAVANTHANKULAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"2849", district:"28", Block:"217", label:"PRASANNA MATRIC HIGHER SECONDARY  SCHOOL  PUTHIYAMPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2850", district:"27", Block:"49", label:"C.N.M.S. SIVAKAMI MATRIC HSS - CHINNAMANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2851", district:"28", Block:"280", label:"Sri Kanchi Sankara Bagavathi Vidyalaya Matric Hr.Sec.School, Kommadikottai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2852", district:"25", Block:"344", label:"RAJAMANI MATRICULATION SCHOOL, THIRUNAGESWARAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"2853", district:"23", Block:"272", label:"THE LEADERS MATRICULATION HR SEC SCHOOL KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2854", district:"14", Block:"158", label:"ARULMALAR MATRIC HR SEC SCHOOL, K K NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2855", district:"3", Block:"195", label:"M.Ct.M  CHIDAMBARAM CHETTYAR MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2856", district:"14", Block:"328", label:"AMUDHAM MATRIC HR SEC SCHOOL, PERUNGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2857", district:"17", Block:"201", label:"SURABI MATRICULATION HIGHER SECONDARY SCHOOL, NGGO'S COLONY, NAMAKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2858", district:"31", Block:"362", label:"GOODWILL MATRIC HSS TIRUPATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2859", district:"14", Block:"161", label:"ROYAL VIDYALAYA MATRIC HR SEC SCHOOL, VILANGUDI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2860", district:"27", Block:"319", label:"SRI PADMANAPAN  MS - ALLINAGARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2861", district:"25", Block:"314", label:"ST.JOHN DE BRITTO AIDED MIDDLE SCHOOL-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2862", district:"28", Block:"351", label:"SAKTHI VIDYALAYA  MATRIC SCHOOL, KAMARAJ NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2863", district:"34", Block:"412", label:"PUMS PUNGAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2864", district:"34", Block:"412", label:"PUMS ARAIYALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2865", district:"30", Block:"361", label:"Thayammai Ammal MS, Tirunelveli", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2866", district:"38", Block:"295", label:"MUTHUMARI MATRICULATION HR SEC SCHOOL, THIRUTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2867", district:"34", Block:"358", label:"PUMS KARUMARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2868", district:"34", Block:"48", label:"PUMS THANDARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2869", district:"25", Block:"313", label:"PUMS - KATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2870", district:"22", Block:"203", label:"ISHA VIDHYA RAMANIYAM MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2871", district:"8", Block:"185", label:"ISHA VIDHYA MATRICULATION HIGHER SECONDARY SCHOOL,PERUMAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2872", district:"25", Block:"144", label:"KARTHI VIDHYALAYA  MAT  HR.SEC. SCHOOL KORANATTU KARUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2873", district:"23", Block:"272", label:"KAMBAN KARPAKAM MATRIC HR SEC SCHOOL , KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2874", district:"17", Block:"285", label:"SBM AIDED MIDDLE SCHOOL SENDAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2875", district:"22", Block:"215", label:"SOUTH INDIAN MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2876", district:"14", Block:"303", label:"M.S.R  MATRICULATION  HIGHER  SECONDARY  SCHOOL, T KALLUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2877", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERUNAZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"2878", district:"24", Block:"388", label:"S.R.P MS, SIVAGIRI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2879", district:"4", Block:"242", label:"SRI RANGANATHAR MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2880", district:"28", Block:"350", label:"CHANDY MATRIC HIGH SCHOOL,MUTHIAHPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"2881", district:"28", Block:"217", label:"TNDTA MS KOMBADI THALAVAIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2882", district:"38", Block:"295", label:"THE SIVAKASI LIONS MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2883", district:"7", Block:"59", label:"ST.PATRICKS ACADEMY, C.KALANAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2884", district:"12", Block:"140", label:"PUMS, IRUMBOOTHIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"2885", district:"27", Block:"40", label:"Z.K.M. MAT HSS - BODINAYAKANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2886", district:"11", Block:"149", label:"GMS KALPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2887", district:"21", Block:"408", label:"GANGADHARA MU AIDED MS RANIPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2888", district:"3", Block:"259", label:"ALAGAPPA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2889", district:"34", Block:"44", label:"PUMS KOTTAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2890", district:"1", Block:"81", label:"FATIMA MATRICULATION HIGHER SECONDARY SCHOOL  JAYANKONDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2891", district:"34", Block:"412", label:"PUMS MARUSUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2892", district:"25", Block:"144", label:"ARR MAT HSS KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2893", district:"34", Block:"44", label:"PUMS MELPENNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2894", district:"4", Block:"279", label:"A B C MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2895", district:"7", Block:"213", label:"AKSHAYA ACADEMY MATRIC, KOSAVAPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2896", district:"38", Block:"407", label:"KVS MATRICULATION HSS SCHOOL, VIRUDHUNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2897", district:"8", Block:"65", label:"SRI MARUTHI VIDHYA BHAVAN MATRICULATION HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2898", district:"4", Block:"13", label:"KANDASAMY MATRICULATION HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2899", district:"3", Block:"259", label:"T T A MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2900", district:"23", Block:"79", label:"PUMS MUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2901", district:"5", Block:"148", label:"ST MARY'S MATRICULATION SCHOOL,VADALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2902", district:"13", Block:"78", label:"SRI VENKATESWARA MHSS AVALAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2903", district:"10", Block:"146", label:"P S Balabhavan MHSS Mangadu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2904", district:"31", Block:"103", label:"PUMS CHELLARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2905", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. VILANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2906", district:"21", Block:"290", label:"PUMS, PALAYAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2907", district:"33", Block:"403", label:"EMMANUEL METHoDIST MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2908", district:"35", Block:"173", label:"UAMHSS DHARANI MATRIC ,MANNARGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2909", district:"3", Block:"240", label:"BAYNES MEM BAPTIST CHURCH MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2910", district:"38", Block:"295", label:"LORD P C A A  LIONS MATRICULATION HR SEC SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2911", district:"27", Block:"49", label:"PUMS - THIMMINAYAKANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"2912", district:"6", Block:"218", label:"PUMS PERIYANURPUTHUPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2913", district:"24", Block:"6", label:"TDTA MS, Alangulam", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2914", district:"32", Block:"190", label:"KALAIKOVIL VIDHYA MANDIR MATRIC HIGHER SECONDARY SCHOOL, POLARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2915", district:"3", Block:"304", label:"JRK MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2916", district:"24", Block:"119", label:"HINDU MS-CHINNANADANOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2917", district:"33", Block:"364", label:"PUMS - KARTHIKEYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2918", district:"4", Block:"248", label:"PANCHAYATH UNION MIDDLE SCHOOL, KARATTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2919", district:"3", Block:"304", label:"THAI SATHYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2920", district:"33", Block:"260", label:"ST. PAUL'S MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2921", district:"28", Block:"351", label:"EXTENSION MS, NEW COLONY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2922", district:"30", Block:"174", label:"TDTA MS,NORTH VAGAIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2923", district:"33", Block:"252", label:"VAILANKANNI  MATRICULATION HIGHER SECONDARY, AVADI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2924", district:"34", Block:"14", label:"PUMS PAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2925", district:"25", Block:"313", label:"PUMS - THIRUMALAISAMUDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2926", district:"25", Block:"228", label:"ST. BASTIN'S MATRICULATION HIGHER SECONDARY SCHOOL, PAPANASAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2927", district:"5", Block:"39", label:"PUMS THALAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2928", district:"20", Block:"231", label:"DR.SURESH MATRICULATION HIGH SCHOOL, PARAMAKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2929", district:"21", Block:"21", label:"PUMS, SEMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"2930", district:"17", Block:"267", label:"PUMS PON.KALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2931", district:"28", Block:"350", label:"KAMAK MATRIC HR.SEC. SCHOOL,  SANKARAPERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2932", district:"25", Block:"11", label:"PUMS - RAILADY AMMAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2933", district:"32", Block:"104", label:"PUMS BHARATHIYAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"2934", district:"30", Block:"229", label:"BHARATHI VIDYALAYAM MIDDLE SCHOOL, RENGASAMUDRAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2935", district:"5", Block:"148", label:"PUMS RAMANATHANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2936", district:"13", Block:"122", label:"HOLY CROSS MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2937", district:"7", Block:"212", label:"KAVIYAN MATRIC HIGH SCHOOL AMMAYANAYAKANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2938", district:"33", Block:"252", label:"SRI RAMAKRISHNA MATRIC . HR .SEC .SCHOOL , PATTABIRAM , CH-72", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2939", district:"33", Block:"260", label:"ST THOMAS MS, SATHUMA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2940", district:"5", Block:"143", label:"AMS  KOTHAVASAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2941", district:"27", Block:"375", label:"Sri N.M.R. SUBBARAMAN MIDDLE SCHOOL - KOHILAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2942", district:"4", Block:"386", label:"GOVERNMENT MIDDLE SCHOOL ,SHOLAYAR EST.", Sch_type:"Government", Category:"Middle School"},
    { key:"2943", district:"27", Block:"40", label:"THIRUPPATHI.MS - DOMBUCHERY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2944", district:"31", Block:"103", label:"PUMS SIMMANAPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2945", district:"34", Block:"27", label:"ADWMS PAPPAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"2946", district:"31", Block:"155", label:"BLESSO MATRIC SCHOOL, AMBUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2947", district:"6", Block:"77", label:"PUMS KARUPPILIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2948", district:"6", Block:"77", label:"PUMS NACHINAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2949", district:"23", Block:"79", label:"PUMS KEELANETTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2950", district:"6", Block:"235", label:"PUMS NALLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2951", district:"27", Block:"319", label:"GKMS - KARUVELNAYAKKANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"2952", district:"33", Block:"64", label:"DON BOSCO MATRIC  HR SEC SCHOOL, UTHUKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2953", district:"23", Block:"271", label:"PUMS VARAPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2954", district:"11", Block:"3", label:"GMS VATTAVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2955", district:"17", Block:"360", label:"MUNICIPAL MIDDLE SCHOOL, SURIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2956", district:"24", Block:"288", label:"GOVERNMENT MIDDLE SCHOOL,KARKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"2957", district:"34", Block:"27", label:"MMS SHAFFIULLA ST ARNI", Sch_type:"Government", Category:"Middle School"},
    { key:"2958", district:"4", Block:"301", label:"PUMS BOGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2959", district:"27", Block:"375", label:"PUMS - ANAMALAYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2960", district:"4", Block:"126", label:"PUMS SIKKALAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2961", district:"21", Block:"359", label:"PUMS KALAVAI PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2962", district:"33", Block:"64", label:"PUMS, MAGARAL", Sch_type:"Government", Category:"Middle School"},
    { key:"2963", district:"28", Block:"350", label:"SACRED HEART.MAT.S", Sch_type:"Un-aided", Category:"High School"},
    { key:"2964", district:"21", Block:"359", label:"PUMS RAGHUNATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2965", district:"25", Block:"344", label:"PUMS - SIVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2966", district:"32", Block:"378", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, UTHUKULI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"2967", district:"11", Block:"354", label:"RAMALEKSHMI VIDYALAYA MATRICULATION SCHOOL, THOVALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"2968", district:"34", Block:"14", label:"PUMS ERUMAIVETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2969", district:"25", Block:"144", label:"MPL MS MADULAMPET", Sch_type:"Government", Category:"Middle School"},
    { key:"2970", district:"25", Block:"42", label:"ADWMS-CHINNAKANGEYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"2971", district:"5", Block:"148", label:"PUMS - THANGALIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2972", district:"30", Block:"92", label:"JAWAHAR  NATIONAL MS SALAIPUTHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2973", district:"24", Block:"119", label:"TDTA MS PUTHUSURANDAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"2974", district:"11", Block:"3", label:"GOVERNMENT MIDDLE SCHOOL  SUCHINDRUM", Sch_type:"Government", Category:"Middle School"},
    { key:"2975", district:"13", Block:"117", label:"SRI RAJARAJESWARI VIDHYALAYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2976", district:"6", Block:"218", label:"PUMS SEENGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"2977", district:"28", Block:"280", label:"TDTA MS Kumaran Vila", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2978", district:"4", Block:"386", label:"AMS INJIPARAI LD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2979", district:"33", Block:"76", label:"PUMS PALIKAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"2980", district:"6", Block:"235", label:"PUMS KOMBADIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2981", district:"33", Block:"252", label:"SRI ANNAI VIDYASHARAM, MATRIC HIGHER SECONDARY SCHOOL, THIRUVERKADU.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2982", district:"27", Block:"49", label:"PUMS - CHINNAPOTTIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2983", district:"34", Block:"47", label:"DANIE MATRIC SCHOOL -CHETPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"2984", district:"4", Block:"13", label:"PUMS MANJANAICKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2985", district:"22", Block:"226", label:"SALEM SRI SOWDESWARI MATRIC HR. SEC. SCHOOL, KONDALAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2986", district:"4", Block:"386", label:"PANCHAYAT UNION MIDDLE SCHOOLSIRUKUNDRA L D", Sch_type:"Government", Category:"Middle School"},
    { key:"2987", district:"17", Block:"186", label:"RASI MATRICULATION  SCHOOL, MOHANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"2988", district:"34", Block:"27", label:"MMS SARPANAPET", Sch_type:"Government", Category:"Middle School"},
    { key:"2989", district:"34", Block:"256", label:"PUMS VADAMATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"2990", district:"6", Block:"218", label:"PUMS KADATHIKOLLU", Sch_type:"Government", Category:"Middle School"},
    { key:"2991", district:"3", Block:"240", label:"WISDOM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"2992", district:"27", Block:"319", label:"PUMS -AMBASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2993", district:"32", Block:"378", label:"SARASWATHI KALVI NILAYAM MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"2994", district:"4", Block:"52", label:"CMS TATABAD", Sch_type:"Government", Category:"Middle School"},
    { key:"2995", district:"34", Block:"238", label:"BAVANI AIDED MIDDLE VEPPAMPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2996", district:"25", Block:"144", label:"PUMS KOVELACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"2997", district:"29", Block:"381", label:"DE BRITTO MS, THIRUKAVALUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"2998", district:"6", Block:"235", label:"PUMS K.AGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"2999", district:"25", Block:"234", label:"ANNAI THERASA  MATRIC SCHOOL- SEMBAALUR  EAST PATTUKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3000", district:"33", Block:"364", label:"PUMS - R K STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"3001", district:"4", Block:"13", label:"PUMS SUBBEGOUNDENPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3002", district:"31", Block:"362", label:"PUMS KALIYAMMAN KOIL NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3003", district:"33", Block:"403", label:"BUTTERFLY MAT SCH, AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3004", district:"3", Block:"240", label:"C.S.I MIDDLE SCHOOL MM ST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3005", district:"24", Block:"91", label:"SRI KAILASA MS IDAIKAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3006", district:"27", Block:"319", label:"AMARAVATHI MS - THIRUCHENDUR, KODANGIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3007", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHINNAUDAPPANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3008", district:"28", Block:"280", label:"TNDTA MS, POOVUDAYARPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3009", district:"25", Block:"313", label:"PUMS - RAVUSAPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3010", district:"25", Block:"314", label:"T.E.L.C  MS-MANAMPUCHAVADI-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3011", district:"25", Block:"313", label:"PUMS - KULICHAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"3012", district:"6", Block:"218", label:"PUMS ERRAGUNDAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3013", district:"33", Block:"184", label:"PUMS, ARANGANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3014", district:"25", Block:"313", label:"PUMS - KALAIGNAR  NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3015", district:"22", Block:"34", label:"SRI SWAMY INTERNATIONAL SCHOOL CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3016", district:"11", Block:"149", label:"GMS THOTTICODE", Sch_type:"Government", Category:"Middle School"},
    { key:"3017", district:"8", Block:"281", label:"SOUNDRAM VIDYALAYA MATRICULATION SCHOOL, RAJAN NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3018", district:"21", Block:"359", label:"PUMS MELAPALANTHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3019", district:"21", Block:"359", label:"PUMS MELPUDUPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3020", district:"5", Block:"148", label:"PUMS VADAKUMELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3021", district:"17", Block:"201", label:"PUMS KAVETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3022", district:"17", Block:"200", label:"PUMS ODUVANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"3023", district:"11", Block:"124", label:"HINDU VIDYALAYA MATRIC. HIGHER SECONDARY SCHOOL. MARTHANDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3024", district:"25", Block:"42", label:"PUMS-PAVANAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3025", district:"6", Block:"77", label:"PUMS SANGILIVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3026", district:"27", Block:"40", label:"PUM S- VALAIYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3027", district:"33", Block:"364", label:"PUMS - L N KANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3028", district:"25", Block:"11", label:"PUMS - PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3029", district:"11", Block:"263", label:"GMS MELAKRISHNANPUTHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3030", district:"8", Block:"10", label:"T.C.M. MATRICULATION SCHOOL, SANDAIPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3031", district:"36", Block:"74", label:"MD AMS CHINNATHOTTALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3032", district:"38", Block:"295", label:"SARVASEVA  MATRICULATION SCHOOL,ERICHANATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3033", district:"32", Block:"392", label:"P.U.M.S, VELLAKOVIL-EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"3034", district:"4", Block:"13", label:"PUMS VAZHAIKOMBU", Sch_type:"Government", Category:"Middle School"},
    { key:"3035", district:"31", Block:"362", label:"PUMS, VELANNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3036", district:"34", Block:"27", label:"MMS THACHUR ROAD ( SS KOIL ST)", Sch_type:"Government", Category:"Middle School"},
    { key:"3037", district:"4", Block:"386", label:"AIDED MIDDLE SCHOOL-KARAMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3038", district:"5", Block:"115", label:"PANCHAYAT UNION MIDDLE SCHOOL,  NACHIYARPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3039", district:"11", Block:"341", label:"GMS. MUTHALAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3040", district:"4", Block:"52", label:"TRINITY MATRIC H S S", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3041", district:"32", Block:"371", label:"PUMS ANTHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3042", district:"34", Block:"365", label:"PUMS VELAYAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3043", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL , S.KARAIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3044", district:"4", Block:"248", label:"PUMS,UNJAVELAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3045", district:"32", Block:"32", label:"PANCHAYAT UNION MIDDLE SCHOOL DEVARAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3046", district:"21", Block:"359", label:"PUMS VEMBI COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"3047", district:"31", Block:"82", label:"PUMS, MANDALAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3048", district:"27", Block:"40", label:"15TH WARD MPL MS - BODI", Sch_type:"Government", Category:"Middle School"},
    { key:"3049", district:"4", Block:"245", label:"KPM MATRICULATION HR SEC SCHOOL, EACHANARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3050", district:"21", Block:"21", label:"PUMS NAGARIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3051", district:"30", Block:"222", label:"CORPN MS PERUMAL SANNATHI , PALAYAMKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3052", district:"11", Block:"355", label:"RC MIDDLE SCHOOL VELLICODE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3053", district:"27", Block:"241", label:"PUMS -  NALLAKARUPPANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3054", district:"4", Block:"248", label:"PANCHAYAT UNION MIDDLE SCHOOL,KOLARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3055", district:"33", Block:"338", label:"GOVT. WELFARE MS, PERUMALPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"3056", district:"25", Block:"228", label:"PUMS - EACHANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3057", district:"31", Block:"155", label:"PUMS ATHIMAKULAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3058", district:"23", Block:"166", label:"PUMS SOMATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3059", district:"33", Block:"64", label:"PUMS, POORIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3060", district:"23", Block:"79", label:"St.ANNES MATRICULATION SCHOOL, ILAYANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3061", district:"33", Block:"184", label:"PUMS,ALADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3062", district:"11", Block:"263", label:"GMS ERUMPUKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3063", district:"35", Block:"141", label:"PUMS, ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3064", district:"27", Block:"319", label:"PUMS - UPPARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3065", district:"23", Block:"272", label:"CHIDAMBARAM VISALAKSHI  MATRICULATION  SCHOOL KARAIKUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3066", district:"36", Block:"243", label:"PUMS MORASAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3067", district:"27", Block:"375", label:"T.H.N.U. MATRICULATION HIGHER SECONDARY SCHOOL - THEVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3068", district:"17", Block:"285", label:"PUMS THIRUMALAIGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"3069", district:"11", Block:"191", label:"GOVERNMENT MIDDLE SCHOOL  KIRATHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3070", district:"31", Block:"362", label:"PUMS THATHAVALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3071", district:"36", Block:"105", label:"PUMS SALAMANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3072", district:"3", Block:"2", label:"CMS MGR NAGAR II", Sch_type:"Government", Category:"Middle School"},
    { key:"3073", district:"6", Block:"187", label:"PUMS SAMANDAHALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"3074", district:"34", Block:"412", label:"PUMS PERIYA IYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3075", district:"25", Block:"239", label:"PUMS- VALASAIKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3076", district:"11", Block:"191", label:"GOVERNMENT MIDDLE SCHOOL  PAINKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3077", district:"4", Block:"301", label:"PUMS KAMMALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3078", district:"4", Block:"279", label:"CMS KRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3079", district:"32", Block:"73", label:"PUMS MOONGILTHOLUVU", Sch_type:"Government", Category:"Middle School"},
    { key:"3080", district:"11", Block:"191", label:"CONCORDIA LUTHERAN HIGH SCHOOL,  KALPARAPOTTAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"3081", district:"11", Block:"341", label:"GMS UNNIYOORKONAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3082", district:"34", Block:"93", label:"PUMS SHOLAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3083", district:"31", Block:"103", label:"PUMS SAMATHUVAPURAMH", Sch_type:"Government", Category:"Middle School"},
    { key:"3084", district:"33", Block:"64", label:"PUMS, SEMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"3085", district:"34", Block:"365", label:"PUMS PANDITHAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"3086", district:"27", Block:"241", label:"ST.PATRICKS R.C. MS - THENKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3087", district:"25", Block:"144", label:"PUMS - NATHANKOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"3088", district:"33", Block:"338", label:"PUMS, THALAKANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3089", district:"33", Block:"260", label:"DEIVA SIGAMANI MIDDLE SCHOOL, , KALADIPET, THIRUVOTTIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3090", district:"34", Block:"395", label:"PUMS SOZHAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3091", district:"14", Block:"324", label:"KAZHUVATHEVAR MEMORIAL MATRIC HR SEC SCH, K.P.OTHAPPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3092", district:"17", Block:"66", label:"PUMS VADUGAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3093", district:"25", Block:"314", label:"MUDAL THARA INDU AIDED MIDDLE SCHOOL-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3094", district:"12", Block:"348", label:"SERVITE MAT.SCHOOL,THOGAIMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3095", district:"25", Block:"314", label:"RAILWAYCOLONY AIDED MIDDLE SCHOOL-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3096", district:"32", Block:"250", label:"PUMS KOVILPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3097", district:"34", Block:"395", label:"PUMS PILLANTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3098", district:"28", Block:"402", label:"St.Charles MATRICULATION SCHOOL, Vilathikulam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3099", district:"33", Block:"64", label:"PUMS, THAMARAIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3100", district:"27", Block:"55", label:"GOVT KALLAR HS, NARAYANATHEVANPATTY  ", Sch_type:"Government", Category:"High School"},
    { key:"3101", district:"31", Block:"362", label:"PUMS LALAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3102", district:"27", Block:"49", label:"V.U.ANNA MS - CHINNAMANUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3103", district:"4", Block:"302", label:"PUMS AATHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3104", district:"8", Block:"128", label:"PUMS,SIVAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"3105", district:"27", Block:"375", label:"PUMS - BAZZAR, UTHAMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3106", district:"17", Block:"267", label:"SRI AMMAN MATRIC HIGH SCHOOL,MUTHUKALIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"3107", district:"25", Block:"11", label:"PUMS - EDAIIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"3108", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3109", district:"25", Block:"313", label:"PUMS - SURAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3110", district:"33", Block:"87", label:"PUMS THENKARANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3111", district:"3", Block:"240", label:"C.S.I. MIDDLE SCHOOL GM ST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3112", district:"24", Block:"307", label:"PARASAKTHI M HSS,COURTALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3113", district:"3", Block:"2", label:"THIRUVALLUVAR GURUKULAM MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3114", district:"6", Block:"77", label:"PUMS SAKKILIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3115", district:"5", Block:"148", label:"PUMS THAMBIPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3116", district:"3", Block:"195", label:"CMS MK AMMAN KOIL ST", Sch_type:"Government", Category:"Middle School"},
    { key:"3117", district:"27", Block:"319", label:"N.P.V MS - POOMALAIKUNDU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3118", district:"11", Block:"263", label:"GMS PARAKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3119", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELAKODUMALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3120", district:"1", Block:"81", label:"PUMS-PADAINILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3121", district:"24", Block:"91", label:"KRISHNA MID.SCHOOL,KADAYANALLUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3122", district:"34", Block:"256", label:"PUMS PADIAGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3123", district:"28", Block:"297", label:"HINDHU ADI DRAVIDA MS SIVAGALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3124", district:"34", Block:"365", label:"PUMS ISUKAZHIKATTERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3125", district:"34", Block:"93", label:"PUMS PAZHANKOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"3126", district:"14", Block:"160", label:"SRIMAN NAYAKIYAR VIDYA MANDIR MATRIC HR SEC SCHOOL, TEPPAKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3127", district:"34", Block:"93", label:"PUMS VEERALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3128", district:"5", Block:"39", label:"PUMS ADHIVARAGANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3129", district:"17", Block:"85", label:"PUMS KOLAKKATTUPUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3130", district:"4", Block:"248", label:"PUMS,NAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3131", district:"5", Block:"148", label:"PUMS PARVATHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3132", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL KULAVAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3133", district:"28", Block:"257", label:"PUMS MELAKARANTHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3134", district:"6", Block:"235", label:"PUMS ARAKASANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3135", district:"11", Block:"341", label:"GMS KUTTIYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3136", district:"33", Block:"364", label:"PUMS- D V PURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3137", district:"27", Block:"194", label:"PUMS - SINGARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3138", district:"14", Block:"183", label:"LAKSHMI MATRIC HR SEC SCHOOL, THIRUVATHAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3139", district:"6", Block:"218", label:"PUMS KADUKAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3140", district:"34", Block:"47", label:"PUMS SANIKAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3141", district:"3", Block:"2", label:"SRI SARADA MIDDLE SCHOOL WEST MAMBALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3142", district:"34", Block:"27", label:"MMS, P A KOIL ST, ARNI", Sch_type:"Government", Category:"Middle School"},
    { key:"3143", district:"30", Block:"229", label:"RC MS THALARKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3144", district:"27", Block:"194", label:"PUMS - THOPPAIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3145", district:"22", Block:"316", label:"VETHATHIRI MAHARISHI MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3146", district:"33", Block:"289", label:"PUMS, NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3147", district:"32", Block:"363", label:"MMS, POOLAVARISUGUMAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3148", district:"25", Block:"144", label:"NATIONAL SCHOOL FOR THE HEARING IMPAIRED -  KUMBAKONAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3149", district:"33", Block:"260", label:"MUNICIPAL. MIDDLE SCHOOL THIRUVOTRIYUR WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"3150", district:"33", Block:"338", label:"PUMS, VISHNUVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3151", district:"24", Block:"119", label:"PUMS, VELLAKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3152", district:"30", Block:"92", label:"HINDU MARAVAR MS MALAIYADIPUDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3153", district:"17", Block:"85", label:"PUMS SULLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3154", district:"34", Block:"412", label:"PUMS KARIPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3155", district:"33", Block:"184", label:"PUMS, THATHTHAMANJI", Sch_type:"Government", Category:"Middle School"},
    { key:"3156", district:"27", Block:"194", label:"PUMS - KAMARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3157", district:"33", Block:"338", label:"TELC MS, PERIA KUPPAM, TRL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3158", district:"36", Block:"393", label:"PUMS ANPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3159", district:"6", Block:"235", label:"PUMS VANNATHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3160", district:"21", Block:"116", label:"PUMS-UPPARANTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3161", district:"31", Block:"82", label:"PUMS, OOSINATTAN KOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3162", district:"6", Block:"218", label:"PUMS SAKLINATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3163", district:"20", Block:"86", label:"NADAR MAHAJANA SANGAM KALVITHANTHAI K.S.SHANMUGA NADAR MIDDLE SCHOOL, SAYALGUDI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3164", district:"27", Block:"319", label:"PUMS - BALAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3165", district:"25", Block:"313", label:"PUMS - AYYASAMYPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3166", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, UDAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3167", district:"25", Block:"144", label:"THIRUNAVUKARASU AMS KUMBAKONAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3168", district:"27", Block:"375", label:"PUMS - CHOCKALINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3169", district:"17", Block:"360", label:"MUNICIPAL MIDDLE SCHOOL, ARIGNAR ANNA  NAGAR ", Sch_type:"Government", Category:"Middle School"},
    { key:"3170", district:"6", Block:"235", label:"PUMS ONDIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3171", district:"27", Block:"40", label:"PUMS - AMMAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3172", district:"8", Block:"20", label:"PUMS  ERATTIMINTHANGI", Sch_type:"Government", Category:"Middle School"},
    { key:"3173", district:"34", Block:"365", label:"ADWMS ADAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3174", district:"21", Block:"408", label:"THIRUMATHI LAKSHMI LOGANATHAN MATRIC SCHOOL, ARCOT", Sch_type:"Un-aided", Category:"High School"},
    { key:"3175", district:"6", Block:"235", label:"PUMS NAGADASAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3176", district:"21", Block:"359", label:"PUMS SORAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3177", district:"5", Block:"39", label:"AMS VEERAMUDAIYANATHAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3178", district:"27", Block:"375", label:"PUMS - KALIMETTUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3179", district:"30", Block:"361", label:"CORPORATION MIDDLE SCHOOL RAHUMANPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3180", district:"33", Block:"184", label:"PUMS,VELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3181", district:"33", Block:"252", label:"KALAIMAGAL MATRIC HR SEC SCHOOL, ANNA NAGAR PATTABIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3182", district:"6", Block:"235", label:"PUMS AJJANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3183", district:"33", Block:"337", label:"PUMS, VEERARAGAVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3184", district:"30", Block:"174", label:"PUMS,KURICHIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3185", district:"13", Block:"78", label:"PARIMALAM MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3186", district:"4", Block:"242", label:"PUMS ONNIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3187", district:"14", Block:"161", label:"SIDDHU MATRIC HR SEC SCHOOL, VISWANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3188", district:"7", Block:"127", label:"ST THOMAS AQUINAS MATRIC HIGHER SECONDARY SCHOOL,PETHUPARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3189", district:"7", Block:"29", label:"SAKTHIVELAVAN VIDHYALAYA,CHINNALAPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3190", district:"22", Block:"129", label:"ST.MARY'S MATRIC HR.SEC.SCHOOL,METTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3191", district:"34", Block:"93", label:"GOVT MIDDLE KETTAVARAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3192", district:"7", Block:"60", label:"SAHAYA MATHA MATRIC SCHOOL,DINDIGUL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3193", district:"13", Block:"291", label:"VAILANKANNI MHSS SHOOLAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3194", district:"4", Block:"13", label:"PUMS KPM COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"3195", district:"22", Block:"34", label:"SHRI SWAMY MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3196", district:"11", Block:"355", label:"HINDU VIDYALAYA MATRIC HSS KAPPIYARAI,KARUNGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3197", district:"34", Block:"27", label:"PUMS SUBANRAO PETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3198", district:"22", Block:"278", label:"PSF MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3199", district:"13", Block:"78", label:"SAPTHAGIRI MAT.HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3200", district:"4", Block:"279", label:"N M MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3201", district:"27", Block:"55", label:"VIVEKANANTHA MATRIC HSS - KAMAYAGOUNDANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3202", district:"17", Block:"85", label:"VIVEKANANDA MATRICULATION HIGHER SECONDARY SCHOOL, PAUNDAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3203", district:"4", Block:"248", label:"MUNICIPAL MIDDLE SCHOOL KOTTUR ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"3204", district:"3", Block:"237", label:"GRACE MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3205", district:"25", Block:"144", label:"ST ANNES MATRICULATION HIGHER SECONDARY SCHOOL(GIRLS) KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3206", district:"28", Block:"351", label:"HOLY CROSS HOME SCIENCE MATRICULATION SCHOOL, NEW COLONY", Sch_type:"Un-aided", Category:"High School"},
    { key:"3207", district:"33", Block:"364", label:"GMS-RAMAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3208", district:"7", Block:"60", label:"EQUITAS GURUKUL MATRICULATION HIGHER SECONDARY SCHOOL,DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3209", district:"3", Block:"195", label:"ST JOHNS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3210", district:"34", Block:"256", label:"PUMS KALLARAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3211", district:"31", Block:"5", label:"BHARATH MATRIC HSS, ALANGAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3212", district:"38", Block:"295", label:"V.N.U.A.P.N.T. MATRICULATION HR SEC SCHOOL, VILAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3213", district:"28", Block:"217", label:"INDHIRA GANDHI MAT. SCHOOL,OTTAPIDARAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3214", district:"29", Block:"258", label:"DALMIA VIDYA MANDIR, DALMIAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3215", district:"27", Block:"49", label:"PETCHIYAMMAL.MS - SANKARAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3216", district:"31", Block:"103", label:"PUMS, GOUNDAPPANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3217", district:"32", Block:"363", label:"Annai Matric Tiruppur", Sch_type:"Un-aided", Category:"High School"},
    { key:"3218", district:"8", Block:"185", label:"JAYCEES MATRI HSS,POONDURAI ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3219", district:"38", Block:"407", label:"DHAMU MEMORIAL MATRICULATION SCHOOL, SEVALPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3220", district:"27", Block:"241", label:"PUMS  - AZHAGAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"3221", district:"32", Block:"73", label:"PUMS KOTTAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3222", district:"6", Block:"187", label:"PUMS KELAVALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"3223", district:"38", Block:"295", label:"ARASAN GANESAN MATRICULATION HR SEC SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3224", district:"8", Block:"65", label:"MOTHERS MATRIC HIGHER SECONDARY SCHOOL., KALINGARAYANPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3225", district:"30", Block:"229", label:"NEWLIFE MATRIC ACADEMY, PAPPAKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3226", district:"4", Block:"247", label:"SWASTIK MAT SCHOOL, CHINNERIPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3227", district:"4", Block:"107", label:"PUMS,CHINNATHOTTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3228", district:"36", Block:"113", label:"VANI VIDYALAYA MATRIC. HR. SEC. SCHOOL KATPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3229", district:"23", Block:"272", label:"C.V.C.T.V MEENAKSHI ACHI MATRICULATION SCHOOL, KANADUKATHAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"3230", district:"34", Block:"387", label:"PUMS ARASUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3231", district:"4", Block:"279", label:"G.R.DAMODARAN MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3232", district:"4", Block:"13", label:"PUMS, KIZHAVANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3233", district:"6", Block:"230", label:"STANLEY MHSS, PAPPIREDDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3234", district:"29", Block:"152", label:"SRI VIVEKANANDA MATRIC SCHOOL UMAR NAGAR SIRUDAIYUR, LALGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3235", district:"14", Block:"161", label:"BHARAT VIDYA MANDIR MATRIC HIGH SCHOOL, IYER BUNGALOW", Sch_type:"Un-aided", Category:"High School"},
    { key:"3236", district:"4", Block:"248", label:"AROKIAMATHA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3237", district:"14", Block:"159", label:"SIVAKASI NADARS MATRIC HR SEC SCHOOL, ELLIS NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3238", district:"8", Block:"185", label:"RAJENDRAN MATRIC HSS,VADUGAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3239", district:"12", Block:"348", label:"OMSAKTHI MATRICULATION SCHOOL A. NADUPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3240", district:"29", Block:"357", label:"VIMALA M HSS THURAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3241", district:"12", Block:"315", label:"CHETTINAD RANI MEYYAMMAI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3242", district:"32", Block:"223", label:"SWAMY VIVEKANANDHA MATRIC HR SEC SCHOOL, PALLADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3243", district:"25", Block:"314", label:"DON BOSCO MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3244", district:"11", Block:"354", label:"GMS KEERIPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3245", district:"6", Block:"187", label:"PUMS NALLAGUDLAHALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"3246", district:"3", Block:"259", label:"PADMA SARANGAPANI MATRIC HR.SEC.SCHOOL,VILLIVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3247", district:"4", Block:"248", label:"BHARATIYA VIDYA MANDIR MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3248", district:"14", Block:"158", label:"KALYANI MATRIC HR SEC SCHOOL, SAKKUDI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3249", district:"3", Block:"237", label:"MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3250", district:"7", Block:"207", label:"RAMSUN'S MATRIC HIGER SECONDARY SCHOOL ,MEYYAMPATTY,NATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3251", district:"33", Block:"403", label:"HUSSAIN MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3252", district:"38", Block:"295", label:"SUNDARAVEL MATRICULATION HR SEC SCHOOL, THIRUTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3253", district:"11", Block:"355", label:"GOVERNMENT MIDDILE SCHOOL BRAMMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3254", district:"28", Block:"111", label:"CAMBRIDGE MATRICULATION SCHOOL, KONGARAYAKURICHI.", Sch_type:"Un-aided", Category:"High School"},
    { key:"3255", district:"21", Block:"409", label:"PUMS V.C.MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3256", district:"33", Block:"260", label:"KINGS MATRIC SCHOOL, LAKSHMIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3257", district:"17", Block:"397", label:"MERLION MATRIC HR.SEC.SCHOOL, NO-3, KOMARAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3258", district:"6", Block:"198", label:"PUMS MALAIYURKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3259", district:"36", Block:"393", label:"KOONA PRESIDENCY MATRIC HSS, KONAVATTAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3260", district:"4", Block:"107", label:"SRI SARASWATHI VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3261", district:"25", Block:"313", label:"PUMS - MAPPILLAINAYAGANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3262", district:"32", Block:"32", label:"AKR ACADEMY SCHOOL  ANAIPUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3263", district:"25", Block:"287", label:"KATHERINE MATRIC - CHINNAAVUDAIYARKOIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3264", district:"3", Block:"259", label:"GRACE MAT HSS", Sch_type:"Un-aided", Category:"High School"},
    { key:"3265", district:"13", Block:"376", label:"PUMS MUSILIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3266", district:"7", Block:"36", label:"ANNAI VAILANKANNI MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3267", district:"22", Block:"274", label:"SRI SARADA MATRIC HR. SEC. SCHOOL FOR GIRLS,FAIRLANDS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3268", district:"7", Block:"59", label:"KK AYYA NADAR GANGAMIRTHAMMAL MATRIC SCHOOL,BALAKRISHNAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3269", district:"38", Block:"295", label:"N.P.S.S.R.K. ROTARY MATRICULATION HIGHER SECONDARY SCHOOL, VISWANATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3270", district:"31", Block:"82", label:"ST.PAULS MHSS CHETTIYAPPANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3271", district:"14", Block:"324", label:"PKN MATRIC HR SEC SCHOOL, TIRUMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3272", district:"32", Block:"145", label:"Vivekanandha Academy Senior Secondary School  (CBSE) .", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3273", district:"3", Block:"237", label:"SREE AYYAPPA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3274", district:"28", Block:"297", label:"PUMS GANAPATHISAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3275", district:"12", Block:"112", label:"ST.THERESA MATRIC. HR. SEC. SCHOOL,KARUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3276", district:"12", Block:"315", label:"MALAR MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3277", district:"29", Block:"168", label:"LAKSHMI MATRICULATION HR. SEC. SCHOOL.MANAPARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3278", district:"6", Block:"58", label:"GOOD SHEPHERD MHSS, DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3279", district:"17", Block:"200", label:"KPM HI TECH MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3280", district:"33", Block:"338", label:"CARMEL PUBLIC SCH,SEVVAPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"3281", district:"7", Block:"275", label:"ST.ANNES MATRIC SCHOOL,VEMBARPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3282", district:"4", Block:"302", label:"SRI KAMACHIAMMAN MAT HSS", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3283", district:"34", Block:"387", label:"SENDHAMIZH MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3284", district:"23", Block:"79", label:"ST.MARY MID.SCH, SALAIGRAMAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3285", district:"4", Block:"302", label:"PANCHAYAT UNION MIDDLE SCHOOL SUBBARAYANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3286", district:"3", Block:"368", label:"GILL ADARSH MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3287", district:"33", Block:"338", label:"VIVEKANANDA VIDYAKALA ASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3288", district:"36", Block:"74", label:"HABEEB MATRIC HS SCHOOL, SANTHAPETAI, GUDIYATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3289", district:"22", Block:"273", label:"ST. JOSEPH MATRICULATION SCHOOL SURAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3290", district:"22", Block:"246", label:"RAJ MATRIC HR. SEC. SCHOOL, PUTHIRAGOUNDAMPALAYAM, ATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3291", district:"28", Block:"320", label:"KANCHI SRI SANKARA ACADEMY MHSS, WEST TIRUCHENDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3292", district:"6", Block:"77", label:"PUMS H EACHAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3293", district:"25", Block:"314", label:"SHRI VENKATESWARA MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3294", district:"4", Block:"247", label:"CHANGAVI VIDHYAA MANDIR MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3295", district:"7", Block:"213", label:"SRI KRISHNA MATRICULATION Hr. SEC. SCHOOL, ODDANCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3296", district:"3", Block:"195", label:"ST. ANTONY'S GIRLS HR .SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"3297", district:"13", Block:"35", label:"VICTORIA MATRIC,HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3298", district:"4", Block:"386", label:"BEULA MATRICULATION HR. SEC. SCHOOL, VALPARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3299", district:"31", Block:"362", label:"SRI RAMAKRISHNA VIDHYALAYA MATRIC HSS, TIRUPATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3300", district:"25", Block:"344", label:"THICOSILKS MATRIC HR SEC SCHOOL-THIRUBUVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3301", district:"27", Block:"241", label:"MAHARISHI VIDYA MANDIR MATRIC SCHOOL - GENGUVARPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3302", district:"32", Block:"371", label:"ANNAI ABIRAMI MATRIC HSS SCHOOL VILAMARATHUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3303", district:"29", Block:"168", label:"SRI GURU MATRIC HR. SEC. SCHOOL, MANAPARAIPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3304", district:"11", Block:"191", label:"K.A.B.D. MATRIC. HR. SEC. SCHOOL, PAINKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3305", district:"4", Block:"279", label:"RUBY MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3306", district:"6", Block:"77", label:"PUMS ACHALVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3307", district:"12", Block:"112", label:"KARUR VETRI VINAYAKA MATRIC. HR.SEC.SCHOOL, KARUR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3308", district:"25", Block:"228", label:"PATTUKOTTAI ALAGIRI MAT.HSS-PAPANASAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3309", district:"17", Block:"285", label:"KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL,KALKURICHI, BELUKURICHI, NAMAKKAL -637402", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3310", district:"31", Block:"103", label:"PUMS THOKKIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3311", district:"25", Block:"314", label:"CMS-PALLIAGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3312", district:"27", Block:"40", label:"KAMARAJAR VIDYASALAI MHSS - BODINAYAKANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3313", district:"4", Block:"301", label:"ANNAI ARIVAGAM MATRICULATION SCHOOL SENJERIPUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3314", district:"3", Block:"2", label:"ANNAI THERESA MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3315", district:"6", Block:"198", label:"PUMS APPANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3316", district:"29", Block:"176", label:"PUMS,VADAKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3317", district:"3", Block:"304", label:"JANAKI RAMACHANDIRAN MATRIC HIGHER  SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3318", district:"17", Block:"165", label:"PUMS KURUKKALAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3319", district:"28", Block:"280", label:"HENRIY MATRIC HR.SEC. SCHOOL, SATHANKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3320", district:"23", Block:"94", label:"HOLY SPIRIT MATRIC.HR.SEC.SCHOOL SEEGOORANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3321", district:"25", Block:"144", label:"MORNING STAR MAT CHOLAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3322", district:"14", Block:"159", label:"CHANDLER MATRIC HR SEC SCHOOL, PONNAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3323", district:"3", Block:"240", label:"H.U.C. MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3324", district:"25", Block:"314", label:"CMS-OLD HOUSING UNIT", Sch_type:"Government", Category:"Middle School"},
    { key:"3325", district:"7", Block:"268", label:"SSM ACADEMY AKKARAIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3326", district:"33", Block:"260", label:"SRI SAKTHI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3327", district:"4", Block:"245", label:"RUKMANI KANNAN VIDYALAYA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3328", district:"34", Block:"238", label:"PUMS -MELNANDIYAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3329", district:"14", Block:"306", label:"FUSCO'S MATRIC HR SEC SCHOOL, T. VADIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3330", district:"4", Block:"52", label:"BHARATIYA VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL, R.S.PURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3331", district:"14", Block:"283", label:"P.U.M.S, UTHAPURAM - I", Sch_type:"Government", Category:"Middle School"},
    { key:"3332", district:"25", Block:"313", label:"AMS- RAMAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3333", district:"36", Block:"74", label:"PUMS LINGUNDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3334", district:"28", Block:"402", label:"H.N.MS, Kulathur", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3335", district:"6", Block:"218", label:"PUMS BALAPPANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3336", district:"34", Block:"256", label:"PUMS VEERANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3337", district:"22", Block:"34", label:"SRI VIVEKANANDA PATASALAI MATRIC SCHOOL, SATHIYA NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3338", district:"25", Block:"144", label:"PUMS-ASOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3339", district:"4", Block:"302", label:"SRI SUGA BRAHMA MAHARISHI VIDYALAYAM MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3340", district:"7", Block:"29", label:"VICTORY MATRICULATION HR. SEC. SCHOOL,CHINNALAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3341", district:"12", Block:"315", label:"N.R.M.G & R MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3342", district:"4", Block:"126", label:"AKSHAYA ACADEMY MAT HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3343", district:"38", Block:"295", label:"K.KAMARAJAR MATRICULATION HR SEC SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3344", district:"23", Block:"272", label:"MUTHIAH ALAGAPPA MATRIC HR SEC SCHOOL, KOTTAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3345", district:"37", Block:"100", label:"MAHATMA GANDHI MATRICULATION SCHOOL KANJANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3346", district:"25", Block:"313", label:"PUMS - AMMANPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3347", district:"12", Block:"112", label:"KONGU VELLALAR MATRIC. HR.SEC.SCHOOL, KARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3348", district:"22", Block:"215", label:"KONGU MATRIC HR. SEC. SCHOOL,VELLAKALPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3349", district:"20", Block:"231", label:"PARAMAKUDI LIONS MATRICULATION HIGHER SECONDARY SCHOOL, THELICHATHANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3350", district:"3", Block:"2", label:"C.ANNAPOORANI AMMAL M S", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3351", district:"6", Block:"235", label:"PUMS ODDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3352", district:"13", Block:"35", label:"SELVA MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3353", district:"34", Block:"312", label:"CHRIST JOTHI MATRIC HSS THANIPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3354", district:"34", Block:"365", label:"PUMS SU. NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3355", district:"3", Block:"2", label:"SHRI A GANESAN MAT HR SEC SCHOOL, VELACHERY. ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3356", district:"20", Block:"231", label:"AYIRA VAISYA MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3357", district:"25", Block:"313", label:"AMS- AMMANPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3358", district:"28", Block:"369", label:"TNDTA MIDDLE SCHOOL KALVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3359", district:"11", Block:"191", label:"ST JUDES MS VALLAVILAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3360", district:"1", Block:"16", label:"ST. MARTIN'S MATRICULATION  SCHOOL, ANDIMADAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3361", district:"25", Block:"144", label:"Al-Ameen Matriculation Higher Secondary School, Kumbakonam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3362", district:"12", Block:"112", label:"SRI RAMAKRISHNA MATRICULATION SCHOOL, MUTHANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3363", district:"25", Block:"314", label:"ROYAL MATRIC HIGHER SECONDARY SCHOOL - M.CHAVADI-THANJAVURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3364", district:"25", Block:"42", label:"PUMS-MGM.SANATORIUM", Sch_type:"Government", Category:"Middle School"},
    { key:"3365", district:"13", Block:"78", label:"PUMS ACHETTIPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3366", district:"3", Block:"237", label:"SRI PADMA SARANGAPANI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3367", district:"25", Block:"216", label:"PUMS - PULAVANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3368", district:"32", Block:"363", label:"SHRI SARASWATHI GIRI MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3369", district:"17", Block:"201", label:"TRINITY INTERNATIONAL SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3370", district:"33", Block:"403", label:"SRE SASSTHA MATRIC HIGHER SECONDARY SCHOOL, NERKUNDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3371", district:"31", Block:"82", label:"BOSTON MATRIC HSS, JOLARPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3372", district:"11", Block:"341", label:"CSI MIDDLE SCHOOL MARAMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3373", district:"12", Block:"112", label:"ST.MARY'S MATRIC HIGHER SECONDARY SCHOOL,VANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3374", district:"34", Block:"412", label:"DESIA MATRICULATION-KATTUKANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3375", district:"7", Block:"127", label:"JAYARAJ ANNAPACKIAM MATRICULATION SCHOOL, PANNAIKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"3376", district:"3", Block:"195", label:"CMS GANDHIGRAMAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3377", district:"25", Block:"313", label:"YAGAPPA MATRICULATION HSS-KALAINGNAR NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3378", district:"25", Block:"228", label:"PUMS - PASUPATHI KOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"3379", district:"32", Block:"104", label:"SRI VIDHYA NIKETHAN MATRIC HR SEC SCHOOL, VARATHAPPAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3380", district:"24", Block:"288", label:"VANCHI GOVERNMENT MIDDLE SCHOOL SHENGOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3381", district:"25", Block:"314", label:"ST. ANTONY'S AIDED MIDDLE SCHOOL-M-CHAVADI-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3382", district:"7", Block:"220", label:"ST. PETER'S MATRICULATION SCHOOL,PALANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3383", district:"13", Block:"78", label:"VISWA BHARATHI MATRIC.HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3384", district:"36", Block:"74", label:"PUMS ERTHANGAL PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3385", district:"14", Block:"328", label:"AMRITA VIDYALAYAM MATRICULATION SCHOOL, THIRUNAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3386", district:"36", Block:"74", label:"RAJIV GANDHI MATRIC SCHOOL, GANDHI NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3387", district:"33", Block:"403", label:"PASUMPON AACINI MAT HSS AMBATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3388", district:"33", Block:"289", label:"ST.JOSEPH MS,MONDIAMMAN NAGAR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3389", district:"4", Block:"242", label:"IMMACULATE HEART OF MARY MATHSS PRESS COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3390", district:"12", Block:"23", label:"VENUS MATRIC SCHOOL,PALLAPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"3391", district:"23", Block:"272", label:"ST MICHAEL MATRIC HR SEC SCHOOL , ARIYAKKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3392", district:"31", Block:"103", label:"PUMS, ARAVAMATRAPPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3393", district:"22", Block:"383", label:"THIAGARAJAR MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3394", district:"22", Block:"129", label:"VALLUVAR VASUKI MATRIC HR. SEC. SCHOOL,NATTAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3395", district:"4", Block:"301", label:"SRI DHAYANANDAPURI MATRIC HIGHER SECONDARY SCHOOL, DASANAICKENPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3396", district:"17", Block:"267", label:"PU MIDDLE SCHOOL , KOONAVELAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3397", district:"7", Block:"219", label:"PRG VN MAT SCH,NEIKARAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3398", district:"28", Block:"351", label:"ST.PATRICKS MS MELUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3399", district:"13", Block:"122", label:"PUMS HANUMANTHAPURAM TELUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"3400", district:"34", Block:"48", label:"VIVEGA INTERNATIONAL MATRIC SCHOOL CHEYYAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3401", district:"4", Block:"247", label:"PANCHAYAT UNION MIDDLE SCHOOL, R.PONNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3402", district:"23", Block:"79", label:"PUMS KONGAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3403", district:"4", Block:"247", label:"VISHWA SISHYA VIDYODAYA SCH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3404", district:"30", Block:"222", label:"ROSE MARY MHSS PALAYAMKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3405", district:"34", Block:"365", label:"PUMS KALLERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3406", district:"4", Block:"247", label:"BHARATH VIDHYA NIKKETAN MHSS ACHIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3407", district:"14", Block:"328", label:"SENGUNDHAR MATRIC HIGH SCHOOL, CHINTHAMANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3408", district:"33", Block:"87", label:"CHELLAMMAL VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL MELNALLATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3409", district:"36", Block:"113", label:"DONBOSCO MATRIC HSS, KATPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3410", district:"34", Block:"48", label:"PUMS RAMAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3411", district:"23", Block:"96", label:"KALAIMAGAL MAT SCHOOL,KALLAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3412", district:"3", Block:"2", label:"ST MARYS MATRICULATION  HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3413", district:"33", Block:"184", label:"SARASWATHI MS, MELUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3414", district:"3", Block:"270", label:"ST THOMAS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3415", district:"26", Block:"370", label:"VIVEKANANDA MATRICULATION HIGH SCHOOL M.PALADA", Sch_type:"Un-aided", Category:"High School"},
    { key:"3416", district:"33", Block:"252", label:"ST.THOMAS MATRIC HR SEC SCHOOL, THANDURAI, PATTABIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3417", district:"17", Block:"85", label:"SRI RAJA VINAYAGA MATRICULATION SCHOOL, VELLATHARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3418", district:"17", Block:"200", label:"TRINITY VIDHYALAYA MATRICULATION SCHOOL, MULLUKURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3419", district:"6", Block:"235", label:"PUMS PUDUKARAMBU", Sch_type:"Government", Category:"Middle School"},
    { key:"3420", district:"33", Block:"184", label:"SRI AAIMATHAJI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3421", district:"22", Block:"383", label:"HOLY CHILDREN MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3422", district:"7", Block:"59", label:"PANNAI MATRICULATION SCHOOL,MULLIPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3423", district:"12", Block:"140", label:"PUMS , KRISHNARAYAPURAM (G)", Sch_type:"Government", Category:"Middle School"},
    { key:"3424", district:"32", Block:"154", label:"PUMS NG PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3425", district:"29", Block:"172", label:"M.A.M.MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3426", district:"24", Block:"6", label:"RC MS, Thuthikulam", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3427", district:"32", Block:"363", label:"KONGU VELLALAR MHSS, ANGERIPALAYAM ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3428", district:"6", Block:"198", label:"UNITY MHSS, VETHALAIKARANKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3429", district:"14", Block:"183", label:"SRI BALAN EVERGREEN MATRIC HIGH SCHOOL, KEELAVALAVU", Sch_type:"Un-aided", Category:"High School"},
    { key:"3430", district:"6", Block:"187", label:"PUMS JAGGUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3431", district:"7", Block:"127", label:"SRI SANKARA MHSS,KODAIKANAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3432", district:"3", Block:"304", label:"RAMAKRISHNA MISSION MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3433", district:"14", Block:"161", label:"LUCY PERRY NOBLE GIRLS HR SEC SCHOOL, RATCHANYAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"3434", district:"11", Block:"149", label:"ADLINE MATRIC HSS-VILLUKURI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3435", district:"27", Block:"319", label:"PUMS - UNJAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3436", district:"28", Block:"369", label:"RC MS, UDANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3437", district:"3", Block:"237", label:"LOURDES MAT SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3438", district:"38", Block:"295", label:"SRI SHENBAGA VINAYAGAR MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3439", district:"34", Block:"249", label:"SRI RAMAJAYAM MATRIC POLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3440", district:"6", Block:"235", label:"PUMS GURUKALAIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3441", district:"7", Block:"213", label:"ST.JAMES MATRIC SCHOOL,ODDANCHATRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3442", district:"25", Block:"11", label:"PUMS - ARUMALAIKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3443", district:"30", Block:"46", label:"Panchayat  Union Middle School Kallidaikurichi", Sch_type:"Government", Category:"Middle School"},
    { key:"3444", district:"36", Block:"113", label:"WILLIAMS MATRIC HSS, VELLAIKALMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3445", district:"33", Block:"337", label:"PUMS, NABALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3446", district:"34", Block:"14", label:"WISDOM MAT HSS CHEYYATRAI VENDRAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3447", district:"25", Block:"234", label:"OXFORD MATRICULATION HIGHER SECONDARY SCHOOL -PUDUKKOTTAI ULLUR, PATTUKKOTTAI ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3448", district:"4", Block:"242", label:"PUMS APPANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3449", district:"14", Block:"328", label:"RAJAN MATRIC HR SEC SCHOOL, VILLAPURAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3450", district:"11", Block:"149", label:"GMS MOONGILVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3451", district:"3", Block:"237", label:"THANGAM MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3452", district:"31", Block:"362", label:"PUMS SEERANGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3453", district:"24", Block:"288", label:"AG Matric Higher Secondary School, Shengottai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3454", district:"3", Block:"195", label:"ST.JOHN'S MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3455", district:"7", Block:"219", label:"DEVI MATRICULATION HIGHER SECONDARY SCHOOL,K. G. VALASU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3456", district:"35", Block:"141", label:"PUMS, SARABOJIRAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3457", district:"13", Block:"78", label:"YOGI VEMANA MATRIC.HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3458", district:"25", Block:"287", label:"PUMS -KODIVAYALMARAKKAVALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3459", district:"3", Block:"237", label:"JAIGOPAL GARODIA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3460", district:"7", Block:"60", label:"SOPHIYA MATRIC SCHOOL, DINDIGUL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3461", district:"23", Block:"271", label:"PUMS KONDAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3462", district:"26", Block:"370", label:"NILGIRIS MATRIC HR SEC SCHOOL , LOVEDALE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3463", district:"34", Block:"48", label:"WISDOM VIDHYASHRAM INTERNATIONAL SCHOOL  CHEYYAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3464", district:"29", Block:"192", label:"AMALA MATRIC HR SEC SCHOOL, MUSIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3465", district:"17", Block:"85", label:"PUMS PONMALARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3466", district:"13", Block:"78", label:"PUMS HOSUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3467", district:"12", Block:"140", label:"PUMS,KAMBALIYAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3468", district:"17", Block:"85", label:"RKV MATRIC HR.SEC.SCHOOL, JEDARPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3469", district:"14", Block:"306", label:"VIVEKANANDA MATRIC HR SEC SCHOOL, SHOLAVANDAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3470", district:"6", Block:"77", label:"SATHYAM MHSS, MOPPIRIPATTI, HARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3471", district:"17", Block:"267", label:"MMS-V.NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3472", district:"33", Block:"184", label:"KALVI POONGA MATRICULATION SCHOOL , KANIYAMBAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3473", district:"34", Block:"27", label:"ADWMS PAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3474", district:"27", Block:"40", label:"HAJEE K.L.K.S  ABDUL RAZAK INTERNATIONAL MATRIC SCHOOL, BODINAYAKANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3475", district:"17", Block:"186", label:"SHINEHILL ACADEMY MATRIC HIGHER SECONDARY SCHOOL,VALAYAPATTI (PO), NAMAKKAL DT -637020", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3476", district:"7", Block:"127", label:"BRINDAVAN MHSS,KODAIKANAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3477", district:"14", Block:"161", label:"ST.MARY'S MATRIC HR SEC SCHOOL, S.ALANGULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3478", district:"7", Block:"213", label:"HOLY CROSS MATRICULATION HIGHER SECONDARY SCHOOL,ODDANCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3479", district:"27", Block:"55", label:"MAZHALAIYAR MATRIC HR SEC SCHOOL - KEELAGUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3480", district:"7", Block:"59", label:"KENNEDY MATRICULATION HIGHER SECONDARY SCHOOL, VAZHAKKAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3481", district:"17", Block:"186", label:"KALAIMAGAL MATRIC HR SEC SCHOOL ,MOHANUR ,NAMAKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3482", district:"33", Block:"260", label:"ST. JOSEPH MAT. HSS, ENNORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3483", district:"4", Block:"13", label:"PUMS CHINNAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3484", district:"22", Block:"278", label:"Shri Vidya Saagar Matric School, Nagichettipatti", Sch_type:"Un-aided", Category:"High School"},
    { key:"3485", district:"29", Block:"367", label:"Al-Jamieathus Sadhik Mat. School, Khajamalai.", Sch_type:"Un-aided", Category:"High School"},
    { key:"3486", district:"26", Block:"72", label:"M S S MATRICULATION HIGH SCHOOL, UPPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"3487", district:"7", Block:"213", label:"KALVI MATRIC HR. SEC. SCHOOL, KAPPILIYAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3488", district:"14", Block:"183", label:"VETRIVEL VIDYASALA MATRIC HIGH SCHOOL, MELUR ", Sch_type:"Un-aided", Category:"High School"},
    { key:"3489", district:"7", Block:"29", label:"VIDYA SIKSHAA MATRIC HIGHER SECONDARY SCHOOL, BODIKAMANVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3490", district:"25", Block:"344", label:"PUMS - THIRUVISANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3491", district:"22", Block:"226", label:"SRI VIDHYA BHARATI MATRIC HR. SEC. SCHOOL, NEIKARAPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3492", district:"33", Block:"184", label:"PUMS,AVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3493", district:"29", Block:"168", label:"VINMATHEE MATRIC HSS, MANAPARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3494", district:"30", Block:"174", label:"PANCHAYAT UNION MIDDLE SCHOOL , DURAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3495", district:"38", Block:"295", label:"V.S.K.DURAISWAMYNADAR GIRLS MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3496", district:"6", Block:"218", label:"PUMS VEDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3497", district:"21", Block:"359", label:"PUMS NAMBARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3498", district:"25", Block:"144", label:"PUMS - THIRUPURAMBIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3499", district:"17", Block:"360", label:"OVVAI KALVI NILAYAM MIDDLE SCHOOL TIRUCHENGODE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3500", district:"24", Block:"307", label:"SUBASH MATRIC HSS,TENKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3501", district:"4", Block:"13", label:"PUMS KARIANCHETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3502", district:"34", Block:"44", label:"SRI VIVEKANANDA MATRIC HIGHER SEC SCHOOL-CHENGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3503", district:"4", Block:"247", label:"PANCHAYAT UNION MIDDLE SCHOOL THAPPATTAIKILAVAN PUDUR ", Sch_type:"Government", Category:"Middle School"},
    { key:"3504", district:"12", Block:"112", label:"NAVJEEVAN MATRIC SCHOOL,PANCHAMADEVI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3505", district:"33", Block:"403", label:"SHRI  VEL'S  ESTATE MATRICULATION HR. SEC.SCHOOL,VELLANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3506", district:"7", Block:"207", label:"RC MATRICULATION HIGHER SECONDARY SCHOOL,NATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3507", district:"33", Block:"87", label:"PUMS ADIGATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3508", district:"24", Block:"307", label:"VIVEKANANDA MIDDLE SCHOOL MATHALAMPARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3509", district:"13", Block:"35", label:"SRV MATRIC HSS ONDIMAVATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3510", district:"3", Block:"259", label:"ICI MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3511", district:"14", Block:"328", label:"SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, Jeevanagar", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3512", district:"32", Block:"363", label:"MMS, CHELLAPPAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3513", district:"6", Block:"235", label:"BRINDHAVAN MATRIC SCHOOL, SENGANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3514", district:"17", Block:"397", label:"SRI VENKATESHWARAA MATRICULATION HIGHER SECONDARY SCHOOL,VENNANDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3515", district:"34", Block:"387", label:"SRI BHARATHAMATHA MATRICULATION SCHOOL VANDAVASI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3516", district:"21", Block:"359", label:"PUMS PARADHARAMI", Sch_type:"Government", Category:"Middle School"},
    { key:"3517", district:"4", Block:"247", label:"PUMS ALAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3518", district:"25", Block:"144", label:"VIVEKANANDHA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, THENAMPADUGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3519", district:"23", Block:"272", label:"SHRI VIDHYAA GIRI MATRICULATION HIGHER SECONDARY SCHOOL, PUDUVAYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3520", district:"10", Block:"146", label:"The National IT Int. Matric Hr Sec School, Kumananchavadi", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3521", district:"14", Block:"328", label:"V.K.K PLAY GROUP MATRIC HR SEC SCHOOL, PONMENI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3522", district:"25", Block:"287", label:"PUMS -OMAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3523", district:"34", Block:"249", label:"TOWN MATRIC HSS POLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3524", district:"11", Block:"341", label:"LITTEL FLOWER M.S.C.M.S. ANAKARAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3525", district:"13", Block:"35", label:"MGM HSS PERANDAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3526", district:"20", Block:"332", label:"VIJI VIDYALAYA MATRICULATION SCHOOL, THIRU UTHARAKOSAMANGAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3527", district:"32", Block:"392", label:"SRI KAMADENU MATRIC  SCHOOL, UTHAMAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3528", district:"33", Block:"260", label:"THE WESLEYS MS, KOLATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3529", district:"34", Block:"365", label:"PUMS KADAGAMAN", Sch_type:"Government", Category:"Middle School"},
    { key:"3530", district:"4", Block:"248", label:"AROKIAMATHA MATRICULATION SCHOOL, POLLACHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3531", district:"22", Block:"30", label:"MALAR MATRIC HR SEC SCHOOL, THULUKKANUR, KALLANATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3532", district:"4", Block:"386", label:"PANCHAYATH UNION MIDDLE SCHOOL URULIKKAL UD", Sch_type:"Government", Category:"Middle School"},
    { key:"3533", district:"7", Block:"213", label:"CHRISTIAN MATRICULATION HSS,ODDANCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3534", district:"35", Block:"209", label:"UAMHS,A.N.ABDUL RAHIM POTHAKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3535", district:"5", Block:"54", label:"ST ANTONY'S MATRICULATION HIGHER SECONDARY SCHOOL - CHINNAKANGANANKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3536", district:"23", Block:"79", label:"PUMS PUDUR VALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3537", district:"11", Block:"354", label:"GMS-ESANTHIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3538", district:"25", Block:"314", label:"JAINA FREE AIDED MIDDLE SCHOOL-KARANTHAI-THANJAVUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3539", district:"7", Block:"60", label:"LIONS MATRICULATION HIGHER SECONDARY SCHOOL,DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3540", district:"27", Block:"241", label:"SANKARANARAYANAN MEMORIAL MIDDLE SCHOOL -  VADUGAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3541", district:"34", Block:"256", label:"PUMS THAMARAIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3542", district:"21", Block:"290", label:"QUEEN MARY'S MATRIC. HR. SEC. SCHOOL, KATTRAMPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3543", district:"7", Block:"59", label:"CRESENT MAT HS, ANGUNAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3544", district:"25", Block:"11", label:"PUMS - THIRUBUVANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3545", district:"31", Block:"362", label:"PUMS ANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3546", district:"4", Block:"301", label:"PUMS SENJERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3547", district:"33", Block:"76", label:"SHANTHI NIKETHAN MS NEW GUMMIDIPOONDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3548", district:"31", Block:"155", label:"PUMS PERIYAVARIGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3549", district:"3", Block:"69", label:"CHENNAI PORT & DOCK EDUCATIONAL TRUST HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3550", district:"28", Block:"280", label:"RC MS ,THAILAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3551", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. KARUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3552", district:"34", Block:"365", label:"PUMS NAVAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"3553", district:"17", Block:"360", label:"VIDYAA VIKAS INTERNATIONAL SCHOOL,CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3554", district:"33", Block:"403", label:"BALAGURU CHETI.VV, VEERAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3555", district:"31", Block:"103", label:"PUMS RAGUPATHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3556", district:"12", Block:"23", label:"B.S.P.MATRIC  HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3557", district:"6", Block:"235", label:"PUMS SIGARALAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3558", district:"30", Block:"92", label:"ALHUDHA MATRIC HR SEC SCHOOL ERUVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3559", district:"32", Block:"104", label:"PUMS PALAYAKOTTAIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3560", district:"36", Block:"74", label:"PUMS DHANAKONDAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3561", district:"17", Block:"267", label:"PUMS MUTHUKALIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3562", district:"33", Block:"184", label:"PUMS,PANAPPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3563", district:"8", Block:"244", label:"APEX SCHOOL FOR HEARING IMPAIRED, SLATERPURAM", Sch_type:"Un-aided", Category:"Others"},
    { key:"3564", district:"17", Block:"63", label:"SHRI RENGA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL,RAYARPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3565", district:"25", Block:"144", label:"PUMS - NADUVAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3566", district:"4", Block:"52", label:"CORPORATION MIDDLE SCHOOL KOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3567", district:"20", Block:"332", label:"SATHAKATHUN JARIA MIDDLE SCHOOL, KILAKKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3568", district:"4", Block:"245", label:"PUMS NOYYALPALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3569", district:"34", Block:"47", label:"PUMS ALLIYALAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3570", district:"34", Block:"47", label:"PUMS KOZHAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3571", district:"34", Block:"47", label:"RCM MIDDLE THACHAMBADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3572", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL SENEERKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3573", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL THIRUMANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3574", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, YADHAVAR KUDI, POTHIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3575", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, MARANTHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3576", district:"34", Block:"395", label:"PUMS ABDULLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3577", district:"34", Block:"47", label:"PUMS SADUPPERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3578", district:"5", Block:"143", label:"PUMS JAHIR HUSSAIN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3579", district:"36", Block:"74", label:"APS MADARASE-E-NISWAN, MELALATHUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3580", district:"34", Block:"318", label:"PUMS JAPTHIKARANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3581", district:"34", Block:"48", label:"ST JOSEPH MATRIC KODANAGAR CHEYYAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3582", district:"6", Block:"77", label:"PUMS AVALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3583", district:"6", Block:"218", label:"PUMS GUTHALAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3584", district:"24", Block:"119", label:"HINDU MS SIVAGAMIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3585", district:"17", Block:"267", label:"PU MIDDLE SCHOOL,GOUNDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3586", district:"15", Block:"284", label:"PUMS MATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3587", district:"24", Block:"119", label:"K.M.H.MS VINAITHEERTHANADARPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3588", district:"17", Block:"63", label:"PUMS PACH.PUDUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3589", district:"34", Block:"14", label:"PUMS VEERAMBAKKAM PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3590", district:"27", Block:"31", label:"PUMS - PULLIMANCOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3591", district:"25", Block:"42", label:"PUMS-NEMAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3592", district:"3", Block:"240", label:"ST PETERS MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3593", district:"29", Block:"176", label:"PUMS,D.PUTHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3594", district:"24", Block:"91", label:"PUMS,PUNNAIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3595", district:"34", Block:"395", label:"PUMS UKKAMPERUMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3596", district:"27", Block:"375", label:"PUMS - NAGAYAGOUNDANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3597", district:"6", Block:"77", label:"PUMS PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3598", district:"30", Block:"46", label:"ST.JOHNS MIDDLE SCHOOL VEERAVANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3599", district:"27", Block:"49", label:"R.C.MS - CHINNAMANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3600", district:"7", Block:"59", label:"HOLYCROSS CONVENT MATRIC HR SEC SCHOOL, RAMAYANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3601", district:"17", Block:"360", label:"PANCHAYAT UNION MIDDLE SCHOOL ,NARAYANIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3602", district:"25", Block:"42", label:"PUMS-PUDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3603", district:"5", Block:"39", label:"AMS MULLIPALLAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3604", district:"13", Block:"35", label:"VAILANKANNI MHSS BARGUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3605", district:"34", Block:"47", label:"PUMS MODAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3606", district:"23", Block:"271", label:"PUMS KIZHAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3607", district:"23", Block:"79", label:"PUMS N.ANDAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3608", district:"34", Block:"249", label:"PUMS ATHUVAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3609", district:"25", Block:"234", label:"IMAM SHAFI MAT HR SEC SCHOOL - ADIRAMPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3610", district:"6", Block:"77", label:"MUTHU MASE MATRIC SCHOOL, HARUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3611", district:"21", Block:"21", label:"PUMS, ANAIKATTA PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3612", district:"11", Block:"124", label:"VIDYA JOTHI MHSS MARTHANDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3613", district:"34", Block:"27", label:"PUMS THUNTHAREEGAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"3614", district:"34", Block:"27", label:"PUMS S.U.VANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3615", district:"34", Block:"47", label:"RCM MIDDLE PATHIYAVARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3616", district:"11", Block:"355", label:"GMS-ALENCODE", Sch_type:"Government", Category:"Middle School"},
    { key:"3617", district:"28", Block:"217", label:"NMS MARUTHANVALVOO", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3618", district:"25", Block:"313", label:"PUMS - MELAMANOJIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3619", district:"33", Block:"403", label:"MUN.MID.SCH PATTARAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3620", district:"25", Block:"239", label:"PUMS -NATTANIKKOTTAI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"3621", district:"25", Block:"11", label:"PUMS- SENBAGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3622", district:"5", Block:"39", label:"PUMS BHUVANAGIRI WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"3623", district:"34", Block:"395", label:"PUMS BOKKASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3624", district:"4", Block:"107", label:"PUMS,JADAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3625", district:"33", Block:"338", label:"PUMS, PUTLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3626", district:"12", Block:"142", label:"SRI VIDYA BHARATHI MATRIC HIGHER SECONDARY. SCHOOL, KULITHALAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3627", district:"6", Block:"235", label:"PUMS VATHIMARATHAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3628", district:"34", Block:"48", label:"MMS-KODANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3629", district:"3", Block:"368", label:"CMS KRISHNAMPET", Sch_type:"Government", Category:"Middle School"},
    { key:"3630", district:"27", Block:"241", label:"BHARATHIYAR MS - VADAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3631", district:"8", Block:"128", label:"PUMS,VADAKKUPUDUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3632", district:"25", Block:"144", label:"PUMS NAGAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3633", district:"25", Block:"42", label:"RC AMS-MEGALATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3634", district:"34", Block:"27", label:"PUMS NETHAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3635", district:"33", Block:"252", label:"IMMANUEL MATRIC HR SEC SCHOOL PATTABIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3636", district:"5", Block:"148", label:"PUMS ALAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3637", district:"33", Block:"64", label:"PUMS, AYALACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3638", district:"7", Block:"268", label:"HAJI MU. MAT.SCH,SRIRAMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3639", district:"25", Block:"344", label:"AL KHAREEM MATRICULATION  SCHOOL-MURUKKANGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3640", district:"21", Block:"359", label:"PUMS CHENNASAMUTHRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3641", district:"34", Block:"365", label:"PUMS ARADAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"3642", district:"28", Block:"8", label:"TNDTA MS NALUMAVADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3643", district:"25", Block:"287", label:"ABU MATRIC HSS- PUDUPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3644", district:"30", Block:"361", label:"Hindu MS, M V Puram, TVL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3645", district:"34", Block:"93", label:"PUMS GENGAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3646", district:"29", Block:"317", label:"AIED MS SITTILARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3647", district:"24", Block:"91", label:"SRI SIVA SAILA NATHA MIDDLE SCHOOL NEDUVAYAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3648", district:"32", Block:"363", label:"MMS,THIRUNEELAKANDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3649", district:"34", Block:"93", label:"PUMS SETTAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"3650", district:"4", Block:"13", label:"PUMS NARIKKALPATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"3651", district:"4", Block:"349", label:"PRG MDL SCHOOL, THENNAMANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3652", district:"35", Block:"141", label:"PUMS, PARUTHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3653", district:"11", Block:"355", label:"GMS PILAVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3654", district:"25", Block:"313", label:"AMS - SITHIRAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3655", district:"22", Block:"68", label:"LUCKY MATRIC SCHOOL, THAMMAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3656", district:"5", Block:"39", label:"SARASWATHI MATRICULATION SCHOOL BOOTHAVARAYANPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3657", district:"34", Block:"395", label:"PUMS PULIVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3658", district:"25", Block:"314", label:"MAKKAL  UZHIYA SANGAM AIDED MIDDLE SCHOOL-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3659", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - CHINNA IRUSAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3660", district:"6", Block:"235", label:"PUMS MAKKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3661", district:"33", Block:"252", label:"CHRIST KING MATRIC HIGHER SECONDARY SCHOOL, PATTABIRAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3662", district:"30", Block:"222", label:"CMS MANAKAVALAM HOSPITAL ST", Sch_type:"Government", Category:"Middle School"},
    { key:"3663", district:"31", Block:"155", label:"PUMS KUTTAKINTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3664", district:"34", Block:"47", label:"PUMS THENVILAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3665", district:"13", Block:"376", label:"PUMS MOONGILERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3666", district:"27", Block:"241", label:"7TH DAY ADV  MATRIC HR SEC SCHOOL -  T.KALLIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3667", district:"13", Block:"376", label:"PUMS KOTTARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3668", district:"13", Block:"78", label:"PUMS BOMMANDAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3669", district:"6", Block:"58", label:"SEVENTHDAY ADVENTIST MHSS , DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3670", district:"29", Block:"171", label:"SBIOA MATRIC HR. SEC. SCHOOL, AIR PORT K.K NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3671", district:"4", Block:"279", label:"PUMS KOTTAIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3672", district:"25", Block:"314", label:"D.K.SUBBAIYAH NAIDU MS-THANJAVUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3673", district:"3", Block:"304", label:"SUNDARAM MAT SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3674", district:"13", Block:"117", label:"GOPI KRISHNA MATRICULATION HR.SEC SCHOOL DASAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3675", district:"32", Block:"363", label:"PUMS,MUMMOORTHY NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3676", district:"34", Block:"365", label:"PUMS T. VALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3677", district:"11", Block:"3", label:"GMS KRISHNANCOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"3678", district:"28", Block:"320", label:"SENTHIL KUMARAN  MATRIC HR.SEC. SCHOOL,TIRUCHENDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3679", district:"17", Block:"224", label:"PUMS T.VASUKI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3680", district:"28", Block:"257", label:"SEENI MAT HS NAGALAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3681", district:"25", Block:"313", label:"PUMS - NANCHIKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3682", district:"4", Block:"386", label:"PANCHAYATH UNION MIDDLE SCHOOL PANNIMADE I", Sch_type:"Government", Category:"Middle School"},
    { key:"3683", district:"25", Block:"42", label:"PUMS-PALAYAPATTI SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"3684", district:"11", Block:"355", label:"UMA MATRIC HIGHER SECONDARY SCHOOL, ALWARCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3685", district:"11", Block:"182", label:"GMS-PERAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3686", district:"34", Block:"365", label:"PUMS THANDARAI COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"3687", district:"33", Block:"184", label:"PUMS,NEW NANDIAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3688", district:"13", Block:"376", label:"PUMS ELAVAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3689", district:"33", Block:"76", label:"PUMS THANIPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3690", district:"34", Block:"47", label:"PUMS VAMBALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3691", district:"24", Block:"6", label:"R.C. MS, Mariathaipuram", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3692", district:"5", Block:"148", label:"PUMS MEENAKSHIPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3693", district:"11", Block:"355", label:"GMS,ERANIEL", Sch_type:"Government", Category:"Middle School"},
    { key:"3694", district:"33", Block:"252", label:"AVADI MUNICIPAL MIDDLE SCHOOL KAMARAJ NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3695", district:"33", Block:"260", label:"ADW MS, VILANGADUPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3696", district:"25", Block:"42", label:"CHELLAM AMS-ORATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3697", district:"6", Block:"235", label:"PUMS A.PAUPARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3698", district:"4", Block:"247", label:"Sri Chinthamani Memorial Matric HSS, Thippampatti", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3699", district:"25", Block:"11", label:"PUMS - UKKADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3700", district:"31", Block:"103", label:"PUMS, KUNICHI MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3701", district:"23", Block:"272", label:"PUMS MITHRANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3702", district:"33", Block:"337", label:"PUMS, PAGASALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3703", district:"34", Block:"48", label:"PUMS SENGAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3704", district:"24", Block:"307", label:"HINDU MIDDLE SCHOOL KASIMAJORPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3705", district:"23", Block:"106", label:"ILANGO .MID SCHOOL  VENGALORE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3706", district:"25", Block:"330", label:"AMS-THIRUKODIKAVAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3707", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL  T.KOSAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3708", district:"11", Block:"182", label:"GMS (GIRLS) ARUMANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3709", district:"34", Block:"27", label:"PUMS SOMANTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3710", district:"17", Block:"85", label:"PUMS K.AYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3711", district:"20", Block:"41", label:"PANCHAYAT UNION MIDDLE SCHOOL, VEERAVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3712", district:"17", Block:"360", label:"VISHNU VIDHYALAYA MATRICULATION SCHOOL, ETTIMADAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3713", district:"34", Block:"48", label:"MMS-THIRUVOTHUR (MUSLIM)", Sch_type:"Government", Category:"Middle School"},
    { key:"3714", district:"34", Block:"387", label:"PUMS VAZHUR AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3715", district:"11", Block:"182", label:"G M S (GIRLS) KUZHITHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3716", district:"34", Block:"412", label:"PUMS KOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3717", district:"24", Block:"388", label:"PUJMS, VASUDEVANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3718", district:"11", Block:"263", label:"HOLY INFANT JESUS MIDDLE SCHOOL, PUTHENTHURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3719", district:"20", Block:"188", label:"R.C MIDDLE SCHOOL , KODARENDAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3720", district:"5", Block:"39", label:"PANCHAYAT UNION MIDDLE SCHOOL , BOOTHAVARAYANPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3721", district:"21", Block:"359", label:"PUMS KUTTIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3722", district:"24", Block:"288", label:"GOVERNMENT MIDDLE SCHOOL MEKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3723", district:"4", Block:"242", label:"PUMS SOMAIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3724", district:"30", Block:"46", label:"PUMS VEERAVANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3725", district:"5", Block:"39", label:"PUMS LALPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3726", district:"4", Block:"248", label:"MMS, BALAGOPALAPURAM ST", Sch_type:"Government", Category:"Middle School"},
    { key:"3727", district:"21", Block:"359", label:"PUMS AAYIRAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3728", district:"24", Block:"307", label:"PUMS THIRUCHITRAMBALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3729", district:"34", Block:"365", label:"PUMS SATHIYA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3730", district:"21", Block:"116", label:"BHARATHIDHASANAR MATRIC SCHOOL, KAVERIPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3731", district:"33", Block:"225", label:"PUMS, MELAPUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3732", district:"34", Block:"27", label:"PUMS 12PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3733", district:"6", Block:"218", label:"PUMS DODDARADHANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3734", district:"30", Block:"385", label:"VADAKKU HINDU MIDDLE SCHOOL PANAGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3735", district:"11", Block:"191", label:"GMS LEKSHMI PUTHUKKADAI, NITHIRAVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3736", district:"24", Block:"91", label:"PUMS,BALA ARUNACHALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3737", district:"13", Block:"122", label:"PUMS EANIMUCHANDIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3738", district:"29", Block:"343", label:"OM MARUTHI MATRICULATION HIGH SCHOOL,KARUMANDAPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3739", district:"4", Block:"242", label:"PUMS MGR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3740", district:"11", Block:"191", label:"GOVTMIDDLE SCHOOL, PARTHIVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3741", district:"34", Block:"249", label:"PUMS EZUVAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3742", district:"17", Block:"360", label:"MAHADEVA VIDHYALAYAM MIDDLE SCHOOL, WARD 17", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3743", district:"4", Block:"107", label:"PUMS,CHIKKARAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3744", district:"11", Block:"354", label:"GMS-PARVATHIPUTHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3745", district:"23", Block:"79", label:"PUMS, A.NEDUNGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3746", district:"9", Block:"347", label:"AIDED MIDDLE SCHOOL THIMMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3747", district:"11", Block:"355", label:"GMS KOTTIKODE", Sch_type:"Government", Category:"Middle School"},
    { key:"3748", district:"27", Block:"375", label:"St. PERIYANAYAGI MIDDLE SCHOOL -  UTHAMAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3749", district:"25", Block:"42", label:"PUMS-THOGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3750", district:"6", Block:"218", label:"PUMS THIRUMALVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3751", district:"25", Block:"336", label:"PUMS-MAHARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3752", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL -  M PUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3753", district:"34", Block:"48", label:"PUMS-MUKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3754", district:"6", Block:"218", label:"PUMS CHANDRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3755", district:"4", Block:"248", label:"PANCHAYAT UNION MIDDLE SCHOOL,KUNDALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3756", district:"6", Block:"235", label:"PUMS A.ETTIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3757", district:"24", Block:"388", label:"GURUSAMY MS, RAMANATHAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3758", district:"36", Block:"74", label:"PUMS MELMUTTUKOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3759", district:"30", Block:"46", label:"PUMS MELAUPOORANI", Sch_type:"Government", Category:"Middle School"},
    { key:"3760", district:"11", Block:"191", label:"GOVERNMENT MIDDLE SCHOOL-PUDUKADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3761", district:"25", Block:"144", label:"MPL MS - KOTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3762", district:"12", Block:"140", label:"SARASWATHY VIDYALAYA  MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3763", district:"28", Block:"8", label:"PUMS, AVARANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3764", district:"34", Block:"27", label:"MMS ANANTHAPURAM(N) ARNI", Sch_type:"Government", Category:"Middle School"},
    { key:"3765", district:"21", Block:"408", label:"MMS MARKET  WALAJAH", Sch_type:"Government", Category:"Middle School"},
    { key:"3766", district:"35", Block:"141", label:"AMS, KODAVASAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3767", district:"27", Block:"31", label:"PUMS - RAJAKKALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3768", district:"17", Block:"224", label:"PUMS ALAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"3769", district:"33", Block:"184", label:"PUMS,VELLIVOYAL CHAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3770", district:"32", Block:"371", label:"PUMS ORALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3771", district:"24", Block:"6", label:"TDTA MS, Solaiseri", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3772", district:"28", Block:"297", label:"PUMS MEENACHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3773", district:"34", Block:"47", label:"PUMS KILPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"3774", district:"32", Block:"371", label:"MMS PALANIANDAVAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3775", district:"3", Block:"237", label:"PONNUSWAMY MAT HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3776", district:"20", Block:"86", label:"AMBIKAVATHY HINDU NADAR MIDDLE SCHOOL, VELLAPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3777", district:"3", Block:"240", label:"C.S.I. MIDDLE SCHOOL (CHOOLAI)", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3778", district:"34", Block:"256", label:"PUMS ADIVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3779", district:"32", Block:"32", label:"PUMS VADUGAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3780", district:"14", Block:"283", label:"P.U.M.S. ATHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3781", district:"34", Block:"47", label:"ST. JOSEPH MATRICULATION SUSAINAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3782", district:"34", Block:"312", label:"PUMS -NAVAKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3783", district:"25", Block:"314", label:"SRI SADASIVA AIDED MIDDLE SCHOOL- THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3784", district:"23", Block:"96", label:"PUMS, PALAVANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3785", district:"14", Block:"328", label:"LITTLE DIAMONDS MATRIC HIGH SCHOOL, VILLAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3786", district:"30", Block:"46", label:"SRI RAMAKRISHNA MIDDLE SCHOOL CHERANMAHADEVI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3787", district:"34", Block:"365", label:"PUMS ERADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3788", district:"4", Block:"302", label:"PUMS KARAVALI MATHAPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3789", district:"3", Block:"195", label:"ROSARY MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3790", district:"28", Block:"351", label:"PAUL MATRIC SCHOOL, KANDHASAMYPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3791", district:"28", Block:"351", label:"X'AN MATRIC HR.SEC SCHOOL, THOOTHUKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3792", district:"14", Block:"161", label:"ROTARY LAHARRY MATRIC HR SEC SCHOOL, BIBI KULAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3793", district:"3", Block:"270", label:"MEASI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3794", district:"27", Block:"319", label:"GKMS - KAKKIVADANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"3795", district:"4", Block:"248", label:"VISWADEEPTHI MAT HSS, UNJAVELAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3796", district:"29", Block:"343", label:"PERIYAR CENT MEM MAT HR SEC SCHOOL, PERIYAR NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3797", district:"14", Block:"328", label:"AMALA MATRICULATION HIGHER SECONDARY SCHOOL, THIRUNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3798", district:"13", Block:"78", label:"SRI BHARATHI VIDYALAYA MHSS ALASANATHAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3799", district:"13", Block:"177", label:"SUNFLOWER MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3800", district:"4", Block:"13", label:"SHRI VIGNESWARA MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3801", district:"13", Block:"78", label:"ST.JOSEPH'S MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3802", district:"21", Block:"21", label:"SACRED HEART MHSS ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3803", district:"11", Block:"3", label:"ST.JOSEPH'S MATRIC HR.SEC SCHOOL - NAGERCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3804", district:"4", Block:"245", label:"S.E.S MATRICULATION HR SEC SCHOOL, GANAPATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3805", district:"36", Block:"113", label:"CLUNY MHSS KATPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3806", district:"11", Block:"355", label:"AMALA MATRIC -THUCKALAY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3807", district:"25", Block:"344", label:"ANURADHA MAHA VIDYALAYA  MATRICULATION HIGHER SECONDARY SCHOOL  -NALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3808", district:"34", Block:"312", label:"PUMS KUBERAPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3809", district:"18", Block:"236", label:"PANIMALAR MATRICULATION HIGHER SECONDARY SCHOOL PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3810", district:"32", Block:"104", label:"MERCY MATRIC HR SEC SCHOOL, KADAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3811", district:"7", Block:"220", label:"KARTHIK VIDHYA MANDIR MATRIC HIGHER SECONDARY SCHOOL, PALANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3812", district:"21", Block:"21", label:"PUMS SEYYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3813", district:"5", Block:"54", label:"MUNICIPAL MIDDLE SCHOOL - DEVANAMPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3814", district:"22", Block:"274", label:"YUVA BHARATHI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3815", district:"7", Block:"36", label:"N.S.V.V MATRIC HSS, PATTIVEERANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3816", district:"25", Block:"345", label:"SRI KRISHNA MATRIC-P.VIDUTHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3817", district:"25", Block:"11", label:"ST. MARCINA'S MAT.HSS-AMMAPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3818", district:"4", Block:"279", label:"PERIYANAYAKI AMMAN  MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3819", district:"34", Block:"365", label:"SRI RAMAKRISHNA MAT HS TIRUVANNAMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3820", district:"13", Block:"78", label:"ST.AUGUSTINE MAT.HR SEC SCHOOL ONNALVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3821", district:"5", Block:"143", label:"PUMS VADAKKUKOLAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3822", district:"22", Block:"273", label:"N.P.R. MATRIC HR. SEC. SCHOOL , NATTAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3823", district:"20", Block:"231", label:"KALIDASS MIDDLE SCHOOL, PARAMAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3824", district:"4", Block:"248", label:"NACHIAR VIDYALAYAM MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3825", district:"22", Block:"129", label:"M.A.M.MATRIC HR.SEC.SCHOOL,MATHAIYANKUTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3826", district:"27", Block:"40", label:"SCISM MATRIC HSS  - BODINAYAKANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3827", district:"7", Block:"29", label:"LEO MATRIC SCHOOL,ANNAMALAIYAR MILLS COLONY, DINDIGUL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3828", district:"34", Block:"312", label:"PUMS VEPPUR CHEKKADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3829", district:"36", Block:"113", label:"SHRI MANU MAKHIJA MEMORIAL SHRISHTI MATRIC SCHOOL, SENGUTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3830", district:"34", Block:"358", label:"SHISHYA MATIRC HSS MUNIYANDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3831", district:"38", Block:"206", label:"ASMA MATRICULATION HIGH SCHOOL VEERACHOLAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"3832", district:"3", Block:"2", label:"SIVASAKTHI MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3833", district:"11", Block:"3", label:"KASTURBA MIDDLE SCHOOL-THEROOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3834", district:"22", Block:"273", label:"SENGUNTHAR MATRIC  HR. SEC. SCHOOL, SANYASIKUNDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3835", district:"30", Block:"222", label:"Sarah Tucker Hr Sec School For Girls, Palayamkottai", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"3836", district:"17", Block:"224", label:"MMS NARAYANA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3837", district:"14", Block:"374", label:"SEVENTHDAY ADVENTIST MATRICULATION HIGHER SECONDARY SCHOOL, USILAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3838", district:"4", Block:"349", label:"DELHI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3839", district:"27", Block:"55", label:"AL-AZ'HAR MATRICULATION HIGHER SECONDARY  SCHOOL, CUMBUM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3840", district:"12", Block:"112", label:"CHERAN MAT. HR.SEC.SCHOOL, VENNAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3841", district:"11", Block:"3", label:"GMS KALIANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3842", district:"3", Block:"259", label:"DAV MAT HSS GILL NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3843", district:"31", Block:"82", label:"ST.CHARLES MHSS THIRUPATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3844", district:"34", Block:"395", label:"PUMS SUMANGALI", Sch_type:"Government", Category:"Middle School"},
    { key:"3845", district:"4", Block:"242", label:"ANNAI VIOLET MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3846", district:"35", Block:"141", label:"PUMS, SARGUNESWARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3847", district:"31", Block:"362", label:"PUMS NARAYANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3848", district:"11", Block:"355", label:"GOVERNMENT MIDDILE SCHOOL PATHIRAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3849", district:"14", Block:"328", label:"KRISHNAMAL RAMASUBBAIYER SCHOOL, TVR NAGAR, MADURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3850", district:"20", Block:"332", label:"DHEENIAH MATRICULATION  HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3851", district:"33", Block:"252", label:"RCM MATRICULATION SCHOOL, OLD AGRAHARAM, AVADI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3852", district:"7", Block:"127", label:"SAANTHAM MATRICULATION HIGHER SECONDARY SCHOOL,NAIDUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3853", district:"33", Block:"337", label:"PUMS, KANCHIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3854", district:"30", Block:"46", label:"LAKSHMIPATHI MS KALLIDAIKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3855", district:"28", Block:"402", label:"SHARON MATRIC HR.SEC.SCHOOL, VILATHIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3856", district:"26", Block:"370", label:"PRIYADHARSHINI MATRIC SCHOOL, BIKKATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"3857", district:"17", Block:"254", label:"PAAVAI VIDHYASHRAM SENIOR SECONDARY SCHOOL, PACHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3858", district:"25", Block:"216", label:"SRI SRINIVASA  MATRIC HSS-ORATHANADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3859", district:"34", Block:"256", label:"PUMS NAGAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"3860", district:"25", Block:"336", label:"PUMS-VEERASINGAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3861", district:"29", Block:"343", label:"ST. LITTLE FLOWER MATRIC HIGHER SECONDARY SCHOOL, KATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3862", district:"29", Block:"343", label:"VVJMPV MATRIC HR SEC SCHOOL , POLICE COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3863", district:"17", Block:"254", label:"PUMS KATHIRANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3864", district:"31", Block:"208", label:"SSV MATRICULATION HIGHER SECONDARY SCHOOL, NATRAMPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3865", district:"11", Block:"355", label:"ALDRIN MATRIC HIGHER SECONDARY SCHOOL, KANDANVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3866", district:"12", Block:"142", label:"AUROBINDO MATRIC HR SEC SCHOOL NACHALUR KARUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3867", district:"13", Block:"78", label:"ST.ANN'S MATRICULATION.SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3868", district:"31", Block:"362", label:"PUMS, KAKANAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3869", district:"32", Block:"250", label:"PUMS KH COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"3870", district:"25", Block:"313", label:"AMS - ST. XAVIER  VALLAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3871", district:"36", Block:"113", label:"SHRISHTI CBSE BRAMMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3872", district:"31", Block:"208", label:"MMS NETHAJI NAGAR NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"3873", district:"22", Block:"383", label:"ST. MICHAEL'S MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3874", district:"22", Block:"30", label:"SARASWATHI MATRIC SCHOOL, MULLAIVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3875", district:"17", Block:"85", label:"K.S MANIAM VIDHYA BHAVAN SENIOR SECONDARY SCHOOL  CBSE ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3876", district:"25", Block:"11", label:"PUMS - VAIYACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3877", district:"7", Block:"213", label:"BFHV MATRICULATION HSS,ODDANCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3878", district:"7", Block:"29", label:"HOLY CROSS MATRICULATION HIGHER SECONDARY SCHOOL,VAKKAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3879", district:"11", Block:"355", label:"ST ANTONY'S MATRICULATION SCHOOL, CHEMPARUTHIVILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3880", district:"13", Block:"78", label:"PUMS GOLLAPALLI -URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"3881", district:"4", Block:"107", label:"PASUPATHIAPPA MIDDLE SCHOOL, KARAMADAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3882", district:"28", Block:"350", label:"PUMS THERMALNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3883", district:"19", Block:"25", label:"KARPAGA VINAYAGA MATRICULATION HIGHER SECONDARY SCHOOL, SIVAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3884", district:"22", Block:"383", label:"SRI THIAGARAJAR MEMORIAL MATRICULATION SCHOOL,BELUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"3885", district:"7", Block:"127", label:"ZION MHSS,KODAIKANAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3886", district:"3", Block:"69", label:"SACRED HEART MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3887", district:"24", Block:"307", label:"OXFORD MATRIC. HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3888", district:"36", Block:"113", label:"SUNBEAM MATRIC HSS, METTUKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3889", district:"13", Block:"78", label:"PUMS NAGONDAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3890", district:"21", Block:"359", label:"PUMS GUNDALERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3891", district:"34", Block:"358", label:"RCM MIDDLE BOODHAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3892", district:"11", Block:"341", label:"GMS MATHAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3893", district:"6", Block:"230", label:"VENGANAR VAJRAM MHSS, PAPPIREDDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3894", district:"25", Block:"330", label:"SRI KUMARA GURUPARA SWAMIGAL MIDDLE SCHOOL - THIRUPPANANDAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3895", district:"5", Block:"143", label:"AMS VARAVARA MUNI CHIDAMBARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3896", district:"29", Block:"15", label:"VIGNESH SRI RENGA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3897", district:"34", Block:"312", label:"PUMS KOTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3898", district:"6", Block:"77", label:"PUMS GANAPATHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3899", district:"3", Block:"195", label:"DAVID MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3900", district:"30", Block:"46", label:"SUN MATRICULATION SCHOOL CHERANMAHADEVI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3901", district:"25", Block:"42", label:"PUMS-KOOTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3902", district:"6", Block:"198", label:"VALLALAR ARIVALAYAM MATRIC SCHOOL, VALLUVAR NAGAR, DHARMAPURI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3903", district:"13", Block:"78", label:"PUMS MUGULAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"3904", district:"25", Block:"144", label:"NATIONAL VIDYALAYA CBSE HSS - KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3905", district:"4", Block:"247", label:"PUMS JALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3906", district:"11", Block:"355", label:"RCHS CHEMPARUTHIVILAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"3907", district:"6", Block:"77", label:"PUMS PERIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3908", district:"11", Block:"182", label:"FRIENDS MATRICULATION HIGHER SECONDARY SCHOOL PANAMKALAI, KALIAKKAVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3909", district:"28", Block:"297", label:"RC MS G.PATTINAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3910", district:"13", Block:"139", label:"MTV MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3911", district:"34", Block:"256", label:"PUMS NANDHIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3912", district:"7", Block:"60", label:"SRI VASAVI MATRICULATION HR. SEC. SCHOOL, DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3913", district:"31", Block:"362", label:"PUMS, KEELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3914", district:"4", Block:"245", label:"EQUITAS GURUKUL MATRIC.HR.SEC .SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3915", district:"25", Block:"313", label:"PUMS - MARIYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3916", district:"5", Block:"143", label:"PUMS NANTHIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3917", district:"33", Block:"253", label:"PUMS, RAMANJERI", Sch_type:"Government", Category:"Middle School"},
    { key:"3918", district:"33", Block:"76", label:"ST MARYS HSS CHINTHALAKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3919", district:"4", Block:"52", label:"AL-AZHAR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3920", district:"25", Block:"144", label:"AA MPL MS KUMBAKONAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3921", district:"23", Block:"271", label:"PUMS K.PUDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"3922", district:"21", Block:"408", label:"VEDANIKETHAN MATRIC HSS, ARCOT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3923", district:"4", Block:"248", label:"ADITYA VIDHYA MANDIR MATRIC. HR. SEC. SCHOOL, SULESWARANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3924", district:"24", Block:"119", label:"TDTA MS-RAJAGOBALAPERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3925", district:"29", Block:"367", label:"St. Arnold's Mat. School, Ponmalaipatti.", Sch_type:"Un-aided", Category:"High School"},
    { key:"3926", district:"28", Block:"369", label:"HASSANIYA MIDDLE SCHOOL, KULASEKARANPATTINAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3927", district:"14", Block:"324", label:"SARAVANA'S VIDHYA MANDIR MATRIC HR SEC SCHOOL, KANGEYANATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3928", district:"4", Block:"52", label:"RAM NAGAR SUBURBAN MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3929", district:"4", Block:"245", label:"SINDHI VIDYALAYA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3930", district:"14", Block:"328", label:"HDI JAIN MATRIC HR SEC SCHOOL, PASUMALAI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3931", district:"6", Block:"235", label:"PUMS VELAMANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3932", district:"3", Block:"270", label:"DR RSM GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"3933", district:"30", Block:"361", label:"Little Flower Mat. HSS, Tvl", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3934", district:"4", Block:"52", label:"PANDIYAN MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3935", district:"11", Block:"191", label:"ST JOSEPH MIDDLE SCHOOL  ERAVIPUTHENTHURAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"3936", district:"6", Block:"218", label:"PUMS CHENNAPPANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3937", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL PRAYAMBATH", Sch_type:"Government", Category:"Middle School"},
    { key:"3938", district:"33", Block:"184", label:"EDEN MATRICULATION HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3939", district:"25", Block:"344", label:"PUMS - MANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3940", district:"3", Block:"368", label:"D AHMED ALI PARPIA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3941", district:"25", Block:"313", label:"PUMS - THITTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3942", district:"11", Block:"355", label:"A.G. MATRICULATION SCHOOL,CHERIKADAI,PALLIYADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3943", district:"3", Block:"259", label:"AMBAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3944", district:"4", Block:"279", label:"THAVATHIRU KANDASAMY SWAMIGAL MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3945", district:"26", Block:"53", label:"NILGIRI MATRICULATION SCHOOL, RKS, YELLANALLI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3946", district:"3", Block:"237", label:"ANGEL MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3947", district:"32", Block:"392", label:"BALA MATRIC HR.SEC SCHOOL , VELLAKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3948", district:"14", Block:"161", label:"CATHY MATRIC HR SEC SCHOOL, KURINJI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3949", district:"25", Block:"228", label:"SRI. MAHAGANAPATHY MIDDLE SCHOOL - GANAPATHY AGRAGARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3950", district:"11", Block:"3", label:"ALPHA MATRIC  HSS KRISHNANCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3951", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL ALICHIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"3952", district:"25", Block:"42", label:"VIDHYA MHSS- BUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3953", district:"5", Block:"54", label:"SARVA SEVA MATRICULATION SCHOOL, KANNARAPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3954", district:"33", Block:"184", label:"PUMS, MADIMAI KANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3955", district:"13", Block:"78", label:"PUMS MATHIGIRI BOYS -URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"3956", district:"28", Block:"280", label:"PUMS Panaivilai", Sch_type:"Government", Category:"Middle School"},
    { key:"3957", district:"25", Block:"287", label:"PUMS -RETTAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"3958", district:"3", Block:"2", label:"CAMBRRIDGE MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3959", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL, NARAIYOORANI", Sch_type:"Government", Category:"Middle School"},
    { key:"3960", district:"24", Block:"89", label:"SRI ATHRIKALA NIALAYA MS  SIVASAILAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3961", district:"11", Block:"355", label:"ST.JOHNS MATRIC HIGHER SECONDARY SCHOOL, PALLIYADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3962", district:"3", Block:"195", label:"ST.BEDE'S A.I HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"3963", district:"11", Block:"341", label:"LITTLE FLOWER MATRICULATION SCHOOL CHETTICHARVILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"3964", district:"7", Block:"59", label:"SEVENTH DAY ADVENTIST MATRIC HSS,PONNAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3965", district:"11", Block:"149", label:"GMS MONDAIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3966", district:"6", Block:"77", label:"PUMS CHANDRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3967", district:"13", Block:"78", label:"PUMS SEETHARAM NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"3968", district:"32", Block:"363", label:"PUMS, SAMUNDIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3969", district:"22", Block:"273", label:"SRI VIDYA MANDIR SR. SEC. SCHOOL (CBSE), MEIYYANOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3970", district:"3", Block:"62", label:"MINERVA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"3971", district:"22", Block:"133", label:"A.G.N. MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3972", district:"38", Block:"282", label:"SOUTHSIDE MATRICULATION HIGHER SECONDARY SCHOOL, METTAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3973", district:"27", Block:"55", label:"ST.MARY'S.MATRIC HR SEC SCHOOL - CUMBUM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3974", district:"13", Block:"122", label:"PUMS ANUSONAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3975", district:"4", Block:"279", label:"CMS MASAKALIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3976", district:"11", Block:"3", label:"HEBRON MATRIC.HIGHER SEC.SCHOOL-PAVALAR NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3977", district:"12", Block:"23", label:"HAJEE MEERA ACADEMY,ESANATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"3978", district:"22", Block:"163", label:"SWAMY VIVEKANANDAR MATRIC HR. SEC. SCHOOL,KANCHAMALAYUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3979", district:"11", Block:"355", label:"GMS VILAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"3980", district:"27", Block:"55", label:"FAIRLANDS  A FOUNDATION SCHOOL - CBSE - C. PUTHUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3981", district:"13", Block:"78", label:"PUMS KUMUDEPALLI -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"3982", district:"11", Block:"124", label:"CJM HSS UNNAMALAIKADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3983", district:"31", Block:"362", label:"PUMS PUDHU POONGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3984", district:"3", Block:"237", label:"KAMARAJ MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3985", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, THONDANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"3986", district:"25", Block:"239", label:"KALAIMAGAL MATRIC HR SEC SCHOOL - THIRUCHITRAMBALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3987", district:"29", Block:"258", label:"DALMIA HSS,DALMIAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"3988", district:"35", Block:"173", label:"UAMHSS OXFORD , KOOTHANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3989", district:"30", Block:"385", label:"LITTLE FLOWER NURSERY PS PANAGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"3990", district:"24", Block:"119", label:"PUMS-THUVARANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"3991", district:"36", Block:"74", label:"PUMS ERIPATTARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3992", district:"25", Block:"344", label:"PUMS - SATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3993", district:"12", Block:"83", label:"RN MATRIC.HR.SEC.SCHOOL,CHINNADHARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3994", district:"25", Block:"144", label:"AIDED MS KORANATTU KARUPPUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"3995", district:"21", Block:"359", label:"PUMS PUNGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"3996", district:"6", Block:"235", label:"PARAMVEER MHSS, PAUPARAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"3997", district:"5", Block:"148", label:"PANCHAYAT UNION MIDDLE SCHOOL -  PERIYAKATTUSAGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"3998", district:"33", Block:"253", label:"PUMS, MANJAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"3999", district:"27", Block:"375", label:"PUMS - KOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4000", district:"11", Block:"355", label:"GMS THUCKALAY", Sch_type:"Government", Category:"Middle School"},
    { key:"4001", district:"21", Block:"409", label:"VKV MATRIC SCHOOL, VANNIVEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"4002", district:"27", Block:"241", label:"NELLAIAPPER MIDDLE SCHOOL - VADAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4003", district:"31", Block:"155", label:"MMS PANNEER SELVAM NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"4004", district:"36", Block:"12", label:"SHIKSHA KENDRA MHSS PALLIKONDA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4005", district:"26", Block:"370", label:"THE OXFORD CONTINENTAL MATRIC HS SCHOOL OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4006", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOZHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4007", district:"7", Block:"207", label:"SFS MATRICULATION SCHOOL ,SENDURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4008", district:"11", Block:"191", label:"GOVT MIDDLE SCHOOL  PORANCODE", Sch_type:"Government", Category:"Middle School"},
    { key:"4009", district:"29", Block:"258", label:"CHILD JESUS MATRI.S,PULLAMBADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4010", district:"25", Block:"228", label:"PUMS - PANDARAVADI (NORTH)", Sch_type:"Government", Category:"Middle School"},
    { key:"4011", district:"6", Block:"77", label:"PUMS KOLAGAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"4012", district:"3", Block:"304", label:"SPRINGFIELD MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4013", district:"3", Block:"259", label:"CSI BAIN MATRICULATION & HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4014", district:"5", Block:"120", label:"AIDED MIDDLE SCHOOL- POONTHOTTAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4015", district:"23", Block:"272", label:"PUMS V.SOORAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4016", district:"23", Block:"94", label:"O M G S MATRIC SCHOOL KALAIYARKOIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4017", district:"13", Block:"78", label:"PUMS BEDARAPALLI -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4018", district:"5", Block:"39", label:"PUMS KUMUDIMOOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4019", district:"22", Block:"278", label:"AKASH VIDHYA BHAVAN MATRIC HR SEC SCHOOL, SANKARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4020", district:"31", Block:"362", label:"SHEPHERDS MATRIC HSS, MADAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4021", district:"22", Block:"61", label:"ST.MARY'S MATRIC SCHOOL, VELLANDIVALASU", Sch_type:"Un-aided", Category:"High School"},
    { key:"4022", district:"21", Block:"408", label:"THAMIZH THENRAL THIRU VI-KA MIDDLE SCHOOL RANIPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4023", district:"21", Block:"290", label:"PUMS-CHINAPARAVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4024", district:"34", Block:"312", label:"PUMS MALAYANUR CHEKKADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4025", district:"25", Block:"228", label:"RDB MATRICULATION HSS-PAPANASAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4026", district:"36", Block:"105", label:"CAMFORD MATRIC HSS, KANIYAMBADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4027", district:"25", Block:"344", label:"PUMS - AMMANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4028", district:"26", Block:"53", label:"TIMBRE TOPS MATRICULATION SCHOOL, COONOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4029", district:"17", Block:"63", label:"SHRI RAMACHANDIRA MATRIC HR SEC SCHOOL,ELACHIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4030", district:"11", Block:"354", label:"GMS-THERISANAMKOPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"4031", district:"5", Block:"39", label:"PUMS C.ALAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4032", district:"33", Block:"252", label:"ST.JOHNS MATRIC HR SEC SCHOOL, LAKSHMIPURAM, THIRUNINRAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4033", district:"25", Block:"336", label:"HARIJAN AIDED MS-VELLAMPERAMBUR-E", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4034", district:"4", Block:"279", label:"NATIONAL MODEL MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4035", district:"11", Block:"191", label:"MUNCHIRAI PUNITHA AROCKIYAMATHA MAT. HSS PUTHUKADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4036", district:"25", Block:"314", label:"GIRLS CHRISTIAN AIDED HR SEC SCHOOL-M-CHAVADI-THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"4037", district:"5", Block:"39", label:"PUMS NELLIKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4038", district:"14", Block:"161", label:"SPARTANNS MATRIC HIGH SCHOOL, NAGANAKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4039", district:"2", Block:"300", label:"St 'Joseph MHSS Butt road", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4040", district:"11", Block:"341", label:"Vedavyasa Matriculation Higher Secondary School Vellicode", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4041", district:"32", Block:"363", label:"THIRUMURUGAN MHS,NERUPPERICHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4042", district:"11", Block:"355", label:"GMS KOTTAMAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"4043", district:"1", Block:"81", label:"MODERN MATRICULATION  HIGHER  SECONDARY  SCHOOL  JAYANKONDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4044", district:"6", Block:"58", label:"DAWN MHSS, S.V. ROAD, DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4045", district:"36", Block:"393", label:"SARASWATHI VIDYALAYA MATRIC SCHOOL, ALAMELUMANGAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4046", district:"3", Block:"69", label:"SRI SAYEE VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4047", district:"7", Block:"213", label:"AKSHAYA MATRICULATION HSS,LAKKAYANKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4048", district:"27", Block:"49", label:"NADARKAL URAVINMURAI MS - SUKKANGALPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4049", district:"12", Block:"142", label:"NALANTHA MATRICULATION SCHOOL,KARUNGALPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4050", district:"13", Block:"78", label:"PUMS BAGALUR -URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4051", district:"4", Block:"107", label:"METRO MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4052", district:"33", Block:"64", label:"PUMS, 82 PANAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4053", district:"25", Block:"336", label:"PUMS -VILANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4054", district:"29", Block:"152", label:"NEST MATRICULATION HSS,LALGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4055", district:"13", Block:"78", label:"PUMS BEGAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4056", district:"30", Block:"361", label:"Presentation Conv. Mat. HS, Tvl", Sch_type:"Un-aided", Category:"High School"},
    { key:"4057", district:"3", Block:"62", label:"CSI MONAHAN MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4058", district:"7", Block:"220", label:"AKSHAYA ACADEMY MATRIC HSS,PALANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4059", district:"5", Block:"54", label:"MUNICIPAL MIDDLE SCHOOL -  PUDHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4060", district:"28", Block:"351", label:"THE VIKASA SCHOOL, MILLERPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4061", district:"38", Block:"295", label:"S.D.A. MATRICULATION HIGH SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4062", district:"34", Block:"47", label:"PUMS ATHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4063", district:"34", Block:"365", label:"VICTORIYA HINDU MS TIRUVANNAMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4064", district:"27", Block:"49", label:"MAYAR RAM MATRIC SCHOOL - CHINNAMANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4065", district:"29", Block:"343", label:"CSI MATRIC HIGH SCHOOL ,PONMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4066", district:"36", Block:"74", label:"PUMS PONNAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4067", district:"20", Block:"266", label:"VALLUVAN MIDDLE SCHOOL , VELIPATTINAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4068", district:"34", Block:"395", label:"PUMS PUNNAI PUDUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4069", district:"11", Block:"191", label:"G M S KOLLEMCODE", Sch_type:"Government", Category:"Middle School"},
    { key:"4070", district:"21", Block:"408", label:"SEVENTHDAY ADVENTIST MATRIC SCHOOL, RANIPET", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4071", district:"4", Block:"13", label:"PUMS GENGAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4072", district:"4", Block:"248", label:"PUMS SEMBAGOUNDARCOLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"4073", district:"3", Block:"62", label:"SEVENTH DAY ADVENTIST MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4074", district:"13", Block:"78", label:"PUMS ARASANATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4075", district:"34", Block:"27", label:"PUMS VELLERI", Sch_type:"Government", Category:"Middle School"},
    { key:"4076", district:"5", Block:"115", label:"PUMS SRI ADIVARAGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4077", district:"31", Block:"5", label:"PUMS KURUMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4078", district:"17", Block:"267", label:"PU MIDDLE SCHOOL,KURUKKAPURAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"4079", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL  KANGIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4080", district:"17", Block:"232", label:"PUMS BOMMAKKAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4081", district:"13", Block:"122", label:"SEVENTH DAY MATRIC SCHOOL MARASANDIRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4082", district:"23", Block:"79", label:"ST.J.MID.SCHOOL, NALLOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4083", district:"6", Block:"218", label:"PUMS NALLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4084", district:"14", Block:"158", label:"KASTHURIBA GANDHI MATRICULATION  SCHOOL, MATHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4085", district:"35", Block:"141", label:"PUMS, RADHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4086", district:"24", Block:"307", label:"TDTA MS, KAKAIYANUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4087", district:"13", Block:"122", label:"PUMS H.SETTIPALLI TAM&TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4088", district:"25", Block:"344", label:"PUMS - AYYAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4089", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL -  PETHANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4090", district:"36", Block:"74", label:"PUMS RAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4091", district:"11", Block:"182", label:"GMS-VANNIYOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4092", district:"6", Block:"235", label:"PUMS PODUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4093", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, A.THARAIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4094", district:"25", Block:"11", label:"PUMS - SIKKAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"4095", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL AGARAMEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4096", district:"13", Block:"122", label:"PUMS ECHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4097", district:"17", Block:"66", label:"PUMS THOOSUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4098", district:"33", Block:"76", label:"ST ANTONY MS ROSANAGARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4099", district:"25", Block:"216", label:"PUMS - THENNAMANADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4100", district:"13", Block:"311", label:"PUMS SITHANDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4101", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL KAMMAVAR KANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4102", district:"36", Block:"74", label:"PUMS ANANGANALLORE", Sch_type:"Government", Category:"Middle School"},
    { key:"4103", district:"34", Block:"93", label:"PUMS KOOTRAMPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4104", district:"25", Block:"157", label:"SRI RAMAVILAS AIDED MIDDLE SCHOOL - ELANKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4105", district:"6", Block:"198", label:"PUMS E.K.PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4106", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL , PATTAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"4107", district:"21", Block:"290", label:"PUMS VENGUPET", Sch_type:"Government", Category:"Middle School"},
    { key:"4108", district:"3", Block:"2", label:"CMS METTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4109", district:"34", Block:"387", label:"PUMS SENNAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4110", district:"12", Block:"112", label:"P.U.MIDDLE SCHOOL,PANCHAMADEVI", Sch_type:"Government", Category:"Middle School"},
    { key:"4111", district:"13", Block:"376", label:"PUMS PULIYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4112", district:"5", Block:"120", label:"AIDED-VALLALAR AIDED MIDDLE SCHOOL- PUDHUPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4113", district:"3", Block:"2", label:"A.C. Middle School Kanagam", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4114", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL ,  POTHIRAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4115", district:"6", Block:"218", label:"PUMS ULAGANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4116", district:"36", Block:"113", label:"PUMS KAMMAVAR PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4117", district:"4", Block:"302", label:"PANCHAYAT UNION MIDDLE SCHOOL VADUGANKALIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4118", district:"34", Block:"249", label:"PUMS NARANAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4119", district:"11", Block:"355", label:"V. N. MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL-MOOLACHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4120", district:"34", Block:"44", label:"PUMS PERIYAKALTHAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4121", district:"7", Block:"352", label:"VIVEKANANDA HINDU MATRICULATION SCHOOL, NEELAGOUNDANPATTI.", Sch_type:"Un-aided", Category:"High School"},
    { key:"4122", district:"12", Block:"140", label:"PUMS, S.MUNAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4123", district:"5", Block:"143", label:"PUMS N.POOLAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4124", district:"4", Block:"248", label:"PUMS,SRINIVASAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4125", district:"34", Block:"318", label:"PUMS RAMASAMUDIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4126", district:"13", Block:"78", label:"PUMS PUNGANDODDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4127", district:"28", Block:"369", label:"TNDTA MIDDLE SCHOOL MEYYOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4128", district:"27", Block:"241", label:"RENGAKRISHNAN MIDDLE SCHOOL - PERIYAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4129", district:"8", Block:"20", label:"PUMS , MADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4130", district:"11", Block:"354", label:"BAS MAT SCHL-CHENBAGARAMANPUTHOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4131", district:"17", Block:"267", label:"THIRUVENKATAVILAS AIDED MIDDLE SCHOOL,RASIPURAM.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4132", district:"31", Block:"82", label:"PUMS ODDAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4133", district:"31", Block:"362", label:"PUMS, VAZHUTHALAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"4134", district:"3", Block:"2", label:"GANAPATHY NATIONAL MIDDLE SCHOOL, SAIDAPET, CH-15.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4135", district:"5", Block:"39", label:"PUMS KARAIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4136", district:"27", Block:"375", label:"St. THERASA'S R.C. MIDDLE SCHOOL - THEVARAM.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4137", district:"25", Block:"336", label:"SRI RAMA KRISHNA AIDED MIDDLE SCHOOL-THILLAISTHANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4138", district:"35", Block:"141", label:"PUMS, 18.PUTHUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4139", district:"13", Block:"122", label:"PUMS AGGONDAPALLI TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4140", district:"13", Block:"122", label:"PUMS RAYAKOTTAI URDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4141", district:"31", Block:"5", label:"PUMS, PALLIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"4142", district:"27", Block:"40", label:"S.K.S.MS - DOMBUCHERY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4143", district:"11", Block:"341", label:"ST. SAHAYAMATHA M.S. EANCHACODE", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4144", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, GOVINDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4145", district:"25", Block:"144", label:"PUMS - AMMAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4146", district:"28", Block:"369", label:"GOVERNMENT MS UDANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4147", district:"34", Block:"387", label:"PUMS-KILSATHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4148", district:"5", Block:"115", label:"PANCHAYAT UNION MIDDLE SCHOOL  ANANTHAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4149", district:"34", Block:"249", label:"PUMS EDAPIRAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4150", district:"24", Block:"388", label:"SENAITHALAIVAR MS, VASUDEVANALLUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4151", district:"6", Block:"218", label:"WISDOMLAND MATRIC SCHOOL, KARAGATHAHALLI, PALACODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"4152", district:"34", Block:"387", label:"PUMS KOVALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4153", district:"25", Block:"314", label:"CMS-CHINNAKADAITHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"4154", district:"24", Block:"307", label:"M.M. MS VISWANTHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4155", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, KARUNGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4156", district:"34", Block:"238", label:"PUMS VINAYAGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4157", district:"4", Block:"247", label:"PUMS ZAMINKALIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4158", district:"13", Block:"311", label:"PUMS SEENKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4159", district:"5", Block:"98", label:"PUMS KARKUDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4160", district:"34", Block:"395", label:"PUMS PERUMANTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4161", district:"36", Block:"74", label:"PUMS MELALATHUR (H)", Sch_type:"Government", Category:"Middle School"},
    { key:"4162", district:"28", Block:"297", label:"TNDTA MS KOVANKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4163", district:"24", Block:"119", label:"HINDU MS SUBBANOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4164", district:"13", Block:"78", label:"PUMS GOPANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4165", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL NADUKUTHAGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4166", district:"34", Block:"387", label:"PUMS SEMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4167", district:"5", Block:"143", label:"ADWMS- VARAHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4168", district:"21", Block:"24", label:"PUMS MANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4169", district:"6", Block:"235", label:"PUMS KRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4170", district:"24", Block:"6", label:"TDTA MS, Parankuntrapuram", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4171", district:"24", Block:"6", label:"NMM MS, Kidarakulam", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4172", district:"11", Block:"149", label:"GMS ALANVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4173", district:"17", Block:"267", label:"ARULMEGU VENKATESWARA AIDED MIDDLE SCHOOL,ANDAGALOREGATE.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4174", district:"13", Block:"311", label:"PUMS SARAKAPALLY -TAM-TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4175", district:"28", Block:"369", label:"PSM MS KULASEKARANPATTINAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4176", district:"6", Block:"218", label:"PUMS BELAMARANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4177", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL , SAMYPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4178", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - VAZHISOTHANAIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4179", district:"25", Block:"344", label:"ADWMS - S.PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4180", district:"3", Block:"2", label:"CMS CART MAN STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"4181", district:"33", Block:"76", label:"GPD REFUGE CAMP MS PETHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4182", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL MELMANAMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4183", district:"11", Block:"182", label:"GOVT. L M  M S ARUMANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4184", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4185", district:"5", Block:"120", label:"AIDED-RASAMBAL ACHI MIDDLE SCHOOL, PONNAKOIL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4186", district:"28", Block:"8", label:"TNDTA MIDDLE SCHOOL SINNAMADANKUDIERUPPU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4187", district:"11", Block:"341", label:"ST. JOSEPH'S MIDDLE SCHOOL PUTHANKADAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4188", district:"34", Block:"44", label:"PUMS NEW KUILAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4189", district:"28", Block:"369", label:"HOLY GHOST MS MANAPADU", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4190", district:"24", Block:"91", label:"P.U.M.S,DURAISAMYPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4191", district:"7", Block:"36", label:"SRI SARADHA VIDHYALAYA MATRIC HR SEC SCHOOL, BATLAGUNDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4192", district:"31", Block:"155", label:"B.S MATRIC SCHOOL, PACHAKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4193", district:"11", Block:"355", label:"GMS PUTHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4194", district:"6", Block:"218", label:"PUMS JERTHALAV", Sch_type:"Government", Category:"Middle School"},
    { key:"4195", district:"34", Block:"256", label:"PUMS MUNNURMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4196", district:"34", Block:"125", label:"ADWMS KARNAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4197", district:"34", Block:"312", label:"PUMS VEERANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4198", district:"25", Block:"336", label:"PUMS-THIRUPAZHANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4199", district:"25", Block:"228", label:"ST.SABASTIN MS-PAPANASAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4200", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERUNGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4201", district:"14", Block:"328", label:"INDIRA GANDHI MEMORIAL MATRIC HR SEC SCHOOL, TIRUNAGAR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4202", district:"5", Block:"39", label:"PUMS, VATHARAYANTHETHU", Sch_type:"Government", Category:"Middle School"},
    { key:"4203", district:"25", Block:"314", label:"SRI. GANESA VID. MS- POOKARATHERU-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4204", district:"5", Block:"98", label:"PUMS VEENANKENI", Sch_type:"Government", Category:"Middle School"},
    { key:"4205", district:"3", Block:"2", label:"BETHEL MAT HSS VELACHERY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4206", district:"33", Block:"225", label:"PUMS, JANGALA PALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4207", district:"22", Block:"179", label:"SRI RAGHAVENDRA MATRIC HR SEC SCHOOL MECHERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4208", district:"4", Block:"13", label:"SHRI MAHATHMA VIDHYAA MANTHIR MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4209", district:"8", Block:"71", label:"SRI NATIONAL SCHOOL, GOBI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4210", district:"11", Block:"341", label:"ST. THOMAS MSCMS THEMANOOR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4211", district:"4", Block:"349", label:"PUMS KALAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4212", district:"22", Block:"203", label:"MALCO VIDYALAYA MATRIC HR. SEC. SCHOOL, METTUR DAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4213", district:"17", Block:"267", label:"R C ST.MARY'S MIDDLE SCHOOL, KAKKAVERY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4214", district:"3", Block:"237", label:"DANISH MATRIC. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4215", district:"25", Block:"336", label:"ST.REETA AIDED MIDDLE SCHOOL-THIRUPPANTHURUTHI.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4216", district:"34", Block:"387", label:"KINGSTON MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4217", district:"33", Block:"184", label:"SON TRUST NUR& PRI SCH, MINJUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4218", district:"4", Block:"242", label:"PUMS 24 VEERAPANDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4219", district:"6", Block:"218", label:"PUMS PALACODE  URDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4220", district:"22", Block:"30", label:"VIVEKANANDA MATRICULATION HR.SEC. SCHOOL,PUDUPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4221", district:"17", Block:"254", label:"PUMS KANNURPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4222", district:"30", Block:"385", label:"Balakrishna Matriculation Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4223", district:"2", Block:"114", label:"SRI SANKARA VIDYALAYA MAT HSS, URAPAKKAM-603210", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4224", district:"29", Block:"171", label:"INFANT JESUS MATRIC. HR. SEC. SCHOOL, EDAMALAIPATTI PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4225", district:"11", Block:"355", label:"GOVERNMENT MIDDILE SCHOOL,,PULIYOORKURICHY", Sch_type:"Government", Category:"Middle School"},
    { key:"4226", district:"3", Block:"304", label:"STELLA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4227", district:"14", Block:"161", label:"P.S.Y MATRIC HR SEC SCHOOL, NARAYANAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4228", district:"17", Block:"397", label:"JVM MATRICULATION HR. SEC. SCHOOL,R.PUDUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4229", district:"27", Block:"40", label:"GOVT A.D.W MID SCHOOL - B.DURAIRAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4230", district:"11", Block:"191", label:"MAYA  KRISHNA SWAMY  VIDYALAYA, ANANTHANAGER, ATHENCODE.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4231", district:"3", Block:"2", label:"GEETHA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4232", district:"27", Block:"40", label:"SRI RAM SOLAI MIDDLE SCH -  KAMARAJAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4233", district:"7", Block:"127", label:"VIVEKANANDHA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, PANNAIKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4234", district:"29", Block:"381", label:"SAHAYA ANNAI MATRICULATION SCHOOL, AZAD ROAD", Sch_type:"Un-aided", Category:"High School"},
    { key:"4235", district:"24", Block:"307", label:"MKVK MATRIC HSS,TENKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4236", district:"5", Block:"120", label:"S.D.SION MATRICULATION HIGHER SECONDARY SCHOOL, BOOTHANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4237", district:"17", Block:"224", label:"MMS WEST COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"4238", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL KODUKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4239", district:"11", Block:"3", label:"GMS MARAVANKUDIERUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"4240", district:"2", Block:"299", label:"Mother Matriculation Higher Secondary School, Pallikaranai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4241", district:"25", Block:"216", label:"LITTLE ROSE M H S S- ORATHANADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4242", district:"36", Block:"113", label:"Kings Matric HSS Kasam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4243", district:"17", Block:"132", label:"DHARMAVIDYALAYA MATRI HIGH SCHOOL KOLLIHILLS", Sch_type:"Un-aided", Category:"High School"},
    { key:"4244", district:"2", Block:"322", label:"ST MARY'S MATRIC HR.SEC SCHOOL, Mamallapuram-603104", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4245", district:"13", Block:"139", label:"SRI SAI KRISHNA VIDYAGIRI MATRIC SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"4246", district:"25", Block:"144", label:"MYDEEN MAT. HSS - KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4247", district:"22", Block:"391", label:"VIVEKANANDA BALA MANDIR MATRIC SCHOOL, ATTAYAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"4248", district:"13", Block:"78", label:"BHAGAVANSHREE RAMAKRISHNA MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4249", district:"11", Block:"124", label:"WHITE MEMORIAL MATRIC. HIGHER SECONDARY SCHOOL, MARTHANDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4250", district:"6", Block:"198", label:"VALLALAR ARIVALAYAM MIDDLE SCHOOL, KURINJI NAGAR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4251", district:"23", Block:"96", label:"KOVILOOR ANDAVAR MATRIC HR SEC SCHOOL, KOVILOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4252", district:"11", Block:"124", label:"A.P.J.M.MATRIC HR.SEC.S-SOOSAIPURAM, KARUNGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4253", district:"17", Block:"85", label:"PUMS V.PUDHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4254", district:"13", Block:"376", label:"PUMS U.REDDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4255", district:"4", Block:"301", label:"KALAIMAGAL MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4256", district:"26", Block:"370", label:"ANNAI SARADADEVI MATRICULATION HR. SEC. SCHOOL, ANIKORAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4257", district:"6", Block:"58", label:"SRI VISWABHARATHI MHSS, A.GOLLAHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4258", district:"38", Block:"295", label:"SRI N M MEHTA JAIN MATRICULATION HR SEC SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4259", district:"31", Block:"155", label:"MMS GOVINDHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4260", district:"23", Block:"272", label:"MAHARISHI.VIDYA.MANDIR MATRIC.HR.SEC.SCHOOL, KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4261", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, NAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4262", district:"2", Block:"299", label:"SREE IYAPPA MHSS, SOZHANGANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4263", district:"13", Block:"78", label:"SREE SARASWATHI VID. MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4264", district:"31", Block:"103", label:"PUMS, SEVATHUR PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4265", district:"36", Block:"393", label:"DESIA MATRIC SCHOOL, SASTHRI NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4266", district:"4", Block:"248", label:"PUMS,AMBARAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4267", district:"8", Block:"10", label:"PUMS MURALI", Sch_type:"Government", Category:"Middle School"},
    { key:"4268", district:"28", Block:"350", label:"SRI VIVEKANANDA MATRIC SCHOOL MULLAKKADU CAMP II", Sch_type:"Un-aided", Category:"High School"},
    { key:"4269", district:"35", Block:"173", label:"MMS,GOPALA SAMUDRAM MANNARGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4270", district:"13", Block:"78", label:"PUMS RAM NAGAR- URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4271", district:"22", Block:"274", label:"VINAYAKA VIDYALAYA MATRIC HR. SEC. SCHOOL,FAIRLANDS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4272", district:"11", Block:"341", label:"John Paul ll Matric Hr. Sec. School Kulasekharam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4273", district:"5", Block:"17", label:"BWDA MATRIC HR SEC SCHOOL,  RASAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4274", district:"13", Block:"122", label:"PUMS KANDAGANAPALLI TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4275", district:"28", Block:"320", label:"MUHYIDDEEN MATRIC HR.SEC.SCHOOL KAYALPATNAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4276", district:"31", Block:"103", label:"PUMS NAICKANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4277", district:"13", Block:"122", label:"PUMS KOTTAIULIMANGALAM TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4278", district:"38", Block:"295", label:"THIRUTHANGAL LIONS MATRICULATION HR SEC SCHOOL, THIRUTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4279", district:"2", Block:"300", label:"Indra Gandhi MS Adambakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"4280", district:"34", Block:"358", label:"PUMS V.NAMMIYANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4281", district:"2", Block:"299", label:"TAGORE MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4282", district:"34", Block:"256", label:"PUMS NARASINGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4283", district:"11", Block:"355", label:"CARMAL MATRIC SCHOOL,  MANNALIKARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4284", district:"26", Block:"53", label:"MMB MATRIC HS", Sch_type:"Un-aided", Category:"High School"},
    { key:"4285", district:"22", Block:"129", label:"APPLE PERKS INTERNATIONAL SCHOOL,KOLATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4286", district:"22", Block:"383", label:"VAIGAI MATRIC. HR.SEC SCHOOL VALAPPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4287", district:"14", Block:"183", label:"SHRI RAM MATRIC HR SEC SCHOOL, MELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4288", district:"22", Block:"274", label:"ST JOSEPH'S MATRIC HR. SEC. SCHOOL, SALEM-6", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4289", district:"30", Block:"385", label:"St.XAVIER'S MS PERUMANAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4290", district:"14", Block:"161", label:"LE CHATELIER MATRIC HR SEC SCHOOL, CHINNACHOKKIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4291", district:"4", Block:"279", label:"SHREE VISHNU VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4292", district:"26", Block:"370", label:"SRI RAMAKRISHNA MATRIC SCHOOL, NANJANAD", Sch_type:"Un-aided", Category:"High School"},
    { key:"4293", district:"11", Block:"355", label:"MOTHER THERESA MATRIC HIGHER SECONDARY SCHOOL, MYLACODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4294", district:"11", Block:"124", label:"LUTH. MS VAZHUTHALAMPALLAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4295", district:"3", Block:"304", label:"SRI TARACHAND GALADA JAIN MAT", Sch_type:"Un-aided", Category:"High School"},
    { key:"4296", district:"33", Block:"338", label:"SRI ARAVINDAR MATRIC.SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4297", district:"21", Block:"21", label:"RAILWAY MIXED HS ARAKKONAM", Sch_type:"Central Govt", Category:"High School"},
    { key:"4298", district:"25", Block:"287", label:"PUMS -ETTIVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4299", district:"35", Block:"141", label:"PUMS, NEMMELI", Sch_type:"Government", Category:"Middle School"},
    { key:"4300", district:"4", Block:"302", label:"PUMS SENGATHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4301", district:"5", Block:"39", label:"PUMS KEEZHAMOONGILADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4302", district:"17", Block:"201", label:"FATHIMA RC MIDDLE SCHOOL NAMAKKAL(AIDED)", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4303", district:"33", Block:"260", label:"SRI RAMDAYAL KHEMKA VV, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4304", district:"3", Block:"240", label:"St. MARK's MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4305", district:"30", Block:"385", label:"THRESA MS PUSHPAVANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4306", district:"28", Block:"350", label:"SRI KAMAKSHI VIDYALAYA MAT HSS C.G.E COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4307", district:"25", Block:"344", label:"PUMS - THIRUMANGALAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4308", district:"7", Block:"219", label:"VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, AYAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4309", district:"11", Block:"341", label:"C.S.I MATRIC.HR.SEC.SCHOOL,KULASEKHARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4310", district:"30", Block:"174", label:"TDTAMS,NANCHANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4311", district:"27", Block:"55", label:"ST. ALPHONSE  MS -  UTHAMAPURAM - CUMBUM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4312", district:"34", Block:"256", label:"PUMS DEVANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4313", district:"27", Block:"40", label:"PUMS - AGAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4314", district:"33", Block:"260", label:"RUKMANI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL. ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4315", district:"21", Block:"359", label:"MOTHER MATRIC SCHOOL, KALAVAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4316", district:"4", Block:"279", label:"PUMS NEHRU NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"4317", district:"36", Block:"74", label:"PANCHAYAT UNION MIDDLE SCHOOL KOTTARAMADUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"4318", district:"13", Block:"311", label:"PUMS UNISENATHAM -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4319", district:"4", Block:"248", label:"PUMS,VAKKAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4320", district:"2", Block:"300", label:"HOLY FAMILY CONVENT MATRIC HIGHER SECONDARY SCHOOL, KEELKATTALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4321", district:"29", Block:"343", label:"YWCA MAT.HIGH SCHOOL ., ANNA NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4322", district:"11", Block:"124", label:"GOVT MIDDLE SCHOOL, DEVICODE", Sch_type:"Government", Category:"Middle School"},
    { key:"4323", district:"2", Block:"299", label:"Peniel Matric HSS, Pallikaranai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4324", district:"8", Block:"244", label:"VVIS SENIOR SECONDARY SCHOOL,KAMBULIYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4325", district:"30", Block:"385", label:"TDTA MS SAHAYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4326", district:"29", Block:"172", label:"YWCA MAT.,BIKSHANDARKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4327", district:"13", Block:"78", label:"PUMS KELAVARAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4328", district:"30", Block:"222", label:"RAHMANIA MHSS MELAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4329", district:"3", Block:"69", label:"NORTH MADRAS SOCIAL SERVICE MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4330", district:"3", Block:"368", label:"REX MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4331", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUNGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"4332", district:"27", Block:"55", label:"VOC MS -   KEELAGUDALUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4333", district:"25", Block:"144", label:"PUMS-AMMACHATRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4334", district:"30", Block:"92", label:"SA MS NAMBIYARADAIPPU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4335", district:"28", Block:"350", label:"RC MS THALAMUTHU NAGAR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4336", district:"14", Block:"328", label:"DEVASAKAYAM MATRIC HR SEC SCHOOL, PASUMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4337", district:"29", Block:"343", label:"HAPF PARAMAHAMSA MAT.HIGH SCHOOL,HAPP", Sch_type:"Un-aided", Category:"High School"},
    { key:"4338", district:"32", Block:"57", label:"ST AVILA MATRICULATION SCHOOL, DHARAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4339", district:"34", Block:"249", label:"PUMS PADAVEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4340", district:"6", Block:"77", label:"PUMS SENGALERI", Sch_type:"Government", Category:"Middle School"},
    { key:"4341", district:"24", Block:"307", label:"TDTA PRIMARY SCHOOL VALLAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4342", district:"31", Block:"362", label:"PUMS KOODAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"4343", district:"21", Block:"24", label:"ST.JOSEPH MATRIC HSS, VEPPOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4344", district:"3", Block:"270", label:"ARUMUGA NADAR GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"4345", district:"32", Block:"363", label:"KMCPUBLIC SCHOOL,,PERUMANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4346", district:"22", Block:"34", label:"KAILASH MAANSAROVAR HR. SEC. SCHOOL K.N.COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4347", district:"33", Block:"184", label:"ST.ANNE'S MATRIC HIGHER SECONDARY SCHOOL, MINJUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4348", district:"8", Block:"185", label:"AGASTYA ACADEMY CBSE VEERAPPAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4349", district:"10", Block:"146", label:"Masi MHSS, Iyappanthangal", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4350", district:"4", Block:"302", label:"PUMS M.GANAPATHYPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4351", district:"25", Block:"216", label:"ST.MARYS AIDED MS-ORATHANADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4352", district:"6", Block:"77", label:"PUMS VEPPANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4353", district:"22", Block:"413", label:"OUR LADY OF VELANKANNI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4354", district:"28", Block:"351", label:"CRESCENT MATRIC HR.SEC SCHOOL, CHIDAMBARANAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4355", district:"33", Block:"260", label:"PNECT KAMARAJ MATRIC SCHOOL, MADHAVARAM WARD 22", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4356", district:"32", Block:"371", label:"PUMS KILUVANKATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4357", district:"6", Block:"235", label:"PUMS PERIYATHOTTAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4358", district:"22", Block:"383", label:"SARASWATHI MATRIC HR. SEC. SCHOOL, VALAPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4359", district:"36", Block:"113", label:"SUNBEAM CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4360", district:"34", Block:"365", label:"ADWMS PALAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4361", district:"13", Block:"122", label:"PUMS KOWTHALAM TELUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"4362", district:"4", Block:"279", label:"CMS.NEELIKKONAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4363", district:"11", Block:"355", label:"ST. JOSEPH'S MIDDLE SCHOOL, KALKURICHY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4364", district:"30", Block:"385", label:"NALANDHA MATRIC HIGH SCHOOL, VALLIOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4365", district:"5", Block:"143", label:"AMS ANNAI SATHIYA", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4366", district:"22", Block:"274", label:"HOLY FLOWER MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4367", district:"22", Block:"309", label:"BHARATHI VIDHYALAYA MATRIC HR. SEC. SCHOOL, SIRUVACHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4368", district:"8", Block:"281", label:"ST. JAMES MATRIC HS SCHOOL, SATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4369", district:"24", Block:"288", label:"SPECTRUM MATRIC HIGHER SECONDARY SCHOOL KATHIRKANTHAM E-VILAKKU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4370", district:"30", Block:"385", label:"BHARATH MATRIC SCHOOL, THALAVAIPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4371", district:"7", Block:"213", label:"SPM OXFORD MATRICULATION HSS,KALANJIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4372", district:"11", Block:"341", label:"GMS. KANNANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4373", district:"6", Block:"235", label:"PUMS GOWRICHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4374", district:"4", Block:"247", label:"PUMS T.NALLIGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4375", district:"28", Block:"217", label:"PUMS MUPPILIVETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4376", district:"22", Block:"274", label:"BALA GURUKULAM MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4377", district:"7", Block:"207", label:"SHRI MEENAKSHI MATRIC SCHOOL,N.KOVILPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"4378", district:"36", Block:"74", label:"PANCHAYAT UNION MIDDLE SCHOOL SEEVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4379", district:"23", Block:"294", label:"OXFORD MATRIC HSS  T.PUDUR SIVAGANGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4380", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL BUNGALOWPUDUR-PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4381", district:"8", Block:"281", label:"ST. JOSEPH'S MATRICULATION HIGH SCHOOL, SATHYAMANGALAM.", Sch_type:"Un-aided", Category:"High School"},
    { key:"4382", district:"2", Block:"322", label:"BLESSINGS MATRIC HR.SEC.SCHOOL KEERAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4383", district:"34", Block:"44", label:"SAHAYAMADHA MATRIC HSS- CHENGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4384", district:"21", Block:"290", label:"SARASWATHI VELU MATRIC HSS, SHOLINGHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4385", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL V MAMANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4386", district:"13", Block:"78", label:"PUMS POONAPALLI -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4387", district:"33", Block:"260", label:"VELAMMAL VIDHYASHRAM, SURAPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4388", district:"33", Block:"252", label:"ST JOSEPH MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4389", district:"33", Block:"403", label:"SRI VENKATESWARA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4390", district:"13", Block:"78", label:"PUMS CHENNASANDIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4391", district:"31", Block:"362", label:"PUMS VINAYAGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4392", district:"6", Block:"198", label:"PUMS ANUMANTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4393", district:"2", Block:"299", label:"kalaimagal Vidhya Mandir MHSS  madipakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4394", district:"15", Block:"131", label:"PUMS NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4395", district:"17", Block:"66", label:"SRI VIVEKANANDHA VIDHYAALAYA MATRICULATION SCHOOL, ERUMAPATTI, NAMAKKAL DT -637021", Sch_type:"Un-aided", Category:"High School"},
    { key:"4396", district:"8", Block:"10", label:"PUMS MARAVANKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4397", district:"27", Block:"375", label:"HINDU MIDDLE SCHOOL - PALLAVARAYANPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4398", district:"11", Block:"355", label:"ST. THERESA'S MIDDLE SCHOOL KANDANVILAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4399", district:"13", Block:"311", label:"PUMS MEKALAGOWNUR -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4400", district:"12", Block:"315", label:"LORD'S PARK MATRIC. HR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4401", district:"12", Block:"88", label:"KAVERI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4402", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL- KALIYAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4403", district:"30", Block:"361", label:"IDEAL MATRICULATION SCHOOL, PETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4404", district:"12", Block:"140", label:"PUMS, METTANKINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4405", district:"12", Block:"140", label:"TANCEM MATRIC SCHOOL MAYANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4406", district:"24", Block:"6", label:"Harijan MS, Kankanankinaru", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4407", district:"31", Block:"5", label:"BRITE MATRIC SCHOOL, MADANANCHERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4408", district:"13", Block:"311", label:"PUMS ANNIYALAM -TAM-TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4409", district:"34", Block:"387", label:"PUMS ARIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4410", district:"11", Block:"355", label:"ST ANTONY'S MS VATTAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4411", district:"4", Block:"247", label:"MUTHUSAMY GOUNDER MATRICULATION HIGHER SECONDARY  SCHOOL R.GOPALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4412", district:"6", Block:"77", label:"KURINCHI MHSS, CHINNANKUPPAM, HARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4413", district:"34", Block:"249", label:"PUMS R.KUNNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4414", district:"29", Block:"171", label:"ST. VINCENT MATRIC HIGHER SECONDARY SCHOOL,  N. KUTTAPATTU VANNANKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4415", district:"1", Block:"26", label:"VENUS MATRICULATION  HIGH SCHOOL  ARIYALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4416", district:"6", Block:"77", label:"PUMS KOKKARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4417", district:"25", Block:"344", label:"AIDED  MIDDLE SCHOOL - NAGARASAMPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4418", district:"17", Block:"132", label:"HILLDALE MATRICULATION HIGHER SECONDARY SCHOOL, VASALURPATTI,KOLLIMALAI,NAMAKKAL DT -637411", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4419", district:"33", Block:"76", label:"VIVEKANANDA MATRIC HIGHER SECONDARY SCHOOL, THURAPPALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4420", district:"28", Block:"297", label:"PUMS PADMANABAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4421", district:"5", Block:"148", label:"PUMS ELLAPPANPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4422", district:"8", Block:"185", label:"LIONS M.HR.S.SCHOOL, PALANIGOUNDENVALASU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4423", district:"13", Block:"78", label:"PUMS HANUMANTHAPURAM -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4424", district:"33", Block:"260", label:"ST ANNS MAT HSS, MADHAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4425", district:"34", Block:"48", label:"PUMS-PAINKINAR", Sch_type:"Government", Category:"Middle School"},
    { key:"4426", district:"28", Block:"369", label:"TNDTA MS SEERKATCHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4427", district:"6", Block:"187", label:"PUMS GUNDALAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"4428", district:"3", Block:"240", label:"TERAPANTH JAIN VIDYALAYA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4429", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL  S. KOLLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4430", district:"33", Block:"252", label:"AVL MATRIC HIGHER SECONDARY SCHOOL,KATTUPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4431", district:"34", Block:"365", label:"PUMS SAVALPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4432", district:"24", Block:"91", label:"MELAPPALAYAM HINDU MIDDLE SCHOOL-MELAKADAYANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4433", district:"36", Block:"105", label:"PUMS PANGALATHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"4434", district:"28", Block:"297", label:"PUMS PUTHUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4435", district:"25", Block:"144", label:"PUMS-PULIYAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4436", district:"6", Block:"77", label:"PUMS VEERAPPANAIKKANAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4437", district:"13", Block:"311", label:"PUMS BELPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"4438", district:"21", Block:"359", label:"PUMS CHENGANAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4439", district:"25", Block:"228", label:"PUMS THIRUPALATHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4440", district:"3", Block:"195", label:"PS MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4441", district:"5", Block:"115", label:"PANCHAYAT UNION MIDDLE SCHOOL  SATHAVATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4442", district:"29", Block:"353", label:"DR G VISWANATHAM PILLAI MATRIC SCHOOL,THOTTIYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4443", district:"4", Block:"386", label:"PUMS SELLALIPARAI EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"4444", district:"32", Block:"363", label:"MMS SV COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"4445", district:"13", Block:"311", label:"PUMS MAVANATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"4446", district:"23", Block:"79", label:"PUMS A. MEIYANENDHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4447", district:"6", Block:"218", label:"SRI BHARATHI VIDYALAYA ACADEMY MHSS,  MARANDAHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4448", district:"34", Block:"256", label:"ST. ARULANANDAR MATRIC SCHOOL  PUDUPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4449", district:"12", Block:"142", label:"MOUNT GIRIS MATRIC HR SEC SCHOOL AYYARMALAI KARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4450", district:"6", Block:"187", label:"PUMS PUDUREDDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4451", district:"11", Block:"149", label:"ST PETERS MS KADIAPATTANAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4452", district:"17", Block:"200", label:"PUMS KONGALAMMAN KOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"4453", district:"6", Block:"77", label:"PUMS ALAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4454", district:"13", Block:"122", label:"PUMS ULLUKURUKKI URDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4455", district:"34", Block:"249", label:"SRI RAGAVENDIRA MATRIC HR. SEC. KASTHAMBADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4456", district:"13", Block:"376", label:"PUMS VELLAKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4457", district:"33", Block:"184", label:"PUMS,SENGAZHAURNEERMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4458", district:"24", Block:"89", label:"SRI SAILAPATHY MS, ALWARKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4459", district:"34", Block:"387", label:"PUMS KODANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4460", district:"34", Block:"14", label:"PUMS CHEYYATRAIVENDRAN", Sch_type:"Government", Category:"Middle School"},
    { key:"4461", district:"13", Block:"311", label:"PUMS BENNANGUR -URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4462", district:"27", Block:"31", label:"A.M.H.N.U. MAT HSS, AUNDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4463", district:"34", Block:"238", label:"RCM MIDDLE SCHOOL MOSAVADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4464", district:"13", Block:"376", label:"PUMS BADAPPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4465", district:"13", Block:"311", label:"PUMS CHOODASANDIRAM -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4466", district:"11", Block:"355", label:"CSI MATRICULATION SCHOOL, MOOLACHEL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4467", district:"19", Block:"255", label:"THE LITTLE ANGELS JAYARANI MATRICULATION HIGHER SECONDARY  SCHOOL, PUDUKKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4468", district:"34", Block:"125", label:"PUMS KADAMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4469", district:"6", Block:"109", label:"PUMS GUDDAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4470", district:"1", Block:"81", label:"PUMS-EDAIKATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"4471", district:"33", Block:"338", label:"GOVT. MODEL SCHOOL, TIRUR", Sch_type:"Government", Category:"High School"},
    { key:"4472", district:"5", Block:"39", label:"PUGMS PERUMATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4473", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL,  APPIRAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4474", district:"5", Block:"98", label:"PUMS PUDHUVIRUDHAGIRIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4475", district:"28", Block:"402", label:"TNDTA MS, VILATHIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4476", district:"24", Block:"388", label:"MPL MS, THAIKKA ST, PULIANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4477", district:"34", Block:"238", label:"PUMS BAGAVANTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4478", district:"4", Block:"107", label:"PUMS,KUTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4479", district:"25", Block:"144", label:"ST AGASTHINAR AMS KUMBAKONAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4480", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOTTAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4481", district:"23", Block:"79", label:"NALMEIPER MATRICULATION SCHOOL , VARUNTHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4482", district:"17", Block:"232", label:"PUMS NALLIYAMPALAYAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4483", district:"25", Block:"234", label:"MPLMS-MUSLIM THERU", Sch_type:"Government", Category:"Middle School"},
    { key:"4484", district:"25", Block:"313", label:"PUMS - SAKKARASAMANTHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4485", district:"20", Block:"86", label:"ANJANEYA MATRICULATION SCHOOL, KANDILAN", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4486", district:"34", Block:"249", label:"DMMS POLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4487", district:"6", Block:"235", label:"PUMS KODIHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4488", district:"6", Block:"198", label:"PUMS EARUPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4489", district:"11", Block:"149", label:"GMS KUNNANCADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4490", district:"34", Block:"125", label:"PUMS SO.NAMMIYANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4491", district:"32", Block:"154", label:"PUMS RUTHIRAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4492", district:"33", Block:"252", label:"THIRUVALLUVAR MID.SCH, NAMBI NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4493", district:"20", Block:"335", label:"CREATIVE MATRICULATION HIGHER SECONDARY SCHOOL, PULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4494", district:"30", Block:"92", label:"HINDU MS THIRUKURUNGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4495", district:"34", Block:"256", label:"PUMS JAPTHIKARIYANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4496", district:"13", Block:"311", label:"PUMS KEMPAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4497", district:"34", Block:"48", label:"PUMS VADATHANDALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4498", district:"13", Block:"311", label:"PUMS BENNANGUR -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4499", district:"14", Block:"160", label:"NADAR VIDHYASALA MIDDLE SCHOOL, SOUTH GATE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4500", district:"13", Block:"78", label:"PUMS MUGALUR -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4501", district:"13", Block:"311", label:"PUMS BELUR -TEL - ENG", Sch_type:"Government", Category:"Middle School"},
    { key:"4502", district:"35", Block:"173", label:"MMS MODEL, MANNARGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4503", district:"21", Block:"21", label:"PUMS GEDARIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4504", district:"12", Block:"142", label:"LITTLE FAIRIES MAT. SCHOOL, KULITHALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4505", district:"25", Block:"239", label:"KALAIMAGAL AIDED MIDDLE SCHOOL- THURAVIKKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4506", district:"34", Block:"125", label:"PUMS ARUMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4507", district:"27", Block:"194", label:"PUMS - PONNANPADUGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4508", district:"6", Block:"235", label:"PUMS DIGILODU", Sch_type:"Government", Category:"Middle School"},
    { key:"4509", district:"5", Block:"199", label:"PUMS MELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4510", district:"30", Block:"222", label:"CMS MARY ARDEN MS PALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4511", district:"36", Block:"12", label:"PUMS OTHIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4512", district:"13", Block:"311", label:"PUMS VEERASETTIYERI", Sch_type:"Government", Category:"Middle School"},
    { key:"4513", district:"30", Block:"385", label:"TDTA MIDDLE SCHOOL. THIRUMALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4514", district:"36", Block:"74", label:"PUMS SENGUNDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4515", district:"12", Block:"140", label:"PUMS, VADAKKUKALLUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"4516", district:"12", Block:"140", label:"PUMS, KEELADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4517", district:"25", Block:"314", label:"CMS-VANDIKARATHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"4518", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, KUNDUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4519", district:"13", Block:"122", label:"PUMS POONAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4520", district:"34", Block:"412", label:"PUMS ONNUPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4521", district:"32", Block:"363", label:"MMS ,Mettupalayam", Sch_type:"Government", Category:"Middle School"},
    { key:"4522", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, THIRUVAIMUDHALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4523", district:"24", Block:"6", label:"Sri Shanmuga MS, Melamaruthappapuram", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4524", district:"6", Block:"77", label:"PUMS VALLIMADURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4525", district:"4", Block:"52", label:"T E LC MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4526", district:"13", Block:"78", label:"PUMS KOTHAGONDAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4527", district:"5", Block:"39", label:"PUMS MELAMOONGILADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4528", district:"34", Block:"358", label:"PUMS USAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4529", district:"34", Block:"48", label:"PUMS MELNAGARAMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4530", district:"3", Block:"62", label:"KALAIPOONGA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4531", district:"38", Block:"407", label:"P.S.T.M.SEVENTH DAY ADVENTISTS MATRIC HSS VIRUTHUNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4532", district:"13", Block:"122", label:"PUMS GIRIYANAPALLI TELUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"4533", district:"8", Block:"244", label:"SHREE SWAMI VIVEKANANDHA MATRIC HIGHER SECONDARY SCHOOL, K.S.PALAYAM, PERUNDURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4534", district:"33", Block:"260", label:"S.S.S.M ETPS MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4535", district:"5", Block:"98", label:"PUMS U.ADHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4536", district:"3", Block:"270", label:"C.S.I. MIDDLE SCHOOL (MINT RAJAGOPAL)", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4537", district:"31", Block:"362", label:"PUMS IRUNAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"4538", district:"21", Block:"116", label:"PUMS-SIRUVALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4539", district:"21", Block:"359", label:"PUMS KANIYANUR PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4540", district:"5", Block:"98", label:"PUMS KO.MAVIDANTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4541", district:"4", Block:"248", label:"MMS,VADUGAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4542", district:"22", Block:"309", label:"SRI VENKATESWARA MATRIC SCHOOL, NATHAKKARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4543", district:"36", Block:"105", label:"PUMS BAGAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4544", district:"34", Block:"256", label:"PUMS ORAVANDAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4545", district:"13", Block:"122", label:"PUMS ZEEBI TAM / TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4546", district:"31", Block:"82", label:"PUMS, ELAGIRI VILLAGE", Sch_type:"Government", Category:"Middle School"},
    { key:"4547", district:"6", Block:"198", label:"PUMS THADANGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4548", district:"4", Block:"245", label:"PUMS ECHANARI", Sch_type:"Government", Category:"Middle School"},
    { key:"4549", district:"27", Block:"55", label:"ST.JOSHEP R.C.MS, HANUMANTHANPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4550", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL, RADHANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4551", district:"24", Block:"91", label:"PUMS,SUNDARESAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4552", district:"13", Block:"311", label:"PUMS BETTAPALLY(U)", Sch_type:"Government", Category:"Middle School"},
    { key:"4553", district:"27", Block:"194", label:"PUMS - MELAPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"4554", district:"21", Block:"408", label:"GANGADHARA AMS ARCOT", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4555", district:"31", Block:"103", label:"PUMS MAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4556", district:"6", Block:"198", label:"PUMS PAGALAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4557", district:"5", Block:"39", label:"ADWS THILLAINAYAGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4558", district:"23", Block:"333", label:"PUMS KATTAMANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4559", district:"33", Block:"260", label:"PANCHAYATH UNION MIDDLE SCHOOL , MURUGAMPEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4560", district:"35", Block:"205", label:"PUMS,KOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4561", district:"24", Block:"388", label:"PACKIATHAI MS, CHINTHAMANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4562", district:"28", Block:"217", label:"RAMANUJA HINDU MS OTTANATHAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4563", district:"28", Block:"350", label:"TNDTA MS K.RAMASAMIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4564", district:"21", Block:"21", label:"ST. XAVIER'S MATRIC SCHOOL, SOGANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4565", district:"25", Block:"216", label:"PUMS - KAKKARAIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4566", district:"33", Block:"260", label:"MUN. MIDDLE SCHOOL, MILK COLONY, MADHAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4567", district:"27", Block:"319", label:"BHARATHI MS - SEELAYAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4568", district:"13", Block:"311", label:"PUMS MARAGATTA DODDY", Sch_type:"Government", Category:"Middle School"},
    { key:"4569", district:"31", Block:"155", label:"PUMS EACHAMBUT", Sch_type:"Government", Category:"Middle School"},
    { key:"4570", district:"36", Block:"74", label:"PANCHAYAT UNION MIDDLE SCHOOL MUKKUNDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4571", district:"33", Block:"403", label:"ARUL MARY Matriculation School", Sch_type:"Un-aided", Category:"High School"},
    { key:"4572", district:"27", Block:"55", label:"VEENA VIDHYALAYA MATRIC SCHOOL, C.PUDUPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4573", district:"25", Block:"11", label:"PUMS - VADAKKUMANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4574", district:"31", Block:"5", label:"PUMS, MATHAKADAPPA", Sch_type:"Government", Category:"Middle School"},
    { key:"4575", district:"32", Block:"223", label:"UNIVERSAL MATRIC HR SEC SCHOOL, SEDAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4576", district:"32", Block:"250", label:"PUMS PALANIGOUNDEN PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4577", district:"6", Block:"235", label:"PUMS MANJARAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4578", district:"28", Block:"320", label:"SUDHANTHIRA VIDHYASALAI MIDDLE SCHOOL, MANAKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4579", district:"6", Block:"198", label:"PUMS MALLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4580", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, KAVALAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4581", district:"25", Block:"345", label:"PUMS-POYYUNDAR KUDIKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4582", district:"34", Block:"358", label:"PUMS KALASTHAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4583", district:"25", Block:"11", label:"PUMS - RARAMUTHIRAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4584", district:"27", Block:"40", label:"BUDDHAR MIDDLE SCHOOL - BODI ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4585", district:"25", Block:"144", label:"PUMS SOLANMALIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4586", district:"20", Block:"332", label:"N.M.S. S.V. MIDDLE SCHOOL, MAYAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4587", district:"27", Block:"241", label:"SRI MARKANDEYA WEAVERS MIDDLE SCHOOL - VADUGAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4588", district:"30", Block:"361", label:"Valliammai Mat. MS, Tvl", Sch_type:"Un-aided", Category:"High School"},
    { key:"4589", district:"32", Block:"378", label:"NANJUNDAPURAM RANGASAMY KARUPPANNA NADAR HIGH SCHOOL, KUNNATHUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"4590", district:"25", Block:"11", label:"PUMS - KOVILUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4591", district:"25", Block:"157", label:"PUMS - AVIKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4592", district:"34", Block:"249", label:"PUMS GANESAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4593", district:"34", Block:"125", label:"PUMS RAYAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4594", district:"6", Block:"235", label:"PUMS MANJARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4595", district:"13", Block:"311", label:"PUMS DODDABILIMUDIRAI -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4596", district:"25", Block:"157", label:"PUMS - MADUKKUR SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"4597", district:"33", Block:"261", label:"PUMS,CHETTIVARIPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4598", district:"34", Block:"47", label:"PUMS KOLAKARAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4599", district:"30", Block:"204", label:"CMS MS AMBALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4600", district:"25", Block:"344", label:"PUMS - KURICHIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4601", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - RAMAPURAM EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"4602", district:"34", Block:"387", label:"PUMS ULUNDAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4603", district:"35", Block:"141", label:"AMS, MANAVALANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4604", district:"36", Block:"113", label:"PUMS L.G.Pudur", Sch_type:"Government", Category:"Middle School"},
    { key:"4605", district:"5", Block:"54", label:"MUNICIPAL MIDDLE SCHOOL - REDDY CHATHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4606", district:"34", Block:"125", label:"PUMS SIRUNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4607", district:"2", Block:"300", label:"ALWIN MEMORIAL PUBLIC SC,SELAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4608", district:"21", Block:"24", label:"PUNCHAYAT UNION MIDDLE SCHOOL,  NARAYANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4609", district:"32", Block:"378", label:"GHSS, MORATTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"4610", district:"3", Block:"240", label:"ANAIKAR MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4611", district:"8", Block:"310", label:"JSS MATRICULATION SCHOOL THALAVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4612", district:"12", Block:"315", label:"ST.ANTONY'S MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4613", district:"13", Block:"311", label:"PUMS BINNAMANGALAM -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4614", district:"12", Block:"112", label:" JAIRAM VIDYA BHAVAN MATRIC.HR.SEC. SCHOOL,THIRUGAMPULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4615", district:"27", Block:"319", label:"KOTTUR MS - KOTTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4616", district:"24", Block:"288", label:"GOVERNMENT MIDDLE SCHOOL,KACHERI COMPOUND,SHENGOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4617", district:"3", Block:"62", label:"CRESCENT MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4618", district:"10", Block:"146", label:"Sivanthi MS Kundrathur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4619", district:"14", Block:"158", label:"LAKSHMI MATRIC HR SEC SCHOOL, VEERAPANCHAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4620", district:"11", Block:"355", label:"ISLAMIC MODEL MATRIC Hr. Sec. School  THIRUVITHANCODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4621", district:"29", Block:"366", label:"JESSIE MARTIC HSS, PUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4622", district:"17", Block:"200", label:"PUMS ONPATHAMPALIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4623", district:"2", Block:"300", label:"MONTFORT MATRICULATION HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4624", district:"12", Block:"315", label:"VELAMMAL VIDHYALAYA SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4625", district:"25", Block:"11", label:"PUMS - SOOLIAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4626", district:"5", Block:"170", label:"SRI GNANAGURU VIDYALAYA MATRICULATION HIGHER  SECONDARY SCHOOL,  TITTAGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4627", district:"34", Block:"312", label:"ANNAI VELANKANNI MATRIC MALLIKAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4628", district:"3", Block:"62", label:"TERAPANTH JAIN VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4629", district:"13", Block:"311", label:"PUMS ULIBANDA", Sch_type:"Government", Category:"Middle School"},
    { key:"4630", district:"11", Block:"355", label:"MALAVIYA VIDYA KENDRAM, KUMARAKOVIL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4631", district:"2", Block:"114", label:"ST ANN'S MHSS, MELAMAIYUR-603002", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4632", district:"10", Block:"146", label:"ST. ANNES MAT HSS MADANANDHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4633", district:"4", Block:"245", label:"ST.JOSEPH'S MIDDLE SCHOOL, PODANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4634", district:"1", Block:"26", label:"NIRMALA MATRICULATION SCHOOL,  ARIYALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4635", district:"8", Block:"202", label:"GWMS,E.RAYARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4636", district:"34", Block:"44", label:"PUMS UCHIMALAIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4637", district:"3", Block:"2", label:"CSI M SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4638", district:"33", Block:"252", label:" JAIGOPAL GARODIA VIVEK VID CBSE SCH ", Sch_type:"Un-aided", Category:"High School"},
    { key:"4639", district:"13", Block:"311", label:"PUMS AGALAKOTTA -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4640", district:"2", Block:"300", label:"Marian Mat.HSS, St.Thomas Mount", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4641", district:"5", Block:"115", label:"PUMS UDAIYARGUDI MIXED", Sch_type:"Government", Category:"Middle School"},
    { key:"4642", district:"29", Block:"343", label:"BHEL MATRIC HR SEC SCHOOL, KAILASAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4643", district:"13", Block:"35", label:"KINGSLEY GARDEN'S MHSS KANDIKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4644", district:"2", Block:"329", label:"Ramaniyam Sankara HSS,Toraipakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4645", district:"34", Block:"387", label:"AGASTHIYA VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL VANDAVSI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4646", district:"34", Block:"365", label:"SRI RAMANA MATRIC HS VENGIKAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4647", district:"3", Block:"237", label:"ST MOSES MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4648", district:"33", Block:"184", label:"BAALA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, MANALI NEW TOWN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4649", district:"12", Block:"112", label:"BHARANI PARK MATRIC.HR.SEC. SCHOOL, VENNAIMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4650", district:"11", Block:"182", label:"ST. GABRIALS R.C. MIDDLE SCHOOL, THICKURICHY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4651", district:"1", Block:"305", label:"PUMS-CHOLAMADEVI", Sch_type:"Government", Category:"Middle School"},
    { key:"4652", district:"4", Block:"107", label:"ROSE GARDEN MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4653", district:"17", Block:"85", label:"PUMS VADAKARAIATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4654", district:"5", Block:"199", label:"PUMS , KOTHATTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4655", district:"3", Block:"2", label:"CMS THIDEER NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"4656", district:"30", Block:"222", label:"CHRISTHU RAJA HSS PALAY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"4657", district:"2", Block:"299", label:"Christ The King Matriculation HSS, Medavakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4658", district:"3", Block:"195", label:"BALA SARAVANA VIDYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4659", district:"28", Block:"350", label:"ISHA VIDHYA MATRIC. HR.SEC. SCHOOL,  KOOTAMPULI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4660", district:"6", Block:"218", label:"PUMS GUMMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4661", district:"2", Block:"299", label:"SRI SANKARA MAT SCHOOL THURAIPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4662", district:"5", Block:"199", label:"PUMS KANDAPPANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"4663", district:"4", Block:"279", label:"PUMS VEERIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4664", district:"4", Block:"126", label:"PUMS NO.10 MUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4665", district:"12", Block:"112", label:"VIVEKANANDA  MATRIC HIGHER SECONDARY SCHOOL,PASUPATHIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4666", district:"35", Block:"173", label:"MMS RAJAMPALAYAM, MANNARGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4667", district:"14", Block:"328", label:"S.P.J MATRIC HR SEC SCHOOL, AVANIYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4668", district:"35", Block:"209", label:"UAMHSS OMD,MATRIC HR.SECONDARY SCHOOL, VAZHACHERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4669", district:"17", Block:"186", label:"APS RCMS PETTAPALAYAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4670", district:"4", Block:"242", label:"VIVEKAM MATRIC HR SEC SCHOOL NO.4.VEERAPANDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4671", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, PAVALAKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4672", district:"25", Block:"157", label:"PUMS - ANDAMI", Sch_type:"Government", Category:"Middle School"},
    { key:"4673", district:"34", Block:"249", label:"PUMS VASUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4674", district:"3", Block:"240", label:"PRINCESS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4675", district:"12", Block:"112", label:"GURUDEVAR MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4676", district:"38", Block:"110", label:"SUNDRAM MATRICULATION HR. SEC. SCHOOL KRISHNAPURAM AVIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4677", district:"12", Block:"112", label:"BHARANI VIDHYALAYA SCHOOL ,VENNAIMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4678", district:"12", Block:"315", label:"SRI SANKARA VIDHYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4679", district:"3", Block:"62", label:"ST ANTONYS ANGLO INDIAN HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"4680", district:"13", Block:"78", label:"PUMS ZEEMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4681", district:"13", Block:"398", label:"SRI SARASWATHI VID.MHSS, KUNDARAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4682", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL, SETHIDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4683", district:"29", Block:"366", label:"A.K.K.V. AARUNADU.  MAT. SCHOOL, TRICHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4684", district:"23", Block:"272", label:"SRI SUBBAIAH AMBALAM MATRIC HR SEC SCHOOL , KOTTAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4685", district:"5", Block:"148", label:"APS KRISHNANKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4686", district:"3", Block:"195", label:"MOUNT CARMEL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4687", district:"17", Block:"267", label:"ANANDA VIDYALAYA MATRICULATION  HIGHER  SECONDARY SCHOOL,  R .PATTANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4688", district:"13", Block:"398", label:"VAILANKANNI MHSS, VEPPANAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4689", district:"2", Block:"1", label:"ST. JOSEPH'S MATRIC HR. SEC. SCHOOL, ACHARAPAKKAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4690", district:"31", Block:"5", label:"PUMS, INDIRA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"4691", district:"31", Block:"103", label:"PUMS, KANDHILI", Sch_type:"Government", Category:"Middle School"},
    { key:"4692", district:"2", Block:"299", label:"Sacred Heart MHSS,Nanmangalam.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4693", district:"11", Block:"341", label:"N.V.K.S. HIGHER SEC. SCHOOL  ATTOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4694", district:"3", Block:"304", label:"LITTLE FLOWER MAT HSS ASHOK NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4695", district:"32", Block:"73", label:"PUMS,AMANTHAKADAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"4696", district:"2", Block:"299", label:"Sri S. B.S.C VIVEKANANDA VIDYALAYA, MUDICHUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4697", district:"32", Block:"392", label:"PUMS THENNANKARAIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4698", district:"30", Block:"262", label:"SACRED HEART MIDDLE SCHOOL T.KALLIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4699", district:"6", Block:"235", label:"PUMS BIKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4700", district:"15", Block:"151", label:"THIRUVAVADUTHURAI ADHEENAM AIDED MS, THIRUVAVADUTHURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4701", district:"3", Block:"2", label:"BETA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4702", district:"16", Block:"325", label:"PUMS-THIRUPPAYATHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4703", district:"2", Block:"300", label:"HEBA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4704", district:"38", Block:"110", label:"SARVA SEVA MATRICULATION SCHOOL, THONUGAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4705", district:"13", Block:"376", label:"PUMS NAPPIRAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4706", district:"16", Block:"325", label:"PUMS-KANGALANCHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"4707", district:"3", Block:"2", label:"ECI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4708", district:"32", Block:"250", label:"PUMS KULLAMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4709", district:"35", Block:"173", label:"PUMS,EATHAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4710", district:"3", Block:"195", label:"AKSHAYAH MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4711", district:"2", Block:"300", label:"HOLY QUEEN MATRIC. HR. SEC. SCHOOL, NAGALKENI, CHROMPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4712", district:"29", Block:"317", label:"Rabindra Bharathi Matric School, THATHIENGARPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"4713", district:"2", Block:"300", label:"SHIKSHAA MATRIC HR. SEC. SCHOOL,  HASTHINAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4714", district:"12", Block:"315", label:"SRI MEENATCHI MATRIC SCHOOL,KARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4715", district:"3", Block:"259", label:"MES RAZEENA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4716", district:"5", Block:"143", label:"MPLMS MANALANE", Sch_type:"Government", Category:"Middle School"},
    { key:"4717", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUSTAQKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"4718", district:"32", Block:"104", label:"JAIRUPAA MATRICULATION SCHOOL, THOTTIYAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4719", district:"24", Block:"91", label:"USP MATRIC  HR SEC SCHOOL,KODIKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4720", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL , VALLIMATHURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4721", district:"24", Block:"6", label:"TDTA MS  ALWANTHULUKKAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4722", district:"30", Block:"46", label:"HINDU MIDDLE SCHOOL KARUKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4723", district:"13", Block:"311", label:"PUMS CHINNAUBBANUR -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"4724", district:"6", Block:"235", label:"PUMS VATTUVANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4725", district:"12", Block:"112", label:"TNPL PUBLIC SCHOOL,KAKITHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4726", district:"20", Block:"188", label:"SARAVANA MATRICULATION HIGH SCHOOL, KAKKOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4727", district:"15", Block:"131", label:"PUMS KEERANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4728", district:"3", Block:"195", label:"MONTFORT ACADEMY MAT SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4729", district:"36", Block:"394", label:"SAVIO MATRICULATION  SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4730", district:"31", Block:"103", label:"PUMS, PARADASIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4731", district:"6", Block:"77", label:"PUMS G PAAPPINAICKANVALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4732", district:"25", Block:"344", label:"RAMAKRISHNA AIDED  MIDDLE SCHOOL - NACHIYARKOIL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4733", district:"4", Block:"302", label:"PUMS VENKITTAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4734", district:"3", Block:"237", label:"BETHEL MAT SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4735", district:"11", Block:"354", label:"ZION JEBA THOTTAM MATRICULATION SCHOOL-ESANTHIMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4736", district:"13", Block:"78", label:"PUMS HOSUR -TEL&KAN", Sch_type:"Government", Category:"Middle School"},
    { key:"4737", district:"28", Block:"369", label:"RMV MIDDLE SCHOOL, SIRUNADARKUDIERUPPU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4738", district:"15", Block:"151", label:"PANCHAYAT UNION MIDDLE SCHOOL   KONERIRAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4739", district:"13", Block:"117", label:"ROYAL MATRIC HR SEC SCHOOL, ARS NAGAR,KAVERIPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4740", district:"25", Block:"314", label:"INDIAN MATRIC HIGH SCHOOL - ESWARI NAGAR-THANJAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4741", district:"11", Block:"124", label:"KALLADAI SHRI KRISHNA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4742", district:"14", Block:"303", label:"K.K.G MATRICULATION  HIGHER  SECONDARY  SCHOOL, P.THOTTIAPATTI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4743", district:"25", Block:"157", label:"PUMS - RAMAMBALPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4744", district:"19", Block:"251", label:"LIONS MATRICULATION HIGHER SECONDARY SCHOOL, PONNAMARAVATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4745", district:"12", Block:"315", label:"GURU VIDHYALAYA MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4746", district:"25", Block:"313", label:"PUMS - THENPERAMPOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4747", district:"35", Block:"173", label:"UAMHS INDIA INTERNATIONAL MATRIC, KOOTHANALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4748", district:"17", Block:"232", label:"VIB MATRICULATION SCHOOL PEECHAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4749", district:"5", Block:"148", label:"THIRUMURUGAN MIDDLE SCHOOL., V.PUDUKUPPAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4750", district:"1", Block:"346", label:"PUMS SATHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4751", district:"2", Block:"299", label:"ST ANTONY'S MATRIC HR. SEC. SCHOOL, KOTTIVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4752", district:"11", Block:"191", label:"GOVERNMENT MIDDLE SCHOOL  MUKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4753", district:"22", Block:"246", label:"GNANADEEPAM MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4754", district:"2", Block:"114", label:"RKN GYAN JOTHI PUBLIC  SCHOOL, ANUMANTHA PUTHERI.-603002.", Sch_type:"Un-aided", Category:"High School"},
    { key:"4755", district:"31", Block:"155", label:"AS-SHUKOOR MATRIC SCHOOL, AMBUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4756", district:"2", Block:"114", label:"Neelan Matric Hr Sec School Guduvancheri-603202", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4757", district:"29", Block:"343", label:"St. Joseph Matriculation Higher Secondary School, VOC Nagar, Thuvakudimalai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4758", district:"28", Block:"351", label:"KSHATRIYA VIDHYASALA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4759", district:"25", Block:"234", label:"MPLMS- THANGAVEL NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"4760", district:"8", Block:"356", label:"MONTFORT SCHOOL, D.G.PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4761", district:"5", Block:"98", label:"SAMI VIVEKANANTHA MATRICULATION SCHOOL-SEPLANATHAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4762", district:"31", Block:"155", label:"MMS BETHELEHEM", Sch_type:"Government", Category:"Middle School"},
    { key:"4763", district:"5", Block:"199", label:"PUMS SEVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4764", district:"36", Block:"394", label:"AMS R.C.M UTHARIAMADHA", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4765", district:"4", Block:"52", label:"P R Sidha Naidu Memorial Matriculation Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4766", district:"5", Block:"115", label:"AMS VEERANANDAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4767", district:"21", Block:"408", label:"MMS KARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4768", district:"36", Block:"243", label:"ADWMS MASIGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4769", district:"23", Block:"79", label:"St.MARY'S MIDDLE SCHOOL  SOORANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4770", district:"34", Block:"125", label:"ADWMS PANNIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4771", district:"11", Block:"3", label:"DVD MATRIC HIGH SCHOOL-NAGERCOIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4772", district:"25", Block:"228", label:"PUMS - PONPETHI", Sch_type:"Government", Category:"Middle School"},
    { key:"4773", district:"6", Block:"109", label:"PUMS B GETTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4774", district:"3", Block:"368", label:"SREE VENKATESHWARAR MATRIC. HR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4775", district:"4", Block:"242", label:"BISHOP FRANCIS MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4776", district:"28", Block:"8", label:"GRAMA MS  VELLAKOIL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4777", district:"13", Block:"122", label:"PUMS THOTTIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4778", district:"22", Block:"215", label:"KALAIMAGAL MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4779", district:"16", Block:"123", label:"PUMS RADHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4780", district:"7", Block:"29", label:"PRISM MATRIC HIGHER SECONDARY SCHOOL, AATHILAKSHMIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4781", district:"6", Block:"77", label:"PUMS MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4782", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL , KATHANENDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4783", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, KILLIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4784", district:"25", Block:"228", label:"CRESCENT MATRIC HIGHER SECONDARY SCHOOL -PANDARAVADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4785", district:"7", Block:"275", label:"NEHRUJI MAT SCHOOL,THIRUMALAIKENI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4786", district:"21", Block:"290", label:"SKMS JAMBUKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4787", district:"12", Block:"112", label:"SRI KRISHNA MATRIC.SCHOOL,VELAYUTHAMPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4788", district:"11", Block:"149", label:"LUTHERAN MIDDLE SCHOOL SARALVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4789", district:"34", Block:"249", label:"PUMS KANNIGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4790", district:"16", Block:"389", label:"ELAKKUVANAR AMS,VOIMEDU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4791", district:"2", Block:"114", label:"JRK MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4792", district:"24", Block:"307", label:"MPL,13th WARD MS,TENKASI", Sch_type:"Government", Category:"Middle School"},
    { key:"4793", district:"4", Block:"107", label:"PUMS,THERAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4794", district:"29", Block:"152", label:"LALGUDI LIONS MATRICULATION SCHOOL,LALGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4795", district:"11", Block:"182", label:"ST.JOSEPH'S M S.C.M.S KULAPPARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4796", district:"9", Block:"95", label:"RR CAMBRIDGE HIGHER SECONDARY SCHOOL NEELAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4797", district:"33", Block:"252", label:"INFANT JESUS MATRIC HR SEC SCHOOL, THANDURAI, PATTABIRAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4798", district:"29", Block:"366", label:"KINGSLEY MAT.SCHOOL, TRICHY", Sch_type:"Un-aided", Category:"High School"},
    { key:"4799", district:"30", Block:"262", label:"HEBRON MATRIC HSS .KOODANKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4800", district:"4", Block:"242", label:"PUMS THOPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4801", district:"3", Block:"368", label:"M.O.P VAISHNAV MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4802", district:"2", Block:"299", label:"Sri Selva Vignesh, Pozhichalur", Sch_type:"Un-aided", Category:"High School"},
    { key:"4803", district:"29", Block:"343", label:"CHELLAMMAL MAT.HSS CRAWFORD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4804", district:"33", Block:"184", label:"PUMS, KATTAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4805", district:"11", Block:"191", label:"GOVERNMENT MIDDLE SCHOOL  MUNCHIRAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4806", district:"4", Block:"301", label:"PUMS POORANDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4807", district:"1", Block:"26", label:"MONTFORT MATRICULATION  HIGHER SECONDARY SCHOOL  ARIYALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4808", district:"8", Block:"281", label:"SRT UNIVERSAL MATRIC HIGHER SECONDARY SCHOOL, KONAMOOLAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4809", district:"2", Block:"299", label:"St John's  Matriculation  Higher  Secondary  School  ,Perungalathur.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4810", district:"28", Block:"369", label:"PUMS, NAINAPILLAI ST", Sch_type:"Government", Category:"Middle School"},
    { key:"4811", district:"10", Block:"101", label:"Annie Besant MS, Kanchipuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"4812", district:"18", Block:"399", label:"SWAMI VIVEKANANDA MATRIC HR. SEC. SCHOOL, ARUMBAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4813", district:"3", Block:"240", label:"KR MONTFORD MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4814", district:"36", Block:"105", label:"PUMS ADUKKAMBARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4815", district:"36", Block:"393", label:"HOLY CROSS HSS FOR THE HEARING IMPAIRED -SATHUVACHARI,VELLORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4816", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, URAIKINARU", Sch_type:"Government", Category:"Middle School"},
    { key:"4817", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, ORIVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4818", district:"25", Block:"344", label:" THIAGARAJAR AIDED  MIDDLE SCHOOL - SANNAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4819", district:"27", Block:"31", label:"VAILANKANNI MATHA MATRIC HR SEC SCHOOL JAKKAMPATTI AUNDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4820", district:"32", Block:"363", label:"BHARATHI VIKAS MAT SCHOOL,", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4821", district:"28", Block:"280", label:"TNDTA MS,SATHYANAGARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4822", district:"29", Block:"343", label:"St.ANTHONY'S MAT HIGH SCHOOL ,RENGA NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4823", district:"19", Block:"67", label:"ARULMARY MAT.SCHOOL, GANDARVAKKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4824", district:"1", Block:"81", label:"PUMS-VANAVANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4825", district:"13", Block:"376", label:"ADHIYAMAN MHSS UTHANGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4826", district:"34", Block:"256", label:"PUMS-PERIYERI", Sch_type:"Government", Category:"Middle School"},
    { key:"4827", district:"15", Block:"151", label:"PANCHAYAT UNION MIDDLE SCHOOL   AASIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4828", district:"29", Block:"357", label:"RAJ VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL,THURAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4829", district:"20", Block:"197", label:"HAMEETHIA MIDDLE SCHOOL, GANGAIKONDAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4830", district:"36", Block:"393", label:"SPARK MATRIC HSS, THIRUMALAIKODI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4831", district:"10", Block:"296", label:"St.Mary's MHSS Sriperumbudur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4832", district:"25", Block:"336", label:"ADW MS-VENKADASAMUDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4833", district:"32", Block:"250", label:"KINGSBERRY  MATRICULATION HR SEC SCHOOL, V.KALLIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4834", district:"6", Block:"198", label:"PUMS KONANGIHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4835", district:"25", Block:"216", label:" PUMS- NEDUVAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4836", district:"19", Block:"22", label:"MAEIL MATRICULATION SCHOOL,ARANTHANGI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4837", district:"11", Block:"3", label:"CSI MAT.HSS ,JOSUWA STREET -NAGERCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4838", district:"4", Block:"302", label:"PUMS KURUMBAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4839", district:"10", Block:"101", label:"Bharathidasan MHSS, Orikkai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4840", district:"15", Block:"131", label:"PUMS THIRUMAYILADI", Sch_type:"Government", Category:"Middle School"},
    { key:"4841", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL ODIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4842", district:"15", Block:"151", label:"PUMS NACHINARGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4843", district:"5", Block:"39", label:"PUMS NATHAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4844", district:"32", Block:"154", label:"PUMS,THANTHONI", Sch_type:"Government", Category:"Middle School"},
    { key:"4845", district:"22", Block:"273", label:"GLAZE BROOKE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4846", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, ALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4847", district:"25", Block:"239", label:"PUMS- PONNANKANNIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4848", district:"11", Block:"124", label:"C.S.I HACKER MATRICULATION SCHOOL, PAYANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4849", district:"29", Block:"176", label:"ST.ANTONY'S MATRIC SCHOOL, THUVARAKURCHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4850", district:"14", Block:"160", label:"PALANIAPPA MIDDLE SCHOOL,  CMR ROAD, MADURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4851", district:"15", Block:"151", label:"PUMS KANCHIVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4852", district:"2", Block:"114", label:"ST JOHN'S MATRIC. HR.SEC.SCHOOL, NANDHIVARAM, GUDUVANCHERI-603202", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4853", district:"30", Block:"9", label:"ST. MARY'S MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4854", district:"36", Block:"74", label:"PUMS K V PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4855", district:"12", Block:"23", label:"OXFORD MATRIC SCHOOL,PALLAPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"4856", district:"1", Block:"346", label:"PUMS KALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4857", district:"28", Block:"217", label:"LITTLE FLOWER N&P AKKAYANAYAKKANPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4858", district:"10", Block:"146", label:"Little Flower MHSS Kundrathur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4859", district:"22", Block:"163", label:"SRI AMMAN MATRIC HR. SEC. SCHOOL, VAIKUNTHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4860", district:"2", Block:"299", label:"MARYA NIVAS MHSS, POZHICHALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4861", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, PONTHAMPULI", Sch_type:"Government", Category:"Middle School"},
    { key:"4862", district:"7", Block:"29", label:"CHERAN VIDHYALAYA  MATRIC HIGHER SECONDARY SCHOOL, KALIKKAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4863", district:"4", Block:"301", label:"VENKITARAJ MATRICULATION SCHOOL,SULTHANPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"4864", district:"11", Block:"149", label:"NAVAJOTHI MATRIC HSS MONDAIKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4865", district:"17", Block:"200", label:"KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL, NAMAGIRIPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4866", district:"18", Block:"236", label:"GOLDEN GATES MATRIC HIGHER SECONDARY SCHOOL PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4867", district:"36", Block:"394", label:"AUMS URDU GIRLS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4868", district:"16", Block:"308", label:"PUMS THALAINAYAR AGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4869", district:"25", Block:"144", label:"MADHA DIFFERENTLY ABLED HR SEC SCHOOL-SAKKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4870", district:"7", Block:"275", label:"VICTORY MATRIC SCHOOL, KAMBILIAMPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"4871", district:"5", Block:"143", label:"VENUS MATRICULATION HIGHER SECONDARY SCHOOL,THILLAI NAGAR,CHIDAMBARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4872", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  ARUTHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4873", district:"14", Block:"158", label:"NOYES MATRIC HR SEC SCHOOL,NARIMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4874", district:"29", Block:"343", label:"St.George Montessori Higher Secondary School,Anbil Nagar,Trichy", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4875", district:"17", Block:"224", label:"PUMS VEERAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4876", district:"2", Block:"329", label:"BUVANA KRISHNAN MAT.HR.SEC.SCHOOL,KELAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4877", district:"7", Block:"60", label:"GREEN PARK MATRICULATION SCHOOL, BEGAMPUR, DINDIGUL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4878", district:"2", Block:"299", label:"EBENEZER MATRIC HIGH SCHOOL, NEW PERUNGALATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4879", district:"18", Block:"400", label:"ST.ANDREWS MATRICULATION HIGHER SECONDARY SCHOOL THIRUMANDURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4880", district:"11", Block:"182", label:"GOVT M V MIDDLE S MARTHANDAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4881", district:"5", Block:"98", label:"PUMS A. VALLIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4882", district:"17", Block:"165", label:"PUMS THUTHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4883", district:"5", Block:"115", label:"PANCHAYAT UNION MIDDLE SCHOOL EYYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4884", district:"12", Block:"112", label:"KVBOA MATRIC. HR.SEC. SCHOOL, KARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4885", district:"6", Block:"235", label:"PUMS HOGENAKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"4886", district:"33", Block:"337", label:"PUMS, THOZHUDHAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4887", district:"15", Block:"151", label:"PUMS PORUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4888", district:"12", Block:"112", label:"P.U.MIDDLE SCHOOL, NOCHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4889", district:"11", Block:"191", label:"GMS CHATHENCODE", Sch_type:"Government", Category:"Middle School"},
    { key:"4890", district:"13", Block:"398", label:"PUMS EGUDATHAMPALLI URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4891", district:"23", Block:"79", label:"PUMS KANNAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4892", district:"6", Block:"218", label:"PUMS GENGANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4893", district:"22", Block:"68", label:"BHARATHI MATRIC HR. SEC. SCHOOL, THAMMAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4894", district:"16", Block:"325", label:"PUMS-VADAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4895", district:"15", Block:"151", label:"PANCHAYAT UNION MIDDLE SCHOOL   KAPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4896", district:"25", Block:"234", label:"TELC MS-PATTUKKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4897", district:"2", Block:"300", label:"ST. MARY MATRIC HR SEC SCHOOL, ZAMIN PALLAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4898", district:"34", Block:"365", label:"ST. ANTONY MATRIC PAVITHIRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4899", district:"34", Block:"27", label:"PUMS ADAYAPULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4900", district:"2", Block:"300", label:"HILTON MHSS, PALAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4901", district:"3", Block:"304", label:"LOYOLA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4902", district:"2", Block:"300", label:"SANTHOSH VID MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4903", district:"25", Block:"228", label:"ST. MARY'S MATRICULATION SCHOOL -PASUPATHIKOIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4904", district:"5", Block:"406", label:"MUNICIPAL MIDDLE SCHOOL SOUTH FORT STREET-VRIDHACHALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4905", district:"16", Block:"389", label:"AMS-PUSHPAVANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4906", district:"29", Block:"353", label:"INFANT JESUS MATRICULATION SCHOOL, KATTUPUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"4907", district:"7", Block:"212", label:"HNUPR MATRICULATION HR. SEC SCHOOL, NILAKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4908", district:"3", Block:"304", label:"SRI CHILD FRUIT MATRIC", Sch_type:"Un-aided", Category:"High School"},
    { key:"4909", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL MERPANAIKKADU EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"4910", district:"3", Block:"69", label:"Angels Babyland Matriculation Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4911", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARIKIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4912", district:"19", Block:"339", label:"MODERN MATRIC HR.SEC. SCHOOL ,ALANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4913", district:"29", Block:"171", label:"CARE INTERNATIONAL SCHOOL (CBSE), THAYANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4914", district:"13", Block:"122", label:"PUMS ODAYANDAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4915", district:"35", Block:"382", label:"PUMS MANICKAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4916", district:"15", Block:"131", label:"SRI  MEENAKSHI AMMAL AMS CHIDAMBARANATHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4917", district:"3", Block:"237", label:"DONBOSCO MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4918", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEEZHANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4919", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL THEKKIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"4920", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL VADAKARAITHAZHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4921", district:"24", Block:"6", label:"PUMS, Annamalaiputhur", Sch_type:"Government", Category:"Middle School"},
    { key:"4922", district:"13", Block:"139", label:"TCR MATRIC  SCHOOL RAYAKOTTAI ROAD KRISHNAGIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4923", district:"5", Block:"227", label:" MUNICIPAL  MIDDLE SCHOOL  GANDHIROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"4924", district:"28", Block:"402", label:"ST.PETER MS, SUBRAMANIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4925", district:"21", Block:"408", label:"AMS SRI KRISHNA VIDHYALAYA RANIPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4926", district:"23", Block:"79", label:"PUMS THAYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4927", district:"12", Block:"112", label:"P.U.MID.SCHOOL,N.PUGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4928", district:"4", Block:"52", label:"SHRI NEHRU VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4929", district:"2", Block:"300", label:"Vyasa Vid.MHSS,Puzhuthivakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4930", district:"17", Block:"360", label:"PANCHAYAT UNION MIDDLE SCHOOL, V PETHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4931", district:"13", Block:"398", label:"PUMS JINKALURE  URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4932", district:"2", Block:"329", label:"EVERGREEN MATRICULATION HIGHER S ECONDARY SCHOOL, THIRUPORUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4933", district:"17", Block:"360", label:"PANCHAYAT UNION MIDDLE SCHOOL, UPPILIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4934", district:"17", Block:"267", label:"PU MIDDLE SCHOOL,85R.KOMARAPALAYAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"4935", district:"2", Block:"300", label:"St.Sebastian  MHSS Pallavaram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4936", district:"5", Block:"39", label:"AMS AMBAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4937", district:"17", Block:"360", label:"KSR MATRIC HIGHER SECONDARY SCHOOL, THOKKAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4938", district:"15", Block:"293", label:"MMS OOZHIYAKARANTHOPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"4939", district:"23", Block:"272", label:"T.V.MID.SCH, NEMATHANPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4940", district:"24", Block:"6", label:"PUMS, Muthammalpuram", Sch_type:"Government", Category:"Middle School"},
    { key:"4941", district:"2", Block:"300", label:"SRI SANKARA VIDYALAYA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4942", district:"11", Block:"355", label:"SREERAM MATRIC HIGHER SECONDARY SCHOOL,PARASERI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4943", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL ANTHILI", Sch_type:"Government", Category:"Middle School"},
    { key:"4944", district:"26", Block:"53", label:"HOLY ANGELS MATRIC", Sch_type:"Un-aided", Category:"High School"},
    { key:"4945", district:"12", Block:"88", label:"SRI LAKSHMI MAT.SCHOOL, THARAGAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"4946", district:"14", Block:"183", label:"SARASWATHI VIDHAYALAYA MATRIC HIGH SCHOOL, M SUBRAMANIAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4947", district:"4", Block:"279", label:"ST.XAVIER'S MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4948", district:"13", Block:"122", label:"PUMS GULLATTI TELUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"4949", district:"5", Block:"143", label:"PUMS VALLAMPADUGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4950", district:"29", Block:"317", label:"SB MAT. SCH.SORIAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4951", district:"2", Block:"299", label:"ANNAI VIOLET MATRICULATION HIGHER SECONDARY SCHOOL CHITLAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4952", district:"5", Block:"39", label:"PUMS KATHAZHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4953", district:"3", Block:"259", label:"PONNAMMAL MEMORIAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"4954", district:"8", Block:"128", label:"SHREE VALLI MARIC  HSS,ICHIPPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4955", district:"35", Block:"205", label:"PUMS,KURUNGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4956", district:"36", Block:"74", label:"PUMS NATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4957", district:"14", Block:"283", label:"P.U.M.S, UTHAPURAM - II", Sch_type:"Government", Category:"Middle School"},
    { key:"4958", district:"21", Block:"21", label:"SELVAM MATRIC HSS, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4959", district:"28", Block:"351", label:"APC VEERABAHU MATRIC HR.SEC. SCHOOL, MILLERPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4960", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUMMUDISATHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"4961", district:"7", Block:"219", label:"CRESCENT MATRICULATION HR.SEC.SCHOOL,NEIKARAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4962", district:"12", Block:"112", label:"P.U.MID.SCHOOL,MOOLIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4963", district:"36", Block:"12", label:"PUMS  VASANTHANADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4964", district:"2", Block:"299", label:"MANUELMONY MATRIC. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4965", district:"14", Block:"158", label:"CROWN MATRIC HR SEC SCHOOL, GORIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4966", district:"29", Block:"343", label:"INFANT JESUS MAT HIGH SCHOOL, PONMALAIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"4967", district:"7", Block:"207", label:"KALLALAGAR MATRICULATION SCHOOL,AYYANARPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4968", district:"15", Block:"284", label:"PUMS ALAVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"4969", district:"5", Block:"54", label:"BHAVANI AMMAL MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL, K.N.PETTAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4970", district:"3", Block:"62", label:"MADRAS CHRISTIAN COLLEGE  MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4971", district:"10", Block:"410", label:"M C A MATRIC HR SEC SCHOOL, PALAYASEEVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4972", district:"5", Block:"233", label:"PUMS GUMATHPALLI MIDDLE", Sch_type:"Government", Category:"Middle School"},
    { key:"4973", district:"15", Block:"151", label:"PUMS PAZHAYAKUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4974", district:"13", Block:"376", label:"PUMS ODDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4975", district:"6", Block:"235", label:"PUMS EAMANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4976", district:"13", Block:"122", label:"PUMS VARAGANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"4977", district:"25", Block:"144", label:"SRI KALAIMAGAL KENDRA MATRIC HIGHER SECONDARY SCHOOL KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4978", district:"4", Block:"279", label:"SREE DHARMASASTHA MAT. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4979", district:"8", Block:"71", label:"PANCHAYAT UNION MIDDLE SCHOOL, NALLAGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4980", district:"11", Block:"149", label:"St. MARY'S MATRIC HIGHER SECONDARY SCHOOL, SIMONCOLONY.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4981", district:"11", Block:"355", label:"VICTORY PUBLIC SCHOOL CBSE ALANCODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4982", district:"2", Block:"300", label:"ANNAI VELANKANI MHSS, PAMMAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4983", district:"30", Block:"262", label:"KAMARAJ MIDDLE SCHOOL T.KALLIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"4984", district:"33", Block:"184", label:"ADW MID SCHOOL, PONNERI", Sch_type:"Government", Category:"Middle School"},
    { key:"4985", district:"35", Block:"193", label:"PUMS, AARIYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"4986", district:"27", Block:"55", label:"MPL MS - SUNGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"4987", district:"13", Block:"398", label:"PUMS VEPPANAPALLI - URDU", Sch_type:"Government", Category:"Middle School"},
    { key:"4988", district:"2", Block:"300", label:"SRI SANKARAVID.MHSS, PAMMAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4989", district:"15", Block:"151", label:"PANCHAYAT UNION MIDDLE SCHOOL   ALANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4990", district:"11", Block:"182", label:"R C M S MELPALAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"4991", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KOTTAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"4992", district:"16", Block:"123", label:"PUMS KERALANTHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"4993", district:"15", Block:"131", label:"PUMS SANTHAPADUGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"4994", district:"31", Block:"103", label:"PUMS MERKATHIYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"4995", district:"15", Block:"151", label:"VELAMMA KALASHETHRA  MATRICULATION SCHOOL  KUTHALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"4996", district:"4", Block:"242", label:"INFANT JESUS CONVENT HIGHER SECONDARY SCHOOL FOR HEARING IMPAIRED", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4997", district:"17", Block:"66", label:"PUMS KASTHURIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"4998", district:"7", Block:"220", label:"ARULMIGU PALANIANDAVAR MATRICULATION HSS,PALANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"4999", district:"2", Block:"329", label:"AARUPADAI VEEDU MATRICULATION HIGHER SECONDARY SCHOOL, THIRUPORUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5000", district:"36", Block:"243", label:"PUMS MEL KOTHAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5001", district:"4", Block:"52", label:"C M S WEDDERBURN PET", Sch_type:"Government", Category:"Middle School"},
    { key:"5002", district:"13", Block:"35", label:"KAMESHVER VIDYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5003", district:"30", Block:"204", label:"PUMS CHINTHAMANI", Sch_type:"Government", Category:"Middle School"},
    { key:"5004", district:"2", Block:"329", label:"ST. MARY'S MHSS, KELAMBAKKAM-603103", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5005", district:"14", Block:"161", label:"ST.MICHAEL MATRIC HR SEC SCHOOL, P&T NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5006", district:"29", Block:"366", label:"Hilda Thomas YWCA Matriculation Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5007", district:"13", Block:"376", label:"PUMS VEPPALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5008", district:"34", Block:"44", label:"PUMS PINJUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5009", district:"13", Block:"122", label:"PUMS GEDDAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5010", district:"5", Block:"227", label:"MUNICIPAL MIDDLE  SCHOOL SEERANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5011", district:"6", Block:"235", label:"PUMS ELUMALMANDHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5012", district:"5", Block:"115", label:"VIVEKANANDAN AIDED MIDDLE SCHOOL, MELAKADAMBUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5013", district:"14", Block:"158", label:"P.U.M.S.MEENAKSHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5014", district:"16", Block:"123", label:"PUMS VADAKKALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5015", district:"3", Block:"237", label:"ST JOHNS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5016", district:"11", Block:"191", label:"GOVT MS POOTHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5017", district:"29", Block:"366", label:"R.DAYANIDHI MEMORIAL VIDYASALA MATRICULATION SCHOOL,KULUMANI ROAD", Sch_type:"Un-aided", Category:"High School"},
    { key:"5018", district:"31", Block:"103", label:"PUMS RAVUTHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5019", district:"16", Block:"389", label:"RV SHARMA AMS, THETHAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5020", district:"4", Block:"247", label:"KESHAV VIDHYA MANDIR MHSS POLLACH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5021", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL- C.MELAVANNIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5022", district:"33", Block:"260", label:"AYISHA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5023", district:"11", Block:"3", label:"ST. TRESA'S MATRIC  SCHOOL  KOTTAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5024", district:"34", Block:"47", label:"PUMS OGHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5025", district:"2", Block:"51", label:"St.JOSEPH MATRIC,PORUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5026", district:"13", Block:"78", label:"PUMS ALASANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5027", district:"10", Block:"101", label:"M.L.M. Mamallan MHSS, Kanchipuram 631502", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5028", district:"30", Block:"385", label:"HOLYCROSS RC MS VALLIOOR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5029", district:"21", Block:"408", label:"SRI ANDAL AMS ARCOT", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5030", district:"11", Block:"191", label:"ST. ANTONY'S M.S.C. MIDDLE SCHOOL METHUKUMMEL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5031", district:"8", Block:"185", label:"RD INTERNATIONAL SCHOOL, GATUPUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5032", district:"3", Block:"237", label:"VATSALYA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5033", district:"24", Block:"388", label:"PUJMS, NAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5034", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL KANGIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5035", district:"31", Block:"103", label:"ADWMS ADIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5036", district:"12", Block:"142", label:"PUMS, KADAMBARKOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"5037", district:"2", Block:"299", label:"LYDIA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5038", district:"14", Block:"161", label:"VIBGYOR MATRIC HIGH SCHOOL, K.PUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5039", district:"35", Block:"193", label:"PUMS,THILLAIVILAGAM-SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5040", district:"5", Block:"98", label:"AMS T. PAVAZHANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5041", district:"29", Block:"171", label:"ALPHA WISDOM VIDHAYASHRAM SCHOOL (CBSE), K K NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5042", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - ARISIPERIYANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5043", district:"16", Block:"389", label:"NAKKEERANAR AMS,PANCHANATHIKULAM WEST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5044", district:"13", Block:"311", label:"PUMS SALIVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5045", district:"13", Block:"311", label:"PUMS DEVARULIMANGALAM -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"5046", district:"33", Block:"184", label:"PUMS,KORAI KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5047", district:"22", Block:"30", label:"RASI MATRIC HR. SEC. SCHOOL, MALLIYAKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5048", district:"6", Block:"235", label:"PUMS M.THANDA", Sch_type:"Government", Category:"Middle School"},
    { key:"5049", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, K.VEPPANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5050", district:"21", Block:"210", label:"BHARATH VIDYA MATRIC SCHOOL, SENDAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"5051", district:"5", Block:"148", label:"PUMS MANTHOPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"5052", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE SCHOOL VISOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5053", district:"4", Block:"386", label:"PANCHAYATH UNION MIDDLE SCHOOL NALLAMUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5054", district:"17", Block:"200", label:"PUMS VELAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5055", district:"32", Block:"371", label:"MMS RUDRAPPANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5056", district:"25", Block:"344", label:"AIDED  MIDDLE SCHOOL - VEPPATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5057", district:"7", Block:"127", label:"ASSEFA VIDHYALAYA MATRICULATION SCHOOL, KAMANUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5058", district:"29", Block:"258", label:"KALAIVANI MATRIC SCHOOL,PULLAMBADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5059", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL , SELLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5060", district:"2", Block:"299", label:"SRI SESHADRI MHSS CHROMPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5061", district:"35", Block:"137", label:"PUMS, IRULNEEKI", Sch_type:"Government", Category:"Middle School"},
    { key:"5062", district:"27", Block:"241", label:"SIDDHARATHA MATRICULATION  SCHOOL -  KEELAVADAKARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5063", district:"29", Block:"192", label:"SHANTHI Matric..H.S.S MUSIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5064", district:"25", Block:"336", label:"PUMS-POONAVARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5065", district:"28", Block:"351", label:"MUNICIPAL MS, SIVANTHAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5066", district:"1", Block:"286", label:"TSN MATRICULATION SCHOOL DALAVOI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5067", district:"5", Block:"211", label:"KALAIMAGAL AIDED MIDDLE SCHOOL, MOOLAKUPPAM @KATTUKOLLAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5068", district:"17", Block:"200", label:"PUMS NARAIKINARU", Sch_type:"Government", Category:"Middle School"},
    { key:"5069", district:"11", Block:"149", label:"GMS KALLUKOOTTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5070", district:"35", Block:"141", label:"PUMS, KANGEYANAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5071", district:"36", Block:"74", label:"PUMS SARAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5072", district:"6", Block:"198", label:"PUMS CHIKKAGOUNDANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5073", district:"2", Block:"299", label:"NAPPINNAI MAT SCHOOL,", Sch_type:"Un-aided", Category:"High School"},
    { key:"5074", district:"11", Block:"191", label:"GOVT MIDDLLE SCHOOL, KUNNATHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5075", district:"16", Block:"308", label:"PUMS THIRUVIDAIMARUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5076", district:"4", Block:"279", label:"ST JOSEPH'S MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5077", district:"34", Block:"358", label:"PUMS PALAYAMANNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5078", district:"35", Block:"193", label:"PUMS,KARPAGA-COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"5079", district:"28", Block:"369", label:"PUMS, IDAICHIVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5080", district:"11", Block:"124", label:"GMS PARAVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5081", district:"35", Block:"382", label:"PUMS MANALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5082", district:"36", Block:"393", label:"PUMS ATHIYURVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"5083", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL NARAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5084", district:"36", Block:"113", label:"PUMS Kuppatha Mottur", Sch_type:"Government", Category:"Middle School"},
    { key:"5085", district:"15", Block:"151", label:"PUMS KANDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5086", district:"34", Block:"249", label:"PUMS CHINNAPUSHPAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"5087", district:"36", Block:"393", label:"PUMS ABDULLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5088", district:"5", Block:"148", label:"PUMS THIYAGAVALLI 2", Sch_type:"Government", Category:"Middle School"},
    { key:"5089", district:"36", Block:"394", label:"MMS EDA  STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"5090", district:"24", Block:"307", label:"KATTU BAVA MIDDLE SCHOOL , TENKASI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5091", district:"35", Block:"173", label:"PUMS.THENPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5092", district:"15", Block:"131", label:"PUMS PALAIYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5093", district:"5", Block:"148", label:"PUMS SAMATTIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5094", district:"36", Block:"74", label:"PUMS PAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5095", district:"13", Block:"122", label:"PUMS THEN DINNUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5096", district:"16", Block:"389", label:"SRI. THAYUMANAVAR VIDYALAYA AMS VEDARANYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5097", district:"31", Block:"103", label:"GADWMS, NATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5098", district:"34", Block:"125", label:"PUMS MANAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5099", district:"25", Block:"287", label:"PUMS -KATTAYANKADU UKKADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5100", district:"24", Block:"307", label:"MUSTAFIAH MIDDLE SCHOOL TENKASI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5101", district:"28", Block:"369", label:"PUMS MANADUTHANDUPATHU", Sch_type:"Government", Category:"Middle School"},
    { key:"5102", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  KOVILPURAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5103", district:"29", Block:"366", label:"SARASWATHI BALA MANDIR MATRICULATION SCHOOL SRINIVASA NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5104", district:"31", Block:"5", label:"PUUBMS, JAFFARABAD", Sch_type:"Government", Category:"Middle School"},
    { key:"5105", district:"33", Block:"184", label:"PUMS, THIRUPPALAIVANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5106", district:"35", Block:"137", label:"PUMS, MELAKANDAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5107", district:"6", Block:"187", label:"PUMS RANIMOOKANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5108", district:"16", Block:"389", label:"PUMS, PERIYAKUTHAKAI EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"5109", district:"29", Block:"176", label:"PUMS,AATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5110", district:"21", Block:"409", label:"PUMS NARASINGAPURAM / AMMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5111", district:"29", Block:"172", label:"SRI BALA VIDYAMANDIR MATRIC HSS SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5112", district:"1", Block:"81", label:"RAJAN MIDDLE SCHOOL OKKANATHAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5113", district:"32", Block:"250", label:"KALAIMAGAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5114", district:"34", Block:"365", label:"PUMS PAVUPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5115", district:"16", Block:"389", label:"PUMS,PICHAKATTALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5116", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL UDHAYAMAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5117", district:"14", Block:"328", label:"PRAJNA MATRIC HR SEC SCHOOL, THIRUPARANKUNDRAM   ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5118", district:"21", Block:"210", label:"SENTHIL KUMARAN PS, GANAPATHIPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5119", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL, MANALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5120", district:"34", Block:"93", label:"AIDED MIDDLE SIRUVALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5121", district:"34", Block:"365", label:"MMS THENIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5122", district:"15", Block:"151", label:"PUMS KODIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5123", district:"12", Block:"112", label:"P.U.MID.SCHOOL,THANGARAJ,NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5124", district:"27", Block:"55", label:"PUMS - KEELAGUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5125", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL PAPPANCHATRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5126", district:"12", Block:"142", label:"PUMS, SIVAYAM WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"5127", district:"34", Block:"14", label:"PUMS PERUMBALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5128", district:"32", Block:"392", label:"P.U.M.S,CHINNAMUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5129", district:"35", Block:"173", label:"PUMS PERIYAKOTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5130", district:"30", Block:"46", label:"THE MIDDLE SCHOOL MELASEVAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5131", district:"28", Block:"217", label:"PUMS, SAKKAMMALPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5132", district:"19", Block:"326", label:"KING JESUS MATRIC SCHOOL, THIRUMAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5133", district:"23", Block:"96", label:"PUMS T.NADUVIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5134", district:"2", Block:"300", label:"NADAR SANGA MATRIC. HR. SEC .SCHOOL, PAMMAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5135", district:"28", Block:"351", label:"INTER CHRISTIAN MISSION MS BRYANT NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5136", district:"15", Block:"151", label:"PANCHAYAT UNION MIDDLE SCHOOL    ELANTHANGUDI ", Sch_type:"Government", Category:"Middle School"},
    { key:"5137", district:"13", Block:"398", label:"PUMS KUPPACHIPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5138", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL, MEESAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5139", district:"34", Block:"358", label:"PUMS SANANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5140", district:"34", Block:"358", label:"PUMS KAMALAPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5141", district:"5", Block:"148", label:"PUMS PETHANAYAKKANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5142", district:"17", Block:"224", label:"JAI KANNAMMAL KALVI NILAYAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5143", district:"35", Block:"137", label:"PUMS, KUMMATTITHIDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5144", district:"6", Block:"198", label:"PUMS THENGAMARATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5145", district:"30", Block:"385", label:"TDTA MS THALAVAIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5146", district:"28", Block:"8", label:"ST MARY'S  MIDDLE SCHOOL ,PUNNAIKAYAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5147", district:"25", Block:"157", label:"PUMS - VIKRAMAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5148", district:"6", Block:"198", label:"PUMS KALEGOUNDANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5149", district:"25", Block:"228", label:"ALIF MATRICULATION SCHOOL - VALUTHOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5150", district:"16", Block:"389", label:"PUMS KURAVAPULAM WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"5151", district:"35", Block:"205", label:"PUMS, KOILTHIRUMALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5152", district:"33", Block:"337", label:"PUMS, THAZHAVEDU H/C", Sch_type:"Government", Category:"Middle School"},
    { key:"5153", district:"24", Block:"307", label:"CMS MACWHIRTER MIDDLE SCHOOL TENKASI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5154", district:"22", Block:"246", label:"PUMS-MANNUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5155", district:"34", Block:"44", label:"PUMS MANMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5156", district:"28", Block:"402", label:"N.K.MS, Arunkulam", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5157", district:"6", Block:"235", label:"PUMS VELAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5158", district:"16", Block:"389", label:"PUMS, CHETTITHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"5159", district:"28", Block:"350", label:"PUMS THIRUVANANTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5160", district:"15", Block:"151", label:"PANCHAYAT UNION MIDDLE SCHOOL   VAZHUVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5161", district:"15", Block:"284", label:"PANCHAYAT UNION MIDDLE SCHOOL    KIDANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5162", district:"13", Block:"122", label:"PUMS NELLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5163", district:"12", Block:"112", label:"P.U.MID.SCHOOL,SEVANTHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5164", district:"6", Block:"235", label:"YESU RAJA MHSS, PAUPARAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5165", district:"14", Block:"328", label:"ZION GOOD SHEPHERDS MATRIC HR SEC SCHOOL, AVANIAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5166", district:"23", Block:"79", label:"MUTHU VINAYAGAR MIDDLE SCHOOL , VADAKKU SAMUTHIRAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5167", district:"23", Block:"79", label:"PUMS THIRUVALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5168", district:"13", Block:"376", label:"PUMS THURINJIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"5169", district:"3", Block:"69", label:"NORTH MADRAS MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5170", district:"33", Block:"252", label:"MAHALASHMI MATRICULATION HSS PARUTHIPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5171", district:"30", Block:"9", label:"ST.XAVIER'S MS - IRUTHAYAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5172", district:"32", Block:"104", label:"PUMS MARAVAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5173", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, MADURANTHAGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5174", district:"17", Block:"201", label:"PUMS KONDICHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5175", district:"3", Block:"62", label:"OUR LADY'S MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5176", district:"11", Block:"263", label:"ST.FRANCIS MATRIC.HR.SEC.SCHOOL,VELLALANVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5177", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, ODDAKANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5178", district:"30", Block:"221", label:"PUMS,KEELATHIDIYOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5179", district:"4", Block:"242", label:"PUMS HOUSING UNIT", Sch_type:"Government", Category:"Middle School"},
    { key:"5180", district:"27", Block:"375", label:"SRI SUBRAMANIA BARATHI MIDDLE SCHOOL - THAMMINAYAKANPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5181", district:"13", Block:"291", label:"PUMS NALLARALAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5182", district:"20", Block:"99", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKOTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5183", district:"34", Block:"312", label:"PUMS PUTHUR CHEKKADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5184", district:"15", Block:"151", label:"PUMS KOZHAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5185", district:"34", Block:"238", label:"PUMS SEPTANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5186", district:"29", Block:"176", label:"PUMS,KARADIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5187", district:"20", Block:"266", label:"SRI SETHUPATHI MIDDLE SCHOOL , RAMANATHAPURAM ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5188", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL , SAMBAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5189", district:"31", Block:"362", label:"PUMS POORIGAMANIMITTA", Sch_type:"Government", Category:"Middle School"},
    { key:"5190", district:"36", Block:"105", label:"PUMS MOTHAKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5191", district:"31", Block:"103", label:"PUMS, PALLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5192", district:"34", Block:"44", label:"PUMS GANESAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5193", district:"16", Block:"389", label:"PUMS SEMBAGARAYANALLUR NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5194", district:"1", Block:"81", label:"PUMS-MELAKUDIYIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"5195", district:"34", Block:"365", label:"MMS KANA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5196", district:"6", Block:"235", label:"PUMS KOOKUTTAMARUDHAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5197", district:"27", Block:"31", label:"PUMS - MARAVAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5198", district:"15", Block:"131", label:"PUMS VEMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5199", district:"31", Block:"103", label:"PUMS KAKANGARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5200", district:"16", Block:"308", label:"PUMS VADUGOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5201", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL KOZHINDRAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5202", district:"34", Block:"44", label:"PUMS K.ANNANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5203", district:"12", Block:"112", label:"P.U.MID.SCHOOL,THIRUMUKKUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5204", district:"5", Block:"17", label:"PUMS KATTUMUTHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5205", district:"24", Block:"288", label:"GOVERNMENT MIDDLE SCHOOL ELATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5206", district:"8", Block:"71", label:"SRI D.S. SARADHA VIDHYALAYA MIDDLE SCHOOL, GOBI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5207", district:"15", Block:"151", label:"PUMS THIRUVALANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"5208", district:"33", Block:"403", label:"ST THERASA MAT HSS MANNURPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5209", district:"8", Block:"202", label:"AALAYAM MATRICULATION SCHOOL, NAMBIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5210", district:"28", Block:"297", label:"HINDU MS PANDARAVILAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5211", district:"5", Block:"233", label:"PUMS KILLAIPATTINAVAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5212", district:"33", Block:"64", label:"PUMS, PUNNAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5213", district:"34", Block:"312", label:"PUMS NEDUGAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5214", district:"34", Block:"365", label:"PUMS ANANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5215", district:"21", Block:"408", label:"LFC GIRLS AIDED HSS RANIPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"5216", district:"25", Block:"314", label:"BHARATHA MADA MS-VANDIKARA THERU-THANJAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5217", district:"27", Block:"40", label:"SENTHIL MIDDLE SCHOOL - KEPPURENGANPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5218", district:"25", Block:"144", label:"BHARATHA RATHNA PURATCHITHALAIVAR DR.MGR MEMMORIAL MPL MIDDLE SCHOOL ", Sch_type:"Government", Category:"Middle School"},
    { key:"5219", district:"34", Block:"80", label:"PUMS THURINJIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5220", district:"17", Block:"66", label:"PUMS NAVALADIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5221", district:"5", Block:"98", label:"PUMS, A.KURAVANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5222", district:"24", Block:"119", label:"TDTA MS  MELAPATTAMUDAIARPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5223", district:"15", Block:"151", label:"SRI SOLEESWARA MS, KUTHALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5224", district:"35", Block:"193", label:"PUMS,T-KEELAKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"5225", district:"5", Block:"143", label:"PUMS PAZHAYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5226", district:"17", Block:"66", label:"PUMS A VALAVANTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5227", district:"32", Block:"250", label:"PUMS RAMAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5228", district:"6", Block:"77", label:"PUMS MANTHIKULAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5229", district:"25", Block:"157", label:"PUMS - KADANTHANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5230", district:"27", Block:"31", label:"PUMS - SUNDARARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5231", district:"25", Block:"144", label:"SARASWATHI PADASALA AHSS  KUMBAKONAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"5232", district:"15", Block:"131", label:"VASANTHALA AMS PANNAKKARANKOTTHAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5233", district:"33", Block:"184", label:"PUMS ELAVAMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"5234", district:"32", Block:"250", label:"PUMS MASANALLAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5235", district:"34", Block:"125", label:"PUMS GANALAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5236", district:"12", Block:"112", label:"P.U.MIDDLE SCHOOL,THALAVAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5237", district:"21", Block:"116", label:"SARASWATHI MATRIC HSS, KAVERIPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5238", district:"5", Block:"211", label:"NLC MIDDLE SCHOOL BLOCK- 9  NEYVELI -TS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5239", district:"25", Block:"157", label:"PUMS - SOKKANAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5240", district:"6", Block:"198", label:"PUMS RAMARKOODAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5241", district:"5", Block:"54", label:"AIDED MIDDLE SCHOOL - SRI RAMAKRISHNA - THIRUPPATHIRIPULIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5242", district:"29", Block:"381", label:"R.C. MATRICULATION SCHOOL, VAIYAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"5243", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL KAZHUMALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5244", district:"28", Block:"8", label:"PUMS,PURAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5245", district:"1", Block:"81", label:"PUMS-NARASINGAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5246", district:"25", Block:"228", label:"PUMS - MELA MANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"5247", district:"20", Block:"188", label:"R.C MIDDLE SCHOOL, M.MICHAELPATTANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5248", district:"5", Block:"98", label:"PUMS PALLAKOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5249", district:"34", Block:"365", label:"PUMS ANDIYAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5250", district:"34", Block:"312", label:"PUMS SERPPAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5251", district:"21", Block:"24", label:"PUNCHAYAT UNION MIDDLE SCHOOL, THENNANDIYALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5252", district:"10", Block:"410", label:"PUMS, PUTHAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5253", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, VALASAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"5254", district:"33", Block:"184", label:"PUMS,RAJIVGANDHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5255", district:"36", Block:"12", label:"PUMS VARADHALAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5256", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, THIMMANAYAKKANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5257", district:"2", Block:"300", label:"St.Peters Mat.HSS, St.Thomas Mount", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5258", district:"34", Block:"249", label:"PUMS MUKKURUMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5259", district:"12", Block:"142", label:"SRI. VIVEKANANDA AIDED MIDDLE SCHOOL, KANDIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5260", district:"4", Block:"52", label:"ST. FRANCIS ANGLO INDIAN GIRLS HIGH SCHOOL (AIDED)", Sch_type:"Partially Aided", Category:"High School"},
    { key:"5261", district:"23", Block:"106", label:"PUMS DEVANDADAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"5262", district:"5", Block:"115", label:"PUMS THIRUCHENNAPURM", Sch_type:"Government", Category:"Middle School"},
    { key:"5263", district:"33", Block:"252", label:"PANCHAYAT UNION MIDDLE SCHOOL KUTHAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5264", district:"16", Block:"196", label:"PUMS KEECHANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5265", district:"28", Block:"8", label:"TNDTA MIDDLE SCHOOL  KARUNKADAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5266", district:"4", Block:"242", label:"HARI OM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5267", district:"2", Block:"114", label:"SRI VISHWA VIDYALAYA  MHSS, Vandalur-600048", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5268", district:"6", Block:"77", label:"PUMS NAIKUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5269", district:"15", Block:"178", label:"VIVEKANANDHA MATRIC SCHOOL MANALMEDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5270", district:"25", Block:"157", label:"PUMS - KARUPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5271", district:"3", Block:"259", label:"DR BMS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5272", district:"5", Block:"143", label:"PANCHAYATH UNION MIDDLE SCHOOL LALPET MAIN", Sch_type:"Government", Category:"Middle School"},
    { key:"5273", district:"3", Block:"69", label:"VELANKANNI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5274", district:"33", Block:"76", label:"SRI KALAIMAGAL VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5275", district:"2", Block:"300", label:"ST MARKS MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5276", district:"2", Block:"299", label:"Rosily MHSS Chitlapakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5277", district:"29", Block:"367", label:"St. James Mat. Hr. Sec. School, Cantonment", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5278", district:"17", Block:"200", label:"PUMS SEERAPPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5279", district:"37", Block:"404", label:"RAMAKRISHNA MISSION VIDYALAYA MATRIC HR SEC SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5280", district:"11", Block:"3", label:"GMS POTTAIYADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5281", district:"12", Block:"315", label:"STAR SCHOOL CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"5282", district:"14", Block:"158", label:"FUSCO'S MATRIC HR SEC SCHOOL, YAGAPPA NAGAR,VANDIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5283", district:"13", Block:"122", label:"PUMS DENKANIKOTTA URDU", Sch_type:"Government", Category:"Middle School"},
    { key:"5284", district:"2", Block:"299", label:"St.Peter's Matriculation Higher Secondary School,Pallikaranai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5285", district:"10", Block:"146", label:"ANNAI THERESA MAT. HR.SEC. SCHOOL, IYYAPANTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5286", district:"2", Block:"299", label:"Brilliant MHSS Madipakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5287", district:"15", Block:"151", label:"PUMS GANGATHARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5288", district:"16", Block:"123", label:"GHSS KURUKKATHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"5289", district:"2", Block:"114", label:"SRI. K.CHUNNILAL JAIN VIVEKANANDA VIDYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"5290", district:"35", Block:"193", label:"PUMS,KALLIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5291", district:"16", Block:"325", label:"SRI NADESAIYHA GOVT AMS SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5292", district:"16", Block:"123", label:"PUMS NAGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5293", district:"2", Block:"299", label:"ST GEORGE MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5294", district:"6", Block:"198", label:"PUMS DHINNAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5295", district:"22", Block:"34", label:"SRI VIDYA MANDIR HR. SEC. SCHOOL, RAMALINGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5296", district:"33", Block:"225", label:"ANM MS, PUNNIYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5297", district:"4", Block:"107", label:"SAN JOSE MATRIC HR SEC SCHOOL, METTUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5298", district:"2", Block:"114", label:"R K M VID MHSS,Chengalpattu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5299", district:"21", Block:"290", label:"PUMS MARUDHALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5300", district:"33", Block:"403", label:"DAV (G) SR.SEC.SCH, COLLECTOR NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5301", district:"6", Block:"187", label:"SRI SANKARA MHSS,KAMBAINALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5302", district:"4", Block:"302", label:"PUMS KADUVETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5303", district:"27", Block:"49", label:"MUSLIM MS - CHINNAMANUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5304", district:"29", Block:"171", label:"CREA CHILDREN'S ACADEMY MATRIC HIGHER SECONDARY SCHOOL NAGAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5305", district:"5", Block:"17", label:"PUMS C.PAGANDAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5306", district:"6", Block:"198", label:"PUMS NEKKUNDHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5307", district:"12", Block:"140", label:"PUMS, KATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5308", district:"19", Block:"255", label:"MOUNTZION MATRIC  HR SEC SCHOOL,PUDUKKOTTAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5309", district:"2", Block:"300", label:"New Prince MHSS Ullagaram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5310", district:"10", Block:"101", label:"SSKV Mat.HSS., Kanchipuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5311", district:"15", Block:"151", label:"PUMS KUTTALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5312", district:"37", Block:"404", label:"THE CLARIBEL MATRIC SCHOOL MAHARAJAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"5313", district:"3", Block:"2", label:"ADVENT CHRISTIAN MIDDLE SCHOOL,  THIRUVANMIYUR, CH-41.", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5314", district:"5", Block:"98", label:"SIVABHARATHI MATRICULATION HIGHER SECONDARY SCHOOL SIRUVARAPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5315", district:"15", Block:"151", label:"PUMS KOKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5316", district:"34", Block:"412", label:"AIDED MIDDLE KATTUKANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5317", district:"29", Block:"353", label:"Dr.V. Gengusamy Naidu  MAT SCHOOL,THOTTIYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"5318", district:"6", Block:"77", label:"PUMS T ANDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5319", district:"3", Block:"237", label:"CHANDABAI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5320", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL VAIRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5321", district:"24", Block:"6", label:"Sri Mahalingam MS, Marukalankulam", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5322", district:"13", Block:"291", label:"PUMS KOTTANGARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5323", district:"3", Block:"237", label:"GOODWILL HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"5324", district:"2", Block:"299", label:"Rajkumar Sulochana MHSS Madipakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5325", district:"16", Block:"123", label:"PUMS KURUMANANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5326", district:"2", Block:"299", label:"ELLEN SHARMA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5327", district:"2", Block:"300", label:"GOOD SHEPHERD MATRICULATION HIGHER SECONDARY SCHOOL, OLD PALLAVARAM, CHENNAI- 117", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5328", district:"5", Block:"170", label:"SARASWATHI ARIVALAYAM HIGHER SECONDARY  SCHOOL , KALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5329", district:"2", Block:"114", label:"ST JOSEPH MAT HSS CHENGELPUT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5330", district:"22", Block:"383", label:"VENUS VIDYALAYA MATRICULATION SCHOOL,VEPPILAIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5331", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, AYANSITHANENDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5332", district:"29", Block:"258", label:"PUMS,KEELARASUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5333", district:"12", Block:"140", label:"PUMS VAYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5334", district:"23", Block:"79", label:"PUMS KARUNJUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5335", district:"15", Block:"178", label:"TELC MIDDLE SCHOOL,MAYILADUTHURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5336", district:"29", Block:"317", label:"INTERNATIONAL TAMILAN Matriculation Academy", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5337", district:"5", Block:"39", label:"SRI ARUNACHALA  MATRICULATION  HIGHER SECONDARY  SCHOOL,  BHUVANAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5338", district:"33", Block:"403", label:"ST JOHNS MS,TM VOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5339", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL SITHATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5340", district:"13", Block:"78", label:"PUMS CHENNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5341", district:"5", Block:"233", label:"PANCHAYAT UNION MIDDLE SCHOOL  SILAMBIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5342", district:"25", Block:"216", label:"PUMS- AMBALAPPATTU (S)", Sch_type:"Government", Category:"Middle School"},
    { key:"5343", district:"10", Block:"146", label:"ROSHNI MATRICULATION SCHOOL, KUTHNOOR KAVANUR (POST)", Sch_type:"Un-aided", Category:"High School"},
    { key:"5344", district:"22", Block:"273", label:"S.R.K. MATRIC HR. SEC. SCHOOL,KANDAMPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5345", district:"12", Block:"315", label:"P.U.M.SCHOOL,ACHIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5346", district:"27", Block:"49", label:"MRV MS - MARKAYANKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5347", district:"23", Block:"294", label:"21 ST CENTURY  MATRIC HSS SIVAGANGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5348", district:"2", Block:"114", label:"ST.ANNE'S NURSERY AND PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5349", district:"10", Block:"101", label:"Pallavan MS, Kolivakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5350", district:"18", Block:"236", label:"CHRISTIAN MATRIC HR.SEC SCHOOL PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5351", district:"2", Block:"299", label:"ZION MAT HSS MADAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5352", district:"2", Block:"300", label:"Anna Mat Hr School, East Tambaram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5353", district:"35", Block:"193", label:"PUMS,OVARUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5354", district:"14", Block:"161", label:"MARUTHU RUKMANI MATRIC HR SEC SCHOOL, P&T NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5355", district:"23", Block:"272", label:"PUMS CHINNAVENGAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5356", district:"35", Block:"209", label:"PUMS, MULLAIVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5357", district:"35", Block:"173", label:"PUMS.MELANEMMELI", Sch_type:"Government", Category:"Middle School"},
    { key:"5358", district:"29", Block:"366", label:"CARMELS MAT HSS,TRICHY-3", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5359", district:"13", Block:"311", label:"POORNA KAMALA VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5360", district:"15", Block:"131", label:"GHSS THIRUMULLAIVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"5361", district:"10", Block:"101", label:"PUMS,PONNIYAMMAN PATTARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5362", district:"33", Block:"403", label:"St. Mary's Matriculation Higher Secondary School ,IAF Avadi", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5363", district:"35", Block:"205", label:"PUMS,SIRUPULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5364", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, KODIVAYAL EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"5365", district:"13", Block:"122", label:"PUMS THOTTINAYAKANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5366", district:"10", Block:"377", label:"Servasev Vidhyalaya Matriculation Higher Secondary School, Manithottam ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5367", district:"23", Block:"333", label:"ARULMIGU THANDEESWARAR MATRIC. HIGH SCHOOL,THIRUPPUVANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"5368", district:"30", Block:"92", label:"TDTA MS IDAYANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5369", district:"34", Block:"365", label:"PUMS VERAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5370", district:"16", Block:"389", label:"AMS,KATHARIPULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5371", district:"31", Block:"208", label:"SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5372", district:"29", Block:"343", label:"JEGAN MATHA MATRIC HSS SCHOOL,K.K.NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5373", district:"6", Block:"198", label:"PUMS OMALNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5374", district:"10", Block:"101", label:"PUMS, DAMAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5375", district:"2", Block:"299", label:"Loyola MHSS Chitlabakkam ch-64", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5376", district:"13", Block:"35", label:"PUMS NERALAKOTTA", Sch_type:"Government", Category:"Middle School"},
    { key:"5377", district:"21", Block:"290", label:"PUMS THALANGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5378", district:"23", Block:"272", label:"PUMS AMBAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5379", district:"12", Block:"315", label:"LITTLE FLOWER MATRIC  HR. SEC. SCHOOL,MANAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5380", district:"17", Block:"224", label:"THE AVS MATRICULATION SCHOOL, KOMARAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"5381", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL VASANTHAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5382", district:"6", Block:"230", label:"SRI VENKATESWARA MHSS, PAPPIREDDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5383", district:"10", Block:"146", label:"M.S.J.M. HinduVid MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5384", district:"2", Block:"300", label:"National Mat.Hr.Sec.School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5385", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL PONPATTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5386", district:"3", Block:"304", label:"RAMALINGA MISSION MIDDLE SCH", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5387", district:"14", Block:"160", label:"MADURAI MEENAKSHI MATRIC HR SEC SCHOOL, PETCHIAMMAN PADITHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5388", district:"2", Block:"114", label:"BRINDAVAN PUBLIC SCHOOL, ATHUR-603101, CHENGALPATTU", Sch_type:"Un-aided", Category:"High School"},
    { key:"5389", district:"16", Block:"123", label:"PUMS ATHIPULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5390", district:"28", Block:"369", label:"TNDTA MIDDLE SCHOOL, VETHAKOTTAVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5391", district:"5", Block:"233", label:"PUMS PICHAVARAM SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5392", district:"2", Block:"300", label:"KARPAGA VIGNESWARA VIDYALAYA M. H. S. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5393", district:"31", Block:"103", label:"PUMS CHITHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"5394", district:"2", Block:"322", label:"SEVENTH DAY ADVENTIST MHSS PUDUPATTINAM, KALPAKKAM-603 102", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5395", district:"1", Block:"81", label:"PUMS-VANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5396", district:"26", Block:"72", label:"MTS MATRICULATION HIGH SCHOOL PADANTHORAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5397", district:"6", Block:"77", label:"HIMALAYAN MHSS, GOPALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5398", district:"14", Block:"328", label:"ST.JOHN'S MATRIC HR SEC SCHOOL, KOCHADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5399", district:"2", Block:"114", label:"EMMANUEL MATRICULATION SCHOOL,URAPPAKKAM-603211", Sch_type:"Un-aided", Category:"High School"},
    { key:"5400", district:"2", Block:"300", label:"S.C.S MHSS, Nehru Ngr,chrompet,ch-44", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5401", district:"2", Block:"299", label:"JOSHUA MHSS ,OLD PERUNGALATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5402", district:"23", Block:"79", label:"PUMS MELATHURAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5403", district:"12", Block:"112", label:"LITTLE ANGELS' ENGLISH HR.SEC.SCHOOL,KARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5404", district:"36", Block:"84", label:"LITTLE STARS MATRIC HS  LATHERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5405", district:"24", Block:"388", label:"RAJASINGH MS, THERKKU CHATRAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5406", district:"16", Block:"389", label:"PUMS,THENNADAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5407", district:"27", Block:"55", label:"PUMS -  ERAVANGALAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5408", district:"2", Block:"299", label:"Infant Jesus Mat. Sch, Pallikaranai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5409", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL ALAPPIRANTHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"5410", district:"13", Block:"398", label:"PUMS VIRUPPASANDIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5411", district:"2", Block:"299", label:"St.Antony Matric HSS, Pallikaranai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5412", district:"15", Block:"293", label:"PUMS KAVERIPOOMPATTINAM (FISHER)", Sch_type:"Government", Category:"Middle School"},
    { key:"5413", district:"33", Block:"338", label:"SHREE NIKETAN MATRIC HIGHER SECONDARY  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5414", district:"33", Block:"260", label:"RAGHUPATHY RAJA MS, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5415", district:"28", Block:"297", label:"SRI SUNDARA VINAYAGAR MS VELLUR ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5416", district:"2", Block:"300", label:"VIDHYA MANDIR MS,  EAST TAMBARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"5417", district:"13", Block:"291", label:"PUMS  BELLATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5418", district:"4", Block:"13", label:"SHRI KRISHNA VIDHYALAYA MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5419", district:"23", Block:"166", label:"BABA MATRIC SCHOOL MANAMADURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5420", district:"4", Block:"242", label:"LAUREL MATRIC .HR .SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5421", district:"14", Block:"161", label:"MARY ANN MATRIC HR SEC SCHOOL, K PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5422", district:"1", Block:"346", label:"PUMS THIRUVENGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5423", district:"21", Block:"290", label:"PUMS - SHOLINGHUR MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5424", district:"12", Block:"88", label:"PUMS, ARASAGOUNDANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5425", district:"5", Block:"233", label:"PUMS UTHAMASOZHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5426", district:"36", Block:"113", label:"TRINITY MATRIC HSS, GANDHI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5427", district:"3", Block:"237", label:"JRET RAGHAVA MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5428", district:"15", Block:"284", label:"SVS AMS ERUKKATANCHERY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5429", district:"23", Block:"79", label:"I.N.P.T MATRIC HS, ILAIYANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5430", district:"16", Block:"389", label:"PUMS THANIKOTTAGAM SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5431", district:"2", Block:"153", label:"CRESCENT MHSS,CHEYYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5432", district:"29", Block:"366", label:"SRI JAYENDRA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5433", district:"35", Block:"209", label:"PUMS, VIZHALKOTTAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5434", district:"19", Block:"251", label:"CHIDAMBARAM MATRICULATION HIGHER SECONDARY, VALAYAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5435", district:"5", Block:"406", label:"MUNICIPAL MIDDLE SCHOOL BOODHAMUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5436", district:"33", Block:"252", label:"PENGUIN MATRIC HR SEC SCHOOL, AVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5437", district:"19", Block:"33", label:"NEW SANGGEETH MATRIC HR. SEC. SCHOOL, MIMISAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5438", district:"15", Block:"178", label:"GEETHA MATRICULATION SCHOOL CHENJI KALI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5439", district:"1", Block:"81", label:"PUMS-KAZHUVANTHONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5440", district:"32", Block:"392", label:"GNANASAMBANDAR MHSS, PUDHUPPAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5441", district:"34", Block:"249", label:"ADWMS KELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5442", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL, ALINTHIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5443", district:"6", Block:"187", label:"PUMS KURUBARAHALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"5444", district:"2", Block:"300", label:"GK Shetty Hindu Vid MHSS Adambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5445", district:"2", Block:"322", label:"INFANT JESUS MHSS, PUDUPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5446", district:"12", Block:"112", label:"P.U.MID.SCHOOL EAST THAVUTTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5447", district:"5", Block:"115", label:"PUMS AMBUJAVALLIPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5448", district:"14", Block:"161", label:"MARY IMMACULATE MATRIC HIGH SCHOOL, APPATHURAINAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5449", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.OORAPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"5450", district:"14", Block:"374", label:"PONMANI MATRIC HR SEC SCHOOL, USILAMPATTI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5451", district:"35", Block:"173", label:"PUMS.NEMMELI", Sch_type:"Government", Category:"Middle School"},
    { key:"5452", district:"17", Block:"85", label:"PUMS VENGAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"5453", district:"10", Block:"410", label:"St.Joseph Matriculation Higher Secondary School, Walajapad", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5454", district:"35", Block:"193", label:"PUMS,KARPAGA-KADUVETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5455", district:"3", Block:"62", label:"CMS V.V .ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"5456", district:"12", Block:"112", label:"GANDHIYAR MID.SCHOOL,VELAYUTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5457", district:"27", Block:"241", label:"3RD MPL MS - VADAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5458", district:"16", Block:"389", label:"PUMS,VOIMEDU WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"5459", district:"35", Block:"137", label:"PUMS, SENTHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5460", district:"30", Block:"222", label:"GANESA MOORTHY MS KEELAVEERA RAGAVAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5461", district:"32", Block:"363", label:"PUMS, NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5462", district:"21", Block:"290", label:"PUMS KUPPAKKALMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"5463", district:"22", Block:"274", label:"ANDERSON MATRIC HR. SEC. SCHOOL,YERCAUD MAIN ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5464", district:"3", Block:"259", label:"VALLAL SABAPATHY MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5465", district:"36", Block:"394", label:"AMS SARASWATHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5466", district:"3", Block:"259", label:"SRI VENKATESWARA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5467", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOLVEGAKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5468", district:"21", Block:"21", label:"PUMS, MARANKANDIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5469", district:"5", Block:"143", label:"MPLMS NORTH CAR STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"5470", district:"2", Block:"300", label:"Indian Matric HSS, Tambaram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5471", district:"33", Block:"252", label:"HOLY IMMANUEL MATRICULATION SCHOOL AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5472", district:"5", Block:"120", label:"AMS ENNANAGARAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5473", district:"10", Block:"101", label:"Venkateswara MS Orikkai", Sch_type:"Un-aided", Category:"High School"},
    { key:"5474", district:"36", Block:"394", label:"BHARAT MATRIC HSS, KOSAPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5475", district:"12", Block:"315", label:"P.U.M.SCHOOL,MACHANNA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5476", district:"29", Block:"367", label:"Seventh-Day Adventist Mat.Hr.Sec.School,Beemnagar", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5477", district:"15", Block:"131", label:"PUMS MUTHALAIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"5478", district:"33", Block:"260", label:"SRI K.C.TOSHNIWAL VIVEKANANDA VIDYALAYA, PERIYA MATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5479", district:"30", Block:"385", label:"ST.JOSEPH'S MS KILAVANERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5480", district:"11", Block:"341", label:"GOOD SHEPHRED MS. VIRALIKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"5481", district:"36", Block:"12", label:"PUMS OTTERIPALLAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5482", district:"2", Block:"300", label:"ST.VINCENT PALLOTTI MHSS ZAMIN PALLAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5483", district:"3", Block:"240", label:"SRI LAKSHMI NARAYANA MATRICULATION HIGHER SECONDARY  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5484", district:"2", Block:"114", label:"Sivananda Saraswathi  MHSS,Kattankulathur-603203", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5485", district:"23", Block:"294", label:"ST JUSTIN'S MATRIC HSS,SIVAGANGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5486", district:"32", Block:"363", label:"MMS,DEVANGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5487", district:"16", Block:"389", label:"PUMS,THOPPUTHURAI(MUSILIM)", Sch_type:"Government", Category:"Middle School"},
    { key:"5488", district:"16", Block:"389", label:"PUMS,PERIYAKUTHAGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5489", district:"12", Block:"315", label:"VENUS MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5490", district:"11", Block:"149", label:"AROCKIA ANNAI MS PALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5491", district:"2", Block:"299", label:"LAXMI NARAYANA MATRIC SCHOOL,CHENNAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5492", district:"30", Block:"9", label:"HINDU MS - VAGAIGULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5493", district:"11", Block:"355", label:"LITTLE MEADS METRIC SCHOOL PALIYADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5494", district:"13", Block:"376", label:"PUMS GENGAPIRAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5495", district:"12", Block:"112", label:"MUNICIPAL JAYAPRAKASH MIDDLE SCHOOL,KARUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5496", district:"10", Block:"146", label:"Nav Bharath MHSS Mangadu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5497", district:"36", Block:"113", label:"PUMS BALAMUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5498", district:"25", Block:"144", label:"PUMS-SATHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5499", district:"16", Block:"325", label:"SMT CRESCENT MATRICULATION SCHOOL AUDALAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5500", district:"33", Block:"403", label:"RANGASAMY MATRIC  HSS,", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5501", district:"24", Block:"6", label:"CMS Arunothaya MS, Nallur", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5502", district:"35", Block:"173", label:"PUMS,OHAI PERAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5503", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL THENKALAVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5504", district:"34", Block:"27", label:"PUMS KANIGILUPPAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5505", district:"5", Block:"115", label:"AMS KANDAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5506", district:"21", Block:"290", label:"PUMS THANIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5507", district:"10", Block:"146", label:"PUMS IRANTAMKATTALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5508", district:"16", Block:"308", label:"PUMS ARUNTHAVAMPULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5509", district:"28", Block:"297", label:"RC MS SEBATHIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5510", district:"16", Block:"123", label:"PUMS VADAKKUPANAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5511", district:"35", Block:"334", label:"PUMS THIRUTHURAIPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5512", district:"11", Block:"182", label:"GOOD SHEPHERD.MAT.HSS-MARTHANDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5513", district:"6", Block:"187", label:"PUMS VAGUTHUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"5514", district:"16", Block:"123", label:"PUMS KADAMBANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5515", district:"31", Block:"5", label:"PUMS OMAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5516", district:"1", Block:"26", label:"PUMS AATHIKUDIKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"5517", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL AMMAKALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5518", district:"3", Block:"195", label:"CSI MIDDLE SCHOOL,PUDUPAKKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5519", district:"35", Block:"209", label:"PUMS, KALACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"5520", district:"21", Block:"21", label:"PUMS KAVANOOR NARASINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5521", district:"23", Block:"272", label:"PUMS PULIYANKUDIYIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"5522", district:"4", Block:"245", label:"CMS SANGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5523", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARIYANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5524", district:"12", Block:"142", label:"PUMS, MANATHATTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5525", district:"34", Block:"93", label:"ADWMS KADALADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5526", district:"11", Block:"149", label:"GMS-COLACHEL", Sch_type:"Government", Category:"Middle School"},
    { key:"5527", district:"23", Block:"96", label:"PUMS, PATHARAGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5528", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5529", district:"28", Block:"217", label:"HINDU NADAR MS,  ATHANOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5530", district:"34", Block:"365", label:"MMS TOWN HALL TIRUVANNAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5531", district:"5", Block:"143", label:"TELC MIDDLE M.ODAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5532", district:"31", Block:"82", label:"EBENEZER MAT. HR.SEC.SCHOOL, YELAGIRI HILLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5533", district:"25", Block:"144", label:"C.P VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL,  KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5534", district:"32", Block:"250", label:"PUMS MARUTHURAYAN VALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"5535", district:"2", Block:"299", label:"St John's MS Madambakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"5536", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, VADAHARIRAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5537", district:"28", Block:"280", label:"PUMS, GNANAIYARKUDIERUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"5538", district:"13", Block:"311", label:"PUMS T.KOTHANUR -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"5539", district:"6", Block:"235", label:"PUMS KATTUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5540", district:"6", Block:"109", label:"PUMS MURUKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5541", district:"21", Block:"290", label:"C.S.I. MARY MCLEAN MIDDLE SCHOOL, SHOLINGHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5542", district:"23", Block:"271", label:"PUMS GANAPATHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5543", district:"16", Block:"389", label:"PUMS,MOOLAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5544", district:"24", Block:"119", label:"TDTA MS MELAMEIGNANAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5545", district:"1", Block:"81", label:"PUMS-THAZHUTHAZAIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"5546", district:"16", Block:"389", label:"PUMS,PATTINATHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"5547", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL MEBOOTHAGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5548", district:"36", Block:"113", label:"PUMS PEDDABODINATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5549", district:"27", Block:"40", label:"NIRMALA MS - SILAMARATHUPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5550", district:"2", Block:"329", label:"BHARATHA VIDYALAYA MATRICULATION HR SEC SCHOOL, THANDALAM-TIRUPORUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5551", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, UDAYAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5552", district:"6", Block:"235", label:"PUMS RAJAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5553", district:"13", Block:"291", label:"PUMS VEMPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5554", district:"4", Block:"245", label:"PSG HIGH SCHOOL, VEDAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5555", district:"24", Block:"307", label:"MPL 9th WARD MS.TENKSI", Sch_type:"Government", Category:"Middle School"},
    { key:"5556", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL VADASIRUVALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5557", district:"15", Block:"151", label:"PUMS MEKKIRIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5558", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOTHANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5559", district:"16", Block:"308", label:"PUMS MARACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"5560", district:"8", Block:"356", label:"PANCHAYAT UNION MIDDLE SCHOOL, VANIPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5561", district:"21", Block:"359", label:"PUMS VARAGUR PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5562", district:"2", Block:"300", label:"Rajkumar Suloch MHSS Nanganallur", Sch_type:"Un-aided", Category:"High School"},
    { key:"5563", district:"32", Block:"371", label:"MMS KATCHERI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"5564", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL THULANGAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5565", district:"13", Block:"311", label:"PUMS KATCHUVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5566", district:"24", Block:"89", label:"SRI MEENAKSHI MS KILA AMBUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5567", district:"33", Block:"364", label:"PUMS - KORAMANAGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5568", district:"16", Block:"389", label:"THENI AMS,RAJAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5569", district:"36", Block:"113", label:"DESIA MATRIC SCHOOL, VANJUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5570", district:"34", Block:"358", label:"PUMS CHERIYANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5571", district:"29", Block:"172", label:"SRI MEIKANDAAR  MATRIC HR. SECONDARY SCHOOL,MANNACHANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5572", district:"27", Block:"40", label:"PUMS -  KARIAPPAGOUNDANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5573", district:"36", Block:"12", label:"PUMS ONGAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5574", district:"21", Block:"290", label:"PUMS G.C. KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5575", district:"5", Block:"98", label:"PUMS UYAKONDARAVI", Sch_type:"Government", Category:"Middle School"},
    { key:"5576", district:"13", Block:"78", label:"SHANTHI NIKETHAN MHSS, S.MUDUGANAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5577", district:"34", Block:"249", label:"PUMS KURUVIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5578", district:"16", Block:"389", label:"PUMS THIRD STREET, VEDARANIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5579", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL,  GOVINDAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5580", district:"12", Block:"112", label:"MPL PASUPATHIPALAYAM MID SCHOOL", Sch_type:"Government", Category:"Middle School"},
    { key:"5581", district:"36", Block:"84", label:"PUMS CHENNARAYANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5582", district:"15", Block:"284", label:"ST ANTONYS MS NATHAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5583", district:"34", Block:"358", label:"PUMS-PORKUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5584", district:"36", Block:"113", label:"MDMS BALAYANKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5585", district:"5", Block:"233", label:"PUMS PUNJAIMAHATHUVAZHKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5586", district:"30", Block:"222", label:"Oxfo Matriculation School", Sch_type:"Un-aided", Category:"High School"},
    { key:"5587", district:"31", Block:"103", label:"PUMS (SSA), KODIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5588", district:"19", Block:"326", label:"ST.SEBASTIAR MATRIC SCHOOL, KATTUBAVAPALLIVASAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5589", district:"5", Block:"233", label:"PUMS KOVILAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5590", district:"34", Block:"44", label:"PUMS VALAYAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5591", district:"36", Block:"243", label:"PUMS PERIYA DHAMAL CHERUVU", Sch_type:"Government", Category:"Middle School"},
    { key:"5592", district:"15", Block:"178", label:"PUMS KESINGAN", Sch_type:"Government", Category:"Middle School"},
    { key:"5593", district:"13", Block:"122", label:"PUMS J KARUPALLI TELUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"5594", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERIYASALATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5595", district:"13", Block:"376", label:"PUMS KADAVANI", Sch_type:"Government", Category:"Middle School"},
    { key:"5596", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL ARUNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5597", district:"6", Block:"235", label:"PUMS GIDDANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5598", district:"13", Block:"122", label:"PUMS KELAMANGALAM URDU", Sch_type:"Government", Category:"Middle School"},
    { key:"5599", district:"6", Block:"198", label:"PUMS MERKATHIYANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5600", district:"31", Block:"362", label:"AMS RCM KOVILUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5601", district:"34", Block:"387", label:"PUMS - THENNANGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5602", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL, ERAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"5603", district:"13", Block:"78", label:"SRI SATHYA SAI BALAGURUKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"5604", district:"5", Block:"120", label:"AMS THANDESWARANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5605", district:"15", Block:"178", label:"PUMS VAKKARAMARY", Sch_type:"Government", Category:"Middle School"},
    { key:"5606", district:"5", Block:"98", label:"PUMS SATHAPPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5607", district:"15", Block:"178", label:"PUMS NIDUR NEIVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5608", district:"6", Block:"235", label:"PUMS PUDUR SOLAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5609", district:"15", Block:"151", label:"PUMS KILIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5610", district:"11", Block:"149", label:"GMS SARAVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5611", district:"25", Block:"330", label:"PUMS-MUTTAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5612", district:"6", Block:"198", label:"PUMS GETTUPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5613", district:"13", Block:"78", label:"BETHEL MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5614", district:"16", Block:"196", label:"MMS KADAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5615", district:"5", Block:"199", label:"PUMS T.PUDAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5616", district:"5", Block:"143", label:"PUMS VADAKKUMANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5617", district:"30", Block:"9", label:"ST. THERASA MS - VKPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5618", district:"35", Block:"382", label:"PUMS MANAKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5619", district:"35", Block:"193", label:"PUMS PETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5620", district:"5", Block:"115", label:"PUMS M.ADHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5621", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE  SCHOOL , POOSERI", Sch_type:"Government", Category:"Middle School"},
    { key:"5622", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - U.C CHAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5623", district:"13", Block:"398", label:"PUMS THIMMASANDIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5624", district:"15", Block:"284", label:"PUMS SAMATHUVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5625", district:"31", Block:"103", label:"PUMS, NARAVINTHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5626", district:"30", Block:"221", label:"THIRU THAYUMANASUNDARAM MS, SIVANTHIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5627", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  VADAMARUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5628", district:"15", Block:"151", label:"GANAPATHY NATIONAL AIDED MIDDLE SCHOOL, KUTHALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5629", district:"6", Block:"198", label:"PUMS SOLIYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5630", district:"17", Block:"254", label:"PUMS MIN  ANNANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5631", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL , PORUVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5632", district:"34", Block:"125", label:"PUMS-ZAMEEN AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5633", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL MANAMTHAVIZHNTHAPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5634", district:"2", Block:"299", label:"Jeppiaar Matriculation Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5635", district:"11", Block:"341", label:"GMS. ITTAGAVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"5636", district:"24", Block:"119", label:"TDTA St.Paul's MS ADAIKALAPATTANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5637", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, THETHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5638", district:"2", Block:"299", label:"St. JOHN'S  MATRIC. HR.SEC.SCHOOL, DURGA NAGAR,TAMBARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5639", district:"34", Block:"249", label:"PUMS KATTIPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5640", district:"33", Block:"184", label:"PUMS,CHINNAKAVANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5641", district:"4", Block:"156", label:"SRI.P.MALLAIYAN MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5642", district:"20", Block:"265", label:"V.K.RAMAIAH MIDDLE SCHOOL, KAVANAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5643", district:"1", Block:"286", label:"ST.THERESA MATRICULATION  HIGHER SECONDARY SCHOOL SENDURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5644", district:"3", Block:"62", label:"CMS RAMA STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"5645", district:"17", Block:"201", label:"NAMAKKAL KONGU MATRIC HIGHER SECONDARY SCHOOL , KEERAMBUR , NAMAKKAL DT -637207", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5646", district:"30", Block:"46", label:"CHITHRA MATRICULATION  SCHOOL CHERANMAHADEVI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5647", district:"14", Block:"160", label:"ST.JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL, OLD KUYAVARPALAYAM ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5648", district:"16", Block:"389", label:"PUMS, POOSARIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"5649", district:"3", Block:"240", label:"MERCURY MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5650", district:"11", Block:"124", label:"SACRED HEART MHS  VIRICODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"5651", district:"5", Block:"39", label:"PUMS ESWARAN KOIL ST", Sch_type:"Government", Category:"Middle School"},
    { key:"5652", district:"6", Block:"218", label:"JAI SAKTHI MHSS, KARTHARAPATTI, PALACODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5653", district:"12", Block:"142", label:"ST. DOMINIC SAVIO MAT. SCHOOL, KULITHALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5654", district:"29", Block:"171", label:"ST.FRANCIS MATRIC. HR. SEC. SCHOOL, UKT MALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5655", district:"3", Block:"270", label:"OUR LADYS CENTRE MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5656", district:"3", Block:"259", label:"MARY IMMACULATE MS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5657", district:"3", Block:"195", label:"CMS DAMODARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5658", district:"3", Block:"240", label:"CMS MH ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"5659", district:"3", Block:"2", label:"RBI STAFF QUARTERS SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5660", district:"25", Block:"144", label:"PATTU MATRIC HSS SAKKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5661", district:"1", Block:"81", label:"PERIYAR MATRICULATION  HSS JAYANKONDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5662", district:"11", Block:"191", label:"ST. THOMAS M.S.C. MIDDLE SCHOOL MANKUZHI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5663", district:"2", Block:"329", label:"ADWMS Melakottaiyur", Sch_type:"Government", Category:"Middle School"},
    { key:"5664", district:"3", Block:"195", label:"ST FRANCIS XAVIER'S MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5665", district:"5", Block:"17", label:"PUMS CHITARASUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5666", district:"21", Block:"290", label:"UNITY MATRIC HSS, PANDIYANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5667", district:"20", Block:"188", label:"THE CRESCENT MATRICULATION HIGHER SECONDARY  SCHOOL, KOLUNDURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5668", district:"4", Block:"242", label:"MGM MATRICULATION HR SEC SCHOOL, K.N.G.PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5669", district:"34", Block:"44", label:"SRI RAMAKRISHNA MATRIC HSS CHENGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5670", district:"26", Block:"135", label:"ALPHA G.K. MATRICULATION HIGHER SECONDARY SCHOOL ARAVENU KOTAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5671", district:"12", Block:"140", label:"PUMS, MAHADANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5672", district:"33", Block:"252", label:"CHENNAI PUBLIC SCHOOL THIRUMAZHISAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5673", district:"13", Block:"139", label:"BHARAT MATRIC HSS, KRISHNAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5674", district:"15", Block:"131", label:"ARULMOZHI AMS THANDAVANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5675", district:"7", Block:"36", label:"JEYASEELAN MEM MAT  BATLAGUNDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5676", district:"28", Block:"257", label:"PUMS LAKSHMIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5677", district:"29", Block:"152", label:"PENI-EL MAT.HSS,ANGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5678", district:"36", Block:"12", label:"HOLY ANGELS MHSS PALLIKONDA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5679", district:"2", Block:"1", label:"PAMELA MARTINEZ PATHWAY MAT. SCHOOL, AGILI VILLAGE.", Sch_type:"Un-aided", Category:"High School"},
    { key:"5680", district:"2", Block:"329", label:"ST JOSEPH'S MATRICULATION SCHOOL,MELAKOTTAIYUR-600127", Sch_type:"Un-aided", Category:"High School"},
    { key:"5681", district:"30", Block:"262", label:"ST.ALOYSIUS MS SOUNDARAPANDIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5682", district:"12", Block:"140", label:"HOLY MADONA MATRIC HR.SEC.SCHOOL ,LALAPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5683", district:"2", Block:"300", label:"N.S.N Matric Hr.Sec,School Chrompet", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5684", district:"14", Block:"303", label:"LORD VENKATESHWARA MATRICULATION  HIGHER SECONDARY  SCHOOL, T KALLUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5685", district:"29", Block:"317", label:"SOWDAMBIKAA  MAT. HR. SEC. SCHOOL. THATHIENGARPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5686", district:"17", Block:"232", label:"PUMS PILLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5687", district:"34", Block:"365", label:"PUMS KOTANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5688", district:"30", Block:"9", label:"PUMS - PAPANASAM, LOWER DAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5689", district:"25", Block:"144", label:"PUMS - THENAMPADUGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5690", district:"22", Block:"30", label:"SARASWATHI MHSS, AMMAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5691", district:"30", Block:"361", label:"Our Own Modern Mat. HSS, Pettai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5692", district:"10", Block:"377", label:"SERVASEV VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, KUNNAVAKKAM-603107", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5693", district:"15", Block:"178", label:"MOTHER TERESA MATRICULATION SCHOOL, MAYILADUTHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5694", district:"3", Block:"62", label:"LITTLE FLOWER CONVENT - DEAF HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"5695", district:"25", Block:"216", label:"PUMS-SAMAYANKUDIKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"5696", district:"28", Block:"217", label:"TNDTA MIDDLE SCHOOL,VEPPANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5697", district:"5", Block:"148", label:"ANANDHAN MEMORIAL MATRIC HR SEC  SCHOOL -VANNIYARPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5698", district:"2", Block:"162", label:"DR V.GENGUSAMY NAIDU MS", Sch_type:"Un-aided", Category:"High School"},
    { key:"5699", district:"28", Block:"350", label:"ISRAEL MEMORIAL MATRIC SCHOOL KATTALANKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5700", district:"12", Block:"142", label:"R.C. AMALARAKINI MID. SCHOOL, KULITHALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5701", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL VENMANIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5702", district:"5", Block:"17", label:"PUMS MUTHUKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5703", district:"2", Block:"114", label:"SHRI NATESAN VIDYASALA MATRICULATION HIGHER SECONDARY SCHOOL, MANNIVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5704", district:"19", Block:"251", label:"SRI AMBAL MATRICULATION SCHOOL, KOPPANAPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5705", district:"25", Block:"228", label:"Tansri Ubaidulla Matriculation Higher Secondary School, Rajagiri", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5706", district:"29", Block:"366", label:"SRI SANKARA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5707", district:"25", Block:"228", label:"KASSIMIA DAWOOD MS - RAJAGIRI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5708", district:"5", Block:"199", label:"PUMS PENNADAM SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5709", district:"31", Block:"208", label:"AL-HUDA MATRIC HSS, VANIYAMBADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5710", district:"12", Block:"142", label:"PUMS, SATHIYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5711", district:"35", Block:"193", label:"PUMS,KEELAPANDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5712", district:"4", Block:"245", label:"PUMS METTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5713", district:"32", Block:"250", label:"ADW MS KANDIYANKOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"5714", district:"3", Block:"237", label:"SINGARAM PILLAI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5715", district:"30", Block:"92", label:"TDTA MS MANJUVILAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5716", district:"16", Block:"123", label:"PUMS MOHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5717", district:"17", Block:"165", label:"MAHENDRA MATRICULATION HIGHER SECONDARY SCHOOL,KALIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5718", district:"13", Block:"122", label:"PUMS U.PURAM TAM/TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"5719", district:"3", Block:"304", label:"JG HINDU VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5720", district:"15", Block:"178", label:"KALAIVANI MATRICULATION SCHOOL, KADALANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5721", district:"23", Block:"106", label:"PUMS THIRUPPAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5722", district:"30", Block:"9", label:"STC BRANCH MS-AMBASAMUDRAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5723", district:"16", Block:"123", label:"PUMS IRUKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5724", district:"13", Block:"291", label:"Er.PERUMAL MANIMEKALAI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5725", district:"25", Block:"228", label:"ANJUMAN MATRIC HIGHER SECONDARY SCHOOL- AYYAMPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5726", district:"11", Block:"341", label:"SMR NATIONAL SCHOOL, PULIERRANGY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5727", district:"2", Block:"300", label:"ZION MAT HSS SELAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5728", district:"34", Block:"312", label:"PUMS- THONDAMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5729", district:"5", Block:"54", label:"MUNICIPAL MIDDLE SCHOOL - BROOKEESPET", Sch_type:"Government", Category:"Middle School"},
    { key:"5730", district:"5", Block:"98", label:"AMS VILAKKAPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5731", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL SATHIYAKANDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5732", district:"36", Block:"74", label:"PUMS SYNAKUNDA", Sch_type:"Government", Category:"Middle School"},
    { key:"5733", district:"2", Block:"300", label:"Sitadevi Garodia Hindu Vidyalaya M.H.S.School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5734", district:"16", Block:"325", label:"PUMS PUTHAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5735", district:"23", Block:"56", label:"VAIRAM GROUP MATRIC HSS, DEVAKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5736", district:"3", Block:"259", label:"CSI MS TANA ST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5737", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL  ERIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5738", district:"3", Block:"237", label:"KALIGI  RANGANATHAN  MONTFORD MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5739", district:"7", Block:"60", label:"ACHYUTA ACADEMY MATRIC HIGHER SECONDARY SCHOOL, DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5740", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL VENGAMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5741", district:"2", Block:"51", label:"GVR MATRIC HR. SEC. SCHOOL - KADAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5742", district:"36", Block:"105", label:"VDS MATRIC HSS, EDAYANSATHU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5743", district:"2", Block:"51", label:"KVS MATRICULATION HIGHER SECONDARY SCHOOL, KADAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5744", district:"38", Block:"295", label:"S.N.M.F. MATRICULATION HR SEC SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5745", district:"22", Block:"90", label:"NEW INDIA MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5746", district:"3", Block:"69", label:"ST JOSEPH MAT HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5747", district:"29", Block:"176", label:"PUMS,PILLUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5748", district:"3", Block:"240", label:"SRI LGG MAT.HR.SEC  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5749", district:"6", Block:"77", label:"P.D.R.VELLACHIAMMAL MHSS, SEKKAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5750", district:"28", Block:"351", label:"BELL MATRIC HR. SEC. SCHOOL TOOVEYPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5751", district:"2", Block:"299", label:"ZION MATRICULATION HIGHER SECONDARY  SCHOOL,  SEMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5752", district:"27", Block:"241", label:"HAMEEDIA MS -  THENKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5753", district:"34", Block:"395", label:"VIDYA VIKAS MATRIC HR SEC SCHOOL MORANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5754", district:"5", Block:"98", label:"PUMS AMMERIKOKKANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5755", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL SEMBALAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5756", district:"5", Block:"199", label:"PUMS A.MARUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5757", district:"11", Block:"191", label:"GOVT.M.S-UTHACHIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5758", district:"14", Block:"161", label:"CHRIST THE KING MATRIC HR SEC SCHOOL, VILANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5759", district:"4", Block:"349", label:"PUMS ARUMUGAGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5760", district:"25", Block:"314", label:" SRI SAVITRI VID. MAT. HS-KARANTHAI-THANJAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5761", district:"24", Block:"91", label:"MUNICIPAL MS PETTAI ,KADAYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5762", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - SANGOLIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5763", district:"3", Block:"69", label:"NETHAJI MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5764", district:"2", Block:"299", label:"AALFA MATRIC HR SEC SCHOOL PEERKANKARANAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5765", district:"21", Block:"408", label:"SHANTHINIKETHAN MATRICULATION SCHOOL  ARCOT", Sch_type:"Un-aided", Category:"High School"},
    { key:"5766", district:"35", Block:"193", label:"PUMS,EDAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5767", district:"12", Block:"112", label:"RAJA AIDED MIDDLE SCHOOL,VEDICHIPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5768", district:"22", Block:"30", label:"HOLY MOTHER MATRICULATION SCHOOL, KEERIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"5769", district:"16", Block:"325", label:"PUMS KOTTARAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5770", district:"12", Block:"112", label:"MPL UMAIYAL MID SCHOOL, KARUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5771", district:"37", Block:"214", label:"THAI TAMIL MIDDLE SCHOOL ROSANI TINDIVANAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5772", district:"32", Block:"32", label:"PUMS SAMATHUVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5773", district:"16", Block:"123", label:"PUMS THIRUKKANNANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5774", district:"3", Block:"304", label:"SEVENTH DAY ADVENTIST MATRIC HIGH SCHOOL ,KODAMBAKKAM CHENNAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5775", district:"35", Block:"193", label:"PUMS KALAIKKARANVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"5776", district:"28", Block:"402", label:"PUMS, Kamalapuram", Sch_type:"Government", Category:"Middle School"},
    { key:"5777", district:"34", Block:"365", label:"SRI SARASWATHI VIKAS MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5778", district:"4", Block:"242", label:"PANCHAYAT UNION MIDDLE SCHOOL GOVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5779", district:"4", Block:"242", label:"PUMS THUDIYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5780", district:"36", Block:"84", label:"PUMS B.N.PALAYAM PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5781", district:"20", Block:"86", label:"HINDU NADAR MIDDLE SCHOOL, KOKKARASANKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5782", district:"5", Block:"199", label:"ADWS ILANGIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5783", district:"27", Block:"31", label:"K.S..MIDDLE  SCHOOL - BALASAMUDRAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5784", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL NORTH STREET TIRUKOVILUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5785", district:"16", Block:"389", label:"RAMAMADAM AMS-PERIYAKUTHAGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5786", district:"35", Block:"137", label:"PUMS, BALAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5787", district:"16", Block:"123", label:"PUMS KOHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5788", district:"12", Block:"142", label:"PUMS, BANGALAPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5789", district:"28", Block:"8", label:"ST. XAVIERS MS, SETHUKKUVAITHAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5790", district:"36", Block:"113", label:"SWAMY VIVEKANANDA MATRIC  HR SEC SCHOOL, SERKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5791", district:"28", Block:"8", label:"RC MS SAVERIYARPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5792", district:"34", Block:"358", label:"PUMS VADA ANDAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5793", district:"36", Block:"113", label:"ADWMS Brammapuram", Sch_type:"Government", Category:"Middle School"},
    { key:"5794", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, A.C.V.MANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5795", district:"12", Block:"112", label:"VOC AIDED MIDDLE SCHOOL,KARUR.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5796", district:"14", Block:"324", label:"ARIMA NAMALVAR MATRICLUATION SCHOOL, THIRUMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5797", district:"37", Block:"214", label:"BHARATHI AIDED MIDDLE SCHOOL PANCHALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5798", district:"16", Block:"308", label:"PUMS KOLAPPADU", Sch_type:"Government", Category:"Middle School"},
    { key:"5799", district:"16", Block:"389", label:"PUMS, VELLIKIDANKU", Sch_type:"Government", Category:"Middle School"},
    { key:"5800", district:"2", Block:"299", label:"PROF. S.A. MAT. HR. SEC. SCHOOL ,Semmanchery-119", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5801", district:"16", Block:"325", label:"PUMS-THIRUCHENKATTANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5802", district:"13", Block:"311", label:"PUMS THIMMENATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"5803", district:"17", Block:"360", label:" AVVAI KSR MATRICULATION SCHOOL, TIRUCHENGODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"5804", district:"34", Block:"125", label:"PUMS ARANJI", Sch_type:"Government", Category:"Middle School"},
    { key:"5805", district:"24", Block:"288", label:"GOVERNMENT MIDDLE SCHOOL POOLANKUDIYIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"5806", district:"25", Block:"330", label:"PUMS-KEELMANTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5807", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL CHINNAMAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5808", district:"16", Block:"123", label:"PUMS THEVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5809", district:"5", Block:"199", label:"PUMS VEPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5810", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL PORASAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5811", district:"14", Block:"160", label:"L.V BALU SAMY IYER& SONS M.S", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5812", district:"3", Block:"69", label:"CMS RANGANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5813", district:"5", Block:"233", label:"PUMS THILLAIVIDANGAN", Sch_type:"Government", Category:"Middle School"},
    { key:"5814", district:"5", Block:"199", label:"PUMS ADHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5815", district:"34", Block:"125", label:"PUMS OLAIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5816", district:"24", Block:"89", label:"PUMS RAVANASAMUDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5817", district:"32", Block:"250", label:"PUMS VELLANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5818", district:"13", Block:"291", label:"PUMS BASTHALAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5819", district:"28", Block:"350", label:"HINDU MIDDLE SCHOOL MUDIVAITHANENDAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5820", district:"21", Block:"21", label:"GABRIELS MIDDLE SCHOOL ARAKKONAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5821", district:"29", Block:"176", label:"PUMS,ENDAPULI", Sch_type:"Government", Category:"Middle School"},
    { key:"5822", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, VALLAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5823", district:"36", Block:"393", label:"PUMS SADUPERI", Sch_type:"Government", Category:"Middle School"},
    { key:"5824", district:"33", Block:"403", label:"LITTLE HOLY ANGELS'  MATRICULATION HIGHER SECONDARY SCHOOL ,AYAPPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5825", district:"8", Block:"310", label:"PUMS HASANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5826", district:"31", Block:"155", label:"PUMS NARIYAMBUT", Sch_type:"Government", Category:"Middle School"},
    { key:"5827", district:"6", Block:"187", label:"PUMS MANIYAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5828", district:"28", Block:"297", label:"HAJI MEEYAN ABDUL CADER MS SRIVAIKUNDAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5829", district:"5", Block:"199", label:"PUMS NARASINGAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5830", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, VALINOKKAM, ADANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"5831", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  KADALI", Sch_type:"Government", Category:"Middle School"},
    { key:"5832", district:"6", Block:"235", label:"PUMS KENDAYANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5833", district:"21", Block:"359", label:"YOGI VEMANA MATRIC SCHOOL, THIMIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5834", district:"5", Block:"17", label:"PUMS PAITHAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5835", district:"6", Block:"187", label:"PUMS AMBALAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"5836", district:"11", Block:"354", label:"G.A.D.W.M.S.UDAIYADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5837", district:"29", Block:"343", label:"GOOD SHEPHERD MAT HR SEC SCHOOL , KK NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5838", district:"35", Block:"193", label:"PUMS,ALKATTUVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"5839", district:"20", Block:"332", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHINNAPALAIYARENDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5840", district:"34", Block:"125", label:"PUMS KALLAYEE", Sch_type:"Government", Category:"Middle School"},
    { key:"5841", district:"6", Block:"198", label:"PUMS KAMALANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5842", district:"24", Block:"119", label:"HINDU MS CHENNALTHAPUTHUKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5843", district:"5", Block:"227", label:"MUNICIPAL MIDDLE  SCHOOL MELAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5844", district:"34", Block:"365", label:"PUMS MELCHETTIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5845", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL NADUKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5846", district:"34", Block:"125", label:"PUMS EAST VETTAVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5847", district:"15", Block:"293", label:"TRMCT .MS. KANNIYAGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5848", district:"13", Block:"376", label:"PUMS PERUMALNAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5849", district:"6", Block:"235", label:"PUMS JANGAMAIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5850", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL, A.MANAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5851", district:"34", Block:"312", label:"PUMS VIJAYAPPANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5852", district:"6", Block:"187", label:"PUMS ALAMARATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5853", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KEELAKIDARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5854", district:"35", Block:"193", label:"PUMS,THOLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5855", district:"5", Block:"98", label:"PUMS CHINNAKOTTUMULAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5856", district:"11", Block:"124", label:"GMS THATTANVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5857", district:"13", Block:"35", label:"PUMS PONGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5858", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEELAMUNDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5859", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHINNA ERVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5860", district:"28", Block:"111", label:"ST.LUCIA R.C MIDDLE SCHOOL , SEYDUNGANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5861", district:"35", Block:"209", label:"PUMS, MOOVARKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5862", district:"4", Block:"349", label:"PANCHAYAT UNION MIDDLE SCHOOL  PACHAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5863", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, AYYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5864", district:"17", Block:"360", label:"KALAIMAGAL KALVI NILAYAM MIDDLE SCHOOL SALAPALAYAM WARD 32", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5865", district:"23", Block:"106", label:"PUMS SADAYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5866", district:"20", Block:"86", label:"KSHATRIYA NADAR MIDDLE SCHOOL, KANNIRAJAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5867", district:"35", Block:"205", label:"PUMS, AATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5868", district:"33", Block:"364", label:"CSI MS-AGOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5869", district:"4", Block:"349", label:"PANCHAYAT UNION MIDDLE SCHOOL VADIVELAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5870", district:"34", Block:"44", label:"PUMS KATTAMADUVU", Sch_type:"Government", Category:"Middle School"},
    { key:"5871", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL AYINGUDI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5872", district:"24", Block:"119", label:"HINDU MS-SIVANADANOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5873", district:"34", Block:"365", label:"PUMS MELKACHIRAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5874", district:"30", Block:"46", label:"CHITHRA MATRICULATION HIGHER SECONDARY SCHOOL PATHAMADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5875", district:"4", Block:"107", label:"PUMS,GOBANARI", Sch_type:"Government", Category:"Middle School"},
    { key:"5876", district:"25", Block:"239", label:"PUMS - THULUKKAVIDUTHI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5877", district:"1", Block:"81", label:"ST.SEPASTIAN MIDDLE SCHOOL VIZHAPPALLAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5878", district:"6", Block:"187", label:"PUMS CHINNAMURUKKAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"5879", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE  SCHOOL  POOMARI", Sch_type:"Government", Category:"Middle School"},
    { key:"5880", district:"16", Block:"325", label:"PUMS PAKKAMKOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5881", district:"16", Block:"389", label:"PUMS-THIYAGARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5882", district:"13", Block:"311", label:"PUMS KADAGANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5883", district:"5", Block:"54", label:"AIDED MIDDLE SCHOOL - SACRED HEART - PUHUPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5884", district:"25", Block:"330", label:"AMS-KATHIRAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5885", district:"32", Block:"32", label:"PUMS GANDHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5886", district:"6", Block:"77", label:"PUMS PORUSUMARATHU VALLAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"5887", district:"20", Block:"332", label:"PANCHAYAT UNION MIDDLE SCHOOL, KUTHUKKALVALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"5888", district:"16", Block:"389", label:"PUMS,NEIVILAKKU", Sch_type:"Government", Category:"Middle School"},
    { key:"5889", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  THAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5890", district:"15", Block:"178", label:"PUMS  MULAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5891", district:"3", Block:"304", label:"AMBATTUR LIONS CLUB MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5892", district:"25", Block:"345", label:"PUMS-THIPPAN VIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5893", district:"27", Block:"55", label:"PUMS - SURULIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"5894", district:"12", Block:"142", label:"RENGA AID. MID. SCHOOL, PARALI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5895", district:"31", Block:"5", label:"PUMS, OLD VANIYAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"5896", district:"4", Block:"52", label:"I.C.C. GIRLS AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5897", district:"2", Block:"162", label:"BLUE STAR NURSERY AND PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5898", district:"34", Block:"365", label:"PUMS ADAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5899", district:"12", Block:"142", label:"PUMS, THALIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5900", district:"5", Block:"115", label:"PUMS SHANDAN", Sch_type:"Government", Category:"Middle School"},
    { key:"5901", district:"35", Block:"209", label:"PUMS, SITHAMALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"5902", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, PANDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5903", district:"30", Block:"204", label:"TDTA MS MEENAKSHINATHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5904", district:"13", Block:"311", label:"PUMS ARUPALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"5905", district:"16", Block:"389", label:"ANANTHARASU AMS, MARAIGNAYANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5906", district:"15", Block:"178", label:"AMS SERUTHIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5907", district:"16", Block:"123", label:"PUMS VALLAVINAYAGAN KOTTAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5908", district:"17", Block:"200", label:"PUMS URAMBU", Sch_type:"Government", Category:"Middle School"},
    { key:"5909", district:"34", Block:"318", label:"PUMS  THELLAR ADK", Sch_type:"Government", Category:"Middle School"},
    { key:"5910", district:"32", Block:"371", label:"PUMS PARTHASARATHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5911", district:"28", Block:"217", label:"PUMS,THERKKUVEERA PANDIAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5912", district:"3", Block:"69", label:"CMS CHINNANDIMADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5913", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, AVANATHANKOTTAI WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"5914", district:"4", Block:"349", label:"PUMS PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5915", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL RAJAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5916", district:"34", Block:"125", label:"PUMS GENGANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5917", district:"32", Block:"363", label:"MMS PAPPANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5918", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL MANGALAMPETTAI ( MUSLIM )", Sch_type:"Government", Category:"Middle School"},
    { key:"5919", district:"15", Block:"131", label:"SMS AMS SETHIRUPPU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5920", district:"4", Block:"107", label:"PUMS KENDEPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5921", district:"15", Block:"131", label:"PUMS OTHAVANTHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5922", district:"35", Block:"382", label:"PUMS AVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5923", district:"31", Block:"155", label:"PUMS,KATTUKOLLAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"5924", district:"13", Block:"177", label:"PUMS KOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5925", district:"36", Block:"74", label:"PUMS INDRA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5926", district:"12", Block:"315", label:"SRINIVASA VIDHYALAYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5927", district:"16", Block:"123", label:"PUMS KUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5928", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL, ANAISERI", Sch_type:"Government", Category:"Middle School"},
    { key:"5929", district:"35", Block:"209", label:"PUMS, KARUVAKURICHI COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"5930", district:"13", Block:"122", label:"PUMS KOTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5931", district:"5", Block:"170", label:"ANNAI TERESA MATRICULATION HIGHER SECONDARY SCHOOL , KEELAKALPOONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5932", district:"34", Block:"358", label:"PUMS M.PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5933", district:"4", Block:"349", label:"PUMS BOLUVAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5934", district:"35", Block:"173", label:"PUMS PANDUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5935", district:"32", Block:"250", label:"PUMS E.VADUGAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5936", district:"23", Block:"96", label:"PUMS, PILAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5937", district:"34", Block:"93", label:"ANMMS THENMAHADEVAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5938", district:"25", Block:"345", label:"PERIYAR MATRICULATION HIGH SCHOOL-VETTIKKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"5939", district:"15", Block:"151", label:"PUMS MANGAINALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5940", district:"25", Block:"336", label:"MAHATHMA MATRICULATION SCHOOL, NADUKAVERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5941", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, PIRATHABIRAMANPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5942", district:"35", Block:"209", label:"PUMS, SERUMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5943", district:"32", Block:"392", label:"P.U.M.S,RASATHAVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"5944", district:"28", Block:"8", label:"PUMS PERUMALKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5945", district:"29", Block:"176", label:"PUMS,ALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5946", district:"35", Block:"205", label:"PUMS ,POZHAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5947", district:"5", Block:"115", label:"SARASWATHI AIDED MIDDLE SCHOOL, GUNAMANGALAM.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5948", district:"6", Block:"198", label:"PUMS GANAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"5949", district:"27", Block:"375", label:"ALHIKMAH MATRIC HIGHER SECONDARY SCHOOL - PTR.COLONY,UTHAMAPALAYAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5950", district:"16", Block:"325", label:"GMS VAZHMANGALAM,", Sch_type:"Government", Category:"Middle School"},
    { key:"5951", district:"34", Block:"125", label:"PUMS KATHALAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"5952", district:"5", Block:"17", label:"PUMS SUNDARAVANDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5953", district:"11", Block:"182", label:"SHREE VIDHYADHIRAJA VIDYA VIKAS MATRIC SCHOOL KUZHITHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"5954", district:"31", Block:"155", label:"SEVENTH DAY ADVENTIST MATRIC HSS , SANDRORKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5955", district:"20", Block:"265", label:"JOTHI MIDDLE SCHOOL,  AYNGUDI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5956", district:"21", Block:"21", label:"PUMS VELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5957", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL NATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5958", district:"33", Block:"184", label:"PUMS,PONNERI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5959", district:"34", Block:"93", label:"PUMS PATTIYANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5960", district:"11", Block:"124", label:"CARMEL MATRICULATION SCHOOL - MATHAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"5961", district:"34", Block:"365", label:"PUMS AZHAGANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"5962", district:"4", Block:"52", label:"GOVERNMENT MIDDLE SCHOOL, THIRUMAL STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"5963", district:"5", Block:"39", label:"PUMS PERIYANARKUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5964", district:"33", Block:"260", label:"PUMS, SANMUGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5965", district:"33", Block:"403", label:"SRI RAMA KRISHNA VIDYA MANDIR  MATRICULATION SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"5966", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL VIZHUKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5967", district:"1", Block:"81", label:"GMS-AYUTHAKALAM NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"5968", district:"24", Block:"89", label:"GNANAM MARAVA MS, GOVINDAPERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5969", district:"20", Block:"99", label:"KSHATHRIYA VIDHYA SALA MIDDLE SCHOOL, VALAIYAPOOKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5970", district:"11", Block:"3", label:"AROCKIA ANNAI M.S-AROCKIAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"5971", district:"5", Block:"143", label:"MPLMS MALAIKATTI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"5972", district:"34", Block:"312", label:"PUMS T.VELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5973", district:"5", Block:"143", label:"AMS SIVAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5974", district:"34", Block:"312", label:"PUMS MELPACHAR", Sch_type:"Government", Category:"Middle School"},
    { key:"5975", district:"12", Block:"88", label:"PUMS. D.SEETHAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"5976", district:"34", Block:"93", label:"PUMS KAPPALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"5977", district:"35", Block:"205", label:"PUMS,PAVATTAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"5978", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - KODUKKANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5979", district:"24", Block:"119", label:"TDTA MS-PETHANADARPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5980", district:"27", Block:"241", label:"SEKKILAR MS - THENKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"5981", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - THIRUMANIKUZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"5982", district:"3", Block:"304", label:"LITTLE FLOWER MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"5983", district:"2", Block:"300", label:"D.A.V School Adambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5984", district:"4", Block:"279", label:"C R R MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5985", district:"22", Block:"129", label:"MONTFORT MATRIC HR. SEC. SCHOOL, KOLATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5986", district:"30", Block:"46", label:"PUMS KOONIYOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"5987", district:"13", Block:"122", label:"VAILANKANNI MHSS DENKANIKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5988", district:"4", Block:"156", label:"PMG MATRIC HIGHER SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5989", district:"34", Block:"358", label:"PUMS THURINJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5990", district:"3", Block:"270", label:"St KEVIN'S AI HR. SEC. SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"5991", district:"2", Block:"300", label:"SRI VENKATESWARA VID MATRIC SCHOOL, SELAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"5992", district:"2", Block:"114", label:"SHALOM MAT HSS, VANDALUR-48", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5993", district:"2", Block:"300", label:"P.M.S  MATRICULATION HIGHER SECONDARY SCHOOL  NANGANALLUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5994", district:"14", Block:"161", label:"VELAMMAL MEMORIAL MATRIC HR SEC SCHOOL,THIRUPPALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5995", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, M.KARISALKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5996", district:"34", Block:"238", label:"PUMS ANATHIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"5997", district:"21", Block:"408", label:"BEATTIE MEMORIAL MATRIC SCHOOL, RANIPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"5998", district:"3", Block:"304", label:"SHRI B S MOOTHA SR SEC SCH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"5999", district:"17", Block:"186", label:"LITTLE ANGELS MATRICULATION SCHOOL, ANIYAPURAM, NAMAKKAL.", Sch_type:"Un-aided", Category:"High School"},
    { key:"6000", district:"11", Block:"191", label:"OUR LADY OF MERCY EM  KARUNYAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6001", district:"25", Block:"228", label:"PUMS - ATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6002", district:"33", Block:"260", label:"NATIONAL LOTUS MATRIC HR.SEC SCHOOL,REDHILLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6003", district:"16", Block:"389", label:"PUMS, AYAKKARANPULAM 2", Sch_type:"Government", Category:"Middle School"},
    { key:"6004", district:"14", Block:"324", label:"LINGA MATRIC HIGH SCHOOL, TIRUMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6005", district:"21", Block:"408", label:"ANNAI SARADA MATRIC HSS, ARCOT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6006", district:"36", Block:"394", label:"RAMALINGAM MATRIC SCHOOL, SAIDAPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"6007", district:"25", Block:"228", label:"PUMS - ELANGARKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6008", district:"3", Block:"195", label:"BESANT ARUNDALE SR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6009", district:"13", Block:"78", label:"ST.ANNE'S MAT.SCHOOL BAGALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6010", district:"2", Block:"300", label:"MCC CAMPUS MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6011", district:"17", Block:"224", label:"VIGHYAAN VIKASH MATRICULATION SCHOOL KOMARAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6012", district:"12", Block:"83", label:"CHERAN MATRIC HR SEC SCHOOL, PUNNAMCHATHIRAM, KARUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6013", district:"36", Block:"74", label:"ST.PETERS MATRIC SCHOOL, GUDIYATHAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6014", district:"33", Block:"403", label:"EBENEZER LIVING WORD MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6015", district:"13", Block:"376", label:"SIVA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6016", district:"6", Block:"109", label:"THANAPPA GOUNDER MHSS, KERAKODAHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6017", district:"28", Block:"350", label:"INFANT MATRICULATION HIGHER SECONDARY SCHOOL, KRISHNARAJAPURAM MAIN ROAD, THOOTHUKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6018", district:"3", Block:"368", label:"ST. WILLIAMS AIHS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"6019", district:"2", Block:"300", label:"SACRED HEART MATRIC HIGHER SECONDARY SCHOOL, ANAKAPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6020", district:"3", Block:"69", label:"BCS JAIN MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6021", district:"11", Block:"149", label:"ORIENT MATRIC SCHOOL-COLACHEL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6022", district:"2", Block:"299", label:"ST PIUS MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6023", district:"1", Block:"81", label:"PUMS-PICHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6024", district:"9", Block:"50", label:"JEEVAN MATRICULATION HIGHER SECONDARY SCHOOL  AMMAIYAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6025", district:"6", Block:"187", label:"PUMS VADAGARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6026", district:"35", Block:"209", label:"PUMS, SONAPPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6027", district:"3", Block:"62", label:"RBCCC MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6028", district:"2", Block:"299", label:"Kings MHSS Madipakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6029", district:"25", Block:"287", label:"PUMS -KOLLUKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"6030", district:"13", Block:"291", label:"PUMS BEERPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6031", district:"11", Block:"149", label:"SREE VIVEKANANDA MATRIC HSS, KOOTTUMANGALAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6032", district:"34", Block:"365", label:"KRISHNA VIDYA MANDIR MAT  HR SEC SCHOOL TIRUVANNAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6033", district:"4", Block:"242", label:"PMA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6034", district:"3", Block:"62", label:"ST AUGUSTINE MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6035", district:"8", Block:"65", label:"SRI KRP ACADEMY, CHITTODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"6036", district:"35", Block:"193", label:"PUMS,GOPALASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6037", district:"35", Block:"382", label:"PUMS UTHANI", Sch_type:"Government", Category:"Middle School"},
    { key:"6038", district:"3", Block:"237", label:"DON BOSCO MATRIC . HR. SEC.  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6039", district:"4", Block:"107", label:"SVGV MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6040", district:"5", Block:"143", label:"PUMS KADAVACHERRY", Sch_type:"Government", Category:"Middle School"},
    { key:"6041", district:"35", Block:"193", label:"PUMS,JAMBUVANODAI THARKA", Sch_type:"Government", Category:"Middle School"},
    { key:"6042", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, NAYAKKARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6043", district:"28", Block:"369", label:"APARNA MATRIC. HR.SEC.SCHOOL MURUGESAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6044", district:"5", Block:"406", label:"HINDU AIDED MIDDLE SCHOOL IRUSALAKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6045", district:"14", Block:"306", label:"M.V.M.KALAIVANI MATRIC HR SEC SCHOOL, SHOLAVANDAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6046", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, VALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6047", district:"3", Block:"240", label:"ST ALOYSIUS AI HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"6048", district:"15", Block:"178", label:"VAITHEESWARA MATRICULATION HIGH SCHOOL  ATHUKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6049", district:"33", Block:"403", label:"NALLI KUPPUSWAMY V V KORATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6050", district:"2", Block:"299", label:"MONTFORT MHSS, PERUNGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6051", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, AVATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6052", district:"28", Block:"369", label:"THAYAMMAL MS KULASAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6053", district:"8", Block:"185", label:"P.K.P.SWAMY MATRIC HSS,KALYANIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6054", district:"12", Block:"315", label:"PANCHAYAT.UNION MIDDLE .SCHOOL,RETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6055", district:"36", Block:"12", label:"PUMS VETTUVANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6056", district:"15", Block:"178", label:"P.U.M.S SETHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6057", district:"2", Block:"300", label:"Emmanuel MHSS Pammal", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6058", district:"12", Block:"315", label:"PANCHAYAT.UNION.MIDDLE.SCHOOL,GOVINDHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6059", district:"35", Block:"137", label:"PUMS, KANTHARI", Sch_type:"Government", Category:"Middle School"},
    { key:"6060", district:"20", Block:"197", label:"PANCHAYAT UNION MIDDLE SCHOOL, THALAIYADIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6061", district:"22", Block:"68", label:"SRI DEVI VIDHYALAYA MATRIC HR. SEC. SCHOOL, ANAIYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6062", district:"6", Block:"77", label:"PUMS PARAYAPATTIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6063", district:"3", Block:"69", label:"OUR LADY'S MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6064", district:"24", Block:"388", label:"SM MS, THALAIVANKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6065", district:"10", Block:"101", label:"Cholan Mat.HSS,Sembarambakkam-631552", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6066", district:"23", Block:"272", label:"PUMS A.VELANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6067", district:"17", Block:"224", label:"CSI MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6068", district:"13", Block:"35", label:"C.S.I. MIDDLE SCHOOL, MGR NAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6069", district:"3", Block:"62", label:"DHANAKOTI  MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6070", district:"23", Block:"272", label:"SRI SARATHA VIDHALAYA MATRIC SCHOOL, AMARAVATHIPUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6071", district:"3", Block:"2", label:"THE ASHRAM MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6072", district:"19", Block:"167", label:"SRI JAGADEESHWARA MAT. HR. SEC. SCHOOL, MANAMELKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6073", district:"15", Block:"178", label:"AMS THIRUCHITRAMBALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6074", district:"22", Block:"273", label:"CHINMAYA VIDYALAYA MATRIC HIGH SCHOOL,SOLAMPALLAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6075", district:"3", Block:"368", label:"CMS TRIPLICANE HIGH ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"6076", district:"2", Block:"299", label:"NSN MHSS CHITLAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6077", district:"28", Block:"351", label:"JOHNS MATRIC HS, MILLERPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6078", district:"19", Block:"255", label:"SUDHARSAN VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL ,PUDUKKOTTAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6079", district:"2", Block:"300", label:"SMT RAMKUWAR DEVI FOMRA VIVEKANANDA VIDYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6080", district:"22", Block:"391", label:"SRI VIDYA MANDIR HR. SEC. SCHOOL (MATRIC), STEEL PLANT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6081", district:"2", Block:"114", label:"TMS VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6082", district:"13", Block:"376", label:"PUMS CHINNAKARAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6083", district:"25", Block:"344", label:"GMS - THEPPERUMANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6084", district:"32", Block:"392", label:"A.N.V. VIDHYALAYA  MATRIC  SCHOOL,VELLAKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6085", district:"16", Block:"389", label:"SUBBAIYA AMS, PAYATHAVARANKADU", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6086", district:"35", Block:"209", label:"PUMS, RISHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6087", district:"18", Block:"7", label:"HAYAGREEVA MATRICULATION   HIGHER SECONDARY SCHOOL NAKKASALEM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6088", district:"3", Block:"2", label:"THE BESANT THEOSOPHICAL HR. SEC. SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6089", district:"2", Block:"300", label:"EMMANUEL NURSERY & PRIMARY SCHOOL, ALANDUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6090", district:"7", Block:"59", label:"SRI LAKSHMI VIDYALAYA MATRIC HR.SEC.SCHOOL,THADIKOMBU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6091", district:"35", Block:"193", label:"PUMS,AZADNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"6092", district:"21", Block:"409", label:"SUNBEAM SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6093", district:"16", Block:"389", label:"PUMS, SINGANKUTTHAKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6094", district:"13", Block:"376", label:"CHRIST MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6095", district:"11", Block:"3", label:"St. STELLA'S MATRICULATION HR.SEC.SCHOOL  ANJUGRAMAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6096", district:"24", Block:"6", label:"TDTA MS, Karuvantha", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6097", district:"10", Block:"410", label:"Agathiya Matriculation HSS, Walajabad 631605", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6098", district:"9", Block:"323", label:"VASAVI AIDED  MIDDLE SCHOOL  SOUTH STREET TIRUKOILUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6099", district:"21", Block:"409", label:"SUNBEAM MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6100", district:"3", Block:"69", label:"ARINGAR ANNA MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6101", district:"26", Block:"53", label:"CHRIST THE KING METRICULATION SCHOOL, KOLAKOMABAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6102", district:"36", Block:"394", label:"MMS KAGITHAPATTARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6103", district:"29", Block:"366", label:"AUROBINDO INTERNATIONAL SCHOOL, WORAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6104", district:"3", Block:"237", label:"TAHA CONVENT MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6105", district:"28", Block:"257", label:"SKMS PALLIVASALPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6106", district:"14", Block:"158", label:"P.U.M.S. MELAMADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6107", district:"11", Block:"149", label:"GMS CHETTIYARMADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6108", district:"16", Block:"389", label:"PUMS CHETTIYAKADU ", Sch_type:"Government", Category:"Middle School"},
    { key:"6109", district:"35", Block:"205", label:"PUMS SERUVALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6110", district:"3", Block:"304", label:"BALA VIDHYA MATRIC SCH", Sch_type:"Un-aided", Category:"High School"},
    { key:"6111", district:"13", Block:"35", label:"PUMS MAGADEVAGOLLAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6112", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL MELMALAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6113", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL KADUVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6114", district:"26", Block:"72", label:"ST ANTONYS MAT HSS, RAJAGOPALAPURAM, GUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6115", district:"5", Block:"199", label:"PUMS PENNADAM EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"6116", district:"7", Block:"220", label:"KENNEDY MATRICULATION SCHOOL, PALANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6117", district:"13", Block:"117", label:"GREEN VALLEY  INTERNATIONAL MATRIC HIGHER SECONDARY SCHOOL - PANNANDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6118", district:"30", Block:"385", label:"SRM MIDDLE SCHOOL NAKKANERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6119", district:"16", Block:"389", label:"PUMS GOVIDANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"6120", district:"28", Block:"280", label:"TNDTA MS,PALANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6121", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL,  MELPANAIYOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"6122", district:"15", Block:"178", label:"PUMS - MANAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6123", district:"35", Block:"193", label:"PUMS,NEW STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"6124", district:"30", Block:"385", label:"TDTA MS VALLIAMMAIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6125", district:"2", Block:"114", label:"ANANTHAVALLI MAT HSS, URAPAKKAM-603210", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6126", district:"3", Block:"69", label:"CMS 540 T H ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"6127", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  KADIYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"6128", district:"35", Block:"382", label:"PUMS VADAKKUPATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6129", district:"5", Block:"98", label:"PUMS V.SATHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6130", district:"13", Block:"376", label:"CHRIST MATRIC SCHOOL, SINGARAPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6131", district:"4", Block:"52", label:"ALG MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6132", district:"27", Block:"241", label:"VELLALAR MS -  VADUGAPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6133", district:"28", Block:"297", label:"PUMS SRIVAIKUNTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6134", district:"5", Block:"233", label:"ISHA VIDHYA MATRICULATION HIGHER SECONDARY.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6135", district:"35", Block:"209", label:"PUMS, SEKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6136", district:"12", Block:"112", label:"P.U.MID.SCHOOL,P.PUGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6137", district:"13", Block:"35", label:"PUMS DESUPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6138", district:"3", Block:"62", label:"THE MADRAS SEVA SADAN HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6139", district:"13", Block:"35", label:"PUMS KEEL POONGURTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"6140", district:"23", Block:"96", label:"PUMS, KALLIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6141", district:"30", Block:"46", label:"PUMS PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6142", district:"30", Block:"222", label:"ANGELO MHSS PALAYAMKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6143", district:"2", Block:"300", label:"KAVITHA MHSS, PALLAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6144", district:"2", Block:"114", label:"LITTLE JACKY MAT HSS CHENGALPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6145", district:"12", Block:"112", label:"P.U.MID.SCHOOL,V.PASUPATHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6146", district:"3", Block:"270", label:"BISHOP CORRIE AI HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"6147", district:"21", Block:"210", label:"DR. V.S. ISAAC MATRIC SCHOOL, ATTUPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6148", district:"16", Block:"389", label:"SEETHALAKSHMI AMS, ARAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6149", district:"3", Block:"237", label:"DON BOSCO MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6150", district:"3", Block:"62", label:"CMS VENKATAMMAL SAMATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"6151", district:"12", Block:"112", label:"SRI ANNAI VIDHYALAYA MATRICULATION SCHOOL,VENGAMEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"6152", district:"36", Block:"394", label:"SRI VASAVI MATRIC SCHOOL, VELLORE", Sch_type:"Un-aided", Category:"High School"},
    { key:"6153", district:"2", Block:"329", label:"SRI VANI VID. MAT.HSS, THIRUPORUR-603110", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6154", district:"34", Block:"358", label:"MARY IMMACULATE HEART MATRIC HSS KARIYANDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6155", district:"5", Block:"54", label:"KRISHNASAMY MEMORIAL MATRIC HIGHER SECONDARY SCHOOL,CUDDALORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6156", district:"16", Block:"308", label:"PUMS SANTHANAMTHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"6157", district:"21", Block:"21", label:"PUMS-VEDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6158", district:"19", Block:"18", label:"PSK MATRIC HIGHER SECONDARY SCHOOL, POMMADIMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6159", district:"17", Block:"254", label:"RGR INTERNATIONAL SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"6160", district:"11", Block:"354", label:"Holy Family Middle School, Ettamadai", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6161", district:"34", Block:"48", label:"DR VGN MATRIC HIGH SCHOOL CHEYYAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6162", district:"5", Block:"211", label:"ST. ANTONY'S MATRICULATION HIGHER SECONDARY SCHOOL  BLOCK-29 NEYVELI- TS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6163", district:"24", Block:"6", label:"HINDU THIRU MURUGAN MS PUTHUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6164", district:"35", Block:"209", label:"PUMS,VADAKARAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6165", district:"35", Block:"209", label:"PUMS, AGRAPODAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6166", district:"21", Block:"24", label:"PANCHAYAT UNION MIDDLE SCHOOL, KILMINNAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6167", district:"3", Block:"304", label:"CMS PRAKASAM ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"6168", district:"28", Block:"280", label:"TNDTA MIDDLE SCHOO,KADATCHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6169", district:"30", Block:"204", label:"PU MS PATTARPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6170", district:"37", Block:"214", label:"SRI KAMATCHI VILAS MIDDLE SCHOOL TINDIVANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6171", district:"34", Block:"318", label:"TTMMS DESUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6172", district:"34", Block:"44", label:"PUMS PUDUPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6173", district:"13", Block:"35", label:"PUMS NALLAPPANAYAKKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6174", district:"6", Block:"77", label:"PUMS SUNDAKAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6175", district:"27", Block:"31", label:"PUMS - NARASINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6176", district:"20", Block:"188", label:" PANCHAYAT UNION MIDDLE SCHOOL, VENNEERVAIKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6177", district:"12", Block:"88", label:"PUMS, POONJOLAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6178", district:"4", Block:"245", label:"PUMS KONAVAIKALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6179", district:"35", Block:"137", label:"PUMS, MAHARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6180", district:"11", Block:"124", label:"BARATH MATRICULATION SCHOOL ANANVILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6181", district:"35", Block:"382", label:"PUMS UTTHUKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"6182", district:"6", Block:"187", label:"PUMS KONGARAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6183", district:"35", Block:"137", label:"PUMS, VENGATHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6184", district:"12", Block:"88", label:"PUMS, KAVARAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6185", district:"13", Block:"122", label:"PUMS JAKKERI TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"6186", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL (MUSLIM) , PASAR.", Sch_type:"Government", Category:"Middle School"},
    { key:"6187", district:"5", Block:"143", label:"PANCHAYATH UNION MIDDLE SCHOOL KOLLIMALAI MELPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"6188", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL EDAYANCHAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6189", district:"35", Block:"137", label:"PUMS, VALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6190", district:"36", Block:"12", label:"SRI SAI LAKSHMI MATRIC SCHOOL,  PALLIKONDA", Sch_type:"Un-aided", Category:"High School"},
    { key:"6191", district:"35", Block:"209", label:"PUMS, ARICHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6192", district:"21", Block:"24", label:"SRI JAYENDRA SARASWATHI SWAMIGAL MATRIC SCHOOL,MULLUVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6193", district:"3", Block:"62", label:"CMS 1A MODEL SCHOOL ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"6194", district:"28", Block:"350", label:"SOMASUNDARA MIDDLE SCHOOL VARTHAGAREDDIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6195", district:"25", Block:"11", label:"PUMS - ATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6196", district:"34", Block:"27", label:"PUMS, ARIYAPPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6197", district:"29", Block:"258", label:"PAREE MATRIC  HR. SEC. SCHOOL, VENKATACHALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6198", district:"34", Block:"44", label:"PUMS THAZAIYUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"6199", district:"35", Block:"205", label:"PUMS,INJIGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6200", district:"3", Block:"69", label:"OXFORD MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6201", district:"31", Block:"5", label:"PUMS, PATTHAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6202", district:"14", Block:"160", label:"CORPORATION MIDDLE SCHOOL  SANDAIPETTAI ", Sch_type:"Government", Category:"Middle School"},
    { key:"6203", district:"13", Block:"311", label:"PUMS NOGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6204", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL, PARANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"6205", district:"12", Block:"112", label:"RASAMMAL MATRIC. Hr.Sec.School, VETTAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6206", district:"12", Block:"88", label:"PUMS, MULLIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6207", district:"34", Block:"93", label:"PUMS MELVANNIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6208", district:"16", Block:"123", label:"PUMS VANDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6209", district:"6", Block:"235", label:"PUMS KOVALLIKOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6210", district:"14", Block:"158", label:"P.U.M.S THATCHANENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6211", district:"13", Block:"177", label:"PUMS KUMARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6212", district:"21", Block:"290", label:"PUMS-KUNNATHUR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"6213", district:"25", Block:"336", label:"GMS NADUKAVERI", Sch_type:"Government", Category:"Middle School"},
    { key:"6214", district:"23", Block:"106", label:"PUMS KALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6215", district:"27", Block:"49", label:"PUMS - KAMARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6216", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - SOTHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6217", district:"13", Block:"311", label:"PUMS A.PUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6218", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL , EDAIYANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"6219", district:"36", Block:"243", label:"PUMS CHINNA DHAMAL CHERUVU", Sch_type:"Government", Category:"Middle School"},
    { key:"6220", district:"7", Block:"212", label:"SOURASHTRA MIDDLE SCHOOL ,NILAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6221", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL  ARIYALUR THIRUKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6222", district:"34", Block:"27", label:"PUMS PAZHANGAMUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6223", district:"32", Block:"363", label:"PUMS, AYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6224", district:"19", Block:"255", label:"BHARATH MATRIC. HR.SEC.SCHOOL, THIRUVAPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6225", district:"35", Block:"209", label:"PUMS,THENKARAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6226", district:"36", Block:"74", label:"PUMS GANGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6227", district:"30", Block:"204", label:"GOVT MS KOONTHANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6228", district:"4", Block:"245", label:"SRI VAIDEESWARA VIDYALAYAM AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6229", district:"36", Block:"243", label:"PUMS ODDARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6230", district:"33", Block:"184", label:"PUMS,ARIYANVOYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6231", district:"34", Block:"365", label:"PUMS VENGIKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6232", district:"24", Block:"89", label:"PUMS, LAKSHMIYOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"6233", district:"3", Block:"62", label:"DAV GIRLS SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6234", district:"31", Block:"155", label:"PUMS METTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6235", district:"13", Block:"376", label:"PUMS SINGARAPETTAI URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"6236", district:"36", Block:"113", label:"AMS MSCA Thirupakkuttai", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6237", district:"35", Block:"137", label:"PUMS, PERUGAVALNTHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"6238", district:"16", Block:"389", label:"PUMS, KAILAVANAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6239", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL M.AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6240", district:"27", Block:"319", label:"S.W.P.M. KAMBAR MS - SEELAYAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6241", district:"35", Block:"173", label:"PUMS NEDUVAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6242", district:"6", Block:"187", label:"PUMS SUNKARAHALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"6243", district:"34", Block:"358", label:"PUMS-POYYANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6244", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL NALLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6245", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL JAMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6246", district:"21", Block:"409", label:"LITTLE FLOWER CONVENT MATRIC HSS AMMOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6247", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL KUYAVANKADUVETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6248", district:"35", Block:"209", label:"PUMS, VADAKKU THOPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"6249", district:"3", Block:"259", label:"CMS ARUMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6250", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL PAZHANGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6251", district:"6", Block:"198", label:"PUMS DHANDUKARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6252", district:"4", Block:"52", label:"ST. AGNES GIRLS AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6253", district:"27", Block:"31", label:"KAMMAVAR AIDED MIDDLE SCHOOL -M.SUBBULAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6254", district:"35", Block:"209", label:"PUMS, KAPPALUTAIYAN", Sch_type:"Government", Category:"Middle School"},
    { key:"6255", district:"3", Block:"69", label:"C.S.I MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6256", district:"1", Block:"81", label:"PUMS-UDAIYARPALAYAM NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"6257", district:"26", Block:"135", label:"PUMS, KAIRBETA", Sch_type:"Government", Category:"Middle School"},
    { key:"6258", district:"13", Block:"311", label:"PUMS MALLAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6259", district:"13", Block:"398", label:"PUMS NACHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6260", district:"12", Block:"315", label:"P.U.M.SCHOOL,CHINNAMOOKANAKURUCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"6261", district:"27", Block:"31", label:"PUMS - SITHAIYAGOUNDANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6262", district:"5", Block:"54", label:"ST.MARY'S MATRICULATION HR.SEC.SCHOOL, CUDDALORE-607001.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6263", district:"31", Block:"103", label:"PUMS KALARPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"6264", district:"3", Block:"259", label:"SRI ARANGIAH NAIDU MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6265", district:"3", Block:"240", label:"ST JOSEPH'S AI HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"6266", district:"12", Block:"88", label:"PUMS, KURUMBAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6267", district:"25", Block:"234", label:"PUMS -KOTTAGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6268", district:"15", Block:"131", label:"MOUNT CARMEL MATRICULATION SCHOOL, ERUKKUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6269", district:"17", Block:"201", label:"GMS VAGURAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6270", district:"27", Block:"31", label:"PUMS - AUNDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6271", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL AYANVELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6272", district:"4", Block:"107", label:"BRINDHAVANNA VIDHIALAYA SCHOOL CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"6273", district:"38", Block:"407", label:"SRI VIDHYA MATRICULATION SCHOOL, P.KUMARALINGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6274", district:"5", Block:"233", label:"PUMS PONNANTHITTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6275", district:"6", Block:"187", label:"I.V.L. MHSS, IRUMATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6276", district:"8", Block:"202", label:"P.K. MATRICULATION SCHOOL, SELLIPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6277", district:"2", Block:"299", label:"SHRIRAM MATRICULATION SCHOOL, THIRUNEERMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6278", district:"25", Block:"228", label:"PUMS - PAPANASAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6279", district:"36", Block:"74", label:"PUMS PUTTAVARIPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6280", district:"3", Block:"195", label:"LADY WILLINGTON HIGHER SECONDARY SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6281", district:"13", Block:"376", label:"PUMS JOTHINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"6282", district:"4", Block:"245", label:"PANDIT NEHRU MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6283", district:"34", Block:"365", label:"CARMEL MATRICULATION SCHOOL, TIRUVANNAMALAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6284", district:"36", Block:"113", label:"PUMS Vandranthangal", Sch_type:"Government", Category:"Middle School"},
    { key:"6285", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL KODIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6286", district:"34", Block:"48", label:"INDO AMERICAN  MATRIC HR.SEC.SCHOOL CHEYYAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6287", district:"35", Block:"205", label:"PUMS,THALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6288", district:"11", Block:"355", label:"SREE RAMAKRISHNA MATRIC HR.SEC SCHOOL,PARAICODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6289", district:"22", Block:"68", label:"NATIONAL MATRIC HR. SEC. SCHOOL, ANAIYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6290", district:"11", Block:"263", label:"GNANA VIDYA MANDIR, KEELAKATTUVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6291", district:"15", Block:"151", label:"ST.MICHAELS R.C AIDED MIDDLE SCHOOL, PATTAVARTHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6292", district:"16", Block:"325", label:"PUMS ATHALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6293", district:"11", Block:"191", label:"JOHN BONAL MATRICULATION SCHOOL, MARTHANDANTHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6294", district:"9", Block:"323", label:"AIDED MIDDLE SCHOOL  MEMALUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6295", district:"34", Block:"387", label:"SRI VENKATESWARA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6296", district:"17", Block:"254", label:"PUMS SEVAGOUNDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6297", district:"3", Block:"62", label:"Good Shepherd Matric Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6298", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL SALAVATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"6299", district:"7", Block:"36", label:"MOUNT ZION MATRIC HR.SEC.SCHOOL, BATLAGUNDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6300", district:"29", Block:"172", label:"ST.MARY'S MATRIC HSS SCHOOL,TOLGATE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6301", district:"10", Block:"410", label:"Devi Matriculation School,Vengudi,Walajabad 631605", Sch_type:"Un-aided", Category:"High School"},
    { key:"6302", district:"14", Block:"159", label:"CAPRON HALL GIRLS HR SEC SCHOOL, PONNAGARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"6303", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL EAPPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6304", district:"13", Block:"398", label:"PUMS  BALANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6305", district:"28", Block:"8", label:"TNDTA MS THEMANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6306", district:"13", Block:"291", label:"PUMS MARANDAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6307", district:"16", Block:"325", label:"PUMS - PANDARAVADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6308", district:"35", Block:"134", label:"PUMS KEERANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6309", district:"14", Block:"161", label:"E.B.G MATRIC HR SEC SCHOOL, MOONDRUMAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6310", district:"35", Block:"209", label:"PUMS, SEENIKUDIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"6311", district:"30", Block:"385", label:"PUM SCHOOL ,VALLIYOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"6312", district:"5", Block:"199", label:"PUMS PASIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6313", district:"11", Block:"191", label:"ST.JUDE'S MATRICULATION SCHOOL-CHINNATHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6314", district:"13", Block:"291", label:"PUMS KADHIREPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6315", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, PARATHUR SAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6316", district:"31", Block:"82", label:"ST.CHARLES MATRIC HSS, ATHANAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6317", district:"15", Block:"178", label:"PANCHAYAT UNION MIDDLE SCHOOL   MANNAMPANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6318", district:"2", Block:"300", label:"VKK Ammaniammal Mat.HS, Pammal", Sch_type:"Un-aided", Category:"High School"},
    { key:"6319", district:"1", Block:"16", label:"SHRI SOWBAKYA MATRICULATION  HIGHER . SECONDARY SCHOOL ANDIMADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6320", district:"4", Block:"126", label:"SWAMY CHIDBHAVANANDA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6321", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL SEMBAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"6322", district:"5", Block:"211", label:"JAWAHAR HIGHER SECONDARY SCHOOL, BLOCK-17 NEYVELI -TS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6323", district:"11", Block:"355", label:"ST JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL, MULAGUMOODU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6324", district:"35", Block:"193", label:"PUMS,KOVILUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6325", district:"31", Block:"103", label:"HOLY CROSS MATRIC HSS, AATHIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6326", district:"3", Block:"69", label:"CMS 13 BUDDHA ST", Sch_type:"Government", Category:"Middle School"},
    { key:"6327", district:"17", Block:"224", label:"SPB MATRIC HSS SPB COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6328", district:"30", Block:"385", label:"HINDU MIDDLE SCHOOL. THIRUMALAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6329", district:"17", Block:"254", label:"R.G.R MATRICULATION HIGHER SECONDARY SCHOOL, PUDUCHATRAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6330", district:"12", Block:"140", label:"PUMS , KRISHNARAYAPURAM (B)", Sch_type:"Government", Category:"Middle School"},
    { key:"6331", district:"29", Block:"15", label:"SREE VAIJAYANTHEE VIDHYALAYA MATRIC HSS SRIRANGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6332", district:"16", Block:"121", label:"ANNAI VAILANKANNI MATRICULATION HIGH SCHOOL,VETTAIKARANIRUPPU", Sch_type:"Un-aided", Category:"High School"},
    { key:"6333", district:"35", Block:"205", label:"PUMS,ALANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6334", district:"4", Block:"279", label:"THE BRIDGEWOODS PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6335", district:"2", Block:"114", label:"Annai Venankani NPS Nedukundram", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6336", district:"28", Block:"280", label:"RC MS,CHETTIVILAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6337", district:"37", Block:"404", label:"SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL VILUPPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6338", district:"12", Block:"88", label:"PUMS, SERVAIKARANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6339", district:"4", Block:"245", label:"Kasturi Matriculation Hr.Sec.School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6340", district:"3", Block:"69", label:"CMS GPW COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"6341", district:"17", Block:"186", label:"SAROJINI VIDHYALAYA MATRIC HR SEC SCHOOL, KUMARIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6342", district:"5", Block:"199", label:"PUMS SENGAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"6343", district:"1", Block:"81", label:"PUMS-PUDHUCHAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6344", district:"28", Block:"297", label:"PUMS PERUNKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6345", district:"19", Block:"22", label:"NAZREEN MATRIC SCHOOL, RETHINAKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6346", district:"2", Block:"300", label:"New Prince MHSS Adambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6347", district:"12", Block:"140", label:"PUMS MANAVASI", Sch_type:"Government", Category:"Middle School"},
    { key:"6348", district:"33", Block:"260", label:"SRI SANKARA VIDYA KENDRA MATRIC HR.SEC.SCHOOL THIRUVOTTIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6349", district:"35", Block:"193", label:"PUMS,THILLAIVILAGAM-EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"6350", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL  KURINJIPPAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6351", district:"21", Block:"359", label:"PUMS VENKATAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6352", district:"13", Block:"139", label:"NALANDA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6353", district:"17", Block:"285", label:"VETHALOGA VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, SENDAMANGALAM, NAMAKKAL DT -637409", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6354", district:"12", Block:"88", label:"PUMS, KALUTHARIKKAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6355", district:"34", Block:"395", label:"PUMS THANDAPPANTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6356", district:"3", Block:"270", label:"ST.ANNES MATRIC. HIGHER SEC.  SCHOOL,CH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6357", district:"5", Block:"143", label:"IMAM GAZZALI MATRIC HIGHER SECONDARYSCHOOL- LALPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6358", district:"5", Block:"98", label:"PUMS- KEEZHAPALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6359", district:"9", Block:"95", label:"GOVERNMENT MIDDLE SCHOOL SIRUVANGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6360", district:"3", Block:"62", label:"ST ANTONYS MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6361", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, SEVVERI", Sch_type:"Government", Category:"Middle School"},
    { key:"6362", district:"17", Block:"200", label:"SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL, AYILPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6363", district:"5", Block:"143", label:"NIRMALA MATRICULATION HIGHER SECONDARY SCHOOL,CHIDAMBARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6364", district:"35", Block:"340", label:"MMS ,VIJAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6365", district:"2", Block:"299", label:"BHARATH DASS MHSS PERUNGUDI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6366", district:"32", Block:"371", label:"PANCHAYAT UNION MIDDLE SCHOOL DHALI", Sch_type:"Government", Category:"Middle School"},
    { key:"6367", district:"14", Block:"158", label:"AKNU S SUNDAR MATRIC HR SEC SCHOOL, KALIKAPPAN ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6368", district:"36", Block:"113", label:"POORNA VIDHYALAYA MATRIC SCHOOL, THIRUVALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6369", district:"14", Block:"161", label:"BASINGER GARDENS MATRIC SCHOOL, P&T NAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6370", district:"2", Block:"299", label:"VANI MATRIC HSS", Sch_type:"Un-aided", Category:"High School"},
    { key:"6371", district:"12", Block:"23", label:"G.K.VIDYAMANDIR MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6372", district:"15", Block:"131", label:"PUMS KODIYAMPALAIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6373", district:"4", Block:"247", label:"SIWALIK MATRIC.HR.SEC. SCHOOL, POLLACHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6374", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL MADUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6375", district:"24", Block:"388", label:"ANDIYAPPA MS, PULIANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6376", district:"25", Block:"234", label:"PUMS -SANTHANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"6377", district:"13", Block:"291", label:"PUMS MADHARASANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6378", district:"25", Block:"216", label:"PUMS - MANDALAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6379", district:"3", Block:"195", label:"DAV PUBLIC SCHOOL VELACHERY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6380", district:"13", Block:"291", label:"PUMS BEELALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6381", district:"29", Block:"171", label:"SRI KARAPAGA VINAYAGA MATRIC HSS, K.K. NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6382", district:"2", Block:"300", label:"ALANDUR MUNICIPEL MIDDLE SCHOOL ADAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6383", district:"13", Block:"122", label:"NEW GENERATION MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6384", district:"2", Block:"299", label:"A.V.G. VIDYALAYA MATRIC.HIGHER SECONDARY SCHOOL,KOVILAMBAKKAM .", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6385", district:"12", Block:"83", label:"P.U.MID..SCHOOL,SALIYAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6386", district:"12", Block:"112", label:"PON VIDHYA MANDIR CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6387", district:"28", Block:"8", label:"TNDTA Dr.mATHURAM MS, NAZARETH", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6388", district:"11", Block:"182", label:"CHRIST THE KING MATRIC HSS, KANNUMAMOODU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6389", district:"5", Block:"199", label:"PUMS PA. KOTHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6390", district:"2", Block:"329", label:"MURRAY RABINDRA Matriculation SCHOOL, PUTHUPAKKAM-603103", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6391", district:"31", Block:"82", label:"VAANI MATRIC HSS, CHETTIYAPPANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6392", district:"22", Block:"274", label:"SRI VIDYA MANDIR SR. SEC. SCHOOL, SHIVAJI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6393", district:"11", Block:"355", label:"ST JOSEPH'S HSS MULAGUMOODU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"6394", district:"20", Block:"231", label:"SEVENTH DAY ADVENTIST MATRICULATION SCHOOL, PARAMAKUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6395", district:"3", Block:"304", label:"GRT MAHALAKSHMI VID MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6396", district:"35", Block:"173", label:"PUMS.PAMANI", Sch_type:"Government", Category:"Middle School"},
    { key:"6397", district:"4", Block:"247", label:"PANCHAYAT UNION MIDDLE SCHOOL  KOLLUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6398", district:"37", Block:"189", label:"ST.JOHN BOSCO MATRICULATION SCHOOL KANDACHIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6399", district:"17", Block:"285", label:"BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL, KALAPPANAICKENPATTI,NAMAKKAL DT -637404", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6400", district:"21", Block:"409", label:"BRIGHT MINDS VIDYODAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6401", district:"13", Block:"398", label:"PUMS  KURIYANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6402", district:"29", Block:"367", label:"Shakespeare Mat. School, Palakarai.", Sch_type:"Un-aided", Category:"High School"},
    { key:"6403", district:"22", Block:"273", label:"A.R. MATRIC HR.SEC.SCHOOL, PERUMALMALAIADIVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6404", district:"13", Block:"291", label:"PUMS CHENNAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6405", district:"21", Block:"290", label:"SENGUNTHAR AIDED MIDDLE SCHOOL SHOLINGHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6406", district:"3", Block:"195", label:"ST.LAZARUS MIDDLE SCH00L", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6407", district:"31", Block:"208", label:"FATHIMA MATRIC HSS, VANIYAMBADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6408", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL KEELATHENUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6409", district:"13", Block:"177", label:"SHRI VENKATESWARA VID. MHSS MATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6410", district:"27", Block:"31", label:"PUMS - KOVILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6411", district:"14", Block:"160", label:"PREM MATRIC HIGH SCHOOL, TEPPAKKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6412", district:"37", Block:"404", label:"SRI NATARAJA MIDDLE SCHOOL VILLUPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6413", district:"3", Block:"62", label:"MARY CLUB WALA JADHAV GIRLS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6414", district:"3", Block:"304", label:"DR MGR JANAKI MAT SCH", Sch_type:"Un-aided", Category:"High School"},
    { key:"6415", district:"37", Block:"404", label:"SACRED HEART CONVENT ANGLO INDIAN HIGHER SECONDARY SCHOOL  VILLUPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"6416", district:"21", Block:"24", label:"KHAIRE JAREE MS , MELVISHARAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6417", district:"24", Block:"288", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,PULIYARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6418", district:"35", Block:"193", label:"PUMS,UDHAYAMARTHANDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6419", district:"34", Block:"387", label:"PUMS AMMAYAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6420", district:"33", Block:"184", label:"PUMS, LIGHT HOUSE KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6421", district:"28", Block:"351", label:"AUXILIUM MATRIC HR.SEC. SCHOOL THERESPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6422", district:"11", Block:"263", label:"ST JOSEPH HS RAJAKMANGALAMTHURAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"6423", district:"34", Block:"44", label:"ADWMS KAYAMBATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6424", district:"5", Block:"406", label:"FATIMA MATRICULATION HIGHER SECONDARY SCHOOL VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6425", district:"34", Block:"387", label:"PUMS PUNNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6426", district:"13", Block:"398", label:"PUMS  AVALNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6427", district:"3", Block:"259", label:"RAMACHANDRA MATRICULATION HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6428", district:"26", Block:"135", label:"HRM MEMORIAL MATRIC SCHOOL HITTAKAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6429", district:"13", Block:"78", label:"BHARATHIYAR MAT.SCHOOL, BALAJI NAGAR, CHINNAELASAGIRI, HOSUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6430", district:"11", Block:"355", label:"OXFORD MARTIC KUMARAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6431", district:"1", Block:"16", label:"PUMS KEELAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6432", district:"9", Block:"50", label:"BHARATHIDHASAN MATRIC SCHOOL ELIYATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6433", district:"15", Block:"131", label:"PUMS  KADAIKKAN VINAYAGA NALLUR ", Sch_type:"Government", Category:"Middle School"},
    { key:"6434", district:"2", Block:"300", label:"christ king matriculation school", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6435", district:"31", Block:"5", label:"PUMS, VALAYAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6436", district:"28", Block:"8", label:"TNDTA MIDDLE SCHOOL MEERANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6437", district:"28", Block:"320", label:"TNDTA MIDDLE SCHOOL SONAGANVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6438", district:"30", Block:"385", label:"HINDU MIDDLE SCHOOL CHETTIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6439", district:"25", Block:"216", label:"PUMS - PANCHANATHIKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6440", district:"11", Block:"341", label:"EXCEL CENTRAL SCHOOL THIRUVATTAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6441", district:"17", Block:"201", label:"TRINITY ACADEMY MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6442", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL T.V. PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6443", district:"13", Block:"122", label:"PUMS SOOLAKUNTA", Sch_type:"Government", Category:"Middle School"},
    { key:"6444", district:"13", Block:"35", label:"PUMS KULLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6445", district:"13", Block:"35", label:"PUMS ANAKODI", Sch_type:"Government", Category:"Middle School"},
    { key:"6446", district:"32", Block:"392", label:"PUMS SUBRAMANIA GOUNDANVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"6447", district:"13", Block:"376", label:"PUMS MARAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6448", district:"19", Block:"33", label:"ST.JOESEPH'S MID.SCH. AVUDAIYARKOIL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6449", district:"25", Block:"144", label:"PUMS - SENGANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"6450", district:"34", Block:"365", label:"PUMS KANDIYANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6451", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, PACHALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6452", district:"35", Block:"209", label:"AMS,LAKUMIVILAS, NEEDAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6453", district:"35", Block:"334", label:"PUMS CHIDAMBARAKOTAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6454", district:"12", Block:"142", label:"PUMS, THANEERPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6455", district:"12", Block:"315", label:"P.U.M.SCHOOL,KUMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6456", district:"4", Block:"13", label:"MARY MATHA MATRICULATION SCHOOL, GANAPATHY PALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6457", district:"21", Block:"409", label:"PUMS RAJIVGANDHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"6458", district:"34", Block:"249", label:"AUXILIUM MATRIC", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6459", district:"36", Block:"113", label:"PUMS THENPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6460", district:"36", Block:"84", label:"PUMS KAVASAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6461", district:"29", Block:"152", label:"H.C KOTHARI BALAVIHAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6462", district:"6", Block:"198", label:"PUMS MEL BOORUKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6463", district:"5", Block:"143", label:"PUMS SIRAKILANDANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6464", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL, SOGAYANTHOPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"6465", district:"12", Block:"88", label:"PUMS, CHINTHAMANIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6466", district:"13", Block:"291", label:"PUMS B.CHENNASANDIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6467", district:"6", Block:"235", label:"PUMS ANJEHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6468", district:"36", Block:"74", label:"PUMS S MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6469", district:"3", Block:"237", label:"VIVEK VIDYALAYA MID SCH", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6470", district:"33", Block:"184", label:"PUMS,ATHIPATTU REDDYPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6471", district:"13", Block:"117", label:"THIRU ARUT PRAKASHA MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6472", district:"6", Block:"218", label:"PUMS KANSALBELU", Sch_type:"Government", Category:"Middle School"},
    { key:"6473", district:"12", Block:"23", label:"P.U.M.SCHOOL, THIRUMANICKAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6474", district:"12", Block:"88", label:"PUMS, POOSARIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6475", district:"34", Block:"249", label:"PUMS KALVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6476", district:"36", Block:"113", label:"PUMS KATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6477", district:"23", Block:"271", label:"PUMS KUNNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6478", district:"2", Block:"300", label:"Modern senior secondary school", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6479", district:"36", Block:"113", label:"PUMS Eranthangal", Sch_type:"Government", Category:"Middle School"},
    { key:"6480", district:"25", Block:"336", label:"PUMS-SEMMANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6481", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL ELANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"6482", district:"15", Block:"131", label:"PUMS ERUKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6483", district:"3", Block:"62", label:"CMS 3 AP ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"6484", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - EDAIYARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6485", district:"12", Block:"88", label:"ST. XAVIER'S GOVT. AIDED MID.SCHOOL, SINNANDIPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6486", district:"10", Block:"410", label:"RCMMS OTTANTHANGAL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6487", district:"3", Block:"69", label:"CMS CORONATION NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"6488", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERIYALUR WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"6489", district:"13", Block:"35", label:"PUMS MITTAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6490", district:"5", Block:"120", label:"SRI VIJAYA MAT. PALAYANKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6491", district:"6", Block:"235", label:"PUMS SUNJALNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6492", district:"2", Block:"329", label:"PUMS KAYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"6493", district:"30", Block:"229", label:"PANCHAYAT UNION MIDDLE SCHOOL,,ANAINTHANADARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6494", district:"31", Block:"155", label:"PUMS CHINNAPALLIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6495", district:"35", Block:"137", label:"PUMS, PERIYAKURUVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6496", district:"24", Block:"91", label:"WISDOM MATRIC HIGHER SECONDARY SCHOOL,KADAYANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6497", district:"11", Block:"124", label:"GMS MANKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6498", district:"21", Block:"116", label:"PUMS-MAMANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6499", district:"28", Block:"217", label:"TDTA MS, KATCHERITHALAVAIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6500", district:"15", Block:"151", label:"ADW MS THIRUKKULAMBIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6501", district:"12", Block:"23", label:"P.U.M.SCHOOL,MALAIKOVILUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6502", district:"15", Block:"131", label:"PUMS ARPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6503", district:"29", Block:"258", label:"PUMS,ALAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6504", district:"20", Block:"332", label:"NADAR MIDDLE SCHOOL, MAVILANTHOPE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6505", district:"36", Block:"12", label:"PUMS MARUTHAVALLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6506", district:"15", Block:"178", label:"KRISHNA AIDED MIDDLE SCHOOL,ARUVAPPADI.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6507", district:"34", Block:"44", label:"PUMS THEETHANDAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6508", district:"16", Block:"325", label:"PUMS KUTHALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6509", district:"5", Block:"199", label:"PUMS VANNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6510", district:"5", Block:"233", label:"PANCHAYAT UNION MIDDLE SCHOOL - ADHIVARAGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6511", district:"22", Block:"179", label:"SRI SARASWATHI  VIDHYALAYA MATRIC HR SEC SCHOOL,KUTTAPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6512", district:"4", Block:"301", label:"PUMS SELAKKARICHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6513", district:"30", Block:"9", label:"RC MARAVAR MS- VAIRAVIKULAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6514", district:"35", Block:"193", label:"PUMS,MARUTHANGAVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"6515", district:"14", Block:"183", label:"ASIAN MATRIC HR SEC SCHOOL, MELUR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6516", district:"24", Block:"388", label:"T.S.M MS, PULIANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6517", district:"34", Block:"312", label:"PUMS VAZHAVACHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6518", district:"15", Block:"284", label:"PUMS  KUMARAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6519", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL K.THERKUTHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"6520", district:"6", Block:"235", label:"PUMS PANAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6521", district:"3", Block:"195", label:"S.R.Kalyanaraman Memorial P.S Matriculation School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6522", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL ALAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6523", district:"25", Block:"234", label:"PUMS- PAZHANJUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6524", district:"11", Block:"182", label:"GMS PURAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"6525", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL DEVIYANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6526", district:"36", Block:"12", label:"PUMS KUTCHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6527", district:"4", Block:"279", label:"PUMS CHINNAMETTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6528", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL EYAKUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6529", district:"15", Block:"178", label:"PUMS PALAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6530", district:"34", Block:"312", label:"PUMS(MUSLIM) THIRUVADATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6531", district:"27", Block:"49", label:"PUMS - KARICHIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6532", district:"5", Block:"233", label:"PANCHAYAT UNION MIDDLE SCHOOL  ARIYAGOSHTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6533", district:"27", Block:"319", label:"GKMS - BOMMAYAKOUNDANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6534", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL KANTHAKUMARAN", Sch_type:"Government", Category:"Middle School"},
    { key:"6535", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL AVIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6536", district:"12", Block:"23", label:"P.U.M.SCHOOL,SEMBAGANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6537", district:"24", Block:"6", label:"TDTA MS ODAIMARICHAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6538", district:"3", Block:"270", label:"B.SUBBARAYAN MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6539", district:"6", Block:"198", label:"PUMS PAPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6540", district:"31", Block:"155", label:"PUMS KANDRAYANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6541", district:"35", Block:"137", label:"PUMS, PAINGATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6542", district:"15", Block:"293", label:"PUMS ALLIVILAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6543", district:"34", Block:"312", label:"PUMS MELTHIRUVADATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6544", district:"31", Block:"82", label:"PUMS, KATTERI", Sch_type:"Government", Category:"Middle School"},
    { key:"6545", district:"16", Block:"389", label:"GANESA AMS, CHETTIPULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6546", district:"27", Block:"241", label:"NAYAGAM MS - T.VENKATACHALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6547", district:"3", Block:"259", label:"C. M. S PULLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6548", district:"3", Block:"2", label:"A.C MIDDLE SCHOOL GUINDY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6549", district:"9", Block:"347", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL MADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6550", district:"25", Block:"344", label:" PUMS - KALYANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6551", district:"15", Block:"284", label:"PUMS MARUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6552", district:"23", Block:"96", label:"MEYYAPPA MIDDLE SCHOOL,KALLUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6553", district:"6", Block:"198", label:"PUMS B K VALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"6554", district:"13", Block:"376", label:"PUMS KODALAVALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6555", district:"6", Block:"230", label:"PUMS AJJAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6556", district:"13", Block:"177", label:"PUMS MADHAMPATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"6557", district:"12", Block:"88", label:"PUMS, THALIVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6558", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL DEVARADIYARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6559", district:"36", Block:"113", label:"PUMS Kandipedu", Sch_type:"Government", Category:"Middle School"},
    { key:"6560", district:"5", Block:"98", label:"PUMS PAZHAAYAPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6561", district:"6", Block:"198", label:"PUMS SAMICHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6562", district:"13", Block:"376", label:"PUMS KATTANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6563", district:"3", Block:"62", label:"SMT VALLIAMMAL RAJABHADAR MIIDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6564", district:"13", Block:"291", label:"PUMS PUNNAGARAM -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"6565", district:"10", Block:"410", label:"PUMS AYMICHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"6566", district:"12", Block:"142", label:"PUMS, POYYAMANI", Sch_type:"Government", Category:"Middle School"},
    { key:"6567", district:"2", Block:"329", label:"PUMS KOTTAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"6568", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL VENGAIVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6569", district:"13", Block:"177", label:"PUMS OLAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6570", district:"33", Block:"64", label:"PUMS, ATTHANGIKAVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6571", district:"35", Block:"205", label:"PUMS,VADAGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6572", district:"6", Block:"77", label:"PUMS SURANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6573", district:"36", Block:"84", label:"PUMS P.K.PURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6574", district:"5", Block:"199", label:"PUMS ERAPPAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"6575", district:"24", Block:"91", label:"PUDUR NEW  MIDDLE SCHOOL. .KADAYANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6576", district:"34", Block:"358", label:"PUMS UTHIRAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6577", district:"13", Block:"398", label:"PUMS JEDUKOTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6578", district:"35", Block:"205", label:"AMS,THIRUKKOTTARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6579", district:"33", Block:"403", label:"VELAMMAL VIDHIYASHRAM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6580", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL KUPPANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6581", district:"21", Block:"24", label:"PUMS UPPUPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6582", district:"34", Block:"125", label:"MOORTHY MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6583", district:"5", Block:"54", label:"MUNICIPAL MIDDLE SCHOOL - SIMON GARDEN", Sch_type:"Government", Category:"Middle School"},
    { key:"6584", district:"4", Block:"52", label:"NIRMALA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6585", district:"1", Block:"81", label:"MEENAAKSHI RAMASAMY MATRIC HSS THATHANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6586", district:"34", Block:"412", label:"MADRAS MATRIC, KANNAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6587", district:"36", Block:"74", label:"LITTLE FLOWER MATRIC SCHOOL , GUDIYATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6588", district:"13", Block:"35", label:"PUMS PONNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6589", district:"6", Block:"235", label:"PUMS AARALKUNDHI", Sch_type:"Government", Category:"Middle School"},
    { key:"6590", district:"11", Block:"191", label:"LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL ,PUSHPAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6591", district:"12", Block:"142", label:"PUMS, MARUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6592", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  THORAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6593", district:"17", Block:"224", label:"JKK NATTRAJA MATRIC HSS, KUMARAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6594", district:"4", Block:"107", label:"ST.JOSEPH'S MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6595", district:"4", Block:"247", label:"DISHA A LIFE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6596", district:"32", Block:"32", label:"PUMS AVINASHILINGAM PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6597", district:"36", Block:"243", label:"PUMS SARANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6598", district:"3", Block:"240", label:"V.O.C VIDIYALAYAA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6599", district:"12", Block:"88", label:"PUMS, VEERANAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6600", district:"10", Block:"146", label:"Mangadu public school", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6601", district:"13", Block:"35", label:"PUMS CHETTIPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6602", district:"29", Block:"366", label:"DHANALAKSHMI SRINIVASAN MAT. WORAIYUR,TRICHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6603", district:"13", Block:"311", label:"PUMS KOTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6604", district:"26", Block:"72", label:"HOLY CROSS MATRIC HSS DEVARSHOLAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6605", district:"5", Block:"17", label:"PUMS S.K.PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6606", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL SATHIYAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6607", district:"22", Block:"179", label:"MEENAMPARK MATRIC HR. SEC. SCHOOL,MECHERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6608", district:"34", Block:"395", label:"PUMS KALPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6609", district:"29", Block:"176", label:"VIDIYAL MATRIC.HSS,V.KAIKATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6610", district:"25", Block:"144", label:"SRI MATHA MAT HSS KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6611", district:"22", Block:"274", label:"PUSHPA MATRIC SCHOOL,GUGAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6612", district:"5", Block:"170", label:"PUMS , NAVALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6613", district:"2", Block:"299", label:"ZIGMA MHSS, MEDAVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6614", district:"28", Block:"8", label:"PUMS,PALKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6615", district:"5", Block:"54", label:"CK SCHOOL OF PRACTICAL KNOWLEDGE MATRICULATION HIGHER SECONDARY SCHOOL - CUDDALORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6616", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELMANGALAM ", Sch_type:"Government", Category:"Middle School"},
    { key:"6617", district:"22", Block:"274", label:"SRI VASAVI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6618", district:"34", Block:"395", label:"SANGFORD SENIOR SECONDARY SCHOOL ABDULLAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6619", district:"31", Block:"362", label:"YMCA BROWN MEMORIAL MATRIC HSS, TIRUPATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6620", district:"37", Block:"404", label:"JOHN DEWEY MATRIC HIGHER SECONDARY SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6621", district:"11", Block:"149", label:"MARIA RAFOLS MATRIC Hr.Sec. SCHOOL, PUTHOOR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6622", district:"35", Block:"340", label:"PUMS, SEMANGALAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"6623", district:"25", Block:"234", label:"MPLMS- JAYALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6624", district:"3", Block:"368", label:"Y.M.C.A BOYS TOWN HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6625", district:"5", Block:"233", label:"PUMS THACHAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"6626", district:"15", Block:"178", label:"AMS SITHARKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6627", district:"29", Block:"343", label:"SRI RAMAKRISHNA VIDYALAYA MATRIC HIGH SCHOOL, AYYAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6628", district:"3", Block:"259", label:"MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6629", district:"3", Block:"62", label:"SATYA MAT HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6630", district:"21", Block:"21", label:"PUMS-THANDALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6631", district:"5", Block:"115", label:"ARUNACHALAM HINDU AIDED MIDDLE SCHOOL, KANATTAMPULIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6632", district:"28", Block:"217", label:"PUMS,ARAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6633", district:"2", Block:"299", label:"SACRED HEART MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6634", district:"4", Block:"156", label:"KALAIVANI MODEL MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6635", district:"25", Block:"287", label:"PUMS- SAMBAIPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6636", district:"31", Block:"155", label:"T.ABDUL WAHID MATRIC HSS ,AMBUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6637", district:"36", Block:"243", label:"KALAIMAGAL MIDDLE SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6638", district:"12", Block:"83", label:"P.U.MID..SCHOOL, KARUDAIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6639", district:"36", Block:"74", label:"MMS GANGADARASAMY", Sch_type:"Government", Category:"Middle School"},
    { key:"6640", district:"22", Block:"203", label:"KALAIMAGAL MATRIC HR. SEC. SCHOOL,NANGAVALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6641", district:"2", Block:"300", label:"Hindu Religious  Aided MS, Alandur", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6642", district:"23", Block:"94", label:"ROMAN CATHOLIC MIDDLE SCHOOL, SUSAIAPPARPATTANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6643", district:"35", Block:"193", label:"PUMS,PETHAVELANKOTTAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6644", district:"36", Block:"393", label:"VELLORE MATRIC SCHOOL, SATHUVACHARI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6645", district:"29", Block:"15", label:"BRINDAVAN VIDYALAYA  T V KOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6646", district:"35", Block:"340", label:"PUMS ANDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6647", district:"17", Block:"360", label:"VALARAIGATE VIDYALAYAM ", Sch_type:"Un-aided", Category:"High School"},
    { key:"6648", district:"3", Block:"62", label:"CHENNAI MIDDLE SCHOOL - SPECIAL SCHOOL FOR MUSCULAR DISTROPHY", Sch_type:"Government", Category:"Middle School"},
    { key:"6649", district:"35", Block:"193", label:"PUMS,EKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6650", district:"32", Block:"371", label:"U.K.P.M MATRICULATION SCHOOL UDUMALPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"6651", district:"2", Block:"300", label:"Sri Ramakrishna Matric HSS, Tambaram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6652", district:"2", Block:"300", label:"SRI RAMANA VID.MS,WEST TAMBARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6653", district:"22", Block:"163", label:"VINAYAGA  MATRIC HR. SEC. SCHOOL, KANNANTHERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6654", district:"11", Block:"3", label:"THE RAJAS INTERNATIONAL SCHOOL,OZHUGINANASERY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6655", district:"6", Block:"235", label:"PUMS BALINJERAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6656", district:"3", Block:"69", label:"SACRED HEART MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6657", district:"2", Block:"114", label:"Mahindra World School Veerapuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6658", district:"5", Block:"17", label:"MMS MULLIGRAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6659", district:"32", Block:"392", label:"P.U.M.S,THEETHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6660", district:"24", Block:"89", label:"TDTA MS, PULAVANOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6661", district:"22", Block:"278", label:"PSG MATRICULATION HIGHER SECONDARY SCHOOL SANKARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6662", district:"32", Block:"32", label:"PUMS ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6663", district:"31", Block:"82", label:"PUMS, JOLARPET (SOUTH)", Sch_type:"Government", Category:"Middle School"},
    { key:"6664", district:"3", Block:"195", label:"CSI MIDDLE SCHOOL,ADAM STREET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6665", district:"4", Block:"242", label:"AGGARWAL MATRICULATION HR SEC SCHOOL, K.N.G. PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6666", district:"2", Block:"114", label:"ST. MARY'S MATRICULATION HIGHER SECONDARY SCHOOL, CHENGALPATTU-603002", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6667", district:"38", Block:"295", label:"GOOD SHEPHERD MATRICULATION HIGH SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6668", district:"34", Block:"256", label:"SRI SARASWATHI VIDHYALAYA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6669", district:"7", Block:"59", label:"AKSHAYA MATRICULATION HR. SEC. SCHOOL,SELAPPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6670", district:"25", Block:"239", label:"S.E.T. VIDHYADEVI MATRIC HR SEC SCHOOL - ALIVALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6671", district:"34", Block:"365", label:"RAMANA MAHARISHI LOYOLA ACADEMY MATRICULATION HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6672", district:"24", Block:"119", label:"THIRU SUNDARAVEL Middle School-SOUTH POOLANGULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6673", district:"15", Block:"178", label:"PANCHAYATH UNION MIDDLE SCHOOL CHOZHAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6674", district:"34", Block:"93", label:"PUMS MATTAVETTU", Sch_type:"Government", Category:"Middle School"},
    { key:"6675", district:"1", Block:"81", label:"PUMS-SALUPPAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6676", district:"28", Block:"280", label:"TNDTA MS, KANKANIYARPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6677", district:"24", Block:"6", label:"A.V.NAYAGAM MIDDLE SCHOOL, UTHUMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6678", district:"31", Block:"208", label:"O.MOHANRAJ MATRIC SCHOOL, VANIYAMBADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6679", district:"38", Block:"295", label:"SIVAKASI HINDU NADAR VICTORIYA MIDDLE SCHOOL, SIVAKASI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6680", district:"5", Block:"233", label:"THIRUVALLUVAR AMS VILLIYANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6681", district:"2", Block:"300", label:"SIR & LADY  M VENKATASUBBA RAO MHSS, TAMBARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6682", district:"13", Block:"398", label:"CRESCENT MHSS KURUBARAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6683", district:"4", Block:"242", label:"SRI KRISHNA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6684", district:"28", Block:"280", label:"TNDTA MS, THARUVAI WEST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6685", district:"34", Block:"365", label:"PUMS NACHANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"6686", district:"6", Block:"198", label:"PUMS ERRAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6687", district:"35", Block:"340", label:"PUMS THENNAVARAYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6688", district:"25", Block:"344", label:"R.RAJARAMAN  MIDDLE SCHOOL-NARASINGANPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6689", district:"6", Block:"235", label:"PUMS SIDUVAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6690", district:"13", Block:"35", label:"PUMS SUNDAGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6691", district:"4", Block:"349", label:"SRI NARAYANA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6692", district:"10", Block:"101", label:"Sree Narayana Guru MHSS, Big Kanchipuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6693", district:"13", Block:"35", label:"PUMS KARADI GOLLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"6694", district:"3", Block:"259", label:"C.M.S VELLALA STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"6695", district:"3", Block:"195", label:"KUMARA RAJAH MUTHIAH MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6696", district:"35", Block:"382", label:"PUMS,VEDAMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6697", district:"12", Block:"112", label:"P.U.MID.SCHOOL,PERIYAKULATHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6698", district:"31", Block:"208", label:"AR RAHMAN MATRICULATION SCHOOL, KHADERPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"6699", district:"2", Block:"329", label:"SUDDHANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL , AMMAPETTAI.603108", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6700", district:"5", Block:"54", label:"A.R.L.M.MATRICULATION HIGHER SECONDARY SCHOOL, VILVA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6701", district:"21", Block:"210", label:"PUMS, PADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6702", district:"13", Block:"291", label:"PUMS CHEMBARASANAPALLI -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"6703", district:"19", Block:"25", label:"SRI MANICKAM MAT H S SIVAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6704", district:"28", Block:"297", label:"LOABA MATRICULATION HIGH SCHOOL,  ERAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6705", district:"2", Block:"299", label:"VGP  PHILOMINAL  MATRICULATION  SCHOOL , INJAMBAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6706", district:"12", Block:"23", label:"P.U.M.SCHOOL, PALLAPATTI URUSU", Sch_type:"Government", Category:"Middle School"},
    { key:"6707", district:"35", Block:"193", label:"PUMS,KUNNALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6708", district:"3", Block:"237", label:"JOY HIL MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6709", district:"34", Block:"312", label:"PUMS BONDHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6710", district:"6", Block:"235", label:"PUMS POOVANKADU COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"6711", district:"11", Block:"191", label:"ST MATHEWS MS ANNIKKARAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6712", district:"4", Block:"247", label:"PANCHAYAT UNION MIDDLE SCHOOL THIPPAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"6713", district:"23", Block:"79", label:"BHARATHIYAR MATRICULATION SCHOOL SOORANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6714", district:"14", Block:"159", label:"OUR LADY MATRIC HR SEC SCHOOL, PONMENI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6715", district:"25", Block:"234", label:"THE LITTLE BIRD MATRIC-PATTUKKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6716", district:"30", Block:"229", label:"THIRUVALLUVAR  MS SATTUPATTU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6717", district:"16", Block:"325", label:"PUMS VOWALADI", Sch_type:"Government", Category:"Middle School"},
    { key:"6718", district:"4", Block:"13", label:"OXFORD MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6719", district:"21", Block:"290", label:"PUMS-AYIPEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"6720", district:"13", Block:"398", label:"PUMS  BOMMARASANAPALLI (TELUGU)", Sch_type:"Government", Category:"Middle School"},
    { key:"6721", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL KATTALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"6722", district:"12", Block:"112", label:"P.U.MID.SCHOOL,THIRUVALLUVAR", Sch_type:"Government", Category:"Middle School"},
    { key:"6723", district:"3", Block:"62", label:"SHRI KRISHNASWAMY MATRICULATION  HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6724", district:"34", Block:"312", label:"PUMS VARAGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6725", district:"2", Block:"114", label:"PUMS, UNAMANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"6726", district:"28", Block:"217", label:"RC MS PUTHIYAMPUTHUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"6727", district:"4", Block:"245", label:"NISE ACADEMY, POTHANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6728", district:"24", Block:"89", label:"RENGANATHA MS , PAPPANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6729", district:"17", Block:"201", label:"GREEN PARK INTERNATIONAL SCHOOL BODHUPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6730", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL VETTIPERUMALAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6731", district:"28", Block:"350", label:"ST.JOSEPH'S.MATRIC HR.SEC.SCHOOL, MANGALAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6732", district:"13", Block:"35", label:"PUMS MARIMANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"6733", district:"34", Block:"238", label:"SIDDHARTHA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6734", district:"35", Block:"209", label:"PUMS, DEVANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"6735", district:"28", Block:"8", label:"PU MS CAMPBELLABAD", Sch_type:"Government", Category:"Middle School"},
    { key:"6736", district:"30", Block:"229", label:"SARATHA MIDDLE SCHOOL,IDAIKAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6737", district:"6", Block:"187", label:"AMRUTHA MHSS, KAMBAINALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6738", district:"25", Block:"330", label:"PASUPATHI MIDDLE SCHOOL-PANDHANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6739", district:"23", Block:"79", label:"ROMAN CATHOLIC MID SCHOOL KOTTAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6740", district:"11", Block:"263", label:"BHARATH ADVANCED MATRICULATION HSS, KOIYANVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6741", district:"30", Block:"221", label:"RADASAMY PST .V NATHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6742", district:"21", Block:"24", label:"PUMS TAJPURA", Sch_type:"Government", Category:"Middle School"},
    { key:"6743", district:"9", Block:"277", label:"JAYAM MATRIC HIGHER SECONDARY SCHOOL, SANKARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6744", district:"2", Block:"300", label:"MMS URDU PALLAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6745", district:"21", Block:"21", label:"CSI MIDDLE AIDED-PARANJI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6746", district:"35", Block:"334", label:"AMS,ARULANANTHA PALLANKOVIL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6747", district:"33", Block:"338", label:"CSI HIGH SCHOOL, IKKADU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"6748", district:"33", Block:"338", label:"GOVT.HIGH.SCH.KILAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6749", district:"33", Block:"338", label:"GOVT.HR.SEC.SCHOOL,KAKKALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6750", district:"33", Block:"338", label:"GHS,MELAKONDAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"6751", district:"33", Block:"338", label:"GOVT.HIGH.SCHOOL,PAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6752", district:"33", Block:"338", label:"SREE HARI SARADHA VIDYALAYA MATRICULATION SCHOOL , PAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6753", district:"33", Block:"338", label:"MAHAKAVI BHARATHIYAR HSS - KASUVA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6754", district:"33", Block:"338", label:"PUMS, PULLARAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"6755", district:"33", Block:"338", label:"GOVT. WEL (B) HSS, SEVVAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6756", district:"33", Block:"338", label:"GOVT. WEL (G) HSS, SEVVAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6757", district:"33", Block:"338", label:"KURUNJI CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6758", district:"33", Block:"338", label:"ABS VIDHYA MANDHIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6759", district:"33", Block:"338", label:"GOVT.HR.SEC.SCHOOL,VELLIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6760", district:"33", Block:"338", label:"GHS,VEPPAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"6761", district:"33", Block:"338", label:"GHS VILAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6762", district:"33", Block:"338", label:"CSI GOUDIE HR SEC SCH, TRL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6763", district:"33", Block:"338", label:"KMN&BROS MUNICIPAL HIGHER SEC ,SATHYAMURTHY STREET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6764", district:"33", Block:"338", label:"DRBCCCH HR.SEC.SCHOOL,TIRUVALLUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6765", district:"33", Block:"338", label:"MOTHER TERESA MEM SS,PERIYAKUP", Sch_type:"Un-aided", Category:"High School"},
    { key:"6766", district:"33", Block:"338", label:"MUN. HR . SEC SCHOOL, TRL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6767", district:"33", Block:"338", label:"SRI LAKSHMI HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6768", district:"33", Block:"338", label:"GOVT(G)HR.SEC.SCHOOL,TRL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6769", district:"33", Block:"253", label:"GHS,ALLIKUZHI", Sch_type:"Government", Category:"High School"},
    { key:"6770", district:"33", Block:"253", label:"GOVT HSS, SEETHANJERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6771", district:"33", Block:"253", label:"GOVT HIGH SCH, GOONIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"6772", district:"33", Block:"253", label:"CHRIST KING HIGH SCH, KATCHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6773", district:"33", Block:"253", label:"GHS , MAMANDUR", Sch_type:"Government", Category:"High School"},
    { key:"6774", district:"33", Block:"253", label:"GOVT HIGH SCH, MAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6775", district:"33", Block:"253", label:"GOVT HIGHER SEC SCH, MEYYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6776", district:"33", Block:"253", label:"PUMS, MONNAVEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"6777", district:"33", Block:"253", label:"GHS,SATHURANGAPET", Sch_type:"Government", Category:"High School"},
    { key:"6778", district:"33", Block:"253", label:"TELC HR SEC SCH, PANDUR VIL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6779", district:"33", Block:"253", label:"GOVT HIGH SCH, PATTRAI P.PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"6780", district:"33", Block:"253", label:"GOVT HR SEC SCH, PENNALURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6781", district:"33", Block:"253", label:"GOVT HR SEC SCHOOL PONDAVAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6782", district:"33", Block:"253", label:"GOVT HR SEC SCH, POONDI VILLAG", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6783", district:"33", Block:"253", label:"GHS,RAMATHANDALAM", Sch_type:"Government", Category:"High School"},
    { key:"6784", district:"33", Block:"253", label:"GHS, SENDRAYANPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"6785", district:"33", Block:"253", label:"GHS,THIRUPACHUR", Sch_type:"Government", Category:"High School"},
    { key:"6786", district:"33", Block:"253", label:"GHS , BANGARAMPET", Sch_type:"Government", Category:"High School"},
    { key:"6787", district:"33", Block:"253", label:"GHS , THOMUR", Sch_type:"Government", Category:"High School"},
    { key:"6788", district:"33", Block:"87", label:"GHS EGATUR", Sch_type:"Government", Category:"High School"},
    { key:"6789", district:"33", Block:"87", label:"GHS ERAYAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"6790", district:"33", Block:"87", label:"GOVTGHS KADAMBATHUR", Sch_type:"Government", Category:"High School"},
    { key:"6791", district:"33", Block:"87", label:"GOVTHSS KADAMBATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6792", district:"33", Block:"87", label:"ST JOSEPH (B)HSS KILACHERI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6793", district:"33", Block:"87", label:"SACRED HEART GIRLS HR SEC SCHOOL -  KILACHERI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6794", district:"33", Block:"87", label:"GOVT HIGH SCHOOL KOOVAM", Sch_type:"Government", Category:"High School"},
    { key:"6795", district:"33", Block:"87", label:"GOVT.HR.SEC.SCHOOL KOPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6796", district:"33", Block:"87", label:"GOVT HS MELNALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"6797", district:"33", Block:"87", label:"GOVT.GIRLS HR.SEC.SCHOOL PERAMBAKKAM-631402", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6798", district:"33", Block:"87", label:"GOVT.BOYS. HR.SEC.SCHOOL PERAMBAKKAM-631402", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6799", district:"33", Block:"87", label:"GHS SENJI", Sch_type:"Government", Category:"High School"},
    { key:"6800", district:"33", Block:"87", label:"GHS THIRUPANTHIYUR", Sch_type:"Government", Category:"High School"},
    { key:"6801", district:"33", Block:"87", label:"ST.JOSEPH GIRLS HR SEC SCHOOL, PANNUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6802", district:"33", Block:"87", label:"DONBOSCO HSS PANNUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"6803", district:"33", Block:"87", label:"GHS ULUNTHAI", Sch_type:"Government", Category:"High School"},
    { key:"6804", district:"33", Block:"87", label:"GHSS VIDAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6805", district:"33", Block:"87", label:"GHS VAYALUR", Sch_type:"Government", Category:"High School"},
    { key:"6806", district:"33", Block:"87", label:"GGHS MANAVALANAGAR", Sch_type:"Government", Category:"High School"},
    { key:"6807", district:"33", Block:"87", label:"GHSS MANAVALANAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6808", district:"33", Block:"64", label:"GOVT HIGH SCHOOL, ATHIVAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6809", district:"33", Block:"64", label:"GHS, BOOCHIATHIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"6810", district:"33", Block:"64", label:"GOVT HIGHER SECONDARY SCHOOL, GURUVOYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6811", district:"33", Block:"64", label:"GOVT HSS SCHOOL, KANNIGAIPAIR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6812", district:"33", Block:"64", label:"KRUSHNAA VIDHYALAYA NURSERY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6813", district:"33", Block:"64", label:"GOVT HIGH SCHOOL, KOMAKKAMBEDU", Sch_type:"Government", Category:"High School"},
    { key:"6814", district:"33", Block:"64", label:"GOVT HIGH SCHOOL, MALANDUR", Sch_type:"Government", Category:"High School"},
    { key:"6815", district:"33", Block:"64", label:"GOVT HIGH SCHOOL, PALAVAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6816", district:"33", Block:"64", label:"GOVT HSS, PERIYAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6817", district:"33", Block:"64", label:"GOVT HIGH SCHOOL, THIRUKKANDALAM", Sch_type:"Government", Category:"High School"},
    { key:"6818", district:"33", Block:"64", label:"GOVT HIGH SCHOOL, THANDALAM", Sch_type:"Government", Category:"High School"},
    { key:"6819", district:"33", Block:"64", label:"GOVT HIGH SCHOOL, VADAMADURAI", Sch_type:"Government", Category:"High School"},
    { key:"6820", district:"33", Block:"64", label:"GOVT HIGHER SECONDARY SCHOOL, VENGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6821", district:"33", Block:"64", label:"KOTHANDARAMAN HIGH SCHOOL, UTHUKOTTAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"6822", district:"33", Block:"64", label:"GOVT B HSS, UTHUKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6823", district:"33", Block:"64", label:"GOVT G HSS, UTHUKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6824", district:"33", Block:"252", label:"ROUNDTABLE 30 VIVEKANANDA VIDYALAYA ", Sch_type:"Un-aided", Category:"High School"},
    { key:"6825", district:"33", Block:"252", label:"GOVERNMENT HIGH SCHOOL BANAVEDU THOTTAM", Sch_type:"Government", Category:"High School"},
    { key:"6826", district:"33", Block:"252", label:"GOVERNMENT HIGH SCHOOL SEMBARAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6827", district:"33", Block:"252", label:"ST.JOHN'S ACADEMY RESIDENTIAL MATRICULATION HIGHER SECONDARY SCHOOL,PALANJUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6828", district:"33", Block:"252", label:"GOVERNMENT HIGH SCHOOL GUDAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6829", district:"33", Block:"252", label:"LCVKS HIGH SCHOOL KATTUPAKKAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6830", district:"33", Block:"252", label:"ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL KOLAPPANCHERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6831", district:"33", Block:"252", label:"SRI VIDHYA ACADEMY INTERNATIONAL RESIDENCIAL SCHOOL KOLAPPANCHERY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6832", district:"33", Block:"252", label:"GOVERNMENT HIGH SCHOOL KOTTAMEDU", Sch_type:"Government", Category:"High School"},
    { key:"6833", district:"33", Block:"252", label:"MAHATMA GANDHI HIGH SCHOOL UTKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6834", district:"33", Block:"252", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KUTHAMBAKKAM THEN COLONY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6835", district:"33", Block:"252", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KEELMANAMBEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6836", district:"33", Block:"252", label:"GOVERNMENT HIGH SCHOOL NAZARATHPET", Sch_type:"Government", Category:"High School"},
    { key:"6837", district:"33", Block:"252", label:"GOVERNMENT HIGH SCHOOL NEMILICHERY", Sch_type:"Government", Category:"High School"},
    { key:"6838", district:"33", Block:"252", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SENNEERKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6839", district:"33", Block:"252", label:"OXFORD MATRICULATION SCHOOL SENNERKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6840", district:"33", Block:"252", label:"ADI DRIVIDA WELFARE HIGH SCHOOL MELMANAGAR", Sch_type:"Government", Category:"High School"},
    { key:"6841", district:"33", Block:"252", label:"GOVERNMENT HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED POONAMALLEE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6842", district:"33", Block:"252", label:"SAROJINI VARADAPPAN GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6843", district:"33", Block:"252", label:"ARIGNAR ANNA GOVERNMENT HIGHER SECONDARY SCHOOL POONAMALLEE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6844", district:"33", Block:"252", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL POONAMALLEE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6845", district:"33", Block:"252", label:"AVADI MUNICIPAL HIGH SCHOOL KONAMBEDU", Sch_type:"Government", Category:"High School"},
    { key:"6846", district:"33", Block:"252", label:"AVADI MUNICIPAL HIGH SCHOOL AVADI", Sch_type:"Government", Category:"High School"},
    { key:"6847", district:"33", Block:"252", label:"IMMACULATE HEART OF MARY'S GIRLS HIGHER SECONDARY SCHOOL, AVADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6848", district:"33", Block:"252", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KAMARAJ NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6849", district:"33", Block:"252", label:"RCM HSS KAMARAJ NAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6850", district:"33", Block:"252", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KAMARAJ NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6851", district:"33", Block:"252", label:"ANNAI LOURD HSS,PATTABIRAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6852", district:"33", Block:"252", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THANDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6853", district:"33", Block:"252", label:"HOLY INFANT JESUS MATRIC HR SEC SCHOOL PATTABIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6854", district:"33", Block:"252", label:"AVADI MUNICIPAL HIGH SCHOOL PATTABIRAM CHATHRAM", Sch_type:"Government", Category:"High School"},
    { key:"6855", district:"33", Block:"252", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THIRUMAZHISAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6856", district:"33", Block:"252", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THIRUNINRAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6857", district:"33", Block:"252", label:"DASAR HSS, THIRUNINRAVUR.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6858", district:"33", Block:"252", label:"JAYA MATRICULATION SCHOOL, KRISHNAPURAM.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6859", district:"33", Block:"252", label:"GOVERNMENT HIGHER SECONDARY SCHOOL , SUNDARASOZHAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6860", district:"33", Block:"252", label:"RMK CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6861", district:"33", Block:"184", label:"GHS - PAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6862", district:"33", Block:"184", label:"GHS,ANUPPAMBATTU", Sch_type:"Government", Category:"High School"},
    { key:"6863", district:"33", Block:"184", label:"GHS,ATHIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"6864", district:"33", Block:"184", label:"PUMS,ELIAMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"6865", district:"33", Block:"184", label:"GHS - PAZAYANAPPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"6866", district:"33", Block:"184", label:"GOVT HS,MANALI NEW TOWN PHASE 1", Sch_type:"Government", Category:"High School"},
    { key:"6867", district:"33", Block:"184", label:"GOVT HSS,MANALI NEW TOWN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6868", district:"33", Block:"184", label:"INFANT JESUS MAT HSS,LAKSHMI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6869", district:"33", Block:"184", label:"GHS, MANGODU", Sch_type:"Government", Category:"High School"},
    { key:"6870", district:"33", Block:"184", label:"GHS, GOONANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"6871", district:"33", Block:"184", label:"GHS, KATTUPALLI", Sch_type:"Government", Category:"High School"},
    { key:"6872", district:"33", Block:"184", label:"GHS, KOLUR", Sch_type:"Government", Category:"High School"},
    { key:"6873", district:"33", Block:"184", label:"JOSEPH HS, KOTTAIKUPPAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6874", district:"33", Block:"184", label:"GHSS, KATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6875", district:"33", Block:"184", label:"GHS, KANIYAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6876", district:"33", Block:"184", label:"GHSS, MEDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6877", district:"33", Block:"184", label:"ANM HIGH SCHOOL, MELUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6878", district:"33", Block:"184", label:"GHS, NALUR", Sch_type:"Government", Category:"High School"},
    { key:"6879", district:"33", Block:"184", label:"GH GOVT HSS,PERUMBEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6880", district:"33", Block:"184", label:"GHSS,PULICAT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6881", district:"33", Block:"184", label:"ANM MS,PALLIPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"6882", district:"33", Block:"184", label:"GHS, SUBBA REDDY PALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"6883", district:"33", Block:"184", label:"GOVT HIGH SCHOOL  ANNAMALAICHERRY", Sch_type:"Government", Category:"High School"},
    { key:"6884", district:"33", Block:"184", label:"VIDYA MANDIR MATRIC HIGHER SECONDARY SCHOOL,KOKKUMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6885", district:"33", Block:"184", label:"GOVT HSS, THIRUVELLAVOYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6886", district:"33", Block:"184", label:"ADW HS, VALLUR", Sch_type:"Government", Category:"High School"},
    { key:"6887", district:"33", Block:"184", label:"DVS REDDY HSS, MINJUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6888", district:"33", Block:"184", label:"SAHAYAMATHA GIRLS HIGHER SECONDARY SCHOOL, MINJUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6889", district:"33", Block:"184", label:"ADWHS,MINJUR", Sch_type:"Government", Category:"High School"},
    { key:"6890", district:"33", Block:"184", label:"GHS,MINJUR", Sch_type:"Government", Category:"High School"},
    { key:"6891", district:"33", Block:"184", label:"GOVT (B) HSS, PONNERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6892", district:"33", Block:"184", label:"GOVT (G) HSS, PONNERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6893", district:"33", Block:"184", label:"UNITY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"6894", district:"33", Block:"289", label:"GOVT.HSS,NEW ALAMATHI - MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6895", district:"33", Block:"289", label:"GHS, OLD ALAMATHY", Sch_type:"Government", Category:"High School"},
    { key:"6896", district:"33", Block:"289", label:"GOVT.HS ,ANDARKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"6897", district:"33", Block:"289", label:"GHS,ATHUR", Sch_type:"Government", Category:"High School"},
    { key:"6898", district:"33", Block:"289", label:"GOVT HSS,BUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6899", district:"33", Block:"289", label:"GHS, CHINNAMPEDU", Sch_type:"Government", Category:"High School"},
    { key:"6900", district:"33", Block:"289", label:"GHS, NEW ERUMAI VETTIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"6901", district:"33", Block:"289", label:"GHS, JAGANATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"6902", district:"33", Block:"289", label:"GHSS, GNAYERU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6903", district:"33", Block:"289", label:"SHRI GORANTLA RAMALINGAIAH VIVEKANANDA VIDYALAYA THATCHUR KOOTU ROAD, , PANJETTI,, PONNERI TALUK", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6904", district:"33", Block:"289", label:"G.H.S.S,PADIANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6905", district:"33", Block:"289", label:"GOVT HSS(G),SHOLAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6906", district:"33", Block:"289", label:"GOVT HSS (Boys) ,SHOLAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6907", district:"33", Block:"289", label:"GHS, VICHOOR", Sch_type:"Government", Category:"High School"},
    { key:"6908", district:"33", Block:"289", label:"GOVT (G) HSS, ARANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6909", district:"33", Block:"289", label:"GOVT (B)  HSS, ARANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6910", district:"33", Block:"76", label:"GHS ATHUPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6911", district:"33", Block:"76", label:"GOVT HSS ARAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6912", district:"33", Block:"76", label:"ST MARYS HSS ARAMBAKKAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6913", district:"33", Block:"76", label:"GOVT HIGH SCH N M KANDIGAI", Sch_type:"Government", Category:"High School"},
    { key:"6914", district:"33", Block:"76", label:"GOVT HS EGUVARPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"6915", district:"33", Block:"76", label:"GOVT HSS ELAVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6916", district:"33", Block:"76", label:"GHS G R KANDIGAI", Sch_type:"Government", Category:"High School"},
    { key:"6917", district:"33", Block:"76", label:"GOVT HSS A N KANDIGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6918", district:"33", Block:"76", label:"GHSS KAVARAIPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6919", district:"33", Block:"76", label:"R M K RESIDENTAL SCHOOL KAVARAIPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6920", district:"33", Block:"76", label:"GHS OBASAMUTHRAM", Sch_type:"Government", Category:"High School"},
    { key:"6921", district:"33", Block:"76", label:"GHSS NEW GUMMIDIPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6922", district:"33", Block:"76", label:"GHS POOVALAMBEDU", Sch_type:"Government", Category:"High School"},
    { key:"6923", district:"33", Block:"76", label:"GGHSS PATHIRVEDU MADHARPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6924", district:"33", Block:"76", label:"GBHSS PATHIRVEDU MADHARPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6925", district:"33", Block:"76", label:"GHS S R KANDIGAI", Sch_type:"Government", Category:"High School"},
    { key:"6926", district:"33", Block:"76", label:"GHSS SUNNAMBUKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6927", district:"33", Block:"76", label:"GOVT WEL HSS THERVOY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6928", district:"33", Block:"76", label:"ARUL JOTHI MATRICULATION SCHOOL, NEW GUMMIDIPOONDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6929", district:"33", Block:"76", label:"GGHSS GUMMIDIPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6930", district:"33", Block:"76", label:"KLK GBHSS GUMMIDIPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6931", district:"33", Block:"403", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, AYAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6932", district:"33", Block:"403", label:"NATHAN HS, NERKUNDRAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6933", district:"33", Block:"403", label:"GOVT H.S, NERKUNDRAM", Sch_type:"Government", Category:"High School"},
    { key:"6934", district:"33", Block:"403", label:"SRI AYYAPPA N&P, METTUKUPPAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6935", district:"33", Block:"403", label:"DAWN CBSE SCH, NOLAMBUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6936", district:"33", Block:"403", label:"GOVT HS, PALAVEDU", Sch_type:"Government", Category:"High School"},
    { key:"6937", district:"33", Block:"403", label:"GOVT HSS, PAMMADUKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6938", district:"33", Block:"403", label:"GOVT HSS, LAKSHMIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6939", district:"33", Block:"403", label:"GHSS,PANDESWARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6940", district:"33", Block:"403", label:"ADW MS, VELLANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"6941", district:"33", Block:"403", label:"GHS, ARIKKAMPEDU", Sch_type:"Government", Category:"High School"},
    { key:"6942", district:"33", Block:"403", label:"GHS, KOLLUMEDU", Sch_type:"Government", Category:"High School"},
    { key:"6943", district:"33", Block:"403", label:"GHS KARAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"6944", district:"33", Block:"403", label:"GHSS, MITTAMALLEE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6945", district:"33", Block:"403", label:"SIRAGU MONTESSORI  MITTANANALLEE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6946", district:"33", Block:"403", label:"ADW HIGH SCH, MITTAMALLEE", Sch_type:"Government", Category:"High School"},
    { key:"6947", district:"33", Block:"403", label:"KENDRIYA VID OCF, AVADI", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"6948", district:"33", Block:"403", label:"CRPF KV CBSE, AVADI", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"6949", district:"33", Block:"403", label:"GHSS SATHIYAMURTHY NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6950", district:"33", Block:"403", label:"ST. THOMAS MATRICULATION SCHOOL, KOVILPATHAGAI, AVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"6951", district:"33", Block:"403", label:"VELAMMAL MATRICULATION HIGHER SECONDARY, MOGA-EAST", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6952", district:"33", Block:"403", label:"VIJAYANTA HIGHER SEC SCHOOL , AVADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6953", district:"33", Block:"403", label:"MHS,AGRAHARAM", Sch_type:"Government", Category:"High School"},
    { key:"6954", district:"33", Block:"403", label:"VELAMMAL VIDHYASHRAM AMBATTUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6955", district:"33", Block:"403", label:"SRI RAMAKRISHNA MS,AMBATHUR 8", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6956", district:"33", Block:"403", label:"KV AIRFORCE STATION IAF AVADI", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"6957", district:"33", Block:"403", label:"PJ GUPTA HS AMBATTUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6958", district:"33", Block:"403", label:"SRM HSS AMBATTUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6959", district:"33", Block:"403", label:"VALLIAMMAL MAT VENKATAPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"6960", district:"33", Block:"403", label:"GOVT (G) HSS AMBATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6961", district:"33", Block:"403", label:"NATHELLA VIDHYODAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"6962", district:"33", Block:"403", label:"HIGH SCHOOL T V NAGAR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6963", district:"33", Block:"403", label:"HUSSAINY HS HUSSAINABAD", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6964", district:"33", Block:"403", label:"ST JOSEPH MAT H S S AMBATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6965", district:"33", Block:"403", label:"AMBT MUN.HIGH.SCH CHOLOPURAM", Sch_type:"Government", Category:"High School"},
    { key:"6966", district:"33", Block:"403", label:"MUN.HSS KORATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6967", district:"33", Block:"403", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MUGAPAIR WEST - MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6968", district:"33", Block:"403", label:"BARATH MS, MUGAPPAIR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6969", district:"33", Block:"403", label:"BRITTANIA HS, PADI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"6970", district:"33", Block:"403", label:"C.S.I EWART MATRICULATION HIGHER SECONDARY SCHOOL, ANNA NAGAR WEST EXTN,CHENNAI -101", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6971", district:"33", Block:"403", label:"GOVT (B) HIGH, MUGAPAIR EAST", Sch_type:"Government", Category:"High School"},
    { key:"6972", district:"33", Block:"403", label:"GOVT (G) HSS, MUGAPAIR EAST", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6973", district:"33", Block:"403", label:"CHENNAI PUBLIC SCHOOL, SECTOR-1", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6974", district:"33", Block:"403", label:"BETHANY HSS, MUGAPAIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6975", district:"33", Block:"403", label:"RAVINDRA BHARATHI GLOBAL SCHOOL ,MOGAPPAIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6976", district:"33", Block:"403", label:"GHS, KOLADI", Sch_type:"Government", Category:"High School"},
    { key:"6977", district:"33", Block:"403", label:"GOVT HR.SEC.SCH,AYANAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6978", district:"33", Block:"403", label:"DURGAPRASAD VIV.VID, AYANAMPAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6979", district:"33", Block:"403", label:"GHS, VELAPPANCHAVADI", Sch_type:"Government", Category:"High School"},
    { key:"6980", district:"33", Block:"403", label:"SKDJ HSS, THIRUVERKADU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6981", district:"33", Block:"403", label:"GOVT HIGHER SECONDARY SCHOOL, ALAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6982", district:"33", Block:"403", label:"ARULMIGU MEENAKSHI AMMAN HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6983", district:"33", Block:"403", label:"GOVT HSS,MADHURAVOYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6984", district:"33", Block:"403", label:"OUR LADY MATRIC HR SEC SCHOOL ,MADHURAVOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6985", district:"33", Block:"403", label:"GOVT GIRLS HSS,PORUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6986", district:"33", Block:"403", label:"GOVT BOYS HSS,PORUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6987", district:"33", Block:"403", label:"LAMECH HSS,VALASARAVAKKAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"6988", district:"33", Block:"403", label:"SRI VENIYAMMAAL MATRICULATION SCHOOL, RAMAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"6989", district:"33", Block:"403", label:"GOVT HSS,RAMAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6990", district:"33", Block:"403", label:"RAMAKRISHNA VIDHYA NIKETHAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6991", district:"33", Block:"403", label:"GOVT HS VANAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"6992", district:"33", Block:"403", label:"ZAMAR MATRICULATION HR.SEC.SCHOOL, AYAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6993", district:"33", Block:"403", label:"BHAKTAVATSALAM VID,KORATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"6994", district:"33", Block:"403", label:"MUNICIPAL HIGHER SECONDARY SCHOOL,ATHIPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6995", district:"33", Block:"403", label:"AVADI MUNICIPAL HIGHER SECONDARY SCHOOL,THIRUMULLAVOYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"6996", district:"33", Block:"260", label:"GOVERNMENT HIGH SCHOOL, KATHIRVEDU", Sch_type:"Government", Category:"High School"},
    { key:"6997", district:"33", Block:"260", label:"GHS, MATHUR", Sch_type:"Government", Category:"High School"},
    { key:"6998", district:"33", Block:"260", label:"IMMANUVEL MS, SURAPET", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"6999", district:"33", Block:"260", label:"PUMS, KOSAPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7000", district:"33", Block:"260", label:"ADW (B) HSS, VADAKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7001", district:"33", Block:"260", label:"ADW (G) HSS, VADAKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7002", district:"33", Block:"260", label:"GOVT (B) HSS, NARAVARIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7003", district:"33", Block:"260", label:"GOVT (G) HSS, NARAVARIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7004", district:"33", Block:"260", label:"GOVT HSS, MANALI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7005", district:"33", Block:"260", label:" Dr. SIVANTHI ADITHANAR GIRLS HIGHER SECONDARY SCHOOL, MANALI , CHENNAI- 68", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7006", district:"33", Block:"260", label:"J.G.CARODIA GOVT HSS, MADHAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7007", district:"33", Block:"260", label:"ST.ANNE'S GIRLS HR.SEC.SCHOOL,MADHAVARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7008", district:"33", Block:"260", label:"MUN HSS, LAKSHMIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7009", district:"33", Block:"260", label:"POPPILIRAJA GOVT HSS, PUZHAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7010", district:"33", Block:"260", label:"ST. ANTONY'S MATRIC HIGHER SECONDARY SCHOOL, PUZHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7011", district:"33", Block:"260", label:"GOVT HSS, KATHIVAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7012", district:"33", Block:"260", label:"GOVT HIGH SCHOOL, A.S.NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"7013", district:"33", Block:"260", label:"SAKAYA MATHA HS, A.S.NAGAR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7014", district:"33", Block:"260", label:"JAI GOPAL CARODIA HSS THIRUVOTRIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7015", district:"33", Block:"260", label:"THIRUTHANGAL NADAR VID, THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7016", district:"33", Block:"260", label:"SRI MAHAVEER JAIN HIGHER SECONDARY SCHOOL , THIRUVOTRIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7017", district:"33", Block:"260", label:"VELLAIYAN CHETTIAR HSS, THIRUVOTRIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7018", district:"33", Block:"260", label:"SRI V RAMAKRISHNA HSS, THIRUVOTRIYUR WARD 17", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7019", district:"33", Block:"260", label:"OUR LADY HSS, THIRUVOTRIYUR WARD 22", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7020", district:"33", Block:"260", label:"MUN HIGH SCHOOL, KALADIPET", Sch_type:"Government", Category:"High School"},
    { key:"7021", district:"33", Block:"260", label:"VENUS HIGH SCHOOL , THIRUVOTRIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"7022", district:"33", Block:"260", label:"ST PAULS MAHAJ. HSS, KALADIPET", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7023", district:"33", Block:"260", label:"RAMAKRISHNA MATRIC, S, SATHANK", Sch_type:"Un-aided", Category:"High School"},
    { key:"7024", district:"33", Block:"260", label:"GOVT GIRLS HIGH SCHOOL, SATHANGADU", Sch_type:"Government", Category:"High School"},
    { key:"7025", district:"33", Block:"260", label:"FATHER JESUS, THIRUVOTRIYUR WARD 44", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7026", district:"33", Block:"260", label:"SMT MOHINI SARAOGI VIVEKANANDA VIDYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"7027", district:"33", Block:"260", label:"MHS, ERNAVUR", Sch_type:"Government", Category:"High School"},
    { key:"7028", district:"33", Block:"364", label:"GHSS-BUCHIREDDIPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7029", district:"33", Block:"364", label:"GHSS - K.G.KANDIGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7030", district:"33", Block:"364", label:"ST.CHARLES HSS K.G.KANDIGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7031", district:"33", Block:"364", label:"GHSS -MADDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7032", district:"33", Block:"364", label:"GHS - MURUKKAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"7033", district:"33", Block:"364", label:"GHS - ISLAM NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"7034", district:"33", Block:"364", label:"GHS - AMIRTHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7035", district:"33", Block:"364", label:"CSI HIGH SCHOOL - DURAIVARIPET", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7036", district:"33", Block:"364", label:"GOVT(B) HSS-TIRUTTANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7037", district:"33", Block:"364", label:"RADHAKRISHNAN MATRICULATION- TIRUTTANI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7038", district:"33", Block:"364", label:"GOVT(G) HSS-TIRUTTANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7039", district:"33", Block:"337", label:"GOVT HSS, ARCOT KUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7040", district:"33", Block:"337", label:"GHSS, ARUNGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7041", district:"33", Block:"337", label:"GHS, CHIVADA", Sch_type:"Government", Category:"High School"},
    { key:"7042", district:"33", Block:"337", label:"GHSS, KANAGAMMACHATRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7043", district:"33", Block:"337", label:"GHS, KAVERIRAJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7044", district:"33", Block:"337", label:"GHS, MANAVUR", Sch_type:"Government", Category:"High School"},
    { key:"7045", district:"33", Block:"337", label:"GHS, NEMILI", Sch_type:"Government", Category:"High School"},
    { key:"7046", district:"33", Block:"337", label:"GOVT ADW HS, PERIYAKALAKATTUR", Sch_type:"Government", Category:"High School"},
    { key:"7047", district:"33", Block:"337", label:"GHSS, POONIMANGADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7048", district:"33", Block:"337", label:"GHS, THUMBIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"7049", district:"33", Block:"337", label:"GHSS, THIRUVALANGADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7050", district:"33", Block:"337", label:"AISHIKA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7051", district:"33", Block:"225", label:"GHS, KARIMBEDU", Sch_type:"Government", Category:"High School"},
    { key:"7052", district:"33", Block:"225", label:"GOVT HS, KEECHALAM", Sch_type:"Government", Category:"High School"},
    { key:"7053", district:"33", Block:"225", label:"GHS, KESAVARAJAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"7054", district:"33", Block:"225", label:"GOVT (G) HS, ATHIMANJERIPET", Sch_type:"Government", Category:"High School"},
    { key:"7055", district:"33", Block:"225", label:"GOVT (B) HSS, ATHIMANJERIPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7056", district:"33", Block:"225", label:"GHS, SUNDARESAN NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"7057", district:"33", Block:"225", label:"GHS, KONASAMUDRAM", Sch_type:"Government", Category:"High School"},
    { key:"7058", district:"33", Block:"225", label:"GOVTHSS, SORAIKKAIPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7059", district:"33", Block:"225", label:"GOVT HS, NEDIYAM", Sch_type:"Government", Category:"High School"},
    { key:"7060", district:"33", Block:"225", label:"GOVT HS, NOCHILI", Sch_type:"Government", Category:"High School"},
    { key:"7061", district:"33", Block:"225", label:"SH HSS, PANDRAVEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7062", district:"33", Block:"225", label:"GHS, NALLAVANAMPET", Sch_type:"Government", Category:"High School"},
    { key:"7063", district:"33", Block:"225", label:"GHS, BOMMARAJUPET", Sch_type:"Government", Category:"High School"},
    { key:"7064", district:"33", Block:"225", label:"GOVT HS, VELIAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"7065", district:"33", Block:"225", label:"ST MARYS MAT, POTHATURPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"7066", district:"33", Block:"225", label:"GOVT (G) HSS, POTHATURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7067", district:"33", Block:"225", label:"GOVT (B) HSS, POTHATURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7068", district:"33", Block:"225", label:"GOVT BOYS HSS, PALLIPAT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7069", district:"33", Block:"225", label:"GOVT (G) HSS, PALLIPAT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7070", district:"33", Block:"261", label:"A. M. K GOVT (G) HSS,AMMAYARKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7071", district:"33", Block:"261", label:"GHS, KONDAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7072", district:"33", Block:"261", label:"GOVT HS ASWARANTHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7073", district:"33", Block:"261", label:"GOVT HS, AYYANERI", Sch_type:"Government", Category:"High School"},
    { key:"7074", district:"33", Block:"261", label:"GOVTHSS, BALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7075", district:"33", Block:"261", label:"GHS,CHANNURMALLAVARAM", Sch_type:"Government", Category:"High School"},
    { key:"7076", district:"33", Block:"261", label:"SIVARANJINI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7077", district:"33", Block:"261", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,SENGATANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7078", district:"33", Block:"261", label:"GOVT HS,PERIYANAGAPOONDI", Sch_type:"Government", Category:"High School"},
    { key:"7079", district:"33", Block:"261", label:"GOVT (G) HSS, R.K.PET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7080", district:"33", Block:"261", label:"GOVT (B) HSS, R.K.PET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7081", district:"33", Block:"261", label:"GOVT HS,RAJANAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"7082", district:"33", Block:"261", label:"GOVT HSS,SRIKALIKAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7083", district:"33", Block:"261", label:"GOVT HSS,VANGANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7084", district:"33", Block:"261", label:"GOVT HSS,VEDIYANGADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7085", district:"33", Block:"261", label:"GOVT HS,VEERAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"7086", district:"3", Block:"69", label:"SRI GURUMURTHY VIDYALAYA HS", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7087", district:"3", Block:"69", label:"SAVIOUR MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7088", district:"3", Block:"69", label:"F.E.S. Mat. Hr. Sec. School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7089", district:"3", Block:"69", label:"PADMA PRAKASH MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7090", district:"3", Block:"69", label:"ANM MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7091", district:"3", Block:"69", label:"SRI VIVEKANANDA VIDYALAYA MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7092", district:"3", Block:"69", label:"CSI BAIN MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7093", district:"3", Block:"69", label:"DOVETON MATRIC HR SEC SCHOOL, CHINNA KODUNGAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7094", district:"3", Block:"69", label:"CHS ERUKKANCHERY", Sch_type:"Government", Category:"High School"},
    { key:"7095", district:"3", Block:"69", label:"CHENNAI HIGH SCHOOL, KODUNGAIYUR, CHENNAI 118", Sch_type:"Government", Category:"High School"},
    { key:"7096", district:"3", Block:"69", label:"GHS KODUNGAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"7097", district:"3", Block:"69", label:"ST JOSEPH'S HSS  ERUKKANCHERY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7098", district:"3", Block:"69", label:"DON BOSCO MAT SCHOOL TEACHERS COLONY", Sch_type:"Un-aided", Category:"High School"},
    { key:"7099", district:"3", Block:"69", label:"DAVID SONGS MAT SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"7100", district:"3", Block:"69", label:"CHSS PATEL NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7101", district:"3", Block:"69", label:"VALLALAR MAT SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7102", district:"3", Block:"69", label:"DANIEL MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7103", district:"3", Block:"69", label:"CHS CPT COLONY", Sch_type:"Government", Category:"High School"},
    { key:"7104", district:"3", Block:"69", label:"CHS ARATHOON ROAD  NEW MARKET FARM", Sch_type:"Government", Category:"High School"},
    { key:"7105", district:"3", Block:"69", label:"SHEBHA MAT HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7106", district:"3", Block:"69", label:"CHENNAI HIGHER SECONDARY SCHOOL, 731, T.H. ROAD, CH-81.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7107", district:"3", Block:"69", label:"TSS NADARGAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7108", district:"3", Block:"69", label:"SOUNDARAPANDI SUPPAMMA (GIRLS) HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7109", district:"3", Block:"69", label:"MURUGA DHANUSHKODI GIRLS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7110", district:"3", Block:"69", label:"SIR THEAGARAYA COLLEGE HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7111", district:"3", Block:"69", label:"BHAGYAM CHELLIAH MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7112", district:"3", Block:"69", label:"ARUL JOTHI MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7113", district:"3", Block:"69", label:"AXENE EVANGELICAL MISSION HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7114", district:"3", Block:"69", label:"A.E.M. MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7115", district:"3", Block:"69", label:"CHS SEVENWELLS @ HARINARAYANAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7116", district:"3", Block:"69", label:"CGHSS BUDDHA ST", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7117", district:"3", Block:"69", label:"SRI KARPAGAM MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7118", district:"3", Block:"69", label:"SRI MAGESWARA  MATRICULATION  SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7119", district:"3", Block:"69", label:"CUGMS WASHERMENPET", Sch_type:"Government", Category:"Middle School"},
    { key:"7120", district:"3", Block:"270", label:"CMS VINAYAGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7121", district:"3", Block:"270", label:"CMS KUMMALAMMAN KOIL ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7122", district:"3", Block:"270", label:"CMS PUDUMANI KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7123", district:"3", Block:"270", label:"GOVT. CHILDREN HOME FOR BOYS", Sch_type:"Government", Category:"Middle School"},
    { key:"7124", district:"3", Block:"270", label:"SRI SARADHA DEVI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7125", district:"3", Block:"270", label:"CHSS APPASAMY LANE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7126", district:"3", Block:"270", label:"KC SANKARALINGA NADAR HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7127", district:"3", Block:"270", label:"PAK PALANISAMY HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7128", district:"3", Block:"270", label:"SRI. RAMA KRISHNA MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7129", district:"3", Block:"270", label:"CMS DORAI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7130", district:"3", Block:"270", label:"ST THERESA'S GIRLS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7131", district:"3", Block:"270", label:"VALLAL ETTIYAPPA NAICKER HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7132", district:"3", Block:"270", label:"CBHS SN CHETTY ST", Sch_type:"Government", Category:"High School"},
    { key:"7133", district:"3", Block:"270", label:"CSI RAJAGOPAL HS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7134", district:"3", Block:"270", label:"ST PETERS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7135", district:"3", Block:"270", label:"SHREE GK JAIN HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7136", district:"3", Block:"270", label:"ST ANNE'S GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7137", district:"3", Block:"270", label:"KAMARAJ MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"7138", district:"3", Block:"270", label:"KAMALA VINAYAGAR AIDED MID SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7139", district:"3", Block:"270", label:"VALLAL SI ALAGARSAMY HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7140", district:"3", Block:"270", label:"DHANALAKSHMI HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7141", district:"3", Block:"270", label:"CMS SANJEEVIRAYAN KOIL STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7142", district:"3", Block:"270", label:"CHS MANIGANDAN ST", Sch_type:"Government", Category:"High School"},
    { key:"7143", district:"3", Block:"270", label:"C U BOYS HS WASHERMENPET", Sch_type:"Government", Category:"High School"},
    { key:"7144", district:"3", Block:"270", label:"TRI JAGAN MOHAN MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"7145", district:"3", Block:"270", label:"KANNAPPA NAYANAR AIDED MID SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7146", district:"3", Block:"270", label:"ST. ROQUE'S MATRIC SCHOOL.   ", Sch_type:"Un-aided", Category:"High School"},
    { key:"7147", district:"3", Block:"270", label:"C T HS ANJANEYA NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"7148", district:"3", Block:"270", label:"C.M.S.SEVEN WELLS", Sch_type:"Government", Category:"Middle School"},
    { key:"7149", district:"3", Block:"270", label:"GOVT MUSLIM HS", Sch_type:"Government", Category:"High School"},
    { key:"7150", district:"3", Block:"270", label:"ST ANNE'S GIRLS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7151", district:"3", Block:"270", label:"ST.GABRIEL'S HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7152", district:"3", Block:"270", label:"CUGMS BROADWAY", Sch_type:"Government", Category:"Middle School"},
    { key:"7153", district:"3", Block:"270", label:"DOMINIC SAVIO MATRICULATION  SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7154", district:"3", Block:"270", label:"C.M.S.RAMASAMY ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7155", district:"3", Block:"270", label:"C.M.S.SALAI VINAYAGAR KOIL ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7156", district:"3", Block:"270", label:"DR DHARMAMBAL AIDED MID SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7157", district:"3", Block:"270", label:"GOVT MUSLIM GIRLS HS", Sch_type:"Government", Category:"High School"},
    { key:"7158", district:"3", Block:"270", label:"K. RAMIAH CHETTY ARC GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7159", district:"3", Block:"270", label:"ANDERSON RAJAGOPAL GIRLS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7160", district:"3", Block:"270", label:"MSB MATRICULATION HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7161", district:"3", Block:"270", label:"CUGMS ANGAPPA STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7162", district:"3", Block:"270", label:"ST. MARY'S AI HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7163", district:"3", Block:"270", label:"THE MUTHIALPET HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7164", district:"3", Block:"270", label:"ANDERSON DAY GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7165", district:"3", Block:"270", label:"P.NAGAMMA AIDED MID SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7166", district:"3", Block:"270", label:"ST. AGNES AIDED MID SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7167", district:"3", Block:"270", label:"SKPD BOYS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7168", district:"3", Block:"270", label:"KTCT GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7169", district:"3", Block:"270", label:"HAJEE ESSA ABBA SAIT'S HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7170", district:"3", Block:"270", label:"SHREE JTCJ MISSION HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7171", district:"3", Block:"270", label:"PACHAIYAPPAS COLLEGE HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7172", district:"3", Block:"270", label:"CHS CB ROAD", Sch_type:"Government", Category:"High School"},
    { key:"7173", district:"3", Block:"270", label:"C.M.S. PADAVETTAMMAN", Sch_type:"Government", Category:"Middle School"},
    { key:"7174", district:"3", Block:"240", label:"CMS MOORTHINGAR ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7175", district:"3", Block:"240", label:"CHENNAI HIGHER SECONDARY SCHOOL  KALYANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7176", district:"3", Block:"240", label:"DON BOSCO HSS VYASARPADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7177", district:"3", Block:"240", label:"SMT  NDJA VIV VID JR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7178", district:"3", Block:"240", label:"GHSS MKB NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7179", district:"3", Block:"240", label:"CMS NEW KAMARAJ NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"7180", district:"3", Block:"240", label:"CHS GANESAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7181", district:"3", Block:"240", label:"CMS GOKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7182", district:"3", Block:"240", label:"CGHSS MH ROAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7183", district:"3", Block:"240", label:"SKNS PMC VIV VID JR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7184", district:"3", Block:"240", label:"CMS EKANGIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7185", district:"3", Block:"240", label:"CHS COOKS ROAD", Sch_type:"Government", Category:"High School"},
    { key:"7186", district:"3", Block:"240", label:"CMS H&S FACTORY", Sch_type:"Government", Category:"Middle School"},
    { key:"7187", district:"3", Block:"240", label:"ADW BHSS KANNIGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7188", district:"3", Block:"240", label:"ADW GHSS KANNIGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7189", district:"3", Block:"240", label:"CHS AMMAIAMMAL ST", Sch_type:"Government", Category:"High School"},
    { key:"7190", district:"3", Block:"240", label:"CMS DECOSTER ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"7191", district:"3", Block:"240", label:"CUMS PERAMBUR BARRACKS", Sch_type:"Government", Category:"Middle School"},
    { key:"7192", district:"3", Block:"240", label:"CHRISTIAN HS", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7193", district:"3", Block:"240", label:"CHS THIRUVENGADA SAMY STREET", Sch_type:"Government", Category:"High School"},
    { key:"7194", district:"3", Block:"240", label:"ADW HS PULIANTHOPE", Sch_type:"Government", Category:"High School"},
    { key:"7195", district:"3", Block:"240", label:"C.M.S. 130 AMMAN KOIL STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7196", district:"3", Block:"240", label:"THE SAIVITE MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7197", district:"3", Block:"240", label:"SRI RAMAKRISHNA MATH VIVEKANANDHA CENTENARY GIRLS' HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7198", district:"3", Block:"240", label:"DR GURUSWAMY MUDALIAR TTV HR SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7199", district:"3", Block:"240", label:"TTV HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7200", district:"3", Block:"240", label:"GANESH BAI GALADA JAIN GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7201", district:"3", Block:"240", label:"CMS K K LANE", Sch_type:"Government", Category:"Middle School"},
    { key:"7202", district:"3", Block:"240", label:"The Hindu Union Committee HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7203", district:"3", Block:"240", label:"DON BOSCO HS VEPERY", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7204", district:"3", Block:"240", label:"PT LEE Chenglavaraya Naicker HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7205", district:"3", Block:"240", label:"C.M.S. VK PILLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7206", district:"3", Block:"240", label:"C.M.S. 246 MINT STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7207", district:"3", Block:"240", label:"SREE THIRUVOTEESWARAR FREE HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7208", district:"3", Block:"240", label:"ST MATTHIAS' ANGLO INDIAN HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7209", district:"3", Block:"240", label:"GSSV JAIN VIDHYALAYA JUNIOR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7210", district:"3", Block:"240", label:"C.M.S. 87 ANNAPILLAI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7211", district:"3", Block:"240", label:"THE MADRAS PROGRESSIVE UNION HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7212", district:"3", Block:"240", label:"THE HINDU THEOLOGICAL  HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7213", district:"3", Block:"240", label:"CHS WALLTAX ROAD", Sch_type:"Government", Category:"High School"},
    { key:"7214", district:"3", Block:"240", label:"SNCP ARYA SAMAJ HS", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7215", district:"3", Block:"240", label:"MOONGIBAI GOENKA GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7216", district:"3", Block:"240", label:"SHREE AG JAIN HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7217", district:"3", Block:"240", label:"Motilal Fomra Sanatana Dharma Hss", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7218", district:"3", Block:"240", label:"Shree K.Sugunibai Sanatana Dharma Girls HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7219", district:"3", Block:"240", label:"MAHARISHI VIDYA MANDIR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7220", district:"3", Block:"240", label:"SRI BADALCHAND SCC JAIN VID MS", Sch_type:"Un-aided", Category:"High School"},
    { key:"7221", district:"3", Block:"240", label:"SHRI SANATANA DHARMA VIDYALAYA MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7222", district:"3", Block:"240", label:"SRI J T SURANA JAIN VIDYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7223", district:"3", Block:"240", label:"PT MISSION HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7224", district:"3", Block:"240", label:"SMD HINDU HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7225", district:"3", Block:"240", label:"SRI A.B. PAREKH  GUJARATI VIDYAMANDIR HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7226", district:"3", Block:"240", label:"MANILAL M MEHTA GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7227", district:"3", Block:"240", label:"SSV HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7228", district:"3", Block:"240", label:"ZPH GUJ KKM MAT SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7229", district:"3", Block:"240", label:"CHS NP KOIL ST AT SHARMA NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"7230", district:"3", Block:"237", label:"GNANODHAYA GIRLS HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7231", district:"3", Block:"237", label:"DRBCC HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7232", district:"3", Block:"237", label:"GHS BV COLONY", Sch_type:"Government", Category:"High School"},
    { key:"7233", district:"3", Block:"237", label:"ANNAI VAILANKANNI HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7234", district:"3", Block:"237", label:"JAMALIA HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7235", district:"3", Block:"237", label:"CMS KC GARDEN", Sch_type:"Government", Category:"Middle School"},
    { key:"7236", district:"3", Block:"237", label:"MODEL MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7237", district:"3", Block:"237", label:"CMS GOPALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7238", district:"3", Block:"237", label:"SRI BALA VIDYALAYA  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7239", district:"3", Block:"237", label:"ST.MARK'S MATRICULATION SCHOOL SEMBIUM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7240", district:"3", Block:"237", label:"DON BOSCO HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7241", district:"3", Block:"237", label:"CMS MADUMANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"7242", district:"3", Block:"237", label:"CMS VASANTHA GARADEN", Sch_type:"Government", Category:"Middle School"},
    { key:"7243", district:"3", Block:"237", label:"CHSS BUNDER GARDEN ST", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7244", district:"3", Block:"237", label:"LOURDES GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7245", district:"3", Block:"237", label:"JRET GIRLS HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7246", district:"3", Block:"237", label:"C.S.I MID.SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7247", district:"3", Block:"237", label:"RAILWAY COLONY HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7248", district:"3", Block:"237", label:"SRI CHAMPALAL  PAGARIYA JAIN HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7249", district:"3", Block:"237", label:"SDC VIVEKANANDA VIDYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7250", district:"3", Block:"237", label:"CSI PUTHUIR SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7251", district:"3", Block:"237", label:"CMS RANGASAI ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7252", district:"3", Block:"237", label:"CHS SOMAIYA RAJAH ST", Sch_type:"Government", Category:"High School"},
    { key:"7253", district:"3", Block:"237", label:"CGHSS MKT ST", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7254", district:"3", Block:"237", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  GKM COLONY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7255", district:"3", Block:"237", label:"CHSS PALAVAYAL ROAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7256", district:"3", Block:"237", label:"PRCA HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7257", district:"3", Block:"237", label:"KANNADA SANGHA HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7258", district:"3", Block:"237", label:"ICF SILVER JUBILEE MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7259", district:"3", Block:"237", label:"KARUNA SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7260", district:"3", Block:"237", label:"RAILWAY COLONY MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7261", district:"3", Block:"237", label:"DR SALAI GOVINDARAJAN GIRLS MATRIC HIGH SCHOOL, AYANAVARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"7262", district:"3", Block:"237", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7263", district:"3", Block:"237", label:"CHENNAI HIGH SCHOOL NAMMALWARPET CHENNAI-12", Sch_type:"Government", Category:"High School"},
    { key:"7264", district:"3", Block:"237", label:"CMS SUNDARAM STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7265", district:"3", Block:"237", label:"CHSS KOLATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7266", district:"3", Block:"237", label:"SRI BALAJI MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7267", district:"3", Block:"237", label:"ST. JOSEPH OF ANNECY MATRICULATION  SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7268", district:"3", Block:"237", label:"CMS VILLIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7269", district:"3", Block:"237", label:"JOHN GABRIEL MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7270", district:"3", Block:"237", label:"SINGARAM PILLAI BOYS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7271", district:"3", Block:"237", label:"SKDT HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7272", district:"3", Block:"237", label:"GOVT HS VILLIWAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7273", district:"3", Block:"237", label:"GOVT GIRLS HSS VILLIWAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7274", district:"3", Block:"237", label:"JOHN WILLIAMS MATRICULATION SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"7275", district:"3", Block:"237", label:"SINGARAM PILLAI GIRLS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7276", district:"3", Block:"237", label:"OUR  LADY OF VELANGANNI MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7277", district:"3", Block:"259", label:"I.C.F. HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7278", district:"3", Block:"259", label:"W. P. A. SOUNDARAPANDIAN HR. SEC. SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7279", district:"3", Block:"259", label:"NIRMAL HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7280", district:"3", Block:"259", label:"SRI ARANGIAH NAIDU HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7281", district:"3", Block:"259", label:"THE TARAPORE AND LOGANATHAN HR. SEC. SCHOOL FOR GIRLS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7282", district:"3", Block:"259", label:"CSI JESSIE MOSES MAT. HR. SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7283", district:"3", Block:"259", label:"CHINMAYA VIDYALAYA ANNA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7284", district:"3", Block:"259", label:"KENDRIYA VIDYALAYA ANNA NAGAR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"7285", district:"3", Block:"259", label:"JAIVEL MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7286", district:"3", Block:"259", label:"SBOA SCHOOL & JUNIOR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7287", district:"3", Block:"259", label:"Shri Krishnaswamy Matric Hr. Sec. School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7288", district:"3", Block:"259", label:"CHSS KOYAMBEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7289", district:"3", Block:"259", label:"CHSS VIRUGAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7290", district:"3", Block:"259", label:"AV MEIYAPPAN MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7291", district:"3", Block:"259", label:"CHINMAYA VIDYALAYA SR.SEC.SCHOOL ,VIRUGAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7292", district:"3", Block:"259", label:"Smt. M.J.V HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7293", district:"3", Block:"259", label:"CHINMAYA VIDYALAYA HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7294", district:"3", Block:"259", label:"Shri Krishnaswamy Matric Hr. Sec. School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7295", district:"3", Block:"259", label:"THIRU VI KA HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7296", district:"3", Block:"259", label:"C.M.S GUJJI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7297", district:"3", Block:"259", label:"CHENNAI GIRLS HIGHER SECONDARY SCHOOL PULLA AVENUE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7298", district:"3", Block:"259", label:"CMS AMINJIKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7299", district:"3", Block:"259", label:"RAJESWARI MAT HS", Sch_type:"Un-aided", Category:"High School"},
    { key:"7300", district:"3", Block:"259", label:"CHENNAI HIGH SCHOOL KILPAUK ", Sch_type:"Government", Category:"High School"},
    { key:"7301", district:"3", Block:"259", label:"KOLA SARASWATHI VAISHNAV SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7302", district:"3", Block:"259", label:"KASTHURIBAI GANDHI HIGH SCHOOL", Sch_type:"Government", Category:"High School"},
    { key:"7303", district:"3", Block:"259", label:"THE SECRETARIAT COLONY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7304", district:"3", Block:"259", label:"B.V.BHAVAN'S RAJAJI VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7305", district:"3", Block:"259", label:"CHINMAYA VIDYALAYA KILPAUK", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7306", district:"3", Block:"259", label:"SINDHI MODEL SR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7307", district:"3", Block:"259", label:"CHENNAI BOYS HIGHER SECONDARY SCHOOL G.KOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7308", district:"3", Block:"259", label:"BALAR KALVI NILAYAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"7309", district:"3", Block:"259", label:"CSI MS PERUMALPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7310", district:"3", Block:"259", label:"CSI MS SUNDRAM ST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7311", district:"3", Block:"259", label:"ST. PAULS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7312", district:"3", Block:"259", label:"SIR MCTM HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7313", district:"3", Block:"259", label:"LADY M.CT.M GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7314", district:"3", Block:"259", label:"E.L.M.FABRICIUS HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7315", district:"3", Block:"259", label:"TELC MAGDALENE GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7316", district:"3", Block:"259", label:"SHRI B.K.KOTHARI MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7317", district:"3", Block:"259", label:"CHSS  SUBBARAYAN ST", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7318", district:"3", Block:"259", label:"KERALA VIDYALAYAM HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7319", district:"3", Block:"259", label:"ST GEORGE'S AI HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7320", district:"3", Block:"259", label:"SHERWOOD HALL SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7321", district:"3", Block:"259", label:"CHENNAI HIGH SCHOOL MCNICHOLS ROAD ", Sch_type:"Government", Category:"High School"},
    { key:"7322", district:"3", Block:"259", label:"CHS MMDA II", Sch_type:"Government", Category:"High School"},
    { key:"7323", district:"3", Block:"259", label:"KOLA PERUMAL CHETTY VAISHNAV SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7324", district:"3", Block:"259", label:"DANIEL THOMAS GIRLS HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7325", district:"3", Block:"259", label:"CMS MMDA I", Sch_type:"Government", Category:"Middle School"},
    { key:"7326", district:"3", Block:"259", label:"CHS THIRUVALLUVARPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7327", district:"3", Block:"259", label:"CHSS CHOOLAIMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7328", district:"3", Block:"259", label:"L M DHADHA SR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7329", district:"3", Block:"259", label:"KENDRIYA VIDYALAYA GILL NAGAR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"7330", district:"3", Block:"259", label:"CMS APPASAMI", Sch_type:"Government", Category:"Middle School"},
    { key:"7331", district:"3", Block:"259", label:"CMS KUTTY ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7332", district:"3", Block:"259", label:"MALAYALA VIDYALAYAM HS", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7333", district:"3", Block:"259", label:"ARPUTHARAJ MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7334", district:"3", Block:"368", label:"CMS ELLISPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7335", district:"3", Block:"368", label:"AIWC HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7336", district:"3", Block:"368", label:"CUMS THAYARSAHIB STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7337", district:"3", Block:"368", label:"GOVT. MUSLIM HIGHER SECONDARY SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7338", district:"3", Block:"368", label:"Govt Madarasa - I - Asam HSS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7339", district:"3", Block:"368", label:"KENDRIYA VIDYALAYA HSS", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"7340", district:"3", Block:"368", label:"C KALYANAM GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7341", district:"3", Block:"368", label:"CHINDADRIPET HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7342", district:"3", Block:"368", label:"CHINTADRIPET MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7343", district:"3", Block:"368", label:"CMS NARASINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7344", district:"3", Block:"368", label:"CHS GOYATHOPE", Sch_type:"Government", Category:"High School"},
    { key:"7345", district:"3", Block:"368", label:"RBANC HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7346", district:"3", Block:"368", label:"CMS THYPOON ALI KHAN STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7347", district:"3", Block:"368", label:"CMS BANGARU STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7348", district:"3", Block:"368", label:"H.H.MANDAL MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7349", district:"3", Block:"368", label:"DEVI NAVASAKTHI MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7350", district:"3", Block:"368", label:"THE MUSLIM HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7351", district:"3", Block:"368", label:"SRI JNSJV MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7352", district:"3", Block:"368", label:"MURTHUZAVIYA ORIENTAL HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7353", district:"3", Block:"368", label:"CSI KELLETT HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7354", district:"3", Block:"368", label:"CMS BELLS ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"7355", district:"3", Block:"368", label:"CSI MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7356", district:"3", Block:"368", label:"THE HINDU SENIOR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7357", district:"3", Block:"368", label:"THE HINDU HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7358", district:"3", Block:"368", label:"CMS SOORAPPA STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7359", district:"3", Block:"368", label:"N.K.T NATIONAL GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7360", district:"3", Block:"368", label:"TAMILNAD MAT SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7361", district:"3", Block:"368", label:"N.K.T NATIONAL BOYS HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7362", district:"3", Block:"368", label:"CMS VR PILLAI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7363", district:"3", Block:"368", label:"CHS IRUSAPPA STREET", Sch_type:"Government", Category:"High School"},
    { key:"7364", district:"3", Block:"368", label:"GOVT. MODEL GHSS TRIPLICANE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7365", district:"3", Block:"368", label:"CUMS 10 PETERS ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"7366", district:"3", Block:"368", label:"CMS BEGUM SAHIB STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7367", district:"3", Block:"368", label:"CHSS LLOYDS ROAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7368", district:"3", Block:"368", label:"C.S.I St. Ebba's Matriculation  Hr. Sec. School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7369", district:"3", Block:"368", label:"CHS VP KOIL ST", Sch_type:"Government", Category:"High School"},
    { key:"7370", district:"3", Block:"62", label:"AUXILIUM GIRLS  higher secondary school", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7371", district:"3", Block:"62", label:"Madras Christian College Higher Secondary School", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7372", district:"3", Block:"62", label:"LADY ANDAL VENKATASUBBARAO MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7373", district:"3", Block:"62", label:"CHS PADAVATTAMMAN KOIL ST", Sch_type:"Government", Category:"High School"},
    { key:"7374", district:"3", Block:"62", label:"C.M.S. 127 ANGALAMMAN KOIL ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7375", district:"3", Block:"62", label:"C.M.S. 118 ANGALAMMAN KOIL ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7376", district:"3", Block:"62", label:"CHENNAI HIGH SCHOOL  STRAHANS ROAD", Sch_type:"Government", Category:"High School"},
    { key:"7377", district:"3", Block:"62", label:"THE SALVATION ARMY MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7378", district:"3", Block:"62", label:"CMS 88 TANA ST", Sch_type:"Government", Category:"Middle School"},
    { key:"7379", district:"3", Block:"62", label:"CHENNAI GIRLS HIGHER SECONDARY SCHOOL -ROTLER STREET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7380", district:"3", Block:"62", label:"CMS VAIKKAKARAN STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"7381", district:"3", Block:"62", label:"CMS 72 AP ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"7382", district:"3", Block:"62", label:"SRI MUTHUKUMARASWAMY DEVASTHANAM P.VENUGOPAL CHETTY HIGHER  SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7383", district:"3", Block:"62", label:"ST. JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL.", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7384", district:"3", Block:"62", label:"ST. ANDREWS  HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7385", district:"3", Block:"62", label:"CMS JAGANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7386", district:"3", Block:"62", label:"CMS SIVARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7387", district:"3", Block:"62", label:"CMS EGMORE", Sch_type:"Government", Category:"Middle School"},
    { key:"7388", district:"3", Block:"62", label:"PRESIDENCY GIRLS HSS -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7389", district:"3", Block:"62", label:"DON BOSCO MATRIC  HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7390", district:"3", Block:"62", label:"CMS VARADARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7391", district:"3", Block:"62", label:"CHENNAI BOYS HR.SEC.SCHOOL,  NUNGAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7392", district:"3", Block:"62", label:"CHENNAI GIRLS HR.SEC.SCHOOL -  NUNGAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7393", district:"3", Block:"62", label:"PADMA SESHADRI BALA BHAVAN SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7394", district:"3", Block:"62", label:"ASSUMPTION HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7395", district:"3", Block:"62", label:"CHS THOUSAND LIGHTS", Sch_type:"Government", Category:"High School"},
    { key:"7396", district:"3", Block:"62", label:"ASAN MEMORIAL SENIOR SECONDARY SCHOOLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7397", district:"3", Block:"62", label:"ASAN MEMORIAL MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7398", district:"3", Block:"62", label:"LITTLE FLOWER CONVENT - BLIND", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7399", district:"3", Block:"62", label:"ST URSULA'S ANGLO-INDIAN  HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7400", district:"3", Block:"62", label:"IMMACULATE HEART OF MARY MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7401", district:"3", Block:"62", label:"DAV BOYS SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7402", district:"3", Block:"62", label:"SRI SARADHA SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7403", district:"3", Block:"62", label:"NATIONAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7404", district:"3", Block:"62", label:"CMS VELLALA TEYNAMPET", Sch_type:"Government", Category:"Middle School"},
    { key:"7405", district:"3", Block:"62", label:"DR AMBEDKAR GOVT HR.SEC.SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7406", district:"3", Block:"62", label:"ST JOSEPH'S MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7407", district:"3", Block:"304", label:"GHSS MMDA ARUMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7408", district:"3", Block:"304", label:"ST JOSEPH HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7409", district:"3", Block:"304", label:"JG GOVT GIRL'S HIGHER SECONDARY SCHOOL,CHOOLAIMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7410", district:"3", Block:"304", label:"SATHYAMURTHY HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7411", district:"3", Block:"304", label:"CMS ERIKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7412", district:"3", Block:"304", label:"Al hira model  school", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7413", district:"3", Block:"304", label:"CHS VANNIA TEYNAMPET", Sch_type:"Government", Category:"High School"},
    { key:"7414", district:"3", Block:"304", label:"THE GROVE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7415", district:"3", Block:"304", label:"KESARI HR SEC SCHOOL,  T NAGAR,  CHENNAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7416", district:"3", Block:"304", label:"C A S DR P VENKATARAMANA RAO  HIGHER SECONDARY  SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7417", district:"3", Block:"304", label:"CMS TRUSTPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7418", district:"3", Block:"304", label:"SARASWATHI VIDYALAYA SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7419", district:"3", Block:"304", label:"Metro English medium school", Sch_type:"Un-aided", Category:"High School"},
    { key:"7420", district:"3", Block:"304", label:"MCN HIGHER SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7421", district:"3", Block:"304", label:"CMS KAMARAJAR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"7422", district:"3", Block:"304", label:"CHS RANGARAJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7423", district:"3", Block:"304", label:"CMS GANGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7424", district:"3", Block:"304", label:"M.G.R HR SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7425", district:"3", Block:"304", label:"CHENNAI HIGHER SECONDARY SCHOOL PULIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7426", district:"3", Block:"304", label:"KARNATAKA SANGHA HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7427", district:"3", Block:"304", label:"CHATRAPATHI SHIVAJI DAV SEC SH", Sch_type:"Un-aided", Category:"High School"},
    { key:"7428", district:"3", Block:"304", label:"PCKG GOVT HSS KODAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7429", district:"3", Block:"304", label:"DURGA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7430", district:"3", Block:"304", label:"CHENNAI HIGH SCHOOL KANNIYAPPA NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"7431", district:"3", Block:"304", label:"GHSS PUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7432", district:"3", Block:"304", label:"KENDRIYA VIDYALAYA  ASHOK NAGAR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"7433", district:"3", Block:"304", label:"GOVT GIRLS HR SEC SCHOOL ASHOK NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7434", district:"3", Block:"304", label:"VRUKSHA ", Sch_type:"Un-aided", Category:"High School"},
    { key:"7435", district:"3", Block:"304", label:"JAWAHAR HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7436", district:"3", Block:"304", label:"DR. K.K. NIRMALA GIRLS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7437", district:"3", Block:"304", label:"ANJUHAM HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7438", district:"3", Block:"304", label:"SRI RKM HR SEC SCHOOL MAIN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7439", district:"3", Block:"304", label:"SHRINE VELANKANNI  SS SCH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7440", district:"3", Block:"304", label:"SRI RKM SV MIDDLE SCH", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7441", district:"3", Block:"304", label:"SRI RKM BOYS HR SEC SCHOOL SOUTH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7442", district:"3", Block:"304", label:"SHRI M B JAIN HR SEC SCH", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7443", district:"3", Block:"304", label:"SMT VASANTBEN CHANDUBHAI SHAH MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7444", district:"3", Block:"304", label:"SREE NARAYANA MISSION SS SCH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7445", district:"3", Block:"304", label:"CHS KANNAMMAPET", Sch_type:"Government", Category:"High School"},
    { key:"7446", district:"3", Block:"304", label:"SRI R K M SARADA VIDYALAYA  GIRLS HR SEC SCHOOL T NAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7447", district:"3", Block:"304", label:"GUNTUR SUBBIA GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7448", district:"3", Block:"304", label:"GOVT GIRLS HIGH SCH TNAGAR", Sch_type:"Government", Category:"High School"},
    { key:"7449", district:"3", Block:"304", label:"AMRITA VIDYALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7450", district:"3", Block:"304", label:"PADMA SESHADRI BALA BHAVAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7451", district:"3", Block:"304", label:"ST ANNES MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7452", district:"3", Block:"304", label:"VANI VIDYALAYA SENIOR SECONDARY & JUNIOR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7453", district:"3", Block:"304", label:"C D NAYAGAM THEAGARAYANAGAR HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7454", district:"3", Block:"304", label:"SRI RKM MODEL HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7455", district:"3", Block:"304", label:"KAVERI HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7456", district:"3", Block:"304", label:"GENERAL CARIAPPA HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7457", district:"3", Block:"304", label:"AVICHI HSS SALIGRAMAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7458", district:"3", Block:"304", label:"CHSS NESAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7459", district:"3", Block:"304", label:"JAWAHAR VIDYALAYA SR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7460", district:"3", Block:"304", label:"ANNAI VALAN KANNI MAT SCH", Sch_type:"Un-aided", Category:"High School"},
    { key:"7461", district:"3", Block:"304", label:"JG GOVT GIRLS HSS VIRUGAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7462", district:"3", Block:"304", label:"CMS VADAPALANI", Sch_type:"Government", Category:"Middle School"},
    { key:"7463", district:"3", Block:"2", label:"GHSS MGR NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7464", district:"3", Block:"2", label:"CHSS MGR NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7465", district:"3", Block:"2", label:"CHS JAFFERKHANPET", Sch_type:"Government", Category:"High School"},
    { key:"7466", district:"3", Block:"2", label:"CHSS WEST MAMBALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7467", district:"3", Block:"2", label:"SRI AHOBILA MATH ORIENTAL HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7468", district:"3", Block:"2", label:"CHSS BOYS SAIDAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7469", district:"3", Block:"2", label:"CHSS GIRLS SAIDAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7470", district:"3", Block:"2", label:"VIDYA SAGAR B.M.SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7471", district:"3", Block:"2", label:"CHSS CIT NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7472", district:"3", Block:"2", label:"ADVENT CHRISTIAN HIGH SCHOOL, KOTTUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7473", district:"3", Block:"2", label:"AMM MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7474", district:"3", Block:"2", label:"KENDRIYA VIDYALAYA, IIT", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"7475", district:"3", Block:"2", label:"KENDRIYA VIDYALAYA, CLRI", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"7476", district:"3", Block:"2", label:"CHENNAI HIGH SCHOOL, KOTTUR", Sch_type:"Government", Category:"High School"},
    { key:"7477", district:"3", Block:"2", label:"JAIGOPAL GARODIA GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SAIDAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7478", district:"3", Block:"2", label:"ST ANTONYS MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7479", district:"3", Block:"2", label:"OUR LADY OF HEALTH MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7480", district:"3", Block:"2", label:"YMCA COLLEGE SPORTS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7481", district:"3", Block:"2", label:"ST. FRANCIS XAVIER HIGH SCHOOL, LITTLEMOUNT,CHENNAI-15", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7482", district:"3", Block:"2", label:"CHRISTIAN MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7483", district:"3", Block:"2", label:"LIONS CLUB OF GUINDY LABOUR COLONY HIGH SCHOOL ", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7484", district:"3", Block:"2", label:"CHSS MADUVANKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7485", district:"3", Block:"2", label:"LADY NYE MRT-1 SPECIAL  HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7486", district:"3", Block:"2", label:"ARSHA VIDYA MANDIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7487", district:"3", Block:"2", label:"THE ASHRAM TASSC MODEL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7488", district:"3", Block:"2", label:"ALPHA SCHOOL CIT NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7489", district:"3", Block:"2", label:"AVVAI ILLAM T.V.R GIRLS' HR SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7490", district:"3", Block:"2", label:"RANI LADY MEYYAMMAI HIGHER SECONDARY SCHOOL ", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7491", district:"3", Block:"2", label:"KUMARA RAJAH MUTHIAH BOYS HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7492", district:"3", Block:"2", label:"BALA VIDYA MANDIR ADYAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7493", district:"3", Block:"2", label:"ST MICHAELS ACADEMY MAT HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7494", district:"3", Block:"2", label:"ST LOUIS INSTITUTE FOR THE DEAF AND THE BLIND", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7495", district:"3", Block:"2", label:"GHSS VELACHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7496", district:"3", Block:"2", label:"ARIGNAR ANNA GOVT HSS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7497", district:"3", Block:"2", label:"ADVENT CHRISTIAN  HIGH  SCHOOL, VELACHERY", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7498", district:"3", Block:"2", label:"ST ANTONY'S HIGHER SECONDARY SCHOOL VELACHERY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7499", district:"3", Block:"2", label:"ST MARK'S HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7500", district:"3", Block:"195", label:"SACRED HEART GIRLS HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7501", district:"3", Block:"195", label:"GANAPATHY IYER GIRLS HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7502", district:"3", Block:"195", label:"WESLEY HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7503", district:"3", Block:"195", label:"GOPALAPURAM BOYS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7504", district:"3", Block:"195", label:"GOVT HSS NANDANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7505", district:"3", Block:"195", label:"CHSS ALWARPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7506", district:"3", Block:"195", label:"THE CHILDREN'S  GARDEN HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7507", district:"3", Block:"195", label:"KESARI HGHER SECONDARY SCHOOL MYLAPORE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7508", district:"3", Block:"195", label:"VIDYA MANDIR SR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7509", district:"3", Block:"195", label:"SAVITRIAMMAL ORIENTAL HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7510", district:"3", Block:"195", label:"P.S HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7511", district:"3", Block:"195", label:"PS SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7512", district:"3", Block:"195", label:"ALL SAINTS' MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7513", district:"3", Block:"195", label:"RAMAKRISHNA MISSION RESIDENTIAL HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"7514", district:"3", Block:"195", label:"KALEIDOSCOPE LEARNING CENTER", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7515", district:"3", Block:"195", label:"CHS KP ST", Sch_type:"Government", Category:"High School"},
    { key:"7516", district:"3", Block:"195", label:"CSI  HR .SEC.SCHOOL FOR THE DEAF", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7517", district:"3", Block:"195", label:"ST FRANCIS XAVIER MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7518", district:"3", Block:"195", label:"LADY SIVASWAMI AYYAR GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7519", district:"3", Block:"195", label:"DOMINIC SAVIO MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7520", district:"3", Block:"195", label:"SIR SIVASAMI KALALAYA SENIOR SECONDARY SCHOOL,MYLAPORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7521", district:"3", Block:"195", label:"CHS CANAL BANK ROAD", Sch_type:"Government", Category:"High School"},
    { key:"7522", district:"3", Block:"195", label:"ST RAPHAEL'S GIRLS HR SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7523", district:"3", Block:"195", label:"SRI RAJALAKSHMI MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7524", district:"3", Block:"195", label:"SIR SIVASWAMI KALALAYA HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7525", district:"3", Block:"195", label:"RANI MEYYAMMAI GIRLS' HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7526", district:"3", Block:"195", label:"RAJAH MUTHIAH HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7527", district:"3", Block:"195", label:"CHETTINAD HARI SHREE VIDYALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7528", district:"3", Block:"195", label:"CMS SANTHOME", Sch_type:"Government", Category:"Middle School"},
    { key:"7529", district:"3", Block:"195", label:"SANTHOME HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7530", district:"3", Block:"195", label:"SISHYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7531", district:"3", Block:"195", label:"CHS KAMARAJ AVENUE", Sch_type:"Government", Category:"High School"},
    { key:"7532", district:"3", Block:"195", label:"St. John's English School & Jr College", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7533", district:"3", Block:"195", label:"OLCOTT MEMORIAL HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7534", district:"3", Block:"195", label:"ST BRITTO'S ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7535", district:"3", Block:"195", label:"SRI RAJALAKSHMI MATRIC HR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7536", district:"3", Block:"195", label:"CHSS VELACHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7537", district:"3", Block:"195", label:"THE SPASTIC SOCIETY OF TAMIL NADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7538", district:"3", Block:"195", label:"CHSS THARAMANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7539", district:"3", Block:"195", label:"ARVIND MARTICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7540", district:"3", Block:"195", label:"EVAANS MATRICULATION HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7541", district:"3", Block:"195", label:"CHSS THIRUVANMIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7542", district:"3", Block:"195", label:"THE HINDU SR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7543", district:"3", Block:"195", label:"CHILDREN'S PARADISE MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7544", district:"3", Block:"195", label:"SWABODHINI SCHOOL FOR  SPECIAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7545", district:"3", Block:"195", label:"CSI ST.EBBAS GIRLS HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7546", district:"10", Block:"101", label:"GHSS DAMAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7547", district:"10", Block:"101", label:"PUMS, KILAR", Sch_type:"Government", Category:"Middle School"},
    { key:"7548", district:"10", Block:"101", label:"PUMS MUSARAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7549", district:"10", Block:"101", label:"GHSS, Musaravakkam", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7550", district:"10", Block:"101", label:"PUMS,PERUMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7551", district:"10", Block:"101", label:"GHS, Vishar", Sch_type:"Government", Category:"High School"},
    { key:"7552", district:"10", Block:"101", label:"GHSS,THIRUPPUTKUZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7553", district:"10", Block:"101", label:"PUMS,Muttavakkam", Sch_type:"Government", Category:"Middle School"},
    { key:"7554", district:"10", Block:"101", label:"PUMS VATHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7555", district:"10", Block:"101", label:"PUMS, KOORAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7556", district:"10", Block:"101", label:"PUMS, Kilambi", Sch_type:"Government", Category:"Middle School"},
    { key:"7557", district:"10", Block:"101", label:"Sri Uma Vid. MHSS, Keelambi", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7558", district:"10", Block:"101", label:"Janus Global School, CBSE, Kilambi", Sch_type:"Un-aided", Category:"High School"},
    { key:"7559", district:"10", Block:"101", label:"PUMS, SIRUKAVERIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7560", district:"10", Block:"101", label:"PUMS, KILKADIRPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7561", district:"10", Block:"101", label:"PUMS, METTUKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7562", district:"10", Block:"101", label:"GHSS AYYANGARKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7563", district:"10", Block:"101", label:"PUMS, VALATHOTTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7564", district:"10", Block:"101", label:"PUMS, KALAKATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7565", district:"10", Block:"101", label:"PUMS, KURUVIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7566", district:"10", Block:"101", label:"GHS., Periyanatham", Sch_type:"Government", Category:"High School"},
    { key:"7567", district:"10", Block:"101", label:"PUMS MARIYAMMANNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"7568", district:"10", Block:"101", label:"ADWHS ORIKKAI", Sch_type:"Government", Category:"High School"},
    { key:"7569", district:"10", Block:"101", label:"GHS For the Hearing Impaired, ORIKKAI", Sch_type:"Government", Category:"High School"},
    { key:"7570", district:"10", Block:"101", label:"RCM AIDED HS, VIPPEDU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7571", district:"10", Block:"101", label:"PUMS, VISHNU KANCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"7572", district:"10", Block:"101", label:"PUMS,THIMMASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7573", district:"10", Block:"101", label:"GHS THIRUKALIMEDU", Sch_type:"Government", Category:"High School"},
    { key:"7574", district:"10", Block:"101", label:"PUMS,ANGAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7575", district:"10", Block:"101", label:"GHSS, Avalur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7576", district:"10", Block:"101", label:"Prashanthi Gurukulam CBSE School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7577", district:"10", Block:"101", label:"PUMS, KAMMARASAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7578", district:"10", Block:"101", label:"PUMS, KAVANTHANDALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7579", district:"10", Block:"101", label:"GHSS ARPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7580", district:"10", Block:"101", label:"Grace Carmel Matriculation School, Arpakkam-631603", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7581", district:"10", Block:"101", label:"PUMS,MAGARAL", Sch_type:"Government", Category:"Middle School"},
    { key:"7582", district:"10", Block:"101", label:"PUMS THENAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7583", district:"10", Block:"101", label:"Infant Jesus Mat.HSS, Kanchipuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7584", district:"10", Block:"101", label:"GHS NASARATHPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"7585", district:"10", Block:"101", label:"PUMS COLLECTORS COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"7586", district:"10", Block:"101", label:"GHS COLLECTORATE COLONY", Sch_type:"Government", Category:"High School"},
    { key:"7587", district:"10", Block:"101", label:"Swami Vivekananda MHSS,Sevilimedu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7588", district:"10", Block:"101", label:"Bharathi MS, NGO Colony Kanchipuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"7589", district:"10", Block:"101", label:"GHS SEVILIMEDU", Sch_type:"Government", Category:"High School"},
    { key:"7590", district:"10", Block:"101", label:"MMS OLIMUGAMADUPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7591", district:"10", Block:"101", label:"MMS. KVK., Kanchipuram", Sch_type:"Government", Category:"Middle School"},
    { key:"7592", district:"10", Block:"101", label:"PTVS Matriculation school,Kanchipuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"7593", district:"10", Block:"101", label:"PTVS (Aided) HS, Kanchipuram", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7594", district:"10", Block:"101", label:"MariyaAuxilium(Aided)HSS(G),Kanchi", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7595", district:"10", Block:"101", label:"MMS,ALADI KANCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7596", district:"10", Block:"101", label:"SSKV(G) HSS,KANCHIPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7597", district:"10", Block:"101", label:"CSI (Aided) MS, Kanchipuram", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7598", district:"10", Block:"101", label:"SRI DHANDAPANI ORIENTAL AIDED HSS, KANCHIPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7599", district:"10", Block:"101", label:"CSI (AIDED) HS(G) - KANCHEEPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7600", district:"10", Block:"101", label:"MMS, OKKAPIRANDAN ,KANCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7601", district:"10", Block:"101", label:"MMS YAGASALAI ", Sch_type:"Government", Category:"Middle School"},
    { key:"7602", district:"10", Block:"101", label:"GHSS(Girls)., Big Kanchipuram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7603", district:"10", Block:"101", label:"Dr.PSS.Mpl.HSS., Kanchipuram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7604", district:"10", Block:"101", label:"Anderson (Aided) HSS(B), Kanchi", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7605", district:"10", Block:"101", label:"Khatri Mat.HS., Kanchipuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"7606", district:"10", Block:"101", label:"Rani Annadurai Mpl.HSS(G),Kanchipuram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7607", district:"10", Block:"101", label:"MMS MUTHUCETTIYAAR, KANCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7608", district:"10", Block:"101", label:"CSI  MIDDLE SCHOOL, CENTRAL KANCHIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7609", district:"10", Block:"101", label:"Thiyagi (Aided) MS, Kanchipuram", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7610", district:"10", Block:"101", label:"Pachaiyappa's HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7611", district:"10", Block:"101", label:"Victoria Mat.HSS., Kanchipuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7612", district:"10", Block:"101", label:"MMS, MELPILLAIYAR PALAYAM, KANCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7613", district:"10", Block:"101", label:"PACHAIYAPPA'S BRANCH, KANCHIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7614", district:"10", Block:"101", label:"MMS EKAMBARA SANNADHI, KANCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7615", district:"10", Block:"101", label:"BMS GGHSS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7616", district:"10", Block:"101", label:"CSMHSS,Pillayarpalayam, Kanchipuram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7617", district:"10", Block:"101", label:"Sundar Mission MHSS, Kanchipuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7618", district:"10", Block:"101", label:"Sri Krishna (Aided) MS, Kanchipuram", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7619", district:"10", Block:"101", label:"MMS VAIGUNDAM, KANCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7620", district:"10", Block:"101", label:"SRI VANI NILAYAM HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7621", district:"10", Block:"101", label:"Sri Ekambarapuram (Aided) MS, Kanchi", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7622", district:"10", Block:"101", label:"CMS GHSS, Kancheepuram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7623", district:"10", Block:"101", label:"A.K.T.MPL HS, KANCHIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7624", district:"10", Block:"101", label:"Cholan MHSS,  Kanchipuram-631502", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7625", district:"10", Block:"101", label:"Aruna Matriculation school", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7626", district:"10", Block:"101", label:"SRI RAMANUJA VIDYALAYA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7627", district:"10", Block:"410", label:"GHS, VAIYAVOOR", Sch_type:"Government", Category:"High School"},
    { key:"7628", district:"10", Block:"410", label:"PUMS MUTHIYALPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7629", district:"10", Block:"410", label:"PUMS VILLIVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7630", district:"10", Block:"410", label:"GHSS, NAICKENPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7631", district:"10", Block:"410", label:"GGHSS,EKANAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7632", district:"10", Block:"410", label:"PUMS POOSIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7633", district:"10", Block:"410", label:"PUMS KEEL OTTIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7634", district:"10", Block:"410", label:"THIRU KANNABIRAN MS PULIAMBAKKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7635", district:"10", Block:"410", label:"PUMS NATHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7636", district:"10", Block:"410", label:"VSA GHS, UTHUKKADU", Sch_type:"Government", Category:"High School"},
    { key:"7637", district:"10", Block:"410", label:"PUMS KALIYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"7638", district:"10", Block:"410", label:"Good Shephered Matriculation School, Vaiyavoor", Sch_type:"Un-aided", Category:"High School"},
    { key:"7639", district:"10", Block:"410", label:"GHS, ENATHUR", Sch_type:"Government", Category:"High School"},
    { key:"7640", district:"10", Block:"410", label:"PUMS SITTIYAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7641", district:"10", Block:"410", label:"GHSS, THENNERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7642", district:"10", Block:"410", label:"ADWMS THALAYAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"7643", district:"10", Block:"410", label:"GHSS, SINGADIVAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7644", district:"10", Block:"410", label:"PUMS ATTUPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7645", district:"10", Block:"410", label:"PUMS KOOTHIRAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7646", district:"10", Block:"410", label:"GHS KARAI", Sch_type:"Government", Category:"High School"},
    { key:"7647", district:"10", Block:"410", label:"PUMS SIRUVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7648", district:"10", Block:"410", label:"ADW HSS, INJAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7649", district:"10", Block:"410", label:"PUMS VELIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7650", district:"10", Block:"410", label:"CN(AIDED) MS,UVERICHATTRAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"7651", district:"10", Block:"410", label:"GHSS, GOVINDAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7652", district:"10", Block:"410", label:"RAMAKRISHNA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL PADUNELLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7653", district:"10", Block:"410", label:"GHSS, PULLALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7654", district:"10", Block:"410", label:"PUMS SAMANTHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7655", district:"10", Block:"410", label:"PUMS KOTTAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7656", district:"10", Block:"410", label:"GHS, PURISAI", Sch_type:"Government", Category:"High School"},
    { key:"7657", district:"10", Block:"410", label:"PUMS VALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7658", district:"10", Block:"410", label:"PUMS KATTUPATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7659", district:"10", Block:"410", label:"GHSS, PARANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7660", district:"10", Block:"410", label:"PUMS NEERVALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7661", district:"10", Block:"410", label:"PUMS ALAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7662", district:"10", Block:"410", label:"PUMS KUNNAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7663", district:"10", Block:"410", label:"A.P.N.VIDHYALAYA MATRICULATION HR SEC SCHOOL, VARANAVASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7664", district:"10", Block:"410", label:"GHS, ULLAVUR", Sch_type:"Government", Category:"High School"},
    { key:"7665", district:"10", Block:"410", label:"GHS, DEVERIAMPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7666", district:"10", Block:"410", label:"PUMS PALAYASEEVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7667", district:"10", Block:"410", label:"PUMS, SANKARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7668", district:"10", Block:"410", label:"RCM AIDED HS, WALAJABAD", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7669", district:"10", Block:"410", label:"DSSN(AIDED) MS,WALAJABAD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7670", district:"10", Block:"410", label:"ARIGNAR ANNA GOVT BOYS HR SEC SCHOOL WALAJABAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7671", district:"10", Block:"410", label:"GGHSS, WALAJABAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7672", district:"10", Block:"410", label:"W.T.Masilamani Mudaliyar Hr Sec School", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7673", district:"10", Block:"410", label:"GHSS, IYYAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7674", district:"10", Block:"377", label:"PUMS, KARUVEPPAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"7675", district:"10", Block:"377", label:"GHSS, Kaliyampoondi", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7676", district:"10", Block:"377", label:"PUMS, AZHISOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"7677", district:"10", Block:"377", label:"PUMS, ELANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"7678", district:"10", Block:"377", label:"GOVT MODEL HR SEC SCHOOL, PERUNAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7679", district:"10", Block:"377", label:"R.C MIDDLE SCHOOL , MANAMPATHY KANDIGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7680", district:"10", Block:"377", label:"GHSS,MANAMPATHY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7681", district:"10", Block:"377", label:"PUMS, VISOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"7682", district:"10", Block:"377", label:"ST.JOSEPH (AIDED)HS, R.N.KANDIGAI, UTHIRAMERUR TK", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7683", district:"10", Block:"377", label:"PUMS, PENNALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7684", district:"10", Block:"377", label:"GHS,KATTUPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7685", district:"10", Block:"377", label:"PUMS, ARASANIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7686", district:"10", Block:"377", label:"PUMS, AGARAMDULY", Sch_type:"Government", Category:"Middle School"},
    { key:"7687", district:"10", Block:"377", label:"PUMS KAVANURPUDHUCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"7688", district:"10", Block:"377", label:"GHSS,KAMMALAMPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7689", district:"10", Block:"377", label:"Asefa HS-kattukollai", Sch_type:"Un-aided", Category:"High School"},
    { key:"7690", district:"10", Block:"377", label:"Meenakshi Ammal Matric Hr Sec School Perunkozhi ,UTHIRAMERUR 603406", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7691", district:"10", Block:"377", label:"Sarva Seva HS, Chinnamakulam", Sch_type:"Un-aided", Category:"High School"},
    { key:"7692", district:"10", Block:"377", label:"PUMS, MARUTHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7693", district:"10", Block:"377", label:"GHSS THIRUPULIVANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7694", district:"10", Block:"377", label:"GHSS NEIYADUPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7695", district:"10", Block:"377", label:"PUMS MALAYANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7696", district:"10", Block:"377", label:"PEERY MHSS THOTTANAVAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7697", district:"10", Block:"377", label:"GOVT ADW HR SEC SCHOOL RETTAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7698", district:"10", Block:"377", label:"PUMS, KUNNAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7699", district:"10", Block:"377", label:"GHS ANAMPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7700", district:"10", Block:"377", label:"PUMS VAYALAKKAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7701", district:"10", Block:"377", label:"GHS THIRUMUKKUDAL", Sch_type:"Government", Category:"High School"},
    { key:"7702", district:"10", Block:"377", label:"PUMS, PINAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7703", district:"10", Block:"377", label:"GHS,SEETANANCHERI", Sch_type:"Government", Category:"High School"},
    { key:"7704", district:"10", Block:"377", label:"PUMS, SATHANANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"7705", district:"10", Block:"377", label:"GHSS,ORAKKATTUPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7706", district:"10", Block:"377", label:"AMALA ANNAI (AIDED) Higher Sec School,MAMBAKKAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7707", district:"10", Block:"377", label:"PUMS, EDAMACHI", Sch_type:"Government", Category:"Middle School"},
    { key:"7708", district:"10", Block:"377", label:"PUMS THIRUVANTHAVAR", Sch_type:"Government", Category:"Middle School"},
    { key:"7709", district:"10", Block:"377", label:"GHSS,SALAVAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7710", district:"10", Block:"377", label:"PUMS, OZHAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7711", district:"10", Block:"377", label:"ASEFA HS, MEYYURODAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"7712", district:"10", Block:"377", label:"GBHSS,UTHIRAMERUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7713", district:"10", Block:"377", label:"PANCHAYAT UNION MODEL MIDDLE SCHOOL, UTHIRAMERUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7714", district:"10", Block:"377", label:"GGHSS,UTHIRAMERUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7715", district:"10", Block:"377", label:"PALARNESAN AIDED MIDDLE SCHOOL - UTHIRAMERUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7716", district:"10", Block:"377", label:"PUMS, VADANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7717", district:"10", Block:"377", label:"RAMAKRISHNA MISSION HIGH SCHOOL, MALLIANKARANAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7718", district:"10", Block:"377", label:"LOYOLA HSS, KUPPAYANALLUR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7719", district:"10", Block:"377", label:"PUMS , VEDAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7720", district:"10", Block:"377", label:"PUMS, 1-3 WARD, UTHIRAMERUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7721", district:"10", Block:"296", label:"GHS, PITCHIVAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7722", district:"10", Block:"296", label:"PUMS O M MANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7723", district:"10", Block:"296", label:"GHS, EDAYARPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7724", district:"10", Block:"296", label:"PUMS EGANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7725", district:"10", Block:"296", label:"PUMS KOOTHAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7726", district:"10", Block:"296", label:"GHSS, MADURAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7727", district:"10", Block:"296", label:"GOVERNMENT HIGH SCHOOL,KEERANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"7728", district:"10", Block:"296", label:"PUMS,PODAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"7729", district:"10", Block:"296", label:"GHSS, MOLACHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7730", district:"10", Block:"296", label:"MORNING STAR MATRICULATION SCHOOL, MOLACHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7731", district:"10", Block:"296", label:"PUMS S V CHATRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7732", district:"10", Block:"296", label:"MAHARISHI INTL.RESI.,SANTHAVELLORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7733", district:"10", Block:"296", label:"GHS, SANTHAVELLORE", Sch_type:"Government", Category:"High School"},
    { key:"7734", district:"10", Block:"296", label:"D.A.V.SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7735", district:"10", Block:"296", label:"GHS, KUNNAM", Sch_type:"Government", Category:"High School"},
    { key:"7736", district:"10", Block:"296", label:"PUMS ECHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7737", district:"10", Block:"296", label:"PUMS - THIRUMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7738", district:"10", Block:"296", label:"PUMS MAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7739", district:"10", Block:"296", label:"PUMS PONDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7740", district:"10", Block:"296", label:"PUMS VADAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7741", district:"10", Block:"296", label:"ADWMS- KILOY", Sch_type:"Government", Category:"Middle School"},
    { key:"7742", district:"10", Block:"296", label:"MARTHOMA PUPLIC NURSERY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7743", district:"10", Block:"296", label:"PUMS AYAKOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7744", district:"10", Block:"296", label:"GOVERNMENT HIGH SCHOOL SENGADU", Sch_type:"Government", Category:"High School"},
    { key:"7745", district:"10", Block:"296", label:"PUMS NEMILI", Sch_type:"Government", Category:"Middle School"},
    { key:"7746", district:"10", Block:"296", label:"ST.ANNES (AIDED) HIGH SCHOOL, VALARPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7747", district:"10", Block:"296", label:"GOVERNMENT HIGH SCHOOL, MEVALURKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"7748", district:"10", Block:"296", label:"APPOLLO VIDYASHRAM MEVALURKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7749", district:"10", Block:"296", label:"GHSS 101 ZAMIN THANDALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7750", district:"10", Block:"296", label:"PUMS-IRUNKATTUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7751", district:"10", Block:"296", label:"PUMS PENNALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7752", district:"10", Block:"296", label:"PUMS PUDUPPER", Sch_type:"Government", Category:"Middle School"},
    { key:"7753", district:"10", Block:"296", label:"GOVERNMENT HIGH SCHOOL VENGADU", Sch_type:"Government", Category:"High School"},
    { key:"7754", district:"10", Block:"296", label:"PUMS PILLAIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7755", district:"10", Block:"296", label:"GHS, KOLATHUR", Sch_type:"Government", Category:"High School"},
    { key:"7756", district:"10", Block:"296", label:"GHS, MAGANYAM", Sch_type:"Government", Category:"High School"},
    { key:"7757", district:"10", Block:"296", label:"GOVERNMENT HIGH SCHOOL GUNDUPERUMBEDU", Sch_type:"Government", Category:"High School"},
    { key:"7758", district:"10", Block:"296", label:"GOVERNMENT HIGHER SECONDARY SCHOOL. PANRUTTI - 631 604.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7759", district:"10", Block:"296", label:"PUMS, ERAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7760", district:"10", Block:"296", label:"L. F. H. S. S (Aided) THERESAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7761", district:"10", Block:"296", label:"P. S. TEMPLE GREEN VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7762", district:"10", Block:"296", label:"TSK GOVERNMENT HIGHER SECONDARY SCHOOL MATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7763", district:"10", Block:"296", label:"P.U.M.S.GOVT.EMPLOYERS QUARTERS.", Sch_type:"Government", Category:"Middle School"},
    { key:"7764", district:"10", Block:"296", label:"PUMS SPR SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"7765", district:"10", Block:"296", label:"J J GBHSS SRIPERUMBUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7766", district:"10", Block:"296", label:"GHSS (GIRLS) SRIPERUMBUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7767", district:"10", Block:"296", label:"CSI HIGH SCHOOL SRIPERUMBUDUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7768", district:"10", Block:"296", label:"VIVEKANANDA VIDYALAYA MATRIC . HR. SEC. SCHOOL, SRIPERUMBUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7769", district:"10", Block:"296", label:"HAYAGRIVA VIDHYASHRAM SENIOR SECONDARY SCHOOL. SRIPERUMBUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7770", district:"10", Block:"296", label:"ST.JOSEPH'S RESIDENTIAL SCHOOL SRIPERUMBUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7771", district:"10", Block:"296", label:"KSHETRA VIDYALAYA,MADUVANKARAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7772", district:"10", Block:"146", label:"GHS PERIAR NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"7773", district:"10", Block:"146", label:"Madha MATRIC HSS Kundrathur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7774", district:"10", Block:"146", label:"GHS SIRUKALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"7775", district:"10", Block:"146", label:"Sri Arabindo MHSS, Malayambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7776", district:"10", Block:"146", label:"GHS Malayambakkam", Sch_type:"Government", Category:"High School"},
    { key:"7777", district:"10", Block:"146", label:"PUMS Kolumanivakkam", Sch_type:"Government", Category:"Middle School"},
    { key:"7778", district:"10", Block:"146", label:"Panchayat union middle school,PARANIPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7779", district:"10", Block:"146", label:"PUMS ANNAI INDRA NAGAR PARANIPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7780", district:"10", Block:"146", label:"GHSS IYYAPANTHANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7781", district:"10", Block:"146", label:"GOVT.ADW.HSS.,MOULIVAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7782", district:"10", Block:"146", label:"PUMS  MATHANANTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7783", district:"10", Block:"146", label:"GHS MUGALIVAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7784", district:"10", Block:"146", label:"CVC TRUST MS, KOLAPPAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7785", district:"10", Block:"146", label:"ST FRANCIS INTERNATIONAL SCHOOL KOLAPPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"7786", district:"10", Block:"146", label:"PON VIDHYASHRAM KOLAPPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7787", district:"10", Block:"146", label:"SARASWATHY VIDHYALAYA MATRICULATION   HIGHER SECONDARY SCHOOL ,GERUGAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7788", district:"10", Block:"146", label:"THE PSBB MILLENNIUM SCHOOL GERUGAMPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7789", district:"10", Block:"146", label:"GHS GERUGAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7790", district:"10", Block:"146", label:"ADWHS THANDALAM", Sch_type:"Government", Category:"High School"},
    { key:"7791", district:"10", Block:"146", label:"GBHSS KOVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7792", district:"10", Block:"146", label:"PUMS PUDUVATTARAM KOVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7793", district:"10", Block:"146", label:"G(G)HSS, Kovur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7794", district:"10", Block:"146", label:"Sri Krish International School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7795", district:"10", Block:"146", label:"GHSS THIRUMUDIVAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7796", district:"10", Block:"146", label:"SREE SHANTHI ANAND VIDYALAYA(CBSE),THIRUMUDIVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7797", district:"10", Block:"146", label:"PUMS PAZHANTHANDALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7798", district:"10", Block:"146", label:"RCM HSS(Aided), Naduveerapattu", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7799", district:"10", Block:"146", label:"Good Earth School, Naduveerapatu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7800", district:"10", Block:"146", label:"ST JOSEPH MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7801", district:"10", Block:"146", label:"SAIRAM MHSS, POONTHANDALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7802", district:"10", Block:"146", label:"GHS NALLUR", Sch_type:"Government", Category:"High School"},
    { key:"7803", district:"10", Block:"146", label:"GHSS, Somangalam", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7804", district:"10", Block:"146", label:"VEDAVALLI VIDYA MANDIR SENIOR SECONDARY SCHOOL, SOMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7805", district:"10", Block:"146", label:"GHS MANIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"7806", district:"10", Block:"146", label:"PUMS Karasangal", Sch_type:"Government", Category:"Middle School"},
    { key:"7807", district:"10", Block:"146", label:"pums Keezhpadappai", Sch_type:"Government", Category:"Middle School"},
    { key:"7808", district:"10", Block:"146", label:"pums melpadappai", Sch_type:"Government", Category:"Middle School"},
    { key:"7809", district:"10", Block:"146", label:"G(B)HSS, Padappai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7810", district:"10", Block:"146", label:"St.MARY MAT SCHOOL,PADAPPAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7811", district:"10", Block:"146", label:"EVEREST VID.MAT,PADAPPAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"7812", district:"10", Block:"146", label:"G(G)HSS, PADAPPAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7813", district:"10", Block:"146", label:"PUMS Ezhichur", Sch_type:"Government", Category:"Middle School"},
    { key:"7814", district:"10", Block:"146", label:"GOVERNMENT HIGH SCHOOL VADAKUPATTU", Sch_type:"Government", Category:"High School"},
    { key:"7815", district:"10", Block:"146", label:"Thriveni Academy Vadakkupattu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7816", district:"10", Block:"146", label:"ADWHS Chennakuppam", Sch_type:"Government", Category:"High School"},
    { key:"7817", district:"10", Block:"146", label:"P.U.M.S , Salamangalam .", Sch_type:"Government", Category:"Middle School"},
    { key:"7818", district:"10", Block:"146", label:"pums Athanancheri", Sch_type:"Government", Category:"Middle School"},
    { key:"7819", district:"10", Block:"146", label:"PUMS , Serappanancheri .", Sch_type:"Government", Category:"Middle School"},
    { key:"7820", district:"10", Block:"146", label:"Bharrathi Vidyashram ", Sch_type:"Un-aided", Category:"High School"},
    { key:"7821", district:"10", Block:"146", label:"Ghs, Umaiyalparanacheri", Sch_type:"Government", Category:"High School"},
    { key:"7822", district:"10", Block:"146", label:"GHS,NATTARASANPATTU", Sch_type:"Government", Category:"High School"},
    { key:"7823", district:"10", Block:"146", label:"PUMS Orathur", Sch_type:"Government", Category:"Middle School"},
    { key:"7824", district:"10", Block:"146", label:"G.T.M.N GOVT AIDED MIDDLE SCHOOL KAVANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7825", district:"10", Block:"146", label:"Government High School,Madambakkam", Sch_type:"Government", Category:"High School"},
    { key:"7826", district:"10", Block:"146", label:"PUMS MANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"7827", district:"10", Block:"146", label:"GHS,MANGADU", Sch_type:"Government", Category:"High School"},
    { key:"7828", district:"10", Block:"146", label:"Dawn MHSS Pattur Mangadu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7829", district:"10", Block:"146", label:"St.Mary's MS Mangadu", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7830", district:"10", Block:"146", label:"St.Annes Metric School Ragunathapuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"7831", district:"10", Block:"146", label:"Mukkala Nammalvar Chetty school", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7832", district:"10", Block:"146", label:"Valluvar(Aided)MS,Thirunageswaram", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7833", district:"10", Block:"146", label:"GHSS (Girls), Kundrathur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7834", district:"10", Block:"146", label:"GHSS (Boys), Kundrathur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7835", district:"10", Block:"146", label:"Panchayath Union Middle School, Manappakkam", Sch_type:"Government", Category:"Middle School"},
    { key:"7836", district:"10", Block:"146", label:"DR.MGR HOME AND HIGHER SECONDARY SCHOOL FOR THE SPEECH AND HEARING IMPAIRED MANAPAKKAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7837", district:"10", Block:"146", label:"PUMS  Sikkarayapuram", Sch_type:"Government", Category:"Middle School"},
    { key:"7838", district:"10", Block:"146", label:"LOYOLA ACADEMY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7839", district:"2", Block:"329", label:"PUMS KOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7840", district:"2", Block:"329", label:"ACMS PONMAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7841", district:"2", Block:"329", label:"GHSS MAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7842", district:"2", Block:"329", label:"MAHARISHI VIDYA MANDIR SR SEC SCHOOL - POLACHERY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7843", district:"2", Block:"329", label:"Ansaldo HS(Unaided), Thazhambur", Sch_type:"Un-aided", Category:"High School"},
    { key:"7844", district:"2", Block:"329", label:"PUMS NAVALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"7845", district:"2", Block:"329", label:"PADMA ADARSH HIGHER SECONDARY SCHOOL - VANIYANCHAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7846", district:"2", Block:"329", label:"PUMS KANATHUR REDDY KUPPAM COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"7847", district:"2", Block:"329", label:"ST. LOURDES MATRICULATION SCHOOL, MUTTUKKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"7848", district:"2", Block:"329", label:"PUMS KAZHIPATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7849", district:"2", Block:"329", label:"GATE WAY INTERNATIONAL, KAZHIPATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7850", district:"2", Block:"329", label:"PADMA SESHADRI BALA BHAVAN SENIOR SECONDARY SCHOOL,SIRUSERI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7851", district:"2", Block:"329", label:"GHSS KELAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7852", district:"2", Block:"329", label:"SATHANA SPECIAL SCHOOL FOR DEAF", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7853", district:"2", Block:"329", label:"ST. FRANCIS DE SALES MHSS ,  KELAMBAKKAM-603 103", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7854", district:"2", Block:"329", label:"Panchayat Union Middle School, PADUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7855", district:"2", Block:"329", label:"ST JOSEPH HSS KOVALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7856", district:"2", Block:"329", label:"Govt Higher Secondary  School,KOVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7857", district:"2", Block:"329", label:"GURUKULAM A TRUST CHILDREN INITIATIVE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7858", district:"2", Block:"329", label:"ADWHSS THAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7859", district:"2", Block:"329", label:"JAGANNATH VIDYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7860", district:"2", Block:"329", label:"PUMS PERUNTHANDALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7861", district:"2", Block:"329", label:"GHS ANUMANTHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7862", district:"2", Block:"329", label:"PANCHAYAT UNION MIDDLE SCHOOL , SIRUNKUNDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7863", district:"2", Block:"329", label:"GHSS NELLIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7864", district:"2", Block:"329", label:"CEE DEE YES DAV SC AMMAPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"7865", district:"2", Block:"329", label:"PUMS VENBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"7866", district:"2", Block:"329", label:"GHS ILLALUR", Sch_type:"Government", Category:"High School"},
    { key:"7867", district:"2", Block:"329", label:"Government Higher  Secondary School - NEMMELI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7868", district:"2", Block:"329", label:"PUMS THANDALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7869", district:"2", Block:"329", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  SEMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7870", district:"2", Block:"329", label:"GOVERNMENT HIGH SCHOOL  KARUMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7871", district:"2", Block:"329", label:"PUMS MELAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7872", district:"2", Block:"329", label:"GHS ORAGADAM", Sch_type:"Government", Category:"High School"},
    { key:"7873", district:"2", Block:"329", label:"PUMS MULLIPPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7874", district:"2", Block:"329", label:"PUMS RAYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7875", district:"2", Block:"329", label:"GHSS MANAMPATHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7876", district:"2", Block:"329", label:"Panchayat Union Middle School - KUNNAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"7877", district:"2", Block:"329", label:"PUMS KANNAGAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"7878", district:"2", Block:"329", label:"PUMS AMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"7879", district:"2", Block:"329", label:"ADWMS ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7880", district:"2", Block:"329", label:"Adi Dravida Welfare High School - PATTIPULAM", Sch_type:"Government", Category:"High School"},
    { key:"7881", district:"2", Block:"329", label:"GHS PAIYANOOR", Sch_type:"Government", Category:"High School"},
    { key:"7882", district:"2", Block:"329", label:"R.C ST JOSEPH'S HIGH SCHOOL KOOTHAVAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"7883", district:"2", Block:"329", label:"Panchayat Union Middle School - SALAVANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7884", district:"2", Block:"329", label:"GBHSS THIRUPORUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7885", district:"2", Block:"329", label:"GHSS(G) THIRUPORUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7886", district:"2", Block:"114", label:"GHS, Mannivakkam", Sch_type:"Government", Category:"High School"},
    { key:"7887", district:"2", Block:"114", label:"Deva Annai Mat HS Kilambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7888", district:"2", Block:"114", label:"ADWHSS, KILAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7889", district:"2", Block:"114", label:"GHS, Karanai Puducheri", Sch_type:"Government", Category:"High School"},
    { key:"7890", district:"2", Block:"114", label:"PUMS, ALAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7891", district:"2", Block:"114", label:"GHS, KOLAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"7892", district:"2", Block:"114", label:"PUMS, PERUMATTUNALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7893", district:"2", Block:"114", label:"GHS ASTHINAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7894", district:"2", Block:"114", label:"Government High School Kumizhi.", Sch_type:"Government", Category:"High School"},
    { key:"7895", district:"2", Block:"114", label:"PUMS, KEERAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7896", district:"2", Block:"114", label:"PUMS, NALLAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7897", district:"2", Block:"114", label:"GHS, KANDIGAI", Sch_type:"Government", Category:"High School"},
    { key:"7898", district:"2", Block:"114", label:"GHS, RATHINAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"7899", district:"2", Block:"114", label:"PUMS, MELKALVOY", Sch_type:"Government", Category:"Middle School"},
    { key:"7900", district:"2", Block:"114", label:"PUMS, KALVOY", Sch_type:"Government", Category:"Middle School"},
    { key:"7901", district:"2", Block:"114", label:"GHS, KAYARAMBEDU", Sch_type:"Government", Category:"High School"},
    { key:"7902", district:"2", Block:"114", label:"PUMS, APPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7903", district:"2", Block:"114", label:"PUMS, 81 VENBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7904", district:"2", Block:"114", label:"PUMS, GURUVANMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"7905", district:"2", Block:"114", label:"PUMS, REDDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7906", district:"2", Block:"114", label:"PUMS, KONGANANCHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"7907", district:"2", Block:"114", label:"ADWHSS Palur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7908", district:"2", Block:"114", label:"LittleFlower High School,Karumbakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"7909", district:"2", Block:"114", label:"St. Paul's MHSS, Thimmavaram-603101", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7910", district:"2", Block:"114", label:"PUMS, MELACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"7911", district:"2", Block:"114", label:"PUMS, VILLIYAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7912", district:"2", Block:"114", label:"PUMS, ATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7913", district:"2", Block:"114", label:"Bakthavatchalam (Aided) HS, Athur", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7914", district:"2", Block:"114", label:"BRINDAVAN MHSS, Athur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7915", district:"2", Block:"114", label:"RLT ACADEMY ATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7916", district:"2", Block:"114", label:"PUMS, CHETTIPUNIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7917", district:"2", Block:"114", label:"Gnanodaya Unaided HS, Venkatapuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"7918", district:"2", Block:"114", label:"PUMS, 73 KOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7919", district:"2", Block:"114", label:"GHS, KARUNILAM", Sch_type:"Government", Category:"High School"},
    { key:"7920", district:"2", Block:"114", label:"PUMS THENMELPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7921", district:"2", Block:"114", label:"PUMS KONDAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7922", district:"2", Block:"114", label:"GHSS ANJUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7923", district:"2", Block:"114", label:"PUMS SENNERI", Sch_type:"Government", Category:"Middle School"},
    { key:"7924", district:"2", Block:"114", label:"PUMS PATTARAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7925", district:"2", Block:"114", label:"PUMS PARANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7926", district:"2", Block:"114", label:"ADWMS, OZHALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7927", district:"2", Block:"114", label:"PUMS 230 VALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7928", district:"2", Block:"114", label:"St.Mary's MHSS, Nandhivaram, Guduvancherri-603202", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7929", district:"2", Block:"114", label:"ADWMS, NANDHIVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7930", district:"2", Block:"114", label:"GBHSS, NANDHIVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7931", district:"2", Block:"114", label:"GGHSS, NANDHIVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7932", district:"2", Block:"114", label:"BHARATHIYAR MHSS, GUDUVANCHERY-603202", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7933", district:"2", Block:"114", label:"Bharathiyar MS Guduvancheri", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7934", district:"2", Block:"114", label:"PUMS GUDUVANCHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"7935", district:"2", Block:"114", label:"VIDYA MANDIR @ ESTANCIA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7936", district:"2", Block:"114", label:"PUMS POTHERI EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"7937", district:"2", Block:"114", label:"PUMS NINNAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7938", district:"2", Block:"114", label:"ST.JOSEPH'S MATRIC HR.SEC.SCHOOL, MARAIMALAI NAGAR-603209", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7939", district:"2", Block:"114", label:"GHSS MM NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7940", district:"2", Block:"114", label:"PUMS GUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7941", district:"2", Block:"114", label:"PUMS KALIVANTHAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"7942", district:"2", Block:"114", label:"RSK MS GOKULAPURAM 603204", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"7943", district:"2", Block:"114", label:"CSI (Aided) HSS  Melrosapuram", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7944", district:"2", Block:"114", label:"GHS, PERAMANUR", Sch_type:"Government", Category:"High School"},
    { key:"7945", district:"2", Block:"114", label:"DR.MANGALAM(AIDED) HIGH SCHOOL, KATTANKOLATHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"7946", district:"2", Block:"114", label:"GHS, THIRUKATCHUR", Sch_type:"Government", Category:"High School"},
    { key:"7947", district:"2", Block:"114", label:"GOVT HIGH SCHOOL KATTANKOLATHUR", Sch_type:"Government", Category:"High School"},
    { key:"7948", district:"10", Block:"146", label:"Loyola Academy CBSE School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7949", district:"2", Block:"114", label:"Arignar Anna Municipal Boys Higher Secondary School Chengalpattu", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7950", district:"2", Block:"114", label:"St.Mary's Girls' Hr.Sec.School,Chengalpattu", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7951", district:"2", Block:"114", label:"SEVENTH DAY ADVENTIST MHSS, CHENGALPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7952", district:"2", Block:"114", label:"St.Columbas HSS, Chengalpattu", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7953", district:"2", Block:"114", label:"MUNICIPAL MIDDLE SCHOOL, VADAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"7954", district:"2", Block:"114", label:"CSI Alison Cassie (Aided) HSS, Chengalpattu", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7955", district:"2", Block:"114", label:"Ramakrishna (Aided) HSS(B), VNNagar", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7956", district:"2", Block:"114", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, CHENGALPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7957", district:"2", Block:"114", label:"S.R.K.M.V.GIRLS.HR.SEC.SCHOOL,CPT", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"7958", district:"2", Block:"114", label:"TELC CORNATION AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7959", district:"2", Block:"114", label:"ST.JOSEPH AIDED HSS,CHENGALPATTU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"7960", district:"2", Block:"114", label:"CSI ST. HILDAS & ST. HUGHS Chengalpattu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7961", district:"2", Block:"114", label:"Prasan Vidya  Bala Mandir MHSS, Chengalpet-603001", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7962", district:"2", Block:"114", label:"Jeevam Ganoday (Deaf)", Sch_type:"Un-aided", Category:"High School"},
    { key:"7963", district:"2", Block:"114", label:"PUMS SINGAPERUMAL KOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"7964", district:"2", Block:"114", label:"PUMS THIRUTHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"7965", district:"2", Block:"114", label:"Iseral Mat.HS", Sch_type:"Un-aided", Category:"High School"},
    { key:"7966", district:"2", Block:"114", label:"ECIST MS Singaperumalkoil", Sch_type:"Un-aided", Category:"High School"},
    { key:"7967", district:"2", Block:"114", label:"GHSS SINGAPERUMAL KOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7968", district:"2", Block:"114", label:"VIDHYA SAGAR GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7969", district:"2", Block:"114", label:"GHSS, Otteri Extension", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7970", district:"2", Block:"114", label:"CRESCENT RESIDENTIAL MATRIC HR SEC SCHOOL, VANDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7971", district:"2", Block:"114", label:"WILLYS INTEGRATED HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"7972", district:"2", Block:"114", label:"SARASWATHI MHSS, Urapakkam-603210", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7973", district:"2", Block:"322", label:"PUMS-PUDUPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7974", district:"2", Block:"322", label:"HAWISCHAR HAPPY HIGH SCHOOL, KEELAVEEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"7975", district:"2", Block:"322", label:"CGBVMS-NENMELI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7976", district:"2", Block:"322", label:"PUMS-KEERAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7977", district:"2", Block:"322", label:"GHS SOGANDI", Sch_type:"Government", Category:"High School"},
    { key:"7978", district:"2", Block:"322", label:"SADHGURU GLOBAL MATRIC HSS, SOGANDI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"7979", district:"2", Block:"322", label:"PP GHSS PON VILAINTHA KALATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7980", district:"2", Block:"322", label:"PUMS-PONPATHARKOODAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7981", district:"2", Block:"322", label:"PUMS-ANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"7982", district:"2", Block:"322", label:"GOVT HR SEC SCHOOL PANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7983", district:"2", Block:"322", label:"PUMS-KILAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7984", district:"2", Block:"322", label:"GVMS-MUDAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"7985", district:"2", Block:"322", label:"GHSS ( BOYS ), THIRUKALUKUNDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7986", district:"2", Block:"322", label:"GHS KUZHIPANTHANDALAM", Sch_type:"Government", Category:"High School"},
    { key:"7987", district:"2", Block:"322", label:"PUMS-VADAKADAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"7988", district:"2", Block:"322", label:"ANNAI VELLAN KANNI MATRICULATION SCHOOL PERUMALERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"7989", district:"2", Block:"322", label:"PUMS-KOKKILAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"7990", district:"2", Block:"322", label:"PUMS-KADAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"7991", district:"2", Block:"322", label:"PUMS-PATTIKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"7992", district:"2", Block:"322", label:"PUMS-KUNNAVAKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"7993", district:"2", Block:"322", label:"GHS VEERAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"7994", district:"2", Block:"322", label:"PUMS-MULLIKOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7995", district:"2", Block:"322", label:"GHS NATHAMKARIYACHERI", Sch_type:"Government", Category:"High School"},
    { key:"7996", district:"2", Block:"322", label:"ADWHSS MANAMAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"7997", district:"2", Block:"322", label:"PUMS-KUNNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"7998", district:"2", Block:"322", label:"ATOMIC ENERGY CENTRAL SCHOOL, ANUPURAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"7999", district:"2", Block:"322", label:"GHSS VENGAMPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8000", district:"2", Block:"322", label:"PUMS-SADRAS", Sch_type:"Government", Category:"Middle School"},
    { key:"8001", district:"2", Block:"322", label:"GHSS SADHURANGAPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8002", district:"2", Block:"322", label:"KENDRIYA VIDYALAYA NO.2, EAST SADRAS, KALPAKKAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"8003", district:"2", Block:"322", label:"ATOMIC ENERGY CENTRAL SCHOOL -NO. 2 ,EAST SADRAS, KALPAKKAM", Sch_type:"Central Govt", Category:"High School"},
    { key:"8004", district:"2", Block:"322", label:"PUMS-VITTILAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8005", district:"2", Block:"322", label:"GHS-SOORADIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"8006", district:"2", Block:"322", label:"ADWHSS NERUMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8007", district:"2", Block:"322", label:"PUMS-IRUMBULICHERRY", Sch_type:"Government", Category:"Middle School"},
    { key:"8008", district:"2", Block:"322", label:"PUMS-BOMMARASAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8009", district:"2", Block:"322", label:"PUMS-AYAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8010", district:"2", Block:"322", label:"PUMS-UYYALIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8011", district:"2", Block:"322", label:"GHSS VAYALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8012", district:"2", Block:"322", label:"ADWMS-PS NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"8013", district:"2", Block:"322", label:"ANNAMALAI MS-DESUMUGIPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8014", district:"2", Block:"322", label:"PUMS-NALVARPET", Sch_type:"Government", Category:"Middle School"},
    { key:"8015", district:"2", Block:"322", label:"PUMS-AMMANAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8016", district:"2", Block:"322", label:"PUMS-KARUMARAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8017", district:"2", Block:"322", label:"V S MHSS Thirukalukundram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8018", district:"2", Block:"322", label:"Aruna MHSS,Thirukazhukundram-603109", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8019", district:"2", Block:"322", label:"PTVS HS THIRUKAZHUKUNDRAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8020", district:"2", Block:"322", label:"PUMS-THIRUKAZHUKUNDRAM NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"8021", district:"2", Block:"322", label:"GGHSS THIRUKAZHUKUNDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8022", district:"2", Block:"322", label:"VS MATRICULATION SCHOOL MAMALLAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8023", district:"2", Block:"322", label:"PUMS-MAMALLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8024", district:"2", Block:"322", label:"GHSS MAMALLAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8025", district:"2", Block:"322", label:"ATOMIC ENERGY CENTRAL SCHOOL -NO.1, PUDUPATTINAM, KALPAKKAM", Sch_type:"Central Govt", Category:"High School"},
    { key:"8026", district:"2", Block:"322", label:"GHSS PUDHUPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8027", district:"2", Block:"322", label:"KENDRIYA VIDYALAYA NO.1, PUDUPATTINAM, KALPAKKAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"8028", district:"2", Block:"300", label:"UNCLE SARGUNAR MHSS ANAKAPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8029", district:"2", Block:"300", label:"SHRINE THERESA MATRICULATION SCHOOL, ANAKAPUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"8030", district:"2", Block:"300", label:"GHSS ANAKAPATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8031", district:"2", Block:"300", label:"VELS VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, ANAKAPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8032", district:"2", Block:"300", label:"THANTHAI PERIYAR GOVT HR SEC SCHOOL PUZHUDHIVAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8033", district:"2", Block:"300", label:"St. Thomas Mat HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8034", district:"2", Block:"300", label:"PUMS PASUMPON NAGAR PAMMAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8035", district:"2", Block:"300", label:"CSI JMA MIDDLE SCHOOL PAMMAL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8036", district:"2", Block:"300", label:"PANCHAYAT UNION MIDDLE SCHOOL, MOONGILERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8037", district:"2", Block:"300", label:"SRI SANKARA MATRIC. HR.SEC. SCHOOL, PAMMAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8038", district:"2", Block:"300", label:"ABTM MIDDLE SCHOOL,NAZARATHPURAM,ST. THOMAS MOUNT", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8039", district:"2", Block:"300", label:"DR M G R CANTONMENT HS SCHOOL ST THOMAS. MOUNT", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8040", district:"2", Block:"300", label:"RCM Aided HS., St. Thomas Mount", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8041", district:"2", Block:"300", label:"CSI MS MOUNT", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8042", district:"2", Block:"300", label:"CSI. ST. THOMAS HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8043", district:"2", Block:"300", label:"ST.HELEN'S GIRLS  HSS, ST.THOMAS MOUNT", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8044", district:"2", Block:"300", label:"CIVIL AVIATION MS MEENAMBAKKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8045", district:"2", Block:"300", label:"C. S. I ST. THOMAS MATRICULATION HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8046", district:"2", Block:"300", label:"MMS Keelkattalai", Sch_type:"Government", Category:"Middle School"},
    { key:"8047", district:"2", Block:"300", label:"THIRUVALLUVAR MIDDLE SCHOOL, ADAMBAKKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8048", district:"2", Block:"300", label:"Kendriya Vidyalaya,DGOA,Alandur", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"8049", district:"2", Block:"300", label:"VAILANKANNI SILVER JUBILEE MATRIC.HR.SEC.SCHOOL, MADIPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8050", district:"2", Block:"300", label:"Hindu Col.Chellammal vid,Alandur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8051", district:"2", Block:"300", label:"Sri Venkateswara Hindu Colony Middle School, Nanganallur", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8052", district:"2", Block:"300", label:"G(B)HSS,NANGANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8053", district:"2", Block:"300", label:"JAIGOPAL GARODIA GGHSS , NANGANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8054", district:"2", Block:"300", label:"Prince MHSS Nanganallur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8055", district:"2", Block:"300", label:"MONTFORT HSS ALANDUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8056", district:"2", Block:"300", label:"Municipal High School, Alandur", Sch_type:"Government", Category:"High School"},
    { key:"8057", district:"2", Block:"300", label:"AJS NIDHI HSS, Alandur", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8058", district:"2", Block:"300", label:"ST. BRITTO'S MAT.HR.SEC. SCHOOL, ADAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8059", district:"2", Block:"300", label:"ST.THERESA  GIRLS HSS, PALLAVARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8060", district:"2", Block:"300", label:"NATIONAL MS PALLAVARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8061", district:"2", Block:"300", label:"MMA GHSS, Pallavaram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8062", district:"2", Block:"300", label:"PAM MS PALLAVARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8063", district:"2", Block:"300", label:"MPL HSS ZAMIN PALLAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8064", district:"2", Block:"300", label:"ST.FRANCIS XAVIER NUR & PRI SCHOOL, PALLAVARAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8065", district:"2", Block:"300", label:"SRI SATHYA SAI INSTITUTE OF EDUCARE, CHROMPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8066", district:"2", Block:"300", label:"MPL HSS HASTHINAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8067", district:"2", Block:"300", label:"GOVERNMENT (ADW) HIGHER SECONDARY SCHOOL, NAGALKENI, CHROMEPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8068", district:"2", Block:"300", label:" SAVIOUR JESUS MATRICULATION HIGHER SECONDARY SCHOOL,CHROMEPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8069", district:"2", Block:"300", label:"VENKATESWARA MS CHROMEPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8070", district:"2", Block:"300", label:"Neelaram Mat.HSS,Chrompet", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8071", district:"2", Block:"300", label:"CES MIDDLE SCHOOL,  CHROMPET  ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8072", district:"2", Block:"300", label:"SRI AIYASWAMY AIYAR HS CHROMPET", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8073", district:"2", Block:"300", label:"GOVT. BOYS HR. SEC. SCHOOL, CHROMPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8074", district:"2", Block:"300", label:"MBN GGHSS CHROMEPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8075", district:"2", Block:"300", label:"Srimathi Lash Mem.Mat.HS, Chromepet", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8076", district:"2", Block:"300", label:"HOLY ANGELS GIRLS HIGH SCHOOL CHROMEPET", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8077", district:"2", Block:"300", label:"Holy Angels MHSS chrompet,ch-44", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8078", district:"2", Block:"300", label:"ARINGNAR ANNA CANTONMENT HIGH SCHOOL PALLAVARAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8079", district:"2", Block:"300", label:"MPL HSS ZAMIN ROYAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8080", district:"2", Block:"300", label:"C.S.I ST.STEPHEN'S MATRICULATION SCHOOL PALLAVARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8081", district:"2", Block:"300", label:"Modern Matric School,Chrompet", Sch_type:"Un-aided", Category:"High School"},
    { key:"8082", district:"2", Block:"300", label:"ST.MARK'S MATRICULATION HIGHER SECONDARY SCHOOL, CHROMEPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8083", district:"2", Block:"300", label:"Green Valley Matric HS, Chrompet", Sch_type:"Un-aided", Category:"High School"},
    { key:"8084", district:"2", Block:"300", label:"Royal Matric HSS,Hastinapuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"8085", district:"2", Block:"300", label:"GGN International School", Sch_type:"Un-aided", Category:"High School"},
    { key:"8086", district:"2", Block:"300", label:"Sri Sankara Global Academy, Keelkatalai", Sch_type:"Un-aided", Category:"High School"},
    { key:"8087", district:"2", Block:"300", label:"DHAYA JOTHY MATRIC HR SEC SCHOOL ,OLD PALLAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8088", district:"2", Block:"300", label:"VELS VIDYASHRAM, PALLAVARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8089", district:"2", Block:"300", label:"ADWMS KADAPERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8090", district:"2", Block:"300", label:"Seventh-Day Adventist Matric. High school, Tambaram", Sch_type:"Un-aided", Category:"High School"},
    { key:"8091", district:"2", Block:"300", label:"VALLUVAR GURUKULAM HSS ,TAMBARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8092", district:"2", Block:"300", label:"GURUKULAM MAT HSS TAMBARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8093", district:"2", Block:"300", label:"Avvai (Aided) MS, Tambaram", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8094", district:"2", Block:"300", label:"THE MADRAS SEVA SADAN (AIDED) HSS, TAMBARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8095", district:"2", Block:"300", label:"JAIGOPAL GARODIA NATIONAL HSS, EAST TAMBARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8096", district:"2", Block:"300", label:"CHRIST KING MS, TAMBARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8097", district:"2", Block:"300", label:"MPB GOVT GIRLS HR SEC SCHOOL EAST TAMBARAM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8098", district:"2", Block:"300", label:"MUNICIPAL HSS, SELAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8099", district:"2", Block:"300", label:"MMS VINOBA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"8100", district:"2", Block:"300", label:"CORLEY HR. SEC. SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8101", district:"2", Block:"300", label:"ST. MARYS MHSS, EAST TAMBARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8102", district:"2", Block:"300", label:"CSI MS TAMBARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8103", district:"2", Block:"300", label:"MMS IRUMBULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8104", district:"2", Block:"300", label:"RAJARAM MIDDLE SCHOOL, TAMBARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8105", district:"2", Block:"300", label:"Prof ACK Memorial telugu school west tambaram", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8106", district:"2", Block:"300", label:"MCC RSL HSS WEST TAMBARAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8107", district:"2", Block:"300", label:"Anandjain vidyalaya MHSS tambaram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8108", district:"2", Block:"300", label:"MMS Kannadiyarpalayam", Sch_type:"Government", Category:"Middle School"},
    { key:"8109", district:"2", Block:"300", label:"CHRIST KING GIRLS HR. SEC. SCHOOL EAST TAMBARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8110", district:"2", Block:"300", label:"ST. JOSEPH'S HIGHER SECONDARY SCHOOL, TAMBARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8111", district:"2", Block:"300", label:"BABY (AIDED)HIGH SCHOOL TAMBARAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8112", district:"2", Block:"300", label:"SHREE GUGANS SCHOOL CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"8113", district:"2", Block:"300", label:"C S I CORLEY MATRIC SCHOOL,EAST TAMBARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8114", district:"2", Block:"300", label:"ST JOHN'S MATRIC  SCHOOL,EAST TAMBARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8115", district:"2", Block:"300", label:"MPL HS OLD TAMBARAM", Sch_type:"Government", Category:"High School"},
    { key:"8116", district:"2", Block:"300", label:"SHRI VENKATESHWARA HSS, Madipakkam", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8117", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, VELLAPUTHUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8118", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, THEETTALAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8119", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHITHIRAKOODAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8120", district:"2", Block:"1", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VEDANTHANGAL.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8121", district:"2", Block:"1", label:"SACRED HEART MATRIC HIGH  SCHOOL, VALAIYAPUTHUR, VEDANTHANGAL.", Sch_type:"Un-aided", Category:"High School"},
    { key:"8122", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERUMBAKKAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8123", district:"2", Block:"1", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, L.ENDATHUR.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8124", district:"2", Block:"1", label:"ADHI PARASAKTHI  NURSERY AND PRIMARY SCHOOL, L.ENDATHUR.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8125", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, KILIYANAGAR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8126", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, PATHIRI.", Sch_type:"Government", Category:"Middle School"},
    { key:"8127", district:"2", Block:"1", label:"GOVERNMENT HIGH SCHOOL, RAMAPURAM.", Sch_type:"Government", Category:"High School"},
    { key:"8128", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEEZHAMUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8129", district:"2", Block:"1", label:"R.C.HIGHER SECONDARY SCHOOL, THANDALAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8130", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, GUDALORE.", Sch_type:"Government", Category:"Middle School"},
    { key:"8131", district:"2", Block:"1", label:"ALFREDO CUOMO HIGHER SECONDARY SCHOOL, SENDIVAKKAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8132", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, KATTUGUDALUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8133", district:"2", Block:"1", label:"GOVERNMENT HIGH SCHOOL, KATTUKARANAI.", Sch_type:"Government", Category:"High School"},
    { key:"8134", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, MATHUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8135", district:"2", Block:"1", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ELAPAKKAM.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8136", district:"2", Block:"1", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ORATHI.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8137", district:"2", Block:"1", label:"RAINBOW NURSERY AND PRIMARY SCHOOL, ORATHI.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8138", district:"2", Block:"1", label:"GOVERNMENT HIGH SCHOOL, VADAMANIPAKKAM.", Sch_type:"Government", Category:"High School"},
    { key:"8139", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, THINNALUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8140", district:"2", Block:"1", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ANANDHAMANGALAM.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8141", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, KONGARAIMAMPATTU.", Sch_type:"Government", Category:"Middle School"},
    { key:"8142", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, KALATHUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8143", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEEZHATHIVAKKAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8144", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, MINNAL CHITHAMUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8145", district:"2", Block:"1", label:"GOVERNMENT HIGH SCHOOL, NEDUNGAL.", Sch_type:"Government", Category:"High School"},
    { key:"8146", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, MURUNGAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"8147", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, VELIYAMPAKKAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8148", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL,  ATHUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8149", district:"2", Block:"1", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THOZHUPEDU.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8150", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, KADAMALAIPUTHUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8151", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERUMBERKANDIGAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"8152", district:"2", Block:"1", label:"DR. JACOBS HITECH SCHOOL, VILANGADU.", Sch_type:"Un-aided", Category:"High School"},
    { key:"8153", district:"2", Block:"1", label:"GOVERNMENT HIGH SCHOOL, THIMMAPURAM.", Sch_type:"Government", Category:"High School"},
    { key:"8154", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, SEETHAPURAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8155", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, UNAMALAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"8156", district:"2", Block:"1", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MORAPAKKAM.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8157", district:"2", Block:"1", label:"MARVAR GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, ACHARAPAKKAM.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8158", district:"2", Block:"1", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, ACHARAPAKKAM.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8159", district:"2", Block:"1", label:"PANCHAYAT UNION MIDDLE SCHOOL, VENGATESAPURAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8160", district:"2", Block:"162", label:"GHS, SITHANDIMANDAPAM", Sch_type:"Government", Category:"High School"},
    { key:"8161", district:"2", Block:"162", label:"PUMS MAMANDUR THENPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"8162", district:"2", Block:"162", label:"PUMS MAMANDUR VADAPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"8163", district:"2", Block:"162", label:"PUMS PAZHAMATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8164", district:"2", Block:"162", label:"GHS BUKKATHURAI", Sch_type:"Government", Category:"High School"},
    { key:"8165", district:"2", Block:"162", label:"PUMS NELVOY", Sch_type:"Government", Category:"Middle School"},
    { key:"8166", district:"2", Block:"162", label:"GOVERNMENT HIGH SCHOOL ONAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8167", district:"2", Block:"162", label:"PUMS PUZHUTHIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8168", district:"2", Block:"162", label:"GHSS , KUNNANKULATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8169", district:"2", Block:"162", label:"PUMS VAIYAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8170", district:"2", Block:"162", label:"PUMS PAZHAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8171", district:"2", Block:"162", label:"PUMS PADALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8172", district:"2", Block:"162", label:"MCSM GOVERNMENT HIGHER SECONDARY SCHOOL PADALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8173", district:"2", Block:"162", label:"PUMS LNPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8174", district:"2", Block:"162", label:"PUMS ARAYAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8175", district:"2", Block:"162", label:"ADWMS SANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8176", district:"2", Block:"162", label:"GHS VALLUVAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8177", district:"2", Block:"162", label:"PUMS VEDAVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8178", district:"2", Block:"162", label:"GHS PAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8179", district:"2", Block:"162", label:"ADWMS DEVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8180", district:"2", Block:"162", label:"PUMS SILAVATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8181", district:"2", Block:"162", label:"PUMS PUDUPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8182", district:"2", Block:"162", label:"PUMS KINAR", Sch_type:"Government", Category:"Middle School"},
    { key:"8183", district:"2", Block:"162", label:"ST JOSEPH AIDED HS,KK PUDHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8184", district:"2", Block:"162", label:"GHS BOODUR", Sch_type:"Government", Category:"High School"},
    { key:"8185", district:"2", Block:"162", label:"PUMS KUNNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8186", district:"2", Block:"162", label:"GHSS VEERANAKUNNAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8187", district:"2", Block:"162", label:"PUMS MUNNITHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8188", district:"2", Block:"162", label:"PUMS MUDUKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8189", district:"2", Block:"162", label:"VKM (Aided) HS, Vilvarayanallur", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8190", district:"2", Block:"162", label:"PUMS ARUNGUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8191", district:"2", Block:"162", label:"PUMS KAVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8192", district:"2", Block:"162", label:"PUMS ATHIVAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8193", district:"2", Block:"162", label:"GOVERNMENT HIGH SCHOOL ZAMIN ENDATHUR", Sch_type:"Government", Category:"High School"},
    { key:"8194", district:"2", Block:"162", label:"VENUGOPALASAMY TRUST NURSERY AND PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8195", district:"2", Block:"162", label:"MUTHUMALLA MS SIRUNALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8196", district:"2", Block:"162", label:"PUMS ZAMEENPERUVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"8197", district:"2", Block:"162", label:"PUMS MARUVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8198", district:"2", Block:"162", label:"PUMS PERIYAVENMANI", Sch_type:"Government", Category:"Middle School"},
    { key:"8199", district:"2", Block:"162", label:"PUMS KATTUDEVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8200", district:"2", Block:"162", label:"PUMS NALLAMUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8201", district:"2", Block:"162", label:"DR R ARULAPPA HIGHER SECONDARY SCHOOL NEERPAIR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8202", district:"2", Block:"162", label:"PUMS JAMINBUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8203", district:"2", Block:"162", label:"GHSS( ADW ) IRUMBEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8204", district:"2", Block:"162", label:"PTNVKS MS MADURANTAKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8205", district:"2", Block:"162", label:"GGHSS MADURANTHAKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8206", district:"2", Block:"162", label:"SRI AHOBILA MUTT ORIENTAL HIGH SCHOOL MADURANTHAKAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8207", district:"2", Block:"162", label:"HINDU CORNATION MS MADURANTAGAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8208", district:"2", Block:"162", label:"HINDU HR.SEC SCHOOL,MADURANTHAGAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8209", district:"2", Block:"162", label:"ST JOSEPH MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8210", district:"2", Block:"162", label:"CSI TOWN GIRLS MS MADURANTAKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8211", district:"2", Block:"162", label:"CSI HS MADURANTAGAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8212", district:"2", Block:"162", label:"MMS MOCHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"8213", district:"2", Block:"162", label:"RC AIDED HIGH SCHOOL, MADURANTHAGAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8214", district:"2", Block:"162", label:"GURUKULAM GIRLS HS MADURANTHAGAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8215", district:"2", Block:"162", label:"GURUKULAM BOYS HS MADURANTHAGAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8216", district:"2", Block:"162", label:"vivegananda vidyalaya mat hss", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8217", district:"2", Block:"162", label:"GHSS KARUNKUZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8218", district:"2", Block:"162", label:"PUMS KARUNKUZHI EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"8219", district:"2", Block:"162", label:"CSI MS KARUNGUZHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8220", district:"2", Block:"162", label:"NEW RIVER MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8221", district:"2", Block:"153", label:"GIRL GHSS CHEYYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8222", district:"2", Block:"153", label:"ST MARYS AIDED  HS,CHEYYUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8223", district:"2", Block:"153", label:"GBHSS CHEYYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8224", district:"2", Block:"153", label:"LITTLE FLOWER (G) HSS  CHEYYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8225", district:"2", Block:"153", label:"SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL CHEYYUR-603302", Sch_type:"Un-aided", Category:"High School"},
    { key:"8226", district:"2", Block:"153", label:"ST.MARY'S MATRICULATION SCHOOL, CHEYYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"8227", district:"2", Block:"153", label:"SAINT EXUPERY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"8228", district:"2", Block:"153", label:"RCMS SENGATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8229", district:"2", Block:"153", label:"PUMS KADUGAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8230", district:"2", Block:"153", label:"PUMS THENPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8231", district:"2", Block:"153", label:"GHSS, THIRUVATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8232", district:"2", Block:"153", label:"PUMS PERIYAVELIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"8233", district:"2", Block:"153", label:"St. Joseph HSS, Pavinjur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8234", district:"2", Block:"153", label:"PUMS SEEVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"8235", district:"2", Block:"153", label:"PUMS SEEKINANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8236", district:"2", Block:"153", label:"Marg Nava Jothi Seekinankuppam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8237", district:"2", Block:"153", label:"GHS VADAKKUVAYALUR", Sch_type:"Government", Category:"High School"},
    { key:"8238", district:"2", Block:"153", label:"PUMS LATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8239", district:"2", Block:"153", label:"PUMS KALKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8240", district:"2", Block:"153", label:"PUMS THONDAMANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8241", district:"2", Block:"153", label:"PUMS THATTAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8242", district:"2", Block:"153", label:"PUMS PARAMESWARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8243", district:"2", Block:"153", label:"GHS PARAMESWARAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"8244", district:"2", Block:"153", label:"St. Thomas Matric Hr.Sec. SCHOOL , Nerkunapattu.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8245", district:"2", Block:"153", label:"GHSS  KUVATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8246", district:"2", Block:"153", label:"PUMS KADALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8247", district:"2", Block:"153", label:"SWAMI DAYANANDA ROTARY MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8248", district:"2", Block:"153", label:"PUMS NEDUMARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8249", district:"2", Block:"153", label:"PUMS AKKINAMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"8250", district:"2", Block:"153", label:"GHSS, ANAICUT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8251", district:"2", Block:"153", label:"GHS  KODUR", Sch_type:"Government", Category:"High School"},
    { key:"8252", district:"2", Block:"153", label:"PUMS MADAIYAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8253", district:"2", Block:"153", label:"RC(AIDED)MS POONCHERY", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8254", district:"2", Block:"153", label:"SACRED HEART HS PARAMANKENIKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8255", district:"2", Block:"153", label:"PUMS MUGAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8256", district:"2", Block:"51", label:"ADHIPARASAKTHI MHSS-MELMARUVATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8257", district:"2", Block:"51", label:"G B PUBLIC SCHOOL - MELMARUVATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8258", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL, SOTHUPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8259", district:"2", Block:"51", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SOTHUPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8260", district:"2", Block:"51", label:"ADI-DRAVIDA WELFARE MIDDLE SCHOOL ,PORAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8261", district:"2", Block:"51", label:"ADI-DRAVIDA WELFARE MIDDLE SCHOOL, KILMARUVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8262", district:"2", Block:"51", label:"GOVERNMENT HIGH SCHOOL - IRUMBULI                                                                   ", Sch_type:"Government", Category:"High School"},
    { key:"8263", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - POLAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8264", district:"2", Block:"51", label:"GOVERNMENT HIGHER SECONDARY SCHOOL- POLAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8265", district:"2", Block:"51", label:"BHARATHI MATRICULATION HR SEC SCHOOL SARAVAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8266", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8267", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - PERUKKARANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8268", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - PARUKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8269", district:"2", Block:"51", label:"GOVT HIGH SCHOOL, INDALUR", Sch_type:"Government", Category:"High School"},
    { key:"8270", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - ARKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"8271", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - 23 A KOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8272", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - PULIYANI", Sch_type:"Government", Category:"Middle School"},
    { key:"8273", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - NERKUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8274", district:"2", Block:"51", label:"GOVERNMENT HIGH SCHOOL- SIRUMAILUR", Sch_type:"Government", Category:"High School"},
    { key:"8275", district:"2", Block:"51", label:"GOVERNMENT HR SEC SCHOOL, KAYAPAKKAM.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8276", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - NUGUMBAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8277", district:"2", Block:"51", label:"GOVERNMENT HIGH SCHOOL- PONTHUR", Sch_type:"Government", Category:"High School"},
    { key:"8278", district:"2", Block:"51", label:"SARVASEVA HS PERIYAKALAKADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"8279", district:"2", Block:"51", label:"GOVERNMENT HIGH SCHOOL - PUTHIRANKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"8280", district:"2", Block:"51", label:"ADI-DRAVIDA WELFARE MIDDLE SCHOOL- ESUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8281", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8282", district:"2", Block:"51", label:"GOVT HIGH SCHOOL -  ANDARKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8283", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL- VANNIANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8284", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL- PUTHUPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8285", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL- CHUNAMBEDU - (M)", Sch_type:"Government", Category:"Middle School"},
    { key:"8286", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL- VILLIPAKKAM(M)", Sch_type:"Government", Category:"Middle School"},
    { key:"8287", district:"2", Block:"51", label:"GOVERNMENT HIGHER SECONDARY SCHOOL- CHUNAMBEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8288", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - VEDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8289", district:"2", Block:"51", label:"GOVERNMENT HIGH SCHOOL - KADUKALLUR", Sch_type:"Government", Category:"High School"},
    { key:"8290", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL- NAINARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8291", district:"2", Block:"51", label:"GOVERNMENT HIGH SCHOOL - MUDHALIYARKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8292", district:"2", Block:"51", label:"SRI P K GOVT BOYS HR SEC SCHOOL-KADAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8293", district:"2", Block:"51", label:"ST. MARY MATRIC HS SCHOOL, KADAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8294", district:"2", Block:"51", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - KADAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8295", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL- ALAMBARAIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8296", district:"2", Block:"51", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VENNANGUPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8297", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8298", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL SEMPULIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8299", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL - GENGADEVANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8300", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL- PANAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8301", district:"2", Block:"51", label:"PANCHAYATH UNION MIDDLE SCHOOL- VILAMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8302", district:"2", Block:"299", label:"ADWHSS, Paduvanchery", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8303", district:"2", Block:"299", label:"PUMS VENGAMBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8304", district:"2", Block:"299", label:"GHS COWLBAZAR", Sch_type:"Government", Category:"High School"},
    { key:"8305", district:"2", Block:"299", label:"PUMS Mootaikkaranchavadi", Sch_type:"Government", Category:"Middle School"},
    { key:"8306", district:"2", Block:"299", label:"ST.JOSEPHS HR.SEC.SCHOOL,VETTUVANKENI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8307", district:"2", Block:"299", label:"ALM MAT HSS INJAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8308", district:"2", Block:"299", label:"ADVENT CHRISTIAN HIGHSCHOOL  INJAMBAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8309", district:"2", Block:"299", label:"Pon Vidyashram (CBSE),Injambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8310", district:"2", Block:"299", label:"PRIM ROSE School,Injambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8311", district:"2", Block:"299", label:"PUMS JALLADIANPET", Sch_type:"Government", Category:"Middle School"},
    { key:"8312", district:"2", Block:"299", label:"Baynes MBC MHSS, Jaladianpet", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8313", district:"2", Block:"299", label:"GHS, KARAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8314", district:"2", Block:"299", label:"Ramana Vidyalaya, Sholinganallur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8315", district:"2", Block:"299", label:"GHSS,KOVILAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8316", district:"2", Block:"299", label:"Himayam Matric Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8317", district:"2", Block:"299", label:"ST..MARY'S  MATRIC  HR. SEC. SCHOOL KOTTIVAKKAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8318", district:"2", Block:"299", label:"YMCA (Aided) HSS, Kottivakkam", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8319", district:"2", Block:"299", label:"NELLAI NADAR MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8320", district:"2", Block:"299", label:"PUMS MADIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8321", district:"2", Block:"299", label:"HOLY PRINCE MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8322", district:"2", Block:"299", label:"Prince MHSS Madipakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8323", district:"2", Block:"299", label:"SAI RAM VIDYALAYA SENIOR SECONDARY SCHOOL(CBSE), MADIPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8324", district:"2", Block:"299", label:"Sai MHSS Madipakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8325", district:"2", Block:"299", label:"pole star matric hr sec school", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8326", district:"2", Block:"299", label:"GHS NANMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"8327", district:"2", Block:"299", label:"VIDHYA MATRIC.HR.SEC.SCHOOL,JALLADIANPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8328", district:"2", Block:"299", label:"BORN SCHOLARS  MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8329", district:"2", Block:"299", label:"GHSS Medavakkam", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8330", district:"2", Block:"299", label:"St.Joseph MHSS,Medavakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8331", district:"2", Block:"299", label:"GHSS, MOOVARASAMPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8332", district:"2", Block:"299", label:"PUMS, ANNAI INDRA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"8333", district:"2", Block:"299", label:"PUMS NEELANKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8334", district:"2", Block:"299", label:"BROTHER HOOD MAT HSS NEELANGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8335", district:"2", Block:"299", label:"Sunbeam Matriculation Hr.sec.School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8336", district:"2", Block:"299", label:"IID ELIM MAT SCHOOL  METUKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8337", district:"2", Block:"299", label:"VIJAY NURSERY & PRIMARY SCHOOL, METTUKUPPAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8338", district:"2", Block:"299", label:"GHSS, OKKIYAM THURAIPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8339", district:"2", Block:"299", label:"CAUVERY MATRICULATION HIGHER SECONDARY SCHOOL,THURAIPAKKAM, CHENNAI - 97", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8340", district:"2", Block:"299", label:"AKSHARA MAT  HSS OKKIAM THURAIPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8341", district:"2", Block:"299", label:"LITTLE ANGELS MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8342", district:"2", Block:"299", label:"GHSS, KANNAGI NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8343", district:"2", Block:"299", label:"HOPE FOUNDATION MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"8344", district:"2", Block:"299", label:"PUMS OTTIYAMPAKKAAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8345", district:"2", Block:"299", label:"ADW High School, Palavakkam", Sch_type:"Government", Category:"High School"},
    { key:"8346", district:"2", Block:"299", label:"DR.AL MUDALIYAR MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"8347", district:"2", Block:"299", label:"PUMS  Pozhichalur", Sch_type:"Government", Category:"Middle School"},
    { key:"8348", district:"2", Block:"299", label:"Sri Sarada Eashwar Vidya Mandir MHSS ,Pozhichalur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8349", district:"2", Block:"299", label:"PUMS, NOOKAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8350", district:"2", Block:"299", label:"PUMS Semmancherry", Sch_type:"Government", Category:"Middle School"},
    { key:"8351", district:"2", Block:"299", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,SEMMANCHERRY(SCB)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8352", district:"2", Block:"299", label:"ST PAUL'S MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"8353", district:"2", Block:"299", label:"Alpha Nursery & Primary School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8354", district:"2", Block:"299", label:"MAYFIELD RESIDENTIAL SCHOOL SITHALAPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8355", district:"2", Block:"299", label:"GOVT HIGH SCHOOL SITHALAPAKKAM.", Sch_type:"Government", Category:"High School"},
    { key:"8356", district:"2", Block:"299", label:"PUMS THIRUSULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8357", district:"2", Block:"299", label:"K.V No:1,AFS, Madambakkam", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"8358", district:"2", Block:"299", label:"KENDRIYA VIDYALAYA No:2,AFS ,Selaiyur", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"8359", district:"2", Block:"299", label:"GHSS, THIRUVANCHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8360", district:"2", Block:"299", label:"GHS UTHANDI", Sch_type:"Government", Category:"High School"},
    { key:"8361", district:"2", Block:"299", label:"SHRI SHAKTHI VIDYALAYA NURSERY AND PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"8362", district:"2", Block:"299", label:"Sudananda Vidyalaya,Uthandi", Sch_type:"Un-aided", Category:"High School"},
    { key:"8363", district:"2", Block:"299", label:"PUMS Santhoshapuram", Sch_type:"Government", Category:"Middle School"},
    { key:"8364", district:"2", Block:"299", label:"St.Paul's Matriculation School, Vijayanagaram", Sch_type:"Un-aided", Category:"High School"},
    { key:"8365", district:"2", Block:"299", label:"ABHIJAY NURSERY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8366", district:"2", Block:"299", label:"SRI SUBA BALA BHAVAN NURSERY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8367", district:"2", Block:"299", label:"GHS, CHITLAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8368", district:"2", Block:"299", label:"JANS MATRICULATION HIGHER SECONDARY SCHOOL CHITLAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8369", district:"2", Block:"299", label:"OLIVE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8370", district:"2", Block:"299", label:"GOVT. ADW HR.SEC.SCHOOL, MEENAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8371", district:"2", Block:"299", label:"Infant Jesus Matriculation High School, Pazhavanthangal", Sch_type:"Un-aided", Category:"High School"},
    { key:"8372", district:"2", Block:"299", label:"GHSS MADAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8373", district:"2", Block:"299", label:"IDPL Matric HSS,Nandambakkam", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8374", district:"2", Block:"299", label:"JACINTH MS, Narayanapuram, Pallikaranai", Sch_type:"Un-aided", Category:"High School"},
    { key:"8375", district:"2", Block:"299", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, PALLIKARANAI, CHENNAI 600100", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8376", district:"2", Block:"299", label:"PUMS PUDUKUDIYIRUPPU PALLIKARANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8377", district:"2", Block:"299", label:"SHREE VIDYA MANDIR MATRIC. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8378", district:"2", Block:"299", label:"Kalaimagal Kalasalai Middle School, Peerkankaranai", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8379", district:"2", Block:"299", label:"GHSS PEERKANKARANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8380", district:"2", Block:"299", label:"St.Annes Matric HSS, Perungalathur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8381", district:"2", Block:"299", label:"GHSS, old Perungalathur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8382", district:"2", Block:"299", label:"Abacus Montessori School, Perungudi, Chennai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8383", district:"2", Block:"299", label:"SANGFORD N&P SCHOOL.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8384", district:"2", Block:"299", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PERUNGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8385", district:"2", Block:"299", label:"SHANTHINIKETAN MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8386", district:"2", Block:"299", label:"Advent Christian Middle School, Sembakkam", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8387", district:"2", Block:"299", label:"ST. MARYS MAT SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8388", district:"2", Block:"299", label:"ALPHA MATRICULATION HR. SEC. SCHOOL, SEMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8389", district:"2", Block:"299", label:"Boaz Public School,Sembakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8390", district:"2", Block:"299", label:"M.A.V. VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8391", district:"2", Block:"299", label:"GHSS SHOLINGANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8392", district:"2", Block:"299", label:"PUMS SHOLINGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8393", district:"2", Block:"299", label:"GOVERNMENT HIGH SCHOOL,KUDUMIYANDITHOPPU, CHENNAI 119", Sch_type:"Government", Category:"High School"},
    { key:"8394", district:"2", Block:"299", label:"JMKS GHS LAKSHMIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"8395", district:"2", Block:"299", label:"PUMS THIRUNEERMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8396", district:"2", Block:"299", label:"Sri Vidhyaalayaa Matriculation School", Sch_type:"Un-aided", Category:"High School"},
    { key:"8397", district:"2", Block:"299", label:"SRIMATHI SUNDARAVALLI MEMORIAL SCHOOL CHROMPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8398", district:"21", Block:"21", label:"GHS THANIGAI POLUR", Sch_type:"Government", Category:"High School"},
    { key:"8399", district:"21", Block:"21", label:"KALVIKKOTTAI CHRISTIAN HR.SEC SCHOOL THANIGAI POLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8400", district:"21", Block:"21", label:"PUMS KIZHANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8401", district:"21", Block:"21", label:"GHS  PERUNGALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"8402", district:"21", Block:"21", label:"GHSS VALARPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8403", district:"21", Block:"21", label:"GOVT HR SEC SCHOOL MUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8404", district:"21", Block:"21", label:"GHS MULVOY", Sch_type:"Government", Category:"High School"},
    { key:"8405", district:"21", Block:"21", label:"CSI HS KILPAKKAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8406", district:"21", Block:"21", label:"RCM HS ARAKKONAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8407", district:"21", Block:"21", label:"GHSS  ARAKKONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8408", district:"21", Block:"21", label:"G(G) HSS ARAKKONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8409", district:"21", Block:"21", label:"G(G)ADWHSS ARAKKONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8410", district:"21", Block:"21", label:"CSI ST ANDREWS HSS, ARAKKONAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8411", district:"21", Block:"21", label:"CSI(C)  HSS ARAKKONAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8412", district:"21", Block:"21", label:"MMS ARAKKONAM EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"8413", district:"21", Block:"21", label:"MMS GANDHI NAGER ARAKKONAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8414", district:"21", Block:"21", label:"MMS POLATCHI ARAKKONAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8415", district:"21", Block:"21", label:"M A JAIN MMS WINTERPET", Sch_type:"Government", Category:"Middle School"},
    { key:"8416", district:"21", Block:"21", label:"KENDRIYA VIDHYALAYA HSS PERUMOOCHI", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"8417", district:"21", Block:"21", label:"DHANAM PACHAIYAPPAN MATRIC HSS, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8418", district:"21", Block:"21", label:"PUMS PULIYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8419", district:"21", Block:"21", label:"GHSS MOSUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8420", district:"21", Block:"21", label:"PUMS PERUMUCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"8421", district:"21", Block:"21", label:"G(B)ADWHSS ARAKKONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8422", district:"21", Block:"21", label:"PUMS POIYAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8423", district:"21", Block:"21", label:"DAYANA MAT SCH NEWPET THAKKOLAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8424", district:"21", Block:"21", label:"G(B)HSS THAKKOLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8425", district:"21", Block:"21", label:"G(G)HSS, THAKKOLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8426", district:"21", Block:"21", label:"DONBOSCO HS SAGAYATHOTTAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8427", district:"21", Block:"21", label:"KENDRIYA VIDHYALAYA SCHOOL,  THAKKOLAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"8428", district:"21", Block:"21", label:"R C M MIDD SCHOOL URIYURKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8429", district:"21", Block:"116", label:"PUMS, AVALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8430", district:"21", Block:"116", label:"RTM HS, KALATHUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8431", district:"21", Block:"116", label:"PUMS, SANKARANPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"8432", district:"21", Block:"116", label:"GHS PERUMPULIPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8433", district:"21", Block:"210", label:"PUMS, POIGAINALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8434", district:"21", Block:"210", label:"GHSS MELAPULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8435", district:"21", Block:"210", label:"PUMS NEDUMPULI", Sch_type:"Government", Category:"Middle School"},
    { key:"8436", district:"21", Block:"210", label:"PUMS, THURAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8437", district:"21", Block:"210", label:"GHS ULIYANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"8438", district:"21", Block:"210", label:"PUMS, PERAPPERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8439", district:"21", Block:"210", label:"GHS KILVEEDHI", Sch_type:"Government", Category:"High School"},
    { key:"8440", district:"21", Block:"210", label:"GOVT HS MELERI", Sch_type:"Government", Category:"High School"},
    { key:"8441", district:"21", Block:"210", label:"GHSS MAHENDIRAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8442", district:"21", Block:"210", label:"PUMS, VELITHANGIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8443", district:"21", Block:"210", label:"GHS KATTUPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8444", district:"21", Block:"210", label:"G ADW HS ELATHUR", Sch_type:"Government", Category:"High School"},
    { key:"8445", district:"21", Block:"21", label:"PANCHAYAT UNION MIDDLE SCHOOL,MELPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8446", district:"21", Block:"21", label:"GHSS KUMPINIPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8447", district:"21", Block:"210", label:"GOVERMENT HIGH SCHOOL, CHITTERI", Sch_type:"Government", Category:"High School"},
    { key:"8448", district:"21", Block:"210", label:"GHSS NAGAVEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8449", district:"21", Block:"210", label:"PUMS, KIZHANTHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8450", district:"21", Block:"210", label:"PUMS, SIRUNAMALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"8451", district:"21", Block:"210", label:"GHSS PALLAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8452", district:"21", Block:"210", label:"PUMS, MANAMADURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8453", district:"21", Block:"210", label:"GHS VETTANGULAM", Sch_type:"Government", Category:"High School"},
    { key:"8454", district:"21", Block:"210", label:"PUMS, REDDIVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8455", district:"21", Block:"210", label:"PUMS, PILLAIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8456", district:"21", Block:"210", label:"GHS KADAMBANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"8457", district:"21", Block:"210", label:"PUMS, KILVENPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8458", district:"21", Block:"210", label:"GHSS THIRUMALPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8459", district:"21", Block:"210", label:"GHS, PALLUR", Sch_type:"Government", Category:"High School"},
    { key:"8460", district:"21", Block:"210", label:"PUMS, S.KOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8461", district:"21", Block:"210", label:"PUMS, ASANELLIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8462", district:"21", Block:"210", label:"GHS KILVENKATAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"8463", district:"21", Block:"210", label:"GHSS SAYANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8464", district:"21", Block:"210", label:"GHSS SENDAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8465", district:"21", Block:"210", label:"PUMS, ATTUPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8466", district:"21", Block:"210", label:"GHS GANAPATHIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"8467", district:"21", Block:"210", label:"PUMS, MURUNGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8468", district:"21", Block:"210", label:"GHS PARAMESHWARAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"8469", district:"21", Block:"210", label:"PUMS, ARIGILAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"8470", district:"21", Block:"210", label:"G( B)HSS PANAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8471", district:"21", Block:"210", label:"GOVT MODEL HIGHER SECONDARY SCHOOL , PANAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8472", district:"21", Block:"210", label:"PUMS, PUNNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8473", district:"21", Block:"210", label:"G(G)HSS NEMILI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8474", district:"21", Block:"210", label:"G(B)HSS NEMILI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8475", district:"21", Block:"290", label:"GHS GANGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"8476", district:"21", Block:"21", label:"PUMS, SOGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8477", district:"21", Block:"21", label:"G(G)HSS GURUVARAJAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8478", district:"21", Block:"21", label:"G(B)HSS GURUVARAJAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8479", district:"21", Block:"21", label:"GBHSS MINNAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8480", district:"21", Block:"21", label:"G(G)HSS NARASINGAPURAM(MINNAL)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8481", district:"21", Block:"21", label:"GOVT HIGH SCHOOL MITTAPET", Sch_type:"Government", Category:"High School"},
    { key:"8482", district:"21", Block:"290", label:"GHSS GUDALORE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8483", district:"21", Block:"290", label:"GANDHIJI HS KARIKKAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8484", district:"21", Block:"290", label:"CSI HS THALIKAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8485", district:"21", Block:"290", label:"GHS KUNNATHUR", Sch_type:"Government", Category:"High School"},
    { key:"8486", district:"21", Block:"290", label:"GOVT HS POLIPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8487", district:"21", Block:"290", label:"GOVT ADW HS AYAL", Sch_type:"Government", Category:"High School"},
    { key:"8488", district:"21", Block:"290", label:"GHS SURAI", Sch_type:"Government", Category:"High School"},
    { key:"8489", district:"21", Block:"116", label:"GOVT BOYS HSS BANAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8490", district:"21", Block:"116", label:"GOVT GIRLS HSS BANAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8491", district:"21", Block:"116", label:"GOVT HS VEDANTHANGAL", Sch_type:"Government", Category:"High School"},
    { key:"8492", district:"21", Block:"116", label:"GOVT HSS PUDHUPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8493", district:"21", Block:"116", label:"RCM MS-ALAPAKKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8494", district:"21", Block:"116", label:"GOVT HS CHERI", Sch_type:"Government", Category:"High School"},
    { key:"8495", district:"21", Block:"116", label:"GOVT BOYS HSS KAVERIPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8496", district:"21", Block:"116", label:"GOVT GIRLS HSS KAVERIPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8497", district:"21", Block:"116", label:"GOVT HS AYARPADI", Sch_type:"Government", Category:"High School"},
    { key:"8498", district:"21", Block:"116", label:"GOVT HSS SIRUKARUMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8499", district:"21", Block:"21", label:"GOVT HS ANVARTHIKHANPET", Sch_type:"Government", Category:"High School"},
    { key:"8500", district:"21", Block:"21", label:"PUMS-KILAVANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8501", district:"21", Block:"21", label:"PUMS-ITCHIPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8502", district:"21", Block:"21", label:"KINGS & QUEENS CBSE THANDALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8503", district:"21", Block:"290", label:"CSI AIDED HS PARAVATHUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8504", district:"36", Block:"113", label:"PUMS THENGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8505", district:"36", Block:"113", label:"PUMS PONNAIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8506", district:"36", Block:"113", label:"ANMMS P.N. PALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8507", district:"36", Block:"113", label:"GOVT GIRLS HSS PONNAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8508", district:"36", Block:"113", label:"GOVT BOYS HSS PONNAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8509", district:"36", Block:"113", label:"DECCAN MATRICULATION SCHOOL PONNAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"8510", district:"36", Block:"113", label:"PUMS KOKKERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8511", district:"36", Block:"113", label:"PUMS THEEYARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8512", district:"36", Block:"113", label:"ANMMS MILAGAIKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8513", district:"36", Block:"113", label:"PUMS MELKATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8514", district:"36", Block:"113", label:"MDMS MELPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8515", district:"36", Block:"113", label:"GOVT HSS VALLIMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8516", district:"36", Block:"113", label:"PUMS PERUMALKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8517", district:"36", Block:"113", label:"GHS THATHIREDDIPALLI", Sch_type:"Government", Category:"High School"},
    { key:"8518", district:"36", Block:"113", label:"PUMS MUTHARASIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8519", district:"36", Block:"113", label:"PUMS RAMANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8520", district:"36", Block:"113", label:"GOVT HSS VENNAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8521", district:"36", Block:"113", label:"KRISHNA MATRIC HSS THENPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8522", district:"21", Block:"290", label:"PUMS THAGARAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8523", district:"21", Block:"290", label:"PUMS SENGALNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8524", district:"21", Block:"290", label:"GOVT HSS RENDADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8525", district:"21", Block:"290", label:"PUMS DHALAVAIPATTADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8526", district:"21", Block:"290", label:"PUMS PERUNKANCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"8527", district:"21", Block:"290", label:"GOVT HSS KODAIKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8528", district:"21", Block:"290", label:"SM AIDED MS KONDAPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8529", district:"21", Block:"290", label:"GOVT BOYS HSS SHOLINGHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8530", district:"21", Block:"290", label:"TMT. ETHIRAJAMMAL MUDALIANDAR GOVT. MODEL GIRLS HR. SEC. SCHOOL. SHOLINGHUR.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8531", district:"21", Block:"290", label:"GOODLET HSS SHOLINGHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8532", district:"21", Block:"290", label:"ASWINI MATRIC HSS, SHOLINGUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8533", district:"21", Block:"290", label:"GOVT HS PULIVALAM", Sch_type:"Government", Category:"High School"},
    { key:"8534", district:"21", Block:"290", label:"ANMMS V.K. THANGAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8535", district:"21", Block:"290", label:"PUMS KATTRAMPAKKAM MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8536", district:"21", Block:"290", label:"GOVT HR SEC SCHOOL NEELAKANDARAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8537", district:"21", Block:"290", label:"PUMS VANGHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8538", district:"21", Block:"290", label:"GOVT HSS OZHUGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8539", district:"21", Block:"290", label:"PUMS KOLATHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8540", district:"21", Block:"290", label:"GOVT HS VELAM", Sch_type:"Government", Category:"High School"},
    { key:"8541", district:"21", Block:"409", label:"PUMS VASUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8542", district:"21", Block:"409", label:"PUMS KRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8543", district:"21", Block:"409", label:"GOVT. HR.SEC.SCHOOL VALLUVAMPAKKAM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8544", district:"21", Block:"409", label:"PUMS SENGADU MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8545", district:"21", Block:"409", label:"GOVT HSS AMMOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8546", district:"21", Block:"409", label:"PUMS KALPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8547", district:"21", Block:"409", label:"GOVT HSS LALAPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8548", district:"21", Block:"409", label:"L R SCUDDER HS EKAMBARANALLORE", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8549", district:"21", Block:"409", label:"PUMS SEEKARAJAPURAM MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8550", district:"21", Block:"409", label:"PUMS NARASINGAPURAM / THIRUVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8551", district:"21", Block:"409", label:"SRI RAMAKRISHNA BHEL HR SEC SCHOOL, RANIPET,VELLORE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8552", district:"21", Block:"409", label:"DAV  BHEL HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8553", district:"21", Block:"409", label:"ST JOHNS MHS SEEKARAJAPURAM ", Sch_type:"Un-aided", Category:"High School"},
    { key:"8554", district:"21", Block:"409", label:"PUMS THENGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8555", district:"21", Block:"409", label:"GOVT HS SIPCOT", Sch_type:"Government", Category:"High School"},
    { key:"8556", district:"21", Block:"409", label:"GOVT H S S, PULLIYANKANNU.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8557", district:"21", Block:"409", label:"AMS VANAPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8558", district:"21", Block:"409", label:"GOVT HS CHETTITHANGAL", Sch_type:"Government", Category:"High School"},
    { key:"8559", district:"21", Block:"409", label:"GOVT HS VANNIVEDU MOTTUR", Sch_type:"Government", Category:"High School"},
    { key:"8560", district:"21", Block:"409", label:"BALSAM ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"8561", district:"21", Block:"409", label:"PM AIDED MS ANANTHALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8562", district:"21", Block:"409", label:"PUMS THENKADAPENTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8563", district:"21", Block:"409", label:"PUMS CHENNASAMUTHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8564", district:"21", Block:"409", label:"GOVT HR. SEC. SCHOOL POONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8565", district:"21", Block:"409", label:"GOVT HS BAGAVELI", Sch_type:"Government", Category:"High School"},
    { key:"8566", district:"21", Block:"409", label:"GOVT HS SUMAITHANGI", Sch_type:"Government", Category:"High School"},
    { key:"8567", district:"21", Block:"409", label:"PUMS KADAPERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8568", district:"21", Block:"409", label:"SD GOVT HSS THIRUPARKADAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8569", district:"21", Block:"408", label:"MMS ANNA NAGAR ARCOT", Sch_type:"Government", Category:"Middle School"},
    { key:"8570", district:"21", Block:"408", label:"MHS THOPPUKANA NORTH ARCOT", Sch_type:"Government", Category:"High School"},
    { key:"8571", district:"21", Block:"408", label:"GOVT BOYS HSS ARCOT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8572", district:"21", Block:"24", label:"GOVT GIRLS HIGHER SECONDARY SCHOOL , ARCOT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8573", district:"21", Block:"408", label:"GVC AIDED HSS ARCOT", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8574", district:"21", Block:"408", label:"GOVT  HIGHER SECONDARY SCHOOL , RANIPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8575", district:"21", Block:"408", label:"GOVT ADW HSS KARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8576", district:"21", Block:"408", label:"GOVT THEERAN SATH MS RANIPET", Sch_type:"Government", Category:"Middle School"},
    { key:"8577", district:"21", Block:"408", label:"VRV AIDED HSS RANIPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8578", district:"21", Block:"408", label:"MMS MIXED  WALAJAH", Sch_type:"Government", Category:"Middle School"},
    { key:"8579", district:"21", Block:"408", label:"GOVT GIRLS HSS WALAJAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8580", district:"21", Block:"408", label:"MHS THOPUKANA SOUTH ARCOT", Sch_type:"Government", Category:"High School"},
    { key:"8581", district:"21", Block:"408", label:"GOVT BOYS HSS VANNIVEDU WALAJA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8582", district:"21", Block:"359", label:"LORD MACAULAY MATRIC HSS, CHINNASALAMANATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8583", district:"21", Block:"359", label:"GHSS KAVANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8584", district:"21", Block:"359", label:"AAGHSS DHAMARAIPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8585", district:"21", Block:"359", label:"GHS MAZHAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"8586", district:"21", Block:"359", label:"GHS KANIYANUR", Sch_type:"Government", Category:"High School"},
    { key:"8587", district:"21", Block:"359", label:"GHS IRUNGUR", Sch_type:"Government", Category:"High School"},
    { key:"8588", district:"21", Block:"359", label:"GHSS PENNAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8589", district:"21", Block:"359", label:"GHSS MAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8590", district:"21", Block:"359", label:"GHSS VALAPANDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8591", district:"21", Block:"359", label:"GBHSS THIMIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8592", district:"21", Block:"359", label:"GGHSS THIMIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8593", district:"21", Block:"359", label:"AAGHSS VILAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8594", district:"21", Block:"359", label:"GGHSS KALAVAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8595", district:"21", Block:"359", label:"GBHSS KALAVAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8596", district:"21", Block:"359", label:"GHSS VALAYATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8597", district:"21", Block:"24", label:"GOVT HS SATHUR", Sch_type:"Government", Category:"High School"},
    { key:"8598", district:"21", Block:"24", label:"PALLAVAN MATRIC HIGH SCHOOL, TAJPURA", Sch_type:"Un-aided", Category:"High School"},
    { key:"8599", district:"21", Block:"24", label:"PUMS MUPPADHUVETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"8600", district:"21", Block:"24", label:"GHS, V.K.MANGADU", Sch_type:"Government", Category:"High School"},
    { key:"8601", district:"21", Block:"24", label:"PUMS ATHITHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8602", district:"21", Block:"24", label:"GOVT HS PUDUPADI", Sch_type:"Government", Category:"High School"},
    { key:"8603", district:"21", Block:"24", label:"PVMGHSS,CHAKKARAMALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8604", district:"21", Block:"24", label:"GHSS ESSAYANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8605", district:"21", Block:"24", label:"GHS, ARUMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8606", district:"21", Block:"24", label:"GOVT HS MECHERI", Sch_type:"Government", Category:"High School"},
    { key:"8607", district:"21", Block:"24", label:"PUMS METTU MULLUVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"8608", district:"21", Block:"24", label:"PUMS KEERAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"8609", district:"21", Block:"24", label:"GHSS, K.VELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8610", district:"21", Block:"24", label:"PUMS ANANTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8611", district:"21", Block:"24", label:"PUMS ARAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8612", district:"21", Block:"24", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, POOTTUTHAKKU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8613", district:"21", Block:"24", label:"GOVERNMENT HIGH SCHOOL, MELAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8614", district:"21", Block:"24", label:"PANCHAYAT UNION MMIDDLE SCOOL,  MANGUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8615", district:"21", Block:"24", label:"PUNCHAYAT UNION MIDDLE SCHOOL,  NANDIYALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8616", district:"21", Block:"24", label:"ANJUMAN E MUYEDUL (M) AMS MELVISHARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8617", district:"21", Block:"24", label:"PUmS HANSA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"8618", district:"21", Block:"24", label:"ISLAMIAH B HSS MELVISHARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8619", district:"21", Block:"24", label:"ISLAMIAH G HSS MELVISHARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8620", district:"21", Block:"24", label:"KH MATRIC (B) HSS,  THEN NANDIYALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8621", district:"21", Block:"24", label:"GOVT HS AYILAM", Sch_type:"Government", Category:"High School"},
    { key:"8622", district:"21", Block:"24", label:"GOVT HS KATHIYAVADI", Sch_type:"Government", Category:"High School"},
    { key:"8623", district:"21", Block:"24", label:"PUMS, SAMBASIVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8624", district:"21", Block:"24", label:"PUMS, THAZHANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"8625", district:"21", Block:"24", label:"PUMS, RAMANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8626", district:"21", Block:"24", label:"GOVT (HINDU) HR.SEC.SCHOOL RASATHUPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8627", district:"21", Block:"24", label:"ADWMS KRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8628", district:"21", Block:"24", label:"PUMS, PERIYA KUKKUNDI", Sch_type:"Government", Category:"Middle School"},
    { key:"8629", district:"21", Block:"24", label:"PUMS, CHINNA KUKKUNDI", Sch_type:"Government", Category:"Middle School"},
    { key:"8630", district:"36", Block:"393", label:"PUMS PULIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"8631", district:"36", Block:"393", label:"PUMS GURUMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8632", district:"36", Block:"393", label:"PUMS USSOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"8633", district:"36", Block:"393", label:"GOVT. GIRLS HR. SEC. SCHOOL, USSOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8634", district:"36", Block:"393", label:"GOVT. BOYS HR. SEC. SCHOOL, USSOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8635", district:"36", Block:"393", label:"ADHITHYA VIDYASHRAM MATRIC SCHOOL, USSOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"8636", district:"36", Block:"393", label:"GOVT HS GOVINDA REDDY PALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"8637", district:"36", Block:"393", label:"GHS MELMANAVUR", Sch_type:"Government", Category:"High School"},
    { key:"8638", district:"36", Block:"393", label:"ADWMS KARUKAMBATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8639", district:"36", Block:"393", label:"PUMS SHENBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8640", district:"36", Block:"393", label:"GADWMS SHENBAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8641", district:"36", Block:"393", label:"JANASAKTHI AMS", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8642", district:"36", Block:"393", label:"GHSS KONAVATTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8643", district:"36", Block:"393", label:"GHS SEMBEDU", Sch_type:"Government", Category:"High School"},
    { key:"8644", district:"36", Block:"393", label:"GOVT HS ARIYUR", Sch_type:"Government", Category:"High School"},
    { key:"8645", district:"36", Block:"393", label:"HOLY QUEEN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8646", district:"36", Block:"393", label:"PUMS THORAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"8647", district:"36", Block:"393", label:"Govt Hr. Sec.School, Thorapadi", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8648", district:"36", Block:"393", label:"PUMS SAINATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8649", district:"36", Block:"393", label:"KRISHNASAMY HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8650", district:"36", Block:"393", label:"SREE JAYAM SCHOOL (ICSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"8651", district:"36", Block:"393", label:"PUMS SATHUVACHARI(E)", Sch_type:"Government", Category:"Middle School"},
    { key:"8652", district:"36", Block:"393", label:"PUMS VOC NAGAR, SATHUVACHARI", Sch_type:"Government", Category:"Middle School"},
    { key:"8653", district:"36", Block:"393", label:"VALLALAR AMS SATHUVACHARI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8654", district:"36", Block:"393", label:"GOVT HSS SATHUVACHARI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8655", district:"36", Block:"393", label:"ST.PAUL'S MATRIC HSS, SATHUVACHARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8656", district:"36", Block:"393", label:"SIDDHARTHA SENIOR SECONDARY SCHOOL,VELLORE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8657", district:"36", Block:"393", label:" SACRED HEART HIGH SCHOOL RANGAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8658", district:"36", Block:"393", label:"GADWHSS PERUMUGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8659", district:"36", Block:"393", label:"G ADW HSS, Alamelumangapuram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8660", district:"36", Block:"394", label:"MMS BOOSHNAM NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"8661", district:"36", Block:"394", label:"MMS ETTIAMMAN KOIL STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"8662", district:"36", Block:"394", label:"MMS MAIN BAZAAR HINDU", Sch_type:"Government", Category:"Middle School"},
    { key:"8663", district:"36", Block:"394", label:"MMS KALASPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8664", district:"36", Block:"394", label:"MMS AMBEDKAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"8665", district:"36", Block:"394", label:"MHS KOSAPET MARKET", Sch_type:"Government", Category:"High School"},
    { key:"8666", district:"36", Block:"394", label:"MHS S.K.M KASPA", Sch_type:"Government", Category:"High School"},
    { key:"8667", district:"36", Block:"394", label:"VKVM GGHSS, VELAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8668", district:"36", Block:"394", label:"MHS SALAVANPET", Sch_type:"Government", Category:"High School"},
    { key:"8669", district:"36", Block:"394", label:"GOVT MUNICIPAL GIRLS HSS, THOTTAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8670", district:"36", Block:"394", label:"GOVT MUNICIPAL HSS KAKM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8671", district:"36", Block:"394", label:"AMS CIRCAR MUNDY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8672", district:"36", Block:"394", label:"AMS SARVAJANA", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8673", district:"36", Block:"394", label:"AMS SRI RAMANUJAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8674", district:"36", Block:"394", label:"AMS SANKARANPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8675", district:"36", Block:"394", label:"AMS RAMALINGAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8676", district:"36", Block:"394", label:"AMS SRI RAMAKRISHNA", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8677", district:"36", Block:"394", label:"AHS DON BOSCO", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8678", district:"36", Block:"394", label:"AHS REV. PONNURANGAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8679", district:"36", Block:"394", label:"AHS HONNEGAR ASHRAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8680", district:"36", Block:"394", label:"VOORHEES AHSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8681", district:"36", Block:"394", label:"AHSS SRI VENKATESHWARA", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8682", district:"36", Block:"394", label:"JOSEPH MATRIC SCHOOL, VASANTHAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8683", district:"36", Block:"394", label:"GOVT MUSLIM HSS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8684", district:"36", Block:"394", label:"AHSS ST. MARY'S", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8685", district:"36", Block:"394", label:"VIDHYA MANDIR MATRIC HSS, VELLORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8686", district:"36", Block:"105", label:"GHSS KILARASAMPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8687", district:"36", Block:"105", label:"PUMS NANJUKONDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8688", district:"36", Block:"105", label:"PUMS PALATHUVANNAN", Sch_type:"Government", Category:"Middle School"},
    { key:"8689", district:"36", Block:"105", label:"PUMS THUTHIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"8690", district:"36", Block:"105", label:"GHSS CHOLAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8691", district:"36", Block:"105", label:"PUMS PALLA EDAYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"8692", district:"36", Block:"105", label:"GHSS VIRUPATCHIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8693", district:"36", Block:"105", label:"DESIA MATRIC HSS, VIRUPATCHIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8694", district:"36", Block:"105", label:"MADRAS MHSS BAGAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8695", district:"36", Block:"105", label:"PUMS PALAVANSATHU", Sch_type:"Government", Category:"Middle School"},
    { key:"8696", district:"36", Block:"105", label:"GHS EDAYANSATHU", Sch_type:"Government", Category:"High School"},
    { key:"8697", district:"36", Block:"105", label:"PUMS METTU EDYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"8698", district:"36", Block:"105", label:"ST JOSEPH AHS A .KATTUPADI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8699", district:"36", Block:"105", label:"EXCEL MATRIC SCHOOL, ADUKAMPARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"8700", district:"36", Block:"105", label:"PUMS THUTHIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8701", district:"36", Block:"105", label:"GHSS PENNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8702", district:"36", Block:"105", label:"ANNAI MATRICULATION SCHOOL PENNATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"8703", district:"36", Block:"105", label:"PUMS VEPPAMPATTU (H)", Sch_type:"Government", Category:"Middle School"},
    { key:"8704", district:"36", Block:"105", label:"AIDED RCM MS K KATTUPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8705", district:"36", Block:"105", label:"GHSS KANIYAMBADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8706", district:"36", Block:"105", label:"PUMS SATHUMADURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8707", district:"36", Block:"105", label:"PUMS ARCOTTON KUDISAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8708", district:"36", Block:"105", label:"PUMS NAICKANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8709", district:"36", Block:"105", label:"GHS MUNJURPET", Sch_type:"Government", Category:"High School"},
    { key:"8710", district:"36", Block:"105", label:"PUMS MOTTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8711", district:"36", Block:"105", label:"PUMS KILVALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8712", district:"36", Block:"105", label:"KKS MANI MATRIC HSS ,VALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8713", district:"36", Block:"105", label:"GHSS KAMMAVANPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8714", district:"36", Block:"105", label:"GHS KAMMASAMUDHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"8715", district:"36", Block:"12", label:"PUMS  RAMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8716", district:"36", Block:"12", label:"AGARAM MATRIC SCHOOL, PALLIKONDA", Sch_type:"Un-aided", Category:"High School"},
    { key:"8717", district:"36", Block:"12", label:"GOVT HS THIPPASAMUDHRAM", Sch_type:"Government", Category:"High School"},
    { key:"8718", district:"36", Block:"12", label:"GOVT HSS VIRINCHIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8719", district:"36", Block:"12", label:"GHS PICHANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"8720", district:"36", Block:"12", label:"GOVT HS DEVICHETTIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8721", district:"36", Block:"12", label:"GOVT ADW HS MAGAMADHUPURAM", Sch_type:"Government", Category:"High School"},
    { key:"8722", district:"36", Block:"12", label:"PUMS BASAVANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8723", district:"36", Block:"12", label:"GOVT HS MELARASAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"8724", district:"36", Block:"12", label:"MDAPS VANNANTHANGAL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8725", district:"36", Block:"12", label:"PUMS KOTTAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"8726", district:"36", Block:"12", label:"GOVT HS ATHIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8727", district:"36", Block:"12", label:"GOVT BOYS HSS ODUGATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8728", district:"36", Block:"12", label:"GOVT GIRLS HSS ODUGATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8729", district:"36", Block:"12", label:"PUMS PINNATHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8730", district:"36", Block:"12", label:"PUMS KUMBALKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8731", district:"36", Block:"12", label:"GOVT HS KILKOTHUR", Sch_type:"Government", Category:"High School"},
    { key:"8732", district:"36", Block:"12", label:"S.P.A.N.M.SCHOOL ERIYUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8733", district:"36", Block:"12", label:"GOVT HS SERPADI", Sch_type:"Government", Category:"High School"},
    { key:"8734", district:"36", Block:"12", label:"PUMS KATHARIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8735", district:"36", Block:"12", label:"GOVT HS PEENJAMANDAI", Sch_type:"Government", Category:"High School"},
    { key:"8736", district:"36", Block:"12", label:"PUMS JARTHANKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8737", district:"36", Block:"12", label:"PUMS ERIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8738", district:"36", Block:"12", label:"ANWARUL ISLAM AIDED MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"8739", district:"36", Block:"12", label:"ST. THERESAL HIGH SCHOOL  PALLIKONDA", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8740", district:"36", Block:"12", label:"GOVT GIRLS HSS PALLIKONDA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8741", district:"36", Block:"12", label:"GOVT BOYS HSS PALLIKONDA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8742", district:"36", Block:"12", label:"PUMS KANTHANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8743", district:"36", Block:"12", label:"GOVT HS KAZHANIPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"8744", district:"36", Block:"12", label:"ANURAT VIDYALAYA SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8745", district:"36", Block:"12", label:"GOVT HS ERAIVANKADU", Sch_type:"Government", Category:"High School"},
    { key:"8746", district:"36", Block:"12", label:"PUMS SEDUVALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8747", district:"36", Block:"12", label:"ADW MS SEDHUVALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8748", district:"36", Block:"12", label:"PUMSCHOOL VELANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"8749", district:"36", Block:"12", label:"GOVT HS MOOLAIGATE", Sch_type:"Government", Category:"High School"},
    { key:"8750", district:"36", Block:"12", label:"GOVT GIRLS HSS ANAICUT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8751", district:"36", Block:"12", label:"GBHSS ANAICUT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8752", district:"36", Block:"12", label:"PUMS THARVAZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"8753", district:"36", Block:"12", label:"GOVT HS ELAVAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"8754", district:"36", Block:"12", label:"GOVT BOYS HSS POIGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8755", district:"36", Block:"12", label:"GOVT GIRLS HSS POIGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8756", district:"36", Block:"12", label:"PUMS MADAYAPPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8757", district:"36", Block:"113", label:"GOVT HSS JANGALAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8758", district:"36", Block:"113", label:"PUMS  Jabrapet", Sch_type:"Government", Category:"Middle School"},
    { key:"8759", district:"36", Block:"113", label:"AHS ST.XAVIER'S CHRISTIANPET", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8760", district:"36", Block:"113", label:"PUMS Katpadi North", Sch_type:"Government", Category:"Middle School"},
    { key:"8761", district:"36", Block:"113", label:"PUMS Katpadi South", Sch_type:"Government", Category:"Middle School"},
    { key:"8762", district:"36", Block:"113", label:"GGHSS KATPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8763", district:"36", Block:"113", label:"KALVI ULAGAM SIVA HS KILITHANPATTARAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8764", district:"36", Block:"113", label:"Ams Ebenezar", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8765", district:"36", Block:"113", label:"GBHSS KATPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8766", district:"36", Block:"113", label:"PUMS Aruppumedu", Sch_type:"Government", Category:"Middle School"},
    { key:"8767", district:"36", Block:"113", label:"PUMS Kalinjur", Sch_type:"Government", Category:"Middle School"},
    { key:"8768", district:"36", Block:"113", label:"PUMS Kalinjur Mottur", Sch_type:"Government", Category:"Middle School"},
    { key:"8769", district:"36", Block:"113", label:"JAYABHARATH MATRIC SCHOOL, KALINJUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"8770", district:"36", Block:"113", label:"BHAGWAN MAHAVEER DAYANIKETAN JAIN SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"8771", district:"36", Block:"113", label:"GOVT HSS VANJUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8772", district:"36", Block:"113", label:"PUMS T.K.Puram", Sch_type:"Government", Category:"Middle School"},
    { key:"8773", district:"36", Block:"113", label:"Ams Union mission viruthambut", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8774", district:"36", Block:"113", label:"TMKV GGHSS Kangeyanallur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8775", district:"36", Block:"113", label:"TMKV GOVT BOYS HSS KANGEYANALLORE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8776", district:"36", Block:"113", label:"PUMS Ashok Nagar", Sch_type:"Government", Category:"Middle School"},
    { key:"8777", district:"36", Block:"113", label:"PUMS Old Katpadi", Sch_type:"Government", Category:"Middle School"},
    { key:"8778", district:"36", Block:"113", label:"PUMS Pallikuppam", Sch_type:"Government", Category:"Middle School"},
    { key:"8779", district:"36", Block:"113", label:"AUXILIUM HSS GANDHINAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8780", district:"36", Block:"113", label:"DONBOSCO HSS GANDHINAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8781", district:"36", Block:"113", label:"DEVALOIS HSS KASAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8782", district:"36", Block:"113", label:"IDA SCUDDER SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8783", district:"36", Block:"113", label:"KAVITHA MATRIC SCHOOL, KATPADI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8784", district:"36", Block:"113", label:"GOVT HSS BRAMMAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8785", district:"36", Block:"113", label:"KEERTHIGA MATRIC HSS, THIRUPAKKUTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8786", district:"36", Block:"113", label:"GHS Karikiri", Sch_type:"Government", Category:"High School"},
    { key:"8787", district:"36", Block:"113", label:"GHSS SERKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8788", district:"36", Block:"113", label:"PUMS 66 Puthur", Sch_type:"Government", Category:"Middle School"},
    { key:"8789", district:"36", Block:"113", label:"PUMS Sevoor", Sch_type:"Government", Category:"Middle School"},
    { key:"8790", district:"36", Block:"113", label:"GOVT HR.SEC. SCHOOL, KARNAMBUT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8791", district:"36", Block:"113", label:"GGHSS THIRUVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8792", district:"36", Block:"113", label:"GBHSS THIRUVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8793", district:"36", Block:"84", label:"GHSS , GUDIYATHAM RS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8794", district:"36", Block:"84", label:"PUMS SETHUVANDAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8795", district:"36", Block:"84", label:"SARASWATHI VIDHYALAYA MATRIC HSS , SETHUVANDAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8796", district:"36", Block:"84", label:"GHS DEVARISHIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8797", district:"36", Block:"84", label:"ADWMS NAGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"8798", district:"36", Block:"84", label:"PUMS HYDERPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8799", district:"36", Block:"84", label:"GHS , PASUMATHUR", Sch_type:"Government", Category:"High School"},
    { key:"8800", district:"36", Block:"84", label:"SGR GHSS KOSAVANPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8801", district:"36", Block:"84", label:"PUMS MELKAVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8802", district:"36", Block:"84", label:"GHS , MACHANUR", Sch_type:"Government", Category:"High School"},
    { key:"8803", district:"36", Block:"84", label:"PUMS MOOLAKANGKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8804", district:"36", Block:"84", label:"GHSS , PANAMADANGI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8805", district:"36", Block:"84", label:"PUMS PALLAKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8806", district:"36", Block:"84", label:"GHS , MELMOIL", Sch_type:"Government", Category:"High School"},
    { key:"8807", district:"36", Block:"84", label:"SRI KALAIVANI VIDYALAYA MATRIC HSS, MELMAYIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8808", district:"36", Block:"84", label:"PUMS  KILMUTTUKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8809", district:"36", Block:"84", label:"GBHSS KVKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8810", district:"36", Block:"84", label:"GGHSS , KVKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8811", district:"36", Block:"84", label:"SAI GURUJI MATRIC HSS, K.V.KUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8812", district:"36", Block:"84", label:"MKB MATRIC SCHOOL, ARJUNAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8813", district:"36", Block:"84", label:"GADWHSS PILLANTHIPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8814", district:"36", Block:"84", label:"GHSS, VADUGANTHANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8815", district:"36", Block:"84", label:"PUMS VELAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8816", district:"36", Block:"84", label:"GHSS SENJI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8817", district:"36", Block:"84", label:"PUMS THONDANTHULASI", Sch_type:"Government", Category:"Middle School"},
    { key:"8818", district:"36", Block:"84", label:"KALVIULAGAM AHS , ARUMBAKKAM MOTTUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8819", district:"36", Block:"84", label:"GGHSS , LATHERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8820", district:"36", Block:"84", label:"GBHSS ,LATTERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8821", district:"36", Block:"84", label:"GHS , THIRUMANI", Sch_type:"Government", Category:"High School"},
    { key:"8822", district:"36", Block:"74", label:"GHS SEMPALLI", Sch_type:"Government", Category:"High School"},
    { key:"8823", district:"36", Block:"74", label:"PUMS UPPARAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"8824", district:"36", Block:"74", label:"GHSS KOTTAMITTAH", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8825", district:"36", Block:"74", label:"GOVERNMENT HIGHER SECONDARY SCHOOOL THATTAPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8826", district:"36", Block:"74", label:"GHSS AGRAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8827", district:"36", Block:"74", label:"GHSS ERTHANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8828", district:"36", Block:"74", label:"GHSS VALATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8829", district:"36", Block:"74", label:"PUMS ULLI", Sch_type:"Government", Category:"Middle School"},
    { key:"8830", district:"36", Block:"74", label:"GHS SEMBEDU", Sch_type:"Government", Category:"High School"},
    { key:"8831", district:"36", Block:"74", label:"ADWHS CHETTIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8832", district:"36", Block:"74", label:"GOVERNMENT HIGH SCHOOL KALLUR", Sch_type:"Government", Category:"High School"},
    { key:"8833", district:"36", Block:"74", label:"GHSS GOODANAGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8834", district:"36", Block:"74", label:"PUMS SINGALPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"8835", district:"36", Block:"74", label:"GHSS KALLAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8836", district:"36", Block:"74", label:"PUMS KANAVAI MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8837", district:"36", Block:"74", label:"PUMS GUDLAVARIPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"8838", district:"36", Block:"74", label:"GHS VEERICHETTIPALLI", Sch_type:"Government", Category:"High School"},
    { key:"8839", district:"36", Block:"74", label:"SRI RAMAKRISHNA GARDEN MATRIC SCHOOL, PARADARAMI", Sch_type:"Un-aided", Category:"High School"},
    { key:"8840", district:"36", Block:"74", label:"GBHSS PARADARAMI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8841", district:"36", Block:"74", label:"GGHSS PARADARAMI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8842", district:"36", Block:"74", label:"GURUJI MATRIC SCHOOL, PARADARAMI", Sch_type:"Un-aided", Category:"High School"},
    { key:"8843", district:"36", Block:"74", label:"GHS POOSARIVALASAI", Sch_type:"Government", Category:"High School"},
    { key:"8844", district:"36", Block:"74", label:"GOVT MPL HR SEC SCHOOL GUDIYATTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8845", district:"36", Block:"74", label:"GGHSS NELLOREPET (G)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8846", district:"36", Block:"74", label:"JOTHI HR. SEC. SCHOOL, GUDIYATHAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8847", district:"36", Block:"74", label:"AHSS VALLALAR GUDIYATHAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8848", district:"36", Block:"74", label:"AHSS NATIONAL GUDIYATHAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8849", district:"36", Block:"74", label:"AHSS THIRUVALLUVAR GUDIYATHAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8850", district:"36", Block:"74", label:"CRESCENT MATRIC SCHOOL, SEDUKKARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"8851", district:"36", Block:"74", label:"A. J.  SARASWATHI MATRIC VIDHYALAYA SCHOOL , NELLOREPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"8852", district:"36", Block:"74", label:"GOVT.BOYS HR.SEC.SCHOOL, NELLOREPET,GUDIYATHAM,VELLORE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8853", district:"36", Block:"74", label:"MADRASA - E - SALEEMA NISWAN, GUDIYATHAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"8854", district:"36", Block:"74", label:"ADWHS OLAKKASI", Sch_type:"Government", Category:"High School"},
    { key:"8855", district:"36", Block:"243", label:"GHS ARAVATLA", Sch_type:"Government", Category:"High School"},
    { key:"8856", district:"36", Block:"243", label:"GHS , PASMARPENTA", Sch_type:"Government", Category:"High School"},
    { key:"8857", district:"36", Block:"243", label:"GHS ERUKKAMBUT", Sch_type:"Government", Category:"High School"},
    { key:"8858", district:"36", Block:"243", label:"GHS MACHAMBUT", Sch_type:"Government", Category:"High School"},
    { key:"8859", district:"36", Block:"243", label:"GHS BALUR", Sch_type:"Government", Category:"High School"},
    { key:"8860", district:"36", Block:"243", label:"SRI KRISHNA MATRIC SCHOOL, CHINNA DHAMAL CHERUVU", Sch_type:"Un-aided", Category:"High School"},
    { key:"8861", district:"36", Block:"243", label:"GHS PALLALAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8862", district:"36", Block:"243", label:"GHS SATHKAR", Sch_type:"Government", Category:"High School"},
    { key:"8863", district:"36", Block:"243", label:"PUMS PERNAMBUT", Sch_type:"Government", Category:"Middle School"},
    { key:"8864", district:"36", Block:"243", label:"ISLAMIAH HSS PERNAMBUT", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8865", district:"31", Block:"155", label:"SHRI KALAIMAGAL MATRIC SCHOOL , OOMERABAD", Sch_type:"Un-aided", Category:"High School"},
    { key:"8866", district:"36", Block:"243", label:"GGHSS PERNAMBUT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8867", district:"36", Block:"243", label:"GADWHSS PERNAMBUT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8868", district:"36", Block:"243", label:"RAMIZA ORIENTAL GHS PERNAMBUT", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8869", district:"36", Block:"243", label:"NUSRATHUL ISLAM GHSS, PERNAMBUT", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8870", district:"36", Block:"243", label:"St. Paul's Matric Higher Secondary School, Pernambut", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8871", district:"36", Block:"243", label:"BHARATHI VIDYALAYA NATIONAL INSTITUTE OF OPEN SCHOOLING", Sch_type:"Un-aided", Category:"Others"},
    { key:"8872", district:"36", Block:"243", label:"GADWHSS TT MOTTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8873", district:"36", Block:"243", label:"PUMS CHINTHAKANAVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8874", district:"36", Block:"74", label:"PUMS MORTHANA", Sch_type:"Government", Category:"Middle School"},
    { key:"8875", district:"36", Block:"243", label:"PUMS REDDYMANGKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8876", district:"36", Block:"243", label:"GHSS ALINJIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8877", district:"36", Block:"243", label:"PUMS MV KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8878", district:"36", Block:"243", label:"SRI VIVEKANANDA VIDHAYALAYA MATRIC HSS , MAILPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8879", district:"36", Block:"243", label:"GHSS MAILPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8880", district:"36", Block:"243", label:"IELC AIDED MIDDLE SCHOOL MAILPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8881", district:"31", Block:"155", label:"SAINT ANTONYS HSS UDAYENDIRAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8882", district:"31", Block:"5", label:"GHS GOLLAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8883", district:"31", Block:"155", label:"GHSS VADACHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8884", district:"31", Block:"155", label:"GHS MELSANANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8885", district:"31", Block:"155", label:"PUMS MALAYAMBUT", Sch_type:"Government", Category:"Middle School"},
    { key:"8886", district:"31", Block:"155", label:"GHSS ARANGALDURUGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8887", district:"31", Block:"155", label:"PUMS KUMARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8888", district:"31", Block:"155", label:"AMS VEERANGKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8889", district:"31", Block:"155", label:"GHSS DEVALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8890", district:"31", Block:"155", label:"HINDU HSS KARUMBUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8891", district:"31", Block:"155", label:"PUMS PARSANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"8892", district:"31", Block:"155", label:"ADWMS VENKATASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8893", district:"31", Block:"155", label:"PUMS KAILASAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"8894", district:"31", Block:"155", label:"IQRA MATRIC HIGHER SECONDARY  SCHOOL, OOMERABAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8895", district:"31", Block:"155", label:"MUHAMMADIA AIDED HIGH SCHOOL, OOMERABAD", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8896", district:"31", Block:"155", label:"GHS MITTALAMMELUR", Sch_type:"Government", Category:"High School"},
    { key:"8897", district:"31", Block:"155", label:"PUMS AYTHAMBUT", Sch_type:"Government", Category:"Middle School"},
    { key:"8898", district:"31", Block:"155", label:"ISLAMIAH JAMA - ATH HIGH SCHOOL NARIYAMBUT", Sch_type:"Partially Aided", Category:"High School"},
    { key:"8899", district:"36", Block:"243", label:"GHS KOKKALUR", Sch_type:"Government", Category:"High School"},
    { key:"8900", district:"36", Block:"243", label:"GHS. ERIGUTHI", Sch_type:"Government", Category:"High School"},
    { key:"8901", district:"36", Block:"243", label:"GHS BATHALAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"8902", district:"36", Block:"12", label:"GHS AGARAM", Sch_type:"Government", Category:"High School"},
    { key:"8903", district:"36", Block:"12", label:"GHSS CHINNAPALLIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8904", district:"36", Block:"12", label:"GHS MELPALLIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"8905", district:"36", Block:"12", label:"GHS ASANAMBUT", Sch_type:"Government", Category:"High School"},
    { key:"8906", district:"36", Block:"12", label:"GHS,GURUVARAJAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"8907", district:"36", Block:"12", label:"ADWMS,VEPANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8908", district:"31", Block:"155", label:"ADWMS,SOMALAPURAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8909", district:"31", Block:"155", label:"PUMS,VADAPUDUPET", Sch_type:"Government", Category:"Middle School"},
    { key:"8910", district:"31", Block:"155", label:"ACSMHSS VADAPUDUPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8911", district:"31", Block:"155", label:"PUMS,KILMURUNGAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"8912", district:"31", Block:"155", label:"PUMS,VENGILI.", Sch_type:"Government", Category:"Middle School"},
    { key:"8913", district:"31", Block:"155", label:"GHSS MADANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8914", district:"31", Block:"155", label:"Tagore National High School", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8915", district:"36", Block:"74", label:"PUMS,VADAKATHIPATI.", Sch_type:"Government", Category:"Middle School"},
    { key:"8916", district:"36", Block:"74", label:"GHS AGARAMCHERI", Sch_type:"Government", Category:"High School"},
    { key:"8917", district:"36", Block:"74", label:"ST.JOHN BLESSO MAT SCHOOL AGARAMCHERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"8918", district:"36", Block:"74", label:"GHS GOLLAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"8919", district:"36", Block:"74", label:"GHS PALLIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8920", district:"31", Block:"155", label:"PUMS,THIRUMALAIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8921", district:"31", Block:"155", label:"MMS SANDRORKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8922", district:"31", Block:"155", label:"CONCORDIA  AMS AMBUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8923", district:"31", Block:"155", label:"GHS AMBUR", Sch_type:"Government", Category:"High School"},
    { key:"8924", district:"31", Block:"155", label:"CONCORDIA HSS AMBUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8925", district:"31", Block:"155", label:"HINDU G HSS AMBUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8926", district:"31", Block:"155", label:"HINDU HR SEC SCHOOL AMBUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8927", district:"31", Block:"155", label:"MAZHARUL ULOOM HSS,AMBUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8928", district:"31", Block:"155", label:"ANAIKAR ORIENTAL (ARABIC) HR.SEC.SCHOOL, AMBUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8929", district:"31", Block:"155", label:"HASNATH-E-JARIA GIRLS HR. SEC. SCHOOL AMBUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8930", district:"31", Block:"155", label:"HABIBIA ORIENTAL GHSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8931", district:"31", Block:"155", label:"SRI VIVEKANANDA MATRIC HSS, AMBUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8932", district:"31", Block:"155", label:"BETHEL MATRIC HSS AMBUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8933", district:"31", Block:"155", label:"SRI VIDYA VIHAR MHSS,AMBUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8934", district:"31", Block:"155", label:"SHUKOOR MATRIC HSS, AMBUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8935", district:"31", Block:"155", label:"GHS NACHARKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8936", district:"31", Block:"155", label:"GHS PERIYANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8937", district:"31", Block:"155", label:"AMBUR KURUNJI MATRIC HSS, PERIYANKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8938", district:"31", Block:"155", label:"PUMS,SOLUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"8939", district:"31", Block:"155", label:"PUMS,ALANKUPPAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"8940", district:"31", Block:"155", label:"HINDU AMS VINNAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8941", district:"31", Block:"155", label:"GHS MINNUR", Sch_type:"Government", Category:"High School"},
    { key:"8942", district:"31", Block:"155", label:"EMBESSO MATRIC HSS , MARAPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8943", district:"31", Block:"5", label:"GHS MADANANCHERI", Sch_type:"Government", Category:"High School"},
    { key:"8944", district:"31", Block:"82", label:"SAINT FRANCIS DE SALES MATRIC HSS JANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8945", district:"31", Block:"82", label:"SIGARAM MATRIC HSS, CHETTIYAPPANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"8946", district:"31", Block:"362", label:"FORESTMS, THAGARAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8947", district:"31", Block:"362", label:"FOREST MS, PERUMPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"8948", district:"31", Block:"362", label:"GOV.TRIBLRES.SC,ARASAMARATHUKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"8949", district:"31", Block:"362", label:"FOREST HSS PUDURNADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8950", district:"31", Block:"362", label:"PUMS, MELPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8951", district:"31", Block:"362", label:"FOREST HSS NELLIVASALNADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8952", district:"31", Block:"362", label:"GHS PULIYUR", Sch_type:"Government", Category:"High School"},
    { key:"8953", district:"31", Block:"5", label:"GHSS GIRISAMUDHIRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8954", district:"31", Block:"5", label:"SRI VIVEKANANDA MATRIC SCHOOL , VELLAKUTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"8955", district:"31", Block:"5", label:"GHSS VELLAKUTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8956", district:"31", Block:"5", label:"GHSS VALLIPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8957", district:"31", Block:"5", label:"GHS PERIYAKURUMBTHERU", Sch_type:"Government", Category:"High School"},
    { key:"8958", district:"31", Block:"5", label:"GHS KOTHAKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"8959", district:"31", Block:"5", label:"GHSS NIMMIYAMBATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8960", district:"31", Block:"5", label:"PUUGMS, JAFFARABAD", Sch_type:"Government", Category:"Middle School"},
    { key:"8961", district:"31", Block:"5", label:"ADW HR.SEC SCHOOL ALANGAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8962", district:"31", Block:"5", label:"GGHSS ALANGAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8963", district:"31", Block:"5", label:"BRINTHAVAN MATRIC SCHOOL, ALANGAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"8964", district:"31", Block:"5", label:"PUMS, KALLARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"8965", district:"31", Block:"5", label:"GHSS MALAIREDDIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8966", district:"31", Block:"208", label:"PUMS ALINJIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8967", district:"31", Block:"5", label:"PUMS CHINDAGAMANIPENDA", Sch_type:"Government", Category:"Middle School"},
    { key:"8968", district:"31", Block:"208", label:"GHSS THUMBERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8969", district:"31", Block:"208", label:"GHS CHICKANANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"8970", district:"31", Block:"208", label:"PUMS JAVADHU RAMA SAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8971", district:"31", Block:"208", label:"PUMS, BYYAPPANAYAKENPET", Sch_type:"Government", Category:"Middle School"},
    { key:"8972", district:"31", Block:"208", label:"PUMS MAMUDIMANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"8973", district:"31", Block:"208", label:"GHSS PATCHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8974", district:"31", Block:"208", label:"PUMS KATHARI", Sch_type:"Government", Category:"Middle School"},
    { key:"8975", district:"31", Block:"208", label:"GOVT. HSS. DHASIRIYAPPANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8976", district:"31", Block:"208", label:"GHSS RAMANAICKENPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8977", district:"31", Block:"208", label:"PUMS ANNA NAGAR(M)", Sch_type:"Government", Category:"Middle School"},
    { key:"8978", district:"31", Block:"208", label:"PUMS GURUBHAVANIGUNDA", Sch_type:"Government", Category:"Middle School"},
    { key:"8979", district:"31", Block:"208", label:"PUMS THEKKUPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"8980", district:"31", Block:"208", label:"PUMS KODAIYANCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"8981", district:"31", Block:"208", label:"GHSS AMBALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8982", district:"31", Block:"208", label:"THIRU-VI- KA MIDDLE SCHOOL THIRU-VI-KA NAGAR MARUTHUVAR COLONY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"8983", district:"31", Block:"208", label:"ISLAMIAH BOYS HSS VANIYAMBADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8984", district:"31", Block:"208", label:"HINDU HSS VANIYAMBADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8985", district:"31", Block:"208", label:"MMS MUSLIM GIRLS BASHEERABAD", Sch_type:"Government", Category:"Middle School"},
    { key:"8986", district:"31", Block:"208", label:"MMS MUSLIM GIRLS FORT", Sch_type:"Government", Category:"Middle School"},
    { key:"8987", district:"31", Block:"208", label:"MMS MUSLIM GIRLS KHADERPET", Sch_type:"Government", Category:"Middle School"},
    { key:"8988", district:"31", Block:"208", label:"ISLAMIAH GIRLS HSS VANIYAMBADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"8989", district:"31", Block:"208", label:"KHADERIA HSS VANIYAMBADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"8990", district:"31", Block:"208", label:"MMS PERUMALPET", Sch_type:"Government", Category:"Middle School"},
    { key:"8991", district:"31", Block:"208", label:"TVKV HS VANIYAMBADI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"8992", district:"31", Block:"208", label:"GOVT MPL  MODEL HIGHER SECONDARY SCHOOL  GANDHI NAGAR VANIYAMBADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"8993", district:"31", Block:"208", label:"PUMS AVARANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"8994", district:"31", Block:"208", label:"PUMS PULLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"8995", district:"31", Block:"208", label:"PUMS KONDAKINDANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"8996", district:"31", Block:"208", label:"GHS No. 1. KOTHUR", Sch_type:"Government", Category:"High School"},
    { key:"8997", district:"31", Block:"208", label:"GHS THIMMAMPET", Sch_type:"Government", Category:"High School"},
    { key:"8998", district:"31", Block:"208", label:"PUMS VELLANAYAKANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"8999", district:"31", Block:"208", label:"GHS NAYANACHERUVU", Sch_type:"Government", Category:"High School"},
    { key:"9000", district:"31", Block:"208", label:"PUMS NARAYANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9001", district:"31", Block:"208", label:"PUMS ELARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9002", district:"31", Block:"208", label:"GOVT HSS JANGALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9003", district:"31", Block:"208", label:"PUMS ADIPERAMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9004", district:"31", Block:"208", label:"GOVT. GIRLS HSS NATRAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9005", district:"31", Block:"208", label:"PUMS ARASANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9006", district:"31", Block:"208", label:"GOVT. BOYS. HSS NATRAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9007", district:"31", Block:"208", label:"BRINDAVAN MATRIC HSS, NATRAMPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9008", district:"31", Block:"208", label:"GHS K BANDARAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"9009", district:"31", Block:"208", label:"MMS HINDU  PERIYAPET", Sch_type:"Government", Category:"Middle School"},
    { key:"9010", district:"31", Block:"208", label:"NAYA MADRASA  URDU MIDDLE SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"9011", district:"31", Block:"208", label:"ADARSH MATRIC.HSS, VANIYAMBADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9012", district:"31", Block:"82", label:"PUMS, KAVERIPET", Sch_type:"Government", Category:"Middle School"},
    { key:"9013", district:"31", Block:"82", label:"PUMS, POONAIKUTTAIPALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9014", district:"31", Block:"82", label:"PUMS, CHINNA MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9015", district:"31", Block:"82", label:"PUMS CHINNAGOUNDANUR (P)", Sch_type:"Government", Category:"Middle School"},
    { key:"9016", district:"31", Block:"82", label:"GOVT HSS PONNERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9017", district:"31", Block:"82", label:"GGHSS JOLARPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9018", district:"31", Block:"82", label:"ST.JOSEPH GIRLS HSS JOLARPET", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9019", district:"31", Block:"82", label:"AHSS DONBOSCO - JOLARPET", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9020", district:"31", Block:"82", label:"GOVT HSS VAKKANAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9021", district:"31", Block:"82", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  JOLARPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9022", district:"31", Block:"82", label:"SRI RAMAKRISHNA VIDHYALAYA MHSS JOLARPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9023", district:"31", Block:"82", label:"INFANT JESUS MATRIC SCHOOL, JOLARPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"9024", district:"31", Block:"82", label:"UNIVERSAL MATRIC HSS, JOLARPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9025", district:"31", Block:"82", label:"GHSS ATHANAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9026", district:"31", Block:"82", label:"ST.CHARLES AHSS ATHANAVUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9027", district:"31", Block:"82", label:"PUMS, KATTERI WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"9028", district:"31", Block:"82", label:"Sri Vinayaka Matriculation School,", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"9029", district:"31", Block:"82", label:"GHSS  THAMALERIMUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9030", district:"31", Block:"82", label:"PUMS, POOSANIKKAIVATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9031", district:"31", Block:"82", label:"GOVT HS THIRIYALAM", Sch_type:"Government", Category:"High School"},
    { key:"9032", district:"31", Block:"82", label:"DEWAN MOHAMED MEMORIAL MATRIC  HR SEC SCHOOL ,  KETHANDAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9033", district:"31", Block:"82", label:"GHS CHINNAKAMMIYAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9034", district:"31", Block:"82", label:"GOVT HS VETTAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9035", district:"31", Block:"82", label:"PUMS, CHINNAVEPPAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"9036", district:"31", Block:"82", label:"PUMS CHINNAKALLUPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9037", district:"31", Block:"82", label:"PUMS, KUNICHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9038", district:"31", Block:"82", label:"PUMS, KITTAPAIYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9039", district:"31", Block:"82", label:"PUMS, VEERAGOUNDANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9040", district:"31", Block:"82", label:"GOVT HSS VELAKKALNATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9041", district:"31", Block:"82", label:"GOVT HS CHANDRAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9042", district:"31", Block:"82", label:"PUMS, VEPPALNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9043", district:"31", Block:"82", label:"GHSS JAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9044", district:"31", Block:"82", label:"PUMS, SUNNAMBU KUTTAI (IVIL)", Sch_type:"Government", Category:"Middle School"},
    { key:"9045", district:"31", Block:"82", label:"PUMS, MUTHANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9046", district:"31", Block:"82", label:"GHSS MALLAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9047", district:"31", Block:"82", label:"PUMS, KATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9048", district:"31", Block:"82", label:"PUMS, PUDUPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9049", district:"31", Block:"82", label:"GOVT BHSS PUDUPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9050", district:"31", Block:"82", label:"GOVT (G)HSS PUDUPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9051", district:"31", Block:"82", label:"PUMS, OM SAKTHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"9052", district:"31", Block:"82", label:"GOVT HIGH SCHOOL AGRAHARAM", Sch_type:"Government", Category:"High School"},
    { key:"9053", district:"31", Block:"82", label:"PUMS, MOOKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9054", district:"31", Block:"82", label:"PUMS  PUDUR MOOKANUR ", Sch_type:"Government", Category:"Middle School"},
    { key:"9055", district:"31", Block:"82", label:"PUMS ADIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9056", district:"31", Block:"82", label:"GOVT HS PARANDAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"9057", district:"31", Block:"82", label:"GOVT HS PUTHAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"9058", district:"31", Block:"82", label:"PUMS, KURUMBER  COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"9059", district:"31", Block:"82", label:"DON BOSCO MATRIC HSS, THIRUPATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9060", district:"31", Block:"82", label:"ANT MATRIC SCHOOL, THIRUPATTUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"9061", district:"31", Block:"82", label:"PUMS, PULLANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"9062", district:"31", Block:"362", label:"GHSS PALNANGKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9063", district:"31", Block:"362", label:"GOVT HS ANNADAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9064", district:"31", Block:"362", label:"GOVT HS ACHAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"9065", district:"31", Block:"362", label:"GOVT GIRLS HSS MADAVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9066", district:"31", Block:"362", label:"GOVT BOYS HSS MADAVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9067", district:"31", Block:"362", label:"GOVT HS KODUMMAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"9068", district:"31", Block:"362", label:"GHS ARUNTHATHIYAR  COLONY", Sch_type:"Government", Category:"High School"},
    { key:"9069", district:"31", Block:"362", label:"GOVT HSS  BOMMIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9070", district:"31", Block:"362", label:"GOVT HS THIMMANAMATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9071", district:"31", Block:"362", label:"GOVT HS JAMANAPUDURPOONGULAM", Sch_type:"Government", Category:"High School"},
    { key:"9072", district:"31", Block:"5", label:"PUMS BALAPPALNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9073", district:"31", Block:"5", label:"GOVT HR. SEC. SCHOOL POONGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9074", district:"31", Block:"5", label:"GOVT HS MITTUR", Sch_type:"Government", Category:"High School"},
    { key:"9075", district:"31", Block:"5", label:"GOVT HR SEC SCHOOL MITTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9076", district:"31", Block:"362", label:"GOVT HSS ANDIYAPPANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9077", district:"31", Block:"362", label:"GOVT HS PERUMAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9078", district:"31", Block:"362", label:"GOVT HSS VADUKAMUTHAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9079", district:"31", Block:"362", label:"AHSS TMS TIRUPATTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9080", district:"31", Block:"362", label:"AHSS RAMAKRISHNA TIRUPATTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9081", district:"31", Block:"362", label:"DOMINIC SAVIO HSS TIRUPATTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9082", district:"31", Block:"362", label:"AGHSS UBAIBAS TIRUPATTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9083", district:"31", Block:"362", label:"MARY IMMACULATE GHSS TIRUPATTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9084", district:"31", Block:"362", label:"GOVT BOYS HSS TIRUPATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9085", district:"31", Block:"362", label:"GOVT MGHSS TIRUPATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9086", district:"31", Block:"362", label:"GOVT GARDEN HS TIRUPATTUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9087", district:"31", Block:"362", label:"AHSS OSMANIA TIRUPATTUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9088", district:"31", Block:"103", label:"GOVT HS MANDALANAYANAGUNDA", Sch_type:"Government", Category:"High School"},
    { key:"9089", district:"31", Block:"103", label:"GHS GUMMIDIGAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9090", district:"31", Block:"103", label:"GOVT GIRLS HSS GAJALNAYAKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9091", district:"31", Block:"103", label:"IVN GOVT BOYS HR SEC SCHOOL GAJALNAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9092", district:"31", Block:"103", label:"VAILANKANNI MATRICULATION SCHOOL VENGALAPURAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9093", district:"31", Block:"103", label:"JOTHI HS GAJALNAICKANPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9094", district:"31", Block:"103", label:"PUMS, P.MUTHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9095", district:"31", Block:"103", label:"DR CHANDRALEKHA MATRIC HSS, TIRUPATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9096", district:"31", Block:"103", label:"GOVERNMENT HIGH SCHOOL , VENGALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9097", district:"31", Block:"103", label:"INDIAN MODERN MATRIC SCHOOL, KASINAICKANPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9098", district:"31", Block:"103", label:"LINGANNAMANI MATRIC HSS, THAYAPPA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9099", district:"31", Block:"103", label:"PUMS CHINNAKASINAYAKKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9100", district:"31", Block:"103", label:"GOVT HS KASINAICKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9101", district:"31", Block:"103", label:"SHRI AMRITA HSS ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9102", district:"31", Block:"103", label:"PUMS, LAKKINAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9103", district:"31", Block:"103", label:"PUMS JALLIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9104", district:"31", Block:"103", label:"GOVT HS PERIYAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"9105", district:"31", Block:"103", label:"PUMS TELUNGUMATRAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9106", district:"31", Block:"103", label:"PUMS, MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9107", district:"31", Block:"103", label:"GHS KIZHAKKU BADANAVADI", Sch_type:"Government", Category:"High School"},
    { key:"9108", district:"31", Block:"103", label:"GOVT HSS SUNDHARAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9109", district:"31", Block:"103", label:"GOVT HSS NATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9110", district:"31", Block:"103", label:"PUMS  KOLKANUR ", Sch_type:"Government", Category:"Middle School"},
    { key:"9111", district:"31", Block:"103", label:"GHSS PERIYAKANNALAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9112", district:"31", Block:"103", label:"SWAMI VIVEKANANDHA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"9113", district:"31", Block:"103", label:"GOVT HS SEVVATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9114", district:"31", Block:"103", label:"SRI BALA VIDYALAYA MATRIC SCHOOL, SEVVATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"9115", district:"31", Block:"103", label:"GOVT HSS KUNICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9116", district:"31", Block:"103", label:"GOVT HS ELAVAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9117", district:"31", Block:"103", label:"SHRI VENKATESWARA MATRIC HR. SEC. SCHOOL, THORANAMPATHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9118", district:"31", Block:"103", label:"GOVT HS THORANAMPATHY", Sch_type:"Government", Category:"High School"},
    { key:"9119", district:"31", Block:"103", label:"GOVT HSS KORATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9120", district:"31", Block:"103", label:"GOVT HSS VISHAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9121", district:"31", Block:"103", label:"ST. MARY'S MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9122", district:"31", Block:"103", label:"GOVT HS ANGANATHAVALASAI", Sch_type:"Government", Category:"High School"},
    { key:"9123", district:"31", Block:"103", label:"GOVT HSS PERAMPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9124", district:"31", Block:"103", label:"ADWMS KURUMBERI", Sch_type:"Government", Category:"Middle School"},
    { key:"9125", district:"31", Block:"103", label:"GHSS KURUMBERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9126", district:"31", Block:"103", label:"GOVT HSS MATRAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9127", district:"6", Block:"58", label:"PUMS A.GOLLAHALLI                 ", Sch_type:"Government", Category:"Middle School"},
    { key:"9128", district:"6", Block:"58", label:"GHSS ADHAGAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9129", district:"6", Block:"58", label:"PUMS ALIVAYANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9130", district:"6", Block:"58", label:"PUMS ATTUKARANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9131", district:"6", Block:"58", label:"GHS BATHIREDDIHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9132", district:"6", Block:"58", label:"MMS FORT URDU", Sch_type:"Government", Category:"Middle School"},
    { key:"9133", district:"6", Block:"58", label:"MMS ANNASAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9134", district:"6", Block:"58", label:"MMS IV WARD - S.V.ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"9135", district:"6", Block:"58", label:"MMS KUMARASAMI PET", Sch_type:"Government", Category:"Middle School"},
    { key:"9136", district:"6", Block:"58", label:"MMS MATHIKONPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9137", district:"6", Block:"58", label:"MMS SANDAIPET", Sch_type:"Government", Category:"Middle School"},
    { key:"9138", district:"6", Block:"58", label:"GHS KIRUBANANDA Variyar", Sch_type:"Government", Category:"High School"},
    { key:"9139", district:"6", Block:"58", label:"MHS MUNICIPAL GIRLS", Sch_type:"Government", Category:"High School"},
    { key:"9140", district:"6", Block:"58", label:"Adhiyaman GBHSS Dharmapuri", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9141", district:"6", Block:"58", label:"GHSS AVVAIYAR GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9142", district:"6", Block:"58", label:"SRI VIJAY VIDHYASHRAM, DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9143", district:"6", Block:"58", label:"PUMS GENGANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9144", district:"6", Block:"58", label:"GHS GULIYANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9145", district:"6", Block:"58", label:"PUMS GUNDALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9146", district:"6", Block:"58", label:"GHSS HALE DHARMAPURI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9147", district:"6", Block:"58", label:"FATHIMA AIDED MIDDLE SCHOOL, KADAGATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"9148", district:"6", Block:"58", label:"GHSS KADAGATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9149", district:"6", Block:"58", label:"BAARATH MATRIC SCHOOL, PACHINAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9150", district:"6", Block:"58", label:"PUMS KATTUR MARIAMMAN KOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"9151", district:"6", Block:"58", label:"PUMS NARASINGAPURAM KOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9152", district:"6", Block:"58", label:"GHSS KONANGINAICKANAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9153", district:"6", Block:"58", label:"GHSS KRISHNAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9154", district:"6", Block:"58", label:"KAMARASAR MATRIC SCHOOL, KRISHNAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"9155", district:"6", Block:"58", label:"PUMS KUPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9156", district:"6", Block:"58", label:"GHS LAKKIYAMPATTI GIRLS", Sch_type:"Government", Category:"High School"},
    { key:"9157", district:"6", Block:"58", label:"GHSS LAKKIYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9158", district:"6", Block:"58", label:"GHSS FOR THE HEARING IMPAIRED DHARMAPURI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9159", district:"6", Block:"58", label:"JACK & JILL MHSS, DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9160", district:"6", Block:"58", label:"ROTARY VIVEKANANDA SCHOOL FOR HEARING IMPAIRED, PIDAMANERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9161", district:"6", Block:"58", label:"PACHAMUTHU MHSS, DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9162", district:"6", Block:"58", label:"KENDIRA VIDYALAYA CBSE", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"9163", district:"6", Block:"58", label:"PUMS LALAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9164", district:"6", Block:"58", label:"GHS M ODDAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9165", district:"6", Block:"58", label:"PUMS MANALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9166", district:"6", Block:"58", label:"PUMS MOOLAKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"9167", district:"6", Block:"58", label:"SRI SANKARA VIDYALAYA MATRIC SCHOOL, GUNCHEETIPATTI, AKKAMANAHALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9168", district:"6", Block:"58", label:"GHS MOOKKANAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9169", district:"6", Block:"58", label:"GHSS MUKKALNAICKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9170", district:"6", Block:"58", label:"PUMS MUTHUGOUNDANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9171", district:"6", Block:"58", label:"PUMS MUTHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9172", district:"6", Block:"58", label:"PUMS PACHINAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9173", district:"6", Block:"58", label:"GHS PALLAKOLLAI", Sch_type:"Government", Category:"High School"},
    { key:"9174", district:"6", Block:"58", label:"PUMS RAJATHOPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"9175", district:"6", Block:"58", label:"PUMS S.KOTTAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9176", district:"6", Block:"58", label:"PUMS A SEKKARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9177", district:"6", Block:"58", label:"CARMEL MATRIC SCHOOL, SEMMANDAKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"9178", district:"6", Block:"58", label:"PUMS SIKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9179", district:"6", Block:"58", label:"GHSS SOLAIKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9180", district:"6", Block:"58", label:"DR. RAMADOSS HR. SEC. SCHOOL, SOLAIKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9181", district:"6", Block:"58", label:"PUMS SOWLUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"9182", district:"6", Block:"58", label:"SREE SIVANIVEL HIGH SCHOOL, UNGARANAHALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9183", district:"6", Block:"58", label:"GHSS V MUTHAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9184", district:"6", Block:"58", label:"GHSS VATHALMALAI PERIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9185", district:"6", Block:"58", label:"PUMS VENKATANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9186", district:"6", Block:"58", label:"PUMS VEPPAMARATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9187", district:"6", Block:"58", label:"PUMS A.NADUHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9188", district:"6", Block:"58", label:"GHS K NADUHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9189", district:"6", Block:"58", label:"PUMS MARAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9190", district:"6", Block:"58", label:"GHS MITTANOOLAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9191", district:"6", Block:"58", label:"GHS NAICKENKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"9192", district:"6", Block:"58", label:"PUMS PERIYAKURAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9193", district:"6", Block:"58", label:"PUMS PULIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9194", district:"6", Block:"58", label:"PUMS PUZHUTHIKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9195", district:"6", Block:"58", label:"ADWMS RAJAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9196", district:"6", Block:"58", label:"PUMS SOGATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9197", district:"6", Block:"58", label:"VARIYAR HR. SEC. SCHOOL, SOGATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9198", district:"6", Block:"58", label:"GHS THIPPIREDDIHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9199", district:"6", Block:"58", label:"PUMS V.GOLLAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9200", district:"6", Block:"58", label:"GHS VELLOLAI", Sch_type:"Government", Category:"High School"},
    { key:"9201", district:"6", Block:"198", label:"AAGHSS ADHIYAMANKOTTAI BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9202", district:"6", Block:"198", label:"GHSS ADHIYAMANKOTTAI GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9203", district:"6", Block:"198", label:"NEW HORIZON SENIORS ACADEMY MHSS, CHETTIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9204", district:"6", Block:"198", label:"GHSS B AGRAHARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9205", district:"6", Block:"198", label:"GHSS BANDAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9206", district:"6", Block:"198", label:"GHS BEDRAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9207", district:"6", Block:"198", label:"PUMS BOOTHANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9208", district:"6", Block:"198", label:"PUMS EACHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9209", district:"6", Block:"198", label:"GHSS ELAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9210", district:"6", Block:"198", label:"PUMS EASALPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"9211", district:"6", Block:"198", label:"GHSS ERRABAIYANAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9212", district:"6", Block:"198", label:"GHS GETTUHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9213", district:"6", Block:"198", label:"GHS GOOLIKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"9214", district:"6", Block:"198", label:"GHSS INDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9215", district:"6", Block:"198", label:"PUMS KAMARAJNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"9216", district:"6", Block:"198", label:"ST. THOMAS GIRLS HIGH SCHOOL, KOVILUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"9217", district:"6", Block:"198", label:"ST JOHNS  HIGH SCHOOL, KOVILUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9218", district:"6", Block:"198", label:"GHSS LALIGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9219", district:"6", Block:"198", label:"GHSS MANIYATHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9220", district:"6", Block:"198", label:"PUMS MUNDASU PURAVADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9221", district:"6", Block:"198", label:"PUMS NADAPPANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9222", district:"6", Block:"198", label:"GHSS NALLAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9223", district:"6", Block:"198", label:"GHS NATHATHAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9224", district:"6", Block:"198", label:"PUMS OSHAHALLIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9225", district:"6", Block:"198", label:"GHSS PALAYAMPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9226", district:"6", Block:"198", label:"PUMS PALLAPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9227", district:"6", Block:"198", label:"PUMS CHINNAIYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9228", district:"6", Block:"198", label:"GHS PANGUNATHAMKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"9229", district:"6", Block:"198", label:"GHS POOVALMADUVU", Sch_type:"Government", Category:"High School"},
    { key:"9230", district:"6", Block:"198", label:"GHS SANTHARAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9231", district:"6", Block:"198", label:"GHS SOMENAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9232", district:"6", Block:"198", label:"GHS T GANIGARAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9233", district:"6", Block:"198", label:"SRI SIDDHI VINAYAKA MHSS, THADANGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9234", district:"6", Block:"198", label:"PUMS THALAVAIHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9235", district:"6", Block:"198", label:"GHSS THOPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9236", district:"6", Block:"198", label:"PUMS UMMIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9237", district:"6", Block:"198", label:"GHSS  VENKATTAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9238", district:"6", Block:"198", label:"AMALA HR. SEC. SCHOOL, VIMALAPURI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9239", district:"6", Block:"198", label:"GHS AVVAI NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"9240", district:"6", Block:"198", label:"GHS BARIGAM", Sch_type:"Government", Category:"High School"},
    { key:"9241", district:"6", Block:"198", label:"GHS DHATHANAYAKANPATTY", Sch_type:"Government", Category:"High School"},
    { key:"9242", district:"6", Block:"198", label:"GHS KOMATHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9243", district:"6", Block:"198", label:"GHS NAGARKOODAL", Sch_type:"Government", Category:"High School"},
    { key:"9244", district:"6", Block:"198", label:"GHS NARTHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9245", district:"6", Block:"198", label:"GHS PALAVADI", Sch_type:"Government", Category:"High School"},
    { key:"9246", district:"6", Block:"198", label:"GHS RAJAGOLLAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9247", district:"6", Block:"198", label:"PUMS SIVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9248", district:"6", Block:"198", label:"GHS THAMMANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9249", district:"6", Block:"235", label:"PUMS ALAMARATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9250", district:"6", Block:"235", label:"GHS ATHIMARATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9251", district:"6", Block:"235", label:"PUMS BADHARAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9252", district:"6", Block:"235", label:"PUMS BOOTHINATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9253", district:"6", Block:"235", label:"PUMS CHINNAPALLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9254", district:"6", Block:"235", label:"PUMS CHINNAPPANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9255", district:"6", Block:"235", label:"GHS CHINNAVATHALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9256", district:"6", Block:"235", label:"GHS DASAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9257", district:"6", Block:"235", label:"PUMS DHONNAGUDDAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9258", district:"6", Block:"235", label:"GHS EARIMALAI", Sch_type:"Government", Category:"High School"},
    { key:"9259", district:"6", Block:"235", label:"GHSS ERIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9260", district:"6", Block:"235", label:"GHS ERRAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9261", district:"6", Block:"235", label:"GHS ETTIKUTTAI", Sch_type:"Government", Category:"High School"},
    { key:"9262", district:"6", Block:"235", label:"PUMS ETTIKUZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"9263", district:"6", Block:"235", label:"PUMS GODUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9264", district:"6", Block:"235", label:"GHS HALEPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9265", district:"6", Block:"235", label:"GHSS KALAPPAMBADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9266", district:"6", Block:"235", label:"GHS KARIAPPANAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9267", district:"6", Block:"235", label:"PUMS KONDAIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9268", district:"6", Block:"235", label:"PUMS KOOTHAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9269", district:"6", Block:"235", label:"GHSS KULLANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9270", district:"6", Block:"235", label:"PUMS M.SANJEEVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9271", district:"6", Block:"235", label:"GHSS MANGARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9272", district:"6", Block:"235", label:"PUMS MANGURUCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"9273", district:"6", Block:"235", label:"PUMS MALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9274", district:"6", Block:"235", label:"GHS MANJANAIKANAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9275", district:"6", Block:"235", label:"PUMS MOONGILMADUVU", Sch_type:"Government", Category:"Middle School"},
    { key:"9276", district:"6", Block:"235", label:"PUMS NAGAMARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9277", district:"6", Block:"235", label:"PUMS NALLAPPANAYAKKANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9278", district:"6", Block:"235", label:"SRI VISALI VIDYALAYA MATRIC SCHOOL, AATHANOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"9279", district:"6", Block:"235", label:"GHSS NERUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9280", district:"6", Block:"235", label:"PUMS PALIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"9281", district:"6", Block:"235", label:"GHS PALLIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9282", district:"6", Block:"235", label:"TSSGHSS PAUPARAPATTI - MODEL BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9283", district:"6", Block:"235", label:"GHSS GIRLS PAUPARAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9284", district:"6", Block:"235", label:"KGBV RESIDENTIAL SCHOOL, PAUPPARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9285", district:"6", Block:"235", label:"PUMS PAVALANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9286", district:"6", Block:"235", label:"GHSS PENNAGARAM Boys", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9287", district:"6", Block:"235", label:"GHSS PENNAGARAM GIRLS ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9288", district:"6", Block:"235", label:"SRI VINAYAGA MHSS, PENNAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9289", district:"6", Block:"235", label:"APPOLLO MHSS, PENNAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9290", district:"6", Block:"235", label:"GHS PERIYUR", Sch_type:"Government", Category:"High School"},
    { key:"9291", district:"6", Block:"235", label:"GHSS PERUMBALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9292", district:"6", Block:"235", label:"ADHIYAMAN HIGH SCHOOL, RAMAKONDAHALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9293", district:"6", Block:"235", label:"GHS POOCHUR", Sch_type:"Government", Category:"High School"},
    { key:"9294", district:"6", Block:"235", label:"PUMS PUDUNAGAMARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9295", district:"6", Block:"235", label:"GHS PUDUBALASAMUTHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"9296", district:"6", Block:"235", label:"GHS PUDUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9297", district:"6", Block:"235", label:"GHS RAMAKONDAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9298", district:"6", Block:"235", label:"PUMS SAMATHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"9299", district:"6", Block:"235", label:"GHS KOTTAYUR", Sch_type:"Government", Category:"High School"},
    { key:"9300", district:"6", Block:"235", label:"GHS SEELANAYAKKANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9301", district:"6", Block:"235", label:"GHSS SELLAMUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9302", district:"6", Block:"235", label:"PUMS SITLAKARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9303", district:"6", Block:"235", label:"PUMS TEAKADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9304", district:"6", Block:"235", label:"GHS THIRUMALVADI", Sch_type:"Government", Category:"High School"},
    { key:"9305", district:"6", Block:"235", label:"PUMS THITHIOPPANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9306", district:"6", Block:"235", label:"PUMS THOTLANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9307", district:"6", Block:"235", label:"PUMS VARAGOORANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9308", district:"6", Block:"235", label:"GHS ALAMARATHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9309", district:"6", Block:"235", label:"GHS CHINNAMBALLI", Sch_type:"Government", Category:"High School"},
    { key:"9310", district:"6", Block:"235", label:"MODEL HSS CHINNAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9311", district:"6", Block:"235", label:"PUMS KADAMADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9312", district:"6", Block:"235", label:"PUMS NAGANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9313", district:"6", Block:"235", label:"GHSS OOTTAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9314", district:"6", Block:"235", label:"GHSS P.GOLLAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9315", district:"6", Block:"218", label:"GHSS AMANIMALLAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9316", district:"6", Block:"218", label:"GHSS BELARAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9317", district:"6", Block:"218", label:"GHSS BELLUHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9318", district:"6", Block:"218", label:"GHS BOOGANAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9319", district:"6", Block:"218", label:"GHS BOPPIDI", Sch_type:"Government", Category:"High School"},
    { key:"9320", district:"6", Block:"218", label:"GHS GOLASANAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9321", district:"6", Block:"218", label:"GHSS KAMMALAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9322", district:"6", Block:"218", label:"KGBV RESIDENTIAL SCHOOL, AMANIMALLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9323", district:"6", Block:"218", label:"GHS KOVILUR", Sch_type:"Government", Category:"High School"},
    { key:"9324", district:"6", Block:"218", label:"GHSS MARANDAHALLI - BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9325", district:"6", Block:"218", label:"GHSS MARANDAHALLI - GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9326", district:"6", Block:"218", label:"GHS MATTIYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9327", district:"6", Block:"218", label:"GHSS PALACODE - BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9328", district:"6", Block:"218", label:"GHSS PALACODE - GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9329", district:"6", Block:"218", label:"GHSS  PANJAPPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9330", district:"6", Block:"218", label:"GOVT.THANTHAI PERIYAR JUVANILE", Sch_type:"Government", Category:"Middle School"},
    { key:"9331", district:"6", Block:"218", label:"GHSS PULIKKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9332", district:"6", Block:"218", label:"GHS SASTHIRAMUTULU", Sch_type:"Government", Category:"High School"},
    { key:"9333", district:"6", Block:"218", label:"GHS SEKKODI", Sch_type:"Government", Category:"High School"},
    { key:"9334", district:"6", Block:"218", label:"SACRED HEART HR. SEC. SCHOOL, SELLIYAMPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9335", district:"6", Block:"218", label:"GHSS THIRUMALVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9336", district:"6", Block:"218", label:"PUMS NAMMANDAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9337", district:"6", Block:"218", label:"GHS SOMANAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9338", district:"6", Block:"109", label:"PUMS ADDILAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9339", district:"6", Block:"109", label:"PUMS BALLENAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9340", district:"6", Block:"109", label:"GHSS BEGARAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9341", district:"6", Block:"109", label:"PUMS BODARA HALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9342", district:"6", Block:"109", label:"GHS MEKKANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9343", district:"6", Block:"109", label:"PUMS BOMMANDAHALLI MODUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9344", district:"6", Block:"109", label:"PUMS CHENNAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9345", district:"6", Block:"109", label:"PUMS DHASAMBAIL", Sch_type:"Government", Category:"Middle School"},
    { key:"9346", district:"6", Block:"109", label:"PUMS DHINDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"9347", district:"6", Block:"109", label:"PUMS ERRASEEGALA HALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9348", district:"6", Block:"109", label:"PUMS GENGUCHETTI PATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9349", district:"6", Block:"109", label:"PUMS GOLLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9350", district:"6", Block:"109", label:"PUMS GUNDALAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9351", district:"6", Block:"109", label:"GHSS HANUMANTHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9352", district:"6", Block:"109", label:"GHSS JAKKASAMUDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9353", district:"6", Block:"109", label:"PUMS JAMBOOTH", Sch_type:"Government", Category:"Middle School"},
    { key:"9354", district:"6", Block:"109", label:"PUMS JITTANDAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9355", district:"6", Block:"109", label:"PUMS KULI KADU", Sch_type:"Government", Category:"Middle School"},
    { key:"9356", district:"6", Block:"109", label:"PUMS KAMARAJ NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"9357", district:"6", Block:"109", label:"GHSS KANNIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9358", district:"6", Block:"109", label:"PUMS KARAGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9359", district:"6", Block:"109", label:"GHSS KARIMANGALAM - BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9360", district:"6", Block:"109", label:"GHSS KARIMANGALAM - GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9361", district:"6", Block:"109", label:"BHARATH MATRIC SCHOOL, KARIMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"9362", district:"6", Block:"109", label:"KGBV RESIDENTIAL SCHOOL, KARIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9363", district:"6", Block:"109", label:"SHANTHINIKETHAN HR. SEC. SCHOOL, KARIMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9364", district:"6", Block:"109", label:"PUMS KATHIRAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9365", district:"6", Block:"109", label:"PUMS KERAKODA HALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9366", district:"6", Block:"109", label:"PUMS KOTHALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9367", district:"6", Block:"109", label:"GHS KOTTUMARANA HALLI", Sch_type:"Government", Category:"High School"},
    { key:"9368", district:"6", Block:"109", label:"PUMS KOVILOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9369", district:"6", Block:"109", label:"PUMS KUPPANGARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9370", district:"6", Block:"109", label:"GHS MADHAGIRI", Sch_type:"Government", Category:"High School"},
    { key:"9371", district:"6", Block:"109", label:"GHSS KAMALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9372", district:"6", Block:"109", label:"GHSS MALLUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9373", district:"6", Block:"109", label:"PUMS MARAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9374", district:"6", Block:"109", label:"GHSS MATLAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9375", district:"6", Block:"109", label:"PUMS MOLAPPANA HALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9376", district:"6", Block:"109", label:"PUMS MORASU HALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9377", district:"6", Block:"109", label:"PUMS MOTTALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9378", district:"6", Block:"109", label:"PUMS MUKKULAMSEEGALAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9379", district:"6", Block:"109", label:"PUMS NAGANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9380", district:"6", Block:"109", label:"PUMS PARAIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9381", district:"6", Block:"109", label:"PUMS PERIYADHABBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9382", district:"6", Block:"109", label:"GHSS PERIYAMPATTI Boys", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9383", district:"6", Block:"109", label:"SRI ANNAMALAIYAR MATRIC SCHOOL, KALAPPANAHALLI, KARIMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"9384", district:"6", Block:"109", label:"PUMS PULIKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"9385", district:"6", Block:"109", label:"PUMS RAMIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9386", district:"6", Block:"109", label:"PUMS SOKKANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9387", district:"6", Block:"109", label:"PUMS SONNAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9388", district:"6", Block:"109", label:"PUMS SOTTANDAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9389", district:"6", Block:"109", label:"PUMS SUNNAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9390", district:"6", Block:"109", label:"GHSS BANDHARAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9391", district:"6", Block:"109", label:"GHSS BOMMAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9392", district:"6", Block:"109", label:"PUMS ELUMICHANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9393", district:"6", Block:"109", label:"PUMS KUMBARAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9394", district:"6", Block:"109", label:"PUMS MAHENDRAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9395", district:"6", Block:"109", label:"PUMS KANDAGA BILE", Sch_type:"Government", Category:"Middle School"},
    { key:"9396", district:"6", Block:"187", label:"GHSS BUDDIREDDIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9397", district:"6", Block:"187", label:"MARUTHAM MHSS, MORAPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9398", district:"6", Block:"187", label:"GHSS CINTHALPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9399", district:"6", Block:"187", label:"GHSS M DODDAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9400", district:"6", Block:"187", label:"PUMS E.AGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9401", district:"6", Block:"187", label:"GHSS IRUMATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9402", district:"6", Block:"187", label:"GHSS KADATHUR - BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9403", district:"6", Block:"187", label:"GHSS KADATHUR - GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9404", district:"6", Block:"187", label:"KALAIMAGAL MHSS, KADATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9405", district:"6", Block:"187", label:"SWAMY VIVEKANANDA MATRIC SCHOOL, KADATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"9406", district:"6", Block:"187", label:"PUMS KADAYAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"9407", district:"6", Block:"187", label:"GHSS KAMBAINALLUR - GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9408", district:"6", Block:"187", label:"GHSS KAMBAINALLUR - BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9409", district:"6", Block:"187", label:"OXFORD MATRIC SCHOOL, SEMMANAHALLI, KAMBAINALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"9410", district:"6", Block:"187", label:"SACRED HEART MATRIC SCHOOL, NELLIMARATHUPATTI, KELAVALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9411", district:"6", Block:"187", label:"GHS LINGANAICKANAHALLY", Sch_type:"Government", Category:"High School"},
    { key:"9412", district:"6", Block:"187", label:"GHSS MORAPPUR - BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9413", district:"6", Block:"187", label:"GHSS MORAPPUR - GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9414", district:"6", Block:"187", label:"VISHWA BHARATHI MHSS, MORAPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9415", district:"6", Block:"187", label:"PUMS OBLINAICKANAHALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"9416", district:"6", Block:"187", label:"GHSS ODASALPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9417", district:"6", Block:"187", label:"GHS PAPPICHETTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9418", district:"6", Block:"187", label:"PUMS PERIYURVATHALMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9419", district:"6", Block:"187", label:"GHSS R.GOPINATHAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9420", district:"6", Block:"187", label:"GHSS RAMIYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9421", district:"6", Block:"187", label:"PUMS REGADAHALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"9422", district:"6", Block:"187", label:"GHSS JALIPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9423", district:"6", Block:"187", label:"GHS SANTHAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"9424", district:"6", Block:"187", label:"PUMS SEKKANDAHALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"9425", district:"6", Block:"187", label:"GHS T.AYYAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"9426", district:"6", Block:"187", label:"PUMS THALANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9427", district:"6", Block:"187", label:"ST. JOSEPH'S HIGH SCHOOL, THENKARAIKOTTAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9428", district:"6", Block:"187", label:"GHSS THONGANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9429", district:"6", Block:"187", label:"PUMS VETHARAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"9430", district:"6", Block:"187", label:"GHS DASIRAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"9431", district:"6", Block:"187", label:"KONGU MHSS, MORAPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9432", district:"6", Block:"187", label:"GHS ELAVADAI", Sch_type:"Government", Category:"High School"},
    { key:"9433", district:"6", Block:"187", label:"PUMS HUNISINAHALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"9434", district:"6", Block:"187", label:"GHS KANDAGOUNDANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9435", district:"6", Block:"187", label:"GHS KETHUREDDIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"9436", district:"6", Block:"187", label:"GHSS   NATHAMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9437", district:"6", Block:"187", label:"GHSS   NAVALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9438", district:"6", Block:"187", label:"GHS POLAIYAMPALLY", Sch_type:"Government", Category:"High School"},
    { key:"9439", district:"6", Block:"187", label:"GHS SILLARAHALLY", Sch_type:"Government", Category:"High School"},
    { key:"9440", district:"6", Block:"187", label:"ACHIEVERS ACADEMY MHSS, SUNGARAHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9441", district:"6", Block:"187", label:"GHS THIPPAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"9442", district:"6", Block:"187", label:"GHS K.EACHCHAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"9443", district:"6", Block:"230", label:"PUMS A AMMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9444", district:"6", Block:"230", label:"PUMS A PALLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9445", district:"6", Block:"230", label:"GHSS  ADIGARAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9446", district:"6", Block:"230", label:"GHS ALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9447", district:"6", Block:"230", label:"GTRMS ARASANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9448", district:"6", Block:"230", label:"GHSS B.MALLAPURAM GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9449", district:"6", Block:"230", label:"GHSS B.MALLAPURAM BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9450", district:"6", Block:"230", label:"AMG MHSS, NADUR, BOMMIDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9451", district:"6", Block:"230", label:"CARMEL HR. SEC. SCHOOL, B. PALLIPATTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9452", district:"6", Block:"230", label:"ST.MARY'S MATRIC SCHOOL, B.PALLIPATTI, PAPPIREDDIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9453", district:"6", Block:"230", label:"GHSS B.THURINJIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9454", district:"6", Block:"230", label:"GHSS BAIRNATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9455", district:"6", Block:"230", label:"PUMS BOTHAKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"9456", district:"6", Block:"230", label:"GTRMS ELANTHAKOTTAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9457", district:"6", Block:"230", label:"PUMS GUNDALMADUVU", Sch_type:"Government", Category:"Middle School"},
    { key:"9458", district:"6", Block:"230", label:"KGV GHSS H.PUDUPATTI-BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9459", district:"6", Block:"230", label:"GTRHS KALASAPADI", Sch_type:"Government", Category:"High School"},
    { key:"9460", district:"6", Block:"230", label:"GHSS KALIPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9461", district:"6", Block:"230", label:"GTRMS KALLATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9462", district:"6", Block:"230", label:"PUMS KATHIRIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9463", district:"6", Block:"230", label:"GHS KOMBUR", Sch_type:"Government", Category:"High School"},
    { key:"9464", district:"6", Block:"230", label:"GTRMS MANKADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9465", district:"6", Block:"230", label:"GTRMS MANNUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9466", district:"6", Block:"230", label:"GHSS MENASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9467", district:"6", Block:"230", label:"GHS MOLAYANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9468", district:"6", Block:"230", label:"SREE VETRI VIKAS MHSS, MOLAYANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9469", district:"6", Block:"230", label:"PKV GHSS MOOKAREDDIPATTI GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9470", district:"6", Block:"230", label:"BLESSING MIDDLE SCHOOL, MULLERIKADU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"9471", district:"6", Block:"230", label:"PUMS MULLIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"9472", district:"6", Block:"230", label:"GTRMS NOCHIKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9473", district:"6", Block:"230", label:"PUMS ODDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9474", district:"6", Block:"230", label:"PUMS PAPPAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9475", district:"6", Block:"230", label:"GHSS PAPPIREDDIPATTI - BOYS ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9476", district:"6", Block:"230", label:"GHSS PAPPIREDDIPATTI GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9477", district:"6", Block:"230", label:"GTRMS PERERI", Sch_type:"Government", Category:"Middle School"},
    { key:"9478", district:"6", Block:"230", label:"PUMS PERERIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9479", district:"6", Block:"230", label:"GHS S.AMMAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"9480", district:"6", Block:"230", label:"PUMS SALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9481", district:"6", Block:"230", label:"PUMS SELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9482", district:"6", Block:"230", label:"GTRHSS SITHERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9483", district:"6", Block:"230", label:"GHS VASIGOUNDANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9484", district:"6", Block:"230", label:"GTRMS VELLAMPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"9485", district:"6", Block:"230", label:"GHS VENKATASAMUDRAM", Sch_type:"Government", Category:"High School"},
    { key:"9486", district:"6", Block:"230", label:"GHS LAKSHMAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9487", district:"6", Block:"230", label:"GHS PILPARUTHI", Sch_type:"Government", Category:"High School"},
    { key:"9488", district:"6", Block:"230", label:"PUMS SAMIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9489", district:"6", Block:"77", label:"GHS A VELAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9490", district:"6", Block:"77", label:"PUMS  C GOBINATHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9491", district:"6", Block:"77", label:"GHS CHINNANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9492", district:"6", Block:"77", label:"GHS ELLAPUDIYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9493", district:"6", Block:"77", label:"JAYAM VIDHYALAYA MHSS, H.DHODDAMPATTI, HARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9494", district:"6", Block:"77", label:"GHSS HARUR BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9495", district:"6", Block:"77", label:"GHSS HARUR GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9496", district:"6", Block:"77", label:"ST. MARY'S HR. SEC. SCHOOL, HARUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9497", district:"6", Block:"77", label:"GHSS JAMMANAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9498", district:"6", Block:"77", label:"GHSS K VETRAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9499", district:"6", Block:"77", label:"GHS KEERAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9500", district:"6", Block:"77", label:"GHS KELAPPARAI", Sch_type:"Government", Category:"High School"},
    { key:"9501", district:"6", Block:"77", label:"PUMS KOMARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9502", district:"6", Block:"77", label:"PUMS KONGAVEMBU", Sch_type:"Government", Category:"Middle School"},
    { key:"9503", district:"6", Block:"77", label:"ARINGAR ANNA GHSS KOTTAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9504", district:"6", Block:"77", label:"GHS M.THATHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9505", district:"6", Block:"77", label:"GHS MAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9506", district:"6", Block:"77", label:"SRI SARASWATHY MHSS, ITLAPPATTI, HARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9507", district:"6", Block:"77", label:"DR. ANNAL AMBEDKAR GOVT. ADWHSS, MARUTHIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9508", district:"6", Block:"77", label:"GHS MATHIAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9509", district:"6", Block:"77", label:"GHSS NARIPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9510", district:"6", Block:"77", label:"GTRHSS NARIPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9511", district:"6", Block:"77", label:"GHS PETHATHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9512", district:"6", Block:"77", label:"PUMS RAYAPPAN KOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9513", district:"6", Block:"77", label:"GTRHS S.THATHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9514", district:"6", Block:"77", label:"GHS SATTAIYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9515", district:"6", Block:"77", label:"GHS SELLAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9516", district:"6", Block:"77", label:"R.V. HR. SEC. SCHOOL, SENDRAYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9517", district:"6", Block:"77", label:"GHS T AMMAPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"9518", district:"6", Block:"77", label:"GHSS THEERTHAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9519", district:"6", Block:"77", label:"ERK HR. SEC. SCHOOL, ERUMIYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9520", district:"6", Block:"77", label:"GHS VEDAKATTAMADUVU", Sch_type:"Government", Category:"High School"},
    { key:"9521", district:"6", Block:"77", label:"GHS VELANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9522", district:"6", Block:"77", label:"GHSS VEPPAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9523", district:"6", Block:"77", label:"BHARATHI VIDYALAYA HIGH SCHOOL, VEPPAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"9524", district:"6", Block:"77", label:"GHS BAIRNAICKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9525", district:"6", Block:"77", label:"GHS PARAYAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9526", district:"6", Block:"77", label:"INDIAN MHSS, GOPINATHAMPATTI, PARAIYAPPATTI PUDUR, HARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9527", district:"6", Block:"77", label:"GHS PONNERI", Sch_type:"Government", Category:"High School"},
    { key:"9528", district:"6", Block:"77", label:"GHS SIKKALURE", Sch_type:"Government", Category:"High School"},
    { key:"9529", district:"6", Block:"77", label:"GHSS SITLINGI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9530", district:"6", Block:"77", label:"GTRHSS VACHATHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9531", district:"34", Block:"395", label:"ADWHS ARASANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9532", district:"34", Block:"395", label:"GHSS AZHIVIDAITHANGI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9533", district:"34", Block:"395", label:"GHS BUDERI", Sch_type:"Government", Category:"High School"},
    { key:"9534", district:"34", Block:"395", label:"GHSS BRAMMADESAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9535", district:"34", Block:"395", label:"GHSS CHITHATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9536", district:"34", Block:"395", label:"GHS DUSI", Sch_type:"Government", Category:"High School"},
    { key:"9537", district:"34", Block:"395", label:"GHSS MAMANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9538", district:"34", Block:"395", label:"PUMS MATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9539", district:"34", Block:"395", label:"GHSS MENALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9540", district:"34", Block:"395", label:"GHS NAMANDI", Sch_type:"Government", Category:"High School"},
    { key:"9541", district:"34", Block:"395", label:"GHS NATTERI", Sch_type:"Government", Category:"High School"},
    { key:"9542", district:"34", Block:"395", label:"GHS PALLAVARAM", Sch_type:"Government", Category:"High School"},
    { key:"9543", district:"34", Block:"395", label:"GGHSS PERUNGATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9544", district:"34", Block:"395", label:"GHSS(BOYS) PERUNGATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9545", district:"34", Block:"395", label:"GHSS PUNNAI PUDUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9546", district:"34", Block:"395", label:"GHSS RANDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9547", district:"34", Block:"395", label:"GHS SATTUVANTHANGAL", Sch_type:"Government", Category:"High School"},
    { key:"9548", district:"34", Block:"395", label:"GHS THENNAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9549", district:"34", Block:"395", label:"ADWMS VADAILLUPPAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9550", district:"34", Block:"395", label:"GHSS VADAILLUPPAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9551", district:"34", Block:"395", label:"GHSS VADAMANAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9552", district:"34", Block:"395", label:"GBHSS-VEMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9553", district:"34", Block:"395", label:"GHSS(GIRLS) VEMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9554", district:"34", Block:"395", label:"GHS VENGALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9555", district:"34", Block:"395", label:"PUMS THIRUPPANANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"9556", district:"34", Block:"48", label:"GHS HOUSING BOARD", Sch_type:"Government", Category:"High School"},
    { key:"9557", district:"34", Block:"48", label:"GHS KAZHAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"9558", district:"34", Block:"48", label:"GHSS KORUKKAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9559", district:"34", Block:"48", label:"GHS KORUKKATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9560", district:"34", Block:"48", label:"GHS KUNNATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9561", district:"34", Block:"48", label:"GHS MELSEESHAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"9562", district:"34", Block:"48", label:"GHS MELMATTAI VINNAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"9563", district:"34", Block:"48", label:"KNNC GHSS MUNUGAPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9564", district:"34", Block:"48", label:"GHSS NEDUMBIRAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9565", district:"34", Block:"48", label:"GHSS- PALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9566", district:"34", Block:"48", label:"GHS PAPPANTHANGAL", Sch_type:"Government", Category:"High School"},
    { key:"9567", district:"34", Block:"48", label:"ADWHS PULIYARAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"9568", district:"34", Block:"48", label:"GHS THIRUMANI", Sch_type:"Government", Category:"High School"},
    { key:"9569", district:"34", Block:"48", label:"GHS VADUGAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9570", district:"34", Block:"48", label:"GHSS-VAZHKUDAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9571", district:"34", Block:"48", label:"VICTORY MATRIC, VINNAVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9572", district:"34", Block:"48", label:"GHS THIRUVOTHUR", Sch_type:"Government", Category:"High School"},
    { key:"9573", district:"34", Block:"48", label:"GHSS(BOYS) CHEYYAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9574", district:"34", Block:"48", label:"GMHSS(GIRLS)- CHEYYAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9575", district:"34", Block:"48", label:"MHS GIRUDHARANPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"9576", district:"34", Block:"48", label:"ST PAULS HIGH SCHOOL CHEYYAR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9577", district:"34", Block:"48", label:"AIMS CBSE SENIOR SECONDARY SCHOOL, CHEYYAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9578", district:"34", Block:"14", label:"GHSS AKKUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9579", district:"34", Block:"14", label:"GHS ALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9580", district:"34", Block:"14", label:"GHSS ANAKKAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9581", district:"34", Block:"14", label:"GHS ANAPPATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9582", district:"34", Block:"14", label:"GHS KOOZHAMANDAL", Sch_type:"Government", Category:"High School"},
    { key:"9583", district:"34", Block:"14", label:"GHSS KOVILUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9584", district:"34", Block:"14", label:"GHS METTU ECHUR", Sch_type:"Government", Category:"High School"},
    { key:"9585", district:"34", Block:"14", label:"PUMS NEDUNGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"9586", district:"34", Block:"14", label:"GHSS PURISAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9587", district:"34", Block:"14", label:"GHS THENELAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"9588", district:"34", Block:"14", label:"GHS THETHURAI", Sch_type:"Government", Category:"High School"},
    { key:"9589", district:"34", Block:"14", label:"GHSS UKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9590", district:"34", Block:"14", label:"GHSS SENGADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9591", district:"34", Block:"238", label:"GHS ALLIYANDAL", Sch_type:"Government", Category:"High School"},
    { key:"9592", district:"34", Block:"238", label:"AUXILIUM MATRICULATION SCHOOL  ANMARUDAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"9593", district:"34", Block:"238", label:"GHS AVANIYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9594", district:"34", Block:"238", label:"GHS AYALAVADI", Sch_type:"Government", Category:"High School"},
    { key:"9595", district:"34", Block:"238", label:"GHSS(GIRLS)-KOLLAPPALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9596", district:"34", Block:"238", label:"GHSS(BOYS) KOLAPPALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9597", district:"34", Block:"238", label:"GHS MELACHARI KOTTUPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"9598", district:"34", Block:"238", label:"GHSS MADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9599", district:"34", Block:"238", label:"GHS MELATHANGAL", Sch_type:"Government", Category:"High School"},
    { key:"9600", district:"34", Block:"238", label:"GHS NAMATHODU", Sch_type:"Government", Category:"High School"},
    { key:"9601", district:"34", Block:"238", label:"GHSS NEDUNGUNAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9602", district:"34", Block:"238", label:"GHSS(BOYS) PERNAMALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9603", district:"34", Block:"238", label:"GHSS(GIRLS) PERNAMALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9604", district:"34", Block:"238", label:"GHS VALLAM", Sch_type:"Government", Category:"High School"},
    { key:"9605", district:"34", Block:"238", label:"GHS VAYALUR", Sch_type:"Government", Category:"High School"},
    { key:"9606", district:"34", Block:"387", label:"GHS AMUDUR", Sch_type:"Government", Category:"High School"},
    { key:"9607", district:"34", Block:"387", label:"GHS ATHIPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"9608", district:"34", Block:"387", label:"GHS BADHUR", Sch_type:"Government", Category:"High School"},
    { key:"9609", district:"34", Block:"387", label:"S.R.I INTERNATIONAL MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9610", district:"34", Block:"387", label:"GHSS IRUMBEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9611", district:"34", Block:"387", label:"GHSS KILKODUNGALORE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9612", district:"34", Block:"387", label:"GHS(GIRLS) KILKODUNGALORE", Sch_type:"Government", Category:"High School"},
    { key:"9613", district:"34", Block:"387", label:"GHSS KILKOVALAIVEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9614", district:"34", Block:"387", label:"PUMS-KILPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9615", district:"34", Block:"387", label:"PUMS KILSEMBEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"9616", district:"34", Block:"387", label:"PUMS KOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9617", district:"34", Block:"387", label:"GHSS MARUDADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9618", district:"34", Block:"387", label:"GHS NELLIYANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"9619", district:"34", Block:"387", label:"GHS SALAVEDU", Sch_type:"Government", Category:"High School"},
    { key:"9620", district:"34", Block:"387", label:"GHS SALUKKAI", Sch_type:"Government", Category:"High School"},
    { key:"9621", district:"34", Block:"387", label:"GHSS(BOYS)-VANDAVASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9622", district:"34", Block:"387", label:"GHSS(GIRLS)-VANDAVASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9623", district:"34", Block:"387", label:"RCM HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9624", district:"34", Block:"387", label:"SANNATHI HSS VANDAVASI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9625", district:"34", Block:"387", label:"SACRED HEART HSS(G)", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9626", district:"34", Block:"387", label:"GHS VELIYAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"9627", district:"34", Block:"387", label:"JUBILEE GLOBAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"9628", district:"34", Block:"318", label:"GGHS C.M.PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"9629", district:"34", Block:"318", label:"PUMS-CHETTIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9630", district:"34", Block:"318", label:"GHSS(BOYS) DESUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9631", district:"34", Block:"318", label:"GHSS(GIRLS) DESUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9632", district:"34", Block:"318", label:"PUMS GENGAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"9633", district:"34", Block:"318", label:"GHS KADAMBAI", Sch_type:"Government", Category:"High School"},
    { key:"9634", district:"34", Block:"318", label:"PUMS KANDAVARATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"9635", district:"34", Block:"318", label:"PUMS S.KATTERI", Sch_type:"Government", Category:"Middle School"},
    { key:"9636", district:"34", Block:"318", label:"PUMS KILNANDIYAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9637", district:"34", Block:"318", label:"GHS KILPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"9638", district:"34", Block:"318", label:"PUMS KILVELLIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9639", district:"34", Block:"318", label:"PUMS KILVILLIVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9640", district:"34", Block:"318", label:"GHS KODIYALAM", Sch_type:"Government", Category:"High School"},
    { key:"9641", district:"34", Block:"318", label:"GHS KONDAIYANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9642", district:"34", Block:"318", label:"PUMS KOOTHAMBATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"9643", district:"34", Block:"318", label:"GHS KORAKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"9644", district:"34", Block:"318", label:"GHSS KUNNAGAMPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9645", district:"34", Block:"318", label:"PUMS MANJAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"9646", district:"34", Block:"318", label:"GHSS MAZHAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9647", district:"34", Block:"318", label:"PUMS MEESANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9648", district:"34", Block:"318", label:"GHS MELPATHI", Sch_type:"Government", Category:"High School"},
    { key:"9649", district:"34", Block:"318", label:"GHS NADUKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9650", district:"34", Block:"318", label:"GHSS NALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9651", district:"34", Block:"318", label:"GHS NERKUNAM", Sch_type:"Government", Category:"High School"},
    { key:"9652", district:"34", Block:"318", label:"PUMS PENNATTAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9653", district:"34", Block:"318", label:"GHSS PONNUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9654", district:"34", Block:"318", label:"PUMS SATHIYAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9655", district:"34", Block:"318", label:"PUMS SENAL", Sch_type:"Government", Category:"Middle School"},
    { key:"9656", district:"34", Block:"318", label:"PUMS THAKKANDARAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9657", district:"34", Block:"318", label:"GHSS(BOYS)-THELLAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9658", district:"34", Block:"318", label:"GGHS THELLAR", Sch_type:"Government", Category:"High School"},
    { key:"9659", district:"34", Block:"318", label:"OXFORD HIGHER SECONDARY SCHOOL, THELLAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9660", district:"34", Block:"318", label:"PUMS THENTHINNALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9661", district:"34", Block:"318", label:"GHSS THEYYAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9662", district:"34", Block:"318", label:"GHS VAYALAMOOR", Sch_type:"Government", Category:"High School"},
    { key:"9663", district:"34", Block:"318", label:"GHS VEDAL", Sch_type:"Government", Category:"High School"},
    { key:"9664", district:"34", Block:"27", label:"GHS ADHANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9665", district:"34", Block:"27", label:"GHSS AGRAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9666", district:"34", Block:"27", label:"GHSS- IRUMBEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9667", district:"34", Block:"27", label:"GHS HARIHARAN NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"9668", district:"34", Block:"27", label:"GHS KALPOONDI", Sch_type:"Government", Category:"High School"},
    { key:"9669", district:"34", Block:"27", label:"GHS MEYYUR", Sch_type:"Government", Category:"High School"},
    { key:"9670", district:"34", Block:"27", label:"GHS MORAPANTHANGAL", Sch_type:"Government", Category:"High School"},
    { key:"9671", district:"34", Block:"27", label:"GHSS MULLANDIRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9672", district:"34", Block:"27", label:"GHS NESAL", Sch_type:"Government", Category:"High School"},
    { key:"9673", district:"34", Block:"27", label:"GHS PAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"9674", district:"34", Block:"27", label:"GHS RATTINAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"9675", district:"34", Block:"27", label:"GHSS S.V.NAGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9676", district:"34", Block:"27", label:"GHSS SEVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9677", district:"34", Block:"27", label:"GHS SIRUMOOR", Sch_type:"Government", Category:"High School"},
    { key:"9678", district:"34", Block:"27", label:"GHS VADUGASATHU", Sch_type:"Government", Category:"High School"},
    { key:"9679", district:"34", Block:"27", label:"GHS VELAPADI", Sch_type:"Government", Category:"High School"},
    { key:"9680", district:"34", Block:"27", label:"GHS VETTIYANTHOLUVAM", Sch_type:"Government", Category:"High School"},
    { key:"9681", district:"34", Block:"27", label:"GHS VILAI", Sch_type:"Government", Category:"High School"},
    { key:"9682", district:"34", Block:"27", label:"CSI HSS  ARNI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9683", district:"34", Block:"27", label:"GHSS (B)  ARNI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9684", district:"34", Block:"27", label:"GGMHSS ARNI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9685", district:"34", Block:"27", label:"ST. JOSEPH HSS(GIRLS) ARNI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9686", district:"34", Block:"27", label:"SUB. SASTRIAR HSS ARNI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9687", district:"34", Block:"412", label:"GHS 5PUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"9688", district:"34", Block:"412", label:"GHSS DEVIKAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9689", district:"34", Block:"412", label:"GHS(GIRLS) DEVIKAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9690", district:"34", Block:"412", label:"GBHSS KANNAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9691", district:"34", Block:"412", label:"GHSS(GIRLS) KANNAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9692", district:"34", Block:"412", label:"GHSS KUNNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9693", district:"34", Block:"412", label:"GHS MALAIYAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9694", district:"34", Block:"412", label:"GHS MELNAGAR", Sch_type:"Government", Category:"High School"},
    { key:"9695", district:"34", Block:"412", label:"GHS MULLIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9696", district:"34", Block:"412", label:"GHSS NADUKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9697", district:"34", Block:"412", label:"GHSS ONNUPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9698", district:"34", Block:"412", label:"GHS THATCHUR", Sch_type:"Government", Category:"High School"},
    { key:"9699", district:"34", Block:"412", label:"GHS THELLUR", Sch_type:"Government", Category:"High School"},
    { key:"9700", district:"34", Block:"412", label:"GHS VINNAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"9701", district:"34", Block:"249", label:"PUMS ALLINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"9702", district:"34", Block:"249", label:"GHSS ANANTHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9703", district:"34", Block:"249", label:"GHSS ATHIMOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9704", district:"34", Block:"249", label:"S C GOVT BOYS HR.SEC. SCHOOL KALAMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9705", district:"34", Block:"249", label:"GHSS (GIRLS) KALAMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9706", district:"34", Block:"249", label:"GHS KALASAMUDRAM", Sch_type:"Government", Category:"High School"},
    { key:"9707", district:"34", Block:"249", label:"ADWHS KALIYAM", Sch_type:"Government", Category:"High School"},
    { key:"9708", district:"34", Block:"249", label:"GHS KANGEYANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9709", district:"34", Block:"249", label:"GHS KASTHAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"9710", district:"34", Block:"249", label:"GHSS KELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9711", district:"34", Block:"249", label:"GHSS KUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9712", district:"34", Block:"249", label:"GHSS MAMBATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9713", district:"34", Block:"249", label:"GHS PADIYAMBATTU", Sch_type:"Government", Category:"High School"},
    { key:"9714", district:"34", Block:"249", label:"GHS PERIYAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"9715", district:"34", Block:"249", label:"GHS PERUMALPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"9716", district:"34", Block:"249", label:"DON BOSCO HSS POLUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9717", district:"34", Block:"249", label:"GHSS (BOYS) POLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9718", district:"34", Block:"249", label:"GHSS (GIRLS) POLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9719", district:"34", Block:"249", label:"GHS POTHARAI", Sch_type:"Government", Category:"High School"},
    { key:"9720", district:"34", Block:"249", label:"GHS RAMANATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9721", district:"34", Block:"249", label:"GHSS RENUKONDAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9722", district:"34", Block:"249", label:"GHSS SANTHAVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9723", district:"34", Block:"249", label:"GHSS SENGUNAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9724", district:"34", Block:"249", label:"GHS THURINJIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9725", district:"34", Block:"249", label:"AKSHAYA VIDHYA. MANDHIR MATRIC HIGHER SECONDARY SCHOOL VASUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9726", district:"34", Block:"249", label:"GHS VELLUR II", Sch_type:"Government", Category:"High School"},
    { key:"9727", district:"34", Block:"249", label:"OXFORD MATRIC HR. SEC. SCHOOL VENMANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9728", district:"34", Block:"249", label:"PUMS VETTAGIRIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9729", district:"34", Block:"93", label:"GHSS (BOYS) ADAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9730", district:"34", Block:"93", label:"GHSS (GIRLS) ADHAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9731", district:"34", Block:"93", label:"PUMS ALANGARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9732", district:"34", Block:"93", label:"GHS ANIYALAI", Sch_type:"Government", Category:"High School"},
    { key:"9733", district:"34", Block:"93", label:"SRI SARAVANA VIDHYALAYA MATRIC HR SEC SCHOOL ,ELATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9734", district:"34", Block:"93", label:"GHSS KADALADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9735", district:"34", Block:"93", label:"GMHSS (BOYS) KALASAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9736", district:"34", Block:"93", label:"GHSS (GIRLS) KALASAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9737", district:"34", Block:"93", label:"ARAVINDHAR MATRIC KALASAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9738", district:"34", Block:"93", label:"SWAMI VENKATESWARA MATRICULATION HIGHER SECONDARY SCHOOL, KANDHAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9739", district:"34", Block:"93", label:"GHS KANTHAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"9740", district:"34", Block:"93", label:"GHS KIDAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"9741", district:"34", Block:"93", label:"GHS KILKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9742", district:"34", Block:"93", label:"GHSS KILPALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9743", district:"34", Block:"93", label:"GHSS LADAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9744", district:"34", Block:"93", label:"GHSS MELARANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9745", district:"34", Block:"93", label:"GHSS MELSHOLANKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9746", district:"34", Block:"93", label:"GHS PADAGAM", Sch_type:"Government", Category:"High School"},
    { key:"9747", district:"34", Block:"93", label:"GHS POONDI", Sch_type:"Government", Category:"High School"},
    { key:"9748", district:"34", Block:"93", label:"GHS THENMAHADEVAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"9749", district:"34", Block:"93", label:"ADWMS VEERALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9750", district:"34", Block:"47", label:"DHIVYA MATRIC HSS CHETPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9751", district:"34", Block:"47", label:"DOMINIC SAVIO HSS (BOYS) CHETPET", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9752", district:"34", Block:"47", label:"GHSS PAZHAMPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9753", district:"34", Block:"47", label:"ST ANNES HSS (GIRLS) CHETPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9754", district:"34", Block:"47", label:"GHS ETTIVADI", Sch_type:"Government", Category:"High School"},
    { key:"9755", district:"34", Block:"47", label:"GHS GUDALUR", Sch_type:"Government", Category:"High School"},
    { key:"9756", district:"34", Block:"47", label:"GHS INDIRAVANAM", Sch_type:"Government", Category:"High School"},
    { key:"9757", district:"34", Block:"47", label:"GHS KILKARIKATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9758", district:"34", Block:"47", label:"GHSS MANDAKOLATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9759", district:"34", Block:"47", label:"GHSS MANSURABAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9760", district:"34", Block:"47", label:"GHSS NAMBEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9761", district:"34", Block:"47", label:"GHS PELASUR", Sch_type:"Government", Category:"High School"},
    { key:"9762", district:"34", Block:"47", label:"GHSS PERNAMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9763", district:"34", Block:"47", label:"GHS RANDAM", Sch_type:"Government", Category:"High School"},
    { key:"9764", district:"34", Block:"47", label:"GHSS SANARAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9765", district:"34", Block:"47", label:"GHSS SEVARAPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9766", district:"34", Block:"47", label:"NIRMALA MADHA HSS (GIRLS) SUSAINAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9767", district:"34", Block:"47", label:"ST JOSEPHS HSS (BOYS) SUSAINAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9768", district:"34", Block:"47", label:"SRI BHAGAVAN NEMINATHA HR SEC SCHOOL  TIRUMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9769", district:"34", Block:"47", label:"GHS ULAGAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9770", district:"34", Block:"47", label:"GHSS VADAMATHIMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9771", district:"34", Block:"358", label:"GHS ANANDAL", Sch_type:"Government", Category:"High School"},
    { key:"9772", district:"34", Block:"358", label:"GHS ARPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"9773", district:"34", Block:"358", label:"GHS DURGAINAMMIYANDAL", Sch_type:"Government", Category:"High School"},
    { key:"9774", district:"34", Block:"358", label:"GHS ERUMPOONDI", Sch_type:"Government", Category:"High School"},
    { key:"9775", district:"34", Block:"358", label:"GHSS INAMKARIYANDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9776", district:"34", Block:"358", label:"ATR VIDHYASHRAM INTERNATIONAL MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9777", district:"34", Block:"358", label:"GHS KATTUPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"9778", district:"34", Block:"358", label:"SNS MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9779", district:"34", Block:"358", label:"GHSS MALLAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9780", district:"34", Block:"358", label:"GHSS (GIRLS) MALLAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9781", district:"34", Block:"358", label:"SRI SAI VIDYA  MANDIR MATRICULATION SCHOOL, MALLAVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"9782", district:"34", Block:"358", label:"GHSS MANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9783", district:"34", Block:"358", label:"GANDHI INTERNATIONAL MATRIC", Sch_type:"Un-aided", Category:"High School"},
    { key:"9784", district:"34", Block:"358", label:"GHS MADHALAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"9785", district:"34", Block:"358", label:"ST MARYS HS MARUTHUVAMBADI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9786", district:"34", Block:"358", label:"GHS MEPPATHURAI", Sch_type:"Government", Category:"High School"},
    { key:"9787", district:"34", Block:"358", label:"GHSS NARTHAMPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9788", district:"34", Block:"358", label:"DMHS NOOKAMBADI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9789", district:"34", Block:"358", label:"GHS SADAYANODAI", Sch_type:"Government", Category:"High School"},
    { key:"9790", district:"34", Block:"358", label:"PUMS SEELAPANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"9791", district:"34", Block:"358", label:"GHSS SORAKOLATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9792", district:"34", Block:"358", label:"GHS VALLIVAGAI", Sch_type:"Government", Category:"High School"},
    { key:"9793", district:"34", Block:"358", label:"GHSS VEDANTHAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9794", district:"34", Block:"358", label:"GHS VELUGANANDAL", Sch_type:"Government", Category:"High School"},
    { key:"9795", district:"34", Block:"125", label:"GHS AVOOR", Sch_type:"Government", Category:"High School"},
    { key:"9796", district:"34", Block:"125", label:"NEW INDIAN MATRIC. HI-TECH HR SEC SCHOOL-OLAIPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9797", district:"34", Block:"125", label:"GHS CHELLANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9798", district:"34", Block:"125", label:"GHSS IYNGUNAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9799", district:"34", Block:"125", label:"GHS KARIKALAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"9800", district:"34", Block:"125", label:"RRHS KARUNGALIKUPPAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9801", district:"34", Block:"125", label:"GHS KAZHIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"9802", district:"34", Block:"125", label:"GHS KEEKALUR", Sch_type:"Government", Category:"High School"},
    { key:"9803", district:"34", Block:"125", label:"GHSS (BOYS) KILPENNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9804", district:"34", Block:"125", label:"GHSS (GIRLS) KILPENNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9805", district:"34", Block:"125", label:"GHS KOLATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9806", district:"34", Block:"125", label:"GHS KONALUR", Sch_type:"Government", Category:"High School"},
    { key:"9807", district:"34", Block:"125", label:"GHSS MEKKALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9808", district:"34", Block:"125", label:"ST PAUL HS NA.GENGAPATTU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9809", district:"34", Block:"125", label:"GHS NARIYAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"9810", district:"34", Block:"125", label:"GHSS RAJANTHANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9811", district:"34", Block:"125", label:"NATIONAL MATRIC HIGHER SECONDARY  SCHOOL RAJANTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9812", district:"34", Block:"125", label:"AMEEN HSS SO.PUDUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9813", district:"34", Block:"125", label:"DMHS SOMASIPADI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9814", district:"34", Block:"125", label:"GHS SU.POLAKUNAM", Sch_type:"Government", Category:"High School"},
    { key:"9815", district:"34", Block:"125", label:"GHS VAIPUR", Sch_type:"Government", Category:"High School"},
    { key:"9816", district:"34", Block:"125", label:"GHS VALUTHALANGUNAM", Sch_type:"Government", Category:"High School"},
    { key:"9817", district:"34", Block:"125", label:"GHSS VEDANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9818", district:"34", Block:"125", label:"GHS VELANANDAL", Sch_type:"Government", Category:"High School"},
    { key:"9819", district:"34", Block:"125", label:"GHSS (BOYS) VETTAVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9820", district:"34", Block:"125", label:"GHSS (GIRLS) VETTAVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9821", district:"34", Block:"125", label:"SA HSS (GIRLS) VETTAVALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9822", district:"34", Block:"125", label:"SM HSS (BOYS) VETTAVALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9823", district:"34", Block:"125", label:"DR. LAMAYAR MATRIC VETTAVALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"9824", district:"34", Block:"125", label:"GOOD WILL MATRIC HR.SEC SCHOOL ANUKKUMALAI VETTAVALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9825", district:"34", Block:"125", label:"ST.XAVIER HS ZAMEEN GUDALUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9826", district:"34", Block:"365", label:"  TVS ACADEMY MONTESSORI AND ENGLISH MEDIUM SCHOOL, ADAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"9827", district:"34", Block:"365", label:"SS AIDED HS ADIANNAMALAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"9828", district:"34", Block:"365", label:"GHS ADIANNAMALAI", Sch_type:"Government", Category:"High School"},
    { key:"9829", district:"34", Block:"365", label:"GHSS ANDAMPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9830", district:"34", Block:"365", label:"GHS ANAIPIRANDAN", Sch_type:"Government", Category:"High School"},
    { key:"9831", district:"34", Block:"365", label:"ADWHS AYYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"9832", district:"34", Block:"365", label:"PUMS AYYAMPALAYAM PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9833", district:"34", Block:"365", label:"TKKMHS CHINNAKANGIANUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"9834", district:"34", Block:"365", label:"GHS DEVANUR", Sch_type:"Government", Category:"High School"},
    { key:"9835", district:"34", Block:"365", label:"GHSS KATTAMPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9836", district:"34", Block:"365", label:"SRI RAMANA MAHARSHI MATRIC  HR SEC SCHOOL KAVERIYAMPOONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9837", district:"34", Block:"365", label:"SMM MODEL HS KILANAIKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9838", district:"34", Block:"365", label:"GHSS KOLAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9839", district:"34", Block:"365", label:"G.P. MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"9840", district:"34", Block:"365", label:"SIRUMALAR HS MATHURAMPATU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9841", district:"34", Block:"365", label:"PUMS MALAPPAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9842", district:"34", Block:"365", label:"HEBRON MATRIC HIGHER SECONDARY SCHOOL -  MANJAMPOONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9843", district:"34", Block:"365", label:"GHS MEYYUR", Sch_type:"Government", Category:"High School"},
    { key:"9844", district:"34", Block:"365", label:"SARASWATHI KNOWLEDGE YUVAKENDRA MATRICULATION HR SEC SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9845", district:"34", Block:"365", label:"GHSS NALLAVANPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9846", district:"34", Block:"365", label:"GHS NARAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"9847", district:"34", Block:"365", label:"PUMS NARIYAPPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"9848", district:"34", Block:"365", label:"GHSS PALAYANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9849", district:"34", Block:"365", label:"GHS PARAYAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9850", district:"34", Block:"365", label:"PSA HSS (BOYS) PAVITHIRAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9851", district:"34", Block:"365", label:"ST ANTONYS HSS (GIRLS) PAVITHIRAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9852", district:"34", Block:"365", label:"GHS PERUMANAM", Sch_type:"Government", Category:"High School"},
    { key:"9853", district:"34", Block:"365", label:"PUMS SO. KILNACHIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"9854", district:"34", Block:"365", label:"KUMARAN HSS SO.KILNATCHIPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9855", district:"34", Block:"365", label:"JEEVA VELU GIRLS MATRICLATION HR SEC SCHOOL THENMATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9856", district:"34", Block:"365", label:"G T R HIGH SCHOOL  SU.KILNATCHIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9857", district:"34", Block:"365", label:"GHSS SU.VALAVETTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9858", district:"34", Block:"365", label:"RANGAMMAL MEMORIAL HR.SEC.SCHOOL FOR THE HEARING IMPAIRED", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9859", district:"34", Block:"365", label:"PUMS SAMUKKUDAIYAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"9860", district:"34", Block:"365", label:"GHSS THACHAMPATU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9861", district:"34", Block:"365", label:"GHS THANDARAI", Sch_type:"Government", Category:"High School"},
    { key:"9862", district:"34", Block:"365", label:"JEEVA VELU INTERNATIONAL SCHOOL - THENMATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9863", district:"34", Block:"365", label:"GHS VEDIYAPPANUR", Sch_type:"Government", Category:"High School"},
    { key:"9864", district:"34", Block:"365", label:"ST ANTONY HS VIRUDUVILANGINAN", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9865", district:"34", Block:"365", label:"ALC HSS SARON", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9866", district:"34", Block:"365", label:"DM HSS TIRUVANNAMALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9867", district:"34", Block:"365", label:"TNAP GHSS TIRUVANNAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9868", district:"34", Block:"365", label:"MOUNT SAINT JOSEPH MATRIC HSS TIRUVANNAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9869", district:"34", Block:"365", label:"MUNICIPAL HS KILNATHUR", Sch_type:"Government", Category:"High School"},
    { key:"9870", district:"34", Block:"365", label:"MUNICIPAL HS (GIRLS) TIRUVANNAMALAI", Sch_type:"Government", Category:"High School"},
    { key:"9871", district:"34", Block:"365", label:"MUNICIPAL  HSS TIRUVANNAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9872", district:"34", Block:"365", label:"MUNICIPAL  HSS (GIRLS) TIRUVANNAMALAI -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9873", district:"34", Block:"365", label:"SI GHSS TIRUVANNAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9874", district:"34", Block:"365", label:"SRI VDS JAIN HSS TIRUVANNAMALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9875", district:"34", Block:"365", label:"ST XAVIERS HS KILNATHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9876", district:"34", Block:"365", label:"VISION MATRICULATION HIGHER SECONDARY SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9877", district:"34", Block:"365", label:"KENDRIYA VIDYALAYA GANDHI NAGAR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"9878", district:"34", Block:"365", label:"M A KADHAR SAIT TIRUVANNAMALAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"9879", district:"34", Block:"256", label:"GHS AMARNATHAPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"9880", district:"34", Block:"256", label:"GHSS AMMAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9881", district:"34", Block:"256", label:"GHS C.GENGAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9882", district:"34", Block:"256", label:"ST JOSEPHS HS C.NAMMIANDAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"9883", district:"34", Block:"256", label:"GHSS ERAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9884", district:"34", Block:"256", label:"GHSS KANJI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9885", district:"34", Block:"256", label:"GHSS(GIRLS) KANJI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9886", district:"34", Block:"256", label:"GHSS KARAPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9887", district:"34", Block:"256", label:"GHS KOTTAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"9888", district:"34", Block:"256", label:"GHSS- MASHAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9889", district:"34", Block:"256", label:"GHS MELAPUNJAI", Sch_type:"Government", Category:"High School"},
    { key:"9890", district:"34", Block:"256", label:"GHS MELNACHIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9891", district:"34", Block:"256", label:"GHS MELPADUR", Sch_type:"Government", Category:"High School"},
    { key:"9892", district:"34", Block:"256", label:"GHS MUTHANUR", Sch_type:"Government", Category:"High School"},
    { key:"9893", district:"34", Block:"256", label:"GHSS PANAIOLAIPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9894", district:"34", Block:"256", label:"GHS PERIYAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"9895", district:"34", Block:"256", label:"GHSS(BOYS)-PUDUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9896", district:"34", Block:"256", label:"GGMHSS PUDUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9897", district:"34", Block:"256", label:"GHS PUDUR CHENGAM", Sch_type:"Government", Category:"High School"},
    { key:"9898", district:"34", Block:"256", label:"GHS THORAPADI", Sch_type:"Government", Category:"High School"},
    { key:"9899", district:"34", Block:"256", label:"GHS VASUDEVANPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9900", district:"34", Block:"256", label:"GHS VOIVIDANTHANGAL", Sch_type:"Government", Category:"High School"},
    { key:"9901", district:"34", Block:"44", label:"VIMALA IRUDHAYA MATRICULATION SCHOOL ANWARABATH", Sch_type:"Un-aided", Category:"High School"},
    { key:"9902", district:"34", Block:"44", label:"PUMS ARASANKANNI", Sch_type:"Government", Category:"Middle School"},
    { key:"9903", district:"34", Block:"44", label:"GHSS ARATTAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9904", district:"34", Block:"44", label:"PUMS -AYOTHIYAPATTANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9905", district:"34", Block:"44", label:"PUMS CHE. AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9906", district:"34", Block:"44", label:"GHS CHE.NACHIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"9907", district:"34", Block:"44", label:"PUMS CHE. TIRUVALLUVARNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"9908", district:"34", Block:"44", label:"GHS C.SORPANANDAL", Sch_type:"Government", Category:"High School"},
    { key:"9909", district:"34", Block:"44", label:"GHSS(BOYS) CHENGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9910", district:"34", Block:"44", label:"GHSS(GIRLS) CHENGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9911", district:"34", Block:"44", label:"SAGAYA MADHA HSS(TM) CHENGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9912", district:"34", Block:"44", label:"GHS (MUSLIM) CHENGAM", Sch_type:"Government", Category:"High School"},
    { key:"9913", district:"34", Block:"44", label:"MAHARISHI  HR SEC SCHOOL CHENGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9914", district:"34", Block:"44", label:"GHS CHENNASAMUTHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"9915", district:"34", Block:"44", label:"GHSS  ELANGUNNI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9916", district:"34", Block:"44", label:"GHSS KANNAKURUKKAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9917", district:"34", Block:"44", label:"GHS KARIMALAIPADI", Sch_type:"Government", Category:"High School"},
    { key:"9918", district:"34", Block:"44", label:"GHS KRISHNAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9919", district:"34", Block:"44", label:"GHS KUPPANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"9920", district:"34", Block:"44", label:"GHS MELCHENGAM", Sch_type:"Government", Category:"High School"},
    { key:"9921", district:"34", Block:"44", label:"GBHSS-MELPALLIPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9922", district:"34", Block:"44", label:"GGHSS MELPALLIPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9923", district:"34", Block:"44", label:"PUMS MELPUZHUTHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9924", district:"34", Block:"44", label:"ADWHS MELPUZHUTHIYUR", Sch_type:"Government", Category:"High School"},
    { key:"9925", district:"34", Block:"44", label:"GHS MELRAVANDAVADI", Sch_type:"Government", Category:"High School"},
    { key:"9926", district:"34", Block:"44", label:"PUMS MELVANAKKAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9927", district:"34", Block:"44", label:"GHS NEEPATHURAI", Sch_type:"Government", Category:"High School"},
    { key:"9928", district:"34", Block:"44", label:"GHS PACHAL", Sch_type:"Government", Category:"High School"},
    { key:"9929", district:"34", Block:"44", label:"GHSS PARAMANANDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9930", district:"34", Block:"44", label:"GHSS PERIYAKOLAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9931", district:"34", Block:"44", label:"PUMS PERUMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9932", district:"34", Block:"44", label:"GHS PULIYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9933", district:"34", Block:"44", label:"PUMS THALAVANAICKENPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9934", district:"34", Block:"44", label:"GHS VINNAVANOOR", Sch_type:"Government", Category:"High School"},
    { key:"9935", district:"34", Block:"312", label:"PUMS AGARAM PALLIPET", Sch_type:"Government", Category:"Middle School"},
    { key:"9936", district:"34", Block:"312", label:"GHS BEEMARAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"9937", district:"34", Block:"312", label:"GHSS C.REDDIYAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9938", district:"34", Block:"312", label:"PUMS CHINNIYAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9939", district:"34", Block:"312", label:"PUMS DESURPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9940", district:"34", Block:"312", label:"PUMS DEVARADIYARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9941", district:"34", Block:"312", label:"GHS EDAKKAL", Sch_type:"Government", Category:"High School"},
    { key:"9942", district:"34", Block:"312", label:"GHS EDATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"9943", district:"34", Block:"312", label:"CARMEL HSS ELAYANKANNI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"9944", district:"34", Block:"312", label:"GOOD SHEPHERD HIGHER SECONDARY SCHOOL  GURUKILAMOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9945", district:"34", Block:"312", label:"GHS KAMBATTU", Sch_type:"Government", Category:"High School"},
    { key:"9946", district:"34", Block:"312", label:"GHS KEERANUR", Sch_type:"Government", Category:"High School"},
    { key:"9947", district:"34", Block:"312", label:"GHS KILSIRUPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"9948", district:"34", Block:"312", label:"PUMS KILVANAKKAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"9949", district:"34", Block:"312", label:"GHS MALAMANJANUR PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"9950", district:"34", Block:"312", label:"PUMS MELKARIPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9951", district:"34", Block:"312", label:"GHS MELMUTHANUR", Sch_type:"Government", Category:"High School"},
    { key:"9952", district:"34", Block:"312", label:"GHSS MOTHAKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9953", district:"34", Block:"312", label:"GHS NARAYANAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9954", district:"34", Block:"312", label:"PUMS PERAYAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"9955", district:"34", Block:"312", label:"GHSS PERUNGULATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9956", district:"34", Block:"312", label:"KGBVRS PERUNGULATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9957", district:"34", Block:"312", label:"PUMS RADHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"9958", district:"34", Block:"312", label:"GHSS RAYANDAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9959", district:"34", Block:"312", label:"GHS S.GUDALUR", Sch_type:"Government", Category:"High School"},
    { key:"9960", district:"34", Block:"312", label:"GHS SADHAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"9961", district:"34", Block:"312", label:"GHSS SATHANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9962", district:"34", Block:"312", label:"GHS (GIRLS) SATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"9963", district:"34", Block:"312", label:"GHSS (BOYS) THANDARAMPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9964", district:"34", Block:"312", label:"GHSS (GIRLS) THANDRAMPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9965", district:"34", Block:"312", label:"BHARATH VIDHYA MANDIR MATRIC HR SEC SCHOOL THANDRAMPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9966", district:"34", Block:"312", label:"GHS THARADAPET", Sch_type:"Government", Category:"High School"},
    { key:"9967", district:"34", Block:"312", label:"GGHSS THANIPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9968", district:"34", Block:"312", label:"GBHSS- THANIPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9969", district:"34", Block:"312", label:"KAMALAMMAL MATRIC S THANIPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"9970", district:"34", Block:"312", label:"GHS THENKARIMBALUR", Sch_type:"Government", Category:"High School"},
    { key:"9971", district:"34", Block:"312", label:"RCM MS UDAYARKUPPAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"9972", district:"34", Block:"312", label:"GHS (GIRLS) VANAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"9973", district:"34", Block:"312", label:"GHSS - VANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9974", district:"34", Block:"80", label:"PUMS ALANJANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9975", district:"34", Block:"80", label:"ST JOSEPH HSS ATHIPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9976", district:"34", Block:"80", label:"PUMS BANDIREW", Sch_type:"Government", Category:"Middle School"},
    { key:"9977", district:"34", Block:"80", label:"KGBVRS GUNDALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9978", district:"34", Block:"80", label:"TRIBAL HSS GUNIGANTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"9979", district:"34", Block:"80", label:"PUMS IRULAMPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9980", district:"34", Block:"80", label:"FOREST HSS JAMUNAMARATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9981", district:"34", Block:"80", label:"FOREST MS JAMMUNAMARATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9982", district:"34", Block:"80", label:"PUMS KALLATHUR(P)", Sch_type:"Government", Category:"Middle School"},
    { key:"9983", district:"34", Block:"80", label:"PUMS KALLIPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9984", district:"34", Block:"80", label:"FOREST MS KALYANAMANDAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9985", district:"34", Block:"80", label:"PUMS KANAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9986", district:"34", Block:"80", label:"GHS KILAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"9987", district:"34", Block:"80", label:"PUMS KILKANAVAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9988", district:"34", Block:"80", label:"FOREST MS KILVILAMUCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"9989", district:"34", Block:"80", label:"PUMS KOOTTATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9990", district:"34", Block:"80", label:"FOREST MS KOVILUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9991", district:"34", Block:"80", label:"KGBVRS AMMATANKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"9992", district:"34", Block:"80", label:"FOREST MS MELPET", Sch_type:"Government", Category:"Middle School"},
    { key:"9993", district:"34", Block:"80", label:"PUMS MUTHANATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9994", district:"34", Block:"80", label:"KGBVRS MUTHANATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9995", district:"34", Block:"80", label:"GTR HSS NAMMIYAMPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"9996", district:"34", Block:"80", label:"GTRMS OORGOUNDANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"9997", district:"34", Block:"80", label:"FOREST MS PULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9998", district:"34", Block:"80", label:"KGBVRS PULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"9999", district:"34", Block:"80", label:"GTRMS SERAMARATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10000", district:"34", Block:"80", label:"PUMS THANIYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10001", district:"34", Block:"80", label:"PUMS THOPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10002", district:"34", Block:"80", label:"PUMS THUMBAIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"10003", district:"34", Block:"80", label:"FOREST MS VEERAPPANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10004", district:"34", Block:"80", label:"PUMS VELLIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10005", district:"34", Block:"80", label:"FOREST MS VAZHAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"10006", district:"34", Block:"80", label:"GHS VEDAKOLLAIMEDU", Sch_type:"Government", Category:"High School"},
    { key:"10007", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ANNAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10008", district:"37", Block:"181", label:"RAJA DESINGH MATRIC HIGH  SCHOOL  AVALURPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"10009", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL(G) AVALURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10010", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL(B) AVALURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10011", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL   AATHIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10012", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDRY SCHOOL DEVANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10013", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL KARADIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10014", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  ETHAPPATU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10015", district:"37", Block:"181", label:"GOVERNMENT HIGH SCHOOL EYYIL", Sch_type:"Government", Category:"High School"},
    { key:"10016", district:"37", Block:"181", label:"SRI PARAMESWAR M HIGH SCHOOL GENGAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10017", district:"37", Block:"181", label:"AIDED MIDDLE SCHOOL  KUDUVAMPOONDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10018", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  KALATHAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10019", district:"37", Block:"181", label:"GOVERNMENT HIGH SCHOOL KAPLAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"10020", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  VALATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"10021", district:"37", Block:"181", label:"OUTREACH MATRIC  HIGH SCHOOL VALATHY", Sch_type:"Un-aided", Category:"High School"},
    { key:"10022", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL KODUKANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10023", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  KOTTAPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10024", district:"37", Block:"181", label:"C.S.I BISHOP SAM PONNIAH  HIGH SCHOOL GNANOTHAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10025", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  MANANTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10026", district:"37", Block:"181", label:"GANGA VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, MELMALAYANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10027", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MELMALAYANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10028", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL MELACHERRY", Sch_type:"Government", Category:"Middle School"},
    { key:"10029", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  MELSAVALAMPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10030", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  THEPPIRAMPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10031", district:"37", Block:"181", label:"GOVERNMENT HIGH SCHOOL  METTUVAILAMOOR", Sch_type:"Government", Category:"High School"},
    { key:"10032", district:"37", Block:"181", label:"GOVERNMENT HIGH SCHOOL  NARANAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"10033", district:"37", Block:"181", label:"GOVERNMENT HIGH SCHOOL  NOCHALUR", Sch_type:"Government", Category:"High School"},
    { key:"10034", district:"37", Block:"181", label:"GOVERNMENT HIGH SCHOOL  PERIYANOLAMBAI", Sch_type:"Government", Category:"High School"},
    { key:"10035", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  VADUKAPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10036", district:"37", Block:"181", label:"SARADHA VIDYALAYA MATRICULATION SCHOOL.VADUGANPOONDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10037", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  PERUVALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10038", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  PERUVALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10039", district:"37", Block:"181", label:"GOVERNMENT HIGH SCHOOL  SATHAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"10040", district:"37", Block:"181", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  SEVALAPURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10041", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  SIRUTHALAIPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10042", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  THAZHANGUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10043", district:"37", Block:"181", label:"PANCHAYAT UNION MIDDLE SCHOOL  THENPALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10044", district:"37", Block:"181", label:"SACRED HEART HIGH SCHOOL  THURINCHIPOONDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10045", district:"37", Block:"70", label:"IMMACULATE HIGH SCHOOL VELANTHANGAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10046", district:"37", Block:"70", label:"GOVERNMENT HIGH SCHOOL  MELPAPPAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"10047", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10048", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL  PUTHAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10049", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL KATTUCHITHAMUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10050", district:"37", Block:"70", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL  NALLAN PILLAI PETRAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10051", district:"37", Block:"70", label:"GOVERNMENT HIGH SCHOOL  THADAGAM", Sch_type:"Government", Category:"High School"},
    { key:"10052", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL PAZHAVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10053", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL SO.KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10054", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL PAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10055", district:"37", Block:"70", label:"SRI RAMAKRISHNA MIDDLE SCHOOL  SATHIYAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10056", district:"37", Block:"70", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL  SATHIYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10057", district:"37", Block:"70", label:"RAJA DESINGH VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL  SATHIYAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10058", district:"37", Block:"70", label:"ROMAN CATHOLIC MIDDLE SCHOOL (UNAIDED) SATHIYAMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10059", district:"37", Block:"70", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL  ALAMPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10060", district:"37", Block:"70", label:"ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL ALAMPOONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10061", district:"37", Block:"70", label:"ST. AUGUSTINE HIGH SCHOOL  THANGALKARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10062", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL ODHIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10063", district:"37", Block:"70", label:"ROMAN CATHOLIC MIDDLE SCHOOL MANALAPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10064", district:"37", Block:"70", label:"AIDED NON MINORITY MIDDLE SCHOOL R.NAYAMPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10065", district:"37", Block:"70", label:"GOVERNMENT HIGH SCHOOL  DEVADHANAMPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"10066", district:"37", Block:"70", label:"GOVERNMENT HIGH SCHOOL  KONAI", Sch_type:"Government", Category:"High School"},
    { key:"10067", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL SERANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10068", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL THACHAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10069", district:"37", Block:"70", label:"ST.ANTONY'S HIGH SCHOOL  ANAIYERI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10070", district:"37", Block:"70", label:"LOURDU ANNAI HIGHER SECONDARY SCHOOL KANAKANKUPPAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10071", district:"37", Block:"70", label:"GOVERNMENT HIGH SCHOOL  KANAKKANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"10072", district:"37", Block:"70", label:"GOVERNMENT HIGH SCHOOL  MALAIYARASANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"10073", district:"37", Block:"70", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL MAZHAVANTHANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10074", district:"37", Block:"70", label:"GOVERNMENT HIGH SCHOOL  THUTHIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10075", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL (G) ANANTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10076", district:"37", Block:"70", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL  ANANTHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10077", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL KONALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10078", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL OTTAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10079", district:"37", Block:"70", label:"GOVERNMENT HIGH SCHOOL  KARAI", Sch_type:"Government", Category:"High School"},
    { key:"10080", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL SITTAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10081", district:"37", Block:"70", label:"SARVA SEVA VIDHYALAYA MATRIC HS SITTAMPOONDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10082", district:"37", Block:"70", label:"ADIDRAVIDA WELFARE HIGHER SECONDARY SCHOOL KAVARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10083", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL JAYANKONDAN", Sch_type:"Government", Category:"Middle School"},
    { key:"10084", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL MEENAMBUR (B) URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10085", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL PALAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10086", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL THENPUDUPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10087", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL GINGEE", Sch_type:"Government", Category:"Middle School"},
    { key:"10088", district:"37", Block:"70", label:"RAJA DESINGH GOVERNMENT BOYS HIGHER SECONDARY SCHOOL GINGEE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10089", district:"37", Block:"70", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL (G) GINGEE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10090", district:"37", Block:"70", label:"AL-HILAL MATRIC HIGHER SECONDARY SCHOOL GINGEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10091", district:"37", Block:"70", label:"SARADHA MATRIC HIGHER SECONDARY SCHOOL GINGEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10092", district:"37", Block:"70", label:"CHANAKYA MATRICULATION HIGHER SECONDARY SCHOOL GINGEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10093", district:"37", Block:"70", label:"ST.MICHAEL'S HIGHER SECONDARY SCHOOL  KRISHNAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10094", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL SAKKARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10095", district:"37", Block:"70", label:"KALAIVANI  MATRICULATION HIGHER SECONDARY SCHOOL GINGEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10096", district:"37", Block:"70", label:"GOVERNMENT MUSLIM URDU HIGH SCHOOL, GINGEE", Sch_type:"Government", Category:"High School"},
    { key:"10097", district:"37", Block:"70", label:"SHVETA  PADMASANI MATRIC HIGHER SECONDARY SCHOOL GINGEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10098", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL KAPPAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10099", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL THALAVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10100", district:"37", Block:"384", label:"SACRED HEART HIGHER SECONDARY SCHOOL ANILADY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10101", district:"37", Block:"384", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KEELMAMPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10102", district:"37", Block:"384", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL SORATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10103", district:"37", Block:"384", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10104", district:"37", Block:"384", label:"CARE COUNCIL HIGH SCHOOL , PANDIYANKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10105", district:"37", Block:"384", label:"GOVERNMENT HIGH SCHOOL MODAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"10106", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL RAJAMPULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10107", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL  UDAIYANTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10108", district:"37", Block:"384", label:"GOVERNMENT HIGH SCHOOL KALLAPULIYUR", Sch_type:"Government", Category:"High School"},
    { key:"10109", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL MELKALAVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10110", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL NEGANUR PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10111", district:"37", Block:"384", label:"GOVERNMENT HIGHER SECONDARY SCHOOL NEGANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10112", district:"37", Block:"384", label:"SRI RAAM MATRIC HIGHER SECONDARY SCHOOL NANGILIKONDAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10113", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL KADAMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10114", district:"37", Block:"384", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ILLODU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10115", district:"37", Block:"384", label:"GOVERNMENT HIGH SCHOOL PENNAGAR", Sch_type:"Government", Category:"High School"},
    { key:"10116", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL ARIYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10117", district:"37", Block:"384", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MELOLAKKUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10118", district:"37", Block:"384", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VEERANAMUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10119", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL EDANEMLI", Sch_type:"Government", Category:"Middle School"},
    { key:"10120", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL MELATHIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10121", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL ARUGAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"10122", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL  PALLIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10123", district:"37", Block:"384", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MELSITHAMUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10124", district:"37", Block:"384", label:"GOVERNMENT HIGH SCHOOL VALLAM", Sch_type:"Government", Category:"High School"},
    { key:"10125", district:"37", Block:"384", label:"GOVERNMENT HIGH SCHOOL KONGARAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10126", district:"37", Block:"384", label:"GOVERNMENT HIGH SCHOOL MELSEVUR", Sch_type:"Government", Category:"High School"},
    { key:"10127", district:"37", Block:"384", label:"PANCHAYAT UNION MIDDLE SCHOOL  THALAVALAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10128", district:"37", Block:"214", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VELLIMEDUPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10129", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL VADASIRUVALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10130", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL KEEZHMAVILANGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10131", district:"37", Block:"214", label:"GOVERNMENT HIGHER SECONDARY SCHOOL DHADHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10132", district:"37", Block:"214", label:"THIRUVALLUVAR HIGHER SECONDARY SCHOOL  KARUVAMBAKKAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10133", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL URAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10134", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL MELPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10135", district:"37", Block:"214", label:"GOVERNMENT HIGHER SECONDARY SCHOOL OLAKKUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10136", district:"37", Block:"214", label:"SRI THANGAM MATRICULATION SCHOOL. OLAKKUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10137", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL KONERIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10138", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL KEEZHADHANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"10139", district:"37", Block:"214", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10140", district:"37", Block:"214", label:"ST.FRANCIS MATRIC HIGHER SECONDARY SCHOOL,ASSISI NAGAR,SARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10141", district:"37", Block:"214", label:"GOVERNMENT HIGHER SECONDARY SCHOOL NOLAMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10142", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL KEEZHSEVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"10143", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL KEEZHPOOTHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"10144", district:"37", Block:"214", label:"GOVERNMENT HIGH SCHOOL ATCHIPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"10145", district:"37", Block:"214", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL  PANGOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10146", district:"37", Block:"214", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ONGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10147", district:"37", Block:"214", label:"PANCHAYAT UNION MIDDLE SCHOOL KADAVAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10148", district:"37", Block:"214", label:"GOVERNMENT HIGHER SECONDARY SCHOOL AVANIPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10149", district:"37", Block:"214", label:"ST.ANN'S Hr SEC SCHOOL TINDIVANAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"10150", district:"37", Block:"214", label:"MONTFORT MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10151", district:"37", Block:"214", label:"TAGORE MATRICULATION HIGHER SECONDARY SCHOOL JAYAPURAM,TINDIVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10152", district:"37", Block:"214", label:"ST.JOSEPH OF CLUNY MATRIC HR.SEC.SCHOOL, TINDIVANAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10153", district:"37", Block:"214", label:"ST. PHILOMENA'S GIRLS HIGHER SECONDARY SCHOOL TINDIVANAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"10154", district:"37", Block:"214", label:"MUNICIPAL HIGHER SECONDARY SCHOOL KAVERIPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10155", district:"37", Block:"214", label:"ST.ANN'S MIDDLE SCHOOL TINDIVANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10156", district:"37", Block:"214", label:"TAGORE HIGH SCHOOL (CBSE), TINDIVANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10157", district:"37", Block:"214", label:"MADRAS DIOCESE CRANE MIDDLE SCHOOL TINDIVANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10158", district:"37", Block:"214", label:"NATIONAL HR SEC SCHOOL TINDIVANAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"10159", district:"37", Block:"214", label:"COOSHALCHAND HR SEC SCHOOL, TINDIVANAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10160", district:"37", Block:"214", label:"SRI KANNIGAPARAMESWARI GIRLS HIGH SCHOOL TINDIVANAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10161", district:"37", Block:"214", label:"MUSLIM MIDDLE SCHOOL TINDIVANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10162", district:"37", Block:"214", label:"SRI MARAGADAMBIGAI HIGH SCHOOL, TINDIVANAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10163", district:"37", Block:"214", label:"CHANAKYA MATRIC HIGHER SECONDARY SCHOOL, PONDY ROAD, TINDIVANAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10164", district:"37", Block:"214", label:"SOLAR MATRICULATION HIGHER SECONDARY SCHOOL.TINDIVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10165", district:"37", Block:"214", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  MURUNGAPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10166", district:"37", Block:"214", label:"WALTER SCUDDER HIGHER SECONDARY SCHOOL TINDIVANAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"10167", district:"37", Block:"214", label:"SREE NAGAMMAL MIDDLE SCHOOL , TINDIVANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10168", district:"37", Block:"214", label:"MARY'S MATRICULATION HIGHER SECONDARY SCHOOL TINDIVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10169", district:"37", Block:"214", label:"SAINT FRANCIS D' ASSISI MATRICULATION HIGHER SECONDARY SCHOOL TINDIVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10170", district:"37", Block:"214", label:"VALARMATHI KARUPAIYA MATRIC SCHOOL, TINDIVANAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10171", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL AGOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10172", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL AVVAIYARKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10173", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  ALAGRAMAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10174", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  ASOOR", Sch_type:"Government", Category:"High School"},
    { key:"10175", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL RETTANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10176", district:"37", Block:"164", label:"KENNEDY MATRIC HIGHER SECONDARY SCHOOL RETTANAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10177", district:"37", Block:"164", label:"HOLY ANGEL MATRIC HIGHER SECONDARY SCHOOL  RETTANAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10178", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL PERAMANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10179", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL MAILAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10180", district:"37", Block:"164", label:"SRI SIVAPRAKASA SWAMIGAL HIGHER SECONDARY SCHOOL MAILAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10181", district:"37", Block:"164", label:"NEHRU MATRIC HIGHER SECONDARY SCHOOL MAILAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10182", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL THENALAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10183", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  CHENDUR", Sch_type:"Government", Category:"High School"},
    { key:"10184", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THAZHUTHALI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10185", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  DEEVANUR", Sch_type:"Government", Category:"High School"},
    { key:"10186", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PATHIRAPULIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10187", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL NEDIMOZHIYANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10188", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PERIYATHACHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10189", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VEEDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10190", district:"37", Block:"164", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PERANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10191", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  KOLLAR", Sch_type:"Government", Category:"High School"},
    { key:"10192", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL VEMPOONDI ", Sch_type:"Government", Category:"Middle School"},
    { key:"10193", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL GANAPATHIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10194", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  KATTUSIVIRI", Sch_type:"Government", Category:"High School"},
    { key:"10195", district:"37", Block:"164", label:"ST.JOHN'S MATRIC HIGHER SECONDARY SCHOOL CHINNANERKUNAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10196", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  KOOTERIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10197", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  KOTHAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"10198", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL NADUVANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10199", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  NEDI", Sch_type:"Government", Category:"High School"},
    { key:"10200", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  MELPERADIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"10201", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  PULIYANUR", Sch_type:"Government", Category:"High School"},
    { key:"10202", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL VELIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10203", district:"37", Block:"164", label:"PANCHAYAT UNION MIDDLE SCHOOL SITHANI", Sch_type:"Government", Category:"Middle School"},
    { key:"10204", district:"37", Block:"164", label:"GOVERNMENT HIGH SCHOOL  CHINNANERKUNAM", Sch_type:"Government", Category:"High School"},
    { key:"10205", district:"37", Block:"164", label:"AIDED MIDDLE SCHOOL KALLAKOLATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10206", district:"37", Block:"164", label:"SRI RAJARAJESWARI MATRIC HIGH SCHOOL,PELAKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10207", district:"37", Block:"164", label:"AIDED MIDDLE SCHOOL MUPPULI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10208", district:"37", Block:"175", label:"ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL SINGANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10209", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL THENPAZAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10210", district:"37", Block:"175", label:"GOVERNMENT HIGHER SECONDARY SCHOOL OMANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10211", district:"37", Block:"175", label:"VKM VIDHYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10212", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL  ANNAM PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10213", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL VENGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10214", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL, KEEL EDAYALAM", Sch_type:"Government", Category:"High School"},
    { key:"10215", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL MOLASUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10216", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL KOVADI", Sch_type:"Government", Category:"High School"},
    { key:"10217", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL THENNERGUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10218", district:"37", Block:"175", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ENDIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10219", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL VITTALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"10220", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL PERUMUKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10221", district:"37", Block:"175", label:"GANDHIJI HIGH SCHOOL KIZHSIVIRI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10222", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL T.NALLALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10223", district:"37", Block:"175", label:"GOVERMENT HIGHER SECONDARY SCHOOL  BRAMMADESAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10224", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL VANNIPPER", Sch_type:"Government", Category:"Middle School"},
    { key:"10225", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL,VADANERKUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10226", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"10227", district:"37", Block:"175", label:"SUBHASHREE VIDHYALAYA MATRICULATION SCHOOL NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10228", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL ALANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"10229", district:"37", Block:"175", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VEPPERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10230", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL OMIPPER", Sch_type:"Government", Category:"Middle School"},
    { key:"10231", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10232", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL KANDADU", Sch_type:"Government", Category:"High School"},
    { key:"10233", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL EKKIYARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10234", district:"37", Block:"175", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL KAZHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10235", district:"37", Block:"175", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MARAKKANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10236", district:"37", Block:"175", label:"SRI RAMAKRISHNA MATRIC HIGHER SECONDARY SCHOOL MARAKKANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10237", district:"37", Block:"175", label:"JMJ MATRIC HIGHER SECONDARY SCHOOL MARAKKANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10238", district:"37", Block:"175", label:"GOVERNMENT (GIRLS) HIGH SCHOOL MARAKKANAM", Sch_type:"Government", Category:"High School"},
    { key:"10239", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL KAIPANIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"10240", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL ALAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10241", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL PANICHAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10242", district:"37", Block:"175", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ANUMANDAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10243", district:"37", Block:"175", label:"BHARATHIYAR MATRIC HR. SEC SCHOOL,  MARAKKANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10244", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL M.PUDUPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10245", district:"37", Block:"175", label:"PANCHAYAT UNION MIDDLE SCHOOL VANDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10246", district:"37", Block:"175", label:"GOVERNMENT HIGHER SECONDARY SCHOOL NADUKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10247", district:"37", Block:"175", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KOONIMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10248", district:"37", Block:"175", label:"ALM MATRICULATION HIGHER SECONDARY SCHOOL KOONIMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10249", district:"37", Block:"175", label:"AL- RASHEED MATRICULATION SCHOOL KOONIMEDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10250", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL MUDALIYARKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"10251", district:"37", Block:"175", label:"SAMINATHA VIDHYALAYA HIGH SCHOOL KEEZHPUTHUPATTU", Sch_type:"Un-aided", Category:"High School"},
    { key:"10252", district:"37", Block:"175", label:"RYAN MATRICULATION SCHOOL NAMBIKKAINALLUR KILPUTHUPATTU", Sch_type:"Un-aided", Category:"High School"},
    { key:"10253", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL THENKODIPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"10254", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL ULAGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"10255", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL KILAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10256", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL KARATTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10257", district:"37", Block:"379", label:"AIDED MIDDLE SCHOOL UPPUVELUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10258", district:"37", Block:"379", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PUDUKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10259", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL T. PARANGANI", Sch_type:"Government", Category:"Middle School"},
    { key:"10260", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL NALLAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"10261", district:"37", Block:"379", label:"ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL KONDAMUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10262", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL ARUVAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10263", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL THENSIRUVALORE", Sch_type:"Government", Category:"High School"},
    { key:"10264", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL ADHANAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10265", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL THERKUNAM", Sch_type:"Government", Category:"High School"},
    { key:"10266", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL KILIYANUR (C)", Sch_type:"Government", Category:"Middle School"},
    { key:"10267", district:"37", Block:"379", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KILIYANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10268", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL KONJIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10269", district:"37", Block:"379", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KAZHUPERUMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10270", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL KOZHUVARI", Sch_type:"Government", Category:"Middle School"},
    { key:"10271", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL NESAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10272", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL KODUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10273", district:"37", Block:"379", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THAILAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10274", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL KUNNAM", Sch_type:"Government", Category:"High School"},
    { key:"10275", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL PERUMPAKKAM (C)", Sch_type:"Government", Category:"Middle School"},
    { key:"10276", district:"37", Block:"379", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ERAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10277", district:"37", Block:"379", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KARASANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10278", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL SEMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"10279", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL RANGANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10280", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL KATRAMPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"10281", district:"37", Block:"379", label:"ARUNACHALAM AIDED MIDDLE SCHOOL OZHUNDIYAPATTU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10282", district:"37", Block:"379", label:"ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL  PULICHAPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10283", district:"37", Block:"379", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10284", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL V.PARANGANI", Sch_type:"Government", Category:"Middle School"},
    { key:"10285", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL SIRUVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10286", district:"37", Block:"379", label:"ELANGO AMS POMBOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10287", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL THIRUVAKKARAI", Sch_type:"Government", Category:"High School"},
    { key:"10288", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL SENGAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10289", district:"37", Block:"379", label:"SRI ARAVINDAR VIDHYALAYA  MATRICULATION HIGH SCHOOL  AGASAMPATTU", Sch_type:"Un-aided", Category:"High School"},
    { key:"10290", district:"37", Block:"379", label:"DMI ST. JOSEPH  MATRICULATION SCHOOL ACHARAMPATTU", Sch_type:"Un-aided", Category:"High School"},
    { key:"10291", district:"37", Block:"379", label:"UDAVI GENTILLESSE MATRIC HIGHER SECONDARY  SCHOOL,EDAYANCHAVADY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10292", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL BOMMIYARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10293", district:"37", Block:"379", label:"AMS THIRUCHITRAMBALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10294", district:"37", Block:"379", label:"GANDHI AIDED HSS THIRUCHITRAMBALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"10295", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL POOTHURAI", Sch_type:"Government", Category:"High School"},
    { key:"10296", district:"37", Block:"379", label:"SWAMI VIVEKANANDHA MIDDLE SCHOOL, KADAPERIKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10297", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL PERAMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10298", district:"37", Block:"379", label:"PANCHAYAT UNION MIDDLE SCHOOL PERIYAMUDALIYAR CHAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10299", district:"37", Block:"379", label:"GOVERNMENT HIGHER SECONDARY SCHOOL   KOTTAKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10300", district:"37", Block:"379", label:"VETHA HIGH SCHOOL CHINNA KOTTAKUPPAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10301", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL KEEZHKOOTHAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"10302", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL NALLAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"10303", district:"37", Block:"100", label:"GOVERNMENT MIDDLE SCHOOL UDAYANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10304", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ATHIYURTHIRUKKAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10305", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  KARUVATCHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10306", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL PUDUKARUVATCHI", Sch_type:"Government", Category:"High School"},
    { key:"10307", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PANAMALAI PETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10308", district:"37", Block:"100", label:"GOVERNMENT GIRLS HIGH SCHOOL PANAMALAI PETTAI", Sch_type:"Government", Category:"High School"},
    { key:"10309", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SANGEETHAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10310", district:"37", Block:"100", label:"ST.MARY'S HIGH SCHOOL  NANGATHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10311", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL KALYANAMPOONDI", Sch_type:"Government", Category:"High School"},
    { key:"10312", district:"37", Block:"100", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ANNIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10313", district:"37", Block:"100", label:"OLIVE TREE MATRICULATION HIGHER SECONDARY SCHOOL, ANNIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10314", district:"37", Block:"100", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  ANNIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10315", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL VELLERIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10316", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL EZHUSEMPON", Sch_type:"Government", Category:"High School"},
    { key:"10317", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL MELKARANAI", Sch_type:"Government", Category:"High School"},
    { key:"10318", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL SE.KUNNATHUR", Sch_type:"Government", Category:"High School"},
    { key:"10319", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL  KANJANUR", Sch_type:"Government", Category:"High School"},
    { key:"10320", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL SIRUVALAI", Sch_type:"Government", Category:"High School"},
    { key:"10321", district:"37", Block:"100", label:"REDWINGS MATRICULATION SCHOOL  KEDAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10322", district:"37", Block:"100", label:"ST. LITTLE FLOWER HIGH SCHOOL KAKKANUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10323", district:"37", Block:"100", label:"SRI VIJAYALAKSHMI MATRICULATION SCHOOL ,KAKKANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10324", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL  KOZHIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10325", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL  PALLIYANTHUR", Sch_type:"Government", Category:"High School"},
    { key:"10326", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY SCHOOL(BOYS)  KEDAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10327", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL (GIRLS) KEDAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10328", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MAMBALAPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10329", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL KALPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10330", district:"37", Block:"100", label:"ROMAN CATHOLIC MIDDLE SCHOOL THELI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10331", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10332", district:"37", Block:"100", label:"PANCHAYAT UNION MIDDLE SCHOOL  AGARAM SITHAMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"10333", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL  VENGANTHUR", Sch_type:"Government", Category:"High School"},
    { key:"10334", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL SANIMEDU", Sch_type:"Government", Category:"High School"},
    { key:"10335", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL  PERUMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"10336", district:"37", Block:"100", label:"SFS MAT.SCHOOL PERUMPAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10337", district:"37", Block:"100", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KONUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10338", district:"37", Block:"100", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL  KARUNGALIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10339", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ENNAIYIRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10340", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL MUTTATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10341", district:"37", Block:"401", label:"WYCKOFF HIGHER SECONDARY SCHOOL MUTTATHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"10342", district:"37", Block:"401", label:"GOVERNMENT HIGH SCHOOL E-MANDAGAPET", Sch_type:"Government", Category:"High School"},
    { key:"10343", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL ECHANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10344", district:"37", Block:"401", label:"GOVERNMENTHIGH SCHOOL ESALAM", Sch_type:"Government", Category:"High School"},
    { key:"10345", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THENPAIR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10346", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL T PUDUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10347", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL CHINNATHACHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10348", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL KUTHAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10349", district:"37", Block:"401", label:"ADI DRAVIDAR WELFARE HIGH SCHOOL V SALAI", Sch_type:"Government", Category:"High School"},
    { key:"10350", district:"37", Block:"401", label:"ST. PATRICK'S  ACADEMY MATRICULATION SCHOOL V. SALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10351", district:"37", Block:"401", label:"GHS MELAKONDAI", Sch_type:"Government", Category:"High School"},
    { key:"10352", district:"37", Block:"401", label:"GOVERNMENT HIGH SCHOOL ASOOR", Sch_type:"Government", Category:"High School"},
    { key:"10353", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PONNANKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10354", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL VEMBI", Sch_type:"Government", Category:"Middle School"},
    { key:"10355", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL ULAGALAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10356", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THUMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10357", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL  KOTTIYAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10358", district:"37", Block:"401", label:"JAI RAMAKRISHNAA VIDYALAYA MATRICULATION SCHOOL ,V.SATHANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10359", district:"37", Block:"401", label:"GOVERNMENT HIGH SCHOOL V.SATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"10360", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL VIKARAVANDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10361", district:"37", Block:"401", label:"GOVERNMENT HIGH SCHOOL (GIRLS) VIKARAVANDI", Sch_type:"Government", Category:"High School"},
    { key:"10362", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  VIKARAVANDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10363", district:"37", Block:"401", label:"ST MARY'S MATRIC SCHOOL VIKARAVANDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10364", district:"37", Block:"401", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL  AVADAIYARPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10365", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL THORAVI", Sch_type:"Government", Category:"Middle School"},
    { key:"10366", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL  PANAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10367", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL PAPPANAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10368", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL ORATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10369", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL CHINTHAMANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10370", district:"37", Block:"401", label:"NAAHAR PUBLIC SCHOOL AYYUR AGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10371", district:"37", Block:"401", label:"GOVERNMENT HIGH SCHOOL MUNDIYAMPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"10372", district:"37", Block:"401", label:"RAJSHREE SUGARS RAMAKRISHNA VID MATRIC HIGHER SECONDARY SCHOOL , MUNDIYAMPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10373", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PANAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10374", district:"37", Block:"401", label:"GOVERNMENT HIGH SCHOOL RADHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"10375", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL MADURAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10376", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL KAPPIYAMPULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10377", district:"37", Block:"401", label:"SIGA  HIGHER SECONDARY SCHOOL KAPPIYAMPULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10378", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL VAKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10379", district:"37", Block:"401", label:"PANCHAYAT UNION MIDDLE SCHOOL PAGANDAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10380", district:"37", Block:"401", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PAGANDAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10381", district:"37", Block:"401", label:"AIDED MIDDLE SCHOOL SINGARAPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10382", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL MUTHAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"10383", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL ARASAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"10384", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL ATHIYUR THIRUVATHI", Sch_type:"Government", Category:"High School"},
    { key:"10385", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL ASARANGKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"10386", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL ANANGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10387", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL CHOLAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10388", district:"37", Block:"130", label:"GOVERNMENT HIGHER SECONDARY SCHOOL EDAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10389", district:"37", Block:"130", label:"ADI DRAVIDA WELFARE HIGH SCHOOL  KAPPUR", Sch_type:"Government", Category:"High School"},
    { key:"10390", district:"37", Block:"130", label:"BONNE NEHRU MATRICULATION SCHOOL,KANDAMANADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10391", district:"37", Block:"130", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  KANDAMANADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10392", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL KALLAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10393", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL MARAGATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"10394", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL KAAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10395", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL KAVANIPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10396", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL VALAVANUR WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"10397", district:"37", Block:"130", label:"GOVINDIER AIDED MIDDLE SCHOOL VALAVANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10398", district:"37", Block:"130", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  VALAVANUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10399", district:"37", Block:"130", label:"S.R.GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ,VALAVANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10400", district:"37", Block:"130", label:"SEVENTH DAY ADVENTIST MATRICULATION SCHOOL VALAVANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10401", district:"37", Block:"130", label:"SHREEM MATRICULATION SCHOOL VALAVANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10402", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL KONDANGI", Sch_type:"Government", Category:"High School"},
    { key:"10403", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL  KOLIYANUR WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"10404", district:"37", Block:"130", label:"CONIFER MATRICULATION SCHOOL KOLIYANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10405", district:"37", Block:"130", label:"SRI SARASWATHI VIDHYALA MATRICULATION SCHOOL KOLIYANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10406", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL ANICHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10407", district:"37", Block:"130", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KOLIYANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10408", district:"37", Block:"130", label:"SIVASAKTHI MATRICULATION HIGHER SECONDARY SCHOOL KOLIYANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10409", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL SALAIAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10410", district:"37", Block:"130", label:"ADITYA'S VIVEKANAN MATRIC HIGHER SECONDARY SCHOOL DEVANATHA SWAMY NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10411", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL SALAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10412", district:"37", Block:"130", label:"RAMAKRISHNA MISSION VIDYALAYA MATRICULATION SCHOOL SALAMEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"10413", district:"37", Block:"130", label:"BHARATHI AIDED MIDDLE SCHOOL SAGADEVANPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10414", district:"37", Block:"130", label:"ROMAN CATHOLIC AIDED MIDDLE SCHOOL SENGADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10415", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL SOZHAGANUR", Sch_type:"Government", Category:"High School"},
    { key:"10416", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL THALAVANUR", Sch_type:"Government", Category:"High School"},
    { key:"10417", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL T METTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10418", district:"37", Block:"130", label:"SRI JEYEANDRA SARASWATHY VIDYAALAYAA MATRICULATION HIGHER SECONDARY SCHOOL T.MATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10419", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL THIRUPACHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10420", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL POOTHAMEDU", Sch_type:"Government", Category:"High School"},
    { key:"10421", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL THOGAIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10422", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL NANNADU", Sch_type:"Government", Category:"High School"},
    { key:"10423", district:"37", Block:"130", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL NARAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10424", district:"37", Block:"130", label:"KALAIMAGAL AIDED MIDDLE SCHOOL RAGAVANPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10425", district:"37", Block:"130", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PILLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10426", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL PIDAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10427", district:"37", Block:"130", label:"GOVERNMENT HIGH SCHOOL POYYAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"10428", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL  MELPATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"10429", district:"37", Block:"130", label:"PANCHAYAT UNION MIDDLE SCHOOL VAZHUDAREDDY", Sch_type:"Government", Category:"Middle School"},
    { key:"10430", district:"37", Block:"130", label:"S.P.N MATRICULATION HIGHER SECONDARY SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10431", district:"37", Block:"130", label:"ADI DRAVIDA WELFARE HIGHER SECONDARY SCHOOL VAZHUDAREDDY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10432", district:"37", Block:"130", label:"SHANTHINIKETAN  MATRICULATION SCHOOL THIRUPPACHAVADIMEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"10433", district:"37", Block:"130", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VIRATTIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10434", district:"37", Block:"102", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VAZHUDHAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10435", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL KALITHIRAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10436", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL AMMANANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10437", district:"37", Block:"102", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SITHALAMPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10438", district:"37", Block:"102", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL KODUKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10439", district:"37", Block:"102", label:"GOVERNMENT HIGH SCHOOL M.MANDAGAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10440", district:"37", Block:"102", label:"OMANDURAR AIDED MIDDLE SCHOOL KOTHAMPAKKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10441", district:"37", Block:"102", label:"ANANDHA AIDED MIDDLE SCHOOL VANIAMPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10442", district:"37", Block:"102", label:"GOVERNMENT HIGH SCHOOL V.AGARAM COLONY", Sch_type:"Government", Category:"High School"},
    { key:"10443", district:"37", Block:"102", label:"GOVERNMENT HIGH SCHOOL VADAVAMBALAM", Sch_type:"Government", Category:"High School"},
    { key:"10444", district:"37", Block:"102", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL P.VILLIANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10445", district:"37", Block:"102", label:"GOVERNMENTGIRLS  HIGH SCHOOL SIRUVANTHADU", Sch_type:"Government", Category:"High School"},
    { key:"10446", district:"37", Block:"102", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SIRUVANTHADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10447", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL POOVARASANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10448", district:"37", Block:"102", label:"GOVERNMENT HIGH SCHOOL ARPISAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"10449", district:"37", Block:"102", label:"GOVERNMENT HIGH SCHOOL PAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"10450", district:"37", Block:"102", label:"VENKATAVARADA AIDED MIDDLE SCHOOL KRISHNAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10451", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL KALINJIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10452", district:"37", Block:"102", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL RAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10453", district:"37", Block:"102", label:"GOVERNMENT HIGHER SECONDARY SCHOOL RAMPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10454", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL KONGAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10455", district:"37", Block:"102", label:"GOVERNMENT HIGH SCHOOL L.R.PALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"10456", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL  V.BOODHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10457", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL  V.MATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10458", district:"37", Block:"102", label:"GOVERNMENT HIGH SCHOOL  KUMALAM", Sch_type:"Government", Category:"High School"},
    { key:"10459", district:"37", Block:"102", label:"THIRUVALLUVAR AIDED MIDDLE SCHOOL  SESHANGANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10460", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL  PALLITHENNAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10461", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL NAVAMALMARUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10462", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL  C.B.SAMUDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10463", district:"37", Block:"102", label:"PANCHAYAT UNION MIDDLE SCHOOL KANDAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10464", district:"37", Block:"102", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KANDAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10465", district:"37", Block:"102", label:"GOVERNMENT HIGH SCHOOL PERIABABU SAMUDRAM", Sch_type:"Government", Category:"High School"},
    { key:"10466", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL MELANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10467", district:"9", Block:"269", label:"GOVERNMENT HIGH SCHOOL ATHIYANTHAL", Sch_type:"Government", Category:"High School"},
    { key:"10468", district:"9", Block:"323", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VILANDAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10469", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL KULADEEPAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10470", district:"9", Block:"323", label:"St.AROCKIA ANNAI HIGH SCHOOL T.ATHIPAKKAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10471", district:"37", Block:"189", label:"GOVERNMENT HIGH SCHOOL KODUKKAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10472", district:"37", Block:"189", label:"OUR LADY OF FATHIMA MATRICULATION SCHOOL ADICHANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10473", district:"37", Block:"189", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL VEERAPANDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10474", district:"37", Block:"189", label:"GOVERNMENT HIGH SCHOOL NAYANUR", Sch_type:"Government", Category:"High School"},
    { key:"10475", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL DEVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10476", district:"37", Block:"189", label:"GOVERNMENT HIGH  SCHOOL OTTAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10477", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL NERKUNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10478", district:"37", Block:"189", label:"GOVERNMENT HIGHER SECONDARY SCHOOL V. PUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10479", district:"37", Block:"189", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  KANDACHIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10480", district:"37", Block:"189", label:"A G R GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL KANDACHIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10481", district:"37", Block:"189", label:"ST.MARY HIGH  SCHOOL OTHIYATHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10482", district:"37", Block:"189", label:"A R R GOVERNMENT HIGHER SECONDARY SCHOOL KARANAI PERICHANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10483", district:"37", Block:"189", label:"ST.XAVIER'S BOYS HIGHER SECONDARY SCHOOL MUGAIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10484", district:"37", Block:"189", label:"ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL MUGAIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10485", district:"37", Block:"189", label:"ST PETER PAUL SPECIAL HIGHER SECONDARY SCHOOL MUGAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10486", district:"37", Block:"189", label:"ST JOSEPH'S MATRICULATION SCHOOL MUGAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10487", district:"37", Block:"189", label:"GOVERNMENT HIGH  SCHOOL AYANDUR", Sch_type:"Government", Category:"High School"},
    { key:"10488", district:"37", Block:"189", label:"GOVERNMENT HIGH  SCHOOL SENNAKUNAM", Sch_type:"Government", Category:"High School"},
    { key:"10489", district:"37", Block:"189", label:"PANCHAYAT UNION MIDDLE SCHOOL VEERANGIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10490", district:"37", Block:"189", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL ALAMPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10491", district:"37", Block:"189", label:"AKD MATRIC HIGHER SECONDARY SCHOOL ALAMBADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10492", district:"37", Block:"189", label:"ROMAN CATHOLIC MIDDLE  SCHOOL KODUNGAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10493", district:"37", Block:"189", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ARCADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10494", district:"37", Block:"189", label:"GOVERNMENT HIGH SCHOOL PAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"10495", district:"9", Block:"269", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL MANALURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10496", district:"9", Block:"269", label:"GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL MANALURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10497", district:"9", Block:"269", label:"DEEPALAKSHMI MATRICULATION SCHOOL MANALURPET", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10498", district:"37", Block:"189", label:"BALAMANDIR MATRIC HIGHER SECONDARY SCHOOL ARAKANDANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10499", district:"37", Block:"189", label:"SRI LAKSHMI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, ARAKANDANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10500", district:"37", Block:"189", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ARAKANDANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10501", district:"37", Block:"189", label:"AIDED MIDDLE SCHOOL  ARAKANDANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10502", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL VADAKKUNEMILI", Sch_type:"Government", Category:"Middle School"},
    { key:"10503", district:"9", Block:"323", label:"SRI BARATHI MATRIC  SCHOOL  VADAKKUNEMILI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10504", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  ATHANDAMARUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10505", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL  MUDHALUR", Sch_type:"Government", Category:"High School"},
    { key:"10506", district:"9", Block:"323", label:"SRI RAMAKRISHNA MATRICULATION SCHOOL AVI KOLAPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10507", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL  KATTUPAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"10508", district:"9", Block:"323", label:"AIDED MIDDLE SCHOOL  ELRAMPATTU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10509", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  VADAMALAIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10510", district:"9", Block:"323", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  THIRUPALA PANDHAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10511", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  THURINJIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10512", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL  PONNIYANDHAL", Sch_type:"Government", Category:"High School"},
    { key:"10513", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  KOMALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10514", district:"9", Block:"323", label:"SRI MARUTHI VIDHYASHRAM MATRIC HIGH SCHOOL  KOMALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10515", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL  CHOLAVANDIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"10516", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL THAGADI", Sch_type:"Government", Category:"High School"},
    { key:"10517", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL  KOOVANUR", Sch_type:"Government", Category:"High School"},
    { key:"10518", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL THIMMACHUR", Sch_type:"Government", Category:"High School"},
    { key:"10519", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  PADIYANDHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10520", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  KOLAPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10521", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL NEDUMUDAIYAN", Sch_type:"Government", Category:"Middle School"},
    { key:"10522", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  MADAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10523", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOLL  EDAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10524", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  AVI.PUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10525", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  MELATHALANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10526", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL KANAGANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10527", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL SIVANARTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10528", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL  KEEZHATHAZHANUR", Sch_type:"Government", Category:"High School"},
    { key:"10529", district:"9", Block:"323", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  G.ARIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10530", district:"9", Block:"323", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  G.ARIYUR (MODEL)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10531", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL  AMMANKOLLAIMEDU", Sch_type:"Government", Category:"High School"},
    { key:"10532", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  KACHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10533", district:"9", Block:"323", label:"KASTUBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL TIRUPALAPANDHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10534", district:"9", Block:"323", label:"GOVERNMENT HIGH SCHOOL  VENGUR", Sch_type:"Government", Category:"High School"},
    { key:"10535", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  ALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"10536", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL THULAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10537", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  MOHALAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10538", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL DEVIYAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10539", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL SANGIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10540", district:"9", Block:"323", label:"PANCHAYAT UNION MIDDLE SCHOOL  PILLAIYAR PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10541", district:"9", Block:"323", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL TIRUKOVILUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10542", district:"9", Block:"323", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL TIRUKOVILUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10543", district:"9", Block:"323", label:"SRI.GNAN.MATRIC.HIGHER SECONDARY SCHOOL  TIRUKOILUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10544", district:"9", Block:"323", label:"UNAIDED MIDDLE SCHOOL (BOARDING WELFARE) TIRUKOVILUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10545", district:"9", Block:"323", label:"DANISH MISSION (GIRLS) BOARDING HIGHER SECONDARY SCHOOL  SILOAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10546", district:"9", Block:"323", label:"DANISH  MISSION  MIDDLE SCHOOL  KEELAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10547", district:"9", Block:"323", label:"DANISH MISSION  HIGH SCHOOL  SANDHAPET", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10548", district:"9", Block:"323", label:"SRI VIDYA MANDIR SENIOR SECONDARY SCHOOL TIRUKOILUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10549", district:"9", Block:"323", label:"SRI SAI VIDHYALAYA MATRICULATION SCHOOL SANDAPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"10550", district:"37", Block:"342", label:"GOVERNMENT HIGH SCHOOL ARUNGURUKKAI", Sch_type:"Government", Category:"High School"},
    { key:"10551", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL OTTANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10552", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL AMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"10553", district:"37", Block:"342", label:"GOVERNMENT HIGH SCHOOL PERIYASEVALAI", Sch_type:"Government", Category:"High School"},
    { key:"10554", district:"37", Block:"342", label:"CHENGALRAYAN CO-OPERATIVE SUGAR MILLS MATRICULATION SCHOOL PERIYASEVALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10555", district:"37", Block:"342", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SARAVANAMPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10556", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  ANAIVARI", Sch_type:"Government", Category:"Middle School"},
    { key:"10557", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL ARUMPATTU SAMATHUVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10558", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL SITHANANGOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"10559", district:"37", Block:"342", label:"St.JOHN'S HIGHER  SECONDARY SCHOOL SITHANANGOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10560", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL MAMANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10561", district:"37", Block:"342", label:"ARUTKODI AIDED MIDDLE SCHOOL ARUL NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10562", district:"37", Block:"342", label:"KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL THIRUVENNAINALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10563", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL PENNAIVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10564", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  AMAVASAIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10565", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL PAVANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10566", district:"37", Block:"342", label:" GOVERNMENT HIGH SCHOOL T.PUDHUPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"10567", district:"37", Block:"342", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PERANGIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10568", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  EDAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10569", district:"37", Block:"342", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  SITHALINGAMADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10570", district:"37", Block:"342", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  SITHALINGAMADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10571", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL ERUVELPATTU COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"10572", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  KARAPPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10573", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  MELTHANIYALAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10574", district:"37", Block:"342", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  ANATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10575", district:"37", Block:"342", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  ARASUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10576", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  KANTHALAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10577", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL ANDARAYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10578", district:"37", Block:"342", label:"KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL ANDARAYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10579", district:"37", Block:"342", label:"GOVERNMENT HIGH SCHOOL  T.EDAIYAR", Sch_type:"Government", Category:"High School"},
    { key:"10580", district:"37", Block:"342", label:"CHRIST THE KING MATRICULATION HIGHER SECONDARY SCHOOL, METTUKUPPAM, ELANDURAI VILLAGE- 607209", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10581", district:"37", Block:"342", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  MANAKKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10582", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL CHINNASEVALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10583", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL THIRUVENNAINALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10584", district:"37", Block:"342", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  THIRUVENNAINALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10585", district:"37", Block:"342", label:"BONNE NEHRU HIGHER SECONDARY SCHOOL THIRUVENNAINALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10586", district:"37", Block:"342", label:"GANDHI MEMORIAL HIGHER SECONDARY SCHOOL THIRUVENNAINALLUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10587", district:"37", Block:"342", label:"GOVERNMENT HIGH SCHOOL  THADUTHATKONDOOR", Sch_type:"Government", Category:"High School"},
    { key:"10588", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL MAZHAYAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10589", district:"37", Block:"342", label:"GOVERNMENT HIGHER SECONDARY SCHOOL EMAPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10590", district:"37", Block:"342", label:"GOVERNMENT HIGH SCHOOL  SIRUMADURAI", Sch_type:"Government", Category:"High School"},
    { key:"10591", district:"37", Block:"342", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  ENATHIMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10592", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL SIRUVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10593", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  SEMMAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10594", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  MELAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10595", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL  VALAIYAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10596", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL SANKARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10597", district:"9", Block:"277", label:"CHRIST SPECIAL HIGH SCHOOL SANKARAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10598", district:"9", Block:"277", label:"GOVERNMENT GIRLS HIGHER  SECONDARY SCHOOL SANKARAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10599", district:"9", Block:"277", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  SANKARAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10600", district:"9", Block:"277", label:"ST.JOSEPH ACADEMY  MATRIC HIGHER SECONDARY SCHOOL SANKARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10601", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL KATTU VANNANJUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10602", district:"9", Block:"277", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  DEVAPANDALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10603", district:"9", Block:"277", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  DEVAPANDALAM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10604", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL  S.KULATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10605", district:"9", Block:"277", label:"ADAIKALA ANNAI HIGHER SECONDARY SCHOOL VIRIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10606", district:"9", Block:"277", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SOUNDARAVALLI PALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10607", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL THIYAGARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10608", district:"9", Block:"277", label:"ALAGAPPA AIDED MIDDLE SCHOOL POOTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10609", district:"9", Block:"277", label:"GOVERNMENT HIGH SCHOOL PAVALAM", Sch_type:"Government", Category:"High School"},
    { key:"10610", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL SEMBARAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10611", district:"9", Block:"277", label:"GOVERNMENT HIGH SCHOOL  POIKUNAM", Sch_type:"Government", Category:"High School"},
    { key:"10612", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL ARASAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10613", district:"9", Block:"277", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  ARASAMPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10614", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL KOSAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10615", district:"9", Block:"277", label:"GOVERNMENT HIGH SCHOOL  SESHASAMUDHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"10616", district:"9", Block:"277", label:"GOVERNMENT HIGHER SECONDARY SCHOOL NEDUMANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10617", district:"9", Block:"277", label:"BHARATHI MATRICULATION SCHOOL, NEDUMANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10618", district:"9", Block:"277", label:"GOVERNMENT HIGH SCHOOL PALAYANUR", Sch_type:"Government", Category:"High School"},
    { key:"10619", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL MANJAPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10620", district:"9", Block:"277", label:"OXFORD MATRIC. HIGHER SECONDARY SCHOOL VADASEMAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10621", district:"9", Block:"277", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MURARBAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10622", district:"9", Block:"277", label:"GOVERNMENT GIRLS HIGH SCHOOL MURARBAD", Sch_type:"Government", Category:"High School"},
    { key:"10623", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL SOLAMPATTU COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"10624", district:"9", Block:"277", label:"UNAIDED ROMAN CATHALIC MIDDLE SCHOOL  SOLAMPATTU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10625", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL LAKKINAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"10626", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL RAVATHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10627", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL RANGAPPANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10628", district:"9", Block:"277", label:"ANNAI TERESA MATRIC HIGHER SECONDARY SCHOOL  RANGAPPANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10629", district:"9", Block:"277", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PUDHUPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10630", district:"9", Block:"277", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MOONGILTHURAIPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10631", district:"9", Block:"277", label:"THE KCSM MATRIC HIGHER SECONDARY SCHOOL MOONKILTHURAIPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10632", district:"9", Block:"277", label:"ST. DONBOSCO MATRIC HIGHER SECONDARY SCHOOL  MOONGILTHURAIPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10633", district:"9", Block:"277", label:"ST.MARY'S MATRICULATION SCHOOL, PORUVALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10634", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL PORASAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10635", district:"9", Block:"277", label:"ROMAN CATHALIC MIDDLE SCHOOL ARULAMPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10636", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL (URUDHU)VADAPONPARAPPI", Sch_type:"Government", Category:"Middle School"},
    { key:"10637", district:"9", Block:"277", label:"SARVODAYA MATRIC HIGHER SECONDARY SCHOOL, VADAPONPARAPPI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10638", district:"9", Block:"277", label:"AIDED MIDDLE SCHOOL VADAKEERANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10639", district:"9", Block:"277", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  BRAMMAKUNDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10640", district:"9", Block:"277", label:"ST.MICHAEL'S HIGH SCHOOL MICHAELPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10641", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL MANALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10642", district:"9", Block:"277", label:"PANCHAYAT UNION MIDDLE SCHOOL POUNJIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10643", district:"9", Block:"277", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SELLAMPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10644", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL PERUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10645", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL CHINNAPALAPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10646", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL MATTAPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10647", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL POTTIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10648", district:"9", Block:"277", label:"GOOD SHEPHERD HIGHER SECONDARY SCHOOL SERAPATTU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10649", district:"9", Block:"277", label:"GOVERNMENT HIGH  SCHOOL  PUDHUPALAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"10650", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL PARANGINATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10651", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL JAMBADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10652", district:"9", Block:"269", label:"GOVERNMENT HIGH SCHOOL SEERPADANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"10653", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL ARKAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10654", district:"9", Block:"269", label:"ST VINNARASI HIGH SCHOOL IRUDAYAMPATTU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10655", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL ANTHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10656", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL PAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10657", district:"9", Block:"269", label:"GOVERNMENT HIGH SCHOOL THOZHUVANTHANGAL", Sch_type:"Government", Category:"High School"},
    { key:"10658", district:"9", Block:"269", label:"UNAIDED ROMAN CATHOLIC MIDDLE SCHOOL KANANKADU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10659", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL VADAMAMANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10660", district:"9", Block:"269", label:"GOVERNMENT HIGH SCHOOL PERIYAKOLLIYUR", Sch_type:"Government", Category:"High School"},
    { key:"10661", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL SEERPANANTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10662", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL ELAYANARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10663", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL PERIYAMANIYANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10664", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL KALLIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10665", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL SIRUPANAIYUR THAKKA", Sch_type:"Government", Category:"Middle School"},
    { key:"10666", district:"9", Block:"269", label:"GOVERNMENT HIGH SCHOOL KADAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"10667", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL MARUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10668", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL EDUTHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10669", district:"9", Block:"269", label:"GOVERNMENT HIGHER SECONDARY SCHOOL EDUTHANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10670", district:"9", Block:"269", label:"RAMANA MATRICULATION SCHOOL VANAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10671", district:"9", Block:"269", label:"SAINT DONBOSCO  HIGHER SECONDARY SCHOOL  VANABURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10672", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL ATHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10673", district:"9", Block:"269", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KADUVANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10674", district:"9", Block:"269", label:"KULANDAI YESU HIGH SCHOOL  MAIYANUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10675", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL ARIYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10676", district:"9", Block:"269", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ARIYALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10677", district:"9", Block:"269", label:"KASTHURIBA GANDHI BALIGA VIDYALAYA RESIDENTIAL SCHOOL VANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10678", district:"9", Block:"269", label:"ADHI DIRAVIDAR WELFARE MIDDLE SCHOOL ENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10679", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL AVIRIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10680", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL LA.GUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10681", district:"9", Block:"269", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KEELPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10682", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL NOOROLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10683", district:"9", Block:"269", label:"ROMAN CATHOLIC MIDDLE SCHOOL MELAPAZHANGUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10684", district:"9", Block:"269", label:"GOVERNMENT MODEL SCHOOL SITHAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10685", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL PAVANDUR HINDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10686", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL PASAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10687", district:"9", Block:"269", label:"ADHI DIRAVIDAR WELFARE MIDDLE SCHOOL PASAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10688", district:"9", Block:"269", label:"GOVERNMENT HIGHER SECONDARY SCHOOL RISHIVANDIYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10689", district:"9", Block:"269", label:"GOVERNMENT GIRLS  HIGH SCHOOL RISHIVANDIYAM", Sch_type:"Government", Category:"High School"},
    { key:"10690", district:"9", Block:"269", label:"KASTHURIBA GANDHI BALIGA VIDYALAYA RESIDENTIAL SCHOOL RISHIVANDIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10691", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL PRIVIDAYAMPAT", Sch_type:"Government", Category:"Middle School"},
    { key:"10692", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL MANDAGAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10693", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL VELANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10694", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL PALLIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10695", district:"9", Block:"269", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SITHERIPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10696", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL SOOLANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"10697", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL PAZHAYASIRUVANGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10698", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL THONDANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10699", district:"9", Block:"95", label:"VANMATHI MATRICULATION HIGH SCHOOL EDUTHAVAINATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10700", district:"9", Block:"95", label:"GOVERNMENT HIGHER SECONDARY SCHOOL EDUTHAVAINATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10701", district:"9", Block:"95", label:"MASS MATRICULATION  SCHOOL EDUTHAVAINATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10702", district:"9", Block:"95", label:"KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL PACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"10703", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL MANMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10704", district:"9", Block:"95", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KARADICHITHOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10705", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL THAVADIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10706", district:"9", Block:"95", label:"THIRU MURUGAN AIDED MIDDLE SCHOOL MADHAVACHERY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10707", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL MADAVACHERRY", Sch_type:"Government", Category:"High School"},
    { key:"10708", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL PALRAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10709", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL MATHUR", Sch_type:"Government", Category:"High School"},
    { key:"10710", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL EMAPPAIR", Sch_type:"Government", Category:"High School"},
    { key:"10711", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL EAST KALLAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"10712", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL KALLAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"10713", district:"9", Block:"95", label:"ROMAN CATHOLIC MIDDLE SCHOOL KALLAKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10714", district:"9", Block:"95", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KALLAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10715", district:"9", Block:"95", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KALLAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10716", district:"9", Block:"95", label:"BHARATHI MATRIC HIGHER SECONDARY SCHOOL, KALLAKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10717", district:"9", Block:"95", label:"MOUNT CARMAL MATRICULATION HIGHER SECONDARY SCHOOL KALLAKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10718", district:"9", Block:"95", label:"ST.MARYS MATRICULATION SCHOOL KALLAKURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10719", district:"9", Block:"95", label:"VIDHYALAKSHMI HIGH SCHOOL KALLAKURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10720", district:"9", Block:"95", label:"ADI DRAVIDAR  WELFARE MIDDLE SCHOOL SIRUVANGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10721", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL SALAI MAMANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10722", district:"9", Block:"95", label:"ARASAKUMARI HiGH SCHOOL SIRUVANGUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10723", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL PERUVANGUR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"10724", district:"9", Block:"95", label:"AKT  ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL NEELAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10725", district:"9", Block:"95", label:"SRIRAM AIDED MIDDLE SCHOOL NEELAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10726", district:"9", Block:"95", label:"AKT HIGH SCHOOL NEELAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10727", district:"9", Block:"95", label:"A K T MEMORIAL VIDYA SAAKET  SCHOOL, NEELAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10728", district:"9", Block:"95", label:"ADI DRAVIDAR  WELFARE MIDDLE SCHOOL THENKEERANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10729", district:"9", Block:"95", label:"MAHABARATHI INTERNATIONAL SCHOOL THENKEERANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10730", district:"9", Block:"95", label:"ADI DRAVIDAR  WELFARE HIGH SCHOOL NIRAIMATHI", Sch_type:"Government", Category:"High School"},
    { key:"10731", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL VEERACHOLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10732", district:"9", Block:"95", label:"KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL PERIYASIRUVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10733", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL MALAIKOTTALAM", Sch_type:"Government", Category:"High School"},
    { key:"10734", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL VANAVAREDDY", Sch_type:"Government", Category:"High School"},
    { key:"10735", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL KATTANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10736", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL VILAMBAR", Sch_type:"Government", Category:"High School"},
    { key:"10737", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL THACHUR KATTU KOTTAGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10738", district:"9", Block:"95", label:"OXALISS INTERNATIONAL SCHOOL THACHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10739", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL PORPADAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"10740", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL ERAVAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10741", district:"9", Block:"95", label:"Dr. RKS MASTERS MATRIC HR SECONDARY SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10742", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL THANDALAI PERUVANGUR", Sch_type:"Government", Category:"High School"},
    { key:"10743", district:"9", Block:"95", label:"ROMAN CATHOLIC MIDDLE SCHOOL AGARKOTTALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10744", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10745", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL  A. PUTHANTHDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10746", district:"9", Block:"95", label:"KANCHANADEVI MATRICULATION HIGHER SECONDARY SCHOOL ALATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10747", district:"9", Block:"95", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PERIYASIRUVATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10748", district:"9", Block:"95", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL PERIYASIRUVATUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10749", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL K.ALAMBALAM", Sch_type:"Government", Category:"High School"},
    { key:"10750", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL ARIYAPERUMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10751", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL RANGANATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"10752", district:"9", Block:"95", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SOMANDARGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10753", district:"9", Block:"95", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PERUMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10754", district:"9", Block:"95", label:"KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL  PUKKIRAVARI", Sch_type:"Government", Category:"Middle School"},
    { key:"10755", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL PUKKIRAVARI", Sch_type:"Government", Category:"High School"},
    { key:"10756", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL SIRUMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10757", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL MANIYARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10758", district:"9", Block:"95", label:"KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL THARISIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"10759", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY  SCHOOL INNADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10760", district:"9", Block:"95", label:"GOOD SHEPHERD HIGH SCHOOL  MELNILAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10761", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL NOCHIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10762", district:"9", Block:"95", label:"MURUGU AIDED MIDDLE SCHOOL MALLIYAMPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10763", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL MELVAZHAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10764", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL KOTTAPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10765", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL ARAMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"10766", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL VARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10767", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL EZHUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10768", district:"9", Block:"95", label:"GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL MELPACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"10769", district:"9", Block:"95", label:"GOVERNMENT EKLAVYA MODEL RESIDENTIAL BOYS HIGHER SECONDARY SCHOOL , VELLIMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10770", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL KADATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10771", district:"9", Block:"50", label:"GOVERNMENT HIGH SCHOOL THENGIANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"10772", district:"9", Block:"50", label:"GOVERNMENT HIGH SCHOOL PAITHANTHURAI", Sch_type:"Government", Category:"High School"},
    { key:"10773", district:"9", Block:"50", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THOTTIYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10774", district:"9", Block:"50", label:"AIDED HIGHER SECONDARY SCHOOL MELNARIYAPPANUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10775", district:"9", Block:"50", label:"GOVERNMENT HIGH SCHOOL KALLANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"10776", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL PANDIANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10777", district:"9", Block:"50", label:"GOVERNMENT HIGH SCHOOL ELAVADI", Sch_type:"Government", Category:"High School"},
    { key:"10778", district:"9", Block:"50", label:"GOVERNMENT HIGH SCHOOL AMMAYAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"10779", district:"9", Block:"50", label:"KURINJIE HIGHER SECONDARY SCHOOL. AMMAIYAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10780", district:"9", Block:"50", label:"GOVERNMENT HIGHER SECONDARY SCHOOL NAINARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10781", district:"9", Block:"50", label:"ST.PAUL HIGH SCHOOL NAINARPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10782", district:"9", Block:"50", label:"GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL CHINNASALEM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10783", district:"9", Block:"50", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL CHINNASALEM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10784", district:"9", Block:"50", label:"BHARATHI BALAMANDIR MATRIC HIGHER SECONDARY SCHOOL, CHINNASALEM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10785", district:"9", Block:"50", label:"AIDED MIDDLE SCHOOL CHINNASALEM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10786", district:"9", Block:"50", label:"AIDED HIGHER SECONDARY SCHOOL CHINNASALEM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10787", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL KEELKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10788", district:"9", Block:"50", label:"GOVERNMENT HIGH SCHOOL  KARUNTHALAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"10789", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL V ALAMBALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10790", district:"9", Block:"50", label:"GOVERNMENT  HIGHER SECONDARY SCHOOL THAGAMTHERTHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10791", district:"9", Block:"50", label:"WELLDONE MATRIC HR.SEC SCHOOL,THAGAMTHEERTHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10792", district:"9", Block:"50", label:"GOVERNMENT HIGH SCHOOL  PAKKAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"10793", district:"9", Block:"50", label:"ST.ANN'S HIGH SCHOOL  THENSIRUVALLUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10794", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL THOTTAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10795", district:"9", Block:"50", label:"GOVERNMENT  HIGHER SECONDARY SCHOOL GUGAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10796", district:"9", Block:"50", label:"SAKTHI MATRIC HIGHER SECONDARY SCHOOL KANIYAMOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10797", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL PETHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10798", district:"9", Block:"50", label:"ST. JOSEPH'S HIGHER SECONDARY SCHOOL ULAGANKATHAN", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10799", district:"9", Block:"50", label:"BHARATHI HIGH SCHOOL BANGARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10800", district:"9", Block:"50", label:"ADI-DRAVIDA WELFARE MIDDLE SCHOOL  NAMASIVAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10801", district:"9", Block:"50", label:"SRI VIVEKANANDA HIGHER SECONDARY SCHOOL  EARVAIPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10802", district:"9", Block:"50", label:"GOVERNMENT  HIGHER SECONDARY SCHOOL KUTHIRAICHANDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10803", district:"9", Block:"50", label:"ST. MARY'S HIGH SCHOOL KARANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10804", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL  MATTIKAIKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"10805", district:"9", Block:"50", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  NAGAKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10806", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL VENKATAMMAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10807", district:"9", Block:"50", label:"GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL VADAKKANANDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10808", district:"9", Block:"50", label:"BHARATHI MATRIC HIGHER SECONDARY SCHOOL VADAKKANANDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10809", district:"9", Block:"50", label:"GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL GOMUGI DAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10810", district:"9", Block:"50", label:"VANMATHI MATRIC HIGHER SECONDARY SCHOOL  VADAKANNANTHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10811", district:"9", Block:"50", label:"PANCHAYAT UNION MIDDLE SCHOOL KACHIRAYAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10812", district:"9", Block:"50", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  KACHIRAYAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10813", district:"9", Block:"50", label:"AIDED MIDDLE SCHOOL METTUPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10814", district:"9", Block:"50", label:"DANISH MISSION HIGHER SECONDARY SCHOOL KARIYALUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10815", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL  PRITHIVIMANGALAM (HINDU)", Sch_type:"Government", Category:"Middle School"},
    { key:"10816", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL  PRITHIVIMANGALAM (COLONY)", Sch_type:"Government", Category:"Middle School"},
    { key:"10817", district:"9", Block:"347", label:"MOUNT PARK HIGHER SECONDARY SCHOOL,  THIYAGADURGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10818", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL THIYAGAI", Sch_type:"Government", Category:"High School"},
    { key:"10819", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL VILAKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10820", district:"9", Block:"347", label:"ADI DRAVIDAR WELFARE HIGH SCHOOL KUDIYANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"10821", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL VELAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"10822", district:"9", Block:"347", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KUTHAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10823", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL EYYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10824", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL GURUPEEDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10825", district:"9", Block:"347", label:"GOVERNMENT MIDDLE SCHOOL ERANJI ", Sch_type:"Government", Category:"Middle School"},
    { key:"10826", district:"9", Block:"347", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ASAKALATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10827", district:"9", Block:"347", label:"GOVERNMENT HIGHER SECONDARY SCHOOL S.OHAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10828", district:"9", Block:"347", label:"GOVERNMENT HIGHER SECONDARY SCHOOL NAGALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10829", district:"9", Block:"347", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VIRUGAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10830", district:"9", Block:"347", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL VIRUGAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10831", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL PUDU UCHIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10832", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL KONGARAYAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"10833", district:"9", Block:"347", label:"KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL MUDIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10834", district:"9", Block:"347", label:"PANCHAYAT UNION MIDDLE SCHOOL KANANGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10835", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL PEELAMEDU", Sch_type:"Government", Category:"High School"},
    { key:"10836", district:"9", Block:"347", label:"KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL PALLAGACHERRY", Sch_type:"Government", Category:"Middle School"},
    { key:"10837", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL PALLAGACHERRY", Sch_type:"Government", Category:"High School"},
    { key:"10838", district:"9", Block:"347", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL THIYAGADURGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10839", district:"9", Block:"347", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL THIYAGADURGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10840", district:"9", Block:"347", label:"VASAVI MATRICULATION SCHOOL THIYAGADURGAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"10841", district:"9", Block:"347", label:"MOUNT PARK SPECIAL ACADEMY  MATRIC HIGHER SECONDARY SCHOOL PERIYAMAMPATTU , THIYAGADURGAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10842", district:"9", Block:"372", label:" PANCHAYAT UNION MIDDLE SCHOOL PERIYAKURUKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10843", district:"9", Block:"372", label:"OM GURU GANESA MATRICULATION SCHOOL KATTUEDAIYAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10844", district:"9", Block:"372", label:"GOVERNMENT HIGH SCHOOL KATTUEDAIYAR", Sch_type:"Government", Category:"High School"},
    { key:"10845", district:"9", Block:"372", label:"ST.JOSEPH HSS, ERAIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10846", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL T PALLAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10847", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL  KOOVADU", Sch_type:"Government", Category:"Middle School"},
    { key:"10848", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL NATHAMUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10849", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL  KALSIRUNAGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10850", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL KUNJARAM (H)", Sch_type:"Government", Category:"Middle School"},
    { key:"10851", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL KATTUSELLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10852", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL KOOTHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10853", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL ELLAIGIRAMAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10854", district:"9", Block:"372", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ELAVANASUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10855", district:"9", Block:"372", label:"SRI VINAYAGA MATRICULATION HIGHER SECONDARY SCHOOL  ELAVANASUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10856", district:"9", Block:"372", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  ELAVANASUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10857", district:"9", Block:"372", label:"BHARATH MATRICULATION  SCHOOL ELAVANASUR KOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10858", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL VADAKURUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10859", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL ANGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10860", district:"9", Block:"372", label:"GOVERNMENT MIDDLE SCHOOL PARINTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10861", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL T.THAKKA", Sch_type:"Government", Category:"Middle School"},
    { key:"10862", district:"9", Block:"372", label:"GOVERNMENT HIGH SCHOOL NEDUMANUR", Sch_type:"Government", Category:"High School"},
    { key:"10863", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL EMAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10864", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL  SIRUPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10865", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL  PUGAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"10866", district:"9", Block:"372", label:"GOVERNMENT HIGHER SECONDARY SCHOOL M.KUNNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10867", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL THAMAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10868", district:"9", Block:"372", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KILIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10869", district:"9", Block:"372", label:" SRI RAMAKRISHNA VIDYALAYA GURUKULAM MATRIC HIGHER SECONDARY SCHOOL EDAIKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10870", district:"9", Block:"372", label:"KASTHURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL A. KUMARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10871", district:"9", Block:"372", label:"KASTHURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL KOOTHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10872", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL PALI PUTHUCOLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"10873", district:"9", Block:"372", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL  PALI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10874", district:"9", Block:"372", label:"BUTTERFLY MATRICULATION SCHOOL SHEIK HUSSAINPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"10875", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL KANAIYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10876", district:"9", Block:"372", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ASANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10877", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL PU.KONALAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"10878", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL PU.KILLANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10879", district:"9", Block:"372", label:"PARKAVAN MATRICULATION HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"10880", district:"9", Block:"372", label:"RAVLATHUL JANNAH ORIENTAL HIGH SCHOOL  ULUNDURPET", Sch_type:"Fully Aided", Category:"High School"},
    { key:"10881", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL  R.R.KUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10882", district:"9", Block:"372", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VELLAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10883", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL ULUNDURPET(HINDU)", Sch_type:"Government", Category:"Middle School"},
    { key:"10884", district:"9", Block:"372", label:"PANCHAYAT UNION MIDDLE SCHOOL ULUNDANDAR KOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"10885", district:"9", Block:"372", label:"BESCHI HIGHER SECONDARY SCHOOL  ULUNDURPET", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"10886", district:"9", Block:"372", label:"GOVERNMENT GIRLS MODEL HIGHER SECONDARY SCHOOL ULUNDURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10887", district:"9", Block:"372", label:"GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL  ULUNDURPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10888", district:"9", Block:"372", label:"SRI SARADA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL  ULUNDURPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10889", district:"9", Block:"372", label:"BEULAH  MATRICULATION SCHOOL ULUNDURPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"10890", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL KAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10891", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10892", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL KILAPPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10893", district:"9", Block:"327", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KALAMARUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10894", district:"9", Block:"327", label:"ARUNA HIGHER SECONDARY SCHOOL KORATAN KURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10895", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL PILLAIYARKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10896", district:"9", Block:"327", label:"GOVERNMENT HIGH SCHOOL VELUR", Sch_type:"Government", Category:"High School"},
    { key:"10897", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL THIRUNARANKUNDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10898", district:"9", Block:"327", label:"AIDED MIDDLE SCHOOL MADAPATTU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10899", district:"37", Block:"342", label:"PANCHAYAT UNION MIDDLE SCHOOL KARUVEPPILAIAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10900", district:"9", Block:"327", label:"GOVERNMENT HIGH SCHOOL IRUNDAI", Sch_type:"Government", Category:"High School"},
    { key:"10901", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL KORATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10902", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL KOOVAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10903", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL PERUMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"10904", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL T.ORATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10905", district:"9", Block:"327", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PADUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10906", district:"9", Block:"327", label:"VEDANTHA VID MATRICULATION SCHOOL PADUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"10907", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL PERIYAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10908", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL THIMMIREDDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10909", district:"9", Block:"327", label:"DON BOSCO HIGHER SECONDARY SCHOOL GEDILAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10910", district:"9", Block:"327", label:"S S V HIGHER SECONDARY SCHOOL SENTHAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10911", district:"9", Block:"327", label:"DANISH MISSION HIGH SCHOOL PARIKKAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10912", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL SEMMANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"10913", district:"9", Block:"327", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THIRUNAVALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10914", district:"9", Block:"327", label:"KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL THIRUNAVALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10915", district:"9", Block:"327", label:"ISHA VIDYA MATRICULATION HIGHER SECONDARY SCHOOL TIRUNAVALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10916", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL ESWARAKANDANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10917", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL SOMASIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10918", district:"9", Block:"327", label:"AIDED MIDDLE SCHOOL EAST MARUDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10919", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL ARINATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10920", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL SENGURICHI COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"10921", district:"9", Block:"327", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SENGURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10922", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL NAINAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10923", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL PU.MAMPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10924", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL ELUPPAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10925", district:"9", Block:"327", label:"PANCHAYAT UNION MIDDLE SCHOOL KALLAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"10926", district:"9", Block:"327", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SENDANADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10927", district:"9", Block:"327", label:"KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL , SENTHANADU", Sch_type:"Government", Category:"Middle School"},
    { key:"10928", district:"37", Block:"404", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KEELPERUMPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10929", district:"37", Block:"404", label:"GOVERNMENT NEHRUJI MIDDLE SCHOOL VILLUPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10930", district:"37", Block:"404", label:"MUNICIPAL HIGHER SECONDARY SCHOOL B.N.THOPE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10931", district:"37", Block:"404", label:"SACRED HEART CENTRAL SCHOOL  VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10932", district:"37", Block:"404", label:"MRIC RC HIGH SCHOOL VILLUPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"10933", district:"37", Block:"404", label:"NEW JOHN DEWEY MATRICULATION SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10934", district:"37", Block:"404", label:"MAHATHMA GANDHI HIGH SCHOOL VILLUPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10935", district:"37", Block:"404", label:"TAGORE MATRICULATION SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10936", district:"37", Block:"404", label:"MUNICIPAL HIGH SCHOOL POONTHOTTAM", Sch_type:"Government", Category:"High School"},
    { key:"10937", district:"37", Block:"404", label:"E.S.MATRICULATION HIGHER SECONDARY SCHOOL, VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10938", district:"37", Block:"404", label:"SARADA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10939", district:"37", Block:"404", label:"MUNICIPAL HIGH SCHOOL HOSPITAL ROAD", Sch_type:"Government", Category:"High School"},
    { key:"10940", district:"37", Block:"404", label:"ST JOHN SPECIAL HIGHER SECONDARY SCHOOL FOR DIFFERENTLY ABLED  VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10941", district:"37", Block:"404", label:"ST.XAVIER MIDDLE SCHOOL VILLUPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10942", district:"37", Block:"404", label:"ST XAVIER MATRICULATION SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10943", district:"37", Block:"404", label:"SRI KAMAKOTI ORIENTAL HIGH SCHOOL VILLUPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"10944", district:"37", Block:"404", label:"SRI VENKATESWARA MATRICULATION SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10945", district:"37", Block:"404", label:"RAMAKRISHNA MIDDLE SCHOOL VILLUPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10946", district:"37", Block:"404", label:"VPS MATRICULATION SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"10947", district:"37", Block:"404", label:"HAJEE MANSOORSHA ORIENTAL ARABIC HIGH SCHOOL VILLUPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"10948", district:"37", Block:"404", label:"TELC MIDDLE SCHOOL COURT ROAD VILLUPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10949", district:"37", Block:"404", label:"THIRU KAMARAJ MUNICIPAL HIGHER SECONDARY SCHOOL  VILLUPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10950", district:"37", Block:"404", label:"GOVERNMENT MODEL GIRLS HIGHER SECONDARY SCHOOL,VILLUPURAM -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10951", district:"37", Block:"404", label:"NARAYANS MATRICULATION HIGHER SECONDARY SCHOOL  VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10952", district:"22", Block:"68", label:"PUMS, MOOKAGOUNDANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10953", district:"22", Block:"68", label:"GHS ULIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"10954", district:"22", Block:"68", label:"PUMS, NAGIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"10955", district:"22", Block:"68", label:"PUMS, THANEERTHOTTI", Sch_type:"Government", Category:"Middle School"},
    { key:"10956", district:"22", Block:"68", label:"VIDYO AMIRTHAS PUBLIC SCHOOL, NAGIYAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10957", district:"22", Block:"68", label:"PUMS, MOOLAPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10958", district:"22", Block:"68", label:"GHSS KONDAIYAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10959", district:"22", Block:"68", label:"GBHS SENTHARAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"10960", district:"22", Block:"68", label:"GGHSS SENTHARAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10961", district:"22", Block:"68", label:"ST.MARY'S MIDDLE SCHOOL, KONERIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"10962", district:"22", Block:"68", label:"PUMS, THAMMAMPATTI 12th WARD", Sch_type:"Government", Category:"Middle School"},
    { key:"10963", district:"22", Block:"68", label:"PUMS, GANDHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"10964", district:"22", Block:"68", label:"GBHSS THAMAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10965", district:"22", Block:"68", label:"GGHSS THAMAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10966", district:"22", Block:"68", label:"PUMS, VALAKKOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"10967", district:"22", Block:"68", label:"GOVT HSS GUDAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10968", district:"22", Block:"68", label:"SREE VIDHYA VIKAS MATRIC HR. SEC. SCHOOL, GUDAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10969", district:"22", Block:"68", label:"NEW INDIA MATRIC SCHOOL, GUDAMALI", Sch_type:"Un-aided", Category:"High School"},
    { key:"10970", district:"22", Block:"68", label:"PUMS, 74 KRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10971", district:"22", Block:"68", label:"GTR HS ODAIKATTUPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"10972", district:"22", Block:"68", label:"GHS KADAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"10973", district:"22", Block:"68", label:"PUMS, GANGAVALLI 10th WARD", Sch_type:"Government", Category:"Middle School"},
    { key:"10974", district:"22", Block:"68", label:"GBHSS GANGAVALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10975", district:"22", Block:"68", label:"GGHSS GANGAVALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10976", district:"22", Block:"68", label:"SRI SARADHA MANDIR MATRIC HR. SEC. SCHOOL, GANGAVALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"10977", district:"22", Block:"68", label:"PUMS, ODHIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10978", district:"22", Block:"68", label:"PUMS, GANGAVALLI MAIN", Sch_type:"Government", Category:"Middle School"},
    { key:"10979", district:"22", Block:"68", label:"GHSS THEDAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10980", district:"22", Block:"246", label:"GHS NEIYAMALAI", Sch_type:"Government", Category:"High School"},
    { key:"10981", district:"22", Block:"246", label:"GHSS THUMBAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10982", district:"22", Block:"246", label:"ST. MICHEAL'S MATRIC SCHOOL,THUMBAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"10983", district:"22", Block:"246", label:"PUMS-MAMANJI", Sch_type:"Government", Category:"Middle School"},
    { key:"10984", district:"22", Block:"246", label:"PUMS-ELUPULI", Sch_type:"Government", Category:"Middle School"},
    { key:"10985", district:"22", Block:"246", label:"PUMS-PAPPANAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"10986", district:"22", Block:"246", label:"PUMS-EDAYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"10987", district:"22", Block:"246", label:"GHSS PANAIMADAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10988", district:"22", Block:"246", label:"GHS THANDANUR", Sch_type:"Government", Category:"High School"},
    { key:"10989", district:"22", Block:"246", label:"PUMS-SEKKADIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"10990", district:"22", Block:"246", label:"PUMS-A.KOMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"10991", district:"22", Block:"246", label:"GHS KOTTAVADI", Sch_type:"Government", Category:"High School"},
    { key:"10992", district:"22", Block:"246", label:"PUMS-KALLERIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"10993", district:"22", Block:"246", label:"PUMS-YETHAPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10994", district:"22", Block:"246", label:"GGHS YETHAPUR", Sch_type:"Government", Category:"High School"},
    { key:"10995", district:"22", Block:"246", label:"GHSS YETHAPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10996", district:"22", Block:"246", label:"EKALAIVA MODEL HR. SEC. SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"10997", district:"22", Block:"246", label:"PUMS-VAITHIYAGOUNDANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"10998", district:"22", Block:"246", label:"GHS PERIYAKRISHNAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"10999", district:"22", Block:"246", label:"K.G.B.V. PERIYAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11000", district:"22", Block:"246", label:"PUMS-CHINNAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11001", district:"22", Block:"246", label:"GHS THAMAYANOOR", Sch_type:"Government", Category:"High School"},
    { key:"11002", district:"22", Block:"246", label:"GHS ARIYAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11003", district:"22", Block:"246", label:"GBHSS PETHANAICKENPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11004", district:"22", Block:"246", label:"PUMS-PALANDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11005", district:"22", Block:"246", label:"GGHSS PETHANAICKENPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11006", district:"22", Block:"246", label:"PUMS-OLAPPADY", Sch_type:"Government", Category:"Middle School"},
    { key:"11007", district:"22", Block:"246", label:"PUMS-THALAVAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11008", district:"22", Block:"246", label:"PUMS-PALANIYAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"11009", district:"22", Block:"246", label:"GHS GOPALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11010", district:"22", Block:"246", label:"GTRMS THALVALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11011", district:"22", Block:"246", label:"GTRMS-PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11012", district:"22", Block:"246", label:"ASISI MIDDLE SCHOOL, KARUMANDURAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"11013", district:"22", Block:"246", label:"GTR HR. SEC. SCHOOL, KARUMANDHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11014", district:"22", Block:"246", label:"GTR HIGH SCHOOL, THEKKAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"11015", district:"22", Block:"246", label:"GTRHSS, KARIYAKOILVALAVU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11016", district:"22", Block:"246", label:"GTRMS-SOOLANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"11017", district:"22", Block:"90", label:"PUMS, ANNA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"11018", district:"22", Block:"90", label:"PUMS, VEERIYANTHANDA", Sch_type:"Government", Category:"Middle School"},
    { key:"11019", district:"22", Block:"90", label:"PUMS, V KONGARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11020", district:"22", Block:"90", label:"ST. ANGELA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11021", district:"22", Block:"90", label:"PUMS, VEERATCHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11022", district:"22", Block:"90", label:"PUMS, K N PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11023", district:"22", Block:"90", label:"GHSS,K.MORUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11024", district:"22", Block:"90", label:"GHSS, RAMAMOORTHI NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11025", district:"22", Block:"90", label:"PUMS, KANNAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11026", district:"22", Block:"90", label:"K.G.B.V KADAYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11027", district:"22", Block:"90", label:"PUMS, DANISHPET", Sch_type:"Government", Category:"Middle School"},
    { key:"11028", district:"22", Block:"90", label:"GHSS,PERIYA VADAGAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11029", district:"22", Block:"90", label:"BETHEL MATRICULATION HR.SEC SCHOOL, DANISHPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11030", district:"22", Block:"90", label:"PUMS, UMBILICKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11031", district:"22", Block:"90", label:"GHS,BOMMIYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11032", district:"22", Block:"90", label:"PUMS, SUNDAKAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11033", district:"22", Block:"90", label:"GHSS,NADUPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11034", district:"22", Block:"90", label:"A.M. PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11035", district:"22", Block:"90", label:"PUMS, KADAYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11036", district:"22", Block:"90", label:"SWAMY VIVEKANANDHA VIDHYALAYA MATRIC HR. SEC. SCHOOL,KADAYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11037", district:"22", Block:"90", label:"PUMS, ELATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11038", district:"22", Block:"90", label:"PUMS, THALAVAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11039", district:"22", Block:"90", label:"PUMS, GURUVAREDDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11040", district:"22", Block:"90", label:"GHSS,GUNDUKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11041", district:"22", Block:"90", label:"PUMS, NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11042", district:"22", Block:"90", label:"GHS,KONGUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11043", district:"22", Block:"90", label:"PUMS, NALLUR MANIYAKARANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"11044", district:"22", Block:"90", label:"PUMS, PUDURKARUVALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"11045", district:"22", Block:"90", label:"PUMS, KARUVALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"11046", district:"22", Block:"90", label:"PUMS, MARAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11047", district:"22", Block:"90", label:"GHS, MOOKANUR", Sch_type:"Government", Category:"High School"},
    { key:"11048", district:"22", Block:"90", label:"GHS, DEEVATTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11049", district:"22", Block:"90", label:"GANGAKAVERI HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11050", district:"22", Block:"90", label:"GANGAKAVERI MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11051", district:"22", Block:"90", label:"PUMS, POOSARIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11052", district:"22", Block:"90", label:"MOUNT SHERVAROYS MATRICULATION SCHOOL, POOSARIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"11053", district:"22", Block:"90", label:"PUMS, DASASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11054", district:"22", Block:"90", label:"MODEL SCHOOL ,KADAYAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11055", district:"22", Block:"90", label:"GHSS, PANNAPPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11056", district:"22", Block:"90", label:"PUMS, THINNAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11057", district:"22", Block:"90", label:"PUMS, MATTUKARANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11058", district:"22", Block:"90", label:"GHSS,DHARAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11059", district:"22", Block:"90", label:"GGHS,DHARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11060", district:"22", Block:"90", label:"PUMS, KOTTAMEDU NEW SCHOOL", Sch_type:"Government", Category:"Middle School"},
    { key:"11061", district:"22", Block:"90", label:"PUMS, PAPPICHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11062", district:"22", Block:"90", label:"GHSS,KANJANAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11063", district:"22", Block:"90", label:"PUMS, SEMMANDAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11064", district:"22", Block:"90", label:"GHSS,PERIYAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11065", district:"22", Block:"163", label:"PUMS VELLAIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11066", district:"22", Block:"163", label:"GHSS, VAIKUNTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11067", district:"22", Block:"163", label:"PUMS, UNJAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11068", district:"22", Block:"163", label:"GHSS ALAGAPPAMPALAYAM PUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11069", district:"22", Block:"163", label:"KURINJI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11070", district:"22", Block:"163", label:"PUMS EDANGANASALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11071", district:"22", Block:"163", label:"PUMS, E.METTUKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11072", district:"22", Block:"163", label:"PUMS, RASIGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11073", district:"22", Block:"163", label:"PUMS, KADAYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11074", district:"22", Block:"163", label:"PUMS, NALLANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11075", district:"22", Block:"163", label:"G.H.S.S., K.K.NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11076", district:"22", Block:"163", label:" JOTHI VIDHYALAYA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11077", district:"22", Block:"163", label:"GHS, VALAYACHETTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11078", district:"22", Block:"163", label:"PUMS THAPPAKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11079", district:"22", Block:"163", label:"PUMS METTUTHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"11080", district:"22", Block:"163", label:"G.B.H.S.S  MAGUDANCHAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11081", district:"22", Block:"163", label:"G.G.H.S.S. MAGUDANCHAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11082", district:"22", Block:"163", label:"K.G.B.V. MAGUDANCHAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11083", district:"22", Block:"163", label:"LOTUS MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11084", district:"22", Block:"163", label:"PUMS, GUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11085", district:"22", Block:"163", label:"PUMS, KANDARKULAMANICKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11086", district:"22", Block:"133", label:"PUMS MUTHIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11087", district:"22", Block:"133", label:"PUMS PUDHUPPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11088", district:"22", Block:"133", label:"PUMS ARISIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11089", district:"22", Block:"133", label:"PUMS MANICKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11090", district:"22", Block:"133", label:"GHSS SAMUDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11091", district:"22", Block:"133", label:"PUMS V.METTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11092", district:"22", Block:"133", label:"PUMS PACHALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11093", district:"22", Block:"133", label:"PUMS MUNIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11094", district:"22", Block:"133", label:"GHSS V.METTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11095", district:"22", Block:"133", label:"MODEL SCHOOL,KONGANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11096", district:"22", Block:"133", label:"GHS VELLALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11097", district:"22", Block:"133", label:"GHS KANNIYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11098", district:"22", Block:"133", label:"PUMS DEVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11099", district:"22", Block:"133", label:"PUMS RAYANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11100", district:"22", Block:"133", label:"PUMS KOMBAIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11101", district:"22", Block:"133", label:"GHS KORANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11102", district:"22", Block:"133", label:"PUMS ALAGAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11103", district:"22", Block:"133", label:"PUMS K.VADUKAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11104", district:"22", Block:"133", label:"PUMS K.PALLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11105", district:"22", Block:"133", label:"PUMS THONDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11106", district:"22", Block:"133", label:"PUMS KONANKIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11107", district:"22", Block:"133", label:"PUMS ETTIKUTTAI MEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"11108", district:"22", Block:"133", label:"GHS KATCHUPALLI", Sch_type:"Government", Category:"High School"},
    { key:"11109", district:"22", Block:"133", label:"PUMS KONGANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11110", district:"22", Block:"133", label:"PUMS RAKKIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11111", district:"22", Block:"133", label:"GGHSS, KONGANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11112", district:"22", Block:"133", label:"GBHSS, KONGANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11113", district:"22", Block:"133", label:"K.G.B.V. KONGANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11114", district:"22", Block:"133", label:"PUMS NACHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11115", district:"22", Block:"133", label:"PUMS VENDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11116", district:"22", Block:"133", label:"PUMS THANGAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11117", district:"22", Block:"133", label:"PUMS KONANGKUTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11118", district:"22", Block:"226", label:"PUMS,RANGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11119", district:"22", Block:"226", label:"GOVT MODEL HSS JARI KONDALAMPATTI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11120", district:"22", Block:"226", label:"GOVT BOYS HSS JARI KONDALAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11121", district:"22", Block:"226", label:"SRI VIDYA MANDIR HR. SEC. SCHOOL, KONDALAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"11122", district:"22", Block:"226", label:"PUMS,MALANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11123", district:"22", Block:"226", label:"GOVT HSS NEIKARAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11124", district:"22", Block:"226", label:"PUMS,KATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11125", district:"22", Block:"226", label:"PUMS,GANDHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11126", district:"22", Block:"226", label:"GOLDEN CHOICE MATRIC HR. SEC. SCHOOL, DASANAICKENPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11127", district:"22", Block:"226", label:"GHSS,DASANAICKENPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11128", district:"22", Block:"226", label:"K.G.B.V. SCHOOL, NILAVARAPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11129", district:"22", Block:"226", label:"PUMS,THAMMANAICKENPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11130", district:"22", Block:"226", label:"panchayat union middle school vaniyampady", Sch_type:"Government", Category:"Middle School"},
    { key:"11131", district:"22", Block:"226", label:"PANCHAYAT UNION MIDDLE SCHOOL, EARVADI VANIYAMBADI.", Sch_type:"Government", Category:"Middle School"},
    { key:"11132", district:"22", Block:"226", label:"PANCHAYAT UNION MIDDLE SCHOOL VALAKUTTAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11133", district:"22", Block:"226", label:"PUMS,PARAPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11134", district:"22", Block:"226", label:"PUMS,MECHERIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11135", district:"22", Block:"226", label:"PUMS,KOZHINCHIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11136", district:"22", Block:"226", label:"GHSS,NAZHIKKALPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11137", district:"22", Block:"226", label:"PANCHAYAT UNION MIDDLE SCHOOL SANTHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11138", district:"22", Block:"226", label:"PANCHAYAT UNION MIDDLE SCHOOL AMMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11139", district:"22", Block:"226", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11140", district:"22", Block:"226", label:"VEE GEE VIKAS PUBLIC SCHOOL,MALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11141", district:"22", Block:"226", label:"PUMS,S-ATTAYAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11142", district:"22", Block:"226", label:"GOVT HIGH SCHOOL GAJJALNAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11143", district:"22", Block:"226", label:"PUMS,JARUGUMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11144", district:"22", Block:"226", label:"VEDHHA VIKKAS MATRIC HR. SEC. SCHOOL, PANAMARATHUPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11145", district:"22", Block:"226", label:"GOVT HR SEC SCHOOL, PANAMARATHUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11146", district:"22", Block:"226", label:"CHRISTOPHER MATRIC HR. SEC. SCHOOL, PANAMARATHUPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11147", district:"22", Block:"226", label:"PANCHAYAT UNION MIDDLE SCHOOL,THIPPAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11148", district:"22", Block:"226", label:"GOVERNMENT HIGHER SECONDERY SCHOOL,KULLAPPANAICKANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11149", district:"22", Block:"226", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL, KONAMADUVU", Sch_type:"Government", Category:"High School"},
    { key:"11150", district:"22", Block:"226", label:"PANCHAYAT UNION MIDDLE SCHOOL,ADIMALAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11151", district:"22", Block:"309", label:"GHS, SADHASIVAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11152", district:"22", Block:"309", label:"PUMS, SARVOY", Sch_type:"Government", Category:"Middle School"},
    { key:"11153", district:"22", Block:"309", label:"LITTLE FLOWER MATRIC HR SEC SCHOOL, NATHAKKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11154", district:"22", Block:"309", label:"GHS, SARVOYPUDHUR", Sch_type:"Government", Category:"High School"},
    { key:"11155", district:"22", Block:"309", label:"GHS, VADA CHENNIMALAI", Sch_type:"Government", Category:"High School"},
    { key:"11156", district:"22", Block:"309", label:"GHSS, KATTUKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11157", district:"22", Block:"309", label:"PUMS, MANIVILUNDAN NORTH PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11158", district:"22", Block:"309", label:"PUMS, MUTTALPOOMARATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11159", district:"22", Block:"309", label:"PUMS, MANIVILUNDAN NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"11160", district:"22", Block:"309", label:"MARUTHI HIGHER SECONDARY SCHOOL, M.V.SOUTH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11161", district:"22", Block:"309", label:"JAYAM ACADEMY (CBSE), M.V.SOUTH", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"11162", district:"22", Block:"309", label:"JAYAM MATRIC HR. SEC. SCHOOL, SAMIYAR KINARU, ATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11163", district:"22", Block:"309", label:"PUMS, SIRUVACHUR 4TH WARD", Sch_type:"Government", Category:"Middle School"},
    { key:"11164", district:"22", Block:"309", label:"CLASSIC MATRIC SCHOOL, SIRUVACHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11165", district:"22", Block:"309", label:"GHSS, SIRUVACHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11166", district:"22", Block:"309", label:"PUMS, VEPPANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11167", district:"22", Block:"309", label:"GHSS, UNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11168", district:"22", Block:"309", label:"GHS, PUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"11169", district:"22", Block:"309", label:"GHS, NAVAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"11170", district:"22", Block:"309", label:"NEHRU MATRICULATION SCHOOL, NAVAKKURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"11171", district:"22", Block:"309", label:"PUMS, PATTUTHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11172", district:"22", Block:"309", label:"GHSS, DEVIYAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11173", district:"22", Block:"309", label:"TAGORE MATRIC HR. SEC. SCHOOL, DEVIYAKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11174", district:"22", Block:"309", label:"BHARATHIYAR GIRLS HR. SEC. SCHOOL, DEVIYAKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11175", district:"22", Block:"309", label:"PAAVENDHAR MATRIC HR. SEC. SCHOOL, M.V.SOUTH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11176", district:"22", Block:"309", label:"TAGORE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11177", district:"22", Block:"309", label:"PUMS, VADAKUMARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11178", district:"22", Block:"309", label:"GHSS, SATHAPPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11179", district:"22", Block:"309", label:"PUMS, PUNALVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"11180", district:"22", Block:"309", label:"GHS, NAVALUR", Sch_type:"Government", Category:"High School"},
    { key:"11181", district:"22", Block:"309", label:"PUMS, VEDHANAYAGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11182", district:"22", Block:"309", label:"GBHSS, THALAIVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11183", district:"22", Block:"309", label:"GGHSS, THALAIVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11184", district:"22", Block:"309", label:"PUMS, NATHAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11185", district:"22", Block:"309", label:"SRI VENKATESWARA HR. SEC. SCHOOL, NATHAKKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11186", district:"22", Block:"309", label:"PUMS, PERIYERI", Sch_type:"Government", Category:"Middle School"},
    { key:"11187", district:"22", Block:"309", label:"MUTHAMIZHL HIGHER SECONDARY SCHOOL, PERIYERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11188", district:"22", Block:"309", label:"SRI RAJA MURUGA CBSE SCHOOL, PERIYERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"11189", district:"22", Block:"309", label:"GHSS, ARAGALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11190", district:"22", Block:"309", label:"GGHS, ARAGALUR", Sch_type:"Government", Category:"High School"},
    { key:"11191", district:"22", Block:"309", label:"BHARATHI VIDYA MANDIR MATRIC SCHOOL, SITERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"11192", district:"22", Block:"309", label:"GHS, SITHERI", Sch_type:"Government", Category:"High School"},
    { key:"11193", district:"22", Block:"309", label:"GHS, GOVINDAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11194", district:"22", Block:"309", label:"PUMS, THENKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11195", district:"22", Block:"309", label:"GBHSS, VEERAGANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11196", district:"22", Block:"309", label:"GGHSS, VEERAGANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11197", district:"22", Block:"309", label:"SRI RAGAVENDRA MATRIC HR. SEC. SCHOOL, VEERAGANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11198", district:"22", Block:"309", label:"PUMS, THITTACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"11199", district:"22", Block:"309", label:"GHS, LATHUVADI", Sch_type:"Government", Category:"High School"},
    { key:"11200", district:"22", Block:"309", label:"GHS, VELLAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"11201", district:"22", Block:"309", label:"PUMS, ELUPPANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11202", district:"22", Block:"309", label:"SRI RAMAKRISHNA MATRICULATION SCHOOL, ELUPPANATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11203", district:"22", Block:"309", label:"GHS, VEPPAMPOONDI", Sch_type:"Government", Category:"High School"},
    { key:"11204", district:"22", Block:"309", label:"GHS, PULIYANKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"11205", district:"22", Block:"309", label:"GHS, EAST RAJAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11206", district:"22", Block:"309", label:"PUMS, KAVARPANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11207", district:"22", Block:"391", label:"PUMS M.G.R. NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"11208", district:"22", Block:"391", label:"PUMS CHINNAPOOSALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11209", district:"22", Block:"391", label:"PUMS ELAGUVAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11210", district:"22", Block:"391", label:"GHSS MURUNGAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11211", district:"22", Block:"391", label:"PUMS NAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11212", district:"22", Block:"391", label:"SHALOM CONVENT MATRIC SCHOOL, PUDHU ROAD,PERUMAMPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11213", district:"22", Block:"391", label:"PUMS KOTHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11214", district:"22", Block:"391", label:"PUMS S.K. VALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"11215", district:"22", Block:"391", label:"GHS, PERIYAPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"11216", district:"22", Block:"391", label:"GHS, ATTAVANAIPOOLAVARI", Sch_type:"Government", Category:"High School"},
    { key:"11217", district:"22", Block:"391", label:"PUMS UTHAMACHOLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11218", district:"22", Block:"391", label:"V.M. ANNAPOORANA MATRIC SCHOOL, UTHAMASOLAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11219", district:"22", Block:"391", label:"GHS PERUMAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11220", district:"22", Block:"391", label:"GGHSS,  ELAMPILLAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11221", district:"22", Block:"391", label:"GBHSS, ELAMPILLAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11222", district:"22", Block:"391", label:"PUMS REDDIMANIYAKARANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11223", district:"22", Block:"391", label:"PUMS K.PUDHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11224", district:"22", Block:"391", label:"GHSS, VEMBADITHALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11225", district:"22", Block:"391", label:"K.G.B.V. ELAMPILLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11226", district:"22", Block:"391", label:"PUMS RAKKIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11227", district:"22", Block:"391", label:"VIDHYAA VAASHINI HR. SEC. SCHOOL, RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11228", district:"22", Block:"391", label:"PUMS THANAKUTTYPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11229", district:"22", Block:"391", label:"GBHSS ATTAYAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11230", district:"22", Block:"391", label:"M.N.S. GOVT. GIRLS HR. SEC. SCHOOL, ATTAYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11231", district:"22", Block:"391", label:"SRI VIDHYAA MANDIR HR. SEC. SCHOOL, PETHAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11232", district:"22", Block:"391", label:"GHS, CHINNASEERAGAPADI", Sch_type:"Government", Category:"High School"},
    { key:"11233", district:"22", Block:"391", label:"PUMS PERIYASEERAGAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11234", district:"22", Block:"391", label:"PUMS VEERAPANDI", Sch_type:"Government", Category:"Middle School"},
    { key:"11235", district:"22", Block:"391", label:"GHSS VEERAPANDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11236", district:"22", Block:"391", label:"PUMS PALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11237", district:"22", Block:"391", label:"PUMS ERUSANAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11238", district:"22", Block:"273", label:"PUMS,SIRCARKOLLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11239", district:"22", Block:"273", label:"GHSS,SARKARKOLAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11240", district:"22", Block:"273", label:"PUMS, MAJURAKOLLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11241", district:"22", Block:"273", label:"PUMS, THIRUMALAIGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"11242", district:"22", Block:"273", label:"GHS,VEDUGATHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11243", district:"22", Block:"273", label:"PUMS, AYYAMPERUMAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11244", district:"22", Block:"273", label:"SRI PALANIAPPA MATRIC SCHOOL,MALLAMOOPAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"11245", district:"22", Block:"273", label:"SRI GAYATHRI HR. SEC. SCHOOL, THALAVAIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11246", district:"22", Block:"273", label:"PUMS, JAGIRREDDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11247", district:"22", Block:"273", label:"VAGGISVARI VIDYALAYA (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11248", district:"22", Block:"273", label:"GHSS,JAGIR AMMAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11249", district:"22", Block:"273", label:"SENTHIL PUBLIC SCHOOL (CBSE) JAGIR AMPALAYAMMAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11250", district:"22", Block:"273", label:"PUMS, NARASOTHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11251", district:"22", Block:"273", label:"MOTHERS VIDHYALAYA MATRIC HR. SEC. SCHOOL,NARASOTHIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11252", district:"22", Block:"273", label:"GHS,ALAGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11253", district:"22", Block:"273", label:"NSK VAISHINAVI SMART MATRICULATION  SCHOOL GORIMEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"11254", district:"22", Block:"273", label:"GHS,CHETTICHAVADI", Sch_type:"Government", Category:"High School"},
    { key:"11255", district:"22", Block:"273", label:"PUMS, CHINNAKOLLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11256", district:"22", Block:"273", label:"PUMS, VINAYAGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11257", district:"22", Block:"273", label:"MOUNT MARY MATRIC HR. SEC. SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11258", district:"22", Block:"273", label:"EMERALD VALLEY PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11259", district:"22", Block:"273", label:"SHARON HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11260", district:"22", Block:"273", label:"SRI SESHAAS INTERNATIONAL PUBLIC SCHOOL,YERCAUD FOOTHILLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11261", district:"22", Block:"273", label:"GHS, KONDAPPANAICKENPATTY", Sch_type:"Government", Category:"High School"},
    { key:"11262", district:"22", Block:"273", label:"PUMS,KANNANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"11263", district:"22", Block:"273", label:"PUMS, THAMARAINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"11264", district:"22", Block:"273", label:"PUMS CHINNATHIRUPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"11265", district:"22", Block:"273", label:"ST. BASIL MATRIC SCHOOL, CHINNATHIRUPATHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"11266", district:"22", Block:"273", label:"GHSS,KANNANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11267", district:"22", Block:"273", label:"PUMS,MITTAPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11268", district:"22", Block:"273", label:"PUMS,KOMBAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11269", district:"22", Block:"273", label:"GHS ALAGAPURAM PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"11270", district:"22", Block:"273", label:"PUMS,SANNIYASIGUNDU", Sch_type:"Government", Category:"Middle School"},
    { key:"11271", district:"22", Block:"273", label:"ST. THOMAS MATRIC SCHOOL, SANNIYASIGUNDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"11272", district:"22", Block:"273", label:"GHS, ERUMAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11273", district:"22", Block:"273", label:"PUMS, BHARATHINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"11274", district:"22", Block:"273", label:"GHSS, MANIYANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11275", district:"22", Block:"273", label:"MMS,BODINAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11276", district:"22", Block:"273", label:"SRI RAMALINGA VALLALAR HR. SEC. SCHOOL, SURAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11277", district:"22", Block:"273", label:"DHAARUS SALAAM MATRIC HR. SEC. SCHOOL SALEM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11278", district:"22", Block:"273", label:"SBK MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11279", district:"22", Block:"273", label:"SRI RAMAKRISHNA SARADA HR. SEC. SCHOOL (AIDED)", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11280", district:"22", Block:"273", label:"NEELAMBAL SUBRAMANIAM HR. SEC. SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11281", district:"22", Block:"273", label:"ST. JOSEPH GIRLS HR. SEC. SCHOOL (AIDED),SURAMANGALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11282", district:"22", Block:"273", label:"PUMS, KUDUMIANTHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"11283", district:"22", Block:"273", label:"GHS FOR DEAF SURAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"11284", district:"22", Block:"273", label:"PUMS,SELATHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11285", district:"22", Block:"273", label:"PUMS, SIVADHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11286", district:"22", Block:"273", label:"GHSS,SIVADHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11287", district:"22", Block:"273", label:"PUMS,ANDIPATI", Sch_type:"Government", Category:"Middle School"},
    { key:"11288", district:"22", Block:"273", label:"PUMS, KANDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11289", district:"22", Block:"273", label:"EQUITAS GURUKUL MATRIC HR. SEC. SCHOOL, SURAMANGALAM, KANDAMPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11290", district:"22", Block:"273", label:"GHS SEELANAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11291", district:"22", Block:"316", label:"GHS M.OLAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11292", district:"22", Block:"316", label:"PUMS MALLIKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11293", district:"22", Block:"316", label:"PUMS RASANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"11294", district:"22", Block:"316", label:"GHSS AMARAGUNDHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11295", district:"22", Block:"316", label:"PUMS ALAKUSAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11296", district:"22", Block:"316", label:"GHSS T.KONAGAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11297", district:"22", Block:"316", label:"PUMS PAVALATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11298", district:"22", Block:"316", label:"PUMS PARAKKALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11299", district:"22", Block:"316", label:"PUMS ARUNACHALAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11300", district:"22", Block:"316", label:"PUMS M.G.R COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"11301", district:"22", Block:"316", label:"PUMS MOTTUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11302", district:"22", Block:"316", label:"PUMS THARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11303", district:"22", Block:"316", label:"SENGUNTHAR MATRIC HR. SEC. SCHOOL, THARAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11304", district:"22", Block:"316", label:"St.CHARLES MATRIC SCHOOL, THARAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11305", district:"22", Block:"316", label:"GGHSS THARAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11306", district:"22", Block:"316", label:"SENGUNDHAR MAHAJANA HR. SEC. SCHOOL, THARAMANGALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11307", district:"22", Block:"316", label:"SRI JOTHI HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11308", district:"22", Block:"316", label:"VANNIYAKULA SATHIRIYAR HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11309", district:"22", Block:"316", label:"PUMS ARURPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11310", district:"22", Block:"316", label:"PUMS PALAKARANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"11311", district:"22", Block:"316", label:"GHS SEDAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11312", district:"22", Block:"316", label:"K.G.B.V.  CHINNAPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11313", district:"22", Block:"316", label:"PUMS KATHIMARANVALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"11314", district:"22", Block:"316", label:"GHSS RAMIREDDIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11315", district:"22", Block:"316", label:"GHS VENNANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11316", district:"22", Block:"316", label:"PUMS  ARIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11317", district:"22", Block:"316", label:"PUMS NACHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11318", district:"22", Block:"316", label:"SREE VARI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11319", district:"22", Block:"316", label:"PUMS EDAIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11320", district:"22", Block:"316", label:"PUMS A.P.VATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11321", district:"22", Block:"316", label:"PUMS MATAIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11322", district:"22", Block:"316", label:"PUMS VANICHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11323", district:"22", Block:"316", label:"PUMS LACHUMAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11324", district:"22", Block:"316", label:"PUMS SENGODANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"11325", district:"22", Block:"316", label:"GHSS CHINNAPILLAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11326", district:"22", Block:"316", label:"GHSS, THUTTAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11327", district:"22", Block:"316", label:"PUMS KONDAKARANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"11328", district:"22", Block:"316", label:"GHSS CHINNAPPAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11329", district:"22", Block:"316", label:"PUMS KRISHNAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11330", district:"22", Block:"316", label:"PUMS KOTTAIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"11331", district:"22", Block:"316", label:"PUMS PERIYAKARUKKALVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11332", district:"22", Block:"203", label:"GHS KUNJANDIYUR", Sch_type:"Government", Category:"High School"},
    { key:"11333", district:"22", Block:"203", label:"RAMESH VIDHAYASHRAM MATRIC HR. SEC. SCHOOL, KUNJANDIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11334", district:"22", Block:"203", label:"GHSS PUDUCHAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11335", district:"22", Block:"203", label:"NALANDA MATRICULATION SCHOOL, BHARATHI NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11336", district:"22", Block:"203", label:"PUMS ANDIKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11337", district:"22", Block:"203", label:"PUMS VEERANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11338", district:"22", Block:"203", label:"GHS KONUR", Sch_type:"Government", Category:"High School"},
    { key:"11339", district:"22", Block:"203", label:"GHSS SANDAIDHANAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11340", district:"22", Block:"203", label:"PUMS VEERAKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"11341", district:"22", Block:"203", label:"PUMS PERIYA VANAVASI", Sch_type:"Government", Category:"Middle School"},
    { key:"11342", district:"22", Block:"203", label:"PUMS SAPPANIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11343", district:"22", Block:"203", label:"GBHSS VANAVASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11344", district:"22", Block:"203", label:"PUMS KARATTUPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11345", district:"22", Block:"203", label:"PUMS NANGAVALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"11346", district:"22", Block:"203", label:"GGHSS NANGAVALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11347", district:"22", Block:"203", label:"GHS CHINNASORAGAI", Sch_type:"Government", Category:"High School"},
    { key:"11348", district:"22", Block:"203", label:"PUMS PERIYASORAGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11349", district:"22", Block:"203", label:"PUMS SEERANGANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"11350", district:"22", Block:"203", label:"PUMS PONNAPPAN VALAVU A.V.COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"11351", district:"22", Block:"203", label:"PUMS CHINNANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11352", district:"22", Block:"203", label:"PUMS KUPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11353", district:"22", Block:"203", label:"PUMS SOORAPPALI", Sch_type:"Government", Category:"Middle School"},
    { key:"11354", district:"22", Block:"203", label:"PUMS NORACHIVALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"11355", district:"22", Block:"203", label:"PUMS KATTINAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11356", district:"22", Block:"203", label:"PUMS SOURIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11357", district:"22", Block:"203", label:"GHSS, SAVURIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11358", district:"22", Block:"203", label:"PUMS KAPPARATTHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11359", district:"22", Block:"203", label:"PUMS PODAIYAN THERU", Sch_type:"Government", Category:"Middle School"},
    { key:"11360", district:"22", Block:"203", label:"PUMS VANGALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11361", district:"22", Block:"203", label:"GGHSS JALAKANDAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11362", district:"22", Block:"203", label:"GBHSS JALAKANDAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11363", district:"22", Block:"203", label:"PUMS S. PUDUREDDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11364", district:"22", Block:"203", label:"PUMS THENGALVARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11365", district:"22", Block:"203", label:"PUMS CHINNAKKAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11366", district:"22", Block:"203", label:"GHS SALEM CAMP", Sch_type:"Government", Category:"High School"},
    { key:"11367", district:"22", Block:"203", label:"JAWAHAR HS, KARUMALAIKOODAL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11368", district:"22", Block:"203", label:"J.P. MATRICULATION SCHOOL, ANNALMIN NAGAR, METTUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11369", district:"22", Block:"203", label:"PUMS THURAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11370", district:"22", Block:"203", label:"K.G.B.V. NANGAVALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"11371", district:"22", Block:"61", label:"PUMS ADUVAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11372", district:"22", Block:"61", label:"GHS ADAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"11373", district:"22", Block:"61", label:"PUMS AMMASIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11374", district:"22", Block:"61", label:"GHS IRUPPALI", Sch_type:"Government", Category:"High School"},
    { key:"11375", district:"22", Block:"61", label:"GHSS CHETTIMANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11376", district:"22", Block:"61", label:"MODEL SCHOOL, CHETTIMANKURUCHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11377", district:"22", Block:"61", label:"PUMS PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11378", district:"22", Block:"61", label:"PUMS PULIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11379", district:"22", Block:"61", label:"GHSS CHITTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11380", district:"22", Block:"61", label:"K.G.B.V. CHITTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11381", district:"22", Block:"61", label:"PUMS KUPPANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11382", district:"22", Block:"61", label:"GHSS POOLAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11383", district:"22", Block:"61", label:"GHSS KONERIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11384", district:"22", Block:"61", label:"GHS NEDUNKULAM", Sch_type:"Government", Category:"High School"},
    { key:"11385", district:"22", Block:"61", label:"PUMS CHINNAMUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11386", district:"22", Block:"61", label:"GHSS VELLARIVELLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11387", district:"22", Block:"61", label:"AACHARIYA HR. SEC. SCHOOL, VELLARIVELLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11388", district:"22", Block:"61", label:"GHS KUNJAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11389", district:"22", Block:"61", label:"SHEMPARK PUBLIC SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"11390", district:"22", Block:"61", label:"MMS ALACHAMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11391", district:"22", Block:"61", label:"MMS KAVANDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11392", district:"22", Block:"61", label:"GGHSS EDAPPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11393", district:"22", Block:"61", label:"PUMS VALAGOUNDANVALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"11394", district:"22", Block:"61", label:"GBHSS EDAPPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11395", district:"22", Block:"61", label:"PUMS MANIYAKKARAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11396", district:"22", Block:"61", label:"GHS DADAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11397", district:"22", Block:"61", label:"PUMS VEMBANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"11398", district:"22", Block:"30", label:"PUMS SIVAGANGAIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11399", district:"22", Block:"30", label:"GHSS KALPAGANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11400", district:"22", Block:"30", label:"VANI VIKAS MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11401", district:"22", Block:"30", label:"GHSS THANDAVARAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11402", district:"22", Block:"30", label:"GHSS CHOKKANATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11403", district:"22", Block:"30", label:"PUMS SEELIAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11404", district:"22", Block:"30", label:"GOVT HR SEC SCHOOL MALLIYAKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11405", district:"22", Block:"30", label:"PUMS URANDIVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"11406", district:"22", Block:"30", label:"PUMS GOVINDARAJAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11407", district:"22", Block:"30", label:"GHSS KEERIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11408", district:"22", Block:"30", label:"PUMS KOOLAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"11409", district:"22", Block:"30", label:"GHS PAITHUR", Sch_type:"Government", Category:"High School"},
    { key:"11410", district:"22", Block:"30", label:"GBHSS ATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11411", district:"22", Block:"30", label:"GGHSS ATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11412", district:"22", Block:"30", label:"SRI SARADHA MEMORIAL MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11413", district:"22", Block:"30", label:"TSP MATRIC HR. SEC. SCHOOL, VINAYAGAPURAM, ATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11414", district:"22", Block:"30", label:"GHS NARASINGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11415", district:"22", Block:"30", label:"SRI S.R.V MATRICULATION HR. SEC. SCHOOL,NARASINGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11416", district:"22", Block:"30", label:"AKCHARA BALABHAVAN (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"11417", district:"22", Block:"30", label:"PUMS THENNANGUDIPALYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11418", district:"22", Block:"30", label:"GREEN PARK  MATRIC HSS ATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11419", district:"22", Block:"30", label:"A.E.T. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11420", district:"22", Block:"30", label:"PUMS LAKSHMANASAMUDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11421", district:"22", Block:"30", label:"PUMS KAMARAJ NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"11422", district:"22", Block:"30", label:"GHS RAMANAICKENPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11423", district:"22", Block:"30", label:"PUMS AMMAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11424", district:"22", Block:"30", label:"JAIVINS ACADEMY (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11425", district:"22", Block:"30", label:"GHS KALLANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"11426", district:"22", Block:"30", label:"M.M.S.MULLAIVADY", Sch_type:"Government", Category:"Middle School"},
    { key:"11427", district:"22", Block:"30", label:"M.M.S.GANDHINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"11428", district:"22", Block:"30", label:"ST MARY'S RC M.S(AIDED)", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11429", district:"22", Block:"30", label:"NATIONAL MIDDLE SCHOOL, ATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11430", district:"22", Block:"30", label:"THIRU CHINNASAMY AYYA MIDDLE SCHOOL, ATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11431", district:"22", Block:"30", label:"C.S.I. HIGH SCHOOL, ATTUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11432", district:"22", Block:"30", label:"ADW HSS AMBEDKAR NAGAR, ATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11433", district:"22", Block:"30", label:"BHARATHIYAR HI TECH INTERNATIONAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11434", district:"22", Block:"30", label:"GHS VALAYAMADEVI", Sch_type:"Government", Category:"High School"},
    { key:"11435", district:"22", Block:"30", label:"GHSS MANJINI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11436", district:"22", Block:"30", label:"PUMS PUNGAVADY", Sch_type:"Government", Category:"Middle School"},
    { key:"11437", district:"22", Block:"34", label:"GTRMS, ALADIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11438", district:"22", Block:"34", label:"GTRHSS ARUNOOTHUMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11439", district:"22", Block:"34", label:"PUMS, ANUPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11440", district:"22", Block:"34", label:"PUMS, VILAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11441", district:"22", Block:"34", label:"GHSS, KOOTTATHUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11442", district:"22", Block:"34", label:"GHS, MINNAMPALLI", Sch_type:"Government", Category:"High School"},
    { key:"11443", district:"22", Block:"34", label:"GHSS, AYOTHIYAPATTANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11444", district:"22", Block:"34", label:"THANGAM MOUNT LITERA ZEE SCHOOL RAMALINGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11445", district:"22", Block:"34", label:"GHS, RAMALINGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11446", district:"22", Block:"34", label:"PUMS, PERIYAGOUNDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11447", district:"22", Block:"34", label:"GHS, KARIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11448", district:"22", Block:"34", label:"ST. MARYS HR. SEC. SCHOOL, A.N.MANGALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11449", district:"22", Block:"34", label:"PUMS, KARUMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11450", district:"22", Block:"34", label:"PUMS, SELLIYAMMAN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"11451", district:"22", Block:"34", label:"GHSS, METTUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11452", district:"22", Block:"34", label:"PUMS, VEDAPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11453", district:"22", Block:"34", label:"GHSS, VEERANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11454", district:"22", Block:"34", label:"GHSS, VELLALAGUNDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11455", district:"22", Block:"34", label:"GHS, D-PERUMAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11456", district:"22", Block:"34", label:"HOLY CROSS MATRIC HR. SEC. SCHOOL, K.N. COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11457", district:"22", Block:"34", label:"GBHSS, VALASAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11458", district:"22", Block:"34", label:"VISHWA VIKAASH MATRIC SCHOOL, VALASAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11459", district:"22", Block:"34", label:"GGHSS, VALASAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11460", district:"22", Block:"34", label:"GHSS, SUKKAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11461", district:"22", Block:"34", label:"ST.NEVA MILLER MATRICULATION SCHOOL, PARUTHIKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"11462", district:"22", Block:"34", label:"ST. GRAHAM MEMORIAL HR. SEC. SCHOOL, VAITHATHANOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11463", district:"22", Block:"34", label:"PUMS, C.THATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11464", district:"22", Block:"34", label:"GHS, ACHANKUTTAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11465", district:"22", Block:"34", label:"PUMS, KUPPANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11466", district:"22", Block:"34", label:"THE GENE TECH MATRICULATION SCHOOL, KUPPANOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11467", district:"22", Block:"34", label:"PUMS, VELLIYAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11468", district:"22", Block:"34", label:"PUMS, ALLIKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11469", district:"22", Block:"34", label:"PUMS, THATHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11470", district:"22", Block:"34", label:"GHS, VAIKKALPATTARAI", Sch_type:"Government", Category:"High School"},
    { key:"11471", district:"22", Block:"34", label:"PUMS, VARAGAMPADY", Sch_type:"Government", Category:"Middle School"},
    { key:"11472", district:"22", Block:"34", label:"GHSS, UDAYAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11473", district:"22", Block:"34", label:"NOTRE DAME OF HOLY CROSS SCHOOL, GUNDUKALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11474", district:"22", Block:"34", label:"PUMS, KUMARAGIRIPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11475", district:"22", Block:"34", label:"JOTHI MATRIC HR. SEC. SCHOOL, K.N. COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11476", district:"22", Block:"34", label:"GHS, K.N. COLONY", Sch_type:"Government", Category:"High School"},
    { key:"11477", district:"22", Block:"129", label:"PUMS,KURUMBANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11478", district:"22", Block:"129", label:"PUMS,KANAMOOCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"11479", district:"22", Block:"129", label:"GOVT. HIGH SCHOOL,CHETTIUR", Sch_type:"Government", Category:"High School"},
    { key:"11480", district:"22", Block:"129", label:"K.G.B.V. KANNAMOOCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"11481", district:"22", Block:"129", label:"PUMS,KAVERIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11482", district:"22", Block:"129", label:"PUMS,KATHIRIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11483", district:"22", Block:"129", label:"PUMS,KOTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11484", district:"22", Block:"129", label:"PUMS,GOVINDAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11485", district:"22", Block:"129", label:"PUMS,THARKADU SUNDHARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11486", district:"22", Block:"129", label:"PUMS,TELUNGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11487", district:"22", Block:"129", label:"GOVT. HR. SEC.SCHOOL,  KAVERIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11488", district:"22", Block:"129", label:"GOVT. HIGH SCHOOL, KARUNGALLUR", Sch_type:"Government", Category:"High School"},
    { key:"11489", district:"22", Block:"129", label:"PUMS,KOLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11490", district:"22", Block:"129", label:"J.B. MATRIC HR. SEC. SCHOOL, KOLATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11491", district:"22", Block:"129", label:"GOVT. GIRLS HR. SEC.SCHOOL, KOLATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11492", district:"22", Block:"129", label:"NIRMALA HR. SEC. SCHOOL,KOLATHUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11493", district:"22", Block:"129", label:"PUMS,PORAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11494", district:"22", Block:"129", label:"GOVT. HR. SEC. SCHOOL,SANAVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11495", district:"22", Block:"129", label:"PUMS,P.K.NATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11496", district:"22", Block:"129", label:"SRI RAMAKRISHNA GURUKULA HIGH SCHOOL, CHINNATHANDA", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11497", district:"22", Block:"129", label:"K.G.B.V. NEETHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11498", district:"22", Block:"129", label:"GOVT. HIGH SCHOOL, NEETHIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11499", district:"22", Block:"129", label:"PUMS,MOOLAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11500", district:"22", Block:"129", label:"GOVT. HIGH SCHOOL,MATHAIYANKUTTAI", Sch_type:"Government", Category:"High School"},
    { key:"11501", district:"22", Block:"129", label:"M.A.M.HR.SEC.SCHOOL,MATHAIYANKUTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11502", district:"22", Block:"129", label:"PUMS,NAGAMPATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"11503", district:"22", Block:"129", label:"G.V. HR.SEC.SCHOOL,MASILAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11504", district:"22", Block:"129", label:"PUMS,AYYAMPUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11505", district:"22", Block:"129", label:"VIVEKANANDHA KALVI NILAYAM MATRIC SCHOOL,KARUNGALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11506", district:"22", Block:"129", label:"PUMS,SETHUKULI", Sch_type:"Government", Category:"Middle School"},
    { key:"11507", district:"22", Block:"129", label:"PUMS,PANNAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11508", district:"22", Block:"129", label:"AIDED KAMALA MIDDLE SCHOOL,METTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11509", district:"22", Block:"129", label:"ST.PHILOMINA'S MIDDLE SCHOOL, METTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11510", district:"22", Block:"129", label:"GOVT. HR. SEC.SCHOOL, METTUR DAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11511", district:"22", Block:"129", label:"ST.MARY'S HR. SEC.SCHOOL, METTUR DAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11512", district:"22", Block:"129", label:"VAIDHEESAWARA HR.SEC.SCHOOL,METTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11513", district:"22", Block:"129", label:"GOVT. GIRLS HIGH SCHOOL, METTUR", Sch_type:"Government", Category:"High School"},
    { key:"11514", district:"22", Block:"129", label:"M.A.M. HI-TECH MATRIC HR. SEC. SCHOOL, METTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11515", district:"22", Block:"179", label:"PUMS,VALATHASAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11516", district:"22", Block:"179", label:"GHSS CHINTHAMANIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11517", district:"22", Block:"179", label:"GHSS M.N.PATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11518", district:"22", Block:"179", label:"G.H.S ARANGANOOR", Sch_type:"Government", Category:"High School"},
    { key:"11519", district:"22", Block:"179", label:"PKM GHSS MADHANAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11520", district:"22", Block:"179", label:"SRI SARASWATHI VIDHYALAYA HR. SEC. SCHOOL, KUTTAPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11521", district:"22", Block:"179", label:"SRI VIDHYA BHARATHI MATRIC HR. SEC. SCHOOL, MALAIADIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11522", district:"22", Block:"179", label:"PUM SCHOOL, MALLAPPANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11523", district:"22", Block:"179", label:"PUM SCHOOL, VIRUTHASAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11524", district:"22", Block:"179", label:"GHSS KULLAMUDAIYANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11525", district:"22", Block:"179", label:"PUMS,AMARATHANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"11526", district:"22", Block:"179", label:"JEMS INTERNATIONAL MATRIC HR. SEC. SCHOOL,SAVADIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11527", district:"22", Block:"179", label:"GHS BUKKAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"11528", district:"22", Block:"179", label:"PUM SCHOOL, AMARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11529", district:"22", Block:"179", label:"PUM SCHOOL, THETHIGIRIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11530", district:"22", Block:"179", label:"PUM SCHOOL, KATCHARAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11531", district:"22", Block:"179", label:"PUM SCHOOL, KARUMBUSALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11532", district:"22", Block:"179", label:"PUM SCHOOL, ERUMAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11533", district:"22", Block:"179", label:"GHSS VELLAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11534", district:"22", Block:"179", label:"WESTERN GATE MAT. SCHOOL, UPPARAPPATTI KATTU VALAVU, METTUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11535", district:"22", Block:"179", label:"SONASUN HI TECH MATRIC HR. SEC. SCHOOL,VELLAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11536", district:"22", Block:"179", label:"PUM SCHOOL, PALLAKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11537", district:"22", Block:"179", label:"PUM SCHOOL, THIPPARATHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11538", district:"22", Block:"179", label:"GHS MALLIKUNDAM", Sch_type:"Government", Category:"High School"},
    { key:"11539", district:"22", Block:"179", label:"PUM SCHOOL, M.PALLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11540", district:"22", Block:"179", label:"PUM SCHOOL, VANNIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11541", district:"22", Block:"179", label:"PUM SCHOOL, NARIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11542", district:"22", Block:"179", label:"PUM SCHOOL, KEERAIKARANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11543", district:"22", Block:"179", label:"GHS KOONANDIYUR", Sch_type:"Government", Category:"High School"},
    { key:"11544", district:"22", Block:"179", label:"PUM SCHOOL, PARAKKALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11545", district:"22", Block:"179", label:"GBHSS, MECHERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11546", district:"22", Block:"179", label:"GGHSS MECHERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11547", district:"22", Block:"179", label:"PUM SCHOOL, THONGALANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11548", district:"22", Block:"215", label:"GHSS OMALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11549", district:"22", Block:"215", label:"VELASAMY CHETTIYAR HR. SEC. SCHOOL, OMALUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11550", district:"22", Block:"215", label:"FATHIMA GIRLS HR. SEC. SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11551", district:"22", Block:"215", label:"GREEN HOME MATRIC HR. SEC. SCHOOL,NATIONAL NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11552", district:"22", Block:"215", label:"FATIMA MATRICULATION SCHOOL OMALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11553", district:"22", Block:"215", label:"P.U.M.S.PERAMACHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11554", district:"22", Block:"215", label:"WISDOM GATES MATRICULATION HR. SEC. SCHOOL, VELAGOUNDANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11555", district:"22", Block:"215", label:"GHS KAMALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11556", district:"22", Block:"215", label:"ST.NICHOLAS MID SCH R.C.CHETTIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11557", district:"22", Block:"215", label:"JOHN BRITTO MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11558", district:"22", Block:"215", label:"P.U.M.S.POTTIAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11559", district:"22", Block:"215", label:"P.U.M.S.ARANMANAIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11560", district:"22", Block:"215", label:"P.U.M.S.THUMBIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11561", district:"22", Block:"215", label:"P.U.M.S.KENDAPERIANVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"11562", district:"22", Block:"215", label:"P.U.M.S.THONNAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"11563", district:"22", Block:"215", label:"NORTH PARK MATRIC SCHOOL,THINNAPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"11564", district:"22", Block:"215", label:"P.U.M.S.SARAKKAPPILLAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11565", district:"22", Block:"215", label:"P.U.M.S.REDDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11566", district:"22", Block:"215", label:"P.U.M.S.KOMALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11567", district:"22", Block:"215", label:"ADW MS THATHIAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11568", district:"22", Block:"215", label:"P.U.M.S..P.KALIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11569", district:"22", Block:"215", label:"P.U.M.S.KARUPPANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11570", district:"22", Block:"215", label:"PERARIGNAR ANNA G.H.S.S, THOLASAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11571", district:"22", Block:"215", label:"GHSS M.CHETTIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11572", district:"22", Block:"215", label:"GHS PERIYAKADAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11573", district:"22", Block:"215", label:"P.U.M.S.REDDIPATTI KATTUVALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"11574", district:"22", Block:"215", label:"P.U.M.S.PACHANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11575", district:"22", Block:"215", label:"GHSS,PANAKATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11576", district:"22", Block:"215", label:"P.U.M.S KULLAGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11577", district:"22", Block:"215", label:"P.U.M.S METTUPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"11578", district:"22", Block:"215", label:"SKV HI TECH MATRICULATION HR.SEC.SCHOOL ,KARUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11579", district:"22", Block:"215", label:"GHSS,KARUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11580", district:"22", Block:"215", label:"SSRM HR. SEC. SCHOOL, KARUMBALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11581", district:"22", Block:"215", label:"RAAJAM MATRIC HR. SEC. SCHOOL, KARUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11582", district:"22", Block:"215", label:"P.U.M.S.SANGEETHAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11583", district:"22", Block:"215", label:"ADWHSS MATTUKARANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11584", district:"22", Block:"215", label:"P.U.M.S.CHELLAPILLAIKUTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11585", district:"22", Block:"215", label:"P.U.M.S.P.NALLAGOUNDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11586", district:"22", Block:"215", label:"P.U.M.S.BOOMINAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11587", district:"22", Block:"215", label:"ST.JOSEPH HR. SEC. SCHOOL, PAGALPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11588", district:"22", Block:"215", label:"MUKUNDA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11589", district:"22", Block:"215", label:"GHSS PAGALPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11590", district:"22", Block:"215", label:"P.U.M.S.PUDHURKADAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11591", district:"22", Block:"215", label:"P.U.M.S.KANDAMPITCHANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"11592", district:"22", Block:"215", label:"GHSS MUTHUNAICKENPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11593", district:"22", Block:"215", label:"P.U.M.S.SEMMANKOODAL", Sch_type:"Government", Category:"Middle School"},
    { key:"11594", district:"22", Block:"215", label:"GHS PALAKUTTAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"11595", district:"22", Block:"215", label:"GHS SAKKARA CHETTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11596", district:"22", Block:"215", label:"VIVEKANANDA KENDRIYA VIDHYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"11597", district:"22", Block:"215", label:"P.U.M.SPULIAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11598", district:"22", Block:"215", label:"P.U.M.S.VATTAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11599", district:"22", Block:"215", label:"GHSS THEKKAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11600", district:"22", Block:"215", label:"P.U.M.S.THATHIANGARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11601", district:"22", Block:"215", label:"P.U.M.S.MOONGILPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11602", district:"22", Block:"215", label:"P.U.M.S.SAMINAICKANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11603", district:"22", Block:"215", label:"GHSS VELLALAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11604", district:"22", Block:"215", label:"P.U.M.S.MANGKUPPAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11605", district:"22", Block:"215", label:"KIZHAR PALANIYANDI PILLAI AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11606", district:"22", Block:"215", label:"GOLDEN SPARK MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11607", district:"22", Block:"278", label:"PUMS SANKARI IIIRD WARD", Sch_type:"Government", Category:"Middle School"},
    { key:"11608", district:"22", Block:"278", label:"PUMS NAGICHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11609", district:"22", Block:"278", label:"PUMS OONJAKORAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11610", district:"22", Block:"278", label:"GHSS NATTUVAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11611", district:"22", Block:"278", label:"GHSS B SANKARI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11612", district:"22", Block:"278", label:"GHSS G SANKARI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11613", district:"22", Block:"278", label:"SRE VIDYASAAGAR HR. SEC. SCHOOL, NAGICHETTIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11614", district:"22", Block:"278", label:"GHS AKKAMAPET", Sch_type:"Government", Category:"High School"},
    { key:"11615", district:"22", Block:"278", label:"GHSS THEVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11616", district:"22", Block:"278", label:"PUMS OLAPPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11617", district:"22", Block:"278", label:"GHS ARA KULLAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11618", district:"22", Block:"278", label:"GHSS ARA CHETTIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11619", district:"22", Block:"278", label:"PUMS A.REDDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11620", district:"22", Block:"278", label:"PUMS MOTHAIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11621", district:"22", Block:"278", label:"GHS CHINNAGOUNDANOOR", Sch_type:"Government", Category:"High School"},
    { key:"11622", district:"22", Block:"278", label:"PUMS CHETTIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11623", district:"22", Block:"278", label:"PUMS MANJAKKALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11624", district:"22", Block:"278", label:"PUMS DEVANNAGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11625", district:"22", Block:"278", label:"K.G.B.V. DEVANNAGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11626", district:"22", Block:"278", label:"PUMS IRUGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11627", district:"22", Block:"278", label:"PUMS OKKILIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11628", district:"22", Block:"278", label:"PUMS VATRAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11629", district:"22", Block:"278", label:"PUMS KAVERIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11630", district:"22", Block:"278", label:"PUMS KAIKOLAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11631", district:"22", Block:"278", label:"PUMS PONNAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11632", district:"22", Block:"278", label:"GHS KATHERI", Sch_type:"Government", Category:"High School"},
    { key:"11633", district:"22", Block:"278", label:"PUMS VENGIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11634", district:"22", Block:"278", label:"P.U.M.S.PULLAGOUNDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11635", district:"22", Block:"278", label:"GHS VADUGAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11636", district:"22", Block:"383", label:"PUMS, MALAIYALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11637", district:"22", Block:"383", label:"GHSS, THIRUMANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11638", district:"22", Block:"383", label:"GHS, VEPPILAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11639", district:"22", Block:"383", label:"PUMS, PONNARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11640", district:"22", Block:"383", label:"PUMS, V.MANNARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11641", district:"22", Block:"383", label:"GHS, VILARIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11642", district:"22", Block:"383", label:"GHSS, SINGIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11643", district:"22", Block:"383", label:"SREE GOKULAM MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11644", district:"22", Block:"383", label:"GGHSS, VALAPPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11645", district:"22", Block:"383", label:"GOVT BOYS HSS, VALAPPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11646", district:"22", Block:"383", label:"PUMS, KAVARKALPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11647", district:"22", Block:"383", label:"GHS, SESANCHAVADI", Sch_type:"Government", Category:"High School"},
    { key:"11648", district:"22", Block:"383", label:"PUMS, MUTHAMPATTY COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"11649", district:"22", Block:"383", label:"GMS, MUTHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11650", district:"22", Block:"383", label:"PUMS, MANNAICKENPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11651", district:"22", Block:"383", label:"GHS THUKKIYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11652", district:"22", Block:"383", label:"GHS ATHANURPATTY", Sch_type:"Government", Category:"High School"},
    { key:"11653", district:"22", Block:"383", label:"GGHSS, BELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11654", district:"22", Block:"383", label:"GBHSS, BELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11655", district:"22", Block:"383", label:"SRI SAKTHI VIKAAS MATRIC HR. SEC. SCHOOL,BELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11656", district:"22", Block:"383", label:"GHS, NEERMULLIKUTTAI", Sch_type:"Government", Category:"High School"},
    { key:"11657", district:"22", Block:"383", label:"GHS, C.N.PALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11658", district:"22", Block:"383", label:"PUMS, KURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"11659", district:"22", Block:"383", label:"PUMS, KONANCHETTIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11660", district:"22", Block:"383", label:"PUMS, ARANGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11661", district:"22", Block:"383", label:"GTR HR. SEC. SCHOOL, VELLIGOUNDANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11662", district:"22", Block:"383", label:"PUMS, CHANDRAPILLAIVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"11663", district:"22", Block:"413", label:"PUMS SENGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11664", district:"22", Block:"413", label:"PUMS, THALAICHOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11665", district:"22", Block:"413", label:"PUMS.GUNDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11666", district:"22", Block:"413", label:"ST. JOSEPH BOYS HR. SEC. SCHOOL, YERCAUD", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11667", district:"22", Block:"413", label:"SACRED HEART ANGLO INDIAN GIRLS HR. SEC. SCHOOL, YERCAUD", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11668", district:"22", Block:"413", label:"GHSS YERCAUD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11669", district:"22", Block:"413", label:"NAZARETH GIRLS HR. SEC. SCHOOL, YERCAUD", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11670", district:"22", Block:"413", label:"MONTFORT ANGLO INDIAN HR. SEC. SCHOOL, YERCAUD", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11671", district:"22", Block:"413", label:"ST.CHARLES SCHOOL,YERCAUD", Sch_type:"Un-aided", Category:"High School"},
    { key:"11672", district:"22", Block:"413", label:"PUMS, MULUVI", Sch_type:"Government", Category:"Middle School"},
    { key:"11673", district:"22", Block:"413", label:"GHS NAGALUR", Sch_type:"Government", Category:"High School"},
    { key:"11674", district:"22", Block:"413", label:"PUMS, PATTIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"11675", district:"22", Block:"413", label:"PUMS, KOLAGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11676", district:"22", Block:"413", label:"GHS VELLAKKADAI", Sch_type:"Government", Category:"High School"},
    { key:"11677", district:"22", Block:"413", label:"PUMS, NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11678", district:"22", Block:"413", label:"PUMS, MARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11679", district:"22", Block:"413", label:"MONTFORT COMMUNITY HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11680", district:"22", Block:"413", label:"G.T.R. MIDDLE SCH VAZHAVANTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"11681", district:"22", Block:"413", label:"SEVAROYS VALLY HR. SEC. SCHOOL, VALAVANTHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11682", district:"22", Block:"413", label:"PUMS.CHINNA MATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11683", district:"22", Block:"413", label:"K.G.B.V. YERCAUD", Sch_type:"Government", Category:"Middle School"},
    { key:"11684", district:"22", Block:"274", label:"SRI SARADA VIDYALAYA HR. SEC. SCHOOL FOR GIRLS (AIDED)", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11685", district:"22", Block:"274", label:"HOLY ANGELS MATRIC HR. SEC. SCHOOL,FAIRLANDS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11686", district:"22", Block:"274", label:"ST.JOHN'S MATRIC HR. SEC. SCHOOL,NEW  ALAGAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11687", district:"22", Block:"274", label:"ANDERSON HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"11688", district:"22", Block:"274", label:"JAYALAKSHMI VIDYALAYAM HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"11689", district:"22", Block:"274", label:"NEW INDIA DISCOVERY MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11690", district:"22", Block:"274", label:"MPL HIGH SCHOOL PUTHUMARIYAMMAN KOIL ST", Sch_type:"Government", Category:"High School"},
    { key:"11691", district:"22", Block:"274", label:"K M G H S S MANAKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11692", district:"22", Block:"274", label:"BHARATHI VIDYALAYA HR. SEC. SCHOOL,MARAVANERI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11693", district:"22", Block:"274", label:"C.S.I. HOBART GIRLS HIGH SCHOOL,HASTHAMPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"11694", district:"22", Block:"274", label:"L.E.F. EDEN GARDEN MATRIC SCHOOL,HASTHAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"11695", district:"22", Block:"274", label:"CLUNY MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11696", district:"22", Block:"274", label:"AMS CHANDRA DEVAPRASAD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11697", district:"22", Block:"274", label:"GOVT HIGH SCHOOL AR LINES", Sch_type:"Government", Category:"High School"},
    { key:"11698", district:"22", Block:"274", label:"MHS SAGADEVAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11699", district:"22", Block:"274", label:"THE LITTLE FLOWER HR. SEC. SCHOOL, SALEM-7", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11700", district:"22", Block:"274", label:"ST. PAUL'S HR. SEC. SCHOOL, SALEM-7", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11701", district:"22", Block:"274", label:"MMS ARISIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11702", district:"22", Block:"274", label:"ST. MARY'S GIRLS HR. SEC. SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11703", district:"22", Block:"274", label:"THE LITTLE FLOWER MATRICULATION SCHOOL,FOUR ROADS", Sch_type:"Un-aided", Category:"High School"},
    { key:"11704", district:"22", Block:"274", label:"MMS. NARASIMMAN SALAI ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"11705", district:"22", Block:"274", label:" JAYARAKKINI AIDED MS, SHEVAPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11706", district:"22", Block:"274", label:"GOKULANATHA HINDU MAHAJANA BOYS HR.SEC.SALEM 1", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11707", district:"22", Block:"274", label:"MANURKULA DEVANGA HIGH SCHOOL,SHEVAPET", Sch_type:"Partially Aided", Category:"High School"},
    { key:"11708", district:"22", Block:"274", label:"VASAVI HR SEC SCHOOL, SHEVAPET", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11709", district:"22", Block:"274", label:"MHS SHEVAPET", Sch_type:"Government", Category:"High School"},
    { key:"11710", district:"22", Block:"274", label:" CORNATION AIDED MS, SELLAKUTTIKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11711", district:"22", Block:"274", label:"AMS SOURASHTRA VIDYALAYA", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11712", district:"22", Block:"274", label:"GGHSS SALEM 1", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11713", district:"22", Block:"274", label:"CSI HIGHER SEC SCHOOL-FORT", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11714", district:"22", Block:"274", label:"MBHSS FORT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11715", district:"22", Block:"274", label:"MMS CAR STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"11716", district:"22", Block:"274", label:"TIPPU ISLAMIYA HIGH SCHOOL,SALEM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11717", district:"22", Block:"274", label:"TIPPU ISLAMIYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11718", district:"22", Block:"274", label:"MMS. THEETTUKKAL STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"11719", district:"22", Block:"274", label:"SRI SATHYA SAI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11720", district:"22", Block:"274", label:"MMS. ELLAPPAN STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"11721", district:"22", Block:"274", label:"MBHSS, AMMAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11722", district:"22", Block:"274", label:"MGHSS AMMAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11723", district:"22", Block:"274", label:"MHS, VAIYAPURI STREET", Sch_type:"Government", Category:"High School"},
    { key:"11724", district:"22", Block:"274", label:"MBHSS PAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11725", district:"22", Block:"274", label:"JAY MATRICULATION SCHOOL, KITCHIPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11726", district:"22", Block:"274", label:"SINDHI HINDU HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"11727", district:"22", Block:"274", label:"MGHSS PAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11728", district:"22", Block:"274", label:"MMS ERUMAPALAYAM No.2", Sch_type:"Government", Category:"Middle School"},
    { key:"11729", district:"22", Block:"274", label:"MMS PACHAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"11730", district:"22", Block:"274", label:" ANGAYAR KANNI AIDED MS  - AMMAPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11731", district:"22", Block:"274", label:"SOURASHTRA HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"11732", district:"22", Block:"274", label:"MHS PUDHU STREET KITCHIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11733", district:"22", Block:"274", label:"NATIONAL HIGH SCHOOL K PALAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"11734", district:"22", Block:"274", label:"J.R. CAMBRIDGE SCHOOL, NARAYANA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11735", district:"22", Block:"274", label:"AMS CSI AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11736", district:"22", Block:"274", label:"MPL GHSS, GUGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11737", district:"22", Block:"274", label:"MMS - KALIAMMAN KOIL ST", Sch_type:"Government", Category:"Middle School"},
    { key:"11738", district:"22", Block:"274", label:"AMS SRI GURUSWAMY-GUGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11739", district:"22", Block:"274", label:"SEVENTH DAY ADVENTIST MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11740", district:"22", Block:"274", label:"SRI VIDYA MANDIR SEC. SCHOOL, SHEVAPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"11741", district:"22", Block:"274", label:"MMS.ANNATHANAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11742", district:"22", Block:"274", label:"JAYARANI GIRL'S AIDED HR SEC  SCHOOL,NETHIMEDU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11743", district:"22", Block:"274", label:"THE GUGAI HR SEC SCHOOL -GUGAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11744", district:"22", Block:"274", label:"GOKULA VILAS  AIDED MS,SANJEEVARAYANPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11745", district:"22", Block:"274", label:"MMS. SANJEEVARAYANPET", Sch_type:"Government", Category:"Middle School"},
    { key:"11746", district:"22", Block:"274", label:"MMS, THADAGAPATTI, METTU ST ,SALEM-6", Sch_type:"Government", Category:"Middle School"},
    { key:"11747", district:"22", Block:"274", label:"VEERALAKSHMI VIDYALAYA HIGH SCHOOL,GUGAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"11748", district:"17", Block:"201", label:"PUMS KATHAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"11749", district:"17", Block:"201", label:"GHS MARURPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11750", district:"17", Block:"201", label:"PUMS MUDALAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11751", district:"17", Block:"201", label:"MMS RAMAPURAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11752", district:"17", Block:"201", label:"PUMS MUDALAIPATTIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11753", district:"17", Block:"201", label:"NAMMALAVAR AIDED MIDDLE SCHOOL,NAMAKKAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11754", district:"17", Block:"201", label:"MHS FORT NAMAKKAL", Sch_type:"Government", Category:"High School"},
    { key:"11755", district:"17", Block:"201", label:"SBM AIDED HIGH SCHOOL NAMAKKAL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11756", district:"17", Block:"201", label:"SELVAM MATRIC HIGHER SECONDARY SCHOOL, 3/1143 TRICHY ROAD, NAMAKKAL DT - 637001", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11757", district:"17", Block:"201", label:"MARUTHI VIDDYALAYA  MATRICULATION SCHOOL, 135/1 PATHI NAGAR, SALEM ROAD, NAMAKKAL DT -637001", Sch_type:"Un-aided", Category:"High School"},
    { key:"11758", district:"17", Block:"201", label:"GOVT HR SEC SCHOOL NAMAKKAL (SOUTH)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11759", district:"17", Block:"201", label:"GGHSS  NAMAKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11760", district:"17", Block:"201", label:"KANDASWAMI KANDAR MATRICULATION HIGHER SECONDARY SCHOOL, GANDHI NAGAR, NAMAKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11761", district:"17", Block:"201", label:"JACK & JILL MATRICULATION SCHOOL,RAMAPURAM PUDUR, NAMAKKAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"11762", district:"17", Block:"201", label:"SELVAM HIGHER SECONDARY SCHOOL MUDALAIPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11763", district:"17", Block:"201", label:"GHSS  NAMAKKAL (NORTH )", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11764", district:"17", Block:"201", label:"GHS AYYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11765", district:"17", Block:"201", label:"GREEN PARK MATRICULATION HIGHER SECONDARY SCHOOL BODHUPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11766", district:"17", Block:"201", label:"SRI VINAYAGA  HIGHER SECONDARY  SCHOOL PORASAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11767", district:"17", Block:"201", label:"GHS THALIGAI", Sch_type:"Government", Category:"High School"},
    { key:"11768", district:"17", Block:"201", label:"PUMS THINDAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11769", district:"17", Block:"201", label:"GHSS ERNAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11770", district:"17", Block:"201", label:"GHS KEERAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"11771", district:"17", Block:"201", label:"PUMS KEELSATHAMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11772", district:"17", Block:"201", label:"GHSS KONUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11773", district:"17", Block:"201", label:"The Navodaya Academy Senior Secondary School - CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11774", district:"17", Block:"201", label:"GHS THOTTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11775", district:"17", Block:"201", label:"DR.M.S. UDAYAMURTHY SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"High School"},
    { key:"11776", district:"17", Block:"201", label:"KURINJI MATRIC HIGHER SECONDARY SCHOOL KAVETTIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11777", district:"17", Block:"201", label:"KURINJI HSS KAVETTIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11778", district:"17", Block:"201", label:"GHS BODHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11779", district:"17", Block:"201", label:"KURINJI SENIOR SECONDARY SCHOOL KAVETTIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11780", district:"17", Block:"201", label:"GHS PERIYAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11781", district:"17", Block:"201", label:"THE SPECTRUM ACADEMY MATRICULATION SCHOOL, 6/258 TRICHY ROAD, NAMAKKAL DT -637001", Sch_type:"Un-aided", Category:"High School"},
    { key:"11782", district:"17", Block:"201", label:"JEIVIKASS HR.SEC SCHOOL, MOHANUR ROAD, NAMAKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11783", district:"17", Block:"201", label:"NATIONAL PUBLIC SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11784", district:"17", Block:"201", label:"PGP MATRICULATION HIGHER SECONDARY SCHOOL, PALANI NAGAR, VETTAMBADI, NAMAKKAL DT -637405", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11785", district:"17", Block:"201", label:"PGP INTERNATIONAL SCHOOL ,VETTAMBADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11786", district:"17", Block:"201", label:"GHS VETTAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"11787", district:"17", Block:"201", label:"PUMS CHINNAVEPPANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11788", district:"17", Block:"201", label:"CHRIST KING MATRICULATION HIGHER SECONDARY SCHOOL, NALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11789", district:"17", Block:"132", label:"GTR HSS SENGARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11790", district:"17", Block:"132", label:"KGBV NATHUKULIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"11791", district:"17", Block:"132", label:"GHS NATHUKULIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"11792", district:"17", Block:"132", label:"GHSS ARIYURPUDUVALAVU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11793", district:"17", Block:"132", label:"KGBV ARIYUR KILAKKUVALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"11794", district:"17", Block:"132", label:"MODEL SCHOOL, KOLLIHILLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11795", district:"17", Block:"132", label:"GTR HSS VALAVANTHINADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11796", district:"17", Block:"132", label:"KGBV SELURNADU", Sch_type:"Government", Category:"Middle School"},
    { key:"11797", district:"17", Block:"186", label:"GHSS PALAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11798", district:"17", Block:"186", label:"GHSS ANIYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11799", district:"17", Block:"186", label:"LITTLE ANGELS HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11800", district:"17", Block:"186", label:"THE SCSM MATRICULATION HIGHER SECONDARY SCHOOL. MOHANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11801", district:"17", Block:"186", label:"GOVT MODEL GIRLS HSS MOHANUR -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11802", district:"17", Block:"186", label:"GOVT  BOYS HSS MOHANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11803", district:"17", Block:"186", label:"VETRI VIDYALAYA MATRICULATION SCHOOL, ALAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"11804", district:"17", Block:"186", label:"GHS ORUVANDURPUDHUR", Sch_type:"Government", Category:"High School"},
    { key:"11805", district:"17", Block:"186", label:"GHS ANDAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11806", district:"17", Block:"186", label:"GHSS VALAYAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11807", district:"17", Block:"186", label:"GHSS  N.PUDUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11808", district:"17", Block:"66", label:"BHARATHI HIGHER SECONDARY SCHOOL REDDIPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11809", district:"17", Block:"66", label:"BHARATHI VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, REDDIPATTI, NAMAKKAL DT -637002", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11810", district:"17", Block:"66", label:"SRI MAHABHARATHI HIGHER SECONDARY SCHOOL, KOOLIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11811", district:"17", Block:"66", label:"GHSS MUTHUKAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11812", district:"17", Block:"66", label:"GHS BODINAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11813", district:"17", Block:"66", label:"GHSS PALAYAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11814", district:"17", Block:"66", label:"ADW HIGH SCH A.PALAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"11815", district:"17", Block:"66", label:"GHSS POTTIREDDIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11816", district:"17", Block:"66", label:"GHS ALANGANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"11817", district:"17", Block:"66", label:"GHS PAVITHRAMPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"11818", district:"17", Block:"66", label:"GHS PAVITHRAM", Sch_type:"Government", Category:"High School"},
    { key:"11819", district:"17", Block:"66", label:"GHSS VARAGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11820", district:"17", Block:"66", label:"PUMS DEVARAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11821", district:"17", Block:"66", label:"PUMS VARATHARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11822", district:"17", Block:"66", label:"OXFORD MATRICULATION HIGHER SECONDARY SCHOOL, KAIKATTI, ERUMAPATTI, NAMAKKAL DT -637021", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11823", district:"17", Block:"66", label:"GBHSS ERUMAPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11824", district:"17", Block:"66", label:"GGHSS ERUMAPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11825", district:"17", Block:"66", label:"GHSS KAVAKKARANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11826", district:"17", Block:"66", label:"GHS JAMBUMADAI", Sch_type:"Government", Category:"High School"},
    { key:"11827", district:"17", Block:"66", label:"GHSS SEVINTHIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11828", district:"17", Block:"285", label:"GGHSS SENDAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11829", district:"17", Block:"285", label:"VIVEKA MATRICULATION SCHOOL, PONNAMAPUDUR,SENDAMANGALAM, NAMAKKAL DT -637404", Sch_type:"Un-aided", Category:"High School"},
    { key:"11830", district:"17", Block:"285", label:"GGHSS KALAPPANAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11831", district:"17", Block:"285", label:"GBHSS KALAPPANAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11832", district:"17", Block:"285", label:"GHSS BELUKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11833", district:"17", Block:"285", label:"BRINDAVAN MATRICULATION HIGHER SECONDARY SCHOOL, THUTHIKULAM, NAMAKKAL DT-637404", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11834", district:"17", Block:"285", label:"GHS RAMANATHAPURAMPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"11835", district:"17", Block:"285", label:"GBHSS SENDAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11836", district:"17", Block:"285", label:"GHS POTTANAM", Sch_type:"Government", Category:"High School"},
    { key:"11837", district:"17", Block:"254", label:"GHSS ELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11838", district:"17", Block:"254", label:"RAINBOW MATRICULATION SCHOOL, PUDUCHATRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11839", district:"17", Block:"254", label:"GHSS PUDUCHATRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11840", district:"17", Block:"254", label:"ADW HR SEC SCHOOL, KALANGANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11841", district:"17", Block:"254", label:"GHSS KARAIKURICHIPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11842", district:"17", Block:"254", label:"GHSS PACHAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11843", district:"17", Block:"254", label:"GHSS THIRUMALAIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11844", district:"17", Block:"254", label:"GHSS SELLAPPAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11845", district:"17", Block:"254", label:"KAMARAJAR HIGHER SECONDARY SCHOOL BOMMAIKUTTAIMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11846", district:"17", Block:"267", label:"GOVERNMENT HIGHER SECONDARY SCHOOL-R.PATTANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11847", district:"17", Block:"267", label:"RASI INTERNATIONAL SCHOOL(CBSE), RASIPURAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11848", district:"17", Block:"267", label:"SHRI VIDHYA BHARATHI MODERN HR SEC(CBSE) SCHOOL,RASIPURAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11849", district:"17", Block:"267", label:"GHS CHANDRASEKARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11850", district:"17", Block:"267", label:"R.C.SACRED HEART AIDED HR.SEC.SCHOOL,RASIPURAM.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11851", district:"17", Block:"267", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ANNASALAI, RASIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11852", district:"17", Block:"267", label:"GHSS-SIVANANDA SALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11853", district:"17", Block:"267", label:"NATIONAL GIRLS HIGHER SECONDARY SCHOOL, RASIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11854", district:"17", Block:"267", label:"SRI VASAVI VIDYALAYA  MATRIC HR SEC SCHOOL,RASIPURAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11855", district:"17", Block:"267", label:"SRV GIRLS HIGHER SECONDARY SCHOOL,RASIPURAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11856", district:"17", Block:"267", label:"SRV BOYS HIGHER SECONDARY SCHOOL,RASIPURAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11857", district:"17", Block:"267", label:"SRV EXCEL MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11858", district:"17", Block:"267", label:"GOVT HIGH SCHOOL,KUNAVELAMPATTY PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"11859", district:"17", Block:"267", label:"SENGUNTHAR MAHAJANA GIRLS HIGH SCHOOL,GURUSAMIPALAYAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11860", district:"17", Block:"267", label:"SENGUNTHAR MAHAJANA GOVERNMENT AIDED HIGHER SECONDARY SCHOOL, GURUSAMIPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11861", district:"17", Block:"267", label:"NATIONAL MATRICULATION SCHOOL, SINGALANDAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11862", district:"17", Block:"267", label:"GHSS SINGALANTHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11863", district:"17", Block:"267", label:"GHSS VADUGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11864", district:"17", Block:"397", label:"GHS MINNAKKAL", Sch_type:"Government", Category:"High School"},
    { key:"11865", district:"17", Block:"397", label:"GHS NO-3 KOMARAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11866", district:"17", Block:"397", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ALAVAIPATTI VENNANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11867", district:"17", Block:"397", label:"GHSS (G)ALAVAIPATTI VENNANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11868", district:"17", Block:"397", label:"THILLAIYADI VALLIYAMMAI  AIDED GIRLS HIGH SCHOOL, VENNANDUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11869", district:"17", Block:"397", label:"SRI VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL, NACHIPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11870", district:"17", Block:"397", label:"GHS O.SOWDHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11871", district:"17", Block:"397", label:"KALAIMAGAL MATRICULATION SCHOOL, PALANTHINIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"11872", district:"17", Block:"397", label:"GHSS ATHANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11873", district:"17", Block:"397", label:"GHS ATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"11874", district:"17", Block:"397", label:"VETRI VHSS B KEERANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11875", district:"17", Block:"397", label:"VETRI VHSS G KEERANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11876", district:"17", Block:"397", label:"GANDHI KALVI NILAYAM HIGH SCHOOL, THENGALPALAYAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11877", district:"17", Block:"397", label:"GHSS R.PUDUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11878", district:"17", Block:"397", label:"SRV HITECH MATRIC HIGHER SECONDARY SCHOOL,MASAKALIPATTI, RASIPURAM TK, NAMAKKAL DT -637401", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11879", district:"17", Block:"200", label:"GHS R PUDUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11880", district:"17", Block:"200", label:"GHS THANNEERPANDALKADU", Sch_type:"Government", Category:"High School"},
    { key:"11881", district:"17", Block:"200", label:"GBHSS NAMAGIRIPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11882", district:"17", Block:"200", label:"GGHSS NAMAGIRIPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11883", district:"17", Block:"200", label:"GHSS THO JEDARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11884", district:"17", Block:"200", label:"GHS AYILPATTI", Sch_type:"Government", Category:"High School"},
    { key:"11885", district:"17", Block:"200", label:"GHSS UDAIYARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11886", district:"17", Block:"200", label:"SRI SWAMY MATRICULATION HIGHER SECONDARY SCHOOL, SAMBAPALIPUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11887", district:"17", Block:"200", label:"SRI RAM VINAYAKA SCHOOL, PILLIPPAKKUTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"11888", district:"17", Block:"200", label:"GTR (G) HSS MULLUKKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11889", district:"17", Block:"200", label:"GTR BOYS HR SEC SCHOOL  MULLUKKURCHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11890", district:"17", Block:"200", label:"GHSS THIMMANNAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11891", district:"17", Block:"200", label:"GHSS MANGALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11892", district:"17", Block:"360", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THANNEERPANDALPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11893", district:"17", Block:"360", label:"GOVERNMENT HIGH SCHOOL, ANANGOOR", Sch_type:"Government", Category:"High School"},
    { key:"11894", district:"17", Block:"360", label:"GOVERNMENT HIGH SCHOOL, PATLUR", Sch_type:"Government", Category:"High School"},
    { key:"11895", district:"17", Block:"360", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  ERAYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11896", district:"17", Block:"360", label:"GOVERNMENT HIGH SCHOOL, MOLASI", Sch_type:"Government", Category:"High School"},
    { key:"11897", district:"17", Block:"360", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VITTAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11898", district:"17", Block:"360", label:"A.S.S MATRICULATION HIGHER SECONDARY SCHOOL, KUCHIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11899", district:"17", Block:"360", label:"KSR AKSHARA ACADEMY  THOKKAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11900", district:"17", Block:"360", label:"GHSS DEVANANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11901", district:"17", Block:"360", label:"VETRI O.S.S. HIGH SCHOOL, ANANGOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"11902", district:"17", Block:"360", label:"GOVT GIRLS HIGHER SEC. SCHOOL, TIRUCHENGODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11903", district:"17", Block:"360", label:"GOVT BOYS HIGHER SECONDARY SCHOOL,TIRUCHENGODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11904", district:"17", Block:"360", label:"MAHADEVA VIDYALAYAM HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11905", district:"17", Block:"360", label:"SAINT ANTONY HIGHER SECONDARY SCHOOL, WARD 5", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"11906", district:"17", Block:"360", label:"NEW OXFORD MATRICULATION SCHOOL, KARATTUPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11907", district:"17", Block:"360", label:"SEVENTH-DAY ADVENTIST MATRICULATION HIGHER SECONDARY SCHOOL,TIRUCHENGODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"11908", district:"17", Block:"360", label:"HOLY ANGELS MATRIC HR.SEC.SCHOOL NEIKARAPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11909", district:"17", Block:"360", label:"VRIKSHA GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11910", district:"17", Block:"360", label:"VIDYAA VIKAS MATRIC HR. SEC. SCHOOL, VARAHOORAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11911", district:"17", Block:"360", label:"VIDYAA VIKAS GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11912", district:"17", Block:"360", label:"GOVT. HIGH SCHOOL, ANDIPALYAM", Sch_type:"Government", Category:"High School"},
    { key:"11913", district:"17", Block:"360", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, CHITTALANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11914", district:"17", Block:"360", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KOZHIKKALNATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11915", district:"17", Block:"360", label:"SPM HIGHER SECONDARY SCHOOL KOZHIKKALNATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11916", district:"17", Block:"360", label:"NALANDAS HIGHER SECONDARY SCHOOL, CHINNATHAMBIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11917", district:"17", Block:"224", label:"HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL KUMARAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11918", district:"17", Block:"224", label:"GHSS KULLANAICKANPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11919", district:"17", Block:"224", label:"GHSS(BOYS) KUMARAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11920", district:"17", Block:"224", label:"RELIANCE MATRIC HR SEC SCHOOL, KUPPANDAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11921", district:"17", Block:"224", label:"SSM LAKSHMIAMMAL MATRIC HSS, KUMARAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11922", district:"17", Block:"224", label:"POORNAMATHI MATRIC HIGHER SECONDARY SCHOOL, KUMARAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11923", district:"17", Block:"224", label:"Govt. Model Girls Hr.Sec. School, KUMARAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11924", district:"17", Block:"224", label:"JKK RANGAMMAL GIRLS HSS KUMARAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11925", district:"17", Block:"224", label:"ROYAL INTERNATIONAL  SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11926", district:"17", Block:"224", label:"MHS BUDHAR STREET", Sch_type:"Government", Category:"High School"},
    { key:"11927", district:"17", Block:"224", label:"SSM CENTRAL SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11928", district:"17", Block:"224", label:"PUMS V.METTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11929", district:"17", Block:"224", label:"EINSSTEIN MATRIC HIGHER SECONDARY SCHOOL, KATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11930", district:"17", Block:"224", label:"MUNIRAJAH MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11931", district:"17", Block:"224", label:"NEHRU MEMORIAL SAMPOORANIAMMAL HR SEC SCHOOL FOR DIFFERENTLY ABLED, ETHIRMEDU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11932", district:"17", Block:"224", label:"GHS VEMANKATTUVALASU", Sch_type:"Government", Category:"High School"},
    { key:"11933", district:"17", Block:"224", label:"GHS ARUVANKADU", Sch_type:"Government", Category:"High School"},
    { key:"11934", district:"17", Block:"224", label:"GHS KALIYANOOR", Sch_type:"Government", Category:"High School"},
    { key:"11935", district:"17", Block:"224", label:"RATHNA MATRICULATION SCHOOL PALLIPALAYAM AGRAHARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11936", district:"17", Block:"224", label:"GHSS(BOYS) PALLIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11937", district:"17", Block:"224", label:"GHS VEDIYARASAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11938", district:"17", Block:"224", label:"GHS MAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11939", district:"17", Block:"224", label:"GHSS VEPPADAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11940", district:"17", Block:"224", label:"VANI VIDYALAYA MATRIC HSS UPPUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11941", district:"17", Block:"224", label:"GHS E.PUDUPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11942", district:"17", Block:"224", label:"GHSS PALLAKKAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11943", district:"17", Block:"224", label:"GHSS SOWDHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11944", district:"17", Block:"224", label:"KRP MATRIC HSS PACHAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11945", district:"17", Block:"224", label:"SANKAR HIGHER SECONDARY SCHOOL SANKARI WEST", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11946", district:"17", Block:"224", label:"GHS PADAIVEEDU", Sch_type:"Government", Category:"High School"},
    { key:"11947", district:"17", Block:"224", label:"SPK MATRIC HSS KADACHANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11948", district:"17", Block:"224", label:"SRRI SPK PUBLIC SENIOR SECONDARY SCHOOL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11949", district:"17", Block:"224", label:"SPB  HIGH SCHOOL(AIDED)", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11950", district:"17", Block:"224", label:"VALLABHI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"11951", district:"17", Block:"224", label:"GHSS(GIRLS) PALLIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11952", district:"17", Block:"224", label:"GHS CAUVERY R S", Sch_type:"Government", Category:"High School"},
    { key:"11953", district:"17", Block:"224", label:"GHSS PAPPAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11954", district:"17", Block:"224", label:"GHSS KOKKARAYANPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11955", district:"17", Block:"165", label:"SHRI VINAYAGA HR SEC SCHOOL    PILLANATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11956", district:"17", Block:"165", label:"GHS  SAPPAYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"11957", district:"17", Block:"165", label:"GOVT GIRLS HR SEC SCHOOL MALLASAMUDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11958", district:"17", Block:"165", label:"GOVT BOYS HR SEC SCHOOL MALLASAMUTHRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11959", district:"17", Block:"165", label:"AKV PUBLIC SCHOOL, SENIOR SECONDARY - CBSE, SURIYAGOUNDAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11960", district:"17", Block:"165", label:"GOVERNMENT HIGH SCHOOL,KOTTAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11961", district:"17", Block:"165", label:"GOVERNMENT HR SEC SCHOOL VAIYAPPAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11962", district:"17", Block:"165", label:"GOVERNMENT HR SEC SCHOOL RAMAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11963", district:"17", Block:"63", label:"SENGUNTHAR MATRICULATION HIGHER SECONDARY SCHOOL, KOSAVAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11964", district:"17", Block:"63", label:"MAHENDRA MATRIC HIGHER SECONDARY SCHOOL, KUMARAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11965", district:"17", Block:"63", label:"PUMS SATHINAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11966", district:"17", Block:"63", label:"GHS KUPPANDAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11967", district:"17", Block:"63", label:"VIVEKANANDHA VIDHYA BHAVAN MATRIC HR.SEC.SCHOOL  ELAYAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11968", district:"17", Block:"63", label:"GHSS ULAGAPPAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11969", district:"17", Block:"63", label:"SHRI RENGA VIDYALAYA HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11970", district:"17", Block:"63", label:"T P P GHSS MANALI JEDARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11971", district:"17", Block:"63", label:"STAR MATRIC HIGHER SECONDARY SCHOOL, PERIYAMANALI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11972", district:"17", Block:"63", label:"GHSS ELACHIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11973", district:"17", Block:"63", label:"SHRI VIDHYABHARATHI HIGH SCHOOL SAKKARAMPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"11974", district:"17", Block:"63", label:"PUMS NALLAKUMARANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"11975", district:"17", Block:"63", label:"ST.THERASAS MIDDLE SCHOOL  ELANAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"11976", district:"17", Block:"63", label:"GHSS MANICKAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11977", district:"17", Block:"63", label:"GHS ILUPPILI", Sch_type:"Government", Category:"High School"},
    { key:"11978", district:"17", Block:"63", label:"PUMS KOKKALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"11979", district:"17", Block:"63", label:"GHSS PERIYAMANALI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11980", district:"17", Block:"63", label:"GHSS VELAGOUNDAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11981", district:"17", Block:"63", label:"KONGUNADU MATRIC HSS VELAGOUNDAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11982", district:"17", Block:"63", label:"GHSS BOMMAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11983", district:"17", Block:"232", label:"SKV HIGHER SECONDARY SCHOOL, M.KANDAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11984", district:"17", Block:"232", label:"VIB HIGHER SECONDARY SCHOOL, PEECHAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"11985", district:"17", Block:"232", label:"GHSS KANDAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11986", district:"17", Block:"232", label:"GHSS KARICHIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11987", district:"17", Block:"232", label:"GHS S.PUDUPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11988", district:"17", Block:"232", label:"GHS K.KOLANDHAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11989", district:"17", Block:"232", label:"GHS CHETTIYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11990", district:"17", Block:"232", label:"GHS KONDARASAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11991", district:"17", Block:"232", label:"GHS VILLIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"11992", district:"17", Block:"232", label:"PUMS PILLAIKALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"11993", district:"17", Block:"232", label:"SRI RAMAMOORTHI HIGH SCHOOL VASANTHAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"11994", district:"17", Block:"232", label:"GOVT GIRLS HR SEC SCHOOL, PARAMATHI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11995", district:"17", Block:"232", label:"GBHSS PARAMATHY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11996", district:"17", Block:"232", label:"GIRLS HIGHER SECONDARY SCHOOL (AIDED), VELUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11997", district:"17", Block:"232", label:"KANDASWAMI KANDAR'S HIGHER SECONDARY SCHOOL VELUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"11998", district:"17", Block:"85", label:"GHSS SOLASIRAMANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"11999", district:"17", Block:"85", label:"SREE AMMAN MATRICULATION HIGHER SECONDARY  SCHOOL, MARAPPAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12000", district:"17", Block:"85", label:"GHS NANJAPPAGOUNDAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12001", district:"17", Block:"85", label:"GHSS KABILARMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12002", district:"17", Block:"85", label:"VIVEKANANDA HIGHER SECONDARY SCHOOL PAUNDAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12003", district:"17", Block:"85", label:"RN OXFORD MATRICULATION SCHOOL, MUNIAGOUNDAMPALAYAM, NAMAKKAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12004", district:"17", Block:"85", label:"SUN STAR HR SEC SCHOOL, VADAKARAIATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12005", district:"17", Block:"85", label:"GHSS JEDARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12006", district:"17", Block:"85", label:"GHS ANANGUR", Sch_type:"Government", Category:"High School"},
    { key:"12007", district:"17", Block:"85", label:"GHSS PILIKKALPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12008", district:"17", Block:"85", label:"GHS KONDALAM", Sch_type:"Government", Category:"High School"},
    { key:"12009", district:"17", Block:"85", label:"GGHSS PANDAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12010", district:"17", Block:"85", label:"GBHSS PANDAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12011", district:"17", Block:"85", label:"GHSS POTHANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12012", district:"8", Block:"10", label:"MODEL HIGHER SECONDARY SCHOOL, MATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12013", district:"8", Block:"10", label:"GHS UMAREDDIYUR", Sch_type:"Government", Category:"High School"},
    { key:"12014", district:"8", Block:"10", label:"GHSS GURUVAREDDIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12015", district:"8", Block:"10", label:"PUMS PALAKUTTAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12016", district:"8", Block:"10", label:"GHS KURICHI", Sch_type:"Government", Category:"High School"},
    { key:"12017", district:"8", Block:"10", label:"ANMGHS MUHASIPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"12018", district:"8", Block:"10", label:"GHSS NERINJIPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12019", district:"8", Block:"10", label:"PUMS PATLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12020", district:"8", Block:"10", label:"S.E.T. HIGHER SECONDARY SCHOOL , POONACHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12021", district:"8", Block:"10", label:"ST.IGNATIUS HSS BUDAPADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12022", district:"8", Block:"10", label:"KASTHURBA GANDHI BALIKA VIDYALAYA GIRLS RESIDENTIAL SCHOOL,  KURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"12023", district:"8", Block:"10", label:"PUMS ANANTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12024", district:"8", Block:"10", label:"GHSS SINGAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12025", district:"8", Block:"10", label:"GHS KURUMBAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12026", district:"8", Block:"10", label:"GHSS VELLITHIRUPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12027", district:"8", Block:"10", label:"GHSS OLAGADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12028", district:"8", Block:"10", label:"GHS KUPPICHIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12029", district:"8", Block:"10", label:"GHSS CHENNAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12030", district:"8", Block:"10", label:"GHSS ALAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12031", district:"8", Block:"20", label:"GBHSS ANTHIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12032", district:"8", Block:"20", label:"GGHSS ANTHIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12033", district:"8", Block:"20", label:"KASTURIBA GANDHI BALIKA VIDYALAYA,  PALLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12034", district:"8", Block:"20", label:"GHS THAVITTUPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12035", district:"8", Block:"20", label:"GHSS ATHANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12036", district:"8", Block:"20", label:"GTRMS KUTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12037", district:"8", Block:"20", label:"GTRMS KATHIRIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12038", district:"8", Block:"20", label:"PUMS KADAIERATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12039", district:"8", Block:"20", label:"PUMS  SENGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12040", district:"8", Block:"20", label:"GTRHSS  BARGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12041", district:"8", Block:"20", label:"GHSS OOSUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12042", district:"8", Block:"20", label:"GHSS  DEVARMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12043", district:"8", Block:"20", label:"GHSS A.SEMBULICHAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12044", district:"8", Block:"20", label:"PUMS KATTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12045", district:"8", Block:"20", label:"PUMS A.PUDHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12046", district:"8", Block:"20", label:"GTRMS KINATHADISOZHAGA", Sch_type:"Government", Category:"Middle School"},
    { key:"12047", district:"8", Block:"20", label:"ST. MICHAEL'S HSS, MICHAELPALAYAM.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12048", district:"8", Block:"20", label:"GHS PUDHUKADU GANDHINAGAR", Sch_type:"Government", Category:"High School"},
    { key:"12049", district:"8", Block:"20", label:"GHSS MOONGILPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12050", district:"8", Block:"20", label:"ST.SEBASTIAN'S HIGH SCHOOL NAGALUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12051", district:"8", Block:"20", label:"MANGALAM HSS ANTHIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12052", district:"8", Block:"20", label:"GHS PALLIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12053", district:"8", Block:"20", label:"PUMS SANKARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12054", district:"8", Block:"20", label:"SAKTHI HSS NACHIMUTHUPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12055", district:"8", Block:"37", label:"GHS SALANGAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12056", district:"8", Block:"37", label:"GHS ALATHUR THATTARPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12057", district:"8", Block:"37", label:"IDEAL HSS PARUVACHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12058", district:"8", Block:"37", label:"ADHARSH VIDHYALAYA HSS PARUVACHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12059", district:"8", Block:"37", label:"NEW IDEAL HSS EERATTAIKARADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12060", district:"8", Block:"37", label:"GHS PUNNAM", Sch_type:"Government", Category:"High School"},
    { key:"12061", district:"8", Block:"37", label:"SVVB MATRIC HIGHER SECONDARY SCHOOL, SAKTHINAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12062", district:"8", Block:"37", label:"GHSS, A.PUDHUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12063", district:"8", Block:"37", label:"GHSS MYLAMBADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12064", district:"8", Block:"37", label:"GHS URATCHIKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"12065", district:"8", Block:"37", label:"GHS KUTTIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12066", district:"8", Block:"37", label:"GOVERNMENT VINOBHA HIGHER SECONDARY SCHOOL, THALAVAIPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12067", district:"8", Block:"37", label:"SMGHSS  ODATHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12068", district:"8", Block:"37", label:"GHSS PERIYAPULIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12069", district:"8", Block:"37", label:"GGHSS  KAVINDAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12070", district:"8", Block:"37", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  KAVINDAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12071", district:"8", Block:"37", label:"GHSS, AYYAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12072", district:"8", Block:"37", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, BHAVANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12073", district:"8", Block:"37", label:"GGHSS BHAVANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12074", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, ERANGATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12075", district:"8", Block:"38", label:"RC HIGH SCHOOL, VALIPALAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12076", district:"8", Block:"38", label:"GOVT.HIGHER SEC.SCHOOL,KAVILIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12077", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, MARAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12078", district:"8", Block:"38", label:"GOVT. HIGH SCHOOL, KARAPADI", Sch_type:"Government", Category:"High School"},
    { key:"12079", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUTHURASANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12080", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, THAIRPALLAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12081", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL, VENGANAICKANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12082", district:"8", Block:"38", label:"GOVT. HIGHER SEC.SCHOOL, BHAVANISAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12083", district:"8", Block:"38", label:"GOVT.HIGH SCHOOL, NALLUR", Sch_type:"Government", Category:"High School"},
    { key:"12084", district:"8", Block:"38", label:"GOVT. HIGHER SEC. SCHOOL, PANAYAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12085", district:"8", Block:"38", label:"KVK GOVT. BOYS HS SCHOOL, P.PULIAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12086", district:"8", Block:"38", label:"KOM GOVT. GIRLS HS SCHOOL, P.PULIAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12087", district:"8", Block:"38", label:"GOVT. HIGH SCHOOL, UTHANDIYUR", Sch_type:"Government", Category:"High School"},
    { key:"12088", district:"8", Block:"38", label:"PANCHAYAT UNION MIDDLE SCHOOL,GODEPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12089", district:"8", Block:"38", label:"GOVT. HIGHER SEC. SCHOOL, DODDAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12090", district:"8", Block:"45", label:"KOMARAPPA SENGUNTHAR GIRLS HS, CHENNIMALAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12091", district:"8", Block:"45", label:"GOVERNMENT HIGH SCHOOL,AMMAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12092", district:"8", Block:"45", label:"GOVERNMENT HIGH SCHOOL KAMARAJNAGAR", Sch_type:"Government", Category:"High School"},
    { key:"12093", district:"8", Block:"45", label:"GHS, AYYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12094", district:"8", Block:"45", label:"GHSS,VELLODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12095", district:"8", Block:"45", label:"KOMARAPPA SENGUNTHAR HSS, M.PIDARIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12096", district:"8", Block:"45", label:"GHS,MUGASIPIDARIYUR", Sch_type:"Government", Category:"High School"},
    { key:"12097", district:"8", Block:"45", label:"GHSS, ENGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12098", district:"8", Block:"45", label:"SHRI MAHAA HIGHER SECONDARY SCHOOL, INGUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12099", district:"8", Block:"45", label:"SHRI GANGA HIGHER SEC SCHOOL, INGUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12100", district:"8", Block:"45", label:"THE UNIQUE ACADEMY SCHOOL, INGUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12101", district:"8", Block:"45", label:"ST.XAVIER'S HIGH SCHOOL, VADUGAPALAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12102", district:"8", Block:"45", label:"GHSS, PASUVAPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12103", district:"8", Block:"45", label:"GHSS, THIPPAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12104", district:"32", Block:"57", label:"GHSS ALANGIAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12105", district:"32", Block:"57", label:"MMS CHITRAVUTHANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12106", district:"32", Block:"57", label:"PUMS S.KANGAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12107", district:"32", Block:"57", label:"PUMS CHIKKINAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12108", district:"32", Block:"57", label:"PUMS C.KUMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12109", district:"32", Block:"57", label:"GHS SELAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12110", district:"32", Block:"57", label:"PUMS D.KUMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12111", district:"32", Block:"57", label:"PUMS NARASINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12112", district:"32", Block:"57", label:"GHSS DHALAVAIPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12113", district:"32", Block:"57", label:"ST ANTONYS MIDDLE SCHOOL DHARAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12114", district:"32", Block:"57", label:"MMS OPS DHARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12115", district:"32", Block:"57", label:"GHSS DHARAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12116", district:"32", Block:"57", label:"ALOYSIUS GIRLS HSS DHARAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12117", district:"32", Block:"57", label:"CSI GIRLS HSS DHARAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12118", district:"32", Block:"57", label:"MMS VALAYAKARA STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"12119", district:"32", Block:"57", label:"NCP MHSS DHARAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12120", district:"32", Block:"57", label:"CSI BOYS HSS DHARAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12121", district:"32", Block:"57", label:"PONNU GIRLS HS DHARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"12122", district:"32", Block:"57", label:"THENMALAR HSS DHARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12123", district:"32", Block:"57", label:"SAKTHI MATRICULATION SCHOOL DHARAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"12124", district:"32", Block:"57", label:"PUMS PERIYAPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12125", district:"32", Block:"57", label:"PUMS GOVINDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12126", district:"32", Block:"57", label:"MSN HSS GOVINDAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12127", district:"32", Block:"57", label:"PUMS GANDHIJI NAGAR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"12128", district:"32", Block:"57", label:"PUMS KALIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12129", district:"32", Block:"57", label:"GHSS KOLATHUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12130", district:"32", Block:"57", label:"INDHIRA GANDHI MHSS , KOLATHUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12131", district:"32", Block:"57", label:"DR NALLINI HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12132", district:"32", Block:"57", label:"MUNICIPAL MIDDLE SCHOOL, KOLINJIWADI", Sch_type:"Government", Category:"Middle School"},
    { key:"12133", district:"32", Block:"57", label:"GHS THERPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12134", district:"32", Block:"57", label:"GHS NANJIYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12135", district:"32", Block:"57", label:"PUMS PONNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12136", district:"32", Block:"57", label:"PUMS THOPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12137", district:"32", Block:"57", label:"PUMS VEERACHIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12138", district:"8", Block:"65", label:"GHSS, CHITTODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12139", district:"8", Block:"65", label:"GGHSS, CHITHODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12140", district:"8", Block:"65", label:"SEVENTHDAY ADVENTIST MATRICULATION SCHOOL,CHITHODE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12141", district:"8", Block:"65", label:"SAKTHI HS, CHITTODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12142", district:"8", Block:"65", label:"GRACE HIGHER SECONDARY SCHOOL, ELAVAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12143", district:"8", Block:"65", label:"GHSS B..P. AGRAHARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12144", district:"8", Block:"65", label:"MATHARASA HITHAYATHUL ISLAM GIRLS HIGH SCHOOL, B.P. AGRAHARAM.", Sch_type:"Un-aided", Category:"High School"},
    { key:"12145", district:"8", Block:"65", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VEERAPPANCHATRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12146", district:"8", Block:"65", label:"GHS, CHINNASEMUR", Sch_type:"Government", Category:"High School"},
    { key:"12147", district:"8", Block:"65", label:"GHS, SENGODAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12148", district:"8", Block:"65", label:"GOVERNMENT HIGH SCHOOL FOR HEARING IMPAIRED, R.N. PUDUR,ERODE", Sch_type:"Government", Category:"High School"},
    { key:"12149", district:"8", Block:"65", label:"GHSS, RAMANATHAPURAM PUDHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12150", district:"8", Block:"65", label:"AHSS, BHARATHI KALVI NILAYAM, NASIYANUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12151", district:"8", Block:"65", label:"VELLALAR GIRLS HIGH SCHOOL , THINDAL-638012", Sch_type:"Un-aided", Category:"High School"},
    { key:"12152", district:"8", Block:"65", label:"THE ERODE ARIMA SOCIETY TRUST SPECIAL SCHOOL, SENGODAMPALAYAM", Sch_type:"Un-aided", Category:"Others"},
    { key:"12153", district:"8", Block:"65", label:"KONGU ARIVALAYAM,  MR-CHILDREN SCHOOL, SENGODAMPAL", Sch_type:"Un-aided", Category:"Others"},
    { key:"12154", district:"8", Block:"65", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THINDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12155", district:"8", Block:"65", label:"SREE AMMAN MATRICULATION HSS,EVK SAMPATH NAGAR, ERODE-11 ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12156", district:"8", Block:"65", label:"GHSS, NANJAPPA GOUNDAN VALASU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12157", district:"8", Block:"65", label:"KALAIMAGAL KALVI NILAYAM GIRLS HSS, ERODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12158", district:"8", Block:"65", label:"CHS, E.K.VALASU", Sch_type:"Government", Category:"High School"},
    { key:"12159", district:"8", Block:"65", label:"AHS, ST MARY'S, ERODE", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12160", district:"8", Block:"65", label:"AHS, ST.RITA GIRLS, RAILWAY COLONY", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12161", district:"8", Block:"65", label:"MEENAKSHI SUNDARANAR SENGUNTHAR HR SEC SCHOOL,RANGAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12162", district:"8", Block:"65", label:"DEIVANAI PERUMAL HS, MOLAGOUNDANPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"12163", district:"8", Block:"65", label:"GHSS, KASIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12164", district:"8", Block:"65", label:"SRI SAKTHI VIDHYA NIKETHAN MATRIC HSS,RANGAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12165", district:"8", Block:"65", label:"GHSS, KUMALANKUTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12166", district:"8", Block:"65", label:"NANDHI MATRICULATION HSS, CAUVERY STREET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12167", district:"8", Block:"65", label:"V.V.C.R.MURUGESANAR SENGUNTHAR GIRLS HR.SEC.SCHOOL,ERODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12168", district:"8", Block:"65", label:"SIDDHARTHA MATRICULATION HSS, THIRUNAGAR COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12169", district:"8", Block:"65", label:"MPL (GIRLS) KARUNGALPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12170", district:"8", Block:"65", label:"MRG GGHSS, VEERAPPANCHATRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12171", district:"8", Block:"65", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, ERODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12172", district:"8", Block:"65", label:"AHSS, C.S.I. GIRLS, ERODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12173", district:"8", Block:"65", label:"AHSS, C.S.I. BOYS, ERODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12174", district:"8", Block:"65", label:"AHSS, ATM, VAIRAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12175", district:"8", Block:"65", label:"CHSS, RAILWAY COLONY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12176", district:"8", Block:"65", label:"CHSS, KAMARAJ MUNICIPAL BOYS, KARUNGALPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12177", district:"8", Block:"65", label:"E.K.M. ABDUL GANI MATHARASA ISLAMIA HIGH SCHOOL,ERODE", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12178", district:"8", Block:"65", label:"MAHAJANA HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12179", district:"8", Block:"65", label:"THE SENGUNTHAR HIGHER SECONDARY SCHOOL, ERODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12180", district:"8", Block:"71", label:"GOVT. HIGHER SECONDARY SCHOOL, P.METTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12181", district:"8", Block:"71", label:"GOVT. HIGH SCHOOL, K.METTUPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12182", district:"8", Block:"71", label:"GANDHI KALVI NILAYAM HIGHER SECONDARY SCHOOL,KUGALUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12183", district:"8", Block:"71", label:"GOVT. HIGHER SECONDARY SCHOOL, POLAVAKKALIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12184", district:"8", Block:"71", label:"SRI VENKATESHWARA VIDHAYALAYAA HIGHER SEC. SCHOOL,", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12185", district:"8", Block:"71", label:"MUNICIPAL GIRLS HIGHER SECONDARY SCHOOL, GOBI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12186", district:"8", Block:"71", label:"GOVT. HIGH SCHOOL,  KULLAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12187", district:"8", Block:"71", label:"MUNICIPAL HIGHER SEC.SCHOOL, MODACHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12188", district:"8", Block:"71", label:"SARATHA HIGHER SECONDARY SCHOOL, MODACHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12189", district:"8", Block:"71", label:"PALANIAMMAL GIRLS HS SCHOOL, GOBI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12190", district:"8", Block:"71", label:"GHSS, KARATTADIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12191", district:"8", Block:"71", label:"GOVT. HIGH SCHOOL, KALINGIYAM", Sch_type:"Government", Category:"High School"},
    { key:"12192", district:"8", Block:"71", label:"SHREE GURUKULAM HIGHER SEC. SCHOOL, GOBI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12193", district:"8", Block:"71", label:"GOVT. HIGH SCHOOL, ALUKKULI", Sch_type:"Government", Category:"High School"},
    { key:"12194", district:"8", Block:"71", label:"GOVT. HIGH SCHOOL, MALLIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12195", district:"8", Block:"71", label:"C.S.I. MIDDLE SCHOOL, SIRUVALUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12196", district:"8", Block:"71", label:"GOVT. HIGHER SECONDARY SCHOOL, VELLANKOVIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12197", district:"8", Block:"71", label:"AMALA HIGH SCHOOL, GOBI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12198", district:"8", Block:"71", label:"DIAMOND JUBILEE HIGHER SECONDARY SCHOOL, GOBI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12199", district:"8", Block:"71", label:"MUNICIPAL BOYS HR. SEC. SCHOOL, GOBI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12200", district:"8", Block:"71", label:"VENGAMMAIYAR MH SCHOOL, GOBI", Sch_type:"Government", Category:"High School"},
    { key:"12201", district:"8", Block:"71", label:"ST PAULS MATRIC HIGHER SECONDARY SCHOOL,  PATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12202", district:"8", Block:"71", label:"ST. MARY'S HIGH SCHOOL, KOLAPPALUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12203", district:"8", Block:"71", label:"THAI TAMIL HIGH SCHOOL, KOLAPPALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12204", district:"32", Block:"104", label:"GHS NEIKARANPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12205", district:"32", Block:"104", label:"PUMS MARUDURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12206", district:"32", Block:"104", label:"T A M M GHSS NATHAKKADAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12207", district:"32", Block:"104", label:"GHSS PADIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12208", district:"32", Block:"104", label:"PUMS VARATHAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12209", district:"32", Block:"104", label:"PUMS PARANJERVAZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"12210", district:"32", Block:"104", label:"PUMS AVINASIPALAYAMPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12211", district:"32", Block:"104", label:"PUMS POTHIYAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12212", district:"32", Block:"104", label:"PUMS SIVANMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12213", district:"32", Block:"104", label:"CARMEL GIRLS HIGHER SECONDARY SCHOOL KANGAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12214", district:"32", Block:"104", label:"GHSS KANGAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12215", district:"32", Block:"104", label:"PUMS VEERANAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12216", district:"8", Block:"128", label:"GHS,KARAVALASU", Sch_type:"Government", Category:"High School"},
    { key:"12217", district:"8", Block:"128", label:"GHS,NAGAPPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12218", district:"8", Block:"128", label:"GHSS,SALAIPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12219", district:"8", Block:"128", label:"GHSS,THAMARAIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12220", district:"8", Block:"128", label:"GHSS,PASUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12221", district:"8", Block:"128", label:"GHSS,THANDAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12222", district:"8", Block:"128", label:"SSV AIDED GIRLS HIGH SCHOOL,KODUMUDI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12223", district:"8", Block:"128", label:"SSV AIDED HIGHER SECONDARY SCHOOL,KODUMUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12224", district:"8", Block:"128", label:"GGHSS,SIVAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12225", district:"8", Block:"128", label:"GBHSS,SIVAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12226", district:"8", Block:"128", label:"GHSS,VELLOTTAMPARAPPU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12227", district:"8", Block:"128", label:"GHSS,UNJALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12228", district:"32", Block:"145", label:"GHS BELLAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12229", district:"32", Block:"145", label:"GHS KETHALREV", Sch_type:"Government", Category:"High School"},
    { key:"12230", district:"32", Block:"145", label:"PUMS ERAGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12231", district:"32", Block:"145", label:"GHS SANKARANDAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12232", district:"32", Block:"145", label:"AHSS SANTHINIKETHAN HSS KULLAMPALAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12233", district:"32", Block:"145", label:"GHS NALLIMADAM", Sch_type:"Government", Category:"High School"},
    { key:"12234", district:"32", Block:"145", label:"PUMS KANGAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12235", district:"32", Block:"145", label:"GHSS KUNDADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12236", district:"32", Block:"145", label:"VMCDV HSS THAYAMPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12237", district:"32", Block:"145", label:"KGBV THONDAMUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12238", district:"8", Block:"185", label:"GBHSS, MODAKKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12239", district:"8", Block:"185", label:"GGHSS, MODAKKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12240", district:"8", Block:"185", label:"SWAMY MATRIC HSS,MODAKKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12241", district:"8", Block:"185", label:"SHRI VETHATHIRI VIDHYALAYA Met. SCHOOL,SAMYNATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12242", district:"8", Block:"185", label:"GHSS,ELUMATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12243", district:"8", Block:"185", label:"PUMS,ANANTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12244", district:"8", Block:"185", label:"GV ACADEMY,ELUMATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12245", district:"8", Block:"185", label:"PUMS,KARUNDEVANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12246", district:"8", Block:"185", label:"PUMS,KALATHUMINNAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12247", district:"8", Block:"185", label:"GHSS,GANAPATHIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12248", district:"8", Block:"185", label:"PUMS, NAGARATCHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12249", district:"8", Block:"185", label:"PUMS, MUTHUGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12250", district:"8", Block:"185", label:"KURINCHI VENTHAN KALVI NILAYAM, CHINNYAMPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12251", district:"8", Block:"185", label:"PRASANTHI VIDYA BHAVAN MATRIC SCHOOL, CHINNYAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12252", district:"8", Block:"185", label:"PUMS , MANNATHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12253", district:"8", Block:"185", label:"GHSS, MUGASI ANUMANPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12254", district:"8", Block:"185", label:"GHSS,ARACHALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12255", district:"8", Block:"185", label:"PUMS, ANAIKALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12256", district:"8", Block:"185", label:"GHSS,LAKKAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12257", district:"8", Block:"185", label:"PUMS,POONDURAISEMUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12258", district:"8", Block:"185", label:"PUMS,KILIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12259", district:"8", Block:"185", label:"PUMS,ELAIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12260", district:"8", Block:"185", label:"PUMS,NATHAGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12261", district:"8", Block:"185", label:"GHS,KASPAPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"12262", district:"8", Block:"185", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, AVAL POONDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12263", district:"8", Block:"185", label:"KASTHURBAGRAM HIGH SCHOOL, KASTHURBAGRAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12264", district:"8", Block:"185", label:"GHSS,MINNAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12265", district:"8", Block:"185", label:"GHS,VADUGAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12266", district:"8", Block:"185", label:"GMS,VILAKETHI", Sch_type:"Government", Category:"Middle School"},
    { key:"12267", district:"8", Block:"185", label:"SARVA SEVA MIDDLE SCHOOL, VINOBHAGRAMAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12268", district:"32", Block:"190", label:"PUMS ERASINAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12269", district:"32", Block:"190", label:"GHS N.C.G.VALASU", Sch_type:"Government", Category:"High School"},
    { key:"12270", district:"32", Block:"190", label:"GHSS VADUGAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12271", district:"32", Block:"190", label:"GHSS KANNIVAADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12272", district:"32", Block:"190", label:"GHS SESAIYANPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12273", district:"32", Block:"190", label:"GHSS MOOLANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12274", district:"32", Block:"190", label:"VANJIAMMAN  MATRIC MULANUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12275", district:"32", Block:"190", label:"KGBV MULANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12276", district:"32", Block:"190", label:"MODEL HSS MOOLANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12277", district:"32", Block:"190", label:"GHSS ELUGAMVALASU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12278", district:"32", Block:"190", label:"GHS NANJAITHALAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"12279", district:"32", Block:"190", label:"GHS PERAMIUM", Sch_type:"Government", Category:"High School"},
    { key:"12280", district:"32", Block:"190", label:"GHS CHINNAKKAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12281", district:"8", Block:"202", label:"GOVT. HIGH SCHOOL, VETTAIYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12282", district:"8", Block:"202", label:"GOVT. HIGHER SECONDARY SCHOOL, SAVAKATTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12283", district:"8", Block:"202", label:"GHSS, KADATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12284", district:"8", Block:"202", label:"GOVT. HIGH SCHOOL, P.KARATTUPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12285", district:"8", Block:"202", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KOODAKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12286", district:"8", Block:"202", label:"GOVT. HIGHER SEC. SCHOOL, KURUMANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12287", district:"8", Block:"202", label:"GOVT. BOYS HIGHER SEC. SCHOOL, NAMBIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12288", district:"8", Block:"202", label:"GOVT. GIRLS HIGHER SEC. SCHOOL, NAMBIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12289", district:"8", Block:"202", label:"KAMARAJ HIGHER SEC. SCHOOL, NAMBIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12290", district:"8", Block:"202", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MALAYAPPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12291", district:"8", Block:"202", label:"GOVT. MODEL HIGHER SEC.SCHOOL, GETTICHEVIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12292", district:"8", Block:"202", label:"KASTURIBA GANDHI BALIKA VIDYALAYA, SOKKUMARIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12293", district:"8", Block:"202", label:"GOVT. HIGHER SEC. SCHOOL, VEMANDAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12294", district:"8", Block:"202", label:"GOVT. HIGH SCHOOL, SEMMAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12295", district:"8", Block:"202", label:"MODEL HIGHER SEC. SCHOOL, PATTIMANIYAKARANPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12296", district:"8", Block:"244", label:"GHSS,VIJAYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12297", district:"8", Block:"244", label:"MAHIDHAR ACADEMY HIGH SCHOOL,NANDAGIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12298", district:"8", Block:"244", label:"GHSS,NALLAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12299", district:"8", Block:"244", label:"GHS, THIRUVACHI", Sch_type:"Government", Category:"High School"},
    { key:"12300", district:"8", Block:"244", label:"MPD GOVERNMENT HIGHER SECONDARY SCHOOL,THINGALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12301", district:"8", Block:"244", label:"GHS,KAIKOLAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12302", district:"8", Block:"244", label:"GHS,PANDIYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12303", district:"8", Block:"244", label:"GHSS,THUDUPATHY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12304", district:"8", Block:"244", label:"GGHSS, PERUNDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12305", district:"8", Block:"244", label:"GHSS KANJIKOVIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12306", district:"8", Block:"244", label:"GHS,PETHAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12307", district:"8", Block:"244", label:"GBHSS,PERUNDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12308", district:"8", Block:"244", label:"GHS,THOPPUPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12309", district:"8", Block:"244", label:"GMHSS,SEENAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12310", district:"8", Block:"281", label:"GOVERNMENT HIGH SCHOOL, VEDARNAGAR,PUTHUVADAVALLI", Sch_type:"Government", Category:"High School"},
    { key:"12311", district:"8", Block:"281", label:"KASTHURIBA HIGHER SECONDARY SCHOOL, RAJANNAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12312", district:"8", Block:"281", label:"GOVERNMENT HIGH SCHOOL , CHIKKARASAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12313", district:"8", Block:"281", label:"GOVT. HIGH SCHOOL, INDIYAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12314", district:"8", Block:"281", label:"GOVERNMENT HIGH SCHOOL, MAKKINANKOMBAI", Sch_type:"Government", Category:"High School"},
    { key:"12315", district:"8", Block:"281", label:"GOVT. HIGH SCHOOL, IKKARAINEGAMAM", Sch_type:"Government", Category:"High School"},
    { key:"12316", district:"8", Block:"281", label:"KASTURIBA GANDHI BALIKA VIDHYALAYA, VEDARNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12317", district:"8", Block:"281", label:"BSC, GOVERNMENT HIGHER SECONDARY SCHOOL, KEMBANAICKENPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12318", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, MALLIAMMANDURGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12319", district:"8", Block:"281", label:"GTRMS, BATHRIBADUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"12320", district:"8", Block:"281", label:"GOVT. HIGHER SEC. SCHOOL, BASUVANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12321", district:"8", Block:"281", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KALKADAMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12322", district:"8", Block:"281", label:"ST.JOHN DE BRITTO GIRLS HIGH SCHOOL, SATHIYAMANGALAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12323", district:"8", Block:"281", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SATHYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12324", district:"8", Block:"281", label:"GOVERNMENT GIRLS MODEL HIGHER SECONDARY SCHOOL, SATHYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12325", district:"8", Block:"281", label:"MUNICIPAL HIGH SCHOOL, RANGASAMUDHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"12326", district:"8", Block:"281", label:"SRI RAGAVENDRA HR SEC. SCHOOL, SATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12327", district:"8", Block:"281", label:"GOVERNMENT HIGH SCHOOL, MILLMEDU", Sch_type:"Government", Category:"High School"},
    { key:"12328", district:"8", Block:"310", label:"GHS GERMALAM", Sch_type:"Government", Category:"High School"},
    { key:"12329", district:"8", Block:"310", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGHER SECONDARY SCHOOL HASANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12330", district:"8", Block:"310", label:"GTRHS GEDDESAL", Sch_type:"Government", Category:"High School"},
    { key:"12331", district:"8", Block:"310", label:"GTRMS BEJELATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12332", district:"8", Block:"310", label:"GTRHS THALAMALAI", Sch_type:"Government", Category:"High School"},
    { key:"12333", district:"8", Block:"310", label:"PANCHAYAT UNION MIDDLE SCHOOL, THALAVADY", Sch_type:"Government", Category:"Middle School"},
    { key:"12334", district:"8", Block:"310", label:"GHSS THALAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12335", district:"8", Block:"310", label:"GHS KADATTI", Sch_type:"Government", Category:"High School"},
    { key:"12336", district:"8", Block:"310", label:"PUMS DODDAKAJANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12337", district:"8", Block:"310", label:"DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL, DODDAGAJANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12338", district:"8", Block:"310", label:"GHS THIGANARAI", Sch_type:"Government", Category:"High School"},
    { key:"12339", district:"8", Block:"310", label:"KASTURIBA GANDHI BALIKA VIDYALAYA, KUNNANPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12340", district:"8", Block:"310", label:"GHS CHIKKAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"12341", district:"8", Block:"310", label:"GHSS KOTTAMALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12342", district:"8", Block:"310", label:"PUMS ARALAVADY", Sch_type:"Government", Category:"Middle School"},
    { key:"12343", district:"8", Block:"310", label:"GOVERNMENT TRIBAL WELFARE MIDDLE SCHOOL, KANAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12344", district:"8", Block:"310", label:"ST JOSEPH'S HIGHER SECONDARY SCHOOL, SOOSAIPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12345", district:"8", Block:"310", label:"ST ANN'S HIGH SCHOOL THIGANARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12346", district:"8", Block:"310", label:"GHSS PANAGAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12347", district:"8", Block:"356", label:"JOHN ARTHUR HS,GANDHI NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12348", district:"8", Block:"356", label:"KASTURIBA GANDHI BALIKA VIDYALAYA, NANJAIPULIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12349", district:"8", Block:"356", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KONGARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12350", district:"8", Block:"356", label:"GOVT, HIGHER SEC. SCHOOL, KALLIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12351", district:"8", Block:"356", label:"GOVT. HIGH SCHOOL, VALAYAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12352", district:"8", Block:"356", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12353", district:"8", Block:"356", label:"GOVT.HIGHER SEC.SCHOOL, BUNGALOWPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12354", district:"8", Block:"356", label:"GOVERNMENT. HIGHER SECONDARY. SCHOOL, KASIPALAYAM(GOBI)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12355", district:"8", Block:"356", label:"GOVT. GIRLS HIGHER SEC SCHOOL, T.N.PALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12356", district:"8", Block:"356", label:"SRI PARIYUR AMMAN HIGHER SEC. SCHOOL, T.N.PALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12357", district:"8", Block:"356", label:"GOVT. HIGHER SEC, SCHOOL, D.G.PUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12358", district:"8", Block:"356", label:"ST. FRANCIS XAVIER HR SEC SCHOOL, PERIYA KODIVERY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12359", district:"32", Block:"378", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, UTHUKULI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12360", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, SENAPATHI CHETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12361", district:"32", Block:"378", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VELLIRAVELI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12362", district:"32", Block:"378", label:"GHS SARKAR PERIYAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12363", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, NALLIGOUNDANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12364", district:"32", Block:"378", label:"SMGHSS SURIYAPPAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12365", district:"32", Block:"378", label:"PANCHAYAT UNION MIDDLE SCHOOL, PAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12366", district:"32", Block:"378", label:"GHSS SARAVANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12367", district:"32", Block:"392", label:"P.U.M.S, LKC NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12368", district:"32", Block:"392", label:"P.U.M.S,VELLAKOVIL-WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"12369", district:"32", Block:"392", label:"A.A. GOVT.HR.SEC. SCHOOL,  VELLAKOVIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12370", district:"32", Block:"392", label:"PUNITHA  AMALA ANNAI  GIRLS HIGH SCHOOL (Aided)", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12371", district:"32", Block:"392", label:"P.U.M.S,SORIYANKINATHU PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12372", district:"32", Block:"392", label:"G.H.S, DURAI RAMASAMY NAGAR, VELLAKOVIL", Sch_type:"Government", Category:"High School"},
    { key:"12373", district:"32", Block:"392", label:"P.U.M.S,V.GANAPATHI PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12374", district:"32", Block:"392", label:"VIVEKANANDA VIDYALAYA MATRIC  HR.SEC.SCH, MUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12375", district:"32", Block:"392", label:"GHSS, MUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12376", district:"32", Block:"392", label:"P.U.M.S, MUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12377", district:"32", Block:"392", label:"P.U.M.S,OODAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12378", district:"32", Block:"392", label:"GOVT.HIGH.SCHOOL. OLAPPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12379", district:"32", Block:"392", label:"P.U.M.S, VEERACHOLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12380", district:"32", Block:"392", label:"GHS LAKKAMANAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12381", district:"32", Block:"392", label:"P.U.M.S,KAMBALIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12382", district:"32", Block:"392", label:"P.U.M.S,M.VELAYUTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12383", district:"32", Block:"392", label:"GOVT.HIGH.SCHOOL, MANGALAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12384", district:"32", Block:"392", label:"P.U.M.S,NADUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12385", district:"32", Block:"392", label:"GHSS, PUDHUPPAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12386", district:"32", Block:"392", label:"GHSS, UTHAMAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12387", district:"32", Block:"392", label:"P.U.M.S,VELAPPANAYAKAN VALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"12388", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL,  ADASHOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12389", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL, ODAIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"12390", district:"26", Block:"370", label:"ADI DRAVIDAR WELFARE MIDDLE SCHOOL,  DODDABETTA", Sch_type:"Government", Category:"Middle School"},
    { key:"12391", district:"26", Block:"370", label:"HPF HS INDUNAGAR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12392", district:"26", Block:"370", label:"ST. ANTONY'S HIGH SCHOOL, LOVEDALE, OOTY", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12393", district:"26", Block:"370", label:"KENDRIYA VIDYALAYA , INDUNAGAR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"12394", district:"26", Block:"370", label:"UNIQUE PUBLIC MATRICULATION SCHOOL, FERNHILL, OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12395", district:"26", Block:"370", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,  NANJANAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12396", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KILCOWHATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12397", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELCOWHATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12398", district:"26", Block:"370", label:"THAKKARBAPA GURUKULAM AIDED MIDDLE SCHOOL NIRGACIMUND", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12399", district:"26", Block:"370", label:"FRANCIS XAVIER AIDED MIDDLE SCHOOL, KURUTHUKULI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12400", district:"26", Block:"370", label:"BRAESIDE  MATRICULATION SCHOOL, NANJANAD", Sch_type:"Un-aided", Category:"High School"},
    { key:"12401", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL SHOLADA", Sch_type:"Government", Category:"Middle School"},
    { key:"12402", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL,  HULLATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"12403", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL, EKONI", Sch_type:"Government", Category:"High School"},
    { key:"12404", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL, CHINNA COONOOR", Sch_type:"Government", Category:"High School"},
    { key:"12405", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL, KADANADU", Sch_type:"Government", Category:"High School"},
    { key:"12406", district:"26", Block:"370", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, DHAVANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12407", district:"26", Block:"370", label:"SATHYA SAI  MATRICULATION HR.SEC.SCHOOL, AGALAR, THUNERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12408", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL, THORAIHATTY", Sch_type:"Government", Category:"High School"},
    { key:"12409", district:"26", Block:"370", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  ANIKORAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12410", district:"26", Block:"370", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, THUNERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12411", district:"26", Block:"370", label:"GURUKULAM MATRICULATION SCHOOL, AGALAR, THUNERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12412", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL, EBBANAD", Sch_type:"Government", Category:"High School"},
    { key:"12413", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL, KENTHORAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12414", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL, T. MANIHATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12415", district:"26", Block:"370", label:"PANCHAYAT UNION MIDDLE SCHOOL, BACKIANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12416", district:"26", Block:"370", label:"HOLY ANGEL MATRICULATION SCHOOL, THUMMANATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"12417", district:"26", Block:"370", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THUMMANATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12418", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL, BILLICOMBAI", Sch_type:"Government", Category:"High School"},
    { key:"12419", district:"26", Block:"370", label:"SRI GIRI EESWARA MATRICULATION SCHOOL KAGGUCHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12420", district:"26", Block:"370", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,  KAGGUCHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12421", district:"26", Block:"370", label:"MAHATMA GANDHI MATRIC.HR.SEC.SCHOOL, KAGGUCHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12422", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL, KOOKALTHORAI", Sch_type:"Government", Category:"High School"},
    { key:"12423", district:"26", Block:"370", label:"PUMS, B. MANIHATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12424", district:"26", Block:"370", label:"PUMS, DEVARSHOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12425", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL, KILOOR KOKKALADA", Sch_type:"Government", Category:"High School"},
    { key:"12426", district:"26", Block:"370", label:"GOVERNMENT HIGH SCHOOL,  NUNTHALA", Sch_type:"Government", Category:"High School"},
    { key:"12427", district:"26", Block:"370", label:"GHSS KANNERIMANTHANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12428", district:"26", Block:"370", label:"GHSS KATHADIMATTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12429", district:"26", Block:"370", label:"SIVASAILAM CHAMRAJ HR.SEC. SCHOOL, CHAMRAJ ESTATE", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12430", district:"26", Block:"370", label:"GHSS KINNAKORAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12431", district:"26", Block:"370", label:"PUMS , MELKUNDAH", Sch_type:"Government", Category:"Middle School"},
    { key:"12432", district:"26", Block:"370", label:"GHS MULLIMALAI", Sch_type:"Government", Category:"High School"},
    { key:"12433", district:"26", Block:"370", label:"PUMS ANNANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12434", district:"26", Block:"370", label:"PUMS, MULLIGOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"12435", district:"26", Block:"370", label:"ADW MS, THAKKARBABA NAGAR, EMERALD", Sch_type:"Government", Category:"Middle School"},
    { key:"12436", district:"26", Block:"370", label:"GHS KALLAKORAI", Sch_type:"Government", Category:"High School"},
    { key:"12437", district:"26", Block:"370", label:"GHSS EMERALD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12438", district:"26", Block:"370", label:"LALITHA MATRIC HS ITHALAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12439", district:"26", Block:"370", label:"GHSS ITHALAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12440", district:"26", Block:"370", label:"NEW ERA MATRICULATION SCHOOL OOTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12441", district:"26", Block:"370", label:"MMS, RAMAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12442", district:"26", Block:"370", label:"MMS,THALAIYATTUMUND", Sch_type:"Government", Category:"Middle School"},
    { key:"12443", district:"26", Block:"370", label:"SACRED HEART MS, BANDHISHOLA", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12444", district:"26", Block:"370", label:"CSI,  HOBART", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12445", district:"26", Block:"370", label:"GHS FOR HEARING IMPAIRED OOTY", Sch_type:"Government", Category:"High School"},
    { key:"12446", district:"26", Block:"370", label:"MHS KODAPPAMUND", Sch_type:"Government", Category:"High School"},
    { key:"12447", district:"26", Block:"370", label:"ST.THERESAS HS FINGER POST OOTY", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12448", district:"26", Block:"370", label:"NAZARETH CONVENT HIGH SCHOOL AND JUNIOR COLLEGE, OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12449", district:"26", Block:"370", label:"WOODSIDE SCHOOL OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12450", district:"26", Block:"370", label:"CHIRISTUS REX SR.SEC SCHOOL, OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12451", district:"26", Block:"370", label:"BREEKS ALL INDIA SECONDARY SCHOOL OOTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"12452", district:"26", Block:"370", label:"GOVERNMENT HIGHER SECONDARY SCHOOL UDHAGAMANDALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12453", district:"26", Block:"370", label:"SRI SHANTHI VIJAI GIRLS HSS -OOTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12454", district:"26", Block:"370", label:"SACRED HEARTS HSS,OOTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12455", district:"26", Block:"370", label:"BETHLEHEM (G) HSS OOTY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12456", district:"26", Block:"370", label:"CSIGELL MEM (G)HSS OOTY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12457", district:"26", Block:"370", label:"BREEKS MEMORIAL ANGLO  INDIAN HSS, OOTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12458", district:"26", Block:"370", label:"ST.HILDAS (G) HSS OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12459", district:"26", Block:"370", label:"JSS PUBLIC SCHOOL, OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12460", district:"26", Block:"370", label:"GOOD SHEPHERED PUBLIC SCHOOL, OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12461", district:"26", Block:"370", label:"HEBRON SCHOOL OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12462", district:"26", Block:"370", label:"NIRMALA MATRICULATION SCHOOL, FINGERPOST", Sch_type:"Un-aided", Category:"High School"},
    { key:"12463", district:"26", Block:"370", label:"MPL, (URUDU) MIDDLE SCHOOL GANDHINAGAR II", Sch_type:"Government", Category:"Middle School"},
    { key:"12464", district:"26", Block:"370", label:"ST.JOSEPHS HSS OOTY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12465", district:"26", Block:"370", label:"CSI (CMM) HR. SEC. SCHOOL, OOTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12466", district:"26", Block:"370", label:"CRESENT CASTLE MATRIC HR.SCHOOL OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12467", district:"26", Block:"370", label:"SRVS MATRICULATION SCHOOL,GREEN FIELD, OOTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"12468", district:"26", Block:"370", label:"CRESCENT CASTLE PUBLIC SCHOOL,OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12469", district:"26", Block:"370", label:"PUMS, PYKARA", Sch_type:"Government", Category:"Middle School"},
    { key:"12470", district:"26", Block:"370", label:"PUMS, T.R. BAZAAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12471", district:"26", Block:"370", label:"ADW GHS HOOKER", Sch_type:"Government", Category:"High School"},
    { key:"12472", district:"26", Block:"370", label:"GHS NADUVATTAM", Sch_type:"Government", Category:"High School"},
    { key:"12473", district:"26", Block:"370", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SHOLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12474", district:"26", Block:"370", label:"PUMS, GLENMORGAN CAMP", Sch_type:"Government", Category:"Middle School"},
    { key:"12475", district:"26", Block:"370", label:"PUMS,  KILKUNDAH", Sch_type:"Government", Category:"Middle School"},
    { key:"12476", district:"26", Block:"370", label:"GOVERNMENT (GIRLS) HIGH SCHOOL MANJOOR ", Sch_type:"Government", Category:"High School"},
    { key:"12477", district:"26", Block:"370", label:"ALPHONSA SCHOOL, MANJOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12478", district:"26", Block:"370", label:"GHSS MANJOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12479", district:"26", Block:"370", label:"KUNDHA MATRIC SCHOOL,KOTRAKANDI, MANJOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12480", district:"26", Block:"370", label:"GHS BIKKATTY", Sch_type:"Government", Category:"High School"},
    { key:"12481", district:"26", Block:"370", label:"GHSS YEDAKAD                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12482", district:"26", Block:"53", label:"PUMS BETTATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12483", district:"26", Block:"53", label:"PUMS ELITHORAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12484", district:"26", Block:"53", label:"GHSS YEDAPPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12485", district:"26", Block:"53", label:"PUMS HALAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12486", district:"26", Block:"53", label:"PUMS THUTHURMATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12487", district:"26", Block:"53", label:"PUMS MELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12488", district:"26", Block:"53", label:"PUMS ARIHATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12489", district:"26", Block:"53", label:"CSI MIDDLE SCHOOL, KOLACOMBAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12490", district:"26", Block:"53", label:"GHS THUTHURMATTAM", Sch_type:"Government", Category:"High School"},
    { key:"12491", district:"26", Block:"53", label:"GHS MANJACOMBAI", Sch_type:"Government", Category:"High School"},
    { key:"12492", district:"26", Block:"53", label:"GHSS MELUR HOSATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12493", district:"26", Block:"53", label:"GOVERNMENT (GIRLS) HIGH SCHOOL, HUBBATHALAI", Sch_type:"Government", Category:"High School"},
    { key:"12494", district:"26", Block:"53", label:"GOVERNMENT HIGHER SECONDARY SCHOOL HUBBATHALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12495", district:"26", Block:"53", label:"SRI KALAIMAGAL MATRICULATION SCHOOL, SOGATHTHORAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12496", district:"26", Block:"53", label:"REACH MATRIC HSS COONOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12497", district:"26", Block:"53", label:"MUNICIPAL MIDDLE SCHOOL BANDISHOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12498", district:"26", Block:"53", label:"MMS ATTADI", Sch_type:"Government", Category:"Middle School"},
    { key:"12499", district:"26", Block:"53", label:"MMS GANDHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12500", district:"26", Block:"53", label:"CSI MS COONOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12501", district:"26", Block:"53", label:"ST.JOSEPHS MATRICULATION SCHOOL, ALWARPET, COONOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12502", district:"26", Block:"53", label:"SRI SARGURU TRIBAL HS, COONOOR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12503", district:"26", Block:"53", label:"MOUNTAIN HOME HSS COONOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12504", district:"26", Block:"53", label:"ARIGNAR ANNA GHSS,COONOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12505", district:"26", Block:"53", label:"ST.ANTONY'S HR. SEC. SCHOOL, COONOOR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12506", district:"26", Block:"53", label:"ST.MARYS (G) HSS COONOOR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12507", district:"26", Block:"53", label:"SRI SHANTHI VIJAI GIRLS HR SEC SCHOOL COONOOR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12508", district:"26", Block:"53", label:"STANES ANGLO INDIAN HSS, COONOOR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12509", district:"26", Block:"53", label:"ST JOSEPHS BOYS ANGLO INDIAN  HSS, COONOOR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12510", district:"26", Block:"53", label:"ST JOSEPHS CONVENT ANGLO INDIAN GIRLS HSS, COONOOR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12511", district:"26", Block:"53", label:"PUMS GURRENCY", Sch_type:"Government", Category:"Middle School"},
    { key:"12512", district:"26", Block:"53", label:"BRINDAVAN PUBLIC HSS WELLINGTON", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12513", district:"26", Block:"53", label:"ST.JOSEPH AMS WELLINGTON COONOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12514", district:"26", Block:"53", label:"CANTONMENT BOARD HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12515", district:"26", Block:"53", label:"ST. JOSEPHS BOYS HIGH SCHOOL, WELLINGTON BARRACKS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12516", district:"26", Block:"53", label:"HOLY INNOCENTS HS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12517", district:"26", Block:"53", label:"KENDRIYA VIDYALAYA HSS WELLINGTON", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"12518", district:"26", Block:"53", label:"ST. ANN'S GIRLS HR. SEC. SCHOOL, ARUVANKADU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12519", district:"26", Block:"53", label:"MRS. BULLMORE'S MATRICULATION SCHOOL, MOUNT PLEASANT,  COONOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12520", district:"26", Block:"53", label:"ARMY HSS WELLINGTON", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12521", district:"26", Block:"53", label:"CANTONMENT BOARD SECONDARY SCHOOL, CHINNA VANDISHOLAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12522", district:"26", Block:"53", label:"PUMS SOGATHORAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12523", district:"26", Block:"53", label:"PUMS DENALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12524", district:"26", Block:"53", label:"GHS YELLANALLI", Sch_type:"Government", Category:"High School"},
    { key:"12525", district:"26", Block:"53", label:"ST.MICHAEL'S HS, SHANTHOOOR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12526", district:"26", Block:"53", label:"LAIDLAW MEM HSS KETTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12527", district:"26", Block:"53", label:"CSI HIGHER SECONDARY SCHOOL, KETTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12528", district:"26", Block:"53", label:"THE LAWRENCE SCHOOL, LOVEDALE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12529", district:"26", Block:"53", label:"GHS HULICAL", Sch_type:"Government", Category:"High School"},
    { key:"12530", district:"26", Block:"53", label:"SIRUMALAR HIGH SCHOOL, SELAS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12531", district:"26", Block:"53", label:"CSI HIGH SCHOOL, NONSUCH", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12532", district:"26", Block:"53", label:"PUMS KODERI", Sch_type:"Government", Category:"Middle School"},
    { key:"12533", district:"26", Block:"53", label:"PUMS THAMBATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12534", district:"26", Block:"53", label:"PUMS KOLLIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12535", district:"26", Block:"53", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ADHIGARATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12536", district:"26", Block:"53", label:"N S IYAH MEMORIAL HSS-KATARY DAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12537", district:"26", Block:"53", label:"SRI KNM MATRICULATION SCHOOL, KETTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12538", district:"26", Block:"53", label:"PUMS JEGATHALA", Sch_type:"Government", Category:"Middle School"},
    { key:"12539", district:"26", Block:"53", label:"KENDRIYA VIDYALAYA HSS ARUVANKADU", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"12540", district:"26", Block:"53", label:"DEFENCE EMPLOYEES MATRICULATION SCHOOL, ARUVANKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"12541", district:"26", Block:"53", label:"CF HSS ARUVANKADU", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"12542", district:"26", Block:"135", label:"GTR MS HALLIMOYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12543", district:"26", Block:"135", label:"GHS THENGUMARAHADA", Sch_type:"Government", Category:"High School"},
    { key:"12544", district:"26", Block:"135", label:"PUMS, KERADAMATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12545", district:"26", Block:"135", label:"GHS, MILIDHANE", Sch_type:"Government", Category:"High School"},
    { key:"12546", district:"26", Block:"135", label:"GHSS NEDUGULA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12547", district:"26", Block:"135", label:"HILLFORT MATRICULATION & HIGHER SECONDARY SCHOOL, KEIRCOMBAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12548", district:"26", Block:"135", label:"GOVERNMENT HIGH SCHOOL, KAIRCOMBAI", Sch_type:"Government", Category:"High School"},
    { key:"12549", district:"26", Block:"135", label:"PUMS KADASOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12550", district:"26", Block:"135", label:"GHS SHOLURMATTAM", Sch_type:"Government", Category:"High School"},
    { key:"12551", district:"26", Block:"135", label:"ST.ANTONY'S MATRICULATION SCHOOL, SHOLURMATTAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"12552", district:"26", Block:"135", label:"GHSS, KIL-KOTAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12553", district:"26", Block:"135", label:"GOVERNMENT TRIBAL RESIDENTIAL  HIGH SCHOOL, KARIKKAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"12554", district:"26", Block:"135", label:"GHS, KENGARAI", Sch_type:"Government", Category:"High School"},
    { key:"12555", district:"26", Block:"135", label:"GHS KONAVAKORAI", Sch_type:"Government", Category:"High School"},
    { key:"12556", district:"26", Block:"135", label:"PUMS, GUNDADA", Sch_type:"Government", Category:"Middle School"},
    { key:"12557", district:"26", Block:"135", label:"GHS, KATTABETU", Sch_type:"Government", Category:"High School"},
    { key:"12558", district:"26", Block:"135", label:"PUMS, SACKATHA", Sch_type:"Government", Category:"Middle School"},
    { key:"12559", district:"26", Block:"135", label:"GTR MS, ARAVENU", Sch_type:"Government", Category:"Middle School"},
    { key:"12560", district:"26", Block:"135", label:"GHS, KOTTACOMBAI", Sch_type:"Government", Category:"High School"},
    { key:"12561", district:"26", Block:"135", label:"GHS ARAVENU", Sch_type:"Government", Category:"High School"},
    { key:"12562", district:"26", Block:"135", label:"GTR HSS KUNJAPPANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12563", district:"26", Block:"135", label:"PUMS, HORASHOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12564", district:"26", Block:"135", label:"ST.ANTONY'S MS, KOTAGIRI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12565", district:"26", Block:"135", label:"NALLAYAN MS, KOTAGIRI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12566", district:"26", Block:"135", label:"GHS, KANNERIMUKU", Sch_type:"Government", Category:"High School"},
    { key:"12567", district:"26", Block:"135", label:"ST.MARY'S HS, KOTAGIRI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12568", district:"26", Block:"135", label:"VISWASHANTHI VIDYALAYA MATRIC SCHOOL, KOTAGIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12569", district:"26", Block:"135", label:"GHSS KOTAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12570", district:"26", Block:"135", label:"CSI HSS KOTAGIRI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12571", district:"26", Block:"135", label:"ST.MARYS GIRLS HSS KOTAGIRI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12572", district:"26", Block:"135", label:"ST.JUDES PUBLIC SCHOOL, KOTAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12573", district:"26", Block:"135", label:"KOTAGIRI PUBLIC SCHOOL, KOTAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12574", district:"26", Block:"135", label:"PANDIARAJ MEMORIAL MATRIC HIGHER SECONDARY SCHOOL, KOTAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12575", district:"26", Block:"135", label:"GREEN VALLEY MATRICULATION SCHOOL KOTAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12576", district:"26", Block:"135", label:"ST.MARY'S HOME MAT.SCHOOL, KOTAGIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12577", district:"26", Block:"135", label:"RIVERSIDE PUBLIC SCHOOL, KOTAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12578", district:"26", Block:"135", label:"SATHIYAKAATHI GLOBAL SCHOOL, KOTAGIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12579", district:"26", Block:"72", label:"GTRMS MUKKATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12580", district:"26", Block:"72", label:"PUMS MAYFIELD", Sch_type:"Government", Category:"Middle School"},
    { key:"12581", district:"26", Block:"72", label:"PUMS VILLANGORE", Sch_type:"Government", Category:"Middle School"},
    { key:"12582", district:"26", Block:"72", label:"PUMS MUTHIRAKOLLI", Sch_type:"Government", Category:"Middle School"},
    { key:"12583", district:"26", Block:"72", label:"PUMS PATTAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"12584", district:"26", Block:"72", label:"PUMS PEARL DIVISION", Sch_type:"Government", Category:"Middle School"},
    { key:"12585", district:"26", Block:"72", label:"GOVERNMENT HIGH SCHOOL, PAKKANA", Sch_type:"Government", Category:"High School"},
    { key:"12586", district:"26", Block:"72", label:"GHS NELAKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"12587", district:"26", Block:"72", label:"GHSS BITHERKAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12588", district:"26", Block:"72", label:"GHS KUNTHALADY", Sch_type:"Government", Category:"High School"},
    { key:"12589", district:"26", Block:"72", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGHER SECONDARY SCHOOL KARGUDI                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12590", district:"26", Block:"72", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SREEMADURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12591", district:"26", Block:"72", label:"ST SEBASTIAN MAT HS,MANVAYAL, SREEMADURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12592", district:"26", Block:"72", label:"PUMS MARADI", Sch_type:"Government", Category:"Middle School"},
    { key:"12593", district:"26", Block:"72", label:"GOVERNMENT HIGH SCHOOL MANNATHIVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"12594", district:"26", Block:"72", label:"PUMS KAKKUNDI", Sch_type:"Government", Category:"Middle School"},
    { key:"12595", district:"26", Block:"72", label:"GOVERNMENT (GIRLS) HIGH SCHOOL ERUMAD", Sch_type:"Government", Category:"High School"},
    { key:"12596", district:"26", Block:"72", label:"GHS KAYYUNNI", Sch_type:"Government", Category:"High School"},
    { key:"12597", district:"26", Block:"72", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,  ERUMAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12598", district:"26", Block:"72", label:"GTRMS AYYANKOLLY", Sch_type:"Government", Category:"Middle School"},
    { key:"12599", district:"26", Block:"72", label:"GHSS AMBALAMOOLA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12600", district:"26", Block:"72", label:"GHS AMBALAVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"12601", district:"26", Block:"72", label:"PUMS CHERAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"12602", district:"26", Block:"72", label:"PUMS NAICKENSHOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12603", district:"26", Block:"72", label:"PUMS KARAKOLLY", Sch_type:"Government", Category:"Middle School"},
    { key:"12604", district:"26", Block:"72", label:"GTRMS KAPPALA", Sch_type:"Government", Category:"Middle School"},
    { key:"12605", district:"26", Block:"72", label:"GHSS CHERAMBADY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12606", district:"26", Block:"72", label:"GHSS KOLAPALLY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12607", district:"26", Block:"72", label:"PUMS CHERANGODE", Sch_type:"Government", Category:"Middle School"},
    { key:"12608", district:"26", Block:"72", label:"PUMS MAYOR", Sch_type:"Government", Category:"Middle School"},
    { key:"12609", district:"26", Block:"72", label:"GTRMS MAVANALLA", Sch_type:"Government", Category:"Middle School"},
    { key:"12610", district:"26", Block:"72", label:"GRG MEMORIAL HR SEC SCHOOL, VAZHAITHOTTAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12611", district:"26", Block:"72", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MASINAGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12612", district:"26", Block:"72", label:"SRI SHANTHI VIJAI HSS MASINAGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12613", district:"26", Block:"72", label:"PUMS KOZHIPALAM                 ", Sch_type:"Government", Category:"Middle School"},
    { key:"12614", district:"26", Block:"72", label:"PUMS NANTHATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12615", district:"26", Block:"72", label:"PUMS ATHIPALI", Sch_type:"Government", Category:"Middle School"},
    { key:"12616", district:"26", Block:"72", label:"PUMS FIRSTMILE GUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12617", district:"26", Block:"72", label:"GOVERNMENT HIGH SCHOOL, PUTHURVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"12618", district:"26", Block:"72", label:"PUMS VANDIPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12619", district:"26", Block:"72", label:"GTRMS THORAPALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"12620", district:"26", Block:"72", label:"ST. MARY'S HIGH SCHOOL UPPER GUDALUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12621", district:"26", Block:"72", label:"SRI RAMAKRISHNA MATRIC HS GUDALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12622", district:"26", Block:"72", label:"GTMO HR SEC SCHOOL GUDALUR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12623", district:"26", Block:"72", label:"MORNING STAR HSS GUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12624", district:"26", Block:"72", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL GUDALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12625", district:"26", Block:"72", label:"FATIMA GIRLS HIGHER SECONDARY SCHOOL , GUDALUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12626", district:"26", Block:"72", label:"ST THOMAS HSS MT NAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12627", district:"26", Block:"72", label:"BHARATH COMMUNITY MATRIC HSS GUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12628", district:"26", Block:"72", label:"KALAIVANI MAT HSS GUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12629", district:"26", Block:"72", label:"GTMO MATRIC HSS CHEMBALA, GUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12630", district:"26", Block:"72", label:"ST THOMAS ENG HS GUDALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12631", district:"26", Block:"72", label:"BRINDAVAN MATRICULATION SCHOOL, ATHIPALY", Sch_type:"Un-aided", Category:"High School"},
    { key:"12632", district:"26", Block:"72", label:" Netaji Subhash Chandra Bose Avasiya Vidyalaya- Kozhikolly", Sch_type:"Government", Category:"Middle School"},
    { key:"12633", district:"26", Block:"72", label:"PUMS BHARATHINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12634", district:"26", Block:"72", label:"PUMS GANDHI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12635", district:"26", Block:"72", label:"GOVERNMENT HIGH SCHOOL CHOONDY", Sch_type:"Government", Category:"High School"},
    { key:"12636", district:"26", Block:"72", label:"PUMS PERIASHOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12637", district:"26", Block:"72", label:"GOVERNMENT HIGH SCHOOL DHARMAGIRI", Sch_type:"Government", Category:"High School"},
    { key:"12638", district:"26", Block:"72", label:"PUMS NEWHOPE", Sch_type:"Government", Category:"Middle School"},
    { key:"12639", district:"26", Block:"72", label:"GHSS OVALLEY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12640", district:"26", Block:"72", label:"NOORUL HUDHA HS YELLAMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12641", district:"26", Block:"72", label:"GHS AROTTUPARAI", Sch_type:"Government", Category:"High School"},
    { key:"12642", district:"26", Block:"72", label:"PUMS DEVAN 2", Sch_type:"Government", Category:"Middle School"},
    { key:"12643", district:"26", Block:"72", label:"GHSS DEVARSHOLA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12644", district:"26", Block:"72", label:"AL HIDHAYA MATRIC SCHOOL, OTTUVAYAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12645", district:"26", Block:"72", label:"AL-AMEEN MATRICULATION SCHOOL,  DEVARSHOLAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12646", district:"26", Block:"72", label:"GHS KOTTAMEDU", Sch_type:"Government", Category:"High School"},
    { key:"12647", district:"26", Block:"72", label:"GTRMS PONNENI", Sch_type:"Government", Category:"Middle School"},
    { key:"12648", district:"26", Block:"72", label:"GOVERNMENT HIGH SCHOOL PULIYAMBARA", Sch_type:"Government", Category:"High School"},
    { key:"12649", district:"26", Block:"72", label:"PUMS UPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12650", district:"26", Block:"72", label:"GHS ATHIKUNNA", Sch_type:"Government", Category:"High School"},
    { key:"12651", district:"26", Block:"72", label:"BHARATH MATHA HSS UPPATTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12652", district:"26", Block:"72", label:"ST FRANCIS XAVIER(G) HS PANDALUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12653", district:"26", Block:"72", label:"GHSS PANDALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12654", district:"26", Block:"72", label:"GHS PADANTHORAI", Sch_type:"Government", Category:"High School"},
    { key:"12655", district:"26", Block:"72", label:"CSI HIGH SCHOOL, PADANTHORAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12656", district:"26", Block:"72", label:"PUMS DEVALA", Sch_type:"Government", Category:"Middle School"},
    { key:"12657", district:"26", Block:"72", label:"PUMS DEVALAHATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12658", district:"26", Block:"72", label:"PUMS VAZHAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"12659", district:"26", Block:"72", label:"GHSS DEVALA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12660", district:"26", Block:"72", label:"HOLY CROSS CONVENT MATRIC DEVALA", Sch_type:"Un-aided", Category:"High School"},
    { key:"12661", district:"4", Block:"52", label:"LISIEUX MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12662", district:"4", Block:"52", label:"T.A. RAMALINGAM CHETTIAR AIDED HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12663", district:"4", Block:"52", label:"SRI AVINASHILINGAM GIRLS HR.SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12664", district:"4", Block:"52", label:"CORPORATION HIGH SCHOOL RAMALINGAM COLONY", Sch_type:"Government", Category:"High School"},
    { key:"12665", district:"4", Block:"52", label:"ANGAPPA EDUCATIONAL TRUST SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12666", district:"4", Block:"52", label:"BISHOP OUBAGARAM PARTIALLY AIDED HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12667", district:"4", Block:"52", label:"CORPORATION GIRLS HIGHER SEC. SCHOOL R.S. PURAM WEST", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12668", district:"4", Block:"52", label:"CORPORATION HIGHER SECONDARY SCHOOL R.S. PURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12669", district:"4", Block:"52", label:"CORPORATION HIGH SCHOOL FOR DEAF,  R.S.PURAM", Sch_type:"Government", Category:"High School"},
    { key:"12670", district:"4", Block:"52", label:"ASHIRWAAD SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12671", district:"4", Block:"52", label:"CORPORATION SRP AMMANIAMMAL GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12672", district:"4", Block:"52", label:"COIMBATORE SEVANILAYAM GIRLS AIDED HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12673", district:"4", Block:"52", label:"DEVANGA AIDED HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12674", district:"4", Block:"52", label:"SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12675", district:"4", Block:"52", label:"CMS PAPPANAICKEN PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12676", district:"4", Block:"52", label:"MANI AIDED HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12677", district:"4", Block:"52", label:"Y.W.C.A. MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12678", district:"4", Block:"52", label:"CORPORATION GIRLS HIGH SCHOOL PULIAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"12679", district:"4", Block:"52", label:"ST ANTONYS HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12680", district:"4", Block:"52", label:"AIR FORCE SCHOOL,COIMBATORE", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"12681", district:"4", Block:"52", label:"VIDHYA NIKETAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12682", district:"4", Block:"52", label:"KRISHNASAMY NAGAR GANGA NAGAR MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12683", district:"4", Block:"52", label:"CORPORATION GIRLS HIGHER SEC SCHOOL RAMAKRISHNAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12684", district:"4", Block:"52", label:"CORPORATION HR SEC SCHOOL, RAMANATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12685", district:"4", Block:"52", label:"ST MARY'S GIRLS HR.SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12686", district:"4", Block:"52", label:"C S I MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12687", district:"4", Block:"52", label:"R.K.SREERANGAMMAL KALVI NILAYAM HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12688", district:"4", Block:"52", label:"STANES ANGLO INDIAN HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12689", district:"4", Block:"52", label:"C.S.I GIRLS AIDED HIGHER SECONDARY SCHOOL AVINASHI ROAD", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12690", district:"4", Block:"52", label:"G.D.MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12691", district:"4", Block:"52", label:"CORPORATION HIGH SCHOOL ANUPPARPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12692", district:"4", Block:"52", label:"THE SUBURBAN HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12693", district:"4", Block:"52", label:"CGHSS RANGANATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12694", district:"4", Block:"52", label:"CORPORATION GIRLS HIGH SCHOOL SIDHAPUDHUR", Sch_type:"Government", Category:"High School"},
    { key:"12695", district:"4", Block:"52", label:"CORPORATION HIGHER SECONDARY SCHOOL SIDHAPUDHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12696", district:"4", Block:"52", label:"ST.MARY'S HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12697", district:"4", Block:"52", label:"BRINDISI MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12698", district:"4", Block:"52", label:"CORPORATION HIGHER SECONDARY SCHOOL, NORTH COIMBATORE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12699", district:"4", Block:"52", label:"SHREE BALDEVDAS KIKANI VIDYAMANDIR MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12700", district:"4", Block:"52", label:"SHREE BALDEVDAS KIKANI VIDYAMANDIR AIDED HIGHER SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12701", district:"4", Block:"52", label:"KIKANI VIDHYA MANDIR SENIOR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12702", district:"4", Block:"52", label:"CORPORATION GIRLS HIGHER SECONDARY SCHOOL OPPANAKARA STREET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12703", district:"4", Block:"52", label:"PRESENTATION CONVENT GIRLS HR.SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12704", district:"4", Block:"52", label:"CSI BOYS HR.SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12705", district:"4", Block:"52", label:"CITY CORPORATION HIGHER SECONDARY SCHOOL, V.H. ROAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12706", district:"4", Block:"52", label:"ST.MICHAEL'S HR.SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12707", district:"4", Block:"52", label:"MANBAUL ULOOM Hr SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12708", district:"4", Block:"52", label:"CORPORATION GIRLS HIGH SCOOL SELVAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"12709", district:"4", Block:"52", label:"CORPORATION HIGHER SECONDARY SCHOOL SELVAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12710", district:"4", Block:"52", label:"CORPORATION HIGHER SECONDARY SCHOOL OKKILIYAR COLONY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12711", district:"4", Block:"52", label:"CORPORATION MIDDLE SCHOOL  BB STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"12712", district:"4", Block:"52", label:"MARANNA GOWDER HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12713", district:"4", Block:"52", label:"CCMA GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, RAJA STREET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12714", district:"4", Block:"52", label:"MARANNA GOWDER MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12715", district:"4", Block:"52", label:"VEERASAMY MUDALIAR AIDED HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12716", district:"4", Block:"52", label:"ATHAR JAMATH AIDED HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12717", district:"4", Block:"156", label:"GHS -VAZHUKKUPARAI", Sch_type:"Government", Category:"High School"},
    { key:"12718", district:"4", Block:"156", label:"PUMS-PACHAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12719", district:"4", Block:"156", label:"UNIT MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12720", district:"4", Block:"156", label:"PUMS-PALATHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12721", district:"4", Block:"156", label:"GOVERNMENT HIGHER SECONDARY SCHOOL OTHAKKALMANDAPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12722", district:"4", Block:"156", label:"GHSS MALUMICHAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12723", district:"4", Block:"156", label:"GHSJJ NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"12724", district:"4", Block:"156", label:"PUMS-SAMATHUVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12725", district:"4", Block:"156", label:"PUMS-OKKILIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12726", district:"4", Block:"156", label:"SREE SAKTHI MATRIC HR SECSCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12727", district:"4", Block:"156", label:"GHSS -CHETTIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12728", district:"4", Block:"156", label:"ZION MODEL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12729", district:"4", Block:"156", label:"GLOBAL PATHWAYS MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12730", district:"4", Block:"156", label:"AVATAR PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12731", district:"4", Block:"156", label:"PUMS-BODIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12732", district:"4", Block:"156", label:"RAVEL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12733", district:"4", Block:"156", label:"VEDANTA ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12734", district:"4", Block:"156", label:"PUMS-IDAYARPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12735", district:"4", Block:"156", label:"ELGI MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12736", district:"4", Block:"156", label:"GHSS-VELLALORE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12737", district:"4", Block:"156", label:"NIRMALA MATHA CONVENT MATRIC HR SEC SCHOOL, VELLALORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12738", district:"4", Block:"156", label:"NACHIMUTHU GOUNDER RUKMANI AMMAL HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12739", district:"4", Block:"156", label:"LINGAN VIDYALAYAM MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"12740", district:"4", Block:"156", label:"ACC MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12741", district:"4", Block:"156", label:"PUMS-ARIVOLI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12742", district:"4", Block:"156", label:"GHS-KURUMBAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12743", district:"4", Block:"156", label:"GHSS MADUKKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12744", district:"4", Block:"156", label:"GHS MARAPPALAM", Sch_type:"Government", Category:"High School"},
    { key:"12745", district:"4", Block:"156", label:"PUMS-ETTIMADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12746", district:"4", Block:"156", label:"WESTERN GHATS INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12747", district:"4", Block:"156", label:"GTRHS MAVUTHAMPATHI", Sch_type:"Government", Category:"High School"},
    { key:"12748", district:"4", Block:"156", label:"PUMS RANGASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12749", district:"4", Block:"156", label:"PUMS CHINNANAVAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12750", district:"4", Block:"156", label:"GHSS-PICHANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12751", district:"4", Block:"156", label:"PUMS-THIRUMALAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12752", district:"4", Block:"156", label:"SRI L.DHARMMA KANNAN VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12753", district:"4", Block:"156", label:"GHS KUMITIPATHI", Sch_type:"Government", Category:"High School"},
    { key:"12754", district:"4", Block:"242", label:"GTRMS PALAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12755", district:"4", Block:"242", label:"GOVERNMENT HIGHER SECONDARY SCHOOL NAICKENPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12756", district:"4", Block:"242", label:"PUMS SELVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12757", district:"4", Block:"242", label:"TAT KALANILAYAM AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12758", district:"4", Block:"242", label:"SRI K.RANGASAMY NAIDU HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12759", district:"4", Block:"242", label:"PIONEER MILLS HIGHER SECONDARY SCHOOL JOTHIPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12760", district:"4", Block:"242", label:"SRI RAMAKRISHNA MISSION VIDYALAYA HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12761", district:"4", Block:"242", label:"GKD MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12762", district:"4", Block:"242", label:"SRI RAMAKRISHNA MISSION VIDYALAYA SWAMI SHIVANANDA HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12763", district:"4", Block:"242", label:"PUMS VINOBAVE ARIVOLINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12764", district:"4", Block:"242", label:"GOVT HIGH SCHOOL NO 4 VEERAPANDI", Sch_type:"Government", Category:"High School"},
    { key:"12765", district:"4", Block:"242", label:"THAMBU HIGHER SECONDARY SCHOOL PRESSCOLONY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12766", district:"4", Block:"242", label:"GHS NARASIMMANAICKENPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12767", district:"4", Block:"242", label:"GGHS ASOKAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"12768", district:"4", Block:"242", label:"GHSS ASOKAPURAM -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12769", district:"4", Block:"242", label:"DR.DASARATHAN INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12770", district:"4", Block:"242", label:"ST JOHN BOSCO MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12771", district:"4", Block:"242", label:"V.C.SUBAIA MEENAKSHIAMMA MATRIC HR. SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12772", district:"4", Block:"242", label:"THAI LAXMI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12773", district:"4", Block:"242", label:"VIDYA VIKASINI OPPORTUNITY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12774", district:"4", Block:"242", label:"GHSS PANNIMADAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12775", district:"4", Block:"242", label:"GHS KANUVAI", Sch_type:"Government", Category:"High School"},
    { key:"12776", district:"4", Block:"242", label:"JAYAM FOUNDATION MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12777", district:"4", Block:"242", label:"GGHSS CHINNATHADAGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12778", district:"4", Block:"242", label:"GBHSS CHINNATHADAGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12779", district:"4", Block:"242", label:"PUMS DHOOMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12780", district:"4", Block:"242", label:"GTRHSS ANAIKATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12781", district:"4", Block:"242", label:"CHAVARA VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12782", district:"4", Block:"242", label:"THE PSBB MILLENNIUM SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12783", district:"4", Block:"242", label:"SRI SATHYA SAAI VIDHYA NIKETAN MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12784", district:"4", Block:"242", label:"GHSS SUNDAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12785", district:"4", Block:"242", label:"HOLY ANGELS MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12786", district:"4", Block:"242", label:"GHSS 49,KAVUNDAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12787", district:"4", Block:"242", label:"IRCS  ( HI ) SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12788", district:"4", Block:"242", label:"WVS SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12789", district:"4", Block:"242", label:"NAIRS VIDHYA MANDHIR (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"12790", district:"4", Block:"245", label:"RAILWAY HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12791", district:"4", Block:"245", label:"PUMS GANESAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12792", district:"4", Block:"245", label:"GHSS KURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12793", district:"4", Block:"245", label:"NIRMALAMATHA CONVENT MAT HR SEC SCHOOL, KUNIAMUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12794", district:"4", Block:"245", label:"SRI VISWESWARA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12795", district:"4", Block:"245", label:"V.S.SENGOTTAIAH MEMORIAL AIDED HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12796", district:"4", Block:"245", label:"GREEN GARDEN MATRICULATION SCHOOL , KURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"12797", district:"4", Block:"245", label:"MOUNT CARMEL MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12798", district:"4", Block:"245", label:"GHS KAMARAJ NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"12799", district:"4", Block:"245", label:"SETHU VIDYALAYA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12800", district:"4", Block:"245", label:"GHSS SUNDAKKAMUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12801", district:"4", Block:"245", label:"THAVATHIRU SANDHALINGA ADIKALAR AIDED HIGHER SECONDARY SCHOOL, PERUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12802", district:"4", Block:"245", label:"GHSS KUNIYAMUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12803", district:"4", Block:"245", label:"GHSS KULATHUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12804", district:"4", Block:"245", label:"V.L.B JANAKIAMMAL AIDED GIRLS HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12805", district:"4", Block:"245", label:"AIDED HIGH SCHOOL CBM SAKUNDHALA VIDHYALAYA", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12806", district:"4", Block:"245", label:"RKV Senior Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12807", district:"4", Block:"245", label:"SARASWATHI RAMACHANDRAN VIDYALAYA MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12808", district:"4", Block:"245", label:"NS MATRICULATION HR SEC SCHOOL, KUNIYAMUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12809", district:"4", Block:"245", label:"AMRIT CENTRE FOR SPECIAL NEEDS", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12810", district:"4", Block:"245", label:"V.V.VANI VIDYALAYA MATRIC.HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12811", district:"4", Block:"245", label:"SHIRUSHTI VIDHYALAYA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12812", district:"4", Block:"245", label:"SFV GHSS SEERANAICKENPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12813", district:"4", Block:"245", label:"CHS PAPPANAICKENPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"12814", district:"4", Block:"245", label:"CHS KOVILMEDU", Sch_type:"Government", Category:"High School"},
    { key:"12815", district:"4", Block:"245", label:"CHSS VENKITTAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12816", district:"4", Block:"245", label:"AVILA CONVENT MATRICULATION HR SEC SCHOOL, VELANDIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12817", district:"4", Block:"245", label:"SAIBABA VID.MDL SCH.SB KOIL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12818", district:"4", Block:"245", label:"CHS KUPPAKONAMPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"12819", district:"4", Block:"245", label:"ST THOMAS AIDED HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12820", district:"4", Block:"245", label:"CHSS RATHINAPURI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12821", district:"4", Block:"245", label:"GHS GANDHIMANAGAR", Sch_type:"Government", Category:"High School"},
    { key:"12822", district:"4", Block:"245", label:"GHSS, GANAPATHY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12823", district:"4", Block:"245", label:"CMS AIDED HIGH SCHOOL (MALAYALAM), GANAPATHY, COIMBATORE 641006", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12824", district:"4", Block:"245", label:"ELSIE MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12825", district:"4", Block:"245", label:"CHSS MANIYAKARANPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12826", district:"4", Block:"245", label:"THE CAMFORD INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12827", district:"4", Block:"245", label:"KOVAI KALAIMAGAL MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12828", district:"4", Block:"279", label:"GANDHI CENTENARY MEMORIAL MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12829", district:"4", Block:"279", label:"THIYAGI N.G.RAMASWAMY MEMORIAL HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12830", district:"4", Block:"279", label:"SRI GOPAL NAIDU HR SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12831", district:"4", Block:"279", label:"KASTHURBA GANDHI ORAL SCHOOL HEART FOR THE HEARING IMPAIRED", Sch_type:"Un-aided", Category:"High School"},
    { key:"12832", district:"4", Block:"279", label:"CRESCENT MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12833", district:"4", Block:"279", label:"CHS VARADHARAJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"12834", district:"4", Block:"279", label:"PSG SARVAJANA HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12835", district:"4", Block:"279", label:"CHSS,PEELAMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12836", district:"4", Block:"279", label:"PSGR KRISHNAMMAL GIRLS.HR.SEC.SCHOOL PEELAMEDU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12837", district:"4", Block:"279", label:"PSG PUBLIC SCHOOLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12838", district:"4", Block:"279", label:"ST JOSEPH'S BOYS AIDED MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12839", district:"4", Block:"279", label:"ST.PHILOMENA'S HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12840", district:"4", Block:"279", label:"CHSS UDAYAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12841", district:"4", Block:"279", label:"KENDRIYA VIDYALAYA PULIAKULAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"12842", district:"4", Block:"279", label:"GEETHANJALEE MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12843", district:"4", Block:"279", label:"C.M.S SIHS COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"12844", district:"4", Block:"279", label:"RAJALAKSHMI AIDED HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12845", district:"4", Block:"279", label:"SNVNV GOVERNMENT BOYS HIGH SCHOOL SINGANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"12846", district:"4", Block:"279", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL SINGANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12847", district:"4", Block:"279", label:"NADAR HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12848", district:"4", Block:"279", label:"GOVERNMENT BOYS HIGHER SECONARY SCHOOL ONDIPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12849", district:"4", Block:"279", label:"R.C GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ONDIPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12850", district:"4", Block:"279", label:"A R N MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12851", district:"4", Block:"279", label:"ISLAMIYAH MAT.HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12852", district:"4", Block:"279", label:"SPBN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12853", district:"4", Block:"279", label:"BVM GLOBAL CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12854", district:"4", Block:"279", label:"VIVEKANANDA VIDYALAYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12855", district:"4", Block:"279", label:"PUMS KURUMBAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12856", district:"4", Block:"279", label:"GHSS SSKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12857", district:"4", Block:"279", label:"K.V. MATRIC HR SEC  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12858", district:"4", Block:"279", label:"INDIAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12859", district:"4", Block:"279", label:"SWATHANTHRA HR.SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12860", district:"4", Block:"279", label:"GHSS VELLAMADAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12861", district:"4", Block:"279", label:"PUMS KEERANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12862", district:"4", Block:"279", label:"PUMS-CHINNAVEDAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12863", district:"4", Block:"279", label:"PUMS UDAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12864", district:"4", Block:"279", label:"T R A HR SEC SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"12865", district:"4", Block:"279", label:"COIMBATORE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12866", district:"4", Block:"279", label:"KAUMARAM SUSHILA SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12867", district:"4", Block:"279", label:"MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12868", district:"4", Block:"279", label:"PUMS CHERAN MAANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12869", district:"4", Block:"279", label:"R J MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12870", district:"4", Block:"279", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VILANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12871", district:"4", Block:"279", label:"VIDHYA NIKETAN MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12872", district:"4", Block:"279", label:"PUMS SHAJAHAN NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"12873", district:"4", Block:"279", label:"VIMAL JYOTHI CONVENT MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12874", district:"4", Block:"279", label:"ELANGO MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12875", district:"4", Block:"279", label:"GOVERNMENT HIGH SCHOOL, SARAVANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12876", district:"4", Block:"279", label:"VIVEKAM SENIOR SECONDARY SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12877", district:"4", Block:"279", label:"LISIEUX CMI PUBLIC SCHOOL, SARAVANAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12878", district:"4", Block:"279", label:"GHS IDIGARAI", Sch_type:"Government", Category:"High School"},
    { key:"12879", district:"4", Block:"279", label:"PUMS VATTAMALAIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12880", district:"4", Block:"279", label:"VCV GHSS VELLAKINAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12881", district:"4", Block:"279", label:"CRAYONS PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12882", district:"4", Block:"279", label:"MANCHESTER INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12883", district:"4", Block:"279", label:"CHANDRA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12884", district:"4", Block:"279", label:"GHSS KALAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12885", district:"4", Block:"279", label:"PSGG KANYAGURUKULAM HR. SEC. SCHOOL FOR GIRLS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12886", district:"4", Block:"279", label:"SRI KK NAIDU HR SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12887", district:"4", Block:"279", label:"SRIMATHI D. PADMAVATHI AMMAL HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12888", district:"4", Block:"279", label:"SUGUNA PIP SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12889", district:"4", Block:"279", label:"THE IDEAL MOUNT LITERA ZEE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12890", district:"4", Block:"279", label:"SRI VIVEKANANDA MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12891", district:"4", Block:"279", label:"CHANDRAKANTHI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12892", district:"4", Block:"279", label:"ANAN INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12893", district:"4", Block:"279", label:"PUMS-VELLANIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"12894", district:"4", Block:"349", label:"PANCHAYAT UNION MIDDLE SCHOOL VADAVALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"12895", district:"4", Block:"349", label:" PANCHAYAT UNION MIDDLE SCHOOL, BOMMANAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12896", district:"4", Block:"349", label:"PANCHAYAT UNION MIDDLE SCHOOL VADAVALLI SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"12897", district:"4", Block:"349", label:"MARUTHAMALAI SUBRAMANIYA SWAMY DEVASTHANA HIGHER SECONDARY SCHOOL , VADAVALLI, CBE-641041.", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12898", district:"4", Block:"349", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KALVEERAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12899", district:"4", Block:"349", label:"R.P.M.VIDYALAYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"12900", district:"4", Block:"349", label:"PANCHAYAT UNION MIDDLE SCHOOL, KALIKKANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12901", district:"4", Block:"349", label:"GGHSS THONDAMUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12902", district:"4", Block:"349", label:"GBHSS THONDAMUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12903", district:"4", Block:"349", label:"GHS DEVARAYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"12904", district:"4", Block:"349", label:"GHSS NARASIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12905", district:"4", Block:"349", label:"PUMS VIRALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12906", district:"4", Block:"349", label:"GHS SEMMEDU", Sch_type:"Government", Category:"High School"},
    { key:"12907", district:"4", Block:"349", label:"GTR HS MUTTATHUVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"12908", district:"4", Block:"349", label:"ISHA HOME SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12909", district:"4", Block:"349", label:"GHSS MADVARAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12910", district:"4", Block:"349", label:"CHINMAYA INTERNATIONAL RESIDENTIAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12911", district:"4", Block:"349", label:"PUMS ALANDURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"12912", district:"4", Block:"349", label:"GHSS ALANDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12913", district:"4", Block:"349", label:"GHS NADHEGOUNDENPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"12914", district:"4", Block:"349", label:"GHSS SENNANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12915", district:"4", Block:"349", label:"AIDED MID SCHOOL KUPPANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12916", district:"4", Block:"349", label:"GHS MADHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12917", district:"4", Block:"349", label:"GHSS THEETHIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12918", district:"4", Block:"349", label:"PANCHYAT UNION MIDDLE SCHOOL  PERUR CHETTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12919", district:"4", Block:"13", label:"ANANYA VIDYALAYA MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12920", district:"4", Block:"13", label:"GHS PETHANAICKANUR", Sch_type:"Government", Category:"High School"},
    { key:"12921", district:"4", Block:"13", label:"SRI VAGISVARI VIDYA MANDIR MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12922", district:"4", Block:"13", label:"GHSS SOMANDURAI CHITTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12923", district:"4", Block:"13", label:"PUMS THENSANGAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12924", district:"4", Block:"13", label:"M C GGHSS KOTTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12925", district:"4", Block:"13", label:"GOVT BOYS HSS KOTTUR MALAYANDIPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12926", district:"4", Block:"13", label:"GHS ALIYAR NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"12927", district:"4", Block:"13", label:"VRT GGHSS ANAIMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12928", district:"4", Block:"13", label:"GHSS, ANAIMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12929", district:"4", Block:"13", label:"GHS ATHUPOLLACHI", Sch_type:"Government", Category:"High School"},
    { key:"12930", district:"4", Block:"13", label:"PALANIAMMAL HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12931", district:"4", Block:"13", label:"GHSS Diwansapudur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12932", district:"4", Block:"13", label:"FOREST DEPT TRIBAL  RESIDENTIAL MIDDLE SCHOOL TOPSLIP", Sch_type:"Government", Category:"Middle School"},
    { key:"12933", district:"4", Block:"13", label:"PUMS SARALAIPATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"12934", district:"4", Block:"13", label:"FOREST HILL ACADEMY MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12935", district:"4", Block:"13", label:"GHSS VETTAIKARANPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12936", district:"4", Block:"13", label:"GHS SETHUMADAI", Sch_type:"Government", Category:"High School"},
    { key:"12937", district:"4", Block:"13", label:"PUMS ODAYAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12938", district:"4", Block:"13", label:"ANNAI MATRICULATION SCHOOL, CHINNAPPAMPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"12939", district:"32", Block:"73", label:"GHSS GUDIMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12940", district:"32", Block:"73", label:"GHSS POOLAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12941", district:"32", Block:"73", label:"GHS V.VELUR", Sch_type:"Government", Category:"High School"},
    { key:"12942", district:"32", Block:"73", label:"GHSS RAMACHANDRAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12943", district:"32", Block:"73", label:"GHS KONGALNAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"12944", district:"32", Block:"73", label:"GHSS PEDAPPAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12945", district:"4", Block:"126", label:"GHS ARASAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12946", district:"4", Block:"126", label:"GHSS SERIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12947", district:"4", Block:"126", label:"GHS ANDIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12948", district:"4", Block:"126", label:"GHS DEVANAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12949", district:"4", Block:"126", label:"PUMS SENDRAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12950", district:"4", Block:"126", label:"PUMS DASANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12951", district:"4", Block:"126", label:"GHS KATTAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12952", district:"4", Block:"126", label:"PUMS KOTHAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"12953", district:"4", Block:"126", label:"GHSS KALIANNANPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12954", district:"4", Block:"126", label:"PUMS KONDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"12955", district:"4", Block:"126", label:"TELC MS KARUNAGARAPURI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12956", district:"4", Block:"126", label:"NOYYAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12957", district:"4", Block:"126", label:"PUMS NALLATTIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12958", district:"4", Block:"126", label:"PUMS METTUVAVI", Sch_type:"Government", Category:"Middle School"},
    { key:"12959", district:"4", Block:"126", label:"GHS PANAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12960", district:"4", Block:"126", label:"MNM HIGH SCHOOL JAKKARPALAYAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12961", district:"4", Block:"126", label:"PUMS PALARPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"12962", district:"4", Block:"126", label:"GHSS MUTHUGOUNDENUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12963", district:"4", Block:"126", label:"GHSS VADACHITHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12964", district:"4", Block:"126", label:"GHS SENGUTTAIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12965", district:"4", Block:"126", label:"AIDED MIDDLE SCHOOL SINGARAM PALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"12966", district:"4", Block:"126", label:"GHSS KINATHUKADAVU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12967", district:"4", Block:"126", label:"TELC SR. BERGENDHAL GIRLS HR SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"12968", district:"4", Block:"126", label:"PRESENTATION CON FOR DISABLED", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"12969", district:"32", Block:"154", label:"GHSS MADATHUKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12970", district:"32", Block:"154", label:"NATIONAL MATRICULATION HR .SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12971", district:"32", Block:"154", label:"GHS SK PUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"12972", district:"32", Block:"154", label:"JSR HSS MADATHUKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12973", district:"32", Block:"154", label:"GHS SOLAMADEVI", Sch_type:"Government", Category:"High School"},
    { key:"12974", district:"32", Block:"154", label:"SRI VENKATA KRISHNA HSS,KANIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"12975", district:"32", Block:"154", label:"GHSS KARATHOLUVU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12976", district:"32", Block:"154", label:"GHS THUNGAVI", Sch_type:"Government", Category:"High School"},
    { key:"12977", district:"32", Block:"154", label:"ANUGRAHA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12978", district:"32", Block:"154", label:"VIVEKANANDA VIDYALAYAM HR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12979", district:"32", Block:"154", label:"GHS PAPPANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"12980", district:"32", Block:"154", label:"PUMS,PERUMALPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12981", district:"32", Block:"154", label:"GHSS KOMARALINGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12982", district:"32", Block:"154", label:"OM SAKTHI Hr Sec School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12983", district:"32", Block:"154", label:"GHS KUPPAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12984", district:"32", Block:"154", label:"GHS KADATHUR", Sch_type:"Government", Category:"High School"},
    { key:"12985", district:"4", Block:"247", label:"PANCHAYAT UNION MIDDLE SCHOOL  BODIPLAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12986", district:"4", Block:"247", label:"GHS DEVAMBADI KALIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"12987", district:"4", Block:"247", label:"PUMS JAMIN MUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"12988", district:"4", Block:"247", label:"CENTRAL HIGH SCHOOL A NAGORE", Sch_type:"Fully Aided", Category:"High School"},
    { key:"12989", district:"4", Block:"247", label:"JK PUBLIC HIGHER SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"12990", district:"4", Block:"247", label:"GHSS PURAVIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12991", district:"4", Block:"247", label:"GOVERNMENT HIGH SCHOOL PULIYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"12992", district:"4", Block:"247", label:"PANCHAYAT UNION MIDDLE SCHOOL RAMAPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"12993", district:"4", Block:"247", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  MANNUR RAMANATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12994", district:"4", Block:"247", label:"PUMS VADAKKUKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"12995", district:"4", Block:"247", label:"PUMS P.NAGORE", Sch_type:"Government", Category:"Middle School"},
    { key:"12996", district:"4", Block:"247", label:"RISE MATRICULATION SCHOOL SAMIYANDIPUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"12997", district:"4", Block:"247", label:"GGHS NEGAMAM", Sch_type:"Government", Category:"High School"},
    { key:"12998", district:"4", Block:"247", label:"GHSS Negamam", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"12999", district:"4", Block:"247", label:"GOVERNMENT HIGH SCHOOL GOLLAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13000", district:"4", Block:"248", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,GOMANGALAMPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13001", district:"4", Block:"248", label:"VIDYA NETHRRA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13002", district:"4", Block:"248", label:"GHSS KANJAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13003", district:"4", Block:"248", label:"PALANIGOUNDER HIGHER SECONDARY SCHOOL, POLLACHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13004", district:"4", Block:"248", label:"NGNG HSS REDDIARUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13005", district:"4", Block:"248", label:"GHSS SINGANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13006", district:"4", Block:"248", label:"GOVERNMENT HIGH SCHOOL,S.MALAYANDIPATTINAM", Sch_type:"Government", Category:"High School"},
    { key:"13007", district:"4", Block:"248", label:"ADW HSS THONDAMUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13008", district:"4", Block:"248", label:"SHENBAGAM MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13009", district:"4", Block:"248", label:"VIVEKANANTHAA VITHYALAYAA MATRIC SCHOOL, KUNCHIPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"13010", district:"4", Block:"248", label:"GHSS , ZAMIN UTHUKULI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13011", district:"4", Block:"248", label:"TMT. RUKMANIAMMAL HSS, JOTHI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13012", district:"4", Block:"248", label:"SVGHSS SAMATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13013", district:"4", Block:"248", label:"MARIAMMAL GIRLS HIGHER SECONDARY SCHOOL, POLLACHI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13014", district:"4", Block:"248", label:"SHANTINIKETAN MATRICULATION SCHOOL, POLLACHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"13015", district:"4", Block:"248", label:"SAMATHUR RAMA IYYANKAR MUNICIPAL HR SEC SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13016", district:"4", Block:"248", label:"SRI LATHANGI VIDHYA MANDIR MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13017", district:"4", Block:"248", label:"MGHSS POLLACHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13018", district:"4", Block:"248", label:"MUNICIPAL BOYS HR. SEC. SCHOOL, POLLACHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13019", district:"4", Block:"248", label:"GOVT GIRLS HR.SC.SCHOOL, POLLACHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13020", district:"4", Block:"248", label:"SHAANTHI MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13021", district:"32", Block:"371", label:"NARAYANASAMY GHSS PERIYAVALAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13022", district:"32", Block:"371", label:"PUMS CHINNAPOOLANKINAR", Sch_type:"Government", Category:"Middle School"},
    { key:"13023", district:"32", Block:"371", label:"PUMS BODIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13024", district:"32", Block:"371", label:"VAV INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13025", district:"32", Block:"371", label:"GKN HSS PUNGAMUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13026", district:"32", Block:"371", label:"GOVERNMENT HIGH SCHOOL THIRUMOORTHINAGAR", Sch_type:"Government", Category:"High School"},
    { key:"13027", district:"32", Block:"371", label:"GHSS JALLIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13028", district:"32", Block:"371", label:"PUMS PALLAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13029", district:"32", Block:"371", label:"PUMS,MANUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13030", district:"32", Block:"371", label:"GHS,MALAIYANDIPATTINAM", Sch_type:"Government", Category:"High School"},
    { key:"13031", district:"32", Block:"371", label:"PON NAAVARASU MATRIC SCHOOL, MALAIYANDIGOUNDANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"13032", district:"32", Block:"371", label:"PUMS,THUMBALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13033", district:"32", Block:"371", label:"GHSS RAJENDRA ROAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13034", district:"32", Block:"371", label:"GBHSS UDUMALPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13035", district:"32", Block:"371", label:"BCGGHSS UDUMALPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13036", district:"32", Block:"371", label:"SVG HSS UDUMALPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13037", district:"32", Block:"371", label:"S.K.P.HSS UDUMALPET", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13038", district:"32", Block:"371", label:"RKR HSS UDUMALPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13039", district:"32", Block:"371", label:"PUMS KAMARAJ NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"13040", district:"32", Block:"371", label:"GOVT HIGH SCHOOL, SIVASAKTHI COLONY UDUMALPET", Sch_type:"Government", Category:"High School"},
    { key:"13041", district:"32", Block:"371", label:"PMGHSS PULANKINAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13042", district:"32", Block:"371", label:"GGHSS DEVANURPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13043", district:"32", Block:"371", label:"GHSS UDUKKAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13044", district:"32", Block:"371", label:"SRI VENKATESWARA MAT SCHOOL  MANUPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"13045", district:"32", Block:"371", label:"SAINIK SCHOOL AMARAVATHI NAGAR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"13046", district:"32", Block:"371", label:"GHS AMARAVATHINAGAR", Sch_type:"Government", Category:"High School"},
    { key:"13047", district:"32", Block:"371", label:"GHS KALLAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"13048", district:"32", Block:"371", label:"SNVGHSS ELAYAMUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13049", district:"32", Block:"371", label:"RVG HSS KURICHIKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13050", district:"32", Block:"371", label:"SRINIVASA VIDHYALAYA AIDED MIDDLE SCHOOL GANAPATHIPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13051", district:"4", Block:"386", label:"PANCHAYATH  UNION MIDDLE SCHOOL VELLONIE LOWER DIVISION", Sch_type:"Government", Category:"Middle School"},
    { key:"13052", district:"4", Block:"386", label:"GTRMS VALPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13053", district:"4", Block:"386", label:"PANCHAYATH UNION MIDDLE SCHOOL MURUGALI", Sch_type:"Government", Category:"Middle School"},
    { key:"13054", district:"4", Block:"386", label:"PANCHAYATH UNION MIDDLE SCHOOL PACHAMALAI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"13055", district:"4", Block:"386", label:"AIDED MIDDLE SCHOOL  MUDIS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13056", district:"4", Block:"386", label:"SACRED HEART MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13057", district:"4", Block:"386", label:"GGHSS VALPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13058", district:"4", Block:"386", label:"GOVERNMENT HIGH SCHOOL ROTTIKADAI", Sch_type:"Government", Category:"High School"},
    { key:"13059", district:"4", Block:"386", label:"GOVERNMENT HIGH SCHOOL CINCHONA", Sch_type:"Government", Category:"High School"},
    { key:"13060", district:"4", Block:"386", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SHOLAYAR DAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13061", district:"4", Block:"386", label:"GHSS ATTAKATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13062", district:"4", Block:"386", label:"GHS KADAMPARAI", Sch_type:"Government", Category:"High School"},
    { key:"13063", district:"4", Block:"386", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VALPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13064", district:"4", Block:"386", label:"THUYA IRUDAYA GIRLS HR SEC SCHOOL, VALPARAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13065", district:"4", Block:"386", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Valparai", Sch_type:"Government", Category:"Middle School"},
    { key:"13066", district:"4", Block:"19", label:"GHSS ANAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13067", district:"4", Block:"19", label:"PUMS, AKKARAI SENGAPPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"13068", district:"4", Block:"19", label:"PANCHAYAT UNION MIDDILE SCHOOL, POGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13069", district:"4", Block:"19", label:"GOVERNMENT HIGH SCHOOL, PERIYAPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"13070", district:"4", Block:"19", label:"PANCHAYAT UNION MIDDLE SCHOOL KUNNATHUR PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13071", district:"4", Block:"19", label:"SREE MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13072", district:"4", Block:"19", label:"PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13073", district:"4", Block:"19", label:"T.S.A. GOVT HR.SEC.SCHOOL, KATTAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13074", district:"4", Block:"19", label:"SRI BHARATHI MATRICULATION HIGHER SECONDRY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13075", district:"4", Block:"19", label:"PANCHAYAT UNION MIDDLE SCHOOL ORAIKALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13076", district:"4", Block:"19", label:"GOVERNMENT HIGH SCHOOL PONNEGOUNDENPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"13077", district:"4", Block:"19", label:"PUMS VADAKKALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13078", district:"4", Block:"19", label:"PUMS KANUVAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13079", district:"4", Block:"19", label:"PANCHAYAT UNION MIDDLE SCHOOLC, SELLAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13080", district:"4", Block:"19", label:"PANCHAYAT UNION MIDDLE SCHOOL, AAMBOTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"13081", district:"4", Block:"19", label:"PUMS, ANNUR METTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13082", district:"4", Block:"19", label:"PUMS, OTTERPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13083", district:"4", Block:"19", label:"ST MARY'S CONVENT MATRICULATION HIGHER SECONDARY SCHOOL, ALLIKKARANPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13084", district:"4", Block:"19", label:"PUMS, KANJAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"13085", district:"4", Block:"19", label:"PUMS, PUTHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13086", district:"4", Block:"19", label:"GHSS, KEMBANAICKENPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13087", district:"4", Block:"19", label:"PANCAHAYAT UNION MIDDLE SCHOOL, ELLAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13088", district:"4", Block:"19", label:"STANES MATRICULATION SCHOOL, ELLAPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"13089", district:"4", Block:"19", label:"PUMS, PILLAIYAPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13090", district:"4", Block:"19", label:"PUMS, ANNUR KUMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13091", district:"4", Block:"19", label:"AMARAR A. MUTHUGOUNDER  GOVERNMENT HIGHER SECONDARY SCHOOL, ANNUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13092", district:"4", Block:"19", label:"GANDHIJI GOVT HR SEC SCHOOL, SOKKAMPALAYAM 641653", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13093", district:"4", Block:"19", label:"K.G. GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13094", district:"4", Block:"19", label:"SRI AKILA VIDYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13095", district:"32", Block:"32", label:"PANCHAYATH UNION MIDDLE SCHOOL SALAIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13096", district:"32", Block:"32", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  CHEYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13097", district:"32", Block:"32", label:"GHSS KANUR PUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13098", district:"32", Block:"32", label:"PANCHAYAT UNION MIDDLE SCHOOL SEMBAGOUNDENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13099", district:"32", Block:"32", label:"GHSS KARUVALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13100", district:"32", Block:"32", label:"GOVERNMENT HIGH SCHOOL , THIRUMALAIGOUNDENPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13101", district:"32", Block:"32", label:"GHS KARUKKANKATTU PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"13102", district:"32", Block:"32", label:"PUMS NAMBIYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13103", district:"32", Block:"32", label:"AVANASHI KALVI NILAYAM HS  M.NADHAMPALAYM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13104", district:"32", Block:"32", label:"PUMS AYEEGOUNDENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13105", district:"32", Block:"32", label:"GHSS PERIYAYEEPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13106", district:"32", Block:"32", label:"GHS PANCHALINGAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13107", district:"32", Block:"32", label:"GHS POTHAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13108", district:"32", Block:"32", label:"PUMS MONDIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13109", district:"32", Block:"32", label:"PUMS PUDUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13110", district:"32", Block:"32", label:"SRI SELVANAYAGI MATRICULATION SCHOOL , VANJIPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"13111", district:"32", Block:"32", label:"GOVERNMENT HIGH SCHOOL VANJIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13112", district:"32", Block:"32", label:"PUMS NARIYAMPALLIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13113", district:"32", Block:"32", label:"PANCHAYAT UNION MIDDLE SCHOOL SENNIMALAI GOUNDENPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13114", district:"32", Block:"32", label:"KONGU KALVI NILAYAM HIGHER  SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13115", district:"32", Block:"32", label:"PANCHAYAT UNION MIDDLE SCHOOL THANDUKKARANPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13116", district:"32", Block:"32", label:"PUMS SULLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13117", district:"32", Block:"32", label:"PUMS THIMMANAYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13118", district:"32", Block:"32", label:"GHSS THEKKALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13119", district:"32", Block:"32", label:"PUMS THULUKAMUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13120", district:"32", Block:"32", label:"PUMS KARUNAIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13121", district:"32", Block:"32", label:"PANCHAYAT UNION MIDDLE SCHOOL VELAYUTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13122", district:"32", Block:"32", label:"PUMS KASIGOUNDANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13123", district:"32", Block:"32", label:"PUMS A.KURUMBAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13124", district:"32", Block:"32", label:"PUMS AMMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13125", district:"32", Block:"32", label:"GOVT  HIGH SCHOOL RAKKIYAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13126", district:"32", Block:"32", label:"GOVT GIRLS HIGHER SECONDARY SCHOOL AVINASHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13127", district:"32", Block:"32", label:"ST THOMAS GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13128", district:"32", Block:"32", label:"GOVT. BOYS HR SEC SCHOOL AVINASHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13129", district:"32", Block:"32", label:"RAYAR KALVI NILAYAM HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13130", district:"32", Block:"32", label:"NANJAPPA NIKETHAN THANNEERPANDHALCOLONY", Sch_type:"Un-aided", Category:"High School"},
    { key:"13131", district:"4", Block:"107", label:"PUMS MANGALAKARAI PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13132", district:"4", Block:"107", label:"TMT KEMPANANJAMMA MML HR SEC SCHOOL  KARAMADAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13133", district:"4", Block:"107", label:"GOVT HR SEC SCHOOL, KARAMADAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13134", district:"4", Block:"107", label:"PUMS,NEELAMPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"13135", district:"4", Block:"107", label:"GHS THOLAMPALYAM", Sch_type:"Government", Category:"High School"},
    { key:"13136", district:"4", Block:"107", label:"PUMS,PALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13137", district:"4", Block:"107", label:"ROSARI GIRLS HIGH SCHOOL METTUPALAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13138", district:"4", Block:"107", label:"GHSS METTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13139", district:"4", Block:"107", label:"PUMS,SUNDHANTHIRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13140", district:"4", Block:"107", label:"GHS KANNARPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13141", district:"4", Block:"107", label:"VIDYAA VIKAS MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13142", district:"4", Block:"107", label:"PUMS,CHINNAKUMARAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13143", district:"4", Block:"107", label:"PUMS,M.KOUNDAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13144", district:"4", Block:"107", label:"PUMS,PUDHUKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"13145", district:"4", Block:"107", label:"GHSS PETHIKUTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13146", district:"4", Block:"107", label:"GHSS VELLIANGADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13147", district:"4", Block:"107", label:"GHSS S.PUNGAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13148", district:"4", Block:"107", label:"GHSS OOMAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13149", district:"4", Block:"107", label:"PUMS,LINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13150", district:"4", Block:"107", label:"PUMS ALATHI VATCHINAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13151", district:"4", Block:"107", label:"PUMS MOOLATHURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13152", district:"4", Block:"107", label:"VIJAYALAKSHMI MATRICULATION HSS, SIRUMUGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13153", district:"4", Block:"107", label:"GOVT HR SEC SCHOOL SIRUMUGAIPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13154", district:"4", Block:"107", label:"GHS ILLUPPAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13155", district:"4", Block:"107", label:"ALAGIRI SURESH GHS ALANGOMBU", Sch_type:"Government", Category:"High School"},
    { key:"13156", district:"4", Block:"107", label:"SHREE SARASSWATHI VIDHYAAH MANDHEER SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13157", district:"4", Block:"107", label:"PUMS,BELLEPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13158", district:"4", Block:"107", label:"E.A.B GOVT HR SEC SCHOOL THENPONMUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13159", district:"4", Block:"107", label:"PUMS THEKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13160", district:"4", Block:"107", label:"PUMS DHASANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13161", district:"4", Block:"107", label:"GADW HR SEC SCHOOL WELSPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13162", district:"4", Block:"107", label:"PUMS K.PUNGAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13163", district:"4", Block:"107", label:"PUMS,THIMMAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13164", district:"4", Block:"107", label:"GHSS , BUJANGANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13165", district:"4", Block:"107", label:"T.DURAISWAMY GOWDER HSS, SEELIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13166", district:"4", Block:"107", label:"SRI VINAYAGA VIDHYALAYA SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13167", district:"4", Block:"107", label:"GHS PARALI POWER HOUSE", Sch_type:"Government", Category:"High School"},
    { key:"13168", district:"4", Block:"107", label:"C.S.I. MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13169", district:"4", Block:"107", label:"G.M.R.C. MIDDLE SCHOOL, METTUPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13170", district:"4", Block:"107", label:"CAREWELL MATRIC HR SEC SCHOOL , METTUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13171", district:"4", Block:"107", label:"MPL GIRLS HSS,METTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13172", district:"4", Block:"107", label:"MAHAJANA HSS,METTUPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13173", district:"4", Block:"107", label:"CHRIST THE KING MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13174", district:"32", Block:"223", label:"SCAD INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13175", district:"32", Block:"223", label:"GHSS GANAPATHIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13176", district:"32", Block:"223", label:"S L N M HR SEC SCHOOL KARADIVAVI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13177", district:"32", Block:"223", label:"GHSS ARULPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13178", district:"32", Block:"223", label:"GHS KARANAMPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"13179", district:"32", Block:"223", label:"GHS KODANGIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13180", district:"32", Block:"223", label:"GHS KAMANAICKENPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13181", district:"32", Block:"223", label:"GHS SEGAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13182", district:"32", Block:"223", label:"GHS ARIVOLINAGAR", Sch_type:"Government", Category:"High School"},
    { key:"13183", district:"32", Block:"223", label:"GOVERNMENT  GIRLS HIGHER SECONDARY SCHOOL PALLADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13184", district:"32", Block:"223", label:"GOVT BOYS HR SEC SCHOOL PALLADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13185", district:"32", Block:"223", label:"GHS P.VADUGAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13186", district:"32", Block:"223", label:"VALLALAR GURUKULAM HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13187", district:"32", Block:"223", label:"GHS POOMALUR", Sch_type:"Government", Category:"High School"},
    { key:"13188", district:"32", Block:"223", label:"GHS NADUVELAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13189", district:"32", Block:"223", label:"MOUNT LITERA ZEE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13190", district:"32", Block:"223", label:"A V A T HR SEC SCHOOL SAMALAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13191", district:"32", Block:"223", label:"GHS KARUGAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13192", district:"32", Block:"223", label:"GHSS SAMIGOUNDANPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13193", district:"32", Block:"223", label:"GHS 63 VELAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13194", district:"32", Block:"250", label:"GHSS KODUVAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13195", district:"32", Block:"250", label:"SRI ALAGUMALAI VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13196", district:"32", Block:"250", label:"GHSS V.KALLIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13197", district:"32", Block:"250", label:"GHS VALAYAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13198", district:"32", Block:"250", label:"GHS KATTUR", Sch_type:"Government", Category:"High School"},
    { key:"13199", district:"32", Block:"250", label:"GHSS KETHANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13200", district:"32", Block:"250", label:"GEM INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13201", district:"32", Block:"250", label:"P V K N HR SEC SCHOOL PONGALUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13202", district:"32", Block:"250", label:"GHSS PERUNTHOLUVU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13203", district:"32", Block:"250", label:"GHSS POLLIKALIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13204", district:"32", Block:"250", label:"LM MATRICULATION SCHOOL, KULLAMPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"13205", district:"4", Block:"301", label:"SRI VENKATESHWARA HIGH SCHOOL APPANAICKENPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13206", district:"4", Block:"301", label:"GOVERNMENT HIGH SCHOOL PONNAKKANI", Sch_type:"Government", Category:"High School"},
    { key:"13207", district:"4", Block:"301", label:"GOVERNMENT HIGHER SECONDARY SCHOOL EDAYAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13208", district:"4", Block:"301", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KALLAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13209", district:"4", Block:"301", label:"GOVERNMENT HIGH SCHOOL AKKANAICKENPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13210", district:"4", Block:"301", label:"SRI RAMASWAMY NAIDU VIDYALAYAM HR SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13211", district:"4", Block:"301", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SENJERIMALAIYADIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13212", district:"4", Block:"301", label:"THAVATHIRU MARIMUTHU ADIGALAR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13213", district:"4", Block:"301", label:"PUMS S.AYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13214", district:"4", Block:"301", label:"GOVERNMENT HIGHER SECONDARY SCHOOL J.KRISHNAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13215", district:"4", Block:"301", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SENJERIPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13216", district:"4", Block:"301", label:"GOVERNMENT HIGH SCHOOL JALLIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13217", district:"4", Block:"301", label:"PUMS ODAKKALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13218", district:"4", Block:"301", label:"SOKKAN CHETTIAR MALLAMMAL HIGHER SECONDARY SCHOOL VADAMBACHERI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13219", district:"4", Block:"301", label:"GOVERNMENT HIGH SCHOOL SULTHANPET", Sch_type:"Government", Category:"High School"},
    { key:"13220", district:"4", Block:"302", label:"GHS RASIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13221", district:"4", Block:"302", label:"PUMS ATHAPPAGOUNDANPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13222", district:"4", Block:"302", label:"GHSS,IRUGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13223", district:"4", Block:"302", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SULUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13224", district:"4", Block:"302", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SULUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13225", district:"4", Block:"302", label:"GHSS KANGEYAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13226", district:"4", Block:"302", label:"KENDRIYA VIDYALAYA", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"13227", district:"4", Block:"302", label:"GHS KALANGAL", Sch_type:"Government", Category:"High School"},
    { key:"13228", district:"4", Block:"302", label:"ANUGRAHA MANDHIR CBSE SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13229", district:"4", Block:"302", label:"GHSS KANNAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13230", district:"4", Block:"302", label:"AIDED KADRI MILLS HIGHER SECONDARY SCHOOL OTTARPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13231", district:"4", Block:"302", label:"GHS PALLAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13232", district:"4", Block:"302", label:"VIVEKANANDA KALVI NILAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"13233", district:"4", Block:"302", label:"VIJAYA VIDYALAYA  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13234", district:"4", Block:"302", label:"GHS PATTANAM", Sch_type:"Government", Category:"High School"},
    { key:"13235", district:"4", Block:"302", label:"S K N GOVT HR SEC SCHOOL, PEEDAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13236", district:"4", Block:"302", label:"AIDED ST MARYS BOYS HIGH SCHOOL KARUMATHAMPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13237", district:"4", Block:"302", label:"ST.JOSEPHS GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13238", district:"4", Block:"302", label:"KONGU VELLALAR MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13239", district:"4", Block:"302", label:"A.R.C MAT SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"13240", district:"4", Block:"302", label:"HOLY ROSARY MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13241", district:"4", Block:"302", label:"KOVAI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13242", district:"4", Block:"302", label:"GOVERNMENT HIGH SCHOOL SEMMANDAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13243", district:"4", Block:"302", label:"PUMS KITTAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13244", district:"4", Block:"302", label:"K K VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13245", district:"4", Block:"302", label:"K K VIDHYALAYA HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13246", district:"4", Block:"302", label:"GOVT HR SEC SCHOOL VAGARAYAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13247", district:"4", Block:"302", label:"PUMS UNJAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13248", district:"4", Block:"302", label:"GHS SELLAPPAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13249", district:"4", Block:"302", label:"GHSS,ARASUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13250", district:"4", Block:"302", label:"GRD-CPF MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13251", district:"4", Block:"302", label:"GHSS CHINNIAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13252", district:"4", Block:"302", label:"PUMS MUTHUGOUNDENPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13253", district:"32", Block:"363", label:"AVP TRUST NATIONAL MATRIC HR SEC SCHOOL, ATHUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13254", district:"32", Block:"363", label:"GHSS, AYYANKALIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13255", district:"32", Block:"363", label:"PUMS,ETTIVEERAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13256", district:"32", Block:"363", label:"GHS, IDUVAI", Sch_type:"Government", Category:"High School"},
    { key:"13257", district:"32", Block:"363", label:"GHS GURUVAYURAPPAN NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"13258", district:"32", Block:"363", label:"SIVA SAKTHI MHSS,TIRUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13259", district:"32", Block:"363", label:"SAKTHI VIGNESWARA KALVI NILAYAM HR SEC SCHOOL, PONGUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13260", district:"32", Block:"363", label:"GHSS KANAKKAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13261", district:"32", Block:"363", label:"PUMS SOKKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13262", district:"32", Block:"363", label:"PUMS,THORAVALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13263", district:"32", Block:"363", label:"G BOYS HSS PERUMANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13264", district:"32", Block:"363", label:"AVS CENTRAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13265", district:"32", Block:"363", label:"GHS PANDIAN NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"13266", district:"32", Block:"363", label:"Murugu Matric Hr.Sec.School ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13267", district:"32", Block:"363", label:"APS ACADAMY MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13268", district:"32", Block:"363", label:"GHS POOLUVAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13269", district:"32", Block:"363", label:"GHS VAVIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13270", district:"32", Block:"363", label:"PUMS, VALLIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13271", district:"32", Block:"363", label:"TAGORE VIDYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"13272", district:"32", Block:"363", label:"GHS PICHAM PALAYAM PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"13273", district:"32", Block:"363", label:"PUMS, RANGANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13274", district:"32", Block:"363", label:"GHSS ANUPPARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13275", district:"32", Block:"363", label:"VETRI VIDYALAYA MATRIC SCHOOL, SANKARAN THOTTAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"13276", district:"32", Block:"363", label:"SRI THANDAPANI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13277", district:"32", Block:"363", label:"GHSS MANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13278", district:"32", Block:"363", label:"PUMS,SUNDAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"13279", district:"32", Block:"363", label:"GHSS,IDUVAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13280", district:"32", Block:"363", label:"GHSS,VEERAPANDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13281", district:"32", Block:"363", label:"GHS KARUPPAGOUNDANPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13282", district:"32", Block:"363", label:"PUMS,MUTHANAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13283", district:"32", Block:"363", label:"Vivekananda Vidyalaya Matric Hr. Sec, K.Chettipalayam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13284", district:"32", Block:"363", label:"ADW HS,KOVILVAZHI", Sch_type:"Government", Category:"High School"},
    { key:"13285", district:"32", Block:"363", label:"GHS K.CHETTIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13286", district:"32", Block:"363", label:"PUMS, GENGANAICKENPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13287", district:"32", Block:"363", label:"GHS MUDHALIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13288", district:"32", Block:"363", label:"ABACUS INT SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13289", district:"32", Block:"363", label:"GHSS,VIJAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13290", district:"32", Block:"363", label:"BISHOP UBAGARASWAMY HIGHER SECONDARY SCHOOL, TIRUPPUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13291", district:"32", Block:"363", label:"SHRI MAKESH VIDYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13292", district:"32", Block:"363", label:"MHSS,KUMAR NAGAR,TIRUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13293", district:"32", Block:"363", label:"MHS,NESAWALAR COLONY", Sch_type:"Government", Category:"High School"},
    { key:"13294", district:"32", Block:"363", label:"MHSS,NEW RAMAKRISHNAPURAM, TIRUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13295", district:"32", Block:"363", label:"CHINNASAMY AMMAL BOYS MHSS,TIRUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13296", district:"32", Block:"363", label:"ST.S JOSEPH'S HIGH SCHOOL, KUMARAN ROAD,TIRUPPUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13297", district:"32", Block:"363", label:"PREMA MAT HSS, COLLEGE ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13298", district:"32", Block:"363", label:"KAIRALI VIDHYALAYAM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13299", district:"32", Block:"363", label:"JAIVABAI MGHSS -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13300", district:"32", Block:"363", label:"NANJAPPA MPL BOYS HR SEC SCHOOL TIRUPPUR. ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13301", district:"32", Block:"363", label:"ROTARY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13302", district:"32", Block:"363", label:"MMS,Parappalayam,", Sch_type:"Government", Category:"Middle School"},
    { key:"13303", district:"32", Block:"363", label:"MHS, KARUVAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13304", district:"32", Block:"363", label:"KSC GOVT HR SEC SCHOOL TIRUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13305", district:"32", Block:"363", label:"M N MURUGAPPA CHETTIYAR GHSS, TIRUPPUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13306", district:"32", Block:"363", label:"MUNICIPAL HIGH SCHOOL,NOYYAL STREET,TIRUPPUR", Sch_type:"Government", Category:"High School"},
    { key:"13307", district:"32", Block:"363", label:"AL-AMEEN HIGH SCHOOL, KANGEYAM ROAD", Sch_type:"Un-aided", Category:"High School"},
    { key:"13308", district:"7", Block:"29", label:"GTRHSS-PERUMPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13309", district:"7", Block:"29", label:"NPR GHSS,AYYAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13310", district:"7", Block:"29", label:"GHS,DEVARAPPANPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13311", district:"7", Block:"29", label:"PANCHAYAT UNION MIDDLE SCHOOL, SANGAREDDYKOTTAI .", Sch_type:"Government", Category:"Middle School"},
    { key:"13312", district:"7", Block:"29", label:"PANCHAYAT UNION MIDDLE SCHOOL, K.SINGARAKOTTAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"13313", district:"7", Block:"29", label:"GOVT. KALLAR HIGH SCHOOL, NELLUR", Sch_type:"Government", Category:"High School"},
    { key:"13314", district:"7", Block:"29", label:"RVLNA GHSS - SITHAREVU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13315", district:"7", Block:"29", label:"PUMS, BODIKAMMANVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"13316", district:"7", Block:"29", label:"GHSS, SITHAYANKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13317", district:"7", Block:"29", label:"CSI MIDDLE SCHOOL, PALAYAMKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13318", district:"7", Block:"29", label:"RC SACRED HEART MS-ATHOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13319", district:"7", Block:"29", label:"GHSS, ATHOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13320", district:"7", Block:"29", label:"NAGALAKSHMI MATRIC HR SEC SCHOOL,SITHAYANKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13321", district:"7", Block:"29", label:"PUMS, PARAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13322", district:"7", Block:"29", label:"ST.MICHAEL'S MIDDLE SCHOOL, VANNAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13323", district:"7", Block:"29", label:"GANDHIJI MS-MALLAIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13324", district:"7", Block:"29", label:"GHSS,V.KOOTHAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13325", district:"7", Block:"29", label:"PUMS, J.PUDUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13326", district:"7", Block:"29", label:"PUMS, A.KURUMBAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13327", district:"7", Block:"29", label:"G.AD.WHSS-MURUGAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13328", district:"7", Block:"29", label:"PUMS, KEELAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13329", district:"7", Block:"29", label:"DEVANGAR (G) HS,CHINNALAPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13330", district:"7", Block:"29", label:"DEVANGAR HIGHER SECONDARY SCHOOL,CHINNALAPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13331", district:"7", Block:"29", label:"THAMBITHOTTAM HSS,GANDHIGRAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13332", district:"7", Block:"29", label:"ST JOSEPH'S MATRICULATION SCHOOL,CHINNALAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"13333", district:"7", Block:"29", label:"KASTHURIBA SEVIKASHRAM SPECIAL HIGHER SECONDRAY SCHOOL , GANDHIGRAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13334", district:"7", Block:"29", label:"KENDRIYA VIDHYALAYA,GANDHIGRAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"13335", district:"7", Block:"29", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,CHETTIAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13336", district:"7", Block:"29", label:"GOVERNMENT HIGH SCHOOL, CHINNALAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13337", district:"7", Block:"29", label:"RC MIDDLE SCHOOL, ARIYANELLURE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13338", district:"7", Block:"29", label:"PANCHAYAT UNION MIDDLE SCHOOL ,PITHALAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13339", district:"7", Block:"29", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,N.PANJAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13340", district:"7", Block:"29", label:"ST AUGUSTINAR(G)HSS,N.PANJAMPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13341", district:"7", Block:"29", label:"DR ZAHIR HUSSIAN HSS,PILLAYARNATHAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13342", district:"7", Block:"36", label:"PUMS, K.PUDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13343", district:"7", Block:"36", label:"GOVT. KALLAR MIDDLE SCHOOL, G.THUMMALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13344", district:"7", Block:"36", label:"PUMS, M.VADIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13345", district:"7", Block:"36", label:"ANNA AIDED MIDDLE SCHOOL, MUTHALAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"13346", district:"7", Block:"36", label:"GOVT. KALLAR HSS SEVUGAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13347", district:"7", Block:"36", label:"N.S.V.V.B HSS PATTIVEERANPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13348", district:"7", Block:"36", label:"N.S.V.V.G HSS PATTIVEERANPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13349", district:"7", Block:"36", label:"LAKSHMI NARAYANA MATRICULATION SCHOOL, PATTIVEERANPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"13350", district:"7", Block:"36", label:"GOVT KALLAR HSS, MALLANAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13351", district:"7", Block:"36", label:"PUMS, KULIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13352", district:"7", Block:"36", label:"GHSS, KOMBAIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13353", district:"7", Block:"36", label:"PUMS, KATTAKAMANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13354", district:"7", Block:"36", label:"PUMS, 1ST WARD BATLAGUNDU", Sch_type:"Government", Category:"Middle School"},
    { key:"13355", district:"7", Block:"36", label:"GOVT HS OLD  BATLAGUNDU", Sch_type:"Government", Category:"High School"},
    { key:"13356", district:"7", Block:"36", label:"GOVT BOYS HSS  BATLAGUNDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13357", district:"7", Block:"36", label:"HOLYCROSS GIRLSHSS  BATLAGUNDU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13358", district:"7", Block:"36", label:"MAHALAKSHMI GHSS  BATLAGUNDU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13359", district:"7", Block:"36", label:"CSI HSS, BATLAGUNDU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13360", district:"7", Block:"36", label:"PUMS,VERIYAPPANAICKENPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13361", district:"7", Block:"36", label:"GHS, VIRALIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13362", district:"7", Block:"36", label:"GHS, ELUVANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13363", district:"7", Block:"36", label:"GOVT. KALLAR HSS, THEPPATHUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13364", district:"7", Block:"36", label:"PUMS, VALAIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13365", district:"7", Block:"36", label:"GOVT MODEL HSS, VIRUVEEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13366", district:"7", Block:"36", label:"PUMS, NADAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13367", district:"7", Block:"36", label:"GHSS, SEKKAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13368", district:"7", Block:"36", label:"PUMS, SENGATTAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13369", district:"7", Block:"59", label:"PANCHAYAT UNION MIDDLE SCHOOL SUKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13370", district:"7", Block:"59", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,AGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13371", district:"7", Block:"59", label:"ST.PAULS HS,ULAGAMPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13372", district:"7", Block:"59", label:"GOVT. KALLAR MIDDLE SCHOOL, KOTTURAVARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13373", district:"7", Block:"59", label:"ST.JAMES HSS,A.VELLODU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13374", district:"7", Block:"59", label:"GOVT.HSS,NARASINGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13375", district:"7", Block:"59", label:"SOUNDARARAJA VIDYALAYA, PONNAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13376", district:"7", Block:"59", label:"ST.ANTONY'S AIDED MIDDLE SCHOOL, PUDUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13377", district:"7", Block:"59", label:"GHS,MEENAKSHINAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13378", district:"7", Block:"59", label:"PUMS, KALLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13379", district:"7", Block:"59", label:"ANGUVILAS HIGHER SECONDARY SCHOOL- ANGU NAGAR, DINDIGUL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13380", district:"7", Block:"59", label:"HAJI P C NAINAR MOHAMED ORIENTAL ARABIC HIGH SCHOOL, DINDIGUL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13381", district:"7", Block:"59", label:"GOVT. KALLAR HIGHER SECONDARY SCHOOL, CHENNAMANAYAKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13382", district:"7", Block:"59", label:"GHS, CHETTINAYAKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13383", district:"7", Block:"59", label:"PUMS,UNDARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13384", district:"7", Block:"59", label:"INFANT JESUS HS,MARAVAPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13385", district:"7", Block:"59", label:"GOVERNMENT HIGH SCHOOL -THADIKOMBU", Sch_type:"Government", Category:"High School"},
    { key:"13386", district:"7", Block:"59", label:"PUMS,SEELAPPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"13387", district:"7", Block:"59", label:"SHRI MAHARISHI VIDYA MANDIR, SEELAPPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13388", district:"7", Block:"59", label:"VIDHYA PARTHI HSS,SEELAPPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13389", district:"7", Block:"59", label:"PUMS,KODANGINAYAKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13390", district:"7", Block:"59", label:"ST.JOSEPH'S MIDDLE SCHOOL, MULLIPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13391", district:"7", Block:"59", label:"PUMS,THAMARAIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"13392", district:"7", Block:"59", label:"CSMA HSS,M.M.KOVILUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13393", district:"7", Block:"59", label:"QUAID-E-MILLETH HIGH SCHOOL,RAJAKKAPATTY", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13394", district:"7", Block:"59", label:"CHEVALIER ACADEMY MATRICULATION HR. SEC. SCHOOL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13395", district:"7", Block:"59", label:"PUMS,BALAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13396", district:"7", Block:"59", label:"JEHOVAH REFORMED CHRISTIAN HIGH SCHOOL, SEELOVAMNAGAR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13397", district:"7", Block:"59", label:"PUMS,THOTTANUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"13398", district:"7", Block:"59", label:"GHSS,NALLAMANAYAKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13399", district:"7", Block:"59", label:"ST.ANTONY'S MIDDLE SCHOOL, IRENDALAPARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"13400", district:"7", Block:"59", label:"PUMS,PALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13401", district:"7", Block:"59", label:"HILLOCK INTERNATIONAL SCHOOL,SIRUMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13402", district:"7", Block:"59", label:"GOVERNMENT HIGH SCHOOL ,SIRUMALAI PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"13403", district:"7", Block:"75", label:"SARASWATHI MS NARASINGAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13404", district:"7", Block:"75", label:"GHSS R.VELLODU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13405", district:"7", Block:"75", label:"PUMS, CCC QUARRY", Sch_type:"Government", Category:"Middle School"},
    { key:"13406", district:"7", Block:"75", label:"RANI MEYYAMMAI HSS, CCC QUARRY", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13407", district:"7", Block:"75", label:"GHSS, SERVAIKARANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13408", district:"7", Block:"75", label:"PUMS, JEGALAMMANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13409", district:"7", Block:"75", label:"VOC HIGH SCHOOL, D.GUDALUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13410", district:"7", Block:"75", label:"PUMS, L.CHETTIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13411", district:"7", Block:"75", label:"GHSS, LANTHAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13412", district:"7", Block:"75", label:"PUMS, RAMAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"13413", district:"7", Block:"75", label:"THIRUMURUGAN AIDED MIDDLE SCHOOL, PALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13414", district:"7", Block:"75", label:"GHSS, GUZILIAMPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13415", district:"7", Block:"75", label:"PUMS, KARIKKALI", Sch_type:"Government", Category:"Middle School"},
    { key:"13416", district:"7", Block:"75", label:"KAMBAN VIDHYALAYA HS, SALIKARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"13417", district:"7", Block:"75", label:"PUMS, ULLIAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13418", district:"7", Block:"75", label:"PUMS, PALLANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13419", district:"7", Block:"75", label:"PUMS, KOOMBURPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13420", district:"7", Block:"75", label:"PUMS, KONDAMANAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13421", district:"7", Block:"75", label:"GHS, R.P. PILLAMANAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13422", district:"7", Block:"75", label:"PUMS, KILAKUVADUGAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"13423", district:"7", Block:"75", label:"PUMS, SUBRAMANIYAPILLAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13424", district:"7", Block:"75", label:"CHINRAJ DHANA GHSS PULIAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13425", district:"7", Block:"75", label:"PUMS, PUNGAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"13426", district:"7", Block:"75", label:"PUMS, CHINNALAGUNAICKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13427", district:"7", Block:"75", label:"PUMS, R.KOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13428", district:"7", Block:"127", label:"PUMS,GUNDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13429", district:"7", Block:"127", label:"PUMS,PALAMPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13430", district:"7", Block:"127", label:"PUMS,KOOKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"13431", district:"7", Block:"127", label:"RC MIDDLE SCHOOL, KAMARAJAPURAM,KOMBAIKADU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"13432", district:"7", Block:"127", label:"GHS,VILPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13433", district:"7", Block:"127", label:"GHSS,POOMPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13434", district:"7", Block:"127", label:"GHSS, MANNAVANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13435", district:"7", Block:"127", label:"PUMS,POLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13436", district:"7", Block:"127", label:"PUMS,MELAKILAVARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13437", district:"7", Block:"127", label:"GHS,KAVUNJI", Sch_type:"Government", Category:"High School"},
    { key:"13438", district:"7", Block:"127", label:"GHS,POONDI", Sch_type:"Government", Category:"High School"},
    { key:"13439", district:"7", Block:"127", label:"ST. JOSEPH'S HIGH SCHOOL,PERUMALMALAI, KODAIKANAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13440", district:"7", Block:"127", label:"PUMS,KUMBARIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13441", district:"7", Block:"127", label:"GHS,POOLATHUR", Sch_type:"Government", Category:"High School"},
    { key:"13442", district:"7", Block:"127", label:"GHS(GIRLS), PANNAIKADU", Sch_type:"Government", Category:"High School"},
    { key:"13443", district:"7", Block:"127", label:"GHSS,PANNAIKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13444", district:"7", Block:"127", label:"NMHS,THANDIKUDI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"13445", district:"7", Block:"127", label:"PUMS, MANGALAMCOMBU", Sch_type:"Government", Category:"Middle School"},
    { key:"13446", district:"7", Block:"127", label:"PUMS,KUPPAMMALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13447", district:"7", Block:"127", label:"GHS,KILAKKUCHETTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13448", district:"7", Block:"127", label:"PUMS, NADUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13449", district:"7", Block:"127", label:"PUMS,PACHALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13450", district:"7", Block:"127", label:"PUMS,VADAKAVUNJI", Sch_type:"Government", Category:"Middle School"},
    { key:"13451", district:"7", Block:"127", label:"ST XAVIER'S HSS, NAIDUPURAM, KODAIKANAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13452", district:"7", Block:"127", label:"CSI TOWN  MIDDLE SCHOOL,KODAIKANAL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"13453", district:"7", Block:"127", label:"CSI MIDDLE SCHOOL,PACKIAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13454", district:"7", Block:"127", label:"GHSS,KODAIKANAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13455", district:"7", Block:"127", label:"ST JOHN'S GHSS KODAIKANAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13456", district:"7", Block:"127", label:"RC TOWN HSS,KODAIKANAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13457", district:"7", Block:"127", label:"ST XAVIER'S HIGHER SECONDARY SCHOOL,SHENBAGANUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13458", district:"7", Block:"127", label:"ST PETERS MHSS,PAMBARPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13459", district:"7", Block:"127", label:"BHARATIYA VIDYA BHAVAN'S GANDHI VIDYASHRAM, INTERNATIONAL RESIDENTIAL PUBLIC SCHOOL(CBSE),KODAIKANAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13460", district:"7", Block:"127", label:"KODAI PHSS,KODAIKANAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13461", district:"7", Block:"127", label:"ST. THOMAS MATRICULATION HR. SEC.SCHOOL,NAIDUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13462", district:"7", Block:"127", label:"PRESENTATION CONVENT MATRICULATION HIGHER SECONDARY SCHOOL, NAIDUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13463", district:"7", Block:"127", label:"ST THERASA HS,PAMBARPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13464", district:"7", Block:"127", label:"KODAIKANAL INTERNATIONAL SCHOOL, KODAIKANAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13465", district:"7", Block:"207", label:"GHS,PILLAYARNATHAM", Sch_type:"Government", Category:"High School"},
    { key:"13466", district:"7", Block:"207", label:"PUMS, MAMARATHUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13467", district:"7", Block:"207", label:"GOVT.HSS. ,SENDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13468", district:"7", Block:"207", label:"SARVASEVA MIDDLE SCHOOL, METTUPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"13469", district:"7", Block:"207", label:"GHS, KUDAGIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13470", district:"7", Block:"207", label:"GHS,MANAKATTUR", Sch_type:"Government", Category:"High School"},
    { key:"13471", district:"7", Block:"207", label:"PUMS, SIRANKKATTUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13472", district:"7", Block:"207", label:"GOVT.HSS.,KOSUKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13473", district:"7", Block:"207", label:"PUMS, SORIPARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13474", district:"7", Block:"207", label:"SARVASEVA HSS,AYYANARPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13475", district:"7", Block:"207", label:"SARVA SEVA SPARROWS NEST MATRICULATION SCHOOL,AYYANARPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"13476", district:"7", Block:"207", label:"SARVA SEVA HIGH SCHOOL ,K.PUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"13477", district:"7", Block:"207", label:"GHSS, ARAVANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13478", district:"7", Block:"207", label:"PUMS, N.KURUMBAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13479", district:"7", Block:"207", label:"SARVA SEVA HIGH SCHOOL ,ANDIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"13480", district:"7", Block:"207", label:"PUMS, NALLAKANDAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13481", district:"7", Block:"207", label:"PUMS, ODUGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13482", district:"7", Block:"207", label:"SARVA SEVA MIDDLE SCHOOL,ETTAYAMPATTY", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"13483", district:"7", Block:"207", label:"GOVT HSS,SIRUGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13484", district:"7", Block:"207", label:"GOVT HSS ,SAMUTHRAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13485", district:"7", Block:"207", label:"PUMS,NADUVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"13486", district:"7", Block:"207", label:"RCMS, AVICHIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13487", district:"7", Block:"207", label:"PUMS, N. PUDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13488", district:"7", Block:"207", label:"PUMS P.MALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13489", district:"7", Block:"207", label:"GHS,KUTTUPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13490", district:"7", Block:"207", label:"PUMS,SERVEEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"13491", district:"7", Block:"207", label:"DURAIKAMALAM GOVT MODEL HIGHER SECONDARY SCHOOL, N.KOVILPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13492", district:"7", Block:"207", label:"GOVT GIRLS HIGHER SECONDARY SCHOOL, NATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13493", district:"7", Block:"207", label:"LANDIS MATRIC HIGHER SECONDARY SCHOOL,NATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13494", district:"7", Block:"207", label:"RC HIGHER SECONDARY SCHOOL,NATHAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13495", district:"7", Block:"207", label:"GHS, MULAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"13496", district:"7", Block:"207", label:"PUMS, PUNNAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13497", district:"7", Block:"207", label:"GHS, VELAYUTHAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13498", district:"7", Block:"207", label:"PUMS, SATHAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"13499", district:"7", Block:"207", label:"PUMS, VILAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13500", district:"7", Block:"207", label:"GHS, KOMANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13501", district:"7", Block:"207", label:"PUMS, PATTANAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13502", district:"7", Block:"207", label:"PUMS, KASAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13503", district:"7", Block:"207", label:"GHSS, VATHIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13504", district:"7", Block:"207", label:"GHS, LINGAVADI", Sch_type:"Government", Category:"High School"},
    { key:"13505", district:"7", Block:"207", label:"PUMS P.THETHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13506", district:"7", Block:"207", label:"PENIEL HSS,VEMPARALI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13507", district:"7", Block:"212", label:"PANCHAYAT UNION MIDDLE SCHOOL,GULLICHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13508", district:"7", Block:"212", label:"PUMS, GUNDALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13509", district:"7", Block:"212", label:"GOVT KALLAR HIGH SCHOOL SITHARKALNATHAM", Sch_type:"Government", Category:"High School"},
    { key:"13510", district:"7", Block:"212", label:"GOVT.KR.HSS,VILAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13511", district:"7", Block:"212", label:"PUMS, PILLAIYARNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13512", district:"7", Block:"212", label:"PUMS,SIRUNAYAKKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13513", district:"7", Block:"212", label:"GOVT.KR.HSS,ANAIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13514", district:"7", Block:"212", label:"PANCHAYAT UNION MIDDLE SCHOOL,E.KOVILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13515", district:"7", Block:"212", label:"GOVT.KALLAR MIDDLE SCHOOL, MUSUVANUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"13516", district:"7", Block:"212", label:"PUMS,VEELINAYAKKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13517", district:"7", Block:"212", label:"PUMS, S.THUMMALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13518", district:"7", Block:"212", label:"PANCHAYAT UNION MIDDLE SCHOOL,CHINNAMANAYAKKANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13519", district:"7", Block:"212", label:"KCM.MATRIC SCHOOL, NILAKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13520", district:"7", Block:"212", label:"GOVT. HIGHER SECONDARY SCHOOL, NILAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13521", district:"7", Block:"212", label:"HNUPR GIRLS HSS,NILAKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13522", district:"7", Block:"212", label:"ST.JOSEPHS MIDDLE SCHOOL,,SILUKKUVARPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13523", district:"7", Block:"212", label:"MUTHURAJA MIDDLE SCHOOL, KARIAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13524", district:"7", Block:"212", label:"RC.HSS,SILUKKUVARPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13525", district:"7", Block:"212", label:"SWAMI VIVEKANANDA HIGHER SECONDARY SCHOOL, SILUKKUVARPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13526", district:"7", Block:"212", label:"RC.HSS,MICHEALPALAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13527", district:"7", Block:"212", label:"GOVT.KR.HSS,KAMUPILLAICHATHRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13528", district:"7", Block:"212", label:"PUMS, J.OOTHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13529", district:"7", Block:"212", label:"PUMS, ADAIKALAMATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13530", district:"7", Block:"212", label:"PUMS, SAKKAYANAYAKKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13531", district:"7", Block:"212", label:"DIRAVIAM GHSS,KAMALAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13532", district:"7", Block:"212", label:"GOVT.ADW HIGHER SECONDARY SCHOOL, ALAGAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13533", district:"7", Block:"212", label:"RC MIDDLE SCHOOL, AMMAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13534", district:"7", Block:"212", label:"GHSS,AMMAYANAYAKANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13535", district:"7", Block:"212", label:"PUMS, POTTICHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13536", district:"7", Block:"212", label:"GOVT. KALLAR MIDDLE SCHOOL, PALLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13537", district:"7", Block:"212", label:"GHSS,PALLAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13538", district:"7", Block:"212", label:"RC MS KALLADIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13539", district:"7", Block:"212", label:"PUMS, SADAYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13540", district:"7", Block:"212", label:"GHSS, MATTAPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13541", district:"7", Block:"212", label:"GOVT. KALLAR MIDDLE SCHOOL, RAMARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13542", district:"7", Block:"213", label:"PUMS,K.KEERANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13543", district:"7", Block:"213", label:"GHSS,ODAIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13544", district:"7", Block:"213", label:"PUMS,VERIYAPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13545", district:"7", Block:"213", label:"PUMS,PERIYAKULIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13546", district:"7", Block:"213", label:"GHSS,MUTHUNAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13547", district:"7", Block:"213", label:"VIVEKANANDA VIDYALAYA HIGHER SECONDARY SCHOOL,KALANJIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13548", district:"7", Block:"213", label:"PUMS, SALAIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13549", district:"7", Block:"213", label:"AKSHAYA ACADEMY CBSE SCHOOL,LAKKAYANKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13550", district:"7", Block:"213", label:"PUMS,KOLLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13551", district:"7", Block:"213", label:"PUMS,GANDHINAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"13552", district:"7", Block:"213", label:"RC LOYALA MS,ODDANCHATRAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"13553", district:"7", Block:"213", label:"CSI MS, THUMMICHAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13554", district:"7", Block:"213", label:"K.R GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, ODDANCHATHRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13555", district:"7", Block:"213", label:"GOVT GIRLS HIGHER SECONDARY SCHOOL, ODDANCHATRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13556", district:"7", Block:"213", label:"PUMS,SAMIYARPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13557", district:"7", Block:"213", label:"PUMS,SAMATHUVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13558", district:"7", Block:"213", label:"RC FATHIMA HS,VIRUPATCHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13559", district:"7", Block:"213", label:"GHS, CHINNAKARATTUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13560", district:"7", Block:"213", label:"GHSS,PERIYAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13561", district:"7", Block:"213", label:"GHS, KAVERIAMMAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13562", district:"7", Block:"213", label:"PUMS,THANGACHIAMMAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13563", district:"7", Block:"213", label:"PUMS, AMBILIKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13564", district:"7", Block:"213", label:"SNHSS,AMBILIKAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13565", district:"7", Block:"213", label:"HINDU AIDED MIDDLE SCHOOL, CHINNAMANDAVADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13566", district:"7", Block:"213", label:"PUMS,PALAYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13567", district:"7", Block:"213", label:"GHS,PARAPPALARU", Sch_type:"Government", Category:"High School"},
    { key:"13568", district:"7", Block:"213", label:"GHSS,MARKAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13569", district:"7", Block:"213", label:"PUMS, JAVADHUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13570", district:"7", Block:"213", label:"NEHRUJI GHSS,IDAYAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13571", district:"7", Block:"213", label:"HINDU AIDED MS-KODANGIPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13572", district:"7", Block:"213", label:"GHS, PULIYAMARATHUKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"13573", district:"7", Block:"213", label:"GHSS, P.CHATRAPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13574", district:"7", Block:"213", label:"SEENIVASAN MEM.GHSS-GOPALAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13575", district:"7", Block:"213", label:"PUMS, VEERALAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13576", district:"7", Block:"213", label:"TRIUMPH ACADEMY MATRICULATION SCHOOL, D.PUDUKKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"13577", district:"7", Block:"219", label:"ST JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL, JAWAHAR NAGAR, PALANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13578", district:"7", Block:"219", label:"PUMS,KOTHAIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13579", district:"7", Block:"219", label:"VINAYAK VIDYALAYA HSS, KANAKKANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13580", district:"7", Block:"219", label:"VANAN MS, KALIKKANAICKENPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13581", district:"7", Block:"219", label:"JRC HIGHER SECONDARY SCHOOL, SHANMUGANATHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13582", district:"7", Block:"219", label:"PUMS, VARATHAMANATHI ANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13583", district:"7", Block:"219", label:"GHSS,BALASAMUDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13584", district:"7", Block:"219", label:"VELAN VIKAS MAT SCH, PM SHED", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13585", district:"7", Block:"219", label:"PUMS,THAMARAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13586", district:"7", Block:"219", label:"PUMS, K.VELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13587", district:"7", Block:"219", label:"GHS,A.KALAYAMPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"13588", district:"7", Block:"219", label:"GHS,CHINNAGANDHIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"13589", district:"7", Block:"219", label:"SARASWATHI HIGH SCHOOL, AMARAPOONDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13590", district:"7", Block:"219", label:"PUMS, GOMBAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13591", district:"7", Block:"219", label:"GHS,KANAKKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13592", district:"7", Block:"219", label:"ITO HIGHER SECONDARY SCHOOL,AYAKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13593", district:"7", Block:"219", label:"GHS,AYAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"13594", district:"7", Block:"219", label:"PUMS, OBULAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13595", district:"7", Block:"219", label:"PUMS,PERUMALPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13596", district:"7", Block:"219", label:"PUMS,R.AYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13597", district:"7", Block:"219", label:"PUMS, PETHANAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13598", district:"7", Block:"219", label:"GURUVAPPA HSS,NEIKARAPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13599", district:"7", Block:"219", label:"RENUGADEVI HSS,NEIKARAPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13600", district:"7", Block:"219", label:"RIPS,NEIKARAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13601", district:"7", Block:"219", label:"PUMS,PERIYAKALAYAMPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13602", district:"7", Block:"219", label:"PUMS, VIJAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13603", district:"7", Block:"219", label:"PUMS, AKKAMANAICKENPUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13604", district:"7", Block:"219", label:"PUMS, KUTHIRAIYARU ANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13605", district:"7", Block:"219", label:"PUMS,LAKSHMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13606", district:"7", Block:"219", label:"PUMS, ANDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13607", district:"7", Block:"219", label:"PUMS,V.P.PUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13608", district:"7", Block:"219", label:"PUMS, KUPPAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13609", district:"7", Block:"219", label:"GHSS,PAPPAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13610", district:"7", Block:"219", label:"PUMS,KAVALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13611", district:"7", Block:"219", label:"PUMS, PONTHUPULLI", Sch_type:"Government", Category:"Middle School"},
    { key:"13612", district:"7", Block:"268", label:"PUMS,PUDUETTAMANAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13613", district:"7", Block:"268", label:"GNANAM MS,KAMBILINAICKENPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13614", district:"7", Block:"268", label:"GHSS,K.PUDUKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13615", district:"7", Block:"268", label:"PUMS,GURUNATHANAICKNUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13616", district:"7", Block:"268", label:"PUMS,NAVAMARATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13617", district:"7", Block:"268", label:"PUMS,M.ASARIPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13618", district:"7", Block:"268", label:"GHSS,SULLERUMBU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13619", district:"7", Block:"268", label:"PUMS,KATHIRAYANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13620", district:"7", Block:"268", label:"PUMS,SILVARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13621", district:"7", Block:"268", label:"GKHSS,KONDAMANAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13622", district:"7", Block:"268", label:"GHS, S.VADIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13623", district:"7", Block:"268", label:"PUMS,THEPPAKULATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13624", district:"7", Block:"268", label:"PUMS,KATHIRANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13625", district:"7", Block:"268", label:"PUMS,K.ELLAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13626", district:"7", Block:"268", label:"GMS, KOTTAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13627", district:"7", Block:"268", label:"PUMS,T.PUDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13628", district:"7", Block:"268", label:"GHS,T.PANNAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13629", district:"7", Block:"268", label:"PUMS,SRIRAMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13630", district:"7", Block:"268", label:"PUMS,BOLIYAMMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13631", district:"7", Block:"268", label:"PUMS,VELLAMADATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13632", district:"7", Block:"268", label:"RADHASAMY MMS,RAJAPUDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13633", district:"7", Block:"268", label:"GHSS,THIRUMALAIRAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13634", district:"7", Block:"268", label:"PUMS,ADALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"13635", district:"7", Block:"268", label:"PUMS,PANDRIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13636", district:"7", Block:"268", label:"GHS,KARISALPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13637", district:"7", Block:"268", label:"GHSS,DHARMATHUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13638", district:"7", Block:"268", label:"PUMS,D.KOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13639", district:"7", Block:"268", label:"PUMS,KUYAVANAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13640", district:"7", Block:"268", label:"THIRUMURUGAN MS,KASAVANAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13641", district:"7", Block:"268", label:"GHSS,KONNUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13642", district:"7", Block:"268", label:"MRGGHSS,KANNIVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13643", district:"7", Block:"268", label:"GOVT. BOYS HIGHER SECONDARY SCHOOL,KANNIVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13644", district:"7", Block:"268", label:"ST.MICHEAL'S HS,AMMAPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13645", district:"7", Block:"268", label:"ARUPADAIYAPPAN HIGH SCHOOL, ERNAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"13646", district:"7", Block:"268", label:"MEENAKSHI HSS,MANGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13647", district:"7", Block:"268", label:"MEENAKSHI MATRICULATION  HIGHER SECONDARY SCHOOL, MANGARAI PIRIVU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13648", district:"7", Block:"268", label:"PUMS,PALAM RAJAKKAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13649", district:"7", Block:"268", label:"ST.FRANCIS XAVIER HSS,KUTTATHUAVARAMPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13650", district:"7", Block:"268", label:"SSM MATRICULATION HIGHER SECONDARY SCHOOL, AKKARAIPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13651", district:"7", Block:"268", label:"LOYOLA HSS,HANUMANTHARAYANKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13652", district:"7", Block:"275", label:"CARMEL MATRICULATION SCHOOL, NOCHIODAIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"13653", district:"7", Block:"275", label:"GHS,KOOVAKURUMBAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13654", district:"7", Block:"275", label:"PUMS, VADAKATTUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13655", district:"7", Block:"275", label:"RC AMALA ANNAI MS, THAVASIMADAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13656", district:"7", Block:"275", label:"PUMS, EMAKKALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13657", district:"7", Block:"275", label:"PUMS, KONAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13658", district:"7", Block:"275", label:"GHSS,SHANARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13659", district:"7", Block:"275", label:"PUMS, KANNIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13660", district:"7", Block:"275", label:"GHS,ANJUKULIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13661", district:"7", Block:"275", label:"GHS,AVILIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13662", district:"7", Block:"275", label:"RC CONVENT MS-KOSAVAPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13663", district:"7", Block:"275", label:"ST.ANTONY'S HSS,KOSAVAPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13664", district:"7", Block:"275", label:"PUMS, M.PANNAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13665", district:"7", Block:"275", label:"HINDU AIDED MS ,THEETHAMPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13666", district:"7", Block:"275", label:"ST.BRITTO HS,PUGAIYILAIPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13667", district:"7", Block:"275", label:"GHS,SILUVATHUR", Sch_type:"Government", Category:"High School"},
    { key:"13668", district:"7", Block:"275", label:"ST.MARCELIN HSS,VANGAMANOOTHU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13669", district:"7", Block:"275", label:"PUMS, K.AMMAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13670", district:"7", Block:"275", label:"PUMS, NILAPATTYPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13671", district:"7", Block:"275", label:"GHSS,KAMBILIAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13672", district:"7", Block:"275", label:"PUMS, KATTUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13673", district:"7", Block:"275", label:"PUMS, SADAIYAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13674", district:"7", Block:"275", label:"PUMS, ALAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13675", district:"7", Block:"275", label:"PUMS, S.VALLAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13676", district:"7", Block:"275", label:"GHSS,SENGURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13677", district:"7", Block:"275", label:"PUMS, MARKAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13678", district:"7", Block:"275", label:"SRI NARASIMMAN AMS, V.METTUPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13679", district:"7", Block:"275", label:"GHS, MARUNOOTHU", Sch_type:"Government", Category:"High School"},
    { key:"13680", district:"7", Block:"275", label:"PUMS, POOVAKIZHAVANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13681", district:"7", Block:"275", label:"PUMS, J.MANIYAKKARANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13682", district:"7", Block:"275", label:"GHS,T.RAMARAJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"13683", district:"7", Block:"275", label:"PUMS, SEDIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13684", district:"7", Block:"275", label:"GGHSS,GOPALPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13685", district:"7", Block:"275", label:"GHSS,VEMBARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13686", district:"7", Block:"275", label:"PUMS, K.AYYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13687", district:"7", Block:"275", label:"PUMS, PERUMAL KOVILPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13688", district:"7", Block:"275", label:"PUMS, KANAVAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13689", district:"7", Block:"275", label:"GHS,SAKKILIANKODAI", Sch_type:"Government", Category:"High School"},
    { key:"13690", district:"7", Block:"352", label:"PUMS,KALUTHARIKIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13691", district:"7", Block:"352", label:"GHS,KOOTHAMPOONDI", Sch_type:"Government", Category:"High School"},
    { key:"13692", district:"7", Block:"352", label:"GHS,KARIYAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13693", district:"7", Block:"352", label:"PUMS,PERUMALKOVILVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"13694", district:"7", Block:"352", label:"C.S.I HIGH SCHOOL,KALLIMANDAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13695", district:"7", Block:"352", label:"THIRUPPATHI ARULNERI HR.SEC.SCHOOL,KALLIMANDAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13696", district:"7", Block:"352", label:"BRINDAVAN MATRICULATION SCHOOL,KALLIMANDHAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"13697", district:"7", Block:"352", label:"PUMS,KAPPALPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13698", district:"7", Block:"352", label:"PUMS,KOTHAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13699", district:"7", Block:"352", label:"JRC H.SC,KOTHAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13700", district:"7", Block:"352", label:"GOVT.H.SCHOOL,PORULUR", Sch_type:"Government", Category:"High School"},
    { key:"13701", district:"7", Block:"352", label:"PUMS, PUNGAMUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13702", district:"7", Block:"352", label:"GHS,POOLAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13703", district:"7", Block:"352", label:"SANKAR PONNAR HSS, THUMBALAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13704", district:"7", Block:"352", label:"PUMS,VILVATHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13705", district:"7", Block:"352", label:"GOVT.H.SC,MANOOR", Sch_type:"Government", Category:"High School"},
    { key:"13706", district:"7", Block:"352", label:"MAHESH KARTHIK MATRIC HIGHER SECONDARY SCHOOL,AKKARAIPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13707", district:"7", Block:"352", label:"GOVT.HSS,THOPPAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13708", district:"7", Block:"352", label:"GURUGULAM MIDDLE SCHOOL, KEERANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13709", district:"7", Block:"352", label:"GHSS,KEERANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13710", district:"7", Block:"352", label:"C G M HSS,KORIKKADAVU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13711", district:"7", Block:"352", label:"GHSS,THALAIYUTHU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13712", district:"7", Block:"352", label:"GHS,SAMINATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"13713", district:"7", Block:"352", label:"GHS,PERICHIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13714", district:"7", Block:"352", label:"PUMS,ATHIMARATHUVALASU", Sch_type:"Government", Category:"Middle School"},
    { key:"13715", district:"7", Block:"352", label:"GHSS,KOKKARAKKAL VALASU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13716", district:"7", Block:"352", label:"MANIMEKALAI ELANGO HR SEC SCHOOL,DEVATHUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13717", district:"7", Block:"352", label:"PUMS,PODUVARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13718", district:"7", Block:"352", label:"GHSS,MANJANAICKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13719", district:"7", Block:"380", label:"PUMS,KULATHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"13720", district:"7", Block:"380", label:"PUMS,MALAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13721", district:"7", Block:"380", label:"GHS,PAGANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"13722", district:"7", Block:"380", label:"GHSS THENNAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13723", district:"7", Block:"380", label:"PUMS, ANDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13724", district:"7", Block:"380", label:"PUMS, P.KOSAVAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13725", district:"7", Block:"380", label:"GHS,PADIYUR", Sch_type:"Government", Category:"High School"},
    { key:"13726", district:"7", Block:"380", label:"PUMS, VELLAPOMMANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13727", district:"7", Block:"380", label:"GHS,VELVARKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"13728", district:"7", Block:"380", label:"PUMS, SINGARAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13729", district:"7", Block:"380", label:"GHS, K.PUDHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13730", district:"7", Block:"380", label:"PUMS VELAYUTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13731", district:"7", Block:"380", label:"GOVT GIRLS HIGHER SECONDARY SCHOOL, VADAMADURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13732", district:"7", Block:"380", label:"GBHSS VADAMADURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13733", district:"7", Block:"380", label:"PUMS, V.CHITOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"13734", district:"7", Block:"380", label:"AHS, KALAIMAGAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13735", district:"7", Block:"380", label:"PUMS G.KURUMBAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13736", district:"7", Block:"380", label:"PUMS, PILATHU", Sch_type:"Government", Category:"Middle School"},
    { key:"13737", district:"7", Block:"380", label:"PUMS,MAMMANIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13738", district:"7", Block:"380", label:"GHS,KOMBERIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13739", district:"7", Block:"380", label:"GHS,VALAVICHETTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13740", district:"7", Block:"380", label:"PUMS, SUKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13741", district:"7", Block:"380", label:"PUMS, SITHUVARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13742", district:"7", Block:"380", label:"PUMS,KOLLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13743", district:"7", Block:"380", label:"PUMS,PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13744", district:"7", Block:"380", label:"GHSS,AYYALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13745", district:"7", Block:"380", label:"PUMS,A.KOMBAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13746", district:"7", Block:"380", label:"PUMS,KUPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13747", district:"7", Block:"380", label:"PUMS,KINATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13748", district:"7", Block:"380", label:"PUMS,KARUVARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13749", district:"7", Block:"380", label:"PUMS,KAKKAYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13750", district:"7", Block:"390", label:"PUMS,ALAGAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"13751", district:"7", Block:"390", label:"PUMS,PALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13752", district:"7", Block:"390", label:"GOVT. HSS, ALAGAPURI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13753", district:"7", Block:"390", label:"PUMS, RENGANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13754", district:"7", Block:"390", label:"GOVT MODEL HR SEC SCHOOL, KASIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13755", district:"7", Block:"390", label:"NEHURU AIDED MS.KARATTUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13756", district:"7", Block:"390", label:"GHS, KOTTUR", Sch_type:"Government", Category:"High School"},
    { key:"13757", district:"7", Block:"390", label:"PUMS,MINUKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13758", district:"7", Block:"390", label:"PUMS,NAGAKKONANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13759", district:"7", Block:"390", label:"PUMS,VEDASANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13760", district:"7", Block:"390", label:"PUMS,NAGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13761", district:"7", Block:"390", label:"PUMS,SENANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13762", district:"7", Block:"390", label:"GOVT GIRLS HIGHER SECONDARY SCHOOL, VEDASANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13763", district:"7", Block:"390", label:"GOVT BOYS HR SEC SCHOOL, VEDASANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13764", district:"7", Block:"390", label:"BVM MATRICULATION, VEDASANDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"13765", district:"7", Block:"390", label:"GHS,VIRUTHALAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13766", district:"7", Block:"390", label:"PUMS,KOVAKKAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13767", district:"7", Block:"390", label:"GHSS,NAGAIYAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13768", district:"7", Block:"390", label:"PUMS,VAIVESPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13769", district:"7", Block:"390", label:"PUMS,KOVILUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13770", district:"7", Block:"390", label:"GHSS,K.RAMANATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13771", district:"7", Block:"390", label:"GHS,USILAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13772", district:"7", Block:"390", label:"GHS,CHELLAKUTTIYUR", Sch_type:"Government", Category:"High School"},
    { key:"13773", district:"7", Block:"390", label:"GHS,NALLAMANARKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"13774", district:"7", Block:"390", label:"GHS,DEVANAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13775", district:"7", Block:"390", label:"PUMS,POOTHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13776", district:"7", Block:"390", label:"ST.FRANCIS XAVIER'S R.C. MIDDLE SCHOOL, POOTHAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13777", district:"7", Block:"390", label:"GHS,MARAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"13778", district:"7", Block:"390", label:"LITTLE FLOWER GIRL'S HSS, MARAMBADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13779", district:"7", Block:"390", label:"PUMS,THOTTANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13780", district:"7", Block:"390", label:"PUMS,ERIYODU", Sch_type:"Government", Category:"Middle School"},
    { key:"13781", district:"7", Block:"390", label:"GHSS, ERIYODU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13782", district:"7", Block:"60", label:"HAZRATH AMIRUNNISHA BEGAM ORIENTAL HIGHER SECONDARY SCHOOL,WARD 38, DINDIGUL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13783", district:"7", Block:"60", label:"GHSS,PALANI ROAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13784", district:"7", Block:"60", label:"JOHN PAUL HSS,NEHRUJI NAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13785", district:"7", Block:"60", label:"JOHN PAUL ACADEMY CBSE SCHOOL,NEHRUJI NAGAR, DINDIGUL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13786", district:"7", Block:"60", label:"ST CHINNAPPAR HS,DINDIGUL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13787", district:"7", Block:"60", label:"SRI VASAVI HSS,WARD 12", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13788", district:"7", Block:"60", label:"GANDHIJI MEMORIAL MIDDLE SCHOOL, 5TH WARD, DINDIGUL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13789", district:"7", Block:"60", label:"G(G)HSS,KACHERITHERU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13790", district:"7", Block:"60", label:"ST CECILIAS MS,WARD 13", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13791", district:"7", Block:"60", label:"PUNITHA SOOSAIYAPPAR HSS,DINDIGUL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13792", district:"7", Block:"60", label:"ST. JOSEPH'S GIRLS HSS,WARD13, DINDIGUL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13793", district:"7", Block:"60", label:"MSP SOLAI NADAR MEMORIAL HSS,GTN ROAD, DINDIGUL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13794", district:"7", Block:"60", label:"OM SHANTHI CBSE SCHOOL,DINDIGUL", Sch_type:"Un-aided", Category:"High School"},
    { key:"13795", district:"7", Block:"60", label:"CSI BOARDING MS,WARD 19, DINDIGUL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"13796", district:"7", Block:"60", label:"DUDLEY HSS,DINDIGUL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13797", district:"7", Block:"60", label:"ST MARYS HSS,WARD-12", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13798", district:"7", Block:"60", label:"NEHRUJI MEM HSS, DINDIGUL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13799", district:"7", Block:"60", label:"ST FRANCIS XAVIER HSS,WARD-23, DINDIGUL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13800", district:"7", Block:"60", label:"GANDHIJI MS,WARD-24", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13801", district:"7", Block:"60", label:"T E L C MS,WARD-18", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13802", district:"7", Block:"60", label:"BHARATHI MIDDLE SCHOOL, WARD-30", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13803", district:"7", Block:"60", label:"RANIMANGAMMAL AIDED MS,WARD-33", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"13804", district:"7", Block:"60", label:"THIRU.NAMPERUMAL MIDDLE SCHOOL,WARD-31, DINDIGUL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13805", district:"7", Block:"60", label:"SANTHAI ROAD MUNICIPAL MIDDLE SCHOOL,WARD-48, DINDIGUL", Sch_type:"Government", Category:"Middle School"},
    { key:"13806", district:"7", Block:"60", label:"ARUL JOTHI VALLALAR HIGHER SECONDARY SCHOOL, DINDIGUL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13807", district:"7", Block:"60", label:"ST PHILOMINAS MS, WARD-42", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13808", district:"7", Block:"60", label:"ANNAMALAIAR MILLS GIRLS' HR.SEC.SCHOOL, DINDIGUL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13809", district:"7", Block:"60", label:"KASUVALLUVAN MIDDLE SCHOOL, DINDIGUL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13810", district:"7", Block:"60", label:"METTUPATTI MUNCP HS, WARD-37", Sch_type:"Government", Category:"High School"},
    { key:"13811", district:"7", Block:"60", label:"ST XAVIER MS,DINDIGUL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13812", district:"7", Block:"60", label:"SOWRASTRA SRI VARADHARAJA HS,WARD 47, DINDIGUL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13813", district:"7", Block:"60", label:"OUR LADY OF LOURDES GIRLS HIGHER SECONDARY SCHOOL, DINDIGUL-624002", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13814", district:"7", Block:"60", label:"TPKN MATRIC HIGHER SECONDARY SCHOOL,BEGAMPUR, DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13815", district:"7", Block:"60", label:"LOURDU MATHA MATRICULATION SCHOOL,BEGAMPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"13816", district:"7", Block:"220", label:"C.S.I WAKEMAN MS,PALANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13817", district:"7", Block:"220", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PALANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13818", district:"7", Block:"220", label:"LITTLE FLOWER HIGH SCHOOL,PALANI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13819", district:"7", Block:"220", label:"MATHANISHA MUSLIM HS,PALANI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13820", district:"7", Block:"220", label:"MUNICIPAL BAZZAR MS,PALANI", Sch_type:"Government", Category:"Middle School"},
    { key:"13821", district:"7", Block:"220", label:"MMS,SHANMUGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13822", district:"7", Block:"220", label:"STANDARD MATRICULATION SCHOOL,PALANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"13823", district:"7", Block:"220", label:"AKSHAYA ACADEMY CBSE SCHOOL,PALANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13824", district:"7", Block:"220", label:"MUNICIPAL MS,ADIVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13825", district:"7", Block:"220", label:"MUNICIPAL(B) HSS,PALANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13826", district:"7", Block:"220", label:"THIRUVALLUVAR MS,PALANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"13827", district:"12", Block:"112", label:"RANGASAMY GOUNDER HR.SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13828", district:"12", Block:"112", label:"P.E.V.R. GOVT.HR.SEC.SCH,NOYYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13829", district:"12", Block:"112", label:"GOVT.BOYS. HR.SEC SCHOOL,PUGALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13830", district:"12", Block:"112", label:"GOVT GIRLS HR SEC SCHOOL, PUGALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13831", district:"12", Block:"112", label:"GOVT.HIGH.P.THOTTAKURUCHI", Sch_type:"Government", Category:"High School"},
    { key:"13832", district:"12", Block:"112", label:"LITERA VALLEY ZEE SCHOOL,MANMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"13833", district:"12", Block:"112", label:"GOVT.HIGH.SCHOOL,N.PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"13834", district:"12", Block:"112", label:"GOVT.GIRLS.HIGHER SECONDARY SCHOOL, VANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13835", district:"12", Block:"112", label:"GOVT.HR.SEC.SCHOOL,VANGAL(B)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13836", district:"12", Block:"112", label:"GOVT.HR.SEC.SCHOOL, MANMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13837", district:"12", Block:"112", label:"SRI SARASWATHI VIDHYA MANDHIR  MATRIC HIGHER SECONDARY SCHOOL, MANMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13838", district:"12", Block:"112", label:"GOVT HIGH SCHOOL,ATHUR", Sch_type:"Government", Category:"High School"},
    { key:"13839", district:"12", Block:"112", label:"P.U.MID.SCHOOL,VENGAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"13840", district:"12", Block:"112", label:"P.U.MID SCHOOL,V.V.G NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"13841", district:"12", Block:"112", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,PERIYAKULATHUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13842", district:"12", Block:"112", label:"GOVT.GIRLS HR.SEC SCHOOL,KARUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13843", district:"12", Block:"112", label:"MPL (B) HR.SEC.SCHOOL,KARUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13844", district:"12", Block:"112", label:"CSI BOY'S HR.SEC SCHOOL,KARUR.", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13845", district:"12", Block:"112", label:"CSI GIRL'S HR.SEC SCHOOL,KARUR.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13846", district:"12", Block:"112", label:"GOVT.HIGH SCHOOL,VANGAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13847", district:"12", Block:"112", label:"GOVT.HR.SEC.SCHOOL,NERUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13848", district:"12", Block:"112", label:"P.U.MID.SCHOOL,N.PUDHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13849", district:"12", Block:"112", label:"GOVT.HR.SEC.SCHOOL,RANGANATHANPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13850", district:"12", Block:"112", label:"ADW MID.SCH,THIRUKKAMPULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13851", district:"12", Block:"112", label:"MPL KUMARAN HIGH SCH,KARUR", Sch_type:"Government", Category:"High School"},
    { key:"13852", district:"12", Block:"112", label:"MORNING STAR HIGH SCHOOL, KARUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13853", district:"12", Block:"112", label:"PASUPATHEESWARA MUNICIPAL GIRLS Hr. Sec. School, Karur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13854", district:"12", Block:"112", label:"KONGU  HR.SEC.SCHOOL, VENNAIMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13855", district:"12", Block:"112", label:"VIVEKANANDA MIDDLE SCHOOL,PASUPATHYPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"13856", district:"12", Block:"112", label:"VIVEKANANTHA BOYS HR.SEC.SCHOOL,PASUPATHIPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13857", district:"12", Block:"112", label:"SRI  SARADA  GIRLS HIGHER  SECONDARY  SCHOOL, PASUPATHIPALAYAM, KARUR-4", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13858", district:"12", Block:"112", label:"MUNICIPAL MIDDLE SCHOOL,THIRUMANILAIYUR ", Sch_type:"Government", Category:"Middle School"},
    { key:"13859", district:"12", Block:"315", label:"GOVT.HR.SEC.SCHOOL,K.P.THALAIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13860", district:"12", Block:"315", label:"GOVERNMENT.HIGH SCHOOL, ANDANKOIL EAST", Sch_type:"Government", Category:"High School"},
    { key:"13861", district:"12", Block:"315", label:"G.HIGH SCHOOL, KOYAMPALLI", Sch_type:"Government", Category:"High School"},
    { key:"13862", district:"12", Block:"315", label:"P.U.MID.SCHOOL,SUKKALIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13863", district:"12", Block:"315", label:"GOVT.HIGH.SCHOOL, PALLAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13864", district:"12", Block:"315", label:"P.A.VIDHYABHAVAN HR.SEC.SCHOOL,KAKKAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13865", district:"12", Block:"315", label:"VELAMMAL MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13866", district:"12", Block:"315", label:"P.U.M.SCHOOL,KODANKIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13867", district:"12", Block:"315", label:"P.U.MID.SCHOOL,RAYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"13868", district:"12", Block:"315", label:"G.H.SCHOOL, T.SELLANDIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"13869", district:"12", Block:"315", label:"ADW HIGH SCHOOL, SANAPRETTI", Sch_type:"Government", Category:"High School"},
    { key:"13870", district:"12", Block:"315", label:"ST.MARY'S AIDED HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"13871", district:"12", Block:"315", label:"P.U.M.SCHOOL,GANDHI GRAMAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13872", district:"12", Block:"315", label:"GOVT.HR.SEC.SCHOOL,THANTHONI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13873", district:"12", Block:"315", label:"ST.THERASA GIRLS.HIGHER SECONDARY SCHOOL, KARUR-4", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13874", district:"12", Block:"315", label:"P.U.M.SCHOOL,P. VELLALAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13875", district:"12", Block:"315", label:"DR.M.A.M.RAMASAMY CHETTIAR GHS", Sch_type:"Government", Category:"High School"},
    { key:"13876", district:"12", Block:"315", label:"RANI MEYYAMMAI HR. SEC. SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13877", district:"12", Block:"315", label:"CHETTINAD VIDYA MANDIR CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13878", district:"12", Block:"315", label:"Government Higher Secondary School ,Uppidamangalam", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13879", district:"12", Block:"315", label:"G.H.S,KARRUPUR", Sch_type:"Government", Category:"High School"},
    { key:"13880", district:"12", Block:"315", label:"ASHRAM HR. SEC. SCHOOL,MANAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13881", district:"12", Block:"315", label:"GOVT. HR. SEC SCHOOL, MANAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13882", district:"12", Block:"315", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THAMMANAICKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13883", district:"12", Block:"315", label:"P.U.M.SCHOOL,PAGANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13884", district:"12", Block:"315", label:"BHARATHI HIGH SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13885", district:"12", Block:"315", label:"P.U.M.SCHOOL,JALLIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13886", district:"12", Block:"315", label:"GOVERNMENT GIRLS HIGHER SECONDARY.SCHOOL,VELLIYANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13887", district:"12", Block:"315", label:"G.HR.SEC.SCHOOL,VELIYANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13888", district:"12", Block:"315", label:"P.U.M.SCHOOL,ALLALIGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13889", district:"12", Block:"315", label:"P.U.M.SCHOOL,AYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13890", district:"12", Block:"315", label:"P.U.M.SCHOOL,BOMMANUTHUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13891", district:"12", Block:"315", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,JEGATHABI -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13892", district:"12", Block:"315", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,PORANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13893", district:"12", Block:"315", label:"KARUR SARASWATHI VIDHYALAYA HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13894", district:"12", Block:"23", label:"GHSS, MALAIKOVILUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13895", district:"12", Block:"23", label:"P.U.M.SCHOOL,ARAVAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"13896", district:"12", Block:"23", label:"GOVT GIRLS HIGH SCHOOL,ARAVAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"13897", district:"12", Block:"23", label:"GHSS,ARAVAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13898", district:"12", Block:"23", label:"Government High School,Varikkappatti", Sch_type:"Government", Category:"High School"},
    { key:"13899", district:"12", Block:"23", label:"GHS,KOVILUR", Sch_type:"Government", Category:"High School"},
    { key:"13900", district:"12", Block:"23", label:"P.U.M.SCHOOL,INANGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13901", district:"12", Block:"23", label:"P.U.M.SCHOOL,A.VENKATAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13902", district:"12", Block:"23", label:"GHS,KURUMBAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"13903", district:"12", Block:"23", label:"P.U.M.SCHOOL,MOLAIYANDIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13904", district:"12", Block:"23", label:"GHS,SOUNDARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"13905", district:"12", Block:"23", label:"P.U.M.SCHOOL,PALLAPATTI(NEW)", Sch_type:"Government", Category:"Middle School"},
    { key:"13906", district:"12", Block:"23", label:"PALLAPATTY HSS,PALLAPATTY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13907", district:"12", Block:"23", label:"USWATHUN HASANA ORIENTAL ARABIC,PALLAPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"13908", district:"12", Block:"23", label:"GHSS,ANDIPATTIKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13909", district:"12", Block:"23", label:"P.U.M.SCHOOL,ESANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13910", district:"12", Block:"23", label:"GOVT.HIGHER.SEC.SCHOOL,ESANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13911", district:"12", Block:"23", label:"P.U.MID.SCHOOL,ZAMINATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13912", district:"12", Block:"83", label:"P.U.MID.SCHOOL,PANDILINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13913", district:"12", Block:"83", label:"GOVT.HIGH.SCHOOL,KARVAZHI", Sch_type:"Government", Category:"High School"},
    { key:"13914", district:"12", Block:"83", label:"P.U.MID.SCHOOL,ATHIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13915", district:"12", Block:"83", label:"P.U.MID..SCHOOL, PUNNAM CHATHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13916", district:"12", Block:"83", label:"GOVT.ADW.H.S.SCHOOL,PUNNAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13917", district:"12", Block:"83", label:"P.U.MID..SCHOOL, THATHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13918", district:"12", Block:"83", label:"AASEE HR SEC SCHOOL,PAVITHIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13919", district:"12", Block:"83", label:"G.H.S.PAVITHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"13920", district:"12", Block:"83", label:"GOVT.HR.SEC.SCHOOL,K.PARAMATHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13921", district:"12", Block:"83", label:"P.U.MID..SCHOOL,THULUKKAM PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13922", district:"12", Block:"83", label:"GOVT.HR.SEC.SCHOOL,THENNILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13923", district:"12", Block:"83", label:"P.U.MID.SCHOOL,M.THOTTAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"13924", district:"12", Block:"83", label:"P.U.MID..SCHOOL, KODANTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13925", district:"12", Block:"83", label:"P.U.MID.SCHOOL,C.GUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13926", district:"12", Block:"83", label:"GHS.PERIYATHIRUMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"13927", district:"12", Block:"83", label:"P.U.MID.SCHOOL,ARANGAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13928", district:"12", Block:"83", label:"GOVT. BOYS. HR..SEC.SCHOOL,CHINNADHARAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13929", district:"12", Block:"83", label:"GOVT GIRLS HR SEC SCHOOL,CHINNADHARAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13930", district:"12", Block:"83", label:"P.U.MID.SCHOOL,KASIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13931", district:"12", Block:"83", label:"P.U.MID.SCHOOL, ELLAIMETTUPUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13932", district:"12", Block:"83", label:"UMAYAL HIGH SCHOOL,EZHIL KUDIL,MUDIGANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"13933", district:"12", Block:"83", label:"GOVT HR SEC SCHOOL,THUMBIVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13934", district:"12", Block:"83", label:"P.U.MID.SCHOOL,VISWANATHAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"13935", district:"12", Block:"83", label:"P.U.MID.SCHOOL, NANJAIKALAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"13936", district:"12", Block:"83", label:"P.U.MID.SCHOOL, ELAVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13937", district:"12", Block:"83", label:"GOVT HR SEC SCHOOL,ELAVANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13938", district:"12", Block:"83", label:"P.U.MID.SCHOOL, RAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13939", district:"12", Block:"83", label:"P.U.MID.SCHOOL, NAMBAGOUNDANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13940", district:"12", Block:"140", label:"GHSS , VENGAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13941", district:"12", Block:"140", label:"ADWMS , PILLAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13942", district:"12", Block:"140", label:"GHSS , LALAPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13943", district:"12", Block:"140", label:"PUMS , MATHIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13944", district:"12", Block:"140", label:"PUMS , LALAPET WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"13945", district:"12", Block:"140", label:"ANNAI INDRA MEMORIAL HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13946", district:"12", Block:"140", label:"GOVERNMENT HIGHER SECONDARY SCHOOL , PUNAVASIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13947", district:"12", Block:"140", label:"PUMS , METUMAHADANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13948", district:"12", Block:"140", label:"PUMS , KUPPUREDDYPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13949", district:"12", Block:"140", label:"GHSS , PALAYAJAYANKONDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13950", district:"12", Block:"140", label:"PUMS , PICHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13951", district:"12", Block:"140", label:"GHSS , KRISHNARAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13952", district:"12", Block:"140", label:"KSRV HIGHER SECONDARY SCHOOL , KOVAGULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13953", district:"12", Block:"140", label:"PUMS, MALAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13954", district:"12", Block:"140", label:"GHS , METTU THIRUKAMPULIYUR", Sch_type:"Government", Category:"High School"},
    { key:"13955", district:"12", Block:"140", label:"GHS MAYANUR", Sch_type:"Government", Category:"High School"},
    { key:"13956", district:"12", Block:"140", label:"GHS, KATTALAI", Sch_type:"Government", Category:"High School"},
    { key:"13957", district:"12", Block:"140", label:"ADWHSS, CHINNAMANAYAKKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13958", district:"12", Block:"140", label:"GHS, VEERARAKKIAM", Sch_type:"Government", Category:"High School"},
    { key:"13959", district:"12", Block:"140", label:"PUMS, SENGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"13960", district:"12", Block:"140", label:"GHSS, CHINNA SENKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13961", district:"12", Block:"140", label:"PUMS, KANNAMUTHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13962", district:"12", Block:"140", label:"PUMS, PANJAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13963", district:"12", Block:"140", label:"GOVT MODEL  HR SEC  SCHOOL,  PANJAPATTY KARUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13964", district:"12", Block:"140", label:"PUMS,POTHURAVUTHANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13965", district:"12", Block:"140", label:"GHS, MUTHAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13966", district:"12", Block:"140", label:"GHS, KATTARIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13967", district:"12", Block:"140", label:"PUMS,KUPPACHIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13968", district:"12", Block:"140", label:"PUMS,VELANKATTUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13969", district:"12", Block:"140", label:"GHS, KUPPANDIYUR", Sch_type:"Government", Category:"High School"},
    { key:"13970", district:"12", Block:"140", label:"GOVERNMENT HIGHER SECONDARY SCHOOL , SENGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13971", district:"12", Block:"140", label:"PUMS, GOUNDAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13972", district:"12", Block:"88", label:"PUMS VILVAMARATHUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13973", district:"12", Block:"88", label:"PUMS, VEERIYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13974", district:"12", Block:"88", label:"GHSS,KANIYALAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13975", district:"12", Block:"88", label:"GHSS, KURUNIKULATHUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13976", district:"12", Block:"88", label:"PUMS, PAPPAYAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"13977", district:"12", Block:"88", label:"PUMS, MUTHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13978", district:"12", Block:"88", label:"PUMS, VELAYUTHAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13979", district:"12", Block:"88", label:"PUMS, KEERANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"13980", district:"12", Block:"88", label:"PUMS, THALUMBAGOUDANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"13981", district:"12", Block:"88", label:"GHS, PUDUVADI", Sch_type:"Government", Category:"High School"},
    { key:"13982", district:"12", Block:"88", label:"PUMS, MAMARATHUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13983", district:"12", Block:"88", label:"GHS, THENNILAI-KADAVOOR", Sch_type:"Government", Category:"High School"},
    { key:"13984", district:"12", Block:"88", label:"GHS, MANJAPULIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13985", district:"12", Block:"88", label:"MARIST.HR.SEC.SCHOOL, P. UDAYAPATTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"13986", district:"12", Block:"88", label:"PUMS, VARAVANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"13987", district:"12", Block:"88", label:"PUMS, ANAIGOUNDANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13988", district:"12", Block:"88", label:"PUMS, MYLAMPATTY(BOYS)", Sch_type:"Government", Category:"Middle School"},
    { key:"13989", district:"12", Block:"88", label:"PUMS, THARAGAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13990", district:"12", Block:"88", label:"GHSS, THARAGAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13991", district:"12", Block:"88", label:"SAUDIA ORIENTAL ARABIC HSS, CHINTHAMANIPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"13992", district:"12", Block:"88", label:"MODEL HR. SEC. SCHOOL, THARAGAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"13993", district:"12", Block:"88", label:"PUMS, VALVARMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"13994", district:"12", Block:"88", label:"PUMS, THASIREDDIAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13995", district:"12", Block:"88", label:"ADWHS, MAVATHUR", Sch_type:"Government", Category:"High School"},
    { key:"13996", district:"12", Block:"88", label:"PUMS, SENNAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"13997", district:"12", Block:"88", label:"GHS, NALLURANPATTY", Sch_type:"Government", Category:"High School"},
    { key:"13998", district:"12", Block:"88", label:"PUMS, PALAVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"13999", district:"12", Block:"88", label:"GHSS, PALAVIDUTHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14000", district:"12", Block:"88", label:"PUMS, E.RAJAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14001", district:"12", Block:"88", label:"PUMS, VALAYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14002", district:"12", Block:"88", label:"PUMS, K. AYYAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14003", district:"12", Block:"88", label:"GHSS, KADAVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14004", district:"12", Block:"88", label:"ANNAI LIA HIGHER SECONDARY SCHOOL, SEVAPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14005", district:"12", Block:"88", label:"KGBV, THARAGAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14006", district:"12", Block:"88", label:"PUMS, ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14007", district:"12", Block:"88", label:"PUMS, D. IDAYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14008", district:"12", Block:"88", label:"GHS, E. SUKKAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14009", district:"12", Block:"142", label:"PUMS, KUMARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14010", district:"12", Block:"142", label:"PUMS, MELAKUPPUREDDIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14011", district:"12", Block:"142", label:"THIRU.V.KA.AID.HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14012", district:"12", Block:"142", label:"PUMS, ADINATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14013", district:"12", Block:"142", label:"PUMS, MARIYAMMAN KOVIL, KULITHALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14014", district:"12", Block:"142", label:"BHARATHI VIDYALAYA HIGH SCHOOL, KULITHALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14015", district:"12", Block:"142", label:"GHSS, KULITHALAI (B)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14016", district:"12", Block:"142", label:"GHSS, KULITHALAI (G)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14017", district:"12", Block:"142", label:"PUMS, V. PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14018", district:"12", Block:"142", label:"GOVT ADW HSS, KOTTAMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14019", district:"12", Block:"142", label:"GHS, THIMMACHIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"14020", district:"12", Block:"142", label:"GHS,THIMAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14021", district:"12", Block:"142", label:"PUMS, NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14022", district:"12", Block:"142", label:"PUMS,SUKKAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14023", district:"12", Block:"142", label:"PUMS, I.PUDUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14024", district:"12", Block:"142", label:"GHSS, INUNGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14025", district:"12", Block:"142", label:"PUMS, THIRUCHAPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14026", district:"12", Block:"142", label:"PUMS, NANGAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14027", district:"12", Block:"142", label:"N.N.R.HIGH SCHOOL, NANGAVARAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14028", district:"12", Block:"142", label:"GHS, NACHALUR", Sch_type:"Government", Category:"High School"},
    { key:"14029", district:"12", Block:"142", label:"PUMS, KURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14030", district:"12", Block:"142", label:"PUMS, MADUVILUNDANPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14031", district:"12", Block:"142", label:"GHSS, AYYARMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14032", district:"12", Block:"142", label:"JOHN MAT. H.S., SIVAYAM EAST", Sch_type:"Un-aided", Category:"High School"},
    { key:"14033", district:"12", Block:"348", label:"GHSS, G.UDAIYAPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14034", district:"12", Block:"348", label:"Panchayat union middle school Muthalaipatti", Sch_type:"Government", Category:"Middle School"},
    { key:"14035", district:"12", Block:"348", label:"panchayat union middle school, veeramalaikoundanpatty", Sch_type:"Government", Category:"Middle School"},
    { key:"14036", district:"12", Block:"348", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, NEITHALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14037", district:"12", Block:"348", label:"BAGAVATHY GOVERNMENT AIDED HIGH SCHOOL, KAVANDAMPATTY", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14038", district:"12", Block:"348", label:"P.U.M.SCHOOL, CHINNAPANAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14039", district:"12", Block:"348", label:"Adi-Dravida Welfare High School,NEITHALUR", Sch_type:"Government", Category:"High School"},
    { key:"14040", district:"12", Block:"348", label:"G.H.S.SCHOOL, RACHANDAR THIRUMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14041", district:"12", Block:"348", label:"PANCHAYAT UNION MIDDLE SCHOOL,  ARCHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14042", district:"12", Block:"348", label:"PUMS, INAM  PULUTHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"14043", district:"12", Block:"348", label:"GOVERNMENT HIGH SCHOOL, BALASAMUTHIRAPATTI, KARUR", Sch_type:"Government", Category:"High School"},
    { key:"14044", district:"12", Block:"348", label:"G.H.SCHOOL, KAVALKARANPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14045", district:"12", Block:"348", label:"G.H.SCHOOL, VADASERI", Sch_type:"Government", Category:"High School"},
    { key:"14046", district:"12", Block:"348", label:"P.U.M.SCHOOL,A.NADUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14047", district:"12", Block:"348", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,KEELAVELIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14048", district:"12", Block:"348", label:"ST.ANNES HIGH SCHOOL, KALLADAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14049", district:"12", Block:"348", label:"ST.FATHIMA AIDED MID.SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14050", district:"12", Block:"348", label:"G.H.SCHOOL, PILLUR", Sch_type:"Government", Category:"High School"},
    { key:"14051", district:"12", Block:"348", label:"P.U.M.SCHOOL, NADAKKAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14052", district:"12", Block:"348", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THOGAIMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14053", district:"12", Block:"348", label:"P.U.M.SCHOOL,MAHALIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14054", district:"12", Block:"348", label:"P.U.M.SCHOOL, MOOTAKKAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14055", district:"12", Block:"348", label:"G.H.SCHOOL, A.UDAYAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14056", district:"12", Block:"348", label:"P.U.M.SCHOOL, NALLAKAVUNDAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14057", district:"12", Block:"348", label:"G.H.SCHOOL,  NAGANOOR", Sch_type:"Government", Category:"High School"},
    { key:"14058", district:"12", Block:"348", label:"P.U.M.SCHOOL, CHINNAREDDIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14059", district:"12", Block:"348", label:"GOVT.A.D.W.H.SCHOOL,THELUNGAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14060", district:"12", Block:"348", label:"PUMS,Chinnaiyampalaiyam", Sch_type:"Government", Category:"Middle School"},
    { key:"14061", district:"12", Block:"348", label:"Panchayat union middle school Sepalapatti", Sch_type:"Government", Category:"Middle School"},
    { key:"14062", district:"12", Block:"348", label:"Panchayat union middle school, PURASAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14063", district:"12", Block:"348", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ALATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14064", district:"29", Block:"15", label:"GHS UTHAMARSEELI", Sch_type:"Government", Category:"High School"},
    { key:"14065", district:"29", Block:"15", label:"GHSS, THIRUVALARSOLAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14066", district:"29", Block:"15", label:"AYYANAR CORPORATION HIGH SCHOOL MELUR", Sch_type:"Government", Category:"High School"},
    { key:"14067", district:"29", Block:"15", label:"GHS MUTHARASANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"14068", district:"29", Block:"15", label:"SRI VENKATESHWARA HSS", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14069", district:"29", Block:"15", label:"G.H.S.S THIRUCHENDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14070", district:"29", Block:"15", label:"PUMS, AMMANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"14071", district:"29", Block:"15", label:"MATHURA VIDHYALAYA MATRIC HSS, PERIYAKARUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14072", district:"29", Block:"15", label:"SRI VIVEKANANDA VIDHYAVANAM HS THIRUPARAITHURAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14073", district:"29", Block:"15", label:"SRI RAMKRISHANA VIDHYALAYA HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14074", district:"29", Block:"15", label:"VIVEKANANDA HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14075", district:"29", Block:"15", label:"PUMS, PERUGAMANI", Sch_type:"Government", Category:"Middle School"},
    { key:"14076", district:"29", Block:"15", label:"PUMS KODIYALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14077", district:"29", Block:"15", label:"GHSS,AYILAPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14078", district:"29", Block:"15", label:"AHSS RATHNA,PETTAVAITHALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14079", district:"29", Block:"15", label:"ST GABRIEL MATRICULATION HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14080", district:"29", Block:"15", label:"GHS PETTAVAITHALAI", Sch_type:"Government", Category:"High School"},
    { key:"14081", district:"29", Block:"15", label:"GHS DEVASTHANAM", Sch_type:"Government", Category:"High School"},
    { key:"14082", district:"29", Block:"15", label:"GHS KULUMANI", Sch_type:"Government", Category:"High School"},
    { key:"14083", district:"29", Block:"15", label:"GHS SEERATHOPPU", Sch_type:"Government", Category:"High School"},
    { key:"14084", district:"29", Block:"15", label:"SRI BHARATI VIDYASHRAM MAT SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"14085", district:"29", Block:"15", label:"PUMS,MULLIKARUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14086", district:"29", Block:"15", label:"G.H.S.S ETTARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14087", district:"29", Block:"15", label:"GHS KEERIKKALMEDU", Sch_type:"Government", Category:"High School"},
    { key:"14088", district:"29", Block:"15", label:"AIDED MIDDLE SCHOOL EAST RENGA", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14089", district:"29", Block:"15", label:"AIDED MIDDLE SCHOOL O.R.R ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14090", district:"29", Block:"15", label:"THE HIGHER SECONDARY SCHOOL FOR BOYS , SRIRANGAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14091", district:"29", Block:"15", label:"THE GIRLS' HIGHER SECONDARY SCHOOL, SRIRANGAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14092", district:"29", Block:"15", label:"THE KALAIVAANI MATRICULATION SCHOOL,SRIRANGAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14093", district:"29", Block:"15", label:"MPL.MS. DR. RAJAN", Sch_type:"Government", Category:"Middle School"},
    { key:"14094", district:"29", Block:"15", label:"AIDED MIDDLE SCHOOL SHANMUGA ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14095", district:"29", Block:"15", label:"MPL.MS. DEVI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"14096", district:"29", Block:"15", label:"DR.G.CHITTRAMBALAM  MAT.SCHOOL, SRI RANGAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"14097", district:"29", Block:"15", label:"MPL.M.S SRI RENGANATHA", Sch_type:"Government", Category:"Middle School"},
    { key:"14098", district:"29", Block:"15", label:"NEHRUJI MEMORIAL MPL.M.S", Sch_type:"Government", Category:"Middle School"},
    { key:"14099", district:"29", Block:"15", label:"THE HIGH SCHOOL , THIRUVANAIKOVIL ", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14100", district:"29", Block:"15", label:"SRI AKILANDESWARI VIDYALAYA THIRUVANAIKOIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"14101", district:"29", Block:"15", label:"SRI VAGEESHA VIDHYASHRAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14102", district:"29", Block:"15", label:"CORPORATION HIGH SCHOOL, TV  KOVIL", Sch_type:"Government", Category:"High School"},
    { key:"14103", district:"29", Block:"152", label:"PUMS,AGALANGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14104", district:"29", Block:"152", label:"PUMS, AGALANGANALLURN.STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"14105", district:"29", Block:"152", label:"PUMS.SOUTH CHATHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14106", district:"29", Block:"152", label:"PUMS, ARIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14107", district:"29", Block:"152", label:"TELC HIGH SCHOOL,SENGARAIYUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14108", district:"29", Block:"152", label:"P.U.M.S.ESANAKKORAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14109", district:"29", Block:"152", label:"SAINT MARY MIDDLE SCHOOL, PERIYAVARSEELI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14110", district:"29", Block:"152", label:"ADI DIRAVIDAR WELFARE  HIGHER SECONDARY SCHOOL , KEELANBIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14111", district:"29", Block:"152", label:"ST.PHILOMINA'SRCAMS,MELAPERUNGAVUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14112", district:"29", Block:"152", label:"VALLUVAR A.M.S.KOMAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14113", district:"29", Block:"152", label:"ST.ANTONY'S HSS,KONNAIKUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14114", district:"29", Block:"152", label:"P.U.MIDDLE SCHOOL, KOOHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14115", district:"29", Block:"152", label:"ST.MARY'S HIGH SCHOOL,PALLIVIDAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14116", district:"29", Block:"152", label:"ST.LEONARDI MATRIC. HIGHER SECONDARY SCHOOL,SAMAYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14117", district:"29", Block:"152", label:"P.U.M.S.MANAKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"14118", district:"29", Block:"152", label:"G.H.S.S.ANBIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14119", district:"29", Block:"152", label:"PANCHAYAT UNION MIDDLE SCHOOL, MARUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14120", district:"29", Block:"152", label:"P.U.M.S. NATHAMANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"14121", district:"29", Block:"152", label:"G.H.S. NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"14122", district:"29", Block:"152", label:"GOVERNMENT HIGH SCHOOL, MANDURAI", Sch_type:"Government", Category:"High School"},
    { key:"14123", district:"29", Block:"152", label:"P.U.M.S.PALLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14124", district:"29", Block:"152", label:"G.H.S, PERUVALANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"14125", district:"29", Block:"152", label:"GHS, PUDHUR UTHAMANUR", Sch_type:"Government", Category:"High School"},
    { key:"14126", district:"29", Block:"152", label:"AROKIA ANNAI MATRICULATION SCHOOL,PUDURUTHAMANUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"14127", district:"29", Block:"152", label:"PUMS, SATHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14128", district:"29", Block:"152", label:"INFANT JESUS HSS, S VINAYAGAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14129", district:"29", Block:"152", label:"C.M.K.A.M.S.KATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14130", district:"29", Block:"152", label:"P.U.M.S.THACHANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14131", district:"29", Block:"152", label:"G.H.S.THALAKKUDI", Sch_type:"Government", Category:"High School"},
    { key:"14132", district:"29", Block:"152", label:"P.U.M.S.THIRUMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14133", district:"29", Block:"152", label:"PANCHAYAT UNION MIDDLE SCHOOL, THIRUMANAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"14134", district:"29", Block:"152", label:"GHSS, VALADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14135", district:"29", Block:"152", label:"ST.GABRIEL'S M.S GABRIELPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14136", district:"29", Block:"152", label:"ST.ANNES HR. SEC. SCHOOL, T.V NAGAR, LALGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14137", district:"29", Block:"152", label:"G.H.S.S.LALGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14138", district:"29", Block:"152", label:"LNP GIRLS' HSS, LALGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14139", district:"29", Block:"152", label:"DON BOSCO H.S MANAKKAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"14140", district:"29", Block:"152", label:"SAI VIDHYALAYA (CBSE) SCHOOL, LALGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14141", district:"29", Block:"152", label:"Govt. Hr. Sec. School, Poovalur, Lalgudi", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14142", district:"29", Block:"172", label:"GOVT.BOYS HSC, MANNACHANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14143", district:"29", Block:"172", label:"GOVT  MODEL GIRLS HSS, MANNACHANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14144", district:"29", Block:"172", label:"AMS LALITHA, ULANTHANKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14145", district:"29", Block:"172", label:"SRIMARIAMMAN H.S.S.SAMAYAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14146", district:"29", Block:"172", label:"GHS, S.KANNANOOR", Sch_type:"Government", Category:"High School"},
    { key:"14147", district:"29", Block:"172", label:"ST.JOHN'S H.S.S IRUNGALUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14148", district:"29", Block:"172", label:"ST.XAVIER'S H.S.S, PURATHAKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14149", district:"29", Block:"172", label:"ST.THERASA'S AID. HS,KONALAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14150", district:"29", Block:"172", label:"PUMS, 85.SRIDEVI MANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14151", district:"29", Block:"172", label:"PUMS,INAMTHIRUPATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14152", district:"29", Block:"172", label:"G.H.S, PERAGAMBI", Sch_type:"Government", Category:"High School"},
    { key:"14153", district:"29", Block:"172", label:"GHS, EDUMALAI", Sch_type:"Government", Category:"High School"},
    { key:"14154", district:"29", Block:"172", label:"GHSS,SIRUGANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14155", district:"29", Block:"172", label:"M.A.M SENIOR SECONDARY (CBSE) SCHOOL, SIRUGANUR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14156", district:"29", Block:"172", label:"ADWHSS, M.R.PALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14157", district:"29", Block:"172", label:"PUMS, VAZHAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14158", district:"29", Block:"172", label:"PUMS, AZHAGIYAMANAVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14159", district:"29", Block:"172", label:"GHSS, C.AYYAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14160", district:"29", Block:"172", label:"PUMS, INAM KALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14161", district:"29", Block:"172", label:"RAMA NATARAJA HIGH SCHOOL, 94 KARIYAMANICKAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14162", district:"29", Block:"172", label:"PUMS, KOOTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14163", district:"29", Block:"172", label:"SRI VIGNESH VIDYALAYA SENIOR SEC. SCHOOL, KOOTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14164", district:"29", Block:"172", label:"PUMS, PACHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14165", district:"29", Block:"172", label:"PUMS,MADHAVAPERUMAL KOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"14166", district:"29", Block:"172", label:"PUMS, THINAIPPANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14167", district:"29", Block:"172", label:"GHSS,OMANTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14168", district:"29", Block:"172", label:"RAJA HS, BIKSHANDARKOVIL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14169", district:"29", Block:"172", label:"PUMS, RASAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14170", district:"29", Block:"172", label:"GHS,POONAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"14171", district:"29", Block:"172", label:"SRV MATRICULATION HR SEC  SCHOOL ,SAMAYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14172", district:"29", Block:"172", label:"PUMS, VEERANI", Sch_type:"Government", Category:"Middle School"},
    { key:"14173", district:"29", Block:"172", label:"GOVT ADW M.S.DEVI MANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14174", district:"29", Block:"172", label:"GANTHIYA MANYA AMS, THEERAMPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14175", district:"29", Block:"172", label:"PUMS THILLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14176", district:"29", Block:"172", label:"PUMS,VALMALPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14177", district:"29", Block:"172", label:"GHSS,THIRUPPANJILI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14178", district:"29", Block:"172", label:"G.H.S.S THIRUVELLARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14179", district:"29", Block:"172", label:"PUMS KALAVAIPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14180", district:"29", Block:"172", label:"GOVT. ADW HR. SEC. SCHOOL, THIRUVASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14181", district:"29", Block:"172", label:"PUMS, THODAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14182", district:"29", Block:"172", label:"GHSS, VALAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14183", district:"29", Block:"172", label:"PUMS, SENNAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14184", district:"29", Block:"172", label:"GHSS, SIRUGAMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14185", district:"29", Block:"172", label:"GHS, KURUVAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14186", district:"29", Block:"171", label:"ST. PATRICK'S HR. SEC. SCHOOL, ALUNDUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14187", district:"29", Block:"171", label:"ST. PATRICK'S ACADAMY (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"14188", district:"29", Block:"171", label:"HMY GOVT. HSS INAMKULATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14189", district:"29", Block:"171", label:"R V S INTERNATIONAL (CBSE) SCHOOL, INAMKULATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"14190", district:"29", Block:"171", label:"GHS, PERIYA ALAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14191", district:"29", Block:"171", label:"GOVT. HR. SEC. SCHOOL, INAM MATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14192", district:"29", Block:"171", label:"PUMS, M.G.R NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"14193", district:"29", Block:"171", label:"NEHRU AIDED MS MUTHUKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14194", district:"29", Block:"171", label:"PUMS, N.KUTTAPPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"14195", district:"29", Block:"171", label:"ANNAI INDIRA GANDHI MEMORIAL HSS, MUTHUKULAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14196", district:"29", Block:"171", label:"MARY MATHA HIGH SCHOOL, SOUTH PAGANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"14197", district:"29", Block:"171", label:"GOVT. HIGH SCHOOL, INAM PERIYANAYAGI CHATHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"14198", district:"29", Block:"171", label:"PUMS, MATTAPARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14199", district:"29", Block:"171", label:"PUMS, K.KALLIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"14200", district:"29", Block:"171", label:"PUMS, PANJAPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14201", district:"29", Block:"171", label:"GOVT. HR. SEC. SCHOOL, POONGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14202", district:"29", Block:"171", label:"ST. ANTONY'S RC AMS, NORTH PAGANUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14203", district:"29", Block:"171", label:"LAWRENCE MATRIC. HIGH SCHOOL, MULLIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"14204", district:"29", Block:"171", label:"ALPHA PLUS MATRIC HR. SEC. SCHOOL, K K NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14205", district:"29", Block:"171", label:"GOVT. HIGH SCHOOL, K K NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"14206", district:"29", Block:"171", label:"ORCHARD MATRIC. HR. SEC. SCHOOL, K K NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14207", district:"29", Block:"171", label:"PUMS, K. SATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14208", district:"29", Block:"171", label:"HOLY FAMILY R C HR. SEC. SCHOOL, AMMAPETTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14209", district:"29", Block:"171", label:"AMRITA VIDHYALAYAM (CBSE), SOLANGANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14210", district:"29", Block:"171", label:"AIDED MS,PALLAKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14211", district:"29", Block:"171", label:"ST. JOAN OF ARC INTER. SCHOOL (CBSE), SUNNAMBUKARANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14212", district:"29", Block:"171", label:"PUMS, PIRATIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14213", district:"29", Block:"171", label:"ST JOSEPH'S HR SEC SCHOOL, PUNGANUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14214", district:"29", Block:"171", label:"S.A.S MATRICULATION HR. SEC. SCHOOL, PUNGANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14215", district:"29", Block:"171", label:"PUMS, SOMARASAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14216", district:"29", Block:"171", label:"ST.JOSEPH'S HR. SEC. SCHOOL, SOMARASAMPETTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14217", district:"29", Block:"171", label:"GOVT. HR. SEC. SCHOOL, SOMARASAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14218", district:"29", Block:"171", label:"GOVT. HIGH SCHOOL, THAYANUR", Sch_type:"Government", Category:"High School"},
    { key:"14219", district:"29", Block:"171", label:"MUTHURAJA AMS, ADAVATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14220", district:"29", Block:"171", label:"SRI SIVANANDA BALALAYA CBSE & NIOS SCHOOL, ADAVATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"14221", district:"29", Block:"171", label:"RC MIDDLE SCHOOL, UYYAKONDAN THIRUMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14222", district:"29", Block:"171", label:"SRI UMA PARAMESHWARI AMS, RAMJEE NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14223", district:"29", Block:"171", label:"KALAIMAGAL AIDED HS, EDAMALAIPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14224", district:"29", Block:"171", label:"ST.JOSEPH'S HR. SEC. SCHOOL, NAGAMANGALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14225", district:"29", Block:"171", label:"GOOD SAMARITAN HS, NAGAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14226", district:"29", Block:"168", label:"PUMS, KUTHIRAIKUTHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14227", district:"29", Block:"168", label:"ST.XAVIAR MS KOTTAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14228", district:"29", Block:"168", label:"ST.MARY'S HSS, SAMARIA, MANAPARAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14229", district:"29", Block:"168", label:"GOVT HS, THOPPAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14230", district:"29", Block:"168", label:"PUMS, MARAVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14231", district:"29", Block:"168", label:"PUMS, K.PERIYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14232", district:"29", Block:"168", label:"Infant Jesus HSS, Maravanoor", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14233", district:"29", Block:"168", label:"GOVT HS, SAMUDRAM", Sch_type:"Government", Category:"High School"},
    { key:"14234", district:"29", Block:"168", label:"PUMS, MUTHAPUDAIYANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14235", district:"29", Block:"168", label:"ST.JOSEPH HR. SEC. SCHOOL, POTHAMETTUPATTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14236", district:"29", Block:"168", label:"PUMS, KATTUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14237", district:"29", Block:"168", label:"GHSS,KANNUDAYANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14238", district:"29", Block:"168", label:"MONTFORT  SCHOOL (CBSE) , MANAPARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14239", district:"29", Block:"168", label:"GADWMS, VENGAIKURICHY", Sch_type:"Government", Category:"Middle School"},
    { key:"14240", district:"29", Block:"168", label:"ST.ANTONY'S HSS, MANJAMPATTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14241", district:"29", Block:"168", label:"GOVT HS, MALAIYADIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14242", district:"29", Block:"168", label:"PUMS, A.KURUMBAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14243", district:"29", Block:"168", label:"GOVT.ADW HSS, PAMPATTIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14244", district:"29", Block:"168", label:"PUMS, KEEZHAPOIGAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14245", district:"29", Block:"168", label:"GOVT.ADW HSS, POIGAIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14246", district:"29", Block:"168", label:"THIAKESAR ALAI HR.SEC.SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14247", district:"29", Block:"168", label:"CAUVERY INTERNATIONAL  (CBSE) SCHOOL, MANAPARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14248", district:"29", Block:"168", label:"THE ALMIGHTY'S MATRICULATION SCHOOL, MANAPARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"14249", district:"29", Block:"168", label:"PUMS, F.KEELAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14250", district:"29", Block:"168", label:"GOVT HS, ANANDHANOOR", Sch_type:"Government", Category:"High School"},
    { key:"14251", district:"29", Block:"168", label:"GOVT HS, ANAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14252", district:"29", Block:"168", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PANNANKOMBU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14253", district:"29", Block:"168", label:"PUMS, VALAIYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14254", district:"29", Block:"168", label:"PUMS, PITCHAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14255", district:"29", Block:"168", label:"PUMS, PANNAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14256", district:"29", Block:"168", label:"MOHANDOSS MIDDLE SCHOOL, PERUMAMPATTY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14257", district:"29", Block:"168", label:"PUMS, KARUPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14258", district:"29", Block:"168", label:"GHSS, AZHAGAGOUNDAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14259", district:"29", Block:"168", label:"GOVT HSS, PUTHANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14260", district:"29", Block:"168", label:"PUMS, VELLAIYAGOUNDANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14261", district:"29", Block:"168", label:"PUMS, EDAIYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14262", district:"29", Block:"168", label:"ORACLE MAT.SCHOOL, PUTHANATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14263", district:"29", Block:"168", label:"THE CHALLENGERS VIDHAYALAYA MATRICULATION SCHOOL, MANAPARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14264", district:"29", Block:"168", label:"MUNICIPAL MIDDLE SCHOOL, MANAPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14265", district:"29", Block:"168", label:"NATIONAL MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14266", district:"29", Block:"168", label:"ST.ANDREW'S HSS, MANAPARAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14267", district:"29", Block:"168", label:"L.F.G.HSS, MANAPARAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14268", district:"29", Block:"168", label:"GOVT GIRLS HSS, MANAPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14269", district:"29", Block:"168", label:"GOVT BOYS HSS, MANAPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14270", district:"29", Block:"176", label:"PUMS,THOTTIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14271", district:"29", Block:"176", label:"PUMS,IRATTIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14272", district:"29", Block:"176", label:"GHSS,Kovilpatty", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14273", district:"29", Block:"176", label:"PUMS,VAGUTHALVARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14274", district:"29", Block:"176", label:"GHSS,Amanakkampatty", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14275", district:"29", Block:"176", label:"GHS,Kannivadugapatti", Sch_type:"Government", Category:"High School"},
    { key:"14276", district:"29", Block:"176", label:"GHS,Kannuthu", Sch_type:"Government", Category:"High School"},
    { key:"14277", district:"29", Block:"176", label:"GHS,Maniyankurichy", Sch_type:"Government", Category:"High School"},
    { key:"14278", district:"29", Block:"176", label:"GHS,Kallakampatty", Sch_type:"Government", Category:"High School"},
    { key:"14279", district:"29", Block:"176", label:"PUMS,KARVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14280", district:"29", Block:"176", label:"GHSS,T.SUKKAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14281", district:"29", Block:"176", label:"PUMS,MUDUKKUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14282", district:"29", Block:"176", label:"PUMS,GOUNDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14283", district:"29", Block:"176", label:"GHS,Mettupatti", Sch_type:"Government", Category:"High School"},
    { key:"14284", district:"29", Block:"176", label:"PUMS,POLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14285", district:"29", Block:"176", label:"GHS,THENUR", Sch_type:"Government", Category:"High School"},
    { key:"14286", district:"29", Block:"176", label:"PUMS,A.PUTHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14287", district:"29", Block:"176", label:"GHSS,Uthukkuli", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14288", district:"29", Block:"176", label:"PUMS,ARRIYAKKONPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14289", district:"29", Block:"176", label:"PUMS,VELLAPITCHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14290", district:"29", Block:"176", label:"GHS,VEMBANUR", Sch_type:"Government", Category:"High School"},
    { key:"14291", district:"29", Block:"176", label:"GHS,Unaiyur", Sch_type:"Government", Category:"High School"},
    { key:"14292", district:"29", Block:"176", label:"PUMS,KODUMBAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14293", district:"29", Block:"176", label:"PUMS,D.PORUVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14294", district:"29", Block:"176", label:"ST.JAMES HSS,Palakurichy", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14295", district:"29", Block:"176", label:"ST.JUSTIN GIRLS HR. SEC.SCHOOL, PALAKURICHI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14296", district:"29", Block:"176", label:"PUMS,KALINGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14297", district:"29", Block:"176", label:"GHS,Subbarayapatti", Sch_type:"Government", Category:"High School"},
    { key:"14298", district:"29", Block:"176", label:"PUMS,SOKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14299", district:"29", Block:"176", label:"PUMS,A.VALAYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14300", district:"29", Block:"176", label:"GHSS,VALANADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14301", district:"29", Block:"176", label:"GHS,T.Edayapatti", Sch_type:"Government", Category:"High School"},
    { key:"14302", district:"29", Block:"176", label:"GHSS,Marungapuri", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14303", district:"29", Block:"176", label:"PUMS,THALAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14304", district:"29", Block:"176", label:"Panchayat Union Middle school,T.SOKKANATHAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14305", district:"29", Block:"176", label:"PUMS,NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14306", district:"29", Block:"176", label:"PUMS,ADAIKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14307", district:"29", Block:"176", label:"PUMS,KARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14308", district:"29", Block:"176", label:"GHS,Kanjanayakanpatti", Sch_type:"Government", Category:"High School"},
    { key:"14309", district:"29", Block:"176", label:"PUMS,IKKARAIGOSIKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14310", district:"29", Block:"176", label:"PUMS,PIDARAPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14311", district:"29", Block:"176", label:"GHSS,Sevalpatti", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14312", district:"29", Block:"176", label:"PUMS,AKKIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14313", district:"29", Block:"176", label:"GHS,Thethur", Sch_type:"Government", Category:"High School"},
    { key:"14314", district:"29", Block:"176", label:"PUMS,ATHIKARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14315", district:"29", Block:"176", label:"PUMS,PONNAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14316", district:"29", Block:"176", label:"Govt Model Boys Hr. Sec. School,Thuvarankurichy", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14317", district:"29", Block:"176", label:"GHSS GIRLS,Thuvarankurichy", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14318", district:"29", Block:"176", label:"BHARATH MATRICULATION SCHOOL,THURVARANKURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14319", district:"29", Block:"192", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL,  AYYAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14320", district:"29", Block:"192", label:"GHSS AMOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14321", district:"29", Block:"192", label:"PUMS MANAPPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14322", district:"29", Block:"192", label:"PUMS KARUPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14323", district:"29", Block:"192", label:"PUMS KATTUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14324", district:"29", Block:"192", label:"PUMS GUNASEELAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14325", district:"29", Block:"192", label:"GHS KALLUR", Sch_type:"Government", Category:"High School"},
    { key:"14326", district:"29", Block:"192", label:"GHS KOTTATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14327", district:"29", Block:"192", label:"PUMS SOUTH SITHAMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14328", district:"29", Block:"192", label:"GHSS NORTH SITHAMBUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14329", district:"29", Block:"192", label:"PUMS THANDALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14330", district:"29", Block:"192", label:"GHS SUKKAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14331", district:"29", Block:"192", label:"GHSS THANDALAIPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14332", district:"29", Block:"192", label:"PUMS VELAKKANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14333", district:"29", Block:"192", label:"GHS THINNANUR", Sch_type:"Government", Category:"High School"},
    { key:"14334", district:"29", Block:"192", label:"GADWHSS THIRUTHALAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14335", district:"29", Block:"192", label:"PUMS MELAKOTTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14336", district:"29", Block:"192", label:"GHS THIRUTHIYAMALAI", Sch_type:"Government", Category:"High School"},
    { key:"14337", district:"29", Block:"192", label:"AMS VEERAMANIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14338", district:"29", Block:"192", label:"GHS NACHAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14339", district:"29", Block:"192", label:"PUMS NEIVELY", Sch_type:"Government", Category:"Middle School"},
    { key:"14340", district:"29", Block:"192", label:"PUMS SOUTH NALLIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14341", district:"29", Block:"192", label:"NEHRU HSS, PUTHANAMPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14342", district:"29", Block:"192", label:"GHSS,PULIVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14343", district:"29", Block:"192", label:"J.F.O.A HSS, PULIVALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14344", district:"29", Block:"192", label:"PUMS PERAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14345", district:"29", Block:"192", label:"GHS SINTHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14346", district:"29", Block:"192", label:"GHSS KARATTAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14347", district:"29", Block:"192", label:"GHS.MANBARAI", Sch_type:"Government", Category:"High School"},
    { key:"14348", district:"29", Block:"192", label:"GHSS MOOVANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14349", district:"29", Block:"192", label:"PUMS VELLAKKALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14350", district:"29", Block:"192", label:"GHS VELLUR", Sch_type:"Government", Category:"High School"},
    { key:"14351", district:"29", Block:"192", label:"PUMS ANAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14352", district:"29", Block:"192", label:"PUMS KANNUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14353", district:"29", Block:"192", label:"PUMS SANTHAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14354", district:"29", Block:"192", label:"PUMS MUSIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"14355", district:"29", Block:"192", label:"G(B)HSS MUSIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14356", district:"29", Block:"192", label:"G(G)HSS MUSIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14357", district:"29", Block:"192", label:"AMALA G HSS, MUSIRI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14358", district:"29", Block:"258", label:"GHSS,KALLAGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14359", district:"29", Block:"258", label:"PUMS,THIRANIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14360", district:"29", Block:"258", label:"PUMS,SARADAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14361", district:"29", Block:"258", label:"GHS,SIRUKALAPPUR", Sch_type:"Government", Category:"High School"},
    { key:"14362", district:"29", Block:"258", label:"GHSS,PERUVALAPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14363", district:"29", Block:"258", label:"GHSS,KANAKILIYANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14364", district:"29", Block:"258", label:"GHSS,PUDURPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14365", district:"29", Block:"258", label:"ST.PETER'S HSS,VIRAGALUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14366", district:"29", Block:"258", label:"ST.LOUIS R.C.M.SC,PULLAMBADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14367", district:"29", Block:"258", label:"GHSS PULLAMBADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14368", district:"29", Block:"258", label:"GHSS,MALVOI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14369", district:"29", Block:"258", label:"GHS,OOTATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14370", district:"29", Block:"258", label:"PUMS,ALUNTHALAIPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14371", district:"29", Block:"258", label:"PUMS,MELARASUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14372", district:"29", Block:"258", label:"PUMS,MUTHUVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14373", district:"29", Block:"258", label:"PUMS,NEIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14374", district:"29", Block:"258", label:"PUMS,THAPPOI", Sch_type:"Government", Category:"Middle School"},
    { key:"14375", district:"29", Block:"258", label:"PUMS,KUMULUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14376", district:"29", Block:"258", label:"PUMS,REDDIMANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"14377", district:"29", Block:"258", label:"PUMS,P.K.AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14378", district:"29", Block:"258", label:"ANNAI ERICA HR SEC SCHOOL P K AGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14379", district:"29", Block:"258", label:"RCMS,VANTHALAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14380", district:"29", Block:"258", label:"GHS,VANTHALAI KUDALUR", Sch_type:"Government", Category:"High School"},
    { key:"14381", district:"29", Block:"258", label:"PUMS,VELLANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14382", district:"29", Block:"258", label:"PUMS,RAMANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14383", district:"29", Block:"258", label:"R C MEETPER HS, RAMANATHAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14384", district:"29", Block:"258", label:"VETRI VIDHYALAYA HSS,VELLANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14385", district:"29", Block:"258", label:"NAVA BHARATH VIDYALAYA SENIOR SEC. SCHOOL,E.VELLANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14386", district:"29", Block:"258", label:"GHS, PUNJAI SANGENTHI", Sch_type:"Government", Category:"High School"},
    { key:"14387", district:"29", Block:"258", label:"R C JAMES MIDDLE SCHOOL, KOVANDAKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14388", district:"29", Block:"258", label:"ST MARY'S MIDDLE SCHOOL,VADUGARPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14389", district:"29", Block:"258", label:"ST JOSEPH'S GIRLS HSS,VADUGARPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14390", district:"29", Block:"258", label:"RC MIDDLE SCHOOL,KALLAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14391", district:"29", Block:"258", label:"ADWHS,KALLAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"14392", district:"29", Block:"366", label:"V.O.C.MIDDLE SCHOOL,EAST CHIND", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14393", district:"29", Block:"366", label:"U.D.V HSS, TOWN RAILWAY STATION, TRICHY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14394", district:"29", Block:"366", label:"E.R.HR.SEC.SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14395", district:"29", Block:"366", label:"THIRUVALLUVAR MID. SCH", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14396", district:"29", Block:"366", label:"ICSE KAMAKODI VIDHIYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"14397", district:"29", Block:"366", label:"RC Middle School Butterworth Road", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14398", district:"29", Block:"366", label:"MATHURAM MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14399", district:"29", Block:"366", label:"VEERAN KALVI KOODAM M.SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14400", district:"29", Block:"366", label:"SAVITRI VIDYASALA HINDU GIRLS' HIGHER SECONDARY SCHOOL, TIRUCHIRAPALLI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14401", district:"29", Block:"366", label:"ST.JOSEPH'S COLLEGE HR. SECONDARY SCHOOL, MAINGUARDGATE", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14402", district:"29", Block:"366", label:"BAKTHA THAYUMANAVAR M.SCH", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14403", district:"29", Block:"366", label:"AMS.THOTTA RAJAGOPAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14404", district:"29", Block:"366", label:"HAZARATH NATHERVALI M.SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14405", district:"29", Block:"366", label:"ST.MARYS TOPE M.SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14406", district:"29", Block:"366", label:"BISHOP HEBER BOYS HSS, TEPPAKULAM,TRICHY-2", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14407", district:"29", Block:"366", label:"NATIONAL COLLGE HSS, COLLEGE ROAD,TRICHY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14408", district:"29", Block:"366", label:"GOVT GIRLS HSS, TOWN HALL, TRICHY-2", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14409", district:"29", Block:"366", label:"CORPORATION HR SEC SCHOOL, E.B ROAD, TRICHY-8", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14410", district:"29", Block:"366", label:"AMS-ALANGAVILAS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14411", district:"29", Block:"366", label:"MUNICIPAL MIDDLE SCHOOL THARANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14412", district:"29", Block:"366", label:"MUNICIPAL MIDDLE SCHOOL KOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14413", district:"29", Block:"366", label:"T.E.L.C.M.SCHOOL KALLA ST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14414", district:"29", Block:"366", label:"SAROJAKASTHURIRENGAN M.S", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14415", district:"29", Block:"366", label:"AMS-MADARASA-E- MOHAMEADIA MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14416", district:"29", Block:"366", label:"GOVERNMENT SYED MURTHUZA MODEL HIGHER SECONDARY SCHOOL, TRICHY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14417", district:"29", Block:"366", label:"MUN.MID.SCH.VARAGANERI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"14418", district:"29", Block:"366", label:"HOLY REDEEMER'S GIRLS HSS, PALAKARAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14419", district:"29", Block:"366", label:"R.C  ST.  STANISLAUS  MIDDLE SCHOOL,PALAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14420", district:"29", Block:"366", label:"H.R.M.S", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14421", district:"29", Block:"366", label:"MUNICIPAL MIDDLE SCHOOL SENGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14422", district:"29", Block:"366", label:"MUNICIPAL MIDDLE SCHOOL BEEMANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"14423", district:"29", Block:"366", label:"KANAGA MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14424", district:"29", Block:"366", label:"MAHATMA GANDHI CENTENARY VIDYALAYA, TENNUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"14425", district:"29", Block:"366", label:"RAJAJI VIDYALAYA,TENNUR,TRICHY-17", Sch_type:"Un-aided", Category:"High School"},
    { key:"14426", district:"29", Block:"366", label:"SUBBIAH MEM.MID.SCH,TENNUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14427", district:"29", Block:"366", label:"AMS-ALL SAINTS TENNUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14428", district:"29", Block:"366", label:"TENNUR MID.SCH, TENNUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14429", district:"29", Block:"366", label:"ALL SAINTS HIGH SCHOOL, PUTHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14430", district:"29", Block:"366", label:"C.E. HSS, VANNARAPET, PUTHUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14431", district:"29", Block:"366", label:"GOVT. GIRLS SCH FOR THE BLIND , NEAR G.H ,TRICHY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14432", district:"29", Block:"366", label:"METHODIST GIRLS HR.SEC.SCHOOL, WORAIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14433", district:"29", Block:"366", label:"BISHOP HEBER BOYS HSS, PUTHUR,TRICHY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14434", district:"29", Block:"366", label:"SRI RAMAKRISHNA MID.SCH,PUTHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14435", district:"29", Block:"366", label:"MUNICIPAL MID.SCH,WORIYUREAST", Sch_type:"Government", Category:"Middle School"},
    { key:"14436", district:"29", Block:"366", label:"K A P VISVANATHAM HR SEC SCHOOL THILLAI NAGAR TIRUCHIRAPPALLI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14437", district:"29", Block:"366", label:"DR.G.SAKUNTHALA  MATRICULATION  HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14438", district:"29", Block:"366", label:"CAUVERY MATRICULATION HR. SEC. SCHOOL, BHARATHI NAGAR, TRICHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14439", district:"29", Block:"366", label:"R.C.MIDDLE,SCHOOL,WORIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14440", district:"29", Block:"366", label:"MUN.MID.SCHOOL,PANDAMANGALAM NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"14441", district:"29", Block:"366", label:"MUN.MID.SCHOOL,WORAIYUR NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"14442", district:"29", Block:"366", label:"SHRI KASTHURI RANGAN VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"14443", district:"29", Block:"366", label:"SESHA IYENGAR MEMORIAL HR. SEC. SCHOOL, WORAIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14444", district:"29", Block:"366", label:"R.C.MID.SCHOOL,KALNAYAKKAN ST.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14445", district:"29", Block:"367", label:"ADW Mid. School, Varaganeri", Sch_type:"Government", Category:"Middle School"},
    { key:"14446", district:"29", Block:"367", label:"T.S.M. High School, Varaganeri", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14447", district:"29", Block:"367", label:"Ponniah Hr. Sec. School, Hirudayapuram", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14448", district:"29", Block:"367", label:"T.E.L.C. Mid. School, Kemps Town.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14449", district:"29", Block:"367", label:"St. Antony's Mid. School, Duraisamipuram.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14450", district:"29", Block:"367", label:"Sri Yadhu Kula Sangam Mid. School, Palakarai.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14451", district:"29", Block:"367", label:"Selva Damodaran Hr. Sec. School, Palakarai", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14452", district:"29", Block:"367", label:"Corporation High School, Senthaneerpuram.", Sch_type:"Government", Category:"High School"},
    { key:"14453", district:"29", Block:"367", label:"Seven Dolloar's Mid. School, Therasalpuram.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14454", district:"29", Block:"367", label:"St. John Britto Mid. School, T.V.S.Tolgate", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14455", district:"29", Block:"367", label:"John Britto Mat. School, Subramaniyapuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"14456", district:"29", Block:"367", label:"Vestley Matric. School, T.V.S. Tollgate,", Sch_type:"Un-aided", Category:"High School"},
    { key:"14457", district:"29", Block:"367", label:"Corp. Mid. School, Subramaniapuram.", Sch_type:"Government", Category:"Middle School"},
    { key:"14458", district:"29", Block:"367", label:"R.C. Authimulam Mid. School, Subramaniapuram.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14459", district:"29", Block:"367", label:"Seventh Day Adventist MATRICULATION SCHOOL, SUBRAMANIYAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14460", district:"29", Block:"367", label:"Corporation High School, Kamaraj Nagar.", Sch_type:"Government", Category:"High School"},
    { key:"14461", district:"29", Block:"367", label:"AL-HUDHA HR. SEC. SCHOOL,AIRPORT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14462", district:"29", Block:"367", label:"St. Agnes Mid. School, Ponmalaipatti.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14463", district:"29", Block:"367", label:"St.Joseph's Girls' Hr. Sec. School, Ponmalaipatti", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14464", district:"29", Block:"367", label:"DOLOUR'S HIGH SCHOOL FOR THE DEAF,PONMALAIPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14465", district:"29", Block:"367", label:"Abbot Marcel R.C. Hr. Sec. School, Sempattu.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14466", district:"29", Block:"367", label:"Annai  Hr. Sec. School, Airport.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14467", district:"29", Block:"367", label:"Adams Mat. Hr. Sec. School, Airport.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14468", district:"29", Block:"367", label:"Infant Jesus High School, Kajamalai Colony,", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14469", district:"29", Block:"367", label:"Seva Sangam Girls Hr. Sec. School, Cantonment", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14470", district:"29", Block:"367", label:"R.C HR. SEC. SCHOOL, CANTONMENT", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14471", district:"29", Block:"367", label:"St. John Vestry A.I. Hr. Sec. School, Cantonment.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14472", district:"29", Block:"367", label:"Campion A.I. Hr. Sec. School, Cantonment.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14473", district:"29", Block:"367", label:"G. ADW. Hr. Sec. School, Milaguparai.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14474", district:"29", Block:"367", label:"KAMALA NIKETAN MONTESSORI SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14475", district:"29", Block:"367", label:"St. Anne's Mid. School,", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14476", district:"29", Block:"367", label:"St. Juliana's Mid. School,", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14477", district:"29", Block:"367", label:"B.H.M. High School, Melapudur.", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14478", district:"29", Block:"367", label:"St. Mary's High School, Melapudur,", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14479", district:"29", Block:"367", label:"ST. ANNE'S GIRLS' HR. SEC. SCHOOL, MELAPUDUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14480", district:"29", Block:"367", label:"Saraswathi Vilas Mid. School, Beemanagar", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14481", district:"29", Block:"343", label:"GHSS , ARASANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14482", district:"29", Block:"343", label:"MONTFORT SCHOOL, KATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14483", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL,GUNDUR BURMACOLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"14484", district:"29", Block:"343", label:"PANCHAYAT UNION MIDDLE SCHOOL, KANTHALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14485", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL,KEELAKALKANDARKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14486", district:"29", Block:"343", label:"GOVT HIGH SCHOOL, M.K.KOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"14487", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, KEELA MULLAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"14488", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, KILIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14489", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, VEETHIVADANGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14490", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, POLICE COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"14491", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, NAVALPATTU SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"14492", district:"29", Block:"343", label:"OFT HIGH SCHOOL, OFT", Sch_type:"Central Govt", Category:"High School"},
    { key:"14493", district:"29", Block:"343", label:"GOVT BOYS HIGH SCHOOL, ANNA NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"14494", district:"29", Block:"343", label:"GOVT GIRLS HIGH SCHOOL, ANNA NAGAR, TRICHY", Sch_type:"Government", Category:"High School"},
    { key:"14495", district:"29", Block:"343", label:"KENDRIYA VIDHALAYA -I-OFT", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"14496", district:"29", Block:"343", label:"OFT HR SEC SCHOOL , OFT ESTATE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14497", district:"29", Block:"343", label:"GOVT HR SEC SCHOOL, THENERIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14498", district:"29", Block:"343", label:"KV- II-HAPP", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"14499", district:"29", Block:"343", label:"GOVT HIGH SCHOOL, POOLANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"14500", district:"29", Block:"343", label:"ST.PHILOMINAS MID SCHOOL,KATTUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14501", district:"29", Block:"343", label:"GOVT ADW BOYS HR SEC SCHOOL, KATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14502", district:"29", Block:"343", label:"GOVT ADW GIRLS HR SEC SCHOOL, KATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14503", district:"29", Block:"343", label:"St.Antony's Higher Secondary School, Kattur,Trichy-19", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14504", district:"29", Block:"343", label:"ST.MARY'S HIGH SCHOOL, PATHALAPETTAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14505", district:"29", Block:"343", label:"GOVT ADW HIGHER SECONDARY SCHOOL, SOLAMADEVI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14506", district:"29", Block:"343", label:"NATHAN MID SCHOOL, PERIYASOORIYUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14507", district:"29", Block:"343", label:"PANCHAYAT UNON MID SCHOOL,CHINNASURIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14508", district:"29", Block:"343", label:"GOVT HIGH SCHOOL, THIRUNEDUNGULAM", Sch_type:"Government", Category:"High School"},
    { key:"14509", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL,VALAVANDANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14510", district:"29", Block:"343", label:"ST.JOSEPH HIGH SCHOOL, AYYAMPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14511", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, VENGUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14512", district:"29", Block:"343", label:"GOVT HR SEC SCHOOL, THUVAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14513", district:"29", Block:"343", label:"REC.,MID.SCHOOL, THUVAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14514", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, THUVAKUDIMALAI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"14515", district:"29", Block:"343", label:"INFANT JESUS GIRLS HR SEC SCHOOL, THUVAKUDI MALAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14516", district:"29", Block:"343", label:"RSK HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14517", district:"29", Block:"343", label:"SARATHA MIDDLE SCHOOL, KAMARAJAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14518", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, KOOTHAIPAR", Sch_type:"Government", Category:"Middle School"},
    { key:"14519", district:"29", Block:"343", label:"ST.JOSEPH MID SCHOOL, KAILASAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14520", district:"29", Block:"343", label:"BOILER PLANT MID SCHOOL, KAILASAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14521", district:"29", Block:"343", label:"BOILER PLANT GIRLS HR SEC SCHOOL, KAILASAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14522", district:"29", Block:"343", label:"BOILER PLANT BOYS HR SEC SCHOOL, KAILASAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14523", district:"29", Block:"343", label:"BHEL TAMIL MEDIUM M S", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14524", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL THIRUVERUMBUR NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"14525", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL,BAGAVATHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14526", district:"29", Block:"343", label:"MUKKULATHORE HR SE SCHOOL, THIRUVERUMBUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14527", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL, UKKADAI ARIYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14528", district:"29", Block:"343", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARIYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14529", district:"29", Block:"343", label:"R.C.MID.SCHOOL, ARIYAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14530", district:"29", Block:"343", label:"PANCHAYAT UNION MIDDLE SCHOOL, KAMARAJ NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"14531", district:"29", Block:"343", label:"PANCHAYAT UNION MID SCHOOL,THANGESWARI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"14532", district:"29", Block:"343", label:"GOVT.HIGH.SCHOOL ,AMBIKAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"14533", district:"29", Block:"343", label:"SACRED HEART HSS,PONMALAIPATTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14534", district:"29", Block:"343", label:"ST MARY'S MS MELEKAKONDAR KOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14535", district:"29", Block:"343", label:"HOLY CROSS GIRLS HR SEC SCHOOL, PONMALAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14536", district:"29", Block:"343", label:"BRINDAVAN VIDYALAYA MATRICULATION HR.SEC. SCHOOL, AMBIKAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14537", district:"29", Block:"343", label:"THE BRITISH ENGLISH MEDIUM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"14538", district:"29", Block:"343", label:"PERIYAR MANIAMMAI GIRLS HR SEC SCHOOL, PERIYAR NAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14539", district:"29", Block:"343", label:"LITTLE FLOWER HR SEC SCHOOL,CRAWFORD", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14540", district:"29", Block:"343", label:"KAJAMIAN HR SEC SCHOOL , KHAJANAGAR, TRICHY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14541", district:"29", Block:"343", label:"ST.MARY'S M.S. MANNARPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14542", district:"29", Block:"343", label:"RAILWAY MIXED HR SEC SCHOOL,PONMALAI", Sch_type:"Central Govt", Category:"High School"},
    { key:"14543", district:"29", Block:"343", label:"SAMADH HIGHER SECONDARY SCHOOL, KHAJA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14544", district:"29", Block:"343", label:"EQUITAS GURUKUL MATRIC SCHOOL,KATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14545", district:"29", Block:"353", label:"PUMS,KIDARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14546", district:"29", Block:"353", label:"GHS,NAGAIYANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"14547", district:"29", Block:"353", label:"PUMS,SRIRAMASAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14548", district:"29", Block:"353", label:"PUMS,UNNIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14549", district:"29", Block:"353", label:"GHS,THALAIMALAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14550", district:"29", Block:"353", label:"GHSS,ELURPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14551", district:"29", Block:"353", label:"GHS,UDAIYAKULAMPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"14552", district:"29", Block:"353", label:"PUMS KARTHIGAIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14553", district:"29", Block:"353", label:"ST JOSEPHS M S,THOLURPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14554", district:"29", Block:"353", label:"GHSS,BALASAMUTHIRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14555", district:"29", Block:"353", label:"NEW MULLAI HIGH SCHOOL,THOLURPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14556", district:"29", Block:"353", label:"GHS,ALAGARAI", Sch_type:"Government", Category:"High School"},
    { key:"14557", district:"29", Block:"353", label:"PUMS,THIRUEENGOIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14558", district:"29", Block:"353", label:"LALITHA UNAIDED  HIGH SCHOOL, THIRUEENGOIMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14559", district:"29", Block:"353", label:"GHS,ARANGOOR", Sch_type:"Government", Category:"High School"},
    { key:"14560", district:"29", Block:"353", label:"GHSS,NATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14561", district:"29", Block:"353", label:"GHS,THIRUNARAYANAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"14562", district:"29", Block:"353", label:"PUMS,SRINIVASANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14563", district:"29", Block:"353", label:"PUMS,KADUVETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14564", district:"29", Block:"353", label:"GHSS, KOLAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14565", district:"29", Block:"353", label:"FATHIMA M S,KOLAKKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14566", district:"29", Block:"353", label:"PUMS,SEELAIPILLAIYARPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14567", district:"29", Block:"353", label:"PUMS,THOTTIYAM-NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"14568", district:"29", Block:"353", label:"GBHSS,THOTTIYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14569", district:"29", Block:"353", label:"GHSS,THOTTIYAM GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14570", district:"29", Block:"353", label:"ST. MARYS AIDED HS, THOTTIYAM NORTH", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14571", district:"29", Block:"353", label:"MULLAI HR. SEC. SCHOOL, BALASAMUTHIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14572", district:"29", Block:"353", label:"SOWDAAMBIKAA MATRIC HSS BALASAMUTHIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14573", district:"29", Block:"353", label:"ZAMINDARS HS S,KATTUPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14574", district:"29", Block:"353", label:"GHSS, KATTUPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14575", district:"29", Block:"357", label:"PUMS,AMMAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14576", district:"29", Block:"357", label:"ADW M S ,ADHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14577", district:"29", Block:"357", label:"GADWHSS,KANNANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14578", district:"29", Block:"357", label:"GHS,KANNANUR", Sch_type:"Government", Category:"High School"},
    { key:"14579", district:"29", Block:"357", label:"PUMS SANGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14580", district:"29", Block:"357", label:"PUMS,K.KOTHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14581", district:"29", Block:"357", label:"THE KEERAMBUR HS ,KEERAMBUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14582", district:"29", Block:"357", label:"PUMS, KEELAKUNNUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14583", district:"29", Block:"357", label:"GTRHS SEMBULICHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14584", district:"29", Block:"357", label:"PUMS,V.A.SAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14585", district:"29", Block:"357", label:"GEE VEE VIDHYALAYAA MATRIC SCHOOL, SINGALANTHAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14586", district:"29", Block:"357", label:"GHS,SIKKATHAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"14587", district:"29", Block:"357", label:"GADWHS, SIKKATHAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"14588", district:"29", Block:"357", label:"GHSS,SENAPPANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14589", district:"29", Block:"357", label:"PUMS,SELLIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14590", district:"29", Block:"357", label:"PUMS,SOKKANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14591", district:"29", Block:"357", label:"GHS,NADUVALUR", Sch_type:"Government", Category:"High School"},
    { key:"14592", district:"29", Block:"357", label:"GADWHS,NAGALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"14593", district:"29", Block:"357", label:"PUMS,NALLAVANNIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14594", district:"29", Block:"357", label:"PUMS,K.PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14595", district:"29", Block:"357", label:"PUMS,KILIYANURPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14596", district:"29", Block:"357", label:"R C M S PERUMALPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14597", district:"29", Block:"357", label:"D N G H S S PERUMALPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14598", district:"29", Block:"357", label:"PUMS,PONNUSANGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14599", district:"29", Block:"357", label:"PUMS,MADHURAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"14600", district:"29", Block:"357", label:"PUMS,CHITHTHIRAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14601", district:"29", Block:"357", label:"C.S.I.H.S.S.THURAIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14602", district:"29", Block:"357", label:"AIDED M S,MARUVATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14603", district:"29", Block:"357", label:"AIDED M S,PULIYAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14604", district:"29", Block:"357", label:"SARASWATHI AIDED M S , MURUGUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14605", district:"29", Block:"357", label:"AIDED M S , T.RENGANATHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14606", district:"29", Block:"357", label:"GTRMS , NAGOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"14607", district:"29", Block:"357", label:"GTRHSS,CHINNAILUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14608", district:"29", Block:"357", label:"GHSS,SENGATUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14609", district:"29", Block:"357", label:"PUMS,VEERAMACHANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14610", district:"29", Block:"357", label:"NEHRU M S,KAVERIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14611", district:"29", Block:"357", label:"SPG M SCHOOL,THURAIYUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14612", district:"29", Block:"357", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THURAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14613", district:"29", Block:"357", label:"G ADW HSS ,THURAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14614", district:"29", Block:"357", label:"ZAMINDAR'S H S S ,THURAIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14615", district:"29", Block:"357", label:"SENGUNTHAR H S S ,THURAIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14616", district:"29", Block:"357", label:"MUNICIPAL HIGH SCHOOL ,THURAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"14617", district:"29", Block:"357", label:"RAJA HR. SEC. SCHOOL,THURAIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14618", district:"29", Block:"357", label:"KALPANA KENDHRIYA ACADEMY (CBSE) SCHOOL,MURUGOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"14619", district:"29", Block:"317", label:"GHS PILLAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"14620", district:"29", Block:"317", label:"PUMS URUDAIAPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14621", district:"29", Block:"317", label:"GHS KAARUGUDI", Sch_type:"Government", Category:"High School"},
    { key:"14622", district:"29", Block:"317", label:"AIDED MS KONAPPAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14623", district:"29", Block:"317", label:"PUMS MAHADEVI", Sch_type:"Government", Category:"Middle School"},
    { key:"14624", district:"29", Block:"317", label:"AIDED MS MANAGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14625", district:"29", Block:"317", label:"PUMS AZHAGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14626", district:"29", Block:"317", label:"PUMS AYETHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14627", district:"29", Block:"317", label:"GHSS, PAITHAMPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14628", district:"29", Block:"317", label:"GHSS, PAPPAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14629", district:"29", Block:"317", label:"GHS, SERUGUDI", Sch_type:"Government", Category:"High School"},
    { key:"14630", district:"29", Block:"317", label:"GHSS, THUMBALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14631", district:"29", Block:"317", label:"GOVT. ADW HSS THULAIYANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14632", district:"29", Block:"317", label:"PUMS, JEMBUNATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14633", district:"29", Block:"317", label:"AIED MS URAKKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14634", district:"29", Block:"317", label:"GHSS, VALASIRAMANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14635", district:"29", Block:"317", label:"PUMS, VALAIYEDUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"14636", district:"29", Block:"317", label:"GOVT HIGHER SECONDARY SCHOOL (BOYS) METTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14637", district:"29", Block:"317", label:"GHSS(G) METTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14638", district:"29", Block:"317", label:"SAMS THATHIENGARPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14639", district:"29", Block:"317", label:"GGHSS THATHIENGARPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14640", district:"29", Block:"317", label:"GHSS(B) THATHIENGARPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14641", district:"29", Block:"373", label:"PUMS, T.PATHERPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14642", district:"29", Block:"373", label:"PUMS, T.MANGAPATTI PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14643", district:"29", Block:"373", label:"GHSS, T.MURUNGAPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14644", district:"29", Block:"373", label:"ADWHS, KOPPAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14645", district:"29", Block:"373", label:"PUMS, KOPPAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14646", district:"29", Block:"373", label:"GOVT HIGH SCHOOL, NAGANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"14647", district:"29", Block:"373", label:"SVVHSS, VAIRICHETTIPALAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14648", district:"29", Block:"373", label:"GHS, SOBANAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"14649", district:"29", Block:"373", label:"AMS, VENKATACHALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14650", district:"29", Block:"373", label:"GTRHSS, TOP SENGATTUPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14651", district:"29", Block:"373", label:"GTRMS, PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14652", district:"29", Block:"373", label:"GHSS,UPPILIYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14653", district:"29", Block:"373", label:"RCMS, KOTTAPALAYAM (BOYS)", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14654", district:"29", Block:"373", label:"OLLGHSS, KOTTAPALAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14655", district:"29", Block:"373", label:"GOVT. ADW HS, BALAKRISHNAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14656", district:"29", Block:"373", label:"Government Higher Secondary School, B.METTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14657", district:"29", Block:"373", label:"PUMS, E.KEELAPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14658", district:"29", Block:"373", label:"HAMS, OKKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14659", district:"29", Block:"373", label:"AAGHSS, PACHAPERUMALPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14660", district:"29", Block:"373", label:"GHS, NETTAVELAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14661", district:"29", Block:"373", label:"PUMS, ALATHUDAIYANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14662", district:"29", Block:"373", label:"GHS, SIRUNAVALLUR", Sch_type:"Government", Category:"High School"},
    { key:"14663", district:"29", Block:"373", label:"PUMS, KAMATCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14664", district:"29", Block:"373", label:"AGMHSS, ERAGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14665", district:"29", Block:"373", label:"AMS, ALAGAPURI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14666", district:"29", Block:"381", label:"PUMS, SUNDAKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14667", district:"29", Block:"381", label:"GHS, KARUMAGOUNDANPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14668", district:"29", Block:"381", label:"GHSS, ARASUNILAIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14669", district:"29", Block:"381", label:"GHSS, ELANGAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14670", district:"29", Block:"381", label:"GHSS, THAVALAIVEERANPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14671", district:"29", Block:"381", label:"PUMS, ELAMANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14672", district:"29", Block:"381", label:"GHS, ENAMPUDHUR", Sch_type:"Government", Category:"High School"},
    { key:"14673", district:"29", Block:"381", label:"GHS, PUDUVADI", Sch_type:"Government", Category:"High School"},
    { key:"14674", district:"29", Block:"381", label:"PUMS, ANANKARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14675", district:"29", Block:"381", label:"PUMS, K-VALAYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14676", district:"29", Block:"381", label:"PUMS, THOPPANAYAKKANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14677", district:"29", Block:"381", label:"PUMS, KUMARAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14678", district:"29", Block:"381", label:"GHS, SADAYAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"14679", district:"29", Block:"381", label:"PUMS, PAMPATTIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14680", district:"29", Block:"381", label:"PUMS, PUDHUMANIYARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14681", district:"29", Block:"381", label:"R.C HSS, PERIYA ANAIKARAIPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14682", district:"29", Block:"381", label:"PUMS, KALPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14683", district:"29", Block:"381", label:"GHSS, NADUPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14684", district:"29", Block:"381", label:"GHS, V.POOSARIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14685", district:"29", Block:"381", label:"GHSS, ONTHAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14686", district:"29", Block:"381", label:"GHSS, N.POOLAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14687", district:"29", Block:"381", label:"PUMS, K.PUDUKKOTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14688", district:"29", Block:"381", label:"LITTLE FLOWER M S, KARUNGULAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"14689", district:"29", Block:"381", label:"GHSS, KARUNGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14690", district:"29", Block:"381", label:"PUMS,VAIYAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14691", district:"29", Block:"381", label:"ARIGNAR ANNA GHSS, VAIYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14692", district:"29", Block:"381", label:"PUMS, PANNAPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14693", district:"29", Block:"381", label:"PUMS  V.CHETTIYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14694", district:"29", Block:"381", label:"PUMS, INAMREDDIYAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14695", district:"1", Block:"305", label:"PUMS MANAGATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14696", district:"1", Block:"305", label:"PUMS NACHIYARPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14697", district:"1", Block:"305", label:"GOWDHAMA BUDDHAR HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14698", district:"1", Block:"305", label:"TPEVR GOVT HIGH SCHOOL ULLIYAKKUDI", Sch_type:"Government", Category:"High School"},
    { key:"14699", district:"1", Block:"305", label:"PUMS KORAIKUZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14700", district:"1", Block:"305", label:"GHS-VENMANKONDAN", Sch_type:"Government", Category:"High School"},
    { key:"14701", district:"1", Block:"305", label:"GHS-TENKACHI PERUMAL NATHAM", Sch_type:"Government", Category:"High School"},
    { key:"14702", district:"1", Block:"305", label:"PUMS THADAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14703", district:"1", Block:"305", label:"GHSS-T.PALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14704", district:"1", Block:"305", label:"GHS-NAYAGANAIPRIYAL", Sch_type:"Government", Category:"High School"},
    { key:"14705", district:"1", Block:"305", label:"PUMS-ANAIKUDAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14706", district:"1", Block:"305", label:"PUMS-PORPATHINTHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14707", district:"1", Block:"305", label:"GHS-SILAL", Sch_type:"Government", Category:"High School"},
    { key:"14708", district:"1", Block:"305", label:"PUMS-EDANKANNI", Sch_type:"Government", Category:"Middle School"},
    { key:"14709", district:"1", Block:"305", label:"GHSS-KODALIKARUPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14710", district:"1", Block:"305", label:"PUMS KODALI", Sch_type:"Government", Category:"Middle School"},
    { key:"14711", district:"1", Block:"305", label:"GHSS-UDHAYANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14712", district:"1", Block:"305", label:"PUMS-VEMBUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"14713", district:"1", Block:"305", label:"PUMS-THENNAVANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14714", district:"1", Block:"305", label:"PUMS-KADUVETTANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14715", district:"1", Block:"305", label:"GHSS-Z.SUTHAMALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14716", district:"1", Block:"305", label:"GHSS-VIKKIRAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14717", district:"1", Block:"305", label:"PUMS-KADAMPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14718", district:"1", Block:"305", label:"GHS-GUNAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"14719", district:"1", Block:"305", label:"GHS-SATHAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"14720", district:"1", Block:"305", label:"PUMS-MUTTUVANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"14721", district:"1", Block:"305", label:"PUMS-GOVINTHAPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14722", district:"1", Block:"305", label:"PUMS-KASANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14723", district:"1", Block:"305", label:"BHARATHA MATHA RC HR SEC SCHOOL NADUVALUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14724", district:"1", Block:"305", label:"PUMS-ARANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14725", district:"1", Block:"305", label:"GHSS-THIRUPURANTHAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14726", district:"1", Block:"305", label:"PUMS-ARULMOZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14727", district:"1", Block:"305", label:"GHSS-KARAIKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14728", district:"1", Block:"305", label:"PUMS-MATHANATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14729", district:"18", Block:"400", label:"PUMS THIRUMANDURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14730", district:"18", Block:"400", label:"GHS VADAKKALUR", Sch_type:"Government", Category:"High School"},
    { key:"14731", district:"18", Block:"400", label:"GHSS OGALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14732", district:"18", Block:"400", label:"GHSS - S.ADUTHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14733", district:"18", Block:"400", label:"GHS KILUMATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14734", district:"18", Block:"400", label:"GHS ATHIYUR", Sch_type:"Government", Category:"High School"},
    { key:"14735", district:"18", Block:"400", label:"PUMS AGARAM SEEGOOR-E", Sch_type:"Government", Category:"Middle School"},
    { key:"14736", district:"18", Block:"400", label:"Aided MS, Vayalur", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14737", district:"18", Block:"400", label:"PUMS VASISTAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14738", district:"18", Block:"400", label:"PUMS MELAKALINGARANANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14739", district:"18", Block:"400", label:"GHSS KEELAPERAMBALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14740", district:"18", Block:"400", label:"GMS VELVIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14741", district:"18", Block:"400", label:"PUMS-VAYALAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14742", district:"18", Block:"400", label:"PUMS V.KEERANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14743", district:"18", Block:"400", label:"PUMS KALLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14744", district:"18", Block:"400", label:"PUMS-OLAIPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14745", district:"18", Block:"400", label:"GHSS VEPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14746", district:"18", Block:"400", label:"RC STJOHN HS PERUMATHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14747", district:"18", Block:"400", label:"PUMS-LABBAIKUDIKADU-W", Sch_type:"Government", Category:"Middle School"},
    { key:"14748", district:"18", Block:"400", label:"SALAMATH M SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"14749", district:"18", Block:"400", label:"GGHSS LABBAIKUDIKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14750", district:"18", Block:"400", label:"GHSS(B)-LABBAIKUDIKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14751", district:"18", Block:"400", label:"HAJI ABDULA SHA MATRICULATION HIGH SCHOOL PENNAKONAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14752", district:"18", Block:"400", label:"GHSS THUNGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14753", district:"18", Block:"400", label:"PANDIAN AIDED.MID.S.NALLARIKAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14754", district:"18", Block:"400", label:"Chidambaram MS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14755", district:"18", Block:"400", label:"PUMS KOTHAVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"14756", district:"18", Block:"400", label:"GHS VARAGOOR", Sch_type:"Government", Category:"High School"},
    { key:"14757", district:"18", Block:"7", label:"SARASWATHI VIDYALAYA HIGHER SECONDARY SCHOOL VARISAIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14758", district:"18", Block:"400", label:"GHS PARAVAI", Sch_type:"Government", Category:"High School"},
    { key:"14759", district:"18", Block:"400", label:"TELC, Aykudi", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14760", district:"18", Block:"400", label:"GHS ELUMUR", Sch_type:"Government", Category:"High School"},
    { key:"14761", district:"18", Block:"400", label:"GHS KARUGUDI", Sch_type:"Government", Category:"High School"},
    { key:"14762", district:"18", Block:"400", label:"PUMS ANDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14763", district:"18", Block:"400", label:"ST.PAUL  MATRICULATION HIGHER SECONDARY SCHOOL  ANTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14764", district:"18", Block:"400", label:"GHS  ASUR", Sch_type:"Government", Category:"High School"},
    { key:"14765", district:"18", Block:"400", label:"VAANPUGAL VALLUVAR HSS OTHIYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14766", district:"18", Block:"400", label:"GHS OTHIYAM", Sch_type:"Government", Category:"High School"},
    { key:"14767", district:"18", Block:"400", label:"GBHSS KUNNAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14768", district:"18", Block:"400", label:"GGHSS KUNNAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14769", district:"18", Block:"400", label:"GHS - PERIYAMMAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"14770", district:"18", Block:"400", label:"GHSS-PERALI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14771", district:"18", Block:"400", label:"GHSS MARUVATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14772", district:"18", Block:"400", label:"KASTHURBA GANDHI BALIKA VIDHYALAYA  SCHOOL  PERALI", Sch_type:"Government", Category:"Middle School"},
    { key:"14773", district:"18", Block:"400", label:"PUMS SITHALI", Sch_type:"Government", Category:"Middle School"},
    { key:"14774", district:"18", Block:"400", label:"GHS- PEELVADI", Sch_type:"Government", Category:"High School"},
    { key:"14775", district:"18", Block:"400", label:"GHSS KEELAPULIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14776", district:"18", Block:"400", label:"SWAMY VIVEKANANDA MATRICULATION HIGHER SECONDARY SCHOOL KEELAPULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14777", district:"18", Block:"400", label:"PUMS SIRUGUDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"14778", district:"18", Block:"400", label:"GHS MURUKKANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"14779", district:"18", Block:"400", label:"GHS KADUR", Sch_type:"Government", Category:"High School"},
    { key:"14780", district:"18", Block:"399", label:"PUMS VELLUVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14781", district:"18", Block:"399", label:"GHS KARIYANOOR", Sch_type:"Government", Category:"High School"},
    { key:"14782", district:"18", Block:"399", label:"GHSS KAI.KALATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14783", district:"18", Block:"399", label:"MAHATHMA MATRICULATION HIGH SCHOOL KAIKALATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"14784", district:"18", Block:"399", label:"GHS METTUPPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"14785", district:"18", Block:"399", label:"PUMS-V.KALATHUR(G)", Sch_type:"Government", Category:"Middle School"},
    { key:"14786", district:"18", Block:"399", label:"GHSS V. KALATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14787", district:"18", Block:"399", label:"HIDAYATH MATRICULATION  HIGH SCHOOL, V.KALATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"14788", district:"18", Block:"399", label:"ST MARY'S HSS  AGARAM V.KALATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14789", district:"18", Block:"399", label:"GHSS NERKUNAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14790", district:"18", Block:"399", label:"T.ROVER.H.S.NERUKUNAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14791", district:"18", Block:"399", label:"GHSS PASUMBALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14792", district:"18", Block:"399", label:"ADWHS PASUMBALUR", Sch_type:"Government", Category:"High School"},
    { key:"14793", district:"18", Block:"399", label:"PUMS, PILLANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14794", district:"18", Block:"399", label:"PUMS, SIRUNILA", Sch_type:"Government", Category:"Middle School"},
    { key:"14795", district:"18", Block:"399", label:"GHSS VALIKANDAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14796", district:"18", Block:"399", label:"ST.JOHN.M.SCHOOL,THIRUVALANDURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14797", district:"18", Block:"399", label:"GHSS RANJANKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14798", district:"18", Block:"399", label:"PUMS-AYYANPERIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14799", district:"18", Block:"399", label:"GHS VENPAVUR", Sch_type:"Government", Category:"High School"},
    { key:"14800", district:"18", Block:"399", label:"PUMS N.PUDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14801", district:"18", Block:"399", label:"GHS NEIKUPAI", Sch_type:"Government", Category:"High School"},
    { key:"14802", district:"18", Block:"399", label:"GHS NOOTHAPUR", Sch_type:"Government", Category:"High School"},
    { key:"14803", district:"18", Block:"399", label:"RC.M.S,ERAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14804", district:"18", Block:"399", label:"TELC M S,ERAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14805", district:"18", Block:"399", label:"NEHRU HSS ERAIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14806", district:"18", Block:"399", label:"GHSS ANUKKUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14807", district:"18", Block:"399", label:"GHSS ARUMBAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14808", district:"18", Block:"399", label:"SHANTHI NIKETHAN MATRICULATION HIGHER SECONDARY SCHOOL  ARUMBAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14809", district:"18", Block:"399", label:"SRI RAGAVENDRA HR. SEC. SCHOOL, ARUMBAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14810", district:"18", Block:"399", label:"PUMS UDUMBIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14811", district:"18", Block:"399", label:"PUMS KALLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"14812", district:"18", Block:"399", label:"EDEN GARDENS MATRIC HR. SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14813", district:"18", Block:"399", label:"EDEN GARDENS HR.SEC.SCHOOL UDUMBIAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14814", district:"18", Block:"399", label:"PUMS, MOHAMEDPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14815", district:"18", Block:"399", label:"PUMS,BRAMMADESAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14816", district:"18", Block:"399", label:"GHSS POOLAMBADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14817", district:"18", Block:"399", label:"ST.MARY M.S, THONDAMANDURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14818", district:"18", Block:"399", label:"PUMS, VIJAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14819", district:"18", Block:"399", label:"GHS THONDAMANDURAI", Sch_type:"Government", Category:"High School"},
    { key:"14820", district:"18", Block:"399", label:"GHSS VENKALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14821", district:"18", Block:"399", label:"SHRI MATHURA MATRIC  SCHOOL KRISHNAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14822", district:"18", Block:"399", label:"ANNAI MATRICULATION  HIGH SCHOOL  VENGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14823", district:"18", Block:"399", label:"GHSS  VEPPANTHATTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14824", district:"18", Block:"399", label:"PUMS, A.METTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14825", district:"18", Block:"399", label:"GHS GOUNDERPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"14826", district:"18", Block:"399", label:"RC. M.SCHOOL.FATHIMAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14827", district:"18", Block:"399", label:"LFHSS ANNAMANGALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14828", district:"18", Block:"236", label:"GHSS PERAMBALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14829", district:"18", Block:"236", label:"MOULANA HSS-PERAMBALUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14830", district:"18", Block:"236", label:"THANTHAI HANS ROEVER HSS-PERAMBALUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14831", district:"18", Block:"236", label:"TELC MID SCH THURAIMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14832", district:"18", Block:"236", label:"ST.DOMINIC'S GIRLS HIGHER SECONDARY SCHOOL, PERAMBALUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14833", district:"18", Block:"236", label:"PANIMALAR HSS-PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14834", district:"18", Block:"236", label:"SRI SARADHA DEVI GIRLS HR. SEC. SCHOOL - PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14835", district:"18", Block:"236", label:"SRI RAMAKRISHNA GIRLS MATRICULATION HR.SEC SCHOOL PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14836", district:"18", Block:"236", label:"THANTHAI ROEVER MATRICULATION HIGH SCHOOL  PERAMBALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"14837", district:"18", Block:"236", label:"DHANALAKSHMI SRINIVASAN HSS-PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14838", district:"18", Block:"236", label:"DHANALAKSHMI SRINIVASAN MATRICULATION HIGHER SECONDARY SCHOOL, PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14839", district:"18", Block:"236", label:"KEN-VIDYALA-PERAMBALUR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"14840", district:"18", Block:"236", label:"GOWTHAMABUTHAR-PER", Sch_type:"Un-aided", Category:"High School"},
    { key:"14841", district:"18", Block:"236", label:"HANS-ROVER-CBSE-PER", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14842", district:"18", Block:"236", label:"ALMIGHTY VIDHYALAYA PUBLIC  SCHOOL SIRUVACHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14843", district:"18", Block:"236", label:"PUMS NOCHIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14844", district:"18", Block:"236", label:"PUMS,K.ERAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14845", district:"18", Block:"236", label:"PUMS,ARANARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14846", district:"18", Block:"236", label:"RCMS-PALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14847", district:"18", Block:"236", label:"GHS-THAMBIRANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"14848", district:"18", Block:"236", label:"GHSS-KAVULPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14849", district:"18", Block:"236", label:"GHSS CHATHIRAMANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14850", district:"18", Block:"236", label:"GHSS_KURUMBALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14851", district:"18", Block:"236", label:"GHSS-SIRUVACHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14852", district:"18", Block:"236", label:"GHS-VELLUR", Sch_type:"Government", Category:"High School"},
    { key:"14853", district:"18", Block:"236", label:"PUMS,BOMMANAPADY", Sch_type:"Government", Category:"Middle School"},
    { key:"14854", district:"18", Block:"236", label:"PUMS,AYYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14855", district:"18", Block:"236", label:"GHS_KALPADI", Sch_type:"Government", Category:"High School"},
    { key:"14856", district:"18", Block:"236", label:"PUMS, ARUMADAL", Sch_type:"Government", Category:"Middle School"},
    { key:"14857", district:"18", Block:"236", label:"PUMS-ESANAI(N)", Sch_type:"Government", Category:"Middle School"},
    { key:"14858", district:"18", Block:"236", label:"GHSS ESANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14859", district:"18", Block:"236", label:"PUMS,KONERIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14860", district:"18", Block:"236", label:"GHS LADAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"14861", district:"18", Block:"236", label:"ADWHS-LADAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"14862", district:"18", Block:"236", label:"GHSS_MELAPULIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14863", district:"18", Block:"236", label:"PUMS THIRUPEYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"14864", district:"18", Block:"236", label:"GHSS AMMAPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14865", district:"18", Block:"236", label:"MRV-AMMAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14866", district:"18", Block:"236", label:"ADWHSS-KALARAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14867", district:"18", Block:"236", label:"TRHS-INDIRA NAGAR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14868", district:"18", Block:"236", label:"GHSS-ELAMBALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14869", district:"18", Block:"236", label:"PUMS, SENJERI", Sch_type:"Government", Category:"Middle School"},
    { key:"14870", district:"18", Block:"236", label:"ADWHS-ALAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"14871", district:"18", Block:"236", label:"GHS-SENGUNAM", Sch_type:"Government", Category:"High School"},
    { key:"14872", district:"1", Block:"286", label:"GHS ANGANUR", Sch_type:"Government", Category:"High School"},
    { key:"14873", district:"1", Block:"286", label:"GHS SANNASINALLUR", Sch_type:"Government", Category:"High School"},
    { key:"14874", district:"1", Block:"286", label:"GHSS-DALAVOI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14875", district:"1", Block:"286", label:"PUMS ALATHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14876", district:"1", Block:"286", label:"VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL ALATHIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14877", district:"1", Block:"286", label:"GOVT.HR.SEC.SCHOOL,MULLUKKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14878", district:"1", Block:"286", label:"PUMS PUDUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14879", district:"1", Block:"286", label:"PUMS MANAKKUDAIYAN", Sch_type:"Government", Category:"Middle School"},
    { key:"14880", district:"1", Block:"286", label:"PUMS MULLAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14881", district:"1", Block:"286", label:"ST PHILOMINAL HR SEC SCHOOL KULUMUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14882", district:"1", Block:"286", label:"GHS-MANAPATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14883", district:"1", Block:"286", label:"AIDED MID SCH MUDUKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14884", district:"1", Block:"286", label:"PUMS KURICHIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14885", district:"1", Block:"286", label:"GHSS-KURUCHIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14886", district:"1", Block:"286", label:"ANNAI HIGH SCHOOL MARAKURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"14887", district:"1", Block:"286", label:"KALAIMAGAL -HR-SEC-SCHOOL -THULAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14888", district:"1", Block:"286", label:"PUMS SIRUKALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14889", district:"1", Block:"286", label:"GHS-NALLAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"14890", district:"1", Block:"286", label:"VETRIVINAYAGA HR SEC SCHOOL NALLAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14891", district:"1", Block:"286", label:"GHSS-PONPARAPPI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14892", district:"1", Block:"286", label:"PUMS PONPARAPPI COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"14893", district:"1", Block:"286", label:"PUMS KEELAMALIGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14894", district:"1", Block:"286", label:"AIDED MID NAGALKUZHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14895", district:"1", Block:"286", label:"GOVT HSS PARANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14896", district:"1", Block:"286", label:"AIDED MID KUMIZHIYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14897", district:"1", Block:"286", label:"PUMS PALAYAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"14898", district:"1", Block:"286", label:"GHSS-IRUMBULIKURUCHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14899", district:"1", Block:"286", label:"AIDED MID VEERAKKAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14900", district:"1", Block:"286", label:"GHS-SIRUKADAMPUR", Sch_type:"Government", Category:"High School"},
    { key:"14901", district:"1", Block:"286", label:"GHSS-ANANDAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14902", district:"1", Block:"286", label:"GHS-UNJINI", Sch_type:"Government", Category:"High School"},
    { key:"14903", district:"1", Block:"286", label:"PUMS MARUVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14904", district:"1", Block:"286", label:"GHS-PERIYAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"14905", district:"1", Block:"286", label:"PUMS VANJINAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14906", district:"1", Block:"286", label:"PUMS CHOKKANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14907", district:"1", Block:"286", label:"PUMS NAKKAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14908", district:"1", Block:"286", label:"GHSS MODEL SCHOOL-SENDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14909", district:"1", Block:"286", label:"GHSS-SENDURAI(GIRLS)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14910", district:"1", Block:"286", label:"ARIGNAR ANNA HR SEC SCHOOL SENDURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14911", district:"1", Block:"286", label:"GHS-CHOLANKUDIKKADU", Sch_type:"Government", Category:"High School"},
    { key:"14912", district:"18", Block:"7", label:"GHSS  THENUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14913", district:"18", Block:"7", label:"GHS T. KALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14914", district:"18", Block:"7", label:"PUMS PUDUAMMAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14915", district:"18", Block:"7", label:"GHSS NAKASELAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14916", district:"18", Block:"7", label:"ARIVALAYAM HS NAKKASELAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14917", district:"18", Block:"7", label:"VALLALAR AIDED MIDDLE SCHOOL, CHETTIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14918", district:"18", Block:"7", label:"GHSS CHETTIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14919", district:"18", Block:"7", label:"PUMS NATTARMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14920", district:"18", Block:"7", label:"PUMS MARUTHADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14921", district:"18", Block:"7", label:"PUMS,VIJAYAGOPALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14922", district:"18", Block:"7", label:"GHS IRUR", Sch_type:"Government", Category:"High School"},
    { key:"14923", district:"18", Block:"7", label:"MURUGAN AID. MIS. NARANAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14924", district:"18", Block:"7", label:"PUMS,VARAGUPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14925", district:"18", Block:"7", label:"ADWHSS NATHAKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14926", district:"18", Block:"7", label:"GHSS KARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14927", district:"18", Block:"7", label:"PUMS KANNAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"14928", district:"18", Block:"7", label:"PUMS,PUDHUVIRALIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"14929", district:"18", Block:"7", label:"PUMS,SIRUVAYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14930", district:"18", Block:"7", label:"PUMS, SIRUGANPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14931", district:"18", Block:"7", label:"PUMS SATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14932", district:"18", Block:"7", label:"NETHAJI AID.MS.ADDAIKAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14933", district:"18", Block:"7", label:"GHSS PADALUR -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14934", district:"18", Block:"7", label:"ST. FR. MAT. PADALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14935", district:"18", Block:"7", label:"SREE AMBAALS MATRIC HR SEC SCHOOL - PADALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14936", district:"18", Block:"7", label:"ANNAI HSS PADALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14937", district:"18", Block:"7", label:"PERIYAR M S, PADALUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"14938", district:"18", Block:"7", label:"PUMS, THIRUVILAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14939", district:"18", Block:"7", label:"GHSS KOLAKKANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14940", district:"18", Block:"7", label:"PUMS, THIMMUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14941", district:"18", Block:"7", label:"GHS SILLAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"14942", district:"18", Block:"7", label:"PUMS, METHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"14943", district:"18", Block:"7", label:"PUMS, RAMALINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14944", district:"18", Block:"7", label:"PUMS, NOCHIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14945", district:"18", Block:"7", label:"PUMS MAKKAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14946", district:"18", Block:"7", label:"PUMS, PILIMISAI", Sch_type:"Government", Category:"Middle School"},
    { key:"14947", district:"18", Block:"7", label:"PUMS ELANTHANGUZHI (W)", Sch_type:"Government", Category:"Middle School"},
    { key:"14948", district:"18", Block:"7", label:"PUMS,KEEZHAMATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14949", district:"18", Block:"7", label:"PUMS,ALLINAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14950", district:"18", Block:"7", label:" GHS KOLATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14951", district:"18", Block:"7", label:"GHS ZAMINE ATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14952", district:"18", Block:"7", label:"GHS GUDALUR", Sch_type:"Government", Category:"High School"},
    { key:"14953", district:"18", Block:"7", label:"GHS- ZAMINPERAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"14954", district:"18", Block:"7", label:"GHS ADHANUR", Sch_type:"Government", Category:"High School"},
    { key:"14955", district:"18", Block:"7", label:"RAJAVIGNESH HSS MELAMATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14956", district:"18", Block:"7", label:"GHS MELAMATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14957", district:"18", Block:"7", label:"GHSS KOOTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14958", district:"1", Block:"26", label:"GHS VELLUR", Sch_type:"Government", Category:"High School"},
    { key:"14959", district:"1", Block:"26", label:"PUMS ELUPPAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14960", district:"1", Block:"26", label:"GHSS POYYATHANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14961", district:"1", Block:"26", label:"GHS RAYAMPURAM", Sch_type:"Government", Category:"High School"},
    { key:"14962", district:"1", Block:"26", label:"PUMS SENNIVANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14963", district:"1", Block:"26", label:"PUMS OTTAKKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"14964", district:"1", Block:"26", label:"PUMS O.KOTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"14965", district:"1", Block:"26", label:"GHS SALAYAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"14966", district:"1", Block:"26", label:"GHSS GOVINTHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14967", district:"1", Block:"26", label:"GHS THAMARAIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"14968", district:"1", Block:"26", label:"VIDHYA MANDIR PUBLIC SCHOOL THAMARAIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14969", district:"1", Block:"26", label:"RC NIRMALA GANDHI MIDDLE SCHOOL ARIYALUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14970", district:"1", Block:"26", label:"NIRMALA (G) HSS ARIYALUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"14971", district:"1", Block:"26", label:"GHSS ARIYALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14972", district:"1", Block:"26", label:"ST.MARY'S HIGH SCHOOL, ARIYALUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"14973", district:"1", Block:"26", label:"CSI HSS ARIYALUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"14974", district:"1", Block:"26", label:"GOVT G HS ARIYALUR", Sch_type:"Government", Category:"High School"},
    { key:"14975", district:"1", Block:"26", label:"KGBV SCHOOL- KARU SENAPATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"14976", district:"1", Block:"26", label:"PUMS VALAJA NAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14977", district:"1", Block:"26", label:"PUMS ASTHINAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14978", district:"1", Block:"26", label:"S K V P KOIL GIRLS HIGH SCHOOL KALLANKURICHI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"14979", district:"1", Block:"26", label:"GHS MANAKKUDI", Sch_type:"Government", Category:"High School"},
    { key:"14980", district:"1", Block:"26", label:"GOVT AIDED KRV MIDDLE SCHOOL LINGATHADIMEDU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"14981", district:"1", Block:"26", label:"ARASUNAGAR MATRICULATION HIGHER SECONDARY SCHOOL  ARIYALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14982", district:"1", Block:"26", label:"GHS AYANATHUR", Sch_type:"Government", Category:"High School"},
    { key:"14983", district:"1", Block:"26", label:"ADITYA BIRLA PUBLIC SCHOOL REDDIPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"14984", district:"1", Block:"26", label:"GHS KATTUPRINGIUAM", Sch_type:"Government", Category:"High School"},
    { key:"14985", district:"1", Block:"26", label:"PUMS MANNUZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14986", district:"1", Block:"26", label:"GHSS K.AMBAPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14987", district:"1", Block:"26", label:"GHS VILANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"14988", district:"1", Block:"26", label:"GHS NAGAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"14989", district:"1", Block:"26", label:"PARIVALLAL HSS NAGAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"14990", district:"1", Block:"26", label:"PUMS MUNIYANKURICHY", Sch_type:"Government", Category:"Middle School"},
    { key:"14991", district:"1", Block:"26", label:"PUMS PUTHUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"14992", district:"1", Block:"26", label:"PUMS VELIPRINKIUM", Sch_type:"Government", Category:"Middle School"},
    { key:"14993", district:"1", Block:"26", label:"GHS REDDIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"14994", district:"1", Block:"26", label:"GHS SIRUVALUR", Sch_type:"Government", Category:"High School"},
    { key:"14995", district:"1", Block:"26", label:"PUMS KARU.SENAPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"14996", district:"1", Block:"26", label:"PUMS KARUVIDACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"14997", district:"1", Block:"26", label:"GOVT HR SEC SCHOOL  KARU.POYYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"14998", district:"1", Block:"26", label:"PUMS EDAYATHANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"14999", district:"1", Block:"26", label:"GOVT HR SEC SCHOOL PERIYATHIRUKONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15000", district:"1", Block:"26", label:"PUMS CHETTITHIRUKKONAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15001", district:"1", Block:"26", label:"PUMS KALLAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15002", district:"1", Block:"26", label:"GHS ARUNGAL", Sch_type:"Government", Category:"High School"},
    { key:"15003", district:"1", Block:"26", label:"PUMS ANDIPATTAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"15004", district:"1", Block:"26", label:"GHSS SUNDAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15005", district:"1", Block:"26", label:"PUMS P.OTTAKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"15006", district:"1", Block:"26", label:"GOVT.MIDDLE.ATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15007", district:"1", Block:"26", label:"PUMS PALLAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15008", district:"1", Block:"16", label:"GHS OLAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"15009", district:"1", Block:"16", label:"PUMS THAVADANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15010", district:"1", Block:"16", label:"GOVT HSS ALAGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15011", district:"1", Block:"16", label:"PUMS CHINNAKARUKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15012", district:"1", Block:"16", label:"PUMS AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15013", district:"1", Block:"16", label:"GHSS EDAIYAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15014", district:"1", Block:"16", label:"GOVT.HR.SEC.SCHOOL,AYYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15015", district:"1", Block:"16", label:"PUMS KANGUZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15016", district:"1", Block:"16", label:"PUMS PATTANAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15017", district:"1", Block:"16", label:"PUMS VILANDHAI -E", Sch_type:"Government", Category:"Middle School"},
    { key:"15018", district:"1", Block:"16", label:"GHSS ANDIMADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15019", district:"1", Block:"16", label:"GHS (GIRLS) ANDIMADAM", Sch_type:"Government", Category:"High School"},
    { key:"15020", district:"1", Block:"16", label:"GHS KAVARAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"15021", district:"1", Block:"16", label:"ST MARY'S MATRICULATION  HIGH SCHOOL  KAVARAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15022", district:"1", Block:"16", label:"DON BOSCO HR SEC SCHOOL VARADARAJANPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15023", district:"1", Block:"16", label:"ALANGARA ANNAI(GIRLS) HSS VARADHARAJANPET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15024", district:"1", Block:"16", label:"PUMS PERIYAKRISHNAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15025", district:"1", Block:"16", label:"GHS PERIYATHATHUR", Sch_type:"Government", Category:"High School"},
    { key:"15026", district:"1", Block:"16", label:"ST.ANNES. MS KEELANEDUVAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15027", district:"1", Block:"16", label:"PUMS KOVILVALKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15028", district:"1", Block:"16", label:"GHS THIRUKALLAPUR", Sch_type:"Government", Category:"High School"},
    { key:"15029", district:"1", Block:"16", label:"ADW HSS POOVANIPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15030", district:"1", Block:"16", label:"PUMS KOOVATHUR EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"15031", district:"1", Block:"16", label:"GHS KOOVATHUR", Sch_type:"Government", Category:"High School"},
    { key:"15032", district:"1", Block:"16", label:"PUMS KATTATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15033", district:"1", Block:"16", label:"PUMS Z.KULATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15034", district:"1", Block:"16", label:"PUMS KUVAGAM EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"15035", district:"1", Block:"16", label:"GHS KUVAGAM", Sch_type:"Government", Category:"High School"},
    { key:"15036", district:"1", Block:"16", label:"PUMS K.VALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15037", district:"1", Block:"16", label:"GHS KODDUKKUR", Sch_type:"Government", Category:"High School"},
    { key:"15038", district:"1", Block:"16", label:"PUMS THERKKUPATI MARUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15039", district:"1", Block:"16", label:"GHSS MARUDHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15040", district:"1", Block:"16", label:"PUMS DEVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15041", district:"1", Block:"16", label:"GHSS ELAIYUR-VARIYANKAVAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15042", district:"1", Block:"16", label:"PUMS Z.MELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15043", district:"1", Block:"16", label:"ANNAI LOURD(GIRLS)HSS THENNUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15044", district:"1", Block:"16", label:"GHS NAGAMPANTHAL", Sch_type:"Government", Category:"High School"},
    { key:"15045", district:"1", Block:"16", label:"PUMS CHINNATHUKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15046", district:"1", Block:"81", label:"GMHSS JAYANKONDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15047", district:"1", Block:"81", label:"FATHIMA (G) HSS JAYANKONDAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15048", district:"1", Block:"81", label:"GHS (G) JAYANKONDAM", Sch_type:"Government", Category:"High School"},
    { key:"15049", district:"1", Block:"81", label:"ANNAI TERESA MATRICULATION SCHOOL JAYANKONDAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15050", district:"1", Block:"81", label:"GHSS (G) UDAYARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15051", district:"1", Block:"81", label:"NOWFAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15052", district:"1", Block:"81", label:"GHSS UDAYARPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15053", district:"1", Block:"81", label:"SRI ANNAI MATRICULATION SCHOOL UDAYARAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15054", district:"1", Block:"81", label:"GHS CHINNAVALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"15055", district:"1", Block:"81", label:"PUMS-T.KUDIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"15056", district:"1", Block:"81", label:"MEENAAKSHI RAMASAMY HSS THATHANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15057", district:"1", Block:"81", label:"GHSS T.KEELAVELI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15058", district:"1", Block:"81", label:"GHSS T.POTTAKKOLLAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15059", district:"1", Block:"81", label:"PUMS-SUNDIPALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15060", district:"1", Block:"81", label:"GHS MUTHUSERVAMADAM", Sch_type:"Government", Category:"High School"},
    { key:"15061", district:"1", Block:"81", label:"GHSS MEENSURUTTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15062", district:"1", Block:"81", label:"GHSS (G) MEENSURUTTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15063", district:"1", Block:"81", label:"PUMS-VEERABOGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15064", district:"1", Block:"81", label:"GHSS KALLATHUR THANDALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15065", district:"1", Block:"81", label:"R C FATHIMA HS VADAVEEKKAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15066", district:"1", Block:"81", label:"GHS ADW VETHIYARVETTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15067", district:"1", Block:"81", label:"GHS ERAVANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"15068", district:"1", Block:"81", label:"GOVT HSS AYYAPPANAYAKKANPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15069", district:"1", Block:"81", label:"GHSS MELANIKUZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15070", district:"1", Block:"81", label:"CSI HSS MAHIMAIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15071", district:"1", Block:"81", label:"GHS SENGUNTHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"15072", district:"1", Block:"81", label:"PUMS-KACHIPERUMAL", Sch_type:"Government", Category:"Middle School"},
    { key:"15073", district:"1", Block:"81", label:"RAJA SATHA BAKKIYAM HIGH SCHOOL THULARANKURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15074", district:"1", Block:"81", label:"PUMS-KADARANKONDAN", Sch_type:"Government", Category:"Middle School"},
    { key:"15075", district:"1", Block:"81", label:"GHSS ULKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15076", district:"1", Block:"81", label:"GHSS GANGAIKONDACHOLAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15077", district:"1", Block:"81", label:"HELAN KELLER SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"15078", district:"1", Block:"81", label:"GHSS VANATHIRAYANPATTANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15079", district:"1", Block:"81", label:"PUMS MEIKKAVALPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15080", district:"1", Block:"81", label:"INDRA PUBLIC HR SEC SCHOOL THALUTHALAIMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15081", district:"1", Block:"81", label:"PUMS-EDAIYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"15082", district:"1", Block:"346", label:"GHSS THIRUMAZHAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15083", district:"1", Block:"346", label:"GHS KANDIRATHEETHAM", Sch_type:"Government", Category:"High School"},
    { key:"15084", district:"1", Block:"346", label:"PUMS VILUPPANANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15085", district:"1", Block:"346", label:"PUMS PALAYAPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"15086", district:"1", Block:"346", label:"PUMS-KEELAESANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15087", district:"1", Block:"346", label:"VEERAMAMUNIVAR MIDDLE.SCHOOL ELAKKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15088", district:"1", Block:"346", label:"GOVERNMENT MODEL HR.SEC SCHOOL ELAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15089", district:"1", Block:"346", label:"PUMS SENGARAYANKATTALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15090", district:"1", Block:"346", label:"PUMS ALAGIYAMANAVALAN", Sch_type:"Government", Category:"Middle School"},
    { key:"15091", district:"1", Block:"346", label:"UC MIDDLESCHOOL (AIDED) PUDUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15092", district:"1", Block:"346", label:"ST. IGNATIUS KULAMANICKAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15093", district:"1", Block:"346", label:"GHSS ELANDAIKUDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15094", district:"1", Block:"346", label:"GHSS KAMARASAVALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15095", district:"1", Block:"346", label:"GHS RAMANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"15096", district:"1", Block:"346", label:"GHSS KOVILUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15097", district:"1", Block:"346", label:"GHSS KURUVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15098", district:"1", Block:"346", label:"GHS THOTHUR", Sch_type:"Government", Category:"High School"},
    { key:"15099", district:"1", Block:"346", label:"PUMS CHINNAPATTAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"15100", district:"1", Block:"346", label:"PUMS VILAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15101", district:"1", Block:"346", label:"GHS VENGANUR", Sch_type:"Government", Category:"High School"},
    { key:"15102", district:"1", Block:"346", label:"PUMS KORATHAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15103", district:"1", Block:"346", label:"PUMS ANGIYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15104", district:"1", Block:"346", label:"GHS SANNAVUR", Sch_type:"Government", Category:"High School"},
    { key:"15105", district:"1", Block:"346", label:"GHS PALINGANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"15106", district:"1", Block:"346", label:"PUMS KARAYAVETTI", Sch_type:"Government", Category:"Middle School"},
    { key:"15107", district:"1", Block:"346", label:"GHSS KEELAKAVATTANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15108", district:"1", Block:"346", label:"PUMS SENAPATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"15109", district:"1", Block:"346", label:"GHS KEELAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"15110", district:"1", Block:"346", label:"PUMS POONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15111", district:"1", Block:"346", label:"ST. IGNATIUS HIGH SCHOOL  KOKKUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15112", district:"1", Block:"346", label:"GHS VARANAVASI", Sch_type:"Government", Category:"High School"},
    { key:"15113", district:"1", Block:"346", label:"GHSS KEELAPALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15114", district:"1", Block:"346", label:"ARUNACHALA MATRICULATION SCHOOL KEELAPALUVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15115", district:"1", Block:"346", label:"PUMS PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15116", district:"1", Block:"346", label:"GHS VETRIYUR", Sch_type:"Government", Category:"High School"},
    { key:"15117", district:"1", Block:"346", label:"PUMS THIRUPEYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"15118", district:"1", Block:"346", label:"PUMS VADUGAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15119", district:"1", Block:"346", label:"GHSS KEELAKOLATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15120", district:"1", Block:"346", label:"GHSS THIRUMANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15121", district:"1", Block:"346", label:"PUMS VETTAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15122", district:"1", Block:"346", label:"GHS KOVIL ESANAI", Sch_type:"Government", Category:"High School"},
    { key:"15123", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - SUTHUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15124", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL -  SANRORPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15125", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - C.N.PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15126", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL -  C.N.PALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15127", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - THAZHANKUDA", Sch_type:"Government", Category:"Middle School"},
    { key:"15128", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL -  KARAIKADU COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"15129", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL - KARAIKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15130", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - KEEZH AZHINJIPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"15131", district:"5", Block:"54", label:"GOVERNMENT HIGH SCHOOL - KEEZH KUMARAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"15132", district:"5", Block:"54", label:"GOVERNMENT HIGH SCHOOL - RASAPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"15133", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - PERIYAKATTUPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15134", district:"5", Block:"54", label:"VELLAI THAMARAI MATRICULATION SCHOOL - CHINNA KATTUPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15135", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL -  KUTTIYANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15136", district:"5", Block:"54", label:"KRISHNASAMY MATRICULATION HIGHER SECONDARY SCHOOL OF EXCELLENCE - S.KUMARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15137", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL - NADUVEERAPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15138", district:"5", Block:"54", label:"AIDED MIDDLE SCHOOL - APPAR- NALLATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15139", district:"5", Block:"54", label:"AKSHARA VIDYAASHRAM SENIOR SECONDARY  SCHOOL (CBSE), NATHAPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15140", district:"5", Block:"54", label:"GOVERNMENT HIGH SCHOOL - PACHAYANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"15141", district:"5", Block:"54", label:"ST THOMAS MATRICULATION SCHOOL - KOOTHAPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15142", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - PERIYA KANGANANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15143", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL - RAMAPURAM WEST", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15144", district:"5", Block:"54", label:"AHS - ANNAI VELANKANNI CLUNY- SATHANKUPPAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15145", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - SEDAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15146", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - SEMMANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15147", district:"5", Block:"54", label:"MAHATMA GANDHI INTERNATIONAL SCHOOL- SINGIRIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15148", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL - THOOKKANAMPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15149", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL - THIRUVANDIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15150", district:"5", Block:"54", label:"SRI VIDYA KALA KENDRAM MATRICULATION HIGH SCHOOL, THIRUVANTHIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15151", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - THOTTAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"15152", district:"5", Block:"54", label:"KRISHNASWAMY VIDYA NIKETHAN SENIOR SECONDARY SCHOOL (CBSE) - S.KUMARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15153", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - VANAMADEVI", Sch_type:"Government", Category:"Middle School"},
    { key:"15154", district:"5", Block:"54", label:"GOVERNMENT HIGH  SCHOOL - VARAKALPATTU", Sch_type:"Government", Category:"High School"},
    { key:"15155", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL - V.KATTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15156", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - VELLAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15157", district:"5", Block:"54", label:"PANCHAYAT UNION MIDDLE SCHOOL - VILANGALPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"15158", district:"5", Block:"54", label:"AIDED MIDDLE SCHOOL ST MARY'S - PUDHUPALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15159", district:"5", Block:"54", label:"MODERN MATRICULATION HIGHER SECONDARY SCHOOL, VELI SEMMANDALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15160", district:"5", Block:"54", label:"SAVIOUR JESUS MATRICULATION HIGHER SECONDARY SCHOOL - CUDDALORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15161", district:"5", Block:"54", label:"SRI VARADHAM GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL -  VENUGOPALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15162", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL - VANDIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15163", district:"5", Block:"54", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - THIRUPATHIRIPULIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15164", district:"5", Block:"54", label:"MUNICIPAL HIGHER SECONDARY SCHOOL - CUDDALORE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15165", district:"5", Block:"54", label:"ST JOSEPH'S HIGHER SECONDARY SCHOOL - MANJAKUPPAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15166", district:"5", Block:"54", label:"ST JOSEPH'S HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15167", district:"5", Block:"54", label:"SRI PATALESWARAR HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15168", district:"5", Block:"54", label:"ST ANNE'S GIRLS HIGHER SECONDARY SCHOOL - PUDHUPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15169", district:"5", Block:"54", label:"ST JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL - KOOTHAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15170", district:"5", Block:"54", label:"LAKSHMI CHORDIA MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL - S.S.R.NAGAR- THIRUPPATHIRIPULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15171", district:"5", Block:"54", label:"NEW MILLENNIUM MATRICULATION HIGHER SECONARY SCHOOL - KUMARAPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15172", district:"5", Block:"54", label:"SRI VALLIAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, THIRUPPATHIRIPULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15173", district:"5", Block:"54", label:"GOVERNMENT HIGHER SECONDARY SCHOOL - MANJAKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15174", district:"5", Block:"54", label:"GOVERNMENT DEAF AND DUMB MIDDLE SCHOOL - CUDDALORE", Sch_type:"Government", Category:"Middle School"},
    { key:"15175", district:"5", Block:"54", label:"SRI LAKSHMI CHORDIA MATRICULATION HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15176", district:"5", Block:"54", label:"SRI MAHARISHI VIDHYA MANDHIR MATRICULATION SCHOOL - MANJAKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15177", district:"5", Block:"54", label:"ARISTO PUBLIC SCHOOL - THIRUPPATHIRIPULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15178", district:"5", Block:"54", label:"ADHIDRAVIDAR WELFARE MIDDLE SCHOOL - SELLANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15179", district:"5", Block:"54", label:"GOVERNMENT HIGH SCHOOL - SANKARAN STREET", Sch_type:"Government", Category:"High School"},
    { key:"15180", district:"5", Block:"54", label:"ST FRANCIS XAVIER MS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15181", district:"5", Block:"54", label:"ST PHILOMINA'S GIRLS HIGH SCHOOL,CUDDALORE PORT", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15182", district:"5", Block:"54", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - CUDDALORE PORT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15183", district:"5", Block:"54", label:"ST DAVID'S HIGHER SECONDARY SCHOOL - CUDDALORE PORT", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15184", district:"5", Block:"54", label:"KAMATCHI SHANMUGAM MATRICULATION HIGHER SECONDARY SCHOOL- SIVANANDHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15185", district:"5", Block:"54", label:"SRI SARASWATHI VIDHYAALAYA MATRICULATION HIGHER SECONDARY SCHOOL , CUDDALORE  PORT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15186", district:"5", Block:"54", label:"ST DAVID'S MATRICULATION SCHOOL- CUDALORE PORT", Sch_type:"Un-aided", Category:"High School"},
    { key:"15187", district:"5", Block:"54", label:"LAKSHMI MATRIC  SCHOOL - CUDDALORE PORT", Sch_type:"Un-aided", Category:"High School"},
    { key:"15188", district:"5", Block:"54", label:"A.G.M MIDDLE SCHOOL CUDDALORE PORT", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15189", district:"5", Block:"17", label:"PUMS-REDDIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15190", district:"5", Block:"17", label:"PUMS BANDARAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15191", district:"5", Block:"17", label:"GHS ENATHIRIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"15192", district:"5", Block:"17", label:"GOVERNMENT HIGH SCHOOL,  KANDARAKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"15193", district:"5", Block:"17", label:"PUMS KARUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15194", district:"5", Block:"17", label:"GOVERNMENT HIGHER SEC SCHOOL, KOZHIPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15195", district:"5", Block:"17", label:"PUMS THATTAMPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15196", district:"5", Block:"17", label:"GHS MELKUMARAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"15197", district:"5", Block:"17", label:"PUMS MELPATTAMPAKKAM GIRLS", Sch_type:"Government", Category:"Middle School"},
    { key:"15198", district:"5", Block:"17", label:"AHSS, G. CHR. HR. SEC. SCH, MELPATTAMPAKKAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15199", district:"5", Block:"17", label:"GHS, MELPATTAMPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"15200", district:"5", Block:"17", label:"PUMS ORAIYUR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"15201", district:"5", Block:"17", label:"GHSS ORAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15202", district:"5", Block:"17", label:"PUMS PANAPAKKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15203", district:"5", Block:"17", label:"ST.PETER'S HS, T.SATHIPATTU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15204", district:"5", Block:"17", label:"MOTHER THERESA MATRIC HIGHER SECONDARY SCHOOL - SATHIPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15205", district:"5", Block:"17", label:"GHSS, THIRUTHURAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15206", district:"5", Block:"17", label:"GGHS, PUDHUPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15207", district:"5", Block:"17", label:"GOVT BOYS HIGHER SECONDARY SCHOOL ,PUTHUPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15208", district:"5", Block:"17", label:"AMS KILPATHY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15209", district:"5", Block:"17", label:"Govt Boys Higher Secondary School,  NELLIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15210", district:"5", Block:"17", label:"GGHSS, NELLIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15211", district:"5", Block:"17", label:"BALAVIHAR MATRIC HR SEC SCHOOL, NELLIKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15212", district:"5", Block:"17", label:"AHSS, NELLIKUPPAM (DM)", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15213", district:"5", Block:"17", label:"ST. PAUL MAT. HR. SEC .SCH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15214", district:"5", Block:"17", label:"ST.DOMINIC SAVIO MAT.HR.SEC. SCH.NELLIKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15215", district:"5", Block:"17", label:"RR MATRIC HR SEC SCHOOL,NELLIKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15216", district:"5", Block:"17", label:"MUNICIPAL HIGH SCHOOL,  THIRUKANDESWARAM", Sch_type:"Government", Category:"High School"},
    { key:"15217", district:"5", Block:"17", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,PALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15218", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL ANGUCHETTIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"15219", district:"5", Block:"227", label:" AIDED  MIDDLE  SCHOOL R.C MOZHANDIKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15220", district:"5", Block:"227", label:"KURINJI MATRICULATION HIGH SCHOOL, AZHAGAPPASAMUTHIRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15221", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL PAVAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15222", district:"5", Block:"227", label:"R.K .GOVERNMENT HIGHER SECONDARY SCHOOL KADAMPULIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15223", district:"5", Block:"227", label:"St .  PAUL PUBLIC SCHOOL, CBSE  PANIKANKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15224", district:"5", Block:"227", label:"RAJAGURU MATRIC HR SEC.SCHOOL-KADAMPULIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15225", district:"5", Block:"227", label:" MODEL SCHOOL,PANRUTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15226", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL KARUKKAI", Sch_type:"Government", Category:"High School"},
    { key:"15227", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL-KATTUKOODALUR", Sch_type:"Government", Category:"High School"},
    { key:"15228", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL KEEZHAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"15229", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL KEEZHKANGEYANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15230", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL KUDIYIRUPPU", Sch_type:"Government", Category:"High School"},
    { key:"15231", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL PUDHUPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"15232", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL KEEZHMAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"15233", district:"5", Block:"227", label:"ST.MARYS MATRICULATION HIGH  SCHOOL L.N.PURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15234", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL MALIGAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"15235", district:"5", Block:"227", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MARUNGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15236", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL MELIRUPPU", Sch_type:"Government", Category:"High School"},
    { key:"15237", district:"5", Block:"227", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  SIRUGRAMAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15238", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL S.EARIPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15239", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL SIRUVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15240", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL THAZHAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"15241", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL THIRUVAMOOR", Sch_type:"Government", Category:"High School"},
    { key:"15242", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL VEERASINGANKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15243", district:"5", Block:"227", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VEERAPERUMANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15244", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL VADAKKU MELMAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"15245", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL METTUKUPPAM (PANRUTI)", Sch_type:"Government", Category:"High School"},
    { key:"15246", district:"5", Block:"227", label:"GOVERNMENT GIRLS  HIGHER SECONDARY SCHOOL PERPERIYANKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15247", district:"5", Block:"227", label:"GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL PERPERIYANKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15248", district:"5", Block:"227", label:"D.KALIYAPERUMAL MEMORIAL MATRICULATION SCHOOL. PERPERIYANKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15249", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE  SCHOOL SOORAKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15250", district:"5", Block:"227", label:"SRI RAMAKRISHNA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL, SOORAKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15251", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL POONGUNAM", Sch_type:"Government", Category:"High School"},
    { key:"15252", district:"5", Block:"227", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PERIYAKATTUPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15253", district:"5", Block:"227", label:"GOVERNMENT ADIDIRAVIDAR WELFARE HIGHER SECONDARY SCHOOL, SEMAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15254", district:"5", Block:"227", label:"GOVERNMENT HIGH SCHOOL  SEMMEDU ", Sch_type:"Government", Category:"High School"},
    { key:"15255", district:"5", Block:"227", label:"GOVERNMENT HIGHER SECONDARY SCHOOL BADRAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15256", district:"5", Block:"227", label:" AIDED  MIDDLE  SCHOOL  R.C PANICKANNKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15257", district:"5", Block:"227", label:" AIDED  MIDDLE  SCHOOL A.V PANRUTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15258", district:"5", Block:"227", label:" SREE SARADHA VIDHYALAYA  MATRICULATION  SCHOOL PANRUTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15259", district:"5", Block:"227", label:"RADHIKA  MATRICULATION HIGH SCHOOL PANRUTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15260", district:"5", Block:"227", label:"MUNICIPAL HIGH  SCHOOL THIRUVATHIGAI", Sch_type:"Government", Category:"High School"},
    { key:"15261", district:"5", Block:"227", label:"AIDED HIGH SCHOOL D.M.PANRUTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15262", district:"5", Block:"227", label:"SRI.P.MUTHAIYAR HR.SEC.SCHOOL, PANRUTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15263", district:"5", Block:"227", label:"RATHANA  MATRIC.HIGHER .SECONDARY.SCHOOL, PANRUTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15264", district:"5", Block:"227", label:"AIDED SHREE.A.SUBBARAYA CHETTIAR'S GIRLS HIGHER SECONDARY SCHOOL- PANRUTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15265", district:"5", Block:"227", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  PANRUTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15266", district:"5", Block:"227", label:"SEVENTH DAY ADVENTIST MATRICULATION HR SEC SCHOOL PANRUTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15267", district:"5", Block:"227", label:"JOHN DEWEY MATRICULATION  HR SEC SCHOOL  PANRUTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15268", district:"5", Block:"227", label:"THE NEW JOHN DEWEY MATRIC HIGH SCHOOL PANRUTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15269", district:"5", Block:"227", label:"SHREE MAEDHA MATRICULATION  HIGH SCHOOL ,  PANRUTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15270", district:"5", Block:"227", label:"THIRUVALLUVAR ,MATRICULATION HIGHER SEC  SCHOOL PANRUTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15271", district:"5", Block:"227", label:"ST. ANNE'S MATRIC HIGHER SEC  SCHOOL ,THATTANCHAVADI,PANRUTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15272", district:"5", Block:"227", label:"PANCHAYAT UNION MIDDLE SCHOOL CHETTIPATTARAI  COLONY PANRUTI", Sch_type:"Government", Category:"Middle School"},
    { key:"15273", district:"5", Block:"227", label:"KASTHURIBA GANDHI BALIGA VIDHYALAYA SCHOOL KADAMPULIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15274", district:"5", Block:"211", label:"ARUL JOTHI AIDED MIDDLE SCHOOL  VADAKUTHU ( EAST)", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15275", district:"5", Block:"211", label:"NLC MIDDLE SCHOOL BLOCK- 20  NEYVELI-TS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15276", district:"5", Block:"211", label:"DANISH MISSION MIDDDLE SCHOOL BLOCK- 19 NEYVELI-TS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15277", district:"5", Block:"211", label:"THOLKAPPIYANAR MIDDLE SCHOOL BLOCK -16 NEYVELI-TS", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15278", district:"5", Block:"211", label:"JAWAHAR MATRICULATION HIGHER SECONDARY SCHOOL BLOCK -17 NEYVELI - TS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15279", district:"5", Block:"211", label:"JUNIOR JAWAHAR MATRICULATION  SCHOOL, BLOCK- 6 NEYVELI - TS", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15280", district:"5", Block:"211", label:"MODEL JAWAHAR MATRICULATION SCHOOL, BLOCK 28 NEYVELI-TS", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15281", district:"5", Block:"211", label:"LITTLE JAWAHAR MATRICULATION SCHOOL, MANDARAKUPPAM NEYVELI-2", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15282", district:"5", Block:"211", label:"ST. PAUL'S MATRICULATION HIGHER SECONDARY SCHOOL , BLOCK -4 NEYVELI-TS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15283", district:"5", Block:"211", label:"ST. JOSEPH OF CLUNY MATRICULATION  HIGHER SECONDARY SCHOOL  BLOCK-25 NEYVELI -TS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15284", district:"5", Block:"211", label:"SRI AUROBINDO VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, BLOCK-19 NEYVELI-TS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15285", district:"5", Block:"211", label:"SEVENTH DAY ADVENTIST  MATRICULATION HIGHER SECONDARY SCHOOL BLOCK-13 NEYVELI-TS ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15286", district:"5", Block:"211", label:"TAGORE MATRICULATION HIGHER SECONDARY SCHOOL BLOCK-29 NEYVELI-TS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15287", district:"5", Block:"211", label:"N M J MATRICULATION HIGH SCHOOL BLOCK-12 NEYVELI -TS", Sch_type:"Un-aided", Category:"High School"},
    { key:"15288", district:"5", Block:"211", label:"ST. JOSEPH'S HIGH SCHOOL  BLOCK-4, NEYVELI-TS", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15289", district:"5", Block:"211", label:"NLC GIRLS HIGHER SECONDARY SCHOOL  BLOCK-11 NEYVELI -TS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15290", district:"5", Block:"211", label:"NLC BOYS HIGHER SECONDARY SCHOOL  BLOCK-10, NEYVELI -TS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15291", district:"5", Block:"211", label:"NLC HIGH SCHOOL BLOCK-26 NEYVELI-TS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15292", district:"5", Block:"211", label:"NLC HIGHER SECONDARY SCHOOL MANDARAKUPPAM,.NEYVELI.-607802", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15293", district:"5", Block:"211", label:"NLC GIRLS HIGH SCHOOL  MANDARAKUPPAM  NEYVELI- 607802..", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15294", district:"5", Block:"211", label:"VALLALAR MATRICULATION HIGHER SECONDARY  SCHOOL, KOLLUKARANKUTTAI, MARUNGUR(PO)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15295", district:"5", Block:"148", label:"GHS ADOORAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"15296", district:"5", Block:"148", label:"ADWMS ARANGAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15297", district:"5", Block:"148", label:"GOVT HR SEC SCHOOL, THEERTHANAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15298", district:"5", Block:"148", label:"AMS ADOORKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15299", district:"5", Block:"148", label:"KANNAN MATRICULATION  SCHOOL,KULLANCHAVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15300", district:"5", Block:"148", label:"GHSS KULLANCHAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15301", district:"5", Block:"148", label:"GHS MARUVAI", Sch_type:"Government", Category:"High School"},
    { key:"15302", district:"5", Block:"148", label:"GHS POOVANIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"15303", district:"5", Block:"148", label:"GHS S.PUDHUR", Sch_type:"Government", Category:"High School"},
    { key:"15304", district:"5", Block:"148", label:"GHS NAGAMMAPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"15305", district:"5", Block:"148", label:"PUMS PERIYAKANNADI", Sch_type:"Government", Category:"Middle School"},
    { key:"15306", district:"5", Block:"148", label:"ADWMS KOTHANDARAMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15307", district:"5", Block:"148", label:"PUMS AYIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15308", district:"5", Block:"148", label:"PUMS KOTHAVACHERRY", Sch_type:"Government", Category:"Middle School"},
    { key:"15309", district:"5", Block:"148", label:"GHS KALKUNAM", Sch_type:"Government", Category:"High School"},
    { key:"15310", district:"5", Block:"148", label:"GHS RANGANATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"15311", district:"5", Block:"148", label:"PUMS GURUVAPPANPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15312", district:"5", Block:"148", label:"GHSS ANDARMULLIPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15313", district:"5", Block:"148", label:"GHS PULIYUR KATTUSAGAI", Sch_type:"Government", Category:"High School"},
    { key:"15314", district:"5", Block:"148", label:"ST MARY'S  HIGH SCHOOL-KRISHNANKUPPAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15315", district:"5", Block:"148", label:"GHS PONNANGKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"15316", district:"5", Block:"148", label:"GHS SANTHAVELIPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"15317", district:"5", Block:"148", label:"GHS KO.CHATHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"15318", district:"5", Block:"148", label:"GHSS KARUNKUZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15319", district:"5", Block:"148", label:"GHS METTUKUPPAM (KURINJIPADI)", Sch_type:"Government", Category:"High School"},
    { key:"15320", district:"5", Block:"148", label:"GHSS VENGADAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15321", district:"5", Block:"148", label:"GHS AMBALAVANANPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"15322", district:"5", Block:"148", label:"GHS KANNITAMILNADU", Sch_type:"Government", Category:"High School"},
    { key:"15323", district:"5", Block:"148", label:"GHS K.NELLIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"15324", district:"5", Block:"148", label:"FATHIMA MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15325", district:"5", Block:"148", label:"SRI THAMBUSAMY MATRICULATION SCHOOL, ABATHARANAPURAM. VADALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15326", district:"5", Block:"148", label:"ST JOHN HS PARVATHIPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15327", district:"5", Block:"148", label:"VALLALAR GURUGULAM HR SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15328", district:"5", Block:"148", label:"GGHSS VADALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15329", district:"5", Block:"148", label:"S.D EADEN MATRICULATION HIGHER SECONDARY  SCHOOL, VADALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15330", district:"5", Block:"148", label:"TRIPURANENI VIDYALAYA MATRICULATION  SCHOOL,VADALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15331", district:"5", Block:"148", label:"GHSS VADALUR PUDHUNAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15332", district:"5", Block:"148", label:"GHS ABATHARANAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"15333", district:"5", Block:"148", label:"S.D.S HIGH SCHOOL-SERAKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15334", district:"5", Block:"148", label:"ARUNACHALA MATRICULATION HIGHER SECONDARY SCHOOL, KURINJPADI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15335", district:"5", Block:"148", label:"ST PAUL MATRICULATION HIGHER SECONDARY  SCHOOL, KURINJIPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15336", district:"5", Block:"148", label:"SRIRAM MATRICULATION HIGHER SECONDARY SCHOOL, KURINJIPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15337", district:"5", Block:"148", label:"SRI GAYATHRI MATRICULATION SCHOOL,KURINJIPADI.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15338", district:"5", Block:"148", label:"GOVT GIRLS HSS KURINJIPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15339", district:"5", Block:"148", label:"G(B)HSS KURINJIPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15340", district:"5", Block:"148", label:"S.K.V HSS KURINJIPADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15341", district:"5", Block:"148", label:"SKV THAIYALNAYAKI AMMAL MATRICULATION SCHOOL, KURINJIPADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15342", district:"5", Block:"148", label:"JEEVA MATRICULATION  HIGHER SECONDARY  SCHOOL-SUBRAMANIYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15343", district:"5", Block:"148", label:"SRI RAMALINGAR HS", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15344", district:"5", Block:"148", label:"GHSS POONDIYANKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15345", district:"5", Block:"148", label:"GHS PERIYAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"15346", district:"5", Block:"148", label:"ARULMIGU VALLALAR  HIGH SCHOOL,  VELAVINAYAGARKUPPAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15347", district:"5", Block:"148", label:"GHSS KATTIYANKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15348", district:"5", Block:"148", label:"LIFE HELP MATRICULATIONSCHOOL, METTUPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15349", district:"5", Block:"148", label:"GHSS  INDHRA NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15350", district:"5", Block:"406", label:"GOVERNMENT HIGHER SECONDARY SCHOOL- MUGASAPARUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15351", district:"5", Block:"406", label:"ST. PAUL MATRICULATION HIGHER SECONDARY SCHOOL, MANGALAMPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15352", district:"5", Block:"406", label:"ANNAI HATHEEJA GIRLS HIGH SCHOOL,  MANGALAMPETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15353", district:"5", Block:"406", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL MANGALAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15354", district:"5", Block:"406", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL- MANGALAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15355", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL PULIYUR", Sch_type:"Government", Category:"High School"},
    { key:"15356", district:"5", Block:"406", label:"GOVERNMENT HIGHER SECONDARY SCHOOL- ALADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15357", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL MATHUR", Sch_type:"Government", Category:"High School"},
    { key:"15358", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL PERIYAVADAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"15359", district:"5", Block:"406", label:"SENTHIL MATRICULATION HIGHER SECONDARY SCHOOL PERIYAVADAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15360", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL KO-PUVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15361", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL PUDHUKOORAIPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"15362", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL- EDAICHITHUR", Sch_type:"Government", Category:"High School"},
    { key:"15363", district:"5", Block:"406", label:"ST. AGNES HIGH SCHOOL VEERAREDDIKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15364", district:"5", Block:"406", label:"V.E.T. HIGHER SECONDARY SCHOOL ERUMANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15365", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL ERUMANUR", Sch_type:"Government", Category:"High School"},
    { key:"15366", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL CHINNAVADAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"15367", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL M. PATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"15368", district:"5", Block:"406", label:"AIDED MIDDLE SCHOOL  PUDUVILANGULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15369", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL PARAVALUR (SOUTH)", Sch_type:"Government", Category:"Middle School"},
    { key:"15370", district:"5", Block:"406", label:"GOVERNMENT HIGHER SECONDARY SCHOOL THORAVALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15371", district:"5", Block:"406", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KARUVEPPILANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15372", district:"5", Block:"406", label:"PANCHAYAT UNION MIDDLE  SCHOOL VILANKATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15373", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL RAJENDRAPATTINAM", Sch_type:"Government", Category:"High School"},
    { key:"15374", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL VANNANKUDIKADU", Sch_type:"Government", Category:"High School"},
    { key:"15375", district:"5", Block:"406", label:"DANISH MISSION MIDDLE  SCHOOL KASPA", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"15376", district:"5", Block:"406", label:"KRISHNASAMY AIDED MIDDLE SCHOOL NATCHIYARPETTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15377", district:"5", Block:"406", label:"MUNICIPAL MIDDLE SCHOOL PUDUPET", Sch_type:"Government", Category:"Middle School"},
    { key:"15378", district:"5", Block:"406", label:"GOVERNMENT MODEL GIRLS HIGHER SECONDARY SCHOOL ,VRIDDHACHALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15379", district:"5", Block:"406", label:"BABY MATRICULATION SCHOOL VRIDDHACHALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15380", district:"5", Block:"406", label:"SRI SARASWATHI VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15381", district:"5", Block:"406", label:"SAKTHI MATRICULATION HIGHER SECONDARY SCHOOL, PERIYAR NAGAR,VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15382", district:"5", Block:"406", label:"DANISH MISSION HIGHER SECONDARY SCHOOL VRIDDHACHALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15383", district:"5", Block:"406", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL VRIDDHACHALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15384", district:"5", Block:"406", label:"SRI SARADA VIDYALAYA MATRICULATION SCHOOL, VRIDDHACHALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15385", district:"5", Block:"406", label:"DEVI MATRIC HR SEC  SCHOOL-VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15386", district:"5", Block:"406", label:"NIRMALA CREATIVE MATRICULATION SCHOOL- ANNA NAGAR, VRIDDHACHALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15387", district:"5", Block:"406", label:"MAHARISHI VIDYA MANDIR  MATRICULATION HIGHER SECONDARY SCHOOL, VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15388", district:"5", Block:"406", label:"KSR HI-TECH CBSE SCHOOL- VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15389", district:"5", Block:"406", label:"R.C.AIDED MIDDLE SCHOOL KOVILANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15390", district:"5", Block:"199", label:"ARUNA HIGHER SECONDARY SCHOOL  , ERAIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15391", district:"5", Block:"199", label:"SHREE AMBIKA VIDHYALAYA  MATRICULATION SCHOOL ERAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15392", district:"5", Block:"199", label:"PUMS SATHANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15393", district:"5", Block:"199", label:"GHSS THOLAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15394", district:"5", Block:"199", label:"GHSS POOLAMPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15395", district:"5", Block:"199", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PELANDURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15396", district:"5", Block:"199", label:"ADW GHS THEEVALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15397", district:"5", Block:"199", label:"PUMS KILIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15398", district:"5", Block:"199", label:"GHS VADAKARAI", Sch_type:"Government", Category:"High School"},
    { key:"15399", district:"5", Block:"199", label:"PUMS CHINNAKOSAPALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15400", district:"5", Block:"199", label:"PUMS MURUGANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15401", district:"5", Block:"199", label:"AMS VENKARUMBUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15402", district:"5", Block:"199", label:"PUMS MARUTHATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15403", district:"5", Block:"199", label:"ADW GHS SIRUMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"15404", district:"5", Block:"199", label:"GHS MANNAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"15405", district:"5", Block:"199", label:"GOVERNMENT HIGH SCHOOL , A.CHITTHUR", Sch_type:"Government", Category:"High School"},
    { key:"15406", district:"5", Block:"199", label:"ADWS NARAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15407", district:"5", Block:"199", label:"AMS A.AGARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15408", district:"5", Block:"199", label:"GHSS VEPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15409", district:"5", Block:"199", label:"AYYANAR MA TRIC HR SEC  SCHOOL,  VEPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15410", district:"5", Block:"199", label:"GOVERNMENT (MODEL ) HIGHER SECONDARY SCHOOL, NALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15411", district:"5", Block:"199", label:"GHS KEEZHAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"15412", district:"5", Block:"199", label:"ADWHSS GIRLS SEPPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15413", district:"5", Block:"199", label:"ADWHSS BOYS SEPPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15414", district:"5", Block:"199", label:"PUMS NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15415", district:"5", Block:"199", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15416", district:"5", Block:"199", label:"PUMS VALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15417", district:"5", Block:"199", label:"PUMS NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"15418", district:"5", Block:"199", label:"GHSS KOVILUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15419", district:"5", Block:"199", label:"GHS - SATHIYAM", Sch_type:"Government", Category:"High School"},
    { key:"15420", district:"5", Block:"199", label:"PUMS VARAMBANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15421", district:"5", Block:"199", label:"GHS PERIYANESALUR", Sch_type:"Government", Category:"High School"},
    { key:"15422", district:"5", Block:"199", label:"AMS KO.KOTHANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15423", district:"5", Block:"199", label:"ADWS MALIGAIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"15424", district:"5", Block:"199", label:"PUMS PENNADAM WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"15425", district:"5", Block:"199", label:"BABY MATRICULATION SCHOOL PENNADAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15426", district:"5", Block:"199", label:"GOVERNMENT ( GIRLS)HIGHER SECONDARY SCHOOL, PENNADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15427", district:"5", Block:"199", label:"GHSS BOYS PENNADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15428", district:"5", Block:"199", label:"LOTUS INTERNATIONAL MATRIC.HR.SEC. SCHOOL, PENNADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15429", district:"5", Block:"199", label:"BHARATHI MATRICULATION SCHOOL PENNADAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15430", district:"5", Block:"199", label:"KGBV , PERIYANESALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15431", district:"5", Block:"199", label:"PUMS SOZHANNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"15432", district:"5", Block:"170", label:"GOVERNMENT BOYS' HIGHER SECONDARY SCHOOL, THOZHUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15433", district:"5", Block:"170", label:"GOVERNMENT GIRLS HIGH SCHOOL,THOLUDUR", Sch_type:"Government", Category:"High School"},
    { key:"15434", district:"5", Block:"170", label:"OPR HIGH SCHOOL, THOZHUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15435", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL, EZHUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"15436", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL , KANDAMATHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"15437", district:"5", Block:"170", label:"ANDAVAR HIGH SCHOOL , KALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15438", district:"5", Block:"170", label:"GOVERNMENT HIGHER SECONDARY SCHOOL , KEELAKALPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15439", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL , KEEZHORATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15440", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL ,  KORAKKAVADI", Sch_type:"Government", Category:"High School"},
    { key:"15441", district:"5", Block:"170", label:"GOVERNMENT HIGHER SECONDARY SCHOOL , SIRUPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15442", district:"5", Block:"170", label:"TAGORE  MATRICULATION SCHOOL, SIRUPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15443", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL , PANAIYANTHUR", Sch_type:"Government", Category:"High School"},
    { key:"15444", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, PULIKARAMBALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15445", district:"5", Block:"170", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MANGALORE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15446", district:"5", Block:"170", label:"ADI DRAVIDA WELFARE HIGHER SECONDARY SCHOOL , MA-PODAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15447", district:"5", Block:"170", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MA.PODAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15448", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL , RETTAKKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15449", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL,VADAPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15450", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, VINAYAGANANDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"15451", district:"5", Block:"170", label:"GOVERNMENT HIGHER SECONDARY SCHOOL , AVINANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15452", district:"5", Block:"170", label:"SRI VINAYAGA MATRICULATION SCHOOL, POTHIRAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15453", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL , KOTTARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15454", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL , VAIYANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"15455", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, NEDUNGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15456", district:"5", Block:"170", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, E.KEERANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15457", district:"5", Block:"170", label:"ADI DRAVIDA WELFARE MIDDLE SCHOOL , KAZHUDHUR ", Sch_type:"Government", Category:"Middle School"},
    { key:"15458", district:"5", Block:"170", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KAZHUDHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15459", district:"5", Block:"170", label:"SRI VENKATESWARA MATRICULATION HIGHER SECONDARY  SCHOOL, KAZHUTHUR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15460", district:"5", Block:"170", label:"Panchayat Union Middle School,Alambadi", Sch_type:"Government", Category:"Middle School"},
    { key:"15461", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL,KORAKKAI", Sch_type:"Government", Category:"High School"},
    { key:"15462", district:"5", Block:"170", label:"NATIONAL MATRICULATION SCHOOL, THOLUDUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15463", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, VAGAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15464", district:"5", Block:"170", label:"GOVT. BOYS' HIGHER SECONDARY SCHOOL , TITTAGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15465", district:"5", Block:"170", label:"GOVT.GIRLS' HIGHER SECONDARY SCHOOL, TITTAGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15466", district:"5", Block:"170", label:"DR NAVALAR NEDUNCHEZHIYAN MATRIC HIGHER SECONDARY SCHOOL, TITTAGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15467", district:"5", Block:"170", label:"INDIAN MATRICULATION HIGHER SECONDARY SCHOOL, TITTAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15468", district:"5", Block:"170", label:"PUMS T. ELAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15469", district:"5", Block:"170", label:"AIDED MIDDLE SCHOOL, POYANAPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15470", district:"5", Block:"170", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ADARI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15471", district:"5", Block:"170", label:"KASTURBA GANDHI BALIKA VIDHYALAYA , THOZHUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15472", district:"5", Block:"170", label:"SRI  KALAIMAGAL MATRICULATION SCHOOL-ADARI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15473", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL,ORANGUR", Sch_type:"Government", Category:"High School"},
    { key:"15474", district:"5", Block:"170", label:"PANCHAYAT UNION MIDDLE SCHOOL, LAKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15475", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL, THATCHUR", Sch_type:"Government", Category:"High School"},
    { key:"15476", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL, SIRUMULAI", Sch_type:"Government", Category:"High School"},
    { key:"15477", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL, VENGANUR", Sch_type:"Government", Category:"High School"},
    { key:"15478", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL.EDAICHERUVAI", Sch_type:"Government", Category:"High School"},
    { key:"15479", district:"5", Block:"98", label:"GHS KO.ADHANUR", Sch_type:"Government", Category:"High School"},
    { key:"15480", district:"5", Block:"98", label:"ADW HSS, DHARMANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15481", district:"5", Block:"98", label:"AR RAHMATH MATRICULATION SCHOOL PERIYAKURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15482", district:"5", Block:"98", label:"GHSS IRUPPU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15483", district:"5", Block:"98", label:"GHS IRULAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"15484", district:"5", Block:"98", label:"GHSS KAMMAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15485", district:"5", Block:"98", label:"AMS GOPALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15486", district:"5", Block:"98", label:"ST.THERSA'S HS, KATTUKUNANKURICHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15487", district:"5", Block:"98", label:"AMS KAVANUR WEST", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15488", district:"5", Block:"98", label:"GHSS C.KEERANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15489", district:"5", Block:"98", label:"GHS -KOTTERI", Sch_type:"Government", Category:"High School"},
    { key:"15490", district:"5", Block:"98", label:"PUMS KUMARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15491", district:"5", Block:"98", label:"SACRED HEART HSS, IRUPUKURICHI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15492", district:"5", Block:"98", label:"GHS, MELAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"15493", district:"5", Block:"98", label:"P.RAMALINGA MDR GHSS, MUDHANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15494", district:"5", Block:"98", label:"GHS - NADIYAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"15495", district:"5", Block:"98", label:"GHS , OTTIMEDU", Sch_type:"Government", Category:"High School"},
    { key:"15496", district:"5", Block:"98", label:"GHS PERIYAKAPPANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"15497", district:"5", Block:"98", label:"AUXILIUM MATCULATION  SCHOOL-V.SATHAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15498", district:"5", Block:"98", label:"GHSS SEPLANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15499", district:"5", Block:"98", label:"AMS SIRUVARPPUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15500", district:"5", Block:"98", label:"PUMS THOPPALIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15501", district:"5", Block:"98", label:"PUMS UTHANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"15502", district:"5", Block:"98", label:"GHS -VEPPANKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"15503", district:"5", Block:"98", label:"JPA VIDYALAYA  MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"15504", district:"5", Block:"98", label:"GHS GANGAIKONDAN COLONY", Sch_type:"Government", Category:"High School"},
    { key:"15505", district:"5", Block:"98", label:"SACRED HEART MATRICULATION SCHOOL, KVR NAGAR,NEYVELI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15506", district:"5", Block:"98", label:"PUMS- KOLLIRUPPU COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"15507", district:"5", Block:"233", label:"PUMS C. PUDUPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15508", district:"5", Block:"233", label:"PUMS K. PANCHANGKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15509", district:"5", Block:"233", label:"PUMS KARIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15510", district:"5", Block:"233", label:"PANCHAYAT UNION MIDDLE SCHOOL -  SAMMANDAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15511", district:"5", Block:"233", label:"RC. HIGH SCHOOL,  MANIKOLLAI ", Sch_type:"Un-aided", Category:"High School"},
    { key:"15512", district:"5", Block:"233", label:"GOVERNMENT MODEL  HIGHER SECONDARY SCHOOL - B.MUTLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15513", district:"5", Block:"233", label:"JAWAHAR MATRICULATION HIGHER SECONDARY SCHOOL,  B.MUTLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15514", district:"5", Block:"233", label:"ATCHAYA MANTHIR HIGHER SECONDARY SCHOOL-B.MUTLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15515", district:"5", Block:"233", label:"PUMS PERIYAPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"15516", district:"5", Block:"233", label:"SRI BARANI VIDHYALAYA HIGHER SECONDARY SCHOOL -PERIYAPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15517", district:"5", Block:"233", label:"PUMS SENDHIRAKILLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15518", district:"5", Block:"233", label:"GHSS SAMIYARPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15519", district:"5", Block:"233", label:"GOVERNMENT HIGH SCHOOL - AYEEPURAM", Sch_type:"Government", Category:"High School"},
    { key:"15520", district:"5", Block:"233", label:"GHS VELANGIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"15521", district:"5", Block:"233", label:"ADIDRAVIDAR WELFARE MIDDLE SCHOOL - ADHIVARAGANALLUR ", Sch_type:"Government", Category:"Middle School"},
    { key:"15522", district:"5", Block:"233", label:"GHS KAVARAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"15523", district:"5", Block:"233", label:"PUMS KEEZHAMANAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15524", district:"5", Block:"233", label:"PUMS KOTHANGKUDI HINDU", Sch_type:"Government", Category:"Middle School"},
    { key:"15525", district:"5", Block:"233", label:"GHS KOTHATTAI", Sch_type:"Government", Category:"High School"},
    { key:"15526", district:"5", Block:"233", label:"PUMS MEETHIKUDI EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"15527", district:"5", Block:"233", label:"ADWMS NANJAIMAHATHUVAZHKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15528", district:"5", Block:"233", label:"PUMS PALLIPADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15529", district:"5", Block:"233", label:"MUSTHAFA MATRICULATION HIGHER SECONDARY SCHOOL PALLIPADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15530", district:"5", Block:"233", label:"GHSS- KODIPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15531", district:"5", Block:"233", label:"GHSS T.S.PETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15532", district:"5", Block:"233", label:"GHSS KILLAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15533", district:"5", Block:"233", label:"PUMS MGR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"15534", district:"5", Block:"233", label:"PUMS KALAIGNAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"15535", district:"5", Block:"233", label:"SARVA SEVA MATRIC KILLAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15536", district:"5", Block:"233", label:"GHS MUDASALVODAI", Sch_type:"Government", Category:"High School"},
    { key:"15537", district:"5", Block:"233", label:"AIDED HIGHER SECONDARY SCHOOL SEVAMANDIR GIRLS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15538", district:"5", Block:"233", label:"GHSS PARANGIPETTAI GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15539", district:"5", Block:"233", label:"GOVT HIGHER SECONDARY SCHOOL PARANGIPETTAI BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15540", district:"5", Block:"233", label:"SEVAMANDIR MATRICULATION HIGHER SECONDARY SCHOOL, PARANGIPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15541", district:"5", Block:"233", label:"KALIMA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15542", district:"5", Block:"233", label:"MUNA AUSTRALIAN MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15543", district:"5", Block:"39", label:"GHSS KRISHNAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15544", district:"5", Block:"39", label:"GHSS MANJAKOLLAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15545", district:"5", Block:"39", label:"GHS SATHAPADI", Sch_type:"Government", Category:"High School"},
    { key:"15546", district:"5", Block:"39", label:"PUMS MARUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15547", district:"5", Block:"39", label:"GHSS ERUMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15548", district:"5", Block:"39", label:"GHS B ADHANUR", Sch_type:"Government", Category:"High School"},
    { key:"15549", district:"5", Block:"39", label:"GHSS C.MUTLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15550", district:"5", Block:"39", label:"SHREE RAGHAVENDRA SCHOOL, CBSE. KEEZHAMOONGILADI,CHIDAMBARAM.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15551", district:"5", Block:"39", label:"EDISON G AGORAM  MEMORIAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15552", district:"5", Block:"39", label:"VALLALAR HIGHER SECONDARY SCHOOL, VALAYAMADEVI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15553", district:"5", Block:"39", label:"GHS PINNALUR", Sch_type:"Government", Category:"High School"},
    { key:"15554", district:"5", Block:"39", label:"GHS VADAKKUTHITTAI", Sch_type:"Government", Category:"High School"},
    { key:"15555", district:"5", Block:"39", label:"GHSS MEL BHUVANAGIRI BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15556", district:"5", Block:"39", label:"GGHSS, BHUVANAGIRI GIRLS -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15557", district:"5", Block:"39", label:"BHARATHI MATRICULATION HIGHER SECONDARYSCHOOL,BHUVANAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15558", district:"5", Block:"39", label:"MANGALAM MATRICULATION HIGHER SECONDARY SCHOOL,BHUVANAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15559", district:"5", Block:"39", label:"SRI  RAGHAVENDRA MATRIC HIGHER SECONDARY  SCHOOL-BHUVANAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15560", district:"5", Block:"39", label:"DGM HSS SETHIYATHOPE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15561", district:"5", Block:"39", label:"CHANDRA HR SEC SCHOOL -  SETHIYATHOPE", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15562", district:"5", Block:"39", label:"S.D.EADEN MATRICULATION SCHOOL,  SETHIATHOPE.", Sch_type:"Un-aided", Category:"High School"},
    { key:"15563", district:"5", Block:"39", label:"GHS SETHIYATHOPE", Sch_type:"Government", Category:"High School"},
    { key:"15564", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOODALAIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15565", district:"5", Block:"120", label:"GOVERNMENT HIGHER SECONDARY SCHOOL- KANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15566", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL-KEERAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"15567", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- VILAGAM", Sch_type:"Government", Category:"High School"},
    { key:"15568", district:"5", Block:"120", label:"ADI-DRAVIDA WELFARE GOVERNMENT HIGHER SECONDARY SCHOOL- MATHURANTHAGANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15569", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- MAZHAVARAYANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"15570", district:"5", Block:"120", label:"ADI-DRAVIDA WELFARE MIDDLE SCHOOL- MUGAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15571", district:"5", Block:"120", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, T.NEDUNCHERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15572", district:"5", Block:"120", label:"THIRUVALLUVAR HIGHER SECONDARY SCHOOL , ORATHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15573", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- PALAYANKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"15574", district:"5", Block:"120", label:"AIDED-ST.LOURDE'S MIDDLE SCHOOL- SILUVAIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15575", district:"5", Block:"120", label:"KAMARAJ MATRIC HIGHER SECONDARY SPECIAL SCHOOL, VAYALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15576", district:"5", Block:"120", label:"AIDED MIDDLE SCHOOL- PANNAPATTU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15577", district:"5", Block:"120", label:"ADI-DRAVIDA WELFARE MIDDLE SCHOOL- PERUNGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15578", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15579", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- PUDAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"15580", district:"5", Block:"120", label:"AIDED-ST.MARY'S MIDDLE SCHOOL- VALISAPET", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15581", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- RAMAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"15582", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- SAKKANKUDI", Sch_type:"Government", Category:"High School"},
    { key:"15583", district:"5", Block:"120", label:"RBM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"15584", district:"5", Block:"120", label:"AMS GOVINDANALLUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"15585", district:"5", Block:"120", label:"AHS SOZHATHARAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15586", district:"5", Block:"120", label:"AIDED-SACRED HEART HIGHER SECONDARY SCHOOL- VADAKUPALAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15587", district:"5", Block:"120", label:"PANCHAYAT UNION MIDDLE SCHOOL, VAZHAIKKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15588", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- VEYYALORE", Sch_type:"Government", Category:"High School"},
    { key:"15589", district:"5", Block:"143", label:"GOVERNMENT HR.SEC SCHOOL LALPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15590", district:"5", Block:"143", label:"GOVERNMENT HR.SEC.SCHOOL KUMARATCHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15591", district:"5", Block:"143", label:"GOVERNMENT HIGH SCHOOL ELLERI", Sch_type:"Government", Category:"High School"},
    { key:"15592", district:"5", Block:"143", label:"GHS NEYVASAL", Sch_type:"Government", Category:"High School"},
    { key:"15593", district:"5", Block:"143", label:"PUMS MEYYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15594", district:"5", Block:"143", label:"GOVERNMENT HIGH SCHOOL KOODUVELICHAVADI", Sch_type:"Government", Category:"High School"},
    { key:"15595", district:"5", Block:"143", label:"GOVERNMENT HIGH SCHOOL EDAIYAR", Sch_type:"Government", Category:"High School"},
    { key:"15596", district:"5", Block:"143", label:"GOVERNMENT HIGH SCHOOL THIRUNARAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"15597", district:"5", Block:"143", label:"GHS PARIVILAGAM", Sch_type:"Government", Category:"High School"},
    { key:"15598", district:"5", Block:"143", label:"ADWSHSS MELAVANNIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15599", district:"5", Block:"143", label:"GOVERNMENT HIGH SCHOOL KARUPPUR", Sch_type:"Government", Category:"High School"},
    { key:"15600", district:"5", Block:"143", label:"GOVT HIGH SCHOOL,  JAYANKONDAPATTINAM", Sch_type:"Government", Category:"High School"},
    { key:"15601", district:"5", Block:"143", label:"GHS SIVAPURI", Sch_type:"Government", Category:"High School"},
    { key:"15602", district:"5", Block:"143", label:"PUMS MULLANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15603", district:"5", Block:"143", label:"GHSS VALLATHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15604", district:"5", Block:"143", label:"WILLIAMS MATRICULATION SCHOOL VALLAMPADUGAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15605", district:"5", Block:"143", label:"GHS VELLUR", Sch_type:"Government", Category:"High School"},
    { key:"15606", district:"5", Block:"143", label:"AMS VAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15607", district:"5", Block:"143", label:"LEELA MEMO MAT ANNAMALAINAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15608", district:"5", Block:"143", label:"A.R.G ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL,ANNAMALAI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15609", district:"5", Block:"143", label:"MPLMS KOTHANGUDI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"15610", district:"5", Block:"143", label:"AMS TELC CHIDAMBARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15611", district:"5", Block:"143", label:"KAMARAJ MATRICULATION HIGHER SECONDARY SCHOOL,CHIDAMBARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15612", district:"5", Block:"143", label:"THILLAI MATRIC", Sch_type:"Un-aided", Category:"High School"},
    { key:"15613", district:"5", Block:"143", label:"RCT HSS CHIDAMBARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15614", district:"5", Block:"143", label:"RANI SEETHAI HSS ANNAMALAI NAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15615", district:"5", Block:"143", label:"GOVT GIRLS HR SEC SCHOOL-CHIDAMBARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15616", district:"5", Block:"143", label:"GOVT NANDANAR BOYS HSS CHIDAMBARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15617", district:"5", Block:"143", label:"GOVT NANDANAR GIRLS HSS CHIDAMBARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15618", district:"5", Block:"143", label:"ARUMUGA NAVALAR HIGHER SECONDARY SCHOOL CHIDAMBARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15619", district:"5", Block:"143", label:"SRI RAMAKRISHNA VIDYASALA HR SEC SCHOOL-CHIDAMBARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15620", district:"5", Block:"143", label:"PACHAIYAPPA'S HIGHER SECONDARY SCHOOL CHIDAMBARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15621", district:"5", Block:"143", label:"SARASU MATRICULATION SCHOOL,PARAMESWARANALLURE", Sch_type:"Un-aided", Category:"High School"},
    { key:"15622", district:"5", Block:"143", label:"SRI NATARAJA MATRIC SCHOOL-C.THADESWARANALLUR CHIDAMBARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15623", district:"5", Block:"143", label:"MPLGHS-SAMBANDHAKARA ST", Sch_type:"Government", Category:"High School"},
    { key:"15624", district:"5", Block:"143", label:"SRI GURUGNANASAMBANDAR MISSION MATRICULATION  HIGHER SECONDARY SCHOOL, CHIDAMBARAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15625", district:"5", Block:"115", label:"ST.ANTHONY'S HS , ARANTHANGI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15626", district:"5", Block:"115", label:"GHS KOZHAI", Sch_type:"Government", Category:"High School"},
    { key:"15627", district:"5", Block:"115", label:"GHSS MAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15628", district:"5", Block:"115", label:"RAJIV GANDHI NATIONAL  MAT HR SEC SCHOOL , NATTARMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15629", district:"5", Block:"115", label:"RAJIV GANDHI NATIONAL HIGH SCHOOL, NATTARMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15630", district:"5", Block:"115", label:"PANCHAYAT UNION MIDDLE SCHOOL  MELPALANCHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15631", district:"5", Block:"115", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  SRINEDUNCHERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15632", district:"5", Block:"115", label:"AIDED MUSLIM HIGH SCHOOL , AYANGUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15633", district:"5", Block:"115", label:"GHSS.KANDAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15634", district:"5", Block:"115", label:"GHSS , KANJANKOLLAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15635", district:"5", Block:"115", label:"AMS KALLAIMAGAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15636", district:"5", Block:"115", label:"GHSS(BOYS) , KATTUMANNARKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15637", district:"5", Block:"115", label:"P R G HR SEC SCHOOL, KATTUMANNARKOIL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15638", district:"5", Block:"115", label:"GK MAT HSS , KATTUMANNARKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15639", district:"5", Block:"115", label:"AIDED HIGH SCHOOL (RC) , UDAIYARKUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15640", district:"5", Block:"115", label:"KALAIMAGAL MATRIC HIGHER SECONDARY  SCHOOL  KATTUMANNARKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15641", district:"5", Block:"115", label:"GHSS(GIRLS) , KATTUMANNARKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15642", district:"5", Block:"115", label:"MUSLIM HIGH SCH., KOLLUMEDU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15643", district:"5", Block:"115", label:"GHS OMAMPULIYUR", Sch_type:"Government", Category:"High School"},
    { key:"15644", district:"5", Block:"115", label:"GHS GUNAVASAL", Sch_type:"Government", Category:"High School"},
    { key:"15645", district:"5", Block:"115", label:"GHS , KARUPPERI", Sch_type:"Government", Category:"High School"},
    { key:"15646", district:"5", Block:"115", label:"GOVERNMENT HIGH SCHOOL, KURUNGUDI", Sch_type:"Government", Category:"High School"},
    { key:"15647", district:"5", Block:"115", label:"GHS , MANIYAMADOOR", Sch_type:"Government", Category:"High School"},
    { key:"15648", district:"5", Block:"115", label:"GHS , MOVUR", Sch_type:"Government", Category:"High School"},
    { key:"15649", district:"5", Block:"115", label:"GHSS , MUTTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15650", district:"5", Block:"115", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, REDDIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15651", district:"5", Block:"115", label:"DVC HSS , SRIMUSHNAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15652", district:"5", Block:"115", label:"DHAVA AMUTHAM MHSS, SRIMUSHNAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15653", district:"5", Block:"115", label:"GHSS THIRUMUTTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15654", district:"16", Block:"196", label:"GHSS, AZHIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15655", district:"16", Block:"196", label:"GHS ELANKADAMBANUR", Sch_type:"Government", Category:"High School"},
    { key:"15656", district:"16", Block:"196", label:"GHSS MUTTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15657", district:"16", Block:"196", label:"PUMS SANGAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15658", district:"16", Block:"196", label:"PUMS,PORAVACHERRY", Sch_type:"Government", Category:"Middle School"},
    { key:"15659", district:"16", Block:"196", label:"PUMS ANTHANAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15660", district:"16", Block:"196", label:"KUMARAN AMS MANJAKOLLAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15661", district:"16", Block:"196", label:"GHSS SIKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15662", district:"16", Block:"196", label:"GHS VADAKKUPOIGAINALLUR", Sch_type:"Government", Category:"High School"},
    { key:"15663", district:"16", Block:"196", label:"GHSS AKKARAIPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15664", district:"16", Block:"196", label:"GHS PAPPAKOVIL", Sch_type:"Government", Category:"High School"},
    { key:"15665", district:"16", Block:"196", label:"GHS PUTHUCHERY", Sch_type:"Government", Category:"High School"},
    { key:"15666", district:"16", Block:"196", label:"PUMS VERGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15667", district:"16", Block:"196", label:"CHIDAMBARANAR AMS ORATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15668", district:"16", Block:"196", label:"PUMS THERKKU POIGAINALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15669", district:"16", Block:"196", label:"AMS ST Antonys Therkku Poigainallur", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15670", district:"16", Block:"196", label:"GHS,VADAVOOR", Sch_type:"Government", Category:"High School"},
    { key:"15671", district:"16", Block:"196", label:"PUMS KURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15672", district:"16", Block:"196", label:"GHS KURICHI", Sch_type:"Government", Category:"High School"},
    { key:"15673", district:"16", Block:"196", label:"PUMS VADUGACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"15674", district:"16", Block:"196", label:"GHS, SEMBIYANMADEVI", Sch_type:"Government", Category:"High School"},
    { key:"15675", district:"16", Block:"196", label:"NATIONAL AIDED HIGHER SECONDARY SCHOOL NAGORE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15676", district:"16", Block:"196", label:"MMS MIYAH STREET NAGORE", Sch_type:"Government", Category:"Middle School"},
    { key:"15677", district:"16", Block:"196", label:"MMS PATTINACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"15678", district:"16", Block:"196", label:"GHOUTHIA AIDED HIGHER SECONDARY SCHOOL NAGORE", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15679", district:"16", Block:"196", label:"Nagore Crescent Matriculation Higher Secondary School for Girls", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15680", district:"16", Block:"196", label:"CHINMAYA VIDYALAYA HSS CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15681", district:"16", Block:"196", label:"MMS NAMBIYAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"15682", district:"16", Block:"196", label:" NAGORE MODERN MATRICULATION HIGHER SECONDARY SCHOOL, NAGORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15683", district:"16", Block:"196", label:"GHS NAGORE", Sch_type:"Government", Category:"High School"},
    { key:"15684", district:"16", Block:"196", label:"MMS NELLUKKADAI STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"15685", district:"16", Block:"196", label:"SRIMATHY PADMAVATHI MATRIC HSS VELIPPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15686", district:"16", Block:"196", label:"NATARAJAN DHAMAYANTHI AIDED HIGHER SECONDARY SCHOOL VELIPPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15687", district:"16", Block:"196", label:"J.JEYA MATRICULATION HIGHER SECONDARY SCHOOL COOKS ROAD VELIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15688", district:"16", Block:"196", label:"Aided Nadarajan Damayanthi High School, Nagapattinam.", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15689", district:"16", Block:"196", label:"ST. ANTONYS AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15690", district:"16", Block:"196", label:"AMS.ST.ANTONYS LOURDU, NAGAPATTINAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15691", district:"16", Block:"196", label:"NATIONAL AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15692", district:"16", Block:"196", label:"NEHRU MATRICULATION HIGHER SECONDARY SCHOOL NAGAPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15693", district:"16", Block:"196", label:"MGHSS NAGAPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15694", district:"16", Block:"196", label:"GSPJ ADHARSH MATRICULATION HIGHER SECONDARY SCHOOL NAGAPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15695", district:"16", Block:"196", label:"CSI AIDED HIGH SCHOOL NAGAPATTINAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15696", district:"16", Block:"196", label:"CSI AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15697", district:"16", Block:"196", label:"MMS KALLUKARA STREET.", Sch_type:"Government", Category:"Middle School"},
    { key:"15698", district:"16", Block:"196", label:"AMRITA VIDYALAYAM SENIOR SECONDARY SCHOOL NAGAPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15699", district:"16", Block:"196", label:"AJC PUBLIC SCHOOL CBSE NAGAPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15700", district:"16", Block:"196", label:"ST. MICHAEL'S AKADEMY KARUVELANKADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15701", district:"16", Block:"325", label:"GHS GANAPATHIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"15702", district:"16", Block:"325", label:"GHSS THITTACHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15703", district:"16", Block:"325", label:"GHS THITTACHERRY", Sch_type:"Government", Category:"High School"},
    { key:"15704", district:"16", Block:"325", label:"GHSS PURAGRAMAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15705", district:"16", Block:"325", label:"GHSS THIRUMARUGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15706", district:"16", Block:"325", label:"GHS KEEZHAPOLAGAM", Sch_type:"Government", Category:"High School"},
    { key:"15707", district:"16", Block:"325", label:"GHS MARUNGUR", Sch_type:"Government", Category:"High School"},
    { key:"15708", district:"16", Block:"325", label:"AL-AMAN CRESCENT MATRIC HR SEC SCHOOL ATHALAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15709", district:"16", Block:"325", label:"AKR MATRIC HR.SEC.SCHOOL ENANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15710", district:"16", Block:"325", label:"GHSS ENANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15711", district:"16", Block:"325", label:"GHSS THIRUKKANNAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15712", district:"16", Block:"325", label:"GHSS AMBAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15713", district:"16", Block:"325", label:"PUMS PORAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15714", district:"16", Block:"325", label:"ST.JOSEPH AMS SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15715", district:"16", Block:"325", label:"GHS VAZHGUDI", Sch_type:"Government", Category:"High School"},
    { key:"15716", district:"16", Block:"325", label:"ST.ANNES AMS SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15717", district:"16", Block:"325", label:"GHS NARIMANAM", Sch_type:"Government", Category:"High School"},
    { key:"15718", district:"16", Block:"123", label:"GHS VENKIDANGAL", Sch_type:"Government", Category:"High School"},
    { key:"15719", district:"16", Block:"123", label:"GHSS KILVELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15720", district:"16", Block:"123", label:"THIRU ANJUVATTATHAMMAN GIRLS AIDED HIGH SCHOOL, KILVELUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15721", district:"16", Block:"123", label:"GHS KILVELUR", Sch_type:"Government", Category:"High School"},
    { key:"15722", district:"16", Block:"123", label:"GHSS THEVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15723", district:"16", Block:"123", label:"INFANT JESUS MATRICULATION HIGH SCHOOL THEVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15724", district:"16", Block:"123", label:"GHS KILLUKUDI", Sch_type:"Government", Category:"High School"},
    { key:"15725", district:"16", Block:"123", label:"GHSS ANTHAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15726", district:"16", Block:"123", label:"GHS SATTIYAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"15727", district:"16", Block:"123", label:"GHS ATHAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"15728", district:"16", Block:"123", label:"VALIVALAM DESIKAR  AIDED HIGHER SECONDARY SCHOOL VALIVALM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15729", district:"16", Block:"121", label:"AMS-P.R.PURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15730", district:"16", Block:"121", label:"GHSS-P.R.PURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15731", district:"16", Block:"121", label:"GHS SERUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"15732", district:"16", Block:"121", label:"GHSS THIRUPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15733", district:"16", Block:"121", label:"PUMS KARAIMETTUTHIDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"15734", district:"16", Block:"121", label:"PUNITHA FATHIMA MATRICULATION SCHOOL THIRUPOONDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15735", district:"16", Block:"121", label:"ST SEBASTIANS HR SEC SCHOOL KAMESWARAM  EAST", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15736", district:"16", Block:"121", label:"PUMS VILUNTHAMAVADI WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"15737", district:"16", Block:"121", label:"PUMS VIZHUNTHAMAVADI (T)EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"15738", district:"16", Block:"121", label:"GHSS-VILUNTHAMAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15739", district:"16", Block:"121", label:"GHS KARAPPIDAGAI", Sch_type:"Government", Category:"High School"},
    { key:"15740", district:"16", Block:"121", label:"GHSS KEEZHAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15741", district:"16", Block:"121", label:"PUMS VENMANACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"15742", district:"16", Block:"121", label:"PUMS THATHANTHIRUVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"15743", district:"16", Block:"121", label:"GHSS VETTAIKKARANIRUPPU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15744", district:"16", Block:"121", label:"AMS-PUDUPALLI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15745", district:"16", Block:"121", label:"GHSS PALAKKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15746", district:"16", Block:"121", label:"AMS-THANNILAPPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15747", district:"16", Block:"121", label:"GHS GRAMATHUMEDU", Sch_type:"Government", Category:"High School"},
    { key:"15748", district:"16", Block:"121", label:"OUR LADY OF HEALTH AIDED HIGHER SECONDARY SCHOOL, VELANKANNI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15749", district:"16", Block:"121", label:"PUMS VAILANKANNI", Sch_type:"Government", Category:"Middle School"},
    { key:"15750", district:"16", Block:"121", label:"CHRIST THE KING SPECIAL HIGHER SECONDARY SCHOOL VAILANKANNI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15751", district:"16", Block:"121", label:"CHRIST THE KING HS-VAILANKANNI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15752", district:"16", Block:"121", label:"AMS-CHOLAVIDYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15753", district:"16", Block:"121", label:"ST. ANTONY'S AIDED HIGH SCHOOL   KARUNKANNI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15754", district:"16", Block:"121", label:"PUMS THIRUVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"15755", district:"16", Block:"121", label:"GHS ETTUKKUDI", Sch_type:"Government", Category:"High School"},
    { key:"15756", district:"16", Block:"121", label:"AMS-KEELAVALAKKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15757", district:"16", Block:"121", label:"AMGHSS THIRUKUVALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15758", district:"16", Block:"121", label:"PUMS THIRUKKUVALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15759", district:"16", Block:"308", label:"GHS AYMOOR", Sch_type:"Government", Category:"High School"},
    { key:"15760", district:"16", Block:"308", label:"VGHSS MANAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15761", district:"16", Block:"308", label:"PUMS MANAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15762", district:"16", Block:"308", label:"ST. XAVIER'S HIGHER SECONDARY SCHOOL NEERMULAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15763", district:"16", Block:"308", label:"PUMS NEERMULAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15764", district:"16", Block:"308", label:"GHS KOTHANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"15765", district:"16", Block:"308", label:"GHS SITHAIMOOR", Sch_type:"Government", Category:"High School"},
    { key:"15766", district:"16", Block:"308", label:"GHS PANGAL", Sch_type:"Government", Category:"High School"},
    { key:"15767", district:"16", Block:"308", label:"GHS KOLAPPADU", Sch_type:"Government", Category:"High School"},
    { key:"15768", district:"16", Block:"308", label:"GHSS UMBALACHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15769", district:"16", Block:"308", label:"PUMS PRINJUMOOLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15770", district:"16", Block:"308", label:"GHSS THALAINAYAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15771", district:"16", Block:"308", label:"PUMS ORADIYAMBULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15772", district:"16", Block:"308", label:"GHS ORADIYAMBULAM", Sch_type:"Government", Category:"High School"},
    { key:"15773", district:"16", Block:"308", label:"PUMS THALAINAYAR 4 SETHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15774", district:"16", Block:"308", label:"GHS KOVILPATHU", Sch_type:"Government", Category:"High School"},
    { key:"15775", district:"16", Block:"308", label:"GHSS VELLAPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15776", district:"16", Block:"308", label:"GHS VELLAPALLAM(FC)", Sch_type:"Government", Category:"High School"},
    { key:"15777", district:"16", Block:"308", label:"SRINIVASANAR AIDED HIGH SCHOOL NALUVEDAPATHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15778", district:"16", Block:"308", label:"GHS MOOKATCHI THERU", Sch_type:"Government", Category:"High School"},
    { key:"15779", district:"16", Block:"308", label:"AIDED MIDDLE SCHOOL AVARIKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15780", district:"16", Block:"308", label:"GHS KALLIMEDU", Sch_type:"Government", Category:"High School"},
    { key:"15781", district:"16", Block:"389", label:"GHSS PUSHPAVANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15782", district:"16", Block:"389", label:"GHSS-SEMBODAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15783", district:"16", Block:"389", label:"SEETHALAKSHMI AIDED HIGH SCHOOL,KURAVAPULAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15784", district:"16", Block:"389", label:"POINT CALIMERE INTERNATIONAL SCHOOL, KURAVAPULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15785", district:"16", Block:"389", label:"GHSS KADINALVAYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15786", district:"16", Block:"389", label:"GHSS  KARUPPAMPULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15787", district:"16", Block:"389", label:"SK GHSS THETHAKUDI SOUTH", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15788", district:"16", Block:"389", label:"AMS, MUTHALIYAR THOPPU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15789", district:"16", Block:"389", label:"ADW HS, VETTIYAKADU", Sch_type:"Government", Category:"High School"},
    { key:"15790", district:"16", Block:"389", label:"SKS GHSS VEDARANYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15791", district:"16", Block:"389", label:"G(GIRLS) HSS VEDARANYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15792", district:"16", Block:"389", label:"ST ANTONY'S MATRICULATION HR SEC SCHOOL VEDARANYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15793", district:"16", Block:"389", label:"SWAMI VIVEKANANDHA MATRICULATION SCHOOL ANDARKADU VEDARANYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15794", district:"16", Block:"389", label:"GHSS THOPPUTHURAI -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15795", district:"16", Block:"389", label:"QUAIDE MILLATH MATRICULATION HIGHER SECONDARY SCHOOL, THOPPUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15796", district:"16", Block:"389", label:"GHS ARUKATTUTHURAI", Sch_type:"Government", Category:"High School"},
    { key:"15797", district:"16", Block:"389", label:"AL-NOOR INDIAN MATRICULATION HIGHER SECONDARY SCHOOL, THETHAKUDI SOUTH THOPPUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15798", district:"16", Block:"389", label:"KGKG GIRLS AIDED HR. SEC. SCHOOL ,VEDARANYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15799", district:"16", Block:"389", label:"GHSS KODIYAKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15800", district:"16", Block:"389", label:"GHSS THAGATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15801", district:"16", Block:"389", label:"GHSS THANIKOTTAGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15802", district:"16", Block:"389", label:"GHSS SARABOJIRAJAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15803", district:"16", Block:"389", label:"GHS MARUTHUR SOUTH", Sch_type:"Government", Category:"High School"},
    { key:"15804", district:"16", Block:"389", label:"GHSS PANCHANATHIKULAM EAST", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15805", district:"16", Block:"389", label:"R.NATESANAR GOVT. HR.SEC.SCHOOL, AYAKARANPULAM 2", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15806", district:"16", Block:"389", label:"GHS SIRUTHALAIKADU", Sch_type:"Government", Category:"High School"},
    { key:"15807", district:"16", Block:"389", label:"GHS KOVILKULAM", Sch_type:"Government", Category:"High School"},
    { key:"15808", district:"16", Block:"389", label:"MAHATMA GANDHI AMS MUTHALIYAR KUTHAGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15809", district:"16", Block:"389", label:"GOVT GIRLS HR SEC SCHOOL,AYAKKARANPULAM -3", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15810", district:"16", Block:"389", label:"GHS AYAKKARANPULAM 3", Sch_type:"Government", Category:"High School"},
    { key:"15811", district:"16", Block:"389", label:"KANTHAVILAS AMS, VADAMAZHAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15812", district:"16", Block:"389", label:"GHSS KARIYAPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15813", district:"16", Block:"389", label:"GHS NAGAKKUDAIYAN", Sch_type:"Government", Category:"High School"},
    { key:"15814", district:"16", Block:"389", label:"GHSS KATHARIPULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15815", district:"16", Block:"389", label:"AMS, KARUPPANKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15816", district:"16", Block:"389", label:"GHS MARUTHUR NORTH", Sch_type:"Government", Category:"High School"},
    { key:"15817", district:"15", Block:"178", label:"UMA GIRLS HIGH SCHOOL THIRUCHITRAMBALAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15818", district:"15", Block:"178", label:"GHS KADALANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"15819", district:"15", Block:"178", label:"PANCHAYATH UNION MIDDLE SCHOOL MANALMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"15820", district:"15", Block:"178", label:"PUMS- ELUPPAPATTU.", Sch_type:"Government", Category:"Middle School"},
    { key:"15821", district:"15", Block:"178", label:"GHSS MANALMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15822", district:"15", Block:"178", label:"GHSS (G) MANALMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15823", district:"15", Block:"178", label:"SRI RAMAKRISHNA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL MANALMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15824", district:"15", Block:"178", label:"GHS ATHUR", Sch_type:"Government", Category:"High School"},
    { key:"15825", district:"15", Block:"178", label:"PUMS, KALI", Sch_type:"Government", Category:"Middle School"},
    { key:"15826", district:"15", Block:"178", label:"SRI KAMATCHI AMS KANNIYANATHAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15827", district:"15", Block:"178", label:"GHSS KORUKKAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15828", district:"15", Block:"178", label:"PANCHAYAT UNION MIDDLE SCHOOL, PANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15829", district:"15", Block:"178", label:"GHS THIRUVALAPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"15830", district:"15", Block:"178", label:"GHS SITHAMALLI", Sch_type:"Government", Category:"High School"},
    { key:"15831", district:"15", Block:"178", label:"AIDED MIDDLE SCHOOL, ELANTHOPE", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15832", district:"15", Block:"178", label:"GHS VILLIYANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"15833", district:"15", Block:"178", label:"GHS MELANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"15834", district:"15", Block:"178", label:"GOVT GIRLS HR SEC SCHOOL NIDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15835", district:"15", Block:"178", label:"NASRUL MUSLIMEEN (MATRIC) HR SEC SCHOOL NIDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15836", district:"15", Block:"178", label:"AL HAJEE MATRIC HIGH   SCHOOL NIDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15837", district:"15", Block:"178", label:"GHS NIDUR", Sch_type:"Government", Category:"High School"},
    { key:"15838", district:"15", Block:"178", label:"GHSS ANANTHATHANDAVAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15839", district:"15", Block:"178", label:"SRI KALYANEESWARI AIDED MIDDLE SCHOOL MOZHAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15840", district:"15", Block:"178", label:"PUMS THIRUMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15841", district:"15", Block:"178", label:"GHSS THIRUMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15842", district:"15", Block:"178", label:"RAJ MATRIC HR SEC SCHOOL, MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15843", district:"15", Block:"178", label:"Thiruarasen Middle School, Kazhukanimuttam", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15844", district:"15", Block:"178", label:"GHS MAPPADUGAI", Sch_type:"Government", Category:"High School"},
    { key:"15845", district:"15", Block:"178", label:"AGRA MATRIC SCHOOL MAYILADUTHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15846", district:"15", Block:"178", label:"AIDED MIDDLE SCHOOL, MALLIYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15847", district:"15", Block:"178", label:"MMRA GHS MOOVALUR", Sch_type:"Government", Category:"High School"},
    { key:"15848", district:"15", Block:"178", label:"MEKANA INTERNATIONAL MATRICULATION Hr.Sec MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15849", district:"15", Block:"178", label:"EVEREST KENBRIDGE CBSE SCHOOL MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15850", district:"15", Block:"178", label:"SRI GURU GNANASAMBANDAR HSS, DHARUMAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15851", district:"15", Block:"178", label:" PANCHAYAT UNION MIDDLE SCHOOL   MARAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15852", district:"15", Block:"178", label:"MUNICIPAL HIGH SCHOOL, THIRUINDALUR", Sch_type:"Government", Category:"High School"},
    { key:"15853", district:"15", Block:"178", label:"GURUMOORTHY MIDDLE SCHOOL, MAYILADUTHURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15854", district:"15", Block:"178", label:"SRI KANCHI SANKARA VIDYALAYA MATRICULATION  HR.SEC  SCHOOL, MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15855", district:"15", Block:"178", label:"TGN MPL HR SEC SCHOOL. MAYILADUTHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15856", district:"15", Block:"178", label:"GOVT G HSS MAYILADUTHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15857", district:"15", Block:"178", label:"SILVER JUBLEE MAT HS SCHOOL MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15858", district:"15", Block:"178", label:"ARIVALAYAM MATRICULATION HIGHER SECONDARY SCHOOL SCHOOL, MYD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15859", district:"15", Block:"178", label:"ROTARY CLUB MATRICULATION HIGHER SECONDARY SCHOOL MAYILADUTHUARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15860", district:"15", Block:"178", label:"DBTR HSS MAYILADUTHURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15861", district:"15", Block:"178", label:"AZAD GIRLS MAT HR SEC SCHOOL. MAYILADUTHUIRAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15862", district:"15", Block:"178", label:"MAYURA MAT HIGH SCHOOL, MAYILADUTHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15863", district:"15", Block:"178", label:"KITTAPPA MHSS MAYILADUTHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15864", district:"15", Block:"178", label:"ARC.KAMATCHI.MATRIC.HR.SEC.SCHOOL MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15865", district:"15", Block:"178", label:"ST.PAUL'S GIRLS HIGHER SECONDARY SCHOOL, MAYILADUTHURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15866", district:"15", Block:"178", label:"ST ANTONYS HIGH SCHOOL, KORNAD", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15867", district:"15", Block:"178", label:"RAJ MATRIC HR SEC  SCHOOL, KORANADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15868", district:"15", Block:"178", label:"BALA SARASWATHI MAT SCHOOL, KORNAD", Sch_type:"Un-aided", Category:"High School"},
    { key:"15869", district:"15", Block:"178", label:"GURUGNANASAMBANDAR MISSION MATRIC HIGHER SECONDARY SCHOOL MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15870", district:"15", Block:"178", label:"ST XAVIOR HIGH SCHOOL. MAYILADUTHURAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15871", district:"15", Block:"178", label:"MNA MS MAYILADUTHURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15872", district:"15", Block:"178", label:"KALAIMAHAL MATRICULATION  HR SEC SCHOOL MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15873", district:"15", Block:"284", label:"GHS,VAITHIYANATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"15874", district:"15", Block:"284", label:"GHS,ERUMAL THOKKALAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"15875", district:"15", Block:"284", label:"AMS MELAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15876", district:"15", Block:"284", label:"ROYAL MATRICULATION Hr. SEC. SCHOOL, SANKIRUPPU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15877", district:"15", Block:"284", label:"ALAGU JOTHI ACADAMY  MELAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15878", district:"15", Block:"284", label:"AMS PONSAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15879", district:"15", Block:"284", label:"ANNAI KASTHURIBA AIDED HIGH SCHOOL, NATARAJAPILLAI CHAVADI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15880", district:"15", Block:"284", label:"PUMS THALACHANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"15881", district:"15", Block:"284", label:"PUMS KURANGUPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15882", district:"15", Block:"284", label:"TELC MS KARAIMEDU", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"15883", district:"15", Block:"284", label:"GHSS,KEELAPERUMPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15884", district:"15", Block:"284", label:"GHS,ARUPATHY", Sch_type:"Government", Category:"High School"},
    { key:"15885", district:"15", Block:"284", label:"PUMS MARUTHAMPALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15886", district:"15", Block:"284", label:"GHS CHINNANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"15887", district:"15", Block:"284", label:"PUMS AKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15888", district:"15", Block:"284", label:"GGHS, AKKUR", Sch_type:"Government", Category:"High School"},
    { key:"15889", district:"15", Block:"284", label:"GHSS, AKKUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15890", district:"15", Block:"284", label:"ORIENTAL ARABIC AIDED HR SEC SCHOOL, AKKUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15891", district:"15", Block:"284", label:"KALAIMAHAL MATRICULATION HIGHER SECONDARY SCHOOL, AKKUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15892", district:"15", Block:"284", label:"GGHSS,SEMBANARKOVIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15893", district:"15", Block:"284", label:"NAPAC MATRICULATION HSS,SEMBANARKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15894", district:"15", Block:"284", label:"AMS PARASALUR SEMBANARKOVIL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15895", district:"15", Block:"284", label:"SAMBANDAM AIDED HIGHER SECONDARY SCHOOL, SEMBANARKOVIL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15896", district:"15", Block:"284", label:"KALAIMAHAL MATRICULATION HIGHER SECONDARY SCHOOL, SEMBANARKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15897", district:"15", Block:"284", label:"HAJA SARAMMAL MATRICULATION HSS, VADAGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15898", district:"15", Block:"284", label:"GHS,VADAKARAI", Sch_type:"Government", Category:"High School"},
    { key:"15899", district:"15", Block:"284", label:"GMS KEEZHMATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15900", district:"15", Block:"284", label:"GHSS,THIRUKKADAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15901", district:"15", Block:"284", label:"PUMS THIRUKKADAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15902", district:"15", Block:"284", label:"SRI GURUGNANA SAMBANDAR MISSION MATRICULATION HIGH SCHOOL, THIRUKKADAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15903", district:"15", Block:"284", label:"KALAIMAHAL MATRICULATION HR SEC SCHOOL,THIRUKKADAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15904", district:"15", Block:"284", label:"PUMS VEPPANCHERRY", Sch_type:"Government", Category:"Middle School"},
    { key:"15905", district:"15", Block:"284", label:"SRI SRI VIDYA MANDIR MATRICULATION SCHOOL, ANAIKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"15906", district:"15", Block:"284", label:"PUMS ELUPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15907", district:"15", Block:"284", label:"PUMS HARIHRANKOODAL", Sch_type:"Government", Category:"Middle School"},
    { key:"15908", district:"15", Block:"284", label:"GHSS,SANKARANPANDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15909", district:"15", Block:"284", label:"AL-AMAN(M) MHHS, SANKARANPANDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15910", district:"15", Block:"284", label:"HAMEEDIYA AIDED HR SEC SCHOOL, THIRUKKALACHERY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15911", district:"15", Block:"284", label:"AIYAS(M)HS, THIRUKKALACHERY", Sch_type:"Un-aided", Category:"High School"},
    { key:"15912", district:"15", Block:"284", label:"KALAIMAHAL MATRICULATION  HR.SEC SCHOOL AYAPPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15913", district:"15", Block:"284", label:"PUMS THIRUVIDAIKAZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"15914", district:"15", Block:"284", label:"VALLIAMMAI GHSS, THILLAIYADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15915", district:"15", Block:"284", label:"ANDAVAR AMS PORAYAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15916", district:"15", Block:"284", label:"TELC  THALTHAKUMI GIRLS HIGH SCHOOL, PORAYAR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"15917", district:"15", Block:"284", label:"TELC BJM BOYS AIDED HIGHER SECONDARY SCHOOL,THARANGAMPADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15918", district:"15", Block:"284", label:"ST THERESA'S AIDED GIRLS HIGHER SECONDARY SCHOOL, THARANGAMBADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15919", district:"15", Block:"284", label:"THAVASU MUTHU NADAR AIDED HR SEC SCHOOL, PORAYAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15920", district:"15", Block:"284", label:"SHARMILA GODDESS (M)HSS, PORAYAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15921", district:"15", Block:"284", label:"NIVEDHA MATRICULATION HIGHER SECONDARY SCHOOL, PORAYAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15922", district:"15", Block:"284", label:"HOPE FOUNDATION MATRICULATION HIGH SCHOOL, THARANGAMPADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15923", district:"15", Block:"284", label:"GHS,CHANDIRAPADI", Sch_type:"Government", Category:"High School"},
    { key:"15924", district:"15", Block:"284", label:"PUMS NARASINGANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15925", district:"15", Block:"284", label:"SOWRIRAJAN  HIGH SCHOOL, THIRUVILAIYATTAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15926", district:"15", Block:"284", label:"KALAIMAHAL MATRIC SCHOOL THIRUVILAIYATTAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15927", district:"15", Block:"284", label:"PUMS KARUGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15928", district:"15", Block:"284", label:"GHS,NALLADAI", Sch_type:"Government", Category:"High School"},
    { key:"15929", district:"15", Block:"151", label:"GOVT HIGH SCHOOL, AGARATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"15930", district:"15", Block:"151", label:"GHSS KILIYANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15931", district:"15", Block:"151", label:"AL HITHANA MATRICULATION HIGH SCHOOL KILIYANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"15932", district:"15", Block:"151", label:"GHS PERAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"15933", district:"15", Block:"151", label:"GHS VANATHIRAJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"15934", district:"15", Block:"151", label:"PUMS KADALANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15935", district:"15", Block:"151", label:"RAJ VIDHYALAYA MAT HR SEC. SCHOOL, KUTHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15936", district:"15", Block:"151", label:"NEW STAR MATRICULATION SCHOOL, KUTHALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15937", district:"15", Block:"151", label:"GGHSS,KUTHALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15938", district:"15", Block:"151", label:"ST MARY'S MATRICULATION HIGH SCHOOL KUTTALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"15939", district:"15", Block:"151", label:"GHSS,KUTHALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15940", district:"15", Block:"151", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,THIRUVAVADUTHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15941", district:"15", Block:"151", label:"NISHA MAT HIGHER SECONDARY SCHOOL, THERIZHADUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15942", district:"15", Block:"151", label:"KAMBAR GHSS THERIZHANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15943", district:"15", Block:"151", label:"JAIHIND MATRICULATION HIGHER SECONDARY  SCHOOL , THERIZHANDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15944", district:"15", Block:"151", label:"ANNAI VELANKANNI MATHA HSS PERAVUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15945", district:"15", Block:"151", label:"Dr.G.S.KALYANASUNDARAM MATRIC HSS, PAZHAYAKUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15946", district:"15", Block:"151", label:"GHSS KOMAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15947", district:"15", Block:"151", label:"SRI RAMAKRISHNA MATRICULATION HR.SEC.SCHOOL, SIVANAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15948", district:"15", Block:"151", label:"GOOD LUCK MATRICULATION HR.SEC SCHOOL, SRIKANDAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15949", district:"15", Block:"151", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SRIKANDAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15950", district:"15", Block:"151", label:"GOVT. HIGHER SECONDARY SCHOOL,VEZHAMURITHANPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15951", district:"15", Block:"151", label:"KSO HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15952", district:"15", Block:"293", label:"PANCHAYAT UNION MIDDLE SCHOOL   THITTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15953", district:"15", Block:"293", label:"PANCHAYAT UNION MIDDLE SCHOOL   SATTANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15954", district:"15", Block:"293", label:"DAGGSM  VTP MS .THENPATHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15955", district:"15", Block:"293", label:"BEST MATRICULATION HR SEC SCHOOL SIRKALI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15956", district:"15", Block:"293", label:"VIVEKANANDA MATRIC. HSS SIRKALI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15957", district:"15", Block:"293", label:"GOOD SAMARITAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15958", district:"15", Block:"293", label:"MPL HSS SIRKALI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15959", district:"15", Block:"293", label:"SHYAMALA GIRLS AIDED HR SEC SCHOOL SIRKALI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15960", district:"15", Block:"293", label:"LMC AIDED HR SEC SCHOOL SIRKALI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15961", district:"15", Block:"293", label:"S M HINDU AIDED HR SEC SCHOOL SIRKALI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15962", district:"15", Block:"293", label:"ST JOSEPH MATRICULATION  HR SEC SCHOOL, P.K. ROAD, SIRKALI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15963", district:"15", Block:"293", label:"SMH MS SOUTH ST SIRKAZHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15964", district:"15", Block:"293", label:"MUNICIPAL MIDDLE SCHOOL PANAKATTANKUDISALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"15965", district:"15", Block:"293", label:"S M HINDU MATRICULATION HIGH SCHOOL, SIRKALI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15966", district:"15", Block:"293", label:"EZHILMALAR MHS SIRKALI", Sch_type:"Un-aided", Category:"High School"},
    { key:"15967", district:"15", Block:"293", label:"PANCHAYAT UNION MIDDLE SCHOOL   SENTHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15968", district:"15", Block:"293", label:"KAM AIDED HIGH SCHOOL KOVILPATHU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15969", district:"15", Block:"293", label:"GHS AGANI", Sch_type:"Government", Category:"High School"},
    { key:"15970", district:"15", Block:"293", label:"PUMS VALLUVAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15971", district:"15", Block:"293", label:"ADW GHSS KONDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15972", district:"15", Block:"293", label:"PUMS PUNGANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15973", district:"15", Block:"293", label:"GHSS VAITHEESWARANKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15974", district:"15", Block:"293", label:"G(G)HSS VAITHEESWARANKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15975", district:"15", Block:"293", label:"SRI GURUGNANASAMBANDAR MISSION - SRI MUTHAIAH MATRIC. HR. SEC. SCHOOL, VAITHEESWARANKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"15976", district:"15", Block:"293", label:"SRI MUTHURAJAM MATRICULATION SCHOOL KARKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"15977", district:"15", Block:"293", label:"ADW GHSS KARAIMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15978", district:"15", Block:"293", label:"GHS MELACHALAI", Sch_type:"Government", Category:"High School"},
    { key:"15979", district:"15", Block:"293", label:"ANNAI SARATHA VIDHYA MATRIC  SCHOOL, KARAIMEDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"15980", district:"15", Block:"293", label:"PUMS KATHIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"15981", district:"15", Block:"293", label:"GHSS NANGUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15982", district:"15", Block:"293", label:"GHS THIRUVALI", Sch_type:"Government", Category:"High School"},
    { key:"15983", district:"15", Block:"293", label:"GHS THIRUNAGARI", Sch_type:"Government", Category:"High School"},
    { key:"15984", district:"15", Block:"293", label:"PUMS ANNAPPANPETTAI                 ", Sch_type:"Government", Category:"Middle School"},
    { key:"15985", district:"15", Block:"293", label:"GHSS KONAYAMPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"15986", district:"15", Block:"293", label:"GHS AGARAPERUNTHOTTAM", Sch_type:"Government", Category:"High School"},
    { key:"15987", district:"15", Block:"293", label:"PUMS NIMELI NEPPATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"15988", district:"15", Block:"293", label:"SSD BOYS AIDED HIGER SECONDARY SCHOOL, THIRUVENGADU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"15989", district:"15", Block:"293", label:"SSD GIRLS HIGH SCHOOL, THIRUVENGADU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15990", district:"15", Block:"293", label:"HOLY CROSS MATRIC SCHOOL THIRUVENGADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"15991", district:"15", Block:"293", label:"PUMS MANGAIMADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"15992", district:"15", Block:"293", label:"GHS KAVERIPOOMPATTINAM", Sch_type:"Government", Category:"High School"},
    { key:"15993", district:"15", Block:"293", label:"SRINIVASA AIDED HSS MELAIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"15994", district:"15", Block:"293", label:"GHS VANAGIRIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"15995", district:"15", Block:"131", label:"SRI RAMAKIRUBA AMS KADAVASAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"15996", district:"15", Block:"131", label:"RAHMANIYA HIGH SCHOOL, VADAKAL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"15997", district:"15", Block:"131", label:"PUMS VETTANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"15998", district:"15", Block:"131", label:"PUMS KOOZHAIYAR", Sch_type:"Government", Category:"Middle School"},
    { key:"15999", district:"15", Block:"131", label:"GOVERNMENT HIGH SCHOOL, THODUVAI", Sch_type:"Government", Category:"High School"},
    { key:"16000", district:"15", Block:"131", label:"VICTORIAL  MATRICULATION HIGHER SECONDARY SCHOOL, THIRUMULLAIVASAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16001", district:"15", Block:"131", label:"PUMS RADHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16002", district:"15", Block:"131", label:"GOVERNMENT HIGH SCHOOL, EDAMANAL", Sch_type:"Government", Category:"High School"},
    { key:"16003", district:"15", Block:"131", label:"SIVANANDHA AMS MADHANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16004", district:"15", Block:"131", label:"GOVT ADW HIGHER SECONDARY  SCHOOL MATHANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16005", district:"15", Block:"131", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ACHALPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16006", district:"15", Block:"131", label:"GOVERNMENT HIGH SCHOOL, THITTUPADUGAI", Sch_type:"Government", Category:"High School"},
    { key:"16007", district:"15", Block:"131", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PUDUPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16008", district:"15", Block:"131", label:"RAMESH MATRICULATION HIGH SCHOOL, PUDUPATTINAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"16009", district:"15", Block:"131", label:"RAMAKRISHNA AIDED HR SEC SCHOOL, KOTHANDAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16010", district:"15", Block:"131", label:"GOVERNMENT HIGH SCHOOL, KODAKKARAMOOLAI", Sch_type:"Government", Category:"High School"},
    { key:"16011", district:"15", Block:"131", label:"GOVERNMENT HIGH SCHOOL, THANDAVANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"16012", district:"15", Block:"131", label:"MUTHUSAMI VISWANATHAN AIDED HIGH SCHOOL, MATHIRAVELUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16013", district:"15", Block:"131", label:"GOVERNMENT HIGH SCHOOL, VADARANGAM", Sch_type:"Government", Category:"High School"},
    { key:"16014", district:"15", Block:"131", label:"GMS PANANGATTANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16015", district:"15", Block:"131", label:"PUMS OLAIYAMPUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16016", district:"15", Block:"131", label:"PUMS ANANTHAKOOTHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"16017", district:"15", Block:"131", label:"BHARATH MATRICULATION Hr.SEC. SCHOOL, PUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16018", district:"15", Block:"131", label:"SRINIVASA MATRICULATION  HR.SEC SCHOOL, KOLLIDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16019", district:"15", Block:"131", label:"PUMS PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16020", district:"15", Block:"131", label:"MEENATCHI VIDYALAYA MATRICULATION HSS SCHOOL,   PUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16021", district:"15", Block:"131", label:"GOVERNMENT HIGH SCHOOL, MANALAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"16022", district:"15", Block:"131", label:"GHSS THULASENTHIRAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16023", district:"15", Block:"131", label:"VENKATESA AIDED HR SEC SCHOOL AYANKUDIPALLAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16024", district:"15", Block:"131", label:"SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL, KOLLIDAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16025", district:"35", Block:"382", label:"PUMS,ARAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"16026", district:"35", Block:"382", label:"GHSS, ALANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16027", district:"35", Block:"382", label:"GHSS - AVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16028", district:"35", Block:"382", label:"GHS- CHANDRASEKARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16029", district:"35", Block:"382", label:"GHSS - GOVINDAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16030", district:"35", Block:"382", label:"UAMHSS,YOUTH WELFARE MATRIC HIGHER SECONDARY SCHOOL ,GOVINDAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16031", district:"35", Block:"382", label:"GHSS - HARIDWARAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16032", district:"35", Block:"382", label:"PUMS KANDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16033", district:"35", Block:"382", label:"PUMS KOTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16034", district:"35", Block:"382", label:"UAHS,ST MARYS MATRIC ,KOTTAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"16035", district:"35", Block:"382", label:"AMS VIDAIYALKARUPPUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16036", district:"35", Block:"382", label:"GHS - MUNIYUR", Sch_type:"Government", Category:"High School"},
    { key:"16037", district:"35", Block:"382", label:"PUMS NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16038", district:"35", Block:"382", label:"PUMS NARTHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16039", district:"35", Block:"382", label:"PUMS PADAGACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"16040", district:"35", Block:"382", label:"PUMS PERUNGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16041", district:"35", Block:"382", label:"PUMS  KETHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16042", district:"35", Block:"382", label:"PUMS THENKARAI ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16043", district:"35", Block:"382", label:"PUMS VILATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16044", district:"35", Block:"382", label:"UAMHS, CARMEL MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"16045", district:"35", Block:"382", label:"GBHSS VALANGAIMAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16046", district:"35", Block:"382", label:"GGHSS - VALANGAIMAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16047", district:"35", Block:"382", label:"UAMHSS  SRI SANKARA  MATRICULATION HIGHER SECONDARY SCHOOL, VALANGAIMAN .", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16048", district:"35", Block:"141", label:"GHS, PALLAVANATTHAM", Sch_type:"Government", Category:"High School"},
    { key:"16049", district:"35", Block:"141", label:"GHSS MANANTANGUDI AYYAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16050", district:"35", Block:"141", label:"GHSS KANDIRAMANICKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16051", district:"35", Block:"141", label:"GHS KOOTHANUR", Sch_type:"Government", Category:"High School"},
    { key:"16052", district:"35", Block:"141", label:"GHSS ERAVANCHERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16053", district:"35", Block:"141", label:"AHSS, MANJAKKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16054", district:"35", Block:"141", label:"UAHSS, MANJAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16055", district:"35", Block:"141", label:"AMS,MOOLANKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16056", district:"35", Block:"141", label:"UAMHS, MOOLANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16057", district:"35", Block:"141", label:"GHS SENGALIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16058", district:"35", Block:"141", label:"GHSS ENNAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16059", district:"35", Block:"141", label:"GHS, THIRUVIDACHERY", Sch_type:"Government", Category:"High School"},
    { key:"16060", district:"35", Block:"141", label:"GHS THIRUVIZHIMIZHALAI", Sch_type:"Government", Category:"High School"},
    { key:"16061", district:"35", Block:"141", label:"AHS, VADAMATTAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16062", district:"35", Block:"141", label:"AMS,VIKKIRAPANDIYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16063", district:"35", Block:"141", label:"AHSS,VISHNUPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16064", district:"35", Block:"141", label:"GBHSS, KUDAVASAL,AGARAOHAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16065", district:"35", Block:"141", label:"GGHSS  KODAVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16066", district:"35", Block:"141", label:"UAHSS SRI MAHADEVA  GURUJI  VIDHYALAYA MATRICULATION HIGHER  SECONDARYSCHOOL KUDAVASAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16067", district:"35", Block:"134", label:"UAMS SHIKSHAYATAN , ARASAVANANKADU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16068", district:"35", Block:"134", label:"AMS,MUGUNTHANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16069", district:"35", Block:"134", label:"AMS PATTUDAIYANIRRIPPU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16070", district:"35", Block:"134", label:"PUMS, KONDAIYANIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"16071", district:"35", Block:"134", label:"PUMS ETTIYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16072", district:"35", Block:"134", label:"PUMS VADAKANDAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16073", district:"35", Block:"134", label:"PUMS ABIVIRUTHEESWARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16074", district:"35", Block:"134", label:"AHSS,SEMBANGUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16075", district:"35", Block:"134", label:"PUMS PERUMPUGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16076", district:"35", Block:"134", label:"GHS,MANAKKAL AYYAMPET", Sch_type:"Government", Category:"High School"},
    { key:"16077", district:"35", Block:"134", label:"PUMS, VANDAMPALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16078", district:"35", Block:"134", label:"GHS,ENGAN", Sch_type:"Government", Category:"High School"},
    { key:"16079", district:"35", Block:"134", label:"GHS,SELLUR", Sch_type:"Government", Category:"High School"},
    { key:"16080", district:"35", Block:"134", label:"PUMS PAVITHRAMANICKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16081", district:"35", Block:"134", label:"UAMS CAMPPAIGN  MATRICULATION  SCHOOL ,PAVITHRAMANICKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16082", district:"35", Block:"134", label:"AMS PANNAIVILAGAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16083", district:"35", Block:"134", label:"GHS,ATHIKKADAI", Sch_type:"Government", Category:"High School"},
    { key:"16084", district:"35", Block:"134", label:"PUMS THIRUVIDAIVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16085", district:"35", Block:"134", label:"PUMS AMMAIYAPPAN EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"16086", district:"35", Block:"134", label:"PUMS, AMMAIYAPPAN WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"16087", district:"35", Block:"134", label:"GHSS,AMMAIYAPPAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16088", district:"35", Block:"134", label:"GHS,KANKODUTHAVANITHAM", Sch_type:"Government", Category:"High School"},
    { key:"16089", district:"35", Block:"134", label:"PUMS VENNAVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16090", district:"35", Block:"134", label:"PUMS KILARIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16091", district:"35", Block:"134", label:"AMS PATHUR MELKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16092", district:"35", Block:"134", label:"PUMS KULIKKARAI WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"16093", district:"35", Block:"134", label:"GHSS,KULIKKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16094", district:"35", Block:"134", label:"PUMS, CHETTISIMIZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16095", district:"35", Block:"134", label:"PUMS THIRUKKANNAMANGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16096", district:"35", Block:"134", label:"PUMS MELARATHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16097", district:"35", Block:"134", label:"GHSS,KATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16098", district:"35", Block:"134", label:"PUMS ANAIVADAPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16099", district:"35", Block:"134", label:"PUMS ERUKKATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16100", district:"35", Block:"134", label:"PUMS MELATHIRUMATHIKUNNAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16101", district:"35", Block:"134", label:"PUMS KODIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16102", district:"35", Block:"134", label:"PUMS MUSIRIAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16103", district:"35", Block:"134", label:"GMS DEVARKANDANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16104", district:"35", Block:"134", label:"GGHSS,KORADACHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16105", district:"35", Block:"134", label:"GBHSS ,KORADACHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16106", district:"35", Block:"134", label:"UAHS UNITED MATRIC,KORADACHERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16107", district:"35", Block:"205", label:"GHS, KEERANOOR", Sch_type:"Government", Category:"High School"},
    { key:"16108", district:"35", Block:"205", label:"ADWHSS, NEDUNGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16109", district:"35", Block:"205", label:"UAHS,MASH,PAVATTAKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16110", district:"35", Block:"205", label:"GHSS , KADUVANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16111", district:"35", Block:"205", label:"PUMS,THIRUMEEACHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16112", district:"35", Block:"205", label:"PUMS, PANDARAVADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16113", district:"35", Block:"205", label:"GHS, KOLLAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16114", district:"35", Block:"205", label:"UAMS AL-ISLAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"16115", district:"35", Block:"205", label:"AMS, POONTHOTTAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16116", district:"35", Block:"205", label:"GHSS, POONTHOTTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16117", district:"35", Block:"205", label:"UAMHSS, LALITHAMBIKAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16118", district:"35", Block:"205", label:"PUMS,MAHARAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16119", district:"35", Block:"205", label:"GHSS SRIVANCHIYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16120", district:"35", Block:"205", label:"GHSS,ACHUTHAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16121", district:"35", Block:"205", label:"AHS, MUDIKONDAN", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16122", district:"35", Block:"205", label:"GHSS   ANAIKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16123", district:"35", Block:"205", label:"GHS, MAPPILAIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"16124", district:"35", Block:"205", label:"GHSS, PANANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16125", district:"35", Block:"205", label:"PUMS,MOONGILGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16126", district:"35", Block:"205", label:"AMS-THANGAM, NALLAMANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16127", district:"35", Block:"205", label:"UAMHSS, VALLALAR GURUKULAM NANNI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16128", district:"35", Block:"205", label:"GGHSS, NANNILAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16129", district:"35", Block:"205", label:"GBHSS, NANNILAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16130", district:"35", Block:"205", label:"GHS, SANNANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"16131", district:"35", Block:"205", label:"UAHS,SRIVIDHYA  MATRICULATION ,PERALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"16132", district:"35", Block:"205", label:"UAMHSS SANKARA PERALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16133", district:"35", Block:"205", label:"GGHSS ,PERALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16134", district:"35", Block:"205", label:"GHSS PERALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16135", district:"35", Block:"340", label:"GHSS, ADIYAKKAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16136", district:"35", Block:"340", label:"GHSS, ALIVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16137", district:"35", Block:"340", label:"ADWHS , AMOOR", Sch_type:"Government", Category:"High School"},
    { key:"16138", district:"35", Block:"340", label:"PUMS, KALLIKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16139", district:"35", Block:"340", label:"PUMS KALYANAMAHADEVI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16140", district:"35", Block:"340", label:"GHS,KOTTARAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16141", district:"35", Block:"340", label:"UAHSS,THE MERIT SENTHAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16142", district:"35", Block:"340", label:"PUMS KOODUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16143", district:"35", Block:"340", label:"GHS MANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"16144", district:"35", Block:"340", label:"PUMS.ODACHERY", Sch_type:"Government", Category:"Middle School"},
    { key:"16145", district:"35", Block:"340", label:"GHS PAZHAYAVALAM", Sch_type:"Government", Category:"High School"},
    { key:"16146", district:"35", Block:"340", label:"GHSS, PUDHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16147", district:"35", Block:"340", label:"PUMS PUTHUPATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16148", district:"35", Block:"340", label:"GHSS, PULIVALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16149", district:"35", Block:"340", label:"UAMS,SRIKANCHI-SANKARA, PULIVALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16150", district:"35", Block:"340", label:"PUMS  PUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16151", district:"35", Block:"340", label:"PUMS THANDALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16152", district:"35", Block:"340", label:"PUMS, THIYANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16153", district:"35", Block:"340", label:"GHS, VILAMAL", Sch_type:"Government", Category:"High School"},
    { key:"16154", district:"35", Block:"340", label:"AMS SRI KRISHNA ,THAPPALAMPULIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16155", district:"35", Block:"340", label:"PUMS THIRUKKARAVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16156", district:"35", Block:"340", label:"PUMS KOMAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16157", district:"35", Block:"340", label:"GHSS, THIRUNEIPAIR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16158", district:"35", Block:"340", label:"PUMS TIRUVATHIRAIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16159", district:"35", Block:"340", label:"GHSS, SURANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16160", district:"35", Block:"340", label:"GHS,VADAKARAI", Sch_type:"Government", Category:"High School"},
    { key:"16161", district:"35", Block:"340", label:"PUMS  VAIPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16162", district:"35", Block:"340", label:"PUMS VEPPATHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16163", district:"35", Block:"340", label:"AHSS, VELUDAYAR ,TIRUVARUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16164", district:"35", Block:"340", label:"UAMHSS,KASTURBA ,TIRUVARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16165", district:"35", Block:"340", label:"AMS-SS ,TIRUVARUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16166", district:"35", Block:"340", label:"ABHSS,VST, TIRUVARUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16167", district:"35", Block:"340", label:"GHSS, KODIKKALPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16168", district:"35", Block:"340", label:"UAMHSS,SAIRAM,TIRUVARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16169", district:"35", Block:"340", label:"UAMHS ,NETHAJI SUBASH BOSE MATRIC", Sch_type:"Un-aided", Category:"High School"},
    { key:"16170", district:"35", Block:"340", label:"MMS,GOWRISAMY , THIRUVARUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16171", district:"35", Block:"340", label:"AMS, SAIVAPRAGASA,TIRUVARUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16172", district:"35", Block:"340", label:"AGHSS,GRM,TIRUVARUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16173", district:"35", Block:"340", label:"MUNICIPAL  HIGH SCHOOL , MADAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16174", district:"35", Block:"340", label:"UAMHSS, MEENATCHI MATRIC,TIRUVARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16175", district:"35", Block:"340", label:"AMS KIDARAMKONDAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16176", district:"35", Block:"340", label:"UAHSS SHUBHA VIDYALAYA MATRICULATION", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16177", district:"35", Block:"340", label:"UAHSS-CBSE-TRINITY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16178", district:"35", Block:"340", label:"AMS, MOSES", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16179", district:"35", Block:"340", label:"AMS, RAMAN,TIRUVARUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16180", district:"35", Block:"173", label:"UAMHSS DEVI MATRIC,MANNARGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16181", district:"35", Block:"173", label:"PUMS.VENKARAMPERIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16182", district:"35", Block:"173", label:"PUMS,CHITHIRAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16183", district:"35", Block:"173", label:"PUMS.ARICHANDRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16184", district:"35", Block:"173", label:"UAMS,SRI VIVEKANANDHA VIDYALAYA  MATRICULATION  SCHOOL , KARIKKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16185", district:"35", Block:"173", label:"PUMS.KANDITHAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16186", district:"35", Block:"173", label:"GHSS,THIRUVALLUVAR ALANGOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16187", district:"35", Block:"173", label:"GHSS MAHADEVAPPATTANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16188", district:"35", Block:"173", label:"PUMS.MELATHIRUPPALAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16189", district:"35", Block:"173", label:"PUMS, THIRURAMESWARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16190", district:"35", Block:"173", label:"AHS,THIRUMURUGAN,MELAVASAL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16191", district:"35", Block:"173", label:"PUMS.MARAVAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16192", district:"35", Block:"173", label:"GHS, THANNEERKUNNAM", Sch_type:"Government", Category:"High School"},
    { key:"16193", district:"35", Block:"173", label:"PUMS.RAJAGOPALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16194", district:"35", Block:"173", label:"GHSS PAINGANADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16195", district:"35", Block:"173", label:"AMS VENUDAIYAR, PALAIYANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16196", district:"35", Block:"173", label:"GHSS PARAVAKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16197", district:"35", Block:"173", label:"GHSS,PULLAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16198", district:"35", Block:"173", label:"GHSS,SERANKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16199", district:"35", Block:"173", label:"PUMS.SUNDHARAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16200", district:"35", Block:"173", label:"ADWHSS, SAVALAKARAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16201", district:"35", Block:"173", label:"AHS MEENAKSHI MARIYAPPAR, CHOLAPANDI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16202", district:"35", Block:"173", label:"GHS,THULASENDRAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16203", district:"35", Block:"173", label:"PUMS,VADAKOVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"16204", district:"35", Block:"173", label:"GHS, VADAPATHIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"16205", district:"35", Block:"173", label:"PUMS.BOOTHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16206", district:"35", Block:"173", label:"PUMS.VADAPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16207", district:"35", Block:"173", label:"UAMHSS,NAVA BHARATH MATRIC HR.SEC.SCHOOL ULLIKKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16208", district:"35", Block:"173", label:"GHSS,ULLIKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16209", district:"35", Block:"173", label:" CBSE, ARJ HR.SEC SCHOOL, EDAYAR NATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16210", district:"35", Block:"173", label:"UAMS ESAR MATRIC SCHOOL, LAKSHMANGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16211", district:"35", Block:"173", label:"GBHSS, KOOTHANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16212", district:"35", Block:"173", label:"PUMS MARAKKADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16213", district:"35", Block:"173", label:"AHSS (BOYS) ,MANBA-UL-ULA KOOTHANALLUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16214", district:"35", Block:"173", label:"GGHSS,KOOTHANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16215", district:"35", Block:"173", label:"CWSN MS MANOLAYAM, MELAPANANGATTANKUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16216", district:"35", Block:"173", label:"UAMHSS SBA MATRIC SCHOOL,MANNARGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16217", district:"35", Block:"173", label:"AMS JAYALAKSHMI VILAS, MANNARGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16218", district:"35", Block:"173", label:"AMS,VANI VILAS, MANNARGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16219", district:"35", Block:"173", label:"AHS ADHIPARASAKTHI, MANNARGUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16220", district:"35", Block:"173", label:"UAMHS,ASHOKA SISHU VIHAR MATRIC, MANNARGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16221", district:"35", Block:"173", label:"AMS,MANIMEKALAI ,MANNARGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16222", district:"35", Block:"173", label:"AHSS,NATIONAL, MANNARGUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16223", district:"35", Block:"173", label:"UAMHS,BARATHI VIDYALAYA, MANNARGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16224", district:"35", Block:"173", label:"UAMHS SUBASH CHANDRA BOSE MATRIC, MANNARGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16225", district:"35", Block:"173", label:"GGHSS MODEL ,MANNARGUDI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16226", district:"35", Block:"173", label:"AGHSS ST.JOSEPH, MANNARGUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16227", district:"35", Block:"173", label:"UAMHSS SAVIOUR JESUS MATRIC, MANNARGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16228", district:"35", Block:"173", label:"UAMHSS SRI SHANMUKA , MANNARGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16229", district:"35", Block:"173", label:"UAMHSS,BHARATHIDASAN MATRIC, MANNARGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16230", district:"35", Block:"173", label:"UAHSS,LORD SEVEN HILLS MANNARGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16231", district:"35", Block:"173", label:"UAHS,MANNAI NARAYANASAMY MATRIC, MANNARGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16232", district:"35", Block:"173", label:"UAMHSS SRI SHANMUKA , NEW BYE PASS ROAD, MANNARGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16233", district:"35", Block:"173", label:"AHS ST JOSEPH MANNARGUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16234", district:"35", Block:"173", label:"CBSE DHARANI VIDYA MANTHIR  ,MANNARGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16235", district:"35", Block:"209", label:"UAHSS, MOTHERINDIA, VALACHERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16236", district:"35", Block:"209", label:"PUMS,PODAKKUDI SOUTH SSA", Sch_type:"Government", Category:"Middle School"},
    { key:"16237", district:"35", Block:"209", label:"GBHSS, PODAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16238", district:"35", Block:"209", label:"GGHSS, PODAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16239", district:"35", Block:"209", label:"GHS , KOVILVENNI", Sch_type:"Government", Category:"High School"},
    { key:"16240", district:"35", Block:"209", label:"GHS, EDAKEELAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"16241", district:"35", Block:"209", label:"GHSS, MUNNAVALKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16242", district:"35", Block:"209", label:"GHS, PERAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"16243", district:"35", Block:"209", label:"PUMS, KALACHERI WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"16244", district:"35", Block:"209", label:"GHSS, RAYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16245", district:"35", Block:"209", label:"AHSS VADUVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16246", district:"35", Block:"209", label:"UAMHSS,L.F.VISWANATH, EDAMELAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16247", district:"35", Block:"209", label:"GHS, PULLAVARAYANKUDIKKADU", Sch_type:"Government", Category:"High School"},
    { key:"16248", district:"35", Block:"209", label:"GHS, KATTAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16249", district:"35", Block:"209", label:"GHSS, PERAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16250", district:"35", Block:"209", label:"PUMS, THALIKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16251", district:"35", Block:"209", label:"GHSS, KARUVAKKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16252", district:"35", Block:"209", label:"PUMS, VADUVUR THENPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16253", district:"35", Block:"209", label:"PUMS, NALLIKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16254", district:"35", Block:"209", label:"PUMS, MELAPOOVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"16255", district:"35", Block:"209", label:"GHS, POOVANOOR", Sch_type:"Government", Category:"High School"},
    { key:"16256", district:"35", Block:"209", label:"PAHS,MELALAVANTHACHERI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16257", district:"35", Block:"209", label:"UAMHSS, NEELAN MATRIC HR.SEC,SCHOOL.NEEDAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16258", district:"35", Block:"209", label:"AHSS, NEEDAMANGALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16259", district:"35", Block:"209", label:"UAMHSS, ST.JUDE'S MATRIC HIGHER SECONDARY SCHOOL,  NEEDAMANGALAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16260", district:"35", Block:"209", label:"GHS, NEEDAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"16261", district:"35", Block:"137", label:"PUMS, ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16262", district:"35", Block:"137", label:"AMS, ST ANNE'S MIDDLE SCHOOL DEVATHANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16263", district:"35", Block:"137", label:"GHSS,KALAPPAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16264", district:"35", Block:"137", label:"PUMS, NARUVALI KALAPPAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16265", district:"35", Block:"137", label:"PUMS, KEZHUVATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16266", district:"35", Block:"137", label:"PUMS, KARUPPUKILAR GANESAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16267", district:"35", Block:"137", label:"GGHSS,KOTTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16268", district:"35", Block:"137", label:"GHSS,KOTTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16269", district:"35", Block:"137", label:"PUMS, 83.KULAMANICKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16270", district:"35", Block:"137", label:"GHSS,PUTHAGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16271", district:"35", Block:"137", label:"PUMS, KUNNIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16272", district:"35", Block:"137", label:"PUMS, KURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16273", district:"35", Block:"137", label:"PUMS, MANNUKUMUNDAN", Sch_type:"Government", Category:"Middle School"},
    { key:"16274", district:"35", Block:"137", label:"PUMS, MAVATTAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16275", district:"35", Block:"137", label:"PUMS, MELANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16276", district:"35", Block:"137", label:"GHS,MAZHAVARAYANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"16277", district:"35", Block:"137", label:"PUMS, NOCHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16278", district:"35", Block:"137", label:"PUMS, ORATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16279", district:"35", Block:"137", label:"GHSS ,PALAYAKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16280", district:"35", Block:"137", label:"GHS,MELAPANAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"16281", district:"35", Block:"137", label:"GHSS,PERUGAVAZHNDAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16282", district:"35", Block:"137", label:"PUMS, KUMARAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16283", district:"35", Block:"137", label:"GHS,PALAYUR", Sch_type:"Government", Category:"High School"},
    { key:"16284", district:"35", Block:"137", label:"PUMS, RENGANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16285", district:"35", Block:"137", label:"GHS,SERUVAMANI", Sch_type:"Government", Category:"High School"},
    { key:"16286", district:"35", Block:"137", label:"GHS,THENPARAI", Sch_type:"Government", Category:"High School"},
    { key:"16287", district:"35", Block:"137", label:"GHS,THERKUNANALUR", Sch_type:"Government", Category:"High School"},
    { key:"16288", district:"35", Block:"137", label:"GHSS(B) THIRUMAKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16289", district:"35", Block:"137", label:"GGHSS  THIRUMAKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16290", district:"35", Block:"137", label:"GHS,THIRUKKALAR", Sch_type:"Government", Category:"High School"},
    { key:"16291", district:"35", Block:"137", label:"PUMS, THIRUNELLIKAVAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16292", district:"35", Block:"137", label:"GHS,VATTAR", Sch_type:"Government", Category:"High School"},
    { key:"16293", district:"35", Block:"137", label:"GHS,VIKIRAPANDIYAM", Sch_type:"Government", Category:"High School"},
    { key:"16294", district:"35", Block:"334", label:"PUMS ANDANKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16295", district:"35", Block:"334", label:"PUMS RAJAKOTHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16296", district:"35", Block:"334", label:"AHS, JOHN PRITO PALLANKOVIL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16297", district:"35", Block:"334", label:"AMS,RAYANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16298", district:"35", Block:"334", label:"GHS, EZHILUR", Sch_type:"Government", Category:"High School"},
    { key:"16299", district:"35", Block:"334", label:"PUMS KOMALAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16300", district:"35", Block:"334", label:"PUMS THIRUPATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16301", district:"35", Block:"334", label:"PUMS ALIVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16302", district:"35", Block:"334", label:"PUMS VARAMBIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16303", district:"35", Block:"334", label:"PUMS KUNNUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16304", district:"35", Block:"334", label:"GHSS  NEDUMBALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16305", district:"35", Block:"334", label:"GHSS, ALATHAMPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16306", district:"35", Block:"334", label:"GHSS VILAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16307", district:"35", Block:"334", label:"UAHS VENKATESWARA MATRICULATION SCHOOL KATCHANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"16308", district:"35", Block:"334", label:"GHS KATCHANAM", Sch_type:"Government", Category:"High School"},
    { key:"16309", district:"35", Block:"334", label:"PUMS ARAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16310", district:"35", Block:"334", label:"PUMS ADIRENGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16311", district:"35", Block:"334", label:"PUMS PALAYANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16312", district:"35", Block:"334", label:"GHSS,KATTIMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16313", district:"35", Block:"334", label:"PUMS SEKAL KOTTAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16314", district:"35", Block:"334", label:"PUMS MANALI", Sch_type:"Government", Category:"Middle School"},
    { key:"16315", district:"35", Block:"334", label:"GHS MANALI", Sch_type:"Government", Category:"High School"},
    { key:"16316", district:"35", Block:"334", label:"PUMS SEKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16317", district:"35", Block:"334", label:"PUMS SEKAL MADAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16318", district:"35", Block:"334", label:"PUMS MELAKORUKKAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16319", district:"35", Block:"334", label:"AMS KORUKKAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16320", district:"35", Block:"334", label:"GHSS, THALAIKKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16321", district:"35", Block:"334", label:"UAHS SAI SRINIVAS,TTP", Sch_type:"Un-aided", Category:"High School"},
    { key:"16322", district:"35", Block:"334", label:"GHSS PAMANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16323", district:"35", Block:"334", label:"PUMS PITCHANKOTTAGAM(N)", Sch_type:"Government", Category:"Middle School"},
    { key:"16324", district:"35", Block:"334", label:"PUMS MELAMARUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16325", district:"35", Block:"334", label:"GHS, THIRUTHANGOOR", Sch_type:"Government", Category:"High School"},
    { key:"16326", district:"35", Block:"334", label:"AMS, KEERAKKALUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16327", district:"35", Block:"334", label:"UAMHS ST.THERASA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"16328", district:"35", Block:"334", label:"AHSS,ST.THERASA,TTP", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16329", district:"35", Block:"334", label:"ADWHSS,ABISEKAKATTALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16330", district:"35", Block:"334", label:"GHSS GIRLS THIRUTHURAIPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16331", district:"35", Block:"334", label:"GBHSS,THIRUTHURAIPOONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16332", district:"35", Block:"193", label:"GHS ALANGADU", Sch_type:"Government", Category:"High School"},
    { key:"16333", district:"35", Block:"193", label:"GHSS IDUMBAVANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16334", district:"35", Block:"193", label:"GHS, KEEZHAVADIYAKADU", Sch_type:"Government", Category:"High School"},
    { key:"16335", district:"35", Block:"193", label:"PUMS,KALLADIKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16336", district:"35", Block:"193", label:"GHS,JAMBUVANODAI NORTH", Sch_type:"Government", Category:"High School"},
    { key:"16337", district:"35", Block:"193", label:"UAMHS, OMA JAMBUVANODAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16338", district:"35", Block:"193", label:"UAMHSS PALM KALLIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16339", district:"35", Block:"193", label:"GHS,KEELAPERUMAZHAI", Sch_type:"Government", Category:"High School"},
    { key:"16340", district:"35", Block:"193", label:"GHS MANKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16341", district:"35", Block:"193", label:"GHS MARUTHAVANAM", Sch_type:"Government", Category:"High School"},
    { key:"16342", district:"35", Block:"193", label:"PANCHAYAT UNION MIDDLE SCHOOL,VELLANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16343", district:"35", Block:"193", label:"UAMHS SRI OM SAKTHI ,VEPPANCHERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16344", district:"35", Block:"193", label:"GHSS THILLAIVILGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16345", district:"35", Block:"193", label:"GHS SENGANKADU", Sch_type:"Government", Category:"High School"},
    { key:"16346", district:"35", Block:"193", label:"GHSS,NACHIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16347", district:"35", Block:"193", label:"UAMHS MILLENNIUM ", Sch_type:"Un-aided", Category:"High School"},
    { key:"16348", district:"35", Block:"193", label:"GHS-KARAIYANKADU", Sch_type:"Government", Category:"High School"},
    { key:"16349", district:"35", Block:"193", label:"KAP AGHSS, PERIYANAYAKI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16350", district:"35", Block:"193", label:"GHSS MUTHUPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16351", district:"35", Block:"193", label:"UAMHSS,RAHAMATH, MUTHUPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16352", district:"35", Block:"193", label:"UAMHS,WINNER ALANGADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"16353", district:"25", Block:"314", label:"VITTAL MATRIC SCHOOL PALLIYAGGRAHARAM-THANJAVUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16354", district:"25", Block:"314", label:"UMA MAHESWARA HSS-KARANTHAI-THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16355", district:"25", Block:"314", label:"KALYANASUNDARAM HIGHER SECONDARY SCHOOL, THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16356", district:"25", Block:"314", label:"UKKADAI SRI AMBAL HIGH SCHOOL -MELAVEETHI-THANJAVUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16357", district:"25", Block:"314", label:"VEERA RAGHAVA HSS-THERKU VEETHI-THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16358", district:"25", Block:"314", label:"ORIENTAL HSS-THERKKU VEETHI-THANJAVUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16359", district:"25", Block:"314", label:"CHSS-THENKEEL ALANGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16360", district:"25", Block:"314", label:"GHSS (GIRLS) - THANJAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16361", district:"25", Block:"314", label:"RAJAH'S HSS - ARANMANAI VALAGAM-THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16362", district:"25", Block:"314", label:"BLAKE AIDED HIGHER SECONDARY SCHOOL - M-CHAVADI-THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16363", district:"25", Block:"314", label:"CMS-SOWRASTRA THERU", Sch_type:"Government", Category:"Middle School"},
    { key:"16364", district:"25", Block:"314", label:"GMS RAJAJI - THANJAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16365", district:"25", Block:"314", label:"GHSS(BOYS) - MEMBALAM-THANJAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16366", district:"25", Block:"314", label:"GHSS -HEARING IMPAIRED (MEMBALAM)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16367", district:"25", Block:"314", label:"P.V.SELVARAJ (GIRLS) AIDED HR SEC SCHOOL- MEMBALAM-THANJAVUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16368", district:"25", Block:"314", label:"GHSS-VISUALLY IMPAIRED(MEMBALAM)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16369", district:"25", Block:"314", label:"THIRUMAGAL AIDED HR SEC SCHOOL - M.C.ROAD-THANJAVUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16370", district:"25", Block:"314", label:"ALLWIN HSS-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16371", district:"25", Block:"314", label:"PONNAIYAH RAMAJAYAM PUBLIC HR. SEC. SCHOOL - THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16372", district:"25", Block:"314", label:"SPADIX MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16373", district:"25", Block:"314", label:"CHS- NEELAGIRI", Sch_type:"Government", Category:"High School"},
    { key:"16374", district:"25", Block:"314", label:"AUXILIUM GIRLS HIGHER SECONDARY SCHOOL-THANJAVUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16375", district:"25", Block:"314", label:"MAHARISHI VIDYA MANDIR CBSE SCHOOL -THANJAUVR", Sch_type:"Un-aided", Category:"High School"},
    { key:"16376", district:"25", Block:"314", label:"SACRED HEART (GIRLS)HSS - THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16377", district:"25", Block:"314", label:"ST. ANTONYS  HSS-THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16378", district:"25", Block:"314", label:"SEVENTH DAY ADVENTIST MATRIC HSS,VANDIKARATHERU-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16379", district:"25", Block:"314", label:"ST. JOSEPH(GIRLS) HSS-POOKARATHERU-THANJAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16380", district:"25", Block:"314", label:"THE CHRIST THE KING HSS-A.S.NA-THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16381", district:"25", Block:"314", label:"CHSS-ANNANAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16382", district:"25", Block:"313", label:"GHS- KALLAPERAMPUR", Sch_type:"Government", Category:"High School"},
    { key:"16383", district:"25", Block:"313", label:"PUMS - VANNARAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16384", district:"25", Block:"313", label:"GHSS - ALAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16385", district:"25", Block:"313", label:"ST. MARY'S HIGH SCHOOL  - THIRUKKANURPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16386", district:"25", Block:"313", label:"KALAIMAGAL HR SEC SCHOOL VALLAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16387", district:"25", Block:"313", label:"KENDRIYA VIDYALAYA  AF STATION", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"16388", district:"25", Block:"313", label:"GHS-PILLAIYARPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16389", district:"25", Block:"313", label:"A.A. SUGAR FACT. HS-KURUNGULAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16390", district:"25", Block:"313", label:"GHS - KURUNGULAM EAST", Sch_type:"Government", Category:"High School"},
    { key:"16391", district:"25", Block:"313", label:"GHS - MATHUR (WEST)", Sch_type:"Government", Category:"High School"},
    { key:"16392", district:"25", Block:"313", label:"GHS - THAZHAKKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16393", district:"25", Block:"313", label:"THAMARAI INTERNATIONAL -KADAKADAPAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16394", district:"25", Block:"313", label:"G (G)HSS (GIRLS) - MARIYAMMAN KOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16395", district:"25", Block:"313", label:"GBHSS - MARIYAMMAN KOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16396", district:"25", Block:"313", label:"ST.MARY'S HR SEC SCHOOL -MARIAMMANKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16397", district:"25", Block:"313", label:"ST.MICHAEL'S HIGH SCHOOL - MADHAKKOTTAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16398", district:"25", Block:"313", label:"LITTLESCHOLARS(T)HS-PILOMINANAGER", Sch_type:"Un-aided", Category:"High School"},
    { key:"16399", district:"25", Block:"313", label:"GHS- EZHUPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16400", district:"25", Block:"313", label:"GHS - N. VALLUNDANPATTU", Sch_type:"Government", Category:"High School"},
    { key:"16401", district:"25", Block:"313", label:"GHSS - MARUNGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16402", district:"25", Block:"313", label:"GHS - KOLLANKARAI", Sch_type:"Government", Category:"High School"},
    { key:"16403", district:"25", Block:"313", label:"GHS - KASANADU PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"16404", district:"25", Block:"313", label:"AIDED  MS - KANDITHAMPATTU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16405", district:"25", Block:"313", label:"ST. JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL,  VILAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16406", district:"25", Block:"313", label:"BLOSSOM PUBLIC SCHOOL -SURAKKOTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16407", district:"25", Block:"313", label:"GOVT ADW  HS -  MANOJIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16408", district:"25", Block:"313", label:"ST.MARYS HS - THILLAI NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"16409", district:"25", Block:"313", label:"GHS - PANAVELI", Sch_type:"Government", Category:"High School"},
    { key:"16410", district:"25", Block:"313", label:"ARULNERI HIGH SCHOOL- AMMANPETTAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16411", district:"25", Block:"313", label:"GBHSS - VALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16412", district:"25", Block:"313", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL -VALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16413", district:"25", Block:"336", label:"AMS-SIVASAMIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16414", district:"25", Block:"336", label:"Sacred Heart Higher Secondary School  Michaelpatty", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16415", district:"25", Block:"336", label:"GHS- VANARANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"16416", district:"25", Block:"336", label:"GHS-SATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"16417", district:"25", Block:"336", label:"GHS VALAPPAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16418", district:"25", Block:"336", label:"ST.THOMAS RC HIGH SCHOOL -MANATHIDAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16419", district:"25", Block:"336", label:"GHS VARAGOOR", Sch_type:"Government", Category:"High School"},
    { key:"16420", district:"25", Block:"336", label:"PUMS ALLISAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16421", district:"25", Block:"336", label:"GHS MOHAMED BUNDAR", Sch_type:"Government", Category:"High School"},
    { key:"16422", district:"25", Block:"336", label:"GHS KANDIYUR", Sch_type:"Government", Category:"High School"},
    { key:"16423", district:"25", Block:"336", label:"GHS ACHANUR", Sch_type:"Government", Category:"High School"},
    { key:"16424", district:"25", Block:"336", label:"ADW HSS-KEELAPUNALVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16425", district:"25", Block:"336", label:"GHSS - MELATHIRUPPANTHURITHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16426", district:"25", Block:"336", label:"ST.JOSEPH HS-THIRUVAIYARU-E", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16427", district:"25", Block:"336", label:"GHSS(GIRLS) THIRUVAIYARU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16428", district:"25", Block:"336", label:"SR.HSS-THIRUVAIYARU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16429", district:"25", Block:"42", label:"SINNARANI AHS- A.POONDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16430", district:"25", Block:"42", label:"ADWHSS-AGARAPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16431", district:"25", Block:"42", label:"GHS-ACHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16432", district:"25", Block:"42", label:"GHS-INDALUR", Sch_type:"Government", Category:"High School"},
    { key:"16433", district:"25", Block:"42", label:"GHS-KOVILADI", Sch_type:"Government", Category:"High School"},
    { key:"16434", district:"25", Block:"42", label:"SRIDIVYA MKR MAT.SCHOOL-SANOORAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16435", district:"25", Block:"42", label:"GHS-CHELLAPPANPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"16436", district:"25", Block:"42", label:"GHSS-SENGIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16437", district:"25", Block:"42", label:"ADW HS -PUDUKUDDI", Sch_type:"Government", Category:"High School"},
    { key:"16438", district:"25", Block:"42", label:"ST PETER PAULS  HS -MUTHANDIPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16439", district:"25", Block:"42", label:"GGHSS -BUDALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16440", district:"25", Block:"42", label:"GBHSS-BUDALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16441", district:"25", Block:"42", label:"GHSS-MANAIYERIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16442", district:"25", Block:"42", label:"ADWHS-MUTHUVEERAKANDIYANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16443", district:"25", Block:"42", label:"GHSS-ELANGADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16444", district:"25", Block:"42", label:"GHS-RAYAMUNDANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16445", district:"25", Block:"42", label:"GHS-THIRUKKATTUPALLI", Sch_type:"Government", Category:"High School"},
    { key:"16446", district:"25", Block:"42", label:"SIVASWAMI AYYAR CENTENARY GIRLS' HIGH SCHOOL THIRUKKATTUPPALLI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16447", district:"25", Block:"42", label:"SIR SIVASWAMI AYYAR HSS-THIRUKATTUPALLI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16448", district:"25", Block:"216", label:"PUMS - AZHIVAIKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16449", district:"25", Block:"216", label:"GHS - AZHIAVAIKKAL NATHAM", Sch_type:"Government", Category:"High School"},
    { key:"16450", district:"25", Block:"216", label:"GHSS-ILLUPPAITHOPPU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16451", district:"25", Block:"216", label:"PUMS-KUDIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16452", district:"25", Block:"216", label:"GHS - ADHANAKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"16453", district:"25", Block:"216", label:"GHSS - PAPPANADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16454", district:"25", Block:"216", label:"KASILINGAM MATRIC - PAPPANADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"16455", district:"25", Block:"216", label:"MMA  HIGHER SECONDARY SCHOOL-PAPPANADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16456", district:"25", Block:"216", label:"GHS-SOLAPURAM(WEST)", Sch_type:"Government", Category:"High School"},
    { key:"16457", district:"25", Block:"216", label:"GHSS - EACHANKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16458", district:"25", Block:"216", label:"GHS - KAKKARAI", Sch_type:"Government", Category:"High School"},
    { key:"16459", district:"25", Block:"216", label:"GHS - KANNANTHANKUDI (EAST)", Sch_type:"Government", Category:"High School"},
    { key:"16460", district:"25", Block:"216", label:"GHS - KANNANTHANGUDI MELAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"16461", district:"25", Block:"216", label:"GHSS - KANNUKUDI (WEST)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16462", district:"25", Block:"216", label:"GHSS - KARUKKADIPPATTI (WEST)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16463", district:"25", Block:"216", label:"PUMS - NELLUPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"16464", district:"25", Block:"216", label:"GHS - KAVARAPPATTU", Sch_type:"Government", Category:"High School"},
    { key:"16465", district:"25", Block:"216", label:"GHS  - KULAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"16466", district:"25", Block:"216", label:"GHSS - MELAULUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16467", district:"25", Block:"216", label:"GHSS-PANAYAKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16468", district:"25", Block:"216", label:"GHS - NADUVOOR", Sch_type:"Government", Category:"High School"},
    { key:"16469", district:"25", Block:"216", label:"GHSS - NEIVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16470", district:"25", Block:"216", label:"GHS(G) - OKKANADU KEELAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"16471", district:"25", Block:"216", label:"GHSS  - OKKANADU KEELAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16472", district:"25", Block:"216", label:"GOVT. MPN H S- OKKANADU MELAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"16473", district:"25", Block:"216", label:"GHSS-URANTHARAYANKUDIKKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16474", district:"25", Block:"216", label:"GHSS -PATCHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16475", district:"25", Block:"216", label:"PUMS - PARITHIKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16476", district:"25", Block:"216", label:"D.A. GHSS  - PONNAPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16477", district:"25", Block:"216", label:"GHS-POOVATHUR", Sch_type:"Government", Category:"High School"},
    { key:"16478", district:"25", Block:"216", label:"GHSS - POYYUNDARKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16479", district:"25", Block:"216", label:"GHS - ARSUTHIPATTU", Sch_type:"Government", Category:"High School"},
    { key:"16480", district:"25", Block:"216", label:"GHS - SADAIYARKOIL", Sch_type:"Government", Category:"High School"},
    { key:"16481", district:"25", Block:"216", label:"GHS -SETHURAYANKUDIKKADU", Sch_type:"Government", Category:"High School"},
    { key:"16482", district:"25", Block:"216", label:"PUMS - THALAYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16483", district:"25", Block:"216", label:"GHSS - THEKKUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16484", district:"25", Block:"216", label:"SRI RAMAVILAS HS-THENNAMANADU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16485", district:"25", Block:"216", label:"GHSS - THIRUMANGALAKKOTTAI KEELAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16486", district:"25", Block:"216", label:"GHS -THIRUMANGALAKKOTTAI MELA COLONY", Sch_type:"Government", Category:"High School"},
    { key:"16487", district:"25", Block:"216", label:"NALANDA MATRIC SCHOOL - THIRUMANAGALKOTTAI KEELAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"16488", district:"25", Block:"216", label:"GHS-VADAKUR VADAKKU", Sch_type:"Government", Category:"High School"},
    { key:"16489", district:"25", Block:"216", label:"GHSS - VADASERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16490", district:"25", Block:"216", label:"GHSS-VANDAIYARIRUPPU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16491", district:"25", Block:"216", label:"GHS-VELLUR", Sch_type:"Government", Category:"High School"},
    { key:"16492", district:"25", Block:"216", label:"GHS-ORATHANADU PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"16493", district:"25", Block:"216", label:"GHSS (BOYS) - ORATHANADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16494", district:"25", Block:"216", label:"GHSS(G)-ORATHANADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16495", district:"25", Block:"345", label:"GHS-ATHAMBAI (NORTH)", Sch_type:"Government", Category:"High School"},
    { key:"16496", district:"25", Block:"345", label:"PUMS-ATHAMBAI(SOUTH)", Sch_type:"Government", Category:"Middle School"},
    { key:"16497", district:"25", Block:"345", label:"PUMS-AKKARAIVATTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16498", district:"25", Block:"345", label:"GHSS- ANANDAGOPALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16499", district:"25", Block:"345", label:"PUMS-SOLAGANKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16500", district:"25", Block:"345", label:"GHS-SIVAVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"16501", district:"25", Block:"345", label:"PUMS-KANDIYANATCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16502", district:"25", Block:"345", label:"PUMS-PILLUVETTUVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16503", district:"25", Block:"345", label:"GHSS-THIRUVONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16504", district:"25", Block:"345", label:"NAVINA HS-PILLUVETTUVIDUTHUI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16505", district:"25", Block:"345", label:"GHS-KAYAVOOR", Sch_type:"Government", Category:"High School"},
    { key:"16506", district:"25", Block:"345", label:"GHS-KILAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"16507", district:"25", Block:"345", label:"PUMS-KOLLUKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16508", district:"25", Block:"345", label:"PUMS-NEYVELI(NORTH)", Sch_type:"Government", Category:"Middle School"},
    { key:"16509", district:"25", Block:"345", label:"GHSS-NEYVELI(SOUTH)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16510", district:"25", Block:"345", label:"JOTHI AIDED MS-URANIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16511", district:"25", Block:"345", label:"GHSS-URANIPURAM(VETTUVAKOTTAI)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16512", district:"25", Block:"345", label:"GHS-KATTATHI UNJIYAVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"16513", district:"25", Block:"345", label:"GOVT MODEL  HR. SEC. SCHOOL -VENGARAI KOTTAIKKADU -614628", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16514", district:"25", Block:"345", label:"GHSS-PINNAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16515", district:"25", Block:"345", label:"RANI  MARIMUTHU MATRIC HS-PINNAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"16516", district:"25", Block:"345", label:"GHS-SANKARANATHAR KUDIKADU", Sch_type:"Government", Category:"High School"},
    { key:"16517", district:"25", Block:"345", label:"GHS-SENNIYAVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"16518", district:"25", Block:"345", label:"GHS-SILLATHUR(NORTH)", Sch_type:"Government", Category:"High School"},
    { key:"16519", district:"25", Block:"345", label:"GHS-THALIGAIVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"16520", district:"25", Block:"345", label:"PUMS-THIRUNALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16521", district:"25", Block:"345", label:"PUMS-PATHUPULIVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16522", district:"25", Block:"345", label:"PUMS-VENGARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16523", district:"25", Block:"345", label:"GHSS-VETTIKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16524", district:"25", Block:"345", label:"PUMS-VETTUVAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16525", district:"25", Block:"144", label:"Shri Balaji Matriculation School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16526", district:"25", Block:"144", label:"AIDED MS DEVANACHERY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16527", district:"25", Block:"144", label:"MINERVA HSS KADICHAMPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16528", district:"25", Block:"144", label:"PUMS KALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16529", district:"25", Block:"144", label:"MADHA HIGHER  SECONDARY SCHOOL FOR THE DEAF, SAKKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16530", district:"25", Block:"144", label:"GHSS NEERATHANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16531", district:"25", Block:"144", label:"DHANISHLOS SPL SCH MS-MP MANDAPAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16532", district:"25", Block:"144", label:"ANNA GHSS-PATTEESWARAM -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16533", district:"25", Block:"144", label:"GHSS-SUNDARAPERUMALKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16534", district:"25", Block:"144", label:"AIDED MS - MADAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16535", district:"25", Block:"144", label:"GHS - THIPPIRAJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16536", district:"25", Block:"144", label:"GHSS - THIRUPPURAMBIAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16537", district:"25", Block:"144", label:"GHS - UDAIYALUR", Sch_type:"Government", Category:"High School"},
    { key:"16538", district:"25", Block:"144", label:"ADW MS - CHOLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16539", district:"25", Block:"144", label:"GHSS - CHOLAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16540", district:"25", Block:"144", label:"VALLALAR AHSS - KUMBAKONAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16541", district:"25", Block:"144", label:"KAMARAJAR MPL HS - MELA CAUVERY", Sch_type:"Government", Category:"High School"},
    { key:"16542", district:"25", Block:"144", label:"NATIVE AHSS KUMBAKONAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16543", district:"25", Block:"144", label:"GHSS GIRLS KUMBAKONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16544", district:"25", Block:"144", label:"AAGHSS KUMBAKONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16545", district:"25", Block:"144", label:"K.T. VEEDHI AIDED HS KUMBAKONAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16546", district:"25", Block:"144", label:"BANADURAI AHSS KUMBAKONAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16547", district:"25", Block:"144", label:"ORIENTAL AHSS KUMBAKONAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16548", district:"25", Block:"144", label:"TOWN AHSS KUMBAKONAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16549", district:"25", Block:"144", label:"LITTLE FLOWER HR SEC SCHOOL  KUMBAKONAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16550", district:"25", Block:"144", label:"RC MORNING STAR AHS KUMBAKONAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16551", district:"25", Block:"144", label:"ARR MPL HSS KUMBAKONAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16552", district:"25", Block:"144", label:"RANI SUBBAIYYAR MPL MS KUMBAKONAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16553", district:"25", Block:"144", label:"ST JOSEPH AHSS KUMBAKONAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16554", district:"25", Block:"144", label:"AJC ENGLISH SCHOOL KUMBAKONAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"16555", district:"25", Block:"144", label:"GHSS DARASURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16556", district:"25", Block:"144", label:"JAYAM AIDED AMS-SHOLAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16557", district:"25", Block:"144", label:"GHS - SHOLAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16558", district:"25", Block:"144", label:"AIDED MS SWAMIMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16559", district:"25", Block:"144", label:"GHSS-SWAMIMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16560", district:"25", Block:"228", label:"GHS - ILUPPAKKORAI", Sch_type:"Government", Category:"High School"},
    { key:"16561", district:"25", Block:"228", label:"GOVINDASAMY MOOPANAR HIGH SCHOOL - KABISTHALAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16562", district:"25", Block:"228", label:"ST. GABRIELS GIRLS HIGHER SECONDARY SCHOOL PASUPATHI KOVIL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16563", district:"25", Block:"228", label:"SRIVIDYASRAM MATRICULATION SCHOOL, VADAKURANGADUTHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16564", district:"25", Block:"228", label:"GHS VANNIYADI", Sch_type:"Government", Category:"High School"},
    { key:"16565", district:"25", Block:"228", label:"KASSIMIA HIGHER SECONDARY SCHOOL, RAJAGIRI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16566", district:"25", Block:"228", label:"HIDAYATHUN NISWAN GENERAL ARABIC GIRLS HIGHER SECONDARY SCHOOL-RAJAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16567", district:"25", Block:"228", label:"GHS- RAMANUJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16568", district:"25", Block:"228", label:"PUMS CHAKKARAPALLI (SOUTH)", Sch_type:"Government", Category:"Middle School"},
    { key:"16569", district:"25", Block:"228", label:"GHS - CHAKKARAPPALLI", Sch_type:"Government", Category:"High School"},
    { key:"16570", district:"25", Block:"228", label:"GHS - VALKAI", Sch_type:"Government", Category:"High School"},
    { key:"16571", district:"25", Block:"228", label:"PUMS - SOOLAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16572", district:"25", Block:"228", label:"GHSS (GIRLS) - PAPANASAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16573", district:"25", Block:"228", label:"ADW MS - KELA MANCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"16574", district:"25", Block:"228", label:"GHSS - PULLAPUTHANKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16575", district:"25", Block:"228", label:"GHS - PATTAVARTHI", Sch_type:"Government", Category:"High School"},
    { key:"16576", district:"25", Block:"228", label:"THE HIGH SCHOOL - UMAYALPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16577", district:"25", Block:"228", label:"PUMS UMAPALAPADI KARUPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16578", district:"25", Block:"228", label:"SOWKATHUL ISLAM BMS HSS- VALUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16579", district:"25", Block:"228", label:"GHSS - VEERAMANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16580", district:"25", Block:"228", label:"PUMS - AYYAMPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16581", district:"25", Block:"228", label:"GHSS - AYYAMPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16582", district:"25", Block:"228", label:"GHSS - PAPANASAM (BOYS)", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16583", district:"25", Block:"11", label:"GHS - ANNAPPANPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"16584", district:"25", Block:"11", label:"GHS - ARUNTHAVAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16585", district:"25", Block:"11", label:"GHS - UTHAMARGUDI", Sch_type:"Government", Category:"High School"},
    { key:"16586", district:"25", Block:"11", label:"AIDED MS - KALANCHERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16587", district:"25", Block:"11", label:"GHS - KAMBARNATHAM", Sch_type:"Government", Category:"High School"},
    { key:"16588", district:"25", Block:"11", label:"EDT. HSS N.V. KUDIKKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16589", district:"25", Block:"11", label:"GHSS -  SALIYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16590", district:"25", Block:"11", label:"GHSS - POONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16591", district:"25", Block:"11", label:"GHSS - THIRUKARUKKAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16592", district:"25", Block:"11", label:"RC AIDED HSS(G) - AMMAPETTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16593", district:"25", Block:"11", label:"U.A.A.T HSS - AMMAPETTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16594", district:"25", Block:"11", label:"GHS AMMAPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"16595", district:"25", Block:"11", label:"GHSS - MELATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16596", district:"25", Block:"344", label:"PUMS - ARIYACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"16597", district:"25", Block:"344", label:"ADWHS- NALLADAI", Sch_type:"Government", Category:"High School"},
    { key:"16598", district:"25", Block:"344", label:"GHS - VANNAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16599", district:"25", Block:"344", label:"ADWHS - THIRUMALAIRAJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16600", district:"25", Block:"344", label:"PUMS - MANJAMALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"16601", district:"25", Block:"344", label:"JAYAMKONDA MATHA AIDED MIDDLE SCHOOL-MATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16602", district:"25", Block:"344", label:"GBHSS   - NACHIYARKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16603", district:"25", Block:"344", label:"S.PUDUR  HIGH SCHOOL  - S.PUDUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16604", district:"25", Block:"344", label:"PUMS - MURUGANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16605", district:"25", Block:"344", label:"RKR GHSS - THIRUCHERAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16606", district:"25", Block:"344", label:"MATHAR MANRAM HS-THIRUMANGALAKUDI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16607", district:"25", Block:"344", label:"GGHSS - NACHIYARKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16608", district:"25", Block:"344", label:"GHS-THUKKATCHI", Sch_type:"Government", Category:"High School"},
    { key:"16609", district:"25", Block:"344", label:"GHSS- THIRUNAGESWARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16610", district:"25", Block:"344", label:"AIDED  MIDDLE SCHOOL - ADUTHURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16611", district:"25", Block:"344", label:"GHSS - ADUTHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16612", district:"25", Block:"344", label:"SRI KGS  HIGHER SECONDARY SCHOOL- ADUTHURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16613", district:"25", Block:"344", label:"RICE CITY MATRIC. HIGHER SECONDARY SCHOOL - ADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16614", district:"25", Block:"344", label:"THE CRESCENT HIGHER SECONDARY SCHOOL - AVANIYAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16615", district:"25", Block:"344", label:"GHSS - THIRUBUVANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16616", district:"25", Block:"344", label:"THIRUVAVADUTHURAI ADHEENAM AMBALAVANA DESIGAR AIDED MIDDLE SCHOOL -THIRUVIDAIMARUDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16617", district:"25", Block:"344", label:"THIRUVAVADUTHURAI ADHEENAM HIGHER SECONDARY SCHOOL - THIRVIDAIMARUDUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16618", district:"25", Block:"344", label:"ST  JOSEPHS  HIGH SCHOOL - AMMANPETTAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16619", district:"25", Block:"330", label:"GHS-ARALUR", Sch_type:"Government", Category:"High School"},
    { key:"16620", district:"25", Block:"330", label:"RAMAKRISHNA MIDDDLE SCHOOL - KEELAKATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16621", district:"25", Block:"330", label:"AMS  THATHUVANCHERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16622", district:"25", Block:"330", label:"GHS-KONULAMPALLAM", Sch_type:"Government", Category:"High School"},
    { key:"16623", district:"25", Block:"330", label:"GHS-KURICHI", Sch_type:"Government", Category:"High School"},
    { key:"16624", district:"25", Block:"330", label:"SARASWATHI MS-MULLANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16625", district:"25", Block:"330", label:"PUMS-PANDHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16626", district:"25", Block:"330", label:"GHSS (GIRLS) - PANDHANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16627", district:"25", Block:"330", label:"GHSS(B) -PANDHANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16628", district:"25", Block:"330", label:"SRI VENKATESWARA MATRIC  SCHOOL-PANDHANALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"16629", district:"25", Block:"330", label:"PUMS-VETTAMANGALAM ", Sch_type:"Government", Category:"Middle School"},
    { key:"16630", district:"25", Block:"330", label:"GHS-CHITRIDAIYANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"16631", district:"25", Block:"330", label:"KGS AIDED MS-THIRULOGI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"16632", district:"25", Block:"330", label:"GHS-KODIYALAM", Sch_type:"Government", Category:"High School"},
    { key:"16633", district:"25", Block:"330", label:"PUMS - MOZHAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16634", district:"25", Block:"330", label:"KG  AIDED MS - THUGILI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16635", district:"25", Block:"330", label:"GHS - THIRUVAIPADI", Sch_type:"Government", Category:"High School"},
    { key:"16636", district:"25", Block:"330", label:"GHSS ANAIKKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16637", district:"25", Block:"330", label:"SRI KAILAI SUBRAMAIYA DESIKA SWAMIGAL HIGHER SECONDARY SCHOOL-THIRUPPANANDAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16638", district:"25", Block:"234", label:"GHS-ALADIKKUMULAI", Sch_type:"Government", Category:"High School"},
    { key:"16639", district:"25", Block:"234", label:"GHS-ENATHI", Sch_type:"Government", Category:"High School"},
    { key:"16640", district:"25", Block:"234", label:"PUMS- ERIPURAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16641", district:"25", Block:"234", label:"PUMS- ETTUPPULIKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16642", district:"25", Block:"234", label:"GHSS- KARAMBAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16643", district:"25", Block:"234", label:"AMS-ST.MICHAEL - MUTHALCHERI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"16644", district:"25", Block:"234", label:"GHS-NAMBIVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"16645", district:"25", Block:"234", label:"GHSS-NATTUCHALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16646", district:"25", Block:"234", label:"M.G.M- HIGH SCHOOL- VALAVANPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"16647", district:"25", Block:"234", label:"PUMS-PALLIKONDAN", Sch_type:"Government", Category:"Middle School"},
    { key:"16648", district:"25", Block:"234", label:"LAUREL HIGHER SECONDARY SCHOOL - PALLIKONDAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16649", district:"25", Block:"234", label:"PUMS- PANNAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16650", district:"25", Block:"234", label:"PUMS- PARAKKALAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16651", district:"25", Block:"234", label:"PUMS- PONNAVARAYANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16652", district:"25", Block:"234", label:"GHSS-PUDUKOTTAI ULLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16653", district:"25", Block:"234", label:"PUMS- KEEZHATHOTTAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16654", district:"25", Block:"234", label:"GHSS- RAJAMADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16655", district:"25", Block:"234", label:"GHS- SENDANKADU", Sch_type:"Government", Category:"High School"},
    { key:"16656", district:"25", Block:"234", label:"ARUNODHAYA HSS-SENDANKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16657", district:"25", Block:"234", label:"GHS-SOORAPPALLAM", Sch_type:"Government", Category:"High School"},
    { key:"16658", district:"25", Block:"234", label:"MORDEN MATRIC HSS- SOORAPPALLAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"16659", district:"25", Block:"234", label:"PUMS -T.MARAVAKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16660", district:"25", Block:"234", label:"GHS- T.MELAKADU", Sch_type:"Government", Category:"High School"},
    { key:"16661", district:"25", Block:"234", label:"GHSS- THAMBIKKOTTAI VADAKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16662", district:"25", Block:"234", label:"PUMS- MANJAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16663", district:"25", Block:"234", label:"GHS-SENAGAPADUTHANKADU", Sch_type:"Government", Category:"High School"},
    { key:"16664", district:"25", Block:"234", label:"GHSS- THAMARANKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16665", district:"25", Block:"234", label:"PUMS -THOKKALIKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16666", district:"25", Block:"234", label:"GHSS-THUVARANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16667", district:"25", Block:"234", label:"ST JOSEPH'S HIGHER SECONDARY SCHOOL SANJAYA  NAGAR ", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16668", district:"25", Block:"234", label:"BRINDHAVAN HIGHER SECONDARY SCHOOL- SUKKIRANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16669", district:"25", Block:"234", label:"PUMS -VENDAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16670", district:"25", Block:"234", label:"ST.FRANCIS XAVIER'S HIGH SCHOOL- ANAIKKADU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16671", district:"25", Block:"234", label:"GHS -NADUVIKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"16672", district:"25", Block:"234", label:"GHSS- KAZHUKUPULIKKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16673", district:"25", Block:"234", label:"MPLMS-  KANDIYANTHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"16674", district:"25", Block:"234", label:"MPLMS- PERUMALKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"16675", district:"25", Block:"234", label:"MPLMS-PALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16676", district:"25", Block:"234", label:"ADWHSS-PATTUKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16677", district:"25", Block:"234", label:"GOVERNMENT MODEL HIGHER SEC SCHOOL - PATTUKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16678", district:"25", Block:"234", label:"G(G)HSS-PATTUKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16679", district:"25", Block:"234", label:"ST. ISABEL GIRLS HIGHER SECONDARY SCHOOL - PATTUKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16680", district:"25", Block:"234", label:"Rethinakumar Memorial Saradha Vidhyalaya Matriculation School, Sunnambukara Street, Pattukkottai ", Sch_type:"Un-aided", Category:"High School"},
    { key:"16681", district:"25", Block:"234", label:"Mount Carmel Matriculation Higher Secondary School- Adhirampattinam Road, Pattukkottai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16682", district:"25", Block:"234", label:"ST.THOMAS HIGHER SECONDARY SCHOOL  PATTUKKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16683", district:"25", Block:"234", label:"PUMS- ADHIRAMPATTINAM NADUTHERU", Sch_type:"Government", Category:"Middle School"},
    { key:"16684", district:"25", Block:"234", label:"PUMS- ADHIRAMPATTINAM WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"16685", district:"25", Block:"234", label:"G(G)HSS-ADIRAMPATTINAM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16686", district:"25", Block:"234", label:"KHADIR  MOHIDEEN HSS BOYS ADIRAMPATTINAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16687", district:"25", Block:"234", label:"KHADIR  MOHIDEEN HSS GIRLS-ADIRAMPATTINAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16688", district:"25", Block:"234", label:"A.L MARTRICULATION SCHOOL- ADIRAMPATTINAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16689", district:"25", Block:"157", label:"PUMS - ALAMPALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16690", district:"25", Block:"157", label:"GHS (GIRLS) - ALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"16691", district:"25", Block:"157", label:"GHSS - ALATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16692", district:"25", Block:"157", label:"GHS - ATHIVETTI", Sch_type:"Government", Category:"High School"},
    { key:"16693", district:"25", Block:"157", label:"GHS - KANYAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"16694", district:"25", Block:"157", label:"GHSS- KASANKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16695", district:"25", Block:"157", label:"GHS - KEELAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"16696", district:"25", Block:"157", label:"PUMS - MANNANKADU NORTH ", Sch_type:"Government", Category:"Middle School"},
    { key:"16697", district:"25", Block:"157", label:"GHS - MOOTHAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"16698", district:"25", Block:"157", label:"GHS - PERIYAKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"16699", district:"25", Block:"157", label:"GHSS - SIRAMELKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16700", district:"25", Block:"157", label:"SRI VENKATESWARA VIDHYA MANDHIR HR SEC SCHOOL, THALIKKOTTAI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16701", district:"25", Block:"157", label:"GHS - VATTAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16702", district:"25", Block:"157", label:"PUMS - VEPPANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16703", district:"25", Block:"157", label:"GANDHI MATRIC HSS-VIKRAMAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16704", district:"25", Block:"157", label:"WESTERN MATRIC HS - VIKRAMAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"16705", district:"25", Block:"157", label:"PUMS - MADUKKUR NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"16706", district:"25", Block:"157", label:"GHSS(GIRLS) -MADUKKUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16707", district:"25", Block:"157", label:"PUMS - MADUKKUR P.K. ST", Sch_type:"Government", Category:"Middle School"},
    { key:"16708", district:"25", Block:"157", label:"GHSS(BOYS) - MADUKKUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16709", district:"25", Block:"157", label:"FATHIMA MARIAM - HSS - MADUKUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16710", district:"25", Block:"239", label:"GHS - ENATHIKARAMBAI", Sch_type:"Government", Category:"High School"},
    { key:"16711", district:"25", Block:"239", label:"GHSS - EDAIYATHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16712", district:"25", Block:"239", label:"GHS - KALAGAM", Sch_type:"Government", Category:"High School"},
    { key:"16713", district:"25", Block:"239", label:"GHS - KONTRAIKADU", Sch_type:"Government", Category:"High School"},
    { key:"16714", district:"25", Block:"239", label:"GHS- KALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"16715", district:"25", Block:"239", label:"GHS - SITHUKADU", Sch_type:"Government", Category:"High School"},
    { key:"16716", district:"25", Block:"239", label:"ADWHS -  KALLURANIKKADU", Sch_type:"Government", Category:"High School"},
    { key:"16717", district:"25", Block:"239", label:"PUMS- KURUCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16718", district:"25", Block:"239", label:"PUMS -METTUVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16719", district:"25", Block:"239", label:"INDIAN BANK EMPLOYEES ASSOCIATION  HIGH SCHOOL - KURICHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16720", district:"25", Block:"239", label:"PUMS - PERIYATHERKUKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16721", district:"25", Block:"239", label:"GHS - PAINGAL", Sch_type:"Government", Category:"High School"},
    { key:"16722", district:"25", Block:"239", label:"PUMS - PALATHALI", Sch_type:"Government", Category:"Middle School"},
    { key:"16723", district:"25", Block:"239", label:"GHSS - AVANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16724", district:"25", Block:"239", label:"GHS - PINNAVASAL", Sch_type:"Government", Category:"High School"},
    { key:"16725", district:"25", Block:"239", label:"PUMS - POOVALUR WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"16726", district:"25", Block:"239", label:"ST.ANNE'S GIRLS HIGH SCHOOL - PUNALVASAL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16727", district:"25", Block:"239", label:"PUNITHA.AROKIYA.ANNAI AIDED HSS - PUNALVASAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16728", district:"25", Block:"239", label:"DON BOSCO MATRIC  HR SEC SCHOOL - PUNALVASAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16729", district:"25", Block:"239", label:"GHS - SENGAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"16730", district:"25", Block:"239", label:"GHS-SERUVAVIDUTHI NORTH", Sch_type:"Government", Category:"High School"},
    { key:"16731", district:"25", Block:"239", label:"PUMS -POKKANVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16732", district:"25", Block:"239", label:"GHSS -THIRUCHITRAMBALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16733", district:"25", Block:"239", label:"SRI VENKATESHWARA VIDHYALAYA CBSE SCHOOL - PERAVURANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16734", district:"25", Block:"239", label:"GHS - VATTADIKOTTAI NORTH", Sch_type:"Government", Category:"High School"},
    { key:"16735", district:"25", Block:"239", label:"GHSS GIRLS- PERAVURANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16736", district:"25", Block:"239", label:"GHSS BOYS - PERAVURANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16737", district:"25", Block:"239", label:"VR VEERAPPA MEMORIAL HR SEC SCHOOL - PERAVURANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16738", district:"25", Block:"239", label:"ST ANNES HIGH SCHOOL - ATHANUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16739", district:"25", Block:"287", label:"GHS -RENDAMPULIKADU", Sch_type:"Government", Category:"High School"},
    { key:"16740", district:"25", Block:"287", label:"PUMS- KALANIVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16741", district:"25", Block:"287", label:"PUMS -KALYANARAMANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16742", district:"25", Block:"287", label:"GHSS -KARISAVAYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16743", district:"25", Block:"287", label:"GHSS- KURUVIKKARAMBAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16744", district:"25", Block:"287", label:"GHSS- MANAKKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16745", district:"25", Block:"287", label:"PUMS -MARUNKAPPALLAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16746", district:"25", Block:"287", label:"GHS -MUTHUKADU", Sch_type:"Government", Category:"High School"},
    { key:"16747", district:"25", Block:"287", label:"GHS -NADIYAM", Sch_type:"Government", Category:"High School"},
    { key:"16748", district:"25", Block:"287", label:"ADWHS -NADUVIKURCHI", Sch_type:"Government", Category:"High School"},
    { key:"16749", district:"25", Block:"287", label:"GHSS -PALLATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16750", district:"25", Block:"287", label:"PUMS -MALLIPATTINAM SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"16751", district:"25", Block:"287", label:"PUMS -CHINNAMANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16752", district:"25", Block:"287", label:"GHSS -MALLIPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16753", district:"25", Block:"287", label:"GHS- SETHUBAVACHATRAM", Sch_type:"Government", Category:"High School"},
    { key:"16754", district:"25", Block:"287", label:"GHS -PUTHUTHERU", Sch_type:"Government", Category:"High School"},
    { key:"16755", district:"25", Block:"287", label:"GHS UDAYANADU", Sch_type:"Government", Category:"High School"},
    { key:"16756", district:"25", Block:"287", label:"PUMS -MUDACHIKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16757", district:"25", Block:"287", label:"GHS -VILANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"16758", district:"25", Block:"287", label:"GHSS -PERUMAGALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16759", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL KANNANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16760", district:"19", Block:"147", label:"GOVERNMENT HIGH SCHOOL MELAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16761", district:"19", Block:"147", label:"GOVERNMENT HIGH SCHOOL THEMMAVUR", Sch_type:"Government", Category:"High School"},
    { key:"16762", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL KOPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16763", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL RAKKATHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16764", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL UDAIYALIPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16765", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL VALIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16766", district:"19", Block:"147", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ODUGAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16767", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL MOSAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16768", district:"19", Block:"147", label:"GOVERNMENT HIGH SCHOOL UPPILIYAKKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16769", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL SENAIYAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16770", district:"19", Block:"147", label:"GOVERNMENT HIGH SCHOOL VAITHUR", Sch_type:"Government", Category:"High School"},
    { key:"16771", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL ARANIPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16772", district:"19", Block:"147", label:"R.C.RAWUSAPPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"16773", district:"19", Block:"147", label:"GOVERNMENT HIGH SCHOOL KULATHUR", Sch_type:"Government", Category:"High School"},
    { key:"16774", district:"19", Block:"147", label:"MUTHUSWAMY VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL KULATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16775", district:"19", Block:"147", label:"GOVERNMENT HIGH SCHOOL LEKKANAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16776", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL ILLUPPAKKUDIPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16777", district:"19", Block:"147", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ANDAKKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16778", district:"19", Block:"147", label:"VSA NURSERY AND PRIMARY SCHOOL ANDAKKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"16779", district:"19", Block:"147", label:"ST.ANTONY MIDDLE SCHOOL KILLUKKOTTAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"16780", district:"19", Block:"147", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KILLUKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16781", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL THIRUMALAIRAYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16782", district:"19", Block:"147", label:"ST.JOSEPH HIGH SCHOOL THIRUMALAIRAYAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"16783", district:"19", Block:"147", label:"PUNITHA SAHAYAMATHA MIDDLE SCHOOL KEERANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16784", district:"19", Block:"147", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KEERANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16785", district:"19", Block:"147", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KEERANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16786", district:"19", Block:"147", label:"PUNITHA AROCKIYA ANNAI HIGHER SECONDARY SCHOOL KEERANUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16787", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL KUNDRANDARKOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"16788", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL VATHANAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16789", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL ODUKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16790", district:"19", Block:"147", label:"GOVERNMENT HIGHER SECONDARY SCHOOL PULIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16791", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL PALLATHUPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16792", district:"19", Block:"147", label:"PANCHAYAT UNION MIDDLE SCHOOL PAPPUDAIYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16793", district:"19", Block:"18", label:"GOVT HIGH SCHOOL,THIRUNALLUR", Sch_type:"Government", Category:"High School"},
    { key:"16794", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL, MURUGAKONPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16795", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL, KATTAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16796", district:"19", Block:"18", label:"ST.IGNATIUS.HS -SATHAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"16797", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOTHIRAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16798", district:"19", Block:"18", label:"GHSS,MALAIKUDIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16799", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.ENNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16800", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.MELAPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16801", district:"19", Block:"18", label:"ADWHS.ENNAI", Sch_type:"Government", Category:"High School"},
    { key:"16802", district:"19", Block:"18", label:"MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL,ILLUPPUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16803", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL VETTUKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"16804", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.ERUKKUMANIPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16805", district:"19", Block:"18", label:"GOVT HR SEC SCHOOL, RAPPUSAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16806", district:"19", Block:"18", label:"GOVT HIGHER SECONDARY SCHOOL.KEELAKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16807", district:"19", Block:"18", label:"GOVT HIGHER SECONDARY  SCHOOL,NARTHAMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16808", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.KOTHAMANGALAPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16809", district:"19", Block:"18", label:"GOVT HIGHER SECONDARY SCHOOL.CAUVERYNAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16810", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.POONGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16811", district:"19", Block:"18", label:"GOVT HIGH SCHOOL VADASERIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16812", district:"19", Block:"18", label:"GOVT HIGHER SECONDARY  SCHOOL.SATHIYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16813", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.KALADIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16814", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.VEERAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16815", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.EDAYAPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16816", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16817", district:"19", Block:"18", label:"GOVT HIGH SCHOOL.KILIKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16818", district:"19", Block:"18", label:"GOVT HIGHER SECONDARY SCHOOL.PARAMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16819", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.P.PANAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"16820", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.KULAVAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16821", district:"19", Block:"18", label:"GOVT HR SEC SCHOOL. MANNAVELAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16822", district:"19", Block:"18", label:"GOVT HIGH SCHOOL.MATHIYANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"16823", district:"19", Block:"18", label:"GHSS.MARUTHANTHALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16824", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL .T.METTUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16825", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.PERUNJUNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16826", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.AGARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16827", district:"19", Block:"18", label:"GOVT HIGHER SECONDARY SCHOOL.VAYALOGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16828", district:"19", Block:"18", label:"ST.ANNE'S HS MUTHALIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"16829", district:"19", Block:"18", label:"GOVERNMENT HIGH SCHOOL.KUDUMIYANMALAI", Sch_type:"Government", Category:"High School"},
    { key:"16830", district:"19", Block:"18", label:"RC.HIGHER SECONDARY SCHOOL,ILLUPPUR.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16831", district:"19", Block:"18", label:"GOVT.HR.SEC.SCHOOL,ILLUPPUR.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16832", district:"19", Block:"18", label:"ARULMALAR MATRIC SCHOOL,ILLUPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"16833", district:"19", Block:"18", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL,ILLUPPUR.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16834", district:"19", Block:"18", label:"MOTHER TERASA MATRIC.METTUSALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16835", district:"19", Block:"18", label:"VAIRAMS PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16836", district:"19", Block:"18", label:" PANCHAYAT UNION MIDDLE SCHOOL.ESWARANKOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"16837", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL CHITHANNAVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16838", district:"19", Block:"18", label:"GOVT HIGHER SECONDARY SCHOOL.ANNAVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16839", district:"19", Block:"18", label:"KOKILA MATRICULATION SCHOOL, ANNAVASAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"16840", district:"19", Block:"18", label:"PANCHAYAT UNION MIDDLE SCHOOL.KATHAVAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"16841", district:"19", Block:"18", label:"GOVT HIGH SCHOOL.MELUR", Sch_type:"Government", Category:"High School"},
    { key:"16842", district:"19", Block:"18", label:"GOVT HSS SCHOOL.MUKKANAMALAIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16843", district:"19", Block:"18", label:"MAJESTIC MATRIC MUKKANAMALAIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16844", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL, LANCHAMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"16845", district:"19", Block:"405", label:"GOVERNMENT HIGH SCHOOL, KALKUDI", Sch_type:"Government", Category:"High School"},
    { key:"16846", district:"19", Block:"405", label:"GOVERNMENT HIGHER SECONDARY SCHOOL KODUMBALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16847", district:"19", Block:"405", label:"PANCHAYT UNION MIDDLE SCHOOL PILIPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"16848", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL. KUNNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16849", district:"19", Block:"405", label:"ST.THOMAS HIGHER SECONDARY SCHOOL  NAZARETH", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16850", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL,LAKSHMANANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16851", district:"19", Block:"405", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MANDAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16852", district:"19", Block:"405", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16853", district:"19", Block:"405", label:"ST.NORBERT R.C. MIDDLE SCHOOL. MATHUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"16854", district:"19", Block:"405", label:"GOVERNMENT HIGHER SECONDARY SCHOOL(SPL) MATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16855", district:"19", Block:"405", label:"SACRED HEART SCHOOL CBSE  MATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"16856", district:"19", Block:"405", label:"GOVERNMENT HIGH SCHOOL MEENAVELI", Sch_type:"Government", Category:"High School"},
    { key:"16857", district:"19", Block:"405", label:"PREMANANDA HIGH SCHOOL MELAPATCHKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16858", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL NAMBAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16859", district:"19", Block:"405", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, KAVARAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16860", district:"19", Block:"405", label:"GOVERNMENT HIGH SCHOOL NEERPALANI", Sch_type:"Government", Category:"High School"},
    { key:"16861", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL. SEETHAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16862", district:"19", Block:"405", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SEVALPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16863", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL RAJAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"16864", district:"19", Block:"405", label:"GOVERNMENT HIGH SCHOOL, RAJALIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16865", district:"19", Block:"405", label:"GOVERNMENT HIGHER SECONDARY SCHOOL ,SOORIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16866", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL. THENGAITHINNIPPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"16867", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL THENNAMPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"16868", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL THERAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"16869", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL VELLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16870", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL NARIAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16871", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL VILLAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16872", district:"19", Block:"405", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, VIRALIMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16873", district:"19", Block:"405", label:"GOVERNMENT HIGHER SECONDARY SCHOOL(B) VIRALIMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16874", district:"19", Block:"405", label:"ST TERESA'S GIRLS HIGH SCHOOL VIRALIMALAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16875", district:"19", Block:"405", label:"PROVIDENCE CONVENT MATRICULATION SCHOOL, VIRALIMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"16876", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL. RAMAKKAVUNDANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16877", district:"19", Block:"405", label:"PANCHAYT UNION MIDDLE SCHOOL SERALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16878", district:"19", Block:"405", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, AVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16879", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL NADUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16880", district:"19", Block:"405", label:"PANCHAYAT UNION MIDDLE SCHOOL, THONDAIMANNALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16881", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, SOLAGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16882", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, VIRALIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16883", district:"19", Block:"67", label:"ST MARY'S HIGHER SECONDARY  SCHOOL, THACHANKURICHI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16884", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, KURUMPOONDI", Sch_type:"Government", Category:"Middle School"},
    { key:"16885", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, MANJAPPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16886", district:"19", Block:"67", label:"GOVERNMENT HIGH SCHOOL, KOMAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"16887", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARIYANIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16888", district:"19", Block:"67", label:"GOVERNMENT HIGH SCHOOL, PUDUNAGAR", Sch_type:"Government", Category:"High School"},
    { key:"16889", district:"19", Block:"67", label:"PUMS-PALAYAGANDARVAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16890", district:"19", Block:"67", label:"PANCHAYAT UNION MID SCHOOL, MEIKUDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16891", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, VEERADIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16892", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUDUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16893", district:"19", Block:"67", label:"GOVERNMENT HIGH SCHOOL, KULATHURNAYAKKARPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16894", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16895", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, MOHANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"16896", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOTHAGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16897", district:"19", Block:"67", label:"PUMS. VEMBANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16898", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, KATTUNAVAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16899", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, SUNDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16900", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, SANGAMVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16901", district:"19", Block:"67", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,KALLAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16902", district:"19", Block:"67", label:"GOVERNMENT HIGH SCHOOL,VELLALAVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"16903", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, ANDANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"16904", district:"19", Block:"67", label:"GOVERNMENT HIGH SCHOOL, VELADIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16905", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, KENDAYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16906", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, MANGANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"16907", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, MATTANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"16908", district:"19", Block:"67", label:"GOVERNMENT GIRLS HR SEC SCHOOL, GANDARVAKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16909", district:"19", Block:"67", label:"GOVERNMENT BOYS HR SEC SCHOOL, GANDARVAKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16910", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, GANDARVAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16911", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, MULLIKAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16912", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, AKKACHIPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16913", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, PAGATTUVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16914", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,KARUPPUDAIYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16915", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL KALLUKKARANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16916", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,SIRUNANGUPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16917", district:"19", Block:"255", label:"LITTLE.FLOWER UPPER PRIMARY,T.M.R.SAMUTHIRAM.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16918", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,KUMMUPPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16919", district:"19", Block:"255", label:"GOVERNMENT MODEL HR SECONDARY SCHOOL,PUDUKKOTTAI.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16920", district:"19", Block:"255", label:"GOVT HIGH SCHOOL,MULLUR.", Sch_type:"Government", Category:"High School"},
    { key:"16921", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL MOOKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16922", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL M.KULAVAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16923", district:"19", Block:"255", label:"GOVT HIGHER SECONDARY SCHOOL,A.MATHUR.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16924", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,KAMMANGADU.", Sch_type:"Government", Category:"Middle School"},
    { key:"16925", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,MELAPPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16926", district:"19", Block:"255", label:"GOVERNMENT HIGH SCHOOL,MANAVIDUTHI.", Sch_type:"Government", Category:"High School"},
    { key:"16927", district:"19", Block:"255", label:"GOVT HIGH SCHOOL,BALAN NAGAR.", Sch_type:"Government", Category:"High School"},
    { key:"16928", district:"19", Block:"255", label:"GOVERNMENT HIGH SCHOOL,ELLAIPPATTI.", Sch_type:"Government", Category:"High School"},
    { key:"16929", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL PERUNGONDANVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16930", district:"19", Block:"255", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL PERUNGALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16931", district:"19", Block:"255", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,PUTHAMBUR.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16932", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL MELAVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"16933", district:"19", Block:"255", label:"GOVERNMENT HIGH SCHOOL,SAMMATTIVIDUTHI.", Sch_type:"Government", Category:"High School"},
    { key:"16934", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,RAJAKULATHUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"16935", district:"19", Block:"255", label:"GOVERNMENT HIGH SCHOOL,SEMBATTUR.", Sch_type:"Government", Category:"High School"},
    { key:"16936", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL VANNARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16937", district:"19", Block:"255", label:"GOVERNMENT HIGH SCHOOL,VARAPPUR.", Sch_type:"Government", Category:"High School"},
    { key:"16938", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,SOUTHCHETTIYAPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16939", district:"19", Block:"255", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VADAVALAM.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16940", district:"19", Block:"255", label:"GOVERNMENT HIGH SCHOOL, KAYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"16941", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,RAJAPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16942", district:"19", Block:"255", label:"GOVERNMENT HIGH SCHOOL, VAGAVASAL.", Sch_type:"Government", Category:"High School"},
    { key:"16943", district:"19", Block:"255", label:"MAI SKOOL,VAGAVASAL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16944", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL VALAVAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16945", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,ASHOKNAGAR.", Sch_type:"Government", Category:"Middle School"},
    { key:"16946", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,THAILANAGAR.", Sch_type:"Government", Category:"Middle School"},
    { key:"16947", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL ADANAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16948", district:"19", Block:"255", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ADANAKKOTTAI.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16949", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,SOTHUPALAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16950", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL GANAPATHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16951", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,SOUTH THONDAIMANOORANI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16952", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,KUPPAYANPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16953", district:"19", Block:"255", label:"PANCHAYAT UNION MIDDLE SCHOOL,AGARAPPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16954", district:"19", Block:"255", label:"MUNICIPAL MIDDLE SCHOOL, MACHUVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"16955", district:"19", Block:"255", label:"GOVT GIRLS HR.SEC SCHOOL SANDAIPETTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16956", district:"19", Block:"255", label:"GOVT UPPER PRIMARY SCHOOL,PUDUKKOTTAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16957", district:"19", Block:"255", label:"TELC HIGHER SECONDARY SCHOOL, PUDUKKOTTAI,", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"16958", district:"19", Block:"255", label:"GOVERNMENT MIDDLE SCHOOL FOR HEARING IMPAIRED,PUDUKKOTTAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16959", district:"19", Block:"255", label:"TELC MIDDLE SCHOOL, PUDUKKOTTAI,", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16960", district:"19", Block:"255", label:"MUNICIPAL MIDDLE SCHOOL,KOILPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16961", district:"19", Block:"255", label:"GOVT GIRLS HIGH SCHOOL, THIRUKKOKARANAM.", Sch_type:"Government", Category:"High School"},
    { key:"16962", district:"19", Block:"255", label:"GOVT HIGHER SECONDARY SCHOOL, THIRUKKOKARANAM.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16963", district:"19", Block:"255", label:"MUNICIPAL.MID.S.SANDAPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16964", district:"19", Block:"255", label:"MUNICIPAL HIGH SCHOOL,THIRUVAPPUR.", Sch_type:"Government", Category:"High School"},
    { key:"16965", district:"19", Block:"255", label:"MUNICIPAL HIGH SCHOOL, RAJAGOPALAPURAM.", Sch_type:"Government", Category:"High School"},
    { key:"16966", district:"19", Block:"255", label:"HASANATHULJARIA MIDDLE SCHOOL PUDUKKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16967", district:"19", Block:"255", label:"SAMSKRITA.VID.ORI.HIGH SCHOOL,PUDUKKOTTAI.", Sch_type:"Partially Aided", Category:"High School"},
    { key:"16968", district:"19", Block:"255", label:"MUNICIPAL HIGH SCHOOL, KAMARAJAPURAM.", Sch_type:"Government", Category:"High School"},
    { key:"16969", district:"19", Block:"255", label:"MUNICIPAL MIDDLE SCHOOL,BOSENAGAR.", Sch_type:"Government", Category:"Middle School"},
    { key:"16970", district:"19", Block:"255", label:"SRI.SUBBARAMAIYER MIDDLE SCHOOL,PUDUKKOTTAI.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16971", district:"19", Block:"255", label:"RANEES' GOVT GIRLS HR.SEC SCHOOL,PUDUKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16972", district:"19", Block:"255", label:"RANEES GOVT GIRLS HIGH SCHOOL, PUDUKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"16973", district:"19", Block:"255", label:"GOVT.MIDDLE SCHOOL FOR THE VISUALLY IMPAIRED,PUDUKKOTTAI.", Sch_type:"Government", Category:"Middle School"},
    { key:"16974", district:"19", Block:"255", label:"RC MIDDLE SCHOOL, PUDUKKOTTAI.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16975", district:"19", Block:"255", label:"SRI KULAPATHI BALAIAH HR SEC SCHOOL,PUDUKKOTTAI.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16976", district:"19", Block:"255", label:"ST.MARY'S HIGHER SEC SCHOOL, PUDUKKOTTAI.", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16977", district:"19", Block:"255", label:"GOVT HIGH SCHOOL, GANDHINAGAR", Sch_type:"Government", Category:"High School"},
    { key:"16978", district:"19", Block:"255", label:"SACRED HEART GIRLS HR.SEC SCHOOL, PUDUKKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"16979", district:"19", Block:"255", label:"NIZAM ORIENTAL  ARABIC HIGH SCHOOL,PUDUKKOTTAI.", Sch_type:"Fully Aided", Category:"High School"},
    { key:"16980", district:"19", Block:"255", label:"SRI RAJARAJESWARI MATRIC  SCHOOL,PUDUKKOTTAI.", Sch_type:"Un-aided", Category:"High School"},
    { key:"16981", district:"19", Block:"255", label:"SRI BRAGATHANBAL GOVT HR SEC SCHOOL, PUDUKKOTTAI.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16982", district:"19", Block:"255", label:"MUNICIPAL MIDDLE SCHOOL,SANTHANATHAPURAM.", Sch_type:"Government", Category:"Middle School"},
    { key:"16983", district:"19", Block:"255", label:"MUNICIPAL MIDDLE SCHOOL, PILLAITHANEERPANTHAL.", Sch_type:"Government", Category:"Middle School"},
    { key:"16984", district:"19", Block:"255", label:"VAIRAMS MATRIC HIGHER SEC SCHOOL, PUDUKKOTTAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16985", district:"19", Block:"255", label:"NEHRU MATRIC SCHOOL,PUDUKKOTTAI.", Sch_type:"Un-aided", Category:"High School"},
    { key:"16986", district:"19", Block:"255", label:"MUNICIPAL MIDDLE SCHOOL,MALAIYEEDU.", Sch_type:"Government", Category:"Middle School"},
    { key:"16987", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL PATCHIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"16988", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL PAPPANVIDUTHY", Sch_type:"Government", Category:"Middle School"},
    { key:"16989", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL,K.V.KOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"16990", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL,KUMMANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"16991", district:"19", Block:"339", label:"ST.ANTONYS MIDDLE SCHOOL,ARASADIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"16992", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL,SIKKAPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16993", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL MELATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16994", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL KEELATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"16995", district:"19", Block:"339", label:"ST.IGNATIOUS MATRIC HR.SEC.SCHOOL,VELLAKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16996", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL,VADAKKUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"16997", district:"19", Block:"339", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,VADAKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"16998", district:"19", Block:"339", label:"THANGAM MATRICULATION HIGHER SECONDARY  SCHOOL,VADAKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"16999", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL,PULLANVIDUTHY", Sch_type:"Government", Category:"High School"},
    { key:"17000", district:"19", Block:"339", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,THIRUVARANKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17001", district:"19", Block:"339", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,NEDUVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17002", district:"19", Block:"339", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,L.N.PURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17003", district:"19", Block:"339", label:"GOVT BOYS HIGHER SECONDARY SCHOOL,KEERAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17004", district:"19", Block:"339", label:"GOVT GIRLS HIGHER SECONDARY SCHOOL,KEERAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17005", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL KASIMPUTHUPETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17006", district:"19", Block:"339", label:"GOVT.HIGH SCHOOL,KULAMANGALAM-NORTH", Sch_type:"Government", Category:"High School"},
    { key:"17007", district:"19", Block:"339", label:"GOVT.HIGH SCHOOL,KULAMANGALAM-SOUTH", Sch_type:"Government", Category:"High School"},
    { key:"17008", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL,SENTHANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"17009", district:"19", Block:"339", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,MANGADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17010", district:"19", Block:"339", label:"PUMS, KOTHAMANGALAM PALAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17011", district:"19", Block:"339", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL,KOTHAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17012", district:"19", Block:"339", label:"GOVT.GIRLS HR.SEC.SCHOOL KOTHAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17013", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL,SOORANVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"17014", district:"19", Block:"339", label:"SRI SUBA BHARATHI MATRIC SCHOOL,KALLALANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17015", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL,PALLATHIVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"17016", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL PATHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17017", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL ,KALYANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17018", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL,KOTHAKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17019", district:"19", Block:"339", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,MANJANVIDUTHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17020", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL,MANGANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17021", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL,VEPPANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17022", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL IMMANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17023", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL THIRUKKATTALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17024", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL,METTUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17025", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL,KAIKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17026", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL POOVARASAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17027", district:"19", Block:"339", label:"RTA.GOVT.HIGHER SECONDARY SCHOOL,VALLATHIRAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17028", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL KATHAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17029", district:"19", Block:"339", label:"ST.JOSEPH HR.SEC.SCHOOL,VENKADAKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17030", district:"19", Block:"339", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,VENNAVALKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17031", district:"19", Block:"339", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,ARAYAPPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17032", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL K.RASIAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"17033", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL SERIYALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17034", district:"19", Block:"339", label:"P.P.M HIGH SCHOOL,KARAMBAKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"17035", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL PANANKULAM-NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"17036", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL MELAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17037", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL S.KULAVAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17038", district:"19", Block:"339", label:"GOVERNMENT HIGH SCHOOL KEELAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"17039", district:"19", Block:"339", label:"PUNITHA ARPUTHA MATHA MIDDLE SCHOOL,ALANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17040", district:"19", Block:"339", label:"PANCHAYAT UNION MIDDLE SCHOOL ALANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17041", district:"19", Block:"339", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL,ALANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17042", district:"19", Block:"339", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL,ALANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17043", district:"19", Block:"339", label:"HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL,ALANGUDI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17044", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, THATTAMANAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17045", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUTHALIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17046", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, INNANVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17047", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, PATHUTHAKKU", Sch_type:"Government", Category:"Middle School"},
    { key:"17048", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, PAPPAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17049", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKOTTAIVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17050", district:"19", Block:"108", label:"GOVT HIGH SCHOOL, KILANGADU", Sch_type:"Government", Category:"High School"},
    { key:"17051", district:"19", Block:"108", label:"GOVT.HR.SEC.SCHOOL, REGUNATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17052", district:"19", Block:"108", label:"GOVT.HR.SEC.SCHOOL, KALIYARAYANVIDUTHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17053", district:"19", Block:"108", label:"JEVANJOTHI.HR.SEC.SCHOOL, KONAKOLLAIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17054", district:"19", Block:"108", label:"GOVT.HIGH SCHOOL, BANTHUVAKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17055", district:"19", Block:"108", label:"PANCHAYAYT UNION MIDDLE SCHOOL MYLANKONEPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17056", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL KARAMBAVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17057", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, SEVVAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17058", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, PILAVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17059", district:"19", Block:"108", label:"ADI DRAVIDAR WELFARE HIGH SCHOOL, PILAVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"17060", district:"19", Block:"108", label:"PUMS.RANGIYANVIDUTHI-NEW", Sch_type:"Government", Category:"Middle School"},
    { key:"17061", district:"19", Block:"108", label:"GOVT.BOYS HR.SEC.SCHOOL, KARAMBAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17062", district:"19", Block:"108", label:"GOVT.GIRLS.HR.SEC.SCHOOL,KARAMBAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17063", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOLAPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17064", district:"19", Block:"108", label:"GOVT.HIGH SCHOOL, PALLAVARAYANPATHAI", Sch_type:"Government", Category:"High School"},
    { key:"17065", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL, ELAIKADIVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17066", district:"19", Block:"108", label:"GHS.THIRUMANANCHERI", Sch_type:"Government", Category:"High School"},
    { key:"17067", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL KARUKKAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17068", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL KURUMBIVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17069", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL VANAKKANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17070", district:"19", Block:"108", label:"GOVT ADW HR SEC SCHOOL MULLANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17071", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL KADUKKAKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17072", district:"19", Block:"108", label:"PUMS.KANNAKKANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17073", district:"19", Block:"108", label:"GHSS.VETTANVIDUTHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17074", district:"19", Block:"108", label:"PUMS.KRISHNAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17075", district:"19", Block:"108", label:"PUMS.MEENAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17076", district:"19", Block:"108", label:"GHS.MOLUDAIYANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17077", district:"19", Block:"108", label:"GOVT.HR.SEC.SCHOOL, MALAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17078", district:"19", Block:"108", label:"CHRIST THE KING MATRICULATION SCHOOL,SELVANATHAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17079", district:"19", Block:"108", label:"PUMS, NAINANKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17080", district:"19", Block:"108", label:"GOVT.HIGH SCHOOL, PONNANVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"17081", district:"19", Block:"108", label:"PUMS, NAMBANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17082", district:"19", Block:"108", label:"GOVT.HIGH SCHOOL, KEELAPATTI (M)", Sch_type:"Government", Category:"High School"},
    { key:"17083", district:"19", Block:"108", label:"PUMS, ODAPPAVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17084", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL KATTATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17085", district:"19", Block:"108", label:"GOVT.HIGH SCHOOL,AMBUKKOVIL", Sch_type:"Government", Category:"High School"},
    { key:"17086", district:"19", Block:"108", label:"PANCHAYAT UNION MIDDLE SCHOOL VELLALAVIDUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17087", district:"19", Block:"108", label:"PUMS, THEETHANIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17088", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL,PILLAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17089", district:"19", Block:"326", label:"G.G.H.S.S.P.ALAGAPURI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17090", district:"19", Block:"326", label:"PICHAIYAPPA MIDDLE SCHOOL UNAIYUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17091", district:"19", Block:"326", label:"SRI SIVAKAMI AMBAL HIGH SCHOOL, RANGIEM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17092", district:"19", Block:"326", label:"SRI.PR.M.MEYYAPPA CHETTIYAR MIDDLE SCHOOL ,VIRACHILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17093", district:"19", Block:"326", label:"VALLUVAR MIDDLE SCHOOL, KULIPIRAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17094", district:"19", Block:"326", label:"M.CT.RM HIGHER SEC.SCHOOL, KULIPIRAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17095", district:"19", Block:"326", label:"CHRISTURAJA HIGH SCHOOL, IRUTHAYAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17096", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, MALAIKUDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17097", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, VALAIYANVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17098", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL KULATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17099", district:"19", Block:"326", label:"GOVTHIGH SCHOOL,THULAIYANUR.", Sch_type:"Government", Category:"High School"},
    { key:"17100", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17101", district:"19", Block:"326", label:"GHSS LEMBALAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17102", district:"19", Block:"326", label:"SRI SARASWATHI HIGHER SECONDARY SCHOOL, KONAPET", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17103", district:"19", Block:"326", label:"GOVT.GIRLS.HR.SEC.SCHOOL, THIRUMAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17104", district:"19", Block:"326", label:"GOVT.HR.SEC.SCHOOL, THIRUMAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17105", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL S.NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17106", district:"19", Block:"326", label:"R.C. MIDDLE SCHOOL, NEIVASAL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"17107", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL ATHANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17108", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, VENGALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17109", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL THERKUPUDUVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17110", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17111", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOTTAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17112", district:"19", Block:"326", label:"RAMANATHAN CHETTIAR HR.SEC.SCHOOL, NACHANDUPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17113", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL KATTUBAVA PALLIVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17114", district:"19", Block:"326", label:"SM.MAR.ALAMELARUNACHALAM HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17115", district:"19", Block:"326", label:"SCM, MIDDLE SCHOOL, KURUVIKONDANAPTTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17116", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL,SETHURAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17117", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, V.LAKSHMIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17118", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, PILAKKUDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17119", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, OLIYAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17120", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, MARAVAMADURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17121", district:"19", Block:"251", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SADAYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17122", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SHOOL KONNAIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17123", district:"19", Block:"251", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, NAGARAPPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17124", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, ALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17125", district:"19", Block:"251", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ALAVAYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17126", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, KANDIYANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17127", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, PON.PUDUPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17128", district:"19", Block:"251", label:"V.V.HR SEC SCHOOL.PONNAMARAVAT", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17129", district:"19", Block:"251", label:"GOVT G.H.S.PON.PUDUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17130", district:"19", Block:"251", label:"GOVERNMENT HIGH SCHOOL, THIRUKKALAMPUR", Sch_type:"Government", Category:"High School"},
    { key:"17131", district:"19", Block:"251", label:"GOVERNMENT HIGH SCHOOL, KARUPPUKUDIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17132", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, PICHAKALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17133", district:"19", Block:"251", label:"GOVERNMENT HIGH SCHOOL, VARPATTU", Sch_type:"Government", Category:"High School"},
    { key:"17134", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, VALAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17135", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, EDAIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17136", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARASAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17137", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, NERINJIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17138", district:"19", Block:"251", label:"M.A.K GOVERNMENT HIGHER SECONDARY SCHOOL, KARAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17139", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL MULLIPPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17140", district:"19", Block:"251", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MELATHANIYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17141", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, R.PALAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17142", district:"19", Block:"251", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17143", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL SEMBOOTHY", Sch_type:"Government", Category:"Middle School"},
    { key:"17144", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL KEELATHANIYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17145", district:"19", Block:"251", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MELASIVAPURI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17146", district:"19", Block:"251", label:"M.NARAYANAN CHETTIAR HIGH SCHOOL,KOPPANAPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17147", district:"19", Block:"251", label:"KALAIMAGAL KALLOORI GIRLS HIGH SCHOOL,KOPPANAPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17148", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, KALLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17149", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL MYLAPORE", Sch_type:"Government", Category:"Middle School"},
    { key:"17150", district:"19", Block:"251", label:"PANCHAYAT UNION MIDDLE SCHOOL, KATTAYANDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17151", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, NEDUNGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17152", district:"19", Block:"25", label:"AYYAKKANNU GHSS, KEELANILAIKKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17153", district:"19", Block:"25", label:"PRAISE MATRICULATION SCHOOL, KEELANILAIKKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17154", district:"19", Block:"25", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THANJURE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17155", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, NALLAMBAL SAMUTHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17156", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, POONAIYAN KUDIERUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"17157", district:"19", Block:"25", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KALANIVASAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17158", district:"19", Block:"25", label:"GOVERNMENT HIGH SCHOOL, POSAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17159", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, PILIYAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17160", district:"19", Block:"25", label:"GOVERNMENT HIGH SCHOOL, PERUNGUDI", Sch_type:"Government", Category:"High School"},
    { key:"17161", district:"19", Block:"25", label:"GOVERNMENT HIGH SCHOOL, OTHAIPULIKKUDIYIRUPPU", Sch_type:"Government", Category:"High School"},
    { key:"17162", district:"19", Block:"25", label:"JAIHINTH  BALA BHAVAN MATRICULATION SCHOOL, VAIYAPURIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17163", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, THIRUVANAIKAVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17164", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, THEKKATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17165", district:"19", Block:"25", label:"M CT M CC M HIGH SCHOOL, NAMANASAMUDRAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17166", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, MEENIKANDHA", Sch_type:"Government", Category:"Middle School"},
    { key:"17167", district:"19", Block:"25", label:"GOVERNMENT HIGH SCHOOL, KURUNGALUR", Sch_type:"Government", Category:"High School"},
    { key:"17168", district:"19", Block:"25", label:"SRI GANESAR MIDDLE SCHOOL, VISHVANATHPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17169", district:"19", Block:"25", label:"ULAGAPPAR HIGHER SECONDARY SCHOOL, RAMACHANDRAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17170", district:"19", Block:"25", label:"SKT GANDHI HIGH SCHOOL, RAYAVARAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17171", district:"19", Block:"25", label:"GOVERNMENT HIGH SCHOOL, MIRATTUNILAI", Sch_type:"Government", Category:"High School"},
    { key:"17172", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, IRUMBANADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17173", district:"19", Block:"25", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ARIMALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17174", district:"19", Block:"25", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, ARIMALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17175", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, ONANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17176", district:"19", Block:"25", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, YEMBAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17177", district:"19", Block:"25", label:"IMAYAM NURSERY PRIMARY SCHOOL, YEMBAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17178", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, KALLUR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"17179", district:"19", Block:"25", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17180", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, KADAYAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17181", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, VADAKKU NALLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17182", district:"19", Block:"25", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUNASANTHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17183", district:"19", Block:"22", label:"GOVT HR SEC SCHOOL MARAMADAKKI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17184", district:"19", Block:"22", label:"PUNCHAYAT UNION MIDDLE SCHOOL PARAVAKOTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17185", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,KURUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17186", district:"19", Block:"22", label:"GOVT HIGH SCHOOL,NARPAVALASENGAMARI", Sch_type:"Government", Category:"High School"},
    { key:"17187", district:"19", Block:"22", label:"GOVT HR SEC SCHOOL, THANTHANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17188", district:"19", Block:"22", label:"GOVT HR SEC SCHOOL, CHIDAMBARAVIDUTHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17189", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, AVANATHANKOTTAI EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"17190", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,KUNNAKURUMBI", Sch_type:"Government", Category:"Middle School"},
    { key:"17191", district:"19", Block:"22", label:"GOVT HR SEC SCHOOL, SILATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17192", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, ALIYANILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17193", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, ANNAMALAIYAN KUDIYIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"17194", district:"19", Block:"22", label:"GOVT HR SEC SCHOOL, POOVAIMANAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17195", district:"19", Block:"22", label:"GOVT HIGH SCHOOL, PERIYALUR EAST", Sch_type:"Government", Category:"High School"},
    { key:"17196", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, NEIVATHALI", Sch_type:"Government", Category:"Middle School"},
    { key:"17197", district:"19", Block:"22", label:"GOVT HR SEC SCHOOL MERPANAIKKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17198", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL , RAMASAMYPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17199", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL , VALLAVARI WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"17200", district:"19", Block:"22", label:"GOVT HIGH SCHOOL, VALLAVARI EAST", Sch_type:"Government", Category:"High School"},
    { key:"17201", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, KONNAKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17202", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, KURUNTHIRAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17203", district:"19", Block:"22", label:"GOVT HIGH SCHOOL , ERUKKALAKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17204", district:"19", Block:"22", label:"GOVT HIGH SCHOOL , RAJENDRAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"17205", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, KONGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17206", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL , VAIRIVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17207", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, RETHINAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17208", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOOTHADIVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17209", district:"19", Block:"22", label:"LAUREL HIGHER SECONDARY SCHOOL, KURUMBAKKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17210", district:"19", Block:"22", label:"GOVT HIGH SCHOOL, ARASARKULAM", Sch_type:"Government", Category:"High School"},
    { key:"17211", district:"19", Block:"22", label:"ALHIDHAYA JAMATH ORIENTAL ARABIC HIGH SCHOOL, ARASARKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"17212", district:"19", Block:"22", label:"GOVT HIGH SCHOOL MANGALANADU EAST", Sch_type:"Government", Category:"High School"},
    { key:"17213", district:"19", Block:"22", label:"GOVT HIGH SCHOOL, AMARASIMENDRAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"17214", district:"19", Block:"22", label:"GOVT BOYS HR SEC SCHOOL,SUBRAMANIYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17215", district:"19", Block:"22", label:"GOVT GIRLS HR SEC SCHOOL, SUBRAMANIYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17216", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL KARUNGULIKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17217", district:"19", Block:"22", label:"GOVT HIGH SCHOOL,PERUNGADU", Sch_type:"Government", Category:"High School"},
    { key:"17218", district:"19", Block:"22", label:"GOVT HIGH SCHOOL,MELAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"17219", district:"19", Block:"22", label:"DOCTORS PUBLIC SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17220", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, VIGNESWARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17221", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,VEERANDAN", Sch_type:"Government", Category:"Middle School"},
    { key:"17222", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,KAMMANGADU NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"17223", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,THIRUNALUR NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"17224", district:"19", Block:"22", label:"GOVT HIGH SCHOOL, THIRUNALLUR SOUTH", Sch_type:"Government", Category:"High School"},
    { key:"17225", district:"19", Block:"22", label:"GOVT HIGH SCHOOL, AYINGUDI SOUTH", Sch_type:"Government", Category:"High School"},
    { key:"17226", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,MANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17227", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL MOOKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17228", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,PANCHATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17229", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,PUDUVAKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17230", district:"19", Block:"22", label:"ST.MARYS R.C HIGH SCHOOL, EGANIVAYAL THENPATHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17231", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, DURAIYARASAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17232", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, ATHANI", Sch_type:"Government", Category:"Middle School"},
    { key:"17233", district:"19", Block:"22", label:"GOVT HR SEC SCHOOL ATHANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17234", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL, THATTANVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17235", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,NAGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17236", district:"19", Block:"22", label:"GOVT HR SEC SCHOOL,NAGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17237", district:"19", Block:"22", label:"GOVERNMENT MODEL  HIGHER  SECONDARY  SCHOOL , ARANTHANGI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17238", district:"19", Block:"22", label:"MUNICIPAL MIDDLE SCHOOL L.N.PURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17239", district:"19", Block:"22", label:"IDEAL MATRIC HR. SEC. SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17240", district:"19", Block:"22", label:"CHIRST KING R.C. MIDDLE SCHOOL, ARANTHANGI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17241", district:"19", Block:"22", label:"VESTLEY HR SEC SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17242", district:"19", Block:"22", label:"S.M.A.MIDDLE.SCHOOL,CHITTANKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17243", district:"19", Block:"22", label:"SATHYAMOORTHY MIDDLE SCHOOL, ARANTHANGI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17244", district:"19", Block:"22", label:"A.J.J.ORIENTAL ARABIC HR SEC SCHOOL,ARANTHANGI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17245", district:"19", Block:"22", label:"GOVT GIRLS HR SEC SCHOOL,ARANTHANGI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17246", district:"19", Block:"22", label:"KAMARAJ HIGH SCHOOL, ARANTHANGI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17247", district:"19", Block:"22", label:"MUNICIPAL MIDDLE SCHOOL WEST, ARANTHANGI", Sch_type:"Government", Category:"Middle School"},
    { key:"17248", district:"19", Block:"22", label:"T.E.L.C MIDDLE SCHOOL,ARANTHANGI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17249", district:"19", Block:"22", label:"GOVT HIGH SCHOOL,SUNAIYAKKADU", Sch_type:"Government", Category:"High School"},
    { key:"17250", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,THOLUVANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17251", district:"19", Block:"22", label:"GOVT HIGH SCHOOL,ARASARKULAM EAST", Sch_type:"Government", Category:"High School"},
    { key:"17252", district:"19", Block:"22", label:"PANCHAYAT UNION MIDDLE SCHOOL,MENAKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17253", district:"19", Block:"167", label:"GOVT HIGH SCHOOL, EDAIYATHIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"17254", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL SINGAVANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17255", district:"19", Block:"167", label:"GOVT. HIGH SCHOOL, KARAKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17256", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHELLANENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17257", district:"19", Block:"167", label:"GOVT. HIGH SCHOOL, JEGATHAPATTINAM", Sch_type:"Government", Category:"High School"},
    { key:"17258", district:"19", Block:"167", label:"GOVT. HIGH SCHOOL, KOLENTHIRAM", Sch_type:"Government", Category:"High School"},
    { key:"17259", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, MANJAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17260", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, VADAKKU AMMAPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17261", district:"19", Block:"167", label:"GOVT. GIRLS HR. SEC. SCHOOL, MANAMELKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17262", district:"19", Block:"167", label:"GOVT. BOYS HR. SEC. SCHOOL, MANAMELKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17263", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELASTHANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17264", district:"19", Block:"167", label:"GOVT. HR. SEC. SCHOOL, AMBALAVANANENDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17265", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, NILAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17266", district:"19", Block:"167", label:"GOVT. HR. SEC. SCHOOL, PERUMARUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17267", district:"19", Block:"167", label:"ST.MARY'S MIDDLE SCHOOL, MOOVANUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"17268", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL KARKKAMALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17269", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, VELLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17270", district:"19", Block:"167", label:"ST. JOSEPH HR. SEC. SCHOOL, VICHOOR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17271", district:"19", Block:"167", label:"GOVT. HIGH SCHOOL, KRISHNAJIPATTINAM", Sch_type:"Government", Category:"High School"},
    { key:"17272", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL VEDIYANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17273", district:"19", Block:"167", label:"GOVT. HIGH SCHOOL, GOPALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"17274", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, PALAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17275", district:"19", Block:"167", label:"GOVT. HR. SEC. SCHOOL, KOTTAIPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17276", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL(MC), KOTTAIPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17277", district:"19", Block:"167", label:"GOVT. GIRLS. HIGH SCHOOL, KOTTAIPATTINAM", Sch_type:"Government", Category:"High School"},
    { key:"17278", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL BOYS, AMMAPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17279", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, AVUDAIYARPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17280", district:"19", Block:"167", label:"GOVT. GIRLS HR. SEC. SCHOOL, AMMAPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17281", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, THANDALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17282", district:"19", Block:"167", label:"PANCHAYAT UNION MIDDLE SCHOOL, KARAKATHIKKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17283", district:"19", Block:"167", label:"GOVT. HR. SEC. SCHOOL, KATTUMAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17284", district:"19", Block:"167", label:"AL-HUDA MATRIC HIGH SCHOOL,AZHAGANVAYAL.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17285", district:"19", Block:"33", label:"GOVERNMENT HIGH SCHOOL,AMARADAKKI.", Sch_type:"Government", Category:"High School"},
    { key:"17286", district:"19", Block:"33", label:"GOVERNMENT HIGH SCHOOL OKKUR", Sch_type:"Government", Category:"High School"},
    { key:"17287", district:"19", Block:"33", label:"PANCHAYAT UNION MID SCHOOL   KANNAKKANVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17288", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE  SCHOOL ,  THACHAMALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"17289", district:"19", Block:"33", label:"S. R.V.HSS, THIRUPPUNAVASAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17290", district:"19", Block:"33", label:"GOVT HIGH SCHOOL .POYYATHANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"17291", district:"19", Block:"33", label:"PANCHAYAT UNION MID SCHOOL   AVUDAIYARKOIL", Sch_type:"Government", Category:"Middle School"},
    { key:"17292", district:"19", Block:"33", label:"GOVT HR SEC SCHOOL AVUDAIYARKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17293", district:"19", Block:"33", label:"GOVT.GIRLS HIGH   SCHOOL, AVUDAIYARKOIL", Sch_type:"Government", Category:"High School"},
    { key:"17294", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. SIDDHAKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17295", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. THONDAIMANENDHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17296", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. KARANIYANENDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17297", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. R. PUDUPATTINAM(MUSLIM)", Sch_type:"Government", Category:"Middle School"},
    { key:"17298", district:"19", Block:"33", label:"GOVT HR SEC SCHOOL GOPALAPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17299", district:"19", Block:"33", label:"R.C.HIGH.SCHOOL ,PADIRIGUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17300", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL MUTHUKUDA", Sch_type:"Government", Category:"Middle School"},
    { key:"17301", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL.ELUNOOTRIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17302", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL PIRANTHANI", Sch_type:"Government", Category:"Middle School"},
    { key:"17303", district:"19", Block:"33", label:"PANCHAYAT UNION  MIDDLE SCHOOL. PERUNAVALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17304", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. PARAMANDHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17305", district:"19", Block:"33", label:"GOVT HIGH SCHOOL PONBETHI", Sch_type:"Government", Category:"High School"},
    { key:"17306", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. ARASANAGARIPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17307", district:"19", Block:"33", label:"GOVT. HR.SEC. SCHOOL. MIMISAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17308", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. AVANAMPERUNGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17309", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. VELVARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17310", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. KEELKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17311", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. PANDIPATHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17312", district:"19", Block:"33", label:"MANICKAVASAKAR.H.S.SCHOOL ,PANDIPATHIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17313", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. KUMULOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17314", district:"19", Block:"33", label:"GOVT. HR.SEC. SCHOOL. KARUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17315", district:"19", Block:"33", label:"PANCHAYAT UNION MIDDLE SCHOOL. KALABAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17316", district:"19", Block:"33", label:"GOVT HIGH SCHOOL THALANUR", Sch_type:"Government", Category:"High School"},
    { key:"17317", district:"23", Block:"294", label:"GOVT HSS ALAVAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17318", district:"23", Block:"294", label:"PUMS AMMATCHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17319", district:"23", Block:"294", label:"PUMS ARASANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17320", district:"23", Block:"294", label:"PUMS AR USILAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17321", district:"23", Block:"294", label:"GOVT HSS ARASANOOR THIRUMANJOLAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17322", district:"23", Block:"294", label:"PUMS MUTHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17323", district:"23", Block:"294", label:"PUMS KUTTITHINNI", Sch_type:"Government", Category:"Middle School"},
    { key:"17324", district:"23", Block:"294", label:"PUMS MAHASIVANENDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17325", district:"23", Block:"294", label:"GOVT HSS ALAGAMANAGARI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17326", district:"23", Block:"294", label:"PUMS IDAYAMELUR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"17327", district:"23", Block:"294", label:"GOVT HSS IDAYAMELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17328", district:"23", Block:"294", label:"VIKNESHWARA VIDYALAYA  MATRICULATION SCHOOL IDAYAMELUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"17329", district:"23", Block:"294", label:"MUTHU MATRICULATION IDAYAMELUR COLONY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17330", district:"23", Block:"294", label:"PUMS KOOTURAVUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17331", district:"23", Block:"294", label:"SARVASEVA HIGH SCHOOL VAHULATHUPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17332", district:"23", Block:"294", label:"GOVT HIGH SCHOOL KATTANIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17333", district:"23", Block:"294", label:"PUMS KANNARIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"17334", district:"23", Block:"294", label:"SAKTHI HIGH SCHOOL JOTHI NAGAR PADAMATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"17335", district:"23", Block:"294", label:"PUMS SUNDARANADAPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"17336", district:"23", Block:"294", label:"PUMS KANJIRANGAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17337", district:"23", Block:"294", label:"GOVT MODEL HIGHER SECONDARY SCHOOL KEELAPOONGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17338", district:"23", Block:"294", label:"PUMS VEERAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17339", district:"23", Block:"294", label:"GOVT HIGH SCHOOL KUMARAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17340", district:"23", Block:"294", label:"PUMS URULI", Sch_type:"Government", Category:"Middle School"},
    { key:"17341", district:"23", Block:"294", label:"GOVT HIGH SCHOOL SITHALOOR", Sch_type:"Government", Category:"High School"},
    { key:"17342", district:"23", Block:"294", label:"GOVT HSS MANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17343", district:"23", Block:"294", label:"PUMS MATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17344", district:"23", Block:"294", label:"PUMS MELAPOONGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17345", district:"23", Block:"294", label:"SRI KARPAGA MATHA MIDDLE SCHOOL MADAGUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17346", district:"23", Block:"294", label:"GOVT HIGHER SECONDARY  SCHOOL V MALAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17347", district:"23", Block:"294", label:"SWAMY VIVEKANANTHA MATRIC HR SEC  SCHOOL METTUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17348", district:"23", Block:"294", label:"PUMS NAMANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17349", district:"23", Block:"294", label:"PUMS NALUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17350", district:"23", Block:"294", label:"SC HSS OKKUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17351", district:"23", Block:"294", label:"PUMS OKKUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17352", district:"23", Block:"294", label:"PUMS ANNANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"17353", district:"23", Block:"294", label:"ST JOSEPH MATRIC SCHOOL O PUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"17354", district:"23", Block:"294", label:"GOVT HIGH SCHOOL PADAMATHUR", Sch_type:"Government", Category:"High School"},
    { key:"17355", district:"23", Block:"294", label:"PUMS PILLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17356", district:"23", Block:"294", label:"PUMS PIRAVALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17357", district:"23", Block:"294", label:"GOVT HIGH SCHOOL MELASALOOR", Sch_type:"Government", Category:"High School"},
    { key:"17358", district:"23", Block:"294", label:"PUMS SOLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17359", district:"23", Block:"294", label:"SHUDDHANANDA BHARATI DESIYA VIDYALAYAM  HIGH SCHOOL SOLAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17360", district:"23", Block:"294", label:"SHRI RAMANA VIKASS HIGHER SECONDARY SCHOOL,CHOLAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17361", district:"23", Block:"294", label:"PUMS KOMALIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17362", district:"23", Block:"294", label:"PUMS PASANKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17363", district:"23", Block:"294", label:"GOVT HIGHER  SECONDARY  SCHOOL ,  SAKKANTHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17364", district:"23", Block:"294", label:"GOVT HSS THAMARAKKI SOUTH ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17365", district:"23", Block:"294", label:"PUMS THAMARAKKI NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"17366", district:"23", Block:"294", label:"PUMS THIRUMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17367", district:"23", Block:"294", label:"GOVT HIGH SCHOOL KEELAKANDANI", Sch_type:"Government", Category:"High School"},
    { key:"17368", district:"23", Block:"294", label:"ANNAI SRI VEERAMAKALIAMMAN MATRIC HR SEC SCHOOL MELAVANIYANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17369", district:"23", Block:"294", label:"KARPURASUNDARAPANDIAN RAMALAKSHMI HR SEC SCHOOL SIVAGANGAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17370", district:"23", Block:"294", label:"MOTHER THERASA MATRIC SCHOOL SIVAGANGAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17371", district:"23", Block:"294", label:"GOVT HSS MARUTHUPANDIYAR NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17372", district:"23", Block:"294", label:"ST ANNYS MATRTIC  SCHOOL KANJIRANGAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17373", district:"23", Block:"294", label:"GOVT GIRLS HSS, SIVAGANGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17374", district:"23", Block:"294", label:"ST FRANCIS MID SCHOOL BRITTO NAGAR SIVAGANGAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"17375", district:"23", Block:"294", label:"ALICE MILLER GIRLS HS SIVAGANGAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17376", district:"23", Block:"294", label:"SWAMI VIVEKANANDA HIGH SCHOOL SIVAGANGAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17377", district:"23", Block:"294", label:"MANNAR MIDDLE SCHOOL, SIVAGANGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17378", district:"23", Block:"294", label:"MANNAR HR SEC SCHOOL, SIVAGANGAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17379", district:"23", Block:"294", label:"Rajakumari Rajarajeshwai kalanilaya MIDDLE SCHOOL SIVAGANGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17380", district:"23", Block:"294", label:"ST. JOSEPH ' S HR SEC SCHOOL SIVAGANGAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17381", district:"23", Block:"294", label:"MMS 48 COLONY SIVAGANGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17382", district:"23", Block:"294", label:"ST JUSTIN'S GIRLS HSS,SIVAGANGAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17383", district:"23", Block:"294", label:"AR NATESAN CHETTIYAR MIDDLE SCHOOL SIVAGANGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17384", district:"23", Block:"294", label:"ROMAN CATHOLIC MID SCHOOL SIVAGANGAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17385", district:"23", Block:"294", label:"NAMBIKKAI MANAVALARCHI SCHOOL SIVAGANGAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17386", district:"23", Block:"294", label:"KENDIRIYA VIDYALAYA SIVAGANGAI", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"17387", district:"23", Block:"294", label:"ST MICHAEL MATRIC HSS SCHOOL SIVAGANGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17388", district:"23", Block:"294", label:"AL-HUDHA ISLAMIC INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL SIVAGANGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17389", district:"23", Block:"94", label:"PUMS ETTIAGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17390", district:"23", Block:"94", label:"PUMS KARUNGALAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17391", district:"23", Block:"94", label:"GOVT  HSS KALAIYARKOVIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17392", district:"23", Block:"94", label:"KRISHNANANTHAM VIDYASHRAM MATRICULATION SCHOOL KALAIYARKOVIL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17393", district:"23", Block:"94", label:"GOVT HSS ,KOLLANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17394", district:"23", Block:"94", label:"PUMS GOWRIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17395", district:"23", Block:"94", label:"PUMS SILANTHAGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17396", district:"23", Block:"94", label:"GOVT  HSS SILUKKUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17397", district:"23", Block:"94", label:"SAMBAVIGA HSS RAGINIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17398", district:"23", Block:"94", label:"PUMS,  SEVALPUNJAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17399", district:"23", Block:"94", label:"PUMS,  PERIAOLAIKUDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17400", district:"23", Block:"94", label:"PUMS, MARAKKATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17401", district:"23", Block:"94", label:"PUMS,  MANGATTUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17402", district:"23", Block:"94", label:"PUMS,  MELAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17403", district:"23", Block:"94", label:"PUMS,  MELAMARUNGOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17404", district:"23", Block:"94", label:"PUMS UDAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17405", district:"23", Block:"94", label:"PUMS,  USILANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17406", district:"23", Block:"94", label:"PUMS,  KANCHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17407", district:"23", Block:"94", label:"PUMS, SATHANI", Sch_type:"Government", Category:"Middle School"},
    { key:"17408", district:"23", Block:"94", label:"PUMS,  SIRUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17409", district:"23", Block:"94", label:"PUMS,  SENGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17410", district:"23", Block:"94", label:"PUMS,  NADAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17411", district:"23", Block:"94", label:"PUMS, PALUVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17412", district:"23", Block:"94", label:"PUMS,  PILLATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17413", district:"23", Block:"94", label:"PUMS,  PERIYAKILUVATCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17414", district:"23", Block:"94", label:"PUMS,  POTTAGAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17415", district:"23", Block:"94", label:"GOVT HR SEC SCHOOL MARAVAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17416", district:"23", Block:"94", label:"PUMS,  VELARENDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17417", district:"23", Block:"94", label:"GOVT ADW  HSS MALLAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17418", district:"23", Block:"94", label:"GOVT GIRLS HS PAGANERI", Sch_type:"Government", Category:"High School"},
    { key:"17419", district:"23", Block:"94", label:"GOVT HIGHER SECONDARY SCHOOL PAGANERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17420", district:"23", Block:"94", label:"K M S C HSS NATTARASAN KOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17421", district:"23", Block:"94", label:"S R M HSS NATTARASAN KOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17422", district:"23", Block:"94", label:"ST.TERESAS CARMEL HIGH SCHOOL PAGAIYANJAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"17423", district:"23", Block:"94", label:"ULAGAMATHA HIGH SCHOOL SAKKUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17424", district:"23", Block:"94", label:"GHS,K.CHOCKANATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"17425", district:"23", Block:"94", label:"PUMS, NAGARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17426", district:"23", Block:"94", label:"GOVT  HS KARUMANTHAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"17427", district:"23", Block:"94", label:"GOVT  HS SETHUR", Sch_type:"Government", Category:"High School"},
    { key:"17428", district:"23", Block:"94", label:"PUMS, PANANGADI", Sch_type:"Government", Category:"Middle School"},
    { key:"17429", district:"23", Block:"94", label:"PUMS, KALAYARMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17430", district:"23", Block:"94", label:"PUMS, MUDIKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17431", district:"23", Block:"94", label:"GHS, PERIYAKANNANOOR", Sch_type:"Government", Category:"High School"},
    { key:"17432", district:"23", Block:"94", label:"PUMS, KEELAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17433", district:"23", Block:"94", label:"PUMS, SEMBAR", Sch_type:"Government", Category:"Middle School"},
    { key:"17434", district:"23", Block:"94", label:"PUMS, MUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17435", district:"23", Block:"94", label:"ROMANCATHALICKkMIDDLESCHOOL, ANDICHURANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17436", district:"23", Block:"94", label:"ROMAN CATHOLIC MID SCHOOL OTTANAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17437", district:"23", Block:"94", label:"ROMAN CATHOLIC MIDDLE SCHOOL, PULIYADITHAMMAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17438", district:"23", Block:"94", label:"AUXILIUM MATRIC HR SEC SCHOOL, PULIYADITHAMMAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17439", district:"23", Block:"94", label:"SAHAYARANI GIRLS  HR SEC SCHOOL SUSAIAPPARPATTANAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17440", district:"23", Block:"94", label:"ROMAN CATHOLIC MIDDLE SCHOOL, PALLITHAMMAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17441", district:"23", Block:"94", label:"DON BOSCO  HR SEC  SCHOOL  PALLITHAMMAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17442", district:"23", Block:"94", label:"ROMAN CATHOLIC MIDDLE SCHOOL THUVARALANKANMAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17443", district:"23", Block:"94", label:"ROMAN CATHOLIC MID SCHOOL PULIKANMOI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17444", district:"23", Block:"94", label:"SINNAR MANDAR MIDDLE SCHOOL, SENGULIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17445", district:"23", Block:"166", label:"PUMS ARASAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17446", district:"23", Block:"166", label:"GOVT HR SEC SCHOOL CHINNAKANNANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17447", district:"23", Block:"166", label:"SACRED HEART MIDDLE SCHOOL IDAIKATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17448", district:"23", Block:"166", label:"GOVT HR SEC SCHOOL IDAIKATTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17449", district:"23", Block:"166", label:"PUMS KEELAMELKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17450", district:"23", Block:"166", label:"GOVT HR SEC SCHOOL KATTIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17451", district:"23", Block:"166", label:"PUMS KEELAPPASALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17452", district:"23", Block:"166", label:"GOOD WILL MATRIC SHOOL MANAMADURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17453", district:"23", Block:"166", label:"GOVT HIGH SCHOOL VETHIYARENDAL", Sch_type:"Government", Category:"High School"},
    { key:"17454", district:"23", Block:"166", label:"PUMS KEELAPIDAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17455", district:"23", Block:"166", label:"PUMS KUVALAIVELI", Sch_type:"Government", Category:"Middle School"},
    { key:"17456", district:"23", Block:"166", label:"CEYRAC HS, MOONGILOORANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17457", district:"23", Block:"166", label:"GOVT HIGH SCHOOL MOONGILOORANI", Sch_type:"Government", Category:"High School"},
    { key:"17458", district:"23", Block:"166", label:"GOVT HIGH SCHOOL MELANETTUR", Sch_type:"Government", Category:"High School"},
    { key:"17459", district:"23", Block:"166", label:"PUMS MELAPIDAVUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17460", district:"23", Block:"166", label:"PUMS VILAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17461", district:"23", Block:"166", label:"GOVT HIGH SCHOOL MELAPASALAI", Sch_type:"Government", Category:"High School"},
    { key:"17462", district:"23", Block:"166", label:"GOVT HIGH SCHOOL MILAGANOOR", Sch_type:"Government", Category:"High School"},
    { key:"17463", district:"23", Block:"166", label:"PUMS THUTHIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17464", district:"23", Block:"166", label:"GOVT HR SEC SCHOOL PERIYAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17465", district:"23", Block:"166", label:"ST. CHARLES MATRICULATION SCHOOL, PERIYAKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17466", district:"23", Block:"166", label:"PUMS S.PUDUKKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17467", district:"23", Block:"166", label:"PUMS NATHAPURAKKI", Sch_type:"Government", Category:"Middle School"},
    { key:"17468", district:"23", Block:"166", label:"PUMS SEIKALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17469", district:"23", Block:"166", label:"GHS KALKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"17470", district:"23", Block:"166", label:"PUMS VELUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17471", district:"23", Block:"166", label:"PUMS PAPPANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17472", district:"23", Block:"166", label:"PUMS KULAIYANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17473", district:"23", Block:"166", label:"GOVT HIGH SCHOOL PARAYANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"17474", district:"23", Block:"166", label:"PUMS AVARANGADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17475", district:"23", Block:"166", label:"GOVT HR SEC SCHOOL KOMBUKARANENDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17476", district:"23", Block:"166", label:"GOVT HIGH SCHOOL VELLIKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"17477", district:"23", Block:"166", label:"GOVT HR SEC SCHOOL VEMBATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17478", district:"23", Block:"166", label:"PUMS V.PUDUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17479", district:"23", Block:"166", label:"PUMS VILATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17480", district:"23", Block:"166", label:"PUMS RAJAGAMBEERAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17481", district:"23", Block:"166", label:"ST MARYS HSS RAJAGAMBEERAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17482", district:"23", Block:"166", label:"PUMS THANJAKKUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17483", district:"23", Block:"166", label:"M.K.N MID SCHOOL T.PUDUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17484", district:"23", Block:"166", label:"PUMS BURMACOLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"17485", district:"23", Block:"166", label:"SEVENTH DAY ADVENTIST  MAT HSS SCHOOL MANAMADURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17486", district:"23", Block:"166", label:"OVC HSS MANAMADURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17487", district:"23", Block:"166", label:"R.C.St.CECILIAL MIDDLE.SCHOOL,MANAMADURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17488", district:"23", Block:"166", label:"ST.JOSEPH'S MATRIC HSS SCHOOL MANAMADURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17489", district:"23", Block:"166", label:"C.S.I HIGH SCHOOL MANAMADURAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17490", district:"23", Block:"166", label:"GOVT GIRLS HSS MANAMADURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17491", district:"23", Block:"166", label:"CSI DARLING SELVABAI DAVID HIGH SCHOOL FOR THE DEAF, MANAMADURAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17492", district:"23", Block:"333", label:"SYED ISMAIL AVULIYA MIDDLE SCHOOL THIRUPPUVANAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"17493", district:"23", Block:"333", label:"PUMS THIRUPPUVANAM SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"17494", district:"23", Block:"333", label:"PUMS THIRUPPUVANAM NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"17495", district:"23", Block:"333", label:"ANNAI SIVA PACKIYAM MEMORIAL HR. SEC. SCHOOL, THIRUPPUVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17496", district:"23", Block:"333", label:"GOVT GIRLS HSS THIRUPPUVANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17497", district:"23", Block:"333", label:"PUMS THIRUPPUVANAM PALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17498", district:"23", Block:"333", label:"KAMARAJAR MATRIC  HR SEC SCHOOL THIRUPPUVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17499", district:"23", Block:"333", label:"ST MARY'S MATRIC HR SEC  THIRUPPUVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17500", district:"23", Block:"333", label:"VELAMMAL MATRIC HR SEC  THIRUPPUVANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17501", district:"23", Block:"333", label:"PUMS MADAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17502", district:"23", Block:"333", label:"GOVT BOYS HSS THIRUPPUVANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17503", district:"23", Block:"333", label:"ARIYAVA MONTESSORI MATRIC. HR. SEC. SCHOOL, MADAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17504", district:"23", Block:"333", label:"GOVT HIGHER SECONDARY  SCHOOL ALLINAGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17505", district:"23", Block:"333", label:"GOVT HIGH SCHOOL POOVANTHI", Sch_type:"Government", Category:"High School"},
    { key:"17506", district:"23", Block:"333", label:"MANIMARAN MATRICULATION SCHOOL  POOVANTHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17507", district:"23", Block:"333", label:"PUMS KALUGARKADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17508", district:"23", Block:"333", label:"PUMS ENATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17509", district:"23", Block:"333", label:"PUMS KANAKANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17510", district:"23", Block:"333", label:"PUMS MELARANGIAN", Sch_type:"Government", Category:"Middle School"},
    { key:"17511", district:"23", Block:"333", label:"PUMS KELERANGIAN", Sch_type:"Government", Category:"Middle School"},
    { key:"17512", district:"23", Block:"333", label:"PUMS VAYALCHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"17513", district:"23", Block:"333", label:"PUMS PRAMANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17514", district:"23", Block:"333", label:"PUMS MARANADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17515", district:"23", Block:"333", label:"PUMS MALAVARAYANENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17516", district:"23", Block:"333", label:"PUMS PASIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17517", district:"23", Block:"333", label:"GOVT HSS KEELADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17518", district:"23", Block:"333", label:"GOVT HIGH SCHOOL MANALUR", Sch_type:"Government", Category:"High School"},
    { key:"17519", district:"23", Block:"333", label:"ALAGUMALAR MHSS MANALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17520", district:"23", Block:"333", label:"PUMS MELAVELLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17521", district:"23", Block:"333", label:"PUMS KONTHAGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17522", district:"23", Block:"333", label:"GOVT HIGH SCHOOL ILANTHAIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"17523", district:"23", Block:"333", label:"GOVT HIGH SCHOOL D.KARISALKULAM", Sch_type:"Government", Category:"High School"},
    { key:"17524", district:"23", Block:"333", label:"PUMS KAZHUVANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17525", district:"23", Block:"333", label:"PUMS POTTAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17526", district:"23", Block:"333", label:"GOVT HIGH SCHOOL KANCHERANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"17527", district:"23", Block:"333", label:"PUMS CHOTTATHATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17528", district:"23", Block:"333", label:"PUMS MUKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17529", district:"23", Block:"333", label:"PUMS SENGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17530", district:"23", Block:"333", label:"GOVT HIGH SCHOOL THAVATHARENTHAL", Sch_type:"Government", Category:"High School"},
    { key:"17531", district:"23", Block:"333", label:"PUMS KANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17532", district:"23", Block:"333", label:"PUMS THOOTHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17533", district:"23", Block:"333", label:"PUMS THIRUPPACHETHI SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"17534", district:"23", Block:"333", label:"GOVT HSS THIRUPPACHETHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17535", district:"23", Block:"333", label:"GOVT HSS PALAYANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17536", district:"23", Block:"333", label:"PUMS VANNIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17537", district:"23", Block:"333", label:"VELAMMAL RESIDENTIAL SCHOOL LADANENDAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17538", district:"23", Block:"333", label:"GOVT HIGH SCHOOL LADANENTHAL", Sch_type:"Government", Category:"High School"},
    { key:"17539", district:"23", Block:"333", label:"PUMS SANGANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17540", district:"23", Block:"333", label:"PALANI ANDAVAR MIDDLE SCHOOL PAPPKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17541", district:"23", Block:"333", label:"PUMS KURUNTHANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17542", district:"23", Block:"333", label:"PUMS T.PAPANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17543", district:"23", Block:"333", label:"PUMS MUDHUVANTHIDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17544", district:"23", Block:"333", label:"PUMS T.VELANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17545", district:"23", Block:"333", label:"ROMAN CATHOLIC.1 MIDDLE SCHOOL MICHALPATTINAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17546", district:"23", Block:"333", label:"GOVT HIGH SCHOOL KALLURANI", Sch_type:"Government", Category:"High School"},
    { key:"17547", district:"23", Block:"333", label:"ST MARY'S HIGH SCHOOL MICHAELPATTINAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17548", district:"23", Block:"333", label:"PUMS KILATHARI", Sch_type:"Government", Category:"Middle School"},
    { key:"17549", district:"23", Block:"79", label:"ST EUGENE HIGH SCHOOL, KOMBADIMADURAI.", Sch_type:"Un-aided", Category:"High School"},
    { key:"17550", district:"23", Block:"79", label:"ST. ANTONY'S MIDDLE SCHOOL, KALVELIPOTTAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17551", district:"23", Block:"79", label:"BONIFACE HS , ARIYANDIPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17552", district:"23", Block:"79", label:"GOVT. HSS MUNAIVENDRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17553", district:"23", Block:"79", label:"GOVT HIGH SCHOOL  KATCHATHANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"17554", district:"23", Block:"79", label:"VEERAMA MUNIVAR.MID.SCHOOL, OCCHANTHATTU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17555", district:"23", Block:"79", label:"ST.JAMES HSS, SOORANAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17556", district:"23", Block:"79", label:"ST ANTONY'S HIGH SCHOOL, KALLADITHIDAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17557", district:"23", Block:"79", label:"GOVT HIGH SCHOOL ALAVIDANGAN", Sch_type:"Government", Category:"High School"},
    { key:"17558", district:"23", Block:"79", label:"GOVT HSS, SALAIGRAMAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17559", district:"23", Block:"79", label:"GOVT HIGHER SEC SCHOOL SATHANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17560", district:"23", Block:"79", label:"PUMS MARUTHANGANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17561", district:"23", Block:"79", label:"R.C HIGH SCHOOL, KUNDUKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"17562", district:"23", Block:"79", label:"PUMS, THUGAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17563", district:"23", Block:"79", label:"HAJEE KK IBRAHIM ALI HR SEC SCHOOL PUDUR ", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17564", district:"23", Block:"79", label:"HAMEEDIA HSS, SALAIYOOR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17565", district:"23", Block:"79", label:"MELAPALLI VASAL (G) HSS, ILAIYANGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17566", district:"23", Block:"79", label:"ILAYANGUDI HSS , ILAYANGUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17567", district:"23", Block:"79", label:"GOVT (G) HIGH SCHOOL ILAYANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"17568", district:"23", Block:"56", label:"GOVT. HIGHER SECONDARY SCHOOL, PERIYAKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17569", district:"23", Block:"56", label:"PUMS EZHUVANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17570", district:"23", Block:"56", label:"PUMS, NATCHANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17571", district:"23", Block:"56", label:"PUMS BHARATHI VELANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17572", district:"23", Block:"56", label:"PUMS KALLANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17573", district:"23", Block:"56", label:"PUMS D.NAGANI", Sch_type:"Government", Category:"Middle School"},
    { key:"17574", district:"23", Block:"56", label:"PUMS MAVIDITHIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17575", district:"23", Block:"56", label:"ST. JOHN DE BRITTO MIDDLE SCHOOL, PULIYAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17576", district:"23", Block:"56", label:"GOVT HIGH SCHOOl, PULIYAL", Sch_type:"Government", Category:"High School"},
    { key:"17577", district:"23", Block:"56", label:"MOUNT SENARIO MATRIC HIGHER SECONDARY SCHOOL, MUPPAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17578", district:"23", Block:"56", label:"GOVT HIGH SCHOOL, MUPPAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"17579", district:"23", Block:"56", label:"PUMS. PIDARANENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17580", district:"23", Block:"56", label:"PUMS KAIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17581", district:"23", Block:"56", label:"SRI SHANMUGA MIDDLE SCHOOL, VELAYUTHAPATTINAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17582", district:"23", Block:"56", label:"PUMS PARAMBAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17583", district:"23", Block:"56", label:"PUMS PANIPULANVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17584", district:"23", Block:"56", label:"P.S.S HIGH SCHOOL, SHANMUGANATHAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17585", district:"23", Block:"56", label:"PUMS VELIMUTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17586", district:"23", Block:"56", label:"ST JOHN'S GIRLS HR SEC SCHOOL DEVAKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17587", district:"23", Block:"56", label:"PUMS ATHANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17588", district:"23", Block:"56", label:"ST. JOSEPH MIDDLE SCHOOL, THIDAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17589", district:"23", Block:"56", label:"PUMS SELUGAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17590", district:"23", Block:"56", label:"MUNICIPAL HIGH SCHOOL, DEVAKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17591", district:"23", Block:"56", label:"10TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17592", district:"23", Block:"56", label:"16TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17593", district:"23", Block:"56", label:"KVP MATRICULATION SCHOOOL, DEVAKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17594", district:"23", Block:"56", label:"14TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17595", district:"23", Block:"56", label:"PETHAL ACHI GIRLS HR SEC SCHOOL, DEVAKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17596", district:"23", Block:"56", label:"INFANT JESUS MATRIC HSS, DEVAKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17597", district:"23", Block:"56", label:"SRI MURUGANANDHA MIDDLE SCHOOL DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17598", district:"23", Block:"56", label:"MANICKAM MIDDLE SCHOOL, DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17599", district:"23", Block:"56", label:"ST. JOSEPH'S MIDDLE SCHOOL, RAMNAGAR, DEVAKOTTAI (EXTN.)", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17600", district:"23", Block:"56", label:"ST JOSEPH MATRIC HIGHER SECONDARY SCHOOL, DEVAKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17601", district:"23", Block:"56", label:"S.R.MID SCHOOL DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17602", district:"23", Block:"56", label:"ZAMINTHAR STREET HIGH SCHOOL DEVAKOTTAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17603", district:"23", Block:"56", label:"CHAIRMAN MANICKAVASAGAM MIDDLE SCHOOL, DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17604", district:"23", Block:"56", label:"SRI SEVUGAN ANNAMALAI MATRIC SCHOOL, DEVAKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17605", district:"23", Block:"56", label:"THIRUVALLUVAR ARULNERI MIDDLE SCHOOL, DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17606", district:"23", Block:"56", label:"SRINIVASA MIDDLE SCHOOL, DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17607", district:"23", Block:"56", label:"MUTHATHAL MIDDLE SCHOOL, DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17608", district:"23", Block:"56", label:"DE BRITTO HSS, DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17609", district:"23", Block:"56", label:"N.S.M.V.P.S HSS, DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17610", district:"23", Block:"56", label:"ST MARY'S GIRLS HR SEC SCHOOL, DEVAKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17611", district:"23", Block:"56", label:"LOTUS AN. VENKATACHALAM CHETTIAR ICSE SCHOOL DEVAKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17612", district:"23", Block:"56", label:"PUMS ANTHAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17613", district:"23", Block:"56", label:"GOVT. HR SEC SCHOOL, THIRUVEGAMPET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17614", district:"23", Block:"56", label:"PUMS KAVATHUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17615", district:"23", Block:"56", label:"PUMS SENKARKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"17616", district:"23", Block:"56", label:"PUMS ILANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17617", district:"23", Block:"56", label:"SAINT JOSEPH MIDDLE SCHOOL, SARUGANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17618", district:"23", Block:"56", label:"ST. PAUL'S HIGH SCHOOL, SARUGANI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17619", district:"23", Block:"56", label:"ST JOSEPH'S HR SEC SCHOOL, SEKKAKUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17620", district:"23", Block:"56", label:"V.N.T.GOVT HR SEC SCHOOL, SHANMUGANATHAPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17621", district:"23", Block:"56", label:"RC HIGH SCHOOL, KEELAUCHANI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17622", district:"23", Block:"56", label:"PUMS URUVATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17623", district:"23", Block:"56", label:"PUMS SIRUNALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17624", district:"23", Block:"106", label:"ROMANCATHOLICMIDDLESCHOOL NANAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17625", district:"23", Block:"106", label:"GOVT HSS HANUMANTHAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17626", district:"23", Block:"106", label:"PUMS UNJANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17627", district:"23", Block:"106", label:"GOVT.(ADW)HS, UNJANAI", Sch_type:"Government", Category:"High School"},
    { key:"17628", district:"23", Block:"106", label:"GOVT HS NARANAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"17629", district:"23", Block:"106", label:"PUMS MANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17630", district:"23", Block:"106", label:"GOVT HIGH SCHOOL SIRUVATCHI", Sch_type:"Government", Category:"High School"},
    { key:"17631", district:"23", Block:"106", label:"GOVT HSS KANNANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17632", district:"23", Block:"106", label:"PUMS KODIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17633", district:"23", Block:"106", label:"PUMS VADAKEELGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17634", district:"23", Block:"106", label:"PUMS CHITHANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17635", district:"23", Block:"106", label:"GOVERNMENT HIGH SCHOOL, T.SIRUVANOOR", Sch_type:"Government", Category:"High School"},
    { key:"17636", district:"23", Block:"272", label:"GOVT HSS, O.SIRUVAYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17637", district:"23", Block:"272", label:"CHELLAPPAN VIDYA MANDIR KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17638", district:"23", Block:"272", label:"GOVT HIGH SCHOOL, THIRUVELANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"17639", district:"23", Block:"272", label:"M.C.T.M.C.C HR SEC, SCHOOL KANADUKATHAN", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17640", district:"23", Block:"272", label:"SELVA VINAYAGAR HIGH SCHOOL, KOTHAMANGALAM.", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17641", district:"23", Block:"272", label:"SEETHAI ACHI MIDDLE SCHOOL PALLATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17642", district:"23", Block:"272", label:"A.C HSS, PALLATHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17643", district:"23", Block:"272", label:"R.M.M.GIRLS.HIGH SCH PALLATHUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17644", district:"23", Block:"272", label:"AL.V.A.M.PUMS , KOTTAIYUR.", Sch_type:"Government", Category:"Middle School"},
    { key:"17645", district:"23", Block:"272", label:"C.C. (G) HSS, KOTTAIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17646", district:"23", Block:"272", label:"T.A.C.GOVT.HR.SEC SCHOOL ,  KOTTAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17647", district:"23", Block:"272", label:"M.M. SCHOOL, KALANIVASAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17648", district:"23", Block:"272", label:"PUMS NESAVALAR COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"17649", district:"23", Block:"272", label:"SRI VAIRAVAR MATRIC HIGH SCHOOL , KARAIKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17650", district:"23", Block:"272", label:"PRNC MIDDLE SCHOOL KARAIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17651", district:"23", Block:"272", label:"B.MPL MID SCHOOL BARATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"17652", district:"23", Block:"272", label:"MPL MID SCHOOL SOUTH STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"17653", district:"23", Block:"272", label:"V.O.C MPL MID SCHOOL KARAIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17654", district:"23", Block:"272", label:"M.V GOVT HSS, KARAIKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17655", district:"23", Block:"272", label:"GOVT (G) HSS, MUTHUPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17656", district:"23", Block:"272", label:"S.M (G) HSS, KARAIKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17657", district:"23", Block:"272", label:"S.M.S.V.HR SEC SCHOOL , KARAIKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17658", district:"23", Block:"272", label:"ALAGAPPA MODEL HSS, KARAIKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17659", district:"23", Block:"272", label:"ANNAI VELANGANNI MATRIC SCHOOL , KARAIKUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17660", district:"23", Block:"272", label:"AZAD.MATRIC HIGH SCHOOL, KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17661", district:"23", Block:"272", label:"SAHAYAMATHA MATRICULATION HIGHER SECONDARY SCHOOL,KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17662", district:"23", Block:"272", label:"SRI RAGHAVENDRA MATRIC HR SEC SCHOOL, KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17663", district:"23", Block:"272", label:"R.H. HIGHER SECONDARY  SCHOOL. ( RESIDENTIAL  HANDICAPED ),KARIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17664", district:"23", Block:"272", label:"ALANGUDIYAR STREET MUNICIPAL HIGH SCHOOL ,   KARAIKUDI", Sch_type:"Government", Category:"High School"},
    { key:"17665", district:"23", Block:"272", label:"L.F.R.C HIGHER SECONDARY SCHOOL, KARAIKUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17666", district:"23", Block:"272", label:"SRI KALAIVANI VIDHYALAYA MATRIC HSS, KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17667", district:"23", Block:"272", label:"SCGR HSS AMARAVATHIPUDUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17668", district:"23", Block:"272", label:"PUMS DEVAKOTTAI SALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17669", district:"23", Block:"272", label:"GOVT HSS, ARIYAGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17670", district:"23", Block:"272", label:"INFANT JESUS MAT SCOOL ARIYAKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17671", district:"23", Block:"272", label:"GOVT HIGH SCHOOL ILUPPAIKUDI", Sch_type:"Government", Category:"High School"},
    { key:"17672", district:"23", Block:"272", label:"ALAGAPPA MATRIC HSS, KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17673", district:"23", Block:"272", label:"KENDRIYA VIDHYALAYA SCHOOL, KARAIKUDI", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"17674", district:"23", Block:"272", label:"C.A.M. HIGH SCHOOL ,KANDANUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17675", district:"23", Block:"272", label:"R.C HSS, PUDUVAYAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17676", district:"23", Block:"272", label:"SRI SARASWATHI  VIDYASALAI GIRLS HR SEC SCHOOL PUDUVAYAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17677", district:"23", Block:"272", label:"GOVT HIGH SCHOOL, SAKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17678", district:"23", Block:"272", label:"SRI KALAIMAGAL VIDYALAYA MHSS PUDHUVAYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17679", district:"23", Block:"272", label:"Shangaranandha Gurugulam (CBSE) School-Puduvayal", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17680", district:"23", Block:"272", label:"PUMS PETHATCHIKUDIIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"17681", district:"23", Block:"272", label:"GOVT HSS, PEERKALAIKADU -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17682", district:"23", Block:"272", label:"PUMS AYENIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17683", district:"23", Block:"272", label:"Pums Vilarikkadu Panampatti", Sch_type:"Government", Category:"Middle School"},
    { key:"17684", district:"23", Block:"272", label:"GOVT HIGH SCHOOL, SIRUGAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17685", district:"23", Block:"272", label:"GOVT HIGH SCHOOL, PERIYAKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17686", district:"23", Block:"272", label:"GOVT HIGH SCHOOL, JEYANKONDAN", Sch_type:"Government", Category:"High School"},
    { key:"17687", district:"23", Block:"272", label:"GOVT HIGH SCHOOL, SITHIVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"17688", district:"23", Block:"272", label:"GOVT HSS, MITHIRAVAYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17689", district:"23", Block:"272", label:"PUMS N.PALAIUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17690", district:"23", Block:"331", label:"PUMS ALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17691", district:"23", Block:"331", label:"PUMS AIYINIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17692", district:"23", Block:"331", label:"GOVT HIGH SCHOOL E SENBAGAPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"17693", district:"23", Block:"331", label:"GOVT HIGH SCHOOL SEVVOOR", Sch_type:"Government", Category:"High School"},
    { key:"17694", district:"23", Block:"331", label:"PUMS MITHILAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17695", district:"23", Block:"331", label:"PUMS K.ILAYATHANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17696", district:"23", Block:"331", label:"VIVEKANANDA KALVI KAZHAGAM  MIDDLE SCHOOL AAVINIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17697", district:"23", Block:"331", label:"SMS HSS KEELASEVALPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17698", district:"23", Block:"331", label:"RM M MAT SCHOOL KILASELVALPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17699", district:"23", Block:"331", label:"PUMS PIRAMANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17700", district:"23", Block:"331", label:"PUMS MELAIYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17701", district:"23", Block:"331", label:"SPD.MID.SCHOOL KANDAVARAYANPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17702", district:"23", Block:"331", label:"PUMS KARAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17703", district:"23", Block:"331", label:"PUMS MANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17704", district:"23", Block:"331", label:"PUMS KARUPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17705", district:"23", Block:"331", label:"PUMS DEVARAMBOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17706", district:"23", Block:"331", label:"PLAZAA  MATRICULATION  SCHOOL ,   PUDHUKATTAMBUR.", Sch_type:"Un-aided", Category:"High School"},
    { key:"17707", district:"23", Block:"331", label:"PUMS KANAKKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17708", district:"23", Block:"331", label:"PUMS V.MALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17709", district:"23", Block:"331", label:"GOVT HSS S VELANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17710", district:"23", Block:"331", label:"PUMS KONNATHANPATI", Sch_type:"Government", Category:"Middle School"},
    { key:"17711", district:"23", Block:"331", label:"GOVT HSS KOTTAIYIRUPPU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17712", district:"23", Block:"331", label:"PUMS THIRUVIDAIYARPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"17713", district:"23", Block:"331", label:"K.V.MID SCHOOL SIRUKOODALPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17714", district:"23", Block:"331", label:"PUMS VANCHINIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17715", district:"23", Block:"331", label:"GOVT HIGH SCHOOL MAHIBALANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17716", district:"23", Block:"331", label:"PUMS NEDUMARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17717", district:"23", Block:"331", label:"VT GIRLS HIGH SCHOOL A.THEKKUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17718", district:"23", Block:"331", label:"SVK HSS A THEKKUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17719", district:"23", Block:"331", label:"GOVT HIGH SCHOOL THIRUKKALAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17720", district:"23", Block:"331", label:"PUMS CHAMANTHANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17721", district:"23", Block:"331", label:"GOVT HSS THIRUKKOSTIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17722", district:"23", Block:"331", label:"PUMS THUVAR", Sch_type:"Government", Category:"Middle School"},
    { key:"17723", district:"23", Block:"331", label:"SSARAVANA MATRIC SCHOOL THUVAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"17724", district:"23", Block:"331", label:"PUMS M.PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17725", district:"23", Block:"331", label:"PUMS VANIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17726", district:"23", Block:"331", label:"PUMS SEVINIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17727", district:"23", Block:"331", label:"TM GOVT HSS POOLANKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17728", district:"23", Block:"331", label:"AVM Public Matriculation Higher Secondary School,Sevvoor", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17729", district:"23", Block:"331", label:"PUMS SUNNAMBIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"17730", district:"23", Block:"331", label:"PUMS MARKKANDEYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17731", district:"23", Block:"331", label:"PUMS CHUNDAKKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"17732", district:"23", Block:"331", label:"PUMS K.VAIRAVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17733", district:"23", Block:"331", label:"SATHAPPA GOVT HSS NERKKUPPAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17734", district:"23", Block:"331", label:"PUMS N.VADUGAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17735", district:"23", Block:"331", label:"GOVT HIGH SCHOOL T PUTHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17736", district:"23", Block:"331", label:"LIMRA MATRICULATION HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17737", district:"23", Block:"331", label:"N.M.GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ,THIRUPPATHUR.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17738", district:"23", Block:"331", label:"CHRISTHURAJA MATRIC HIGHER SECONDARY SCHOOL K.VAIRAVANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17739", district:"23", Block:"331", label:"MUSLIM PULAVAR MID SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17740", district:"23", Block:"331", label:"R.C.FATHIMA MIDDLE SCHOOL , THIRUPPATHUR. .", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17741", district:"23", Block:"331", label:"BABA AMIR BADHUSHA MATRIC HR SEC SCHOOL, THIRUPPATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17742", district:"23", Block:"331", label:"TELC TRANSATIONAL MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17743", district:"23", Block:"331", label:"A.P.GOVT.(B)HR.SEC.SCHOOL THIRUPPATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17744", district:"23", Block:"331", label:"ARUMUGAM PILLAI SEETHAI AMMAL MATRIC  HR SEC SCHOOL, THIRUPPATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17745", district:"23", Block:"331", label:"TELC BLIND MIDDLE SCHOOL TPR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17746", district:"23", Block:"331", label:"GOVT HIGH SCHOOL THENMAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"17747", district:"23", Block:"331", label:"INDIRAGANDHI MATRICULATION SCHOOL THENMAPATTU", Sch_type:"Un-aided", Category:"High School"},
    { key:"17748", district:"23", Block:"292", label:"PUMS VENGAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17749", district:"23", Block:"292", label:"GOVT BOYS HSS SINGAMPUNARI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17750", district:"23", Block:"292", label:"RM  RM GOVT GIRLS HR SEC  SCHOOL SINGAMPUNARI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17751", district:"23", Block:"292", label:"GOVT BOYS HIGH SCHOOL A KALAPPUR", Sch_type:"Government", Category:"High School"},
    { key:"17752", district:"23", Block:"292", label:"AVM MATRIC HSS SINGAMPUNARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17753", district:"23", Block:"292", label:"GOVT GIRLS HIGH SCHOOL A KALAPPUR", Sch_type:"Government", Category:"High School"},
    { key:"17754", district:"23", Block:"292", label:"SRI SEVUGAMOORTHY MATRIC SCHOOL, SINGAMPUNARI", Sch_type:"Un-aided", Category:"High School"},
    { key:"17755", district:"23", Block:"292", label:"PUMS KAPPARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17756", district:"23", Block:"292", label:"PUMS VETTAIANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17757", district:"23", Block:"292", label:"PV MATRIC HSS SINGAMPUNARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17758", district:"23", Block:"292", label:"GOVT HIGH SCHOOL ANIAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17759", district:"23", Block:"292", label:"PUMS SIRUMARUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17760", district:"23", Block:"292", label:"GOVT HIGH SCHOOL CHELLIAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17761", district:"23", Block:"292", label:"PUMS MARUDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17762", district:"23", Block:"292", label:"GOVT HSS ERIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17763", district:"23", Block:"292", label:"PUMS S.MAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17764", district:"23", Block:"292", label:"PUMS MELAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17765", district:"23", Block:"292", label:"VALLAL PARI HSS PIRANMALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17766", district:"23", Block:"292", label:"GOVT HIGH SCHOOL MALLAKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17767", district:"23", Block:"292", label:"PUMS S KOVILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17768", district:"23", Block:"292", label:"GOVT HIGH SCHOOL M SOORAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"17769", district:"23", Block:"292", label:"GOVT HSS SS KOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17770", district:"23", Block:"292", label:"PUMS VADAVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17771", district:"23", Block:"292", label:"GOVT HIGHER SECONDARY SCHOOL KIRUNGAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17772", district:"23", Block:"292", label:"PUMS ERUMAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17773", district:"23", Block:"292", label:"PUMS JEYANKONDANELAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17774", district:"23", Block:"292", label:"GOVT HIGH SCHOOL ARALIKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17775", district:"23", Block:"292", label:"PUMS KALLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17776", district:"23", Block:"292", label:"GOVT HSS A MURAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17777", district:"23", Block:"292", label:"PUMS S.SEVALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17778", district:"23", Block:"271", label:"PUMS ARIYANDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17779", district:"23", Block:"271", label:"RMSA MODEL HSS, KATTUKUDIPATTI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17780", district:"23", Block:"271", label:"PUMS KURUMBALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17781", district:"23", Block:"271", label:"PUMS DHARMAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17782", district:"23", Block:"271", label:"PUMS NAGAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17783", district:"23", Block:"271", label:"GOVT HR SEC SCHOOL MUSUNDAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17784", district:"23", Block:"271", label:"GOVT HSS  KARISALPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17785", district:"23", Block:"271", label:"PUMS URATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17786", district:"23", Block:"271", label:"PUMS MELAVANNARIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"17787", district:"23", Block:"271", label:"GOVT HSS KATTUKUDIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17788", district:"23", Block:"271", label:"GOVT HSS PULUTHIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17789", district:"23", Block:"271", label:"GOVT HSS ULAGAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17790", district:"23", Block:"271", label:"KGBV ULAGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17791", district:"23", Block:"271", label:"GOVT HS VALASAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17792", district:"23", Block:"271", label:"GOVT HSS V.PUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17793", district:"23", Block:"96", label:"GOVT GIRLS HS KALLAL", Sch_type:"Government", Category:"High School"},
    { key:"17794", district:"23", Block:"96", label:"KALLAL MURUGAPPA  HR SEC SCHOOL, KALLAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17795", district:"23", Block:"96", label:"SHANTHI RANI  MATRIC HR SEC SCHOOL, KALLAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17796", district:"23", Block:"96", label:"BRITTO HR.SEC.SCHOOL, KALLAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17797", district:"23", Block:"96", label:"NEHRU.MIDDLE.SCHOOL, ATHANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17798", district:"23", Block:"96", label:"GOVT HS ALANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"17799", district:"23", Block:"96", label:"TAGOREMIDDLE.SCHOOL,A.SIRUVAYAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17800", district:"23", Block:"96", label:"ROMANCATHALICKMIDDLESCHOOL, SATHARASANPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17801", district:"23", Block:"96", label:"PUMS, KALIPULI", Sch_type:"Government", Category:"Middle School"},
    { key:"17802", district:"23", Block:"96", label:"PUMS, KOOTHAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17803", district:"23", Block:"96", label:"SETHU  IRANI MATRIC HR SEC  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17804", district:"23", Block:"96", label:"T.R.V.A.HIGH SCHOOL ,PATTAMANGALAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17805", district:"23", Block:"96", label:"D.K.G.HR.SEC..SCH,KUNDRAKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17806", district:"23", Block:"96", label:"PUMS.KURUNTHAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"17807", district:"23", Block:"96", label:"ST.SEBASTIAN'S HIGH SCHOOL - KUTHALUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17808", district:"23", Block:"96", label:"PUMS, PADATHANPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"17809", district:"23", Block:"96", label:"PUMS, PEYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17810", district:"23", Block:"96", label:"GOVT HS KOVILUR", Sch_type:"Government", Category:"High School"},
    { key:"17811", district:"23", Block:"96", label:"R C M HS NADARAJAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17812", district:"23", Block:"96", label:"J V HS NACHIAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17813", district:"23", Block:"96", label:"PUMS, N.VAIRAVANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17814", district:"23", Block:"96", label:"RC MIDDLE SCHOOL, PANANGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17815", district:"23", Block:"96", label:"PUMS, SEVARAKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17816", district:"23", Block:"96", label:"PUMS, S.R.PATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17817", district:"23", Block:"96", label:"M.K.GHSS, SEMBANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17818", district:"23", Block:"96", label:"SOMASUNDARAM MIDDLE SCHOOL,SIRAVAYAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17819", district:"23", Block:"96", label:"ADW.GOVT.HIGH.SCHOOL, ATHIKARAM", Sch_type:"Government", Category:"High School"},
    { key:"17820", district:"23", Block:"96", label:"PUMS, THALAKAVOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17821", district:"23", Block:"96", label:"L.A.R.MID.SCHOOL,MANAGIRI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17822", district:"23", Block:"96", label:"GOVT MODEL HIGHER SECONDARY SCHOOL VISALAYANKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17823", district:"23", Block:"96", label:"PUMS, VEPPANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17824", district:"23", Block:"96", label:"GOVT HSS VETRIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17825", district:"23", Block:"96", label:"SRI DHANDAYUTHAPANI HS KANDRAMANICKAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17826", district:"23", Block:"96", label:"M.A.P.G.HIGH.SCHOOL,KANDRAMANICKAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"17827", district:"23", Block:"96", label:"PUMS, K.VALAIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17828", district:"14", Block:"4", label:"PANCHAYAT UNION MIDDLE SCHOOL, SEMBUKUDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17829", district:"14", Block:"4", label:"GOVT HR SEC SCHOOL, T.AYYANKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17830", district:"14", Block:"4", label:"GOVT HR SEC SCHOOL, KONDAYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17831", district:"14", Block:"4", label:"PUMS KOIL KUTTI NAYAKKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17832", district:"14", Block:"4", label:"PUMS ALAGAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"17833", district:"14", Block:"4", label:"PUMS A PUDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17834", district:"14", Block:"4", label:"PUMS KURAVANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17835", district:"14", Block:"4", label:"GOVT BOYS HR SEC SCHOOL, ALANGANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17836", district:"14", Block:"4", label:"GOVT GIRLS HR SEC SCHOOL, ALANGANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17837", district:"14", Block:"4", label:"PUMS KOTTAIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"17838", district:"14", Block:"4", label:"GOVT HIGH SCHOOL, MANIYANJI", Sch_type:"Government", Category:"High School"},
    { key:"17839", district:"14", Block:"4", label:"PUMS THANDALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"17840", district:"14", Block:"4", label:"GOVT ADW HR SEC SCHOOL, ACHAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17841", district:"14", Block:"4", label:"PANCHAYAT UNION MIDDLE SCHOOL, VAVIDAMARUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17842", district:"14", Block:"4", label:"NATIONAL CO-OP SUGAR MILLS HR SEC SCHOOL, 15B METTUPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17843", district:"14", Block:"4", label:"PUMS URSERI", Sch_type:"Government", Category:"Middle School"},
    { key:"17844", district:"14", Block:"4", label:"GOVT HIGH SCHOOL, ERRAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17845", district:"14", Block:"4", label:"GOVT HR SEC SCHOOL, MUDUVARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17846", district:"14", Block:"4", label:"PUMS MANICKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17847", district:"14", Block:"4", label:"GOVT HR SEC SCHOOL, VELLAIYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17848", district:"14", Block:"4", label:"PUMS SARANTHANGI", Sch_type:"Government", Category:"Middle School"},
    { key:"17849", district:"14", Block:"4", label:"PUMS KODANGIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17850", district:"14", Block:"4", label:"PUMS PONDUGAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17851", district:"14", Block:"4", label:"GOVT HIGH SCHOOL, SENTHAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"17852", district:"14", Block:"4", label:"GOVT HIGH SCHOOL, CHATRAVALLALAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17853", district:"14", Block:"4", label:"GOVT MODEL HR SEC SCHOOL, PALAMEDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17854", district:"14", Block:"4", label:"P.H.N.U.S.P PATHIRAKALIAMMAN MATRIC HR SEC SCHOOL, PALAMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17855", district:"14", Block:"4", label:"PUMS THETHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"17856", district:"14", Block:"4", label:"PUMS T.KOZHINJIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17857", district:"14", Block:"4", label:"GOVT HR SEC SCHOOL, T METTUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17858", district:"14", Block:"4", label:"PUMS RAMAGOUNDANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17859", district:"14", Block:"4", label:"PUMS VALAYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17860", district:"14", Block:"4", label:"PUMS DEVASERI", Sch_type:"Government", Category:"Middle School"},
    { key:"17861", district:"14", Block:"43", label:"PANCHAYAT UNION MIDDLE SCHOOL PANAMOOPPANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17862", district:"14", Block:"43", label:"GOVT HIGH SCHOOL, CHAKKARAPPANAYAKKANUR", Sch_type:"Government", Category:"High School"},
    { key:"17863", district:"14", Block:"43", label:"GOVT KALLAR HR SEC SCHOOL, VIKKIRAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17864", district:"14", Block:"43", label:"ST.JOSEPH MATRIC HR SEC SCHOOL, VIKKIRAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17865", district:"14", Block:"43", label:"PANCHAYAT UNION MIDDLE SCHOOL NADUMUDALAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17866", district:"14", Block:"43", label:"GOVT.KALLAR.M.S. PANNIYAN", Sch_type:"Government", Category:"Middle School"},
    { key:"17867", district:"14", Block:"43", label:"ST CLARET'S HIGHER SECONDARY SCHOOL  KARUMATHUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17868", district:"14", Block:"43", label:"GOVT KALLAR HR SEC SCHOOL, VADAKKAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17869", district:"14", Block:"43", label:"GOVT HIGH SCHOOL, CHELLAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17870", district:"14", Block:"43", label:"FATIMA CONVENT MATRIC HIGH SCHOOL, CHELLAMPATTI ", Sch_type:"Un-aided", Category:"High School"},
    { key:"17871", district:"14", Block:"43", label:"GOVT KALLAR HR. SEC. SCHOOL, KOVILANGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17872", district:"14", Block:"43", label:"P.U.M.S JOTHIMANICKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17873", district:"14", Block:"43", label:"P.U.M.S. VALANDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17874", district:"14", Block:"43", label:"P.U.M.S. KURAVAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17875", district:"14", Block:"43", label:"P.U.M.S. K.NATTAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17876", district:"14", Block:"43", label:"GOVT KALLAR HR SEC SCHOOL, PAPPAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17877", district:"14", Block:"43", label:"GOVT.K.M.S KATTAKARUPPANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17878", district:"14", Block:"43", label:"P.U.M.S. K.SEMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17879", district:"14", Block:"43", label:"GOVT.KALLAR.M.S. KUPPANAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17880", district:"14", Block:"43", label:"GOVT KALLAR HR SEC SCHOOL, NATTAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17881", district:"14", Block:"43", label:"NATANESHWER MATRICULATION SCHOOL, NATTAMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"17882", district:"14", Block:"43", label:"GOVT.KALLAR.M.S. AMBATAYAMPATI", Sch_type:"Government", Category:"Middle School"},
    { key:"17883", district:"14", Block:"43", label:"P.U.M.S. SINDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17884", district:"14", Block:"43", label:"GOVT KALLAR HR SEC SCHOOL, THUMMAKUNDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17885", district:"14", Block:"43", label:"GOVT KALLAR HR SEC SCHOOL, V.KALLAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17886", district:"14", Block:"97", label:"GOVT HR SEC SCHOOL, SENGAPADAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17887", district:"14", Block:"97", label:"P.U.M.S KARISALKALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17888", district:"14", Block:"97", label:"P.U.M.S ULAGANI", Sch_type:"Government", Category:"Middle School"},
    { key:"17889", district:"14", Block:"97", label:"KOODAKOVIL NADARS HR SEC SCHOOL, KOODAKOVIL ", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17890", district:"14", Block:"97", label:"P.U.M.S THUMBAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17891", district:"14", Block:"97", label:"GOVT HIGH SCHOOL, T. PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"17892", district:"14", Block:"97", label:"P.U.M.S. VALAYANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17893", district:"14", Block:"97", label:"GOVT HIGH SCHOOL, T.ARASAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17894", district:"14", Block:"97", label:"GOVT HIGH SCHOOL, SIVARAKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"17895", district:"14", Block:"97", label:"GOVT HR SEC SCHOOL, VILLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17896", district:"14", Block:"97", label:"P.U.M.S. MARAVAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17897", district:"14", Block:"97", label:"GOVT HR SEC SCHOOL, KALLIKUDI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17898", district:"14", Block:"97", label:"P.U.M.S AGATHAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17899", district:"14", Block:"97", label:"P.U.M.S. LALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17900", district:"14", Block:"97", label:"P.U.M.S. THENAMMANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17901", district:"14", Block:"97", label:"GOVT HR SEC SCHOOL, M PULIANKULAM                                 ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17902", district:"14", Block:"97", label:"P.U.M.S M.PUDUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17903", district:"14", Block:"97", label:"P.U.M.S. CHITHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17904", district:"14", Block:"97", label:"P.U.M.S. MAIYTTAN PATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17905", district:"14", Block:"97", label:"P.U.M.S. AVALSOORAM PATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17906", district:"14", Block:"97", label:"P.U.M.S K.UNNIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17907", district:"14", Block:"97", label:"GOVT HR SEC SCHOOL, K.VELLAKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17908", district:"14", Block:"97", label:"P.U.M.S MELAUPPILIGUNDU", Sch_type:"Government", Category:"Middle School"},
    { key:"17909", district:"14", Block:"97", label:"P.U.M.S T.KOKKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17910", district:"14", Block:"97", label:"GOVT HIGH SCHOOL, VEERAPERMUALPURAM", Sch_type:"Government", Category:"High School"},
    { key:"17911", district:"14", Block:"97", label:"PANCHAYAT UNION MIDDLE SCHOOL PEIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17912", district:"14", Block:"97", label:"HINDU NADAR HIGH SCHOOL, K SENNAMPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"17913", district:"14", Block:"97", label:"P.U.M.S MELAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17914", district:"14", Block:"97", label:"P.U.M.S S.P.NATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17915", district:"14", Block:"97", label:"P.U.M.S. MOCHIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17916", district:"14", Block:"97", label:"P.U.M.S. KURAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17917", district:"14", Block:"97", label:"GOVT HIGH SCHOOL, THIRUMAL", Sch_type:"Government", Category:"High School"},
    { key:"17918", district:"14", Block:"97", label:"GOVT HIGH SCHOOL, MARUTHANGUDI                                  ", Sch_type:"Government", Category:"High School"},
    { key:"17919", district:"14", Block:"97", label:"P.U.M.S S.VELLAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17920", district:"14", Block:"303", label:"GOVT HIGH SCHOOL, APPAKARAI", Sch_type:"Government", Category:"High School"},
    { key:"17921", district:"14", Block:"303", label:"GOVT HIGH SCHOOL, A.THOTTIAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17922", district:"14", Block:"303", label:"GOVT MODEL HR SEC SCHOOL, T.KALLUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17923", district:"14", Block:"303", label:"GANDHINIKETAN G VENKATACHALAPATHY HR SEC SCHOOL, T.KALLUPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17924", district:"14", Block:"303", label:"GOVT HIGH SCHOOL, VANNIVELAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17925", district:"14", Block:"303", label:"GOVT HR SEC SCHOOL, T.KUNNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17926", district:"14", Block:"303", label:"P.U.M.S  PULIAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17927", district:"14", Block:"303", label:"P.U.M.S  KARAIKENI", Sch_type:"Government", Category:"Middle School"},
    { key:"17928", district:"14", Block:"303", label:"PANCHAYAT UNION MIDDLE SCHOOL, SOLAIPATTI L", Sch_type:"Government", Category:"Middle School"},
    { key:"17929", district:"14", Block:"303", label:"PANCHAYAT UNION MIDDLE SCHOOL AMMAPATTI V", Sch_type:"Government", Category:"Middle School"},
    { key:"17930", district:"14", Block:"303", label:"PANCHAYAT UNION MIDDLE SCHOOL, THOTTIAPATTI P", Sch_type:"Government", Category:"Middle School"},
    { key:"17931", district:"14", Block:"303", label:"P.U.M.S CHINNAPOOLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17932", district:"14", Block:"303", label:"SRI KANDASAMY VIDHYALAYAM HR SEC SCHOOL, PAPPUNAYAKKANPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"17933", district:"14", Block:"303", label:"GOVT. ADW MIDDLE SCHOOL, S.KEELAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17934", district:"14", Block:"303", label:"P.U.M.S S.PARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17935", district:"14", Block:"303", label:"SRI M K V SALA HIGHER SECONDARY SCHOOL  MELAPATTI S", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17936", district:"14", Block:"303", label:"P.U.M.S  KOOVALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17937", district:"14", Block:"303", label:"P.U.M.S. THUMANAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17938", district:"14", Block:"303", label:"P.U.M.S  KUMARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17939", district:"14", Block:"303", label:"P.U.M.S SILAIMALAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17940", district:"14", Block:"303", label:"GOVT HR SEC SCHOOL, M.SUBBULAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17941", district:"14", Block:"303", label:"P.U.M.S CHINNACHITTULOTTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17942", district:"14", Block:"303", label:"P.U.M.S MURUGANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"17943", district:"14", Block:"303", label:"SRI V.K.V SALA M.S GOPINAYAKANPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"17944", district:"14", Block:"303", label:"GOVT HIGH SCHOOL, GOPINAYAKKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17945", district:"14", Block:"303", label:"ADI.M.S  CHOKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17946", district:"14", Block:"303", label:"GOVT GIRLS HR SEC SCHOOL, PERAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17947", district:"14", Block:"303", label:"GANDHIJI MEMORIAL GOVT BOYS HR SEC SCHOOL, PERAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17948", district:"14", Block:"136", label:"GOVT HIGH SCHOOL, SOORAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17949", district:"14", Block:"136", label:"GOVT HIGH SCHOOL, PALLAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17950", district:"14", Block:"136", label:"GOVT HR SEC SCHOOL, POTTAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17951", district:"14", Block:"136", label:"P.U.M.S THONTHILINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17952", district:"14", Block:"136", label:"GOVT HIGH SCHOOL, SOKKAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17953", district:"14", Block:"136", label:"P.U.M.S MANAPPACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"17954", district:"14", Block:"136", label:"P.U.M.S M.VELLALAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17955", district:"14", Block:"136", label:"P.U.M.S KUNNARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17956", district:"14", Block:"136", label:"GOVT HR SEC SCHOOL, MANGALAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17957", district:"14", Block:"136", label:"PANCHAYAT UNION MIDDLE SCHOOL, OTTAKKOVILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17958", district:"14", Block:"136", label:"P.U.M.S S.MALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17959", district:"14", Block:"136", label:"GOVT GIRLS HR SEC SCHOOL, KOTTAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17960", district:"14", Block:"136", label:"GOVT HR SEC SCHOOL, KOTTAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17961", district:"14", Block:"136", label:"GOVT HR SEC SCHOOL, CHOKKALINGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17962", district:"14", Block:"136", label:"P.U.M.S MANALMETUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17963", district:"14", Block:"136", label:"GOVT GIRLS HR SC SCHOOL, KARUNGALAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17964", district:"14", Block:"136", label:"GOVERNMENT Hr. Sec. SCHOOL, KARUNGALAKUDI, Madurai Dist.", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17965", district:"14", Block:"136", label:"P.U.M.S VANJINAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17966", district:"14", Block:"136", label:"GOVT HIGH SCHOOL, MANAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17967", district:"14", Block:"136", label:"GOVT HR SEC SCHOOL, THUMBAIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17968", district:"14", Block:"136", label:"P.U.M.S THAMARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17969", district:"14", Block:"136", label:"P.U.M.S BOOTHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17970", district:"14", Block:"136", label:"P.U.M.S ATTAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17971", district:"14", Block:"136", label:"GOVT HIGH SCHOOL, KODUKKAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17972", district:"14", Block:"136", label:"P.U.M.S ETTIMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17973", district:"14", Block:"136", label:"P.U.M.S SENNAGARAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17974", district:"14", Block:"136", label:"GOVT HR SEC SCHOOL, MELAVALAVU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17975", district:"14", Block:"136", label:"P.U.M.S PATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17976", district:"14", Block:"136", label:"GOVT HR SEC SCHOOL, KAMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17977", district:"14", Block:"136", label:"GOVT HR SEC SCHOOL, SEKKIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"17978", district:"14", Block:"158", label:"ADI.D.M.S CHEMPIANENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"17979", district:"14", Block:"158", label:"P.U.M.S ARUMBANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17980", district:"14", Block:"158", label:"PANCHAYAT UNION MIDDLE SCHOOL, NAYAKKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17981", district:"14", Block:"158", label:"P.U.M.S CHINNA MANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17982", district:"14", Block:"158", label:"GOVT HIGH SCHOOL, MANGULAM", Sch_type:"Government", Category:"High School"},
    { key:"17983", district:"14", Block:"158", label:"GOVT ADW HIGH SCHOOL, KURUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"17984", district:"14", Block:"158", label:"P.U.M.S. KALLANTHIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"17985", district:"14", Block:"158", label:"GOVT HIGH SCHOOL, MATHUR", Sch_type:"Government", Category:"High School"},
    { key:"17986", district:"14", Block:"158", label:"GOVT HIGH SCHOOL, MAYANDIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"17987", district:"14", Block:"158", label:"P.U.M.S. CHITTAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"17988", district:"14", Block:"158", label:"P.U.M.S. KODIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"17989", district:"14", Block:"158", label:"P.U.M.S. MANGALAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17990", district:"14", Block:"158", label:"P.U.M.S. UTHANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"17991", district:"14", Block:"158", label:"GOVT HIGH SCHOOL, VANDIYUR", Sch_type:"Government", Category:"High School"},
    { key:"17992", district:"14", Block:"158", label:"PANCHAYAT UNION MIDDLE SCHOOL, THIRUMOHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"17993", district:"14", Block:"158", label:"T.V.S. LAKSHMI SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17994", district:"14", Block:"158", label:"P.U.M.S KARUPPAYURANI", Sch_type:"Government", Category:"Middle School"},
    { key:"17995", district:"14", Block:"158", label:"APPAR HR SEC SCHOOL, KARUPPAYURANI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"17996", district:"14", Block:"158", label:"P.U.M.S. KABIR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"17997", district:"14", Block:"158", label:"LIONS MATRIC HR SEC SCHOOL,  KARUPPAYURANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"17998", district:"14", Block:"158", label:"GOVT HIGH SCHOOL, ANDARKOTTARAM", Sch_type:"Government", Category:"High School"},
    { key:"17999", district:"14", Block:"158", label:"GOVT HIGH SCHOOL, VARICHIYUR", Sch_type:"Government", Category:"High School"},
    { key:"18000", district:"14", Block:"158", label:"GOVT ADW HR SEC SCHOOL, ELAMANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18001", district:"14", Block:"158", label:"P.U.M.S KONDAPETHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"18002", district:"14", Block:"158", label:"P.U.M.S. L.K.B.NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18003", district:"14", Block:"158", label:"GOVT HIGH SCHOOL. SAKKIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"18004", district:"14", Block:"158", label:"P.U.M.S SAKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18005", district:"14", Block:"158", label:"P.U.M.S. KARSERI", Sch_type:"Government", Category:"Middle School"},
    { key:"18006", district:"14", Block:"158", label:"P.U.M.S.KUNNATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18007", district:"14", Block:"158", label:"GOVT HR SEC SCHOOL, KALIMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18008", district:"14", Block:"158", label:"FES.MATRICULATION SCHOOL-GOMATHIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18009", district:"14", Block:"158", label:"GOVT. MODEL GIRLS HR SEC SCHOOL, Y.OTHAKKADAI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18010", district:"14", Block:"158", label:"GOVT BOYS HR SEC SCHOOL, Y.OTHAKKADAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18011", district:"14", Block:"158", label:"Y.O N.U.M.S OTHAKADAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18012", district:"14", Block:"158", label:"RAGHAV.PUBLIC MATRICULATION SCHOOL-Y.OTHAKKADAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18013", district:"14", Block:"158", label:"MAHARISHI VIDYA MANDIR MATRIC  SCHOOL, K K NAGAR ", Sch_type:"Un-aided", Category:"High School"},
    { key:"18014", district:"14", Block:"158", label:"COR.M.S SATHAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18015", district:"14", Block:"158", label:"THANAM MIDDLE SCHOOL, GANDHI NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18016", district:"14", Block:"158", label:"AMBIKA MATRIC HR SEC SCHOOL, ANNA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18017", district:"14", Block:"158", label:"CORPORATION ELANGO HR SEC SCHOOL, SHENOY NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18018", district:"14", Block:"158", label:"TALLAKULAM SENGUNTHAR URAVINMURAI HIGH SCHOOL, TALLAKULAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18019", district:"14", Block:"158", label:"AMERICAN COLLEGE HR SEC SCHOOL, TALLAKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18020", district:"14", Block:"158", label:"CORPORATION HIGH SCHOOL, TALLAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18021", district:"14", Block:"158", label:"NEHRU VIDYASALAI HR SEC SCHOOL, VETHAVALLI NAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18022", district:"14", Block:"158", label:"KATIE WILCOX MATRIC HR SEC SCHOOL, NARIMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18023", district:"14", Block:"158", label:"O.C.P.M GIRLS HR SEC SCHOOL, TALLAKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18024", district:"14", Block:"158", label:"SAIRAM MATRIC HR SEC SCHOOL, GORIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18025", district:"14", Block:"158", label:"CORPORATION PONMUDIYAR GIRLS HR SEC SCHOOL, GORIPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18026", district:"14", Block:"161", label:"PANCHAYAT UNION MIDDLE SCHOOL, ATHALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18027", district:"14", Block:"161", label:"P.U.M.S. ALATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18028", district:"14", Block:"161", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18029", district:"14", Block:"161", label:"GOVT HR SEC SCHOOL, M.CHATRAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18030", district:"14", Block:"161", label:"MADURAI CRESCENT MATRIC HR SEC SCHOOL, KALLAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18031", district:"14", Block:"161", label:"DON BOSCO HR SEC SCHOOL, THIRUMALPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18032", district:"14", Block:"161", label:"AUXILIUM MATRIC SCHOOL, THIRUMALPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18033", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, OOMACHIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18034", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, CHINNAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18035", district:"14", Block:"161", label:"IAB (BLIND) HR SEC SCHOOL, SUNDARARAJANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18036", district:"14", Block:"161", label:"PANCHAYAT UNION MIDDLE SCHOOL, KANNANENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"18037", district:"14", Block:"161", label:"MADONNA MATRICULATION HIGHER SECONDARY SCHOOL, KANNANENTHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18038", district:"14", Block:"161", label:"SRI GOPALAKRISHNA HIGHER SECONDARY SCHOOL,SIRUTHUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18039", district:"14", Block:"161", label:"AUXILIUM  HIGHER SECONDARY SCHOOL, SIRUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18040", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, KARUVANOOR", Sch_type:"Government", Category:"High School"},
    { key:"18041", district:"14", Block:"161", label:"P.U.M.S. SIKKANTHAR SAVADI", Sch_type:"Government", Category:"Middle School"},
    { key:"18042", district:"14", Block:"161", label:"P.U.M.S. KOILPAPPAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18043", district:"14", Block:"161", label:"M.P.R. PUBLIC HIGHER SECONDARY SCHOOL-KAVANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18044", district:"14", Block:"161", label:"GOVT HR SEC SCHOOL, KULAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18045", district:"14", Block:"161", label:"GOVT ADW HIGH SCHOOL, ELUMBUR", Sch_type:"Government", Category:"High School"},
    { key:"18046", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, MANJAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18047", district:"14", Block:"161", label:"PSYECHI MAT.H.S ATHIKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18048", district:"14", Block:"161", label:"GOVT HR SEC SCHOOL, POTHUMBU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18049", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, SIRUVALAI", Sch_type:"Government", Category:"High School"},
    { key:"18050", district:"14", Block:"161", label:"P.U.M.S.  THENUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18051", district:"14", Block:"161", label:"P.U.M.S. NARAYANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18052", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, THIRUPPALAI", Sch_type:"Government", Category:"High School"},
    { key:"18053", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, NAGANAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18054", district:"14", Block:"161", label:"PANCHAYAT UNION MIDDLE SCHOOL, PERIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18055", district:"14", Block:"161", label:"SRI RAM N.M.P.S KACHARAMPETTAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18056", district:"14", Block:"161", label:"SRIRAM NALLAMANI YADAVA GIRLS HR SEC SCHOOL,THIRUPPALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18057", district:"14", Block:"161", label:"P.U.M.S. VAIRAVANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18058", district:"14", Block:"161", label:"P.U.M.S. SAMBAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18059", district:"14", Block:"161", label:"P.U.M.S. VELICHANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18060", district:"14", Block:"161", label:"P.U.M.S. MUDAKATHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"18061", district:"14", Block:"161", label:"Sree Maha Vidhyalayam Matric School, Anaiyur", Sch_type:"Un-aided", Category:"High School"},
    { key:"18062", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, ANAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"18063", district:"14", Block:"161", label:"P.U.M.S MILAGARANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18064", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, MALAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18065", district:"14", Block:"161", label:"THE NALLAMANI MATRIC Hr. Sec. SCHOOL, SAMAYANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18066", district:"14", Block:"161", label:"GOVT HR SEC SCHOOL, SAMAYANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18067", district:"14", Block:"161", label:"ST.JOSEPH HIGH SCHOOL, SAMAYANALLUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18068", district:"14", Block:"161", label:"GOVT HR SEC SCHOOL, PARAVAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18069", district:"14", Block:"161", label:"ST.JOSEPH'S SCHOOL FOR THE BLIND,  PARAVAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18070", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, SATHIYAMOORTHY NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"18071", district:"14", Block:"161", label:"SREE MAHA MATRIC HR SEC SCHOOL, VAITHIYANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18072", district:"14", Block:"161", label:"ADHYAPANA CBSE HS, VILANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18073", district:"14", Block:"161", label:"SRI RAMANA MAHARISHI MATRICULATION SCHOOL-KOODALNAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18074", district:"14", Block:"161", label:"ALLWIN MATRIC HR SEC SCHOOL, KOODAL NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18075", district:"14", Block:"161", label:"SREE VISALAKSHI MILLS HIGH SCHOOL, VISALAKSHI NAGAR                                  ", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18076", district:"14", Block:"161", label:"ST.ANT.M.S KOODALNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18077", district:"14", Block:"161", label:"ST.ANTONY'S GIRLS HR SEC SCHOOL, KOODAL NAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18078", district:"14", Block:"161", label:"KESWICK PUBLIC SCHOOL, KOODALNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18079", district:"14", Block:"161", label:"PANCHAYAT UNION MIDDLE SCHOOL, VILANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18080", district:"14", Block:"161", label:"SHANTHI VID.M.S  PALLAR PUDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18081", district:"14", Block:"161", label:"DHANAPAUL HR SEC SCHOOL, MULLAINAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18082", district:"14", Block:"161", label:"R.C MIDDLE SCHOOL, K.PUDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18083", district:"14", Block:"161", label:"AL-AMEEN HR SEC SCHOOL, K.PUDUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18084", district:"14", Block:"161", label:"PHOENIX MATRIC HR SEC SCHOOL, K PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18085", district:"14", Block:"161", label:"SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, SURYA NAGAR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18086", district:"14", Block:"161", label:"LOURDU ANNAI GIRLS HR SEC SCHOOL, K.PUDUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18087", district:"14", Block:"161", label:"DE NOBILI MATRIC HR SEC SCHOOL, K PUDUR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18088", district:"14", Block:"161", label:"MAHATMA MONTESSORI MATRIC HR SEC.SCHOOL, BABA BUILDING, SURVEYOR COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18089", district:"14", Block:"161", label:"MAHATMA MONT CBSE K.PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18090", district:"14", Block:"161", label:"BALMANDIRAM HR SEC SCHOOL, MADURAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18091", district:"14", Block:"161", label:"Y.M.C.A KAMAK Hr. Sec. SCHOOL FOR DEAF, VISHALAKSHIPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18092", district:"14", Block:"161", label:"SEVENTHDAY MATRIC HR SEC SCHOOL, CHINNACHOKKIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18093", district:"14", Block:"161", label:"GURUKUL MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18094", district:"14", Block:"161", label:"JOTHI HIGHER SECONDARY SCHOOL, NARIMEDU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18095", district:"14", Block:"161", label:"CORPORATION KAKKAIPADINIYAR GIRLS HR SEC SCHOOL, CHOKKIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18096", district:"14", Block:"161", label:"Y.W.C.A MAT.HR.S.S VALLABAI RD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18097", district:"14", Block:"161", label:"KENDRA VIDYALAYA HIGHER SECONDARY SCHOOL, NARIMEDU MADURAI 2", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"18098", district:"14", Block:"161", label:"COR.M.S KRISHNAPURAM COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"18099", district:"14", Block:"161", label:"DHANAPAL M.S B.B KULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18100", district:"14", Block:"161", label:"CORPORATION PANDIAN NEDUNCHEZHIAN  HR SEC SCHOOL, AR LINE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18101", district:"14", Block:"161", label:"CORPORATION BHARATHIAR HIGH SCHOOL, SELLUR", Sch_type:"Government", Category:"High School"},
    { key:"18102", district:"14", Block:"161", label:"RAJAJI M.S MEENAMBALPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18103", district:"14", Block:"161", label:"THIRU VI KA CORPORATION HR SEC SCHOOL, THATHANERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18104", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL,KARAIYIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18105", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, E.MALAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18106", district:"14", Block:"183", label:"GOVT HIGH SCHOOL, PAPPAKUDIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18107", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEELAVALAVU", Sch_type:"Government", Category:"Middle School"},
    { key:"18108", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, SARUGUVALAYAPATTI                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18109", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, VADAKKUVALAIYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18110", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, NAVINIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18111", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, PATHINETTANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18112", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, THERKKUTHERU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18113", district:"14", Block:"183", label:"SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, CHINNASURAKKUNDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18114", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, A.VALLALAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18115", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, KIDARIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18116", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, ALAGAPURI", Sch_type:"Government", Category:"Middle School"},
    { key:"18117", district:"14", Block:"183", label:"LATHA MATHAVAN MATRIC HR SEC SCHOOL, KIDARIPATTI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18118", district:"14", Block:"183", label:"ARULMIGU SUNDARARASA HIGH SCHOOL, ALAGARKOIL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18119", district:"14", Block:"183", label:"MAHATMA MONTESSORI MATRIC HIGHER SECONDARY SCHOOL, ALAGARKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18120", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, A.VALAYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18121", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARITTAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18122", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, S.KALLAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18123", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, VELLARIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18124", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, THERKAMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"18125", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, SUNNAMBOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"18126", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, VEPPADAPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"18127", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, THIRUVATHAVUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18128", district:"14", Block:"183", label:"CSI MS KOTTAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18129", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, KOTTANATHAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18130", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, PALAIYURPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18131", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, VANNAMPARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18132", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, THANIAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18133", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, VELLALURE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18134", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, URANGANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18135", district:"14", Block:"183", label:"PANCHAYAT UNION MIDDLE SCHOOL, KURICHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18136", district:"14", Block:"183", label:"GOVT HIGH SCHOOL, SEMMANIPATTI                                  ", Sch_type:"Government", Category:"High School"},
    { key:"18137", district:"14", Block:"183", label:"MILTON MATRIC HR SEC SCHOOL,MELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18138", district:"14", Block:"183", label:"MEENAKSHI HIGH SCHOOL, NONDIKOVILPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18139", district:"14", Block:"183", label:"GOVT HR SEC SCHOOL, MELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18140", district:"14", Block:"183", label:"AL-AMEEN URDHU TAMIL HIGH SCHOOL, MELUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18141", district:"14", Block:"183", label:"MUNICIPAL HIGH SCHOOL, MELUR", Sch_type:"Government", Category:"High School"},
    { key:"18142", district:"14", Block:"183", label:"TAMILARASI MIDDLE SCHOOL, MELUR.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18143", district:"14", Block:"183", label:"GOVT GIRLS HR SEC SCHOOL, MELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18144", district:"14", Block:"183", label:"SAKTHI VIDHYALAYA MATRIC HR SEC SCHOOL, MELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18145", district:"14", Block:"183", label:"ABUL KALAM AZAD M.S.  MELUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18146", district:"14", Block:"183", label:"SYDAI SA DURAISAMY MATRIC.HR SEC SCHOOL,MELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18147", district:"14", Block:"183", label:"JAYARAJ ANNAPACKIAM CSI HIGH SCHOOL, MELUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18148", district:"14", Block:"183", label:"R.C.MIDDLE SCHOOL, MELUR.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18149", district:"14", Block:"283", label:"GOVT HR SEC SCHOOL, SEDAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18150", district:"14", Block:"283", label:"GOVT KALLAR HR SEC SCHOOL, POOSALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18151", district:"14", Block:"283", label:"GOVT HIGH SCHOOL, CHINNAKATTALAI", Sch_type:"Government", Category:"High School"},
    { key:"18152", district:"14", Block:"283", label:"GOVERNMENT KALLAR HR SEC  SCHOOL, PERUNGAMANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18153", district:"14", Block:"283", label:"GOVT BOYS HR SEC SCHOOL, ELUMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18154", district:"14", Block:"283", label:"GOVT GIRLS HR SEC SCHOOL, ELUMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18155", district:"14", Block:"283", label:"P.U.M.S. E.GOPALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18156", district:"14", Block:"283", label:"VIVEKANANDA MIDDILE SCHOOL, ELUMALAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18157", district:"14", Block:"283", label:"GOVT HR SEC SCHOOL, M. KALLUPATTI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18158", district:"14", Block:"283", label:"GOVT HIGH SCHOOL, CHELLAYEPURAM                                  ", Sch_type:"Government", Category:"High School"},
    { key:"18159", district:"14", Block:"283", label:"P.U.M.S, SEELANAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18160", district:"14", Block:"283", label:"G. K. MIDDLE SCHOOL MELATHIRUMANICKAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18161", district:"14", Block:"283", label:"P.U.M.S, ATHANKARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18162", district:"14", Block:"283", label:"GOVT HIGH SCHOOL, T.RAMANATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18163", district:"14", Block:"283", label:"Govt.ADW. Middle School, VADAKATHIYANPATTI.", Sch_type:"Government", Category:"Middle School"},
    { key:"18164", district:"14", Block:"283", label:"GOVT KALLAR HR SEC SCHOOL, THADAYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18165", district:"14", Block:"283", label:"RM P S RAMIAH NADAR HIGHER SECONDARY SCHOOL  ATHIPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18166", district:"14", Block:"283", label:"Rm.P.S.RAMAIAH NADAR MATRICULATION SCHOOL, ATHIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"18167", district:"14", Block:"283", label:"PARASAKTHI HIGHER SECONDARY SCHOOL  KOTTAIPATTI S", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18168", district:"14", Block:"283", label:"GOVT HIGH SCHOOL, V.RAMASAMYPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18169", district:"14", Block:"283", label:"SRI.LAKSHMI.N.M.S.VANDARI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18170", district:"14", Block:"283", label:"GOVT HR SEC SCHOOL, SAPTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18171", district:"14", Block:"283", label:"P.U.M.S PALAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18172", district:"14", Block:"283", label:"GOVT ADW HIGH SCHOOL, SANGARALINGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18173", district:"14", Block:"283", label:"P.U.M.S, S.PAPINAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18174", district:"14", Block:"283", label:"P.U.M.S,  MUTHUNAGAIAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18175", district:"14", Block:"283", label:"P.U.M.S. NAGAIAHPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18176", district:"14", Block:"324", label:"P.U.M.S. CHEKKANURANI", Sch_type:"Government", Category:"Middle School"},
    { key:"18177", district:"14", Block:"324", label:"GOVT KALLAR HR SEC SCHOOL, CHECKANURANI                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18178", district:"14", Block:"324", label:"KEREN MATRICULATION HIGHER SECONDARY SCHOOL CHECKANURANI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18179", district:"14", Block:"324", label:"P.U.M.S MAVILIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18180", district:"14", Block:"324", label:"GOVT HIGH SCHOOL, K.MEENAKSHIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18181", district:"14", Block:"324", label:"GOVT KALLAR HIGH SCHOOL, KARADIKKAL", Sch_type:"Government", Category:"High School"},
    { key:"18182", district:"14", Block:"324", label:"SRI MEENAKSHI GIRLS HR SEC SCHOOL, KARADIKKAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18183", district:"14", Block:"324", label:"P.U.M.S.   UCHAPPATI", Sch_type:"Government", Category:"Middle School"},
    { key:"18184", district:"14", Block:"324", label:"THIAGARAJAR MILLS HR SEC SCHOOL, KAPPALUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18185", district:"14", Block:"324", label:"GOVT KALLAR HR SEC SCHOOL, KAPPALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18186", district:"14", Block:"324", label:"GOVT KALLAR HR SEC SCHOOL, MELAURAPPANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18187", district:"14", Block:"324", label:"JOTHI DHARMAN ASSISI HR SEC SCHOOL, SATHANGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18188", district:"14", Block:"324", label:"KSHATRIYA NADARS HIGH SCHOOL, SATHANGUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18189", district:"14", Block:"324", label:"P.U.M.S KANDAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18190", district:"14", Block:"324", label:"GOVT HR SEC SCHOOL, VAGAIKULAM P", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18191", district:"14", Block:"324", label:"P.U.M.S GANGEIYANATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18192", district:"14", Block:"324", label:"GOVT MODEL HR SEC SCHOOL, P.AMMAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18193", district:"14", Block:"324", label:"P.U.M.S KILAVANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"18194", district:"14", Block:"324", label:"GOVT HR SEC SCHOOL, ACHAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18195", district:"14", Block:"324", label:"P.U.M.S. KATRAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18196", district:"14", Block:"324", label:"R.N.M.S. T.PUDUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18197", district:"14", Block:"324", label:"P.U.M.S .S.MEENAKSHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18198", district:"14", Block:"324", label:"P.U.M.S S.VALAYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18199", district:"14", Block:"324", label:"P.U.M.S. CHITHIREDDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18200", district:"14", Block:"324", label:"P.U.M.S THANGALACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"18201", district:"14", Block:"324", label:"PANCHAYAT UNION MIDDLE SCHOOL,  RAYAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18202", district:"14", Block:"324", label:"P.U.M.S.ALAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18203", district:"14", Block:"324", label:"TEDDY HIGHER SECONDARY SCHOOL, ALAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18204", district:"14", Block:"324", label:"GOVT HR SEC SCHOOL, MELAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18205", district:"14", Block:"324", label:"FRANCOIS MEYER MATRIC SCHOOL, ALAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"18206", district:"14", Block:"324", label:"P.U.M.S. KARISALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18207", district:"14", Block:"324", label:"MEPCO SCHLENK MATRIC HR SEC SCHOOL,  MARAVANKULAM, THIRUMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18208", district:"14", Block:"324", label:"GOVT HIGH SCHOOL, VIDATHAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18209", district:"14", Block:"324", label:"P.U.M.S MAIKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18210", district:"14", Block:"324", label:"GOVT HR SEC SCHOOL. THIRUMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18211", district:"14", Block:"324", label:"PKN HR SEC SCHOOL, THIRUMANGALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18212", district:"14", Block:"324", label:"AL AMEEN MUSLIM HR SEC SCHOOL, THIRUMALGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18213", district:"14", Block:"324", label:"PKN GIRLS HR SEC SCHOOL THIRUMANGALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18214", district:"14", Block:"324", label:"GOVT GIRLS HR SEC SCHOOL, THIRUMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18215", district:"14", Block:"324", label:"C.S.I.HIGH.SCHOOL, THIRUMANGALAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18216", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, KODIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"18217", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, THUVARIMAN", Sch_type:"Government", Category:"High School"},
    { key:"18218", district:"14", Block:"328", label:"BLESSING MATRIC HR SEC SCHOOL, ATCHAMPATHU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18219", district:"14", Block:"328", label:"P.U.M.S  VILACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"18220", district:"14", Block:"328", label:"P.U.M.S   NAGAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18221", district:"14", Block:"328", label:"M N U JAYARAJ NADAR HR SEC SCHOOL  NAGAMALAIPUDUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18222", district:"14", Block:"328", label:"SIRUMALAR GIRLS HR SEC SCHOOL, NAGAMALAI PUDUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18223", district:"14", Block:"328", label:"BEN MATRIC HIGH SCHOOL, NAGAMALAI  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"18224", district:"14", Block:"328", label:"M.N.U JAYARAJ ANNAPACKIAM MATRIC HR SEC SCHOOL, NAGAMALAI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18225", district:"14", Block:"328", label:"AKSHARA MATRIC HR SEC SCHOOL, KARADIPATTI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18226", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, VADAPALANJI                                  ", Sch_type:"Government", Category:"High School"},
    { key:"18227", district:"14", Block:"328", label:"P.U.M.S  THENPALANJI ", Sch_type:"Government", Category:"Middle School"},
    { key:"18228", district:"14", Block:"328", label:"PALKALAI NAGAR MS PALKALAINAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18229", district:"14", Block:"328", label:"P.U.M.S VELLAI PARAIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18230", district:"14", Block:"328", label:"GOVT HIGH SCHOOL,VEDARPULIYANKULAM                                  ", Sch_type:"Government", Category:"High School"},
    { key:"18231", district:"14", Block:"328", label:"ST.CHARLES GIRLS HR SEC SCHOOL, THIRUVALLUVAR NAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18232", district:"14", Block:"328", label:"GOVT KALLAR HIGH SCHOOL, THANAKKANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18233", district:"14", Block:"328", label:"GOVT. KALLAR MIDDLE SCHOOL,  KEELAKUILKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18234", district:"14", Block:"328", label:"GOVT.KALLAR MIDDLE SCHOOL, VADIVELKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18235", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, VIRAGANOOR", Sch_type:"Government", Category:"High School"},
    { key:"18236", district:"14", Block:"328", label:"LEONARD SPL FOR THE HEARING IM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18237", district:"14", Block:"328", label:"VELAMMAL MATRIC HR SEC SCHOOL, VIRAGANOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18238", district:"14", Block:"328", label:"GOVT HR SEC SCHOOL, S PULIANKULAM                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18239", district:"14", Block:"328", label:"P.U.M.S  PANAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18240", district:"14", Block:"328", label:"P.U.M.S  CHINTHAMANI", Sch_type:"Government", Category:"Middle School"},
    { key:"18241", district:"14", Block:"328", label:"VELAMMAL VIDYALAYA, ANUPPANADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18242", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, SAMANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"18243", district:"14", Block:"328", label:"IDHAYAM RAJENDRAN RESIDENTIAL SCHOOL, SAMANATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18244", district:"14", Block:"328", label:"P.U.M.S  PERIYAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18245", district:"14", Block:"328", label:"P.U.M.S  PRASANNA COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"18246", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, AYANPAPPAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"18247", district:"14", Block:"328", label:"P.U.M.S  CHINNAUDAIPPU THIRUPPARANKUNRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18248", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, VALAYANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18249", district:"14", Block:"328", label:"ST.ANNES GIRLS HR SEC SCHOOL, PERUNGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18250", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, PERUNKUDI", Sch_type:"Government", Category:"High School"},
    { key:"18251", district:"14", Block:"328", label:"N.K.KUPPIEN RATHNAMANI VIDYALAYA MIDDLE SCHOOL, KAITHARI NAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18252", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, NILAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"18253", district:"14", Block:"328", label:"AIDED MIDDLE SCHOOL, KOOTHIYARKUNDU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18254", district:"14", Block:"328", label:"PANCHAYAT UNION MIDDLE SCHOOL, SOORAKKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18255", district:"14", Block:"328", label:"P.U.M.S  PARAMBUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18256", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, PERIYA ALANGULAM                                ", Sch_type:"Government", Category:"High School"},
    { key:"18257", district:"14", Block:"328", label:"P.U.M.S NEDUMADURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18258", district:"14", Block:"328", label:"GOVT HR SEC SCHOOL, PARAPATHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18259", district:"14", Block:"328", label:"P.U.M.S  NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18260", district:"14", Block:"328", label:"PUMS SOLANKURUNI", Sch_type:"Government", Category:"Middle School"},
    { key:"18261", district:"14", Block:"328", label:"P.U.M.S SAMATHUVAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18262", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, VIRATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"18263", district:"14", Block:"328", label:"P.U.M.S  NEDUNKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18264", district:"14", Block:"328", label:"GOVT HR SEC SCHOOL, AVANIAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18265", district:"14", Block:"328", label:"P.U.M.S  AVANIAPURAM    1", Sch_type:"Government", Category:"Middle School"},
    { key:"18266", district:"14", Block:"328", label:"P.N.U.A.S NADAR MATRIC HR SEC SCHOOL, AVANIYAPURAM   ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18267", district:"14", Block:"328", label:"PASUMALAI HR SEC SCHOOL, PASUMALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18268", district:"14", Block:"328", label:"CSI GIRLS HR SEC SCHOOL, PASUMALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18269", district:"14", Block:"328", label:"PANSY VIDYALAYA MATRIC HR SEC SCHOOL, THIRUPARANKUNDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18270", district:"14", Block:"328", label:"GOVT HR SEC SCHOOL, THIRUPRANKUNDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18271", district:"14", Block:"328", label:"JOTHI M.S CHANDRA PALAYAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18272", district:"14", Block:"328", label:"ARULMIGU ANDAVAR SUBRAMANIA SWAMY GIRLS HR SEC SCHOOL, THIRUPARANKUNDRAM ", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18273", district:"14", Block:"328", label:"MUTHUTHEVAR MUKKULATHORE HR SEC SCHOOL, THIRUNAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18274", district:"14", Block:"328", label:"AMALA PETHANNEL M.S THIRUNAGAR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18275", district:"14", Block:"328", label:"MALLEGAI MATRIC HIGH SCHOOL, THIRUNAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"18276", district:"14", Block:"328", label:"SITALAKSHMI GIRLS HR SEC SCHOOL, THIRUNAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18277", district:"14", Block:"328", label:"CS RAMACHARY MEMORIAL MATRIC HR SEC SCHOOL, THIRUNAGAR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18278", district:"14", Block:"328", label:"SAVITHABAI (KOVAI) HR SEC SCHOOL, THIRUNAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18279", district:"14", Block:"328", label:"SARAH MIDLE SCHOOL, THIRUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18280", district:"14", Block:"328", label:"COR.M.S VILLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18281", district:"14", Block:"328", label:"GOVT. ORTHO S VILLAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18282", district:"14", Block:"328", label:"K.V.T MATRIC HR SEC SCHOOL, SUBRAMANIYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18283", district:"14", Block:"328", label:"NEETHIRAJAN BHARATHI HIGH SCHOOL, JAIHINDUPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18284", district:"14", Block:"328", label:"NETHAJI MIDDLE SCHOOL, JAIHINDPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18285", district:"14", Block:"328", label:"COR.M.S JAIHINDUPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18286", district:"14", Block:"328", label:"K.A.C.A ARUNACHALAM HR SEC SCHOOL, JAIHINDPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18287", district:"14", Block:"328", label:"SRI AYYANAR MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18288", district:"14", Block:"328", label:"T.V.S HR SEC SCHOOL, LAKSHMIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18289", district:"14", Block:"328", label:"COR.M.S VEERAMUDAIYAN", Sch_type:"Government", Category:"Middle School"},
    { key:"18290", district:"14", Block:"328", label:"THE TVS SCHOOL, TVS NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18291", district:"14", Block:"328", label:"GOVT.KALLAR.H.S, MUTHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18292", district:"14", Block:"328", label:"CORPORATION MIDDLE SCHOOL, PASUMALAI SUB STATION", Sch_type:"Government", Category:"Middle School"},
    { key:"18293", district:"14", Block:"328", label:"RUBY MATRIC HR SEC SCHOOL, PALANGANATHAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18294", district:"14", Block:"328", label:"VISAKAN  SCHOOL, ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18295", district:"14", Block:"328", label:"COR.M.S MADAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18296", district:"14", Block:"328", label:"CORPORATION NSSB GIRLS HR SEC SCHOOL, PALANGANATHAM                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18297", district:"14", Block:"328", label:"R.C HIGH SCHOOL, PALANGANATHAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18298", district:"14", Block:"328", label:"SWATHI MATRICULATION SCHOOL, PALANGANATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18299", district:"14", Block:"328", label:"SRI AUROBINDO MIRA MATRIC HR SEC SCHOOL, NEHRU NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18300", district:"14", Block:"328", label:"JEEVANA SCHOOL, PONMENI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18301", district:"14", Block:"328", label:"KNPMM PILLAIMAR SANGAM HR SEC SCHOOL, SAMMATTIPURAM ", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18302", district:"14", Block:"328", label:"INFANT JESUS M.SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18303", district:"14", Block:"328", label:"RAJAM VIDYALAYAM MIDDLE SCHOOL, PONMENI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18304", district:"14", Block:"328", label:"A.L.M MATRIC HIGH SCHOOL, Ponmeni, ARASARADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"18305", district:"14", Block:"328", label:"KENDRA VIDYALAYA HIGHER SECONDARY SCHOOL, THIRUPARANKUNDRAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"18306", district:"14", Block:"328", label:"K.G.V SALA HIGH SCHOOL, VIRATTIPATHU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18307", district:"14", Block:"328", label:"SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, KOCHADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18308", district:"14", Block:"328", label:"SRI SADHANA MATRIC HR SEC SCHOOL, VIRATTIPATHU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18309", district:"14", Block:"328", label:"QUEEN MIRA INTERN S KOCHADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18310", district:"14", Block:"374", label:"P.U.M.S. UTHAPPANAICKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18311", district:"14", Block:"374", label:"JAYASEELAN MEMORIAL MATRIC SCHOOL, UTHAPPANAYAKKANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"18312", district:"14", Block:"374", label:"P.U.M.S, U.VADIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18313", district:"14", Block:"374", label:"GOVT KALLAR HR SEC SCHOOL, VELLAIMALAIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18314", district:"14", Block:"374", label:"P.U.M.S KAMARAJAR NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18315", district:"14", Block:"374", label:"A RATHINASAMY NADAR MEMORIAL HIGHER SECONDARY SCHOOL UTHAPPANAYAKKANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18316", district:"14", Block:"374", label:"GOVT.K.M.S. KALLUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"18317", district:"14", Block:"374", label:"GOVT KALLAR HR SEC SCHOOL, K.PERUMALPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18318", district:"14", Block:"374", label:"GOVT KALLAR HIGH SCHOOL, SULIOCHANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18319", district:"14", Block:"374", label:"P.U.M.S NADUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18320", district:"14", Block:"374", label:"P.U.M.S.SEEMANUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"18321", district:"14", Block:"374", label:"GOVT KALLAR HR SEC SCHOOL, A.POOCHIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18322", district:"14", Block:"374", label:"P.U.M.S. MELAEDAYAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18323", district:"14", Block:"374", label:"P.U.M.S CHETTIAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18324", district:"14", Block:"374", label:"GOVT HIGH SCHOOL, THOTTAPPANAYAKKANUR", Sch_type:"Government", Category:"High School"},
    { key:"18325", district:"14", Block:"374", label:"GOVERNMENT KALLAR HIGH SCHOOL  NALLUTHEVANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18326", district:"14", Block:"374", label:"P.U.M.S POTHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18327", district:"14", Block:"374", label:"GOVERNMENT KALLAR HIGH SCHOOL  VAGURANI", Sch_type:"Government", Category:"High School"},
    { key:"18328", district:"14", Block:"374", label:"P.U.M.S AYOTHIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18329", district:"14", Block:"374", label:"GOVT K.M.S ERUMARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18330", district:"14", Block:"374", label:"P.U.M.S ALLIKUNDAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18331", district:"14", Block:"374", label:"GOVT. K.M.S MANUTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"18332", district:"14", Block:"374", label:"P.U.M.S JOTHILNAICKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18333", district:"14", Block:"374", label:"NADAR SARASWATHI MATRICULATION HR SEC SCHOOL, USILAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18334", district:"14", Block:"374", label:"R C LITTLE FLOWER GIRLS HIGHER SECONDARY SCHOOL MELAPUDUR USILAMPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18335", district:"14", Block:"374", label:"P.U.M.S  BAJAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18336", district:"14", Block:"374", label:"GOVT HR SEC SCHOOL, USILAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18337", district:"14", Block:"374", label:"GOVT KALLAR HIGH SCHOOL, KARUKATTANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18338", district:"14", Block:"374", label:"T.E.L.C.M.S   USILAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18339", district:"14", Block:"374", label:"T E L C GIRLS' HIGHER SECONDARY SCHOOL USILAMPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18340", district:"14", Block:"374", label:"T.E.L.C M.S  KEELAPUDUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18341", district:"14", Block:"374", label:"NADAR SARASWATHI HIGHER SECONDARY SCHOOL  USILAMPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18342", district:"14", Block:"306", label:"P.U.M.S. KULASEKARANKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18343", district:"14", Block:"306", label:"MADURA SUGARS GOVT HR SEC SCHOOL.PANDIARAJAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18344", district:"14", Block:"306", label:"GOVERNMENT MIDDLE SCHOOL PERUMALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18345", district:"14", Block:"306", label:"P.U.M.S SEMINIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18346", district:"14", Block:"306", label:"P.U.M.S.KUTLADAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18347", district:"14", Block:"306", label:"GOVT HIGH SCHOOL, KATCHAIKATTI", Sch_type:"Government", Category:"High School"},
    { key:"18348", district:"14", Block:"306", label:"P.U.M.S. T.ANDIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18349", district:"14", Block:"306", label:"PANCHAYAT UNION MIDDLE SCHOOL, KATTAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18350", district:"14", Block:"306", label:"GOVT HIGH SCHOOL, METTUNEERATHAN", Sch_type:"Government", Category:"High School"},
    { key:"18351", district:"14", Block:"306", label:"GOVT GIRLS HR SEC SCHOOL, T.VADIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18352", district:"14", Block:"306", label:"GOVT BOYS HR SEC SCHOOL, T.VADIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18353", district:"14", Block:"306", label:"ST CHARLES HIGH SCHOOL, T.VADIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"18354", district:"14", Block:"306", label:"GOVT ADW HR SEC SCHOOL, NACHIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18355", district:"14", Block:"306", label:"P.U.M.S IRUMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"18356", district:"14", Block:"306", label:"GOVT HIGH SCHOOL, KARUPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18357", district:"14", Block:"306", label:"P.U.M.S MANNADI MANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18358", district:"14", Block:"306", label:"GOVT KALLAR HR SEC SCHOOL, AYYAPPANAIYAKKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18359", district:"14", Block:"306", label:"GOVT HR SEC SCHOOL, T.MULLIPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18360", district:"14", Block:"306", label:"GOVT HIGH SCHOOL, KADUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18361", district:"14", Block:"306", label:"P.U.M.S KELAMATTAIYAN", Sch_type:"Government", Category:"Middle School"},
    { key:"18362", district:"14", Block:"306", label:"GOVT KALLAR HR SEC SCHOOL, MELLAKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18363", district:"14", Block:"306", label:"KERAN MATRIC.SCHOOL, MALAYUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18364", district:"14", Block:"306", label:"P.U.M.S.KATCHIRAYIRUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"18365", district:"14", Block:"306", label:"P.U.M.S PETTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18366", district:"14", Block:"306", label:"GOVT GIRLS HR SEC SCHOOL, SHOLAVANDAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18367", district:"14", Block:"306", label:"P.U.M.S.SHOLAVANDHAN,CHATHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18368", district:"14", Block:"306", label:"R.C.M.SHOLAVANDHAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18369", district:"14", Block:"306", label:"KAMARAJAR MATRIC HR SEC SCHOOL, SHOLAVANDAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18370", district:"14", Block:"306", label:"AS GOVT HR SEC SCHOOL, SHOLAVANDAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18371", district:"14", Block:"306", label:"KAMARAJAR.MID.S.SHOLAVANDHAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18372", district:"14", Block:"306", label:"P.U.M.S.THIRUVEDAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18373", district:"14", Block:"306", label:"VIVEKANANDA HR SEC SCHOOL, THIRUVEDAKAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18374", district:"14", Block:"306", label:"NARENDRA MATRICULATION  SCHOOL, THIRUVEDAKAM WEST", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18375", district:"14", Block:"306", label:"P.U.M.S NEDUNGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18376", district:"14", Block:"306", label:"P.U.M.S THIRUVALAVAYANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18377", district:"14", Block:"306", label:"MOUNT LITERA ZEE SCHOOL, RAYAPURAM, MADURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18378", district:"14", Block:"306", label:"R.C.M.S.ROYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18379", district:"14", Block:"306", label:"P.U.M.S. C.PUDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18380", district:"14", Block:"159", label:"COR.M.S SHENOY NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18381", district:"14", Block:"159", label:"SUBBARAYALU MEMORIAL  MIDDLE SCHOOL, ALWARPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18382", district:"14", Block:"159", label:"COR.M.S KARUMBALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18383", district:"14", Block:"159", label:"COR.M.S RAMNAD A.R.LINE", Sch_type:"Government", Category:"Middle School"},
    { key:"18384", district:"14", Block:"159", label:"JEYABHARATH M.S PALAM ST.ROAD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18385", district:"14", Block:"159", label:"MANOHARA M.S SELLUR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18386", district:"14", Block:"159", label:"ST.JOHN DE BRITTO MS, THATHTHANERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18387", district:"14", Block:"159", label:"MLWA HR SEC SCHOOL, PONNAGARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18388", district:"14", Block:"159", label:"MANGAYARKARASI HR SEC SCHOOL, PONNAGRAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18389", district:"14", Block:"159", label:"VIKAASA MATRIC Hr.Sec. SCHOOL, PONNAGRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18390", district:"14", Block:"159", label:"T.E.L.C. JEYARAJ ANNAPACKIAM HIGH SCHOOL, PONNAGARAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18391", district:"14", Block:"159", label:"SRI MEENAKSHI MIDDLE SCHOOL, SHUNMUGAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18392", district:"14", Block:"159", label:"GEORGE M.S RAJENDRA ST. MADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18393", district:"14", Block:"159", label:"C.C.P Middle School,  RAJENDRA MAIN ROAD.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18394", district:"14", Block:"159", label:"CORPORATION VELLIVEETHIAR GIRLS HR SEC SCHOOL, PONNAGARAM                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18395", district:"14", Block:"159", label:"MAD.MUTHU M.S AZHAGARADI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18396", district:"14", Block:"159", label:"CORPORATION SETHUPATHI PANDITHURAI BOYS HR SEC SCHOOL, NEW JAIL ROAD                                ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18397", district:"14", Block:"159", label:"MUKKULATHOR MIDDLE SCHOOL, MELAPONNAGARAM MAIN ROAD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18398", district:"14", Block:"159", label:"BALA M.S MELAPONNANAGRAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18399", district:"14", Block:"159", label:"HOLY FAMILY GIRLS HR SEC SCHOOL, GNANAOLIVUPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18400", district:"14", Block:"159", label:"ST.BRITTO HR SEC SCHOOL, GNANAOLIVUPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18401", district:"14", Block:"159", label:"Y.M.C.C. MIDDLE SCHOOL, NEW JAIL ROAD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18402", district:"14", Block:"159", label:"SRI VIDYALAYAM MATRIC HSS, S.S.COLONY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18403", district:"14", Block:"159", label:"GOVT GIRLS HR SEC SCHOOL, MAHABOOBPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18404", district:"14", Block:"159", label:"AZHAGHU SUNDHRAM MEM.M.S", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18405", district:"14", Block:"159", label:"MADURAI CITY MIDDLE SCHOOL  ANSARINAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18406", district:"14", Block:"159", label:"MADURA COLLEGE HR SEC SCHOOL, RMS ROAD, MADURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18407", district:"14", Block:"159", label:"RAILWAY  HSS, RAILWAY COLONY", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"18408", district:"14", Block:"159", label:"UNION CHRISTIAN HR SEC SCHOOL, MADURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18409", district:"14", Block:"159", label:"CORPORATION MIDDLE SCHOOL, WEST GATE NO 2", Sch_type:"Government", Category:"Middle School"},
    { key:"18410", district:"14", Block:"159", label:"MANGAYARKARASI MIDDLE SCHOOL, MANINAGARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18411", district:"14", Block:"159", label:"SETUPATI HR SEC SCHOOL, NORTHVELI STREET", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18412", district:"14", Block:"159", label:"HOLY ANGEL HR SEC SCHOOL, KANAGAVEL COLONY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18413", district:"14", Block:"159", label:"VEERAMAMUNIVAR VIDYASALAI MIDDLE SCHOOL, KANAGAVEL COLONY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18414", district:"14", Block:"159", label:"SAHAYAMATHA MIDDLE SCHOOL, M.K.PURAM, JAIHINDUPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18415", district:"14", Block:"159", label:"PREM VIDYA SALAI SOLAI ALAGU P", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18416", district:"14", Block:"159", label:"SRI MURUGAN MIDDLE SCHOOL, JEEVA NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18417", district:"14", Block:"159", label:"FATIMA MIDDLE SCHOOL, KASTHURIBAI NAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18418", district:"14", Block:"160", label:"GUHAN MATRIC HR SEC SCHOOL, TEPPAKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18419", district:"14", Block:"160", label:"R.C MIDDLE SCHOOL , ROSARY CHURCH, TOWNHALL ROAD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18420", district:"14", Block:"160", label:"RAJAPARAMESWARI MIDDLE SCHOOL, ANNANAGAR,  VANDIYUR MAIN ROAD , MADURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18421", district:"14", Block:"160", label:"CORPORATION KASTHURIBAI GANDHI GIRLS HR SEC SCHOOL, SIMMAKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18422", district:"14", Block:"160", label:"D M & R GIRLS' HIGH SCHOOL NORTH MASI STREET MADURAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18423", district:"14", Block:"160", label:"CORPORATION AVVAI GIRLS HR SEC SCHOOL, EAST AVANI MOOLA STREET", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18424", district:"14", Block:"160", label:"AYIRA VAISYA HR SEC SCHOOL,  VELLIAMBALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18425", district:"14", Block:"160", label:"SOURASHTRA  (CO-ED) HR SEC SCHOOL, NETHAJI ROAD, MADURAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18426", district:"14", Block:"160", label:"DIOCESE  OF MADURAI AND RAMNAD MIDDLE SCHOOL, MADURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18427", district:"14", Block:"160", label:"CORPORATION MARAIMALAI ADIGALAR BOYS HIGH SCHOOL, SOUTH GATE                                  ", Sch_type:"Government", Category:"High School"},
    { key:"18428", district:"14", Block:"160", label:"PANDIA VELLALA COR.M.S", Sch_type:"Government", Category:"Middle School"},
    { key:"18429", district:"14", Block:"160", label:"CORPORATION  EVRN GIRLS HR SEC SCHOOL, SOUTH VELI STREET    ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18430", district:"14", Block:"160", label:"CORPORATION HR SEC SCHOOL, SUNDHARAJAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18431", district:"14", Block:"160", label:"THIAGARAJAR HR SEC SCHOOL, VASANTHA NAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18432", district:"14", Block:"160", label:"GURUKULAM HIGH SCHOOL, SUBRAMANIAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18433", district:"14", Block:"160", label:"CORPORATION KAMBAR HR SEC SCHOOL, SUBRAMANIAPURAM                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18434", district:"14", Block:"160", label:"ST.JOSEPH'S GIRLS HR SEC SCHOOL, OLD KUYAVARPALAYAM ROAD", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18435", district:"14", Block:"160", label:"NADAR HR SEC SCHOOL, SOUTH GATE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18436", district:"14", Block:"160", label:"SINGARATOPE COR.M.S", Sch_type:"Government", Category:"Middle School"},
    { key:"18437", district:"14", Block:"160", label:"CORPORATION BHARATHIDASANAR HR SEC SCHOOL, NEW MAHALIPATTI ROAD                                  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18438", district:"14", Block:"160", label:"CORPORATION N.M.S.M HIGH SCHOOL, EAST GATE", Sch_type:"Government", Category:"High School"},
    { key:"18439", district:"14", Block:"160", label:"R.C MIDDLE SCHOOL, EAST GATE, MADURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18440", district:"14", Block:"160", label:"CORPORATION THIRUVALLUVAR MAHAL HIGH SCHOOL, MADURAI", Sch_type:"Government", Category:"High School"},
    { key:"18441", district:"14", Block:"160", label:"V.H.N.GIRLS HR SEC SCHOOL, MUNICHALAI      ", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18442", district:"14", Block:"160", label:"CORPORATION UMARUPULAVAR HIGH SCHOOL, PERIYAPALAM", Sch_type:"Government", Category:"High School"},
    { key:"18443", district:"14", Block:"160", label:"M.A.V.M.M HR SEC SCHOOL, YANAIKKAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18444", district:"14", Block:"160", label:"CORPORATION HIGH SCHOOL, MUNICHALAI", Sch_type:"Government", Category:"High School"},
    { key:"18445", district:"14", Block:"160", label:"RAJA DHANALAKSHMI M.S.", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18446", district:"14", Block:"160", label:"MEENAKSHIPURAM MIDDLE SCHOOL  SREENIVASA PERUMALKOIL STREET, MADURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18447", district:"14", Block:"160", label:"SOURASHTRA HR SEC SCHOOL, MADURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18448", district:"14", Block:"160", label:"SRI P.V MATRIC HR SEC SCHOOL, KAMARAJAR SALAI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18449", district:"14", Block:"160", label:"M.K.R.AYYA NADAR JEYALAKSHMI AMMAL HR SEC SCHOOL TEPPAKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18450", district:"14", Block:"160", label:"NIRMALA GIRLS HR SEC SCHOOL, TEPPAKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18451", district:"14", Block:"160", label:"GOMATHI M.S BABU NAGAR", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18452", district:"14", Block:"160", label:"R.C FATIMA M.S", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18453", district:"14", Block:"160", label:"THIAGARAJAR MODEL HR SEC SCHOOL, TEPPAKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18454", district:"14", Block:"160", label:"V.M.J HR.S.S", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18455", district:"14", Block:"160", label:"SRI MEENAKSHI SUNDARESWARAR GIRLS HR SEC SCHOOL, TEPPAKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18456", district:"14", Block:"160", label:"MOTHER THERESA MATRIC HR SEC SCHOOL, MELA ANUPPANADY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18457", district:"14", Block:"160", label:"CORPORATION HIGH SCHOOL, ANUPPANADI", Sch_type:"Government", Category:"High School"},
    { key:"18458", district:"14", Block:"160", label:"ST.PATRICK'S MIDDLE SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18459", district:"14", Block:"160", label:"CORPORATION MANIMEGALAI GIRLS HIGH SCHOOL, KAMARAJAR SALAI", Sch_type:"Government", Category:"High School"},
    { key:"18460", district:"14", Block:"160", label:"VHN BOYS HR SEC SCHOOL, KUYAVARPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18461", district:"14", Block:"160", label:"OM SARAVANA BHAVA THIRUMURGAN MIDDLE SCHOOL,  EAST MADURAI STATION ROAD , MADURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18462", district:"14", Block:"160", label:"ANNAI THERESA MATRIC HIGH SCHOOL, BALARENGAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18463", district:"14", Block:"160", label:"CHANDRA MIDDLE SCHOOL, MELA ANUPPANDI, MADURAI ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18464", district:"14", Block:"160", label:"ST.MARY'S  HR SEC SCHOOL, EAST VELI ST.", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18465", district:"14", Block:"160", label:"PNU A.P.T.DORAIRAJ HIGHER SEC SCHOOL, MELAANUPPANADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18466", district:"14", Block:"160", label:"DR.T.THIRUGNANAM HIGH SCHOOL, EAST SANTHAI ", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18467", district:"14", Block:"160", label:"MANU GIRLS HR SEC SCHOOL, KEERAITHURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18468", district:"14", Block:"160", label:"SUNDARAMBAL M.S", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18469", district:"27", Block:"31", label:"GOVT HS, P.DHARMATHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18470", district:"27", Block:"31", label:"GOVT HSS, T.SUBBULAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18471", district:"27", Block:"31", label:"GOVT (ADW) HSS, BOMMINAICKANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18472", district:"27", Block:"31", label:"PUMS - THIMMARASANAICKANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18473", district:"27", Block:"31", label:"PUMS - T.RAJAGOPALANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18474", district:"27", Block:"31", label:"PUMS - NATCHIYARPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18475", district:"27", Block:"31", label:"GOVT HSS, RENGASAMUDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18476", district:"27", Block:"31", label:"PUMS - KEELAMUTHANAMPATTI @ KATHIRAYAGOUNDANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18477", district:"27", Block:"31", label:"GOVT KALLAR HSS, MUTHANAMPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18478", district:"27", Block:"31", label:"PUMS - KALLUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18479", district:"27", Block:"31", label:"PUMS - MUTHUSANGILIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18480", district:"27", Block:"31", label:"PUMS - C.R.CAMP @ ANNAI INDRA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18481", district:"27", Block:"31", label:"GOVT HS, SHANMUGASUNDARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18482", district:"27", Block:"31", label:"GOVT HS, ANUPPAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18483", district:"27", Block:"31", label:"GOVT HS, KONDAMANAICKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18484", district:"27", Block:"31", label:"HINDU HSS, JAKKAMPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18485", district:"27", Block:"31", label:"GOVT BOYS HSS, AUNDIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18486", district:"27", Block:"31", label:"GOVT GIRLS HSS, AUNDIPATTI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18487", district:"27", Block:"31", label:"GOVT HSS, KANNIAPPAPILLAIPATTI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18488", district:"27", Block:"31", label:"S.K.A. HSS, AUNDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18489", district:"27", Block:"31", label:"GOVT KALLAR HSS, ETHAKOVIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18490", district:"27", Block:"31", label:"GOVT HS, MUTHUMARIAMMAN NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"18491", district:"27", Block:"31", label:"GOVT HS, KOTHAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18492", district:"27", Block:"31", label:"GOVT KALLAR HSS, RAJADHANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18493", district:"27", Block:"31", label:"GOVT HSS, PALACOMBAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18494", district:"27", Block:"31", label:"GOVT HSS, THEPPAMMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18495", district:"27", Block:"31", label:"GANESHA MS -  SITHARPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18496", district:"27", Block:"31", label:"GOVT HSS, S.KATHIRNARASINGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18497", district:"27", Block:"31", label:"GOVT HSS, OKKARAIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18498", district:"27", Block:"31", label:"GOVT HS, ASARIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18499", district:"27", Block:"31", label:"GOVT HS, G.USILAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18500", district:"27", Block:"31", label:"GOVT HS, THEKKAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18501", district:"27", Block:"31", label:"PUMS - ERATHIMMAKKALPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18502", district:"27", Block:"31", label:"GOVT (ADW) HSS, KUNNUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18503", district:"27", Block:"31", label:"GOVT (ADW) HS, AMMACHIYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18504", district:"27", Block:"40", label:"PUMS - KURANGANI", Sch_type:"Government", Category:"Middle School"},
    { key:"18505", district:"27", Block:"40", label:"GOVT HSS, BOOTHIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18506", district:"27", Block:"40", label:"PURNA VIDYA BHAVAN MAT HSS - KODANGIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18507", district:"27", Block:"40", label:"GKMS - B.MEENATCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18508", district:"27", Block:"40", label:"GOVT KALLAR HS -  B.ANAIKARAIPATTY  ", Sch_type:"Government", Category:"High School"},
    { key:"18509", district:"27", Block:"40", label:"PUMS,-KARATUPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"18510", district:"27", Block:"40", label:"GOVT HSS-MELACHOCKANATHAPURAM  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18511", district:"27", Block:"40", label:"PUMS - MUNTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"18512", district:"27", Block:"40", label:"PUMS-MANAPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"18513", district:"27", Block:"40", label:"GOVT HSS-SILAMALAI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18514", district:"27", Block:"40", label:"GOVT HSS - SILLAMARATHUPATTI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18515", district:"27", Block:"40", label:"M.P.M. MS -  VISUVASAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18516", district:"27", Block:"40", label:"GRAMA POTHU KALIKADEVI HS - BADRAKALIPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18517", district:"27", Block:"40", label:"PATCHAIYAPPA.HS - UPPUKOTTAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18518", district:"27", Block:"40", label:"PUMS - KOOLAYANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18519", district:"27", Block:"40", label:"GKMS - PALARPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"18520", district:"27", Block:"40", label:"PUMS -  MANIAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18521", district:"27", Block:"40", label:"7TH WARD MPL HSS - BODI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18522", district:"27", Block:"40", label:"GKMS -  BODI EAST", Sch_type:"Government", Category:"Middle School"},
    { key:"18523", district:"27", Block:"40", label:"SRI SOWDAMBIKA MIDDLE SCHOOL - BODI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18524", district:"27", Block:"40", label:"Z.K.M. HSS -  BODINAYAKANUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18525", district:"27", Block:"40", label:"PANKAJAM GIRLS HSS -  BODINAYAKANUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18526", district:"27", Block:"40", label:"NADAR HSS, THIRUMALAPURAM - BODI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18527", district:"27", Block:"40", label:"10TH WARD MPL HS - BODINAYAKANUR", Sch_type:"Government", Category:"High School"},
    { key:"18528", district:"27", Block:"40", label:"KAMARAJ VIDHYALAYA HS - BODINAYAKANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"18529", district:"27", Block:"49", label:"TPVVU GURUKKAL MS -  CHINNAMANUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18530", district:"27", Block:"49", label:"SKA HSS - CHINNAMANUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18531", district:"27", Block:"49", label:"MUNICIPAL  HS, CHINNAMANUR", Sch_type:"Government", Category:"High School"},
    { key:"18532", district:"27", Block:"49", label:"KVA GIRLS HSS - CHINNAMANUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18533", district:"27", Block:"49", label:"GOVT HSS - ERASAKKANAYAKANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18534", district:"27", Block:"49", label:"GANDHIJI VIDHYA BEEDAM HSS- KANNISERVAIPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18535", district:"27", Block:"49", label:"PACHAIYAPPA HINDU NADAR HSS - KAMATCHIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18536", district:"27", Block:"49", label:"GOVT HS -SEEPALAKOTTAI ", Sch_type:"Government", Category:"High School"},
    { key:"18537", district:"27", Block:"49", label:"MARIAPPA MEMORIAL MATRIC SCHOOL- KAMATCHIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18538", district:"27", Block:"49", label:"GOVT HSS -ODAIPATTI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18539", district:"27", Block:"49", label:"GOVT KALLAR HSS - VELLAIAMMALPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18540", district:"27", Block:"49", label:"GOVT KALLAR HSS- MARKEYANKOTTAI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18541", district:"27", Block:"49", label:"GOVT HS- PULIKUTHI  ", Sch_type:"Government", Category:"High School"},
    { key:"18542", district:"27", Block:"49", label:"GOVT HSS- KUTCHANUR  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18543", district:"27", Block:"49", label:"GOVT HS- SANKARAPURAM  ", Sch_type:"Government", Category:"High School"},
    { key:"18544", district:"27", Block:"49", label:"GOVT HS - RAMAKRISHNAPURAM  ", Sch_type:"Government", Category:"High School"},
    { key:"18545", district:"27", Block:"49", label:"GOVT KALLAR HSS - MUTHAIYANCHETTYPATTY  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18546", district:"27", Block:"55", label:"SRI A.L.V. HS, C.PUTHUPATTY", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18547", district:"27", Block:"55", label:"K.L.S.S. GOVT HSS, C.PUTHUPATTY  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18548", district:"27", Block:"55", label:"N.S.K.P. HSS, GUDALUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18549", district:"27", Block:"55", label:"GOVT HSS, LOWER CAMP  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18550", district:"27", Block:"55", label:"R. M. GOVT KALLAR HSS, MELAGUDALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18551", district:"27", Block:"55", label:"THIRUVALLUVAR HSS, KEELAGUDALUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18552", district:"27", Block:"55", label:"GOVT KALLAR HSS, KARUNAKAMUTHANPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18553", district:"27", Block:"55", label:"GOVT KALLAR HS, KULLAPPAGOUNDANPATTY", Sch_type:"Government", Category:"High School"},
    { key:"18554", district:"27", Block:"55", label:"GOVT HSS, SURULIPATTY ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18555", district:"27", Block:"55", label:"GOVT HSS, HIGHWAVYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18556", district:"27", Block:"55", label:"PUMS - MELMANALAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18557", district:"27", Block:"55", label:"PUMS -  KAMAYAKOUNDANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18558", district:"27", Block:"55", label:"KASTHURIBAI MS - KAMAYAGOUNDANPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18559", district:"27", Block:"55", label:"KASTHURIBAI GIRLS HSS, KAMAYAGOUNDANPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18560", district:"27", Block:"55", label:"GOVT HSS, KAMAYAKOUNDANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18561", district:"27", Block:"55", label:"ILAHI ORIENTAL ARABIC HS, CUMBUM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18562", district:"27", Block:"55", label:"GOVT KALLAR HSS, UTHAMAPURAM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18563", district:"27", Block:"55", label:"MPL GIRLS HSS, M.A.PURAM, CUMBUM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18564", district:"27", Block:"55", label:"MPL.MS - ALAMARAM, CUMBUM", Sch_type:"Government", Category:"Middle School"},
    { key:"18565", district:"27", Block:"55", label:"SRI MUTHIAH PILLAI MEMORIAL HSS, CUMBUM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18566", district:"27", Block:"55", label:"C.P.U. HSS, CUMBUM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18567", district:"27", Block:"55", label:"A. R. GOVT GIRLS HSS, CUMBUM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18568", district:"27", Block:"55", label:"S.V.MATRIC SCHOOL - CUMBUM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18569", district:"27", Block:"194", label:"GOVT HSS, RAJENDRANAGAR  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18570", district:"27", Block:"194", label:"GOVT HSS, GANDAMANUR    ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18571", district:"27", Block:"194", label:"GOVT HSS, KADAMALAIKUNDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18572", district:"27", Block:"194", label:"GOVT HSS, KUMANANTHOZHU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18573", district:"27", Block:"194", label:"GOVT HSS, MANTHISUNAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18574", district:"27", Block:"194", label:"HAYAGREEVA VIDHYALAYA MATRIC HSS KADAMALAIKUNDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18575", district:"27", Block:"194", label:"G.R.V. HSS, MYLADUMPARAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18576", district:"27", Block:"194", label:"PUMS -  NARIYOOTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"18577", district:"27", Block:"194", label:"GOVT HSS, MURUKKODAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18578", district:"27", Block:"194", label:"GOVT HSS, MYLADUMPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18579", district:"27", Block:"194", label:"GOVT HS, POOSANUTHU", Sch_type:"Government", Category:"High School"},
    { key:"18580", district:"27", Block:"194", label:"GOVT HS, KOMBAITHOZHU  ", Sch_type:"Government", Category:"High School"},
    { key:"18581", district:"27", Block:"194", label:"GOVT HS, THUMMAKUNDU", Sch_type:"Government", Category:"High School"},
    { key:"18582", district:"27", Block:"194", label:"GOVT HSS, THANGAMMALPURAM  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18583", district:"27", Block:"194", label:"GOVT HSS, VARUSANADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18584", district:"27", Block:"194", label:"GOVT HSS, VAIKKALPARAI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18585", district:"27", Block:"241", label:"GOVT HSS -  GENGUVARPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18586", district:"27", Block:"241", label:"GOVT HSS - G.KALLUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18587", district:"27", Block:"241", label:"ST. PETERS HSS - G.KALLUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18588", district:"27", Block:"241", label:"GOVT HSS - DEVADANAPATTI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18589", district:"27", Block:"241", label:"GOVT KALLAR HS - PULLAKKAPATTTY", Sch_type:"Government", Category:"High School"},
    { key:"18590", district:"27", Block:"241", label:"GOVT HS - MANJALAR DAM ", Sch_type:"Government", Category:"High School"},
    { key:"18591", district:"27", Block:"241", label:"GOVT HS - SATHAKOVILPATTI  ", Sch_type:"Government", Category:"High School"},
    { key:"18592", district:"27", Block:"241", label:"GOVT MODEL HSS -  SILVARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18593", district:"27", Block:"241", label:"GOVT HSS - JAYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18594", district:"27", Block:"241", label:"GOVT HS - BOMMINAYAKKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18595", district:"27", Block:"241", label:"GOVT HS -  GULLAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18596", district:"27", Block:"241", label:"GOVT HSS -  A.VADIPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18597", district:"27", Block:"241", label:"SRI MAYAPANDEESWARI MS -  MELMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18598", district:"27", Block:"241", label:"GOVT HSS -  VADUGAPATTI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18599", district:"27", Block:"241", label:"GOVT HSS - VAIGAIDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18600", district:"27", Block:"241", label:"GOVT KALLAR HSS -  E.PUTHUPATTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18601", district:"27", Block:"241", label:"GOVT HS -  MURUGAMALAI NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"18602", district:"27", Block:"241", label:"GOVT HS  -  T.KALLIPATTI  ", Sch_type:"Government", Category:"High School"},
    { key:"18603", district:"27", Block:"241", label:"GOVT HSS - THAMARAIKULAM  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18604", district:"27", Block:"241", label:"GOVT HSS -  SARUTHUPATTY  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18605", district:"27", Block:"241", label:"GOVT HSS - LAKSHMIPURAM  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18606", district:"27", Block:"241", label:"GOVT HS, GANDHINAGAR  ", Sch_type:"Government", Category:"High School"},
    { key:"18607", district:"27", Block:"241", label:"MUTHALAMMAN HINDU HSS -  VADAPUDUPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18608", district:"27", Block:"241", label:"U.C.MS - KOTTAIMEDU ,VADAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18609", district:"27", Block:"241", label:"GOVT GIRLS HSS - PERIYAKULAM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18610", district:"27", Block:"241", label:"RAHIM BROS.MS - VADAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18611", district:"27", Block:"241", label:"7TH WARD MS - VADAKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18612", district:"27", Block:"241", label:"SRI SARASWATHI MS -VADAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18613", district:"27", Block:"241", label:"V.M. GOVT HSS - PERIYAKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18614", district:"27", Block:"241", label:"NADAR MS - THENKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18615", district:"27", Block:"241", label:"ST. ANNES HSS - PERIYAKULAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18616", district:"27", Block:"241", label:"C.S.I. MS - THANDUPALAYAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18617", district:"27", Block:"241", label:"NIRMALA MS - THENKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18618", district:"27", Block:"241", label:"BUDDHAR KALA MS -  THENKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18619", district:"27", Block:"241", label:"10TH WARD MUNICIPAL MIDDLE SCHOOL - THENKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18620", district:"27", Block:"319", label:"KAMMA DHARMA HS - GOVINDANAGARAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18621", district:"27", Block:"319", label:"SRI VARADHA VENKATARAMANA HSS - VENKATACHALAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18622", district:"27", Block:"319", label:"GOVT HSS, DHARMAPURI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18623", district:"27", Block:"319", label:"GOVT HSS, KODUVILARPATTI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18624", district:"27", Block:"319", label:"S.R.G. GOVT HS, SRIRENGAPURAM  ", Sch_type:"Government", Category:"High School"},
    { key:"18625", district:"27", Block:"319", label:"SHRI CHANDRA GUPTA MAURYA INTERNATIONAL SCHOOL, UPPARPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18626", district:"27", Block:"319", label:"GOVT KALLAR HSS, ANNANJI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18627", district:"27", Block:"319", label:"GOVT HSS, KOTTUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18628", district:"27", Block:"319", label:"GOVT HSS, VEERAPANDI  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18629", district:"27", Block:"319", label:"PALANIAPPA MEMORIAL HSS - PALANICHETTIPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18630", district:"27", Block:"319", label:"VELAMMAL VIDHYA CBSE SCHOOL - MUTHUTHEVANPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18631", district:"27", Block:"319", label:"MUTHIAH HS - ALLINAGARAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18632", district:"27", Block:"319", label:"GOVT MODEL  HSS, ALLINAGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18633", district:"27", Block:"319", label:"R.C. HS - THENI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18634", district:"27", Block:"319", label:"N.A.KONDURAJA MEMORIAL HS - THENI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18635", district:"27", Block:"319", label:"NADAR SARASWATHI HSS - THENI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18636", district:"27", Block:"319", label:"N.S. GIRLS HSS - THENI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18637", district:"27", Block:"319", label:"P.C. GIRLS HSS - THENI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18638", district:"27", Block:"319", label:"THE LITTLE KINGDOM SENIOR SCHOOL - VEDAPURI ARANMANAIPUTHUR THENI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18639", district:"27", Block:"375", label:"St. ALOYSIUS BOYS Hr.,SEC.,SCHOOL - ROYAPPANPATTY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18640", district:"27", Block:"375", label:"St. AGNES GIRLS Hr.,Sec.,SCHOOL - ROYAPPANPATTY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18641", district:"27", Block:"375", label:"SAVARIYAPPA UDAYAR MEMORIAL HIGHER SECONDARY SCHOOL - ROYAPPANPATTY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18642", district:"27", Block:"375", label:"AMALA ANNAI HIGHER SECONDARY SCHOOL -  T.SINTHALAICHERY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18643", district:"27", Block:"375", label:"HINDU MIDDLE SCHOOL - PANNAIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18644", district:"27", Block:"375", label:"GOVT HSS, PANNAIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18645", district:"27", Block:"375", label:"GOVT HSS, MELASINDALAICHERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18646", district:"27", Block:"375", label:"SRI KANNIKA PARAMESWARI HIGHER SECONDARY SCHOOL - KOMBAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18647", district:"27", Block:"375", label:"AYYA MATRICULATION  SCHOOL - KOMBAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18648", district:"27", Block:"375", label:"GOVT HSS, THEVARAM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18649", district:"27", Block:"375", label:"SRI RENGASAMY MEMORIAL HIGHER SECONDARY SCHOOL - LAKSHMINAYAKKANPATTY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18650", district:"27", Block:"375", label:"MOHAMED FATHIMA GIRLS HIGH SCHOOL - UTHAMAPALAYAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18651", district:"27", Block:"375", label:"GOVT MODEL  HSS, UTHAMAPALAYAM  ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18652", district:"27", Block:"375", label:"GOVERNMENT  HIGH SCHOOL   -  U.AMMAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"18653", district:"27", Block:"375", label:"ST. JOSEPH'S R.C. HIGH SCHOOL - UTHAMAPALAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18654", district:"38", Block:"28", label:"P.U.M.S, VILLIPATHIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"18655", district:"38", Block:"28", label:"GHSS, SOOLAKKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18656", district:"38", Block:"28", label:"GHS FOR HEARING IMPAIRED, SOOLAKKARAI", Sch_type:"Government", Category:"High School"},
    { key:"18657", district:"38", Block:"28", label:"SRI VEERAPPA VIDHYALAYA HR SEC SCHOOL, KULLURSANDAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18658", district:"38", Block:"28", label:"GHSS, PALAVANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18659", district:"38", Block:"28", label:"HINDU HIGH SCHOOL, GOPALAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18660", district:"38", Block:"28", label:"CSD MS KOVILANGULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18661", district:"38", Block:"28", label:"G H S, KOVILANGULAM", Sch_type:"Government", Category:"High School"},
    { key:"18662", district:"38", Block:"28", label:"P.U.M.S., PERIYAKATTANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18663", district:"38", Block:"28", label:"ST.JOSEPH'S CARMEL MATRICULATION SCHOOL, ARUPPUKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"18664", district:"38", Block:"28", label:"BPV SALA  HSS, PALAYAMPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18665", district:"38", Block:"28", label:"G H S S, SEMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18666", district:"38", Block:"28", label:"VELLALAR MS., PULIYURAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18667", district:"38", Block:"28", label:"NADAR MIDDLE SCHOOL., PULIYURAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18668", district:"38", Block:"28", label:"PUMS, KOOTHIPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18669", district:"38", Block:"28", label:"GOVT HR SEC SCHOOL, MALAIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18670", district:"38", Block:"28", label:"P U M S AMANAKKUNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18671", district:"38", Block:"28", label:"GHSS, VELLAIYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18672", district:"38", Block:"28", label:"GHSS CHETTIKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18673", district:"38", Block:"28", label:"RUKNMSPN HS RAMANAICKENPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18674", district:"38", Block:"28", label:"GOVERNMENT HIGH SCHOOL, KURUNTHAMADAM", Sch_type:"Government", Category:"High School"},
    { key:"18675", district:"38", Block:"28", label:"VHNKUSKV HIGH SCHOOL, VADHUVARPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18676", district:"38", Block:"28", label:"PUMS, CHETTIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18677", district:"38", Block:"28", label:"P U M S, KASILINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18678", district:"38", Block:"28", label:"GHSS (B), PANDALKUDI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18679", district:"38", Block:"28", label:"VIDYA MANDIR MATRICULATION SCHOOL, RAMCO NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"18680", district:"38", Block:"28", label:"GHSS (G), PANDALKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18681", district:"38", Block:"28", label:"P U M S., KOPPUCHITAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18682", district:"38", Block:"28", label:"APTSMPS MATRICULATION SCHOOL, PULIYAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"18683", district:"38", Block:"28", label:"MUNICIPAL MIDDLE SCHOOL, MADURAI SALAI, ARUPPUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18684", district:"38", Block:"28", label:"MUNICIPAL MIDDLE SCHOOL, WEST CAR STREET, ARUPPUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18685", district:"38", Block:"28", label:"DEVANGAR MIDDLE SCHOOL., CHOKKALINGAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18686", district:"38", Block:"28", label:"CSI BOARDING MS, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18687", district:"38", Block:"28", label:"DEVANGAR PULIYAMPATTI MS, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18688", district:"38", Block:"28", label:"VELAYUTHAPURAM SALIYAR MS., ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18689", district:"38", Block:"28", label:"KAMMAVAR RAMANUJAR MS, CHINNAPULIYAMPATTI, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18690", district:"38", Block:"28", label:"HINDU NADAR M S, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18691", district:"38", Block:"28", label:"VELLAKOTTAI SALIAR MIDDLE SCHOOL., ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18692", district:"38", Block:"28", label:"S T R N GHSS, ARUPPUKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18693", district:"38", Block:"28", label:"SENGUNTHAR H.S, THIRUNAGARAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18694", district:"38", Block:"28", label:"VELLAIKOTTAI SENGUNDHAR HS., APK", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18695", district:"38", Block:"28", label:"AL AMEEN MUSLIM HSS, ARUPPUKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18696", district:"38", Block:"28", label:"SBK BOYS HSS ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18697", district:"38", Block:"28", label:"S B K GIRLS HR SEC SCHOOL, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18698", district:"38", Block:"28", label:"DEVANGAR HSS., ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18699", district:"38", Block:"28", label:"DEVANGAR GIRLS HSS, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18700", district:"38", Block:"28", label:"CSI GIRLS HSS, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18701", district:"38", Block:"28", label:"SALIYAR MAHAJANA HIGHER SECONDARY SCHOOL, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18702", district:"38", Block:"28", label:"DEVANGAR TOWN MS, ARUPPUKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18703", district:"38", Block:"28", label:"SHRI MATHRU VIDYASHRAM MATRICULATION HR SEC SCHOOL, ARUPPUKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18704", district:"38", Block:"28", label:"MPL HIGH SCHOOL, WEAVERS COLONY, ARUPPUKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"18705", district:"38", Block:"28", label:"SBK INTERNATIONAL SCHOOL(CBSE), ARUPPUKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18706", district:"38", Block:"407", label:"KVS MIDDLE SCHOOL, SENGUNDRAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18707", district:"38", Block:"407", label:"PUMS,MOOLIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18708", district:"38", Block:"407", label:"HINDU MIDDLE SCHOOL, P.KUMARALINGAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18709", district:"38", Block:"407", label:"GHS, PAVALI", Sch_type:"Government", Category:"High School"},
    { key:"18710", district:"38", Block:"407", label:"GHS,VADAMALAIKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"18711", district:"38", Block:"407", label:"GHSS CHATRAREDDIAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18712", district:"38", Block:"407", label:"GHS,PERIA PERALI", Sch_type:"Government", Category:"High School"},
    { key:"18713", district:"38", Block:"407", label:"PUMS,PELAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18714", district:"38", Block:"407", label:"PUMS,CHINNAMOOPANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18715", district:"38", Block:"407", label:"KKSSN MEMORIAL MIDDLE SCHOOL ROSALPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"18716", district:"38", Block:"407", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MEESALUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18717", district:"38", Block:"407", label:"GHSS AMATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18718", district:"38", Block:"407", label:"PUMS, THATHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18719", district:"38", Block:"407", label:"PUMS MARULOOTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"18720", district:"38", Block:"407", label:"GHS,INAMREDDIAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18721", district:"38", Block:"407", label:"PUMS O.KOVILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18722", district:"38", Block:"407", label:"GHSS, SANKARALINGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18723", district:"38", Block:"407", label:"ST.XAVIER'S HS K.PUDUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18724", district:"38", Block:"407", label:"GOVT HR SEC SCHOOL THIYAGARAJAPURAM -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18725", district:"38", Block:"407", label:"GHS,PATTAMPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"18726", district:"38", Block:"407", label:"GHSS, METTUKUNDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18727", district:"38", Block:"407", label:"HINDU MIDDLE SCHOOL, KADAMBANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18728", district:"38", Block:"407", label:"PUMS KUPPAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18729", district:"38", Block:"407", label:"PUMS THADANGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18730", district:"38", Block:"407", label:"GHSS, AVUDAIYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18731", district:"38", Block:"407", label:"MCNUPDA HSS M.CHINNAIYAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18732", district:"38", Block:"407", label:"PUMS,A.PUDHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18733", district:"38", Block:"407", label:"GHSS, VEERARPATTI APPAYANAYAKENPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18734", district:"38", Block:"407", label:"GHSS, NADUVAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18735", district:"38", Block:"407", label:"SMG MUNICIPAL MS VIRUDHUNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18736", district:"38", Block:"407", label:"MUNICIPAL MUSLIM MIDDLE SCHOOL, VIRUDHUNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18737", district:"38", Block:"407", label:"MUNICIPAL MIDDLE SCHOOL,RAMAMOORTHY ROAD", Sch_type:"Government", Category:"Middle School"},
    { key:"18738", district:"38", Block:"407", label:"MUNICIPAL MIDDLE SCHOOL, SIVAN KOVIL ST, VIRUDHUNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18739", district:"38", Block:"407", label:"K.C.S.DHANUSHKODI NADAR  MUNICIPAL MIDDLE SCHOOL,VIRUDHUNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"18740", district:"38", Block:"407", label:"KVS MIDDLE SCHOOL, VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18741", district:"38", Block:"407", label:"KPMJHS HOWA BIBI MS, VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18742", district:"38", Block:"407", label:"TELC MS VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18743", district:"38", Block:"407", label:"HAJEE P SYED MM SCHOOL VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18744", district:"38", Block:"407", label:"KGMS VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18745", district:"38", Block:"407", label:"SVA MS VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18746", district:"38", Block:"407", label:"K KAMARAJ VIDHYASALA MS VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18747", district:"38", Block:"407", label:"THIRUVALLUVAR VIDHYASALA MIDDLE SCHOOL, VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18748", district:"38", Block:"407", label:"AP SANKARALINGA NADAR GIRLS MIDDLE SCHOOL VNR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18749", district:"38", Block:"407", label:"VPP HIGHER SECONDARY SCHOOL, Virudhunagar", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18750", district:"38", Block:"407", label:"SSSN GOVT HR SEC SCHOOL, VIRUDHUNAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18751", district:"38", Block:"407", label:"TPNM GIRLS HIGHER SECONDARY SCHOOL, VIRUDHUNAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18752", district:"38", Block:"407", label:"HAJEE P SYED M HSS VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18753", district:"38", Block:"407", label:"SOWDAMBIGAI HSS  VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18754", district:"38", Block:"407", label:"KGHSS VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18755", district:"38", Block:"407", label:"VKMJHSHB GIRLS HR SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18756", district:"38", Block:"407", label:"RC HSS VIRUDHUNAGAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18757", district:"38", Block:"407", label:"KVS HSS VIRUDHUNAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18758", district:"38", Block:"407", label:" P S CHIDAMBARA NADAR SENIOR ENGLISH SCHOOL, VIRUDHUNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18759", district:"38", Block:"407", label:"VIRUDHUNAGAR MATRICULATION SCHOOL, VIRUDHUNAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"18760", district:"38", Block:"407", label:"KSHATRIYA VIDHYASALA CENTENARY SCHOOL, VIRUDHUNAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18761", district:"38", Block:"407", label:"RAO BAHADUR MSPS MUNICIPAL HIGH SCHOOL, VIRUDHUNAGAR", Sch_type:"Government", Category:"High School"},
    { key:"18762", district:"38", Block:"110", label:"KENDRIYA VIDYALAYA, VIRUDHUNAGAR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"18763", district:"38", Block:"407", label:"ASPCC MUNICIPAL HS,VIRUDHUNAGAR", Sch_type:"Government", Category:"High School"},
    { key:"18764", district:"38", Block:"407", label:"SARASWATHI AMMAL LIONS MATRICULATON SCHOOL, VIRUDHUNAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"18765", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, MANGULAM UDAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18766", district:"38", Block:"110", label:"GOVERNMENT HIGH SCHOOL, KURANDI", Sch_type:"Government", Category:"High School"},
    { key:"18767", district:"38", Block:"110", label:"FROEBEL FRIEDRICH VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, AVIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18768", district:"38", Block:"110", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, AVIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18769", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARASAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18770", district:"38", Block:"110", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MUSTAKKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18771", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELAKKALANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18772", district:"38", Block:"110", label:"GOVERNMENT HIGHER SECONDARY SCHOOL P. PUDHUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18773", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL V. NANGOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"18774", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, PAPPANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18775", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, KAMBIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18776", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, MEENATCHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18777", district:"38", Block:"110", label:"GOVERNMENT HIGH SCHOOL S.KALLUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18778", district:"38", Block:"110", label:"GOVT HIGH SCHOOL PAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18779", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL VAIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18780", district:"38", Block:"110", label:"GOVT HIGH SCHOOL PISINDI", Sch_type:"Government", Category:"High School"},
    { key:"18781", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, ACHANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18782", district:"38", Block:"110", label:"GOVERNMENT HIGH SCHOOL,MANTHOPPU", Sch_type:"Government", Category:"High School"},
    { key:"18783", district:"38", Block:"110", label:"GOVT MIDDLE SCHOOL  SOLAIKAVUNDANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18784", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEELA ALAGIANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18785", district:"38", Block:"110", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ALAGIANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18786", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL MELATHULUKKANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18787", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL THANDIYANENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"18788", district:"38", Block:"110", label:"GOVT HIGH SCHOOL THONUGAL", Sch_type:"Government", Category:"High School"},
    { key:"18789", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL KOVILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18790", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL VARALOTTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18791", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL MILL COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"18792", district:"38", Block:"110", label:"GOVERNMENT HIGH SCHOOL, KALKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"18793", district:"38", Block:"110", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, JOKILPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18794", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, VAKKANANKUNDU", Sch_type:"Government", Category:"Middle School"},
    { key:"18795", district:"38", Block:"110", label:"GOVT HIGH SCHOOL, KALUVANACHERRY", Sch_type:"Government", Category:"High School"},
    { key:"18796", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, THOPPUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18797", district:"38", Block:"110", label:"GOVT HIGH SCHOOL, CHITHUMOONDRADAIPPU", Sch_type:"Government", Category:"High School"},
    { key:"18798", district:"38", Block:"110", label:"GOVERNMENT HIGH SCHOOL, CHATIRAM PULIANGULAM", Sch_type:"Government", Category:"High School"},
    { key:"18799", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, A. THOTTIYANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18800", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, T. VEPPANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18801", district:"38", Block:"110", label:"GOVT HIGH SCHOOL MUDUKKANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18802", district:"38", Block:"110", label:"GOVERNMENT HIGH SCHOOL, VALLAPPANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18803", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, MURUGAIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18804", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, T. CHETTIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18805", district:"38", Block:"110", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SALAIMARAIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18806", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL THENOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"18807", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, SURANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"18808", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL CHOKKAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18809", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL E. THAMARAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18810", district:"38", Block:"110", label:"GOVT HIGH SCHOOL KAMBALI", Sch_type:"Government", Category:"High School"},
    { key:"18811", district:"38", Block:"110", label:"PANCHAYAT UNION MIDDLE SCHOOL, KARIAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18812", district:"38", Block:"110", label:"AMALA HIGH SCHOOL KARIAPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18813", district:"38", Block:"110", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KARIAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18814", district:"38", Block:"110", label:"GOVERNMENT HIGHER SECONDARY SCHOOL MALLANKINAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18815", district:"38", Block:"110", label:"M N M.S.P. SENTHIKUMARA NADAR HIGHER SECONDARY SCHOOL, MALLANKINAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18816", district:"38", Block:"110", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KARIAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18817", district:"38", Block:"321", label:"GOVT HIGH SCHOOL KATHALAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18818", district:"38", Block:"321", label:"GOVT HIGHER SECONDARY SCHOOL MUTHURAMALINGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18819", district:"38", Block:"321", label:"SBK HIGHER SECONDARY SCHOOL KALLOORANI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18820", district:"38", Block:"321", label:"GOVT HIGH SCHOOL, SAVVASUPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18821", district:"38", Block:"321", label:"PUMS MELAKANDAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18822", district:"38", Block:"321", label:"PUMS MADATHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18823", district:"38", Block:"321", label:"GANDHI VIDYALAYAM HSS KULASEKARANALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"18824", district:"38", Block:"321", label:"GOVT HIGH SCHOOL KULASEKARANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"18825", district:"38", Block:"321", label:"GOVT HIGH SCHOOL MARAVARPERUNGUDI", Sch_type:"Government", Category:"High School"},
    { key:"18826", district:"38", Block:"321", label:"GOVT HIGHER SECONDARY SCHOOL REDDIAPATTI M - MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18827", district:"38", Block:"321", label:"GOVT HIGHER SECONDARY SCHOOL THUMMUCHINNAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18828", district:"38", Block:"321", label:"PUMS POTHAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18829", district:"38", Block:"321", label:"NMSSV MIDDLE SCHOOL THOPPALAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18830", district:"38", Block:"321", label:"PUMS RAJAGOPALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18831", district:"38", Block:"321", label:"GOVT HIGH SCHOOL K CHETTIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18832", district:"38", Block:"321", label:"GOVT HIGH SCHOOL NALLANGULAM", Sch_type:"Government", Category:"High School"},
    { key:"18833", district:"38", Block:"321", label:"GOVT HIGHER SECONDARY SCHOOL PARALACHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18834", district:"38", Block:"321", label:"GOVT HIGH SCHOOL VADAKKUNATHAM", Sch_type:"Government", Category:"High School"},
    { key:"18835", district:"38", Block:"321", label:"PUMS SENKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18836", district:"38", Block:"321", label:"GOVT HIGHER SECONDARY SCHOOL POOLANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18837", district:"38", Block:"321", label:"GOVT HIGH SCHOOL KEELKUDI", Sch_type:"Government", Category:"High School"},
    { key:"18838", district:"38", Block:"321", label:"PUMS ILLUPPAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18839", district:"38", Block:"321", label:"PUMS K VELAYUTHAPURAM ", Sch_type:"Government", Category:"Middle School"},
    { key:"18840", district:"38", Block:"321", label:"GOVT HIGH SCHOOL VIDATHAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18841", district:"38", Block:"321", label:"PUMS ERRAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18842", district:"38", Block:"321", label:"PUMS PUDUPATTI P M", Sch_type:"Government", Category:"Middle School"},
    { key:"18843", district:"38", Block:"321", label:"PUMS PULIKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"18844", district:"38", Block:"321", label:"GOVT HIGH SCHOOL P THOTTIYANGULAM", Sch_type:"Government", Category:"High School"},
    { key:"18845", district:"38", Block:"321", label:"T.U.N.S.V.HSS,THIRUCHULI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18846", district:"38", Block:"321", label:"SETHUPATHI GOVT HIGHER SECONDARY SCHOOL TIRUCHULI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18847", district:"38", Block:"321", label:"GOVT HIGHER SECONDARY SCHOOL TAMILPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18848", district:"38", Block:"321", label:"GOVT HIGH SCHOOL UDAIYANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18849", district:"38", Block:"321", label:"PUMS KARENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"18850", district:"38", Block:"206", label:"PUMS THATCHANENDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"18851", district:"38", Block:"206", label:"GOVERNMENT HIGH SCHOOL PILLAIYARKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18852", district:"38", Block:"206", label:"PANCHAYAT UNION MIDDLE SCHOOL,  THIMMAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18853", district:"38", Block:"206", label:"GOVT HIGH SCHOOL PULVOIKARAI", Sch_type:"Government", Category:"High School"},
    { key:"18854", district:"38", Block:"206", label:"P.K.M.I. GHSS A MUKKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18855", district:"38", Block:"206", label:"KASTURBA GANDHI BALIKA VIDYALAYA, A.MUKKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18856", district:"38", Block:"206", label:"GHS SIRUVANOOR", Sch_type:"Government", Category:"High School"},
    { key:"18857", district:"38", Block:"206", label:"GOVERNMENT HIGH SCHOOL, NALOOR", Sch_type:"Government", Category:"High School"},
    { key:"18858", district:"38", Block:"206", label:"GHS ULUTHIMADAI", Sch_type:"Government", Category:"High School"},
    { key:"18859", district:"38", Block:"206", label:"GHSS KATTANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18860", district:"38", Block:"206", label:"GHS IRUNCHIRAI", Sch_type:"Government", Category:"High School"},
    { key:"18861", district:"38", Block:"206", label:"GHSS ULAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18862", district:"38", Block:"206", label:"PUMS CHOTTAMURI", Sch_type:"Government", Category:"Middle School"},
    { key:"18863", district:"38", Block:"206", label:"MARUTHUPANDIAR GOVERNMENT HR SEC SCHOOL, NARIKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18864", district:"38", Block:"206", label:"PUMS  MARAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18865", district:"38", Block:"206", label:"PUMS. SETHURAYANENTHAL", Sch_type:"Government", Category:"Middle School"},
    { key:"18866", district:"38", Block:"206", label:"KASTURBA GANDHI BALIKA VIDYALAYA, NARIKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18867", district:"38", Block:"206", label:"PUMS. MANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"18868", district:"38", Block:"206", label:"PUMS. T.VELANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18869", district:"38", Block:"206", label:"PUMS. KANAYAMARITHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"18870", district:"38", Block:"206", label:"GOVERNMENT HIGH SCHOOL , S.VALLAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18871", district:"38", Block:"206", label:"PUMS MELAPARUTHIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18872", district:"38", Block:"206", label:"PUMS VARISAIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18873", district:"38", Block:"206", label:"PUMS VEERACHOLAN WEST", Sch_type:"Government", Category:"Middle School"},
    { key:"18874", district:"38", Block:"206", label:"GHSS VEERACHOLAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18875", district:"38", Block:"206", label:"PUMS OTTANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18876", district:"38", Block:"206", label:"PUMS MINAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18877", district:"38", Block:"206", label:"PUMS CHELLAIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18878", district:"38", Block:"206", label:"PUMS SEMPONNERUNCHI", Sch_type:"Government", Category:"Middle School"},
    { key:"18879", district:"38", Block:"206", label:"PUMS. MUTHANERI", Sch_type:"Government", Category:"Middle School"},
    { key:"18880", district:"38", Block:"206", label:"PUMS V ALANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18881", district:"38", Block:"206", label:"PANCHAYAT UNION MIDDLE SCHOOL,  SULLANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18882", district:"38", Block:"206", label:"GHSS ANAIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18883", district:"38", Block:"206", label:"KASTURBA GANDHI BALIKA VIDYALAYA, ANAIKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18884", district:"38", Block:"206", label:"PUMS RENGAIYANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18885", district:"38", Block:"206", label:"GHSS, PANAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18886", district:"38", Block:"206", label:"GHSS ILUPPAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18887", district:"38", Block:"206", label:"PUMS AMMANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18888", district:"38", Block:"206", label:"PUMS. UDAYASERVAIKARANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18889", district:"38", Block:"206", label:"GHS MUTHURAMALINGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18890", district:"38", Block:"206", label:"PUMS KUMILANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18891", district:"38", Block:"411", label:"T.E.L.C .M.S.RAMASAMIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18892", district:"38", Block:"411", label:"P.U.M.S KANSAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18893", district:"38", Block:"411", label:"P.U.M.S.NEDUNKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18894", district:"38", Block:"411", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KOOMAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18895", district:"38", Block:"411", label:"P.U.M.S SETHUNARAYANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18896", district:"38", Block:"411", label:"Govt A.D.W.MIDDLE SCHOOL AGASAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18897", district:"38", Block:"411", label:"P.U.M.S MATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18898", district:"38", Block:"411", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MAHARAJAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18899", district:"38", Block:"411", label:"P.U.M.S.THAMBIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18900", district:"38", Block:"411", label:"P.U.M.S MEENAKSHIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18901", district:"38", Block:"411", label:"KALAIMAGAL HSS, S.RAMACHANDRAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18902", district:"38", Block:"411", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MELAGOPALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18903", district:"38", Block:"411", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THAILAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18904", district:"38", Block:"411", label:"GHS, AYANKARISALKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18905", district:"38", Block:"411", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, S.AMMAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18906", district:"38", Block:"411", label:"GHS, SEELNAICKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18907", district:"38", Block:"411", label:"R.C.HS.,AYANNATHAMPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"18908", district:"38", Block:"411", label:"P U M S KUNNUR", Sch_type:"Government", Category:"Middle School"},
    { key:"18909", district:"38", Block:"411", label:"KAMARAJ M.S, KUNNUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18910", district:"38", Block:"411", label:"HINDU H.S.S.  WATRAP", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18911", district:"38", Block:"411", label:"HINDU (G)H.S.WATRAP", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18912", district:"38", Block:"411", label:"NADAR H.S.S  WATRAP", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18913", district:"38", Block:"411", label:"R.C M.S W.PUDUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18914", district:"38", Block:"411", label:"SRI RENUGA HINDU HIGH SCHOOL, W.PUDHUPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18915", district:"38", Block:"411", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SUNDARAPANDIAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18916", district:"38", Block:"295", label:"P.U.M.S GOUNDAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18917", district:"38", Block:"295", label:"P.U.M.S AYYANARPURAM COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"18918", district:"38", Block:"295", label:"KAMMA KALVI SANGA HR SEC SCHOOL, SITHURAJAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18919", district:"38", Block:"295", label:"G.H.S.S.,BOOVANATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18920", district:"38", Block:"295", label:"P.U.M.S.MATHIYASENAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18921", district:"38", Block:"295", label:"TCH MS, MUTHUKUMARAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18922", district:"38", Block:"295", label:"S N (JUNIOR) G.H.S.,VELLUR", Sch_type:"Government", Category:"High School"},
    { key:"18923", district:"38", Block:"295", label:"P.U.M.S KUMILANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18924", district:"38", Block:"295", label:"G.H.S.S.,NADAIYANERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18925", district:"38", Block:"295", label:"PANCHAYAT UNION MIDDLE SCHOOL, RENGAPALAYAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18926", district:"38", Block:"295", label:"G.H.S.S., MANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18927", district:"38", Block:"295", label:"P.U.M.S JAMEENSALVARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18928", district:"38", Block:"295", label:"P.U.M.S PETHULUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18929", district:"38", Block:"295", label:"G.H.S.,ALAMARATHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18930", district:"38", Block:"295", label:"GHS VADAMALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"18931", district:"38", Block:"295", label:"P.U.M.S, CHITHAMANAICKENPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18932", district:"38", Block:"295", label:"G.H.S PUDUKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"18933", district:"38", Block:"295", label:"P.U.M.S.VELLAIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18934", district:"38", Block:"295", label:"P.U.M.S.SENGAMALAMNATCHIYARPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18935", district:"38", Block:"295", label:"G.H.S.,SUKKIRAVARPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18936", district:"38", Block:"295", label:"G.H.S.,ANAIKUTTAM", Sch_type:"Government", Category:"High School"},
    { key:"18937", district:"38", Block:"295", label:"P.U.M.S. SANARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18938", district:"38", Block:"295", label:"MEADOWS HIGHER SECONDARY SCHOOL, KALAIYARKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18939", district:"38", Block:"295", label:"G.H.S.S, MEENAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18940", district:"38", Block:"295", label:"GOVT HIGH SCHOOL, PERAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18941", district:"38", Block:"295", label:"G.H.S.,ANUPPANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"18942", district:"38", Block:"295", label:"P.U.M.S.URAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18943", district:"38", Block:"295", label:"P.U.M.S.VADAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18944", district:"38", Block:"295", label:"AVM MARIMUTHU NADAR HR SEC SCHOOL, VILAMPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18945", district:"38", Block:"295", label:"GOVT HIGHER SECONDARY SCHOOL, MARANERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18946", district:"38", Block:"295", label:"P.U.M.S KUPPANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18947", district:"38", Block:"295", label:"VEERAMAMUNIVAR HIGH SCHOOL, KRISHNAPERI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18948", district:"38", Block:"295", label:"KAMAK HIGHER SECONDARY SCHOOL, KRISHNAPERI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18949", district:"38", Block:"295", label:"S.A.V. SALA MIDDLE SCHOOL, MUTHALIPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18950", district:"38", Block:"295", label:"SRI KRS. MATRICULATION SCHOOL, VISWANATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18951", district:"38", Block:"295", label:"C.M.S. MIDDLE SCHOOL, SATCHIYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18952", district:"38", Block:"295", label:"HINDU THEVAMAR HIGHER SECONDARY SCHOOL, SIVAKASI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18953", district:"38", Block:"295", label:"MUNICIPAL HR SEC SCHOOL., AMMANKOVILPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18954", district:"38", Block:"295", label:"VELANKANNI MATHA HR SEC SCHOOL, SIVAKASI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18955", district:"38", Block:"295", label:"S.R.,G.H.S.S.,THIRUTHANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18956", district:"38", Block:"295", label:"A  U  MUNICIPAL HIGHER SECONDARY SCHOOL, SIVAKASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18957", district:"38", Block:"295", label:"S.H.N. GIRLS HIGHER SECONDARY SCHOOL, SIVAKASI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18958", district:"38", Block:"295", label:"S.N.G. GIRLS HIGHER SECONDARY SCHOOL., THIRUTHANGAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18959", district:"38", Block:"295", label:"CORONATION GIRLS HIGHER SECONDARY SCHOOL, SIVAKASI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18960", district:"38", Block:"295", label:"S.C.M.S.GIRLS HIGHER SECONDARY SCHOOL, SATCHIYAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18961", district:"38", Block:"295", label:"N.P.A.S.P.R.RATHNAVILAS HR SEC SCHOOL, SIVAKASI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18962", district:"38", Block:"295", label:"SIVAKASI HINDUNADARS GIRLS MIDDLE SCHOOL, SIVAKASI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18963", district:"38", Block:"295", label:"MUSLIM HIGHER SECONDARY SCHOOL, SIVAKASI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18964", district:"38", Block:"295", label:"SIVAKASI HINDU NADAR VICTORIA HR SEC SCHOOL, SIVAKASI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"18965", district:"38", Block:"295", label:"YRTV MAT HR SEC SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18966", district:"38", Block:"295", label:"KALAIMAGAL HIGHER SECONDARY SCHOOL, THIRUTHANGAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18967", district:"38", Block:"295", label:"SAFIRE MATRICULATION HIGHER SECONDARY  SCHOOL,SIVAKASI- THAYILPATTI ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18968", district:"38", Block:"295", label:"SHNV MATRIC HSS,SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"18969", district:"38", Block:"295", label:"SRUTHI VIDHYODHAYA , SIVAKASI", Sch_type:"Un-aided", Category:"High School"},
    { key:"18970", district:"38", Block:"295", label:"CSI HIGH SCHOOL & VTC FOR THE DEAF, SATCHIYAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"18971", district:"38", Block:"295", label:"CSI M&R SCHOOL, SATCHIAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"18972", district:"38", Block:"295", label:"G.H.S.S.,RESERVE LINE SIVAKASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18973", district:"38", Block:"295", label:"GOVT GIRLS HR SEC SCHOOL, THIRUTHANGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18974", district:"38", Block:"295", label:"G.H.S.S,VISWANATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18975", district:"38", Block:"295", label:"G.H.S.S., NARANAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18976", district:"38", Block:"295", label:"G.H.S.S, PALLAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18977", district:"38", Block:"295", label:"ANNAVI THOTTAM MUNICIPAL GIRLS HIGH SCHOOL, SIVAKASI", Sch_type:"Government", Category:"High School"},
    { key:"18978", district:"38", Block:"295", label:"GOVT HIGH SCHOOL ANAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"18979", district:"38", Block:"282", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, CHINNAKKAMANPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18980", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, METTAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"18981", district:"38", Block:"282", label:"HINDU MIDDLE SCHOOL, VENKATACHALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"18982", district:"38", Block:"282", label:"KAMMA MAHAJANA TRUST GIRLS HIGHER SECONDARY SCHOOL, VENKATACHALAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"18983", district:"38", Block:"282", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PADANTHAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18984", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, AYAN CHATRAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"18985", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, NAMBIRAJAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18986", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, A.RAMALINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18987", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, K.CHOKKALINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18988", district:"38", Block:"282", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MUTHARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18989", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, SIRUKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"18990", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, IRUKKANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"18991", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, NATHATHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18992", district:"38", Block:"282", label:"S.R.GOVERNMENT HIGHER SECONDARY SCHOOL, N.METTUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18993", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, POTHIREDDIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18994", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, PERIYA KOLLAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"18995", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, THERKUR", Sch_type:"Government", Category:"High School"},
    { key:"18996", district:"38", Block:"282", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, O.METTUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"18997", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, KANMAISURANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"18998", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, PUDHUSURANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"18999", district:"38", Block:"282", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, N.SUBBAIYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19000", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, SIVANANAINTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19001", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, THOTTILOVANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"19002", district:"38", Block:"282", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, NALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19003", district:"38", Block:"282", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, UPPATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19004", district:"38", Block:"282", label:"PANCHAYAT UNION MIDDLE SCHOOL, CHINDAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"19005", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, MUTHALNAICKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"19006", district:"38", Block:"282", label:"MPL. HIGHER SECONDARY SCHOOL, MELAGANDHI NAGAR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19007", district:"38", Block:"282", label:"MPL. MIDDLE SCHOOL, SATTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19008", district:"38", Block:"282", label:"S. GURUSAMY NADAR KRISHNAMMAL MEMORIAL MIDDLE SCHOOL, SATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19009", district:"38", Block:"282", label:"S.H.N. EDWARD MIDDLE SCHOOL, SATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19010", district:"38", Block:"282", label:"T.E.L.C. MIDDLE SCHOOL, SATTUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19011", district:"38", Block:"282", label:"ST. THERESA HIGHER SECONDARY SCHOOL, SATTUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19012", district:"38", Block:"282", label:"ST. STANISLAUS HIGHER SECONDARY SCHOOL, SATTUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19013", district:"38", Block:"282", label:"AYIRA VYSIA BOYS HR SEC SCHOOL, SATTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19014", district:"38", Block:"282", label:"S.H.N. ETHEL HARVEY GIRLS HIGHER SECONDARY SCHOOL, SATTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19015", district:"38", Block:"282", label:"S.H.N. EDWARD HIGHER SECONDARY SCHOOL, SATTUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19016", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUGIZHTHAGAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19017", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, M.V.PATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19018", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, MULLIMUNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19019", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, PADANAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19020", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, PALANGULAM-2", Sch_type:"Government", Category:"Middle School"},
    { key:"19021", district:"20", Block:"335", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PANDUKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19022", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, PANNAVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"19023", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, PASIPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19024", district:"20", Block:"335", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, S.P.PATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19025", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, KANKOLLANPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19026", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, KADANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19027", district:"20", Block:"335", label:"R.C. MIDDLE SCHOOL, KURUMILANKUDI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19028", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, KODIPANGU", Sch_type:"Government", Category:"Middle School"},
    { key:"19029", district:"20", Block:"335", label:"AMALA ANNAI HIGHER SECONDARY SCHOOL, KARANKADU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19030", district:"20", Block:"335", label:"GOVERNMENT HIGH SCHOOL, KAVALAIVENDRAN", Sch_type:"Government", Category:"High School"},
    { key:"19031", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, ARUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19032", district:"20", Block:"335", label:"SIRUMALAR HIGHER SECONDARY  SCHOOL, ANDAVOORANI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19033", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, ANJUKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19034", district:"20", Block:"335", label:"GOVERNMENT HIGH SCHOOL, THALIRMARUNGUR", Sch_type:"Government", Category:"High School"},
    { key:"19035", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, M.R PATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19036", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, VELLAIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19037", district:"20", Block:"335", label:"MUNAWWARA MIDDLE SCHOOL , THONDI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19038", district:"20", Block:"335", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19039", district:"20", Block:"335", label:"S.M. GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, THONDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19040", district:"20", Block:"335", label:"WILHELM HIGH SCHOOL , THONDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19041", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, THINAIYATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19042", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, N.PANANCHAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"19043", district:"20", Block:"335", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THIRUVADANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19044", district:"20", Block:"335", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, THIRUVADANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19045", district:"20", Block:"335", label:"ST. NORBERT R.C. HIGHER SECONDARY SCHOOL, THIRUVETRIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19046", district:"20", Block:"335", label:"GOVERNMENT HIGH SCHOOL, VATTANAM", Sch_type:"Government", Category:"High School"},
    { key:"19047", district:"20", Block:"335", label:"GOVERNMENT HIGH SCHOOL, NAMBUTHALAI", Sch_type:"Government", Category:"High School"},
    { key:"19048", district:"20", Block:"335", label:"ST. FRANCIS HIGHER SECONDARY SCHOOL, C.K.MANGALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19049", district:"20", Block:"335", label:"GOVERNMENT HIGH SCHOOL, THINAIKATHANVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"19050", district:"20", Block:"335", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MANGALAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19051", district:"20", Block:"335", label:"ST.ANTONY'S MIDDLE SCHOOL,NAGARIKATHTHAN", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19052", district:"20", Block:"335", label:"ST.MARY'S MIDDLE SCHOOL,  ORIKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19053", district:"20", Block:"335", label:"ST. ARULANANDAR HIGHER SECONDARY SCHOOL, ORIYUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19054", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, ILUPPAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19055", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, N.M.MANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19056", district:"20", Block:"335", label:"PANCHAYAT UNION MIDDLE SCHOOL, VISUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19057", district:"20", Block:"265", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, R.S.MANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19058", district:"20", Block:"265", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, R.S.MANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19059", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL, KAVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"19060", district:"20", Block:"265", label:"GOVERNMENT HIGH SCHOOL, SHOLANDUR", Sch_type:"Government", Category:"High School"},
    { key:"19061", district:"20", Block:"265", label:"GOVERNMENT  MODEL HIGHER SECONDARY SCHOOL, THIRUPPALAIKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19062", district:"20", Block:"265", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, UPPOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19063", district:"20", Block:"265", label:"SRI AMIRTA MATRICULATION SCHOOL, UPPOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19064", district:"20", Block:"265", label:"GOVERNMENT HIGH SCHOOL, VETTUKKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19065", district:"20", Block:"265", label:"ST.XAVIER'S HIGH SCHOOL, SAVERIYARPATTANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19066", district:"20", Block:"265", label:"ST.MICHAEL'S HIGHER SECONDARY SCHOOL, SENGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19067", district:"20", Block:"265", label:"SACRED HEART HIGH SCHOOL,  IRUDAYAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19068", district:"20", Block:"265", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ANANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19069", district:"20", Block:"265", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOODALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"19070", district:"20", Block:"265", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SANAVELI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19071", district:"20", Block:"231", label:"GOVERNMENT HIGH SCHOOL, KALAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"19072", district:"20", Block:"231", label:"GOVERNMENT HIGH SCHOOL, M.NEDUNGULAM", Sch_type:"Government", Category:"High School"},
    { key:"19073", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUDHUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"19074", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19075", district:"20", Block:"231", label:"GOVERNMENT HIGH SCHOOL, PIDARISERI", Sch_type:"Government", Category:"High School"},
    { key:"19076", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, THELICHATHANALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19077", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, KEELAPERUNGARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19078", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, SUDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19079", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, KUMARAKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19080", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUTHURAMALINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19081", district:"20", Block:"231", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MELAIKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19082", district:"20", Block:"231", label:"GOVERNMENT HIGH SCHOOL, PERUMALKOIL", Sch_type:"Government", Category:"High School"},
    { key:"19083", district:"20", Block:"231", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PAMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19084", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELAPERUNGARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19085", district:"20", Block:"231", label:"CRESCENT HIGH SCHOOL, PARTHIBANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"19086", district:"20", Block:"231", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PARTHIBANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19087", district:"20", Block:"231", label:"PANCHAYAT UNION MIDDLE SCHOOL, PARALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19088", district:"20", Block:"231", label:"RC MIDDLE SCHOOL, MELAKAVANOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19089", district:"20", Block:"231", label:"S.R.V.S. MIDDLE SCHOOL, LAKSHMIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19090", district:"20", Block:"231", label:"GOVERNMENT HIGH SCHOOL, KAMUDAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"19091", district:"20", Block:"231", label:"GOVERNMENT ADI DRAVIDAR WELFARE MIDDLE SCHOOL, PAMBUVILUNTHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"19092", district:"20", Block:"231", label:"GOVERNMENT ADI DRAVIDAR WELFARE MIDDLE  SCHOOL, NILAYAMBADI", Sch_type:"Government", Category:"Middle School"},
    { key:"19093", district:"20", Block:"231", label:"MUNICIPAL MIDDLE SCHOOL,  MANJALPATTANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19094", district:"20", Block:"231", label:"S.N.V. GOVERNMENT  MODEL HIGHER SECONDARY SCHOOL, EMANESWARAM ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19095", district:"20", Block:"231", label:"K.A. WEST MUSLIM HIGH SCHOOL, PARAMAKUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19096", district:"20", Block:"231", label:"K.J. EAST MUSLIM HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19097", district:"20", Block:"231", label:"SOURASHTRA HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19098", district:"20", Block:"231", label:"ALANGARA MATHA HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19099", district:"20", Block:"231", label:"AYIRA VAISYA HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19100", district:"20", Block:"231", label:"R.S. GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19101", district:"20", Block:"231", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PARAMAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19102", district:"20", Block:"231", label:"GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, KATTUPARAMAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"19103", district:"20", Block:"41", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KAMANKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19104", district:"20", Block:"41", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MANJUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19105", district:"20", Block:"41", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SATHIRAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19106", district:"20", Block:"41", label:"VASAN MATRICULATION HIGHER SECONDARY SCHOOL, SATHIRAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19107", district:"20", Block:"41", label:"GOVERNMENT HIGH SCHOOL, SEVVOOR", Sch_type:"Government", Category:"High School"},
    { key:"19108", district:"20", Block:"41", label:"GOVERNMENT HIGH SCHOOL, S.KODIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19109", district:"20", Block:"41", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUDALUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19110", district:"20", Block:"41", label:"NADAR MAHAJANA SANGAM SERMATHAI VASAN HIGH SCHOOL, ARASADIVANDAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"19111", district:"20", Block:"197", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, NAINARKOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19112", district:"20", Block:"197", label:"GOVERNMENT HIGH SCHOOL,  S.SIRUVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"19113", district:"20", Block:"197", label:"GOVERNMENT HIGH SCHOOL, KULATHUR", Sch_type:"Government", Category:"High School"},
    { key:"19114", district:"20", Block:"197", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KARADARNTHAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19115", district:"20", Block:"197", label:"GOVERNMENT HIGH SCHOOL,  ATTANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"19116", district:"20", Block:"197", label:"GOVERNMENT HIGH SCHOOL,  P.KODIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19117", district:"20", Block:"197", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, POTTAGAVAYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19118", district:"20", Block:"197", label:"GOVERNMENT HIGH SCHOOL,  KILIYUR", Sch_type:"Government", Category:"High School"},
    { key:"19119", district:"20", Block:"99", label:"GOVERNMENT HIGH SCHOOL, PAMMANENDAL", Sch_type:"Government", Category:"High School"},
    { key:"19120", district:"20", Block:"99", label:"GOVERNMENT HIGH SCHOOL, PAKKUVETTI", Sch_type:"Government", Category:"High School"},
    { key:"19121", district:"20", Block:"99", label:"KSHATRIYA HINDU NADAR HIGHER SECONDARY SCHOOL, PERUNAZHI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19122", district:"20", Block:"99", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KOVILANGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19123", district:"20", Block:"99", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KOTTAIMEDU, KAMUTHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19124", district:"20", Block:"99", label:"KSHATHRIYA NADAR HIGHER SECONDARY SCHOOL (BOYS), KAMUTHI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19125", district:"20", Block:"99", label:"KSHATHRIYA NADAR HIGHER SECONDARY SCHOOL (GIRLS), KAMUTHI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19126", district:"20", Block:"99", label:"GOWRAVA HIGH SCHOOL, KAMUTHI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19127", district:"20", Block:"99", label:"KALAVIRUTHI HIGHER SECONDARY SCHOOL, KAMUTHI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19128", district:"20", Block:"99", label:"IQBAL HIGH SCHOOL, SUNDARAPURAM,KAMUTHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19129", district:"20", Block:"99", label:"GOVERNMENT HIGH SCHOOL, T.PUNAVASAL", Sch_type:"Government", Category:"High School"},
    { key:"19130", district:"20", Block:"99", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PERAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19131", district:"20", Block:"99", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MANDALAMANICKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19132", district:"20", Block:"99", label:"GOVERNMENT HIGH SCHOOL, VAZHIMARICHAN", Sch_type:"Government", Category:"High School"},
    { key:"19133", district:"20", Block:"99", label:"GOVERNMENT HIGH SCHOOL, T.VALLAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19134", district:"20", Block:"99", label:"MUSLIM HIGHER SECONDARY SCHOOL, ABIRAMAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19135", district:"20", Block:"99", label:"V.N.S. HIGH SCHOOL , ABIRAMAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19136", district:"20", Block:"99", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, RAMASAMYPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19137", district:"20", Block:"99", label:"SAINT JOHN'S HIGHER SECONDARY SCHOOL, KEELAMUDIMANNARKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19138", district:"20", Block:"99", label:"DEVANGAR HIGHER SECONDARY SCHOOL, NEERAVI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19139", district:"20", Block:"99", label:"GOVERNMENT ADI DRAVIDAR HIGH SCHOOL, SENGAPPADAI", Sch_type:"Government", Category:"High School"},
    { key:"19140", district:"20", Block:"188", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ALANGANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19141", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL , KADAMBODAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19142", district:"20", Block:"188", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KAKKOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19143", district:"20", Block:"188", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KEELATHOOVAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19144", district:"20", Block:"188", label:"GOVERNMENT HIGH SCHOOL , KEERANOOR", Sch_type:"Government", Category:"High School"},
    { key:"19145", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL, KOLUNDURAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19146", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELATHOOVAL", Sch_type:"Government", Category:"Middle School"},
    { key:"19147", district:"20", Block:"188", label:"PANCHAYAT UNION MIDDLE SCHOOL, MUDUKULATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19148", district:"20", Block:"188", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MUDUKULATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19149", district:"20", Block:"188", label:"PALLIVASAL HIGHER SECONDARY SCHOOL, MUDUKULATHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19150", district:"20", Block:"188", label:"T.E.L.C. HIGH SCHOOL, MUDUKULATHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19151", district:"20", Block:"188", label:" PANCHAYAT UNION MIDDLE SCHOOL , PERUNGARUNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19152", district:"20", Block:"188", label:"GOVERNMENT HIGH SCHOOL , PUZHUTHIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19153", district:"20", Block:"188", label:"GOVERNMENT HIGHER SECONDARY  SCHOOL, SELVANAYAGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19154", district:"20", Block:"188", label:"GOVERNMENT HIGH SCHOOL, SEYYAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"19155", district:"20", Block:"188", label:"ROWTHER SAHIB HIGHER SECONDARY SCHOOL, THERIRUVELI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19156", district:"20", Block:"188", label:"SACRED HEART HIGHER SECONDARY SCHOOL, THIRUVARANGAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19157", district:"20", Block:"188", label:"GOVERNMENT HIGH  SCHOOL , ULAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"19158", district:"20", Block:"188", label:"GOVERNMENT HIGH SCHOOL, VALANADU", Sch_type:"Government", Category:"High School"},
    { key:"19159", district:"20", Block:"188", label:"GOVERNMENT HIGH SCHOOL , VILANGULATHUR", Sch_type:"Government", Category:"High School"},
    { key:"19160", district:"20", Block:"86", label:"GOVERNMENT HIGH SCHOOL, APPANOOR", Sch_type:"Government", Category:"High School"},
    { key:"19161", district:"20", Block:"86", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SAYALGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19162", district:"20", Block:"86", label:"GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SAYALGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19163", district:"20", Block:"86", label:"GOVERNMENT HIGH SCHOOL, KADUGUSANTHAI", Sch_type:"Government", Category:"High School"},
    { key:"19164", district:"20", Block:"86", label:"PANCHAYAT UNION MIDDLE SCHOOL, K.SATHIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19165", district:"20", Block:"86", label:"GOVERNMENT MIDDLE SCHOOL, KADALADI", Sch_type:"Government", Category:"Middle School"},
    { key:"19166", district:"20", Block:"86", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KADALADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19167", district:"20", Block:"86", label:"GOVERNMENT HIGH SCHOOL, KIDATHIRUKKAI", Sch_type:"Government", Category:"High School"},
    { key:"19168", district:"20", Block:"86", label:"GOVERNMENT HIGH SCHOOL, GURUVADI", Sch_type:"Government", Category:"High School"},
    { key:"19169", district:"20", Block:"86", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, S.THARAIKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19170", district:"20", Block:"86", label:"GOVERNMENT HIGH SCHOOL, ILANCHEMBOOR", Sch_type:"Government", Category:"High School"},
    { key:"19171", district:"20", Block:"86", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, T.M.KOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19172", district:"20", Block:"86", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, UCHINATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19173", district:"20", Block:"86", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, T.MARIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19174", district:"20", Block:"86", label:"GOVERNMENT HIGH SCHOOL, MELASELVANOOR", Sch_type:"Government", Category:"High School"},
    { key:"19175", district:"20", Block:"86", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SIKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19176", district:"20", Block:"86", label:"SYED ABUBUCKARI BADHUSA MUNSIF HUSSIN GOVERNMENT HIGHER SECONDARY SCHOOL, ERVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19177", district:"20", Block:"86", label:"GOVERNMENT HIGH SCHOOL, EDAMBADAL", Sch_type:"Government", Category:"High School"},
    { key:"19178", district:"20", Block:"86", label:"GOVERNMENT HIGH SCHOOL, VALINOKKAM", Sch_type:"Government", Category:"High School"},
    { key:"19179", district:"20", Block:"86", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MELAKIDARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19180", district:"20", Block:"86", label:"ST. XAVIER CLUNY HIGHER SECONDARY SCHOOL, M.SAVARIARPATTANAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19181", district:"20", Block:"86", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KANNIRAJAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19182", district:"20", Block:"86", label:"BHARATHA MATHA HIGH SCHOOL, NARIPPAIYUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19183", district:"20", Block:"266", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, DEVIPATTINAM SOUTH", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19184", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL , KALUGOORANI", Sch_type:"Government", Category:"Middle School"},
    { key:"19185", district:"20", Block:"266", label:"GOVERNMENT HIGH SCHOOL, PALANIVALASAI", Sch_type:"Government", Category:"High School"},
    { key:"19186", district:"20", Block:"266", label:"GOVERNMENT HIGH SCHOOL, SAMBAI", Sch_type:"Government", Category:"High School"},
    { key:"19187", district:"20", Block:"266", label:"GOVERNMENT HIGH SCHOOL, SAKKARAKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"19188", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL , VAIRAVANKOVIL", Sch_type:"Government", Category:"Middle School"},
    { key:"19189", district:"20", Block:"266", label:"GOVERNMENT HIGH SCHOOL , ATHIYOOTHU", Sch_type:"Government", Category:"High School"},
    { key:"19190", district:"20", Block:"266", label:"MOHAMEDIA HIGHER SECONDARY SCHOOL, CHITHARKOTTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19191", district:"20", Block:"266", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, R.KAVANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19192", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL , KARUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19193", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL , KATTOORANI", Sch_type:"Government", Category:"Middle School"},
    { key:"19194", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL , MADHAVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"19195", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL, PUTHENDAL", Sch_type:"Government", Category:"Middle School"},
    { key:"19196", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL , PULLANGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19197", district:"20", Block:"266", label:"GOVERNMENT HIGH SCHOOL , PERAVOOR", Sch_type:"Government", Category:"High School"},
    { key:"19198", district:"20", Block:"266", label:"ABCS KENBRIDGE HR. SEC. SCHOOL, SINGANENDAL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19199", district:"20", Block:"266", label:"GOVERNMENT HIGH SCHOOL , THORUVALUR", Sch_type:"Government", Category:"High School"},
    { key:"19200", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL , RAJASOORIYAMADAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19201", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL, VANNIVAYAL", Sch_type:"Government", Category:"Middle School"},
    { key:"19202", district:"20", Block:"266", label:"PANCHAYAT UNION MIDDLE SCHOOL, NARANAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19203", district:"20", Block:"266", label:"RAJAH'S HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19204", district:"20", Block:"266", label:"ST.JOSEPH 'S HIGHER SECONDARY SCHOOL , RAMANATHAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19205", district:"20", Block:"266", label:"RAJA M.DHINKAR HIGH SCHOOL, RAMANATHAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19206", district:"20", Block:"266", label:"D.D.VINAYAGAR HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19207", district:"20", Block:"266", label:"SYED AMMAL HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19208", district:"20", Block:"266", label:"SYED AMMAL MATRIC (GIRLS) HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19209", district:"20", Block:"266", label:"COLUMBU ALIM HIGH SCHOOL , VELIPPATTINAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19210", district:"20", Block:"266", label:"VALLAL PARI MUNICIPAL MIDDLE SCHOOL , VELIPATTINAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19211", district:"20", Block:"266", label:"MUNICIPAL GIRLS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19212", district:"20", Block:"266", label:"ARIGNAR ANNA MUNICIPAL MIDDLE SCHOOL , RAMANATHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19213", district:"20", Block:"266", label:"SCHWARTZ BOYS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19214", district:"20", Block:"266", label:"ST. ANDREW'S GIRLS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19215", district:"20", Block:"332", label:"PANCHAYAT UNION MIDDLE SCHOOL , ALANGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19216", district:"20", Block:"332", label:"PANCHAYAT UNION MIDDLE SCHOOL , EKKAGUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19217", district:"20", Block:"332", label:"C.S.I. MIDDLE SCHOOL, KILAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19218", district:"20", Block:"332", label:"ISLAMIAH HIGH SCHOOL, KILAKARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"19219", district:"20", Block:"332", label:"HAMEEDIAH BOYS HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19220", district:"20", Block:"332", label:"HAMEEDIA GIRLS HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19221", district:"20", Block:"332", label:"KHYRATHUL JALALIYA HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19222", district:"20", Block:"332", label:"MAKHDHOOMIA HIGHER SECONDARY SCHOOL, KILAKARAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19223", district:"20", Block:"332", label:"PANCHAYAT UNION MIDDLE SCHOOL , L.KARUNGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19224", district:"20", Block:"332", label:"GOVERNMENT HIGH SCHOOL, PANAIYADIYENDAL", Sch_type:"Government", Category:"High School"},
    { key:"19225", district:"20", Block:"332", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THIRU UTHRAKOSAMANGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19226", district:"20", Block:"332", label:"GOVERNMENT HIGH SCHOOL, VELANOOR", Sch_type:"Government", Category:"High School"},
    { key:"19227", district:"20", Block:"332", label:"PANCHAYAT UNION MIDDLE SCHOOL, BHARATHINAGAR,MAYAKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19228", district:"20", Block:"332", label:"SRISETHUMANICKAM HIGH SCHOOL, MELASEETHAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19229", district:"20", Block:"332", label:"GOVERNMENT HIGH SCHOOL, KANCHIRANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"19230", district:"20", Block:"332", label:"GOVERNMENT HIGH SCHOOL, KALIMANGUNDU", Sch_type:"Government", Category:"High School"},
    { key:"19231", district:"20", Block:"332", label:"PANCHAYAT UNION MIDDLE SCHOOL, LAKSHMIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19232", district:"20", Block:"332", label:"PANCHAYAT UNION MIDDLE SCHOOL, MELAPUDUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19233", district:"20", Block:"332", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PERIYAPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19234", district:"20", Block:"332", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, REGUNATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19235", district:"20", Block:"332", label:"GOVERNMENT HIGH SCHOOL, PUDUKOIL", Sch_type:"Government", Category:"High School"},
    { key:"19236", district:"20", Block:"332", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THIRUPPULLANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19237", district:"20", Block:"332", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THINAIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19238", district:"20", Block:"332", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VANNANGUNDU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19239", district:"20", Block:"332", label:"N.M.S. HIGH SCHOOL, METHALODAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19240", district:"20", Block:"332", label:"ST. JOSEPH'S HIGHER SECONDARY SCHOOL, MUTHUPETTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19241", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL, RAMESWARAM NO.I", Sch_type:"Government", Category:"Middle School"},
    { key:"19242", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, RAMESWARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19243", district:"20", Block:"169", label:"SRI PARVATHAVARTHINI AMMAN GIRLS HIGHER SECONDARY SCHOOL, RAMESWARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19244", district:"20", Block:"169", label:"KENDRIYA VIDYALAYA HIGH SCHOOL, RAMESWARAM", Sch_type:"Central Govt", Category:"High School"},
    { key:"19245", district:"20", Block:"169", label:"VIVEKANANDA VIDYALAYA MIDDLE SCHOOL, RAMESWARAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19246", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL, GANDHI NAGAR, MANDAPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19247", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL, KARAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"19248", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL, MEYYAMPULI", Sch_type:"Government", Category:"Middle School"},
    { key:"19249", district:"20", Block:"169", label:"KAMARAJ MIDDLE SCHOOL, THANGACHIMADAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19250", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, THANGACHIMADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19251", district:"20", Block:"169", label:"HOLY CROSS GIRLS HIGHER SECONDARY SCHOOL, THANGACHIMADAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19252", district:"20", Block:"169", label:"PUNITHA YAGAPPAR  HIGHER SECONDARY SCHOOL, THANGACHIMADAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19253", district:"20", Block:"169", label:"PRINCE OF PEACE MIDDLE SCHOOL, THANGACHIMADAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19254", district:"20", Block:"169", label:"ST. ANTONY'S MIDDLE SCHOOL, PAMBAN", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19255", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PAMBAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19256", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL , CHINNAPPALAM, PAMBAN", Sch_type:"Government", Category:"Middle School"},
    { key:"19257", district:"20", Block:"169", label:"PERUNTHALAIVAR KAMARAJAR MIDDLE SCHOOL, PAMBAN", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19258", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL , PIRAPPANVALASAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19259", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL, SUNDARAMUDAYAN", Sch_type:"Government", Category:"High School"},
    { key:"19260", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL , THARAVAITHOPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"19261", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, VEDHALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19262", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, IRUMENI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19263", district:"20", Block:"169", label:"ST. JOSEPH'S HIGHER SECONDARY SCHOOL, VERKOTTU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19264", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, ALAGANKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19265", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL, KALKINATRUVALASAI", Sch_type:"Government", Category:"High School"},
    { key:"19266", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL,KUYAVANKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19267", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL, KUMBARAM", Sch_type:"Government", Category:"High School"},
    { key:"19268", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, PUDUMADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19269", district:"20", Block:"169", label:"BAHRUDIN BOYS GOVERNMENT HIGHER SECONDARY SCHOOL, PANAIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19270", district:"20", Block:"169", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PANAIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19271", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL, PATTANAMKATHAN", Sch_type:"Government", Category:"Middle School"},
    { key:"19272", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, RETTAIYURANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19273", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, SATHANKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19274", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL, THERBOGI", Sch_type:"Government", Category:"Middle School"},
    { key:"19275", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL, THAMARAIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19276", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, UCHIPULI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19277", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL, PERUNGULAM", Sch_type:"Government", Category:"High School"},
    { key:"19278", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL,VALANTHARAVAI", Sch_type:"Government", Category:"High School"},
    { key:"19279", district:"20", Block:"169", label:"ARABI OLLIYULLA HIGH SCHOOL PUDUVALASAI ", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19280", district:"20", Block:"169", label:"KENDRIYA VIDYALAYA HIGHER SECONDARY SCHOOL, MANDAPAM CAMP", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"19281", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, MANDAPAM CAMP", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19282", district:"20", Block:"169", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, MANDAPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19283", district:"20", Block:"169", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, KADUKKAIVALASAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19284", district:"20", Block:"169", label:"PANCHAYAT UNION MIDDLE SCHOOL,DHANUSKODI", Sch_type:"Government", Category:"Middle School"},
    { key:"19285", district:"28", Block:"351", label:"P. THANGAMMAL MEMORIAL HSS POLPETTAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19286", district:"28", Block:"351", label:"GOOD SHEPHERD SCHOOL FOR THE HEARING IMPAIRED, BRYANT NAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19287", district:"28", Block:"351", label:"TAGORE MEMORIAL MIDDLE SCHOOL, THOOTHUKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19288", district:"28", Block:"351", label:"ST. IGNATIUS HSS, INNACIARPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19289", district:"28", Block:"351", label:"DIRAVIAPURAM TNDTA MS MUTHUKRISHNAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19290", district:"28", Block:"351", label:"CORPORATION HSS SAMUELPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19291", district:"28", Block:"351", label:"LITTLE FLOWER RC HS, THERESPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19292", district:"28", Block:"351", label:"CALDWELL HSS THOOTHUKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19293", district:"28", Block:"351", label:"ST. JOSEPH GIRLS HSS CRUZPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19294", district:"28", Block:"351", label:"ST. FRANCIS XAVIERS HSS, CHINNAKOVIL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19295", district:"28", Block:"351", label:"ST.ALOYSIUS GHSS, CHINNAKOIL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19296", district:"28", Block:"351", label:"DASNEVIS MATHA GIRLS HSS, LIONS TOWN", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19297", district:"28", Block:"351", label:"ST.LASALLE  HIGHER SECONDARY SCHOOL, GEORAGE ROAD, TUTICORIN", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19298", district:"28", Block:"351", label:"KARAPETTAI NADAR BOYS HSS, LIONS TOWN", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19299", district:"28", Block:"351", label:"TNDTA MS SHANMUGAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19300", district:"28", Block:"351", label:"KARAPETTAI NADAR GIRLS HIGHER SECONDARY SCHOOL, THOOTHUKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19301", district:"28", Block:"351", label:"HOLY CROSS (G)HSS, VE ROAD", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19302", district:"28", Block:"351", label:"HOLY CROSS ANGLO INDIAN HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19303", district:"28", Block:"351", label:"CM HS, TR NAIDU STREET", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19304", district:"28", Block:"351", label:"RCHS, RATHINAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19305", district:"28", Block:"351", label:"SAV HSS,MELUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19306", district:"28", Block:"351", label:"VICTORIA GIRLS HSS THOOTHUKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19307", district:"28", Block:"351", label:"CM HSS, RENGANATHAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19308", district:"28", Block:"351", label:"MUNICIPAL MS ,GIN FACTORY ROAD  THOOTHUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19309", district:"28", Block:"351", label:"MUNICIPAL MS SHUNMUGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19310", district:"28", Block:"351", label:"SUBBIAH VIDYALAYAM GIRLS HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19311", district:"28", Block:"351", label:"BHARATHIYAR VIDYALAYAM HSS THOOTHUKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19312", district:"28", Block:"351", label:"SEENA VANA GHSS, THOOTHUKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19313", district:"28", Block:"351", label:"THANGAM MS, ANNANAGAR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19314", district:"28", Block:"351", label:"ST MARYS BOYS HSS, MILLERPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19315", district:"28", Block:"351", label:"AMM SINNAMANI NADAR HS THOOTHUKUDI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19316", district:"28", Block:"351", label:"SAKTHI VINAYAGAR HINDU VIDYALAYA HSS CHINDAMBARANAG", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19317", district:"28", Block:"351", label:"MUNICIPAL MS, SOUTH NEW STREET", Sch_type:"Government", Category:"Middle School"},
    { key:"19318", district:"28", Block:"111", label:"RC MIDDLE SCHOOL KALIYAVOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19319", district:"28", Block:"111", label:"GHSS SAVALAPERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19320", district:"28", Block:"111", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  KEELAPOOVANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19321", district:"28", Block:"111", label:"HINDU MIDDLE SCHOOL CHOKALINGAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19322", district:"28", Block:"111", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SEKKARAKKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19323", district:"28", Block:"111", label:"GHS KASILINGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"19324", district:"28", Block:"111", label:"NARAYANAN MS ELLAINAYACKENPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19325", district:"28", Block:"111", label:"PUMS POTTALURANI", Sch_type:"Government", Category:"Middle School"},
    { key:"19326", district:"28", Block:"111", label:"PUMS VALLANADU SOUTH", Sch_type:"Government", Category:"Middle School"},
    { key:"19327", district:"28", Block:"111", label:"VTVD GHSS  VALLANADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19328", district:"28", Block:"111", label:"SARAH MATRICULATION HIGHER SECONDNARY SCHOOL, HAMIDIYANAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19329", district:"28", Block:"111", label:"TNDTA MS MANAKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19330", district:"28", Block:"111", label:"TNDTA MS ALWARKARKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19331", district:"28", Block:"111", label:"PUMS ARAMPANNAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19332", district:"28", Block:"111", label:"MEERA MATRICULATION SCHOOL, ARAMPANNAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"19333", district:"28", Block:"111", label:"GHSS KONGARAYAKURUCHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19334", district:"28", Block:"111", label:"PUMS MURAPPANADU", Sch_type:"Government", Category:"Middle School"},
    { key:"19335", district:"28", Block:"111", label:"GOVT. H.S.ANAVARATHANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"19336", district:"28", Block:"111", label:"MMMS SEYDUNGANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19337", district:"28", Block:"111", label:"HINDU JEYALAKSHMI MS KILAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19338", district:"28", Block:"111", label:"GHSS KARUNKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19339", district:"28", Block:"111", label:"PUMS MELASRIYANTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19340", district:"28", Block:"111", label:"PUMS MANALVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19341", district:"28", Block:"111", label:"GHS  KALVOY", Sch_type:"Government", Category:"High School"},
    { key:"19342", district:"28", Block:"111", label:"GHSS RAMANUJAMPUDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19343", district:"28", Block:"111", label:"TNDTA MS THATHANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19344", district:"28", Block:"297", label:"PUMS PONNANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"19345", district:"28", Block:"297", label:"APC VEERABAHU HS PUDUPATTI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19346", district:"28", Block:"297", label:"MK HS PATMANAGARAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19347", district:"28", Block:"297", label:"MM MAT HSS PATMANAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19348", district:"28", Block:"297", label:"TNDTA MS PEROOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19349", district:"28", Block:"297", label:"GHSS SIVAKALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19350", district:"28", Block:"297", label:"SMA GHSS SIRUTHONDANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19351", district:"28", Block:"297", label:"GHSS VALAVALLAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19352", district:"28", Block:"297", label:"ST ALOYSIUS MS KORKAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19353", district:"28", Block:"297", label:"GHSS UMARIKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19354", district:"28", Block:"297", label:"TDTA GOOD SHEPHERD HS IDAIYERKADU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19355", district:"28", Block:"297", label:"PUMS PULVAVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19356", district:"28", Block:"297", label:"ST ANTONYS HSS PAZHAIYAKAYAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19357", district:"28", Block:"297", label:"GHSS MUKKANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19358", district:"28", Block:"297", label:"RC MS NADUVAIKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19359", district:"28", Block:"297", label:"POPE MEMORIAL HR.SEC.SCHOOL SAWYERPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19360", district:"28", Block:"297", label:"ST.MARYS (G)HSS,  SAWYERPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19361", district:"28", Block:"297", label:"AMA HINDU HSS SIVATHAIYAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19362", district:"28", Block:"297", label:"GGHSS ERAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19363", district:"28", Block:"297", label:"ST THERESAL MS ERAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19364", district:"28", Block:"297", label:"TUCKER HR SEC. SCHOOL.. PANNAIVILAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19365", district:"28", Block:"297", label:"TNDTA MS PANDARAVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19366", district:"28", Block:"297", label:"SEVENTH DAY ADVENTIST MATRIC, SRIVAIKUNDAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19367", district:"28", Block:"297", label:"ST JAMES MATRIC HS SRIVAIKUNTAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19368", district:"28", Block:"297", label:"SRI KGS B HSS SRIVAIKUNTAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19369", district:"28", Block:"297", label:"SRI A.K.S GOVT. GIRLS HIGHER SECONDARY SCHOOL , SRIVAIKUNTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19370", district:"28", Block:"297", label:"TVRK H VID MAT HSS SRIVAIKUNTAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19371", district:"28", Block:"297", label:"ULAGAMMAL ANNI MEMORIAL MS SRIVAIKUNDAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19372", district:"28", Block:"8", label:"PU MS PULIYANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19373", district:"28", Block:"8", label:"TNDTA MS PALANIAPPAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19374", district:"28", Block:"8", label:"TNDTA MIDDLE SCHOOL, UDAYARKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19375", district:"28", Block:"8", label:"PUMS MELAVELLAMADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19376", district:"28", Block:"8", label:"GVG TNDTA MS  PILLAIYANMANAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19377", district:"28", Block:"8", label:"ERS MS MUKUPERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19378", district:"28", Block:"8", label:"ST.MARK'S HSS  MUKUPERI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19379", district:"28", Block:"8", label:"TNDTA MS NEIVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19380", district:"28", Block:"8", label:"ST.MARY'S (G) HS, KATCHANAVILAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19381", district:"28", Block:"8", label:"SRI GANESAR HSS PANICKANADARKUDIERUPPU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19382", district:"28", Block:"8", label:"KAMARAJ  HIGHER SECONDARY SCHOOL NALUMAVADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19383", district:"28", Block:"8", label:"PUMS, KURUMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19384", district:"28", Block:"8", label:"ST.LUCIA HS KURUMBUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19385", district:"28", Block:"8", label:"HINDU MS VARANDIAVEL& AGOPALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19386", district:"28", Block:"8", label:"TNDTA HS, MARANTHALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"19387", district:"28", Block:"8", label:"PUMS,SOUTH AUTHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"19388", district:"28", Block:"8", label:"RC. ST. MICHAEL'S MIDDLE SCHOOL, SERNTHAMANGALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19389", district:"28", Block:"8", label:"ST.JOSEPH HSS PUNNAIKAYAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19390", district:"28", Block:"8", label:"HINDU HSS ALWARTHIRUNAGARI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19391", district:"28", Block:"8", label:"GHSS MAVADIPANNAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19392", district:"28", Block:"8", label:"S SHUNMUGASUNDARA NADAR HSS AUTHOOR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19393", district:"28", Block:"8", label:"GHSS AUTHOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19394", district:"28", Block:"8", label:"PUMS, NORTH AUTHOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"19395", district:"28", Block:"8", label:"JAMES MEMORIAL MATRIC HR. SEC SCHOOL, PRAKASAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19396", district:"28", Block:"8", label:"DEAF MS THIRUMARAIYOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19397", district:"28", Block:"8", label:"SOLOMON MATRICULATION HIGHER SECONDARY SCHOOL, NAZARETH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19398", district:"28", Block:"8", label:"ST.JOHN'S GIRLS' HIGHER SECONDARY SCHOOL , NAZARETH", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19399", district:"28", Block:"8", label:"MARGOSCHIS HIGHER SECONDARY SCHOOL NAZARETHH", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19400", district:"28", Block:"8", label:"PUMS , AMBALACHERI", Sch_type:"Government", Category:"Middle School"},
    { key:"19401", district:"28", Block:"8", label:"ASP MS KARUVELAMPADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19402", district:"28", Block:"8", label:"TDTA KURUKALPERI HSS ASIRVATHAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19403", district:"28", Block:"8", label:"EKA RATCHAGAR SABAI HIGHER SECONDARY SCHOOL, SALAIPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19404", district:"28", Block:"320", label:"ASA GOVT BOYS HSS, TIRUCHENDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19405", district:"28", Block:"320", label:"R C HIGH SCHOOL, AMALINAGAR TIRUCHENDUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19406", district:"28", Block:"320", label:"SM GHSS,TIRUCHENDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19407", district:"28", Block:"320", label:"PUMS KEELAKANAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19408", district:"28", Block:"320", label:"CENTRAL HR.SEC.SCHOOL, KAYALPATANAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19409", district:"28", Block:"320", label:"ZUBAIDA HSS, KAYALPATNAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19410", district:"28", Block:"320", label:"GGHSS,KAYALPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19411", district:"28", Block:"320", label:"PUMS ODAKKARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19412", district:"28", Block:"320", label:"L.K. MATRICULATION HIGHER SECONDARY SCHOOL, KAYALPATNAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19413", district:"28", Block:"320", label:"L.K. HR. SEC. SCHOOL, KAYALPATNAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19414", district:"28", Block:"320", label:"PUMS THAIKKA ST", Sch_type:"Government", Category:"Middle School"},
    { key:"19415", district:"28", Block:"320", label:"KAMLAVATI SENIOR SECONDARY SCHOOL, SAHUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19416", district:"28", Block:"320", label:"K A HR SEC SCHOOL,PEYANVILAI ARUMUGANERI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19417", district:"28", Block:"320", label:"ARULNERI HINDU HS THIRUMALAIYAPPAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19418", district:"28", Block:"320", label:"TNDTA MS, RAJAMANIYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19419", district:"28", Block:"320", label:"GGHSS,ARUMUGANERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19420", district:"28", Block:"320", label:"SSMID.S, MADATHUVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19421", district:"28", Block:"320", label:"SARASWATHI MS, ARUMUGANERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19422", district:"28", Block:"320", label:"HIN. HS, ARUMUGANERI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19423", district:"28", Block:"320", label:"TNDTA MS, VEERAMANICKAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19424", district:"28", Block:"320", label:"ST.JOSEPH HSS, ADAIKALAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19425", district:"28", Block:"320", label:"ST. THOMAS HSS, VIRAPANDIYANPATNAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19426", district:"28", Block:"320", label:"HINDU HS, POOCHIKADU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19427", district:"28", Block:"320", label:"TNDTA MIDDLE SCHOOL  JERUSELAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19428", district:"28", Block:"320", label:"PERUNTHALAIVAR KAMARAJAR GOVERNMENT HIGHER SECONDARY SCHOOL,  THERIKUDIYIRUPPU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19429", district:"28", Block:"320", label:"GHSS, KAYAMOZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19430", district:"28", Block:"320", label:"PUMS KEELANADUNALUMOOLAIKINARU", Sch_type:"Government", Category:"Middle School"},
    { key:"19431", district:"28", Block:"320", label:"GOVT HS, NADUNALUMOOLAIKINARU", Sch_type:"Government", Category:"High School"},
    { key:"19432", district:"28", Block:"320", label:"PUMS N.MUTHAIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19433", district:"28", Block:"320", label:"ST ANTONYS RC MIDDLE SCHOOL PUTHUKINATHANVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19434", district:"28", Block:"320", label:"TDTA MARY SOUNDARAPANDIAN HIGH SCHOOL, PITCHIVILAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19435", district:"28", Block:"369", label:"PUMS   PUTHUMANAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19436", district:"28", Block:"369", label:"MARY ANN BEST GIRL'S HIGHER SECONDARY SCHOOL, PANDARANCHETTIVILAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19437", district:"28", Block:"369", label:"TDTA HSS, CHRISTIANAGARAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19438", district:"28", Block:"369", label:"ADW MS SUTHANTHIRANAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"19439", district:"28", Block:"369", label:"KKR TDTA HIGH SCHOOL, PARAMANKURICHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19440", district:"28", Block:"369", label:"BAM HSS, VELLALANVILLAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19441", district:"28", Block:"369", label:"ST.ANNES, HIGH SCHOOL KEELARAMASAMIAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19442", district:"28", Block:"369", label:"SI.BA.SIVANTHI ADITANAR HIGH SCHOOL VEPPANKADU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19443", district:"28", Block:"369", label:"AMBROSE HIGHER SECONDARY SCHOOL, MEIGNANAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19444", district:"28", Block:"369", label:"ELLIOTT TUXFORD GIRLS HIGHER SECONDARY SCHOOL, MEGNANAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19445", district:"28", Block:"369", label:"SENTHIL ANDAVAR MS, SEERKATCHI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19446", district:"28", Block:"369", label:"Anitha Kumaran Matric & Higher Secondary School, Thandupathu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19447", district:"28", Block:"369", label:"SRI RKC HR. SEC. SCHOOL, UDANGUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19448", district:"28", Block:"369", label:"GHS, VENKATRAMANUJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"19449", district:"28", Block:"369", label:"THIRU ARUL HSS, KULASEKARANPATINAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19450", district:"28", Block:"369", label:"VALLIAMMAIYAR GIRLS HR.SEC.SCHOOL KULASEKHARAPATNAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19451", district:"28", Block:"369", label:"ST. MARYS MS MANAPADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19452", district:"28", Block:"369", label:"ST. JOSEPH HSS, MANAPADU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19453", district:"28", Block:"280", label:"TNDTA MS, THERIPANAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19454", district:"28", Block:"280", label:"AVE MARIA MATRIC HR.SEC.SCHOOL,PANNAMPARAI JUNCTION", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19455", district:"28", Block:"280", label:"TDTA RANJI AARON MEMORIAL HIGHER SECONDARY SCHOOL,ANANDAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19456", district:"28", Block:"280", label:"ST. Micheal's High School, Nochikulam", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19457", district:"28", Block:"280", label:"RC MS .NEDUNKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19458", district:"28", Block:"280", label:"CMS EVANGELICAL HIGH SCHOOL, KALUNGUVILAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19459", district:"28", Block:"280", label:"GHSS KOMBANKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19460", district:"28", Block:"280", label:"TNDTA MS, SIRAPPUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19461", district:"28", Block:"280", label:"RC MS, CHIDAMBARAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19462", district:"28", Block:"280", label:"GHS, PUDUKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19463", district:"28", Block:"280", label:"SRI MUTHARAMMAN HINDU MS , VIJAYARAMAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19464", district:"28", Block:"280", label:"TNDTA MS, ANBIN NAGARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19465", district:"28", Block:"280", label:" St.Michael's Higher secondary school, Mudalur", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19466", district:"28", Block:"280", label:"SRI SARATHA SACRED HEART MS, PALLAKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19467", district:"28", Block:"280", label:"SRI PARVATHI MS, PITCHIVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19468", district:"28", Block:"280", label:"GHS SUNDANKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"19469", district:"28", Block:"280", label:"TNDTA ALL SAINTS MS,POHLIARPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19470", district:"28", Block:"280", label:"ST.MARYS HR SEC SCHOOL,  POTHAKALANVILLAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19471", district:"28", Block:"280", label:"SSN., GOVT.HSS KOMMADIKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19472", district:"28", Block:"280", label:"Manalmatha Hr.Sec.School, Sokkankudieruppu", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19473", district:"28", Block:"280", label:"TNDTA MS, NALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19474", district:"28", Block:"280", label:"St.Anne's High School, Thattarmadam", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19475", district:"28", Block:"280", label:"TNTDA MS,ATHISAYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19476", district:"28", Block:"280", label:"ST JOSEPH'S RC MS KADAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19477", district:"28", Block:"280", label:"St,Antony's Middle School, Puchikadu", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19478", district:"28", Block:"280", label:"PUMS, USARATHUKUDIYERUPU", Sch_type:"Government", Category:"Middle School"},
    { key:"19479", district:"28", Block:"280", label:"PUMS,PUTHANTHARUVAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19480", district:"28", Block:"280", label:"Little Flower High School, Periyathalai", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19481", district:"28", Block:"280", label:"GHSS PADUKKAPATHU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19482", district:"28", Block:"280", label:"SRI MARIAMMAN HINDU HR. SEC. SCHOOL , SATHANKULAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19483", district:"28", Block:"280", label:"TNDTA RMP PULAMADAN CHETTIAR NATIONAL HIGHER SECONDARY SCHOOL , SATHANKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19484", district:"28", Block:"280", label:"A E IBRAHIM MiDDLE SCHOOL, SATHANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19485", district:"28", Block:"280", label:"SACRED HEART HSS,SATHANKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19486", district:"28", Block:"280", label:"ST JOSEPH GIRLS HIGHER SECONDARY SCHOOL, SATTANKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19487", district:"28", Block:"217", label:"TDTA HIGH SCHOOL, K.KAILASAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19488", district:"28", Block:"217", label:"RC MS PULIYAMPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19489", district:"28", Block:"217", label:"GOVERNMENT HIGH SCHOOL PARIVILLIKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"19490", district:"28", Block:"217", label:"MUTHUKARUPPAN HARIJAN HSS,SUBBAMMALPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19491", district:"28", Block:"217", label:"T.D.T.A.K.G.M.HR.SEC.SCHOOL, S.KAILASAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19492", district:"28", Block:"217", label:"PUMS,OSANOOTHU", Sch_type:"Government", Category:"Middle School"},
    { key:"19493", district:"28", Block:"217", label:"PUMS,KEELAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19494", district:"28", Block:"217", label:"GHSS, PASUVANTHANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19495", district:"28", Block:"217", label:"ST JOSEPHS HR.SEC.SCHOOL, KEELAMUDIMAN", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19496", district:"28", Block:"217", label:"PUMS, VEERANSUNDARALINGAMNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"19497", district:"28", Block:"217", label:"V.O.C. GHSS, Ottapidaram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19498", district:"28", Block:"217", label:"TMB MCAVOY RURAL HSS, OTTAPIDARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19499", district:"28", Block:"217", label:"JOHN THE BAPTIST HSS PUTHIYAMPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19500", district:"28", Block:"217", label:"GHSS KURUKKUSALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19501", district:"28", Block:"217", label:"PU MS,KEELASEITHALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19502", district:"28", Block:"217", label:"GHS, EPPODUMVENDRAN", Sch_type:"Government", Category:"High School"},
    { key:"19503", district:"28", Block:"217", label:"GHS, VEDANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"19504", district:"28", Block:"217", label:"GOVERNMENT HIGHER SECONDARY SCHOOL VEPPALODAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19505", district:"28", Block:"217", label:"PUMS, PATTNAMARUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"19506", district:"28", Block:"217", label:"GHS KOLLANKINARU", Sch_type:"Government", Category:"High School"},
    { key:"19507", district:"28", Block:"217", label:"GHSS THARUVAIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19508", district:"28", Block:"217", label:"NATARAJAN HSS, KATTUNAYAKANPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19509", district:"28", Block:"402", label:"T.V.A.NALLALAGU NADAR HIGHER SECONDARY SCHOOL , PERILOVANPATTI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19510", district:"28", Block:"402", label:"GHS Kodangipatti", Sch_type:"Government", Category:"High School"},
    { key:"19511", district:"28", Block:"402", label:"T.N.D.T.A. MS, Chithavanayakkanpatti", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19512", district:"28", Block:"402", label:"S.R. MS, AyanBommayapuram", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19513", district:"28", Block:"402", label:"PUMS, Kalugachalapuram", Sch_type:"Government", Category:"Middle School"},
    { key:"19514", district:"28", Block:"402", label:"GHS Padarnthapuli", Sch_type:"Government", Category:"High School"},
    { key:"19515", district:"28", Block:"402", label:"GHS Ariyanayagipuram", Sch_type:"Government", Category:"High School"},
    { key:"19516", district:"28", Block:"402", label:"GHSS Sivagnanapuram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19517", district:"28", Block:"402", label:"PUMS, SAKKAMMALPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19518", district:"28", Block:"402", label:"TNDTA MS Marthandampatti", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19519", district:"28", Block:"402", label:"H.N. MS, Panaiyur", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19520", district:"28", Block:"402", label:"GHSS,KULATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19521", district:"28", Block:"402", label:"TNDTA MS, V.VEDAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19522", district:"28", Block:"402", label:"St.Paul's HS - V.Vedapatti", Sch_type:"Un-aided", Category:"High School"},
    { key:"19523", district:"28", Block:"402", label:"GOVT HIGH SCHOOL MELMANTHAI ", Sch_type:"Government", Category:"High School"},
    { key:"19524", district:"28", Block:"402", label:"PUMS, Thathaneri", Sch_type:"Government", Category:"Middle School"},
    { key:"19525", district:"28", Block:"402", label:"H.G.K.HS  K.Thangammalpuram", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19526", district:"28", Block:"402", label:"ST.ANTONY'S HS, PERIYASAMYPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19527", district:"28", Block:"402", label:"St.Sebastian MS, Vembar", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19528", district:"28", Block:"402", label:"GHSS  Vembar", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19529", district:"28", Block:"402", label:"ST.MARY'S MATRICULATION SCHOOL, VEMBAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"19530", district:"28", Block:"402", label:"GHSS VILATHIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19531", district:"28", Block:"402", label:"C.A.R.M.GHSS-Vilathikulam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19532", district:"28", Block:"402", label:"ST. TERESAL'S MS SIPPIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19533", district:"28", Block:"402", label:"ST.LOUIS HSS, KEELA VAIPPAR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19534", district:"28", Block:"402", label:"PUMS, K.SUBRAMANIAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19535", district:"28", Block:"402", label:"PUMS, Neeravi Puthupatti", Sch_type:"Government", Category:"Middle School"},
    { key:"19536", district:"28", Block:"402", label:"PUMS, Nedunkulam", Sch_type:"Government", Category:"Middle School"},
    { key:"19537", district:"28", Block:"402", label:"N.H.S. Karisalkulam", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19538", district:"28", Block:"257", label:"HNHS PUDUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19539", district:"28", Block:"257", label:"GHSS Pudur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19540", district:"28", Block:"257", label:"PUMS KULAKATANKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"19541", district:"28", Block:"257", label:"PUMS METTILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"19542", district:"28", Block:"257", label:"P V K S GOVT HIGH SCHOOL, SENNAMAREDDIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"19543", district:"28", Block:"257", label:"PUMS MUTHUSAMIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19544", district:"28", Block:"257", label:"PUMS MANIYAKARANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"19545", district:"28", Block:"257", label:"PUMS KEELAKARANTHAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19546", district:"28", Block:"257", label:"PUMS SIVALARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"19547", district:"28", Block:"257", label:"PUMS THAPPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"19548", district:"28", Block:"257", label:"PUMS L V PURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19549", district:"28", Block:"257", label:"HNHSS N.VEDAPATTI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19550", district:"28", Block:"257", label:"TNDTA MS NATHATHUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19551", district:"28", Block:"257", label:"GHSS, BOOTHALAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19552", district:"28", Block:"257", label:"PUMS KOVILKUARETTIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19553", district:"28", Block:"257", label:"TDTA JAYARAJ ANNAPACKIAM HIGH SCHOOL, NAGALAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19554", district:"28", Block:"257", label:"PUMS VOWALTHOTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"19555", district:"28", Block:"257", label:"SKK H HS Nagalapuram", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19556", district:"28", Block:"257", label:"SAN HSS Nagalapuram", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19557", district:"28", Block:"257", label:"PUMS SINGILIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"19558", district:"28", Block:"257", label:"GHS KADALKUDI", Sch_type:"Government", Category:"High School"},
    { key:"19559", district:"28", Block:"257", label:"PUMS AYANVADAMALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19560", district:"28", Block:"257", label:"MPMS RAMACHANDRAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19561", district:"28", Block:"257", label:"PUMS AYANKARISALKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19562", district:"28", Block:"257", label:"PUMS MAVILPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"19563", district:"28", Block:"257", label:"PUMS, MASARPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"19564", district:"28", Block:"257", label:"HNMS MUTHULAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19565", district:"28", Block:"257", label:"GHS M.KOTTUR", Sch_type:"Government", Category:"High School"},
    { key:"19566", district:"28", Block:"257", label:"PUMS MELANAMBIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19567", district:"28", Block:"257", label:"GHSS SENKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19568", district:"28", Block:"257", label:"GHSS VEMBUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19569", district:"28", Block:"257", label:"NMS VEERAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19570", district:"28", Block:"257", label:"PUMS, KEELA ARUNACHALAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19571", district:"28", Block:"350", label:"PUMS UMARIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19572", district:"28", Block:"350", label:"PUMS MELATHATTAPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19573", district:"28", Block:"350", label:"VOC GOVT MS THATTAPARAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19574", district:"28", Block:"350", label:"TNDTA MS PERURANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19575", district:"28", Block:"350", label:"GHSS MUDIVAITHANENDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19576", district:"28", Block:"350", label:"TNDTA MS SERVAIKARANMADAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19577", district:"28", Block:"350", label:"TNDTA ABISHEKANATHAR HSS KULAYANKARISAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19578", district:"28", Block:"350", label:"TNDTA MS KORAMPALLAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19579", district:"28", Block:"350", label:"DEVAPITCHAI MEMORIAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"19580", district:"28", Block:"350", label:"GOOD SHEPHERD MODEL SCHOOL KORAMPALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19581", district:"28", Block:"350", label:"SDR SCHOOL KORAMPALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19582", district:"28", Block:"350", label:"TDTA PSP HSS PUTHUKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19583", district:"28", Block:"350", label:"GHSS SORISPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19584", district:"28", Block:"350", label:"BMC HIGHER SECONDARY SCHOOL  MILLERPURAM THOOTHUKUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19585", district:"28", Block:"350", label:"LUCIA TRANSITIONAL MIDDLE SCHOOL FOR THE INTELLECTUAL DISABLED", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19586", district:"28", Block:"350", label:"ALAGAR PUBLIC SCHOOL THOOTHUKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19587", district:"28", Block:"350", label:"ST.XAVIER'S HIGH SCHOOL, T.SAVERIARPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19588", district:"28", Block:"350", label:"S.P.K.NADAR M.S MAPPILAIYURANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19589", district:"28", Block:"350", label:"ST. ANTONY'S HS SILUVAIPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19590", district:"28", Block:"350", label:"V.V.D MEMORIAL HSS AROCKIYAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19591", district:"28", Block:"350", label:"HARBOUR HR SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19592", district:"28", Block:"350", label:"ST. ANTONY'S MATRIC. HR.SEC.SCHOOL, MACHADO NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19593", district:"28", Block:"350", label:"TNDTA MS MULLAKKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19594", district:"28", Block:"350", label:"K.T.KOSALRAM HIGH SCHOOL, MUTHIAHPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19595", district:"28", Block:"350", label:"GHSS M.THANGAMMALPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19596", district:"28", Block:"350", label:"ST.ANTONY'S HS M.SAVARIYARPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19597", district:"28", Block:"350", label:"SPIC NAGAR HIGHER SECONDARY SCHOOL MUTHIAH PURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19598", district:"24", Block:"6", label:"Govt. HSS, Alangulam", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19599", district:"24", Block:"6", label:"HINDU HIGHER SECONDARY SCHOOL, MAYAMANKURICHI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19600", district:"24", Block:"6", label:"Govt. HSS, Nettur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19601", district:"24", Block:"6", label:"SRI VAIDHYALINGA SWAMY HIGH SCHOOL - ALADIPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19602", district:"24", Block:"6", label:"West Tirunelveli HSS, Nallur", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19603", district:"24", Block:"6", label:"Govt. HSS, Maranthai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19604", district:"24", Block:"6", label:"St. ASSISI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19605", district:"24", Block:"6", label:"Muslim HS, Veeranam", Sch_type:"Un-aided", Category:"High School"},
    { key:"19606", district:"24", Block:"6", label:"Govt. HSS, Veeranam", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19607", district:"24", Block:"6", label:"GRAMA COMMITTEE HSS, Venkateswarapuram", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19608", district:"24", Block:"6", label:"CET Tennyson HS, Keelakalangal", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19609", district:"24", Block:"6", label:"MCM HR SEC SCHOOL ANNAMALAIPUDUR, TIRUNELVELI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19610", district:"24", Block:"6", label:"ST. JOHN'S HSS - VADIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19611", district:"24", Block:"6", label:"V.N.T MIDDLE SCHOOL,KARUVANTHA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19612", district:"24", Block:"6", label:"Govt. HSS, Uthumalai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19613", district:"30", Block:"9", label:"GHS, MANNARKOVIL", Sch_type:"Government", Category:"High School"},
    { key:"19614", district:"30", Block:"9", label:"PUMS - VKPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19615", district:"30", Block:"9", label:"NADAR UNION MS- MUTHALIYARPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19616", district:"30", Block:"9", label:"SENAI THALAIVAR HSS - VKPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19617", district:"30", Block:"9", label:"SACRED HEART HS- IRUTHAYAKULAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19618", district:"30", Block:"9", label:"ANDREW MATRIC HS - VKPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19619", district:"30", Block:"9", label:"BRILLIANT N&PS - IRUTHAYAKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19620", district:"30", Block:"9", label:"ST. MARY'S HSS- VKPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19621", district:"30", Block:"9", label:"PLWA HSS - VKPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19622", district:"30", Block:"9", label:"AMALI GIRLS HSS- IRUTHAYAKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19623", district:"30", Block:"9", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PAPANASAM UPPERDAM", Sch_type:"Government", Category:"High School"},
    { key:"19624", district:"30", Block:"9", label:"LOTUS MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"19625", district:"30", Block:"9", label:"PUMS-AMBASAMUDRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19626", district:"30", Block:"9", label:"MOHAITHEEN FATHIMA MS AMBASAMUDRAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19627", district:"30", Block:"9", label:"ST. JOSEPH'S HS-AMBASAMUDRAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19628", district:"30", Block:"9", label:"A.V.RM.V.GOVT(G)HSS-AMBASAMUDRAM -MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19629", district:"30", Block:"9", label:"A.V.RM.V.MATRIC HSS-AMBASAMUDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19630", district:"30", Block:"9", label:"P.S.V. N&PS-AMBASAMUDRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19631", district:"30", Block:"9", label:"VIKASA SCHOOL, AMBASAMUDRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19632", district:"30", Block:"9", label:"THEERTHAPATHI HSS-AMBASAMUDRAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19633", district:"30", Block:"9", label:"ST.XAVIERS MS, ADIVARAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19634", district:"30", Block:"9", label:"REACH MATRIC HSS-CHETTIMEDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19635", district:"30", Block:"9", label:"GHSS, VELLANGULI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19636", district:"30", Block:"9", label:"SETHU SUBRAMANIA MS - OORKAD", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19637", district:"30", Block:"9", label:"SRI KAMARAJ MS -AGASTHIYARPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19638", district:"30", Block:"9", label:"CAMBRIDGE MHS - AGASTHIYARPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19639", district:"30", Block:"9", label:"CAMBRIDGE INTERNATIONAL CBSE SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"19640", district:"30", Block:"9", label:"GHS - MANIMUTHAR", Sch_type:"Government", Category:"High School"},
    { key:"19641", district:"30", Block:"9", label:"GHS - MANJOLAI", Sch_type:"Government", Category:"High School"},
    { key:"19642", district:"30", Block:"9", label:"ST.MICHAEL HS- SINGAMPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19643", district:"30", Block:"9", label:"PUMS - KODARANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19644", district:"30", Block:"46", label:"C.M.S.E.S DAVID MEMORIAL HIGHER SECONDARY SCHOOL, KARISAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19645", district:"30", Block:"46", label:"GGHS CHERANMAHADEVI", Sch_type:"Government", Category:"High School"},
    { key:"19646", district:"30", Block:"46", label:"MUSLIM COM HS CHERANMAHADEVI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19647", district:"30", Block:"46", label:"PERIYAR GHSS, CHERANMAHADEVI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19648", district:"30", Block:"46", label:"MAH.GANDHI MAT.S KALLIDAIKURICHI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19649", district:"30", Block:"46", label:"GHSS KALLIDAIKURICHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19650", district:"30", Block:"46", label:"TILAK VIDYALAYA HR SEC SCHOOL KALLIDAIKURICHI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19651", district:"30", Block:"46", label:"BHARATHIYAR GHSS VEERAVANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19652", district:"30", Block:"46", label:"ST.JOHNS HSS VEERAVANALLUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19653", district:"30", Block:"46", label:"THE RAMASESHIER HIGHER SECONDARY SCHOOL, PATTAMADAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19654", district:"30", Block:"46", label:"GGHSS PATHAMADAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19655", district:"30", Block:"46", label:"TRINITY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19656", district:"30", Block:"46", label:"TDTA HS MELASEVAL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19657", district:"30", Block:"46", label:"PANNAI VENKATARAMIER HS GOPALASAMUDRAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19658", district:"30", Block:"46", label:"SCHOENSTATT MATRICULATION SCHOOL, PIRANCHERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"19659", district:"30", Block:"46", label:"GOVT HIGH SCHOOL POTTAL", Sch_type:"Government", Category:"High School"},
    { key:"19660", district:"24", Block:"89", label:"ST JOSEPHS HS VEIKKALIPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19661", district:"24", Block:"89", label:"GHS, KAVOOR", Sch_type:"Government", Category:"High School"},
    { key:"19662", district:"24", Block:"89", label:"CHATRAM BHARATHI GIRLS HS, THERKKU KADAYAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19663", district:"24", Block:"89", label:"SRI KAILASAM MEMORIAL HIGH SCHOOL THIRUMALAIAPPAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19664", district:"24", Block:"89", label:"CHATRAM BHARATHI HR SEC SCHOOL KADAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19665", district:"24", Block:"89", label:"GHSS, MUDALIYARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19666", district:"24", Block:"89", label:"GANDHIGRAM SANTHI HIGHER SECONDARY SCHOOL FOR THE HEARING IMPAIRED, SIVASAILAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19667", district:"24", Block:"89", label:"SRI PARAMAKALYANI HSS, ALWARKURICHI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19668", district:"24", Block:"89", label:"ST ANTONY'S HSS, KALYANIPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19669", district:"24", Block:"89", label:"PUMS, PAPPANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19670", district:"24", Block:"89", label:"GANAPATHY MS, PAPPANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19671", district:"24", Block:"89", label:"GHS, MYLAPPAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"19672", district:"24", Block:"89", label:"KSHATHRIYAR COM.MS VENGADAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"19673", district:"24", Block:"89", label:"SSV HSS MATHAPATTINAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19674", district:"24", Block:"89", label:"THIRU MURUGAN HS VALLIAMMALPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19675", district:"24", Block:"89", label:"TDTA MS INTHANKATTALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19676", district:"24", Block:"91", label:"M.M.HSS,IDAIKAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19677", district:"24", Block:"91", label:"GHSS,KASIDARMAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19678", district:"24", Block:"91", label:"T.M.MS NEDUVAYAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19679", district:"24", Block:"91", label:"GOVT HR  SEC SCHOOL NEDUVAYAL ACHANPUDUR TENKASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19680", district:"24", Block:"91", label:"HINDUMARAVA MS KODIKURICHI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19681", district:"24", Block:"91", label:"HINDU MS SIVARAMA PETTAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19682", district:"24", Block:"91", label:"SRI ULAGA HSS M.KRISHNAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19683", district:"24", Block:"91", label:"GHSS(G)KADAYANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19684", district:"24", Block:"91", label:"GHSS(B) KADAYANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19685", district:"24", Block:"91", label:"DARUSSALAM HSS KADAYANALLUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19686", district:"24", Block:"91", label:"HIDHAYATHUL ISLAM HIGHER SECONDARY SCHOOL, KADAYANALLUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19687", district:"24", Block:"91", label:"PETTAIMUSLIM HIGHER SECONDARY SCHOOL KADAYANALLUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19688", district:"24", Block:"91", label:"RATNA HIGH SCHOOL,KADAYANALLUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19689", district:"24", Block:"91", label:"MASOOD THAIKA HIGHER SECONDARY SCHOOL  KADAYANALLUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19690", district:"24", Block:"91", label:"HINDU MS,THIRIKUDAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19691", district:"24", Block:"91", label:"LIONS MAHATMA MAT SC ,MS PURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19692", district:"24", Block:"91", label:"EVEREST MATRIC HIGHER SECONDARY SCHOOL ACHAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19693", district:"24", Block:"91", label:"GHS,IDAIKAL", Sch_type:"Government", Category:"High School"},
    { key:"19694", district:"24", Block:"91", label:"RUKMANI HS,MANGALAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19695", district:"24", Block:"91", label:"KING OF KINGS MAT.SCH,MANGALAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19696", district:"24", Block:"91", label:"CRESCENT MATRICULATION HIGHER SECONDARY SCHOOL,ACHAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19697", district:"24", Block:"91", label:"SATHANA VIDYALAYA MATRICULATION SCHOOL, BALAARUNACHALAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19698", district:"24", Block:"91", label:"GOVT HS(ADW),VALASAI", Sch_type:"Government", Category:"High School"},
    { key:"19699", district:"24", Block:"91", label:"PUMS,CHIDAMBARAPERI", Sch_type:"Government", Category:"Middle School"},
    { key:"19700", district:"24", Block:"91", label:"GHSS,ANAIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19701", district:"24", Block:"91", label:"GHS,KULAYANERI", Sch_type:"Government", Category:"High School"},
    { key:"19702", district:"24", Block:"91", label:"GHS,POIGAI", Sch_type:"Government", Category:"High School"},
    { key:"19703", district:"24", Block:"91", label:"GOVT.HSS,URMELAZHAGIAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19704", district:"24", Block:"91", label:"GOVT HIGH SCHOOL,VELAYUTHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"19705", district:"30", Block:"92", label:"CMS EVANGELICAL MS MELAPATHAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19706", district:"30", Block:"92", label:"GOVT HIGH SCHOOL PADMANERI", Sch_type:"Government", Category:"High School"},
    { key:"19707", district:"30", Block:"92", label:"AMIR JAMAL HSS IDAIYANKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19708", district:"30", Block:"92", label:"SRI NAMMALVAR MS KALLIKULAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19709", district:"30", Block:"92", label:"ANNAI THERESA HS MEENAVANKULAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19710", district:"30", Block:"92", label:"K.A.M.P. MEERANIA HSS KALAKAD", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19711", district:"30", Block:"92", label:"GOVT HR SEC SCHOOL KALAKAD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19712", district:"30", Block:"92", label:"GOMATHI ARULNERI GIRLS HR SEC SCHOOL KALAKAD", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19713", district:"30", Block:"92", label:"WALKER HIGHER SECONDARY SCHOOL, DOHNAVUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19714", district:"30", Block:"92", label:"TVS GOVT HR SEC SCHOOL THIRUGURUNGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19715", district:"30", Block:"92", label:"GOVT(B)  HR SEC SCHOOL, ERUVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19716", district:"30", Block:"92", label:"GOVT (G)  HR SEC SCHOOL,  ERUVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19717", district:"24", Block:"119", label:"GHSS -PULLUKATTUVALASAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19718", district:"24", Block:"119", label:"GOVT HR SEC SCHOOL -VINAITHEERTHANADARPATTI - 627808", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19719", district:"24", Block:"119", label:"PUNITHA ARULAPPAR HSS-AVUDAIYANOOR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19720", district:"24", Block:"119", label:"PAPN GHSS-AVUDAIYANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19721", district:"24", Block:"119", label:"SIVA SAKTHI VIDYALAYA PODIANOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"19722", district:"24", Block:"119", label:"GHSS AYANKURUMBALAPERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19723", district:"24", Block:"119", label:"GHSS SIVAGURUNATHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19724", district:"24", Block:"119", label:"BAREN BRUCK HSS BUNGALOW SURANDAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19725", district:"24", Block:"119", label:"SERMATHAIVASAN HSS SURANDAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19726", district:"24", Block:"119", label:"GHSS MELAPAVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19727", district:"24", Block:"119", label:"AV HS KEELAPAVOOR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19728", district:"24", Block:"119", label:"NADAR HINDU HS KEELAPAVOOR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19729", district:"24", Block:"119", label:"GHS AYOTHIYAPURIPATTANAM", Sch_type:"Government", Category:"High School"},
    { key:"19730", district:"24", Block:"119", label:"GHSS POOLANGULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19731", district:"24", Block:"119", label:"QUEENS N&PS POOLANGULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19732", district:"24", Block:"119", label:"PUMS,GURUSAMIPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19733", district:"24", Block:"119", label:"HINDU MS-MADATHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19734", district:"24", Block:"119", label:"TPS(B)GHSS PAVOORCHATRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19735", district:"24", Block:"119", label:"AVVAIYAR G.GHSS-PAVOORCHATRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19736", district:"24", Block:"119", label:"ANNAI VELANKANNI MATRIC HR.SEC.SCHOOL PAVOORCHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19737", district:"24", Block:"119", label:"MALAVIYA VIDYA KENDRAM PAVOORCHATRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19738", district:"24", Block:"119", label:"GHSS,VEERAKERALAMPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19739", district:"24", Block:"119", label:"ANNA HSS-VEERAKERALAMPUTHUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19740", district:"24", Block:"119", label:"ST.ANTONY HSS-VEERAKERALAMPUTHUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19741", district:"24", Block:"119", label:"GOVT HIGH SCHOOL BOOBALASAMUDRAM", Sch_type:"Government", Category:"High School"},
    { key:"19742", district:"30", Block:"174", label:"ST PETERS HSS,UKIRANKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19743", district:"30", Block:"174", label:"NIM HS,THERKUPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19744", district:"30", Block:"174", label:"PUMS,SUBBIAHPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19745", district:"30", Block:"174", label:"GHS,CHETTIKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"19746", district:"30", Block:"174", label:"GHS,MELAPILLAIYARKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19747", district:"30", Block:"174", label:"GOVT HSS,VADAKKU CHELIYANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19748", district:"30", Block:"174", label:"GHSS,GANGAIKONDAN", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19749", district:"30", Block:"174", label:"GHSS,PALAMADAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19750", district:"30", Block:"174", label:"TDTA MS,THENKALAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19751", district:"30", Block:"174", label:"DR. AMBEDKAR GOVT.(A.D.W) HIGHER SECONDARY SCHOOL, NALLAMMALPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19752", district:"30", Block:"174", label:"GOVT(ADW) GIRLS HR SEC SCHOOL, NALLAMMALPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19753", district:"30", Block:"174", label:"GHS PALLAMADAI", Sch_type:"Government", Category:"High School"},
    { key:"19754", district:"30", Block:"174", label:"GHSS,MANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19755", district:"30", Block:"229", label:"SAROJINI MEMMS ,SIRUKKANKURICHI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19756", district:"30", Block:"174", label:"G(ADW)HSS,THULUKKARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19757", district:"30", Block:"229", label:"GHSS NADUKALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19758", district:"30", Block:"229", label:"KINGS MAT SCHOOL, KONDANAGARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19759", district:"30", Block:"174", label:"GHSS,SUTHAMALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19760", district:"30", Block:"174", label:"GHS,NARASINGANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"19761", district:"30", Block:"204", label:"CMS MS MARUTHAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19762", district:"30", Block:"204", label:"GOVT HSS MARUTHAKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19763", district:"30", Block:"204", label:"CMS MS ALWANERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19764", district:"30", Block:"204", label:"CMS  MS PARUTHIPADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19765", district:"30", Block:"204", label:"GOVT HS KARANTHANERI", Sch_type:"Government", Category:"High School"},
    { key:"19766", district:"30", Block:"204", label:"ST.JOSEPH'S MS THAMBUPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19767", district:"30", Block:"204", label:"GGHSS NANGUNERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19768", district:"30", Block:"204", label:"GOVT (B) HSS NANGUNERI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19769", district:"30", Block:"204", label:"SRI RAMANUJA MAT HS NANGUNERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"19770", district:"30", Block:"204", label:"GOVT HSS MOOLAIKARAIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19771", district:"30", Block:"204", label:"St. ALOYSIOUS MS CHINTHAMANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19772", district:"30", Block:"204", label:"GOVT HSS MUNANJIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19773", district:"30", Block:"204", label:"ST. ANTONY'S HIGH SCHOOL. SOMANATHAPERI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19774", district:"30", Block:"204", label:"TDTA MS KALUVOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19775", district:"30", Block:"204", label:"ST.ANTONY'S MS KARANKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19776", district:"30", Block:"204", label:"GOVT HSS THALAPATHYSAMUDRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19777", district:"30", Block:"204", label:"GOVT HIGH SCHOOL THIRUVENGADANATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"19778", district:"30", Block:"204", label:"TDTA MS PARAPPADI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19779", district:"30", Block:"204", label:"GOVT HSS PARAPPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19780", district:"30", Block:"204", label:"ST JOHN'S MATRICULATION SCHOOL, PARAPPADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"19781", district:"30", Block:"204", label:"RC MS THINAIYOORANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19782", district:"30", Block:"204", label:"GOVT HS KARIYANDI", Sch_type:"Government", Category:"High School"},
    { key:"19783", district:"30", Block:"204", label:"MG RURAL HS VENKATRAYAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19784", district:"30", Block:"204", label:"NADAR MS PATTARAIKATTIVILAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19785", district:"30", Block:"204", label:"ST. MARY'S  HSS MANNARPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19786", district:"30", Block:"204", label:"KENDRA VIDYALAIYA HSS, KENDRA VIDYALAIYA HSS, S.V.NARAYANAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"19787", district:"30", Block:"204", label:"GHS, NORTH VIJAYANARAYANAM", Sch_type:"Government", Category:"High School"},
    { key:"19788", district:"30", Block:"204", label:"RC MS AZHAGAPPAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19789", district:"30", Block:"204", label:"SENTHIL ANDAVAN ARULNERI HIGH SCHOOL VIJAYA ACHAMPADU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19790", district:"30", Block:"204", label:"A.V JOSEPH GHSS, ITTAMOZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19791", district:"30", Block:"221", label:"GHS,SEEVALAPERI", Sch_type:"Government", Category:"High School"},
    { key:"19792", district:"30", Block:"221", label:"R.C.MS, THONITHURAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19793", district:"30", Block:"221", label:"HARIHAR MIDDLE SCHOOL , MELAPATTAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19794", district:"30", Block:"221", label:"TDTAMS MANARKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19795", district:"30", Block:"221", label:"PUMS, NADUVAKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"19796", district:"30", Block:"221", label:"GHSS, BURKITMANAGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19797", district:"30", Block:"221", label:"SRI CHELLIAH MS MELAPUTHANERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19798", district:"30", Block:"221", label:"TDTA MIDDLE SCHOOL ,MELAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19799", district:"30", Block:"221", label:"SRI SARADA VIDYALAYA HIGH SCHOOL, ARIYAKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19800", district:"30", Block:"221", label:"SRI SARADA MATRIC HIGHER SECONDARY SCHOOL, ARIYAKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19801", district:"30", Block:"221", label:"GHS, KUTHUKAL", Sch_type:"Government", Category:"High School"},
    { key:"19802", district:"30", Block:"221", label:"OASIS MHSS, TUTICORIN ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19803", district:"30", Block:"221", label:"ST.JOSEPH'S HS, V M AROCKIANATHAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"19804", district:"30", Block:"221", label:"ST.ANTONY PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19805", district:"30", Block:"221", label:"KRGHSS, REDDIARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19806", district:"30", Block:"221", label:"T.D.T.A. JAYARAJ ANNAPACKIAM MATRIC HR. SEC. SCHOOL, TUCKKARAMMALPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19807", district:"30", Block:"221", label:"TDTA MS,ARAIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19808", district:"30", Block:"221", label:"GHSS, MUNNEERPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19809", district:"30", Block:"221", label:"GHS, THARUVAI", Sch_type:"Government", Category:"High School"},
    { key:"19810", district:"30", Block:"221", label:"PUMS, SENGULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19811", district:"30", Block:"221", label:"TDTA MS, OMANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19812", district:"30", Block:"221", label:"TDTA MS, KONGANTHANPARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19813", district:"30", Block:"221", label:"TDTA MS, SIVANTHIPATTI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19814", district:"30", Block:"221", label:"GOVT ADW MS, PUTHUNAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"19815", district:"30", Block:"221", label:"ST.JOSEPH HS, NARANAMMALPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19816", district:"30", Block:"221", label:"PUMS, URUDAYARKUDIYERUPPU", Sch_type:"Government", Category:"Middle School"},
    { key:"19817", district:"30", Block:"221", label:"THIRU TKM KAILASAMPILLAI MS, THATCHANNALUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19818", district:"30", Block:"221", label:"BUNGALOW MS, THATCHANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19819", district:"30", Block:"221", label:"VEDIC VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19820", district:"30", Block:"221", label:"ADI DRAVIDA HS, RAMAIANPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19821", district:"30", Block:"221", label:"GHS, KAMMALANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19822", district:"30", Block:"221", label:"PONNUSAMY MS, RAJAVALLIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19823", district:"30", Block:"221", label:"SRI AYYACHAMY MS, KARAIYERUPPU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19824", district:"30", Block:"221", label:"GHS, CHATHIRAMKUDIYERUPPU", Sch_type:"Government", Category:"High School"},
    { key:"19825", district:"30", Block:"221", label:"KENBRIDGE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19826", district:"30", Block:"221", label:"Venkadasamy Naidu Hr. Sec. School, Sankarnagar", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19827", district:"30", Block:"221", label:"SANKAR HR SEC SCHOOL, SANKARNAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19828", district:"30", Block:"221", label:"GITA KRISHNMORTHY VIDHYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"19829", district:"30", Block:"221", label:"ST.ANNES  PHYSICALY HANDICAPPED MIDDLE SCHOOL, ANAIARKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19830", district:"30", Block:"221", label:"PUNCHAYAT MIDDLE SCHOOL, PIRANTHANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19831", district:"30", Block:"229", label:"PANCHAYAT UNION MIDDLE SCHOOL,POTHUKUDI", Sch_type:"Government", Category:"Middle School"},
    { key:"19832", district:"30", Block:"229", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, POTHUKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19833", district:"24", Block:"6", label:"RC MS KUTHAPANCHAN", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19834", district:"24", Block:"6", label:"TDTA MS PUTHUPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19835", district:"24", Block:"6", label:"GHSS,MARUTHAMPUTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19836", district:"24", Block:"6", label:"HINDU MS UDAIYAMPULI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19837", district:"30", Block:"229", label:"PUMS,KUMARASAMIYAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19838", district:"30", Block:"229", label:"HINDU MS ODAIKARAI THULUKKAPATTI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19839", district:"30", Block:"229", label:"HINDU MIDDLE SCHOOL,PAPPAKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19840", district:"30", Block:"229", label:"SOKKALAL HSS MUKKUDAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19841", district:"30", Block:"229", label:"ST PAUL HSS SINGAMPARAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19842", district:"30", Block:"229", label:"GHSS,VADAKKU ARIYANAYAGIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19843", district:"30", Block:"229", label:"HINDU MS HARIKESAVANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19844", district:"30", Block:"229", label:"GAJENDRA VARATHAR MS ATHALANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19845", district:"30", Block:"262", label:"ST.ALOYSIUS MIDDLE SCHOOL T.KALLIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19846", district:"30", Block:"262", label:"ST. ALOYSIUS HSS T.KALLIKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19847", district:"30", Block:"262", label:"ST.PETER'S MS RAYAPPAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19848", district:"30", Block:"262", label:"SEEMATTI AMMAL MUTHAMMAL REDDIYAR GOVT HR.SECONDARY SCHOOL SAMUGARENGAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19849", district:"30", Block:"262", label:"ST.JOSEPH HS PUTHUKUDIYIRUPPU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19850", district:"30", Block:"262", label:"TDTA MS RADHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19851", district:"30", Block:"262", label:"RC PERIYA NAYAGI MIDDLE SCHOOL RADHAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19852", district:"30", Block:"262", label:"NVC GOVT HR.SECONDARY SCHOOL RADHAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19853", district:"30", Block:"262", label:"ST ANDREWS MATRICULATION HIGHER SECONDARY SCHOOL RADHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19854", district:"30", Block:"262", label:"RAMALINGAM KUMARASWAMY RAMALINGAM MIDDLE SCHOOL,PARAMESHWARAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19855", district:"30", Block:"262", label:"ANANDHA MIDDLE SCHOOL UDHAYATHOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19856", district:"30", Block:"262", label:"GHS ATHUKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"19857", district:"30", Block:"262", label:"HINDU  MIDDLE SCHOOL  KOODANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19858", district:"30", Block:"262", label:"ST.ANNE'S HIGHER SECOONDARY SCHOOL KOODANKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19859", district:"30", Block:"262", label:"MALAVIYA VIDYA KENDRAM KOODANKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19860", district:"30", Block:"262", label:"TDTA HS KOODANKULAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19861", district:"30", Block:"262", label:"GHSS KOODANKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19862", district:"30", Block:"262", label:"PUMS VIJAYAPATHY", Sch_type:"Government", Category:"Middle School"},
    { key:"19863", district:"30", Block:"262", label:"BISHOP ROCHE  HSS IDINDAKARAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19864", district:"30", Block:"262", label:"PANCHAYAT UNION MIDDLE SCHOOL SANGANAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19865", district:"30", Block:"262", label:"BHARATH MIDDLE SCHOOL VAIRAVIKINARU", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19866", district:"30", Block:"262", label:"MUSLIM MS THIRUVAMBALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19867", district:"30", Block:"262", label:"HINDU YADAVA MIDDLE SCHOOL TIRUVAMBALAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19868", district:"30", Block:"262", label:"ST.KITHERI HIGH SCHOOL  KOOTHENKUZHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19869", district:"30", Block:"262", label:"PUBLIC SEVAA SANGAM MIDDLE SCHOOL  MUDAVANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19870", district:"30", Block:"262", label:"GHS KASTHURIRENGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"19871", district:"30", Block:"262", label:"JOHNY MIDDLE SCHOOL  KUMBIKULAM NORTH", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19872", district:"30", Block:"262", label:"GHS SEELATHIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19873", district:"30", Block:"262", label:"RC MS MITTATHARKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19874", district:"30", Block:"262", label:"SSMM MS KOTTAIKARUNKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19875", district:"30", Block:"262", label:"PANCHAYAT UNION MIDDLE SCHOOL, KUMARAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19876", district:"30", Block:"262", label:"TDTA MIDDLE SCHOOL IDAIYANKUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19877", district:"30", Block:"262", label:"TDTA MS KEERAIKARANTHATTU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19878", district:"30", Block:"262", label:"TDTA MS  SUVISESHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19879", district:"30", Block:"262", label:"STELLA MARIS GIRLS HIGH SCHOOL, TISAIYANVILAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19880", district:"30", Block:"262", label:"SAMARIAH ST. JOHN'S HR. SEC. SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19881", district:"30", Block:"262", label:"HOLY REDEEMERS HSS TISAYANVILAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19882", district:"30", Block:"262", label:"SRI RAMAKRISHNA HSS TISAYANVILAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19883", district:"30", Block:"262", label:"CALDWELL CENTINARY MEMORIAL HSS EDAYANKUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19884", district:"30", Block:"262", label:"JAYARRAJESH MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19885", district:"30", Block:"262", label:"GHS SHANMUGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"19886", district:"30", Block:"262", label:"TDTA MS MADATHACHAMPADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19887", district:"30", Block:"262", label:"MALAVIYA VIDYA KENDRAM APPUVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19888", district:"30", Block:"262", label:"LIONS MATRICULATION HIGHER SECONDARY SCHOOL APPUVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19889", district:"30", Block:"262", label:"RC MS ANAIKARAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19890", district:"30", Block:"262", label:"S M CADER MEERA SAIBO HSS PETTAIKULAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19891", district:"30", Block:"262", label:"TDTA MIDDLE SCHOOL KARAICHUTHUPUTHUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19892", district:"30", Block:"262", label:"TDTA MIDDLE SCHOOL NAVALADY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19893", district:"30", Block:"262", label:"ST XAVIER'S MS AVUDAIYALPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19894", district:"30", Block:"262", label:"DAKSHINA  MARA NADAR SANGAM SIVANTHI ADITHANAR HIGHER SECONDARY SCHOOL,NAVALADY NAVALADY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19895", district:"30", Block:"262", label:"PUMS KUNJANVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"19896", district:"30", Block:"262", label:"ST JOSEPH MS UVARI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19897", district:"30", Block:"262", label:"SRI SUYAMBULINGASAMY MIDDLE SCHOOL  UVARI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19898", district:"30", Block:"262", label:"ST ANTONY'S MIDDLE SCHOOL  KOOTAPPANAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19899", district:"30", Block:"262", label:"GHSS KUTTAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19900", district:"30", Block:"262", label:"ST MARY'S HSS UVARI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19901", district:"30", Block:"262", label:"MALAVIYA VIDYA KENDRAM UVARI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19902", district:"24", Block:"288", label:"GOVERNMENT HIGH SCHOOL,MELASHENGOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"19903", district:"24", Block:"288", label:"Sri Moolam Sastiyaptha Poorthi Sattanatha Karayalar Government Boys Higher Secondary School, Shencottai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19904", district:"24", Block:"288", label:"SRI RAMA MANDIRAM GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, Shengottai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19905", district:"24", Block:"288", label:"Sri Jayendra Vidyalaya Matric Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19906", district:"24", Block:"288", label:"GOVERNMENT HIGH SCHOOL KATTALAIKUDIYARUPPU", Sch_type:"Government", Category:"High School"},
    { key:"19907", district:"24", Block:"288", label:"PULARI MATRICULATION SCHOOL,  PUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"19908", district:"24", Block:"288", label:"LAKSHMI HARIHARA HIGH SCHOOL ELATHUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19909", district:"24", Block:"288", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SEEVANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19910", district:"24", Block:"288", label:"PUNITHA ARULANANTHAR HIGHER SECONDARY SCHOOL AGARAKATTU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19911", district:"24", Block:"288", label:"GOVERNMENT HIGHER SECONDARY SCHOOL AYIKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19912", district:"24", Block:"288", label:"SIVA SARASWATHI VIDYALAYA HIGHER SECONDARY SCHOOL AYIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19913", district:"24", Block:"288", label:"HINDU NADAR URAVIN MURAI COMMITTEE DR.SIVANTHI ADITANAR MIDDLE SCHOOL SAMBAVAR VADAKARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19914", district:"24", Block:"288", label:"GOVERNMENT HIGHER SECONDARY SCHOOL SAMBAVAR VADAKARAI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19915", district:"24", Block:"307", label:"GHSS VADAKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19916", district:"24", Block:"307", label:"MUSLIM HS VADAKARAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19917", district:"24", Block:"307", label:"HUSSAINIYA MUSLIM HIGH SCHOOL VADAKARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"19918", district:"24", Block:"307", label:"RKV MS PANPOLI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19919", district:"24", Block:"307", label:"GHSS, PANPOLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19920", district:"24", Block:"307", label:"A.G.HS, KANAKAPILLAIVALASAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19921", district:"24", Block:"307", label:"St. MARY'S HS KUTHUKALVALASAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19922", district:"24", Block:"307", label:"GHS, VETHAMPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"19923", district:"24", Block:"307", label:"ARAMBAJOTHI MIDDLE SCHOOL ILANJI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19924", district:"24", Block:"307", label:"RAMASWAMY PILLAI HR. SEC. SCHOOL., ILANJI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19925", district:"24", Block:"307", label:"SYED RESI.HSS,ILANJI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19926", district:"24", Block:"307", label:"P S T K D GIRLS HR SEC SCHOOL, COURTALLAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19927", district:"24", Block:"307", label:"GHSS,MELAGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19928", district:"24", Block:"307", label:"EMMANUEL MATRIC HR SEC SCHOOL, MELAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19929", district:"24", Block:"307", label:"MPL, 7th WARD MS,TENKASI", Sch_type:"Government", Category:"Middle School"},
    { key:"19930", district:"24", Block:"307", label:"ICI Govt Model HSS,TENKASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19931", district:"24", Block:"307", label:"MANJAMMAL HSS(G),TENKASI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19932", district:"24", Block:"307", label:"PONNAMBALAM MIDDLE SCHOOL TENKASI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19933", district:"24", Block:"307", label:"St.  MICHAEL'S HSS(G) TENKASI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19934", district:"24", Block:"307", label:"NEHRU HSS,TENKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19935", district:"24", Block:"307", label:"HAMEETHIYA MS,TENKASI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19936", district:"24", Block:"307", label:"VEERAMAMUNIVAR RC HSS,TENKASI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19937", district:"24", Block:"307", label:"HINDU HSS,KEELAPULIUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19938", district:"24", Block:"307", label:"HINDU HSS(G),KEELAPULIUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"19939", district:"24", Block:"307", label:"GHSS SUNDARAPANDIYAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19940", district:"24", Block:"307", label:"SRI RAM MATRIC HR SEC SCHOOL, VALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19941", district:"24", Block:"307", label:"ANNAI THERESA R C HSS VALLAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19942", district:"24", Block:"307", label:"NATIONAL PUBLIC SCHOOL, VALLAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"19943", district:"30", Block:"385", label:"ARULAIAH  MIDDLE SCHOOL KOTTAYADI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19944", district:"30", Block:"385", label:"SA NOBLE MEMORIAL HS, VALLIYOOR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19945", district:"30", Block:"385", label:"GOVT.HR.SEC.SCHOOL VALLIYOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19946", district:"30", Block:"385", label:"MERCY MATRIC. HIGHER SECONDARY SCHOOL, VALLIYOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19947", district:"30", Block:"385", label:"Fatima Girls Higher Secondary School", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19948", district:"30", Block:"385", label:"CONCORDIA HSS VALLIOOR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19949", district:"30", Block:"385", label:"HUSSEY MS PANAGUDI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19950", district:"30", Block:"385", label:"GOVERNMENT H S S  PANAGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19951", district:"30", Block:"385", label:"SACRED HEART HIGHER SECONDARY SCHOOL PANAGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19952", district:"30", Block:"385", label:"NIRMALA RC MIDDLE SCHOOL THERKU VALLIYOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19953", district:"30", Block:"385", label:"GOVERNMENT HIGH SCHOOL ROSEMIYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"19954", district:"30", Block:"385", label:"HOLYFAMILY MIDDLE SCHOOL VADAKANGULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19955", district:"30", Block:"385", label:"GHS KAVALKINARU", Sch_type:"Government", Category:"High School"},
    { key:"19956", district:"30", Block:"385", label:"ADH HS PERIYANAYAKI PURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19957", district:"30", Block:"385", label:"SACRED HEART HSS KAVALKINARU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19958", district:"30", Block:"385", label:"ST THRESA HSS VADAKANKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19959", district:"30", Block:"385", label:"CONCORDIA HSS VADAKANKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19960", district:"30", Block:"385", label:"NEHRU NATIONAL HSS VADAKANKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19961", district:"30", Block:"385", label:"TDTA MS ANNAIKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19962", district:"30", Block:"385", label:"GHSS THULUKKARPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19963", district:"30", Block:"385", label:"TDTA MS KANNANALLUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19964", district:"30", Block:"385", label:"TDTA MIDDLE SCHOOL ZIONMALAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19965", district:"30", Block:"385", label:"SRI MUTHARAMMAN MIDDLE SCHOOL SIVASUBRAMANIYAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19966", district:"30", Block:"385", label:"RC MS PATHINATHAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19967", district:"30", Block:"385", label:"GHS KOLIANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"19968", district:"30", Block:"385", label:"PANCHAYAT UNION MIDDLE SCHOOL SOUNDARALINGAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19969", district:"30", Block:"385", label:"HINDU HS OORALVAIMOZHY", Sch_type:"Un-aided", Category:"High School"},
    { key:"19970", district:"30", Block:"385", label:"HINDU ARULNERI MS CHITHAMPARAPURAM", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"19971", district:"30", Block:"385", label:"GHSS PAZHAVOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19972", district:"30", Block:"385", label:"ALAMERLU VIDYASHARMAM MATRI. HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"19973", district:"30", Block:"385", label:"BALIAH MARTHANDAM HR. SEC. SCHOOL., AVARAIKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19974", district:"30", Block:"385", label:"GHS MATHAGANERI", Sch_type:"Government", Category:"High School"},
    { key:"19975", district:"30", Block:"385", label:"TDTA HS JACOBPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19976", district:"30", Block:"385", label:"HINDU MIDDLE SCHOOL SANGANAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19977", district:"30", Block:"385", label:"G HIGH SCHOOL THERKKU KARUNGULAM", Sch_type:"Government", Category:"High School"},
    { key:"19978", district:"30", Block:"385", label:"LM MIDDLE SCHOOL KANNANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19979", district:"30", Block:"385", label:"LM MIDDLE SCHOOL LEVANGIPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19980", district:"30", Block:"385", label:"GOVT HIGH SCHOOL MADAMPILLAITHARMAM", Sch_type:"Government", Category:"High School"},
    { key:"19981", district:"30", Block:"385", label:"ST JOSEPH HSS KOOTTAPULI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19982", district:"30", Block:"385", label:"ST ANNES HSS RAJAKRISHNAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19983", district:"30", Block:"385", label:"HINDU HSS KANNANKULAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19984", district:"30", Block:"385", label:"GHSS CHETTIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19985", district:"30", Block:"385", label:"ATOMIC ENERGY HSS ANUVIJAYANAGAR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"19986", district:"30", Block:"385", label:"KUTTALAM MEMORIAL MS IRUKKANTH", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19987", district:"24", Block:"388", label:"ARUNODHAYA MS, VISWANATHAPERI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19988", district:"24", Block:"388", label:"KAMMAWAR HINDU HIGH SCHOOL,VISWANATHAPERI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19989", district:"24", Block:"388", label:"BALA VINAYAGAR HS, SIVAGIRI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19990", district:"24", Block:"388", label:"STELLA MARYS HSS,SIVAGIRI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"19991", district:"24", Block:"388", label:"RC HS,DEVIPATTINAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"19992", district:"24", Block:"388", label:"PANCHAYAT UNION JAWAHAR MIDDLE SCHOOL , SIVAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"19993", district:"24", Block:"388", label:"COMMITTEE NADAR HS,SIVAGIRI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19994", district:"24", Block:"388", label:"SIVAGIRI SENAITHALAIVAR HSS", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"19995", district:"24", Block:"388", label:"GOVT MS, ARUGANKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"19996", district:"24", Block:"388", label:"RC MS, DURAISAMIYAPURAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"19997", district:"24", Block:"388", label:"GHSS,THENMALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"19998", district:"24", Block:"388", label:"THIRU C P ATHITHANAR GIRLS HIGH SCHOOL RAYAGIRI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"19999", district:"24", Block:"388", label:"R.H.N.U.THIRU.SI.BA.SIVANTHI ATHITHANAR HSS RAYAGIRI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20000", district:"24", Block:"388", label:"N.C HSS,RAMANATHAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20001", district:"24", Block:"388", label:"MP GOVT HSS,NELKATTUM SEVAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20002", district:"24", Block:"388", label:"DHARANI MATRICULATION HIGHER SECONDARY SCHOOL, DHARANINAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20003", district:"24", Block:"388", label:"NADAR URAVINMURAI KAMARAJAR GIRLS HIGH SCHOOL VASUDEVANALLUR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20004", district:"24", Block:"388", label:"MOHAIDEENIA HS,VASUDEVANALLUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20005", district:"24", Block:"388", label:"GHSS,VASUDEVANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20006", district:"24", Block:"388", label:"PUMS, MALAIYADIKURICHI", Sch_type:"Government", Category:"Middle School"},
    { key:"20007", district:"24", Block:"388", label:"RC MS, CHINTHAMANI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20008", district:"24", Block:"388", label:"H.N.U.M HS,CINTHAMANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20009", district:"24", Block:"388", label:"KANNA HSS(GIRLS),CINTHAMANI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20010", district:"24", Block:"388", label:"SEENIAMMAL HS,CHINTHAMANI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20011", district:"24", Block:"388", label:"SRI KANNA MATRIC .HSS, CHINTHAMANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20012", district:"24", Block:"388", label:"MOHAIDEENANDAVAR HS,PULIANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20013", district:"24", Block:"388", label:"AVS HS,PULIANGUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20014", district:"24", Block:"388", label:"GOVT GIRLS HR SEC SCHOOL PULIANGUDI TENKASI DISTRICT", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20015", district:"24", Block:"388", label:"PULIANGUDI SENAITHALAIVAR HR SEC SCHOOL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20016", district:"24", Block:"388", label:"RC HS,PULIANGUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20017", district:"24", Block:"388", label:"GHSS(B),PULIANGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20018", district:"24", Block:"388", label:"QUAIDE MILLATH HSS PULIANGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20019", district:"24", Block:"388", label:"H.N.U.C.HSS.T.N.PUTHUKUDI - PULIANGUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20020", district:"24", Block:"388", label:"SUYAMBULINGAM HSS,PULIANGUDI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20021", district:"24", Block:"388", label:"PK GHSS,MULLIKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20022", district:"24", Block:"388", label:"ST.MARYS MAT. SCHOOL, MULLIKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20023", district:"24", Block:"388", label:"CMML HS,VADAMALAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20024", district:"30", Block:"222", label:"MANORANJITHAM MS,KOKKIRAKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20025", district:"30", Block:"222", label:"BISHOP SARGENT MS,PALAYAMKOTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20026", district:"30", Block:"222", label:"CATHEDRAL  HSS, PALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20027", district:"30", Block:"222", label:"VIVEKANANDA VIDHYASHRAM MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20028", district:"30", Block:"222", label:"BISHOP SARGENT MENTALLY RETARDED", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20029", district:"30", Block:"222", label:"ADITHYA VIDHYA NIKETAN MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20030", district:"30", Block:"222", label:"PUSHPALATA VIDYA MANDIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20031", district:"30", Block:"222", label:"HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20032", district:"30", Block:"222", label:"SRI KANTHIMATHI  AMBAL GIRLS HSS HIGHGROUND", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20033", district:"30", Block:"222", label:"M.N.ABDUR RAHMAN HSS TIRUNELVELI ", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20034", district:"30", Block:"222", label:"ST.JOHNS HSS PALAYAMKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20035", district:"30", Block:"222", label:"FATIMA ORIENTAL ARABIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20036", district:"30", Block:"222", label:"USBORNE MS PALAYAMKOTTAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20037", district:"30", Block:"222", label:"SERVITE MATRIC HIGHER SECONDARY SCHOOL, PALAYAMKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20038", district:"30", Block:"222", label:"CHILD JESUS GIRLS HSS, PALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20039", district:"30", Block:"222", label:"ST. IGNATIUS CONVENT HSS, PALAYAMKOTTAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20040", district:"30", Block:"222", label:"ST.XAVIERS HSS PALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20041", district:"30", Block:"222", label:"KATHIR VIDYALAYA N&PS  PERUMAL PURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20042", district:"30", Block:"222", label:"ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL,JAWAHAR NAGAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20043", district:"30", Block:"222", label:"GOVT.OBSERVATION HOME", Sch_type:"Government", Category:"Middle School"},
    { key:"20044", district:"30", Block:"222", label:"FLORENCE SWAISON HSS(D&F),PALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20045", district:"30", Block:"222", label:"RAHMANIA HSS MELAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20046", district:"30", Block:"222", label:"ST.THOMAS HS KURICHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20047", district:"30", Block:"222", label:"THE MUSLIM HSS MELAPALAYAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20048", district:"30", Block:"222", label:"Punitha Suvakkin Matric School ", Sch_type:"Un-aided", Category:"High School"},
    { key:"20049", district:"30", Block:"222", label:"QUAIDE MILLETH CORPORATION HIGHER SECONDARY SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20050", district:"30", Block:"222", label:"MUSLIM GIRLS HSS MELAPALAYAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20051", district:"30", Block:"361", label:"GOVERNMENT HR SEC SCHOOL,TIRUNELVELI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20052", district:"30", Block:"361", label:"SCHAFFTER HIGHER SECONDARY SCHOOL, TIRUNELVELI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20053", district:"30", Block:"361", label:"THE MDT HINDU COLLEGE HR.SEC SCHOOL,TIRUNELVELI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20054", district:"30", Block:"361", label:"Mpl. (G) HSS,  Meenakshipuram, Tirunelveli Junction", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20055", district:"30", Block:"361", label:"Bharathiar Mpl. HS, Tirunelveli", Sch_type:"Government", Category:"High School"},
    { key:"20056", district:"30", Block:"361", label:"STC Branch Upper Clapton Middle School,TVL Town", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20057", district:"30", Block:"361", label:"Jawahar Govt. HS, Tirunelveli", Sch_type:"Government", Category:"High School"},
    { key:"20058", district:"30", Block:"361", label:"Mpl. (G) HSS, Kallanai, TVL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20059", district:"30", Block:"361", label:"Sri Manthiramoorthy HSS, Tirunelveli", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20060", district:"30", Block:"361", label:"Meenakshi Matric. Hr. Sec School, Tvl", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20061", district:"30", Block:"361", label:"LITTLE FLOWER MATRIC HR. SEC. SCHOOL TIRUNELVELI TOWN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20062", district:"30", Block:"361", label:"Gaffoor Middle School, Pettai", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"20063", district:"30", Block:"361", label:"Mpl. (G) HSS, Pettai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20064", district:"30", Block:"361", label:"Kamaraj Mpl. HSS, Pettai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20065", district:"30", Block:"361", label:"St. Antony's HS, Pettai", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20066", district:"30", Block:"361", label:"Ooliyasthanam MS, Palayapettai", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20067", district:"30", Block:"361", label:"Hindu Middle School, Matha Middle. Street., TVL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20068", district:"30", Block:"361", label:"TDTA MS, Arasaradi Palam, TVL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20069", district:"11", Block:"354", label:"GHSS-THOVALAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20070", district:"11", Block:"354", label:"GHSS-MADHAVALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20071", district:"11", Block:"354", label:"GHSS-CHENBAGARAMANPUTHOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20072", district:"11", Block:"354", label:"GHS-VELLAMADAM", Sch_type:"Government", Category:"High School"},
    { key:"20073", district:"11", Block:"354", label:"GHS-THIRUPATHISARAM", Sch_type:"Government", Category:"High School"},
    { key:"20074", district:"11", Block:"354", label:"ANNAI CHELLAMMAL CBSE SCHOOLTHIRUPATHISARAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20075", district:"11", Block:"354", label:"GHSS-THAZHAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20076", district:"11", Block:"354", label:"GHSS-BOOTHAPANDY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20077", district:"11", Block:"354", label:"GHSS-THITTUVILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20078", district:"11", Block:"354", label:"BGCSI MATRICULATION SCHOOL, THITTUVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20079", district:"11", Block:"354", label:"PJ GHS (G)-BHOOTHAPANDI", Sch_type:"Government", Category:"High School"},
    { key:"20080", district:"11", Block:"354", label:"GHSS-ARUMANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20081", district:"11", Block:"354", label:"GHS-POTTIYOOR", Sch_type:"Government", Category:"High School"},
    { key:"20082", district:"11", Block:"354", label:"GHSS-KURATHIYARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20083", district:"11", Block:"354", label:"GOVT ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL VAZHAYATHUVAYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20084", district:"11", Block:"354", label:"C.W.H.S-THADIKARANKONAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20085", district:"11", Block:"354", label:"GHS-ANANTHAPURAM, KADUKARAI ", Sch_type:"Government", Category:"High School"},
    { key:"20086", district:"11", Block:"354", label:"GHS-KATTUPUTHOOR", Sch_type:"Government", Category:"High School"},
    { key:"20087", district:"11", Block:"354", label:"GHS-ERACHAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"20088", district:"11", Block:"354", label:"GHS-ARAL PERUMALPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20089", district:"11", Block:"354", label:"GHSS-ARALVAIMOZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20090", district:"11", Block:"354", label:"RAJA'S MATRIC HR.SEC.SCHOOL - ARALVOIMOZHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20091", district:"11", Block:"354", label:"GHS-ARAL KUMARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20092", district:"11", Block:"354", label:"COMERIN INTERNATIONAL ARALVOIMOZHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20093", district:"11", Block:"3", label:"GOVT HR SEC SCHOOL  OTTAYALVILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20094", district:"11", Block:"3", label:"GHS MAHADANAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20095", district:"11", Block:"3", label:"MARY IMMACULATE HS KANYAKUMARI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20096", district:"11", Block:"3", label:"ST ANTONY'S HSS KANYAKUMARI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20097", district:"11", Block:"3", label:"KANYAKUMARI PUBLIC SCHOOL- KANYAKUMARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20098", district:"11", Block:"3", label:"ST. JOSEPH CALASANZ SCHOOL- KANYAKUMARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20099", district:"11", Block:"3", label:"GOVERNMENT HIGH SCHOOL ELANTHAYADIVILAI", Sch_type:"Government", Category:"High School"},
    { key:"20100", district:"11", Block:"3", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, AGASTHEESWARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20101", district:"11", Block:"3", label:"ST.IGNATIOUS H.S,KOVALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20102", district:"11", Block:"3", label:"AMRITA VIDYALAYAM  KANYAKUMARI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20103", district:"11", Block:"3", label:"GOVERNMENT HIGH SCHOOL  NORTH THAMARAIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"20104", district:"11", Block:"3", label:"GOVERNMENT HIGH SCHOOL KOTTAIYADI", Sch_type:"Government", Category:"High School"},
    { key:"20105", district:"11", Block:"3", label:"LMS.H.S.S,SOUTH THAMARAIKULAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20106", district:"11", Block:"3", label:"GOOD SHEPHERED MATRIC Hr.Sec. SCHOOL, THERIVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20107", district:"11", Block:"3", label:"SIVA MATRIC HIGHER SECONDARY SCHOOL CHITHENKUDIYIRUPPU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20108", district:"11", Block:"3", label:"GHS KULASEKARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20109", district:"11", Block:"3", label:"RINGLETAUBE HSS MYLAUDY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20110", district:"11", Block:"3", label:"MOSES MATRIC SCHOOL - MYLAUDY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20111", district:"11", Block:"3", label:"GHSS KUMARAPURAM THOPOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20112", district:"11", Block:"3", label:"GHS RAMAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20113", district:"11", Block:"3", label:"GOVT.HIGH SCHOOL , NALLOOR", Sch_type:"Government", Category:"High School"},
    { key:"20114", district:"11", Block:"3", label:"PEARL MATRIC HIGHER SEC.SCHOOL -THEREKALPUTHOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20115", district:"11", Block:"3", label:"ISHA VIDHYA MATRICULATION HIGHER SECONDARY SCHOOL-THEROOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20116", district:"11", Block:"3", label:"GHS ERAVIPUTHOOR", Sch_type:"Government", Category:"High School"},
    { key:"20117", district:"11", Block:"3", label:"S.M.S.M.HSS-SUCHINDRUM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20118", district:"11", Block:"3", label:"GMS POTTALKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20119", district:"11", Block:"3", label:"GHS VARIYOOR", Sch_type:"Government", Category:"High School"},
    { key:"20120", district:"11", Block:"3", label:"ST.JOSEPH'S (G) HS-AZHAGAPPAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20121", district:"11", Block:"3", label:"LMS HSS JAMESTOWN", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20122", district:"11", Block:"3", label:"ST.ANTONY'S HSS AZHAGAPPAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20123", district:"11", Block:"3", label:"GHS KANAGAPPAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20124", district:"11", Block:"3", label:"CHRIST CMI CENTRAL SCHOOL-ANJUGRAMAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20125", district:"11", Block:"3", label:"GOVERNMENT HIGH SCHOOL,  EACHENVILAI", Sch_type:"Government", Category:"High School"},
    { key:"20126", district:"11", Block:"3", label:"GHSS KOTTARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20127", district:"11", Block:"3", label:"CSI HS  FOR  THE DEAF KOTTARAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20128", district:"11", Block:"3", label:"Dr .ABDULKALAM INTERNATIONAL SCHOOL  & RESEARCH  ACADEMY,PERIAVIAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20129", district:"11", Block:"3", label:"ANBU ILLAM HR.SEC.SCHOOL FOR SPEACH & HEARING IMPAIRED- NAGERCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20130", district:"11", Block:"3", label:"SALVATION ARMY HSS,VETTOORNIMADAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20131", district:"11", Block:"3", label:"SALVATION ARMY MATRIC HSS ,VETTOORNIMADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20132", district:"11", Block:"3", label:"ADARSH VIDYA KENDRA-NAGERCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20133", district:"11", Block:"3", label:"SMRV HR.SEC.SCHOOL,VADASERY", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20134", district:"11", Block:"3", label:"GHSS VADASERY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20135", district:"11", Block:"3", label:"S.M.R.V. MATRICULATION  SCHOOL , VADASERY", Sch_type:"Un-aided", Category:"High School"},
    { key:"20136", district:"11", Block:"3", label:"ST. XAVIER 'S HS KOTTAR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20137", district:"11", Block:"3", label:"GOVERNMENT HIGH SCHOOL EZHAKARAM", Sch_type:"Government", Category:"High School"},
    { key:"20138", district:"11", Block:"3", label:"SADAVADANI S.P G.HSS-EDALAKUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20139", district:"11", Block:"3", label:"ST. MICHAEL'S HIGH SCHOOL VETHAKARANKUDIYIRUPPU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20140", district:"11", Block:"3", label:"GHS IRULLAPPAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20141", district:"11", Block:"3", label:"GHSS VALLANKUMARANVILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20142", district:"11", Block:"3", label:"BISHOP AROCKIASAMY HR.SEC.SCHOOL- MARAVANKUDIERUPPU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20143", district:"11", Block:"3", label:"CREATIVE MATRIC SCHOOL, KEEZHA MARAVANKUDIYERUPPU", Sch_type:"Un-aided", Category:"High School"},
    { key:"20144", district:"11", Block:"3", label:"GHS MELASOORANKUDI", Sch_type:"Government", Category:"High School"},
    { key:"20145", district:"11", Block:"3", label:"ST. ANTONY 'S HSS, KURUSADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20146", district:"11", Block:"3", label:"CONCORDIA HIGH SCHOOL NAGERCOIL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20147", district:"11", Block:"3", label:"IELC PUBLIC SCHOOL NAGERCOIL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20148", district:"11", Block:"3", label:"St.ALOYSIUS HSS - CHAYAKKARANPARAPPUVILAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20149", district:"11", Block:"3", label:"KENDRIYA VIDYALAYA NAGERCOIL", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"20150", district:"11", Block:"3", label:"SCOTT CHRISTIAN HSS-NAGERCOIL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20151", district:"11", Block:"3", label:"ORAL HR SEC SCHOOL FOR THE HEARING IMPAIRED NAGERCOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20152", district:"11", Block:"3", label:"GHS NESAVALAR ST VADASERY", Sch_type:"Government", Category:"High School"},
    { key:"20153", district:"11", Block:"3", label:"K.N.S.K.GHS OZHUGINASERY", Sch_type:"Government", Category:"High School"},
    { key:"20154", district:"11", Block:"3", label:"GHS MEENAKSHIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20155", district:"11", Block:"3", label:"ST.JOSEPH 'S CONVENT HSS ,NAGERCOIL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20156", district:"11", Block:"3", label:"PHILIPS MATRIC SCHOOL, NAGERCOIL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20157", district:"11", Block:"3", label:"KDVP GOVT GIRLS MODEL HR SEC SCHOOL, KOTTAR, NAGERCOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20158", district:"11", Block:"3", label:"SLB GOVT.(G) HIGH SCHOOL -NAGERCOIL", Sch_type:"Government", Category:"High School"},
    { key:"20159", district:"11", Block:"3", label:"St..FRANCIS  HSS,  NAGERCOIL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20160", district:"11", Block:"3", label:"GHS VADALIVILAI", Sch_type:"Government", Category:"High School"},
    { key:"20161", district:"11", Block:"3", label:"LITTLE FLOWER (G ) HR .SEC.SCHOOL -RAMANPUTHUR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20162", district:"11", Block:"3", label:"CARMEL HR.SEC.SCHOOL RAMANPUTHUR ,NAGERCOIL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20163", district:"11", Block:"3", label:"SLB GOVT.HR.SEC.SCHOOL-NAGERCOIL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20164", district:"11", Block:"3", label:"HOME CHURCH GIRLS HIGH SCHOOL - NAGERCOIL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20165", district:"11", Block:"3", label:"DUTHIE GIRLS HR.SE.SCHOOL -NAGERCOIL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20166", district:"11", Block:"263", label:"GHS PARAKKAI", Sch_type:"Government", Category:"High School"},
    { key:"20167", district:"11", Block:"263", label:"GHSS THENGAMPUTHOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20168", district:"11", Block:"263", label:"LITTLE FLOWER HS-MANAKUDY", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20169", district:"11", Block:"263", label:"LMPC HIGHER SECONDARY SCHOOL, PUTHALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20170", district:"11", Block:"263", label:"KANNAN MATRIC HIGHER SECONDARY SCHOOL, THENGAMPUTHOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20171", district:"11", Block:"263", label:"BELIEF CENTRAL SCHOOL-THENGAMPUTHOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"20172", district:"11", Block:"263", label:"GHS-PUTHOOR", Sch_type:"Government", Category:"High School"},
    { key:"20173", district:"11", Block:"263", label:"GHSS,SOORANKUDY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20174", district:"11", Block:"263", label:"GHSS-SUNDAPATTIVILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20175", district:"11", Block:"263", label:"LMS HSS ZIONPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20176", district:"11", Block:"263", label:"GHS PARVATHIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20177", district:"11", Block:"263", label:"GHS PUTHERI", Sch_type:"Government", Category:"High School"},
    { key:"20178", district:"11", Block:"263", label:"GHSS PERUVILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20179", district:"11", Block:"263", label:"ST.JOSEPH's HR.SEC. SCHOOL, ASARIPALLAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20180", district:"11", Block:"263", label:"SHANTHI NILAYAM SPECIAL SCHOOL-PARUTHIVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20181", district:"11", Block:"263", label:"GHSS EATHAMOZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20182", district:"11", Block:"263", label:"GHSS-RAJAKKAMANAGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20183", district:"11", Block:"263", label:"AVD HSS GANAPATHIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20184", district:"11", Block:"263", label:"GHS ALANKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"20185", district:"11", Block:"263", label:"GHSS MONIKETTIPOTTAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20186", district:"11", Block:"263", label:"St. Jude Thaddaeus Higher Secondary School, Pallam", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20187", district:"11", Block:"263", label:"GHSS ANANDANADARKUDY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20188", district:"11", Block:"263", label:"LMS GIRLS HIGH SCHOOL SANTHAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20189", district:"11", Block:"263", label:"DR. SAMUEL HSS SANTHAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20190", district:"11", Block:"263", label:"GOVERNMENT HIGH SCHOOL, VEMBANOOR", Sch_type:"Government", Category:"High School"},
    { key:"20191", district:"11", Block:"263", label:"ST. ROSSELLO'S SCHOOL, SADAYALPUTHOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"20192", district:"11", Block:"149", label:"GHSS ALOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20193", district:"11", Block:"149", label:"GHSS KODUPAKUZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20194", district:"11", Block:"149", label:"CORPUS CHRISTI SCHOOL , PERUVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20195", district:"11", Block:"149", label:"GHSS - ERANIEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20196", district:"11", Block:"149", label:"ST.XAVIER'S HIGHER SECONDARY SCHOOL,PATTARIVILLAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20197", district:"11", Block:"149", label:"ST.FRANCIS XAVIER Hr.Sec.School, Manguzhy", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20198", district:"11", Block:"149", label:"GHS THALAKULAM", Sch_type:"Government", Category:"High School"},
    { key:"20199", district:"11", Block:"149", label:"GHS VELLICHANTHAI", Sch_type:"Government", Category:"High School"},
    { key:"20200", district:"11", Block:"149", label:"GHSS-AMMANDIVILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20201", district:"11", Block:"149", label:"WESLEY MIDDLE SCHOOL UNNANKULAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20202", district:"11", Block:"149", label:"ST.JOSEPH'S H.S- SAREL.", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20203", district:"11", Block:"149", label:"SACRED HEART HIGH SCHOOL, KADIAPATTANAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20204", district:"11", Block:"149", label:"SAROJINI MEMORIAL HIGHER SECONDARY SCHOOL ORAPPANAVILAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20205", district:"11", Block:"149", label:"ALL SAINTS HSS MUTTOM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20206", district:"11", Block:"149", label:"ST JOSEPH'S SCHOOL, ATTINKARAI, MANAVALAKURICHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20207", district:"11", Block:"149", label:"GHS CHERAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"20208", district:"11", Block:"149", label:"GHSS(G)-KADIAPATINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20209", district:"11", Block:"149", label:"BMHSS MANAVALAKURICHI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20210", district:"11", Block:"149", label:"DEVASAM HSS MONDAIKADU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20211", district:"11", Block:"149", label:"GHSS ANAKUZHI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20212", district:"11", Block:"149", label:"ST. MICHAEL'S HIGH SCHOOL KODIMUNAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20213", district:"11", Block:"149", label:"PROVIDENCE HIGHER SECONDARY SCHOOL,RITAPURAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20214", district:"11", Block:"149", label:"DEVI HSS KUNANKAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20215", district:"11", Block:"149", label:"ST. IGNATIUS HSS KURUMPANAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20216", district:"11", Block:"149", label:"VKP HSS COLACHEL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20217", district:"11", Block:"149", label:"ST. ALEX MS-KOTTILPADU", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"20218", district:"11", Block:"149", label:"GHS-ELAPPAVILAI", Sch_type:"Government", Category:"High School"},
    { key:"20219", district:"11", Block:"149", label:"ST. MARY'S HSS-COLACHEL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20220", district:"11", Block:"149", label:"ST JAMES HS VANIYAKUDI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20221", district:"11", Block:"149", label:"GOOD SHEPHERED MATRICULATION SCHOOL,SIMON COLONY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20222", district:"11", Block:"149", label:"RC HSS KONAMKADU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20223", district:"11", Block:"149", label:"GHS VILLUKURI", Sch_type:"Government", Category:"High School"},
    { key:"20224", district:"11", Block:"149", label:"ST. LAWRENCE HSS MADATHATTUVILAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20225", district:"11", Block:"149", label:"ST. ALOYSIUS HSS KARANKADU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20226", district:"11", Block:"149", label:"LMS GHSS NEYYOOR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20227", district:"11", Block:"149", label:"MYRNA MEMORIAL HSS BETHELPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20228", district:"11", Block:"149", label:"K.J MCILROY MAT.S, NEYYOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20229", district:"11", Block:"149", label:"CSI MATRICULATION HIGH SCHOOL SEYNAMVILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20230", district:"11", Block:"149", label:"JOCHEBED MATRICULATION SCHOOL KALLUKOOTTAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20231", district:"11", Block:"355", label:"GHS VATHIYARKONAM", Sch_type:"Government", Category:"High School"},
    { key:"20232", district:"11", Block:"355", label:"St.Joseph's M.S.C.H.S.S,Chellamkonam", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20233", district:"11", Block:"355", label:"RC HIGH SCHOOL PALLIYADI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20234", district:"11", Block:"355", label:"CHRISTUCOIL LMS HR SEC SCHOOL PALLIYADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20235", district:"11", Block:"355", label:"KARUNALYAM SCHOOL MENTALLY RETARDED -KALLUVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20236", district:"11", Block:"355", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,KALKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20237", district:"11", Block:"355", label:"GOVT HSS-THUCKALAY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20238", district:"11", Block:"355", label:"AMALA CONVENT(G) H.S.S,THUCKALAY", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20239", district:"11", Block:"355", label:"GOVERNMENT HIGH SCHOOL, MOOLACHI", Sch_type:"Government", Category:"High School"},
    { key:"20240", district:"11", Block:"355", label:"SKVMS MUTTAIKADU", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20241", district:"11", Block:"355", label:"CARMAL GIRLS HSS MANALIKARAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20242", district:"11", Block:"355", label:"LMS HSS KADAMALAIKUNTU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20243", district:"11", Block:"355", label:"GHS KUMARAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20244", district:"11", Block:"355", label:"ST. MARY GORETTY HSS  MANALIKARAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20245", district:"11", Block:"355", label:"BG ARIVU ILLAM KOTTICODU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20246", district:"11", Block:"355", label:"GHS(GIRLS),ERANIEL", Sch_type:"Government", Category:"High School"},
    { key:"20247", district:"11", Block:"355", label:"GHS PEYANKUZHI", Sch_type:"Government", Category:"High School"},
    { key:"20248", district:"11", Block:"355", label:"GHSS.KANDANVILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20249", district:"11", Block:"355", label:"HACKER MEMORIAL HSS ,NEYYOOR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20250", district:"11", Block:"355", label:"GMS KODIYOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"20251", district:"11", Block:"355", label:"GHSS-THRIVITHANCODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20252", district:"11", Block:"355", label:"GHSS-KANNATTUVILAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20253", district:"11", Block:"355", label:"INFANT JESUS GIRLS HIGH SCHOOL -  MULAGUMOODU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20254", district:"11", Block:"355", label:"VIVEKANANDA MAT Hr.Sec.SCHOOL-VAIKUNDAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20255", district:"11", Block:"355", label:"SREE RAMJI VITHIYA KENDRAA THICKANAMCODE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20256", district:"11", Block:"355", label:"MOUNT LITERA ZEE SCHOOL -MYLODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20257", district:"11", Block:"355", label:"GHS PERUNCHILAMBU", Sch_type:"Government", Category:"High School"},
    { key:"20258", district:"11", Block:"341", label:"GHS VILLUNNIKONAM", Sch_type:"Government", Category:"High School"},
    { key:"20259", district:"11", Block:"341", label:"YETTACODE HSS. YETTACODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20260", district:"11", Block:"341", label:"GHS. CHERUKOLE", Sch_type:"Government", Category:"High School"},
    { key:"20261", district:"11", Block:"341", label:"GHS.POOVANCODE", Sch_type:"Government", Category:"High School"},
    { key:"20262", district:"11", Block:"341", label:"M.V. H.S. CHENKODI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20263", district:"11", Block:"341", label:"GHS THACHOOR", Sch_type:"Government", Category:"High School"},
    { key:"20264", district:"11", Block:"341", label:"GHS. CHERUPPALOR", Sch_type:"Government", Category:"High School"},
    { key:"20265", district:"11", Block:"341", label:"GHS. THIRUVARAMBU", Sch_type:"Government", Category:"High School"},
    { key:"20266", district:"11", Block:"341", label:"GHS. THIRUVATTAR", Sch_type:"Government", Category:"High School"},
    { key:"20267", district:"11", Block:"341", label:"ARUNACHALAM HSS., THIRUVATTAR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20268", district:"11", Block:"341", label:"GHS KESAVAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20269", district:"11", Block:"341", label:"DEVASAM H.S. THIRPARAPPU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20270", district:"11", Block:"341", label:"GOVT. TRIBAL RESIDENTIAL HSS PECHIPPARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20271", district:"11", Block:"341", label:"ST. PETER'S HSS. CHEKKAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20272", district:"11", Block:"341", label:"GHS. PONMANAI", Sch_type:"Government", Category:"High School"},
    { key:"20273", district:"11", Block:"341", label:"GOVT HIGH SCHOOL MANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"20274", district:"11", Block:"341", label:"GHSS. KULASEKARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20275", district:"11", Block:"341", label:"GHS. VEEYANOOR", Sch_type:"Government", Category:"High School"},
    { key:"20276", district:"11", Block:"341", label:"GHS. ERAVIPUTHOORKADAI", Sch_type:"Government", Category:"High School"},
    { key:"20277", district:"11", Block:"341", label:"GHSS KATTATHURAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20278", district:"11", Block:"341", label:"ST. JOSEPH'S HIGH SCHOOL MULAVILAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20279", district:"11", Block:"341", label:"ST. JOSEPH'S HIGH SCHOOL PILANKALAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20280", district:"11", Block:"341", label:"AIDED HS. KATTATHURAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20281", district:"11", Block:"341", label:"BETHEL BLOOMS N&PS-AZHAHIAMANDAPAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20282", district:"11", Block:"341", label:"GHS. THIRUNANTHIKARAI", Sch_type:"Government", Category:"High School"},
    { key:"20283", district:"11", Block:"341", label:"ST. URSULA'S GIRLS HSS KULASEKHARAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20284", district:"11", Block:"341", label:"SBJ VIDYA BHAVAN INTERNATIONAL SCHOOL-PULIYERANGI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20285", district:"11", Block:"341", label:"ST. ANTONY'S HS. SURULACODE", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20286", district:"11", Block:"341", label:"GHS KOTHAYAR LOWER CAMP", Sch_type:"Government", Category:"High School"},
    { key:"20287", district:"11", Block:"182", label:"GHS CHEMMANKALAI", Sch_type:"Government", Category:"High School"},
    { key:"20288", district:"11", Block:"182", label:"GHS MELPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20289", district:"11", Block:"182", label:"GHSS THIKKURICHY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20290", district:"11", Block:"182", label:"SACRED HEART H S PACODE", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20291", district:"11", Block:"182", label:"ABRAHAM MEMORIAL .HSS-MARUNTHENCODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20292", district:"11", Block:"182", label:"ROYAL MAT H S S  PACODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20293", district:"11", Block:"182", label:"SREE KRISHNA VIDYALAYA MATRIC HIGH SCHOOL, ALAMPADI, CHOOZHIKKONAM, MARUTHANCODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"20294", district:"11", Block:"182", label:"SACRED HEART CONVENT MATRIC. HR.SEC.SCHOOL ,KAZHUVANTHITTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20295", district:"11", Block:"182", label:"Holy Angels' Matriculation School, Melpuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"20296", district:"11", Block:"182", label:"G H S  KALIAKKAVILAI", Sch_type:"Government", Category:"High School"},
    { key:"20297", district:"11", Block:"182", label:"G H S MECODE", Sch_type:"Government", Category:"High School"},
    { key:"20298", district:"11", Block:"182", label:"T. C. K. H. S. S PADANTHALUMOODU ", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20299", district:"11", Block:"182", label:"SACR H MAT HSS PADANTHALUMOODU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20300", district:"11", Block:"182", label:"ST JOSEPH'S HSS THIRUTHAPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20301", district:"11", Block:"182", label:"G H S S VILAVANCODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20302", district:"11", Block:"182", label:"G H S S (BOYS)MARTHANDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20303", district:"11", Block:"182", label:"G H S S GIRLS MARTHANDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20304", district:"11", Block:"182", label:"L.M.S HIGHER SECONDARY SCHOOL FOR BOYS,MARTHANDAM.", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20305", district:"11", Block:"182", label:"L M S GIRLS H S S MARTHANDAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20306", district:"11", Block:"182", label:"MJKM MSC.HR.SEC. SCHOOL, CHRISTURAJAPURAM -MARTHANDAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20307", district:"11", Block:"182", label:"GHSS-PALUKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20308", district:"11", Block:"182", label:"ST JAMES H S PARAKUNNU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20309", district:"11", Block:"182", label:"ST. FRANCIS NURSERY AND PRIMARY SCHOOL, MEZHACODE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20310", district:"11", Block:"182", label:"B.R.MEMORIAL MATRICULATION SCHOOL, PALUKAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20311", district:"11", Block:"182", label:"G LMMS MALAICODE", Sch_type:"Government", Category:"Middle School"},
    { key:"20312", district:"11", Block:"182", label:" M NM HS EDAICODE", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20313", district:"11", Block:"182", label:"ST MARYS HSS MELPALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20314", district:"11", Block:"182", label:"AIDED HIGHER SECONDARY SCHOOL, MOTTAKALAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20315", district:"11", Block:"182", label:"PRIVATE HSS KALLUPALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20316", district:"11", Block:"182", label:"HF HSS MUKKUTTUKAL", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20317", district:"11", Block:"182", label:"SMILE ST ANTONY MAT. S KURACODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"20318", district:"11", Block:"182", label:"GBFM HS DEVICODE", Sch_type:"Government", Category:"High School"},
    { key:"20319", district:"11", Block:"182", label:"NEHRU MEMMORIAL .M.S-KARODE,ARUMANAI", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"20320", district:"11", Block:"182", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  KADAYAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20321", district:"11", Block:"182", label:"GMS-CHITTAR", Sch_type:"Government", Category:"Middle School"},
    { key:"20322", district:"11", Block:"182", label:"ADI  DRA TRI RES HSS PATHUKANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20323", district:"11", Block:"182", label:"SACRED HEART MIDDLE SCHOOL ,KADAYAL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20324", district:"11", Block:"182", label:"HOLY FAMILY H S ARUKANI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20325", district:"11", Block:"182", label:"ST.THOMAS HIGH SCHOOL-NETTA", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20326", district:"11", Block:"182", label:"ST MARYS HSS KALIYAL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20327", district:"11", Block:"182", label:"ST.MARY'S CENTRAL SCHOOL .KALIYAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20328", district:"11", Block:"182", label:"G S V M S MALACODE", Sch_type:"Government", Category:"Middle School"},
    { key:"20329", district:"11", Block:"182", label:"PPM H S S ANDUCODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20330", district:"11", Block:"182", label:"CHRIST INTERNATIONAL SCHOOL-MALAMARI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20331", district:"11", Block:"182", label:"GHS- VELLAMCODE", Sch_type:"Government", Category:"High School"},
    { key:"20332", district:"11", Block:"182", label:"GHSS-ARUMANAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20333", district:"11", Block:"182", label:"HSS-ARUMANAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20334", district:"11", Block:"182", label:"KAMARAJ MEMORIAL HSS-PANTHALUVILAI, MANJALUMOODU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20335", district:"11", Block:"182", label:"NM VIDYA KENDRA (CBSE)- CHITHARAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20336", district:"11", Block:"182", label:"ARUNODAYA VIDHAYALAYA MATRIC SCHOOL-MANJALUMOODU", Sch_type:"Un-aided", Category:"High School"},
    { key:"20337", district:"11", Block:"182", label:"GHS- PULIYOORSALAI", Sch_type:"Government", Category:"High School"},
    { key:"20338", district:"11", Block:"124", label:"GHSS-NALLOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20339", district:"11", Block:"124", label:"ST Anne's Matric Higher Secondary School, Elavuvilai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20340", district:"11", Block:"124", label:"SACRED HEART INTERNATIONAL SCHOOL-PAMMAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20341", district:"11", Block:"124", label:"GMS KADAMANKUZHI", Sch_type:"Government", Category:"Middle School"},
    { key:"20342", district:"11", Block:"124", label:"ST. FRANCIS XAVIER H.S - ALANCHI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20343", district:"11", Block:"124", label:"ST. ALOYSIUS GIRLS HSS VELLIAVILAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20344", district:"11", Block:"124", label:"LMS HSS - MATHICODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20345", district:"11", Block:"124", label:"K.T.P. HIGHER SEC. SCHOOL MIDALAKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20346", district:"11", Block:"124", label:"RINGLE TAUBE MATRIC HIGHER SECONDARY  SCHOOL-MATHICODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20347", district:"11", Block:"124", label:"GHS UDAYA MARTHANDAM", Sch_type:"Government", Category:"High School"},
    { key:"20348", district:"11", Block:"124", label:"GHSS MATHICODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20349", district:"11", Block:"124", label:"ST. ANTONY'S GIRLS HIGH SCHOOL ,THUNDATHUVILAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20350", district:"11", Block:"124", label:"ST. BERNADETTE'S HSS MANGALAKUNTU", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20351", district:"11", Block:"124", label:"SAINIKS.MATRIC SCHOOL-KARUNGAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20352", district:"11", Block:"124", label:"GHSS KEEZHKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20353", district:"11", Block:"124", label:"ST. AHSS ENAMPUTHENTHURAI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20354", district:"11", Block:"124", label:"ST. JO HS-RAMANTHURAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20355", district:"11", Block:"124", label:"HAM HSS CHENTHARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20356", district:"11", Block:"124", label:"GHS KEEZHKULAM WEST", Sch_type:"Government", Category:"High School"},
    { key:"20357", district:"11", Block:"124", label:"GMS VENCODE NORTH", Sch_type:"Government", Category:"Middle School"},
    { key:"20358", district:"11", Block:"124", label:"ST. JOSEPH'S HIGHER SECONDARY SCHOOL VIZHUNTHAYAMBALAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20359", district:"11", Block:"124", label:"ST. FRANCIS HSS VENCODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20360", district:"11", Block:"124", label:"OXFORD MATRIC HIGHER SECONDARY SCHOOL,THOLAYAVATTAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20361", district:"11", Block:"124", label:"BETHANI MHSS  VENCODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20362", district:"11", Block:"124", label:"GOVT MIDDLE SCHOOL, KANJIRAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20363", district:"11", Block:"124", label:"GHS KANJIRACODE", Sch_type:"Government", Category:"High School"},
    { key:"20364", district:"11", Block:"124", label:"GHS UNNAMALAIKADAI", Sch_type:"Government", Category:"High School"},
    { key:"20365", district:"11", Block:"124", label:"GHSS NATTALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20366", district:"11", Block:"124", label:"ST. JOHN VIANNEY'S GIRLS' HR. SEC. SCHOOL , PALLIYADI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20367", district:"11", Block:"124", label:"ST. MSC HS NATTALAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20368", district:"11", Block:"124", label:"ST. AHS ELAVUVILAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20369", district:"11", Block:"124", label:"CSI SCHOOL FOR THE BLIND, IRENIPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20370", district:"11", Block:"124", label:"INFANT JESUS MATRIC HSS MAMOOTTUKADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20371", district:"11", Block:"124", label:"N.A.M. MATRICULATION  HR. SEC. SCHOOL,NELVELI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20372", district:"11", Block:"124", label:"SREE CHENBAGA MATRICULATION SCHOOL PAYANAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20373", district:"11", Block:"124", label:"GHSS KARUNGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20374", district:"11", Block:"124", label:"ST. ANTONY'S HIGH SCHOOL, MULLANGANAVILAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20375", district:"11", Block:"124", label:"CONCORDIA HR.SEC.SCHOOL-POTTETI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20376", district:"11", Block:"191", label:"GOVERNMENT MIDDLE  SCHOOL , VENKANJI", Sch_type:"Government", Category:"Middle School"},
    { key:"20377", district:"11", Block:"191", label:"GOVERNMENT  HIGHER SECONDARY  SCHOOL ,KOLLENCODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20378", district:"11", Block:"191", label:"ST JOSEPH HIGH SCHOOL,  SILUVAIPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20379", district:"11", Block:"191", label:"MAR GREGORIOS M.S.C. HIGH SCHOOL KIRATHOOR", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20380", district:"11", Block:"191", label:"ST STEPHEN HIGH SCHOOL  SOUTHPALAVILAI", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20381", district:"11", Block:"191", label:"SREEDEVI GIRLS Higher Secondary  School  KOLLAMCODE", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20382", district:"11", Block:"191", label:"ST ALOYSIUS HSS MARTHANDANTHURAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20383", district:"11", Block:"191", label:"RTM LMS HSS VENKANCHI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20384", district:"11", Block:"191", label:"SHREE VR V V MATRIC HIGH SCHOOL KOLLEMCODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"20385", district:"11", Block:"191", label:"ST NICHOLOSE HS  NEERODITHURAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20386", district:"11", Block:"191", label:"GOVT HSS EZHUDESAPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20387", district:"11", Block:"191", label:"ST JOSEPH HS KARUNYAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20388", district:"11", Block:"191", label:"PIUS XI  HSS  THOOTHOOR", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20389", district:"11", Block:"191", label:"JAYAMATHA MATRICULATION SCHOOL CHRISTURAJAPURAM NITHRAVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20390", district:"11", Block:"191", label:"ST LITTLE FLOWER MHS KANJAMPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20391", district:"11", Block:"191", label:"GOVT ADW HSS KALINGARAJAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20392", district:"11", Block:"191", label:"GOVERNMENT HIGH SCHOOL  ANANTHAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"20393", district:"11", Block:"191", label:"GOVERNMENT HIGHER SECONDARY SCHOOL  THENGAPATTANAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20394", district:"11", Block:"191", label:"AMSI HIGH SCHOOL", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20395", district:"11", Block:"191", label:"AL AMEEN MATRICULATION SCHOOL, THENGAIPATTINAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20396", district:"11", Block:"191", label:"GPVMS  MANKADU", Sch_type:"Government", Category:"Middle School"},
    { key:"20397", district:"11", Block:"191", label:"GOVERNMENT MIDDLE SCHOOL PALLIKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"20398", district:"11", Block:"191", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL MUNCHIRAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20399", district:"11", Block:"191", label:"ST MARYS  MS  PUTHUKDADAI", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20400", district:"11", Block:"191", label:"ST FRANCIS HSS  VAVARAI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20401", district:"11", Block:"191", label:"GOVERNMENT MIDDLE SCHOOL, KALIAKKAVILAI", Sch_type:"Government", Category:"Middle School"},
    { key:"20402", district:"11", Block:"191", label:"Sacred Heart Middle School Kamarajnagar", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"20403", district:"11", Block:"191", label:"ST. JOSEPH'S MIDDLE SCHOOL VALANOOR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20404", district:"11", Block:"191", label:"ST.EPHREM'S M.S.C  HSS, SOORIACODE", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20405", district:"11", Block:"191", label:"SREE BHAGAVATHY VIDYALAYA MATRIC HR. SEC. SCHOOL MEKKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20406", district:"11", Block:"191", label:"GOVT HS MARAYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20407", district:"11", Block:"191", label:"ST ANTONYS HS KAPPUKADU", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20408", district:"11", Block:"191", label:"CSI VV HSS IRENIPURAM", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20409", district:"11", Block:"191", label:"CSI VV (G) HS IRENIPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20410", district:"11", Block:"191", label:"SRI VIGNESWARA MHSS KAPPUKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20411", district:"11", Block:"191", label:"C.S.I. GNANASIROMONY MATRICULATION SCHOOL IRENIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20412", district:"11", Block:"191", label:"SRI KRISHNA MATRICULATION  SCHOOL-THAVITTAVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20413", district:"11", Block:"191", label:"GOVT HS PALKULAM", Sch_type:"Government", Category:"High School"},
    { key:"20414", district:"11", Block:"191", label:"GHS ATHENCODE", Sch_type:"Government", Category:"High School"},
    { key:"20415", district:"11", Block:"191", label:"CHRIST THE KING HS PIRACODE", Sch_type:"Fully Aided", Category:"High School"},
    { key:"20416", district:"13", Block:"376", label:"GHS A.PALLATHUR", Sch_type:"Government", Category:"High School"},
    { key:"20417", district:"13", Block:"376", label:"PUMS ANDIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20418", district:"13", Block:"376", label:"GHSS ATHIPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20419", district:"13", Block:"376", label:"GHS CHENNAPPANAICKANUR", Sch_type:"Government", Category:"High School"},
    { key:"20420", district:"13", Block:"376", label:"GHS P.ETTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20421", district:"13", Block:"376", label:"GHS GOVINDAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20422", district:"13", Block:"376", label:"GHS HANUMANTHEERTHAM", Sch_type:"Government", Category:"High School"},
    { key:"20423", district:"13", Block:"376", label:"GHSS KALLAVI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20424", district:"13", Block:"376", label:"GHS- GIRLS KALLAVI ", Sch_type:"Government", Category:"High School"},
    { key:"20425", district:"13", Block:"376", label:"WISDOM MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20426", district:"13", Block:"376", label:"SRI BALAVIDHYA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20427", district:"13", Block:"376", label:"GHSS KARAPATTU - MODEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20428", district:"13", Block:"376", label:"GHSS KEELKUPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20429", district:"13", Block:"376", label:"GHS KEELMATHUR", Sch_type:"Government", Category:"High School"},
    { key:"20430", district:"13", Block:"376", label:"PUMS KETHUNAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20431", district:"13", Block:"376", label:"PUMS KOMMAMPATTU", Sch_type:"Government", Category:"Middle School"},
    { key:"20432", district:"13", Block:"376", label:"GHS KONAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20433", district:"13", Block:"376", label:"PUMS KONDAMPATTY", Sch_type:"Government", Category:"Middle School"},
    { key:"20434", district:"13", Block:"376", label:"GHS KOTTUKARAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20435", district:"13", Block:"376", label:"PUMS LAKKAMPATTI COLONY", Sch_type:"Government", Category:"Middle School"},
    { key:"20436", district:"13", Block:"376", label:"GHSS MAGANURPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20437", district:"13", Block:"376", label:"GHSS MITTAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20438", district:"13", Block:"376", label:"MARY WARD MATRIC HSS MITTAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20439", district:"13", Block:"376", label:"BALA MANDIR MATRICULATION SCHOOL B.PUDUR, MITTAPALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20440", district:"13", Block:"376", label:"GHS NAICKANUR", Sch_type:"Government", Category:"High School"},
    { key:"20441", district:"13", Block:"376", label:"PUMS NALLAVAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20442", district:"13", Block:"376", label:"GHSS PERIYATHALLAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20443", district:"13", Block:"376", label:"GHS PUNGANAI", Sch_type:"Government", Category:"High School"},
    { key:"20444", district:"13", Block:"376", label:"GHSS SINGARAPETTAI BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20445", district:"13", Block:"376", label:"GHSS- GIRLS  SINGARAPETTAI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20446", district:"13", Block:"376", label:"MOTHERS MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20447", district:"13", Block:"376", label:"GHS THIRUVANAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"20448", district:"13", Block:"376", label:"GHS UPPARAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20449", district:"13", Block:"376", label:"GHSS UTHANGARAI BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20450", district:"13", Block:"376", label:"GHSS-GIRLS UTHANGARAI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20451", district:"13", Block:"376", label:"PUMS CHENGALNEERPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20452", district:"13", Block:"376", label:"GHS EGGUR", Sch_type:"Government", Category:"High School"},
    { key:"20453", district:"13", Block:"376", label:"PUMS P.NADUPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20454", district:"13", Block:"376", label:"GHS N.VELLALAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"20455", district:"13", Block:"139", label:"GHS ITTIKKALAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"20456", district:"13", Block:"139", label:"PUMS AGRAHARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20457", district:"13", Block:"139", label:"GHSS ALAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20458", district:"13", Block:"139", label:"PUMS BALAGURI", Sch_type:"Government", Category:"Middle School"},
    { key:"20459", district:"13", Block:"139", label:"PUMS BALIGANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"20460", district:"13", Block:"139", label:"PUMS BELLAMPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20461", district:"13", Block:"139", label:"GHS BELLARAMPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20462", district:"13", Block:"139", label:"GHS BETTEPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20463", district:"13", Block:"139", label:"PUMS BOGANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20464", district:"13", Block:"139", label:"D.K.SAMY MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20465", district:"13", Block:"139", label:"GHS CHIKKAPOOVATHI", Sch_type:"Government", Category:"High School"},
    { key:"20466", district:"13", Block:"139", label:"GHSS CHINNAMELUPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20467", district:"13", Block:"139", label:"PUMS DHASIRIPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20468", district:"13", Block:"139", label:"PUMS EKKALNATHAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20469", district:"13", Block:"139", label:"GHS GANGALERI", Sch_type:"Government", Category:"High School"},
    { key:"20470", district:"13", Block:"139", label:"GHS GIDDAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20471", district:"13", Block:"139", label:"PUMS GOLLAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20472", district:"13", Block:"139", label:"GHS GUMMANUR", Sch_type:"Government", Category:"High School"},
    { key:"20473", district:"13", Block:"139", label:"PUMS K.A.NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"20474", district:"13", Block:"139", label:"GHS JAGIR VENKATAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20475", district:"13", Block:"139", label:"GHSS K.R.P.DAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20476", district:"13", Block:"139", label:"PUMS KALLAKURUKKI", Sch_type:"Government", Category:"Middle School"},
    { key:"20477", district:"13", Block:"139", label:"GHS KAMMAMPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20478", district:"13", Block:"139", label:"TES HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20479", district:"13", Block:"139", label:"VIJAY MILLINIUM CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20480", district:"13", Block:"139", label:"PUMS KOTHUR GIRLS (U)", Sch_type:"Government", Category:"Middle School"},
    { key:"20481", district:"13", Block:"139", label:"SVC MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20482", district:"13", Block:"139", label:"GHS MADEPATTY", Sch_type:"Government", Category:"High School"},
    { key:"20483", district:"13", Block:"139", label:"GHS MAHARAJAKADAI", Sch_type:"Government", Category:"High School"},
    { key:"20484", district:"13", Block:"139", label:"GHS MADHINAYANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20485", district:"13", Block:"139", label:"GHS MEKALACHINNAMPALLI (GIRLS)", Sch_type:"Government", Category:"High School"},
    { key:"20486", district:"13", Block:"139", label:"KAMARAJ GHSS  MEKALACHINNAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20487", district:"13", Block:"139", label:"PUMS MELERIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"20488", district:"13", Block:"139", label:"GHS MITTAPALLI(KRI)", Sch_type:"Government", Category:"High School"},
    { key:"20489", district:"13", Block:"139", label:"PUMS NARALAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20490", district:"13", Block:"139", label:"PUMS OBELESAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20491", district:"13", Block:"139", label:"PUMS PEDDATHALAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20492", district:"13", Block:"139", label:"CAMBRIDGE MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20493", district:"13", Block:"139", label:"GHS PERIYAKOTTAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20494", district:"13", Block:"139", label:"PUMS PERIYATHAKKEPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20495", district:"13", Block:"139", label:"GHS POTHINAYANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20496", district:"13", Block:"139", label:"PUMS R.POOSARIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20497", district:"13", Block:"139", label:"GHS SOKKADI", Sch_type:"Government", Category:"High School"},
    { key:"20498", district:"13", Block:"139", label:"PUMS THUDUGANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20499", district:"13", Block:"139", label:"PUMS THURINJIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20500", district:"13", Block:"139", label:"PUMS VELAGALAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20501", district:"13", Block:"139", label:"GHSS KANAGAMUTLU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20502", district:"13", Block:"139", label:"PUMS BANDARAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20503", district:"13", Block:"139", label:"GHS K.POOSARIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20504", district:"13", Block:"139", label:"GHS NEKKUNDHI", Sch_type:"Government", Category:"High School"},
    { key:"20505", district:"13", Block:"139", label:"GHS PACHIGANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20506", district:"13", Block:"139", label:"PUMS SEMBADAMUTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20507", district:"13", Block:"139", label:"MMS ANNA NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"20508", district:"13", Block:"139", label:"MMS DOWLATABAD (U)", Sch_type:"Government", Category:"Middle School"},
    { key:"20509", district:"13", Block:"139", label:"MMS GANDHI ROAD (U)", Sch_type:"Government", Category:"Middle School"},
    { key:"20510", district:"13", Block:"139", label:"MMS OLD PET", Sch_type:"Government", Category:"Middle School"},
    { key:"20511", district:"13", Block:"139", label:"IELC AIDED MIDDLE SCHOOL", Sch_type:"Partially Aided", Category:"Middle School"},
    { key:"20512", district:"13", Block:"139", label:"MMS FORT GIRLS (U)", Sch_type:"Government", Category:"Middle School"},
    { key:"20513", district:"13", Block:"139", label:"MMS OLD SUB JAIL ROAD ", Sch_type:"Government", Category:"Middle School"},
    { key:"20514", district:"13", Block:"139", label:"MMS RAJU STREET ", Sch_type:"Government", Category:"Middle School"},
    { key:"20515", district:"13", Block:"139", label:"MHSS OLDPET KRISHNAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20516", district:"13", Block:"139", label:"R.C.FATIMA BOYS HS, KRISHNAGIRI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20517", district:"13", Block:"139", label:"GHSS KRISHNAGIRI BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20518", district:"13", Block:"139", label:"GHSS KRISHNAGIRI GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20519", district:"13", Block:"139", label:"VAILANKANNI MATRIC HSS KRISHNAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20520", district:"13", Block:"139", label:"SWAMI VIVEKANANDA MATRIC HSS KRISHNAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20521", district:"13", Block:"139", label:"EDEN GARDEN ENGLISH SCHOOL KRISHNAGIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20522", district:"13", Block:"139", label:"MAHARISHI MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20523", district:"13", Block:"139", label:"KRISHILAND MATRIC SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"20524", district:"13", Block:"139", label:"ST.ANN'S GIRLS HSS KRISHNAGIRI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20525", district:"13", Block:"117", label:"GHSS AGARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20526", district:"13", Block:"117", label:"MUTHAMIZH GHSS ARASAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20527", district:"13", Block:"117", label:"GOVT.GIRLS HS, ARASAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20528", district:"13", Block:"117", label:"SRI RAMAKRISHNA MATRIC HSS ARASAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20529", district:"13", Block:"117", label:"GHS BALEGULI", Sch_type:"Government", Category:"High School"},
    { key:"20530", district:"13", Block:"117", label:"GHS BANNIHALLI", Sch_type:"Government", Category:"High School"},
    { key:"20531", district:"13", Block:"117", label:"GHS BANNIHALLI PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"20532", district:"13", Block:"117", label:"GHSS BARUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20533", district:"13", Block:"117", label:"PUMS BENDARHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20534", district:"13", Block:"117", label:"GHS CHETTIMARAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20535", district:"13", Block:"117", label:"PUMS SATHINAICKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20536", district:"13", Block:"117", label:"GHSS-GIRLS  KAVERIPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20537", district:"13", Block:"117", label:"PUMS K.MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20538", district:"13", Block:"117", label:"PUMS KAVERIPATTINAM URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"20539", district:"13", Block:"117", label:"GHSS-BOYS  KAVERIPATTINAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20540", district:"13", Block:"117", label:"PUMS KEELKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20541", district:"13", Block:"117", label:"GHS KOORAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20542", district:"13", Block:"117", label:"PUMS MANI NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"20543", district:"13", Block:"117", label:"PUMS MARICHETTIHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20544", district:"13", Block:"117", label:"ASHOK MISSION MATRIC.HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20545", district:"13", Block:"117", label:"PUMS MITTAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20546", district:"13", Block:"117", label:"GHS KADHIRIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20547", district:"13", Block:"117", label:"GHSS MORANAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20548", district:"13", Block:"117", label:"PUMS N.THATTAKKAL", Sch_type:"Government", Category:"Middle School"},
    { key:"20549", district:"13", Block:"117", label:"GHSS NADUPAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20550", district:"13", Block:"117", label:"NATIONAL MATRICULATION SCHOOL - PAIYUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20551", district:"13", Block:"117", label:"GHSS-GIRLS  NAGARASAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20552", district:"13", Block:"117", label:"PR GHSS-BOYS, NAGARASAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20553", district:"13", Block:"117", label:"PUMS NARIMEDU", Sch_type:"Government", Category:"Middle School"},
    { key:"20554", district:"13", Block:"117", label:"GHS NATTANMAIKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"20555", district:"13", Block:"117", label:"GHSS NEDUNGAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20556", district:"13", Block:"117", label:"PBA GHS-GIRLS  PANNANDUR", Sch_type:"Government", Category:"High School"},
    { key:"20557", district:"13", Block:"117", label:"PDB GHSS PANNANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20558", district:"13", Block:"117", label:"PUMS PENNESWARAMADAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20559", district:"13", Block:"117", label:"GHS PERIYAKARADIYUR", Sch_type:"Government", Category:"High School"},
    { key:"20560", district:"13", Block:"117", label:"PUMS POTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20561", district:"13", Block:"117", label:"PUMS PULIYAMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20562", district:"13", Block:"117", label:"GHSS RAMAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20563", district:"13", Block:"117", label:"CAMBRIDGE PUBLIC e-SCHOOL, KAVERIPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20564", district:"13", Block:"117", label:"PUMS SAVALOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"20565", district:"13", Block:"117", label:"GHS SOBANUR", Sch_type:"Government", Category:"High School"},
    { key:"20566", district:"13", Block:"117", label:"PUMS THERPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20567", district:"13", Block:"117", label:"GHS THIMMAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20568", district:"13", Block:"117", label:"GHS THOPPUR", Sch_type:"Government", Category:"High School"},
    { key:"20569", district:"13", Block:"117", label:"GHS VADAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"20570", district:"13", Block:"117", label:"NATIONAL HSS VARATTAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20571", district:"13", Block:"117", label:"PUMS VEERAMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"20572", district:"13", Block:"117", label:"GHSS VELAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20573", district:"13", Block:"117", label:"GHS VILANGAMUDI", Sch_type:"Government", Category:"High School"},
    { key:"20574", district:"13", Block:"117", label:"PUMS BERUHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20575", district:"13", Block:"117", label:"PUMS DEVARMUKKULAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20576", district:"13", Block:"117", label:"GHS GUNDALAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20577", district:"13", Block:"117", label:"GHS JAGADAP", Sch_type:"Government", Category:"High School"},
    { key:"20578", district:"13", Block:"117", label:"GHS KALVEHALLI", Sch_type:"Government", Category:"High School"},
    { key:"20579", district:"13", Block:"117", label:"GHS KARAGUR", Sch_type:"Government", Category:"High School"},
    { key:"20580", district:"13", Block:"117", label:"GHS METTUPULIYUR", Sch_type:"Government", Category:"High School"},
    { key:"20581", district:"13", Block:"117", label:"PUMS THALIHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20582", district:"13", Block:"35", label:"PUMS AMBALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20583", district:"13", Block:"35", label:"GHSS ANCHOOR-JAGADEVI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20584", district:"13", Block:"35", label:"AES HSS PERIYAPANAMUTLU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20585", district:"13", Block:"35", label:"GHS BALETHOTTAM", Sch_type:"Government", Category:"High School"},
    { key:"20586", district:"13", Block:"35", label:"PUMS GENGINAYAKKANPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20587", district:"13", Block:"35", label:"PUMS BANDASEEMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20588", district:"13", Block:"35", label:"GHSS BARGUR BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20589", district:"13", Block:"35", label:"GHSS-GIRLS  BARGUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20590", district:"13", Block:"35", label:"CONCORDIA HSS  BARGUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20591", district:"13", Block:"35", label:"IELC BLIND BARGUR", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20592", district:"13", Block:"35", label:"VAILANKANNI HSS BARGUR KRISHNAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20593", district:"13", Block:"35", label:"GHS BELAVARTHI", Sch_type:"Government", Category:"High School"},
    { key:"20594", district:"13", Block:"35", label:"GHSS CHINTHAGAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20595", district:"13", Block:"35", label:"PUMS DHINNUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20596", district:"13", Block:"35", label:"ST.ANTONY'S HSS ELATHAGIRI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"20597", district:"13", Block:"35", label:"GHS EMAKKALNATHAM", Sch_type:"Government", Category:"High School"},
    { key:"20598", district:"13", Block:"35", label:"PUMS GANGAVARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20599", district:"13", Block:"35", label:"PUMS GOLRUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20600", district:"13", Block:"35", label:"GHS GUNDIYALNATHAM", Sch_type:"Government", Category:"High School"},
    { key:"20601", district:"13", Block:"35", label:"GHSS IKUNDAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20602", district:"13", Block:"35", label:"GHS JINJAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20603", district:"13", Block:"35", label:"GHS KALIKOIL", Sch_type:"Government", Category:"High School"},
    { key:"20604", district:"13", Block:"35", label:"PUMS KANAMOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"20605", district:"13", Block:"35", label:"ST.XAVIER MS, KANDIKUPPAM", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20606", district:"13", Block:"35", label:"GHSS KAPPALVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20607", district:"13", Block:"35", label:"GHS KARAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"20608", district:"13", Block:"35", label:"GHS KONDAPPANAYANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20609", district:"13", Block:"35", label:"PUMS KONGANSERU", Sch_type:"Government", Category:"Middle School"},
    { key:"20610", district:"13", Block:"35", label:"ANNA ARIVAGAM HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20611", district:"13", Block:"35", label:"PUMS MAJITH GOLLAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20612", district:"13", Block:"35", label:"GHSS MALLAPADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20613", district:"13", Block:"35", label:"PUMS MARUDEPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20614", district:"13", Block:"35", label:"GHSS MELKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20615", district:"13", Block:"35", label:"GHS MINDIGIRI", Sch_type:"Government", Category:"High School"},
    { key:"20616", district:"13", Block:"35", label:"GHS NACKALPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20617", district:"13", Block:"35", label:"GHS OPPATHAVADI", Sch_type:"Government", Category:"High School"},
    { key:"20618", district:"13", Block:"35", label:"GHSS ORAPPAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20619", district:"13", Block:"35", label:"PUMS PARAVEPPANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20620", district:"13", Block:"35", label:"GHS PERIYAPANAMUTLU", Sch_type:"Government", Category:"High School"},
    { key:"20621", district:"13", Block:"35", label:"GHSS PERUGOPANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20622", district:"13", Block:"35", label:"GHSS GIRLS POCHAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20623", district:"13", Block:"35", label:"GHSS BOYS POCHAMPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20624", district:"13", Block:"35", label:"GHSS PULIYAMPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20625", district:"13", Block:"35", label:"GHSS-GIRLS  SANTHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20626", district:"13", Block:"35", label:"GHSS SANTHUR BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20627", district:"13", Block:"35", label:"PUMS SEEMANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20628", district:"13", Block:"35", label:"GHSS SIGARALAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20629", district:"13", Block:"35", label:"GHS SOOLAMALAI", Sch_type:"Government", Category:"High School"},
    { key:"20630", district:"13", Block:"35", label:"PUMS SRINIVASAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20631", district:"13", Block:"35", label:"PUMS THELLABENDA", Sch_type:"Government", Category:"Middle School"},
    { key:"20632", district:"13", Block:"35", label:"GHSS-GIRLS THOGARAPALLI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20633", district:"13", Block:"35", label:"GHSS THOGARAPALLI BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20634", district:"13", Block:"35", label:"GHSS VARATANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20635", district:"13", Block:"35", label:"PUMS VARAMANAGUNDA", Sch_type:"Government", Category:"Middle School"},
    { key:"20636", district:"13", Block:"35", label:"GHS VENNAMPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20637", district:"13", Block:"35", label:"PUMS AGAMUDINAGAMANGALAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20638", district:"13", Block:"35", label:"PUMS CHINNAMATTARAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20639", district:"13", Block:"35", label:"PUMS MADHEPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20640", district:"13", Block:"35", label:"PUMS PUDHU MOTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20641", district:"13", Block:"35", label:"PUMS THATHEMPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20642", district:"13", Block:"35", label:"GHS VEPPALAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20643", district:"13", Block:"398", label:"PUMS  BADDHIMADUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"20644", district:"13", Block:"398", label:"GOVT HSS, CHENNASANDIRAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20645", district:"13", Block:"398", label:"PUMS CHIGARAMANAKAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20646", district:"13", Block:"398", label:"GOVT HS, ENNEGOLLUPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"20647", district:"13", Block:"398", label:"PUMS GANGASANDIRAM(URUDU)", Sch_type:"Government", Category:"Middle School"},
    { key:"20648", district:"13", Block:"398", label:"PUMS HALEKUNDANI", Sch_type:"Government", Category:"Middle School"},
    { key:"20649", district:"13", Block:"398", label:"GOVT HS, KORALNATHAM", Sch_type:"Government", Category:"High School"},
    { key:"20650", district:"13", Block:"398", label:"GHSS, KUNDARAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20651", district:"13", Block:"398", label:"SRI SATHYA SAI MATRIC.HR SEC SCHOOL, KUNDARAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20652", district:"13", Block:"398", label:"GOVT HSS KURUBARAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20653", district:"13", Block:"398", label:"KGBV POLUPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20654", district:"13", Block:"398", label:"GHSS, MANAVARANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20655", district:"13", Block:"398", label:"PUMS MARASANDIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20656", district:"13", Block:"398", label:"PUMS NEDUSALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"20657", district:"13", Block:"398", label:"PUMS RAMASANDIRAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20658", district:"13", Block:"398", label:"GOVT.HS,THEERTHAM", Sch_type:"Government", Category:"High School"},
    { key:"20659", district:"13", Block:"398", label:"GOVT.HS, V.MADEPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20660", district:"13", Block:"398", label:"SHRI MARUTHI MATRIC HIGHER SECONDARY SCHOOL V.MADEPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20661", district:"13", Block:"398", label:"GOVT. HSS-BOYS  VEPPANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20662", district:"13", Block:"398", label:"GHSS-GIRLS, VEPPANAPALLI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20663", district:"13", Block:"398", label:"GOVT HS, CHINNAKOTHUR", Sch_type:"Government", Category:"High School"},
    { key:"20664", district:"13", Block:"398", label:"PUMS KODIPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20665", district:"13", Block:"398", label:"PUMS NALLUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20666", district:"13", Block:"398", label:"GOVT HS, NEDUMARTHI", Sch_type:"Government", Category:"High School"},
    { key:"20667", district:"13", Block:"398", label:"GOVT.HS, NERALAGIRI", Sch_type:"Government", Category:"High School"},
    { key:"20668", district:"13", Block:"291", label:"GHS ADDAGURUKKI", Sch_type:"Government", Category:"High School"},
    { key:"20669", district:"13", Block:"291", label:"PUMS AGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20670", district:"13", Block:"291", label:"PUMS ALUSONAI", Sch_type:"Government", Category:"Middle School"},
    { key:"20671", district:"13", Block:"291", label:"PUMS ARUPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20672", district:"13", Block:"291", label:"PUMS ATHIMUGAM-URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"20673", district:"13", Block:"291", label:"JNM GHS ATHIMUGAM", Sch_type:"Government", Category:"High School"},
    { key:"20674", district:"13", Block:"291", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,  BERIGAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20675", district:"13", Block:"291", label:"SRI VIDYA BHARATHI MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20676", district:"13", Block:"291", label:"GHSS BUKKASAGARAM -TEL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20677", district:"13", Block:"291", label:"PUMS CHAPPADI", Sch_type:"Government", Category:"Middle School"},
    { key:"20678", district:"13", Block:"291", label:"GHS CHINNADINNUR", Sch_type:"Government", Category:"High School"},
    { key:"20679", district:"13", Block:"291", label:"PUMS D.KOTHAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20680", district:"13", Block:"291", label:"GHS DEVASANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20681", district:"13", Block:"291", label:"GOVERNMENT HIGH SCHOOL,  DODDEGOUNDAMPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20682", district:"13", Block:"291", label:"GHSS ENUSONAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20683", district:"13", Block:"291", label:"PUMS GEJJALANDODDI -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20684", district:"13", Block:"291", label:"PUMS GUDISADANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20685", district:"13", Block:"291", label:"GHSS HALESEEBAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20686", district:"13", Block:"291", label:"PUMS HANUMANTHAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20687", district:"13", Block:"291", label:"PUMS HOSAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20688", district:"13", Block:"291", label:"GHSS IMMEDINAYAKANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20689", district:"13", Block:"291", label:"PUMS KADATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20690", district:"13", Block:"291", label:"GHSS KALINGAVARAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20691", district:"13", Block:"291", label:"GHS KAMANDODDI -TEL", Sch_type:"Government", Category:"High School"},
    { key:"20692", district:"13", Block:"291", label:"PUMS KANALATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20693", district:"13", Block:"291", label:"PUMS KATTIGANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20694", district:"13", Block:"291", label:"PUMS KOTHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20695", district:"13", Block:"291", label:"PUMS KOTTAYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20696", district:"13", Block:"291", label:"PUMS KUMBALAM -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20697", district:"13", Block:"291", label:"GHS MORASAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20698", district:"13", Block:"291", label:"GHS MUDUGURIKI", Sch_type:"Government", Category:"High School"},
    { key:"20699", district:"13", Block:"291", label:"PUMS NALLAGANAKOTHAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20700", district:"13", Block:"291", label:"PUMS NARASEEPURAM -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20701", district:"13", Block:"291", label:"GHS NERIGAM -TEL", Sch_type:"Government", Category:"High School"},
    { key:"20702", district:"13", Block:"291", label:"PUMS PALAVATHIMMANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20703", district:"13", Block:"291", label:"PUMS PANNAPALLI -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20704", district:"13", Block:"291", label:"GOVERNMENT HIGH SCHOOL, PATHAKOTTA", Sch_type:"Government", Category:"High School"},
    { key:"20705", district:"13", Block:"291", label:"RAJAJI MEMORIAL GHS PERANDAPALLI -TEL", Sch_type:"Government", Category:"High School"},
    { key:"20706", district:"13", Block:"291", label:"TVS ACADEMY M.BATHALAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20707", district:"13", Block:"291", label:"PUMS PULIYARASI", Sch_type:"Government", Category:"Middle School"},
    { key:"20708", district:"13", Block:"291", label:"PUMS SHOOLAGIRI URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"20709", district:"13", Block:"291", label:"GHSS-GIRLS  SHOOLAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20710", district:"13", Block:"291", label:"GHSS BOYS SHOOLAGIRI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20711", district:"13", Block:"291", label:"KGBV SHOOLAGIRI", Sch_type:"Government", Category:"Middle School"},
    { key:"20712", district:"13", Block:"291", label:"JOHN MARIE MEMORIAL MATRIC SCHOOL, SHOOLAGIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20713", district:"13", Block:"291", label:"NEW LITTLE FLOWER MHSS SHOOLAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20714", district:"13", Block:"291", label:"MODEL HSS SHOOLAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20715", district:"13", Block:"291", label:"GHS ULAGAM", Sch_type:"Government", Category:"High School"},
    { key:"20716", district:"13", Block:"291", label:"GHS UNGATTI", Sch_type:"Government", Category:"High School"},
    { key:"20717", district:"13", Block:"291", label:"GHS A.SETTIPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20718", district:"13", Block:"291", label:"PUMS CHIMPALTHIRADI", Sch_type:"Government", Category:"Middle School"},
    { key:"20719", district:"13", Block:"291", label:"PUMS DORIPALLI -TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20720", district:"13", Block:"291", label:"GHS MORANAPALLI -TEL", Sch_type:"Government", Category:"High School"},
    { key:"20721", district:"13", Block:"291", label:"PUMS SAMANAPALLI -TAM-TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20722", district:"13", Block:"291", label:"GHS VENKATESAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20723", district:"13", Block:"291", label:"PUMS PEDDASIGARALAPALLI-URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"20724", district:"13", Block:"291", label:"PUMS THIYAGARASANAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20725", district:"13", Block:"291", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, UDHANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20726", district:"13", Block:"291", label:"SARASWATHY VIDYALAYA MATRIC SCHOOL, UDDANAPALLI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20727", district:"13", Block:"78", label:"GHS ANDIVADI", Sch_type:"Government", Category:"High School"},
    { key:"20728", district:"13", Block:"78", label:"GHSS-GIRLS  BAGALUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20729", district:"13", Block:"78", label:"GHSS-BOYS  BAGALUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20730", district:"13", Block:"78", label:"SHANTHIBAVAN RESIDENTIAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20731", district:"13", Block:"78", label:"GHS BHARATHIYAR NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"20732", district:"13", Block:"78", label:"GHS DASARAPALLIDINNA", Sch_type:"Government", Category:"High School"},
    { key:"20733", district:"13", Block:"78", label:"GHS DEVEERAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20734", district:"13", Block:"78", label:"GOVT URDU HR. SEC. SCHOOL - HOSUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20735", district:"13", Block:"78", label:"JOHN BOSCO GIRLS HSS HOSUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20736", district:"13", Block:"78", label:"RV GHSS-BOYS HOSUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20737", district:"13", Block:"78", label:"GOVT. MODEL GIRLS HR SEC SCHOOL  HOSUR ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20738", district:"13", Block:"78", label:"BLOSSOM MATRIC", Sch_type:"Un-aided", Category:"High School"},
    { key:"20739", district:"13", Block:"78", label:"CAMBRIDGE MAT.SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20740", district:"13", Block:"78", label:"DAVID SIRONMANI MAT.", Sch_type:"Un-aided", Category:"High School"},
    { key:"20741", district:"13", Block:"78", label:"MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20742", district:"13", Block:"78", label:"SWATHI MHSS HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20743", district:"13", Block:"78", label:"SIDDARTH VILLAGE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20744", district:"13", Block:"78", label:"SISHYA SCHOOL HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20745", district:"13", Block:"78", label:"ASIAN CHRISTIAN HIGH SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20746", district:"13", Block:"78", label:"THE ASHOK LEYLAND SCHOOL HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20747", district:"13", Block:"78", label:"GHSS, MULLAINAGAR, HOSUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20748", district:"13", Block:"78", label:"GHS-GIRLS  HOSUR ", Sch_type:"Government", Category:"High School"},
    { key:"20749", district:"13", Block:"78", label:"SHANTHI NIKETHAN MATRIC SCHOOL, HOSUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20750", district:"13", Block:"78", label:"GHS KOTHAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20751", district:"13", Block:"78", label:"GHSS MACHINAYAKANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20752", district:"13", Block:"78", label:"GHSS MATHIGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20753", district:"13", Block:"78", label:"THE TITAN SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20754", district:"13", Block:"78", label:"GHS MOOKANDAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20755", district:"13", Block:"78", label:"GHSS NALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20756", district:"13", Block:"78", label:"GHS ONNALVADI", Sch_type:"Government", Category:"High School"},
    { key:"20757", district:"13", Block:"78", label:"GURUDHURONACHARIA HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20758", district:"13", Block:"78", label:"NATIONAL MATRIC.HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20759", district:"13", Block:"78", label:"GHS SAMATHUVAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20760", district:"13", Block:"78", label:"GHS THORAPALLI AGRAHARAM", Sch_type:"Government", Category:"High School"},
    { key:"20761", district:"13", Block:"78", label:"VANAPRASTHA INTERNATIONAL SCHOOL, MUGALAPALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20762", district:"13", Block:"78", label:"MOUNT CARMEL MATRI HR.SEC. SCHOOL HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20763", district:"13", Block:"78", label:"GHSS ZUZUVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20764", district:"13", Block:"78", label:"OPEN ARMS INTERNATIONAL SCHOOL,HOSUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"20765", district:"13", Block:"78", label:"GHS CHOODAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20766", district:"13", Block:"78", label:"GHS MUTHALI", Sch_type:"Government", Category:"High School"},
    { key:"20767", district:"13", Block:"122", label:"GHS ALASATTY TELUGU", Sch_type:"Government", Category:"High School"},
    { key:"20768", district:"13", Block:"122", label:"GHS BAIRAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"20769", district:"13", Block:"122", label:"GHS BEVANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"20770", district:"13", Block:"122", label:"ST THOMAS BRILLIANT MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20771", district:"13", Block:"122", label:"GHS D RATHINAGIRI", Sch_type:"Government", Category:"High School"},
    { key:"20772", district:"13", Block:"122", label:"ETERNAL LIGHT PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20773", district:"13", Block:"122", label:"GHSS-GIRLS  DENKANIKOTTAI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20774", district:"13", Block:"122", label:"ST JOSEPHS HIGH SCHOOL AIDED", Sch_type:"Partially Aided", Category:"High School"},
    { key:"20775", district:"13", Block:"122", label:"GHSS BOYS DENKANIKOTTAI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20776", district:"13", Block:"122", label:"KGBV DENKANIKOTTAI", Sch_type:"Government", Category:"Middle School"},
    { key:"20777", district:"13", Block:"122", label:"GHS DODDABELLUR TELUGU", Sch_type:"Government", Category:"High School"},
    { key:"20778", district:"13", Block:"122", label:"GHS SANTHANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20779", district:"13", Block:"122", label:"GHS-GIRLS  KELAMANGALAM ", Sch_type:"Government", Category:"High School"},
    { key:"20780", district:"13", Block:"122", label:"GHSS KELAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20781", district:"13", Block:"122", label:"MODEL SCHOOL KELAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20782", district:"13", Block:"122", label:"GHS KUNDUMARANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20783", district:"13", Block:"122", label:"GHS LINGEGOWNDAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"20784", district:"13", Block:"122", label:"GHS NAGAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"20785", district:"13", Block:"122", label:"PUMS NELLUKUNTHI", Sch_type:"Government", Category:"Middle School"},
    { key:"20786", district:"13", Block:"122", label:"PUMS NEMILERI", Sch_type:"Government", Category:"Middle School"},
    { key:"20787", district:"13", Block:"122", label:"PUMS PACHAPANATTI TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20788", district:"13", Block:"122", label:"GHSS GIRLS  RAYAKOTTAI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20789", district:"13", Block:"122", label:"GHSS-BOYS RAYAKOTTAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20790", district:"13", Block:"122", label:"ETERNAL LIGHT MATRIC.HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20791", district:"13", Block:"122", label:"PUMS SAMBANDAKOTTA TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20792", district:"13", Block:"122", label:"PUMS SIDDAPURAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20793", district:"13", Block:"122", label:"PUMS SONNE NAYAKKANAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20794", district:"13", Block:"122", label:"GHS THIPPASANDIRAM", Sch_type:"Government", Category:"High School"},
    { key:"20795", district:"13", Block:"122", label:"GHSS  ULLUKURUKKAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20796", district:"13", Block:"122", label:"GHS BOMMATHATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"20797", district:"13", Block:"122", label:"GHS D.T.DINNUR", Sch_type:"Government", Category:"High School"},
    { key:"20798", district:"13", Block:"122", label:"GHS KOPPAKARAI", Sch_type:"Government", Category:"High School"},
    { key:"20799", district:"13", Block:"122", label:"JOHN BRITTO HSS ANIYALAM", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20800", district:"13", Block:"122", label:"PUMS BOLLAKOLLAI", Sch_type:"Government", Category:"Middle School"},
    { key:"20801", district:"13", Block:"122", label:"GHS BETTAMUGILALAM", Sch_type:"Government", Category:"High School"},
    { key:"20802", district:"13", Block:"122", label:"GHSS UNICHETTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20803", district:"13", Block:"122", label:"PUMS THOLVAPETTA", Sch_type:"Government", Category:"Middle School"},
    { key:"20804", district:"13", Block:"311", label:"GHSS ANCHETTY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20805", district:"13", Block:"311", label:"SANTA MARIA ACADEMY  INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20806", district:"13", Block:"311", label:"GHS ANDEVANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20807", district:"13", Block:"311", label:"GHS B.LAKKASANDIRAM", Sch_type:"Government", Category:"High School"},
    { key:"20808", district:"13", Block:"311", label:"GHSS BALATHOTTANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20809", district:"13", Block:"311", label:"PUMS BALATHOTTANAPALLY -URUDU", Sch_type:"Government", Category:"Middle School"},
    { key:"20810", district:"13", Block:"311", label:"PUMS BILIGUNDU", Sch_type:"Government", Category:"Middle School"},
    { key:"20811", district:"13", Block:"311", label:"PUMS CHAKKALAPALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"20812", district:"13", Block:"311", label:"GHS D.KOTHANUR -TEL", Sch_type:"Government", Category:"High School"},
    { key:"20813", district:"13", Block:"311", label:"PUMS DODDUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20814", district:"13", Block:"311", label:"GHS DODDAMANCHI", Sch_type:"Government", Category:"High School"},
    { key:"20815", district:"13", Block:"311", label:"GHS GERATTY", Sch_type:"Government", Category:"High School"},
    { key:"20816", district:"13", Block:"311", label:"GHS JAWALAGIRI -TEL", Sch_type:"Government", Category:"High School"},
    { key:"20817", district:"13", Block:"311", label:"GHS KAKKADASAM -TEL", Sch_type:"Government", Category:"High School"},
    { key:"20818", district:"13", Block:"311", label:"PUMS NOORODHUSAMIMALAI", Sch_type:"Government", Category:"Middle School"},
    { key:"20819", district:"13", Block:"311", label:"IVDP NETHAJI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20820", district:"13", Block:"311", label:"GHS KUNDUKOTTA -TEL", Sch_type:"Government", Category:"High School"},
    { key:"20821", district:"13", Block:"311", label:"GHSS MADHAGONDAPALLI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20822", district:"13", Block:"311", label:"OUR LADY'S HSS MADHAKONDAPALLI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"20823", district:"13", Block:"311", label:"MATHAKONDAPALLI MODEL SCHOOL, MATHAKONDAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20824", district:"13", Block:"311", label:"SHEMFORD SENIOR SECONDARY SCHOOL, MADHAGONDAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20825", district:"13", Block:"311", label:"PUMS MANCHUGONDAPALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20826", district:"13", Block:"311", label:"GHSS NATRAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20827", district:"13", Block:"311", label:"GHSS PEDDABELAGONDAPALLY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20828", district:"13", Block:"311", label:"ST.JOSEPH'S HSS SESURAJAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20829", district:"13", Block:"311", label:"GHS T.SHULAKUNTA", Sch_type:"Government", Category:"High School"},
    { key:"20830", district:"13", Block:"311", label:"GHSS THALLY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20831", district:"13", Block:"311", label:"GHS-GIRLS  THALLY ", Sch_type:"Government", Category:"High School"},
    { key:"20832", district:"13", Block:"311", label:"GHS THALLY -URUDU", Sch_type:"Government", Category:"High School"},
    { key:"20833", district:"13", Block:"311", label:"KGBV THALLY", Sch_type:"Government", Category:"Middle School"},
    { key:"20834", district:"13", Block:"311", label:"GHSS URIGAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20835", district:"13", Block:"311", label:"DE PAUL HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20836", district:"13", Block:"311", label:"GHS ALENATHAM", Sch_type:"Government", Category:"High School"},
    { key:"20837", district:"13", Block:"311", label:"GHS BOOTHATTIKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"20838", district:"13", Block:"311", label:"GHS BIKKANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20839", district:"13", Block:"311", label:"THOOYA SAGAYA MATHA AIDED MIDDLE SCHOOL-DASARAPALLY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20840", district:"13", Block:"311", label:"GHS GUMMALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"20841", district:"13", Block:"311", label:"GHS KARADIKKAL", Sch_type:"Government", Category:"High School"},
    { key:"20842", district:"13", Block:"311", label:"PUMS MARUPALLY -TAM-TEL", Sch_type:"Government", Category:"Middle School"},
    { key:"20843", district:"13", Block:"311", label:"GHS THAGGATTY", Sch_type:"Government", Category:"High School"},
    { key:"20844", district:"13", Block:"177", label:"PUMS AMMANKOILPATHI", Sch_type:"Government", Category:"Middle School"},
    { key:"20845", district:"13", Block:"177", label:"GHSS ANANDUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20846", district:"13", Block:"177", label:"GHSS ATHIGANOOR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20847", district:"13", Block:"177", label:"GHSS GERIGEPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20848", district:"13", Block:"177", label:"GHS GUNDEPALLI", Sch_type:"Government", Category:"High School"},
    { key:"20849", district:"13", Block:"177", label:"GHS K.ETTIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20850", district:"13", Block:"177", label:"GHS KALARPATHY", Sch_type:"Government", Category:"High School"},
    { key:"20851", district:"13", Block:"177", label:"PUMS KANJANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"20852", district:"13", Block:"177", label:"GHSS KANNANDAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20853", district:"13", Block:"177", label:"PUMS KARUVANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"20854", district:"13", Block:"177", label:"GHS KODAMANDAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20855", district:"13", Block:"177", label:"GHS KODIPATHY", Sch_type:"Government", Category:"High School"},
    { key:"20856", district:"13", Block:"177", label:"GHSS KUNNATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20857", district:"13", Block:"177", label:"GHS-GIRLS  KUNNATHUR", Sch_type:"Government", Category:"High School"},
    { key:"20858", district:"13", Block:"177", label:"GHSS M.NADUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20859", district:"13", Block:"177", label:"PUMS M.PALLATHUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20860", district:"13", Block:"177", label:"GHSS MADARAHALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20861", district:"13", Block:"177", label:"PUMS MALAYANDAHALLI", Sch_type:"Government", Category:"Middle School"},
    { key:"20862", district:"13", Block:"177", label:"GHSS MATHUR-BOYS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20863", district:"13", Block:"177", label:"GHSS-GIRLS  MATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20864", district:"13", Block:"177", label:"KALAIMAGAL KALALAYA MHSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20865", district:"13", Block:"177", label:"BHARANI INTERNATIONAL MODERN SCHOOL KANNANDAHALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20866", district:"13", Block:"177", label:"PUMS MUTHAGOUNDANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"20867", district:"13", Block:"177", label:"GHS ODDAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20868", district:"13", Block:"177", label:"GHS RAMAKRISHNAMPATHI", Sch_type:"Government", Category:"High School"},
    { key:"20869", district:"13", Block:"177", label:"PUMS RANGANOOR", Sch_type:"Government", Category:"Middle School"},
    { key:"20870", district:"13", Block:"177", label:"GHSS SAMALPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20871", district:"13", Block:"177", label:"GHS SOOLAGARAI", Sch_type:"Government", Category:"High School"},
    { key:"20872", district:"13", Block:"177", label:"GHS VALIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"20873", district:"13", Block:"177", label:"PUMS VEERACHIKUPPAM", Sch_type:"Government", Category:"Middle School"},
    { key:"20874", district:"13", Block:"177", label:"PUMS ANDERIPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"20875", district:"13", Block:"177", label:"GADWHSS ATHIPALLAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"20876", district:"13", Block:"177", label:"GHS PASANTHI", Sch_type:"Government", Category:"High School"},
    { key:"20877", district:"13", Block:"177", label:"GHS VELAVALLI", Sch_type:"Government", Category:"High School"},
    { key:"20878", district:"2", Block:"162", label:"SUBHAM VIDHYALAYAA SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20879", district:"4", Block:"302", label:"SSVM WORLD SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20880", district:"2", Block:"322", label:"SREE GOKULAM PUBLIC SCHOOL, NENMELI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20881", district:"10", Block:"410", label:"SRI JAYENDRA GOLDEN JUBILEE SCHOOL,ENATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20882", district:"22", Block:"273", label:"CLUNY VIDYA NIKETHAN SCHOOL,ERUMAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20883", district:"4", Block:"107", label:"SATCHIDANANDA JOTHI NIKETHAN INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20884", district:"4", Block:"107", label:"SRI AMBAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20885", district:"4", Block:"279", label:"GRD PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20886", district:"32", Block:"57", label:"AGARAM PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20887", district:"14", Block:"161", label:"MAHARISHI VIDYAMANDIR S, KOILPAPPAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20888", district:"36", Block:"12", label:"SRI SAI VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20889", district:"5", Block:"120", label:"ATHENA GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20890", district:"3", Block:"304", label:"MAHATMA GANDHI VIDYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"20891", district:"33", Block:"338", label:"GUILD OF SERVICES BALAVIGHAR SPL SCH", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20892", district:"33", Block:"252", label:"SAFA N&P SCHOOL AVADI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20893", district:"2", Block:"114", label:"St Pauls Academy, Chengalpattu", Sch_type:"Un-aided", Category:"High School"},
    { key:"20894", district:"33", Block:"184", label:"OPPORTUNITY SCHOOL , OLD NAPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20895", district:"33", Block:"252", label:"DON GUANELLA SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20896", district:"33", Block:"252", label:"SHRI.M.A.KRISHNASWAMY MATRICULATION HIGHER SECONDARY SCHOOL.AVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20897", district:"33", Block:"403", label:"GOOD WORD PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20898", district:"33", Block:"260", label:"ST.CHARLES MATRIC", Sch_type:"Un-aided", Category:"High School"},
    { key:"20899", district:"2", Block:"114", label:"Guardian Int. School, Melamaiyur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20900", district:"2", Block:"322", label:"SRS VIDYA MANDIR , VADAKADAMBADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20901", district:"31", Block:"155", label:"RADIANT MATRIC SCHOOL, KADAVALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20902", district:"8", Block:"65", label:"SAKTHI  SPECIAL SCHOO FOR MENTALLY RETARDEDL, MAMARATHUPALAYAM", Sch_type:"Un-aided", Category:"Others"},
    { key:"20903", district:"8", Block:"65", label:"GOKULAM SPECIAL SCHOOL FOR MENTALLY RETARDED, MUTHAMPALAYAM", Sch_type:"Un-aided", Category:"Others"},
    { key:"20904", district:"8", Block:"185", label:"BHARATHIYAR SPECIAL SCHOOL FOR MENTALLY RETARTED, SAVADIPALAYAM", Sch_type:"Un-aided", Category:"Others"},
    { key:"20905", district:"15", Block:"178", label:"GOOD SAMARITAN MATRICULATION HIGHER SECONDARY SCHOOL MAYILADUTHURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20906", district:"11", Block:"3", label:"JOHNS CENTRAL SCHOOL ANJUGRAMAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20907", district:"3", Block:"69", label:"SUFA MATRICULATION HIGHER SECONDARY  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20908", district:"4", Block:"242", label:"YUVA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20909", district:"31", Block:"82", label:"GREENLAND MATRIC SCHOOL, PUDUPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"20910", district:"11", Block:"191", label:"ST.JOHNS MATRIC SCHOOL- THOOTHOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"20911", district:"33", Block:"364", label:"SHAKTHI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20912", district:"34", Block:"14", label:"BRIGHT PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20913", district:"22", Block:"316", label:"SENGUNTHAR PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20914", district:"17", Block:"254", label:"CHANDRA CHELLAPPAN INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20915", district:"32", Block:"392", label:"NAVA INTERNATIONAL SCHOOL(CBSE), MUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"20916", district:"29", Block:"15", label:"K N R VIDHYALAYA,(CBSE) SRIRANGAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20917", district:"5", Block:"170", label:"SATHIYA SAAI PUBLIC SCHOOL, PASAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"20918", district:"23", Block:"294", label:"MONTFORT  CBSE SCHOOL,SUNDARANADAPPU", Sch_type:"Un-aided", Category:"High School"},
    { key:"20919", district:"14", Block:"324", label:"P.K.N VIDYALAYA CBSE SCHOOL, THIRUMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20920", district:"30", Block:"92", label:"ST JOSEPH MATRICULATION HIGHER SECONDARY SCHOOL KADAMBODUVALVU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20921", district:"11", Block:"3", label:"SHEFFIELD INNOVATIVE CBSE SCHOOL ,IRULAPPAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20922", district:"33", Block:"76", label:"PUMS SANAPUTTUR", Sch_type:"Government", Category:"Middle School"},
    { key:"20923", district:"2", Block:"299", label:"GOVERNMENT  SCHOOL FOR THE HEARING IMPAIRED,,TAMBARAM SANATORIUM,CHENNAI 600047", Sch_type:"Government", Category:"Middle School"},
    { key:"20924", district:"22", Block:"274", label:"GOVT BLIND MIDDLE SCHOOL", Sch_type:"Government", Category:"Middle School"},
    { key:"20925", district:"33", Block:"225", label:"CSI MS, NEDUGAL COLONY", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"20926", district:"33", Block:"338", label:"GRACY SCH FOR MR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20927", district:"33", Block:"403", label:"VASANTHAM SPL SCH", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20928", district:"10", Block:"410", label:"Sri Sairam Inter. Matric school, Valluvapakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"20929", district:"2", Block:"322", label:"V S STARS PUBLIC SCHOOL THIRUKAZHUKUNDRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20930", district:"33", Block:"252", label:"OXFORD PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20931", district:"33", Block:"252", label:"FAITH SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20932", district:"6", Block:"187", label:"SHRI GURU RAGHAVENDRA MATRIC SCHOOL, SILLARAHALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20933", district:"33", Block:"403", label:"HOPE TRUST AMBATTUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20934", district:"33", Block:"403", label:"BALAVIGASH SPL SCH AVADI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20935", district:"33", Block:"403", label:"CARMEL SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20936", district:"33", Block:"403", label:"JAYAM SPL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20937", district:"33", Block:"403", label:"SRI VENKATESWARA VIDHYALAYA SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20938", district:"33", Block:"403", label:"VEL TECH Dr RR & Dr SR MAT HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20939", district:"33", Block:"403", label:"MANASA SPL SC", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20940", district:"33", Block:"403", label:"SATHYALOK SCH FOR SPL CHILDREN(MR)", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20941", district:"34", Block:"93", label:"DON BOSCO MATRICULATION SCHOOL - ADHAMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20942", district:"2", Block:"114", label:"Srimathi Sundaravalli Memorial School, Kolapakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20943", district:"2", Block:"114", label:"Fatima Matric School, Chettipunniyam-603204", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20944", district:"2", Block:"322", label:"JEEVA MEMORIAL PUBLIC  CBSE SENIOR  SECONDARY SCHOOL , PALLAEGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20945", district:"36", Block:"74", label:"LALETHA GOVINDHAN MATRIC SCHOOL, ULLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20946", district:"2", Block:"299", label:"BVM Global School, Seevaram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20947", district:"34", Block:"125", label:"SKV INTERNATIONAL SCHOOL-KEERANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"20948", district:"34", Block:"365", label:"THE PATH GLOBAL PUBLIC SCHOOL  MALAPPAMPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20949", district:"34", Block:"365", label:"AMAZON INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20950", district:"36", Block:"394", label:"IQRA MATRIC SCHOOL, R.N PALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20951", district:"31", Block:"82", label:"JOLARPET THIRUVALLUVAR MATRIC SCHOOL, JOLARPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"20952", district:"34", Block:"256", label:"SRI SARADHA MATRIC SCHOOL -PUDUPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20953", district:"4", Block:"245", label:"THE LITTLE KINGDOM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20954", district:"31", Block:"362", label:"SWAMI VIVEKANADA MATRIC HSS, THIMMANAMUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20955", district:"34", Block:"312", label:"SKV MATRICULATION  HR SEC SCHOOL-KOLUNDAMPET", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20956", district:"34", Block:"395", label:"VETRI SIGARAM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20957", district:"34", Block:"48", label:"JAMBU MAHARISHI VANNIYAR MATRICULATION HIGHER SECONDARY  SCHOOL PALANTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20958", district:"34", Block:"48", label:"VISWA BARATHI MATRICULATION", Sch_type:"Un-aided", Category:"High School"},
    { key:"20959", district:"34", Block:"125", label:"SRI ARUNESHWAR MATRIC SCHOOL-VETTAVALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20960", district:"8", Block:"37", label:"SRI SWAMI VIVEKANANDA INTELLECTUAL DISABLED SCHOOL, BHAVANI", Sch_type:"Un-aided", Category:"Others"},
    { key:"20961", district:"37", Block:"70", label:"SRI DHARANI INTERNATIONAL  SCHOOL GINGEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20962", district:"37", Block:"379", label:"NEW CREATION BILINGUAL SCHOOL KUILAPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20963", district:"9", Block:"50", label:"ECR INTERNATIONAL   SCHOOL KANIYAMOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20964", district:"22", Block:"215", label:"SREE THULASI VIKKAS MATRIC SCHOOL, THOLASAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20965", district:"32", Block:"57", label:"BETHANIYA SPECIAL SCHOOL FOR M.R CHILDREN", Sch_type:"Un-aided", Category:"Others"},
    { key:"20966", district:"26", Block:"53", label:"ST.MICHAEL S MATRIC SCHOOL, SHANTHOOR, KETTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20967", district:"26", Block:"72", label:"SREE SARASWATHI VIVEKANANDA  MAHA VIDHYALAYA MATRIC AYYANKOLLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"20968", district:"4", Block:"279", label:"KAUMARAM PRASHANTHI  ACADEMY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20969", district:"32", Block:"73", label:"N.V.MATRIC HR,SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20970", district:"32", Block:"371", label:"SHAANTHI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20971", district:"32", Block:"32", label:"SRI HAYAGREVAR VIDYALAYA MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20972", district:"12", Block:"23", label:"ISHETHRA INTERNATIONAL RESIDENTIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20973", district:"1", Block:"26", label:"SRI RAMAKRISHANA PUBLIC SCHOOL VENKATAKRISHNAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"20974", district:"15", Block:"178", label:"IDEAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20975", district:"19", Block:"255", label:"BHARATHI VIDYALAYA MATRIC SCHOOL, THIRUGOKARNAM ", Sch_type:"Un-aided", Category:"High School"},
    { key:"20976", district:"23", Block:"294", label:"ST. XAVIER PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20977", district:"38", Block:"295", label:"MOUNT LITERA ZEE SCHOOL., SIVAKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20978", district:"38", Block:"295", label:"V S K D NADAR GURUKUL MATRIC HR SEC SCHOOL, SATCHIYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20979", district:"24", Block:"307", label:"BHARATH VIDYA MANDIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20980", district:"11", Block:"3", label:"MOUNT LITERA ZEE SCHOOL-MYLAUDY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20981", district:"11", Block:"3", label:"OASIS SPECIAL SCHOOL-CHETTIKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20982", district:"11", Block:"149", label:"JOTHI NILAYAM,PALAPPALLAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20983", district:"11", Block:"124", label:"ARTESIA MATRICULATION HR SEC SCHOOL KAPPUKADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20984", district:"11", Block:"191", label:"ST. MARRY'S SCHOOL AND HOME FOR MENTALLY CHALLENGED CHILDREN KARUNAIILLAM-NAMBALI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20985", district:"25", Block:"313", label:"CHOLA INTERNATIONAL -THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20986", district:"25", Block:"313", label:"YAGAPPA INTERNATIONAL. SCHOOL - THANJAVUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20987", district:"25", Block:"313", label:"CHRIST INTERNATIONAL SCHOOL-NEELAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20988", district:"3", Block:"240", label:"INDIRA VIVEKANANDA VIDHYALAYA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20989", district:"35", Block:"134", label:"KV  KENDRIYA VIDYALAYA THIRUVARUR", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"20990", district:"8", Block:"38", label:"SNR VIDHYA NETHRA MATRICULATION HR.SEC.SCHOOL,VINNAPPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20991", district:"4", Block:"242", label:"BENGLEN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"20992", district:"7", Block:"59", label:"PARVATHY'S ANUGRAHA INTERNATIONAL SCHOOL, DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20993", district:"7", Block:"59", label:"JMJ MATRIC, SIRUMALAIPUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"20994", district:"19", Block:"326", label:"MOUNTOLIVE MATRIC HR.SEC.SCHOOL,MOONGITHAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20995", district:"19", Block:"25", label:"AL-MUNAWARA ISLAMIC SCHOOL, SIVAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20996", district:"23", Block:"94", label:"PAUL SANKAR CBSE SCHOOL,NARUPUTHAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"20997", district:"27", Block:"40", label:"THE SPICE VALLEY PUBLIC SCHOOL - BODI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20998", district:"28", Block:"320", label:"PEARL PUBLIC SCHOOL, ARUMUGANERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"20999", district:"30", Block:"222", label:"PUSHPALATHA BRITISH INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21000", district:"33", Block:"64", label:"SUGUNAMMAL  MATRIC SCHOOL VENGAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21001", district:"33", Block:"252", label:"JAYA PUBLIC SCHOOL, Thiruninravur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21002", district:"2", Block:"299", label:"Annie matric. Hr. Sec.school,Mudichur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21003", district:"33", Block:"252", label:"SRI BUDDHA VIDYA MANDIR MATRIC HR SEC SCHOOL, THIRUMAZHISAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21004", district:"33", Block:"289", label:"DR.GUPTA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21005", district:"33", Block:"403", label:"ANNAI TERASA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21006", district:"33", Block:"225", label:"SRI GNANA POORNA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21007", district:"3", Block:"69", label:"SRI MAGESHWARA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21008", district:"2", Block:"162", label:"VM Vidya Kendra Mat.School, Madurantakam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21009", district:"31", Block:"82", label:"VEDHA MATRIC HSS, T.VEERAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21010", district:"31", Block:"82", label:"C.S MATRIC HSS, JAYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21011", district:"31", Block:"82", label:"GREEN VALLEY PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21012", district:"6", Block:"198", label:"ISHA VIDHYA INFOSYS MHSS, SAMYCHETTIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21013", district:"6", Block:"198", label:"AKSHAYA MHSS, NATHAHALLI, INDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21014", district:"34", Block:"238", label:"ST.PAULS MATRIC HIGHER SECONDARY SCHOOL - VAYALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21015", district:"34", Block:"27", label:"SHRI BALA VIDHYA MANDIR MATRIC HR SEC  SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21016", district:"34", Block:"44", label:"SIDDHARTH MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21017", district:"9", Block:"95", label:"KALAIVANI MATRICULATION  HIGHER SECONDARY  SCHOOL , PORPADAKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21018", district:"9", Block:"50", label:"MAHA KAVI MATRIC HIGHER SECONDARY SCHOOL , RAYAPPANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21019", district:"22", Block:"68", label:"AIM MATRIC HR. SEC. SCHOOL, NAGIYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21020", district:"22", Block:"163", label:"DHEERAN VIDHYAALAYAA MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21021", district:"22", Block:"273", label:"SRI GAYATHRI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21022", district:"22", Block:"316", label:"SALEM HI-TECH MATRIC SCHOOL, SELVAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21023", district:"22", Block:"316", label:"SRM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21024", district:"17", Block:"360", label:"MEDHA MATRICULATION SCHOOL, PUDHUPULIYAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21025", district:"8", Block:"202", label:"SHREE VIKAS MATRICULATION SCHOOL, K.METTUPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21026", district:"4", Block:"302", label:"MDN FUTURE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21027", district:"7", Block:"29", label:"CHERAN GLOBAL MATRIC. HIGH SCHOOL, SEDAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21028", district:"7", Block:"219", label:"SREE GUNA MATRIC, AMARAPOONDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21029", district:"7", Block:"390", label:"BEDFORD ACADEMY MATRIC HIGHER SECONDARY SCHOOL, MINUKKAMPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21030", district:"29", Block:"171", label:"VIKAS VIDHYASHRAM MATRICULATION HIGHER SECONDARY SCHOOL, INAMKULATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21031", district:"5", Block:"199", label:"BHAVANI VIDYASHRAM MATRICULATION HR SEC  SCHOOL KANDAPPANKURICHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21032", district:"5", Block:"170", label:"DR AKP OXBRIDGE MATRIC HIGHER SECONDARY SCHOOL,  SIRUPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21033", district:"25", Block:"144", label:"CHRIST MATRICULATION SCHOOL KUMBAKONAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21034", district:"38", Block:"295", label:"LVR MATRICULATION SCHOOL,SENGAMALANACHIARPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21035", district:"20", Block:"231", label:"SOURASHTRA MATRICULATION HIGH SCHOOL, EMANESWARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21036", district:"20", Block:"99", label:"RAHMANIA GARDEN MATRIC HIGH SCHOOL, KAMUTHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21037", district:"20", Block:"332", label:"SRI SARASWATHI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, REGUNATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21038", district:"20", Block:"332", label:"SRI KRISHNA MATRICULATION SCHOOL, VANNANGUNDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"21039", district:"28", Block:"217", label:"SPRING MATRICULATION SCHOOL, CHANDRAGIRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21040", district:"30", Block:"385", label:"Sri Vidya Mandir Matriculation Girls High School, Vallioor", Sch_type:"Un-aided", Category:"High School"},
    { key:"21041", district:"30", Block:"385", label:"BALIAH MARTHANDAM MATRICULATION HIGHER SECONDARY  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21042", district:"13", Block:"78", label:"ADVAITH INTERNATIONAL ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21043", district:"13", Block:"78", label:"PARIMALAM MATRIC SCHOOL, HOSUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21044", district:"13", Block:"78", label:"CARMEL INTERNANATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21045", district:"33", Block:"253", label:"VIVEKANANDA VISION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21046", district:"33", Block:"253", label:"Dr.S.Ramakrishnan Memorial CBSE School,Old Thirupachur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21047", district:"4", Block:"279", label:"REEDS WORLD SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21048", district:"33", Block:"403", label:"AIR FORCE SCHOOL AVADI", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"21049", district:"10", Block:"296", label:"RYAN INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21050", district:"2", Block:"114", label:"SCAD World School, Pazhaveli", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21051", district:"2", Block:"322", label:"BLESSINGS INTERNATIONAL SCHOOL , KEERAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21052", district:"2", Block:"300", label:"RAVINDRA BHARATHI  GLOBAL KEELKATTALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21053", district:"2", Block:"162", label:"Prasan Vidya Mandir, Vadapathi", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21054", district:"2", Block:"51", label:"VEDHA VIDHYASHRAM CBSE SCHOOL-MANAPAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21055", district:"2", Block:"299", label:"NSN Memorial Sr.Sec. school(CBSE) , Chitlapakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21056", district:"36", Block:"84", label:"BRAMMAAS VIDHYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21057", district:"36", Block:"84", label:" VIDHYALAKSHMI  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21058", district:"31", Block:"362", label:"DAYANANDA VIDYALAYA MATRIC HR. SEC. SCHOOL, KURUSILAPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21059", district:"6", Block:"58", label:"TARUWIN VIDHYA MANDIR SCHOOL, POOSARIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21060", district:"34", Block:"249", label:"ASIAN MATRICULATION SCHOOL, SENGUNAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21061", district:"37", Block:"70", label:"SRI SAIRAM PUBLIC SCHOOL (CBSE) JAYANKONDAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"21062", district:"37", Block:"100", label:"VET VIDYAA MANDHIR CBSE SCHOOL KANAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21063", district:"9", Block:"323", label:"SRI SIKSHA KENDRA INTERNATIONAL SCHOOL KUCHIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21064", district:"9", Block:"372", label:"SRI RAMANA PUBLIC SCHOOL(CBSE), KATTUSELLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21065", district:"22", Block:"133", label:"WINNAR VIKAS INTERNATIONAL SCHOOL (CBSE), KORANAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21066", district:"22", Block:"34", label:"GLOBAL GATEWAY HI-TECH SCHOOL, ANUPPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21067", district:"22", Block:"274", label:"HOLY ANGELS PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21068", district:"22", Block:"274", label:"JAIRAM PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21069", district:"17", Block:"254", label:"THE CHILDPLAN ACADEMY (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"21070", district:"17", Block:"267", label:"VETRI VIKAAS PUBLIC SCHOOL (SENIOR SECONDARY),RASIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21071", district:"17", Block:"360", label:"THE MERIDIAN INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21072", district:"8", Block:"45", label:"SHEMFORD FUTURISTIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21073", district:"32", Block:"57", label:"AUROBINDO VIDHYALAYA CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21074", district:"32", Block:"57", label:"GREENFIELD INTERNATIONAL SCHOOL CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21075", district:"8", Block:"71", label:"BHARATHI VIDHYALAYA SCHOOL ,GOBI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21076", district:"8", Block:"71", label:"M.R.S. PUBLIC SCHOOL, KOLAPPALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21077", district:"32", Block:"392", label:"SATHYAM INTERNATIONAL SCHOOL(CBSE), S G VALASU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21078", district:"4", Block:"156", label:"GEDEE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21079", district:"4", Block:"245", label:"RATHINAM INTERNATIONAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21080", district:"4", Block:"279", label:"ALCHEMY PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21081", district:"4", Block:"279", label:"DOBBS PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21082", district:"4", Block:"279", label:"THE NGP SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21083", district:"4", Block:"247", label:"ARB INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21084", district:"32", Block:"371", label:"SRINIVASA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21085", district:"32", Block:"32", label:"VIVEGAA SCHOOL(CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"21086", district:"32", Block:"32", label:"SPRING MOUNT PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21087", district:"4", Block:"302", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21088", district:"32", Block:"363", label:"KIDS CLUB SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21089", district:"7", Block:"59", label:"PANNAI PUBLIC SCHOOL(CBSE), MULLIPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21090", district:"7", Block:"60", label:"ACHYUTA ACADEMY CBSE SCHOOL, DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21091", district:"29", Block:"171", label:"SAS VIDYALAYA (CBSE), DHEERAN NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21092", district:"29", Block:"168", label:"SOWMA PUBLIC SCHOOL(CBSE),MANAPARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21093", district:"29", Block:"176", label:"SRI HAYAAGREEVAR VIDHYALAYA (CBSE),M.KALLUPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21094", district:"5", Block:"54", label:"THE PSBB MILLENNIUM SCHOOL - PERIYAKATUPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21095", district:"5", Block:"406", label:"JAYAPRIYA VIDYALAYA SENIOR SECONDARY SCHOOL  CBSE, VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21096", district:"5", Block:"406", label:"SRI VIDHYA MANDHIR MATRICULATION SCHOOL,  VRIDDHACHALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21097", district:"5", Block:"199", label:"PRIME INTERNATIONAL SCHOOL VEPPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21098", district:"5", Block:"120", label:"SHRI SAI VIDHYASHARAM CBSE SCHOOL, KANNANGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21099", district:"5", Block:"143", label:"SHEMFORD FUTURISTIC CBSE -C.THANDEWARNALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21100", district:"5", Block:"115", label:"SBG CBSE VIDYALAYA-THENPATHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21101", district:"25", Block:"313", label:"PONNAIYAH  RAMAJAYAM PUBLIC SCHOOL-SOORAKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21102", district:"25", Block:"144", label:"ARR SL VIDYASHRAAM  SENIOR SECONDARY SCHOOL -KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21103", district:"25", Block:"234", label:"BRILLIANT -CBSE-PATTUKKOTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21104", district:"25", Block:"234", label:"BON SECOURS PUBLIC SCHOOL -CBSE-PATTUKKOTTAI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21105", district:"19", Block:"67", label:"VIDYA VIKAS INTER.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21106", district:"14", Block:"328", label:"OM SADHANA CENTRAL CBSE S, VIRATTIPATH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21107", district:"27", Block:"55", label:"JAYTECH INTERNATIONAL SENIOR SECONDARY  SCHOOL C.PUDHUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21108", district:"38", Block:"28", label:"CHANDRA NATIONAL SCHOOL, ARUPPUKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21109", district:"38", Block:"407", label:"KSHATRIYA VIDHYASALA ENGLISH MEDIUM SCHOOL , KOORAIKUNDU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21110", district:"38", Block:"321", label:"MINERVA PUBLIC SCHOOL, MANGULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21111", district:"38", Block:"321", label:"SRI VISHWAS VIDHYALAYA SCHOOL, PANNAI MOONDRADAIPPU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21112", district:"38", Block:"264", label:"ARUMUGAM PALANIGURU MODERN SCHOOL CHATRAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21113", district:"38", Block:"264", label:"SRI RAMANA ACADEMY SENIOR SECONDARY CBSE, RAJAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21114", district:"38", Block:"298", label:"SRI MAYURA INTERNATIONAL SCH APPAIYANAYAKAR PATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21115", district:"38", Block:"295", label:"AAA INTERNATIONAL SCHOOL.,THIRUTHANGAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21116", district:"20", Block:"266", label:"AMRITA VIDYALAYAM, R.S.MADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21117", district:"20", Block:"332", label:"KANNADIVAPPA INTERNATIONAL SCHOOL, KANJIRANGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21118", district:"30", Block:"221", label:"ROSE MARY PUBLIC SCHOOL VM CHATHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21119", district:"24", Block:"276", label:"VELS PUBLIC SCHOOL VADAKKUPUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21120", district:"24", Block:"307", label:"ESAKKI VIDHYASHRAM CBSE SCHOOL TENKASI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21121", district:"30", Block:"385", label:"S.A.V BALAKRISHNA CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21122", district:"30", Block:"385", label:"KINGS  CBSE SCHOOL PUDHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21123", district:"30", Block:"385", label:"JESUS SCHOOL FOR EXCELLENCE ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21124", district:"24", Block:"388", label:"KANNA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21125", district:"11", Block:"124", label:"BETHANY NAVAJEEVAN CBSE SCHOOL-VENCODE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21126", district:"13", Block:"139", label:"VAILANKANNI PUBLIC SCHOOL KRISHNAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21127", district:"2", Block:"299", label:"Dr.C.L.Metha Sishya OMR School, Thoraipakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21128", district:"4", Block:"279", label:"SAMSKAARA ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21129", district:"14", Block:"328", label:"ST.ASSISI WORL  IGCSE SCH, THUVARIMAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"21130", district:"31", Block:"82", label:"PUMS, SANJEEVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"21131", district:"15", Block:"178", label:"PUMS PULAVANUR", Sch_type:"Government", Category:"Middle School"},
    { key:"21132", district:"15", Block:"131", label:"PUMS PERAMBUR", Sch_type:"Government", Category:"Middle School"},
    { key:"21133", district:"22", Block:"316", label:"PUMS KANGANIYUR", Sch_type:"Government", Category:"Middle School"},
    { key:"21134", district:"28", Block:"138", label:"PUMS, IDAISEVAL", Sch_type:"Government", Category:"Middle School"},
    { key:"21135", district:"13", Block:"78", label:"PUMS THORAPALLI -TELUGU", Sch_type:"Government", Category:"Middle School"},
    { key:"21136", district:"2", Block:"300", label:"Sunshine Chennai Senior SS, Puzhuthivakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21137", district:"3", Block:"2", label:"UNITY PUBLIC SCHOOL KOTTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21138", district:"37", Block:"379", label:"NEW  ERA  SECONDARY SCHOOL  KUILAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21139", district:"22", Block:"68", label:"G.E.T. EXCELLENT MATRIC HR. SEC. SCHOOL, NADUVALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21140", district:"22", Block:"61", label:"KALAIMAGAL VIDHYASHRAM MATRIC HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21141", district:"22", Block:"179", label:"SRI RAAM VIDYALAYA MATRIC HR. SEC. SCHOOL, POTTANERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21142", district:"2", Block:"300", label:"KENDRIYA VIDHYALAYA MEENAMBAKKAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"21143", district:"8", Block:"65", label:"MOUNT CARMEL INTERNATIONAL SCHOOL, KOLLAMPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21144", district:"2", Block:"300", label:"ST. DOMINICS ANGLO - INDIAN HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21145", district:"33", Block:"252", label:"SANA MODEL SCHOOL KATTUPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21146", district:"3", Block:"237", label:"RAMAKRISHNA MATRIC H.S. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21147", district:"10", Block:"146", label:"S. R. N. M. MATRICULATION HIGHER SECONDARY SCHOOL, NANDAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21148", district:"34", Block:"27", label:"VIKAS VIDHYASHARAM MATRICULATION HR SEC SCHOOL NESAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21149", district:"34", Block:"412", label:"ORANGE INTERNATIONAL MATRIC HSS - VINNAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21150", district:"37", Block:"189", label:"VIVEKANANDA VIDHYALAYA MATRICULATION HIGHER SECONDARY  SCHOOL  MANAMPOONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21151", district:"17", Block:"186", label:"THE MODERN ACADEMY MATRIC HIGHER SECONDARY SCHOOL,N.PUDUPATTI, TRICHY ROAD, NAMAKKAL -637020", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21152", district:"32", Block:"104", label:"SWAMI VIVEKANANDA MATRIC HR SEC SCHOOL, KANGEYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21153", district:"8", Block:"185", label:"GREENPARK MATRIC HIGHER SECONDARY SCHOOL, KUPPAGOUNDAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21154", district:"32", Block:"371", label:"SRI SRISTI VIKKAS ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL , PARTHASARATHYPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21155", district:"32", Block:"371", label:"SREE  ADHARSH  MATRICULATION  HR.SEC. SCHOOL, PALLAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21156", district:"4", Block:"107", label:"SHREE SARASSWATHI VIDHYAAH MANDHEER MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21157", district:"32", Block:"223", label:"THIRU JANARTHANA MATRIC HR SEC SCHOOL, KARADIVAVI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21158", district:"32", Block:"363", label:"DHANAM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21159", district:"32", Block:"363", label:"EDEN GARDENS MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21160", district:"7", Block:"219", label:"SWAMY MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21161", district:"7", Block:"219", label:"BHAARATH VIDHYA BHAVAN MAT.HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21162", district:"12", Block:"83", label:"STAR MATRICULATION HR.SEC. SCHOOL, PAVITHIRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21163", district:"29", Block:"343", label:"CHELLAMMAL MATRIC GIRLS HR SEC SCHOOL VENGUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21164", district:"29", Block:"357", label:"SOWDAMBIKAA MATRIC GIRLS HR. SEC. SCHOOL,THURAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21165", district:"18", Block:"399", label:"LABBAIKUDIKADU WISDOM MATRIC HR.SEC.SCHOOL AYANPERAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21166", district:"14", Block:"303", label:"SAMABHARATHAM MATRICULATION  HIGHER SECONDARY  SCHOOL, KILANGULAM  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21167", district:"14", Block:"283", label:"THIRUVALLUVAR MATRIC HIGHER SECONARY  SCHOOL, SOOLAPURAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21168", district:"14", Block:"374", label:"JAYASEELAN MATRIC HR SEC SCHOOL, MATHARAI, MUTHUPANDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21169", district:"27", Block:"241", label:"TMHNU.Nadar Saraswathi Girls Matriculation Higher Secondary School VADAPUDUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21170", district:"38", Block:"28", label:"ST.FRANCIS DE SALES MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21171", district:"38", Block:"295", label:"ANITHA MATRICULATION HSS ,KONGALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21172", district:"13", Block:"35", label:"GONZAGA MHSS KATHANPALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21173", district:"33", Block:"403", label:"PON VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21174", district:"33", Block:"403", label:"DEVI ACAD SENIOR SEC SCH,VALASARAVAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21175", district:"3", Block:"195", label:"ST. JOHN S SR SEC. SCHOOL & JUNIOR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21176", district:"2", Block:"299", label:"GT Aloha Vidhya Mandir", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21177", district:"2", Block:"299", label:"Sreevatsa Viswanathan Vivekananda Vidyalaya Senior Secondary School, Chitlapakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21178", district:"21", Block:"409", label:"VEDAVALLI VIDYALAYA (NPS) SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21179", district:"34", Block:"249", label:"SRI RAMAJAYAM GLOBAL (CBSE)-POLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21180", district:"34", Block:"365", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21181", district:"4", Block:"245", label:"AMRITA VIDYALAYAM CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21182", district:"4", Block:"279", label:"NATIONAL MODEL SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21183", district:"4", Block:"302", label:"PARK GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21184", district:"32", Block:"363", label:"LITTLE KINGDOM SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21185", district:"13", Block:"139", label:"NALANDA INTERNATIONAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21186", district:"13", Block:"78", label:"NALANDA INTERNATIONAL PUBLIC SCHOOL HOSUR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21187", district:"32", Block:"223", label:"THE INDIAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21188", district:"11", Block:"341", label:"EXCEL GLOBAL SCHOOL-THIRUVATTAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21189", district:"28", Block:"350", label:"PUMS, ATHIMARAPATTI", Sch_type:"Government", Category:"Middle School"},
    { key:"21190", district:"18", Block:"400", label:"GHSS MODEL SCHOOL KILUMATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21191", district:"1", Block:"26", label:"GHSS MODEL SCHOOL ASITHINAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21192", district:"28", Block:"138", label:"GHSS, ILAYARASANENDAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21193", district:"28", Block:"138", label:"GHSS, PILLAIYARNATHAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21194", district:"13", Block:"376", label:"GHSS   PAVAKKAL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21195", district:"13", Block:"398", label:"MODEL HSS VEPPANAPALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21196", district:"13", Block:"311", label:"MODEL HSS THALLY", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21197", district:"10", Block:"146", label:"GHSS KOLAPPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21198", district:"31", Block:"82", label:"GHSS KETHANDAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21199", district:"6", Block:"218", label:"MODEL SCHOOL - PALACODE", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21200", district:"6", Block:"109", label:"MODEL SCHOOL KARIMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21201", district:"34", Block:"80", label:"Govt Model Higher Sec school perungattur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21202", district:"37", Block:"342", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL EMAPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21203", district:"9", Block:"277", label:"MODEL HIGHER SECONDARY SCHOOL MOOLAKKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21204", district:"9", Block:"372", label:"MODEL SCHOOL - GOVERNMENT HIGHER SECONDARY SCHOOL  A.KUMARAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21205", district:"9", Block:"327", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL VELUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21206", district:"22", Block:"246", label:"MODEL SCHOOL , PETHANAIKANPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21207", district:"22", Block:"163", label:"MODEL SCHOOL, MAGUDANCHAVADI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21208", district:"22", Block:"226", label:"MODEL SCHOOL , PANAMARATHUPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21209", district:"22", Block:"391", label:"MODEL SCHOOL, VEERAPANDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21210", district:"22", Block:"316", label:"MODEL SCHOOL, THARAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21211", district:"22", Block:"203", label:"MODEL SCHOOL, NANGAVALLI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21212", district:"22", Block:"129", label:"MODEL SCHOOL, KOLATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21213", district:"22", Block:"278", label:"MODEL SCHOOL , SANKARI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21214", district:"22", Block:"413", label:"MODEL SCHOOL, YERCAUD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21215", district:"17", Block:"165", label:"MODEL SCHOOL, RAMAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21216", district:"8", Block:"20", label:"GMHSS,SELLAMPALAYAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21217", district:"32", Block:"145", label:"GMHSS, KUNDADAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21218", district:"8", Block:"281", label:"GOVT. MODEL HIGHER SECONDARY SCHOOL, SATHYAMANGALAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21219", district:"8", Block:"310", label:"GMHSS, MALLANGULI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21220", district:"26", Block:"135", label:"GOVERNMENT HIGHER SECONDARY SCHOOL,  ELADA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21221", district:"5", Block:"170", label:"MANGALORE MODEL SCHOOL, M.PODAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21222", district:"35", Block:"209", label:"GHSS,EDAMELAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21223", district:"35", Block:"137", label:"GHSS, RADHANARASIMMAPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21224", district:"30", Block:"204", label:"ADW GHSS KADAMBANKULAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21225", district:"35", Block:"173", label:"AHSS FINDLAY ,MANNARGUDI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"21226", district:"28", Block:"138", label:"SHYAMALA H.S.S.,ILUPPIYURANI", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"21227", district:"37", Block:"404", label:"VRP HIGHER SECONDARY SCHOOL VILLUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21228", district:"32", Block:"57", label:"VEVEAHAM HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21229", district:"17", Block:"397", label:"VIDHYA NIKETHAN INTEL MATRIC HIGHER SECONDARY SCHOOL,THENGALPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21230", district:"17", Block:"232", label:"SKV GIRLS MATRIC HR SEC SCHOOL, M. KANDAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21231", district:"30", Block:"204", label:"REACH MHSS MOOLAIKARAIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21232", district:"35", Block:"173", label:"MHSS CO_OPERATIVE URBAN,MANNARGUDI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21233", district:"30", Block:"222", label:"ST ANNE S REHABILITATION CENTRE FOR THE MENTALLY HANDICAPPED", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21234", district:"2", Block:"299", label:"Sri Kanchi Mahaswami Vidya Mandir, Sembakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21235", district:"33", Block:"252", label:"THE PUPIL SAVEETHA ECO SCHOOL SENNEERKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21236", district:"10", Block:"101", label:"Royal Matriculation Higher Secondary School Kanchipuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21237", district:"2", Block:"114", label:"GKM VIDYASHRAM SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21238", district:"2", Block:"322", label:"PATHASHAALA PALAR CENTRE FOR LEARNING KFI, VALLIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21239", district:"36", Block:"74", label:"Aadithya Vidhyashram Matric School", Sch_type:"Un-aided", Category:"High School"},
    { key:"21240", district:"6", Block:"109", label:"SHREE B.C.R. MHSS, KARIMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21241", district:"22", Block:"68", label:"KAMARAJAR MATRIC HR. SEC. SCHOOL, THEDAVOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21242", district:"22", Block:"246", label:"ANNAI MATRICULATION HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21243", district:"22", Block:"246", label:"PRAPAVATHY MATRIC HIGHER SECONDARY SCHOOL, KARUMANDURAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21244", district:"22", Block:"226", label:"SPS MATRIC HR. SEC. SCHOOL, NEIKKARAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21245", district:"32", Block:"32", label:"SRI SHAKTHI INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21246", district:"32", Block:"363", label:"VIDHYASAGAR INTERNATIONAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21247", district:"29", Block:"366", label:"CARMELS ENGLISH SCHOOL,RAMALINGANAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21248", district:"1", Block:"81", label:"ST.ANDREW S MATRIC SCHOOL-JAYANKONDAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21249", district:"5", Block:"54", label:"AURO CHILD INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL -VARAKALPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21250", district:"13", Block:"78", label:"ST.JOSEPH'S ACADEMY, HOSUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21251", district:"33", Block:"403", label:"OCF CENTRAL GOVERMENT SCHOOL", Sch_type:"Central Govt", Category:"High School"},
    { key:"21252", district:"8", Block:"281", label:"SR NACHIMUTHU GOUNDER MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21253", district:"4", Block:"242", label:"SAI VIDHYA NIKETHAN MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21254", district:"33", Block:"252", label:"KARTHIKEYAN MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21255", district:"33", Block:"403", label:"SRI DEVI MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21256", district:"2", Block:"299", label:"Mount Carmel Mat. School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21257", district:"36", Block:"12", label:"BHARATH MATRIC HSS, ODUGATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21258", district:"36", Block:"12", label:"SRI VIVEKANANDA MATRIC HSS, ANAICUT", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21259", district:"31", Block:"82", label:"PEACE GARDEN MATRIC HSS, YELAGIRI HILLS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21260", district:"6", Block:"187", label:"SRI RAM MHSS, BOOMISAMUTHIRAM, KAMBAINALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21261", district:"6", Block:"230", label:"VAAGAI VIDHYALAYA MHSS, H.PUDUPATTI, PAPPIREDDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21262", district:"6", Block:"77", label:"SRI VIJAYYA VIDHYALAYA MHSS, H.DHODDAMPATTI, HARUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21263", district:"34", Block:"318", label:"ARAVIND INTERNATIONAL MATRICULATION SCHOOL - THELLAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21264", district:"34", Block:"27", label:"SRI BHARATHI VIDHYASHRAM MATRIC HR.SEC. SCHOOL, AGRAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21265", district:"34", Block:"125", label:"SAKTHI MATRIC-KUNNANKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21266", district:"17", Block:"254", label:"SRI GURUKULAM MATRICULATION SCHOOL, REDDIPUDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21267", district:"17", Block:"200", label:"GREEN WORLD EXCEL SCHOOL MOOLAPALLIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21268", district:"17", Block:"224", label:"SRK MATRICULATION SCHOOL, PALLAKKAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21269", district:"32", Block:"145", label:"MERIT MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21270", district:"8", Block:"310", label:"KCT MATRICULATION SCHOOL, RAMAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21271", district:"8", Block:"356", label:"KONGU VIDYALAYA MATRICULATION,VARAPPALLAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21272", district:"4", Block:"248", label:"SHRI VINAYAGAR MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21273", district:"32", Block:"371", label:"RKR GNANODHAYA MATRIC HSS SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21274", district:"32", Block:"32", label:"SRI NACHAMMAL VIDYAVANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21275", district:"32", Block:"223", label:"RAJA NATIONAL MATRIC HR SEC SCHOOL, PALLADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21276", district:"32", Block:"363", label:"MARUTHI EXCEL MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21277", district:"32", Block:"363", label:"MANI PUBLIC ACADEMY MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21278", district:"7", Block:"219", label:"ST.PAUL S MATRICULATION.SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21279", district:"29", Block:"176", label:"S.R VIDHYALAYA MATRICULATION HR. SEC. SCHOOL, AYYANARPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21280", district:"29", Block:"353", label:"SREE SARRADHA MATRICULATION HS SCHOOL, KOLATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21281", district:"5", Block:"170", label:"SATHYA SAAI MATRICULATION HIGHER SECONDARY SCHOOL.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21282", district:"5", Block:"143", label:"SRI VENKATESWARA MATRICULATION SCHOOL, SIVAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21283", district:"5", Block:"143", label:"THE MATRICULATION HIGHER SECONDARY SCHOOL, CHIDAMBARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21284", district:"16", Block:"389", label:"RVS BHARATH MATRICULATION HIGHER SECONDARY SCHOOL AYAKARANPULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21285", district:"14", Block:"136", label:"ONE TO THRE WONDER CONVENT MATRICULATION SCHOOL, VALAISERIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21286", district:"14", Block:"183", label:"R.V.  MATRICULATION HIGHER SECONDARY SCHOOL, MELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21287", district:"14", Block:"160", label:"SRI VANI VIDHYALAYA MATRIC HR SEC SCHOOL, SUBRAMANIYAPURAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21288", district:"27", Block:"319", label:"AANDAL ALAGAR MATRIC HR. SEC. SCHOOL - VAYALPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21289", district:"30", Block:"46", label:"St.JOHN'S MATRICULATION HR.SEC.SCHOOL VEERAVANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21290", district:"24", Block:"119", label:"S.R MATRIC HIGHER SECONDARYSCHOOL SURANDAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21291", district:"11", Block:"149", label:"ARUNACHALA MATRIC HIGHER SECONDARY SCHOOL, KANAKAMMAL GARDENS, KATTUVILAI, VELLICHANTHAI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21292", district:"13", Block:"139", label:"RIMS MATRIC HSS", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21293", district:"13", Block:"78", label:"SWAMY  VIVEKANANDA MATRIC HSS, HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21294", district:"33", Block:"253", label:"SHREE NIKETAN PATASALA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21295", district:"33", Block:"260", label:"GODSON PUBLIC SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21296", district:"33", Block:"260", label:"VRUKSHA VIDYAASHRAM SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21297", district:"10", Block:"377", label:"MEENAKSHI AMMAL GLOBAL SCHOOL PERUNGOZHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21298", district:"10", Block:"296", label:"Maharashi Vidha Mandir School, Irunkattukottai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21299", district:"2", Block:"299", label:"THE PSBB MILLENNIUM SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21300", district:"31", Block:"82", label:"SHEMFORD FUTURISTIC SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21301", district:"6", Block:"58", label:"DAWN SHIKSHALAYA PUBLIC SCHOOL, DHARMAPURI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21302", district:"6", Block:"58", label:"THE VIJAY MILLENNIUM SCHOOL, SOGATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21303", district:"6", Block:"58", label:"KAMALAM INTERNATIONAL SCHOOL, K. NADUHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21304", district:"6", Block:"77", label:"INDIAN PUBLIC SCHOOL , GOBINATHAMPATTI KOOT ROAD, PARAYAPATTI PUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21305", district:"34", Block:"365", label:"VIGNESH INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21306", district:"37", Block:"384", label:"RAJADESING PUBLIC SCHOOL KALAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21307", district:"22", Block:"133", label:"APPU ARIVAALAYEM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21308", district:"17", Block:"165", label:"MAHENDRA INTERNATIONAL SCHOOL, KALIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21309", district:"32", Block:"104", label:"KOVAI VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21310", district:"32", Block:"378", label:"VEDANTA ACADEMY,UTHUKULI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21311", district:"32", Block:"378", label:"SRI KUMARAN PUBLIC SENIOR SECONDARY SCHOOL, SENGAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21312", district:"4", Block:"156", label:"AMRITA VIDYALAYAM SENIOR SECONDARY SCHOOL, ETTIMADAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21313", district:"4", Block:"245", label:"ORISON ACADEMY SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21314", district:"4", Block:"279", label:"SRI JAYENDRA SARASWATHY VIDYALAYA   SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21315", district:"4", Block:"126", label:"VIVEK VIDYA MANDIR (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"21316", district:"4", Block:"19", label:"K.G.INTERNATIONALSCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21317", district:"32", Block:"32", label:"THE EARNEST ACADEMY,PAZHANKARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21318", district:"4", Block:"107", label:"VIDYAA VIKAS INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21319", district:"32", Block:"363", label:"SMART MODERN SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21320", district:"32", Block:"363", label:"FRONTLINE MILLENNIUM SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21321", district:"32", Block:"363", label:" A.V.P. TRUST PUBLIC SENIOR SECONDARY SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21322", district:"7", Block:"29", label:"LUXOR WORLD SCHOOL, CHINNALAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21323", district:"7", Block:"268", label:"VIVEKANANDAR VIDHYA MANDIR, SULLERUMBU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21324", district:"29", Block:"15", label:"VIVEKANANDA VIDYALAYA CBSE,THIRUPPARAITHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21325", district:"29", Block:"171", label:"MOUNT LITERA ZEE HSS (CBSE), UYYAKONDAN THIRUMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21326", district:"29", Block:"366", label:"SANTHANAM VIDHYALAYA (CBSE) SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21327", district:"29", Block:"343", label:"CHELLAMMAL CBSE SCHOOL VENGUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21328", district:"29", Block:"357", label:"SRM PUBLIC SCHOOL(CBSE),THURAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21329", district:"5", Block:"211", label:"KENDRIYA VIDYALAYA HIGHER SECONDARY SCHOOL(CBSE)  BLOCK - 3 NEYVELI-TS", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"21330", district:"25", Block:"234", label:"LAURAL SENIOR SEC. SCHOOL-PALLIKONDAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21331", district:"19", Block:"326", label:"MOUNTZION.INT.LENA VILAKKU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21332", district:"23", Block:"294", label:"NEHRUJI CENTRAL HIGHER SECONDARY SCHOOL CBSE. IDAYAMELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21333", district:"23", Block:"56", label:"BHAARATH PUBLIC CBSE SCHOOL, AARAVAYAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21334", district:"23", Block:"272", label:"THE LEADERS ACADEMY KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21335", district:"23", Block:"96", label:"CHETTINAD PUBLIC SCHOOL CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21336", district:"14", Block:"183", label:"SRI LAKSHMI NARAYANA VID CBSE S, A.VALAYAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21337", district:"14", Block:"328", label:"VELAMMAL CBSE.H.S VIRAGANOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21338", district:"27", Block:"319", label:"THENI KAMMAVAR SANGAM PUBLIC SCHOOL - KODUVILARPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21339", district:"38", Block:"407", label:"RAMCO VIDYALAYA, R.R.NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21340", district:"38", Block:"298", label:"MAHARISHI VIDYA MANDIR SECONDARY SCHOOL - CBSE, SRIVILLIPUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21341", district:"38", Block:"298", label:"SATHYA VIDYALAYA CBSE,PILLAYARKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21342", district:"38", Block:"282", label:"SRIKARA VIDHYA MANDHIR SENIOR SECONDARY SCHOOL, SIVANANAINTHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21343", district:"24", Block:"388", label:"DHARANI INTERNATIONAL SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21344", district:"30", Block:"222", label:"FRANCIS XAVIER SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21345", district:"13", Block:"376", label:"DHEERAN CHINNAMALAI INTERNATIONAL RESIDENTIAL SCHOOL, UTHANGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21346", district:"13", Block:"139", label:"CAMBRIDGE PUBLIC e-SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21347", district:"13", Block:"139", label:"BHARAT INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21348", district:"13", Block:"78", label:"SRI GURUKULAM SENIOR SECONDARY SCHOOL, HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21349", district:"13", Block:"78", label:"LITERA VALLEY ZEE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21350", district:"3", Block:"240", label:"SEVENTH DAY ADVENTIST HR. SEC SCHOOL (ICSE & ISE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"21351", district:"3", Block:"62", label:"DOVETON GIRLS HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21352", district:"3", Block:"62", label:"DOVETON BOYS HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21353", district:"22", Block:"413", label:"MONTFORT SCHOOL (ICSE), YERCAUD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21354", district:"4", Block:"52", label:"STANES ICSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21355", district:"12", Block:"83", label:"THE INDIAN PUBLIC SCHOOL, POOLAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21356", district:"29", Block:"367", label:"SEVENTH DAY ADVENTIST (ICSE)- BEEMANAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21357", district:"14", Block:"159", label:"SEVENTH DAY ADV.H.S  ICSE S,KENNET ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21358", district:"4", Block:"156", label:"GHS MYLERIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"21359", district:"4", Block:"245", label:"GHS SUGUNAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21360", district:"33", Block:"338", label:"GOVT. GIRLS HS VELLIYUR", Sch_type:"Government", Category:"High School"},
    { key:"21361", district:"4", Block:"248", label:"GHS MAKKINAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21362", district:"32", Block:"363", label:"GHS BOMMANAICKENPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"21363", district:"32", Block:"363", label:"MHS KUMARANANDAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21364", district:"33", Block:"87", label:"GHS PAPPARAMPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"21365", district:"7", Block:"75", label:"GHS,R.PUDUKKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"21366", district:"7", Block:"207", label:"GHS, KOTTAIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"21367", district:"7", Block:"207", label:"GHS, ULUPPAGUDI", Sch_type:"Government", Category:"High School"},
    { key:"21368", district:"7", Block:"207", label:"GHS, PARALIPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"21369", district:"7", Block:"352", label:"GHS, MELKARAIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"21370", district:"12", Block:"112", label:"GOVERNMENT HIGH SCHOOL, SOMUR", Sch_type:"Government", Category:"High School"},
    { key:"21371", district:"12", Block:"348", label:"GGHS,RACHANDAR THIRUMALAI", Sch_type:"Government", Category:"High School"},
    { key:"21372", district:"29", Block:"15", label:"KAMATCHI MATRICULATION SCHOOL, POTHAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21373", district:"29", Block:"15", label:"GHS,POSAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"21374", district:"29", Block:"171", label:"GHS (GIRLS), INAMKULATHUR", Sch_type:"Government", Category:"High School"},
    { key:"21375", district:"29", Block:"168", label:"GHS,KARUMPULIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"21376", district:"29", Block:"168", label:"GHS,PUTHANATHAM(GIRLS)", Sch_type:"Government", Category:"High School"},
    { key:"21377", district:"29", Block:"317", label:"GOVT HIGH SCHOOL,KARIGALI", Sch_type:"Government", Category:"High School"},
    { key:"21378", district:"29", Block:"317", label:"GHS,VELAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21379", district:"29", Block:"381", label:"GHS,ARASUNILAIPALAYAM(GIRLS)", Sch_type:"Government", Category:"High School"},
    { key:"21380", district:"18", Block:"400", label:"GHS NANNAI", Sch_type:"Government", Category:"High School"},
    { key:"21381", district:"18", Block:"236", label:"GHS VADAKKUMADEVI", Sch_type:"Government", Category:"High School"},
    { key:"21382", district:"1", Block:"286", label:"GHS NAMAGUNAM", Sch_type:"Government", Category:"High School"},
    { key:"21383", district:"1", Block:"26", label:"GHS THELUR", Sch_type:"Government", Category:"High School"},
    { key:"21384", district:"1", Block:"81", label:"GHS KOLLAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21385", district:"5", Block:"148", label:"GHS KUNDIYAMALLUR", Sch_type:"Government", Category:"High School"},
    { key:"21386", district:"5", Block:"199", label:"GGHS-NALLUR", Sch_type:"Government", Category:"High School"},
    { key:"21387", district:"5", Block:"143", label:"GOVERNMENT GIRLS HIGH SCHOOL-LALPET", Sch_type:"Government", Category:"High School"},
    { key:"21388", district:"16", Block:"196", label:"GHS KALASAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"21389", district:"16", Block:"325", label:"GHS PANANGUDI SAMATHUVAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21390", district:"16", Block:"121", label:"GHS THIRUPOONDI NORTH", Sch_type:"Government", Category:"High School"},
    { key:"21391", district:"16", Block:"121", label:"GHS VILUNTHAMAVADI (E)", Sch_type:"Government", Category:"High School"},
    { key:"21392", district:"33", Block:"289", label:"GHS,ORAKKADU", Sch_type:"Government", Category:"High School"},
    { key:"21393", district:"15", Block:"284", label:"GHS PERUMALPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"21394", district:"35", Block:"382", label:"GHS ENAMKILIUR", Sch_type:"Government", Category:"High School"},
    { key:"21395", district:"35", Block:"382", label:"GHS SITHANVALUR", Sch_type:"Government", Category:"High School"},
    { key:"21396", district:"23", Block:"294", label:"GHS MUDIKANDAM", Sch_type:"Government", Category:"High School"},
    { key:"21397", district:"33", Block:"403", label:"GOVT GIRLS HIGHER SECONDARY SCHOOL, AYAPPAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21398", district:"33", Block:"403", label:"GHS , KARLAPAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"21399", district:"23", Block:"272", label:"GHS, POYYAVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"21400", district:"14", Block:"97", label:"GOVT HIGH SCHOOL, VADAKKAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21401", district:"14", Block:"158", label:"GOVT HIGH SCHOOL, RAJAKKOOR", Sch_type:"Government", Category:"High School"},
    { key:"21402", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, THOPPUR", Sch_type:"Government", Category:"High School"},
    { key:"21403", district:"27", Block:"40", label:"GOVT HS -  RASINGAPURAM  ", Sch_type:"Government", Category:"High School"},
    { key:"21404", district:"38", Block:"411", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KOOMAPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21405", district:"38", Block:"295", label:"G.H.S.,M.PUDHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21406", district:"38", Block:"295", label:"G.H.S.,KOPPAIYANAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21407", district:"38", Block:"396", label:"G.H.S.,MUTHANDIAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21408", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, N.G.O.COLONY", Sch_type:"Government", Category:"High School"},
    { key:"21409", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, K.METTUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21410", district:"20", Block:"335", label:"GOVERNMENT HIGH SCHOOL,  CHOLAGANPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"21411", district:"28", Block:"280", label:"GHS PANNAMPARAI", Sch_type:"Government", Category:"High School"},
    { key:"21412", district:"28", Block:"350", label:"GHS POTTALKADU", Sch_type:"Government", Category:"High School"},
    { key:"21413", district:"24", Block:"6", label:"GHS ,PALAPATHIRARAMAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21414", district:"30", Block:"174", label:"G(G) HS GANGAIKONDAN", Sch_type:"Government", Category:"High School"},
    { key:"21415", district:"30", Block:"174", label:"G(G)HS  MANUR", Sch_type:"Government", Category:"High School"},
    { key:"21416", district:"30", Block:"229", label:"G(G) HS NADUKKALLUR", Sch_type:"Government", Category:"High School"},
    { key:"21417", district:"24", Block:"180", label:"GOVERNMENT HIGH SCHOOL, PANDIYAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21418", district:"24", Block:"180", label:"GOVERNMENT HIGH SCHOOL, VENDRILINGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21419", district:"13", Block:"376", label:"GHS M.VELLALAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21420", district:"13", Block:"139", label:"GHS KATTIGANAPALLI ", Sch_type:"Government", Category:"High School"},
    { key:"21421", district:"13", Block:"35", label:"GHS GUTTUR", Sch_type:"Government", Category:"High School"},
    { key:"21422", district:"13", Block:"291", label:"GHS BERIGAI - URDU", Sch_type:"Government", Category:"High School"},
    { key:"21423", district:"13", Block:"311", label:"GGHS PEDDABELAGONDAPALLI - GIRLS", Sch_type:"Government", Category:"High School"},
    { key:"21424", district:"33", Block:"261", label:"Govt Girls High school,Vanganoor", Sch_type:"Government", Category:"High School"},
    { key:"21425", district:"10", Block:"101", label:"GHS, Thammanur", Sch_type:"Government", Category:"High School"},
    { key:"21426", district:"10", Block:"146", label:"GHS PATTUR MUSLIM", Sch_type:"Government", Category:"High School"},
    { key:"21427", district:"2", Block:"329", label:"GHS(G), Kelambakkam", Sch_type:"Government", Category:"High School"},
    { key:"21428", district:"2", Block:"322", label:"GHS SALUR", Sch_type:"Government", Category:"High School"},
    { key:"21429", district:"2", Block:"153", label:"GHS ,Neelamangalam", Sch_type:"Government", Category:"High School"},
    { key:"21430", district:"2", Block:"299", label:"GHS, Perumbakkam", Sch_type:"Government", Category:"High School"},
    { key:"21431", district:"36", Block:"393", label:"GHS,  PALAMATHI", Sch_type:"Government", Category:"High School"},
    { key:"21432", district:"36", Block:"105", label:"GGHS KILARASAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"21433", district:"36", Block:"74", label:"GHS GANDHI NAGAR, GUDIYATTAM", Sch_type:"Government", Category:"High School"},
    { key:"21434", district:"31", Block:"5", label:"GHS KANAVAIPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"21435", district:"31", Block:"5", label:"GGHS NIMMIYAMPATTU", Sch_type:"Government", Category:"High School"},
    { key:"21436", district:"31", Block:"208", label:"GHS THAGARAKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"21437", district:"31", Block:"208", label:"GGHS PATCHUR", Sch_type:"Government", Category:"High School"},
    { key:"21438", district:"31", Block:"82", label:"GHS, NEKKUNDHI", Sch_type:"Government", Category:"High School"},
    { key:"21439", district:"31", Block:"82", label:"GHS ASHOK NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"21440", district:"31", Block:"362", label:"GOVT HS NONDI MARIYAMMAN KOVIL", Sch_type:"Government", Category:"High School"},
    { key:"21441", district:"31", Block:"103", label:"GHS ADIYUR", Sch_type:"Government", Category:"High School"},
    { key:"21442", district:"6", Block:"198", label:"GHS B.AGRAHARAM GIRLS", Sch_type:"Government", Category:"High School"},
    { key:"21443", district:"6", Block:"198", label:"GHS INDUR GIRLS", Sch_type:"Government", Category:"High School"},
    { key:"21444", district:"6", Block:"198", label:"GHS BALAJANGAMANAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"21445", district:"6", Block:"109", label:"GHSS PERIYAMPATTI - GIRLS", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21446", district:"6", Block:"187", label:"GHS MUTHANOOR", Sch_type:"Government", Category:"High School"},
    { key:"21447", district:"6", Block:"230", label:"GHS B.THURINJIPATTI GIRLS", Sch_type:"Government", Category:"High School"},
    { key:"21448", district:"34", Block:"48", label:"GHS PARASUR", Sch_type:"Government", Category:"High School"},
    { key:"21449", district:"34", Block:"14", label:"GHS ELANEERKUNDRAM", Sch_type:"Government", Category:"High School"},
    { key:"21450", district:"34", Block:"387", label:"GHS-ELANGADU", Sch_type:"Government", Category:"High School"},
    { key:"21451", district:"34", Block:"44", label:"GHSS-ANDIPATTI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21452", district:"37", Block:"175", label:"GOVERNMENT HIGH SCHOOL MUNNUR", Sch_type:"Government", Category:"High School"},
    { key:"21453", district:"9", Block:"277", label:"GOVERNMENT HIGH SCHOOL URANGANI", Sch_type:"Government", Category:"High School"},
    { key:"21454", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL VANIYANDAL", Sch_type:"Government", Category:"High School"},
    { key:"21455", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL ANAIKARAIKOTTALAM", Sch_type:"Government", Category:"High School"},
    { key:"21456", district:"9", Block:"372", label:"GOVERNMENT HIGH SCHOOL SIKKADU", Sch_type:"Government", Category:"High School"},
    { key:"21457", district:"22", Block:"246", label:"GHS UMAYALPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21458", district:"22", Block:"226", label:"GHS NILAVARAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21459", district:"22", Block:"391", label:"GHS,KEERAIPAPPAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"21460", district:"22", Block:"215", label:"GHS KOTTA GOUNDAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21461", district:"17", Block:"132", label:"GHS SELURNADU", Sch_type:"Government", Category:"High School"},
    { key:"21462", district:"17", Block:"200", label:"GHS SINGILIYANCOMBAI", Sch_type:"Government", Category:"High School"},
    { key:"21463", district:"32", Block:"57", label:"GHS,CHINNAKKAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"21464", district:"32", Block:"57", label:"GHS,MANAKKADAVU", Sch_type:"Government", Category:"High School"},
    { key:"21465", district:"32", Block:"57", label:"GHS, DHASARPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21466", district:"32", Block:"57", label:"GHS, POTTIKKAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"21467", district:"8", Block:"185", label:"GHS, KANAGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21468", district:"8", Block:"244", label:"GHS, SINGANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"21469", district:"8", Block:"244", label:"GHS, NITCHAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"21470", district:"26", Block:"370", label:"GHS SHOLUR KOTHAGIRI                                                   ", Sch_type:"Government", Category:"High School"},
    { key:"21471", district:"5", Block:"170", label:"GOVERNMENT HIGH SCHOOL ,  RAMANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"21472", district:"35", Block:"173", label:"GHS,MOOVANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"21473", district:"35", Block:"193", label:"GHS, PANDI", Sch_type:"Government", Category:"High School"},
    { key:"21474", district:"35", Block:"193", label:"GHS THONDIAKADU", Sch_type:"Government", Category:"High School"},
    { key:"21475", district:"25", Block:"216", label:"GHS - 34 KOVILUR", Sch_type:"Government", Category:"High School"},
    { key:"21476", district:"25", Block:"345", label:"GHS - KARIYAVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"21477", district:"25", Block:"345", label:"GHS - UNIJI VIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"21478", district:"25", Block:"239", label:"GHS - SORNAKKADU", Sch_type:"Government", Category:"High School"},
    { key:"21479", district:"19", Block:"18", label:"GHS PULVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"21480", district:"19", Block:"18", label:"GOVT HIGH SCHOOL PERUMANADU", Sch_type:"Government", Category:"High School"},
    { key:"21481", district:"19", Block:"67", label:"GHS-THUVAR", Sch_type:"Government", Category:"High School"},
    { key:"21482", district:"19", Block:"67", label:"GHS PALLAVARAYANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21483", district:"19", Block:"22", label:"GOVT GIRLS HIGH SCHOOL SILATTUR", Sch_type:"Government", Category:"High School"},
    { key:"21484", district:"33", Block:"338", label:"ADW HS PUNGATHUR", Sch_type:"Government", Category:"High School"},
    { key:"21485", district:"7", Block:"212", label:"GOVT KALLAR HIGH SCHOOL E AVARAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21486", district:"5", Block:"39", label:"GOVT(ADW)HR SEC SCHOOL,VANDURAYANPATTU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21487", district:"14", Block:"324", label:"GOVT KALLAR HIGH SCHOOL, KINNIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"21488", district:"14", Block:"374", label:"GOVT KALLAR HIGH SCHOOL, VADUGAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21489", district:"14", Block:"374", label:"GOVT KALLAR HIGH SCHOOL, MEIKILARPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21490", district:"3", Block:"259", label:"ADW HIGH SCHOOL THIRUMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"21491", district:"3", Block:"259", label:"ADW VIRUGAMBAKKAM HIGH SCHOOL", Sch_type:"Government", Category:"High School"},
    { key:"21492", district:"2", Block:"329", label:"ADWHS, Pudupakkam", Sch_type:"Government", Category:"High School"},
    { key:"21493", district:"36", Block:"74", label:"ADW HS R.VENKATAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"21494", district:"31", Block:"362", label:"ADWHS JADAYANUR", Sch_type:"Government", Category:"High School"},
    { key:"21495", district:"37", Block:"379", label:"ADI DRAVIDAR WELFARE HIGH SCHOOL POMBUR", Sch_type:"Government", Category:"High School"},
    { key:"21496", district:"22", Block:"246", label:"GTR HIGH SCHOOL, PAGUDUPATTU", Sch_type:"Government", Category:"High School"},
    { key:"21497", district:"22", Block:"30", label:"ADWHS, KOTHAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"21498", district:"22", Block:"129", label:"GTR HIGH SCHOOL, RAMANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21499", district:"22", Block:"383", label:"G.T.R. HIGH SCHOOL, PERIYAKUTTIMADUVU", Sch_type:"Government", Category:"High School"},
    { key:"21500", district:"22", Block:"413", label:"GTR HIGH SCHOOL, SEMMANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"21501", district:"4", Block:"19", label:"GOVT ADIDRAVIDA WELFARE HIGH SCHOOL, PASUR", Sch_type:"Government", Category:"High School"},
    { key:"21502", district:"7", Block:"36", label:"GOVT.ADWHS, V.KURUMBAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21503", district:"12", Block:"140", label:"ADWHS, NANTHANKOTAI", Sch_type:"Government", Category:"High School"},
    { key:"21504", district:"29", Block:"15", label:"ADW HS,KONDAYAMPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"21505", district:"29", Block:"15", label:"ADWHS,S.PUDUKOTTAI", Sch_type:"Government", Category:"High School"},
    { key:"21506", district:"29", Block:"152", label:"ADWHS,MELAVALADI", Sch_type:"Government", Category:"High School"},
    { key:"21507", district:"29", Block:"172", label:"GOVT ADWHS ,VENGANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"21508", district:"29", Block:"168", label:"GADWHS,SEVALUR", Sch_type:"Government", Category:"High School"},
    { key:"21509", district:"29", Block:"176", label:"ADWHS,PALAYAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"21510", district:"29", Block:"192", label:"GADWHS PERUR", Sch_type:"Government", Category:"High School"},
    { key:"21511", district:"29", Block:"353", label:"ADWHS,ARIYANAMPETTAI", Sch_type:"Government", Category:"High School"},
    { key:"21512", district:"29", Block:"353", label:"GOVT. ADW HS,M.PUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"21513", district:"18", Block:"400", label:"ADWHS OGALUR", Sch_type:"Government", Category:"High School"},
    { key:"21514", district:"18", Block:"236", label:"ADWHS BOMMANAPADI", Sch_type:"Government", Category:"High School"},
    { key:"21515", district:"18", Block:"236", label:"ADWHS EACHAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"21516", district:"18", Block:"7", label:"ADWHS PADALUR", Sch_type:"Government", Category:"High School"},
    { key:"21517", district:"1", Block:"16", label:"ADWHS VILANTHAI", Sch_type:"Government", Category:"High School"},
    { key:"21518", district:"5", Block:"98", label:"ADWGHS-U.MANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"21519", district:"5", Block:"143", label:"ADWHS M.KOLAKKUDI", Sch_type:"Government", Category:"High School"},
    { key:"21520", district:"16", Block:"196", label:"ADW HS AZHINJAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"21521", district:"20", Block:"265", label:"GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, VADAVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"21522", district:"20", Block:"332", label:"GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, PAZHANCHIRAI ", Sch_type:"Government", Category:"High School"},
    { key:"21523", district:"3", Block:"240", label:"M P DEVADAS HIGH SCHOOL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21524", district:"15", Block:"284", label:"THIRUVENKADAM AIDED HIGH SCHOOL KEEZHAIYUR, SEMBANARKOVIL", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21525", district:"10", Block:"296", label:"RCM (Aided) HS,Chellampattidai", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21526", district:"24", Block:"119", label:"HINDU HS MUTHUKRISHNAPERI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21527", district:"29", Block:"366", label:"FATIMA AIDED HIGH SCHOOL PUTHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21528", district:"34", Block:"249", label:"ELANGO HS-KESAVAPURAM", Sch_type:"Fully Aided", Category:"High School"},
    { key:"21529", district:"7", Block:"36", label:"IQBALIA  HIGH SCHOOL, BATLAGUNDU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21530", district:"7", Block:"212", label:"NADAR HS,NILAKOTTAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21531", district:"7", Block:"60", label:"SRI SAVITHRI VIDHYASALA HS", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21532", district:"29", Block:"168", label:"ST.IGNATIUS HIGH SCHOOL,AVARAMPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21533", district:"1", Block:"305", label:"ST. MICHEALS AIDED HIGH  SCHOOL KEELAMICHAELPATTY", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21534", district:"16", Block:"123", label:"SRI KALAIMAHAL AIDED HIGH SCHOOL MANALUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21535", district:"35", Block:"382", label:"AHS NIRMALA MANAKUNDU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21536", district:"35", Block:"141", label:"AHS, PERUMPANNAIYUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21537", district:"35", Block:"340", label:"AHS RC FATHIMA , THIRUVARUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21538", district:"35", Block:"137", label:"AHS , ST ANTONY'S HIGH SCHOOL ADICHAPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21539", district:"25", Block:"144", label:"ST. XAVIER HIGH SCHOOL-KUMBAKONAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21540", district:"19", Block:"108", label:"ST.JOSEPH.HIGHER SECONDARY SCHOOL.KOTTAIKADU", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"21541", district:"28", Block:"320", label:"THIRUKKUDUMBAM   HIGH SCHOOL , ALANTHALAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21542", district:"30", Block:"92", label:"HINDU HS PANDITHANKURICHI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21543", district:"24", Block:"180", label:"ST PETER HIGH SCHOOL SENDAMARAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21544", district:"6", Block:"109", label:"SHARON HIGH SCHOOL, MATLAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21545", district:"34", Block:"365", label:"BHAGAVAN HR.SEC.SCHOOL - KILNATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21546", district:"32", Block:"32", label:"SRI RAGHAVENDRA VIDYALAYA HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21547", district:"32", Block:"250", label:"THAITAMIL HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21548", district:"12", Block:"88", label:"SRI KARUNAIRAGAVAJI VIDHAYALAYA HSS, THARAGAMPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21549", district:"1", Block:"305", label:"CHOCKALINGAM VEMBU AMMAL HIGH SCHOOL SRIPURANTHAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"21550", district:"18", Block:"400", label:"VETRI HIGH SCHOOL AGARAM SEEGOOR ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21551", district:"1", Block:"81", label:"NALLASIRIAR THIRU RAMACHANDRA HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21552", district:"1", Block:"81", label:"MRV RAJA HR SEC SCHOOL PAPPAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21553", district:"5", Block:"148", label:"HOLY CROSS HIGH SCHOOL,  NJV NAGAR  VADAKUTHU", Sch_type:"Un-aided", Category:"High School"},
    { key:"21554", district:"5", Block:"170", label:"GOKULAM HIGH SCHOOL,VENGANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21555", district:"16", Block:"389", label:"VICTORY HIGHER SECONDARY SCHOOL PANCHANATHIKULAM WEST", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21556", district:"25", Block:"228", label:"ST. ANTONY S HIGH SCHOOL- PASUPATHIKOVIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21557", district:"14", Block:"328", label:"PALLOTTI HIGHER  SECONDARY  SCHOOL, NAGAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21558", district:"19", Block:"22", label:"AMIRTHA VIDYA VIKAAS HR. SEC. SCHOOL, KOVILVAYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21559", district:"8", Block:"45", label:"GREEN GARDEN  MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21560", district:"5", Block:"115", label:"ARUNMOZHY MATRIC  HIGHER SECONDARY SCHOOL-VEERANANDAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21561", district:"14", Block:"328", label:"MALLI VIDHYALAYA HIGH SCHOOL, PERUNGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21562", district:"22", Block:"273", label:"MUNICIPAL HIGH SCHOOL, OLD SURAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"21563", district:"33", Block:"403", label:"AMHS KOVILPATHAGAI", Sch_type:"Government", Category:"High School"},
    { key:"21564", district:"23", Block:"272", label:"RAMANATHAN CHETTIAR MUNICIPAL HIGH SCHOOL  KARAIKUDI ", Sch_type:"Government", Category:"High School"},
    { key:"21565", district:"34", Block:"27", label:"MUNICIPAL HIGH SCHOOL KANNAPPAN ST POONTHOTAM ARNI", Sch_type:"Government", Category:"High School"},
    { key:"21566", district:"32", Block:"363", label:"MHSS PADMAVATHIPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21567", district:"12", Block:"112", label:"MPL KOTTAIMEDU HIGH SCHOOL, KARUR", Sch_type:"Government", Category:"High School"},
    { key:"21568", district:"16", Block:"196", label:"KUMARAN AIDED HIGH SCHOOL MANJAKOLLAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21569", district:"21", Block:"409", label:"VEDAVALLI HIGHER SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21570", district:"13", Block:"78", label:"MAHARISHI VIDYA MANDIR HSS HOSUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21571", district:"21", Block:"290", label:"VIDYA PEETAM SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21572", district:"10", Block:"410", label:"Jei Mathajee CBSE School, Injambakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"21573", district:"21", Block:"210", label:"CENTUM NURSARY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21574", district:"21", Block:"21", label:"SNV  MATRIC SCHOOL, THANDALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21575", district:"21", Block:"359", label:"DR.VGN MATRIC SCHOOL, KALAVAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21576", district:"31", Block:"82", label:"Sri Narayani Vidhyalaya matric school", Sch_type:"Un-aided", Category:"High School"},
    { key:"21577", district:"34", Block:"238", label:"SWAMY VIVEKANANDA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21578", district:"33", Block:"260", label:"KUMARAN CWSN	School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21579", district:"33", Block:"260", label:"ANBALAYA CWSN SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21580", district:"10", Block:"410", label:"Beta Public School, Injambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21581", district:"2", Block:"329", label:"Velammal Vidhyashram, Mambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21582", district:"2", Block:"322", label:"Raniammal Matric School, Narasankuppam 603109", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21583", district:"34", Block:"47", label:"SRI SANTHA VIDYALAYA MATRIC HSS-GENGAISOODAMANI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21584", district:"2", Block:"299", label:"Srujana Montessori N & PS, Kovilambakkam", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21585", district:"2", Block:"299", label:"Gate Way International School, Neelankarai", Sch_type:"Un-aided", Category:"High School"},
    { key:"21586", district:"2", Block:"299", label:"The lords international school, perungalathur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21587", district:"18", Block:"236", label:"GREENPARK MATRICULATION HIGHER SECONDARY SCHOOL SIRUVACHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21588", district:"11", Block:"191", label:"NOBLE PUBLIC SCHOOL, KALIAKKAVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21589", district:"6", Block:"235", label:"SIVAM INTERNATIONAL SCHOOL, THITHIYOPANAHALLI, PENNAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21590", district:"6", Block:"77", label:"SREE KUMARAGURU MATRIC SCHOOL, KOKKARAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21591", district:"37", Block:"175", label:"SHRI RAM SCHOOL,OMANDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21592", district:"9", Block:"95", label:"INTACT MATRICULATION  SCHOOL VELLIMALI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21593", district:"9", Block:"327", label:"J S CENTRAL SCHOOL MADAPPATTU", Sch_type:"Un-aided", Category:"High School"},
    { key:"21594", district:"22", Block:"246", label:"BMS MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21595", district:"22", Block:"34", label:"SELVA BHARATHI MATRICULATION  SCHOOL VAIKALPATTARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21596", district:"17", Block:"186", label:"PARK VIEW ACADEMY, ANIYAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21597", district:"26", Block:"72", label:"IMS MATRICULATION HIGH SCHOOL PAKKANA, GUDALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21598", district:"4", Block:"349", label:"CMC INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21599", district:"32", Block:"154", label:"JSR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21600", district:"4", Block:"248", label:"CARMEL JYOTHI CMI PUBLIC SCHOOL, UNJAVELAMPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21601", district:"4", Block:"248", label:"VINAYAGA VIDYODHAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21602", district:"7", Block:"212", label:"RADIANCE PUBLIC SCHOOL(CBSE), KOZHINCHIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21603", district:"7", Block:"380", label:"MOTHER THERASA ACADEMY MATRICULATION SCHOOL, MOREPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21604", district:"7", Block:"390", label:"VIDHYODHAYAA MATRICULATION SCHOOL, THOPPUPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21605", district:"18", Block:"236", label:"MARAGATHAAM MATRIC HR SEC SCHOOL PERAMBALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21606", district:"16", Block:"389", label:"SARADHAMBAL VIDYASHRAM MATRICULATION HIGHER SEC. SCHOOL THETHAKUDI SOUTH", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21607", district:"15", Block:"284", label:"ABDUL KALAM MATRICULATION SCHOOL,E.SATHANOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21608", district:"35", Block:"382", label:"UAMS CVS FREEDOM VIDHYALAYA MATRICULATION ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21609", district:"35", Block:"134", label:"CBSE VIVEKANANDAM VIDYASHRAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21610", district:"25", Block:"313", label:"GANDHI MISSION INTERNATIONAL SCHOOL (CBSE)-MATHUR WEST", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21611", district:"25", Block:"144", label:"KAVERY MATRICULATION SCHOOL -MELACAUVERY -KUMBAKONAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21612", district:"27", Block:"40", label:"SCISM RURAL ACADEMY MATRICULATION   SCHOOL - RASINGAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21613", district:"38", Block:"264", label:"KALVI THANTHAI KAMARAJAR VIDHYASALA MATRICULATION SCHOOL, RAJAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21614", district:"20", Block:"335", label:"ST. NORBERT R.C. MATRICULATION HIGH SCHOOL, THIRUVETRIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21615", district:"20", Block:"99", label:"ST. JAMES MATRICULATION HIGH SCHOOL, PASUMPON", Sch_type:"Un-aided", Category:"High School"},
    { key:"21616", district:"20", Block:"86", label:"SARANYA MATRICULATION HIGH SCHOOL, VALINOKKAM VILAKKU, KEELAKIDARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21617", district:"20", Block:"266", label:"SHIFAN NOOR GLOBAL ACADEMY, KOPPERIMADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21618", district:"24", Block:"89", label:"REDEEMER MATRICULATION HSS SCHOOL, CHETTIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21619", district:"30", Block:"92", label:"JANAKI KRISHNA VIDYA MANDIR N & PS PADMANERI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21620", district:"30", Block:"204", label:"ST.  ANN'S  MATRICULATION SCHOOL, ELANGULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21621", district:"30", Block:"229", label:"ST FRANCIS DE PAUL PRIMARY ENGLISH MEDIUM SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21622", district:"11", Block:"149", label:"St.Sebastian Matric High School. Madathattuvilai", Sch_type:"Un-aided", Category:"High School"},
    { key:"21623", district:"11", Block:"341", label:"ARKA-THE GREEN SCHOOL-ARUVIKARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21624", district:"11", Block:"182", label:"N.V.K.S.VIDYALAYA VETTUVENNI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21625", district:"11", Block:"124", label:"NANJIL CATHOLIC SCHOOL(CBSE)-VAZHUTHALAMPALLAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21626", district:"11", Block:"191", label:"ST.XAVIER ENGLISH SCHOOL-PALAVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21627", district:"13", Block:"376", label:"LOURDU MATHA MATRICULATION SCHOOL VISUVASAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21628", district:"13", Block:"139", label:"SRI VIJAY VIDYALAYA MATRIC SCHOOL OLDPET ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21629", district:"13", Block:"139", label:"EXCEL MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21630", district:"13", Block:"117", label:"SRI VINAYAGA VIDYALAYA MATRIC. SCHOOL, KALKUTTAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21631", district:"13", Block:"35", label:"ST. JOSEPH'S INTERNATIONAL SCHOOL, PERIYAPANAMUTLU ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21632", district:"33", Block:"64", label:"G.T.VIDHYA MANDIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21633", district:"32", Block:"57", label:"VEVEAHAM PRIME ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21634", district:"8", Block:"65", label:"C.E.T. MATRICULATION SCHOOL, VETTUKATTUVALASU", Sch_type:"Un-aided", Category:"High School"},
    { key:"21635", district:"32", Block:"223", label:"YOUNG INDIA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21636", district:"23", Block:"94", label:"ST.JOSEPH S PUBLIC SCHOOL,KALAIYARKOI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21637", district:"14", Block:"374", label:"AACHI INTERNATIONAL  SCHOOL, USILAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21638", district:"27", Block:"31", label:"ANGEL VIDHYA MANDIR MAT HSS, AUNDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21639", district:"30", Block:"46", label:"SUCCESS CONVENT MATRICULATION SCHOOL, MELASEVAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21640", district:"24", Block:"6", label:"SARUKA NURSARY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21641", district:"9", Block:"372", label:"CHORDIA JAIN SCHOOL OLAYANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21642", district:"4", Block:"349", label:"ADHYAYANA INTERNATIONAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21643", district:"10", Block:"410", label:"Saraswathi Vidyalaya Matric School, Enathur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21644", district:"22", Block:"68", label:"KAMARAJAR PUBLIC SCHOOL, THEDAVOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21645", district:"22", Block:"391", label:"JEEVA PUBLIC SCHOOL, SEVAMPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21646", district:"22", Block:"179", label:"SRI RAAM VIDYALAYA PUBLIC SCHOOL, POTTANERI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21647", district:"4", Block:"248", label:"HONEY BUNCH SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21648", district:"4", Block:"19", label:"SRI AMBAL THULASI PUBLIC SCHOOL SENIOR SECONDARY CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21649", district:"25", Block:"157", label:"SRI VINAYAKA PUBLIC SCHOOL -MOHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21650", district:"38", Block:"298", label:"PENNINGTON PUBLIC SCHOOL CBSE, SRIVILLIPUTHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21651", district:"20", Block:"266", label:"KRISHNA INTERNATIONAL SCHOOL, ECR DEVIPATTINAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21652", district:"16", Block:"196", label:"EGS PILLAY MATRICULATION HIGH SCHOOL KADAMBADI NAGAPATTINAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21653", district:"4", Block:"19", label:"NAVABHARATH NATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21654", district:"8", Block:"45", label:"MAHARISHI VIDYA MANDIR CBSE SCHOOL, INGUR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21655", district:"8", Block:"356", label:"SARATHA INTERNATIONAL SCHOOL, KODIVERY  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21656", district:"8", Block:"356", label:"BEST MATRICULATION SCHOOL, KALLIPATTI  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21657", district:"8", Block:"281", label:"SANTHOME INTERNATIONAL SCHOOL, SENBAGAPUDUR  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21658", district:"6", Block:"77", label:"SRI ARUNACHALA HIGH SCHOOL, MANDHIKULAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21659", district:"6", Block:"77", label:"BHARATH HI-TECH MHSS, PULUTHIYUR, KOKKARAPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21660", district:"6", Block:"218", label:"ST. AROKIA ANNAI SCHOOL, SELLIYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21661", district:"6", Block:"198", label:"SRI RAMACHANDRA MATRIC SCHOOL, DHOMBARAKAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21662", district:"6", Block:"58", label:"MKS MATRIC SCHOOL, SOGATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21663", district:"6", Block:"58", label:"AUXILIUM MHSS, SOGATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21664", district:"17", Block:"360", label:"BARANI INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21665", district:"17", Block:"66", label:"BHARATHI ACADEMY,  REDDIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21666", district:"17", Block:"201", label:"THAQWA MATRICULATION SCHOOL , NALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21667", district:"17", Block:"165", label:"SRI VINAYAGA INTERNATIONAL SCHOOL, VAIYAPPAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21668", district:"17", Block:"397", label:"MAHARISHI VIDYA MANDIR  (CBSE), MASAKALIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21669", district:"17", Block:"267", label:"SRI VIDYA MANDIR MATRICULATION SCHOOL,KURUKKAPURAM.", Sch_type:"Un-aided", Category:"High School"},
    { key:"21670", district:"22", Block:"163", label:"DHEERAN ACADEMY (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"21671", district:"22", Block:"163", label:"LOTUS PUBLIC SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21672", district:"22", Block:"383", label:"HERITAGE VIDHYALAYA  SCHOOL, KAVARKALPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21673", district:"34", Block:"125", label:"SASTRA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21674", district:"34", Block:"125", label:"LAKSHMI AMMAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21675", district:"34", Block:"47", label:"ANNAI RENUGAMBAL MATRIC HR. SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21676", district:"34", Block:"249", label:"GHS(GIRLS)-KELUR", Sch_type:"Government", Category:"High School"},
    { key:"21677", district:"34", Block:"412", label:"ORANGE BRITISH ACADEMY SCHOOL-VINNAMANGALAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21678", district:"21", Block:"290", label:"VALLIYAMMAI MATRIC SCHOOL, SHOLINGHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21679", district:"31", Block:"5", label:"JAYA VAASAVI MATRIC.HR.SEC.SCHOOL, ALANGAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21680", district:"21", Block:"409", label:"THE GEEKAY WORLD SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21681", district:"36", Block:"393", label:"SRI NARAYANI VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21682", district:"36", Block:"393", label:"SPARK CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21683", district:"37", Block:"164", label:"BWDA MIDDLE SCHOOL KOLLIYANGUNAM  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21684", district:"37", Block:"164", label:"SKD MATRICULATION SCHOOL SOZHIYASORKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21685", district:"9", Block:"95", label:"SRI VENKATESHWARA MATRICULATION SCHOOL MATHUR  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21686", district:"37", Block:"70", label:"ASUVATHAMA MATRIC HIGHER SECONDARY SCHOOL, PALAPPADI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21687", district:"9", Block:"50", label:"SEVA MATRICULTION MIDDLE SCHOOL PULUVAPADI  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21688", district:"5", Block:"406", label:"ANTONY PUBLIC  SENIOR SEC SCHOOL-CUDDALORE MAIN ROAD ,PUDHUKURAIPETTAI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21689", district:"5", Block:"148", label:"DON BOSCO MATRICULATION SCHOOL-ANAYAMPET", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21690", district:"5", Block:"148", label:"ANNAI MULLAI MATRICULATION HIGHER SECONDARY SCHOOL,-VADAKUTHU  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21691", district:"5", Block:"98", label:"JAYAPRIYA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL,GOPALAPURAM.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21692", district:"5", Block:"54", label:"SRI VANI VIDHYALAYA MATRICULATION SCHOOL, THOTTI  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21693", district:"5", Block:"54", label:"EQUITAS GURUKUL MATRICULATION HIGHER SECONDARY SCHOOL- DEVANAMPATTINAM ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21694", district:"5", Block:"39", label:"OXFORD ENGLISH SCHOOL-THILLAINAYAGAPURAM		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21695", district:"5", Block:"17", label:"PALLAVA INTERNATIONAL CBSE SCHOOL, KEEZHAKUPPAM ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21696", district:"33", Block:"261", label:"SRI AKSHAYA VIDYALAYA MATRICULATION SCHOOL , AMMAYARKUPPAM ROAD , RKPET , THIRUVALLUR DISTRICT", Sch_type:"Un-aided", Category:"High School"},
    { key:"21697", district:"33", Block:"261", label:"BILLABONG HIGH INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21698", district:"33", Block:"403", label:"THE NAZARETH ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21699", district:"33", Block:"403", label:"VIJAYANTA MODEL HR SEC SCHOOL		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21700", district:"33", Block:"403", label:"ANNAI VIOLET INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21701", district:"33", Block:"403", label:"EBENEZER MARCUS INTERNATIONAL SCHOOL & JR. COLLEGE		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21702", district:"33", Block:"403", label:"ARULMIGU MEENAKSHIAMMAN PUBLIC SCHOOL		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21703", district:"33", Block:"403", label:"V.N.R. VIVEKANANDA VIDYALAYA		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21704", district:"33", Block:"403", label:"LA CHATELAINE JUNIOR COLLEGE  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21705", district:"33", Block:"76", label:"R.M.K PATASHAALA CBSE  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21706", district:"33", Block:"76", label:"T.J.S MATRIC HR.SEC.SCHOOL,PERUVOYAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21707", district:"33", Block:"184", label:"ST MARKS MATRIC SCH  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21708", district:"33", Block:"252", label:"MAHALAKSHMI VIDHYA MANDHIR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21709", district:"33", Block:"252", label:"VELAMMAL VIDYALAYA  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21710", district:"33", Block:"252", label:"VGN CHINMAYA VIDYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21711", district:"33", Block:"252", label:"S.S.CITIZEN SCH		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21712", district:"33", Block:"252", label:"JAI HIND MATRICULATION SCHOOL,THIRUMAZHISAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21713", district:"33", Block:"252", label:"SUDHARSANAM VIDYAASHRAM  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21714", district:"33", Block:"64", label:"J.N.N VIDYALLAYA ,KANNIGAIPER  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21715", district:"33", Block:"87", label:"VISHWAKSENA GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21716", district:"33", Block:"253", label:"QALAM INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21717", district:"33", Block:"338", label:"VELAMMAL ACADEMY MATRICULATION SCHOOL PAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21718", district:"2", Block:"299", label:"VAELS International school,INJAMBAKKAM		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21719", district:"2", Block:"299", label:"St.John's public School,Jaladianpet		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21720", district:"2", Block:"299", label:"Narayana Group of Schools,Neelankarai		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21721", district:"2", Block:"299", label:"St.John's International school,Neelangarai  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21722", district:"2", Block:"299", label:"Sri Shankara NPS, Neelangarai		", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21723", district:"2", Block:"299", label:"Bharathi Vidyalaya  Senior Secondary School, Perumbakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21724", district:"2", Block:"299", label:"BVM Global School,Perumbakkam		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21725", district:"2", Block:"299", label:"Army Public School,Nandambakkam  ", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"21726", district:"2", Block:"299", label:"Sri Sankara Balavidyalaya,Pallikaranai		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21727", district:"2", Block:"299", label:"SAN Academy,Pallikaranai  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21728", district:"2", Block:"300", label:"Narayana E-techno schools  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21729", district:"2", Block:"114", label:"Lightworkers Academy", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21730", district:"2", Block:"114", label:"ST.MICHAEL'S NURSERY & PRIMARY SCHHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21731", district:"2", Block:"114", label:"Sivananda Rajaram Senior Secondary School Maraimaai Nagar		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21732", district:"2", Block:"329", label:"VELS VIDYASHRAM SENIOR SECONDARY SCHOOL, THALAMBUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21733", district:"10", Block:"146", label:"Alwin International Public School, Kzheel Padappai  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21734", district:"10", Block:"146", label:"Velammal Vidhyashram, Salamangalam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21735", district:"10", Block:"146", label:"Peace on Green Earth Public School, Karimanagar  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21736", district:"10", Block:"146", label:"Sivanthi Public School, Kundrathur  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21737", district:"10", Block:"146", label:"BSSK Marticulation School, Varatharajapuram		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21738", district:"10", Block:"101", label:"Gurukshetra Public School-KONERIKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21739", district:"10", Block:"101", label:"SSKV Boys Matric  HrSS Kanchipuram 631501", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21740", district:"10", Block:"101", label:"Dhwarkesh Vidhyashram CBSE School, Kanchipuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"21741", district:"7", Block:"29", label:"RC SAHAYARANI HIGH SCHOOL,CHINNALAPATTI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21742", district:"7", Block:"390", label:"SHRI MAHA VIDHYALAYA HS, THANEERPANDAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21743", district:"7", Block:"380", label:"SHRI JAY RAJ VIDYA MANDIR INTERNATIONAL SCHOOL, VELVARKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21744", district:"27", Block:"241", label:"TMHNU NADAR SARASWATHI PUBLIC SCHOOL - VADAPUDUPATTI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21745", district:"27", Block:"319", label:"MARY MATHA CMI PUBLIC SCHOOL - THENI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21746", district:"27", Block:"319", label:"T.M.H.N.U.VIDYALAYA MATRIC HSS - THENI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21747", district:"27", Block:"49", label:"GAYATHRI GIRLS MATRIC HSS - CHINNAMANUR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21748", district:"27", Block:"40", label:"PURNA VIDYA BHAVAN PUBLIC SCHOOL - KODANGIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21749", district:"14", Block:"328", label:"K.M.R INTERNATIONAL CBSE SCHOOL, ATCHAMPATHU  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21750", district:"14", Block:"328", label:"K.C VIDYALAY CBSE HIGH SCHOOL, ELIYARPATHI  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21751", district:"14", Block:"324", label:"KEREN PUBLIC SCHOOL, CHEKKANURANI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21752", district:"19", Block:"22", label:"SHIVAANI VIDHYAA MANDIR HR. SEC. SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21753", district:"19", Block:"67", label:"VIDYA VIKAS BOYS MAT.HR.SECSCHOOL	PUDUNAGAR	", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21754", district:"18", Block:"236", label:"GOLDEN GATES VIDHYASHRAM SR.SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21755", district:"18", Block:"7", label:"VARADHA WIKAS PUBLIC SCHOOL VARASAIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21756", district:"18", Block:"400", label:"IMMANUVEL HIGH SCHOOL KUNNAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21757", district:"12", Block:"23", label:"USWATHUN HASANA ISLAMIC INTERNATIONAL SCHOOL  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21758", district:"12", Block:"112", label:"EQUITAS GURUKUL MATRIC HIGHER SECONDARY SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21759", district:"29", Block:"367", label:"LOVE DALE MATRIC SCHOOL,KAVERI NAGAR		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21760", district:"29", Block:"168", label:"CHELLAMMAL MATRIC HIGHER SECONDARY SCHOOL, THIRUMALAIYANPATTI, MANAPPARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21761", district:"29", Block:"168", label:"SRI GURU VIDYALAYA (CBSE),MANAPPARAI  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21762", district:"29", Block:"171", label:"ALPHA CAMBRIDGE INTERNATIONAL. SCHOOL, K.K NAGAR		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21763", district:"29", Block:"171", label:"SBIOA SR. SEC. SCHOOL (CBSE), AIRPORT POST, TRICHY-7  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21764", district:"35", Block:"173", label:"UAMS DR ALAMEEN AR ,MATRICULATION SCHOOL  NAHANGUDI  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21765", district:"25", Block:"336", label:"SRI SRINIVASA HIGH SCHOOL-THIRUVAIYARU  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21766", district:"25", Block:"234", label:"DHARMMABAL VEERAPPAN PUBLIC SCHOOL -POONAVARAYANKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21767", district:"31", Block:"155", label:"MJR MATRICULATION SCHOOL VEERANKUPPAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21768", district:"36", Block:"243", label:"AHS CONCORDIA, PERNAMBUT", Sch_type:"Fully Aided", Category:"High School"},
    { key:"21769", district:"31", Block:"155", label:"FUTURE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21770", district:"31", Block:"155", label:"WISDOM PARK INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21771", district:"21", Block:"21", label:"PARAMPARA CBSE SCHOOL, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21772", district:"21", Block:"21", label:"TRS GLOBAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21773", district:"36", Block:"113", label:"WISDOM MATRIC HSS, RAGHUPATHI NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21774", district:"36", Block:"113", label:"KINGSTON MATRIC HSS, KATPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21775", district:"36", Block:"113", label:"VANI VIDHAYALAYA  SCHOOL CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21776", district:"31", Block:"103", label:"RAJAS INTERNATIONALS SCHOOLS CBSE  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21777", district:"31", Block:"103", label:"SRI VIJAY VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, ADIYUR, TIRUPATTUR..", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21778", district:"31", Block:"103", label:"SREE SHAI SARAN MATRICULATION SCHOOL SEVATHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21779", district:"31", Block:"103", label:"MAHA MATRIC SCHOOL, PUDUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21780", district:"31", Block:"103", label:"DR.THANGAMMA INTERNATIONAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21781", district:"36", Block:"84", label:"MOUNT LITERA ZEE SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21782", district:"36", Block:"74", label:"ASHIRWAD'S INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21783", district:"21", Block:"24", label:"SMILES INDIA VIDYASHRAM CBSE SCHOOL  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21784", district:"21", Block:"24", label:"GLOBAL PUBLIC SCHOOL CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21785", district:"36", Block:"12", label:"THE NAMAKKAL TEACHERS PARK MHSS, SATHYAMANGALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21786", district:"37", Block:"379", label:"UNAIDED KUILAPALAYAM HIGHER SECONDARY SCHOOL KUILAPALAYAM  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21787", district:"37", Block:"379", label:"ST MARYS HIGH SCHOOL THIRUCHITRAMBALAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21788", district:"37", Block:"379", label:"RAGAVENDRA MIDDLE SCHOOL PERIYAMUDALIYAR CHAVADI WARD 1", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21789", district:"37", Block:"379", label:"NIRVANA HIGH SCHOOL NADUKUPPAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21790", district:"9", Block:"277", label:"NEW POWER MATRIC HIGHER SECONDARY SCHOOL SANKARAPURAM  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21791", district:"9", Block:"277", label:"KALAIMAHAL  MATRIC HIGHER SECONDARY SCHOOL,  MANJAPUTHUR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21792", district:"9", Block:"277", label:"CHAKRA VIDYALAYA CBSE SCHOOL VADASEMAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21793", district:"9", Block:"277", label:"VIVEKANANDA HIGHER SECONDARY SCHOOL SOLAMPATTU  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21794", district:"37", Block:"214", label:"SRI DHARAMCHAND JAIN SCHOOL (CBSE) KARUVAMPAKKAM  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21795", district:"32", Block:"250", label:"ANNAL SPECIAL SCHOOL  ", Sch_type:"Un-aided", Category:"Others"},
    { key:"21796", district:"32", Block:"363", label:"AVP TRUST MATRICULATION SCHOOL  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21797", district:"32", Block:"363", label:"GLOBAL DISCOVERY ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21798", district:"32", Block:"363", label:"BRIGHT PUBLIC MATRICULATION SCHOOL  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21799", district:"1", Block:"346", label:"SWAMI MATRIC HR SEC SCHOOL KEELAPALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21800", district:"1", Block:"81", label:"MEENAKSHI RAMASAMY VIDHYALAYA THANTHANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21801", district:"1", Block:"26", label:"AKILA BHARATHY MATRICULATION HIGH SCHOOL  POYYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21802", district:"1", Block:"26", label:"ANNAI INDHIRA HIGH SCHOOL VILANGUDI		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21803", district:"13", Block:"117", label:"KS MILLENNIUM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21804", district:"13", Block:"177", label:"SRI VETRI VIKAS MATRIC. HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21805", district:"13", Block:"78", label:"SRI VIJAY VIDHYASHRAM SCHOOL HOSUR		", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21806", district:"13", Block:"78", label:"SREE SHIRDI SAI VIDYALAYA MATRICULATION SCHOOL  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21807", district:"13", Block:"139", label:"TRINITY ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21808", district:"3", Block:"237", label:"EVERWIN VIDHYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21809", district:"3", Block:"2", label:"ST. MICHAEL'S  ACADEMY SCHOOL OF EXCELLENCE		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21810", district:"11", Block:"354", label:"LHL SCHOOL - VISUVASAPURAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21811", district:"11", Block:"191", label:"ST. STEPHEN'S MATRIC SCHOOL, SOUTH PALAVILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21812", district:"11", Block:"191", label:"KABD CBSE SCHOOL-PAINKULAM  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21813", district:"11", Block:"124", label:"ST.MARY'S SCHOOL , AMANATTANTHERI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21814", district:"11", Block:"124", label:"BEST SCHOOL (CBSE)-PALOOR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21815", district:"11", Block:"182", label:"HOLY TRINITY INTERNATIONAL SCHOOL-MELPALAI  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21816", district:"11", Block:"341", label:"TRINITY CENTRAL SCHOOL-CHITHRENCODE  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21817", district:"11", Block:"341", label:"SRK INTERNATIONAL SCHOOL-KULASEKARAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21818", district:"11", Block:"149", label:"GOLDEN N&P SCHOOL-CHERAMANGALAM	", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21819", district:"11", Block:"149", label:"ST.JOSEPH'S N&P SCHOOL-KALIMAR  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21820", district:"11", Block:"263", label:"GARDENCOURT CAMBRIDGE SCHOOL-KATTADITHATTU  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21821", district:"11", Block:"263", label:"PADMASHREE VIDYALAYA-GANAPATHIPURAM  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21822", district:"28", Block:"138", label:"SHRI PAPER ANANDA MATRICULATION  SCHOOL ILAYARASANENDAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21823", district:"30", Block:"361", label:"ROSE MARY IDEAL PUBLIC SCHOOL  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21824", district:"30", Block:"222", label:"AL-MADINAH PUBLIC SCHOOL(CBSE)  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21825", district:"24", Block:"388", label:"ANNAI MEENAKSHI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21826", district:"24", Block:"388", label:"SVC SAI NIKETAN SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21827", district:"30", Block:"385", label:"KEINS CBSE SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21828", district:"30", Block:"385", label:"RMS INTERNATIONAL SCHOOL THANDIARKULAM  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21829", district:"24", Block:"307", label:"St. JOSEPH PUBLIC SCHOOL		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21830", district:"30", Block:"262", label:"VSR INTERNATIONAL SCHOOL, TISAYANVILAI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21831", district:"30", Block:"262", label:"POTHIHAI PUBLIC MATRIC HIGHER SECONDARY SCHOOL, IDAYANKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21832", district:"30", Block:"262", label:"SRI GOLD STAR MATRIC HIGHER SECONDARY SCHOOL,TISAIYANVILAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21833", district:"30", Block:"221", label:"HOLY ANGELS BCV INTERNATIONAL SCHOOL, CAUSSANELPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21834", district:"30", Block:"221", label:"GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL,MUNNERPALLAM  ", Sch_type:"Government", Category:"High School"},
    { key:"21835", district:"24", Block:"119", label:"HINDU HS VELLAIPANEIARYPATTY  ", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21836", district:"24", Block:"91", label:"NALLAMANI MATRIC SCHOOL KODIKURICHI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21837", district:"24", Block:"91", label:"AL-AZHAR MATRICULATION SCHOOL, MANGALAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21838", district:"24", Block:"89", label:"GOOD SHEPHERD MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21839", district:"24", Block:"6", label:"HOLY CROSS MATRICULATION  HSS SCHOOL MARANTHAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21840", district:"24", Block:"6", label:"JESUS LOVES MATRICULATION HSS SCHOOL VENKATESHWARAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21841", district:"23", Block:"94", label:"ST.JOSEPH'S MATRICULATION SCHOOL  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21842", district:"23", Block:"94", label:"ST. MICHAEL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21843", district:"23", Block:"94", label:"OXFORD MATRICULATION HIGHER SECONDARY SCHOOL SOORAKKULAM  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21844", district:"23", Block:"94", label:"SRI SARASWATHY VIKAS VIDYALAYA MATRIC HR SEC SCHOOL, KALAYARKOIL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21845", district:"23", Block:"96", label:"MOUNT ZION SILVER JUBILEE MATRICULATION HR SEC  SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21846", district:"23", Block:"272", label:"SARAVANA MATRICULATION MIDDLE SCHOOL MANACHAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21847", district:"23", Block:"272", label:"SHREE RAAJA RAJAN SCHOOL  AMARAVATHI PUDUR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21848", district:"23", Block:"272", label:"ALAGAPPA  ACADEMY   ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21849", district:"23", Block:"272", label:"ALAGAPPA GIRLS MAT.HSS. SCHOOL, KARAIKUDI.  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21850", district:"23", Block:"272", label:"KARAIKUDI MAHARISHI VIDYA MANDIR MT.HR.SEC.  SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21851", district:"23", Block:"56", label:"PANCHU VALLIAPPA MATRICULATION SCHOOL DEVAKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21852", district:"23", Block:"166", label:"GOODWILL MATRIC HR.SEC.SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21853", district:"38", Block:"411", label:"VIVEKANANTHA VIDYA MANDIR.,AKKANAPURAM  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21854", district:"38", Block:"411", label:"LINGA GLOBAL SCHOOL.,KRISHNANKOVIL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21855", district:"38", Block:"396", label:"SP MODERN MATRICULATION SCHOOL ,SEVALPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21856", district:"38", Block:"396", label:"HAYAGRIVAS INTERNATIONAL SCHOOL.PERNAYAKANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21857", district:"38", Block:"295", label:"AYYAVU MATRICULATION HIGH SCHOOL, VADAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21858", district:"38", Block:"282", label:"SOUTHSIDE INTERNATIONAL SCHOOL, METTAMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21859", district:"20", Block:"335", label:"AMEER SULTHAN ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL, THONDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21860", district:"20", Block:"231", label:"MOUNT LITERA ZEE CBSE SCHOOL, KAMUDAKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21861", district:"25", Block:"144", label:"CHRIST THE KING (GIRLS) MAT HSS-KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21862", district:"25", Block:"216", label:"SRI SRI GNAN MANDIR MATRIC  HIGH  SCHOOL-VADAKKUR SOUTH -ORATHANADU THANJAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21863", district:"25", Block:"42", label:"YAGAPPA INT RESI. SCHOOL -SENGIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21864", district:"26", Block:"370", label:"NIZAM BRITISH INTERNATIONAL SCHOOL, OOTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21865", district:"26", Block:"370", label:"THE BLUEMOUNTAINS SCHOOL OOTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21866", district:"26", Block:"72", label:"GLOBAL MATRICULATION SCHOOL DEVALA  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21867", district:"26", Block:"135", label:"INTERNATIONAL COMMUNITY SCHOOL KOTAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21868", district:"4", Block:"349", label:"KARUNYA CHIRISTIAN SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21869", district:"4", Block:"279", label:"SNS ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21870", district:"4", Block:"279", label:"NAGARATHINAM INTERNATIONAL SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"High School"},
    { key:"21871", district:"4", Block:"279", label:"ADITHYA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21872", district:"4", Block:"279", label:"DON BOSCO SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21873", district:"4", Block:"245", label:"C.S ACADEMY CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21874", district:"4", Block:"156", label:"BRINDHAVAN VIDHYALAYA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21875", district:"11", Block:"3", label:"DONGUANELLA SPECIALSCHOOL FOR M.C-S.THAMARAIKULAM  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21876", district:"7", Block:"212", label:"DAMIEN PUBLIC SENIOR SECONDARY SCHOOL(CBSE), DIRAVIAM NAGAR, NILAKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21877", district:"14", Block:"161", label:"VIKAASA WORLD Hr. Sec. SCHOOL, VEERAPANDI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21878", district:"29", Block:"172", label:"SVR MATRIC SCHOOL,ATHANI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21879", district:"29", Block:"172", label:"RITI VIKASH VIDHAYALA,THEERAMPALAYAM  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21880", district:"25", Block:"313", label:"RAMYA SATHIANATHAN SENIOR SECONDARY SCHOOL(CBSE)-ALAKKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21881", district:"25", Block:"313", label:"KAMALA SUBRAMANIAM CBSE SCHOOL  -THANJAVUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"21882", district:"4", Block:"107", label:"VIDYAA VIKAS MAT. GIRLS HR. SEC. SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21883", district:"4", Block:"52", label:"SUGUNA INTERNATIONAL  CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21884", district:"31", Block:"208", label:"CONCORDIA HSS VANIYAMBADI", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"21885", district:"2", Block:"322", label:"SHINING STAR MATRICULATION SCHOOL, VASUVASAMUTHIRAM ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21886", district:"2", Block:"322", label:"Excellent NPS, Pudhupattinam  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21887", district:"3", Block:"62", label:"SIR MUTHA SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21888", district:"32", Block:"32", label:"SAKTHI VIDHYALAYAA MATRICULATION SCHOOL, SELLAPPAMPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21889", district:"4", Block:"156", label:"L.K.MAHA VIDHYALAYA MATRIC SCHOOL  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21890", district:"5", Block:"406", label:"VIRUTHAI VIKAS MATRICULATION  HIGHER SECONDARY SCHOOL -MANALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21891", district:"7", Block:"36", label:"FIRST STEP PUBLIC SCHOOL, KANAVAIPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21892", district:"7", Block:"36", label:"APOORVAA PUBLIC SCHOOL (CBSE), M.VADIPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"21893", district:"7", Block:"36", label:"SWAMI VIVEKANANDA VIDYALAYA, BATLAGUNDU  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21894", district:"7", Block:"75", label:"KARUR VIVEGAM VIDHYALAYA HIGH SCHOOL, D.GUDALUR  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21895", district:"8", Block:"244", label:"THE RICHMOND MATRICULATION HIGHER SECONDARY SCHOOL, SEENAPURAM  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21896", district:"8", Block:"244", label:"THE RICHMOND PUBLIC SCHOOL, SEENAPURAM		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21897", district:"2", Block:"300", label:"Agurchand Manmull Jain School, Meenambakkam  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21898", district:"2", Block:"300", label:"Apex Ponvidyashram School,  Puzhuthivakkam  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21899", district:"2", Block:"300", label:"New Prince Shri Bhavani Vidyashram, Ullagaram		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21900", district:"2", Block:"300", label:"PRINCE SRIVARI SENIOR SECONDARY SCHOOL , MADIPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21901", district:"10", Block:"377", label:"Adithyaa Matriculation School, Perunagar  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21902", district:"11", Block:"3", label:"AVILA SPECIAL SCHOOL-KANYAKUMARI  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21903", district:"11", Block:"3", label:"BISHOP REMIGIUS SCHOOL. CBSE NAGERCOIL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21904", district:"11", Block:"149", label:"ADLINE  MATRIC SCHOOL-MADATHATTUVILAI  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21905", district:"11", Block:"149", label:"VINS SCHOOL OF EXCELLENCE-CHUNKANKADAI  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21906", district:"11", Block:"263", label:"EVANS SCHOOL OF EXCELLENCE (CBSE), CTM PURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21907", district:"11", Block:"341", label:"ASSISI VIDHYALAYA SCHOOL FOR D.ABLED-CHENAMCODE  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21908", district:"11", Block:"355", label:"OXFORD MATRICULATION SCHOOL-AZHAGIAMANDAPAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21909", district:"1", Block:"26", label:"NALANDHA HR SEC SCHOOL RAYAMPURAM		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21910", district:"1", Block:"305", label:"JAYAM HIGH SCHOOL SUNDARESAPURAM		", Sch_type:"Un-aided", Category:"High School"},
    { key:"21911", district:"22", Block:"133", label:"C.M.MATRIC SCHOOL, RANGAMPALAYAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21912", district:"22", Block:"133", label:"UNIVERSAL MATRIC HR. SEC. SCHOOL, KONAKUTTAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21913", district:"22", Block:"226", label:"VEDHHA VIKASS HR. SEC. SCHOOL, SANDHIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21914", district:"23", Block:"106", label:"SRI KALAIVANI INTERNATIONAL(ICSE)SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21915", district:"33", Block:"252", label:"SPARTAN INTERNATIONAL SCHOOL CHEMBARAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21916", district:"33", Block:"253", label:"ST.XAVIERS MATRICULATION SCHOOL  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21917", district:"28", Block:"320", label:"WISDOM PUBLIC SCHOOL, VEERAPANDIAPATTINAM  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21918", district:"28", Block:"402", label:"SRI AMBAL VIDHYALAYA, AMBALNAGAR VILATHIKULAM		", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21919", district:"29", Block:"15", label:"GHS, POTHAVUR  ", Sch_type:"Government", Category:"High School"},
    { key:"21920", district:"29", Block:"366", label:"CAUVERY GLOBAL SCHOOL (CBSE),TRICHY  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21921", district:"29", Block:"366", label:"SRI VIGNESH PUBLIC SCHOOL (CBSE), WORAIYUR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21922", district:"24", Block:"6", label:"ARCHVEL MATRICULATION SCHOOL ALANGULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21923", district:"30", Block:"174", label:"SRI ARAVIND VIDYALAYA N&PS", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21924", district:"30", Block:"174", label:"LITTLE FLOWER PUBLIC SCHOOL GANDHINAGAR  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21925", district:"34", Block:"47", label:"DHIVYA HIGH SCHOOL-CHETPET  ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21926", district:"34", Block:"93", label:"CHEZHIAN MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21927", district:"36", Block:"12", label:"SHIKSHA GREENWOODS ICSE SCHOOL, PALLIKONDA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21928", district:"36", Block:"12", label:"KVS NURSERY & PRIMARY SCHOOL PALLIKONDA  ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21929", district:"36", Block:"84", label:"VISION VIDYASHRAM WORLD SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21930", district:"31", Block:"155", label:"V.NALINI VIDYALAYA MATRIC SCHOOL, NARIYAMBUT", Sch_type:"Un-aided", Category:"High School"},
    { key:"21931", district:"36", Block:"394", label:"LUTHERAN SCHOOL HOME FOR THE DEAF & BLIND  ", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"21932", district:"36", Block:"394", label:"GUILD OF SERVICE SCHOOL FOR THE BLIND", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"21933", district:"37", Block:"100", label:"OM SIVA SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL,PANAMALAI PETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21934", district:"9", Block:"372", label:"SRI RAMAKRISHNA VIDYA MANDIR INTERNATIONAL SCHOOL, ULUNDURPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"21935", district:"9", Block:"372", label:"RPK MIDDLE SCHOOL ULUNDURPET", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21936", district:"38", Block:"28", label:"R.J.MANTRA ENGLISH SCHOOL,CHINNAVALLIKULAM ROAD  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21937", district:"38", Block:"282", label:"GVJ INTERNATIONAL SCHOOL.,N.VENKADESWARAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21938", district:"38", Block:"298", label:"SACRED HEART CONVENT HIGH SCHOOL, SRIVILLIPUTHUR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21939", district:"3", Block:"62", label:"DON BOSCO SCHOOL OF EXCELLENCE EGMORE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21940", district:"10", Block:"101", label:"New Bharathidasan Mat.HSS, Orikkai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21941", district:"33", Block:"403", label:"NETHRODAYA SPECIAL SCHOOL FOR VI & LD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21942", district:"33", Block:"403", label:"NARENDRA MATRIC SCHOOL,KOVILPATHAGAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21943", district:"33", Block:"403", label:"BHAKTAATSALAM SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21944", district:"33", Block:"403", label:"NAZARETH MHSS, KANNADAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21945", district:"33", Block:"252", label:"RCM HIGH SCHOOL KARAYANCHAVADI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21946", district:"33", Block:"338", label:"SRI.R.M.JAIN VIDHYAPEETH MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21947", district:"32", Block:"223", label:"ADHARSH VIDHYALAYA MATRIC HR SEC SCHOOL, PALLADAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21948", district:"2", Block:"322", label:"ATOMIC ENERGY HIGHER SECONDARY SCHOOL, EAST SADRAS, KALPAKKAM", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"21949", district:"10", Block:"101", label:"Kanchi Global Matriculation Hr.Sec.School, Kanchipuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21950", district:"15", Block:"284", label:"THAMARAI MATRIC HR.SEC. SCHOOL, SEMBANARKOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21951", district:"6", Block:"230", label:"SIGARAM MHSS, PAPPAMBADI , PAPPIREDDIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21952", district:"33", Block:"403", label:"AJAY SPECIAL SCHOOL, COLLECTOR NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21953", district:"33", Block:"252", label:"NAZARETH MATRIC HR SEC SCHOOL TNHB, AVADI.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21954", district:"34", Block:"47", label:"ST.AMALARAKKINI HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"21955", district:"34", Block:"395", label:"BON SECOURS SPECIAL SCHOOL FOR SPEECH & HEARING IMPAIRED", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21956", district:"14", Block:"324", label:"GOVT KALLAR GIRLS HR SEC SCHOOL, CHECKANURANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"21957", district:"4", Block:"302", label:"TIRUPUR HR SEC SCHOOL FOR THE DEAF", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21958", district:"29", Block:"168", label:"SARASWATHI VIDHYALAYA MATRICULATION HSS,ANDAVAR KOVIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21959", district:"8", Block:"10", label:"PERIYASAMY HIGH SCHOOL, AMMAPETTAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"21960", district:"8", Block:"281", label:"ROMAN CATHOLIC HIGH SCHOOL, GUNDRI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21961", district:"29", Block:"176", label:"CHIRUMALAR HIGHER SECONDARY SCHOOL,VELLAKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21962", district:"5", Block:"406", label:"P.M.S. HIGHER SECONDARY SCHOOL FOR THE SPEECH & HEARING IMPAIRED KONANKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21963", district:"5", Block:"54", label:"OASIS SNEHADEEP HIGH SCHOOL FOR THE SEVERELY DISABLED - VANNIYARPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21964", district:"29", Block:"343", label:" SHRINE VAILANKANNI MATRIC HR.SEC SCHOOL  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21965", district:"36", Block:"12", label:"THE NAMAKKAL TEACHERS VIDHYASHRAM MATRIC HSS, KAZHANIPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21966", district:"19", Block:"22", label:"ARANTHAI  ANNAI SPEECH AND HEARING IMPAIRMENT SPECIAL SCHOOL ARANTHANGI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21967", district:"13", Block:"78", label:"NEW BRILLIANT IDEAL MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21968", district:"37", Block:"401", label:"SEVENTH DAY WHITE MATRICULATION SCHOOL V.SATHANUR ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21969", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL MOOLAKADU", Sch_type:"Government", Category:"High School"},
    { key:"21970", district:"9", Block:"95", label:"SRI BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL THACHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21971", district:"9", Block:"327", label:"ADI DRAVIDAR WELFARE HIGH SCHOOL KALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"21972", district:"22", Block:"274", label:"JAIRAM HIGHER SECONDARY SCHOOL, CHINNATHIRUPATHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21973", district:"33", Block:"338", label:"ABS VIDHYAALAYAA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21974", district:"22", Block:"68", label:"SRI VIDHYA BHARATHI MATRIC HR. SEC. SCHOOL, NADUVALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21975", district:"23", Block:"272", label:"VIDHYA GIRI MATRICULATION HIGHER SECONDARY SCHOOL, KARAIKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21976", district:"13", Block:"78", label:"VANAPRASTHA INTERNATIONAL MATRIC HR SEC SCHOOL-THUMMANAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21977", district:"28", Block:"280", label:"MARY IMMACULATE MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21978", district:"28", Block:"351", label:"ST.THOMAS THE APOSTLE MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21979", district:"32", Block:"392", label:"SRI AANOOR VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, VAIKALMETTUPUDUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21980", district:"19", Block:"405", label:"CHELLAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21981", district:"19", Block:"67", label:" CHRIST THE KING MATRICULATION SCHOOL , GANDARVAKKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"21982", district:"5", Block:"120", label:"KAMARAJ INTERNATIONAL CBSE SCHOOL,VAYALUR.", Sch_type:"Un-aided", Category:"High School"},
    { key:"21983", district:"33", Block:"338", label:"VIVEKANANDA VIDHYALAYA MATRIC", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21984", district:"33", Block:"252", label:"SRI RAM VIDHYA MANDIR KOTTAMEDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21985", district:"33", Block:"252", label:"AL-HIKMA MADARASA & EDUCATIONAL CENTER", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21986", district:"33", Block:"252", label:"VELAMMAL MATRIC HR SEC SCHOOL, PARUTHIPATTU,  AVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21987", district:"33", Block:"289", label:"RDS VIDYA MANTHIR NUR & PRI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21988", district:"33", Block:"403", label:"GREAT HARVEST VIDYALAYA ", Sch_type:"Un-aided", Category:"High School"},
    { key:"21989", district:"33", Block:"403", label:"GODSON UDAYA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21990", district:"33", Block:"403", label:"PASUMPON NARAYANA e-TECHNO SCHOOL, AMBATTUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"21991", district:"33", Block:"403", label:"SPARTAN EXCLUSIVE SCHOOL , MOGAPPAIR EAST", Sch_type:"Un-aided", Category:"High School"},
    { key:"21992", district:"33", Block:"403", label:"NARAYANA OLYMPIAD SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21993", district:"33", Block:"403", label:"VELAMMAL VIDYALAYA, MELAYANAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21994", district:"33", Block:"403", label:"NARAYANA E-TECHNO SCH , VELLAPAN CHAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21995", district:"33", Block:"403", label:"NARAYANA OLYMPIAD SCHOOL, OMSAKTHINAGAR, VANAGARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"21996", district:"33", Block:"403", label:"DAYASADAN AGARWAL VIDYALAYA, NERKUNDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"21997", district:"33", Block:"403", label:"ADW HS, MADHURAVOYAL", Sch_type:"Government", Category:"High School"},
    { key:"21998", district:"33", Block:"403", label:"EVERWIN PUBLIC SCHOOL, MADURAVOYAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"21999", district:"33", Block:"403", label:"VELAMMAL VIDYALAYA, ALAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22000", district:"33", Block:"403", label:"MADHA PUBLIC SCHOOL, VALASARAVAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22001", district:"33", Block:"260", label:"ST.ANNES MAT SCH ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22002", district:"33", Block:"260", label:"GREENFIELD CHENNAI INTERNATIONAL SCHOOL,", Sch_type:"Un-aided", Category:"High School"},
    { key:"22003", district:"33", Block:"260", label:"VELAMMAL GLOBAL INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22004", district:"33", Block:"364", label:"SRI VIDYA NIKETAN MATRIC HIGHER SECONDARY  SCHOOL E.N KANDIGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22005", district:"33", Block:"364", label:"GRT MAHALAKSMI VIDHYALAYA CBSE SCHOOL,PATTABIRAMAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22006", district:"33", Block:"337", label:"ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22007", district:"3", Block:"240", label:"SRI SUSHWANI MATHA JAIN VIDYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22008", district:"3", Block:"237", label:"KRM PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22009", district:"3", Block:"237", label:"THE TEMPLE-SCHOOL OF ENLIGHTENMENT", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22010", district:"3", Block:"259", label:"SBOA GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22011", district:"3", Block:"259", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22012", district:"3", Block:"62", label:"UNION CHRISTIAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22013", district:"3", Block:"304", label:"TRINITY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22014", district:"3", Block:"2", label:"DAVe BABA VIDYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"22015", district:"3", Block:"2", label:"ST.PATRICK'S HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22016", district:"3", Block:"2", label:"AGR GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22017", district:"3", Block:"195", label:"ST.BEDE'S ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22018", district:"3", Block:"195", label:"ISWARI PRASAD DATTATREYA SPECIAL SCHOOL", Sch_type:"Fully Aided", Category:"Middle School"},
    { key:"22019", district:"10", Block:"101", label:"ADWHS, Melottivakkam", Sch_type:"Government", Category:"High School"},
    { key:"22020", district:"10", Block:"101", label:"Brindavan Anglo Vedic International School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22021", district:"10", Block:"410", label:"AMIRTHAM MATRIC SCHOOL,VENGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22022", district:"10", Block:"296", label:"COSMOPOLITAN MODEL VIDYALYA, SENGADU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22023", district:"10", Block:"296", label:"LOYOLA INTERNATIONAL RESIDENTIAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22024", district:"10", Block:"296", label:"Montfort School, Theresapuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"22025", district:"10", Block:"146", label:"Annai Theresa Matriculation School, Bharaniputhur", Sch_type:"Un-aided", Category:"High School"},
    { key:"22026", district:"10", Block:"146", label:"NARAYANA E- TECHNO SCHOOL, MS NAGAR, CHENNAI TN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22027", district:"10", Block:"146", label:"VELAMMAL BODHI CAMPUS SCHOOL  KOLAPPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22028", district:"10", Block:"146", label:"Holy Sai International School, Adhanur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22029", district:"10", Block:"146", label:"RISHS International  School, Mangadu", Sch_type:"Un-aided", Category:"High School"},
    { key:"22030", district:"2", Block:"329", label:"Velammal New Gen School, Kelambakkam", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22031", district:"2", Block:"114", label:"Velammal Vidyashram, Perumattunallur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22032", district:"2", Block:"114", label:"Maharishi Vidya Mandir, Echankaranai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22033", district:"2", Block:"114", label:"JRK Global School, Kattankolathur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22034", district:"2", Block:"114", label:"Velammal Vidhyashram (CBSE), Kalivanthapattu", Sch_type:"Un-aided", Category:"High School"},
    { key:"22035", district:"2", Block:"300", label:"GOVT ADW HIGH SCHOOL, ANAKAPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"22036", district:"2", Block:"300", label:"Mahalakshmi Vidya Mandir School, Pammal", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22037", district:"2", Block:"300", label:"Knowledge Academy School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22038", district:"2", Block:"300", label:"Hindustan International School, Mount", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22039", district:"2", Block:"300", label:"PRINCE SRIVARI SENIOR SECONDARY SCHOOL NANGANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22040", district:"2", Block:"300", label:"Sri Chaitanya Techno School, Chrompet", Sch_type:"Un-aided", Category:"High School"},
    { key:"22041", district:"2", Block:"153", label:"Layola Matric School, Cheyyur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22042", district:"2", Block:"51", label:"ASSEFA VIDHYALAYA MATRIC. HR. SEC.SCHOOL- POORIYAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22043", district:"2", Block:"299", label:"Akshar Arbol International School Injambakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22044", district:"2", Block:"299", label:"HINDUSTAN INTERNATIONAL SCHOOL KARAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22045", district:"2", Block:"299", label:"SHRADDHA Children Academy, Kottivakkam Kuppam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22046", district:"2", Block:"299", label:"AL QAMAR  ACADEMY, Kottivakkam Kuppam", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22047", district:"2", Block:"299", label:"RAMACHANDRAA PUBLIC SCHOOL, KOTTIVAKKAM KUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22048", district:"2", Block:"299", label:"Ravindra Bharathi Global School", Sch_type:"Un-aided", Category:"High School"},
    { key:"22049", district:"2", Block:"299", label:"Velammal New Gen School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22050", district:"2", Block:"299", label:"APL Global School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22051", district:"2", Block:"299", label:"PUMS, Ezhil Nagar", Sch_type:"Government", Category:"Middle School"},
    { key:"22052", district:"2", Block:"299", label:"St. Johns Universal School, Palavakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"22053", district:"2", Block:"299", label:"Seed Academy School", Sch_type:"Un-aided", Category:"High School"},
    { key:"22054", district:"2", Block:"299", label:"BVM International School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22055", district:"2", Block:"299", label:"SKB  VIDHYASHRAM School, Perungalathur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22056", district:"2", Block:"299", label:"Trileaves International School, Sembakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22057", district:"2", Block:"299", label:"Trileaves Global School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22058", district:"2", Block:"299", label:"Babaji Vidhyashram, Sholinganallur", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22059", district:"21", Block:"21", label:"GOVT ADW HIGH SCHOOL, AMMANUR", Sch_type:"Government", Category:"High School"},
    { key:"22060", district:"21", Block:"210", label:"GOVT HIGH SCHOOL, JAGIRTHANDALAM", Sch_type:"Government", Category:"High School"},
    { key:"22061", district:"21", Block:"409", label:" CHRIST THE KING CBSE SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"22062", district:"21", Block:"359", label:"Dr.S.R.K VIDHYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"22063", district:"36", Block:"113", label:"KINGSTON INTERNATIONAL ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22064", district:"36", Block:"84", label:"ADWGHS AMMANAGKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"22065", district:"36", Block:"84", label:"Arya Vidhyasshram International Residential School ( Senior Secondary )", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22066", district:"36", Block:"243", label:"ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL, PALLALAKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22067", district:"36", Block:"243", label:"ASH - SHAMS N&P SCHOOL, PERNAMBUT", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22068", district:"36", Block:"243", label:" GREEN VALLEY N&P SCHOOL  SATHKAR ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22069", district:"31", Block:"155", label:"KSR MATRIC PUBLIC HIGHER SECONDARY SCHOOL, MADHANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22070", district:"31", Block:"5", label:"GOVT (B) HR SEC SCHOOL  ALANGAYAM  ,TIRUPATHUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22071", district:"31", Block:"208", label:"RAJI GARDEN MATRIC HIGHER SECONDARY SCHOOL, NATRAMPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22072", district:"31", Block:"82", label:"DON BOSCO SCHOOL OF EXCELLENCE ", Sch_type:"Un-aided", Category:"High School"},
    { key:"22073", district:"31", Block:"103", label:"SRI V.R.V MATRIC HIGHER SECONDARY SCHOOL , PERAMPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22074", district:"6", Block:"58", label:"DNV International Academy", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22075", district:"6", Block:"198", label:"SENTHIL MHSS, ADHIYAMANKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22076", district:"6", Block:"198", label:"SENTHIL PUBLIC SCHOOL, ADHIYAMANKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22077", district:"6", Block:"235", label:"THE SPARTANS SCHOOL OF EXCELLENCE MATRIC SCHOOL, PARUVATHANAHALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22078", district:"6", Block:"218", label:"GHS AGARAM", Sch_type:"Government", Category:"High School"},
    { key:"22079", district:"6", Block:"218", label:"GHS KANNUKARAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22080", district:"6", Block:"109", label:"SRI VIJAY VIDYALAYA MATRIC SCHOOL, THANDUKARANAHALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22081", district:"6", Block:"77", label:"MULLAI MHSS, MAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22082", district:"34", Block:"48", label:"DHRONA PUBLIC SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"22083", district:"34", Block:"48", label:"VIRUTCHAM INTER PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22084", district:"34", Block:"387", label:"HASHINY INTERNATIONAL MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22085", district:"34", Block:"27", label:"APPLE INTERNATIONAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22086", district:"34", Block:"27", label:"KANNAMMAL INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22087", district:"34", Block:"412", label:"TULIP INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22088", district:"34", Block:"93", label:"ADWHS-KETTAVARAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22089", district:"34", Block:"47", label:"CHINMAYA VIDHYALAYA MATRIC HR SEC SCHOOL MANDAKOLATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22090", district:"34", Block:"125", label:"SAN MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22091", district:"34", Block:"44", label:"GHS-ANTHANUR", Sch_type:"Government", Category:"High School"},
    { key:"22092", district:"34", Block:"80", label:"GTRHSS-ATTIYANUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22093", district:"34", Block:"80", label:"GTRHS-KOVILANDOOR", Sch_type:"Government", Category:"High School"},
    { key:"22094", district:"34", Block:"80", label:"GTRHSS-PATTARAIKADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22095", district:"37", Block:"70", label:"SRI VIDHYA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL NARASINGARAYANPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22096", district:"37", Block:"379", label:"SREE MOTHER VIDYASHRAM KILIYANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22097", district:"37", Block:"401", label:"K.G.  VIDYASHRAM CENTRAL BOARD SECONDARY EDUCATION SCHOOL ASHOKAPURI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22098", district:"9", Block:"323", label:"NEW VIDYA  MANDIR MATRICLULATION SCHOOL KULADEEPAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22099", district:"9", Block:"323", label:"ST. ANTONY MATRICULATION SCHOOL T.ATHIPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22100", district:"37", Block:"189", label:"SRI LAKSHMI VIDHYAASHRAM SCHOOL ARAKANDANALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22101", district:"37", Block:"189", label:"VIVEKA PUBLIC SCHOOL (CBSE)  ARAKANDANALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22102", district:"37", Block:"342", label:"GOVERNMENT HIGH SCHOOL T.KOLATHUR", Sch_type:"Government", Category:"High School"},
    { key:"22103", district:"9", Block:"95", label:"GS MATRICULATION SCHOOL ALATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22104", district:"9", Block:"50", label:"BALAN BALAMANDHIR MATRIC HIGH SCHOOL NAINARPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22105", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL SITHALUR", Sch_type:"Government", Category:"High School"},
    { key:"22106", district:"9", Block:"327", label:"GOVERNMENT HIGH SCHOOL NANNARAM", Sch_type:"Government", Category:"High School"},
    { key:"22107", district:"22", Block:"68", label:"G.E.T. SCHOOL OF EXCELLENCE, NADUVALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22108", district:"22", Block:"246", label:"VIDHYA VIDYALAYA MATRICULATION SCHOOL, YETHAPPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22109", district:"22", Block:"246", label:"SRI AMITH VIDYALAYAA MATRIC SCHOOL ABINAVAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22110", district:"22", Block:"246", label:"SHRI SWAMY MATRIC SCHOOL, PETHANAICKENPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22111", district:"22", Block:"246", label:"ST. JOSEPH INTERNATIONAL SCHOOL, PERUNCHOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22112", district:"22", Block:"226", label:" ST. JOHN'S MATRICULATION SCHOOL,VENGAMPATTI MALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22113", district:"22", Block:"203", label:" VEDANTA VIDYALAYA  METTUR DAM ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22114", district:"22", Block:"30", label:"SHRI SWAMY PUBLIC SCHOOL, KALPAGANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22115", district:"22", Block:"30", label:"RASI VIDHYASHRAM (CBSE) SCHOOL, EACHAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22116", district:"22", Block:"215", label:"ADW HS, SIKKANAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22117", district:"22", Block:"215", label:"G.R.MATRIC HR. SEC. SCHOOL, CHELLAPILLAIKUTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22118", district:"22", Block:"383", label:"KALAIMAGAL VIDHYA MANDIR MATRIC HR. SEC. SCHOOL, SESANCHAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22119", district:"22", Block:"274", label:"ST. JOHN'S NATIONAL ACADEMY, ALAGAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22120", district:"17", Block:"201", label:"SPECTRUM LIFE SCHOOL, NAMAKKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22121", district:"17", Block:"254", label:"GNANODAYA INTERNATIONAL SCHOOL(CBSE) AK SAMUTHIRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22122", district:"17", Block:"267", label:"SRV INTERNATIONAL SCHOOL,PATTANAM ROAD,RASIPURAM.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22123", district:"17", Block:"224", label:"GREEN LAND MATRICULATION SCHOOL, METTUKADAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22124", district:"17", Block:"85", label:"CHANAKYA HI-TECH MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22125", district:"8", Block:"37", label:"PSK HI TECH ACADEMY, PARUVACHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22126", district:"8", Block:"65", label:"AN NOOR MATRICULATION SCHOOL, SUNNAMPU ODAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22127", district:"8", Block:"65", label:"NANDHA CENTRAL CITY SCHOOL, NARAYANAVALASU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22128", district:"8", Block:"65", label:"THE INDIAN PUBLIC SCHOOL (CAMBRIDGE INTERNATIONAL CAMPUS), SENAPATHY PALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22129", district:"8", Block:"128", label:"KONGU MATRICULATION HIGHER SECONDARY SCHOOL,  KARUMANDAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22130", district:"32", Block:"378", label:"SRI VETRI VINAYAGA MATRIC SCHOOL, PULANGULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22131", district:"32", Block:"378", label:"AALAYA  ACADEMY, SARKAR PERIYAPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22132", district:"26", Block:"370", label:"EKALAVYA MODEL RESIDENTIAL HIGHER SECONDARY SCHOOL M.PALADA", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22133", district:"26", Block:"72", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Vattakolly", Sch_type:"Government", Category:"Middle School"},
    { key:"22134", district:"4", Block:"52", label:"BHARATHI REHABILITATION CENTRE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22135", district:"4", Block:"52", label:"STANES SCHOOL CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22136", district:"4", Block:"52", label:"JEYAM SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22137", district:"4", Block:"156", label:"THE SAMHITA  ACADEMY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22138", district:"4", Block:"156", label:"SREE VISHNU VIDHYALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22139", district:"4", Block:"242", label:"NOVA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22140", district:"4", Block:"242", label:"BAPTIST ACADEMY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22141", district:"4", Block:"242", label:"YOUCAN SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22142", district:"4", Block:"279", label:"GITHANJALI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22143", district:"4", Block:"279", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Ondipudur", Sch_type:"Government", Category:"Middle School"},
    { key:"22144", district:"32", Block:"73", label:"SRI ARUNGARAIAMMAN NALLATHAAI VIDHYALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22145", district:"32", Block:"154", label:"UNITED PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22146", district:"32", Block:"371", label:"GOVT ADHIDRAVIDAR WELFARE HIGH SCHOOL AMMAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22147", district:"4", Block:"19", label:"KOVAI VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22148", district:"32", Block:"32", label:"VEDANTA ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22149", district:"32", Block:"32", label:"THE QUEST INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22150", district:"32", Block:"32", label:"BHARATHI VIDHYAA KENDHRA", Sch_type:"Un-aided", Category:"High School"},
    { key:"22151", district:"32", Block:"32", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Avinashi", Sch_type:"Government", Category:"Middle School"},
    { key:"22152", district:"32", Block:"223", label:"JAYANTHI PUBLIC SENIOR SECONDARY SCHOOL ,ARULPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22153", district:"32", Block:"223", label:"ADHARSH VIDHYALAYA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22154", district:"32", Block:"223", label:"SHIVA NIKETAN SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22155", district:"32", Block:"250", label:"GREEN PARK SMART SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22156", district:"4", Block:"301", label:"SHASWI INTERNATIONAL SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22157", district:"4", Block:"301", label:"SRI DHAYANANDAPURI MODEL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22158", district:"4", Block:"302", label:"SRI KANDHA VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22159", district:"4", Block:"302", label:"HOLY FAMILY MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22160", district:"4", Block:"302", label:"SRI VIVEKANANDHA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22161", district:"4", Block:"302", label:"YELLOW TRAIN SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22162", district:"32", Block:"363", label:"MGV GLOBAL ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22163", district:"32", Block:"363", label:"SRI JAI SARADHA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22164", district:"32", Block:"363", label:"SAI KIRUPA SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Others"},
    { key:"22165", district:"32", Block:"363", label:"AMRITA VIDYALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22166", district:"32", Block:"363", label:"DKT GLOBAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22167", district:"32", Block:"363", label:"KIDS PARK ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22168", district:"32", Block:"363", label:"BHARATHI VIDHYA BHAVAN SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22169", district:"32", Block:"363", label:"BHARATHI VIDHYASRAM", Sch_type:"Un-aided", Category:"Others"},
    { key:"22170", district:"32", Block:"363", label:"SUBBIAH CENTRAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22171", district:"32", Block:"363", label:"SHARANALAYA INTERNATIONAL MONTESSORI SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22172", district:"32", Block:"363", label:"KIDS CLUB INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22173", district:"7", Block:"29", label:"ORCHIDS SPECIAL SCHOOL, RATHINAGIRI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22174", district:"7", Block:"59", label:"AKV VIDYALAYA, KALLIPATTI, DINDIGUL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22175", district:"7", Block:"59", label:"ARVI SPECIAL SCHOOL FOR MR, SEELAPADI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22176", district:"7", Block:"127", label:"SHOLAI SCHOOL CENTRE FOR LEARNING ORGANIC AGRIC & APPROPRIATE TECHNOLOGY BHARATHI ANNA NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22177", district:"7", Block:"207", label:"UDHAYAM SPECIAL SCHOOL, VELAMPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22178", district:"7", Block:"207", label:"GHS, MOONGIL PATTI", Sch_type:"Government", Category:"High School"},
    { key:"22179", district:"7", Block:"212", label:"PUDHU UDHAYAM SPECIAL SCHOOL FOR MR, MURUGATHOORANPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22180", district:"7", Block:"219", label:"AWARD SPECIAL SCHOOL FOR MR, SIVAGIRIPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22181", district:"7", Block:"380", label:"BIGDREAMERS ACADEMY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22182", district:"7", Block:"380", label:"AKSHARA SPECIAL SCHOOL FOR MR,VADAMADURAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22183", district:"12", Block:"140", label:"GHS, K.OTTAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22184", district:"12", Block:"88", label:"AMUTHASURABHI VIDHYALAYA MATRIC. SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22185", district:"29", Block:"176", label:"DMI ST.JOSEPH GLOBAL CBSE SCHOOL,YAGAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22186", district:"29", Block:"192", label:"RAGHAVENDRA MATRICULATION HIGHER SEC. SCHOOL, KARATTAMPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22187", district:"29", Block:"192", label:"SHRI JAYENDRA VIDHYALAYA CBSE SCHOOL, VADUGAPATTY, MUSIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22188", district:"29", Block:"343", label:"MATHA MATRICULATION SCHOOL,M.K.KOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22189", district:"29", Block:"357", label:"SRI BALAJI VIDHYALAYA MATRICULATION HR. SEC. SCH., GOVINDAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22190", district:"29", Block:"357", label:"ATHAVAN ACADEMY MATRICULATION HS, PONNUSANGAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22191", district:"29", Block:"357", label:"RAJ VIDYA BHAVAN GIRLS MATRICULATION HS, THURAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22192", district:"29", Block:"317", label:"CHELAMMAL VIDHYAASHRAM Senior SEC. CBSE AYETHAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22193", district:"29", Block:"373", label:"RSK INTERNATIONAL (CBSE) SCHOOL,VENKATACHALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22194", district:"18", Block:"399", label:"GTRHS MALAIYALAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22195", district:"5", Block:"54", label:"ST.ANNE'S SCHOOL - PUDHUPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22196", district:"5", Block:"54", label:"GLOBAL SPECIAL SCHOOL FOR INTELLECTUAL DISABLED - 4TH CROSS STREET- SUBBURAYALU NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22197", district:"5", Block:"54", label:"MOUNT LITERA ZEE SCHOOL - VANDIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22198", district:"5", Block:"54", label:"EZHUCHI INTELLECTUAL DISABLED SPECIAL CARE SCHOOL - KONDUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22199", district:"5", Block:"54", label:"SHANTHAM SPECIAL SCHOOL FOR MENTALLY CHALLENGED - B.VADUGAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22200", district:"5", Block:"54", label:"SRI SIVASAKTHI MENTALLY RETARDED SPECIAL CARE SCHOOL - K.N.PETTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22201", district:"5", Block:"54", label:"DHAYA SPECIAL SCHOOL FOR INTELLECTUAL DISABLED - VANNIYARPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22202", district:"5", Block:"54", label:"CK SCHOOL OF PROGRESSIVE EDUCATION - CHELLANKUPPAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22203", district:"5", Block:"148", label:"RATHNA SPECIAL SCHOOL  METTUPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22204", district:"5", Block:"406", label:"JESSICA SPECIAL SCHOOL FOR THE INTELLECTUAL DISABLED- ALADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22205", district:"5", Block:"199", label:"GHS-PE.POOVANUR", Sch_type:"Government", Category:"High School"},
    { key:"22206", district:"5", Block:"199", label:"SRI BALAJI HIGHER SECONDARY SCHOOL,  NALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22207", district:"5", Block:"170", label:"Rainbow International Matric. Hr.Sec.School, Keelakalpoondi.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22208", district:"5", Block:"170", label:"SRI VENKATESWARA CBSE SCHOOL, KAZHUDUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22209", district:"5", Block:"233", label:"BABA VIDHYALAYA MATRICULATION HIGHER SECONDARY  SCHOOL, PARANGIPETTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22210", district:"5", Block:"39", label:"REDCE SPECIAL SCHOOL-VANDRAYANPATTU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22211", district:"5", Block:"39", label:"SRI RAGAVENDRA SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22212", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- C.VEERASOLAGAN", Sch_type:"Government", Category:"High School"},
    { key:"22213", district:"5", Block:"143", label:"CHALLENGED CHILDREN AND WOMEN EDUCATION TRUST", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22214", district:"5", Block:"143", label:"G.V. SPECIAL SCHOOL FOR MENTALLY RETARDED", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22215", district:"5", Block:"143", label:"R.N.SUSEELA SPECIAL SCHOOL FOR HEARING IMPAIRED", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22216", district:"5", Block:"115", label:"G.K. PUBLIC SENIOR  SECONDARY SCHOOL, KATTUMANNARKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22217", district:"15", Block:"178", label:"KAMALALAYAM N&P,PUTHAGARAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22218", district:"15", Block:"284", label:"GOVT HIGH SCHOOL NALLUCHERY", Sch_type:"Government", Category:"High School"},
    { key:"22219", district:"15", Block:"151", label:"GOVT HIGH SCHOOL THIRUMANANCHERI", Sch_type:"Government", Category:"High School"},
    { key:"22220", district:"15", Block:"293", label:"LIONS CLUB MATRICULATION SCHOOL VILANTHIDASAMUTHIRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22221", district:"35", Block:"141", label:"GHS ,SEETHAKKAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"22222", district:"25", Block:"313", label:"RADIANT INTERNATIONAL  SCHOOL CBSE -MADHAKOTTAI ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22223", district:"25", Block:"42", label:"SHEMFORD FUTURISTIC SCHOOL-BUDALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22224", district:"25", Block:"144", label:"THAMARAI INTERNATIONAL SCHOOL-KUMBAKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22225", district:"25", Block:"11", label:"GHS IRUMPUTHALAI", Sch_type:"Government", Category:"High School"},
    { key:"22226", district:"25", Block:"344", label:"MAHARISHI VIDYA MANDIR CBSE SCHOOL, AMMACHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22227", district:"25", Block:"234", label:"BRINDHAVAN PUBLIC SCHOOL- SUKKIRANPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22228", district:"25", Block:"287", label:"ROYAL PARK HIGH SCHOOL -NAVAKKOLLAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22229", district:"25", Block:"287", label:"ATLANTIC SCHOOL-VEERIYANKOTTAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22230", district:"19", Block:"405", label:"SFS. PUBLIC SCHOOL ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22231", district:"19", Block:"108", label:"BRIGHT  MATRIC HR.SEC. SCHOOL KARAMBAKKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22232", district:"19", Block:"25", label:"MRM INTERNATIONAL CBSE SCHOOL, SIVAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22233", district:"19", Block:"22", label:"SHEIK FATHIMA GIRLS MATRIC HR. SEC. SCHOOL, ARANTHANGI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22234", district:"23", Block:"294", label:"MOUNT LITERA ZEE SCHOOL, SIVAGANGAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22235", district:"23", Block:"94", label:"BODHI INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22236", district:"23", Block:"333", label:"BRITISH INTERNATIONAL SCHOOL CBSE, MADAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22237", district:"23", Block:"79", label:"ILM PUBLIC SCHOOL ILAYANGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22238", district:"23", Block:"56", label:"ST. JOSEPH INTERNATIONAL SCHOOL,KODIKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22239", district:"23", Block:"56", label:"SHREE CHINNAPPAN VIDHYA MANDHIR MATRIC. HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22240", district:"23", Block:"292", label:"GLOBAL INTERNATIONAL SCHOOL, M. KOVILPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22241", district:"14", Block:"43", label:"GURUDEV MATRIC  SCHOOL, POOTHIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22242", district:"14", Block:"303", label:"HINDU AIDED HIGHSCHOOL, SALISANTHAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"22243", district:"14", Block:"158", label:"R.C HIGH SCHOOL, KATHAKINARU", Sch_type:"Partially Aided", Category:"High School"},
    { key:"22244", district:"14", Block:"158", label:"GOVT HIGH SCHOOL, POIGAIKARAIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22245", district:"14", Block:"158", label:"VALLABA VID CBSE S, THIRUVIZHANPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22246", district:"14", Block:"158", label:"P.N. MATRICULATION HIGHER SECONDARY SCHOOL- MANGALAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22247", district:"14", Block:"183", label:"ARRAHMAAN INTERNATIONAL SCHOOL, CHINNASOORAKUNDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22248", district:"14", Block:"183", label:"JAZZ PUBLIC MATRIC HR SEC SCHOOL, MELUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22249", district:"14", Block:"283", label:"VISWA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL., ELUMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22250", district:"14", Block:"328", label:"SRI AUROBINDO MIRA UNIVERSAL SCHOOL, KEELAMATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22251", district:"14", Block:"328", label:"S.B.O.A.  SCHOOL, NAGAMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22252", district:"14", Block:"328", label:"K.L.N. VIDYALAYA CBSE Hr. Sec. SCHOOL, VIRAGANOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22253", district:"14", Block:"328", label:"THE INDIAN 3C MATRICULATION SCHOOL. , VILACHERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22254", district:"14", Block:"328", label:"SWAMI VIVEKANANDA VID. CBSE S, HARVIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22255", district:"14", Block:"374", label:"KEREN PUBLIC SCHOOL,  KARAYANPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22256", district:"14", Block:"374", label:"KEREN MATRIC SCHOOL, KARAYANPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22257", district:"14", Block:"306", label:"THAAI SCHOOL, T.VADIPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22258", district:"27", Block:"31", label:"JAIKRISH VIDHYASHRAM CBSE SCHOOL  - MUTHUKRISHNAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22259", district:"27", Block:"40", label:"SRI HAYAGREEVAR VIDHYA MANDIR MATRIC  HR.SEC. SCHOOL, SADAYALPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22260", district:"27", Block:"40", label:"G.D. MAT HSS - BODINAYAKANUR                                  ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22261", district:"27", Block:"55", label:"R R INTERNATIONAL SCHOOL - C .PUDUPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22262", district:"38", Block:"28", label:"IQRA MATRICULATION SCHOOL, ARUPPUKOTTAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22263", district:"38", Block:"28", label:"GREEN WISDOM MATRICULATION SCHOOL, ARUPPUKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22264", district:"38", Block:"407", label:"WISDOM WEALTH INTERNATIONAL SCHOOL, MARULOOTHU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22265", district:"38", Block:"321", label:"SARADHA VIDYALAYA MATRICULATION SCHOOL,  M.REDDIYAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22266", district:"38", Block:"298", label:"SRI AYYAN KENDRA VIDYALAYA INTERNATIONAL SCHOOL, KALANGAPERI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22267", district:"20", Block:"86", label:"THIYAKI DHARMAKKAN AMIRTHAM LITTLE ANGEL MATRICULATION SCHOOL, KANNIRAJAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22268", district:"20", Block:"266", label:"M.G. PUBLIC CBSE SCHOOL, PERAVOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22269", district:"20", Block:"169", label:"NATIONAL ACADEMY CENTRAL SCHOOL, PATTANAMKATHAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"22270", district:"28", Block:"350", label:"AMRITA VIDYALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22271", district:"28", Block:"350", label:"SRI LALITHA VIDHYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"22272", district:"24", Block:"6", label:"Bharathi Vidhya Mandhir Matriculation Higher Secondary School, Venkateswarapuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22273", district:"30", Block:"9", label:"VELS VIDHYALAYA CBSE SCHOOL ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22274", district:"30", Block:"9", label:"ST ASSISI PUBLIC SCHOOL CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22275", district:"30", Block:"9", label:"AROMA NIKETAN CBSE SCHOOL ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22276", district:"30", Block:"46", label:"CARMEL GARDEN MATRICULATION SCHOOL ODAIKARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22277", district:"30", Block:"46", label:"THE HOPE MATRICULATION HR.SEC.SCHOOL POOTHATHANKUDIERUPPU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22278", district:"30", Block:"174", label:"ST JOHN'S MATRICULATION SCHOOL,GANGAIKONDAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"22279", district:"30", Block:"174", label:"HILLEYA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22280", district:"30", Block:"221", label:"RADASAMY HS MELATHIRUVENKATANATHAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22281", district:"30", Block:"229", label:"RUKMANI NURSERY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22282", district:"24", Block:"307", label:"TREASURE ILAND INTERNATIONAL SCHOOL MAVADIKKALTHOPPU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22283", district:"30", Block:"385", label:"JONATHAN MEMORIAL MATRICULATION SCHOOL KAMARAJ NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22284", district:"11", Block:"354", label:"ST.JOSEPH INTERNATIONAL SCHOOL-ARALVAIMOZHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22285", district:"11", Block:"3", label:"St.MICHAEL'S HIGH SCHOOL - RAJAVOOR", Sch_type:"Partially Aided", Category:"High School"},
    { key:"22286", district:"11", Block:"263", label:"LITTLE CHAMPION CBSE SCHOOL, PAMBANVILAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22287", district:"11", Block:"355", label:"THE PACKIANATH PUBLIC SCHOOL- KATTATHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22288", district:"11", Block:"124", label:"ST.JOSEPH HIGH SCHOOL-MATHIRAVILAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"22289", district:"11", Block:"191", label:"LITTLE FLOWER CENTRAL SCHOOL-PUSHPAGIRI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22290", district:"11", Block:"191", label:"ST.MARY'S CBSE SCHOOL-MARIAGIRI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22291", district:"13", Block:"376", label:"R.P.S MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22292", district:"13", Block:"35", label:"K.E.T. MATRICULATION PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22293", district:"13", Block:"291", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Athimugam", Sch_type:"Government", Category:"Middle School"},
    { key:"22294", district:"13", Block:"291", label:"NTR INTERNATIONAL SCHOOL, BUKKASAGARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22295", district:"13", Block:"291", label:"ST.MARY'S ENGLISH SCHOOL - ICSE ", Sch_type:"Un-aided", Category:"High School"},
    { key:"22296", district:"13", Block:"291", label:"GOVERNMENT HIGH SCHOOL, SANAMAVU", Sch_type:"Government", Category:"High School"},
    { key:"22297", district:"13", Block:"78", label:"SRI CHAITANYA TECHNO SCHOOL DINNUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22298", district:"13", Block:"78", label:"ADITI VIDYA MANDIR MATRICULATION SCHOOL, THIMMASANDRAM, AVALAPALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22299", district:"13", Block:"78", label:"HOSUR PUBLIC  SCHOOL-KARAPALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22300", district:"13", Block:"122", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Denkanikottai", Sch_type:"Government", Category:"Middle School"},
    { key:"22301", district:"13", Block:"122", label:"SEVENTH DAY ADVENTIST INTERNATIONAL ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22302", district:"13", Block:"311", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- kakkadasam", Sch_type:"Government", Category:"Middle School"},
    { key:"22303", district:"13", Block:"177", label:"SRI SWAMY VIVEKANANDHA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22304", district:"33", Block:"87", label:"GHS VENMANAMBUDUR", Sch_type:"Government", Category:"High School"},
    { key:"22305", district:"33", Block:"87", label:"DMI ST.JOSEPH GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22306", district:"33", Block:"87", label:"MOTHER TERESA MEMORIAL RESIDENTIAL SPECIAL SCH", Sch_type:"Un-aided", Category:"High School"},
    { key:"22307", district:"33", Block:"252", label:"MAHAVEER RAJASTHANI INTERNATIONAL SCHOOL, GUDAPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22308", district:"33", Block:"252", label:"VELAMMAL VIDYALAYA SENNEERKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22309", district:"33", Block:"252", label:"SRI GURU AKKASAMY VELAMMAL MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22310", district:"33", Block:"252", label:"RBS NURSERY AND PRIMARY KONAMBEDU, AVADI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22311", district:"33", Block:"252", label:"AVADI MUNICIPAL HIGH SCHOOL PATTABIRAM RAILWAY SIDING", Sch_type:"Government", Category:"High School"},
    { key:"22312", district:"33", Block:"184", label:"GHS, MANALI NEW TOWN", Sch_type:"Government", Category:"High School"},
    { key:"22313", district:"33", Block:"403", label:"GHS, VEERAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22314", district:"33", Block:"403", label:"RAVINDRA BHARATHI CBSE SCHOOL, NERKUNDRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22315", district:"33", Block:"403", label:"THE SCHRAM ACADAMY NOLAMBUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22316", district:"33", Block:"403", label:"AMBATTUR MUNICIPAL HIGH SCHOOL KAMARAJAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22317", district:"33", Block:"403", label:"BRIGHT THE LEARNING CENTER ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22318", district:"33", Block:"403", label:"SANKALP - THE LEARNING CENTER", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22319", district:"33", Block:"403", label:"MUN HIGH SCH, PADI KUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"22320", district:"33", Block:"403", label:"SRISHTI SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22321", district:"33", Block:"403", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22322", district:"33", Block:"403", label:"THE SCRAM ACADEMY AYANAMPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22323", district:"33", Block:"403", label:"ACHARIYA SIKSHA MANDIR , ALAPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22324", district:"33", Block:"403", label:"VEDANTA ACADEMY SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22325", district:"33", Block:"403", label:"VELAMMAL VIDYALAYA (ANNEXURE) SR.SEC.SCHOOL. MEL AYANAMBAKKAM, CHENNAI 95.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22326", district:"33", Block:"403", label:"MANGALAM VIDHYASHRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22327", district:"33", Block:"260", label:"EVERWIN VIDHYASHRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22328", district:"33", Block:"260", label:"GHS, CHINNA SEKKADU", Sch_type:"Government", Category:"High School"},
    { key:"22329", district:"33", Block:"260", label:"PADMASHREE  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22330", district:"33", Block:"260", label:"GHS, KANNAPPASAMY NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"22331", district:"33", Block:"364", label:"GHS, V C R KANDIGAI", Sch_type:"Government", Category:"High School"},
    { key:"22332", district:"33", Block:"364", label:"GHS, VELANCHERI", Sch_type:"Government", Category:"High School"},
    { key:"22333", district:"3", Block:"69", label:"VELANKANNI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22334", district:"3", Block:"237", label:"N.S.P. VIDHYAPEETAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22335", district:"3", Block:"259", label:"MAHALASHMI VIDHYA MANDHIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22336", district:"3", Block:"195", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22337", district:"10", Block:"101", label:"Infini International CBSE School, Kalakkattur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22338", district:"10", Block:"101", label:"Victoria Matriculation School, Kanchipuram", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22339", district:"10", Block:"410", label:"Mahatma Gandhi Matric School, Kaliyanur", Sch_type:"Un-aided", Category:"High School"},
    { key:"22340", district:"10", Block:"410", label:"Kanchi Vani Vidyalaya, Enathur", Sch_type:"Un-aided", Category:"High School"},
    { key:"22341", district:"10", Block:"296", label:"GHS KATRAMBAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"22342", district:"10", Block:"296", label:"The Schram Academy, Irumgattukottai", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22343", district:"10", Block:"296", label:"Swami Vivekananda Vidya Peedam, Sriperumbudur", Sch_type:"Un-aided", Category:"High School"},
    { key:"22344", district:"10", Block:"146", label:"Sri Ayyappan Vidyal Matric School, Kovur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22345", district:"10", Block:"146", label:"Jain Public School, Thirumudivakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22346", district:"10", Block:"146", label:"BUDDING MINDS INTERNATIONAL SCHOOL, Manimangalam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22347", district:"10", Block:"146", label:"Velammal Vidyalaya, Mangadu", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22348", district:"10", Block:"146", label:"Maharishi vidhya mandir", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22349", district:"10", Block:"146", label:"Shree Niketan Patasala CBSE School, Varatharajapuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22350", district:"2", Block:"329", label:"GGHS, Manampathy", Sch_type:"Government", Category:"High School"},
    { key:"22351", district:"2", Block:"329", label:"HLC International School, Karanai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22352", district:"2", Block:"329", label:"GHS, Siruthavoor", Sch_type:"Government", Category:"High School"},
    { key:"22353", district:"2", Block:"114", label:"Sri Krish International School, Rathinamangalam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22354", district:"2", Block:"114", label:"Christwood High School, Venkadamangalam", Sch_type:"Un-aided", Category:"High School"},
    { key:"22355", district:"2", Block:"114", label:"SRM Public School, Nandhivaram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22356", district:"2", Block:"114", label:"Loknayak Jayaprakash Narayan Vidyalaya, Guduvancheri", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22357", district:"2", Block:"322", label:"ST MARY'S ACADEMY , MAMALLAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22358", district:"2", Block:"300", label:"Noble Academy ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22359", district:"2", Block:"300", label:"SRI SANKARA GLOBAL ACADEMY, PAMMAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22360", district:"2", Block:"300", label:"UNITY MATRIC SCHOOL, ADAMBAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22361", district:"2", Block:"300", label:"Shikshaa Public School, Hasthinapuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22362", district:"2", Block:"300", label:"Arul Jothi Public CBSE School, Old Pallavaram", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22363", district:"2", Block:"162", label:"Sai Shakthi Vidhya Mandhir School, Meyyur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22364", district:"2", Block:"162", label:"GHS 150 ESSUR", Sch_type:"Government", Category:"High School"},
    { key:"22365", district:"2", Block:"299", label:"AKG Public School, Jalladianpet", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22366", district:"2", Block:"299", label:"Sri Chaitanya Techno Scool, Kovilamabakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"22367", district:"2", Block:"299", label:"Sri Chaitanya Techno School, Thuraipakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"22368", district:"2", Block:"299", label:"Al - Fajr International School, Kottivakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22369", district:"2", Block:"299", label:"HORIZON INTERNATIONAL ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22370", district:"2", Block:"299", label:"Ravindra Bharathi Global School, Mudichur", Sch_type:"Un-aided", Category:"High School"},
    { key:"22371", district:"2", Block:"299", label:"Sheshavalli Public School, Mudichur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22372", district:"2", Block:"299", label:"SRI CHAITHANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22373", district:"2", Block:"299", label:"NETHAJI VIDHYALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22374", district:"2", Block:"299", label:"NPS International School, Perumbakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22375", district:"2", Block:"299", label:"SHREE BHARATH VIDYAASHRAM , THIRUVANCHERY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22376", district:"2", Block:"299", label:"Grace International School, Chitlapakkam", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22377", district:"2", Block:"299", label:"Velammal Vidyalaya School, Sholinganallur", Sch_type:"Un-aided", Category:"High School"},
    { key:"22378", district:"21", Block:"21", label:"NAVY CHILDREN'S SCHOOL", Sch_type:"Central Govt", Category:"High School"},
    { key:"22379", district:"21", Block:"210", label:"GOVT HIGH SCHOOL MELKALATHUR", Sch_type:"Government", Category:"High School"},
    { key:"22380", district:"36", Block:"113", label:"SRI SRINIVASA VIDHYASHARAM CBSE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22381", district:"21", Block:"290", label:"SKV INTERNATIONAL PUBLIC SCHOOL ( CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"22382", district:"21", Block:"290", label:"GOVT HIGH SCHOOL MELVEERANAM", Sch_type:"Government", Category:"High School"},
    { key:"22383", district:"21", Block:"359", label:"SRI RAMAKRISHNA VIDHYALAYA NATIONAL INSTITUTE OF OPEN SCHOOLING", Sch_type:"Un-aided", Category:"High School"},
    { key:"22384", district:"21", Block:"24", label:"INDO AUSTRALIAN ACADEMY OF EXCELLENCE", Sch_type:"Un-aided", Category:"High School"},
    { key:"22385", district:"36", Block:"393", label:"VELAMMAL BODHI CAMPUS ( CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22386", district:"36", Block:"394", label:"GOVT MPL MUSLIM HIGH SCHOOL, R.N.PALAYAM. VELLORE", Sch_type:"Government", Category:"High School"},
    { key:"22387", district:"36", Block:"105", label:"DREAMS MATRICULATION SCHOOL, KANIYAMBADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22388", district:"36", Block:"12", label:"SPRING DAYS CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22389", district:"36", Block:"84", label:"GHS  PALLATHUR", Sch_type:"Government", Category:"High School"},
    { key:"22390", district:"36", Block:"74", label:"GOVT HIGH SCHOOL KALIYAMMANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22391", district:"36", Block:"243", label:"GHS MGR NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"22392", district:"31", Block:"155", label:"S.R.V HI TECH MATRICULATION SCHOOL, MANIYARAKUPPAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22393", district:"31", Block:"155", label:"VIVEKA MATRICULATION SCHOOL, MARAPATTU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22394", district:"31", Block:"5", label:"KALAM VIDYALAYA MATRICULATION SCHOOL, VANIYAMBADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22395", district:"31", Block:"5", label:"GOVT HIGH SCHOOL B.NAICKANUR", Sch_type:"Government", Category:"High School"},
    { key:"22396", district:"31", Block:"208", label:"MUNCIPAL MUSLIM BOYS HIGH SCHOOL GANDHI NAGAR VANIYAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"22397", district:"31", Block:"82", label:"GOVT HIGH SCHOOL KUNNATHUR", Sch_type:"Government", Category:"High School"},
    { key:"22398", district:"31", Block:"82", label:"GOVT HIGH SCHOOL KONAPATTU", Sch_type:"Government", Category:"High School"},
    { key:"22399", district:"31", Block:"362", label:"EKLAVYA MODEL RESIDENTIAL HR.SEC SCHOOL,PUDURNADU", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22400", district:"6", Block:"58", label:"SASSI GNANODAYA ACADEMY, NALLANAHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22401", district:"6", Block:"235", label:"VIVEKANANDA VIDYALAYA MATRIC SCHOOL, JANGAMAIYANOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22402", district:"6", Block:"235", label:"LOTUS MATRIC SCHOOL, PENNAGARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22403", district:"6", Block:"235", label:"NETAJI SUBHASH CHANDRA BOSE AVASIYA VIDYALAYA, PENNAGARAM", Sch_type:"Government", Category:"Middle School"},
    { key:"22404", district:"6", Block:"235", label:"AASAN HIGH SCHOOL, KOTTAYUR, SATHIYANATHAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22405", district:"6", Block:"218", label:"SRI VENKATESHWARA  MHSS, CHIKKADORANABETTAM, MARANDAHALLI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22406", district:"6", Block:"218", label:"RADHA MATRIC SCHOOL, VELLICHANDHAI, PALACODE", Sch_type:"Un-aided", Category:"High School"},
    { key:"22407", district:"6", Block:"218", label:"GHS VALAITHOTTAM", Sch_type:"Government", Category:"High School"},
    { key:"22408", district:"6", Block:"109", label:"GHS THUMBALAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"22409", district:"6", Block:"187", label:"GHS SENKUTTAI", Sch_type:"Government", Category:"High School"},
    { key:"22410", district:"6", Block:"187", label:"GHS VEPPILAIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22411", district:"34", Block:"395", label:"GHS KUTHANUR", Sch_type:"Government", Category:"High School"},
    { key:"22412", district:"34", Block:"48", label:"GHS-ENATHAVADI", Sch_type:"Government", Category:"High School"},
    { key:"22413", district:"34", Block:"27", label:"GHS-SANGEETHAVADI", Sch_type:"Government", Category:"High School"},
    { key:"22414", district:"34", Block:"47", label:"WIN PARK VIDHYALAYA MATRICULATION  SCHOOL, CHETPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"22415", district:"34", Block:"358", label:"K-ZONE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22416", district:"34", Block:"365", label:"GHS-THALAYAMPALLAM", Sch_type:"Government", Category:"High School"},
    { key:"22417", district:"34", Block:"365", label:"SKP VANITHA MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22418", district:"34", Block:"365", label:"SKP VANITHA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22419", district:"34", Block:"365", label:"RS INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22420", district:"34", Block:"44", label:"SRISHTI HIGHER SEC  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22421", district:"34", Block:"44", label:"SIGARAM INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22422", district:"34", Block:"44", label:"SHREE NIVEDHA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22423", district:"34", Block:"312", label:"SRI JAYAM MATRIC.HR.SEC  SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22424", district:"34", Block:"312", label:"GHS-THENMUDIYANUR", Sch_type:"Government", Category:"High School"},
    { key:"22425", district:"34", Block:"80", label:"EKLAVYA MODEL RESIDENTIAL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22426", district:"37", Block:"181", label:"HOLY ANGEL MATRICULATION SCHOOL KUDUVAMPOONDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22427", district:"37", Block:"401", label:"GOVERNMENT HIGH SCHOOL KUNDALAPULIYUR", Sch_type:"Government", Category:"High School"},
    { key:"22428", district:"37", Block:"401", label:"VELA SPECIAL SCHOOL  LAKSHMIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22429", district:"37", Block:"130", label:"JOHN DEWEY INTERNATIONAL SCHOOL (CBSE) PANANKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22430", district:"9", Block:"95", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Eduthavainatham", Sch_type:"Government", Category:"Middle School"},
    { key:"22431", district:"9", Block:"95", label:"GOVERNMENT HIGH SCHOOL THENKEERANUR", Sch_type:"Government", Category:"High School"},
    { key:"22432", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL  VADATHORASALUR", Sch_type:"Government", Category:"High School"},
    { key:"22433", district:"9", Block:"347", label:"MOUNT PARK PUBLIC SENIOR SECONDARY  SCHOOL (CBSE) VADATHORASALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22434", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL PANAYANGAL", Sch_type:"Government", Category:"High School"},
    { key:"22435", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL NINNAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"22436", district:"9", Block:"327", label:"GOVERNMENT HIGH SCHOOL PANDUR", Sch_type:"Government", Category:"High School"},
    { key:"22437", district:"22", Block:"246", label:"GHS VELLALAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22438", district:"22", Block:"163", label:"GHS MATTAIYAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22439", district:"22", Block:"226", label:"GHS PARANATTAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"22440", district:"22", Block:"273", label:"GHS KAMINAICKENPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22441", district:"22", Block:"273", label:"SRI CHAITANYA TECHNO SCHOOL,PERUMAL MALAI ROAD", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22442", district:"22", Block:"273", label:"THE INDIAN PUBLIC SCHOOL, CHETTICHAVADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22443", district:"22", Block:"34", label:"GHS MASINAICKENPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22444", district:"22", Block:"274", label:"MHS., KALARAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22445", district:"17", Block:"201", label:"PARK PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22446", district:"17", Block:"132", label:"GOVT.EKLAVYA MODEL RESIDENTIAL HR.SEC.SCHOOL, SENKARAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22447", district:"17", Block:"186", label:"SHRI SENTHUR INTERNATIONAL SCHOOL (CBSE) MOHANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22448", district:"17", Block:"254", label:"GHS THATHATHIRIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22449", district:"17", Block:"224", label:"MHS CHINNAPPANAYAKENPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22450", district:"17", Block:"224", label:"SRI VANI INTERNATIONAL SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"22451", district:"17", Block:"224", label:"VIGNESH VIDHYAA BHAVAN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22452", district:"17", Block:"63", label:"GHS KUMARAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"22453", district:"17", Block:"232", label:"MALAR PUBLIC SCHOOL, PARAMATHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22454", district:"17", Block:"232", label:"GOVERNMENT HIGH SCHOOL KUPPUCHIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22455", district:"8", Block:"20", label:"VIVEGAM VIDHYABHAVAN MATRICULATION SCHOOL, VEMPATHI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22456", district:"8", Block:"20", label:"GOVERNMENT HIGH SCHOOL, SINTHAGOUNDANPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22457", district:"8", Block:"37", label:"TRIPLEE A MATRIC & PLAY SCHOOL, KURUPPANAICKANPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22458", district:"32", Block:"57", label:"PRAKRUTHI PUBLIC SCHOOL, PANCHAPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22459", district:"32", Block:"57", label:"GOVERNMENT HIGH SCHOOL, AACHIYUR", Sch_type:"Government", Category:"High School"},
    { key:"22460", district:"32", Block:"57", label:"SRI KRISHNA VIDHYALAYA SCHOOL, SAKTHI NAGAR,DHARAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22461", district:"8", Block:"71", label:"GOVERNMENT HIGH SCHOOL, P.VELLALAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22462", district:"8", Block:"71", label:"SHREE GURUKULAM PUBLIC SCHOOL,MOOLAVAIKKAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22463", district:"8", Block:"71", label:"GOVERNMENT HIGH SCHOOL, MANIYAKARANPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"22464", district:"32", Block:"104", label:"GHS KEERANUR", Sch_type:"Government", Category:"High School"},
    { key:"22465", district:"32", Block:"104", label:"GHS THAMMAREDDIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22466", district:"8", Block:"202", label:"GOVERNMENT HIGH SCHOOL, KUMMAKKALIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22467", district:"8", Block:"202", label:"GOVERNMENT HIGH SCHOOL, POTHAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22468", district:"8", Block:"281", label:"GOVERNMENT HIGH SCHOOL, GUJJAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22469", district:"8", Block:"310", label:"GOVERNMENT HIGH SCHOOL, SUJJILKARAI", Sch_type:"Government", Category:"High School"},
    { key:"22470", district:"32", Block:"378", label:"GOVERNMENT HIGH SCHOOL, KARUMANCHIRAI", Sch_type:"Government", Category:"High School"},
    { key:"22471", district:"26", Block:"53", label:"ST. JOSEPH'S ACADEMY, COONOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22472", district:"26", Block:"53", label:"UDHAVI, CENTRE FOR CWSN ALWARPET COONOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22473", district:"26", Block:"72", label:"GOVERNMENT HIGH SCHOOL PONNUR", Sch_type:"Government", Category:"High School"},
    { key:"22474", district:"4", Block:"156", label:"VIAN VEENAI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22475", district:"4", Block:"156", label:"GOVERNMENT HIGH SCHOOL-KANDEGOUNDEN CHAVADI", Sch_type:"Government", Category:"High School"},
    { key:"22476", district:"4", Block:"242", label:"AKSHARAM INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22477", district:"4", Block:"242", label:"THE OOTY PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22478", district:"4", Block:"242", label:"THE FIRST SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22479", district:"4", Block:"245", label:"PEEPAL PRODIGY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22480", district:"4", Block:"279", label:"JEEVANS NURSERY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22481", district:"4", Block:"279", label:"ADITHYA PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22482", district:"4", Block:"349", label:"IDHAZHL NATIONAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22483", district:"4", Block:"349", label:"VISHWANKAR PUBLIC SCHOOL POOLUVAPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22484", district:"4", Block:"13", label:"GHS PERIYAPODHU", Sch_type:"Government", Category:"High School"},
    { key:"22485", district:"32", Block:"73", label:"THE INDIAN MODEL SCHOOL, KOTTAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22486", district:"32", Block:"371", label:"SHRI VENKATESHWARA VIDYALAYA DEVANURPUDUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22487", district:"4", Block:"386", label:"GOVERNMENT HIGH SCHOOL WATER FALLS EAST", Sch_type:"Government", Category:"High School"},
    { key:"22488", district:"4", Block:"107", label:"TRG MATRICULATION SCHOOL, BUJANGANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22489", district:"4", Block:"107", label:"MUNICIPAL HIGH SCHOOL MANI NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"22490", district:"32", Block:"223", label:"GHS KALINATHAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22491", district:"32", Block:"250", label:"ARUDHRA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22492", district:"4", Block:"302", label:"GOVERNMENT HIGH SCHOOL KADAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"22493", district:"32", Block:"363", label:"VRIKSHAA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22494", district:"32", Block:"363", label:"MHS K.V.R NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"22495", district:"32", Block:"363", label:"THE HOME SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22496", district:"7", Block:"36", label:"SREE RAMS MATRICULATION SCHOOL, KONNAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22497", district:"7", Block:"36", label:"SRI GURUKRISHNA MATRICULATION SCHOOL, BATLAGUNDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22498", district:"7", Block:"59", label:"GREEN VALLEY PUBLIC SCHOOL, PALLAPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22499", district:"7", Block:"59", label:"GURUKUL SPECIAL SCHOOL FOR MR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22500", district:"7", Block:"75", label:"HAYAGREEVAR'S KONGUNAD ACADEMY SENIOR SECONDARY SCHOOL, D.GUDALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22501", district:"7", Block:"75", label:"GHS, K.ANAIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22502", district:"7", Block:"207", label:"GHS, CHITHIRAIKOUNDANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22503", district:"7", Block:"207", label:"GHS, PERIYUR PATTI", Sch_type:"Government", Category:"High School"},
    { key:"22504", district:"7", Block:"207", label:"RAMSUN'S INTERNATIONAL SCHOOL, NATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22505", district:"7", Block:"212", label:"GHS,KOZHINJIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22506", district:"7", Block:"219", label:"HAYAGRIVAR MATRIC HIGHER SECONDARY SCHOOL, RUKKUVARPATTY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22507", district:"7", Block:"275", label:"GHS, VEERACHINNAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22508", district:"7", Block:"352", label:"SHRI VIDHYA MANDHIR(CBSE), PUSHPATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22509", district:"7", Block:"380", label:"GHS, THANGAMMAPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22510", district:"7", Block:"60", label:"SRI KAMARAJAR MATRICULATION SCHOOL, DINDIGUL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22511", district:"7", Block:"60", label:"DNU SMBM PUBLIC SCHOOL(CBSE), DINDIGUL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22512", district:"12", Block:"23", label:"GOVERNMENT HIGH SCHOOL, K . SEETHAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22513", district:"12", Block:"140", label:"GHS, DESIYAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"22514", district:"12", Block:"88", label:"GHS, KADAVOOR", Sch_type:"Government", Category:"High School"},
    { key:"22515", district:"12", Block:"142", label:"SIDDHARTH PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22516", district:"12", Block:"142", label:"SHREE SAI VIDHYALAYA CBSE SCHOOL,V.PUDUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22517", district:"12", Block:"142", label:"SITA RAJARAM PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22518", district:"12", Block:"348", label:"GHS,BOMMANAYAKKANPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22519", district:"29", Block:"152", label:"GHS, LALGUDI", Sch_type:"Government", Category:"High School"},
    { key:"22520", district:"29", Block:"171", label:"GHS, YAGAPUDAIYANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22521", district:"29", Block:"171", label:"SIGMA SCHOOL, MANIKANDAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22522", district:"29", Block:"171", label:"GOVT. HIGH SCHOOL, OLAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"22523", district:"29", Block:"171", label:"AKARA WORLD SCHOOL (CBSE), K K NAGAR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22524", district:"29", Block:"171", label:"GOVT. HIGH SCHOOL, EDAMALAIPATTI PUDUR", Sch_type:"Government", Category:"High School"},
    { key:"22525", district:"29", Block:"176", label:"GHS,PALUVANCHI", Sch_type:"Government", Category:"High School"},
    { key:"22526", district:"29", Block:"176", label:"SETHU MATRICULATION HIGHER SECONDARY SCHOOL, THUVARANKURICHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22527", district:"29", Block:"192", label:"GHS PERIYAKODUNTHURAI", Sch_type:"Government", Category:"High School"},
    { key:"22528", district:"29", Block:"192", label:"GHS CHINNA KODUNTHURAI", Sch_type:"Government", Category:"High School"},
    { key:"22529", district:"29", Block:"343", label:"BVM GLOBAL TRICHY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22530", district:"29", Block:"343", label:"KENDRIYA VIDYALAYA GOLDEN ROCK (SR) TRICHY", Sch_type:"Central Govt", Category:"High School"},
    { key:"22531", district:"29", Block:"353", label:"SHAANTHI NIKKETHAN MATRICULATION SCHOOL ALAGARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22532", district:"29", Block:"357", label:"GHS, VENGADATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"22533", district:"29", Block:"357", label:"MAHATHMA SCHOOL OF EXCELLENCE,MURUGUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22534", district:"29", Block:"317", label:"GHS,V.GANESAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22535", district:"29", Block:"373", label:"SRI RAMAKRISHNA VIDYALAYA MATRICULATION SCHOOL, UPPILIYAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22536", district:"1", Block:"305", label:"GOKILAMBAL MATRICULATION HIGHER SECONDARY SCHOOL KUZHAVADAIYAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22537", district:"1", Block:"305", label:"GOKILAMBAL CBSE SCHOOL KUZHAVADAIYAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22538", district:"18", Block:"400", label:"GHS PUDHUVETTAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"22539", district:"18", Block:"399", label:"GHS PERIYAMMAPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22540", district:"18", Block:"236", label:" RAMAKRISHNA VIDHYABHAVAN CBSE PERAMBALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22541", district:"1", Block:"286", label:"GHS AYANTHATHANUR", Sch_type:"Government", Category:"High School"},
    { key:"22542", district:"18", Block:"7", label:"GHS THERANI", Sch_type:"Government", Category:"High School"},
    { key:"22543", district:"1", Block:"16", label:"GHS SILAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"22544", district:"1", Block:"16", label:"GHS ELAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"22545", district:"1", Block:"81", label:"N.R.PUBLIC SCHOOL UDAYARPLAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22546", district:"1", Block:"81", label:"GOVERNMENT HIGH SCHOOL T.CHOZHANKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"22547", district:"1", Block:"346", label:"GHS AYANSUTHAMALLI", Sch_type:"Government", Category:"High School"},
    { key:"22548", district:"5", Block:"54", label:"GOVERNMENT HIGH SCHOOL - AZHAGIANATHAM", Sch_type:"Government", Category:"High School"},
    { key:"22549", district:"5", Block:"17", label:"GHS-MALIGAIMEDU", Sch_type:"Government", Category:"High School"},
    { key:"22550", district:"5", Block:"17", label:"EDIFY SCHOOL, VAZHAPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22551", district:"5", Block:"148", label:"SRK INTERNATIONAL  SENIOR SECONDARY SCHOOL-KEEZHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22552", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL-KO.PAVAZHANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"22553", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL SATHUKOODAL", Sch_type:"Government", Category:"High School"},
    { key:"22554", district:"5", Block:"406", label:"VIRUDHAI  VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, VRIDDHACHALAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22555", district:"5", Block:"199", label:"SRI RAMAKRISHNA MATRIC HIGHER SECONDARY SCHOOL - N.NARAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22556", district:"5", Block:"170", label:"GREEN PARK MATRIC HIGHER SECONDARY  SCHOOL,ELUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22557", district:"5", Block:"170", label:"GREEN PARK INTERNATIONAL SCHOOL, ELUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22558", district:"5", Block:"170", label:" STEPHEN HIGH SCHOOL-KORAKKAVADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22559", district:"5", Block:"233", label:"GHS-POOVALAI", Sch_type:"Government", Category:"High School"},
    { key:"22560", district:"5", Block:"120", label:"GOVERNMENT HIGH SCHOOL- K.ADOOR", Sch_type:"Government", Category:"High School"},
    { key:"22561", district:"5", Block:"115", label:"GHS-VANAMADEVI", Sch_type:"Government", Category:"High School"},
    { key:"22562", district:"5", Block:"115", label:"CS JAIN  MATRICULATION SCHOOL-THIRUMUTTAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22563", district:"16", Block:"389", label:"GOVERNMENT HIGH SCHOOL, ADHANUR", Sch_type:"Government", Category:"High School"},
    { key:"22564", district:"16", Block:"389", label:"GOVERNMENT HIGH SCHOOL, RAJANKATTALAI", Sch_type:"Government", Category:"High School"},
    { key:"22565", district:"16", Block:"389", label:"GOVERNMENT HIGH SCHOOL PANNAL", Sch_type:"Government", Category:"High School"},
    { key:"22566", district:"15", Block:"178", label:"GOVERNMENT HIGH SCHOOL KEEZHATHUKUDI", Sch_type:"Government", Category:"High School"},
    { key:"22567", district:"15", Block:"178", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Pallavarayanpettai", Sch_type:"Government", Category:"Middle School"},
    { key:"22568", district:"15", Block:"178", label:"GOLDEN PUBLIC CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22569", district:"15", Block:"151", label:"GOVERNMENT HIGH SCHOOL, THERIZHANDUR", Sch_type:"Government", Category:"High School"},
    { key:"22570", district:"35", Block:"134", label:"UAHSS OYSTER  LEARNING   INITIATIVE  MATRICULATION SCHOOL  ,AMMAIYAPPAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22571", district:"35", Block:"205", label:"GHS,PILLUR", Sch_type:"Government", Category:"High School"},
    { key:"22572", district:"35", Block:"205", label:"GHS,MENANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"22573", district:"35", Block:"173", label:"GHS,VELUKUDI", Sch_type:"Government", Category:"High School"},
    { key:"22574", district:"35", Block:"334", label:"UAMS ,SVS INTERNATIONAL CBSC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22575", district:"25", Block:"313", label:"BLOSSOM BLUEBELLS ACADEMY -THIRUKKANURPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22576", district:"25", Block:"313", label:"VELAMMAL BODHI CAMPUS  SCHOOL (CBSE)- NILAGIRI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22577", district:"25", Block:"216", label:"SHANMUGAM MANDIR MATRIC HSS-PALAMPUTHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22578", district:"25", Block:"216", label:"SEETHARAM INTERNATIONAL  SCHOOL - PALAMPUTHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22579", district:"25", Block:"144", label:"GHS - KEELAKORUKKAI", Sch_type:"Government", Category:"High School"},
    { key:"22580", district:"25", Block:"144", label:"EQUITAS GURUKUL MATRICULATION SCHOOL - KUMBAKONAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22581", district:"25", Block:"239", label:"DELTA INTERNATIONAL SCHOOL - KURICHI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22582", district:"25", Block:"287", label:"PIONEER SCHOOL-CHOKKANATHAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22583", district:"25", Block:"287", label:"GHS -KUPPATHEVAN", Sch_type:"Government", Category:"High School"},
    { key:"22584", district:"19", Block:"18", label:"GOVT HIGH SCHOOL.IRUNTHIRAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22585", district:"19", Block:"405", label:"GOVERNMENT HIGH SCHOOL AKKALNAYAKKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22586", district:"19", Block:"405", label:"GOVERNMENT HIGH SCHOOL MALAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22587", district:"19", Block:"405", label:"GOVERNMENT HIGH SCHOOL ATHIPALLAM", Sch_type:"Government", Category:"High School"},
    { key:"22588", district:"19", Block:"67", label:"GOVT HIGH SCHOOL PUNALKULAM", Sch_type:"Government", Category:"High School"},
    { key:"22589", district:"19", Block:"108", label:"GOVT HIGH SCHOOL  KOLANTHIRANPATTU ", Sch_type:"Government", Category:"High School"},
    { key:"22590", district:"19", Block:"167", label:"GOVT HIGH SCHOOL PONNAGARAM", Sch_type:"Government", Category:"High School"},
    { key:"22591", district:"19", Block:"167", label:"GOVT HIGH SCHOOL PUDUKUDI", Sch_type:"Government", Category:"High School"},
    { key:"22592", district:"23", Block:"294", label:"PRIST UNIVERSITY PUBLIC SCHOOL,ARASANOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22593", district:"23", Block:"294", label:"ST. ANNE'S KARUNALAYA RESIDENTIAL SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22594", district:"23", Block:"94", label:"ARIBALA MATRICULATION SCHOOL  PALKULAM MARAVAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22595", district:"23", Block:"272", label:"GHS,SAKKAVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"22596", district:"23", Block:"331", label:"SKS CBSE SCHOOL,THIRUPPATHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22597", district:"14", Block:"4", label:"THAAI MATRIC HR SEC SCHOOL, ALANGANALLUR ", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22598", district:"14", Block:"43", label:"GOVT KALLAR HIGH SCHOOL, AYYANARKULAM", Sch_type:"Government", Category:"High School"},
    { key:"22599", district:"14", Block:"97", label:"GOVT HIGH SCHOOL, CHINNA ULAGANI", Sch_type:"Government", Category:"High School"},
    { key:"22600", district:"14", Block:"97", label:"V.T.MANIKANDAN MATRIC SCHOOL, K.VELLAKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22601", district:"14", Block:"303", label:"GOVT HIGH SCHOOL.V.CHATRAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22602", district:"14", Block:"158", label:"MAHATMA GLOBAL GATEWAY CBSE SCHOOL, VEERAPANCHAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22603", district:"14", Block:"158", label:"MAHATMA GLOBAL GATEWAY INTERNATION S, VEERAPANCHAN", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22604", district:"14", Block:"161", label:"JAIN VIDYALAYA  SCHOOL, THIRUPPALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22605", district:"14", Block:"183", label:"MAHATMA MONTESSORI SCHOOL, ALAGARKOIL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22606", district:"14", Block:"283", label:"GOVT HIGH SCHOOL THULLUKUTTINAICKANUR", Sch_type:"Government", Category:"High School"},
    { key:"22607", district:"14", Block:"324", label:"ST.JOSEPH MATRICULATION SCHOOL, THIRALI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22608", district:"14", Block:"328", label:"VIKASA PROGRASSIVE SCHOOL, NAGAMALAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22609", district:"14", Block:"328", label:"NMS VIJAYALAKSHMI SANJEEVIMALAYAN KALVIYAGAM, NAGAMALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22610", district:"14", Block:"374", label:"GOVT KALLAR HIGH SCHOOL, KEERIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22611", district:"27", Block:"40", label:"R.P. HS - MEENAKSHIPURAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"22612", district:"27", Block:"40", label:"SCISM SENIOR SECONDARY  SCHOOL (CBSE) -  MELACHOKKANATHAPURAM,  BODINAYAKANUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22613", district:"27", Block:"49", label:"SHRI VIKASA VIDYALAYA   PUBLIC SCHOOL (CBSE)- CHINNAMANUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22614", district:"27", Block:"241", label:"MOUNT ZION ACADEMY CBSE SCHOOL - G.MEENATCHIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22615", district:"27", Block:"241", label:"SHRI ROSY VIDYAALAYA - LAKSHMIPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22616", district:"27", Block:"241", label:"GOVT HS -  ERUMALAINAYAKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22617", district:"27", Block:"241", label:"GOVT HS -  AMMAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22618", district:"38", Block:"407", label:"SHRI VISHWAKARMA SEVA VIDYALAYA, VIRUDHUNAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22619", district:"38", Block:"407", label:"G.H.S.,SENNELKUDI", Sch_type:"Government", Category:"High School"},
    { key:"22620", district:"38", Block:"264", label:"GOVERNMENT HIGH SCHOOL, SIVAKAMIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22621", district:"38", Block:"264", label:"ST.JOSEPH INTERNATIONAL SCHOOL RAJAPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22622", district:"38", Block:"298", label:"GOVERNMENT HIGH SCHOOL, LAKSHMIAPURAMPUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"22623", district:"38", Block:"298", label:"YASHAS ENGLISH SCHOOL.,MALLI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22624", district:"38", Block:"411", label:"G.H.S, THULUKKAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22625", district:"38", Block:"295", label:"G.H.S.,SATHYANAGAR", Sch_type:"Government", Category:"High School"},
    { key:"22626", district:"20", Block:"231", label:"UDAIYAR MATRICULATION SCHOOL, MELAKAVANOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22627", district:"20", Block:"231", label:"GANAPATHY CHETTIAR VIDYALAYA, PARAMAKUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22628", district:"20", Block:"231", label:"DR. ABDUL KALAM PUBLIC SCHOOL, PUTHU NAGAR, PARAMAKUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22629", district:"20", Block:"41", label:"MEENATCHI MATRIC HIGHER SECONDARY SCHOOL, CHINNA EDAMBADAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22630", district:"20", Block:"266", label:"GOVERNMENT HIGH SCHOOL, DEVIPATTINAM SOUTH", Sch_type:"Government", Category:"High School"},
    { key:"22631", district:"20", Block:"332", label:"AL BAYYINAH MATRIC SCHOOL, KILAKARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22632", district:"20", Block:"169", label:"CHRIST THE KING INTERNATIONAL SCHOOL, MEYYAMPULI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22633", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL, RAMESWARAM ROAD", Sch_type:"Government", Category:"High School"},
    { key:"22634", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL, VANIYANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"22635", district:"28", Block:"111", label:"JOS MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22636", district:"28", Block:"297", label:"THE VIKASA INTERNATIONAL SCHOOL, SAWYERPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22637", district:"28", Block:"138", label:"HOLY TRINITY PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22638", district:"28", Block:"138", label:"VELS VIDHYALAYA .CBSE SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22639", district:"28", Block:"138", label:"VEERABAGU VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22640", district:"28", Block:"217", label:"GHS AKKANAYAKKANPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22641", district:"28", Block:"257", label:"GHS KARUPPUR", Sch_type:"Government", Category:"High School"},
    { key:"22642", district:"24", Block:"89", label:"GHS NALANKATTALAI", Sch_type:"Government", Category:"High School"},
    { key:"22643", district:"24", Block:"91", label:"BRILLIANT MATRICULATION  KADAYANALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22644", district:"24", Block:"91", label:"BEST INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22645", district:"24", Block:"91", label:"NEW CAMBRIDGE MATRICULATION HIGHER SECONDARY SCHOOL, MANGALAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22646", district:"24", Block:"150", label:"GOVT HIGH SCHOOL  PERUNKOTTUR", Sch_type:"Government", Category:"High School"},
    { key:"22647", district:"30", Block:"174", label:"GHS RASTHA", Sch_type:"Government", Category:"High School"},
    { key:"22648", district:"24", Block:"180", label:"ST. ROSSELLOS MATRIC SCHOOL, AYALPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22649", district:"24", Block:"180", label:"GOVERNMENT HIGH SCHOOL, MELANEELITHANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"22650", district:"30", Block:"221", label:"SRI JAYENDRA SWAMIGAL VIDHYA KENDRA SCHOOL VM CHATRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22651", district:"30", Block:"221", label:"MUTHAMIL PUBLIC SCHOOL, MUNNERPALLAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22652", district:"30", Block:"229", label:"STACG HITECH SCHOOL CBSE IDAIKAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22653", district:"30", Block:"262", label:"OUR LADY OF SNOWS MATRIC HR. SEC. SCHOOL T.KALLIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22654", district:"24", Block:"276", label:"GHS MADATHUPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22655", district:"24", Block:"276", label:"SRI LAKSHMI MATRICULATION SCHOOL ARIYANAYAGIPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22656", district:"24", Block:"307", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22657", district:"30", Block:"385", label:"SACRED HEART PUBLIC SCHOOL PANAGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22658", district:"30", Block:"385", label:"SARASWATHY VIDYALAYA CBSE THERKKU VALLIYOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22659", district:"24", Block:"388", label:"VEERAMARUDU SAHODARARHAL VIDYALAYA NELKATTUMSEVAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22660", district:"24", Block:"388", label:"THANGAPALAM MATRICULATION HSS SUBRAMANIAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22661", district:"30", Block:"222", label:"ROSE MARY MODEL PUBLIC SCHOOL RAMACHANDRA NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22662", district:"11", Block:"263", label:"ZION INTERNATIONAL SCHOOL-ALAMPARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22663", district:"11", Block:"149", label:"ST.PAULA MONTAL SCHOOL,MUKKALAMPADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22664", district:"11", Block:"182", label:"MAR IVANIOS CBSE SCHOOL,KUZHITHURAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22665", district:"11", Block:"182", label:"GOVERNMENT HIGH SCHOOL,UTHIRAMCODE", Sch_type:"Government", Category:"High School"},
    { key:"22666", district:"11", Block:"124", label:"BETHLAHEM HILLSIDE INTERNATIONAL SCHOOL-KARUNGAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22667", district:"11", Block:"124", label:"RPA CENTRAL SCHOOL, MAMOOTUKADAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22668", district:"13", Block:"376", label:"ADHIYAMAN PUBLIC SCHOOL, UTHANGARAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22669", district:"13", Block:"376", label:"SRS MATRICULATION HR SEC SCHOOL, ELACHOOR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22670", district:"13", Block:"139", label:"THUVARAGA MATRIC HSS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22671", district:"13", Block:"117", label:"GHS DEVEERAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"22672", district:"13", Block:"35", label:"NOTRE DAME ACADEMY MATRIC SCHOOL KUNNANUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22673", district:"13", Block:"398", label:"SRI SARASWATHI VIDYALAYA , KUNDARAPALLI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22674", district:"13", Block:"291", label:"GHS ERRANDAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"22675", district:"13", Block:"291", label:"GHS KATTINAYAKANADODDI", Sch_type:"Government", Category:"High School"},
    { key:"22676", district:"13", Block:"78", label:"ANNAI ARAVINTHAR SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22677", district:"13", Block:"311", label:"GURUKULAM GLOBAL RESIDENTIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22678", district:"13", Block:"311", label:"INDIAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22679", district:"13", Block:"311", label:"GHS PANDURANGAN DODDI", Sch_type:"Government", Category:"High School"},
    { key:"22680", district:"13", Block:"177", label:"GUNA MATRIC HR SEC SCHOOL, GENDIGAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22681", district:"13", Block:"177", label:"GHS SALOOR", Sch_type:"Government", Category:"High School"},
    { key:"22682", district:"13", Block:"177", label:"UDHAYAAM MATRIC HR SEC SCHOOL, SIVAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22683", district:"3", Block:"270", label:"A.A.PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22684", district:"3", Block:"304", label:"PEACE ACADEMY", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22685", district:"3", Block:"195", label:"D.A.V. BABA SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22686", district:"10", Block:"146", label:"SRI CHAITANYA TECHNO SCHOOL, MANAPAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22687", district:"33", Block:"252", label:"MAHARISHI VIDYA MANDIR SR SEC SCHOOL, MELPAKKAM, AVADI-77", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22688", district:"1", Block:"26", label:"SABARMATHI VIDHYALAYA PUBLIC SCHOOL ARIYALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22689", district:"29", Block:"258", label:"SRI VETRI VIDHAYALAYA (CBSE) , VELLANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22690", district:"11", Block:"263", label:"STELLA MARY'S CBSE SCHOOL-VELLAMODI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22691", district:"28", Block:"297", label:"ATOMIC ENERGY CENTRAL SCHOOL, PAZHAYAKAYAL", Sch_type:"Central Govt", Category:"Middle School"},
    { key:"22692", district:"3", Block:"270", label:"NARAYANA E - TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22693", district:"3", Block:"368", label:"SSH MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22694", district:"37", Block:"130", label:"NIRMAL VIDYA MATRICULATION SCHOOL ASARANKUPPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22695", district:"20", Block:"169", label:"GOVERNMENT HIGH SCHOOL (GIRLS), PUDUMADAM", Sch_type:"Government", Category:"High School"},
    { key:"22696", district:"4", Block:"156", label:"NIRMALA MATHA CONVENT SCHOOL (ICSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"22697", district:"16", Block:"308", label:"SIVASAKTHI INTERNATIONAL  SCHOOL, THALAINAYAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22698", district:"16", Block:"389", label:"AGARAM MATRICULATION SCHOOL KARUPPAMPULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22699", district:"15", Block:"293", label:"SUBHAM VIDYA MANDIR SIRKALI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22700", district:"15", Block:"293", label:"SHRI NATARAJAN MEMORIAL PUBLIC SCHOOL KARAIMEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22701", district:"23", Block:"94", label:"KARPAGA MATRICULATION  SCHOOL SAKTHI NAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22702", district:"23", Block:"294", label:"SASTRA ENGLISH SCHOOL GANDHINAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22703", district:"8", Block:"20", label:"KSC PUBLIC SCHOOL, MANJALANAICKANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22704", district:"8", Block:"38", label:"CHANAKYA INTERNATIONAL SCHOOL, PONMEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22705", district:"8", Block:"38", label:"SPRINGDALE PUBLIC SCHOOL, PUNJAI PULIAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22706", district:"8", Block:"45", label:"SRI JAI VIKAS PUBLIC CBSE SCHOOL, ULAGAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22707", district:"8", Block:"45", label:"KONGU INTERNATIONAL SCHOOL(CBSE), MUTHAIAN KOVIL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22708", district:"2", Block:"329", label:"POLICE PUBLIC SCHOOL, MELAKOTTAIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22709", district:"2", Block:"114", label:"ZION INTERNATIONAL PUBLIC SCHOOL, NEDUNGUNDRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22710", district:"2", Block:"329", label:"BILLABONG HIGH INTERNATIONAL SCHOOL, THAIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22711", district:"2", Block:"329", label:"HINDUSTAN INTERNATIONAL SCHOOL PADUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22712", district:"2", Block:"329", label:"SBOA HIGH SCHOOL POLACHERRY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22713", district:"2", Block:"329", label:"MOUNT LITERAZEE SCHOOL NAVALUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22714", district:"2", Block:"299", label:"JAGANNATH VIDYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22715", district:"2", Block:"299", label:"NARAYANA E TECHNO SCHOOL SHOLINGANALLUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22716", district:"20", Block:"169", label:"AMRITA VIDYALAYAM, ARIYANGUNDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22717", district:"20", Block:"86", label:"DON BOSCO SCHOOL OF EXCELLENCE, SAYALGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22718", district:"33", Block:"252", label:"CPS GLOBAL SCHOOL, THIRUMAZISAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22719", district:"33", Block:"252", label:"ADITYA VIDYASHRAM , VEERARAGAVAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22720", district:"33", Block:"403", label:"SCOPE GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22721", district:"33", Block:"403", label:"ADW HS, VALASARA VAKKAM", Sch_type:"Government", Category:"High School"},
    { key:"22722", district:"33", Block:"260", label:"VELLAMMAL NEW GEN SCHOOL , SURAPET", Sch_type:"Un-aided", Category:"High School"},
    { key:"22723", district:"33", Block:"260", label:"VELLAMMAL NEW GEN SCHOOL MADHAVARAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22724", district:"33", Block:"260", label:"SRI SATHYA SAI VIDYA VIHAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22725", district:"33", Block:"261", label:"SRI ARUTSAI VETRI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22726", district:"33", Block:"403", label:"ALPHA CBSE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22727", district:"24", Block:"119", label:"SMA NATIONAL PUBLIC SCHOOL ADAAKKALAPATTANAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22728", district:"30", Block:"174", label:"NAMMA OORU GLOBAL SCHOOLS, GANGAIKONDAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"22729", district:"24", Block:"180", label:"SRI SWARNA VIDYASHRAM - SHUNMUGANALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22730", district:"30", Block:"204", label:"NELLAI INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22731", district:"24", Block:"276", label:"VIVEKANANDHA VIDHYALAYA, PANAIYUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22732", district:"24", Block:"307", label:"PIONEER INTERNATIONAL CBSE SCHOOL ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22733", district:"24", Block:"307", label:"VEL'S VIDHYALAYA SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22734", district:"30", Block:"385", label:"VEDIC VIDYASHRAM CBSE SCHOOL THERKKU VALLIYOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22735", district:"36", Block:"12", label:"SRI SANKARA MATRIC HR. SEC. SCHOOL, ODUGATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22736", district:"21", Block:"21", label:"HAYAGRIVAR VIDYAASHRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22737", district:"21", Block:"408", label:"S.S.S.MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22738", district:"21", Block:"359", label:"THE KRISAR ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22739", district:"21", Block:"359", label:"VEDHA VIDHYA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22740", district:"21", Block:"359", label:"SRI JAYAM NAMAKKAL MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22741", district:"21", Block:"290", label:"VCS HI TECH INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22742", district:"21", Block:"409", label:"THE GEEKAY WORLD (IGCSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22743", district:"10", Block:"146", label:"Swamy's school , santhosh nagar Madhanandhapuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"22744", district:"8", Block:"20", label:"GTRHS, KONGADAI", Sch_type:"Government", Category:"High School"},
    { key:"22745", district:"10", Block:"146", label:"Swamy's school , Gurusamy nagar Madhanandhapuram", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22746", district:"26", Block:"135", label:"BEST PUBLIC SCHOOL, KADAKODU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22747", district:"21", Block:"409", label:"WISDOM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22748", district:"4", Block:"107", label:"BHARATH VIDHYA NIKETHAN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22749", district:"27", Block:"55", label:"MAHARISHI VIDHYA MANDHIR  SCHOOL - KULLAPPAGOUNDANPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22750", district:"27", Block:"375", label:"LOYOLA INTERNATIONAL PUBLIC SCHOOL - T.SINDALAICHERY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22751", district:"25", Block:"330", label:"GHS -KATHIRAMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"22752", district:"10", Block:"146", label:"Peter International School(CBSE).Kavanur", Sch_type:"Un-aided", Category:"High School"},
    { key:"22753", district:"10", Block:"410", label:"Asian International Residential School. Palayaseevaram", Sch_type:"Un-aided", Category:"High School"},
    { key:"22754", district:"10", Block:"146", label:"sri chaitanya Techno School Iyyappanthangal", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22755", district:"37", Block:"130", label:"E.S LORDS INTERNATIONAL SCHOOL AYYANKOILPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22756", district:"37", Block:"164", label:"HOLY ANGELSCHOOL RETTANAI(CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"22757", district:"37", Block:"164", label:"GREEN PARADISE RETTANAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22758", district:"37", Block:"175", label:"CHANAKYA VIDYASHRAM (CBSE) MARAKKANAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22759", district:"37", Block:"214", label:"DMI ST JOSEPH GLOBAL SCHOOL, KEEZHATHANOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22760", district:"9", Block:"277", label:"SUBAM INTERNATIONAL (CBSE) SCHOOL VADAPONPARAPPI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22761", district:"37", Block:"384", label:"DENI MATRICULATION SCHOOL, MELKALAVAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22762", district:"33", Block:"403", label:"SRI CHITHANYA SCHOOL, NOLAMBUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22763", district:"19", Block:"18", label:"ST. JOSEPH GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22764", district:"14", Block:"158", label:"GOVT. SPECIAL SCHOOL FOR VI, K.K.NAGAR", Sch_type:"Government", Category:"Middle School"},
    { key:"22765", district:"14", Block:"158", label:"SPARKS VIDYALA SPECIAL S, SATHAMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22766", district:"21", Block:"290", label:"SKV MATRICULATION PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22767", district:"13", Block:"35", label:"SIVAGAMIAMMAL INTERNATIONAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22768", district:"35", Block:"340", label:"CBSE RC Fathima public school,Thiruvarur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22769", district:"35", Block:"173", label:"CBSE,DELTA PUBLIC SCHOOL,PANANKATTANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22770", district:"11", Block:"3", label:"AROCKIA ANNAI ACADEMY (CBSE) SCHOOL-KOTTAVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22771", district:"11", Block:"263", label:"VINMEEN SCHOOL, PARVATHIPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22772", district:"22", Block:"226", label:"ELFS PUBLIC SCHOOL ,VENGAMPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22773", district:"22", Block:"226", label:"SREE JOTHI VIDYALAYA MATRIC SCHOOL, VENGAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22774", district:"14", Block:"183", label:"EDUSTAR MODEL SCHOOL, MELUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22775", district:"15", Block:"178", label:"VEETA VARSITY CBSE SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22776", district:"15", Block:"178", label:"ARK GLOBAL  SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22777", district:"2", Block:"300", label:"ST. ANTONY'S R.C. HIGH SCHOOL, SENGALANEERMALAI", Sch_type:"Partially Aided", Category:"High School"},
    { key:"22778", district:"2", Block:"300", label:"Baalyaa School (NIOS) Keelkattalai", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22779", district:"14", Block:"136", label:"NAVA INDIA CHINESE MATRICULATION SCHOOL, ALAMPATTY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22780", district:"34", Block:"412", label:"RAMAJEYAM MATRICULATION SCHOOL DEVIKAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22781", district:"34", Block:"412", label:"SAFA MATRIC School, Thatchur", Sch_type:"Un-aided", Category:"High School"},
    { key:"22782", district:"34", Block:"395", label:"Chitra Krishnasamy Vidyalaya CBSE,Vembakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"22783", district:"38", Block:"28", label:"KALAIMAGAL VIDHYALAYA, PANDALKUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22784", district:"38", Block:"110", label:"SRI SABARI NATIONAL SCHOOL, KARIAPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22785", district:"38", Block:"282", label:"M.M VIDYASHRAM, SATTUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22786", district:"38", Block:"295", label:"SRI KRISHNASAMY INTERNATIONAL SCHOOL, MARANERI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22787", district:"38", Block:"298", label:"SRIVI LIONS INTERNATIONAL SCHOOL, SRIVILLIPUTHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22788", district:"38", Block:"298", label:"VPMS SELVI SHANKAR(SESHA) VIDYASRAM SCHOOL, SRIVILLIPUTHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22789", district:"14", Block:"328", label:"EVERGREEN VIDHYAASHRAM CBSE SCHOOL, AVANIYAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22790", district:"29", Block:"317", label:"Bodhi vidhyalaya CBSE,Annai Nagar,M.Pudhupatti,musiri.", Sch_type:"Un-aided", Category:"High School"},
    { key:"22791", district:"14", Block:"161", label:"MADURAI PUBLIC SCHOOL, PETCHIKULAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22792", district:"29", Block:"317", label:"VIDHYA BHARATHI VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22793", district:"29", Block:"172", label:"SRV PUBLIC SCHOOL, SAMAYAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22794", district:"29", Block:"171", label:"SRV PUBLIC SCHOOL, PIRATIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22795", district:"37", Block:"342", label:"E.M.SUBRAMANI REDDIYAR VIVEKANANDA VIDYALAYA CBSE SCHOOL ERUVELPATTU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22796", district:"29", Block:"367", label:"St. James Academy(CBSE),TRICHY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22797", district:"29", Block:"171", label:"AWNIYA PUBLIC SCHOOL, AMMAPETTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22798", district:"2", Block:"300", label:"NEW PRINCE SHRI BHAVANI VIDYASHRAM, ADAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22799", district:"29", Block:"366", label:"NR PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22800", district:"29", Block:"357", label:"Sri Krishna Vidhyalaya Matric School, MURUGUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22801", district:"31", Block:"103", label:"CSI Public School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22802", district:"24", Block:"388", label:"TMS WELLDONE MATRICULATION SCHOOL PULIANGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22803", district:"4", Block:"52", label:"PRAKRIYA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22804", district:"29", Block:"343", label:"TIRUCHY PUBLIC SCHOOL ,THUVAKUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22805", district:"17", Block:"285", label:"VETHALOGA VIDHYALAYA  CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"22806", district:"17", Block:"267", label:"SRV INNOVATIVE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22807", district:"17", Block:"267", label:"SRI VIDYA MANDIR INTERNATIONAL (CBSE) SCHOOL,RASIPURAM.", Sch_type:"Un-aided", Category:"High School"},
    { key:"22808", district:"17", Block:"267", label:"SRV PUBLIC SCHOOL, RASIPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22809", district:"17", Block:"63", label:"KONGUNADU SCHOOL  VELAGOUNDAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22810", district:"17", Block:"85", label:"VIVEKANANDA VIDYALAYAM PAUNDAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22811", district:"17", Block:"85", label:"R.N. OXFORD PUBLIC SCHOOL ", Sch_type:"Un-aided", Category:"High School"},
    { key:"22812", district:"34", Block:"395", label:"Naalantha public school (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22813", district:"4", Block:"279", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22814", district:"4", Block:"279", label:"EINSTEIN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22815", district:"4", Block:"279", label:"BRILLIANT VIDYA BHAVAN", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22816", district:"4", Block:"279", label:"THE AALAM INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22817", district:"4", Block:"279", label:"INDIAN PUBLIC CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22818", district:"4", Block:"247", label:"P.A.INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22819", district:"4", Block:"247", label:"MAHARISHI INTERATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22820", district:"4", Block:"107", label:"VIVIDH INTERNATIONAL RESIDENTIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22821", district:"32", Block:"363", label:"PUMS HOUSING UNIT", Sch_type:"Government", Category:"Middle School"},
    { key:"22822", district:"32", Block:"250", label:"JAI SHRIRAM ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL, AVINASHIPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22823", district:"4", Block:"242", label:"THE UNITED PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22824", district:"36", Block:"113", label:"PINCUSHION MONTESSORI INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22825", district:"21", Block:"21", label:"JEEVA MATRIC. HR. SEC. SCHOOL, ARAKKONAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22826", district:"17", Block:"224", label:"AVS GIRLS MATRICULATION HIGHER SECONDARY SCHOOL, KOMARAPALAYAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22827", district:"2", Block:"299", label:"Marg Vidhyalaya CBSE school Pallikaranai", Sch_type:"Un-aided", Category:"High School"},
    { key:"22828", district:"2", Block:"299", label:"SAN Academy, Kamakoti Nagar,Pallikaranai.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22829", district:"14", Block:"328", label:"ELITE PUBLIC CBSE SCHOOL, PULIYANKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22830", district:"14", Block:"328", label:"NAGASIVA VIDYALAYA SCHOOL, SAMANATHAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22831", district:"14", Block:"328", label:"DOLPHIN ELITE CBSE S, PONMENI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22832", district:"13", Block:"117", label:"RICH MAAN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22833", district:"5", Block:"170", label:"ASWIN'S MATRIC  SCHOOL,MANGALUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22834", district:"13", Block:"117", label:"GOPI KRISHNA'S MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22835", district:"36", Block:"113", label:"JEYAMALLI INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22836", district:"32", Block:"371", label:"AMAZON PUBLIC SCHOOL-UDUMALPET", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22837", district:"4", Block:"13", label:"VIDHYA VISHWALAYA CBSE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22838", district:"32", Block:"363", label:"DREAM PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22839", district:"32", Block:"363", label:"VIKAS VIDYALAYA JUNIORS MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22840", district:"32", Block:"363", label:"MALAR ACE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22841", district:"32", Block:"363", label:"S.K.V.MATRICULATION SCHOOL,MANNARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22842", district:"5", Block:"54", label:"V.C.S .SHEMFORD INTERNATIONAL SCHOOL,CAPPER HILLS", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22843", district:"28", Block:"350", label:"SERVITE SCHOOL, MARAVANMADAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22844", district:"24", Block:"91", label:"NALLAMANI VIDHYALAYA CBSE SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22845", district:"3", Block:"62", label:"MCC PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22846", district:"2", Block:"300", label:"ST.THOMAS ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22847", district:"22", Block:"34", label:"CROWN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22848", district:"28", Block:"217", label:"KING OF KINGS SCHOOL - CBSE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22849", district:"11", Block:"124", label:"BETHLAHEM NEW CBSE CENTRAL SCHOOL, MOOSARI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22850", district:"11", Block:"149", label:"MARIA RAFOLS SCHOOL, LEKSHMIPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22851", district:"11", Block:"341", label:"SDA ENGLISH HIGH SCHOOL, PULIERRANGHY", Sch_type:"Un-aided", Category:"High School"},
    { key:"22852", district:"3", Block:"69", label:"DANIEL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22853", district:"34", Block:"358", label:"PVK MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22854", district:"4", Block:"302", label:"KONGU PUBLIC SCHOOL, KARUMATHAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22855", district:"22", Block:"391", label:"MAHARISHI VIDYA MANDIR (CBSE)", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22856", district:"7", Block:"275", label:"DLV PUBLIC SCHOOL, KANAVAIPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22857", district:"10", Block:"146", label:"Amrita Vidyalayam", Sch_type:"Un-aided", Category:"High School"},
    { key:"22858", district:"18", Block:"400", label:"GANDHIRAM HIGH SCHOOL PUDHUVETTAKUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22859", district:"22", Block:"68", label:"GHS NADUVALUR", Sch_type:"Government", Category:"High School"},
    { key:"22860", district:"22", Block:"163", label:"GHS KANNANTHERI", Sch_type:"Government", Category:"High School"},
    { key:"22861", district:"22", Block:"226", label:"GHS KAMMALAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22862", district:"22", Block:"61", label:"GHS KURUKKALPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22863", district:"22", Block:"30", label:"GHS THAVALAPPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22864", district:"16", Block:"121", label:"GOVERNMENT HIGH SCHOOL PERIYATHUMBUR", Sch_type:"Government", Category:"High School"},
    { key:"22865", district:"15", Block:"178", label:"GOVERNMENT HIGH SCHOOL THALANCHERY", Sch_type:"Government", Category:"High School"},
    { key:"22866", district:"16", Block:"389", label:"GOVERNMENT HIGH SCHOOL PIRANTHIYANKARAI", Sch_type:"Government", Category:"High School"},
    { key:"22867", district:"29", Block:"172", label:"RANE VIDYALAYA (CBSE), THEERAMPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22868", district:"13", Block:"78", label:"SRI VIJAY VIDYASHRAM BAGALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22869", district:"8", Block:"20", label:"GHS, THAMARAIKARAI", Sch_type:"Government", Category:"High School"},
    { key:"22870", district:"8", Block:"202", label:"GHS, SUNDAKKAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22871", district:"8", Block:"185", label:"GHS, KANDIKKATTUVALASU", Sch_type:"Government", Category:"High School"},
    { key:"22872", district:"8", Block:"281", label:"GOVERNMENT HIGH SCHOOL,MAKKAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22873", district:"8", Block:"310", label:"GHS, KUNNANPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22874", district:"37", Block:"379", label:"GOVERNMENT HIGH SCHOOL- PERAVOOR", Sch_type:"Government", Category:"High School"},
    { key:"22875", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL ADHANUR", Sch_type:"Government", Category:"High School"},
    { key:"22876", district:"9", Block:"323", label:"GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  G.ARIYUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22877", district:"33", Block:"403", label:"MUNICIPAL HIGH SCHOOL, KALLIKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"22878", district:"33", Block:"403", label:"GHS METTUKUPPAM (N)", Sch_type:"Government", Category:"High School"},
    { key:"22879", district:"33", Block:"184", label:"GHS, A.REDDY PALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22880", district:"6", Block:"187", label:"SRIRAM PUBLIC SCHOOL, BHOOMISAMUTHIRAM, KAMBAINALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22881", district:"8", Block:"71", label:"GHS, ODAKKATTUR", Sch_type:"Government", Category:"High School"},
    { key:"22882", district:"8", Block:"45", label:"GOVERNMENT HIGH SCHOOL, VOIPADI", Sch_type:"Government", Category:"High School"},
    { key:"22883", district:"14", Block:"158", label:"OLR SPECIAL SCHOOL, VANDIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22884", district:"35", Block:"382", label:"GHS Thenkuvalaveli", Sch_type:"Government", Category:"High School"},
    { key:"22885", district:"35", Block:"205", label:"GHS Ubhayavethanthapuram", Sch_type:"Government", Category:"High School"},
    { key:"22886", district:"35", Block:"209", label:"GHS Karakottai", Sch_type:"Government", Category:"High School"},
    { key:"22887", district:"9", Block:"372", label:"GOVERNMENT HIGH SCHOOL PINNALVADI", Sch_type:"Government", Category:"High School"},
    { key:"22888", district:"14", Block:"328", label:"GOVT HIGH SCHOOL, KARUVELAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22889", district:"9", Block:"269", label:"GOVERNMENT HIGH SCHOOL MURUKKAMPADI", Sch_type:"Government", Category:"High School"},
    { key:"22890", district:"13", Block:"376", label:"GHS CHANDRAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22891", district:"13", Block:"78", label:"GHS NANDHIMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"22892", district:"13", Block:"35", label:"GHS ALERAHALLI", Sch_type:"Government", Category:"High School"},
    { key:"22893", district:"14", Block:"4", label:"GOVT HIGH SCHOOL, AYYUR", Sch_type:"Government", Category:"High School"},
    { key:"22894", district:"2", Block:"329", label:"Athena Global School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22895", district:"2", Block:"329", label:"Government Eklavya Model Residential School - Pattipulam", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22896", district:"2", Block:"322", label:"GHS VALLIPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22897", district:"2", Block:"162", label:"GHS Thottanaval", Sch_type:"Government", Category:"High School"},
    { key:"22898", district:"10", Block:"146", label:"GHS, THUNDALKAZHANI.", Sch_type:"Government", Category:"High School"},
    { key:"22899", district:"33", Block:"261", label:"GHS, ADHIVARAGAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22900", district:"18", Block:"7", label:"GHS KOTTARAI", Sch_type:"Government", Category:"High School"},
    { key:"22901", district:"32", Block:"223", label:"KEY STAGE SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22902", district:"38", Block:"321", label:"GOVERNMENT HIGH SCHOOL, CHITHALAKUNDU", Sch_type:"Government", Category:"High School"},
    { key:"22903", district:"38", Block:"411", label:"GHS, KEELAKOTTAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"22904", district:"38", Block:"282", label:"GOVERNMENT HIGH SCHOOL, MELAPUDHUR", Sch_type:"Government", Category:"High School"},
    { key:"22905", district:"30", Block:"174", label:"GHS ETTANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"22906", district:"30", Block:"174", label:"GHS KOOVACHIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22907", district:"24", Block:"6", label:"GHS THALAISUBRAMANIAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22908", district:"24", Block:"388", label:"GOVERNMENT HIGH SCHOOL, KOODALOOR", Sch_type:"Government", Category:"High School"},
    { key:"22909", district:"1", Block:"81", label:"GHS DEVAMANGALAM-S", Sch_type:"Government", Category:"High School"},
    { key:"22910", district:"1", Block:"346", label:"GHS ERAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"22911", district:"12", Block:"88", label:"ST.Joseph's Matriculation School", Sch_type:"Un-aided", Category:"High School"},
    { key:"22912", district:"19", Block:"255", label:"GOVT HIGH SCHOOL MANTHANGUDI", Sch_type:"Government", Category:"High School"},
    { key:"22913", district:"19", Block:"18", label:"GOVT HIGH SCHOOL MANGUDY", Sch_type:"Government", Category:"High School"},
    { key:"22914", district:"19", Block:"405", label:"GOVERNMENT HIGH SCHOOL PAKKUDI", Sch_type:"Government", Category:"High School"},
    { key:"22915", district:"33", Block:"225", label:"K V PATSHALA", Sch_type:"Un-aided", Category:"High School"},
    { key:"22916", district:"25", Block:"234", label:"GHS -Adirampattinam Karaiyur Theru", Sch_type:"Government", Category:"High School"},
    { key:"22917", district:"25", Block:"239", label:"GHS- EDAIYATHI KONAR ST", Sch_type:"Government", Category:"High School"},
    { key:"22918", district:"32", Block:"223", label:"GHS ANNA NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"22919", district:"3", Block:"69", label:"NARAYANA e - TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22920", district:"34", Block:"27", label:"GHS-MAMANDOOR", Sch_type:"Government", Category:"High School"},
    { key:"22921", district:"34", Block:"395", label:"GHS MORANAM", Sch_type:"Government", Category:"High School"},
    { key:"22922", district:"34", Block:"93", label:"GHS-NAVAPPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22923", district:"34", Block:"125", label:"GHS-KATTUMALAIYANUR", Sch_type:"Government", Category:"High School"},
    { key:"22924", district:"27", Block:"49", label:"GOVT HS , APPIPATTI  ", Sch_type:"Government", Category:"High School"},
    { key:"22925", district:"19", Block:"67", label:"GOVT HIGH SCHOOL ATHANGARAIVIDUTHI", Sch_type:"Government", Category:"High School"},
    { key:"22926", district:"20", Block:"188", label:"GOVERNMENT HIGH SCHOOL, VENGALAKURICHI", Sch_type:"Government", Category:"High School"},
    { key:"22927", district:"32", Block:"363", label:"GHS MASCO NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"22928", district:"32", Block:"363", label:"G GIRLS HSS PERUMANALLUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"22929", district:"4", Block:"245", label:"GHS EDAYARPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22930", district:"4", Block:"245", label:"CORPORATION HIGH SCHOOL, RAMASAMY NAGAR, NALLAMPALAYAM ROAD.", Sch_type:"Government", Category:"High School"},
    { key:"22931", district:"5", Block:"227", label:"GOVERNMENT GIRLS HIGH SCHOOL PANRUTI", Sch_type:"Government", Category:"High School"},
    { key:"22932", district:"23", Block:"333", label:"GOVT HIGH SCHOOL  KALIYANTHUR", Sch_type:"Government", Category:"High School"},
    { key:"22933", district:"23", Block:"294", label:"GHS ILUPPAKUDI", Sch_type:"Government", Category:"High School"},
    { key:"22934", district:"32", Block:"73", label:"GHS Pukkulam", Sch_type:"Government", Category:"High School"},
    { key:"22935", district:"4", Block:"156", label:"AATHREIYA SCHOOL FOR EXCELLENCE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22936", district:"4", Block:"302", label:"GHS NEELAMBUR", Sch_type:"Government", Category:"High School"},
    { key:"22937", district:"19", Block:"18", label:"GOVERMENT GIRLS HIGH SCHOOL, ANNAVASAL", Sch_type:"Government", Category:"High School"},
    { key:"22938", district:"7", Block:"59", label:"GHS, NALLAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22939", district:"7", Block:"380", label:"GHS, V.KURUNTHAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22940", district:"5", Block:"115", label:"GHS-NATTARMANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"22941", district:"6", Block:"77", label:"MAHARISHI VIDYA MANDIR, PULUTHIYUR, KOKKARAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22942", district:"6", Block:"77", label:"GHS POYYAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22943", district:"6", Block:"235", label:"GHS JELMARAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22944", district:"6", Block:"218", label:"GHS EACHAMPALLAM", Sch_type:"Government", Category:"High School"},
    { key:"22945", district:"6", Block:"218", label:"GHS JOGIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22946", district:"6", Block:"109", label:"GHS GUNDAGANUR", Sch_type:"Government", Category:"High School"},
    { key:"22947", district:"2", Block:"329", label:"HIRANANDANI UPSCALE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22948", district:"31", Block:"82", label:"GHS, CHINNAMOOKANUR", Sch_type:"Government", Category:"High School"},
    { key:"22949", district:"21", Block:"409", label:"GHS, GUDIMALLUR", Sch_type:"Government", Category:"High School"},
    { key:"22950", district:"31", Block:"208", label:"MHS, KONAMEDU, VANIYAMBADI", Sch_type:"Government", Category:"High School"},
    { key:"22951", district:"21", Block:"210", label:"GGHS, THIRUMALPUR", Sch_type:"Government", Category:"High School"},
    { key:"22952", district:"32", Block:"104", label:"GHS PERIYAILLIYAM", Sch_type:"Government", Category:"High School"},
    { key:"22953", district:"33", Block:"403", label:"SRI CHAITANYA SCHOOL, RAMAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22954", district:"4", Block:"156", label:"GHS OTHAKKALMANDAPAM", Sch_type:"Government", Category:"High School"},
    { key:"22955", district:"13", Block:"139", label:"GHS PEDDANAPALLI", Sch_type:"Government", Category:"High School"},
    { key:"22956", district:"28", Block:"297", label:"GHS THOLAPPANPANNAI", Sch_type:"Government", Category:"High School"},
    { key:"22957", district:"19", Block:"22", label:"SAI LAUREL INTERNATIONAL SCHOOL,KURUMBAKKADU", Sch_type:"Un-aided", Category:"High School"},
    { key:"22958", district:"32", Block:"57", label:"VEVEAHAM MATRIC HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22959", district:"3", Block:"304", label:"SHRINE VAILANKANNI GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22960", district:"27", Block:"319", label:"SANTINIKETAN PUBLIC SCHOOL - MUTHUTHEVANPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22961", district:"27", Block:"55", label:"SRI SAKTHI VINAYAGAR INTERNATIONAL (CBSE) SCHOOL - CUMBUM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22962", district:"3", Block:"259", label:"CSI EWART GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22963", district:"14", Block:"283", label:"GOVT HIGH SCHOOL, T.KRISHNAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"22964", district:"14", Block:"183", label:"GOVT HIGH SCHOOL, PULIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22965", district:"14", Block:"161", label:"GOVT HIGH SCHOOL, OORMECHIKULAM", Sch_type:"Government", Category:"High School"},
    { key:"22966", district:"17", Block:"132", label:"GHS, SEPPANKULAMPATTI", Sch_type:"Government", Category:"High School"},
    { key:"22967", district:"17", Block:"186", label:"GOVT HIGH SCHOOL, S.VAZHAVANTHI", Sch_type:"Government", Category:"High School"},
    { key:"22968", district:"17", Block:"360", label:"GOVT HIGH SCHOOL, KARUMAGOUNDAMPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22969", district:"17", Block:"224", label:"GHS AVATHIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"22970", district:"3", Block:"259", label:"C.S.I. BAIN SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22971", district:"3", Block:"195", label:"NAVADISHA MONTESSORI SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22972", district:"3", Block:"270", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22973", district:"18", Block:"236", label:"GHS (G) PERAMBALUR", Sch_type:"Government", Category:"High School"},
    { key:"22974", district:"12", Block:"348", label:"GOVERNMENT HIGH SCHOOL, PATHIRIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22975", district:"12", Block:"88", label:"GOVERNMENT HIGH SCHOOL, SONAMPATTY", Sch_type:"Government", Category:"High School"},
    { key:"22976", district:"4", Block:"13", label:"GHS RAMANAMUDHALIPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"22977", district:"4", Block:"107", label:"BHAGWAN MAHAVEER MUNICIPAL HIGH SCHOOL, SANKAR NAGAR", Sch_type:"Government", Category:"High School"},
    { key:"22978", district:"25", Block:"144", label:"KARTHI VIDHYALAYA INTERNATIONAL SCHOOL-KUMBAKONAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"22979", district:"4", Block:"279", label:"SSVM SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"High School"},
    { key:"22980", district:"4", Block:"279", label:"PERKS PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22981", district:"6", Block:"187", label:"AVVAI VIDHYASHRAM PUBLIC SCHOOL, POLAIYAMPALLI, MORAPPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22982", district:"6", Block:"187", label:"GREEN PARK INTERNATIONAL SCHOOL, KADATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"22983", district:"28", Block:"320", label:"KAMLAVATI HIGHER SECONDARY SCHOOL,SAHUPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22984", district:"3", Block:"237", label:"ARYA SAMAJ HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22985", district:"29", Block:"176", label:"GHS Muthalvarpatty", Sch_type:"Government", Category:"High School"},
    { key:"22986", district:"6", Block:"187", label:"GREEN PARK MHSS, KADATHUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22987", district:"9", Block:"372", label:"ST. ANNE'S GIRLS HIGHER SECONDARY  SCHOOL ERAIYUR", Sch_type:"Partially Aided", Category:"Higher Secondary School"},
    { key:"22988", district:"10", Block:"101", label:"Billabong High International School, Kanchipuram", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22989", district:"34", Block:"365", label:"VIGNESH MATRICULATION HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22990", district:"26", Block:"72", label:"GHS KARIYASHOLAI", Sch_type:"Government", Category:"High School"},
    { key:"22991", district:"26", Block:"135", label:"GOVERNMENT HIGH SCHOOL, SULLIGUDU", Sch_type:"Government", Category:"High School"},
    { key:"22992", district:"2", Block:"299", label:"Alpha International School , Sembakkam", Sch_type:"Un-aided", Category:"High School"},
    { key:"22993", district:"10", Block:"101", label:"Shrishti international school Kanchipuram", Sch_type:"Un-aided", Category:"High School"},
    { key:"22994", district:"7", Block:"59", label:"PRNP VIDHYA MANDHIR MATRIC HIGHER SECONDARY SCHOOL, MULLIPADI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"22995", district:"7", Block:"219", label:"BHAARATH PUBLIC SCHOOL, PALANI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22996", district:"13", Block:"78", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"22997", district:"14", Block:"158", label:"MAHAJANA VIDYALAYA MAT. S, UTHANGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"22998", district:"6", Block:"77", label:"HARUR INTERNATIONAL SCHOOL, H.DODDAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"22999", district:"29", Block:"168", label:"GHS Vadakkuserpatti", Sch_type:"Government", Category:"High School"},
    { key:"23000", district:"12", Block:"315", label:"SHRI VIJAYALAKSHMI  VIDHYALAYAA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23001", district:"33", Block:"403", label:"SRI VIMALA VIDYALAYA CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23002", district:"28", Block:"297", label:"THE VIKASA SCHOOL CBSE, SAWYERPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23003", district:"14", Block:"161", label:"DELHI WORLD PUBLIC SCHOOL, PARAVAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23004", district:"3", Block:"69", label:"SMK VIDHYASHRAM & JUNIOR COLLEGE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23005", district:"3", Block:"69", label:"VELANKANNI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23006", district:"3", Block:"195", label:"NARAYANA OLYMPIAD SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23007", district:"27", Block:"40", label:"G.D. PUBLIC SCHOOL - BODINAYAKANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23008", district:"6", Block:"235", label:"VIVEKANANDA MATRIC SCHOOL, PUDHUR SOLAPPADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23009", district:"3", Block:"62", label:"AGARWAL VIDYALAYA AND JUNIOR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23010", district:"16", Block:"196", label:"SIR ISSAC NEWTON MATRIC HR,SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23011", district:"1", Block:"26", label:"VINAYAGA PUBLIC SCHOOL MELAKARUPPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23012", district:"34", Block:"125", label:"JAYAM VIDYASHRAM SCHOOL( CBSE )  KILPENNATHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23013", district:"18", Block:"236", label:"SRI RAMAKRISHNA BOYS MATRIC HR.SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23014", district:"2", Block:"162", label:"RC High School Thatchur", Sch_type:"Partially Aided", Category:"High School"},
    { key:"23015", district:"11", Block:"149", label:"SREE VIVEKANANDA CBSE SCHOOL,KOOTTUMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23016", district:"13", Block:"35", label:"SELVA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23017", district:"2", Block:"299", label:"Sharanalaya Montessori ,Akkarai", Sch_type:"Un-aided", Category:"High School"},
    { key:"23018", district:"10", Block:"146", label:"Tianno vidyashrm", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23019", district:"14", Block:"160", label:"PNU Dr.T.MUTHUSAMY HIGHER SECONDARY SCHOOL, VAZHAITHOPPU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23020", district:"2", Block:"299", label:"JS Global School Sholinganallur", Sch_type:"Un-aided", Category:"High School"},
    { key:"23021", district:"3", Block:"2", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23022", district:"13", Block:"78", label:"GREEN VALLEY SCHOOL, HOSUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23023", district:"8", Block:"244", label:"BHARATHI INTERNATIONAL SCHOOL, VIJAYAMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23024", district:"2", Block:"329", label:"LITTLE FLOCK NURSERY AND PRIMARY SCHOOL, ANUMANTHAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23025", district:"10", Block:"146", label:"PUMS NAVALUR (PUDHU KUDIYIRUPPU)", Sch_type:"Government", Category:"Middle School"},
    { key:"23026", district:"37", Block:"164", label:"K K DHANDAPANI WORLD SENIOR SECONDARY   SCHOOL,MELPERADIKUPPAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23027", district:"12", Block:"112", label:"SHARVALAKSHA VIDHYAALAYAA,VETTAMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23028", district:"7", Block:"352", label:"SASTI KENDRALAYA INTERNATIONAL PUBLIC SCHOOL, PULIYAMPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23029", district:"23", Block:"96", label:"SRI RAJA VIDYA VIKAAS CBSE SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23030", district:"13", Block:"122", label:"MOTHER THERESA PUBLIC SCHOOL, RAYAKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23031", district:"18", Block:"399", label:"IDEAL MATRICULATION SCHOOL , V.KALATHUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23032", district:"32", Block:"104", label:"GLOBAL INTERNATIONAL SCHOOL, KANGAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23033", district:"12", Block:"315", label:"VIJAY VIDHYA MANDIR ,EMOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23034", district:"6", Block:"109", label:"SRI RAGHAVENDRA HIGH SCHOOL, KARIMANGALAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23035", district:"14", Block:"324", label:"GOVT. KALLAR HIGH SCHOOL, K.PULIYANKULAM", Sch_type:"Government", Category:"High School"},
    { key:"23036", district:"14", Block:"43", label:"GOVT. KALLAR HIGH SCHOOL, A.PUDHUPATTI", Sch_type:"Government", Category:"High School"},
    { key:"23037", district:"14", Block:"374", label:"GOVT. KALLAR HIGH SCHOOL, V.PERUMALPATTI", Sch_type:"Government", Category:"High School"},
    { key:"23038", district:"15", Block:"151", label:"RAJ VIDYALAYA INTERNATIONAL SCHOOL,KUTHALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23039", district:"33", Block:"252", label:"PUSHKARA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23040", district:"28", Block:"351", label:"ST.THOMAS  SCHOOL, INNACIARPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23041", district:"28", Block:"351", label:"VICTORIA SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23042", district:"5", Block:"199", label:"JAYAPRIYA VIDYALAYA MATRICULATION SCHOOL, THIRUPPAYAR.", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23043", district:"36", Block:"394", label:"THE TAKSHILAH GLOBAL SCHOOL VELLORE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23044", district:"12", Block:"315", label:"KARUR SARASWATHI VIDHYA MANDHIRR, THULASIKODUMBU, KARUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23045", district:"12", Block:"83", label:"VENUS GLOBAL CAMPUS SCHOOL(CBSE),PUNNAMCHATHIRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23046", district:"26", Block:"72", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL, DEVALA", Sch_type:"Government", Category:"High School"},
    { key:"23047", district:"16", Block:"325", label:"NALLANDA MATRICULATION  SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23048", district:"33", Block:"76", label:"TJS PUBLIC SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"23049", district:"6", Block:"230", label:"GOLDEN GATES MHSS, BAIRNATHAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23050", district:"7", Block:"59", label:"VIDHYA PARTHI NATIONAL ACADEMY, SEELAPADI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23051", district:"4", Block:"19", label:"NAVA BHARATH INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23052", district:"13", Block:"35", label:"VAILANKANNI HIGHER SECONDARY SCHOOL BARGUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23053", district:"5", Block:"115", label:"AKSHARA VIDYA MANDIR, CBSE SCHOOL, VILVAKULAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23054", district:"20", Block:"266", label:"SYED AMMAL PUBLIC SCHOOL, PERAVOOR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23055", district:"20", Block:"169", label:"MOHAMED SATHAK KABEER PUBLIC SCHOOL, RAMANATHAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23056", district:"20", Block:"169", label:"AL QALAM INTERNATIONAL SCHOOL, PERUNGULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23057", district:"6", Block:"187", label:"AVVAI MHSS, JADAYAMPATTI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23058", district:"34", Block:"47", label:"SRI BHARATHI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23059", district:"4", Block:"279", label:"SRI RAMAKRISHNA CENTRAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23060", district:"4", Block:"279", label:"RAK'S PALLIKKOODAM SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23061", district:"5", Block:"54", label:"GREEN TECH MATRICULATION  HIGHER SECONDARY SCHOOL, VELLAPPAKKAM,", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23062", district:"13", Block:"177", label:"GURUKULAM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23063", district:"2", Block:"114", label:"ELA GREEN SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23064", district:"2", Block:"114", label:"SDA HIGHER SCONDARY SCHOOL,CHENGALPATTU", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23065", district:"2", Block:"299", label:"RAVINDRA BHARATHI GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23066", district:"9", Block:"95", label:"JS GLOBAL ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"23067", district:"8", Block:"10", label:"KALAIVANI KALVI NILAYAM MATRICULATION SCHOOL,CHENNAMPATTI.", Sch_type:"Un-aided", Category:"High School"},
    { key:"23068", district:"7", Block:"213", label:"SRI KRISHNA SCHOOL, ODDANCHATHRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23069", district:"7", Block:"213", label:"BUDS VIDYASHRAM CBSE SCHOOL, ODDANCHATRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23070", district:"7", Block:"390", label:"AKSHARA PUBLIC SCHOOL, VELLANAMPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23071", district:"14", Block:"161", label:"JANNAH PUBLIC SCHOOL, PARAYATHIKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23072", district:"14", Block:"161", label:"VEDIC VIDYASHRAM SCHOOL, THIRUPPALAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23073", district:"24", Block:"276", label:"VIVEKANANDA GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23074", district:"30", Block:"222", label:"ROSE MARY SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23075", district:"30", Block:"361", label:"MAHATHMA GANDHI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23076", district:"13", Block:"35", label:"ST. KANAKADASA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23077", district:"32", Block:"363", label:"CENTURY FOUNDATION PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23078", district:"19", Block:"255", label:"TAAGORE MATRICULATION SCHOOL, ADAPPANVAYAL NORTH, PUDUKKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23079", district:"33", Block:"260", label:"ACHARYA MAHASHRAMAN TERAPANTH JAIN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23080", district:"3", Block:"240", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23081", district:"3", Block:"237", label:"KULAPATI DR.S.BALAKRISHNA JOSHI GURRUKULAM SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23082", district:"3", Block:"237", label:"SHRI KRISHNASWAMY VIDYASHRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23083", district:"3", Block:"62", label:"LADY ANDAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23084", district:"11", Block:"341", label:"THRAYAMBIKA VIDYA MANDIR, PADANILAM, KULASEKHARAM.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23085", district:"18", Block:"7", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Malayappa nagar", Sch_type:"Government", Category:"Middle School"},
    { key:"23086", district:"6", Block:"235", label:"NEWTONS APPLE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23087", district:"6", Block:"187", label:"KONGU SCHOOL OF EXCELLENCE, MORAPPUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23088", district:"4", Block:"245", label:"SIDHAR GNANA PEEDAM SENIOR SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23089", district:"4", Block:"279", label:"RAK'S PALLIKKOODAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23090", district:"4", Block:"279", label:"MANCHESTER INTERNATIONAL SCHOOL CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23091", district:"29", Block:"176", label:"MONTFORT SCHOOL (CBSE) PALAKURICHY", Sch_type:"Un-aided", Category:"High School"},
    { key:"23092", district:"29", Block:"176", label:"PONNAR SANKAR KALVIYALYA WORLD SHOOL (CBSE) IRATTIYAPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23093", district:"29", Block:"176", label:"ALIF MATRICULATION SCHOOL THUVARANKURICHY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23094", district:"1", Block:"81", label:"SRI LAKSHMI VIDYALAYA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23095", district:"28", Block:"369", label:"ESTHER SANTHAM HR SEC SCHOOL MEIGNANAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23096", district:"8", Block:"20", label:"NAKKEERAN MATRICULATION SCHOOL, SANDHIPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23097", district:"8", Block:"65", label:"SRI CHAITANYA TECHNO SCHOOL, THINDAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23098", district:"4", Block:"279", label:"KOVAI VIDYA MANDHIR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23099", district:"4", Block:"245", label:"NIRMALA MATHA CONVENT SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23100", district:"7", Block:"127", label:"ZION INTERNATIONAL SCHOOL, KODAIKANAL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23101", district:"2", Block:"299", label:"SRICHIATANYA TECHNO SCHOOL, PERUNGUDI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23102", district:"2", Block:"300", label:"VELS VIDYASHRAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23103", district:"2", Block:"322", label:"GURU SHREE SHANTIVIJAI JAIN GURUKUL  NALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23104", district:"10", Block:"146", label:"ST JOSEPH MATRIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23105", district:"2", Block:"114", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23106", district:"16", Block:"196", label:"E.G.S.P.J INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23107", district:"16", Block:"196", label:"SIR ISSAC NEWTON CBSE  SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23108", district:"22", Block:"30", label:"ATTUR PUBLIC MATRICULATION SCHOOL, RAMANAYAKANPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23109", district:"22", Block:"129", label:"SRI RAMAKRISHNA VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23110", district:"28", Block:"320", label:"ST JOSEPHS SCHOOL VEERAPANDIYANPATNAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23111", district:"30", Block:"174", label:"ST JOSEPH GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23112", district:"30", Block:"361", label:"SOCRATEES MODEL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23113", district:"24", Block:"119", label:"SR SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23114", district:"30", Block:"262", label:"HARVARD INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23115", district:"32", Block:"371", label:"KENDRIYA VIDYALAYA , UDUMALPET", Sch_type:"Central Govt", Category:"Middle School"},
    { key:"23116", district:"32", Block:"32", label:"SELLANDIAMMAN HIGH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23117", district:"33", Block:"260", label:"SRI CHAITANYA TECHNO SCHOOL (CBSE) ", Sch_type:"Un-aided", Category:"High School"},
    { key:"23118", district:"33", Block:"403", label:"THE LEO ACADEMY OF EXCELLENCE (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"23119", district:"34", Block:"312", label:"THE GREEN MOUNT INTERNATIONAL SCHOOL RADHAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23120", district:"34", Block:"358", label:"VIJAY VIDYA MANDIR CBSE SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23121", district:"34", Block:"48", label:"INDIAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23122", district:"34", Block:"249", label:"VANMUKIL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23123", district:"34", Block:"44", label:"NANDHEESWARA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23124", district:"34", Block:"365", label:"AKSARA VIDHYAMANDIR ", Sch_type:"Un-aided", Category:"High School"},
    { key:"23125", district:"9", Block:"323", label:"SRI SARADHA VIDHYASHRAM ,T.KEERANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23126", district:"37", Block:"70", label:"SRI RANGAPOOPATHI INTERNATIONAL SCHOOL , ALAMPOONDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23127", district:"38", Block:"264", label:"SRI ANNAI VIDYALAYA MATRICULATION SCHOOL, CHETTIYARPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23128", district:"22", Block:"413", label:"EKALAVYA MODEL RESIDENTIAL SCHOOL, YERCAUD", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23129", district:"29", Block:"258", label:"CHRIST THE KING PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23130", district:"14", Block:"159", label:"TRIVENI SCHOOL, PONNAGARAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23131", district:"14", Block:"328", label:"ASHVATTHA SCHOOL, THANATHAVAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23132", district:"4", Block:"52", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23133", district:"18", Block:"399", label:"ST JOSEPH GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23134", district:"33", Block:"403", label:"NARAYANA E-TECHNO SCHOOL,RAGHAVENDRA NAGAR,RAMAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23135", district:"29", Block:"171", label:"MOUNT HIRA PUBLIC SCHOOL, K.SATHANUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23136", district:"29", Block:"343", label:"WISDOM WEALTH INTERNATION ICSE SCHOOL, MORAI CITY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23137", district:"37", Block:"404", label:"AKSHARDHAM CENTRAL SCHOOL - VILUPPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23138", district:"34", Block:"365", label:"MY FIRST SCHOOL INTERNATIONAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23139", district:"25", Block:"313", label:"BISHOP DEVADASS AMBROSE VIDYALAYA -THANJAVUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23140", district:"7", Block:"219", label:"BRILLIANT KIDS GLOBAL SCHOOL(CBSE), KANAKKANPATTY, PALANI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23141", district:"22", Block:"34", label:"GTRHS , ADIMALAIPUDUR", Sch_type:"Government", Category:"High School"},
    { key:"23142", district:"22", Block:"246", label:"GTR HIGH SCHOOL, KUNNUR", Sch_type:"Government", Category:"High School"},
    { key:"23143", district:"22", Block:"68", label:"GTR HIGH SCHOOL, KODUNGAL", Sch_type:"Government", Category:"High School"},
    { key:"23144", district:"36", Block:"74", label:"G.E.T PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23145", district:"30", Block:"385", label:"Netaji Subhash Chandra Bose Avasiya Vidyalaya- Valliyoor", Sch_type:"Government", Category:"Middle School"},
    { key:"23146", district:"38", Block:"396", label:"MADURAI C.E.O.A MATRICULATION SCHOOL, SALVARPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23147", district:"33", Block:"403", label:"VELAMMAL VIDYALAYA PORUR CHENNAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23148", district:"33", Block:"260", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23149", district:"12", Block:"140", label:"ISHA VIDHYA JUSTDIAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23150", district:"32", Block:"223", label:"WHITE CLOUDS WORLD SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23151", district:"32", Block:"363", label:"ADVAITA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23152", district:"4", Block:"52", label:"LISIEUX CMI INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23153", district:"34", Block:"249", label:"SREE YOGARAMS MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23154", district:"34", Block:"80", label:"GTRHS ARASUVELI", Sch_type:"Government", Category:"High School"},
    { key:"23155", district:"37", Block:"130", label:"SARASWATHI EXCEL MATRICULATION SCHOOL SOZHAGANUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23156", district:"30", Block:"46", label:"SCAD INTERNATIONAL SCHOOL, CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23157", district:"2", Block:"153", label:"GOOD SHEPHERD INTERNATIONAL SCHOOL VEPANCHERY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23158", district:"2", Block:"300", label:"GURUKULAM VIDYASHRAM WEST TAMBARAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23159", district:"2", Block:"114", label:"VIKAS MANTRA PUBLIC SCHOOL ANJUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23160", district:"4", Block:"52", label:"NAVY CHILDREN SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23161", district:"30", Block:"221", label:"MEENA SANKAR VIDYALAYA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23162", district:"30", Block:"385", label:"INDIAN MATRICULATON SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23163", district:"4", Block:"52", label:"SNV GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23164", district:"26", Block:"370", label:"THE OXFORD CONTINENTAL SCHOOL ADASHOLA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23165", district:"21", Block:"21", label:"AMBARI VIDYA MANDIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23166", district:"30", Block:"92", label:"MVM CAMBRIDGE VIDYA MANDIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23167", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL WELFARE HIGH SCHOOL KILAKADU", Sch_type:"Government", Category:"High School"},
    { key:"23168", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PACHERY", Sch_type:"Government", Category:"High School"},
    { key:"23169", district:"32", Block:"154", label:"BHARATHI VIDHYA MANDIR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23170", district:"26", Block:"72", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL BOKKAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"23171", district:"24", Block:"119", label:"SIVA SAKTHI VIDYALAYA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23172", district:"5", Block:"115", label:"THIRUGNANAM INTERNATIONAL SCHOOL,MELPULIYANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23173", district:"30", Block:"222", label:"ZAREENA FATHIMA MATRIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23174", district:"10", Block:"101", label:"SOORYAN MATRICULATION SCHOOL CHITHERIMEDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23175", district:"4", Block:"107", label:"KARAMADAI RANGANATHAR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23176", district:"7", Block:"207", label:"PENIEL SCHOOL(CBSE), VEMPARALI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23177", district:"22", Block:"34", label:"VIDHYA PEETHAM I-SCHOOL, MANNARPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23178", district:"17", Block:"224", label:"SRI RAGAVENDRA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23179", district:"5", Block:"115", label:"SBG VIDHYALAYA MATRICULATION SCHOOL, THENPATHY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23180", district:"4", Block:"279", label:"ADITHYA GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23181", district:"13", Block:"291", label:"STANFORD PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23182", district:"8", Block:"185", label:"RAJENDRAN ACADEMY FOR LEARNING, VADUGAPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23183", district:"8", Block:"185", label:"THE GREENS SCHOOL, KANAGAPURAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23184", district:"4", Block:"52", label:"BHARATHI SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23185", district:"38", Block:"264", label:"MIRASU VEERAPPA NAIDU VIDYALAYA MATRICULATION SCHOOL, CHETTIYARPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23186", district:"38", Block:"264", label:"SIVAKAMI HIGH SCHOOL, RAJAPALAYAM", Sch_type:"Partially Aided", Category:"High School"},
    { key:"23187", district:"11", Block:"3", label:"AVATAR INTERNATIONAL MODEL SCHOOL -VALAN NAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23188", district:"5", Block:"227", label:"SPDS JAYANTHI VIDYAPEEDAM ,KADAMPULIYUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23189", district:"30", Block:"221", label:"VELS VIDHYALAYA CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23190", district:"33", Block:"403", label:"RUDRAPPASAMY SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23191", district:"33", Block:"260", label:"SRI VENKATESWARA PUBLIC SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"High School"},
    { key:"23192", district:"14", Block:"158", label:"KENDIRIYA VIDYALAYA ITBP SCHOOL, IDAYAPATTI", Sch_type:"Central Govt", Category:"Middle School"},
    { key:"23193", district:"7", Block:"219", label:"HOLY CROSS SCHOOL (CBSE), PALANI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23194", district:"17", Block:"63", label:"SHRI RAMACHANDIRA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23195", district:"24", Block:"119", label:"WONDER SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23196", district:"37", Block:"401", label:"VILLUPURAM VIDYALAYA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23197", district:"38", Block:"411", label:"RANGARAO LIONS MATRICULATION HR. SEC SCHOOL, MAHARAJAPURAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23198", district:"2", Block:"329", label:"COLOURS INDIAN SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23199", district:"15", Block:"284", label:"SREE KRISHNA MATRICULATION HS SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23200", district:"36", Block:"113", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23201", district:"24", Block:"89", label:"GANDHIGRAM TRUST AVVAI ASHRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23202", district:"19", Block:"251", label:"CT INTERNATIONAL SCHOOL,KESARAPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23203", district:"19", Block:"255", label:"SOUTH SPRING INTERNATIONAL SCHOOL,PERUNKONDANVIDUTHI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23204", district:"2", Block:"329", label:"THE SCHOOL - KFI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23205", district:"2", Block:"329", label:"EDUBBA SCHOOL, POLACHERY ROAD", Sch_type:"Un-aided", Category:"High School"},
    { key:"23206", district:"7", Block:"36", label:"JAYASEELAN PUBLIC SCHOOL, BATLAGUNDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23207", district:"3", Block:"237", label:"ICF VIDYANIKETAN", Sch_type:"Un-aided", Category:"High School"},
    { key:"23208", district:"4", Block:"19", label:"WHITE EAGLE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23209", district:"23", Block:"294", label:"SRI THIYAGARAJA MATRICULATION SCHOOL, PERUNGUDI.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23210", district:"23", Block:"294", label:"KENDRIYA VIDYALAYA ITBP , ILLUPPAIKUDI .", Sch_type:"Central Govt", Category:"Higher Secondary School"},
    { key:"23211", district:"4", Block:"242", label:"CHETTINAD VIDYA MANDIR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23212", district:"34", Block:"365", label:"SHAZAMMAL VIDHYA MANDHIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23213", district:"32", Block:"32", label:"SHRI CHENDOOR VIDHYALAYA MATRICULATION HR. SEC. SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23214", district:"33", Block:"76", label:"ELITE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23215", district:"33", Block:"403", label:"SWAMY'S SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23216", district:"4", Block:"242", label:"AKSHARA ACADEMY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23217", district:"12", Block:"112", label:"SRI CHAITANYA TECHNO SCHOOL , KARUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23218", district:"28", Block:"138", label:"RAVILLA VIDYALAYA - CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23219", district:"24", Block:"91", label:"GLOBAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23220", district:"33", Block:"252", label:"SINDHI MODEL SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23221", district:"10", Block:"146", label:"OLOGY TECH SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23222", district:"2", Block:"299", label:"CAMPUS K SCHOOL, SHOLLINGANALLUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23223", district:"2", Block:"299", label:"JEPPIAAR SCHOOL CHEMMENCHERY", Sch_type:"Un-aided", Category:"High School"},
    { key:"23224", district:"2", Block:"299", label:"SRI CHAITANYA TECHNO SCHOOL, PALAVAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23225", district:"11", Block:"182", label:"CHRIST THE KING SCHOOL- KANNUMAMOODU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23226", district:"21", Block:"409", label:"GHS (URDU), KALMELKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"23227", district:"2", Block:"329", label:"KC HIGH SCHOOL NAVALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23228", district:"9", Block:"347", label:"GOVERNMENT HIGH SCHOOL MUDIYANUR", Sch_type:"Government", Category:"High School"},
    { key:"23229", district:"37", Block:"189", label:"GOVERNMENT HIGH SCHOOL SU PILRAMPAT", Sch_type:"Government", Category:"High School"},
    { key:"23230", district:"9", Block:"372", label:"GOVERNMENT HIGH SCHOOL ADHAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"23231", district:"2", Block:"299", label:"GHSS PERUMBAKKAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23232", district:"22", Block:"203", label:"GHS MOOLAKKADU", Sch_type:"Government", Category:"High School"},
    { key:"23233", district:"22", Block:"129", label:"GHS CHEKKANUR", Sch_type:"Government", Category:"High School"},
    { key:"23234", district:"33", Block:"184", label:"GOVERNMENT HIGH SCHOOL , JAMELAPATH", Sch_type:"Government", Category:"High School"},
    { key:"23235", district:"4", Block:"245", label:"GHS KUNIYAMUTHUR", Sch_type:"Government", Category:"High School"},
    { key:"23236", district:"4", Block:"13", label:"GHS ANGALAKURUCHI", Sch_type:"Government", Category:"High School"},
    { key:"23237", district:"4", Block:"247", label:"GHS R.GOPALAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"23238", district:"4", Block:"302", label:"MATER DEI INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23239", district:"33", Block:"364", label:"TRS GLOBAL PUBLIC SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23240", district:"20", Block:"231", label:"MUNICIPAL HIGH SCHOOL, EMANESWARAM", Sch_type:"Government", Category:"High School"},
    { key:"23241", district:"8", Block:"37", label:"GOVERNMENT HIGH SCHOOL, KURUPPANAICKENPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"23242", district:"32", Block:"363", label:"GOVERNMENT HIGH SCHOOL , PERICHIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"23243", district:"32", Block:"363", label:"GOVERNMENT HIGH SCHOOL , MANGALAM", Sch_type:"Government", Category:"High School"},
    { key:"23244", district:"32", Block:"73", label:"GOVERNMENT HIGH SCHOOL , A.AMMAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"23245", district:"32", Block:"73", label:"GOVERNMENT HIGH SCHOOL , PERIYAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"23246", district:"2", Block:"162", label:"GHS MALAIPALAYAM", Sch_type:"Government", Category:"High School"},
    { key:"23247", district:"22", Block:"133", label:"GHS SANARAPATTI", Sch_type:"Government", Category:"High School"},
    { key:"23248", district:"34", Block:"27", label:"MHS ANATHAPURAM(SAIDAI)", Sch_type:"Government", Category:"High School"},
    { key:"23249", district:"25", Block:"287", label:"GHS SENTHALAIVAYAL", Sch_type:"Government", Category:"High School"},
    { key:"23250", district:"8", Block:"65", label:"GOVERNMENT HIGH SCHOOL, PERIYAVALASU", Sch_type:"Government", Category:"High School"},
    { key:"23251", district:"16", Block:"196", label:"GOVERNMENT HIGH SCHOOL SAMANTHANKUPPAM", Sch_type:"Government", Category:"High School"},
    { key:"23252", district:"33", Block:"252", label:"JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA HIGHER SECONDARY SCHOOL, AVADI, CHENNAI - 54", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23253", district:"17", Block:"360", label:"MUNICIPAL HIGH SCHOOL, NESAVALAR COLONY", Sch_type:"Government", Category:"High School"},
    { key:"23254", district:"17", Block:"201", label:"GOVERNMENT HIGH SCHOOL, CHINNAMUTHALAIPATTY", Sch_type:"Government", Category:"High School"},
    { key:"23255", district:"17", Block:"132", label:"GOVERNMENT HIGH SCHOOL, ALATHURNADU", Sch_type:"Government", Category:"High School"},
    { key:"23256", district:"36", Block:"243", label:"VAANI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23257", district:"28", Block:"138", label:"GHS ILAMBUVANAM", Sch_type:"Government", Category:"High School"},
    { key:"23258", district:"28", Block:"350", label:"ST.ANN'S SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23259", district:"30", Block:"204", label:"GHS SHENBAGARAMANALLUR", Sch_type:"Government", Category:"High School"},
    { key:"23260", district:"30", Block:"262", label:"ARPUTHAM SAMUEL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23261", district:"33", Block:"403", label:"D.A.V SECONDARY SCHOOL, MOGAPPAIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23262", district:"32", Block:"363", label:"NACHIYAR PADASALA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23263", district:"7", Block:"59", label:"SBC ACADEMY OF EXCELLENCE, SEELAPADI, DINDIGUL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23264", district:"3", Block:"259", label:"ST CHRISTOPHER'S ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"23265", district:"22", Block:"273", label:"GOLDEN GATES EARTH SCHOOL, KONDAPPANAICKENPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23266", district:"28", Block:"369", label:"JAY INTERNATIONAL SCHOOL NANGAIMOZHI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23267", district:"11", Block:"263", label:"PONJESLY PUBLIC SCHOOL -ALMAPARAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23268", district:"22", Block:"273", label:"SRI VIDYA MANDIR SCHOOL, KANNANKURICHI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23269", district:"33", Block:"403", label:"MARIAN SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23270", district:"5", Block:"211", label:"MAHARISHI VIDYA MANDIR ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23271", district:"2", Block:"299", label:"NARAYANA OLYMPIAD SCHOOL,MADIPAKKAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23272", district:"29", Block:"168", label:"GHS KARUTHAKODANGIPATTI", Sch_type:"Government", Category:"High School"},
    { key:"23273", district:"13", Block:"311", label:"GHS T.BELALAM", Sch_type:"Government", Category:"High School"},
    { key:"23274", district:"13", Block:"311", label:"GHS KODAGARAI", Sch_type:"Government", Category:"High School"},
    { key:"23275", district:"22", Block:"34", label:"HOLY CROSS INTERNATIONAL SCHOOL, K.N.COLONY, AMMAPET,SALEM-14", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23276", district:"3", Block:"240", label:"RAASHIDH MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23277", district:"2", Block:"114", label:"QUEEN MARYS ACADEMY MATRICULATION SCHOOL PORUNTHAVAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23278", district:"2", Block:"299", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23279", district:"2", Block:"300", label:"SAN ACADEMY  TAMBARAM WEST", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23280", district:"30", Block:"221", label:"KAMARAJAR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23281", district:"4", Block:"156", label:"NEHRU INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23282", district:"4", Block:"279", label:"VIBGYOR HIGH", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23283", district:"17", Block:"224", label:"K.S.INDIAN SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23284", district:"28", Block:"138", label:"KARIDHA PUBLIC SCHOOL, ETTAYAPURAM ROAD, KOVILPATTI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23285", district:"30", Block:"221", label:"IIP LAKSHMI RAMAN GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23286", district:"19", Block:"18", label:"GOVERNMENT HIGH SCHOOL KEZHA PALUVANCHI ", Sch_type:"Government", Category:"High School"},
    { key:"23287", district:"35", Block:"340", label:"CBSE MAHARISHI VIDYA MANDIR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23288", district:"37", Block:"100", label:"GOVERNMENT HIGH SCHOOL KADAYAM", Sch_type:"Government", Category:"High School"},
    { key:"23289", district:"29", Block:"172", label:"MAHALAKSHMI VIDYA MANDIR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23290", district:"38", Block:"295", label:"R.S.R INTERNATIONAL SCHOOL, SIVAKASI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23291", district:"33", Block:"252", label:"SRI CHAITANYA TECHNO SCHOOL, POONAMALLEE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23292", district:"1", Block:"16", label:"OHM SAKTHI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23293", district:"2", Block:"299", label:"SUJAY PUBLIC SCHOOL MEDAVAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23294", district:"18", Block:"400", label:"MAHATMA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23295", district:"38", Block:"411", label:"GOVT ADW HIGH SCHOOL, KOTTAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"23296", district:"18", Block:"7", label:"SARASWATHI VIDYALAYA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23297", district:"22", Block:"309", label:"MARUTHI MATRICULATION SCHOOL, MANIVIZHUNDHAN SOUTH", Sch_type:"Un-aided", Category:"High School"},
    { key:"23298", district:"28", Block:"217", label:"CHANDRA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23299", district:"12", Block:"348", label:"GOVERNMENT HIGH SCHOOL, KALLAI", Sch_type:"Government", Category:"High School"},
    { key:"23300", district:"35", Block:"137", label:"UAMS KALAIVANI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23301", district:"32", Block:"363", label:"EDMUNDS MEMORIAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23302", district:"2", Block:"162", label:"KARPAGA VINAYAGA GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23303", district:"2", Block:"329", label:"SHRADDHA CHILDRENS ACADEMY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23304", district:"9", Block:"95", label:"AKT MEMORIAL MATRICULATION GIRLS SCHOOL NEELAMANGALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23305", district:"24", Block:"6", label:"GHS KASINATHAPURAM", Sch_type:"Government", Category:"High School"},
    { key:"23306", district:"9", Block:"277", label:"GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PARANGINATHAM", Sch_type:"Government", Category:"High School"},
    { key:"23307", district:"2", Block:"299", label:"VISTA BILLABONG HIGH INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23308", district:"4", Block:"279", label:"VIDHYA NIKETAN SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23309", district:"33", Block:"260", label:"VELAMMAL NEW - GEN SCHOOL , THIRUVOTTIYUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23310", district:"21", Block:"24", label:"GOVT. ADW.HIGH SCHOOL, VEPPUR", Sch_type:"Government", Category:"High School"},
    { key:"23311", district:"2", Block:"299", label:"THE BAY INTERNATIONAL SCHOOL &  JUNIOR COLLEGE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23312", district:"4", Block:"156", label:"THAAMARAI WORLD SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23313", district:"14", Block:"324", label:"NRM DREAM INTERNATIONAL SCHOOL, SATHANGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23314", district:"36", Block:"113", label:"FIITJEE GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23315", district:"20", Block:"169", label:"DR. ILAYARAJA GLOBAL ACADEMY, PIRAPPANVALASAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23316", district:"34", Block:"412", label:"SHRI RAMACHANDRA SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23317", district:"28", Block:"138", label:"KAMARAJ INTERNATIONAL ACADEMY", Sch_type:"Un-aided", Category:"High School"},
    { key:"23318", district:"14", Block:"183", label:"GOVT HIGH SCHOOL, KEELAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"23319", district:"14", Block:"303", label:"GOVT HIGH SCHOOL, SANTHAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"23320", district:"22", Block:"226", label:"VEDHHA VIKASS PUBLIC SCHOOL ,SANTHIYUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23321", district:"22", Block:"273", label:"SRK GLOBAL SCHOOL ,KANDHAMPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23322", district:"22", Block:"413", label:"SACRED HEART SCHOOL ,YERCAUD", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23323", district:"34", Block:"365", label:"AQSA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23324", district:"20", Block:"231", label:"KAVINA INTERNATIONAL SCHOOL, PAMBOOR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23325", district:"32", Block:"190", label:"VANJIAMMAN VIDYA VIKAS MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23326", district:"25", Block:"144", label:"VELAMMAL BODHI CAMPUS- KUMBAKONAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23327", district:"24", Block:"307", label:"OXFORD PUBLIC SCHOOL, KUTHUKKALVALASAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23328", district:"24", Block:"307", label:"GREEN PARK MATRICULATION SCHOOL, TENKASI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23329", district:"24", Block:"6", label:"JACKSON MATRICULATION SCHOOL, VENKATESWARAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23330", district:"25", Block:"313", label:"KALAIMAGAL VIDHYA MANDIR SCHOOL -THANJAVUR RURAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23331", district:"8", Block:"65", label:"VELAMMAL BODHI CAMPUS, MELTHINDAL, ERODE", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23332", district:"8", Block:"185", label:"THE NAVARRASAM ACADEMY, ARACHALUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23333", district:"21", Block:"24", label:"MMES PUBLIC SCHOOL, MELVISHARAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23334", district:"33", Block:"260", label:"OUR LADY SCHOOL OF EXCELLENCE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23335", district:"33", Block:"403", label:"GREEN VALLEY CENTRAL SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23336", district:"33", Block:"403", label:"M.G.R. ADARSH SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23337", district:"33", Block:"252", label:"JOSEPH VIDYA KSHETRA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23338", district:"33", Block:"252", label:"GRACE PARK PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23339", district:"10", Block:"146", label:"VAGISHA VIDHYALAYA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23340", district:"4", Block:"242", label:"ANGAPPA ACADEMY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23341", district:"34", Block:"387", label:"SWAMY ABEDHANANDHA VIDHYASHRAM SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23342", district:"1", Block:"81", label:"JAI HIND INTERNATIOANL VIDHYALAYA SCHOOL GURUVALAPPARKOVIL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23343", district:"38", Block:"407", label:"SFS MAT SCHOOL", Sch_type:"Un-aided", Category:"Others"},
    { key:"23344", district:"4", Block:"242", label:"AKSHAYA ACADEMY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23345", district:"3", Block:"62", label:"P.N.D. ADARSH VIDYALAYA SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23346", district:"3", Block:"240", label:"THE SHRI RAM UNIVERSAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23347", district:"5", Block:"227", label:"Balavihar Matric Hr. Sec. School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23348", district:"11", Block:"3", label:"Bharathi Vidhya Mandir Matriculation School", Sch_type:"Un-aided", Category:"High School"},
    { key:"23349", district:"2", Block:"299", label:"Gateway the Complete School", Sch_type:"Un-aided", Category:"Others"},
    { key:"23350", district:"32", Block:"32", label:"SAKTHIDEVI MATRIC KANIYAMPOONDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23351", district:"4", Block:"52", label:"Dharmamurthi sri SRPPS Vidhyalaya mat. School", Sch_type:"Un-aided", Category:"High School"},
    { key:"23352", district:"4", Block:"245", label:"APG Matric Hr. Sec. School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23353", district:"2", Block:"299", label:"RV Matriculation school", Sch_type:"Un-aided", Category:"High School"},
    { key:"23354", district:"14", Block:"158", label:"Chethana Mat. Hr. Sec. School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23355", district:"19", Block:"22", label:"Excellent nursery & primary( private) school", Sch_type:"Un-aided", Category:"Primary School"},
    { key:"23356", district:"19", Block:"22", label:"Swamy Vivekananda nursery & primary( private) school", Sch_type:"Un-aided", Category:"Primary School"},
    { key:"23357", district:"4", Block:"245", label:"Shri Meenakshi Matric Higher Secondary School", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23358", district:"2", Block:"299", label:"St.Paul’s matriculation higher secondary school", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23359", district:"11", Block:"341", label:"Hari Sree Matriculation Higher Secondary school", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23360", district:"8", Block:"244", label:"SRI ARUL NURSERY AND PRIMARY SCHOOL", Sch_type:"Unaided", Category:"Primary School"},
    { key:"23361", district:"24", Block:"288", label:"ANNAI THERASA METRICULATION SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23362", district:"4", Block:"302", label:"Kathir Vidyaa Mandhir Secondary School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23363", district:"12", Block:"315", label:"SHRI GURU BRAMMA VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23364", district:"30", Block:"222", label:"Good Shepherd school", Sch_type:"Unaided", Category:"Others"},
    { key:"23365", district:"8", Block:"37", label:" Sri sakthi supreme ideal vikas matric higher secondary school", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23366", district:"14", Block:"160", label:"DEVASAKAYAM CHETTIYAR MIDDLE SCHOOL", Sch_type:"Unaided", Category:"Middle School"},
    { key:"23367", district:"14", Block:"158", label:"K KANDASAMY KOTHANAR HR.SEC SCHOOL", Sch_type:"Fully Aided", Category:"Higher Secondary School"},
    { key:"23368", district:"5", Block:"39", label:"BEST MATRICULATION SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23369", district:"8", Block:"65", label:"Nandha Matric Hr.Sec. School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23370", district:"34", Block:"412", label:"S.G.S Matriculation School", Sch_type:"Unaided", Category:"High School"},
    { key:"23371", district:"34", Block:"44", label:"Sri Sarathi balamandir matric higher secondary school", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23372", district:"2", Block:"329", label:"Sushil Hari International Residency school", Sch_type:"Unaided", Category:"Others"},
    { key:"23373", district:"10", Block:"101", label:"USHA MATRICULATION SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23374", district:"8", Block:"37", label:"kamala vijaya vidhyalaya school", Sch_type:"Unaided", Category:"Others"},
    { key:"23375", district:"7", Block:"219", label:"RAINBOW NURSERY AND PRIMARY SCHOOL", Sch_type:"Unaided", Category:"Primary School"},
    { key:"23376", district:"4", Block:"52", label:"SRPPS Vidhyalaya Matric School", Sch_type:"Unaided", Category:"High School"},
    { key:"23377", district:"28", Block:"280", label:"St. John's NURSERY AND PRIMARY SCHOOL", Sch_type:"Unaided", Category:"Primary School"},
    { key:"23378", district:"4", Block:"301", label:"Sree lakshmi vidhya mandir matriculation school", Sch_type:"Unaided", Category:"High School"},
    { key:"23379", district:"17", Block:"224", label:"Annai sellammal matriculation school", Sch_type:"Unaided", Category:"High School"},
    { key:"23380", district:"4", Block:"279", label:"Jay Matriculation School", Sch_type:"Unaided", Category:"High School"},
    { key:"23381", district:"22", Block:"273", label:"Sugam Matriculation Hr.Sec.School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23382", district:"11", Block:"355", label:"May Flower Matric Hr Sec School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23383", district:"2", Block:"300", label:"SDMS Jain Vidhyalaya Matriculation School", Sch_type:"Unaided", Category:"High School"},
    { key:"23384", district:"33", Block:"403", label:"NARAYANA MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23385", district:"6", Block:"77", label:" Little flower HR SEC SCHOOL", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23386", district:"9", Block:"50", label:"Denish mission middle school", Sch_type:"Unaided", Category:"Middle School"},
    { key:"23387", district:"32", Block:"392", label:"K.K.P. MATRIC HR SEC SCHOOL", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23388", district:"25", Block:"11", label:"INDIAN CITY SCHOOL", Sch_type:"Unaided", Category:"Others"},
    { key:"23389", district:"10", Block:"101", label:"kattai koothu gurukul", Sch_type:"Unaided", Category:"High School"},
    { key:"23390", district:"6", Block:"109", label:"MULLAI HIGH SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23391", district:"4", Block:"107", label:"SRI RAGAVENDRA VIDYALAYA.", Sch_type:"Unaided", Category:"Others"},
    { key:"23392", district:"4", Block:"245", label:"APG Matriculation Higher Secondary School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23393", district:"22", Block:"391", label:"THE OXFORD MATRIC SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23394", district:"10", Block:"410", label:"GOOD SHEPHARD MATRICULATION SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23395", district:"17", Block:"201", label:"Anna-Nehru Matric Hr Sec School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23396", district:"22", Block:"226", label:"Diamond Ray's Mat HrSec School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23397", district:"33", Block:"225", label:"Sri Vidya mandir  matric school", Sch_type:"Unaided", Category:"High School"},
    { key:"23398", district:"8", Block:"65", label:"CSI Brough Matriculation school ", Sch_type:"Unaided", Category:"High School"},
    { key:"23399", district:"32", Block:"250", label:"Sri Ramakrishna Vidhyalaya Matric HR.sec school", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23400", district:"24", Block:"91", label:"RVS Matriculation School", Sch_type:"Unaided", Category:"High School"},
    { key:"23401", district:"4", Block:"302", label:"Kathir Vidhyashram Matriculation higher secondary school", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23402", district:"13", Block:"35", label:"KKP Matriculation School", Sch_type:"Unaided", Category:"High School"},
    { key:"23403", district:"8", Block:"65", label:"Yuvabharathi global school( CBSE)", Sch_type:"Unaided", Category:"High School"},
    { key:"23404", district:"22", Block:"61", label:"Little Star Nursery Primary and High School", Sch_type:"Unaided", Category:"High School"},
    { key:"23405", district:"5", Block:"17", label:"Danish Mission Hr Sec School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23406", district:"17", Block:"267", label:"SRP International School (CBSE)", Sch_type:"Unaided", Category:"Others"},
    { key:"23407", district:"29", Block:"357", label:"Vedha Green School", Sch_type:"Unaided", Category:"Others"},
    { key:"23408", district:"9", Block:"50", label:"LITTLE FLOWER HIGHER SECONDARY SCHOOL", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23410", district:"9", Block:"95", label:"SIRUMALAR INTERNATIONAL SCHOOL", Sch_type:"Unaided", Category:"Others"},
    { key:"23409", district:"2", Block:"299", label:"The Indian Public School", Sch_type:"Unaided", Category:"High School"},
    { key:"23411", district:"3", Block:"2", label:"nehru high school", Sch_type:"Unaided", Category:"High School"},
    { key:"23412", district:"20", Block:"266", label:"Mannar Sethupathy Matriculation Hr Sec School", Sch_type:"Unaided", Category:"Higher Secondary School"},
    { key:"23413", district:"10", Block:"296", label:"ST.THOMAS MATRICULATION  SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23414", district:"17", Block:"224", label:"Sri Akilandeswari nursery and primary school", Sch_type:"Unaided", Category:"Primary School"},
    { key:"23415", district:"32", Block:"145", label:"Shree Venaudaiyar Vidhyalaya School,", Sch_type:"Unaided", Category:"High School"},
    { key:"23416", district:"22", Block:"413", label:"Brightsun Matric School", Sch_type:"Unaided", Category:"High School"},
    { key:"23417", district:"33", Block:"403", label:"PRS Matric School", Sch_type:"Unaided", Category:"High School"},
    { key:"23418", district:"6", Block:"198", label:"National Child Labour Project, Special Training  Center", Sch_type:"Unaided", Category:"Middle School"},
    { key:"23419", district:"32", Block:"363", label:"SRI KUMARAN MATRIC SCHOOL", Sch_type:"Unaided", Category:"High School"},
    { key:"23420", district:"22", Block:"61", label:"Bharathi vidyalaya nursery and primary school", Sch_type:"Unaided", Category:"Primary School"},
    { key:"23421", district:"6", Block:"187", label:"Pughal Matriculation School", Sch_type:"Unaided", Category:"High School"},
    { key:"23422", district:"8", Block:"244", label:"Brainstorm High school", Sch_type:"Unaided", Category:"High School"},
    { key:"23423", district:"2", Block:"114", label:"SRI T.S. BALIAH MEMORIAL MATRICULATION HS", Sch_type:"Unaided", Category:"High School"},
    { key:"23424", district:"3", Block:"62", label:"Olive International School", Sch_type:"Unaided", Category:"High School"},
    { key:"23425", district:"4", Block:"19", label:"BENEVOLENCE INTERNATIONAL PUBLIC SCHOOL", Sch_type:"Unaided", Category:"Middle School"},
    { key:"23426", district:"1", Block:"16", label:"FRANS SCHLOOZ SCHOOL OF EXCELLENCE (CBSE)", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23427", district:"1", Block:"81", label:"PM PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23428", district:"1", Block:"81", label:"JAIHINDH INTERNATIONAL VIDHYALAYAV", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23429", district:"1", Block:"346", label:"ARIYALUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23430", district:"2", Block:"1", label:"AALAM INTERNATIONAL SCHOOL, THOZHUPEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"23431", district:"2", Block:"114", label:"VELAMMAL NEWGEN SCHOOL NEDUNGUNDRAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23432", district:"2", Block:"114", label:"VIDYA MANDIR SENIOR SECONDARY SCHOOL ESTANCIA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23433", district:"2", Block:"162", label:"VIVEKANANDA VIDYALAYA MADURANDAGAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23434", district:"2", Block:"299", label:"SHREE NIKETAN PATASALA AGARAMTHEN", Sch_type:"Un-aided", Category:"High School"},
    { key:"23435", district:"2", Block:"299", label:"FIIT JEE GLOBAL SCHOOL  VENGAMBAKKAM", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23436", district:"2", Block:"299", label:"VELAMMAL NEWGEN SCHOOL, MADIPAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23437", district:"2", Block:"299", label:"MAHARISHI VIDYA MANDIR KOVILAMBAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23438", district:"2", Block:"299", label:"ANNAI ARUL PUBLIC SCHOOL, MADAMBAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23439", district:"2", Block:"299", label:"MAHARISHI VIDYA MANDIR, THIRUNEERMALAI", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23440", district:"2", Block:"299", label:"THE INTERNATIONAL VILLAGE SCHOOL SHOLLINGANALLUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23441", district:"2", Block:"299", label:"GATEWAY THE COMPLETE SCHOOL, SHOLLINGANALLUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23442", district:"2", Block:"329", label:"ADWHS MELAKOTTAIYUR", Sch_type:"Government", Category:"High School"},
    { key:"23443", district:"2", Block:"329", label:"VELAMMAL NEW GEN SCHOOL - NAVALUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23444", district:"2", Block:"329", label:"VELAMMAL VIDHYASHRAM PADUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23445", district:"2", Block:"329", label:"VELLORE INTERNATIONAL RESIDENTIAL SCHOOL  KAYAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23446", district:"2", Block:"329", label:"JAGANNATH VIDYALAYA MATRICULATION SCHOOL. THANDALAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23447", district:"3", Block:"2", label:"A.M.M SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23448", district:"3", Block:"2", label:"Centre of Academic Excellence Saidapet, Chennai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23449", district:"3", Block:"2", label:"K.S.N SECONDARY SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23450", district:"3", Block:"414", label:"ORCHIDS THE INTERNATIONAL SCHOOL, THURAIPAKKAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23451", district:"3", Block:"414", label:"SAIRAM LEOMUTHU PUBLIC SCHOOL VENGAIVASAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23452", district:"3", Block:"414", label:"ADHITHYA PUBLIC SCHOOL, SHOLINGANALLUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23453", district:"3", Block:"415", label:"JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA, ANNA NAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23454", district:"3", Block:"62", label:"Government Model School, Chennai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23455", district:"3", Block:"416", label:"AVM RAJESWARI - THE SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23456", district:"3", Block:"270", label:"SANA SMART MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23457", district:"3", Block:"417", label:"MANOJ KIRAN SPECIAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23458", district:"3", Block:"418", label:"CHENNAI DISTRICT GOVERNMENT MODEL SCHOOL, CHENNAI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23459", district:"3", Block:"418", label:"SRIRAM VIDHYA MANDIR SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23460", district:"4", Block:"13", label:"SRINITHI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23461", district:"4", Block:"52", label:"Government Model School, Coimbatore", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23462", district:"4", Block:"156", label:"FAITH MODEL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23463", district:"4", Block:"242", label:"IMMACULATE INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23464", district:"4", Block:"242", label:"AVANI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23465", district:"4", Block:"242", label:"BHARATHIYA VIDYA BHAVAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23466", district:"4", Block:"245", label:"PRISTINE NURSERY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23467", district:"4", Block:"245", label:"CS ACADEMY INTERNATIONAL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23468", district:"4", Block:"245", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23469", district:"4", Block:"247", label:"ARAM PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23470", district:"4", Block:"248", label:"SHAANTHI SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23471", district:"4", Block:"254", label:"COIMBATORE DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23472", district:"4", Block:"302", label:"SBIOA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23473", district:"4", Block:"349", label:"KIKANI GLOBAL ACADEMY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23474", district:"5", Block:"39", label:"SRI KALAIMAGAL VIDHYALAYANURSERY&PRIMARY SCHOOL, MARUDHUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23475", district:"5", Block:"54", label:"CUDDALORE DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23476", district:"5", Block:"148", label:"JAYAPRIYA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23477", district:"6", Block:"58", label:"DHARMAPURI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23478", district:"6", Block:"58", label:"Government Model School, Dharmapuri", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23479", district:"6", Block:"218", label:"NEW GENERATION N&P SCHOOL, PANJAPALLI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23480", district:"7", Block:"390", label:"SREE SARASWATHY ACADEMY MATRICULATION SCHOOL, SREERAMAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23481", district:"8", Block:"65", label:"ERODE PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23482", district:"8", Block:"65", label:"SIFT MODEL MIDDLE SCHOOL, ERODE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23483", district:"8", Block:"185", label:"AMRITA VIDYALAYAM, PUNJAILAKKAPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23484", district:"8", Block:"244", label:"ERODE DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23485", district:"9", Block:"95", label:"KALLAKURICHI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23486", district:"9", Block:"372", label:"SRI GNANAMBIGAA VIDYALAYA CBSE SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23487", district:"10", Block:"101", label:"SRI LAKSHMI GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23488", district:"10", Block:"410", label:"VELAMMAL BODHI CAMPUS", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23489", district:"11", Block:"124", label:"ST.JUDE'S HI-TECH SCHOOL- SOUTH NEERVAKUZHI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23490", district:"11", Block:"149", label:"MOTHER THERASA (SCHOOL FOR THE INTELLECTUAL DIASABLES) -KOVILANVILAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23491", district:"11", Block:"341", label:"GTR HIGH SCHOOL -MANALODAI", Sch_type:"Government", Category:"High School"},
    { key:"23492", district:"11", Block:"354", label:"KOWSHIKA KENDRIYA VIDHYALAYA, DERISANAMCOPE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23493", district:"12", Block:"83", label:"CHERAN UNIVERSAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23494", district:"12", Block:"315", label:"KARUR PUBLIC SCHOOL(CBSE)", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23495", district:"12", Block:"315", label:"THE ADRIAN LOYAL SCHOOL (CBSE)", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23496", district:"13", Block:"78", label:"AGASTHYA VIDYA MANDIR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23497", district:"13", Block:"78", label:"BRUNDAVANA SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23498", district:"13", Block:"78", label:"THE OAKRICH INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23499", district:"13", Block:"78", label:"ST. XAVIER'S ACADEMY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23500", district:"13", Block:"78", label:"BMR GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23501", district:"13", Block:"139", label:"KRISHNAGIRI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23502", district:"13", Block:"139", label:"GOVERNMENT MODEL HIGHER SECONDARY SCHOOL KRISHNAGIRI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23503", district:"13", Block:"311", label:"SRI BALAGANGADARA SWAMIJI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23504", district:"13", Block:"376", label:"OXFORD INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23505", district:"14", Block:"97", label:"KOTHARI PUBLIC SCHOOL, SIVARAKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23506", district:"14", Block:"158", label:"THE INDIAN PUBLIC SCHOOL, KARUPPAYURANI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23507", district:"14", Block:"158", label:"MPF INTERNATIONAL SCHOOL, MAYILANGUNDU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23508", district:"14", Block:"161", label:"VIKAASA JUBILEE SCHOOL, LOURDHU NAGAR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23509", district:"14", Block:"306", label:"NAM VIDHYA MANDIR MATRICULATION SCHOOL, PAALPANNAI ROAD. T.VADIPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23510", district:"14", Block:"324", label:"GOVT.KALLAR GIRLS RESIDENTIAL SCHOOL., CHECKANURANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23511", district:"14", Block:"324", label:"GOVT.KALLAR BOYS RESIDENTIAL SCHOOL., CHECKANURANI", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23512", district:"14", Block:"328", label:"Government Model School, Madurai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23513", district:"14", Block:"328", label:"MADURAI DISTRICT GOVERNMENT MODEL SCHOOL, THOPPUR", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23514", district:"14", Block:"328", label:"MAHARISHI VIDYA MANDIR, THANAKKANKULAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23515", district:"14", Block:"328", label:"ST. ANNE'S MATRICULATION SCHOOL, PONMENI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23516", district:"14", Block:"328", label:"RIGHT WAY ISLAMIC MATRICULATION SCHOOL., AVANIYAPURAM.", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23517", district:"14", Block:"374", label:"GREEN PARK VIDYALAYA NURSURY AND PRIMARY SCHOOL, J.J. NAGAR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23518", district:"16", Block:"196", label:"NAGAPATTINAM DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23519", district:"17", Block:"224", label:"EXCEL PUBLIC SCHOOL (CBSE), PALLAKKAPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23520", district:"17", Block:"254", label:"PAAVAI MATRICULATION HIGHER SECONDARY SCHOOL, PACHAL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23521", district:"17", Block:"360", label:"THE JPS ACADEMY MATRICULATION SCHOOL, TIRUCHENGODE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23522", district:"17", Block:"397", label:"VIDHYA NIKETHAN INTEL PUBLIC SCHOOL, THENGALPALAYAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23523", district:"18", Block:"7", label:"ANNAI MATRICULATION SCHOOL PADALUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23524", district:"18", Block:"236", label:"MALAR MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23525", district:"18", Block:"236", label:"SRI MUTHAIYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23526", district:"18", Block:"236", label:"AADHAV PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Others"},
    { key:"23527", district:"18", Block:"399", label:"PERAMBALUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23528", district:"19", Block:"18", label:"M.S.A  MATRICULATION SCHOOL. KEELAKURICHI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23529", district:"19", Block:"255", label:"DELHI PUBLIC SCHOOL , PUDUKKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23530", district:"20", Block:"169", label:"RAJA PUBLIC SCHOOL, KUNJARVALASAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23531", district:"20", Block:"169", label:"RAMAKRISHNA MATH VIVEKANANDA VIDYALAYA CBSE SCHOOL, NAGACHI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23532", district:"20", Block:"266", label:"RAMANATHAPURAM DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23533", district:"21", Block:"24", label:"RANIPET DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23534", district:"21", Block:"210", label:"Nalanda Matriculation School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23535", district:"21", Block:"290", label:"MONISHA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23536", district:"21", Block:"359", label:"Mahalakshmi Vidhyashram School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23537", district:"21", Block:"408", label:"MERCURY MATRICULATION SCHOOL, ARCOT", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23538", district:"21", Block:"409", label:"LEADERS VIDHYASHRAM CBSE SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23539", district:"22", Block:"34", label:"A.R.R.S. MATRIC HR. SEC. SCHOOL, VALASAIYUR", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23540", district:"22", Block:"34", label:"ROYAL PARK SCHOOL, KUMARAGIRI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23541", district:"22", Block:"90", label:"Government Model School, Salem", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23542", district:"22", Block:"90", label:"SALEM DISTRICT GOVERNMENT MODEL SCHOOL, SALEM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23543", district:"22", Block:"133", label:"STANFORD MATRICULATION SCHOOL, ETTIKUTTAI MEDU", Sch_type:"Un-aided", Category:"High School"},
    { key:"23544", district:"22", Block:"179", label:"MEENAMPARK VIDHYALAYA SCHOOL, PERIYASATHAPPADI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23545", district:"22", Block:"215", label:"SRI SAI GURU PUBLIC SCHOOL, VELAGOUNDANPUDUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23546", district:"22", Block:"273", label:"EMERALD VALLEY PUBLIC SCHOOL, KONDAPPANAIKANPATTY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23547", district:"22", Block:"274", label:"ST. MARY'S PUBLIC SCHOOL, ARISIPALAYAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23548", district:"22", Block:"278", label:"SRI KONGU MATRICULATION SCHOOL, SANKARI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23549", district:"23", Block:"56", label:"ST.MARY'S MATRICULATION SCHOOL, DEVAKOTTAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23550", district:"23", Block:"94", label:"OMGS SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23551", district:"23", Block:"96", label:"MOUNT ZION SILVER JUBILEE SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23552", district:"23", Block:"106", label:"INTEL VIDYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23553", district:"23", Block:"294", label:"SIVAGANGAI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23554", district:"23", Block:"294", label:"PANNAI MARIVENI GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23555", district:"23", Block:"331", label:"BSR GREEN PARK INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23556", district:"23", Block:"333", label:"VIRUDHUNAGAR HINDU NADAR THOLANDI MANICKAM NADAR JANAKI AMMAL GIRLS MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23557", district:"24", Block:"288", label:"DMI ST.JOSEPH GLOBAL SCHOOL AGARAKATTU", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23558", district:"24", Block:"307", label:"IBNU UMAR ISLAMIC  MATRICULATION SCHOOL, TENKASI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23559", district:"25", Block:"157", label:"MAHATHMA GANDHI PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23560", district:"25", Block:"216", label:"SENTAMIL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23561", district:"25", Block:"234", label:"GOLDEN INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23562", district:"25", Block:"234", label:"MOUNT CARMEL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23563", district:"25", Block:"313", label:"Government Model School, Thanjavur", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23564", district:"25", Block:"313", label:"Thanjavur District Government Model School", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23565", district:"25", Block:"345", label:"NALDHAVAN MATRICULATION SCHOOL- URANIPURAM ", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23566", district:"26", Block:"53", label:"Government Model School, Nilgiris", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23567", district:"26", Block:"53", label:"NILGIRIS DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23568", district:"27", Block:"31", label:"LINGA GLOBAL SCHOOL, RENGASAMUTHRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23569", district:"27", Block:"49", label:"GOVERNMENT KALLAR RESIDENTIAL SCHOOL (CO ED), VELLAYAMMALPURAM", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23570", district:"27", Block:"319", label:"P.C.PATTI HINDU NADAR URAVINMURAI R.S. MATRICULATION SCHOOL - MUTHUTHEVANPATTI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23571", district:"27", Block:"375", label:"GREEN MOUNT PUBLIC SCHOOL - PANNAIPURAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23572", district:"28", Block:"138", label:"LAKSHMI MILLS MATRICULATION SCHOOL , INAMMANIACHI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23573", district:"28", Block:"138", label:"PUNITHA OHM GLOBAL CBSE", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23574", district:"28", Block:"138", label:"BRINDAVAN'S KIDS UNIVERSITY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23575", district:"28", Block:"217", label:"VELAVAN VIDYALAYA", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23576", district:"28", Block:"320", label:"KANCHI SANKARA VIDHYASHRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23577", district:"28", Block:"402", label:"G K VINAYAKA VIDYALAYA SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23578", district:"29", Block:"15", label:"SVV PUBLIC SCHOOL ALLUR", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23579", district:"29", Block:"171", label:"TIRUCHIRAPPALLI DISTRICT  GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23580", district:"29", Block:"171", label:"MOULANA JAMALI MATRICULATION SCHOOL, INAM KULATHUR, TRICHY", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23581", district:"29", Block:"171", label:"THE INDIAN PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23582", district:"29", Block:"171", label:"AGACHEVI TRANSITIONAL SCHOOL FOR HEARING IMPAIRED", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23583", district:"29", Block:"353", label:"Cambridge Matriculation School,Meikkalnayakanpatti", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23584", district:"29", Block:"367", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23585", district:"29", Block:"367", label:"RAJAM KRISHNAMURTHY (CBSE) PUBLIC SCHOOL, KARUMANDAPAM", Sch_type:"Un-aided", Category:"High School"},
    { key:"23586", district:"30", Block:"9", label:"AL AMEEN NURSERY & PRIMARY SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23587", district:"30", Block:"92", label:"NELLAI ERUVADI MODEL  SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23588", district:"30", Block:"221", label:"Government Model School, Tirunelveli", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23589", district:"30", Block:"222", label:"TIRUNELVELI DISTRICT GOVERNMENT MODEL  SCHOOL ,TIRUNELVELI ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23590", district:"31", Block:"82", label:"DEVI VENKATACHALAM PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23591", district:"31", Block:"103", label:"SRI VIJAY VIDYASHRAM SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23592", district:"31", Block:"155", label:"KSR MYTIKAS PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23593", district:"31", Block:"362", label:"TIRUPATHUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23594", district:"32", Block:"32", label:"THE SCV CENTRAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23595", district:"32", Block:"32", label:"PALANIAPPA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23596", district:"32", Block:"223", label:"VENKATESHWHARA VIDHYA MANDHIR SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23597", district:"32", Block:"363", label:"Majestic Convent Matriculation School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23598", district:"32", Block:"363", label:"T.N.K. VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23599", district:"32", Block:"363", label:"Frontline NewGen International School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23600", district:"32", Block:"392", label:"Saraswathi Vidhya Mandhir Matriculation School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23601", district:"33", Block:"76", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23602", district:"33", Block:"252", label:"AVPT  SCHOOL CBSE", Sch_type:"Un-aided", Category:"High School"},
    { key:"23603", district:"33", Block:"260", label:"Olive Tree Global School", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23604", district:"33", Block:"260", label:"PRIM BUDS GARDEN", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23605", district:"33", Block:"261", label:"MY DREAM MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23606", district:"33", Block:"261", label:"SRI VAARIYAR VIDHYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23607", district:"33", Block:"289", label:"SRI KRISH INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23608", district:"33", Block:"289", label:"GFC GLOBAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23609", district:"33", Block:"337", label:"EVERGREEN MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23610", district:"33", Block:"338", label:"TIRUVALLUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23611", district:"33", Block:"403", label:"AACHI GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23612", district:"33", Block:"403", label:"KALAIMAGAL VIDHYASHRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23613", district:"33", Block:"403", label:"BETHEL GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23614", district:"33", Block:"403", label:"MAR GREGORIOS PUBLIC SCHOOL, MOGAPPAIR , WEST,SECTOR-7", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23615", district:"33", Block:"403", label:"CPS GLOBAL SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23616", district:"33", Block:"403", label:"SV HIGH SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23617", district:"33", Block:"403", label:"ABS GLOBAL SMART SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23618", district:"34", Block:"44", label:"A.P.J MEMORIAL MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23619", district:"34", Block:"358", label:"SISHYA VIDYALAYA", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23620", district:"34", Block:"365", label:"Government Model School, Tiruvannamalai", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23621", district:"34", Block:"365", label:"SRI VIJAY VIDYALAYA MAT HR SEC SCHOOL", Sch_type:"Un-aided", Category:"Higher Secondary School"},
    { key:"23622", district:"34", Block:"365", label:"SRI VIJAY VIDYASHRAM", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23623", district:"34", Block:"365", label:"TIRUVANNAMALAI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23624", district:"34", Block:"387", label:"VIRUTCHAM VIDHYASHRAM INTERNATIONAL PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23625", district:"35", Block:"134", label:"TIRUVARUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23626", district:"35", Block:"173", label:"CBSE CONCORDIA PUBLIC, MANNARGUDI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23627", district:"35", Block:"340", label:"UAHS MAULANA ABULKALAM AZAD MATRICULATION , THIRUVARUR", Sch_type:"Un-aided", Category:"High School"},
    { key:"23628", district:"35", Block:"382", label:"UAHS MAHADEVA GURUJI VIDYASHRAM ALANGUDI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23629", district:"36", Block:"74", label:"EDIFY SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23630", district:"36", Block:"84", label:"SREE ABIRAAMI (CBSE ) SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23631", district:"36", Block:"105", label:"MAHARISHI VIDYA MANDIR-NELVOY", Sch_type:"Un-aided", Category:"High School"},
    { key:"23632", district:"36", Block:"113", label:"PRINCE PARK MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23633", district:"36", Block:"113", label:"SHRI PONMATHI MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23634", district:"36", Block:"393", label:"VELLORE DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23635", district:"37", Block:"130", label:"Government Model School, Vilupuram", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23636", district:"37", Block:"189", label:"VIVEKANANDA VIDYALAYA PUBLIC SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23637", district:"37", Block:"189", label:"PALANIVELU MATRICULATION SCHOOL MELVALAI", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23638", district:"37", Block:"379", label:"KAVIKAMBAN MATRIC SCHOOL THIRUVAKKARAI", Sch_type:"Un-aided", Category:"High School"},
    { key:"23639", district:"37", Block:"401", label:"VILLUPURAM DISTRICT GOVERNMENT MODEL SCHOOL ", Sch_type:"Government", Category:"Higher Secondary School"},
    { key:"23640", district:"37", Block:"401", label:"St Josephs School V Sathanur", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23641", district:"38", Block:"282", label:"LITTLE BUDS UNIQUE MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23642", district:"38", Block:"295", label:"GOOD SHEPHERD MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23643", district:"38", Block:"295", label:"SIDHARAJAA INTERNATIONAL SCHOOL", Sch_type:"Un-aided", Category:"High School"},
    { key:"23644", district:"38", Block:"298", label:"TELUGU KULALAR SRI SOOLAI VINAYAGAR VIDYALAYA", Sch_type:"Un-aided", Category:"High School"},
    { key:"23645", district:"38", Block:"298", label:"HINDU MATRICULATION SCHOOL", Sch_type:"Un-aided", Category:"Middle School"},
    { key:"23646", district:"30", Block:"262", label: "Atomic Energy Higher Secondary School", Sch_type: "Unaided", Category: "High School"},
    { key:"23647", district:"3", Block:"415", label: "Sir Ramaswami Mudaliar Higher Secondary School", Sch_type: "Unaided", Category: "High School"},
    { key:"23648", district:"3", Block:"2", label: "Manthan Vidyashram", Sch_type: "Unaided", Category: "High School"},
    { key:"23649", district:"2", Block:"299", label: "RV matriculation school", Sch_type: "Unaided", Category: "High School"},
    { key:"23650", district:"37", Block:"214", label: "Wisdom Nursery and Primary school", Sch_type: "Unaided", Category: "Primary school"},
    { key:"23651", district:"3", Block:"418", label: "Sri Ram Dayal Khemka Vivekananda Vidyalaya Senior Secondary School", Sch_type: "Unaided", Category: "High School"},
    { key:"23652", district:"37", Block:"404", label: "Railway Mixed High School", Sch_type: "Unaided", Category: "High School"},
    { key:"23653", district:"33", Block:"261", label: "Saraswathi Vidyalaya Matric School", Sch_type: "Unaided", Category: "High School"},
    { key:"23654", district:"11", Block:"355", label: "jennifer matriculation higher secondary school ", Sch_type: "Unaided", Category: "High School"},
    { key:"23655", district:"3", Block:"270", label: "Kalaimagal School", Sch_type: "Unaided", Category: "High School"},
    { key:"23656", district:"22", Block:"215", label: "BVM Global @Salem", Sch_type: "Unaided", Category: "High School"},
    { key:"23657", district:"12", Block:"140", label: "Sri Krishna School ", Sch_type: "Unaided", Category: "High School"},
    { key:"23658", district:"34", Block:"365", label: "Danish Mission Higher Secondary School", Sch_type: "Unaided", Category: "High School"},
    { key:"23659", district:"17", Block:"232", label: "Sri Vidhya Lakshmi Matriculation School", Sch_type: "Unaided", Category: "High School"},
    { key:"23660", district:"10", Block:"101", label: "Melmaruvathur Adhiparasakthi High School", Sch_type: "Unaided", Category: "High School"},
    { key:"23661", district:"3", Block:"195", label: "Besant Lvr Matriculation Higher Secondary School", Sch_type: "Unaided", Category: "High School"},
    { key:"23662", district:"4", Block:"245", label: "St Mary's Matriculation School Edayarpalayam Coimbatore", Sch_type: "Unaided", Category: "Middle School"},
	{ key:"23663", district:"33", Block:"364", label: "New Eden School", Sch_type: "Unaided", Category: "High School"},
	{ key:"23664", district:"14", Block:"158", label: "Ellora International Kids School", Sch_type: "Unaided", Category: "Primary school"},
	{ key:"23665", district:"12", Block:"23", label: "Ferdin Public School", Sch_type: "Unaided", Category: "High School"},
	{ key:"23666", district:"33", Block:"338", label: "TVIS Velammal Vidyalaya", Sch_type: "Unaided", Category: "High School"},
	{ key:"23667", district:"33", Block:"338", label: "R.M.Jain Govt.Girls Hr.Sec.School", Sch_type: "Government", Category: "Higher Secondary School"},
	{ key:"23668", district:"6", Block:"198", label: "Sri Jansi High School ", Sch_type: "Unaided", Category: "High School"},
	{ key:"23669", district:"31", Block:"362", label: "Sri Nandhanam International School (CBSE)", Sch_type: "Unaided", Category: "High School"},
	{ key:"23670", district:"5", Block:"406", label: "BVB Matriculation Hr.Sec.School", Sch_type: "Unaided", Category: "High School"},
	{ key:"23671", district:"34", Block:"365", label: "Vivekananda Nursery School", Sch_type: "Unaided", Category: "Primary school"},
	{ key:"23672", district:"4", Block:"349", label: "K.G Public Matric Higher Secondary School", Sch_type: "Unaided", Category: "Higher Secondary School"},
	{ key:"23673", district:"10", Block:"101", label: "Shree Sangalpa School", Sch_type: "Unaided", Category: "High School"},
	{ key:"23674", district:"14", Block:"328", label: "Harveypatti higher secondary school", Sch_type: "Unaided", Category: "Higher Secondary School"},
	{ key:"23675", district:"3", Block:"415", label: "Vinod Vijay Vidyalaya Matric Higher secondary school ", Sch_type: "Unaided", Category: "Higher Secondary School"},
	{ key:"23676", district:"4", Block:"52", label: "ADWAITH THOUGHT ACADEMY, Coimbatore", Sch_type: "Unaided", Category: "High School"},
	{ key:"23677", district:"31", Block:"362", label: "Shakespere Matriculation school", Sch_type: "Unaided", Category: "High School"},
	{ key:"23678", district:"2", Block:"300", label: "Saratha Mtriculation School", Sch_type: "Unaided", Category: "High School"},
	{ key:"23679", district:"33", Block:"260", label: "KBC Government Girls Hr Sec School", Sch_type: "Government", Category: "Higher Secondary School"},
	{ key:"23680", district:"32", Block:"363", label: "Mugi Kids Nursery & PrimarySchool", Sch_type: "Unaided", Category: "Primary school"},
	{ key:"23681", district:"36", Block:"105", label: "Madras Matriculation Hr Sec School", Sch_type: "Unaided", Category: "Higher Secondary School"},
    { key:"23682", district:"25", Block:"144", label: "Town higher secondary school", Sch_type: "Unaided", Category: "Higher Secondary School"},
    { key:"23683", district:"11", Block:"191", label: "Holy Angels International School,", Sch_type: "Unaided", Category: "High School"},
    { key:"23684", district:"22", Block:"179", label: "RP matric higher secondary school", Sch_type: "Unaided", Category: "Higher Secondary School"},
    { key:"23685", district:"7", Block:"60", label: "AUSILIUM HIGH SCHOOL", Sch_type: "Unaided", Category: "High School"},
    { key:"23686", district:"4", Block:"19", label: "KPR Women Employees Educational Division", Sch_type: "Unaided", Category: "Higher Secondary School"},
    { key:"23687", district:"8", Block:"244", label: "Appichimar vidhya mandir school ", Sch_type: "Unaided", Category: "High School"},
    { key:"23688", district:"3", Block:"418", label: " kids park vidyashram school", Sch_type: "Unaided", Category: "High School"},
    { key:"23689", district:"4", Block:"156", label: "Sri Meenakshi Matric School", Sch_type: "Unaided", Category: "High School"},
    { key:"23690", district:"6", Block:"58", label: "Agni Sarar Higher Secondary School", Sch_type: "Unaided", Category: "High School"},
    { key:"23691", district:"4", Block:"52", label: "velammal bodhi campus", Sch_type: "Unaided", Category: "Higher Secondary School"},
    { key:"23692", district:"22", Block:"274", label: "Silver Bell Matriculation School", Sch_type: "Unaided", Category: "High School"},
    { key:"23693", district:"14", Block:"374", label: "Sathya Vidhyalaya International School", Sch_type: "Unaided", Category: "High School"},
    { key:"23694", district:"5", Block:"199", label: "Jayasakthi Nursery & Primary School", Sch_type: "Unaided", Category: "Primary school"},
    { key:"23695", district:"3", Block:"414", label: "Nehru Govt. Hr. Sec. School", Sch_type: "Government", Category: "Higher Secondary School"},
    { key:"23696", district:"33", Block:"252", label: "Govt. Girls Model Hr. Sec. School", Sch_type: "Government", Category: "Higher Secondary School"},
    { key:"23697", district:"32", Block:"363", label: "JAISAKTHI VIRAJA GURUKULAM (CBSC SCHOOL)", Sch_type: "Unaided", Category: "Middle School"},
	{ key:"23698", district:"38", Block:"298", label: "st antony matriculation school ", Sch_type: "Unaided", Category: "High School"},
	{ key:"23699", district:"10", Block:"296", label: "st.vincent matriculation school ", Sch_type: "Unaided", Category: "High School"},
	{ key:"23700", district:"6", Block:"187", label: "jayasree vidhyalaya matric school ", Sch_type: "Unaided", Category: "High School"},
	{ key:"23701", district:"4", Block:"349", label: "sri venkatachalatathy vidyalaya matric hr. sec. school", Sch_type: "Unaided", Category: "Higher Secondary School"},
	{ key:"23702", district:"29", Block:"192", label: "Saraswati Vidya Shala School ", Sch_type: "Unaided", Category: "High School"},
	{ key:"23703", district:"14", Block:"324", label: "Aarushi Global School", Sch_type: "Unaided", Category: "High School"},
    { key:"23704", district:"3", Block:"2", label:"ECI MAT HSS", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23705", district:"3", Block:"2", label:"CHENNAI MIDDLE SCHOOL  MADIPAKKAM", Sch_type:"GovernmentMiddle School"},
    { key:"23706", district:"3", Block:"2", label:"THE PSBB MILLENNIUM SCHOOL Thazhambur", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23707", district:"3", Block:"2", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"23708", district:"3", Block:"2", label:"NARAYANA OLYMPIAD SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"23709", district:"3", Block:"2", label:"VELS GLOBAL SCHOOL NANDAMBAKKAM", Sch_type:"Un-aidedHigh School"},
    { key:"23710", district:"3", Block:"2", label:"SAN ACDEMY", Sch_type:"Un-aidedHigh School"},
    { key:"23711", district:"11", Block:"3", label:"SANTHIDAM BETHANIA MATRICULATION, KANYAKUMARI", Sch_type:"Un-aidedMiddle School"},
    { key:"23712", district:"14", Block:"4", label:"PUMS KOTTAIMEDU", Sch_type:"GovernmentMiddle School"},
    { key:"23713", district:"14", Block:"4", label:"PUMS THANDALAI", Sch_type:"GovernmentMiddle School"},
    { key:"23714", district:"14", Block:"4", label:"PUMS MANICKAMPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23715", district:"18", Block:"7", label:"GHS GUDALUR", Sch_type:"GovernmentHigh School"},
    { key:"23716", district:"36", Block:"12", label:"Oxford Matriculation School, Odugathur", Sch_type:"Un-aidedMiddle School"},
    { key:"23717", district:"1", Block:"16", label:"PUMS DEVANUR", Sch_type:"GovernmentMiddle School"},
    { key:"23718", district:"5", Block:"17", label:"PUMS PANAPAKKAM", Sch_type:"GovernmentMiddle School"},
    { key:"23719", district:"19", Block:"18", label:"SANTHOMA MATRICULLATION SCHOOL VIDIVAKAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23720", district:"19", Block:"18", label:"DURAIRAJ MEMORIAL MATRICULATION SCHOOL PERUMANADU", Sch_type:"Un-aidedMiddle School"},
    { key:"23721", district:"4", Block:"19", label:"PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23722", district:"4", Block:"19", label:"KOVAI VIDYASHRAM", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23723", district:"21", Block:"21", label:"HAYAGRIVAR VIDYAASHRAM", Sch_type:"Un-aidedHigh School"},
    { key:"23724", district:"19", Block:"22", label:"KURUKULAM NURSERY AND PRIMARY SCHOOL  ALANJIRANKADU", Sch_type:"Un-aidedMiddle School"},
    { key:"23725", district:"19", Block:"22", label:"MONTFORT SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23726", district:"12", Block:"23", label:"USWATHUN HASANA ORIENTAL ARABIC,PALLAPATTI", Sch_type:"Fully AidedHigher SecondarySchool"},
    { key:"23727", district:"21", Block:"24", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23728", district:"21", Block:"24", label:"MAYFLOWER SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23729", district:"19", Block:"25", label:"CARMEL RANI MATRIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23730", district:"1", Block:"26", label:"GHS VELLUR", Sch_type:"GovernmentHigh School"},
    { key:"23731", district:"1", Block:"26", label:"GOLDEN GATES GLOBAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23732", district:"38", Block:"28", label:"SRI VENKATESWARAA MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23733", district:"22", Block:"30", label:"PUMS KAMARAJ NAGAR", Sch_type:"GovernmentMiddle School"},
    { key:"23734", district:"27", Block:"31", label:"THENI DISTRICT GOVERNMENT MODEL SCHOOL - THEKKAMPATTI", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23735", district:"27", Block:"31", label:"THE LITTLE FLOWER MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23736", district:"32", Block:"32", label:"PUMS PUDUPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23737", district:"32", Block:"32", label:"PUMS SULLIPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23738", district:"32", Block:"32", label:"PUMS SAMATHUVAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23739", district:"32", Block:"32", label:"PUMS AMMAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23740", district:"32", Block:"32", label:"VEDANTA ACADEMY", Sch_type:"Un-aidedHigh School"},
    { key:"23741", district:"22", Block:"34", label:"S.B.I.O.A. PUBLIC SCHOOL, GUNDUKALLUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23742", district:"13", Block:"35", label:"PUMS SRINIVASAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23743", district:"13", Block:"35", label:"DURAI MODERN SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23744", district:"5", Block:"39", label:"GHSS KRISHNAPURAM", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23745", district:"5", Block:"39", label:"PUMS NATHAMEDU", Sch_type:"GovernmentMiddle School"},
    { key:"23746", district:"5", Block:"39", label:"PUMS MARUDUR", Sch_type:"GovernmentMiddle School"},
    { key:"23747", district:"20", Block:"41", label:"NETHAJI SUBHASH CHANDRA BOSE AWASIYA VIDYALAYA, SATHIRAKUDI, BOGALUR", Sch_type:"GovernmentMiddle School"},
    { key:"23748", district:"20", Block:"41", label:"BODHI VIDHYALAYA CBSE SCHOOL, SEYYALUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23749", district:"34", Block:"44", label:"PUMS GANESAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23750", district:"34", Block:"44", label:"PUMS PUDUPATTU", Sch_type:"GovernmentMiddle School"},
    { key:"23751", district:"34", Block:"47", label:"PUMS MARUTHUVAMBADI", Sch_type:"GovernmentMiddle School"},
    { key:"23752", district:"34", Block:"47", label:"PUMS NARASINGAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23753", district:"34", Block:"48", label:"GHS KUNNATHUR", Sch_type:"GovernmentHigh School"},
    { key:"23754", district:"34", Block:"48", label:"INDIAN PUBLIC SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"23755", district:"27", Block:"49", label:"PUMS - KAMARAJAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23756", district:"27", Block:"49", label:"SHREE VIKASAA PUBLIC SCHOOL - CHINNAMANUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23757", district:"9", Block:"50", label:"AIDED MIDDLE SCHOOL KARANUR", Sch_type:"Fully AidedMiddle School"},
    { key:"23758", district:"4", Block:"52", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"23759", district:"4", Block:"52", label:"COIMBATORE DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23760", district:"4", Block:"52", label:"CARMEL GARDEN PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23761", district:"26", Block:"53", label:"PUMS MELUR", Sch_type:"GovernmentMiddle School"},
    { key:"23762", district:"26", Block:"53", label:"NILGIRIS DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23763", district:"26", Block:"53", label:"ST JOSEPH'S CONVENT SCHOOLS", Sch_type:"Un-aidedMiddle School"},
    { key:"23764", district:"5", Block:"54", label:"CUDDALORE DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23765", district:"5", Block:"54", label:"ARCOT RAMASWAMI LAKSHMANASWAMI MUDHALIYAR HR SEC SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23766", district:"5", Block:"54", label:"ARISTO PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23767", district:"32", Block:"57", label:"PUMS NARASINGAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23768", district:"32", Block:"57", label:"PUMS THOPPAMPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23769", district:"6", Block:"58", label:"SREE SIVANIVEL VIDHYALAYA MATRICULATION SCHOOL, UNGARANAHALLI, DHARMAPURI", Sch_type:"Un-aidedMiddle School"},
    { key:"23770", district:"7", Block:"59", label:"VELAMMAL BODHI CAMPUS, DINDIGUL", Sch_type:"Un-aidedMiddle School"},
    { key:"23771", district:"7", Block:"59", label:"TAKSHASHILA VIDYA MANDIR, ANAIPATTI", Sch_type:"Un-aidedMiddle School"},
    { key:"23772", district:"7", Block:"59", label:"J KAY SRI SANKARA PUBLIC SCHOOL, DINDIGUL", Sch_type:"Un-aidedMiddle School"},
    { key:"23773", district:"22", Block:"61", label:"PUMS PUTHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23774", district:"22", Block:"61", label:"PUMS PULIYAMPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23775", district:"33", Block:"64", label:"PUMS, PUNNAPAKKAM", Sch_type:"GovernmentMiddle School"},
    { key:"23776", district:"33", Block:"64", label:"PUMS, SEMBEDU", Sch_type:"GovernmentMiddle School"},
    { key:"23777", district:"8", Block:"65", label:"VELS GLOBAL SCHOOL,KOLLAMPALAYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23778", district:"8", Block:"65", label:"HINDU GLOBAL SCHOOL , MAMARATHUPALAYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23779", district:"8", Block:"65", label:"AATHICHUDI SCHOOL FOR DYSLEXIA, E.K VALASU", Sch_type:"Un-aidedOthers"},
    { key:"23780", district:"17", Block:"66", label:"SRI CHAITANYA TECHNO SCHOOL, KOOLIPATTY", Sch_type:"Un-aidedMiddle School"},
    { key:"23781", district:"19", Block:"67", label:"PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23782", district:"22", Block:"68", label:"PUMS, GANDHI NAGAR", Sch_type:"GovernmentMiddle School"},
    { key:"23783", district:"37", Block:"70", label:"PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU", Sch_type:"GovernmentMiddle School"},
    { key:"23784", district:"26", Block:"72", label:"PUMS GANDHI NAGAR", Sch_type:"GovernmentMiddle School"},
    { key:"23785", district:"32", Block:"73", label:"RG International Public School", Sch_type:"Un-aidedMiddle School"},
    { key:"23786", district:"36", Block:"74", label:"GHS SEMBEDU", Sch_type:"GovernmentHigh School"},
    { key:"23787", district:"36", Block:"74", label:"PUMS NATHAM", Sch_type:"GovernmentMiddle School"},
    { key:"23788", district:"36", Block:"74", label:"ARUMBUGAL ", Sch_type:"Un-aidedMiddle School"},
    { key:"23789", district:"6", Block:"77", label:"PUMS CHANDRAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23790", district:"13", Block:"78", label:"GHSS NALLUR", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23791", district:"13", Block:"78", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23792", district:"13", Block:"78", label:"NIMAI PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23793", district:"34", Block:"80", label:"AUXILIUM MATRICULATION SCHOOL, JAMUNAMARATHUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23794", district:"31", Block:"82", label:"PUMS, KATTUR", Sch_type:"GovernmentMiddle School"},
    { key:"23795", district:"36", Block:"84", label:"SRI LAKSHMI VIDYALAYA NURSERY AND PRIMARY SCHOOL MELMOIL", Sch_type:"Un-aidedMiddle School"},
    { key:"23796", district:"36", Block:"84", label:"SARADAMBAL SRINIVASA MUDALIAR MEMORIAL PADASALA (CBSE)", Sch_type:"Un-aidedHigh School"},
    { key:"23797", district:"17", Block:"85", label:"PUMS K.AYYAMPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23798", district:"12", Block:"88", label:"PUMS, MAMARATHUPATTY", Sch_type:"GovernmentMiddle School"},
    { key:"23799", district:"12", Block:"88", label:"PUMS, ALATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23800", district:"22", Block:"90", label:"PUMS, ELATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23801", district:"22", Block:"90", label:"PUMS, NALLUR", Sch_type:"GovernmentMiddle School"},
    { key:"23802", district:"23", Block:"94", label:"PUMS, KEELAKOTTAI", Sch_type:"GovernmentMiddle School"},
    { key:"23803", district:"9", Block:"95", label:"PANCHAYAT UNION MIDDLE SCHOOL ALATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23804", district:"9", Block:"95", label:"KALLAKURICHI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23805", district:"5", Block:"98", label:"PUMS KUMARAMANGALAM", Sch_type:"GovernmentMiddle School"},
    { key:"23806", district:"37", Block:"100", label:"NILAA MATRICULATION SCHOOL,KOSAPALAYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23807", district:"32", Block:"104", label:"PUMS MARAVAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23808", district:"32", Block:"104", label:"PUMS VEERANAMPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23809", district:"32", Block:"104", label:"GHS KEERANUR", Sch_type:"GovernmentHigh School"},
    { key:"23810", district:"36", Block:"105", label:"MERIDIAN MATRICULATION SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"23811", district:"4", Block:"107", label:"ST.JOSEPH'S MATRIC HR.SEC.SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23812", district:"4", Block:"107", label:"NAV BHARATH MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23813", district:"4", Block:"107", label:"EXPONICS ACADEMY", Sch_type:"Un-aidedMiddle School"},
    { key:"23814", district:"6", Block:"109", label:"PUMS MARAVADI", Sch_type:"GovernmentMiddle School"},
    { key:"23815", district:"28", Block:"111", label:"Thoothukkudi District Government Model School", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23816", district:"36", Block:"113", label:"PUMS Sevoor", Sch_type:"GovernmentMiddle School"},
    { key:"23817", district:"36", Block:"113", label:"KV Special Schools", Sch_type:"Un-aidedMiddle School"},
    { key:"23818", district:"36", Block:"113", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23819", district:"2", Block:"114", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23820", district:"2", Block:"114", label:"CRESCENT SCHOOL, VANDALUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23821", district:"2", Block:"114", label:"Chengalpattu District Government Model School", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23822", district:"5", Block:"115", label:"DVC MATRICULATION SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23823", district:"21", Block:"116", label:"BHARATHIDHASANAR PUBLIC SCHOOL, PANAPAKKAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23824", district:"13", Block:"117", label:"PUMS PULIYAMPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23825", district:"5", Block:"120", label:"KARUPASAMY VIDYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23826", district:"13", Block:"122", label:"GHS NAGAMANGALAM", Sch_type:"GovernmentHigh School"},
    { key:"23827", district:"13", Block:"122", label:"PUMS KOTTAIYUR", Sch_type:"GovernmentMiddle School"},
    { key:"23828", district:"11", Block:"124", label:"ST.ALOYSIUS MATRICULATION SCHOOL-VELLIAVILAI ", Sch_type:"Un-aidedMiddle School"},
    { key:"23829", district:"4", Block:"126", label:"VIDYARPANA  HOME MATRICULATION SCHOOL  ", Sch_type:"Un-aidedMiddle School"},
    { key:"23830", district:"15", Block:"131", label:"PUMS PUTHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23831", district:"35", Block:"134", label:"PUMS KODIMANGALAM", Sch_type:"GovernmentMiddle School"},
    { key:"23832", district:"35", Block:"134", label:"TIRUVARUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23833", district:"35", Block:"137", label:"PUMS, ALATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23834", district:"35", Block:"137", label:"PUMS, KURICHI", Sch_type:"GovernmentMiddle School"},
    { key:"23835", district:"35", Block:"137", label:"PUMS, KUMARAMANGALAM", Sch_type:"GovernmentMiddle School"},
    { key:"23836", district:"35", Block:"137", label:"PUMS, RENGANATHAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23837", district:"13", Block:"139", label:"SENTHIL PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23838", district:"13", Block:"139", label:"SENTHIL MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23839", district:"13", Block:"139", label:"ST. ANNE'S PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23840", district:"13", Block:"139", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23841", district:"12", Block:"140", label:"Karur District Government Model School", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23842", district:"35", Block:"141", label:"PUMS, ALATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23843", district:"35", Block:"141", label:"BLOOM THE NURSERY AND PRIMARY SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23844", district:"12", Block:"142", label:"PUMS, NALLUR", Sch_type:"GovernmentMiddle School"},
    { key:"23845", district:"12", Block:"142", label:"PUMS, KURICHI", Sch_type:"GovernmentMiddle School"},
    { key:"23846", district:"5", Block:"143", label:"GHS VELLUR", Sch_type:"GovernmentHigh School"},
    { key:"23847", district:"5", Block:"143", label:"Sri Saraswathi Vidhyalaya", Sch_type:"Un-aidedMiddle School"},
    { key:"23848", district:"25", Block:"144", label:"PUMS KALLUR", Sch_type:"GovernmentMiddle School"},
    { key:"23849", district:"25", Block:"144", label:"KARTHI VIDHYALAYA PUBLIC SCHOOL", Sch_type:"Central GovtMiddle School"},
    { key:"23850", district:"32", Block:"145", label:"SRI GURUJI VIDYA MANDHIR MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23851", district:"10", Block:"146", label:"PUMS MANGADU", Sch_type:"GovernmentMiddle School"},
    { key:"23852", district:"10", Block:"146", label:"Amrita Vidyalayam", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23853", district:"10", Block:"146", label:"LALAJI MEMORIAL OMEGA INTERNATIONAL SCHOOL (CIS)", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23854", district:"10", Block:"146", label:"KANCHIPURAM DISTRICT GOVERNMENT MODEL SCHOOL, MANIMANGALAM", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23855", district:"10", Block:"146", label:"SSP VIDHYASHRAM, VARADARAJAPURAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23856", district:"5", Block:"148", label:"GHSS KARUNKUZHI", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23857", district:"5", Block:"148", label:"VASANTHAM SPECIAL SCHOOL -VADALUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23858", district:"4", Block:"156", label:"PUMS RANGASAMUTHIRAM", Sch_type:"GovernmentMiddle School"},
    { key:"23859", district:"4", Block:"156", label:"ELGI SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23860", district:"4", Block:"156", label:"VIAN VEENAI PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23861", district:"2", Block:"162", label:"PUMS NELVOY", Sch_type:"GovernmentMiddle School"},
    { key:"23862", district:"2", Block:"162", label:"PUMS PAZHAYANUR", Sch_type:"GovernmentMiddle School"},
    { key:"23863", district:"2", Block:"162", label:"PUMS PUDUPATTU", Sch_type:"GovernmentMiddle School"},
    { key:"23864", district:"2", Block:"162", label:"PUMS KUNNATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23865", district:"2", Block:"162", label:"SRI S. C AGARWAL RT 95 VIVEKANANDA VIDYASHRAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23866", district:"22", Block:"163", label:"PUMS, KADAYAMPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23867", district:"23", Block:"166", label:"PUMS THUTHIKULAM", Sch_type:"GovernmentMiddle School"},
    { key:"23868", district:"23", Block:"166", label:"PUMS VELUR", Sch_type:"GovernmentMiddle School"},
    { key:"23869", district:"23", Block:"166", label:"PUMS VILATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23870", district:"23", Block:"166", label:"BABA MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23871", district:"29", Block:"168", label:"PUMS, KATTUPATTY", Sch_type:"GovernmentMiddle School"},
    { key:"23872", district:"29", Block:"171", label:"TIRUCHIRAPPALLI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23873", district:"29", Block:"171", label:"ACHARIYA SIKSHA MANDIR,K.SATHANUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23874", district:"35", Block:"173", label:"UAPPS KASTURBA GANDHI MATRICULATION,  PUNAVASAL", Sch_type:"Un-aidedMiddle School"},
    { key:"23875", district:"13", Block:"177", label:"GHSS KUNNATHUR", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23876", district:"15", Block:"178", label:"GHSS KORUKKAI", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23877", district:"15", Block:"178", label:"MAYILADUTHURAI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23878", district:"37", Block:"181", label:"NETHAJI SUBHASH CHANDRA BOSE AVASIYA VIDYALAYA DEVANUR", Sch_type:"GovernmentMiddle School"},
    { key:"23879", district:"33", Block:"184", label:"BHAGAVAN SRI KRISHNA VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23880", district:"8", Block:"185", label:"THE VELS ACADEMY, MUTHUGOUNDANPALAYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23881", district:"17", Block:"186", label:"PUMS UNJAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23882", district:"32", Block:"190", label:"ST. MARY'S MATRICULATION SCHOOL, MALLAMPALAYAM", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23883", district:"29", Block:"192", label:"PUMS VELLAKKALPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23884", district:"16", Block:"196", label:"GHS KURICHI", Sch_type:"GovernmentHigh School"},
    { key:"23885", district:"16", Block:"196", label:"NAGAPATTINAM DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23886", district:"6", Block:"198", label:"PUMS PAPPAMPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23887", district:"5", Block:"199", label:"GHSS VEPPUR", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23888", district:"5", Block:"199", label:"PUMS NALLUR", Sch_type:"GovernmentMiddle School"},
    { key:"23889", district:"5", Block:"199", label:"GHSS KOVILUR", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23890", district:"17", Block:"201", label:"Namakkal District Government Model School", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23891", district:"8", Block:"202", label:"EDISON INTERNATIONAL SCHOOL, VEMANDAMPALAYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23892", district:"38", Block:"206", label:"PUPS. THIRUMANICKANENTHAL", Sch_type:"GovernmentMiddle School"},
    { key:"23893", district:"31", Block:"208", label:"PUMS NARAYANAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23894", district:"7", Block:"213", label:"PUMS,SAMATHUVAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23895", district:"22", Block:"215", label:"KONGU SRV INTERNATIONAL SCHOOL, VELLAKKALPATTI", Sch_type:"Un-aidedMiddle School"},
    { key:"23896", district:"25", Block:"216", label:"GHS-VELLUR", Sch_type:"GovernmentHigh School"},
    { key:"23897", district:"28", Block:"217", label:"HNUP MATRICULATION SCHOOL PUTHIAMPUTHUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23898", district:"6", Block:"218", label:"PUMS NALLAMPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23899", district:"6", Block:"218", label:"GHS AGARAM", Sch_type:"GovernmentHigh School"},
    { key:"23900", district:"30", Block:"221", label:"KAMARAJAR MATRICULATION SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"23901", district:"30", Block:"221", label:"AMRITA VIDYALAYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23902", district:"30", Block:"222", label:"GOVT SERVICE HOME HSS, PALAI", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23903", district:"30", Block:"222", label:"MITRA SCHOOL FOR AUTISM", Sch_type:"Un-aidedHigh School"},
    { key:"23904", district:"32", Block:"223", label:"INFANT JESUS PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23905", district:"33", Block:"225", label:"TVM MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23906", district:"33", Block:"225", label:"MOTHER TERESA MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23907", district:"22", Block:"226", label:"PUMS,KATTUR", Sch_type:"GovernmentMiddle School"},
    { key:"23908", district:"22", Block:"226", label:"VEDHA VIKKAS HR. SEC. SCHOOL, SANTHIYUR", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23909", district:"17", Block:"232", label:"PUMS MARAVAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23910", district:"5", Block:"233", label:"PUMS MGR NAGAR", Sch_type:"GovernmentMiddle School"},
    { key:"23911", district:"5", Block:"233", label:"GREEN OLIVE MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23912", district:"25", Block:"234", label:"N.R. PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23913", district:"6", Block:"235", label:"PUMS KRISHNAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23914", district:"6", Block:"235", label:"PUMS MAKKANUR", Sch_type:"GovernmentMiddle School"},
    { key:"23915", district:"6", Block:"235", label:"PUMS KADAMADAI", Sch_type:"GovernmentMiddle School"},
    { key:"23916", district:"18", Block:"236", label:"GHSS AMMAPALAYAM", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23917", district:"34", Block:"238", label:"GHS VAYALUR", Sch_type:"GovernmentHigh School"},
    { key:"23918", district:"27", Block:"241", label:"KALVI INTERNATIONAL PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23919", district:"4", Block:"242", label:"AVILA INTERNATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23920", district:"4", Block:"242", label:"ADITYA VIDYASHRAM - GURUGRAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23921", district:"8", Block:"244", label:"ERODE DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23922", district:"4", Block:"245", label:"PUMS GANESAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"23923", district:"4", Block:"245", label:"SINDHI INTERNATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23924", district:"4", Block:"245", label:"THE HARVEE SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23925", district:"4", Block:"247", label:"PUMS ALAMPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23926", district:"4", Block:"248", label:"GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23927", district:"4", Block:"248", label:"NACHIAR - THE WORLD SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23928", district:"32", Block:"250", label:"PUMS VAVIPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23929", district:"19", Block:"251", label:"GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23930", district:"33", Block:"252", label:"The Pupil(International School)", Sch_type:"Un-aidedMiddle School"},
    { key:"23931", district:"33", Block:"252", label:"VELAMMAL ACADEMY", Sch_type:"Un-aidedMiddle School"},
    { key:"23932", district:"33", Block:"252", label:"JAI HIND PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23933", district:"33", Block:"253", label:"SRI NARAYANA MATRICULATION SCHOOL , KOLUNDHALUR", Sch_type:"Un-aidedHigh School"},
    { key:"23934", district:"28", Block:"257", label:"GHSS Pudur", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23935", district:"7", Block:"268", label:"VIVEKANANDHA PUBLIC SCHOOL, KUNJANAMPATTI", Sch_type:"Un-aidedMiddle School"},
    { key:"23936", district:"7", Block:"268", label:"VIDVIDA ACADEMY, SRIRAMAPURAM, DINDIGUL", Sch_type:"Un-aidedMiddle School"},
    { key:"23937", district:"9", Block:"269", label:"PANCHAYAT UNION MIDDLE SCHOOL PAKKAM", Sch_type:"GovernmentMiddle School"},
    { key:"23938", district:"9", Block:"269", label:"TIRUMALA MATRIC SCHOOL VANAPURAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23939", district:"23", Block:"271", label:"PUMS GANAPATHIPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23940", district:"22", Block:"273", label:"NARAYANA E-TECHNO SCHOOL, REDDIYUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23941", district:"22", Block:"274", label:"SILVERLINES MATRICULATION SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"23942", district:"22", Block:"274", label:"JOTHI CHANDARAN VIDYAALAYAM MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23943", district:"7", Block:"275", label:"UNITY ACADEMY MATRICULATION SCHOOL, RAJAKKAPATTY", Sch_type:"Un-aidedMiddle School"},
    { key:"23944", district:"9", Block:"277", label:"ST. JOSEPH ACADEMY INTERNATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23945", district:"22", Block:"278", label:"PUMS VENGIPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23946", district:"4", Block:"279", label:"PUMS KURUMBAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23947", district:"4", Block:"279", label:"MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23948", district:"4", Block:"279", label:"SRI RAMAKRISHNA MAT HR SEC SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23949", district:"4", Block:"279", label:"PSG MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23950", district:"4", Block:"279", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23951", district:"4", Block:"279", label:"AMRITA VIDYALAYAM", Sch_type:"Un-aidedHigh School"},
    { key:"23952", district:"4", Block:"279", label:"CHANDRAMARI INTERNATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23953", district:"4", Block:"279", label:"SAMASHTI INTERNATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23954", district:"4", Block:"279", label:"HINDUSTHAN INTERNATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23955", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23956", district:"8", Block:"281", label:"PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR", Sch_type:"GovernmentMiddle School"},
    { key:"23957", district:"38", Block:"282", label:"SATTUR KMT VIDHYALAYA", Sch_type:"Un-aidedMiddle School"},
    { key:"23958", district:"15", Block:"284", label:"PUMS MATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23959", district:"15", Block:"284", label:"GHS PERUMALPETTAI", Sch_type:"GovernmentHigh School"},
    { key:"23960", district:"1", Block:"286", label:"PUMS PUDUPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23961", district:"1", Block:"286", label:"RAMCO VIDYALAYA DALAVOI", Sch_type:"Un-aidedMiddle School"},
    { key:"23962", district:"24", Block:"288", label:"BRIGHTON MATRICULATION SCHOOL SAMBAVAR VADAKARAI", Sch_type:"Un-aidedMiddle School"},
    { key:"23963", district:"21", Block:"290", label:"SARASWATHI VIDHYALAYA MATRICULATION SCHOOL MELVENKATAPURAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23964", district:"13", Block:"291", label:"PUMS AGARAM", Sch_type:"GovernmentMiddle School"},
    { key:"23965", district:"23", Block:"292", label:"GANDHI MATRICULATION SHOOL ERIYUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23966", district:"15", Block:"293", label:"PUMS PUNGANUR", Sch_type:"GovernmentMiddle School"},
    { key:"23967", district:"23", Block:"294", label:"PUMS MUTHUPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"23968", district:"23", Block:"294", label:"PUMS MATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"23969", district:"23", Block:"294", label:"PUMS ANNANAGAR", Sch_type:"GovernmentMiddle School"},
    { key:"23970", district:"23", Block:"294", label:"PUMS PILLUR", Sch_type:"GovernmentMiddle School"},
    { key:"23971", district:"23", Block:"294", label:"SIVAGANGAI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23972", district:"23", Block:"294", label:"SCHOOL OF SCHOLARS", Sch_type:"Un-aidedMiddle School"},
    { key:"23973", district:"10", Block:"296", label:"ST. VINCENT'S NURSERY AND PRIMARY SCHOOL, MOLASUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23974", district:"10", Block:"296", label:"PUMS MAMBAKKAM", Sch_type:"GovernmentMiddle School"},
    { key:"23975", district:"10", Block:"296", label:"ST.ANTHONY'S SCHOOL, KUNNAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23976", district:"38", Block:"298", label:"Virudhunagar District Government Model School", Sch_type:"GovernmentHigher Secondary School"},
    { key:"23977", district:"2", Block:"299", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23978", district:"2", Block:"299", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23979", district:"2", Block:"299", label:"ORCHIDS THE INTERNATIONAL SCHOOL,PERUMBAKKAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23980", district:"2", Block:"299", label:"DAV SARADA ESHWAR VIDYALAYA POZHICHALUR", Sch_type:"Un-aidedMiddle School"},
    { key:"23981", district:"2", Block:"300", label:"AMETHYST INTERNATIONAL SCHOOL OTTIYAMBAKKAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23982", district:"2", Block:"300", label:"SRI SANKARA VIDYALAYA", Sch_type:"Un-aidedMiddle School"},
    { key:"23983", district:"4", Block:"301", label:"WEST PAARK INTERNATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23984", district:"4", Block:"302", label:"PUMS UNJAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23985", district:"4", Block:"302", label:"PUMS KURUMBAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"23986", district:"4", Block:"302", label:"YELLOW TRAIN SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23987", district:"4", Block:"302", label:"ZEN KIDZ MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23988", district:"4", Block:"302", label:"SRI SUKA BRAHMA MAHARISHI VIDYA MANDIR INTERNATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23989", district:"4", Block:"302", label:"AIR FORCE SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23990", district:"3", Block:"304", label:"SANDIPANI VIDYALAYA", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23991", district:"24", Block:"307", label:"KATTU BAVA HIGH SCHOOL,TENKASI ", Sch_type:"Partially AidedHigh School"},
    { key:"23992", district:"24", Block:"307", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"23993", district:"13", Block:"311", label:"PUMS KOTTAIYUR", Sch_type:"GovernmentMiddle School"},
    { key:"23994", district:"13", Block:"311", label:"SRI BALAGANGADARA SWAMIJI PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23995", district:"13", Block:"311", label:"UK PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23996", district:"13", Block:"311", label:"INDIAN PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"23997", district:"34", Block:"312", label:"PUMS KOTTAIYUR", Sch_type:"GovernmentMiddle School"},
    { key:"23998", district:"25", Block:"313", label:"HIDHAYATH MATRICULATION SCHOOL - VALLAM", Sch_type:"Un-aidedMiddle School"},
    { key:"23999", district:"25", Block:"313", label:"AMRITA VIDYALAYAM, THANJAVUR", Sch_type:"Un-aidedMiddle School"},
    { key:"24000", district:"29", Block:"317", label:"TKV MATRICULATION SCHOOL,THUMBALAM", Sch_type:"Un-aidedMiddle School"},
    { key:"24001", district:"29", Block:"317", label:"V.J.MATRICULATION SCHOOL,SITTILARAI(PO),MUSIRI,TIRUCHIRAPPALLI DISTRICT", Sch_type:"Un-aidedMiddle School"},
    { key:"24002", district:"2", Block:"322", label:"SELVAGAM SANTHANALAKSHMI NOBLE MATRICULATION SCHOOL, THIRUKKAZHUKUNDRAM", Sch_type:"Un-aidedMiddle School"},
    { key:"24003", district:"2", Block:"322", label:"MAAMALLAN SCHOOL POONCHERI", Sch_type:"Un-aidedMiddle School"},
    { key:"24004", district:"16", Block:"325", label:"GHS GANAPATHIPURAM", Sch_type:"GovernmentHigh School"},
    { key:"24005", district:"19", Block:"326", label:"PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM", Sch_type:"GovernmentMiddle School"},
    { key:"24006", district:"9", Block:"327", label:"JUBILATION MATRICULATION SCHOOL KALAMARUDUR", Sch_type:"Un-aidedMiddle School"},
    { key:"24007", district:"14", Block:"328", label:"THE AKSHAYA ACADEMY CAMPUS SCHOOL, THIRUPARAMKUNDRAM", Sch_type:"Un-aidedMiddle School"},
    { key:"24008", district:"2", Block:"329", label:"PUMS KOLATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"24009", district:"2", Block:"329", label:"Athena Global School", Sch_type:"Un-aidedMiddle School"},
    { key:"24010", district:"2", Block:"329", label:"VIJAY VIDHYASHRAM, PADUR", Sch_type:"Un-aidedMiddle School"},
    { key:"24011", district:"2", Block:"329", label:"MILTON BILLABONG INTERNATIONAL SCHOOL, THAIYUR ,KELAMBAKKAM", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24012", district:"2", Block:"329", label:"VELLORE INTERNATIONAL SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24013", district:"2", Block:"329", label:"CHETTINAD SARVALOKAA EDUCATION INTRENATIONAL SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24014", district:"31", Block:"331", label:"PUMS VINAYAGAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"24015", district:"23", Block:"331", label:"PUMS NEDUMARAM", Sch_type:"GovernmentMiddle School"},
    { key:"24016", district:"23", Block:"331", label:"PUMS M.PUDUR", Sch_type:"GovernmentMiddle School"},
    { key:"24017", district:"20", Block:"332", label:"RAMANATHAPURAM DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24018", district:"25", Block:"336", label:"GHS VARAGOOR", Sch_type:"GovernmentHigh School"},
    { key:"24019", district:"33", Block:"338", label:"TIRUVALLUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24020", district:"19", Block:"339", label:"PUDUKKOTTAI DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24021", district:"35", Block:"340", label:"PUMS THANDALAI", Sch_type:"GovernmentMiddle School"},
    { key:"24022", district:"35", Block:"340", label:"GHS,VADAKARAI", Sch_type:"GovernmentHigh School"},
    { key:"24023", district:"1", Block:"346", label:"GHSS KOVILUR", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24024", district:"1", Block:"346", label:"PUMS PUTHUR", Sch_type:"GovernmentMiddle School"},
    { key:"24025", district:"1", Block:"346", label:"PUMS THIRUPEYAR", Sch_type:"GovernmentMiddle School"},
    { key:"24026", district:"1", Block:"346", label:"PUMS VADUGAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"24027", district:"1", Block:"346", label:"ARIYALUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24028", district:"12", Block:"348", label:"S.V.C PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24029", district:"28", Block:"350", label:"PUPS K THIRAVIYAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"24030", district:"28", Block:"350", label:"AMRITA VIDYALAYAM", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24031", district:"7", Block:"352", label:"PONMALAR PUBLIC SCHOOL, KEERANUR", Sch_type:"Un-aidedMiddle School"},
    { key:"24032", district:"29", Block:"353", label:"SRI SABARI VIDYALA MATRICULATION SCHOOL,THOTTIYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"24033", district:"29", Block:"353", label:"SOWDAMBIKAA VIDYALAYA SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24034", district:"11", Block:"354", label:"Kanniyakumari District Government Model School", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24035", district:"21", Block:"359", label:"GHSS MAMBAKKAM", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24036", district:"17", Block:"360", label:"G GLOBAL SCHOOL, RAJAGOUNDAMPALAYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"24037", district:"32", Block:"363", label:"PUMS SOKKANUR", Sch_type:"GovernmentMiddle School"},
    { key:"24038", district:"32", Block:"363", label:"GHSS MANGALAM", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24039", district:"32", Block:"363", label:"PUMS, NALLUR", Sch_type:"GovernmentMiddle School"},
    { key:"24040", district:"32", Block:"363", label:"AMRITA VIDYALAYAM", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24041", district:"32", Block:"363", label:"PUMS HOUSING UNIT", Sch_type:"GovernmentMiddle School"},
    { key:"24042", district:"32", Block:"363", label:"LITTLE STAR MATRICULATION SCHOOL, K.V.R NAGAR", Sch_type:"Un-aidedMiddle School"},
    { key:"24043", district:"34", Block:"365", label:"GHS MEYYUR", Sch_type:"GovernmentHigh School"},
    { key:"24044", district:"34", Block:"365", label:"VELAMMAL VIDHYASHRAM", Sch_type:"Un-aidedMiddle School"},
    { key:"24045", district:"29", Block:"367", label:"ST.JOHN'S VESTRY,TRICHY", Sch_type:"Un-aidedMiddle School"},
    { key:"24046", district:"28", Block:"369", label:"AK SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24047", district:"26", Block:"370", label:"SRI SHANTHI VIJAI MATRICULATION SCHOOL, OOTY", Sch_type:"Un-aidedHigh School"},
    { key:"24048", district:"32", Block:"371", label:"PUMS KAMARAJ NAGAR", Sch_type:"GovernmentMiddle School"},
    { key:"24049", district:"32", Block:"371", label:"Tiruppur District Government Model School", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24050", district:"37", Block:"379", label:"AMS THIRUCHITRAMBALAM", Sch_type:"Fully AidedMiddle School"},
    { key:"24051", district:"37", Block:"379", label:"KAVI KAMBAN HIGH SCHOOL THIRUVAKKARAI", Sch_type:"Un-aidedHigh School"},
    { key:"24052", district:"7", Block:"380", label:"PUMS, ANDIPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"24053", district:"7", Block:"380", label:"PUMS,KOLLAPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"24054", district:"7", Block:"380", label:"DINDIGUL DISTRICT GOVERNMENT MODEL SCHOOL, DINDIGUL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24055", district:"29", Block:"381", label:"GHS, PUDUVADI", Sch_type:"GovernmentHigh School"},
    { key:"24056", district:"35", Block:"382", label:"PUMS KANDIYUR", Sch_type:"GovernmentMiddle School"},
    { key:"24057", district:"35", Block:"382", label:"PUMS KOTTAIYUR", Sch_type:"GovernmentMiddle School"},
    { key:"24058", district:"35", Block:"382", label:"PUMS NALLUR", Sch_type:"GovernmentMiddle School"},
    { key:"24059", district:"30", Block:"385", label:"GOVERNMENT HR SEC SCHOOL CHETTIKULAM", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24060", district:"30", Block:"385", label:"ST ANNS SCHOOL (ICSE)", Sch_type:"Un-aidedMiddle School"},
    { key:"24061", district:"34", Block:"387", label:"AGASTHIYA VIDYALAYA", Sch_type:"Un-aidedMiddle School"},
    { key:"24062", district:"24", Block:"388", label:"Tenkasi District Government Model School", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24063", district:"7", Block:"390", label:"PUMS,PALAPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"24064", district:"7", Block:"390", label:"PUMS,KOVILUR", Sch_type:"GovernmentMiddle School"},
    { key:"24065", district:"36", Block:"393", label:"PUMS ABDULLAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"24066", district:"36", Block:"393", label:"VELLORE DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24067", district:"34", Block:"395", label:"PUMS MATHUR", Sch_type:"GovernmentMiddle School"},
    { key:"24068", district:"13", Block:"398", label:"PUMS NALLUR", Sch_type:"GovernmentMiddle School"},
    { key:"24069", district:"18", Block:"399", label:"St MARY'S MATRIC HR SEC SCHOOL  V.KALATHUR", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24070", district:"18", Block:"399", label:"PUMS, VIJAYAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"24071", district:"18", Block:"399", label:"ST JOSEPH GLOBAL SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24072", district:"18", Block:"399", label:"PERAMBALUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24073", district:"18", Block:"399", label:"St.MARY'S PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24074", district:"18", Block:"399", label:"SPRING FIELD PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24075", district:"37", Block:"401", label:"NATIONAL ACADEMY MATRIC  SCHOOL THUMBUR  ", Sch_type:"Un-aidedMiddle School"},
    { key:"24076", district:"28", Block:"402", label:"PUMS, SAKKAMMALPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"24077", district:"28", Block:"402", label:"PUPS, Solaimalaiyanpatti", Sch_type:"GovernmentMiddle School"},
    { key:"24078", district:"33", Block:"403", label:"GOOD SHEPHERDS GLOBAL MATRIC SCHOOL, METTUPALAYAM", Sch_type:"Un-aidedMiddle School"},
    { key:"24079", district:"33", Block:"403", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24080", district:"33", Block:"403", label:"ABS GLOBAL SMART SCHOOL ,  ANNANUR", Sch_type:"Un-aidedMiddle School"},
    { key:"24081", district:"33", Block:"403", label:"TI SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24082", district:"33", Block:"403", label:"MAHARISHI VIDYA MANDIR NOLAMBUR", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24083", district:"5", Block:"406", label:"GOVERNMENT HIGH SCHOOL MATHUR", Sch_type:"GovernmentHigh School"},
    { key:"24084", district:"38", Block:"407", label:"PUMS KUPPAMPATTI", Sch_type:"GovernmentMiddle School"},
    { key:"24085", district:"38", Block:"407", label:"PUMS THADANGAM", Sch_type:"GovernmentMiddle School"},
    { key:"24086", district:"21", Block:"408", label:"RANIPET DISTRICT GOVERNMENT MODEL SCHOOL ", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24087", district:"21", Block:"409", label:"PUMS VASUR", Sch_type:"GovernmentMiddle School"},
    { key:"24088", district:"21", Block:"409", label:"PUMS KRISHNAPURAM", Sch_type:"GovernmentMiddle School"},
    { key:"24089", district:"21", Block:"409", label:"PUMS THENGAL", Sch_type:"GovernmentMiddle School"},
    { key:"24090", district:"21", Block:"409", label:"WISDOM MATRICULATION SCHOOL, PADIYAMPAKKAM", Sch_type:"Un-aidedHigh School"},
    { key:"24091", district:"21", Block:"409", label:"THE OASIS PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24092", district:"10", Block:"410", label:"PUMS ALAPAKKAM", Sch_type:"GovernmentMiddle School"},
    { key:"24093", district:"34", Block:"412", label:"PUMS AMMAPALAYAM", Sch_type:"GovernmentMiddle School"},
    { key:"24094", district:"22", Block:"413", label:"PUMS, NALLUR", Sch_type:"GovernmentMiddle School"},
    { key:"24095", district:"3", Block:"415", label:"MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24096", district:"3", Block:"415", label:"ST JOHNS MAT HSS", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24097", district:"3", Block:"415", label:"Shri Krishnaswamy Matric Hr. Sec. School", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24098", district:"3", Block:"415", label:"NARAYANA E-TECHNO SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24099", district:"3", Block:"416", label:"SRI CHAITANYA TECHNO SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24100", district:"3", Block:"417", label:"SRIVATSA MATRICULATION SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24101", district:"3", Block:"418", label:"NARAYANA e - TECHNO SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24102", district:"3", Block:"418", label:"VELANKANNI PUBLIC SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24103", district:"3", Block:"270", label:"ST ANNE'S GIRLS HSS", Sch_type:"Partially AidedHigher Secondary School"},
    { key:"24104", district:"3", Block:"419", label:"CSI MIDDLE SCHOOL", Sch_type:"Fully AidedMiddle School"},
    { key:"24105", district:"3", Block:"419", label:"Good Shepherd Matric Higher Secondary School", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24106", district:"3", Block:"419", label:"Mahila Vidyalaya Nursery & Primary School", Sch_type:"Un-aidedMiddle School"},
    { key:"24107", district:"3", Block:"420", label:"LITTLE LAMBS MATRICULATION SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24108", district:"3", Block:"420", label:"EVERWIN VIDHYASHRAM", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24109", district:"3", Block:"270", label:"SRI CHAITANYA TECHNO SCHOOL ROYAPURAM", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24110", district:"3", Block:"270", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24111", district:"3", Block:"420", label:"MAHARISHI VIDYA MANDIR", Sch_type:"Un-aidedHigher Secondary School"},
    { key:"24112", district:"31", Block:"331", label:"TIRUPATHUR DISTRICT GOVERNMENT MODEL SCHOOL", Sch_type:"GovernmentHigher Secondary School"},
    { key:"24113", district:"3", Block:"419", label:"M.CT.M CHIDAMBARAM CHETTYAR SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24114", district:"3", Block:"419", label:"SIR SIVASWAMI KALALAYA MATRICULATION SCHOOL", Sch_type:"Un-aidedHigh School"},
    { key:"24115", district:"3", Block:"420", label:"NARAYANA e-TECHNO SCHOOL", Sch_type:"Un-aidedMiddle School"},
    { key:"24116", district:"32", Block:"363", label: "Kumaran matriculation school", Sch_type: "Un-aided", Category: "High School"},
    { key:"24117", district:"15", Block:"284", label: "Sri vivekanandha nursary and primary school", Sch_type: "Un-aided", Category: "Middle School"},
    { key:"24118", district:"32", Block:"223", label: "Devathai Pri &Nursary School", Sch_type: "Un-aided", Category: "Middle School"},
    { key:"24119", district:"17", Block:"224", label: "Sbg nursery and primary school", Sch_type: "Un-aided", Category: "Middle School"},
    { key:"24120", district:"24", Block:"307", label: "Sri Parasakthi Vidyalaya - CBSE School Tenkasi", Sch_type: "Un-aided", Category: "Higher Secondary School"},
    { key:"24121", district:"34", Block:"318", label: "SRI MAHAVEER MATRICULATION SCHOOL - GUNAMBADI", Sch_type: "Un-aided", Category: "High School"},
    { key:"24122", district:"3", Block:"414", label: "Sun shine Chennai senior secondary school", Sch_type: "Un-aided", Category: "Higher Secondary School"},
    { key:"24123", district:"31", Block:"82", label: "Railway mixed higher secondary school", Sch_type: "Un-aided", Category: "Higher Secondary School"},
    { key:"24124", district:"38", Block:"282", label: "sun indiya matriculation higher secondary school", Sch_type: "Un-aided", Category: "High School"},
    { key:"24125", district:"35", Block:"340", label: "K M G Aided Middle School", Sch_type: "Aided", Category: "Middle School"},
    { key:"24126", district:"27", Block:"55", label: "O.R.school", Sch_type: "Un-aided", Category: "Middle School"},
    { key:"24127", district:"34", Block:"312", label: "Akshaya N&P School", Sch_type: "Un-aided", Category: "Middle School"},
    { key:"24128", district:"3", Block:"415", label: "Pon Vidyashram Group of Senior Secondary Schools", Sch_type: "Un-aided", Category: "High School"},
    { key:"24129", district:"22", Block:"30", label: "St. Joseph School", Sch_type: "Un-aided", Category: "High School"},
    { key:"24130", district:"21", Block:"21", label: "JAIG Senior Secondary School ", Sch_type: "Un-aided", Category: "High School"},
    { key:"24131", district:"3", Block:"259", label: "Al-Qalam Islamic School - Madarasa", Sch_type: "Un-aided", Category: "High School"},
    { key:"24132", district:"2", Block:"300", label: "Vels Global School", Sch_type: "Un-aided", Category: "High School"},
    { key:"24133", district:"3", Block:"195", label: "Sprouts Montessori", Sch_type: "Un-aided", Category: "Middle School"},
    { key:"24134", district:"27", Block:"55", label: "THE ASCENT ACADEMY", Sch_type: "Un-aided", Category: "Middle School"},
    { key:"24135", district:"37", Block:"404", label: "Achariya Siksha Mandir", Sch_type: "Un-aided", Category: "High School"},
    { key:"24136", district:"1", Block:"305", label: "mangayarkarsai manila nadunilai palli ariyalur district", Sch_type: "Un-aided", Category: "Middle School"},
    { key:"24137", district:"4", Block:"302", label: "ITERACY MISSION INTERNATIONAL  School", Sch_type: "Un-aided", Category: "High School"}
]

const SCHOOL_BY_DISTRICT = {
    "1": [
        {
            "key": "2292",
            "district": "1",
            "Block": "26",
            "label": "THE ADITYA BIRLA MATRICULATION SCHOOL REDDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        { 
            "key":"24136", "district":"1", 
            "Block":"305", "label": "mangayarkarsai manila nadunilai palli ariyalur district", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "2516",
            "district": "1",
            "Block": "346",
            "label": "SWAMI GIRLS MATRIC HR SEC SCHOOL KEELAPALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2708",
            "district": "1",
            "Block": "81",
            "label": "PUMS - SOORIYAMANAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2890",
            "district": "1",
            "Block": "81",
            "label": "FATIMA MATRICULATION HIGHER SECONDARY SCHOOL  JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3120",
            "district": "1",
            "Block": "81",
            "label": "PUMS-PADAINILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3360",
            "district": "1",
            "Block": "16",
            "label": "ST. MARTIN'S MATRICULATION  SCHOOL, ANDIMADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4043",
            "district": "1",
            "Block": "81",
            "label": "MODERN MATRICULATION  HIGHER  SECONDARY  SCHOOL  JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4415",
            "district": "1",
            "Block": "26",
            "label": "VENUS MATRICULATION  HIGH SCHOOL  ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4470",
            "district": "1",
            "Block": "81",
            "label": "PUMS-EDAIKATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4634",
            "district": "1",
            "Block": "26",
            "label": "NIRMALA MATRICULATION SCHOOL,  ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4651",
            "district": "1",
            "Block": "305",
            "label": "PUMS-CHOLAMADEVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4750",
            "district": "1",
            "Block": "346",
            "label": "PUMS SATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4807",
            "district": "1",
            "Block": "26",
            "label": "MONTFORT MATRICULATION  HIGHER SECONDARY SCHOOL  ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4824",
            "district": "1",
            "Block": "81",
            "label": "PUMS-VANAVANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4856",
            "district": "1",
            "Block": "346",
            "label": "PUMS KALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5066",
            "district": "1",
            "Block": "286",
            "label": "TSN MATRICULATION SCHOOL DALAVOI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5112",
            "district": "1",
            "Block": "81",
            "label": "RAJAN MIDDLE SCHOOL OKKANATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5194",
            "district": "1",
            "Block": "81",
            "label": "PUMS-MELAKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5245",
            "district": "1",
            "Block": "81",
            "label": "PUMS-NARASINGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5395",
            "district": "1",
            "Block": "81",
            "label": "PUMS-VANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5422",
            "district": "1",
            "Block": "346",
            "label": "PUMS THIRUVENGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5439",
            "district": "1",
            "Block": "81",
            "label": "PUMS-KAZHUVANTHONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5516",
            "district": "1",
            "Block": "26",
            "label": "PUMS AATHIKUDIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5545",
            "district": "1",
            "Block": "81",
            "label": "PUMS-THAZHUTHAZAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5643",
            "district": "1",
            "Block": "286",
            "label": "ST.THERESA MATRICULATION  HIGHER SECONDARY SCHOOL SENDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5661",
            "district": "1",
            "Block": "81",
            "label": "PERIYAR MATRICULATION  HSS JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5877",
            "district": "1",
            "Block": "81",
            "label": "ST.SEPASTIAN MIDDLE SCHOOL VIZHAPPALLAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5967",
            "district": "1",
            "Block": "81",
            "label": "GMS-AYUTHAKALAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6023",
            "district": "1",
            "Block": "81",
            "label": "PUMS-PICHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6256",
            "district": "1",
            "Block": "81",
            "label": "PUMS-UDAIYARPALAYAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6319",
            "district": "1",
            "Block": "16",
            "label": "SHRI SOWBAKYA MATRICULATION  HIGHER . SECONDARY SCHOOL ANDIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6343",
            "district": "1",
            "Block": "81",
            "label": "PUMS-PUDHUCHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6431",
            "district": "1",
            "Block": "16",
            "label": "PUMS KEELAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6585",
            "district": "1",
            "Block": "81",
            "label": "MEENAAKSHI RAMASAMY MATRIC HSS THATHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6675",
            "district": "1",
            "Block": "81",
            "label": "PUMS-SALUPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14695",
            "district": "1",
            "Block": "305",
            "label": "PUMS MANAGATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14696",
            "district": "1",
            "Block": "305",
            "label": "PUMS NACHIYARPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14697",
            "district": "1",
            "Block": "305",
            "label": "GOWDHAMA BUDDHAR HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14698",
            "district": "1",
            "Block": "305",
            "label": "TPEVR GOVT HIGH SCHOOL ULLIYAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14699",
            "district": "1",
            "Block": "305",
            "label": "PUMS KORAIKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14700",
            "district": "1",
            "Block": "305",
            "label": "GHS-VENMANKONDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14701",
            "district": "1",
            "Block": "305",
            "label": "GHS-TENKACHI PERUMAL NATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14702",
            "district": "1",
            "Block": "305",
            "label": "PUMS THADAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14703",
            "district": "1",
            "Block": "305",
            "label": "GHSS-T.PALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14704",
            "district": "1",
            "Block": "305",
            "label": "GHS-NAYAGANAIPRIYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14705",
            "district": "1",
            "Block": "305",
            "label": "PUMS-ANAIKUDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14706",
            "district": "1",
            "Block": "305",
            "label": "PUMS-PORPATHINTHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14707",
            "district": "1",
            "Block": "305",
            "label": "GHS-SILAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14708",
            "district": "1",
            "Block": "305",
            "label": "PUMS-EDANKANNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14709",
            "district": "1",
            "Block": "305",
            "label": "GHSS-KODALIKARUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14710",
            "district": "1",
            "Block": "305",
            "label": "PUMS KODALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14711",
            "district": "1",
            "Block": "305",
            "label": "GHSS-UDHAYANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14712",
            "district": "1",
            "Block": "305",
            "label": "PUMS-VEMBUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14713",
            "district": "1",
            "Block": "305",
            "label": "PUMS-THENNAVANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14714",
            "district": "1",
            "Block": "305",
            "label": "PUMS-KADUVETTANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14715",
            "district": "1",
            "Block": "305",
            "label": "GHSS-Z.SUTHAMALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14716",
            "district": "1",
            "Block": "305",
            "label": "GHSS-VIKKIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14717",
            "district": "1",
            "Block": "305",
            "label": "PUMS-KADAMPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14718",
            "district": "1",
            "Block": "305",
            "label": "GHS-GUNAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14719",
            "district": "1",
            "Block": "305",
            "label": "GHS-SATHAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14720",
            "district": "1",
            "Block": "305",
            "label": "PUMS-MUTTUVANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14721",
            "district": "1",
            "Block": "305",
            "label": "PUMS-GOVINTHAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14722",
            "district": "1",
            "Block": "305",
            "label": "PUMS-KASANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14723",
            "district": "1",
            "Block": "305",
            "label": "BHARATHA MATHA RC HR SEC SCHOOL NADUVALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14724",
            "district": "1",
            "Block": "305",
            "label": "PUMS-ARANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14725",
            "district": "1",
            "Block": "305",
            "label": "GHSS-THIRUPURANTHAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14726",
            "district": "1",
            "Block": "305",
            "label": "PUMS-ARULMOZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14727",
            "district": "1",
            "Block": "305",
            "label": "GHSS-KARAIKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14728",
            "district": "1",
            "Block": "305",
            "label": "PUMS-MATHANATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14872",
            "district": "1",
            "Block": "286",
            "label": "GHS ANGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14873",
            "district": "1",
            "Block": "286",
            "label": "GHS SANNASINALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14874",
            "district": "1",
            "Block": "286",
            "label": "GHSS-DALAVOI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14875",
            "district": "1",
            "Block": "286",
            "label": "PUMS ALATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14876",
            "district": "1",
            "Block": "286",
            "label": "VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL ALATHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14877",
            "district": "1",
            "Block": "286",
            "label": "GOVT.HR.SEC.SCHOOL,MULLUKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14878",
            "district": "1",
            "Block": "286",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14879",
            "district": "1",
            "Block": "286",
            "label": "PUMS MANAKKUDAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14880",
            "district": "1",
            "Block": "286",
            "label": "PUMS MULLAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14881",
            "district": "1",
            "Block": "286",
            "label": "ST PHILOMINAL HR SEC SCHOOL KULUMUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14882",
            "district": "1",
            "Block": "286",
            "label": "GHS-MANAPATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14883",
            "district": "1",
            "Block": "286",
            "label": "AIDED MID SCH MUDUKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14884",
            "district": "1",
            "Block": "286",
            "label": "PUMS KURICHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14885",
            "district": "1",
            "Block": "286",
            "label": "GHSS-KURUCHIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14886",
            "district": "1",
            "Block": "286",
            "label": "ANNAI HIGH SCHOOL MARAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14887",
            "district": "1",
            "Block": "286",
            "label": "KALAIMAGAL -HR-SEC-SCHOOL -THULAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14888",
            "district": "1",
            "Block": "286",
            "label": "PUMS SIRUKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14889",
            "district": "1",
            "Block": "286",
            "label": "GHS-NALLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14890",
            "district": "1",
            "Block": "286",
            "label": "VETRIVINAYAGA HR SEC SCHOOL NALLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14891",
            "district": "1",
            "Block": "286",
            "label": "GHSS-PONPARAPPI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14892",
            "district": "1",
            "Block": "286",
            "label": "PUMS PONPARAPPI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14893",
            "district": "1",
            "Block": "286",
            "label": "PUMS KEELAMALIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14894",
            "district": "1",
            "Block": "286",
            "label": "AIDED MID NAGALKUZHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14895",
            "district": "1",
            "Block": "286",
            "label": "GOVT HSS PARANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14896",
            "district": "1",
            "Block": "286",
            "label": "AIDED MID KUMIZHIYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14897",
            "district": "1",
            "Block": "286",
            "label": "PUMS PALAYAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14898",
            "district": "1",
            "Block": "286",
            "label": "GHSS-IRUMBULIKURUCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14899",
            "district": "1",
            "Block": "286",
            "label": "AIDED MID VEERAKKAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14900",
            "district": "1",
            "Block": "286",
            "label": "GHS-SIRUKADAMPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14901",
            "district": "1",
            "Block": "286",
            "label": "GHSS-ANANDAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14902",
            "district": "1",
            "Block": "286",
            "label": "GHS-UNJINI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14903",
            "district": "1",
            "Block": "286",
            "label": "PUMS MARUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14904",
            "district": "1",
            "Block": "286",
            "label": "GHS-PERIYAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14905",
            "district": "1",
            "Block": "286",
            "label": "PUMS VANJINAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14906",
            "district": "1",
            "Block": "286",
            "label": "PUMS CHOKKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14907",
            "district": "1",
            "Block": "286",
            "label": "PUMS NAKKAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14908",
            "district": "1",
            "Block": "286",
            "label": "GHSS MODEL SCHOOL-SENDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14909",
            "district": "1",
            "Block": "286",
            "label": "GHSS-SENDURAI(GIRLS)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14910",
            "district": "1",
            "Block": "286",
            "label": "ARIGNAR ANNA HR SEC SCHOOL SENDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14911",
            "district": "1",
            "Block": "286",
            "label": "GHS-CHOLANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14958",
            "district": "1",
            "Block": "26",
            "label": "GHS VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14959",
            "district": "1",
            "Block": "26",
            "label": "PUMS ELUPPAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14960",
            "district": "1",
            "Block": "26",
            "label": "GHSS POYYATHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14961",
            "district": "1",
            "Block": "26",
            "label": "GHS RAYAMPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14962",
            "district": "1",
            "Block": "26",
            "label": "PUMS SENNIVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14963",
            "district": "1",
            "Block": "26",
            "label": "PUMS OTTAKKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14964",
            "district": "1",
            "Block": "26",
            "label": "PUMS O.KOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14965",
            "district": "1",
            "Block": "26",
            "label": "GHS SALAYAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14966",
            "district": "1",
            "Block": "26",
            "label": "GHSS GOVINTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14967",
            "district": "1",
            "Block": "26",
            "label": "GHS THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14968",
            "district": "1",
            "Block": "26",
            "label": "VIDHYA MANDIR PUBLIC SCHOOL THAMARAIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14969",
            "district": "1",
            "Block": "26",
            "label": "RC NIRMALA GANDHI MIDDLE SCHOOL ARIYALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14970",
            "district": "1",
            "Block": "26",
            "label": "NIRMALA (G) HSS ARIYALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14971",
            "district": "1",
            "Block": "26",
            "label": "GHSS ARIYALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14972",
            "district": "1",
            "Block": "26",
            "label": "ST.MARY'S HIGH SCHOOL, ARIYALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14973",
            "district": "1",
            "Block": "26",
            "label": "CSI HSS ARIYALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14974",
            "district": "1",
            "Block": "26",
            "label": "GOVT G HS ARIYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14975",
            "district": "1",
            "Block": "26",
            "label": "KGBV SCHOOL- KARU SENAPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14976",
            "district": "1",
            "Block": "26",
            "label": "PUMS VALAJA NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14977",
            "district": "1",
            "Block": "26",
            "label": "PUMS ASTHINAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14978",
            "district": "1",
            "Block": "26",
            "label": "S K V P KOIL GIRLS HIGH SCHOOL KALLANKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14979",
            "district": "1",
            "Block": "26",
            "label": "GHS MANAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14980",
            "district": "1",
            "Block": "26",
            "label": "GOVT AIDED KRV MIDDLE SCHOOL LINGATHADIMEDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14981",
            "district": "1",
            "Block": "26",
            "label": "ARASUNAGAR MATRICULATION HIGHER SECONDARY SCHOOL  ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14982",
            "district": "1",
            "Block": "26",
            "label": "GHS AYANATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14983",
            "district": "1",
            "Block": "26",
            "label": "ADITYA BIRLA PUBLIC SCHOOL REDDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14984",
            "district": "1",
            "Block": "26",
            "label": "GHS KATTUPRINGIUAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14985",
            "district": "1",
            "Block": "26",
            "label": "PUMS MANNUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14986",
            "district": "1",
            "Block": "26",
            "label": "GHSS K.AMBAPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14987",
            "district": "1",
            "Block": "26",
            "label": "GHS VILANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14988",
            "district": "1",
            "Block": "26",
            "label": "GHS NAGAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14989",
            "district": "1",
            "Block": "26",
            "label": "PARIVALLAL HSS NAGAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14990",
            "district": "1",
            "Block": "26",
            "label": "PUMS MUNIYANKURICHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14991",
            "district": "1",
            "Block": "26",
            "label": "PUMS PUTHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14992",
            "district": "1",
            "Block": "26",
            "label": "PUMS VELIPRINKIUM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14993",
            "district": "1",
            "Block": "26",
            "label": "GHS REDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14994",
            "district": "1",
            "Block": "26",
            "label": "GHS SIRUVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14995",
            "district": "1",
            "Block": "26",
            "label": "PUMS KARU.SENAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14996",
            "district": "1",
            "Block": "26",
            "label": "PUMS KARUVIDACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14997",
            "district": "1",
            "Block": "26",
            "label": "GOVT HR SEC SCHOOL  KARU.POYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14998",
            "district": "1",
            "Block": "26",
            "label": "PUMS EDAYATHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14999",
            "district": "1",
            "Block": "26",
            "label": "GOVT HR SEC SCHOOL PERIYATHIRUKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15000",
            "district": "1",
            "Block": "26",
            "label": "PUMS CHETTITHIRUKKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15001",
            "district": "1",
            "Block": "26",
            "label": "PUMS KALLAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15002",
            "district": "1",
            "Block": "26",
            "label": "GHS ARUNGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15003",
            "district": "1",
            "Block": "26",
            "label": "PUMS ANDIPATTAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15004",
            "district": "1",
            "Block": "26",
            "label": "GHSS SUNDAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15005",
            "district": "1",
            "Block": "26",
            "label": "PUMS P.OTTAKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15006",
            "district": "1",
            "Block": "26",
            "label": "GOVT.MIDDLE.ATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15007",
            "district": "1",
            "Block": "26",
            "label": "PUMS PALLAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15008",
            "district": "1",
            "Block": "16",
            "label": "GHS OLAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15009",
            "district": "1",
            "Block": "16",
            "label": "PUMS THAVADANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15010",
            "district": "1",
            "Block": "16",
            "label": "GOVT HSS ALAGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15011",
            "district": "1",
            "Block": "16",
            "label": "PUMS CHINNAKARUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15012",
            "district": "1",
            "Block": "16",
            "label": "PUMS AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15013",
            "district": "1",
            "Block": "16",
            "label": "GHSS EDAIYAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15014",
            "district": "1",
            "Block": "16",
            "label": "GOVT.HR.SEC.SCHOOL,AYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15015",
            "district": "1",
            "Block": "16",
            "label": "PUMS KANGUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15016",
            "district": "1",
            "Block": "16",
            "label": "PUMS PATTANAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15017",
            "district": "1",
            "Block": "16",
            "label": "PUMS VILANDHAI -E",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15018",
            "district": "1",
            "Block": "16",
            "label": "GHSS ANDIMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15019",
            "district": "1",
            "Block": "16",
            "label": "GHS (GIRLS) ANDIMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15020",
            "district": "1",
            "Block": "16",
            "label": "GHS KAVARAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15021",
            "district": "1",
            "Block": "16",
            "label": "ST MARY'S MATRICULATION  HIGH SCHOOL  KAVARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15022",
            "district": "1",
            "Block": "16",
            "label": "DON BOSCO HR SEC SCHOOL VARADARAJANPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15023",
            "district": "1",
            "Block": "16",
            "label": "ALANGARA ANNAI(GIRLS) HSS VARADHARAJANPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15024",
            "district": "1",
            "Block": "16",
            "label": "PUMS PERIYAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15025",
            "district": "1",
            "Block": "16",
            "label": "GHS PERIYATHATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15026",
            "district": "1",
            "Block": "16",
            "label": "ST.ANNES. MS KEELANEDUVAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15027",
            "district": "1",
            "Block": "16",
            "label": "PUMS KOVILVALKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15028",
            "district": "1",
            "Block": "16",
            "label": "GHS THIRUKALLAPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15029",
            "district": "1",
            "Block": "16",
            "label": "ADW HSS POOVANIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15030",
            "district": "1",
            "Block": "16",
            "label": "PUMS KOOVATHUR EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15031",
            "district": "1",
            "Block": "16",
            "label": "GHS KOOVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15032",
            "district": "1",
            "Block": "16",
            "label": "PUMS KATTATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15033",
            "district": "1",
            "Block": "16",
            "label": "PUMS Z.KULATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15034",
            "district": "1",
            "Block": "16",
            "label": "PUMS KUVAGAM EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15035",
            "district": "1",
            "Block": "16",
            "label": "GHS KUVAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15036",
            "district": "1",
            "Block": "16",
            "label": "PUMS K.VALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15037",
            "district": "1",
            "Block": "16",
            "label": "GHS KODDUKKUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15038",
            "district": "1",
            "Block": "16",
            "label": "PUMS THERKKUPATI MARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15039",
            "district": "1",
            "Block": "16",
            "label": "GHSS MARUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15040",
            "district": "1",
            "Block": "16",
            "label": "PUMS DEVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15041",
            "district": "1",
            "Block": "16",
            "label": "GHSS ELAIYUR-VARIYANKAVAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15042",
            "district": "1",
            "Block": "16",
            "label": "PUMS Z.MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15043",
            "district": "1",
            "Block": "16",
            "label": "ANNAI LOURD(GIRLS)HSS THENNUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15044",
            "district": "1",
            "Block": "16",
            "label": "GHS NAGAMPANTHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15045",
            "district": "1",
            "Block": "16",
            "label": "PUMS CHINNATHUKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15046",
            "district": "1",
            "Block": "81",
            "label": "GMHSS JAYANKONDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15047",
            "district": "1",
            "Block": "81",
            "label": "FATHIMA (G) HSS JAYANKONDAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15048",
            "district": "1",
            "Block": "81",
            "label": "GHS (G) JAYANKONDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15049",
            "district": "1",
            "Block": "81",
            "label": "ANNAI TERESA MATRICULATION SCHOOL JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15050",
            "district": "1",
            "Block": "81",
            "label": "GHSS (G) UDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15051",
            "district": "1",
            "Block": "81",
            "label": "NOWFAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15052",
            "district": "1",
            "Block": "81",
            "label": "GHSS UDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15053",
            "district": "1",
            "Block": "81",
            "label": "SRI ANNAI MATRICULATION SCHOOL UDAYARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15054",
            "district": "1",
            "Block": "81",
            "label": "GHS CHINNAVALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15055",
            "district": "1",
            "Block": "81",
            "label": "PUMS-T.KUDIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15056",
            "district": "1",
            "Block": "81",
            "label": "MEENAAKSHI RAMASAMY HSS THATHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15057",
            "district": "1",
            "Block": "81",
            "label": "GHSS T.KEELAVELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15058",
            "district": "1",
            "Block": "81",
            "label": "GHSS T.POTTAKKOLLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15059",
            "district": "1",
            "Block": "81",
            "label": "PUMS-SUNDIPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15060",
            "district": "1",
            "Block": "81",
            "label": "GHS MUTHUSERVAMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15061",
            "district": "1",
            "Block": "81",
            "label": "GHSS MEENSURUTTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15062",
            "district": "1",
            "Block": "81",
            "label": "GHSS (G) MEENSURUTTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15063",
            "district": "1",
            "Block": "81",
            "label": "PUMS-VEERABOGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15064",
            "district": "1",
            "Block": "81",
            "label": "GHSS KALLATHUR THANDALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15065",
            "district": "1",
            "Block": "81",
            "label": "R C FATHIMA HS VADAVEEKKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15066",
            "district": "1",
            "Block": "81",
            "label": "GHS ADW VETHIYARVETTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15067",
            "district": "1",
            "Block": "81",
            "label": "GHS ERAVANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15068",
            "district": "1",
            "Block": "81",
            "label": "GOVT HSS AYYAPPANAYAKKANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15069",
            "district": "1",
            "Block": "81",
            "label": "GHSS MELANIKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15070",
            "district": "1",
            "Block": "81",
            "label": "CSI HSS MAHIMAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15071",
            "district": "1",
            "Block": "81",
            "label": "GHS SENGUNTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15072",
            "district": "1",
            "Block": "81",
            "label": "PUMS-KACHIPERUMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15073",
            "district": "1",
            "Block": "81",
            "label": "RAJA SATHA BAKKIYAM HIGH SCHOOL THULARANKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15074",
            "district": "1",
            "Block": "81",
            "label": "PUMS-KADARANKONDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15075",
            "district": "1",
            "Block": "81",
            "label": "GHSS ULKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15076",
            "district": "1",
            "Block": "81",
            "label": "GHSS GANGAIKONDACHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15077",
            "district": "1",
            "Block": "81",
            "label": "HELAN KELLER SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15078",
            "district": "1",
            "Block": "81",
            "label": "GHSS VANATHIRAYANPATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15079",
            "district": "1",
            "Block": "81",
            "label": "PUMS MEIKKAVALPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15080",
            "district": "1",
            "Block": "81",
            "label": "INDRA PUBLIC HR SEC SCHOOL THALUTHALAIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15081",
            "district": "1",
            "Block": "81",
            "label": "PUMS-EDAIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15082",
            "district": "1",
            "Block": "346",
            "label": "GHSS THIRUMAZHAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15083",
            "district": "1",
            "Block": "346",
            "label": "GHS KANDIRATHEETHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15084",
            "district": "1",
            "Block": "346",
            "label": "PUMS VILUPPANANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15085",
            "district": "1",
            "Block": "346",
            "label": "PUMS PALAYAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15086",
            "district": "1",
            "Block": "346",
            "label": "PUMS-KEELAESANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15087",
            "district": "1",
            "Block": "346",
            "label": "VEERAMAMUNIVAR MIDDLE.SCHOOL ELAKKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15088",
            "district": "1",
            "Block": "346",
            "label": "GOVERNMENT MODEL HR.SEC SCHOOL ELAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15089",
            "district": "1",
            "Block": "346",
            "label": "PUMS SENGARAYANKATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15090",
            "district": "1",
            "Block": "346",
            "label": "PUMS ALAGIYAMANAVALAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15091",
            "district": "1",
            "Block": "346",
            "label": "UC MIDDLESCHOOL (AIDED) PUDUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15092",
            "district": "1",
            "Block": "346",
            "label": "ST. IGNATIUS KULAMANICKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15093",
            "district": "1",
            "Block": "346",
            "label": "GHSS ELANDAIKUDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15094",
            "district": "1",
            "Block": "346",
            "label": "GHSS KAMARASAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15095",
            "district": "1",
            "Block": "346",
            "label": "GHS RAMANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15096",
            "district": "1",
            "Block": "346",
            "label": "GHSS KOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15097",
            "district": "1",
            "Block": "346",
            "label": "GHSS KURUVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15098",
            "district": "1",
            "Block": "346",
            "label": "GHS THOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15099",
            "district": "1",
            "Block": "346",
            "label": "PUMS CHINNAPATTAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15100",
            "district": "1",
            "Block": "346",
            "label": "PUMS VILAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15101",
            "district": "1",
            "Block": "346",
            "label": "GHS VENGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15102",
            "district": "1",
            "Block": "346",
            "label": "PUMS KORATHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15103",
            "district": "1",
            "Block": "346",
            "label": "PUMS ANGIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15104",
            "district": "1",
            "Block": "346",
            "label": "GHS SANNAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15105",
            "district": "1",
            "Block": "346",
            "label": "GHS PALINGANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15106",
            "district": "1",
            "Block": "346",
            "label": "PUMS KARAYAVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15107",
            "district": "1",
            "Block": "346",
            "label": "GHSS KEELAKAVATTANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15108",
            "district": "1",
            "Block": "346",
            "label": "PUMS SENAPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15109",
            "district": "1",
            "Block": "346",
            "label": "GHS KEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15110",
            "district": "1",
            "Block": "346",
            "label": "PUMS POONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15111",
            "district": "1",
            "Block": "346",
            "label": "ST. IGNATIUS HIGH SCHOOL  KOKKUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15112",
            "district": "1",
            "Block": "346",
            "label": "GHS VARANAVASI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15113",
            "district": "1",
            "Block": "346",
            "label": "GHSS KEELAPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15114",
            "district": "1",
            "Block": "346",
            "label": "ARUNACHALA MATRICULATION SCHOOL KEELAPALUVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15115",
            "district": "1",
            "Block": "346",
            "label": "PUMS PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15116",
            "district": "1",
            "Block": "346",
            "label": "GHS VETRIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15117",
            "district": "1",
            "Block": "346",
            "label": "PUMS THIRUPEYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15118",
            "district": "1",
            "Block": "346",
            "label": "PUMS VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15119",
            "district": "1",
            "Block": "346",
            "label": "GHSS KEELAKOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15120",
            "district": "1",
            "Block": "346",
            "label": "GHSS THIRUMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15121",
            "district": "1",
            "Block": "346",
            "label": "PUMS VETTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15122",
            "district": "1",
            "Block": "346",
            "label": "GHS KOVIL ESANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20973",
            "district": "1",
            "Block": "26",
            "label": "SRI RAMAKRISHANA PUBLIC SCHOOL VENKATAKRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21191",
            "district": "1",
            "Block": "26",
            "label": "GHSS MODEL SCHOOL ASITHINAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21248",
            "district": "1",
            "Block": "81",
            "label": "ST.ANDREW S MATRIC SCHOOL-JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21382",
            "district": "1",
            "Block": "286",
            "label": "GHS NAMAGUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21383",
            "district": "1",
            "Block": "26",
            "label": "GHS THELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21384",
            "district": "1",
            "Block": "81",
            "label": "GHS KOLLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21517",
            "district": "1",
            "Block": "16",
            "label": "ADWHS VILANTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21533",
            "district": "1",
            "Block": "305",
            "label": "ST. MICHEALS AIDED HIGH  SCHOOL KEELAMICHAELPATTY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21549",
            "district": "1",
            "Block": "305",
            "label": "CHOCKALINGAM VEMBU AMMAL HIGH SCHOOL SRIPURANTHAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21551",
            "district": "1",
            "Block": "81",
            "label": "NALLASIRIAR THIRU RAMACHANDRA HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21552",
            "district": "1",
            "Block": "81",
            "label": "MRV RAJA HR SEC SCHOOL PAPPAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21799",
            "district": "1",
            "Block": "346",
            "label": "SWAMI MATRIC HR SEC SCHOOL KEELAPALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21800",
            "district": "1",
            "Block": "81",
            "label": "MEENAKSHI RAMASAMY VIDHYALAYA THANTHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21801",
            "district": "1",
            "Block": "26",
            "label": "AKILA BHARATHY MATRICULATION HIGH SCHOOL  POYYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21802",
            "district": "1",
            "Block": "26",
            "label": "ANNAI INDHIRA HIGH SCHOOL VILANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21909",
            "district": "1",
            "Block": "26",
            "label": "NALANDHA HR SEC SCHOOL RAYAMPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21910",
            "district": "1",
            "Block": "305",
            "label": "JAYAM HIGH SCHOOL SUNDARESAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22536",
            "district": "1",
            "Block": "305",
            "label": "GOKILAMBAL MATRICULATION HIGHER SECONDARY SCHOOL KUZHAVADAIYAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22537",
            "district": "1",
            "Block": "305",
            "label": "GOKILAMBAL CBSE SCHOOL KUZHAVADAIYAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22541",
            "district": "1",
            "Block": "286",
            "label": "GHS AYANTHATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22543",
            "district": "1",
            "Block": "16",
            "label": "GHS SILAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22544",
            "district": "1",
            "Block": "16",
            "label": "GHS ELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22545",
            "district": "1",
            "Block": "81",
            "label": "N.R.PUBLIC SCHOOL UDAYARPLAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22546",
            "district": "1",
            "Block": "81",
            "label": "GOVERNMENT HIGH SCHOOL T.CHOZHANKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22547",
            "district": "1",
            "Block": "346",
            "label": "GHS AYANSUTHAMALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22688",
            "district": "1",
            "Block": "26",
            "label": "SABARMATHI VIDHYALAYA PUBLIC SCHOOL ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22909",
            "district": "1",
            "Block": "81",
            "label": "GHS DEVAMANGALAM-S",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22910",
            "district": "1",
            "Block": "346",
            "label": "GHS ERAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23011",
            "district": "1",
            "Block": "26",
            "label": "VINAYAGA PUBLIC SCHOOL MELAKARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23094",
            "district": "1",
            "Block": "81",
            "label": "SRI LAKSHMI VIDYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23292",
            "district": "1",
            "Block": "16",
            "label": "OHM SAKTHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23342",
            "district": "1",
            "Block": "81",
            "label": "JAI HIND INTERNATIOANL VIDHYALAYA SCHOOL GURUVALAPPARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23426",
            "district": "1",
            "Block": "16",
            "label": "FRANS SCHLOOZ SCHOOL OF EXCELLENCE (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23427",
            "district": "1",
            "Block": "81",
            "label": "PM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23428",
            "district": "1",
            "Block": "81",
            "label": "JAIHINDH INTERNATIONAL VIDHYALAYAV",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23429",
            "district": "1",
            "Block": "346",
            "label": "ARIYALUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23717",
            "district": "1",
            "Block": "16",
            "label": "PUMS DEVANUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23730",
            "district": "1",
            "Block": "26",
            "label": "GHS VELLUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23731",
            "district": "1",
            "Block": "26",
            "label": "GOLDEN GATES GLOBAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23960",
            "district": "1",
            "Block": "286",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23961",
            "district": "1",
            "Block": "286",
            "label": "RAMCO VIDYALAYA DALAVOI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24023",
            "district": "1",
            "Block": "346",
            "label": "GHSS KOVILUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24024",
            "district": "1",
            "Block": "346",
            "label": "PUMS PUTHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24025",
            "district": "1",
            "Block": "346",
            "label": "PUMS THIRUPEYAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24026",
            "district": "1",
            "Block": "346",
            "label": "PUMS VADUGAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24027",
            "district": "1",
            "Block": "346",
            "label": "ARIYALUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "2": [
        {
            "key": "23358",
            "district": "2",
            "Block": "299",
            "label": "St.Paul’s matriculation higher secondary school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24132", "district":"2", 
            "Block":"300", "label": "Vels Global School", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "23649",
            "district": "2",
            "Block": "299",
            "label": "RV matriculation school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23349",
            "district": "2",
            "Block": "299",
            "label": "Gateway the Complete School",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "23353",
            "district": "2",
            "Block": "299",
            "label": "RV Matriculation school",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "416",
            "district": "2",
            "Block": "300",
            "label": "St.Don Bosco Matric HS,Chrompet",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4039",
            "district": "2",
            "Block": "300",
            "label": "St 'Joseph MHSS Butt road",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4223",
            "district": "2",
            "Block": "114",
            "label": "SRI SANKARA VIDYALAYA MAT HSS, URAPAKKAM-603210",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4240",
            "district": "2",
            "Block": "299",
            "label": "Mother Matriculation Higher Secondary School, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4244",
            "district": "2",
            "Block": "322",
            "label": "ST MARY'S MATRIC HR.SEC SCHOOL, Mamallapuram-603104",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4262",
            "district": "2",
            "Block": "299",
            "label": "SREE IYAPPA MHSS, SOZHANGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4279",
            "district": "2",
            "Block": "300",
            "label": "Indra Gandhi MS Adambakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4281",
            "district": "2",
            "Block": "299",
            "label": "TAGORE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4320",
            "district": "2",
            "Block": "300",
            "label": "HOLY FAMILY CONVENT MATRIC HIGHER SECONDARY SCHOOL, KEELKATTALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4323",
            "district": "2",
            "Block": "299",
            "label": "Peniel Matric HSS, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4382",
            "district": "2",
            "Block": "322",
            "label": "BLESSINGS MATRIC HR.SEC.SCHOOL KEERAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4393",
            "district": "2",
            "Block": "299",
            "label": "kalaimagal Vidhya Mandir MHSS  madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4607",
            "district": "2",
            "Block": "300",
            "label": "ALWIN MEMORIAL PUBLIC SC,SELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4623",
            "district": "2",
            "Block": "300",
            "label": "MONTFORT MATRICULATION HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4631",
            "district": "2",
            "Block": "114",
            "label": "ST ANN'S MHSS, MELAMAIYUR-603002",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4640",
            "district": "2",
            "Block": "300",
            "label": "Marian Mat.HSS, St.Thomas Mount",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4644",
            "district": "2",
            "Block": "329",
            "label": "Ramaniyam Sankara HSS,Toraipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23383",
            "district": "2",
            "Block": "300",
            "label": "SDMS Jain Vidhyalaya Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4657",
            "district": "2",
            "Block": "299",
            "label": "Christ The King Matriculation HSS, Medavakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4661",
            "district": "2",
            "Block": "299",
            "label": "SRI SANKARA MAT SCHOOL THURAIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4689",
            "district": "2",
            "Block": "1",
            "label": "ST. JOSEPH'S MATRIC HR. SEC. SCHOOL, ACHARAPAKKAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4692",
            "district": "2",
            "Block": "299",
            "label": "Sacred Heart MHSS,Nanmangalam.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4696",
            "district": "2",
            "Block": "299",
            "label": "Sri S. B.S.C VIVEKANANDA VIDYALAYA, MUDICHUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4703",
            "district": "2",
            "Block": "300",
            "label": "HEBA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4711",
            "district": "2",
            "Block": "300",
            "label": "HOLY QUEEN MATRIC. HR. SEC. SCHOOL, NAGALKENI, CHROMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4713",
            "district": "2",
            "Block": "300",
            "label": "SHIKSHAA MATRIC HR. SEC. SCHOOL,  HASTHINAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4751",
            "district": "2",
            "Block": "299",
            "label": "ST ANTONY'S MATRIC HR. SEC. SCHOOL, KOTTIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4754",
            "district": "2",
            "Block": "114",
            "label": "RKN GYAN JOTHI PUBLIC  SCHOOL, ANUMANTHA PUTHERI.-603002.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4756",
            "district": "2",
            "Block": "114",
            "label": "Neelan Matric Hr Sec School Guduvancheri-603202",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4791",
            "district": "2",
            "Block": "114",
            "label": "JRK MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23372",
            "district": "2",
            "Block": "329",
            "label": "Sushil Hari International Residency school",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "4802",
            "district": "2",
            "Block": "299",
            "label": "Sri Selva Vignesh, Pozhichalur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4809",
            "district": "2",
            "Block": "299",
            "label": "St John's  Matriculation  Higher  Secondary  School  ,Perungalathur.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4852",
            "district": "2",
            "Block": "114",
            "label": "ST JOHN'S MATRIC. HR.SEC.SCHOOL, NANDHIVARAM, GUDUVANCHERI-603202",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4860",
            "district": "2",
            "Block": "299",
            "label": "MARYA NIVAS MHSS, POZHICHALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4876",
            "district": "2",
            "Block": "329",
            "label": "BUVANA KRISHNAN MAT.HR.SEC.SCHOOL,KELAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4878",
            "district": "2",
            "Block": "299",
            "label": "EBENEZER MATRIC HIGH SCHOOL, NEW PERUNGALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4897",
            "district": "2",
            "Block": "300",
            "label": "ST. MARY MATRIC HR SEC SCHOOL, ZAMIN PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4900",
            "district": "2",
            "Block": "300",
            "label": "HILTON MHSS, PALAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4902",
            "district": "2",
            "Block": "300",
            "label": "SANTHOSH VID MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4929",
            "district": "2",
            "Block": "300",
            "label": "Vyasa Vid.MHSS,Puzhuthivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4932",
            "district": "2",
            "Block": "329",
            "label": "EVERGREEN MATRICULATION HIGHER S ECONDARY SCHOOL, THIRUPORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4935",
            "district": "2",
            "Block": "300",
            "label": "St.Sebastian  MHSS Pallavaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4941",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARA VIDYALAYA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4951",
            "district": "2",
            "Block": "299",
            "label": "ANNAI VIOLET MATRICULATION HIGHER SECONDARY SCHOOL CHITLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4964",
            "district": "2",
            "Block": "299",
            "label": "MANUELMONY MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4982",
            "district": "2",
            "Block": "300",
            "label": "ANNAI VELANKANI MHSS, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4988",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARAVID.MHSS, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4999",
            "district": "2",
            "Block": "329",
            "label": "AARUPADAI VEEDU MATRICULATION HIGHER SECONDARY SCHOOL, THIRUPORUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5004",
            "district": "2",
            "Block": "329",
            "label": "ST. MARY'S MHSS, KELAMBAKKAM-603103",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5025",
            "district": "2",
            "Block": "51",
            "label": "St.JOSEPH MATRIC,PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5037",
            "district": "2",
            "Block": "299",
            "label": "LYDIA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5060",
            "district": "2",
            "Block": "299",
            "label": "SRI SESHADRI MHSS CHROMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5073",
            "district": "2",
            "Block": "299",
            "label": "NAPPINNAI MAT SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5134",
            "district": "2",
            "Block": "300",
            "label": "NADAR SANGA MATRIC. HR. SEC .SCHOOL, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5257",
            "district": "2",
            "Block": "300",
            "label": "St.Peters Mat.HSS, St.Thomas Mount",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5267",
            "district": "2",
            "Block": "114",
            "label": "SRI VISHWA VIDYALAYA  MHSS, Vandalur-600048",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5275",
            "district": "2",
            "Block": "300",
            "label": "ST MARKS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5276",
            "district": "2",
            "Block": "299",
            "label": "Rosily MHSS Chitlapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5284",
            "district": "2",
            "Block": "299",
            "label": "St.Peter's Matriculation Higher Secondary School,Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5286",
            "district": "2",
            "Block": "299",
            "label": "Brilliant MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5289",
            "district": "2",
            "Block": "114",
            "label": "SRI. K.CHUNNILAL JAIN VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5293",
            "district": "2",
            "Block": "299",
            "label": "ST GEORGE MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5298",
            "district": "2",
            "Block": "114",
            "label": "R K M VID MHSS,Chengalpattu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5309",
            "district": "2",
            "Block": "300",
            "label": "New Prince MHSS Ullagaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5324",
            "district": "2",
            "Block": "299",
            "label": "Rajkumar Sulochana MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5326",
            "district": "2",
            "Block": "299",
            "label": "ELLEN SHARMA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5327",
            "district": "2",
            "Block": "300",
            "label": "GOOD SHEPHERD MATRICULATION HIGHER SECONDARY SCHOOL, OLD PALLAVARAM, CHENNAI- 117",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5329",
            "district": "2",
            "Block": "114",
            "label": "ST JOSEPH MAT HSS CHENGELPUT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5348",
            "district": "2",
            "Block": "114",
            "label": "ST.ANNE'S NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5351",
            "district": "2",
            "Block": "299",
            "label": "ZION MAT HSS MADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5352",
            "district": "2",
            "Block": "300",
            "label": "Anna Mat Hr School, East Tambaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5375",
            "district": "2",
            "Block": "299",
            "label": "Loyola MHSS Chitlabakkam ch-64",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5384",
            "district": "2",
            "Block": "300",
            "label": "National Mat.Hr.Sec.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5388",
            "district": "2",
            "Block": "114",
            "label": "BRINDAVAN PUBLIC SCHOOL, ATHUR-603101, CHENGALPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5392",
            "district": "2",
            "Block": "300",
            "label": "KARPAGA VIGNESWARA VIDYALAYA M. H. S. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5394",
            "district": "2",
            "Block": "322",
            "label": "SEVENTH DAY ADVENTIST MHSS PUDUPATTINAM, KALPAKKAM-603 102",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5399",
            "district": "2",
            "Block": "114",
            "label": "EMMANUEL MATRICULATION SCHOOL,URAPPAKKAM-603211",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5400",
            "district": "2",
            "Block": "300",
            "label": "S.C.S MHSS, Nehru Ngr,chrompet,ch-44",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5401",
            "district": "2",
            "Block": "299",
            "label": "JOSHUA MHSS ,OLD PERUNGALATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5408",
            "district": "2",
            "Block": "299",
            "label": "Infant Jesus Mat. Sch, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5411",
            "district": "2",
            "Block": "299",
            "label": "St.Antony Matric HSS, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5416",
            "district": "2",
            "Block": "300",
            "label": "VIDHYA MANDIR MS,  EAST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5431",
            "district": "2",
            "Block": "153",
            "label": "CRESCENT MHSS,CHEYYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5444",
            "district": "2",
            "Block": "300",
            "label": "GK Shetty Hindu Vid MHSS Adambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5445",
            "district": "2",
            "Block": "322",
            "label": "INFANT JESUS MHSS, PUDUPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5470",
            "district": "2",
            "Block": "300",
            "label": "Indian Matric HSS, Tambaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5482",
            "district": "2",
            "Block": "300",
            "label": "ST.VINCENT PALLOTTI MHSS ZAMIN PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5484",
            "district": "2",
            "Block": "114",
            "label": "Sivananda Saraswathi  MHSS,Kattankulathur-603203",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5491",
            "district": "2",
            "Block": "299",
            "label": "LAXMI NARAYANA MATRIC SCHOOL,CHENNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5535",
            "district": "2",
            "Block": "299",
            "label": "St John's MS Madambakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5550",
            "district": "2",
            "Block": "329",
            "label": "BHARATHA VIDYALAYA MATRICULATION HR SEC SCHOOL, THANDALAM-TIRUPORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5562",
            "district": "2",
            "Block": "300",
            "label": "Rajkumar Suloch MHSS Nanganallur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5634",
            "district": "2",
            "Block": "299",
            "label": "Jeppiaar Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5638",
            "district": "2",
            "Block": "299",
            "label": "St. JOHN'S  MATRIC. HR.SEC.SCHOOL, DURGA NAGAR,TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5663",
            "district": "2",
            "Block": "329",
            "label": "ADWMS Melakottaiyur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5679",
            "district": "2",
            "Block": "1",
            "label": "PAMELA MARTINEZ PATHWAY MAT. SCHOOL, AGILI VILLAGE.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5680",
            "district": "2",
            "Block": "329",
            "label": "ST JOSEPH'S MATRICULATION SCHOOL,MELAKOTTAIYUR-600127",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5683",
            "district": "2",
            "Block": "300",
            "label": "N.S.N Matric Hr.Sec,School Chrompet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5698",
            "district": "2",
            "Block": "162",
            "label": "DR V.GENGUSAMY NAIDU MS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5703",
            "district": "2",
            "Block": "114",
            "label": "SHRI NATESAN VIDYASALA MATRICULATION HIGHER SECONDARY SCHOOL, MANNIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5727",
            "district": "2",
            "Block": "300",
            "label": "ZION MAT HSS SELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5733",
            "district": "2",
            "Block": "300",
            "label": "Sitadevi Garodia Hindu Vidyalaya M.H.S.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5741",
            "district": "2",
            "Block": "51",
            "label": "GVR MATRIC HR. SEC. SCHOOL - KADAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5743",
            "district": "2",
            "Block": "51",
            "label": "KVS MATRICULATION HIGHER SECONDARY SCHOOL, KADAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5751",
            "district": "2",
            "Block": "299",
            "label": "ZION MATRICULATION HIGHER SECONDARY  SCHOOL,  SEMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5764",
            "district": "2",
            "Block": "299",
            "label": "AALFA MATRIC HR SEC SCHOOL PEERKANKARANAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5800",
            "district": "2",
            "Block": "299",
            "label": "PROF. S.A. MAT. HR. SEC. SCHOOL ,Semmanchery-119",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5897",
            "district": "2",
            "Block": "162",
            "label": "BLUE STAR NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5983",
            "district": "2",
            "Block": "300",
            "label": "D.A.V School Adambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5991",
            "district": "2",
            "Block": "300",
            "label": "SRI VENKATESWARA VID MATRIC SCHOOL, SELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5992",
            "district": "2",
            "Block": "114",
            "label": "SHALOM MAT HSS, VANDALUR-48",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5993",
            "district": "2",
            "Block": "300",
            "label": "P.M.S  MATRICULATION HIGHER SECONDARY SCHOOL  NANGANALLUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6010",
            "district": "2",
            "Block": "300",
            "label": "MCC CAMPUS MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6019",
            "district": "2",
            "Block": "300",
            "label": "SACRED HEART MATRIC HIGHER SECONDARY SCHOOL, ANAKAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6022",
            "district": "2",
            "Block": "299",
            "label": "ST PIUS MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6028",
            "district": "2",
            "Block": "299",
            "label": "Kings MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6050",
            "district": "2",
            "Block": "299",
            "label": "MONTFORT MHSS, PERUNGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6057",
            "district": "2",
            "Block": "300",
            "label": "Emmanuel MHSS Pammal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6076",
            "district": "2",
            "Block": "299",
            "label": "NSN MHSS CHITLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6079",
            "district": "2",
            "Block": "300",
            "label": "SMT RAMKUWAR DEVI FOMRA VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6081",
            "district": "2",
            "Block": "114",
            "label": "TMS VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6089",
            "district": "2",
            "Block": "300",
            "label": "EMMANUEL NURSERY & PRIMARY SCHOOL, ALANDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6125",
            "district": "2",
            "Block": "114",
            "label": "ANANTHAVALLI MAT HSS, URAPAKKAM-603210",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6143",
            "district": "2",
            "Block": "300",
            "label": "KAVITHA MHSS, PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6144",
            "district": "2",
            "Block": "114",
            "label": "LITTLE JACKY MAT HSS CHENGALPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6153",
            "district": "2",
            "Block": "329",
            "label": "SRI VANI VID. MAT.HSS, THIRUPORUR-603110",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6277",
            "district": "2",
            "Block": "299",
            "label": "SHRIRAM MATRICULATION SCHOOL, THIRUNEERMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6318",
            "district": "2",
            "Block": "300",
            "label": "VKK Ammaniammal Mat.HS, Pammal",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6335",
            "district": "2",
            "Block": "114",
            "label": "Annai Venankani NPS Nedukundram",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6346",
            "district": "2",
            "Block": "300",
            "label": "New Prince MHSS Adambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6365",
            "district": "2",
            "Block": "299",
            "label": "BHARATH DASS MHSS PERUNGUDI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6370",
            "district": "2",
            "Block": "299",
            "label": "VANI MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6382",
            "district": "2",
            "Block": "300",
            "label": "ALANDUR MUNICIPEL MIDDLE SCHOOL ADAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6384",
            "district": "2",
            "Block": "299",
            "label": "A.V.G. VIDYALAYA MATRIC.HIGHER SECONDARY SCHOOL,KOVILAMBAKKAM .",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6390",
            "district": "2",
            "Block": "329",
            "label": "MURRAY RABINDRA Matriculation SCHOOL, PUTHUPAKKAM-603103",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6434",
            "district": "2",
            "Block": "300",
            "label": "christ king matriculation school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6478",
            "district": "2",
            "Block": "300",
            "label": "Modern senior secondary school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6492",
            "district": "2",
            "Block": "329",
            "label": "PUMS KAYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6567",
            "district": "2",
            "Block": "329",
            "label": "PUMS KOTTAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6613",
            "district": "2",
            "Block": "299",
            "label": "ZIGMA MHSS, MEDAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6633",
            "district": "2",
            "Block": "299",
            "label": "SACRED HEART MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6641",
            "district": "2",
            "Block": "300",
            "label": "Hindu Religious  Aided MS, Alandur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6651",
            "district": "2",
            "Block": "300",
            "label": "Sri Ramakrishna Matric HSS, Tambaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6652",
            "district": "2",
            "Block": "300",
            "label": "SRI RAMANA VID.MS,WEST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6657",
            "district": "2",
            "Block": "114",
            "label": "Mahindra World School Veerapuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6666",
            "district": "2",
            "Block": "114",
            "label": "ST. MARY'S MATRICULATION HIGHER SECONDARY SCHOOL, CHENGALPATTU-603002",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6681",
            "district": "2",
            "Block": "300",
            "label": "SIR & LADY  M VENKATASUBBA RAO MHSS, TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6699",
            "district": "2",
            "Block": "329",
            "label": "SUDDHANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL , AMMAPETTAI.603108",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6705",
            "district": "2",
            "Block": "299",
            "label": "VGP  PHILOMINAL  MATRICULATION  SCHOOL , INJAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6725",
            "district": "2",
            "Block": "114",
            "label": "PUMS, UNAMANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6744",
            "district": "2",
            "Block": "300",
            "label": "MMS URDU PALLAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7839",
            "district": "2",
            "Block": "329",
            "label": "PUMS KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7840",
            "district": "2",
            "Block": "329",
            "label": "ACMS PONMAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7841",
            "district": "2",
            "Block": "329",
            "label": "GHSS MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7842",
            "district": "2",
            "Block": "329",
            "label": "MAHARISHI VIDYA MANDIR SR SEC SCHOOL - POLACHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7843",
            "district": "2",
            "Block": "329",
            "label": "Ansaldo HS(Unaided), Thazhambur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7844",
            "district": "2",
            "Block": "329",
            "label": "PUMS NAVALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7845",
            "district": "2",
            "Block": "329",
            "label": "PADMA ADARSH HIGHER SECONDARY SCHOOL - VANIYANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7846",
            "district": "2",
            "Block": "329",
            "label": "PUMS KANATHUR REDDY KUPPAM COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7847",
            "district": "2",
            "Block": "329",
            "label": "ST. LOURDES MATRICULATION SCHOOL, MUTTUKKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7848",
            "district": "2",
            "Block": "329",
            "label": "PUMS KAZHIPATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7849",
            "district": "2",
            "Block": "329",
            "label": "GATE WAY INTERNATIONAL, KAZHIPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7850",
            "district": "2",
            "Block": "329",
            "label": "PADMA SESHADRI BALA BHAVAN SENIOR SECONDARY SCHOOL,SIRUSERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7851",
            "district": "2",
            "Block": "329",
            "label": "GHSS KELAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7852",
            "district": "2",
            "Block": "329",
            "label": "SATHANA SPECIAL SCHOOL FOR DEAF",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7853",
            "district": "2",
            "Block": "329",
            "label": "ST. FRANCIS DE SALES MHSS ,  KELAMBAKKAM-603 103",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7854",
            "district": "2",
            "Block": "329",
            "label": "Panchayat Union Middle School, PADUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7855",
            "district": "2",
            "Block": "329",
            "label": "ST JOSEPH HSS KOVALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7856",
            "district": "2",
            "Block": "329",
            "label": "Govt Higher Secondary  School,KOVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7857",
            "district": "2",
            "Block": "329",
            "label": "GURUKULAM A TRUST CHILDREN INITIATIVE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7858",
            "district": "2",
            "Block": "329",
            "label": "ADWHSS THAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7859",
            "district": "2",
            "Block": "329",
            "label": "JAGANNATH VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7860",
            "district": "2",
            "Block": "329",
            "label": "PUMS PERUNTHANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7861",
            "district": "2",
            "Block": "329",
            "label": "GHS ANUMANTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7862",
            "district": "2",
            "Block": "329",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , SIRUNKUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7863",
            "district": "2",
            "Block": "329",
            "label": "GHSS NELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7864",
            "district": "2",
            "Block": "329",
            "label": "CEE DEE YES DAV SC AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7865",
            "district": "2",
            "Block": "329",
            "label": "PUMS VENBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7866",
            "district": "2",
            "Block": "329",
            "label": "GHS ILLALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7867",
            "district": "2",
            "Block": "329",
            "label": "Government Higher  Secondary School - NEMMELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7868",
            "district": "2",
            "Block": "329",
            "label": "PUMS THANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7869",
            "district": "2",
            "Block": "329",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  SEMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7870",
            "district": "2",
            "Block": "329",
            "label": "GOVERNMENT HIGH SCHOOL  KARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7871",
            "district": "2",
            "Block": "329",
            "label": "PUMS MELAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7872",
            "district": "2",
            "Block": "329",
            "label": "GHS ORAGADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7873",
            "district": "2",
            "Block": "329",
            "label": "PUMS MULLIPPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7874",
            "district": "2",
            "Block": "329",
            "label": "PUMS RAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7875",
            "district": "2",
            "Block": "329",
            "label": "GHSS MANAMPATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7876",
            "district": "2",
            "Block": "329",
            "label": "Panchayat Union Middle School - KUNNAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7877",
            "district": "2",
            "Block": "329",
            "label": "PUMS KANNAGAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7878",
            "district": "2",
            "Block": "329",
            "label": "PUMS AMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7879",
            "district": "2",
            "Block": "329",
            "label": "ADWMS ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7880",
            "district": "2",
            "Block": "329",
            "label": "Adi Dravida Welfare High School - PATTIPULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7881",
            "district": "2",
            "Block": "329",
            "label": "GHS PAIYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7882",
            "district": "2",
            "Block": "329",
            "label": "R.C ST JOSEPH'S HIGH SCHOOL KOOTHAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7883",
            "district": "2",
            "Block": "329",
            "label": "Panchayat Union Middle School - SALAVANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7884",
            "district": "2",
            "Block": "329",
            "label": "GBHSS THIRUPORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7885",
            "district": "2",
            "Block": "329",
            "label": "GHSS(G) THIRUPORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7886",
            "district": "2",
            "Block": "114",
            "label": "GHS, Mannivakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7887",
            "district": "2",
            "Block": "114",
            "label": "Deva Annai Mat HS Kilambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7888",
            "district": "2",
            "Block": "114",
            "label": "ADWHSS, KILAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7889",
            "district": "2",
            "Block": "114",
            "label": "GHS, Karanai Puducheri",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7890",
            "district": "2",
            "Block": "114",
            "label": "PUMS, ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7891",
            "district": "2",
            "Block": "114",
            "label": "GHS, KOLAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7892",
            "district": "2",
            "Block": "114",
            "label": "PUMS, PERUMATTUNALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7893",
            "district": "2",
            "Block": "114",
            "label": "GHS ASTHINAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7894",
            "district": "2",
            "Block": "114",
            "label": "Government High School Kumizhi.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7895",
            "district": "2",
            "Block": "114",
            "label": "PUMS, KEERAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7896",
            "district": "2",
            "Block": "114",
            "label": "PUMS, NALLAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7897",
            "district": "2",
            "Block": "114",
            "label": "GHS, KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7898",
            "district": "2",
            "Block": "114",
            "label": "GHS, RATHINAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7899",
            "district": "2",
            "Block": "114",
            "label": "PUMS, MELKALVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7900",
            "district": "2",
            "Block": "114",
            "label": "PUMS, KALVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7901",
            "district": "2",
            "Block": "114",
            "label": "GHS, KAYARAMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7902",
            "district": "2",
            "Block": "114",
            "label": "PUMS, APPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7903",
            "district": "2",
            "Block": "114",
            "label": "PUMS, 81 VENBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7904",
            "district": "2",
            "Block": "114",
            "label": "PUMS, GURUVANMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7905",
            "district": "2",
            "Block": "114",
            "label": "PUMS, REDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7906",
            "district": "2",
            "Block": "114",
            "label": "PUMS, KONGANANCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7907",
            "district": "2",
            "Block": "114",
            "label": "ADWHSS Palur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7908",
            "district": "2",
            "Block": "114",
            "label": "LittleFlower High School,Karumbakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7909",
            "district": "2",
            "Block": "114",
            "label": "St. Paul's MHSS, Thimmavaram-603101",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7910",
            "district": "2",
            "Block": "114",
            "label": "PUMS, MELACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7911",
            "district": "2",
            "Block": "114",
            "label": "PUMS, VILLIYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7912",
            "district": "2",
            "Block": "114",
            "label": "PUMS, ATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7913",
            "district": "2",
            "Block": "114",
            "label": "Bakthavatchalam (Aided) HS, Athur",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7914",
            "district": "2",
            "Block": "114",
            "label": "BRINDAVAN MHSS, Athur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7915",
            "district": "2",
            "Block": "114",
            "label": "RLT ACADEMY ATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7916",
            "district": "2",
            "Block": "114",
            "label": "PUMS, CHETTIPUNIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7917",
            "district": "2",
            "Block": "114",
            "label": "Gnanodaya Unaided HS, Venkatapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7918",
            "district": "2",
            "Block": "114",
            "label": "PUMS, 73 KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7919",
            "district": "2",
            "Block": "114",
            "label": "GHS, KARUNILAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7920",
            "district": "2",
            "Block": "114",
            "label": "PUMS THENMELPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7921",
            "district": "2",
            "Block": "114",
            "label": "PUMS KONDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7922",
            "district": "2",
            "Block": "114",
            "label": "GHSS ANJUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7923",
            "district": "2",
            "Block": "114",
            "label": "PUMS SENNERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7924",
            "district": "2",
            "Block": "114",
            "label": "PUMS PATTARAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7925",
            "district": "2",
            "Block": "114",
            "label": "PUMS PARANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7926",
            "district": "2",
            "Block": "114",
            "label": "ADWMS, OZHALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7927",
            "district": "2",
            "Block": "114",
            "label": "PUMS 230 VALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7928",
            "district": "2",
            "Block": "114",
            "label": "St.Mary's MHSS, Nandhivaram, Guduvancherri-603202",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7929",
            "district": "2",
            "Block": "114",
            "label": "ADWMS, NANDHIVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7930",
            "district": "2",
            "Block": "114",
            "label": "GBHSS, NANDHIVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7931",
            "district": "2",
            "Block": "114",
            "label": "GGHSS, NANDHIVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7932",
            "district": "2",
            "Block": "114",
            "label": "BHARATHIYAR MHSS, GUDUVANCHERY-603202",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7933",
            "district": "2",
            "Block": "114",
            "label": "Bharathiyar MS Guduvancheri",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7934",
            "district": "2",
            "Block": "114",
            "label": "PUMS GUDUVANCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7935",
            "district": "2",
            "Block": "114",
            "label": "VIDYA MANDIR @ ESTANCIA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7936",
            "district": "2",
            "Block": "114",
            "label": "PUMS POTHERI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7937",
            "district": "2",
            "Block": "114",
            "label": "PUMS NINNAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7938",
            "district": "2",
            "Block": "114",
            "label": "ST.JOSEPH'S MATRIC HR.SEC.SCHOOL, MARAIMALAI NAGAR-603209",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7939",
            "district": "2",
            "Block": "114",
            "label": "GHSS MM NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7940",
            "district": "2",
            "Block": "114",
            "label": "PUMS GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7941",
            "district": "2",
            "Block": "114",
            "label": "PUMS KALIVANTHAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7942",
            "district": "2",
            "Block": "114",
            "label": "RSK MS GOKULAPURAM 603204",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7943",
            "district": "2",
            "Block": "114",
            "label": "CSI (Aided) HSS  Melrosapuram",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7944",
            "district": "2",
            "Block": "114",
            "label": "GHS, PERAMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7945",
            "district": "2",
            "Block": "114",
            "label": "DR.MANGALAM(AIDED) HIGH SCHOOL, KATTANKOLATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7946",
            "district": "2",
            "Block": "114",
            "label": "GHS, THIRUKATCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7947",
            "district": "2",
            "Block": "114",
            "label": "GOVT HIGH SCHOOL KATTANKOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7949",
            "district": "2",
            "Block": "114",
            "label": "Arignar Anna Municipal Boys Higher Secondary School Chengalpattu",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7950",
            "district": "2",
            "Block": "114",
            "label": "St.Mary's Girls' Hr.Sec.School,Chengalpattu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7951",
            "district": "2",
            "Block": "114",
            "label": "SEVENTH DAY ADVENTIST MHSS, CHENGALPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7952",
            "district": "2",
            "Block": "114",
            "label": "St.Columbas HSS, Chengalpattu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7953",
            "district": "2",
            "Block": "114",
            "label": "MUNICIPAL MIDDLE SCHOOL, VADAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7954",
            "district": "2",
            "Block": "114",
            "label": "CSI Alison Cassie (Aided) HSS, Chengalpattu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7955",
            "district": "2",
            "Block": "114",
            "label": "Ramakrishna (Aided) HSS(B), VNNagar",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7956",
            "district": "2",
            "Block": "114",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, CHENGALPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7957",
            "district": "2",
            "Block": "114",
            "label": "S.R.K.M.V.GIRLS.HR.SEC.SCHOOL,CPT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7958",
            "district": "2",
            "Block": "114",
            "label": "TELC CORNATION AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7959",
            "district": "2",
            "Block": "114",
            "label": "ST.JOSEPH AIDED HSS,CHENGALPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7960",
            "district": "2",
            "Block": "114",
            "label": "CSI ST. HILDAS & ST. HUGHS Chengalpattu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7961",
            "district": "2",
            "Block": "114",
            "label": "Prasan Vidya  Bala Mandir MHSS, Chengalpet-603001",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7962",
            "district": "2",
            "Block": "114",
            "label": "Jeevam Ganoday (Deaf)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7963",
            "district": "2",
            "Block": "114",
            "label": "PUMS SINGAPERUMAL KOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7964",
            "district": "2",
            "Block": "114",
            "label": "PUMS THIRUTHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7965",
            "district": "2",
            "Block": "114",
            "label": "Iseral Mat.HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7966",
            "district": "2",
            "Block": "114",
            "label": "ECIST MS Singaperumalkoil",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7967",
            "district": "2",
            "Block": "114",
            "label": "GHSS SINGAPERUMAL KOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7968",
            "district": "2",
            "Block": "114",
            "label": "VIDHYA SAGAR GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7969",
            "district": "2",
            "Block": "114",
            "label": "GHSS, Otteri Extension",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7970",
            "district": "2",
            "Block": "114",
            "label": "CRESCENT RESIDENTIAL MATRIC HR SEC SCHOOL, VANDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7971",
            "district": "2",
            "Block": "114",
            "label": "WILLYS INTEGRATED HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7972",
            "district": "2",
            "Block": "114",
            "label": "SARASWATHI MHSS, Urapakkam-603210",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7973",
            "district": "2",
            "Block": "322",
            "label": "PUMS-PUDUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7974",
            "district": "2",
            "Block": "322",
            "label": "HAWISCHAR HAPPY HIGH SCHOOL, KEELAVEEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7975",
            "district": "2",
            "Block": "322",
            "label": "CGBVMS-NENMELI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7976",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KEERAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7977",
            "district": "2",
            "Block": "322",
            "label": "GHS SOGANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7978",
            "district": "2",
            "Block": "322",
            "label": "SADHGURU GLOBAL MATRIC HSS, SOGANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7979",
            "district": "2",
            "Block": "322",
            "label": "PP GHSS PON VILAINTHA KALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7980",
            "district": "2",
            "Block": "322",
            "label": "PUMS-PONPATHARKOODAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7981",
            "district": "2",
            "Block": "322",
            "label": "PUMS-ANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7982",
            "district": "2",
            "Block": "322",
            "label": "GOVT HR SEC SCHOOL PANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7983",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KILAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7984",
            "district": "2",
            "Block": "322",
            "label": "GVMS-MUDAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7985",
            "district": "2",
            "Block": "322",
            "label": "GHSS ( BOYS ), THIRUKALUKUNDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7986",
            "district": "2",
            "Block": "322",
            "label": "GHS KUZHIPANTHANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7987",
            "district": "2",
            "Block": "322",
            "label": "PUMS-VADAKADAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7988",
            "district": "2",
            "Block": "322",
            "label": "ANNAI VELLAN KANNI MATRICULATION SCHOOL PERUMALERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7989",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KOKKILAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7990",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KADAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7991",
            "district": "2",
            "Block": "322",
            "label": "PUMS-PATTIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7992",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KUNNAVAKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7993",
            "district": "2",
            "Block": "322",
            "label": "GHS VEERAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7994",
            "district": "2",
            "Block": "322",
            "label": "PUMS-MULLIKOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7995",
            "district": "2",
            "Block": "322",
            "label": "GHS NATHAMKARIYACHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7996",
            "district": "2",
            "Block": "322",
            "label": "ADWHSS MANAMAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7997",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7998",
            "district": "2",
            "Block": "322",
            "label": "ATOMIC ENERGY CENTRAL SCHOOL, ANUPURAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7999",
            "district": "2",
            "Block": "322",
            "label": "GHSS VENGAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8000",
            "district": "2",
            "Block": "322",
            "label": "PUMS-SADRAS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8001",
            "district": "2",
            "Block": "322",
            "label": "GHSS SADHURANGAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8002",
            "district": "2",
            "Block": "322",
            "label": "KENDRIYA VIDYALAYA NO.2, EAST SADRAS, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8003",
            "district": "2",
            "Block": "322",
            "label": "ATOMIC ENERGY CENTRAL SCHOOL -NO. 2 ,EAST SADRAS, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "8004",
            "district": "2",
            "Block": "322",
            "label": "PUMS-VITTILAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8005",
            "district": "2",
            "Block": "322",
            "label": "GHS-SOORADIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8006",
            "district": "2",
            "Block": "322",
            "label": "ADWHSS NERUMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8007",
            "district": "2",
            "Block": "322",
            "label": "PUMS-IRUMBULICHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8008",
            "district": "2",
            "Block": "322",
            "label": "PUMS-BOMMARASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8009",
            "district": "2",
            "Block": "322",
            "label": "PUMS-AYAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8010",
            "district": "2",
            "Block": "322",
            "label": "PUMS-UYYALIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8011",
            "district": "2",
            "Block": "322",
            "label": "GHSS VAYALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8012",
            "district": "2",
            "Block": "322",
            "label": "ADWMS-PS NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8013",
            "district": "2",
            "Block": "322",
            "label": "ANNAMALAI MS-DESUMUGIPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8014",
            "district": "2",
            "Block": "322",
            "label": "PUMS-NALVARPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8015",
            "district": "2",
            "Block": "322",
            "label": "PUMS-AMMANAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8016",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KARUMARAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8017",
            "district": "2",
            "Block": "322",
            "label": "V S MHSS Thirukalukundram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8018",
            "district": "2",
            "Block": "322",
            "label": "Aruna MHSS,Thirukazhukundram-603109",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8019",
            "district": "2",
            "Block": "322",
            "label": "PTVS HS THIRUKAZHUKUNDRAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8020",
            "district": "2",
            "Block": "322",
            "label": "PUMS-THIRUKAZHUKUNDRAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8021",
            "district": "2",
            "Block": "322",
            "label": "GGHSS THIRUKAZHUKUNDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8022",
            "district": "2",
            "Block": "322",
            "label": "VS MATRICULATION SCHOOL MAMALLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8023",
            "district": "2",
            "Block": "322",
            "label": "PUMS-MAMALLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8024",
            "district": "2",
            "Block": "322",
            "label": "GHSS MAMALLAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8025",
            "district": "2",
            "Block": "322",
            "label": "ATOMIC ENERGY CENTRAL SCHOOL -NO.1, PUDUPATTINAM, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "8026",
            "district": "2",
            "Block": "322",
            "label": "GHSS PUDHUPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8027",
            "district": "2",
            "Block": "322",
            "label": "KENDRIYA VIDYALAYA NO.1, PUDUPATTINAM, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8028",
            "district": "2",
            "Block": "300",
            "label": "UNCLE SARGUNAR MHSS ANAKAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8029",
            "district": "2",
            "Block": "300",
            "label": "SHRINE THERESA MATRICULATION SCHOOL, ANAKAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8030",
            "district": "2",
            "Block": "300",
            "label": "GHSS ANAKAPATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8031",
            "district": "2",
            "Block": "300",
            "label": "VELS VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, ANAKAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8032",
            "district": "2",
            "Block": "300",
            "label": "THANTHAI PERIYAR GOVT HR SEC SCHOOL PUZHUDHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8033",
            "district": "2",
            "Block": "300",
            "label": "St. Thomas Mat HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8034",
            "district": "2",
            "Block": "300",
            "label": "PUMS PASUMPON NAGAR PAMMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8035",
            "district": "2",
            "Block": "300",
            "label": "CSI JMA MIDDLE SCHOOL PAMMAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8036",
            "district": "2",
            "Block": "300",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MOONGILERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8037",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARA MATRIC. HR.SEC. SCHOOL, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8038",
            "district": "2",
            "Block": "300",
            "label": "ABTM MIDDLE SCHOOL,NAZARATHPURAM,ST. THOMAS MOUNT",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8039",
            "district": "2",
            "Block": "300",
            "label": "DR M G R CANTONMENT HS SCHOOL ST THOMAS. MOUNT",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8040",
            "district": "2",
            "Block": "300",
            "label": "RCM Aided HS., St. Thomas Mount",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8041",
            "district": "2",
            "Block": "300",
            "label": "CSI MS MOUNT",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8042",
            "district": "2",
            "Block": "300",
            "label": "CSI. ST. THOMAS HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8043",
            "district": "2",
            "Block": "300",
            "label": "ST.HELEN'S GIRLS  HSS, ST.THOMAS MOUNT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8044",
            "district": "2",
            "Block": "300",
            "label": "CIVIL AVIATION MS MEENAMBAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8045",
            "district": "2",
            "Block": "300",
            "label": "C. S. I ST. THOMAS MATRICULATION HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8046",
            "district": "2",
            "Block": "300",
            "label": "MMS Keelkattalai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8047",
            "district": "2",
            "Block": "300",
            "label": "THIRUVALLUVAR MIDDLE SCHOOL, ADAMBAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8048",
            "district": "2",
            "Block": "300",
            "label": "Kendriya Vidyalaya,DGOA,Alandur",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8049",
            "district": "2",
            "Block": "300",
            "label": "VAILANKANNI SILVER JUBILEE MATRIC.HR.SEC.SCHOOL, MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8050",
            "district": "2",
            "Block": "300",
            "label": "Hindu Col.Chellammal vid,Alandur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8051",
            "district": "2",
            "Block": "300",
            "label": "Sri Venkateswara Hindu Colony Middle School, Nanganallur",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8052",
            "district": "2",
            "Block": "300",
            "label": "G(B)HSS,NANGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8053",
            "district": "2",
            "Block": "300",
            "label": "JAIGOPAL GARODIA GGHSS , NANGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8054",
            "district": "2",
            "Block": "300",
            "label": "Prince MHSS Nanganallur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8055",
            "district": "2",
            "Block": "300",
            "label": "MONTFORT HSS ALANDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8056",
            "district": "2",
            "Block": "300",
            "label": "Municipal High School, Alandur",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8057",
            "district": "2",
            "Block": "300",
            "label": "AJS NIDHI HSS, Alandur",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8058",
            "district": "2",
            "Block": "300",
            "label": "ST. BRITTO'S MAT.HR.SEC. SCHOOL, ADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8059",
            "district": "2",
            "Block": "300",
            "label": "ST.THERESA  GIRLS HSS, PALLAVARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8060",
            "district": "2",
            "Block": "300",
            "label": "NATIONAL MS PALLAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8061",
            "district": "2",
            "Block": "300",
            "label": "MMA GHSS, Pallavaram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8062",
            "district": "2",
            "Block": "300",
            "label": "PAM MS PALLAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8063",
            "district": "2",
            "Block": "300",
            "label": "MPL HSS ZAMIN PALLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8064",
            "district": "2",
            "Block": "300",
            "label": "ST.FRANCIS XAVIER NUR & PRI SCHOOL, PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8065",
            "district": "2",
            "Block": "300",
            "label": "SRI SATHYA SAI INSTITUTE OF EDUCARE, CHROMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8066",
            "district": "2",
            "Block": "300",
            "label": "MPL HSS HASTHINAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8067",
            "district": "2",
            "Block": "300",
            "label": "GOVERNMENT (ADW) HIGHER SECONDARY SCHOOL, NAGALKENI, CHROMEPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8068",
            "district": "2",
            "Block": "300",
            "label": "SAVIOUR JESUS MATRICULATION HIGHER SECONDARY SCHOOL,CHROMEPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8069",
            "district": "2",
            "Block": "300",
            "label": "VENKATESWARA MS CHROMEPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8070",
            "district": "2",
            "Block": "300",
            "label": "Neelaram Mat.HSS,Chrompet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8071",
            "district": "2",
            "Block": "300",
            "label": "CES MIDDLE SCHOOL,  CHROMPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8072",
            "district": "2",
            "Block": "300",
            "label": "SRI AIYASWAMY AIYAR HS CHROMPET",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8073",
            "district": "2",
            "Block": "300",
            "label": "GOVT. BOYS HR. SEC. SCHOOL, CHROMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8074",
            "district": "2",
            "Block": "300",
            "label": "MBN GGHSS CHROMEPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8075",
            "district": "2",
            "Block": "300",
            "label": "Srimathi Lash Mem.Mat.HS, Chromepet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8076",
            "district": "2",
            "Block": "300",
            "label": "HOLY ANGELS GIRLS HIGH SCHOOL CHROMEPET",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8077",
            "district": "2",
            "Block": "300",
            "label": "Holy Angels MHSS chrompet,ch-44",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8078",
            "district": "2",
            "Block": "300",
            "label": "ARINGNAR ANNA CANTONMENT HIGH SCHOOL PALLAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8079",
            "district": "2",
            "Block": "300",
            "label": "MPL HSS ZAMIN ROYAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8080",
            "district": "2",
            "Block": "300",
            "label": "C.S.I ST.STEPHEN'S MATRICULATION SCHOOL PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8081",
            "district": "2",
            "Block": "300",
            "label": "Modern Matric School,Chrompet",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8082",
            "district": "2",
            "Block": "300",
            "label": "ST.MARK'S MATRICULATION HIGHER SECONDARY SCHOOL, CHROMEPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8083",
            "district": "2",
            "Block": "300",
            "label": "Green Valley Matric HS, Chrompet",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8084",
            "district": "2",
            "Block": "300",
            "label": "Royal Matric HSS,Hastinapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8085",
            "district": "2",
            "Block": "300",
            "label": "GGN International School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8086",
            "district": "2",
            "Block": "300",
            "label": "Sri Sankara Global Academy, Keelkatalai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8087",
            "district": "2",
            "Block": "300",
            "label": "DHAYA JOTHY MATRIC HR SEC SCHOOL ,OLD PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8088",
            "district": "2",
            "Block": "300",
            "label": "VELS VIDYASHRAM, PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8089",
            "district": "2",
            "Block": "300",
            "label": "ADWMS KADAPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8090",
            "district": "2",
            "Block": "300",
            "label": "Seventh-Day Adventist Matric. High school, Tambaram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8091",
            "district": "2",
            "Block": "300",
            "label": "VALLUVAR GURUKULAM HSS ,TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8092",
            "district": "2",
            "Block": "300",
            "label": "GURUKULAM MAT HSS TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8093",
            "district": "2",
            "Block": "300",
            "label": "Avvai (Aided) MS, Tambaram",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8094",
            "district": "2",
            "Block": "300",
            "label": "THE MADRAS SEVA SADAN (AIDED) HSS, TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8095",
            "district": "2",
            "Block": "300",
            "label": "JAIGOPAL GARODIA NATIONAL HSS, EAST TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8096",
            "district": "2",
            "Block": "300",
            "label": "CHRIST KING MS, TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8097",
            "district": "2",
            "Block": "300",
            "label": "MPB GOVT GIRLS HR SEC SCHOOL EAST TAMBARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8098",
            "district": "2",
            "Block": "300",
            "label": "MUNICIPAL HSS, SELAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8099",
            "district": "2",
            "Block": "300",
            "label": "MMS VINOBA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8100",
            "district": "2",
            "Block": "300",
            "label": "CORLEY HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8101",
            "district": "2",
            "Block": "300",
            "label": "ST. MARYS MHSS, EAST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8102",
            "district": "2",
            "Block": "300",
            "label": "CSI MS TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8103",
            "district": "2",
            "Block": "300",
            "label": "MMS IRUMBULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8104",
            "district": "2",
            "Block": "300",
            "label": "RAJARAM MIDDLE SCHOOL, TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8105",
            "district": "2",
            "Block": "300",
            "label": "Prof ACK Memorial telugu school west tambaram",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8106",
            "district": "2",
            "Block": "300",
            "label": "MCC RSL HSS WEST TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8107",
            "district": "2",
            "Block": "300",
            "label": "Anandjain vidyalaya MHSS tambaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8108",
            "district": "2",
            "Block": "300",
            "label": "MMS Kannadiyarpalayam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8109",
            "district": "2",
            "Block": "300",
            "label": "CHRIST KING GIRLS HR. SEC. SCHOOL EAST TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8110",
            "district": "2",
            "Block": "300",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL, TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8111",
            "district": "2",
            "Block": "300",
            "label": "BABY (AIDED)HIGH SCHOOL TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8112",
            "district": "2",
            "Block": "300",
            "label": "SHREE GUGANS SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8113",
            "district": "2",
            "Block": "300",
            "label": "C S I CORLEY MATRIC SCHOOL,EAST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8114",
            "district": "2",
            "Block": "300",
            "label": "ST JOHN'S MATRIC  SCHOOL,EAST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8115",
            "district": "2",
            "Block": "300",
            "label": "MPL HS OLD TAMBARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8116",
            "district": "2",
            "Block": "300",
            "label": "SHRI VENKATESHWARA HSS, Madipakkam",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8117",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLAPUTHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8118",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THEETTALAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8119",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHITHIRAKOODAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8120",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VEDANTHANGAL.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8121",
            "district": "2",
            "Block": "1",
            "label": "SACRED HEART MATRIC HIGH  SCHOOL, VALAIYAPUTHUR, VEDANTHANGAL.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8122",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUMBAKKAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8123",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, L.ENDATHUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8124",
            "district": "2",
            "Block": "1",
            "label": "ADHI PARASAKTHI  NURSERY AND PRIMARY SCHOOL, L.ENDATHUR.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8125",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KILIYANAGAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8126",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PATHIRI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8127",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, RAMAPURAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8128",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEEZHAMUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8129",
            "district": "2",
            "Block": "1",
            "label": "R.C.HIGHER SECONDARY SCHOOL, THANDALAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8130",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GUDALORE.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8131",
            "district": "2",
            "Block": "1",
            "label": "ALFREDO CUOMO HIGHER SECONDARY SCHOOL, SENDIVAKKAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8132",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTUGUDALUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8133",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, KATTUKARANAI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23678",
            "district": "2",
            "Block": "300",
            "label": "Saratha Mtriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "8134",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MATHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8135",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ELAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8136",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ORATHI.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8137",
            "district": "2",
            "Block": "1",
            "label": "RAINBOW NURSERY AND PRIMARY SCHOOL, ORATHI.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8138",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, VADAMANIPAKKAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8139",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THINNALUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8140",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ANANDHAMANGALAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8141",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONGARAIMAMPATTU.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8142",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KALATHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8143",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEEZHATHIVAKKAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8144",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MINNAL CHITHAMUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8145",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, NEDUNGAL.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8146",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MURUNGAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8147",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELIYAMPAKKAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8148",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  ATHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8149",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THOZHUPEDU.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8150",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADAMALAIPUTHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8151",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUMBERKANDIGAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8152",
            "district": "2",
            "Block": "1",
            "label": "DR. JACOBS HITECH SCHOOL, VILANGADU.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8153",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, THIMMAPURAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8154",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEETHAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8155",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UNAMALAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8156",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MORAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8157",
            "district": "2",
            "Block": "1",
            "label": "MARVAR GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, ACHARAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8158",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, ACHARAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8159",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VENGATESAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8160",
            "district": "2",
            "Block": "162",
            "label": "GHS, SITHANDIMANDAPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8161",
            "district": "2",
            "Block": "162",
            "label": "PUMS MAMANDUR THENPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8162",
            "district": "2",
            "Block": "162",
            "label": "PUMS MAMANDUR VADAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8163",
            "district": "2",
            "Block": "162",
            "label": "PUMS PAZHAMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8164",
            "district": "2",
            "Block": "162",
            "label": "GHS BUKKATHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8165",
            "district": "2",
            "Block": "162",
            "label": "PUMS NELVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8166",
            "district": "2",
            "Block": "162",
            "label": "GOVERNMENT HIGH SCHOOL ONAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8167",
            "district": "2",
            "Block": "162",
            "label": "PUMS PUZHUTHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8168",
            "district": "2",
            "Block": "162",
            "label": "GHSS , KUNNANKULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8169",
            "district": "2",
            "Block": "162",
            "label": "PUMS VAIYAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8170",
            "district": "2",
            "Block": "162",
            "label": "PUMS PAZHAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8171",
            "district": "2",
            "Block": "162",
            "label": "PUMS PADALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8172",
            "district": "2",
            "Block": "162",
            "label": "MCSM GOVERNMENT HIGHER SECONDARY SCHOOL PADALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8173",
            "district": "2",
            "Block": "162",
            "label": "PUMS LNPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8174",
            "district": "2",
            "Block": "162",
            "label": "PUMS ARAYAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8175",
            "district": "2",
            "Block": "162",
            "label": "ADWMS SANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8176",
            "district": "2",
            "Block": "162",
            "label": "GHS VALLUVAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8177",
            "district": "2",
            "Block": "162",
            "label": "PUMS VEDAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8178",
            "district": "2",
            "Block": "162",
            "label": "GHS PAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8179",
            "district": "2",
            "Block": "162",
            "label": "ADWMS DEVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8180",
            "district": "2",
            "Block": "162",
            "label": "PUMS SILAVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8181",
            "district": "2",
            "Block": "162",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8182",
            "district": "2",
            "Block": "162",
            "label": "PUMS KINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8183",
            "district": "2",
            "Block": "162",
            "label": "ST JOSEPH AIDED HS,KK PUDHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8184",
            "district": "2",
            "Block": "162",
            "label": "GHS BOODUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8185",
            "district": "2",
            "Block": "162",
            "label": "PUMS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8186",
            "district": "2",
            "Block": "162",
            "label": "GHSS VEERANAKUNNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8187",
            "district": "2",
            "Block": "162",
            "label": "PUMS MUNNITHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8188",
            "district": "2",
            "Block": "162",
            "label": "PUMS MUDUKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8189",
            "district": "2",
            "Block": "162",
            "label": "VKM (Aided) HS, Vilvarayanallur",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8190",
            "district": "2",
            "Block": "162",
            "label": "PUMS ARUNGUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8191",
            "district": "2",
            "Block": "162",
            "label": "PUMS KAVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8192",
            "district": "2",
            "Block": "162",
            "label": "PUMS ATHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8193",
            "district": "2",
            "Block": "162",
            "label": "GOVERNMENT HIGH SCHOOL ZAMIN ENDATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8194",
            "district": "2",
            "Block": "162",
            "label": "VENUGOPALASAMY TRUST NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8195",
            "district": "2",
            "Block": "162",
            "label": "MUTHUMALLA MS SIRUNALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8196",
            "district": "2",
            "Block": "162",
            "label": "PUMS ZAMEENPERUVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8197",
            "district": "2",
            "Block": "162",
            "label": "PUMS MARUVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8198",
            "district": "2",
            "Block": "162",
            "label": "PUMS PERIYAVENMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8199",
            "district": "2",
            "Block": "162",
            "label": "PUMS KATTUDEVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8200",
            "district": "2",
            "Block": "162",
            "label": "PUMS NALLAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8201",
            "district": "2",
            "Block": "162",
            "label": "DR R ARULAPPA HIGHER SECONDARY SCHOOL NEERPAIR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8202",
            "district": "2",
            "Block": "162",
            "label": "PUMS JAMINBUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8203",
            "district": "2",
            "Block": "162",
            "label": "GHSS( ADW ) IRUMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8204",
            "district": "2",
            "Block": "162",
            "label": "PTNVKS MS MADURANTAKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8205",
            "district": "2",
            "Block": "162",
            "label": "GGHSS MADURANTHAKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8206",
            "district": "2",
            "Block": "162",
            "label": "SRI AHOBILA MUTT ORIENTAL HIGH SCHOOL MADURANTHAKAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8207",
            "district": "2",
            "Block": "162",
            "label": "HINDU CORNATION MS MADURANTAGAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8208",
            "district": "2",
            "Block": "162",
            "label": "HINDU HR.SEC SCHOOL,MADURANTHAGAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8209",
            "district": "2",
            "Block": "162",
            "label": "ST JOSEPH MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8210",
            "district": "2",
            "Block": "162",
            "label": "CSI TOWN GIRLS MS MADURANTAKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8211",
            "district": "2",
            "Block": "162",
            "label": "CSI HS MADURANTAGAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8212",
            "district": "2",
            "Block": "162",
            "label": "MMS MOCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8213",
            "district": "2",
            "Block": "162",
            "label": "RC AIDED HIGH SCHOOL, MADURANTHAGAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8214",
            "district": "2",
            "Block": "162",
            "label": "GURUKULAM GIRLS HS MADURANTHAGAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8215",
            "district": "2",
            "Block": "162",
            "label": "GURUKULAM BOYS HS MADURANTHAGAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8216",
            "district": "2",
            "Block": "162",
            "label": "vivegananda vidyalaya mat hss",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8217",
            "district": "2",
            "Block": "162",
            "label": "GHSS KARUNKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8218",
            "district": "2",
            "Block": "162",
            "label": "PUMS KARUNKUZHI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8219",
            "district": "2",
            "Block": "162",
            "label": "CSI MS KARUNGUZHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8220",
            "district": "2",
            "Block": "162",
            "label": "NEW RIVER MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8221",
            "district": "2",
            "Block": "153",
            "label": "GIRL GHSS CHEYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8222",
            "district": "2",
            "Block": "153",
            "label": "ST MARYS AIDED  HS,CHEYYUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8223",
            "district": "2",
            "Block": "153",
            "label": "GBHSS CHEYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8224",
            "district": "2",
            "Block": "153",
            "label": "LITTLE FLOWER (G) HSS  CHEYYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8225",
            "district": "2",
            "Block": "153",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL CHEYYUR-603302",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8226",
            "district": "2",
            "Block": "153",
            "label": "ST.MARY'S MATRICULATION SCHOOL, CHEYYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8227",
            "district": "2",
            "Block": "153",
            "label": "SAINT EXUPERY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8228",
            "district": "2",
            "Block": "153",
            "label": "RCMS SENGATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8229",
            "district": "2",
            "Block": "153",
            "label": "PUMS KADUGAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8230",
            "district": "2",
            "Block": "153",
            "label": "PUMS THENPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8231",
            "district": "2",
            "Block": "153",
            "label": "GHSS, THIRUVATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8232",
            "district": "2",
            "Block": "153",
            "label": "PUMS PERIYAVELIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8233",
            "district": "2",
            "Block": "153",
            "label": "St. Joseph HSS, Pavinjur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8234",
            "district": "2",
            "Block": "153",
            "label": "PUMS SEEVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8235",
            "district": "2",
            "Block": "153",
            "label": "PUMS SEEKINANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8236",
            "district": "2",
            "Block": "153",
            "label": "Marg Nava Jothi Seekinankuppam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8237",
            "district": "2",
            "Block": "153",
            "label": "GHS VADAKKUVAYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8238",
            "district": "2",
            "Block": "153",
            "label": "PUMS LATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8239",
            "district": "2",
            "Block": "153",
            "label": "PUMS KALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8240",
            "district": "2",
            "Block": "153",
            "label": "PUMS THONDAMANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8241",
            "district": "2",
            "Block": "153",
            "label": "PUMS THATTAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8242",
            "district": "2",
            "Block": "153",
            "label": "PUMS PARAMESWARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8243",
            "district": "2",
            "Block": "153",
            "label": "GHS PARAMESWARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8244",
            "district": "2",
            "Block": "153",
            "label": "St. Thomas Matric Hr.Sec. SCHOOL , Nerkunapattu.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8245",
            "district": "2",
            "Block": "153",
            "label": "GHSS  KUVATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8246",
            "district": "2",
            "Block": "153",
            "label": "PUMS KADALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8247",
            "district": "2",
            "Block": "153",
            "label": "SWAMI DAYANANDA ROTARY MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8248",
            "district": "2",
            "Block": "153",
            "label": "PUMS NEDUMARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8249",
            "district": "2",
            "Block": "153",
            "label": "PUMS AKKINAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8250",
            "district": "2",
            "Block": "153",
            "label": "GHSS, ANAICUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8251",
            "district": "2",
            "Block": "153",
            "label": "GHS  KODUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8252",
            "district": "2",
            "Block": "153",
            "label": "PUMS MADAIYAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8253",
            "district": "2",
            "Block": "153",
            "label": "RC(AIDED)MS POONCHERY",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8254",
            "district": "2",
            "Block": "153",
            "label": "SACRED HEART HS PARAMANKENIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8255",
            "district": "2",
            "Block": "153",
            "label": "PUMS MUGAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8256",
            "district": "2",
            "Block": "51",
            "label": "ADHIPARASAKTHI MHSS-MELMARUVATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8257",
            "district": "2",
            "Block": "51",
            "label": "G B PUBLIC SCHOOL - MELMARUVATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8258",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL, SOTHUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8259",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SOTHUPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8260",
            "district": "2",
            "Block": "51",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL ,PORAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8261",
            "district": "2",
            "Block": "51",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL, KILMARUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8262",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL - IRUMBULI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8263",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - POLAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8264",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- POLAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8265",
            "district": "2",
            "Block": "51",
            "label": "BHARATHI MATRICULATION HR SEC SCHOOL SARAVAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8266",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8267",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - PERUKKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8268",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - PARUKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8269",
            "district": "2",
            "Block": "51",
            "label": "GOVT HIGH SCHOOL, INDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8270",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - ARKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8271",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - 23 A KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8272",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - PULIYANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8273",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - NERKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8274",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL- SIRUMAILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8275",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HR SEC SCHOOL, KAYAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8276",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - NUGUMBAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8277",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL- PONTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8278",
            "district": "2",
            "Block": "51",
            "label": "SARVASEVA HS PERIYAKALAKADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8279",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL - PUTHIRANKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8280",
            "district": "2",
            "Block": "51",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL- ESUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8281",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8282",
            "district": "2",
            "Block": "51",
            "label": "GOVT HIGH SCHOOL -  ANDARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8283",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- VANNIANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8284",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- PUTHUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8285",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- CHUNAMBEDU - (M)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8286",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- VILLIPAKKAM(M)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8287",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- CHUNAMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8288",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - VEDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8289",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL - KADUKALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8290",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- NAINARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8291",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL - MUDHALIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8292",
            "district": "2",
            "Block": "51",
            "label": "SRI P K GOVT BOYS HR SEC SCHOOL-KADAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8293",
            "district": "2",
            "Block": "51",
            "label": "ST. MARY MATRIC HS SCHOOL, KADAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8294",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - KADAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8295",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- ALAMBARAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8296",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VENNANGUPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8297",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8298",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL SEMPULIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8299",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - GENGADEVANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8300",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- PANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8301",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- VILAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8302",
            "district": "2",
            "Block": "299",
            "label": "ADWHSS, Paduvanchery",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8303",
            "district": "2",
            "Block": "299",
            "label": "PUMS VENGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8304",
            "district": "2",
            "Block": "299",
            "label": "GHS COWLBAZAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8305",
            "district": "2",
            "Block": "299",
            "label": "PUMS Mootaikkaranchavadi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8306",
            "district": "2",
            "Block": "299",
            "label": "ST.JOSEPHS HR.SEC.SCHOOL,VETTUVANKENI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8307",
            "district": "2",
            "Block": "299",
            "label": "ALM MAT HSS INJAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8308",
            "district": "2",
            "Block": "299",
            "label": "ADVENT CHRISTIAN HIGHSCHOOL  INJAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8309",
            "district": "2",
            "Block": "299",
            "label": "Pon Vidyashram (CBSE),Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8310",
            "district": "2",
            "Block": "299",
            "label": "PRIM ROSE School,Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8311",
            "district": "2",
            "Block": "299",
            "label": "PUMS JALLADIANPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8312",
            "district": "2",
            "Block": "299",
            "label": "Baynes MBC MHSS, Jaladianpet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8313",
            "district": "2",
            "Block": "299",
            "label": "GHS, KARAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8314",
            "district": "2",
            "Block": "299",
            "label": "Ramana Vidyalaya, Sholinganallur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8315",
            "district": "2",
            "Block": "299",
            "label": "GHSS,KOVILAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8316",
            "district": "2",
            "Block": "299",
            "label": "Himayam Matric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8317",
            "district": "2",
            "Block": "299",
            "label": "ST..MARY'S  MATRIC  HR. SEC. SCHOOL KOTTIVAKKAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8318",
            "district": "2",
            "Block": "299",
            "label": "YMCA (Aided) HSS, Kottivakkam",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8319",
            "district": "2",
            "Block": "299",
            "label": "NELLAI NADAR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8320",
            "district": "2",
            "Block": "299",
            "label": "PUMS MADIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8321",
            "district": "2",
            "Block": "299",
            "label": "HOLY PRINCE MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8322",
            "district": "2",
            "Block": "299",
            "label": "Prince MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8323",
            "district": "2",
            "Block": "299",
            "label": "SAI RAM VIDYALAYA SENIOR SECONDARY SCHOOL(CBSE), MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8324",
            "district": "2",
            "Block": "299",
            "label": "Sai MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8325",
            "district": "2",
            "Block": "299",
            "label": "pole star matric hr sec school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8326",
            "district": "2",
            "Block": "299",
            "label": "GHS NANMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8327",
            "district": "2",
            "Block": "299",
            "label": "VIDHYA MATRIC.HR.SEC.SCHOOL,JALLADIANPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8328",
            "district": "2",
            "Block": "299",
            "label": "BORN SCHOLARS  MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8329",
            "district": "2",
            "Block": "299",
            "label": "GHSS Medavakkam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8330",
            "district": "2",
            "Block": "299",
            "label": "St.Joseph MHSS,Medavakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8331",
            "district": "2",
            "Block": "299",
            "label": "GHSS, MOOVARASAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8332",
            "district": "2",
            "Block": "299",
            "label": "PUMS, ANNAI INDRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8333",
            "district": "2",
            "Block": "299",
            "label": "PUMS NEELANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8334",
            "district": "2",
            "Block": "299",
            "label": "BROTHER HOOD MAT HSS NEELANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8335",
            "district": "2",
            "Block": "299",
            "label": "Sunbeam Matriculation Hr.sec.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8336",
            "district": "2",
            "Block": "299",
            "label": "IID ELIM MAT SCHOOL  METUKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8337",
            "district": "2",
            "Block": "299",
            "label": "VIJAY NURSERY & PRIMARY SCHOOL, METTUKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8338",
            "district": "2",
            "Block": "299",
            "label": "GHSS, OKKIYAM THURAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8339",
            "district": "2",
            "Block": "299",
            "label": "CAUVERY MATRICULATION HIGHER SECONDARY SCHOOL,THURAIPAKKAM, CHENNAI - 97",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8340",
            "district": "2",
            "Block": "299",
            "label": "AKSHARA MAT  HSS OKKIAM THURAIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8341",
            "district": "2",
            "Block": "299",
            "label": "LITTLE ANGELS MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8342",
            "district": "2",
            "Block": "299",
            "label": "GHSS, KANNAGI NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8343",
            "district": "2",
            "Block": "299",
            "label": "HOPE FOUNDATION MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8344",
            "district": "2",
            "Block": "299",
            "label": "PUMS OTTIYAMPAKKAAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8345",
            "district": "2",
            "Block": "299",
            "label": "ADW High School, Palavakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8346",
            "district": "2",
            "Block": "299",
            "label": "DR.AL MUDALIYAR MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8347",
            "district": "2",
            "Block": "299",
            "label": "PUMS  Pozhichalur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8348",
            "district": "2",
            "Block": "299",
            "label": "Sri Sarada Eashwar Vidya Mandir MHSS ,Pozhichalur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8349",
            "district": "2",
            "Block": "299",
            "label": "PUMS, NOOKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8350",
            "district": "2",
            "Block": "299",
            "label": "PUMS Semmancherry",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8351",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,SEMMANCHERRY(SCB)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8352",
            "district": "2",
            "Block": "299",
            "label": "ST PAUL'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8353",
            "district": "2",
            "Block": "299",
            "label": "Alpha Nursery & Primary School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8354",
            "district": "2",
            "Block": "299",
            "label": "MAYFIELD RESIDENTIAL SCHOOL SITHALAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8355",
            "district": "2",
            "Block": "299",
            "label": "GOVT HIGH SCHOOL SITHALAPAKKAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8356",
            "district": "2",
            "Block": "299",
            "label": "PUMS THIRUSULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8357",
            "district": "2",
            "Block": "299",
            "label": "K.V No:1,AFS, Madambakkam",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8358",
            "district": "2",
            "Block": "299",
            "label": "KENDRIYA VIDYALAYA No:2,AFS ,Selaiyur",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8359",
            "district": "2",
            "Block": "299",
            "label": "GHSS, THIRUVANCHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8360",
            "district": "2",
            "Block": "299",
            "label": "GHS UTHANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8361",
            "district": "2",
            "Block": "299",
            "label": "SHRI SHAKTHI VIDYALAYA NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8362",
            "district": "2",
            "Block": "299",
            "label": "Sudananda Vidyalaya,Uthandi",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8363",
            "district": "2",
            "Block": "299",
            "label": "PUMS Santhoshapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8364",
            "district": "2",
            "Block": "299",
            "label": "St.Paul's Matriculation School, Vijayanagaram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8365",
            "district": "2",
            "Block": "299",
            "label": "ABHIJAY NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8366",
            "district": "2",
            "Block": "299",
            "label": "SRI SUBA BALA BHAVAN NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8367",
            "district": "2",
            "Block": "299",
            "label": "GHS, CHITLAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8368",
            "district": "2",
            "Block": "299",
            "label": "JANS MATRICULATION HIGHER SECONDARY SCHOOL CHITLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8369",
            "district": "2",
            "Block": "299",
            "label": "OLIVE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8370",
            "district": "2",
            "Block": "299",
            "label": "GOVT. ADW HR.SEC.SCHOOL, MEENAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8371",
            "district": "2",
            "Block": "299",
            "label": "Infant Jesus Matriculation High School, Pazhavanthangal",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8372",
            "district": "2",
            "Block": "299",
            "label": "GHSS MADAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8373",
            "district": "2",
            "Block": "299",
            "label": "IDPL Matric HSS,Nandambakkam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8374",
            "district": "2",
            "Block": "299",
            "label": "JACINTH MS, Narayanapuram, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8375",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, PALLIKARANAI, CHENNAI 600100",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8376",
            "district": "2",
            "Block": "299",
            "label": "PUMS PUDUKUDIYIRUPPU PALLIKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8377",
            "district": "2",
            "Block": "299",
            "label": "SHREE VIDYA MANDIR MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8378",
            "district": "2",
            "Block": "299",
            "label": "Kalaimagal Kalasalai Middle School, Peerkankaranai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8379",
            "district": "2",
            "Block": "299",
            "label": "GHSS PEERKANKARANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8380",
            "district": "2",
            "Block": "299",
            "label": "St.Annes Matric HSS, Perungalathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8381",
            "district": "2",
            "Block": "299",
            "label": "GHSS, old Perungalathur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8382",
            "district": "2",
            "Block": "299",
            "label": "Abacus Montessori School, Perungudi, Chennai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8383",
            "district": "2",
            "Block": "299",
            "label": "SANGFORD N&P SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8384",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERUNGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8385",
            "district": "2",
            "Block": "299",
            "label": "SHANTHINIKETAN MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8386",
            "district": "2",
            "Block": "299",
            "label": "Advent Christian Middle School, Sembakkam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8387",
            "district": "2",
            "Block": "299",
            "label": "ST. MARYS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8388",
            "district": "2",
            "Block": "299",
            "label": "ALPHA MATRICULATION HR. SEC. SCHOOL, SEMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8389",
            "district": "2",
            "Block": "299",
            "label": "Boaz Public School,Sembakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8390",
            "district": "2",
            "Block": "299",
            "label": "M.A.V. VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8391",
            "district": "2",
            "Block": "299",
            "label": "GHSS SHOLINGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8392",
            "district": "2",
            "Block": "299",
            "label": "PUMS SHOLINGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8393",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT HIGH SCHOOL,KUDUMIYANDITHOPPU, CHENNAI 119",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8394",
            "district": "2",
            "Block": "299",
            "label": "JMKS GHS LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8395",
            "district": "2",
            "Block": "299",
            "label": "PUMS THIRUNEERMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8396",
            "district": "2",
            "Block": "299",
            "label": "Sri Vidhyaalayaa Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8397",
            "district": "2",
            "Block": "299",
            "label": "SRIMATHI SUNDARAVALLI MEMORIAL SCHOOL CHROMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20878",
            "district": "2",
            "Block": "162",
            "label": "SUBHAM VIDHYALAYAA SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20880",
            "district": "2",
            "Block": "322",
            "label": "SREE GOKULAM PUBLIC SCHOOL, NENMELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20893",
            "district": "2",
            "Block": "114",
            "label": "St Pauls Academy, Chengalpattu",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20899",
            "district": "2",
            "Block": "114",
            "label": "Guardian Int. School, Melamaiyur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20900",
            "district": "2",
            "Block": "322",
            "label": "SRS VIDYA MANDIR , VADAKADAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20923",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT  SCHOOL FOR THE HEARING IMPAIRED,,TAMBARAM SANATORIUM,CHENNAI 600047",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20929",
            "district": "2",
            "Block": "322",
            "label": "V S STARS PUBLIC SCHOOL THIRUKAZHUKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20942",
            "district": "2",
            "Block": "114",
            "label": "Srimathi Sundaravalli Memorial School, Kolapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20943",
            "district": "2",
            "Block": "114",
            "label": "Fatima Matric School, Chettipunniyam-603204",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20944",
            "district": "2",
            "Block": "322",
            "label": "JEEVA MEMORIAL PUBLIC  CBSE SENIOR  SECONDARY SCHOOL , PALLAEGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20946",
            "district": "2",
            "Block": "299",
            "label": "BVM Global School, Seevaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21002",
            "district": "2",
            "Block": "299",
            "label": "Annie matric. Hr. Sec.school,Mudichur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21008",
            "district": "2",
            "Block": "162",
            "label": "VM Vidya Kendra Mat.School, Madurantakam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21050",
            "district": "2",
            "Block": "114",
            "label": "SCAD World School, Pazhaveli",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21051",
            "district": "2",
            "Block": "322",
            "label": "BLESSINGS INTERNATIONAL SCHOOL , KEERAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21052",
            "district": "2",
            "Block": "300",
            "label": "RAVINDRA BHARATHI  GLOBAL KEELKATTALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21053",
            "district": "2",
            "Block": "162",
            "label": "Prasan Vidya Mandir, Vadapathi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21054",
            "district": "2",
            "Block": "51",
            "label": "VEDHA VIDHYASHRAM CBSE SCHOOL-MANAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21055",
            "district": "2",
            "Block": "299",
            "label": "NSN Memorial Sr.Sec. school(CBSE) , Chitlapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21127",
            "district": "2",
            "Block": "299",
            "label": "Dr.C.L.Metha Sishya OMR School, Thoraipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21136",
            "district": "2",
            "Block": "300",
            "label": "Sunshine Chennai Senior SS, Puzhuthivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21142",
            "district": "2",
            "Block": "300",
            "label": "KENDRIYA VIDHYALAYA MEENAMBAKKAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21144",
            "district": "2",
            "Block": "300",
            "label": "ST. DOMINICS ANGLO - INDIAN HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21176",
            "district": "2",
            "Block": "299",
            "label": "GT Aloha Vidhya Mandir",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21177",
            "district": "2",
            "Block": "299",
            "label": "Sreevatsa Viswanathan Vivekananda Vidyalaya Senior Secondary School, Chitlapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21234",
            "district": "2",
            "Block": "299",
            "label": "Sri Kanchi Mahaswami Vidya Mandir, Sembakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21237",
            "district": "2",
            "Block": "114",
            "label": "GKM VIDYASHRAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21238",
            "district": "2",
            "Block": "322",
            "label": "PATHASHAALA PALAR CENTRE FOR LEARNING KFI, VALLIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21256",
            "district": "2",
            "Block": "299",
            "label": "Mount Carmel Mat. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21299",
            "district": "2",
            "Block": "299",
            "label": "THE PSBB MILLENNIUM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21427",
            "district": "2",
            "Block": "329",
            "label": "GHS(G), Kelambakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21428",
            "district": "2",
            "Block": "322",
            "label": "GHS SALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21429",
            "district": "2",
            "Block": "153",
            "label": "GHS ,Neelamangalam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21430",
            "district": "2",
            "Block": "299",
            "label": "GHS, Perumbakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21492",
            "district": "2",
            "Block": "329",
            "label": "ADWHS, Pudupakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21581",
            "district": "2",
            "Block": "329",
            "label": "Velammal Vidhyashram, Mambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21582",
            "district": "2",
            "Block": "322",
            "label": "Raniammal Matric School, Narasankuppam 603109",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21584",
            "district": "2",
            "Block": "299",
            "label": "Srujana Montessori N & PS, Kovilambakkam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21585",
            "district": "2",
            "Block": "299",
            "label": "Gate Way International School, Neelankarai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21586",
            "district": "2",
            "Block": "299",
            "label": "The lords international school, perungalathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21718",
            "district": "2",
            "Block": "299",
            "label": "VAELS International school,INJAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21719",
            "district": "2",
            "Block": "299",
            "label": "St.John's public School,Jaladianpet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21720",
            "district": "2",
            "Block": "299",
            "label": "Narayana Group of Schools,Neelankarai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21721",
            "district": "2",
            "Block": "299",
            "label": "St.John's International school,Neelangarai",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21722",
            "district": "2",
            "Block": "299",
            "label": "Sri Shankara NPS, Neelangarai",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21723",
            "district": "2",
            "Block": "299",
            "label": "Bharathi Vidyalaya  Senior Secondary School, Perumbakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21724",
            "district": "2",
            "Block": "299",
            "label": "BVM Global School,Perumbakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21725",
            "district": "2",
            "Block": "299",
            "label": "Army Public School,Nandambakkam",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21726",
            "district": "2",
            "Block": "299",
            "label": "Sri Sankara Balavidyalaya,Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21727",
            "district": "2",
            "Block": "299",
            "label": "SAN Academy,Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21728",
            "district": "2",
            "Block": "300",
            "label": "Narayana E-techno schools",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21729",
            "district": "2",
            "Block": "114",
            "label": "Lightworkers Academy",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21730",
            "district": "2",
            "Block": "114",
            "label": "ST.MICHAEL'S NURSERY & PRIMARY SCHHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21731",
            "district": "2",
            "Block": "114",
            "label": "Sivananda Rajaram Senior Secondary School Maraimaai Nagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21732",
            "district": "2",
            "Block": "329",
            "label": "VELS VIDYASHRAM SENIOR SECONDARY SCHOOL, THALAMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21885",
            "district": "2",
            "Block": "322",
            "label": "SHINING STAR MATRICULATION SCHOOL, VASUVASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21886",
            "district": "2",
            "Block": "322",
            "label": "Excellent NPS, Pudhupattinam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21897",
            "district": "2",
            "Block": "300",
            "label": "Agurchand Manmull Jain School, Meenambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21898",
            "district": "2",
            "Block": "300",
            "label": "Apex Ponvidyashram School,  Puzhuthivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21899",
            "district": "2",
            "Block": "300",
            "label": "New Prince Shri Bhavani Vidyashram, Ullagaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21900",
            "district": "2",
            "Block": "300",
            "label": "PRINCE SRIVARI SENIOR SECONDARY SCHOOL , MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21948",
            "district": "2",
            "Block": "322",
            "label": "ATOMIC ENERGY HIGHER SECONDARY SCHOOL, EAST SADRAS, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22030",
            "district": "2",
            "Block": "329",
            "label": "Velammal New Gen School, Kelambakkam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22031",
            "district": "2",
            "Block": "114",
            "label": "Velammal Vidyashram, Perumattunallur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22032",
            "district": "2",
            "Block": "114",
            "label": "Maharishi Vidya Mandir, Echankaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22033",
            "district": "2",
            "Block": "114",
            "label": "JRK Global School, Kattankolathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22034",
            "district": "2",
            "Block": "114",
            "label": "Velammal Vidhyashram (CBSE), Kalivanthapattu",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22035",
            "district": "2",
            "Block": "300",
            "label": "GOVT ADW HIGH SCHOOL, ANAKAPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22036",
            "district": "2",
            "Block": "300",
            "label": "Mahalakshmi Vidya Mandir School, Pammal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22037",
            "district": "2",
            "Block": "300",
            "label": "Knowledge Academy School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22038",
            "district": "2",
            "Block": "300",
            "label": "Hindustan International School, Mount",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22039",
            "district": "2",
            "Block": "300",
            "label": "PRINCE SRIVARI SENIOR SECONDARY SCHOOL NANGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22040",
            "district": "2",
            "Block": "300",
            "label": "Sri Chaitanya Techno School, Chrompet",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22041",
            "district": "2",
            "Block": "153",
            "label": "Layola Matric School, Cheyyur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22042",
            "district": "2",
            "Block": "51",
            "label": "ASSEFA VIDHYALAYA MATRIC. HR. SEC.SCHOOL- POORIYAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22043",
            "district": "2",
            "Block": "299",
            "label": "Akshar Arbol International School Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22044",
            "district": "2",
            "Block": "299",
            "label": "HINDUSTAN INTERNATIONAL SCHOOL KARAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22045",
            "district": "2",
            "Block": "299",
            "label": "SHRADDHA Children Academy, Kottivakkam Kuppam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22046",
            "district": "2",
            "Block": "299",
            "label": "AL QAMAR  ACADEMY, Kottivakkam Kuppam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22047",
            "district": "2",
            "Block": "299",
            "label": "RAMACHANDRAA PUBLIC SCHOOL, KOTTIVAKKAM KUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22048",
            "district": "2",
            "Block": "299",
            "label": "Ravindra Bharathi Global School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22049",
            "district": "2",
            "Block": "299",
            "label": "Velammal New Gen School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22050",
            "district": "2",
            "Block": "299",
            "label": "APL Global School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22051",
            "district": "2",
            "Block": "299",
            "label": "PUMS, Ezhil Nagar",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22052",
            "district": "2",
            "Block": "299",
            "label": "St. Johns Universal School, Palavakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22053",
            "district": "2",
            "Block": "299",
            "label": "Seed Academy School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22054",
            "district": "2",
            "Block": "299",
            "label": "BVM International School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22055",
            "district": "2",
            "Block": "299",
            "label": "SKB  VIDHYASHRAM School, Perungalathur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22056",
            "district": "2",
            "Block": "299",
            "label": "Trileaves International School, Sembakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22057",
            "district": "2",
            "Block": "299",
            "label": "Trileaves Global School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22058",
            "district": "2",
            "Block": "299",
            "label": "Babaji Vidhyashram, Sholinganallur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22350",
            "district": "2",
            "Block": "329",
            "label": "GGHS, Manampathy",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22351",
            "district": "2",
            "Block": "329",
            "label": "HLC International School, Karanai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22352",
            "district": "2",
            "Block": "329",
            "label": "GHS, Siruthavoor",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22353",
            "district": "2",
            "Block": "114",
            "label": "Sri Krish International School, Rathinamangalam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22354",
            "district": "2",
            "Block": "114",
            "label": "Christwood High School, Venkadamangalam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22355",
            "district": "2",
            "Block": "114",
            "label": "SRM Public School, Nandhivaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22356",
            "district": "2",
            "Block": "114",
            "label": "Loknayak Jayaprakash Narayan Vidyalaya, Guduvancheri",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22357",
            "district": "2",
            "Block": "322",
            "label": "ST MARY'S ACADEMY , MAMALLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22358",
            "district": "2",
            "Block": "300",
            "label": "Noble Academy",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22359",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARA GLOBAL ACADEMY, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22360",
            "district": "2",
            "Block": "300",
            "label": "UNITY MATRIC SCHOOL, ADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22361",
            "district": "2",
            "Block": "300",
            "label": "Shikshaa Public School, Hasthinapuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22362",
            "district": "2",
            "Block": "300",
            "label": "Arul Jothi Public CBSE School, Old Pallavaram",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22363",
            "district": "2",
            "Block": "162",
            "label": "Sai Shakthi Vidhya Mandhir School, Meyyur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22364",
            "district": "2",
            "Block": "162",
            "label": "GHS 150 ESSUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22365",
            "district": "2",
            "Block": "299",
            "label": "AKG Public School, Jalladianpet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22366",
            "district": "2",
            "Block": "299",
            "label": "Sri Chaitanya Techno Scool, Kovilamabakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22367",
            "district": "2",
            "Block": "299",
            "label": "Sri Chaitanya Techno School, Thuraipakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22368",
            "district": "2",
            "Block": "299",
            "label": "Al - Fajr International School, Kottivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22369",
            "district": "2",
            "Block": "299",
            "label": "HORIZON INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22370",
            "district": "2",
            "Block": "299",
            "label": "Ravindra Bharathi Global School, Mudichur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22371",
            "district": "2",
            "Block": "299",
            "label": "Sheshavalli Public School, Mudichur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22372",
            "district": "2",
            "Block": "299",
            "label": "SRI CHAITHANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22373",
            "district": "2",
            "Block": "299",
            "label": "NETHAJI VIDHYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22374",
            "district": "2",
            "Block": "299",
            "label": "NPS International School, Perumbakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22375",
            "district": "2",
            "Block": "299",
            "label": "SHREE BHARATH VIDYAASHRAM , THIRUVANCHERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22376",
            "district": "2",
            "Block": "299",
            "label": "Grace International School, Chitlapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22377",
            "district": "2",
            "Block": "299",
            "label": "Velammal Vidyalaya School, Sholinganallur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22708",
            "district": "2",
            "Block": "329",
            "label": "POLICE PUBLIC SCHOOL, MELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22709",
            "district": "2",
            "Block": "114",
            "label": "ZION INTERNATIONAL PUBLIC SCHOOL, NEDUNGUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22710",
            "district": "2",
            "Block": "329",
            "label": "BILLABONG HIGH INTERNATIONAL SCHOOL, THAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22711",
            "district": "2",
            "Block": "329",
            "label": "HINDUSTAN INTERNATIONAL SCHOOL PADUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22712",
            "district": "2",
            "Block": "329",
            "label": "SBOA HIGH SCHOOL POLACHERRY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22713",
            "district": "2",
            "Block": "329",
            "label": "MOUNT LITERAZEE SCHOOL NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22714",
            "district": "2",
            "Block": "299",
            "label": "JAGANNATH VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22715",
            "district": "2",
            "Block": "299",
            "label": "NARAYANA E TECHNO SCHOOL SHOLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22777",
            "district": "2",
            "Block": "300",
            "label": "ST. ANTONY'S R.C. HIGH SCHOOL, SENGALANEERMALAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22778",
            "district": "2",
            "Block": "300",
            "label": "Baalyaa School (NIOS) Keelkattalai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22798",
            "district": "2",
            "Block": "300",
            "label": "NEW PRINCE SHRI BHAVANI VIDYASHRAM, ADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22827",
            "district": "2",
            "Block": "299",
            "label": "Marg Vidhyalaya CBSE school Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22828",
            "district": "2",
            "Block": "299",
            "label": "SAN Academy, Kamakoti Nagar,Pallikaranai.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22846",
            "district": "2",
            "Block": "300",
            "label": "ST.THOMAS ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22894",
            "district": "2",
            "Block": "329",
            "label": "Athena Global School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22895",
            "district": "2",
            "Block": "329",
            "label": "Government Eklavya Model Residential School - Pattipulam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22896",
            "district": "2",
            "Block": "322",
            "label": "GHS VALLIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22897",
            "district": "2",
            "Block": "162",
            "label": "GHS Thottanaval",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22947",
            "district": "2",
            "Block": "329",
            "label": "HIRANANDANI UPSCALE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22992",
            "district": "2",
            "Block": "299",
            "label": "Alpha International School , Sembakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23014",
            "district": "2",
            "Block": "162",
            "label": "RC High School Thatchur",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "23017",
            "district": "2",
            "Block": "299",
            "label": "Sharanalaya Montessori ,Akkarai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23020",
            "district": "2",
            "Block": "299",
            "label": "JS Global School Sholinganallur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23024",
            "district": "2",
            "Block": "329",
            "label": "LITTLE FLOCK NURSERY AND PRIMARY SCHOOL, ANUMANTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23063",
            "district": "2",
            "Block": "114",
            "label": "ELA GREEN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23064",
            "district": "2",
            "Block": "114",
            "label": "SDA HIGHER SCONDARY SCHOOL,CHENGALPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23065",
            "district": "2",
            "Block": "299",
            "label": "RAVINDRA BHARATHI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23101",
            "district": "2",
            "Block": "299",
            "label": "SRICHIATANYA TECHNO SCHOOL, PERUNGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23102",
            "district": "2",
            "Block": "300",
            "label": "VELS VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23103",
            "district": "2",
            "Block": "322",
            "label": "GURU SHREE SHANTIVIJAI JAIN GURUKUL  NALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23105",
            "district": "2",
            "Block": "114",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23157",
            "district": "2",
            "Block": "153",
            "label": "GOOD SHEPHERD INTERNATIONAL SCHOOL VEPANCHERY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23158",
            "district": "2",
            "Block": "300",
            "label": "GURUKULAM VIDYASHRAM WEST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23159",
            "district": "2",
            "Block": "114",
            "label": "VIKAS MANTRA PUBLIC SCHOOL ANJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23198",
            "district": "2",
            "Block": "329",
            "label": "COLOURS INDIAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23204",
            "district": "2",
            "Block": "329",
            "label": "THE SCHOOL - KFI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23205",
            "district": "2",
            "Block": "329",
            "label": "EDUBBA SCHOOL, POLACHERY ROAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23222",
            "district": "2",
            "Block": "299",
            "label": "CAMPUS K SCHOOL, SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23223",
            "district": "2",
            "Block": "299",
            "label": "JEPPIAAR SCHOOL CHEMMENCHERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23224",
            "district": "2",
            "Block": "299",
            "label": "SRI CHAITANYA TECHNO SCHOOL, PALAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23227",
            "district": "2",
            "Block": "329",
            "label": "KC HIGH SCHOOL NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23423",
            "district": "2",
            "Block": "114",
            "label": "SRI T.S. BALIAH MEMORIAL MATRICULATION HS",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23409",
            "district": "2",
            "Block": "299",
            "label": "The Indian Public School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23231",
            "district": "2",
            "Block": "299",
            "label": "GHSS PERUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23246",
            "district": "2",
            "Block": "162",
            "label": "GHS MALAIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23271",
            "district": "2",
            "Block": "299",
            "label": "NARAYANA OLYMPIAD SCHOOL,MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23277",
            "district": "2",
            "Block": "114",
            "label": "QUEEN MARYS ACADEMY MATRICULATION SCHOOL PORUNTHAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23278",
            "district": "2",
            "Block": "299",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23279",
            "district": "2",
            "Block": "300",
            "label": "SAN ACADEMY  TAMBARAM WEST",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23293",
            "district": "2",
            "Block": "299",
            "label": "SUJAY PUBLIC SCHOOL MEDAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23302",
            "district": "2",
            "Block": "162",
            "label": "KARPAGA VINAYAGA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23303",
            "district": "2",
            "Block": "329",
            "label": "SHRADDHA CHILDRENS ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23307",
            "district": "2",
            "Block": "299",
            "label": "VISTA BILLABONG HIGH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23311",
            "district": "2",
            "Block": "299",
            "label": "THE BAY INTERNATIONAL SCHOOL &  JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23423",
            "district": "2",
            "Block": "114",
            "label": "SRI T.S. BALIAH MEMORIAL MATRICULATION HS",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23430",
            "district": "2",
            "Block": "1",
            "label": "AALAM INTERNATIONAL SCHOOL, THOZHUPEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23431",
            "district": "2",
            "Block": "114",
            "label": "VELAMMAL NEWGEN SCHOOL NEDUNGUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23432",
            "district": "2",
            "Block": "114",
            "label": "VIDYA MANDIR SENIOR SECONDARY SCHOOL ESTANCIA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23433",
            "district": "2",
            "Block": "162",
            "label": "VIVEKANANDA VIDYALAYA MADURANDAGAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23434",
            "district": "2",
            "Block": "299",
            "label": "SHREE NIKETAN PATASALA AGARAMTHEN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23435",
            "district": "2",
            "Block": "299",
            "label": "FIIT JEE GLOBAL SCHOOL  VENGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23436",
            "district": "2",
            "Block": "299",
            "label": "VELAMMAL NEWGEN SCHOOL, MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23437",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR KOVILAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23438",
            "district": "2",
            "Block": "299",
            "label": "ANNAI ARUL PUBLIC SCHOOL, MADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23439",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR, THIRUNEERMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23440",
            "district": "2",
            "Block": "299",
            "label": "THE INTERNATIONAL VILLAGE SCHOOL SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23441",
            "district": "2",
            "Block": "299",
            "label": "GATEWAY THE COMPLETE SCHOOL, SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23442",
            "district": "2",
            "Block": "329",
            "label": "ADWHS MELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23443",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL NEW GEN SCHOOL - NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23444",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL VIDHYASHRAM PADUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23445",
            "district": "2",
            "Block": "329",
            "label": "VELLORE INTERNATIONAL RESIDENTIAL SCHOOL  KAYAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23446",
            "district": "2",
            "Block": "329",
            "label": "JAGANNATH VIDYALAYA MATRICULATION SCHOOL. THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23819",
            "district": "2",
            "Block": "114",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23820",
            "district": "2",
            "Block": "114",
            "label": "CRESCENT SCHOOL, VANDALUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23821",
            "district": "2",
            "Block": "114",
            "label": "Chengalpattu District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23861",
            "district": "2",
            "Block": "162",
            "label": "PUMS NELVOY",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23862",
            "district": "2",
            "Block": "162",
            "label": "PUMS PAZHAYANUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23863",
            "district": "2",
            "Block": "162",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23864",
            "district": "2",
            "Block": "162",
            "label": "PUMS KUNNATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23865",
            "district": "2",
            "Block": "162",
            "label": "SRI S. C AGARWAL RT 95 VIVEKANANDA VIDYASHRAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23977",
            "district": "2",
            "Block": "299",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23978",
            "district": "2",
            "Block": "299",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23979",
            "district": "2",
            "Block": "299",
            "label": "ORCHIDS THE INTERNATIONAL SCHOOL,PERUMBAKKAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23980",
            "district": "2",
            "Block": "299",
            "label": "DAV SARADA ESHWAR VIDYALAYA POZHICHALUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23981",
            "district": "2",
            "Block": "300",
            "label": "AMETHYST INTERNATIONAL SCHOOL OTTIYAMBAKKAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23982",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARA VIDYALAYA",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24002",
            "district": "2",
            "Block": "322",
            "label": "SELVAGAM SANTHANALAKSHMI NOBLE MATRICULATION SCHOOL, THIRUKKAZHUKUNDRAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24003",
            "district": "2",
            "Block": "322",
            "label": "MAAMALLAN SCHOOL POONCHERI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24008",
            "district": "2",
            "Block": "329",
            "label": "PUMS KOLATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24009",
            "district": "2",
            "Block": "329",
            "label": "Athena Global School",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24010",
            "district": "2",
            "Block": "329",
            "label": "VIJAY VIDHYASHRAM, PADUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24011",
            "district": "2",
            "Block": "329",
            "label": "MILTON BILLABONG INTERNATIONAL SCHOOL, THAIYUR ,KELAMBAKKAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24012",
            "district": "2",
            "Block": "329",
            "label": "VELLORE INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24013",
            "district": "2",
            "Block": "329",
            "label": "CHETTINAD SARVALOKAA EDUCATION INTRENATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "3": [
        {
            "key": "374",
            "district": "3",
            "Block": "259",
            "label": "BALALOK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24122", "district":"3", 
            "Block":"414", "label": "Sun shine Chennai senior secondary school", 
            "Sch_type": "Un-aided", "Category": "Higher Secondary School"
        },
        { 
            "key":"24128", "district":"3", 
            "Block":"415", "label": "Pon Vidyashram Group of Senior Secondary Schools", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        { 
            "key":"24131", "district":"3", 
            "Block":"259", "label": "Al-Qalam Islamic School - Madarasa", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        { 
            "key":"24133", "district":"3", 
            "Block":"195", "label": "Sprouts Montessori", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "375",
            "district": "3",
            "Block": "237",
            "label": "RAILWAY BALA BHAVAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "376",
            "district": "3",
            "Block": "62",
            "label": "DOVETON  BOYS' HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "378",
            "district": "3",
            "Block": "259",
            "label": "Shri M Ct Muthiah Memorial Matriculation Hr Sec School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "381",
            "district": "3",
            "Block": "270",
            "label": "MARIA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "386",
            "district": "3",
            "Block": "270",
            "label": "ST MARYS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23647",
            "district": "3",
            "Block": "415",
            "label": "Sir Ramaswami Mudaliar Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23661",
            "district": "3",
            "Block": "195",
            "label": "Besant Lvr Matriculation Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23651",
            "district": "3",
            "Block": "418",
            "label": "Sri Ram Dayal Khemka Vivekananda Vidyalaya Senior Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23648",
            "district": "3",
            "Block": "2",
            "label": "Manthan Vidyashram",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23655",
            "district": "3",
            "Block": "270",
            "label": "Kalaimagal School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "390",
            "district": "3",
            "Block": "259",
            "label": "MORNING STAR MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "392",
            "district": "3",
            "Block": "2",
            "label": "ALL ANGELS MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "394",
            "district": "3",
            "Block": "270",
            "label": "NORTHWICK GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "395",
            "district": "3",
            "Block": "304",
            "label": "GURUJI SRI MURALIDHARA SWAMIGAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23424",
            "district": "3",
            "Block": "62",
            "label": "Olive International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "396",
            "district": "3",
            "Block": "304",
            "label": "FATHIMA BASHEER MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "399",
            "district": "3",
            "Block": "304",
            "label": "ANJUMAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "412",
            "district": "3",
            "Block": "195",
            "label": "ST.SAVIO MATRICULATION HIGHER  SECONDARY SCHOOL, VELACHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "418",
            "district": "3",
            "Block": "259",
            "label": "ST. JOHN'S MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "434",
            "district": "3",
            "Block": "2",
            "label": "ST PATRICKS AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "444",
            "district": "3",
            "Block": "62",
            "label": "SIVAKASI HINDU NADAR  MATRICULATION HR.SEC.SCHOOLL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "445",
            "district": "3",
            "Block": "69",
            "label": "LITTLE FLOWERS MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "451",
            "district": "3",
            "Block": "304",
            "label": "AVM RAJESWARI  MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "455",
            "district": "3",
            "Block": "240",
            "label": "HYDER GARDEN MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "459",
            "district": "3",
            "Block": "304",
            "label": "KARTHIKEYAN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "469",
            "district": "3",
            "Block": "270",
            "label": "TST RAJAH GIRLS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "470",
            "district": "3",
            "Block": "240",
            "label": "VEERA SAVARKAR NETHAJI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "472",
            "district": "3",
            "Block": "69",
            "label": "DON BOSCO MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "485",
            "district": "3",
            "Block": "195",
            "label": "VIDYA RATNA P.T.S MATRIC.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "486",
            "district": "3",
            "Block": "2",
            "label": "FATHIMA CENTRAL SENIOR SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "490",
            "district": "3",
            "Block": "2",
            "label": "VANAVANI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "516",
            "district": "3",
            "Block": "240",
            "label": "DON BOSCO MAT SCHOOL MKB NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "517",
            "district": "3",
            "Block": "240",
            "label": "SEVENTH DAY ADVENTIST MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "518",
            "district": "3",
            "Block": "2",
            "label": "ANNAI VELANKANNI MATRICULATION HIGHER SECONDARY SCHOOL HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "528",
            "district": "3",
            "Block": "259",
            "label": "SUNSHINE ACADEMY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "534",
            "district": "3",
            "Block": "237",
            "label": "ST MARYS BOYS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "542",
            "district": "3",
            "Block": "69",
            "label": "DON BOSCO MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "544",
            "district": "3",
            "Block": "304",
            "label": "SIR M. VENKATASUBBA RAO MAT. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "557",
            "district": "3",
            "Block": "237",
            "label": "BALAJE  VIDYASHRAM MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "573",
            "district": "3",
            "Block": "259",
            "label": "MAR THOMA MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "582",
            "district": "3",
            "Block": "237",
            "label": "KULAPATHI Dr.S.Balakrishnan Joshi Gurrukulam Matriculation HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "585",
            "district": "3",
            "Block": "304",
            "label": "VELANKANNI MATRIC HSS ASHOK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "589",
            "district": "3",
            "Block": "304",
            "label": "VIDYANIKEDAN MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "597",
            "district": "3",
            "Block": "2",
            "label": "SANKARA SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "654",
            "district": "3",
            "Block": "62",
            "label": "GOVT HOBART MUSLIM GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "656",
            "district": "3",
            "Block": "69",
            "label": "SEETHAKKATHI MATRIC HR SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "682",
            "district": "3",
            "Block": "62",
            "label": "SACRED HEART MAT HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "763",
            "district": "3",
            "Block": "2",
            "label": "MAK MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "812",
            "district": "3",
            "Block": "304",
            "label": "VIDYAKSHETHRAM MATRIC SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "815",
            "district": "3",
            "Block": "304",
            "label": "SRI KRISHNASWAMY MHSS KK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "843",
            "district": "3",
            "Block": "368",
            "label": "CHS CHINTADRIPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "849",
            "district": "3",
            "Block": "270",
            "label": "ST. COLUMBAN'S A I Hr Sec School",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "861",
            "district": "3",
            "Block": "304",
            "label": "HOLY ANGELS ANGLO INDIAN HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "868",
            "district": "3",
            "Block": "259",
            "label": "VALLIAMMAL MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "879",
            "district": "3",
            "Block": "195",
            "label": "MP ANAND MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "889",
            "district": "3",
            "Block": "195",
            "label": "MWA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "898",
            "district": "3",
            "Block": "237",
            "label": "EVERWIN MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "926",
            "district": "3",
            "Block": "259",
            "label": "ALLWYN MATRIC HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "941",
            "district": "3",
            "Block": "270",
            "label": "ST FRANCIS XAVIER AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "944",
            "district": "3",
            "Block": "259",
            "label": "DANIEL THOMAS MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "945",
            "district": "3",
            "Block": "62",
            "label": "PN DHAWAN ADARSH VIDYALAYA MAT HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "975",
            "district": "3",
            "Block": "2",
            "label": "INFANT JESUS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "976",
            "district": "3",
            "Block": "259",
            "label": "JGVV MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "988",
            "district": "3",
            "Block": "259",
            "label": "GOOD HOPE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1051",
            "district": "3",
            "Block": "69",
            "label": "ECI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1053",
            "district": "3",
            "Block": "259",
            "label": "BENTINCK HR.SEC.SCHOOL FOR GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1149",
            "district": "3",
            "Block": "368",
            "label": "CAMBRIDGE MAT HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1177",
            "district": "3",
            "Block": "304",
            "label": "LITTLE OXFORD MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1209",
            "district": "3",
            "Block": "195",
            "label": "BHARATH SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1223",
            "district": "3",
            "Block": "259",
            "label": "MOHAMED SATHAK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1233",
            "district": "3",
            "Block": "259",
            "label": "PADMA SARANGAPANI MATRIC HR.SE.SCHOOL, VIRUGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1313",
            "district": "3",
            "Block": "195",
            "label": "SRI SANKARA VIDYASHRAMAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1337",
            "district": "3",
            "Block": "195",
            "label": "MANUELMONY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1344",
            "district": "3",
            "Block": "368",
            "label": "THE CLARKE SCHOOL FOR THE DEAF",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1359",
            "district": "3",
            "Block": "62",
            "label": "AGARWAL VIDYALAYA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1381",
            "district": "3",
            "Block": "195",
            "label": "SARASWATHY VENKATARAMAN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1402",
            "district": "3",
            "Block": "304",
            "label": "BABA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1432",
            "district": "3",
            "Block": "368",
            "label": "EMMA FOULGER MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1452",
            "district": "3",
            "Block": "259",
            "label": "SBOA (M) HSS ANNA NAGAR WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1457",
            "district": "3",
            "Block": "270",
            "label": "MODEL MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1461",
            "district": "3",
            "Block": "2",
            "label": "ST FRANCIS SAVIO MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1470",
            "district": "3",
            "Block": "270",
            "label": "T.M.M.S SHUNMUGA SUNDARAM & A.P. SAMY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1485",
            "district": "3",
            "Block": "69",
            "label": "ST JOSEPH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1488",
            "district": "3",
            "Block": "62",
            "label": "VIDYODAYA GIRLS'HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1511",
            "district": "3",
            "Block": "237",
            "label": "DHANISH MATRIC. HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1535",
            "district": "3",
            "Block": "304",
            "label": "JUSTICE BASHEER AHMD MEM.MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1537",
            "district": "3",
            "Block": "195",
            "label": "CHETTINAD VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1542",
            "district": "3",
            "Block": "259",
            "label": "J.M.Matriculation Girls Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1563",
            "district": "3",
            "Block": "270",
            "label": "KALAIMAGAL VIDYALAYA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1578",
            "district": "3",
            "Block": "62",
            "label": "ANITA METHODIST MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1615",
            "district": "3",
            "Block": "259",
            "label": "NATIONAL STAR MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1619",
            "district": "3",
            "Block": "237",
            "label": "DAVIDSON MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1622",
            "district": "3",
            "Block": "69",
            "label": "SRISHTI INTERNATIONAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1658",
            "district": "3",
            "Block": "304",
            "label": "NAVABHARATH MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1737",
            "district": "3",
            "Block": "195",
            "label": "SRI SANKARA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1743",
            "district": "3",
            "Block": "195",
            "label": "CSI MONAHAN GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1750",
            "district": "3",
            "Block": "62",
            "label": "DOVETON  GIRLS' HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1758",
            "district": "3",
            "Block": "2",
            "label": "GHSS MODEL SAIDAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1784",
            "district": "3",
            "Block": "259",
            "label": "C.S.I. EWART MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1788",
            "district": "3",
            "Block": "304",
            "label": "Sri Ramakrishna Mission Sarada Vidyalaya Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1792",
            "district": "3",
            "Block": "259",
            "label": "ST. VINCENT'S MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1816",
            "district": "3",
            "Block": "304",
            "label": "SRI SITARAM VIDYALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1840",
            "district": "3",
            "Block": "2",
            "label": "OXFORD MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1847",
            "district": "3",
            "Block": "368",
            "label": "EVE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1864",
            "district": "3",
            "Block": "304",
            "label": "FATIMA MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1902",
            "district": "3",
            "Block": "62",
            "label": "UNION CHRISTIAN MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1936",
            "district": "3",
            "Block": "69",
            "label": "TEMPLE PARK MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1947",
            "district": "3",
            "Block": "259",
            "label": "SINDHI MODEL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1992",
            "district": "3",
            "Block": "69",
            "label": "DR RADHAKRISHNAN MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2023",
            "district": "3",
            "Block": "2",
            "label": "Anna Gem Science Park Mat HSS.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2034",
            "district": "3",
            "Block": "2",
            "label": "KUMARARANI MEENA MUTHIAH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2053",
            "district": "3",
            "Block": "368",
            "label": "CHRIST CHURCH ANGLO-INDIAN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2072",
            "district": "3",
            "Block": "195",
            "label": "SRI VENUGOPAL VIDYALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2090",
            "district": "3",
            "Block": "237",
            "label": "PERIYAR MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2148",
            "district": "3",
            "Block": "195",
            "label": "SRI MYLAI KARPAGAVALLI MATRICUATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2166",
            "district": "3",
            "Block": "237",
            "label": "ST MARY'S MATRICULATION GIRLS HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2172",
            "district": "3",
            "Block": "304",
            "label": "SRM NIGHTINGALE MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2190",
            "district": "3",
            "Block": "2",
            "label": "GURU NANAK MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2226",
            "district": "3",
            "Block": "304",
            "label": "VADAPALANI MAT HR SEC SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2240",
            "district": "3",
            "Block": "270",
            "label": "SRI VENKATESWARA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2251",
            "district": "3",
            "Block": "259",
            "label": "ANNA ADARSH MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2259",
            "district": "3",
            "Block": "237",
            "label": "VEERA SAVARAKAR NETAJI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2296",
            "district": "3",
            "Block": "62",
            "label": "VIDYODAYA MATRICULATION HIGHER SECIONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2353",
            "district": "3",
            "Block": "368",
            "label": "CMS BALAJI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2359",
            "district": "3",
            "Block": "237",
            "label": "RADIANCE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2458",
            "district": "3",
            "Block": "195",
            "label": "CMS THIRUVALLUVARPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2469",
            "district": "3",
            "Block": "259",
            "label": "TAGORE MAT HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2477",
            "district": "3",
            "Block": "259",
            "label": "DANIEL THOMAS MAT HSS KOYAMBEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2503",
            "district": "3",
            "Block": "270",
            "label": "KAMARAJ MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2509",
            "district": "3",
            "Block": "259",
            "label": "KANCHEEPURAM ARULMIGU EGAMBARANATHAR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2531",
            "district": "3",
            "Block": "237",
            "label": "ST JOSEPH AI HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2634",
            "district": "3",
            "Block": "304",
            "label": "SHAKESPEARE MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2672",
            "district": "3",
            "Block": "259",
            "label": "MARY CLUBWALA JADHAV SPECIAL HIGHER SECONDARY SCHOOL FOR THE HEARING IMPAIRED",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2685",
            "district": "3",
            "Block": "195",
            "label": "CMS NS GARDEN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2729",
            "district": "3",
            "Block": "2",
            "label": "ALPHA MATRICULATION  HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2804",
            "district": "3",
            "Block": "237",
            "label": "RAILWAY MIXED HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2807",
            "district": "3",
            "Block": "237",
            "label": "ANNAI VAILANKANNI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2813",
            "district": "3",
            "Block": "237",
            "label": "BHARATHA VIDYALA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2818",
            "district": "3",
            "Block": "304",
            "label": "CLARENCE MAT HR SEC SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2823",
            "district": "3",
            "Block": "240",
            "label": "VISWA BHARATHI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2835",
            "district": "3",
            "Block": "304",
            "label": "CSI St.Augustine Matric School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2842",
            "district": "3",
            "Block": "195",
            "label": "CMS PROF SANJEEVI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2855",
            "district": "3",
            "Block": "195",
            "label": "M.Ct.M  CHIDAMBARAM CHETTYAR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2888",
            "district": "3",
            "Block": "259",
            "label": "ALAGAPPA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2899",
            "district": "3",
            "Block": "259",
            "label": "T T A MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2909",
            "district": "3",
            "Block": "240",
            "label": "BAYNES MEM BAPTIST CHURCH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2915",
            "district": "3",
            "Block": "304",
            "label": "JRK MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2919",
            "district": "3",
            "Block": "304",
            "label": "THAI SATHYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2991",
            "district": "3",
            "Block": "240",
            "label": "WISDOM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3004",
            "district": "3",
            "Block": "240",
            "label": "C.S.I MIDDLE SCHOOL MM ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3072",
            "district": "3",
            "Block": "2",
            "label": "CMS MGR NAGAR II",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3111",
            "district": "3",
            "Block": "240",
            "label": "C.S.I. MIDDLE SCHOOL GM ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3113",
            "district": "3",
            "Block": "2",
            "label": "THIRUVALLUVAR GURUKULAM MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3116",
            "district": "3",
            "Block": "195",
            "label": "CMS MK AMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3141",
            "district": "3",
            "Block": "2",
            "label": "SRI SARADA MIDDLE SCHOOL WEST MAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3204",
            "district": "3",
            "Block": "237",
            "label": "GRACE MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3209",
            "district": "3",
            "Block": "195",
            "label": "ST JOHNS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3246",
            "district": "3",
            "Block": "259",
            "label": "PADMA SARANGAPANI MATRIC HR.SEC.SCHOOL,VILLIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3249",
            "district": "3",
            "Block": "237",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3264",
            "district": "3",
            "Block": "259",
            "label": "GRACE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3273",
            "district": "3",
            "Block": "237",
            "label": "SREE AYYAPPA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3286",
            "district": "3",
            "Block": "368",
            "label": "GILL ADARSH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3296",
            "district": "3",
            "Block": "195",
            "label": "ST. ANTONY'S GIRLS HR .SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3314",
            "district": "3",
            "Block": "2",
            "label": "ANNAI THERESA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3317",
            "district": "3",
            "Block": "304",
            "label": "JANAKI RAMACHANDIRAN MATRIC HIGHER  SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3323",
            "district": "3",
            "Block": "240",
            "label": "H.U.C. MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3350",
            "district": "3",
            "Block": "2",
            "label": "C.ANNAPOORANI AMMAL M S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3355",
            "district": "3",
            "Block": "2",
            "label": "SHRI A GANESAN MAT HR SEC SCHOOL, VELACHERY.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3366",
            "district": "3",
            "Block": "237",
            "label": "SRI PADMA SARANGAPANI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3376",
            "district": "3",
            "Block": "195",
            "label": "CMS GANDHIGRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3412",
            "district": "3",
            "Block": "2",
            "label": "ST MARYS MATRICULATION  HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3414",
            "district": "3",
            "Block": "270",
            "label": "ST THOMAS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3432",
            "district": "3",
            "Block": "304",
            "label": "RAMAKRISHNA MISSION MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3437",
            "district": "3",
            "Block": "237",
            "label": "LOURDES MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3451",
            "district": "3",
            "Block": "237",
            "label": "THANGAM MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3454",
            "district": "3",
            "Block": "195",
            "label": "ST.JOHN'S MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3459",
            "district": "3",
            "Block": "237",
            "label": "JAIGOPAL GARODIA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3510",
            "district": "3",
            "Block": "259",
            "label": "ICI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3549",
            "district": "3",
            "Block": "69",
            "label": "CHENNAI PORT & DOCK EDUCATIONAL TRUST HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3592",
            "district": "3",
            "Block": "240",
            "label": "ST PETERS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3629",
            "district": "3",
            "Block": "368",
            "label": "CMS KRISHNAMPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3673",
            "district": "3",
            "Block": "304",
            "label": "SUNDARAM MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3775",
            "district": "3",
            "Block": "237",
            "label": "PONNUSWAMY MAT HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3777",
            "district": "3",
            "Block": "240",
            "label": "C.S.I. MIDDLE SCHOOL (CHOOLAI)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3789",
            "district": "3",
            "Block": "195",
            "label": "ROSARY MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3793",
            "district": "3",
            "Block": "270",
            "label": "MEASI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3832",
            "district": "3",
            "Block": "2",
            "label": "SIVASAKTHI MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3842",
            "district": "3",
            "Block": "259",
            "label": "DAV MAT HSS GILL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3886",
            "district": "3",
            "Block": "69",
            "label": "SACRED HEART MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3899",
            "district": "3",
            "Block": "195",
            "label": "DAVID MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3932",
            "district": "3",
            "Block": "270",
            "label": "DR RSM GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3940",
            "district": "3",
            "Block": "368",
            "label": "D AHMED ALI PARPIA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3943",
            "district": "3",
            "Block": "259",
            "label": "AMBAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3946",
            "district": "3",
            "Block": "237",
            "label": "ANGEL MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3958",
            "district": "3",
            "Block": "2",
            "label": "CAMBRRIDGE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3962",
            "district": "3",
            "Block": "195",
            "label": "ST.BEDE'S A.I HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3970",
            "district": "3",
            "Block": "62",
            "label": "MINERVA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3984",
            "district": "3",
            "Block": "237",
            "label": "KAMARAJ MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4012",
            "district": "3",
            "Block": "304",
            "label": "SPRINGFIELD MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4013",
            "district": "3",
            "Block": "259",
            "label": "CSI BAIN MATRICULATION & HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4046",
            "district": "3",
            "Block": "69",
            "label": "SRI SAYEE VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4057",
            "district": "3",
            "Block": "62",
            "label": "CSI MONAHAN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4073",
            "district": "3",
            "Block": "62",
            "label": "SEVENTH DAY ADVENTIST MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4108",
            "district": "3",
            "Block": "2",
            "label": "CMS METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4113",
            "district": "3",
            "Block": "2",
            "label": "A.C. Middle School Kanagam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4134",
            "district": "3",
            "Block": "2",
            "label": "GANAPATHY NATIONAL MIDDLE SCHOOL, SAIDAPET, CH-15.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4180",
            "district": "3",
            "Block": "2",
            "label": "CMS CART MAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4205",
            "district": "3",
            "Block": "2",
            "label": "BETHEL MAT HSS VELACHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4214",
            "district": "3",
            "Block": "237",
            "label": "DANISH MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4226",
            "district": "3",
            "Block": "304",
            "label": "STELLA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4231",
            "district": "3",
            "Block": "2",
            "label": "GEETHA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4295",
            "district": "3",
            "Block": "304",
            "label": "SRI TARACHAND GALADA JAIN MAT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4304",
            "district": "3",
            "Block": "240",
            "label": "St. MARK's MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4329",
            "district": "3",
            "Block": "69",
            "label": "NORTH MADRAS SOCIAL SERVICE MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4330",
            "district": "3",
            "Block": "368",
            "label": "REX MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4344",
            "district": "3",
            "Block": "270",
            "label": "ARUMUGA NADAR GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4428",
            "district": "3",
            "Block": "240",
            "label": "TERAPANTH JAIN VIDYALAYA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4440",
            "district": "3",
            "Block": "195",
            "label": "PS MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4530",
            "district": "3",
            "Block": "62",
            "label": "KALAIPOONGA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4536",
            "district": "3",
            "Block": "270",
            "label": "C.S.I. MIDDLE SCHOOL (MINT RAJAGOPAL)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4610",
            "district": "3",
            "Block": "240",
            "label": "ANAIKAR MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4617",
            "district": "3",
            "Block": "62",
            "label": "CRESCENT MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4628",
            "district": "3",
            "Block": "62",
            "label": "TERAPANTH JAIN VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4637",
            "district": "3",
            "Block": "2",
            "label": "CSI M SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4647",
            "district": "3",
            "Block": "237",
            "label": "ST MOSES MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4655",
            "district": "3",
            "Block": "2",
            "label": "CMS THIDEER NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4658",
            "district": "3",
            "Block": "195",
            "label": "BALA SARAVANA VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4674",
            "district": "3",
            "Block": "240",
            "label": "PRINCESS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4679",
            "district": "3",
            "Block": "62",
            "label": "ST ANTONYS ANGLO INDIAN HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "4686",
            "district": "3",
            "Block": "195",
            "label": "MOUNT CARMEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4694",
            "district": "3",
            "Block": "304",
            "label": "LITTLE FLOWER MAT HSS ASHOK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4701",
            "district": "3",
            "Block": "2",
            "label": "BETA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4707",
            "district": "3",
            "Block": "2",
            "label": "ECI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4710",
            "district": "3",
            "Block": "195",
            "label": "AKSHAYAH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4715",
            "district": "3",
            "Block": "259",
            "label": "MES RAZEENA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4728",
            "district": "3",
            "Block": "195",
            "label": "MONTFORT ACADEMY MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4734",
            "district": "3",
            "Block": "237",
            "label": "BETHEL MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4774",
            "district": "3",
            "Block": "368",
            "label": "SREE VENKATESHWARAR MATRIC. HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4801",
            "district": "3",
            "Block": "368",
            "label": "M.O.P VAISHNAV MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4813",
            "district": "3",
            "Block": "240",
            "label": "KR MONTFORD MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4901",
            "district": "3",
            "Block": "304",
            "label": "LOYOLA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4908",
            "district": "3",
            "Block": "304",
            "label": "SRI CHILD FRUIT MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4910",
            "district": "3",
            "Block": "69",
            "label": "Angels Babyland Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4917",
            "district": "3",
            "Block": "237",
            "label": "DONBOSCO MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4953",
            "district": "3",
            "Block": "259",
            "label": "PONNAMMAL MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4970",
            "district": "3",
            "Block": "62",
            "label": "MADRAS CHRISTIAN COLLEGE  MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5015",
            "district": "3",
            "Block": "237",
            "label": "ST JOHNS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5032",
            "district": "3",
            "Block": "237",
            "label": "VATSALYA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5169",
            "district": "3",
            "Block": "69",
            "label": "NORTH MADRAS MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5175",
            "district": "3",
            "Block": "62",
            "label": "OUR LADY'S MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5271",
            "district": "3",
            "Block": "259",
            "label": "DR BMS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5273",
            "district": "3",
            "Block": "69",
            "label": "VELANKANNI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5313",
            "district": "3",
            "Block": "2",
            "label": "ADVENT CHRISTIAN MIDDLE SCHOOL,  THIRUVANMIYUR, CH-41.",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5319",
            "district": "3",
            "Block": "237",
            "label": "CHANDABAI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5323",
            "district": "3",
            "Block": "237",
            "label": "GOODWILL HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "5386",
            "district": "3",
            "Block": "304",
            "label": "RAMALINGA MISSION MIDDLE SCH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5427",
            "district": "3",
            "Block": "237",
            "label": "JRET RAGHAVA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5455",
            "district": "3",
            "Block": "62",
            "label": "CMS V.V .ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5464",
            "district": "3",
            "Block": "259",
            "label": "VALLAL SABAPATHY MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5466",
            "district": "3",
            "Block": "259",
            "label": "SRI VENKATESWARA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5483",
            "district": "3",
            "Block": "240",
            "label": "SRI LAKSHMI NARAYANA MATRICULATION HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5518",
            "district": "3",
            "Block": "195",
            "label": "CSI MIDDLE SCHOOL,PUDUPAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5644",
            "district": "3",
            "Block": "62",
            "label": "CMS RAMA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5649",
            "district": "3",
            "Block": "240",
            "label": "MERCURY MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5655",
            "district": "3",
            "Block": "270",
            "label": "OUR LADYS CENTRE MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5656",
            "district": "3",
            "Block": "259",
            "label": "MARY IMMACULATE MS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5657",
            "district": "3",
            "Block": "195",
            "label": "CMS DAMODARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5658",
            "district": "3",
            "Block": "240",
            "label": "CMS MH ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5659",
            "district": "3",
            "Block": "2",
            "label": "RBI STAFF QUARTERS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5664",
            "district": "3",
            "Block": "195",
            "label": "ST FRANCIS XAVIER'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5694",
            "district": "3",
            "Block": "62",
            "label": "LITTLE FLOWER CONVENT - DEAF HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5714",
            "district": "3",
            "Block": "237",
            "label": "SINGARAM PILLAI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5719",
            "district": "3",
            "Block": "304",
            "label": "JG HINDU VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5736",
            "district": "3",
            "Block": "259",
            "label": "CSI MS TANA ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5738",
            "district": "3",
            "Block": "237",
            "label": "KALIGI  RANGANATHAN  MONTFORD MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5746",
            "district": "3",
            "Block": "69",
            "label": "ST JOSEPH MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5748",
            "district": "3",
            "Block": "240",
            "label": "SRI LGG MAT.HR.SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5763",
            "district": "3",
            "Block": "69",
            "label": "NETHAJI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5774",
            "district": "3",
            "Block": "304",
            "label": "SEVENTH DAY ADVENTIST MATRIC HIGH SCHOOL ,KODAMBAKKAM CHENNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5812",
            "district": "3",
            "Block": "69",
            "label": "CMS RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5891",
            "district": "3",
            "Block": "304",
            "label": "AMBATTUR LIONS CLUB MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5912",
            "district": "3",
            "Block": "69",
            "label": "CMS CHINNANDIMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5982",
            "district": "3",
            "Block": "304",
            "label": "LITTLE FLOWER MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5990",
            "district": "3",
            "Block": "270",
            "label": "St KEVIN'S AI HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5998",
            "district": "3",
            "Block": "304",
            "label": "SHRI B S MOOTHA SR SEC SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6008",
            "district": "3",
            "Block": "195",
            "label": "BESANT ARUNDALE SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6018",
            "district": "3",
            "Block": "368",
            "label": "ST. WILLIAMS AIHS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "6020",
            "district": "3",
            "Block": "69",
            "label": "BCS JAIN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6027",
            "district": "3",
            "Block": "62",
            "label": "RBCCC MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6034",
            "district": "3",
            "Block": "62",
            "label": "ST AUGUSTINE MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6038",
            "district": "3",
            "Block": "237",
            "label": "DON BOSCO MATRIC . HR. SEC.  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6047",
            "district": "3",
            "Block": "240",
            "label": "ST ALOYSIUS AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6063",
            "district": "3",
            "Block": "69",
            "label": "OUR LADY'S MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6069",
            "district": "3",
            "Block": "62",
            "label": "DHANAKOTI  MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6071",
            "district": "3",
            "Block": "2",
            "label": "THE ASHRAM MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6075",
            "district": "3",
            "Block": "368",
            "label": "CMS TRIPLICANE HIGH ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6088",
            "district": "3",
            "Block": "2",
            "label": "THE BESANT THEOSOPHICAL HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6100",
            "district": "3",
            "Block": "69",
            "label": "ARINGAR ANNA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6104",
            "district": "3",
            "Block": "237",
            "label": "TAHA CONVENT MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6110",
            "district": "3",
            "Block": "304",
            "label": "BALA VIDHYA MATRIC SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6126",
            "district": "3",
            "Block": "69",
            "label": "CMS 540 T H ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6138",
            "district": "3",
            "Block": "62",
            "label": "THE MADRAS SEVA SADAN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6146",
            "district": "3",
            "Block": "270",
            "label": "BISHOP CORRIE AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6149",
            "district": "3",
            "Block": "237",
            "label": "DON BOSCO MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6150",
            "district": "3",
            "Block": "62",
            "label": "CMS VENKATAMMAL SAMATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6167",
            "district": "3",
            "Block": "304",
            "label": "CMS PRAKASAM ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6193",
            "district": "3",
            "Block": "62",
            "label": "CMS 1A MODEL SCHOOL ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6200",
            "district": "3",
            "Block": "69",
            "label": "OXFORD MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6233",
            "district": "3",
            "Block": "62",
            "label": "DAV GIRLS SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6249",
            "district": "3",
            "Block": "259",
            "label": "CMS ARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6255",
            "district": "3",
            "Block": "69",
            "label": "C.S.I MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6264",
            "district": "3",
            "Block": "259",
            "label": "SRI ARANGIAH NAIDU MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6265",
            "district": "3",
            "Block": "240",
            "label": "ST JOSEPH'S AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6280",
            "district": "3",
            "Block": "195",
            "label": "LADY WILLINGTON HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6297",
            "district": "3",
            "Block": "62",
            "label": "Good Shepherd Matric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6326",
            "district": "3",
            "Block": "69",
            "label": "CMS 13 BUDDHA ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6340",
            "district": "3",
            "Block": "69",
            "label": "CMS GPW COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6356",
            "district": "3",
            "Block": "270",
            "label": "ST.ANNES MATRIC. HIGHER SEC.  SCHOOL,CH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6360",
            "district": "3",
            "Block": "62",
            "label": "ST ANTONYS MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6379",
            "district": "3",
            "Block": "195",
            "label": "DAV PUBLIC SCHOOL VELACHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6395",
            "district": "3",
            "Block": "304",
            "label": "GRT MAHALAKSHMI VID MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6406",
            "district": "3",
            "Block": "195",
            "label": "ST.LAZARUS MIDDLE SCH00L",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6413",
            "district": "3",
            "Block": "62",
            "label": "MARY CLUB WALA JADHAV GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6414",
            "district": "3",
            "Block": "304",
            "label": "DR MGR JANAKI MAT SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6427",
            "district": "3",
            "Block": "259",
            "label": "RAMACHANDRA MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6469",
            "district": "3",
            "Block": "237",
            "label": "VIVEK VIDYALAYA MID SCH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6483",
            "district": "3",
            "Block": "62",
            "label": "CMS 3 AP ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6487",
            "district": "3",
            "Block": "69",
            "label": "CMS CORONATION NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6521",
            "district": "3",
            "Block": "195",
            "label": "S.R.Kalyanaraman Memorial P.S Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6538",
            "district": "3",
            "Block": "270",
            "label": "B.SUBBARAYAN MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6547",
            "district": "3",
            "Block": "259",
            "label": "C. M. S PULLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6548",
            "district": "3",
            "Block": "2",
            "label": "A.C MIDDLE SCHOOL GUINDY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6563",
            "district": "3",
            "Block": "62",
            "label": "SMT VALLIAMMAL RAJABHADAR MIIDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6598",
            "district": "3",
            "Block": "240",
            "label": "V.O.C VIDIYALAYAA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6624",
            "district": "3",
            "Block": "368",
            "label": "Y.M.C.A BOYS TOWN HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6628",
            "district": "3",
            "Block": "259",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6629",
            "district": "3",
            "Block": "62",
            "label": "SATYA MAT HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6648",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI MIDDLE SCHOOL - SPECIAL SCHOOL FOR MUSCULAR DISTROPHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6656",
            "district": "3",
            "Block": "69",
            "label": "SACRED HEART MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6664",
            "district": "3",
            "Block": "195",
            "label": "CSI MIDDLE SCHOOL,ADAM STREET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6694",
            "district": "3",
            "Block": "259",
            "label": "C.M.S VELLALA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6695",
            "district": "3",
            "Block": "195",
            "label": "KUMARA RAJAH MUTHIAH MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6708",
            "district": "3",
            "Block": "237",
            "label": "JOY HIL MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6723",
            "district": "3",
            "Block": "62",
            "label": "SHRI KRISHNASWAMY MATRICULATION  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7086",
            "district": "3",
            "Block": "69",
            "label": "SRI GURUMURTHY VIDYALAYA HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7087",
            "district": "3",
            "Block": "69",
            "label": "SAVIOUR MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7088",
            "district": "3",
            "Block": "69",
            "label": "F.E.S. Mat. Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7089",
            "district": "3",
            "Block": "69",
            "label": "PADMA PRAKASH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7090",
            "district": "3",
            "Block": "69",
            "label": "ANM MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7091",
            "district": "3",
            "Block": "69",
            "label": "SRI VIVEKANANDA VIDYALAYA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7092",
            "district": "3",
            "Block": "69",
            "label": "CSI BAIN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7093",
            "district": "3",
            "Block": "69",
            "label": "DOVETON MATRIC HR SEC SCHOOL, CHINNA KODUNGAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7094",
            "district": "3",
            "Block": "69",
            "label": "CHS ERUKKANCHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7095",
            "district": "3",
            "Block": "69",
            "label": "CHENNAI HIGH SCHOOL, KODUNGAIYUR, CHENNAI 118",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7096",
            "district": "3",
            "Block": "69",
            "label": "GHS KODUNGAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7097",
            "district": "3",
            "Block": "69",
            "label": "ST JOSEPH'S HSS  ERUKKANCHERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7098",
            "district": "3",
            "Block": "69",
            "label": "DON BOSCO MAT SCHOOL TEACHERS COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7099",
            "district": "3",
            "Block": "69",
            "label": "DAVID SONGS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7100",
            "district": "3",
            "Block": "69",
            "label": "CHSS PATEL NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7101",
            "district": "3",
            "Block": "69",
            "label": "VALLALAR MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7102",
            "district": "3",
            "Block": "69",
            "label": "DANIEL MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7103",
            "district": "3",
            "Block": "69",
            "label": "CHS CPT COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7104",
            "district": "3",
            "Block": "69",
            "label": "CHS ARATHOON ROAD  NEW MARKET FARM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7105",
            "district": "3",
            "Block": "69",
            "label": "SHEBHA MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7106",
            "district": "3",
            "Block": "69",
            "label": "CHENNAI HIGHER SECONDARY SCHOOL, 731, T.H. ROAD, CH-81.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7107",
            "district": "3",
            "Block": "69",
            "label": "TSS NADARGAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7108",
            "district": "3",
            "Block": "69",
            "label": "SOUNDARAPANDI SUPPAMMA (GIRLS) HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7109",
            "district": "3",
            "Block": "69",
            "label": "MURUGA DHANUSHKODI GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7110",
            "district": "3",
            "Block": "69",
            "label": "SIR THEAGARAYA COLLEGE HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7111",
            "district": "3",
            "Block": "69",
            "label": "BHAGYAM CHELLIAH MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7112",
            "district": "3",
            "Block": "69",
            "label": "ARUL JOTHI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7113",
            "district": "3",
            "Block": "69",
            "label": "AXENE EVANGELICAL MISSION HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7114",
            "district": "3",
            "Block": "69",
            "label": "A.E.M. MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7115",
            "district": "3",
            "Block": "69",
            "label": "CHS SEVENWELLS @ HARINARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7116",
            "district": "3",
            "Block": "69",
            "label": "CGHSS BUDDHA ST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7117",
            "district": "3",
            "Block": "69",
            "label": "SRI KARPAGAM MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7118",
            "district": "3",
            "Block": "69",
            "label": "SRI MAGESWARA  MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7119",
            "district": "3",
            "Block": "69",
            "label": "CUGMS WASHERMENPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7120",
            "district": "3",
            "Block": "270",
            "label": "CMS VINAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7121",
            "district": "3",
            "Block": "270",
            "label": "CMS KUMMALAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7122",
            "district": "3",
            "Block": "270",
            "label": "CMS PUDUMANI KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7123",
            "district": "3",
            "Block": "270",
            "label": "GOVT. CHILDREN HOME FOR BOYS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7124",
            "district": "3",
            "Block": "270",
            "label": "SRI SARADHA DEVI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7125",
            "district": "3",
            "Block": "270",
            "label": "CHSS APPASAMY LANE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7126",
            "district": "3",
            "Block": "270",
            "label": "KC SANKARALINGA NADAR HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7127",
            "district": "3",
            "Block": "270",
            "label": "PAK PALANISAMY HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7128",
            "district": "3",
            "Block": "270",
            "label": "SRI. RAMA KRISHNA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7129",
            "district": "3",
            "Block": "270",
            "label": "CMS DORAI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7130",
            "district": "3",
            "Block": "270",
            "label": "ST THERESA'S GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7131",
            "district": "3",
            "Block": "270",
            "label": "VALLAL ETTIYAPPA NAICKER HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7132",
            "district": "3",
            "Block": "270",
            "label": "CBHS SN CHETTY ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7133",
            "district": "3",
            "Block": "270",
            "label": "CSI RAJAGOPAL HS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7134",
            "district": "3",
            "Block": "270",
            "label": "ST PETERS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7135",
            "district": "3",
            "Block": "270",
            "label": "SHREE GK JAIN HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7136",
            "district": "3",
            "Block": "270",
            "label": "ST ANNE'S GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7137",
            "district": "3",
            "Block": "270",
            "label": "KAMARAJ MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "7138",
            "district": "3",
            "Block": "270",
            "label": "KAMALA VINAYAGAR AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7139",
            "district": "3",
            "Block": "270",
            "label": "VALLAL SI ALAGARSAMY HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7140",
            "district": "3",
            "Block": "270",
            "label": "DHANALAKSHMI HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7141",
            "district": "3",
            "Block": "270",
            "label": "CMS SANJEEVIRAYAN KOIL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7142",
            "district": "3",
            "Block": "270",
            "label": "CHS MANIGANDAN ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7143",
            "district": "3",
            "Block": "270",
            "label": "C U BOYS HS WASHERMENPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7144",
            "district": "3",
            "Block": "270",
            "label": "TRI JAGAN MOHAN MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "7145",
            "district": "3",
            "Block": "270",
            "label": "KANNAPPA NAYANAR AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7146",
            "district": "3",
            "Block": "270",
            "label": "ST. ROQUE'S MATRIC SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7147",
            "district": "3",
            "Block": "270",
            "label": "C T HS ANJANEYA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7148",
            "district": "3",
            "Block": "270",
            "label": "C.M.S.SEVEN WELLS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7149",
            "district": "3",
            "Block": "270",
            "label": "GOVT MUSLIM HS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7150",
            "district": "3",
            "Block": "270",
            "label": "ST ANNE'S GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7151",
            "district": "3",
            "Block": "270",
            "label": "ST.GABRIEL'S HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7152",
            "district": "3",
            "Block": "270",
            "label": "CUGMS BROADWAY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7153",
            "district": "3",
            "Block": "270",
            "label": "DOMINIC SAVIO MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7154",
            "district": "3",
            "Block": "270",
            "label": "C.M.S.RAMASAMY ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7155",
            "district": "3",
            "Block": "270",
            "label": "C.M.S.SALAI VINAYAGAR KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7156",
            "district": "3",
            "Block": "270",
            "label": "DR DHARMAMBAL AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7157",
            "district": "3",
            "Block": "270",
            "label": "GOVT MUSLIM GIRLS HS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7158",
            "district": "3",
            "Block": "270",
            "label": "K. RAMIAH CHETTY ARC GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7159",
            "district": "3",
            "Block": "270",
            "label": "ANDERSON RAJAGOPAL GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7160",
            "district": "3",
            "Block": "270",
            "label": "MSB MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7161",
            "district": "3",
            "Block": "270",
            "label": "CUGMS ANGAPPA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7162",
            "district": "3",
            "Block": "270",
            "label": "ST. MARY'S AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7163",
            "district": "3",
            "Block": "270",
            "label": "THE MUTHIALPET HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7164",
            "district": "3",
            "Block": "270",
            "label": "ANDERSON DAY GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7165",
            "district": "3",
            "Block": "270",
            "label": "P.NAGAMMA AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7166",
            "district": "3",
            "Block": "270",
            "label": "ST. AGNES AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7167",
            "district": "3",
            "Block": "270",
            "label": "SKPD BOYS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7168",
            "district": "3",
            "Block": "270",
            "label": "KTCT GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7169",
            "district": "3",
            "Block": "270",
            "label": "HAJEE ESSA ABBA SAIT'S HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7170",
            "district": "3",
            "Block": "270",
            "label": "SHREE JTCJ MISSION HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7171",
            "district": "3",
            "Block": "270",
            "label": "PACHAIYAPPAS COLLEGE HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7172",
            "district": "3",
            "Block": "270",
            "label": "CHS CB ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7173",
            "district": "3",
            "Block": "270",
            "label": "C.M.S. PADAVETTAMMAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7174",
            "district": "3",
            "Block": "240",
            "label": "CMS MOORTHINGAR ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7175",
            "district": "3",
            "Block": "240",
            "label": "CHENNAI HIGHER SECONDARY SCHOOL  KALYANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7176",
            "district": "3",
            "Block": "240",
            "label": "DON BOSCO HSS VYASARPADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7177",
            "district": "3",
            "Block": "240",
            "label": "SMT  NDJA VIV VID JR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7178",
            "district": "3",
            "Block": "240",
            "label": "GHSS MKB NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7179",
            "district": "3",
            "Block": "240",
            "label": "CMS NEW KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7180",
            "district": "3",
            "Block": "240",
            "label": "CHS GANESAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7181",
            "district": "3",
            "Block": "240",
            "label": "CMS GOKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7182",
            "district": "3",
            "Block": "240",
            "label": "CGHSS MH ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7183",
            "district": "3",
            "Block": "240",
            "label": "SKNS PMC VIV VID JR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7184",
            "district": "3",
            "Block": "240",
            "label": "CMS EKANGIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7185",
            "district": "3",
            "Block": "240",
            "label": "CHS COOKS ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7186",
            "district": "3",
            "Block": "240",
            "label": "CMS H&S FACTORY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7187",
            "district": "3",
            "Block": "240",
            "label": "ADW BHSS KANNIGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7188",
            "district": "3",
            "Block": "240",
            "label": "ADW GHSS KANNIGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7189",
            "district": "3",
            "Block": "240",
            "label": "CHS AMMAIAMMAL ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7190",
            "district": "3",
            "Block": "240",
            "label": "CMS DECOSTER ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7191",
            "district": "3",
            "Block": "240",
            "label": "CUMS PERAMBUR BARRACKS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7192",
            "district": "3",
            "Block": "240",
            "label": "CHRISTIAN HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7193",
            "district": "3",
            "Block": "240",
            "label": "CHS THIRUVENGADA SAMY STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7194",
            "district": "3",
            "Block": "240",
            "label": "ADW HS PULIANTHOPE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7195",
            "district": "3",
            "Block": "240",
            "label": "C.M.S. 130 AMMAN KOIL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7196",
            "district": "3",
            "Block": "240",
            "label": "THE SAIVITE MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7197",
            "district": "3",
            "Block": "240",
            "label": "SRI RAMAKRISHNA MATH VIVEKANANDHA CENTENARY GIRLS' HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7198",
            "district": "3",
            "Block": "240",
            "label": "DR GURUSWAMY MUDALIAR TTV HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7199",
            "district": "3",
            "Block": "240",
            "label": "TTV HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7200",
            "district": "3",
            "Block": "240",
            "label": "GANESH BAI GALADA JAIN GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7201",
            "district": "3",
            "Block": "240",
            "label": "CMS K K LANE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7202",
            "district": "3",
            "Block": "240",
            "label": "The Hindu Union Committee HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7203",
            "district": "3",
            "Block": "240",
            "label": "DON BOSCO HS VEPERY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7204",
            "district": "3",
            "Block": "240",
            "label": "PT LEE Chenglavaraya Naicker HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7205",
            "district": "3",
            "Block": "240",
            "label": "C.M.S. VK PILLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7206",
            "district": "3",
            "Block": "240",
            "label": "C.M.S. 246 MINT STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7207",
            "district": "3",
            "Block": "240",
            "label": "SREE THIRUVOTEESWARAR FREE HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7208",
            "district": "3",
            "Block": "240",
            "label": "ST MATTHIAS' ANGLO INDIAN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7209",
            "district": "3",
            "Block": "240",
            "label": "GSSV JAIN VIDHYALAYA JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7210",
            "district": "3",
            "Block": "240",
            "label": "C.M.S. 87 ANNAPILLAI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7211",
            "district": "3",
            "Block": "240",
            "label": "THE MADRAS PROGRESSIVE UNION HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7212",
            "district": "3",
            "Block": "240",
            "label": "THE HINDU THEOLOGICAL  HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7213",
            "district": "3",
            "Block": "240",
            "label": "CHS WALLTAX ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7214",
            "district": "3",
            "Block": "240",
            "label": "SNCP ARYA SAMAJ HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7215",
            "district": "3",
            "Block": "240",
            "label": "MOONGIBAI GOENKA GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7216",
            "district": "3",
            "Block": "240",
            "label": "SHREE AG JAIN HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7217",
            "district": "3",
            "Block": "240",
            "label": "Motilal Fomra Sanatana Dharma Hss",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7218",
            "district": "3",
            "Block": "240",
            "label": "Shree K.Sugunibai Sanatana Dharma Girls HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7219",
            "district": "3",
            "Block": "240",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7220",
            "district": "3",
            "Block": "240",
            "label": "SRI BADALCHAND SCC JAIN VID MS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7221",
            "district": "3",
            "Block": "240",
            "label": "SHRI SANATANA DHARMA VIDYALAYA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7222",
            "district": "3",
            "Block": "240",
            "label": "SRI J T SURANA JAIN VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7223",
            "district": "3",
            "Block": "240",
            "label": "PT MISSION HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7224",
            "district": "3",
            "Block": "240",
            "label": "SMD HINDU HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7225",
            "district": "3",
            "Block": "240",
            "label": "SRI A.B. PAREKH  GUJARATI VIDYAMANDIR HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7226",
            "district": "3",
            "Block": "240",
            "label": "MANILAL M MEHTA GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7227",
            "district": "3",
            "Block": "240",
            "label": "SSV HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7228",
            "district": "3",
            "Block": "240",
            "label": "ZPH GUJ KKM MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7229",
            "district": "3",
            "Block": "240",
            "label": "CHS NP KOIL ST AT SHARMA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7230",
            "district": "3",
            "Block": "237",
            "label": "GNANODHAYA GIRLS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7231",
            "district": "3",
            "Block": "237",
            "label": "DRBCC HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7232",
            "district": "3",
            "Block": "237",
            "label": "GHS BV COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7233",
            "district": "3",
            "Block": "237",
            "label": "ANNAI VAILANKANNI HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7234",
            "district": "3",
            "Block": "237",
            "label": "JAMALIA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7235",
            "district": "3",
            "Block": "237",
            "label": "CMS KC GARDEN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7236",
            "district": "3",
            "Block": "237",
            "label": "MODEL MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7237",
            "district": "3",
            "Block": "237",
            "label": "CMS GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7238",
            "district": "3",
            "Block": "237",
            "label": "SRI BALA VIDYALAYA  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23688",
            "district": "3",
            "Block": "418",
            "label": " kids park vidyashram school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7239",
            "district": "3",
            "Block": "237",
            "label": "ST.MARK'S MATRICULATION SCHOOL SEMBIUM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7240",
            "district": "3",
            "Block": "237",
            "label": "DON BOSCO HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7241",
            "district": "3",
            "Block": "237",
            "label": "CMS MADUMANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7242",
            "district": "3",
            "Block": "237",
            "label": "CMS VASANTHA GARADEN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7243",
            "district": "3",
            "Block": "237",
            "label": "CHSS BUNDER GARDEN ST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7244",
            "district": "3",
            "Block": "237",
            "label": "LOURDES GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7245",
            "district": "3",
            "Block": "237",
            "label": "JRET GIRLS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7246",
            "district": "3",
            "Block": "237",
            "label": "C.S.I MID.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7247",
            "district": "3",
            "Block": "237",
            "label": "RAILWAY COLONY HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7248",
            "district": "3",
            "Block": "237",
            "label": "SRI CHAMPALAL  PAGARIYA JAIN HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7249",
            "district": "3",
            "Block": "237",
            "label": "SDC VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7250",
            "district": "3",
            "Block": "237",
            "label": "CSI PUTHUIR SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7251",
            "district": "3",
            "Block": "237",
            "label": "CMS RANGASAI ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7252",
            "district": "3",
            "Block": "237",
            "label": "CHS SOMAIYA RAJAH ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7253",
            "district": "3",
            "Block": "237",
            "label": "CGHSS MKT ST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7254",
            "district": "3",
            "Block": "237",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  GKM COLONY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7255",
            "district": "3",
            "Block": "237",
            "label": "CHSS PALAVAYAL ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7256",
            "district": "3",
            "Block": "237",
            "label": "PRCA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7257",
            "district": "3",
            "Block": "237",
            "label": "KANNADA SANGHA HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7258",
            "district": "3",
            "Block": "237",
            "label": "ICF SILVER JUBILEE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7259",
            "district": "3",
            "Block": "237",
            "label": "KARUNA SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7260",
            "district": "3",
            "Block": "237",
            "label": "RAILWAY COLONY MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7261",
            "district": "3",
            "Block": "237",
            "label": "DR SALAI GOVINDARAJAN GIRLS MATRIC HIGH SCHOOL, AYANAVARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7262",
            "district": "3",
            "Block": "237",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7263",
            "district": "3",
            "Block": "237",
            "label": "CHENNAI HIGH SCHOOL NAMMALWARPET CHENNAI-12",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7264",
            "district": "3",
            "Block": "237",
            "label": "CMS SUNDARAM STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7265",
            "district": "3",
            "Block": "237",
            "label": "CHSS KOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7266",
            "district": "3",
            "Block": "237",
            "label": "SRI BALAJI MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7267",
            "district": "3",
            "Block": "237",
            "label": "ST. JOSEPH OF ANNECY MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7268",
            "district": "3",
            "Block": "237",
            "label": "CMS VILLIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7269",
            "district": "3",
            "Block": "237",
            "label": "JOHN GABRIEL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7270",
            "district": "3",
            "Block": "237",
            "label": "SINGARAM PILLAI BOYS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7271",
            "district": "3",
            "Block": "237",
            "label": "SKDT HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7272",
            "district": "3",
            "Block": "237",
            "label": "GOVT HS VILLIWAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7273",
            "district": "3",
            "Block": "237",
            "label": "GOVT GIRLS HSS VILLIWAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7274",
            "district": "3",
            "Block": "237",
            "label": "JOHN WILLIAMS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7275",
            "district": "3",
            "Block": "237",
            "label": "SINGARAM PILLAI GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7276",
            "district": "3",
            "Block": "237",
            "label": "OUR  LADY OF VELANGANNI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7277",
            "district": "3",
            "Block": "259",
            "label": "I.C.F. HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7278",
            "district": "3",
            "Block": "259",
            "label": "W. P. A. SOUNDARAPANDIAN HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7279",
            "district": "3",
            "Block": "259",
            "label": "NIRMAL HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7280",
            "district": "3",
            "Block": "259",
            "label": "SRI ARANGIAH NAIDU HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7281",
            "district": "3",
            "Block": "259",
            "label": "THE TARAPORE AND LOGANATHAN HR. SEC. SCHOOL FOR GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7282",
            "district": "3",
            "Block": "259",
            "label": "CSI JESSIE MOSES MAT. HR. SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7283",
            "district": "3",
            "Block": "259",
            "label": "CHINMAYA VIDYALAYA ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7284",
            "district": "3",
            "Block": "259",
            "label": "KENDRIYA VIDYALAYA ANNA NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7285",
            "district": "3",
            "Block": "259",
            "label": "JAIVEL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7286",
            "district": "3",
            "Block": "259",
            "label": "SBOA SCHOOL & JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7287",
            "district": "3",
            "Block": "259",
            "label": "Shri Krishnaswamy Matric Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7288",
            "district": "3",
            "Block": "259",
            "label": "CHSS KOYAMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7289",
            "district": "3",
            "Block": "259",
            "label": "CHSS VIRUGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7290",
            "district": "3",
            "Block": "259",
            "label": "AV MEIYAPPAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7291",
            "district": "3",
            "Block": "259",
            "label": "CHINMAYA VIDYALAYA SR.SEC.SCHOOL ,VIRUGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7292",
            "district": "3",
            "Block": "259",
            "label": "Smt. M.J.V HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7293",
            "district": "3",
            "Block": "259",
            "label": "CHINMAYA VIDYALAYA HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7294",
            "district": "3",
            "Block": "259",
            "label": "Shri Krishnaswamy Matric Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7295",
            "district": "3",
            "Block": "259",
            "label": "THIRU VI KA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7296",
            "district": "3",
            "Block": "259",
            "label": "C.M.S GUJJI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7297",
            "district": "3",
            "Block": "259",
            "label": "CHENNAI GIRLS HIGHER SECONDARY SCHOOL PULLA AVENUE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7298",
            "district": "3",
            "Block": "259",
            "label": "CMS AMINJIKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7299",
            "district": "3",
            "Block": "259",
            "label": "RAJESWARI MAT HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7300",
            "district": "3",
            "Block": "259",
            "label": "CHENNAI HIGH SCHOOL KILPAUK",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7301",
            "district": "3",
            "Block": "259",
            "label": "KOLA SARASWATHI VAISHNAV SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7302",
            "district": "3",
            "Block": "259",
            "label": "KASTHURIBAI GANDHI HIGH SCHOOL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7303",
            "district": "3",
            "Block": "259",
            "label": "THE SECRETARIAT COLONY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7304",
            "district": "3",
            "Block": "259",
            "label": "B.V.BHAVAN'S RAJAJI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7305",
            "district": "3",
            "Block": "259",
            "label": "CHINMAYA VIDYALAYA KILPAUK",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7306",
            "district": "3",
            "Block": "259",
            "label": "SINDHI MODEL SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7307",
            "district": "3",
            "Block": "259",
            "label": "CHENNAI BOYS HIGHER SECONDARY SCHOOL G.KOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7308",
            "district": "3",
            "Block": "259",
            "label": "BALAR KALVI NILAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "7309",
            "district": "3",
            "Block": "259",
            "label": "CSI MS PERUMALPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7310",
            "district": "3",
            "Block": "259",
            "label": "CSI MS SUNDRAM ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7311",
            "district": "3",
            "Block": "259",
            "label": "ST. PAULS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7312",
            "district": "3",
            "Block": "259",
            "label": "SIR MCTM HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7313",
            "district": "3",
            "Block": "259",
            "label": "LADY M.CT.M GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7314",
            "district": "3",
            "Block": "259",
            "label": "E.L.M.FABRICIUS HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7315",
            "district": "3",
            "Block": "259",
            "label": "TELC MAGDALENE GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7316",
            "district": "3",
            "Block": "259",
            "label": "SHRI B.K.KOTHARI MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7317",
            "district": "3",
            "Block": "259",
            "label": "CHSS  SUBBARAYAN ST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7318",
            "district": "3",
            "Block": "259",
            "label": "KERALA VIDYALAYAM HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7319",
            "district": "3",
            "Block": "259",
            "label": "ST GEORGE'S AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7320",
            "district": "3",
            "Block": "259",
            "label": "SHERWOOD HALL SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7321",
            "district": "3",
            "Block": "259",
            "label": "CHENNAI HIGH SCHOOL MCNICHOLS ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7322",
            "district": "3",
            "Block": "259",
            "label": "CHS MMDA II",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7323",
            "district": "3",
            "Block": "259",
            "label": "KOLA PERUMAL CHETTY VAISHNAV SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7324",
            "district": "3",
            "Block": "259",
            "label": "DANIEL THOMAS GIRLS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7325",
            "district": "3",
            "Block": "259",
            "label": "CMS MMDA I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7326",
            "district": "3",
            "Block": "259",
            "label": "CHS THIRUVALLUVARPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7327",
            "district": "3",
            "Block": "259",
            "label": "CHSS CHOOLAIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23695",
            "district": "3",
            "Block": "414",
            "label": "Nehru Govt. Hr. Sec. School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7328",
            "district": "3",
            "Block": "259",
            "label": "L M DHADHA SR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7329",
            "district": "3",
            "Block": "259",
            "label": "KENDRIYA VIDYALAYA GILL NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7330",
            "district": "3",
            "Block": "259",
            "label": "CMS APPASAMI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7331",
            "district": "3",
            "Block": "259",
            "label": "CMS KUTTY ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7332",
            "district": "3",
            "Block": "259",
            "label": "MALAYALA VIDYALAYAM HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7333",
            "district": "3",
            "Block": "259",
            "label": "ARPUTHARAJ MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7334",
            "district": "3",
            "Block": "368",
            "label": "CMS ELLISPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7335",
            "district": "3",
            "Block": "368",
            "label": "AIWC HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7336",
            "district": "3",
            "Block": "368",
            "label": "CUMS THAYARSAHIB STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7337",
            "district": "3",
            "Block": "368",
            "label": "GOVT. MUSLIM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7338",
            "district": "3",
            "Block": "368",
            "label": "Govt Madarasa - I - Asam HSS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7339",
            "district": "3",
            "Block": "368",
            "label": "KENDRIYA VIDYALAYA HSS",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7340",
            "district": "3",
            "Block": "368",
            "label": "C KALYANAM GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7341",
            "district": "3",
            "Block": "368",
            "label": "CHINDADRIPET HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7342",
            "district": "3",
            "Block": "368",
            "label": "CHINTADRIPET MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7343",
            "district": "3",
            "Block": "368",
            "label": "CMS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7344",
            "district": "3",
            "Block": "368",
            "label": "CHS GOYATHOPE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7345",
            "district": "3",
            "Block": "368",
            "label": "RBANC HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7346",
            "district": "3",
            "Block": "368",
            "label": "CMS THYPOON ALI KHAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7347",
            "district": "3",
            "Block": "368",
            "label": "CMS BANGARU STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7348",
            "district": "3",
            "Block": "368",
            "label": "H.H.MANDAL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7349",
            "district": "3",
            "Block": "368",
            "label": "DEVI NAVASAKTHI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7350",
            "district": "3",
            "Block": "368",
            "label": "THE MUSLIM HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7351",
            "district": "3",
            "Block": "368",
            "label": "SRI JNSJV MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7352",
            "district": "3",
            "Block": "368",
            "label": "MURTHUZAVIYA ORIENTAL HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7353",
            "district": "3",
            "Block": "368",
            "label": "CSI KELLETT HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7354",
            "district": "3",
            "Block": "368",
            "label": "CMS BELLS ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7355",
            "district": "3",
            "Block": "368",
            "label": "CSI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7356",
            "district": "3",
            "Block": "368",
            "label": "THE HINDU SENIOR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7357",
            "district": "3",
            "Block": "368",
            "label": "THE HINDU HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7358",
            "district": "3",
            "Block": "368",
            "label": "CMS SOORAPPA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7359",
            "district": "3",
            "Block": "368",
            "label": "N.K.T NATIONAL GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7360",
            "district": "3",
            "Block": "368",
            "label": "TAMILNAD MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7361",
            "district": "3",
            "Block": "368",
            "label": "N.K.T NATIONAL BOYS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7362",
            "district": "3",
            "Block": "368",
            "label": "CMS VR PILLAI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7363",
            "district": "3",
            "Block": "368",
            "label": "CHS IRUSAPPA STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7364",
            "district": "3",
            "Block": "368",
            "label": "GOVT. MODEL GHSS TRIPLICANE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7365",
            "district": "3",
            "Block": "368",
            "label": "CUMS 10 PETERS ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7366",
            "district": "3",
            "Block": "368",
            "label": "CMS BEGUM SAHIB STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7367",
            "district": "3",
            "Block": "368",
            "label": "CHSS LLOYDS ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7368",
            "district": "3",
            "Block": "368",
            "label": "C.S.I St. Ebba's Matriculation  Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7369",
            "district": "3",
            "Block": "368",
            "label": "CHS VP KOIL ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7370",
            "district": "3",
            "Block": "62",
            "label": "AUXILIUM GIRLS  higher secondary school",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7371",
            "district": "3",
            "Block": "62",
            "label": "Madras Christian College Higher Secondary School",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7372",
            "district": "3",
            "Block": "62",
            "label": "LADY ANDAL VENKATASUBBARAO MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7373",
            "district": "3",
            "Block": "62",
            "label": "CHS PADAVATTAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7374",
            "district": "3",
            "Block": "62",
            "label": "C.M.S. 127 ANGALAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7375",
            "district": "3",
            "Block": "62",
            "label": "C.M.S. 118 ANGALAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7376",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI HIGH SCHOOL  STRAHANS ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7377",
            "district": "3",
            "Block": "62",
            "label": "THE SALVATION ARMY MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7378",
            "district": "3",
            "Block": "62",
            "label": "CMS 88 TANA ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7379",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI GIRLS HIGHER SECONDARY SCHOOL -ROTLER STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7380",
            "district": "3",
            "Block": "62",
            "label": "CMS VAIKKAKARAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7381",
            "district": "3",
            "Block": "62",
            "label": "CMS 72 AP ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7382",
            "district": "3",
            "Block": "62",
            "label": "SRI MUTHUKUMARASWAMY DEVASTHANAM P.VENUGOPAL CHETTY HIGHER  SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7383",
            "district": "3",
            "Block": "62",
            "label": "ST. JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7384",
            "district": "3",
            "Block": "62",
            "label": "ST. ANDREWS  HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7385",
            "district": "3",
            "Block": "62",
            "label": "CMS JAGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7386",
            "district": "3",
            "Block": "62",
            "label": "CMS SIVARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7387",
            "district": "3",
            "Block": "62",
            "label": "CMS EGMORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7388",
            "district": "3",
            "Block": "62",
            "label": "PRESIDENCY GIRLS HSS -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7389",
            "district": "3",
            "Block": "62",
            "label": "DON BOSCO MATRIC  HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7390",
            "district": "3",
            "Block": "62",
            "label": "CMS VARADARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7391",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI BOYS HR.SEC.SCHOOL,  NUNGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7392",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI GIRLS HR.SEC.SCHOOL -  NUNGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7393",
            "district": "3",
            "Block": "62",
            "label": "PADMA SESHADRI BALA BHAVAN SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7394",
            "district": "3",
            "Block": "62",
            "label": "ASSUMPTION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7395",
            "district": "3",
            "Block": "62",
            "label": "CHS THOUSAND LIGHTS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7396",
            "district": "3",
            "Block": "62",
            "label": "ASAN MEMORIAL SENIOR SECONDARY SCHOOLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7397",
            "district": "3",
            "Block": "62",
            "label": "ASAN MEMORIAL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7398",
            "district": "3",
            "Block": "62",
            "label": "LITTLE FLOWER CONVENT - BLIND",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7399",
            "district": "3",
            "Block": "62",
            "label": "ST URSULA'S ANGLO-INDIAN  HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7400",
            "district": "3",
            "Block": "62",
            "label": "IMMACULATE HEART OF MARY MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7401",
            "district": "3",
            "Block": "62",
            "label": "DAV BOYS SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7402",
            "district": "3",
            "Block": "62",
            "label": "SRI SARADHA SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7403",
            "district": "3",
            "Block": "62",
            "label": "NATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7404",
            "district": "3",
            "Block": "62",
            "label": "CMS VELLALA TEYNAMPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7405",
            "district": "3",
            "Block": "62",
            "label": "DR AMBEDKAR GOVT HR.SEC.SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7406",
            "district": "3",
            "Block": "62",
            "label": "ST JOSEPH'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7407",
            "district": "3",
            "Block": "304",
            "label": "GHSS MMDA ARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7408",
            "district": "3",
            "Block": "304",
            "label": "ST JOSEPH HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7409",
            "district": "3",
            "Block": "304",
            "label": "JG GOVT GIRL'S HIGHER SECONDARY SCHOOL,CHOOLAIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7410",
            "district": "3",
            "Block": "304",
            "label": "SATHYAMURTHY HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7411",
            "district": "3",
            "Block": "304",
            "label": "CMS ERIKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7412",
            "district": "3",
            "Block": "304",
            "label": "Al hira model  school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7413",
            "district": "3",
            "Block": "304",
            "label": "CHS VANNIA TEYNAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7414",
            "district": "3",
            "Block": "304",
            "label": "THE GROVE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7415",
            "district": "3",
            "Block": "304",
            "label": "KESARI HR SEC SCHOOL,  T NAGAR,  CHENNAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7416",
            "district": "3",
            "Block": "304",
            "label": "C A S DR P VENKATARAMANA RAO  HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7417",
            "district": "3",
            "Block": "304",
            "label": "CMS TRUSTPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7418",
            "district": "3",
            "Block": "304",
            "label": "SARASWATHI VIDYALAYA SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7419",
            "district": "3",
            "Block": "304",
            "label": "Metro English medium school",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7420",
            "district": "3",
            "Block": "304",
            "label": "MCN HIGHER SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7421",
            "district": "3",
            "Block": "304",
            "label": "CMS KAMARAJAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7422",
            "district": "3",
            "Block": "304",
            "label": "CHS RANGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7423",
            "district": "3",
            "Block": "304",
            "label": "CMS GANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7424",
            "district": "3",
            "Block": "304",
            "label": "M.G.R HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7425",
            "district": "3",
            "Block": "304",
            "label": "CHENNAI HIGHER SECONDARY SCHOOL PULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7426",
            "district": "3",
            "Block": "304",
            "label": "KARNATAKA SANGHA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7427",
            "district": "3",
            "Block": "304",
            "label": "CHATRAPATHI SHIVAJI DAV SEC SH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7428",
            "district": "3",
            "Block": "304",
            "label": "PCKG GOVT HSS KODAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7429",
            "district": "3",
            "Block": "304",
            "label": "DURGA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7430",
            "district": "3",
            "Block": "304",
            "label": "CHENNAI HIGH SCHOOL KANNIYAPPA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7431",
            "district": "3",
            "Block": "304",
            "label": "GHSS PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7432",
            "district": "3",
            "Block": "304",
            "label": "KENDRIYA VIDYALAYA  ASHOK NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7433",
            "district": "3",
            "Block": "304",
            "label": "GOVT GIRLS HR SEC SCHOOL ASHOK NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23675",
            "district": "3",
            "Block": "415",
            "label": "Vinod Vijay Vidyalaya Matric Higher secondary school ",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7434",
            "district": "3",
            "Block": "304",
            "label": "VRUKSHA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7435",
            "district": "3",
            "Block": "304",
            "label": "JAWAHAR HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7436",
            "district": "3",
            "Block": "304",
            "label": "DR. K.K. NIRMALA GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7437",
            "district": "3",
            "Block": "304",
            "label": "ANJUHAM HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7438",
            "district": "3",
            "Block": "304",
            "label": "SRI RKM HR SEC SCHOOL MAIN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7439",
            "district": "3",
            "Block": "304",
            "label": "SHRINE VELANKANNI  SS SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7440",
            "district": "3",
            "Block": "304",
            "label": "SRI RKM SV MIDDLE SCH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7441",
            "district": "3",
            "Block": "304",
            "label": "SRI RKM BOYS HR SEC SCHOOL SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7442",
            "district": "3",
            "Block": "304",
            "label": "SHRI M B JAIN HR SEC SCH",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7443",
            "district": "3",
            "Block": "304",
            "label": "SMT VASANTBEN CHANDUBHAI SHAH MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7444",
            "district": "3",
            "Block": "304",
            "label": "SREE NARAYANA MISSION SS SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7445",
            "district": "3",
            "Block": "304",
            "label": "CHS KANNAMMAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7446",
            "district": "3",
            "Block": "304",
            "label": "SRI R K M SARADA VIDYALAYA  GIRLS HR SEC SCHOOL T NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7447",
            "district": "3",
            "Block": "304",
            "label": "GUNTUR SUBBIA GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7448",
            "district": "3",
            "Block": "304",
            "label": "GOVT GIRLS HIGH SCH TNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7449",
            "district": "3",
            "Block": "304",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7450",
            "district": "3",
            "Block": "304",
            "label": "PADMA SESHADRI BALA BHAVAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7451",
            "district": "3",
            "Block": "304",
            "label": "ST ANNES MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7452",
            "district": "3",
            "Block": "304",
            "label": "VANI VIDYALAYA SENIOR SECONDARY & JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7453",
            "district": "3",
            "Block": "304",
            "label": "C D NAYAGAM THEAGARAYANAGAR HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7454",
            "district": "3",
            "Block": "304",
            "label": "SRI RKM MODEL HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7455",
            "district": "3",
            "Block": "304",
            "label": "KAVERI HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7456",
            "district": "3",
            "Block": "304",
            "label": "GENERAL CARIAPPA HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7457",
            "district": "3",
            "Block": "304",
            "label": "AVICHI HSS SALIGRAMAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7458",
            "district": "3",
            "Block": "304",
            "label": "CHSS NESAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7459",
            "district": "3",
            "Block": "304",
            "label": "JAWAHAR VIDYALAYA SR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7460",
            "district": "3",
            "Block": "304",
            "label": "ANNAI VALAN KANNI MAT SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7461",
            "district": "3",
            "Block": "304",
            "label": "JG GOVT GIRLS HSS VIRUGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7462",
            "district": "3",
            "Block": "304",
            "label": "CMS VADAPALANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7463",
            "district": "3",
            "Block": "2",
            "label": "GHSS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7464",
            "district": "3",
            "Block": "2",
            "label": "CHSS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7465",
            "district": "3",
            "Block": "2",
            "label": "CHS JAFFERKHANPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7466",
            "district": "3",
            "Block": "2",
            "label": "CHSS WEST MAMBALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7467",
            "district": "3",
            "Block": "2",
            "label": "SRI AHOBILA MATH ORIENTAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7468",
            "district": "3",
            "Block": "2",
            "label": "CHSS BOYS SAIDAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7469",
            "district": "3",
            "Block": "2",
            "label": "CHSS GIRLS SAIDAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7470",
            "district": "3",
            "Block": "2",
            "label": "VIDYA SAGAR B.M.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7471",
            "district": "3",
            "Block": "2",
            "label": "CHSS CIT NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7472",
            "district": "3",
            "Block": "2",
            "label": "ADVENT CHRISTIAN HIGH SCHOOL, KOTTUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7473",
            "district": "3",
            "Block": "2",
            "label": "AMM MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7474",
            "district": "3",
            "Block": "2",
            "label": "KENDRIYA VIDYALAYA, IIT",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7475",
            "district": "3",
            "Block": "2",
            "label": "KENDRIYA VIDYALAYA, CLRI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7476",
            "district": "3",
            "Block": "2",
            "label": "CHENNAI HIGH SCHOOL, KOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7477",
            "district": "3",
            "Block": "2",
            "label": "JAIGOPAL GARODIA GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SAIDAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7478",
            "district": "3",
            "Block": "2",
            "label": "ST ANTONYS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7479",
            "district": "3",
            "Block": "2",
            "label": "OUR LADY OF HEALTH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7480",
            "district": "3",
            "Block": "2",
            "label": "YMCA COLLEGE SPORTS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7481",
            "district": "3",
            "Block": "2",
            "label": "ST. FRANCIS XAVIER HIGH SCHOOL, LITTLEMOUNT,CHENNAI-15",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7482",
            "district": "3",
            "Block": "2",
            "label": "CHRISTIAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7483",
            "district": "3",
            "Block": "2",
            "label": "LIONS CLUB OF GUINDY LABOUR COLONY HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7484",
            "district": "3",
            "Block": "2",
            "label": "CHSS MADUVANKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7485",
            "district": "3",
            "Block": "2",
            "label": "LADY NYE MRT-1 SPECIAL  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7486",
            "district": "3",
            "Block": "2",
            "label": "ARSHA VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7487",
            "district": "3",
            "Block": "2",
            "label": "THE ASHRAM TASSC MODEL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7488",
            "district": "3",
            "Block": "2",
            "label": "ALPHA SCHOOL CIT NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7489",
            "district": "3",
            "Block": "2",
            "label": "AVVAI ILLAM T.V.R GIRLS' HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7490",
            "district": "3",
            "Block": "2",
            "label": "RANI LADY MEYYAMMAI HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7491",
            "district": "3",
            "Block": "2",
            "label": "KUMARA RAJAH MUTHIAH BOYS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7492",
            "district": "3",
            "Block": "2",
            "label": "BALA VIDYA MANDIR ADYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7493",
            "district": "3",
            "Block": "2",
            "label": "ST MICHAELS ACADEMY MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7494",
            "district": "3",
            "Block": "2",
            "label": "ST LOUIS INSTITUTE FOR THE DEAF AND THE BLIND",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7495",
            "district": "3",
            "Block": "2",
            "label": "GHSS VELACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7496",
            "district": "3",
            "Block": "2",
            "label": "ARIGNAR ANNA GOVT HSS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7497",
            "district": "3",
            "Block": "2",
            "label": "ADVENT CHRISTIAN  HIGH  SCHOOL, VELACHERY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7498",
            "district": "3",
            "Block": "2",
            "label": "ST ANTONY'S HIGHER SECONDARY SCHOOL VELACHERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7499",
            "district": "3",
            "Block": "2",
            "label": "ST MARK'S HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7500",
            "district": "3",
            "Block": "195",
            "label": "SACRED HEART GIRLS HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7501",
            "district": "3",
            "Block": "195",
            "label": "GANAPATHY IYER GIRLS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7502",
            "district": "3",
            "Block": "195",
            "label": "WESLEY HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7503",
            "district": "3",
            "Block": "195",
            "label": "GOPALAPURAM BOYS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7504",
            "district": "3",
            "Block": "195",
            "label": "GOVT HSS NANDANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7505",
            "district": "3",
            "Block": "195",
            "label": "CHSS ALWARPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7506",
            "district": "3",
            "Block": "195",
            "label": "THE CHILDREN'S  GARDEN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7507",
            "district": "3",
            "Block": "195",
            "label": "KESARI HGHER SECONDARY SCHOOL MYLAPORE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7508",
            "district": "3",
            "Block": "195",
            "label": "VIDYA MANDIR SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7509",
            "district": "3",
            "Block": "195",
            "label": "SAVITRIAMMAL ORIENTAL HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7510",
            "district": "3",
            "Block": "195",
            "label": "P.S HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7511",
            "district": "3",
            "Block": "195",
            "label": "PS SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7512",
            "district": "3",
            "Block": "195",
            "label": "ALL SAINTS' MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7513",
            "district": "3",
            "Block": "195",
            "label": "RAMAKRISHNA MISSION RESIDENTIAL HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7514",
            "district": "3",
            "Block": "195",
            "label": "KALEIDOSCOPE LEARNING CENTER",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7515",
            "district": "3",
            "Block": "195",
            "label": "CHS KP ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7516",
            "district": "3",
            "Block": "195",
            "label": "CSI  HR .SEC.SCHOOL FOR THE DEAF",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7517",
            "district": "3",
            "Block": "195",
            "label": "ST FRANCIS XAVIER MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23411",
            "district": "3",
            "Block": "2",
            "label": "nehru high school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7518",
            "district": "3",
            "Block": "195",
            "label": "LADY SIVASWAMI AYYAR GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7519",
            "district": "3",
            "Block": "195",
            "label": "DOMINIC SAVIO MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7520",
            "district": "3",
            "Block": "195",
            "label": "SIR SIVASAMI KALALAYA SENIOR SECONDARY SCHOOL,MYLAPORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7521",
            "district": "3",
            "Block": "195",
            "label": "CHS CANAL BANK ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7522",
            "district": "3",
            "Block": "195",
            "label": "ST RAPHAEL'S GIRLS HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7523",
            "district": "3",
            "Block": "195",
            "label": "SRI RAJALAKSHMI MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7524",
            "district": "3",
            "Block": "195",
            "label": "SIR SIVASWAMI KALALAYA HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7525",
            "district": "3",
            "Block": "195",
            "label": "RANI MEYYAMMAI GIRLS' HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7526",
            "district": "3",
            "Block": "195",
            "label": "RAJAH MUTHIAH HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7527",
            "district": "3",
            "Block": "195",
            "label": "CHETTINAD HARI SHREE VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7528",
            "district": "3",
            "Block": "195",
            "label": "CMS SANTHOME",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7529",
            "district": "3",
            "Block": "195",
            "label": "SANTHOME HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7530",
            "district": "3",
            "Block": "195",
            "label": "SISHYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7531",
            "district": "3",
            "Block": "195",
            "label": "CHS KAMARAJ AVENUE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7532",
            "district": "3",
            "Block": "195",
            "label": "St. John's English School & Jr College",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7533",
            "district": "3",
            "Block": "195",
            "label": "OLCOTT MEMORIAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7534",
            "district": "3",
            "Block": "195",
            "label": "ST BRITTO'S ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7535",
            "district": "3",
            "Block": "195",
            "label": "SRI RAJALAKSHMI MATRIC HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7536",
            "district": "3",
            "Block": "195",
            "label": "CHSS VELACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7537",
            "district": "3",
            "Block": "195",
            "label": "THE SPASTIC SOCIETY OF TAMIL NADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7538",
            "district": "3",
            "Block": "195",
            "label": "CHSS THARAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7539",
            "district": "3",
            "Block": "195",
            "label": "ARVIND MARTICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7540",
            "district": "3",
            "Block": "195",
            "label": "EVAANS MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7541",
            "district": "3",
            "Block": "195",
            "label": "CHSS THIRUVANMIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7542",
            "district": "3",
            "Block": "195",
            "label": "THE HINDU SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7543",
            "district": "3",
            "Block": "195",
            "label": "CHILDREN'S PARADISE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7544",
            "district": "3",
            "Block": "195",
            "label": "SWABODHINI SCHOOL FOR  SPECIAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7545",
            "district": "3",
            "Block": "195",
            "label": "CSI ST.EBBAS GIRLS HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20890",
            "district": "3",
            "Block": "304",
            "label": "MAHATMA GANDHI VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20907",
            "district": "3",
            "Block": "69",
            "label": "SUFA MATRICULATION HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20988",
            "district": "3",
            "Block": "240",
            "label": "INDIRA VIVEKANANDA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21007",
            "district": "3",
            "Block": "69",
            "label": "SRI MAGESHWARA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21137",
            "district": "3",
            "Block": "2",
            "label": "UNITY PUBLIC SCHOOL KOTTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21146",
            "district": "3",
            "Block": "237",
            "label": "RAMAKRISHNA MATRIC H.S. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21175",
            "district": "3",
            "Block": "195",
            "label": "ST. JOHN S SR SEC. SCHOOL & JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21350",
            "district": "3",
            "Block": "240",
            "label": "SEVENTH DAY ADVENTIST HR. SEC SCHOOL (ICSE & ISE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21351",
            "district": "3",
            "Block": "62",
            "label": "DOVETON GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21352",
            "district": "3",
            "Block": "62",
            "label": "DOVETON BOYS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21490",
            "district": "3",
            "Block": "259",
            "label": "ADW HIGH SCHOOL THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21491",
            "district": "3",
            "Block": "259",
            "label": "ADW VIRUGAMBAKKAM HIGH SCHOOL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21523",
            "district": "3",
            "Block": "240",
            "label": "M P DEVADAS HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21808",
            "district": "3",
            "Block": "237",
            "label": "EVERWIN VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21809",
            "district": "3",
            "Block": "2",
            "label": "ST. MICHAEL'S  ACADEMY SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21887",
            "district": "3",
            "Block": "62",
            "label": "SIR MUTHA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21939",
            "district": "3",
            "Block": "62",
            "label": "DON BOSCO SCHOOL OF EXCELLENCE EGMORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22007",
            "district": "3",
            "Block": "240",
            "label": "SRI SUSHWANI MATHA JAIN VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22008",
            "district": "3",
            "Block": "237",
            "label": "KRM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22009",
            "district": "3",
            "Block": "237",
            "label": "THE TEMPLE-SCHOOL OF ENLIGHTENMENT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22010",
            "district": "3",
            "Block": "259",
            "label": "SBOA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22011",
            "district": "3",
            "Block": "259",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22012",
            "district": "3",
            "Block": "62",
            "label": "UNION CHRISTIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22013",
            "district": "3",
            "Block": "304",
            "label": "TRINITY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22014",
            "district": "3",
            "Block": "2",
            "label": "DAVe BABA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22015",
            "district": "3",
            "Block": "2",
            "label": "ST.PATRICK'S HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22016",
            "district": "3",
            "Block": "2",
            "label": "AGR GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22017",
            "district": "3",
            "Block": "195",
            "label": "ST.BEDE'S ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22018",
            "district": "3",
            "Block": "195",
            "label": "ISWARI PRASAD DATTATREYA SPECIAL SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "22333",
            "district": "3",
            "Block": "69",
            "label": "VELANKANNI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22334",
            "district": "3",
            "Block": "237",
            "label": "N.S.P. VIDHYAPEETAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22335",
            "district": "3",
            "Block": "259",
            "label": "MAHALASHMI VIDHYA MANDHIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22336",
            "district": "3",
            "Block": "195",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22683",
            "district": "3",
            "Block": "270",
            "label": "A.A.PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22684",
            "district": "3",
            "Block": "304",
            "label": "PEACE ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22685",
            "district": "3",
            "Block": "195",
            "label": "D.A.V. BABA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22692",
            "district": "3",
            "Block": "270",
            "label": "NARAYANA E - TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22693",
            "district": "3",
            "Block": "368",
            "label": "SSH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22845",
            "district": "3",
            "Block": "62",
            "label": "MCC PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22852",
            "district": "3",
            "Block": "69",
            "label": "DANIEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22919",
            "district": "3",
            "Block": "69",
            "label": "NARAYANA e - TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22959",
            "district": "3",
            "Block": "304",
            "label": "SHRINE VAILANKANNI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22962",
            "district": "3",
            "Block": "259",
            "label": "CSI EWART GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22970",
            "district": "3",
            "Block": "259",
            "label": "C.S.I. BAIN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22971",
            "district": "3",
            "Block": "195",
            "label": "NAVADISHA MONTESSORI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22972",
            "district": "3",
            "Block": "270",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22984",
            "district": "3",
            "Block": "237",
            "label": "ARYA SAMAJ HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23004",
            "district": "3",
            "Block": "69",
            "label": "SMK VIDHYASHRAM & JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23005",
            "district": "3",
            "Block": "69",
            "label": "VELANKANNI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23006",
            "district": "3",
            "Block": "195",
            "label": "NARAYANA OLYMPIAD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23009",
            "district": "3",
            "Block": "62",
            "label": "AGARWAL VIDYALAYA AND JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23021",
            "district": "3",
            "Block": "2",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23080",
            "district": "3",
            "Block": "240",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23081",
            "district": "3",
            "Block": "237",
            "label": "KULAPATI DR.S.BALAKRISHNA JOSHI GURRUKULAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23082",
            "district": "3",
            "Block": "237",
            "label": "SHRI KRISHNASWAMY VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23083",
            "district": "3",
            "Block": "62",
            "label": "LADY ANDAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23207",
            "district": "3",
            "Block": "237",
            "label": "ICF VIDYANIKETAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23264",
            "district": "3",
            "Block": "259",
            "label": "ST CHRISTOPHER'S ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23276",
            "district": "3",
            "Block": "240",
            "label": "RAASHIDH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23345",
            "district": "3",
            "Block": "62",
            "label": "P.N.D. ADARSH VIDYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23346",
            "district": "3",
            "Block": "240",
            "label": "THE SHRI RAM UNIVERSAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23424",
            "district": "3",
            "Block": "62",
            "label": "Olive International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23447",
            "district": "3",
            "Block": "2",
            "label": "A.M.M SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23448",
            "district": "3",
            "Block": "2",
            "label": "Centre of Academic Excellence Saidapet, Chennai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23449",
            "district": "3",
            "Block": "2",
            "label": "K.S.N SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23450",
            "district": "3",
            "Block": "414",
            "label": "ORCHIDS THE INTERNATIONAL SCHOOL, THURAIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23451",
            "district": "3",
            "Block": "414",
            "label": "SAIRAM LEOMUTHU PUBLIC SCHOOL VENGAIVASAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23452",
            "district": "3",
            "Block": "414",
            "label": "ADHITHYA PUBLIC SCHOOL, SHOLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23453",
            "district": "3",
            "Block": "415",
            "label": "JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23454",
            "district": "3",
            "Block": "62",
            "label": "Government Model School, Chennai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23455",
            "district": "3",
            "Block": "416",
            "label": "AVM RAJESWARI - THE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23456",
            "district": "3",
            "Block": "270",
            "label": "SANA SMART MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23457",
            "district": "3",
            "Block": "417",
            "label": "MANOJ KIRAN SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23458",
            "district": "3",
            "Block": "418",
            "label": "CHENNAI DISTRICT GOVERNMENT MODEL SCHOOL, CHENNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23459",
            "district": "3",
            "Block": "418",
            "label": "SRIRAM VIDHYA MANDIR SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23704",
            "district": "3",
            "Block": "2",
            "label": "ECI MAT HSS",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23705",
            "district": "3",
            "Block": "2",
            "label": "CHENNAI MIDDLE SCHOOL  MADIPAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23706",
            "district": "3",
            "Block": "2",
            "label": "THE PSBB MILLENNIUM SCHOOL Thazhambur",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23707",
            "district": "3",
            "Block": "2",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23708",
            "district": "3",
            "Block": "2",
            "label": "NARAYANA OLYMPIAD SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23709",
            "district": "3",
            "Block": "2",
            "label": "VELS GLOBAL SCHOOL NANDAMBAKKAM",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23710",
            "district": "3",
            "Block": "2",
            "label": "SAN ACDEMY",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23990",
            "district": "3",
            "Block": "304",
            "label": "SANDIPANI VIDYALAYA",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24095",
            "district": "3",
            "Block": "415",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24096",
            "district": "3",
            "Block": "415",
            "label": "ST JOHNS MAT HSS",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24097",
            "district": "3",
            "Block": "415",
            "label": "Shri Krishnaswamy Matric Hr. Sec. School",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24098",
            "district": "3",
            "Block": "415",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24099",
            "district": "3",
            "Block": "416",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24100",
            "district": "3",
            "Block": "417",
            "label": "SRIVATSA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24101",
            "district": "3",
            "Block": "418",
            "label": "NARAYANA e - TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24102",
            "district": "3",
            "Block": "418",
            "label": "VELANKANNI PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24103",
            "district": "3",
            "Block": "270",
            "label": "ST ANNE'S GIRLS HSS",
            "Sch_type": "Partially AidedHigher Secondary School"
        },
        {
            "key": "24104",
            "district": "3",
            "Block": "419",
            "label": "CSI MIDDLE SCHOOL",
            "Sch_type": "Fully AidedMiddle School"
        },
        {
            "key": "24105",
            "district": "3",
            "Block": "419",
            "label": "Good Shepherd Matric Higher Secondary School",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24106",
            "district": "3",
            "Block": "419",
            "label": "Mahila Vidyalaya Nursery & Primary School",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24107",
            "district": "3",
            "Block": "420",
            "label": "LITTLE LAMBS MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24108",
            "district": "3",
            "Block": "420",
            "label": "EVERWIN VIDHYASHRAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24109",
            "district": "3",
            "Block": "270",
            "label": "SRI CHAITANYA TECHNO SCHOOL ROYAPURAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24110",
            "district": "3",
            "Block": "270",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24111",
            "district": "3",
            "Block": "420",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24113",
            "district": "3",
            "Block": "419",
            "label": "M.CT.M CHIDAMBARAM CHETTYAR SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24114",
            "district": "3",
            "Block": "419",
            "label": "SIR SIVASWAMI KALALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24115",
            "district": "3",
            "Block": "420",
            "label": "NARAYANA e-TECHNO SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "4": [
        {
            "key": "23351",
            "district": "4",
            "Block": "52",
            "label": "Dharmamurthi sri SRPPS Vidhyalaya mat. School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        { 
            "key":"24137", "district":"4", 
            "Block":"302", "label": "ITERACY MISSION INTERNATIONAL School", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "23662",
            "district": "4",
            "Block": "245",
            "label": "St Mary's Matriculation School Edayarpalayam Coimbatore",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23352",
            "district": "4",
            "Block": "245",
            "label": "APG Matric Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23357",
            "district": "4",
            "Block": "245",
            "label": "Shri Meenakshi Matric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "377",
            "district": "4",
            "Block": "19",
            "label": "K.G. MATRICULATION HR SEC SCHOOL, ANNUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "400",
            "district": "4",
            "Block": "242",
            "label": "SRI KRISHNAA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "436",
            "district": "4",
            "Block": "349",
            "label": "GRAHAM STAINES MATRIC HIGHER SECONDARY SCHOOL,SIKKARAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "440",
            "district": "4",
            "Block": "302",
            "label": "A.R.C MHSS - KARUMATHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "450",
            "district": "4",
            "Block": "302",
            "label": "RATHNAVEL SUBRAMANIAM MATRICULATION HIGHER SECONDARY SCHOOL, SULUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "468",
            "district": "4",
            "Block": "52",
            "label": "RASAKONDALAR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "500",
            "district": "4",
            "Block": "302",
            "label": "VIJAYA VIDHYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "520",
            "district": "4",
            "Block": "349",
            "label": "SRI SOWDESWARI VIDYALAYA MODEL MATRIC Hr SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "524",
            "district": "4",
            "Block": "245",
            "label": "SREE NARAYANA MISSION MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23401",
            "district": "4",
            "Block": "302",
            "label": "Kathir Vidhyashram Matriculation higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "536",
            "district": "4",
            "Block": "302",
            "label": "KENNEDY MAT HSS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "540",
            "district": "4",
            "Block": "107",
            "label": "L.E.F. CHRISTIAN MATRICULATION SCHOOL, BEHIND HOUSING UNIT, METTUPALAYAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "561",
            "district": "4",
            "Block": "242",
            "label": "IDEAL MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "591",
            "district": "4",
            "Block": "248",
            "label": "R.V.S MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "619",
            "district": "4",
            "Block": "349",
            "label": "SRI SARGURU MATRICULATION  SCHOOL  THEETIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "624",
            "district": "4",
            "Block": "242",
            "label": "S.V.NAGAR VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "627",
            "district": "4",
            "Block": "245",
            "label": "GURUKULAM HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "639",
            "district": "4",
            "Block": "52",
            "label": "CARMEL GARDEN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "664",
            "district": "4",
            "Block": "245",
            "label": "SBOA MATRICULATION HR SEC SCHOOL,CHOKKAMPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "689",
            "district": "4",
            "Block": "245",
            "label": "MOTHER LAAND MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "703",
            "district": "4",
            "Block": "302",
            "label": "MICHAEL JOB MATRICULATION.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "735",
            "district": "4",
            "Block": "242",
            "label": "DR. P.G.V MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "743",
            "district": "4",
            "Block": "52",
            "label": "BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "751",
            "district": "4",
            "Block": "19",
            "label": "ST. ANTONY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "754",
            "district": "4",
            "Block": "248",
            "label": "VIDYA NETHRRA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "755",
            "district": "4",
            "Block": "279",
            "label": "ADWAITH G.N.S MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "765",
            "district": "4",
            "Block": "52",
            "label": "ALVERNIA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "782",
            "district": "4",
            "Block": "279",
            "label": "PERKS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "786",
            "district": "4",
            "Block": "242",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SAMICHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "817",
            "district": "4",
            "Block": "302",
            "label": "PUMS S.L.V.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "821",
            "district": "4",
            "Block": "242",
            "label": "JAYCEE HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "827",
            "district": "4",
            "Block": "242",
            "label": "CHINKUMAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "830",
            "district": "4",
            "Block": "247",
            "label": "SUBASH VIDHYA MANDIR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "852",
            "district": "4",
            "Block": "52",
            "label": "SUGUNA RIP V MATRICULATION  HIGHER.SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "872",
            "district": "4",
            "Block": "349",
            "label": "CHINMAYA VIDHYALAYA CBSE SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "916",
            "district": "4",
            "Block": "248",
            "label": "P.L.M.School,POLLACHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1021",
            "district": "4",
            "Block": "245",
            "label": "CMS VIDYA MANDIR MATRIC HR SEC SCHOOL, MANIAKARAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1064",
            "district": "4",
            "Block": "245",
            "label": "VASAVI VIDYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1065",
            "district": "4",
            "Block": "242",
            "label": "PUMS EDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1077",
            "district": "4",
            "Block": "242",
            "label": "EXCEL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1092",
            "district": "4",
            "Block": "245",
            "label": "PRESENTATION CONVENT MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1120",
            "district": "4",
            "Block": "279",
            "label": "LITTLE FLOWER MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1124",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ERIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1162",
            "district": "4",
            "Block": "279",
            "label": "ANNAI VELANKANNI MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1172",
            "district": "4",
            "Block": "242",
            "label": "SRI A.RAMASAMY MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1183",
            "district": "4",
            "Block": "349",
            "label": "CHINMAYA VIDYALAYA MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1196",
            "district": "4",
            "Block": "242",
            "label": "PUMS KASTHURINAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1211",
            "district": "4",
            "Block": "52",
            "label": "SREE NARAYANA MISSION MHS SAIBABA COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1214",
            "district": "4",
            "Block": "126",
            "label": "PUMS SINGAIYANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1218",
            "district": "4",
            "Block": "279",
            "label": "ARM MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1232",
            "district": "4",
            "Block": "107",
            "label": "NATIONAL MATRIC  HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1239",
            "district": "4",
            "Block": "52",
            "label": "ST. THERESA GIRLS AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1246",
            "district": "4",
            "Block": "107",
            "label": "AMBAL MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1251",
            "district": "4",
            "Block": "52",
            "label": "V.C.V SHISHU VIDYODAYA MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1320",
            "district": "4",
            "Block": "245",
            "label": "ASHRAM MATRICULATION HR SEC SCHOOL KOVAIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1321",
            "district": "4",
            "Block": "349",
            "label": "ISHA VIDHYA MATRIC HIGHER SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1336",
            "district": "4",
            "Block": "52",
            "label": "CMS MNG STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1355",
            "district": "4",
            "Block": "302",
            "label": "M. NANJAPPA CHETTIAR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1358",
            "district": "4",
            "Block": "242",
            "label": "AVB MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1377",
            "district": "4",
            "Block": "349",
            "label": "PREMIER VIDYAA VIKASH MATRIC HIGHER SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1405",
            "district": "4",
            "Block": "279",
            "label": "G.RAMASWAMY NAIDU MATRIC.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1406",
            "district": "4",
            "Block": "245",
            "label": "SRI SOWDESWARI VIDYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1412",
            "district": "4",
            "Block": "52",
            "label": "ST. JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL,COIMBATORE-641018",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1417",
            "district": "4",
            "Block": "302",
            "label": "PUM SCHOOL, UTHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1424",
            "district": "4",
            "Block": "279",
            "label": "CMS UPPILIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1442",
            "district": "4",
            "Block": "302",
            "label": "KOVAI MARUTHAMALAI MATRICULATION SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1482",
            "district": "4",
            "Block": "242",
            "label": "BHARATIYA VIDYA BHAVAN MATRIC HIGHER SECONDARY SCHOOL, AJJANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1483",
            "district": "4",
            "Block": "242",
            "label": "PUMS KALAPPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1494",
            "district": "4",
            "Block": "52",
            "label": "KAMARAJAR MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1495",
            "district": "4",
            "Block": "279",
            "label": "SRI RAMAKRISHNA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1536",
            "district": "4",
            "Block": "242",
            "label": "VIDYA VIKASINI MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1545",
            "district": "4",
            "Block": "242",
            "label": "BRINDAVAN VIDHYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1562",
            "district": "4",
            "Block": "245",
            "label": "CMS MATRICULATION HR SEC SCHOOL, GANAPATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1567",
            "district": "4",
            "Block": "248",
            "label": "PUMS RANGASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1575",
            "district": "4",
            "Block": "247",
            "label": "PUMS KONDEGOUNDEN PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1592",
            "district": "4",
            "Block": "13",
            "label": "PUMS ARTHANARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1599",
            "district": "4",
            "Block": "302",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELACHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1620",
            "district": "4",
            "Block": "107",
            "label": "MADONNA MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1644",
            "district": "4",
            "Block": "242",
            "label": "ST.PAULS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1651",
            "district": "4",
            "Block": "52",
            "label": "ATHAR JAMATH MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1663",
            "district": "4",
            "Block": "302",
            "label": "AMIRTHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1684",
            "district": "4",
            "Block": "242",
            "label": "PUMS VEERAKERALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1708",
            "district": "4",
            "Block": "247",
            "label": "PUMS THOPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1728",
            "district": "4",
            "Block": "242",
            "label": "PUMS KONDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1818",
            "district": "4",
            "Block": "242",
            "label": "KEARTIMAN ACADEMY MATRIC &HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1835",
            "district": "4",
            "Block": "242",
            "label": "YUVABHARATHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1858",
            "district": "4",
            "Block": "248",
            "label": "MUNICIPAL MIDDLE SCHOOL,,ABT ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1859",
            "district": "4",
            "Block": "52",
            "label": "ST MICHAEL'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1862",
            "district": "4",
            "Block": "126",
            "label": "PUMS SOKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1867",
            "district": "4",
            "Block": "279",
            "label": "VENKATALAKSHMI MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1872",
            "district": "4",
            "Block": "242",
            "label": "VEL INTERNATIONAL PUBLIC SCHOOL, KNG PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1909",
            "district": "4",
            "Block": "242",
            "label": "VIDYA VANAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1911",
            "district": "4",
            "Block": "52",
            "label": "SRI VISWESWARA VIDHYALYA  MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1923",
            "district": "4",
            "Block": "349",
            "label": "NAGINI VIDYALAYA MATRIC HR SEC SCHOOL, KALIKKANAICKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1954",
            "district": "4",
            "Block": "242",
            "label": "GANDHI ADIGAL KALVI NIRUVANAM MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1970",
            "district": "4",
            "Block": "126",
            "label": "KIDS PARK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1972",
            "district": "4",
            "Block": "302",
            "label": "PUMS K.RAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1981",
            "district": "4",
            "Block": "279",
            "label": "GRG MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2003",
            "district": "4",
            "Block": "279",
            "label": "SRI JAYENDRA SARASWATHY VIDYALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2007",
            "district": "4",
            "Block": "156",
            "label": "ST.ANN'S MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2024",
            "district": "4",
            "Block": "242",
            "label": "GSR MATRIC HR SEC SCHOOL KAMATCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2039",
            "district": "4",
            "Block": "302",
            "label": "KALAIVANI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2058",
            "district": "4",
            "Block": "279",
            "label": "HINDUSTHAN MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2082",
            "district": "4",
            "Block": "245",
            "label": "INFANT JESUS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2114",
            "district": "4",
            "Block": "301",
            "label": "PUMS PERIYAKUYILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2117",
            "district": "4",
            "Block": "107",
            "label": "PUMS KARAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2126",
            "district": "4",
            "Block": "242",
            "label": "PUMS SUBRAMANIAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2130",
            "district": "4",
            "Block": "245",
            "label": "SANGAMAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2134",
            "district": "4",
            "Block": "13",
            "label": "K.M.G MATRIC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2136",
            "district": "4",
            "Block": "242",
            "label": "ST JOHN'S MATRIC HR SEC SCHOOL, PRESS COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2161",
            "district": "4",
            "Block": "349",
            "label": "SRI RAGAVENDRA VIDYALAYA MATRIC. HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2168",
            "district": "4",
            "Block": "248",
            "label": "GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2169",
            "district": "4",
            "Block": "52",
            "label": "CHINMAYA VIDYALA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2204",
            "district": "4",
            "Block": "245",
            "label": "ELLAPPA MATRICULATION SCHOOL, KUNIAMUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2213",
            "district": "4",
            "Block": "248",
            "label": "PUMS, KUNJIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2229",
            "district": "4",
            "Block": "107",
            "label": "SRSI MATRIC. HR SEC SCHOOL, KARAMADAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2230",
            "district": "4",
            "Block": "245",
            "label": "T V SEKHARAN MEMORIAL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2233",
            "district": "4",
            "Block": "52",
            "label": "HOLY FAMILY CONVENT MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2235",
            "district": "4",
            "Block": "245",
            "label": "MEWS MATRICULATION SCHOOL POTHANUR MAIN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2237",
            "district": "4",
            "Block": "247",
            "label": "PUMS VADAKKIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2246",
            "district": "4",
            "Block": "302",
            "label": "PUMS KAMACHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2254",
            "district": "4",
            "Block": "156",
            "label": "Dr.V.GENGUSWAMY NAIDU MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2261",
            "district": "4",
            "Block": "349",
            "label": "EVANGELINE MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2275",
            "district": "4",
            "Block": "349",
            "label": "SREE RAMAKRISHNA VIDHYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2299",
            "district": "4",
            "Block": "242",
            "label": "BAPTIST ACADEMY MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2301",
            "district": "4",
            "Block": "242",
            "label": "ANGAPPA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2333",
            "district": "4",
            "Block": "13",
            "label": "PUMS NARASIMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2364",
            "district": "4",
            "Block": "245",
            "label": "EINSTEIN PUBLIC MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2377",
            "district": "4",
            "Block": "349",
            "label": "PUMS IRUTTUPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2467",
            "district": "4",
            "Block": "107",
            "label": "HOLY ANGELS MAT HSS , SELVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2470",
            "district": "4",
            "Block": "247",
            "label": "PKD MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2471",
            "district": "4",
            "Block": "245",
            "label": "MJ VINCENT MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2485",
            "district": "4",
            "Block": "242",
            "label": "PUMS BETTADHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2491",
            "district": "4",
            "Block": "248",
            "label": "MMS VENKATRAMANAN RD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2496",
            "district": "4",
            "Block": "301",
            "label": "PUMS PAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2513",
            "district": "4",
            "Block": "247",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2518",
            "district": "4",
            "Block": "248",
            "label": "OUR LADY OF LOURDES MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2521",
            "district": "4",
            "Block": "248",
            "label": "MUNICIPAL MIDDLE SCHOOL RAMAKRISHNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2532",
            "district": "4",
            "Block": "279",
            "label": "SRI RAMAKRISHNA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2534",
            "district": "4",
            "Block": "52",
            "label": "VIVEKALAYA MATRICULATION  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2535",
            "district": "4",
            "Block": "107",
            "label": "PUM SCHOOL, IRUMBARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2539",
            "district": "4",
            "Block": "242",
            "label": "HOLY CROSS MATRICULATION HR SEC SCHOOL. KANUVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2540",
            "district": "4",
            "Block": "302",
            "label": "SARASWATHI VIDYALAYA MATRIC SCHOOL CHINTHAMANI PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2547",
            "district": "4",
            "Block": "279",
            "label": "CMS KRISHNARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2549",
            "district": "4",
            "Block": "19",
            "label": "SWAMI VIVEKANANDA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2550",
            "district": "4",
            "Block": "279",
            "label": "KALAIMAGAL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2569",
            "district": "4",
            "Block": "279",
            "label": "VICTORY VIDHYALAYA MAT. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2570",
            "district": "4",
            "Block": "126",
            "label": "VIVEK VIDYALAYA MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2574",
            "district": "4",
            "Block": "248",
            "label": "MMS,VSRA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2579",
            "district": "4",
            "Block": "245",
            "label": "MILTON MATRICULATION SCHOOL, CHOKKAMPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2583",
            "district": "4",
            "Block": "126",
            "label": "VIGHNESWAR VIDHYA MANDHIR MAT.HR. SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2586",
            "district": "4",
            "Block": "242",
            "label": "PUMS RAKKIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2592",
            "district": "4",
            "Block": "242",
            "label": "PUMS THIPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2615",
            "district": "4",
            "Block": "13",
            "label": "SRI LAXMI VIDYA BAVAN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2630",
            "district": "4",
            "Block": "247",
            "label": "PUMS ACHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2640",
            "district": "4",
            "Block": "245",
            "label": "PUMS PERUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2671",
            "district": "4",
            "Block": "302",
            "label": "PUMS THOTTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2712",
            "district": "4",
            "Block": "242",
            "label": "PUMS PAPPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2720",
            "district": "4",
            "Block": "126",
            "label": "PUMS SULAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2723",
            "district": "4",
            "Block": "242",
            "label": "PUMS MATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2728",
            "district": "4",
            "Block": "302",
            "label": "K.M.M. MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2746",
            "district": "4",
            "Block": "242",
            "label": "PUMS PERIYANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2762",
            "district": "4",
            "Block": "248",
            "label": "MMS, MARAPETTAI ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2767",
            "district": "4",
            "Block": "245",
            "label": "CMS RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2776",
            "district": "4",
            "Block": "242",
            "label": "INFANT JESUS CONVENT MATRIC HSS,THEKKUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2781",
            "district": "4",
            "Block": "248",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SULEESWARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2786",
            "district": "4",
            "Block": "52",
            "label": "MANBAUL ULOOM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2829",
            "district": "4",
            "Block": "302",
            "label": "PUMS NALLAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2834",
            "district": "4",
            "Block": "13",
            "label": "PUMS PONGALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2879",
            "district": "4",
            "Block": "242",
            "label": "SRI RANGANATHAR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2894",
            "district": "4",
            "Block": "279",
            "label": "A B C MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2898",
            "district": "4",
            "Block": "13",
            "label": "KANDASAMY MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2918",
            "district": "4",
            "Block": "248",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL, KARATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2942",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT MIDDLE SCHOOL ,SHOLAYAR EST.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2958",
            "district": "4",
            "Block": "301",
            "label": "PUMS BOGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2960",
            "district": "4",
            "Block": "126",
            "label": "PUMS SIKKALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2978",
            "district": "4",
            "Block": "386",
            "label": "AMS INJIPARAI LD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2984",
            "district": "4",
            "Block": "13",
            "label": "PUMS MANJANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2986",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYAT UNION MIDDLE SCHOOLSIRUKUNDRA L D",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2994",
            "district": "4",
            "Block": "52",
            "label": "CMS TATABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3001",
            "district": "4",
            "Block": "13",
            "label": "PUMS SUBBEGOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3034",
            "district": "4",
            "Block": "13",
            "label": "PUMS VAZHAIKOMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3037",
            "district": "4",
            "Block": "386",
            "label": "AIDED MIDDLE SCHOOL-KARAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3040",
            "district": "4",
            "Block": "52",
            "label": "TRINITY MATRIC H S S",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3044",
            "district": "4",
            "Block": "248",
            "label": "PUMS,UNJAVELAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3049",
            "district": "4",
            "Block": "245",
            "label": "KPM MATRICULATION HR SEC SCHOOL, EACHANARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3054",
            "district": "4",
            "Block": "248",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KOLARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3077",
            "district": "4",
            "Block": "301",
            "label": "PUMS KAMMALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23425",
            "district": "4",
            "Block": "19",
            "label": "BENEVOLENCE INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "3078",
            "district": "4",
            "Block": "279",
            "label": "CMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3103",
            "district": "4",
            "Block": "302",
            "label": "PUMS AATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3130",
            "district": "4",
            "Block": "248",
            "label": "PUMS,NAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3186",
            "district": "4",
            "Block": "242",
            "label": "PUMS ONNIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3194",
            "district": "4",
            "Block": "13",
            "label": "PUMS KPM COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3200",
            "district": "4",
            "Block": "279",
            "label": "N M MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3203",
            "district": "4",
            "Block": "248",
            "label": "MUNICIPAL MIDDLE SCHOOL KOTTUR ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3226",
            "district": "4",
            "Block": "247",
            "label": "SWASTIK MAT SCHOOL, CHINNERIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3227",
            "district": "4",
            "Block": "107",
            "label": "PUMS,CHINNATHOTTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3231",
            "district": "4",
            "Block": "279",
            "label": "G.R.DAMODARAN MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3232",
            "district": "4",
            "Block": "13",
            "label": "PUMS, KIZHAVANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3236",
            "district": "4",
            "Block": "248",
            "label": "AROKIAMATHA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3247",
            "district": "4",
            "Block": "248",
            "label": "BHARATIYA VIDYA MANDIR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3260",
            "district": "4",
            "Block": "107",
            "label": "SRI SARASWATHI VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3282",
            "district": "4",
            "Block": "302",
            "label": "SRI KAMACHIAMMAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3285",
            "district": "4",
            "Block": "302",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SUBBARAYANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3294",
            "district": "4",
            "Block": "247",
            "label": "CHANGAVI VIDHYAA MANDIR MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3298",
            "district": "4",
            "Block": "386",
            "label": "BEULA MATRICULATION HR. SEC. SCHOOL, VALPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3305",
            "district": "4",
            "Block": "279",
            "label": "RUBY MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3313",
            "district": "4",
            "Block": "301",
            "label": "ANNAI ARIVAGAM MATRICULATION SCHOOL SENJERIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3327",
            "district": "4",
            "Block": "245",
            "label": "RUKMANI KANNAN VIDYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3330",
            "district": "4",
            "Block": "52",
            "label": "BHARATIYA VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL, R.S.PURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3339",
            "district": "4",
            "Block": "302",
            "label": "SRI SUGA BRAHMA MAHARISHI VIDYALAYAM MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3342",
            "district": "4",
            "Block": "126",
            "label": "AKSHAYA ACADEMY MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3389",
            "district": "4",
            "Block": "242",
            "label": "IMMACULATE HEART OF MARY MATHSS PRESS COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3395",
            "district": "4",
            "Block": "301",
            "label": "SRI DHAYANANDAPURI MATRIC HIGHER SECONDARY SCHOOL, DASANAICKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3401",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, R.PONNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3403",
            "district": "4",
            "Block": "247",
            "label": "VISHWA SISHYA VIDYODAYA SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3406",
            "district": "4",
            "Block": "247",
            "label": "BHARATH VIDHYA NIKKETAN MHSS ACHIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3448",
            "district": "4",
            "Block": "242",
            "label": "PUMS APPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3483",
            "district": "4",
            "Block": "13",
            "label": "PUMS CHINNAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3501",
            "district": "4",
            "Block": "13",
            "label": "PUMS KARIANCHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3503",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THAPPATTAIKILAVAN PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3517",
            "district": "4",
            "Block": "247",
            "label": "PUMS ALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3530",
            "district": "4",
            "Block": "248",
            "label": "AROKIAMATHA MATRICULATION SCHOOL, POLLACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3532",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL URULIKKAL UD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3546",
            "district": "4",
            "Block": "301",
            "label": "PUMS SENJERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3566",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION MIDDLE SCHOOL KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3568",
            "district": "4",
            "Block": "245",
            "label": "PUMS NOYYALPALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3624",
            "district": "4",
            "Block": "107",
            "label": "PUMS,JADAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3650",
            "district": "4",
            "Block": "13",
            "label": "PUMS NARIKKALPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3651",
            "district": "4",
            "Block": "349",
            "label": "PRG MDL SCHOOL, THENNAMANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3671",
            "district": "4",
            "Block": "279",
            "label": "PUMS KOTTAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3682",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL PANNIMADE I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3698",
            "district": "4",
            "Block": "247",
            "label": "Sri Chinthamani Memorial Matric HSS, Thippampatti",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3723",
            "district": "4",
            "Block": "242",
            "label": "PUMS SOMAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3726",
            "district": "4",
            "Block": "248",
            "label": "MMS, BALAGOPALAPURAM ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3739",
            "district": "4",
            "Block": "242",
            "label": "PUMS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3743",
            "district": "4",
            "Block": "107",
            "label": "PUMS,CHIKKARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3755",
            "district": "4",
            "Block": "248",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUNDALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3788",
            "district": "4",
            "Block": "302",
            "label": "PUMS KARAVALI MATHAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3795",
            "district": "4",
            "Block": "248",
            "label": "VISWADEEPTHI MAT HSS, UNJAVELAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3800",
            "district": "4",
            "Block": "13",
            "label": "SHRI VIGNESWARA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23689",
            "district": "4",
            "Block": "156",
            "label": "Sri Meenakshi Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23691",
            "district": "4",
            "Block": "52",
            "label": "velammal bodhi campus",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3804",
            "district": "4",
            "Block": "245",
            "label": "S.E.S MATRICULATION HR SEC SCHOOL, GANAPATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3818",
            "district": "4",
            "Block": "279",
            "label": "PERIYANAYAKI AMMAN  MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3824",
            "district": "4",
            "Block": "248",
            "label": "NACHIAR VIDYALAYAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3838",
            "district": "4",
            "Block": "349",
            "label": "DELHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3845",
            "district": "4",
            "Block": "242",
            "label": "ANNAI VIOLET MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3881",
            "district": "4",
            "Block": "107",
            "label": "PASUPATHIAPPA MIDDLE SCHOOL, KARAMADAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3905",
            "district": "4",
            "Block": "247",
            "label": "PUMS JALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3914",
            "district": "4",
            "Block": "245",
            "label": "EQUITAS GURUKUL MATRIC.HR.SEC .SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3919",
            "district": "4",
            "Block": "52",
            "label": "AL-AZHAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3923",
            "district": "4",
            "Block": "248",
            "label": "ADITYA VIDHYA MANDIR MATRIC. HR. SEC. SCHOOL, SULESWARANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3928",
            "district": "4",
            "Block": "52",
            "label": "RAM NAGAR SUBURBAN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3929",
            "district": "4",
            "Block": "245",
            "label": "SINDHI VIDYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3934",
            "district": "4",
            "Block": "52",
            "label": "PANDIYAN MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3944",
            "district": "4",
            "Block": "279",
            "label": "THAVATHIRU KANDASAMY SWAMIGAL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3975",
            "district": "4",
            "Block": "279",
            "label": "CMS MASAKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4034",
            "district": "4",
            "Block": "279",
            "label": "NATIONAL MODEL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4051",
            "district": "4",
            "Block": "107",
            "label": "METRO MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4071",
            "district": "4",
            "Block": "13",
            "label": "PUMS GENGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4072",
            "district": "4",
            "Block": "248",
            "label": "PUMS SEMBAGOUNDARCOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4117",
            "district": "4",
            "Block": "302",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADUGANKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4124",
            "district": "4",
            "Block": "248",
            "label": "PUMS,SRINIVASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4157",
            "district": "4",
            "Block": "247",
            "label": "PUMS ZAMINKALIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4208",
            "district": "4",
            "Block": "13",
            "label": "SHRI MAHATHMA VIDHYAA MANTHIR MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4211",
            "district": "4",
            "Block": "349",
            "label": "PUMS KALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4218",
            "district": "4",
            "Block": "242",
            "label": "PUMS 24 VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4255",
            "district": "4",
            "Block": "301",
            "label": "KALAIMAGAL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4266",
            "district": "4",
            "Block": "248",
            "label": "PUMS,AMBARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4291",
            "district": "4",
            "Block": "279",
            "label": "SHREE VISHNU VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4300",
            "district": "4",
            "Block": "302",
            "label": "PUMS SENGATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4316",
            "district": "4",
            "Block": "279",
            "label": "PUMS NEHRU NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4319",
            "district": "4",
            "Block": "248",
            "label": "PUMS,VAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4350",
            "district": "4",
            "Block": "302",
            "label": "PUMS M.GANAPATHYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4362",
            "district": "4",
            "Block": "279",
            "label": "CMS.NEELIKKONAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4374",
            "district": "4",
            "Block": "247",
            "label": "PUMS T.NALLIGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4411",
            "district": "4",
            "Block": "247",
            "label": "MUTHUSAMY GOUNDER MATRICULATION HIGHER SECONDARY  SCHOOL R.GOPALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4443",
            "district": "4",
            "Block": "386",
            "label": "PUMS SELLALIPARAI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4478",
            "district": "4",
            "Block": "107",
            "label": "PUMS,KUTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4525",
            "district": "4",
            "Block": "52",
            "label": "T E LC MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4541",
            "district": "4",
            "Block": "248",
            "label": "MMS,VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4548",
            "district": "4",
            "Block": "245",
            "label": "PUMS ECHANARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4633",
            "district": "4",
            "Block": "245",
            "label": "ST.JOSEPH'S MIDDLE SCHOOL, PODANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4652",
            "district": "4",
            "Block": "107",
            "label": "ROSE GARDEN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4663",
            "district": "4",
            "Block": "279",
            "label": "PUMS VEERIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4664",
            "district": "4",
            "Block": "126",
            "label": "PUMS NO.10 MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4670",
            "district": "4",
            "Block": "242",
            "label": "VIVEKAM MATRIC HR SEC SCHOOL NO.4.VEERAPANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4733",
            "district": "4",
            "Block": "302",
            "label": "PUMS VENKITTAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4765",
            "district": "4",
            "Block": "52",
            "label": "P R Sidha Naidu Memorial Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4775",
            "district": "4",
            "Block": "242",
            "label": "BISHOP FRANCIS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4793",
            "district": "4",
            "Block": "107",
            "label": "PUMS,THERAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4800",
            "district": "4",
            "Block": "242",
            "label": "PUMS THOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4806",
            "district": "4",
            "Block": "301",
            "label": "PUMS POORANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4838",
            "district": "4",
            "Block": "302",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4863",
            "district": "4",
            "Block": "301",
            "label": "VENKITARAJ MATRICULATION SCHOOL,SULTHANPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4928",
            "district": "4",
            "Block": "52",
            "label": "SHRI NEHRU VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4947",
            "district": "4",
            "Block": "279",
            "label": "ST.XAVIER'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4978",
            "district": "4",
            "Block": "279",
            "label": "SREE DHARMASASTHA MAT. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4996",
            "district": "4",
            "Block": "242",
            "label": "INFANT JESUS CONVENT HIGHER SECONDARY SCHOOL FOR HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5001",
            "district": "4",
            "Block": "52",
            "label": "C M S WEDDERBURN PET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5020",
            "district": "4",
            "Block": "247",
            "label": "KESHAV VIDHYA MANDIR MHSS POLLACH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5053",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL NALLAMUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23362",
            "district": "4",
            "Block": "302",
            "label": "Kathir Vidyaa Mandhir Secondary School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5076",
            "district": "4",
            "Block": "279",
            "label": "ST JOSEPH'S MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5179",
            "district": "4",
            "Block": "242",
            "label": "PUMS HOUSING UNIT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5260",
            "district": "4",
            "Block": "52",
            "label": "ST. FRANCIS ANGLO INDIAN GIRLS HIGH SCHOOL (AIDED)",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "5266",
            "district": "4",
            "Block": "242",
            "label": "HARI OM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5297",
            "district": "4",
            "Block": "107",
            "label": "SAN JOSE MATRIC HR SEC SCHOOL, METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5302",
            "district": "4",
            "Block": "302",
            "label": "PUMS KADUVETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5418",
            "district": "4",
            "Block": "13",
            "label": "SHRI KRISHNA VIDHYALAYA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5420",
            "district": "4",
            "Block": "242",
            "label": "LAUREL MATRIC .HR .SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5522",
            "district": "4",
            "Block": "245",
            "label": "CMS SANGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5554",
            "district": "4",
            "Block": "245",
            "label": "PSG HIGH SCHOOL, VEDAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5641",
            "district": "4",
            "Block": "156",
            "label": "SRI.P.MALLAIYAN MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5668",
            "district": "4",
            "Block": "242",
            "label": "MGM MATRICULATION HR SEC SCHOOL, K.N.G.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5712",
            "district": "4",
            "Block": "245",
            "label": "PUMS METTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5759",
            "district": "4",
            "Block": "349",
            "label": "PUMS ARUMUGAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5778",
            "district": "4",
            "Block": "242",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GOVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5779",
            "district": "4",
            "Block": "242",
            "label": "PUMS THUDIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5862",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PACHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5869",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADIVELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5875",
            "district": "4",
            "Block": "107",
            "label": "PUMS,GOBANARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5896",
            "district": "4",
            "Block": "52",
            "label": "I.C.C. GIRLS AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5914",
            "district": "4",
            "Block": "349",
            "label": "PUMS PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5920",
            "district": "4",
            "Block": "107",
            "label": "PUMS KENDEPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5933",
            "district": "4",
            "Block": "349",
            "label": "PUMS BOLUVAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5962",
            "district": "4",
            "Block": "52",
            "label": "GOVERNMENT MIDDLE SCHOOL, THIRUMAL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5984",
            "district": "4",
            "Block": "279",
            "label": "C R R MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5988",
            "district": "4",
            "Block": "156",
            "label": "PMG MATRIC HIGHER SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6033",
            "district": "4",
            "Block": "242",
            "label": "PMA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6039",
            "district": "4",
            "Block": "107",
            "label": "SVGV MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6131",
            "district": "4",
            "Block": "52",
            "label": "ALG MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6178",
            "district": "4",
            "Block": "245",
            "label": "PUMS KONAVAIKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6228",
            "district": "4",
            "Block": "245",
            "label": "SRI VAIDEESWARA VIDYALAYAM AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6252",
            "district": "4",
            "Block": "52",
            "label": "ST. AGNES GIRLS AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6272",
            "district": "4",
            "Block": "107",
            "label": "BRINDHAVANNA VIDHIALAYA SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6282",
            "district": "4",
            "Block": "245",
            "label": "PANDIT NEHRU MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6320",
            "district": "4",
            "Block": "126",
            "label": "SWAMY CHIDBHAVANANDA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6334",
            "district": "4",
            "Block": "279",
            "label": "THE BRIDGEWOODS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6339",
            "district": "4",
            "Block": "245",
            "label": "Kasturi Matriculation Hr.Sec.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6373",
            "district": "4",
            "Block": "247",
            "label": "SIWALIK MATRIC.HR.SEC. SCHOOL, POLLACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6397",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOLLUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6456",
            "district": "4",
            "Block": "13",
            "label": "MARY MATHA MATRICULATION SCHOOL, GANAPATHY PALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6512",
            "district": "4",
            "Block": "301",
            "label": "PUMS SELAKKARICHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6527",
            "district": "4",
            "Block": "279",
            "label": "PUMS CHINNAMETTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6584",
            "district": "4",
            "Block": "52",
            "label": "NIRMALA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6594",
            "district": "4",
            "Block": "107",
            "label": "ST.JOSEPH'S MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6595",
            "district": "4",
            "Block": "247",
            "label": "DISHA A LIFE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6634",
            "district": "4",
            "Block": "156",
            "label": "KALAIVANI MODEL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6665",
            "district": "4",
            "Block": "242",
            "label": "AGGARWAL MATRICULATION HR SEC SCHOOL, K.N.G. PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6683",
            "district": "4",
            "Block": "242",
            "label": "SRI KRISHNA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6691",
            "district": "4",
            "Block": "349",
            "label": "SRI NARAYANA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6712",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6718",
            "district": "4",
            "Block": "13",
            "label": "OXFORD MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6727",
            "district": "4",
            "Block": "245",
            "label": "NISE ACADEMY, POTHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12661",
            "district": "4",
            "Block": "52",
            "label": "LISIEUX MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12662",
            "district": "4",
            "Block": "52",
            "label": "T.A. RAMALINGAM CHETTIAR AIDED HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12663",
            "district": "4",
            "Block": "52",
            "label": "SRI AVINASHILINGAM GIRLS HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12664",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGH SCHOOL RAMALINGAM COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12665",
            "district": "4",
            "Block": "52",
            "label": "ANGAPPA EDUCATIONAL TRUST SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12666",
            "district": "4",
            "Block": "52",
            "label": "BISHOP OUBAGARAM PARTIALLY AIDED HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12667",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGHER SEC. SCHOOL R.S. PURAM WEST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12668",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL R.S. PURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12669",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGH SCHOOL FOR DEAF,  R.S.PURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12670",
            "district": "4",
            "Block": "52",
            "label": "ASHIRWAAD SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12671",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION SRP AMMANIAMMAL GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12672",
            "district": "4",
            "Block": "52",
            "label": "COIMBATORE SEVANILAYAM GIRLS AIDED HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12673",
            "district": "4",
            "Block": "52",
            "label": "DEVANGA AIDED HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12674",
            "district": "4",
            "Block": "52",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12675",
            "district": "4",
            "Block": "52",
            "label": "CMS PAPPANAICKEN PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12676",
            "district": "4",
            "Block": "52",
            "label": "MANI AIDED HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12677",
            "district": "4",
            "Block": "52",
            "label": "Y.W.C.A. MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12678",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGH SCHOOL PULIAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12679",
            "district": "4",
            "Block": "52",
            "label": "ST ANTONYS HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12680",
            "district": "4",
            "Block": "52",
            "label": "AIR FORCE SCHOOL,COIMBATORE",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12681",
            "district": "4",
            "Block": "52",
            "label": "VIDHYA NIKETAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12682",
            "district": "4",
            "Block": "52",
            "label": "KRISHNASAMY NAGAR GANGA NAGAR MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12683",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGHER SEC SCHOOL RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12684",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HR SEC SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12685",
            "district": "4",
            "Block": "52",
            "label": "ST MARY'S GIRLS HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12686",
            "district": "4",
            "Block": "52",
            "label": "C S I MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12687",
            "district": "4",
            "Block": "52",
            "label": "R.K.SREERANGAMMAL KALVI NILAYAM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12688",
            "district": "4",
            "Block": "52",
            "label": "STANES ANGLO INDIAN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12689",
            "district": "4",
            "Block": "52",
            "label": "C.S.I GIRLS AIDED HIGHER SECONDARY SCHOOL AVINASHI ROAD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12690",
            "district": "4",
            "Block": "52",
            "label": "G.D.MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12691",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGH SCHOOL ANUPPARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12692",
            "district": "4",
            "Block": "52",
            "label": "THE SUBURBAN HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12693",
            "district": "4",
            "Block": "52",
            "label": "CGHSS RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12694",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGH SCHOOL SIDHAPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12695",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL SIDHAPUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12696",
            "district": "4",
            "Block": "52",
            "label": "ST.MARY'S HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12697",
            "district": "4",
            "Block": "52",
            "label": "BRINDISI MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12698",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL, NORTH COIMBATORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12699",
            "district": "4",
            "Block": "52",
            "label": "SHREE BALDEVDAS KIKANI VIDYAMANDIR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12700",
            "district": "4",
            "Block": "52",
            "label": "SHREE BALDEVDAS KIKANI VIDYAMANDIR AIDED HIGHER SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12701",
            "district": "4",
            "Block": "52",
            "label": "KIKANI VIDHYA MANDIR SENIOR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12702",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGHER SECONDARY SCHOOL OPPANAKARA STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12703",
            "district": "4",
            "Block": "52",
            "label": "PRESENTATION CONVENT GIRLS HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23376",
            "district": "4",
            "Block": "52",
            "label": "SRPPS Vidhyalaya Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23378",
            "district": "4",
            "Block": "301",
            "label": "Sree lakshmi vidhya mandir matriculation school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23380",
            "district": "4",
            "Block": "279",
            "label": "Jay Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23391",
            "district": "4",
            "Block": "107",
            "label": "SRI RAGAVENDRA VIDYALAYA.",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "23392",
            "district": "4",
            "Block": "245",
            "label": "APG Matriculation Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12704",
            "district": "4",
            "Block": "52",
            "label": "CSI BOYS HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12705",
            "district": "4",
            "Block": "52",
            "label": "CITY CORPORATION HIGHER SECONDARY SCHOOL, V.H. ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12706",
            "district": "4",
            "Block": "52",
            "label": "ST.MICHAEL'S HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12707",
            "district": "4",
            "Block": "52",
            "label": "MANBAUL ULOOM Hr SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12708",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGH SCOOL SELVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12709",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL SELVAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12710",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL OKKILIYAR COLONY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12711",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION MIDDLE SCHOOL  BB STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12712",
            "district": "4",
            "Block": "52",
            "label": "MARANNA GOWDER HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12713",
            "district": "4",
            "Block": "52",
            "label": "CCMA GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, RAJA STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12714",
            "district": "4",
            "Block": "52",
            "label": "MARANNA GOWDER MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12715",
            "district": "4",
            "Block": "52",
            "label": "VEERASAMY MUDALIAR AIDED HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12716",
            "district": "4",
            "Block": "52",
            "label": "ATHAR JAMATH AIDED HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12717",
            "district": "4",
            "Block": "156",
            "label": "GHS -VAZHUKKUPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12718",
            "district": "4",
            "Block": "156",
            "label": "PUMS-PACHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12719",
            "district": "4",
            "Block": "156",
            "label": "UNIT MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12720",
            "district": "4",
            "Block": "156",
            "label": "PUMS-PALATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12721",
            "district": "4",
            "Block": "156",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL OTHAKKALMANDAPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12722",
            "district": "4",
            "Block": "156",
            "label": "GHSS MALUMICHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12723",
            "district": "4",
            "Block": "156",
            "label": "GHSJJ NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12724",
            "district": "4",
            "Block": "156",
            "label": "PUMS-SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12725",
            "district": "4",
            "Block": "156",
            "label": "PUMS-OKKILIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12726",
            "district": "4",
            "Block": "156",
            "label": "SREE SAKTHI MATRIC HR SECSCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12727",
            "district": "4",
            "Block": "156",
            "label": "GHSS -CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12728",
            "district": "4",
            "Block": "156",
            "label": "ZION MODEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12729",
            "district": "4",
            "Block": "156",
            "label": "GLOBAL PATHWAYS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12730",
            "district": "4",
            "Block": "156",
            "label": "AVATAR PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12731",
            "district": "4",
            "Block": "156",
            "label": "PUMS-BODIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12732",
            "district": "4",
            "Block": "156",
            "label": "RAVEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12733",
            "district": "4",
            "Block": "156",
            "label": "VEDANTA ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12734",
            "district": "4",
            "Block": "156",
            "label": "PUMS-IDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12735",
            "district": "4",
            "Block": "156",
            "label": "ELGI MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12736",
            "district": "4",
            "Block": "156",
            "label": "GHSS-VELLALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12737",
            "district": "4",
            "Block": "156",
            "label": "NIRMALA MATHA CONVENT MATRIC HR SEC SCHOOL, VELLALORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12738",
            "district": "4",
            "Block": "156",
            "label": "NACHIMUTHU GOUNDER RUKMANI AMMAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12739",
            "district": "4",
            "Block": "156",
            "label": "LINGAN VIDYALAYAM MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "12740",
            "district": "4",
            "Block": "156",
            "label": "ACC MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12741",
            "district": "4",
            "Block": "156",
            "label": "PUMS-ARIVOLI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12742",
            "district": "4",
            "Block": "156",
            "label": "GHS-KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12743",
            "district": "4",
            "Block": "156",
            "label": "GHSS MADUKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12744",
            "district": "4",
            "Block": "156",
            "label": "GHS MARAPPALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12745",
            "district": "4",
            "Block": "156",
            "label": "PUMS-ETTIMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12746",
            "district": "4",
            "Block": "156",
            "label": "WESTERN GHATS INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12747",
            "district": "4",
            "Block": "156",
            "label": "GTRHS MAVUTHAMPATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12748",
            "district": "4",
            "Block": "156",
            "label": "PUMS RANGASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12749",
            "district": "4",
            "Block": "156",
            "label": "PUMS CHINNANAVAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12750",
            "district": "4",
            "Block": "156",
            "label": "GHSS-PICHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12751",
            "district": "4",
            "Block": "156",
            "label": "PUMS-THIRUMALAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12752",
            "district": "4",
            "Block": "156",
            "label": "SRI L.DHARMMA KANNAN VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12753",
            "district": "4",
            "Block": "156",
            "label": "GHS KUMITIPATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12754",
            "district": "4",
            "Block": "242",
            "label": "GTRMS PALAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12755",
            "district": "4",
            "Block": "242",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12756",
            "district": "4",
            "Block": "242",
            "label": "PUMS SELVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12757",
            "district": "4",
            "Block": "242",
            "label": "TAT KALANILAYAM AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12758",
            "district": "4",
            "Block": "242",
            "label": "SRI K.RANGASAMY NAIDU HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12759",
            "district": "4",
            "Block": "242",
            "label": "PIONEER MILLS HIGHER SECONDARY SCHOOL JOTHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12760",
            "district": "4",
            "Block": "242",
            "label": "SRI RAMAKRISHNA MISSION VIDYALAYA HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12761",
            "district": "4",
            "Block": "242",
            "label": "GKD MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12762",
            "district": "4",
            "Block": "242",
            "label": "SRI RAMAKRISHNA MISSION VIDYALAYA SWAMI SHIVANANDA HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12763",
            "district": "4",
            "Block": "242",
            "label": "PUMS VINOBAVE ARIVOLINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12764",
            "district": "4",
            "Block": "242",
            "label": "GOVT HIGH SCHOOL NO 4 VEERAPANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12765",
            "district": "4",
            "Block": "242",
            "label": "THAMBU HIGHER SECONDARY SCHOOL PRESSCOLONY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12766",
            "district": "4",
            "Block": "242",
            "label": "GHS NARASIMMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12767",
            "district": "4",
            "Block": "242",
            "label": "GGHS ASOKAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12768",
            "district": "4",
            "Block": "242",
            "label": "GHSS ASOKAPURAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12769",
            "district": "4",
            "Block": "242",
            "label": "DR.DASARATHAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12770",
            "district": "4",
            "Block": "242",
            "label": "ST JOHN BOSCO MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12771",
            "district": "4",
            "Block": "242",
            "label": "V.C.SUBAIA MEENAKSHIAMMA MATRIC HR. SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12772",
            "district": "4",
            "Block": "242",
            "label": "THAI LAXMI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12773",
            "district": "4",
            "Block": "242",
            "label": "VIDYA VIKASINI OPPORTUNITY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12774",
            "district": "4",
            "Block": "242",
            "label": "GHSS PANNIMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12775",
            "district": "4",
            "Block": "242",
            "label": "GHS KANUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12776",
            "district": "4",
            "Block": "242",
            "label": "JAYAM FOUNDATION MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12777",
            "district": "4",
            "Block": "242",
            "label": "GGHSS CHINNATHADAGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12778",
            "district": "4",
            "Block": "242",
            "label": "GBHSS CHINNATHADAGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12779",
            "district": "4",
            "Block": "242",
            "label": "PUMS DHOOMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12780",
            "district": "4",
            "Block": "242",
            "label": "GTRHSS ANAIKATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12781",
            "district": "4",
            "Block": "242",
            "label": "CHAVARA VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12782",
            "district": "4",
            "Block": "242",
            "label": "THE PSBB MILLENNIUM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12783",
            "district": "4",
            "Block": "242",
            "label": "SRI SATHYA SAAI VIDHYA NIKETAN MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12784",
            "district": "4",
            "Block": "242",
            "label": "GHSS SUNDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12785",
            "district": "4",
            "Block": "242",
            "label": "HOLY ANGELS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12786",
            "district": "4",
            "Block": "242",
            "label": "GHSS 49,KAVUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12787",
            "district": "4",
            "Block": "242",
            "label": "IRCS  ( HI ) SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12788",
            "district": "4",
            "Block": "242",
            "label": "WVS SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12789",
            "district": "4",
            "Block": "242",
            "label": "NAIRS VIDHYA MANDHIR (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12790",
            "district": "4",
            "Block": "245",
            "label": "RAILWAY HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12791",
            "district": "4",
            "Block": "245",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12792",
            "district": "4",
            "Block": "245",
            "label": "GHSS KURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12793",
            "district": "4",
            "Block": "245",
            "label": "NIRMALAMATHA CONVENT MAT HR SEC SCHOOL, KUNIAMUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12794",
            "district": "4",
            "Block": "245",
            "label": "SRI VISWESWARA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12795",
            "district": "4",
            "Block": "245",
            "label": "V.S.SENGOTTAIAH MEMORIAL AIDED HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12796",
            "district": "4",
            "Block": "245",
            "label": "GREEN GARDEN MATRICULATION SCHOOL , KURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12797",
            "district": "4",
            "Block": "245",
            "label": "MOUNT CARMEL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12798",
            "district": "4",
            "Block": "245",
            "label": "GHS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12799",
            "district": "4",
            "Block": "245",
            "label": "SETHU VIDYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12800",
            "district": "4",
            "Block": "245",
            "label": "GHSS SUNDAKKAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12801",
            "district": "4",
            "Block": "245",
            "label": "THAVATHIRU SANDHALINGA ADIKALAR AIDED HIGHER SECONDARY SCHOOL, PERUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12802",
            "district": "4",
            "Block": "245",
            "label": "GHSS KUNIYAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12803",
            "district": "4",
            "Block": "245",
            "label": "GHSS KULATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12804",
            "district": "4",
            "Block": "245",
            "label": "V.L.B JANAKIAMMAL AIDED GIRLS HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12805",
            "district": "4",
            "Block": "245",
            "label": "AIDED HIGH SCHOOL CBM SAKUNDHALA VIDHYALAYA",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12806",
            "district": "4",
            "Block": "245",
            "label": "RKV Senior Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12807",
            "district": "4",
            "Block": "245",
            "label": "SARASWATHI RAMACHANDRAN VIDYALAYA MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12808",
            "district": "4",
            "Block": "245",
            "label": "NS MATRICULATION HR SEC SCHOOL, KUNIYAMUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12809",
            "district": "4",
            "Block": "245",
            "label": "AMRIT CENTRE FOR SPECIAL NEEDS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12810",
            "district": "4",
            "Block": "245",
            "label": "V.V.VANI VIDYALAYA MATRIC.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12811",
            "district": "4",
            "Block": "245",
            "label": "SHIRUSHTI VIDHYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12812",
            "district": "4",
            "Block": "245",
            "label": "SFV GHSS SEERANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12813",
            "district": "4",
            "Block": "245",
            "label": "CHS PAPPANAICKENPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12814",
            "district": "4",
            "Block": "245",
            "label": "CHS KOVILMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12815",
            "district": "4",
            "Block": "245",
            "label": "CHSS VENKITTAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12816",
            "district": "4",
            "Block": "245",
            "label": "AVILA CONVENT MATRICULATION HR SEC SCHOOL, VELANDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12817",
            "district": "4",
            "Block": "245",
            "label": "SAIBABA VID.MDL SCH.SB KOIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12818",
            "district": "4",
            "Block": "245",
            "label": "CHS KUPPAKONAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12819",
            "district": "4",
            "Block": "245",
            "label": "ST THOMAS AIDED HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12820",
            "district": "4",
            "Block": "245",
            "label": "CHSS RATHINAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12821",
            "district": "4",
            "Block": "245",
            "label": "GHS GANDHIMANAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12822",
            "district": "4",
            "Block": "245",
            "label": "GHSS, GANAPATHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12823",
            "district": "4",
            "Block": "245",
            "label": "CMS AIDED HIGH SCHOOL (MALAYALAM), GANAPATHY, COIMBATORE 641006",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12824",
            "district": "4",
            "Block": "245",
            "label": "ELSIE MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12825",
            "district": "4",
            "Block": "245",
            "label": "CHSS MANIYAKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12826",
            "district": "4",
            "Block": "245",
            "label": "THE CAMFORD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12827",
            "district": "4",
            "Block": "245",
            "label": "KOVAI KALAIMAGAL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12828",
            "district": "4",
            "Block": "279",
            "label": "GANDHI CENTENARY MEMORIAL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12829",
            "district": "4",
            "Block": "279",
            "label": "THIYAGI N.G.RAMASWAMY MEMORIAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12830",
            "district": "4",
            "Block": "279",
            "label": "SRI GOPAL NAIDU HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12831",
            "district": "4",
            "Block": "279",
            "label": "KASTHURBA GANDHI ORAL SCHOOL HEART FOR THE HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12832",
            "district": "4",
            "Block": "279",
            "label": "CRESCENT MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12833",
            "district": "4",
            "Block": "279",
            "label": "CHS VARADHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12834",
            "district": "4",
            "Block": "279",
            "label": "PSG SARVAJANA HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12835",
            "district": "4",
            "Block": "279",
            "label": "CHSS,PEELAMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12836",
            "district": "4",
            "Block": "279",
            "label": "PSGR KRISHNAMMAL GIRLS.HR.SEC.SCHOOL PEELAMEDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12837",
            "district": "4",
            "Block": "279",
            "label": "PSG PUBLIC SCHOOLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12838",
            "district": "4",
            "Block": "279",
            "label": "ST JOSEPH'S BOYS AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12839",
            "district": "4",
            "Block": "279",
            "label": "ST.PHILOMENA'S HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12840",
            "district": "4",
            "Block": "279",
            "label": "CHSS UDAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12841",
            "district": "4",
            "Block": "279",
            "label": "KENDRIYA VIDYALAYA PULIAKULAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12842",
            "district": "4",
            "Block": "279",
            "label": "GEETHANJALEE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12843",
            "district": "4",
            "Block": "279",
            "label": "C.M.S SIHS COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12844",
            "district": "4",
            "Block": "279",
            "label": "RAJALAKSHMI AIDED HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12845",
            "district": "4",
            "Block": "279",
            "label": "SNVNV GOVERNMENT BOYS HIGH SCHOOL SINGANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12846",
            "district": "4",
            "Block": "279",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL SINGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12847",
            "district": "4",
            "Block": "279",
            "label": "NADAR HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12848",
            "district": "4",
            "Block": "279",
            "label": "GOVERNMENT BOYS HIGHER SECONARY SCHOOL ONDIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12849",
            "district": "4",
            "Block": "279",
            "label": "R.C GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ONDIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12850",
            "district": "4",
            "Block": "279",
            "label": "A R N MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12851",
            "district": "4",
            "Block": "279",
            "label": "ISLAMIYAH MAT.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12852",
            "district": "4",
            "Block": "279",
            "label": "SPBN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12853",
            "district": "4",
            "Block": "279",
            "label": "BVM GLOBAL CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12854",
            "district": "4",
            "Block": "279",
            "label": "VIVEKANANDA VIDYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12855",
            "district": "4",
            "Block": "279",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12856",
            "district": "4",
            "Block": "279",
            "label": "GHSS SSKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12857",
            "district": "4",
            "Block": "279",
            "label": "K.V. MATRIC HR SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12858",
            "district": "4",
            "Block": "279",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12859",
            "district": "4",
            "Block": "279",
            "label": "SWATHANTHRA HR.SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12860",
            "district": "4",
            "Block": "279",
            "label": "GHSS VELLAMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12861",
            "district": "4",
            "Block": "279",
            "label": "PUMS KEERANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12862",
            "district": "4",
            "Block": "279",
            "label": "PUMS-CHINNAVEDAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12863",
            "district": "4",
            "Block": "279",
            "label": "PUMS UDAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12864",
            "district": "4",
            "Block": "279",
            "label": "T R A HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12865",
            "district": "4",
            "Block": "279",
            "label": "COIMBATORE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12866",
            "district": "4",
            "Block": "279",
            "label": "KAUMARAM SUSHILA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12867",
            "district": "4",
            "Block": "279",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12868",
            "district": "4",
            "Block": "279",
            "label": "PUMS CHERAN MAANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12869",
            "district": "4",
            "Block": "279",
            "label": "R J MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12870",
            "district": "4",
            "Block": "279",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VILANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12871",
            "district": "4",
            "Block": "279",
            "label": "VIDHYA NIKETAN MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12872",
            "district": "4",
            "Block": "279",
            "label": "PUMS SHAJAHAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12873",
            "district": "4",
            "Block": "279",
            "label": "VIMAL JYOTHI CONVENT MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12874",
            "district": "4",
            "Block": "279",
            "label": "ELANGO MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12875",
            "district": "4",
            "Block": "279",
            "label": "GOVERNMENT HIGH SCHOOL, SARAVANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12876",
            "district": "4",
            "Block": "279",
            "label": "VIVEKAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12877",
            "district": "4",
            "Block": "279",
            "label": "LISIEUX CMI PUBLIC SCHOOL, SARAVANAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12878",
            "district": "4",
            "Block": "279",
            "label": "GHS IDIGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12879",
            "district": "4",
            "Block": "279",
            "label": "PUMS VATTAMALAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12880",
            "district": "4",
            "Block": "279",
            "label": "VCV GHSS VELLAKINAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12881",
            "district": "4",
            "Block": "279",
            "label": "CRAYONS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12882",
            "district": "4",
            "Block": "279",
            "label": "MANCHESTER INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12883",
            "district": "4",
            "Block": "279",
            "label": "CHANDRA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12884",
            "district": "4",
            "Block": "279",
            "label": "GHSS KALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12885",
            "district": "4",
            "Block": "279",
            "label": "PSGG KANYAGURUKULAM HR. SEC. SCHOOL FOR GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12886",
            "district": "4",
            "Block": "279",
            "label": "SRI KK NAIDU HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12887",
            "district": "4",
            "Block": "279",
            "label": "SRIMATHI D. PADMAVATHI AMMAL HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12888",
            "district": "4",
            "Block": "279",
            "label": "SUGUNA PIP SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12889",
            "district": "4",
            "Block": "279",
            "label": "THE IDEAL MOUNT LITERA ZEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12890",
            "district": "4",
            "Block": "279",
            "label": "SRI VIVEKANANDA MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12891",
            "district": "4",
            "Block": "279",
            "label": "CHANDRAKANTHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12892",
            "district": "4",
            "Block": "279",
            "label": "ANAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12893",
            "district": "4",
            "Block": "279",
            "label": "PUMS-VELLANIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12894",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12895",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BOMMANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12896",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAVALLI SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12897",
            "district": "4",
            "Block": "349",
            "label": "MARUTHAMALAI SUBRAMANIYA SWAMY DEVASTHANA HIGHER SECONDARY SCHOOL , VADAVALLI, CBE-641041.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12898",
            "district": "4",
            "Block": "349",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KALVEERAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12899",
            "district": "4",
            "Block": "349",
            "label": "R.P.M.VIDYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12900",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KALIKKANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12901",
            "district": "4",
            "Block": "349",
            "label": "GGHSS THONDAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12902",
            "district": "4",
            "Block": "349",
            "label": "GBHSS THONDAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12903",
            "district": "4",
            "Block": "349",
            "label": "GHS DEVARAYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12904",
            "district": "4",
            "Block": "349",
            "label": "GHSS NARASIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12905",
            "district": "4",
            "Block": "349",
            "label": "PUMS VIRALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12906",
            "district": "4",
            "Block": "349",
            "label": "GHS SEMMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12907",
            "district": "4",
            "Block": "349",
            "label": "GTR HS MUTTATHUVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12908",
            "district": "4",
            "Block": "349",
            "label": "ISHA HOME SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12909",
            "district": "4",
            "Block": "349",
            "label": "GHSS MADVARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12910",
            "district": "4",
            "Block": "349",
            "label": "CHINMAYA INTERNATIONAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12911",
            "district": "4",
            "Block": "349",
            "label": "PUMS ALANDURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12912",
            "district": "4",
            "Block": "349",
            "label": "GHSS ALANDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12913",
            "district": "4",
            "Block": "349",
            "label": "GHS NADHEGOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12914",
            "district": "4",
            "Block": "349",
            "label": "GHSS SENNANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12915",
            "district": "4",
            "Block": "349",
            "label": "AIDED MID SCHOOL KUPPANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12916",
            "district": "4",
            "Block": "349",
            "label": "GHS MADHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12917",
            "district": "4",
            "Block": "349",
            "label": "GHSS THEETHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12918",
            "district": "4",
            "Block": "349",
            "label": "PANCHYAT UNION MIDDLE SCHOOL  PERUR CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12919",
            "district": "4",
            "Block": "13",
            "label": "ANANYA VIDYALAYA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12920",
            "district": "4",
            "Block": "13",
            "label": "GHS PETHANAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12921",
            "district": "4",
            "Block": "13",
            "label": "SRI VAGISVARI VIDYA MANDIR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12922",
            "district": "4",
            "Block": "13",
            "label": "GHSS SOMANDURAI CHITTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12923",
            "district": "4",
            "Block": "13",
            "label": "PUMS THENSANGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12924",
            "district": "4",
            "Block": "13",
            "label": "M C GGHSS KOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12925",
            "district": "4",
            "Block": "13",
            "label": "GOVT BOYS HSS KOTTUR MALAYANDIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12926",
            "district": "4",
            "Block": "13",
            "label": "GHS ALIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12927",
            "district": "4",
            "Block": "13",
            "label": "VRT GGHSS ANAIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12928",
            "district": "4",
            "Block": "13",
            "label": "GHSS, ANAIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12929",
            "district": "4",
            "Block": "13",
            "label": "GHS ATHUPOLLACHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12930",
            "district": "4",
            "Block": "13",
            "label": "PALANIAMMAL HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12931",
            "district": "4",
            "Block": "13",
            "label": "GHSS Diwansapudur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12932",
            "district": "4",
            "Block": "13",
            "label": "FOREST DEPT TRIBAL  RESIDENTIAL MIDDLE SCHOOL TOPSLIP",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12933",
            "district": "4",
            "Block": "13",
            "label": "PUMS SARALAIPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12934",
            "district": "4",
            "Block": "13",
            "label": "FOREST HILL ACADEMY MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12935",
            "district": "4",
            "Block": "13",
            "label": "GHSS VETTAIKARANPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12936",
            "district": "4",
            "Block": "13",
            "label": "GHS SETHUMADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12937",
            "district": "4",
            "Block": "13",
            "label": "PUMS ODAYAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12938",
            "district": "4",
            "Block": "13",
            "label": "ANNAI MATRICULATION SCHOOL, CHINNAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12945",
            "district": "4",
            "Block": "126",
            "label": "GHS ARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12946",
            "district": "4",
            "Block": "126",
            "label": "GHSS SERIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12947",
            "district": "4",
            "Block": "126",
            "label": "GHS ANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12948",
            "district": "4",
            "Block": "126",
            "label": "GHS DEVANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12949",
            "district": "4",
            "Block": "126",
            "label": "PUMS SENDRAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12950",
            "district": "4",
            "Block": "126",
            "label": "PUMS DASANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12951",
            "district": "4",
            "Block": "126",
            "label": "GHS KATTAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12952",
            "district": "4",
            "Block": "126",
            "label": "PUMS KOTHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12953",
            "district": "4",
            "Block": "126",
            "label": "GHSS KALIANNANPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12954",
            "district": "4",
            "Block": "126",
            "label": "PUMS KONDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12955",
            "district": "4",
            "Block": "126",
            "label": "TELC MS KARUNAGARAPURI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12956",
            "district": "4",
            "Block": "126",
            "label": "NOYYAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12957",
            "district": "4",
            "Block": "126",
            "label": "PUMS NALLATTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12958",
            "district": "4",
            "Block": "126",
            "label": "PUMS METTUVAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12959",
            "district": "4",
            "Block": "126",
            "label": "GHS PANAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12960",
            "district": "4",
            "Block": "126",
            "label": "MNM HIGH SCHOOL JAKKARPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12961",
            "district": "4",
            "Block": "126",
            "label": "PUMS PALARPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12962",
            "district": "4",
            "Block": "126",
            "label": "GHSS MUTHUGOUNDENUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12963",
            "district": "4",
            "Block": "126",
            "label": "GHSS VADACHITHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12964",
            "district": "4",
            "Block": "126",
            "label": "GHS SENGUTTAIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12965",
            "district": "4",
            "Block": "126",
            "label": "AIDED MIDDLE SCHOOL SINGARAM PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12966",
            "district": "4",
            "Block": "126",
            "label": "GHSS KINATHUKADAVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12967",
            "district": "4",
            "Block": "126",
            "label": "TELC SR. BERGENDHAL GIRLS HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12968",
            "district": "4",
            "Block": "126",
            "label": "PRESENTATION CON FOR DISABLED",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12985",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  BODIPLAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12986",
            "district": "4",
            "Block": "247",
            "label": "GHS DEVAMBADI KALIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12987",
            "district": "4",
            "Block": "247",
            "label": "PUMS JAMIN MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12988",
            "district": "4",
            "Block": "247",
            "label": "CENTRAL HIGH SCHOOL A NAGORE",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12989",
            "district": "4",
            "Block": "247",
            "label": "JK PUBLIC HIGHER SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12990",
            "district": "4",
            "Block": "247",
            "label": "GHSS PURAVIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12991",
            "district": "4",
            "Block": "247",
            "label": "GOVERNMENT HIGH SCHOOL PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12992",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAMAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12993",
            "district": "4",
            "Block": "247",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  MANNUR RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12994",
            "district": "4",
            "Block": "247",
            "label": "PUMS VADAKKUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12995",
            "district": "4",
            "Block": "247",
            "label": "PUMS P.NAGORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12996",
            "district": "4",
            "Block": "247",
            "label": "RISE MATRICULATION SCHOOL SAMIYANDIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12997",
            "district": "4",
            "Block": "247",
            "label": "GGHS NEGAMAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12998",
            "district": "4",
            "Block": "247",
            "label": "GHSS Negamam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12999",
            "district": "4",
            "Block": "247",
            "label": "GOVERNMENT HIGH SCHOOL GOLLAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13000",
            "district": "4",
            "Block": "248",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,GOMANGALAMPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13001",
            "district": "4",
            "Block": "248",
            "label": "VIDYA NETHRRA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13002",
            "district": "4",
            "Block": "248",
            "label": "GHSS KANJAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13003",
            "district": "4",
            "Block": "248",
            "label": "PALANIGOUNDER HIGHER SECONDARY SCHOOL, POLLACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13004",
            "district": "4",
            "Block": "248",
            "label": "NGNG HSS REDDIARUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13005",
            "district": "4",
            "Block": "248",
            "label": "GHSS SINGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13006",
            "district": "4",
            "Block": "248",
            "label": "GOVERNMENT HIGH SCHOOL,S.MALAYANDIPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13007",
            "district": "4",
            "Block": "248",
            "label": "ADW HSS THONDAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13008",
            "district": "4",
            "Block": "248",
            "label": "SHENBAGAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13009",
            "district": "4",
            "Block": "248",
            "label": "VIVEKANANTHAA VITHYALAYAA MATRIC SCHOOL, KUNCHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13010",
            "district": "4",
            "Block": "248",
            "label": "GHSS , ZAMIN UTHUKULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13011",
            "district": "4",
            "Block": "248",
            "label": "TMT. RUKMANIAMMAL HSS, JOTHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13012",
            "district": "4",
            "Block": "248",
            "label": "SVGHSS SAMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13013",
            "district": "4",
            "Block": "248",
            "label": "MARIAMMAL GIRLS HIGHER SECONDARY SCHOOL, POLLACHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13014",
            "district": "4",
            "Block": "248",
            "label": "SHANTINIKETAN MATRICULATION SCHOOL, POLLACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13015",
            "district": "4",
            "Block": "248",
            "label": "SAMATHUR RAMA IYYANKAR MUNICIPAL HR SEC SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13016",
            "district": "4",
            "Block": "248",
            "label": "SRI LATHANGI VIDHYA MANDIR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13017",
            "district": "4",
            "Block": "248",
            "label": "MGHSS POLLACHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13018",
            "district": "4",
            "Block": "248",
            "label": "MUNICIPAL BOYS HR. SEC. SCHOOL, POLLACHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13019",
            "district": "4",
            "Block": "248",
            "label": "GOVT GIRLS HR.SC.SCHOOL, POLLACHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13020",
            "district": "4",
            "Block": "248",
            "label": "SHAANTHI MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13051",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH  UNION MIDDLE SCHOOL VELLONIE LOWER DIVISION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13052",
            "district": "4",
            "Block": "386",
            "label": "GTRMS VALPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13053",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL MURUGALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13054",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL PACHAMALAI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13055",
            "district": "4",
            "Block": "386",
            "label": "AIDED MIDDLE SCHOOL  MUDIS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13056",
            "district": "4",
            "Block": "386",
            "label": "SACRED HEART MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13057",
            "district": "4",
            "Block": "386",
            "label": "GGHSS VALPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13058",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGH SCHOOL ROTTIKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13059",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGH SCHOOL CINCHONA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13060",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SHOLAYAR DAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13061",
            "district": "4",
            "Block": "386",
            "label": "GHSS ATTAKATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13062",
            "district": "4",
            "Block": "386",
            "label": "GHS KADAMPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13063",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VALPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13064",
            "district": "4",
            "Block": "386",
            "label": "THUYA IRUDAYA GIRLS HR SEC SCHOOL, VALPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13065",
            "district": "4",
            "Block": "386",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Valparai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13066",
            "district": "4",
            "Block": "19",
            "label": "GHSS ANAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13067",
            "district": "4",
            "Block": "19",
            "label": "PUMS, AKKARAI SENGAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13068",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDILE SCHOOL, POGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13069",
            "district": "4",
            "Block": "19",
            "label": "GOVERNMENT HIGH SCHOOL, PERIYAPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13070",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUNNATHUR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13071",
            "district": "4",
            "Block": "19",
            "label": "SREE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13072",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13073",
            "district": "4",
            "Block": "19",
            "label": "T.S.A. GOVT HR.SEC.SCHOOL, KATTAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13074",
            "district": "4",
            "Block": "19",
            "label": "SRI BHARATHI MATRICULATION HIGHER SECONDRY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13075",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ORAIKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13076",
            "district": "4",
            "Block": "19",
            "label": "GOVERNMENT HIGH SCHOOL PONNEGOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13077",
            "district": "4",
            "Block": "19",
            "label": "PUMS VADAKKALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13078",
            "district": "4",
            "Block": "19",
            "label": "PUMS KANUVAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13079",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOLC, SELLAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13080",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AAMBOTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13081",
            "district": "4",
            "Block": "19",
            "label": "PUMS, ANNUR METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13082",
            "district": "4",
            "Block": "19",
            "label": "PUMS, OTTERPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13083",
            "district": "4",
            "Block": "19",
            "label": "ST MARY'S CONVENT MATRICULATION HIGHER SECONDARY SCHOOL, ALLIKKARANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13084",
            "district": "4",
            "Block": "19",
            "label": "PUMS, KANJAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13085",
            "district": "4",
            "Block": "19",
            "label": "PUMS, PUTHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13086",
            "district": "4",
            "Block": "19",
            "label": "GHSS, KEMBANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13087",
            "district": "4",
            "Block": "19",
            "label": "PANCAHAYAT UNION MIDDLE SCHOOL, ELLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13088",
            "district": "4",
            "Block": "19",
            "label": "STANES MATRICULATION SCHOOL, ELLAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13089",
            "district": "4",
            "Block": "19",
            "label": "PUMS, PILLAIYAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13090",
            "district": "4",
            "Block": "19",
            "label": "PUMS, ANNUR KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13091",
            "district": "4",
            "Block": "19",
            "label": "AMARAR A. MUTHUGOUNDER  GOVERNMENT HIGHER SECONDARY SCHOOL, ANNUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13092",
            "district": "4",
            "Block": "19",
            "label": "GANDHIJI GOVT HR SEC SCHOOL, SOKKAMPALAYAM 641653",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13093",
            "district": "4",
            "Block": "19",
            "label": "K.G. GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13094",
            "district": "4",
            "Block": "19",
            "label": "SRI AKILA VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13131",
            "district": "4",
            "Block": "107",
            "label": "PUMS MANGALAKARAI PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13132",
            "district": "4",
            "Block": "107",
            "label": "TMT KEMPANANJAMMA MML HR SEC SCHOOL  KARAMADAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13133",
            "district": "4",
            "Block": "107",
            "label": "GOVT HR SEC SCHOOL, KARAMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13134",
            "district": "4",
            "Block": "107",
            "label": "PUMS,NEELAMPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13135",
            "district": "4",
            "Block": "107",
            "label": "GHS THOLAMPALYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13136",
            "district": "4",
            "Block": "107",
            "label": "PUMS,PALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13137",
            "district": "4",
            "Block": "107",
            "label": "ROSARI GIRLS HIGH SCHOOL METTUPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13138",
            "district": "4",
            "Block": "107",
            "label": "GHSS METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13139",
            "district": "4",
            "Block": "107",
            "label": "PUMS,SUNDHANTHIRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13140",
            "district": "4",
            "Block": "107",
            "label": "GHS KANNARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13141",
            "district": "4",
            "Block": "107",
            "label": "VIDYAA VIKAS MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13142",
            "district": "4",
            "Block": "107",
            "label": "PUMS,CHINNAKUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13143",
            "district": "4",
            "Block": "107",
            "label": "PUMS,M.KOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13144",
            "district": "4",
            "Block": "107",
            "label": "PUMS,PUDHUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13145",
            "district": "4",
            "Block": "107",
            "label": "GHSS PETHIKUTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13146",
            "district": "4",
            "Block": "107",
            "label": "GHSS VELLIANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13147",
            "district": "4",
            "Block": "107",
            "label": "GHSS S.PUNGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13148",
            "district": "4",
            "Block": "107",
            "label": "GHSS OOMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13149",
            "district": "4",
            "Block": "107",
            "label": "PUMS,LINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13150",
            "district": "4",
            "Block": "107",
            "label": "PUMS ALATHI VATCHINAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13151",
            "district": "4",
            "Block": "107",
            "label": "PUMS MOOLATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13152",
            "district": "4",
            "Block": "107",
            "label": "VIJAYALAKSHMI MATRICULATION HSS, SIRUMUGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13153",
            "district": "4",
            "Block": "107",
            "label": "GOVT HR SEC SCHOOL SIRUMUGAIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13154",
            "district": "4",
            "Block": "107",
            "label": "GHS ILLUPPAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13155",
            "district": "4",
            "Block": "107",
            "label": "ALAGIRI SURESH GHS ALANGOMBU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13156",
            "district": "4",
            "Block": "107",
            "label": "SHREE SARASSWATHI VIDHYAAH MANDHEER SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13157",
            "district": "4",
            "Block": "107",
            "label": "PUMS,BELLEPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13158",
            "district": "4",
            "Block": "107",
            "label": "E.A.B GOVT HR SEC SCHOOL THENPONMUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13159",
            "district": "4",
            "Block": "107",
            "label": "PUMS THEKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13160",
            "district": "4",
            "Block": "107",
            "label": "PUMS DHASANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13161",
            "district": "4",
            "Block": "107",
            "label": "GADW HR SEC SCHOOL WELSPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13162",
            "district": "4",
            "Block": "107",
            "label": "PUMS K.PUNGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13163",
            "district": "4",
            "Block": "107",
            "label": "PUMS,THIMMAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13164",
            "district": "4",
            "Block": "107",
            "label": "GHSS , BUJANGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13165",
            "district": "4",
            "Block": "107",
            "label": "T.DURAISWAMY GOWDER HSS, SEELIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13166",
            "district": "4",
            "Block": "107",
            "label": "SRI VINAYAGA VIDHYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13167",
            "district": "4",
            "Block": "107",
            "label": "GHS PARALI POWER HOUSE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13168",
            "district": "4",
            "Block": "107",
            "label": "C.S.I. MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13169",
            "district": "4",
            "Block": "107",
            "label": "G.M.R.C. MIDDLE SCHOOL, METTUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13170",
            "district": "4",
            "Block": "107",
            "label": "CAREWELL MATRIC HR SEC SCHOOL , METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13171",
            "district": "4",
            "Block": "107",
            "label": "MPL GIRLS HSS,METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13172",
            "district": "4",
            "Block": "107",
            "label": "MAHAJANA HSS,METTUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13173",
            "district": "4",
            "Block": "107",
            "label": "CHRIST THE KING MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13205",
            "district": "4",
            "Block": "301",
            "label": "SRI VENKATESHWARA HIGH SCHOOL APPANAICKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13206",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGH SCHOOL PONNAKKANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13207",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EDAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13208",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13209",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGH SCHOOL AKKANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13210",
            "district": "4",
            "Block": "301",
            "label": "SRI RAMASWAMY NAIDU VIDYALAYAM HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13211",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENJERIMALAIYADIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13212",
            "district": "4",
            "Block": "301",
            "label": "THAVATHIRU MARIMUTHU ADIGALAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13213",
            "district": "4",
            "Block": "301",
            "label": "PUMS S.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13214",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL J.KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13215",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENJERIPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13216",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGH SCHOOL JALLIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13217",
            "district": "4",
            "Block": "301",
            "label": "PUMS ODAKKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13218",
            "district": "4",
            "Block": "301",
            "label": "SOKKAN CHETTIAR MALLAMMAL HIGHER SECONDARY SCHOOL VADAMBACHERI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13219",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGH SCHOOL SULTHANPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13220",
            "district": "4",
            "Block": "302",
            "label": "GHS RASIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13221",
            "district": "4",
            "Block": "302",
            "label": "PUMS ATHAPPAGOUNDANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13222",
            "district": "4",
            "Block": "302",
            "label": "GHSS,IRUGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13223",
            "district": "4",
            "Block": "302",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SULUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13224",
            "district": "4",
            "Block": "302",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SULUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13225",
            "district": "4",
            "Block": "302",
            "label": "GHSS KANGEYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13226",
            "district": "4",
            "Block": "302",
            "label": "KENDRIYA VIDYALAYA",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13227",
            "district": "4",
            "Block": "302",
            "label": "GHS KALANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13228",
            "district": "4",
            "Block": "302",
            "label": "ANUGRAHA MANDHIR CBSE SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13229",
            "district": "4",
            "Block": "302",
            "label": "GHSS KANNAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13230",
            "district": "4",
            "Block": "302",
            "label": "AIDED KADRI MILLS HIGHER SECONDARY SCHOOL OTTARPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13231",
            "district": "4",
            "Block": "302",
            "label": "GHS PALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13232",
            "district": "4",
            "Block": "302",
            "label": "VIVEKANANDA KALVI NILAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13233",
            "district": "4",
            "Block": "302",
            "label": "VIJAYA VIDYALAYA  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13234",
            "district": "4",
            "Block": "302",
            "label": "GHS PATTANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13235",
            "district": "4",
            "Block": "302",
            "label": "S K N GOVT HR SEC SCHOOL, PEEDAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13236",
            "district": "4",
            "Block": "302",
            "label": "AIDED ST MARYS BOYS HIGH SCHOOL KARUMATHAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13237",
            "district": "4",
            "Block": "302",
            "label": "ST.JOSEPHS GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13238",
            "district": "4",
            "Block": "302",
            "label": "KONGU VELLALAR MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13239",
            "district": "4",
            "Block": "302",
            "label": "A.R.C MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13240",
            "district": "4",
            "Block": "302",
            "label": "HOLY ROSARY MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13241",
            "district": "4",
            "Block": "302",
            "label": "KOVAI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13242",
            "district": "4",
            "Block": "302",
            "label": "GOVERNMENT HIGH SCHOOL SEMMANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13243",
            "district": "4",
            "Block": "302",
            "label": "PUMS KITTAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13244",
            "district": "4",
            "Block": "302",
            "label": "K K VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13245",
            "district": "4",
            "Block": "302",
            "label": "K K VIDHYALAYA HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13246",
            "district": "4",
            "Block": "302",
            "label": "GOVT HR SEC SCHOOL VAGARAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13247",
            "district": "4",
            "Block": "302",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13248",
            "district": "4",
            "Block": "302",
            "label": "GHS SELLAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13249",
            "district": "4",
            "Block": "302",
            "label": "GHSS,ARASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13250",
            "district": "4",
            "Block": "302",
            "label": "GRD-CPF MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13251",
            "district": "4",
            "Block": "302",
            "label": "GHSS CHINNIAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13252",
            "district": "4",
            "Block": "302",
            "label": "PUMS MUTHUGOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20879",
            "district": "4",
            "Block": "302",
            "label": "SSVM WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20883",
            "district": "4",
            "Block": "107",
            "label": "SATCHIDANANDA JOTHI NIKETHAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20884",
            "district": "4",
            "Block": "107",
            "label": "SRI AMBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20885",
            "district": "4",
            "Block": "279",
            "label": "GRD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20908",
            "district": "4",
            "Block": "242",
            "label": "YUVA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20953",
            "district": "4",
            "Block": "245",
            "label": "THE LITTLE KINGDOM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20968",
            "district": "4",
            "Block": "279",
            "label": "KAUMARAM PRASHANTHI  ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20991",
            "district": "4",
            "Block": "242",
            "label": "BENGLEN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21026",
            "district": "4",
            "Block": "302",
            "label": "MDN FUTURE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21047",
            "district": "4",
            "Block": "279",
            "label": "REEDS WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21078",
            "district": "4",
            "Block": "156",
            "label": "GEDEE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21079",
            "district": "4",
            "Block": "245",
            "label": "RATHINAM INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21080",
            "district": "4",
            "Block": "279",
            "label": "ALCHEMY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21081",
            "district": "4",
            "Block": "279",
            "label": "DOBBS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21082",
            "district": "4",
            "Block": "279",
            "label": "THE NGP SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21083",
            "district": "4",
            "Block": "247",
            "label": "ARB INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21087",
            "district": "4",
            "Block": "302",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21128",
            "district": "4",
            "Block": "279",
            "label": "SAMSKAARA ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21156",
            "district": "4",
            "Block": "107",
            "label": "SHREE SARASSWATHI VIDHYAAH MANDHEER MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21181",
            "district": "4",
            "Block": "245",
            "label": "AMRITA VIDYALAYAM CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21182",
            "district": "4",
            "Block": "279",
            "label": "NATIONAL MODEL SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21183",
            "district": "4",
            "Block": "302",
            "label": "PARK GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21253",
            "district": "4",
            "Block": "242",
            "label": "SAI VIDHYA NIKETHAN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21272",
            "district": "4",
            "Block": "248",
            "label": "SHRI VINAYAGAR MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21312",
            "district": "4",
            "Block": "156",
            "label": "AMRITA VIDYALAYAM SENIOR SECONDARY SCHOOL, ETTIMADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21313",
            "district": "4",
            "Block": "245",
            "label": "ORISON ACADEMY SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21314",
            "district": "4",
            "Block": "279",
            "label": "SRI JAYENDRA SARASWATHY VIDYALAYA   SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21315",
            "district": "4",
            "Block": "126",
            "label": "VIVEK VIDYA MANDIR (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21316",
            "district": "4",
            "Block": "19",
            "label": "K.G.INTERNATIONALSCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21318",
            "district": "4",
            "Block": "107",
            "label": "VIDYAA VIKAS INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21354",
            "district": "4",
            "Block": "52",
            "label": "STANES ICSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21358",
            "district": "4",
            "Block": "156",
            "label": "GHS MYLERIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21359",
            "district": "4",
            "Block": "245",
            "label": "GHS SUGUNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21361",
            "district": "4",
            "Block": "248",
            "label": "GHS MAKKINAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21501",
            "district": "4",
            "Block": "19",
            "label": "GOVT ADIDRAVIDA WELFARE HIGH SCHOOL, PASUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21598",
            "district": "4",
            "Block": "349",
            "label": "CMC INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21600",
            "district": "4",
            "Block": "248",
            "label": "CARMEL JYOTHI CMI PUBLIC SCHOOL, UNJAVELAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21601",
            "district": "4",
            "Block": "248",
            "label": "VINAYAGA VIDYODHAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21642",
            "district": "4",
            "Block": "349",
            "label": "ADHYAYANA INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21647",
            "district": "4",
            "Block": "248",
            "label": "HONEY BUNCH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21648",
            "district": "4",
            "Block": "19",
            "label": "SRI AMBAL THULASI PUBLIC SCHOOL SENIOR SECONDARY CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21653",
            "district": "4",
            "Block": "19",
            "label": "NAVABHARATH NATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21868",
            "district": "4",
            "Block": "349",
            "label": "KARUNYA CHIRISTIAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21869",
            "district": "4",
            "Block": "279",
            "label": "SNS ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21870",
            "district": "4",
            "Block": "279",
            "label": "NAGARATHINAM INTERNATIONAL SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21871",
            "district": "4",
            "Block": "279",
            "label": "ADITHYA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21872",
            "district": "4",
            "Block": "279",
            "label": "DON BOSCO SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21873",
            "district": "4",
            "Block": "245",
            "label": "C.S ACADEMY CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21874",
            "district": "4",
            "Block": "156",
            "label": "BRINDHAVAN VIDHYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21882",
            "district": "4",
            "Block": "107",
            "label": "VIDYAA VIKAS MAT. GIRLS HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21883",
            "district": "4",
            "Block": "52",
            "label": "SUGUNA INTERNATIONAL  CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21889",
            "district": "4",
            "Block": "156",
            "label": "L.K.MAHA VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21957",
            "district": "4",
            "Block": "302",
            "label": "TIRUPUR HR SEC SCHOOL FOR THE DEAF",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22134",
            "district": "4",
            "Block": "52",
            "label": "BHARATHI REHABILITATION CENTRE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22135",
            "district": "4",
            "Block": "52",
            "label": "STANES SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22136",
            "district": "4",
            "Block": "52",
            "label": "JEYAM SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22137",
            "district": "4",
            "Block": "156",
            "label": "THE SAMHITA  ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22138",
            "district": "4",
            "Block": "156",
            "label": "SREE VISHNU VIDHYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22139",
            "district": "4",
            "Block": "242",
            "label": "NOVA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22140",
            "district": "4",
            "Block": "242",
            "label": "BAPTIST ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22141",
            "district": "4",
            "Block": "242",
            "label": "YOUCAN SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22142",
            "district": "4",
            "Block": "279",
            "label": "GITHANJALI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22143",
            "district": "4",
            "Block": "279",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Ondipudur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22147",
            "district": "4",
            "Block": "19",
            "label": "KOVAI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22156",
            "district": "4",
            "Block": "301",
            "label": "SHASWI INTERNATIONAL SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22157",
            "district": "4",
            "Block": "301",
            "label": "SRI DHAYANANDAPURI MODEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22158",
            "district": "4",
            "Block": "302",
            "label": "SRI KANDHA VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22159",
            "district": "4",
            "Block": "302",
            "label": "HOLY FAMILY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22160",
            "district": "4",
            "Block": "302",
            "label": "SRI VIVEKANANDHA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22161",
            "district": "4",
            "Block": "302",
            "label": "YELLOW TRAIN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22474",
            "district": "4",
            "Block": "156",
            "label": "VIAN VEENAI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22475",
            "district": "4",
            "Block": "156",
            "label": "GOVERNMENT HIGH SCHOOL-KANDEGOUNDEN CHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22476",
            "district": "4",
            "Block": "242",
            "label": "AKSHARAM INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22477",
            "district": "4",
            "Block": "242",
            "label": "THE OOTY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22478",
            "district": "4",
            "Block": "242",
            "label": "THE FIRST SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22479",
            "district": "4",
            "Block": "245",
            "label": "PEEPAL PRODIGY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22480",
            "district": "4",
            "Block": "279",
            "label": "JEEVANS NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22481",
            "district": "4",
            "Block": "279",
            "label": "ADITHYA PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22482",
            "district": "4",
            "Block": "349",
            "label": "IDHAZHL NATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22483",
            "district": "4",
            "Block": "349",
            "label": "VISHWANKAR PUBLIC SCHOOL POOLUVAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22484",
            "district": "4",
            "Block": "13",
            "label": "GHS PERIYAPODHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22487",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGH SCHOOL WATER FALLS EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22488",
            "district": "4",
            "Block": "107",
            "label": "TRG MATRICULATION SCHOOL, BUJANGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22489",
            "district": "4",
            "Block": "107",
            "label": "MUNICIPAL HIGH SCHOOL MANI NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22492",
            "district": "4",
            "Block": "302",
            "label": "GOVERNMENT HIGH SCHOOL KADAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22696",
            "district": "4",
            "Block": "156",
            "label": "NIRMALA MATHA CONVENT SCHOOL (ICSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22748",
            "district": "4",
            "Block": "107",
            "label": "BHARATH VIDHYA NIKETHAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22803",
            "district": "4",
            "Block": "52",
            "label": "PRAKRIYA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22813",
            "district": "4",
            "Block": "279",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22814",
            "district": "4",
            "Block": "279",
            "label": "EINSTEIN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22815",
            "district": "4",
            "Block": "279",
            "label": "BRILLIANT VIDYA BHAVAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22816",
            "district": "4",
            "Block": "279",
            "label": "THE AALAM INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22817",
            "district": "4",
            "Block": "279",
            "label": "INDIAN PUBLIC CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22818",
            "district": "4",
            "Block": "247",
            "label": "P.A.INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22819",
            "district": "4",
            "Block": "247",
            "label": "MAHARISHI INTERATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22820",
            "district": "4",
            "Block": "107",
            "label": "VIVIDH INTERNATIONAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22823",
            "district": "4",
            "Block": "242",
            "label": "THE UNITED PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22837",
            "district": "4",
            "Block": "13",
            "label": "VIDHYA VISHWALAYA CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22854",
            "district": "4",
            "Block": "302",
            "label": "KONGU PUBLIC SCHOOL, KARUMATHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22929",
            "district": "4",
            "Block": "245",
            "label": "GHS EDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22930",
            "district": "4",
            "Block": "245",
            "label": "CORPORATION HIGH SCHOOL, RAMASAMY NAGAR, NALLAMPALAYAM ROAD.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22935",
            "district": "4",
            "Block": "156",
            "label": "AATHREIYA SCHOOL FOR EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22936",
            "district": "4",
            "Block": "302",
            "label": "GHS NEELAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22954",
            "district": "4",
            "Block": "156",
            "label": "GHS OTHAKKALMANDAPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22976",
            "district": "4",
            "Block": "13",
            "label": "GHS RAMANAMUDHALIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22977",
            "district": "4",
            "Block": "107",
            "label": "BHAGWAN MAHAVEER MUNICIPAL HIGH SCHOOL, SANKAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22979",
            "district": "4",
            "Block": "279",
            "label": "SSVM SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22980",
            "district": "4",
            "Block": "279",
            "label": "PERKS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23051",
            "district": "4",
            "Block": "19",
            "label": "NAVA BHARATH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23059",
            "district": "4",
            "Block": "279",
            "label": "SRI RAMAKRISHNA CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23060",
            "district": "4",
            "Block": "279",
            "label": "RAK'S PALLIKKOODAM SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23088",
            "district": "4",
            "Block": "245",
            "label": "SIDHAR GNANA PEEDAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23089",
            "district": "4",
            "Block": "279",
            "label": "RAK'S PALLIKKOODAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23090",
            "district": "4",
            "Block": "279",
            "label": "MANCHESTER INTERNATIONAL SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23098",
            "district": "4",
            "Block": "279",
            "label": "KOVAI VIDYA MANDHIR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23099",
            "district": "4",
            "Block": "245",
            "label": "NIRMALA MATHA CONVENT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23132",
            "district": "4",
            "Block": "52",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23152",
            "district": "4",
            "Block": "52",
            "label": "LISIEUX CMI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23160",
            "district": "4",
            "Block": "52",
            "label": "NAVY CHILDREN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23163",
            "district": "4",
            "Block": "52",
            "label": "SNV GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23175",
            "district": "4",
            "Block": "107",
            "label": "KARAMADAI RANGANATHAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23676",
            "district": "4",
            "Block": "52",
            "label": "ADWAITH THOUGHT ACADEMY, Coimbatore",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23672",
            "district": "4",
            "Block": "349",
            "label": "K.G Public Matric Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23180",
            "district": "4",
            "Block": "279",
            "label": "ADITHYA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23184",
            "district": "4",
            "Block": "52",
            "label": "BHARATHI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23208",
            "district": "4",
            "Block": "19",
            "label": "WHITE EAGLE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23211",
            "district": "4",
            "Block": "242",
            "label": "CHETTINAD VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23216",
            "district": "4",
            "Block": "242",
            "label": "AKSHARA ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23235",
            "district": "4",
            "Block": "245",
            "label": "GHS KUNIYAMUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23686",
            "district": "4",
            "Block": "19",
            "label": "KPR Women Employees Educational Division",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23236",
            "district": "4",
            "Block": "13",
            "label": "GHS ANGALAKURUCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23237",
            "district": "4",
            "Block": "247",
            "label": "GHS R.GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23238",
            "district": "4",
            "Block": "302",
            "label": "MATER DEI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23281",
            "district": "4",
            "Block": "156",
            "label": "NEHRU INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23282",
            "district": "4",
            "Block": "279",
            "label": "VIBGYOR HIGH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23308",
            "district": "4",
            "Block": "279",
            "label": "VIDHYA NIKETAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23312",
            "district": "4",
            "Block": "156",
            "label": "THAAMARAI WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23340",
            "district": "4",
            "Block": "242",
            "label": "ANGAPPA ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23344",
            "district": "4",
            "Block": "242",
            "label": "AKSHAYA ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23425",
            "district": "4",
            "Block": "19",
            "label": "BENEVOLENCE INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23460",
            "district": "4",
            "Block": "13",
            "label": "SRINITHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23461",
            "district": "4",
            "Block": "52",
            "label": "Government Model School, Coimbatore",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23462",
            "district": "4",
            "Block": "156",
            "label": "FAITH MODEL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23463",
            "district": "4",
            "Block": "242",
            "label": "IMMACULATE INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23464",
            "district": "4",
            "Block": "242",
            "label": "AVANI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23465",
            "district": "4",
            "Block": "242",
            "label": "BHARATHIYA VIDYA BHAVAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23466",
            "district": "4",
            "Block": "245",
            "label": "PRISTINE NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23701",
            "district": "4",
            "Block": "349",
            "label": "sri venkatachalatathy vidyalaya matric hr. sec. school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23467",
            "district": "4",
            "Block": "245",
            "label": "CS ACADEMY INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23468",
            "district": "4",
            "Block": "245",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23469",
            "district": "4",
            "Block": "247",
            "label": "ARAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23470",
            "district": "4",
            "Block": "248",
            "label": "SHAANTHI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23471",
            "district": "4",
            "Block": "254",
            "label": "COIMBATORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23472",
            "district": "4",
            "Block": "302",
            "label": "SBIOA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23473",
            "district": "4",
            "Block": "349",
            "label": "KIKANI GLOBAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23721",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23722",
            "district": "4",
            "Block": "19",
            "label": "KOVAI VIDYASHRAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23758",
            "district": "4",
            "Block": "52",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23759",
            "district": "4",
            "Block": "52",
            "label": "COIMBATORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23760",
            "district": "4",
            "Block": "52",
            "label": "CARMEL GARDEN PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23811",
            "district": "4",
            "Block": "107",
            "label": "ST.JOSEPH'S MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23812",
            "district": "4",
            "Block": "107",
            "label": "NAV BHARATH MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23813",
            "district": "4",
            "Block": "107",
            "label": "EXPONICS ACADEMY",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23829",
            "district": "4",
            "Block": "126",
            "label": "VIDYARPANA  HOME MATRICULATION SCHOOL  ",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23858",
            "district": "4",
            "Block": "156",
            "label": "PUMS RANGASAMUTHIRAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23859",
            "district": "4",
            "Block": "156",
            "label": "ELGI SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23860",
            "district": "4",
            "Block": "156",
            "label": "VIAN VEENAI PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23919",
            "district": "4",
            "Block": "242",
            "label": "AVILA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23920",
            "district": "4",
            "Block": "242",
            "label": "ADITYA VIDYASHRAM - GURUGRAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23922",
            "district": "4",
            "Block": "245",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23923",
            "district": "4",
            "Block": "245",
            "label": "SINDHI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23924",
            "district": "4",
            "Block": "245",
            "label": "THE HARVEE SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23925",
            "district": "4",
            "Block": "247",
            "label": "PUMS ALAMPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23926",
            "district": "4",
            "Block": "248",
            "label": "GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23927",
            "district": "4",
            "Block": "248",
            "label": "NACHIAR - THE WORLD SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23946",
            "district": "4",
            "Block": "279",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23947",
            "district": "4",
            "Block": "279",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23948",
            "district": "4",
            "Block": "279",
            "label": "SRI RAMAKRISHNA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23949",
            "district": "4",
            "Block": "279",
            "label": "PSG MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23950",
            "district": "4",
            "Block": "279",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23951",
            "district": "4",
            "Block": "279",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23952",
            "district": "4",
            "Block": "279",
            "label": "CHANDRAMARI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23953",
            "district": "4",
            "Block": "279",
            "label": "SAMASHTI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23954",
            "district": "4",
            "Block": "279",
            "label": "HINDUSTHAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23983",
            "district": "4",
            "Block": "301",
            "label": "WEST PAARK INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23984",
            "district": "4",
            "Block": "302",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23985",
            "district": "4",
            "Block": "302",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23986",
            "district": "4",
            "Block": "302",
            "label": "YELLOW TRAIN SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23987",
            "district": "4",
            "Block": "302",
            "label": "ZEN KIDZ MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23988",
            "district": "4",
            "Block": "302",
            "label": "SRI SUKA BRAHMA MAHARISHI VIDYA MANDIR INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23989",
            "district": "4",
            "Block": "302",
            "label": "AIR FORCE SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "5": [
        {
            "key": "373",
            "district": "5",
            "Block": "406",
            "label": "INFANT PREPARATORY MATRICULATION HIGHER SECONDARY SCHOOL-VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2772",
            "district": "5",
            "Block": "148",
            "label": "PUMS THONDAMANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2790",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - KALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2819",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  CHELLANCHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2825",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THERKUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2833",
            "district": "5",
            "Block": "148",
            "label": "PUMS THENKUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2901",
            "district": "5",
            "Block": "148",
            "label": "ST MARY'S MATRICULATION SCHOOL,VADALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2927",
            "district": "5",
            "Block": "39",
            "label": "PUMS THALAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2935",
            "district": "5",
            "Block": "148",
            "label": "PUMS RAMANATHANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2940",
            "district": "5",
            "Block": "143",
            "label": "AMS  KOTHAVASAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2971",
            "district": "5",
            "Block": "148",
            "label": "PUMS - THANGALIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3020",
            "district": "5",
            "Block": "148",
            "label": "PUMS VADAKUMELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3038",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  NACHIYARPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3108",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3115",
            "district": "5",
            "Block": "148",
            "label": "PUMS THAMBIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3128",
            "district": "5",
            "Block": "39",
            "label": "PUMS ADHIVARAGANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3131",
            "district": "5",
            "Block": "148",
            "label": "PUMS PARVATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3177",
            "district": "5",
            "Block": "39",
            "label": "AMS VEERAMUDAIYANATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3535",
            "district": "5",
            "Block": "54",
            "label": "ST ANTONY'S MATRICULATION HIGHER SECONDARY SCHOOL - CHINNAKANGANANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3578",
            "district": "5",
            "Block": "143",
            "label": "PUMS JAHIR HUSSAIN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3603",
            "district": "5",
            "Block": "39",
            "label": "AMS MULLIPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3622",
            "district": "5",
            "Block": "39",
            "label": "PUMS BHUVANAGIRI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3636",
            "district": "5",
            "Block": "148",
            "label": "PUMS ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3656",
            "district": "5",
            "Block": "39",
            "label": "SARASWATHI MATRICULATION SCHOOL BOOTHAVARAYANPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3659",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - CHINNA IRUSAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3692",
            "district": "5",
            "Block": "148",
            "label": "PUMS MEENAKSHIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3720",
            "district": "5",
            "Block": "39",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , BOOTHAVARAYANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3725",
            "district": "5",
            "Block": "39",
            "label": "PUMS LALPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3752",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  M PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3813",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL - DEVANAMPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3821",
            "district": "5",
            "Block": "143",
            "label": "PUMS VADAKKUKOLAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3895",
            "district": "5",
            "Block": "143",
            "label": "AMS VARAVARA MUNI CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3916",
            "district": "5",
            "Block": "143",
            "label": "PUMS NANTHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3951",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL ALICHIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3953",
            "district": "5",
            "Block": "54",
            "label": "SARVA SEVA MATRICULATION SCHOOL, KANNARAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3985",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THONDANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3997",
            "district": "5",
            "Block": "148",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  PERIYAKATTUSAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4006",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOZHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4014",
            "district": "5",
            "Block": "120",
            "label": "AIDED MIDDLE SCHOOL- POONTHOTTAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4018",
            "district": "5",
            "Block": "39",
            "label": "PUMS KUMUDIMOOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4031",
            "district": "5",
            "Block": "39",
            "label": "PUMS C.ALAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4037",
            "district": "5",
            "Block": "39",
            "label": "PUMS NELLIKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4059",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL -  PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4076",
            "district": "5",
            "Block": "115",
            "label": "PUMS SRI ADIVARAGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4089",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  PETHANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4106",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PATTAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4112",
            "district": "5",
            "Block": "120",
            "label": "AIDED-VALLALAR AIDED MIDDLE SCHOOL- PUDHUPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4114",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ,  POTHIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4123",
            "district": "5",
            "Block": "143",
            "label": "PUMS N.POOLAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4135",
            "district": "5",
            "Block": "39",
            "label": "PUMS KARAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4148",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ANANTHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4159",
            "district": "5",
            "Block": "98",
            "label": "PUMS KARKUDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4167",
            "district": "5",
            "Block": "143",
            "label": "ADWMS- VARAHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4178",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - VAZHISOTHANAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4185",
            "district": "5",
            "Block": "120",
            "label": "AIDED-RASAMBAL ACHI MIDDLE SCHOOL, PONNAKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4202",
            "district": "5",
            "Block": "39",
            "label": "PUMS, VATHARAYANTHETHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4204",
            "district": "5",
            "Block": "98",
            "label": "PUMS VEENANKENI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4236",
            "district": "5",
            "Block": "120",
            "label": "S.D.SION MATRICULATION HIGHER SECONDARY SCHOOL, BOOTHANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4238",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL KODUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4273",
            "district": "5",
            "Block": "17",
            "label": "BWDA MATRIC HR SEC SCHOOL,  RASAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4301",
            "district": "5",
            "Block": "39",
            "label": "PUMS KEEZHAMOONGILADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4365",
            "district": "5",
            "Block": "143",
            "label": "AMS ANNAI SATHIYA",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4402",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL- KALIYAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4421",
            "district": "5",
            "Block": "148",
            "label": "PUMS ELLAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4441",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SATHAVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4472",
            "district": "5",
            "Block": "39",
            "label": "PUGMS PERUMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4474",
            "district": "5",
            "Block": "98",
            "label": "PUMS PUDHUVIRUDHAGIRIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4509",
            "district": "5",
            "Block": "199",
            "label": "PUMS MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4527",
            "district": "5",
            "Block": "39",
            "label": "PUMS MELAMOONGILADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4535",
            "district": "5",
            "Block": "98",
            "label": "PUMS U.ADHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4540",
            "district": "5",
            "Block": "98",
            "label": "PUMS KO.MAVIDANTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4557",
            "district": "5",
            "Block": "39",
            "label": "ADWS THILLAINAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4580",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAVALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4601",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - RAMAPURAM EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4605",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL - REDDY CHATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4626",
            "district": "5",
            "Block": "170",
            "label": "SRI GNANAGURU VIDYALAYA MATRICULATION HIGHER  SECONDARY SCHOOL,  TITTAGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4641",
            "district": "5",
            "Block": "115",
            "label": "PUMS UDAIYARGUDI MIXED",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4654",
            "district": "5",
            "Block": "199",
            "label": "PUMS , KOTHATTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4662",
            "district": "5",
            "Block": "199",
            "label": "PUMS KANDAPPANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4685",
            "district": "5",
            "Block": "148",
            "label": "APS KRISHNANKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4716",
            "district": "5",
            "Block": "143",
            "label": "MPLMS MANALANE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4720",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VALLIMATHURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4749",
            "district": "5",
            "Block": "148",
            "label": "THIRUMURUGAN MIDDLE SCHOOL., V.PUDUKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4761",
            "district": "5",
            "Block": "98",
            "label": "SAMI VIVEKANANTHA MATRICULATION SCHOOL-SEPLANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4763",
            "district": "5",
            "Block": "199",
            "label": "PUMS SEVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4766",
            "district": "5",
            "Block": "115",
            "label": "AMS VEERANANDAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4783",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KILLIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4843",
            "district": "5",
            "Block": "39",
            "label": "PUMS NATHAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4846",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4871",
            "district": "5",
            "Block": "143",
            "label": "VENUS MATRICULATION HIGHER SECONDARY SCHOOL,THILLAI NAGAR,CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4881",
            "district": "5",
            "Block": "98",
            "label": "PUMS A. VALLIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4883",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EYYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4904",
            "district": "5",
            "Block": "406",
            "label": "MUNICIPAL MIDDLE SCHOOL SOUTH FORT STREET-VRIDHACHALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4918",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEEZHANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4923",
            "district": "5",
            "Block": "227",
            "label": "MUNICIPAL  MIDDLE SCHOOL  GANDHIROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4936",
            "district": "5",
            "Block": "39",
            "label": "AMS AMBAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4949",
            "district": "5",
            "Block": "143",
            "label": "PUMS VALLAMPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4952",
            "district": "5",
            "Block": "39",
            "label": "PUMS KATHAZHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4969",
            "district": "5",
            "Block": "54",
            "label": "BHAVANI AMMAL MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL, K.N.PETTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4972",
            "district": "5",
            "Block": "233",
            "label": "PUMS GUMATHPALLI MIDDLE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5010",
            "district": "5",
            "Block": "227",
            "label": "MUNICIPAL MIDDLE  SCHOOL SEERANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5012",
            "district": "5",
            "Block": "115",
            "label": "VIVEKANANDAN AIDED MIDDLE SCHOOL, MELAKADAMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5021",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL- C.MELAVANNIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5040",
            "district": "5",
            "Block": "98",
            "label": "AMS T. PAVAZHANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5042",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - ARISIPERIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5051",
            "district": "5",
            "Block": "148",
            "label": "PUMS MANTHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5052",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VISOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5067",
            "district": "5",
            "Block": "211",
            "label": "KALAIMAGAL AIDED MIDDLE SCHOOL, MOOLAKUPPAM @KATTUKOLLAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5088",
            "district": "5",
            "Block": "148",
            "label": "PUMS THIYAGAVALLI 2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5093",
            "district": "5",
            "Block": "148",
            "label": "PUMS SAMATTIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5141",
            "district": "5",
            "Block": "148",
            "label": "PUMS PETHANAYAKKANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5173",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MADURANTHAGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5177",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ODDAKANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5204",
            "district": "5",
            "Block": "17",
            "label": "PUMS KATTUMUTHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5211",
            "district": "5",
            "Block": "233",
            "label": "PUMS KILLAIPATTINAVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5221",
            "district": "5",
            "Block": "98",
            "label": "PUMS, A.KURAVANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5225",
            "district": "5",
            "Block": "143",
            "label": "PUMS PAZHAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5238",
            "district": "5",
            "Block": "211",
            "label": "NLC MIDDLE SCHOOL BLOCK- 9  NEYVELI -TS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5241",
            "district": "5",
            "Block": "54",
            "label": "AIDED MIDDLE SCHOOL - SRI RAMAKRISHNA - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5248",
            "district": "5",
            "Block": "98",
            "label": "PUMS PALLAKOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5253",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALASAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5262",
            "district": "5",
            "Block": "115",
            "label": "PUMS THIRUCHENNAPURM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5272",
            "district": "5",
            "Block": "143",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL LALPET MAIN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5305",
            "district": "5",
            "Block": "17",
            "label": "PUMS C.PAGANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5314",
            "district": "5",
            "Block": "98",
            "label": "SIVABHARATHI MATRICULATION HIGHER SECONDARY SCHOOL SIRUVARAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5328",
            "district": "5",
            "Block": "170",
            "label": "SARASWATHI ARIVALAYAM HIGHER SECONDARY  SCHOOL , KALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5337",
            "district": "5",
            "Block": "39",
            "label": "SRI ARUNACHALA  MATRICULATION  HIGHER SECONDARY  SCHOOL,  BHUVANAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5341",
            "district": "5",
            "Block": "233",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SILAMBIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5391",
            "district": "5",
            "Block": "233",
            "label": "PUMS PICHAVARAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5425",
            "district": "5",
            "Block": "233",
            "label": "PUMS UTHAMASOZHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5435",
            "district": "5",
            "Block": "406",
            "label": "MUNICIPAL MIDDLE SCHOOL BOODHAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5447",
            "district": "5",
            "Block": "115",
            "label": "PUMS AMBUJAVALLIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5467",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOLVEGAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5469",
            "district": "5",
            "Block": "143",
            "label": "MPLMS NORTH CAR STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5472",
            "district": "5",
            "Block": "120",
            "label": "AMS ENNANAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5505",
            "district": "5",
            "Block": "115",
            "label": "AMS KANDAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5528",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5531",
            "district": "5",
            "Block": "143",
            "label": "TELC MIDDLE M.ODAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5536",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VADAHARIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5575",
            "district": "5",
            "Block": "98",
            "label": "PUMS UYAKONDARAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5585",
            "district": "5",
            "Block": "233",
            "label": "PUMS PUNJAIMAHATHUVAZHKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5589",
            "district": "5",
            "Block": "233",
            "label": "PUMS KOVILAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5604",
            "district": "5",
            "Block": "120",
            "label": "AMS THANDESWARANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5606",
            "district": "5",
            "Block": "98",
            "label": "PUMS SATHAPPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5615",
            "district": "5",
            "Block": "199",
            "label": "PUMS T.PUDAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5616",
            "district": "5",
            "Block": "143",
            "label": "PUMS VADAKKUMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5620",
            "district": "5",
            "Block": "115",
            "label": "PUMS M.ADHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5622",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - U.C CHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5633",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL MANAMTHAVIZHNTHAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5651",
            "district": "5",
            "Block": "39",
            "label": "PUMS ESWARAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5665",
            "district": "5",
            "Block": "17",
            "label": "PUMS CHITARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5697",
            "district": "5",
            "Block": "148",
            "label": "ANANDHAN MEMORIAL MATRIC HR SEC  SCHOOL -VANNIYARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5702",
            "district": "5",
            "Block": "17",
            "label": "PUMS MUTHUKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5708",
            "district": "5",
            "Block": "199",
            "label": "PUMS PENNADAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5729",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL - BROOKEESPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5730",
            "district": "5",
            "Block": "98",
            "label": "AMS VILAKKAPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5754",
            "district": "5",
            "Block": "98",
            "label": "PUMS AMMERIKOKKANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5755",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL SEMBALAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5756",
            "district": "5",
            "Block": "199",
            "label": "PUMS A.MARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23670",
            "district": "5",
            "Block": "406",
            "label": "BVB Matriculation Hr.Sec.School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "5762",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SANGOLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5782",
            "district": "5",
            "Block": "199",
            "label": "ADWS ILANGIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5809",
            "district": "5",
            "Block": "199",
            "label": "PUMS VEPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5813",
            "district": "5",
            "Block": "233",
            "label": "PUMS THILLAIVIDANGAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5814",
            "district": "5",
            "Block": "199",
            "label": "PUMS ADHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5829",
            "district": "5",
            "Block": "199",
            "label": "PUMS NARASINGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5834",
            "district": "5",
            "Block": "17",
            "label": "PUMS PAITHAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5843",
            "district": "5",
            "Block": "227",
            "label": "MUNICIPAL MIDDLE  SCHOOL MELAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5845",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL NADUKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5855",
            "district": "5",
            "Block": "98",
            "label": "PUMS CHINNAKOTTUMULAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5863",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AYYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5883",
            "district": "5",
            "Block": "54",
            "label": "AIDED MIDDLE SCHOOL - SACRED HEART - PUHUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5900",
            "district": "5",
            "Block": "115",
            "label": "PUMS SHANDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5915",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5918",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL MANGALAMPETTAI ( MUSLIM )",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5931",
            "district": "5",
            "Block": "170",
            "label": "ANNAI TERESA MATRICULATION HIGHER SECONDARY SCHOOL , KEELAKALPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5947",
            "district": "5",
            "Block": "115",
            "label": "SARASWATHI AIDED MIDDLE SCHOOL, GUNAMANGALAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5952",
            "district": "5",
            "Block": "17",
            "label": "PUMS SUNDARAVANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5957",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5963",
            "district": "5",
            "Block": "39",
            "label": "PUMS PERIYANARKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5971",
            "district": "5",
            "Block": "143",
            "label": "MPLMS MALAIKATTI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5973",
            "district": "5",
            "Block": "143",
            "label": "AMS SIVAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5978",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - KODUKKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5981",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - THIRUMANIKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6040",
            "district": "5",
            "Block": "143",
            "label": "PUMS KADAVACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6044",
            "district": "5",
            "Block": "406",
            "label": "HINDU AIDED MIDDLE SCHOOL IRUSALAKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6051",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6115",
            "district": "5",
            "Block": "199",
            "label": "PUMS PENNADAM EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6129",
            "district": "5",
            "Block": "98",
            "label": "PUMS V.SATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6134",
            "district": "5",
            "Block": "233",
            "label": "ISHA VIDHYA MATRICULATION HIGHER SECONDARY.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6155",
            "district": "5",
            "Block": "54",
            "label": "KRISHNASAMY MEMORIAL MATRIC HIGHER SECONDARY SCHOOL,CUDDALORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6162",
            "district": "5",
            "Block": "211",
            "label": "ST. ANTONY'S MATRICULATION HIGHER SECONDARY SCHOOL  BLOCK-29 NEYVELI- TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6186",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL (MUSLIM) , PASAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6187",
            "district": "5",
            "Block": "143",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL KOLLIMALAI MELPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6216",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SOTHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6239",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL M.AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6262",
            "district": "5",
            "Block": "54",
            "label": "ST.MARY'S MATRICULATION HR.SEC.SCHOOL, CUDDALORE-607001.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6274",
            "district": "5",
            "Block": "233",
            "label": "PUMS PONNANTHITTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6312",
            "district": "5",
            "Block": "199",
            "label": "PUMS PASIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6315",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PARATHUR SAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6322",
            "district": "5",
            "Block": "211",
            "label": "JAWAHAR HIGHER SECONDARY SCHOOL, BLOCK-17 NEYVELI -TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6342",
            "district": "5",
            "Block": "199",
            "label": "PUMS SENGAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6357",
            "district": "5",
            "Block": "143",
            "label": "IMAM GAZZALI MATRIC HIGHER SECONDARYSCHOOL- LALPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6358",
            "district": "5",
            "Block": "98",
            "label": "PUMS- KEEZHAPALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6361",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEVVERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6363",
            "district": "5",
            "Block": "143",
            "label": "NIRMALA MATRICULATION HIGHER SECONDARY SCHOOL,CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6389",
            "district": "5",
            "Block": "199",
            "label": "PUMS PA. KOTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6424",
            "district": "5",
            "Block": "406",
            "label": "FATIMA MATRICULATION HIGHER SECONDARY SCHOOL VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6442",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL T.V. PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6463",
            "district": "5",
            "Block": "143",
            "label": "PUMS SIRAKILANDANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6484",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - EDAIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6490",
            "district": "5",
            "Block": "120",
            "label": "SRI VIJAYA MAT. PALAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6509",
            "district": "5",
            "Block": "199",
            "label": "PUMS VANNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6510",
            "district": "5",
            "Block": "233",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - ADHIVARAGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6532",
            "district": "5",
            "Block": "233",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ARIYAGOSHTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6534",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANTHAKUMARAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6560",
            "district": "5",
            "Block": "98",
            "label": "PUMS PAZHAAYAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6574",
            "district": "5",
            "Block": "199",
            "label": "PUMS ERAPPAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23368",
            "district": "5",
            "Block": "39",
            "label": "BEST MATRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "6580",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL KUPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6583",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL - SIMON GARDEN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6605",
            "district": "5",
            "Block": "17",
            "label": "PUMS S.K.PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6606",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL SATHIYAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6612",
            "district": "5",
            "Block": "170",
            "label": "PUMS , NAVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6615",
            "district": "5",
            "Block": "54",
            "label": "CK SCHOOL OF PRACTICAL KNOWLEDGE MATRICULATION HIGHER SECONDARY SCHOOL - CUDDALORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6625",
            "district": "5",
            "Block": "233",
            "label": "PUMS THACHAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6631",
            "district": "5",
            "Block": "115",
            "label": "ARUNACHALAM HINDU AIDED MIDDLE SCHOOL, KANATTAMPULIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6658",
            "district": "5",
            "Block": "17",
            "label": "MMS MULLIGRAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6680",
            "district": "5",
            "Block": "233",
            "label": "THIRUVALLUVAR AMS VILLIYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6700",
            "district": "5",
            "Block": "54",
            "label": "A.R.L.M.MATRICULATION HIGHER SECONDARY SCHOOL, VILVA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15123",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SUTHUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15124",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  SANRORPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15125",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - C.N.PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15126",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL -  C.N.PALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15127",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - THAZHANKUDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15128",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  KARAIKADU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15129",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - KARAIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15130",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - KEEZH AZHINJIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15131",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - KEEZH KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15132",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - RASAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15133",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - PERIYAKATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15134",
            "district": "5",
            "Block": "54",
            "label": "VELLAI THAMARAI MATRICULATION SCHOOL - CHINNA KATTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15135",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  KUTTIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15136",
            "district": "5",
            "Block": "54",
            "label": "KRISHNASAMY MATRICULATION HIGHER SECONDARY SCHOOL OF EXCELLENCE - S.KUMARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15137",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - NADUVEERAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15138",
            "district": "5",
            "Block": "54",
            "label": "AIDED MIDDLE SCHOOL - APPAR- NALLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15139",
            "district": "5",
            "Block": "54",
            "label": "AKSHARA VIDYAASHRAM SENIOR SECONDARY  SCHOOL (CBSE), NATHAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15140",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - PACHAYANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15141",
            "district": "5",
            "Block": "54",
            "label": "ST THOMAS MATRICULATION SCHOOL - KOOTHAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15142",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - PERIYA KANGANANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15143",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - RAMAPURAM WEST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15144",
            "district": "5",
            "Block": "54",
            "label": "AHS - ANNAI VELANKANNI CLUNY- SATHANKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15145",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SEDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15146",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SEMMANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15147",
            "district": "5",
            "Block": "54",
            "label": "MAHATMA GANDHI INTERNATIONAL SCHOOL- SINGIRIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15148",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - THOOKKANAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15149",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - THIRUVANDIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15150",
            "district": "5",
            "Block": "54",
            "label": "SRI VIDYA KALA KENDRAM MATRICULATION HIGH SCHOOL, THIRUVANTHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15151",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - THOTTAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15152",
            "district": "5",
            "Block": "54",
            "label": "KRISHNASWAMY VIDYA NIKETHAN SENIOR SECONDARY SCHOOL (CBSE) - S.KUMARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15153",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - VANAMADEVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15154",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH  SCHOOL - VARAKALPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15155",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - V.KATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15156",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - VELLAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15157",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - VILANGALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15158",
            "district": "5",
            "Block": "54",
            "label": "AIDED MIDDLE SCHOOL ST MARY'S - PUDHUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15159",
            "district": "5",
            "Block": "54",
            "label": "MODERN MATRICULATION HIGHER SECONDARY SCHOOL, VELI SEMMANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15160",
            "district": "5",
            "Block": "54",
            "label": "SAVIOUR JESUS MATRICULATION HIGHER SECONDARY SCHOOL - CUDDALORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15161",
            "district": "5",
            "Block": "54",
            "label": "SRI VARADHAM GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL -  VENUGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15162",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - VANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15163",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - THIRUPATHIRIPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15164",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL HIGHER SECONDARY SCHOOL - CUDDALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15165",
            "district": "5",
            "Block": "54",
            "label": "ST JOSEPH'S HIGHER SECONDARY SCHOOL - MANJAKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15166",
            "district": "5",
            "Block": "54",
            "label": "ST JOSEPH'S HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15167",
            "district": "5",
            "Block": "54",
            "label": "SRI PATALESWARAR HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15168",
            "district": "5",
            "Block": "54",
            "label": "ST ANNE'S GIRLS HIGHER SECONDARY SCHOOL - PUDHUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15169",
            "district": "5",
            "Block": "54",
            "label": "ST JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL - KOOTHAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15170",
            "district": "5",
            "Block": "54",
            "label": "LAKSHMI CHORDIA MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL - S.S.R.NAGAR- THIRUPPATHIRIPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15171",
            "district": "5",
            "Block": "54",
            "label": "NEW MILLENNIUM MATRICULATION HIGHER SECONARY SCHOOL - KUMARAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15172",
            "district": "5",
            "Block": "54",
            "label": "SRI VALLIAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, THIRUPPATHIRIPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15173",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - MANJAKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15174",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT DEAF AND DUMB MIDDLE SCHOOL - CUDDALORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15175",
            "district": "5",
            "Block": "54",
            "label": "SRI LAKSHMI CHORDIA MATRICULATION HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15176",
            "district": "5",
            "Block": "54",
            "label": "SRI MAHARISHI VIDHYA MANDHIR MATRICULATION SCHOOL - MANJAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15177",
            "district": "5",
            "Block": "54",
            "label": "ARISTO PUBLIC SCHOOL - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15178",
            "district": "5",
            "Block": "54",
            "label": "ADHIDRAVIDAR WELFARE MIDDLE SCHOOL - SELLANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15179",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - SANKARAN STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15180",
            "district": "5",
            "Block": "54",
            "label": "ST FRANCIS XAVIER MS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15181",
            "district": "5",
            "Block": "54",
            "label": "ST PHILOMINA'S GIRLS HIGH SCHOOL,CUDDALORE PORT",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15182",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - CUDDALORE PORT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15183",
            "district": "5",
            "Block": "54",
            "label": "ST DAVID'S HIGHER SECONDARY SCHOOL - CUDDALORE PORT",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15184",
            "district": "5",
            "Block": "54",
            "label": "KAMATCHI SHANMUGAM MATRICULATION HIGHER SECONDARY SCHOOL- SIVANANDHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15185",
            "district": "5",
            "Block": "54",
            "label": "SRI SARASWATHI VIDHYAALAYA MATRICULATION HIGHER SECONDARY SCHOOL , CUDDALORE  PORT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15186",
            "district": "5",
            "Block": "54",
            "label": "ST DAVID'S MATRICULATION SCHOOL- CUDALORE PORT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15187",
            "district": "5",
            "Block": "54",
            "label": "LAKSHMI MATRIC  SCHOOL - CUDDALORE PORT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15188",
            "district": "5",
            "Block": "54",
            "label": "A.G.M MIDDLE SCHOOL CUDDALORE PORT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15189",
            "district": "5",
            "Block": "17",
            "label": "PUMS-REDDIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15190",
            "district": "5",
            "Block": "17",
            "label": "PUMS BANDARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15191",
            "district": "5",
            "Block": "17",
            "label": "GHS ENATHIRIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15192",
            "district": "5",
            "Block": "17",
            "label": "GOVERNMENT HIGH SCHOOL,  KANDARAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15193",
            "district": "5",
            "Block": "17",
            "label": "PUMS KARUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15194",
            "district": "5",
            "Block": "17",
            "label": "GOVERNMENT HIGHER SEC SCHOOL, KOZHIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15195",
            "district": "5",
            "Block": "17",
            "label": "PUMS THATTAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15196",
            "district": "5",
            "Block": "17",
            "label": "GHS MELKUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15197",
            "district": "5",
            "Block": "17",
            "label": "PUMS MELPATTAMPAKKAM GIRLS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15198",
            "district": "5",
            "Block": "17",
            "label": "AHSS, G. CHR. HR. SEC. SCH, MELPATTAMPAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15199",
            "district": "5",
            "Block": "17",
            "label": "GHS, MELPATTAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15200",
            "district": "5",
            "Block": "17",
            "label": "PUMS ORAIYUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15201",
            "district": "5",
            "Block": "17",
            "label": "GHSS ORAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15202",
            "district": "5",
            "Block": "17",
            "label": "PUMS PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15203",
            "district": "5",
            "Block": "17",
            "label": "ST.PETER'S HS, T.SATHIPATTU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15204",
            "district": "5",
            "Block": "17",
            "label": "MOTHER THERESA MATRIC HIGHER SECONDARY SCHOOL - SATHIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15205",
            "district": "5",
            "Block": "17",
            "label": "GHSS, THIRUTHURAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15206",
            "district": "5",
            "Block": "17",
            "label": "GGHS, PUDHUPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15207",
            "district": "5",
            "Block": "17",
            "label": "GOVT BOYS HIGHER SECONDARY SCHOOL ,PUTHUPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15208",
            "district": "5",
            "Block": "17",
            "label": "AMS KILPATHY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15209",
            "district": "5",
            "Block": "17",
            "label": "Govt Boys Higher Secondary School,  NELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15210",
            "district": "5",
            "Block": "17",
            "label": "GGHSS, NELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15211",
            "district": "5",
            "Block": "17",
            "label": "BALAVIHAR MATRIC HR SEC SCHOOL, NELLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15212",
            "district": "5",
            "Block": "17",
            "label": "AHSS, NELLIKUPPAM (DM)",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15213",
            "district": "5",
            "Block": "17",
            "label": "ST. PAUL MAT. HR. SEC .SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15214",
            "district": "5",
            "Block": "17",
            "label": "ST.DOMINIC SAVIO MAT.HR.SEC. SCH.NELLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15215",
            "district": "5",
            "Block": "17",
            "label": "RR MATRIC HR SEC SCHOOL,NELLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15216",
            "district": "5",
            "Block": "17",
            "label": "MUNICIPAL HIGH SCHOOL,  THIRUKANDESWARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15217",
            "district": "5",
            "Block": "17",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15218",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL ANGUCHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15219",
            "district": "5",
            "Block": "227",
            "label": "AIDED  MIDDLE  SCHOOL R.C MOZHANDIKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15220",
            "district": "5",
            "Block": "227",
            "label": "KURINJI MATRICULATION HIGH SCHOOL, AZHAGAPPASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15221",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL PAVAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15222",
            "district": "5",
            "Block": "227",
            "label": "R.K .GOVERNMENT HIGHER SECONDARY SCHOOL KADAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15223",
            "district": "5",
            "Block": "227",
            "label": "St .  PAUL PUBLIC SCHOOL, CBSE  PANIKANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15224",
            "district": "5",
            "Block": "227",
            "label": "RAJAGURU MATRIC HR SEC.SCHOOL-KADAMPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15225",
            "district": "5",
            "Block": "227",
            "label": "MODEL SCHOOL,PANRUTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15226",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL KARUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15227",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL-KATTUKOODALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23694",
            "district": "5",
            "Block": "199",
            "label": "Jayasakthi Nursery & Primary School",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "15228",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL KEEZHAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15229",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL KEEZHKANGEYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15230",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL KUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15231",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15232",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL KEEZHMAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15233",
            "district": "5",
            "Block": "227",
            "label": "ST.MARYS MATRICULATION HIGH  SCHOOL L.N.PURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15234",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL MALIGAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15235",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MARUNGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15236",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL MELIRUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15237",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  SIRUGRAMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15238",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL S.EARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15239",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL SIRUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15240",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL THAZHAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15241",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL THIRUVAMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15242",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL VEERASINGANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15243",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEERAPERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15244",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL VADAKKU MELMAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15245",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL METTUKUPPAM (PANRUTI)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15246",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT GIRLS  HIGHER SECONDARY SCHOOL PERPERIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15247",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL PERPERIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15248",
            "district": "5",
            "Block": "227",
            "label": "D.KALIYAPERUMAL MEMORIAL MATRICULATION SCHOOL. PERPERIYANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15249",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL SOORAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15250",
            "district": "5",
            "Block": "227",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL, SOORAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15251",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL POONGUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15252",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERIYAKATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15253",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT ADIDIRAVIDAR WELFARE HIGHER SECONDARY SCHOOL, SEMAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15254",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL  SEMMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15255",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL BADRAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15256",
            "district": "5",
            "Block": "227",
            "label": "AIDED  MIDDLE  SCHOOL  R.C PANICKANNKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15257",
            "district": "5",
            "Block": "227",
            "label": "AIDED  MIDDLE  SCHOOL A.V PANRUTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15258",
            "district": "5",
            "Block": "227",
            "label": "SREE SARADHA VIDHYALAYA  MATRICULATION  SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15259",
            "district": "5",
            "Block": "227",
            "label": "RADHIKA  MATRICULATION HIGH SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15260",
            "district": "5",
            "Block": "227",
            "label": "MUNICIPAL HIGH  SCHOOL THIRUVATHIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15261",
            "district": "5",
            "Block": "227",
            "label": "AIDED HIGH SCHOOL D.M.PANRUTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15262",
            "district": "5",
            "Block": "227",
            "label": "SRI.P.MUTHAIYAR HR.SEC.SCHOOL, PANRUTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15263",
            "district": "5",
            "Block": "227",
            "label": "RATHANA  MATRIC.HIGHER .SECONDARY.SCHOOL, PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15264",
            "district": "5",
            "Block": "227",
            "label": "AIDED SHREE.A.SUBBARAYA CHETTIAR'S GIRLS HIGHER SECONDARY SCHOOL- PANRUTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15265",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  PANRUTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15266",
            "district": "5",
            "Block": "227",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION HR SEC SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23347",
            "district": "5",
            "Block": "227",
            "label": "Balavihar Matric Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15267",
            "district": "5",
            "Block": "227",
            "label": "JOHN DEWEY MATRICULATION  HR SEC SCHOOL  PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15268",
            "district": "5",
            "Block": "227",
            "label": "THE NEW JOHN DEWEY MATRIC HIGH SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15269",
            "district": "5",
            "Block": "227",
            "label": "SHREE MAEDHA MATRICULATION  HIGH SCHOOL ,  PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15270",
            "district": "5",
            "Block": "227",
            "label": "THIRUVALLUVAR ,MATRICULATION HIGHER SEC  SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15271",
            "district": "5",
            "Block": "227",
            "label": "ST. ANNE'S MATRIC HIGHER SEC  SCHOOL ,THATTANCHAVADI,PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15272",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHETTIPATTARAI  COLONY PANRUTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15273",
            "district": "5",
            "Block": "227",
            "label": "KASTHURIBA GANDHI BALIGA VIDHYALAYA SCHOOL KADAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15274",
            "district": "5",
            "Block": "211",
            "label": "ARUL JOTHI AIDED MIDDLE SCHOOL  VADAKUTHU ( EAST)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15275",
            "district": "5",
            "Block": "211",
            "label": "NLC MIDDLE SCHOOL BLOCK- 20  NEYVELI-TS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15276",
            "district": "5",
            "Block": "211",
            "label": "DANISH MISSION MIDDDLE SCHOOL BLOCK- 19 NEYVELI-TS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15277",
            "district": "5",
            "Block": "211",
            "label": "THOLKAPPIYANAR MIDDLE SCHOOL BLOCK -16 NEYVELI-TS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15278",
            "district": "5",
            "Block": "211",
            "label": "JAWAHAR MATRICULATION HIGHER SECONDARY SCHOOL BLOCK -17 NEYVELI - TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15279",
            "district": "5",
            "Block": "211",
            "label": "JUNIOR JAWAHAR MATRICULATION  SCHOOL, BLOCK- 6 NEYVELI - TS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15280",
            "district": "5",
            "Block": "211",
            "label": "MODEL JAWAHAR MATRICULATION SCHOOL, BLOCK 28 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15281",
            "district": "5",
            "Block": "211",
            "label": "LITTLE JAWAHAR MATRICULATION SCHOOL, MANDARAKUPPAM NEYVELI-2",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15282",
            "district": "5",
            "Block": "211",
            "label": "ST. PAUL'S MATRICULATION HIGHER SECONDARY SCHOOL , BLOCK -4 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15283",
            "district": "5",
            "Block": "211",
            "label": "ST. JOSEPH OF CLUNY MATRICULATION  HIGHER SECONDARY SCHOOL  BLOCK-25 NEYVELI -TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15284",
            "district": "5",
            "Block": "211",
            "label": "SRI AUROBINDO VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, BLOCK-19 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15285",
            "district": "5",
            "Block": "211",
            "label": "SEVENTH DAY ADVENTIST  MATRICULATION HIGHER SECONDARY SCHOOL BLOCK-13 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15286",
            "district": "5",
            "Block": "211",
            "label": "TAGORE MATRICULATION HIGHER SECONDARY SCHOOL BLOCK-29 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15287",
            "district": "5",
            "Block": "211",
            "label": "N M J MATRICULATION HIGH SCHOOL BLOCK-12 NEYVELI -TS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15288",
            "district": "5",
            "Block": "211",
            "label": "ST. JOSEPH'S HIGH SCHOOL  BLOCK-4, NEYVELI-TS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15289",
            "district": "5",
            "Block": "211",
            "label": "NLC GIRLS HIGHER SECONDARY SCHOOL  BLOCK-11 NEYVELI -TS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15290",
            "district": "5",
            "Block": "211",
            "label": "NLC BOYS HIGHER SECONDARY SCHOOL  BLOCK-10, NEYVELI -TS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15291",
            "district": "5",
            "Block": "211",
            "label": "NLC HIGH SCHOOL BLOCK-26 NEYVELI-TS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15292",
            "district": "5",
            "Block": "211",
            "label": "NLC HIGHER SECONDARY SCHOOL MANDARAKUPPAM,.NEYVELI.-607802",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15293",
            "district": "5",
            "Block": "211",
            "label": "NLC GIRLS HIGH SCHOOL  MANDARAKUPPAM  NEYVELI- 607802..",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15294",
            "district": "5",
            "Block": "211",
            "label": "VALLALAR MATRICULATION HIGHER SECONDARY  SCHOOL, KOLLUKARANKUTTAI, MARUNGUR(PO)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15295",
            "district": "5",
            "Block": "148",
            "label": "GHS ADOORAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15296",
            "district": "5",
            "Block": "148",
            "label": "ADWMS ARANGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15297",
            "district": "5",
            "Block": "148",
            "label": "GOVT HR SEC SCHOOL, THEERTHANAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15298",
            "district": "5",
            "Block": "148",
            "label": "AMS ADOORKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15299",
            "district": "5",
            "Block": "148",
            "label": "KANNAN MATRICULATION  SCHOOL,KULLANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15300",
            "district": "5",
            "Block": "148",
            "label": "GHSS KULLANCHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15301",
            "district": "5",
            "Block": "148",
            "label": "GHS MARUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15302",
            "district": "5",
            "Block": "148",
            "label": "GHS POOVANIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15303",
            "district": "5",
            "Block": "148",
            "label": "GHS S.PUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15304",
            "district": "5",
            "Block": "148",
            "label": "GHS NAGAMMAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15305",
            "district": "5",
            "Block": "148",
            "label": "PUMS PERIYAKANNADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15306",
            "district": "5",
            "Block": "148",
            "label": "ADWMS KOTHANDARAMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15307",
            "district": "5",
            "Block": "148",
            "label": "PUMS AYIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15308",
            "district": "5",
            "Block": "148",
            "label": "PUMS KOTHAVACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15309",
            "district": "5",
            "Block": "148",
            "label": "GHS KALKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15310",
            "district": "5",
            "Block": "148",
            "label": "GHS RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15311",
            "district": "5",
            "Block": "148",
            "label": "PUMS GURUVAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15312",
            "district": "5",
            "Block": "148",
            "label": "GHSS ANDARMULLIPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15313",
            "district": "5",
            "Block": "148",
            "label": "GHS PULIYUR KATTUSAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15314",
            "district": "5",
            "Block": "148",
            "label": "ST MARY'S  HIGH SCHOOL-KRISHNANKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15315",
            "district": "5",
            "Block": "148",
            "label": "GHS PONNANGKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15316",
            "district": "5",
            "Block": "148",
            "label": "GHS SANTHAVELIPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15317",
            "district": "5",
            "Block": "148",
            "label": "GHS KO.CHATHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15318",
            "district": "5",
            "Block": "148",
            "label": "GHSS KARUNKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15319",
            "district": "5",
            "Block": "148",
            "label": "GHS METTUKUPPAM (KURINJIPADI)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15320",
            "district": "5",
            "Block": "148",
            "label": "GHSS VENGADAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15321",
            "district": "5",
            "Block": "148",
            "label": "GHS AMBALAVANANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15322",
            "district": "5",
            "Block": "148",
            "label": "GHS KANNITAMILNADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15323",
            "district": "5",
            "Block": "148",
            "label": "GHS K.NELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15324",
            "district": "5",
            "Block": "148",
            "label": "FATHIMA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15325",
            "district": "5",
            "Block": "148",
            "label": "SRI THAMBUSAMY MATRICULATION SCHOOL, ABATHARANAPURAM. VADALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15326",
            "district": "5",
            "Block": "148",
            "label": "ST JOHN HS PARVATHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15327",
            "district": "5",
            "Block": "148",
            "label": "VALLALAR GURUGULAM HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15328",
            "district": "5",
            "Block": "148",
            "label": "GGHSS VADALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15329",
            "district": "5",
            "Block": "148",
            "label": "S.D EADEN MATRICULATION HIGHER SECONDARY  SCHOOL, VADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15330",
            "district": "5",
            "Block": "148",
            "label": "TRIPURANENI VIDYALAYA MATRICULATION  SCHOOL,VADALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15331",
            "district": "5",
            "Block": "148",
            "label": "GHSS VADALUR PUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15332",
            "district": "5",
            "Block": "148",
            "label": "GHS ABATHARANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15333",
            "district": "5",
            "Block": "148",
            "label": "S.D.S HIGH SCHOOL-SERAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15334",
            "district": "5",
            "Block": "148",
            "label": "ARUNACHALA MATRICULATION HIGHER SECONDARY SCHOOL, KURINJPADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15335",
            "district": "5",
            "Block": "148",
            "label": "ST PAUL MATRICULATION HIGHER SECONDARY  SCHOOL, KURINJIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15336",
            "district": "5",
            "Block": "148",
            "label": "SRIRAM MATRICULATION HIGHER SECONDARY SCHOOL, KURINJIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15337",
            "district": "5",
            "Block": "148",
            "label": "SRI GAYATHRI MATRICULATION SCHOOL,KURINJIPADI.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15338",
            "district": "5",
            "Block": "148",
            "label": "GOVT GIRLS HSS KURINJIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15339",
            "district": "5",
            "Block": "148",
            "label": "G(B)HSS KURINJIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15340",
            "district": "5",
            "Block": "148",
            "label": "S.K.V HSS KURINJIPADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15341",
            "district": "5",
            "Block": "148",
            "label": "SKV THAIYALNAYAKI AMMAL MATRICULATION SCHOOL, KURINJIPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15342",
            "district": "5",
            "Block": "148",
            "label": "JEEVA MATRICULATION  HIGHER SECONDARY  SCHOOL-SUBRAMANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15343",
            "district": "5",
            "Block": "148",
            "label": "SRI RAMALINGAR HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15344",
            "district": "5",
            "Block": "148",
            "label": "GHSS POONDIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15345",
            "district": "5",
            "Block": "148",
            "label": "GHS PERIYAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15346",
            "district": "5",
            "Block": "148",
            "label": "ARULMIGU VALLALAR  HIGH SCHOOL,  VELAVINAYAGARKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15347",
            "district": "5",
            "Block": "148",
            "label": "GHSS KATTIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15348",
            "district": "5",
            "Block": "148",
            "label": "LIFE HELP MATRICULATIONSCHOOL, METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15349",
            "district": "5",
            "Block": "148",
            "label": "GHSS  INDHRA NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15350",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- MUGASAPARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15351",
            "district": "5",
            "Block": "406",
            "label": "ST. PAUL MATRICULATION HIGHER SECONDARY SCHOOL, MANGALAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15352",
            "district": "5",
            "Block": "406",
            "label": "ANNAI HATHEEJA GIRLS HIGH SCHOOL,  MANGALAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15353",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL MANGALAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15354",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL- MANGALAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15355",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL PULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15356",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- ALADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15357",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL MATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15358",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL PERIYAVADAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15359",
            "district": "5",
            "Block": "406",
            "label": "SENTHIL MATRICULATION HIGHER SECONDARY SCHOOL PERIYAVADAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15360",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL KO-PUVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15361",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL PUDHUKOORAIPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15362",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL- EDAICHITHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15363",
            "district": "5",
            "Block": "406",
            "label": "ST. AGNES HIGH SCHOOL VEERAREDDIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15364",
            "district": "5",
            "Block": "406",
            "label": "V.E.T. HIGHER SECONDARY SCHOOL ERUMANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15365",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL ERUMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15366",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL CHINNAVADAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15367",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL M. PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15368",
            "district": "5",
            "Block": "406",
            "label": "AIDED MIDDLE SCHOOL  PUDUVILANGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15369",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL PARAVALUR (SOUTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15370",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THORAVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15371",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KARUVEPPILANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15372",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL VILANKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15373",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL RAJENDRAPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15374",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL VANNANKUDIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15375",
            "district": "5",
            "Block": "406",
            "label": "DANISH MISSION MIDDLE  SCHOOL KASPA",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "15376",
            "district": "5",
            "Block": "406",
            "label": "KRISHNASAMY AIDED MIDDLE SCHOOL NATCHIYARPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15377",
            "district": "5",
            "Block": "406",
            "label": "MUNICIPAL MIDDLE SCHOOL PUDUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15378",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT MODEL GIRLS HIGHER SECONDARY SCHOOL ,VRIDDHACHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15379",
            "district": "5",
            "Block": "406",
            "label": "BABY MATRICULATION SCHOOL VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15380",
            "district": "5",
            "Block": "406",
            "label": "SRI SARASWATHI VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15381",
            "district": "5",
            "Block": "406",
            "label": "SAKTHI MATRICULATION HIGHER SECONDARY SCHOOL, PERIYAR NAGAR,VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15382",
            "district": "5",
            "Block": "406",
            "label": "DANISH MISSION HIGHER SECONDARY SCHOOL VRIDDHACHALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15383",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL VRIDDHACHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15384",
            "district": "5",
            "Block": "406",
            "label": "SRI SARADA VIDYALAYA MATRICULATION SCHOOL, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15385",
            "district": "5",
            "Block": "406",
            "label": "DEVI MATRIC HR SEC  SCHOOL-VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15386",
            "district": "5",
            "Block": "406",
            "label": "NIRMALA CREATIVE MATRICULATION SCHOOL- ANNA NAGAR, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15387",
            "district": "5",
            "Block": "406",
            "label": "MAHARISHI VIDYA MANDIR  MATRICULATION HIGHER SECONDARY SCHOOL, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15388",
            "district": "5",
            "Block": "406",
            "label": "KSR HI-TECH CBSE SCHOOL- VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15389",
            "district": "5",
            "Block": "406",
            "label": "R.C.AIDED MIDDLE SCHOOL KOVILANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15390",
            "district": "5",
            "Block": "199",
            "label": "ARUNA HIGHER SECONDARY SCHOOL  , ERAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15391",
            "district": "5",
            "Block": "199",
            "label": "SHREE AMBIKA VIDHYALAYA  MATRICULATION SCHOOL ERAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15392",
            "district": "5",
            "Block": "199",
            "label": "PUMS SATHANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15393",
            "district": "5",
            "Block": "199",
            "label": "GHSS THOLAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15394",
            "district": "5",
            "Block": "199",
            "label": "GHSS POOLAMPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15395",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PELANDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15396",
            "district": "5",
            "Block": "199",
            "label": "ADW GHS THEEVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15397",
            "district": "5",
            "Block": "199",
            "label": "PUMS KILIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15398",
            "district": "5",
            "Block": "199",
            "label": "GHS VADAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15399",
            "district": "5",
            "Block": "199",
            "label": "PUMS CHINNAKOSAPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15400",
            "district": "5",
            "Block": "199",
            "label": "PUMS MURUGANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15401",
            "district": "5",
            "Block": "199",
            "label": "AMS VENKARUMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15402",
            "district": "5",
            "Block": "199",
            "label": "PUMS MARUTHATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15403",
            "district": "5",
            "Block": "199",
            "label": "ADW GHS SIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15404",
            "district": "5",
            "Block": "199",
            "label": "GHS MANNAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15405",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT HIGH SCHOOL , A.CHITTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15406",
            "district": "5",
            "Block": "199",
            "label": "ADWS NARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15407",
            "district": "5",
            "Block": "199",
            "label": "AMS A.AGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15408",
            "district": "5",
            "Block": "199",
            "label": "GHSS VEPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15409",
            "district": "5",
            "Block": "199",
            "label": "AYYANAR MA TRIC HR SEC  SCHOOL,  VEPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15410",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT (MODEL ) HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15411",
            "district": "5",
            "Block": "199",
            "label": "GHS KEEZHAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15412",
            "district": "5",
            "Block": "199",
            "label": "ADWHSS GIRLS SEPPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15413",
            "district": "5",
            "Block": "199",
            "label": "ADWHSS BOYS SEPPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15414",
            "district": "5",
            "Block": "199",
            "label": "PUMS NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15415",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15416",
            "district": "5",
            "Block": "199",
            "label": "PUMS VALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15417",
            "district": "5",
            "Block": "199",
            "label": "PUMS NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15418",
            "district": "5",
            "Block": "199",
            "label": "GHSS KOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15419",
            "district": "5",
            "Block": "199",
            "label": "GHS - SATHIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15420",
            "district": "5",
            "Block": "199",
            "label": "PUMS VARAMBANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15421",
            "district": "5",
            "Block": "199",
            "label": "GHS PERIYANESALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15422",
            "district": "5",
            "Block": "199",
            "label": "AMS KO.KOTHANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15423",
            "district": "5",
            "Block": "199",
            "label": "ADWS MALIGAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15424",
            "district": "5",
            "Block": "199",
            "label": "PUMS PENNADAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15425",
            "district": "5",
            "Block": "199",
            "label": "BABY MATRICULATION SCHOOL PENNADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15426",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT ( GIRLS)HIGHER SECONDARY SCHOOL, PENNADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15427",
            "district": "5",
            "Block": "199",
            "label": "GHSS BOYS PENNADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15428",
            "district": "5",
            "Block": "199",
            "label": "LOTUS INTERNATIONAL MATRIC.HR.SEC. SCHOOL, PENNADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15429",
            "district": "5",
            "Block": "199",
            "label": "BHARATHI MATRICULATION SCHOOL PENNADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15430",
            "district": "5",
            "Block": "199",
            "label": "KGBV , PERIYANESALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15431",
            "district": "5",
            "Block": "199",
            "label": "PUMS SOZHANNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15432",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT BOYS' HIGHER SECONDARY SCHOOL, THOZHUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15433",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL,THOLUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15434",
            "district": "5",
            "Block": "170",
            "label": "OPR HIGH SCHOOL, THOZHUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15435",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL, EZHUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15436",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KANDAMATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15437",
            "district": "5",
            "Block": "170",
            "label": "ANDAVAR HIGH SCHOOL , KALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15438",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , KEELAKALPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15439",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KEEZHORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15440",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL ,  KORAKKAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15441",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , SIRUPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15442",
            "district": "5",
            "Block": "170",
            "label": "TAGORE  MATRICULATION SCHOOL, SIRUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15443",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL , PANAIYANTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15444",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PULIKARAMBALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15445",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANGALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15446",
            "district": "5",
            "Block": "170",
            "label": "ADI DRAVIDA WELFARE HIGHER SECONDARY SCHOOL , MA-PODAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15447",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MA.PODAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15448",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , RETTAKKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15449",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VADAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15450",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VINAYAGANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15451",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , AVINANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15452",
            "district": "5",
            "Block": "170",
            "label": "SRI VINAYAGA MATRICULATION SCHOOL, POTHIRAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15453",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KOTTARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15454",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL , VAIYANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15455",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15456",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, E.KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15457",
            "district": "5",
            "Block": "170",
            "label": "ADI DRAVIDA WELFARE MIDDLE SCHOOL , KAZHUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15458",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KAZHUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15459",
            "district": "5",
            "Block": "170",
            "label": "SRI VENKATESWARA MATRICULATION HIGHER SECONDARY  SCHOOL, KAZHUTHUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15460",
            "district": "5",
            "Block": "170",
            "label": "Panchayat Union Middle School,Alambadi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15461",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL,KORAKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15462",
            "district": "5",
            "Block": "170",
            "label": "NATIONAL MATRICULATION SCHOOL, THOLUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15463",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VAGAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15464",
            "district": "5",
            "Block": "170",
            "label": "GOVT. BOYS' HIGHER SECONDARY SCHOOL , TITTAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15465",
            "district": "5",
            "Block": "170",
            "label": "GOVT.GIRLS' HIGHER SECONDARY SCHOOL, TITTAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15466",
            "district": "5",
            "Block": "170",
            "label": "DR NAVALAR NEDUNCHEZHIYAN MATRIC HIGHER SECONDARY SCHOOL, TITTAGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15467",
            "district": "5",
            "Block": "170",
            "label": "INDIAN MATRICULATION HIGHER SECONDARY SCHOOL, TITTAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15468",
            "district": "5",
            "Block": "170",
            "label": "PUMS T. ELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15469",
            "district": "5",
            "Block": "170",
            "label": "AIDED MIDDLE SCHOOL, POYANAPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15470",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ADARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15471",
            "district": "5",
            "Block": "170",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA , THOZHUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15472",
            "district": "5",
            "Block": "170",
            "label": "SRI  KALAIMAGAL MATRICULATION SCHOOL-ADARI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15473",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL,ORANGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15474",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, LAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15475",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL, THATCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15476",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL, SIRUMULAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15477",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL, VENGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15478",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL.EDAICHERUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15479",
            "district": "5",
            "Block": "98",
            "label": "GHS KO.ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15480",
            "district": "5",
            "Block": "98",
            "label": "ADW HSS, DHARMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15481",
            "district": "5",
            "Block": "98",
            "label": "AR RAHMATH MATRICULATION SCHOOL PERIYAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15482",
            "district": "5",
            "Block": "98",
            "label": "GHSS IRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15483",
            "district": "5",
            "Block": "98",
            "label": "GHS IRULAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15484",
            "district": "5",
            "Block": "98",
            "label": "GHSS KAMMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15485",
            "district": "5",
            "Block": "98",
            "label": "AMS GOPALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15486",
            "district": "5",
            "Block": "98",
            "label": "ST.THERSA'S HS, KATTUKUNANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15487",
            "district": "5",
            "Block": "98",
            "label": "AMS KAVANUR WEST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15488",
            "district": "5",
            "Block": "98",
            "label": "GHSS C.KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15489",
            "district": "5",
            "Block": "98",
            "label": "GHS -KOTTERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15490",
            "district": "5",
            "Block": "98",
            "label": "PUMS KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15491",
            "district": "5",
            "Block": "98",
            "label": "SACRED HEART HSS, IRUPUKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15492",
            "district": "5",
            "Block": "98",
            "label": "GHS, MELAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15493",
            "district": "5",
            "Block": "98",
            "label": "P.RAMALINGA MDR GHSS, MUDHANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15494",
            "district": "5",
            "Block": "98",
            "label": "GHS - NADIYAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15495",
            "district": "5",
            "Block": "98",
            "label": "GHS , OTTIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15496",
            "district": "5",
            "Block": "98",
            "label": "GHS PERIYAKAPPANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15497",
            "district": "5",
            "Block": "98",
            "label": "AUXILIUM MATCULATION  SCHOOL-V.SATHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15498",
            "district": "5",
            "Block": "98",
            "label": "GHSS SEPLANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15499",
            "district": "5",
            "Block": "98",
            "label": "AMS SIRUVARPPUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15500",
            "district": "5",
            "Block": "98",
            "label": "PUMS THOPPALIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15501",
            "district": "5",
            "Block": "98",
            "label": "PUMS UTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15502",
            "district": "5",
            "Block": "98",
            "label": "GHS -VEPPANKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15503",
            "district": "5",
            "Block": "98",
            "label": "JPA VIDYALAYA  MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15504",
            "district": "5",
            "Block": "98",
            "label": "GHS GANGAIKONDAN COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15505",
            "district": "5",
            "Block": "98",
            "label": "SACRED HEART MATRICULATION SCHOOL, KVR NAGAR,NEYVELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15506",
            "district": "5",
            "Block": "98",
            "label": "PUMS- KOLLIRUPPU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15507",
            "district": "5",
            "Block": "233",
            "label": "PUMS C. PUDUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15508",
            "district": "5",
            "Block": "233",
            "label": "PUMS K. PANCHANGKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15509",
            "district": "5",
            "Block": "233",
            "label": "PUMS KARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15510",
            "district": "5",
            "Block": "233",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  SAMMANDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15511",
            "district": "5",
            "Block": "233",
            "label": "RC. HIGH SCHOOL,  MANIKOLLAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15512",
            "district": "5",
            "Block": "233",
            "label": "GOVERNMENT MODEL  HIGHER SECONDARY SCHOOL - B.MUTLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15513",
            "district": "5",
            "Block": "233",
            "label": "JAWAHAR MATRICULATION HIGHER SECONDARY SCHOOL,  B.MUTLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15514",
            "district": "5",
            "Block": "233",
            "label": "ATCHAYA MANTHIR HIGHER SECONDARY SCHOOL-B.MUTLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15515",
            "district": "5",
            "Block": "233",
            "label": "PUMS PERIYAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15516",
            "district": "5",
            "Block": "233",
            "label": "SRI BARANI VIDHYALAYA HIGHER SECONDARY SCHOOL -PERIYAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15517",
            "district": "5",
            "Block": "233",
            "label": "PUMS SENDHIRAKILLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15518",
            "district": "5",
            "Block": "233",
            "label": "GHSS SAMIYARPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15519",
            "district": "5",
            "Block": "233",
            "label": "GOVERNMENT HIGH SCHOOL - AYEEPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15520",
            "district": "5",
            "Block": "233",
            "label": "GHS VELANGIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15521",
            "district": "5",
            "Block": "233",
            "label": "ADIDRAVIDAR WELFARE MIDDLE SCHOOL - ADHIVARAGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15522",
            "district": "5",
            "Block": "233",
            "label": "GHS KAVARAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15523",
            "district": "5",
            "Block": "233",
            "label": "PUMS KEEZHAMANAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15524",
            "district": "5",
            "Block": "233",
            "label": "PUMS KOTHANGKUDI HINDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15525",
            "district": "5",
            "Block": "233",
            "label": "GHS KOTHATTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15526",
            "district": "5",
            "Block": "233",
            "label": "PUMS MEETHIKUDI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15527",
            "district": "5",
            "Block": "233",
            "label": "ADWMS NANJAIMAHATHUVAZHKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15528",
            "district": "5",
            "Block": "233",
            "label": "PUMS PALLIPADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15529",
            "district": "5",
            "Block": "233",
            "label": "MUSTHAFA MATRICULATION HIGHER SECONDARY SCHOOL PALLIPADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15530",
            "district": "5",
            "Block": "233",
            "label": "GHSS- KODIPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15531",
            "district": "5",
            "Block": "233",
            "label": "GHSS T.S.PETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15532",
            "district": "5",
            "Block": "233",
            "label": "GHSS KILLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15533",
            "district": "5",
            "Block": "233",
            "label": "PUMS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15534",
            "district": "5",
            "Block": "233",
            "label": "PUMS KALAIGNAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15535",
            "district": "5",
            "Block": "233",
            "label": "SARVA SEVA MATRIC KILLAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15536",
            "district": "5",
            "Block": "233",
            "label": "GHS MUDASALVODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15537",
            "district": "5",
            "Block": "233",
            "label": "AIDED HIGHER SECONDARY SCHOOL SEVAMANDIR GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15538",
            "district": "5",
            "Block": "233",
            "label": "GHSS PARANGIPETTAI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15539",
            "district": "5",
            "Block": "233",
            "label": "GOVT HIGHER SECONDARY SCHOOL PARANGIPETTAI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15540",
            "district": "5",
            "Block": "233",
            "label": "SEVAMANDIR MATRICULATION HIGHER SECONDARY SCHOOL, PARANGIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15541",
            "district": "5",
            "Block": "233",
            "label": "KALIMA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15542",
            "district": "5",
            "Block": "233",
            "label": "MUNA AUSTRALIAN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15543",
            "district": "5",
            "Block": "39",
            "label": "GHSS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15544",
            "district": "5",
            "Block": "39",
            "label": "GHSS MANJAKOLLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15545",
            "district": "5",
            "Block": "39",
            "label": "GHS SATHAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15546",
            "district": "5",
            "Block": "39",
            "label": "PUMS MARUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15547",
            "district": "5",
            "Block": "39",
            "label": "GHSS ERUMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15548",
            "district": "5",
            "Block": "39",
            "label": "GHS B ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15549",
            "district": "5",
            "Block": "39",
            "label": "GHSS C.MUTLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15550",
            "district": "5",
            "Block": "39",
            "label": "SHREE RAGHAVENDRA SCHOOL, CBSE. KEEZHAMOONGILADI,CHIDAMBARAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15551",
            "district": "5",
            "Block": "39",
            "label": "EDISON G AGORAM  MEMORIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15552",
            "district": "5",
            "Block": "39",
            "label": "VALLALAR HIGHER SECONDARY SCHOOL, VALAYAMADEVI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15553",
            "district": "5",
            "Block": "39",
            "label": "GHS PINNALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15554",
            "district": "5",
            "Block": "39",
            "label": "GHS VADAKKUTHITTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15555",
            "district": "5",
            "Block": "39",
            "label": "GHSS MEL BHUVANAGIRI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15556",
            "district": "5",
            "Block": "39",
            "label": "GGHSS, BHUVANAGIRI GIRLS -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15557",
            "district": "5",
            "Block": "39",
            "label": "BHARATHI MATRICULATION HIGHER SECONDARYSCHOOL,BHUVANAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15558",
            "district": "5",
            "Block": "39",
            "label": "MANGALAM MATRICULATION HIGHER SECONDARY SCHOOL,BHUVANAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15559",
            "district": "5",
            "Block": "39",
            "label": "SRI  RAGHAVENDRA MATRIC HIGHER SECONDARY  SCHOOL-BHUVANAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15560",
            "district": "5",
            "Block": "39",
            "label": "DGM HSS SETHIYATHOPE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15561",
            "district": "5",
            "Block": "39",
            "label": "CHANDRA HR SEC SCHOOL -  SETHIYATHOPE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15562",
            "district": "5",
            "Block": "39",
            "label": "S.D.EADEN MATRICULATION SCHOOL,  SETHIATHOPE.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15563",
            "district": "5",
            "Block": "39",
            "label": "GHS SETHIYATHOPE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15564",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOODALAIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15565",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- KANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15566",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL-KEERAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15567",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- VILAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15568",
            "district": "5",
            "Block": "120",
            "label": "ADI-DRAVIDA WELFARE GOVERNMENT HIGHER SECONDARY SCHOOL- MATHURANTHAGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15569",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- MAZHAVARAYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15570",
            "district": "5",
            "Block": "120",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL- MUGAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15571",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, T.NEDUNCHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15572",
            "district": "5",
            "Block": "120",
            "label": "THIRUVALLUVAR HIGHER SECONDARY SCHOOL , ORATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15573",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- PALAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15574",
            "district": "5",
            "Block": "120",
            "label": "AIDED-ST.LOURDE'S MIDDLE SCHOOL- SILUVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15575",
            "district": "5",
            "Block": "120",
            "label": "KAMARAJ MATRIC HIGHER SECONDARY SPECIAL SCHOOL, VAYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15576",
            "district": "5",
            "Block": "120",
            "label": "AIDED MIDDLE SCHOOL- PANNAPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15577",
            "district": "5",
            "Block": "120",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL- PERUNGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15578",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15579",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- PUDAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15580",
            "district": "5",
            "Block": "120",
            "label": "AIDED-ST.MARY'S MIDDLE SCHOOL- VALISAPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15581",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- RAMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15582",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- SAKKANKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15583",
            "district": "5",
            "Block": "120",
            "label": "RBM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15584",
            "district": "5",
            "Block": "120",
            "label": "AMS GOVINDANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "15585",
            "district": "5",
            "Block": "120",
            "label": "AHS SOZHATHARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15586",
            "district": "5",
            "Block": "120",
            "label": "AIDED-SACRED HEART HIGHER SECONDARY SCHOOL- VADAKUPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15587",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VAZHAIKKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15588",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- VEYYALORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15589",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HR.SEC SCHOOL LALPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15590",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HR.SEC.SCHOOL KUMARATCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15591",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL ELLERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15592",
            "district": "5",
            "Block": "143",
            "label": "GHS NEYVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15593",
            "district": "5",
            "Block": "143",
            "label": "PUMS MEYYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15594",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL KOODUVELICHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15595",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL EDAIYAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15596",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL THIRUNARAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23405",
            "district": "5",
            "Block": "17",
            "label": "Danish Mission Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15597",
            "district": "5",
            "Block": "143",
            "label": "GHS PARIVILAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15598",
            "district": "5",
            "Block": "143",
            "label": "ADWSHSS MELAVANNIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15599",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL KARUPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15600",
            "district": "5",
            "Block": "143",
            "label": "GOVT HIGH SCHOOL,  JAYANKONDAPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15601",
            "district": "5",
            "Block": "143",
            "label": "GHS SIVAPURI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15602",
            "district": "5",
            "Block": "143",
            "label": "PUMS MULLANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15603",
            "district": "5",
            "Block": "143",
            "label": "GHSS VALLATHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15604",
            "district": "5",
            "Block": "143",
            "label": "WILLIAMS MATRICULATION SCHOOL VALLAMPADUGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15605",
            "district": "5",
            "Block": "143",
            "label": "GHS VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15606",
            "district": "5",
            "Block": "143",
            "label": "AMS VAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15607",
            "district": "5",
            "Block": "143",
            "label": "LEELA MEMO MAT ANNAMALAINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15608",
            "district": "5",
            "Block": "143",
            "label": "A.R.G ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL,ANNAMALAI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15609",
            "district": "5",
            "Block": "143",
            "label": "MPLMS KOTHANGUDI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15610",
            "district": "5",
            "Block": "143",
            "label": "AMS TELC CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15611",
            "district": "5",
            "Block": "143",
            "label": "KAMARAJ MATRICULATION HIGHER SECONDARY SCHOOL,CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15612",
            "district": "5",
            "Block": "143",
            "label": "THILLAI MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15613",
            "district": "5",
            "Block": "143",
            "label": "RCT HSS CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15614",
            "district": "5",
            "Block": "143",
            "label": "RANI SEETHAI HSS ANNAMALAI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15615",
            "district": "5",
            "Block": "143",
            "label": "GOVT GIRLS HR SEC SCHOOL-CHIDAMBARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15616",
            "district": "5",
            "Block": "143",
            "label": "GOVT NANDANAR BOYS HSS CHIDAMBARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15617",
            "district": "5",
            "Block": "143",
            "label": "GOVT NANDANAR GIRLS HSS CHIDAMBARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15618",
            "district": "5",
            "Block": "143",
            "label": "ARUMUGA NAVALAR HIGHER SECONDARY SCHOOL CHIDAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15619",
            "district": "5",
            "Block": "143",
            "label": "SRI RAMAKRISHNA VIDYASALA HR SEC SCHOOL-CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15620",
            "district": "5",
            "Block": "143",
            "label": "PACHAIYAPPA'S HIGHER SECONDARY SCHOOL CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15621",
            "district": "5",
            "Block": "143",
            "label": "SARASU MATRICULATION SCHOOL,PARAMESWARANALLURE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15622",
            "district": "5",
            "Block": "143",
            "label": "SRI NATARAJA MATRIC SCHOOL-C.THADESWARANALLUR CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15623",
            "district": "5",
            "Block": "143",
            "label": "MPLGHS-SAMBANDHAKARA ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15624",
            "district": "5",
            "Block": "143",
            "label": "SRI GURUGNANASAMBANDAR MISSION MATRICULATION  HIGHER SECONDARY SCHOOL, CHIDAMBARAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15625",
            "district": "5",
            "Block": "115",
            "label": "ST.ANTHONY'S HS , ARANTHANGI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15626",
            "district": "5",
            "Block": "115",
            "label": "GHS KOZHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15627",
            "district": "5",
            "Block": "115",
            "label": "GHSS MAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15628",
            "district": "5",
            "Block": "115",
            "label": "RAJIV GANDHI NATIONAL  MAT HR SEC SCHOOL , NATTARMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15629",
            "district": "5",
            "Block": "115",
            "label": "RAJIV GANDHI NATIONAL HIGH SCHOOL, NATTARMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15630",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELPALANCHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15631",
            "district": "5",
            "Block": "115",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  SRINEDUNCHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15632",
            "district": "5",
            "Block": "115",
            "label": "AIDED MUSLIM HIGH SCHOOL , AYANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15633",
            "district": "5",
            "Block": "115",
            "label": "GHSS.KANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15634",
            "district": "5",
            "Block": "115",
            "label": "GHSS , KANJANKOLLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15635",
            "district": "5",
            "Block": "115",
            "label": "AMS KALLAIMAGAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15636",
            "district": "5",
            "Block": "115",
            "label": "GHSS(BOYS) , KATTUMANNARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15637",
            "district": "5",
            "Block": "115",
            "label": "P R G HR SEC SCHOOL, KATTUMANNARKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15638",
            "district": "5",
            "Block": "115",
            "label": "GK MAT HSS , KATTUMANNARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15639",
            "district": "5",
            "Block": "115",
            "label": "AIDED HIGH SCHOOL (RC) , UDAIYARKUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15640",
            "district": "5",
            "Block": "115",
            "label": "KALAIMAGAL MATRIC HIGHER SECONDARY  SCHOOL  KATTUMANNARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15641",
            "district": "5",
            "Block": "115",
            "label": "GHSS(GIRLS) , KATTUMANNARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15642",
            "district": "5",
            "Block": "115",
            "label": "MUSLIM HIGH SCH., KOLLUMEDU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15643",
            "district": "5",
            "Block": "115",
            "label": "GHS OMAMPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15644",
            "district": "5",
            "Block": "115",
            "label": "GHS GUNAVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15645",
            "district": "5",
            "Block": "115",
            "label": "GHS , KARUPPERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15646",
            "district": "5",
            "Block": "115",
            "label": "GOVERNMENT HIGH SCHOOL, KURUNGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15647",
            "district": "5",
            "Block": "115",
            "label": "GHS , MANIYAMADOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15648",
            "district": "5",
            "Block": "115",
            "label": "GHS , MOVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15649",
            "district": "5",
            "Block": "115",
            "label": "GHSS , MUTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15650",
            "district": "5",
            "Block": "115",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, REDDIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15651",
            "district": "5",
            "Block": "115",
            "label": "DVC HSS , SRIMUSHNAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15652",
            "district": "5",
            "Block": "115",
            "label": "DHAVA AMUTHAM MHSS, SRIMUSHNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15653",
            "district": "5",
            "Block": "115",
            "label": "GHSS THIRUMUTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20889",
            "district": "5",
            "Block": "120",
            "label": "ATHENA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20917",
            "district": "5",
            "Block": "170",
            "label": "SATHIYA SAAI PUBLIC SCHOOL, PASAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21031",
            "district": "5",
            "Block": "199",
            "label": "BHAVANI VIDYASHRAM MATRICULATION HR SEC  SCHOOL KANDAPPANKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21032",
            "district": "5",
            "Block": "170",
            "label": "DR AKP OXBRIDGE MATRIC HIGHER SECONDARY SCHOOL,  SIRUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21094",
            "district": "5",
            "Block": "54",
            "label": "THE PSBB MILLENNIUM SCHOOL - PERIYAKATUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21095",
            "district": "5",
            "Block": "406",
            "label": "JAYAPRIYA VIDYALAYA SENIOR SECONDARY SCHOOL  CBSE, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21096",
            "district": "5",
            "Block": "406",
            "label": "SRI VIDHYA MANDHIR MATRICULATION SCHOOL,  VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21097",
            "district": "5",
            "Block": "199",
            "label": "PRIME INTERNATIONAL SCHOOL VEPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21098",
            "district": "5",
            "Block": "120",
            "label": "SHRI SAI VIDHYASHARAM CBSE SCHOOL, KANNANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21099",
            "district": "5",
            "Block": "143",
            "label": "SHEMFORD FUTURISTIC CBSE -C.THANDEWARNALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21100",
            "district": "5",
            "Block": "115",
            "label": "SBG CBSE VIDYALAYA-THENPATHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21221",
            "district": "5",
            "Block": "170",
            "label": "MANGALORE MODEL SCHOOL, M.PODAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21249",
            "district": "5",
            "Block": "54",
            "label": "AURO CHILD INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL -VARAKALPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21281",
            "district": "5",
            "Block": "170",
            "label": "SATHYA SAAI MATRICULATION HIGHER SECONDARY SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21282",
            "district": "5",
            "Block": "143",
            "label": "SRI VENKATESWARA MATRICULATION SCHOOL, SIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21283",
            "district": "5",
            "Block": "143",
            "label": "THE MATRICULATION HIGHER SECONDARY SCHOOL, CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21329",
            "district": "5",
            "Block": "211",
            "label": "KENDRIYA VIDYALAYA HIGHER SECONDARY SCHOOL(CBSE)  BLOCK - 3 NEYVELI-TS",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21385",
            "district": "5",
            "Block": "148",
            "label": "GHS KUNDIYAMALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21386",
            "district": "5",
            "Block": "199",
            "label": "GGHS-NALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21387",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL-LALPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21471",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL ,  RAMANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21486",
            "district": "5",
            "Block": "39",
            "label": "GOVT(ADW)HR SEC SCHOOL,VANDURAYANPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21518",
            "district": "5",
            "Block": "98",
            "label": "ADWGHS-U.MANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21519",
            "district": "5",
            "Block": "143",
            "label": "ADWHS M.KOLAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21553",
            "district": "5",
            "Block": "148",
            "label": "HOLY CROSS HIGH SCHOOL,  NJV NAGAR  VADAKUTHU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21554",
            "district": "5",
            "Block": "170",
            "label": "GOKULAM HIGH SCHOOL,VENGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21560",
            "district": "5",
            "Block": "115",
            "label": "ARUNMOZHY MATRIC  HIGHER SECONDARY SCHOOL-VEERANANDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21688",
            "district": "5",
            "Block": "406",
            "label": "ANTONY PUBLIC  SENIOR SEC SCHOOL-CUDDALORE MAIN ROAD ,PUDHUKURAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21689",
            "district": "5",
            "Block": "148",
            "label": "DON BOSCO MATRICULATION SCHOOL-ANAYAMPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21690",
            "district": "5",
            "Block": "148",
            "label": "ANNAI MULLAI MATRICULATION HIGHER SECONDARY SCHOOL,-VADAKUTHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21691",
            "district": "5",
            "Block": "98",
            "label": "JAYAPRIYA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL,GOPALAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21692",
            "district": "5",
            "Block": "54",
            "label": "SRI VANI VIDHYALAYA MATRICULATION SCHOOL, THOTTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21693",
            "district": "5",
            "Block": "54",
            "label": "EQUITAS GURUKUL MATRICULATION HIGHER SECONDARY SCHOOL- DEVANAMPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21694",
            "district": "5",
            "Block": "39",
            "label": "OXFORD ENGLISH SCHOOL-THILLAINAYAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21695",
            "district": "5",
            "Block": "17",
            "label": "PALLAVA INTERNATIONAL CBSE SCHOOL, KEEZHAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21890",
            "district": "5",
            "Block": "406",
            "label": "VIRUTHAI VIKAS MATRICULATION  HIGHER SECONDARY SCHOOL -MANALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21962",
            "district": "5",
            "Block": "406",
            "label": "P.M.S. HIGHER SECONDARY SCHOOL FOR THE SPEECH & HEARING IMPAIRED KONANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21963",
            "district": "5",
            "Block": "54",
            "label": "OASIS SNEHADEEP HIGH SCHOOL FOR THE SEVERELY DISABLED - VANNIYARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21982",
            "district": "5",
            "Block": "120",
            "label": "KAMARAJ INTERNATIONAL CBSE SCHOOL,VAYALUR.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22195",
            "district": "5",
            "Block": "54",
            "label": "ST.ANNE'S SCHOOL - PUDHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22196",
            "district": "5",
            "Block": "54",
            "label": "GLOBAL SPECIAL SCHOOL FOR INTELLECTUAL DISABLED - 4TH CROSS STREET- SUBBURAYALU NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22197",
            "district": "5",
            "Block": "54",
            "label": "MOUNT LITERA ZEE SCHOOL - VANDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22198",
            "district": "5",
            "Block": "54",
            "label": "EZHUCHI INTELLECTUAL DISABLED SPECIAL CARE SCHOOL - KONDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22199",
            "district": "5",
            "Block": "54",
            "label": "SHANTHAM SPECIAL SCHOOL FOR MENTALLY CHALLENGED - B.VADUGAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22200",
            "district": "5",
            "Block": "54",
            "label": "SRI SIVASAKTHI MENTALLY RETARDED SPECIAL CARE SCHOOL - K.N.PETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22201",
            "district": "5",
            "Block": "54",
            "label": "DHAYA SPECIAL SCHOOL FOR INTELLECTUAL DISABLED - VANNIYARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22202",
            "district": "5",
            "Block": "54",
            "label": "CK SCHOOL OF PROGRESSIVE EDUCATION - CHELLANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22203",
            "district": "5",
            "Block": "148",
            "label": "RATHNA SPECIAL SCHOOL  METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22204",
            "district": "5",
            "Block": "406",
            "label": "JESSICA SPECIAL SCHOOL FOR THE INTELLECTUAL DISABLED- ALADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22205",
            "district": "5",
            "Block": "199",
            "label": "GHS-PE.POOVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22206",
            "district": "5",
            "Block": "199",
            "label": "SRI BALAJI HIGHER SECONDARY SCHOOL,  NALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22207",
            "district": "5",
            "Block": "170",
            "label": "Rainbow International Matric. Hr.Sec.School, Keelakalpoondi.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22208",
            "district": "5",
            "Block": "170",
            "label": "SRI VENKATESWARA CBSE SCHOOL, KAZHUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22209",
            "district": "5",
            "Block": "233",
            "label": "BABA VIDHYALAYA MATRICULATION HIGHER SECONDARY  SCHOOL, PARANGIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22210",
            "district": "5",
            "Block": "39",
            "label": "REDCE SPECIAL SCHOOL-VANDRAYANPATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22211",
            "district": "5",
            "Block": "39",
            "label": "SRI RAGAVENDRA SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22212",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- C.VEERASOLAGAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22213",
            "district": "5",
            "Block": "143",
            "label": "CHALLENGED CHILDREN AND WOMEN EDUCATION TRUST",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22214",
            "district": "5",
            "Block": "143",
            "label": "G.V. SPECIAL SCHOOL FOR MENTALLY RETARDED",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22215",
            "district": "5",
            "Block": "143",
            "label": "R.N.SUSEELA SPECIAL SCHOOL FOR HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22216",
            "district": "5",
            "Block": "115",
            "label": "G.K. PUBLIC SENIOR  SECONDARY SCHOOL, KATTUMANNARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22548",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - AZHAGIANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22549",
            "district": "5",
            "Block": "17",
            "label": "GHS-MALIGAIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22550",
            "district": "5",
            "Block": "17",
            "label": "EDIFY SCHOOL, VAZHAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22551",
            "district": "5",
            "Block": "148",
            "label": "SRK INTERNATIONAL  SENIOR SECONDARY SCHOOL-KEEZHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22552",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL-KO.PAVAZHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22553",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL SATHUKOODAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22554",
            "district": "5",
            "Block": "406",
            "label": "VIRUDHAI  VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22555",
            "district": "5",
            "Block": "199",
            "label": "SRI RAMAKRISHNA MATRIC HIGHER SECONDARY SCHOOL - N.NARAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22556",
            "district": "5",
            "Block": "170",
            "label": "GREEN PARK MATRIC HIGHER SECONDARY  SCHOOL,ELUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22557",
            "district": "5",
            "Block": "170",
            "label": "GREEN PARK INTERNATIONAL SCHOOL, ELUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22558",
            "district": "5",
            "Block": "170",
            "label": "STEPHEN HIGH SCHOOL-KORAKKAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22559",
            "district": "5",
            "Block": "233",
            "label": "GHS-POOVALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22560",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- K.ADOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22561",
            "district": "5",
            "Block": "115",
            "label": "GHS-VANAMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22562",
            "district": "5",
            "Block": "115",
            "label": "CS JAIN  MATRICULATION SCHOOL-THIRUMUTTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22833",
            "district": "5",
            "Block": "170",
            "label": "ASWIN'S MATRIC  SCHOOL,MANGALUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22842",
            "district": "5",
            "Block": "54",
            "label": "V.C.S .SHEMFORD INTERNATIONAL SCHOOL,CAPPER HILLS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22931",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL PANRUTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22940",
            "district": "5",
            "Block": "115",
            "label": "GHS-NATTARMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23042",
            "district": "5",
            "Block": "199",
            "label": "JAYAPRIYA VIDYALAYA MATRICULATION SCHOOL, THIRUPPAYAR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23053",
            "district": "5",
            "Block": "115",
            "label": "AKSHARA VIDYA MANDIR, CBSE SCHOOL, VILVAKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23061",
            "district": "5",
            "Block": "54",
            "label": "GREEN TECH MATRICULATION  HIGHER SECONDARY SCHOOL, VELLAPPAKKAM,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23172",
            "district": "5",
            "Block": "115",
            "label": "THIRUGNANAM INTERNATIONAL SCHOOL,MELPULIYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23179",
            "district": "5",
            "Block": "115",
            "label": "SBG VIDHYALAYA MATRICULATION SCHOOL, THENPATHY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23188",
            "district": "5",
            "Block": "227",
            "label": "SPDS JAYANTHI VIDYAPEEDAM ,KADAMPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23270",
            "district": "5",
            "Block": "211",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23474",
            "district": "5",
            "Block": "39",
            "label": "SRI KALAIMAGAL VIDHYALAYANURSERY&PRIMARY SCHOOL, MARUDHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23475",
            "district": "5",
            "Block": "54",
            "label": "CUDDALORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23476",
            "district": "5",
            "Block": "148",
            "label": "JAYAPRIYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23718",
            "district": "5",
            "Block": "17",
            "label": "PUMS PANAPAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23744",
            "district": "5",
            "Block": "39",
            "label": "GHSS KRISHNAPURAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23745",
            "district": "5",
            "Block": "39",
            "label": "PUMS NATHAMEDU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23746",
            "district": "5",
            "Block": "39",
            "label": "PUMS MARUDUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23764",
            "district": "5",
            "Block": "54",
            "label": "CUDDALORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23765",
            "district": "5",
            "Block": "54",
            "label": "ARCOT RAMASWAMI LAKSHMANASWAMI MUDHALIYAR HR SEC SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23766",
            "district": "5",
            "Block": "54",
            "label": "ARISTO PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23805",
            "district": "5",
            "Block": "98",
            "label": "PUMS KUMARAMANGALAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23822",
            "district": "5",
            "Block": "115",
            "label": "DVC MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23825",
            "district": "5",
            "Block": "120",
            "label": "KARUPASAMY VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23846",
            "district": "5",
            "Block": "143",
            "label": "GHS VELLUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23847",
            "district": "5",
            "Block": "143",
            "label": "Sri Saraswathi Vidhyalaya",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23856",
            "district": "5",
            "Block": "148",
            "label": "GHSS KARUNKUZHI",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23857",
            "district": "5",
            "Block": "148",
            "label": "VASANTHAM SPECIAL SCHOOL -VADALUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23887",
            "district": "5",
            "Block": "199",
            "label": "GHSS VEPPUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23888",
            "district": "5",
            "Block": "199",
            "label": "PUMS NALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23889",
            "district": "5",
            "Block": "199",
            "label": "GHSS KOVILUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23910",
            "district": "5",
            "Block": "233",
            "label": "PUMS MGR NAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23911",
            "district": "5",
            "Block": "233",
            "label": "GREEN OLIVE MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24083",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL MATHUR",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "6": [
        {
            "key": "631",
            "district": "6",
            "Block": "218",
            "label": "DHARMAPURI DIST. CO-OP SURGAR MILLS MATRIC SCHOOL, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1234",
            "district": "6",
            "Block": "58",
            "label": "SRI VIJAY VIDYALAYA GIRLS MHSS, GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1772",
            "district": "6",
            "Block": "58",
            "label": "SRI VIDYA MANDIR MATRIC SCHOOL, PIDAMANERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1853",
            "district": "6",
            "Block": "218",
            "label": "St. LUCY'S MHSS, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2060",
            "district": "6",
            "Block": "77",
            "label": "PUMS KOOKADAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2280",
            "district": "6",
            "Block": "218",
            "label": "SRI VIDYA MANDIR MHSS, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2309",
            "district": "6",
            "Block": "58",
            "label": "PARAMVEER BANASINGH VIDYA ACADEMY OF EXCELLENCE MHSS, AATUKKARANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2337",
            "district": "6",
            "Block": "230",
            "label": "ANNAI INDIA MHSS, MALLAPURAM, BOMMIDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2355",
            "district": "6",
            "Block": "77",
            "label": "PUMS NAARIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2378",
            "district": "6",
            "Block": "218",
            "label": "PUMS KADAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2397",
            "district": "6",
            "Block": "77",
            "label": "PUMS ERUMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2474",
            "district": "6",
            "Block": "77",
            "label": "PUMS PATTAVARTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2486",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHICKAMARANDAHALLI PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2508",
            "district": "6",
            "Block": "187",
            "label": "SRI VINAYAGA VIDHYALAYA MHSS, SILLARAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2524",
            "district": "6",
            "Block": "58",
            "label": "SRI VIJAY VIDYALAYA BOYS MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2533",
            "district": "6",
            "Block": "218",
            "label": "Dr. KRISHNA VIKAS MHSS, MARANDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2562",
            "district": "6",
            "Block": "218",
            "label": "PUMS SEERIYANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2563",
            "district": "6",
            "Block": "77",
            "label": "PUMS KEELANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2593",
            "district": "6",
            "Block": "218",
            "label": "PUMS VEPPALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2595",
            "district": "6",
            "Block": "218",
            "label": "PUMS ENDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2602",
            "district": "6",
            "Block": "218",
            "label": "PUMS ERRANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2611",
            "district": "6",
            "Block": "218",
            "label": "PUMS PEDHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2613",
            "district": "6",
            "Block": "77",
            "label": "PUMS SORIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2623",
            "district": "6",
            "Block": "198",
            "label": "PUMS BOMMASAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2646",
            "district": "6",
            "Block": "218",
            "label": "PUMS PADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2676",
            "district": "6",
            "Block": "218",
            "label": "PUMS PERUNGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2680",
            "district": "6",
            "Block": "235",
            "label": "PUMS K.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2706",
            "district": "6",
            "Block": "198",
            "label": "PUMS KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23385",
            "district": "6",
            "Block": "77",
            "label": " Little flower HR SEC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23390",
            "district": "6",
            "Block": "109",
            "label": "MULLAI HIGH SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "2709",
            "district": "6",
            "Block": "218",
            "label": "PUMS KATTAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2753",
            "district": "6",
            "Block": "218",
            "label": "PUMS GIDDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2764",
            "district": "6",
            "Block": "218",
            "label": "PUMS SOODANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2770",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHIKKATHORANABETTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2779",
            "district": "6",
            "Block": "235",
            "label": "PUMS CHINNAPERMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2780",
            "district": "6",
            "Block": "58",
            "label": "SENTHIL MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2794",
            "district": "6",
            "Block": "58",
            "label": "DON BOSCO MHSS, GUNDALAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2802",
            "district": "6",
            "Block": "235",
            "label": "PUMS SIDUMANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2806",
            "district": "6",
            "Block": "109",
            "label": "ST. PAUL'S MHSS, VELLICHANDHAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2812",
            "district": "6",
            "Block": "218",
            "label": "PUMS SAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2816",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHIKKARTHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2832",
            "district": "6",
            "Block": "218",
            "label": "PUMS ATHIMUTLU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2843",
            "district": "6",
            "Block": "218",
            "label": "PUMS THIMMAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2912",
            "district": "6",
            "Block": "218",
            "label": "PUMS PERIYANURPUTHUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2947",
            "district": "6",
            "Block": "77",
            "label": "PUMS KARUPPILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2948",
            "district": "6",
            "Block": "77",
            "label": "PUMS NACHINAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2950",
            "district": "6",
            "Block": "235",
            "label": "PUMS NALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2976",
            "district": "6",
            "Block": "218",
            "label": "PUMS SEENGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2980",
            "district": "6",
            "Block": "235",
            "label": "PUMS KOMBADIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2990",
            "district": "6",
            "Block": "218",
            "label": "PUMS KADATHIKOLLU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2998",
            "district": "6",
            "Block": "235",
            "label": "PUMS K.AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3012",
            "district": "6",
            "Block": "218",
            "label": "PUMS ERRAGUNDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3025",
            "district": "6",
            "Block": "77",
            "label": "PUMS SANGILIVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3073",
            "district": "6",
            "Block": "187",
            "label": "PUMS SAMANDAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3114",
            "district": "6",
            "Block": "77",
            "label": "PUMS SAKKILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3134",
            "district": "6",
            "Block": "235",
            "label": "PUMS ARAKASANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3139",
            "district": "6",
            "Block": "218",
            "label": "PUMS KADUKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3159",
            "district": "6",
            "Block": "235",
            "label": "PUMS VANNATHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3162",
            "district": "6",
            "Block": "218",
            "label": "PUMS SAKLINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3170",
            "district": "6",
            "Block": "235",
            "label": "PUMS ONDIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3175",
            "district": "6",
            "Block": "235",
            "label": "PUMS NAGADASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3182",
            "district": "6",
            "Block": "235",
            "label": "PUMS AJJANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3222",
            "district": "6",
            "Block": "187",
            "label": "PUMS KELAVALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3233",
            "district": "6",
            "Block": "230",
            "label": "STANLEY MHSS, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3245",
            "district": "6",
            "Block": "187",
            "label": "PUMS NALLAGUDLAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3258",
            "district": "6",
            "Block": "198",
            "label": "PUMS MALAIYURKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3278",
            "district": "6",
            "Block": "58",
            "label": "GOOD SHEPHERD MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3292",
            "district": "6",
            "Block": "77",
            "label": "PUMS H EACHAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3306",
            "district": "6",
            "Block": "77",
            "label": "PUMS ACHALVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3315",
            "district": "6",
            "Block": "198",
            "label": "PUMS APPANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3335",
            "district": "6",
            "Block": "218",
            "label": "PUMS BALAPPANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3351",
            "district": "6",
            "Block": "235",
            "label": "PUMS ODDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3419",
            "district": "6",
            "Block": "235",
            "label": "PUMS PUDUKARAMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3428",
            "district": "6",
            "Block": "198",
            "label": "UNITY MHSS, VETHALAIKARANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3430",
            "district": "6",
            "Block": "187",
            "label": "PUMS JAGGUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3440",
            "district": "6",
            "Block": "235",
            "label": "PUMS GURUKALAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3470",
            "district": "6",
            "Block": "77",
            "label": "SATHYAM MHSS, MOPPIRIPATTI, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3496",
            "district": "6",
            "Block": "218",
            "label": "PUMS VEDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3513",
            "district": "6",
            "Block": "235",
            "label": "BRINDHAVAN MATRIC SCHOOL, SENGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3557",
            "district": "6",
            "Block": "235",
            "label": "PUMS SIGARALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3582",
            "district": "6",
            "Block": "77",
            "label": "PUMS AVALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3583",
            "district": "6",
            "Block": "218",
            "label": "PUMS GUTHALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3597",
            "district": "6",
            "Block": "77",
            "label": "PUMS PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3610",
            "district": "6",
            "Block": "77",
            "label": "MUTHU MASE MATRIC SCHOOL, HARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3627",
            "district": "6",
            "Block": "235",
            "label": "PUMS VATHIMARATHAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3660",
            "district": "6",
            "Block": "235",
            "label": "PUMS MAKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3669",
            "district": "6",
            "Block": "58",
            "label": "SEVENTHDAY ADVENTIST MHSS , DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3697",
            "district": "6",
            "Block": "235",
            "label": "PUMS A.PAUPARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3733",
            "district": "6",
            "Block": "218",
            "label": "PUMS DODDARADHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3750",
            "district": "6",
            "Block": "218",
            "label": "PUMS THIRUMALVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3754",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3756",
            "district": "6",
            "Block": "235",
            "label": "PUMS A.ETTIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3893",
            "district": "6",
            "Block": "230",
            "label": "VENGANAR VAJRAM MHSS, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3898",
            "district": "6",
            "Block": "77",
            "label": "PUMS GANAPATHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3902",
            "district": "6",
            "Block": "198",
            "label": "VALLALAR ARIVALAYAM MATRIC SCHOOL, VALLUVAR NAGAR, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3907",
            "district": "6",
            "Block": "77",
            "label": "PUMS PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3931",
            "district": "6",
            "Block": "235",
            "label": "PUMS VELAMANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3936",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHENNAPPANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3966",
            "district": "6",
            "Block": "77",
            "label": "PUMS CHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3996",
            "district": "6",
            "Block": "235",
            "label": "PARAMVEER MHSS, PAUPARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4011",
            "district": "6",
            "Block": "77",
            "label": "PUMS KOLAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4044",
            "district": "6",
            "Block": "58",
            "label": "DAWN MHSS, S.V. ROAD, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4083",
            "district": "6",
            "Block": "218",
            "label": "PUMS NALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4092",
            "district": "6",
            "Block": "235",
            "label": "PUMS PODUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4105",
            "district": "6",
            "Block": "198",
            "label": "PUMS E.K.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4115",
            "district": "6",
            "Block": "218",
            "label": "PUMS ULAGANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4151",
            "district": "6",
            "Block": "218",
            "label": "WISDOMLAND MATRIC SCHOOL, KARAGATHAHALLI, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4169",
            "district": "6",
            "Block": "235",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4176",
            "district": "6",
            "Block": "218",
            "label": "PUMS BELAMARANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4194",
            "district": "6",
            "Block": "218",
            "label": "PUMS JERTHALAV",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4219",
            "district": "6",
            "Block": "218",
            "label": "PUMS PALACODE  URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23690",
            "district": "6",
            "Block": "58",
            "label": "Agni Sarar Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4250",
            "district": "6",
            "Block": "198",
            "label": "VALLALAR ARIVALAYAM MIDDLE SCHOOL, KURINJI NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4257",
            "district": "6",
            "Block": "58",
            "label": "SRI VISWABHARATHI MHSS, A.GOLLAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4340",
            "district": "6",
            "Block": "77",
            "label": "PUMS SENGALERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4352",
            "district": "6",
            "Block": "77",
            "label": "PUMS VEPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4357",
            "district": "6",
            "Block": "235",
            "label": "PUMS PERIYATHOTTAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4373",
            "district": "6",
            "Block": "235",
            "label": "PUMS GOWRICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4392",
            "district": "6",
            "Block": "198",
            "label": "PUMS ANUMANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4412",
            "district": "6",
            "Block": "77",
            "label": "KURINCHI MHSS, CHINNANKUPPAM, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4416",
            "district": "6",
            "Block": "77",
            "label": "PUMS KOKKARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4427",
            "district": "6",
            "Block": "187",
            "label": "PUMS GUNDALAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4436",
            "district": "6",
            "Block": "77",
            "label": "PUMS VEERAPPANAIKKANAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4447",
            "district": "6",
            "Block": "218",
            "label": "SRI BHARATHI VIDYALAYA ACADEMY MHSS,  MARANDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4450",
            "district": "6",
            "Block": "187",
            "label": "PUMS PUDUREDDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4453",
            "district": "6",
            "Block": "77",
            "label": "PUMS ALAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4469",
            "district": "6",
            "Block": "109",
            "label": "PUMS GUDDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4487",
            "district": "6",
            "Block": "235",
            "label": "PUMS KODIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4488",
            "district": "6",
            "Block": "198",
            "label": "PUMS EARUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4508",
            "district": "6",
            "Block": "235",
            "label": "PUMS DIGILODU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4524",
            "district": "6",
            "Block": "77",
            "label": "PUMS VALLIMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4547",
            "district": "6",
            "Block": "198",
            "label": "PUMS THADANGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4556",
            "district": "6",
            "Block": "198",
            "label": "PUMS PAGALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4577",
            "district": "6",
            "Block": "235",
            "label": "PUMS MANJARAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4579",
            "district": "6",
            "Block": "198",
            "label": "PUMS MALLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4594",
            "district": "6",
            "Block": "235",
            "label": "PUMS MANJARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4660",
            "district": "6",
            "Block": "218",
            "label": "PUMS GUMMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4699",
            "district": "6",
            "Block": "235",
            "label": "PUMS BIKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4724",
            "district": "6",
            "Block": "235",
            "label": "PUMS VATTUVANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4731",
            "district": "6",
            "Block": "77",
            "label": "PUMS G PAAPPINAICKANVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4773",
            "district": "6",
            "Block": "109",
            "label": "PUMS B GETTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4781",
            "district": "6",
            "Block": "77",
            "label": "PUMS MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4834",
            "district": "6",
            "Block": "198",
            "label": "PUMS KONANGIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4885",
            "district": "6",
            "Block": "235",
            "label": "PUMS HOGENAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4892",
            "district": "6",
            "Block": "218",
            "label": "PUMS GENGANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4975",
            "district": "6",
            "Block": "235",
            "label": "PUMS EAMANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5011",
            "district": "6",
            "Block": "235",
            "label": "PUMS ELUMALMANDHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5048",
            "district": "6",
            "Block": "235",
            "label": "PUMS M.THANDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5072",
            "district": "6",
            "Block": "198",
            "label": "PUMS CHIKKAGOUNDANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5107",
            "district": "6",
            "Block": "187",
            "label": "PUMS RANIMOOKANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5144",
            "district": "6",
            "Block": "198",
            "label": "PUMS THENGAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5148",
            "district": "6",
            "Block": "198",
            "label": "PUMS KALEGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5157",
            "district": "6",
            "Block": "235",
            "label": "PUMS VELAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5164",
            "district": "6",
            "Block": "235",
            "label": "YESU RAJA MHSS, PAUPARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5196",
            "district": "6",
            "Block": "235",
            "label": "PUMS KOOKUTTAMARUDHAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5228",
            "district": "6",
            "Block": "77",
            "label": "PUMS MANTHIKULAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5240",
            "district": "6",
            "Block": "198",
            "label": "PUMS RAMARKOODAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5268",
            "district": "6",
            "Block": "77",
            "label": "PUMS NAIKUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5294",
            "district": "6",
            "Block": "198",
            "label": "PUMS DHINNAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5301",
            "district": "6",
            "Block": "187",
            "label": "SRI SANKARA MHSS,KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5306",
            "district": "6",
            "Block": "198",
            "label": "PUMS NEKKUNDHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5318",
            "district": "6",
            "Block": "77",
            "label": "PUMS T ANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5373",
            "district": "6",
            "Block": "198",
            "label": "PUMS OMALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5382",
            "district": "6",
            "Block": "230",
            "label": "SRI VENKATESWARA MHSS, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5397",
            "district": "6",
            "Block": "77",
            "label": "HIMALAYAN MHSS, GOPALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5443",
            "district": "6",
            "Block": "187",
            "label": "PUMS KURUBARAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5513",
            "district": "6",
            "Block": "187",
            "label": "PUMS VAGUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5539",
            "district": "6",
            "Block": "235",
            "label": "PUMS KATTUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5540",
            "district": "6",
            "Block": "109",
            "label": "PUMS MURUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5552",
            "district": "6",
            "Block": "235",
            "label": "PUMS RAJAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5597",
            "district": "6",
            "Block": "235",
            "label": "PUMS GIDDANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5599",
            "district": "6",
            "Block": "198",
            "label": "PUMS MERKATHIYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5608",
            "district": "6",
            "Block": "235",
            "label": "PUMS PUDUR SOLAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5612",
            "district": "6",
            "Block": "198",
            "label": "PUMS GETTUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5629",
            "district": "6",
            "Block": "198",
            "label": "PUMS SOLIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5652",
            "district": "6",
            "Block": "218",
            "label": "JAI SAKTHI MHSS, KARTHARAPATTI, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5749",
            "district": "6",
            "Block": "77",
            "label": "P.D.R.VELLACHIAMMAL MHSS, SEKKAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5827",
            "district": "6",
            "Block": "187",
            "label": "PUMS MANIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5832",
            "district": "6",
            "Block": "235",
            "label": "PUMS KENDAYANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5835",
            "district": "6",
            "Block": "187",
            "label": "PUMS AMBALAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5841",
            "district": "6",
            "Block": "198",
            "label": "PUMS KAMALANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5849",
            "district": "6",
            "Block": "235",
            "label": "PUMS JANGAMAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5852",
            "district": "6",
            "Block": "187",
            "label": "PUMS ALAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5878",
            "district": "6",
            "Block": "187",
            "label": "PUMS CHINNAMURUKKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5886",
            "district": "6",
            "Block": "77",
            "label": "PUMS PORUSUMARATHU VALLAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5948",
            "district": "6",
            "Block": "198",
            "label": "PUMS GANAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6016",
            "district": "6",
            "Block": "109",
            "label": "THANAPPA GOUNDER MHSS, KERAKODAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6025",
            "district": "6",
            "Block": "187",
            "label": "PUMS VADAGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6062",
            "district": "6",
            "Block": "77",
            "label": "PUMS PARAYAPATTIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6174",
            "district": "6",
            "Block": "77",
            "label": "PUMS SUNDAKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6182",
            "district": "6",
            "Block": "187",
            "label": "PUMS KONGARAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6209",
            "district": "6",
            "Block": "235",
            "label": "PUMS KOVALLIKOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6242",
            "district": "6",
            "Block": "187",
            "label": "PUMS SUNKARAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6251",
            "district": "6",
            "Block": "198",
            "label": "PUMS DHANDUKARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6275",
            "district": "6",
            "Block": "187",
            "label": "I.V.L. MHSS, IRUMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6462",
            "district": "6",
            "Block": "198",
            "label": "PUMS MEL BOORUKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6467",
            "district": "6",
            "Block": "235",
            "label": "PUMS ANJEHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6472",
            "district": "6",
            "Block": "218",
            "label": "PUMS KANSALBELU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6491",
            "district": "6",
            "Block": "235",
            "label": "PUMS SUNJALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6520",
            "district": "6",
            "Block": "235",
            "label": "PUMS PANAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6539",
            "district": "6",
            "Block": "198",
            "label": "PUMS PAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6553",
            "district": "6",
            "Block": "198",
            "label": "PUMS B K VALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6555",
            "district": "6",
            "Block": "230",
            "label": "PUMS AJJAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6561",
            "district": "6",
            "Block": "198",
            "label": "PUMS SAMICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6572",
            "district": "6",
            "Block": "77",
            "label": "PUMS SURANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6589",
            "district": "6",
            "Block": "235",
            "label": "PUMS AARALKUNDHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6655",
            "district": "6",
            "Block": "235",
            "label": "PUMS BALINJERAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6686",
            "district": "6",
            "Block": "198",
            "label": "PUMS ERRAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6689",
            "district": "6",
            "Block": "235",
            "label": "PUMS SIDUVAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6710",
            "district": "6",
            "Block": "235",
            "label": "PUMS POOVANKADU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6737",
            "district": "6",
            "Block": "187",
            "label": "AMRUTHA MHSS, KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9127",
            "district": "6",
            "Block": "58",
            "label": "PUMS A.GOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9128",
            "district": "6",
            "Block": "58",
            "label": "GHSS ADHAGAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9129",
            "district": "6",
            "Block": "58",
            "label": "PUMS ALIVAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9130",
            "district": "6",
            "Block": "58",
            "label": "PUMS ATTUKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9131",
            "district": "6",
            "Block": "58",
            "label": "GHS BATHIREDDIHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9132",
            "district": "6",
            "Block": "58",
            "label": "MMS FORT URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9133",
            "district": "6",
            "Block": "58",
            "label": "MMS ANNASAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9134",
            "district": "6",
            "Block": "58",
            "label": "MMS IV WARD - S.V.ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9135",
            "district": "6",
            "Block": "58",
            "label": "MMS KUMARASAMI PET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9136",
            "district": "6",
            "Block": "58",
            "label": "MMS MATHIKONPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9137",
            "district": "6",
            "Block": "58",
            "label": "MMS SANDAIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9138",
            "district": "6",
            "Block": "58",
            "label": "GHS KIRUBANANDA Variyar",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9139",
            "district": "6",
            "Block": "58",
            "label": "MHS MUNICIPAL GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9140",
            "district": "6",
            "Block": "58",
            "label": "Adhiyaman GBHSS Dharmapuri",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9141",
            "district": "6",
            "Block": "58",
            "label": "GHSS AVVAIYAR GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9142",
            "district": "6",
            "Block": "58",
            "label": "SRI VIJAY VIDHYASHRAM, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9143",
            "district": "6",
            "Block": "58",
            "label": "PUMS GENGANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9144",
            "district": "6",
            "Block": "58",
            "label": "GHS GULIYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9145",
            "district": "6",
            "Block": "58",
            "label": "PUMS GUNDALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9146",
            "district": "6",
            "Block": "58",
            "label": "GHSS HALE DHARMAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9147",
            "district": "6",
            "Block": "58",
            "label": "FATHIMA AIDED MIDDLE SCHOOL, KADAGATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "9148",
            "district": "6",
            "Block": "58",
            "label": "GHSS KADAGATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9149",
            "district": "6",
            "Block": "58",
            "label": "BAARATH MATRIC SCHOOL, PACHINAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9150",
            "district": "6",
            "Block": "58",
            "label": "PUMS KATTUR MARIAMMAN KOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9151",
            "district": "6",
            "Block": "58",
            "label": "PUMS NARASINGAPURAM KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9152",
            "district": "6",
            "Block": "58",
            "label": "GHSS KONANGINAICKANAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9153",
            "district": "6",
            "Block": "58",
            "label": "GHSS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9154",
            "district": "6",
            "Block": "58",
            "label": "KAMARASAR MATRIC SCHOOL, KRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9155",
            "district": "6",
            "Block": "58",
            "label": "PUMS KUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9156",
            "district": "6",
            "Block": "58",
            "label": "GHS LAKKIYAMPATTI GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9157",
            "district": "6",
            "Block": "58",
            "label": "GHSS LAKKIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9158",
            "district": "6",
            "Block": "58",
            "label": "GHSS FOR THE HEARING IMPAIRED DHARMAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9159",
            "district": "6",
            "Block": "58",
            "label": "JACK & JILL MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9160",
            "district": "6",
            "Block": "58",
            "label": "ROTARY VIVEKANANDA SCHOOL FOR HEARING IMPAIRED, PIDAMANERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9161",
            "district": "6",
            "Block": "58",
            "label": "PACHAMUTHU MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9162",
            "district": "6",
            "Block": "58",
            "label": "KENDIRA VIDYALAYA CBSE",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9163",
            "district": "6",
            "Block": "58",
            "label": "PUMS LALAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23421",
            "district": "6",
            "Block": "187",
            "label": "Pughal Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23418",
            "district": "6",
            "Block": "198",
            "label": "National Child Labour Project, Special Training  Center",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "9164",
            "district": "6",
            "Block": "58",
            "label": "GHS M ODDAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9165",
            "district": "6",
            "Block": "58",
            "label": "PUMS MANALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9166",
            "district": "6",
            "Block": "58",
            "label": "PUMS MOOLAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9167",
            "district": "6",
            "Block": "58",
            "label": "SRI SANKARA VIDYALAYA MATRIC SCHOOL, GUNCHEETIPATTI, AKKAMANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9168",
            "district": "6",
            "Block": "58",
            "label": "GHS MOOKKANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9169",
            "district": "6",
            "Block": "58",
            "label": "GHSS MUKKALNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9170",
            "district": "6",
            "Block": "58",
            "label": "PUMS MUTHUGOUNDANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9171",
            "district": "6",
            "Block": "58",
            "label": "PUMS MUTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9172",
            "district": "6",
            "Block": "58",
            "label": "PUMS PACHINAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9173",
            "district": "6",
            "Block": "58",
            "label": "GHS PALLAKOLLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9174",
            "district": "6",
            "Block": "58",
            "label": "PUMS RAJATHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9175",
            "district": "6",
            "Block": "58",
            "label": "PUMS S.KOTTAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9176",
            "district": "6",
            "Block": "58",
            "label": "PUMS A SEKKARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9177",
            "district": "6",
            "Block": "58",
            "label": "CARMEL MATRIC SCHOOL, SEMMANDAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9178",
            "district": "6",
            "Block": "58",
            "label": "PUMS SIKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9179",
            "district": "6",
            "Block": "58",
            "label": "GHSS SOLAIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9180",
            "district": "6",
            "Block": "58",
            "label": "DR. RAMADOSS HR. SEC. SCHOOL, SOLAIKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9181",
            "district": "6",
            "Block": "58",
            "label": "PUMS SOWLUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9182",
            "district": "6",
            "Block": "58",
            "label": "SREE SIVANIVEL HIGH SCHOOL, UNGARANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9183",
            "district": "6",
            "Block": "58",
            "label": "GHSS V MUTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9184",
            "district": "6",
            "Block": "58",
            "label": "GHSS VATHALMALAI PERIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9185",
            "district": "6",
            "Block": "58",
            "label": "PUMS VENKATANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9186",
            "district": "6",
            "Block": "58",
            "label": "PUMS VEPPAMARATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9187",
            "district": "6",
            "Block": "58",
            "label": "PUMS A.NADUHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9188",
            "district": "6",
            "Block": "58",
            "label": "GHS K NADUHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9189",
            "district": "6",
            "Block": "58",
            "label": "PUMS MARAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9190",
            "district": "6",
            "Block": "58",
            "label": "GHS MITTANOOLAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9191",
            "district": "6",
            "Block": "58",
            "label": "GHS NAICKENKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9192",
            "district": "6",
            "Block": "58",
            "label": "PUMS PERIYAKURAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9193",
            "district": "6",
            "Block": "58",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9194",
            "district": "6",
            "Block": "58",
            "label": "PUMS PUZHUTHIKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9195",
            "district": "6",
            "Block": "58",
            "label": "ADWMS RAJAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9196",
            "district": "6",
            "Block": "58",
            "label": "PUMS SOGATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9197",
            "district": "6",
            "Block": "58",
            "label": "VARIYAR HR. SEC. SCHOOL, SOGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9198",
            "district": "6",
            "Block": "58",
            "label": "GHS THIPPIREDDIHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9199",
            "district": "6",
            "Block": "58",
            "label": "PUMS V.GOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9200",
            "district": "6",
            "Block": "58",
            "label": "GHS VELLOLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9201",
            "district": "6",
            "Block": "198",
            "label": "AAGHSS ADHIYAMANKOTTAI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9202",
            "district": "6",
            "Block": "198",
            "label": "GHSS ADHIYAMANKOTTAI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9203",
            "district": "6",
            "Block": "198",
            "label": "NEW HORIZON SENIORS ACADEMY MHSS, CHETTIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9204",
            "district": "6",
            "Block": "198",
            "label": "GHSS B AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9205",
            "district": "6",
            "Block": "198",
            "label": "GHSS BANDAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9206",
            "district": "6",
            "Block": "198",
            "label": "GHS BEDRAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9207",
            "district": "6",
            "Block": "198",
            "label": "PUMS BOOTHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9208",
            "district": "6",
            "Block": "198",
            "label": "PUMS EACHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9209",
            "district": "6",
            "Block": "198",
            "label": "GHSS ELAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9210",
            "district": "6",
            "Block": "198",
            "label": "PUMS EASALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9211",
            "district": "6",
            "Block": "198",
            "label": "GHSS ERRABAIYANAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9212",
            "district": "6",
            "Block": "198",
            "label": "GHS GETTUHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9213",
            "district": "6",
            "Block": "198",
            "label": "GHS GOOLIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9214",
            "district": "6",
            "Block": "198",
            "label": "GHSS INDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9215",
            "district": "6",
            "Block": "198",
            "label": "PUMS KAMARAJNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9216",
            "district": "6",
            "Block": "198",
            "label": "ST. THOMAS GIRLS HIGH SCHOOL, KOVILUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9217",
            "district": "6",
            "Block": "198",
            "label": "ST JOHNS  HIGH SCHOOL, KOVILUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9218",
            "district": "6",
            "Block": "198",
            "label": "GHSS LALIGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9219",
            "district": "6",
            "Block": "198",
            "label": "GHSS MANIYATHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9220",
            "district": "6",
            "Block": "198",
            "label": "PUMS MUNDASU PURAVADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9221",
            "district": "6",
            "Block": "198",
            "label": "PUMS NADAPPANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9222",
            "district": "6",
            "Block": "198",
            "label": "GHSS NALLAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9223",
            "district": "6",
            "Block": "198",
            "label": "GHS NATHATHAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9224",
            "district": "6",
            "Block": "198",
            "label": "PUMS OSHAHALLIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9225",
            "district": "6",
            "Block": "198",
            "label": "GHSS PALAYAMPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9226",
            "district": "6",
            "Block": "198",
            "label": "PUMS PALLAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9227",
            "district": "6",
            "Block": "198",
            "label": "PUMS CHINNAIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9228",
            "district": "6",
            "Block": "198",
            "label": "GHS PANGUNATHAMKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9229",
            "district": "6",
            "Block": "198",
            "label": "GHS POOVALMADUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9230",
            "district": "6",
            "Block": "198",
            "label": "GHS SANTHARAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9231",
            "district": "6",
            "Block": "198",
            "label": "GHS SOMENAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9232",
            "district": "6",
            "Block": "198",
            "label": "GHS T GANIGARAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9233",
            "district": "6",
            "Block": "198",
            "label": "SRI SIDDHI VINAYAKA MHSS, THADANGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9234",
            "district": "6",
            "Block": "198",
            "label": "PUMS THALAVAIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9235",
            "district": "6",
            "Block": "198",
            "label": "GHSS THOPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9236",
            "district": "6",
            "Block": "198",
            "label": "PUMS UMMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9237",
            "district": "6",
            "Block": "198",
            "label": "GHSS  VENKATTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9238",
            "district": "6",
            "Block": "198",
            "label": "AMALA HR. SEC. SCHOOL, VIMALAPURI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9239",
            "district": "6",
            "Block": "198",
            "label": "GHS AVVAI NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9240",
            "district": "6",
            "Block": "198",
            "label": "GHS BARIGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9241",
            "district": "6",
            "Block": "198",
            "label": "GHS DHATHANAYAKANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9242",
            "district": "6",
            "Block": "198",
            "label": "GHS KOMATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9243",
            "district": "6",
            "Block": "198",
            "label": "GHS NAGARKOODAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9244",
            "district": "6",
            "Block": "198",
            "label": "GHS NARTHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9245",
            "district": "6",
            "Block": "198",
            "label": "GHS PALAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9246",
            "district": "6",
            "Block": "198",
            "label": "GHS RAJAGOLLAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9247",
            "district": "6",
            "Block": "198",
            "label": "PUMS SIVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9248",
            "district": "6",
            "Block": "198",
            "label": "GHS THAMMANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9249",
            "district": "6",
            "Block": "235",
            "label": "PUMS ALAMARATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9250",
            "district": "6",
            "Block": "235",
            "label": "GHS ATHIMARATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9251",
            "district": "6",
            "Block": "235",
            "label": "PUMS BADHARAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9252",
            "district": "6",
            "Block": "235",
            "label": "PUMS BOOTHINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9253",
            "district": "6",
            "Block": "235",
            "label": "PUMS CHINNAPALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9254",
            "district": "6",
            "Block": "235",
            "label": "PUMS CHINNAPPANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9255",
            "district": "6",
            "Block": "235",
            "label": "GHS CHINNAVATHALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9256",
            "district": "6",
            "Block": "235",
            "label": "GHS DASAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9257",
            "district": "6",
            "Block": "235",
            "label": "PUMS DHONNAGUDDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9258",
            "district": "6",
            "Block": "235",
            "label": "GHS EARIMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9259",
            "district": "6",
            "Block": "235",
            "label": "GHSS ERIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9260",
            "district": "6",
            "Block": "235",
            "label": "GHS ERRAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9261",
            "district": "6",
            "Block": "235",
            "label": "GHS ETTIKUTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9262",
            "district": "6",
            "Block": "235",
            "label": "PUMS ETTIKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9263",
            "district": "6",
            "Block": "235",
            "label": "PUMS GODUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9264",
            "district": "6",
            "Block": "235",
            "label": "GHS HALEPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9265",
            "district": "6",
            "Block": "235",
            "label": "GHSS KALAPPAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9266",
            "district": "6",
            "Block": "235",
            "label": "GHS KARIAPPANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9267",
            "district": "6",
            "Block": "235",
            "label": "PUMS KONDAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9268",
            "district": "6",
            "Block": "235",
            "label": "PUMS KOOTHAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9269",
            "district": "6",
            "Block": "235",
            "label": "GHSS KULLANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9270",
            "district": "6",
            "Block": "235",
            "label": "PUMS M.SANJEEVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9271",
            "district": "6",
            "Block": "235",
            "label": "GHSS MANGARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9272",
            "district": "6",
            "Block": "235",
            "label": "PUMS MANGURUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9273",
            "district": "6",
            "Block": "235",
            "label": "PUMS MALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9274",
            "district": "6",
            "Block": "235",
            "label": "GHS MANJANAIKANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9275",
            "district": "6",
            "Block": "235",
            "label": "PUMS MOONGILMADUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9276",
            "district": "6",
            "Block": "235",
            "label": "PUMS NAGAMARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9277",
            "district": "6",
            "Block": "235",
            "label": "PUMS NALLAPPANAYAKKANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9278",
            "district": "6",
            "Block": "235",
            "label": "SRI VISALI VIDYALAYA MATRIC SCHOOL, AATHANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9279",
            "district": "6",
            "Block": "235",
            "label": "GHSS NERUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9280",
            "district": "6",
            "Block": "235",
            "label": "PUMS PALIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9281",
            "district": "6",
            "Block": "235",
            "label": "GHS PALLIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9282",
            "district": "6",
            "Block": "235",
            "label": "TSSGHSS PAUPARAPATTI - MODEL BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9283",
            "district": "6",
            "Block": "235",
            "label": "GHSS GIRLS PAUPARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9284",
            "district": "6",
            "Block": "235",
            "label": "KGBV RESIDENTIAL SCHOOL, PAUPPARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9285",
            "district": "6",
            "Block": "235",
            "label": "PUMS PAVALANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9286",
            "district": "6",
            "Block": "235",
            "label": "GHSS PENNAGARAM Boys",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9287",
            "district": "6",
            "Block": "235",
            "label": "GHSS PENNAGARAM GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9288",
            "district": "6",
            "Block": "235",
            "label": "SRI VINAYAGA MHSS, PENNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9289",
            "district": "6",
            "Block": "235",
            "label": "APPOLLO MHSS, PENNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9290",
            "district": "6",
            "Block": "235",
            "label": "GHS PERIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9291",
            "district": "6",
            "Block": "235",
            "label": "GHSS PERUMBALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9292",
            "district": "6",
            "Block": "235",
            "label": "ADHIYAMAN HIGH SCHOOL, RAMAKONDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9293",
            "district": "6",
            "Block": "235",
            "label": "GHS POOCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9294",
            "district": "6",
            "Block": "235",
            "label": "PUMS PUDUNAGAMARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9295",
            "district": "6",
            "Block": "235",
            "label": "GHS PUDUBALASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9296",
            "district": "6",
            "Block": "235",
            "label": "GHS PUDUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9297",
            "district": "6",
            "Block": "235",
            "label": "GHS RAMAKONDAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9298",
            "district": "6",
            "Block": "235",
            "label": "PUMS SAMATHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9299",
            "district": "6",
            "Block": "235",
            "label": "GHS KOTTAYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9300",
            "district": "6",
            "Block": "235",
            "label": "GHS SEELANAYAKKANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9301",
            "district": "6",
            "Block": "235",
            "label": "GHSS SELLAMUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9302",
            "district": "6",
            "Block": "235",
            "label": "PUMS SITLAKARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9303",
            "district": "6",
            "Block": "235",
            "label": "PUMS TEAKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9304",
            "district": "6",
            "Block": "235",
            "label": "GHS THIRUMALVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9305",
            "district": "6",
            "Block": "235",
            "label": "PUMS THITHIOPPANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9306",
            "district": "6",
            "Block": "235",
            "label": "PUMS THOTLANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9307",
            "district": "6",
            "Block": "235",
            "label": "PUMS VARAGOORANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9308",
            "district": "6",
            "Block": "235",
            "label": "GHS ALAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9309",
            "district": "6",
            "Block": "235",
            "label": "GHS CHINNAMBALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9310",
            "district": "6",
            "Block": "235",
            "label": "MODEL HSS CHINNAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9311",
            "district": "6",
            "Block": "235",
            "label": "PUMS KADAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9312",
            "district": "6",
            "Block": "235",
            "label": "PUMS NAGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9313",
            "district": "6",
            "Block": "235",
            "label": "GHSS OOTTAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9314",
            "district": "6",
            "Block": "235",
            "label": "GHSS P.GOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9315",
            "district": "6",
            "Block": "218",
            "label": "GHSS AMANIMALLAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9316",
            "district": "6",
            "Block": "218",
            "label": "GHSS BELARAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9317",
            "district": "6",
            "Block": "218",
            "label": "GHSS BELLUHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9318",
            "district": "6",
            "Block": "218",
            "label": "GHS BOOGANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9319",
            "district": "6",
            "Block": "218",
            "label": "GHS BOPPIDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9320",
            "district": "6",
            "Block": "218",
            "label": "GHS GOLASANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9321",
            "district": "6",
            "Block": "218",
            "label": "GHSS KAMMALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9322",
            "district": "6",
            "Block": "218",
            "label": "KGBV RESIDENTIAL SCHOOL, AMANIMALLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9323",
            "district": "6",
            "Block": "218",
            "label": "GHS KOVILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9324",
            "district": "6",
            "Block": "218",
            "label": "GHSS MARANDAHALLI - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9325",
            "district": "6",
            "Block": "218",
            "label": "GHSS MARANDAHALLI - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9326",
            "district": "6",
            "Block": "218",
            "label": "GHS MATTIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9327",
            "district": "6",
            "Block": "218",
            "label": "GHSS PALACODE - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9328",
            "district": "6",
            "Block": "218",
            "label": "GHSS PALACODE - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9329",
            "district": "6",
            "Block": "218",
            "label": "GHSS  PANJAPPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9330",
            "district": "6",
            "Block": "218",
            "label": "GOVT.THANTHAI PERIYAR JUVANILE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9331",
            "district": "6",
            "Block": "218",
            "label": "GHSS PULIKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9332",
            "district": "6",
            "Block": "218",
            "label": "GHS SASTHIRAMUTULU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9333",
            "district": "6",
            "Block": "218",
            "label": "GHS SEKKODI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9334",
            "district": "6",
            "Block": "218",
            "label": "SACRED HEART HR. SEC. SCHOOL, SELLIYAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9335",
            "district": "6",
            "Block": "218",
            "label": "GHSS THIRUMALVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9336",
            "district": "6",
            "Block": "218",
            "label": "PUMS NAMMANDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9337",
            "district": "6",
            "Block": "218",
            "label": "GHS SOMANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9338",
            "district": "6",
            "Block": "109",
            "label": "PUMS ADDILAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9339",
            "district": "6",
            "Block": "109",
            "label": "PUMS BALLENAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9340",
            "district": "6",
            "Block": "109",
            "label": "GHSS BEGARAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9341",
            "district": "6",
            "Block": "109",
            "label": "PUMS BODARA HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9342",
            "district": "6",
            "Block": "109",
            "label": "GHS MEKKANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9343",
            "district": "6",
            "Block": "109",
            "label": "PUMS BOMMANDAHALLI MODUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9344",
            "district": "6",
            "Block": "109",
            "label": "PUMS CHENNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9345",
            "district": "6",
            "Block": "109",
            "label": "PUMS DHASAMBAIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9346",
            "district": "6",
            "Block": "109",
            "label": "PUMS DHINDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9347",
            "district": "6",
            "Block": "109",
            "label": "PUMS ERRASEEGALA HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9348",
            "district": "6",
            "Block": "109",
            "label": "PUMS GENGUCHETTI PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9349",
            "district": "6",
            "Block": "109",
            "label": "PUMS GOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9350",
            "district": "6",
            "Block": "109",
            "label": "PUMS GUNDALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9351",
            "district": "6",
            "Block": "109",
            "label": "GHSS HANUMANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9352",
            "district": "6",
            "Block": "109",
            "label": "GHSS JAKKASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9353",
            "district": "6",
            "Block": "109",
            "label": "PUMS JAMBOOTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9354",
            "district": "6",
            "Block": "109",
            "label": "PUMS JITTANDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9355",
            "district": "6",
            "Block": "109",
            "label": "PUMS KULI KADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9356",
            "district": "6",
            "Block": "109",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9357",
            "district": "6",
            "Block": "109",
            "label": "GHSS KANNIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9358",
            "district": "6",
            "Block": "109",
            "label": "PUMS KARAGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9359",
            "district": "6",
            "Block": "109",
            "label": "GHSS KARIMANGALAM - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9360",
            "district": "6",
            "Block": "109",
            "label": "GHSS KARIMANGALAM - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9361",
            "district": "6",
            "Block": "109",
            "label": "BHARATH MATRIC SCHOOL, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9362",
            "district": "6",
            "Block": "109",
            "label": "KGBV RESIDENTIAL SCHOOL, KARIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9363",
            "district": "6",
            "Block": "109",
            "label": "SHANTHINIKETHAN HR. SEC. SCHOOL, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9364",
            "district": "6",
            "Block": "109",
            "label": "PUMS KATHIRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9365",
            "district": "6",
            "Block": "109",
            "label": "PUMS KERAKODA HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9366",
            "district": "6",
            "Block": "109",
            "label": "PUMS KOTHALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9367",
            "district": "6",
            "Block": "109",
            "label": "GHS KOTTUMARANA HALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9368",
            "district": "6",
            "Block": "109",
            "label": "PUMS KOVILOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9369",
            "district": "6",
            "Block": "109",
            "label": "PUMS KUPPANGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9370",
            "district": "6",
            "Block": "109",
            "label": "GHS MADHAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9371",
            "district": "6",
            "Block": "109",
            "label": "GHSS KAMALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9372",
            "district": "6",
            "Block": "109",
            "label": "GHSS MALLUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9373",
            "district": "6",
            "Block": "109",
            "label": "PUMS MARAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9374",
            "district": "6",
            "Block": "109",
            "label": "GHSS MATLAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9375",
            "district": "6",
            "Block": "109",
            "label": "PUMS MOLAPPANA HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9376",
            "district": "6",
            "Block": "109",
            "label": "PUMS MORASU HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9377",
            "district": "6",
            "Block": "109",
            "label": "PUMS MOTTALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9378",
            "district": "6",
            "Block": "109",
            "label": "PUMS MUKKULAMSEEGALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9379",
            "district": "6",
            "Block": "109",
            "label": "PUMS NAGANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9380",
            "district": "6",
            "Block": "109",
            "label": "PUMS PARAIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9381",
            "district": "6",
            "Block": "109",
            "label": "PUMS PERIYADHABBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9382",
            "district": "6",
            "Block": "109",
            "label": "GHSS PERIYAMPATTI Boys",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9383",
            "district": "6",
            "Block": "109",
            "label": "SRI ANNAMALAIYAR MATRIC SCHOOL, KALAPPANAHALLI, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9384",
            "district": "6",
            "Block": "109",
            "label": "PUMS PULIKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9385",
            "district": "6",
            "Block": "109",
            "label": "PUMS RAMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9386",
            "district": "6",
            "Block": "109",
            "label": "PUMS SOKKANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9387",
            "district": "6",
            "Block": "109",
            "label": "PUMS SONNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9388",
            "district": "6",
            "Block": "109",
            "label": "PUMS SOTTANDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9389",
            "district": "6",
            "Block": "109",
            "label": "PUMS SUNNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9390",
            "district": "6",
            "Block": "109",
            "label": "GHSS BANDHARAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9391",
            "district": "6",
            "Block": "109",
            "label": "GHSS BOMMAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9392",
            "district": "6",
            "Block": "109",
            "label": "PUMS ELUMICHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9393",
            "district": "6",
            "Block": "109",
            "label": "PUMS KUMBARAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9394",
            "district": "6",
            "Block": "109",
            "label": "PUMS MAHENDRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9395",
            "district": "6",
            "Block": "109",
            "label": "PUMS KANDAGA BILE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9396",
            "district": "6",
            "Block": "187",
            "label": "GHSS BUDDIREDDIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9397",
            "district": "6",
            "Block": "187",
            "label": "MARUTHAM MHSS, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9398",
            "district": "6",
            "Block": "187",
            "label": "GHSS CINTHALPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9399",
            "district": "6",
            "Block": "187",
            "label": "GHSS M DODDAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9400",
            "district": "6",
            "Block": "187",
            "label": "PUMS E.AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9401",
            "district": "6",
            "Block": "187",
            "label": "GHSS IRUMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9402",
            "district": "6",
            "Block": "187",
            "label": "GHSS KADATHUR - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9403",
            "district": "6",
            "Block": "187",
            "label": "GHSS KADATHUR - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9404",
            "district": "6",
            "Block": "187",
            "label": "KALAIMAGAL MHSS, KADATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9405",
            "district": "6",
            "Block": "187",
            "label": "SWAMY VIVEKANANDA MATRIC SCHOOL, KADATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9406",
            "district": "6",
            "Block": "187",
            "label": "PUMS KADAYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9407",
            "district": "6",
            "Block": "187",
            "label": "GHSS KAMBAINALLUR - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9408",
            "district": "6",
            "Block": "187",
            "label": "GHSS KAMBAINALLUR - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9409",
            "district": "6",
            "Block": "187",
            "label": "OXFORD MATRIC SCHOOL, SEMMANAHALLI, KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9410",
            "district": "6",
            "Block": "187",
            "label": "SACRED HEART MATRIC SCHOOL, NELLIMARATHUPATTI, KELAVALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9411",
            "district": "6",
            "Block": "187",
            "label": "GHS LINGANAICKANAHALLY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9412",
            "district": "6",
            "Block": "187",
            "label": "GHSS MORAPPUR - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9413",
            "district": "6",
            "Block": "187",
            "label": "GHSS MORAPPUR - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9414",
            "district": "6",
            "Block": "187",
            "label": "VISHWA BHARATHI MHSS, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9415",
            "district": "6",
            "Block": "187",
            "label": "PUMS OBLINAICKANAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9416",
            "district": "6",
            "Block": "187",
            "label": "GHSS ODASALPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9417",
            "district": "6",
            "Block": "187",
            "label": "GHS PAPPICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9418",
            "district": "6",
            "Block": "187",
            "label": "PUMS PERIYURVATHALMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9419",
            "district": "6",
            "Block": "187",
            "label": "GHSS R.GOPINATHAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9420",
            "district": "6",
            "Block": "187",
            "label": "GHSS RAMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9421",
            "district": "6",
            "Block": "187",
            "label": "PUMS REGADAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9422",
            "district": "6",
            "Block": "187",
            "label": "GHSS JALIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9423",
            "district": "6",
            "Block": "187",
            "label": "GHS SANTHAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9424",
            "district": "6",
            "Block": "187",
            "label": "PUMS SEKKANDAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9425",
            "district": "6",
            "Block": "187",
            "label": "GHS T.AYYAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9426",
            "district": "6",
            "Block": "187",
            "label": "PUMS THALANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9427",
            "district": "6",
            "Block": "187",
            "label": "ST. JOSEPH'S HIGH SCHOOL, THENKARAIKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9428",
            "district": "6",
            "Block": "187",
            "label": "GHSS THONGANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9429",
            "district": "6",
            "Block": "187",
            "label": "PUMS VETHARAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9430",
            "district": "6",
            "Block": "187",
            "label": "GHS DASIRAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9431",
            "district": "6",
            "Block": "187",
            "label": "KONGU MHSS, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9432",
            "district": "6",
            "Block": "187",
            "label": "GHS ELAVADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9433",
            "district": "6",
            "Block": "187",
            "label": "PUMS HUNISINAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9434",
            "district": "6",
            "Block": "187",
            "label": "GHS KANDAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9435",
            "district": "6",
            "Block": "187",
            "label": "GHS KETHUREDDIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9436",
            "district": "6",
            "Block": "187",
            "label": "GHSS   NATHAMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9437",
            "district": "6",
            "Block": "187",
            "label": "GHSS   NAVALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9438",
            "district": "6",
            "Block": "187",
            "label": "GHS POLAIYAMPALLY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9439",
            "district": "6",
            "Block": "187",
            "label": "GHS SILLARAHALLY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9440",
            "district": "6",
            "Block": "187",
            "label": "ACHIEVERS ACADEMY MHSS, SUNGARAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9441",
            "district": "6",
            "Block": "187",
            "label": "GHS THIPPAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9442",
            "district": "6",
            "Block": "187",
            "label": "GHS K.EACHCHAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9443",
            "district": "6",
            "Block": "230",
            "label": "PUMS A AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9444",
            "district": "6",
            "Block": "230",
            "label": "PUMS A PALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9445",
            "district": "6",
            "Block": "230",
            "label": "GHSS  ADIGARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9446",
            "district": "6",
            "Block": "230",
            "label": "GHS ALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9447",
            "district": "6",
            "Block": "230",
            "label": "GTRMS ARASANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9448",
            "district": "6",
            "Block": "230",
            "label": "GHSS B.MALLAPURAM GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9449",
            "district": "6",
            "Block": "230",
            "label": "GHSS B.MALLAPURAM BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9450",
            "district": "6",
            "Block": "230",
            "label": "AMG MHSS, NADUR, BOMMIDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9451",
            "district": "6",
            "Block": "230",
            "label": "CARMEL HR. SEC. SCHOOL, B. PALLIPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9452",
            "district": "6",
            "Block": "230",
            "label": "ST.MARY'S MATRIC SCHOOL, B.PALLIPATTI, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9453",
            "district": "6",
            "Block": "230",
            "label": "GHSS B.THURINJIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9454",
            "district": "6",
            "Block": "230",
            "label": "GHSS BAIRNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9455",
            "district": "6",
            "Block": "230",
            "label": "PUMS BOTHAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9456",
            "district": "6",
            "Block": "230",
            "label": "GTRMS ELANTHAKOTTAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9457",
            "district": "6",
            "Block": "230",
            "label": "PUMS GUNDALMADUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9458",
            "district": "6",
            "Block": "230",
            "label": "KGV GHSS H.PUDUPATTI-BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9459",
            "district": "6",
            "Block": "230",
            "label": "GTRHS KALASAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9460",
            "district": "6",
            "Block": "230",
            "label": "GHSS KALIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23668",
            "district": "6",
            "Block": "198",
            "label": "Sri Jansi High School ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "9461",
            "district": "6",
            "Block": "230",
            "label": "GTRMS KALLATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9462",
            "district": "6",
            "Block": "230",
            "label": "PUMS KATHIRIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9463",
            "district": "6",
            "Block": "230",
            "label": "GHS KOMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9464",
            "district": "6",
            "Block": "230",
            "label": "GTRMS MANKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9465",
            "district": "6",
            "Block": "230",
            "label": "GTRMS MANNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9466",
            "district": "6",
            "Block": "230",
            "label": "GHSS MENASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9467",
            "district": "6",
            "Block": "230",
            "label": "GHS MOLAYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9468",
            "district": "6",
            "Block": "230",
            "label": "SREE VETRI VIKAS MHSS, MOLAYANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9469",
            "district": "6",
            "Block": "230",
            "label": "PKV GHSS MOOKAREDDIPATTI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9470",
            "district": "6",
            "Block": "230",
            "label": "BLESSING MIDDLE SCHOOL, MULLERIKADU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9471",
            "district": "6",
            "Block": "230",
            "label": "PUMS MULLIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9472",
            "district": "6",
            "Block": "230",
            "label": "GTRMS NOCHIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9473",
            "district": "6",
            "Block": "230",
            "label": "PUMS ODDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9474",
            "district": "6",
            "Block": "230",
            "label": "PUMS PAPPAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9475",
            "district": "6",
            "Block": "230",
            "label": "GHSS PAPPIREDDIPATTI - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9476",
            "district": "6",
            "Block": "230",
            "label": "GHSS PAPPIREDDIPATTI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9477",
            "district": "6",
            "Block": "230",
            "label": "GTRMS PERERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9478",
            "district": "6",
            "Block": "230",
            "label": "PUMS PERERIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9479",
            "district": "6",
            "Block": "230",
            "label": "GHS S.AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9480",
            "district": "6",
            "Block": "230",
            "label": "PUMS SALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9481",
            "district": "6",
            "Block": "230",
            "label": "PUMS SELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9482",
            "district": "6",
            "Block": "230",
            "label": "GTRHSS SITHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9483",
            "district": "6",
            "Block": "230",
            "label": "GHS VASIGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9484",
            "district": "6",
            "Block": "230",
            "label": "GTRMS VELLAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9485",
            "district": "6",
            "Block": "230",
            "label": "GHS VENKATASAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9486",
            "district": "6",
            "Block": "230",
            "label": "GHS LAKSHMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9487",
            "district": "6",
            "Block": "230",
            "label": "GHS PILPARUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9488",
            "district": "6",
            "Block": "230",
            "label": "PUMS SAMIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9489",
            "district": "6",
            "Block": "77",
            "label": "GHS A VELAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9490",
            "district": "6",
            "Block": "77",
            "label": "PUMS  C GOBINATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9491",
            "district": "6",
            "Block": "77",
            "label": "GHS CHINNANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9492",
            "district": "6",
            "Block": "77",
            "label": "GHS ELLAPUDIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9493",
            "district": "6",
            "Block": "77",
            "label": "JAYAM VIDHYALAYA MHSS, H.DHODDAMPATTI, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9494",
            "district": "6",
            "Block": "77",
            "label": "GHSS HARUR BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9495",
            "district": "6",
            "Block": "77",
            "label": "GHSS HARUR GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23700",
            "district": "6",
            "Block": "187",
            "label": "jayasree vidhyalaya matric school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "9496",
            "district": "6",
            "Block": "77",
            "label": "ST. MARY'S HR. SEC. SCHOOL, HARUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9497",
            "district": "6",
            "Block": "77",
            "label": "GHSS JAMMANAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9498",
            "district": "6",
            "Block": "77",
            "label": "GHSS K VETRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9499",
            "district": "6",
            "Block": "77",
            "label": "GHS KEERAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9500",
            "district": "6",
            "Block": "77",
            "label": "GHS KELAPPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9501",
            "district": "6",
            "Block": "77",
            "label": "PUMS KOMARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9502",
            "district": "6",
            "Block": "77",
            "label": "PUMS KONGAVEMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9503",
            "district": "6",
            "Block": "77",
            "label": "ARINGAR ANNA GHSS KOTTAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9504",
            "district": "6",
            "Block": "77",
            "label": "GHS M.THATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9505",
            "district": "6",
            "Block": "77",
            "label": "GHS MAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9506",
            "district": "6",
            "Block": "77",
            "label": "SRI SARASWATHY MHSS, ITLAPPATTI, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9507",
            "district": "6",
            "Block": "77",
            "label": "DR. ANNAL AMBEDKAR GOVT. ADWHSS, MARUTHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9508",
            "district": "6",
            "Block": "77",
            "label": "GHS MATHIAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9509",
            "district": "6",
            "Block": "77",
            "label": "GHSS NARIPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9510",
            "district": "6",
            "Block": "77",
            "label": "GTRHSS NARIPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9511",
            "district": "6",
            "Block": "77",
            "label": "GHS PETHATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9512",
            "district": "6",
            "Block": "77",
            "label": "PUMS RAYAPPAN KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9513",
            "district": "6",
            "Block": "77",
            "label": "GTRHS S.THATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9514",
            "district": "6",
            "Block": "77",
            "label": "GHS SATTAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9515",
            "district": "6",
            "Block": "77",
            "label": "GHS SELLAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9516",
            "district": "6",
            "Block": "77",
            "label": "R.V. HR. SEC. SCHOOL, SENDRAYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9517",
            "district": "6",
            "Block": "77",
            "label": "GHS T AMMAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9518",
            "district": "6",
            "Block": "77",
            "label": "GHSS THEERTHAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9519",
            "district": "6",
            "Block": "77",
            "label": "ERK HR. SEC. SCHOOL, ERUMIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9520",
            "district": "6",
            "Block": "77",
            "label": "GHS VEDAKATTAMADUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9521",
            "district": "6",
            "Block": "77",
            "label": "GHS VELANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9522",
            "district": "6",
            "Block": "77",
            "label": "GHSS VEPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9523",
            "district": "6",
            "Block": "77",
            "label": "BHARATHI VIDYALAYA HIGH SCHOOL, VEPPAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9524",
            "district": "6",
            "Block": "77",
            "label": "GHS BAIRNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9525",
            "district": "6",
            "Block": "77",
            "label": "GHS PARAYAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9526",
            "district": "6",
            "Block": "77",
            "label": "INDIAN MHSS, GOPINATHAMPATTI, PARAIYAPPATTI PUDUR, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9527",
            "district": "6",
            "Block": "77",
            "label": "GHS PONNERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9528",
            "district": "6",
            "Block": "77",
            "label": "GHS SIKKALURE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9529",
            "district": "6",
            "Block": "77",
            "label": "GHSS SITLINGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9530",
            "district": "6",
            "Block": "77",
            "label": "GTRHSS VACHATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20932",
            "district": "6",
            "Block": "187",
            "label": "SHRI GURU RAGHAVENDRA MATRIC SCHOOL, SILLARAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21012",
            "district": "6",
            "Block": "198",
            "label": "ISHA VIDHYA INFOSYS MHSS, SAMYCHETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21013",
            "district": "6",
            "Block": "198",
            "label": "AKSHAYA MHSS, NATHAHALLI, INDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21059",
            "district": "6",
            "Block": "58",
            "label": "TARUWIN VIDHYA MANDIR SCHOOL, POOSARIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21199",
            "district": "6",
            "Block": "218",
            "label": "MODEL SCHOOL - PALACODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21200",
            "district": "6",
            "Block": "109",
            "label": "MODEL SCHOOL KARIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21240",
            "district": "6",
            "Block": "109",
            "label": "SHREE B.C.R. MHSS, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21260",
            "district": "6",
            "Block": "187",
            "label": "SRI RAM MHSS, BOOMISAMUTHIRAM, KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21261",
            "district": "6",
            "Block": "230",
            "label": "VAAGAI VIDHYALAYA MHSS, H.PUDUPATTI, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21262",
            "district": "6",
            "Block": "77",
            "label": "SRI VIJAYYA VIDHYALAYA MHSS, H.DHODDAMPATTI, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21301",
            "district": "6",
            "Block": "58",
            "label": "DAWN SHIKSHALAYA PUBLIC SCHOOL, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21302",
            "district": "6",
            "Block": "58",
            "label": "THE VIJAY MILLENNIUM SCHOOL, SOGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21303",
            "district": "6",
            "Block": "58",
            "label": "KAMALAM INTERNATIONAL SCHOOL, K. NADUHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21304",
            "district": "6",
            "Block": "77",
            "label": "INDIAN PUBLIC SCHOOL , GOBINATHAMPATTI KOOT ROAD, PARAYAPATTI PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21442",
            "district": "6",
            "Block": "198",
            "label": "GHS B.AGRAHARAM GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21443",
            "district": "6",
            "Block": "198",
            "label": "GHS INDUR GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21444",
            "district": "6",
            "Block": "198",
            "label": "GHS BALAJANGAMANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21445",
            "district": "6",
            "Block": "109",
            "label": "GHSS PERIYAMPATTI - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21446",
            "district": "6",
            "Block": "187",
            "label": "GHS MUTHANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21447",
            "district": "6",
            "Block": "230",
            "label": "GHS B.THURINJIPATTI GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21544",
            "district": "6",
            "Block": "109",
            "label": "SHARON HIGH SCHOOL, MATLAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21589",
            "district": "6",
            "Block": "235",
            "label": "SIVAM INTERNATIONAL SCHOOL, THITHIYOPANAHALLI, PENNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21590",
            "district": "6",
            "Block": "77",
            "label": "SREE KUMARAGURU MATRIC SCHOOL, KOKKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21658",
            "district": "6",
            "Block": "77",
            "label": "SRI ARUNACHALA HIGH SCHOOL, MANDHIKULAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21659",
            "district": "6",
            "Block": "77",
            "label": "BHARATH HI-TECH MHSS, PULUTHIYUR, KOKKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21660",
            "district": "6",
            "Block": "218",
            "label": "ST. AROKIA ANNAI SCHOOL, SELLIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21661",
            "district": "6",
            "Block": "198",
            "label": "SRI RAMACHANDRA MATRIC SCHOOL, DHOMBARAKAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21662",
            "district": "6",
            "Block": "58",
            "label": "MKS MATRIC SCHOOL, SOGATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21663",
            "district": "6",
            "Block": "58",
            "label": "AUXILIUM MHSS, SOGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21951",
            "district": "6",
            "Block": "230",
            "label": "SIGARAM MHSS, PAPPAMBADI , PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22074",
            "district": "6",
            "Block": "58",
            "label": "DNV International Academy",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22075",
            "district": "6",
            "Block": "198",
            "label": "SENTHIL MHSS, ADHIYAMANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22076",
            "district": "6",
            "Block": "198",
            "label": "SENTHIL PUBLIC SCHOOL, ADHIYAMANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22077",
            "district": "6",
            "Block": "235",
            "label": "THE SPARTANS SCHOOL OF EXCELLENCE MATRIC SCHOOL, PARUVATHANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22078",
            "district": "6",
            "Block": "218",
            "label": "GHS AGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22079",
            "district": "6",
            "Block": "218",
            "label": "GHS KANNUKARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22080",
            "district": "6",
            "Block": "109",
            "label": "SRI VIJAY VIDYALAYA MATRIC SCHOOL, THANDUKARANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22081",
            "district": "6",
            "Block": "77",
            "label": "MULLAI MHSS, MAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22400",
            "district": "6",
            "Block": "58",
            "label": "SASSI GNANODAYA ACADEMY, NALLANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22401",
            "district": "6",
            "Block": "235",
            "label": "VIVEKANANDA VIDYALAYA MATRIC SCHOOL, JANGAMAIYANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22402",
            "district": "6",
            "Block": "235",
            "label": "LOTUS MATRIC SCHOOL, PENNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22403",
            "district": "6",
            "Block": "235",
            "label": "NETAJI SUBHASH CHANDRA BOSE AVASIYA VIDYALAYA, PENNAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22404",
            "district": "6",
            "Block": "235",
            "label": "AASAN HIGH SCHOOL, KOTTAYUR, SATHIYANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22405",
            "district": "6",
            "Block": "218",
            "label": "SRI VENKATESHWARA  MHSS, CHIKKADORANABETTAM, MARANDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22406",
            "district": "6",
            "Block": "218",
            "label": "RADHA MATRIC SCHOOL, VELLICHANDHAI, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22407",
            "district": "6",
            "Block": "218",
            "label": "GHS VALAITHOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22408",
            "district": "6",
            "Block": "109",
            "label": "GHS THUMBALAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22409",
            "district": "6",
            "Block": "187",
            "label": "GHS SENKUTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22410",
            "district": "6",
            "Block": "187",
            "label": "GHS VEPPILAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22880",
            "district": "6",
            "Block": "187",
            "label": "SRIRAM PUBLIC SCHOOL, BHOOMISAMUTHIRAM, KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22941",
            "district": "6",
            "Block": "77",
            "label": "MAHARISHI VIDYA MANDIR, PULUTHIYUR, KOKKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22942",
            "district": "6",
            "Block": "77",
            "label": "GHS POYYAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22943",
            "district": "6",
            "Block": "235",
            "label": "GHS JELMARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22944",
            "district": "6",
            "Block": "218",
            "label": "GHS EACHAMPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22945",
            "district": "6",
            "Block": "218",
            "label": "GHS JOGIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22946",
            "district": "6",
            "Block": "109",
            "label": "GHS GUNDAGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22981",
            "district": "6",
            "Block": "187",
            "label": "AVVAI VIDHYASHRAM PUBLIC SCHOOL, POLAIYAMPALLI, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22982",
            "district": "6",
            "Block": "187",
            "label": "GREEN PARK INTERNATIONAL SCHOOL, KADATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22986",
            "district": "6",
            "Block": "187",
            "label": "GREEN PARK MHSS, KADATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22998",
            "district": "6",
            "Block": "77",
            "label": "HARUR INTERNATIONAL SCHOOL, H.DODDAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23008",
            "district": "6",
            "Block": "235",
            "label": "VIVEKANANDA MATRIC SCHOOL, PUDHUR SOLAPPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23034",
            "district": "6",
            "Block": "109",
            "label": "SRI RAGHAVENDRA HIGH SCHOOL, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23049",
            "district": "6",
            "Block": "230",
            "label": "GOLDEN GATES MHSS, BAIRNATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23057",
            "district": "6",
            "Block": "187",
            "label": "AVVAI MHSS, JADAYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23086",
            "district": "6",
            "Block": "235",
            "label": "NEWTONS APPLE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23087",
            "district": "6",
            "Block": "187",
            "label": "KONGU SCHOOL OF EXCELLENCE, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23418",
            "district": "6",
            "Block": "198",
            "label": "National Child Labour Project, Special Training  Center",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23421",
            "district": "6",
            "Block": "187",
            "label": "Pughal Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23477",
            "district": "6",
            "Block": "58",
            "label": "DHARMAPURI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23478",
            "district": "6",
            "Block": "58",
            "label": "Government Model School, Dharmapuri",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23479",
            "district": "6",
            "Block": "218",
            "label": "NEW GENERATION N&P SCHOOL, PANJAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23769",
            "district": "6",
            "Block": "58",
            "label": "SREE SIVANIVEL VIDHYALAYA MATRICULATION SCHOOL, UNGARANAHALLI, DHARMAPURI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23789",
            "district": "6",
            "Block": "77",
            "label": "PUMS CHANDRAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23814",
            "district": "6",
            "Block": "109",
            "label": "PUMS MARAVADI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23886",
            "district": "6",
            "Block": "198",
            "label": "PUMS PAPPAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23898",
            "district": "6",
            "Block": "218",
            "label": "PUMS NALLAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23899",
            "district": "6",
            "Block": "218",
            "label": "GHS AGARAM",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23913",
            "district": "6",
            "Block": "235",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23914",
            "district": "6",
            "Block": "235",
            "label": "PUMS MAKKANUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23915",
            "district": "6",
            "Block": "235",
            "label": "PUMS KADAMADAI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "7": [
        {
            "key": "607",
            "district": "7",
            "Block": "352",
            "label": "RENUGADEVI MATRIC HIGHER SECONDARY SCHOOL, VAGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2576",
            "district": "7",
            "Block": "59",
            "label": "MVM GIRLS MATRIC HSS, ANGUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2782",
            "district": "7",
            "Block": "59",
            "label": "PRASIDDHI VIDYODAYA, SELAPPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2784",
            "district": "7",
            "Block": "60",
            "label": "SMBM MATRIC HSS,GTN ROAD, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2787",
            "district": "7",
            "Block": "59",
            "label": "SHRI GURUMUKI VIDHYASHRAM,PUDHUKAPPLIAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2799",
            "district": "7",
            "Block": "60",
            "label": "ST.JOSEPH'S MATRICULATION HSS, WARD 13, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2831",
            "district": "7",
            "Block": "390",
            "label": "INFANT JESUS MATRICULATION SCHOOL,VEDASANDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2839",
            "district": "7",
            "Block": "380",
            "label": "SAKTHISAI MATRICULATION SCHOOL,THANGAMAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2883",
            "district": "7",
            "Block": "59",
            "label": "ST.PATRICKS ACADEMY, C.KALANAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2895",
            "district": "7",
            "Block": "213",
            "label": "AKSHAYA ACADEMY MATRIC, KOSAVAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2937",
            "district": "7",
            "Block": "212",
            "label": "KAVIYAN MATRIC HIGH SCHOOL AMMAYANAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3188",
            "district": "7",
            "Block": "127",
            "label": "ST THOMAS AQUINAS MATRIC HIGHER SECONDARY SCHOOL,PETHUPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3189",
            "district": "7",
            "Block": "29",
            "label": "SAKTHIVELAVAN VIDHYALAYA,CHINNALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3192",
            "district": "7",
            "Block": "60",
            "label": "SAHAYA MATHA MATRIC SCHOOL,DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3208",
            "district": "7",
            "Block": "60",
            "label": "EQUITAS GURUKUL MATRICULATION HIGHER SECONDARY SCHOOL,DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3250",
            "district": "7",
            "Block": "207",
            "label": "RAMSUN'S MATRIC HIGER SECONDARY SCHOOL ,MEYYAMPATTY,NATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3266",
            "district": "7",
            "Block": "36",
            "label": "ANNAI VAILANKANNI MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3268",
            "district": "7",
            "Block": "59",
            "label": "KK AYYA NADAR GANGAMIRTHAMMAL MATRIC SCHOOL,BALAKRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3281",
            "district": "7",
            "Block": "275",
            "label": "ST.ANNES MATRIC SCHOOL,VEMBARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3295",
            "district": "7",
            "Block": "213",
            "label": "SRI KRISHNA MATRICULATION Hr. SEC. SCHOOL, ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3325",
            "district": "7",
            "Block": "268",
            "label": "SSM ACADEMY AKKARAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3340",
            "district": "7",
            "Block": "29",
            "label": "VICTORY MATRICULATION HR. SEC. SCHOOL,CHINNALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3375",
            "district": "7",
            "Block": "127",
            "label": "JAYARAJ ANNAPACKIAM MATRICULATION SCHOOL, PANNAIKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3382",
            "district": "7",
            "Block": "220",
            "label": "ST. PETER'S MATRICULATION SCHOOL,PALANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3397",
            "district": "7",
            "Block": "219",
            "label": "PRG VN MAT SCH,NEIKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3422",
            "district": "7",
            "Block": "59",
            "label": "PANNAI MATRICULATION SCHOOL,MULLIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3431",
            "district": "7",
            "Block": "127",
            "label": "SRI SANKARA MHSS,KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3441",
            "district": "7",
            "Block": "213",
            "label": "ST.JAMES MATRIC SCHOOL,ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3455",
            "district": "7",
            "Block": "219",
            "label": "DEVI MATRICULATION HIGHER SECONDARY SCHOOL,K. G. VALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3460",
            "district": "7",
            "Block": "60",
            "label": "SOPHIYA MATRIC SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3476",
            "district": "7",
            "Block": "127",
            "label": "BRINDAVAN MHSS,KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3478",
            "district": "7",
            "Block": "213",
            "label": "HOLY CROSS MATRICULATION HIGHER SECONDARY SCHOOL,ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3480",
            "district": "7",
            "Block": "59",
            "label": "KENNEDY MATRICULATION HIGHER SECONDARY SCHOOL, VAZHAKKAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3487",
            "district": "7",
            "Block": "213",
            "label": "KALVI MATRIC HR. SEC. SCHOOL, KAPPILIYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3489",
            "district": "7",
            "Block": "29",
            "label": "VIDYA SIKSHAA MATRIC HIGHER SECONDARY SCHOOL, BODIKAMANVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3506",
            "district": "7",
            "Block": "207",
            "label": "RC MATRICULATION HIGHER SECONDARY SCHOOL,NATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3533",
            "district": "7",
            "Block": "213",
            "label": "CHRISTIAN MATRICULATION HSS,ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3539",
            "district": "7",
            "Block": "60",
            "label": "LIONS MATRICULATION HIGHER SECONDARY SCHOOL,DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3543",
            "district": "7",
            "Block": "59",
            "label": "CRESENT MAT HS, ANGUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3600",
            "district": "7",
            "Block": "59",
            "label": "HOLYCROSS CONVENT MATRIC HR SEC SCHOOL, RAMAYANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3638",
            "district": "7",
            "Block": "268",
            "label": "HAJI MU. MAT.SCH,SRIRAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3811",
            "district": "7",
            "Block": "220",
            "label": "KARTHIK VIDHYA MANDIR MATRIC HIGHER SECONDARY SCHOOL, PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3815",
            "district": "7",
            "Block": "36",
            "label": "N.S.V.V MATRIC HSS, PATTIVEERANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3827",
            "district": "7",
            "Block": "29",
            "label": "LEO MATRIC SCHOOL,ANNAMALAIYAR MILLS COLONY, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3852",
            "district": "7",
            "Block": "127",
            "label": "SAANTHAM MATRICULATION HIGHER SECONDARY SCHOOL,NAIDUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3877",
            "district": "7",
            "Block": "213",
            "label": "BFHV MATRICULATION HSS,ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3878",
            "district": "7",
            "Block": "29",
            "label": "HOLY CROSS MATRICULATION HIGHER SECONDARY SCHOOL,VAKKAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3885",
            "district": "7",
            "Block": "127",
            "label": "ZION MHSS,KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3912",
            "district": "7",
            "Block": "60",
            "label": "SRI VASAVI MATRICULATION HR. SEC. SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3964",
            "district": "7",
            "Block": "59",
            "label": "SEVENTH DAY ADVENTIST MATRIC HSS,PONNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4007",
            "district": "7",
            "Block": "207",
            "label": "SFS MATRICULATION SCHOOL ,SENDURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4047",
            "district": "7",
            "Block": "213",
            "label": "AKSHAYA MATRICULATION HSS,LAKKAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4058",
            "district": "7",
            "Block": "220",
            "label": "AKSHAYA ACADEMY MATRIC HSS,PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4121",
            "district": "7",
            "Block": "352",
            "label": "VIVEKANANDA HINDU MATRICULATION SCHOOL, NEELAGOUNDANPATTI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4191",
            "district": "7",
            "Block": "36",
            "label": "SRI SARADHA VIDHYALAYA MATRIC HR SEC SCHOOL, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4233",
            "district": "7",
            "Block": "127",
            "label": "VIVEKANANDHA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, PANNAIKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4308",
            "district": "7",
            "Block": "219",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, AYAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4371",
            "district": "7",
            "Block": "213",
            "label": "SPM OXFORD MATRICULATION HSS,KALANJIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4377",
            "district": "7",
            "Block": "207",
            "label": "SHRI MEENAKSHI MATRIC SCHOOL,N.KOVILPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4780",
            "district": "7",
            "Block": "29",
            "label": "PRISM MATRIC HIGHER SECONDARY SCHOOL, AATHILAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4785",
            "district": "7",
            "Block": "275",
            "label": "NEHRUJI MAT SCHOOL,THIRUMALAIKENI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4862",
            "district": "7",
            "Block": "29",
            "label": "CHERAN VIDHYALAYA  MATRIC HIGHER SECONDARY SCHOOL, KALIKKAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4870",
            "district": "7",
            "Block": "275",
            "label": "VICTORY MATRIC SCHOOL, KAMBILIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4877",
            "district": "7",
            "Block": "60",
            "label": "GREEN PARK MATRICULATION SCHOOL, BEGAMPUR, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4907",
            "district": "7",
            "Block": "212",
            "label": "HNUPR MATRICULATION HR. SEC SCHOOL, NILAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4961",
            "district": "7",
            "Block": "219",
            "label": "CRESCENT MATRICULATION HR.SEC.SCHOOL,NEIKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4967",
            "district": "7",
            "Block": "207",
            "label": "KALLALAGAR MATRICULATION SCHOOL,AYYANARPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4998",
            "district": "7",
            "Block": "220",
            "label": "ARULMIGU PALANIANDAVAR MATRICULATION HSS,PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5057",
            "district": "7",
            "Block": "127",
            "label": "ASSEFA VIDHYALAYA MATRICULATION SCHOOL, KAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5675",
            "district": "7",
            "Block": "36",
            "label": "JEYASEELAN MEM MAT  BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5739",
            "district": "7",
            "Block": "60",
            "label": "ACHYUTA ACADEMY MATRIC HIGHER SECONDARY SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6090",
            "district": "7",
            "Block": "59",
            "label": "SRI LAKSHMI VIDYALAYA MATRIC HR.SEC.SCHOOL,THADIKOMBU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6116",
            "district": "7",
            "Block": "220",
            "label": "KENNEDY MATRICULATION SCHOOL, PALANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6220",
            "district": "7",
            "Block": "212",
            "label": "SOURASHTRA MIDDLE SCHOOL ,NILAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6299",
            "district": "7",
            "Block": "36",
            "label": "MOUNT ZION MATRIC HR.SEC.SCHOOL, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6669",
            "district": "7",
            "Block": "59",
            "label": "AKSHAYA MATRICULATION HR. SEC. SCHOOL,SELAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13308",
            "district": "7",
            "Block": "29",
            "label": "GTRHSS-PERUMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13309",
            "district": "7",
            "Block": "29",
            "label": "NPR GHSS,AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13310",
            "district": "7",
            "Block": "29",
            "label": "GHS,DEVARAPPANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13311",
            "district": "7",
            "Block": "29",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SANGAREDDYKOTTAI .",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13312",
            "district": "7",
            "Block": "29",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.SINGARAKOTTAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13313",
            "district": "7",
            "Block": "29",
            "label": "GOVT. KALLAR HIGH SCHOOL, NELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13314",
            "district": "7",
            "Block": "29",
            "label": "RVLNA GHSS - SITHAREVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13315",
            "district": "7",
            "Block": "29",
            "label": "PUMS, BODIKAMMANVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13316",
            "district": "7",
            "Block": "29",
            "label": "GHSS, SITHAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13317",
            "district": "7",
            "Block": "29",
            "label": "CSI MIDDLE SCHOOL, PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13318",
            "district": "7",
            "Block": "29",
            "label": "RC SACRED HEART MS-ATHOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13319",
            "district": "7",
            "Block": "29",
            "label": "GHSS, ATHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13320",
            "district": "7",
            "Block": "29",
            "label": "NAGALAKSHMI MATRIC HR SEC SCHOOL,SITHAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13321",
            "district": "7",
            "Block": "29",
            "label": "PUMS, PARAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13322",
            "district": "7",
            "Block": "29",
            "label": "ST.MICHAEL'S MIDDLE SCHOOL, VANNAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13323",
            "district": "7",
            "Block": "29",
            "label": "GANDHIJI MS-MALLAIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13324",
            "district": "7",
            "Block": "29",
            "label": "GHSS,V.KOOTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13325",
            "district": "7",
            "Block": "29",
            "label": "PUMS, J.PUDUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13326",
            "district": "7",
            "Block": "29",
            "label": "PUMS, A.KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13327",
            "district": "7",
            "Block": "29",
            "label": "G.AD.WHSS-MURUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13328",
            "district": "7",
            "Block": "29",
            "label": "PUMS, KEELAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13329",
            "district": "7",
            "Block": "29",
            "label": "DEVANGAR (G) HS,CHINNALAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13330",
            "district": "7",
            "Block": "29",
            "label": "DEVANGAR HIGHER SECONDARY SCHOOL,CHINNALAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13331",
            "district": "7",
            "Block": "29",
            "label": "THAMBITHOTTAM HSS,GANDHIGRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13332",
            "district": "7",
            "Block": "29",
            "label": "ST JOSEPH'S MATRICULATION SCHOOL,CHINNALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13333",
            "district": "7",
            "Block": "29",
            "label": "KASTHURIBA SEVIKASHRAM SPECIAL HIGHER SECONDRAY SCHOOL , GANDHIGRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13334",
            "district": "7",
            "Block": "29",
            "label": "KENDRIYA VIDHYALAYA,GANDHIGRAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13335",
            "district": "7",
            "Block": "29",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,CHETTIAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13336",
            "district": "7",
            "Block": "29",
            "label": "GOVERNMENT HIGH SCHOOL, CHINNALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13337",
            "district": "7",
            "Block": "29",
            "label": "RC MIDDLE SCHOOL, ARIYANELLURE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13338",
            "district": "7",
            "Block": "29",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ,PITHALAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13339",
            "district": "7",
            "Block": "29",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,N.PANJAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13340",
            "district": "7",
            "Block": "29",
            "label": "ST AUGUSTINAR(G)HSS,N.PANJAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13341",
            "district": "7",
            "Block": "29",
            "label": "DR ZAHIR HUSSIAN HSS,PILLAYARNATHAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13342",
            "district": "7",
            "Block": "36",
            "label": "PUMS, K.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13343",
            "district": "7",
            "Block": "36",
            "label": "GOVT. KALLAR MIDDLE SCHOOL, G.THUMMALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13344",
            "district": "7",
            "Block": "36",
            "label": "PUMS, M.VADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13345",
            "district": "7",
            "Block": "36",
            "label": "ANNA AIDED MIDDLE SCHOOL, MUTHALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13346",
            "district": "7",
            "Block": "36",
            "label": "GOVT. KALLAR HSS SEVUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13347",
            "district": "7",
            "Block": "36",
            "label": "N.S.V.V.B HSS PATTIVEERANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13348",
            "district": "7",
            "Block": "36",
            "label": "N.S.V.V.G HSS PATTIVEERANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13349",
            "district": "7",
            "Block": "36",
            "label": "LAKSHMI NARAYANA MATRICULATION SCHOOL, PATTIVEERANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13350",
            "district": "7",
            "Block": "36",
            "label": "GOVT KALLAR HSS, MALLANAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13351",
            "district": "7",
            "Block": "36",
            "label": "PUMS, KULIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13352",
            "district": "7",
            "Block": "36",
            "label": "GHSS, KOMBAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13353",
            "district": "7",
            "Block": "36",
            "label": "PUMS, KATTAKAMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13354",
            "district": "7",
            "Block": "36",
            "label": "PUMS, 1ST WARD BATLAGUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13355",
            "district": "7",
            "Block": "36",
            "label": "GOVT HS OLD  BATLAGUNDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13356",
            "district": "7",
            "Block": "36",
            "label": "GOVT BOYS HSS  BATLAGUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13357",
            "district": "7",
            "Block": "36",
            "label": "HOLYCROSS GIRLSHSS  BATLAGUNDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13358",
            "district": "7",
            "Block": "36",
            "label": "MAHALAKSHMI GHSS  BATLAGUNDU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13359",
            "district": "7",
            "Block": "36",
            "label": "CSI HSS, BATLAGUNDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13360",
            "district": "7",
            "Block": "36",
            "label": "PUMS,VERIYAPPANAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13361",
            "district": "7",
            "Block": "36",
            "label": "GHS, VIRALIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13362",
            "district": "7",
            "Block": "36",
            "label": "GHS, ELUVANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13363",
            "district": "7",
            "Block": "36",
            "label": "GOVT. KALLAR HSS, THEPPATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13364",
            "district": "7",
            "Block": "36",
            "label": "PUMS, VALAIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13365",
            "district": "7",
            "Block": "36",
            "label": "GOVT MODEL HSS, VIRUVEEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13366",
            "district": "7",
            "Block": "36",
            "label": "PUMS, NADAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13367",
            "district": "7",
            "Block": "36",
            "label": "GHSS, SEKKAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13368",
            "district": "7",
            "Block": "36",
            "label": "PUMS, SENGATTAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13369",
            "district": "7",
            "Block": "59",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13370",
            "district": "7",
            "Block": "59",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,AGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13371",
            "district": "7",
            "Block": "59",
            "label": "ST.PAULS HS,ULAGAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13372",
            "district": "7",
            "Block": "59",
            "label": "GOVT. KALLAR MIDDLE SCHOOL, KOTTURAVARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13373",
            "district": "7",
            "Block": "59",
            "label": "ST.JAMES HSS,A.VELLODU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13374",
            "district": "7",
            "Block": "59",
            "label": "GOVT.HSS,NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13375",
            "district": "7",
            "Block": "59",
            "label": "SOUNDARARAJA VIDYALAYA, PONNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13376",
            "district": "7",
            "Block": "59",
            "label": "ST.ANTONY'S AIDED MIDDLE SCHOOL, PUDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13377",
            "district": "7",
            "Block": "59",
            "label": "GHS,MEENAKSHINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13378",
            "district": "7",
            "Block": "59",
            "label": "PUMS, KALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13379",
            "district": "7",
            "Block": "59",
            "label": "ANGUVILAS HIGHER SECONDARY SCHOOL- ANGU NAGAR, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13380",
            "district": "7",
            "Block": "59",
            "label": "HAJI P C NAINAR MOHAMED ORIENTAL ARABIC HIGH SCHOOL, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13381",
            "district": "7",
            "Block": "59",
            "label": "GOVT. KALLAR HIGHER SECONDARY SCHOOL, CHENNAMANAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13382",
            "district": "7",
            "Block": "59",
            "label": "GHS, CHETTINAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13383",
            "district": "7",
            "Block": "59",
            "label": "PUMS,UNDARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13384",
            "district": "7",
            "Block": "59",
            "label": "INFANT JESUS HS,MARAVAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13385",
            "district": "7",
            "Block": "59",
            "label": "GOVERNMENT HIGH SCHOOL -THADIKOMBU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13386",
            "district": "7",
            "Block": "59",
            "label": "PUMS,SEELAPPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13387",
            "district": "7",
            "Block": "59",
            "label": "SHRI MAHARISHI VIDYA MANDIR, SEELAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13388",
            "district": "7",
            "Block": "59",
            "label": "VIDHYA PARTHI HSS,SEELAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13389",
            "district": "7",
            "Block": "59",
            "label": "PUMS,KODANGINAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13390",
            "district": "7",
            "Block": "59",
            "label": "ST.JOSEPH'S MIDDLE SCHOOL, MULLIPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13391",
            "district": "7",
            "Block": "59",
            "label": "PUMS,THAMARAIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13392",
            "district": "7",
            "Block": "59",
            "label": "CSMA HSS,M.M.KOVILUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13393",
            "district": "7",
            "Block": "59",
            "label": "QUAID-E-MILLETH HIGH SCHOOL,RAJAKKAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13394",
            "district": "7",
            "Block": "59",
            "label": "CHEVALIER ACADEMY MATRICULATION HR. SEC. SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13395",
            "district": "7",
            "Block": "59",
            "label": "PUMS,BALAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13396",
            "district": "7",
            "Block": "59",
            "label": "JEHOVAH REFORMED CHRISTIAN HIGH SCHOOL, SEELOVAMNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13397",
            "district": "7",
            "Block": "59",
            "label": "PUMS,THOTTANUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13398",
            "district": "7",
            "Block": "59",
            "label": "GHSS,NALLAMANAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13399",
            "district": "7",
            "Block": "59",
            "label": "ST.ANTONY'S MIDDLE SCHOOL, IRENDALAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13400",
            "district": "7",
            "Block": "59",
            "label": "PUMS,PALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13401",
            "district": "7",
            "Block": "59",
            "label": "HILLOCK INTERNATIONAL SCHOOL,SIRUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13402",
            "district": "7",
            "Block": "59",
            "label": "GOVERNMENT HIGH SCHOOL ,SIRUMALAI PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13403",
            "district": "7",
            "Block": "75",
            "label": "SARASWATHI MS NARASINGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13404",
            "district": "7",
            "Block": "75",
            "label": "GHSS R.VELLODU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13405",
            "district": "7",
            "Block": "75",
            "label": "PUMS, CCC QUARRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13406",
            "district": "7",
            "Block": "75",
            "label": "RANI MEYYAMMAI HSS, CCC QUARRY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13407",
            "district": "7",
            "Block": "75",
            "label": "GHSS, SERVAIKARANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13408",
            "district": "7",
            "Block": "75",
            "label": "PUMS, JEGALAMMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13409",
            "district": "7",
            "Block": "75",
            "label": "VOC HIGH SCHOOL, D.GUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13410",
            "district": "7",
            "Block": "75",
            "label": "PUMS, L.CHETTIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13411",
            "district": "7",
            "Block": "75",
            "label": "GHSS, LANTHAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13412",
            "district": "7",
            "Block": "75",
            "label": "PUMS, RAMAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13413",
            "district": "7",
            "Block": "75",
            "label": "THIRUMURUGAN AIDED MIDDLE SCHOOL, PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13414",
            "district": "7",
            "Block": "75",
            "label": "GHSS, GUZILIAMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13415",
            "district": "7",
            "Block": "75",
            "label": "PUMS, KARIKKALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13416",
            "district": "7",
            "Block": "75",
            "label": "KAMBAN VIDHYALAYA HS, SALIKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13417",
            "district": "7",
            "Block": "75",
            "label": "PUMS, ULLIAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13418",
            "district": "7",
            "Block": "75",
            "label": "PUMS, PALLANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13419",
            "district": "7",
            "Block": "75",
            "label": "PUMS, KOOMBURPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13420",
            "district": "7",
            "Block": "75",
            "label": "PUMS, KONDAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13421",
            "district": "7",
            "Block": "75",
            "label": "GHS, R.P. PILLAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13422",
            "district": "7",
            "Block": "75",
            "label": "PUMS, KILAKUVADUGAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13423",
            "district": "7",
            "Block": "75",
            "label": "PUMS, SUBRAMANIYAPILLAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13424",
            "district": "7",
            "Block": "75",
            "label": "CHINRAJ DHANA GHSS PULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13425",
            "district": "7",
            "Block": "75",
            "label": "PUMS, PUNGAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13426",
            "district": "7",
            "Block": "75",
            "label": "PUMS, CHINNALAGUNAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13427",
            "district": "7",
            "Block": "75",
            "label": "PUMS, R.KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13428",
            "district": "7",
            "Block": "127",
            "label": "PUMS,GUNDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13429",
            "district": "7",
            "Block": "127",
            "label": "PUMS,PALAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13430",
            "district": "7",
            "Block": "127",
            "label": "PUMS,KOOKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13431",
            "district": "7",
            "Block": "127",
            "label": "RC MIDDLE SCHOOL, KAMARAJAPURAM,KOMBAIKADU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13432",
            "district": "7",
            "Block": "127",
            "label": "GHS,VILPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13433",
            "district": "7",
            "Block": "127",
            "label": "GHSS,POOMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13434",
            "district": "7",
            "Block": "127",
            "label": "GHSS, MANNAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13435",
            "district": "7",
            "Block": "127",
            "label": "PUMS,POLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13436",
            "district": "7",
            "Block": "127",
            "label": "PUMS,MELAKILAVARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13437",
            "district": "7",
            "Block": "127",
            "label": "GHS,KAVUNJI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13438",
            "district": "7",
            "Block": "127",
            "label": "GHS,POONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13439",
            "district": "7",
            "Block": "127",
            "label": "ST. JOSEPH'S HIGH SCHOOL,PERUMALMALAI, KODAIKANAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13440",
            "district": "7",
            "Block": "127",
            "label": "PUMS,KUMBARIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13441",
            "district": "7",
            "Block": "127",
            "label": "GHS,POOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13442",
            "district": "7",
            "Block": "127",
            "label": "GHS(GIRLS), PANNAIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13443",
            "district": "7",
            "Block": "127",
            "label": "GHSS,PANNAIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13444",
            "district": "7",
            "Block": "127",
            "label": "NMHS,THANDIKUDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13445",
            "district": "7",
            "Block": "127",
            "label": "PUMS, MANGALAMCOMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13446",
            "district": "7",
            "Block": "127",
            "label": "PUMS,KUPPAMMALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13447",
            "district": "7",
            "Block": "127",
            "label": "GHS,KILAKKUCHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13448",
            "district": "7",
            "Block": "127",
            "label": "PUMS, NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13449",
            "district": "7",
            "Block": "127",
            "label": "PUMS,PACHALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13450",
            "district": "7",
            "Block": "127",
            "label": "PUMS,VADAKAVUNJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13451",
            "district": "7",
            "Block": "127",
            "label": "ST XAVIER'S HSS, NAIDUPURAM, KODAIKANAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13452",
            "district": "7",
            "Block": "127",
            "label": "CSI TOWN  MIDDLE SCHOOL,KODAIKANAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13453",
            "district": "7",
            "Block": "127",
            "label": "CSI MIDDLE SCHOOL,PACKIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13454",
            "district": "7",
            "Block": "127",
            "label": "GHSS,KODAIKANAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13455",
            "district": "7",
            "Block": "127",
            "label": "ST JOHN'S GHSS KODAIKANAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13456",
            "district": "7",
            "Block": "127",
            "label": "RC TOWN HSS,KODAIKANAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13457",
            "district": "7",
            "Block": "127",
            "label": "ST XAVIER'S HIGHER SECONDARY SCHOOL,SHENBAGANUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13458",
            "district": "7",
            "Block": "127",
            "label": "ST PETERS MHSS,PAMBARPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13459",
            "district": "7",
            "Block": "127",
            "label": "BHARATIYA VIDYA BHAVAN'S GANDHI VIDYASHRAM, INTERNATIONAL RESIDENTIAL PUBLIC SCHOOL(CBSE),KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13460",
            "district": "7",
            "Block": "127",
            "label": "KODAI PHSS,KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13461",
            "district": "7",
            "Block": "127",
            "label": "ST. THOMAS MATRICULATION HR. SEC.SCHOOL,NAIDUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13462",
            "district": "7",
            "Block": "127",
            "label": "PRESENTATION CONVENT MATRICULATION HIGHER SECONDARY SCHOOL, NAIDUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13463",
            "district": "7",
            "Block": "127",
            "label": "ST THERASA HS,PAMBARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13464",
            "district": "7",
            "Block": "127",
            "label": "KODAIKANAL INTERNATIONAL SCHOOL, KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23685",
            "district": "7",
            "Block": "60",
            "label": "AUSILIUM HIGH SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "13465",
            "district": "7",
            "Block": "207",
            "label": "GHS,PILLAYARNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13466",
            "district": "7",
            "Block": "207",
            "label": "PUMS, MAMARATHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13467",
            "district": "7",
            "Block": "207",
            "label": "GOVT.HSS. ,SENDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13468",
            "district": "7",
            "Block": "207",
            "label": "SARVASEVA MIDDLE SCHOOL, METTUPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13469",
            "district": "7",
            "Block": "207",
            "label": "GHS, KUDAGIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13470",
            "district": "7",
            "Block": "207",
            "label": "GHS,MANAKATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13471",
            "district": "7",
            "Block": "207",
            "label": "PUMS, SIRANKKATTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13472",
            "district": "7",
            "Block": "207",
            "label": "GOVT.HSS.,KOSUKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13473",
            "district": "7",
            "Block": "207",
            "label": "PUMS, SORIPARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13474",
            "district": "7",
            "Block": "207",
            "label": "SARVASEVA HSS,AYYANARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13475",
            "district": "7",
            "Block": "207",
            "label": "SARVA SEVA SPARROWS NEST MATRICULATION SCHOOL,AYYANARPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13476",
            "district": "7",
            "Block": "207",
            "label": "SARVA SEVA HIGH SCHOOL ,K.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13477",
            "district": "7",
            "Block": "207",
            "label": "GHSS, ARAVANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23375",
            "district": "7",
            "Block": "219",
            "label": "RAINBOW NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "13478",
            "district": "7",
            "Block": "207",
            "label": "PUMS, N.KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13479",
            "district": "7",
            "Block": "207",
            "label": "SARVA SEVA HIGH SCHOOL ,ANDIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13480",
            "district": "7",
            "Block": "207",
            "label": "PUMS, NALLAKANDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13481",
            "district": "7",
            "Block": "207",
            "label": "PUMS, ODUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13482",
            "district": "7",
            "Block": "207",
            "label": "SARVA SEVA MIDDLE SCHOOL,ETTAYAMPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13483",
            "district": "7",
            "Block": "207",
            "label": "GOVT HSS,SIRUGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13484",
            "district": "7",
            "Block": "207",
            "label": "GOVT HSS ,SAMUTHRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13485",
            "district": "7",
            "Block": "207",
            "label": "PUMS,NADUVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13486",
            "district": "7",
            "Block": "207",
            "label": "RCMS, AVICHIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13487",
            "district": "7",
            "Block": "207",
            "label": "PUMS, N. PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13488",
            "district": "7",
            "Block": "207",
            "label": "PUMS P.MALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13489",
            "district": "7",
            "Block": "207",
            "label": "GHS,KUTTUPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13490",
            "district": "7",
            "Block": "207",
            "label": "PUMS,SERVEEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13491",
            "district": "7",
            "Block": "207",
            "label": "DURAIKAMALAM GOVT MODEL HIGHER SECONDARY SCHOOL, N.KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13492",
            "district": "7",
            "Block": "207",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, NATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13493",
            "district": "7",
            "Block": "207",
            "label": "LANDIS MATRIC HIGHER SECONDARY SCHOOL,NATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13494",
            "district": "7",
            "Block": "207",
            "label": "RC HIGHER SECONDARY SCHOOL,NATHAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13495",
            "district": "7",
            "Block": "207",
            "label": "GHS, MULAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13496",
            "district": "7",
            "Block": "207",
            "label": "PUMS, PUNNAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13497",
            "district": "7",
            "Block": "207",
            "label": "GHS, VELAYUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13498",
            "district": "7",
            "Block": "207",
            "label": "PUMS, SATHAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13499",
            "district": "7",
            "Block": "207",
            "label": "PUMS, VILAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13500",
            "district": "7",
            "Block": "207",
            "label": "GHS, KOMANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13501",
            "district": "7",
            "Block": "207",
            "label": "PUMS, PATTANAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13502",
            "district": "7",
            "Block": "207",
            "label": "PUMS, KASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13503",
            "district": "7",
            "Block": "207",
            "label": "GHSS, VATHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13504",
            "district": "7",
            "Block": "207",
            "label": "GHS, LINGAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13505",
            "district": "7",
            "Block": "207",
            "label": "PUMS P.THETHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13506",
            "district": "7",
            "Block": "207",
            "label": "PENIEL HSS,VEMPARALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13507",
            "district": "7",
            "Block": "212",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,GULLICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13508",
            "district": "7",
            "Block": "212",
            "label": "PUMS, GUNDALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13509",
            "district": "7",
            "Block": "212",
            "label": "GOVT KALLAR HIGH SCHOOL SITHARKALNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13510",
            "district": "7",
            "Block": "212",
            "label": "GOVT.KR.HSS,VILAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13511",
            "district": "7",
            "Block": "212",
            "label": "PUMS, PILLAIYARNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13512",
            "district": "7",
            "Block": "212",
            "label": "PUMS,SIRUNAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13513",
            "district": "7",
            "Block": "212",
            "label": "GOVT.KR.HSS,ANAIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13514",
            "district": "7",
            "Block": "212",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,E.KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13515",
            "district": "7",
            "Block": "212",
            "label": "GOVT.KALLAR MIDDLE SCHOOL, MUSUVANUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13516",
            "district": "7",
            "Block": "212",
            "label": "PUMS,VEELINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13517",
            "district": "7",
            "Block": "212",
            "label": "PUMS, S.THUMMALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13518",
            "district": "7",
            "Block": "212",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,CHINNAMANAYAKKANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13519",
            "district": "7",
            "Block": "212",
            "label": "KCM.MATRIC SCHOOL, NILAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13520",
            "district": "7",
            "Block": "212",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, NILAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13521",
            "district": "7",
            "Block": "212",
            "label": "HNUPR GIRLS HSS,NILAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13522",
            "district": "7",
            "Block": "212",
            "label": "ST.JOSEPHS MIDDLE SCHOOL,,SILUKKUVARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13523",
            "district": "7",
            "Block": "212",
            "label": "MUTHURAJA MIDDLE SCHOOL, KARIAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13524",
            "district": "7",
            "Block": "212",
            "label": "RC.HSS,SILUKKUVARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13525",
            "district": "7",
            "Block": "212",
            "label": "SWAMI VIVEKANANDA HIGHER SECONDARY SCHOOL, SILUKKUVARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13526",
            "district": "7",
            "Block": "212",
            "label": "RC.HSS,MICHEALPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13527",
            "district": "7",
            "Block": "212",
            "label": "GOVT.KR.HSS,KAMUPILLAICHATHRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13528",
            "district": "7",
            "Block": "212",
            "label": "PUMS, J.OOTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13529",
            "district": "7",
            "Block": "212",
            "label": "PUMS, ADAIKALAMATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13530",
            "district": "7",
            "Block": "212",
            "label": "PUMS, SAKKAYANAYAKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13531",
            "district": "7",
            "Block": "212",
            "label": "DIRAVIAM GHSS,KAMALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13532",
            "district": "7",
            "Block": "212",
            "label": "GOVT.ADW HIGHER SECONDARY SCHOOL, ALAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13533",
            "district": "7",
            "Block": "212",
            "label": "RC MIDDLE SCHOOL, AMMAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13534",
            "district": "7",
            "Block": "212",
            "label": "GHSS,AMMAYANAYAKANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13535",
            "district": "7",
            "Block": "212",
            "label": "PUMS, POTTICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13536",
            "district": "7",
            "Block": "212",
            "label": "GOVT. KALLAR MIDDLE SCHOOL, PALLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13537",
            "district": "7",
            "Block": "212",
            "label": "GHSS,PALLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13538",
            "district": "7",
            "Block": "212",
            "label": "RC MS KALLADIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13539",
            "district": "7",
            "Block": "212",
            "label": "PUMS, SADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13540",
            "district": "7",
            "Block": "212",
            "label": "GHSS, MATTAPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13541",
            "district": "7",
            "Block": "212",
            "label": "GOVT. KALLAR MIDDLE SCHOOL, RAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13542",
            "district": "7",
            "Block": "213",
            "label": "PUMS,K.KEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13543",
            "district": "7",
            "Block": "213",
            "label": "GHSS,ODAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13544",
            "district": "7",
            "Block": "213",
            "label": "PUMS,VERIYAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13545",
            "district": "7",
            "Block": "213",
            "label": "PUMS,PERIYAKULIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13546",
            "district": "7",
            "Block": "213",
            "label": "GHSS,MUTHUNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13547",
            "district": "7",
            "Block": "213",
            "label": "VIVEKANANDA VIDYALAYA HIGHER SECONDARY SCHOOL,KALANJIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13548",
            "district": "7",
            "Block": "213",
            "label": "PUMS, SALAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13549",
            "district": "7",
            "Block": "213",
            "label": "AKSHAYA ACADEMY CBSE SCHOOL,LAKKAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13550",
            "district": "7",
            "Block": "213",
            "label": "PUMS,KOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13551",
            "district": "7",
            "Block": "213",
            "label": "PUMS,GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13552",
            "district": "7",
            "Block": "213",
            "label": "RC LOYALA MS,ODDANCHATRAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13553",
            "district": "7",
            "Block": "213",
            "label": "CSI MS, THUMMICHAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13554",
            "district": "7",
            "Block": "213",
            "label": "K.R GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, ODDANCHATHRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13555",
            "district": "7",
            "Block": "213",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, ODDANCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13556",
            "district": "7",
            "Block": "213",
            "label": "PUMS,SAMIYARPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13557",
            "district": "7",
            "Block": "213",
            "label": "PUMS,SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13558",
            "district": "7",
            "Block": "213",
            "label": "RC FATHIMA HS,VIRUPATCHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13559",
            "district": "7",
            "Block": "213",
            "label": "GHS, CHINNAKARATTUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13560",
            "district": "7",
            "Block": "213",
            "label": "GHSS,PERIYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13561",
            "district": "7",
            "Block": "213",
            "label": "GHS, KAVERIAMMAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13562",
            "district": "7",
            "Block": "213",
            "label": "PUMS,THANGACHIAMMAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13563",
            "district": "7",
            "Block": "213",
            "label": "PUMS, AMBILIKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13564",
            "district": "7",
            "Block": "213",
            "label": "SNHSS,AMBILIKAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13565",
            "district": "7",
            "Block": "213",
            "label": "HINDU AIDED MIDDLE SCHOOL, CHINNAMANDAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13566",
            "district": "7",
            "Block": "213",
            "label": "PUMS,PALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13567",
            "district": "7",
            "Block": "213",
            "label": "GHS,PARAPPALARU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13568",
            "district": "7",
            "Block": "213",
            "label": "GHSS,MARKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13569",
            "district": "7",
            "Block": "213",
            "label": "PUMS, JAVADHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13570",
            "district": "7",
            "Block": "213",
            "label": "NEHRUJI GHSS,IDAYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13571",
            "district": "7",
            "Block": "213",
            "label": "HINDU AIDED MS-KODANGIPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13572",
            "district": "7",
            "Block": "213",
            "label": "GHS, PULIYAMARATHUKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13573",
            "district": "7",
            "Block": "213",
            "label": "GHSS, P.CHATRAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13574",
            "district": "7",
            "Block": "213",
            "label": "SEENIVASAN MEM.GHSS-GOPALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13575",
            "district": "7",
            "Block": "213",
            "label": "PUMS, VEERALAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13576",
            "district": "7",
            "Block": "213",
            "label": "TRIUMPH ACADEMY MATRICULATION SCHOOL, D.PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13577",
            "district": "7",
            "Block": "219",
            "label": "ST JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL, JAWAHAR NAGAR, PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13578",
            "district": "7",
            "Block": "219",
            "label": "PUMS,KOTHAIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13579",
            "district": "7",
            "Block": "219",
            "label": "VINAYAK VIDYALAYA HSS, KANAKKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13580",
            "district": "7",
            "Block": "219",
            "label": "VANAN MS, KALIKKANAICKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13581",
            "district": "7",
            "Block": "219",
            "label": "JRC HIGHER SECONDARY SCHOOL, SHANMUGANATHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13582",
            "district": "7",
            "Block": "219",
            "label": "PUMS, VARATHAMANATHI ANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13583",
            "district": "7",
            "Block": "219",
            "label": "GHSS,BALASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13584",
            "district": "7",
            "Block": "219",
            "label": "VELAN VIKAS MAT SCH, PM SHED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13585",
            "district": "7",
            "Block": "219",
            "label": "PUMS,THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13586",
            "district": "7",
            "Block": "219",
            "label": "PUMS, K.VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13587",
            "district": "7",
            "Block": "219",
            "label": "GHS,A.KALAYAMPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13588",
            "district": "7",
            "Block": "219",
            "label": "GHS,CHINNAGANDHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13589",
            "district": "7",
            "Block": "219",
            "label": "SARASWATHI HIGH SCHOOL, AMARAPOONDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13590",
            "district": "7",
            "Block": "219",
            "label": "PUMS, GOMBAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13591",
            "district": "7",
            "Block": "219",
            "label": "GHS,KANAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13592",
            "district": "7",
            "Block": "219",
            "label": "ITO HIGHER SECONDARY SCHOOL,AYAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13593",
            "district": "7",
            "Block": "219",
            "label": "GHS,AYAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13594",
            "district": "7",
            "Block": "219",
            "label": "PUMS, OBULAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13595",
            "district": "7",
            "Block": "219",
            "label": "PUMS,PERUMALPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13596",
            "district": "7",
            "Block": "219",
            "label": "PUMS,R.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13597",
            "district": "7",
            "Block": "219",
            "label": "PUMS, PETHANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13598",
            "district": "7",
            "Block": "219",
            "label": "GURUVAPPA HSS,NEIKARAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13599",
            "district": "7",
            "Block": "219",
            "label": "RENUGADEVI HSS,NEIKARAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13600",
            "district": "7",
            "Block": "219",
            "label": "RIPS,NEIKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13601",
            "district": "7",
            "Block": "219",
            "label": "PUMS,PERIYAKALAYAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13602",
            "district": "7",
            "Block": "219",
            "label": "PUMS, VIJAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13603",
            "district": "7",
            "Block": "219",
            "label": "PUMS, AKKAMANAICKENPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13604",
            "district": "7",
            "Block": "219",
            "label": "PUMS, KUTHIRAIYARU ANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13605",
            "district": "7",
            "Block": "219",
            "label": "PUMS,LAKSHMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13606",
            "district": "7",
            "Block": "219",
            "label": "PUMS, ANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13607",
            "district": "7",
            "Block": "219",
            "label": "PUMS,V.P.PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13608",
            "district": "7",
            "Block": "219",
            "label": "PUMS, KUPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13609",
            "district": "7",
            "Block": "219",
            "label": "GHSS,PAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13610",
            "district": "7",
            "Block": "219",
            "label": "PUMS,KAVALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13611",
            "district": "7",
            "Block": "219",
            "label": "PUMS, PONTHUPULLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13612",
            "district": "7",
            "Block": "268",
            "label": "PUMS,PUDUETTAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13613",
            "district": "7",
            "Block": "268",
            "label": "GNANAM MS,KAMBILINAICKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13614",
            "district": "7",
            "Block": "268",
            "label": "GHSS,K.PUDUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13615",
            "district": "7",
            "Block": "268",
            "label": "PUMS,GURUNATHANAICKNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13616",
            "district": "7",
            "Block": "268",
            "label": "PUMS,NAVAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13617",
            "district": "7",
            "Block": "268",
            "label": "PUMS,M.ASARIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13618",
            "district": "7",
            "Block": "268",
            "label": "GHSS,SULLERUMBU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13619",
            "district": "7",
            "Block": "268",
            "label": "PUMS,KATHIRAYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13620",
            "district": "7",
            "Block": "268",
            "label": "PUMS,SILVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13621",
            "district": "7",
            "Block": "268",
            "label": "GKHSS,KONDAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13622",
            "district": "7",
            "Block": "268",
            "label": "GHS, S.VADIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13623",
            "district": "7",
            "Block": "268",
            "label": "PUMS,THEPPAKULATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13624",
            "district": "7",
            "Block": "268",
            "label": "PUMS,KATHIRANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13625",
            "district": "7",
            "Block": "268",
            "label": "PUMS,K.ELLAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13626",
            "district": "7",
            "Block": "268",
            "label": "GMS, KOTTAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13627",
            "district": "7",
            "Block": "268",
            "label": "PUMS,T.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13628",
            "district": "7",
            "Block": "268",
            "label": "GHS,T.PANNAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13629",
            "district": "7",
            "Block": "268",
            "label": "PUMS,SRIRAMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13630",
            "district": "7",
            "Block": "268",
            "label": "PUMS,BOLIYAMMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13631",
            "district": "7",
            "Block": "268",
            "label": "PUMS,VELLAMADATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13632",
            "district": "7",
            "Block": "268",
            "label": "RADHASAMY MMS,RAJAPUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13633",
            "district": "7",
            "Block": "268",
            "label": "GHSS,THIRUMALAIRAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13634",
            "district": "7",
            "Block": "268",
            "label": "PUMS,ADALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13635",
            "district": "7",
            "Block": "268",
            "label": "PUMS,PANDRIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13636",
            "district": "7",
            "Block": "268",
            "label": "GHS,KARISALPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13637",
            "district": "7",
            "Block": "268",
            "label": "GHSS,DHARMATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13638",
            "district": "7",
            "Block": "268",
            "label": "PUMS,D.KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13639",
            "district": "7",
            "Block": "268",
            "label": "PUMS,KUYAVANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13640",
            "district": "7",
            "Block": "268",
            "label": "THIRUMURUGAN MS,KASAVANAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13641",
            "district": "7",
            "Block": "268",
            "label": "GHSS,KONNUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13642",
            "district": "7",
            "Block": "268",
            "label": "MRGGHSS,KANNIVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13643",
            "district": "7",
            "Block": "268",
            "label": "GOVT. BOYS HIGHER SECONDARY SCHOOL,KANNIVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13644",
            "district": "7",
            "Block": "268",
            "label": "ST.MICHEAL'S HS,AMMAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13645",
            "district": "7",
            "Block": "268",
            "label": "ARUPADAIYAPPAN HIGH SCHOOL, ERNAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13646",
            "district": "7",
            "Block": "268",
            "label": "MEENAKSHI HSS,MANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13647",
            "district": "7",
            "Block": "268",
            "label": "MEENAKSHI MATRICULATION  HIGHER SECONDARY SCHOOL, MANGARAI PIRIVU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13648",
            "district": "7",
            "Block": "268",
            "label": "PUMS,PALAM RAJAKKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13649",
            "district": "7",
            "Block": "268",
            "label": "ST.FRANCIS XAVIER HSS,KUTTATHUAVARAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13650",
            "district": "7",
            "Block": "268",
            "label": "SSM MATRICULATION HIGHER SECONDARY SCHOOL, AKKARAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13651",
            "district": "7",
            "Block": "268",
            "label": "LOYOLA HSS,HANUMANTHARAYANKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13652",
            "district": "7",
            "Block": "275",
            "label": "CARMEL MATRICULATION SCHOOL, NOCHIODAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13653",
            "district": "7",
            "Block": "275",
            "label": "GHS,KOOVAKURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13654",
            "district": "7",
            "Block": "275",
            "label": "PUMS, VADAKATTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13655",
            "district": "7",
            "Block": "275",
            "label": "RC AMALA ANNAI MS, THAVASIMADAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13656",
            "district": "7",
            "Block": "275",
            "label": "PUMS, EMAKKALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13657",
            "district": "7",
            "Block": "275",
            "label": "PUMS, KONAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13658",
            "district": "7",
            "Block": "275",
            "label": "GHSS,SHANARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13659",
            "district": "7",
            "Block": "275",
            "label": "PUMS, KANNIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13660",
            "district": "7",
            "Block": "275",
            "label": "GHS,ANJUKULIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13661",
            "district": "7",
            "Block": "275",
            "label": "GHS,AVILIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13662",
            "district": "7",
            "Block": "275",
            "label": "RC CONVENT MS-KOSAVAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13663",
            "district": "7",
            "Block": "275",
            "label": "ST.ANTONY'S HSS,KOSAVAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13664",
            "district": "7",
            "Block": "275",
            "label": "PUMS, M.PANNAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13665",
            "district": "7",
            "Block": "275",
            "label": "HINDU AIDED MS ,THEETHAMPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13666",
            "district": "7",
            "Block": "275",
            "label": "ST.BRITTO HS,PUGAIYILAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13667",
            "district": "7",
            "Block": "275",
            "label": "GHS,SILUVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13668",
            "district": "7",
            "Block": "275",
            "label": "ST.MARCELIN HSS,VANGAMANOOTHU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13669",
            "district": "7",
            "Block": "275",
            "label": "PUMS, K.AMMAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13670",
            "district": "7",
            "Block": "275",
            "label": "PUMS, NILAPATTYPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13671",
            "district": "7",
            "Block": "275",
            "label": "GHSS,KAMBILIAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13672",
            "district": "7",
            "Block": "275",
            "label": "PUMS, KATTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13673",
            "district": "7",
            "Block": "275",
            "label": "PUMS, SADAIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13674",
            "district": "7",
            "Block": "275",
            "label": "PUMS, ALAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13675",
            "district": "7",
            "Block": "275",
            "label": "PUMS, S.VALLAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13676",
            "district": "7",
            "Block": "275",
            "label": "GHSS,SENGURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13677",
            "district": "7",
            "Block": "275",
            "label": "PUMS, MARKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13678",
            "district": "7",
            "Block": "275",
            "label": "SRI NARASIMMAN AMS, V.METTUPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13679",
            "district": "7",
            "Block": "275",
            "label": "GHS, MARUNOOTHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13680",
            "district": "7",
            "Block": "275",
            "label": "PUMS, POOVAKIZHAVANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13681",
            "district": "7",
            "Block": "275",
            "label": "PUMS, J.MANIYAKKARANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13682",
            "district": "7",
            "Block": "275",
            "label": "GHS,T.RAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13683",
            "district": "7",
            "Block": "275",
            "label": "PUMS, SEDIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13684",
            "district": "7",
            "Block": "275",
            "label": "GGHSS,GOPALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13685",
            "district": "7",
            "Block": "275",
            "label": "GHSS,VEMBARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13686",
            "district": "7",
            "Block": "275",
            "label": "PUMS, K.AYYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13687",
            "district": "7",
            "Block": "275",
            "label": "PUMS, PERUMAL KOVILPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13688",
            "district": "7",
            "Block": "275",
            "label": "PUMS, KANAVAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13689",
            "district": "7",
            "Block": "275",
            "label": "GHS,SAKKILIANKODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13690",
            "district": "7",
            "Block": "352",
            "label": "PUMS,KALUTHARIKIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13691",
            "district": "7",
            "Block": "352",
            "label": "GHS,KOOTHAMPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13692",
            "district": "7",
            "Block": "352",
            "label": "GHS,KARIYAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13693",
            "district": "7",
            "Block": "352",
            "label": "PUMS,PERUMALKOVILVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13694",
            "district": "7",
            "Block": "352",
            "label": "C.S.I HIGH SCHOOL,KALLIMANDAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13695",
            "district": "7",
            "Block": "352",
            "label": "THIRUPPATHI ARULNERI HR.SEC.SCHOOL,KALLIMANDAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13696",
            "district": "7",
            "Block": "352",
            "label": "BRINDAVAN MATRICULATION SCHOOL,KALLIMANDHAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13697",
            "district": "7",
            "Block": "352",
            "label": "PUMS,KAPPALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13698",
            "district": "7",
            "Block": "352",
            "label": "PUMS,KOTHAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13699",
            "district": "7",
            "Block": "352",
            "label": "JRC H.SC,KOTHAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13700",
            "district": "7",
            "Block": "352",
            "label": "GOVT.H.SCHOOL,PORULUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13701",
            "district": "7",
            "Block": "352",
            "label": "PUMS, PUNGAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13702",
            "district": "7",
            "Block": "352",
            "label": "GHS,POOLAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13703",
            "district": "7",
            "Block": "352",
            "label": "SANKAR PONNAR HSS, THUMBALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13704",
            "district": "7",
            "Block": "352",
            "label": "PUMS,VILVATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13705",
            "district": "7",
            "Block": "352",
            "label": "GOVT.H.SC,MANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13706",
            "district": "7",
            "Block": "352",
            "label": "MAHESH KARTHIK MATRIC HIGHER SECONDARY SCHOOL,AKKARAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13707",
            "district": "7",
            "Block": "352",
            "label": "GOVT.HSS,THOPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13708",
            "district": "7",
            "Block": "352",
            "label": "GURUGULAM MIDDLE SCHOOL, KEERANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13709",
            "district": "7",
            "Block": "352",
            "label": "GHSS,KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13710",
            "district": "7",
            "Block": "352",
            "label": "C G M HSS,KORIKKADAVU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13711",
            "district": "7",
            "Block": "352",
            "label": "GHSS,THALAIYUTHU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13712",
            "district": "7",
            "Block": "352",
            "label": "GHS,SAMINATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13713",
            "district": "7",
            "Block": "352",
            "label": "GHS,PERICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13714",
            "district": "7",
            "Block": "352",
            "label": "PUMS,ATHIMARATHUVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13715",
            "district": "7",
            "Block": "352",
            "label": "GHSS,KOKKARAKKAL VALASU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13716",
            "district": "7",
            "Block": "352",
            "label": "MANIMEKALAI ELANGO HR SEC SCHOOL,DEVATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13717",
            "district": "7",
            "Block": "352",
            "label": "PUMS,PODUVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13718",
            "district": "7",
            "Block": "352",
            "label": "GHSS,MANJANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13719",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KULATHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13720",
            "district": "7",
            "Block": "380",
            "label": "PUMS,MALAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13721",
            "district": "7",
            "Block": "380",
            "label": "GHS,PAGANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13722",
            "district": "7",
            "Block": "380",
            "label": "GHSS THENNAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13723",
            "district": "7",
            "Block": "380",
            "label": "PUMS, ANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13724",
            "district": "7",
            "Block": "380",
            "label": "PUMS, P.KOSAVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13725",
            "district": "7",
            "Block": "380",
            "label": "GHS,PADIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13726",
            "district": "7",
            "Block": "380",
            "label": "PUMS, VELLAPOMMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13727",
            "district": "7",
            "Block": "380",
            "label": "GHS,VELVARKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13728",
            "district": "7",
            "Block": "380",
            "label": "PUMS, SINGARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13729",
            "district": "7",
            "Block": "380",
            "label": "GHS, K.PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13730",
            "district": "7",
            "Block": "380",
            "label": "PUMS VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13731",
            "district": "7",
            "Block": "380",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, VADAMADURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13732",
            "district": "7",
            "Block": "380",
            "label": "GBHSS VADAMADURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13733",
            "district": "7",
            "Block": "380",
            "label": "PUMS, V.CHITOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13734",
            "district": "7",
            "Block": "380",
            "label": "AHS, KALAIMAGAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13735",
            "district": "7",
            "Block": "380",
            "label": "PUMS G.KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13736",
            "district": "7",
            "Block": "380",
            "label": "PUMS, PILATHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13737",
            "district": "7",
            "Block": "380",
            "label": "PUMS,MAMMANIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13738",
            "district": "7",
            "Block": "380",
            "label": "GHS,KOMBERIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13739",
            "district": "7",
            "Block": "380",
            "label": "GHS,VALAVICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13740",
            "district": "7",
            "Block": "380",
            "label": "PUMS, SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13741",
            "district": "7",
            "Block": "380",
            "label": "PUMS, SITHUVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13742",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13743",
            "district": "7",
            "Block": "380",
            "label": "PUMS,PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13744",
            "district": "7",
            "Block": "380",
            "label": "GHSS,AYYALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13745",
            "district": "7",
            "Block": "380",
            "label": "PUMS,A.KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13746",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KUPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13747",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KINATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13748",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KARUVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13749",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KAKKAYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13750",
            "district": "7",
            "Block": "390",
            "label": "PUMS,ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13751",
            "district": "7",
            "Block": "390",
            "label": "PUMS,PALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13752",
            "district": "7",
            "Block": "390",
            "label": "GOVT. HSS, ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13753",
            "district": "7",
            "Block": "390",
            "label": "PUMS, RENGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13754",
            "district": "7",
            "Block": "390",
            "label": "GOVT MODEL HR SEC SCHOOL, KASIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13755",
            "district": "7",
            "Block": "390",
            "label": "NEHURU AIDED MS.KARATTUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13756",
            "district": "7",
            "Block": "390",
            "label": "GHS, KOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13757",
            "district": "7",
            "Block": "390",
            "label": "PUMS,MINUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13758",
            "district": "7",
            "Block": "390",
            "label": "PUMS,NAGAKKONANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13759",
            "district": "7",
            "Block": "390",
            "label": "PUMS,VEDASANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13760",
            "district": "7",
            "Block": "390",
            "label": "PUMS,NAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13761",
            "district": "7",
            "Block": "390",
            "label": "PUMS,SENANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13762",
            "district": "7",
            "Block": "390",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, VEDASANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13763",
            "district": "7",
            "Block": "390",
            "label": "GOVT BOYS HR SEC SCHOOL, VEDASANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13764",
            "district": "7",
            "Block": "390",
            "label": "BVM MATRICULATION, VEDASANDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13765",
            "district": "7",
            "Block": "390",
            "label": "GHS,VIRUTHALAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13766",
            "district": "7",
            "Block": "390",
            "label": "PUMS,KOVAKKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13767",
            "district": "7",
            "Block": "390",
            "label": "GHSS,NAGAIYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13768",
            "district": "7",
            "Block": "390",
            "label": "PUMS,VAIVESPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13769",
            "district": "7",
            "Block": "390",
            "label": "PUMS,KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13770",
            "district": "7",
            "Block": "390",
            "label": "GHSS,K.RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13771",
            "district": "7",
            "Block": "390",
            "label": "GHS,USILAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13772",
            "district": "7",
            "Block": "390",
            "label": "GHS,CHELLAKUTTIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13773",
            "district": "7",
            "Block": "390",
            "label": "GHS,NALLAMANARKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13774",
            "district": "7",
            "Block": "390",
            "label": "GHS,DEVANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13775",
            "district": "7",
            "Block": "390",
            "label": "PUMS,POOTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13776",
            "district": "7",
            "Block": "390",
            "label": "ST.FRANCIS XAVIER'S R.C. MIDDLE SCHOOL, POOTHAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13777",
            "district": "7",
            "Block": "390",
            "label": "GHS,MARAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13778",
            "district": "7",
            "Block": "390",
            "label": "LITTLE FLOWER GIRL'S HSS, MARAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13779",
            "district": "7",
            "Block": "390",
            "label": "PUMS,THOTTANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13780",
            "district": "7",
            "Block": "390",
            "label": "PUMS,ERIYODU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13781",
            "district": "7",
            "Block": "390",
            "label": "GHSS, ERIYODU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13782",
            "district": "7",
            "Block": "60",
            "label": "HAZRATH AMIRUNNISHA BEGAM ORIENTAL HIGHER SECONDARY SCHOOL,WARD 38, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13783",
            "district": "7",
            "Block": "60",
            "label": "GHSS,PALANI ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13784",
            "district": "7",
            "Block": "60",
            "label": "JOHN PAUL HSS,NEHRUJI NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13785",
            "district": "7",
            "Block": "60",
            "label": "JOHN PAUL ACADEMY CBSE SCHOOL,NEHRUJI NAGAR, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13786",
            "district": "7",
            "Block": "60",
            "label": "ST CHINNAPPAR HS,DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13787",
            "district": "7",
            "Block": "60",
            "label": "SRI VASAVI HSS,WARD 12",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13788",
            "district": "7",
            "Block": "60",
            "label": "GANDHIJI MEMORIAL MIDDLE SCHOOL, 5TH WARD, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13789",
            "district": "7",
            "Block": "60",
            "label": "G(G)HSS,KACHERITHERU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13790",
            "district": "7",
            "Block": "60",
            "label": "ST CECILIAS MS,WARD 13",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13791",
            "district": "7",
            "Block": "60",
            "label": "PUNITHA SOOSAIYAPPAR HSS,DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13792",
            "district": "7",
            "Block": "60",
            "label": "ST. JOSEPH'S GIRLS HSS,WARD13, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13793",
            "district": "7",
            "Block": "60",
            "label": "MSP SOLAI NADAR MEMORIAL HSS,GTN ROAD, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13794",
            "district": "7",
            "Block": "60",
            "label": "OM SHANTHI CBSE SCHOOL,DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13795",
            "district": "7",
            "Block": "60",
            "label": "CSI BOARDING MS,WARD 19, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13796",
            "district": "7",
            "Block": "60",
            "label": "DUDLEY HSS,DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13797",
            "district": "7",
            "Block": "60",
            "label": "ST MARYS HSS,WARD-12",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13798",
            "district": "7",
            "Block": "60",
            "label": "NEHRUJI MEM HSS, DINDIGUL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13799",
            "district": "7",
            "Block": "60",
            "label": "ST FRANCIS XAVIER HSS,WARD-23, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13800",
            "district": "7",
            "Block": "60",
            "label": "GANDHIJI MS,WARD-24",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13801",
            "district": "7",
            "Block": "60",
            "label": "T E L C MS,WARD-18",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13802",
            "district": "7",
            "Block": "60",
            "label": "BHARATHI MIDDLE SCHOOL, WARD-30",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13803",
            "district": "7",
            "Block": "60",
            "label": "RANIMANGAMMAL AIDED MS,WARD-33",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13804",
            "district": "7",
            "Block": "60",
            "label": "THIRU.NAMPERUMAL MIDDLE SCHOOL,WARD-31, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13805",
            "district": "7",
            "Block": "60",
            "label": "SANTHAI ROAD MUNICIPAL MIDDLE SCHOOL,WARD-48, DINDIGUL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13806",
            "district": "7",
            "Block": "60",
            "label": "ARUL JOTHI VALLALAR HIGHER SECONDARY SCHOOL, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13807",
            "district": "7",
            "Block": "60",
            "label": "ST PHILOMINAS MS, WARD-42",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13808",
            "district": "7",
            "Block": "60",
            "label": "ANNAMALAIAR MILLS GIRLS' HR.SEC.SCHOOL, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13809",
            "district": "7",
            "Block": "60",
            "label": "KASUVALLUVAN MIDDLE SCHOOL, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13810",
            "district": "7",
            "Block": "60",
            "label": "METTUPATTI MUNCP HS, WARD-37",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13811",
            "district": "7",
            "Block": "60",
            "label": "ST XAVIER MS,DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13812",
            "district": "7",
            "Block": "60",
            "label": "SOWRASTRA SRI VARADHARAJA HS,WARD 47, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13813",
            "district": "7",
            "Block": "60",
            "label": "OUR LADY OF LOURDES GIRLS HIGHER SECONDARY SCHOOL, DINDIGUL-624002",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13814",
            "district": "7",
            "Block": "60",
            "label": "TPKN MATRIC HIGHER SECONDARY SCHOOL,BEGAMPUR, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13815",
            "district": "7",
            "Block": "60",
            "label": "LOURDU MATHA MATRICULATION SCHOOL,BEGAMPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13816",
            "district": "7",
            "Block": "220",
            "label": "C.S.I WAKEMAN MS,PALANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13817",
            "district": "7",
            "Block": "220",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PALANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13818",
            "district": "7",
            "Block": "220",
            "label": "LITTLE FLOWER HIGH SCHOOL,PALANI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13819",
            "district": "7",
            "Block": "220",
            "label": "MATHANISHA MUSLIM HS,PALANI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13820",
            "district": "7",
            "Block": "220",
            "label": "MUNICIPAL BAZZAR MS,PALANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13821",
            "district": "7",
            "Block": "220",
            "label": "MMS,SHANMUGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13822",
            "district": "7",
            "Block": "220",
            "label": "STANDARD MATRICULATION SCHOOL,PALANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13823",
            "district": "7",
            "Block": "220",
            "label": "AKSHAYA ACADEMY CBSE SCHOOL,PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13824",
            "district": "7",
            "Block": "220",
            "label": "MUNICIPAL MS,ADIVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13825",
            "district": "7",
            "Block": "220",
            "label": "MUNICIPAL(B) HSS,PALANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13826",
            "district": "7",
            "Block": "220",
            "label": "THIRUVALLUVAR MS,PALANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20992",
            "district": "7",
            "Block": "59",
            "label": "PARVATHY'S ANUGRAHA INTERNATIONAL SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20993",
            "district": "7",
            "Block": "59",
            "label": "JMJ MATRIC, SIRUMALAIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21027",
            "district": "7",
            "Block": "29",
            "label": "CHERAN GLOBAL MATRIC. HIGH SCHOOL, SEDAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21028",
            "district": "7",
            "Block": "219",
            "label": "SREE GUNA MATRIC, AMARAPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21029",
            "district": "7",
            "Block": "390",
            "label": "BEDFORD ACADEMY MATRIC HIGHER SECONDARY SCHOOL, MINUKKAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21089",
            "district": "7",
            "Block": "59",
            "label": "PANNAI PUBLIC SCHOOL(CBSE), MULLIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21090",
            "district": "7",
            "Block": "60",
            "label": "ACHYUTA ACADEMY CBSE SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21160",
            "district": "7",
            "Block": "219",
            "label": "SWAMY MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21161",
            "district": "7",
            "Block": "219",
            "label": "BHAARATH VIDHYA BHAVAN MAT.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21278",
            "district": "7",
            "Block": "219",
            "label": "ST.PAUL S MATRICULATION.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21322",
            "district": "7",
            "Block": "29",
            "label": "LUXOR WORLD SCHOOL, CHINNALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21323",
            "district": "7",
            "Block": "268",
            "label": "VIVEKANANDAR VIDHYA MANDIR, SULLERUMBU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21365",
            "district": "7",
            "Block": "75",
            "label": "GHS,R.PUDUKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21366",
            "district": "7",
            "Block": "207",
            "label": "GHS, KOTTAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21367",
            "district": "7",
            "Block": "207",
            "label": "GHS, ULUPPAGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21368",
            "district": "7",
            "Block": "207",
            "label": "GHS, PARALIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21369",
            "district": "7",
            "Block": "352",
            "label": "GHS, MELKARAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21485",
            "district": "7",
            "Block": "212",
            "label": "GOVT KALLAR HIGH SCHOOL E AVARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21502",
            "district": "7",
            "Block": "36",
            "label": "GOVT.ADWHS, V.KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21529",
            "district": "7",
            "Block": "36",
            "label": "IQBALIA  HIGH SCHOOL, BATLAGUNDU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21530",
            "district": "7",
            "Block": "212",
            "label": "NADAR HS,NILAKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21531",
            "district": "7",
            "Block": "60",
            "label": "SRI SAVITHRI VIDHYASALA HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21602",
            "district": "7",
            "Block": "212",
            "label": "RADIANCE PUBLIC SCHOOL(CBSE), KOZHINCHIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21603",
            "district": "7",
            "Block": "380",
            "label": "MOTHER THERASA ACADEMY MATRICULATION SCHOOL, MOREPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21604",
            "district": "7",
            "Block": "390",
            "label": "VIDHYODHAYAA MATRICULATION SCHOOL, THOPPUPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21741",
            "district": "7",
            "Block": "29",
            "label": "RC SAHAYARANI HIGH SCHOOL,CHINNALAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21742",
            "district": "7",
            "Block": "390",
            "label": "SHRI MAHA VIDHYALAYA HS, THANEERPANDAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21743",
            "district": "7",
            "Block": "380",
            "label": "SHRI JAY RAJ VIDYA MANDIR INTERNATIONAL SCHOOL, VELVARKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21876",
            "district": "7",
            "Block": "212",
            "label": "DAMIEN PUBLIC SENIOR SECONDARY SCHOOL(CBSE), DIRAVIAM NAGAR, NILAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21891",
            "district": "7",
            "Block": "36",
            "label": "FIRST STEP PUBLIC SCHOOL, KANAVAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21892",
            "district": "7",
            "Block": "36",
            "label": "APOORVAA PUBLIC SCHOOL (CBSE), M.VADIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21893",
            "district": "7",
            "Block": "36",
            "label": "SWAMI VIVEKANANDA VIDYALAYA, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21894",
            "district": "7",
            "Block": "75",
            "label": "KARUR VIVEGAM VIDHYALAYA HIGH SCHOOL, D.GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22173",
            "district": "7",
            "Block": "29",
            "label": "ORCHIDS SPECIAL SCHOOL, RATHINAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22174",
            "district": "7",
            "Block": "59",
            "label": "AKV VIDYALAYA, KALLIPATTI, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22175",
            "district": "7",
            "Block": "59",
            "label": "ARVI SPECIAL SCHOOL FOR MR, SEELAPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22176",
            "district": "7",
            "Block": "127",
            "label": "SHOLAI SCHOOL CENTRE FOR LEARNING ORGANIC AGRIC & APPROPRIATE TECHNOLOGY BHARATHI ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22177",
            "district": "7",
            "Block": "207",
            "label": "UDHAYAM SPECIAL SCHOOL, VELAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22178",
            "district": "7",
            "Block": "207",
            "label": "GHS, MOONGIL PATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22179",
            "district": "7",
            "Block": "212",
            "label": "PUDHU UDHAYAM SPECIAL SCHOOL FOR MR, MURUGATHOORANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22180",
            "district": "7",
            "Block": "219",
            "label": "AWARD SPECIAL SCHOOL FOR MR, SIVAGIRIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22181",
            "district": "7",
            "Block": "380",
            "label": "BIGDREAMERS ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22182",
            "district": "7",
            "Block": "380",
            "label": "AKSHARA SPECIAL SCHOOL FOR MR,VADAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22496",
            "district": "7",
            "Block": "36",
            "label": "SREE RAMS MATRICULATION SCHOOL, KONNAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22497",
            "district": "7",
            "Block": "36",
            "label": "SRI GURUKRISHNA MATRICULATION SCHOOL, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22498",
            "district": "7",
            "Block": "59",
            "label": "GREEN VALLEY PUBLIC SCHOOL, PALLAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22499",
            "district": "7",
            "Block": "59",
            "label": "GURUKUL SPECIAL SCHOOL FOR MR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22500",
            "district": "7",
            "Block": "75",
            "label": "HAYAGREEVAR'S KONGUNAD ACADEMY SENIOR SECONDARY SCHOOL, D.GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22501",
            "district": "7",
            "Block": "75",
            "label": "GHS, K.ANAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22502",
            "district": "7",
            "Block": "207",
            "label": "GHS, CHITHIRAIKOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22503",
            "district": "7",
            "Block": "207",
            "label": "GHS, PERIYUR PATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22504",
            "district": "7",
            "Block": "207",
            "label": "RAMSUN'S INTERNATIONAL SCHOOL, NATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22505",
            "district": "7",
            "Block": "212",
            "label": "GHS,KOZHINJIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22506",
            "district": "7",
            "Block": "219",
            "label": "HAYAGRIVAR MATRIC HIGHER SECONDARY SCHOOL, RUKKUVARPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22507",
            "district": "7",
            "Block": "275",
            "label": "GHS, VEERACHINNAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22508",
            "district": "7",
            "Block": "352",
            "label": "SHRI VIDHYA MANDHIR(CBSE), PUSHPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22509",
            "district": "7",
            "Block": "380",
            "label": "GHS, THANGAMMAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22510",
            "district": "7",
            "Block": "60",
            "label": "SRI KAMARAJAR MATRICULATION SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22511",
            "district": "7",
            "Block": "60",
            "label": "DNU SMBM PUBLIC SCHOOL(CBSE), DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22856",
            "district": "7",
            "Block": "275",
            "label": "DLV PUBLIC SCHOOL, KANAVAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22938",
            "district": "7",
            "Block": "59",
            "label": "GHS, NALLAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22939",
            "district": "7",
            "Block": "380",
            "label": "GHS, V.KURUNTHAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22994",
            "district": "7",
            "Block": "59",
            "label": "PRNP VIDHYA MANDHIR MATRIC HIGHER SECONDARY SCHOOL, MULLIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22995",
            "district": "7",
            "Block": "219",
            "label": "BHAARATH PUBLIC SCHOOL, PALANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23028",
            "district": "7",
            "Block": "352",
            "label": "SASTI KENDRALAYA INTERNATIONAL PUBLIC SCHOOL, PULIYAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23050",
            "district": "7",
            "Block": "59",
            "label": "VIDHYA PARTHI NATIONAL ACADEMY, SEELAPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23068",
            "district": "7",
            "Block": "213",
            "label": "SRI KRISHNA SCHOOL, ODDANCHATHRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23069",
            "district": "7",
            "Block": "213",
            "label": "BUDS VIDYASHRAM CBSE SCHOOL, ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23070",
            "district": "7",
            "Block": "390",
            "label": "AKSHARA PUBLIC SCHOOL, VELLANAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23100",
            "district": "7",
            "Block": "127",
            "label": "ZION INTERNATIONAL SCHOOL, KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23140",
            "district": "7",
            "Block": "219",
            "label": "BRILLIANT KIDS GLOBAL SCHOOL(CBSE), KANAKKANPATTY, PALANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23176",
            "district": "7",
            "Block": "207",
            "label": "PENIEL SCHOOL(CBSE), VEMPARALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23193",
            "district": "7",
            "Block": "219",
            "label": "HOLY CROSS SCHOOL (CBSE), PALANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23206",
            "district": "7",
            "Block": "36",
            "label": "JAYASEELAN PUBLIC SCHOOL, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23263",
            "district": "7",
            "Block": "59",
            "label": "SBC ACADEMY OF EXCELLENCE, SEELAPADI, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23480",
            "district": "7",
            "Block": "390",
            "label": "SREE SARASWATHY ACADEMY MATRICULATION SCHOOL, SREERAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23770",
            "district": "7",
            "Block": "59",
            "label": "VELAMMAL BODHI CAMPUS, DINDIGUL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23771",
            "district": "7",
            "Block": "59",
            "label": "TAKSHASHILA VIDYA MANDIR, ANAIPATTI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23772",
            "district": "7",
            "Block": "59",
            "label": "J KAY SRI SANKARA PUBLIC SCHOOL, DINDIGUL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23894",
            "district": "7",
            "Block": "213",
            "label": "PUMS,SAMATHUVAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23935",
            "district": "7",
            "Block": "268",
            "label": "VIVEKANANDHA PUBLIC SCHOOL, KUNJANAMPATTI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23936",
            "district": "7",
            "Block": "268",
            "label": "VIDVIDA ACADEMY, SRIRAMAPURAM, DINDIGUL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23943",
            "district": "7",
            "Block": "275",
            "label": "UNITY ACADEMY MATRICULATION SCHOOL, RAJAKKAPATTY",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24031",
            "district": "7",
            "Block": "352",
            "label": "PONMALAR PUBLIC SCHOOL, KEERANUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24052",
            "district": "7",
            "Block": "380",
            "label": "PUMS, ANDIPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24053",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KOLLAPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24054",
            "district": "7",
            "Block": "380",
            "label": "DINDIGUL DISTRICT GOVERNMENT MODEL SCHOOL, DINDIGUL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24063",
            "district": "7",
            "Block": "390",
            "label": "PUMS,PALAPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24064",
            "district": "7",
            "Block": "390",
            "label": "PUMS,KOVILUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "8": [
        {
            "key": "481",
            "district": "8",
            "Block": "281",
            "label": "BHARATHI MATRICULATION SCHOOL, KEMPANAICKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23360",
            "district": "8",
            "Block": "244",
            "label": "SRI ARUL NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23369",
            "district": "8",
            "Block": "65",
            "label": "Nandha Matric Hr.Sec. School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23365",
            "district": "8",
            "Block": "37",
            "label": " Sri sakthi supreme ideal vikas matric higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "495",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGH SCHOOL, PATTIMANIAKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "546",
            "district": "8",
            "Block": "65",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION HS, PATTAKARA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "549",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTTUPULLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "575",
            "district": "8",
            "Block": "65",
            "label": "SHRI JANANI MATRICULATION HIGHER SECONDARY SCHOOL, ELLAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "579",
            "district": "8",
            "Block": "65",
            "label": "VELLALAR GIRLS MATRICULATION HSS, THINDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "593",
            "district": "8",
            "Block": "45",
            "label": "PUMS, SANARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "598",
            "district": "8",
            "Block": "244",
            "label": "VIJAY VIKAS MATRIC HIGHER SECONDARY SCHOOL,KAMBULIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "599",
            "district": "8",
            "Block": "65",
            "label": "SRI VASAVI MATRICULATION SCHOOL, ERODE-638316",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "601",
            "district": "8",
            "Block": "128",
            "label": "ST.A.M.SCHOOL,SAHAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "602",
            "district": "8",
            "Block": "71",
            "label": "RAMAKRISHNA MIDDLE SCHOOL, OLAPPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "605",
            "district": "8",
            "Block": "10",
            "label": "SRV VIDHYYA VICKAS MATRICULATION SCHOOL , CHITTAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "610",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  PERUNTHALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "617",
            "district": "8",
            "Block": "65",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, TEACHERSCOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "618",
            "district": "8",
            "Block": "65",
            "label": "PUMS, B.P.AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "620",
            "district": "8",
            "Block": "65",
            "label": "CMS, RAILWAY COLONY -2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "621",
            "district": "8",
            "Block": "65",
            "label": "PUMS METTUNASUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "623",
            "district": "8",
            "Block": "65",
            "label": "PUMS, ATTAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "630",
            "district": "8",
            "Block": "65",
            "label": "PUMS, MANICKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "635",
            "district": "8",
            "Block": "10",
            "label": "SREE SARAVANA NIKETHAN MATRICULATION HIGHER SECONDARY SCHOOL, NERINJIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "643",
            "district": "8",
            "Block": "20",
            "label": "PUMS BRAMDESAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "646",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "647",
            "district": "8",
            "Block": "65",
            "label": "PUMS, EPB NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "648",
            "district": "8",
            "Block": "71",
            "label": "SRI VENKATESHWARA VIDHYALAYAA MATRICULATION SCHOOL, THASAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "653",
            "district": "8",
            "Block": "20",
            "label": "PUMS KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "655",
            "district": "8",
            "Block": "310",
            "label": "MARIA DEEPTHI MATRIC HIGH SCHOOL PANAKAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "657",
            "district": "8",
            "Block": "20",
            "label": "PUMS ANTHIYUR EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "658",
            "district": "8",
            "Block": "65",
            "label": "THE INDIAN PUBLIC SCHOOL,SENAPATHYPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "661",
            "district": "8",
            "Block": "45",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MURUNGATHOZHUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "666",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "670",
            "district": "8",
            "Block": "20",
            "label": "PUMS KUPPANDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "672",
            "district": "8",
            "Block": "244",
            "label": "KONGU VELLALAR KALVI NIRUVANAM MATRIC HIGHER SECONDARY SCHOOL,KANJI KOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "673",
            "district": "8",
            "Block": "37",
            "label": "PUMS VAIKKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "674",
            "district": "8",
            "Block": "10",
            "label": "PUMS MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "675",
            "district": "8",
            "Block": "185",
            "label": "PUMS,SAMINATHAPURAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "676",
            "district": "8",
            "Block": "71",
            "label": "MRS MATRICULATION SCHOOL, KOLAPPALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "680",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVVAIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "683",
            "district": "8",
            "Block": "37",
            "label": "PUMS SANKARAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "696",
            "district": "8",
            "Block": "128",
            "label": "SRI SANKARA VID MATRIC HR SEC SCHOOL SIVAGIRI ERODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "702",
            "district": "8",
            "Block": "37",
            "label": "PUMS KAVINDAPADIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "705",
            "district": "8",
            "Block": "128",
            "label": "PUMS,PERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "706",
            "district": "8",
            "Block": "281",
            "label": "SRI RAGAVENDARA MATRICULATION HIGHER SECONDARY SCHOOL,  SATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "707",
            "district": "8",
            "Block": "65",
            "label": "KALAIMAGAL KALVI NILAYAM GIRLS' MATRICULATION",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "711",
            "district": "8",
            "Block": "185",
            "label": "PUMS, METTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "713",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONAMOOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "718",
            "district": "8",
            "Block": "310",
            "label": "PUMS NEITHALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "720",
            "district": "8",
            "Block": "20",
            "label": "PUMS, L.SEMBULICHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "724",
            "district": "8",
            "Block": "65",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KOORAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "726",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "728",
            "district": "8",
            "Block": "45",
            "label": "SHRI GANGA MATRICULATION SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "732",
            "district": "8",
            "Block": "20",
            "label": "PUMS  ENNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "733",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUNGAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23374",
            "district": "8",
            "Block": "37",
            "label": "kamala vijaya vidhyalaya school",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "23398",
            "district": "8",
            "Block": "65",
            "label": "CSI Brough Matriculation school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "734",
            "district": "8",
            "Block": "45",
            "label": "PUMS, CHENNIYANGIRIVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "747",
            "district": "8",
            "Block": "20",
            "label": "PUMS THATTAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "759",
            "district": "8",
            "Block": "281",
            "label": "THE NEST MATRICULATION HIGHER SECONDARY SCHOOL, PUTHUVADAVALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "766",
            "district": "8",
            "Block": "65",
            "label": "ERODE HINDU KALVI NILAYAM MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "772",
            "district": "8",
            "Block": "71",
            "label": "AMALA MATRIC. HIGHER SEC. SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "774",
            "district": "8",
            "Block": "10",
            "label": "MAM EXEL MATRICULATION HIGHER SECONDARY SCHOOL,  GURUVAREDDIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "775",
            "district": "8",
            "Block": "281",
            "label": "BANNARIAMMAN VIDYA NIKETAN MATRIC, ALATHUKOMBAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "806",
            "district": "8",
            "Block": "65",
            "label": "PUMS, INDIRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "818",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "820",
            "district": "8",
            "Block": "10",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "826",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "828",
            "district": "8",
            "Block": "45",
            "label": "MOTTAIYAPPAR AMS, VALLIPURATHAMPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "837",
            "district": "8",
            "Block": "310",
            "label": "PUMS BALAPPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "838",
            "district": "8",
            "Block": "281",
            "label": "MUNICIPAL MIDDLE SSCHOOL, SATHYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "839",
            "district": "8",
            "Block": "65",
            "label": "AL AMEEN MATRICULATION HSS, CAUVERY ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "840",
            "district": "8",
            "Block": "37",
            "label": "SARASWATHI VIDHYASHRAM MATRIC HIGHER SECONDARY SCHOOL KAVUNDAPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "855",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PALAYAKALAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "858",
            "district": "8",
            "Block": "37",
            "label": "PUMS PERUMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "864",
            "district": "8",
            "Block": "65",
            "label": "SENGUNTHAR VIDHYALAYA MATRICULATION SCHOOL, ERODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "869",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SAIBANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "871",
            "district": "8",
            "Block": "244",
            "label": "PUMS, VEERANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "876",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, S.GANAPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "877",
            "district": "8",
            "Block": "202",
            "label": "KOMARASAMY GOUNDER MATRIC HSS, VETTAIYAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "883",
            "district": "8",
            "Block": "20",
            "label": "MANGALAM MATRICULATION SCHOOL, ANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "885",
            "district": "8",
            "Block": "185",
            "label": "PUMS,VETTUKATUVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "894",
            "district": "8",
            "Block": "37",
            "label": "PUMS JAMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "895",
            "district": "8",
            "Block": "10",
            "label": "PUMS ALANGARIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "896",
            "district": "8",
            "Block": "45",
            "label": "PUMS, PERUNDURAI R.S",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "899",
            "district": "8",
            "Block": "71",
            "label": "SRI VENKATESHWARA INTERNATIONALSCHOOL,OTHAKUTHIRAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "902",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SATHUMUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "904",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUMANATHAM PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "910",
            "district": "8",
            "Block": "10",
            "label": "PUMS  VEDIKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "922",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  PUDHUKUYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "924",
            "district": "8",
            "Block": "128",
            "label": "RAMALINGAM A.M.SCHOOL,KILAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "931",
            "district": "8",
            "Block": "185",
            "label": "PUMS, A.UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "935",
            "district": "8",
            "Block": "20",
            "label": "GTRMS KAKAYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "940",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONDAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "943",
            "district": "8",
            "Block": "20",
            "label": "PUMS OSAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "946",
            "district": "8",
            "Block": "37",
            "label": "PUMS, PERAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "950",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "953",
            "district": "8",
            "Block": "185",
            "label": "PUMS,PONNATHA VALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "955",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "959",
            "district": "8",
            "Block": "310",
            "label": "PUMS  METTALAVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "961",
            "district": "8",
            "Block": "10",
            "label": "PUMS CHITTHAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "962",
            "district": "8",
            "Block": "37",
            "label": "MMS VARNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "963",
            "district": "8",
            "Block": "71",
            "label": "KAMBAN KALVI NILAYAM MATRIC. HS SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "966",
            "district": "8",
            "Block": "37",
            "label": "PUMS BOMMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "967",
            "district": "8",
            "Block": "65",
            "label": "U.R.C. PALANIAMMAL MATRICULATION HSS, THINDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "972",
            "district": "8",
            "Block": "128",
            "label": "PUMS,MALAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "979",
            "district": "8",
            "Block": "20",
            "label": "PUMS SANTHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "980",
            "district": "8",
            "Block": "244",
            "label": "KONGU VELLALAR MATRIC HIGHER SECONDARY SCHOOL, PERUNDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "981",
            "district": "8",
            "Block": "185",
            "label": "PUMS CHILLANGATTUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "982",
            "district": "8",
            "Block": "20",
            "label": "MANGALAM CENTRAL SCHOOL, ANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "986",
            "district": "8",
            "Block": "65",
            "label": "VELALAR VIDYALAYAA SENIOR SECONDARY SCHOOL,THINDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "987",
            "district": "8",
            "Block": "37",
            "label": "MMS BHAVANI(EAST)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "993",
            "district": "8",
            "Block": "244",
            "label": "PUMS, PERIYAVEERASANGILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "998",
            "district": "8",
            "Block": "65",
            "label": "PUMS MANAKKATUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1004",
            "district": "8",
            "Block": "10",
            "label": "PUMS KUNDHUBAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1007",
            "district": "8",
            "Block": "202",
            "label": "KUMUTHA MATRIC HIGHER SEC SCHOOL, NAMBIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1014",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NANJAPPA GOUNEN PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1020",
            "district": "8",
            "Block": "37",
            "label": "PUMS SIRAIMEETANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1023",
            "district": "8",
            "Block": "71",
            "label": "MMS, (TOWN) GOBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1026",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1028",
            "district": "8",
            "Block": "20",
            "label": "PUMS A.SEMPULICHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1035",
            "district": "8",
            "Block": "185",
            "label": "PUMS,PUDHUVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1038",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KALIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1039",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUNDIPOMMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1041",
            "district": "8",
            "Block": "185",
            "label": "PUMS,SENAPATHYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1049",
            "district": "8",
            "Block": "20",
            "label": "PUMS KOOTHAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1059",
            "district": "8",
            "Block": "38",
            "label": "SRC MEMORIAL MATRIC. HR.SEC.SCHOOL,NALLUR(PO),PULIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1060",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,   KONDAPPANAIKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1063",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUTHUVALLIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1071",
            "district": "8",
            "Block": "37",
            "label": "SSV MHSS SANKARAGOUNDANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1072",
            "district": "8",
            "Block": "128",
            "label": "PUMS.MURUGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1085",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RAMAPAYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1087",
            "district": "8",
            "Block": "37",
            "label": "ADHARSH VIDHYALAYA MHSS PARUVACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1094",
            "district": "8",
            "Block": "128",
            "label": "THAMARAI MATRIC HSS THAMARAIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1118",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EKKATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1157",
            "district": "8",
            "Block": "65",
            "label": "C.S ACADEMY, KAVANDACHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1164",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALATHUKOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1168",
            "district": "8",
            "Block": "37",
            "label": "OPTIMUS PUBLIC SCHOOL SEVAGOUNDANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1171",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERUNDURAI SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1173",
            "district": "8",
            "Block": "10",
            "label": "PUMS SEMBADAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1176",
            "district": "8",
            "Block": "281",
            "label": "RANK INTERNATIONAL SCHOOL, ARIYAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1178",
            "district": "8",
            "Block": "71",
            "label": "C.K.K. MATRIC HS SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1180",
            "district": "8",
            "Block": "244",
            "label": "SHRI VIDYAA BHAVAN MATRICULATION SCHOOL,PULAVARPALAYAM, VIJAYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1186",
            "district": "8",
            "Block": "10",
            "label": "PUMS KOOTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1187",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  ARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1191",
            "district": "8",
            "Block": "20",
            "label": "PUMS SELLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1202",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  IRUTTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1203",
            "district": "8",
            "Block": "65",
            "label": "BHARATHI VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL, THINDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1204",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1217",
            "district": "8",
            "Block": "244",
            "label": "PUMS,LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1226",
            "district": "8",
            "Block": "37",
            "label": "PUMS MINNAVETTUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1228",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THOPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1237",
            "district": "8",
            "Block": "20",
            "label": "PUMS KARATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1238",
            "district": "8",
            "Block": "37",
            "label": "PUMS KADAIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1240",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KULLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1258",
            "district": "8",
            "Block": "37",
            "label": "PUMS SEVAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1259",
            "district": "8",
            "Block": "38",
            "label": "MAMAHARISHI EASWARAYA GURUKULAM MATRICULATION HR.SEC.SCHOOL, THABOVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1260",
            "district": "8",
            "Block": "20",
            "label": "PUMS SETHUNAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1263",
            "district": "8",
            "Block": "65",
            "label": "SRI SARASWATHI VIDHYALAYA Mat.HSS,MAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1269",
            "district": "8",
            "Block": "37",
            "label": "PUMS VAIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1271",
            "district": "8",
            "Block": "65",
            "label": "ASHRAM MATRICULATION HSS, KOLLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1272",
            "district": "8",
            "Block": "71",
            "label": "BHARATHI VIDHYALAYA  MATRIC HSS, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1284",
            "district": "8",
            "Block": "10",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1287",
            "district": "8",
            "Block": "244",
            "label": "PUMS, AYEEGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1289",
            "district": "8",
            "Block": "37",
            "label": "ST.MARY'S MHSS KURUPPANAIKKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1296",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOMBAITHOTTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1300",
            "district": "8",
            "Block": "65",
            "label": "CMS, SKC ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1301",
            "district": "8",
            "Block": "37",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEMBOOTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1302",
            "district": "8",
            "Block": "65",
            "label": "KONGU NATIONAL MATRICULATION HSS, NANJANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1303",
            "district": "8",
            "Block": "65",
            "label": "CARMEL MATRICULATION HSS, KOLLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1304",
            "district": "8",
            "Block": "128",
            "label": "PUMS.VALLIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1307",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PASUVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1310",
            "district": "8",
            "Block": "20",
            "label": "VISHWESVARAIYAH MHSS THOPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1315",
            "district": "8",
            "Block": "128",
            "label": "PUMS,NALLASELLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1316",
            "district": "8",
            "Block": "37",
            "label": "PUMS THIPPICHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1317",
            "district": "8",
            "Block": "71",
            "label": "PUMS, PERIYAKORAVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1319",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, L.SANARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1325",
            "district": "8",
            "Block": "37",
            "label": "TNK MHSS KAVINDAPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1326",
            "district": "8",
            "Block": "37",
            "label": "IDEAL MHS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1330",
            "district": "8",
            "Block": "71",
            "label": "SARATHA MATRIC HSS, MODACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1333",
            "district": "8",
            "Block": "20",
            "label": "PUMS ONTHANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1341",
            "district": "8",
            "Block": "37",
            "label": "PUMS CHINNAPERICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1347",
            "district": "8",
            "Block": "65",
            "label": "PUMS SENGUNTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1348",
            "district": "8",
            "Block": "65",
            "label": "NANDHA CENTRAL SCHOOL, KOORAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1352",
            "district": "8",
            "Block": "65",
            "label": "PUMS, SUKKIRAMANIAVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1357",
            "district": "8",
            "Block": "20",
            "label": "GTRMS SOLAKANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1367",
            "district": "8",
            "Block": "65",
            "label": "S.V.N. MATRICULATION HSS, CHITTODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1372",
            "district": "8",
            "Block": "37",
            "label": "PUMS  ORICHERIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1373",
            "district": "8",
            "Block": "65",
            "label": "GEETHAANJALI ALL INDIA SR.SEC. SCHOOL, THINDAL,ERODE-638012",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1379",
            "district": "8",
            "Block": "65",
            "label": "PUMS,JAVULI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1382",
            "district": "8",
            "Block": "71",
            "label": "JAYARAM MUNCIPAL MIDDLE SCHOOL, GOBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1386",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1389",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANAIKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1391",
            "district": "8",
            "Block": "10",
            "label": "ST. THERESA'S MATRICULATION SCHOOL, OLAGADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1392",
            "district": "8",
            "Block": "65",
            "label": "RAILWAY SENIOR SECONDARY SCHOOL(CBSE), RAILWAY COLONY,ERODE",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1399",
            "district": "8",
            "Block": "128",
            "label": "PUMS, VALLANTHANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1400",
            "district": "8",
            "Block": "65",
            "label": "THE B.V.B.SCHOOL, THERKUPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1401",
            "district": "8",
            "Block": "20",
            "label": "PUMS DHOLLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1408",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADAGANALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1421",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PILIAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1426",
            "district": "8",
            "Block": "45",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MELAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1428",
            "district": "8",
            "Block": "37",
            "label": "MMS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1434",
            "district": "8",
            "Block": "65",
            "label": "PUMS,PERUMALMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1435",
            "district": "8",
            "Block": "65",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ODAKKATTUVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1436",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GANDHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1441",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLAKOILPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1456",
            "district": "8",
            "Block": "310",
            "label": "PUMS MALLANGULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1459",
            "district": "8",
            "Block": "65",
            "label": "PUMS, ASHOKAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1468",
            "district": "8",
            "Block": "45",
            "label": "PUMS,KAVUNDACHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1478",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MADHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1491",
            "district": "8",
            "Block": "38",
            "label": "AMMA MATRICULATION HIGHER SECONDARY SCHOOL, PUNJAI PULIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1507",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, JJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1512",
            "district": "8",
            "Block": "37",
            "label": "PUMS PALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1526",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MALLIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1539",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERUNDURAI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1554",
            "district": "8",
            "Block": "37",
            "label": "PUMS SUKKANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1555",
            "district": "8",
            "Block": "20",
            "label": "VIVEKANANDHA VIDHYALAYA MHS ANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1556",
            "district": "8",
            "Block": "20",
            "label": "PUMS THOTTAKUDIAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1566",
            "district": "8",
            "Block": "38",
            "label": "HOLY REDEEMER'S MATRIC SCHOOL, BHAVANISAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1570",
            "district": "8",
            "Block": "202",
            "label": "SHANGUINE MATRIC HIGHER SECONDARY SCHOOL, KURUMANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1571",
            "district": "8",
            "Block": "128",
            "label": "PUMS,PERIYAVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1576",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SINGIRIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1581",
            "district": "8",
            "Block": "65",
            "label": "A.E.T.MATRICULATION HSS, KATHIRAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1602",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, IRUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1609",
            "district": "8",
            "Block": "185",
            "label": "PUMS,KAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1611",
            "district": "8",
            "Block": "310",
            "label": "PUMS DODDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1616",
            "district": "8",
            "Block": "281",
            "label": "SARU MATRIC HIGHER SECONDARY SCHOOL, KOTTUVERAMPALAYAM,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1621",
            "district": "8",
            "Block": "65",
            "label": "GRACE MATRIC HSS, GRACE NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1626",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUTHIRAIKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1629",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1635",
            "district": "8",
            "Block": "244",
            "label": "PUMS,CINNAMALLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1640",
            "district": "8",
            "Block": "10",
            "label": "LOYOLA MATRICULATION SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1664",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GANAPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1678",
            "district": "8",
            "Block": "65",
            "label": "PUMS,SSP NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1682",
            "district": "8",
            "Block": "244",
            "label": "PUMS,KARUMANDISELLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1730",
            "district": "8",
            "Block": "20",
            "label": "PUMS PERIYASENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1747",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, E.CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1749",
            "district": "8",
            "Block": "202",
            "label": "PUMS, ELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1756",
            "district": "8",
            "Block": "37",
            "label": "PUMS ERUSANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1771",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANAKKARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1773",
            "district": "8",
            "Block": "65",
            "label": "CMS, CAUVERY ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1774",
            "district": "8",
            "Block": "65",
            "label": "PUMS, ELLAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1777",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  PERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1780",
            "district": "8",
            "Block": "45",
            "label": "KONGU VELLALAR MATRIC HSS,MUTHAIYAN KOVIL,CHENNIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1787",
            "district": "8",
            "Block": "20",
            "label": "PUMS OOSIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1794",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERUNDURAI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1796",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THALAIKKOMBUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1797",
            "district": "8",
            "Block": "65",
            "label": "PUMS, VAIRAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1799",
            "district": "8",
            "Block": "37",
            "label": "ADHARSH VIDHYALAYA PUBLIC SCHOOL, PARUVATCHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1808",
            "district": "8",
            "Block": "244",
            "label": "ROOTS MATRIC HIGHER  SECONDARY SCHOOL, MOONGILPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1820",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BAGUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1824",
            "district": "8",
            "Block": "20",
            "label": "PUMS THALAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1825",
            "district": "8",
            "Block": "10",
            "label": "PUMS THANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1827",
            "district": "8",
            "Block": "244",
            "label": "PUMS,SELLAPPAGOUNDANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1831",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEHRUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1852",
            "district": "8",
            "Block": "10",
            "label": "PUMS REDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1860",
            "district": "8",
            "Block": "37",
            "label": "PUMS  APPAKUDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1876",
            "district": "8",
            "Block": "244",
            "label": "KALAIVANI KALVI NILAYAM THINGALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1878",
            "district": "8",
            "Block": "45",
            "label": "VIMALA MATRI. HR SEC.SCHOOL, THOPPUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1886",
            "district": "8",
            "Block": "310",
            "label": "PUMS AREPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1889",
            "district": "8",
            "Block": "71",
            "label": "DIAMOND JUBILEE MATRIC. HS, GOBI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1891",
            "district": "8",
            "Block": "37",
            "label": "SRI VIDHYA MANDIR MS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1896",
            "district": "8",
            "Block": "10",
            "label": "PUMS KONARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1898",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KATTUVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1900",
            "district": "8",
            "Block": "45",
            "label": "PUMS,CHENNIMALAI TOWN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1904",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERIYAVETUVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1908",
            "district": "8",
            "Block": "10",
            "label": "PUMS  JARTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1917",
            "district": "8",
            "Block": "310",
            "label": "PUMS KADUBASUVANMALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1918",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1919",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OTTARKARATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1921",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SITHANKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1927",
            "district": "8",
            "Block": "244",
            "label": "PUMS,TANNERPANDALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1928",
            "district": "8",
            "Block": "45",
            "label": "PUMS, THOPPUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1931",
            "district": "8",
            "Block": "10",
            "label": "TALENT MATRICULATION SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1940",
            "district": "8",
            "Block": "65",
            "label": "GRACE INTERNATIONAL SCHOOL, ELAVAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1941",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UKKARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1942",
            "district": "8",
            "Block": "65",
            "label": "KONGU KALVINILAYAM MATRICULATION HSS, RANGAMPALAY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1945",
            "district": "8",
            "Block": "244",
            "label": "KARUNYA VIDHYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL,PERUNDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1960",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1973",
            "district": "8",
            "Block": "310",
            "label": "PUMS CHIKKANANDHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1976",
            "district": "8",
            "Block": "281",
            "label": "BANNARIAMMAN PUBLIC SCHOOL(CBSE),SATHYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1978",
            "district": "8",
            "Block": "128",
            "label": "PUMS,RATHINAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1980",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  N.VELLALAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1982",
            "district": "8",
            "Block": "65",
            "label": "PUMS, KONGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1988",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARAYEPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1989",
            "district": "8",
            "Block": "20",
            "label": "PUMS BEJILPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1994",
            "district": "8",
            "Block": "20",
            "label": "PUMS DHURUSANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2001",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERUNDURAI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2005",
            "district": "8",
            "Block": "45",
            "label": "YOUNG INDIA MATRIC HSS,VEPPILI PIRIVU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2009",
            "district": "8",
            "Block": "244",
            "label": "PUMS,CINNIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2012",
            "district": "8",
            "Block": "45",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MYILADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2021",
            "district": "8",
            "Block": "45",
            "label": "PUMS,KUMARAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2026",
            "district": "8",
            "Block": "71",
            "label": "SHREE VIDYALAYA MATRIC HS SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2031",
            "district": "8",
            "Block": "65",
            "label": "CHRISTHU JYOTHI MATRICULATION HSS, B.P. AGRAHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2043",
            "district": "8",
            "Block": "185",
            "label": "PUMS,RASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2052",
            "district": "8",
            "Block": "310",
            "label": "PUMS KOTTADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2065",
            "district": "8",
            "Block": "45",
            "label": "VIVEKANANTHA MHSS, V.VELLODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2068",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARATTUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2071",
            "district": "8",
            "Block": "65",
            "label": "THONDAR SEER PARAVUVAR THANGAVELANAAR ILAIGAR KALVI NILAYAM MIDDLE SCHOOL, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2077",
            "district": "8",
            "Block": "20",
            "label": "LITTLE FLOWER MHS KARATTUR MEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2096",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EMMAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2097",
            "district": "8",
            "Block": "310",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BHARATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2099",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2104",
            "district": "8",
            "Block": "10",
            "label": "PUMS NATHAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2107",
            "district": "8",
            "Block": "20",
            "label": "PUMS SUNDAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2112",
            "district": "8",
            "Block": "310",
            "label": "PUMS KALBANDIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2113",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2116",
            "district": "8",
            "Block": "244",
            "label": "PUMS NEELA GOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2128",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BOMMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2138",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SAVANDAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2140",
            "district": "8",
            "Block": "20",
            "label": "PUMS THAMMUREDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2145",
            "district": "8",
            "Block": "244",
            "label": "SAGAR INTERNATIONAL SENIOR SECONDARY SCHOOL,SOLIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2146",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2149",
            "district": "8",
            "Block": "65",
            "label": "PUMS, THANNEER PANDHAL PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2152",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAVANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2154",
            "district": "8",
            "Block": "65",
            "label": "CMS, THIRUNAGAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2179",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERIYAKALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2191",
            "district": "8",
            "Block": "244",
            "label": "PUMS, VETTAIYANKINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2208",
            "district": "8",
            "Block": "65",
            "label": "INDU INTERNATIONAL SCHOOL, VANNANKATTU VALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2232",
            "district": "8",
            "Block": "20",
            "label": "PUMS PUDUMETTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2241",
            "district": "8",
            "Block": "20",
            "label": "PUMS KEELVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2255",
            "district": "8",
            "Block": "20",
            "label": "PUMS EPPATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2264",
            "district": "8",
            "Block": "65",
            "label": "KONGU VIDHYALAYA MATRICULATION SCHOOL, MANICKAMPALAYAM, ERODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2285",
            "district": "8",
            "Block": "65",
            "label": "AL AMEEN METRICULATION HSS, VILLARASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2306",
            "district": "8",
            "Block": "10",
            "label": "PUMS MASAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2329",
            "district": "8",
            "Block": "65",
            "label": "NIRMALA MATHA CONVENT MATRICULATION HSS, MOOLAKA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2330",
            "district": "8",
            "Block": "45",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EKKATTAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2340",
            "district": "8",
            "Block": "281",
            "label": "LITTLE FLOWER MATRIC HIGHER SECONDARY SCHOOL, SATHYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2358",
            "district": "8",
            "Block": "10",
            "label": "PUMS KALPAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2363",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2367",
            "district": "8",
            "Block": "244",
            "label": "ST.JOSEPH'S R.C. MIDDLE SCHOOL,KALLIYAMPUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2372",
            "district": "8",
            "Block": "65",
            "label": "AMS,LALITHA KALVI NILAYAM, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2376",
            "district": "8",
            "Block": "10",
            "label": "PUMS CHINNAPARUVACHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2388",
            "district": "8",
            "Block": "65",
            "label": "SRI SATHYA SAI MATRICULATION HIGHER SECONDARY SCHOOL, CHITTODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2427",
            "district": "8",
            "Block": "20",
            "label": "PUMS BEJILATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2432",
            "district": "8",
            "Block": "65",
            "label": "AMS, RAGUPATHINAIKEN PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2451",
            "district": "8",
            "Block": "45",
            "label": "PUMS,SIRUKKALANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2478",
            "district": "8",
            "Block": "185",
            "label": "NAVARASAM MATRI HSS,PALLIYUTHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2499",
            "district": "8",
            "Block": "65",
            "label": "PUMS MOLAGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2505",
            "district": "8",
            "Block": "281",
            "label": "GTRMS, NAGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2520",
            "district": "8",
            "Block": "244",
            "label": "PUMS, PAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2526",
            "district": "8",
            "Block": "244",
            "label": "PUMS, KAVUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2537",
            "district": "8",
            "Block": "10",
            "label": "R.C.MS CHINNAPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2573",
            "district": "8",
            "Block": "45",
            "label": "SRI RAJALAKSHMI MATRICULATION HIGHER SECONDARY SCHOOL, KARATTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2665",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VINOBANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2745",
            "district": "8",
            "Block": "244",
            "label": "BHARATHI MATRIC HIGHER SECONDARY SCHOOL,SHANMUGAPURAM, VIJAYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2871",
            "district": "8",
            "Block": "185",
            "label": "ISHA VIDHYA MATRICULATION HIGHER SECONDARY SCHOOL,PERUMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2897",
            "district": "8",
            "Block": "65",
            "label": "SRI MARUTHI VIDHYA BHAVAN MATRICULATION HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3017",
            "district": "8",
            "Block": "281",
            "label": "SOUNDRAM VIDYALAYA MATRICULATION SCHOOL, RAJAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3030",
            "district": "8",
            "Block": "10",
            "label": "T.C.M. MATRICULATION SCHOOL, SANDAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3104",
            "district": "8",
            "Block": "128",
            "label": "PUMS,SIVAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3172",
            "district": "8",
            "Block": "20",
            "label": "PUMS  ERATTIMINTHANGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3218",
            "district": "8",
            "Block": "185",
            "label": "JAYCEES MATRI HSS,POONDURAI ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3224",
            "district": "8",
            "Block": "65",
            "label": "MOTHERS MATRIC HIGHER SECONDARY SCHOOL., KALINGARAYANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3238",
            "district": "8",
            "Block": "185",
            "label": "RAJENDRAN MATRIC HSS,VADUGAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3563",
            "district": "8",
            "Block": "244",
            "label": "APEX SCHOOL FOR HEARING IMPAIRED, SLATERPURAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "3631",
            "district": "8",
            "Block": "128",
            "label": "PUMS,VADAKKUPUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4129",
            "district": "8",
            "Block": "20",
            "label": "PUMS , MADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4209",
            "district": "8",
            "Block": "71",
            "label": "SRI NATIONAL SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4267",
            "district": "8",
            "Block": "10",
            "label": "PUMS MURALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4324",
            "district": "8",
            "Block": "244",
            "label": "VVIS SENIOR SECONDARY SCHOOL,KAMBULIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4331",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUNGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4348",
            "district": "8",
            "Block": "185",
            "label": "AGASTYA ACADEMY CBSE VEERAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4368",
            "district": "8",
            "Block": "281",
            "label": "ST. JAMES MATRIC HS SCHOOL, SATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4380",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL BUNGALOWPUDUR-PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4381",
            "district": "8",
            "Block": "281",
            "label": "ST. JOSEPH'S MATRICULATION HIGH SCHOOL, SATHYAMANGALAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4396",
            "district": "8",
            "Block": "10",
            "label": "PUMS MARAVANKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4422",
            "district": "8",
            "Block": "185",
            "label": "LIONS M.HR.S.SCHOOL, PALANIGOUNDENVALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4533",
            "district": "8",
            "Block": "244",
            "label": "SHREE SWAMI VIVEKANANDHA MATRIC HIGHER SECONDARY SCHOOL, K.S.PALAYAM, PERUNDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23687",
            "district": "8",
            "Block": "244",
            "label": "Appichimar vidhya mandir school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4611",
            "district": "8",
            "Block": "310",
            "label": "JSS MATRICULATION SCHOOL THALAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4635",
            "district": "8",
            "Block": "202",
            "label": "GWMS,E.RAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4671",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAVALAKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4760",
            "district": "8",
            "Block": "356",
            "label": "MONTFORT SCHOOL, D.G.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4808",
            "district": "8",
            "Block": "281",
            "label": "SRT UNIVERSAL MATRIC HIGHER SECONDARY SCHOOL, KONAMOOLAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4911",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIKIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4954",
            "district": "8",
            "Block": "128",
            "label": "SHREE VALLI MARIC  HSS,ICHIPPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4979",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NALLAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5031",
            "district": "8",
            "Block": "185",
            "label": "RD INTERNATIONAL SCHOOL, GATUPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5206",
            "district": "8",
            "Block": "71",
            "label": "SRI D.S. SARADHA VIDHYALAYA MIDDLE SCHOOL, GOBI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5209",
            "district": "8",
            "Block": "202",
            "label": "AALAYAM MATRICULATION SCHOOL, NAMBIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5560",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VANIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5594",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERIYASALATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5825",
            "district": "8",
            "Block": "310",
            "label": "PUMS HASANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6035",
            "district": "8",
            "Block": "65",
            "label": "SRI KRP ACADEMY, CHITTODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6053",
            "district": "8",
            "Block": "185",
            "label": "P.K.P.SWAMY MATRIC HSS,KALYANIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6276",
            "district": "8",
            "Block": "202",
            "label": "P.K. MATRICULATION SCHOOL, SELLIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12012",
            "district": "8",
            "Block": "10",
            "label": "MODEL HIGHER SECONDARY SCHOOL, MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12013",
            "district": "8",
            "Block": "10",
            "label": "GHS UMAREDDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12014",
            "district": "8",
            "Block": "10",
            "label": "GHSS GURUVAREDDIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12015",
            "district": "8",
            "Block": "10",
            "label": "PUMS PALAKUTTAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12016",
            "district": "8",
            "Block": "10",
            "label": "GHS KURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12017",
            "district": "8",
            "Block": "10",
            "label": "ANMGHS MUHASIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12018",
            "district": "8",
            "Block": "10",
            "label": "GHSS NERINJIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12019",
            "district": "8",
            "Block": "10",
            "label": "PUMS PATLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12020",
            "district": "8",
            "Block": "10",
            "label": "S.E.T. HIGHER SECONDARY SCHOOL , POONACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12021",
            "district": "8",
            "Block": "10",
            "label": "ST.IGNATIUS HSS BUDAPADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12022",
            "district": "8",
            "Block": "10",
            "label": "KASTHURBA GANDHI BALIKA VIDYALAYA GIRLS RESIDENTIAL SCHOOL,  KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12023",
            "district": "8",
            "Block": "10",
            "label": "PUMS ANANTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12024",
            "district": "8",
            "Block": "10",
            "label": "GHSS SINGAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12025",
            "district": "8",
            "Block": "10",
            "label": "GHS KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12026",
            "district": "8",
            "Block": "10",
            "label": "GHSS VELLITHIRUPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12027",
            "district": "8",
            "Block": "10",
            "label": "GHSS OLAGADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12028",
            "district": "8",
            "Block": "10",
            "label": "GHS KUPPICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12029",
            "district": "8",
            "Block": "10",
            "label": "GHSS CHENNAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12030",
            "district": "8",
            "Block": "10",
            "label": "GHSS ALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12031",
            "district": "8",
            "Block": "20",
            "label": "GBHSS ANTHIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12032",
            "district": "8",
            "Block": "20",
            "label": "GGHSS ANTHIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12033",
            "district": "8",
            "Block": "20",
            "label": "KASTURIBA GANDHI BALIKA VIDYALAYA,  PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12034",
            "district": "8",
            "Block": "20",
            "label": "GHS THAVITTUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12035",
            "district": "8",
            "Block": "20",
            "label": "GHSS ATHANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12036",
            "district": "8",
            "Block": "20",
            "label": "GTRMS KUTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12037",
            "district": "8",
            "Block": "20",
            "label": "GTRMS KATHIRIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12038",
            "district": "8",
            "Block": "20",
            "label": "PUMS KADAIERATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12039",
            "district": "8",
            "Block": "20",
            "label": "PUMS  SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12040",
            "district": "8",
            "Block": "20",
            "label": "GTRHSS  BARGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12041",
            "district": "8",
            "Block": "20",
            "label": "GHSS OOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12042",
            "district": "8",
            "Block": "20",
            "label": "GHSS  DEVARMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12043",
            "district": "8",
            "Block": "20",
            "label": "GHSS A.SEMBULICHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12044",
            "district": "8",
            "Block": "20",
            "label": "PUMS KATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12045",
            "district": "8",
            "Block": "20",
            "label": "PUMS A.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12046",
            "district": "8",
            "Block": "20",
            "label": "GTRMS KINATHADISOZHAGA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12047",
            "district": "8",
            "Block": "20",
            "label": "ST. MICHAEL'S HSS, MICHAELPALAYAM.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12048",
            "district": "8",
            "Block": "20",
            "label": "GHS PUDHUKADU GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12049",
            "district": "8",
            "Block": "20",
            "label": "GHSS MOONGILPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12050",
            "district": "8",
            "Block": "20",
            "label": "ST.SEBASTIAN'S HIGH SCHOOL NAGALUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12051",
            "district": "8",
            "Block": "20",
            "label": "MANGALAM HSS ANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12052",
            "district": "8",
            "Block": "20",
            "label": "GHS PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12053",
            "district": "8",
            "Block": "20",
            "label": "PUMS SANKARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12054",
            "district": "8",
            "Block": "20",
            "label": "SAKTHI HSS NACHIMUTHUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12055",
            "district": "8",
            "Block": "37",
            "label": "GHS SALANGAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12056",
            "district": "8",
            "Block": "37",
            "label": "GHS ALATHUR THATTARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12057",
            "district": "8",
            "Block": "37",
            "label": "IDEAL HSS PARUVACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12058",
            "district": "8",
            "Block": "37",
            "label": "ADHARSH VIDHYALAYA HSS PARUVACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12059",
            "district": "8",
            "Block": "37",
            "label": "NEW IDEAL HSS EERATTAIKARADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12060",
            "district": "8",
            "Block": "37",
            "label": "GHS PUNNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12061",
            "district": "8",
            "Block": "37",
            "label": "SVVB MATRIC HIGHER SECONDARY SCHOOL, SAKTHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12062",
            "district": "8",
            "Block": "37",
            "label": "GHSS, A.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12063",
            "district": "8",
            "Block": "37",
            "label": "GHSS MYLAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12064",
            "district": "8",
            "Block": "37",
            "label": "GHS URATCHIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12065",
            "district": "8",
            "Block": "37",
            "label": "GHS KUTTIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12066",
            "district": "8",
            "Block": "37",
            "label": "GOVERNMENT VINOBHA HIGHER SECONDARY SCHOOL, THALAVAIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12067",
            "district": "8",
            "Block": "37",
            "label": "SMGHSS  ODATHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12068",
            "district": "8",
            "Block": "37",
            "label": "GHSS PERIYAPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12069",
            "district": "8",
            "Block": "37",
            "label": "GGHSS  KAVINDAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12070",
            "district": "8",
            "Block": "37",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  KAVINDAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12071",
            "district": "8",
            "Block": "37",
            "label": "GHSS, AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12072",
            "district": "8",
            "Block": "37",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, BHAVANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12073",
            "district": "8",
            "Block": "37",
            "label": "GGHSS BHAVANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12074",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ERANGATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12075",
            "district": "8",
            "Block": "38",
            "label": "RC HIGH SCHOOL, VALIPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12076",
            "district": "8",
            "Block": "38",
            "label": "GOVT.HIGHER SEC.SCHOOL,KAVILIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12077",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12078",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGH SCHOOL, KARAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12079",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHURASANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12080",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THAIRPALLAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12081",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VENGANAICKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12082",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGHER SEC.SCHOOL, BHAVANISAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12083",
            "district": "8",
            "Block": "38",
            "label": "GOVT.HIGH SCHOOL, NALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12084",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGHER SEC. SCHOOL, PANAYAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12085",
            "district": "8",
            "Block": "38",
            "label": "KVK GOVT. BOYS HS SCHOOL, P.PULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23422",
            "district": "8",
            "Block": "244",
            "label": "Brainstorm High school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "12086",
            "district": "8",
            "Block": "38",
            "label": "KOM GOVT. GIRLS HS SCHOOL, P.PULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12087",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGH SCHOOL, UTHANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12088",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,GODEPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23403",
            "district": "8",
            "Block": "65",
            "label": "Yuvabharathi global school( CBSE)",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "12089",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGHER SEC. SCHOOL, DODDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12090",
            "district": "8",
            "Block": "45",
            "label": "KOMARAPPA SENGUNTHAR GIRLS HS, CHENNIMALAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12091",
            "district": "8",
            "Block": "45",
            "label": "GOVERNMENT HIGH SCHOOL,AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12092",
            "district": "8",
            "Block": "45",
            "label": "GOVERNMENT HIGH SCHOOL KAMARAJNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12093",
            "district": "8",
            "Block": "45",
            "label": "GHS, AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12094",
            "district": "8",
            "Block": "45",
            "label": "GHSS,VELLODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12095",
            "district": "8",
            "Block": "45",
            "label": "KOMARAPPA SENGUNTHAR HSS, M.PIDARIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12096",
            "district": "8",
            "Block": "45",
            "label": "GHS,MUGASIPIDARIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12097",
            "district": "8",
            "Block": "45",
            "label": "GHSS, ENGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12098",
            "district": "8",
            "Block": "45",
            "label": "SHRI MAHAA HIGHER SECONDARY SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12099",
            "district": "8",
            "Block": "45",
            "label": "SHRI GANGA HIGHER SEC SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12100",
            "district": "8",
            "Block": "45",
            "label": "THE UNIQUE ACADEMY SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12101",
            "district": "8",
            "Block": "45",
            "label": "ST.XAVIER'S HIGH SCHOOL, VADUGAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12102",
            "district": "8",
            "Block": "45",
            "label": "GHSS, PASUVAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12103",
            "district": "8",
            "Block": "45",
            "label": "GHSS, THIPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12138",
            "district": "8",
            "Block": "65",
            "label": "GHSS, CHITTODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12139",
            "district": "8",
            "Block": "65",
            "label": "GGHSS, CHITHODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12140",
            "district": "8",
            "Block": "65",
            "label": "SEVENTHDAY ADVENTIST MATRICULATION SCHOOL,CHITHODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12141",
            "district": "8",
            "Block": "65",
            "label": "SAKTHI HS, CHITTODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12142",
            "district": "8",
            "Block": "65",
            "label": "GRACE HIGHER SECONDARY SCHOOL, ELAVAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12143",
            "district": "8",
            "Block": "65",
            "label": "GHSS B..P. AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12144",
            "district": "8",
            "Block": "65",
            "label": "MATHARASA HITHAYATHUL ISLAM GIRLS HIGH SCHOOL, B.P. AGRAHARAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12145",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VEERAPPANCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12146",
            "district": "8",
            "Block": "65",
            "label": "GHS, CHINNASEMUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12147",
            "district": "8",
            "Block": "65",
            "label": "GHS, SENGODAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12148",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT HIGH SCHOOL FOR HEARING IMPAIRED, R.N. PUDUR,ERODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12149",
            "district": "8",
            "Block": "65",
            "label": "GHSS, RAMANATHAPURAM PUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12150",
            "district": "8",
            "Block": "65",
            "label": "AHSS, BHARATHI KALVI NILAYAM, NASIYANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12151",
            "district": "8",
            "Block": "65",
            "label": "VELLALAR GIRLS HIGH SCHOOL , THINDAL-638012",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12152",
            "district": "8",
            "Block": "65",
            "label": "THE ERODE ARIMA SOCIETY TRUST SPECIAL SCHOOL, SENGODAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "12153",
            "district": "8",
            "Block": "65",
            "label": "KONGU ARIVALAYAM,  MR-CHILDREN SCHOOL, SENGODAMPAL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "12154",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THINDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12155",
            "district": "8",
            "Block": "65",
            "label": "SREE AMMAN MATRICULATION HSS,EVK SAMPATH NAGAR, ERODE-11",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12156",
            "district": "8",
            "Block": "65",
            "label": "GHSS, NANJAPPA GOUNDAN VALASU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12157",
            "district": "8",
            "Block": "65",
            "label": "KALAIMAGAL KALVI NILAYAM GIRLS HSS, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12158",
            "district": "8",
            "Block": "65",
            "label": "CHS, E.K.VALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12159",
            "district": "8",
            "Block": "65",
            "label": "AHS, ST MARY'S, ERODE",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12160",
            "district": "8",
            "Block": "65",
            "label": "AHS, ST.RITA GIRLS, RAILWAY COLONY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12161",
            "district": "8",
            "Block": "65",
            "label": "MEENAKSHI SUNDARANAR SENGUNTHAR HR SEC SCHOOL,RANGAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12162",
            "district": "8",
            "Block": "65",
            "label": "DEIVANAI PERUMAL HS, MOLAGOUNDANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12163",
            "district": "8",
            "Block": "65",
            "label": "GHSS, KASIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12164",
            "district": "8",
            "Block": "65",
            "label": "SRI SAKTHI VIDHYA NIKETHAN MATRIC HSS,RANGAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12165",
            "district": "8",
            "Block": "65",
            "label": "GHSS, KUMALANKUTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12166",
            "district": "8",
            "Block": "65",
            "label": "NANDHI MATRICULATION HSS, CAUVERY STREET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12167",
            "district": "8",
            "Block": "65",
            "label": "V.V.C.R.MURUGESANAR SENGUNTHAR GIRLS HR.SEC.SCHOOL,ERODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12168",
            "district": "8",
            "Block": "65",
            "label": "SIDDHARTHA MATRICULATION HSS, THIRUNAGAR COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12169",
            "district": "8",
            "Block": "65",
            "label": "MPL (GIRLS) KARUNGALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12170",
            "district": "8",
            "Block": "65",
            "label": "MRG GGHSS, VEERAPPANCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12171",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, ERODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12172",
            "district": "8",
            "Block": "65",
            "label": "AHSS, C.S.I. GIRLS, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12173",
            "district": "8",
            "Block": "65",
            "label": "AHSS, C.S.I. BOYS, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12174",
            "district": "8",
            "Block": "65",
            "label": "AHSS, ATM, VAIRAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12175",
            "district": "8",
            "Block": "65",
            "label": "CHSS, RAILWAY COLONY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12176",
            "district": "8",
            "Block": "65",
            "label": "CHSS, KAMARAJ MUNICIPAL BOYS, KARUNGALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12177",
            "district": "8",
            "Block": "65",
            "label": "E.K.M. ABDUL GANI MATHARASA ISLAMIA HIGH SCHOOL,ERODE",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12178",
            "district": "8",
            "Block": "65",
            "label": "MAHAJANA HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12179",
            "district": "8",
            "Block": "65",
            "label": "THE SENGUNTHAR HIGHER SECONDARY SCHOOL, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12180",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, P.METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12181",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL, K.METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12182",
            "district": "8",
            "Block": "71",
            "label": "GANDHI KALVI NILAYAM HIGHER SECONDARY SCHOOL,KUGALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12183",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, POLAVAKKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12184",
            "district": "8",
            "Block": "71",
            "label": "SRI VENKATESHWARA VIDHAYALAYAA HIGHER SEC. SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12185",
            "district": "8",
            "Block": "71",
            "label": "MUNICIPAL GIRLS HIGHER SECONDARY SCHOOL, GOBI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12186",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL,  KULLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12187",
            "district": "8",
            "Block": "71",
            "label": "MUNICIPAL HIGHER SEC.SCHOOL, MODACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12188",
            "district": "8",
            "Block": "71",
            "label": "SARATHA HIGHER SECONDARY SCHOOL, MODACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12189",
            "district": "8",
            "Block": "71",
            "label": "PALANIAMMAL GIRLS HS SCHOOL, GOBI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12190",
            "district": "8",
            "Block": "71",
            "label": "GHSS, KARATTADIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12191",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL, KALINGIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12192",
            "district": "8",
            "Block": "71",
            "label": "SHREE GURUKULAM HIGHER SEC. SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12193",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL, ALUKKULI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12194",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL, MALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12195",
            "district": "8",
            "Block": "71",
            "label": "C.S.I. MIDDLE SCHOOL, SIRUVALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12196",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, VELLANKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12197",
            "district": "8",
            "Block": "71",
            "label": "AMALA HIGH SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12198",
            "district": "8",
            "Block": "71",
            "label": "DIAMOND JUBILEE HIGHER SECONDARY SCHOOL, GOBI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12199",
            "district": "8",
            "Block": "71",
            "label": "MUNICIPAL BOYS HR. SEC. SCHOOL, GOBI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12200",
            "district": "8",
            "Block": "71",
            "label": "VENGAMMAIYAR MH SCHOOL, GOBI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12201",
            "district": "8",
            "Block": "71",
            "label": "ST PAULS MATRIC HIGHER SECONDARY SCHOOL,  PATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12202",
            "district": "8",
            "Block": "71",
            "label": "ST. MARY'S HIGH SCHOOL, KOLAPPALUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12203",
            "district": "8",
            "Block": "71",
            "label": "THAI TAMIL HIGH SCHOOL, KOLAPPALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12216",
            "district": "8",
            "Block": "128",
            "label": "GHS,KARAVALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12217",
            "district": "8",
            "Block": "128",
            "label": "GHS,NAGAPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12218",
            "district": "8",
            "Block": "128",
            "label": "GHSS,SALAIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12219",
            "district": "8",
            "Block": "128",
            "label": "GHSS,THAMARAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12220",
            "district": "8",
            "Block": "128",
            "label": "GHSS,PASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12221",
            "district": "8",
            "Block": "128",
            "label": "GHSS,THANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12222",
            "district": "8",
            "Block": "128",
            "label": "SSV AIDED GIRLS HIGH SCHOOL,KODUMUDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12223",
            "district": "8",
            "Block": "128",
            "label": "SSV AIDED HIGHER SECONDARY SCHOOL,KODUMUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12224",
            "district": "8",
            "Block": "128",
            "label": "GGHSS,SIVAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12225",
            "district": "8",
            "Block": "128",
            "label": "GBHSS,SIVAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12226",
            "district": "8",
            "Block": "128",
            "label": "GHSS,VELLOTTAMPARAPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12227",
            "district": "8",
            "Block": "128",
            "label": "GHSS,UNJALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12238",
            "district": "8",
            "Block": "185",
            "label": "GBHSS, MODAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12239",
            "district": "8",
            "Block": "185",
            "label": "GGHSS, MODAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12240",
            "district": "8",
            "Block": "185",
            "label": "SWAMY MATRIC HSS,MODAKKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12241",
            "district": "8",
            "Block": "185",
            "label": "SHRI VETHATHIRI VIDHYALAYA Met. SCHOOL,SAMYNATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12242",
            "district": "8",
            "Block": "185",
            "label": "GHSS,ELUMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12243",
            "district": "8",
            "Block": "185",
            "label": "PUMS,ANANTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12244",
            "district": "8",
            "Block": "185",
            "label": "GV ACADEMY,ELUMATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12245",
            "district": "8",
            "Block": "185",
            "label": "PUMS,KARUNDEVANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12246",
            "district": "8",
            "Block": "185",
            "label": "PUMS,KALATHUMINNAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12247",
            "district": "8",
            "Block": "185",
            "label": "GHSS,GANAPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12248",
            "district": "8",
            "Block": "185",
            "label": "PUMS, NAGARATCHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12249",
            "district": "8",
            "Block": "185",
            "label": "PUMS, MUTHUGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12250",
            "district": "8",
            "Block": "185",
            "label": "KURINCHI VENTHAN KALVI NILAYAM, CHINNYAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12251",
            "district": "8",
            "Block": "185",
            "label": "PRASANTHI VIDYA BHAVAN MATRIC SCHOOL, CHINNYAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12252",
            "district": "8",
            "Block": "185",
            "label": "PUMS , MANNATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12253",
            "district": "8",
            "Block": "185",
            "label": "GHSS, MUGASI ANUMANPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12254",
            "district": "8",
            "Block": "185",
            "label": "GHSS,ARACHALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12255",
            "district": "8",
            "Block": "185",
            "label": "PUMS, ANAIKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12256",
            "district": "8",
            "Block": "185",
            "label": "GHSS,LAKKAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12257",
            "district": "8",
            "Block": "185",
            "label": "PUMS,POONDURAISEMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12258",
            "district": "8",
            "Block": "185",
            "label": "PUMS,KILIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12259",
            "district": "8",
            "Block": "185",
            "label": "PUMS,ELAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12260",
            "district": "8",
            "Block": "185",
            "label": "PUMS,NATHAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12261",
            "district": "8",
            "Block": "185",
            "label": "GHS,KASPAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12262",
            "district": "8",
            "Block": "185",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, AVAL POONDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12263",
            "district": "8",
            "Block": "185",
            "label": "KASTHURBAGRAM HIGH SCHOOL, KASTHURBAGRAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12264",
            "district": "8",
            "Block": "185",
            "label": "GHSS,MINNAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12265",
            "district": "8",
            "Block": "185",
            "label": "GHS,VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12266",
            "district": "8",
            "Block": "185",
            "label": "GMS,VILAKETHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12267",
            "district": "8",
            "Block": "185",
            "label": "SARVA SEVA MIDDLE SCHOOL, VINOBHAGRAMAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12281",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGH SCHOOL, VETTAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12282",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, SAVAKATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12283",
            "district": "8",
            "Block": "202",
            "label": "GHSS, KADATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12284",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGH SCHOOL, P.KARATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12285",
            "district": "8",
            "Block": "202",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOODAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12286",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGHER SEC. SCHOOL, KURUMANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12287",
            "district": "8",
            "Block": "202",
            "label": "GOVT. BOYS HIGHER SEC. SCHOOL, NAMBIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12288",
            "district": "8",
            "Block": "202",
            "label": "GOVT. GIRLS HIGHER SEC. SCHOOL, NAMBIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12289",
            "district": "8",
            "Block": "202",
            "label": "KAMARAJ HIGHER SEC. SCHOOL, NAMBIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12290",
            "district": "8",
            "Block": "202",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MALAYAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12291",
            "district": "8",
            "Block": "202",
            "label": "GOVT. MODEL HIGHER SEC.SCHOOL, GETTICHEVIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12292",
            "district": "8",
            "Block": "202",
            "label": "KASTURIBA GANDHI BALIKA VIDYALAYA, SOKKUMARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12293",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGHER SEC. SCHOOL, VEMANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12294",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGH SCHOOL, SEMMAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12295",
            "district": "8",
            "Block": "202",
            "label": "MODEL HIGHER SEC. SCHOOL, PATTIMANIYAKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12296",
            "district": "8",
            "Block": "244",
            "label": "GHSS,VIJAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12297",
            "district": "8",
            "Block": "244",
            "label": "MAHIDHAR ACADEMY HIGH SCHOOL,NANDAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12298",
            "district": "8",
            "Block": "244",
            "label": "GHSS,NALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12299",
            "district": "8",
            "Block": "244",
            "label": "GHS, THIRUVACHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12300",
            "district": "8",
            "Block": "244",
            "label": "MPD GOVERNMENT HIGHER SECONDARY SCHOOL,THINGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12301",
            "district": "8",
            "Block": "244",
            "label": "GHS,KAIKOLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12302",
            "district": "8",
            "Block": "244",
            "label": "GHS,PANDIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12303",
            "district": "8",
            "Block": "244",
            "label": "GHSS,THUDUPATHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12304",
            "district": "8",
            "Block": "244",
            "label": "GGHSS, PERUNDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12305",
            "district": "8",
            "Block": "244",
            "label": "GHSS KANJIKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12306",
            "district": "8",
            "Block": "244",
            "label": "GHS,PETHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12307",
            "district": "8",
            "Block": "244",
            "label": "GBHSS,PERUNDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12308",
            "district": "8",
            "Block": "244",
            "label": "GHS,THOPPUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12309",
            "district": "8",
            "Block": "244",
            "label": "GMHSS,SEENAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12310",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL, VEDARNAGAR,PUTHUVADAVALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12311",
            "district": "8",
            "Block": "281",
            "label": "KASTHURIBA HIGHER SECONDARY SCHOOL, RAJANNAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12312",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL , CHIKKARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12313",
            "district": "8",
            "Block": "281",
            "label": "GOVT. HIGH SCHOOL, INDIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12314",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL, MAKKINANKOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12315",
            "district": "8",
            "Block": "281",
            "label": "GOVT. HIGH SCHOOL, IKKARAINEGAMAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12316",
            "district": "8",
            "Block": "281",
            "label": "KASTURIBA GANDHI BALIKA VIDHYALAYA, VEDARNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12317",
            "district": "8",
            "Block": "281",
            "label": "BSC, GOVERNMENT HIGHER SECONDARY SCHOOL, KEMBANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12318",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MALLIAMMANDURGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12319",
            "district": "8",
            "Block": "281",
            "label": "GTRMS, BATHRIBADUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12320",
            "district": "8",
            "Block": "281",
            "label": "GOVT. HIGHER SEC. SCHOOL, BASUVANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12321",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KALKADAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12322",
            "district": "8",
            "Block": "281",
            "label": "ST.JOHN DE BRITTO GIRLS HIGH SCHOOL, SATHIYAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12323",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SATHYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12324",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT GIRLS MODEL HIGHER SECONDARY SCHOOL, SATHYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12325",
            "district": "8",
            "Block": "281",
            "label": "MUNICIPAL HIGH SCHOOL, RANGASAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12326",
            "district": "8",
            "Block": "281",
            "label": "SRI RAGAVENDRA HR SEC. SCHOOL, SATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12327",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL, MILLMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12328",
            "district": "8",
            "Block": "310",
            "label": "GHS GERMALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12329",
            "district": "8",
            "Block": "310",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGHER SECONDARY SCHOOL HASANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12330",
            "district": "8",
            "Block": "310",
            "label": "GTRHS GEDDESAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12331",
            "district": "8",
            "Block": "310",
            "label": "GTRMS BEJELATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12332",
            "district": "8",
            "Block": "310",
            "label": "GTRHS THALAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12333",
            "district": "8",
            "Block": "310",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THALAVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12334",
            "district": "8",
            "Block": "310",
            "label": "GHSS THALAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12335",
            "district": "8",
            "Block": "310",
            "label": "GHS KADATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12336",
            "district": "8",
            "Block": "310",
            "label": "PUMS DODDAKAJANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12337",
            "district": "8",
            "Block": "310",
            "label": "DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL, DODDAGAJANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12338",
            "district": "8",
            "Block": "310",
            "label": "GHS THIGANARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12339",
            "district": "8",
            "Block": "310",
            "label": "KASTURIBA GANDHI BALIKA VIDYALAYA, KUNNANPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12340",
            "district": "8",
            "Block": "310",
            "label": "GHS CHIKKAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12341",
            "district": "8",
            "Block": "310",
            "label": "GHSS KOTTAMALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12342",
            "district": "8",
            "Block": "310",
            "label": "PUMS ARALAVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12343",
            "district": "8",
            "Block": "310",
            "label": "GOVERNMENT TRIBAL WELFARE MIDDLE SCHOOL, KANAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12344",
            "district": "8",
            "Block": "310",
            "label": "ST JOSEPH'S HIGHER SECONDARY SCHOOL, SOOSAIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12345",
            "district": "8",
            "Block": "310",
            "label": "ST ANN'S HIGH SCHOOL THIGANARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12346",
            "district": "8",
            "Block": "310",
            "label": "GHSS PANAGAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12347",
            "district": "8",
            "Block": "356",
            "label": "JOHN ARTHUR HS,GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12348",
            "district": "8",
            "Block": "356",
            "label": "KASTURIBA GANDHI BALIKA VIDYALAYA, NANJAIPULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12349",
            "district": "8",
            "Block": "356",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KONGARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12350",
            "district": "8",
            "Block": "356",
            "label": "GOVT, HIGHER SEC. SCHOOL, KALLIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12351",
            "district": "8",
            "Block": "356",
            "label": "GOVT. HIGH SCHOOL, VALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12352",
            "district": "8",
            "Block": "356",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12353",
            "district": "8",
            "Block": "356",
            "label": "GOVT.HIGHER SEC.SCHOOL, BUNGALOWPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12354",
            "district": "8",
            "Block": "356",
            "label": "GOVERNMENT. HIGHER SECONDARY. SCHOOL, KASIPALAYAM(GOBI)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12355",
            "district": "8",
            "Block": "356",
            "label": "GOVT. GIRLS HIGHER SEC SCHOOL, T.N.PALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12356",
            "district": "8",
            "Block": "356",
            "label": "SRI PARIYUR AMMAN HIGHER SEC. SCHOOL, T.N.PALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12357",
            "district": "8",
            "Block": "356",
            "label": "GOVT. HIGHER SEC, SCHOOL, D.G.PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12358",
            "district": "8",
            "Block": "356",
            "label": "ST. FRANCIS XAVIER HR SEC SCHOOL, PERIYA KODIVERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20902",
            "district": "8",
            "Block": "65",
            "label": "SAKTHI  SPECIAL SCHOO FOR MENTALLY RETARDEDL, MAMARATHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "20903",
            "district": "8",
            "Block": "65",
            "label": "GOKULAM SPECIAL SCHOOL FOR MENTALLY RETARDED, MUTHAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "20904",
            "district": "8",
            "Block": "185",
            "label": "BHARATHIYAR SPECIAL SCHOOL FOR MENTALLY RETARTED, SAVADIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "20960",
            "district": "8",
            "Block": "37",
            "label": "SRI SWAMI VIVEKANANDA INTELLECTUAL DISABLED SCHOOL, BHAVANI",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "20990",
            "district": "8",
            "Block": "38",
            "label": "SNR VIDHYA NETHRA MATRICULATION HR.SEC.SCHOOL,VINNAPPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21025",
            "district": "8",
            "Block": "202",
            "label": "SHREE VIKAS MATRICULATION SCHOOL, K.METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21072",
            "district": "8",
            "Block": "45",
            "label": "SHEMFORD FUTURISTIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21075",
            "district": "8",
            "Block": "71",
            "label": "BHARATHI VIDHYALAYA SCHOOL ,GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21076",
            "district": "8",
            "Block": "71",
            "label": "M.R.S. PUBLIC SCHOOL, KOLAPPALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21143",
            "district": "8",
            "Block": "65",
            "label": "MOUNT CARMEL INTERNATIONAL SCHOOL, KOLLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21153",
            "district": "8",
            "Block": "185",
            "label": "GREENPARK MATRIC HIGHER SECONDARY SCHOOL, KUPPAGOUNDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21216",
            "district": "8",
            "Block": "20",
            "label": "GMHSS,SELLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21218",
            "district": "8",
            "Block": "281",
            "label": "GOVT. MODEL HIGHER SECONDARY SCHOOL, SATHYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21219",
            "district": "8",
            "Block": "310",
            "label": "GMHSS, MALLANGULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21252",
            "district": "8",
            "Block": "281",
            "label": "SR NACHIMUTHU GOUNDER MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21270",
            "district": "8",
            "Block": "310",
            "label": "KCT MATRICULATION SCHOOL, RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21271",
            "district": "8",
            "Block": "356",
            "label": "KONGU VIDYALAYA MATRICULATION,VARAPPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21467",
            "district": "8",
            "Block": "185",
            "label": "GHS, KANAGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21468",
            "district": "8",
            "Block": "244",
            "label": "GHS, SINGANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21469",
            "district": "8",
            "Block": "244",
            "label": "GHS, NITCHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21559",
            "district": "8",
            "Block": "45",
            "label": "GREEN GARDEN  MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21634",
            "district": "8",
            "Block": "65",
            "label": "C.E.T. MATRICULATION SCHOOL, VETTUKATTUVALASU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21654",
            "district": "8",
            "Block": "45",
            "label": "MAHARISHI VIDYA MANDIR CBSE SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21655",
            "district": "8",
            "Block": "356",
            "label": "SARATHA INTERNATIONAL SCHOOL, KODIVERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21656",
            "district": "8",
            "Block": "356",
            "label": "BEST MATRICULATION SCHOOL, KALLIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21657",
            "district": "8",
            "Block": "281",
            "label": "SANTHOME INTERNATIONAL SCHOOL, SENBAGAPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21895",
            "district": "8",
            "Block": "244",
            "label": "THE RICHMOND MATRICULATION HIGHER SECONDARY SCHOOL, SEENAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21896",
            "district": "8",
            "Block": "244",
            "label": "THE RICHMOND PUBLIC SCHOOL, SEENAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21959",
            "district": "8",
            "Block": "10",
            "label": "PERIYASAMY HIGH SCHOOL, AMMAPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21960",
            "district": "8",
            "Block": "281",
            "label": "ROMAN CATHOLIC HIGH SCHOOL, GUNDRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22125",
            "district": "8",
            "Block": "37",
            "label": "PSK HI TECH ACADEMY, PARUVACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22126",
            "district": "8",
            "Block": "65",
            "label": "AN NOOR MATRICULATION SCHOOL, SUNNAMPU ODAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22127",
            "district": "8",
            "Block": "65",
            "label": "NANDHA CENTRAL CITY SCHOOL, NARAYANAVALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22128",
            "district": "8",
            "Block": "65",
            "label": "THE INDIAN PUBLIC SCHOOL (CAMBRIDGE INTERNATIONAL CAMPUS), SENAPATHY PALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22129",
            "district": "8",
            "Block": "128",
            "label": "KONGU MATRICULATION HIGHER SECONDARY SCHOOL,  KARUMANDAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22455",
            "district": "8",
            "Block": "20",
            "label": "VIVEGAM VIDHYABHAVAN MATRICULATION SCHOOL, VEMPATHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22456",
            "district": "8",
            "Block": "20",
            "label": "GOVERNMENT HIGH SCHOOL, SINTHAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22457",
            "district": "8",
            "Block": "37",
            "label": "TRIPLEE A MATRIC & PLAY SCHOOL, KURUPPANAICKANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22461",
            "district": "8",
            "Block": "71",
            "label": "GOVERNMENT HIGH SCHOOL, P.VELLALAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22462",
            "district": "8",
            "Block": "71",
            "label": "SHREE GURUKULAM PUBLIC SCHOOL,MOOLAVAIKKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22463",
            "district": "8",
            "Block": "71",
            "label": "GOVERNMENT HIGH SCHOOL, MANIYAKARANPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22466",
            "district": "8",
            "Block": "202",
            "label": "GOVERNMENT HIGH SCHOOL, KUMMAKKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22467",
            "district": "8",
            "Block": "202",
            "label": "GOVERNMENT HIGH SCHOOL, POTHAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22468",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL, GUJJAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22469",
            "district": "8",
            "Block": "310",
            "label": "GOVERNMENT HIGH SCHOOL, SUJJILKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22703",
            "district": "8",
            "Block": "20",
            "label": "KSC PUBLIC SCHOOL, MANJALANAICKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22704",
            "district": "8",
            "Block": "38",
            "label": "CHANAKYA INTERNATIONAL SCHOOL, PONMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22705",
            "district": "8",
            "Block": "38",
            "label": "SPRINGDALE PUBLIC SCHOOL, PUNJAI PULIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22706",
            "district": "8",
            "Block": "45",
            "label": "SRI JAI VIKAS PUBLIC CBSE SCHOOL, ULAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22707",
            "district": "8",
            "Block": "45",
            "label": "KONGU INTERNATIONAL SCHOOL(CBSE), MUTHAIAN KOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22744",
            "district": "8",
            "Block": "20",
            "label": "GTRHS, KONGADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22869",
            "district": "8",
            "Block": "20",
            "label": "GHS, THAMARAIKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22870",
            "district": "8",
            "Block": "202",
            "label": "GHS, SUNDAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22871",
            "district": "8",
            "Block": "185",
            "label": "GHS, KANDIKKATTUVALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22872",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL,MAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22873",
            "district": "8",
            "Block": "310",
            "label": "GHS, KUNNANPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22881",
            "district": "8",
            "Block": "71",
            "label": "GHS, ODAKKATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22882",
            "district": "8",
            "Block": "45",
            "label": "GOVERNMENT HIGH SCHOOL, VOIPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23023",
            "district": "8",
            "Block": "244",
            "label": "BHARATHI INTERNATIONAL SCHOOL, VIJAYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23067",
            "district": "8",
            "Block": "10",
            "label": "KALAIVANI KALVI NILAYAM MATRICULATION SCHOOL,CHENNAMPATTI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23096",
            "district": "8",
            "Block": "20",
            "label": "NAKKEERAN MATRICULATION SCHOOL, SANDHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23097",
            "district": "8",
            "Block": "65",
            "label": "SRI CHAITANYA TECHNO SCHOOL, THINDAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23182",
            "district": "8",
            "Block": "185",
            "label": "RAJENDRAN ACADEMY FOR LEARNING, VADUGAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23183",
            "district": "8",
            "Block": "185",
            "label": "THE GREENS SCHOOL, KANAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23241",
            "district": "8",
            "Block": "37",
            "label": "GOVERNMENT HIGH SCHOOL, KURUPPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23250",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT HIGH SCHOOL, PERIYAVALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23331",
            "district": "8",
            "Block": "65",
            "label": "VELAMMAL BODHI CAMPUS, MELTHINDAL, ERODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23332",
            "district": "8",
            "Block": "185",
            "label": "THE NAVARRASAM ACADEMY, ARACHALUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23422",
            "district": "8",
            "Block": "244",
            "label": "Brainstorm High school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23481",
            "district": "8",
            "Block": "65",
            "label": "ERODE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23482",
            "district": "8",
            "Block": "65",
            "label": "SIFT MODEL MIDDLE SCHOOL, ERODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23483",
            "district": "8",
            "Block": "185",
            "label": "AMRITA VIDYALAYAM, PUNJAILAKKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23484",
            "district": "8",
            "Block": "244",
            "label": "ERODE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23777",
            "district": "8",
            "Block": "65",
            "label": "VELS GLOBAL SCHOOL,KOLLAMPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23778",
            "district": "8",
            "Block": "65",
            "label": "HINDU GLOBAL SCHOOL , MAMARATHUPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23779",
            "district": "8",
            "Block": "65",
            "label": "AATHICHUDI SCHOOL FOR DYSLEXIA, E.K VALASU",
            "Sch_type": "Un-aidedOthers"
        },
        {
            "key": "23880",
            "district": "8",
            "Block": "185",
            "label": "THE VELS ACADEMY, MUTHUGOUNDANPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23891",
            "district": "8",
            "Block": "202",
            "label": "EDISON INTERNATIONAL SCHOOL, VEMANDAMPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23921",
            "district": "8",
            "Block": "244",
            "label": "ERODE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23955",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23956",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "9": [
        {
            "key": "3746",
            "district": "9",
            "Block": "347",
            "label": "AIDED MIDDLE SCHOOL THIMMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4385",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL V MAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4796",
            "district": "9",
            "Block": "95",
            "label": "RR CAMBRIDGE HIGHER SECONDARY SCHOOL NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4872",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ARUTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5034",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANGIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5116",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL UDHAYAMAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5201",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOZHINDRAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5243",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAZHUMALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5517",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMMAKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5556",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADASIRUVALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5627",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VADAMARUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5737",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5784",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NORTH STREET TIRUKOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5807",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHINNAMAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5810",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PORASAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5879",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL  POOMARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6024",
            "district": "9",
            "Block": "50",
            "label": "JEEVAN MATRICULATION HIGHER SECONDARY SCHOOL  AMMAIYAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6098",
            "district": "9",
            "Block": "323",
            "label": "VASAVI AIDED  MIDDLE SCHOOL  SOUTH STREET TIRUKOILUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6113",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADUVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23386",
            "district": "9",
            "Block": "50",
            "label": "Denish mission middle school",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "6127",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KADIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6244",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6245",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL JAMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6250",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAZHANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6271",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AYANVELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6285",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KODIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6294",
            "district": "9",
            "Block": "323",
            "label": "AIDED MIDDLE SCHOOL  MEMALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6321",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMBAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6359",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT MIDDLE SCHOOL SIRUVANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6374",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MADUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6408",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEELATHENUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6432",
            "district": "9",
            "Block": "50",
            "label": "BHARATHIDHASAN MATRIC SCHOOL ELIYATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6525",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVIYANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6549",
            "district": "9",
            "Block": "347",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL MADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6558",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVARADIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6568",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENGAIVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6730",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VETTIPERUMALAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6743",
            "district": "9",
            "Block": "277",
            "label": "JAYAM MATRIC HIGHER SECONDARY SCHOOL, SANKARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10466",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10467",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL ATHIYANTHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10468",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VILANDAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10469",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KULADEEPAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10470",
            "district": "9",
            "Block": "323",
            "label": "St.AROCKIA ANNAI HIGH SCHOOL T.ATHIPAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10495",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL MANALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10496",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL MANALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10497",
            "district": "9",
            "Block": "269",
            "label": "DEEPALAKSHMI MATRICULATION SCHOOL MANALURPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10502",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAKKUNEMILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10503",
            "district": "9",
            "Block": "323",
            "label": "SRI BARATHI MATRIC  SCHOOL  VADAKKUNEMILI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10504",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ATHANDAMARUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10505",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  MUDHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10506",
            "district": "9",
            "Block": "323",
            "label": "SRI RAMAKRISHNA MATRICULATION SCHOOL AVI KOLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10507",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  KATTUPAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10508",
            "district": "9",
            "Block": "323",
            "label": "AIDED MIDDLE SCHOOL  ELRAMPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10509",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VADAMALAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10510",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  THIRUPALA PANDHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10511",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THURINJIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10512",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  PONNIYANDHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10513",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOMALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10514",
            "district": "9",
            "Block": "323",
            "label": "SRI MARUTHI VIDHYASHRAM MATRIC HIGH SCHOOL  KOMALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10515",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  CHOLAVANDIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10516",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL THAGADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10517",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  KOOVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10518",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL THIMMACHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10519",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PADIYANDHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10520",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOLAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10521",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NEDUMUDAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10522",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MADAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10523",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOLL  EDAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10524",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  AVI.PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10525",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELATHALANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10526",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANAGANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10527",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIVANARTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10528",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  KEEZHATHAZHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10529",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  G.ARIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10530",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  G.ARIYUR (MODEL)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10531",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  AMMANKOLLAIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10532",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KACHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10533",
            "district": "9",
            "Block": "323",
            "label": "KASTUBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL TIRUPALAPANDHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10534",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  VENGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10535",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10536",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THULAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10537",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MOHALAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10538",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVIYAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10539",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SANGIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10540",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PILLAIYAR PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10541",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL TIRUKOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10542",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL TIRUKOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10543",
            "district": "9",
            "Block": "323",
            "label": "SRI.GNAN.MATRIC.HIGHER SECONDARY SCHOOL  TIRUKOILUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10544",
            "district": "9",
            "Block": "323",
            "label": "UNAIDED MIDDLE SCHOOL (BOARDING WELFARE) TIRUKOVILUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10545",
            "district": "9",
            "Block": "323",
            "label": "DANISH MISSION (GIRLS) BOARDING HIGHER SECONDARY SCHOOL  SILOAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10546",
            "district": "9",
            "Block": "323",
            "label": "DANISH  MISSION  MIDDLE SCHOOL  KEELAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10547",
            "district": "9",
            "Block": "323",
            "label": "DANISH MISSION  HIGH SCHOOL  SANDHAPET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10548",
            "district": "9",
            "Block": "323",
            "label": "SRI VIDYA MANDIR SENIOR SECONDARY SCHOOL TIRUKOILUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10549",
            "district": "9",
            "Block": "323",
            "label": "SRI SAI VIDHYALAYA MATRICULATION SCHOOL SANDAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10596",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10597",
            "district": "9",
            "Block": "277",
            "label": "CHRIST SPECIAL HIGH SCHOOL SANKARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10598",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT GIRLS HIGHER  SECONDARY SCHOOL SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10599",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10600",
            "district": "9",
            "Block": "277",
            "label": "ST.JOSEPH ACADEMY  MATRIC HIGHER SECONDARY SCHOOL SANKARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10601",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTU VANNANJUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10602",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  DEVAPANDALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10603",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  DEVAPANDALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10604",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  S.KULATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10605",
            "district": "9",
            "Block": "277",
            "label": "ADAIKALA ANNAI HIGHER SECONDARY SCHOOL VIRIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10606",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SOUNDARAVALLI PALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10607",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIYAGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10608",
            "district": "9",
            "Block": "277",
            "label": "ALAGAPPA AIDED MIDDLE SCHOOL POOTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10609",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL PAVALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10610",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMBARAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10611",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL  POIKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10612",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL ARASAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10613",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ARASAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10614",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOSAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10615",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL  SESHASAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10616",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NEDUMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10617",
            "district": "9",
            "Block": "277",
            "label": "BHARATHI MATRICULATION SCHOOL, NEDUMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10618",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL PALAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10619",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MANJAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10620",
            "district": "9",
            "Block": "277",
            "label": "OXFORD MATRIC. HIGHER SECONDARY SCHOOL VADASEMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10621",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MURARBAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10622",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL MURARBAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10623",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SOLAMPATTU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10624",
            "district": "9",
            "Block": "277",
            "label": "UNAIDED ROMAN CATHALIC MIDDLE SCHOOL  SOLAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10625",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL LAKKINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10626",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAVATHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10627",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RANGAPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10628",
            "district": "9",
            "Block": "277",
            "label": "ANNAI TERESA MATRIC HIGHER SECONDARY SCHOOL  RANGAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10629",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PUDHUPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10630",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MOONGILTHURAIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10631",
            "district": "9",
            "Block": "277",
            "label": "THE KCSM MATRIC HIGHER SECONDARY SCHOOL MOONKILTHURAIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10632",
            "district": "9",
            "Block": "277",
            "label": "ST. DONBOSCO MATRIC HIGHER SECONDARY SCHOOL  MOONGILTHURAIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10633",
            "district": "9",
            "Block": "277",
            "label": "ST.MARY'S MATRICULATION SCHOOL, PORUVALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10634",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PORASAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10635",
            "district": "9",
            "Block": "277",
            "label": "ROMAN CATHALIC MIDDLE SCHOOL ARULAMPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10636",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL (URUDHU)VADAPONPARAPPI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10637",
            "district": "9",
            "Block": "277",
            "label": "SARVODAYA MATRIC HIGHER SECONDARY SCHOOL, VADAPONPARAPPI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10638",
            "district": "9",
            "Block": "277",
            "label": "AIDED MIDDLE SCHOOL VADAKEERANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10639",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  BRAMMAKUNDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10640",
            "district": "9",
            "Block": "277",
            "label": "ST.MICHAEL'S HIGH SCHOOL MICHAELPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10641",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MANALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10642",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL POUNJIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10643",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SELLAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10644",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL PERUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10645",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL CHINNAPALAPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10646",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL MATTAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10647",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL POTTIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10648",
            "district": "9",
            "Block": "277",
            "label": "GOOD SHEPHERD HIGHER SECONDARY SCHOOL SERAPATTU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10649",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH  SCHOOL  PUDHUPALAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10650",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL PARANGINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10651",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL JAMBADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10652",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL SEERPADANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10653",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARKAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10654",
            "district": "9",
            "Block": "269",
            "label": "ST VINNARASI HIGH SCHOOL IRUDAYAMPATTU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10655",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10656",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10657",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL THOZHUVANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10658",
            "district": "9",
            "Block": "269",
            "label": "UNAIDED ROMAN CATHOLIC MIDDLE SCHOOL KANANKADU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10659",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAMAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10660",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL PERIYAKOLLIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10661",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEERPANANTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10662",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELAYANARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10663",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERIYAMANIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10664",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALLIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10665",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIRUPANAIYUR THAKKA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10666",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL KADAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10667",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10668",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EDUTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10669",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EDUTHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10670",
            "district": "9",
            "Block": "269",
            "label": "RAMANA MATRICULATION SCHOOL VANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10671",
            "district": "9",
            "Block": "269",
            "label": "SAINT DONBOSCO  HIGHER SECONDARY SCHOOL  VANABURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10672",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10673",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KADUVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10674",
            "district": "9",
            "Block": "269",
            "label": "KULANDAI YESU HIGH SCHOOL  MAIYANUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10675",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10676",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ARIYALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10677",
            "district": "9",
            "Block": "269",
            "label": "KASTHURIBA GANDHI BALIGA VIDYALAYA RESIDENTIAL SCHOOL VANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10678",
            "district": "9",
            "Block": "269",
            "label": "ADHI DIRAVIDAR WELFARE MIDDLE SCHOOL ENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10679",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AVIRIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10680",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL LA.GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10681",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KEELPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10682",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NOOROLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10683",
            "district": "9",
            "Block": "269",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL MELAPAZHANGUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10684",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT MODEL SCHOOL SITHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10685",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAVANDUR HINDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10686",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PASAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10687",
            "district": "9",
            "Block": "269",
            "label": "ADHI DIRAVIDAR WELFARE MIDDLE SCHOOL PASAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10688",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL RISHIVANDIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10689",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT GIRLS  HIGH SCHOOL RISHIVANDIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10690",
            "district": "9",
            "Block": "269",
            "label": "KASTHURIBA GANDHI BALIGA VIDYALAYA RESIDENTIAL SCHOOL RISHIVANDIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10691",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PRIVIDAYAMPAT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10692",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MANDAGAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10693",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10694",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALLIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10695",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SITHERIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10696",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SOOLANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10697",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAZHAYASIRUVANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10698",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THONDANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10699",
            "district": "9",
            "Block": "95",
            "label": "VANMATHI MATRICULATION HIGH SCHOOL EDUTHAVAINATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10700",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EDUTHAVAINATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10701",
            "district": "9",
            "Block": "95",
            "label": "MASS MATRICULATION  SCHOOL EDUTHAVAINATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10702",
            "district": "9",
            "Block": "95",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL PACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10703",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MANMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10704",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KARADICHITHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10705",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THAVADIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10706",
            "district": "9",
            "Block": "95",
            "label": "THIRU MURUGAN AIDED MIDDLE SCHOOL MADHAVACHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10707",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL MADAVACHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10708",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALRAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10709",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL MATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10710",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL EMAPPAIR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10711",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EAST KALLAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10712",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALLAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10713",
            "district": "9",
            "Block": "95",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL KALLAKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10714",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KALLAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10715",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KALLAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10716",
            "district": "9",
            "Block": "95",
            "label": "BHARATHI MATRIC HIGHER SECONDARY SCHOOL, KALLAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10717",
            "district": "9",
            "Block": "95",
            "label": "MOUNT CARMAL MATRICULATION HIGHER SECONDARY SCHOOL KALLAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10718",
            "district": "9",
            "Block": "95",
            "label": "ST.MARYS MATRICULATION SCHOOL KALLAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10719",
            "district": "9",
            "Block": "95",
            "label": "VIDHYALAKSHMI HIGH SCHOOL KALLAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10720",
            "district": "9",
            "Block": "95",
            "label": "ADI DRAVIDAR  WELFARE MIDDLE SCHOOL SIRUVANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10721",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SALAI MAMANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10722",
            "district": "9",
            "Block": "95",
            "label": "ARASAKUMARI HiGH SCHOOL SIRUVANGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10723",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUVANGUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10724",
            "district": "9",
            "Block": "95",
            "label": "AKT  ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10725",
            "district": "9",
            "Block": "95",
            "label": "SRIRAM AIDED MIDDLE SCHOOL NEELAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10726",
            "district": "9",
            "Block": "95",
            "label": "AKT HIGH SCHOOL NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10727",
            "district": "9",
            "Block": "95",
            "label": "A K T MEMORIAL VIDYA SAAKET  SCHOOL, NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10728",
            "district": "9",
            "Block": "95",
            "label": "ADI DRAVIDAR  WELFARE MIDDLE SCHOOL THENKEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10729",
            "district": "9",
            "Block": "95",
            "label": "MAHABARATHI INTERNATIONAL SCHOOL THENKEERANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10730",
            "district": "9",
            "Block": "95",
            "label": "ADI DRAVIDAR  WELFARE HIGH SCHOOL NIRAIMATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10731",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEERACHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10732",
            "district": "9",
            "Block": "95",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL PERIYASIRUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10733",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL MALAIKOTTALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10734",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL VANAVAREDDY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10735",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10736",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL VILAMBAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10737",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THACHUR KATTU KOTTAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10738",
            "district": "9",
            "Block": "95",
            "label": "OXALISS INTERNATIONAL SCHOOL THACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10739",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PORPADAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10740",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ERAVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10741",
            "district": "9",
            "Block": "95",
            "label": "Dr. RKS MASTERS MATRIC HR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10742",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL THANDALAI PERUVANGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10743",
            "district": "9",
            "Block": "95",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL AGARKOTTALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10744",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10745",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  A. PUTHANTHDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10746",
            "district": "9",
            "Block": "95",
            "label": "KANCHANADEVI MATRICULATION HIGHER SECONDARY SCHOOL ALATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10747",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERIYASIRUVATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10748",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL PERIYASIRUVATUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10749",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL K.ALAMBALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10750",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARIYAPERUMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10751",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10752",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SOMANDARGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10753",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERUMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10754",
            "district": "9",
            "Block": "95",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL  PUKKIRAVARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10755",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL PUKKIRAVARI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10756",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10757",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL MANIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10758",
            "district": "9",
            "Block": "95",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL THARISIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10759",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY  SCHOOL INNADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10760",
            "district": "9",
            "Block": "95",
            "label": "GOOD SHEPHERD HIGH SCHOOL  MELNILAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10761",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL NOCHIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10762",
            "district": "9",
            "Block": "95",
            "label": "MURUGU AIDED MIDDLE SCHOOL MALLIYAMPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10763",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL MELVAZHAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10764",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL KOTTAPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10765",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL ARAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10766",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL VARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10767",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL EZHUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10768",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL MELPACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10769",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT EKLAVYA MODEL RESIDENTIAL BOYS HIGHER SECONDARY SCHOOL , VELLIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10770",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10771",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL THENGIANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10772",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL PAITHANTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10773",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THOTTIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10774",
            "district": "9",
            "Block": "50",
            "label": "AIDED HIGHER SECONDARY SCHOOL MELNARIYAPPANUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10775",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL KALLANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10776",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANDIANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10777",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL ELAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10778",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL AMMAYAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10779",
            "district": "9",
            "Block": "50",
            "label": "KURINJIE HIGHER SECONDARY SCHOOL. AMMAIYAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10780",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NAINARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10781",
            "district": "9",
            "Block": "50",
            "label": "ST.PAUL HIGH SCHOOL NAINARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10782",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL CHINNASALEM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10783",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL CHINNASALEM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10784",
            "district": "9",
            "Block": "50",
            "label": "BHARATHI BALAMANDIR MATRIC HIGHER SECONDARY SCHOOL, CHINNASALEM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10785",
            "district": "9",
            "Block": "50",
            "label": "AIDED MIDDLE SCHOOL CHINNASALEM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10786",
            "district": "9",
            "Block": "50",
            "label": "AIDED HIGHER SECONDARY SCHOOL CHINNASALEM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10787",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEELKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10788",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL  KARUNTHALAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10789",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL V ALAMBALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10790",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  HIGHER SECONDARY SCHOOL THAGAMTHERTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10791",
            "district": "9",
            "Block": "50",
            "label": "WELLDONE MATRIC HR.SEC SCHOOL,THAGAMTHEERTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10792",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL  PAKKAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10793",
            "district": "9",
            "Block": "50",
            "label": "ST.ANN'S HIGH SCHOOL  THENSIRUVALLUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10794",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THOTTAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10795",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  HIGHER SECONDARY SCHOOL GUGAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10796",
            "district": "9",
            "Block": "50",
            "label": "SAKTHI MATRIC HIGHER SECONDARY SCHOOL KANIYAMOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10797",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PETHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10798",
            "district": "9",
            "Block": "50",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL ULAGANKATHAN",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10799",
            "district": "9",
            "Block": "50",
            "label": "BHARATHI HIGH SCHOOL BANGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10800",
            "district": "9",
            "Block": "50",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL  NAMASIVAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10801",
            "district": "9",
            "Block": "50",
            "label": "SRI VIVEKANANDA HIGHER SECONDARY SCHOOL  EARVAIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10802",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  HIGHER SECONDARY SCHOOL KUTHIRAICHANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10803",
            "district": "9",
            "Block": "50",
            "label": "ST. MARY'S HIGH SCHOOL KARANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10804",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MATTIKAIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10805",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  NAGAKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10806",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENKATAMMAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10807",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL VADAKKANANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10808",
            "district": "9",
            "Block": "50",
            "label": "BHARATHI MATRIC HIGHER SECONDARY SCHOOL VADAKKANANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10809",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL GOMUGI DAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10810",
            "district": "9",
            "Block": "50",
            "label": "VANMATHI MATRIC HIGHER SECONDARY SCHOOL  VADAKANNANTHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10811",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KACHIRAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10812",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  KACHIRAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10813",
            "district": "9",
            "Block": "50",
            "label": "AIDED MIDDLE SCHOOL METTUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10814",
            "district": "9",
            "Block": "50",
            "label": "DANISH MISSION HIGHER SECONDARY SCHOOL KARIYALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10815",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PRITHIVIMANGALAM (HINDU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10816",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PRITHIVIMANGALAM (COLONY)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10817",
            "district": "9",
            "Block": "347",
            "label": "MOUNT PARK HIGHER SECONDARY SCHOOL,  THIYAGADURGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10818",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL THIYAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10819",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VILAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10820",
            "district": "9",
            "Block": "347",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL KUDIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10821",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10822",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KUTHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10823",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EYYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10824",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GURUPEEDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10825",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT MIDDLE SCHOOL ERANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10826",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ASAKALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10827",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL S.OHAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10828",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10829",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VIRUGAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10830",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL VIRUGAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10831",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PUDU UCHIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10832",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL KONGARAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10833",
            "district": "9",
            "Block": "347",
            "label": "KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL MUDIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10834",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10835",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL PEELAMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10836",
            "district": "9",
            "Block": "347",
            "label": "KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL PALLAGACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10837",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL PALLAGACHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10838",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL THIYAGADURGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10839",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL THIYAGADURGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10840",
            "district": "9",
            "Block": "347",
            "label": "VASAVI MATRICULATION SCHOOL THIYAGADURGAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10841",
            "district": "9",
            "Block": "347",
            "label": "MOUNT PARK SPECIAL ACADEMY  MATRIC HIGHER SECONDARY SCHOOL PERIYAMAMPATTU , THIYAGADURGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10842",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERIYAKURUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10843",
            "district": "9",
            "Block": "372",
            "label": "OM GURU GANESA MATRICULATION SCHOOL KATTUEDAIYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10844",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL KATTUEDAIYAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10845",
            "district": "9",
            "Block": "372",
            "label": "ST.JOSEPH HSS, ERAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10846",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T PALLAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10847",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOOVADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10848",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NATHAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10849",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KALSIRUNAGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10850",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUNJARAM (H)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10851",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTUSELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10852",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOOTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10853",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELLAIGIRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10854",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ELAVANASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10855",
            "district": "9",
            "Block": "372",
            "label": "SRI VINAYAGA MATRICULATION HIGHER SECONDARY SCHOOL  ELAVANASUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10856",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  ELAVANASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10857",
            "district": "9",
            "Block": "372",
            "label": "BHARATH MATRICULATION  SCHOOL ELAVANASUR KOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10858",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAKURUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10859",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10860",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT MIDDLE SCHOOL PARINTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10861",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T.THAKKA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10862",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL NEDUMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10863",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10864",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SIRUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10865",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PUGAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10866",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL M.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10867",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THAMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10868",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KILIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10869",
            "district": "9",
            "Block": "372",
            "label": "SRI RAMAKRISHNA VIDYALAYA GURUKULAM MATRIC HIGHER SECONDARY SCHOOL EDAIKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10870",
            "district": "9",
            "Block": "372",
            "label": "KASTHURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL A. KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10871",
            "district": "9",
            "Block": "372",
            "label": "KASTHURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL KOOTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10872",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALI PUTHUCOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10873",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  PALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10874",
            "district": "9",
            "Block": "372",
            "label": "BUTTERFLY MATRICULATION SCHOOL SHEIK HUSSAINPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10875",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANAIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10876",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ASANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10877",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PU.KONALAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10878",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PU.KILLANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10879",
            "district": "9",
            "Block": "372",
            "label": "PARKAVAN MATRICULATION HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10880",
            "district": "9",
            "Block": "372",
            "label": "RAVLATHUL JANNAH ORIENTAL HIGH SCHOOL  ULUNDURPET",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "10881",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  R.R.KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10882",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VELLAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10883",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ULUNDURPET(HINDU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10884",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ULUNDANDAR KOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10885",
            "district": "9",
            "Block": "372",
            "label": "BESCHI HIGHER SECONDARY SCHOOL  ULUNDURPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10886",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT GIRLS MODEL HIGHER SECONDARY SCHOOL ULUNDURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10887",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL  ULUNDURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10888",
            "district": "9",
            "Block": "372",
            "label": "SRI SARADA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL  ULUNDURPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10889",
            "district": "9",
            "Block": "372",
            "label": "BEULAH  MATRICULATION SCHOOL ULUNDURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10890",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10891",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10892",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KILAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10893",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KALAMARUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10894",
            "district": "9",
            "Block": "327",
            "label": "ARUNA HIGHER SECONDARY SCHOOL KORATAN KURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10895",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PILLAIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10896",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGH SCHOOL VELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10897",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUNARANKUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10898",
            "district": "9",
            "Block": "327",
            "label": "AIDED MIDDLE SCHOOL MADAPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10900",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGH SCHOOL IRUNDAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10901",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KORATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10902",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOOVAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10903",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10904",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T.ORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10905",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PADUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10906",
            "district": "9",
            "Block": "327",
            "label": "VEDANTHA VID MATRICULATION SCHOOL PADUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10907",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERIYAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10908",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIMMIREDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10909",
            "district": "9",
            "Block": "327",
            "label": "DON BOSCO HIGHER SECONDARY SCHOOL GEDILAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10910",
            "district": "9",
            "Block": "327",
            "label": "S S V HIGHER SECONDARY SCHOOL SENTHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10911",
            "district": "9",
            "Block": "327",
            "label": "DANISH MISSION HIGH SCHOOL PARIKKAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10912",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMMANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10913",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THIRUNAVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10914",
            "district": "9",
            "Block": "327",
            "label": "KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL THIRUNAVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10915",
            "district": "9",
            "Block": "327",
            "label": "ISHA VIDYA MATRICULATION HIGHER SECONDARY SCHOOL TIRUNAVALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10916",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ESWARAKANDANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10917",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SOMASIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10918",
            "district": "9",
            "Block": "327",
            "label": "AIDED MIDDLE SCHOOL EAST MARUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10919",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10920",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENGURICHI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10921",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENGURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10922",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NAINAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10923",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PU.MAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10924",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELUPPAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10925",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALLAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23408",
            "district": "9",
            "Block": "50",
            "label": "LITTLE FLOWER HIGHER SECONDARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23410",
            "district": "9",
            "Block": "95",
            "label": "SIRUMALAR INTERNATIONAL SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "10926",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENDANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10927",
            "district": "9",
            "Block": "327",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL , SENTHANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20963",
            "district": "9",
            "Block": "50",
            "label": "ECR INTERNATIONAL   SCHOOL KANIYAMOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21017",
            "district": "9",
            "Block": "95",
            "label": "KALAIVANI MATRICULATION  HIGHER SECONDARY  SCHOOL , PORPADAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21018",
            "district": "9",
            "Block": "50",
            "label": "MAHA KAVI MATRIC HIGHER SECONDARY SCHOOL , RAYAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21063",
            "district": "9",
            "Block": "323",
            "label": "SRI SIKSHA KENDRA INTERNATIONAL SCHOOL KUCHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21064",
            "district": "9",
            "Block": "372",
            "label": "SRI RAMANA PUBLIC SCHOOL(CBSE), KATTUSELLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21203",
            "district": "9",
            "Block": "277",
            "label": "MODEL HIGHER SECONDARY SCHOOL MOOLAKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21204",
            "district": "9",
            "Block": "372",
            "label": "MODEL SCHOOL - GOVERNMENT HIGHER SECONDARY SCHOOL  A.KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21205",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL VELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21453",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL URANGANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21454",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL VANIYANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21455",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL ANAIKARAIKOTTALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21456",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL SIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21592",
            "district": "9",
            "Block": "95",
            "label": "INTACT MATRICULATION  SCHOOL VELLIMALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21593",
            "district": "9",
            "Block": "327",
            "label": "J S CENTRAL SCHOOL MADAPPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21641",
            "district": "9",
            "Block": "372",
            "label": "CHORDIA JAIN SCHOOL OLAYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21685",
            "district": "9",
            "Block": "95",
            "label": "SRI VENKATESHWARA MATRICULATION SCHOOL MATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21687",
            "district": "9",
            "Block": "50",
            "label": "SEVA MATRICULTION MIDDLE SCHOOL PULUVAPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21790",
            "district": "9",
            "Block": "277",
            "label": "NEW POWER MATRIC HIGHER SECONDARY SCHOOL SANKARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21791",
            "district": "9",
            "Block": "277",
            "label": "KALAIMAHAL  MATRIC HIGHER SECONDARY SCHOOL,  MANJAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21792",
            "district": "9",
            "Block": "277",
            "label": "CHAKRA VIDYALAYA CBSE SCHOOL VADASEMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21793",
            "district": "9",
            "Block": "277",
            "label": "VIVEKANANDA HIGHER SECONDARY SCHOOL SOLAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21934",
            "district": "9",
            "Block": "372",
            "label": "SRI RAMAKRISHNA VIDYA MANDIR INTERNATIONAL SCHOOL, ULUNDURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21935",
            "district": "9",
            "Block": "372",
            "label": "RPK MIDDLE SCHOOL ULUNDURPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21969",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL MOOLAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21970",
            "district": "9",
            "Block": "95",
            "label": "SRI BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL THACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21971",
            "district": "9",
            "Block": "327",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL KALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22098",
            "district": "9",
            "Block": "323",
            "label": "NEW VIDYA  MANDIR MATRICLULATION SCHOOL KULADEEPAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22099",
            "district": "9",
            "Block": "323",
            "label": "ST. ANTONY MATRICULATION SCHOOL T.ATHIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22103",
            "district": "9",
            "Block": "95",
            "label": "GS MATRICULATION SCHOOL ALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22104",
            "district": "9",
            "Block": "50",
            "label": "BALAN BALAMANDHIR MATRIC HIGH SCHOOL NAINARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22105",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL SITHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22106",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGH SCHOOL NANNARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22430",
            "district": "9",
            "Block": "95",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Eduthavainatham",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22431",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL THENKEERANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22432",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL  VADATHORASALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22433",
            "district": "9",
            "Block": "347",
            "label": "MOUNT PARK PUBLIC SENIOR SECONDARY  SCHOOL (CBSE) VADATHORASALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22434",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL PANAYANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22435",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL NINNAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22436",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGH SCHOOL PANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22760",
            "district": "9",
            "Block": "277",
            "label": "SUBAM INTERNATIONAL (CBSE) SCHOOL VADAPONPARAPPI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22876",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  G.ARIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22887",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL PINNALVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22889",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL MURUKKAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22987",
            "district": "9",
            "Block": "372",
            "label": "ST. ANNE'S GIRLS HIGHER SECONDARY  SCHOOL ERAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23066",
            "district": "9",
            "Block": "95",
            "label": "JS GLOBAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23125",
            "district": "9",
            "Block": "323",
            "label": "SRI SARADHA VIDHYASHRAM ,T.KEERANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23167",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL WELFARE HIGH SCHOOL KILAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23168",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PACHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23228",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL MUDIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23230",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL ADHAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23304",
            "district": "9",
            "Block": "95",
            "label": "AKT MEMORIAL MATRICULATION GIRLS SCHOOL NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23306",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PARANGINATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23485",
            "district": "9",
            "Block": "95",
            "label": "KALLAKURICHI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23486",
            "district": "9",
            "Block": "372",
            "label": "SRI GNANAMBIGAA VIDYALAYA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23757",
            "district": "9",
            "Block": "50",
            "label": "AIDED MIDDLE SCHOOL KARANUR",
            "Sch_type": "Fully AidedMiddle School"
        },
        {
            "key": "23803",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23804",
            "district": "9",
            "Block": "95",
            "label": "KALLAKURICHI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23937",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23938",
            "district": "9",
            "Block": "269",
            "label": "TIRUMALA MATRIC SCHOOL VANAPURAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23944",
            "district": "9",
            "Block": "277",
            "label": "ST. JOSEPH ACADEMY INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24006",
            "district": "9",
            "Block": "327",
            "label": "JUBILATION MATRICULATION SCHOOL KALAMARUDUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "10": [
        {
            "key": "553",
            "district": "10",
            "Block": "146",
            "label": "Kalashethra MHSS, Iyyappanthangal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "758",
            "district": "10",
            "Block": "146",
            "label": "LALAJI MEMORIAL OMEGA INTERNATIONALSCHOOL  KOLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23660",
            "district": "10",
            "Block": "101",
            "label": "Melmaruvathur Adhiparasakthi High School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "1334",
            "district": "10",
            "Block": "146",
            "label": "Little Flower MHSS Moulivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2903",
            "district": "10",
            "Block": "146",
            "label": "P S Balabhavan MHSS Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3520",
            "district": "10",
            "Block": "146",
            "label": "The National IT Int. Matric Hr Sec School, Kumananchavadi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4349",
            "district": "10",
            "Block": "146",
            "label": "Masi MHSS, Iyappanthangal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4618",
            "district": "10",
            "Block": "146",
            "label": "Sivanthi MS Kundrathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4632",
            "district": "10",
            "Block": "146",
            "label": "ST. ANNES MAT HSS MADANANDHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4811",
            "district": "10",
            "Block": "101",
            "label": "Annie Besant MS, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4831",
            "district": "10",
            "Block": "296",
            "label": "St.Mary's MHSS Sriperumbudur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4839",
            "district": "10",
            "Block": "101",
            "label": "Bharathidasan MHSS, Orikkai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4858",
            "district": "10",
            "Block": "146",
            "label": "Little Flower MHSS Kundrathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4971",
            "district": "10",
            "Block": "410",
            "label": "M C A MATRIC HR SEC SCHOOL, PALAYASEEVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5027",
            "district": "10",
            "Block": "101",
            "label": "M.L.M. Mamallan MHSS, Kanchipuram 631502",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5252",
            "district": "10",
            "Block": "410",
            "label": "PUMS, PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5285",
            "district": "10",
            "Block": "146",
            "label": "ANNAI THERESA MAT. HR.SEC. SCHOOL, IYYAPANTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5310",
            "district": "10",
            "Block": "101",
            "label": "SSKV Mat.HSS., Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5343",
            "district": "10",
            "Block": "146",
            "label": "ROSHNI MATRICULATION SCHOOL, KUTHNOOR KAVANUR (POST)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23699",
            "district": "10",
            "Block": "296",
            "label": "st.vincent matriculation school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "5349",
            "district": "10",
            "Block": "101",
            "label": "Pallavan MS, Kolivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5361",
            "district": "10",
            "Block": "101",
            "label": "PUMS,PONNIYAMMAN PATTARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5366",
            "district": "10",
            "Block": "377",
            "label": "Servasev Vidhyalaya Matriculation Higher Secondary School, Manithottam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5374",
            "district": "10",
            "Block": "101",
            "label": "PUMS, DAMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5383",
            "district": "10",
            "Block": "146",
            "label": "M.S.J.M. HinduVid MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5453",
            "district": "10",
            "Block": "410",
            "label": "St.Joseph Matriculation Higher Secondary School, Walajapad",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5473",
            "district": "10",
            "Block": "101",
            "label": "Venkateswara MS Orikkai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5496",
            "district": "10",
            "Block": "146",
            "label": "Nav Bharath MHSS Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5507",
            "district": "10",
            "Block": "146",
            "label": "PUMS IRANTAMKATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5692",
            "district": "10",
            "Block": "377",
            "label": "SERVASEV VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, KUNNAVAKKAM-603107",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6065",
            "district": "10",
            "Block": "101",
            "label": "Cholan Mat.HSS,Sembarambakkam-631552",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6097",
            "district": "10",
            "Block": "410",
            "label": "Agathiya Matriculation HSS, Walajabad 631605",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6301",
            "district": "10",
            "Block": "410",
            "label": "Devi Matriculation School,Vengudi,Walajabad 631605",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6486",
            "district": "10",
            "Block": "410",
            "label": "RCMMS OTTANTHANGAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "23673",
            "district": "10",
            "Block": "101",
            "label": "Shree Sangalpa School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "6565",
            "district": "10",
            "Block": "410",
            "label": "PUMS AYMICHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6600",
            "district": "10",
            "Block": "146",
            "label": "Mangadu public school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6692",
            "district": "10",
            "Block": "101",
            "label": "Sree Narayana Guru MHSS, Big Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7546",
            "district": "10",
            "Block": "101",
            "label": "GHSS DAMAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7547",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KILAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7548",
            "district": "10",
            "Block": "101",
            "label": "PUMS MUSARAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7549",
            "district": "10",
            "Block": "101",
            "label": "GHSS, Musaravakkam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7550",
            "district": "10",
            "Block": "101",
            "label": "PUMS,PERUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7551",
            "district": "10",
            "Block": "101",
            "label": "GHS, Vishar",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7552",
            "district": "10",
            "Block": "101",
            "label": "GHSS,THIRUPPUTKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7553",
            "district": "10",
            "Block": "101",
            "label": "PUMS,Muttavakkam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7554",
            "district": "10",
            "Block": "101",
            "label": "PUMS VATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7555",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KOORAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7556",
            "district": "10",
            "Block": "101",
            "label": "PUMS, Kilambi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7557",
            "district": "10",
            "Block": "101",
            "label": "Sri Uma Vid. MHSS, Keelambi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7558",
            "district": "10",
            "Block": "101",
            "label": "Janus Global School, CBSE, Kilambi",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7559",
            "district": "10",
            "Block": "101",
            "label": "PUMS, SIRUKAVERIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7560",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KILKADIRPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7561",
            "district": "10",
            "Block": "101",
            "label": "PUMS, METTUKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7562",
            "district": "10",
            "Block": "101",
            "label": "GHSS AYYANGARKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7563",
            "district": "10",
            "Block": "101",
            "label": "PUMS, VALATHOTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7564",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KALAKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7565",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KURUVIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7566",
            "district": "10",
            "Block": "101",
            "label": "GHS., Periyanatham",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7567",
            "district": "10",
            "Block": "101",
            "label": "PUMS MARIYAMMANNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7568",
            "district": "10",
            "Block": "101",
            "label": "ADWHS ORIKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7569",
            "district": "10",
            "Block": "101",
            "label": "GHS For the Hearing Impaired, ORIKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7570",
            "district": "10",
            "Block": "101",
            "label": "RCM AIDED HS, VIPPEDU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7571",
            "district": "10",
            "Block": "101",
            "label": "PUMS, VISHNU KANCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7572",
            "district": "10",
            "Block": "101",
            "label": "PUMS,THIMMASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7573",
            "district": "10",
            "Block": "101",
            "label": "GHS THIRUKALIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7574",
            "district": "10",
            "Block": "101",
            "label": "PUMS,ANGAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7575",
            "district": "10",
            "Block": "101",
            "label": "GHSS, Avalur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7576",
            "district": "10",
            "Block": "101",
            "label": "Prashanthi Gurukulam CBSE School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7577",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KAMMARASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7578",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KAVANTHANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7579",
            "district": "10",
            "Block": "101",
            "label": "GHSS ARPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7580",
            "district": "10",
            "Block": "101",
            "label": "Grace Carmel Matriculation School, Arpakkam-631603",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7581",
            "district": "10",
            "Block": "101",
            "label": "PUMS,MAGARAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7582",
            "district": "10",
            "Block": "101",
            "label": "PUMS THENAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7583",
            "district": "10",
            "Block": "101",
            "label": "Infant Jesus Mat.HSS, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7584",
            "district": "10",
            "Block": "101",
            "label": "GHS NASARATHPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7585",
            "district": "10",
            "Block": "101",
            "label": "PUMS COLLECTORS COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7586",
            "district": "10",
            "Block": "101",
            "label": "GHS COLLECTORATE COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7587",
            "district": "10",
            "Block": "101",
            "label": "Swami Vivekananda MHSS,Sevilimedu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7588",
            "district": "10",
            "Block": "101",
            "label": "Bharathi MS, NGO Colony Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7589",
            "district": "10",
            "Block": "101",
            "label": "GHS SEVILIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7590",
            "district": "10",
            "Block": "101",
            "label": "MMS OLIMUGAMADUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7591",
            "district": "10",
            "Block": "101",
            "label": "MMS. KVK., Kanchipuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7592",
            "district": "10",
            "Block": "101",
            "label": "PTVS Matriculation school,Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7593",
            "district": "10",
            "Block": "101",
            "label": "PTVS (Aided) HS, Kanchipuram",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7594",
            "district": "10",
            "Block": "101",
            "label": "MariyaAuxilium(Aided)HSS(G),Kanchi",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7595",
            "district": "10",
            "Block": "101",
            "label": "MMS,ALADI KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7596",
            "district": "10",
            "Block": "101",
            "label": "SSKV(G) HSS,KANCHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7597",
            "district": "10",
            "Block": "101",
            "label": "CSI (Aided) MS, Kanchipuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7598",
            "district": "10",
            "Block": "101",
            "label": "SRI DHANDAPANI ORIENTAL AIDED HSS, KANCHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7599",
            "district": "10",
            "Block": "101",
            "label": "CSI (AIDED) HS(G) - KANCHEEPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7600",
            "district": "10",
            "Block": "101",
            "label": "MMS, OKKAPIRANDAN ,KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7601",
            "district": "10",
            "Block": "101",
            "label": "MMS YAGASALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7602",
            "district": "10",
            "Block": "101",
            "label": "GHSS(Girls)., Big Kanchipuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7603",
            "district": "10",
            "Block": "101",
            "label": "Dr.PSS.Mpl.HSS., Kanchipuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7604",
            "district": "10",
            "Block": "101",
            "label": "Anderson (Aided) HSS(B), Kanchi",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7605",
            "district": "10",
            "Block": "101",
            "label": "Khatri Mat.HS., Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7606",
            "district": "10",
            "Block": "101",
            "label": "Rani Annadurai Mpl.HSS(G),Kanchipuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7607",
            "district": "10",
            "Block": "101",
            "label": "MMS MUTHUCETTIYAAR, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7608",
            "district": "10",
            "Block": "101",
            "label": "CSI  MIDDLE SCHOOL, CENTRAL KANCHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7609",
            "district": "10",
            "Block": "101",
            "label": "Thiyagi (Aided) MS, Kanchipuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7610",
            "district": "10",
            "Block": "101",
            "label": "Pachaiyappa's HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7611",
            "district": "10",
            "Block": "101",
            "label": "Victoria Mat.HSS., Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7612",
            "district": "10",
            "Block": "101",
            "label": "MMS, MELPILLAIYAR PALAYAM, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7613",
            "district": "10",
            "Block": "101",
            "label": "PACHAIYAPPA'S BRANCH, KANCHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7614",
            "district": "10",
            "Block": "101",
            "label": "MMS EKAMBARA SANNADHI, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7615",
            "district": "10",
            "Block": "101",
            "label": "BMS GGHSS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7616",
            "district": "10",
            "Block": "101",
            "label": "CSMHSS,Pillayarpalayam, Kanchipuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7617",
            "district": "10",
            "Block": "101",
            "label": "Sundar Mission MHSS, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7618",
            "district": "10",
            "Block": "101",
            "label": "Sri Krishna (Aided) MS, Kanchipuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7619",
            "district": "10",
            "Block": "101",
            "label": "MMS VAIGUNDAM, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7620",
            "district": "10",
            "Block": "101",
            "label": "SRI VANI NILAYAM HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7621",
            "district": "10",
            "Block": "101",
            "label": "Sri Ekambarapuram (Aided) MS, Kanchi",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7622",
            "district": "10",
            "Block": "101",
            "label": "CMS GHSS, Kancheepuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7623",
            "district": "10",
            "Block": "101",
            "label": "A.K.T.MPL HS, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7624",
            "district": "10",
            "Block": "101",
            "label": "Cholan MHSS,  Kanchipuram-631502",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7625",
            "district": "10",
            "Block": "101",
            "label": "Aruna Matriculation school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7626",
            "district": "10",
            "Block": "101",
            "label": "SRI RAMANUJA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7627",
            "district": "10",
            "Block": "410",
            "label": "GHS, VAIYAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7628",
            "district": "10",
            "Block": "410",
            "label": "PUMS MUTHIYALPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7629",
            "district": "10",
            "Block": "410",
            "label": "PUMS VILLIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7630",
            "district": "10",
            "Block": "410",
            "label": "GHSS, NAICKENPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7631",
            "district": "10",
            "Block": "410",
            "label": "GGHSS,EKANAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7632",
            "district": "10",
            "Block": "410",
            "label": "PUMS POOSIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7633",
            "district": "10",
            "Block": "410",
            "label": "PUMS KEEL OTTIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7634",
            "district": "10",
            "Block": "410",
            "label": "THIRU KANNABIRAN MS PULIAMBAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7635",
            "district": "10",
            "Block": "410",
            "label": "PUMS NATHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7636",
            "district": "10",
            "Block": "410",
            "label": "VSA GHS, UTHUKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7637",
            "district": "10",
            "Block": "410",
            "label": "PUMS KALIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7638",
            "district": "10",
            "Block": "410",
            "label": "Good Shephered Matriculation School, Vaiyavoor",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7639",
            "district": "10",
            "Block": "410",
            "label": "GHS, ENATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7640",
            "district": "10",
            "Block": "410",
            "label": "PUMS SITTIYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7641",
            "district": "10",
            "Block": "410",
            "label": "GHSS, THENNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7642",
            "district": "10",
            "Block": "410",
            "label": "ADWMS THALAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7643",
            "district": "10",
            "Block": "410",
            "label": "GHSS, SINGADIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7644",
            "district": "10",
            "Block": "410",
            "label": "PUMS ATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7645",
            "district": "10",
            "Block": "410",
            "label": "PUMS KOOTHIRAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7646",
            "district": "10",
            "Block": "410",
            "label": "GHS KARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7647",
            "district": "10",
            "Block": "410",
            "label": "PUMS SIRUVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7648",
            "district": "10",
            "Block": "410",
            "label": "ADW HSS, INJAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7649",
            "district": "10",
            "Block": "410",
            "label": "PUMS VELIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7650",
            "district": "10",
            "Block": "410",
            "label": "CN(AIDED) MS,UVERICHATTRAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "7651",
            "district": "10",
            "Block": "410",
            "label": "GHSS, GOVINDAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7652",
            "district": "10",
            "Block": "410",
            "label": "RAMAKRISHNA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL PADUNELLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7653",
            "district": "10",
            "Block": "410",
            "label": "GHSS, PULLALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7654",
            "district": "10",
            "Block": "410",
            "label": "PUMS SAMANTHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7655",
            "district": "10",
            "Block": "410",
            "label": "PUMS KOTTAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7656",
            "district": "10",
            "Block": "410",
            "label": "GHS, PURISAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7657",
            "district": "10",
            "Block": "410",
            "label": "PUMS VALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7658",
            "district": "10",
            "Block": "410",
            "label": "PUMS KATTUPATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7659",
            "district": "10",
            "Block": "410",
            "label": "GHSS, PARANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7660",
            "district": "10",
            "Block": "410",
            "label": "PUMS NEERVALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7661",
            "district": "10",
            "Block": "410",
            "label": "PUMS ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7662",
            "district": "10",
            "Block": "410",
            "label": "PUMS KUNNAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7663",
            "district": "10",
            "Block": "410",
            "label": "A.P.N.VIDHYALAYA MATRICULATION HR SEC SCHOOL, VARANAVASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7664",
            "district": "10",
            "Block": "410",
            "label": "GHS, ULLAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7665",
            "district": "10",
            "Block": "410",
            "label": "GHS, DEVERIAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7666",
            "district": "10",
            "Block": "410",
            "label": "PUMS PALAYASEEVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7667",
            "district": "10",
            "Block": "410",
            "label": "PUMS, SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7668",
            "district": "10",
            "Block": "410",
            "label": "RCM AIDED HS, WALAJABAD",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7669",
            "district": "10",
            "Block": "410",
            "label": "DSSN(AIDED) MS,WALAJABAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7670",
            "district": "10",
            "Block": "410",
            "label": "ARIGNAR ANNA GOVT BOYS HR SEC SCHOOL WALAJABAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7671",
            "district": "10",
            "Block": "410",
            "label": "GGHSS, WALAJABAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7672",
            "district": "10",
            "Block": "410",
            "label": "W.T.Masilamani Mudaliyar Hr Sec School",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7673",
            "district": "10",
            "Block": "410",
            "label": "GHSS, IYYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7674",
            "district": "10",
            "Block": "377",
            "label": "PUMS, KARUVEPPAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7675",
            "district": "10",
            "Block": "377",
            "label": "GHSS, Kaliyampoondi",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7676",
            "district": "10",
            "Block": "377",
            "label": "PUMS, AZHISOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7677",
            "district": "10",
            "Block": "377",
            "label": "PUMS, ELANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7678",
            "district": "10",
            "Block": "377",
            "label": "GOVT MODEL HR SEC SCHOOL, PERUNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7679",
            "district": "10",
            "Block": "377",
            "label": "R.C MIDDLE SCHOOL , MANAMPATHY KANDIGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7680",
            "district": "10",
            "Block": "377",
            "label": "GHSS,MANAMPATHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7681",
            "district": "10",
            "Block": "377",
            "label": "PUMS, VISOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7682",
            "district": "10",
            "Block": "377",
            "label": "ST.JOSEPH (AIDED)HS, R.N.KANDIGAI, UTHIRAMERUR TK",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7683",
            "district": "10",
            "Block": "377",
            "label": "PUMS, PENNALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7684",
            "district": "10",
            "Block": "377",
            "label": "GHS,KATTUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7685",
            "district": "10",
            "Block": "377",
            "label": "PUMS, ARASANIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7686",
            "district": "10",
            "Block": "377",
            "label": "PUMS, AGARAMDULY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7687",
            "district": "10",
            "Block": "377",
            "label": "PUMS KAVANURPUDHUCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7688",
            "district": "10",
            "Block": "377",
            "label": "GHSS,KAMMALAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7689",
            "district": "10",
            "Block": "377",
            "label": "Asefa HS-kattukollai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7690",
            "district": "10",
            "Block": "377",
            "label": "Meenakshi Ammal Matric Hr Sec School Perunkozhi ,UTHIRAMERUR 603406",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7691",
            "district": "10",
            "Block": "377",
            "label": "Sarva Seva HS, Chinnamakulam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7692",
            "district": "10",
            "Block": "377",
            "label": "PUMS, MARUTHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7693",
            "district": "10",
            "Block": "377",
            "label": "GHSS THIRUPULIVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7694",
            "district": "10",
            "Block": "377",
            "label": "GHSS NEIYADUPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7695",
            "district": "10",
            "Block": "377",
            "label": "PUMS MALAYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7696",
            "district": "10",
            "Block": "377",
            "label": "PEERY MHSS THOTTANAVAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7697",
            "district": "10",
            "Block": "377",
            "label": "GOVT ADW HR SEC SCHOOL RETTAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7698",
            "district": "10",
            "Block": "377",
            "label": "PUMS, KUNNAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7699",
            "district": "10",
            "Block": "377",
            "label": "GHS ANAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7700",
            "district": "10",
            "Block": "377",
            "label": "PUMS VAYALAKKAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7701",
            "district": "10",
            "Block": "377",
            "label": "GHS THIRUMUKKUDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7702",
            "district": "10",
            "Block": "377",
            "label": "PUMS, PINAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7703",
            "district": "10",
            "Block": "377",
            "label": "GHS,SEETANANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7704",
            "district": "10",
            "Block": "377",
            "label": "PUMS, SATHANANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7705",
            "district": "10",
            "Block": "377",
            "label": "GHSS,ORAKKATTUPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7706",
            "district": "10",
            "Block": "377",
            "label": "AMALA ANNAI (AIDED) Higher Sec School,MAMBAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7707",
            "district": "10",
            "Block": "377",
            "label": "PUMS, EDAMACHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7708",
            "district": "10",
            "Block": "377",
            "label": "PUMS THIRUVANTHAVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7709",
            "district": "10",
            "Block": "377",
            "label": "GHSS,SALAVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7710",
            "district": "10",
            "Block": "377",
            "label": "PUMS, OZHAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7711",
            "district": "10",
            "Block": "377",
            "label": "ASEFA HS, MEYYURODAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7712",
            "district": "10",
            "Block": "377",
            "label": "GBHSS,UTHIRAMERUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7713",
            "district": "10",
            "Block": "377",
            "label": "PANCHAYAT UNION MODEL MIDDLE SCHOOL, UTHIRAMERUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7714",
            "district": "10",
            "Block": "377",
            "label": "GGHSS,UTHIRAMERUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7715",
            "district": "10",
            "Block": "377",
            "label": "PALARNESAN AIDED MIDDLE SCHOOL - UTHIRAMERUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7716",
            "district": "10",
            "Block": "377",
            "label": "PUMS, VADANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7717",
            "district": "10",
            "Block": "377",
            "label": "RAMAKRISHNA MISSION HIGH SCHOOL, MALLIANKARANAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7718",
            "district": "10",
            "Block": "377",
            "label": "LOYOLA HSS, KUPPAYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7719",
            "district": "10",
            "Block": "377",
            "label": "PUMS , VEDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7720",
            "district": "10",
            "Block": "377",
            "label": "PUMS, 1-3 WARD, UTHIRAMERUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7721",
            "district": "10",
            "Block": "296",
            "label": "GHS, PITCHIVAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7722",
            "district": "10",
            "Block": "296",
            "label": "PUMS O M MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7723",
            "district": "10",
            "Block": "296",
            "label": "GHS, EDAYARPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7724",
            "district": "10",
            "Block": "296",
            "label": "PUMS EGANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7725",
            "district": "10",
            "Block": "296",
            "label": "PUMS KOOTHAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7726",
            "district": "10",
            "Block": "296",
            "label": "GHSS, MADURAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7727",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL,KEERANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7728",
            "district": "10",
            "Block": "296",
            "label": "PUMS,PODAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7729",
            "district": "10",
            "Block": "296",
            "label": "GHSS, MOLACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7730",
            "district": "10",
            "Block": "296",
            "label": "MORNING STAR MATRICULATION SCHOOL, MOLACHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7731",
            "district": "10",
            "Block": "296",
            "label": "PUMS S V CHATRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7732",
            "district": "10",
            "Block": "296",
            "label": "MAHARISHI INTL.RESI.,SANTHAVELLORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7733",
            "district": "10",
            "Block": "296",
            "label": "GHS, SANTHAVELLORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7734",
            "district": "10",
            "Block": "296",
            "label": "D.A.V.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7735",
            "district": "10",
            "Block": "296",
            "label": "GHS, KUNNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7736",
            "district": "10",
            "Block": "296",
            "label": "PUMS ECHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7737",
            "district": "10",
            "Block": "296",
            "label": "PUMS - THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7738",
            "district": "10",
            "Block": "296",
            "label": "PUMS MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23413",
            "district": "10",
            "Block": "296",
            "label": "ST.THOMAS MATRICULATION  SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7739",
            "district": "10",
            "Block": "296",
            "label": "PUMS PONDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7740",
            "district": "10",
            "Block": "296",
            "label": "PUMS VADAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7741",
            "district": "10",
            "Block": "296",
            "label": "ADWMS- KILOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7742",
            "district": "10",
            "Block": "296",
            "label": "MARTHOMA PUPLIC NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7743",
            "district": "10",
            "Block": "296",
            "label": "PUMS AYAKOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7744",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL SENGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7745",
            "district": "10",
            "Block": "296",
            "label": "PUMS NEMILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7746",
            "district": "10",
            "Block": "296",
            "label": "ST.ANNES (AIDED) HIGH SCHOOL, VALARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7747",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL, MEVALURKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7748",
            "district": "10",
            "Block": "296",
            "label": "APPOLLO VIDYASHRAM MEVALURKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7749",
            "district": "10",
            "Block": "296",
            "label": "GHSS 101 ZAMIN THANDALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7750",
            "district": "10",
            "Block": "296",
            "label": "PUMS-IRUNKATTUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7751",
            "district": "10",
            "Block": "296",
            "label": "PUMS PENNALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7752",
            "district": "10",
            "Block": "296",
            "label": "PUMS PUDUPPER",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7753",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL VENGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7754",
            "district": "10",
            "Block": "296",
            "label": "PUMS PILLAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7755",
            "district": "10",
            "Block": "296",
            "label": "GHS, KOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7756",
            "district": "10",
            "Block": "296",
            "label": "GHS, MAGANYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7757",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL GUNDUPERUMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7758",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL. PANRUTTI - 631 604.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7759",
            "district": "10",
            "Block": "296",
            "label": "PUMS, ERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7760",
            "district": "10",
            "Block": "296",
            "label": "L. F. H. S. S (Aided) THERESAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7761",
            "district": "10",
            "Block": "296",
            "label": "P. S. TEMPLE GREEN VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7762",
            "district": "10",
            "Block": "296",
            "label": "TSK GOVERNMENT HIGHER SECONDARY SCHOOL MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7763",
            "district": "10",
            "Block": "296",
            "label": "P.U.M.S.GOVT.EMPLOYERS QUARTERS.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7764",
            "district": "10",
            "Block": "296",
            "label": "PUMS SPR SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7765",
            "district": "10",
            "Block": "296",
            "label": "J J GBHSS SRIPERUMBUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7766",
            "district": "10",
            "Block": "296",
            "label": "GHSS (GIRLS) SRIPERUMBUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7767",
            "district": "10",
            "Block": "296",
            "label": "CSI HIGH SCHOOL SRIPERUMBUDUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7768",
            "district": "10",
            "Block": "296",
            "label": "VIVEKANANDA VIDYALAYA MATRIC . HR. SEC. SCHOOL, SRIPERUMBUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7769",
            "district": "10",
            "Block": "296",
            "label": "HAYAGRIVA VIDHYASHRAM SENIOR SECONDARY SCHOOL. SRIPERUMBUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7770",
            "district": "10",
            "Block": "296",
            "label": "ST.JOSEPH'S RESIDENTIAL SCHOOL SRIPERUMBUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7771",
            "district": "10",
            "Block": "296",
            "label": "KSHETRA VIDYALAYA,MADUVANKARAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7772",
            "district": "10",
            "Block": "146",
            "label": "GHS PERIAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7773",
            "district": "10",
            "Block": "146",
            "label": "Madha MATRIC HSS Kundrathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7774",
            "district": "10",
            "Block": "146",
            "label": "GHS SIRUKALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7775",
            "district": "10",
            "Block": "146",
            "label": "Sri Arabindo MHSS, Malayambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7776",
            "district": "10",
            "Block": "146",
            "label": "GHS Malayambakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7777",
            "district": "10",
            "Block": "146",
            "label": "PUMS Kolumanivakkam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7778",
            "district": "10",
            "Block": "146",
            "label": "Panchayat union middle school,PARANIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7779",
            "district": "10",
            "Block": "146",
            "label": "PUMS ANNAI INDRA NAGAR PARANIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7780",
            "district": "10",
            "Block": "146",
            "label": "GHSS IYYAPANTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7781",
            "district": "10",
            "Block": "146",
            "label": "GOVT.ADW.HSS.,MOULIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7782",
            "district": "10",
            "Block": "146",
            "label": "PUMS  MATHANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7783",
            "district": "10",
            "Block": "146",
            "label": "GHS MUGALIVAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7784",
            "district": "10",
            "Block": "146",
            "label": "CVC TRUST MS, KOLAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7785",
            "district": "10",
            "Block": "146",
            "label": "ST FRANCIS INTERNATIONAL SCHOOL KOLAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7786",
            "district": "10",
            "Block": "146",
            "label": "PON VIDHYASHRAM KOLAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7787",
            "district": "10",
            "Block": "146",
            "label": "SARASWATHY VIDHYALAYA MATRICULATION   HIGHER SECONDARY SCHOOL ,GERUGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7788",
            "district": "10",
            "Block": "146",
            "label": "THE PSBB MILLENNIUM SCHOOL GERUGAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7789",
            "district": "10",
            "Block": "146",
            "label": "GHS GERUGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7790",
            "district": "10",
            "Block": "146",
            "label": "ADWHS THANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7791",
            "district": "10",
            "Block": "146",
            "label": "GBHSS KOVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7792",
            "district": "10",
            "Block": "146",
            "label": "PUMS PUDUVATTARAM KOVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7793",
            "district": "10",
            "Block": "146",
            "label": "G(G)HSS, Kovur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7794",
            "district": "10",
            "Block": "146",
            "label": "Sri Krish International School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7795",
            "district": "10",
            "Block": "146",
            "label": "GHSS THIRUMUDIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7796",
            "district": "10",
            "Block": "146",
            "label": "SREE SHANTHI ANAND VIDYALAYA(CBSE),THIRUMUDIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7797",
            "district": "10",
            "Block": "146",
            "label": "PUMS PAZHANTHANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7798",
            "district": "10",
            "Block": "146",
            "label": "RCM HSS(Aided), Naduveerapattu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7799",
            "district": "10",
            "Block": "146",
            "label": "Good Earth School, Naduveerapatu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7800",
            "district": "10",
            "Block": "146",
            "label": "ST JOSEPH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7801",
            "district": "10",
            "Block": "146",
            "label": "SAIRAM MHSS, POONTHANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7802",
            "district": "10",
            "Block": "146",
            "label": "GHS NALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7803",
            "district": "10",
            "Block": "146",
            "label": "GHSS, Somangalam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7804",
            "district": "10",
            "Block": "146",
            "label": "VEDAVALLI VIDYA MANDIR SENIOR SECONDARY SCHOOL, SOMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7805",
            "district": "10",
            "Block": "146",
            "label": "GHS MANIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7806",
            "district": "10",
            "Block": "146",
            "label": "PUMS Karasangal",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7807",
            "district": "10",
            "Block": "146",
            "label": "pums Keezhpadappai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7808",
            "district": "10",
            "Block": "146",
            "label": "pums melpadappai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7809",
            "district": "10",
            "Block": "146",
            "label": "G(B)HSS, Padappai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7810",
            "district": "10",
            "Block": "146",
            "label": "St.MARY MAT SCHOOL,PADAPPAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7811",
            "district": "10",
            "Block": "146",
            "label": "EVEREST VID.MAT,PADAPPAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7812",
            "district": "10",
            "Block": "146",
            "label": "G(G)HSS, PADAPPAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7813",
            "district": "10",
            "Block": "146",
            "label": "PUMS Ezhichur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7814",
            "district": "10",
            "Block": "146",
            "label": "GOVERNMENT HIGH SCHOOL VADAKUPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7815",
            "district": "10",
            "Block": "146",
            "label": "Thriveni Academy Vadakkupattu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7816",
            "district": "10",
            "Block": "146",
            "label": "ADWHS Chennakuppam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7817",
            "district": "10",
            "Block": "146",
            "label": "P.U.M.S , Salamangalam .",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7818",
            "district": "10",
            "Block": "146",
            "label": "pums Athanancheri",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7819",
            "district": "10",
            "Block": "146",
            "label": "PUMS , Serappanancheri .",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7820",
            "district": "10",
            "Block": "146",
            "label": "Bharrathi Vidyashram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7821",
            "district": "10",
            "Block": "146",
            "label": "Ghs, Umaiyalparanacheri",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7822",
            "district": "10",
            "Block": "146",
            "label": "GHS,NATTARASANPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7823",
            "district": "10",
            "Block": "146",
            "label": "PUMS Orathur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7824",
            "district": "10",
            "Block": "146",
            "label": "G.T.M.N GOVT AIDED MIDDLE SCHOOL KAVANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7825",
            "district": "10",
            "Block": "146",
            "label": "Government High School,Madambakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7826",
            "district": "10",
            "Block": "146",
            "label": "PUMS MANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7827",
            "district": "10",
            "Block": "146",
            "label": "GHS,MANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7828",
            "district": "10",
            "Block": "146",
            "label": "Dawn MHSS Pattur Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7829",
            "district": "10",
            "Block": "146",
            "label": "St.Mary's MS Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7830",
            "district": "10",
            "Block": "146",
            "label": "St.Annes Metric School Ragunathapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7831",
            "district": "10",
            "Block": "146",
            "label": "Mukkala Nammalvar Chetty school",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7832",
            "district": "10",
            "Block": "146",
            "label": "Valluvar(Aided)MS,Thirunageswaram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23373",
            "district": "10",
            "Block": "101",
            "label": "USHA MATRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23389",
            "district": "10",
            "Block": "101",
            "label": "kattai koothu gurukul",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23394",
            "district": "10",
            "Block": "410",
            "label": "GOOD SHEPHARD MATRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7833",
            "district": "10",
            "Block": "146",
            "label": "GHSS (Girls), Kundrathur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7834",
            "district": "10",
            "Block": "146",
            "label": "GHSS (Boys), Kundrathur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7835",
            "district": "10",
            "Block": "146",
            "label": "Panchayath Union Middle School, Manappakkam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7836",
            "district": "10",
            "Block": "146",
            "label": "DR.MGR HOME AND HIGHER SECONDARY SCHOOL FOR THE SPEECH AND HEARING IMPAIRED MANAPAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7837",
            "district": "10",
            "Block": "146",
            "label": "PUMS  Sikkarayapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7838",
            "district": "10",
            "Block": "146",
            "label": "LOYOLA ACADEMY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7948",
            "district": "10",
            "Block": "146",
            "label": "Loyola Academy CBSE School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20881",
            "district": "10",
            "Block": "410",
            "label": "SRI JAYENDRA GOLDEN JUBILEE SCHOOL,ENATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20928",
            "district": "10",
            "Block": "410",
            "label": "Sri Sairam Inter. Matric school, Valluvapakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21049",
            "district": "10",
            "Block": "296",
            "label": "RYAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21147",
            "district": "10",
            "Block": "146",
            "label": "S. R. N. M. MATRICULATION HIGHER SECONDARY SCHOOL, NANDAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21197",
            "district": "10",
            "Block": "146",
            "label": "GHSS KOLAPPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21236",
            "district": "10",
            "Block": "101",
            "label": "Royal Matriculation Higher Secondary School Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21297",
            "district": "10",
            "Block": "377",
            "label": "MEENAKSHI AMMAL GLOBAL SCHOOL PERUNGOZHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21298",
            "district": "10",
            "Block": "296",
            "label": "Maharashi Vidha Mandir School, Irunkattukottai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21425",
            "district": "10",
            "Block": "101",
            "label": "GHS, Thammanur",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21426",
            "district": "10",
            "Block": "146",
            "label": "GHS PATTUR MUSLIM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21525",
            "district": "10",
            "Block": "296",
            "label": "RCM (Aided) HS,Chellampattidai",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21572",
            "district": "10",
            "Block": "410",
            "label": "Jei Mathajee CBSE School, Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21580",
            "district": "10",
            "Block": "410",
            "label": "Beta Public School, Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21643",
            "district": "10",
            "Block": "410",
            "label": "Saraswathi Vidyalaya Matric School, Enathur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21733",
            "district": "10",
            "Block": "146",
            "label": "Alwin International Public School, Kzheel Padappai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21734",
            "district": "10",
            "Block": "146",
            "label": "Velammal Vidhyashram, Salamangalam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21735",
            "district": "10",
            "Block": "146",
            "label": "Peace on Green Earth Public School, Karimanagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21736",
            "district": "10",
            "Block": "146",
            "label": "Sivanthi Public School, Kundrathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21737",
            "district": "10",
            "Block": "146",
            "label": "BSSK Marticulation School, Varatharajapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21738",
            "district": "10",
            "Block": "101",
            "label": "Gurukshetra Public School-KONERIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21739",
            "district": "10",
            "Block": "101",
            "label": "SSKV Boys Matric  HrSS Kanchipuram 631501",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21740",
            "district": "10",
            "Block": "101",
            "label": "Dhwarkesh Vidhyashram CBSE School, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21901",
            "district": "10",
            "Block": "377",
            "label": "Adithyaa Matriculation School, Perunagar",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21940",
            "district": "10",
            "Block": "101",
            "label": "New Bharathidasan Mat.HSS, Orikkai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21949",
            "district": "10",
            "Block": "101",
            "label": "Kanchi Global Matriculation Hr.Sec.School, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22019",
            "district": "10",
            "Block": "101",
            "label": "ADWHS, Melottivakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22020",
            "district": "10",
            "Block": "101",
            "label": "Brindavan Anglo Vedic International School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22021",
            "district": "10",
            "Block": "410",
            "label": "AMIRTHAM MATRIC SCHOOL,VENGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22022",
            "district": "10",
            "Block": "296",
            "label": "COSMOPOLITAN MODEL VIDYALYA, SENGADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22023",
            "district": "10",
            "Block": "296",
            "label": "LOYOLA INTERNATIONAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22024",
            "district": "10",
            "Block": "296",
            "label": "Montfort School, Theresapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22025",
            "district": "10",
            "Block": "146",
            "label": "Annai Theresa Matriculation School, Bharaniputhur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22026",
            "district": "10",
            "Block": "146",
            "label": "NARAYANA E- TECHNO SCHOOL, MS NAGAR, CHENNAI TN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22027",
            "district": "10",
            "Block": "146",
            "label": "VELAMMAL BODHI CAMPUS SCHOOL  KOLAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22028",
            "district": "10",
            "Block": "146",
            "label": "Holy Sai International School, Adhanur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22029",
            "district": "10",
            "Block": "146",
            "label": "RISHS International  School, Mangadu",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22337",
            "district": "10",
            "Block": "101",
            "label": "Infini International CBSE School, Kalakkattur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22338",
            "district": "10",
            "Block": "101",
            "label": "Victoria Matriculation School, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22339",
            "district": "10",
            "Block": "410",
            "label": "Mahatma Gandhi Matric School, Kaliyanur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22340",
            "district": "10",
            "Block": "410",
            "label": "Kanchi Vani Vidyalaya, Enathur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22341",
            "district": "10",
            "Block": "296",
            "label": "GHS KATRAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22342",
            "district": "10",
            "Block": "296",
            "label": "The Schram Academy, Irumgattukottai",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22343",
            "district": "10",
            "Block": "296",
            "label": "Swami Vivekananda Vidya Peedam, Sriperumbudur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22344",
            "district": "10",
            "Block": "146",
            "label": "Sri Ayyappan Vidyal Matric School, Kovur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22345",
            "district": "10",
            "Block": "146",
            "label": "Jain Public School, Thirumudivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22346",
            "district": "10",
            "Block": "146",
            "label": "BUDDING MINDS INTERNATIONAL SCHOOL, Manimangalam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22347",
            "district": "10",
            "Block": "146",
            "label": "Velammal Vidyalaya, Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22348",
            "district": "10",
            "Block": "146",
            "label": "Maharishi vidhya mandir",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22349",
            "district": "10",
            "Block": "146",
            "label": "Shree Niketan Patasala CBSE School, Varatharajapuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22686",
            "district": "10",
            "Block": "146",
            "label": "SRI CHAITANYA TECHNO SCHOOL, MANAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22743",
            "district": "10",
            "Block": "146",
            "label": "Swamy's school , santhosh nagar Madhanandhapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22745",
            "district": "10",
            "Block": "146",
            "label": "Swamy's school , Gurusamy nagar Madhanandhapuram",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22752",
            "district": "10",
            "Block": "146",
            "label": "Peter International School(CBSE).Kavanur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22753",
            "district": "10",
            "Block": "410",
            "label": "Asian International Residential School. Palayaseevaram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22754",
            "district": "10",
            "Block": "146",
            "label": "sri chaitanya Techno School Iyyappanthangal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22857",
            "district": "10",
            "Block": "146",
            "label": "Amrita Vidyalayam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22898",
            "district": "10",
            "Block": "146",
            "label": "GHS, THUNDALKAZHANI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22988",
            "district": "10",
            "Block": "101",
            "label": "Billabong High International School, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22993",
            "district": "10",
            "Block": "101",
            "label": "Shrishti international school Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23018",
            "district": "10",
            "Block": "146",
            "label": "Tianno vidyashrm",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23025",
            "district": "10",
            "Block": "146",
            "label": "PUMS NAVALUR (PUDHU KUDIYIRUPPU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23104",
            "district": "10",
            "Block": "146",
            "label": "ST JOSEPH MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23174",
            "district": "10",
            "Block": "101",
            "label": "SOORYAN MATRICULATION SCHOOL CHITHERIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23221",
            "district": "10",
            "Block": "146",
            "label": "OLOGY TECH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23339",
            "district": "10",
            "Block": "146",
            "label": "VAGISHA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23487",
            "district": "10",
            "Block": "101",
            "label": "SRI LAKSHMI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23488",
            "district": "10",
            "Block": "410",
            "label": "VELAMMAL BODHI CAMPUS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23851",
            "district": "10",
            "Block": "146",
            "label": "PUMS MANGADU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23852",
            "district": "10",
            "Block": "146",
            "label": "Amrita Vidyalayam",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23853",
            "district": "10",
            "Block": "146",
            "label": "LALAJI MEMORIAL OMEGA INTERNATIONAL SCHOOL (CIS)",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23854",
            "district": "10",
            "Block": "146",
            "label": "KANCHIPURAM DISTRICT GOVERNMENT MODEL SCHOOL, MANIMANGALAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23855",
            "district": "10",
            "Block": "146",
            "label": "SSP VIDHYASHRAM, VARADARAJAPURAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23973",
            "district": "10",
            "Block": "296",
            "label": "ST. VINCENT'S NURSERY AND PRIMARY SCHOOL, MOLASUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23974",
            "district": "10",
            "Block": "296",
            "label": "PUMS MAMBAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23975",
            "district": "10",
            "Block": "296",
            "label": "ST.ANTHONY'S SCHOOL, KUNNAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24092",
            "district": "10",
            "Block": "410",
            "label": "PUMS ALAPAKKAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "11": [
        {
            "key": "551",
            "district": "11",
            "Block": "3",
            "label": "GNANADEEPAM MATRIC HIGHER SECONDARY SCHOOL, AGASTHEESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23654",
            "district": "11",
            "Block": "355",
            "label": "jennifer matriculation higher secondary school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "594",
            "district": "11",
            "Block": "3",
            "label": "CARMEL MATHA MATRIC Hr.Sec.SCHOOL - KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "596",
            "district": "11",
            "Block": "3",
            "label": "E.D.WILLMOTT MEMORIAL S.D.A.MATRIC HSS VETTOORNIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "777",
            "district": "11",
            "Block": "3",
            "label": "PIONEER HSS NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "805",
            "district": "11",
            "Block": "354",
            "label": "RUBEN MATRIC. HR. SEC. SCHOOL - THADIKARANKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "816",
            "district": "11",
            "Block": "149",
            "label": "JOSEPH MATRIC- SASTHANKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "819",
            "district": "11",
            "Block": "3",
            "label": "S.M.MATRIC SCHOOL,MYLAUDY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "829",
            "district": "11",
            "Block": "3",
            "label": "VIVE.KEN.MAT. HSS-KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "842",
            "district": "11",
            "Block": "263",
            "label": "HOLY MOTHER MATRIC SCHOOL, PARVATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "853",
            "district": "11",
            "Block": "263",
            "label": "EVANS MATRIC HR. SEC. SCHOOL, NGO COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "856",
            "district": "11",
            "Block": "354",
            "label": "ST. FRANCIS ASSISI MATRIC SCHOOL- THITTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "897",
            "district": "11",
            "Block": "3",
            "label": "KUMARI MATRICULATION HR. SEC. SCHOOL, KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "929",
            "district": "11",
            "Block": "355",
            "label": "VICTORY MATRIC HIGHER SECONDARY SCHOOL,MYLODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "996",
            "district": "11",
            "Block": "124",
            "label": "SEVENTH DAY ADVENTIST MATRIC HIGH SCHOOL VIRICODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "997",
            "district": "11",
            "Block": "3",
            "label": "SNM HINDU VIDYALAYA MATRIC HSS, KRISHNANCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1008",
            "district": "11",
            "Block": "191",
            "label": "ST. ANTONY'S MATRIC HIGHER SECONDARY SCHOOL, MANAVILA KIRATHOOR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1181",
            "district": "11",
            "Block": "263",
            "label": "SRI RAMJI MATRIC HSS, GANAPATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1206",
            "district": "11",
            "Block": "354",
            "label": "CAROL MATRICULATION HIGHER SECONDARY SCHOOL - THITTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1231",
            "district": "11",
            "Block": "191",
            "label": "SAHAYAMATHA MATRICULATION SCHOOL VAVARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1276",
            "district": "11",
            "Block": "191",
            "label": "SREE KRISHNA HINDU VIDYALAYA M.H.S.S. KANCHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1278",
            "district": "11",
            "Block": "124",
            "label": "SVK MATRICULATION SCHOOL VIRICODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1286",
            "district": "11",
            "Block": "341",
            "label": "ST. THOMAS MARTHOMA MATRICULATION HIGHER SECONDARY SCHOOL, KOLLARAI, KULASEKHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1308",
            "district": "11",
            "Block": "355",
            "label": "ST.MICHAEL'S MATRIC HIGHER SECONDARY SCHOOL,KATTATHURAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1331",
            "district": "11",
            "Block": "3",
            "label": "PONJESLY PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL - NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1366",
            "district": "11",
            "Block": "263",
            "label": "BELFIELD MATRIC HSS, ASARIPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1410",
            "district": "11",
            "Block": "3",
            "label": "SACRED HEART MATRIC HR SEC SCHOOL- AZHAGAPPAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1450",
            "district": "11",
            "Block": "341",
            "label": "MOUNT CARMEL MATRIC HIGHER SECONDARY SCHOOL KOLVEL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1480",
            "district": "11",
            "Block": "149",
            "label": "CARMEL JYOTHI MATRIC HIGHER SECONDARY SCHOOL, VELLIYAKULAM, COLACHEL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1532",
            "district": "11",
            "Block": "354",
            "label": "ISLAMIC SCHOOL-THITTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1559",
            "district": "11",
            "Block": "341",
            "label": "ISLAMIC  MODEL M.S-KULASEKHARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1564",
            "district": "11",
            "Block": "124",
            "label": "BETHLAHEM MATRIC & HR.SEC. SCHOOL,KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1569",
            "district": "11",
            "Block": "3",
            "label": "MALICK DHINAR MATRICULATION  SCHOOL- KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1589",
            "district": "11",
            "Block": "124",
            "label": "GMS MANKANTUVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1625",
            "district": "11",
            "Block": "3",
            "label": "ST TRESA'S MATRIC HR.SEC.SCHOOL- VAZHUKKAMPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1705",
            "district": "11",
            "Block": "191",
            "label": "SR MAT SCHOOL KANJAMPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1731",
            "district": "11",
            "Block": "263",
            "label": "ST.MARY'S MS PILLAITHOPPU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "1740",
            "district": "11",
            "Block": "341",
            "label": "S.R.K.B.V. MAT. HSS., KULASEKHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1795",
            "district": "11",
            "Block": "124",
            "label": "DONBOSCO MATRIC HR.SEC.SCHOOL-MIDALAKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1800",
            "district": "11",
            "Block": "182",
            "label": "KIDS OXFORD MATRIC HR SEC SCHOOL KUZHITHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1812",
            "district": "11",
            "Block": "149",
            "label": "CHRISTIAN MATRICULATION HIGHER SECONDARY SCHOOL,  KALLUKOOTTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1828",
            "district": "11",
            "Block": "191",
            "label": "M.M.K.M. H.S. VARUTHATTU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "1837",
            "district": "11",
            "Block": "263",
            "label": "SACCER MATRICULATION SCHOOL, EAST PULLUVILAI, ERUMBUKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1844",
            "district": "11",
            "Block": "124",
            "label": "SGS MHSS KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1845",
            "district": "11",
            "Block": "3",
            "label": "FATIMA CONVENT MATRICULATION SCHOOL-RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1846",
            "district": "11",
            "Block": "355",
            "label": "DEVI MATRIC SCHOOL RAMANPARAMBU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1882",
            "district": "11",
            "Block": "263",
            "label": "CMC MATRIC HR. SEC. SCHOOL-THAMMATHUKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1901",
            "district": "11",
            "Block": "263",
            "label": "LMS MIDDLE SCHOOL EATHAMOZHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1943",
            "district": "11",
            "Block": "3",
            "label": "ROCK FHELLAR MATRIC.SCHOOL,VADASERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1950",
            "district": "11",
            "Block": "3",
            "label": "SREE NARAYANA GURU MHSS KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1951",
            "district": "11",
            "Block": "182",
            "label": "THE WINNER'S RESIDENTIAL MATRIC HIGHER SECONDARY SCHOOL, KILATHOOR, KALIYAL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1959",
            "district": "11",
            "Block": "354",
            "label": "ANNE'S MATRIC SCHOOL ARALKURUSADY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1962",
            "district": "11",
            "Block": "149",
            "label": "ATHITHIYA MATRIC HSS- AMMANDIVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1998",
            "district": "11",
            "Block": "182",
            "label": "CHRISTHURAJA MA H S S MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2006",
            "district": "11",
            "Block": "263",
            "label": "OUR LADY OF MIRACLE BETHANY MATRIC HIGHER SECONDARY SCHOOL, ANNAI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2018",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  ADAIKKAKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2062",
            "district": "11",
            "Block": "3",
            "label": "ALPHONSA MATRICULATION HIGHER SECONDARY SCHOOL- KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2069",
            "district": "11",
            "Block": "191",
            "label": "ST MARY'S MATRIC. HR. SEC. SCHOOL  MARIAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2091",
            "district": "11",
            "Block": "191",
            "label": "JANET MATRIC HIGHER SECONDARY SCHOOL  MUNCHIRAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2118",
            "district": "11",
            "Block": "263",
            "label": "GMS THAMMATHUKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2125",
            "district": "11",
            "Block": "341",
            "label": "GMS. ANDOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2147",
            "district": "11",
            "Block": "3",
            "label": "GOOD SHEPHERD. MATRICULATION HIGHER SECONDARY SCHOOL - NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2151",
            "district": "11",
            "Block": "124",
            "label": "ROSE OF SHARON THOZHICODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2177",
            "district": "11",
            "Block": "355",
            "label": "GMS THIRUVITHANCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2184",
            "district": "11",
            "Block": "191",
            "label": "MARGREGORIOS MATRIC HSS KIRATHOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2192",
            "district": "11",
            "Block": "149",
            "label": "KALAIVANI MATRIC SCHOOL ,CHETHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2217",
            "district": "11",
            "Block": "263",
            "label": "SWAMY VIVEKANANDA MATRICULATION SCHOOL ATHIKATTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2234",
            "district": "11",
            "Block": "355",
            "label": "CSI PUBLIC MATRIC HR SEC SCHOOL, AZHAGIAMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2242",
            "district": "11",
            "Block": "341",
            "label": "GMS. THOTTAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2288",
            "district": "11",
            "Block": "341",
            "label": "Calvary Matriculation Hr. Sec. School, Kolvel",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2339",
            "district": "11",
            "Block": "263",
            "label": "ST. ANTONY'S MS CHENBAGARAMANPUTHENTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2348",
            "district": "11",
            "Block": "182",
            "label": "NATIONAL MAT.S. ARUMANAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2391",
            "district": "11",
            "Block": "182",
            "label": "DEVAKALA NILAYAM MATRIC HSS-MARUTHANCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2410",
            "district": "11",
            "Block": "3",
            "label": "ST.FRANCIS MATRIC SCHOOL- SARAKKALVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2422",
            "district": "11",
            "Block": "263",
            "label": "GMS CHOTHAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2428",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT MIDDILE SCHOOL,,KANCHIKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2431",
            "district": "11",
            "Block": "263",
            "label": "GMS PILLAYARVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2437",
            "district": "11",
            "Block": "263",
            "label": "GMS KATTADITHATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2438",
            "district": "11",
            "Block": "191",
            "label": "GMS MADICHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2476",
            "district": "11",
            "Block": "355",
            "label": "CONGREGATIONAL CHRISTIAN MATRICULATION SCHOOL ,VALVACHAGOSTAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2479",
            "district": "11",
            "Block": "182",
            "label": "MOUNT CARMEL MATRIC HIGHER SECONDARY SCHOOL, MUZHUCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2487",
            "district": "11",
            "Block": "191",
            "label": "ABC MATRIC H.S.S. KOLLEMCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2490",
            "district": "11",
            "Block": "149",
            "label": "Prof.R.K.Parthasarathy Hindu Vidyalaya Matriculation School Thingalnagar",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2501",
            "district": "11",
            "Block": "263",
            "label": "GMS KIDANGANKARAIVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2502",
            "district": "11",
            "Block": "341",
            "label": "SEVENTH DAY M.HSS., PULIYERANKI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2514",
            "district": "11",
            "Block": "149",
            "label": "ST.ANNE'S MATRIC Hr.Sec. SCHOOL, COLACHEL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2519",
            "district": "11",
            "Block": "191",
            "label": "BADHARUL ISLAM MAT SCHOOL  KALIAKAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2554",
            "district": "11",
            "Block": "263",
            "label": "ISED MATRIC HSS PARAKKAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2558",
            "district": "11",
            "Block": "354",
            "label": "LUTH M.S. MARUGAL-THALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2567",
            "district": "11",
            "Block": "263",
            "label": "ST. MARY'S MIDDLE SCHOOL POZHIKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2596",
            "district": "11",
            "Block": "263",
            "label": "GMS ASARIPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2618",
            "district": "11",
            "Block": "341",
            "label": "GMS. BARATHAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2619",
            "district": "11",
            "Block": "149",
            "label": "GMS MELKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2625",
            "district": "11",
            "Block": "182",
            "label": "Abraham James Mem Matric HSS Gnaramvilai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2632",
            "district": "11",
            "Block": "149",
            "label": "GMS,KOOTUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2664",
            "district": "11",
            "Block": "263",
            "label": "ST. JUDE'S MIDDLE SCHOOL MELA ASRIPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2670",
            "district": "11",
            "Block": "149",
            "label": "GMS KATTIMANCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2682",
            "district": "11",
            "Block": "341",
            "label": "GOVT. TRMS MANALODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "2693",
            "district": "11",
            "Block": "341",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL KUTTAIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2695",
            "district": "11",
            "Block": "354",
            "label": "RCMS-ARAL KURUSADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2727",
            "district": "11",
            "Block": "3",
            "label": "GMS DEVAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2765",
            "district": "11",
            "Block": "3",
            "label": "DVD HSS KOTTAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2796",
            "district": "11",
            "Block": "263",
            "label": "ST. MARY'S HS KESAVANPUTHENTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "2815",
            "district": "11",
            "Block": "355",
            "label": "Smt.R.P.Rajalakshmi Hindu Vidyalaya Mat.Hr.Sec.School,Thuckalay",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2827",
            "district": "11",
            "Block": "124",
            "label": "ST. JHS VALIYAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "2836",
            "district": "11",
            "Block": "124",
            "label": "ST. HELEN HS ENAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "2886",
            "district": "11",
            "Block": "149",
            "label": "GMS KALPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2954",
            "district": "11",
            "Block": "3",
            "label": "GMS VATTAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2967",
            "district": "11",
            "Block": "354",
            "label": "RAMALEKSHMI VIDYALAYA MATRICULATION SCHOOL, THOVALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2974",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT MIDDLE SCHOOL  SUCHINDRUM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3016",
            "district": "11",
            "Block": "149",
            "label": "GMS THOTTICODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3023",
            "district": "11",
            "Block": "124",
            "label": "HINDU VIDYALAYA MATRIC. HIGHER SECONDARY SCHOOL. MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3029",
            "district": "11",
            "Block": "263",
            "label": "GMS MELAKRISHNANPUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3039",
            "district": "11",
            "Block": "341",
            "label": "GMS. MUTHALAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3052",
            "district": "11",
            "Block": "355",
            "label": "RC MIDDLE SCHOOL VELLICODE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3062",
            "district": "11",
            "Block": "263",
            "label": "GMS ERUMPUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3069",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  KIRATHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3076",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  PAINKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3080",
            "district": "11",
            "Block": "191",
            "label": "CONCORDIA LUTHERAN HIGH SCHOOL,  KALPARAPOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "3081",
            "district": "11",
            "Block": "341",
            "label": "GMS UNNIYOORKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3118",
            "district": "11",
            "Block": "263",
            "label": "GMS PARAKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3135",
            "district": "11",
            "Block": "341",
            "label": "GMS KUTTIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3196",
            "district": "11",
            "Block": "355",
            "label": "HINDU VIDYALAYA MATRIC HSS KAPPIYARAI,KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3244",
            "district": "11",
            "Block": "354",
            "label": "GMS KEERIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3253",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT MIDDILE SCHOOL BRAMMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3304",
            "district": "11",
            "Block": "191",
            "label": "K.A.B.D. MATRIC. HR. SEC. SCHOOL, PAINKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3359",
            "district": "11",
            "Block": "191",
            "label": "ST JUDES MS VALLAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3372",
            "district": "11",
            "Block": "341",
            "label": "CSI MIDDLE SCHOOL MARAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3434",
            "district": "11",
            "Block": "149",
            "label": "ADLINE MATRIC HSS-VILLUKURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3450",
            "district": "11",
            "Block": "149",
            "label": "GMS MOONGILVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3524",
            "district": "11",
            "Block": "341",
            "label": "LITTEL FLOWER M.S.C.M.S. ANAKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3537",
            "district": "11",
            "Block": "354",
            "label": "GMS-ESANTHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3612",
            "district": "11",
            "Block": "124",
            "label": "VIDYA JOTHI MHSS MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3616",
            "district": "11",
            "Block": "355",
            "label": "GMS-ALENCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3653",
            "district": "11",
            "Block": "355",
            "label": "GMS PILAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3677",
            "district": "11",
            "Block": "3",
            "label": "GMS KRISHNANCOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3684",
            "district": "11",
            "Block": "355",
            "label": "UMA MATRIC HIGHER SECONDARY SCHOOL, ALWARCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3685",
            "district": "11",
            "Block": "182",
            "label": "GMS-PERAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3693",
            "district": "11",
            "Block": "355",
            "label": "GMS,ERANIEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3708",
            "district": "11",
            "Block": "182",
            "label": "GMS (GIRLS) ARUMANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3715",
            "district": "11",
            "Block": "182",
            "label": "G M S (GIRLS) KUZHITHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3718",
            "district": "11",
            "Block": "263",
            "label": "HOLY INFANT JESUS MIDDLE SCHOOL, PUTHENTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3735",
            "district": "11",
            "Block": "191",
            "label": "GMS LEKSHMI PUTHUKKADAI, NITHIRAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3740",
            "district": "11",
            "Block": "191",
            "label": "GOVTMIDDLE SCHOOL, PARTHIVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3744",
            "district": "11",
            "Block": "354",
            "label": "GMS-PARVATHIPUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3747",
            "district": "11",
            "Block": "355",
            "label": "GMS KOTTIKODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3760",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL-PUDUKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3803",
            "district": "11",
            "Block": "3",
            "label": "ST.JOSEPH'S MATRIC HR.SEC SCHOOL - NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3806",
            "district": "11",
            "Block": "355",
            "label": "AMALA MATRIC -THUCKALAY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3833",
            "district": "11",
            "Block": "3",
            "label": "KASTURBA MIDDLE SCHOOL-THEROOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3841",
            "district": "11",
            "Block": "3",
            "label": "GMS KALIANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3848",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT MIDDILE SCHOOL PATHIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3865",
            "district": "11",
            "Block": "355",
            "label": "ALDRIN MATRIC HIGHER SECONDARY SCHOOL, KANDANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3879",
            "district": "11",
            "Block": "355",
            "label": "ST ANTONY'S MATRICULATION SCHOOL, CHEMPARUTHIVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3892",
            "district": "11",
            "Block": "341",
            "label": "GMS MATHAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3906",
            "district": "11",
            "Block": "355",
            "label": "RCHS CHEMPARUTHIVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "3908",
            "district": "11",
            "Block": "182",
            "label": "FRIENDS MATRICULATION HIGHER SECONDARY SCHOOL PANAMKALAI, KALIAKKAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3935",
            "district": "11",
            "Block": "191",
            "label": "ST JOSEPH MIDDLE SCHOOL  ERAVIPUTHENTHURAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3942",
            "district": "11",
            "Block": "355",
            "label": "A.G. MATRICULATION SCHOOL,CHERIKADAI,PALLIYADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3950",
            "district": "11",
            "Block": "3",
            "label": "ALPHA MATRIC  HSS KRISHNANCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3961",
            "district": "11",
            "Block": "355",
            "label": "ST.JOHNS MATRIC HIGHER SECONDARY SCHOOL, PALLIYADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3963",
            "district": "11",
            "Block": "341",
            "label": "LITTLE FLOWER MATRICULATION SCHOOL CHETTICHARVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3965",
            "district": "11",
            "Block": "149",
            "label": "GMS MONDAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3976",
            "district": "11",
            "Block": "3",
            "label": "HEBRON MATRIC.HIGHER SEC.SCHOOL-PAVALAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3979",
            "district": "11",
            "Block": "355",
            "label": "GMS VILAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3982",
            "district": "11",
            "Block": "124",
            "label": "CJM HSS UNNAMALAIKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4000",
            "district": "11",
            "Block": "355",
            "label": "GMS THUCKALAY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4008",
            "district": "11",
            "Block": "191",
            "label": "GOVT MIDDLE SCHOOL  PORANCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4030",
            "district": "11",
            "Block": "354",
            "label": "GMS-THERISANAMKOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4035",
            "district": "11",
            "Block": "191",
            "label": "MUNCHIRAI PUNITHA AROCKIYAMATHA MAT. HSS PUTHUKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4040",
            "district": "11",
            "Block": "341",
            "label": "Vedavyasa Matriculation Higher Secondary School Vellicode",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4042",
            "district": "11",
            "Block": "355",
            "label": "GMS KOTTAMAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4069",
            "district": "11",
            "Block": "191",
            "label": "G M S KOLLEMCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4091",
            "district": "11",
            "Block": "182",
            "label": "GMS-VANNIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4119",
            "district": "11",
            "Block": "355",
            "label": "V. N. MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL-MOOLACHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4130",
            "district": "11",
            "Block": "354",
            "label": "BAS MAT SCHL-CHENBAGARAMANPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4143",
            "district": "11",
            "Block": "341",
            "label": "ST. SAHAYAMATHA M.S. EANCHACODE",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4172",
            "district": "11",
            "Block": "149",
            "label": "GMS ALANVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4183",
            "district": "11",
            "Block": "182",
            "label": "GOVT. L M  M S ARUMANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4187",
            "district": "11",
            "Block": "341",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL PUTHANKADAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4193",
            "district": "11",
            "Block": "355",
            "label": "GMS PUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4210",
            "district": "11",
            "Block": "341",
            "label": "ST. THOMAS MSCMS THEMANOOR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4225",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT MIDDILE SCHOOL,,PULIYOORKURICHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4230",
            "district": "11",
            "Block": "191",
            "label": "MAYA  KRISHNA SWAMY  VIDYALAYA, ANANTHANAGER, ATHENCODE.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4239",
            "district": "11",
            "Block": "3",
            "label": "GMS MARAVANKUDIERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4249",
            "district": "11",
            "Block": "124",
            "label": "WHITE MEMORIAL MATRIC. HIGHER SECONDARY SCHOOL, MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4252",
            "district": "11",
            "Block": "124",
            "label": "A.P.J.M.MATRIC HR.SEC.S-SOOSAIPURAM, KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4272",
            "district": "11",
            "Block": "341",
            "label": "John Paul ll Matric Hr. Sec. School Kulasekharam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4283",
            "district": "11",
            "Block": "355",
            "label": "CARMAL MATRIC SCHOOL,  MANNALIKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4293",
            "district": "11",
            "Block": "355",
            "label": "MOTHER THERESA MATRIC HIGHER SECONDARY SCHOOL, MYLACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4294",
            "district": "11",
            "Block": "124",
            "label": "LUTH. MS VAZHUTHALAMPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4309",
            "district": "11",
            "Block": "341",
            "label": "C.S.I MATRIC.HR.SEC.SCHOOL,KULASEKHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4322",
            "district": "11",
            "Block": "124",
            "label": "GOVT MIDDLE SCHOOL, DEVICODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4363",
            "district": "11",
            "Block": "355",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL, KALKURICHY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4372",
            "district": "11",
            "Block": "341",
            "label": "GMS. KANNANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4398",
            "district": "11",
            "Block": "355",
            "label": "ST. THERESA'S MIDDLE SCHOOL KANDANVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4410",
            "district": "11",
            "Block": "355",
            "label": "ST ANTONY'S MS VATTAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4451",
            "district": "11",
            "Block": "149",
            "label": "ST PETERS MS KADIAPATTANAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4466",
            "district": "11",
            "Block": "355",
            "label": "CSI MATRICULATION SCHOOL, MOOLACHEL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4489",
            "district": "11",
            "Block": "149",
            "label": "GMS KUNNANCADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4620",
            "district": "11",
            "Block": "355",
            "label": "ISLAMIC MODEL MATRIC Hr. Sec. School  THIRUVITHANCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4630",
            "district": "11",
            "Block": "355",
            "label": "MALAVIYA VIDYA KENDRAM, KUMARAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4650",
            "district": "11",
            "Block": "182",
            "label": "ST. GABRIALS R.C. MIDDLE SCHOOL, THICKURICHY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4693",
            "district": "11",
            "Block": "341",
            "label": "N.V.K.S. HIGHER SEC. SCHOOL  ATTOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4735",
            "district": "11",
            "Block": "354",
            "label": "ZION JEBA THOTTAM MATRICULATION SCHOOL-ESANTHIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4741",
            "district": "11",
            "Block": "124",
            "label": "KALLADAI SHRI KRISHNA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4752",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  MUKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4771",
            "district": "11",
            "Block": "3",
            "label": "DVD MATRIC HIGH SCHOOL-NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4788",
            "district": "11",
            "Block": "149",
            "label": "LUTHERAN MIDDLE SCHOOL SARALVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4795",
            "district": "11",
            "Block": "182",
            "label": "ST.JOSEPH'S M S.C.M.S KULAPPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4805",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  MUNCHIRAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4837",
            "district": "11",
            "Block": "3",
            "label": "CSI MAT.HSS ,JOSUWA STREET -NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4848",
            "district": "11",
            "Block": "124",
            "label": "C.S.I HACKER MATRICULATION SCHOOL, PAYANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4864",
            "district": "11",
            "Block": "149",
            "label": "NAVAJOTHI MATRIC HSS MONDAIKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4880",
            "district": "11",
            "Block": "182",
            "label": "GOVT M V MIDDLE S MARTHANDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4889",
            "district": "11",
            "Block": "191",
            "label": "GMS CHATHENCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4942",
            "district": "11",
            "Block": "355",
            "label": "SREERAM MATRIC HIGHER SECONDARY SCHOOL,PARASERI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4980",
            "district": "11",
            "Block": "149",
            "label": "St. MARY'S MATRIC HIGHER SECONDARY SCHOOL, SIMONCOLONY.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4981",
            "district": "11",
            "Block": "355",
            "label": "VICTORY PUBLIC SCHOOL CBSE ALANCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4990",
            "district": "11",
            "Block": "182",
            "label": "R C M S MELPALAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5016",
            "district": "11",
            "Block": "191",
            "label": "GOVT MS POOTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5023",
            "district": "11",
            "Block": "3",
            "label": "ST. TRESA'S MATRIC  SCHOOL  KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5030",
            "district": "11",
            "Block": "191",
            "label": "ST. ANTONY'S M.S.C. MIDDLE SCHOOL METHUKUMMEL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5069",
            "district": "11",
            "Block": "149",
            "label": "GMS KALLUKOOTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5074",
            "district": "11",
            "Block": "191",
            "label": "GOVT MIDDLLE SCHOOL, KUNNATHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5080",
            "district": "11",
            "Block": "124",
            "label": "GMS PARAVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5176",
            "district": "11",
            "Block": "263",
            "label": "ST.FRANCIS MATRIC.HR.SEC.SCHOOL,VELLALANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5280",
            "district": "11",
            "Block": "3",
            "label": "GMS POTTAIYADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5480",
            "district": "11",
            "Block": "341",
            "label": "GOOD SHEPHRED MS. VIRALIKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5490",
            "district": "11",
            "Block": "149",
            "label": "AROCKIA ANNAI MS PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5493",
            "district": "11",
            "Block": "355",
            "label": "LITTLE MEADS METRIC SCHOOL PALIYADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5512",
            "district": "11",
            "Block": "182",
            "label": "GOOD SHEPHERD.MAT.HSS-MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5526",
            "district": "11",
            "Block": "149",
            "label": "GMS-COLACHEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5610",
            "district": "11",
            "Block": "149",
            "label": "GMS SARAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5635",
            "district": "11",
            "Block": "341",
            "label": "GMS. ITTAGAVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5650",
            "district": "11",
            "Block": "124",
            "label": "SACRED HEART MHS  VIRICODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5662",
            "district": "11",
            "Block": "191",
            "label": "ST. THOMAS M.S.C. MIDDLE SCHOOL MANKUZHI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5726",
            "district": "11",
            "Block": "341",
            "label": "SMR NATIONAL SCHOOL, PULIERRANGY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5757",
            "district": "11",
            "Block": "191",
            "label": "GOVT.M.S-UTHACHIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5836",
            "district": "11",
            "Block": "354",
            "label": "G.A.D.W.M.S.UDAIYADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5856",
            "district": "11",
            "Block": "124",
            "label": "GMS THATTANVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5953",
            "district": "11",
            "Block": "182",
            "label": "SHREE VIDHYADHIRAJA VIDYA VIKAS MATRIC SCHOOL KUZHITHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5960",
            "district": "11",
            "Block": "124",
            "label": "CARMEL MATRICULATION SCHOOL - MATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5970",
            "district": "11",
            "Block": "3",
            "label": "AROCKIA ANNAI M.S-AROCKIAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6000",
            "district": "11",
            "Block": "191",
            "label": "OUR LADY OF MERCY EM  KARUNYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6021",
            "district": "11",
            "Block": "149",
            "label": "ORIENT MATRIC SCHOOL-COLACHEL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6031",
            "district": "11",
            "Block": "149",
            "label": "SREE VIVEKANANDA MATRIC HSS, KOOTTUMANGALAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6095",
            "district": "11",
            "Block": "3",
            "label": "St. STELLA'S MATRICULATION HR.SEC.SCHOOL  ANJUGRAMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6107",
            "district": "11",
            "Block": "149",
            "label": "GMS CHETTIYARMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6160",
            "district": "11",
            "Block": "354",
            "label": "Holy Family Middle School, Ettamadai",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6180",
            "district": "11",
            "Block": "124",
            "label": "BARATH MATRICULATION SCHOOL ANANVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6288",
            "district": "11",
            "Block": "355",
            "label": "SREE RAMAKRISHNA MATRIC HR.SEC SCHOOL,PARAICODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6290",
            "district": "11",
            "Block": "263",
            "label": "GNANA VIDYA MANDIR, KEELAKATTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6293",
            "district": "11",
            "Block": "191",
            "label": "JOHN BONAL MATRICULATION SCHOOL, MARTHANDANTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6313",
            "district": "11",
            "Block": "191",
            "label": "ST.JUDE'S MATRICULATION SCHOOL-CHINNATHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6323",
            "district": "11",
            "Block": "355",
            "label": "ST JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL, MULAGUMOODU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6388",
            "district": "11",
            "Block": "182",
            "label": "CHRIST THE KING MATRIC HSS, KANNUMAMOODU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6393",
            "district": "11",
            "Block": "355",
            "label": "ST JOSEPH'S HSS MULAGUMOODU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6422",
            "district": "11",
            "Block": "263",
            "label": "ST JOSEPH HS RAJAKMANGALAMTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "6430",
            "district": "11",
            "Block": "355",
            "label": "OXFORD MARTIC KUMARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6440",
            "district": "11",
            "Block": "341",
            "label": "EXCEL CENTRAL SCHOOL THIRUVATTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6497",
            "district": "11",
            "Block": "124",
            "label": "GMS MANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6524",
            "district": "11",
            "Block": "182",
            "label": "GMS PURAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6590",
            "district": "11",
            "Block": "191",
            "label": "LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL ,PUSHPAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6621",
            "district": "11",
            "Block": "149",
            "label": "MARIA RAFOLS MATRIC Hr.Sec. SCHOOL, PUTHOOR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6654",
            "district": "11",
            "Block": "3",
            "label": "THE RAJAS INTERNATIONAL SCHOOL,OZHUGINANASERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6711",
            "district": "11",
            "Block": "191",
            "label": "ST MATHEWS MS ANNIKKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6740",
            "district": "11",
            "Block": "263",
            "label": "BHARATH ADVANCED MATRICULATION HSS, KOIYANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20069",
            "district": "11",
            "Block": "354",
            "label": "GHSS-THOVALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20070",
            "district": "11",
            "Block": "354",
            "label": "GHSS-MADHAVALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20071",
            "district": "11",
            "Block": "354",
            "label": "GHSS-CHENBAGARAMANPUTHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20072",
            "district": "11",
            "Block": "354",
            "label": "GHS-VELLAMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20073",
            "district": "11",
            "Block": "354",
            "label": "GHS-THIRUPATHISARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20074",
            "district": "11",
            "Block": "354",
            "label": "ANNAI CHELLAMMAL CBSE SCHOOLTHIRUPATHISARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20075",
            "district": "11",
            "Block": "354",
            "label": "GHSS-THAZHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20076",
            "district": "11",
            "Block": "354",
            "label": "GHSS-BOOTHAPANDY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20077",
            "district": "11",
            "Block": "354",
            "label": "GHSS-THITTUVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20078",
            "district": "11",
            "Block": "354",
            "label": "BGCSI MATRICULATION SCHOOL, THITTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20079",
            "district": "11",
            "Block": "354",
            "label": "PJ GHS (G)-BHOOTHAPANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20080",
            "district": "11",
            "Block": "354",
            "label": "GHSS-ARUMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20081",
            "district": "11",
            "Block": "354",
            "label": "GHS-POTTIYOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20082",
            "district": "11",
            "Block": "354",
            "label": "GHSS-KURATHIYARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20083",
            "district": "11",
            "Block": "354",
            "label": "GOVT ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL VAZHAYATHUVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20084",
            "district": "11",
            "Block": "354",
            "label": "C.W.H.S-THADIKARANKONAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20085",
            "district": "11",
            "Block": "354",
            "label": "GHS-ANANTHAPURAM, KADUKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20086",
            "district": "11",
            "Block": "354",
            "label": "GHS-KATTUPUTHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20087",
            "district": "11",
            "Block": "354",
            "label": "GHS-ERACHAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20088",
            "district": "11",
            "Block": "354",
            "label": "GHS-ARAL PERUMALPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20089",
            "district": "11",
            "Block": "354",
            "label": "GHSS-ARALVAIMOZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20090",
            "district": "11",
            "Block": "354",
            "label": "RAJA'S MATRIC HR.SEC.SCHOOL - ARALVOIMOZHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20091",
            "district": "11",
            "Block": "354",
            "label": "GHS-ARAL KUMARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20092",
            "district": "11",
            "Block": "354",
            "label": "COMERIN INTERNATIONAL ARALVOIMOZHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20093",
            "district": "11",
            "Block": "3",
            "label": "GOVT HR SEC SCHOOL  OTTAYALVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20094",
            "district": "11",
            "Block": "3",
            "label": "GHS MAHADANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20095",
            "district": "11",
            "Block": "3",
            "label": "MARY IMMACULATE HS KANYAKUMARI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20096",
            "district": "11",
            "Block": "3",
            "label": "ST ANTONY'S HSS KANYAKUMARI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20097",
            "district": "11",
            "Block": "3",
            "label": "KANYAKUMARI PUBLIC SCHOOL- KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20098",
            "district": "11",
            "Block": "3",
            "label": "ST. JOSEPH CALASANZ SCHOOL- KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20099",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL ELANTHAYADIVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20100",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, AGASTHEESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20101",
            "district": "11",
            "Block": "3",
            "label": "ST.IGNATIOUS H.S,KOVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20102",
            "district": "11",
            "Block": "3",
            "label": "AMRITA VIDYALAYAM  KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20103",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL  NORTH THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20104",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL KOTTAIYADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20105",
            "district": "11",
            "Block": "3",
            "label": "LMS.H.S.S,SOUTH THAMARAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20106",
            "district": "11",
            "Block": "3",
            "label": "GOOD SHEPHERED MATRIC Hr.Sec. SCHOOL, THERIVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20107",
            "district": "11",
            "Block": "3",
            "label": "SIVA MATRIC HIGHER SECONDARY SCHOOL CHITHENKUDIYIRUPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20108",
            "district": "11",
            "Block": "3",
            "label": "GHS KULASEKARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20109",
            "district": "11",
            "Block": "3",
            "label": "RINGLETAUBE HSS MYLAUDY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20110",
            "district": "11",
            "Block": "3",
            "label": "MOSES MATRIC SCHOOL - MYLAUDY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20111",
            "district": "11",
            "Block": "3",
            "label": "GHSS KUMARAPURAM THOPOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20112",
            "district": "11",
            "Block": "3",
            "label": "GHS RAMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20113",
            "district": "11",
            "Block": "3",
            "label": "GOVT.HIGH SCHOOL , NALLOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20114",
            "district": "11",
            "Block": "3",
            "label": "PEARL MATRIC HIGHER SEC.SCHOOL -THEREKALPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20115",
            "district": "11",
            "Block": "3",
            "label": "ISHA VIDHYA MATRICULATION HIGHER SECONDARY SCHOOL-THEROOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20116",
            "district": "11",
            "Block": "3",
            "label": "GHS ERAVIPUTHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20117",
            "district": "11",
            "Block": "3",
            "label": "S.M.S.M.HSS-SUCHINDRUM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20118",
            "district": "11",
            "Block": "3",
            "label": "GMS POTTALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23382",
            "district": "11",
            "Block": "355",
            "label": "May Flower Matric Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20119",
            "district": "11",
            "Block": "3",
            "label": "GHS VARIYOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20120",
            "district": "11",
            "Block": "3",
            "label": "ST.JOSEPH'S (G) HS-AZHAGAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20121",
            "district": "11",
            "Block": "3",
            "label": "LMS HSS JAMESTOWN",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20122",
            "district": "11",
            "Block": "3",
            "label": "ST.ANTONY'S HSS AZHAGAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20123",
            "district": "11",
            "Block": "3",
            "label": "GHS KANAGAPPAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20124",
            "district": "11",
            "Block": "3",
            "label": "CHRIST CMI CENTRAL SCHOOL-ANJUGRAMAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20125",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL,  EACHENVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20126",
            "district": "11",
            "Block": "3",
            "label": "GHSS KOTTARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20127",
            "district": "11",
            "Block": "3",
            "label": "CSI HS  FOR  THE DEAF KOTTARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20128",
            "district": "11",
            "Block": "3",
            "label": "Dr .ABDULKALAM INTERNATIONAL SCHOOL  & RESEARCH  ACADEMY,PERIAVIAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20129",
            "district": "11",
            "Block": "3",
            "label": "ANBU ILLAM HR.SEC.SCHOOL FOR SPEACH & HEARING IMPAIRED- NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20130",
            "district": "11",
            "Block": "3",
            "label": "SALVATION ARMY HSS,VETTOORNIMADAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20131",
            "district": "11",
            "Block": "3",
            "label": "SALVATION ARMY MATRIC HSS ,VETTOORNIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20132",
            "district": "11",
            "Block": "3",
            "label": "ADARSH VIDYA KENDRA-NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20133",
            "district": "11",
            "Block": "3",
            "label": "SMRV HR.SEC.SCHOOL,VADASERY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20134",
            "district": "11",
            "Block": "3",
            "label": "GHSS VADASERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20135",
            "district": "11",
            "Block": "3",
            "label": "S.M.R.V. MATRICULATION  SCHOOL , VADASERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20136",
            "district": "11",
            "Block": "3",
            "label": "ST. XAVIER 'S HS KOTTAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20137",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL EZHAKARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20138",
            "district": "11",
            "Block": "3",
            "label": "SADAVADANI S.P G.HSS-EDALAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20139",
            "district": "11",
            "Block": "3",
            "label": "ST. MICHAEL'S HIGH SCHOOL VETHAKARANKUDIYIRUPPU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20140",
            "district": "11",
            "Block": "3",
            "label": "GHS IRULLAPPAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20141",
            "district": "11",
            "Block": "3",
            "label": "GHSS VALLANKUMARANVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20142",
            "district": "11",
            "Block": "3",
            "label": "BISHOP AROCKIASAMY HR.SEC.SCHOOL- MARAVANKUDIERUPPU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20143",
            "district": "11",
            "Block": "3",
            "label": "CREATIVE MATRIC SCHOOL, KEEZHA MARAVANKUDIYERUPPU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20144",
            "district": "11",
            "Block": "3",
            "label": "GHS MELASOORANKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20145",
            "district": "11",
            "Block": "3",
            "label": "ST. ANTONY 'S HSS, KURUSADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20146",
            "district": "11",
            "Block": "3",
            "label": "CONCORDIA HIGH SCHOOL NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20147",
            "district": "11",
            "Block": "3",
            "label": "IELC PUBLIC SCHOOL NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20148",
            "district": "11",
            "Block": "3",
            "label": "St.ALOYSIUS HSS - CHAYAKKARANPARAPPUVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20149",
            "district": "11",
            "Block": "3",
            "label": "KENDRIYA VIDYALAYA NAGERCOIL",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20150",
            "district": "11",
            "Block": "3",
            "label": "SCOTT CHRISTIAN HSS-NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20151",
            "district": "11",
            "Block": "3",
            "label": "ORAL HR SEC SCHOOL FOR THE HEARING IMPAIRED NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20152",
            "district": "11",
            "Block": "3",
            "label": "GHS NESAVALAR ST VADASERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20153",
            "district": "11",
            "Block": "3",
            "label": "K.N.S.K.GHS OZHUGINASERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20154",
            "district": "11",
            "Block": "3",
            "label": "GHS MEENAKSHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20155",
            "district": "11",
            "Block": "3",
            "label": "ST.JOSEPH 'S CONVENT HSS ,NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20156",
            "district": "11",
            "Block": "3",
            "label": "PHILIPS MATRIC SCHOOL, NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20157",
            "district": "11",
            "Block": "3",
            "label": "KDVP GOVT GIRLS MODEL HR SEC SCHOOL, KOTTAR, NAGERCOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20158",
            "district": "11",
            "Block": "3",
            "label": "SLB GOVT.(G) HIGH SCHOOL -NAGERCOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20159",
            "district": "11",
            "Block": "3",
            "label": "St..FRANCIS  HSS,  NAGERCOIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20160",
            "district": "11",
            "Block": "3",
            "label": "GHS VADALIVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20161",
            "district": "11",
            "Block": "3",
            "label": "LITTLE FLOWER (G ) HR .SEC.SCHOOL -RAMANPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20162",
            "district": "11",
            "Block": "3",
            "label": "CARMEL HR.SEC.SCHOOL RAMANPUTHUR ,NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20163",
            "district": "11",
            "Block": "3",
            "label": "SLB GOVT.HR.SEC.SCHOOL-NAGERCOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20164",
            "district": "11",
            "Block": "3",
            "label": "HOME CHURCH GIRLS HIGH SCHOOL - NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20165",
            "district": "11",
            "Block": "3",
            "label": "DUTHIE GIRLS HR.SE.SCHOOL -NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20166",
            "district": "11",
            "Block": "263",
            "label": "GHS PARAKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20167",
            "district": "11",
            "Block": "263",
            "label": "GHSS THENGAMPUTHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20168",
            "district": "11",
            "Block": "263",
            "label": "LITTLE FLOWER HS-MANAKUDY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20169",
            "district": "11",
            "Block": "263",
            "label": "LMPC HIGHER SECONDARY SCHOOL, PUTHALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20170",
            "district": "11",
            "Block": "263",
            "label": "KANNAN MATRIC HIGHER SECONDARY SCHOOL, THENGAMPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20171",
            "district": "11",
            "Block": "263",
            "label": "BELIEF CENTRAL SCHOOL-THENGAMPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20172",
            "district": "11",
            "Block": "263",
            "label": "GHS-PUTHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20173",
            "district": "11",
            "Block": "263",
            "label": "GHSS,SOORANKUDY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20174",
            "district": "11",
            "Block": "263",
            "label": "GHSS-SUNDAPATTIVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20175",
            "district": "11",
            "Block": "263",
            "label": "LMS HSS ZIONPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20176",
            "district": "11",
            "Block": "263",
            "label": "GHS PARVATHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20177",
            "district": "11",
            "Block": "263",
            "label": "GHS PUTHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20178",
            "district": "11",
            "Block": "263",
            "label": "GHSS PERUVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20179",
            "district": "11",
            "Block": "263",
            "label": "ST.JOSEPH's HR.SEC. SCHOOL, ASARIPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20180",
            "district": "11",
            "Block": "263",
            "label": "SHANTHI NILAYAM SPECIAL SCHOOL-PARUTHIVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20181",
            "district": "11",
            "Block": "263",
            "label": "GHSS EATHAMOZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20182",
            "district": "11",
            "Block": "263",
            "label": "GHSS-RAJAKKAMANAGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20183",
            "district": "11",
            "Block": "263",
            "label": "AVD HSS GANAPATHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20184",
            "district": "11",
            "Block": "263",
            "label": "GHS ALANKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20185",
            "district": "11",
            "Block": "263",
            "label": "GHSS MONIKETTIPOTTAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20186",
            "district": "11",
            "Block": "263",
            "label": "St. Jude Thaddaeus Higher Secondary School, Pallam",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20187",
            "district": "11",
            "Block": "263",
            "label": "GHSS ANANDANADARKUDY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20188",
            "district": "11",
            "Block": "263",
            "label": "LMS GIRLS HIGH SCHOOL SANTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20189",
            "district": "11",
            "Block": "263",
            "label": "DR. SAMUEL HSS SANTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20190",
            "district": "11",
            "Block": "263",
            "label": "GOVERNMENT HIGH SCHOOL, VEMBANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20191",
            "district": "11",
            "Block": "263",
            "label": "ST. ROSSELLO'S SCHOOL, SADAYALPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20192",
            "district": "11",
            "Block": "149",
            "label": "GHSS ALOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20193",
            "district": "11",
            "Block": "149",
            "label": "GHSS KODUPAKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20194",
            "district": "11",
            "Block": "149",
            "label": "CORPUS CHRISTI SCHOOL , PERUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20195",
            "district": "11",
            "Block": "149",
            "label": "GHSS - ERANIEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20196",
            "district": "11",
            "Block": "149",
            "label": "ST.XAVIER'S HIGHER SECONDARY SCHOOL,PATTARIVILLAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20197",
            "district": "11",
            "Block": "149",
            "label": "ST.FRANCIS XAVIER Hr.Sec.School, Manguzhy",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20198",
            "district": "11",
            "Block": "149",
            "label": "GHS THALAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20199",
            "district": "11",
            "Block": "149",
            "label": "GHS VELLICHANTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20200",
            "district": "11",
            "Block": "149",
            "label": "GHSS-AMMANDIVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20201",
            "district": "11",
            "Block": "149",
            "label": "WESLEY MIDDLE SCHOOL UNNANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20202",
            "district": "11",
            "Block": "149",
            "label": "ST.JOSEPH'S H.S- SAREL.",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20203",
            "district": "11",
            "Block": "149",
            "label": "SACRED HEART HIGH SCHOOL, KADIAPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20204",
            "district": "11",
            "Block": "149",
            "label": "SAROJINI MEMORIAL HIGHER SECONDARY SCHOOL ORAPPANAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20205",
            "district": "11",
            "Block": "149",
            "label": "ALL SAINTS HSS MUTTOM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20206",
            "district": "11",
            "Block": "149",
            "label": "ST JOSEPH'S SCHOOL, ATTINKARAI, MANAVALAKURICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20207",
            "district": "11",
            "Block": "149",
            "label": "GHS CHERAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20208",
            "district": "11",
            "Block": "149",
            "label": "GHSS(G)-KADIAPATINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20209",
            "district": "11",
            "Block": "149",
            "label": "BMHSS MANAVALAKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20210",
            "district": "11",
            "Block": "149",
            "label": "DEVASAM HSS MONDAIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20211",
            "district": "11",
            "Block": "149",
            "label": "GHSS ANAKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20212",
            "district": "11",
            "Block": "149",
            "label": "ST. MICHAEL'S HIGH SCHOOL KODIMUNAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20213",
            "district": "11",
            "Block": "149",
            "label": "PROVIDENCE HIGHER SECONDARY SCHOOL,RITAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20214",
            "district": "11",
            "Block": "149",
            "label": "DEVI HSS KUNANKAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20215",
            "district": "11",
            "Block": "149",
            "label": "ST. IGNATIUS HSS KURUMPANAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20216",
            "district": "11",
            "Block": "149",
            "label": "VKP HSS COLACHEL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20217",
            "district": "11",
            "Block": "149",
            "label": "ST. ALEX MS-KOTTILPADU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20218",
            "district": "11",
            "Block": "149",
            "label": "GHS-ELAPPAVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20219",
            "district": "11",
            "Block": "149",
            "label": "ST. MARY'S HSS-COLACHEL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20220",
            "district": "11",
            "Block": "149",
            "label": "ST JAMES HS VANIYAKUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20221",
            "district": "11",
            "Block": "149",
            "label": "GOOD SHEPHERED MATRICULATION SCHOOL,SIMON COLONY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20222",
            "district": "11",
            "Block": "149",
            "label": "RC HSS KONAMKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20223",
            "district": "11",
            "Block": "149",
            "label": "GHS VILLUKURI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20224",
            "district": "11",
            "Block": "149",
            "label": "ST. LAWRENCE HSS MADATHATTUVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20225",
            "district": "11",
            "Block": "149",
            "label": "ST. ALOYSIUS HSS KARANKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20226",
            "district": "11",
            "Block": "149",
            "label": "LMS GHSS NEYYOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20227",
            "district": "11",
            "Block": "149",
            "label": "MYRNA MEMORIAL HSS BETHELPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20228",
            "district": "11",
            "Block": "149",
            "label": "K.J MCILROY MAT.S, NEYYOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20229",
            "district": "11",
            "Block": "149",
            "label": "CSI MATRICULATION HIGH SCHOOL SEYNAMVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20230",
            "district": "11",
            "Block": "149",
            "label": "JOCHEBED MATRICULATION SCHOOL KALLUKOOTTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20231",
            "district": "11",
            "Block": "355",
            "label": "GHS VATHIYARKONAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20232",
            "district": "11",
            "Block": "355",
            "label": "St.Joseph's M.S.C.H.S.S,Chellamkonam",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20233",
            "district": "11",
            "Block": "355",
            "label": "RC HIGH SCHOOL PALLIYADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20234",
            "district": "11",
            "Block": "355",
            "label": "CHRISTUCOIL LMS HR SEC SCHOOL PALLIYADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20235",
            "district": "11",
            "Block": "355",
            "label": "KARUNALYAM SCHOOL MENTALLY RETARDED -KALLUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20236",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,KALKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20237",
            "district": "11",
            "Block": "355",
            "label": "GOVT HSS-THUCKALAY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20238",
            "district": "11",
            "Block": "355",
            "label": "AMALA CONVENT(G) H.S.S,THUCKALAY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20239",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT HIGH SCHOOL, MOOLACHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20240",
            "district": "11",
            "Block": "355",
            "label": "SKVMS MUTTAIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20241",
            "district": "11",
            "Block": "355",
            "label": "CARMAL GIRLS HSS MANALIKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20242",
            "district": "11",
            "Block": "355",
            "label": "LMS HSS KADAMALAIKUNTU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20243",
            "district": "11",
            "Block": "355",
            "label": "GHS KUMARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20244",
            "district": "11",
            "Block": "355",
            "label": "ST. MARY GORETTY HSS  MANALIKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20245",
            "district": "11",
            "Block": "355",
            "label": "BG ARIVU ILLAM KOTTICODU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20246",
            "district": "11",
            "Block": "355",
            "label": "GHS(GIRLS),ERANIEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20247",
            "district": "11",
            "Block": "355",
            "label": "GHS PEYANKUZHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20248",
            "district": "11",
            "Block": "355",
            "label": "GHSS.KANDANVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20249",
            "district": "11",
            "Block": "355",
            "label": "HACKER MEMORIAL HSS ,NEYYOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20250",
            "district": "11",
            "Block": "355",
            "label": "GMS KODIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20251",
            "district": "11",
            "Block": "355",
            "label": "GHSS-THRIVITHANCODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20252",
            "district": "11",
            "Block": "355",
            "label": "GHSS-KANNATTUVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20253",
            "district": "11",
            "Block": "355",
            "label": "INFANT JESUS GIRLS HIGH SCHOOL -  MULAGUMOODU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20254",
            "district": "11",
            "Block": "355",
            "label": "VIVEKANANDA MAT Hr.Sec.SCHOOL-VAIKUNDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20255",
            "district": "11",
            "Block": "355",
            "label": "SREE RAMJI VITHIYA KENDRAA THICKANAMCODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20256",
            "district": "11",
            "Block": "355",
            "label": "MOUNT LITERA ZEE SCHOOL -MYLODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20257",
            "district": "11",
            "Block": "355",
            "label": "GHS PERUNCHILAMBU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20258",
            "district": "11",
            "Block": "341",
            "label": "GHS VILLUNNIKONAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20259",
            "district": "11",
            "Block": "341",
            "label": "YETTACODE HSS. YETTACODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20260",
            "district": "11",
            "Block": "341",
            "label": "GHS. CHERUKOLE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20261",
            "district": "11",
            "Block": "341",
            "label": "GHS.POOVANCODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20262",
            "district": "11",
            "Block": "341",
            "label": "M.V. H.S. CHENKODI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20263",
            "district": "11",
            "Block": "341",
            "label": "GHS THACHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20264",
            "district": "11",
            "Block": "341",
            "label": "GHS. CHERUPPALOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20265",
            "district": "11",
            "Block": "341",
            "label": "GHS. THIRUVARAMBU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20266",
            "district": "11",
            "Block": "341",
            "label": "GHS. THIRUVATTAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20267",
            "district": "11",
            "Block": "341",
            "label": "ARUNACHALAM HSS., THIRUVATTAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20268",
            "district": "11",
            "Block": "341",
            "label": "GHS KESAVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20269",
            "district": "11",
            "Block": "341",
            "label": "DEVASAM H.S. THIRPARAPPU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20270",
            "district": "11",
            "Block": "341",
            "label": "GOVT. TRIBAL RESIDENTIAL HSS PECHIPPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20271",
            "district": "11",
            "Block": "341",
            "label": "ST. PETER'S HSS. CHEKKAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20272",
            "district": "11",
            "Block": "341",
            "label": "GHS. PONMANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20273",
            "district": "11",
            "Block": "341",
            "label": "GOVT HIGH SCHOOL MANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20274",
            "district": "11",
            "Block": "341",
            "label": "GHSS. KULASEKARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20275",
            "district": "11",
            "Block": "341",
            "label": "GHS. VEEYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20276",
            "district": "11",
            "Block": "341",
            "label": "GHS. ERAVIPUTHOORKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20277",
            "district": "11",
            "Block": "341",
            "label": "GHSS KATTATHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20278",
            "district": "11",
            "Block": "341",
            "label": "ST. JOSEPH'S HIGH SCHOOL MULAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20279",
            "district": "11",
            "Block": "341",
            "label": "ST. JOSEPH'S HIGH SCHOOL PILANKALAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20280",
            "district": "11",
            "Block": "341",
            "label": "AIDED HS. KATTATHURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20281",
            "district": "11",
            "Block": "341",
            "label": "BETHEL BLOOMS N&PS-AZHAHIAMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20282",
            "district": "11",
            "Block": "341",
            "label": "GHS. THIRUNANTHIKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20283",
            "district": "11",
            "Block": "341",
            "label": "ST. URSULA'S GIRLS HSS KULASEKHARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20284",
            "district": "11",
            "Block": "341",
            "label": "SBJ VIDYA BHAVAN INTERNATIONAL SCHOOL-PULIYERANGI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20285",
            "district": "11",
            "Block": "341",
            "label": "ST. ANTONY'S HS. SURULACODE",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20286",
            "district": "11",
            "Block": "341",
            "label": "GHS KOTHAYAR LOWER CAMP",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20287",
            "district": "11",
            "Block": "182",
            "label": "GHS CHEMMANKALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20288",
            "district": "11",
            "Block": "182",
            "label": "GHS MELPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20289",
            "district": "11",
            "Block": "182",
            "label": "GHSS THIKKURICHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20290",
            "district": "11",
            "Block": "182",
            "label": "SACRED HEART H S PACODE",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20291",
            "district": "11",
            "Block": "182",
            "label": "ABRAHAM MEMORIAL .HSS-MARUNTHENCODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20292",
            "district": "11",
            "Block": "182",
            "label": "ROYAL MAT H S S  PACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20293",
            "district": "11",
            "Block": "182",
            "label": "SREE KRISHNA VIDYALAYA MATRIC HIGH SCHOOL, ALAMPADI, CHOOZHIKKONAM, MARUTHANCODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20294",
            "district": "11",
            "Block": "182",
            "label": "SACRED HEART CONVENT MATRIC. HR.SEC.SCHOOL ,KAZHUVANTHITTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20295",
            "district": "11",
            "Block": "182",
            "label": "Holy Angels' Matriculation School, Melpuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20296",
            "district": "11",
            "Block": "182",
            "label": "G H S  KALIAKKAVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20297",
            "district": "11",
            "Block": "182",
            "label": "G H S MECODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20298",
            "district": "11",
            "Block": "182",
            "label": "T. C. K. H. S. S PADANTHALUMOODU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20299",
            "district": "11",
            "Block": "182",
            "label": "SACR H MAT HSS PADANTHALUMOODU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20300",
            "district": "11",
            "Block": "182",
            "label": "ST JOSEPH'S HSS THIRUTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20301",
            "district": "11",
            "Block": "182",
            "label": "G H S S VILAVANCODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20302",
            "district": "11",
            "Block": "182",
            "label": "G H S S (BOYS)MARTHANDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20303",
            "district": "11",
            "Block": "182",
            "label": "G H S S GIRLS MARTHANDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20304",
            "district": "11",
            "Block": "182",
            "label": "L.M.S HIGHER SECONDARY SCHOOL FOR BOYS,MARTHANDAM.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20305",
            "district": "11",
            "Block": "182",
            "label": "L M S GIRLS H S S MARTHANDAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20306",
            "district": "11",
            "Block": "182",
            "label": "MJKM MSC.HR.SEC. SCHOOL, CHRISTURAJAPURAM -MARTHANDAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20307",
            "district": "11",
            "Block": "182",
            "label": "GHSS-PALUKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20308",
            "district": "11",
            "Block": "182",
            "label": "ST JAMES H S PARAKUNNU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20309",
            "district": "11",
            "Block": "182",
            "label": "ST. FRANCIS NURSERY AND PRIMARY SCHOOL, MEZHACODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20310",
            "district": "11",
            "Block": "182",
            "label": "B.R.MEMORIAL MATRICULATION SCHOOL, PALUKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20311",
            "district": "11",
            "Block": "182",
            "label": "G LMMS MALAICODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20312",
            "district": "11",
            "Block": "182",
            "label": "M NM HS EDAICODE",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20313",
            "district": "11",
            "Block": "182",
            "label": "ST MARYS HSS MELPALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20314",
            "district": "11",
            "Block": "182",
            "label": "AIDED HIGHER SECONDARY SCHOOL, MOTTAKALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20315",
            "district": "11",
            "Block": "182",
            "label": "PRIVATE HSS KALLUPALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20316",
            "district": "11",
            "Block": "182",
            "label": "HF HSS MUKKUTTUKAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20317",
            "district": "11",
            "Block": "182",
            "label": "SMILE ST ANTONY MAT. S KURACODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20318",
            "district": "11",
            "Block": "182",
            "label": "GBFM HS DEVICODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20319",
            "district": "11",
            "Block": "182",
            "label": "NEHRU MEMMORIAL .M.S-KARODE,ARUMANAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20320",
            "district": "11",
            "Block": "182",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  KADAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20321",
            "district": "11",
            "Block": "182",
            "label": "GMS-CHITTAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20322",
            "district": "11",
            "Block": "182",
            "label": "ADI  DRA TRI RES HSS PATHUKANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20323",
            "district": "11",
            "Block": "182",
            "label": "SACRED HEART MIDDLE SCHOOL ,KADAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20324",
            "district": "11",
            "Block": "182",
            "label": "HOLY FAMILY H S ARUKANI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "23359",
            "district": "11",
            "Block": "341",
            "label": "Hari Sree Matriculation Higher Secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20325",
            "district": "11",
            "Block": "182",
            "label": "ST.THOMAS HIGH SCHOOL-NETTA",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20326",
            "district": "11",
            "Block": "182",
            "label": "ST MARYS HSS KALIYAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20327",
            "district": "11",
            "Block": "182",
            "label": "ST.MARY'S CENTRAL SCHOOL .KALIYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20328",
            "district": "11",
            "Block": "182",
            "label": "G S V M S MALACODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20329",
            "district": "11",
            "Block": "182",
            "label": "PPM H S S ANDUCODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20330",
            "district": "11",
            "Block": "182",
            "label": "CHRIST INTERNATIONAL SCHOOL-MALAMARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20331",
            "district": "11",
            "Block": "182",
            "label": "GHS- VELLAMCODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20332",
            "district": "11",
            "Block": "182",
            "label": "GHSS-ARUMANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20333",
            "district": "11",
            "Block": "182",
            "label": "HSS-ARUMANAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20334",
            "district": "11",
            "Block": "182",
            "label": "KAMARAJ MEMORIAL HSS-PANTHALUVILAI, MANJALUMOODU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20335",
            "district": "11",
            "Block": "182",
            "label": "NM VIDYA KENDRA (CBSE)- CHITHARAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20336",
            "district": "11",
            "Block": "182",
            "label": "ARUNODAYA VIDHAYALAYA MATRIC SCHOOL-MANJALUMOODU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20337",
            "district": "11",
            "Block": "182",
            "label": "GHS- PULIYOORSALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20338",
            "district": "11",
            "Block": "124",
            "label": "GHSS-NALLOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20339",
            "district": "11",
            "Block": "124",
            "label": "ST Anne's Matric Higher Secondary School, Elavuvilai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20340",
            "district": "11",
            "Block": "124",
            "label": "SACRED HEART INTERNATIONAL SCHOOL-PAMMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20341",
            "district": "11",
            "Block": "124",
            "label": "GMS KADAMANKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20342",
            "district": "11",
            "Block": "124",
            "label": "ST. FRANCIS XAVIER H.S - ALANCHI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20343",
            "district": "11",
            "Block": "124",
            "label": "ST. ALOYSIUS GIRLS HSS VELLIAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20344",
            "district": "11",
            "Block": "124",
            "label": "LMS HSS - MATHICODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20345",
            "district": "11",
            "Block": "124",
            "label": "K.T.P. HIGHER SEC. SCHOOL MIDALAKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20346",
            "district": "11",
            "Block": "124",
            "label": "RINGLE TAUBE MATRIC HIGHER SECONDARY  SCHOOL-MATHICODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20347",
            "district": "11",
            "Block": "124",
            "label": "GHS UDAYA MARTHANDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20348",
            "district": "11",
            "Block": "124",
            "label": "GHSS MATHICODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20349",
            "district": "11",
            "Block": "124",
            "label": "ST. ANTONY'S GIRLS HIGH SCHOOL ,THUNDATHUVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20350",
            "district": "11",
            "Block": "124",
            "label": "ST. BERNADETTE'S HSS MANGALAKUNTU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20351",
            "district": "11",
            "Block": "124",
            "label": "SAINIKS.MATRIC SCHOOL-KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20352",
            "district": "11",
            "Block": "124",
            "label": "GHSS KEEZHKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20353",
            "district": "11",
            "Block": "124",
            "label": "ST. AHSS ENAMPUTHENTHURAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20354",
            "district": "11",
            "Block": "124",
            "label": "ST. JO HS-RAMANTHURAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20355",
            "district": "11",
            "Block": "124",
            "label": "HAM HSS CHENTHARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20356",
            "district": "11",
            "Block": "124",
            "label": "GHS KEEZHKULAM WEST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20357",
            "district": "11",
            "Block": "124",
            "label": "GMS VENCODE NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20358",
            "district": "11",
            "Block": "124",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL VIZHUNTHAYAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20359",
            "district": "11",
            "Block": "124",
            "label": "ST. FRANCIS HSS VENCODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20360",
            "district": "11",
            "Block": "124",
            "label": "OXFORD MATRIC HIGHER SECONDARY SCHOOL,THOLAYAVATTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20361",
            "district": "11",
            "Block": "124",
            "label": "BETHANI MHSS  VENCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20362",
            "district": "11",
            "Block": "124",
            "label": "GOVT MIDDLE SCHOOL, KANJIRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20363",
            "district": "11",
            "Block": "124",
            "label": "GHS KANJIRACODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20364",
            "district": "11",
            "Block": "124",
            "label": "GHS UNNAMALAIKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20365",
            "district": "11",
            "Block": "124",
            "label": "GHSS NATTALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20366",
            "district": "11",
            "Block": "124",
            "label": "ST. JOHN VIANNEY'S GIRLS' HR. SEC. SCHOOL , PALLIYADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20367",
            "district": "11",
            "Block": "124",
            "label": "ST. MSC HS NATTALAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20368",
            "district": "11",
            "Block": "124",
            "label": "ST. AHS ELAVUVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20369",
            "district": "11",
            "Block": "124",
            "label": "CSI SCHOOL FOR THE BLIND, IRENIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20370",
            "district": "11",
            "Block": "124",
            "label": "INFANT JESUS MATRIC HSS MAMOOTTUKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20371",
            "district": "11",
            "Block": "124",
            "label": "N.A.M. MATRICULATION  HR. SEC. SCHOOL,NELVELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20372",
            "district": "11",
            "Block": "124",
            "label": "SREE CHENBAGA MATRICULATION SCHOOL PAYANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20373",
            "district": "11",
            "Block": "124",
            "label": "GHSS KARUNGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20374",
            "district": "11",
            "Block": "124",
            "label": "ST. ANTONY'S HIGH SCHOOL, MULLANGANAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20375",
            "district": "11",
            "Block": "124",
            "label": "CONCORDIA HR.SEC.SCHOOL-POTTETI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20376",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE  SCHOOL , VENKANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20377",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT  HIGHER SECONDARY  SCHOOL ,KOLLENCODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20378",
            "district": "11",
            "Block": "191",
            "label": "ST JOSEPH HIGH SCHOOL,  SILUVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20379",
            "district": "11",
            "Block": "191",
            "label": "MAR GREGORIOS M.S.C. HIGH SCHOOL KIRATHOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20380",
            "district": "11",
            "Block": "191",
            "label": "ST STEPHEN HIGH SCHOOL  SOUTHPALAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20381",
            "district": "11",
            "Block": "191",
            "label": "SREEDEVI GIRLS Higher Secondary  School  KOLLAMCODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20382",
            "district": "11",
            "Block": "191",
            "label": "ST ALOYSIUS HSS MARTHANDANTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20383",
            "district": "11",
            "Block": "191",
            "label": "RTM LMS HSS VENKANCHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20384",
            "district": "11",
            "Block": "191",
            "label": "SHREE VR V V MATRIC HIGH SCHOOL KOLLEMCODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20385",
            "district": "11",
            "Block": "191",
            "label": "ST NICHOLOSE HS  NEERODITHURAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20386",
            "district": "11",
            "Block": "191",
            "label": "GOVT HSS EZHUDESAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20387",
            "district": "11",
            "Block": "191",
            "label": "ST JOSEPH HS KARUNYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20388",
            "district": "11",
            "Block": "191",
            "label": "PIUS XI  HSS  THOOTHOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20389",
            "district": "11",
            "Block": "191",
            "label": "JAYAMATHA MATRICULATION SCHOOL CHRISTURAJAPURAM NITHRAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20390",
            "district": "11",
            "Block": "191",
            "label": "ST LITTLE FLOWER MHS KANJAMPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20391",
            "district": "11",
            "Block": "191",
            "label": "GOVT ADW HSS KALINGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20392",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT HIGH SCHOOL  ANANTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20393",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  THENGAPATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20394",
            "district": "11",
            "Block": "191",
            "label": "AMSI HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20395",
            "district": "11",
            "Block": "191",
            "label": "AL AMEEN MATRICULATION SCHOOL, THENGAIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20396",
            "district": "11",
            "Block": "191",
            "label": "GPVMS  MANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20397",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL PALLIKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20398",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL MUNCHIRAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20399",
            "district": "11",
            "Block": "191",
            "label": "ST MARYS  MS  PUTHUKDADAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20400",
            "district": "11",
            "Block": "191",
            "label": "ST FRANCIS HSS  VAVARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20401",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL, KALIAKKAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20402",
            "district": "11",
            "Block": "191",
            "label": "Sacred Heart Middle School Kamarajnagar",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20403",
            "district": "11",
            "Block": "191",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL VALANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20404",
            "district": "11",
            "Block": "191",
            "label": "ST.EPHREM'S M.S.C  HSS, SOORIACODE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20405",
            "district": "11",
            "Block": "191",
            "label": "SREE BHAGAVATHY VIDYALAYA MATRIC HR. SEC. SCHOOL MEKKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20406",
            "district": "11",
            "Block": "191",
            "label": "GOVT HS MARAYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20407",
            "district": "11",
            "Block": "191",
            "label": "ST ANTONYS HS KAPPUKADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20408",
            "district": "11",
            "Block": "191",
            "label": "CSI VV HSS IRENIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20409",
            "district": "11",
            "Block": "191",
            "label": "CSI VV (G) HS IRENIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20410",
            "district": "11",
            "Block": "191",
            "label": "SRI VIGNESWARA MHSS KAPPUKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20411",
            "district": "11",
            "Block": "191",
            "label": "C.S.I. GNANASIROMONY MATRICULATION SCHOOL IRENIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20412",
            "district": "11",
            "Block": "191",
            "label": "SRI KRISHNA MATRICULATION  SCHOOL-THAVITTAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20413",
            "district": "11",
            "Block": "191",
            "label": "GOVT HS PALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20414",
            "district": "11",
            "Block": "191",
            "label": "GHS ATHENCODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20415",
            "district": "11",
            "Block": "191",
            "label": "CHRIST THE KING HS PIRACODE",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "23348",
            "district": "11",
            "Block": "3",
            "label": "Bharathi Vidhya Mandir Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20906",
            "district": "11",
            "Block": "3",
            "label": "JOHNS CENTRAL SCHOOL ANJUGRAMAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20910",
            "district": "11",
            "Block": "191",
            "label": "ST.JOHNS MATRIC SCHOOL- THOOTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20921",
            "district": "11",
            "Block": "3",
            "label": "SHEFFIELD INNOVATIVE CBSE SCHOOL ,IRULAPPAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20980",
            "district": "11",
            "Block": "3",
            "label": "MOUNT LITERA ZEE SCHOOL-MYLAUDY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20981",
            "district": "11",
            "Block": "3",
            "label": "OASIS SPECIAL SCHOOL-CHETTIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20982",
            "district": "11",
            "Block": "149",
            "label": "JOTHI NILAYAM,PALAPPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20983",
            "district": "11",
            "Block": "124",
            "label": "ARTESIA MATRICULATION HR SEC SCHOOL KAPPUKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20984",
            "district": "11",
            "Block": "191",
            "label": "ST. MARRY'S SCHOOL AND HOME FOR MENTALLY CHALLENGED CHILDREN KARUNAIILLAM-NAMBALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21125",
            "district": "11",
            "Block": "124",
            "label": "BETHANY NAVAJEEVAN CBSE SCHOOL-VENCODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21188",
            "district": "11",
            "Block": "341",
            "label": "EXCEL GLOBAL SCHOOL-THIRUVATTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21291",
            "district": "11",
            "Block": "149",
            "label": "ARUNACHALA MATRIC HIGHER SECONDARY SCHOOL, KANAKAMMAL GARDENS, KATTUVILAI, VELLICHANTHAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21588",
            "district": "11",
            "Block": "191",
            "label": "NOBLE PUBLIC SCHOOL, KALIAKKAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21622",
            "district": "11",
            "Block": "149",
            "label": "St.Sebastian Matric High School. Madathattuvilai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21623",
            "district": "11",
            "Block": "341",
            "label": "ARKA-THE GREEN SCHOOL-ARUVIKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21624",
            "district": "11",
            "Block": "182",
            "label": "N.V.K.S.VIDYALAYA VETTUVENNI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21625",
            "district": "11",
            "Block": "124",
            "label": "NANJIL CATHOLIC SCHOOL(CBSE)-VAZHUTHALAMPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21626",
            "district": "11",
            "Block": "191",
            "label": "ST.XAVIER ENGLISH SCHOOL-PALAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23683",
            "district": "11",
            "Block": "191",
            "label": "Holy Angels International School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "21810",
            "district": "11",
            "Block": "354",
            "label": "LHL SCHOOL - VISUVASAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21811",
            "district": "11",
            "Block": "191",
            "label": "ST. STEPHEN'S MATRIC SCHOOL, SOUTH PALAVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21812",
            "district": "11",
            "Block": "191",
            "label": "KABD CBSE SCHOOL-PAINKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21813",
            "district": "11",
            "Block": "124",
            "label": "ST.MARY'S SCHOOL , AMANATTANTHERI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21814",
            "district": "11",
            "Block": "124",
            "label": "BEST SCHOOL (CBSE)-PALOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21815",
            "district": "11",
            "Block": "182",
            "label": "HOLY TRINITY INTERNATIONAL SCHOOL-MELPALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21816",
            "district": "11",
            "Block": "341",
            "label": "TRINITY CENTRAL SCHOOL-CHITHRENCODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21817",
            "district": "11",
            "Block": "341",
            "label": "SRK INTERNATIONAL SCHOOL-KULASEKARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21818",
            "district": "11",
            "Block": "149",
            "label": "GOLDEN N&P SCHOOL-CHERAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21819",
            "district": "11",
            "Block": "149",
            "label": "ST.JOSEPH'S N&P SCHOOL-KALIMAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21820",
            "district": "11",
            "Block": "263",
            "label": "GARDENCOURT CAMBRIDGE SCHOOL-KATTADITHATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21821",
            "district": "11",
            "Block": "263",
            "label": "PADMASHREE VIDYALAYA-GANAPATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21875",
            "district": "11",
            "Block": "3",
            "label": "DONGUANELLA SPECIALSCHOOL FOR M.C-S.THAMARAIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21902",
            "district": "11",
            "Block": "3",
            "label": "AVILA SPECIAL SCHOOL-KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21903",
            "district": "11",
            "Block": "3",
            "label": "BISHOP REMIGIUS SCHOOL. CBSE NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21904",
            "district": "11",
            "Block": "149",
            "label": "ADLINE  MATRIC SCHOOL-MADATHATTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21905",
            "district": "11",
            "Block": "149",
            "label": "VINS SCHOOL OF EXCELLENCE-CHUNKANKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21906",
            "district": "11",
            "Block": "263",
            "label": "EVANS SCHOOL OF EXCELLENCE (CBSE), CTM PURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21907",
            "district": "11",
            "Block": "341",
            "label": "ASSISI VIDHYALAYA SCHOOL FOR D.ABLED-CHENAMCODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21908",
            "district": "11",
            "Block": "355",
            "label": "OXFORD MATRICULATION SCHOOL-AZHAGIAMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22284",
            "district": "11",
            "Block": "354",
            "label": "ST.JOSEPH INTERNATIONAL SCHOOL-ARALVAIMOZHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22285",
            "district": "11",
            "Block": "3",
            "label": "St.MICHAEL'S HIGH SCHOOL - RAJAVOOR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22286",
            "district": "11",
            "Block": "263",
            "label": "LITTLE CHAMPION CBSE SCHOOL, PAMBANVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22287",
            "district": "11",
            "Block": "355",
            "label": "THE PACKIANATH PUBLIC SCHOOL- KATTATHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22288",
            "district": "11",
            "Block": "124",
            "label": "ST.JOSEPH HIGH SCHOOL-MATHIRAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22289",
            "district": "11",
            "Block": "191",
            "label": "LITTLE FLOWER CENTRAL SCHOOL-PUSHPAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22290",
            "district": "11",
            "Block": "191",
            "label": "ST.MARY'S CBSE SCHOOL-MARIAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22662",
            "district": "11",
            "Block": "263",
            "label": "ZION INTERNATIONAL SCHOOL-ALAMPARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22663",
            "district": "11",
            "Block": "149",
            "label": "ST.PAULA MONTAL SCHOOL,MUKKALAMPADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22664",
            "district": "11",
            "Block": "182",
            "label": "MAR IVANIOS CBSE SCHOOL,KUZHITHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22665",
            "district": "11",
            "Block": "182",
            "label": "GOVERNMENT HIGH SCHOOL,UTHIRAMCODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22666",
            "district": "11",
            "Block": "124",
            "label": "BETHLAHEM HILLSIDE INTERNATIONAL SCHOOL-KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22667",
            "district": "11",
            "Block": "124",
            "label": "RPA CENTRAL SCHOOL, MAMOOTUKADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22690",
            "district": "11",
            "Block": "263",
            "label": "STELLA MARY'S CBSE SCHOOL-VELLAMODI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22770",
            "district": "11",
            "Block": "3",
            "label": "AROCKIA ANNAI ACADEMY (CBSE) SCHOOL-KOTTAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22771",
            "district": "11",
            "Block": "263",
            "label": "VINMEEN SCHOOL, PARVATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22849",
            "district": "11",
            "Block": "124",
            "label": "BETHLAHEM NEW CBSE CENTRAL SCHOOL, MOOSARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22850",
            "district": "11",
            "Block": "149",
            "label": "MARIA RAFOLS SCHOOL, LEKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22851",
            "district": "11",
            "Block": "341",
            "label": "SDA ENGLISH HIGH SCHOOL, PULIERRANGHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23015",
            "district": "11",
            "Block": "149",
            "label": "SREE VIVEKANANDA CBSE SCHOOL,KOOTTUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23084",
            "district": "11",
            "Block": "341",
            "label": "THRAYAMBIKA VIDYA MANDIR, PADANILAM, KULASEKHARAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23187",
            "district": "11",
            "Block": "3",
            "label": "AVATAR INTERNATIONAL MODEL SCHOOL -VALAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23225",
            "district": "11",
            "Block": "182",
            "label": "CHRIST THE KING SCHOOL- KANNUMAMOODU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23267",
            "district": "11",
            "Block": "263",
            "label": "PONJESLY PUBLIC SCHOOL -ALMAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23489",
            "district": "11",
            "Block": "124",
            "label": "ST.JUDE'S HI-TECH SCHOOL- SOUTH NEERVAKUZHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23490",
            "district": "11",
            "Block": "149",
            "label": "MOTHER THERASA (SCHOOL FOR THE INTELLECTUAL DIASABLES) -KOVILANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23491",
            "district": "11",
            "Block": "341",
            "label": "GTR HIGH SCHOOL -MANALODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23492",
            "district": "11",
            "Block": "354",
            "label": "KOWSHIKA KENDRIYA VIDHYALAYA, DERISANAMCOPE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23711",
            "district": "11",
            "Block": "3",
            "label": "SANTHIDAM BETHANIA MATRICULATION, KANYAKUMARI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23828",
            "district": "11",
            "Block": "124",
            "label": "ST.ALOYSIUS MATRICULATION SCHOOL-VELLIAVILAI ",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24034",
            "district": "11",
            "Block": "354",
            "label": "Kanniyakumari District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "12": [
        {
            "key": "1356",
            "district": "12",
            "Block": "23",
            "label": "CRESCENT MATRIC HSS,PALLAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23657",
            "district": "12",
            "Block": "140",
            "label": "Sri Krishna School ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "2022",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KOVAGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2095",
            "district": "12",
            "Block": "112",
            "label": "TNPL MATRICULATION HR.SEC.SCHOOL, KAGITHAAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2424",
            "district": "12",
            "Block": "140",
            "label": "PUMS, PAPPAKKAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23363",
            "district": "12",
            "Block": "315",
            "label": "SHRI GURU BRAMMA VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "2548",
            "district": "12",
            "Block": "23",
            "label": "ARUMUGAM ACADEMY MATRIC HR.SEC.SCHOOL, ARAVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2561",
            "district": "12",
            "Block": "142",
            "label": "SRI KALAIMAGAL MATRIC HR SEC SCHOOL KULITHALAI KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2718",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KUPPAMETTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2783",
            "district": "12",
            "Block": "112",
            "label": "CHERAN MATRIC.HIGH.SCHOOL, KARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2884",
            "district": "12",
            "Block": "140",
            "label": "PUMS, IRUMBOOTHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3094",
            "district": "12",
            "Block": "348",
            "label": "SERVITE MAT.SCHOOL,THOGAIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3239",
            "district": "12",
            "Block": "348",
            "label": "OMSAKTHI MATRICULATION SCHOOL A. NADUPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3241",
            "district": "12",
            "Block": "315",
            "label": "CHETTINAD RANI MEYYAMMAI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3275",
            "district": "12",
            "Block": "112",
            "label": "ST.THERESA MATRIC. HR. SEC. SCHOOL,KARUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3276",
            "district": "12",
            "Block": "315",
            "label": "MALAR MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3307",
            "district": "12",
            "Block": "112",
            "label": "KARUR VETRI VINAYAKA MATRIC. HR.SEC.SCHOOL, KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3341",
            "district": "12",
            "Block": "315",
            "label": "N.R.M.G & R MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3347",
            "district": "12",
            "Block": "112",
            "label": "KONGU VELLALAR MATRIC. HR.SEC.SCHOOL, KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3362",
            "district": "12",
            "Block": "112",
            "label": "SRI RAMAKRISHNA MATRICULATION SCHOOL, MUTHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3373",
            "district": "12",
            "Block": "112",
            "label": "ST.MARY'S MATRIC HIGHER SECONDARY SCHOOL,VANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3390",
            "district": "12",
            "Block": "23",
            "label": "VENUS MATRIC SCHOOL,PALLAPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3423",
            "district": "12",
            "Block": "140",
            "label": "PUMS , KRISHNARAYAPURAM (G)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3467",
            "district": "12",
            "Block": "140",
            "label": "PUMS,KAMBALIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3504",
            "district": "12",
            "Block": "112",
            "label": "NAVJEEVAN MATRIC SCHOOL,PANCHAMADEVI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3556",
            "district": "12",
            "Block": "23",
            "label": "B.S.P.MATRIC  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3626",
            "district": "12",
            "Block": "142",
            "label": "SRI VIDYA BHARATHI MATRIC HIGHER SECONDARY. SCHOOL, KULITHALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3762",
            "district": "12",
            "Block": "140",
            "label": "SARASWATHY VIDYALAYA  MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3840",
            "district": "12",
            "Block": "112",
            "label": "CHERAN MAT. HR.SEC.SCHOOL, VENNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3866",
            "district": "12",
            "Block": "142",
            "label": "AUROBINDO MATRIC HR SEC SCHOOL NACHALUR KARUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3977",
            "district": "12",
            "Block": "23",
            "label": "HAJEE MEERA ACADEMY,ESANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3993",
            "district": "12",
            "Block": "83",
            "label": "RN MATRIC.HR.SEC.SCHOOL,CHINNADHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4049",
            "district": "12",
            "Block": "142",
            "label": "NALANTHA MATRICULATION SCHOOL,KARUNGALPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4110",
            "district": "12",
            "Block": "112",
            "label": "P.U.MIDDLE SCHOOL,PANCHAMADEVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4122",
            "district": "12",
            "Block": "140",
            "label": "PUMS, S.MUNAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4400",
            "district": "12",
            "Block": "315",
            "label": "LORD'S PARK MATRIC. HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4401",
            "district": "12",
            "Block": "88",
            "label": "KAVERI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4404",
            "district": "12",
            "Block": "140",
            "label": "PUMS, METTANKINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4405",
            "district": "12",
            "Block": "140",
            "label": "TANCEM MATRIC SCHOOL MAYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4449",
            "district": "12",
            "Block": "142",
            "label": "MOUNT GIRIS MATRIC HR SEC SCHOOL AYYARMALAI KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4504",
            "district": "12",
            "Block": "142",
            "label": "LITTLE FAIRIES MAT. SCHOOL, KULITHALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4515",
            "district": "12",
            "Block": "140",
            "label": "PUMS, VADAKKUKALLUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4516",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KEELADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4612",
            "district": "12",
            "Block": "315",
            "label": "ST.ANTONY'S MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4614",
            "district": "12",
            "Block": "112",
            "label": "JAIRAM VIDYA BHAVAN MATRIC.HR.SEC. SCHOOL,THIRUGAMPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4624",
            "district": "12",
            "Block": "315",
            "label": "VELAMMAL VIDHYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4649",
            "district": "12",
            "Block": "112",
            "label": "BHARANI PARK MATRIC.HR.SEC. SCHOOL, VENNAIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4665",
            "district": "12",
            "Block": "112",
            "label": "VIVEKANANDA  MATRIC HIGHER SECONDARY SCHOOL,PASUPATHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4675",
            "district": "12",
            "Block": "112",
            "label": "GURUDEVAR MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4677",
            "district": "12",
            "Block": "112",
            "label": "BHARANI VIDHYALAYA SCHOOL ,VENNAIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4678",
            "district": "12",
            "Block": "315",
            "label": "SRI SANKARA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4714",
            "district": "12",
            "Block": "315",
            "label": "SRI MEENATCHI MATRIC SCHOOL,KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4725",
            "district": "12",
            "Block": "112",
            "label": "TNPL PUBLIC SCHOOL,KAKITHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4745",
            "district": "12",
            "Block": "315",
            "label": "GURU VIDHYALAYA MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4787",
            "district": "12",
            "Block": "112",
            "label": "SRI KRISHNA MATRIC.SCHOOL,VELAYUTHAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4855",
            "district": "12",
            "Block": "23",
            "label": "OXFORD MATRIC SCHOOL,PALLAPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4884",
            "district": "12",
            "Block": "112",
            "label": "KVBOA MATRIC. HR.SEC. SCHOOL, KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4888",
            "district": "12",
            "Block": "112",
            "label": "P.U.MIDDLE SCHOOL, NOCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4927",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,N.PUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4945",
            "district": "12",
            "Block": "88",
            "label": "SRI LAKSHMI MAT.SCHOOL, THARAGAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4962",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,MOOLIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5036",
            "district": "12",
            "Block": "142",
            "label": "PUMS, KADAMBARKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5123",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,THANGARAJ,NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5126",
            "district": "12",
            "Block": "142",
            "label": "PUMS, SIVAYAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5163",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,SEVANTHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5203",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,THIRUMUKKUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5236",
            "district": "12",
            "Block": "112",
            "label": "P.U.MIDDLE SCHOOL,THALAVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5259",
            "district": "12",
            "Block": "142",
            "label": "SRI. VIVEKANANDA AIDED MIDDLE SCHOOL, KANDIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5281",
            "district": "12",
            "Block": "315",
            "label": "STAR SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5307",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5333",
            "district": "12",
            "Block": "140",
            "label": "PUMS VAYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5345",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,ACHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5379",
            "district": "12",
            "Block": "315",
            "label": "LITTLE FLOWER MATRIC  HR. SEC. SCHOOL,MANAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5403",
            "district": "12",
            "Block": "112",
            "label": "LITTLE ANGELS' ENGLISH HR.SEC.SCHOOL,KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5424",
            "district": "12",
            "Block": "88",
            "label": "PUMS, ARASAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5446",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL EAST THAVUTTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5456",
            "district": "12",
            "Block": "112",
            "label": "GANDHIYAR MID.SCHOOL,VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5475",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,MACHANNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5489",
            "district": "12",
            "Block": "315",
            "label": "VENUS MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5495",
            "district": "12",
            "Block": "112",
            "label": "MUNICIPAL JAYAPRAKASH MIDDLE SCHOOL,KARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5524",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MANATHATTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5580",
            "district": "12",
            "Block": "112",
            "label": "MPL PASUPATHIPALAYAM MID SCHOOL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5653",
            "district": "12",
            "Block": "142",
            "label": "ST. DOMINIC SAVIO MAT. SCHOOL, KULITHALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5671",
            "district": "12",
            "Block": "140",
            "label": "PUMS, MAHADANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5682",
            "district": "12",
            "Block": "140",
            "label": "HOLY MADONA MATRIC HR.SEC.SCHOOL ,LALAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5700",
            "district": "12",
            "Block": "142",
            "label": "R.C. AMALARAKINI MID. SCHOOL, KULITHALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5710",
            "district": "12",
            "Block": "142",
            "label": "PUMS, SATHIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5767",
            "district": "12",
            "Block": "112",
            "label": "RAJA AIDED MIDDLE SCHOOL,VEDICHIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5770",
            "district": "12",
            "Block": "112",
            "label": "MPL UMAIYAL MID SCHOOL, KARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5788",
            "district": "12",
            "Block": "142",
            "label": "PUMS, BANGALAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5795",
            "district": "12",
            "Block": "112",
            "label": "VOC AIDED MIDDLE SCHOOL,KARUR.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5894",
            "district": "12",
            "Block": "142",
            "label": "RENGA AID. MID. SCHOOL, PARALI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5899",
            "district": "12",
            "Block": "142",
            "label": "PUMS, THALIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5926",
            "district": "12",
            "Block": "315",
            "label": "SRINIVASA VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5975",
            "district": "12",
            "Block": "88",
            "label": "PUMS. D.SEETHAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6012",
            "district": "12",
            "Block": "83",
            "label": "CHERAN MATRIC HR SEC SCHOOL, PUNNAMCHATHIRAM, KARUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6054",
            "district": "12",
            "Block": "315",
            "label": "PANCHAYAT.UNION MIDDLE .SCHOOL,RETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6058",
            "district": "12",
            "Block": "315",
            "label": "PANCHAYAT.UNION.MIDDLE.SCHOOL,GOVINDHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6136",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,P.PUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6145",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,V.PASUPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6151",
            "district": "12",
            "Block": "112",
            "label": "SRI ANNAI VIDHYALAYA MATRICULATION SCHOOL,VENGAMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6177",
            "district": "12",
            "Block": "88",
            "label": "PUMS, POONJOLAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6184",
            "district": "12",
            "Block": "88",
            "label": "PUMS, KAVARAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6205",
            "district": "12",
            "Block": "112",
            "label": "RASAMMAL MATRIC. Hr.Sec.School, VETTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6206",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MULLIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6260",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,CHINNAMOOKANAKURUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6266",
            "district": "12",
            "Block": "88",
            "label": "PUMS, KURUMBAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6330",
            "district": "12",
            "Block": "140",
            "label": "PUMS , KRISHNARAYAPURAM (B)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6338",
            "district": "12",
            "Block": "88",
            "label": "PUMS, SERVAIKARANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6347",
            "district": "12",
            "Block": "140",
            "label": "PUMS MANAVASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6354",
            "district": "12",
            "Block": "88",
            "label": "PUMS, KALUTHARIKKAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6371",
            "district": "12",
            "Block": "23",
            "label": "G.K.VIDYAMANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6385",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL,SALIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6386",
            "district": "12",
            "Block": "112",
            "label": "PON VIDHYA MANDIR CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6454",
            "district": "12",
            "Block": "142",
            "label": "PUMS, THANEERPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6455",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6465",
            "district": "12",
            "Block": "88",
            "label": "PUMS, CHINTHAMANIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6473",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL, THIRUMANICKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6474",
            "district": "12",
            "Block": "88",
            "label": "PUMS, POOSARIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6485",
            "district": "12",
            "Block": "88",
            "label": "ST. XAVIER'S GOVT. AIDED MID.SCHOOL, SINNANDIPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6501",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,MALAIKOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6536",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,SEMBAGANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6557",
            "district": "12",
            "Block": "88",
            "label": "PUMS, THALIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6566",
            "district": "12",
            "Block": "142",
            "label": "PUMS, POYYAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6591",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MARUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6599",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VEERANAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6638",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL, KARUDAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6697",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,PERIYAKULATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6706",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL, PALLAPATTI URUSU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6722",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,THIRUVALLUVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13827",
            "district": "12",
            "Block": "112",
            "label": "RANGASAMY GOUNDER HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13828",
            "district": "12",
            "Block": "112",
            "label": "P.E.V.R. GOVT.HR.SEC.SCH,NOYYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13829",
            "district": "12",
            "Block": "112",
            "label": "GOVT.BOYS. HR.SEC SCHOOL,PUGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13830",
            "district": "12",
            "Block": "112",
            "label": "GOVT GIRLS HR SEC SCHOOL, PUGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13831",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HIGH.P.THOTTAKURUCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13832",
            "district": "12",
            "Block": "112",
            "label": "LITERA VALLEY ZEE SCHOOL,MANMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13833",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HIGH.SCHOOL,N.PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13834",
            "district": "12",
            "Block": "112",
            "label": "GOVT.GIRLS.HIGHER SECONDARY SCHOOL, VANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13835",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HR.SEC.SCHOOL,VANGAL(B)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13836",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HR.SEC.SCHOOL, MANMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13837",
            "district": "12",
            "Block": "112",
            "label": "SRI SARASWATHI VIDHYA MANDHIR  MATRIC HIGHER SECONDARY SCHOOL, MANMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13838",
            "district": "12",
            "Block": "112",
            "label": "GOVT HIGH SCHOOL,ATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13839",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,VENGAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13840",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID SCHOOL,V.V.G NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13841",
            "district": "12",
            "Block": "112",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PERIYAKULATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13842",
            "district": "12",
            "Block": "112",
            "label": "GOVT.GIRLS HR.SEC SCHOOL,KARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13843",
            "district": "12",
            "Block": "112",
            "label": "MPL (B) HR.SEC.SCHOOL,KARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13844",
            "district": "12",
            "Block": "112",
            "label": "CSI BOY'S HR.SEC SCHOOL,KARUR.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13845",
            "district": "12",
            "Block": "112",
            "label": "CSI GIRL'S HR.SEC SCHOOL,KARUR.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13846",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HIGH SCHOOL,VANGAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13847",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HR.SEC.SCHOOL,NERUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13848",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,N.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13849",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HR.SEC.SCHOOL,RANGANATHANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13850",
            "district": "12",
            "Block": "112",
            "label": "ADW MID.SCH,THIRUKKAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13851",
            "district": "12",
            "Block": "112",
            "label": "MPL KUMARAN HIGH SCH,KARUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13852",
            "district": "12",
            "Block": "112",
            "label": "MORNING STAR HIGH SCHOOL, KARUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13853",
            "district": "12",
            "Block": "112",
            "label": "PASUPATHEESWARA MUNICIPAL GIRLS Hr. Sec. School, Karur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13854",
            "district": "12",
            "Block": "112",
            "label": "KONGU  HR.SEC.SCHOOL, VENNAIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13855",
            "district": "12",
            "Block": "112",
            "label": "VIVEKANANDA MIDDLE SCHOOL,PASUPATHYPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13856",
            "district": "12",
            "Block": "112",
            "label": "VIVEKANANTHA BOYS HR.SEC.SCHOOL,PASUPATHIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13857",
            "district": "12",
            "Block": "112",
            "label": "SRI  SARADA  GIRLS HIGHER  SECONDARY  SCHOOL, PASUPATHIPALAYAM, KARUR-4",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13858",
            "district": "12",
            "Block": "112",
            "label": "MUNICIPAL MIDDLE SCHOOL,THIRUMANILAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13859",
            "district": "12",
            "Block": "315",
            "label": "GOVT.HR.SEC.SCHOOL,K.P.THALAIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13860",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT.HIGH SCHOOL, ANDANKOIL EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13861",
            "district": "12",
            "Block": "315",
            "label": "G.HIGH SCHOOL, KOYAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13862",
            "district": "12",
            "Block": "315",
            "label": "P.U.MID.SCHOOL,SUKKALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13863",
            "district": "12",
            "Block": "315",
            "label": "GOVT.HIGH.SCHOOL, PALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13864",
            "district": "12",
            "Block": "315",
            "label": "P.A.VIDHYABHAVAN HR.SEC.SCHOOL,KAKKAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13865",
            "district": "12",
            "Block": "315",
            "label": "VELAMMAL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13866",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,KODANKIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13867",
            "district": "12",
            "Block": "315",
            "label": "P.U.MID.SCHOOL,RAYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13868",
            "district": "12",
            "Block": "315",
            "label": "G.H.SCHOOL, T.SELLANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13869",
            "district": "12",
            "Block": "315",
            "label": "ADW HIGH SCHOOL, SANAPRETTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13870",
            "district": "12",
            "Block": "315",
            "label": "ST.MARY'S AIDED HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13871",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,GANDHI GRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13872",
            "district": "12",
            "Block": "315",
            "label": "GOVT.HR.SEC.SCHOOL,THANTHONI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13873",
            "district": "12",
            "Block": "315",
            "label": "ST.THERASA GIRLS.HIGHER SECONDARY SCHOOL, KARUR-4",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13874",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,P. VELLALAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13875",
            "district": "12",
            "Block": "315",
            "label": "DR.M.A.M.RAMASAMY CHETTIAR GHS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13876",
            "district": "12",
            "Block": "315",
            "label": "RANI MEYYAMMAI HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13877",
            "district": "12",
            "Block": "315",
            "label": "CHETTINAD VIDYA MANDIR CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13878",
            "district": "12",
            "Block": "315",
            "label": "Government Higher Secondary School ,Uppidamangalam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13879",
            "district": "12",
            "Block": "315",
            "label": "G.H.S,KARRUPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13880",
            "district": "12",
            "Block": "315",
            "label": "ASHRAM HR. SEC. SCHOOL,MANAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13881",
            "district": "12",
            "Block": "315",
            "label": "GOVT. HR. SEC SCHOOL, MANAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13882",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THAMMANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13883",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,PAGANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13884",
            "district": "12",
            "Block": "315",
            "label": "BHARATHI HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13885",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,JALLIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13886",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY.SCHOOL,VELLIYANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13887",
            "district": "12",
            "Block": "315",
            "label": "G.HR.SEC.SCHOOL,VELIYANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13888",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,ALLALIGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13889",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13890",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,BOMMANUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13891",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,JEGATHABI -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13892",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PORANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13893",
            "district": "12",
            "Block": "315",
            "label": "KARUR SARASWATHI VIDHYALAYA HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13894",
            "district": "12",
            "Block": "23",
            "label": "GHSS, MALAIKOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13895",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,ARAVAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13896",
            "district": "12",
            "Block": "23",
            "label": "GOVT GIRLS HIGH SCHOOL,ARAVAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13897",
            "district": "12",
            "Block": "23",
            "label": "GHSS,ARAVAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13898",
            "district": "12",
            "Block": "23",
            "label": "Government High School,Varikkappatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13899",
            "district": "12",
            "Block": "23",
            "label": "GHS,KOVILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13900",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,INANGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13901",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,A.VENKATAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13902",
            "district": "12",
            "Block": "23",
            "label": "GHS,KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13903",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,MOLAIYANDIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13904",
            "district": "12",
            "Block": "23",
            "label": "GHS,SOUNDARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13905",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,PALLAPATTI(NEW)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13906",
            "district": "12",
            "Block": "23",
            "label": "PALLAPATTY HSS,PALLAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13907",
            "district": "12",
            "Block": "23",
            "label": "USWATHUN HASANA ORIENTAL ARABIC,PALLAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13908",
            "district": "12",
            "Block": "23",
            "label": "GHSS,ANDIPATTIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13909",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,ESANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13910",
            "district": "12",
            "Block": "23",
            "label": "GOVT.HIGHER.SEC.SCHOOL,ESANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13911",
            "district": "12",
            "Block": "23",
            "label": "P.U.MID.SCHOOL,ZAMINATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13912",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,PANDILINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13913",
            "district": "12",
            "Block": "83",
            "label": "GOVT.HIGH.SCHOOL,KARVAZHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13914",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,ATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13915",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL, PUNNAM CHATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13916",
            "district": "12",
            "Block": "83",
            "label": "GOVT.ADW.H.S.SCHOOL,PUNNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13917",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL, THATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13918",
            "district": "12",
            "Block": "83",
            "label": "AASEE HR SEC SCHOOL,PAVITHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13919",
            "district": "12",
            "Block": "83",
            "label": "G.H.S.PAVITHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13920",
            "district": "12",
            "Block": "83",
            "label": "GOVT.HR.SEC.SCHOOL,K.PARAMATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13921",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL,THULUKKAM PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13922",
            "district": "12",
            "Block": "83",
            "label": "GOVT.HR.SEC.SCHOOL,THENNILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13923",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,M.THOTTAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13924",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL, KODANTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13925",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,C.GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13926",
            "district": "12",
            "Block": "83",
            "label": "GHS.PERIYATHIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13927",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,ARANGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13928",
            "district": "12",
            "Block": "83",
            "label": "GOVT. BOYS. HR..SEC.SCHOOL,CHINNADHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13929",
            "district": "12",
            "Block": "83",
            "label": "GOVT GIRLS HR SEC SCHOOL,CHINNADHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13930",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,KASIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13931",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, ELLAIMETTUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13932",
            "district": "12",
            "Block": "83",
            "label": "UMAYAL HIGH SCHOOL,EZHIL KUDIL,MUDIGANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13933",
            "district": "12",
            "Block": "83",
            "label": "GOVT HR SEC SCHOOL,THUMBIVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13934",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,VISWANATHAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13935",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, NANJAIKALAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13936",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, ELAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13937",
            "district": "12",
            "Block": "83",
            "label": "GOVT HR SEC SCHOOL,ELAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13938",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, RAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13939",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, NAMBAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13940",
            "district": "12",
            "Block": "140",
            "label": "GHSS , VENGAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13941",
            "district": "12",
            "Block": "140",
            "label": "ADWMS , PILLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13942",
            "district": "12",
            "Block": "140",
            "label": "GHSS , LALAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13943",
            "district": "12",
            "Block": "140",
            "label": "PUMS , MATHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13944",
            "district": "12",
            "Block": "140",
            "label": "PUMS , LALAPET WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13945",
            "district": "12",
            "Block": "140",
            "label": "ANNAI INDRA MEMORIAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13946",
            "district": "12",
            "Block": "140",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , PUNAVASIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13947",
            "district": "12",
            "Block": "140",
            "label": "PUMS , METUMAHADANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13948",
            "district": "12",
            "Block": "140",
            "label": "PUMS , KUPPUREDDYPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13949",
            "district": "12",
            "Block": "140",
            "label": "GHSS , PALAYAJAYANKONDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13950",
            "district": "12",
            "Block": "140",
            "label": "PUMS , PICHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13951",
            "district": "12",
            "Block": "140",
            "label": "GHSS , KRISHNARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13952",
            "district": "12",
            "Block": "140",
            "label": "KSRV HIGHER SECONDARY SCHOOL , KOVAGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13953",
            "district": "12",
            "Block": "140",
            "label": "PUMS, MALAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13954",
            "district": "12",
            "Block": "140",
            "label": "GHS , METTU THIRUKAMPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13955",
            "district": "12",
            "Block": "140",
            "label": "GHS MAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13956",
            "district": "12",
            "Block": "140",
            "label": "GHS, KATTALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13957",
            "district": "12",
            "Block": "140",
            "label": "ADWHSS, CHINNAMANAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13958",
            "district": "12",
            "Block": "140",
            "label": "GHS, VEERARAKKIAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13959",
            "district": "12",
            "Block": "140",
            "label": "PUMS, SENGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13960",
            "district": "12",
            "Block": "140",
            "label": "GHSS, CHINNA SENKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13961",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KANNAMUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13962",
            "district": "12",
            "Block": "140",
            "label": "PUMS, PANJAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13963",
            "district": "12",
            "Block": "140",
            "label": "GOVT MODEL  HR SEC  SCHOOL,  PANJAPATTY KARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13964",
            "district": "12",
            "Block": "140",
            "label": "PUMS,POTHURAVUTHANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13965",
            "district": "12",
            "Block": "140",
            "label": "GHS, MUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13966",
            "district": "12",
            "Block": "140",
            "label": "GHS, KATTARIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13967",
            "district": "12",
            "Block": "140",
            "label": "PUMS,KUPPACHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13968",
            "district": "12",
            "Block": "140",
            "label": "PUMS,VELANKATTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13969",
            "district": "12",
            "Block": "140",
            "label": "GHS, KUPPANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13970",
            "district": "12",
            "Block": "140",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , SENGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13971",
            "district": "12",
            "Block": "140",
            "label": "PUMS, GOUNDAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13972",
            "district": "12",
            "Block": "88",
            "label": "PUMS VILVAMARATHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13973",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VEERIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13974",
            "district": "12",
            "Block": "88",
            "label": "GHSS,KANIYALAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13975",
            "district": "12",
            "Block": "88",
            "label": "GHSS, KURUNIKULATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13976",
            "district": "12",
            "Block": "88",
            "label": "PUMS, PAPPAYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13977",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13978",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13979",
            "district": "12",
            "Block": "88",
            "label": "PUMS, KEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13980",
            "district": "12",
            "Block": "88",
            "label": "PUMS, THALUMBAGOUDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13981",
            "district": "12",
            "Block": "88",
            "label": "GHS, PUDUVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13982",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MAMARATHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13983",
            "district": "12",
            "Block": "88",
            "label": "GHS, THENNILAI-KADAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13984",
            "district": "12",
            "Block": "88",
            "label": "GHS, MANJAPULIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13985",
            "district": "12",
            "Block": "88",
            "label": "MARIST.HR.SEC.SCHOOL, P. UDAYAPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13986",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VARAVANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13987",
            "district": "12",
            "Block": "88",
            "label": "PUMS, ANAIGOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13988",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MYLAMPATTY(BOYS)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13989",
            "district": "12",
            "Block": "88",
            "label": "PUMS, THARAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13990",
            "district": "12",
            "Block": "88",
            "label": "GHSS, THARAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13991",
            "district": "12",
            "Block": "88",
            "label": "SAUDIA ORIENTAL ARABIC HSS, CHINTHAMANIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13992",
            "district": "12",
            "Block": "88",
            "label": "MODEL HR. SEC. SCHOOL, THARAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13993",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VALVARMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13994",
            "district": "12",
            "Block": "88",
            "label": "PUMS, THASIREDDIAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13995",
            "district": "12",
            "Block": "88",
            "label": "ADWHS, MAVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13996",
            "district": "12",
            "Block": "88",
            "label": "PUMS, SENNAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13997",
            "district": "12",
            "Block": "88",
            "label": "GHS, NALLURANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13998",
            "district": "12",
            "Block": "88",
            "label": "PUMS, PALAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13999",
            "district": "12",
            "Block": "88",
            "label": "GHSS, PALAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14000",
            "district": "12",
            "Block": "88",
            "label": "PUMS, E.RAJAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14001",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VALAYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14002",
            "district": "12",
            "Block": "88",
            "label": "PUMS, K. AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14003",
            "district": "12",
            "Block": "88",
            "label": "GHSS, KADAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14004",
            "district": "12",
            "Block": "88",
            "label": "ANNAI LIA HIGHER SECONDARY SCHOOL, SEVAPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14005",
            "district": "12",
            "Block": "88",
            "label": "KGBV, THARAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14006",
            "district": "12",
            "Block": "88",
            "label": "PUMS, ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14007",
            "district": "12",
            "Block": "88",
            "label": "PUMS, D. IDAYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14008",
            "district": "12",
            "Block": "88",
            "label": "GHS, E. SUKKAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14009",
            "district": "12",
            "Block": "142",
            "label": "PUMS, KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14010",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MELAKUPPUREDDIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14011",
            "district": "12",
            "Block": "142",
            "label": "THIRU.V.KA.AID.HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14012",
            "district": "12",
            "Block": "142",
            "label": "PUMS, ADINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23665",
            "district": "12",
            "Block": "23",
            "label": "Ferdin Public School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "14013",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MARIYAMMAN KOVIL, KULITHALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14014",
            "district": "12",
            "Block": "142",
            "label": "BHARATHI VIDYALAYA HIGH SCHOOL, KULITHALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14015",
            "district": "12",
            "Block": "142",
            "label": "GHSS, KULITHALAI (B)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14016",
            "district": "12",
            "Block": "142",
            "label": "GHSS, KULITHALAI (G)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14017",
            "district": "12",
            "Block": "142",
            "label": "PUMS, V. PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14018",
            "district": "12",
            "Block": "142",
            "label": "GOVT ADW HSS, KOTTAMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14019",
            "district": "12",
            "Block": "142",
            "label": "GHS, THIMMACHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14020",
            "district": "12",
            "Block": "142",
            "label": "GHS,THIMAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14021",
            "district": "12",
            "Block": "142",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14022",
            "district": "12",
            "Block": "142",
            "label": "PUMS,SUKKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14023",
            "district": "12",
            "Block": "142",
            "label": "PUMS, I.PUDUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14024",
            "district": "12",
            "Block": "142",
            "label": "GHSS, INUNGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14025",
            "district": "12",
            "Block": "142",
            "label": "PUMS, THIRUCHAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14026",
            "district": "12",
            "Block": "142",
            "label": "PUMS, NANGAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14027",
            "district": "12",
            "Block": "142",
            "label": "N.N.R.HIGH SCHOOL, NANGAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14028",
            "district": "12",
            "Block": "142",
            "label": "GHS, NACHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14029",
            "district": "12",
            "Block": "142",
            "label": "PUMS, KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14030",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MADUVILUNDANPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14031",
            "district": "12",
            "Block": "142",
            "label": "GHSS, AYYARMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14032",
            "district": "12",
            "Block": "142",
            "label": "JOHN MAT. H.S., SIVAYAM EAST",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14033",
            "district": "12",
            "Block": "348",
            "label": "GHSS, G.UDAIYAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14034",
            "district": "12",
            "Block": "348",
            "label": "Panchayat union middle school Muthalaipatti",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14035",
            "district": "12",
            "Block": "348",
            "label": "panchayat union middle school, veeramalaikoundanpatty",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14036",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NEITHALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14037",
            "district": "12",
            "Block": "348",
            "label": "BAGAVATHY GOVERNMENT AIDED HIGH SCHOOL, KAVANDAMPATTY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14038",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, CHINNAPANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14039",
            "district": "12",
            "Block": "348",
            "label": "Adi-Dravida Welfare High School,NEITHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14040",
            "district": "12",
            "Block": "348",
            "label": "G.H.S.SCHOOL, RACHANDAR THIRUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14041",
            "district": "12",
            "Block": "348",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  ARCHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14042",
            "district": "12",
            "Block": "348",
            "label": "PUMS, INAM  PULUTHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14043",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGH SCHOOL, BALASAMUTHIRAPATTI, KARUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14044",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL, KAVALKARANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14045",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL, VADASERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14046",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL,A.NADUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14047",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,KEELAVELIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14048",
            "district": "12",
            "Block": "348",
            "label": "ST.ANNES HIGH SCHOOL, KALLADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14049",
            "district": "12",
            "Block": "348",
            "label": "ST.FATHIMA AIDED MID.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14050",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL, PILLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14051",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, NADAKKAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14052",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THOGAIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14053",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL,MAHALIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14054",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, MOOTAKKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14055",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL, A.UDAYAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14056",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, NALLAKAVUNDAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14057",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL,  NAGANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14058",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, CHINNAREDDIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14059",
            "district": "12",
            "Block": "348",
            "label": "GOVT.A.D.W.H.SCHOOL,THELUNGAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14060",
            "district": "12",
            "Block": "348",
            "label": "PUMS,Chinnaiyampalaiyam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14061",
            "district": "12",
            "Block": "348",
            "label": "Panchayat union middle school Sepalapatti",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14062",
            "district": "12",
            "Block": "348",
            "label": "Panchayat union middle school, PURASAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14063",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20972",
            "district": "12",
            "Block": "23",
            "label": "ISHETHRA INTERNATIONAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21162",
            "district": "12",
            "Block": "83",
            "label": "STAR MATRICULATION HR.SEC. SCHOOL, PAVITHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21355",
            "district": "12",
            "Block": "83",
            "label": "THE INDIAN PUBLIC SCHOOL, POOLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21370",
            "district": "12",
            "Block": "112",
            "label": "GOVERNMENT HIGH SCHOOL, SOMUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21371",
            "district": "12",
            "Block": "348",
            "label": "GGHS,RACHANDAR THIRUMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21503",
            "district": "12",
            "Block": "140",
            "label": "ADWHS, NANTHANKOTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21548",
            "district": "12",
            "Block": "88",
            "label": "SRI KARUNAIRAGAVAJI VIDHAYALAYA HSS, THARAGAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21567",
            "district": "12",
            "Block": "112",
            "label": "MPL KOTTAIMEDU HIGH SCHOOL, KARUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21757",
            "district": "12",
            "Block": "23",
            "label": "USWATHUN HASANA ISLAMIC INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21758",
            "district": "12",
            "Block": "112",
            "label": "EQUITAS GURUKUL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22183",
            "district": "12",
            "Block": "140",
            "label": "GHS, K.OTTAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22184",
            "district": "12",
            "Block": "88",
            "label": "AMUTHASURABHI VIDHYALAYA MATRIC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22512",
            "district": "12",
            "Block": "23",
            "label": "GOVERNMENT HIGH SCHOOL, K . SEETHAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22513",
            "district": "12",
            "Block": "140",
            "label": "GHS, DESIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22514",
            "district": "12",
            "Block": "88",
            "label": "GHS, KADAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22515",
            "district": "12",
            "Block": "142",
            "label": "SIDDHARTH PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22516",
            "district": "12",
            "Block": "142",
            "label": "SHREE SAI VIDHYALAYA CBSE SCHOOL,V.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22517",
            "district": "12",
            "Block": "142",
            "label": "SITA RAJARAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22518",
            "district": "12",
            "Block": "348",
            "label": "GHS,BOMMANAYAKKANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22911",
            "district": "12",
            "Block": "88",
            "label": "ST.Joseph's Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22974",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGH SCHOOL, PATHIRIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22975",
            "district": "12",
            "Block": "88",
            "label": "GOVERNMENT HIGH SCHOOL, SONAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23000",
            "district": "12",
            "Block": "315",
            "label": "SHRI VIJAYALAKSHMI  VIDHYALAYAA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23027",
            "district": "12",
            "Block": "112",
            "label": "SHARVALAKSHA VIDHYAALAYAA,VETTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23033",
            "district": "12",
            "Block": "315",
            "label": "VIJAY VIDHYA MANDIR ,EMOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23044",
            "district": "12",
            "Block": "315",
            "label": "KARUR SARASWATHI VIDHYA MANDHIRR, THULASIKODUMBU, KARUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23045",
            "district": "12",
            "Block": "83",
            "label": "VENUS GLOBAL CAMPUS SCHOOL(CBSE),PUNNAMCHATHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23149",
            "district": "12",
            "Block": "140",
            "label": "ISHA VIDHYA JUSTDIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23217",
            "district": "12",
            "Block": "112",
            "label": "SRI CHAITANYA TECHNO SCHOOL , KARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23299",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGH SCHOOL, KALLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23493",
            "district": "12",
            "Block": "83",
            "label": "CHERAN UNIVERSAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23494",
            "district": "12",
            "Block": "315",
            "label": "KARUR PUBLIC SCHOOL(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23495",
            "district": "12",
            "Block": "315",
            "label": "THE ADRIAN LOYAL SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23726",
            "district": "12",
            "Block": "23",
            "label": "USWATHUN HASANA ORIENTAL ARABIC,PALLAPATTI",
            "Sch_type": "Fully AidedHigher SecondarySchool"
        },
        {
            "key": "23798",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MAMARATHUPATTY",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23799",
            "district": "12",
            "Block": "88",
            "label": "PUMS, ALATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23841",
            "district": "12",
            "Block": "140",
            "label": "Karur District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23844",
            "district": "12",
            "Block": "142",
            "label": "PUMS, NALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23845",
            "district": "12",
            "Block": "142",
            "label": "PUMS, KURICHI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24028",
            "district": "12",
            "Block": "348",
            "label": "S.V.C PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "13": [
        {
            "key": "443",
            "district": "13",
            "Block": "78",
            "label": "ACHIEVERS ACADEMY MATRIC.HSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "547",
            "district": "13",
            "Block": "376",
            "label": "SRI VIDYA MANDIR MHSS UTHANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "999",
            "district": "13",
            "Block": "78",
            "label": "CSI CHRIST MATRIC. HSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1082",
            "district": "13",
            "Block": "139",
            "label": "DON BOSCO MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1250",
            "district": "13",
            "Block": "139",
            "label": "TRINITY MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1416",
            "district": "13",
            "Block": "78",
            "label": "SRI VIJAY VIDYALAYA MHSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1437",
            "district": "13",
            "Block": "139",
            "label": "SRI VIJAY VIDYALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1447",
            "district": "13",
            "Block": "35",
            "label": "ST.PAULS MATRIC. BARGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1544",
            "district": "13",
            "Block": "78",
            "label": "SRI SHARADA VIDYA MANDIR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1770",
            "district": "13",
            "Block": "139",
            "label": "D.K.SAMY MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1804",
            "district": "13",
            "Block": "117",
            "label": "NATIONAL MS VARATTAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1849",
            "district": "13",
            "Block": "376",
            "label": "VIDYA VIKAS MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1916",
            "district": "13",
            "Block": "78",
            "label": "ALPHA ELITE HIGH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2464",
            "district": "13",
            "Block": "78",
            "label": "SEVENTHDAY HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2507",
            "district": "13",
            "Block": "311",
            "label": "INECA MAT.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2748",
            "district": "13",
            "Block": "122",
            "label": "SRI SARADHA MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2756",
            "district": "13",
            "Block": "35",
            "label": "ST.KANAKADASA MHSS AMMERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2778",
            "district": "13",
            "Block": "122",
            "label": "ST JOSEPHS MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2803",
            "district": "13",
            "Block": "78",
            "label": "GREEN VALLEY MAT.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2814",
            "district": "13",
            "Block": "35",
            "label": "SRI VIJAYA VIHAS MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2830",
            "district": "13",
            "Block": "78",
            "label": "VAILANKANNI MHSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2902",
            "district": "13",
            "Block": "78",
            "label": "SRI VENKATESWARA MHSS AVALAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2936",
            "district": "13",
            "Block": "122",
            "label": "HOLY CROSS MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2975",
            "district": "13",
            "Block": "117",
            "label": "SRI RAJARAJESWARI VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3185",
            "district": "13",
            "Block": "78",
            "label": "PARIMALAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3193",
            "district": "13",
            "Block": "291",
            "label": "VAILANKANNI MHSS SHOOLAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3199",
            "district": "13",
            "Block": "78",
            "label": "SAPTHAGIRI MAT.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3265",
            "district": "13",
            "Block": "376",
            "label": "PUMS MUSILIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3297",
            "district": "13",
            "Block": "35",
            "label": "VICTORIA MATRIC,HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3352",
            "district": "13",
            "Block": "35",
            "label": "SELVA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3365",
            "district": "13",
            "Block": "78",
            "label": "PUMS ACHETTIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3383",
            "district": "13",
            "Block": "78",
            "label": "VISWA BHARATHI MATRIC.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3399",
            "district": "13",
            "Block": "122",
            "label": "PUMS HANUMANTHAPURAM TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3457",
            "district": "13",
            "Block": "78",
            "label": "YOGI VEMANA MATRIC.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3466",
            "district": "13",
            "Block": "78",
            "label": "PUMS HOSUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3509",
            "district": "13",
            "Block": "35",
            "label": "SRV MATRIC HSS ONDIMAVATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3525",
            "district": "13",
            "Block": "35",
            "label": "MGM HSS PERANDAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3604",
            "district": "13",
            "Block": "35",
            "label": "VAILANKANNI MHSS BARGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3665",
            "district": "13",
            "Block": "376",
            "label": "PUMS MOONGILERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3667",
            "district": "13",
            "Block": "376",
            "label": "PUMS KOTTARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3668",
            "district": "13",
            "Block": "78",
            "label": "PUMS BOMMANDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3674",
            "district": "13",
            "Block": "117",
            "label": "GOPI KRISHNA MATRICULATION HR.SEC SCHOOL DASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3688",
            "district": "13",
            "Block": "376",
            "label": "PUMS ELAVAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3737",
            "district": "13",
            "Block": "122",
            "label": "PUMS EANIMUCHANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3798",
            "district": "13",
            "Block": "78",
            "label": "SRI BHARATHI VIDYALAYA MHSS ALASANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3799",
            "district": "13",
            "Block": "177",
            "label": "SUNFLOWER MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3801",
            "district": "13",
            "Block": "78",
            "label": "ST.JOSEPH'S MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3820",
            "district": "13",
            "Block": "78",
            "label": "ST.AUGUSTINE MAT.HR SEC SCHOOL ONNALVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3867",
            "district": "13",
            "Block": "78",
            "label": "ST.ANN'S MATRICULATION.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3880",
            "district": "13",
            "Block": "78",
            "label": "PUMS GOLLAPALLI -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3889",
            "district": "13",
            "Block": "78",
            "label": "PUMS NAGONDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3903",
            "district": "13",
            "Block": "78",
            "label": "PUMS MUGULAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3910",
            "district": "13",
            "Block": "139",
            "label": "MTV MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3955",
            "district": "13",
            "Block": "78",
            "label": "PUMS MATHIGIRI BOYS -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3967",
            "district": "13",
            "Block": "78",
            "label": "PUMS SEETHARAM NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3974",
            "district": "13",
            "Block": "122",
            "label": "PUMS ANUSONAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3981",
            "district": "13",
            "Block": "78",
            "label": "PUMS KUMUDEPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4017",
            "district": "13",
            "Block": "78",
            "label": "PUMS BEDARAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4050",
            "district": "13",
            "Block": "78",
            "label": "PUMS BAGALUR -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4055",
            "district": "13",
            "Block": "78",
            "label": "PUMS BEGAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4074",
            "district": "13",
            "Block": "78",
            "label": "PUMS ARASANATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4081",
            "district": "13",
            "Block": "122",
            "label": "SEVENTH DAY MATRIC SCHOOL MARASANDIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4087",
            "district": "13",
            "Block": "122",
            "label": "PUMS H.SETTIPALLI TAM&TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4096",
            "district": "13",
            "Block": "122",
            "label": "PUMS ECHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4100",
            "district": "13",
            "Block": "311",
            "label": "PUMS SITHANDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4111",
            "district": "13",
            "Block": "376",
            "label": "PUMS PULIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4126",
            "district": "13",
            "Block": "78",
            "label": "PUMS PUNGANDODDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4139",
            "district": "13",
            "Block": "122",
            "label": "PUMS AGGONDAPALLI TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4140",
            "district": "13",
            "Block": "122",
            "label": "PUMS RAYAKOTTAI URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4158",
            "district": "13",
            "Block": "311",
            "label": "PUMS SEENKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4164",
            "district": "13",
            "Block": "78",
            "label": "PUMS GOPANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4174",
            "district": "13",
            "Block": "311",
            "label": "PUMS SARAKAPALLY -TAM-TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4245",
            "district": "13",
            "Block": "139",
            "label": "SRI SAI KRISHNA VIDYAGIRI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4248",
            "district": "13",
            "Block": "78",
            "label": "BHAGAVANSHREE RAMAKRISHNA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4254",
            "district": "13",
            "Block": "376",
            "label": "PUMS U.REDDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4263",
            "district": "13",
            "Block": "78",
            "label": "SREE SARASWATHI VID. MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4270",
            "district": "13",
            "Block": "78",
            "label": "PUMS RAM NAGAR- URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4274",
            "district": "13",
            "Block": "122",
            "label": "PUMS KANDAGANAPALLI TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4277",
            "district": "13",
            "Block": "122",
            "label": "PUMS KOTTAIULIMANGALAM TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4318",
            "district": "13",
            "Block": "311",
            "label": "PUMS UNISENATHAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4327",
            "district": "13",
            "Block": "78",
            "label": "PUMS KELAVARAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4361",
            "district": "13",
            "Block": "122",
            "label": "PUMS KOWTHALAM TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4386",
            "district": "13",
            "Block": "78",
            "label": "PUMS POONAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4390",
            "district": "13",
            "Block": "78",
            "label": "PUMS CHENNASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4399",
            "district": "13",
            "Block": "311",
            "label": "PUMS MEKALAGOWNUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4408",
            "district": "13",
            "Block": "311",
            "label": "PUMS ANNIYALAM -TAM-TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4423",
            "district": "13",
            "Block": "78",
            "label": "PUMS HANUMANTHAPURAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4437",
            "district": "13",
            "Block": "311",
            "label": "PUMS BELPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4445",
            "district": "13",
            "Block": "311",
            "label": "PUMS MAVANATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4454",
            "district": "13",
            "Block": "122",
            "label": "PUMS ULLUKURUKKI URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4456",
            "district": "13",
            "Block": "376",
            "label": "PUMS VELLAKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4461",
            "district": "13",
            "Block": "311",
            "label": "PUMS BENNANGUR -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4464",
            "district": "13",
            "Block": "376",
            "label": "PUMS BADAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4465",
            "district": "13",
            "Block": "311",
            "label": "PUMS CHOODASANDIRAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4496",
            "district": "13",
            "Block": "311",
            "label": "PUMS KEMPAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4498",
            "district": "13",
            "Block": "311",
            "label": "PUMS BENNANGUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4500",
            "district": "13",
            "Block": "78",
            "label": "PUMS MUGALUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4501",
            "district": "13",
            "Block": "311",
            "label": "PUMS BELUR -TEL - ENG",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4512",
            "district": "13",
            "Block": "311",
            "label": "PUMS VEERASETTIYERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4519",
            "district": "13",
            "Block": "122",
            "label": "PUMS POONAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4526",
            "district": "13",
            "Block": "78",
            "label": "PUMS KOTHAGONDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4532",
            "district": "13",
            "Block": "122",
            "label": "PUMS GIRIYANAPALLI TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4545",
            "district": "13",
            "Block": "122",
            "label": "PUMS ZEEBI TAM / TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4552",
            "district": "13",
            "Block": "311",
            "label": "PUMS BETTAPALLY(U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4568",
            "district": "13",
            "Block": "311",
            "label": "PUMS MARAGATTA DODDY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4595",
            "district": "13",
            "Block": "311",
            "label": "PUMS DODDABILIMUDIRAI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4613",
            "district": "13",
            "Block": "311",
            "label": "PUMS BINNAMANGALAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4629",
            "district": "13",
            "Block": "311",
            "label": "PUMS ULIBANDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4639",
            "district": "13",
            "Block": "311",
            "label": "PUMS AGALAKOTTA -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4643",
            "district": "13",
            "Block": "35",
            "label": "KINGSLEY GARDEN'S MHSS KANDIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4680",
            "district": "13",
            "Block": "78",
            "label": "PUMS ZEEMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4681",
            "district": "13",
            "Block": "398",
            "label": "SRI SARASWATHI VID.MHSS, KUNDARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4688",
            "district": "13",
            "Block": "398",
            "label": "VAILANKANNI MHSS, VEPPANAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4705",
            "district": "13",
            "Block": "376",
            "label": "PUMS NAPPIRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4723",
            "district": "13",
            "Block": "311",
            "label": "PUMS CHINNAUBBANUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4736",
            "district": "13",
            "Block": "78",
            "label": "PUMS HOSUR -TEL&KAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4739",
            "district": "13",
            "Block": "117",
            "label": "ROYAL MATRIC HR SEC SCHOOL, ARS NAGAR,KAVERIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4777",
            "district": "13",
            "Block": "122",
            "label": "PUMS THOTTIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4825",
            "district": "13",
            "Block": "376",
            "label": "ADHIYAMAN MHSS UTHANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4890",
            "district": "13",
            "Block": "398",
            "label": "PUMS EGUDATHAMPALLI URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4914",
            "district": "13",
            "Block": "122",
            "label": "PUMS ODAYANDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4922",
            "district": "13",
            "Block": "139",
            "label": "TCR MATRIC  SCHOOL RAYAKOTTAI ROAD KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4931",
            "district": "13",
            "Block": "398",
            "label": "PUMS JINKALURE  URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4948",
            "district": "13",
            "Block": "122",
            "label": "PUMS GULLATTI TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4974",
            "district": "13",
            "Block": "376",
            "label": "PUMS ODDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4976",
            "district": "13",
            "Block": "122",
            "label": "PUMS VARAGANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4987",
            "district": "13",
            "Block": "398",
            "label": "PUMS VEPPANAPALLI - URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5002",
            "district": "13",
            "Block": "35",
            "label": "KAMESHVER VIDYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5007",
            "district": "13",
            "Block": "376",
            "label": "PUMS VEPPALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5009",
            "district": "13",
            "Block": "122",
            "label": "PUMS GEDDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5026",
            "district": "13",
            "Block": "78",
            "label": "PUMS ALASANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5044",
            "district": "13",
            "Block": "311",
            "label": "PUMS SALIVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5045",
            "district": "13",
            "Block": "311",
            "label": "PUMS DEVARULIMANGALAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5095",
            "district": "13",
            "Block": "122",
            "label": "PUMS THEN DINNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5137",
            "district": "13",
            "Block": "398",
            "label": "PUMS KUPPACHIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5162",
            "district": "13",
            "Block": "122",
            "label": "PUMS NELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5168",
            "district": "13",
            "Block": "376",
            "label": "PUMS THURINJIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5181",
            "district": "13",
            "Block": "291",
            "label": "PUMS NALLARALAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5283",
            "district": "13",
            "Block": "122",
            "label": "PUMS DENKANIKOTTA URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5322",
            "district": "13",
            "Block": "291",
            "label": "PUMS KOTTANGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5340",
            "district": "13",
            "Block": "78",
            "label": "PUMS CHENNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5359",
            "district": "13",
            "Block": "311",
            "label": "POORNA KAMALA VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5365",
            "district": "13",
            "Block": "122",
            "label": "PUMS THOTTINAYAKANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5376",
            "district": "13",
            "Block": "35",
            "label": "PUMS NERALAKOTTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5410",
            "district": "13",
            "Block": "398",
            "label": "PUMS VIRUPPASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5417",
            "district": "13",
            "Block": "291",
            "label": "PUMS  BELLATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5494",
            "district": "13",
            "Block": "376",
            "label": "PUMS GENGAPIRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5538",
            "district": "13",
            "Block": "311",
            "label": "PUMS T.KOTHANUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5553",
            "district": "13",
            "Block": "291",
            "label": "PUMS VEMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5565",
            "district": "13",
            "Block": "311",
            "label": "PUMS KATCHUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5576",
            "district": "13",
            "Block": "78",
            "label": "SHANTHI NIKETHAN MHSS, S.MUDUGANAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5593",
            "district": "13",
            "Block": "122",
            "label": "PUMS J KARUPALLI TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5595",
            "district": "13",
            "Block": "376",
            "label": "PUMS KADAVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5598",
            "district": "13",
            "Block": "122",
            "label": "PUMS KELAMANGALAM URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5603",
            "district": "13",
            "Block": "78",
            "label": "SRI SATHYA SAI BALAGURUKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5613",
            "district": "13",
            "Block": "78",
            "label": "BETHEL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5623",
            "district": "13",
            "Block": "398",
            "label": "PUMS THIMMASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5673",
            "district": "13",
            "Block": "139",
            "label": "BHARAT MATRIC HSS, KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5718",
            "district": "13",
            "Block": "122",
            "label": "PUMS U.PURAM TAM/TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5724",
            "district": "13",
            "Block": "291",
            "label": "Er.PERUMAL MANIMEKALAI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5802",
            "district": "13",
            "Block": "311",
            "label": "PUMS THIMMENATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5818",
            "district": "13",
            "Block": "291",
            "label": "PUMS BASTHALAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5848",
            "district": "13",
            "Block": "376",
            "label": "PUMS PERUMALNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5857",
            "district": "13",
            "Block": "35",
            "label": "PUMS PONGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5882",
            "district": "13",
            "Block": "311",
            "label": "PUMS KADAGANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5904",
            "district": "13",
            "Block": "311",
            "label": "PUMS ARUPALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5924",
            "district": "13",
            "Block": "177",
            "label": "PUMS KOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5930",
            "district": "13",
            "Block": "122",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5987",
            "district": "13",
            "Block": "122",
            "label": "VAILANKANNI MHSS DENKANIKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6009",
            "district": "13",
            "Block": "78",
            "label": "ST.ANNE'S MAT.SCHOOL BAGALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6015",
            "district": "13",
            "Block": "376",
            "label": "SIVA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6030",
            "district": "13",
            "Block": "291",
            "label": "PUMS BEERPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6068",
            "district": "13",
            "Block": "35",
            "label": "C.S.I. MIDDLE SCHOOL, MGR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6082",
            "district": "13",
            "Block": "376",
            "label": "PUMS CHINNAKARAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6094",
            "district": "13",
            "Block": "376",
            "label": "CHRIST MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6111",
            "district": "13",
            "Block": "35",
            "label": "PUMS MAGADEVAGOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6117",
            "district": "13",
            "Block": "117",
            "label": "GREEN VALLEY  INTERNATIONAL MATRIC HIGHER SECONDARY SCHOOL - PANNANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6130",
            "district": "13",
            "Block": "376",
            "label": "CHRIST MATRIC SCHOOL, SINGARAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6137",
            "district": "13",
            "Block": "35",
            "label": "PUMS DESUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6139",
            "district": "13",
            "Block": "35",
            "label": "PUMS KEEL POONGURTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6173",
            "district": "13",
            "Block": "35",
            "label": "PUMS NALLAPPANAYAKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6185",
            "district": "13",
            "Block": "122",
            "label": "PUMS JAKKERI TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6203",
            "district": "13",
            "Block": "311",
            "label": "PUMS NOGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6211",
            "district": "13",
            "Block": "177",
            "label": "PUMS KUMARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6217",
            "district": "13",
            "Block": "311",
            "label": "PUMS A.PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6235",
            "district": "13",
            "Block": "376",
            "label": "PUMS SINGARAPETTAI URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6258",
            "district": "13",
            "Block": "311",
            "label": "PUMS MALLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6259",
            "district": "13",
            "Block": "398",
            "label": "PUMS NACHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6281",
            "district": "13",
            "Block": "376",
            "label": "PUMS JOTHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6304",
            "district": "13",
            "Block": "398",
            "label": "PUMS  BALANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6306",
            "district": "13",
            "Block": "291",
            "label": "PUMS MARANDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6314",
            "district": "13",
            "Block": "291",
            "label": "PUMS KADHIREPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6352",
            "district": "13",
            "Block": "139",
            "label": "NALANDA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6377",
            "district": "13",
            "Block": "291",
            "label": "PUMS MADHARASANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6380",
            "district": "13",
            "Block": "291",
            "label": "PUMS BEELALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6383",
            "district": "13",
            "Block": "122",
            "label": "NEW GENERATION MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6401",
            "district": "13",
            "Block": "398",
            "label": "PUMS  KURIYANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6404",
            "district": "13",
            "Block": "291",
            "label": "PUMS CHENNAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6409",
            "district": "13",
            "Block": "177",
            "label": "SHRI VENKATESWARA VID. MHSS MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6426",
            "district": "13",
            "Block": "398",
            "label": "PUMS  AVALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6429",
            "district": "13",
            "Block": "78",
            "label": "BHARATHIYAR MAT.SCHOOL, BALAJI NAGAR, CHINNAELASAGIRI, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6443",
            "district": "13",
            "Block": "122",
            "label": "PUMS SOOLAKUNTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6444",
            "district": "13",
            "Block": "35",
            "label": "PUMS KULLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6445",
            "district": "13",
            "Block": "35",
            "label": "PUMS ANAKODI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6447",
            "district": "13",
            "Block": "376",
            "label": "PUMS MARAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6466",
            "district": "13",
            "Block": "291",
            "label": "PUMS B.CHENNASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6471",
            "district": "13",
            "Block": "117",
            "label": "THIRU ARUT PRAKASHA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6489",
            "district": "13",
            "Block": "35",
            "label": "PUMS MITTAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6554",
            "district": "13",
            "Block": "376",
            "label": "PUMS KODALAVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6556",
            "district": "13",
            "Block": "177",
            "label": "PUMS MADHAMPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6562",
            "district": "13",
            "Block": "376",
            "label": "PUMS KATTANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6564",
            "district": "13",
            "Block": "291",
            "label": "PUMS PUNNAGARAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6569",
            "district": "13",
            "Block": "177",
            "label": "PUMS OLAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6577",
            "district": "13",
            "Block": "398",
            "label": "PUMS JEDUKOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6588",
            "district": "13",
            "Block": "35",
            "label": "PUMS PONNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6601",
            "district": "13",
            "Block": "35",
            "label": "PUMS CHETTIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6603",
            "district": "13",
            "Block": "311",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6682",
            "district": "13",
            "Block": "398",
            "label": "CRESCENT MHSS KURUBARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6690",
            "district": "13",
            "Block": "35",
            "label": "PUMS SUNDAGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6693",
            "district": "13",
            "Block": "35",
            "label": "PUMS KARADI GOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6702",
            "district": "13",
            "Block": "291",
            "label": "PUMS CHEMBARASANAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6720",
            "district": "13",
            "Block": "398",
            "label": "PUMS  BOMMARASANAPALLI (TELUGU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6732",
            "district": "13",
            "Block": "35",
            "label": "PUMS MARIMANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20416",
            "district": "13",
            "Block": "376",
            "label": "GHS A.PALLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20417",
            "district": "13",
            "Block": "376",
            "label": "PUMS ANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20418",
            "district": "13",
            "Block": "376",
            "label": "GHSS ATHIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20419",
            "district": "13",
            "Block": "376",
            "label": "GHS CHENNAPPANAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20420",
            "district": "13",
            "Block": "376",
            "label": "GHS P.ETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20421",
            "district": "13",
            "Block": "376",
            "label": "GHS GOVINDAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20422",
            "district": "13",
            "Block": "376",
            "label": "GHS HANUMANTHEERTHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20423",
            "district": "13",
            "Block": "376",
            "label": "GHSS KALLAVI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20424",
            "district": "13",
            "Block": "376",
            "label": "GHS- GIRLS KALLAVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20425",
            "district": "13",
            "Block": "376",
            "label": "WISDOM MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20426",
            "district": "13",
            "Block": "376",
            "label": "SRI BALAVIDHYA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20427",
            "district": "13",
            "Block": "376",
            "label": "GHSS KARAPATTU - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20428",
            "district": "13",
            "Block": "376",
            "label": "GHSS KEELKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20429",
            "district": "13",
            "Block": "376",
            "label": "GHS KEELMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20430",
            "district": "13",
            "Block": "376",
            "label": "PUMS KETHUNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20431",
            "district": "13",
            "Block": "376",
            "label": "PUMS KOMMAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20432",
            "district": "13",
            "Block": "376",
            "label": "GHS KONAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20433",
            "district": "13",
            "Block": "376",
            "label": "PUMS KONDAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20434",
            "district": "13",
            "Block": "376",
            "label": "GHS KOTTUKARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20435",
            "district": "13",
            "Block": "376",
            "label": "PUMS LAKKAMPATTI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20436",
            "district": "13",
            "Block": "376",
            "label": "GHSS MAGANURPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20437",
            "district": "13",
            "Block": "376",
            "label": "GHSS MITTAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20438",
            "district": "13",
            "Block": "376",
            "label": "MARY WARD MATRIC HSS MITTAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20439",
            "district": "13",
            "Block": "376",
            "label": "BALA MANDIR MATRICULATION SCHOOL B.PUDUR, MITTAPALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20440",
            "district": "13",
            "Block": "376",
            "label": "GHS NAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20441",
            "district": "13",
            "Block": "376",
            "label": "PUMS NALLAVAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20442",
            "district": "13",
            "Block": "376",
            "label": "GHSS PERIYATHALLAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20443",
            "district": "13",
            "Block": "376",
            "label": "GHS PUNGANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20444",
            "district": "13",
            "Block": "376",
            "label": "GHSS SINGARAPETTAI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20445",
            "district": "13",
            "Block": "376",
            "label": "GHSS- GIRLS  SINGARAPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20446",
            "district": "13",
            "Block": "376",
            "label": "MOTHERS MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20447",
            "district": "13",
            "Block": "376",
            "label": "GHS THIRUVANAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20448",
            "district": "13",
            "Block": "376",
            "label": "GHS UPPARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20449",
            "district": "13",
            "Block": "376",
            "label": "GHSS UTHANGARAI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20450",
            "district": "13",
            "Block": "376",
            "label": "GHSS-GIRLS UTHANGARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20451",
            "district": "13",
            "Block": "376",
            "label": "PUMS CHENGALNEERPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20452",
            "district": "13",
            "Block": "376",
            "label": "GHS EGGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20453",
            "district": "13",
            "Block": "376",
            "label": "PUMS P.NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20454",
            "district": "13",
            "Block": "376",
            "label": "GHS N.VELLALAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20455",
            "district": "13",
            "Block": "139",
            "label": "GHS ITTIKKALAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20456",
            "district": "13",
            "Block": "139",
            "label": "PUMS AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20457",
            "district": "13",
            "Block": "139",
            "label": "GHSS ALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20458",
            "district": "13",
            "Block": "139",
            "label": "PUMS BALAGURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20459",
            "district": "13",
            "Block": "139",
            "label": "PUMS BALIGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20460",
            "district": "13",
            "Block": "139",
            "label": "PUMS BELLAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20461",
            "district": "13",
            "Block": "139",
            "label": "GHS BELLARAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20462",
            "district": "13",
            "Block": "139",
            "label": "GHS BETTEPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20463",
            "district": "13",
            "Block": "139",
            "label": "PUMS BOGANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20464",
            "district": "13",
            "Block": "139",
            "label": "D.K.SAMY MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20465",
            "district": "13",
            "Block": "139",
            "label": "GHS CHIKKAPOOVATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20466",
            "district": "13",
            "Block": "139",
            "label": "GHSS CHINNAMELUPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20467",
            "district": "13",
            "Block": "139",
            "label": "PUMS DHASIRIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20468",
            "district": "13",
            "Block": "139",
            "label": "PUMS EKKALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20469",
            "district": "13",
            "Block": "139",
            "label": "GHS GANGALERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20470",
            "district": "13",
            "Block": "139",
            "label": "GHS GIDDAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20471",
            "district": "13",
            "Block": "139",
            "label": "PUMS GOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20472",
            "district": "13",
            "Block": "139",
            "label": "GHS GUMMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20473",
            "district": "13",
            "Block": "139",
            "label": "PUMS K.A.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20474",
            "district": "13",
            "Block": "139",
            "label": "GHS JAGIR VENKATAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20475",
            "district": "13",
            "Block": "139",
            "label": "GHSS K.R.P.DAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20476",
            "district": "13",
            "Block": "139",
            "label": "PUMS KALLAKURUKKI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20477",
            "district": "13",
            "Block": "139",
            "label": "GHS KAMMAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20478",
            "district": "13",
            "Block": "139",
            "label": "TES HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20479",
            "district": "13",
            "Block": "139",
            "label": "VIJAY MILLINIUM CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20480",
            "district": "13",
            "Block": "139",
            "label": "PUMS KOTHUR GIRLS (U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20481",
            "district": "13",
            "Block": "139",
            "label": "SVC MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20482",
            "district": "13",
            "Block": "139",
            "label": "GHS MADEPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20483",
            "district": "13",
            "Block": "139",
            "label": "GHS MAHARAJAKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20484",
            "district": "13",
            "Block": "139",
            "label": "GHS MADHINAYANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20485",
            "district": "13",
            "Block": "139",
            "label": "GHS MEKALACHINNAMPALLI (GIRLS)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20486",
            "district": "13",
            "Block": "139",
            "label": "KAMARAJ GHSS  MEKALACHINNAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20487",
            "district": "13",
            "Block": "139",
            "label": "PUMS MELERIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20488",
            "district": "13",
            "Block": "139",
            "label": "GHS MITTAPALLI(KRI)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20489",
            "district": "13",
            "Block": "139",
            "label": "PUMS NARALAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20490",
            "district": "13",
            "Block": "139",
            "label": "PUMS OBELESAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20491",
            "district": "13",
            "Block": "139",
            "label": "PUMS PEDDATHALAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20492",
            "district": "13",
            "Block": "139",
            "label": "CAMBRIDGE MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20493",
            "district": "13",
            "Block": "139",
            "label": "GHS PERIYAKOTTAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20494",
            "district": "13",
            "Block": "139",
            "label": "PUMS PERIYATHAKKEPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20495",
            "district": "13",
            "Block": "139",
            "label": "GHS POTHINAYANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20496",
            "district": "13",
            "Block": "139",
            "label": "PUMS R.POOSARIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20497",
            "district": "13",
            "Block": "139",
            "label": "GHS SOKKADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20498",
            "district": "13",
            "Block": "139",
            "label": "PUMS THUDUGANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20499",
            "district": "13",
            "Block": "139",
            "label": "PUMS THURINJIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20500",
            "district": "13",
            "Block": "139",
            "label": "PUMS VELAGALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20501",
            "district": "13",
            "Block": "139",
            "label": "GHSS KANAGAMUTLU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20502",
            "district": "13",
            "Block": "139",
            "label": "PUMS BANDARAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20503",
            "district": "13",
            "Block": "139",
            "label": "GHS K.POOSARIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20504",
            "district": "13",
            "Block": "139",
            "label": "GHS NEKKUNDHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20505",
            "district": "13",
            "Block": "139",
            "label": "GHS PACHIGANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20506",
            "district": "13",
            "Block": "139",
            "label": "PUMS SEMBADAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20507",
            "district": "13",
            "Block": "139",
            "label": "MMS ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20508",
            "district": "13",
            "Block": "139",
            "label": "MMS DOWLATABAD (U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20509",
            "district": "13",
            "Block": "139",
            "label": "MMS GANDHI ROAD (U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20510",
            "district": "13",
            "Block": "139",
            "label": "MMS OLD PET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20511",
            "district": "13",
            "Block": "139",
            "label": "IELC AIDED MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20512",
            "district": "13",
            "Block": "139",
            "label": "MMS FORT GIRLS (U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20513",
            "district": "13",
            "Block": "139",
            "label": "MMS OLD SUB JAIL ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20514",
            "district": "13",
            "Block": "139",
            "label": "MMS RAJU STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20515",
            "district": "13",
            "Block": "139",
            "label": "MHSS OLDPET KRISHNAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20516",
            "district": "13",
            "Block": "139",
            "label": "R.C.FATIMA BOYS HS, KRISHNAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20517",
            "district": "13",
            "Block": "139",
            "label": "GHSS KRISHNAGIRI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20518",
            "district": "13",
            "Block": "139",
            "label": "GHSS KRISHNAGIRI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20519",
            "district": "13",
            "Block": "139",
            "label": "VAILANKANNI MATRIC HSS KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20520",
            "district": "13",
            "Block": "139",
            "label": "SWAMI VIVEKANANDA MATRIC HSS KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20521",
            "district": "13",
            "Block": "139",
            "label": "EDEN GARDEN ENGLISH SCHOOL KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20522",
            "district": "13",
            "Block": "139",
            "label": "MAHARISHI MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20523",
            "district": "13",
            "Block": "139",
            "label": "KRISHILAND MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20524",
            "district": "13",
            "Block": "139",
            "label": "ST.ANN'S GIRLS HSS KRISHNAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20525",
            "district": "13",
            "Block": "117",
            "label": "GHSS AGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20526",
            "district": "13",
            "Block": "117",
            "label": "MUTHAMIZH GHSS ARASAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20527",
            "district": "13",
            "Block": "117",
            "label": "GOVT.GIRLS HS, ARASAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20528",
            "district": "13",
            "Block": "117",
            "label": "SRI RAMAKRISHNA MATRIC HSS ARASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20529",
            "district": "13",
            "Block": "117",
            "label": "GHS BALEGULI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20530",
            "district": "13",
            "Block": "117",
            "label": "GHS BANNIHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20531",
            "district": "13",
            "Block": "117",
            "label": "GHS BANNIHALLI PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20532",
            "district": "13",
            "Block": "117",
            "label": "GHSS BARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20533",
            "district": "13",
            "Block": "117",
            "label": "PUMS BENDARHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20534",
            "district": "13",
            "Block": "117",
            "label": "GHS CHETTIMARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20535",
            "district": "13",
            "Block": "117",
            "label": "PUMS SATHINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20536",
            "district": "13",
            "Block": "117",
            "label": "GHSS-GIRLS  KAVERIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20537",
            "district": "13",
            "Block": "117",
            "label": "PUMS K.MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20538",
            "district": "13",
            "Block": "117",
            "label": "PUMS KAVERIPATTINAM URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20539",
            "district": "13",
            "Block": "117",
            "label": "GHSS-BOYS  KAVERIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20540",
            "district": "13",
            "Block": "117",
            "label": "PUMS KEELKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20541",
            "district": "13",
            "Block": "117",
            "label": "GHS KOORAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20542",
            "district": "13",
            "Block": "117",
            "label": "PUMS MANI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20543",
            "district": "13",
            "Block": "117",
            "label": "PUMS MARICHETTIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20544",
            "district": "13",
            "Block": "117",
            "label": "ASHOK MISSION MATRIC.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20545",
            "district": "13",
            "Block": "117",
            "label": "PUMS MITTAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20546",
            "district": "13",
            "Block": "117",
            "label": "GHS KADHIRIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20547",
            "district": "13",
            "Block": "117",
            "label": "GHSS MORANAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20548",
            "district": "13",
            "Block": "117",
            "label": "PUMS N.THATTAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20549",
            "district": "13",
            "Block": "117",
            "label": "GHSS NADUPAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20550",
            "district": "13",
            "Block": "117",
            "label": "NATIONAL MATRICULATION SCHOOL - PAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20551",
            "district": "13",
            "Block": "117",
            "label": "GHSS-GIRLS  NAGARASAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20552",
            "district": "13",
            "Block": "117",
            "label": "PR GHSS-BOYS, NAGARASAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20553",
            "district": "13",
            "Block": "117",
            "label": "PUMS NARIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20554",
            "district": "13",
            "Block": "117",
            "label": "GHS NATTANMAIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20555",
            "district": "13",
            "Block": "117",
            "label": "GHSS NEDUNGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20556",
            "district": "13",
            "Block": "117",
            "label": "PBA GHS-GIRLS  PANNANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20557",
            "district": "13",
            "Block": "117",
            "label": "PDB GHSS PANNANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20558",
            "district": "13",
            "Block": "117",
            "label": "PUMS PENNESWARAMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20559",
            "district": "13",
            "Block": "117",
            "label": "GHS PERIYAKARADIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20560",
            "district": "13",
            "Block": "117",
            "label": "PUMS POTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20561",
            "district": "13",
            "Block": "117",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20562",
            "district": "13",
            "Block": "117",
            "label": "GHSS RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20563",
            "district": "13",
            "Block": "117",
            "label": "CAMBRIDGE PUBLIC e-SCHOOL, KAVERIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20564",
            "district": "13",
            "Block": "117",
            "label": "PUMS SAVALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20565",
            "district": "13",
            "Block": "117",
            "label": "GHS SOBANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20566",
            "district": "13",
            "Block": "117",
            "label": "PUMS THERPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20567",
            "district": "13",
            "Block": "117",
            "label": "GHS THIMMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20568",
            "district": "13",
            "Block": "117",
            "label": "GHS THOPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20569",
            "district": "13",
            "Block": "117",
            "label": "GHS VADAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20570",
            "district": "13",
            "Block": "117",
            "label": "NATIONAL HSS VARATTAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20571",
            "district": "13",
            "Block": "117",
            "label": "PUMS VEERAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20572",
            "district": "13",
            "Block": "117",
            "label": "GHSS VELAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20573",
            "district": "13",
            "Block": "117",
            "label": "GHS VILANGAMUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20574",
            "district": "13",
            "Block": "117",
            "label": "PUMS BERUHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20575",
            "district": "13",
            "Block": "117",
            "label": "PUMS DEVARMUKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20576",
            "district": "13",
            "Block": "117",
            "label": "GHS GUNDALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20577",
            "district": "13",
            "Block": "117",
            "label": "GHS JAGADAP",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20578",
            "district": "13",
            "Block": "117",
            "label": "GHS KALVEHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20579",
            "district": "13",
            "Block": "117",
            "label": "GHS KARAGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20580",
            "district": "13",
            "Block": "117",
            "label": "GHS METTUPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20581",
            "district": "13",
            "Block": "117",
            "label": "PUMS THALIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20582",
            "district": "13",
            "Block": "35",
            "label": "PUMS AMBALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20583",
            "district": "13",
            "Block": "35",
            "label": "GHSS ANCHOOR-JAGADEVI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20584",
            "district": "13",
            "Block": "35",
            "label": "AES HSS PERIYAPANAMUTLU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20585",
            "district": "13",
            "Block": "35",
            "label": "GHS BALETHOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20586",
            "district": "13",
            "Block": "35",
            "label": "PUMS GENGINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20587",
            "district": "13",
            "Block": "35",
            "label": "PUMS BANDASEEMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20588",
            "district": "13",
            "Block": "35",
            "label": "GHSS BARGUR BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20589",
            "district": "13",
            "Block": "35",
            "label": "GHSS-GIRLS  BARGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20590",
            "district": "13",
            "Block": "35",
            "label": "CONCORDIA HSS  BARGUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20591",
            "district": "13",
            "Block": "35",
            "label": "IELC BLIND BARGUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20592",
            "district": "13",
            "Block": "35",
            "label": "VAILANKANNI HSS BARGUR KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20593",
            "district": "13",
            "Block": "35",
            "label": "GHS BELAVARTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20594",
            "district": "13",
            "Block": "35",
            "label": "GHSS CHINTHAGAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20595",
            "district": "13",
            "Block": "35",
            "label": "PUMS DHINNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20596",
            "district": "13",
            "Block": "35",
            "label": "ST.ANTONY'S HSS ELATHAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20597",
            "district": "13",
            "Block": "35",
            "label": "GHS EMAKKALNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20598",
            "district": "13",
            "Block": "35",
            "label": "PUMS GANGAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20599",
            "district": "13",
            "Block": "35",
            "label": "PUMS GOLRUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20600",
            "district": "13",
            "Block": "35",
            "label": "GHS GUNDIYALNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20601",
            "district": "13",
            "Block": "35",
            "label": "GHSS IKUNDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20602",
            "district": "13",
            "Block": "35",
            "label": "GHS JINJAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20603",
            "district": "13",
            "Block": "35",
            "label": "GHS KALIKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20604",
            "district": "13",
            "Block": "35",
            "label": "PUMS KANAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20605",
            "district": "13",
            "Block": "35",
            "label": "ST.XAVIER MS, KANDIKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20606",
            "district": "13",
            "Block": "35",
            "label": "GHSS KAPPALVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20607",
            "district": "13",
            "Block": "35",
            "label": "GHS KARAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20608",
            "district": "13",
            "Block": "35",
            "label": "GHS KONDAPPANAYANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20609",
            "district": "13",
            "Block": "35",
            "label": "PUMS KONGANSERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20610",
            "district": "13",
            "Block": "35",
            "label": "ANNA ARIVAGAM HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20611",
            "district": "13",
            "Block": "35",
            "label": "PUMS MAJITH GOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20612",
            "district": "13",
            "Block": "35",
            "label": "GHSS MALLAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20613",
            "district": "13",
            "Block": "35",
            "label": "PUMS MARUDEPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20614",
            "district": "13",
            "Block": "35",
            "label": "GHSS MELKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20615",
            "district": "13",
            "Block": "35",
            "label": "GHS MINDIGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20616",
            "district": "13",
            "Block": "35",
            "label": "GHS NACKALPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20617",
            "district": "13",
            "Block": "35",
            "label": "GHS OPPATHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20618",
            "district": "13",
            "Block": "35",
            "label": "GHSS ORAPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20619",
            "district": "13",
            "Block": "35",
            "label": "PUMS PARAVEPPANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20620",
            "district": "13",
            "Block": "35",
            "label": "GHS PERIYAPANAMUTLU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20621",
            "district": "13",
            "Block": "35",
            "label": "GHSS PERUGOPANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20622",
            "district": "13",
            "Block": "35",
            "label": "GHSS GIRLS POCHAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20623",
            "district": "13",
            "Block": "35",
            "label": "GHSS BOYS POCHAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20624",
            "district": "13",
            "Block": "35",
            "label": "GHSS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20625",
            "district": "13",
            "Block": "35",
            "label": "GHSS-GIRLS  SANTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20626",
            "district": "13",
            "Block": "35",
            "label": "GHSS SANTHUR BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20627",
            "district": "13",
            "Block": "35",
            "label": "PUMS SEEMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20628",
            "district": "13",
            "Block": "35",
            "label": "GHSS SIGARALAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20629",
            "district": "13",
            "Block": "35",
            "label": "GHS SOOLAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20630",
            "district": "13",
            "Block": "35",
            "label": "PUMS SRINIVASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20631",
            "district": "13",
            "Block": "35",
            "label": "PUMS THELLABENDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20632",
            "district": "13",
            "Block": "35",
            "label": "GHSS-GIRLS THOGARAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20633",
            "district": "13",
            "Block": "35",
            "label": "GHSS THOGARAPALLI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20634",
            "district": "13",
            "Block": "35",
            "label": "GHSS VARATANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20635",
            "district": "13",
            "Block": "35",
            "label": "PUMS VARAMANAGUNDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20636",
            "district": "13",
            "Block": "35",
            "label": "GHS VENNAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20637",
            "district": "13",
            "Block": "35",
            "label": "PUMS AGAMUDINAGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20638",
            "district": "13",
            "Block": "35",
            "label": "PUMS CHINNAMATTARAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20639",
            "district": "13",
            "Block": "35",
            "label": "PUMS MADHEPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20640",
            "district": "13",
            "Block": "35",
            "label": "PUMS PUDHU MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20641",
            "district": "13",
            "Block": "35",
            "label": "PUMS THATHEMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20642",
            "district": "13",
            "Block": "35",
            "label": "GHS VEPPALAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20643",
            "district": "13",
            "Block": "398",
            "label": "PUMS  BADDHIMADUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20644",
            "district": "13",
            "Block": "398",
            "label": "GOVT HSS, CHENNASANDIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20645",
            "district": "13",
            "Block": "398",
            "label": "PUMS CHIGARAMANAKAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20646",
            "district": "13",
            "Block": "398",
            "label": "GOVT HS, ENNEGOLLUPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20647",
            "district": "13",
            "Block": "398",
            "label": "PUMS GANGASANDIRAM(URUDU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20648",
            "district": "13",
            "Block": "398",
            "label": "PUMS HALEKUNDANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20649",
            "district": "13",
            "Block": "398",
            "label": "GOVT HS, KORALNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20650",
            "district": "13",
            "Block": "398",
            "label": "GHSS, KUNDARAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20651",
            "district": "13",
            "Block": "398",
            "label": "SRI SATHYA SAI MATRIC.HR SEC SCHOOL, KUNDARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20652",
            "district": "13",
            "Block": "398",
            "label": "GOVT HSS KURUBARAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20653",
            "district": "13",
            "Block": "398",
            "label": "KGBV POLUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20654",
            "district": "13",
            "Block": "398",
            "label": "GHSS, MANAVARANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20655",
            "district": "13",
            "Block": "398",
            "label": "PUMS MARASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20656",
            "district": "13",
            "Block": "398",
            "label": "PUMS NEDUSALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20657",
            "district": "13",
            "Block": "398",
            "label": "PUMS RAMASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20658",
            "district": "13",
            "Block": "398",
            "label": "GOVT.HS,THEERTHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20659",
            "district": "13",
            "Block": "398",
            "label": "GOVT.HS, V.MADEPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20660",
            "district": "13",
            "Block": "398",
            "label": "SHRI MARUTHI MATRIC HIGHER SECONDARY SCHOOL V.MADEPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20661",
            "district": "13",
            "Block": "398",
            "label": "GOVT. HSS-BOYS  VEPPANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20662",
            "district": "13",
            "Block": "398",
            "label": "GHSS-GIRLS, VEPPANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20663",
            "district": "13",
            "Block": "398",
            "label": "GOVT HS, CHINNAKOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20664",
            "district": "13",
            "Block": "398",
            "label": "PUMS KODIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20665",
            "district": "13",
            "Block": "398",
            "label": "PUMS NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20666",
            "district": "13",
            "Block": "398",
            "label": "GOVT HS, NEDUMARTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20667",
            "district": "13",
            "Block": "398",
            "label": "GOVT.HS, NERALAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20668",
            "district": "13",
            "Block": "291",
            "label": "GHS ADDAGURUKKI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20669",
            "district": "13",
            "Block": "291",
            "label": "PUMS AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20670",
            "district": "13",
            "Block": "291",
            "label": "PUMS ALUSONAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20671",
            "district": "13",
            "Block": "291",
            "label": "PUMS ARUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20672",
            "district": "13",
            "Block": "291",
            "label": "PUMS ATHIMUGAM-URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20673",
            "district": "13",
            "Block": "291",
            "label": "JNM GHS ATHIMUGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20674",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  BERIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20675",
            "district": "13",
            "Block": "291",
            "label": "SRI VIDYA BHARATHI MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20676",
            "district": "13",
            "Block": "291",
            "label": "GHSS BUKKASAGARAM -TEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20677",
            "district": "13",
            "Block": "291",
            "label": "PUMS CHAPPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20678",
            "district": "13",
            "Block": "291",
            "label": "GHS CHINNADINNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20679",
            "district": "13",
            "Block": "291",
            "label": "PUMS D.KOTHAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20680",
            "district": "13",
            "Block": "291",
            "label": "GHS DEVASANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20681",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGH SCHOOL,  DODDEGOUNDAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20682",
            "district": "13",
            "Block": "291",
            "label": "GHSS ENUSONAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20683",
            "district": "13",
            "Block": "291",
            "label": "PUMS GEJJALANDODDI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20684",
            "district": "13",
            "Block": "291",
            "label": "PUMS GUDISADANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20685",
            "district": "13",
            "Block": "291",
            "label": "GHSS HALESEEBAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20686",
            "district": "13",
            "Block": "291",
            "label": "PUMS HANUMANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20687",
            "district": "13",
            "Block": "291",
            "label": "PUMS HOSAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20688",
            "district": "13",
            "Block": "291",
            "label": "GHSS IMMEDINAYAKANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20689",
            "district": "13",
            "Block": "291",
            "label": "PUMS KADATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20690",
            "district": "13",
            "Block": "291",
            "label": "GHSS KALINGAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20691",
            "district": "13",
            "Block": "291",
            "label": "GHS KAMANDODDI -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20692",
            "district": "13",
            "Block": "291",
            "label": "PUMS KANALATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20693",
            "district": "13",
            "Block": "291",
            "label": "PUMS KATTIGANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20694",
            "district": "13",
            "Block": "291",
            "label": "PUMS KOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20695",
            "district": "13",
            "Block": "291",
            "label": "PUMS KOTTAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20696",
            "district": "13",
            "Block": "291",
            "label": "PUMS KUMBALAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20697",
            "district": "13",
            "Block": "291",
            "label": "GHS MORASAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20698",
            "district": "13",
            "Block": "291",
            "label": "GHS MUDUGURIKI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20699",
            "district": "13",
            "Block": "291",
            "label": "PUMS NALLAGANAKOTHAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20700",
            "district": "13",
            "Block": "291",
            "label": "PUMS NARASEEPURAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20701",
            "district": "13",
            "Block": "291",
            "label": "GHS NERIGAM -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20702",
            "district": "13",
            "Block": "291",
            "label": "PUMS PALAVATHIMMANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20703",
            "district": "13",
            "Block": "291",
            "label": "PUMS PANNAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20704",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGH SCHOOL, PATHAKOTTA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20705",
            "district": "13",
            "Block": "291",
            "label": "RAJAJI MEMORIAL GHS PERANDAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20706",
            "district": "13",
            "Block": "291",
            "label": "TVS ACADEMY M.BATHALAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20707",
            "district": "13",
            "Block": "291",
            "label": "PUMS PULIYARASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20708",
            "district": "13",
            "Block": "291",
            "label": "PUMS SHOOLAGIRI URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20709",
            "district": "13",
            "Block": "291",
            "label": "GHSS-GIRLS  SHOOLAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20710",
            "district": "13",
            "Block": "291",
            "label": "GHSS BOYS SHOOLAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20711",
            "district": "13",
            "Block": "291",
            "label": "KGBV SHOOLAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23402",
            "district": "13",
            "Block": "35",
            "label": "KKP Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "20712",
            "district": "13",
            "Block": "291",
            "label": "JOHN MARIE MEMORIAL MATRIC SCHOOL, SHOOLAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20713",
            "district": "13",
            "Block": "291",
            "label": "NEW LITTLE FLOWER MHSS SHOOLAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20714",
            "district": "13",
            "Block": "291",
            "label": "MODEL HSS SHOOLAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20715",
            "district": "13",
            "Block": "291",
            "label": "GHS ULAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20716",
            "district": "13",
            "Block": "291",
            "label": "GHS UNGATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20717",
            "district": "13",
            "Block": "291",
            "label": "GHS A.SETTIPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20718",
            "district": "13",
            "Block": "291",
            "label": "PUMS CHIMPALTHIRADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20719",
            "district": "13",
            "Block": "291",
            "label": "PUMS DORIPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20720",
            "district": "13",
            "Block": "291",
            "label": "GHS MORANAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20721",
            "district": "13",
            "Block": "291",
            "label": "PUMS SAMANAPALLI -TAM-TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20722",
            "district": "13",
            "Block": "291",
            "label": "GHS VENKATESAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20723",
            "district": "13",
            "Block": "291",
            "label": "PUMS PEDDASIGARALAPALLI-URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20724",
            "district": "13",
            "Block": "291",
            "label": "PUMS THIYAGARASANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20725",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UDHANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20726",
            "district": "13",
            "Block": "291",
            "label": "SARASWATHY VIDYALAYA MATRIC SCHOOL, UDDANAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20727",
            "district": "13",
            "Block": "78",
            "label": "GHS ANDIVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20728",
            "district": "13",
            "Block": "78",
            "label": "GHSS-GIRLS  BAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20729",
            "district": "13",
            "Block": "78",
            "label": "GHSS-BOYS  BAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20730",
            "district": "13",
            "Block": "78",
            "label": "SHANTHIBAVAN RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20731",
            "district": "13",
            "Block": "78",
            "label": "GHS BHARATHIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20732",
            "district": "13",
            "Block": "78",
            "label": "GHS DASARAPALLIDINNA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20733",
            "district": "13",
            "Block": "78",
            "label": "GHS DEVEERAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20734",
            "district": "13",
            "Block": "78",
            "label": "GOVT URDU HR. SEC. SCHOOL - HOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20735",
            "district": "13",
            "Block": "78",
            "label": "JOHN BOSCO GIRLS HSS HOSUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20736",
            "district": "13",
            "Block": "78",
            "label": "RV GHSS-BOYS HOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20737",
            "district": "13",
            "Block": "78",
            "label": "GOVT. MODEL GIRLS HR SEC SCHOOL  HOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20738",
            "district": "13",
            "Block": "78",
            "label": "BLOSSOM MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20739",
            "district": "13",
            "Block": "78",
            "label": "CAMBRIDGE MAT.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20740",
            "district": "13",
            "Block": "78",
            "label": "DAVID SIRONMANI MAT.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20741",
            "district": "13",
            "Block": "78",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20742",
            "district": "13",
            "Block": "78",
            "label": "SWATHI MHSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20743",
            "district": "13",
            "Block": "78",
            "label": "SIDDARTH VILLAGE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20744",
            "district": "13",
            "Block": "78",
            "label": "SISHYA SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20745",
            "district": "13",
            "Block": "78",
            "label": "ASIAN CHRISTIAN HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20746",
            "district": "13",
            "Block": "78",
            "label": "THE ASHOK LEYLAND SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20747",
            "district": "13",
            "Block": "78",
            "label": "GHSS, MULLAINAGAR, HOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20748",
            "district": "13",
            "Block": "78",
            "label": "GHS-GIRLS  HOSUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20749",
            "district": "13",
            "Block": "78",
            "label": "SHANTHI NIKETHAN MATRIC SCHOOL, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20750",
            "district": "13",
            "Block": "78",
            "label": "GHS KOTHAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20751",
            "district": "13",
            "Block": "78",
            "label": "GHSS MACHINAYAKANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20752",
            "district": "13",
            "Block": "78",
            "label": "GHSS MATHIGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20753",
            "district": "13",
            "Block": "78",
            "label": "THE TITAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20754",
            "district": "13",
            "Block": "78",
            "label": "GHS MOOKANDAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20755",
            "district": "13",
            "Block": "78",
            "label": "GHSS NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20756",
            "district": "13",
            "Block": "78",
            "label": "GHS ONNALVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20757",
            "district": "13",
            "Block": "78",
            "label": "GURUDHURONACHARIA HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20758",
            "district": "13",
            "Block": "78",
            "label": "NATIONAL MATRIC.HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20759",
            "district": "13",
            "Block": "78",
            "label": "GHS SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20760",
            "district": "13",
            "Block": "78",
            "label": "GHS THORAPALLI AGRAHARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20761",
            "district": "13",
            "Block": "78",
            "label": "VANAPRASTHA INTERNATIONAL SCHOOL, MUGALAPALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20762",
            "district": "13",
            "Block": "78",
            "label": "MOUNT CARMEL MATRI HR.SEC. SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20763",
            "district": "13",
            "Block": "78",
            "label": "GHSS ZUZUVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20764",
            "district": "13",
            "Block": "78",
            "label": "OPEN ARMS INTERNATIONAL SCHOOL,HOSUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20765",
            "district": "13",
            "Block": "78",
            "label": "GHS CHOODAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20766",
            "district": "13",
            "Block": "78",
            "label": "GHS MUTHALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20767",
            "district": "13",
            "Block": "122",
            "label": "GHS ALASATTY TELUGU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20768",
            "district": "13",
            "Block": "122",
            "label": "GHS BAIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20769",
            "district": "13",
            "Block": "122",
            "label": "GHS BEVANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20770",
            "district": "13",
            "Block": "122",
            "label": "ST THOMAS BRILLIANT MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20771",
            "district": "13",
            "Block": "122",
            "label": "GHS D RATHINAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20772",
            "district": "13",
            "Block": "122",
            "label": "ETERNAL LIGHT PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20773",
            "district": "13",
            "Block": "122",
            "label": "GHSS-GIRLS  DENKANIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20774",
            "district": "13",
            "Block": "122",
            "label": "ST JOSEPHS HIGH SCHOOL AIDED",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20775",
            "district": "13",
            "Block": "122",
            "label": "GHSS BOYS DENKANIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20776",
            "district": "13",
            "Block": "122",
            "label": "KGBV DENKANIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20777",
            "district": "13",
            "Block": "122",
            "label": "GHS DODDABELLUR TELUGU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20778",
            "district": "13",
            "Block": "122",
            "label": "GHS SANTHANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20779",
            "district": "13",
            "Block": "122",
            "label": "GHS-GIRLS  KELAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20780",
            "district": "13",
            "Block": "122",
            "label": "GHSS KELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20781",
            "district": "13",
            "Block": "122",
            "label": "MODEL SCHOOL KELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20782",
            "district": "13",
            "Block": "122",
            "label": "GHS KUNDUMARANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20783",
            "district": "13",
            "Block": "122",
            "label": "GHS LINGEGOWNDAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20784",
            "district": "13",
            "Block": "122",
            "label": "GHS NAGAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20785",
            "district": "13",
            "Block": "122",
            "label": "PUMS NELLUKUNTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20786",
            "district": "13",
            "Block": "122",
            "label": "PUMS NEMILERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20787",
            "district": "13",
            "Block": "122",
            "label": "PUMS PACHAPANATTI TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20788",
            "district": "13",
            "Block": "122",
            "label": "GHSS GIRLS  RAYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20789",
            "district": "13",
            "Block": "122",
            "label": "GHSS-BOYS RAYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20790",
            "district": "13",
            "Block": "122",
            "label": "ETERNAL LIGHT MATRIC.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20791",
            "district": "13",
            "Block": "122",
            "label": "PUMS SAMBANDAKOTTA TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20792",
            "district": "13",
            "Block": "122",
            "label": "PUMS SIDDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20793",
            "district": "13",
            "Block": "122",
            "label": "PUMS SONNE NAYAKKANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20794",
            "district": "13",
            "Block": "122",
            "label": "GHS THIPPASANDIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20795",
            "district": "13",
            "Block": "122",
            "label": "GHSS  ULLUKURUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20796",
            "district": "13",
            "Block": "122",
            "label": "GHS BOMMATHATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20797",
            "district": "13",
            "Block": "122",
            "label": "GHS D.T.DINNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20798",
            "district": "13",
            "Block": "122",
            "label": "GHS KOPPAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20799",
            "district": "13",
            "Block": "122",
            "label": "JOHN BRITTO HSS ANIYALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20800",
            "district": "13",
            "Block": "122",
            "label": "PUMS BOLLAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20801",
            "district": "13",
            "Block": "122",
            "label": "GHS BETTAMUGILALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20802",
            "district": "13",
            "Block": "122",
            "label": "GHSS UNICHETTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20803",
            "district": "13",
            "Block": "122",
            "label": "PUMS THOLVAPETTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20804",
            "district": "13",
            "Block": "311",
            "label": "GHSS ANCHETTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20805",
            "district": "13",
            "Block": "311",
            "label": "SANTA MARIA ACADEMY  INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20806",
            "district": "13",
            "Block": "311",
            "label": "GHS ANDEVANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20807",
            "district": "13",
            "Block": "311",
            "label": "GHS B.LAKKASANDIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20808",
            "district": "13",
            "Block": "311",
            "label": "GHSS BALATHOTTANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20809",
            "district": "13",
            "Block": "311",
            "label": "PUMS BALATHOTTANAPALLY -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20810",
            "district": "13",
            "Block": "311",
            "label": "PUMS BILIGUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20811",
            "district": "13",
            "Block": "311",
            "label": "PUMS CHAKKALAPALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20812",
            "district": "13",
            "Block": "311",
            "label": "GHS D.KOTHANUR -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20813",
            "district": "13",
            "Block": "311",
            "label": "PUMS DODDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20814",
            "district": "13",
            "Block": "311",
            "label": "GHS DODDAMANCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20815",
            "district": "13",
            "Block": "311",
            "label": "GHS GERATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20816",
            "district": "13",
            "Block": "311",
            "label": "GHS JAWALAGIRI -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20817",
            "district": "13",
            "Block": "311",
            "label": "GHS KAKKADASAM -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20818",
            "district": "13",
            "Block": "311",
            "label": "PUMS NOORODHUSAMIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20819",
            "district": "13",
            "Block": "311",
            "label": "IVDP NETHAJI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20820",
            "district": "13",
            "Block": "311",
            "label": "GHS KUNDUKOTTA -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20821",
            "district": "13",
            "Block": "311",
            "label": "GHSS MADHAGONDAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20822",
            "district": "13",
            "Block": "311",
            "label": "OUR LADY'S HSS MADHAKONDAPALLI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20823",
            "district": "13",
            "Block": "311",
            "label": "MATHAKONDAPALLI MODEL SCHOOL, MATHAKONDAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20824",
            "district": "13",
            "Block": "311",
            "label": "SHEMFORD SENIOR SECONDARY SCHOOL, MADHAGONDAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20825",
            "district": "13",
            "Block": "311",
            "label": "PUMS MANCHUGONDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20826",
            "district": "13",
            "Block": "311",
            "label": "GHSS NATRAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20827",
            "district": "13",
            "Block": "311",
            "label": "GHSS PEDDABELAGONDAPALLY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20828",
            "district": "13",
            "Block": "311",
            "label": "ST.JOSEPH'S HSS SESURAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20829",
            "district": "13",
            "Block": "311",
            "label": "GHS T.SHULAKUNTA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20830",
            "district": "13",
            "Block": "311",
            "label": "GHSS THALLY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20831",
            "district": "13",
            "Block": "311",
            "label": "GHS-GIRLS  THALLY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20832",
            "district": "13",
            "Block": "311",
            "label": "GHS THALLY -URUDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20833",
            "district": "13",
            "Block": "311",
            "label": "KGBV THALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20834",
            "district": "13",
            "Block": "311",
            "label": "GHSS URIGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20835",
            "district": "13",
            "Block": "311",
            "label": "DE PAUL HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20836",
            "district": "13",
            "Block": "311",
            "label": "GHS ALENATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20837",
            "district": "13",
            "Block": "311",
            "label": "GHS BOOTHATTIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20838",
            "district": "13",
            "Block": "311",
            "label": "GHS BIKKANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20839",
            "district": "13",
            "Block": "311",
            "label": "THOOYA SAGAYA MATHA AIDED MIDDLE SCHOOL-DASARAPALLY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20840",
            "district": "13",
            "Block": "311",
            "label": "GHS GUMMALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20841",
            "district": "13",
            "Block": "311",
            "label": "GHS KARADIKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20842",
            "district": "13",
            "Block": "311",
            "label": "PUMS MARUPALLY -TAM-TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20843",
            "district": "13",
            "Block": "311",
            "label": "GHS THAGGATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20844",
            "district": "13",
            "Block": "177",
            "label": "PUMS AMMANKOILPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20845",
            "district": "13",
            "Block": "177",
            "label": "GHSS ANANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20846",
            "district": "13",
            "Block": "177",
            "label": "GHSS ATHIGANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20847",
            "district": "13",
            "Block": "177",
            "label": "GHSS GERIGEPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20848",
            "district": "13",
            "Block": "177",
            "label": "GHS GUNDEPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20849",
            "district": "13",
            "Block": "177",
            "label": "GHS K.ETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20850",
            "district": "13",
            "Block": "177",
            "label": "GHS KALARPATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20851",
            "district": "13",
            "Block": "177",
            "label": "PUMS KANJANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20852",
            "district": "13",
            "Block": "177",
            "label": "GHSS KANNANDAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20853",
            "district": "13",
            "Block": "177",
            "label": "PUMS KARUVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20854",
            "district": "13",
            "Block": "177",
            "label": "GHS KODAMANDAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20855",
            "district": "13",
            "Block": "177",
            "label": "GHS KODIPATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20856",
            "district": "13",
            "Block": "177",
            "label": "GHSS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20857",
            "district": "13",
            "Block": "177",
            "label": "GHS-GIRLS  KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20858",
            "district": "13",
            "Block": "177",
            "label": "GHSS M.NADUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20859",
            "district": "13",
            "Block": "177",
            "label": "PUMS M.PALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20860",
            "district": "13",
            "Block": "177",
            "label": "GHSS MADARAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20861",
            "district": "13",
            "Block": "177",
            "label": "PUMS MALAYANDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20862",
            "district": "13",
            "Block": "177",
            "label": "GHSS MATHUR-BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20863",
            "district": "13",
            "Block": "177",
            "label": "GHSS-GIRLS  MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20864",
            "district": "13",
            "Block": "177",
            "label": "KALAIMAGAL KALALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20865",
            "district": "13",
            "Block": "177",
            "label": "BHARANI INTERNATIONAL MODERN SCHOOL KANNANDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20866",
            "district": "13",
            "Block": "177",
            "label": "PUMS MUTHAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20867",
            "district": "13",
            "Block": "177",
            "label": "GHS ODDAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20868",
            "district": "13",
            "Block": "177",
            "label": "GHS RAMAKRISHNAMPATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20869",
            "district": "13",
            "Block": "177",
            "label": "PUMS RANGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20870",
            "district": "13",
            "Block": "177",
            "label": "GHSS SAMALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20871",
            "district": "13",
            "Block": "177",
            "label": "GHS SOOLAGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20872",
            "district": "13",
            "Block": "177",
            "label": "GHS VALIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20873",
            "district": "13",
            "Block": "177",
            "label": "PUMS VEERACHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20874",
            "district": "13",
            "Block": "177",
            "label": "PUMS ANDERIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20875",
            "district": "13",
            "Block": "177",
            "label": "GADWHSS ATHIPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20876",
            "district": "13",
            "Block": "177",
            "label": "GHS PASANTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20877",
            "district": "13",
            "Block": "177",
            "label": "GHS VELAVALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21042",
            "district": "13",
            "Block": "78",
            "label": "ADVAITH INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21043",
            "district": "13",
            "Block": "78",
            "label": "PARIMALAM MATRIC SCHOOL, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21044",
            "district": "13",
            "Block": "78",
            "label": "CARMEL INTERNANATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21126",
            "district": "13",
            "Block": "139",
            "label": "VAILANKANNI PUBLIC SCHOOL KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21135",
            "district": "13",
            "Block": "78",
            "label": "PUMS THORAPALLI -TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21172",
            "district": "13",
            "Block": "35",
            "label": "GONZAGA MHSS KATHANPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21185",
            "district": "13",
            "Block": "139",
            "label": "NALANDA INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21186",
            "district": "13",
            "Block": "78",
            "label": "NALANDA INTERNATIONAL PUBLIC SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21194",
            "district": "13",
            "Block": "376",
            "label": "GHSS   PAVAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21195",
            "district": "13",
            "Block": "398",
            "label": "MODEL HSS VEPPANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21196",
            "district": "13",
            "Block": "311",
            "label": "MODEL HSS THALLY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21250",
            "district": "13",
            "Block": "78",
            "label": "ST.JOSEPH'S ACADEMY, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21292",
            "district": "13",
            "Block": "139",
            "label": "RIMS MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21293",
            "district": "13",
            "Block": "78",
            "label": "SWAMY  VIVEKANANDA MATRIC HSS, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21345",
            "district": "13",
            "Block": "376",
            "label": "DHEERAN CHINNAMALAI INTERNATIONAL RESIDENTIAL SCHOOL, UTHANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21346",
            "district": "13",
            "Block": "139",
            "label": "CAMBRIDGE PUBLIC e-SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21347",
            "district": "13",
            "Block": "139",
            "label": "BHARAT INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21348",
            "district": "13",
            "Block": "78",
            "label": "SRI GURUKULAM SENIOR SECONDARY SCHOOL, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21349",
            "district": "13",
            "Block": "78",
            "label": "LITERA VALLEY ZEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21419",
            "district": "13",
            "Block": "376",
            "label": "GHS M.VELLALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21420",
            "district": "13",
            "Block": "139",
            "label": "GHS KATTIGANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21421",
            "district": "13",
            "Block": "35",
            "label": "GHS GUTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21422",
            "district": "13",
            "Block": "291",
            "label": "GHS BERIGAI - URDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21423",
            "district": "13",
            "Block": "311",
            "label": "GGHS PEDDABELAGONDAPALLI - GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21570",
            "district": "13",
            "Block": "78",
            "label": "MAHARISHI VIDYA MANDIR HSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21627",
            "district": "13",
            "Block": "376",
            "label": "LOURDU MATHA MATRICULATION SCHOOL VISUVASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21628",
            "district": "13",
            "Block": "139",
            "label": "SRI VIJAY VIDYALAYA MATRIC SCHOOL OLDPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21629",
            "district": "13",
            "Block": "139",
            "label": "EXCEL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21630",
            "district": "13",
            "Block": "117",
            "label": "SRI VINAYAGA VIDYALAYA MATRIC. SCHOOL, KALKUTTAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21631",
            "district": "13",
            "Block": "35",
            "label": "ST. JOSEPH'S INTERNATIONAL SCHOOL, PERIYAPANAMUTLU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21803",
            "district": "13",
            "Block": "117",
            "label": "KS MILLENNIUM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21804",
            "district": "13",
            "Block": "177",
            "label": "SRI VETRI VIKAS MATRIC. HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21805",
            "district": "13",
            "Block": "78",
            "label": "SRI VIJAY VIDHYASHRAM SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21806",
            "district": "13",
            "Block": "78",
            "label": "SREE SHIRDI SAI VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21807",
            "district": "13",
            "Block": "139",
            "label": "TRINITY ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21967",
            "district": "13",
            "Block": "78",
            "label": "NEW BRILLIANT IDEAL MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21976",
            "district": "13",
            "Block": "78",
            "label": "VANAPRASTHA INTERNATIONAL MATRIC HR SEC SCHOOL-THUMMANAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22291",
            "district": "13",
            "Block": "376",
            "label": "R.P.S MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22292",
            "district": "13",
            "Block": "35",
            "label": "K.E.T. MATRICULATION PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22293",
            "district": "13",
            "Block": "291",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Athimugam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22294",
            "district": "13",
            "Block": "291",
            "label": "NTR INTERNATIONAL SCHOOL, BUKKASAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22295",
            "district": "13",
            "Block": "291",
            "label": "ST.MARY'S ENGLISH SCHOOL - ICSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22296",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGH SCHOOL, SANAMAVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22297",
            "district": "13",
            "Block": "78",
            "label": "SRI CHAITANYA TECHNO SCHOOL DINNUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22298",
            "district": "13",
            "Block": "78",
            "label": "ADITI VIDYA MANDIR MATRICULATION SCHOOL, THIMMASANDRAM, AVALAPALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22299",
            "district": "13",
            "Block": "78",
            "label": "HOSUR PUBLIC  SCHOOL-KARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22300",
            "district": "13",
            "Block": "122",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Denkanikottai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22301",
            "district": "13",
            "Block": "122",
            "label": "SEVENTH DAY ADVENTIST INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22302",
            "district": "13",
            "Block": "311",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- kakkadasam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22303",
            "district": "13",
            "Block": "177",
            "label": "SRI SWAMY VIVEKANANDHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22668",
            "district": "13",
            "Block": "376",
            "label": "ADHIYAMAN PUBLIC SCHOOL, UTHANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22669",
            "district": "13",
            "Block": "376",
            "label": "SRS MATRICULATION HR SEC SCHOOL, ELACHOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22670",
            "district": "13",
            "Block": "139",
            "label": "THUVARAGA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22671",
            "district": "13",
            "Block": "117",
            "label": "GHS DEVEERAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22672",
            "district": "13",
            "Block": "35",
            "label": "NOTRE DAME ACADEMY MATRIC SCHOOL KUNNANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22673",
            "district": "13",
            "Block": "398",
            "label": "SRI SARASWATHI VIDYALAYA , KUNDARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22674",
            "district": "13",
            "Block": "291",
            "label": "GHS ERRANDAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22675",
            "district": "13",
            "Block": "291",
            "label": "GHS KATTINAYAKANADODDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22676",
            "district": "13",
            "Block": "78",
            "label": "ANNAI ARAVINTHAR SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22677",
            "district": "13",
            "Block": "311",
            "label": "GURUKULAM GLOBAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22678",
            "district": "13",
            "Block": "311",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22679",
            "district": "13",
            "Block": "311",
            "label": "GHS PANDURANGAN DODDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22680",
            "district": "13",
            "Block": "177",
            "label": "GUNA MATRIC HR SEC SCHOOL, GENDIGAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22681",
            "district": "13",
            "Block": "177",
            "label": "GHS SALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22682",
            "district": "13",
            "Block": "177",
            "label": "UDHAYAAM MATRIC HR SEC SCHOOL, SIVAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22767",
            "district": "13",
            "Block": "35",
            "label": "SIVAGAMIAMMAL INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22832",
            "district": "13",
            "Block": "117",
            "label": "RICH MAAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22834",
            "district": "13",
            "Block": "117",
            "label": "GOPI KRISHNA'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22868",
            "district": "13",
            "Block": "78",
            "label": "SRI VIJAY VIDYASHRAM BAGALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22890",
            "district": "13",
            "Block": "376",
            "label": "GHS CHANDRAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22891",
            "district": "13",
            "Block": "78",
            "label": "GHS NANDHIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22892",
            "district": "13",
            "Block": "35",
            "label": "GHS ALERAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22955",
            "district": "13",
            "Block": "139",
            "label": "GHS PEDDANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22996",
            "district": "13",
            "Block": "78",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23016",
            "district": "13",
            "Block": "35",
            "label": "SELVA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23022",
            "district": "13",
            "Block": "78",
            "label": "GREEN VALLEY SCHOOL, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23030",
            "district": "13",
            "Block": "122",
            "label": "MOTHER THERESA PUBLIC SCHOOL, RAYAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23052",
            "district": "13",
            "Block": "35",
            "label": "VAILANKANNI HIGHER SECONDARY SCHOOL BARGUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23062",
            "district": "13",
            "Block": "177",
            "label": "GURUKULAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23076",
            "district": "13",
            "Block": "35",
            "label": "ST. KANAKADASA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23181",
            "district": "13",
            "Block": "291",
            "label": "STANFORD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23273",
            "district": "13",
            "Block": "311",
            "label": "GHS T.BELALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23274",
            "district": "13",
            "Block": "311",
            "label": "GHS KODAGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23496",
            "district": "13",
            "Block": "78",
            "label": "AGASTHYA VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23497",
            "district": "13",
            "Block": "78",
            "label": "BRUNDAVANA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23498",
            "district": "13",
            "Block": "78",
            "label": "THE OAKRICH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23499",
            "district": "13",
            "Block": "78",
            "label": "ST. XAVIER'S ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23500",
            "district": "13",
            "Block": "78",
            "label": "BMR GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23501",
            "district": "13",
            "Block": "139",
            "label": "KRISHNAGIRI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23502",
            "district": "13",
            "Block": "139",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL KRISHNAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23503",
            "district": "13",
            "Block": "311",
            "label": "SRI BALAGANGADARA SWAMIJI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23504",
            "district": "13",
            "Block": "376",
            "label": "OXFORD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23742",
            "district": "13",
            "Block": "35",
            "label": "PUMS SRINIVASAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23743",
            "district": "13",
            "Block": "35",
            "label": "DURAI MODERN SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23790",
            "district": "13",
            "Block": "78",
            "label": "GHSS NALLUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23791",
            "district": "13",
            "Block": "78",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23792",
            "district": "13",
            "Block": "78",
            "label": "NIMAI PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23824",
            "district": "13",
            "Block": "117",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23826",
            "district": "13",
            "Block": "122",
            "label": "GHS NAGAMANGALAM",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23827",
            "district": "13",
            "Block": "122",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23837",
            "district": "13",
            "Block": "139",
            "label": "SENTHIL PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23838",
            "district": "13",
            "Block": "139",
            "label": "SENTHIL MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23839",
            "district": "13",
            "Block": "139",
            "label": "ST. ANNE'S PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23840",
            "district": "13",
            "Block": "139",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23875",
            "district": "13",
            "Block": "177",
            "label": "GHSS KUNNATHUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23964",
            "district": "13",
            "Block": "291",
            "label": "PUMS AGARAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23993",
            "district": "13",
            "Block": "311",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23994",
            "district": "13",
            "Block": "311",
            "label": "SRI BALAGANGADARA SWAMIJI PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23995",
            "district": "13",
            "Block": "311",
            "label": "UK PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23996",
            "district": "13",
            "Block": "311",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24068",
            "district": "13",
            "Block": "398",
            "label": "PUMS NALLUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "14": [
        {
            "key": "527",
            "district": "14",
            "Block": "183",
            "label": "SRI SUNDARESWARA VIDYA SALA MATRIC HR SEC SCHOOL, AATUKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "613",
            "district": "14",
            "Block": "159",
            "label": "GOOD SHEPHERD MATRIC HR SEC SCHOOL, KANAGAVEL COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "669",
            "district": "14",
            "Block": "161",
            "label": "S.E.V MATRIC HR SEC SCHOOL, NARAYANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "712",
            "district": "14",
            "Block": "160",
            "label": "SRI VIDYA BHAVAN MATRIC HIGHER SECONDARY SCHOOL, TEPPAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "761",
            "district": "14",
            "Block": "160",
            "label": "AMALI MATRIC HR SEC SCHOOL, NEW RAMNAD ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "781",
            "district": "14",
            "Block": "160",
            "label": "SOURASHTRA GIRLS HR SEC SCHOOL, ANUPPANADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "891",
            "district": "14",
            "Block": "328",
            "label": "ST.MARY OF LEUCA MATRIC HR SEC SCHOOL, BASTIAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "948",
            "district": "14",
            "Block": "160",
            "label": "SRI SARADA VIDYAVANAM MATRIC GIRLS HR SEC SCHOOL, SIMMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "994",
            "district": "14",
            "Block": "159",
            "label": "SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, MAHABOOBPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1010",
            "district": "14",
            "Block": "158",
            "label": "SARACENS MATRIC HR SEC SCHOOL, MELAMADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1163",
            "district": "14",
            "Block": "306",
            "label": "THAAI MATRIC HIGHER SECONDARY SCHOOL, T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1227",
            "district": "14",
            "Block": "161",
            "label": "XAVIER MATRIC HR SEC SCHOOL, IYER BUNGALOW",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1294",
            "district": "14",
            "Block": "160",
            "label": "ROSE MATRIC HR SEC SCHOOL, KANSAMETTU STREET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1364",
            "district": "14",
            "Block": "324",
            "label": "ST FRANCIS MATRIC HR SEC SCHOOL, THIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1375",
            "district": "14",
            "Block": "158",
            "label": "MRR.MAVMM MATRIC HR SEC SCHOOL, SATHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1396",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION MASATHIYAR GIRLS HR SEC SCHOOL, MANJANAKARA STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1446",
            "district": "14",
            "Block": "183",
            "label": "SUBRAMANIA BHARATHI MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1501",
            "district": "14",
            "Block": "158",
            "label": "TAGORE VIDYALAYAM MATRIC HR SEC SCHOOL, SADASIVA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1598",
            "district": "14",
            "Block": "328",
            "label": "YADAVA MATRIC HR SEC SCHOOL, SUBRAMANIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1648",
            "district": "14",
            "Block": "161",
            "label": "KARPAGAM MATRIC HR SEC SCHOOL, PARAVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1760",
            "district": "14",
            "Block": "161",
            "label": "ST.JOSEPH MATRIC HR SEC SCHOOL, KOODAL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1789",
            "district": "14",
            "Block": "328",
            "label": "SBOA MATRIC HR SEC SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1814",
            "district": "14",
            "Block": "328",
            "label": "DOLPHIN MATRIC HR SEC SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1822",
            "district": "14",
            "Block": "160",
            "label": "SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, ANUPPANADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1856",
            "district": "14",
            "Block": "306",
            "label": "VENKATASALAPATHY MATRIC HIGH SCHOOL, T VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1861",
            "district": "14",
            "Block": "328",
            "label": "K.N.U NACHIAPPANADAR GURUVAMMAL MATRIC HIGH SCHOOL, VILLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1946",
            "district": "14",
            "Block": "158",
            "label": "FUSCO'S MATRIC HIGH SCHOOL, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1985",
            "district": "14",
            "Block": "161",
            "label": "FATIMA MATRIC HR SEC SCHOOL, AATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2004",
            "district": "14",
            "Block": "158",
            "label": "MODERN MAT.S OTHAKADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2014",
            "district": "14",
            "Block": "159",
            "label": "S.R.W.W.O MATRIC HIGH SCHOOL, RAILWAY COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2020",
            "district": "14",
            "Block": "328",
            "label": "B.M.S VIDHYALAYAM MATRIC HIGHER SECONDARY SCHOOL, AVANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23693",
            "district": "14",
            "Block": "374",
            "label": "Sathya Vidhyalaya International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "2025",
            "district": "14",
            "Block": "161",
            "label": "C.E.O.A. MATRIC HR SEC SCHOOL, A KOSAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2035",
            "district": "14",
            "Block": "159",
            "label": "ANANTH MEMORIAL MATRIC HR SEC SCHOOL ARAPPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2066",
            "district": "14",
            "Block": "161",
            "label": "C.E.O.A. MATRIC HIGHER SECONDARY SCHOOL, MEENAMBALPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2186",
            "district": "14",
            "Block": "158",
            "label": "MAHATMA MONTESSORI MATRIC HR SEC SCHOOL, K.K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2188",
            "district": "14",
            "Block": "158",
            "label": "ANNAMALAIYAAR MATRIC HR SEC SCHOOL, ANNA NAGAR EAST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2245",
            "district": "14",
            "Block": "328",
            "label": "GRACE MATRIC HR SEC SCHOOL, JAIHINDPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2290",
            "district": "14",
            "Block": "328",
            "label": "ROYAL PUBLIC MATRIC HIGH  SCHOOL, BETHANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2323",
            "district": "14",
            "Block": "161",
            "label": "SDH JAIN VIDYALAYA MATRIC HR SEC SCHOOL, THIRUPPALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2351",
            "district": "14",
            "Block": "283",
            "label": "R.C.M.S. P.SETTIAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2442",
            "district": "14",
            "Block": "328",
            "label": "GOOD LUCK MATRIC HIGH SCHOOL, VILLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2523",
            "district": "14",
            "Block": "328",
            "label": "ST.JOHN PETER MATRIC HIGH SCHOOL, JAIHINDPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2538",
            "district": "14",
            "Block": "328",
            "label": "SETHURAJAN PADMA MATRIC HR SEC SCHOOL, JAINHINDPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2541",
            "district": "14",
            "Block": "328",
            "label": "ST.CHARLES MATRIC HR SEC SCHOOL, THIRUVALLUVAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2566",
            "district": "14",
            "Block": "4",
            "label": "ROYAL MAT SCHOOL THANICHIYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2571",
            "district": "14",
            "Block": "158",
            "label": "BPM MATRIC HIGH SCHOOL, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2716",
            "district": "14",
            "Block": "328",
            "label": "M.T.PKN MATRIC HIGHER SECONDARY  SCHOOL, PASUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2725",
            "district": "14",
            "Block": "159",
            "label": "ST.BRITTO MATRIC HIGH SCHOOL, GNANAOLIVUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2791",
            "district": "14",
            "Block": "328",
            "label": "ANUSHA VIDYALAYA MATRIC HIGH SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2841",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S. E.KOTTAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2844",
            "district": "14",
            "Block": "283",
            "label": "BHARATHIYAR MATRICULATION  HIGHER SECONDARY  SCHOOL, ELUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2854",
            "district": "14",
            "Block": "158",
            "label": "ARULMALAR MATRIC HR SEC SCHOOL, K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2856",
            "district": "14",
            "Block": "328",
            "label": "AMUDHAM MATRIC HR SEC SCHOOL, PERUNGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2859",
            "district": "14",
            "Block": "161",
            "label": "ROYAL VIDYALAYA MATRIC HR SEC SCHOOL, VILANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2876",
            "district": "14",
            "Block": "303",
            "label": "M.S.R  MATRICULATION  HIGHER  SECONDARY  SCHOOL, T KALLUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3091",
            "district": "14",
            "Block": "324",
            "label": "KAZHUVATHEVAR MEMORIAL MATRIC HR SEC SCH, K.P.OTHAPPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3126",
            "district": "14",
            "Block": "160",
            "label": "SRIMAN NAYAKIYAR VIDYA MANDIR MATRIC HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3138",
            "district": "14",
            "Block": "183",
            "label": "LAKSHMI MATRIC HR SEC SCHOOL, THIRUVATHAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3187",
            "district": "14",
            "Block": "161",
            "label": "SIDDHU MATRIC HR SEC SCHOOL, VISWANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3235",
            "district": "14",
            "Block": "161",
            "label": "BHARAT VIDYA MANDIR MATRIC HIGH SCHOOL, IYER BUNGALOW",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3237",
            "district": "14",
            "Block": "159",
            "label": "SIVAKASI NADARS MATRIC HR SEC SCHOOL, ELLIS NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3248",
            "district": "14",
            "Block": "158",
            "label": "KALYANI MATRIC HR SEC SCHOOL, SAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3271",
            "district": "14",
            "Block": "324",
            "label": "PKN MATRIC HR SEC SCHOOL, TIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3322",
            "district": "14",
            "Block": "159",
            "label": "CHANDLER MATRIC HR SEC SCHOOL, PONNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3329",
            "district": "14",
            "Block": "306",
            "label": "FUSCO'S MATRIC HR SEC SCHOOL, T. VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3331",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, UTHAPURAM - I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3385",
            "district": "14",
            "Block": "328",
            "label": "AMRITA VIDYALAYAM MATRICULATION SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3407",
            "district": "14",
            "Block": "328",
            "label": "SENGUNDHAR MATRIC HIGH SCHOOL, CHINTHAMANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3429",
            "district": "14",
            "Block": "183",
            "label": "SRI BALAN EVERGREEN MATRIC HIGH SCHOOL, KEELAVALAVU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3433",
            "district": "14",
            "Block": "161",
            "label": "LUCY PERRY NOBLE GIRLS HR SEC SCHOOL, RATCHANYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3449",
            "district": "14",
            "Block": "328",
            "label": "RAJAN MATRIC HR SEC SCHOOL, VILLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3469",
            "district": "14",
            "Block": "306",
            "label": "VIVEKANANDA MATRIC HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3477",
            "district": "14",
            "Block": "161",
            "label": "ST.MARY'S MATRIC HR SEC SCHOOL, S.ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3488",
            "district": "14",
            "Block": "183",
            "label": "VETRIVEL VIDYASALA MATRIC HIGH SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3511",
            "district": "14",
            "Block": "328",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, Jeevanagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3521",
            "district": "14",
            "Block": "328",
            "label": "V.K.K PLAY GROUP MATRIC HR SEC SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3780",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S. ATHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3785",
            "district": "14",
            "Block": "328",
            "label": "LITTLE DIAMONDS MATRIC HIGH SCHOOL, VILLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3792",
            "district": "14",
            "Block": "161",
            "label": "ROTARY LAHARRY MATRIC HR SEC SCHOOL, BIBI KULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3797",
            "district": "14",
            "Block": "328",
            "label": "AMALA MATRICULATION HIGHER SECONDARY SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3837",
            "district": "14",
            "Block": "374",
            "label": "SEVENTHDAY ADVENTIST MATRICULATION HIGHER SECONDARY SCHOOL, USILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3849",
            "district": "14",
            "Block": "328",
            "label": "KRISHNAMAL RAMASUBBAIYER SCHOOL, TVR NAGAR, MADURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3927",
            "district": "14",
            "Block": "324",
            "label": "SARAVANA'S VIDHYA MANDIR MATRIC HR SEC SCHOOL, KANGEYANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3930",
            "district": "14",
            "Block": "328",
            "label": "HDI JAIN MATRIC HR SEC SCHOOL, PASUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3948",
            "district": "14",
            "Block": "161",
            "label": "CATHY MATRIC HR SEC SCHOOL, KURINJI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4038",
            "district": "14",
            "Block": "161",
            "label": "SPARTANNS MATRIC HIGH SCHOOL, NAGANAKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4084",
            "district": "14",
            "Block": "158",
            "label": "KASTHURIBA GANDHI MATRICULATION  SCHOOL, MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4201",
            "district": "14",
            "Block": "328",
            "label": "INDIRA GANDHI MEMORIAL MATRIC HR SEC SCHOOL, TIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4227",
            "district": "14",
            "Block": "161",
            "label": "P.S.Y MATRIC HR SEC SCHOOL, NARAYANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4287",
            "district": "14",
            "Block": "183",
            "label": "SHRI RAM MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4290",
            "district": "14",
            "Block": "161",
            "label": "LE CHATELIER MATRIC HR SEC SCHOOL, CHINNACHOKKIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4336",
            "district": "14",
            "Block": "328",
            "label": "DEVASAKAYAM MATRIC HR SEC SCHOOL, PASUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4499",
            "district": "14",
            "Block": "160",
            "label": "NADAR VIDHYASALA MIDDLE SCHOOL, SOUTH GATE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4619",
            "district": "14",
            "Block": "158",
            "label": "LAKSHMI MATRIC HR SEC SCHOOL, VEERAPANCHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4667",
            "district": "14",
            "Block": "328",
            "label": "S.P.J MATRIC HR SEC SCHOOL, AVANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4742",
            "district": "14",
            "Block": "303",
            "label": "K.K.G MATRICULATION  HIGHER  SECONDARY  SCHOOL, P.THOTTIAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4850",
            "district": "14",
            "Block": "160",
            "label": "PALANIAPPA MIDDLE SCHOOL,  CMR ROAD, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4873",
            "district": "14",
            "Block": "158",
            "label": "NOYES MATRIC HR SEC SCHOOL,NARIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4946",
            "district": "14",
            "Block": "183",
            "label": "SARASWATHI VIDHAYALAYA MATRIC HIGH SCHOOL, M SUBRAMANIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4957",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, UTHAPURAM - II",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4965",
            "district": "14",
            "Block": "158",
            "label": "CROWN MATRIC HR SEC SCHOOL, GORIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5005",
            "district": "14",
            "Block": "161",
            "label": "ST.MICHAEL MATRIC HR SEC SCHOOL, P&T NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5013",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S.MEENAKSHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5038",
            "district": "14",
            "Block": "161",
            "label": "VIBGYOR MATRIC HIGH SCHOOL, K.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5117",
            "district": "14",
            "Block": "328",
            "label": "PRAJNA MATRIC HR SEC SCHOOL, THIRUPARANKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5165",
            "district": "14",
            "Block": "328",
            "label": "ZION GOOD SHEPHERDS MATRIC HR SEC SCHOOL, AVANIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5282",
            "district": "14",
            "Block": "158",
            "label": "FUSCO'S MATRIC HR SEC SCHOOL, YAGAPPA NAGAR,VANDIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5354",
            "district": "14",
            "Block": "161",
            "label": "MARUTHU RUKMANI MATRIC HR SEC SCHOOL, P&T NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5387",
            "district": "14",
            "Block": "160",
            "label": "MADURAI MEENAKSHI MATRIC HR SEC SCHOOL, PETCHIAMMAN PADITHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5398",
            "district": "14",
            "Block": "328",
            "label": "ST.JOHN'S MATRIC HR SEC SCHOOL, KOCHADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5421",
            "district": "14",
            "Block": "161",
            "label": "MARY ANN MATRIC HR SEC SCHOOL, K PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5448",
            "district": "14",
            "Block": "161",
            "label": "MARY IMMACULATE MATRIC HIGH SCHOOL, APPATHURAINAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5450",
            "district": "14",
            "Block": "374",
            "label": "PONMANI MATRIC HR SEC SCHOOL, USILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5647",
            "district": "14",
            "Block": "160",
            "label": "ST.JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL, OLD KUYAVARPALAYAM ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5684",
            "district": "14",
            "Block": "303",
            "label": "LORD VENKATESHWARA MATRICULATION  HIGHER SECONDARY  SCHOOL, T KALLUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5758",
            "district": "14",
            "Block": "161",
            "label": "CHRIST THE KING MATRIC HR SEC SCHOOL, VILANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5796",
            "district": "14",
            "Block": "324",
            "label": "ARIMA NAMALVAR MATRICLUATION SCHOOL, THIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5811",
            "district": "14",
            "Block": "160",
            "label": "L.V BALU SAMY IYER& SONS M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5994",
            "district": "14",
            "Block": "161",
            "label": "VELAMMAL MEMORIAL MATRIC HR SEC SCHOOL,THIRUPPALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6004",
            "district": "14",
            "Block": "324",
            "label": "LINGA MATRIC HIGH SCHOOL, TIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6045",
            "district": "14",
            "Block": "306",
            "label": "M.V.M.KALAIVANI MATRIC HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6106",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. MELAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6202",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION MIDDLE SCHOOL  SANDAIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6210",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S THATCHANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6302",
            "district": "14",
            "Block": "159",
            "label": "CAPRON HALL GIRLS HR SEC SCHOOL, PONNAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6309",
            "district": "14",
            "Block": "161",
            "label": "E.B.G MATRIC HR SEC SCHOOL, MOONDRUMAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6367",
            "district": "14",
            "Block": "158",
            "label": "AKNU S SUNDAR MATRIC HR SEC SCHOOL, KALIKAPPAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6369",
            "district": "14",
            "Block": "161",
            "label": "BASINGER GARDENS MATRIC SCHOOL, P&T NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6411",
            "district": "14",
            "Block": "160",
            "label": "PREM MATRIC HIGH SCHOOL, TEPPAKKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6515",
            "district": "14",
            "Block": "183",
            "label": "ASIAN MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6714",
            "district": "14",
            "Block": "159",
            "label": "OUR LADY MATRIC HR SEC SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17828",
            "district": "14",
            "Block": "4",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEMBUKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17829",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, T.AYYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17830",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, KONDAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17831",
            "district": "14",
            "Block": "4",
            "label": "PUMS KOIL KUTTI NAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17832",
            "district": "14",
            "Block": "4",
            "label": "PUMS ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17833",
            "district": "14",
            "Block": "4",
            "label": "PUMS A PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17834",
            "district": "14",
            "Block": "4",
            "label": "PUMS KURAVANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17835",
            "district": "14",
            "Block": "4",
            "label": "GOVT BOYS HR SEC SCHOOL, ALANGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17836",
            "district": "14",
            "Block": "4",
            "label": "GOVT GIRLS HR SEC SCHOOL, ALANGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17837",
            "district": "14",
            "Block": "4",
            "label": "PUMS KOTTAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17838",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, MANIYANJI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17839",
            "district": "14",
            "Block": "4",
            "label": "PUMS THANDALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17840",
            "district": "14",
            "Block": "4",
            "label": "GOVT ADW HR SEC SCHOOL, ACHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17841",
            "district": "14",
            "Block": "4",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VAVIDAMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17842",
            "district": "14",
            "Block": "4",
            "label": "NATIONAL CO-OP SUGAR MILLS HR SEC SCHOOL, 15B METTUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17843",
            "district": "14",
            "Block": "4",
            "label": "PUMS URSERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17844",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, ERRAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17845",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, MUDUVARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17846",
            "district": "14",
            "Block": "4",
            "label": "PUMS MANICKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17847",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, VELLAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17848",
            "district": "14",
            "Block": "4",
            "label": "PUMS SARANTHANGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17849",
            "district": "14",
            "Block": "4",
            "label": "PUMS KODANGIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17850",
            "district": "14",
            "Block": "4",
            "label": "PUMS PONDUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17851",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, SENTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17852",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, CHATRAVALLALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17853",
            "district": "14",
            "Block": "4",
            "label": "GOVT MODEL HR SEC SCHOOL, PALAMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17854",
            "district": "14",
            "Block": "4",
            "label": "P.H.N.U.S.P PATHIRAKALIAMMAN MATRIC HR SEC SCHOOL, PALAMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17855",
            "district": "14",
            "Block": "4",
            "label": "PUMS THETHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17856",
            "district": "14",
            "Block": "4",
            "label": "PUMS T.KOZHINJIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17857",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, T METTUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17858",
            "district": "14",
            "Block": "4",
            "label": "PUMS RAMAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17859",
            "district": "14",
            "Block": "4",
            "label": "PUMS VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17860",
            "district": "14",
            "Block": "4",
            "label": "PUMS DEVASERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17861",
            "district": "14",
            "Block": "43",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANAMOOPPANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17862",
            "district": "14",
            "Block": "43",
            "label": "GOVT HIGH SCHOOL, CHAKKARAPPANAYAKKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17863",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, VIKKIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17864",
            "district": "14",
            "Block": "43",
            "label": "ST.JOSEPH MATRIC HR SEC SCHOOL, VIKKIRAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17865",
            "district": "14",
            "Block": "43",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NADUMUDALAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17866",
            "district": "14",
            "Block": "43",
            "label": "GOVT.KALLAR.M.S. PANNIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17867",
            "district": "14",
            "Block": "43",
            "label": "ST CLARET'S HIGHER SECONDARY SCHOOL  KARUMATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17868",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, VADAKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17869",
            "district": "14",
            "Block": "43",
            "label": "GOVT HIGH SCHOOL, CHELLAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17870",
            "district": "14",
            "Block": "43",
            "label": "FATIMA CONVENT MATRIC HIGH SCHOOL, CHELLAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17871",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR. SEC. SCHOOL, KOVILANGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17872",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S JOTHIMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17873",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. VALANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17874",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. KURAVAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17875",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. K.NATTAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17876",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, PAPPAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17877",
            "district": "14",
            "Block": "43",
            "label": "GOVT.K.M.S KATTAKARUPPANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17878",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. K.SEMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17879",
            "district": "14",
            "Block": "43",
            "label": "GOVT.KALLAR.M.S. KUPPANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17880",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, NATTAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17881",
            "district": "14",
            "Block": "43",
            "label": "NATANESHWER MATRICULATION SCHOOL, NATTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17882",
            "district": "14",
            "Block": "43",
            "label": "GOVT.KALLAR.M.S. AMBATAYAMPATI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17883",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. SINDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23664",
            "district": "14",
            "Block": "158",
            "label": "Ellora International Kids School",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "17884",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, THUMMAKUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17885",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, V.KALLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17886",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, SENGAPADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17887",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S KARISALKALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17888",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S ULAGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17889",
            "district": "14",
            "Block": "97",
            "label": "KOODAKOVIL NADARS HR SEC SCHOOL, KOODAKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17890",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S THUMBAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17891",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, T. PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17892",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. VALAYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17893",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, T.ARASAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17894",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, SIVARAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17895",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, VILLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17896",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. MARAVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17897",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, KALLIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17898",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S AGATHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17899",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. LALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17900",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. THENAMMANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17901",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, M PULIANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17902",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S M.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17903",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. CHITHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17904",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. MAIYTTAN PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17905",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. AVALSOORAM PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17906",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S K.UNNIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17907",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, K.VELLAKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17908",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S MELAUPPILIGUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17909",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S T.KOKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17910",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, VEERAPERMUALPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17911",
            "district": "14",
            "Block": "97",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PEIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17912",
            "district": "14",
            "Block": "97",
            "label": "HINDU NADAR HIGH SCHOOL, K SENNAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17913",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S MELAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17914",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S S.P.NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17915",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. MOCHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17916",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. KURAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17917",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, THIRUMAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17918",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, MARUTHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17919",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S S.VELLAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17920",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, APPAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17921",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, A.THOTTIAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17922",
            "district": "14",
            "Block": "303",
            "label": "GOVT MODEL HR SEC SCHOOL, T.KALLUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17923",
            "district": "14",
            "Block": "303",
            "label": "GANDHINIKETAN G VENKATACHALAPATHY HR SEC SCHOOL, T.KALLUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17924",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, VANNIVELAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17925",
            "district": "14",
            "Block": "303",
            "label": "GOVT HR SEC SCHOOL, T.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17926",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S  PULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17927",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S  KARAIKENI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17928",
            "district": "14",
            "Block": "303",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SOLAIPATTI L",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17929",
            "district": "14",
            "Block": "303",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMMAPATTI V",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17930",
            "district": "14",
            "Block": "303",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THOTTIAPATTI P",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17931",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S CHINNAPOOLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17932",
            "district": "14",
            "Block": "303",
            "label": "SRI KANDASAMY VIDHYALAYAM HR SEC SCHOOL, PAPPUNAYAKKANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17933",
            "district": "14",
            "Block": "303",
            "label": "GOVT. ADW MIDDLE SCHOOL, S.KEELAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17934",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S S.PARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17935",
            "district": "14",
            "Block": "303",
            "label": "SRI M K V SALA HIGHER SECONDARY SCHOOL  MELAPATTI S",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17936",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S  KOOVALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17937",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S. THUMANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17938",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S  KUMARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17939",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S SILAIMALAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17940",
            "district": "14",
            "Block": "303",
            "label": "GOVT HR SEC SCHOOL, M.SUBBULAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17941",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S CHINNACHITTULOTTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17942",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S MURUGANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17943",
            "district": "14",
            "Block": "303",
            "label": "SRI V.K.V SALA M.S GOPINAYAKANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17944",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, GOPINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17945",
            "district": "14",
            "Block": "303",
            "label": "ADI.M.S  CHOKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17946",
            "district": "14",
            "Block": "303",
            "label": "GOVT GIRLS HR SEC SCHOOL, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17947",
            "district": "14",
            "Block": "303",
            "label": "GANDHIJI MEMORIAL GOVT BOYS HR SEC SCHOOL, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17948",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, SOORAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17949",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, PALLAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17950",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, POTTAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17951",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S THONTHILINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17952",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, SOKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17953",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S MANAPPACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17954",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S M.VELLALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17955",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S KUNNARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17956",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, MANGALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17957",
            "district": "14",
            "Block": "136",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OTTAKKOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17958",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S S.MALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17959",
            "district": "14",
            "Block": "136",
            "label": "GOVT GIRLS HR SEC SCHOOL, KOTTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17960",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, KOTTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17961",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, CHOKKALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17962",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S MANALMETUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17963",
            "district": "14",
            "Block": "136",
            "label": "GOVT GIRLS HR SC SCHOOL, KARUNGALAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17964",
            "district": "14",
            "Block": "136",
            "label": "GOVERNMENT Hr. Sec. SCHOOL, KARUNGALAKUDI, Madurai Dist.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17965",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S VANJINAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17966",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, MANAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17967",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, THUMBAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17968",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S THAMARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17969",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S BOOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17970",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S ATTAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17971",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, KODUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17972",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S ETTIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17973",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S SENNAGARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17974",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, MELAVALAVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17975",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S PATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17976",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, KAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17977",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, SEKKIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23674",
            "district": "14",
            "Block": "328",
            "label": "Harveypatti higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17978",
            "district": "14",
            "Block": "158",
            "label": "ADI.D.M.S CHEMPIANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17979",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S ARUMBANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17980",
            "district": "14",
            "Block": "158",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17981",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S CHINNA MANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17982",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, MANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17983",
            "district": "14",
            "Block": "158",
            "label": "GOVT ADW HIGH SCHOOL, KURUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17984",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. KALLANTHIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17985",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, MATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17986",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, MAYANDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17987",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. CHITTAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17988",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. KODIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17989",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. MANGALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17990",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. UTHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17991",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, VANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17992",
            "district": "14",
            "Block": "158",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUMOHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17993",
            "district": "14",
            "Block": "158",
            "label": "T.V.S. LAKSHMI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17994",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S KARUPPAYURANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17995",
            "district": "14",
            "Block": "158",
            "label": "APPAR HR SEC SCHOOL, KARUPPAYURANI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17996",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. KABIR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17997",
            "district": "14",
            "Block": "158",
            "label": "LIONS MATRIC HR SEC SCHOOL,  KARUPPAYURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17998",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, ANDARKOTTARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17999",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, VARICHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18000",
            "district": "14",
            "Block": "158",
            "label": "GOVT ADW HR SEC SCHOOL, ELAMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18001",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S KONDAPETHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18002",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. L.K.B.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18003",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL. SAKKIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18004",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S SAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18005",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. KARSERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18006",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18007",
            "district": "14",
            "Block": "158",
            "label": "GOVT HR SEC SCHOOL, KALIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18008",
            "district": "14",
            "Block": "158",
            "label": "FES.MATRICULATION SCHOOL-GOMATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18009",
            "district": "14",
            "Block": "158",
            "label": "GOVT. MODEL GIRLS HR SEC SCHOOL, Y.OTHAKKADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18010",
            "district": "14",
            "Block": "158",
            "label": "GOVT BOYS HR SEC SCHOOL, Y.OTHAKKADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18011",
            "district": "14",
            "Block": "158",
            "label": "Y.O N.U.M.S OTHAKADAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18012",
            "district": "14",
            "Block": "158",
            "label": "RAGHAV.PUBLIC MATRICULATION SCHOOL-Y.OTHAKKADAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18013",
            "district": "14",
            "Block": "158",
            "label": "MAHARISHI VIDYA MANDIR MATRIC  SCHOOL, K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18014",
            "district": "14",
            "Block": "158",
            "label": "COR.M.S SATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18015",
            "district": "14",
            "Block": "158",
            "label": "THANAM MIDDLE SCHOOL, GANDHI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18016",
            "district": "14",
            "Block": "158",
            "label": "AMBIKA MATRIC HR SEC SCHOOL, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18017",
            "district": "14",
            "Block": "158",
            "label": "CORPORATION ELANGO HR SEC SCHOOL, SHENOY NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18018",
            "district": "14",
            "Block": "158",
            "label": "TALLAKULAM SENGUNTHAR URAVINMURAI HIGH SCHOOL, TALLAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18019",
            "district": "14",
            "Block": "158",
            "label": "AMERICAN COLLEGE HR SEC SCHOOL, TALLAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18020",
            "district": "14",
            "Block": "158",
            "label": "CORPORATION HIGH SCHOOL, TALLAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18021",
            "district": "14",
            "Block": "158",
            "label": "NEHRU VIDYASALAI HR SEC SCHOOL, VETHAVALLI NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18022",
            "district": "14",
            "Block": "158",
            "label": "KATIE WILCOX MATRIC HR SEC SCHOOL, NARIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18023",
            "district": "14",
            "Block": "158",
            "label": "O.C.P.M GIRLS HR SEC SCHOOL, TALLAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18024",
            "district": "14",
            "Block": "158",
            "label": "SAIRAM MATRIC HR SEC SCHOOL, GORIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18025",
            "district": "14",
            "Block": "158",
            "label": "CORPORATION PONMUDIYAR GIRLS HR SEC SCHOOL, GORIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18026",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18027",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18028",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18029",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, M.CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18030",
            "district": "14",
            "Block": "161",
            "label": "MADURAI CRESCENT MATRIC HR SEC SCHOOL, KALLAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18031",
            "district": "14",
            "Block": "161",
            "label": "DON BOSCO HR SEC SCHOOL, THIRUMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18032",
            "district": "14",
            "Block": "161",
            "label": "AUXILIUM MATRIC SCHOOL, THIRUMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18033",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, OOMACHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18034",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, CHINNAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18035",
            "district": "14",
            "Block": "161",
            "label": "IAB (BLIND) HR SEC SCHOOL, SUNDARARAJANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18036",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANNANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18037",
            "district": "14",
            "Block": "161",
            "label": "MADONNA MATRICULATION HIGHER SECONDARY SCHOOL, KANNANENTHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18038",
            "district": "14",
            "Block": "161",
            "label": "SRI GOPALAKRISHNA HIGHER SECONDARY SCHOOL,SIRUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18039",
            "district": "14",
            "Block": "161",
            "label": "AUXILIUM  HIGHER SECONDARY SCHOOL, SIRUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18040",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, KARUVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18041",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. SIKKANTHAR SAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18042",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. KOILPAPPAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18043",
            "district": "14",
            "Block": "161",
            "label": "M.P.R. PUBLIC HIGHER SECONDARY SCHOOL-KAVANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18044",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, KULAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18045",
            "district": "14",
            "Block": "161",
            "label": "GOVT ADW HIGH SCHOOL, ELUMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18046",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, MANJAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18047",
            "district": "14",
            "Block": "161",
            "label": "PSYECHI MAT.H.S ATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18048",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, POTHUMBU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18049",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, SIRUVALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18050",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S.  THENUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18051",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. NARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18052",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, THIRUPPALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18053",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, NAGANAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18054",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18055",
            "district": "14",
            "Block": "161",
            "label": "SRI RAM N.M.P.S KACHARAMPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18056",
            "district": "14",
            "Block": "161",
            "label": "SRIRAM NALLAMANI YADAVA GIRLS HR SEC SCHOOL,THIRUPPALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18057",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. VAIRAVANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18058",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. SAMBAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18059",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. VELICHANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18060",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. MUDAKATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18061",
            "district": "14",
            "Block": "161",
            "label": "Sree Maha Vidhyalayam Matric School, Anaiyur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18062",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, ANAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18063",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S MILAGARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18064",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, MALAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18065",
            "district": "14",
            "Block": "161",
            "label": "THE NALLAMANI MATRIC Hr. Sec. SCHOOL, SAMAYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18066",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, SAMAYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18067",
            "district": "14",
            "Block": "161",
            "label": "ST.JOSEPH HIGH SCHOOL, SAMAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18068",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, PARAVAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18069",
            "district": "14",
            "Block": "161",
            "label": "ST.JOSEPH'S SCHOOL FOR THE BLIND,  PARAVAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18070",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, SATHIYAMOORTHY NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18071",
            "district": "14",
            "Block": "161",
            "label": "SREE MAHA MATRIC HR SEC SCHOOL, VAITHIYANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18072",
            "district": "14",
            "Block": "161",
            "label": "ADHYAPANA CBSE HS, VILANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18073",
            "district": "14",
            "Block": "161",
            "label": "SRI RAMANA MAHARISHI MATRICULATION SCHOOL-KOODALNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18074",
            "district": "14",
            "Block": "161",
            "label": "ALLWIN MATRIC HR SEC SCHOOL, KOODAL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18075",
            "district": "14",
            "Block": "161",
            "label": "SREE VISALAKSHI MILLS HIGH SCHOOL, VISALAKSHI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18076",
            "district": "14",
            "Block": "161",
            "label": "ST.ANT.M.S KOODALNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18077",
            "district": "14",
            "Block": "161",
            "label": "ST.ANTONY'S GIRLS HR SEC SCHOOL, KOODAL NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18078",
            "district": "14",
            "Block": "161",
            "label": "KESWICK PUBLIC SCHOOL, KOODALNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18079",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VILANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18080",
            "district": "14",
            "Block": "161",
            "label": "SHANTHI VID.M.S  PALLAR PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18081",
            "district": "14",
            "Block": "161",
            "label": "DHANAPAUL HR SEC SCHOOL, MULLAINAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18082",
            "district": "14",
            "Block": "161",
            "label": "R.C MIDDLE SCHOOL, K.PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18083",
            "district": "14",
            "Block": "161",
            "label": "AL-AMEEN HR SEC SCHOOL, K.PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18084",
            "district": "14",
            "Block": "161",
            "label": "PHOENIX MATRIC HR SEC SCHOOL, K PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18085",
            "district": "14",
            "Block": "161",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, SURYA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18086",
            "district": "14",
            "Block": "161",
            "label": "LOURDU ANNAI GIRLS HR SEC SCHOOL, K.PUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18087",
            "district": "14",
            "Block": "161",
            "label": "DE NOBILI MATRIC HR SEC SCHOOL, K PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18088",
            "district": "14",
            "Block": "161",
            "label": "MAHATMA MONTESSORI MATRIC HR SEC.SCHOOL, BABA BUILDING, SURVEYOR COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18089",
            "district": "14",
            "Block": "161",
            "label": "MAHATMA MONT CBSE K.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18090",
            "district": "14",
            "Block": "161",
            "label": "BALMANDIRAM HR SEC SCHOOL, MADURAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18091",
            "district": "14",
            "Block": "161",
            "label": "Y.M.C.A KAMAK Hr. Sec. SCHOOL FOR DEAF, VISHALAKSHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18092",
            "district": "14",
            "Block": "161",
            "label": "SEVENTHDAY MATRIC HR SEC SCHOOL, CHINNACHOKKIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18093",
            "district": "14",
            "Block": "161",
            "label": "GURUKUL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18094",
            "district": "14",
            "Block": "161",
            "label": "JOTHI HIGHER SECONDARY SCHOOL, NARIMEDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18095",
            "district": "14",
            "Block": "161",
            "label": "CORPORATION KAKKAIPADINIYAR GIRLS HR SEC SCHOOL, CHOKKIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18096",
            "district": "14",
            "Block": "161",
            "label": "Y.W.C.A MAT.HR.S.S VALLABAI RD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18097",
            "district": "14",
            "Block": "161",
            "label": "KENDRA VIDYALAYA HIGHER SECONDARY SCHOOL, NARIMEDU MADURAI 2",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18098",
            "district": "14",
            "Block": "161",
            "label": "COR.M.S KRISHNAPURAM COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18099",
            "district": "14",
            "Block": "161",
            "label": "DHANAPAL M.S B.B KULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18100",
            "district": "14",
            "Block": "161",
            "label": "CORPORATION PANDIAN NEDUNCHEZHIAN  HR SEC SCHOOL, AR LINE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18101",
            "district": "14",
            "Block": "161",
            "label": "CORPORATION BHARATHIAR HIGH SCHOOL, SELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18102",
            "district": "14",
            "Block": "161",
            "label": "RAJAJI M.S MEENAMBALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18103",
            "district": "14",
            "Block": "161",
            "label": "THIRU VI KA CORPORATION HR SEC SCHOOL, THATHANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18104",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KARAIYIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18105",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, E.MALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18106",
            "district": "14",
            "Block": "183",
            "label": "GOVT HIGH SCHOOL, PAPPAKUDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23354",
            "district": "14",
            "Block": "158",
            "label": "Chethana Mat. Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18107",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELAVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18108",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, SARUGUVALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18109",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VADAKKUVALAIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18110",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAVINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18111",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PATHINETTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18112",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, THERKKUTHERU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18113",
            "district": "14",
            "Block": "183",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, CHINNASURAKKUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18114",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, A.VALLALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18115",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KIDARIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18116",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18117",
            "district": "14",
            "Block": "183",
            "label": "LATHA MATHAVAN MATRIC HR SEC SCHOOL, KIDARIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18118",
            "district": "14",
            "Block": "183",
            "label": "ARULMIGU SUNDARARASA HIGH SCHOOL, ALAGARKOIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18119",
            "district": "14",
            "Block": "183",
            "label": "MAHATMA MONTESSORI MATRIC HIGHER SECONDARY SCHOOL, ALAGARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18120",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18121",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARITTAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18122",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, S.KALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18123",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLARIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18124",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THERKAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18125",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SUNNAMBOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18126",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VEPPADAPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18127",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, THIRUVATHAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18128",
            "district": "14",
            "Block": "183",
            "label": "CSI MS KOTTAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18129",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, KOTTANATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18130",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PALAIYURPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18131",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VANNAMPARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18132",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, THANIAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18133",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, VELLALURE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18134",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, URANGANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18135",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURICHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18136",
            "district": "14",
            "Block": "183",
            "label": "GOVT HIGH SCHOOL, SEMMANIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18137",
            "district": "14",
            "Block": "183",
            "label": "MILTON MATRIC HR SEC SCHOOL,MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18138",
            "district": "14",
            "Block": "183",
            "label": "MEENAKSHI HIGH SCHOOL, NONDIKOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18139",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, MELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18140",
            "district": "14",
            "Block": "183",
            "label": "AL-AMEEN URDHU TAMIL HIGH SCHOOL, MELUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18141",
            "district": "14",
            "Block": "183",
            "label": "MUNICIPAL HIGH SCHOOL, MELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18142",
            "district": "14",
            "Block": "183",
            "label": "TAMILARASI MIDDLE SCHOOL, MELUR.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18143",
            "district": "14",
            "Block": "183",
            "label": "GOVT GIRLS HR SEC SCHOOL, MELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18144",
            "district": "14",
            "Block": "183",
            "label": "SAKTHI VIDHYALAYA MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18145",
            "district": "14",
            "Block": "183",
            "label": "ABUL KALAM AZAD M.S.  MELUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18146",
            "district": "14",
            "Block": "183",
            "label": "SYDAI SA DURAISAMY MATRIC.HR SEC SCHOOL,MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18147",
            "district": "14",
            "Block": "183",
            "label": "JAYARAJ ANNAPACKIAM CSI HIGH SCHOOL, MELUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18148",
            "district": "14",
            "Block": "183",
            "label": "R.C.MIDDLE SCHOOL, MELUR.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18149",
            "district": "14",
            "Block": "283",
            "label": "GOVT HR SEC SCHOOL, SEDAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18150",
            "district": "14",
            "Block": "283",
            "label": "GOVT KALLAR HR SEC SCHOOL, POOSALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18151",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, CHINNAKATTALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18152",
            "district": "14",
            "Block": "283",
            "label": "GOVERNMENT KALLAR HR SEC  SCHOOL, PERUNGAMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18153",
            "district": "14",
            "Block": "283",
            "label": "GOVT BOYS HR SEC SCHOOL, ELUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18154",
            "district": "14",
            "Block": "283",
            "label": "GOVT GIRLS HR SEC SCHOOL, ELUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18155",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S. E.GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18156",
            "district": "14",
            "Block": "283",
            "label": "VIVEKANANDA MIDDILE SCHOOL, ELUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18157",
            "district": "14",
            "Block": "283",
            "label": "GOVT HR SEC SCHOOL, M. KALLUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18158",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, CHELLAYEPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18159",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, SEELANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18160",
            "district": "14",
            "Block": "283",
            "label": "G. K. MIDDLE SCHOOL MELATHIRUMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18161",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, ATHANKARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18162",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, T.RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18163",
            "district": "14",
            "Block": "283",
            "label": "Govt.ADW. Middle School, VADAKATHIYANPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18164",
            "district": "14",
            "Block": "283",
            "label": "GOVT KALLAR HR SEC SCHOOL, THADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18165",
            "district": "14",
            "Block": "283",
            "label": "RM P S RAMIAH NADAR HIGHER SECONDARY SCHOOL  ATHIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18166",
            "district": "14",
            "Block": "283",
            "label": "Rm.P.S.RAMAIAH NADAR MATRICULATION SCHOOL, ATHIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18167",
            "district": "14",
            "Block": "283",
            "label": "PARASAKTHI HIGHER SECONDARY SCHOOL  KOTTAIPATTI S",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18168",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, V.RAMASAMYPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18169",
            "district": "14",
            "Block": "283",
            "label": "SRI.LAKSHMI.N.M.S.VANDARI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18170",
            "district": "14",
            "Block": "283",
            "label": "GOVT HR SEC SCHOOL, SAPTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18171",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S PALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18172",
            "district": "14",
            "Block": "283",
            "label": "GOVT ADW HIGH SCHOOL, SANGARALINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18173",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, S.PAPINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18174",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S,  MUTHUNAGAIAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18175",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S. NAGAIAHPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18176",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S. CHEKKANURANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18177",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HR SEC SCHOOL, CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18178",
            "district": "14",
            "Block": "324",
            "label": "KEREN MATRICULATION HIGHER SECONDARY SCHOOL CHECKANURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18179",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S MAVILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18180",
            "district": "14",
            "Block": "324",
            "label": "GOVT HIGH SCHOOL, K.MEENAKSHIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18181",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HIGH SCHOOL, KARADIKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18182",
            "district": "14",
            "Block": "324",
            "label": "SRI MEENAKSHI GIRLS HR SEC SCHOOL, KARADIKKAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18183",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S.   UCHAPPATI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18184",
            "district": "14",
            "Block": "324",
            "label": "THIAGARAJAR MILLS HR SEC SCHOOL, KAPPALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18185",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HR SEC SCHOOL, KAPPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18186",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HR SEC SCHOOL, MELAURAPPANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18187",
            "district": "14",
            "Block": "324",
            "label": "JOTHI DHARMAN ASSISI HR SEC SCHOOL, SATHANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18188",
            "district": "14",
            "Block": "324",
            "label": "KSHATRIYA NADARS HIGH SCHOOL, SATHANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18189",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S KANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18190",
            "district": "14",
            "Block": "324",
            "label": "GOVT HR SEC SCHOOL, VAGAIKULAM P",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18191",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S GANGEIYANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18192",
            "district": "14",
            "Block": "324",
            "label": "GOVT MODEL HR SEC SCHOOL, P.AMMAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18193",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S KILAVANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18194",
            "district": "14",
            "Block": "324",
            "label": "GOVT HR SEC SCHOOL, ACHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18195",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S. KATRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18196",
            "district": "14",
            "Block": "324",
            "label": "R.N.M.S. T.PUDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18197",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S .S.MEENAKSHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18198",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S S.VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18199",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S. CHITHIREDDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18200",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S THANGALACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18201",
            "district": "14",
            "Block": "324",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  RAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18202",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S.ALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18203",
            "district": "14",
            "Block": "324",
            "label": "TEDDY HIGHER SECONDARY SCHOOL, ALAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18204",
            "district": "14",
            "Block": "324",
            "label": "GOVT HR SEC SCHOOL, MELAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18205",
            "district": "14",
            "Block": "324",
            "label": "FRANCOIS MEYER MATRIC SCHOOL, ALAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18206",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S. KARISALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18207",
            "district": "14",
            "Block": "324",
            "label": "MEPCO SCHLENK MATRIC HR SEC SCHOOL,  MARAVANKULAM, THIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18208",
            "district": "14",
            "Block": "324",
            "label": "GOVT HIGH SCHOOL, VIDATHAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18209",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S MAIKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18210",
            "district": "14",
            "Block": "324",
            "label": "GOVT HR SEC SCHOOL. THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18211",
            "district": "14",
            "Block": "324",
            "label": "PKN HR SEC SCHOOL, THIRUMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18212",
            "district": "14",
            "Block": "324",
            "label": "AL AMEEN MUSLIM HR SEC SCHOOL, THIRUMALGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18213",
            "district": "14",
            "Block": "324",
            "label": "PKN GIRLS HR SEC SCHOOL THIRUMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18214",
            "district": "14",
            "Block": "324",
            "label": "GOVT GIRLS HR SEC SCHOOL, THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18215",
            "district": "14",
            "Block": "324",
            "label": "C.S.I.HIGH.SCHOOL, THIRUMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18216",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, KODIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18217",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, THUVARIMAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18218",
            "district": "14",
            "Block": "328",
            "label": "BLESSING MATRIC HR SEC SCHOOL, ATCHAMPATHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18219",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  VILACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18220",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S   NAGAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18221",
            "district": "14",
            "Block": "328",
            "label": "M N U JAYARAJ NADAR HR SEC SCHOOL  NAGAMALAIPUDUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18222",
            "district": "14",
            "Block": "328",
            "label": "SIRUMALAR GIRLS HR SEC SCHOOL, NAGAMALAI PUDUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18223",
            "district": "14",
            "Block": "328",
            "label": "BEN MATRIC HIGH SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18224",
            "district": "14",
            "Block": "328",
            "label": "M.N.U JAYARAJ ANNAPACKIAM MATRIC HR SEC SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18225",
            "district": "14",
            "Block": "328",
            "label": "AKSHARA MATRIC HR SEC SCHOOL, KARADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18226",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, VADAPALANJI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18227",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  THENPALANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18228",
            "district": "14",
            "Block": "328",
            "label": "PALKALAI NAGAR MS PALKALAINAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18229",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S VELLAI PARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18230",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL,VEDARPULIYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18231",
            "district": "14",
            "Block": "328",
            "label": "ST.CHARLES GIRLS HR SEC SCHOOL, THIRUVALLUVAR NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18232",
            "district": "14",
            "Block": "328",
            "label": "GOVT KALLAR HIGH SCHOOL, THANAKKANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18233",
            "district": "14",
            "Block": "328",
            "label": "GOVT. KALLAR MIDDLE SCHOOL,  KEELAKUILKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18234",
            "district": "14",
            "Block": "328",
            "label": "GOVT.KALLAR MIDDLE SCHOOL, VADIVELKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18235",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, VIRAGANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18236",
            "district": "14",
            "Block": "328",
            "label": "LEONARD SPL FOR THE HEARING IM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18237",
            "district": "14",
            "Block": "328",
            "label": "VELAMMAL MATRIC HR SEC SCHOOL, VIRAGANOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18238",
            "district": "14",
            "Block": "328",
            "label": "GOVT HR SEC SCHOOL, S PULIANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18239",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  PANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18240",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  CHINTHAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18241",
            "district": "14",
            "Block": "328",
            "label": "VELAMMAL VIDYALAYA, ANUPPANADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18242",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, SAMANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18243",
            "district": "14",
            "Block": "328",
            "label": "IDHAYAM RAJENDRAN RESIDENTIAL SCHOOL, SAMANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18244",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  PERIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18245",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  PRASANNA COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18246",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, AYANPAPPAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18247",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  CHINNAUDAIPPU THIRUPPARANKUNRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18248",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, VALAYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18249",
            "district": "14",
            "Block": "328",
            "label": "ST.ANNES GIRLS HR SEC SCHOOL, PERUNGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18250",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, PERUNKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18251",
            "district": "14",
            "Block": "328",
            "label": "N.K.KUPPIEN RATHNAMANI VIDYALAYA MIDDLE SCHOOL, KAITHARI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18252",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, NILAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18253",
            "district": "14",
            "Block": "328",
            "label": "AIDED MIDDLE SCHOOL, KOOTHIYARKUNDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18254",
            "district": "14",
            "Block": "328",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SOORAKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18255",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  PARAMBUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18256",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, PERIYA ALANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18257",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S NEDUMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18258",
            "district": "14",
            "Block": "328",
            "label": "GOVT HR SEC SCHOOL, PARAPATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18259",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18260",
            "district": "14",
            "Block": "328",
            "label": "PUMS SOLANKURUNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18261",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18262",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, VIRATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18263",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  NEDUNKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18264",
            "district": "14",
            "Block": "328",
            "label": "GOVT HR SEC SCHOOL, AVANIAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18265",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  AVANIAPURAM    1",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18266",
            "district": "14",
            "Block": "328",
            "label": "P.N.U.A.S NADAR MATRIC HR SEC SCHOOL, AVANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18267",
            "district": "14",
            "Block": "328",
            "label": "PASUMALAI HR SEC SCHOOL, PASUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18268",
            "district": "14",
            "Block": "328",
            "label": "CSI GIRLS HR SEC SCHOOL, PASUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18269",
            "district": "14",
            "Block": "328",
            "label": "PANSY VIDYALAYA MATRIC HR SEC SCHOOL, THIRUPARANKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18270",
            "district": "14",
            "Block": "328",
            "label": "GOVT HR SEC SCHOOL, THIRUPRANKUNDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18271",
            "district": "14",
            "Block": "328",
            "label": "JOTHI M.S CHANDRA PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18272",
            "district": "14",
            "Block": "328",
            "label": "ARULMIGU ANDAVAR SUBRAMANIA SWAMY GIRLS HR SEC SCHOOL, THIRUPARANKUNDRAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18273",
            "district": "14",
            "Block": "328",
            "label": "MUTHUTHEVAR MUKKULATHORE HR SEC SCHOOL, THIRUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18274",
            "district": "14",
            "Block": "328",
            "label": "AMALA PETHANNEL M.S THIRUNAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18275",
            "district": "14",
            "Block": "328",
            "label": "MALLEGAI MATRIC HIGH SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18276",
            "district": "14",
            "Block": "328",
            "label": "SITALAKSHMI GIRLS HR SEC SCHOOL, THIRUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18277",
            "district": "14",
            "Block": "328",
            "label": "CS RAMACHARY MEMORIAL MATRIC HR SEC SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18278",
            "district": "14",
            "Block": "328",
            "label": "SAVITHABAI (KOVAI) HR SEC SCHOOL, THIRUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18279",
            "district": "14",
            "Block": "328",
            "label": "SARAH MIDLE SCHOOL, THIRUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18280",
            "district": "14",
            "Block": "328",
            "label": "COR.M.S VILLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18281",
            "district": "14",
            "Block": "328",
            "label": "GOVT. ORTHO S VILLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18282",
            "district": "14",
            "Block": "328",
            "label": "K.V.T MATRIC HR SEC SCHOOL, SUBRAMANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18283",
            "district": "14",
            "Block": "328",
            "label": "NEETHIRAJAN BHARATHI HIGH SCHOOL, JAIHINDUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18284",
            "district": "14",
            "Block": "328",
            "label": "NETHAJI MIDDLE SCHOOL, JAIHINDPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18285",
            "district": "14",
            "Block": "328",
            "label": "COR.M.S JAIHINDUPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18286",
            "district": "14",
            "Block": "328",
            "label": "K.A.C.A ARUNACHALAM HR SEC SCHOOL, JAIHINDPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18287",
            "district": "14",
            "Block": "328",
            "label": "SRI AYYANAR MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18288",
            "district": "14",
            "Block": "328",
            "label": "T.V.S HR SEC SCHOOL, LAKSHMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18289",
            "district": "14",
            "Block": "328",
            "label": "COR.M.S VEERAMUDAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18290",
            "district": "14",
            "Block": "328",
            "label": "THE TVS SCHOOL, TVS NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18291",
            "district": "14",
            "Block": "328",
            "label": "GOVT.KALLAR.H.S, MUTHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18292",
            "district": "14",
            "Block": "328",
            "label": "CORPORATION MIDDLE SCHOOL, PASUMALAI SUB STATION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18293",
            "district": "14",
            "Block": "328",
            "label": "RUBY MATRIC HR SEC SCHOOL, PALANGANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18294",
            "district": "14",
            "Block": "328",
            "label": "VISAKAN  SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18295",
            "district": "14",
            "Block": "328",
            "label": "COR.M.S MADAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18296",
            "district": "14",
            "Block": "328",
            "label": "CORPORATION NSSB GIRLS HR SEC SCHOOL, PALANGANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18297",
            "district": "14",
            "Block": "328",
            "label": "R.C HIGH SCHOOL, PALANGANATHAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18298",
            "district": "14",
            "Block": "328",
            "label": "SWATHI MATRICULATION SCHOOL, PALANGANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18299",
            "district": "14",
            "Block": "328",
            "label": "SRI AUROBINDO MIRA MATRIC HR SEC SCHOOL, NEHRU NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18300",
            "district": "14",
            "Block": "328",
            "label": "JEEVANA SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18301",
            "district": "14",
            "Block": "328",
            "label": "KNPMM PILLAIMAR SANGAM HR SEC SCHOOL, SAMMATTIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18302",
            "district": "14",
            "Block": "328",
            "label": "INFANT JESUS M.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18303",
            "district": "14",
            "Block": "328",
            "label": "RAJAM VIDYALAYAM MIDDLE SCHOOL, PONMENI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18304",
            "district": "14",
            "Block": "328",
            "label": "A.L.M MATRIC HIGH SCHOOL, Ponmeni, ARASARADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18305",
            "district": "14",
            "Block": "328",
            "label": "KENDRA VIDYALAYA HIGHER SECONDARY SCHOOL, THIRUPARANKUNDRAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18306",
            "district": "14",
            "Block": "328",
            "label": "K.G.V SALA HIGH SCHOOL, VIRATTIPATHU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18307",
            "district": "14",
            "Block": "328",
            "label": "SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, KOCHADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18308",
            "district": "14",
            "Block": "328",
            "label": "SRI SADHANA MATRIC HR SEC SCHOOL, VIRATTIPATHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18309",
            "district": "14",
            "Block": "328",
            "label": "QUEEN MIRA INTERN S KOCHADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18310",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S. UTHAPPANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18311",
            "district": "14",
            "Block": "374",
            "label": "JAYASEELAN MEMORIAL MATRIC SCHOOL, UTHAPPANAYAKKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18312",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S, U.VADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18313",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HR SEC SCHOOL, VELLAIMALAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18314",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S KAMARAJAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18315",
            "district": "14",
            "Block": "374",
            "label": "A RATHINASAMY NADAR MEMORIAL HIGHER SECONDARY SCHOOL UTHAPPANAYAKKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18316",
            "district": "14",
            "Block": "374",
            "label": "GOVT.K.M.S. KALLUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18317",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HR SEC SCHOOL, K.PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18318",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, SULIOCHANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18319",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18320",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S.SEEMANUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18321",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HR SEC SCHOOL, A.POOCHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18322",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S. MELAEDAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18323",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S CHETTIAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18324",
            "district": "14",
            "Block": "374",
            "label": "GOVT HIGH SCHOOL, THOTTAPPANAYAKKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18325",
            "district": "14",
            "Block": "374",
            "label": "GOVERNMENT KALLAR HIGH SCHOOL  NALLUTHEVANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18326",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S POTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18327",
            "district": "14",
            "Block": "374",
            "label": "GOVERNMENT KALLAR HIGH SCHOOL  VAGURANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18328",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S AYOTHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18329",
            "district": "14",
            "Block": "374",
            "label": "GOVT K.M.S ERUMARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18330",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S ALLIKUNDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18331",
            "district": "14",
            "Block": "374",
            "label": "GOVT. K.M.S MANUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18332",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S JOTHILNAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23366",
            "district": "14",
            "Block": "160",
            "label": "DEVASAKAYAM CHETTIYAR MIDDLE SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23367",
            "district": "14",
            "Block": "158",
            "label": "K KANDASAMY KOTHANAR HR.SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18333",
            "district": "14",
            "Block": "374",
            "label": "NADAR SARASWATHI MATRICULATION HR SEC SCHOOL, USILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18334",
            "district": "14",
            "Block": "374",
            "label": "R C LITTLE FLOWER GIRLS HIGHER SECONDARY SCHOOL MELAPUDUR USILAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18335",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S  BAJAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18336",
            "district": "14",
            "Block": "374",
            "label": "GOVT HR SEC SCHOOL, USILAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18337",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, KARUKATTANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18338",
            "district": "14",
            "Block": "374",
            "label": "T.E.L.C.M.S   USILAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18339",
            "district": "14",
            "Block": "374",
            "label": "T E L C GIRLS' HIGHER SECONDARY SCHOOL USILAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18340",
            "district": "14",
            "Block": "374",
            "label": "T.E.L.C M.S  KEELAPUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18341",
            "district": "14",
            "Block": "374",
            "label": "NADAR SARASWATHI HIGHER SECONDARY SCHOOL  USILAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18342",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S. KULASEKARANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18343",
            "district": "14",
            "Block": "306",
            "label": "MADURA SUGARS GOVT HR SEC SCHOOL.PANDIARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18344",
            "district": "14",
            "Block": "306",
            "label": "GOVERNMENT MIDDLE SCHOOL PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18345",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S SEMINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18346",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S.KUTLADAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18347",
            "district": "14",
            "Block": "306",
            "label": "GOVT HIGH SCHOOL, KATCHAIKATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18348",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S. T.ANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18349",
            "district": "14",
            "Block": "306",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18350",
            "district": "14",
            "Block": "306",
            "label": "GOVT HIGH SCHOOL, METTUNEERATHAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18351",
            "district": "14",
            "Block": "306",
            "label": "GOVT GIRLS HR SEC SCHOOL, T.VADIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18352",
            "district": "14",
            "Block": "306",
            "label": "GOVT BOYS HR SEC SCHOOL, T.VADIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18353",
            "district": "14",
            "Block": "306",
            "label": "ST CHARLES HIGH SCHOOL, T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18354",
            "district": "14",
            "Block": "306",
            "label": "GOVT ADW HR SEC SCHOOL, NACHIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18355",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S IRUMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18356",
            "district": "14",
            "Block": "306",
            "label": "GOVT HIGH SCHOOL, KARUPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18357",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S MANNADI MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18358",
            "district": "14",
            "Block": "306",
            "label": "GOVT KALLAR HR SEC SCHOOL, AYYAPPANAIYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18359",
            "district": "14",
            "Block": "306",
            "label": "GOVT HR SEC SCHOOL, T.MULLIPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18360",
            "district": "14",
            "Block": "306",
            "label": "GOVT HIGH SCHOOL, KADUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18361",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S KELAMATTAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18362",
            "district": "14",
            "Block": "306",
            "label": "GOVT KALLAR HR SEC SCHOOL, MELLAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18363",
            "district": "14",
            "Block": "306",
            "label": "KERAN MATRIC.SCHOOL, MALAYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18364",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S.KATCHIRAYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18365",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S PETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18366",
            "district": "14",
            "Block": "306",
            "label": "GOVT GIRLS HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18367",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S.SHOLAVANDHAN,CHATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18368",
            "district": "14",
            "Block": "306",
            "label": "R.C.M.SHOLAVANDHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18369",
            "district": "14",
            "Block": "306",
            "label": "KAMARAJAR MATRIC HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18370",
            "district": "14",
            "Block": "306",
            "label": "AS GOVT HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18371",
            "district": "14",
            "Block": "306",
            "label": "KAMARAJAR.MID.S.SHOLAVANDHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18372",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S.THIRUVEDAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18373",
            "district": "14",
            "Block": "306",
            "label": "VIVEKANANDA HR SEC SCHOOL, THIRUVEDAKAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18374",
            "district": "14",
            "Block": "306",
            "label": "NARENDRA MATRICULATION  SCHOOL, THIRUVEDAKAM WEST",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18375",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18376",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S THIRUVALAVAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18377",
            "district": "14",
            "Block": "306",
            "label": "MOUNT LITERA ZEE SCHOOL, RAYAPURAM, MADURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18378",
            "district": "14",
            "Block": "306",
            "label": "R.C.M.S.ROYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18379",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S. C.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18380",
            "district": "14",
            "Block": "159",
            "label": "COR.M.S SHENOY NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18381",
            "district": "14",
            "Block": "159",
            "label": "SUBBARAYALU MEMORIAL  MIDDLE SCHOOL, ALWARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18382",
            "district": "14",
            "Block": "159",
            "label": "COR.M.S KARUMBALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18383",
            "district": "14",
            "Block": "159",
            "label": "COR.M.S RAMNAD A.R.LINE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18384",
            "district": "14",
            "Block": "159",
            "label": "JEYABHARATH M.S PALAM ST.ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18385",
            "district": "14",
            "Block": "159",
            "label": "MANOHARA M.S SELLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18386",
            "district": "14",
            "Block": "159",
            "label": "ST.JOHN DE BRITTO MS, THATHTHANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18387",
            "district": "14",
            "Block": "159",
            "label": "MLWA HR SEC SCHOOL, PONNAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18388",
            "district": "14",
            "Block": "159",
            "label": "MANGAYARKARASI HR SEC SCHOOL, PONNAGRAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18389",
            "district": "14",
            "Block": "159",
            "label": "VIKAASA MATRIC Hr.Sec. SCHOOL, PONNAGRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18390",
            "district": "14",
            "Block": "159",
            "label": "T.E.L.C. JEYARAJ ANNAPACKIAM HIGH SCHOOL, PONNAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18391",
            "district": "14",
            "Block": "159",
            "label": "SRI MEENAKSHI MIDDLE SCHOOL, SHUNMUGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18392",
            "district": "14",
            "Block": "159",
            "label": "GEORGE M.S RAJENDRA ST. MADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18393",
            "district": "14",
            "Block": "159",
            "label": "C.C.P Middle School,  RAJENDRA MAIN ROAD.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18394",
            "district": "14",
            "Block": "159",
            "label": "CORPORATION VELLIVEETHIAR GIRLS HR SEC SCHOOL, PONNAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18395",
            "district": "14",
            "Block": "159",
            "label": "MAD.MUTHU M.S AZHAGARADI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18396",
            "district": "14",
            "Block": "159",
            "label": "CORPORATION SETHUPATHI PANDITHURAI BOYS HR SEC SCHOOL, NEW JAIL ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18397",
            "district": "14",
            "Block": "159",
            "label": "MUKKULATHOR MIDDLE SCHOOL, MELAPONNAGARAM MAIN ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18398",
            "district": "14",
            "Block": "159",
            "label": "BALA M.S MELAPONNANAGRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18399",
            "district": "14",
            "Block": "159",
            "label": "HOLY FAMILY GIRLS HR SEC SCHOOL, GNANAOLIVUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18400",
            "district": "14",
            "Block": "159",
            "label": "ST.BRITTO HR SEC SCHOOL, GNANAOLIVUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18401",
            "district": "14",
            "Block": "159",
            "label": "Y.M.C.C. MIDDLE SCHOOL, NEW JAIL ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18402",
            "district": "14",
            "Block": "159",
            "label": "SRI VIDYALAYAM MATRIC HSS, S.S.COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18403",
            "district": "14",
            "Block": "159",
            "label": "GOVT GIRLS HR SEC SCHOOL, MAHABOOBPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18404",
            "district": "14",
            "Block": "159",
            "label": "AZHAGHU SUNDHRAM MEM.M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18405",
            "district": "14",
            "Block": "159",
            "label": "MADURAI CITY MIDDLE SCHOOL  ANSARINAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18406",
            "district": "14",
            "Block": "159",
            "label": "MADURA COLLEGE HR SEC SCHOOL, RMS ROAD, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18407",
            "district": "14",
            "Block": "159",
            "label": "RAILWAY  HSS, RAILWAY COLONY",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18408",
            "district": "14",
            "Block": "159",
            "label": "UNION CHRISTIAN HR SEC SCHOOL, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18409",
            "district": "14",
            "Block": "159",
            "label": "CORPORATION MIDDLE SCHOOL, WEST GATE NO 2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18410",
            "district": "14",
            "Block": "159",
            "label": "MANGAYARKARASI MIDDLE SCHOOL, MANINAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18411",
            "district": "14",
            "Block": "159",
            "label": "SETUPATI HR SEC SCHOOL, NORTHVELI STREET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18412",
            "district": "14",
            "Block": "159",
            "label": "HOLY ANGEL HR SEC SCHOOL, KANAGAVEL COLONY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18413",
            "district": "14",
            "Block": "159",
            "label": "VEERAMAMUNIVAR VIDYASALAI MIDDLE SCHOOL, KANAGAVEL COLONY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18414",
            "district": "14",
            "Block": "159",
            "label": "SAHAYAMATHA MIDDLE SCHOOL, M.K.PURAM, JAIHINDUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18415",
            "district": "14",
            "Block": "159",
            "label": "PREM VIDYA SALAI SOLAI ALAGU P",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18416",
            "district": "14",
            "Block": "159",
            "label": "SRI MURUGAN MIDDLE SCHOOL, JEEVA NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18417",
            "district": "14",
            "Block": "159",
            "label": "FATIMA MIDDLE SCHOOL, KASTHURIBAI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18418",
            "district": "14",
            "Block": "160",
            "label": "GUHAN MATRIC HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18419",
            "district": "14",
            "Block": "160",
            "label": "R.C MIDDLE SCHOOL , ROSARY CHURCH, TOWNHALL ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18420",
            "district": "14",
            "Block": "160",
            "label": "RAJAPARAMESWARI MIDDLE SCHOOL, ANNANAGAR,  VANDIYUR MAIN ROAD , MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18421",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION KASTHURIBAI GANDHI GIRLS HR SEC SCHOOL, SIMMAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18422",
            "district": "14",
            "Block": "160",
            "label": "D M & R GIRLS' HIGH SCHOOL NORTH MASI STREET MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18423",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION AVVAI GIRLS HR SEC SCHOOL, EAST AVANI MOOLA STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18424",
            "district": "14",
            "Block": "160",
            "label": "AYIRA VAISYA HR SEC SCHOOL,  VELLIAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18425",
            "district": "14",
            "Block": "160",
            "label": "SOURASHTRA  (CO-ED) HR SEC SCHOOL, NETHAJI ROAD, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18426",
            "district": "14",
            "Block": "160",
            "label": "DIOCESE  OF MADURAI AND RAMNAD MIDDLE SCHOOL, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18427",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION MARAIMALAI ADIGALAR BOYS HIGH SCHOOL, SOUTH GATE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18428",
            "district": "14",
            "Block": "160",
            "label": "PANDIA VELLALA COR.M.S",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18429",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION  EVRN GIRLS HR SEC SCHOOL, SOUTH VELI STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18430",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION HR SEC SCHOOL, SUNDHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18431",
            "district": "14",
            "Block": "160",
            "label": "THIAGARAJAR HR SEC SCHOOL, VASANTHA NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18432",
            "district": "14",
            "Block": "160",
            "label": "GURUKULAM HIGH SCHOOL, SUBRAMANIAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18433",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION KAMBAR HR SEC SCHOOL, SUBRAMANIAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18434",
            "district": "14",
            "Block": "160",
            "label": "ST.JOSEPH'S GIRLS HR SEC SCHOOL, OLD KUYAVARPALAYAM ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18435",
            "district": "14",
            "Block": "160",
            "label": "NADAR HR SEC SCHOOL, SOUTH GATE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18436",
            "district": "14",
            "Block": "160",
            "label": "SINGARATOPE COR.M.S",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18437",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION BHARATHIDASANAR HR SEC SCHOOL, NEW MAHALIPATTI ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18438",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION N.M.S.M HIGH SCHOOL, EAST GATE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18439",
            "district": "14",
            "Block": "160",
            "label": "R.C MIDDLE SCHOOL, EAST GATE, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18440",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION THIRUVALLUVAR MAHAL HIGH SCHOOL, MADURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18441",
            "district": "14",
            "Block": "160",
            "label": "V.H.N.GIRLS HR SEC SCHOOL, MUNICHALAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18442",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION UMARUPULAVAR HIGH SCHOOL, PERIYAPALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18443",
            "district": "14",
            "Block": "160",
            "label": "M.A.V.M.M HR SEC SCHOOL, YANAIKKAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18444",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION HIGH SCHOOL, MUNICHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18445",
            "district": "14",
            "Block": "160",
            "label": "RAJA DHANALAKSHMI M.S.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18446",
            "district": "14",
            "Block": "160",
            "label": "MEENAKSHIPURAM MIDDLE SCHOOL  SREENIVASA PERUMALKOIL STREET, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18447",
            "district": "14",
            "Block": "160",
            "label": "SOURASHTRA HR SEC SCHOOL, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18448",
            "district": "14",
            "Block": "160",
            "label": "SRI P.V MATRIC HR SEC SCHOOL, KAMARAJAR SALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18449",
            "district": "14",
            "Block": "160",
            "label": "M.K.R.AYYA NADAR JEYALAKSHMI AMMAL HR SEC SCHOOL TEPPAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23703",
            "district": "14",
            "Block": "324",
            "label": "Aarushi Global School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "18450",
            "district": "14",
            "Block": "160",
            "label": "NIRMALA GIRLS HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18451",
            "district": "14",
            "Block": "160",
            "label": "GOMATHI M.S BABU NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18452",
            "district": "14",
            "Block": "160",
            "label": "R.C FATIMA M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18453",
            "district": "14",
            "Block": "160",
            "label": "THIAGARAJAR MODEL HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18454",
            "district": "14",
            "Block": "160",
            "label": "V.M.J HR.S.S",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18455",
            "district": "14",
            "Block": "160",
            "label": "SRI MEENAKSHI SUNDARESWARAR GIRLS HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18456",
            "district": "14",
            "Block": "160",
            "label": "MOTHER THERESA MATRIC HR SEC SCHOOL, MELA ANUPPANADY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18457",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION HIGH SCHOOL, ANUPPANADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18458",
            "district": "14",
            "Block": "160",
            "label": "ST.PATRICK'S MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18459",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION MANIMEGALAI GIRLS HIGH SCHOOL, KAMARAJAR SALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18460",
            "district": "14",
            "Block": "160",
            "label": "VHN BOYS HR SEC SCHOOL, KUYAVARPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18461",
            "district": "14",
            "Block": "160",
            "label": "OM SARAVANA BHAVA THIRUMURGAN MIDDLE SCHOOL,  EAST MADURAI STATION ROAD , MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18462",
            "district": "14",
            "Block": "160",
            "label": "ANNAI THERESA MATRIC HIGH SCHOOL, BALARENGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18463",
            "district": "14",
            "Block": "160",
            "label": "CHANDRA MIDDLE SCHOOL, MELA ANUPPANDI, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18464",
            "district": "14",
            "Block": "160",
            "label": "ST.MARY'S  HR SEC SCHOOL, EAST VELI ST.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18465",
            "district": "14",
            "Block": "160",
            "label": "PNU A.P.T.DORAIRAJ HIGHER SEC SCHOOL, MELAANUPPANADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18466",
            "district": "14",
            "Block": "160",
            "label": "DR.T.THIRUGNANAM HIGH SCHOOL, EAST SANTHAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18467",
            "district": "14",
            "Block": "160",
            "label": "MANU GIRLS HR SEC SCHOOL, KEERAITHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18468",
            "district": "14",
            "Block": "160",
            "label": "SUNDARAMBAL M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20887",
            "district": "14",
            "Block": "161",
            "label": "MAHARISHI VIDYAMANDIR S, KOILPAPPAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20919",
            "district": "14",
            "Block": "324",
            "label": "P.K.N VIDYALAYA CBSE SCHOOL, THIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21106",
            "district": "14",
            "Block": "328",
            "label": "OM SADHANA CENTRAL CBSE S, VIRATTIPATH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21129",
            "district": "14",
            "Block": "328",
            "label": "ST.ASSISI WORL  IGCSE SCH, THUVARIMAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21166",
            "district": "14",
            "Block": "303",
            "label": "SAMABHARATHAM MATRICULATION  HIGHER SECONDARY  SCHOOL, KILANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21167",
            "district": "14",
            "Block": "283",
            "label": "THIRUVALLUVAR MATRIC HIGHER SECONARY  SCHOOL, SOOLAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21168",
            "district": "14",
            "Block": "374",
            "label": "JAYASEELAN MATRIC HR SEC SCHOOL, MATHARAI, MUTHUPANDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21285",
            "district": "14",
            "Block": "136",
            "label": "ONE TO THRE WONDER CONVENT MATRICULATION SCHOOL, VALAISERIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21286",
            "district": "14",
            "Block": "183",
            "label": "R.V.  MATRICULATION HIGHER SECONDARY SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21287",
            "district": "14",
            "Block": "160",
            "label": "SRI VANI VIDHYALAYA MATRIC HR SEC SCHOOL, SUBRAMANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21336",
            "district": "14",
            "Block": "183",
            "label": "SRI LAKSHMI NARAYANA VID CBSE S, A.VALAYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21337",
            "district": "14",
            "Block": "328",
            "label": "VELAMMAL CBSE.H.S VIRAGANOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21357",
            "district": "14",
            "Block": "159",
            "label": "SEVENTH DAY ADV.H.S  ICSE S,KENNET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21400",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, VADAKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21401",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, RAJAKKOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21402",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, THOPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21487",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HIGH SCHOOL, KINNIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21488",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21489",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, MEIKILARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21557",
            "district": "14",
            "Block": "328",
            "label": "PALLOTTI HIGHER  SECONDARY  SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21561",
            "district": "14",
            "Block": "328",
            "label": "MALLI VIDHYALAYA HIGH SCHOOL, PERUNGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21637",
            "district": "14",
            "Block": "374",
            "label": "AACHI INTERNATIONAL  SCHOOL, USILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21749",
            "district": "14",
            "Block": "328",
            "label": "K.M.R INTERNATIONAL CBSE SCHOOL, ATCHAMPATHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21750",
            "district": "14",
            "Block": "328",
            "label": "K.C VIDYALAY CBSE HIGH SCHOOL, ELIYARPATHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21751",
            "district": "14",
            "Block": "324",
            "label": "KEREN PUBLIC SCHOOL, CHEKKANURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21877",
            "district": "14",
            "Block": "161",
            "label": "VIKAASA WORLD Hr. Sec. SCHOOL, VEERAPANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21956",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR GIRLS HR SEC SCHOOL, CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22241",
            "district": "14",
            "Block": "43",
            "label": "GURUDEV MATRIC  SCHOOL, POOTHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22242",
            "district": "14",
            "Block": "303",
            "label": "HINDU AIDED HIGHSCHOOL, SALISANTHAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22243",
            "district": "14",
            "Block": "158",
            "label": "R.C HIGH SCHOOL, KATHAKINARU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22244",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, POIGAIKARAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22245",
            "district": "14",
            "Block": "158",
            "label": "VALLABA VID CBSE S, THIRUVIZHANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22246",
            "district": "14",
            "Block": "158",
            "label": "P.N. MATRICULATION HIGHER SECONDARY SCHOOL- MANGALAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22247",
            "district": "14",
            "Block": "183",
            "label": "ARRAHMAAN INTERNATIONAL SCHOOL, CHINNASOORAKUNDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22248",
            "district": "14",
            "Block": "183",
            "label": "JAZZ PUBLIC MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22249",
            "district": "14",
            "Block": "283",
            "label": "VISWA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL., ELUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22250",
            "district": "14",
            "Block": "328",
            "label": "SRI AUROBINDO MIRA UNIVERSAL SCHOOL, KEELAMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22251",
            "district": "14",
            "Block": "328",
            "label": "S.B.O.A.  SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22252",
            "district": "14",
            "Block": "328",
            "label": "K.L.N. VIDYALAYA CBSE Hr. Sec. SCHOOL, VIRAGANOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22253",
            "district": "14",
            "Block": "328",
            "label": "THE INDIAN 3C MATRICULATION SCHOOL. , VILACHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22254",
            "district": "14",
            "Block": "328",
            "label": "SWAMI VIVEKANANDA VID. CBSE S, HARVIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22255",
            "district": "14",
            "Block": "374",
            "label": "KEREN PUBLIC SCHOOL,  KARAYANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22256",
            "district": "14",
            "Block": "374",
            "label": "KEREN MATRIC SCHOOL, KARAYANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22257",
            "district": "14",
            "Block": "306",
            "label": "THAAI SCHOOL, T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22597",
            "district": "14",
            "Block": "4",
            "label": "THAAI MATRIC HR SEC SCHOOL, ALANGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22598",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HIGH SCHOOL, AYYANARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22599",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, CHINNA ULAGANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22600",
            "district": "14",
            "Block": "97",
            "label": "V.T.MANIKANDAN MATRIC SCHOOL, K.VELLAKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22601",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL.V.CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22602",
            "district": "14",
            "Block": "158",
            "label": "MAHATMA GLOBAL GATEWAY CBSE SCHOOL, VEERAPANCHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22603",
            "district": "14",
            "Block": "158",
            "label": "MAHATMA GLOBAL GATEWAY INTERNATION S, VEERAPANCHAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22604",
            "district": "14",
            "Block": "161",
            "label": "JAIN VIDYALAYA  SCHOOL, THIRUPPALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22605",
            "district": "14",
            "Block": "183",
            "label": "MAHATMA MONTESSORI SCHOOL, ALAGARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22606",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL THULLUKUTTINAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22607",
            "district": "14",
            "Block": "324",
            "label": "ST.JOSEPH MATRICULATION SCHOOL, THIRALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22608",
            "district": "14",
            "Block": "328",
            "label": "VIKASA PROGRASSIVE SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22609",
            "district": "14",
            "Block": "328",
            "label": "NMS VIJAYALAKSHMI SANJEEVIMALAYAN KALVIYAGAM, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22610",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, KEERIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22764",
            "district": "14",
            "Block": "158",
            "label": "GOVT. SPECIAL SCHOOL FOR VI, K.K.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22765",
            "district": "14",
            "Block": "158",
            "label": "SPARKS VIDYALA SPECIAL S, SATHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22774",
            "district": "14",
            "Block": "183",
            "label": "EDUSTAR MODEL SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22779",
            "district": "14",
            "Block": "136",
            "label": "NAVA INDIA CHINESE MATRICULATION SCHOOL, ALAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22789",
            "district": "14",
            "Block": "328",
            "label": "EVERGREEN VIDHYAASHRAM CBSE SCHOOL, AVANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22791",
            "district": "14",
            "Block": "161",
            "label": "MADURAI PUBLIC SCHOOL, PETCHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22829",
            "district": "14",
            "Block": "328",
            "label": "ELITE PUBLIC CBSE SCHOOL, PULIYANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22830",
            "district": "14",
            "Block": "328",
            "label": "NAGASIVA VIDYALAYA SCHOOL, SAMANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22831",
            "district": "14",
            "Block": "328",
            "label": "DOLPHIN ELITE CBSE S, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22883",
            "district": "14",
            "Block": "158",
            "label": "OLR SPECIAL SCHOOL, VANDIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22888",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, KARUVELAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22893",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, AYYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22963",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, T.KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22964",
            "district": "14",
            "Block": "183",
            "label": "GOVT HIGH SCHOOL, PULIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22965",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, OORMECHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22997",
            "district": "14",
            "Block": "158",
            "label": "MAHAJANA VIDYALAYA MAT. S, UTHANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23003",
            "district": "14",
            "Block": "161",
            "label": "DELHI WORLD PUBLIC SCHOOL, PARAVAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23019",
            "district": "14",
            "Block": "160",
            "label": "PNU Dr.T.MUTHUSAMY HIGHER SECONDARY SCHOOL, VAZHAITHOPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23035",
            "district": "14",
            "Block": "324",
            "label": "GOVT. KALLAR HIGH SCHOOL, K.PULIYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23036",
            "district": "14",
            "Block": "43",
            "label": "GOVT. KALLAR HIGH SCHOOL, A.PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23037",
            "district": "14",
            "Block": "374",
            "label": "GOVT. KALLAR HIGH SCHOOL, V.PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23071",
            "district": "14",
            "Block": "161",
            "label": "JANNAH PUBLIC SCHOOL, PARAYATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23072",
            "district": "14",
            "Block": "161",
            "label": "VEDIC VIDYASHRAM SCHOOL, THIRUPPALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23130",
            "district": "14",
            "Block": "159",
            "label": "TRIVENI SCHOOL, PONNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23131",
            "district": "14",
            "Block": "328",
            "label": "ASHVATTHA SCHOOL, THANATHAVAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23192",
            "district": "14",
            "Block": "158",
            "label": "KENDIRIYA VIDYALAYA ITBP SCHOOL, IDAYAPATTI",
            "Sch_type": "Central Govt",
            "Category": "Middle School"
        },
        {
            "key": "23313",
            "district": "14",
            "Block": "324",
            "label": "NRM DREAM INTERNATIONAL SCHOOL, SATHANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23318",
            "district": "14",
            "Block": "183",
            "label": "GOVT HIGH SCHOOL, KEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23319",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, SANTHAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23505",
            "district": "14",
            "Block": "97",
            "label": "KOTHARI PUBLIC SCHOOL, SIVARAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23506",
            "district": "14",
            "Block": "158",
            "label": "THE INDIAN PUBLIC SCHOOL, KARUPPAYURANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23507",
            "district": "14",
            "Block": "158",
            "label": "MPF INTERNATIONAL SCHOOL, MAYILANGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23508",
            "district": "14",
            "Block": "161",
            "label": "VIKAASA JUBILEE SCHOOL, LOURDHU NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23509",
            "district": "14",
            "Block": "306",
            "label": "NAM VIDHYA MANDIR MATRICULATION SCHOOL, PAALPANNAI ROAD. T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23510",
            "district": "14",
            "Block": "324",
            "label": "GOVT.KALLAR GIRLS RESIDENTIAL SCHOOL., CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23511",
            "district": "14",
            "Block": "324",
            "label": "GOVT.KALLAR BOYS RESIDENTIAL SCHOOL., CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23512",
            "district": "14",
            "Block": "328",
            "label": "Government Model School, Madurai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23513",
            "district": "14",
            "Block": "328",
            "label": "MADURAI DISTRICT GOVERNMENT MODEL SCHOOL, THOPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23514",
            "district": "14",
            "Block": "328",
            "label": "MAHARISHI VIDYA MANDIR, THANAKKANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23515",
            "district": "14",
            "Block": "328",
            "label": "ST. ANNE'S MATRICULATION SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23516",
            "district": "14",
            "Block": "328",
            "label": "RIGHT WAY ISLAMIC MATRICULATION SCHOOL., AVANIYAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23517",
            "district": "14",
            "Block": "374",
            "label": "GREEN PARK VIDYALAYA NURSURY AND PRIMARY SCHOOL, J.J. NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23712",
            "district": "14",
            "Block": "4",
            "label": "PUMS KOTTAIMEDU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23713",
            "district": "14",
            "Block": "4",
            "label": "PUMS THANDALAI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23714",
            "district": "14",
            "Block": "4",
            "label": "PUMS MANICKAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24007",
            "district": "14",
            "Block": "328",
            "label": "THE AKSHAYA ACADEMY CAMPUS SCHOOL, THIRUPARAMKUNDRAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "15": [
        {
            "key": "2824",
            "district": "15",
            "Block": "293",
            "label": "PUMS MANIGRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        { 
            "key":"24117", "district":"15", 
            "Block":"284", "label": "sri vivekanandha nursary and primary school", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "3586",
            "district": "15",
            "Block": "284",
            "label": "PUMS MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4394",
            "district": "15",
            "Block": "131",
            "label": "PUMS NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4700",
            "district": "15",
            "Block": "151",
            "label": "THIRUVAVADUTHURAI ADHEENAM AIDED MS, THIRUVAVADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4727",
            "district": "15",
            "Block": "131",
            "label": "PUMS KEERANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4738",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   KONERIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4827",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   AASIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4840",
            "district": "15",
            "Block": "131",
            "label": "PUMS THIRUMAYILADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4842",
            "district": "15",
            "Block": "151",
            "label": "PUMS NACHINARGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4851",
            "district": "15",
            "Block": "151",
            "label": "PUMS KANCHIVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4887",
            "district": "15",
            "Block": "151",
            "label": "PUMS PORUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4895",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   KAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4916",
            "district": "15",
            "Block": "131",
            "label": "SRI  MEENAKSHI AMMAL AMS CHIDAMBARANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4938",
            "district": "15",
            "Block": "293",
            "label": "MMS OOZHIYAKARANTHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4968",
            "district": "15",
            "Block": "284",
            "label": "PUMS ALAVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4973",
            "district": "15",
            "Block": "151",
            "label": "PUMS PAZHAYAKUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4989",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   ALANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4993",
            "district": "15",
            "Block": "131",
            "label": "PUMS SANTHAPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4995",
            "district": "15",
            "Block": "151",
            "label": "VELAMMA KALASHETHRA  MATRICULATION SCHOOL  KUTHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5085",
            "district": "15",
            "Block": "151",
            "label": "PUMS KANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5092",
            "district": "15",
            "Block": "131",
            "label": "PUMS PALAIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5122",
            "district": "15",
            "Block": "151",
            "label": "PUMS KODIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5136",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL    ELANTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5160",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   VAZHUVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5161",
            "district": "15",
            "Block": "284",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL    KIDANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5184",
            "district": "15",
            "Block": "151",
            "label": "PUMS KOZHAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5198",
            "district": "15",
            "Block": "131",
            "label": "PUMS VEMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5207",
            "district": "15",
            "Block": "151",
            "label": "PUMS THIRUVALANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5223",
            "district": "15",
            "Block": "151",
            "label": "SRI SOLEESWARA MS, KUTHALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5232",
            "district": "15",
            "Block": "131",
            "label": "VASANTHALA AMS PANNAKKARANKOTTHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5269",
            "district": "15",
            "Block": "178",
            "label": "VIVEKANANDHA MATRIC SCHOOL MANALMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5287",
            "district": "15",
            "Block": "151",
            "label": "PUMS GANGATHARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5311",
            "district": "15",
            "Block": "151",
            "label": "PUMS KUTTALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5315",
            "district": "15",
            "Block": "151",
            "label": "PUMS KOKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5335",
            "district": "15",
            "Block": "178",
            "label": "TELC MIDDLE SCHOOL,MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5360",
            "district": "15",
            "Block": "131",
            "label": "GHSS THIRUMULLAIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5412",
            "district": "15",
            "Block": "293",
            "label": "PUMS KAVERIPOOMPATTINAM (FISHER)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5428",
            "district": "15",
            "Block": "284",
            "label": "SVS AMS ERUKKATANCHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5438",
            "district": "15",
            "Block": "178",
            "label": "GEETHA MATRICULATION SCHOOL CHENJI KALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5477",
            "district": "15",
            "Block": "131",
            "label": "PUMS MUTHALAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5557",
            "district": "15",
            "Block": "151",
            "label": "PUMS MEKKIRIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5582",
            "district": "15",
            "Block": "284",
            "label": "ST ANTONYS MS NATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5592",
            "district": "15",
            "Block": "178",
            "label": "PUMS KESINGAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5605",
            "district": "15",
            "Block": "178",
            "label": "PUMS VAKKARAMARY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5607",
            "district": "15",
            "Block": "178",
            "label": "PUMS NIDUR NEIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5609",
            "district": "15",
            "Block": "151",
            "label": "PUMS KILIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5624",
            "district": "15",
            "Block": "284",
            "label": "PUMS SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5628",
            "district": "15",
            "Block": "151",
            "label": "GANAPATHY NATIONAL AIDED MIDDLE SCHOOL, KUTHALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5674",
            "district": "15",
            "Block": "131",
            "label": "ARULMOZHI AMS THANDAVANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5693",
            "district": "15",
            "Block": "178",
            "label": "MOTHER TERESA MATRICULATION SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5720",
            "district": "15",
            "Block": "178",
            "label": "KALAIVANI MATRICULATION SCHOOL, KADALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5847",
            "district": "15",
            "Block": "293",
            "label": "TRMCT .MS. KANNIYAGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5890",
            "district": "15",
            "Block": "178",
            "label": "PUMS  MULAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5906",
            "district": "15",
            "Block": "178",
            "label": "AMS SERUTHIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5919",
            "district": "15",
            "Block": "131",
            "label": "SMS AMS SETHIRUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5921",
            "district": "15",
            "Block": "131",
            "label": "PUMS OTHAVANTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5939",
            "district": "15",
            "Block": "151",
            "label": "PUMS MANGAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6048",
            "district": "15",
            "Block": "178",
            "label": "VAITHEESWARA MATRICULATION HIGH SCHOOL  ATHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6056",
            "district": "15",
            "Block": "178",
            "label": "P.U.M.S SETHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6073",
            "district": "15",
            "Block": "178",
            "label": "AMS THIRUCHITRAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6122",
            "district": "15",
            "Block": "178",
            "label": "PUMS - MANAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6268",
            "district": "15",
            "Block": "131",
            "label": "MOUNT CARMEL MATRICULATION SCHOOL, ERUKKUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6291",
            "district": "15",
            "Block": "151",
            "label": "ST.MICHAELS R.C AIDED MIDDLE SCHOOL, PATTAVARTHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6317",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   MANNAMPANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6372",
            "district": "15",
            "Block": "131",
            "label": "PUMS KODIYAMPALAIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6433",
            "district": "15",
            "Block": "131",
            "label": "PUMS  KADAIKKAN VINAYAGA NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6482",
            "district": "15",
            "Block": "131",
            "label": "PUMS ERUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6500",
            "district": "15",
            "Block": "151",
            "label": "ADW MS THIRUKKULAMBIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6502",
            "district": "15",
            "Block": "131",
            "label": "PUMS ARPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6506",
            "district": "15",
            "Block": "178",
            "label": "KRISHNA AIDED MIDDLE SCHOOL,ARUVAPPADI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6518",
            "district": "15",
            "Block": "284",
            "label": "PUMS  KUMARAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6529",
            "district": "15",
            "Block": "178",
            "label": "PUMS PALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6542",
            "district": "15",
            "Block": "293",
            "label": "PUMS ALLIVILAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6551",
            "district": "15",
            "Block": "284",
            "label": "PUMS MARUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6626",
            "district": "15",
            "Block": "178",
            "label": "AMS SITHARKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6673",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL CHOZHAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15817",
            "district": "15",
            "Block": "178",
            "label": "UMA GIRLS HIGH SCHOOL THIRUCHITRAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15818",
            "district": "15",
            "Block": "178",
            "label": "GHS KADALANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15819",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL MANALMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15820",
            "district": "15",
            "Block": "178",
            "label": "PUMS- ELUPPAPATTU.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15821",
            "district": "15",
            "Block": "178",
            "label": "GHSS MANALMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15822",
            "district": "15",
            "Block": "178",
            "label": "GHSS (G) MANALMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15823",
            "district": "15",
            "Block": "178",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL MANALMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15824",
            "district": "15",
            "Block": "178",
            "label": "GHS ATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15825",
            "district": "15",
            "Block": "178",
            "label": "PUMS, KALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15826",
            "district": "15",
            "Block": "178",
            "label": "SRI KAMATCHI AMS KANNIYANATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15827",
            "district": "15",
            "Block": "178",
            "label": "GHSS KORUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15828",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15829",
            "district": "15",
            "Block": "178",
            "label": "GHS THIRUVALAPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15830",
            "district": "15",
            "Block": "178",
            "label": "GHS SITHAMALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15831",
            "district": "15",
            "Block": "178",
            "label": "AIDED MIDDLE SCHOOL, ELANTHOPE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15832",
            "district": "15",
            "Block": "178",
            "label": "GHS VILLIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15833",
            "district": "15",
            "Block": "178",
            "label": "GHS MELANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15834",
            "district": "15",
            "Block": "178",
            "label": "GOVT GIRLS HR SEC SCHOOL NIDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15835",
            "district": "15",
            "Block": "178",
            "label": "NASRUL MUSLIMEEN (MATRIC) HR SEC SCHOOL NIDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15836",
            "district": "15",
            "Block": "178",
            "label": "AL HAJEE MATRIC HIGH   SCHOOL NIDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15837",
            "district": "15",
            "Block": "178",
            "label": "GHS NIDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15838",
            "district": "15",
            "Block": "178",
            "label": "GHSS ANANTHATHANDAVAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15839",
            "district": "15",
            "Block": "178",
            "label": "SRI KALYANEESWARI AIDED MIDDLE SCHOOL MOZHAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15840",
            "district": "15",
            "Block": "178",
            "label": "PUMS THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15841",
            "district": "15",
            "Block": "178",
            "label": "GHSS THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15842",
            "district": "15",
            "Block": "178",
            "label": "RAJ MATRIC HR SEC SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15843",
            "district": "15",
            "Block": "178",
            "label": "Thiruarasen Middle School, Kazhukanimuttam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15844",
            "district": "15",
            "Block": "178",
            "label": "GHS MAPPADUGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15845",
            "district": "15",
            "Block": "178",
            "label": "AGRA MATRIC SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15846",
            "district": "15",
            "Block": "178",
            "label": "AIDED MIDDLE SCHOOL, MALLIYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15847",
            "district": "15",
            "Block": "178",
            "label": "MMRA GHS MOOVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15848",
            "district": "15",
            "Block": "178",
            "label": "MEKANA INTERNATIONAL MATRICULATION Hr.Sec MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15849",
            "district": "15",
            "Block": "178",
            "label": "EVEREST KENBRIDGE CBSE SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15850",
            "district": "15",
            "Block": "178",
            "label": "SRI GURU GNANASAMBANDAR HSS, DHARUMAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15851",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   MARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15852",
            "district": "15",
            "Block": "178",
            "label": "MUNICIPAL HIGH SCHOOL, THIRUINDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15853",
            "district": "15",
            "Block": "178",
            "label": "GURUMOORTHY MIDDLE SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15854",
            "district": "15",
            "Block": "178",
            "label": "SRI KANCHI SANKARA VIDYALAYA MATRICULATION  HR.SEC  SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15855",
            "district": "15",
            "Block": "178",
            "label": "TGN MPL HR SEC SCHOOL. MAYILADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15856",
            "district": "15",
            "Block": "178",
            "label": "GOVT G HSS MAYILADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15857",
            "district": "15",
            "Block": "178",
            "label": "SILVER JUBLEE MAT HS SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15858",
            "district": "15",
            "Block": "178",
            "label": "ARIVALAYAM MATRICULATION HIGHER SECONDARY SCHOOL SCHOOL, MYD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15859",
            "district": "15",
            "Block": "178",
            "label": "ROTARY CLUB MATRICULATION HIGHER SECONDARY SCHOOL MAYILADUTHUARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15860",
            "district": "15",
            "Block": "178",
            "label": "DBTR HSS MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15861",
            "district": "15",
            "Block": "178",
            "label": "AZAD GIRLS MAT HR SEC SCHOOL. MAYILADUTHUIRAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15862",
            "district": "15",
            "Block": "178",
            "label": "MAYURA MAT HIGH SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15863",
            "district": "15",
            "Block": "178",
            "label": "KITTAPPA MHSS MAYILADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15864",
            "district": "15",
            "Block": "178",
            "label": "ARC.KAMATCHI.MATRIC.HR.SEC.SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15865",
            "district": "15",
            "Block": "178",
            "label": "ST.PAUL'S GIRLS HIGHER SECONDARY SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15866",
            "district": "15",
            "Block": "178",
            "label": "ST ANTONYS HIGH SCHOOL, KORNAD",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15867",
            "district": "15",
            "Block": "178",
            "label": "RAJ MATRIC HR SEC  SCHOOL, KORANADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15868",
            "district": "15",
            "Block": "178",
            "label": "BALA SARASWATHI MAT SCHOOL, KORNAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15869",
            "district": "15",
            "Block": "178",
            "label": "GURUGNANASAMBANDAR MISSION MATRIC HIGHER SECONDARY SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15870",
            "district": "15",
            "Block": "178",
            "label": "ST XAVIOR HIGH SCHOOL. MAYILADUTHURAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15871",
            "district": "15",
            "Block": "178",
            "label": "MNA MS MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15872",
            "district": "15",
            "Block": "178",
            "label": "KALAIMAHAL MATRICULATION  HR SEC SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15873",
            "district": "15",
            "Block": "284",
            "label": "GHS,VAITHIYANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15874",
            "district": "15",
            "Block": "284",
            "label": "GHS,ERUMAL THOKKALAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15875",
            "district": "15",
            "Block": "284",
            "label": "AMS MELAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15876",
            "district": "15",
            "Block": "284",
            "label": "ROYAL MATRICULATION Hr. SEC. SCHOOL, SANKIRUPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15877",
            "district": "15",
            "Block": "284",
            "label": "ALAGU JOTHI ACADAMY  MELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15878",
            "district": "15",
            "Block": "284",
            "label": "AMS PONSAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15879",
            "district": "15",
            "Block": "284",
            "label": "ANNAI KASTHURIBA AIDED HIGH SCHOOL, NATARAJAPILLAI CHAVADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15880",
            "district": "15",
            "Block": "284",
            "label": "PUMS THALACHANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15881",
            "district": "15",
            "Block": "284",
            "label": "PUMS KURANGUPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15882",
            "district": "15",
            "Block": "284",
            "label": "TELC MS KARAIMEDU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "15883",
            "district": "15",
            "Block": "284",
            "label": "GHSS,KEELAPERUMPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15884",
            "district": "15",
            "Block": "284",
            "label": "GHS,ARUPATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15885",
            "district": "15",
            "Block": "284",
            "label": "PUMS MARUTHAMPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15886",
            "district": "15",
            "Block": "284",
            "label": "GHS CHINNANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15887",
            "district": "15",
            "Block": "284",
            "label": "PUMS AKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15888",
            "district": "15",
            "Block": "284",
            "label": "GGHS, AKKUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15889",
            "district": "15",
            "Block": "284",
            "label": "GHSS, AKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15890",
            "district": "15",
            "Block": "284",
            "label": "ORIENTAL ARABIC AIDED HR SEC SCHOOL, AKKUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15891",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRICULATION HIGHER SECONDARY SCHOOL, AKKUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15892",
            "district": "15",
            "Block": "284",
            "label": "GGHSS,SEMBANARKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15893",
            "district": "15",
            "Block": "284",
            "label": "NAPAC MATRICULATION HSS,SEMBANARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15894",
            "district": "15",
            "Block": "284",
            "label": "AMS PARASALUR SEMBANARKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15895",
            "district": "15",
            "Block": "284",
            "label": "SAMBANDAM AIDED HIGHER SECONDARY SCHOOL, SEMBANARKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15896",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRICULATION HIGHER SECONDARY SCHOOL, SEMBANARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15897",
            "district": "15",
            "Block": "284",
            "label": "HAJA SARAMMAL MATRICULATION HSS, VADAGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15898",
            "district": "15",
            "Block": "284",
            "label": "GHS,VADAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15899",
            "district": "15",
            "Block": "284",
            "label": "GMS KEEZHMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15900",
            "district": "15",
            "Block": "284",
            "label": "GHSS,THIRUKKADAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15901",
            "district": "15",
            "Block": "284",
            "label": "PUMS THIRUKKADAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15902",
            "district": "15",
            "Block": "284",
            "label": "SRI GURUGNANA SAMBANDAR MISSION MATRICULATION HIGH SCHOOL, THIRUKKADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15903",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRICULATION HR SEC SCHOOL,THIRUKKADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15904",
            "district": "15",
            "Block": "284",
            "label": "PUMS VEPPANCHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15905",
            "district": "15",
            "Block": "284",
            "label": "SRI SRI VIDYA MANDIR MATRICULATION SCHOOL, ANAIKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15906",
            "district": "15",
            "Block": "284",
            "label": "PUMS ELUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15907",
            "district": "15",
            "Block": "284",
            "label": "PUMS HARIHRANKOODAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15908",
            "district": "15",
            "Block": "284",
            "label": "GHSS,SANKARANPANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15909",
            "district": "15",
            "Block": "284",
            "label": "AL-AMAN(M) MHHS, SANKARANPANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15910",
            "district": "15",
            "Block": "284",
            "label": "HAMEEDIYA AIDED HR SEC SCHOOL, THIRUKKALACHERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15911",
            "district": "15",
            "Block": "284",
            "label": "AIYAS(M)HS, THIRUKKALACHERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15912",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRICULATION  HR.SEC SCHOOL AYAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15913",
            "district": "15",
            "Block": "284",
            "label": "PUMS THIRUVIDAIKAZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15914",
            "district": "15",
            "Block": "284",
            "label": "VALLIAMMAI GHSS, THILLAIYADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15915",
            "district": "15",
            "Block": "284",
            "label": "ANDAVAR AMS PORAYAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15916",
            "district": "15",
            "Block": "284",
            "label": "TELC  THALTHAKUMI GIRLS HIGH SCHOOL, PORAYAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15917",
            "district": "15",
            "Block": "284",
            "label": "TELC BJM BOYS AIDED HIGHER SECONDARY SCHOOL,THARANGAMPADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15918",
            "district": "15",
            "Block": "284",
            "label": "ST THERESA'S AIDED GIRLS HIGHER SECONDARY SCHOOL, THARANGAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15919",
            "district": "15",
            "Block": "284",
            "label": "THAVASU MUTHU NADAR AIDED HR SEC SCHOOL, PORAYAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15920",
            "district": "15",
            "Block": "284",
            "label": "SHARMILA GODDESS (M)HSS, PORAYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15921",
            "district": "15",
            "Block": "284",
            "label": "NIVEDHA MATRICULATION HIGHER SECONDARY SCHOOL, PORAYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15922",
            "district": "15",
            "Block": "284",
            "label": "HOPE FOUNDATION MATRICULATION HIGH SCHOOL, THARANGAMPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15923",
            "district": "15",
            "Block": "284",
            "label": "GHS,CHANDIRAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15924",
            "district": "15",
            "Block": "284",
            "label": "PUMS NARASINGANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15925",
            "district": "15",
            "Block": "284",
            "label": "SOWRIRAJAN  HIGH SCHOOL, THIRUVILAIYATTAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15926",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRIC SCHOOL THIRUVILAIYATTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15927",
            "district": "15",
            "Block": "284",
            "label": "PUMS KARUGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15928",
            "district": "15",
            "Block": "284",
            "label": "GHS,NALLADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15929",
            "district": "15",
            "Block": "151",
            "label": "GOVT HIGH SCHOOL, AGARATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15930",
            "district": "15",
            "Block": "151",
            "label": "GHSS KILIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15931",
            "district": "15",
            "Block": "151",
            "label": "AL HITHANA MATRICULATION HIGH SCHOOL KILIYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15932",
            "district": "15",
            "Block": "151",
            "label": "GHS PERAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15933",
            "district": "15",
            "Block": "151",
            "label": "GHS VANATHIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15934",
            "district": "15",
            "Block": "151",
            "label": "PUMS KADALANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15935",
            "district": "15",
            "Block": "151",
            "label": "RAJ VIDHYALAYA MAT HR SEC. SCHOOL, KUTHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15936",
            "district": "15",
            "Block": "151",
            "label": "NEW STAR MATRICULATION SCHOOL, KUTHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15937",
            "district": "15",
            "Block": "151",
            "label": "GGHSS,KUTHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15938",
            "district": "15",
            "Block": "151",
            "label": "ST MARY'S MATRICULATION HIGH SCHOOL KUTTALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15939",
            "district": "15",
            "Block": "151",
            "label": "GHSS,KUTHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15940",
            "district": "15",
            "Block": "151",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,THIRUVAVADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15941",
            "district": "15",
            "Block": "151",
            "label": "NISHA MAT HIGHER SECONDARY SCHOOL, THERIZHADUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15942",
            "district": "15",
            "Block": "151",
            "label": "KAMBAR GHSS THERIZHANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15943",
            "district": "15",
            "Block": "151",
            "label": "JAIHIND MATRICULATION HIGHER SECONDARY  SCHOOL , THERIZHANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15944",
            "district": "15",
            "Block": "151",
            "label": "ANNAI VELANKANNI MATHA HSS PERAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15945",
            "district": "15",
            "Block": "151",
            "label": "Dr.G.S.KALYANASUNDARAM MATRIC HSS, PAZHAYAKUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15946",
            "district": "15",
            "Block": "151",
            "label": "GHSS KOMAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15947",
            "district": "15",
            "Block": "151",
            "label": "SRI RAMAKRISHNA MATRICULATION HR.SEC.SCHOOL, SIVANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15948",
            "district": "15",
            "Block": "151",
            "label": "GOOD LUCK MATRICULATION HR.SEC SCHOOL, SRIKANDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15949",
            "district": "15",
            "Block": "151",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SRIKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15950",
            "district": "15",
            "Block": "151",
            "label": "GOVT. HIGHER SECONDARY SCHOOL,VEZHAMURITHANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15951",
            "district": "15",
            "Block": "151",
            "label": "KSO HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15952",
            "district": "15",
            "Block": "293",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   THITTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15953",
            "district": "15",
            "Block": "293",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   SATTANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15954",
            "district": "15",
            "Block": "293",
            "label": "DAGGSM  VTP MS .THENPATHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15955",
            "district": "15",
            "Block": "293",
            "label": "BEST MATRICULATION HR SEC SCHOOL SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15956",
            "district": "15",
            "Block": "293",
            "label": "VIVEKANANDA MATRIC. HSS SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15957",
            "district": "15",
            "Block": "293",
            "label": "GOOD SAMARITAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15958",
            "district": "15",
            "Block": "293",
            "label": "MPL HSS SIRKALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15959",
            "district": "15",
            "Block": "293",
            "label": "SHYAMALA GIRLS AIDED HR SEC SCHOOL SIRKALI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15960",
            "district": "15",
            "Block": "293",
            "label": "LMC AIDED HR SEC SCHOOL SIRKALI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15961",
            "district": "15",
            "Block": "293",
            "label": "S M HINDU AIDED HR SEC SCHOOL SIRKALI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15962",
            "district": "15",
            "Block": "293",
            "label": "ST JOSEPH MATRICULATION  HR SEC SCHOOL, P.K. ROAD, SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15963",
            "district": "15",
            "Block": "293",
            "label": "SMH MS SOUTH ST SIRKAZHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15964",
            "district": "15",
            "Block": "293",
            "label": "MUNICIPAL MIDDLE SCHOOL PANAKATTANKUDISALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15965",
            "district": "15",
            "Block": "293",
            "label": "S M HINDU MATRICULATION HIGH SCHOOL, SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15966",
            "district": "15",
            "Block": "293",
            "label": "EZHILMALAR MHS SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15967",
            "district": "15",
            "Block": "293",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   SENTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15968",
            "district": "15",
            "Block": "293",
            "label": "KAM AIDED HIGH SCHOOL KOVILPATHU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15969",
            "district": "15",
            "Block": "293",
            "label": "GHS AGANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15970",
            "district": "15",
            "Block": "293",
            "label": "PUMS VALLUVAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15971",
            "district": "15",
            "Block": "293",
            "label": "ADW GHSS KONDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15972",
            "district": "15",
            "Block": "293",
            "label": "PUMS PUNGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15973",
            "district": "15",
            "Block": "293",
            "label": "GHSS VAITHEESWARANKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15974",
            "district": "15",
            "Block": "293",
            "label": "G(G)HSS VAITHEESWARANKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15975",
            "district": "15",
            "Block": "293",
            "label": "SRI GURUGNANASAMBANDAR MISSION - SRI MUTHAIAH MATRIC. HR. SEC. SCHOOL, VAITHEESWARANKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15976",
            "district": "15",
            "Block": "293",
            "label": "SRI MUTHURAJAM MATRICULATION SCHOOL KARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15977",
            "district": "15",
            "Block": "293",
            "label": "ADW GHSS KARAIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15978",
            "district": "15",
            "Block": "293",
            "label": "GHS MELACHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15979",
            "district": "15",
            "Block": "293",
            "label": "ANNAI SARATHA VIDHYA MATRIC  SCHOOL, KARAIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15980",
            "district": "15",
            "Block": "293",
            "label": "PUMS KATHIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15981",
            "district": "15",
            "Block": "293",
            "label": "GHSS NANGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15982",
            "district": "15",
            "Block": "293",
            "label": "GHS THIRUVALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15983",
            "district": "15",
            "Block": "293",
            "label": "GHS THIRUNAGARI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15984",
            "district": "15",
            "Block": "293",
            "label": "PUMS ANNAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15985",
            "district": "15",
            "Block": "293",
            "label": "GHSS KONAYAMPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15986",
            "district": "15",
            "Block": "293",
            "label": "GHS AGARAPERUNTHOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15987",
            "district": "15",
            "Block": "293",
            "label": "PUMS NIMELI NEPPATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15988",
            "district": "15",
            "Block": "293",
            "label": "SSD BOYS AIDED HIGER SECONDARY SCHOOL, THIRUVENGADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15989",
            "district": "15",
            "Block": "293",
            "label": "SSD GIRLS HIGH SCHOOL, THIRUVENGADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15990",
            "district": "15",
            "Block": "293",
            "label": "HOLY CROSS MATRIC SCHOOL THIRUVENGADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15991",
            "district": "15",
            "Block": "293",
            "label": "PUMS MANGAIMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15992",
            "district": "15",
            "Block": "293",
            "label": "GHS KAVERIPOOMPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15993",
            "district": "15",
            "Block": "293",
            "label": "SRINIVASA AIDED HSS MELAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15994",
            "district": "15",
            "Block": "293",
            "label": "GHS VANAGIRIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15995",
            "district": "15",
            "Block": "131",
            "label": "SRI RAMAKIRUBA AMS KADAVASAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15996",
            "district": "15",
            "Block": "131",
            "label": "RAHMANIYA HIGH SCHOOL, VADAKAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15997",
            "district": "15",
            "Block": "131",
            "label": "PUMS VETTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15998",
            "district": "15",
            "Block": "131",
            "label": "PUMS KOOZHAIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15999",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, THODUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16000",
            "district": "15",
            "Block": "131",
            "label": "VICTORIAL  MATRICULATION HIGHER SECONDARY SCHOOL, THIRUMULLAIVASAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16001",
            "district": "15",
            "Block": "131",
            "label": "PUMS RADHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16002",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, EDAMANAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16003",
            "district": "15",
            "Block": "131",
            "label": "SIVANANDHA AMS MADHANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16004",
            "district": "15",
            "Block": "131",
            "label": "GOVT ADW HIGHER SECONDARY  SCHOOL MATHANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16005",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ACHALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16006",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, THITTUPADUGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16007",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PUDUPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16008",
            "district": "15",
            "Block": "131",
            "label": "RAMESH MATRICULATION HIGH SCHOOL, PUDUPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16009",
            "district": "15",
            "Block": "131",
            "label": "RAMAKRISHNA AIDED HR SEC SCHOOL, KOTHANDAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16010",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, KODAKKARAMOOLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16011",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, THANDAVANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16012",
            "district": "15",
            "Block": "131",
            "label": "MUTHUSAMI VISWANATHAN AIDED HIGH SCHOOL, MATHIRAVELUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16013",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, VADARANGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16014",
            "district": "15",
            "Block": "131",
            "label": "GMS PANANGATTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16015",
            "district": "15",
            "Block": "131",
            "label": "PUMS OLAIYAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16016",
            "district": "15",
            "Block": "131",
            "label": "PUMS ANANTHAKOOTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16017",
            "district": "15",
            "Block": "131",
            "label": "BHARATH MATRICULATION Hr.SEC. SCHOOL, PUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16018",
            "district": "15",
            "Block": "131",
            "label": "SRINIVASA MATRICULATION  HR.SEC SCHOOL, KOLLIDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16019",
            "district": "15",
            "Block": "131",
            "label": "PUMS PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16020",
            "district": "15",
            "Block": "131",
            "label": "MEENATCHI VIDYALAYA MATRICULATION HSS SCHOOL,   PUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16021",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, MANALAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16022",
            "district": "15",
            "Block": "131",
            "label": "GHSS THULASENTHIRAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16023",
            "district": "15",
            "Block": "131",
            "label": "VENKATESA AIDED HR SEC SCHOOL AYANKUDIPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16024",
            "district": "15",
            "Block": "131",
            "label": "SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL, KOLLIDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20905",
            "district": "15",
            "Block": "178",
            "label": "GOOD SAMARITAN MATRICULATION HIGHER SECONDARY SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20974",
            "district": "15",
            "Block": "178",
            "label": "IDEAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21131",
            "district": "15",
            "Block": "178",
            "label": "PUMS PULAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21132",
            "district": "15",
            "Block": "131",
            "label": "PUMS PERAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21393",
            "district": "15",
            "Block": "284",
            "label": "GHS PERUMALPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21524",
            "district": "15",
            "Block": "284",
            "label": "THIRUVENKADAM AIDED HIGH SCHOOL KEEZHAIYUR, SEMBANARKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21607",
            "district": "15",
            "Block": "284",
            "label": "ABDUL KALAM MATRICULATION SCHOOL,E.SATHANOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21950",
            "district": "15",
            "Block": "284",
            "label": "THAMARAI MATRIC HR.SEC. SCHOOL, SEMBANARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22217",
            "district": "15",
            "Block": "178",
            "label": "KAMALALAYAM N&P,PUTHAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22218",
            "district": "15",
            "Block": "284",
            "label": "GOVT HIGH SCHOOL NALLUCHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22219",
            "district": "15",
            "Block": "151",
            "label": "GOVT HIGH SCHOOL THIRUMANANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22220",
            "district": "15",
            "Block": "293",
            "label": "LIONS CLUB MATRICULATION SCHOOL VILANTHIDASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22566",
            "district": "15",
            "Block": "178",
            "label": "GOVERNMENT HIGH SCHOOL KEEZHATHUKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22567",
            "district": "15",
            "Block": "178",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Pallavarayanpettai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22568",
            "district": "15",
            "Block": "178",
            "label": "GOLDEN PUBLIC CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22569",
            "district": "15",
            "Block": "151",
            "label": "GOVERNMENT HIGH SCHOOL, THERIZHANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22699",
            "district": "15",
            "Block": "293",
            "label": "SUBHAM VIDYA MANDIR SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22700",
            "district": "15",
            "Block": "293",
            "label": "SHRI NATARAJAN MEMORIAL PUBLIC SCHOOL KARAIMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22775",
            "district": "15",
            "Block": "178",
            "label": "VEETA VARSITY CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22776",
            "district": "15",
            "Block": "178",
            "label": "ARK GLOBAL  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22865",
            "district": "15",
            "Block": "178",
            "label": "GOVERNMENT HIGH SCHOOL THALANCHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23038",
            "district": "15",
            "Block": "151",
            "label": "RAJ VIDYALAYA INTERNATIONAL SCHOOL,KUTHALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23199",
            "district": "15",
            "Block": "284",
            "label": "SREE KRISHNA MATRICULATION HS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23830",
            "district": "15",
            "Block": "131",
            "label": "PUMS PUTHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23876",
            "district": "15",
            "Block": "178",
            "label": "GHSS KORUKKAI",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23877",
            "district": "15",
            "Block": "178",
            "label": "MAYILADUTHURAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23958",
            "district": "15",
            "Block": "284",
            "label": "PUMS MATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23959",
            "district": "15",
            "Block": "284",
            "label": "GHS PERUMALPETTAI",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23966",
            "district": "15",
            "Block": "293",
            "label": "PUMS PUNGANUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "16": [
        {
            "key": "4702",
            "district": "16",
            "Block": "325",
            "label": "PUMS-THIRUPPAYATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4706",
            "district": "16",
            "Block": "325",
            "label": "PUMS-KANGALANCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4779",
            "district": "16",
            "Block": "123",
            "label": "PUMS RADHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4790",
            "district": "16",
            "Block": "389",
            "label": "ELAKKUVANAR AMS,VOIMEDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4868",
            "district": "16",
            "Block": "308",
            "label": "PUMS THALAINAYAR AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4894",
            "district": "16",
            "Block": "325",
            "label": "PUMS-VADAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4905",
            "district": "16",
            "Block": "389",
            "label": "AMS-PUSHPAVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4992",
            "district": "16",
            "Block": "123",
            "label": "PUMS KERALANTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5014",
            "district": "16",
            "Block": "123",
            "label": "PUMS VADAKKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5019",
            "district": "16",
            "Block": "389",
            "label": "RV SHARMA AMS, THETHAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5043",
            "district": "16",
            "Block": "389",
            "label": "NAKKEERANAR AMS,PANCHANATHIKULAM WEST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5075",
            "district": "16",
            "Block": "308",
            "label": "PUMS THIRUVIDAIMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5096",
            "district": "16",
            "Block": "389",
            "label": "SRI. THAYUMANAVAR VIDYALAYA AMS VEDARANYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5108",
            "district": "16",
            "Block": "389",
            "label": "PUMS, PERIYAKUTHAKAI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5115",
            "district": "16",
            "Block": "389",
            "label": "PUMS,PICHAKATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5150",
            "district": "16",
            "Block": "389",
            "label": "PUMS KURAVAPULAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5158",
            "district": "16",
            "Block": "389",
            "label": "PUMS, CHETTITHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5193",
            "district": "16",
            "Block": "389",
            "label": "PUMS SEMBAGARAYANALLUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5200",
            "district": "16",
            "Block": "308",
            "label": "PUMS VADUGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5264",
            "district": "16",
            "Block": "196",
            "label": "PUMS KEECHANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5288",
            "district": "16",
            "Block": "123",
            "label": "GHSS KURUKKATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5291",
            "district": "16",
            "Block": "325",
            "label": "SRI NADESAIYHA GOVT AMS SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5292",
            "district": "16",
            "Block": "123",
            "label": "PUMS NAGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5325",
            "district": "16",
            "Block": "123",
            "label": "PUMS KURUMANANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5370",
            "district": "16",
            "Block": "389",
            "label": "AMS,KATHARIPULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5389",
            "district": "16",
            "Block": "123",
            "label": "PUMS ATHIPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5406",
            "district": "16",
            "Block": "389",
            "label": "PUMS,THENNADAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5430",
            "district": "16",
            "Block": "389",
            "label": "PUMS THANIKOTTAGAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5458",
            "district": "16",
            "Block": "389",
            "label": "PUMS,VOIMEDU WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5487",
            "district": "16",
            "Block": "389",
            "label": "PUMS,THOPPUTHURAI(MUSILIM)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5488",
            "district": "16",
            "Block": "389",
            "label": "PUMS,PERIYAKUTHAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5499",
            "district": "16",
            "Block": "325",
            "label": "SMT CRESCENT MATRICULATION SCHOOL AUDALAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5508",
            "district": "16",
            "Block": "308",
            "label": "PUMS ARUNTHAVAMPULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5510",
            "district": "16",
            "Block": "123",
            "label": "PUMS VADAKKUPANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5514",
            "district": "16",
            "Block": "123",
            "label": "PUMS KADAMBANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5543",
            "district": "16",
            "Block": "389",
            "label": "PUMS,MOOLAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5546",
            "district": "16",
            "Block": "389",
            "label": "PUMS,PATTINATHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5559",
            "district": "16",
            "Block": "308",
            "label": "PUMS MARACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5568",
            "district": "16",
            "Block": "389",
            "label": "THENI AMS,RAJAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5578",
            "district": "16",
            "Block": "389",
            "label": "PUMS THIRD STREET, VEDARANIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5614",
            "district": "16",
            "Block": "196",
            "label": "MMS KADAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5648",
            "district": "16",
            "Block": "389",
            "label": "PUMS, POOSARIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5716",
            "district": "16",
            "Block": "123",
            "label": "PUMS MOHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5723",
            "district": "16",
            "Block": "123",
            "label": "PUMS IRUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5734",
            "district": "16",
            "Block": "325",
            "label": "PUMS PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5769",
            "district": "16",
            "Block": "325",
            "label": "PUMS KOTTARAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5773",
            "district": "16",
            "Block": "123",
            "label": "PUMS THIRUKKANNANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5785",
            "district": "16",
            "Block": "389",
            "label": "RAMAMADAM AMS-PERIYAKUTHAGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5787",
            "district": "16",
            "Block": "123",
            "label": "PUMS KOHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5798",
            "district": "16",
            "Block": "308",
            "label": "PUMS KOLAPPADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5799",
            "district": "16",
            "Block": "389",
            "label": "PUMS, VELLIKIDANKU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5801",
            "district": "16",
            "Block": "325",
            "label": "PUMS-THIRUCHENKATTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5808",
            "district": "16",
            "Block": "123",
            "label": "PUMS THEVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5880",
            "district": "16",
            "Block": "325",
            "label": "PUMS PAKKAMKOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5881",
            "district": "16",
            "Block": "389",
            "label": "PUMS-THIYAGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5888",
            "district": "16",
            "Block": "389",
            "label": "PUMS,NEIVILAKKU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5905",
            "district": "16",
            "Block": "389",
            "label": "ANANTHARASU AMS, MARAIGNAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5907",
            "district": "16",
            "Block": "123",
            "label": "PUMS VALLAVINAYAGAN KOTTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5927",
            "district": "16",
            "Block": "123",
            "label": "PUMS KUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5950",
            "district": "16",
            "Block": "325",
            "label": "GMS VAZHMANGALAM,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6003",
            "district": "16",
            "Block": "389",
            "label": "PUMS, AYAKKARANPULAM 2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6085",
            "district": "16",
            "Block": "389",
            "label": "SUBBAIYA AMS, PAYATHAVARANKADU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6093",
            "district": "16",
            "Block": "389",
            "label": "PUMS, SINGANKUTTHAKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6108",
            "district": "16",
            "Block": "389",
            "label": "PUMS CHETTIYAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6119",
            "district": "16",
            "Block": "389",
            "label": "PUMS GOVIDANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6148",
            "district": "16",
            "Block": "389",
            "label": "SEETHALAKSHMI AMS, ARAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6156",
            "district": "16",
            "Block": "308",
            "label": "PUMS SANTHANAMTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6208",
            "district": "16",
            "Block": "123",
            "label": "PUMS VANDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6238",
            "district": "16",
            "Block": "389",
            "label": "PUMS, KAILAVANAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6292",
            "district": "16",
            "Block": "325",
            "label": "PUMS ATHALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6307",
            "district": "16",
            "Block": "325",
            "label": "PUMS - PANDARAVADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6332",
            "district": "16",
            "Block": "121",
            "label": "ANNAI VAILANKANNI MATRICULATION HIGH SCHOOL,VETTAIKARANIRUPPU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6508",
            "district": "16",
            "Block": "325",
            "label": "PUMS KUTHALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6545",
            "district": "16",
            "Block": "389",
            "label": "GANESA AMS, CHETTIPULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6717",
            "district": "16",
            "Block": "325",
            "label": "PUMS VOWALADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15654",
            "district": "16",
            "Block": "196",
            "label": "GHSS, AZHIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15655",
            "district": "16",
            "Block": "196",
            "label": "GHS ELANKADAMBANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15656",
            "district": "16",
            "Block": "196",
            "label": "GHSS MUTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15657",
            "district": "16",
            "Block": "196",
            "label": "PUMS SANGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15658",
            "district": "16",
            "Block": "196",
            "label": "PUMS,PORAVACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15659",
            "district": "16",
            "Block": "196",
            "label": "PUMS ANTHANAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15660",
            "district": "16",
            "Block": "196",
            "label": "KUMARAN AMS MANJAKOLLAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15661",
            "district": "16",
            "Block": "196",
            "label": "GHSS SIKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15662",
            "district": "16",
            "Block": "196",
            "label": "GHS VADAKKUPOIGAINALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15663",
            "district": "16",
            "Block": "196",
            "label": "GHSS AKKARAIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15664",
            "district": "16",
            "Block": "196",
            "label": "GHS PAPPAKOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15665",
            "district": "16",
            "Block": "196",
            "label": "GHS PUTHUCHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15666",
            "district": "16",
            "Block": "196",
            "label": "PUMS VERGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15667",
            "district": "16",
            "Block": "196",
            "label": "CHIDAMBARANAR AMS ORATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15668",
            "district": "16",
            "Block": "196",
            "label": "PUMS THERKKU POIGAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15669",
            "district": "16",
            "Block": "196",
            "label": "AMS ST Antonys Therkku Poigainallur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15670",
            "district": "16",
            "Block": "196",
            "label": "GHS,VADAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15671",
            "district": "16",
            "Block": "196",
            "label": "PUMS KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15672",
            "district": "16",
            "Block": "196",
            "label": "GHS KURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15673",
            "district": "16",
            "Block": "196",
            "label": "PUMS VADUGACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15674",
            "district": "16",
            "Block": "196",
            "label": "GHS, SEMBIYANMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15675",
            "district": "16",
            "Block": "196",
            "label": "NATIONAL AIDED HIGHER SECONDARY SCHOOL NAGORE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15676",
            "district": "16",
            "Block": "196",
            "label": "MMS MIYAH STREET NAGORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15677",
            "district": "16",
            "Block": "196",
            "label": "MMS PATTINACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15678",
            "district": "16",
            "Block": "196",
            "label": "GHOUTHIA AIDED HIGHER SECONDARY SCHOOL NAGORE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15679",
            "district": "16",
            "Block": "196",
            "label": "Nagore Crescent Matriculation Higher Secondary School for Girls",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15680",
            "district": "16",
            "Block": "196",
            "label": "CHINMAYA VIDYALAYA HSS CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15681",
            "district": "16",
            "Block": "196",
            "label": "MMS NAMBIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15682",
            "district": "16",
            "Block": "196",
            "label": "NAGORE MODERN MATRICULATION HIGHER SECONDARY SCHOOL, NAGORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15683",
            "district": "16",
            "Block": "196",
            "label": "GHS NAGORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15684",
            "district": "16",
            "Block": "196",
            "label": "MMS NELLUKKADAI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15685",
            "district": "16",
            "Block": "196",
            "label": "SRIMATHY PADMAVATHI MATRIC HSS VELIPPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15686",
            "district": "16",
            "Block": "196",
            "label": "NATARAJAN DHAMAYANTHI AIDED HIGHER SECONDARY SCHOOL VELIPPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15687",
            "district": "16",
            "Block": "196",
            "label": "J.JEYA MATRICULATION HIGHER SECONDARY SCHOOL COOKS ROAD VELIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15688",
            "district": "16",
            "Block": "196",
            "label": "Aided Nadarajan Damayanthi High School, Nagapattinam.",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15689",
            "district": "16",
            "Block": "196",
            "label": "ST. ANTONYS AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15690",
            "district": "16",
            "Block": "196",
            "label": "AMS.ST.ANTONYS LOURDU, NAGAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15691",
            "district": "16",
            "Block": "196",
            "label": "NATIONAL AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15692",
            "district": "16",
            "Block": "196",
            "label": "NEHRU MATRICULATION HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15693",
            "district": "16",
            "Block": "196",
            "label": "MGHSS NAGAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15694",
            "district": "16",
            "Block": "196",
            "label": "GSPJ ADHARSH MATRICULATION HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15695",
            "district": "16",
            "Block": "196",
            "label": "CSI AIDED HIGH SCHOOL NAGAPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15696",
            "district": "16",
            "Block": "196",
            "label": "CSI AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15697",
            "district": "16",
            "Block": "196",
            "label": "MMS KALLUKARA STREET.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15698",
            "district": "16",
            "Block": "196",
            "label": "AMRITA VIDYALAYAM SENIOR SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15699",
            "district": "16",
            "Block": "196",
            "label": "AJC PUBLIC SCHOOL CBSE NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15700",
            "district": "16",
            "Block": "196",
            "label": "ST. MICHAEL'S AKADEMY KARUVELANKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15701",
            "district": "16",
            "Block": "325",
            "label": "GHS GANAPATHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15702",
            "district": "16",
            "Block": "325",
            "label": "GHSS THITTACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15703",
            "district": "16",
            "Block": "325",
            "label": "GHS THITTACHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15704",
            "district": "16",
            "Block": "325",
            "label": "GHSS PURAGRAMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15705",
            "district": "16",
            "Block": "325",
            "label": "GHSS THIRUMARUGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15706",
            "district": "16",
            "Block": "325",
            "label": "GHS KEEZHAPOLAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15707",
            "district": "16",
            "Block": "325",
            "label": "GHS MARUNGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15708",
            "district": "16",
            "Block": "325",
            "label": "AL-AMAN CRESCENT MATRIC HR SEC SCHOOL ATHALAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15709",
            "district": "16",
            "Block": "325",
            "label": "AKR MATRIC HR.SEC.SCHOOL ENANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15710",
            "district": "16",
            "Block": "325",
            "label": "GHSS ENANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15711",
            "district": "16",
            "Block": "325",
            "label": "GHSS THIRUKKANNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15712",
            "district": "16",
            "Block": "325",
            "label": "GHSS AMBAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15713",
            "district": "16",
            "Block": "325",
            "label": "PUMS PORAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15714",
            "district": "16",
            "Block": "325",
            "label": "ST.JOSEPH AMS SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15715",
            "district": "16",
            "Block": "325",
            "label": "GHS VAZHGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15716",
            "district": "16",
            "Block": "325",
            "label": "ST.ANNES AMS SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15717",
            "district": "16",
            "Block": "325",
            "label": "GHS NARIMANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15718",
            "district": "16",
            "Block": "123",
            "label": "GHS VENKIDANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15719",
            "district": "16",
            "Block": "123",
            "label": "GHSS KILVELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15720",
            "district": "16",
            "Block": "123",
            "label": "THIRU ANJUVATTATHAMMAN GIRLS AIDED HIGH SCHOOL, KILVELUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15721",
            "district": "16",
            "Block": "123",
            "label": "GHS KILVELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15722",
            "district": "16",
            "Block": "123",
            "label": "GHSS THEVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15723",
            "district": "16",
            "Block": "123",
            "label": "INFANT JESUS MATRICULATION HIGH SCHOOL THEVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15724",
            "district": "16",
            "Block": "123",
            "label": "GHS KILLUKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15725",
            "district": "16",
            "Block": "123",
            "label": "GHSS ANTHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15726",
            "district": "16",
            "Block": "123",
            "label": "GHS SATTIYAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15727",
            "district": "16",
            "Block": "123",
            "label": "GHS ATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15728",
            "district": "16",
            "Block": "123",
            "label": "VALIVALAM DESIKAR  AIDED HIGHER SECONDARY SCHOOL VALIVALM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15729",
            "district": "16",
            "Block": "121",
            "label": "AMS-P.R.PURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15730",
            "district": "16",
            "Block": "121",
            "label": "GHSS-P.R.PURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15731",
            "district": "16",
            "Block": "121",
            "label": "GHS SERUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15732",
            "district": "16",
            "Block": "121",
            "label": "GHSS THIRUPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15733",
            "district": "16",
            "Block": "121",
            "label": "PUMS KARAIMETTUTHIDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15734",
            "district": "16",
            "Block": "121",
            "label": "PUNITHA FATHIMA MATRICULATION SCHOOL THIRUPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15735",
            "district": "16",
            "Block": "121",
            "label": "ST SEBASTIANS HR SEC SCHOOL KAMESWARAM  EAST",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15736",
            "district": "16",
            "Block": "121",
            "label": "PUMS VILUNTHAMAVADI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15737",
            "district": "16",
            "Block": "121",
            "label": "PUMS VIZHUNTHAMAVADI (T)EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15738",
            "district": "16",
            "Block": "121",
            "label": "GHSS-VILUNTHAMAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15739",
            "district": "16",
            "Block": "121",
            "label": "GHS KARAPPIDAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15740",
            "district": "16",
            "Block": "121",
            "label": "GHSS KEEZHAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15741",
            "district": "16",
            "Block": "121",
            "label": "PUMS VENMANACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15742",
            "district": "16",
            "Block": "121",
            "label": "PUMS THATHANTHIRUVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15743",
            "district": "16",
            "Block": "121",
            "label": "GHSS VETTAIKKARANIRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15744",
            "district": "16",
            "Block": "121",
            "label": "AMS-PUDUPALLI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15745",
            "district": "16",
            "Block": "121",
            "label": "GHSS PALAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15746",
            "district": "16",
            "Block": "121",
            "label": "AMS-THANNILAPPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15747",
            "district": "16",
            "Block": "121",
            "label": "GHS GRAMATHUMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15748",
            "district": "16",
            "Block": "121",
            "label": "OUR LADY OF HEALTH AIDED HIGHER SECONDARY SCHOOL, VELANKANNI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15749",
            "district": "16",
            "Block": "121",
            "label": "PUMS VAILANKANNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15750",
            "district": "16",
            "Block": "121",
            "label": "CHRIST THE KING SPECIAL HIGHER SECONDARY SCHOOL VAILANKANNI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15751",
            "district": "16",
            "Block": "121",
            "label": "CHRIST THE KING HS-VAILANKANNI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15752",
            "district": "16",
            "Block": "121",
            "label": "AMS-CHOLAVIDYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15753",
            "district": "16",
            "Block": "121",
            "label": "ST. ANTONY'S AIDED HIGH SCHOOL   KARUNKANNI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15754",
            "district": "16",
            "Block": "121",
            "label": "PUMS THIRUVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15755",
            "district": "16",
            "Block": "121",
            "label": "GHS ETTUKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15756",
            "district": "16",
            "Block": "121",
            "label": "AMS-KEELAVALAKKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15757",
            "district": "16",
            "Block": "121",
            "label": "AMGHSS THIRUKUVALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15758",
            "district": "16",
            "Block": "121",
            "label": "PUMS THIRUKKUVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15759",
            "district": "16",
            "Block": "308",
            "label": "GHS AYMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15760",
            "district": "16",
            "Block": "308",
            "label": "VGHSS MANAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15761",
            "district": "16",
            "Block": "308",
            "label": "PUMS MANAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15762",
            "district": "16",
            "Block": "308",
            "label": "ST. XAVIER'S HIGHER SECONDARY SCHOOL NEERMULAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15763",
            "district": "16",
            "Block": "308",
            "label": "PUMS NEERMULAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15764",
            "district": "16",
            "Block": "308",
            "label": "GHS KOTHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15765",
            "district": "16",
            "Block": "308",
            "label": "GHS SITHAIMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15766",
            "district": "16",
            "Block": "308",
            "label": "GHS PANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15767",
            "district": "16",
            "Block": "308",
            "label": "GHS KOLAPPADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15768",
            "district": "16",
            "Block": "308",
            "label": "GHSS UMBALACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15769",
            "district": "16",
            "Block": "308",
            "label": "PUMS PRINJUMOOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15770",
            "district": "16",
            "Block": "308",
            "label": "GHSS THALAINAYAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15771",
            "district": "16",
            "Block": "308",
            "label": "PUMS ORADIYAMBULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15772",
            "district": "16",
            "Block": "308",
            "label": "GHS ORADIYAMBULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15773",
            "district": "16",
            "Block": "308",
            "label": "PUMS THALAINAYAR 4 SETHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15774",
            "district": "16",
            "Block": "308",
            "label": "GHS KOVILPATHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15775",
            "district": "16",
            "Block": "308",
            "label": "GHSS VELLAPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15776",
            "district": "16",
            "Block": "308",
            "label": "GHS VELLAPALLAM(FC)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15777",
            "district": "16",
            "Block": "308",
            "label": "SRINIVASANAR AIDED HIGH SCHOOL NALUVEDAPATHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15778",
            "district": "16",
            "Block": "308",
            "label": "GHS MOOKATCHI THERU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15779",
            "district": "16",
            "Block": "308",
            "label": "AIDED MIDDLE SCHOOL AVARIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15780",
            "district": "16",
            "Block": "308",
            "label": "GHS KALLIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15781",
            "district": "16",
            "Block": "389",
            "label": "GHSS PUSHPAVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15782",
            "district": "16",
            "Block": "389",
            "label": "GHSS-SEMBODAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15783",
            "district": "16",
            "Block": "389",
            "label": "SEETHALAKSHMI AIDED HIGH SCHOOL,KURAVAPULAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15784",
            "district": "16",
            "Block": "389",
            "label": "POINT CALIMERE INTERNATIONAL SCHOOL, KURAVAPULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15785",
            "district": "16",
            "Block": "389",
            "label": "GHSS KADINALVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15786",
            "district": "16",
            "Block": "389",
            "label": "GHSS  KARUPPAMPULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15787",
            "district": "16",
            "Block": "389",
            "label": "SK GHSS THETHAKUDI SOUTH",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15788",
            "district": "16",
            "Block": "389",
            "label": "AMS, MUTHALIYAR THOPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15789",
            "district": "16",
            "Block": "389",
            "label": "ADW HS, VETTIYAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15790",
            "district": "16",
            "Block": "389",
            "label": "SKS GHSS VEDARANYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15791",
            "district": "16",
            "Block": "389",
            "label": "G(GIRLS) HSS VEDARANYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15792",
            "district": "16",
            "Block": "389",
            "label": "ST ANTONY'S MATRICULATION HR SEC SCHOOL VEDARANYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15793",
            "district": "16",
            "Block": "389",
            "label": "SWAMI VIVEKANANDHA MATRICULATION SCHOOL ANDARKADU VEDARANYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15794",
            "district": "16",
            "Block": "389",
            "label": "GHSS THOPPUTHURAI -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15795",
            "district": "16",
            "Block": "389",
            "label": "QUAIDE MILLATH MATRICULATION HIGHER SECONDARY SCHOOL, THOPPUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15796",
            "district": "16",
            "Block": "389",
            "label": "GHS ARUKATTUTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15797",
            "district": "16",
            "Block": "389",
            "label": "AL-NOOR INDIAN MATRICULATION HIGHER SECONDARY SCHOOL, THETHAKUDI SOUTH THOPPUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15798",
            "district": "16",
            "Block": "389",
            "label": "KGKG GIRLS AIDED HR. SEC. SCHOOL ,VEDARANYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15799",
            "district": "16",
            "Block": "389",
            "label": "GHSS KODIYAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15800",
            "district": "16",
            "Block": "389",
            "label": "GHSS THAGATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15801",
            "district": "16",
            "Block": "389",
            "label": "GHSS THANIKOTTAGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15802",
            "district": "16",
            "Block": "389",
            "label": "GHSS SARABOJIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15803",
            "district": "16",
            "Block": "389",
            "label": "GHS MARUTHUR SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15804",
            "district": "16",
            "Block": "389",
            "label": "GHSS PANCHANATHIKULAM EAST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15805",
            "district": "16",
            "Block": "389",
            "label": "R.NATESANAR GOVT. HR.SEC.SCHOOL, AYAKARANPULAM 2",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15806",
            "district": "16",
            "Block": "389",
            "label": "GHS SIRUTHALAIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15807",
            "district": "16",
            "Block": "389",
            "label": "GHS KOVILKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15808",
            "district": "16",
            "Block": "389",
            "label": "MAHATMA GANDHI AMS MUTHALIYAR KUTHAGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15809",
            "district": "16",
            "Block": "389",
            "label": "GOVT GIRLS HR SEC SCHOOL,AYAKKARANPULAM -3",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15810",
            "district": "16",
            "Block": "389",
            "label": "GHS AYAKKARANPULAM 3",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15811",
            "district": "16",
            "Block": "389",
            "label": "KANTHAVILAS AMS, VADAMAZHAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15812",
            "district": "16",
            "Block": "389",
            "label": "GHSS KARIYAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15813",
            "district": "16",
            "Block": "389",
            "label": "GHS NAGAKKUDAIYAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15814",
            "district": "16",
            "Block": "389",
            "label": "GHSS KATHARIPULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15815",
            "district": "16",
            "Block": "389",
            "label": "AMS, KARUPPANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15816",
            "district": "16",
            "Block": "389",
            "label": "GHS MARUTHUR NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21284",
            "district": "16",
            "Block": "389",
            "label": "RVS BHARATH MATRICULATION HIGHER SECONDARY SCHOOL AYAKARANPULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21388",
            "district": "16",
            "Block": "196",
            "label": "GHS KALASAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21389",
            "district": "16",
            "Block": "325",
            "label": "GHS PANANGUDI SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21390",
            "district": "16",
            "Block": "121",
            "label": "GHS THIRUPOONDI NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21391",
            "district": "16",
            "Block": "121",
            "label": "GHS VILUNTHAMAVADI (E)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21520",
            "district": "16",
            "Block": "196",
            "label": "ADW HS AZHINJAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21534",
            "district": "16",
            "Block": "123",
            "label": "SRI KALAIMAHAL AIDED HIGH SCHOOL MANALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21555",
            "district": "16",
            "Block": "389",
            "label": "VICTORY HIGHER SECONDARY SCHOOL PANCHANATHIKULAM WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21568",
            "district": "16",
            "Block": "196",
            "label": "KUMARAN AIDED HIGH SCHOOL MANJAKOLLAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21606",
            "district": "16",
            "Block": "389",
            "label": "SARADHAMBAL VIDYASHRAM MATRICULATION HIGHER SEC. SCHOOL THETHAKUDI SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21652",
            "district": "16",
            "Block": "196",
            "label": "EGS PILLAY MATRICULATION HIGH SCHOOL KADAMBADI NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22563",
            "district": "16",
            "Block": "389",
            "label": "GOVERNMENT HIGH SCHOOL, ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22564",
            "district": "16",
            "Block": "389",
            "label": "GOVERNMENT HIGH SCHOOL, RAJANKATTALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22565",
            "district": "16",
            "Block": "389",
            "label": "GOVERNMENT HIGH SCHOOL PANNAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22697",
            "district": "16",
            "Block": "308",
            "label": "SIVASAKTHI INTERNATIONAL  SCHOOL, THALAINAYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22698",
            "district": "16",
            "Block": "389",
            "label": "AGARAM MATRICULATION SCHOOL KARUPPAMPULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22864",
            "district": "16",
            "Block": "121",
            "label": "GOVERNMENT HIGH SCHOOL PERIYATHUMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22866",
            "district": "16",
            "Block": "389",
            "label": "GOVERNMENT HIGH SCHOOL PIRANTHIYANKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23010",
            "district": "16",
            "Block": "196",
            "label": "SIR ISSAC NEWTON MATRIC HR,SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23047",
            "district": "16",
            "Block": "325",
            "label": "NALLANDA MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23106",
            "district": "16",
            "Block": "196",
            "label": "E.G.S.P.J INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23107",
            "district": "16",
            "Block": "196",
            "label": "SIR ISSAC NEWTON CBSE  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23251",
            "district": "16",
            "Block": "196",
            "label": "GOVERNMENT HIGH SCHOOL SAMANTHANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23518",
            "district": "16",
            "Block": "196",
            "label": "NAGAPATTINAM DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23884",
            "district": "16",
            "Block": "196",
            "label": "GHS KURICHI",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23885",
            "district": "16",
            "Block": "196",
            "label": "NAGAPATTINAM DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24004",
            "district": "16",
            "Block": "325",
            "label": "GHS GANAPATHIPURAM",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "17": [
        {
            "key": "565",
            "district": "17",
            "Block": "165",
            "label": "SRI VINAYAGA MATRIC HIGHER SECONDARY SCHOOL,VAIYAPPAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24119", "district":"17", 
            "Block":"224", "label": "Sbg nursery and primary school", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "23659",
            "district": "17",
            "Block": "232",
            "label": "Sri Vidhya Lakshmi Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "1221",
            "district": "17",
            "Block": "165",
            "label": "PUMS PARUTHIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1252",
            "district": "17",
            "Block": "232",
            "label": "KONGU MATRIC HSS VELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1261",
            "district": "17",
            "Block": "85",
            "label": "PUMS CHINNASOLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1274",
            "district": "17",
            "Block": "200",
            "label": "PUMS ARIYAGOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1295",
            "district": "17",
            "Block": "232",
            "label": "PUMS JANGAMANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1318",
            "district": "17",
            "Block": "285",
            "label": "PUMS PACHUDAYAMPATTI PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1390",
            "district": "17",
            "Block": "132",
            "label": "PUMS ARIYUR SOLAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1394",
            "district": "17",
            "Block": "186",
            "label": "PUMS ARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1414",
            "district": "17",
            "Block": "132",
            "label": "PUMS ARIYUR KIZHAKKU VALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1423",
            "district": "17",
            "Block": "200",
            "label": "PUMS NARAIKINARUPIRIVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1440",
            "district": "17",
            "Block": "200",
            "label": "PUMS CHINNA VARAGURCOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1448",
            "district": "17",
            "Block": "232",
            "label": "PUMS SULTHANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1453",
            "district": "17",
            "Block": "165",
            "label": "PUMS KALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1462",
            "district": "17",
            "Block": "201",
            "label": "PUMS MARUTHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1471",
            "district": "17",
            "Block": "397",
            "label": "PUMS NACHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1481",
            "district": "17",
            "Block": "224",
            "label": "PUMS ALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1496",
            "district": "17",
            "Block": "232",
            "label": "PUMS VAVIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1500",
            "district": "17",
            "Block": "186",
            "label": "PUMS VALLIYAPPAMPATTIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1508",
            "district": "17",
            "Block": "200",
            "label": "PUMS VELLAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1509",
            "district": "17",
            "Block": "285",
            "label": "PUMS K.VELLALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1515",
            "district": "17",
            "Block": "186",
            "label": "PUMS THOLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1516",
            "district": "17",
            "Block": "285",
            "label": "PUMS MALAIVEPPANKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1519",
            "district": "17",
            "Block": "165",
            "label": "AKV MATRICULATION HIGHER SECONDARY SCHOOL , SURIYAGOUNDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1528",
            "district": "17",
            "Block": "200",
            "label": "PUMS NAMAGIRIPETTAI-I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1529",
            "district": "17",
            "Block": "224",
            "label": "PUMS ELANTHAKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1530",
            "district": "17",
            "Block": "254",
            "label": "KAMARAAJAR MATRICULATION SCHOOL,THALAMBADY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1531",
            "district": "17",
            "Block": "186",
            "label": "PUMS LADDUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1543",
            "district": "17",
            "Block": "224",
            "label": "PUMS THOTTIKARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1553",
            "district": "17",
            "Block": "186",
            "label": "KKMS NANSEI EDAIYAR(AIDED)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1577",
            "district": "17",
            "Block": "66",
            "label": "PUMS KOOLIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1580",
            "district": "17",
            "Block": "85",
            "label": "ST.JOSEPH R.C MID SCHOOL PERUNKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1586",
            "district": "17",
            "Block": "186",
            "label": "PUMS MANAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1597",
            "district": "17",
            "Block": "397",
            "label": "PUMS R.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1607",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, V.MOREPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1612",
            "district": "17",
            "Block": "132",
            "label": "PUMS ASAKKATTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1613",
            "district": "17",
            "Block": "285",
            "label": "PUMS THUTHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1642",
            "district": "17",
            "Block": "397",
            "label": "PUMS NADUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1649",
            "district": "17",
            "Block": "186",
            "label": "PUMS PARALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1652",
            "district": "17",
            "Block": "232",
            "label": "SIVABAKIYAM MUTHUSAMY MATRIC HSS MADESAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1691",
            "district": "17",
            "Block": "186",
            "label": "PUMS RAMUDAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1692",
            "district": "17",
            "Block": "66",
            "label": "PUMS M METTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1701",
            "district": "17",
            "Block": "132",
            "label": "PUMS NARIYANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1712",
            "district": "17",
            "Block": "397",
            "label": "PUMS KUTTALADAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1724",
            "district": "17",
            "Block": "285",
            "label": "PUMS PALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1738",
            "district": "17",
            "Block": "397",
            "label": "PUMS MASAKALIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1744",
            "district": "17",
            "Block": "186",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1745",
            "district": "17",
            "Block": "132",
            "label": "PUMS KUNDUNINADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1790",
            "district": "17",
            "Block": "267",
            "label": "SRI VIDYA MANDIR MATRICULATION HIGHER  SECONDARY SCHOOL,GURUSAMIPALAYAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1806",
            "district": "17",
            "Block": "132",
            "label": "PUMS BAILNADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1809",
            "district": "17",
            "Block": "397",
            "label": "PUMS AKKARAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1823",
            "district": "17",
            "Block": "85",
            "label": "PUMS SELUR SELLAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1841",
            "district": "17",
            "Block": "85",
            "label": "PUMS CHEYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1868",
            "district": "17",
            "Block": "85",
            "label": "PUMS THIDUMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1924",
            "district": "17",
            "Block": "186",
            "label": "PUMS NEIKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1944",
            "district": "17",
            "Block": "224",
            "label": "PUMS KADACHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1975",
            "district": "17",
            "Block": "232",
            "label": "GANDHI MATRICULATION HIGHER SECONDARY SCHOOL, KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2011",
            "district": "17",
            "Block": "165",
            "label": "PUMS EAST MUNJANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2042",
            "district": "17",
            "Block": "254",
            "label": "SRI SAKTHI MATRIC HR.SEC SCHOOL, PUDANCHANTHAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2044",
            "district": "17",
            "Block": "85",
            "label": "PUMS NETTAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2055",
            "district": "17",
            "Block": "85",
            "label": "PUMS VENGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2059",
            "district": "17",
            "Block": "165",
            "label": "PUMS   MAMUNDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2079",
            "district": "17",
            "Block": "232",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2080",
            "district": "17",
            "Block": "232",
            "label": "MALAR MATRIC HSS PARAMATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2092",
            "district": "17",
            "Block": "254",
            "label": "VIVEKANANDA VIDYALLAYA MATRIC HR SEC SCHOOL, PUDUCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2098",
            "district": "17",
            "Block": "132",
            "label": "PUMS NADUVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2110",
            "district": "17",
            "Block": "132",
            "label": "PUMS PERUMPARAPPUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2127",
            "district": "17",
            "Block": "132",
            "label": "PUMS THIRUPULINADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2156",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SIRUMOLASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2180",
            "district": "17",
            "Block": "165",
            "label": "VIDYAA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL, KOOTHANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2203",
            "district": "17",
            "Block": "224",
            "label": "SSRI VALLIAPPA VIDHYALAYAM MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2220",
            "district": "17",
            "Block": "267",
            "label": "AVM AIDED MIDDLE SCHOOL,RASIPURAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2225",
            "district": "17",
            "Block": "232",
            "label": "SKV MATRIC HIGHER SECONDARY SCHOOL, M. KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2236",
            "district": "17",
            "Block": "200",
            "label": "PUMS MOOLAPALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2238",
            "district": "17",
            "Block": "267",
            "label": "SRI GANAPATHI VILAS GOVERNMENT AIDED MIDDLE SCHOOL,PATTANAM MUNIYAPPAM PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2260",
            "district": "17",
            "Block": "132",
            "label": "PUMS PERAKKARAINADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2308",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, T.KOLLAPATTI WARD 30",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23379",
            "district": "17",
            "Block": "224",
            "label": "Annai sellammal matriculation school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23395",
            "district": "17",
            "Block": "201",
            "label": "Anna-Nehru Matric Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2314",
            "district": "17",
            "Block": "232",
            "label": "KANDASWAMI KANDAR MATRIC HR.SEC.SCHOOL, VELUR,NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2332",
            "district": "17",
            "Block": "201",
            "label": "KONGUNADU MATRIC HIGHER SECONDARY SCHOOL, NALLIPALAYAM, NAMAKKAL DT - 637003",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23414",
            "district": "17",
            "Block": "224",
            "label": "Sri Akilandeswari nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "2354",
            "district": "17",
            "Block": "132",
            "label": "PUMS VASALURPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2368",
            "district": "17",
            "Block": "224",
            "label": "PUMS PUDUPALAYAM CAUVERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2370",
            "district": "17",
            "Block": "360",
            "label": "R .VIDYUT MATRIC  HIGHER SEC SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2371",
            "district": "17",
            "Block": "200",
            "label": "PUMS ESWARAMURTHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2381",
            "district": "17",
            "Block": "232",
            "label": "SKV VIDHYAASHRAM SENIOR SECONDARY SCHOOL M.KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2409",
            "district": "17",
            "Block": "360",
            "label": "SENGUNTHAR KALVI NILAYAM WARD 6",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2411",
            "district": "17",
            "Block": "285",
            "label": "PUMS VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2419",
            "district": "17",
            "Block": "224",
            "label": "PUMS  VILANKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2423",
            "district": "17",
            "Block": "254",
            "label": "PUMS LAKKAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2425",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALRASAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2436",
            "district": "17",
            "Block": "85",
            "label": "PUMS ZAMIN ELAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2446",
            "district": "17",
            "Block": "285",
            "label": "PUMS NADUCOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2449",
            "district": "17",
            "Block": "254",
            "label": "PUMS KULLAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2453",
            "district": "17",
            "Block": "66",
            "label": "PUMS KALICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2475",
            "district": "17",
            "Block": "63",
            "label": "SHRI VIDHYABHARATHI MATRIC HIGHER SECONDARY SCHOOL, SAKKARAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2512",
            "district": "17",
            "Block": "132",
            "label": "PUMS PUNGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2525",
            "district": "17",
            "Block": "267",
            "label": "VIDHYA NIKETHAN MATRIC HIGHER SECONDARY SCHOOL,RASIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2529",
            "district": "17",
            "Block": "254",
            "label": "PUMS VEPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2564",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, SEETHARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2568",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELERIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2591",
            "district": "17",
            "Block": "85",
            "label": "BRINDHAVAN  MATRICULATION SCHOOL, POTHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2598",
            "district": "17",
            "Block": "232",
            "label": "PUMS VEERANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2599",
            "district": "17",
            "Block": "200",
            "label": "PUMS THANDAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2603",
            "district": "17",
            "Block": "200",
            "label": "SRI VANI MATRICULATION HIGHER SECONDARY SCHOOL THOPPAPPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2604",
            "district": "17",
            "Block": "186",
            "label": "KAAVERI VIDDHYA BHAVAN MATRIC HR.SEC.SCHOOL, OLAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2610",
            "district": "17",
            "Block": "267",
            "label": "MMS BARATHIDHASAN SALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2620",
            "district": "17",
            "Block": "132",
            "label": "PUMS VALAPPURNADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2633",
            "district": "17",
            "Block": "254",
            "label": "SHANMUGANANDA AIDED MIDDLE SCHOOL AMMAPALAYAMPUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2636",
            "district": "17",
            "Block": "267",
            "label": "PUMS BODINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2641",
            "district": "17",
            "Block": "200",
            "label": "PUMS KARIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2650",
            "district": "17",
            "Block": "224",
            "label": "PUMS SANARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2655",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL  MIDDLE SCHOOL, SANARPALAYAM WARD 29",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2660",
            "district": "17",
            "Block": "200",
            "label": "PUMS PERIYACOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2692",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL,POOSARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2742",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL, ANAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2752",
            "district": "17",
            "Block": "267",
            "label": "SRI VIDYA MANDIR MATRIC HR.SEC.SCHOOL,RASIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2761",
            "district": "17",
            "Block": "267",
            "label": "MMS-KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2800",
            "district": "17",
            "Block": "165",
            "label": "PUMS PALLAKKULI AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2801",
            "district": "17",
            "Block": "267",
            "label": "SRI BHARATHIYA VIDYA MANDIR MATRIC HR SEC SCHOOL,GURUSAMIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2822",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, MALAYADIVARAM WARD 24",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2857",
            "district": "17",
            "Block": "201",
            "label": "SURABI MATRICULATION HIGHER SECONDARY SCHOOL, NGGO'S COLONY, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2874",
            "district": "17",
            "Block": "285",
            "label": "SBM AIDED MIDDLE SCHOOL SENDAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2930",
            "district": "17",
            "Block": "267",
            "label": "PUMS PON.KALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2955",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, SURIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2987",
            "district": "17",
            "Block": "186",
            "label": "RASI MATRICULATION  SCHOOL, MOHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3021",
            "district": "17",
            "Block": "201",
            "label": "PUMS KAVETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3022",
            "district": "17",
            "Block": "200",
            "label": "PUMS ODUVANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3068",
            "district": "17",
            "Block": "285",
            "label": "PUMS THIRUMALAIGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3092",
            "district": "17",
            "Block": "66",
            "label": "PUMS VADUGAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3106",
            "district": "17",
            "Block": "267",
            "label": "SRI AMMAN MATRIC HIGH SCHOOL,MUTHUKALIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3129",
            "district": "17",
            "Block": "85",
            "label": "PUMS KOLAKKATTUPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3153",
            "district": "17",
            "Block": "85",
            "label": "PUMS SULLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3169",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, ARIGNAR ANNA  NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3202",
            "district": "17",
            "Block": "85",
            "label": "VIVEKANANDA MATRICULATION HIGHER SECONDARY SCHOOL, PAUNDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3257",
            "district": "17",
            "Block": "397",
            "label": "MERLION MATRIC HR.SEC.SCHOOL, NO-3, KOMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3279",
            "district": "17",
            "Block": "200",
            "label": "KPM HI TECH MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3309",
            "district": "17",
            "Block": "285",
            "label": "KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL,KALKURICHI, BELUKURICHI, NAMAKKAL -637402",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3318",
            "district": "17",
            "Block": "165",
            "label": "PUMS KURUKKALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3369",
            "district": "17",
            "Block": "201",
            "label": "TRINITY INTERNATIONAL SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3396",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL , KOONAVELAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3417",
            "district": "17",
            "Block": "85",
            "label": "SRI RAJA VINAYAGA MATRICULATION SCHOOL, VELLATHARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3418",
            "district": "17",
            "Block": "200",
            "label": "TRINITY VIDHYALAYA MATRICULATION SCHOOL, MULLUKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3465",
            "district": "17",
            "Block": "85",
            "label": "PUMS PONMALARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3468",
            "district": "17",
            "Block": "85",
            "label": "RKV MATRIC HR.SEC.SCHOOL, JEDARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3471",
            "district": "17",
            "Block": "267",
            "label": "MMS-V.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3475",
            "district": "17",
            "Block": "186",
            "label": "SHINEHILL ACADEMY MATRIC HIGHER SECONDARY SCHOOL,VALAYAPATTI (PO), NAMAKKAL DT -637020",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3481",
            "district": "17",
            "Block": "186",
            "label": "KALAIMAGAL MATRIC HR SEC SCHOOL ,MOHANUR ,NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3499",
            "district": "17",
            "Block": "360",
            "label": "OVVAI KALVI NILAYAM MIDDLE SCHOOL TIRUCHENGODE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3514",
            "district": "17",
            "Block": "397",
            "label": "SRI VENKATESHWARAA MATRICULATION HIGHER SECONDARY SCHOOL,VENNANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3553",
            "district": "17",
            "Block": "360",
            "label": "VIDYAA VIKAS INTERNATIONAL SCHOOL,CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3561",
            "district": "17",
            "Block": "267",
            "label": "PUMS MUTHUKALIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3564",
            "district": "17",
            "Block": "63",
            "label": "SHRI RENGA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL,RAYARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3585",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL,GOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3588",
            "district": "17",
            "Block": "63",
            "label": "PUMS PACH.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3601",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ,NARAYANIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3679",
            "district": "17",
            "Block": "224",
            "label": "PUMS T.VASUKI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3710",
            "district": "17",
            "Block": "85",
            "label": "PUMS K.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3712",
            "district": "17",
            "Block": "360",
            "label": "VISHNU VIDHYALAYA MATRICULATION SCHOOL, ETTIMADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3742",
            "district": "17",
            "Block": "360",
            "label": "MAHADEVA VIDHYALAYAM MIDDLE SCHOOL, WARD 17",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3768",
            "district": "17",
            "Block": "224",
            "label": "PUMS ALAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3836",
            "district": "17",
            "Block": "224",
            "label": "MMS NARAYANA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3857",
            "district": "17",
            "Block": "254",
            "label": "PAAVAI VIDHYASHRAM SENIOR SECONDARY SCHOOL, PACHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3863",
            "district": "17",
            "Block": "254",
            "label": "PUMS KATHIRANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3875",
            "district": "17",
            "Block": "85",
            "label": "K.S MANIAM VIDHYA BHAVAN SENIOR SECONDARY SCHOOL  CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4029",
            "district": "17",
            "Block": "63",
            "label": "SHRI RAMACHANDIRA MATRIC HR SEC SCHOOL,ELACHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4078",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL,KURUKKAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4080",
            "district": "17",
            "Block": "232",
            "label": "PUMS BOMMAKKAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4097",
            "district": "17",
            "Block": "66",
            "label": "PUMS THOOSUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4131",
            "district": "17",
            "Block": "267",
            "label": "THIRUVENKATAVILAS AIDED MIDDLE SCHOOL,RASIPURAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4173",
            "district": "17",
            "Block": "267",
            "label": "ARULMEGU VENKATESWARA AIDED MIDDLE SCHOOL,ANDAGALOREGATE.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4213",
            "district": "17",
            "Block": "267",
            "label": "R C ST.MARY'S MIDDLE SCHOOL, KAKKAVERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4221",
            "district": "17",
            "Block": "254",
            "label": "PUMS KANNURPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4228",
            "district": "17",
            "Block": "397",
            "label": "JVM MATRICULATION HR. SEC. SCHOOL,R.PUDUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4237",
            "district": "17",
            "Block": "224",
            "label": "MMS WEST COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4243",
            "district": "17",
            "Block": "132",
            "label": "DHARMAVIDYALAYA MATRI HIGH SCHOOL KOLLIHILLS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4253",
            "district": "17",
            "Block": "85",
            "label": "PUMS V.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4302",
            "district": "17",
            "Block": "201",
            "label": "FATHIMA RC MIDDLE SCHOOL NAMAKKAL(AIDED)",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4395",
            "district": "17",
            "Block": "66",
            "label": "SRI VIVEKANANDHA VIDHYAALAYA MATRICULATION SCHOOL, ERUMAPATTI, NAMAKKAL DT -637021",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4418",
            "district": "17",
            "Block": "132",
            "label": "HILLDALE MATRICULATION HIGHER SECONDARY SCHOOL, VASALURPATTI,KOLLIMALAI,NAMAKKAL DT -637411",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4452",
            "district": "17",
            "Block": "200",
            "label": "PUMS KONGALAMMAN KOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4482",
            "district": "17",
            "Block": "232",
            "label": "PUMS NALLIYAMPALAYAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4622",
            "district": "17",
            "Block": "200",
            "label": "PUMS ONPATHAMPALIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4653",
            "district": "17",
            "Block": "85",
            "label": "PUMS VADAKARAIATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4669",
            "district": "17",
            "Block": "186",
            "label": "APS RCMS PETTAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4687",
            "district": "17",
            "Block": "267",
            "label": "ANANDA VIDYALAYA MATRICULATION  HIGHER  SECONDARY SCHOOL,  R .PATTANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4748",
            "district": "17",
            "Block": "232",
            "label": "VIB MATRICULATION SCHOOL PEECHAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4865",
            "district": "17",
            "Block": "200",
            "label": "KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL, NAMAGIRIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4875",
            "district": "17",
            "Block": "224",
            "label": "PUMS VEERAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4882",
            "district": "17",
            "Block": "165",
            "label": "PUMS THUTHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4930",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, V PETHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4933",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UPPILIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4934",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL,85R.KOMARAPALAYAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4937",
            "district": "17",
            "Block": "360",
            "label": "KSR MATRIC HIGHER SECONDARY SCHOOL, THOKKAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4997",
            "district": "17",
            "Block": "66",
            "label": "PUMS KASTHURIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5054",
            "district": "17",
            "Block": "200",
            "label": "PUMS VELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5068",
            "district": "17",
            "Block": "200",
            "label": "PUMS NARAIKINARU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5142",
            "district": "17",
            "Block": "224",
            "label": "JAI KANNAMMAL KALVI NILAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5174",
            "district": "17",
            "Block": "201",
            "label": "PUMS KONDICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5220",
            "district": "17",
            "Block": "66",
            "label": "PUMS NAVALADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5226",
            "district": "17",
            "Block": "66",
            "label": "PUMS A VALAVANTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5278",
            "district": "17",
            "Block": "200",
            "label": "PUMS SEERAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5380",
            "district": "17",
            "Block": "224",
            "label": "THE AVS MATRICULATION SCHOOL, KOMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5452",
            "district": "17",
            "Block": "85",
            "label": "PUMS VENGAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5630",
            "district": "17",
            "Block": "254",
            "label": "PUMS MIN  ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5645",
            "district": "17",
            "Block": "201",
            "label": "NAMAKKAL KONGU MATRIC HIGHER SECONDARY SCHOOL , KEERAMBUR , NAMAKKAL DT -637207",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5686",
            "district": "17",
            "Block": "232",
            "label": "PUMS PILLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5717",
            "district": "17",
            "Block": "165",
            "label": "MAHENDRA MATRICULATION HIGHER SECONDARY SCHOOL,KALIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5803",
            "district": "17",
            "Block": "360",
            "label": "AVVAI KSR MATRICULATION SCHOOL, TIRUCHENGODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5864",
            "district": "17",
            "Block": "360",
            "label": "KALAIMAGAL KALVI NILAYAM MIDDLE SCHOOL SALAPALAYAM WARD 32",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5908",
            "district": "17",
            "Block": "200",
            "label": "PUMS URAMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5999",
            "district": "17",
            "Block": "186",
            "label": "LITTLE ANGELS MATRICULATION SCHOOL, ANIYAPURAM, NAMAKKAL.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6011",
            "district": "17",
            "Block": "224",
            "label": "VIGHYAAN VIKASH MATRICULATION SCHOOL KOMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6067",
            "district": "17",
            "Block": "224",
            "label": "CSI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6159",
            "district": "17",
            "Block": "254",
            "label": "RGR INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6269",
            "district": "17",
            "Block": "201",
            "label": "GMS VAGURAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6296",
            "district": "17",
            "Block": "254",
            "label": "PUMS SEVAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6327",
            "district": "17",
            "Block": "224",
            "label": "SPB MATRIC HSS SPB COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6329",
            "district": "17",
            "Block": "254",
            "label": "R.G.R MATRICULATION HIGHER SECONDARY SCHOOL, PUDUCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6341",
            "district": "17",
            "Block": "186",
            "label": "SAROJINI VIDHYALAYA MATRIC HR SEC SCHOOL, KUMARIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6353",
            "district": "17",
            "Block": "285",
            "label": "VETHALOGA VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, SENDAMANGALAM, NAMAKKAL DT -637409",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6362",
            "district": "17",
            "Block": "200",
            "label": "SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL, AYILPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6399",
            "district": "17",
            "Block": "285",
            "label": "BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL, KALAPPANAICKENPATTI,NAMAKKAL DT -637404",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6441",
            "district": "17",
            "Block": "201",
            "label": "TRINITY ACADEMY MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6593",
            "district": "17",
            "Block": "224",
            "label": "JKK NATTRAJA MATRIC HSS, KUMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6647",
            "district": "17",
            "Block": "360",
            "label": "VALARAIGATE VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6729",
            "district": "17",
            "Block": "201",
            "label": "GREEN PARK INTERNATIONAL SCHOOL BODHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11748",
            "district": "17",
            "Block": "201",
            "label": "PUMS KATHAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11749",
            "district": "17",
            "Block": "201",
            "label": "GHS MARURPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11750",
            "district": "17",
            "Block": "201",
            "label": "PUMS MUDALAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11751",
            "district": "17",
            "Block": "201",
            "label": "MMS RAMAPURAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11752",
            "district": "17",
            "Block": "201",
            "label": "PUMS MUDALAIPATTIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11753",
            "district": "17",
            "Block": "201",
            "label": "NAMMALAVAR AIDED MIDDLE SCHOOL,NAMAKKAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11754",
            "district": "17",
            "Block": "201",
            "label": "MHS FORT NAMAKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11755",
            "district": "17",
            "Block": "201",
            "label": "SBM AIDED HIGH SCHOOL NAMAKKAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11756",
            "district": "17",
            "Block": "201",
            "label": "SELVAM MATRIC HIGHER SECONDARY SCHOOL, 3/1143 TRICHY ROAD, NAMAKKAL DT - 637001",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11757",
            "district": "17",
            "Block": "201",
            "label": "MARUTHI VIDDYALAYA  MATRICULATION SCHOOL, 135/1 PATHI NAGAR, SALEM ROAD, NAMAKKAL DT -637001",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11758",
            "district": "17",
            "Block": "201",
            "label": "GOVT HR SEC SCHOOL NAMAKKAL (SOUTH)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11759",
            "district": "17",
            "Block": "201",
            "label": "GGHSS  NAMAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11760",
            "district": "17",
            "Block": "201",
            "label": "KANDASWAMI KANDAR MATRICULATION HIGHER SECONDARY SCHOOL, GANDHI NAGAR, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11761",
            "district": "17",
            "Block": "201",
            "label": "JACK & JILL MATRICULATION SCHOOL,RAMAPURAM PUDUR, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11762",
            "district": "17",
            "Block": "201",
            "label": "SELVAM HIGHER SECONDARY SCHOOL MUDALAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11763",
            "district": "17",
            "Block": "201",
            "label": "GHSS  NAMAKKAL (NORTH )",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11764",
            "district": "17",
            "Block": "201",
            "label": "GHS AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11765",
            "district": "17",
            "Block": "201",
            "label": "GREEN PARK MATRICULATION HIGHER SECONDARY SCHOOL BODHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11766",
            "district": "17",
            "Block": "201",
            "label": "SRI VINAYAGA  HIGHER SECONDARY  SCHOOL PORASAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11767",
            "district": "17",
            "Block": "201",
            "label": "GHS THALIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11768",
            "district": "17",
            "Block": "201",
            "label": "PUMS THINDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11769",
            "district": "17",
            "Block": "201",
            "label": "GHSS ERNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11770",
            "district": "17",
            "Block": "201",
            "label": "GHS KEERAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11771",
            "district": "17",
            "Block": "201",
            "label": "PUMS KEELSATHAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11772",
            "district": "17",
            "Block": "201",
            "label": "GHSS KONUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11773",
            "district": "17",
            "Block": "201",
            "label": "The Navodaya Academy Senior Secondary School - CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11774",
            "district": "17",
            "Block": "201",
            "label": "GHS THOTTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11775",
            "district": "17",
            "Block": "201",
            "label": "DR.M.S. UDAYAMURTHY SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11776",
            "district": "17",
            "Block": "201",
            "label": "KURINJI MATRIC HIGHER SECONDARY SCHOOL KAVETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11777",
            "district": "17",
            "Block": "201",
            "label": "KURINJI HSS KAVETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11778",
            "district": "17",
            "Block": "201",
            "label": "GHS BODHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11779",
            "district": "17",
            "Block": "201",
            "label": "KURINJI SENIOR SECONDARY SCHOOL KAVETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11780",
            "district": "17",
            "Block": "201",
            "label": "GHS PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11781",
            "district": "17",
            "Block": "201",
            "label": "THE SPECTRUM ACADEMY MATRICULATION SCHOOL, 6/258 TRICHY ROAD, NAMAKKAL DT -637001",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11782",
            "district": "17",
            "Block": "201",
            "label": "JEIVIKASS HR.SEC SCHOOL, MOHANUR ROAD, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11783",
            "district": "17",
            "Block": "201",
            "label": "NATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11784",
            "district": "17",
            "Block": "201",
            "label": "PGP MATRICULATION HIGHER SECONDARY SCHOOL, PALANI NAGAR, VETTAMBADI, NAMAKKAL DT -637405",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11785",
            "district": "17",
            "Block": "201",
            "label": "PGP INTERNATIONAL SCHOOL ,VETTAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11786",
            "district": "17",
            "Block": "201",
            "label": "GHS VETTAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11787",
            "district": "17",
            "Block": "201",
            "label": "PUMS CHINNAVEPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11788",
            "district": "17",
            "Block": "201",
            "label": "CHRIST KING MATRICULATION HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11789",
            "district": "17",
            "Block": "132",
            "label": "GTR HSS SENGARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11790",
            "district": "17",
            "Block": "132",
            "label": "KGBV NATHUKULIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11791",
            "district": "17",
            "Block": "132",
            "label": "GHS NATHUKULIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11792",
            "district": "17",
            "Block": "132",
            "label": "GHSS ARIYURPUDUVALAVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11793",
            "district": "17",
            "Block": "132",
            "label": "KGBV ARIYUR KILAKKUVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11794",
            "district": "17",
            "Block": "132",
            "label": "MODEL SCHOOL, KOLLIHILLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11795",
            "district": "17",
            "Block": "132",
            "label": "GTR HSS VALAVANTHINADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11796",
            "district": "17",
            "Block": "132",
            "label": "KGBV SELURNADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11797",
            "district": "17",
            "Block": "186",
            "label": "GHSS PALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11798",
            "district": "17",
            "Block": "186",
            "label": "GHSS ANIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11799",
            "district": "17",
            "Block": "186",
            "label": "LITTLE ANGELS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11800",
            "district": "17",
            "Block": "186",
            "label": "THE SCSM MATRICULATION HIGHER SECONDARY SCHOOL. MOHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11801",
            "district": "17",
            "Block": "186",
            "label": "GOVT MODEL GIRLS HSS MOHANUR -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11802",
            "district": "17",
            "Block": "186",
            "label": "GOVT  BOYS HSS MOHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11803",
            "district": "17",
            "Block": "186",
            "label": "VETRI VIDYALAYA MATRICULATION SCHOOL, ALAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11804",
            "district": "17",
            "Block": "186",
            "label": "GHS ORUVANDURPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11805",
            "district": "17",
            "Block": "186",
            "label": "GHS ANDAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11806",
            "district": "17",
            "Block": "186",
            "label": "GHSS VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11807",
            "district": "17",
            "Block": "186",
            "label": "GHSS  N.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11808",
            "district": "17",
            "Block": "66",
            "label": "BHARATHI HIGHER SECONDARY SCHOOL REDDIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11809",
            "district": "17",
            "Block": "66",
            "label": "BHARATHI VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, REDDIPATTI, NAMAKKAL DT -637002",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11810",
            "district": "17",
            "Block": "66",
            "label": "SRI MAHABHARATHI HIGHER SECONDARY SCHOOL, KOOLIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11811",
            "district": "17",
            "Block": "66",
            "label": "GHSS MUTHUKAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11812",
            "district": "17",
            "Block": "66",
            "label": "GHS BODINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11813",
            "district": "17",
            "Block": "66",
            "label": "GHSS PALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11814",
            "district": "17",
            "Block": "66",
            "label": "ADW HIGH SCH A.PALAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11815",
            "district": "17",
            "Block": "66",
            "label": "GHSS POTTIREDDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11816",
            "district": "17",
            "Block": "66",
            "label": "GHS ALANGANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11817",
            "district": "17",
            "Block": "66",
            "label": "GHS PAVITHRAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11818",
            "district": "17",
            "Block": "66",
            "label": "GHS PAVITHRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11819",
            "district": "17",
            "Block": "66",
            "label": "GHSS VARAGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11820",
            "district": "17",
            "Block": "66",
            "label": "PUMS DEVARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11821",
            "district": "17",
            "Block": "66",
            "label": "PUMS VARATHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11822",
            "district": "17",
            "Block": "66",
            "label": "OXFORD MATRICULATION HIGHER SECONDARY SCHOOL, KAIKATTI, ERUMAPATTI, NAMAKKAL DT -637021",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11823",
            "district": "17",
            "Block": "66",
            "label": "GBHSS ERUMAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11824",
            "district": "17",
            "Block": "66",
            "label": "GGHSS ERUMAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11825",
            "district": "17",
            "Block": "66",
            "label": "GHSS KAVAKKARANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11826",
            "district": "17",
            "Block": "66",
            "label": "GHS JAMBUMADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11827",
            "district": "17",
            "Block": "66",
            "label": "GHSS SEVINTHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11828",
            "district": "17",
            "Block": "285",
            "label": "GGHSS SENDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11829",
            "district": "17",
            "Block": "285",
            "label": "VIVEKA MATRICULATION SCHOOL, PONNAMAPUDUR,SENDAMANGALAM, NAMAKKAL DT -637404",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11830",
            "district": "17",
            "Block": "285",
            "label": "GGHSS KALAPPANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11831",
            "district": "17",
            "Block": "285",
            "label": "GBHSS KALAPPANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11832",
            "district": "17",
            "Block": "285",
            "label": "GHSS BELUKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11833",
            "district": "17",
            "Block": "285",
            "label": "BRINDAVAN MATRICULATION HIGHER SECONDARY SCHOOL, THUTHIKULAM, NAMAKKAL DT-637404",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11834",
            "district": "17",
            "Block": "285",
            "label": "GHS RAMANATHAPURAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11835",
            "district": "17",
            "Block": "285",
            "label": "GBHSS SENDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11836",
            "district": "17",
            "Block": "285",
            "label": "GHS POTTANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11837",
            "district": "17",
            "Block": "254",
            "label": "GHSS ELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11838",
            "district": "17",
            "Block": "254",
            "label": "RAINBOW MATRICULATION SCHOOL, PUDUCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11839",
            "district": "17",
            "Block": "254",
            "label": "GHSS PUDUCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11840",
            "district": "17",
            "Block": "254",
            "label": "ADW HR SEC SCHOOL, KALANGANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11841",
            "district": "17",
            "Block": "254",
            "label": "GHSS KARAIKURICHIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11842",
            "district": "17",
            "Block": "254",
            "label": "GHSS PACHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11843",
            "district": "17",
            "Block": "254",
            "label": "GHSS THIRUMALAIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11844",
            "district": "17",
            "Block": "254",
            "label": "GHSS SELLAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11845",
            "district": "17",
            "Block": "254",
            "label": "KAMARAJAR HIGHER SECONDARY SCHOOL BOMMAIKUTTAIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11846",
            "district": "17",
            "Block": "267",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL-R.PATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11847",
            "district": "17",
            "Block": "267",
            "label": "RASI INTERNATIONAL SCHOOL(CBSE), RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11848",
            "district": "17",
            "Block": "267",
            "label": "SHRI VIDHYA BHARATHI MODERN HR SEC(CBSE) SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11849",
            "district": "17",
            "Block": "267",
            "label": "GHS CHANDRASEKARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11850",
            "district": "17",
            "Block": "267",
            "label": "R.C.SACRED HEART AIDED HR.SEC.SCHOOL,RASIPURAM.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11851",
            "district": "17",
            "Block": "267",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ANNASALAI, RASIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11852",
            "district": "17",
            "Block": "267",
            "label": "GHSS-SIVANANDA SALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11853",
            "district": "17",
            "Block": "267",
            "label": "NATIONAL GIRLS HIGHER SECONDARY SCHOOL, RASIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11854",
            "district": "17",
            "Block": "267",
            "label": "SRI VASAVI VIDYALAYA  MATRIC HR SEC SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11855",
            "district": "17",
            "Block": "267",
            "label": "SRV GIRLS HIGHER SECONDARY SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11856",
            "district": "17",
            "Block": "267",
            "label": "SRV BOYS HIGHER SECONDARY SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11857",
            "district": "17",
            "Block": "267",
            "label": "SRV EXCEL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11858",
            "district": "17",
            "Block": "267",
            "label": "GOVT HIGH SCHOOL,KUNAVELAMPATTY PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11859",
            "district": "17",
            "Block": "267",
            "label": "SENGUNTHAR MAHAJANA GIRLS HIGH SCHOOL,GURUSAMIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11860",
            "district": "17",
            "Block": "267",
            "label": "SENGUNTHAR MAHAJANA GOVERNMENT AIDED HIGHER SECONDARY SCHOOL, GURUSAMIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11861",
            "district": "17",
            "Block": "267",
            "label": "NATIONAL MATRICULATION SCHOOL, SINGALANDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11862",
            "district": "17",
            "Block": "267",
            "label": "GHSS SINGALANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11863",
            "district": "17",
            "Block": "267",
            "label": "GHSS VADUGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11864",
            "district": "17",
            "Block": "397",
            "label": "GHS MINNAKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11865",
            "district": "17",
            "Block": "397",
            "label": "GHS NO-3 KOMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11866",
            "district": "17",
            "Block": "397",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ALAVAIPATTI VENNANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11867",
            "district": "17",
            "Block": "397",
            "label": "GHSS (G)ALAVAIPATTI VENNANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11868",
            "district": "17",
            "Block": "397",
            "label": "THILLAIYADI VALLIYAMMAI  AIDED GIRLS HIGH SCHOOL, VENNANDUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11869",
            "district": "17",
            "Block": "397",
            "label": "SRI VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL, NACHIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11870",
            "district": "17",
            "Block": "397",
            "label": "GHS O.SOWDHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11871",
            "district": "17",
            "Block": "397",
            "label": "KALAIMAGAL MATRICULATION SCHOOL, PALANTHINIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11872",
            "district": "17",
            "Block": "397",
            "label": "GHSS ATHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11873",
            "district": "17",
            "Block": "397",
            "label": "GHS ATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11874",
            "district": "17",
            "Block": "397",
            "label": "VETRI VHSS B KEERANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11875",
            "district": "17",
            "Block": "397",
            "label": "VETRI VHSS G KEERANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11876",
            "district": "17",
            "Block": "397",
            "label": "GANDHI KALVI NILAYAM HIGH SCHOOL, THENGALPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11877",
            "district": "17",
            "Block": "397",
            "label": "GHSS R.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11878",
            "district": "17",
            "Block": "397",
            "label": "SRV HITECH MATRIC HIGHER SECONDARY SCHOOL,MASAKALIPATTI, RASIPURAM TK, NAMAKKAL DT -637401",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11879",
            "district": "17",
            "Block": "200",
            "label": "GHS R PUDUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11880",
            "district": "17",
            "Block": "200",
            "label": "GHS THANNEERPANDALKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11881",
            "district": "17",
            "Block": "200",
            "label": "GBHSS NAMAGIRIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11882",
            "district": "17",
            "Block": "200",
            "label": "GGHSS NAMAGIRIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11883",
            "district": "17",
            "Block": "200",
            "label": "GHSS THO JEDARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11884",
            "district": "17",
            "Block": "200",
            "label": "GHS AYILPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11885",
            "district": "17",
            "Block": "200",
            "label": "GHSS UDAIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11886",
            "district": "17",
            "Block": "200",
            "label": "SRI SWAMY MATRICULATION HIGHER SECONDARY SCHOOL, SAMBAPALIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11887",
            "district": "17",
            "Block": "200",
            "label": "SRI RAM VINAYAKA SCHOOL, PILLIPPAKKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11888",
            "district": "17",
            "Block": "200",
            "label": "GTR (G) HSS MULLUKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11889",
            "district": "17",
            "Block": "200",
            "label": "GTR BOYS HR SEC SCHOOL  MULLUKKURCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11890",
            "district": "17",
            "Block": "200",
            "label": "GHSS THIMMANNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11891",
            "district": "17",
            "Block": "200",
            "label": "GHSS MANGALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11892",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THANNEERPANDALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11893",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGH SCHOOL, ANANGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11894",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGH SCHOOL, PATLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11895",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ERAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11896",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGH SCHOOL, MOLASI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11897",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VITTAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11898",
            "district": "17",
            "Block": "360",
            "label": "A.S.S MATRICULATION HIGHER SECONDARY SCHOOL, KUCHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11899",
            "district": "17",
            "Block": "360",
            "label": "KSR AKSHARA ACADEMY  THOKKAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11900",
            "district": "17",
            "Block": "360",
            "label": "GHSS DEVANANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11901",
            "district": "17",
            "Block": "360",
            "label": "VETRI O.S.S. HIGH SCHOOL, ANANGOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11902",
            "district": "17",
            "Block": "360",
            "label": "GOVT GIRLS HIGHER SEC. SCHOOL, TIRUCHENGODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11903",
            "district": "17",
            "Block": "360",
            "label": "GOVT BOYS HIGHER SECONDARY SCHOOL,TIRUCHENGODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11904",
            "district": "17",
            "Block": "360",
            "label": "MAHADEVA VIDYALAYAM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11905",
            "district": "17",
            "Block": "360",
            "label": "SAINT ANTONY HIGHER SECONDARY SCHOOL, WARD 5",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11906",
            "district": "17",
            "Block": "360",
            "label": "NEW OXFORD MATRICULATION SCHOOL, KARATTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11907",
            "district": "17",
            "Block": "360",
            "label": "SEVENTH-DAY ADVENTIST MATRICULATION HIGHER SECONDARY SCHOOL,TIRUCHENGODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11908",
            "district": "17",
            "Block": "360",
            "label": "HOLY ANGELS MATRIC HR.SEC.SCHOOL NEIKARAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11909",
            "district": "17",
            "Block": "360",
            "label": "VRIKSHA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11910",
            "district": "17",
            "Block": "360",
            "label": "VIDYAA VIKAS MATRIC HR. SEC. SCHOOL, VARAHOORAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11911",
            "district": "17",
            "Block": "360",
            "label": "VIDYAA VIKAS GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11912",
            "district": "17",
            "Block": "360",
            "label": "GOVT. HIGH SCHOOL, ANDIPALYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11913",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, CHITTALANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11914",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOZHIKKALNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11915",
            "district": "17",
            "Block": "360",
            "label": "SPM HIGHER SECONDARY SCHOOL KOZHIKKALNATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11916",
            "district": "17",
            "Block": "360",
            "label": "NALANDAS HIGHER SECONDARY SCHOOL, CHINNATHAMBIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11917",
            "district": "17",
            "Block": "224",
            "label": "HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL KUMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11918",
            "district": "17",
            "Block": "224",
            "label": "GHSS KULLANAICKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11919",
            "district": "17",
            "Block": "224",
            "label": "GHSS(BOYS) KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11920",
            "district": "17",
            "Block": "224",
            "label": "RELIANCE MATRIC HR SEC SCHOOL, KUPPANDAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11921",
            "district": "17",
            "Block": "224",
            "label": "SSM LAKSHMIAMMAL MATRIC HSS, KUMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11922",
            "district": "17",
            "Block": "224",
            "label": "POORNAMATHI MATRIC HIGHER SECONDARY SCHOOL, KUMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11923",
            "district": "17",
            "Block": "224",
            "label": "Govt. Model Girls Hr.Sec. School, KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11924",
            "district": "17",
            "Block": "224",
            "label": "JKK RANGAMMAL GIRLS HSS KUMARAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11925",
            "district": "17",
            "Block": "224",
            "label": "ROYAL INTERNATIONAL  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11926",
            "district": "17",
            "Block": "224",
            "label": "MHS BUDHAR STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11927",
            "district": "17",
            "Block": "224",
            "label": "SSM CENTRAL SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11928",
            "district": "17",
            "Block": "224",
            "label": "PUMS V.METTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11929",
            "district": "17",
            "Block": "224",
            "label": "EINSSTEIN MATRIC HIGHER SECONDARY SCHOOL, KATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11930",
            "district": "17",
            "Block": "224",
            "label": "MUNIRAJAH MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11931",
            "district": "17",
            "Block": "224",
            "label": "NEHRU MEMORIAL SAMPOORANIAMMAL HR SEC SCHOOL FOR DIFFERENTLY ABLED, ETHIRMEDU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11932",
            "district": "17",
            "Block": "224",
            "label": "GHS VEMANKATTUVALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11933",
            "district": "17",
            "Block": "224",
            "label": "GHS ARUVANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11934",
            "district": "17",
            "Block": "224",
            "label": "GHS KALIYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11935",
            "district": "17",
            "Block": "224",
            "label": "RATHNA MATRICULATION SCHOOL PALLIPALAYAM AGRAHARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11936",
            "district": "17",
            "Block": "224",
            "label": "GHSS(BOYS) PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11937",
            "district": "17",
            "Block": "224",
            "label": "GHS VEDIYARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11938",
            "district": "17",
            "Block": "224",
            "label": "GHS MAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11939",
            "district": "17",
            "Block": "224",
            "label": "GHSS VEPPADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11940",
            "district": "17",
            "Block": "224",
            "label": "VANI VIDYALAYA MATRIC HSS UPPUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11941",
            "district": "17",
            "Block": "224",
            "label": "GHS E.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11942",
            "district": "17",
            "Block": "224",
            "label": "GHSS PALLAKKAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11943",
            "district": "17",
            "Block": "224",
            "label": "GHSS SOWDHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11944",
            "district": "17",
            "Block": "224",
            "label": "KRP MATRIC HSS PACHAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11945",
            "district": "17",
            "Block": "224",
            "label": "SANKAR HIGHER SECONDARY SCHOOL SANKARI WEST",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11946",
            "district": "17",
            "Block": "224",
            "label": "GHS PADAIVEEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11947",
            "district": "17",
            "Block": "224",
            "label": "SPK MATRIC HSS KADACHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11948",
            "district": "17",
            "Block": "224",
            "label": "SRRI SPK PUBLIC SENIOR SECONDARY SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11949",
            "district": "17",
            "Block": "224",
            "label": "SPB  HIGH SCHOOL(AIDED)",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11950",
            "district": "17",
            "Block": "224",
            "label": "VALLABHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "11951",
            "district": "17",
            "Block": "224",
            "label": "GHSS(GIRLS) PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11952",
            "district": "17",
            "Block": "224",
            "label": "GHS CAUVERY R S",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11953",
            "district": "17",
            "Block": "224",
            "label": "GHSS PAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11954",
            "district": "17",
            "Block": "224",
            "label": "GHSS KOKKARAYANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11955",
            "district": "17",
            "Block": "165",
            "label": "SHRI VINAYAGA HR SEC SCHOOL    PILLANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11956",
            "district": "17",
            "Block": "165",
            "label": "GHS  SAPPAYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11957",
            "district": "17",
            "Block": "165",
            "label": "GOVT GIRLS HR SEC SCHOOL MALLASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11958",
            "district": "17",
            "Block": "165",
            "label": "GOVT BOYS HR SEC SCHOOL MALLASAMUTHRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11959",
            "district": "17",
            "Block": "165",
            "label": "AKV PUBLIC SCHOOL, SENIOR SECONDARY - CBSE, SURIYAGOUNDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11960",
            "district": "17",
            "Block": "165",
            "label": "GOVERNMENT HIGH SCHOOL,KOTTAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11961",
            "district": "17",
            "Block": "165",
            "label": "GOVERNMENT HR SEC SCHOOL VAIYAPPAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11962",
            "district": "17",
            "Block": "165",
            "label": "GOVERNMENT HR SEC SCHOOL RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11963",
            "district": "17",
            "Block": "63",
            "label": "SENGUNTHAR MATRICULATION HIGHER SECONDARY SCHOOL, KOSAVAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11964",
            "district": "17",
            "Block": "63",
            "label": "MAHENDRA MATRIC HIGHER SECONDARY SCHOOL, KUMARAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11965",
            "district": "17",
            "Block": "63",
            "label": "PUMS SATHINAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11966",
            "district": "17",
            "Block": "63",
            "label": "GHS KUPPANDAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11967",
            "district": "17",
            "Block": "63",
            "label": "VIVEKANANDHA VIDHYA BHAVAN MATRIC HR.SEC.SCHOOL  ELAYAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11968",
            "district": "17",
            "Block": "63",
            "label": "GHSS ULAGAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11969",
            "district": "17",
            "Block": "63",
            "label": "SHRI RENGA VIDYALAYA HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11970",
            "district": "17",
            "Block": "63",
            "label": "T P P GHSS MANALI JEDARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11971",
            "district": "17",
            "Block": "63",
            "label": "STAR MATRIC HIGHER SECONDARY SCHOOL, PERIYAMANALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11972",
            "district": "17",
            "Block": "63",
            "label": "GHSS ELACHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11973",
            "district": "17",
            "Block": "63",
            "label": "SHRI VIDHYABHARATHI HIGH SCHOOL SAKKARAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11974",
            "district": "17",
            "Block": "63",
            "label": "PUMS NALLAKUMARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11975",
            "district": "17",
            "Block": "63",
            "label": "ST.THERASAS MIDDLE SCHOOL  ELANAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11976",
            "district": "17",
            "Block": "63",
            "label": "GHSS MANICKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11977",
            "district": "17",
            "Block": "63",
            "label": "GHS ILUPPILI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11978",
            "district": "17",
            "Block": "63",
            "label": "PUMS KOKKALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11979",
            "district": "17",
            "Block": "63",
            "label": "GHSS PERIYAMANALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11980",
            "district": "17",
            "Block": "63",
            "label": "GHSS VELAGOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11981",
            "district": "17",
            "Block": "63",
            "label": "KONGUNADU MATRIC HSS VELAGOUNDAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11982",
            "district": "17",
            "Block": "63",
            "label": "GHSS BOMMAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11983",
            "district": "17",
            "Block": "232",
            "label": "SKV HIGHER SECONDARY SCHOOL, M.KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11984",
            "district": "17",
            "Block": "232",
            "label": "VIB HIGHER SECONDARY SCHOOL, PEECHAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11985",
            "district": "17",
            "Block": "232",
            "label": "GHSS KANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11986",
            "district": "17",
            "Block": "232",
            "label": "GHSS KARICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11987",
            "district": "17",
            "Block": "232",
            "label": "GHS S.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11988",
            "district": "17",
            "Block": "232",
            "label": "GHS K.KOLANDHAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11989",
            "district": "17",
            "Block": "232",
            "label": "GHS CHETTIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11990",
            "district": "17",
            "Block": "232",
            "label": "GHS KONDARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11991",
            "district": "17",
            "Block": "232",
            "label": "GHS VILLIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11992",
            "district": "17",
            "Block": "232",
            "label": "PUMS PILLAIKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23406",
            "district": "17",
            "Block": "267",
            "label": "SRP International School (CBSE)",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "11993",
            "district": "17",
            "Block": "232",
            "label": "SRI RAMAMOORTHI HIGH SCHOOL VASANTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11994",
            "district": "17",
            "Block": "232",
            "label": "GOVT GIRLS HR SEC SCHOOL, PARAMATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11995",
            "district": "17",
            "Block": "232",
            "label": "GBHSS PARAMATHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11996",
            "district": "17",
            "Block": "232",
            "label": "GIRLS HIGHER SECONDARY SCHOOL (AIDED), VELUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11997",
            "district": "17",
            "Block": "232",
            "label": "KANDASWAMI KANDAR'S HIGHER SECONDARY SCHOOL VELUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11998",
            "district": "17",
            "Block": "85",
            "label": "GHSS SOLASIRAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11999",
            "district": "17",
            "Block": "85",
            "label": "SREE AMMAN MATRICULATION HIGHER SECONDARY  SCHOOL, MARAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12000",
            "district": "17",
            "Block": "85",
            "label": "GHS NANJAPPAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12001",
            "district": "17",
            "Block": "85",
            "label": "GHSS KABILARMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12002",
            "district": "17",
            "Block": "85",
            "label": "VIVEKANANDA HIGHER SECONDARY SCHOOL PAUNDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12003",
            "district": "17",
            "Block": "85",
            "label": "RN OXFORD MATRICULATION SCHOOL, MUNIAGOUNDAMPALAYAM, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12004",
            "district": "17",
            "Block": "85",
            "label": "SUN STAR HR SEC SCHOOL, VADAKARAIATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12005",
            "district": "17",
            "Block": "85",
            "label": "GHSS JEDARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12006",
            "district": "17",
            "Block": "85",
            "label": "GHS ANANGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12007",
            "district": "17",
            "Block": "85",
            "label": "GHSS PILIKKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12008",
            "district": "17",
            "Block": "85",
            "label": "GHS KONDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12009",
            "district": "17",
            "Block": "85",
            "label": "GGHSS PANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12010",
            "district": "17",
            "Block": "85",
            "label": "GBHSS PANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12011",
            "district": "17",
            "Block": "85",
            "label": "GHSS POTHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20914",
            "district": "17",
            "Block": "254",
            "label": "CHANDRA CHELLAPPAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21024",
            "district": "17",
            "Block": "360",
            "label": "MEDHA MATRICULATION SCHOOL, PUDHUPULIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21069",
            "district": "17",
            "Block": "254",
            "label": "THE CHILDPLAN ACADEMY (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21070",
            "district": "17",
            "Block": "267",
            "label": "VETRI VIKAAS PUBLIC SCHOOL (SENIOR SECONDARY),RASIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21071",
            "district": "17",
            "Block": "360",
            "label": "THE MERIDIAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21151",
            "district": "17",
            "Block": "186",
            "label": "THE MODERN ACADEMY MATRIC HIGHER SECONDARY SCHOOL,N.PUDUPATTI, TRICHY ROAD, NAMAKKAL -637020",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21215",
            "district": "17",
            "Block": "165",
            "label": "MODEL SCHOOL, RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21229",
            "district": "17",
            "Block": "397",
            "label": "VIDHYA NIKETHAN INTEL MATRIC HIGHER SECONDARY SCHOOL,THENGALPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21230",
            "district": "17",
            "Block": "232",
            "label": "SKV GIRLS MATRIC HR SEC SCHOOL, M. KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21266",
            "district": "17",
            "Block": "254",
            "label": "SRI GURUKULAM MATRICULATION SCHOOL, REDDIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21267",
            "district": "17",
            "Block": "200",
            "label": "GREEN WORLD EXCEL SCHOOL MOOLAPALLIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21268",
            "district": "17",
            "Block": "224",
            "label": "SRK MATRICULATION SCHOOL, PALLAKKAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21308",
            "district": "17",
            "Block": "165",
            "label": "MAHENDRA INTERNATIONAL SCHOOL, KALIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21461",
            "district": "17",
            "Block": "132",
            "label": "GHS SELURNADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21462",
            "district": "17",
            "Block": "200",
            "label": "GHS SINGILIYANCOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21596",
            "district": "17",
            "Block": "186",
            "label": "PARK VIEW ACADEMY, ANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21664",
            "district": "17",
            "Block": "360",
            "label": "BARANI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21665",
            "district": "17",
            "Block": "66",
            "label": "BHARATHI ACADEMY,  REDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21666",
            "district": "17",
            "Block": "201",
            "label": "THAQWA MATRICULATION SCHOOL , NALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21667",
            "district": "17",
            "Block": "165",
            "label": "SRI VINAYAGA INTERNATIONAL SCHOOL, VAIYAPPAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21668",
            "district": "17",
            "Block": "397",
            "label": "MAHARISHI VIDYA MANDIR  (CBSE), MASAKALIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21669",
            "district": "17",
            "Block": "267",
            "label": "SRI VIDYA MANDIR MATRICULATION SCHOOL,KURUKKAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22120",
            "district": "17",
            "Block": "201",
            "label": "SPECTRUM LIFE SCHOOL, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22121",
            "district": "17",
            "Block": "254",
            "label": "GNANODAYA INTERNATIONAL SCHOOL(CBSE) AK SAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22122",
            "district": "17",
            "Block": "267",
            "label": "SRV INTERNATIONAL SCHOOL,PATTANAM ROAD,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22123",
            "district": "17",
            "Block": "224",
            "label": "GREEN LAND MATRICULATION SCHOOL, METTUKADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22124",
            "district": "17",
            "Block": "85",
            "label": "CHANAKYA HI-TECH MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22445",
            "district": "17",
            "Block": "201",
            "label": "PARK PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22446",
            "district": "17",
            "Block": "132",
            "label": "GOVT.EKLAVYA MODEL RESIDENTIAL HR.SEC.SCHOOL, SENKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22447",
            "district": "17",
            "Block": "186",
            "label": "SHRI SENTHUR INTERNATIONAL SCHOOL (CBSE) MOHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22448",
            "district": "17",
            "Block": "254",
            "label": "GHS THATHATHIRIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22449",
            "district": "17",
            "Block": "224",
            "label": "MHS CHINNAPPANAYAKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22450",
            "district": "17",
            "Block": "224",
            "label": "SRI VANI INTERNATIONAL SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22451",
            "district": "17",
            "Block": "224",
            "label": "VIGNESH VIDHYAA BHAVAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22452",
            "district": "17",
            "Block": "63",
            "label": "GHS KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22453",
            "district": "17",
            "Block": "232",
            "label": "MALAR PUBLIC SCHOOL, PARAMATHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22454",
            "district": "17",
            "Block": "232",
            "label": "GOVERNMENT HIGH SCHOOL KUPPUCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22805",
            "district": "17",
            "Block": "285",
            "label": "VETHALOGA VIDHYALAYA  CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22806",
            "district": "17",
            "Block": "267",
            "label": "SRV INNOVATIVE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22807",
            "district": "17",
            "Block": "267",
            "label": "SRI VIDYA MANDIR INTERNATIONAL (CBSE) SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22808",
            "district": "17",
            "Block": "267",
            "label": "SRV PUBLIC SCHOOL, RASIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22809",
            "district": "17",
            "Block": "63",
            "label": "KONGUNADU SCHOOL  VELAGOUNDAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22810",
            "district": "17",
            "Block": "85",
            "label": "VIVEKANANDA VIDYALAYAM PAUNDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22811",
            "district": "17",
            "Block": "85",
            "label": "R.N. OXFORD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22826",
            "district": "17",
            "Block": "224",
            "label": "AVS GIRLS MATRICULATION HIGHER SECONDARY SCHOOL, KOMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22966",
            "district": "17",
            "Block": "132",
            "label": "GHS, SEPPANKULAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22967",
            "district": "17",
            "Block": "186",
            "label": "GOVT HIGH SCHOOL, S.VAZHAVANTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22968",
            "district": "17",
            "Block": "360",
            "label": "GOVT HIGH SCHOOL, KARUMAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22969",
            "district": "17",
            "Block": "224",
            "label": "GHS AVATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23178",
            "district": "17",
            "Block": "224",
            "label": "SRI RAGAVENDRA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23194",
            "district": "17",
            "Block": "63",
            "label": "SHRI RAMACHANDIRA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23253",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL HIGH SCHOOL, NESAVALAR COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23254",
            "district": "17",
            "Block": "201",
            "label": "GOVERNMENT HIGH SCHOOL, CHINNAMUTHALAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23255",
            "district": "17",
            "Block": "132",
            "label": "GOVERNMENT HIGH SCHOOL, ALATHURNADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23283",
            "district": "17",
            "Block": "224",
            "label": "K.S.INDIAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23414",
            "district": "17",
            "Block": "224",
            "label": "Sri Akilandeswari nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23519",
            "district": "17",
            "Block": "224",
            "label": "EXCEL PUBLIC SCHOOL (CBSE), PALLAKKAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23520",
            "district": "17",
            "Block": "254",
            "label": "PAAVAI MATRICULATION HIGHER SECONDARY SCHOOL, PACHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23521",
            "district": "17",
            "Block": "360",
            "label": "THE JPS ACADEMY MATRICULATION SCHOOL, TIRUCHENGODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23522",
            "district": "17",
            "Block": "397",
            "label": "VIDHYA NIKETHAN INTEL PUBLIC SCHOOL, THENGALPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23780",
            "district": "17",
            "Block": "66",
            "label": "SRI CHAITANYA TECHNO SCHOOL, KOOLIPATTY",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23797",
            "district": "17",
            "Block": "85",
            "label": "PUMS K.AYYAMPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23881",
            "district": "17",
            "Block": "186",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23890",
            "district": "17",
            "Block": "201",
            "label": "Namakkal District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23909",
            "district": "17",
            "Block": "232",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24036",
            "district": "17",
            "Block": "360",
            "label": "G GLOBAL SCHOOL, RAJAGOUNDAMPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "18": [
        {
            "key": "685",
            "district": "18",
            "Block": "236",
            "label": "ST JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL ELAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1499",
            "district": "18",
            "Block": "236",
            "label": "ANNAI PARUVATHAMMAL MATRICULATION HIGH SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2037",
            "district": "18",
            "Block": "7",
            "label": "LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL CHETTIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2334",
            "district": "18",
            "Block": "236",
            "label": "ARUTHRAA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3809",
            "district": "18",
            "Block": "236",
            "label": "PANIMALAR MATRICULATION HIGHER SECONDARY SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4812",
            "district": "18",
            "Block": "399",
            "label": "SWAMI VIVEKANANDA MATRIC HR. SEC. SCHOOL, ARUMBAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4866",
            "district": "18",
            "Block": "236",
            "label": "GOLDEN GATES MATRIC HIGHER SECONDARY SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4879",
            "district": "18",
            "Block": "400",
            "label": "ST.ANDREWS MATRICULATION HIGHER SECONDARY SCHOOL THIRUMANDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5350",
            "district": "18",
            "Block": "236",
            "label": "CHRISTIAN MATRIC HR.SEC SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6087",
            "district": "18",
            "Block": "7",
            "label": "HAYAGREEVA MATRICULATION   HIGHER SECONDARY SCHOOL NAKKASALEM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14729",
            "district": "18",
            "Block": "400",
            "label": "PUMS THIRUMANDURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14730",
            "district": "18",
            "Block": "400",
            "label": "GHS VADAKKALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14731",
            "district": "18",
            "Block": "400",
            "label": "GHSS OGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14732",
            "district": "18",
            "Block": "400",
            "label": "GHSS - S.ADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14733",
            "district": "18",
            "Block": "400",
            "label": "GHS KILUMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14734",
            "district": "18",
            "Block": "400",
            "label": "GHS ATHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14735",
            "district": "18",
            "Block": "400",
            "label": "PUMS AGARAM SEEGOOR-E",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14736",
            "district": "18",
            "Block": "400",
            "label": "Aided MS, Vayalur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14737",
            "district": "18",
            "Block": "400",
            "label": "PUMS VASISTAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14738",
            "district": "18",
            "Block": "400",
            "label": "PUMS MELAKALINGARANANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14739",
            "district": "18",
            "Block": "400",
            "label": "GHSS KEELAPERAMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14740",
            "district": "18",
            "Block": "400",
            "label": "GMS VELVIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14741",
            "district": "18",
            "Block": "400",
            "label": "PUMS-VAYALAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14742",
            "district": "18",
            "Block": "400",
            "label": "PUMS V.KEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14743",
            "district": "18",
            "Block": "400",
            "label": "PUMS KALLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14744",
            "district": "18",
            "Block": "400",
            "label": "PUMS-OLAIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14745",
            "district": "18",
            "Block": "400",
            "label": "GHSS VEPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14746",
            "district": "18",
            "Block": "400",
            "label": "RC STJOHN HS PERUMATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14747",
            "district": "18",
            "Block": "400",
            "label": "PUMS-LABBAIKUDIKADU-W",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14748",
            "district": "18",
            "Block": "400",
            "label": "SALAMATH M SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14749",
            "district": "18",
            "Block": "400",
            "label": "GGHSS LABBAIKUDIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14750",
            "district": "18",
            "Block": "400",
            "label": "GHSS(B)-LABBAIKUDIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14751",
            "district": "18",
            "Block": "400",
            "label": "HAJI ABDULA SHA MATRICULATION HIGH SCHOOL PENNAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14752",
            "district": "18",
            "Block": "400",
            "label": "GHSS THUNGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14753",
            "district": "18",
            "Block": "400",
            "label": "PANDIAN AIDED.MID.S.NALLARIKAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14754",
            "district": "18",
            "Block": "400",
            "label": "Chidambaram MS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14755",
            "district": "18",
            "Block": "400",
            "label": "PUMS KOTHAVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14756",
            "district": "18",
            "Block": "400",
            "label": "GHS VARAGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14757",
            "district": "18",
            "Block": "7",
            "label": "SARASWATHI VIDYALAYA HIGHER SECONDARY SCHOOL VARISAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14758",
            "district": "18",
            "Block": "400",
            "label": "GHS PARAVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14759",
            "district": "18",
            "Block": "400",
            "label": "TELC, Aykudi",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14760",
            "district": "18",
            "Block": "400",
            "label": "GHS ELUMUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14761",
            "district": "18",
            "Block": "400",
            "label": "GHS KARUGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14762",
            "district": "18",
            "Block": "400",
            "label": "PUMS ANDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14763",
            "district": "18",
            "Block": "400",
            "label": "ST.PAUL  MATRICULATION HIGHER SECONDARY SCHOOL  ANTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14764",
            "district": "18",
            "Block": "400",
            "label": "GHS  ASUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14765",
            "district": "18",
            "Block": "400",
            "label": "VAANPUGAL VALLUVAR HSS OTHIYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14766",
            "district": "18",
            "Block": "400",
            "label": "GHS OTHIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14767",
            "district": "18",
            "Block": "400",
            "label": "GBHSS KUNNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14768",
            "district": "18",
            "Block": "400",
            "label": "GGHSS KUNNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14769",
            "district": "18",
            "Block": "400",
            "label": "GHS - PERIYAMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14770",
            "district": "18",
            "Block": "400",
            "label": "GHSS-PERALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14771",
            "district": "18",
            "Block": "400",
            "label": "GHSS MARUVATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14772",
            "district": "18",
            "Block": "400",
            "label": "KASTHURBA GANDHI BALIKA VIDHYALAYA  SCHOOL  PERALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14773",
            "district": "18",
            "Block": "400",
            "label": "PUMS SITHALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14774",
            "district": "18",
            "Block": "400",
            "label": "GHS- PEELVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14775",
            "district": "18",
            "Block": "400",
            "label": "GHSS KEELAPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14776",
            "district": "18",
            "Block": "400",
            "label": "SWAMY VIVEKANANDA MATRICULATION HIGHER SECONDARY SCHOOL KEELAPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14777",
            "district": "18",
            "Block": "400",
            "label": "PUMS SIRUGUDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14778",
            "district": "18",
            "Block": "400",
            "label": "GHS MURUKKANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14779",
            "district": "18",
            "Block": "400",
            "label": "GHS KADUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14780",
            "district": "18",
            "Block": "399",
            "label": "PUMS VELLUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14781",
            "district": "18",
            "Block": "399",
            "label": "GHS KARIYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14782",
            "district": "18",
            "Block": "399",
            "label": "GHSS KAI.KALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14783",
            "district": "18",
            "Block": "399",
            "label": "MAHATHMA MATRICULATION HIGH SCHOOL KAIKALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14784",
            "district": "18",
            "Block": "399",
            "label": "GHS METTUPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14785",
            "district": "18",
            "Block": "399",
            "label": "PUMS-V.KALATHUR(G)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14786",
            "district": "18",
            "Block": "399",
            "label": "GHSS V. KALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14787",
            "district": "18",
            "Block": "399",
            "label": "HIDAYATH MATRICULATION  HIGH SCHOOL, V.KALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14788",
            "district": "18",
            "Block": "399",
            "label": "ST MARY'S HSS  AGARAM V.KALATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14789",
            "district": "18",
            "Block": "399",
            "label": "GHSS NERKUNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14790",
            "district": "18",
            "Block": "399",
            "label": "T.ROVER.H.S.NERUKUNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14791",
            "district": "18",
            "Block": "399",
            "label": "GHSS PASUMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14792",
            "district": "18",
            "Block": "399",
            "label": "ADWHS PASUMBALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14793",
            "district": "18",
            "Block": "399",
            "label": "PUMS, PILLANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14794",
            "district": "18",
            "Block": "399",
            "label": "PUMS, SIRUNILA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14795",
            "district": "18",
            "Block": "399",
            "label": "GHSS VALIKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14796",
            "district": "18",
            "Block": "399",
            "label": "ST.JOHN.M.SCHOOL,THIRUVALANDURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14797",
            "district": "18",
            "Block": "399",
            "label": "GHSS RANJANKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14798",
            "district": "18",
            "Block": "399",
            "label": "PUMS-AYYANPERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14799",
            "district": "18",
            "Block": "399",
            "label": "GHS VENPAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14800",
            "district": "18",
            "Block": "399",
            "label": "PUMS N.PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14801",
            "district": "18",
            "Block": "399",
            "label": "GHS NEIKUPAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14802",
            "district": "18",
            "Block": "399",
            "label": "GHS NOOTHAPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14803",
            "district": "18",
            "Block": "399",
            "label": "RC.M.S,ERAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14804",
            "district": "18",
            "Block": "399",
            "label": "TELC M S,ERAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14805",
            "district": "18",
            "Block": "399",
            "label": "NEHRU HSS ERAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14806",
            "district": "18",
            "Block": "399",
            "label": "GHSS ANUKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14807",
            "district": "18",
            "Block": "399",
            "label": "GHSS ARUMBAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14808",
            "district": "18",
            "Block": "399",
            "label": "SHANTHI NIKETHAN MATRICULATION HIGHER SECONDARY SCHOOL  ARUMBAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14809",
            "district": "18",
            "Block": "399",
            "label": "SRI RAGAVENDRA HR. SEC. SCHOOL, ARUMBAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14810",
            "district": "18",
            "Block": "399",
            "label": "PUMS UDUMBIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14811",
            "district": "18",
            "Block": "399",
            "label": "PUMS KALLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14812",
            "district": "18",
            "Block": "399",
            "label": "EDEN GARDENS MATRIC HR. SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14813",
            "district": "18",
            "Block": "399",
            "label": "EDEN GARDENS HR.SEC.SCHOOL UDUMBIAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14814",
            "district": "18",
            "Block": "399",
            "label": "PUMS, MOHAMEDPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14815",
            "district": "18",
            "Block": "399",
            "label": "PUMS,BRAMMADESAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14816",
            "district": "18",
            "Block": "399",
            "label": "GHSS POOLAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14817",
            "district": "18",
            "Block": "399",
            "label": "ST.MARY M.S, THONDAMANDURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14818",
            "district": "18",
            "Block": "399",
            "label": "PUMS, VIJAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14819",
            "district": "18",
            "Block": "399",
            "label": "GHS THONDAMANDURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14820",
            "district": "18",
            "Block": "399",
            "label": "GHSS VENKALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14821",
            "district": "18",
            "Block": "399",
            "label": "SHRI MATHURA MATRIC  SCHOOL KRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14822",
            "district": "18",
            "Block": "399",
            "label": "ANNAI MATRICULATION  HIGH SCHOOL  VENGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14823",
            "district": "18",
            "Block": "399",
            "label": "GHSS  VEPPANTHATTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14824",
            "district": "18",
            "Block": "399",
            "label": "PUMS, A.METTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14825",
            "district": "18",
            "Block": "399",
            "label": "GHS GOUNDERPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14826",
            "district": "18",
            "Block": "399",
            "label": "RC. M.SCHOOL.FATHIMAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14827",
            "district": "18",
            "Block": "399",
            "label": "LFHSS ANNAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14828",
            "district": "18",
            "Block": "236",
            "label": "GHSS PERAMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14829",
            "district": "18",
            "Block": "236",
            "label": "MOULANA HSS-PERAMBALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14830",
            "district": "18",
            "Block": "236",
            "label": "THANTHAI HANS ROEVER HSS-PERAMBALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14831",
            "district": "18",
            "Block": "236",
            "label": "TELC MID SCH THURAIMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14832",
            "district": "18",
            "Block": "236",
            "label": "ST.DOMINIC'S GIRLS HIGHER SECONDARY SCHOOL, PERAMBALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14833",
            "district": "18",
            "Block": "236",
            "label": "PANIMALAR HSS-PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14834",
            "district": "18",
            "Block": "236",
            "label": "SRI SARADHA DEVI GIRLS HR. SEC. SCHOOL - PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14835",
            "district": "18",
            "Block": "236",
            "label": "SRI RAMAKRISHNA GIRLS MATRICULATION HR.SEC SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14836",
            "district": "18",
            "Block": "236",
            "label": "THANTHAI ROEVER MATRICULATION HIGH SCHOOL  PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14837",
            "district": "18",
            "Block": "236",
            "label": "DHANALAKSHMI SRINIVASAN HSS-PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14838",
            "district": "18",
            "Block": "236",
            "label": "DHANALAKSHMI SRINIVASAN MATRICULATION HIGHER SECONDARY SCHOOL, PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14839",
            "district": "18",
            "Block": "236",
            "label": "KEN-VIDYALA-PERAMBALUR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14840",
            "district": "18",
            "Block": "236",
            "label": "GOWTHAMABUTHAR-PER",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14841",
            "district": "18",
            "Block": "236",
            "label": "HANS-ROVER-CBSE-PER",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14842",
            "district": "18",
            "Block": "236",
            "label": "ALMIGHTY VIDHYALAYA PUBLIC  SCHOOL SIRUVACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14843",
            "district": "18",
            "Block": "236",
            "label": "PUMS NOCHIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14844",
            "district": "18",
            "Block": "236",
            "label": "PUMS,K.ERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14845",
            "district": "18",
            "Block": "236",
            "label": "PUMS,ARANARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14846",
            "district": "18",
            "Block": "236",
            "label": "RCMS-PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14847",
            "district": "18",
            "Block": "236",
            "label": "GHS-THAMBIRANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14848",
            "district": "18",
            "Block": "236",
            "label": "GHSS-KAVULPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14849",
            "district": "18",
            "Block": "236",
            "label": "GHSS CHATHIRAMANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14850",
            "district": "18",
            "Block": "236",
            "label": "GHSS_KURUMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14851",
            "district": "18",
            "Block": "236",
            "label": "GHSS-SIRUVACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14852",
            "district": "18",
            "Block": "236",
            "label": "GHS-VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14853",
            "district": "18",
            "Block": "236",
            "label": "PUMS,BOMMANAPADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14854",
            "district": "18",
            "Block": "236",
            "label": "PUMS,AYYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14855",
            "district": "18",
            "Block": "236",
            "label": "GHS_KALPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14856",
            "district": "18",
            "Block": "236",
            "label": "PUMS, ARUMADAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14857",
            "district": "18",
            "Block": "236",
            "label": "PUMS-ESANAI(N)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14858",
            "district": "18",
            "Block": "236",
            "label": "GHSS ESANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14859",
            "district": "18",
            "Block": "236",
            "label": "PUMS,KONERIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14860",
            "district": "18",
            "Block": "236",
            "label": "GHS LADAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14861",
            "district": "18",
            "Block": "236",
            "label": "ADWHS-LADAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14862",
            "district": "18",
            "Block": "236",
            "label": "GHSS_MELAPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14863",
            "district": "18",
            "Block": "236",
            "label": "PUMS THIRUPEYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14864",
            "district": "18",
            "Block": "236",
            "label": "GHSS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14865",
            "district": "18",
            "Block": "236",
            "label": "MRV-AMMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14866",
            "district": "18",
            "Block": "236",
            "label": "ADWHSS-KALARAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14867",
            "district": "18",
            "Block": "236",
            "label": "TRHS-INDIRA NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14868",
            "district": "18",
            "Block": "236",
            "label": "GHSS-ELAMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14869",
            "district": "18",
            "Block": "236",
            "label": "PUMS, SENJERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14870",
            "district": "18",
            "Block": "236",
            "label": "ADWHS-ALAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14871",
            "district": "18",
            "Block": "236",
            "label": "GHS-SENGUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14912",
            "district": "18",
            "Block": "7",
            "label": "GHSS  THENUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14913",
            "district": "18",
            "Block": "7",
            "label": "GHS T. KALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14914",
            "district": "18",
            "Block": "7",
            "label": "PUMS PUDUAMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14915",
            "district": "18",
            "Block": "7",
            "label": "GHSS NAKASELAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14916",
            "district": "18",
            "Block": "7",
            "label": "ARIVALAYAM HS NAKKASELAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14917",
            "district": "18",
            "Block": "7",
            "label": "VALLALAR AIDED MIDDLE SCHOOL, CHETTIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14918",
            "district": "18",
            "Block": "7",
            "label": "GHSS CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14919",
            "district": "18",
            "Block": "7",
            "label": "PUMS NATTARMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14920",
            "district": "18",
            "Block": "7",
            "label": "PUMS MARUTHADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14921",
            "district": "18",
            "Block": "7",
            "label": "PUMS,VIJAYAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14922",
            "district": "18",
            "Block": "7",
            "label": "GHS IRUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14923",
            "district": "18",
            "Block": "7",
            "label": "MURUGAN AID. MIS. NARANAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14924",
            "district": "18",
            "Block": "7",
            "label": "PUMS,VARAGUPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14925",
            "district": "18",
            "Block": "7",
            "label": "ADWHSS NATHAKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14926",
            "district": "18",
            "Block": "7",
            "label": "GHSS KARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14927",
            "district": "18",
            "Block": "7",
            "label": "PUMS KANNAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14928",
            "district": "18",
            "Block": "7",
            "label": "PUMS,PUDHUVIRALIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14929",
            "district": "18",
            "Block": "7",
            "label": "PUMS,SIRUVAYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14930",
            "district": "18",
            "Block": "7",
            "label": "PUMS, SIRUGANPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14931",
            "district": "18",
            "Block": "7",
            "label": "PUMS SATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14932",
            "district": "18",
            "Block": "7",
            "label": "NETHAJI AID.MS.ADDAIKAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14933",
            "district": "18",
            "Block": "7",
            "label": "GHSS PADALUR -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14934",
            "district": "18",
            "Block": "7",
            "label": "ST. FR. MAT. PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14935",
            "district": "18",
            "Block": "7",
            "label": "SREE AMBAALS MATRIC HR SEC SCHOOL - PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14936",
            "district": "18",
            "Block": "7",
            "label": "ANNAI HSS PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14937",
            "district": "18",
            "Block": "7",
            "label": "PERIYAR M S, PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14938",
            "district": "18",
            "Block": "7",
            "label": "PUMS, THIRUVILAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14939",
            "district": "18",
            "Block": "7",
            "label": "GHSS KOLAKKANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14940",
            "district": "18",
            "Block": "7",
            "label": "PUMS, THIMMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14941",
            "district": "18",
            "Block": "7",
            "label": "GHS SILLAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14942",
            "district": "18",
            "Block": "7",
            "label": "PUMS, METHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14943",
            "district": "18",
            "Block": "7",
            "label": "PUMS, RAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14944",
            "district": "18",
            "Block": "7",
            "label": "PUMS, NOCHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14945",
            "district": "18",
            "Block": "7",
            "label": "PUMS MAKKAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14946",
            "district": "18",
            "Block": "7",
            "label": "PUMS, PILIMISAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14947",
            "district": "18",
            "Block": "7",
            "label": "PUMS ELANTHANGUZHI (W)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14948",
            "district": "18",
            "Block": "7",
            "label": "PUMS,KEEZHAMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14949",
            "district": "18",
            "Block": "7",
            "label": "PUMS,ALLINAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14950",
            "district": "18",
            "Block": "7",
            "label": "GHS KOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14951",
            "district": "18",
            "Block": "7",
            "label": "GHS ZAMINE ATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14952",
            "district": "18",
            "Block": "7",
            "label": "GHS GUDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14953",
            "district": "18",
            "Block": "7",
            "label": "GHS- ZAMINPERAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14954",
            "district": "18",
            "Block": "7",
            "label": "GHS ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14955",
            "district": "18",
            "Block": "7",
            "label": "RAJAVIGNESH HSS MELAMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14956",
            "district": "18",
            "Block": "7",
            "label": "GHS MELAMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14957",
            "district": "18",
            "Block": "7",
            "label": "GHSS KOOTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21165",
            "district": "18",
            "Block": "399",
            "label": "LABBAIKUDIKADU WISDOM MATRIC HR.SEC.SCHOOL AYANPERAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21190",
            "district": "18",
            "Block": "400",
            "label": "GHSS MODEL SCHOOL KILUMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21380",
            "district": "18",
            "Block": "400",
            "label": "GHS NANNAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21381",
            "district": "18",
            "Block": "236",
            "label": "GHS VADAKKUMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21513",
            "district": "18",
            "Block": "400",
            "label": "ADWHS OGALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21514",
            "district": "18",
            "Block": "236",
            "label": "ADWHS BOMMANAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21515",
            "district": "18",
            "Block": "236",
            "label": "ADWHS EACHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21516",
            "district": "18",
            "Block": "7",
            "label": "ADWHS PADALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21550",
            "district": "18",
            "Block": "400",
            "label": "VETRI HIGH SCHOOL AGARAM SEEGOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21587",
            "district": "18",
            "Block": "236",
            "label": "GREENPARK MATRICULATION HIGHER SECONDARY SCHOOL SIRUVACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21605",
            "district": "18",
            "Block": "236",
            "label": "MARAGATHAAM MATRIC HR SEC SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21754",
            "district": "18",
            "Block": "236",
            "label": "GOLDEN GATES VIDHYASHRAM SR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21755",
            "district": "18",
            "Block": "7",
            "label": "VARADHA WIKAS PUBLIC SCHOOL VARASAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21756",
            "district": "18",
            "Block": "400",
            "label": "IMMANUVEL HIGH SCHOOL KUNNAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22194",
            "district": "18",
            "Block": "399",
            "label": "GTRHS MALAIYALAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22538",
            "district": "18",
            "Block": "400",
            "label": "GHS PUDHUVETTAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22539",
            "district": "18",
            "Block": "399",
            "label": "GHS PERIYAMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22540",
            "district": "18",
            "Block": "236",
            "label": "RAMAKRISHNA VIDHYABHAVAN CBSE PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22542",
            "district": "18",
            "Block": "7",
            "label": "GHS THERANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22858",
            "district": "18",
            "Block": "400",
            "label": "GANDHIRAM HIGH SCHOOL PUDHUVETTAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22900",
            "district": "18",
            "Block": "7",
            "label": "GHS KOTTARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22973",
            "district": "18",
            "Block": "236",
            "label": "GHS (G) PERAMBALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23013",
            "district": "18",
            "Block": "236",
            "label": "SRI RAMAKRISHNA BOYS MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23031",
            "district": "18",
            "Block": "399",
            "label": "IDEAL MATRICULATION SCHOOL , V.KALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23085",
            "district": "18",
            "Block": "7",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Malayappa nagar",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23133",
            "district": "18",
            "Block": "399",
            "label": "ST JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23294",
            "district": "18",
            "Block": "400",
            "label": "MAHATMA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23296",
            "district": "18",
            "Block": "7",
            "label": "SARASWATHI VIDYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23523",
            "district": "18",
            "Block": "7",
            "label": "ANNAI MATRICULATION SCHOOL PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23524",
            "district": "18",
            "Block": "236",
            "label": "MALAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23525",
            "district": "18",
            "Block": "236",
            "label": "SRI MUTHAIYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23526",
            "district": "18",
            "Block": "236",
            "label": "AADHAV PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "23527",
            "district": "18",
            "Block": "399",
            "label": "PERAMBALUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23715",
            "district": "18",
            "Block": "7",
            "label": "GHS GUDALUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23916",
            "district": "18",
            "Block": "236",
            "label": "GHSS AMMAPALAYAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24069",
            "district": "18",
            "Block": "399",
            "label": "St MARY'S MATRIC HR SEC SCHOOL  V.KALATHUR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24070",
            "district": "18",
            "Block": "399",
            "label": "PUMS, VIJAYAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24071",
            "district": "18",
            "Block": "399",
            "label": "ST JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24072",
            "district": "18",
            "Block": "399",
            "label": "PERAMBALUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24073",
            "district": "18",
            "Block": "399",
            "label": "St.MARY'S PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24074",
            "district": "18",
            "Block": "399",
            "label": "SPRING FIELD PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "19": [
        {
            "key": "23355",
            "district": "19",
            "Block": "22",
            "label": "Excellent nursery & primary( private) school",
            "Sch_type": "Un-aided",
            "Category": "Primary School"
        },
        {
            "key": "23356",
            "district": "19",
            "Block": "22",
            "label": "Swamy Vivekananda nursery & primary( private) school",
            "Sch_type": "Un-aided",
            "Category": "Primary School"
        },
        {
            "key": "380",
            "district": "19",
            "Block": "339",
            "label": "MARY IMMACULATE MATRIC SCHOOL,K.RASIAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "717",
            "district": "19",
            "Block": "22",
            "label": "ANNAI MEENATCHI NACHIAR MATRIC HR SEC SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "752",
            "district": "19",
            "Block": "167",
            "label": "M.H. MATRIC HR. SEC. SCHOOL, KOTTAIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "824",
            "district": "19",
            "Block": "22",
            "label": "CRESCENT MATRIC SCHOOL, RAJENDRAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "831",
            "district": "19",
            "Block": "339",
            "label": "ADR MATRIC HR.SEC.SCHOOL,KAIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "835",
            "district": "19",
            "Block": "25",
            "label": "SRI SIVAAKAMALAM MATRIC ARIMALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "847",
            "district": "19",
            "Block": "18",
            "label": "PONMARI VID MATRIC,POMMADIMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "905",
            "district": "19",
            "Block": "326",
            "label": "M.R.P MATRIC SCHOOL, P.ALAGAPURI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "932",
            "district": "19",
            "Block": "22",
            "label": "SELECTION MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "949",
            "district": "19",
            "Block": "405",
            "label": "PARUMALA ST.GREGORIOS MATRIC  SCHOOL, MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "983",
            "district": "19",
            "Block": "25",
            "label": "SASTHAA MATRICULATION SCHOOL, KEELANILAIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1048",
            "district": "19",
            "Block": "167",
            "label": "CRESCENT MAT. SCHOOL, AMMAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1050",
            "district": "19",
            "Block": "108",
            "label": "GUTHOOS MATRIC SCHOOL, KARAMBAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1052",
            "district": "19",
            "Block": "22",
            "label": "UMAIYALACHI MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1058",
            "district": "19",
            "Block": "251",
            "label": "IDHAYA MATRICULATION HIGHER SECONDARY SCHOOL , KARAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1105",
            "district": "19",
            "Block": "255",
            "label": "S.F.S. MATRIC HR.SEC.SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1115",
            "district": "19",
            "Block": "22",
            "label": "ST. JOHN'S MATRIC HR. SEC. SCHOOL, RETHINAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1207",
            "district": "19",
            "Block": "405",
            "label": "VIVEGA MATRIC HIGHER SECONDARY SCHOOL. VIRALIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1245",
            "district": "19",
            "Block": "326",
            "label": "SRI LAKSHMI NARAYANA MATRIC SCHOOL, KURUMBAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1256",
            "district": "19",
            "Block": "33",
            "label": "POPULAR MATRIC HR. SEC. SCHOOL MIMISAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1270",
            "district": "19",
            "Block": "326",
            "label": "ST.JOSEPH MATRIC SCHOOL, VIRACHILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1273",
            "district": "19",
            "Block": "255",
            "label": "GUTHOOS MATRIC.HR SECONDARY SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1353",
            "district": "19",
            "Block": "33",
            "label": "ST.JOSEPHS MATRIC SCHOOL AVUDAIYARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1369",
            "district": "19",
            "Block": "339",
            "label": "ABIRAMI MATRICULATION SCHOOL ALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1383",
            "district": "19",
            "Block": "251",
            "label": "AMALA ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, PON-PUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1404",
            "district": "19",
            "Block": "67",
            "label": "VIDYA VIKAS MATRIC HIGHER SEC SCHOOL, PUDUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1467",
            "district": "19",
            "Block": "108",
            "label": "REENA MERCY MATRIC SCHOOL, KARAMBAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1473",
            "district": "19",
            "Block": "22",
            "label": "THAAYAGAM MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1479",
            "district": "19",
            "Block": "22",
            "label": "NATIONAL MATRIC HR SEC SCHOOL,ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1520",
            "district": "19",
            "Block": "326",
            "label": "ST.JOSEPH MATRIC SCHOOL, THIRUMAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1677",
            "district": "19",
            "Block": "251",
            "label": "ST.JOSEPH MATRICULATION SCHOOL, VENTHANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1833",
            "district": "19",
            "Block": "251",
            "label": "NEHRU MATRIC HIGHER SECONDARY SCHOOL, M.USILAMPATTI,MELATHANIYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1883",
            "district": "19",
            "Block": "251",
            "label": "MEIYAPPA  VALLIAMMAI  KANCHI  SANKARA VIDYALAYA MATRICULATION  SCHOOL,VALAYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1993",
            "district": "19",
            "Block": "255",
            "label": "SRI VENKATESWARA MAT.HR.SEC.SCHOOL, THIRUKOKARNAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2120",
            "district": "19",
            "Block": "326",
            "label": "ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, N.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2187",
            "district": "19",
            "Block": "18",
            "label": "INFANTJESUS MAT.HSS.ANNAVASAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2905",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. VILANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3132",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KULAVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3551",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3883",
            "district": "19",
            "Block": "25",
            "label": "KARPAGA VINAYAGA MATRICULATION HIGHER SECONDARY SCHOOL, SIVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4467",
            "district": "19",
            "Block": "255",
            "label": "THE LITTLE ANGELS JAYARANI MATRICULATION HIGHER SECONDARY  SCHOOL, PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4744",
            "district": "19",
            "Block": "251",
            "label": "LIONS MATRICULATION HIGHER SECONDARY SCHOOL, PONNAMARAVATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4823",
            "district": "19",
            "Block": "67",
            "label": "ARULMARY MAT.SCHOOL, GANDARVAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4836",
            "district": "19",
            "Block": "22",
            "label": "MAEIL MATRICULATION SCHOOL,ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4909",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MERPANAIKKADU EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4912",
            "district": "19",
            "Block": "339",
            "label": "MODERN MATRIC HR.SEC. SCHOOL ,ALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4919",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THEKKIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5132",
            "district": "19",
            "Block": "326",
            "label": "KING JESUS MATRIC SCHOOL, THIRUMAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5308",
            "district": "19",
            "Block": "255",
            "label": "MOUNTZION MATRIC  HR SEC SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5364",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KODIVAYAL EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5409",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALAPPIRANTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5434",
            "district": "19",
            "Block": "251",
            "label": "CHIDAMBARAM MATRICULATION HIGHER SECONDARY, VALAYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5437",
            "district": "19",
            "Block": "33",
            "label": "NEW SANGGEETH MATRIC HR. SEC. SCHOOL, MIMISAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5449",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.OORAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5547",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MEBOOTHAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5588",
            "district": "19",
            "Block": "326",
            "label": "ST.SEBASTIAR MATRIC SCHOOL, KATTUBAVAPALLIVASAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5631",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PORUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5704",
            "district": "19",
            "Block": "251",
            "label": "SRI AMBAL MATRICULATION SCHOOL, KOPPANAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5871",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AYINGUDI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5913",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVANATHANKOTTAI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5941",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PIRATHABIRAMANPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6042",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAYAKKARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6072",
            "district": "19",
            "Block": "167",
            "label": "SRI JAGADEESHWARA MAT. HR. SEC. SCHOOL, MANAMELKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6078",
            "district": "19",
            "Block": "255",
            "label": "SUDHARSAN VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL ,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6158",
            "district": "19",
            "Block": "18",
            "label": "PSK MATRIC HIGHER SECONDARY SCHOOL, POMMADIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6218",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , EDAIYANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6224",
            "district": "19",
            "Block": "255",
            "label": "BHARATH MATRIC. HR.SEC.SCHOOL, THIRUVAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6345",
            "district": "19",
            "Block": "22",
            "label": "NAZREEN MATRIC SCHOOL, RETHINAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6448",
            "district": "19",
            "Block": "33",
            "label": "ST.JOESEPH'S MID.SCH. AVUDAIYARKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6451",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PACHALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6488",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERIYALUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6519",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL K.THERKUTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6616",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6703",
            "district": "19",
            "Block": "25",
            "label": "SRI MANICKAM MAT H S SIVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16759",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANNANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16760",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL MELAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16761",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL THEMMAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16762",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16763",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAKKATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16764",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL UDAIYALIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16765",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VALIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16766",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ODUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16767",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOSAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16768",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL UPPILIYAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16769",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENAIYAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16770",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL VAITHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16771",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARANIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16772",
            "district": "19",
            "Block": "147",
            "label": "R.C.RAWUSAPPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16773",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL KULATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16774",
            "district": "19",
            "Block": "147",
            "label": "MUTHUSWAMY VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL KULATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16775",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL LEKKANAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16776",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ILLUPPAKKUDIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16777",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ANDAKKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16778",
            "district": "19",
            "Block": "147",
            "label": "VSA NURSERY AND PRIMARY SCHOOL ANDAKKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16779",
            "district": "19",
            "Block": "147",
            "label": "ST.ANTONY MIDDLE SCHOOL KILLUKKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16780",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KILLUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16781",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUMALAIRAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16782",
            "district": "19",
            "Block": "147",
            "label": "ST.JOSEPH HIGH SCHOOL THIRUMALAIRAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16783",
            "district": "19",
            "Block": "147",
            "label": "PUNITHA SAHAYAMATHA MIDDLE SCHOOL KEERANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16784",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16785",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16786",
            "district": "19",
            "Block": "147",
            "label": "PUNITHA AROCKIYA ANNAI HIGHER SECONDARY SCHOOL KEERANUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16787",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUNDRANDARKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16788",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VATHANAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16789",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ODUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16790",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16791",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALLATHUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16792",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAPPUDAIYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16793",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL,THIRUNALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16794",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MURUGAKONPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16795",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16796",
            "district": "19",
            "Block": "18",
            "label": "ST.IGNATIUS.HS -SATHAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16797",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHIRAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16798",
            "district": "19",
            "Block": "18",
            "label": "GHSS,MALAIKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16799",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ENNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16800",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.MELAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16801",
            "district": "19",
            "Block": "18",
            "label": "ADWHS.ENNAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16802",
            "district": "19",
            "Block": "18",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL,ILLUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16803",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VETTUKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16804",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ERUKKUMANIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16805",
            "district": "19",
            "Block": "18",
            "label": "GOVT HR SEC SCHOOL, RAPPUSAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16806",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.KEELAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16807",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY  SCHOOL,NARTHAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16808",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.KOTHAMANGALAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16809",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.CAUVERYNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16810",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.POONGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16811",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL VADASERIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16812",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY  SCHOOL.SATHIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16813",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.KALADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16814",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.VEERAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16815",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.EDAYAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16816",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16817",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL.KILIKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16818",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.PARAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16819",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.P.PANAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16820",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.KULAVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16821",
            "district": "19",
            "Block": "18",
            "label": "GOVT HR SEC SCHOOL. MANNAVELAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16822",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL.MATHIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16823",
            "district": "19",
            "Block": "18",
            "label": "GHSS.MARUTHANTHALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16824",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL .T.METTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16825",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.PERUNJUNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16826",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.AGARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16827",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.VAYALOGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16828",
            "district": "19",
            "Block": "18",
            "label": "ST.ANNE'S HS MUTHALIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16829",
            "district": "19",
            "Block": "18",
            "label": "GOVERNMENT HIGH SCHOOL.KUDUMIYANMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16830",
            "district": "19",
            "Block": "18",
            "label": "RC.HIGHER SECONDARY SCHOOL,ILLUPPUR.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16831",
            "district": "19",
            "Block": "18",
            "label": "GOVT.HR.SEC.SCHOOL,ILLUPPUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16832",
            "district": "19",
            "Block": "18",
            "label": "ARULMALAR MATRIC SCHOOL,ILLUPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16833",
            "district": "19",
            "Block": "18",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL,ILLUPPUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16834",
            "district": "19",
            "Block": "18",
            "label": "MOTHER TERASA MATRIC.METTUSALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16835",
            "district": "19",
            "Block": "18",
            "label": "VAIRAMS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16836",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ESWARANKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16837",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHITHANNAVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16838",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.ANNAVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16839",
            "district": "19",
            "Block": "18",
            "label": "KOKILA MATRICULATION SCHOOL, ANNAVASAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16840",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.KATHAVAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16841",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL.MELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16842",
            "district": "19",
            "Block": "18",
            "label": "GOVT HSS SCHOOL.MUKKANAMALAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16843",
            "district": "19",
            "Block": "18",
            "label": "MAJESTIC MATRIC MUKKANAMALAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16844",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, LANCHAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16845",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL, KALKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16846",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KODUMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16847",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYT UNION MIDDLE SCHOOL PILIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16848",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16849",
            "district": "19",
            "Block": "405",
            "label": "ST.THOMAS HIGHER SECONDARY SCHOOL  NAZARETH",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16850",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,LAKSHMANANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16851",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANDAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16852",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16853",
            "district": "19",
            "Block": "405",
            "label": "ST.NORBERT R.C. MIDDLE SCHOOL. MATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16854",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(SPL) MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16855",
            "district": "19",
            "Block": "405",
            "label": "SACRED HEART SCHOOL CBSE  MATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16856",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL MEENAVELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16857",
            "district": "19",
            "Block": "405",
            "label": "PREMANANDA HIGH SCHOOL MELAPATCHKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16858",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NAMBAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16859",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, KAVARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16860",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL NEERPALANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16861",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. SEETHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16862",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SEVALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16863",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAJAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16864",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL, RAJALIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16865",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ,SOORIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16866",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. THENGAITHINNIPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16867",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENNAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16868",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THERAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16869",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16870",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NARIAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16871",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VILLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16872",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, VIRALIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16873",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(B) VIRALIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16874",
            "district": "19",
            "Block": "405",
            "label": "ST TERESA'S GIRLS HIGH SCHOOL VIRALIMALAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16875",
            "district": "19",
            "Block": "405",
            "label": "PROVIDENCE CONVENT MATRICULATION SCHOOL, VIRALIMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16876",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. RAMAKKAVUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16877",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYT UNION MIDDLE SCHOOL SERALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16878",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, AVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16879",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16880",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THONDAIMANNALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16881",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SOLAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16882",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VIRALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16883",
            "district": "19",
            "Block": "67",
            "label": "ST MARY'S HIGHER SECONDARY  SCHOOL, THACHANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16884",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16885",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANJAPPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16886",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL, KOMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16887",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIYANIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16888",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL, PUDUNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16889",
            "district": "19",
            "Block": "67",
            "label": "PUMS-PALAYAGANDARVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16890",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MID SCHOOL, MEIKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16891",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VEERADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16892",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUDUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16893",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL, KULATHURNAYAKKARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16894",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16895",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MOHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16896",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHAGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16897",
            "district": "19",
            "Block": "67",
            "label": "PUMS. VEMBANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16898",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTUNAVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16899",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16900",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SANGAMVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16901",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,KALLAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16902",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL,VELLALAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16903",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16904",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL, VELADIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16905",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KENDAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16906",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16907",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MATTANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16908",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT GIRLS HR SEC SCHOOL, GANDARVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16909",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT BOYS HR SEC SCHOOL, GANDARVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16910",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GANDARVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16911",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MULLIKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16912",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AKKACHIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16913",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAGATTUVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16914",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KARUPPUDAIYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16915",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALLUKKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16916",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SIRUNANGUPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16917",
            "district": "19",
            "Block": "255",
            "label": "LITTLE.FLOWER UPPER PRIMARY,T.M.R.SAMUTHIRAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16918",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUMMUPPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16919",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT MODEL HR SECONDARY SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16920",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGH SCHOOL,MULLUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16921",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOOKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16922",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL M.KULAVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16923",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGHER SECONDARY SCHOOL,A.MATHUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16924",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KAMMANGADU.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16925",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MELAPPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16926",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,MANAVIDUTHI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16927",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGH SCHOOL,BALAN NAGAR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16928",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,ELLAIPPATTI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16929",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUNGONDANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16930",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL PERUNGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16931",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PUTHAMBUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16932",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16933",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,SAMMATTIVIDUTHI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16934",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,RAJAKULATHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16935",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,SEMBATTUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16936",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VANNARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16937",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,VARAPPUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16938",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SOUTHCHETTIYAPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16939",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VADAVALAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16940",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL, KAYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16941",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,RAJAPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16942",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL, VAGAVASAL.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16943",
            "district": "19",
            "Block": "255",
            "label": "MAI SKOOL,VAGAVASAL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16944",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VALAVAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16945",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,ASHOKNAGAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16946",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,THAILANAGAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16947",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ADANAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16948",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ADANAKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16949",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SOTHUPALAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16950",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GANAPATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16951",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SOUTH THONDAIMANOORANI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16952",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUPPAYANPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16953",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,AGARAPPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16954",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL, MACHUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16955",
            "district": "19",
            "Block": "255",
            "label": "GOVT GIRLS HR.SEC SCHOOL SANDAIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16956",
            "district": "19",
            "Block": "255",
            "label": "GOVT UPPER PRIMARY SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16957",
            "district": "19",
            "Block": "255",
            "label": "TELC HIGHER SECONDARY SCHOOL, PUDUKKOTTAI,",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16958",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT MIDDLE SCHOOL FOR HEARING IMPAIRED,PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16959",
            "district": "19",
            "Block": "255",
            "label": "TELC MIDDLE SCHOOL, PUDUKKOTTAI,",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16960",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL,KOILPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16961",
            "district": "19",
            "Block": "255",
            "label": "GOVT GIRLS HIGH SCHOOL, THIRUKKOKARANAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16962",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGHER SECONDARY SCHOOL, THIRUKKOKARANAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16963",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL.MID.S.SANDAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16964",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL HIGH SCHOOL,THIRUVAPPUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16965",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL HIGH SCHOOL, RAJAGOPALAPURAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16966",
            "district": "19",
            "Block": "255",
            "label": "HASANATHULJARIA MIDDLE SCHOOL PUDUKKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16967",
            "district": "19",
            "Block": "255",
            "label": "SAMSKRITA.VID.ORI.HIGH SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16968",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL HIGH SCHOOL, KAMARAJAPURAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16969",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL,BOSENAGAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16970",
            "district": "19",
            "Block": "255",
            "label": "SRI.SUBBARAMAIYER MIDDLE SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16971",
            "district": "19",
            "Block": "255",
            "label": "RANEES' GOVT GIRLS HR.SEC SCHOOL,PUDUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16972",
            "district": "19",
            "Block": "255",
            "label": "RANEES GOVT GIRLS HIGH SCHOOL, PUDUKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16973",
            "district": "19",
            "Block": "255",
            "label": "GOVT.MIDDLE SCHOOL FOR THE VISUALLY IMPAIRED,PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16974",
            "district": "19",
            "Block": "255",
            "label": "RC MIDDLE SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16975",
            "district": "19",
            "Block": "255",
            "label": "SRI KULAPATHI BALAIAH HR SEC SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16976",
            "district": "19",
            "Block": "255",
            "label": "ST.MARY'S HIGHER SEC SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16977",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGH SCHOOL, GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16978",
            "district": "19",
            "Block": "255",
            "label": "SACRED HEART GIRLS HR.SEC SCHOOL, PUDUKKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16979",
            "district": "19",
            "Block": "255",
            "label": "NIZAM ORIENTAL  ARABIC HIGH SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16980",
            "district": "19",
            "Block": "255",
            "label": "SRI RAJARAJESWARI MATRIC  SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16981",
            "district": "19",
            "Block": "255",
            "label": "SRI BRAGATHANBAL GOVT HR SEC SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16982",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL,SANTHANATHAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16983",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL, PILLAITHANEERPANTHAL.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16984",
            "district": "19",
            "Block": "255",
            "label": "VAIRAMS MATRIC HIGHER SEC SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16985",
            "district": "19",
            "Block": "255",
            "label": "NEHRU MATRIC SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16986",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL,MALAIYEEDU.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16987",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PATCHIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16988",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAPPANVIDUTHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16989",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,K.V.KOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16990",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUMMANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16991",
            "district": "19",
            "Block": "339",
            "label": "ST.ANTONYS MIDDLE SCHOOL,ARASADIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16992",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SIKKAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16993",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16994",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16995",
            "district": "19",
            "Block": "339",
            "label": "ST.IGNATIOUS MATRIC HR.SEC.SCHOOL,VELLAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16996",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VADAKKUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16997",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,VADAKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16998",
            "district": "19",
            "Block": "339",
            "label": "THANGAM MATRICULATION HIGHER SECONDARY  SCHOOL,VADAKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16999",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,PULLANVIDUTHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17000",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,THIRUVARANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17001",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,NEDUVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17002",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,L.N.PURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17003",
            "district": "19",
            "Block": "339",
            "label": "GOVT BOYS HIGHER SECONDARY SCHOOL,KEERAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17004",
            "district": "19",
            "Block": "339",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL,KEERAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17005",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KASIMPUTHUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17006",
            "district": "19",
            "Block": "339",
            "label": "GOVT.HIGH SCHOOL,KULAMANGALAM-NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17007",
            "district": "19",
            "Block": "339",
            "label": "GOVT.HIGH SCHOOL,KULAMANGALAM-SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17008",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,SENTHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17009",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,MANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17010",
            "district": "19",
            "Block": "339",
            "label": "PUMS, KOTHAMANGALAM PALAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17011",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL,KOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17012",
            "district": "19",
            "Block": "339",
            "label": "GOVT.GIRLS HR.SEC.SCHOOL KOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17013",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,SOORANVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17014",
            "district": "19",
            "Block": "339",
            "label": "SRI SUBA BHARATHI MATRIC SCHOOL,KALLALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17015",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,PALLATHIVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17016",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17017",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ,KALYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17018",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,KOTHAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17019",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,MANJANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17020",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MANGANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17021",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VEPPANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17022",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL IMMANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17023",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUKKATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17024",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,METTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17025",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KAIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17026",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL POOVARASAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17027",
            "district": "19",
            "Block": "339",
            "label": "RTA.GOVT.HIGHER SECONDARY SCHOOL,VALLATHIRAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17028",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATHAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17029",
            "district": "19",
            "Block": "339",
            "label": "ST.JOSEPH HR.SEC.SCHOOL,VENKADAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17030",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,VENNAVALKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17031",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,ARAYAPPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17032",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL K.RASIAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17033",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SERIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17034",
            "district": "19",
            "Block": "339",
            "label": "P.P.M HIGH SCHOOL,KARAMBAKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17035",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANANKULAM-NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17036",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17037",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL S.KULAVAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17038",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL KEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17039",
            "district": "19",
            "Block": "339",
            "label": "PUNITHA ARPUTHA MATHA MIDDLE SCHOOL,ALANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17040",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17041",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL,ALANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17042",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL,ALANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17043",
            "district": "19",
            "Block": "339",
            "label": "HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL,ALANGUDI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17044",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THATTAMANAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17045",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17046",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, INNANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17047",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PATHUTHAKKU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17048",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAPPAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17049",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKOTTAIVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17050",
            "district": "19",
            "Block": "108",
            "label": "GOVT HIGH SCHOOL, KILANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17051",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HR.SEC.SCHOOL, REGUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17052",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HR.SEC.SCHOOL, KALIYARAYANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17053",
            "district": "19",
            "Block": "108",
            "label": "JEVANJOTHI.HR.SEC.SCHOOL, KONAKOLLAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17054",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL, BANTHUVAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17055",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAYT UNION MIDDLE SCHOOL MYLANKONEPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17056",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARAMBAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17057",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEVVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17058",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PILAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17059",
            "district": "19",
            "Block": "108",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL, PILAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17060",
            "district": "19",
            "Block": "108",
            "label": "PUMS.RANGIYANVIDUTHI-NEW",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17061",
            "district": "19",
            "Block": "108",
            "label": "GOVT.BOYS HR.SEC.SCHOOL, KARAMBAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17062",
            "district": "19",
            "Block": "108",
            "label": "GOVT.GIRLS.HR.SEC.SCHOOL,KARAMBAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17063",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOLAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17064",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL, PALLAVARAYANPATHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17065",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ELAIKADIVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17066",
            "district": "19",
            "Block": "108",
            "label": "GHS.THIRUMANANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17067",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARUKKAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17068",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KURUMBIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17069",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VANAKKANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17070",
            "district": "19",
            "Block": "108",
            "label": "GOVT ADW HR SEC SCHOOL MULLANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17071",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADUKKAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17072",
            "district": "19",
            "Block": "108",
            "label": "PUMS.KANNAKKANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17073",
            "district": "19",
            "Block": "108",
            "label": "GHSS.VETTANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17074",
            "district": "19",
            "Block": "108",
            "label": "PUMS.KRISHNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17075",
            "district": "19",
            "Block": "108",
            "label": "PUMS.MEENAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17076",
            "district": "19",
            "Block": "108",
            "label": "GHS.MOLUDAIYANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17077",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HR.SEC.SCHOOL, MALAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17078",
            "district": "19",
            "Block": "108",
            "label": "CHRIST THE KING MATRICULATION SCHOOL,SELVANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17079",
            "district": "19",
            "Block": "108",
            "label": "PUMS, NAINANKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17080",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL, PONNANVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17081",
            "district": "19",
            "Block": "108",
            "label": "PUMS, NAMBANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17082",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL, KEELAPATTI (M)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17083",
            "district": "19",
            "Block": "108",
            "label": "PUMS, ODAPPAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17084",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17085",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL,AMBUKKOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17086",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELLALAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17087",
            "district": "19",
            "Block": "108",
            "label": "PUMS, THEETHANIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17088",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,PILLAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17089",
            "district": "19",
            "Block": "326",
            "label": "G.G.H.S.S.P.ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17090",
            "district": "19",
            "Block": "326",
            "label": "PICHAIYAPPA MIDDLE SCHOOL UNAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17091",
            "district": "19",
            "Block": "326",
            "label": "SRI SIVAKAMI AMBAL HIGH SCHOOL, RANGIEM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17092",
            "district": "19",
            "Block": "326",
            "label": "SRI.PR.M.MEYYAPPA CHETTIYAR MIDDLE SCHOOL ,VIRACHILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17093",
            "district": "19",
            "Block": "326",
            "label": "VALLUVAR MIDDLE SCHOOL, KULIPIRAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17094",
            "district": "19",
            "Block": "326",
            "label": "M.CT.RM HIGHER SEC.SCHOOL, KULIPIRAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17095",
            "district": "19",
            "Block": "326",
            "label": "CHRISTURAJA HIGH SCHOOL, IRUTHAYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17096",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MALAIKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17097",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALAIYANVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17098",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KULATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17099",
            "district": "19",
            "Block": "326",
            "label": "GOVTHIGH SCHOOL,THULAIYANUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17100",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17101",
            "district": "19",
            "Block": "326",
            "label": "GHSS LEMBALAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17102",
            "district": "19",
            "Block": "326",
            "label": "SRI SARASWATHI HIGHER SECONDARY SCHOOL, KONAPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17103",
            "district": "19",
            "Block": "326",
            "label": "GOVT.GIRLS.HR.SEC.SCHOOL, THIRUMAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17104",
            "district": "19",
            "Block": "326",
            "label": "GOVT.HR.SEC.SCHOOL, THIRUMAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17105",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL S.NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17106",
            "district": "19",
            "Block": "326",
            "label": "R.C. MIDDLE SCHOOL, NEIVASAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "17107",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17108",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VENGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17109",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THERKUPUDUVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17110",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17111",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17112",
            "district": "19",
            "Block": "326",
            "label": "RAMANATHAN CHETTIAR HR.SEC.SCHOOL, NACHANDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17113",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTUBAVA PALLIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17114",
            "district": "19",
            "Block": "326",
            "label": "SM.MAR.ALAMELARUNACHALAM HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17115",
            "district": "19",
            "Block": "326",
            "label": "SCM, MIDDLE SCHOOL, KURUVIKONDANAPTTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17116",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SETHURAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17117",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, V.LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17118",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PILAKKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17119",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OLIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17120",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARAVAMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17121",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17122",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SHOOL KONNAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17123",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NAGARAPPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17124",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17125",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALAVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17126",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANDIYANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17127",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PON.PUDUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17128",
            "district": "19",
            "Block": "251",
            "label": "V.V.HR SEC SCHOOL.PONNAMARAVAT",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17129",
            "district": "19",
            "Block": "251",
            "label": "GOVT G.H.S.PON.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17130",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGH SCHOOL, THIRUKKALAMPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17131",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGH SCHOOL, KARUPPUKUDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17132",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PICHAKALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17133",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGH SCHOOL, VARPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17134",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17135",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EDAIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17136",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARASAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17137",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NERINJIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17138",
            "district": "19",
            "Block": "251",
            "label": "M.A.K GOVERNMENT HIGHER SECONDARY SCHOOL, KARAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17139",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MULLIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17140",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELATHANIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17141",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, R.PALAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17142",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17143",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMBOOTHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17144",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEELATHANIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17145",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELASIVAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17146",
            "district": "19",
            "Block": "251",
            "label": "M.NARAYANAN CHETTIAR HIGH SCHOOL,KOPPANAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17147",
            "district": "19",
            "Block": "251",
            "label": "KALAIMAGAL KALLOORI GIRLS HIGH SCHOOL,KOPPANAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17148",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17149",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MYLAPORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17150",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTAYANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17151",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEDUNGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17152",
            "district": "19",
            "Block": "25",
            "label": "AYYAKKANNU GHSS, KEELANILAIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17153",
            "district": "19",
            "Block": "25",
            "label": "PRAISE MATRICULATION SCHOOL, KEELANILAIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17154",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THANJURE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17155",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NALLAMBAL SAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17156",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, POONAIYAN KUDIERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17157",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KALANIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17158",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, POSAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17159",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PILIYAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17160",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, PERUNGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17161",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, OTHAIPULIKKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17162",
            "district": "19",
            "Block": "25",
            "label": "JAIHINTH  BALA BHAVAN MATRICULATION SCHOOL, VAIYAPURIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17163",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUVANAIKAVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17164",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THEKKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17165",
            "district": "19",
            "Block": "25",
            "label": "M CT M CC M HIGH SCHOOL, NAMANASAMUDRAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17166",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEENIKANDHA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17167",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, KURUNGALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17168",
            "district": "19",
            "Block": "25",
            "label": "SRI GANESAR MIDDLE SCHOOL, VISHVANATHPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17169",
            "district": "19",
            "Block": "25",
            "label": "ULAGAPPAR HIGHER SECONDARY SCHOOL, RAMACHANDRAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17170",
            "district": "19",
            "Block": "25",
            "label": "SKT GANDHI HIGH SCHOOL, RAYAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17171",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, MIRATTUNILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17172",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, IRUMBANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17173",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ARIMALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17174",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, ARIMALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17175",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ONANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17176",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, YEMBAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17177",
            "district": "19",
            "Block": "25",
            "label": "IMAYAM NURSERY PRIMARY SCHOOL, YEMBAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17178",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KALLUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17179",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17180",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADAYAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17181",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VADAKKU NALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17182",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUNASANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17183",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL MARAMADAKKI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17184",
            "district": "19",
            "Block": "22",
            "label": "PUNCHAYAT UNION MIDDLE SCHOOL PARAVAKOTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17185",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KURUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17186",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,NARPAVALASENGAMARI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17187",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL, THANTHANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17188",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL, CHIDAMBARAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17189",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVANATHANKOTTAI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17190",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUNNAKURUMBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17191",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL, SILATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17192",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALIYANILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17193",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANNAMALAIYAN KUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17194",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL, POOVAIMANAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17195",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, PERIYALUR EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17196",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEIVATHALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17197",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL MERPANAIKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17198",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , RAMASAMYPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17199",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VALLAVARI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17200",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, VALLAVARI EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17201",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONNAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17202",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUNTHIRAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17203",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL , ERUKKALAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17204",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL , RAJENDRAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17205",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17206",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VAIRIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17207",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RETHINAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17208",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOOTHADIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17209",
            "district": "19",
            "Block": "22",
            "label": "LAUREL HIGHER SECONDARY SCHOOL, KURUMBAKKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17210",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, ARASARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17211",
            "district": "19",
            "Block": "22",
            "label": "ALHIDHAYA JAMATH ORIENTAL ARABIC HIGH SCHOOL, ARASARKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17212",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL MANGALANADU EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17213",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, AMARASIMENDRAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17214",
            "district": "19",
            "Block": "22",
            "label": "GOVT BOYS HR SEC SCHOOL,SUBRAMANIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17215",
            "district": "19",
            "Block": "22",
            "label": "GOVT GIRLS HR SEC SCHOOL, SUBRAMANIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17216",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARUNGULIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17217",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,PERUNGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17218",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,MELAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17219",
            "district": "19",
            "Block": "22",
            "label": "DOCTORS PUBLIC SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17220",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VIGNESWARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17221",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VEERANDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17222",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KAMMANGADU NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17223",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,THIRUNALUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17224",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, THIRUNALLUR SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17225",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, AYINGUDI SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17226",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17227",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOOKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17228",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,PANCHATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17229",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,PUDUVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17230",
            "district": "19",
            "Block": "22",
            "label": "ST.MARYS R.C HIGH SCHOOL, EGANIVAYAL THENPATHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17231",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, DURAIYARASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17232",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17233",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL ATHANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17234",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THATTANVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17235",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,NAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17236",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL,NAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17237",
            "district": "19",
            "Block": "22",
            "label": "GOVERNMENT MODEL  HIGHER  SECONDARY  SCHOOL , ARANTHANGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17238",
            "district": "19",
            "Block": "22",
            "label": "MUNICIPAL MIDDLE SCHOOL L.N.PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17239",
            "district": "19",
            "Block": "22",
            "label": "IDEAL MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17240",
            "district": "19",
            "Block": "22",
            "label": "CHIRST KING R.C. MIDDLE SCHOOL, ARANTHANGI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17241",
            "district": "19",
            "Block": "22",
            "label": "VESTLEY HR SEC SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17242",
            "district": "19",
            "Block": "22",
            "label": "S.M.A.MIDDLE.SCHOOL,CHITTANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17243",
            "district": "19",
            "Block": "22",
            "label": "SATHYAMOORTHY MIDDLE SCHOOL, ARANTHANGI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17244",
            "district": "19",
            "Block": "22",
            "label": "A.J.J.ORIENTAL ARABIC HR SEC SCHOOL,ARANTHANGI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17245",
            "district": "19",
            "Block": "22",
            "label": "GOVT GIRLS HR SEC SCHOOL,ARANTHANGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17246",
            "district": "19",
            "Block": "22",
            "label": "KAMARAJ HIGH SCHOOL, ARANTHANGI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17247",
            "district": "19",
            "Block": "22",
            "label": "MUNICIPAL MIDDLE SCHOOL WEST, ARANTHANGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17248",
            "district": "19",
            "Block": "22",
            "label": "T.E.L.C MIDDLE SCHOOL,ARANTHANGI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17249",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,SUNAIYAKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17250",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,THOLUVANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17251",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,ARASARKULAM EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17252",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MENAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17253",
            "district": "19",
            "Block": "167",
            "label": "GOVT HIGH SCHOOL, EDAIYATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17254",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SINGAVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17255",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, KARAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17256",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHELLANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17257",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, JEGATHAPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17258",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, KOLENTHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17259",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANJAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17260",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VADAKKU AMMAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17261",
            "district": "19",
            "Block": "167",
            "label": "GOVT. GIRLS HR. SEC. SCHOOL, MANAMELKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17262",
            "district": "19",
            "Block": "167",
            "label": "GOVT. BOYS HR. SEC. SCHOOL, MANAMELKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17263",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELASTHANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17264",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HR. SEC. SCHOOL, AMBALAVANANENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17265",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NILAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17266",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HR. SEC. SCHOOL, PERUMARUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17267",
            "district": "19",
            "Block": "167",
            "label": "ST.MARY'S MIDDLE SCHOOL, MOOVANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "17268",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARKKAMALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17269",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17270",
            "district": "19",
            "Block": "167",
            "label": "ST. JOSEPH HR. SEC. SCHOOL, VICHOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17271",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, KRISHNAJIPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17272",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEDIYANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17273",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17274",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PALAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17275",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HR. SEC. SCHOOL, KOTTAIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17276",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL(MC), KOTTAIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17277",
            "district": "19",
            "Block": "167",
            "label": "GOVT. GIRLS. HIGH SCHOOL, KOTTAIPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17278",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL BOYS, AMMAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17279",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVUDAIYARPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17280",
            "district": "19",
            "Block": "167",
            "label": "GOVT. GIRLS HR. SEC. SCHOOL, AMMAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17281",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THANDALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17282",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARAKATHIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17283",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HR. SEC. SCHOOL, KATTUMAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17284",
            "district": "19",
            "Block": "167",
            "label": "AL-HUDA MATRIC HIGH SCHOOL,AZHAGANVAYAL.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17285",
            "district": "19",
            "Block": "33",
            "label": "GOVERNMENT HIGH SCHOOL,AMARADAKKI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17286",
            "district": "19",
            "Block": "33",
            "label": "GOVERNMENT HIGH SCHOOL OKKUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17287",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MID SCHOOL   KANNAKKANVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17288",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL ,  THACHAMALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17289",
            "district": "19",
            "Block": "33",
            "label": "S. R.V.HSS, THIRUPPUNAVASAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17290",
            "district": "19",
            "Block": "33",
            "label": "GOVT HIGH SCHOOL .POYYATHANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17291",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MID SCHOOL   AVUDAIYARKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17292",
            "district": "19",
            "Block": "33",
            "label": "GOVT HR SEC SCHOOL AVUDAIYARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17293",
            "district": "19",
            "Block": "33",
            "label": "GOVT.GIRLS HIGH   SCHOOL, AVUDAIYARKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17294",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. SIDDHAKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17295",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. THONDAIMANENDHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17296",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KARANIYANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17297",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. R. PUDUPATTINAM(MUSLIM)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17298",
            "district": "19",
            "Block": "33",
            "label": "GOVT HR SEC SCHOOL GOPALAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17299",
            "district": "19",
            "Block": "33",
            "label": "R.C.HIGH.SCHOOL ,PADIRIGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17300",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MUTHUKUDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17301",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ELUNOOTRIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17302",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PIRANTHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17303",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION  MIDDLE SCHOOL. PERUNAVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17304",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. PARAMANDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17305",
            "district": "19",
            "Block": "33",
            "label": "GOVT HIGH SCHOOL PONBETHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17306",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. ARASANAGARIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17307",
            "district": "19",
            "Block": "33",
            "label": "GOVT. HR.SEC. SCHOOL. MIMISAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17308",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. AVANAMPERUNGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17309",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. VELVARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17310",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KEELKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17311",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. PANDIPATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17312",
            "district": "19",
            "Block": "33",
            "label": "MANICKAVASAKAR.H.S.SCHOOL ,PANDIPATHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17313",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KUMULOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17314",
            "district": "19",
            "Block": "33",
            "label": "GOVT. HR.SEC. SCHOOL. KARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17315",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KALABAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17316",
            "district": "19",
            "Block": "33",
            "label": "GOVT HIGH SCHOOL THALANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20975",
            "district": "19",
            "Block": "255",
            "label": "BHARATHI VIDYALAYA MATRIC SCHOOL, THIRUGOKARNAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20994",
            "district": "19",
            "Block": "326",
            "label": "MOUNTOLIVE MATRIC HR.SEC.SCHOOL,MOONGITHAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20995",
            "district": "19",
            "Block": "25",
            "label": "AL-MUNAWARA ISLAMIC SCHOOL, SIVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21105",
            "district": "19",
            "Block": "67",
            "label": "VIDYA VIKAS INTER.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21331",
            "district": "19",
            "Block": "326",
            "label": "MOUNTZION.INT.LENA VILAKKU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21479",
            "district": "19",
            "Block": "18",
            "label": "GHS PULVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21480",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL PERUMANADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21481",
            "district": "19",
            "Block": "67",
            "label": "GHS-THUVAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21482",
            "district": "19",
            "Block": "67",
            "label": "GHS PALLAVARAYANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21483",
            "district": "19",
            "Block": "22",
            "label": "GOVT GIRLS HIGH SCHOOL SILATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21540",
            "district": "19",
            "Block": "108",
            "label": "ST.JOSEPH.HIGHER SECONDARY SCHOOL.KOTTAIKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21558",
            "district": "19",
            "Block": "22",
            "label": "AMIRTHA VIDYA VIKAAS HR. SEC. SCHOOL, KOVILVAYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21752",
            "district": "19",
            "Block": "22",
            "label": "SHIVAANI VIDHYAA MANDIR HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21753",
            "district": "19",
            "Block": "67",
            "label": "VIDYA VIKAS BOYS MAT.HR.SECSCHOOL\tPUDUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21966",
            "district": "19",
            "Block": "22",
            "label": "ARANTHAI  ANNAI SPEECH AND HEARING IMPAIRMENT SPECIAL SCHOOL ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21980",
            "district": "19",
            "Block": "405",
            "label": "CHELLAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21981",
            "district": "19",
            "Block": "67",
            "label": "CHRIST THE KING MATRICULATION SCHOOL , GANDARVAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22230",
            "district": "19",
            "Block": "405",
            "label": "SFS. PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22231",
            "district": "19",
            "Block": "108",
            "label": "BRIGHT  MATRIC HR.SEC. SCHOOL KARAMBAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22232",
            "district": "19",
            "Block": "25",
            "label": "MRM INTERNATIONAL CBSE SCHOOL, SIVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22233",
            "district": "19",
            "Block": "22",
            "label": "SHEIK FATHIMA GIRLS MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22584",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL.IRUNTHIRAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22585",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL AKKALNAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22586",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL MALAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22587",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL ATHIPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22588",
            "district": "19",
            "Block": "67",
            "label": "GOVT HIGH SCHOOL PUNALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22589",
            "district": "19",
            "Block": "108",
            "label": "GOVT HIGH SCHOOL  KOLANTHIRANPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22590",
            "district": "19",
            "Block": "167",
            "label": "GOVT HIGH SCHOOL PONNAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22591",
            "district": "19",
            "Block": "167",
            "label": "GOVT HIGH SCHOOL PUDUKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22763",
            "district": "19",
            "Block": "18",
            "label": "ST. JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22912",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGH SCHOOL MANTHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22913",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL MANGUDY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22914",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL PAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22925",
            "district": "19",
            "Block": "67",
            "label": "GOVT HIGH SCHOOL ATHANGARAIVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22937",
            "district": "19",
            "Block": "18",
            "label": "GOVERMENT GIRLS HIGH SCHOOL, ANNAVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22957",
            "district": "19",
            "Block": "22",
            "label": "SAI LAUREL INTERNATIONAL SCHOOL,KURUMBAKKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23078",
            "district": "19",
            "Block": "255",
            "label": "TAAGORE MATRICULATION SCHOOL, ADAPPANVAYAL NORTH, PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23202",
            "district": "19",
            "Block": "251",
            "label": "CT INTERNATIONAL SCHOOL,KESARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23203",
            "district": "19",
            "Block": "255",
            "label": "SOUTH SPRING INTERNATIONAL SCHOOL,PERUNKONDANVIDUTHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23286",
            "district": "19",
            "Block": "18",
            "label": "GOVERNMENT HIGH SCHOOL KEZHA PALUVANCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23528",
            "district": "19",
            "Block": "18",
            "label": "M.S.A  MATRICULATION SCHOOL. KEELAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23529",
            "district": "19",
            "Block": "255",
            "label": "DELHI PUBLIC SCHOOL , PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23719",
            "district": "19",
            "Block": "18",
            "label": "SANTHOMA MATRICULLATION SCHOOL VIDIVAKAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23720",
            "district": "19",
            "Block": "18",
            "label": "DURAIRAJ MEMORIAL MATRICULATION SCHOOL PERUMANADU",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23724",
            "district": "19",
            "Block": "22",
            "label": "KURUKULAM NURSERY AND PRIMARY SCHOOL  ALANJIRANKADU",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23725",
            "district": "19",
            "Block": "22",
            "label": "MONTFORT SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23729",
            "district": "19",
            "Block": "25",
            "label": "CARMEL RANI MATRIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23781",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23929",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24005",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24020",
            "district": "19",
            "Block": "339",
            "label": "PUDUKKOTTAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "20": [
        {
            "key": "410",
            "district": "20",
            "Block": "231",
            "label": "ST.MARY'S MATRICULATION HIGH SCHOOL, PARTHIBANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "719",
            "district": "20",
            "Block": "266",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, DEVIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "770",
            "district": "20",
            "Block": "99",
            "label": "RAHMANIA MATRIC HIGHER SECONDARY SCHOOL,KAMUTHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "808",
            "district": "20",
            "Block": "86",
            "label": "AL-AMEEN MATRICULATION HIGH SCHOOL, VALINOKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "832",
            "district": "20",
            "Block": "169",
            "label": "ALLWIN MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "859",
            "district": "20",
            "Block": "266",
            "label": "CRESCENT MATRICULATION HIGH SCHOOL, VELIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "928",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL ACADEMY MONTESSORI MATRIC  HIGHER SECONDARY SCHOOL, PATTANAMKATHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "930",
            "district": "20",
            "Block": "335",
            "label": "ISLAMIC MODEL MATRICULATION HIGHER SECONDARY SCHOOL, THONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "933",
            "district": "20",
            "Block": "169",
            "label": "KALAIVANI MATRICULATION SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "942",
            "district": "20",
            "Block": "266",
            "label": "BUKHARIYA MATRICULATION HIGHER SECONDARY SCHOOL, DEVIPATTINAM SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "973",
            "district": "20",
            "Block": "266",
            "label": "HOUSING BOARD H.O.W.A.MATRICULATION HIGH SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "984",
            "district": "20",
            "Block": "266",
            "label": "LOUIS LEVAIL MATRICULATION SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "992",
            "district": "20",
            "Block": "86",
            "label": "BANU MATRIC HIGHER SECONDARY SCHOOL, SIKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1009",
            "district": "20",
            "Block": "231",
            "label": "DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1015",
            "district": "20",
            "Block": "266",
            "label": "INFANT JESUS MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1025",
            "district": "20",
            "Block": "266",
            "label": "SYED AMMAL MATRIC (BOYS) HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1029",
            "district": "20",
            "Block": "169",
            "label": "NABEESA AMMAL MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1034",
            "district": "20",
            "Block": "188",
            "label": "SRI KANNA MATRICULATION HIGHER SECONDARY SCHOOL, MUDUKULATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1036",
            "district": "20",
            "Block": "169",
            "label": "THE INTERNATIONAL MATRICULATION SCHOOL, IRUMENI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1081",
            "district": "20",
            "Block": "169",
            "label": "RAJA MATRIC HIGHER SECONDARY SCHOOL, KUNJARVALASAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1096",
            "district": "20",
            "Block": "169",
            "label": "HOLY ISLAND LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL, RAMESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1113",
            "district": "20",
            "Block": "86",
            "label": "V.V.S.M. NATIONAL ACADEMY MATRIC HIGHER SECONDARY SCHOOL, SAYALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1116",
            "district": "20",
            "Block": "231",
            "label": "BHARATHIYAR MIDDLE SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1166",
            "district": "20",
            "Block": "169",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, RAMESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1167",
            "district": "20",
            "Block": "169",
            "label": "MOHAMED DASTAGIR MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1169",
            "district": "20",
            "Block": "169",
            "label": "PURATCHI THALAIVAR DR MGR MATRIC HIGHER SECONDARY SCHOOL, UCHIPULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1179",
            "district": "20",
            "Block": "169",
            "label": "UNITED MATRICULATION HIGH SCHOOL, PATTANAMKATHAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1210",
            "district": "20",
            "Block": "86",
            "label": "INDIAN MATRIC HIGHER SECONDARY SCHOOL, SIKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1236",
            "district": "20",
            "Block": "99",
            "label": "KSHATRIYA NADAR MATRIC HIGHER SECONDARY SCHOOL.,KAMUTHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1254",
            "district": "20",
            "Block": "188",
            "label": "KAMARAJAR MATRICULATION HIGHER SECONDARY SCHOOL, MUDUKULATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1265",
            "district": "20",
            "Block": "41",
            "label": "R.K.SAMY MATRICULATION HIGH SCHOOL, SEYYALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1322",
            "district": "20",
            "Block": "86",
            "label": "SRI SANGEEDHA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, K.KARUNKULAM, KADALADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1324",
            "district": "20",
            "Block": "231",
            "label": "YADAVA MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1342",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ERVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1349",
            "district": "20",
            "Block": "266",
            "label": "SRI KUMARAN MIDDLE SCHOOL , RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1362",
            "district": "20",
            "Block": "231",
            "label": "V.O.C.MATRICULATION HIGHER SECONDARY SCHOOL, N.VALAYANENTHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1385",
            "district": "20",
            "Block": "231",
            "label": "HARISH VARMA MATRICULATION HIGHER SECONDARY SCHOOL, ARIYANENDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1419",
            "district": "20",
            "Block": "332",
            "label": "HAMEEDIAH MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1422",
            "district": "20",
            "Block": "335",
            "label": "AL-HILAL MATRICULATION HIGHER SECONDARY SCHOOL , THONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1427",
            "district": "20",
            "Block": "266",
            "label": "SCHWARTZ MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1451",
            "district": "20",
            "Block": "169",
            "label": "KING OF KINGS MATRIC HIGHER SECONDARY SCHOOL, MEYYAMPULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1463",
            "district": "20",
            "Block": "169",
            "label": "NAZIYA MATRIC HIGHER SECONDARY SCHOOL, ALAGANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1465",
            "district": "20",
            "Block": "332",
            "label": "ISLAMIAH MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1469",
            "district": "20",
            "Block": "86",
            "label": "ROJA MATRIC HIGHER SECONDARY SCHOOL, SAYALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1486",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHARAIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1490",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL ACADEMY MATRIC HIGHER SECONDARY SCHOOL, UCHIPULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1521",
            "district": "20",
            "Block": "86",
            "label": "ELITE MATRIC HIGHER SECONDARY SCHOOL, ERVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1524",
            "district": "20",
            "Block": "169",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL, PAMBAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1583",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ACHANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1595",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PONNAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1639",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAVAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1650",
            "district": "20",
            "Block": "266",
            "label": "A.V.M.S. MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1657",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL MATRICULATION HIGHER SECONDARY SCHOOL,  RAMESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1661",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHITHIRANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1665",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OPPILAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1674",
            "district": "20",
            "Block": "266",
            "label": "SRI SARASWATHI MIDDLE SCHOOL, VELIPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1688",
            "district": "20",
            "Block": "86",
            "label": "SRI RAMAKRISHNA SRI SARADHA MIDDLE SCHOOL, APPANOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1690",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANJAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1699",
            "district": "20",
            "Block": "266",
            "label": "POPE JOHN PAUL II MIDDLE SCHOOL, PASUMPON NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1716",
            "district": "20",
            "Block": "86",
            "label": "NEW MIDDLE SCHOOL, SAYALGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "1717",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ROJMANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1722",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MENNANTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1751",
            "district": "20",
            "Block": "266",
            "label": "VELUMANICKAM MONTESSORI MATRICULATION HIGHER SECONDARY SCHOOL, VANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1759",
            "district": "20",
            "Block": "86",
            "label": "RC MIDDLE SCHOOL, MOOKKAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1815",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1819",
            "district": "20",
            "Block": "332",
            "label": "P.S.S.J. NADAR MATRICULATION SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1926",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUVIKITHIYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1964",
            "district": "20",
            "Block": "231",
            "label": "AGNES MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1997",
            "district": "20",
            "Block": "265",
            "label": "HOLY ANGELS MATRICULATION HIGHER SECONDARY SCHOOL, R.S.MANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2030",
            "district": "20",
            "Block": "231",
            "label": "SOURASHTRA DESIA MIDDLE SCHOOL, SOMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2041",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, S.KALLUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2056",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , S.R.N.PALANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2100",
            "district": "20",
            "Block": "99",
            "label": "HINDU MIDDLE SCHOOL, KAVADIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2101",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELASELVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2106",
            "district": "20",
            "Block": "332",
            "label": "MUHAIIDHEENIA MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2108",
            "district": "20",
            "Block": "86",
            "label": "I.L.M. MATRICULATION HIGH SCHOOL, NARIPPAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2122",
            "district": "20",
            "Block": "231",
            "label": "SUNDARAM MIDDLE SCHOOL, ARIYANENDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2124",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, POTTITHATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2135",
            "district": "20",
            "Block": "169",
            "label": "ST. ANNE'S MATRICULATION HIGHER SECONDARY SCHOOL, AKKALMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2162",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, S.T.SETHURAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2170",
            "district": "20",
            "Block": "99",
            "label": "SAINT JOSEPH'S RC MIDDLE SCHOOL, KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2262",
            "district": "20",
            "Block": "335",
            "label": "RAJAN MATRICULATION HIGH SCHOOL, THIRUVADANAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2267",
            "district": "20",
            "Block": "265",
            "label": "WINNER'S MATRICULATION HIGH SCHOOL, R.S.MANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2326",
            "district": "20",
            "Block": "231",
            "label": "T.E.L.C. MIDDLE SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2338",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHEMCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2362",
            "district": "20",
            "Block": "99",
            "label": "T.E.L.C. MIDDLE SCHOOL, ARANMANAIMEDU, KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2385",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ABIRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2390",
            "district": "20",
            "Block": "99",
            "label": "S.N.ESMAIL MIDDLE SCHOOL, ABIRAMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2394",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. VEERAMAACHANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2511",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL ACADEMY ICSE HIGHER SECONDARY SCHOOL,PATTANAMKATHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2522",
            "district": "20",
            "Block": "169",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2559",
            "district": "20",
            "Block": "332",
            "label": "PEARL MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2607",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EZHUVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2642",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELASIRUPOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2656",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THALAIVANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2687",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.VALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2690",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHUVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2699",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2754",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, POOPANDIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2877",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUNAZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2928",
            "district": "20",
            "Block": "231",
            "label": "DR.SURESH MATRICULATION HIGH SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3007",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINNAUDAPPANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3043",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , S.KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3119",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELAKODUMALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3163",
            "district": "20",
            "Block": "86",
            "label": "NADAR MAHAJANA SANGAM KALVITHANTHAI K.S.SHANMUGA NADAR MIDDLE SCHOOL, SAYALGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3166",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UDAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3349",
            "district": "20",
            "Block": "231",
            "label": "PARAMAKUDI LIONS MATRICULATION HIGHER SECONDARY SCHOOL, THELICHATHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3356",
            "district": "20",
            "Block": "231",
            "label": "AYIRA VAISYA MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3526",
            "district": "20",
            "Block": "332",
            "label": "VIJI VIDYALAYA MATRICULATION SCHOOL, THIRU UTHARAKOSAMANGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3567",
            "district": "20",
            "Block": "332",
            "label": "SATHAKATHUN JARIA MIDDLE SCHOOL, KILAKKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3574",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, YADHAVAR KUDI, POTHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3575",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3711",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VEERAVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3719",
            "district": "20",
            "Block": "188",
            "label": "R.C MIDDLE SCHOOL , KODARENDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3776",
            "district": "20",
            "Block": "86",
            "label": "AMBIKAVATHY HINDU NADAR MIDDLE SCHOOL, VELLAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3823",
            "district": "20",
            "Block": "231",
            "label": "KALIDASS MIDDLE SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3850",
            "district": "20",
            "Block": "332",
            "label": "DHEENIAH MATRICULATION  HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3959",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NARAIYOORANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4067",
            "district": "20",
            "Block": "266",
            "label": "VALLUVAN MIDDLE SCHOOL , VELIPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4093",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.THARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4155",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4177",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , SAMYPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4184",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4200",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUNGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4261",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4473",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  APPIRAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4480",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4485",
            "district": "20",
            "Block": "86",
            "label": "ANJANEYA MATRICULATION SCHOOL, KANDILAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4493",
            "district": "20",
            "Block": "335",
            "label": "CREATIVE MATRICULATION HIGHER SECONDARY SCHOOL, PULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4518",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUNDUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4550",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RADHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4586",
            "district": "20",
            "Block": "332",
            "label": "N.M.S. S.V. MIDDLE SCHOOL, MAYAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4682",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SETHIDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4717",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUSTAQKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4726",
            "district": "20",
            "Block": "188",
            "label": "SARAVANA MATRICULATION HIGH SCHOOL, KAKKOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4782",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KATHANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4816",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, URAIKINARU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4817",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ORIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4829",
            "district": "20",
            "Block": "197",
            "label": "HAMEETHIA MIDDLE SCHOOL, GANGAIKONDAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4861",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PONTHAMPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4960",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUMMUDISATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4991",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KOTTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5049",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.VEPPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5059",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , SELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5119",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5138",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEESAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5182",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKOTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5187",
            "district": "20",
            "Block": "266",
            "label": "SRI SETHUPATHI MIDDLE SCHOOL , RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5188",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , SAMBAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5247",
            "district": "20",
            "Block": "188",
            "label": "R.C MIDDLE SCHOOL, M.MICHAELPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5331",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AYANSITHANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5442",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALINTHIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5523",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5551",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UDAYAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5558",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5579",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  GOVINDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5602",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ERAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5621",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL , POOSERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5637",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THETHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5642",
            "district": "20",
            "Block": "265",
            "label": "V.K.RAMAIAH MIDDLE SCHOOL, KAVANAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5667",
            "district": "20",
            "Block": "188",
            "label": "THE CRESCENT MATRICULATION HIGHER SECONDARY  SCHOOL, KOLUNDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5781",
            "district": "20",
            "Block": "86",
            "label": "HINDU NADAR MIDDLE SCHOOL, KOKKARASANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5794",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.C.V.MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5822",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALLAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5830",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALINOKKAM, ADANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5839",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINNAPALAIYARENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5850",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.MANAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5853",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KEELAKIDARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5858",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELAMUNDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5859",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINNA ERVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5866",
            "district": "20",
            "Block": "86",
            "label": "KSHATRIYA NADAR MIDDLE SCHOOL, KANNIRAJAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5887",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUTHUKKALVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5902",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5928",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANAISERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5955",
            "district": "20",
            "Block": "265",
            "label": "JOTHI MIDDLE SCHOOL,  AYNGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5969",
            "district": "20",
            "Block": "99",
            "label": "KSHATHRIYA VIDHYA SALA MIDDLE SCHOOL, VALAIYAPOOKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5995",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, M.KARISALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6046",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6060",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THALAIYADIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6121",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  MELPANAIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6176",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VENNEERVAIKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6204",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6394",
            "district": "20",
            "Block": "231",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6464",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SOGAYANTHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6504",
            "district": "20",
            "Block": "332",
            "label": "NADAR MIDDLE SCHOOL, MAVILANTHOPE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19016",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUGIZHTHAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19017",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, M.V.PATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19018",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MULLIMUNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19019",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PADANAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19020",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PALANGULAM-2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19021",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PANDUKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19022",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PANNAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19023",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PASIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19024",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, S.P.PATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19025",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANKOLLANPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19026",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19027",
            "district": "20",
            "Block": "335",
            "label": "R.C. MIDDLE SCHOOL, KURUMILANKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19028",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KODIPANGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19029",
            "district": "20",
            "Block": "335",
            "label": "AMALA ANNAI HIGHER SECONDARY SCHOOL, KARANKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19030",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, KAVALAIVENDRAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19031",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19032",
            "district": "20",
            "Block": "335",
            "label": "SIRUMALAR HIGHER SECONDARY  SCHOOL, ANDAVOORANI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19033",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANJUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19034",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, THALIRMARUNGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19035",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, M.R PATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19036",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19037",
            "district": "20",
            "Block": "335",
            "label": "MUNAWWARA MIDDLE SCHOOL , THONDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19038",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19039",
            "district": "20",
            "Block": "335",
            "label": "S.M. GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, THONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19040",
            "district": "20",
            "Block": "335",
            "label": "WILHELM HIGH SCHOOL , THONDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19041",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THINAIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19042",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, N.PANANCHAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19043",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THIRUVADANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19044",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, THIRUVADANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19045",
            "district": "20",
            "Block": "335",
            "label": "ST. NORBERT R.C. HIGHER SECONDARY SCHOOL, THIRUVETRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19046",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, VATTANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19047",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, NAMBUTHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19048",
            "district": "20",
            "Block": "335",
            "label": "ST. FRANCIS HIGHER SECONDARY SCHOOL, C.K.MANGALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19049",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, THINAIKATHANVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19050",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANGALAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19051",
            "district": "20",
            "Block": "335",
            "label": "ST.ANTONY'S MIDDLE SCHOOL,NAGARIKATHTHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19052",
            "district": "20",
            "Block": "335",
            "label": "ST.MARY'S MIDDLE SCHOOL,  ORIKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19053",
            "district": "20",
            "Block": "335",
            "label": "ST. ARULANANDAR HIGHER SECONDARY SCHOOL, ORIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19054",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ILUPPAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19055",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, N.M.MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19056",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VISUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19057",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, R.S.MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19058",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, R.S.MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19059",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19060",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGH SCHOOL, SHOLANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19061",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT  MODEL HIGHER SECONDARY SCHOOL, THIRUPPALAIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19062",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UPPOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19063",
            "district": "20",
            "Block": "265",
            "label": "SRI AMIRTA MATRICULATION SCHOOL, UPPOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19064",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGH SCHOOL, VETTUKKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19065",
            "district": "20",
            "Block": "265",
            "label": "ST.XAVIER'S HIGH SCHOOL, SAVERIYARPATTANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19066",
            "district": "20",
            "Block": "265",
            "label": "ST.MICHAEL'S HIGHER SECONDARY SCHOOL, SENGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19067",
            "district": "20",
            "Block": "265",
            "label": "SACRED HEART HIGH SCHOOL,  IRUDAYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19068",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ANANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19069",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOODALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19070",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SANAVELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19071",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, KALAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19072",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, M.NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19073",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19074",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19075",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, PIDARISERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19076",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THELICHATHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19077",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELAPERUNGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19078",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SUDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19079",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUMARAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19080",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHURAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19081",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELAIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19082",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, PERUMALKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19083",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19084",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELAPERUNGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19085",
            "district": "20",
            "Block": "231",
            "label": "CRESCENT HIGH SCHOOL, PARTHIBANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19086",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PARTHIBANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19087",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PARALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19088",
            "district": "20",
            "Block": "231",
            "label": "RC MIDDLE SCHOOL, MELAKAVANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19089",
            "district": "20",
            "Block": "231",
            "label": "S.R.V.S. MIDDLE SCHOOL, LAKSHMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19090",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, KAMUDAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19091",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE MIDDLE SCHOOL, PAMBUVILUNTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19092",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE MIDDLE  SCHOOL, NILAYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19093",
            "district": "20",
            "Block": "231",
            "label": "MUNICIPAL MIDDLE SCHOOL,  MANJALPATTANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19094",
            "district": "20",
            "Block": "231",
            "label": "S.N.V. GOVERNMENT  MODEL HIGHER SECONDARY SCHOOL, EMANESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19095",
            "district": "20",
            "Block": "231",
            "label": "K.A. WEST MUSLIM HIGH SCHOOL, PARAMAKUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19096",
            "district": "20",
            "Block": "231",
            "label": "K.J. EAST MUSLIM HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19097",
            "district": "20",
            "Block": "231",
            "label": "SOURASHTRA HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19098",
            "district": "20",
            "Block": "231",
            "label": "ALANGARA MATHA HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19099",
            "district": "20",
            "Block": "231",
            "label": "AYIRA VAISYA HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19100",
            "district": "20",
            "Block": "231",
            "label": "R.S. GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19101",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19102",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, KATTUPARAMAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19103",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KAMANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19104",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANJUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19105",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SATHIRAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19106",
            "district": "20",
            "Block": "41",
            "label": "VASAN MATRICULATION HIGHER SECONDARY SCHOOL, SATHIRAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19107",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGH SCHOOL, SEVVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19108",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGH SCHOOL, S.KODIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19109",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19110",
            "district": "20",
            "Block": "41",
            "label": "NADAR MAHAJANA SANGAM SERMATHAI VASAN HIGH SCHOOL, ARASADIVANDAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19111",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NAINARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19112",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL,  S.SIRUVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19113",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL, KULATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19114",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KARADARNTHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19115",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL,  ATTANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19116",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL,  P.KODIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19117",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, POTTAGAVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19118",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL,  KILIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19119",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, PAMMANENDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19120",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, PAKKUVETTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19121",
            "district": "20",
            "Block": "99",
            "label": "KSHATRIYA HINDU NADAR HIGHER SECONDARY SCHOOL, PERUNAZHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19122",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOVILANGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19123",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOTTAIMEDU, KAMUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19124",
            "district": "20",
            "Block": "99",
            "label": "KSHATHRIYA NADAR HIGHER SECONDARY SCHOOL (BOYS), KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19125",
            "district": "20",
            "Block": "99",
            "label": "KSHATHRIYA NADAR HIGHER SECONDARY SCHOOL (GIRLS), KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19126",
            "district": "20",
            "Block": "99",
            "label": "GOWRAVA HIGH SCHOOL, KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "23412",
            "district": "20",
            "Block": "266",
            "label": "Mannar Sethupathy Matriculation Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19127",
            "district": "20",
            "Block": "99",
            "label": "KALAVIRUTHI HIGHER SECONDARY SCHOOL, KAMUTHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19128",
            "district": "20",
            "Block": "99",
            "label": "IQBAL HIGH SCHOOL, SUNDARAPURAM,KAMUTHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19129",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, T.PUNAVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19130",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19131",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANDALAMANICKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19132",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, VAZHIMARICHAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19133",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, T.VALLAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19134",
            "district": "20",
            "Block": "99",
            "label": "MUSLIM HIGHER SECONDARY SCHOOL, ABIRAMAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19135",
            "district": "20",
            "Block": "99",
            "label": "V.N.S. HIGH SCHOOL , ABIRAMAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19136",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, RAMASAMYPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19137",
            "district": "20",
            "Block": "99",
            "label": "SAINT JOHN'S HIGHER SECONDARY SCHOOL, KEELAMUDIMANNARKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19138",
            "district": "20",
            "Block": "99",
            "label": "DEVANGAR HIGHER SECONDARY SCHOOL, NEERAVI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19139",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT ADI DRAVIDAR HIGH SCHOOL, SENGAPPADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19140",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALANGANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19141",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KADAMBODAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19142",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KAKKOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19143",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KEELATHOOVAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19144",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL , KEERANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19145",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOLUNDURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19146",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELATHOOVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19147",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUDUKULATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19148",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MUDUKULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19149",
            "district": "20",
            "Block": "188",
            "label": "PALLIVASAL HIGHER SECONDARY SCHOOL, MUDUKULATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19150",
            "district": "20",
            "Block": "188",
            "label": "T.E.L.C. HIGH SCHOOL, MUDUKULATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19151",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PERUNGARUNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19152",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL , PUZHUTHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19153",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL, SELVANAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19154",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL, SEYYAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19155",
            "district": "20",
            "Block": "188",
            "label": "ROWTHER SAHIB HIGHER SECONDARY SCHOOL, THERIRUVELI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19156",
            "district": "20",
            "Block": "188",
            "label": "SACRED HEART HIGHER SECONDARY SCHOOL, THIRUVARANGAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19157",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH  SCHOOL , ULAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19158",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL, VALANADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19159",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL , VILANGULATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19160",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, APPANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19161",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SAYALGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19162",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SAYALGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19163",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, KADUGUSANTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19164",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.SATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19165",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT MIDDLE SCHOOL, KADALADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19166",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KADALADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19167",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, KIDATHIRUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19168",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, GURUVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19169",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, S.THARAIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19170",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, ILANCHEMBOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19171",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, T.M.KOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19172",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UCHINATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19173",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, T.MARIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19174",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, MELASELVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19175",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SIKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19176",
            "district": "20",
            "Block": "86",
            "label": "SYED ABUBUCKARI BADHUSA MUNSIF HUSSIN GOVERNMENT HIGHER SECONDARY SCHOOL, ERVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19177",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, EDAMBADAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19178",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, VALINOKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19179",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELAKIDARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19180",
            "district": "20",
            "Block": "86",
            "label": "ST. XAVIER CLUNY HIGHER SECONDARY SCHOOL, M.SAVARIARPATTANAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19181",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KANNIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19182",
            "district": "20",
            "Block": "86",
            "label": "BHARATHA MATHA HIGH SCHOOL, NARIPPAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19183",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, DEVIPATTINAM SOUTH",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19184",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KALUGOORANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19185",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL, PALANIVALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19186",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL, SAMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19187",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL, SAKKARAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19188",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VAIRAVANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19189",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL , ATHIYOOTHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19190",
            "district": "20",
            "Block": "266",
            "label": "MOHAMEDIA HIGHER SECONDARY SCHOOL, CHITHARKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19191",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, R.KAVANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19192",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KARUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19193",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KATTOORANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19194",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , MADHAVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19195",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUTHENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19196",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PULLANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19197",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL , PERAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19198",
            "district": "20",
            "Block": "266",
            "label": "ABCS KENBRIDGE HR. SEC. SCHOOL, SINGANENDAL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19199",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL , THORUVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19200",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , RAJASOORIYAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19201",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VANNIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19202",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NARANAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19203",
            "district": "20",
            "Block": "266",
            "label": "RAJAH'S HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19204",
            "district": "20",
            "Block": "266",
            "label": "ST.JOSEPH 'S HIGHER SECONDARY SCHOOL , RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19205",
            "district": "20",
            "Block": "266",
            "label": "RAJA M.DHINKAR HIGH SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19206",
            "district": "20",
            "Block": "266",
            "label": "D.D.VINAYAGAR HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19207",
            "district": "20",
            "Block": "266",
            "label": "SYED AMMAL HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19208",
            "district": "20",
            "Block": "266",
            "label": "SYED AMMAL MATRIC (GIRLS) HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19209",
            "district": "20",
            "Block": "266",
            "label": "COLUMBU ALIM HIGH SCHOOL , VELIPPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19210",
            "district": "20",
            "Block": "266",
            "label": "VALLAL PARI MUNICIPAL MIDDLE SCHOOL , VELIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19211",
            "district": "20",
            "Block": "266",
            "label": "MUNICIPAL GIRLS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19212",
            "district": "20",
            "Block": "266",
            "label": "ARIGNAR ANNA MUNICIPAL MIDDLE SCHOOL , RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19213",
            "district": "20",
            "Block": "266",
            "label": "SCHWARTZ BOYS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19214",
            "district": "20",
            "Block": "266",
            "label": "ST. ANDREW'S GIRLS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19215",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , ALANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19216",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , EKKAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19217",
            "district": "20",
            "Block": "332",
            "label": "C.S.I. MIDDLE SCHOOL, KILAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19218",
            "district": "20",
            "Block": "332",
            "label": "ISLAMIAH HIGH SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19219",
            "district": "20",
            "Block": "332",
            "label": "HAMEEDIAH BOYS HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19220",
            "district": "20",
            "Block": "332",
            "label": "HAMEEDIA GIRLS HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19221",
            "district": "20",
            "Block": "332",
            "label": "KHYRATHUL JALALIYA HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19222",
            "district": "20",
            "Block": "332",
            "label": "MAKHDHOOMIA HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19223",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , L.KARUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19224",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, PANAIYADIYENDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19225",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THIRU UTHRAKOSAMANGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19226",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, VELANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19227",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BHARATHINAGAR,MAYAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19228",
            "district": "20",
            "Block": "332",
            "label": "SRISETHUMANICKAM HIGH SCHOOL, MELASEETHAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19229",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, KANCHIRANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19230",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, KALIMANGUNDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19231",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19232",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELAPUDUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19233",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PERIYAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19234",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, REGUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19235",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, PUDUKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19236",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THIRUPPULLANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19237",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THINAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19238",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VANNANGUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19239",
            "district": "20",
            "Block": "332",
            "label": "N.M.S. HIGH SCHOOL, METHALODAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19240",
            "district": "20",
            "Block": "332",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL, MUTHUPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19241",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RAMESWARAM NO.I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19242",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, RAMESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19243",
            "district": "20",
            "Block": "169",
            "label": "SRI PARVATHAVARTHINI AMMAN GIRLS HIGHER SECONDARY SCHOOL, RAMESWARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19244",
            "district": "20",
            "Block": "169",
            "label": "KENDRIYA VIDYALAYA HIGH SCHOOL, RAMESWARAM",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "19245",
            "district": "20",
            "Block": "169",
            "label": "VIVEKANANDA VIDYALAYA MIDDLE SCHOOL, RAMESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19246",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GANDHI NAGAR, MANDAPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19247",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, KARAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19248",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEYYAMPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19249",
            "district": "20",
            "Block": "169",
            "label": "KAMARAJ MIDDLE SCHOOL, THANGACHIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19250",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THANGACHIMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19251",
            "district": "20",
            "Block": "169",
            "label": "HOLY CROSS GIRLS HIGHER SECONDARY SCHOOL, THANGACHIMADAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19252",
            "district": "20",
            "Block": "169",
            "label": "PUNITHA YAGAPPAR  HIGHER SECONDARY SCHOOL, THANGACHIMADAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19253",
            "district": "20",
            "Block": "169",
            "label": "PRINCE OF PEACE MIDDLE SCHOOL, THANGACHIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19254",
            "district": "20",
            "Block": "169",
            "label": "ST. ANTONY'S MIDDLE SCHOOL, PAMBAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19255",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PAMBAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19256",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , CHINNAPPALAM, PAMBAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19257",
            "district": "20",
            "Block": "169",
            "label": "PERUNTHALAIVAR KAMARAJAR MIDDLE SCHOOL, PAMBAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19258",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PIRAPPANVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19259",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, SUNDARAMUDAYAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19260",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , THARAVAITHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19261",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VEDHALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19262",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, IRUMENI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19263",
            "district": "20",
            "Block": "169",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL, VERKOTTU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19264",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALAGANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19265",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, KALKINATRUVALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19266",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUYAVANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19267",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, KUMBARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19268",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PUDUMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19269",
            "district": "20",
            "Block": "169",
            "label": "BAHRUDIN BOYS GOVERNMENT HIGHER SECONDARY SCHOOL, PANAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19270",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PANAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19271",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PATTANAMKATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19272",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, RETTAIYURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19273",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SATHANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19274",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THERBOGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19275",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19276",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UCHIPULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19277",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, PERUNGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19278",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL,VALANTHARAVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19279",
            "district": "20",
            "Block": "169",
            "label": "ARABI OLLIYULLA HIGH SCHOOL PUDUVALASAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19280",
            "district": "20",
            "Block": "169",
            "label": "KENDRIYA VIDYALAYA HIGHER SECONDARY SCHOOL, MANDAPAM CAMP",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19281",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANDAPAM CAMP",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19282",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, MANDAPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19283",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KADUKKAIVALASAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19284",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,DHANUSKODI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21035",
            "district": "20",
            "Block": "231",
            "label": "SOURASHTRA MATRICULATION HIGH SCHOOL, EMANESWARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21036",
            "district": "20",
            "Block": "99",
            "label": "RAHMANIA GARDEN MATRIC HIGH SCHOOL, KAMUTHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21037",
            "district": "20",
            "Block": "332",
            "label": "SRI SARASWATHI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, REGUNATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21038",
            "district": "20",
            "Block": "332",
            "label": "SRI KRISHNA MATRICULATION SCHOOL, VANNANGUNDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21116",
            "district": "20",
            "Block": "266",
            "label": "AMRITA VIDYALAYAM, R.S.MADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21117",
            "district": "20",
            "Block": "332",
            "label": "KANNADIVAPPA INTERNATIONAL SCHOOL, KANJIRANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21410",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL,  CHOLAGANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21521",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, VADAVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21522",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, PAZHANCHIRAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21614",
            "district": "20",
            "Block": "335",
            "label": "ST. NORBERT R.C. MATRICULATION HIGH SCHOOL, THIRUVETRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21615",
            "district": "20",
            "Block": "99",
            "label": "ST. JAMES MATRICULATION HIGH SCHOOL, PASUMPON",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21616",
            "district": "20",
            "Block": "86",
            "label": "SARANYA MATRICULATION HIGH SCHOOL, VALINOKKAM VILAKKU, KEELAKIDARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21617",
            "district": "20",
            "Block": "266",
            "label": "SHIFAN NOOR GLOBAL ACADEMY, KOPPERIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21651",
            "district": "20",
            "Block": "266",
            "label": "KRISHNA INTERNATIONAL SCHOOL, ECR DEVIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21859",
            "district": "20",
            "Block": "335",
            "label": "AMEER SULTHAN ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL, THONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21860",
            "district": "20",
            "Block": "231",
            "label": "MOUNT LITERA ZEE CBSE SCHOOL, KAMUDAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22267",
            "district": "20",
            "Block": "86",
            "label": "THIYAKI DHARMAKKAN AMIRTHAM LITTLE ANGEL MATRICULATION SCHOOL, KANNIRAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22268",
            "district": "20",
            "Block": "266",
            "label": "M.G. PUBLIC CBSE SCHOOL, PERAVOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22269",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL ACADEMY CENTRAL SCHOOL, PATTANAMKATHAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22626",
            "district": "20",
            "Block": "231",
            "label": "UDAIYAR MATRICULATION SCHOOL, MELAKAVANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22627",
            "district": "20",
            "Block": "231",
            "label": "GANAPATHY CHETTIAR VIDYALAYA, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22628",
            "district": "20",
            "Block": "231",
            "label": "DR. ABDUL KALAM PUBLIC SCHOOL, PUTHU NAGAR, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22629",
            "district": "20",
            "Block": "41",
            "label": "MEENATCHI MATRIC HIGHER SECONDARY SCHOOL, CHINNA EDAMBADAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22630",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL, DEVIPATTINAM SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22631",
            "district": "20",
            "Block": "332",
            "label": "AL BAYYINAH MATRIC SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22632",
            "district": "20",
            "Block": "169",
            "label": "CHRIST THE KING INTERNATIONAL SCHOOL, MEYYAMPULI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22633",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, RAMESWARAM ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22634",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, VANIYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22695",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL (GIRLS), PUDUMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22716",
            "district": "20",
            "Block": "169",
            "label": "AMRITA VIDYALAYAM, ARIYANGUNDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22717",
            "district": "20",
            "Block": "86",
            "label": "DON BOSCO SCHOOL OF EXCELLENCE, SAYALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22926",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL, VENGALAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23054",
            "district": "20",
            "Block": "266",
            "label": "SYED AMMAL PUBLIC SCHOOL, PERAVOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23055",
            "district": "20",
            "Block": "169",
            "label": "MOHAMED SATHAK KABEER PUBLIC SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23056",
            "district": "20",
            "Block": "169",
            "label": "AL QALAM INTERNATIONAL SCHOOL, PERUNGULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23240",
            "district": "20",
            "Block": "231",
            "label": "MUNICIPAL HIGH SCHOOL, EMANESWARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23315",
            "district": "20",
            "Block": "169",
            "label": "DR. ILAYARAJA GLOBAL ACADEMY, PIRAPPANVALASAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23324",
            "district": "20",
            "Block": "231",
            "label": "KAVINA INTERNATIONAL SCHOOL, PAMBOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23530",
            "district": "20",
            "Block": "169",
            "label": "RAJA PUBLIC SCHOOL, KUNJARVALASAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23531",
            "district": "20",
            "Block": "169",
            "label": "RAMAKRISHNA MATH VIVEKANANDA VIDYALAYA CBSE SCHOOL, NAGACHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23532",
            "district": "20",
            "Block": "266",
            "label": "RAMANATHAPURAM DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23747",
            "district": "20",
            "Block": "41",
            "label": "NETHAJI SUBHASH CHANDRA BOSE AWASIYA VIDYALAYA, SATHIRAKUDI, BOGALUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23748",
            "district": "20",
            "Block": "41",
            "label": "BODHI VIDHYALAYA CBSE SCHOOL, SEYYALUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24017",
            "district": "20",
            "Block": "332",
            "label": "RAMANATHAPURAM DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "21": [
        {
            "key": "538",
            "district": "21",
            "Block": "24",
            "label": "KH MATRIC (G) HSS, MELVISHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "562",
            "district": "21",
            "Block": "21",
            "label": "DR VGN MATRIC HSS, ARAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "568",
            "district": "21",
            "Block": "408",
            "label": "GRACE MATRIC HSS, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "588",
            "district": "21",
            "Block": "24",
            "label": "RATHINAGIRI BAGEERATHAN MATRIC HSS, RATHINAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "592",
            "district": "21",
            "Block": "408",
            "label": "SRI RAMAKRISHNA MATRIC HSS, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "625",
            "district": "21",
            "Block": "409",
            "label": "HINDU VIDYALAYA - CBSE SCHOOL SIPCOT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "687",
            "district": "21",
            "Block": "21",
            "label": "ROYAL MATRIC HSS, THAKKOLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "870",
            "district": "21",
            "Block": "409",
            "label": "CSI VELLORE DIOCESE MATRIC HSS , SIPCOT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "958",
            "district": "21",
            "Block": "210",
            "label": "SRI SARASWATHI MATRIC HSS , REDDIVALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1126",
            "district": "21",
            "Block": "408",
            "label": "MANGALAMBAL AIDED MS WALAJAH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1205",
            "district": "21",
            "Block": "21",
            "label": "BHARATHIDHASANAR MATRIC HR SEC SCHOOL, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1266",
            "district": "21",
            "Block": "116",
            "label": "PUMS-PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1384",
            "district": "21",
            "Block": "408",
            "label": "GANGAADARAA MATRIC HSS, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1443",
            "district": "21",
            "Block": "408",
            "label": "OUR LADY'S MATRIC SCHOOL, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1460",
            "district": "21",
            "Block": "408",
            "label": "SRI VENKATESWARA MATRIC SCHOOL, WALAJAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1477",
            "district": "21",
            "Block": "24",
            "label": "HAKEEM MATRIC SCHOOL , MELVISHARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1546",
            "district": "21",
            "Block": "409",
            "label": "VEDAVALLI VIDYALAYA SENIOR SECONDARY SCHOOL, WALAJAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1634",
            "district": "21",
            "Block": "21",
            "label": "VIVEKANANDA VIDYALAYA MATRIC HSS,ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1637",
            "district": "21",
            "Block": "359",
            "label": "ARCOT SRI MAHALAKSHMI MATRIC HSS, VILAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1653",
            "district": "21",
            "Block": "21",
            "label": "JOTHI VALLALAR MIDDLE SCHOOL ARAKKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1715",
            "district": "21",
            "Block": "409",
            "label": "RISHI MATRIC HSS, SEEKARAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1848",
            "district": "21",
            "Block": "290",
            "label": "SRI DIVYA CHAITANYA MATRIC HSS SHOLINGHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1863",
            "district": "21",
            "Block": "210",
            "label": "SHIRNE VELANKANNI MATRIC SCHOOL, PARAMESWARAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1869",
            "district": "21",
            "Block": "210",
            "label": "DR. NATARAJAN MATRIC HSS, VETTANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2046",
            "district": "21",
            "Block": "359",
            "label": "PUMS ANAIMALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2073",
            "district": "21",
            "Block": "21",
            "label": "MARY'S VISION MHSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2137",
            "district": "21",
            "Block": "21",
            "label": "SMS VIMAL MHSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2157",
            "district": "21",
            "Block": "210",
            "label": "VIVEKANANDHA MATRIC.HR.SEC.SCHOOL SAYANAPURAM-NEMILI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2302",
            "district": "21",
            "Block": "408",
            "label": "GANDHI MIS VI AIDED M S WALAJAPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2360",
            "district": "21",
            "Block": "24",
            "label": "ST. PIO MATRIC HIGH  SCHOOL,  UPPUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2430",
            "district": "21",
            "Block": "408",
            "label": "CNR MATRIC SCHOOL, WALAJA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2577",
            "district": "21",
            "Block": "359",
            "label": "PUMS VELLAMBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2659",
            "district": "21",
            "Block": "359",
            "label": "PUMS PAZHAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2677",
            "district": "21",
            "Block": "408",
            "label": "MMS SRINIVASANPET RANIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2722",
            "district": "21",
            "Block": "116",
            "label": "PUMS-KUPPAKKALMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2734",
            "district": "21",
            "Block": "409",
            "label": "ANNE MARIE MATRIC HSS, THIRUPARKADAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2738",
            "district": "21",
            "Block": "359",
            "label": "PUMS MELNELLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2750",
            "district": "21",
            "Block": "21",
            "label": "SRI KRISHNA MATRIC HSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2817",
            "district": "21",
            "Block": "408",
            "label": "NATIONAL MATRIC SCHOOL, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2820",
            "district": "21",
            "Block": "21",
            "label": "VALLUVAR MATRIC HSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2887",
            "district": "21",
            "Block": "408",
            "label": "GANGADHARA MU AIDED MS RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2906",
            "district": "21",
            "Block": "290",
            "label": "PUMS, PALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2929",
            "district": "21",
            "Block": "21",
            "label": "PUMS, SEMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2961",
            "district": "21",
            "Block": "359",
            "label": "PUMS KALAVAI PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2964",
            "district": "21",
            "Block": "359",
            "label": "PUMS RAGHUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3018",
            "district": "21",
            "Block": "359",
            "label": "PUMS MELAPALANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3019",
            "district": "21",
            "Block": "359",
            "label": "PUMS MELPUDUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3046",
            "district": "21",
            "Block": "359",
            "label": "PUMS VEMBI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3050",
            "district": "21",
            "Block": "21",
            "label": "PUMS NAGARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3160",
            "district": "21",
            "Block": "116",
            "label": "PUMS-UPPARANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3174",
            "district": "21",
            "Block": "408",
            "label": "THIRUMATHI LAKSHMI LOGANATHAN MATRIC SCHOOL, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3176",
            "district": "21",
            "Block": "359",
            "label": "PUMS SORAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3255",
            "district": "21",
            "Block": "409",
            "label": "PUMS V.C.MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3497",
            "district": "21",
            "Block": "359",
            "label": "PUMS NAMBARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3516",
            "district": "21",
            "Block": "359",
            "label": "PUMS PARADHARAMI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3542",
            "district": "21",
            "Block": "290",
            "label": "QUEEN MARY'S MATRIC. HR. SEC. SCHOOL, KATTRAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3611",
            "district": "21",
            "Block": "21",
            "label": "PUMS, ANAIKATTA PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3640",
            "district": "21",
            "Block": "359",
            "label": "PUMS CHENNASAMUTHRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3721",
            "district": "21",
            "Block": "359",
            "label": "PUMS KUTTIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3727",
            "district": "21",
            "Block": "359",
            "label": "PUMS AAYIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3730",
            "district": "21",
            "Block": "116",
            "label": "BHARATHIDHASANAR MATRIC SCHOOL, KAVERIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3765",
            "district": "21",
            "Block": "408",
            "label": "MMS MARKET  WALAJAH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3802",
            "district": "21",
            "Block": "21",
            "label": "SACRED HEART MHSS ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3812",
            "district": "21",
            "Block": "21",
            "label": "PUMS SEYYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3890",
            "district": "21",
            "Block": "359",
            "label": "PUMS GUNDALERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3922",
            "district": "21",
            "Block": "408",
            "label": "VEDANIKETHAN MATRIC HSS, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3995",
            "district": "21",
            "Block": "359",
            "label": "PUMS PUNGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4001",
            "district": "21",
            "Block": "409",
            "label": "VKV MATRIC SCHOOL, VANNIVEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4022",
            "district": "21",
            "Block": "408",
            "label": "THAMIZH THENRAL THIRU VI-KA MIDDLE SCHOOL RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4023",
            "district": "21",
            "Block": "290",
            "label": "PUMS-CHINAPARAVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4070",
            "district": "21",
            "Block": "408",
            "label": "SEVENTHDAY ADVENTIST MATRIC SCHOOL, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4107",
            "district": "21",
            "Block": "290",
            "label": "PUMS VENGUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4168",
            "district": "21",
            "Block": "24",
            "label": "PUMS MANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4297",
            "district": "21",
            "Block": "21",
            "label": "RAILWAY MIXED HS ARAKKONAM",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "4315",
            "district": "21",
            "Block": "359",
            "label": "MOTHER MATRIC SCHOOL, KALAVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4343",
            "district": "21",
            "Block": "24",
            "label": "ST.JOSEPH MATRIC HSS, VEPPOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4384",
            "district": "21",
            "Block": "290",
            "label": "SARASWATHI VELU MATRIC HSS, SHOLINGHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4438",
            "district": "21",
            "Block": "359",
            "label": "PUMS CHENGANAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4503",
            "district": "21",
            "Block": "21",
            "label": "PUMS GEDARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4538",
            "district": "21",
            "Block": "116",
            "label": "PUMS-SIRUVALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4539",
            "district": "21",
            "Block": "359",
            "label": "PUMS KANIYANUR PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4554",
            "district": "21",
            "Block": "408",
            "label": "GANGADHARA AMS ARCOT",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4564",
            "district": "21",
            "Block": "21",
            "label": "ST. XAVIER'S MATRIC SCHOOL, SOGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4608",
            "district": "21",
            "Block": "24",
            "label": "PUNCHAYAT UNION MIDDLE SCHOOL,  NARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4767",
            "district": "21",
            "Block": "408",
            "label": "MMS KARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4786",
            "district": "21",
            "Block": "290",
            "label": "SKMS JAMBUKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4925",
            "district": "21",
            "Block": "408",
            "label": "AMS SRI KRISHNA VIDHYALAYA RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4958",
            "district": "21",
            "Block": "21",
            "label": "SELVAM MATRIC HSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5029",
            "district": "21",
            "Block": "408",
            "label": "SRI ANDAL AMS ARCOT",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5050",
            "district": "21",
            "Block": "210",
            "label": "BHARATH VIDYA MATRIC SCHOOL, SENDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5110",
            "district": "21",
            "Block": "409",
            "label": "PUMS NARASINGAPURAM / AMMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5118",
            "district": "21",
            "Block": "210",
            "label": "SENTHIL KUMARAN PS, GANAPATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5215",
            "district": "21",
            "Block": "408",
            "label": "LFC GIRLS AIDED HSS RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5237",
            "district": "21",
            "Block": "116",
            "label": "SARASWATHI MATRIC HSS, KAVERIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5251",
            "district": "21",
            "Block": "24",
            "label": "PUNCHAYAT UNION MIDDLE SCHOOL, THENNANDIYALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5299",
            "district": "21",
            "Block": "290",
            "label": "PUMS MARUDHALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5377",
            "district": "21",
            "Block": "290",
            "label": "PUMS THALANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5423",
            "district": "21",
            "Block": "290",
            "label": "PUMS - SHOLINGHUR MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5462",
            "district": "21",
            "Block": "290",
            "label": "PUMS KUPPAKKALMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5468",
            "district": "21",
            "Block": "21",
            "label": "PUMS, MARANKANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5506",
            "district": "21",
            "Block": "290",
            "label": "PUMS THANIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5520",
            "district": "21",
            "Block": "21",
            "label": "PUMS KAVANOOR NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5541",
            "district": "21",
            "Block": "290",
            "label": "C.S.I. MARY MCLEAN MIDDLE SCHOOL, SHOLINGHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5561",
            "district": "21",
            "Block": "359",
            "label": "PUMS VARAGUR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5574",
            "district": "21",
            "Block": "290",
            "label": "PUMS G.C. KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5666",
            "district": "21",
            "Block": "290",
            "label": "UNITY MATRIC HSS, PANDIYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5765",
            "district": "21",
            "Block": "408",
            "label": "SHANTHINIKETHAN MATRICULATION SCHOOL  ARCOT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5820",
            "district": "21",
            "Block": "21",
            "label": "GABRIELS MIDDLE SCHOOL ARAKKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5833",
            "district": "21",
            "Block": "359",
            "label": "YOGI VEMANA MATRIC SCHOOL, THIMIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5956",
            "district": "21",
            "Block": "21",
            "label": "PUMS VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5997",
            "district": "21",
            "Block": "408",
            "label": "BEATTIE MEMORIAL MATRIC SCHOOL, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6005",
            "district": "21",
            "Block": "408",
            "label": "ANNAI SARADA MATRIC HSS, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6092",
            "district": "21",
            "Block": "409",
            "label": "SUNBEAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6099",
            "district": "21",
            "Block": "409",
            "label": "SUNBEAM MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6147",
            "district": "21",
            "Block": "210",
            "label": "DR. V.S. ISAAC MATRIC SCHOOL, ATTUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6157",
            "district": "21",
            "Block": "21",
            "label": "PUMS-VEDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6166",
            "district": "21",
            "Block": "24",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KILMINNAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6192",
            "district": "21",
            "Block": "24",
            "label": "SRI JAYENDRA SARASWATHI SWAMIGAL MATRIC SCHOOL,MULLUVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6212",
            "district": "21",
            "Block": "290",
            "label": "PUMS-KUNNATHUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6246",
            "district": "21",
            "Block": "409",
            "label": "LITTLE FLOWER CONVENT MATRIC HSS AMMOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6351",
            "district": "21",
            "Block": "359",
            "label": "PUMS VENKATAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6400",
            "district": "21",
            "Block": "409",
            "label": "BRIGHT MINDS VIDYODAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6405",
            "district": "21",
            "Block": "290",
            "label": "SENGUNTHAR AIDED MIDDLE SCHOOL SHOLINGHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6416",
            "district": "21",
            "Block": "24",
            "label": "KHAIRE JAREE MS , MELVISHARAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6457",
            "district": "21",
            "Block": "409",
            "label": "PUMS RAJIVGANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6498",
            "district": "21",
            "Block": "116",
            "label": "PUMS-MAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6581",
            "district": "21",
            "Block": "24",
            "label": "PUMS UPPUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6630",
            "district": "21",
            "Block": "21",
            "label": "PUMS-THANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6701",
            "district": "21",
            "Block": "210",
            "label": "PUMS, PADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6719",
            "district": "21",
            "Block": "290",
            "label": "PUMS-AYIPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6742",
            "district": "21",
            "Block": "24",
            "label": "PUMS TAJPURA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6745",
            "district": "21",
            "Block": "21",
            "label": "CSI MIDDLE AIDED-PARANJI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8398",
            "district": "21",
            "Block": "21",
            "label": "GHS THANIGAI POLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8399",
            "district": "21",
            "Block": "21",
            "label": "KALVIKKOTTAI CHRISTIAN HR.SEC SCHOOL THANIGAI POLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8400",
            "district": "21",
            "Block": "21",
            "label": "PUMS KIZHANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8401",
            "district": "21",
            "Block": "21",
            "label": "GHS  PERUNGALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8402",
            "district": "21",
            "Block": "21",
            "label": "GHSS VALARPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8403",
            "district": "21",
            "Block": "21",
            "label": "GOVT HR SEC SCHOOL MUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8404",
            "district": "21",
            "Block": "21",
            "label": "GHS MULVOY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8405",
            "district": "21",
            "Block": "21",
            "label": "CSI HS KILPAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8406",
            "district": "21",
            "Block": "21",
            "label": "RCM HS ARAKKONAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8407",
            "district": "21",
            "Block": "21",
            "label": "GHSS  ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8408",
            "district": "21",
            "Block": "21",
            "label": "G(G) HSS ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8409",
            "district": "21",
            "Block": "21",
            "label": "G(G)ADWHSS ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8410",
            "district": "21",
            "Block": "21",
            "label": "CSI ST ANDREWS HSS, ARAKKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8411",
            "district": "21",
            "Block": "21",
            "label": "CSI(C)  HSS ARAKKONAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8412",
            "district": "21",
            "Block": "21",
            "label": "MMS ARAKKONAM EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8413",
            "district": "21",
            "Block": "21",
            "label": "MMS GANDHI NAGER ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8414",
            "district": "21",
            "Block": "21",
            "label": "MMS POLATCHI ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8415",
            "district": "21",
            "Block": "21",
            "label": "M A JAIN MMS WINTERPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8416",
            "district": "21",
            "Block": "21",
            "label": "KENDRIYA VIDHYALAYA HSS PERUMOOCHI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8417",
            "district": "21",
            "Block": "21",
            "label": "DHANAM PACHAIYAPPAN MATRIC HSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8418",
            "district": "21",
            "Block": "21",
            "label": "PUMS PULIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8419",
            "district": "21",
            "Block": "21",
            "label": "GHSS MOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8420",
            "district": "21",
            "Block": "21",
            "label": "PUMS PERUMUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8421",
            "district": "21",
            "Block": "21",
            "label": "G(B)ADWHSS ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8422",
            "district": "21",
            "Block": "21",
            "label": "PUMS POIYAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8423",
            "district": "21",
            "Block": "21",
            "label": "DAYANA MAT SCH NEWPET THAKKOLAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8424",
            "district": "21",
            "Block": "21",
            "label": "G(B)HSS THAKKOLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8425",
            "district": "21",
            "Block": "21",
            "label": "G(G)HSS, THAKKOLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8426",
            "district": "21",
            "Block": "21",
            "label": "DONBOSCO HS SAGAYATHOTTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8427",
            "district": "21",
            "Block": "21",
            "label": "KENDRIYA VIDHYALAYA SCHOOL,  THAKKOLAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8428",
            "district": "21",
            "Block": "21",
            "label": "R C M MIDD SCHOOL URIYURKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8429",
            "district": "21",
            "Block": "116",
            "label": "PUMS, AVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8430",
            "district": "21",
            "Block": "116",
            "label": "RTM HS, KALATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8431",
            "district": "21",
            "Block": "116",
            "label": "PUMS, SANKARANPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8432",
            "district": "21",
            "Block": "116",
            "label": "GHS PERUMPULIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8433",
            "district": "21",
            "Block": "210",
            "label": "PUMS, POIGAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8434",
            "district": "21",
            "Block": "210",
            "label": "GHSS MELAPULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8435",
            "district": "21",
            "Block": "210",
            "label": "PUMS NEDUMPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8436",
            "district": "21",
            "Block": "210",
            "label": "PUMS, THURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8437",
            "district": "21",
            "Block": "210",
            "label": "GHS ULIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8438",
            "district": "21",
            "Block": "210",
            "label": "PUMS, PERAPPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8439",
            "district": "21",
            "Block": "210",
            "label": "GHS KILVEEDHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8440",
            "district": "21",
            "Block": "210",
            "label": "GOVT HS MELERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8441",
            "district": "21",
            "Block": "210",
            "label": "GHSS MAHENDIRAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8442",
            "district": "21",
            "Block": "210",
            "label": "PUMS, VELITHANGIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8443",
            "district": "21",
            "Block": "210",
            "label": "GHS KATTUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8444",
            "district": "21",
            "Block": "210",
            "label": "G ADW HS ELATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8445",
            "district": "21",
            "Block": "21",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MELPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8446",
            "district": "21",
            "Block": "21",
            "label": "GHSS KUMPINIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8447",
            "district": "21",
            "Block": "210",
            "label": "GOVERMENT HIGH SCHOOL, CHITTERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8448",
            "district": "21",
            "Block": "210",
            "label": "GHSS NAGAVEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8449",
            "district": "21",
            "Block": "210",
            "label": "PUMS, KIZHANTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8450",
            "district": "21",
            "Block": "210",
            "label": "PUMS, SIRUNAMALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8451",
            "district": "21",
            "Block": "210",
            "label": "GHSS PALLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8452",
            "district": "21",
            "Block": "210",
            "label": "PUMS, MANAMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8453",
            "district": "21",
            "Block": "210",
            "label": "GHS VETTANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8454",
            "district": "21",
            "Block": "210",
            "label": "PUMS, REDDIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8455",
            "district": "21",
            "Block": "210",
            "label": "PUMS, PILLAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8456",
            "district": "21",
            "Block": "210",
            "label": "GHS KADAMBANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8457",
            "district": "21",
            "Block": "210",
            "label": "PUMS, KILVENPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8458",
            "district": "21",
            "Block": "210",
            "label": "GHSS THIRUMALPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8459",
            "district": "21",
            "Block": "210",
            "label": "GHS, PALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8460",
            "district": "21",
            "Block": "210",
            "label": "PUMS, S.KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8461",
            "district": "21",
            "Block": "210",
            "label": "PUMS, ASANELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8462",
            "district": "21",
            "Block": "210",
            "label": "GHS KILVENKATAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8463",
            "district": "21",
            "Block": "210",
            "label": "GHSS SAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8464",
            "district": "21",
            "Block": "210",
            "label": "GHSS SENDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8465",
            "district": "21",
            "Block": "210",
            "label": "PUMS, ATTUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8466",
            "district": "21",
            "Block": "210",
            "label": "GHS GANAPATHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8467",
            "district": "21",
            "Block": "210",
            "label": "PUMS, MURUNGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8468",
            "district": "21",
            "Block": "210",
            "label": "GHS PARAMESHWARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8469",
            "district": "21",
            "Block": "210",
            "label": "PUMS, ARIGILAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8470",
            "district": "21",
            "Block": "210",
            "label": "G( B)HSS PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8471",
            "district": "21",
            "Block": "210",
            "label": "GOVT MODEL HIGHER SECONDARY SCHOOL , PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8472",
            "district": "21",
            "Block": "210",
            "label": "PUMS, PUNNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8473",
            "district": "21",
            "Block": "210",
            "label": "G(G)HSS NEMILI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8474",
            "district": "21",
            "Block": "210",
            "label": "G(B)HSS NEMILI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8475",
            "district": "21",
            "Block": "290",
            "label": "GHS GANGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8476",
            "district": "21",
            "Block": "21",
            "label": "PUMS, SOGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8477",
            "district": "21",
            "Block": "21",
            "label": "G(G)HSS GURUVARAJAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8478",
            "district": "21",
            "Block": "21",
            "label": "G(B)HSS GURUVARAJAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8479",
            "district": "21",
            "Block": "21",
            "label": "GBHSS MINNAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8480",
            "district": "21",
            "Block": "21",
            "label": "G(G)HSS NARASINGAPURAM(MINNAL)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8481",
            "district": "21",
            "Block": "21",
            "label": "GOVT HIGH SCHOOL MITTAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8482",
            "district": "21",
            "Block": "290",
            "label": "GHSS GUDALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8483",
            "district": "21",
            "Block": "290",
            "label": "GANDHIJI HS KARIKKAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8484",
            "district": "21",
            "Block": "290",
            "label": "CSI HS THALIKAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8485",
            "district": "21",
            "Block": "290",
            "label": "GHS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8486",
            "district": "21",
            "Block": "290",
            "label": "GOVT HS POLIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8487",
            "district": "21",
            "Block": "290",
            "label": "GOVT ADW HS AYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8488",
            "district": "21",
            "Block": "290",
            "label": "GHS SURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8489",
            "district": "21",
            "Block": "116",
            "label": "GOVT BOYS HSS BANAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8490",
            "district": "21",
            "Block": "116",
            "label": "GOVT GIRLS HSS BANAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8491",
            "district": "21",
            "Block": "116",
            "label": "GOVT HS VEDANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8492",
            "district": "21",
            "Block": "116",
            "label": "GOVT HSS PUDHUPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8493",
            "district": "21",
            "Block": "116",
            "label": "RCM MS-ALAPAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8494",
            "district": "21",
            "Block": "116",
            "label": "GOVT HS CHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8495",
            "district": "21",
            "Block": "116",
            "label": "GOVT BOYS HSS KAVERIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8496",
            "district": "21",
            "Block": "116",
            "label": "GOVT GIRLS HSS KAVERIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8497",
            "district": "21",
            "Block": "116",
            "label": "GOVT HS AYARPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8498",
            "district": "21",
            "Block": "116",
            "label": "GOVT HSS SIRUKARUMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8499",
            "district": "21",
            "Block": "21",
            "label": "GOVT HS ANVARTHIKHANPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8500",
            "district": "21",
            "Block": "21",
            "label": "PUMS-KILAVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8501",
            "district": "21",
            "Block": "21",
            "label": "PUMS-ITCHIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8502",
            "district": "21",
            "Block": "21",
            "label": "KINGS & QUEENS CBSE THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8503",
            "district": "21",
            "Block": "290",
            "label": "CSI AIDED HS PARAVATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8522",
            "district": "21",
            "Block": "290",
            "label": "PUMS THAGARAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8523",
            "district": "21",
            "Block": "290",
            "label": "PUMS SENGALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8524",
            "district": "21",
            "Block": "290",
            "label": "GOVT HSS RENDADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8525",
            "district": "21",
            "Block": "290",
            "label": "PUMS DHALAVAIPATTADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8526",
            "district": "21",
            "Block": "290",
            "label": "PUMS PERUNKANCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8527",
            "district": "21",
            "Block": "290",
            "label": "GOVT HSS KODAIKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8528",
            "district": "21",
            "Block": "290",
            "label": "SM AIDED MS KONDAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8529",
            "district": "21",
            "Block": "290",
            "label": "GOVT BOYS HSS SHOLINGHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8530",
            "district": "21",
            "Block": "290",
            "label": "TMT. ETHIRAJAMMAL MUDALIANDAR GOVT. MODEL GIRLS HR. SEC. SCHOOL. SHOLINGHUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8531",
            "district": "21",
            "Block": "290",
            "label": "GOODLET HSS SHOLINGHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8532",
            "district": "21",
            "Block": "290",
            "label": "ASWINI MATRIC HSS, SHOLINGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8533",
            "district": "21",
            "Block": "290",
            "label": "GOVT HS PULIVALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8534",
            "district": "21",
            "Block": "290",
            "label": "ANMMS V.K. THANGAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8535",
            "district": "21",
            "Block": "290",
            "label": "PUMS KATTRAMPAKKAM MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8536",
            "district": "21",
            "Block": "290",
            "label": "GOVT HR SEC SCHOOL NEELAKANDARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8537",
            "district": "21",
            "Block": "290",
            "label": "PUMS VANGHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8538",
            "district": "21",
            "Block": "290",
            "label": "GOVT HSS OZHUGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8539",
            "district": "21",
            "Block": "290",
            "label": "PUMS KOLATHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8540",
            "district": "21",
            "Block": "290",
            "label": "GOVT HS VELAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8541",
            "district": "21",
            "Block": "409",
            "label": "PUMS VASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8542",
            "district": "21",
            "Block": "409",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8543",
            "district": "21",
            "Block": "409",
            "label": "GOVT. HR.SEC.SCHOOL VALLUVAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8544",
            "district": "21",
            "Block": "409",
            "label": "PUMS SENGADU MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8545",
            "district": "21",
            "Block": "409",
            "label": "GOVT HSS AMMOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8546",
            "district": "21",
            "Block": "409",
            "label": "PUMS KALPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8547",
            "district": "21",
            "Block": "409",
            "label": "GOVT HSS LALAPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8548",
            "district": "21",
            "Block": "409",
            "label": "L R SCUDDER HS EKAMBARANALLORE",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8549",
            "district": "21",
            "Block": "409",
            "label": "PUMS SEEKARAJAPURAM MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8550",
            "district": "21",
            "Block": "409",
            "label": "PUMS NARASINGAPURAM / THIRUVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8551",
            "district": "21",
            "Block": "409",
            "label": "SRI RAMAKRISHNA BHEL HR SEC SCHOOL, RANIPET,VELLORE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8552",
            "district": "21",
            "Block": "409",
            "label": "DAV  BHEL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8553",
            "district": "21",
            "Block": "409",
            "label": "ST JOHNS MHS SEEKARAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8554",
            "district": "21",
            "Block": "409",
            "label": "PUMS THENGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8555",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS SIPCOT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8556",
            "district": "21",
            "Block": "409",
            "label": "GOVT H S S, PULLIYANKANNU.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8557",
            "district": "21",
            "Block": "409",
            "label": "AMS VANAPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8558",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS CHETTITHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8559",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS VANNIVEDU MOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8560",
            "district": "21",
            "Block": "409",
            "label": "BALSAM ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8561",
            "district": "21",
            "Block": "409",
            "label": "PM AIDED MS ANANTHALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8562",
            "district": "21",
            "Block": "409",
            "label": "PUMS THENKADAPENTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8563",
            "district": "21",
            "Block": "409",
            "label": "PUMS CHENNASAMUTHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8564",
            "district": "21",
            "Block": "409",
            "label": "GOVT HR. SEC. SCHOOL POONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8565",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS BAGAVELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8566",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS SUMAITHANGI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8567",
            "district": "21",
            "Block": "409",
            "label": "PUMS KADAPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8568",
            "district": "21",
            "Block": "409",
            "label": "SD GOVT HSS THIRUPARKADAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8569",
            "district": "21",
            "Block": "408",
            "label": "MMS ANNA NAGAR ARCOT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8570",
            "district": "21",
            "Block": "408",
            "label": "MHS THOPPUKANA NORTH ARCOT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8571",
            "district": "21",
            "Block": "408",
            "label": "GOVT BOYS HSS ARCOT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8572",
            "district": "21",
            "Block": "24",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL , ARCOT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8573",
            "district": "21",
            "Block": "408",
            "label": "GVC AIDED HSS ARCOT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8574",
            "district": "21",
            "Block": "408",
            "label": "GOVT  HIGHER SECONDARY SCHOOL , RANIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8575",
            "district": "21",
            "Block": "408",
            "label": "GOVT ADW HSS KARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8576",
            "district": "21",
            "Block": "408",
            "label": "GOVT THEERAN SATH MS RANIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8577",
            "district": "21",
            "Block": "408",
            "label": "VRV AIDED HSS RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8578",
            "district": "21",
            "Block": "408",
            "label": "MMS MIXED  WALAJAH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8579",
            "district": "21",
            "Block": "408",
            "label": "GOVT GIRLS HSS WALAJAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8580",
            "district": "21",
            "Block": "408",
            "label": "MHS THOPUKANA SOUTH ARCOT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8581",
            "district": "21",
            "Block": "408",
            "label": "GOVT BOYS HSS VANNIVEDU WALAJA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8582",
            "district": "21",
            "Block": "359",
            "label": "LORD MACAULAY MATRIC HSS, CHINNASALAMANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8583",
            "district": "21",
            "Block": "359",
            "label": "GHSS KAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8584",
            "district": "21",
            "Block": "359",
            "label": "AAGHSS DHAMARAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8585",
            "district": "21",
            "Block": "359",
            "label": "GHS MAZHAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8586",
            "district": "21",
            "Block": "359",
            "label": "GHS KANIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8587",
            "district": "21",
            "Block": "359",
            "label": "GHS IRUNGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8588",
            "district": "21",
            "Block": "359",
            "label": "GHSS PENNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8589",
            "district": "21",
            "Block": "359",
            "label": "GHSS MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8590",
            "district": "21",
            "Block": "359",
            "label": "GHSS VALAPANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8591",
            "district": "21",
            "Block": "359",
            "label": "GBHSS THIMIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8592",
            "district": "21",
            "Block": "359",
            "label": "GGHSS THIMIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8593",
            "district": "21",
            "Block": "359",
            "label": "AAGHSS VILAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8594",
            "district": "21",
            "Block": "359",
            "label": "GGHSS KALAVAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8595",
            "district": "21",
            "Block": "359",
            "label": "GBHSS KALAVAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8596",
            "district": "21",
            "Block": "359",
            "label": "GHSS VALAYATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8597",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS SATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8598",
            "district": "21",
            "Block": "24",
            "label": "PALLAVAN MATRIC HIGH SCHOOL, TAJPURA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8599",
            "district": "21",
            "Block": "24",
            "label": "PUMS MUPPADHUVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8600",
            "district": "21",
            "Block": "24",
            "label": "GHS, V.K.MANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8601",
            "district": "21",
            "Block": "24",
            "label": "PUMS ATHITHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8602",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS PUDUPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8603",
            "district": "21",
            "Block": "24",
            "label": "PVMGHSS,CHAKKARAMALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8604",
            "district": "21",
            "Block": "24",
            "label": "GHSS ESSAYANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8605",
            "district": "21",
            "Block": "24",
            "label": "GHS, ARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8606",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS MECHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8607",
            "district": "21",
            "Block": "24",
            "label": "PUMS METTU MULLUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8608",
            "district": "21",
            "Block": "24",
            "label": "PUMS KEERAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8609",
            "district": "21",
            "Block": "24",
            "label": "GHSS, K.VELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8610",
            "district": "21",
            "Block": "24",
            "label": "PUMS ANANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8611",
            "district": "21",
            "Block": "24",
            "label": "PUMS ARAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8612",
            "district": "21",
            "Block": "24",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, POOTTUTHAKKU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8613",
            "district": "21",
            "Block": "24",
            "label": "GOVERNMENT HIGH SCHOOL, MELAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8614",
            "district": "21",
            "Block": "24",
            "label": "PANCHAYAT UNION MMIDDLE SCOOL,  MANGUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8615",
            "district": "21",
            "Block": "24",
            "label": "PUNCHAYAT UNION MIDDLE SCHOOL,  NANDIYALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8616",
            "district": "21",
            "Block": "24",
            "label": "ANJUMAN E MUYEDUL (M) AMS MELVISHARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8617",
            "district": "21",
            "Block": "24",
            "label": "PUmS HANSA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8618",
            "district": "21",
            "Block": "24",
            "label": "ISLAMIAH B HSS MELVISHARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8619",
            "district": "21",
            "Block": "24",
            "label": "ISLAMIAH G HSS MELVISHARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8620",
            "district": "21",
            "Block": "24",
            "label": "KH MATRIC (B) HSS,  THEN NANDIYALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8621",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS AYILAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8622",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS KATHIYAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8623",
            "district": "21",
            "Block": "24",
            "label": "PUMS, SAMBASIVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8624",
            "district": "21",
            "Block": "24",
            "label": "PUMS, THAZHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8625",
            "district": "21",
            "Block": "24",
            "label": "PUMS, RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8626",
            "district": "21",
            "Block": "24",
            "label": "GOVT (HINDU) HR.SEC.SCHOOL RASATHUPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8627",
            "district": "21",
            "Block": "24",
            "label": "ADWMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8628",
            "district": "21",
            "Block": "24",
            "label": "PUMS, PERIYA KUKKUNDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8629",
            "district": "21",
            "Block": "24",
            "label": "PUMS, CHINNA KUKKUNDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21178",
            "district": "21",
            "Block": "409",
            "label": "VEDAVALLI VIDYALAYA (NPS) SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21569",
            "district": "21",
            "Block": "409",
            "label": "VEDAVALLI HIGHER SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21571",
            "district": "21",
            "Block": "290",
            "label": "VIDYA PEETAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21573",
            "district": "21",
            "Block": "210",
            "label": "CENTUM NURSARY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21574",
            "district": "21",
            "Block": "21",
            "label": "SNV  MATRIC SCHOOL, THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21575",
            "district": "21",
            "Block": "359",
            "label": "DR.VGN MATRIC SCHOOL, KALAVAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21678",
            "district": "21",
            "Block": "290",
            "label": "VALLIYAMMAI MATRIC SCHOOL, SHOLINGHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21680",
            "district": "21",
            "Block": "409",
            "label": "THE GEEKAY WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21771",
            "district": "21",
            "Block": "21",
            "label": "PARAMPARA CBSE SCHOOL, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21772",
            "district": "21",
            "Block": "21",
            "label": "TRS GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21783",
            "district": "21",
            "Block": "24",
            "label": "SMILES INDIA VIDYASHRAM CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21784",
            "district": "21",
            "Block": "24",
            "label": "GLOBAL PUBLIC SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22059",
            "district": "21",
            "Block": "21",
            "label": "GOVT ADW HIGH SCHOOL, AMMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22060",
            "district": "21",
            "Block": "210",
            "label": "GOVT HIGH SCHOOL, JAGIRTHANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22061",
            "district": "21",
            "Block": "409",
            "label": "CHRIST THE KING CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22062",
            "district": "21",
            "Block": "359",
            "label": "Dr.S.R.K VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22378",
            "district": "21",
            "Block": "21",
            "label": "NAVY CHILDREN'S SCHOOL",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "22379",
            "district": "21",
            "Block": "210",
            "label": "GOVT HIGH SCHOOL MELKALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22381",
            "district": "21",
            "Block": "290",
            "label": "SKV INTERNATIONAL PUBLIC SCHOOL ( CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22382",
            "district": "21",
            "Block": "290",
            "label": "GOVT HIGH SCHOOL MELVEERANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22383",
            "district": "21",
            "Block": "359",
            "label": "SRI RAMAKRISHNA VIDHYALAYA NATIONAL INSTITUTE OF OPEN SCHOOLING",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22384",
            "district": "21",
            "Block": "24",
            "label": "INDO AUSTRALIAN ACADEMY OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22736",
            "district": "21",
            "Block": "21",
            "label": "HAYAGRIVAR VIDYAASHRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22737",
            "district": "21",
            "Block": "408",
            "label": "S.S.S.MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22738",
            "district": "21",
            "Block": "359",
            "label": "THE KRISAR ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22739",
            "district": "21",
            "Block": "359",
            "label": "VEDHA VIDHYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22740",
            "district": "21",
            "Block": "359",
            "label": "SRI JAYAM NAMAKKAL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22741",
            "district": "21",
            "Block": "290",
            "label": "VCS HI TECH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22742",
            "district": "21",
            "Block": "409",
            "label": "THE GEEKAY WORLD (IGCSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22747",
            "district": "21",
            "Block": "409",
            "label": "WISDOM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22766",
            "district": "21",
            "Block": "290",
            "label": "SKV MATRICULATION PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22825",
            "district": "21",
            "Block": "21",
            "label": "JEEVA MATRIC. HR. SEC. SCHOOL, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22949",
            "district": "21",
            "Block": "409",
            "label": "GHS, GUDIMALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22951",
            "district": "21",
            "Block": "210",
            "label": "GGHS, THIRUMALPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23165",
            "district": "21",
            "Block": "21",
            "label": "AMBARI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23226",
            "district": "21",
            "Block": "409",
            "label": "GHS (URDU), KALMELKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23310",
            "district": "21",
            "Block": "24",
            "label": "GOVT. ADW.HIGH SCHOOL, VEPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23333",
            "district": "21",
            "Block": "24",
            "label": "MMES PUBLIC SCHOOL, MELVISHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23533",
            "district": "21",
            "Block": "24",
            "label": "RANIPET DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23534",
            "district": "21",
            "Block": "210",
            "label": "Nalanda Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23535",
            "district": "21",
            "Block": "290",
            "label": "MONISHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23536",
            "district": "21",
            "Block": "359",
            "label": "Mahalakshmi Vidhyashram School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23537",
            "district": "21",
            "Block": "408",
            "label": "MERCURY MATRICULATION SCHOOL, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23538",
            "district": "21",
            "Block": "409",
            "label": "LEADERS VIDHYASHRAM CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23723",
            "district": "21",
            "Block": "21",
            "label": "HAYAGRIVAR VIDYAASHRAM",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23727",
            "district": "21",
            "Block": "24",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23728",
            "district": "21",
            "Block": "24",
            "label": "MAYFLOWER SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23823",
            "district": "21",
            "Block": "116",
            "label": "BHARATHIDHASANAR PUBLIC SCHOOL, PANAPAKKAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23963",
            "district": "21",
            "Block": "290",
            "label": "SARASWATHI VIDHYALAYA MATRICULATION SCHOOL MELVENKATAPURAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24035",
            "district": "21",
            "Block": "359",
            "label": "GHSS MAMBAKKAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24086",
            "district": "21",
            "Block": "408",
            "label": "RANIPET DISTRICT GOVERNMENT MODEL SCHOOL ",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24087",
            "district": "21",
            "Block": "409",
            "label": "PUMS VASUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24088",
            "district": "21",
            "Block": "409",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24089",
            "district": "21",
            "Block": "409",
            "label": "PUMS THENGAL",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24090",
            "district": "21",
            "Block": "409",
            "label": "WISDOM MATRICULATION SCHOOL, PADIYAMPAKKAM",
            "Sch_type": "Un-aidedHigh School"
        },
        { 
            "key":"24130", "district":"21", 
            "Block":"21", "label": "JAIG Senior Secondary School", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "24091",
            "district": "21",
            "Block": "409",
            "label": "THE OASIS PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "22": [
        {
            "key": "578",
            "district": "22",
            "Block": "309",
            "label": "SWAMI VIVEKANANDA MATRIC SCHOOL, VEERAGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23656",
            "district": "22",
            "Block": "215",
            "label": "BVM Global @Salem",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "1154",
            "district": "22",
            "Block": "273",
            "label": "ST. VINCENT PALLOTI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1193",
            "district": "22",
            "Block": "61",
            "label": "WISDOM MATRICULATION HR. SEC. SCHOOL, IDAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1380",
            "district": "22",
            "Block": "68",
            "label": "SWAMY VIVEKANANDA VIDYALAYA MATRIC SCHOOL, SENDARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1387",
            "district": "22",
            "Block": "316",
            "label": "SRI VIDHYA MANDIR MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1767",
            "district": "22",
            "Block": "34",
            "label": "ROYAL PARK MATRIC HR. SEC. SCHOOL, KUMARIGIRI PET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1958",
            "district": "22",
            "Block": "34",
            "label": "JAY MATRIC HR. SEC. SCHOOL, VAIKKALPATTARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1974",
            "district": "22",
            "Block": "203",
            "label": "SRI SAKTHI MATRICULATION SCHOOL,VANAVASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1984",
            "district": "22",
            "Block": "30",
            "label": "BHARATHIYAR MATRIC HIGHER SECONDARY SCHOOL, ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1995",
            "district": "22",
            "Block": "34",
            "label": "A.R.R.S. CBSE SCHOOL, VALASAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2153",
            "district": "22",
            "Block": "61",
            "label": "RELIANCE MATRIC HR. SEC. SCHOOL,IDAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2159",
            "district": "22",
            "Block": "61",
            "label": "AMALA MATRIC  HR. SEC. SCHOOL,MORASAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2174",
            "district": "22",
            "Block": "90",
            "label": "GOLDEN RAYS MATRIC SCHOOL, KADAYAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2214",
            "district": "22",
            "Block": "163",
            "label": "JAYAM MATRIC. HR. SEC. SCHOOL, NALLANAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2263",
            "district": "22",
            "Block": "274",
            "label": "SRI SARADA BALAMANDIR. BOYS MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2271",
            "district": "22",
            "Block": "274",
            "label": "BALA BARATHI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2300",
            "district": "22",
            "Block": "203",
            "label": "SRI NACHIYAR MATRIC SCHOOL, MALAYAMPALAYAM, JALAGANDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2320",
            "district": "22",
            "Block": "61",
            "label": "S.K.T. MATRIC HR. SEC. SCHOOL, POOLAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2324",
            "district": "22",
            "Block": "273",
            "label": "MODERN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2344",
            "district": "22",
            "Block": "273",
            "label": "GLAZE BROOKE MATRIC HR. SEC. SCHOOL, N.M.ADIVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2456",
            "district": "22",
            "Block": "203",
            "label": "MINERRVA MATRIC HIGHER SECONDARY SCHOOL, KATTAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2488",
            "district": "22",
            "Block": "274",
            "label": "JAYARANI MATRIC HR. SEC. SCHOOL, NETHIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2504",
            "district": "22",
            "Block": "274",
            "label": "C.S.I. GOOD SHEPHERD MATRIC HR. SEC. SCHOOL,FORT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2555",
            "district": "22",
            "Block": "129",
            "label": "HOLY ANGELS MATRIC HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2653",
            "district": "22",
            "Block": "391",
            "label": "SRI VIDYA MANDIR CBSE SCHOOL, SALEM STEEL PLANT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2662",
            "district": "22",
            "Block": "309",
            "label": "JAYA BHARATH MATRIC HR. SEC. SCHOOL,VADA CHENNIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2724",
            "district": "22",
            "Block": "391",
            "label": "SRI VIDYA MANDIR MATRIC HR. SEC. SCHOOL, ELAMPILLAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2736",
            "district": "22",
            "Block": "278",
            "label": "BRINDAVAN MATRIC SCHOOL THEVOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2744",
            "district": "22",
            "Block": "68",
            "label": "SARASWATHI MATRIC SCHOOL, ULIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2821",
            "district": "22",
            "Block": "273",
            "label": "P.V. MATRIC HR. SEC. SCHOOL, SIVADHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2870",
            "district": "22",
            "Block": "203",
            "label": "ISHA VIDHYA RAMANIYAM MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2875",
            "district": "22",
            "Block": "215",
            "label": "SOUTH INDIAN MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2985",
            "district": "22",
            "Block": "226",
            "label": "SALEM SRI SOWDESWARI MATRIC HR. SEC. SCHOOL, KONDALAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3015",
            "district": "22",
            "Block": "34",
            "label": "SRI SWAMY INTERNATIONAL SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3145",
            "district": "22",
            "Block": "316",
            "label": "VETHATHIRI MAHARISHI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3190",
            "district": "22",
            "Block": "129",
            "label": "ST.MARY'S MATRIC HR.SEC.SCHOOL,METTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3195",
            "district": "22",
            "Block": "34",
            "label": "SHRI SWAMY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3198",
            "district": "22",
            "Block": "278",
            "label": "PSF MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3267",
            "district": "22",
            "Block": "274",
            "label": "SRI SARADA MATRIC HR. SEC. SCHOOL FOR GIRLS,FAIRLANDS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3289",
            "district": "22",
            "Block": "273",
            "label": "ST. JOSEPH MATRICULATION SCHOOL SURAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        { 
            "key":"24129", "district":"22", 
            "Block":"30", "label": "St. Joseph School", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "3290",
            "district": "22",
            "Block": "246",
            "label": "RAJ MATRIC HR. SEC. SCHOOL, PUTHIRAGOUNDAMPALAYAM, ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3337",
            "district": "22",
            "Block": "34",
            "label": "SRI VIVEKANANDA PATASALAI MATRIC SCHOOL, SATHIYA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3348",
            "district": "22",
            "Block": "215",
            "label": "KONGU MATRIC HR. SEC. SCHOOL,VELLAKALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3393",
            "district": "22",
            "Block": "383",
            "label": "THIAGARAJAR MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3394",
            "district": "22",
            "Block": "129",
            "label": "VALLUVAR VASUKI MATRIC HR. SEC. SCHOOL,NATTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3421",
            "district": "22",
            "Block": "383",
            "label": "HOLY CHILDREN MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3484",
            "district": "22",
            "Block": "278",
            "label": "Shri Vidya Saagar Matric School, Nagichettipatti",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3491",
            "district": "22",
            "Block": "226",
            "label": "SRI VIDHYA BHARATI MATRIC HR. SEC. SCHOOL, NEIKARAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3531",
            "district": "22",
            "Block": "30",
            "label": "MALAR MATRIC HR SEC SCHOOL, THULUKKANUR, KALLANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3655",
            "district": "22",
            "Block": "68",
            "label": "LUCKY MATRIC SCHOOL, THAMMAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3814",
            "district": "22",
            "Block": "274",
            "label": "YUVA BHARATHI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3822",
            "district": "22",
            "Block": "273",
            "label": "N.P.R. MATRIC HR. SEC. SCHOOL , NATTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3825",
            "district": "22",
            "Block": "129",
            "label": "M.A.M.MATRIC HR.SEC.SCHOOL,MATHAIYANKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3834",
            "district": "22",
            "Block": "273",
            "label": "SENGUNTHAR MATRIC  HR. SEC. SCHOOL, SANYASIKUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3873",
            "district": "22",
            "Block": "383",
            "label": "ST. MICHAEL'S MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3874",
            "district": "22",
            "Block": "30",
            "label": "SARASWATHI MATRIC SCHOOL, MULLAIVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3884",
            "district": "22",
            "Block": "383",
            "label": "SRI THIAGARAJAR MEMORIAL MATRICULATION SCHOOL,BELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3969",
            "district": "22",
            "Block": "273",
            "label": "SRI VIDYA MANDIR SR. SEC. SCHOOL (CBSE), MEIYYANOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3971",
            "district": "22",
            "Block": "133",
            "label": "A.G.N. MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3978",
            "district": "22",
            "Block": "163",
            "label": "SWAMY VIVEKANANDAR MATRIC HR. SEC. SCHOOL,KANCHAMALAYUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4019",
            "district": "22",
            "Block": "278",
            "label": "AKASH VIDHYA BHAVAN MATRIC HR SEC SCHOOL, SANKARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4021",
            "district": "22",
            "Block": "61",
            "label": "ST.MARY'S MATRIC SCHOOL, VELLANDIVALASU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4207",
            "district": "22",
            "Block": "179",
            "label": "SRI RAGHAVENDRA MATRIC HR SEC SCHOOL MECHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4212",
            "district": "22",
            "Block": "203",
            "label": "MALCO VIDYALAYA MATRIC HR. SEC. SCHOOL, METTUR DAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4220",
            "district": "22",
            "Block": "30",
            "label": "VIVEKANANDA MATRICULATION HR.SEC. SCHOOL,PUDUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4247",
            "district": "22",
            "Block": "391",
            "label": "VIVEKANANDA BALA MANDIR MATRIC SCHOOL, ATTAYAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4271",
            "district": "22",
            "Block": "274",
            "label": "VINAYAKA VIDYALAYA MATRIC HR. SEC. SCHOOL,FAIRLANDS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4285",
            "district": "22",
            "Block": "129",
            "label": "APPLE PERKS INTERNATIONAL SCHOOL,KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4286",
            "district": "22",
            "Block": "383",
            "label": "VAIGAI MATRIC. HR.SEC SCHOOL VALAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4288",
            "district": "22",
            "Block": "274",
            "label": "ST JOSEPH'S MATRIC HR. SEC. SCHOOL, SALEM-6",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4346",
            "district": "22",
            "Block": "34",
            "label": "KAILASH MAANSAROVAR HR. SEC. SCHOOL K.N.COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4353",
            "district": "22",
            "Block": "413",
            "label": "OUR LADY OF VELANKANNI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4358",
            "district": "22",
            "Block": "383",
            "label": "SARASWATHI MATRIC HR. SEC. SCHOOL, VALAPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4366",
            "district": "22",
            "Block": "274",
            "label": "HOLY FLOWER MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4367",
            "district": "22",
            "Block": "309",
            "label": "BHARATHI VIDHYALAYA MATRIC HR. SEC. SCHOOL, SIRUVACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4376",
            "district": "22",
            "Block": "274",
            "label": "BALA GURUKULAM MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4542",
            "district": "22",
            "Block": "309",
            "label": "SRI VENKATESWARA MATRIC SCHOOL, NATHAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4753",
            "district": "22",
            "Block": "246",
            "label": "GNANADEEPAM MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4778",
            "district": "22",
            "Block": "215",
            "label": "KALAIMAGAL MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4845",
            "district": "22",
            "Block": "273",
            "label": "GLAZE BROOKE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4859",
            "district": "22",
            "Block": "163",
            "label": "SRI AMMAN MATRIC HR. SEC. SCHOOL, VAIKUNTHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4893",
            "district": "22",
            "Block": "68",
            "label": "BHARATHI MATRIC HR. SEC. SCHOOL, THAMMAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5047",
            "district": "22",
            "Block": "30",
            "label": "RASI MATRIC HR. SEC. SCHOOL, MALLIYAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5154",
            "district": "22",
            "Block": "246",
            "label": "PUMS-MANNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5295",
            "district": "22",
            "Block": "34",
            "label": "SRI VIDYA MANDIR HR. SEC. SCHOOL, RAMALINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5330",
            "district": "22",
            "Block": "383",
            "label": "VENUS VIDYALAYA MATRICULATION SCHOOL,VEPPILAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5344",
            "district": "22",
            "Block": "273",
            "label": "S.R.K. MATRIC HR. SEC. SCHOOL,KANDAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5463",
            "district": "22",
            "Block": "274",
            "label": "ANDERSON MATRIC HR. SEC. SCHOOL,YERCAUD MAIN ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5690",
            "district": "22",
            "Block": "30",
            "label": "SARASWATHI MHSS, AMMAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5745",
            "district": "22",
            "Block": "90",
            "label": "NEW INDIA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5768",
            "district": "22",
            "Block": "30",
            "label": "HOLY MOTHER MATRICULATION SCHOOL, KEERIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5985",
            "district": "22",
            "Block": "129",
            "label": "MONTFORT MATRIC HR. SEC. SCHOOL, KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6061",
            "district": "22",
            "Block": "68",
            "label": "SRI DEVI VIDHYALAYA MATRIC HR. SEC. SCHOOL, ANAIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6074",
            "district": "22",
            "Block": "273",
            "label": "CHINMAYA VIDYALAYA MATRIC HIGH SCHOOL,SOLAMPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6080",
            "district": "22",
            "Block": "391",
            "label": "SRI VIDYA MANDIR HR. SEC. SCHOOL (MATRIC), STEEL PLANT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6289",
            "district": "22",
            "Block": "68",
            "label": "NATIONAL MATRIC HR. SEC. SCHOOL, ANAIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6392",
            "district": "22",
            "Block": "274",
            "label": "SRI VIDYA MANDIR SR. SEC. SCHOOL, SHIVAJI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6403",
            "district": "22",
            "Block": "273",
            "label": "A.R. MATRIC HR.SEC.SCHOOL, PERUMALMALAIADIVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6511",
            "district": "22",
            "Block": "179",
            "label": "SRI SARASWATHI  VIDHYALAYA MATRIC HR SEC SCHOOL,KUTTAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6607",
            "district": "22",
            "Block": "179",
            "label": "MEENAMPARK MATRIC HR. SEC. SCHOOL,MECHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6611",
            "district": "22",
            "Block": "274",
            "label": "PUSHPA MATRIC SCHOOL,GUGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6617",
            "district": "22",
            "Block": "274",
            "label": "SRI VASAVI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6640",
            "district": "22",
            "Block": "203",
            "label": "KALAIMAGAL MATRIC HR. SEC. SCHOOL,NANGAVALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6653",
            "district": "22",
            "Block": "163",
            "label": "VINAYAGA  MATRIC HR. SEC. SCHOOL, KANNANTHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6661",
            "district": "22",
            "Block": "278",
            "label": "PSG MATRICULATION HIGHER SECONDARY SCHOOL SANKARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10952",
            "district": "22",
            "Block": "68",
            "label": "PUMS, MOOKAGOUNDANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10953",
            "district": "22",
            "Block": "68",
            "label": "GHS ULIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10954",
            "district": "22",
            "Block": "68",
            "label": "PUMS, NAGIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10955",
            "district": "22",
            "Block": "68",
            "label": "PUMS, THANEERTHOTTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10956",
            "district": "22",
            "Block": "68",
            "label": "VIDYO AMIRTHAS PUBLIC SCHOOL, NAGIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10957",
            "district": "22",
            "Block": "68",
            "label": "PUMS, MOOLAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10958",
            "district": "22",
            "Block": "68",
            "label": "GHSS KONDAIYAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10959",
            "district": "22",
            "Block": "68",
            "label": "GBHS SENTHARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10960",
            "district": "22",
            "Block": "68",
            "label": "GGHSS SENTHARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10961",
            "district": "22",
            "Block": "68",
            "label": "ST.MARY'S MIDDLE SCHOOL, KONERIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10962",
            "district": "22",
            "Block": "68",
            "label": "PUMS, THAMMAMPATTI 12th WARD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10963",
            "district": "22",
            "Block": "68",
            "label": "PUMS, GANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10964",
            "district": "22",
            "Block": "68",
            "label": "GBHSS THAMAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10965",
            "district": "22",
            "Block": "68",
            "label": "GGHSS THAMAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10966",
            "district": "22",
            "Block": "68",
            "label": "PUMS, VALAKKOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10967",
            "district": "22",
            "Block": "68",
            "label": "GOVT HSS GUDAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10968",
            "district": "22",
            "Block": "68",
            "label": "SREE VIDHYA VIKAS MATRIC HR. SEC. SCHOOL, GUDAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10969",
            "district": "22",
            "Block": "68",
            "label": "NEW INDIA MATRIC SCHOOL, GUDAMALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10970",
            "district": "22",
            "Block": "68",
            "label": "PUMS, 74 KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10971",
            "district": "22",
            "Block": "68",
            "label": "GTR HS ODAIKATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10972",
            "district": "22",
            "Block": "68",
            "label": "GHS KADAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10973",
            "district": "22",
            "Block": "68",
            "label": "PUMS, GANGAVALLI 10th WARD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10974",
            "district": "22",
            "Block": "68",
            "label": "GBHSS GANGAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10975",
            "district": "22",
            "Block": "68",
            "label": "GGHSS GANGAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10976",
            "district": "22",
            "Block": "68",
            "label": "SRI SARADHA MANDIR MATRIC HR. SEC. SCHOOL, GANGAVALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10977",
            "district": "22",
            "Block": "68",
            "label": "PUMS, ODHIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10978",
            "district": "22",
            "Block": "68",
            "label": "PUMS, GANGAVALLI MAIN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10979",
            "district": "22",
            "Block": "68",
            "label": "GHSS THEDAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10980",
            "district": "22",
            "Block": "246",
            "label": "GHS NEIYAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10981",
            "district": "22",
            "Block": "246",
            "label": "GHSS THUMBAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10982",
            "district": "22",
            "Block": "246",
            "label": "ST. MICHEAL'S MATRIC SCHOOL,THUMBAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10983",
            "district": "22",
            "Block": "246",
            "label": "PUMS-MAMANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10984",
            "district": "22",
            "Block": "246",
            "label": "PUMS-ELUPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10985",
            "district": "22",
            "Block": "246",
            "label": "PUMS-PAPPANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10986",
            "district": "22",
            "Block": "246",
            "label": "PUMS-EDAYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10987",
            "district": "22",
            "Block": "246",
            "label": "GHSS PANAIMADAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10988",
            "district": "22",
            "Block": "246",
            "label": "GHS THANDANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10989",
            "district": "22",
            "Block": "246",
            "label": "PUMS-SEKKADIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10990",
            "district": "22",
            "Block": "246",
            "label": "PUMS-A.KOMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10991",
            "district": "22",
            "Block": "246",
            "label": "GHS KOTTAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10992",
            "district": "22",
            "Block": "246",
            "label": "PUMS-KALLERIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10993",
            "district": "22",
            "Block": "246",
            "label": "PUMS-YETHAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10994",
            "district": "22",
            "Block": "246",
            "label": "GGHS YETHAPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10995",
            "district": "22",
            "Block": "246",
            "label": "GHSS YETHAPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10996",
            "district": "22",
            "Block": "246",
            "label": "EKALAIVA MODEL HR. SEC. SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10997",
            "district": "22",
            "Block": "246",
            "label": "PUMS-VAITHIYAGOUNDANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10998",
            "district": "22",
            "Block": "246",
            "label": "GHS PERIYAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10999",
            "district": "22",
            "Block": "246",
            "label": "K.G.B.V. PERIYAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11000",
            "district": "22",
            "Block": "246",
            "label": "PUMS-CHINNAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11001",
            "district": "22",
            "Block": "246",
            "label": "GHS THAMAYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11002",
            "district": "22",
            "Block": "246",
            "label": "GHS ARIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11003",
            "district": "22",
            "Block": "246",
            "label": "GBHSS PETHANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11004",
            "district": "22",
            "Block": "246",
            "label": "PUMS-PALANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11005",
            "district": "22",
            "Block": "246",
            "label": "GGHSS PETHANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11006",
            "district": "22",
            "Block": "246",
            "label": "PUMS-OLAPPADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11007",
            "district": "22",
            "Block": "246",
            "label": "PUMS-THALAVAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11008",
            "district": "22",
            "Block": "246",
            "label": "PUMS-PALANIYAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11009",
            "district": "22",
            "Block": "246",
            "label": "GHS GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11010",
            "district": "22",
            "Block": "246",
            "label": "GTRMS THALVALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11011",
            "district": "22",
            "Block": "246",
            "label": "GTRMS-PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11012",
            "district": "22",
            "Block": "246",
            "label": "ASISI MIDDLE SCHOOL, KARUMANDURAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "11013",
            "district": "22",
            "Block": "246",
            "label": "GTR HR. SEC. SCHOOL, KARUMANDHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11014",
            "district": "22",
            "Block": "246",
            "label": "GTR HIGH SCHOOL, THEKKAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11015",
            "district": "22",
            "Block": "246",
            "label": "GTRHSS, KARIYAKOILVALAVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11016",
            "district": "22",
            "Block": "246",
            "label": "GTRMS-SOOLANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11017",
            "district": "22",
            "Block": "90",
            "label": "PUMS, ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11018",
            "district": "22",
            "Block": "90",
            "label": "PUMS, VEERIYANTHANDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11019",
            "district": "22",
            "Block": "90",
            "label": "PUMS, V KONGARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11020",
            "district": "22",
            "Block": "90",
            "label": "ST. ANGELA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11021",
            "district": "22",
            "Block": "90",
            "label": "PUMS, VEERATCHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11022",
            "district": "22",
            "Block": "90",
            "label": "PUMS, K N PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11023",
            "district": "22",
            "Block": "90",
            "label": "GHSS,K.MORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11024",
            "district": "22",
            "Block": "90",
            "label": "GHSS, RAMAMOORTHI NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11025",
            "district": "22",
            "Block": "90",
            "label": "PUMS, KANNAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11026",
            "district": "22",
            "Block": "90",
            "label": "K.G.B.V KADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11027",
            "district": "22",
            "Block": "90",
            "label": "PUMS, DANISHPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11028",
            "district": "22",
            "Block": "90",
            "label": "GHSS,PERIYA VADAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11029",
            "district": "22",
            "Block": "90",
            "label": "BETHEL MATRICULATION HR.SEC SCHOOL, DANISHPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11030",
            "district": "22",
            "Block": "90",
            "label": "PUMS, UMBILICKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11031",
            "district": "22",
            "Block": "90",
            "label": "GHS,BOMMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11032",
            "district": "22",
            "Block": "90",
            "label": "PUMS, SUNDAKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11033",
            "district": "22",
            "Block": "90",
            "label": "GHSS,NADUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11034",
            "district": "22",
            "Block": "90",
            "label": "A.M. PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11035",
            "district": "22",
            "Block": "90",
            "label": "PUMS, KADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11036",
            "district": "22",
            "Block": "90",
            "label": "SWAMY VIVEKANANDHA VIDHYALAYA MATRIC HR. SEC. SCHOOL,KADAYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11037",
            "district": "22",
            "Block": "90",
            "label": "PUMS, ELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11038",
            "district": "22",
            "Block": "90",
            "label": "PUMS, THALAVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11039",
            "district": "22",
            "Block": "90",
            "label": "PUMS, GURUVAREDDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11040",
            "district": "22",
            "Block": "90",
            "label": "GHSS,GUNDUKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11041",
            "district": "22",
            "Block": "90",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11042",
            "district": "22",
            "Block": "90",
            "label": "GHS,KONGUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11043",
            "district": "22",
            "Block": "90",
            "label": "PUMS, NALLUR MANIYAKARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11044",
            "district": "22",
            "Block": "90",
            "label": "PUMS, PUDURKARUVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11045",
            "district": "22",
            "Block": "90",
            "label": "PUMS, KARUVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11046",
            "district": "22",
            "Block": "90",
            "label": "PUMS, MARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11047",
            "district": "22",
            "Block": "90",
            "label": "GHS, MOOKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11048",
            "district": "22",
            "Block": "90",
            "label": "GHS, DEEVATTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11049",
            "district": "22",
            "Block": "90",
            "label": "GANGAKAVERI HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11050",
            "district": "22",
            "Block": "90",
            "label": "GANGAKAVERI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11051",
            "district": "22",
            "Block": "90",
            "label": "PUMS, POOSARIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11052",
            "district": "22",
            "Block": "90",
            "label": "MOUNT SHERVAROYS MATRICULATION SCHOOL, POOSARIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11053",
            "district": "22",
            "Block": "90",
            "label": "PUMS, DASASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11054",
            "district": "22",
            "Block": "90",
            "label": "MODEL SCHOOL ,KADAYAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11055",
            "district": "22",
            "Block": "90",
            "label": "GHSS, PANNAPPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11056",
            "district": "22",
            "Block": "90",
            "label": "PUMS, THINNAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11057",
            "district": "22",
            "Block": "90",
            "label": "PUMS, MATTUKARANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11058",
            "district": "22",
            "Block": "90",
            "label": "GHSS,DHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11059",
            "district": "22",
            "Block": "90",
            "label": "GGHS,DHARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11060",
            "district": "22",
            "Block": "90",
            "label": "PUMS, KOTTAMEDU NEW SCHOOL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11061",
            "district": "22",
            "Block": "90",
            "label": "PUMS, PAPPICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11062",
            "district": "22",
            "Block": "90",
            "label": "GHSS,KANJANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11063",
            "district": "22",
            "Block": "90",
            "label": "PUMS, SEMMANDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11064",
            "district": "22",
            "Block": "90",
            "label": "GHSS,PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11065",
            "district": "22",
            "Block": "163",
            "label": "PUMS VELLAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11066",
            "district": "22",
            "Block": "163",
            "label": "GHSS, VAIKUNTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11067",
            "district": "22",
            "Block": "163",
            "label": "PUMS, UNJAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11068",
            "district": "22",
            "Block": "163",
            "label": "GHSS ALAGAPPAMPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11069",
            "district": "22",
            "Block": "163",
            "label": "KURINJI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11070",
            "district": "22",
            "Block": "163",
            "label": "PUMS EDANGANASALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11071",
            "district": "22",
            "Block": "163",
            "label": "PUMS, E.METTUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11072",
            "district": "22",
            "Block": "163",
            "label": "PUMS, RASIGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11073",
            "district": "22",
            "Block": "163",
            "label": "PUMS, KADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11074",
            "district": "22",
            "Block": "163",
            "label": "PUMS, NALLANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11075",
            "district": "22",
            "Block": "163",
            "label": "G.H.S.S., K.K.NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11076",
            "district": "22",
            "Block": "163",
            "label": "JOTHI VIDHYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11077",
            "district": "22",
            "Block": "163",
            "label": "GHS, VALAYACHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11078",
            "district": "22",
            "Block": "163",
            "label": "PUMS THAPPAKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11079",
            "district": "22",
            "Block": "163",
            "label": "PUMS METTUTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11080",
            "district": "22",
            "Block": "163",
            "label": "G.B.H.S.S  MAGUDANCHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11081",
            "district": "22",
            "Block": "163",
            "label": "G.G.H.S.S. MAGUDANCHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11082",
            "district": "22",
            "Block": "163",
            "label": "K.G.B.V. MAGUDANCHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11083",
            "district": "22",
            "Block": "163",
            "label": "LOTUS MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11084",
            "district": "22",
            "Block": "163",
            "label": "PUMS, GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11085",
            "district": "22",
            "Block": "163",
            "label": "PUMS, KANDARKULAMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11086",
            "district": "22",
            "Block": "133",
            "label": "PUMS MUTHIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11087",
            "district": "22",
            "Block": "133",
            "label": "PUMS PUDHUPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11088",
            "district": "22",
            "Block": "133",
            "label": "PUMS ARISIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11089",
            "district": "22",
            "Block": "133",
            "label": "PUMS MANICKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11090",
            "district": "22",
            "Block": "133",
            "label": "GHSS SAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11091",
            "district": "22",
            "Block": "133",
            "label": "PUMS V.METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11092",
            "district": "22",
            "Block": "133",
            "label": "PUMS PACHALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11093",
            "district": "22",
            "Block": "133",
            "label": "PUMS MUNIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11094",
            "district": "22",
            "Block": "133",
            "label": "GHSS V.METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11095",
            "district": "22",
            "Block": "133",
            "label": "MODEL SCHOOL,KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11096",
            "district": "22",
            "Block": "133",
            "label": "GHS VELLALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11097",
            "district": "22",
            "Block": "133",
            "label": "GHS KANNIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11098",
            "district": "22",
            "Block": "133",
            "label": "PUMS DEVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11099",
            "district": "22",
            "Block": "133",
            "label": "PUMS RAYANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11100",
            "district": "22",
            "Block": "133",
            "label": "PUMS KOMBAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11101",
            "district": "22",
            "Block": "133",
            "label": "GHS KORANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11102",
            "district": "22",
            "Block": "133",
            "label": "PUMS ALAGAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11103",
            "district": "22",
            "Block": "133",
            "label": "PUMS K.VADUKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11104",
            "district": "22",
            "Block": "133",
            "label": "PUMS K.PALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11105",
            "district": "22",
            "Block": "133",
            "label": "PUMS THONDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11106",
            "district": "22",
            "Block": "133",
            "label": "PUMS KONANKIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11107",
            "district": "22",
            "Block": "133",
            "label": "PUMS ETTIKUTTAI MEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11108",
            "district": "22",
            "Block": "133",
            "label": "GHS KATCHUPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11109",
            "district": "22",
            "Block": "133",
            "label": "PUMS KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11110",
            "district": "22",
            "Block": "133",
            "label": "PUMS RAKKIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11111",
            "district": "22",
            "Block": "133",
            "label": "GGHSS, KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11112",
            "district": "22",
            "Block": "133",
            "label": "GBHSS, KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11113",
            "district": "22",
            "Block": "133",
            "label": "K.G.B.V. KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11114",
            "district": "22",
            "Block": "133",
            "label": "PUMS NACHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11115",
            "district": "22",
            "Block": "133",
            "label": "PUMS VENDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11116",
            "district": "22",
            "Block": "133",
            "label": "PUMS THANGAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11117",
            "district": "22",
            "Block": "133",
            "label": "PUMS KONANGKUTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11118",
            "district": "22",
            "Block": "226",
            "label": "PUMS,RANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11119",
            "district": "22",
            "Block": "226",
            "label": "GOVT MODEL HSS JARI KONDALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11120",
            "district": "22",
            "Block": "226",
            "label": "GOVT BOYS HSS JARI KONDALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11121",
            "district": "22",
            "Block": "226",
            "label": "SRI VIDYA MANDIR HR. SEC. SCHOOL, KONDALAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11122",
            "district": "22",
            "Block": "226",
            "label": "PUMS,MALANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11123",
            "district": "22",
            "Block": "226",
            "label": "GOVT HSS NEIKARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11124",
            "district": "22",
            "Block": "226",
            "label": "PUMS,KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11125",
            "district": "22",
            "Block": "226",
            "label": "PUMS,GANDHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11126",
            "district": "22",
            "Block": "226",
            "label": "GOLDEN CHOICE MATRIC HR. SEC. SCHOOL, DASANAICKENPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11127",
            "district": "22",
            "Block": "226",
            "label": "GHSS,DASANAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11128",
            "district": "22",
            "Block": "226",
            "label": "K.G.B.V. SCHOOL, NILAVARAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11129",
            "district": "22",
            "Block": "226",
            "label": "PUMS,THAMMANAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11130",
            "district": "22",
            "Block": "226",
            "label": "panchayat union middle school vaniyampady",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11131",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EARVADI VANIYAMBADI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11132",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VALAKUTTAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11133",
            "district": "22",
            "Block": "226",
            "label": "PUMS,PARAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11134",
            "district": "22",
            "Block": "226",
            "label": "PUMS,MECHERIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11135",
            "district": "22",
            "Block": "226",
            "label": "PUMS,KOZHINCHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11136",
            "district": "22",
            "Block": "226",
            "label": "GHSS,NAZHIKKALPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11137",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SANTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11138",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11139",
            "district": "22",
            "Block": "226",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11140",
            "district": "22",
            "Block": "226",
            "label": "VEE GEE VIKAS PUBLIC SCHOOL,MALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11141",
            "district": "22",
            "Block": "226",
            "label": "PUMS,S-ATTAYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11142",
            "district": "22",
            "Block": "226",
            "label": "GOVT HIGH SCHOOL GAJJALNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11143",
            "district": "22",
            "Block": "226",
            "label": "PUMS,JARUGUMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11144",
            "district": "22",
            "Block": "226",
            "label": "VEDHHA VIKKAS MATRIC HR. SEC. SCHOOL, PANAMARATHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23420",
            "district": "22",
            "Block": "61",
            "label": "Bharathi vidyalaya nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23416",
            "district": "22",
            "Block": "413",
            "label": "Brightsun Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "11145",
            "district": "22",
            "Block": "226",
            "label": "GOVT HR SEC SCHOOL, PANAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11146",
            "district": "22",
            "Block": "226",
            "label": "CHRISTOPHER MATRIC HR. SEC. SCHOOL, PANAMARATHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11147",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,THIPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11148",
            "district": "22",
            "Block": "226",
            "label": "GOVERNMENT HIGHER SECONDERY SCHOOL,KULLAPPANAICKANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11149",
            "district": "22",
            "Block": "226",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL, KONAMADUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11150",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,ADIMALAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11151",
            "district": "22",
            "Block": "309",
            "label": "GHS, SADHASIVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11152",
            "district": "22",
            "Block": "309",
            "label": "PUMS, SARVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11153",
            "district": "22",
            "Block": "309",
            "label": "LITTLE FLOWER MATRIC HR SEC SCHOOL, NATHAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11154",
            "district": "22",
            "Block": "309",
            "label": "GHS, SARVOYPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11155",
            "district": "22",
            "Block": "309",
            "label": "GHS, VADA CHENNIMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11156",
            "district": "22",
            "Block": "309",
            "label": "GHSS, KATTUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11157",
            "district": "22",
            "Block": "309",
            "label": "PUMS, MANIVILUNDAN NORTH PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11158",
            "district": "22",
            "Block": "309",
            "label": "PUMS, MUTTALPOOMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11159",
            "district": "22",
            "Block": "309",
            "label": "PUMS, MANIVILUNDAN NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11160",
            "district": "22",
            "Block": "309",
            "label": "MARUTHI HIGHER SECONDARY SCHOOL, M.V.SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11161",
            "district": "22",
            "Block": "309",
            "label": "JAYAM ACADEMY (CBSE), M.V.SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "11162",
            "district": "22",
            "Block": "309",
            "label": "JAYAM MATRIC HR. SEC. SCHOOL, SAMIYAR KINARU, ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11163",
            "district": "22",
            "Block": "309",
            "label": "PUMS, SIRUVACHUR 4TH WARD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11164",
            "district": "22",
            "Block": "309",
            "label": "CLASSIC MATRIC SCHOOL, SIRUVACHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11165",
            "district": "22",
            "Block": "309",
            "label": "GHSS, SIRUVACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11166",
            "district": "22",
            "Block": "309",
            "label": "PUMS, VEPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11167",
            "district": "22",
            "Block": "309",
            "label": "GHSS, UNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11168",
            "district": "22",
            "Block": "309",
            "label": "GHS, PUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11169",
            "district": "22",
            "Block": "309",
            "label": "GHS, NAVAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11170",
            "district": "22",
            "Block": "309",
            "label": "NEHRU MATRICULATION SCHOOL, NAVAKKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11171",
            "district": "22",
            "Block": "309",
            "label": "PUMS, PATTUTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11172",
            "district": "22",
            "Block": "309",
            "label": "GHSS, DEVIYAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11173",
            "district": "22",
            "Block": "309",
            "label": "TAGORE MATRIC HR. SEC. SCHOOL, DEVIYAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11174",
            "district": "22",
            "Block": "309",
            "label": "BHARATHIYAR GIRLS HR. SEC. SCHOOL, DEVIYAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11175",
            "district": "22",
            "Block": "309",
            "label": "PAAVENDHAR MATRIC HR. SEC. SCHOOL, M.V.SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11176",
            "district": "22",
            "Block": "309",
            "label": "TAGORE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11177",
            "district": "22",
            "Block": "309",
            "label": "PUMS, VADAKUMARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11178",
            "district": "22",
            "Block": "309",
            "label": "GHSS, SATHAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11179",
            "district": "22",
            "Block": "309",
            "label": "PUMS, PUNALVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11180",
            "district": "22",
            "Block": "309",
            "label": "GHS, NAVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11181",
            "district": "22",
            "Block": "309",
            "label": "PUMS, VEDHANAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11182",
            "district": "22",
            "Block": "309",
            "label": "GBHSS, THALAIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11183",
            "district": "22",
            "Block": "309",
            "label": "GGHSS, THALAIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11184",
            "district": "22",
            "Block": "309",
            "label": "PUMS, NATHAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11185",
            "district": "22",
            "Block": "309",
            "label": "SRI VENKATESWARA HR. SEC. SCHOOL, NATHAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11186",
            "district": "22",
            "Block": "309",
            "label": "PUMS, PERIYERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11187",
            "district": "22",
            "Block": "309",
            "label": "MUTHAMIZHL HIGHER SECONDARY SCHOOL, PERIYERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11188",
            "district": "22",
            "Block": "309",
            "label": "SRI RAJA MURUGA CBSE SCHOOL, PERIYERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11189",
            "district": "22",
            "Block": "309",
            "label": "GHSS, ARAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11190",
            "district": "22",
            "Block": "309",
            "label": "GGHS, ARAGALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11191",
            "district": "22",
            "Block": "309",
            "label": "BHARATHI VIDYA MANDIR MATRIC SCHOOL, SITERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11192",
            "district": "22",
            "Block": "309",
            "label": "GHS, SITHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11193",
            "district": "22",
            "Block": "309",
            "label": "GHS, GOVINDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11194",
            "district": "22",
            "Block": "309",
            "label": "PUMS, THENKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11195",
            "district": "22",
            "Block": "309",
            "label": "GBHSS, VEERAGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11196",
            "district": "22",
            "Block": "309",
            "label": "GGHSS, VEERAGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11197",
            "district": "22",
            "Block": "309",
            "label": "SRI RAGAVENDRA MATRIC HR. SEC. SCHOOL, VEERAGANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11198",
            "district": "22",
            "Block": "309",
            "label": "PUMS, THITTACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11199",
            "district": "22",
            "Block": "309",
            "label": "GHS, LATHUVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11200",
            "district": "22",
            "Block": "309",
            "label": "GHS, VELLAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11201",
            "district": "22",
            "Block": "309",
            "label": "PUMS, ELUPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11202",
            "district": "22",
            "Block": "309",
            "label": "SRI RAMAKRISHNA MATRICULATION SCHOOL, ELUPPANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11203",
            "district": "22",
            "Block": "309",
            "label": "GHS, VEPPAMPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11204",
            "district": "22",
            "Block": "309",
            "label": "GHS, PULIYANKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11205",
            "district": "22",
            "Block": "309",
            "label": "GHS, EAST RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11206",
            "district": "22",
            "Block": "309",
            "label": "PUMS, KAVARPANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11207",
            "district": "22",
            "Block": "391",
            "label": "PUMS M.G.R. NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11208",
            "district": "22",
            "Block": "391",
            "label": "PUMS CHINNAPOOSALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11209",
            "district": "22",
            "Block": "391",
            "label": "PUMS ELAGUVAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11210",
            "district": "22",
            "Block": "391",
            "label": "GHSS MURUNGAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11211",
            "district": "22",
            "Block": "391",
            "label": "PUMS NAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11212",
            "district": "22",
            "Block": "391",
            "label": "SHALOM CONVENT MATRIC SCHOOL, PUDHU ROAD,PERUMAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11213",
            "district": "22",
            "Block": "391",
            "label": "PUMS KOTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11214",
            "district": "22",
            "Block": "391",
            "label": "PUMS S.K. VALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11215",
            "district": "22",
            "Block": "391",
            "label": "GHS, PERIYAPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11216",
            "district": "22",
            "Block": "391",
            "label": "GHS, ATTAVANAIPOOLAVARI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11217",
            "district": "22",
            "Block": "391",
            "label": "PUMS UTHAMACHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11218",
            "district": "22",
            "Block": "391",
            "label": "V.M. ANNAPOORANA MATRIC SCHOOL, UTHAMASOLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11219",
            "district": "22",
            "Block": "391",
            "label": "GHS PERUMAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11220",
            "district": "22",
            "Block": "391",
            "label": "GGHSS,  ELAMPILLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11221",
            "district": "22",
            "Block": "391",
            "label": "GBHSS, ELAMPILLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11222",
            "district": "22",
            "Block": "391",
            "label": "PUMS REDDIMANIYAKARANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11223",
            "district": "22",
            "Block": "391",
            "label": "PUMS K.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11224",
            "district": "22",
            "Block": "391",
            "label": "GHSS, VEMBADITHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11225",
            "district": "22",
            "Block": "391",
            "label": "K.G.B.V. ELAMPILLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11226",
            "district": "22",
            "Block": "391",
            "label": "PUMS RAKKIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11227",
            "district": "22",
            "Block": "391",
            "label": "VIDHYAA VAASHINI HR. SEC. SCHOOL, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11228",
            "district": "22",
            "Block": "391",
            "label": "PUMS THANAKUTTYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11229",
            "district": "22",
            "Block": "391",
            "label": "GBHSS ATTAYAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11230",
            "district": "22",
            "Block": "391",
            "label": "M.N.S. GOVT. GIRLS HR. SEC. SCHOOL, ATTAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11231",
            "district": "22",
            "Block": "391",
            "label": "SRI VIDHYAA MANDIR HR. SEC. SCHOOL, PETHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11232",
            "district": "22",
            "Block": "391",
            "label": "GHS, CHINNASEERAGAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11233",
            "district": "22",
            "Block": "391",
            "label": "PUMS PERIYASEERAGAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11234",
            "district": "22",
            "Block": "391",
            "label": "PUMS VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11235",
            "district": "22",
            "Block": "391",
            "label": "GHSS VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11236",
            "district": "22",
            "Block": "391",
            "label": "PUMS PALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11237",
            "district": "22",
            "Block": "391",
            "label": "PUMS ERUSANAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11238",
            "district": "22",
            "Block": "273",
            "label": "PUMS,SIRCARKOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11239",
            "district": "22",
            "Block": "273",
            "label": "GHSS,SARKARKOLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11240",
            "district": "22",
            "Block": "273",
            "label": "PUMS, MAJURAKOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11241",
            "district": "22",
            "Block": "273",
            "label": "PUMS, THIRUMALAIGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11242",
            "district": "22",
            "Block": "273",
            "label": "GHS,VEDUGATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11243",
            "district": "22",
            "Block": "273",
            "label": "PUMS, AYYAMPERUMAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11244",
            "district": "22",
            "Block": "273",
            "label": "SRI PALANIAPPA MATRIC SCHOOL,MALLAMOOPAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11245",
            "district": "22",
            "Block": "273",
            "label": "SRI GAYATHRI HR. SEC. SCHOOL, THALAVAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11246",
            "district": "22",
            "Block": "273",
            "label": "PUMS, JAGIRREDDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11247",
            "district": "22",
            "Block": "273",
            "label": "VAGGISVARI VIDYALAYA (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11248",
            "district": "22",
            "Block": "273",
            "label": "GHSS,JAGIR AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11249",
            "district": "22",
            "Block": "273",
            "label": "SENTHIL PUBLIC SCHOOL (CBSE) JAGIR AMPALAYAMMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11250",
            "district": "22",
            "Block": "273",
            "label": "PUMS, NARASOTHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11251",
            "district": "22",
            "Block": "273",
            "label": "MOTHERS VIDHYALAYA MATRIC HR. SEC. SCHOOL,NARASOTHIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11252",
            "district": "22",
            "Block": "273",
            "label": "GHS,ALAGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11253",
            "district": "22",
            "Block": "273",
            "label": "NSK VAISHINAVI SMART MATRICULATION  SCHOOL GORIMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11254",
            "district": "22",
            "Block": "273",
            "label": "GHS,CHETTICHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11255",
            "district": "22",
            "Block": "273",
            "label": "PUMS, CHINNAKOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11256",
            "district": "22",
            "Block": "273",
            "label": "PUMS, VINAYAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11257",
            "district": "22",
            "Block": "273",
            "label": "MOUNT MARY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11258",
            "district": "22",
            "Block": "273",
            "label": "EMERALD VALLEY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11259",
            "district": "22",
            "Block": "273",
            "label": "SHARON HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11260",
            "district": "22",
            "Block": "273",
            "label": "SRI SESHAAS INTERNATIONAL PUBLIC SCHOOL,YERCAUD FOOTHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11261",
            "district": "22",
            "Block": "273",
            "label": "GHS, KONDAPPANAICKENPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11262",
            "district": "22",
            "Block": "273",
            "label": "PUMS,KANNANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11263",
            "district": "22",
            "Block": "273",
            "label": "PUMS, THAMARAINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11264",
            "district": "22",
            "Block": "273",
            "label": "PUMS CHINNATHIRUPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11265",
            "district": "22",
            "Block": "273",
            "label": "ST. BASIL MATRIC SCHOOL, CHINNATHIRUPATHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11266",
            "district": "22",
            "Block": "273",
            "label": "GHSS,KANNANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11267",
            "district": "22",
            "Block": "273",
            "label": "PUMS,MITTAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11268",
            "district": "22",
            "Block": "273",
            "label": "PUMS,KOMBAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11269",
            "district": "22",
            "Block": "273",
            "label": "GHS ALAGAPURAM PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11270",
            "district": "22",
            "Block": "273",
            "label": "PUMS,SANNIYASIGUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11271",
            "district": "22",
            "Block": "273",
            "label": "ST. THOMAS MATRIC SCHOOL, SANNIYASIGUNDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11272",
            "district": "22",
            "Block": "273",
            "label": "GHS, ERUMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11273",
            "district": "22",
            "Block": "273",
            "label": "PUMS, BHARATHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11274",
            "district": "22",
            "Block": "273",
            "label": "GHSS, MANIYANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11275",
            "district": "22",
            "Block": "273",
            "label": "MMS,BODINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11276",
            "district": "22",
            "Block": "273",
            "label": "SRI RAMALINGA VALLALAR HR. SEC. SCHOOL, SURAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11277",
            "district": "22",
            "Block": "273",
            "label": "DHAARUS SALAAM MATRIC HR. SEC. SCHOOL SALEM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11278",
            "district": "22",
            "Block": "273",
            "label": "SBK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11279",
            "district": "22",
            "Block": "273",
            "label": "SRI RAMAKRISHNA SARADA HR. SEC. SCHOOL (AIDED)",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11280",
            "district": "22",
            "Block": "273",
            "label": "NEELAMBAL SUBRAMANIAM HR. SEC. SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11281",
            "district": "22",
            "Block": "273",
            "label": "ST. JOSEPH GIRLS HR. SEC. SCHOOL (AIDED),SURAMANGALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11282",
            "district": "22",
            "Block": "273",
            "label": "PUMS, KUDUMIANTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11283",
            "district": "22",
            "Block": "273",
            "label": "GHS FOR DEAF SURAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11284",
            "district": "22",
            "Block": "273",
            "label": "PUMS,SELATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11285",
            "district": "22",
            "Block": "273",
            "label": "PUMS, SIVADHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11286",
            "district": "22",
            "Block": "273",
            "label": "GHSS,SIVADHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11287",
            "district": "22",
            "Block": "273",
            "label": "PUMS,ANDIPATI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11288",
            "district": "22",
            "Block": "273",
            "label": "PUMS, KANDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11289",
            "district": "22",
            "Block": "273",
            "label": "EQUITAS GURUKUL MATRIC HR. SEC. SCHOOL, SURAMANGALAM, KANDAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11290",
            "district": "22",
            "Block": "273",
            "label": "GHS SEELANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11291",
            "district": "22",
            "Block": "316",
            "label": "GHS M.OLAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11292",
            "district": "22",
            "Block": "316",
            "label": "PUMS MALLIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11293",
            "district": "22",
            "Block": "316",
            "label": "PUMS RASANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11294",
            "district": "22",
            "Block": "316",
            "label": "GHSS AMARAGUNDHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11295",
            "district": "22",
            "Block": "316",
            "label": "PUMS ALAKUSAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11296",
            "district": "22",
            "Block": "316",
            "label": "GHSS T.KONAGAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11297",
            "district": "22",
            "Block": "316",
            "label": "PUMS PAVALATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11298",
            "district": "22",
            "Block": "316",
            "label": "PUMS PARAKKALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11299",
            "district": "22",
            "Block": "316",
            "label": "PUMS ARUNACHALAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11300",
            "district": "22",
            "Block": "316",
            "label": "PUMS M.G.R COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11301",
            "district": "22",
            "Block": "316",
            "label": "PUMS MOTTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11302",
            "district": "22",
            "Block": "316",
            "label": "PUMS THARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11303",
            "district": "22",
            "Block": "316",
            "label": "SENGUNTHAR MATRIC HR. SEC. SCHOOL, THARAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11304",
            "district": "22",
            "Block": "316",
            "label": "St.CHARLES MATRIC SCHOOL, THARAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11305",
            "district": "22",
            "Block": "316",
            "label": "GGHSS THARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11306",
            "district": "22",
            "Block": "316",
            "label": "SENGUNDHAR MAHAJANA HR. SEC. SCHOOL, THARAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11307",
            "district": "22",
            "Block": "316",
            "label": "SRI JOTHI HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11308",
            "district": "22",
            "Block": "316",
            "label": "VANNIYAKULA SATHIRIYAR HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11309",
            "district": "22",
            "Block": "316",
            "label": "PUMS ARURPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11310",
            "district": "22",
            "Block": "316",
            "label": "PUMS PALAKARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11311",
            "district": "22",
            "Block": "316",
            "label": "GHS SEDAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11312",
            "district": "22",
            "Block": "316",
            "label": "K.G.B.V.  CHINNAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11313",
            "district": "22",
            "Block": "316",
            "label": "PUMS KATHIMARANVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11314",
            "district": "22",
            "Block": "316",
            "label": "GHSS RAMIREDDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11315",
            "district": "22",
            "Block": "316",
            "label": "GHS VENNANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11316",
            "district": "22",
            "Block": "316",
            "label": "PUMS  ARIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11317",
            "district": "22",
            "Block": "316",
            "label": "PUMS NACHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11318",
            "district": "22",
            "Block": "316",
            "label": "SREE VARI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11319",
            "district": "22",
            "Block": "316",
            "label": "PUMS EDAIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11320",
            "district": "22",
            "Block": "316",
            "label": "PUMS A.P.VATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11321",
            "district": "22",
            "Block": "316",
            "label": "PUMS MATAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11322",
            "district": "22",
            "Block": "316",
            "label": "PUMS VANICHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11323",
            "district": "22",
            "Block": "316",
            "label": "PUMS LACHUMAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11324",
            "district": "22",
            "Block": "316",
            "label": "PUMS SENGODANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11325",
            "district": "22",
            "Block": "316",
            "label": "GHSS CHINNAPILLAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11326",
            "district": "22",
            "Block": "316",
            "label": "GHSS, THUTTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11327",
            "district": "22",
            "Block": "316",
            "label": "PUMS KONDAKARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11328",
            "district": "22",
            "Block": "316",
            "label": "GHSS CHINNAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11329",
            "district": "22",
            "Block": "316",
            "label": "PUMS KRISHNAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11330",
            "district": "22",
            "Block": "316",
            "label": "PUMS KOTTAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11331",
            "district": "22",
            "Block": "316",
            "label": "PUMS PERIYAKARUKKALVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11332",
            "district": "22",
            "Block": "203",
            "label": "GHS KUNJANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11333",
            "district": "22",
            "Block": "203",
            "label": "RAMESH VIDHAYASHRAM MATRIC HR. SEC. SCHOOL, KUNJANDIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11334",
            "district": "22",
            "Block": "203",
            "label": "GHSS PUDUCHAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11335",
            "district": "22",
            "Block": "203",
            "label": "NALANDA MATRICULATION SCHOOL, BHARATHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11336",
            "district": "22",
            "Block": "203",
            "label": "PUMS ANDIKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11337",
            "district": "22",
            "Block": "203",
            "label": "PUMS VEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11338",
            "district": "22",
            "Block": "203",
            "label": "GHS KONUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11339",
            "district": "22",
            "Block": "203",
            "label": "GHSS SANDAIDHANAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11340",
            "district": "22",
            "Block": "203",
            "label": "PUMS VEERAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11341",
            "district": "22",
            "Block": "203",
            "label": "PUMS PERIYA VANAVASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11342",
            "district": "22",
            "Block": "203",
            "label": "PUMS SAPPANIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11343",
            "district": "22",
            "Block": "203",
            "label": "GBHSS VANAVASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11344",
            "district": "22",
            "Block": "203",
            "label": "PUMS KARATTUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11345",
            "district": "22",
            "Block": "203",
            "label": "PUMS NANGAVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11346",
            "district": "22",
            "Block": "203",
            "label": "GGHSS NANGAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11347",
            "district": "22",
            "Block": "203",
            "label": "GHS CHINNASORAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11348",
            "district": "22",
            "Block": "203",
            "label": "PUMS PERIYASORAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11349",
            "district": "22",
            "Block": "203",
            "label": "PUMS SEERANGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11350",
            "district": "22",
            "Block": "203",
            "label": "PUMS PONNAPPAN VALAVU A.V.COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11351",
            "district": "22",
            "Block": "203",
            "label": "PUMS CHINNANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11352",
            "district": "22",
            "Block": "203",
            "label": "PUMS KUPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11353",
            "district": "22",
            "Block": "203",
            "label": "PUMS SOORAPPALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11354",
            "district": "22",
            "Block": "203",
            "label": "PUMS NORACHIVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11355",
            "district": "22",
            "Block": "203",
            "label": "PUMS KATTINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11356",
            "district": "22",
            "Block": "203",
            "label": "PUMS SOURIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11357",
            "district": "22",
            "Block": "203",
            "label": "GHSS, SAVURIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11358",
            "district": "22",
            "Block": "203",
            "label": "PUMS KAPPARATTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11359",
            "district": "22",
            "Block": "203",
            "label": "PUMS PODAIYAN THERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11360",
            "district": "22",
            "Block": "203",
            "label": "PUMS VANGALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11361",
            "district": "22",
            "Block": "203",
            "label": "GGHSS JALAKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11362",
            "district": "22",
            "Block": "203",
            "label": "GBHSS JALAKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11363",
            "district": "22",
            "Block": "203",
            "label": "PUMS S. PUDUREDDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11364",
            "district": "22",
            "Block": "203",
            "label": "PUMS THENGALVARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11365",
            "district": "22",
            "Block": "203",
            "label": "PUMS CHINNAKKAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11366",
            "district": "22",
            "Block": "203",
            "label": "GHS SALEM CAMP",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11367",
            "district": "22",
            "Block": "203",
            "label": "JAWAHAR HS, KARUMALAIKOODAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11368",
            "district": "22",
            "Block": "203",
            "label": "J.P. MATRICULATION SCHOOL, ANNALMIN NAGAR, METTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11369",
            "district": "22",
            "Block": "203",
            "label": "PUMS THURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11370",
            "district": "22",
            "Block": "203",
            "label": "K.G.B.V. NANGAVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11371",
            "district": "22",
            "Block": "61",
            "label": "PUMS ADUVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11372",
            "district": "22",
            "Block": "61",
            "label": "GHS ADAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11373",
            "district": "22",
            "Block": "61",
            "label": "PUMS AMMASIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11374",
            "district": "22",
            "Block": "61",
            "label": "GHS IRUPPALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11375",
            "district": "22",
            "Block": "61",
            "label": "GHSS CHETTIMANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11376",
            "district": "22",
            "Block": "61",
            "label": "MODEL SCHOOL, CHETTIMANKURUCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11377",
            "district": "22",
            "Block": "61",
            "label": "PUMS PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11378",
            "district": "22",
            "Block": "61",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11379",
            "district": "22",
            "Block": "61",
            "label": "GHSS CHITTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11380",
            "district": "22",
            "Block": "61",
            "label": "K.G.B.V. CHITTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11381",
            "district": "22",
            "Block": "61",
            "label": "PUMS KUPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11382",
            "district": "22",
            "Block": "61",
            "label": "GHSS POOLAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11383",
            "district": "22",
            "Block": "61",
            "label": "GHSS KONERIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11384",
            "district": "22",
            "Block": "61",
            "label": "GHS NEDUNKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11385",
            "district": "22",
            "Block": "61",
            "label": "PUMS CHINNAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11386",
            "district": "22",
            "Block": "61",
            "label": "GHSS VELLARIVELLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11387",
            "district": "22",
            "Block": "61",
            "label": "AACHARIYA HR. SEC. SCHOOL, VELLARIVELLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11388",
            "district": "22",
            "Block": "61",
            "label": "GHS KUNJAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11389",
            "district": "22",
            "Block": "61",
            "label": "SHEMPARK PUBLIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11390",
            "district": "22",
            "Block": "61",
            "label": "MMS ALACHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11391",
            "district": "22",
            "Block": "61",
            "label": "MMS KAVANDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11392",
            "district": "22",
            "Block": "61",
            "label": "GGHSS EDAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11393",
            "district": "22",
            "Block": "61",
            "label": "PUMS VALAGOUNDANVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11394",
            "district": "22",
            "Block": "61",
            "label": "GBHSS EDAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11395",
            "district": "22",
            "Block": "61",
            "label": "PUMS MANIYAKKARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11396",
            "district": "22",
            "Block": "61",
            "label": "GHS DADAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11397",
            "district": "22",
            "Block": "61",
            "label": "PUMS VEMBANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11398",
            "district": "22",
            "Block": "30",
            "label": "PUMS SIVAGANGAIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11399",
            "district": "22",
            "Block": "30",
            "label": "GHSS KALPAGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11400",
            "district": "22",
            "Block": "30",
            "label": "VANI VIKAS MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11401",
            "district": "22",
            "Block": "30",
            "label": "GHSS THANDAVARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11402",
            "district": "22",
            "Block": "30",
            "label": "GHSS CHOKKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11403",
            "district": "22",
            "Block": "30",
            "label": "PUMS SEELIAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11404",
            "district": "22",
            "Block": "30",
            "label": "GOVT HR SEC SCHOOL MALLIYAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11405",
            "district": "22",
            "Block": "30",
            "label": "PUMS URANDIVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11406",
            "district": "22",
            "Block": "30",
            "label": "PUMS GOVINDARAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11407",
            "district": "22",
            "Block": "30",
            "label": "GHSS KEERIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11408",
            "district": "22",
            "Block": "30",
            "label": "PUMS KOOLAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11409",
            "district": "22",
            "Block": "30",
            "label": "GHS PAITHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11410",
            "district": "22",
            "Block": "30",
            "label": "GBHSS ATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11411",
            "district": "22",
            "Block": "30",
            "label": "GGHSS ATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11412",
            "district": "22",
            "Block": "30",
            "label": "SRI SARADHA MEMORIAL MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11413",
            "district": "22",
            "Block": "30",
            "label": "TSP MATRIC HR. SEC. SCHOOL, VINAYAGAPURAM, ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11414",
            "district": "22",
            "Block": "30",
            "label": "GHS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11415",
            "district": "22",
            "Block": "30",
            "label": "SRI S.R.V MATRICULATION HR. SEC. SCHOOL,NARASINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11416",
            "district": "22",
            "Block": "30",
            "label": "AKCHARA BALABHAVAN (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11417",
            "district": "22",
            "Block": "30",
            "label": "PUMS THENNANGUDIPALYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11418",
            "district": "22",
            "Block": "30",
            "label": "GREEN PARK  MATRIC HSS ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11419",
            "district": "22",
            "Block": "30",
            "label": "A.E.T. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11420",
            "district": "22",
            "Block": "30",
            "label": "PUMS LAKSHMANASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11421",
            "district": "22",
            "Block": "30",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11422",
            "district": "22",
            "Block": "30",
            "label": "GHS RAMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11423",
            "district": "22",
            "Block": "30",
            "label": "PUMS AMMAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11424",
            "district": "22",
            "Block": "30",
            "label": "JAIVINS ACADEMY (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11425",
            "district": "22",
            "Block": "30",
            "label": "GHS KALLANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11426",
            "district": "22",
            "Block": "30",
            "label": "M.M.S.MULLAIVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11427",
            "district": "22",
            "Block": "30",
            "label": "M.M.S.GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11428",
            "district": "22",
            "Block": "30",
            "label": "ST MARY'S RC M.S(AIDED)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11429",
            "district": "22",
            "Block": "30",
            "label": "NATIONAL MIDDLE SCHOOL, ATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11430",
            "district": "22",
            "Block": "30",
            "label": "THIRU CHINNASAMY AYYA MIDDLE SCHOOL, ATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11431",
            "district": "22",
            "Block": "30",
            "label": "C.S.I. HIGH SCHOOL, ATTUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11432",
            "district": "22",
            "Block": "30",
            "label": "ADW HSS AMBEDKAR NAGAR, ATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11433",
            "district": "22",
            "Block": "30",
            "label": "BHARATHIYAR HI TECH INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11434",
            "district": "22",
            "Block": "30",
            "label": "GHS VALAYAMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11435",
            "district": "22",
            "Block": "30",
            "label": "GHSS MANJINI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11436",
            "district": "22",
            "Block": "30",
            "label": "PUMS PUNGAVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11437",
            "district": "22",
            "Block": "34",
            "label": "GTRMS, ALADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11438",
            "district": "22",
            "Block": "34",
            "label": "GTRHSS ARUNOOTHUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11439",
            "district": "22",
            "Block": "34",
            "label": "PUMS, ANUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11440",
            "district": "22",
            "Block": "34",
            "label": "PUMS, VILAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11441",
            "district": "22",
            "Block": "34",
            "label": "GHSS, KOOTTATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11442",
            "district": "22",
            "Block": "34",
            "label": "GHS, MINNAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11443",
            "district": "22",
            "Block": "34",
            "label": "GHSS, AYOTHIYAPATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11444",
            "district": "22",
            "Block": "34",
            "label": "THANGAM MOUNT LITERA ZEE SCHOOL RAMALINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11445",
            "district": "22",
            "Block": "34",
            "label": "GHS, RAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11446",
            "district": "22",
            "Block": "34",
            "label": "PUMS, PERIYAGOUNDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11447",
            "district": "22",
            "Block": "34",
            "label": "GHS, KARIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11448",
            "district": "22",
            "Block": "34",
            "label": "ST. MARYS HR. SEC. SCHOOL, A.N.MANGALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11449",
            "district": "22",
            "Block": "34",
            "label": "PUMS, KARUMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11450",
            "district": "22",
            "Block": "34",
            "label": "PUMS, SELLIYAMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11451",
            "district": "22",
            "Block": "34",
            "label": "GHSS, METTUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11452",
            "district": "22",
            "Block": "34",
            "label": "PUMS, VEDAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11453",
            "district": "22",
            "Block": "34",
            "label": "GHSS, VEERANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11454",
            "district": "22",
            "Block": "34",
            "label": "GHSS, VELLALAGUNDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11455",
            "district": "22",
            "Block": "34",
            "label": "GHS, D-PERUMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11456",
            "district": "22",
            "Block": "34",
            "label": "HOLY CROSS MATRIC HR. SEC. SCHOOL, K.N. COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11457",
            "district": "22",
            "Block": "34",
            "label": "GBHSS, VALASAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11458",
            "district": "22",
            "Block": "34",
            "label": "VISHWA VIKAASH MATRIC SCHOOL, VALASAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11459",
            "district": "22",
            "Block": "34",
            "label": "GGHSS, VALASAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11460",
            "district": "22",
            "Block": "34",
            "label": "GHSS, SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11461",
            "district": "22",
            "Block": "34",
            "label": "ST.NEVA MILLER MATRICULATION SCHOOL, PARUTHIKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11462",
            "district": "22",
            "Block": "34",
            "label": "ST. GRAHAM MEMORIAL HR. SEC. SCHOOL, VAITHATHANOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11463",
            "district": "22",
            "Block": "34",
            "label": "PUMS, C.THATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11464",
            "district": "22",
            "Block": "34",
            "label": "GHS, ACHANKUTTAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11465",
            "district": "22",
            "Block": "34",
            "label": "PUMS, KUPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11466",
            "district": "22",
            "Block": "34",
            "label": "THE GENE TECH MATRICULATION SCHOOL, KUPPANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11467",
            "district": "22",
            "Block": "34",
            "label": "PUMS, VELLIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11468",
            "district": "22",
            "Block": "34",
            "label": "PUMS, ALLIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11469",
            "district": "22",
            "Block": "34",
            "label": "PUMS, THATHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11470",
            "district": "22",
            "Block": "34",
            "label": "GHS, VAIKKALPATTARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11471",
            "district": "22",
            "Block": "34",
            "label": "PUMS, VARAGAMPADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11472",
            "district": "22",
            "Block": "34",
            "label": "GHSS, UDAYAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11473",
            "district": "22",
            "Block": "34",
            "label": "NOTRE DAME OF HOLY CROSS SCHOOL, GUNDUKALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11474",
            "district": "22",
            "Block": "34",
            "label": "PUMS, KUMARAGIRIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11475",
            "district": "22",
            "Block": "34",
            "label": "JOTHI MATRIC HR. SEC. SCHOOL, K.N. COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11476",
            "district": "22",
            "Block": "34",
            "label": "GHS, K.N. COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11477",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KURUMBANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11478",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KANAMOOCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11479",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HIGH SCHOOL,CHETTIUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11480",
            "district": "22",
            "Block": "129",
            "label": "K.G.B.V. KANNAMOOCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11481",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KAVERIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11482",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KATHIRIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11483",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11484",
            "district": "22",
            "Block": "129",
            "label": "PUMS,GOVINDAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11485",
            "district": "22",
            "Block": "129",
            "label": "PUMS,THARKADU SUNDHARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11486",
            "district": "22",
            "Block": "129",
            "label": "PUMS,TELUNGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11487",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HR. SEC.SCHOOL,  KAVERIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11488",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HIGH SCHOOL, KARUNGALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11489",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11490",
            "district": "22",
            "Block": "129",
            "label": "J.B. MATRIC HR. SEC. SCHOOL, KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11491",
            "district": "22",
            "Block": "129",
            "label": "GOVT. GIRLS HR. SEC.SCHOOL, KOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11492",
            "district": "22",
            "Block": "129",
            "label": "NIRMALA HR. SEC. SCHOOL,KOLATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11493",
            "district": "22",
            "Block": "129",
            "label": "PUMS,PORAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11494",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HR. SEC. SCHOOL,SANAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11495",
            "district": "22",
            "Block": "129",
            "label": "PUMS,P.K.NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11496",
            "district": "22",
            "Block": "129",
            "label": "SRI RAMAKRISHNA GURUKULA HIGH SCHOOL, CHINNATHANDA",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11497",
            "district": "22",
            "Block": "129",
            "label": "K.G.B.V. NEETHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11498",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HIGH SCHOOL, NEETHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11499",
            "district": "22",
            "Block": "129",
            "label": "PUMS,MOOLAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11500",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HIGH SCHOOL,MATHAIYANKUTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11501",
            "district": "22",
            "Block": "129",
            "label": "M.A.M.HR.SEC.SCHOOL,MATHAIYANKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11502",
            "district": "22",
            "Block": "129",
            "label": "PUMS,NAGAMPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11503",
            "district": "22",
            "Block": "129",
            "label": "G.V. HR.SEC.SCHOOL,MASILAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11504",
            "district": "22",
            "Block": "129",
            "label": "PUMS,AYYAMPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11505",
            "district": "22",
            "Block": "129",
            "label": "VIVEKANANDHA KALVI NILAYAM MATRIC SCHOOL,KARUNGALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11506",
            "district": "22",
            "Block": "129",
            "label": "PUMS,SETHUKULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11507",
            "district": "22",
            "Block": "129",
            "label": "PUMS,PANNAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11508",
            "district": "22",
            "Block": "129",
            "label": "AIDED KAMALA MIDDLE SCHOOL,METTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11509",
            "district": "22",
            "Block": "129",
            "label": "ST.PHILOMINA'S MIDDLE SCHOOL, METTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11510",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HR. SEC.SCHOOL, METTUR DAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11511",
            "district": "22",
            "Block": "129",
            "label": "ST.MARY'S HR. SEC.SCHOOL, METTUR DAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11512",
            "district": "22",
            "Block": "129",
            "label": "VAIDHEESAWARA HR.SEC.SCHOOL,METTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11513",
            "district": "22",
            "Block": "129",
            "label": "GOVT. GIRLS HIGH SCHOOL, METTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11514",
            "district": "22",
            "Block": "129",
            "label": "M.A.M. HI-TECH MATRIC HR. SEC. SCHOOL, METTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11515",
            "district": "22",
            "Block": "179",
            "label": "PUMS,VALATHASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11516",
            "district": "22",
            "Block": "179",
            "label": "GHSS CHINTHAMANIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11517",
            "district": "22",
            "Block": "179",
            "label": "GHSS M.N.PATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11518",
            "district": "22",
            "Block": "179",
            "label": "G.H.S ARANGANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11519",
            "district": "22",
            "Block": "179",
            "label": "PKM GHSS MADHANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11520",
            "district": "22",
            "Block": "179",
            "label": "SRI SARASWATHI VIDHYALAYA HR. SEC. SCHOOL, KUTTAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11521",
            "district": "22",
            "Block": "179",
            "label": "SRI VIDHYA BHARATHI MATRIC HR. SEC. SCHOOL, MALAIADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11522",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, MALLAPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11523",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, VIRUTHASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11524",
            "district": "22",
            "Block": "179",
            "label": "GHSS KULLAMUDAIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11525",
            "district": "22",
            "Block": "179",
            "label": "PUMS,AMARATHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11526",
            "district": "22",
            "Block": "179",
            "label": "JEMS INTERNATIONAL MATRIC HR. SEC. SCHOOL,SAVADIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11527",
            "district": "22",
            "Block": "179",
            "label": "GHS BUKKAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11528",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, AMARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11529",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, THETHIGIRIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11530",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, KATCHARAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11531",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, KARUMBUSALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11532",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, ERUMAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11533",
            "district": "22",
            "Block": "179",
            "label": "GHSS VELLAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11534",
            "district": "22",
            "Block": "179",
            "label": "WESTERN GATE MAT. SCHOOL, UPPARAPPATTI KATTU VALAVU, METTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11535",
            "district": "22",
            "Block": "179",
            "label": "SONASUN HI TECH MATRIC HR. SEC. SCHOOL,VELLAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11536",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, PALLAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11537",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, THIPPARATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11538",
            "district": "22",
            "Block": "179",
            "label": "GHS MALLIKUNDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11539",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, M.PALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11540",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, VANNIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11541",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, NARIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11542",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, KEERAIKARANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11543",
            "district": "22",
            "Block": "179",
            "label": "GHS KOONANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11544",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, PARAKKALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11545",
            "district": "22",
            "Block": "179",
            "label": "GBHSS, MECHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11546",
            "district": "22",
            "Block": "179",
            "label": "GGHSS MECHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11547",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, THONGALANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11548",
            "district": "22",
            "Block": "215",
            "label": "GHSS OMALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11549",
            "district": "22",
            "Block": "215",
            "label": "VELASAMY CHETTIYAR HR. SEC. SCHOOL, OMALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11550",
            "district": "22",
            "Block": "215",
            "label": "FATHIMA GIRLS HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11551",
            "district": "22",
            "Block": "215",
            "label": "GREEN HOME MATRIC HR. SEC. SCHOOL,NATIONAL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11552",
            "district": "22",
            "Block": "215",
            "label": "FATIMA MATRICULATION SCHOOL OMALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11553",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.PERAMACHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11554",
            "district": "22",
            "Block": "215",
            "label": "WISDOM GATES MATRICULATION HR. SEC. SCHOOL, VELAGOUNDANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11555",
            "district": "22",
            "Block": "215",
            "label": "GHS KAMALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11556",
            "district": "22",
            "Block": "215",
            "label": "ST.NICHOLAS MID SCH R.C.CHETTIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11557",
            "district": "22",
            "Block": "215",
            "label": "JOHN BRITTO MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11558",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.POTTIAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11559",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.ARANMANAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11560",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.THUMBIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11561",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.KENDAPERIANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11562",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.THONNAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11563",
            "district": "22",
            "Block": "215",
            "label": "NORTH PARK MATRIC SCHOOL,THINNAPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11564",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.SARAKKAPPILLAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11565",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.REDDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11566",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.KOMALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11567",
            "district": "22",
            "Block": "215",
            "label": "ADW MS THATHIAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11568",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S..P.KALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11569",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.KARUPPANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11570",
            "district": "22",
            "Block": "215",
            "label": "PERARIGNAR ANNA G.H.S.S, THOLASAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11571",
            "district": "22",
            "Block": "215",
            "label": "GHSS M.CHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11572",
            "district": "22",
            "Block": "215",
            "label": "GHS PERIYAKADAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11573",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.REDDIPATTI KATTUVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11574",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.PACHANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11575",
            "district": "22",
            "Block": "215",
            "label": "GHSS,PANAKATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11576",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S KULLAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11577",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S METTUPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11578",
            "district": "22",
            "Block": "215",
            "label": "SKV HI TECH MATRICULATION HR.SEC.SCHOOL ,KARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11579",
            "district": "22",
            "Block": "215",
            "label": "GHSS,KARUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11580",
            "district": "22",
            "Block": "215",
            "label": "SSRM HR. SEC. SCHOOL, KARUMBALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11581",
            "district": "22",
            "Block": "215",
            "label": "RAAJAM MATRIC HR. SEC. SCHOOL, KARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11582",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.SANGEETHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11583",
            "district": "22",
            "Block": "215",
            "label": "ADWHSS MATTUKARANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11584",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.CHELLAPILLAIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11585",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.P.NALLAGOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11586",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.BOOMINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11587",
            "district": "22",
            "Block": "215",
            "label": "ST.JOSEPH HR. SEC. SCHOOL, PAGALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11588",
            "district": "22",
            "Block": "215",
            "label": "MUKUNDA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11589",
            "district": "22",
            "Block": "215",
            "label": "GHSS PAGALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11590",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.PUDHURKADAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11591",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.KANDAMPITCHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11592",
            "district": "22",
            "Block": "215",
            "label": "GHSS MUTHUNAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11593",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.SEMMANKOODAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11594",
            "district": "22",
            "Block": "215",
            "label": "GHS PALAKUTTAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11595",
            "district": "22",
            "Block": "215",
            "label": "GHS SAKKARA CHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11596",
            "district": "22",
            "Block": "215",
            "label": "VIVEKANANDA KENDRIYA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11597",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.SPULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11598",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.VATTAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11599",
            "district": "22",
            "Block": "215",
            "label": "GHSS THEKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11600",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.THATHIANGARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11601",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.MOONGILPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11602",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.SAMINAICKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11603",
            "district": "22",
            "Block": "215",
            "label": "GHSS VELLALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11604",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.MANGKUPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11605",
            "district": "22",
            "Block": "215",
            "label": "KIZHAR PALANIYANDI PILLAI AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11606",
            "district": "22",
            "Block": "215",
            "label": "GOLDEN SPARK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11607",
            "district": "22",
            "Block": "278",
            "label": "PUMS SANKARI IIIRD WARD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11608",
            "district": "22",
            "Block": "278",
            "label": "PUMS NAGICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11609",
            "district": "22",
            "Block": "278",
            "label": "PUMS OONJAKORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11610",
            "district": "22",
            "Block": "278",
            "label": "GHSS NATTUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11611",
            "district": "22",
            "Block": "278",
            "label": "GHSS B SANKARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11612",
            "district": "22",
            "Block": "278",
            "label": "GHSS G SANKARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11613",
            "district": "22",
            "Block": "278",
            "label": "SRE VIDYASAAGAR HR. SEC. SCHOOL, NAGICHETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11614",
            "district": "22",
            "Block": "278",
            "label": "GHS AKKAMAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11615",
            "district": "22",
            "Block": "278",
            "label": "GHSS THEVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11616",
            "district": "22",
            "Block": "278",
            "label": "PUMS OLAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11617",
            "district": "22",
            "Block": "278",
            "label": "GHS ARA KULLAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11618",
            "district": "22",
            "Block": "278",
            "label": "GHSS ARA CHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11619",
            "district": "22",
            "Block": "278",
            "label": "PUMS A.REDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11620",
            "district": "22",
            "Block": "278",
            "label": "PUMS MOTHAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11621",
            "district": "22",
            "Block": "278",
            "label": "GHS CHINNAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11622",
            "district": "22",
            "Block": "278",
            "label": "PUMS CHETTIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11623",
            "district": "22",
            "Block": "278",
            "label": "PUMS MANJAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11624",
            "district": "22",
            "Block": "278",
            "label": "PUMS DEVANNAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11625",
            "district": "22",
            "Block": "278",
            "label": "K.G.B.V. DEVANNAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11626",
            "district": "22",
            "Block": "278",
            "label": "PUMS IRUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11627",
            "district": "22",
            "Block": "278",
            "label": "PUMS OKKILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11628",
            "district": "22",
            "Block": "278",
            "label": "PUMS VATRAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11629",
            "district": "22",
            "Block": "278",
            "label": "PUMS KAVERIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11630",
            "district": "22",
            "Block": "278",
            "label": "PUMS KAIKOLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11631",
            "district": "22",
            "Block": "278",
            "label": "PUMS PONNAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11632",
            "district": "22",
            "Block": "278",
            "label": "GHS KATHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11633",
            "district": "22",
            "Block": "278",
            "label": "PUMS VENGIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23404",
            "district": "22",
            "Block": "61",
            "label": "Little Star Nursery Primary and High School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "11634",
            "district": "22",
            "Block": "278",
            "label": "P.U.M.S.PULLAGOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11635",
            "district": "22",
            "Block": "278",
            "label": "GHS VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11636",
            "district": "22",
            "Block": "383",
            "label": "PUMS, MALAIYALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11637",
            "district": "22",
            "Block": "383",
            "label": "GHSS, THIRUMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11638",
            "district": "22",
            "Block": "383",
            "label": "GHS, VEPPILAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11639",
            "district": "22",
            "Block": "383",
            "label": "PUMS, PONNARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11640",
            "district": "22",
            "Block": "383",
            "label": "PUMS, V.MANNARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11641",
            "district": "22",
            "Block": "383",
            "label": "GHS, VILARIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11642",
            "district": "22",
            "Block": "383",
            "label": "GHSS, SINGIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11643",
            "district": "22",
            "Block": "383",
            "label": "SREE GOKULAM MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11644",
            "district": "22",
            "Block": "383",
            "label": "GGHSS, VALAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11645",
            "district": "22",
            "Block": "383",
            "label": "GOVT BOYS HSS, VALAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11646",
            "district": "22",
            "Block": "383",
            "label": "PUMS, KAVARKALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11647",
            "district": "22",
            "Block": "383",
            "label": "GHS, SESANCHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11648",
            "district": "22",
            "Block": "383",
            "label": "PUMS, MUTHAMPATTY COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11649",
            "district": "22",
            "Block": "383",
            "label": "GMS, MUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11650",
            "district": "22",
            "Block": "383",
            "label": "PUMS, MANNAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11651",
            "district": "22",
            "Block": "383",
            "label": "GHS THUKKIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11652",
            "district": "22",
            "Block": "383",
            "label": "GHS ATHANURPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11653",
            "district": "22",
            "Block": "383",
            "label": "GGHSS, BELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11654",
            "district": "22",
            "Block": "383",
            "label": "GBHSS, BELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11655",
            "district": "22",
            "Block": "383",
            "label": "SRI SAKTHI VIKAAS MATRIC HR. SEC. SCHOOL,BELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11656",
            "district": "22",
            "Block": "383",
            "label": "GHS, NEERMULLIKUTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11657",
            "district": "22",
            "Block": "383",
            "label": "GHS, C.N.PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11658",
            "district": "22",
            "Block": "383",
            "label": "PUMS, KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11659",
            "district": "22",
            "Block": "383",
            "label": "PUMS, KONANCHETTIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11660",
            "district": "22",
            "Block": "383",
            "label": "PUMS, ARANGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11661",
            "district": "22",
            "Block": "383",
            "label": "GTR HR. SEC. SCHOOL, VELLIGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11662",
            "district": "22",
            "Block": "383",
            "label": "PUMS, CHANDRAPILLAIVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11663",
            "district": "22",
            "Block": "413",
            "label": "PUMS SENGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11664",
            "district": "22",
            "Block": "413",
            "label": "PUMS, THALAICHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11665",
            "district": "22",
            "Block": "413",
            "label": "PUMS.GUNDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11666",
            "district": "22",
            "Block": "413",
            "label": "ST. JOSEPH BOYS HR. SEC. SCHOOL, YERCAUD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11667",
            "district": "22",
            "Block": "413",
            "label": "SACRED HEART ANGLO INDIAN GIRLS HR. SEC. SCHOOL, YERCAUD",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11668",
            "district": "22",
            "Block": "413",
            "label": "GHSS YERCAUD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11669",
            "district": "22",
            "Block": "413",
            "label": "NAZARETH GIRLS HR. SEC. SCHOOL, YERCAUD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11670",
            "district": "22",
            "Block": "413",
            "label": "MONTFORT ANGLO INDIAN HR. SEC. SCHOOL, YERCAUD",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11671",
            "district": "22",
            "Block": "413",
            "label": "ST.CHARLES SCHOOL,YERCAUD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11672",
            "district": "22",
            "Block": "413",
            "label": "PUMS, MULUVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11673",
            "district": "22",
            "Block": "413",
            "label": "GHS NAGALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11674",
            "district": "22",
            "Block": "413",
            "label": "PUMS, PATTIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11675",
            "district": "22",
            "Block": "413",
            "label": "PUMS, KOLAGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11676",
            "district": "22",
            "Block": "413",
            "label": "GHS VELLAKKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11677",
            "district": "22",
            "Block": "413",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11678",
            "district": "22",
            "Block": "413",
            "label": "PUMS, MARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11679",
            "district": "22",
            "Block": "413",
            "label": "MONTFORT COMMUNITY HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11680",
            "district": "22",
            "Block": "413",
            "label": "G.T.R. MIDDLE SCH VAZHAVANTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11681",
            "district": "22",
            "Block": "413",
            "label": "SEVAROYS VALLY HR. SEC. SCHOOL, VALAVANTHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11682",
            "district": "22",
            "Block": "413",
            "label": "PUMS.CHINNA MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11683",
            "district": "22",
            "Block": "413",
            "label": "K.G.B.V. YERCAUD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11684",
            "district": "22",
            "Block": "274",
            "label": "SRI SARADA VIDYALAYA HR. SEC. SCHOOL FOR GIRLS (AIDED)",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11685",
            "district": "22",
            "Block": "274",
            "label": "HOLY ANGELS MATRIC HR. SEC. SCHOOL,FAIRLANDS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11686",
            "district": "22",
            "Block": "274",
            "label": "ST.JOHN'S MATRIC HR. SEC. SCHOOL,NEW  ALAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11687",
            "district": "22",
            "Block": "274",
            "label": "ANDERSON HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11688",
            "district": "22",
            "Block": "274",
            "label": "JAYALAKSHMI VIDYALAYAM HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11689",
            "district": "22",
            "Block": "274",
            "label": "NEW INDIA DISCOVERY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11690",
            "district": "22",
            "Block": "274",
            "label": "MPL HIGH SCHOOL PUTHUMARIYAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11691",
            "district": "22",
            "Block": "274",
            "label": "K M G H S S MANAKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11692",
            "district": "22",
            "Block": "274",
            "label": "BHARATHI VIDYALAYA HR. SEC. SCHOOL,MARAVANERI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11693",
            "district": "22",
            "Block": "274",
            "label": "C.S.I. HOBART GIRLS HIGH SCHOOL,HASTHAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11694",
            "district": "22",
            "Block": "274",
            "label": "L.E.F. EDEN GARDEN MATRIC SCHOOL,HASTHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11695",
            "district": "22",
            "Block": "274",
            "label": "CLUNY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11696",
            "district": "22",
            "Block": "274",
            "label": "AMS CHANDRA DEVAPRASAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11697",
            "district": "22",
            "Block": "274",
            "label": "GOVT HIGH SCHOOL AR LINES",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11698",
            "district": "22",
            "Block": "274",
            "label": "MHS SAGADEVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11699",
            "district": "22",
            "Block": "274",
            "label": "THE LITTLE FLOWER HR. SEC. SCHOOL, SALEM-7",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11700",
            "district": "22",
            "Block": "274",
            "label": "ST. PAUL'S HR. SEC. SCHOOL, SALEM-7",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11701",
            "district": "22",
            "Block": "274",
            "label": "MMS ARISIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11702",
            "district": "22",
            "Block": "274",
            "label": "ST. MARY'S GIRLS HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11703",
            "district": "22",
            "Block": "274",
            "label": "THE LITTLE FLOWER MATRICULATION SCHOOL,FOUR ROADS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11704",
            "district": "22",
            "Block": "274",
            "label": "MMS. NARASIMMAN SALAI ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11705",
            "district": "22",
            "Block": "274",
            "label": "JAYARAKKINI AIDED MS, SHEVAPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11706",
            "district": "22",
            "Block": "274",
            "label": "GOKULANATHA HINDU MAHAJANA BOYS HR.SEC.SALEM 1",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11707",
            "district": "22",
            "Block": "274",
            "label": "MANURKULA DEVANGA HIGH SCHOOL,SHEVAPET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11708",
            "district": "22",
            "Block": "274",
            "label": "VASAVI HR SEC SCHOOL, SHEVAPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11709",
            "district": "22",
            "Block": "274",
            "label": "MHS SHEVAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11710",
            "district": "22",
            "Block": "274",
            "label": "CORNATION AIDED MS, SELLAKUTTIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11711",
            "district": "22",
            "Block": "274",
            "label": "AMS SOURASHTRA VIDYALAYA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11712",
            "district": "22",
            "Block": "274",
            "label": "GGHSS SALEM 1",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11713",
            "district": "22",
            "Block": "274",
            "label": "CSI HIGHER SEC SCHOOL-FORT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11714",
            "district": "22",
            "Block": "274",
            "label": "MBHSS FORT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11715",
            "district": "22",
            "Block": "274",
            "label": "MMS CAR STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11716",
            "district": "22",
            "Block": "274",
            "label": "TIPPU ISLAMIYA HIGH SCHOOL,SALEM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11717",
            "district": "22",
            "Block": "274",
            "label": "TIPPU ISLAMIYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11718",
            "district": "22",
            "Block": "274",
            "label": "MMS. THEETTUKKAL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11719",
            "district": "22",
            "Block": "274",
            "label": "SRI SATHYA SAI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11720",
            "district": "22",
            "Block": "274",
            "label": "MMS. ELLAPPAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11721",
            "district": "22",
            "Block": "274",
            "label": "MBHSS, AMMAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11722",
            "district": "22",
            "Block": "274",
            "label": "MGHSS AMMAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11723",
            "district": "22",
            "Block": "274",
            "label": "MHS, VAIYAPURI STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11724",
            "district": "22",
            "Block": "274",
            "label": "MBHSS PAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11725",
            "district": "22",
            "Block": "274",
            "label": "JAY MATRICULATION SCHOOL, KITCHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11726",
            "district": "22",
            "Block": "274",
            "label": "SINDHI HINDU HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11727",
            "district": "22",
            "Block": "274",
            "label": "MGHSS PAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11728",
            "district": "22",
            "Block": "274",
            "label": "MMS ERUMAPALAYAM No.2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23381",
            "district": "22",
            "Block": "273",
            "label": "Sugam Matriculation Hr.Sec.School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23393",
            "district": "22",
            "Block": "391",
            "label": "THE OXFORD MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23396",
            "district": "22",
            "Block": "226",
            "label": "Diamond Ray's Mat HrSec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11729",
            "district": "22",
            "Block": "274",
            "label": "MMS PACHAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11730",
            "district": "22",
            "Block": "274",
            "label": "ANGAYAR KANNI AIDED MS  - AMMAPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11731",
            "district": "22",
            "Block": "274",
            "label": "SOURASHTRA HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11732",
            "district": "22",
            "Block": "274",
            "label": "MHS PUDHU STREET KITCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11733",
            "district": "22",
            "Block": "274",
            "label": "NATIONAL HIGH SCHOOL K PALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11734",
            "district": "22",
            "Block": "274",
            "label": "J.R. CAMBRIDGE SCHOOL, NARAYANA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11735",
            "district": "22",
            "Block": "274",
            "label": "AMS CSI AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11736",
            "district": "22",
            "Block": "274",
            "label": "MPL GHSS, GUGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11737",
            "district": "22",
            "Block": "274",
            "label": "MMS - KALIAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11738",
            "district": "22",
            "Block": "274",
            "label": "AMS SRI GURUSWAMY-GUGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11739",
            "district": "22",
            "Block": "274",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11740",
            "district": "22",
            "Block": "274",
            "label": "SRI VIDYA MANDIR SEC. SCHOOL, SHEVAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11741",
            "district": "22",
            "Block": "274",
            "label": "MMS.ANNATHANAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11742",
            "district": "22",
            "Block": "274",
            "label": "JAYARANI GIRL'S AIDED HR SEC  SCHOOL,NETHIMEDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11743",
            "district": "22",
            "Block": "274",
            "label": "THE GUGAI HR SEC SCHOOL -GUGAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11744",
            "district": "22",
            "Block": "274",
            "label": "GOKULA VILAS  AIDED MS,SANJEEVARAYANPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11745",
            "district": "22",
            "Block": "274",
            "label": "MMS. SANJEEVARAYANPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11746",
            "district": "22",
            "Block": "274",
            "label": "MMS, THADAGAPATTI, METTU ST ,SALEM-6",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11747",
            "district": "22",
            "Block": "274",
            "label": "VEERALAKSHMI VIDYALAYA HIGH SCHOOL,GUGAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20882",
            "district": "22",
            "Block": "273",
            "label": "CLUNY VIDYA NIKETHAN SCHOOL,ERUMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20913",
            "district": "22",
            "Block": "316",
            "label": "SENGUNTHAR PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20924",
            "district": "22",
            "Block": "274",
            "label": "GOVT BLIND MIDDLE SCHOOL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23684",
            "district": "22",
            "Block": "179",
            "label": "RP matric higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23692",
            "district": "22",
            "Block": "274",
            "label": "Silver Bell Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "20964",
            "district": "22",
            "Block": "215",
            "label": "SREE THULASI VIKKAS MATRIC SCHOOL, THOLASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21019",
            "district": "22",
            "Block": "68",
            "label": "AIM MATRIC HR. SEC. SCHOOL, NAGIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21020",
            "district": "22",
            "Block": "163",
            "label": "DHEERAN VIDHYAALAYAA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21021",
            "district": "22",
            "Block": "273",
            "label": "SRI GAYATHRI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21022",
            "district": "22",
            "Block": "316",
            "label": "SALEM HI-TECH MATRIC SCHOOL, SELVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21023",
            "district": "22",
            "Block": "316",
            "label": "SRM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21065",
            "district": "22",
            "Block": "133",
            "label": "WINNAR VIKAS INTERNATIONAL SCHOOL (CBSE), KORANAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21066",
            "district": "22",
            "Block": "34",
            "label": "GLOBAL GATEWAY HI-TECH SCHOOL, ANUPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21067",
            "district": "22",
            "Block": "274",
            "label": "HOLY ANGELS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21068",
            "district": "22",
            "Block": "274",
            "label": "JAIRAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21133",
            "district": "22",
            "Block": "316",
            "label": "PUMS KANGANIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21139",
            "district": "22",
            "Block": "68",
            "label": "G.E.T. EXCELLENT MATRIC HR. SEC. SCHOOL, NADUVALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21140",
            "district": "22",
            "Block": "61",
            "label": "KALAIMAGAL VIDHYASHRAM MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21141",
            "district": "22",
            "Block": "179",
            "label": "SRI RAAM VIDYALAYA MATRIC HR. SEC. SCHOOL, POTTANERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21206",
            "district": "22",
            "Block": "246",
            "label": "MODEL SCHOOL , PETHANAIKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21207",
            "district": "22",
            "Block": "163",
            "label": "MODEL SCHOOL, MAGUDANCHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21208",
            "district": "22",
            "Block": "226",
            "label": "MODEL SCHOOL , PANAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21209",
            "district": "22",
            "Block": "391",
            "label": "MODEL SCHOOL, VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21210",
            "district": "22",
            "Block": "316",
            "label": "MODEL SCHOOL, THARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21211",
            "district": "22",
            "Block": "203",
            "label": "MODEL SCHOOL, NANGAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21212",
            "district": "22",
            "Block": "129",
            "label": "MODEL SCHOOL, KOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21213",
            "district": "22",
            "Block": "278",
            "label": "MODEL SCHOOL , SANKARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21214",
            "district": "22",
            "Block": "413",
            "label": "MODEL SCHOOL, YERCAUD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21241",
            "district": "22",
            "Block": "68",
            "label": "KAMARAJAR MATRIC HR. SEC. SCHOOL, THEDAVOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21242",
            "district": "22",
            "Block": "246",
            "label": "ANNAI MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21243",
            "district": "22",
            "Block": "246",
            "label": "PRAPAVATHY MATRIC HIGHER SECONDARY SCHOOL, KARUMANDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21244",
            "district": "22",
            "Block": "226",
            "label": "SPS MATRIC HR. SEC. SCHOOL, NEIKKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21307",
            "district": "22",
            "Block": "133",
            "label": "APPU ARIVAALAYEM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21353",
            "district": "22",
            "Block": "413",
            "label": "MONTFORT SCHOOL (ICSE), YERCAUD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21457",
            "district": "22",
            "Block": "246",
            "label": "GHS UMAYALPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21458",
            "district": "22",
            "Block": "226",
            "label": "GHS NILAVARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21459",
            "district": "22",
            "Block": "391",
            "label": "GHS,KEERAIPAPPAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21460",
            "district": "22",
            "Block": "215",
            "label": "GHS KOTTA GOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21496",
            "district": "22",
            "Block": "246",
            "label": "GTR HIGH SCHOOL, PAGUDUPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21497",
            "district": "22",
            "Block": "30",
            "label": "ADWHS, KOTHAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21498",
            "district": "22",
            "Block": "129",
            "label": "GTR HIGH SCHOOL, RAMANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21499",
            "district": "22",
            "Block": "383",
            "label": "G.T.R. HIGH SCHOOL, PERIYAKUTTIMADUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21500",
            "district": "22",
            "Block": "413",
            "label": "GTR HIGH SCHOOL, SEMMANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21562",
            "district": "22",
            "Block": "273",
            "label": "MUNICIPAL HIGH SCHOOL, OLD SURAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21594",
            "district": "22",
            "Block": "246",
            "label": "BMS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21595",
            "district": "22",
            "Block": "34",
            "label": "SELVA BHARATHI MATRICULATION  SCHOOL VAIKALPATTARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21644",
            "district": "22",
            "Block": "68",
            "label": "KAMARAJAR PUBLIC SCHOOL, THEDAVOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21645",
            "district": "22",
            "Block": "391",
            "label": "JEEVA PUBLIC SCHOOL, SEVAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21646",
            "district": "22",
            "Block": "179",
            "label": "SRI RAAM VIDYALAYA PUBLIC SCHOOL, POTTANERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21670",
            "district": "22",
            "Block": "163",
            "label": "DHEERAN ACADEMY (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21671",
            "district": "22",
            "Block": "163",
            "label": "LOTUS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21672",
            "district": "22",
            "Block": "383",
            "label": "HERITAGE VIDHYALAYA  SCHOOL, KAVARKALPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21911",
            "district": "22",
            "Block": "133",
            "label": "C.M.MATRIC SCHOOL, RANGAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21912",
            "district": "22",
            "Block": "133",
            "label": "UNIVERSAL MATRIC HR. SEC. SCHOOL, KONAKUTTAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21913",
            "district": "22",
            "Block": "226",
            "label": "VEDHHA VIKASS HR. SEC. SCHOOL, SANDHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21972",
            "district": "22",
            "Block": "274",
            "label": "JAIRAM HIGHER SECONDARY SCHOOL, CHINNATHIRUPATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21974",
            "district": "22",
            "Block": "68",
            "label": "SRI VIDHYA BHARATHI MATRIC HR. SEC. SCHOOL, NADUVALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22107",
            "district": "22",
            "Block": "68",
            "label": "G.E.T. SCHOOL OF EXCELLENCE, NADUVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22108",
            "district": "22",
            "Block": "246",
            "label": "VIDHYA VIDYALAYA MATRICULATION SCHOOL, YETHAPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22109",
            "district": "22",
            "Block": "246",
            "label": "SRI AMITH VIDYALAYAA MATRIC SCHOOL ABINAVAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22110",
            "district": "22",
            "Block": "246",
            "label": "SHRI SWAMY MATRIC SCHOOL, PETHANAICKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22111",
            "district": "22",
            "Block": "246",
            "label": "ST. JOSEPH INTERNATIONAL SCHOOL, PERUNCHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22112",
            "district": "22",
            "Block": "226",
            "label": "ST. JOHN'S MATRICULATION SCHOOL,VENGAMPATTI MALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22113",
            "district": "22",
            "Block": "203",
            "label": "VEDANTA VIDYALAYA  METTUR DAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22114",
            "district": "22",
            "Block": "30",
            "label": "SHRI SWAMY PUBLIC SCHOOL, KALPAGANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22115",
            "district": "22",
            "Block": "30",
            "label": "RASI VIDHYASHRAM (CBSE) SCHOOL, EACHAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22116",
            "district": "22",
            "Block": "215",
            "label": "ADW HS, SIKKANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22117",
            "district": "22",
            "Block": "215",
            "label": "G.R.MATRIC HR. SEC. SCHOOL, CHELLAPILLAIKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22118",
            "district": "22",
            "Block": "383",
            "label": "KALAIMAGAL VIDHYA MANDIR MATRIC HR. SEC. SCHOOL, SESANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22119",
            "district": "22",
            "Block": "274",
            "label": "ST. JOHN'S NATIONAL ACADEMY, ALAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22437",
            "district": "22",
            "Block": "246",
            "label": "GHS VELLALAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22438",
            "district": "22",
            "Block": "163",
            "label": "GHS MATTAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22439",
            "district": "22",
            "Block": "226",
            "label": "GHS PARANATTAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22440",
            "district": "22",
            "Block": "273",
            "label": "GHS KAMINAICKENPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22441",
            "district": "22",
            "Block": "273",
            "label": "SRI CHAITANYA TECHNO SCHOOL,PERUMAL MALAI ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22442",
            "district": "22",
            "Block": "273",
            "label": "THE INDIAN PUBLIC SCHOOL, CHETTICHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22443",
            "district": "22",
            "Block": "34",
            "label": "GHS MASINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22444",
            "district": "22",
            "Block": "274",
            "label": "MHS., KALARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22772",
            "district": "22",
            "Block": "226",
            "label": "ELFS PUBLIC SCHOOL ,VENGAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22773",
            "district": "22",
            "Block": "226",
            "label": "SREE JOTHI VIDYALAYA MATRIC SCHOOL, VENGAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22847",
            "district": "22",
            "Block": "34",
            "label": "CROWN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22855",
            "district": "22",
            "Block": "391",
            "label": "MAHARISHI VIDYA MANDIR (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22859",
            "district": "22",
            "Block": "68",
            "label": "GHS NADUVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22860",
            "district": "22",
            "Block": "163",
            "label": "GHS KANNANTHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22861",
            "district": "22",
            "Block": "226",
            "label": "GHS KAMMALAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22862",
            "district": "22",
            "Block": "61",
            "label": "GHS KURUKKALPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22863",
            "district": "22",
            "Block": "30",
            "label": "GHS THAVALAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23108",
            "district": "22",
            "Block": "30",
            "label": "ATTUR PUBLIC MATRICULATION SCHOOL, RAMANAYAKANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23109",
            "district": "22",
            "Block": "129",
            "label": "SRI RAMAKRISHNA VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23128",
            "district": "22",
            "Block": "413",
            "label": "EKALAVYA MODEL RESIDENTIAL SCHOOL, YERCAUD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23141",
            "district": "22",
            "Block": "34",
            "label": "GTRHS , ADIMALAIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23142",
            "district": "22",
            "Block": "246",
            "label": "GTR HIGH SCHOOL, KUNNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23143",
            "district": "22",
            "Block": "68",
            "label": "GTR HIGH SCHOOL, KODUNGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23177",
            "district": "22",
            "Block": "34",
            "label": "VIDHYA PEETHAM I-SCHOOL, MANNARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23232",
            "district": "22",
            "Block": "203",
            "label": "GHS MOOLAKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23233",
            "district": "22",
            "Block": "129",
            "label": "GHS CHEKKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23247",
            "district": "22",
            "Block": "133",
            "label": "GHS SANARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23265",
            "district": "22",
            "Block": "273",
            "label": "GOLDEN GATES EARTH SCHOOL, KONDAPPANAICKENPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23268",
            "district": "22",
            "Block": "273",
            "label": "SRI VIDYA MANDIR SCHOOL, KANNANKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23275",
            "district": "22",
            "Block": "34",
            "label": "HOLY CROSS INTERNATIONAL SCHOOL, K.N.COLONY, AMMAPET,SALEM-14",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23297",
            "district": "22",
            "Block": "309",
            "label": "MARUTHI MATRICULATION SCHOOL, MANIVIZHUNDHAN SOUTH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23320",
            "district": "22",
            "Block": "226",
            "label": "VEDHHA VIKASS PUBLIC SCHOOL ,SANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23321",
            "district": "22",
            "Block": "273",
            "label": "SRK GLOBAL SCHOOL ,KANDHAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23322",
            "district": "22",
            "Block": "413",
            "label": "SACRED HEART SCHOOL ,YERCAUD",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23416",
            "district": "22",
            "Block": "413",
            "label": "Brightsun Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23420",
            "district": "22",
            "Block": "61",
            "label": "Bharathi vidyalaya nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23539",
            "district": "22",
            "Block": "34",
            "label": "A.R.R.S. MATRIC HR. SEC. SCHOOL, VALASAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23540",
            "district": "22",
            "Block": "34",
            "label": "ROYAL PARK SCHOOL, KUMARAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23541",
            "district": "22",
            "Block": "90",
            "label": "Government Model School, Salem",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23542",
            "district": "22",
            "Block": "90",
            "label": "SALEM DISTRICT GOVERNMENT MODEL SCHOOL, SALEM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23543",
            "district": "22",
            "Block": "133",
            "label": "STANFORD MATRICULATION SCHOOL, ETTIKUTTAI MEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23544",
            "district": "22",
            "Block": "179",
            "label": "MEENAMPARK VIDHYALAYA SCHOOL, PERIYASATHAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23545",
            "district": "22",
            "Block": "215",
            "label": "SRI SAI GURU PUBLIC SCHOOL, VELAGOUNDANPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23546",
            "district": "22",
            "Block": "273",
            "label": "EMERALD VALLEY PUBLIC SCHOOL, KONDAPPANAIKANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23547",
            "district": "22",
            "Block": "274",
            "label": "ST. MARY'S PUBLIC SCHOOL, ARISIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23548",
            "district": "22",
            "Block": "278",
            "label": "SRI KONGU MATRICULATION SCHOOL, SANKARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23733",
            "district": "22",
            "Block": "30",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23741",
            "district": "22",
            "Block": "34",
            "label": "S.B.I.O.A. PUBLIC SCHOOL, GUNDUKALLUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23773",
            "district": "22",
            "Block": "61",
            "label": "PUMS PUTHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23774",
            "district": "22",
            "Block": "61",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23782",
            "district": "22",
            "Block": "68",
            "label": "PUMS, GANDHI NAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23800",
            "district": "22",
            "Block": "90",
            "label": "PUMS, ELATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23801",
            "district": "22",
            "Block": "90",
            "label": "PUMS, NALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23866",
            "district": "22",
            "Block": "163",
            "label": "PUMS, KADAYAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23895",
            "district": "22",
            "Block": "215",
            "label": "KONGU SRV INTERNATIONAL SCHOOL, VELLAKKALPATTI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23907",
            "district": "22",
            "Block": "226",
            "label": "PUMS,KATTUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23908",
            "district": "22",
            "Block": "226",
            "label": "VEDHA VIKKAS HR. SEC. SCHOOL, SANTHIYUR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23940",
            "district": "22",
            "Block": "273",
            "label": "NARAYANA E-TECHNO SCHOOL, REDDIYUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23941",
            "district": "22",
            "Block": "274",
            "label": "SILVERLINES MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23942",
            "district": "22",
            "Block": "274",
            "label": "JOTHI CHANDARAN VIDYAALAYAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23945",
            "district": "22",
            "Block": "278",
            "label": "PUMS VENGIPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24094",
            "district": "22",
            "Block": "413",
            "label": "PUMS, NALLUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "23": [
        {
            "key": "793",
            "district": "23",
            "Block": "79",
            "label": "PUMS SATHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1119",
            "district": "23",
            "Block": "79",
            "label": "PUMS NENJATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1493",
            "district": "23",
            "Block": "79",
            "label": "PUMS S.KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1689",
            "district": "23",
            "Block": "79",
            "label": "PUMS, S.VANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1810",
            "district": "23",
            "Block": "79",
            "label": "PUMS AIYANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2357",
            "district": "23",
            "Block": "272",
            "label": "MARUTHA MALAIYAN MAT SCHOOL KARIKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2637",
            "district": "23",
            "Block": "79",
            "label": "PUMS MELAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2674",
            "district": "23",
            "Block": "79",
            "label": "PUMS NAGARAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2739",
            "district": "23",
            "Block": "79",
            "label": "IQRA MATRIC SCHOOL,, ILAIYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2759",
            "district": "23",
            "Block": "271",
            "label": "PUMS VELLIYANKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2788",
            "district": "23",
            "Block": "79",
            "label": "M N A S MIDDLE SCHOOL SALAIYOOR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2853",
            "district": "23",
            "Block": "272",
            "label": "THE LEADERS MATRICULATION HR SEC SCHOOL KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2873",
            "district": "23",
            "Block": "272",
            "label": "KAMBAN KARPAKAM MATRIC HR SEC SCHOOL , KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2900",
            "district": "23",
            "Block": "79",
            "label": "PUMS MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2949",
            "district": "23",
            "Block": "79",
            "label": "PUMS KEELANETTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2953",
            "district": "23",
            "Block": "271",
            "label": "PUMS VARAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3058",
            "district": "23",
            "Block": "166",
            "label": "PUMS SOMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3060",
            "district": "23",
            "Block": "79",
            "label": "St.ANNES MATRICULATION SCHOOL, ILAYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3065",
            "district": "23",
            "Block": "272",
            "label": "CHIDAMBARAM VISALAKSHI  MATRICULATION  SCHOOL KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3229",
            "district": "23",
            "Block": "272",
            "label": "C.V.C.T.V MEENAKSHI ACHI MATRICULATION SCHOOL, KANADUKATHAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3284",
            "district": "23",
            "Block": "79",
            "label": "ST.MARY MID.SCH, SALAIGRAMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3320",
            "district": "23",
            "Block": "94",
            "label": "HOLY SPIRIT MATRIC.HR.SEC.SCHOOL SEEGOORANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3344",
            "district": "23",
            "Block": "272",
            "label": "MUTHIAH ALAGAPPA MATRIC HR SEC SCHOOL, KOTTAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3391",
            "district": "23",
            "Block": "272",
            "label": "ST MICHAEL MATRIC HR SEC SCHOOL , ARIYAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3402",
            "district": "23",
            "Block": "79",
            "label": "PUMS KONGAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3411",
            "district": "23",
            "Block": "96",
            "label": "KALAIMAGAL MAT SCHOOL,KALLAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3461",
            "district": "23",
            "Block": "271",
            "label": "PUMS KONDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3519",
            "district": "23",
            "Block": "272",
            "label": "SHRI VIDHYAA GIRI MATRICULATION HIGHER SECONDARY SCHOOL, PUDUVAYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3536",
            "district": "23",
            "Block": "79",
            "label": "PUMS PUDUR VALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3606",
            "district": "23",
            "Block": "271",
            "label": "PUMS KIZHAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3607",
            "district": "23",
            "Block": "79",
            "label": "PUMS N.ANDAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3701",
            "district": "23",
            "Block": "272",
            "label": "PUMS MITHRANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3705",
            "district": "23",
            "Block": "106",
            "label": "ILANGO .MID SCHOOL  VENGALORE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3745",
            "district": "23",
            "Block": "79",
            "label": "PUMS, A.NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3784",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PALAVANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3921",
            "district": "23",
            "Block": "271",
            "label": "PUMS K.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4015",
            "district": "23",
            "Block": "272",
            "label": "PUMS V.SOORAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4016",
            "district": "23",
            "Block": "94",
            "label": "O M G S MATRIC SCHOOL KALAIYARKOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4082",
            "district": "23",
            "Block": "79",
            "label": "ST.J.MID.SCHOOL, NALLOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4251",
            "district": "23",
            "Block": "96",
            "label": "KOVILOOR ANDAVAR MATRIC HR SEC SCHOOL, KOVILOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4260",
            "district": "23",
            "Block": "272",
            "label": "MAHARISHI.VIDYA.MANDIR MATRIC.HR.SEC.SCHOOL, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4379",
            "district": "23",
            "Block": "294",
            "label": "OXFORD MATRIC HSS  T.PUDUR SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4446",
            "district": "23",
            "Block": "79",
            "label": "PUMS A. MEIYANENDHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4481",
            "district": "23",
            "Block": "79",
            "label": "NALMEIPER MATRICULATION SCHOOL , VARUNTHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4558",
            "district": "23",
            "Block": "333",
            "label": "PUMS KATTAMANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4684",
            "district": "23",
            "Block": "272",
            "label": "SRI SUBBAIAH AMBALAM MATRIC HR SEC SCHOOL , KOTTAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4769",
            "district": "23",
            "Block": "79",
            "label": "St.MARY'S MIDDLE SCHOOL  SOORANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4891",
            "district": "23",
            "Block": "79",
            "label": "PUMS KANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4926",
            "district": "23",
            "Block": "79",
            "label": "PUMS THAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4939",
            "district": "23",
            "Block": "272",
            "label": "T.V.MID.SCH, NEMATHANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5133",
            "district": "23",
            "Block": "96",
            "label": "PUMS T.NADUVIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5166",
            "district": "23",
            "Block": "79",
            "label": "MUTHU VINAYAGAR MIDDLE SCHOOL , VADAKKU SAMUTHIRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5167",
            "district": "23",
            "Block": "79",
            "label": "PUMS THIRUVALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5261",
            "district": "23",
            "Block": "106",
            "label": "PUMS DEVANDADAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5334",
            "district": "23",
            "Block": "79",
            "label": "PUMS KARUNJUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5347",
            "district": "23",
            "Block": "294",
            "label": "21 ST CENTURY  MATRIC HSS SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5355",
            "district": "23",
            "Block": "272",
            "label": "PUMS CHINNAVENGAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5367",
            "district": "23",
            "Block": "333",
            "label": "ARULMIGU THANDEESWARAR MATRIC. HIGH SCHOOL,THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5378",
            "district": "23",
            "Block": "272",
            "label": "PUMS AMBAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5402",
            "district": "23",
            "Block": "79",
            "label": "PUMS MELATHURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5419",
            "district": "23",
            "Block": "166",
            "label": "BABA MATRIC SCHOOL MANAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5429",
            "district": "23",
            "Block": "79",
            "label": "I.N.P.T MATRIC HS, ILAIYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5485",
            "district": "23",
            "Block": "294",
            "label": "ST JUSTIN'S MATRIC HSS,SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5521",
            "district": "23",
            "Block": "272",
            "label": "PUMS PULIYANKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5527",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PATHARAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5542",
            "district": "23",
            "Block": "271",
            "label": "PUMS GANAPATHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5721",
            "district": "23",
            "Block": "106",
            "label": "PUMS THIRUPPAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5735",
            "district": "23",
            "Block": "56",
            "label": "VAIRAM GROUP MATRIC HSS, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5865",
            "district": "23",
            "Block": "106",
            "label": "PUMS SADAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5936",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PILAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6066",
            "district": "23",
            "Block": "272",
            "label": "PUMS A.VELANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6070",
            "district": "23",
            "Block": "272",
            "label": "SRI SARATHA VIDHALAYA MATRIC SCHOOL, AMARAVATHIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6140",
            "district": "23",
            "Block": "96",
            "label": "PUMS, KALLIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6214",
            "district": "23",
            "Block": "106",
            "label": "PUMS KALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6477",
            "district": "23",
            "Block": "271",
            "label": "PUMS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6552",
            "district": "23",
            "Block": "96",
            "label": "MEYYAPPA MIDDLE SCHOOL,KALLUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6642",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL, SUSAIAPPARPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6713",
            "district": "23",
            "Block": "79",
            "label": "BHARATHIYAR MATRICULATION SCHOOL SOORANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6739",
            "district": "23",
            "Block": "79",
            "label": "ROMAN CATHOLIC MID SCHOOL KOTTAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17317",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS ALAVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17318",
            "district": "23",
            "Block": "294",
            "label": "PUMS AMMATCHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17319",
            "district": "23",
            "Block": "294",
            "label": "PUMS ARASANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17320",
            "district": "23",
            "Block": "294",
            "label": "PUMS AR USILAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17321",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS ARASANOOR THIRUMANJOLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17322",
            "district": "23",
            "Block": "294",
            "label": "PUMS MUTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17323",
            "district": "23",
            "Block": "294",
            "label": "PUMS KUTTITHINNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17324",
            "district": "23",
            "Block": "294",
            "label": "PUMS MAHASIVANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17325",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS ALAGAMANAGARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17326",
            "district": "23",
            "Block": "294",
            "label": "PUMS IDAYAMELUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17327",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS IDAYAMELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17328",
            "district": "23",
            "Block": "294",
            "label": "VIKNESHWARA VIDYALAYA  MATRICULATION SCHOOL IDAYAMELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17329",
            "district": "23",
            "Block": "294",
            "label": "MUTHU MATRICULATION IDAYAMELUR COLONY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17330",
            "district": "23",
            "Block": "294",
            "label": "PUMS KOOTURAVUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17331",
            "district": "23",
            "Block": "294",
            "label": "SARVASEVA HIGH SCHOOL VAHULATHUPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17332",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL KATTANIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17333",
            "district": "23",
            "Block": "294",
            "label": "PUMS KANNARIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17334",
            "district": "23",
            "Block": "294",
            "label": "SAKTHI HIGH SCHOOL JOTHI NAGAR PADAMATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17335",
            "district": "23",
            "Block": "294",
            "label": "PUMS SUNDARANADAPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17336",
            "district": "23",
            "Block": "294",
            "label": "PUMS KANJIRANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17337",
            "district": "23",
            "Block": "294",
            "label": "GOVT MODEL HIGHER SECONDARY SCHOOL KEELAPOONGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17338",
            "district": "23",
            "Block": "294",
            "label": "PUMS VEERAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17339",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL KUMARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17340",
            "district": "23",
            "Block": "294",
            "label": "PUMS URULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17341",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL SITHALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17342",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS MANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17343",
            "district": "23",
            "Block": "294",
            "label": "PUMS MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17344",
            "district": "23",
            "Block": "294",
            "label": "PUMS MELAPOONGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17345",
            "district": "23",
            "Block": "294",
            "label": "SRI KARPAGA MATHA MIDDLE SCHOOL MADAGUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17346",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGHER SECONDARY  SCHOOL V MALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17347",
            "district": "23",
            "Block": "294",
            "label": "SWAMY VIVEKANANTHA MATRIC HR SEC  SCHOOL METTUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17348",
            "district": "23",
            "Block": "294",
            "label": "PUMS NAMANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17349",
            "district": "23",
            "Block": "294",
            "label": "PUMS NALUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17350",
            "district": "23",
            "Block": "294",
            "label": "SC HSS OKKUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17351",
            "district": "23",
            "Block": "294",
            "label": "PUMS OKKUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17352",
            "district": "23",
            "Block": "294",
            "label": "PUMS ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17353",
            "district": "23",
            "Block": "294",
            "label": "ST JOSEPH MATRIC SCHOOL O PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17354",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL PADAMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17355",
            "district": "23",
            "Block": "294",
            "label": "PUMS PILLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17356",
            "district": "23",
            "Block": "294",
            "label": "PUMS PIRAVALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17357",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL MELASALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17358",
            "district": "23",
            "Block": "294",
            "label": "PUMS SOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17359",
            "district": "23",
            "Block": "294",
            "label": "SHUDDHANANDA BHARATI DESIYA VIDYALAYAM  HIGH SCHOOL SOLAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17360",
            "district": "23",
            "Block": "294",
            "label": "SHRI RAMANA VIKASS HIGHER SECONDARY SCHOOL,CHOLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17361",
            "district": "23",
            "Block": "294",
            "label": "PUMS KOMALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17362",
            "district": "23",
            "Block": "294",
            "label": "PUMS PASANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17363",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGHER  SECONDARY  SCHOOL ,  SAKKANTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17364",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS THAMARAKKI SOUTH",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17365",
            "district": "23",
            "Block": "294",
            "label": "PUMS THAMARAKKI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17366",
            "district": "23",
            "Block": "294",
            "label": "PUMS THIRUMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17367",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL KEELAKANDANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17368",
            "district": "23",
            "Block": "294",
            "label": "ANNAI SRI VEERAMAKALIAMMAN MATRIC HR SEC SCHOOL MELAVANIYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17369",
            "district": "23",
            "Block": "294",
            "label": "KARPURASUNDARAPANDIAN RAMALAKSHMI HR SEC SCHOOL SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17370",
            "district": "23",
            "Block": "294",
            "label": "MOTHER THERASA MATRIC SCHOOL SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17371",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS MARUTHUPANDIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17372",
            "district": "23",
            "Block": "294",
            "label": "ST ANNYS MATRTIC  SCHOOL KANJIRANGAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17373",
            "district": "23",
            "Block": "294",
            "label": "GOVT GIRLS HSS, SIVAGANGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17374",
            "district": "23",
            "Block": "294",
            "label": "ST FRANCIS MID SCHOOL BRITTO NAGAR SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "17375",
            "district": "23",
            "Block": "294",
            "label": "ALICE MILLER GIRLS HS SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17376",
            "district": "23",
            "Block": "294",
            "label": "SWAMI VIVEKANANDA HIGH SCHOOL SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17377",
            "district": "23",
            "Block": "294",
            "label": "MANNAR MIDDLE SCHOOL, SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17378",
            "district": "23",
            "Block": "294",
            "label": "MANNAR HR SEC SCHOOL, SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17379",
            "district": "23",
            "Block": "294",
            "label": "Rajakumari Rajarajeshwai kalanilaya MIDDLE SCHOOL SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17380",
            "district": "23",
            "Block": "294",
            "label": "ST. JOSEPH ' S HR SEC SCHOOL SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17381",
            "district": "23",
            "Block": "294",
            "label": "MMS 48 COLONY SIVAGANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17382",
            "district": "23",
            "Block": "294",
            "label": "ST JUSTIN'S GIRLS HSS,SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17383",
            "district": "23",
            "Block": "294",
            "label": "AR NATESAN CHETTIYAR MIDDLE SCHOOL SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17384",
            "district": "23",
            "Block": "294",
            "label": "ROMAN CATHOLIC MID SCHOOL SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17385",
            "district": "23",
            "Block": "294",
            "label": "NAMBIKKAI MANAVALARCHI SCHOOL SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17386",
            "district": "23",
            "Block": "294",
            "label": "KENDIRIYA VIDYALAYA SIVAGANGAI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17387",
            "district": "23",
            "Block": "294",
            "label": "ST MICHAEL MATRIC HSS SCHOOL SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17388",
            "district": "23",
            "Block": "294",
            "label": "AL-HUDHA ISLAMIC INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17389",
            "district": "23",
            "Block": "94",
            "label": "PUMS ETTIAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17390",
            "district": "23",
            "Block": "94",
            "label": "PUMS KARUNGALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17391",
            "district": "23",
            "Block": "94",
            "label": "GOVT  HSS KALAIYARKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17392",
            "district": "23",
            "Block": "94",
            "label": "KRISHNANANTHAM VIDYASHRAM MATRICULATION SCHOOL KALAIYARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17393",
            "district": "23",
            "Block": "94",
            "label": "GOVT HSS ,KOLLANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17394",
            "district": "23",
            "Block": "94",
            "label": "PUMS GOWRIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17395",
            "district": "23",
            "Block": "94",
            "label": "PUMS SILANTHAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17396",
            "district": "23",
            "Block": "94",
            "label": "GOVT  HSS SILUKKUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17397",
            "district": "23",
            "Block": "94",
            "label": "SAMBAVIGA HSS RAGINIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17398",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  SEVALPUNJAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17399",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  PERIAOLAIKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17400",
            "district": "23",
            "Block": "94",
            "label": "PUMS, MARAKKATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17401",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  MANGATTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17402",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  MELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17403",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  MELAMARUNGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17404",
            "district": "23",
            "Block": "94",
            "label": "PUMS UDAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17405",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  USILANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17406",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  KANCHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17407",
            "district": "23",
            "Block": "94",
            "label": "PUMS, SATHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17408",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  SIRUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17409",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17410",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  NADAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17411",
            "district": "23",
            "Block": "94",
            "label": "PUMS, PALUVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17412",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  PILLATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17413",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  PERIYAKILUVATCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17414",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  POTTAGAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17415",
            "district": "23",
            "Block": "94",
            "label": "GOVT HR SEC SCHOOL MARAVAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17416",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  VELARENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17417",
            "district": "23",
            "Block": "94",
            "label": "GOVT ADW  HSS MALLAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17418",
            "district": "23",
            "Block": "94",
            "label": "GOVT GIRLS HS PAGANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17419",
            "district": "23",
            "Block": "94",
            "label": "GOVT HIGHER SECONDARY SCHOOL PAGANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17420",
            "district": "23",
            "Block": "94",
            "label": "K M S C HSS NATTARASAN KOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17421",
            "district": "23",
            "Block": "94",
            "label": "S R M HSS NATTARASAN KOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17422",
            "district": "23",
            "Block": "94",
            "label": "ST.TERESAS CARMEL HIGH SCHOOL PAGAIYANJAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17423",
            "district": "23",
            "Block": "94",
            "label": "ULAGAMATHA HIGH SCHOOL SAKKUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17424",
            "district": "23",
            "Block": "94",
            "label": "GHS,K.CHOCKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17425",
            "district": "23",
            "Block": "94",
            "label": "PUMS, NAGARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17426",
            "district": "23",
            "Block": "94",
            "label": "GOVT  HS KARUMANTHAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17427",
            "district": "23",
            "Block": "94",
            "label": "GOVT  HS SETHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17428",
            "district": "23",
            "Block": "94",
            "label": "PUMS, PANANGADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17429",
            "district": "23",
            "Block": "94",
            "label": "PUMS, KALAYARMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17430",
            "district": "23",
            "Block": "94",
            "label": "PUMS, MUDIKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17431",
            "district": "23",
            "Block": "94",
            "label": "GHS, PERIYAKANNANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17432",
            "district": "23",
            "Block": "94",
            "label": "PUMS, KEELAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17433",
            "district": "23",
            "Block": "94",
            "label": "PUMS, SEMBAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17434",
            "district": "23",
            "Block": "94",
            "label": "PUMS, MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17435",
            "district": "23",
            "Block": "94",
            "label": "ROMANCATHALICKkMIDDLESCHOOL, ANDICHURANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17436",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MID SCHOOL OTTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17437",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL, PULIYADITHAMMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17438",
            "district": "23",
            "Block": "94",
            "label": "AUXILIUM MATRIC HR SEC SCHOOL, PULIYADITHAMMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17439",
            "district": "23",
            "Block": "94",
            "label": "SAHAYARANI GIRLS  HR SEC SCHOOL SUSAIAPPARPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17440",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL, PALLITHAMMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17441",
            "district": "23",
            "Block": "94",
            "label": "DON BOSCO  HR SEC  SCHOOL  PALLITHAMMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17442",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL THUVARALANKANMAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17443",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MID SCHOOL PULIKANMOI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17444",
            "district": "23",
            "Block": "94",
            "label": "SINNAR MANDAR MIDDLE SCHOOL, SENGULIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17445",
            "district": "23",
            "Block": "166",
            "label": "PUMS ARASAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17446",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL CHINNAKANNANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17447",
            "district": "23",
            "Block": "166",
            "label": "SACRED HEART MIDDLE SCHOOL IDAIKATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17448",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL IDAIKATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17449",
            "district": "23",
            "Block": "166",
            "label": "PUMS KEELAMELKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17450",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL KATTIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17451",
            "district": "23",
            "Block": "166",
            "label": "PUMS KEELAPPASALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17452",
            "district": "23",
            "Block": "166",
            "label": "GOOD WILL MATRIC SHOOL MANAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17453",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL VETHIYARENDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17454",
            "district": "23",
            "Block": "166",
            "label": "PUMS KEELAPIDAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17455",
            "district": "23",
            "Block": "166",
            "label": "PUMS KUVALAIVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17456",
            "district": "23",
            "Block": "166",
            "label": "CEYRAC HS, MOONGILOORANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17457",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL MOONGILOORANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17458",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL MELANETTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17459",
            "district": "23",
            "Block": "166",
            "label": "PUMS MELAPIDAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17460",
            "district": "23",
            "Block": "166",
            "label": "PUMS VILAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17461",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL MELAPASALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17462",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL MILAGANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17463",
            "district": "23",
            "Block": "166",
            "label": "PUMS THUTHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17464",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL PERIYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17465",
            "district": "23",
            "Block": "166",
            "label": "ST. CHARLES MATRICULATION SCHOOL, PERIYAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17466",
            "district": "23",
            "Block": "166",
            "label": "PUMS S.PUDUKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17467",
            "district": "23",
            "Block": "166",
            "label": "PUMS NATHAPURAKKI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17468",
            "district": "23",
            "Block": "166",
            "label": "PUMS SEIKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17469",
            "district": "23",
            "Block": "166",
            "label": "GHS KALKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17470",
            "district": "23",
            "Block": "166",
            "label": "PUMS VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17471",
            "district": "23",
            "Block": "166",
            "label": "PUMS PAPPANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17472",
            "district": "23",
            "Block": "166",
            "label": "PUMS KULAIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17473",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL PARAYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17474",
            "district": "23",
            "Block": "166",
            "label": "PUMS AVARANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17475",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL KOMBUKARANENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17476",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL VELLIKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17477",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL VEMBATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17478",
            "district": "23",
            "Block": "166",
            "label": "PUMS V.PUDUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17479",
            "district": "23",
            "Block": "166",
            "label": "PUMS VILATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17480",
            "district": "23",
            "Block": "166",
            "label": "PUMS RAJAGAMBEERAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17481",
            "district": "23",
            "Block": "166",
            "label": "ST MARYS HSS RAJAGAMBEERAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17482",
            "district": "23",
            "Block": "166",
            "label": "PUMS THANJAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17483",
            "district": "23",
            "Block": "166",
            "label": "M.K.N MID SCHOOL T.PUDUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17484",
            "district": "23",
            "Block": "166",
            "label": "PUMS BURMACOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17485",
            "district": "23",
            "Block": "166",
            "label": "SEVENTH DAY ADVENTIST  MAT HSS SCHOOL MANAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17486",
            "district": "23",
            "Block": "166",
            "label": "OVC HSS MANAMADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17487",
            "district": "23",
            "Block": "166",
            "label": "R.C.St.CECILIAL MIDDLE.SCHOOL,MANAMADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17488",
            "district": "23",
            "Block": "166",
            "label": "ST.JOSEPH'S MATRIC HSS SCHOOL MANAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17489",
            "district": "23",
            "Block": "166",
            "label": "C.S.I HIGH SCHOOL MANAMADURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17490",
            "district": "23",
            "Block": "166",
            "label": "GOVT GIRLS HSS MANAMADURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17491",
            "district": "23",
            "Block": "166",
            "label": "CSI DARLING SELVABAI DAVID HIGH SCHOOL FOR THE DEAF, MANAMADURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17492",
            "district": "23",
            "Block": "333",
            "label": "SYED ISMAIL AVULIYA MIDDLE SCHOOL THIRUPPUVANAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "17493",
            "district": "23",
            "Block": "333",
            "label": "PUMS THIRUPPUVANAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17494",
            "district": "23",
            "Block": "333",
            "label": "PUMS THIRUPPUVANAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17495",
            "district": "23",
            "Block": "333",
            "label": "ANNAI SIVA PACKIYAM MEMORIAL HR. SEC. SCHOOL, THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17496",
            "district": "23",
            "Block": "333",
            "label": "GOVT GIRLS HSS THIRUPPUVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17497",
            "district": "23",
            "Block": "333",
            "label": "PUMS THIRUPPUVANAM PALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17498",
            "district": "23",
            "Block": "333",
            "label": "KAMARAJAR MATRIC  HR SEC SCHOOL THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17499",
            "district": "23",
            "Block": "333",
            "label": "ST MARY'S MATRIC HR SEC  THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17500",
            "district": "23",
            "Block": "333",
            "label": "VELAMMAL MATRIC HR SEC  THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17501",
            "district": "23",
            "Block": "333",
            "label": "PUMS MADAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17502",
            "district": "23",
            "Block": "333",
            "label": "GOVT BOYS HSS THIRUPPUVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17503",
            "district": "23",
            "Block": "333",
            "label": "ARIYAVA MONTESSORI MATRIC. HR. SEC. SCHOOL, MADAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17504",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGHER SECONDARY  SCHOOL ALLINAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17505",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL POOVANTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17506",
            "district": "23",
            "Block": "333",
            "label": "MANIMARAN MATRICULATION SCHOOL  POOVANTHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17507",
            "district": "23",
            "Block": "333",
            "label": "PUMS KALUGARKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17508",
            "district": "23",
            "Block": "333",
            "label": "PUMS ENATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17509",
            "district": "23",
            "Block": "333",
            "label": "PUMS KANAKANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17510",
            "district": "23",
            "Block": "333",
            "label": "PUMS MELARANGIAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17511",
            "district": "23",
            "Block": "333",
            "label": "PUMS KELERANGIAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17512",
            "district": "23",
            "Block": "333",
            "label": "PUMS VAYALCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17513",
            "district": "23",
            "Block": "333",
            "label": "PUMS PRAMANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17514",
            "district": "23",
            "Block": "333",
            "label": "PUMS MARANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17515",
            "district": "23",
            "Block": "333",
            "label": "PUMS MALAVARAYANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17516",
            "district": "23",
            "Block": "333",
            "label": "PUMS PASIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17517",
            "district": "23",
            "Block": "333",
            "label": "GOVT HSS KEELADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17518",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL MANALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17519",
            "district": "23",
            "Block": "333",
            "label": "ALAGUMALAR MHSS MANALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17520",
            "district": "23",
            "Block": "333",
            "label": "PUMS MELAVELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17521",
            "district": "23",
            "Block": "333",
            "label": "PUMS KONTHAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17522",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL ILANTHAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17523",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL D.KARISALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17524",
            "district": "23",
            "Block": "333",
            "label": "PUMS KAZHUVANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17525",
            "district": "23",
            "Block": "333",
            "label": "PUMS POTTAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17526",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL KANCHERANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17527",
            "district": "23",
            "Block": "333",
            "label": "PUMS CHOTTATHATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17528",
            "district": "23",
            "Block": "333",
            "label": "PUMS MUKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17529",
            "district": "23",
            "Block": "333",
            "label": "PUMS SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17530",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL THAVATHARENTHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17531",
            "district": "23",
            "Block": "333",
            "label": "PUMS KANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17532",
            "district": "23",
            "Block": "333",
            "label": "PUMS THOOTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17533",
            "district": "23",
            "Block": "333",
            "label": "PUMS THIRUPPACHETHI SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17534",
            "district": "23",
            "Block": "333",
            "label": "GOVT HSS THIRUPPACHETHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17535",
            "district": "23",
            "Block": "333",
            "label": "GOVT HSS PALAYANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17536",
            "district": "23",
            "Block": "333",
            "label": "PUMS VANNIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17537",
            "district": "23",
            "Block": "333",
            "label": "VELAMMAL RESIDENTIAL SCHOOL LADANENDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17538",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL LADANENTHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17539",
            "district": "23",
            "Block": "333",
            "label": "PUMS SANGANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17540",
            "district": "23",
            "Block": "333",
            "label": "PALANI ANDAVAR MIDDLE SCHOOL PAPPKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17541",
            "district": "23",
            "Block": "333",
            "label": "PUMS KURUNTHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17542",
            "district": "23",
            "Block": "333",
            "label": "PUMS T.PAPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17543",
            "district": "23",
            "Block": "333",
            "label": "PUMS MUDHUVANTHIDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17544",
            "district": "23",
            "Block": "333",
            "label": "PUMS T.VELANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17545",
            "district": "23",
            "Block": "333",
            "label": "ROMAN CATHOLIC.1 MIDDLE SCHOOL MICHALPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17546",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL KALLURANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17547",
            "district": "23",
            "Block": "333",
            "label": "ST MARY'S HIGH SCHOOL MICHAELPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17548",
            "district": "23",
            "Block": "333",
            "label": "PUMS KILATHARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17549",
            "district": "23",
            "Block": "79",
            "label": "ST EUGENE HIGH SCHOOL, KOMBADIMADURAI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17550",
            "district": "23",
            "Block": "79",
            "label": "ST. ANTONY'S MIDDLE SCHOOL, KALVELIPOTTAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17551",
            "district": "23",
            "Block": "79",
            "label": "BONIFACE HS , ARIYANDIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17552",
            "district": "23",
            "Block": "79",
            "label": "GOVT. HSS MUNAIVENDRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17553",
            "district": "23",
            "Block": "79",
            "label": "GOVT HIGH SCHOOL  KATCHATHANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17554",
            "district": "23",
            "Block": "79",
            "label": "VEERAMA MUNIVAR.MID.SCHOOL, OCCHANTHATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17555",
            "district": "23",
            "Block": "79",
            "label": "ST.JAMES HSS, SOORANAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17556",
            "district": "23",
            "Block": "79",
            "label": "ST ANTONY'S HIGH SCHOOL, KALLADITHIDAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17557",
            "district": "23",
            "Block": "79",
            "label": "GOVT HIGH SCHOOL ALAVIDANGAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17558",
            "district": "23",
            "Block": "79",
            "label": "GOVT HSS, SALAIGRAMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17559",
            "district": "23",
            "Block": "79",
            "label": "GOVT HIGHER SEC SCHOOL SATHANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17560",
            "district": "23",
            "Block": "79",
            "label": "PUMS MARUTHANGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17561",
            "district": "23",
            "Block": "79",
            "label": "R.C HIGH SCHOOL, KUNDUKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17562",
            "district": "23",
            "Block": "79",
            "label": "PUMS, THUGAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17563",
            "district": "23",
            "Block": "79",
            "label": "HAJEE KK IBRAHIM ALI HR SEC SCHOOL PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17564",
            "district": "23",
            "Block": "79",
            "label": "HAMEEDIA HSS, SALAIYOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17565",
            "district": "23",
            "Block": "79",
            "label": "MELAPALLI VASAL (G) HSS, ILAIYANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17566",
            "district": "23",
            "Block": "79",
            "label": "ILAYANGUDI HSS , ILAYANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17567",
            "district": "23",
            "Block": "79",
            "label": "GOVT (G) HIGH SCHOOL ILAYANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17568",
            "district": "23",
            "Block": "56",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, PERIYAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17569",
            "district": "23",
            "Block": "56",
            "label": "PUMS EZHUVANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17570",
            "district": "23",
            "Block": "56",
            "label": "PUMS, NATCHANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17571",
            "district": "23",
            "Block": "56",
            "label": "PUMS BHARATHI VELANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17572",
            "district": "23",
            "Block": "56",
            "label": "PUMS KALLANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17573",
            "district": "23",
            "Block": "56",
            "label": "PUMS D.NAGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17574",
            "district": "23",
            "Block": "56",
            "label": "PUMS MAVIDITHIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17575",
            "district": "23",
            "Block": "56",
            "label": "ST. JOHN DE BRITTO MIDDLE SCHOOL, PULIYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17576",
            "district": "23",
            "Block": "56",
            "label": "GOVT HIGH SCHOOl, PULIYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17577",
            "district": "23",
            "Block": "56",
            "label": "MOUNT SENARIO MATRIC HIGHER SECONDARY SCHOOL, MUPPAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17578",
            "district": "23",
            "Block": "56",
            "label": "GOVT HIGH SCHOOL, MUPPAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17579",
            "district": "23",
            "Block": "56",
            "label": "PUMS. PIDARANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17580",
            "district": "23",
            "Block": "56",
            "label": "PUMS KAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17581",
            "district": "23",
            "Block": "56",
            "label": "SRI SHANMUGA MIDDLE SCHOOL, VELAYUTHAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17582",
            "district": "23",
            "Block": "56",
            "label": "PUMS PARAMBAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17583",
            "district": "23",
            "Block": "56",
            "label": "PUMS PANIPULANVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17584",
            "district": "23",
            "Block": "56",
            "label": "P.S.S HIGH SCHOOL, SHANMUGANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17585",
            "district": "23",
            "Block": "56",
            "label": "PUMS VELIMUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17586",
            "district": "23",
            "Block": "56",
            "label": "ST JOHN'S GIRLS HR SEC SCHOOL DEVAKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17587",
            "district": "23",
            "Block": "56",
            "label": "PUMS ATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17588",
            "district": "23",
            "Block": "56",
            "label": "ST. JOSEPH MIDDLE SCHOOL, THIDAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17589",
            "district": "23",
            "Block": "56",
            "label": "PUMS SELUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17590",
            "district": "23",
            "Block": "56",
            "label": "MUNICIPAL HIGH SCHOOL, DEVAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17591",
            "district": "23",
            "Block": "56",
            "label": "10TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17592",
            "district": "23",
            "Block": "56",
            "label": "16TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17593",
            "district": "23",
            "Block": "56",
            "label": "KVP MATRICULATION SCHOOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17594",
            "district": "23",
            "Block": "56",
            "label": "14TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17595",
            "district": "23",
            "Block": "56",
            "label": "PETHAL ACHI GIRLS HR SEC SCHOOL, DEVAKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17596",
            "district": "23",
            "Block": "56",
            "label": "INFANT JESUS MATRIC HSS, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17597",
            "district": "23",
            "Block": "56",
            "label": "SRI MURUGANANDHA MIDDLE SCHOOL DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17598",
            "district": "23",
            "Block": "56",
            "label": "MANICKAM MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17599",
            "district": "23",
            "Block": "56",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL, RAMNAGAR, DEVAKOTTAI (EXTN.)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17600",
            "district": "23",
            "Block": "56",
            "label": "ST JOSEPH MATRIC HIGHER SECONDARY SCHOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17601",
            "district": "23",
            "Block": "56",
            "label": "S.R.MID SCHOOL DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17602",
            "district": "23",
            "Block": "56",
            "label": "ZAMINTHAR STREET HIGH SCHOOL DEVAKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17603",
            "district": "23",
            "Block": "56",
            "label": "CHAIRMAN MANICKAVASAGAM MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17604",
            "district": "23",
            "Block": "56",
            "label": "SRI SEVUGAN ANNAMALAI MATRIC SCHOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17605",
            "district": "23",
            "Block": "56",
            "label": "THIRUVALLUVAR ARULNERI MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17606",
            "district": "23",
            "Block": "56",
            "label": "SRINIVASA MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17607",
            "district": "23",
            "Block": "56",
            "label": "MUTHATHAL MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17608",
            "district": "23",
            "Block": "56",
            "label": "DE BRITTO HSS, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17609",
            "district": "23",
            "Block": "56",
            "label": "N.S.M.V.P.S HSS, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17610",
            "district": "23",
            "Block": "56",
            "label": "ST MARY'S GIRLS HR SEC SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17611",
            "district": "23",
            "Block": "56",
            "label": "LOTUS AN. VENKATACHALAM CHETTIAR ICSE SCHOOL DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17612",
            "district": "23",
            "Block": "56",
            "label": "PUMS ANTHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17613",
            "district": "23",
            "Block": "56",
            "label": "GOVT. HR SEC SCHOOL, THIRUVEGAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17614",
            "district": "23",
            "Block": "56",
            "label": "PUMS KAVATHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17615",
            "district": "23",
            "Block": "56",
            "label": "PUMS SENKARKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17616",
            "district": "23",
            "Block": "56",
            "label": "PUMS ILANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17617",
            "district": "23",
            "Block": "56",
            "label": "SAINT JOSEPH MIDDLE SCHOOL, SARUGANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17618",
            "district": "23",
            "Block": "56",
            "label": "ST. PAUL'S HIGH SCHOOL, SARUGANI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17619",
            "district": "23",
            "Block": "56",
            "label": "ST JOSEPH'S HR SEC SCHOOL, SEKKAKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17620",
            "district": "23",
            "Block": "56",
            "label": "V.N.T.GOVT HR SEC SCHOOL, SHANMUGANATHAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17621",
            "district": "23",
            "Block": "56",
            "label": "RC HIGH SCHOOL, KEELAUCHANI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17622",
            "district": "23",
            "Block": "56",
            "label": "PUMS URUVATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17623",
            "district": "23",
            "Block": "56",
            "label": "PUMS SIRUNALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17624",
            "district": "23",
            "Block": "106",
            "label": "ROMANCATHOLICMIDDLESCHOOL NANAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17625",
            "district": "23",
            "Block": "106",
            "label": "GOVT HSS HANUMANTHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17626",
            "district": "23",
            "Block": "106",
            "label": "PUMS UNJANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17627",
            "district": "23",
            "Block": "106",
            "label": "GOVT.(ADW)HS, UNJANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17628",
            "district": "23",
            "Block": "106",
            "label": "GOVT HS NARANAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17629",
            "district": "23",
            "Block": "106",
            "label": "PUMS MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17630",
            "district": "23",
            "Block": "106",
            "label": "GOVT HIGH SCHOOL SIRUVATCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17631",
            "district": "23",
            "Block": "106",
            "label": "GOVT HSS KANNANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17632",
            "district": "23",
            "Block": "106",
            "label": "PUMS KODIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17633",
            "district": "23",
            "Block": "106",
            "label": "PUMS VADAKEELGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17634",
            "district": "23",
            "Block": "106",
            "label": "PUMS CHITHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17635",
            "district": "23",
            "Block": "106",
            "label": "GOVERNMENT HIGH SCHOOL, T.SIRUVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17636",
            "district": "23",
            "Block": "272",
            "label": "GOVT HSS, O.SIRUVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17637",
            "district": "23",
            "Block": "272",
            "label": "CHELLAPPAN VIDYA MANDIR KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17638",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, THIRUVELANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17639",
            "district": "23",
            "Block": "272",
            "label": "M.C.T.M.C.C HR SEC, SCHOOL KANADUKATHAN",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17640",
            "district": "23",
            "Block": "272",
            "label": "SELVA VINAYAGAR HIGH SCHOOL, KOTHAMANGALAM.",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17641",
            "district": "23",
            "Block": "272",
            "label": "SEETHAI ACHI MIDDLE SCHOOL PALLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17642",
            "district": "23",
            "Block": "272",
            "label": "A.C HSS, PALLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17643",
            "district": "23",
            "Block": "272",
            "label": "R.M.M.GIRLS.HIGH SCH PALLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17644",
            "district": "23",
            "Block": "272",
            "label": "AL.V.A.M.PUMS , KOTTAIYUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17645",
            "district": "23",
            "Block": "272",
            "label": "C.C. (G) HSS, KOTTAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17646",
            "district": "23",
            "Block": "272",
            "label": "T.A.C.GOVT.HR.SEC SCHOOL ,  KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17647",
            "district": "23",
            "Block": "272",
            "label": "M.M. SCHOOL, KALANIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17648",
            "district": "23",
            "Block": "272",
            "label": "PUMS NESAVALAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17649",
            "district": "23",
            "Block": "272",
            "label": "SRI VAIRAVAR MATRIC HIGH SCHOOL , KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17650",
            "district": "23",
            "Block": "272",
            "label": "PRNC MIDDLE SCHOOL KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17651",
            "district": "23",
            "Block": "272",
            "label": "B.MPL MID SCHOOL BARATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17652",
            "district": "23",
            "Block": "272",
            "label": "MPL MID SCHOOL SOUTH STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17653",
            "district": "23",
            "Block": "272",
            "label": "V.O.C MPL MID SCHOOL KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17654",
            "district": "23",
            "Block": "272",
            "label": "M.V GOVT HSS, KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17655",
            "district": "23",
            "Block": "272",
            "label": "GOVT (G) HSS, MUTHUPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17656",
            "district": "23",
            "Block": "272",
            "label": "S.M (G) HSS, KARAIKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17657",
            "district": "23",
            "Block": "272",
            "label": "S.M.S.V.HR SEC SCHOOL , KARAIKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17658",
            "district": "23",
            "Block": "272",
            "label": "ALAGAPPA MODEL HSS, KARAIKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17659",
            "district": "23",
            "Block": "272",
            "label": "ANNAI VELANGANNI MATRIC SCHOOL , KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17660",
            "district": "23",
            "Block": "272",
            "label": "AZAD.MATRIC HIGH SCHOOL, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17661",
            "district": "23",
            "Block": "272",
            "label": "SAHAYAMATHA MATRICULATION HIGHER SECONDARY SCHOOL,KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17662",
            "district": "23",
            "Block": "272",
            "label": "SRI RAGHAVENDRA MATRIC HR SEC SCHOOL, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17663",
            "district": "23",
            "Block": "272",
            "label": "R.H. HIGHER SECONDARY  SCHOOL. ( RESIDENTIAL  HANDICAPED ),KARIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17664",
            "district": "23",
            "Block": "272",
            "label": "ALANGUDIYAR STREET MUNICIPAL HIGH SCHOOL ,   KARAIKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17665",
            "district": "23",
            "Block": "272",
            "label": "L.F.R.C HIGHER SECONDARY SCHOOL, KARAIKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17666",
            "district": "23",
            "Block": "272",
            "label": "SRI KALAIVANI VIDHYALAYA MATRIC HSS, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17667",
            "district": "23",
            "Block": "272",
            "label": "SCGR HSS AMARAVATHIPUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17668",
            "district": "23",
            "Block": "272",
            "label": "PUMS DEVAKOTTAI SALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17669",
            "district": "23",
            "Block": "272",
            "label": "GOVT HSS, ARIYAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17670",
            "district": "23",
            "Block": "272",
            "label": "INFANT JESUS MAT SCOOL ARIYAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17671",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL ILUPPAIKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17672",
            "district": "23",
            "Block": "272",
            "label": "ALAGAPPA MATRIC HSS, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17673",
            "district": "23",
            "Block": "272",
            "label": "KENDRIYA VIDHYALAYA SCHOOL, KARAIKUDI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17674",
            "district": "23",
            "Block": "272",
            "label": "C.A.M. HIGH SCHOOL ,KANDANUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17675",
            "district": "23",
            "Block": "272",
            "label": "R.C HSS, PUDUVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17676",
            "district": "23",
            "Block": "272",
            "label": "SRI SARASWATHI  VIDYASALAI GIRLS HR SEC SCHOOL PUDUVAYAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17677",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, SAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17678",
            "district": "23",
            "Block": "272",
            "label": "SRI KALAIMAGAL VIDYALAYA MHSS PUDHUVAYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17679",
            "district": "23",
            "Block": "272",
            "label": "Shangaranandha Gurugulam (CBSE) School-Puduvayal",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17680",
            "district": "23",
            "Block": "272",
            "label": "PUMS PETHATCHIKUDIIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17681",
            "district": "23",
            "Block": "272",
            "label": "GOVT HSS, PEERKALAIKADU -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17682",
            "district": "23",
            "Block": "272",
            "label": "PUMS AYENIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17683",
            "district": "23",
            "Block": "272",
            "label": "Pums Vilarikkadu Panampatti",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17684",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, SIRUGAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17685",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, PERIYAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17686",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, JEYANKONDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17687",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, SITHIVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17688",
            "district": "23",
            "Block": "272",
            "label": "GOVT HSS, MITHIRAVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17689",
            "district": "23",
            "Block": "272",
            "label": "PUMS N.PALAIUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17690",
            "district": "23",
            "Block": "331",
            "label": "PUMS ALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17691",
            "district": "23",
            "Block": "331",
            "label": "PUMS AIYINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17692",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL E SENBAGAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17693",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL SEVVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17694",
            "district": "23",
            "Block": "331",
            "label": "PUMS MITHILAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17695",
            "district": "23",
            "Block": "331",
            "label": "PUMS K.ILAYATHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17696",
            "district": "23",
            "Block": "331",
            "label": "VIVEKANANDA KALVI KAZHAGAM  MIDDLE SCHOOL AAVINIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17697",
            "district": "23",
            "Block": "331",
            "label": "SMS HSS KEELASEVALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17698",
            "district": "23",
            "Block": "331",
            "label": "RM M MAT SCHOOL KILASELVALPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17699",
            "district": "23",
            "Block": "331",
            "label": "PUMS PIRAMANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17700",
            "district": "23",
            "Block": "331",
            "label": "PUMS MELAIYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17701",
            "district": "23",
            "Block": "331",
            "label": "SPD.MID.SCHOOL KANDAVARAYANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17702",
            "district": "23",
            "Block": "331",
            "label": "PUMS KARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17703",
            "district": "23",
            "Block": "331",
            "label": "PUMS MANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17704",
            "district": "23",
            "Block": "331",
            "label": "PUMS KARUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17705",
            "district": "23",
            "Block": "331",
            "label": "PUMS DEVARAMBOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17706",
            "district": "23",
            "Block": "331",
            "label": "PLAZAA  MATRICULATION  SCHOOL ,   PUDHUKATTAMBUR.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17707",
            "district": "23",
            "Block": "331",
            "label": "PUMS KANAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17708",
            "district": "23",
            "Block": "331",
            "label": "PUMS V.MALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17709",
            "district": "23",
            "Block": "331",
            "label": "GOVT HSS S VELANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17710",
            "district": "23",
            "Block": "331",
            "label": "PUMS KONNATHANPATI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17711",
            "district": "23",
            "Block": "331",
            "label": "GOVT HSS KOTTAIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17712",
            "district": "23",
            "Block": "331",
            "label": "PUMS THIRUVIDAIYARPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17713",
            "district": "23",
            "Block": "331",
            "label": "K.V.MID SCHOOL SIRUKOODALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17714",
            "district": "23",
            "Block": "331",
            "label": "PUMS VANCHINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17715",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL MAHIBALANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17716",
            "district": "23",
            "Block": "331",
            "label": "PUMS NEDUMARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17717",
            "district": "23",
            "Block": "331",
            "label": "VT GIRLS HIGH SCHOOL A.THEKKUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17718",
            "district": "23",
            "Block": "331",
            "label": "SVK HSS A THEKKUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17719",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL THIRUKKALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17720",
            "district": "23",
            "Block": "331",
            "label": "PUMS CHAMANTHANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17721",
            "district": "23",
            "Block": "331",
            "label": "GOVT HSS THIRUKKOSTIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17722",
            "district": "23",
            "Block": "331",
            "label": "PUMS THUVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17723",
            "district": "23",
            "Block": "331",
            "label": "SSARAVANA MATRIC SCHOOL THUVAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17724",
            "district": "23",
            "Block": "331",
            "label": "PUMS M.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17725",
            "district": "23",
            "Block": "331",
            "label": "PUMS VANIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17726",
            "district": "23",
            "Block": "331",
            "label": "PUMS SEVINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17727",
            "district": "23",
            "Block": "331",
            "label": "TM GOVT HSS POOLANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17728",
            "district": "23",
            "Block": "331",
            "label": "AVM Public Matriculation Higher Secondary School,Sevvoor",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17729",
            "district": "23",
            "Block": "331",
            "label": "PUMS SUNNAMBIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17730",
            "district": "23",
            "Block": "331",
            "label": "PUMS MARKKANDEYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17731",
            "district": "23",
            "Block": "331",
            "label": "PUMS CHUNDAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17732",
            "district": "23",
            "Block": "331",
            "label": "PUMS K.VAIRAVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17733",
            "district": "23",
            "Block": "331",
            "label": "SATHAPPA GOVT HSS NERKKUPPAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17734",
            "district": "23",
            "Block": "331",
            "label": "PUMS N.VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17735",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL T PUTHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17736",
            "district": "23",
            "Block": "331",
            "label": "LIMRA MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17737",
            "district": "23",
            "Block": "331",
            "label": "N.M.GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ,THIRUPPATHUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17738",
            "district": "23",
            "Block": "331",
            "label": "CHRISTHURAJA MATRIC HIGHER SECONDARY SCHOOL K.VAIRAVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17739",
            "district": "23",
            "Block": "331",
            "label": "MUSLIM PULAVAR MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17740",
            "district": "23",
            "Block": "331",
            "label": "R.C.FATHIMA MIDDLE SCHOOL , THIRUPPATHUR. .",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17741",
            "district": "23",
            "Block": "331",
            "label": "BABA AMIR BADHUSHA MATRIC HR SEC SCHOOL, THIRUPPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17742",
            "district": "23",
            "Block": "331",
            "label": "TELC TRANSATIONAL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17743",
            "district": "23",
            "Block": "331",
            "label": "A.P.GOVT.(B)HR.SEC.SCHOOL THIRUPPATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17744",
            "district": "23",
            "Block": "331",
            "label": "ARUMUGAM PILLAI SEETHAI AMMAL MATRIC  HR SEC SCHOOL, THIRUPPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17745",
            "district": "23",
            "Block": "331",
            "label": "TELC BLIND MIDDLE SCHOOL TPR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17746",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL THENMAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17747",
            "district": "23",
            "Block": "331",
            "label": "INDIRAGANDHI MATRICULATION SCHOOL THENMAPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17748",
            "district": "23",
            "Block": "292",
            "label": "PUMS VENGAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17749",
            "district": "23",
            "Block": "292",
            "label": "GOVT BOYS HSS SINGAMPUNARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17750",
            "district": "23",
            "Block": "292",
            "label": "RM  RM GOVT GIRLS HR SEC  SCHOOL SINGAMPUNARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17751",
            "district": "23",
            "Block": "292",
            "label": "GOVT BOYS HIGH SCHOOL A KALAPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17752",
            "district": "23",
            "Block": "292",
            "label": "AVM MATRIC HSS SINGAMPUNARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17753",
            "district": "23",
            "Block": "292",
            "label": "GOVT GIRLS HIGH SCHOOL A KALAPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17754",
            "district": "23",
            "Block": "292",
            "label": "SRI SEVUGAMOORTHY MATRIC SCHOOL, SINGAMPUNARI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17755",
            "district": "23",
            "Block": "292",
            "label": "PUMS KAPPARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17756",
            "district": "23",
            "Block": "292",
            "label": "PUMS VETTAIANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17757",
            "district": "23",
            "Block": "292",
            "label": "PV MATRIC HSS SINGAMPUNARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17758",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL ANIAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17759",
            "district": "23",
            "Block": "292",
            "label": "PUMS SIRUMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17760",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL CHELLIAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17761",
            "district": "23",
            "Block": "292",
            "label": "PUMS MARUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17762",
            "district": "23",
            "Block": "292",
            "label": "GOVT HSS ERIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17763",
            "district": "23",
            "Block": "292",
            "label": "PUMS S.MAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17764",
            "district": "23",
            "Block": "292",
            "label": "PUMS MELAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17765",
            "district": "23",
            "Block": "292",
            "label": "VALLAL PARI HSS PIRANMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17766",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL MALLAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17767",
            "district": "23",
            "Block": "292",
            "label": "PUMS S KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17768",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL M SOORAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17769",
            "district": "23",
            "Block": "292",
            "label": "GOVT HSS SS KOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17770",
            "district": "23",
            "Block": "292",
            "label": "PUMS VADAVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17771",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGHER SECONDARY SCHOOL KIRUNGAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17772",
            "district": "23",
            "Block": "292",
            "label": "PUMS ERUMAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17773",
            "district": "23",
            "Block": "292",
            "label": "PUMS JEYANKONDANELAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17774",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL ARALIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17775",
            "district": "23",
            "Block": "292",
            "label": "PUMS KALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17776",
            "district": "23",
            "Block": "292",
            "label": "GOVT HSS A MURAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17777",
            "district": "23",
            "Block": "292",
            "label": "PUMS S.SEVALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17778",
            "district": "23",
            "Block": "271",
            "label": "PUMS ARIYANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17779",
            "district": "23",
            "Block": "271",
            "label": "RMSA MODEL HSS, KATTUKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17780",
            "district": "23",
            "Block": "271",
            "label": "PUMS KURUMBALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17781",
            "district": "23",
            "Block": "271",
            "label": "PUMS DHARMAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17782",
            "district": "23",
            "Block": "271",
            "label": "PUMS NAGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17783",
            "district": "23",
            "Block": "271",
            "label": "GOVT HR SEC SCHOOL MUSUNDAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17784",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS  KARISALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17785",
            "district": "23",
            "Block": "271",
            "label": "PUMS URATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17786",
            "district": "23",
            "Block": "271",
            "label": "PUMS MELAVANNARIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17787",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS KATTUKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17788",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS PULUTHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17789",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS ULAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17790",
            "district": "23",
            "Block": "271",
            "label": "KGBV ULAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17791",
            "district": "23",
            "Block": "271",
            "label": "GOVT HS VALASAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17792",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS V.PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17793",
            "district": "23",
            "Block": "96",
            "label": "GOVT GIRLS HS KALLAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17794",
            "district": "23",
            "Block": "96",
            "label": "KALLAL MURUGAPPA  HR SEC SCHOOL, KALLAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17795",
            "district": "23",
            "Block": "96",
            "label": "SHANTHI RANI  MATRIC HR SEC SCHOOL, KALLAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17796",
            "district": "23",
            "Block": "96",
            "label": "BRITTO HR.SEC.SCHOOL, KALLAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17797",
            "district": "23",
            "Block": "96",
            "label": "NEHRU.MIDDLE.SCHOOL, ATHANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17798",
            "district": "23",
            "Block": "96",
            "label": "GOVT HS ALANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17799",
            "district": "23",
            "Block": "96",
            "label": "TAGOREMIDDLE.SCHOOL,A.SIRUVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17800",
            "district": "23",
            "Block": "96",
            "label": "ROMANCATHALICKMIDDLESCHOOL, SATHARASANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17801",
            "district": "23",
            "Block": "96",
            "label": "PUMS, KALIPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17802",
            "district": "23",
            "Block": "96",
            "label": "PUMS, KOOTHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17803",
            "district": "23",
            "Block": "96",
            "label": "SETHU  IRANI MATRIC HR SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17804",
            "district": "23",
            "Block": "96",
            "label": "T.R.V.A.HIGH SCHOOL ,PATTAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17805",
            "district": "23",
            "Block": "96",
            "label": "D.K.G.HR.SEC..SCH,KUNDRAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17806",
            "district": "23",
            "Block": "96",
            "label": "PUMS.KURUNTHAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17807",
            "district": "23",
            "Block": "96",
            "label": "ST.SEBASTIAN'S HIGH SCHOOL - KUTHALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17808",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PADATHANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17809",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PEYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17810",
            "district": "23",
            "Block": "96",
            "label": "GOVT HS KOVILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17811",
            "district": "23",
            "Block": "96",
            "label": "R C M HS NADARAJAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17812",
            "district": "23",
            "Block": "96",
            "label": "J V HS NACHIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17813",
            "district": "23",
            "Block": "96",
            "label": "PUMS, N.VAIRAVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17814",
            "district": "23",
            "Block": "96",
            "label": "RC MIDDLE SCHOOL, PANANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17815",
            "district": "23",
            "Block": "96",
            "label": "PUMS, SEVARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17816",
            "district": "23",
            "Block": "96",
            "label": "PUMS, S.R.PATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17817",
            "district": "23",
            "Block": "96",
            "label": "M.K.GHSS, SEMBANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17818",
            "district": "23",
            "Block": "96",
            "label": "SOMASUNDARAM MIDDLE SCHOOL,SIRAVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17819",
            "district": "23",
            "Block": "96",
            "label": "ADW.GOVT.HIGH.SCHOOL, ATHIKARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17820",
            "district": "23",
            "Block": "96",
            "label": "PUMS, THALAKAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17821",
            "district": "23",
            "Block": "96",
            "label": "L.A.R.MID.SCHOOL,MANAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17822",
            "district": "23",
            "Block": "96",
            "label": "GOVT MODEL HIGHER SECONDARY SCHOOL VISALAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17823",
            "district": "23",
            "Block": "96",
            "label": "PUMS, VEPPANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17824",
            "district": "23",
            "Block": "96",
            "label": "GOVT HSS VETRIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17825",
            "district": "23",
            "Block": "96",
            "label": "SRI DHANDAYUTHAPANI HS KANDRAMANICKAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17826",
            "district": "23",
            "Block": "96",
            "label": "M.A.P.G.HIGH.SCHOOL,KANDRAMANICKAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17827",
            "district": "23",
            "Block": "96",
            "label": "PUMS, K.VALAIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20918",
            "district": "23",
            "Block": "294",
            "label": "MONTFORT  CBSE SCHOOL,SUNDARANADAPPU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20976",
            "district": "23",
            "Block": "294",
            "label": "ST. XAVIER PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20996",
            "district": "23",
            "Block": "94",
            "label": "PAUL SANKAR CBSE SCHOOL,NARUPUTHAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21332",
            "district": "23",
            "Block": "294",
            "label": "NEHRUJI CENTRAL HIGHER SECONDARY SCHOOL CBSE. IDAYAMELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21333",
            "district": "23",
            "Block": "56",
            "label": "BHAARATH PUBLIC CBSE SCHOOL, AARAVAYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21334",
            "district": "23",
            "Block": "272",
            "label": "THE LEADERS ACADEMY KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21335",
            "district": "23",
            "Block": "96",
            "label": "CHETTINAD PUBLIC SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21396",
            "district": "23",
            "Block": "294",
            "label": "GHS MUDIKANDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21399",
            "district": "23",
            "Block": "272",
            "label": "GHS, POYYAVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21564",
            "district": "23",
            "Block": "272",
            "label": "RAMANATHAN CHETTIAR MUNICIPAL HIGH SCHOOL  KARAIKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21636",
            "district": "23",
            "Block": "94",
            "label": "ST.JOSEPH S PUBLIC SCHOOL,KALAIYARKOI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21841",
            "district": "23",
            "Block": "94",
            "label": "ST.JOSEPH'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21842",
            "district": "23",
            "Block": "94",
            "label": "ST. MICHAEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21843",
            "district": "23",
            "Block": "94",
            "label": "OXFORD MATRICULATION HIGHER SECONDARY SCHOOL SOORAKKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21844",
            "district": "23",
            "Block": "94",
            "label": "SRI SARASWATHY VIKAS VIDYALAYA MATRIC HR SEC SCHOOL, KALAYARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21845",
            "district": "23",
            "Block": "96",
            "label": "MOUNT ZION SILVER JUBILEE MATRICULATION HR SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21846",
            "district": "23",
            "Block": "272",
            "label": "SARAVANA MATRICULATION MIDDLE SCHOOL MANACHAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21847",
            "district": "23",
            "Block": "272",
            "label": "SHREE RAAJA RAJAN SCHOOL  AMARAVATHI PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21848",
            "district": "23",
            "Block": "272",
            "label": "ALAGAPPA  ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21849",
            "district": "23",
            "Block": "272",
            "label": "ALAGAPPA GIRLS MAT.HSS. SCHOOL, KARAIKUDI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21850",
            "district": "23",
            "Block": "272",
            "label": "KARAIKUDI MAHARISHI VIDYA MANDIR MT.HR.SEC.  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21851",
            "district": "23",
            "Block": "56",
            "label": "PANCHU VALLIAPPA MATRICULATION SCHOOL DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21852",
            "district": "23",
            "Block": "166",
            "label": "GOODWILL MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21914",
            "district": "23",
            "Block": "106",
            "label": "SRI KALAIVANI INTERNATIONAL(ICSE)SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21975",
            "district": "23",
            "Block": "272",
            "label": "VIDHYA GIRI MATRICULATION HIGHER SECONDARY SCHOOL, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22234",
            "district": "23",
            "Block": "294",
            "label": "MOUNT LITERA ZEE SCHOOL, SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22235",
            "district": "23",
            "Block": "94",
            "label": "BODHI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22236",
            "district": "23",
            "Block": "333",
            "label": "BRITISH INTERNATIONAL SCHOOL CBSE, MADAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22237",
            "district": "23",
            "Block": "79",
            "label": "ILM PUBLIC SCHOOL ILAYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22238",
            "district": "23",
            "Block": "56",
            "label": "ST. JOSEPH INTERNATIONAL SCHOOL,KODIKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22239",
            "district": "23",
            "Block": "56",
            "label": "SHREE CHINNAPPAN VIDHYA MANDHIR MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22240",
            "district": "23",
            "Block": "292",
            "label": "GLOBAL INTERNATIONAL SCHOOL, M. KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22592",
            "district": "23",
            "Block": "294",
            "label": "PRIST UNIVERSITY PUBLIC SCHOOL,ARASANOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22593",
            "district": "23",
            "Block": "294",
            "label": "ST. ANNE'S KARUNALAYA RESIDENTIAL SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22594",
            "district": "23",
            "Block": "94",
            "label": "ARIBALA MATRICULATION SCHOOL  PALKULAM MARAVAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22595",
            "district": "23",
            "Block": "272",
            "label": "GHS,SAKKAVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22596",
            "district": "23",
            "Block": "331",
            "label": "SKS CBSE SCHOOL,THIRUPPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22701",
            "district": "23",
            "Block": "94",
            "label": "KARPAGA MATRICULATION  SCHOOL SAKTHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22702",
            "district": "23",
            "Block": "294",
            "label": "SASTRA ENGLISH SCHOOL GANDHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22932",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL  KALIYANTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22933",
            "district": "23",
            "Block": "294",
            "label": "GHS ILUPPAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23029",
            "district": "23",
            "Block": "96",
            "label": "SRI RAJA VIDYA VIKAAS CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23209",
            "district": "23",
            "Block": "294",
            "label": "SRI THIYAGARAJA MATRICULATION SCHOOL, PERUNGUDI.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23210",
            "district": "23",
            "Block": "294",
            "label": "KENDRIYA VIDYALAYA ITBP , ILLUPPAIKUDI .",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23549",
            "district": "23",
            "Block": "56",
            "label": "ST.MARY'S MATRICULATION SCHOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23550",
            "district": "23",
            "Block": "94",
            "label": "OMGS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23551",
            "district": "23",
            "Block": "96",
            "label": "MOUNT ZION SILVER JUBILEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23552",
            "district": "23",
            "Block": "106",
            "label": "INTEL VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23553",
            "district": "23",
            "Block": "294",
            "label": "SIVAGANGAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23554",
            "district": "23",
            "Block": "294",
            "label": "PANNAI MARIVENI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23555",
            "district": "23",
            "Block": "331",
            "label": "BSR GREEN PARK INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23556",
            "district": "23",
            "Block": "333",
            "label": "VIRUDHUNAGAR HINDU NADAR THOLANDI MANICKAM NADAR JANAKI AMMAL GIRLS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23802",
            "district": "23",
            "Block": "94",
            "label": "PUMS, KEELAKOTTAI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23867",
            "district": "23",
            "Block": "166",
            "label": "PUMS THUTHIKULAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23868",
            "district": "23",
            "Block": "166",
            "label": "PUMS VELUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23869",
            "district": "23",
            "Block": "166",
            "label": "PUMS VILATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23870",
            "district": "23",
            "Block": "166",
            "label": "BABA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23939",
            "district": "23",
            "Block": "271",
            "label": "PUMS GANAPATHIPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23965",
            "district": "23",
            "Block": "292",
            "label": "GANDHI MATRICULATION SHOOL ERIYUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23967",
            "district": "23",
            "Block": "294",
            "label": "PUMS MUTHUPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23968",
            "district": "23",
            "Block": "294",
            "label": "PUMS MATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23969",
            "district": "23",
            "Block": "294",
            "label": "PUMS ANNANAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23970",
            "district": "23",
            "Block": "294",
            "label": "PUMS PILLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23971",
            "district": "23",
            "Block": "294",
            "label": "SIVAGANGAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23972",
            "district": "23",
            "Block": "294",
            "label": "SCHOOL OF SCHOLARS",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24015",
            "district": "23",
            "Block": "331",
            "label": "PUMS NEDUMARAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24016",
            "district": "23",
            "Block": "331",
            "label": "PUMS M.PUDUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "24": [
        {
            "key": "157",
            "district": "24",
            "Block": "180",
            "label": "AMALA ANNAI HIGH SCHOOL, RENGASAMDURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        { 
            "key": "24120", 
            "district": "24", 
            "Block": "307", 
            "label": "Sri Parasakthi Vidyalaya - CBSE School Tenkasi", 
            "Sch_type": "Un-aided", 
            "Category": "Higher Secondary School"},
        {
            "key": "158",
            "district": "24",
            "Block": "150",
            "label": "A P A C M S PULIYANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "159",
            "district": "24",
            "Block": "150",
            "label": "DR VGN MATRIC HSS THIRUVENKADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "160",
            "district": "24",
            "Block": "150",
            "label": "GNANASUNDARIAMMAL MS SWAMINATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "161",
            "district": "24",
            "Block": "150",
            "label": "GOVT HIGH SCHOOL ALAMANAICKERPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "162",
            "district": "24",
            "Block": "150",
            "label": "GOVERNMENT HIGH SCHOOL, SEVALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "163",
            "district": "24",
            "Block": "150",
            "label": "GOVT HR SEC SCHOOL KALINGAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "164",
            "district": "24",
            "Block": "150",
            "label": "GOPAL NAICKER GOVT HR SEC SCHOOL KURUVIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "165",
            "district": "24",
            "Block": "150",
            "label": "GOVT HR SEC SCHOOL THIRUVENGADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "166",
            "district": "24",
            "Block": "150",
            "label": "GOVT HSS SAYAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "167",
            "district": "24",
            "Block": "150",
            "label": "HINDU MS AYYANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "168",
            "district": "24",
            "Block": "150",
            "label": "HINDU MIDDLE SCHOOL NARANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "169",
            "district": "24",
            "Block": "150",
            "label": "HINDU MIDDLE SCHOOL PARAIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "170",
            "district": "24",
            "Block": "150",
            "label": "HINDU MS VADAKKUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "171",
            "district": "24",
            "Block": "150",
            "label": "KALAIVANI MATRIC HIGHER SECONDARY SCHOOL  THIRUVENKADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "172",
            "district": "24",
            "Block": "150",
            "label": "MAIPARAI HIGH SCHOOL MAIPARAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "23361",
            "district": "24",
            "Block": "288",
            "label": "ANNAI THERASA METRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "173",
            "district": "24",
            "Block": "150",
            "label": "MPM SWAMY MS ALAGANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "174",
            "district": "24",
            "Block": "150",
            "label": "P U MS ARAICHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "175",
            "district": "24",
            "Block": "150",
            "label": "PUMS  AYVAIPULIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "176",
            "district": "24",
            "Block": "150",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KULASEKARAPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "177",
            "district": "24",
            "Block": "150",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KURUNJAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "178",
            "district": "24",
            "Block": "150",
            "label": "PUMS MELA NALANDULA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "179",
            "district": "24",
            "Block": "150",
            "label": "PUMS PALANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "180",
            "district": "24",
            "Block": "150",
            "label": "PUMS UMAIYATHALAIVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "181",
            "district": "24",
            "Block": "150",
            "label": "P U MS VARAGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "182",
            "district": "24",
            "Block": "150",
            "label": "RC MS CHIDAMBARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "183",
            "district": "24",
            "Block": "150",
            "label": "R C M S IILAYARASANANDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "184",
            "district": "24",
            "Block": "150",
            "label": "THIRU SHANMUGIAH MS AVANIKONENTHAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23400",
            "district": "24",
            "Block": "91",
            "label": "RVS Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "185",
            "district": "24",
            "Block": "150",
            "label": "SRI SIVARAMASUBBUSWAMY MIDDLE SCHOOL VELLAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "186",
            "district": "24",
            "Block": "150",
            "label": "ST.PAUL'S MATRICULATION SCHOOL,SEVALKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "187",
            "district": "24",
            "Block": "150",
            "label": "TDTA MS SAYAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "188",
            "district": "24",
            "Block": "150",
            "label": "TDTA V.M.MIDDLE SCHOOL, VAGAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "189",
            "district": "24",
            "Block": "180",
            "label": "VEERAPPA HINDU MIDDLE SCHOOL,MALAYANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "190",
            "district": "24",
            "Block": "276",
            "label": "36 GST HSS, SANKARANKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "191",
            "district": "24",
            "Block": "276",
            "label": "AMC Govt  Hr. Sec. School, Karivalamvanthanallur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "192",
            "district": "24",
            "Block": "276",
            "label": "ANGEL HS, SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "193",
            "district": "24",
            "Block": "276",
            "label": "ANGEL MATRICULATION SCHOOL SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "194",
            "district": "24",
            "Block": "276",
            "label": "ANNAMALAI MS, SENNIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "195",
            "district": "24",
            "Block": "276",
            "label": "AV HSS , SANKARANKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "196",
            "district": "24",
            "Block": "276",
            "label": "AVK INTERNATIONAL RESIDENTIAL SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "197",
            "district": "24",
            "Block": "276",
            "label": "AVK MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "198",
            "district": "24",
            "Block": "276",
            "label": "GOMATHI AMBAL GHSS( BOYS), SANKARANKOVIL(MODEL)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "199",
            "district": "24",
            "Block": "276",
            "label": "GHSS VEERASIGAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "200",
            "district": "24",
            "Block": "276",
            "label": "Sri Gomathi Ambal Matric Hr. Sec. School, Sankarankovil",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "201",
            "district": "24",
            "Block": "276",
            "label": "GOVT GIRLS HSS, SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "202",
            "district": "24",
            "Block": "276",
            "label": "HARIJAN MS, NOCHIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "203",
            "district": "24",
            "Block": "276",
            "label": "HINDU MS ACHAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "204",
            "district": "24",
            "Block": "276",
            "label": "HINDU MS ,  PANNAIYOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "205",
            "district": "24",
            "Block": "276",
            "label": "HNUC HSS, ARIYANAYAGIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "206",
            "district": "24",
            "Block": "276",
            "label": "IMAM GAZZALI (RAH) ORIENTAL MATRICULATION SCHOOL SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "207",
            "district": "24",
            "Block": "276",
            "label": "JOTHI HR.SEC.SCHOOL, SOLAICHERI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "208",
            "district": "24",
            "Block": "276",
            "label": "MANIMUTHU MS , RAMALINGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "209",
            "district": "24",
            "Block": "276",
            "label": "MMS EAST SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "210",
            "district": "24",
            "Block": "276",
            "label": "MMS , GANDHI NAGAR , SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "211",
            "district": "24",
            "Block": "276",
            "label": "MMS KALAIGNAR SALI SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "212",
            "district": "24",
            "Block": "276",
            "label": "MMS KRISHNASAMI NAGAR SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "213",
            "district": "24",
            "Block": "276",
            "label": "MMS WEST,SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "214",
            "district": "24",
            "Block": "276",
            "label": "PUMS, IRUMANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "215",
            "district": "24",
            "Block": "276",
            "label": "PUMS , MANALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "216",
            "district": "24",
            "Block": "276",
            "label": "PUMS PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "217",
            "district": "24",
            "Block": "276",
            "label": "PUMS, SENTHATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "218",
            "district": "24",
            "Block": "276",
            "label": "PUMS, THIRUVETTANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "219",
            "district": "24",
            "Block": "276",
            "label": "PUMS VADANATHAM PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "220",
            "district": "24",
            "Block": "276",
            "label": "PUMS, VEERIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "221",
            "district": "24",
            "Block": "276",
            "label": "PUMS, VEPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "222",
            "district": "24",
            "Block": "276",
            "label": "RC MS, SANKARANKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "223",
            "district": "24",
            "Block": "276",
            "label": "SENGUNTHAR MIDDLE SCHOOL, SANKARANKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "224",
            "district": "24",
            "Block": "276",
            "label": "SENGUNTHAR HS, SUBBULAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "225",
            "district": "24",
            "Block": "276",
            "label": "SEVENTHDAY MATRIC HSS, SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "226",
            "district": "24",
            "Block": "276",
            "label": "S.N.R. HR.SEC.SCHOOL SRIGOMATHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "227",
            "district": "24",
            "Block": "276",
            "label": "SRI MAHALAKSHMI MATRIC. HR. SEC. SCHOOL, SANKARANKOVIL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "228",
            "district": "24",
            "Block": "276",
            "label": "SRI MATHA MATRIC SCHOOL, KARIVALAMVANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "229",
            "district": "24",
            "Block": "276",
            "label": "ST. JOSEPH MATRIC HSS, SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "230",
            "district": "24",
            "Block": "276",
            "label": "TDTA MS ARUNACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "231",
            "district": "24",
            "Block": "276",
            "label": "TDTA MS, MANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "232",
            "district": "24",
            "Block": "276",
            "label": "TDTA MS , PURAKUDAIYANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "233",
            "district": "24",
            "Block": "276",
            "label": "TDTA MS, SUBBULAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "234",
            "district": "24",
            "Block": "276",
            "label": "SRI VAIYAPURI VIDYALAYA MATRIC HR SEC SCHOOL Sankarankovil",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "235",
            "district": "24",
            "Block": "276",
            "label": "VIVEKANANDA SILVER JUBILEE MATRIC HR.SEC.SCHOOL, VEERASIGAMANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "236",
            "district": "24",
            "Block": "276",
            "label": "VOC MS, KUVALAIKANNI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "237",
            "district": "24",
            "Block": "276",
            "label": "VVS HS, SANKARANKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "239",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGH SCHOOL, PERIYA KOVILANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "240",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NADUVAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "241",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SENDAMARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "242",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THIRUMALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "244",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VELLALANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "247",
            "district": "24",
            "Block": "180",
            "label": "HINDU HARIJAN MIDDLE SCHOOL, KURUKKALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "248",
            "district": "24",
            "Block": "180",
            "label": "HINDU NADAR MIDDLE SCHOOL, KADAYALURUTTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "250",
            "district": "24",
            "Block": "180",
            "label": "MADATHIAMMAL MIDDLE SCHOOL, OOTHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "251",
            "district": "24",
            "Block": "180",
            "label": "PRAKASH MATRICULATION SCHOOL, PANAVADALICHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "252",
            "district": "24",
            "Block": "180",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINNA KOVILANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "253",
            "district": "24",
            "Block": "180",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KO.MARUTHAPPAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "254",
            "district": "24",
            "Block": "180",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KULASEKARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "256",
            "district": "24",
            "Block": "180",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SHUNMUGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "257",
            "district": "24",
            "Block": "180",
            "label": "RAMAR MIDDLE SCHOOL, VELLAPPANADAROOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "258",
            "district": "24",
            "Block": "180",
            "label": "RC MIDDLE SCHOOL, KEELANEELITHANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "260",
            "district": "24",
            "Block": "180",
            "label": "SHRI BHARATH KANNA MATRICULATION SCHOOL. PERIASAMIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "261",
            "district": "24",
            "Block": "180",
            "label": "ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL, SENDAMARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "262",
            "district": "24",
            "Block": "180",
            "label": "SUCCESS MATRICULATION HIGHER SECONDARY SCHOOL, THIRUMALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "701",
            "district": "24",
            "Block": "307",
            "label": "HILTON MATRIC HR SEC SCHOOL, AYIRAPERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "730",
            "district": "24",
            "Block": "6",
            "label": "SRI RAMAKRISHNA MATRIC. HR. SEC. SCHOOL - ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "797",
            "district": "24",
            "Block": "388",
            "label": "S.M.M MS, SUBRAMANIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "989",
            "district": "24",
            "Block": "388",
            "label": "SDA MATRIC HR SEC SCHOOL,T.N.PUTHUKUDI,PULIANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1018",
            "district": "24",
            "Block": "307",
            "label": "BHARATH MONTESSORI MATRIC HR SEC SCHOOL, ILANJI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1068",
            "district": "24",
            "Block": "388",
            "label": "TDTA MS, T.N. PUTHUGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1075",
            "district": "24",
            "Block": "89",
            "label": "GHS, POTTALPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "1079",
            "district": "24",
            "Block": "89",
            "label": "Adarsh Vidyalaya Matric. Hr, Sec. School, Vasagiri nagar, Kadayam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1112",
            "district": "24",
            "Block": "388",
            "label": "PARAMANANTHA MS, PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "1200",
            "district": "24",
            "Block": "6",
            "label": "St. Joseph Mat. HSS, Alangulam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1285",
            "district": "24",
            "Block": "307",
            "label": "JOY MATRIC. HR. SEC, SCHOOL , VADAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1350",
            "district": "24",
            "Block": "91",
            "label": "MASOOD THAIKA MIDDLE SCHOOL KADAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1411",
            "district": "24",
            "Block": "119",
            "label": "RAJA MS-KALUNEERKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1464",
            "district": "24",
            "Block": "119",
            "label": "ST ASSISI MATRIC HR.SEC.SCH. PAVOORCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1605",
            "district": "24",
            "Block": "89",
            "label": "PUMS CHOCKKANATHANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1654",
            "district": "24",
            "Block": "119",
            "label": "JAWAHARLAL MS SURANDAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1679",
            "district": "24",
            "Block": "388",
            "label": "R.M.M.S VASUDEVANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "1696",
            "district": "24",
            "Block": "388",
            "label": "SWAMI RENUGAL MS, VISWANATHAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1704",
            "district": "24",
            "Block": "119",
            "label": "MARAVA MS-KALUNEERKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1798",
            "district": "24",
            "Block": "91",
            "label": "KING UNIVERSE MATRIC HIGHER SECONDARY SCHOOL, KUMANTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1857",
            "district": "24",
            "Block": "119",
            "label": "RC MS SURANDAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1925",
            "district": "24",
            "Block": "119",
            "label": "SRI JEYANDRA MATRIC HIGHER SECONDARY SCHOOL,  SURANDAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1939",
            "district": "24",
            "Block": "288",
            "label": "SHRIRAM VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL ELATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1957",
            "district": "24",
            "Block": "119",
            "label": "PUMS CHELLATHAYARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1963",
            "district": "24",
            "Block": "119",
            "label": "PUMS THIRAVIANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1999",
            "district": "24",
            "Block": "307",
            "label": "ST.JOHNS MATRIC HR SEC SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2045",
            "district": "24",
            "Block": "307",
            "label": "ST.JOSEPH MAT.HSS, PANPOLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2061",
            "district": "24",
            "Block": "89",
            "label": "SRI RAMANARAI NADAR MS, PUNGAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2063",
            "district": "24",
            "Block": "388",
            "label": "VIVEKA MAT.HSS, SIVAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2070",
            "district": "24",
            "Block": "307",
            "label": "AG MATRIC HSS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2086",
            "district": "24",
            "Block": "388",
            "label": "NEW CRESCENT MATRIC HSS, PULIANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2111",
            "district": "24",
            "Block": "307",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2195",
            "district": "24",
            "Block": "119",
            "label": "SHALOM MATRICULATION HIGHER SECONDARY SCHOOL, ,PAVOORCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2244",
            "district": "24",
            "Block": "119",
            "label": "A G MAT S SURANDAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2310",
            "district": "24",
            "Block": "307",
            "label": "PUMS, KARISALKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2350",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS KURUMBALAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2402",
            "district": "24",
            "Block": "91",
            "label": "SRI SANKARA MIDDLE SCHOOL ,KRISHNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2407",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL,THERKUMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2439",
            "district": "24",
            "Block": "119",
            "label": "PUMS -ARIYAPPAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2473",
            "district": "24",
            "Block": "89",
            "label": "VEERA ULGAMMAL MS, CHEKKADIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2480",
            "district": "24",
            "Block": "6",
            "label": "PUMS, Ayyanarkulam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2481",
            "district": "24",
            "Block": "388",
            "label": "RAMALINGAM MS, PULIANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2495",
            "district": "24",
            "Block": "6",
            "label": "R.C. MS, Uthumalai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2527",
            "district": "24",
            "Block": "6",
            "label": "JEEVA MONTESSORI MATRIC HR. SEC. SCHOOL,ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2560",
            "district": "24",
            "Block": "6",
            "label": "Harijan MS, Keelakalangal",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2597",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL KAMBILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2696",
            "district": "24",
            "Block": "119",
            "label": "RATNA MS-E.MEENACHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2805",
            "district": "24",
            "Block": "119",
            "label": "SRI MUPPUDATHI AMMAN MAT HR.SEC.SC. ADAIKALAPATANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2878",
            "district": "24",
            "Block": "388",
            "label": "S.R.P MS, SIVAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2913",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS, Alangulam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2916",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS-CHINNANADANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2956",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL,KARKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2973",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS PUTHUSURANDAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3005",
            "district": "24",
            "Block": "91",
            "label": "SRI KAILASA MS IDAIKAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3112",
            "district": "24",
            "Block": "307",
            "label": "PARASAKTHI M HSS,COURTALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3121",
            "district": "24",
            "Block": "91",
            "label": "KRISHNA MID.SCHOOL,KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3151",
            "district": "24",
            "Block": "119",
            "label": "PUMS, VELLAKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3380",
            "district": "24",
            "Block": "288",
            "label": "VANCHI GOVERNMENT MIDDLE SCHOOL SHENGOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3426",
            "district": "24",
            "Block": "6",
            "label": "RC MS, Thuthikulam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3453",
            "district": "24",
            "Block": "288",
            "label": "AG Matric Higher Secondary School, Shengottai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3500",
            "district": "24",
            "Block": "307",
            "label": "SUBASH MATRIC HSS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3508",
            "district": "24",
            "Block": "307",
            "label": "VIVEKANANDA MIDDLE SCHOOL MATHALAMPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3584",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS SIVAGAMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3587",
            "district": "24",
            "Block": "119",
            "label": "K.M.H.MS VINAITHEERTHANADARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3594",
            "district": "24",
            "Block": "91",
            "label": "PUMS,PUNNAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3647",
            "district": "24",
            "Block": "91",
            "label": "SRI SIVA SAILA NATHA MIDDLE SCHOOL NEDUVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3691",
            "district": "24",
            "Block": "6",
            "label": "R.C. MS, Mariathaipuram",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3704",
            "district": "24",
            "Block": "307",
            "label": "HINDU MIDDLE SCHOOL KASIMAJORPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3717",
            "district": "24",
            "Block": "388",
            "label": "PUJMS, VASUDEVANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3722",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL MEKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3728",
            "district": "24",
            "Block": "307",
            "label": "PUMS THIRUCHITRAMBALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3736",
            "district": "24",
            "Block": "91",
            "label": "PUMS,BALA ARUNACHALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3757",
            "district": "24",
            "Block": "388",
            "label": "GURUSAMY MS, RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3771",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS, Solaiseri",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3887",
            "district": "24",
            "Block": "307",
            "label": "OXFORD MATRIC. HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3924",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS-RAJAGOBALAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3960",
            "district": "24",
            "Block": "89",
            "label": "SRI ATHRIKALA NIALAYA MS  SIVASAILAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3990",
            "district": "24",
            "Block": "119",
            "label": "PUMS-THUVARANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4086",
            "district": "24",
            "Block": "307",
            "label": "TDTA MS, KAKAIYANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4150",
            "district": "24",
            "Block": "388",
            "label": "SENAITHALAIVAR MS, VASUDEVANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4154",
            "district": "24",
            "Block": "307",
            "label": "M.M. MS VISWANTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4163",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS SUBBANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4170",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS, Parankuntrapuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4171",
            "district": "24",
            "Block": "6",
            "label": "NMM MS, Kidarakulam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4190",
            "district": "24",
            "Block": "91",
            "label": "P.U.M.S,DURAISAMYPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4235",
            "district": "24",
            "Block": "307",
            "label": "MKVK MATRIC HSS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4341",
            "district": "24",
            "Block": "307",
            "label": "TDTA PRIMARY SCHOOL VALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4369",
            "district": "24",
            "Block": "288",
            "label": "SPECTRUM MATRIC HIGHER SECONDARY SCHOOL KATHIRKANTHAM E-VILAKKU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4406",
            "district": "24",
            "Block": "6",
            "label": "Harijan MS, Kankanankinaru",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4432",
            "district": "24",
            "Block": "91",
            "label": "MELAPPALAYAM HINDU MIDDLE SCHOOL-MELAKADAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4458",
            "district": "24",
            "Block": "89",
            "label": "SRI SAILAPATHY MS, ALWARKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4476",
            "district": "24",
            "Block": "388",
            "label": "MPL MS, THAIKKA ST, PULIANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4523",
            "district": "24",
            "Block": "6",
            "label": "Sri Shanmuga MS, Melamaruthappapuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4551",
            "district": "24",
            "Block": "91",
            "label": "PUMS,SUNDARESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4561",
            "district": "24",
            "Block": "388",
            "label": "PACKIATHAI MS, CHINTHAMANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4616",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL,KACHERI COMPOUND,SHENGOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4719",
            "district": "24",
            "Block": "91",
            "label": "USP MATRIC  HR SEC SCHOOL,KODIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4721",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS  ALWANTHULUKKAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4792",
            "district": "24",
            "Block": "307",
            "label": "MPL,13th WARD MS,TENKASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4921",
            "district": "24",
            "Block": "6",
            "label": "PUMS, Annamalaiputhur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4940",
            "district": "24",
            "Block": "6",
            "label": "PUMS, Muthammalpuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5033",
            "district": "24",
            "Block": "388",
            "label": "PUJMS, NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5090",
            "district": "24",
            "Block": "307",
            "label": "KATTU BAVA MIDDLE SCHOOL , TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5100",
            "district": "24",
            "Block": "307",
            "label": "MUSTAFIAH MIDDLE SCHOOL TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5153",
            "district": "24",
            "Block": "307",
            "label": "CMS MACWHIRTER MIDDLE SCHOOL TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5205",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL ELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5222",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS  MELAPATTAMUDAIARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5321",
            "district": "24",
            "Block": "6",
            "label": "Sri Mahalingam MS, Marukalankulam",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5405",
            "district": "24",
            "Block": "388",
            "label": "RAJASINGH MS, THERKKU CHATRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5501",
            "district": "24",
            "Block": "6",
            "label": "CMS Arunothaya MS, Nallur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5544",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS MELAMEIGNANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5555",
            "district": "24",
            "Block": "307",
            "label": "MPL 9th WARD MS.TENKSI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5566",
            "district": "24",
            "Block": "89",
            "label": "SRI MEENAKSHI MS KILA AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5636",
            "district": "24",
            "Block": "119",
            "label": "TDTA St.Paul's MS ADAIKALAPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5761",
            "district": "24",
            "Block": "91",
            "label": "MUNICIPAL MS PETTAI ,KADAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5805",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL POOLANKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5816",
            "district": "24",
            "Block": "89",
            "label": "PUMS RAVANASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5842",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS CHENNALTHAPUTHUKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5872",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS-SIVANADANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5968",
            "district": "24",
            "Block": "89",
            "label": "GNANAM MARAVA MS, GOVINDAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5979",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS-PETHANADARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6064",
            "district": "24",
            "Block": "388",
            "label": "SM MS, THALAIVANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6096",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS, Karuvantha",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6163",
            "district": "24",
            "Block": "6",
            "label": "HINDU THIRU MURUGAN MS PUTHUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6232",
            "district": "24",
            "Block": "89",
            "label": "PUMS, LAKSHMIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6375",
            "district": "24",
            "Block": "388",
            "label": "ANDIYAPPA MS, PULIANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6417",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PULIYARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6496",
            "district": "24",
            "Block": "91",
            "label": "WISDOM MATRIC HIGHER SECONDARY SCHOOL,KADAYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6516",
            "district": "24",
            "Block": "388",
            "label": "T.S.M MS, PULIANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6537",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS ODAIMARICHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6575",
            "district": "24",
            "Block": "91",
            "label": "PUDUR NEW  MIDDLE SCHOOL. .KADAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6660",
            "district": "24",
            "Block": "89",
            "label": "TDTA MS, PULAVANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6672",
            "district": "24",
            "Block": "119",
            "label": "THIRU SUNDARAVEL Middle School-SOUTH POOLANGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6677",
            "district": "24",
            "Block": "6",
            "label": "A.V.NAYAGAM MIDDLE SCHOOL, UTHUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6728",
            "district": "24",
            "Block": "89",
            "label": "RENGANATHA MS , PAPPANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19598",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Alangulam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19599",
            "district": "24",
            "Block": "6",
            "label": "HINDU HIGHER SECONDARY SCHOOL, MAYAMANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19600",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Nettur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19601",
            "district": "24",
            "Block": "6",
            "label": "SRI VAIDHYALINGA SWAMY HIGH SCHOOL - ALADIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19602",
            "district": "24",
            "Block": "6",
            "label": "West Tirunelveli HSS, Nallur",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19603",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Maranthai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19604",
            "district": "24",
            "Block": "6",
            "label": "St. ASSISI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19605",
            "district": "24",
            "Block": "6",
            "label": "Muslim HS, Veeranam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19606",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Veeranam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19607",
            "district": "24",
            "Block": "6",
            "label": "GRAMA COMMITTEE HSS, Venkateswarapuram",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19608",
            "district": "24",
            "Block": "6",
            "label": "CET Tennyson HS, Keelakalangal",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19609",
            "district": "24",
            "Block": "6",
            "label": "MCM HR SEC SCHOOL ANNAMALAIPUDUR, TIRUNELVELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19610",
            "district": "24",
            "Block": "6",
            "label": "ST. JOHN'S HSS - VADIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19611",
            "district": "24",
            "Block": "6",
            "label": "V.N.T MIDDLE SCHOOL,KARUVANTHA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19612",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Uthumalai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19660",
            "district": "24",
            "Block": "89",
            "label": "ST JOSEPHS HS VEIKKALIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19661",
            "district": "24",
            "Block": "89",
            "label": "GHS, KAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19662",
            "district": "24",
            "Block": "89",
            "label": "CHATRAM BHARATHI GIRLS HS, THERKKU KADAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19663",
            "district": "24",
            "Block": "89",
            "label": "SRI KAILASAM MEMORIAL HIGH SCHOOL THIRUMALAIAPPAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19664",
            "district": "24",
            "Block": "89",
            "label": "CHATRAM BHARATHI HR SEC SCHOOL KADAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19665",
            "district": "24",
            "Block": "89",
            "label": "GHSS, MUDALIYARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19666",
            "district": "24",
            "Block": "89",
            "label": "GANDHIGRAM SANTHI HIGHER SECONDARY SCHOOL FOR THE HEARING IMPAIRED, SIVASAILAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19667",
            "district": "24",
            "Block": "89",
            "label": "SRI PARAMAKALYANI HSS, ALWARKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19668",
            "district": "24",
            "Block": "89",
            "label": "ST ANTONY'S HSS, KALYANIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19669",
            "district": "24",
            "Block": "89",
            "label": "PUMS, PAPPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19670",
            "district": "24",
            "Block": "89",
            "label": "GANAPATHY MS, PAPPANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19671",
            "district": "24",
            "Block": "89",
            "label": "GHS, MYLAPPAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19672",
            "district": "24",
            "Block": "89",
            "label": "KSHATHRIYAR COM.MS VENGADAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19673",
            "district": "24",
            "Block": "89",
            "label": "SSV HSS MATHAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19674",
            "district": "24",
            "Block": "89",
            "label": "THIRU MURUGAN HS VALLIAMMALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19675",
            "district": "24",
            "Block": "89",
            "label": "TDTA MS INTHANKATTALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19676",
            "district": "24",
            "Block": "91",
            "label": "M.M.HSS,IDAIKAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19677",
            "district": "24",
            "Block": "91",
            "label": "GHSS,KASIDARMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19678",
            "district": "24",
            "Block": "91",
            "label": "T.M.MS NEDUVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19679",
            "district": "24",
            "Block": "91",
            "label": "GOVT HR  SEC SCHOOL NEDUVAYAL ACHANPUDUR TENKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19680",
            "district": "24",
            "Block": "91",
            "label": "HINDUMARAVA MS KODIKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19681",
            "district": "24",
            "Block": "91",
            "label": "HINDU MS SIVARAMA PETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19682",
            "district": "24",
            "Block": "91",
            "label": "SRI ULAGA HSS M.KRISHNAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19683",
            "district": "24",
            "Block": "91",
            "label": "GHSS(G)KADAYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19684",
            "district": "24",
            "Block": "91",
            "label": "GHSS(B) KADAYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19685",
            "district": "24",
            "Block": "91",
            "label": "DARUSSALAM HSS KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19686",
            "district": "24",
            "Block": "91",
            "label": "HIDHAYATHUL ISLAM HIGHER SECONDARY SCHOOL, KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19687",
            "district": "24",
            "Block": "91",
            "label": "PETTAIMUSLIM HIGHER SECONDARY SCHOOL KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19688",
            "district": "24",
            "Block": "91",
            "label": "RATNA HIGH SCHOOL,KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19689",
            "district": "24",
            "Block": "91",
            "label": "MASOOD THAIKA HIGHER SECONDARY SCHOOL  KADAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19690",
            "district": "24",
            "Block": "91",
            "label": "HINDU MS,THIRIKUDAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19691",
            "district": "24",
            "Block": "91",
            "label": "LIONS MAHATMA MAT SC ,MS PURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19692",
            "district": "24",
            "Block": "91",
            "label": "EVEREST MATRIC HIGHER SECONDARY SCHOOL ACHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19693",
            "district": "24",
            "Block": "91",
            "label": "GHS,IDAIKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19694",
            "district": "24",
            "Block": "91",
            "label": "RUKMANI HS,MANGALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19695",
            "district": "24",
            "Block": "91",
            "label": "KING OF KINGS MAT.SCH,MANGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19696",
            "district": "24",
            "Block": "91",
            "label": "CRESCENT MATRICULATION HIGHER SECONDARY SCHOOL,ACHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19697",
            "district": "24",
            "Block": "91",
            "label": "SATHANA VIDYALAYA MATRICULATION SCHOOL, BALAARUNACHALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19698",
            "district": "24",
            "Block": "91",
            "label": "GOVT HS(ADW),VALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19699",
            "district": "24",
            "Block": "91",
            "label": "PUMS,CHIDAMBARAPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19700",
            "district": "24",
            "Block": "91",
            "label": "GHSS,ANAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19701",
            "district": "24",
            "Block": "91",
            "label": "GHS,KULAYANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19702",
            "district": "24",
            "Block": "91",
            "label": "GHS,POIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19703",
            "district": "24",
            "Block": "91",
            "label": "GOVT.HSS,URMELAZHAGIAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19704",
            "district": "24",
            "Block": "91",
            "label": "GOVT HIGH SCHOOL,VELAYUTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19717",
            "district": "24",
            "Block": "119",
            "label": "GHSS -PULLUKATTUVALASAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19718",
            "district": "24",
            "Block": "119",
            "label": "GOVT HR SEC SCHOOL -VINAITHEERTHANADARPATTI - 627808",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19719",
            "district": "24",
            "Block": "119",
            "label": "PUNITHA ARULAPPAR HSS-AVUDAIYANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19720",
            "district": "24",
            "Block": "119",
            "label": "PAPN GHSS-AVUDAIYANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19721",
            "district": "24",
            "Block": "119",
            "label": "SIVA SAKTHI VIDYALAYA PODIANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19722",
            "district": "24",
            "Block": "119",
            "label": "GHSS AYANKURUMBALAPERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19723",
            "district": "24",
            "Block": "119",
            "label": "GHSS SIVAGURUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19724",
            "district": "24",
            "Block": "119",
            "label": "BAREN BRUCK HSS BUNGALOW SURANDAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19725",
            "district": "24",
            "Block": "119",
            "label": "SERMATHAIVASAN HSS SURANDAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19726",
            "district": "24",
            "Block": "119",
            "label": "GHSS MELAPAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19727",
            "district": "24",
            "Block": "119",
            "label": "AV HS KEELAPAVOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19728",
            "district": "24",
            "Block": "119",
            "label": "NADAR HINDU HS KEELAPAVOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19729",
            "district": "24",
            "Block": "119",
            "label": "GHS AYOTHIYAPURIPATTANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19730",
            "district": "24",
            "Block": "119",
            "label": "GHSS POOLANGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19731",
            "district": "24",
            "Block": "119",
            "label": "QUEENS N&PS POOLANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19732",
            "district": "24",
            "Block": "119",
            "label": "PUMS,GURUSAMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19733",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS-MADATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19734",
            "district": "24",
            "Block": "119",
            "label": "TPS(B)GHSS PAVOORCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19735",
            "district": "24",
            "Block": "119",
            "label": "AVVAIYAR G.GHSS-PAVOORCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19736",
            "district": "24",
            "Block": "119",
            "label": "ANNAI VELANKANNI MATRIC HR.SEC.SCHOOL PAVOORCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19737",
            "district": "24",
            "Block": "119",
            "label": "MALAVIYA VIDYA KENDRAM PAVOORCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19738",
            "district": "24",
            "Block": "119",
            "label": "GHSS,VEERAKERALAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19739",
            "district": "24",
            "Block": "119",
            "label": "ANNA HSS-VEERAKERALAMPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19740",
            "district": "24",
            "Block": "119",
            "label": "ST.ANTONY HSS-VEERAKERALAMPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19741",
            "district": "24",
            "Block": "119",
            "label": "GOVT HIGH SCHOOL BOOBALASAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19833",
            "district": "24",
            "Block": "6",
            "label": "RC MS KUTHAPANCHAN",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19834",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS PUTHUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19835",
            "district": "24",
            "Block": "6",
            "label": "GHSS,MARUTHAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19836",
            "district": "24",
            "Block": "6",
            "label": "HINDU MS UDAIYAMPULI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19902",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGH SCHOOL,MELASHENGOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19903",
            "district": "24",
            "Block": "288",
            "label": "Sri Moolam Sastiyaptha Poorthi Sattanatha Karayalar Government Boys Higher Secondary School, Shencottai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19904",
            "district": "24",
            "Block": "288",
            "label": "SRI RAMA MANDIRAM GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, Shengottai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19905",
            "district": "24",
            "Block": "288",
            "label": "Sri Jayendra Vidyalaya Matric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19906",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGH SCHOOL KATTALAIKUDIYARUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19907",
            "district": "24",
            "Block": "288",
            "label": "PULARI MATRICULATION SCHOOL,  PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19908",
            "district": "24",
            "Block": "288",
            "label": "LAKSHMI HARIHARA HIGH SCHOOL ELATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19909",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SEEVANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19910",
            "district": "24",
            "Block": "288",
            "label": "PUNITHA ARULANANTHAR HIGHER SECONDARY SCHOOL AGARAKATTU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19911",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL AYIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19912",
            "district": "24",
            "Block": "288",
            "label": "SIVA SARASWATHI VIDYALAYA HIGHER SECONDARY SCHOOL AYIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19913",
            "district": "24",
            "Block": "288",
            "label": "HINDU NADAR URAVIN MURAI COMMITTEE DR.SIVANTHI ADITANAR MIDDLE SCHOOL SAMBAVAR VADAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19914",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SAMBAVAR VADAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19915",
            "district": "24",
            "Block": "307",
            "label": "GHSS VADAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19916",
            "district": "24",
            "Block": "307",
            "label": "MUSLIM HS VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19917",
            "district": "24",
            "Block": "307",
            "label": "HUSSAINIYA MUSLIM HIGH SCHOOL VADAKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19918",
            "district": "24",
            "Block": "307",
            "label": "RKV MS PANPOLI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19919",
            "district": "24",
            "Block": "307",
            "label": "GHSS, PANPOLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19920",
            "district": "24",
            "Block": "307",
            "label": "A.G.HS, KANAKAPILLAIVALASAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19921",
            "district": "24",
            "Block": "307",
            "label": "St. MARY'S HS KUTHUKALVALASAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19922",
            "district": "24",
            "Block": "307",
            "label": "GHS, VETHAMPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19923",
            "district": "24",
            "Block": "307",
            "label": "ARAMBAJOTHI MIDDLE SCHOOL ILANJI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19924",
            "district": "24",
            "Block": "307",
            "label": "RAMASWAMY PILLAI HR. SEC. SCHOOL., ILANJI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19925",
            "district": "24",
            "Block": "307",
            "label": "SYED RESI.HSS,ILANJI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19926",
            "district": "24",
            "Block": "307",
            "label": "P S T K D GIRLS HR SEC SCHOOL, COURTALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19927",
            "district": "24",
            "Block": "307",
            "label": "GHSS,MELAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19928",
            "district": "24",
            "Block": "307",
            "label": "EMMANUEL MATRIC HR SEC SCHOOL, MELAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19929",
            "district": "24",
            "Block": "307",
            "label": "MPL, 7th WARD MS,TENKASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19930",
            "district": "24",
            "Block": "307",
            "label": "ICI Govt Model HSS,TENKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19931",
            "district": "24",
            "Block": "307",
            "label": "MANJAMMAL HSS(G),TENKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19932",
            "district": "24",
            "Block": "307",
            "label": "PONNAMBALAM MIDDLE SCHOOL TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19933",
            "district": "24",
            "Block": "307",
            "label": "St.  MICHAEL'S HSS(G) TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19934",
            "district": "24",
            "Block": "307",
            "label": "NEHRU HSS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19935",
            "district": "24",
            "Block": "307",
            "label": "HAMEETHIYA MS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19936",
            "district": "24",
            "Block": "307",
            "label": "VEERAMAMUNIVAR RC HSS,TENKASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19937",
            "district": "24",
            "Block": "307",
            "label": "HINDU HSS,KEELAPULIUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19938",
            "district": "24",
            "Block": "307",
            "label": "HINDU HSS(G),KEELAPULIUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19939",
            "district": "24",
            "Block": "307",
            "label": "GHSS SUNDARAPANDIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19940",
            "district": "24",
            "Block": "307",
            "label": "SRI RAM MATRIC HR SEC SCHOOL, VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19941",
            "district": "24",
            "Block": "307",
            "label": "ANNAI THERESA R C HSS VALLAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19942",
            "district": "24",
            "Block": "307",
            "label": "NATIONAL PUBLIC SCHOOL, VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19987",
            "district": "24",
            "Block": "388",
            "label": "ARUNODHAYA MS, VISWANATHAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19988",
            "district": "24",
            "Block": "388",
            "label": "KAMMAWAR HINDU HIGH SCHOOL,VISWANATHAPERI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19989",
            "district": "24",
            "Block": "388",
            "label": "BALA VINAYAGAR HS, SIVAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19990",
            "district": "24",
            "Block": "388",
            "label": "STELLA MARYS HSS,SIVAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19991",
            "district": "24",
            "Block": "388",
            "label": "RC HS,DEVIPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19992",
            "district": "24",
            "Block": "388",
            "label": "PANCHAYAT UNION JAWAHAR MIDDLE SCHOOL , SIVAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19993",
            "district": "24",
            "Block": "388",
            "label": "COMMITTEE NADAR HS,SIVAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19994",
            "district": "24",
            "Block": "388",
            "label": "SIVAGIRI SENAITHALAIVAR HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19995",
            "district": "24",
            "Block": "388",
            "label": "GOVT MS, ARUGANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19996",
            "district": "24",
            "Block": "388",
            "label": "RC MS, DURAISAMIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19997",
            "district": "24",
            "Block": "388",
            "label": "GHSS,THENMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19998",
            "district": "24",
            "Block": "388",
            "label": "THIRU C P ATHITHANAR GIRLS HIGH SCHOOL RAYAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19999",
            "district": "24",
            "Block": "388",
            "label": "R.H.N.U.THIRU.SI.BA.SIVANTHI ATHITHANAR HSS RAYAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20000",
            "district": "24",
            "Block": "388",
            "label": "N.C HSS,RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20001",
            "district": "24",
            "Block": "388",
            "label": "MP GOVT HSS,NELKATTUM SEVAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20002",
            "district": "24",
            "Block": "388",
            "label": "DHARANI MATRICULATION HIGHER SECONDARY SCHOOL, DHARANINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20003",
            "district": "24",
            "Block": "388",
            "label": "NADAR URAVINMURAI KAMARAJAR GIRLS HIGH SCHOOL VASUDEVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20004",
            "district": "24",
            "Block": "388",
            "label": "MOHAIDEENIA HS,VASUDEVANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20005",
            "district": "24",
            "Block": "388",
            "label": "GHSS,VASUDEVANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20006",
            "district": "24",
            "Block": "388",
            "label": "PUMS, MALAIYADIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20007",
            "district": "24",
            "Block": "388",
            "label": "RC MS, CHINTHAMANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20008",
            "district": "24",
            "Block": "388",
            "label": "H.N.U.M HS,CINTHAMANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20009",
            "district": "24",
            "Block": "388",
            "label": "KANNA HSS(GIRLS),CINTHAMANI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20010",
            "district": "24",
            "Block": "388",
            "label": "SEENIAMMAL HS,CHINTHAMANI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20011",
            "district": "24",
            "Block": "388",
            "label": "SRI KANNA MATRIC .HSS, CHINTHAMANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20012",
            "district": "24",
            "Block": "388",
            "label": "MOHAIDEENANDAVAR HS,PULIANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20013",
            "district": "24",
            "Block": "388",
            "label": "AVS HS,PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20014",
            "district": "24",
            "Block": "388",
            "label": "GOVT GIRLS HR SEC SCHOOL PULIANGUDI TENKASI DISTRICT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20015",
            "district": "24",
            "Block": "388",
            "label": "PULIANGUDI SENAITHALAIVAR HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20016",
            "district": "24",
            "Block": "388",
            "label": "RC HS,PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20017",
            "district": "24",
            "Block": "388",
            "label": "GHSS(B),PULIANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20018",
            "district": "24",
            "Block": "388",
            "label": "QUAIDE MILLATH HSS PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20019",
            "district": "24",
            "Block": "388",
            "label": "H.N.U.C.HSS.T.N.PUTHUKUDI - PULIANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20020",
            "district": "24",
            "Block": "388",
            "label": "SUYAMBULINGAM HSS,PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20021",
            "district": "24",
            "Block": "388",
            "label": "PK GHSS,MULLIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20022",
            "district": "24",
            "Block": "388",
            "label": "ST.MARYS MAT. SCHOOL, MULLIKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20023",
            "district": "24",
            "Block": "388",
            "label": "CMML HS,VADAMALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20979",
            "district": "24",
            "Block": "307",
            "label": "BHARATH VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21119",
            "district": "24",
            "Block": "276",
            "label": "VELS PUBLIC SCHOOL VADAKKUPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21120",
            "district": "24",
            "Block": "307",
            "label": "ESAKKI VIDHYASHRAM CBSE SCHOOL TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21124",
            "district": "24",
            "Block": "388",
            "label": "KANNA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21290",
            "district": "24",
            "Block": "119",
            "label": "S.R MATRIC HIGHER SECONDARYSCHOOL SURANDAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21343",
            "district": "24",
            "Block": "388",
            "label": "DHARANI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21413",
            "district": "24",
            "Block": "6",
            "label": "GHS ,PALAPATHIRARAMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21417",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGH SCHOOL, PANDIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21418",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGH SCHOOL, VENDRILINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21526",
            "district": "24",
            "Block": "119",
            "label": "HINDU HS MUTHUKRISHNAPERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21543",
            "district": "24",
            "Block": "180",
            "label": "ST PETER HIGH SCHOOL SENDAMARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21618",
            "district": "24",
            "Block": "89",
            "label": "REDEEMER MATRICULATION HSS SCHOOL, CHETTIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21640",
            "district": "24",
            "Block": "6",
            "label": "SARUKA NURSARY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21825",
            "district": "24",
            "Block": "388",
            "label": "ANNAI MEENAKSHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21826",
            "district": "24",
            "Block": "388",
            "label": "SVC SAI NIKETAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21829",
            "district": "24",
            "Block": "307",
            "label": "St. JOSEPH PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21835",
            "district": "24",
            "Block": "119",
            "label": "HINDU HS VELLAIPANEIARYPATTY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21836",
            "district": "24",
            "Block": "91",
            "label": "NALLAMANI MATRIC SCHOOL KODIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21837",
            "district": "24",
            "Block": "91",
            "label": "AL-AZHAR MATRICULATION SCHOOL, MANGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21838",
            "district": "24",
            "Block": "89",
            "label": "GOOD SHEPHERD MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21839",
            "district": "24",
            "Block": "6",
            "label": "HOLY CROSS MATRICULATION  HSS SCHOOL MARANTHAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21840",
            "district": "24",
            "Block": "6",
            "label": "JESUS LOVES MATRICULATION HSS SCHOOL VENKATESHWARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21922",
            "district": "24",
            "Block": "6",
            "label": "ARCHVEL MATRICULATION SCHOOL ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22272",
            "district": "24",
            "Block": "6",
            "label": "Bharathi Vidhya Mandhir Matriculation Higher Secondary School, Venkateswarapuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22282",
            "district": "24",
            "Block": "307",
            "label": "TREASURE ILAND INTERNATIONAL SCHOOL MAVADIKKALTHOPPU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22642",
            "district": "24",
            "Block": "89",
            "label": "GHS NALANKATTALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22643",
            "district": "24",
            "Block": "91",
            "label": "BRILLIANT MATRICULATION  KADAYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22644",
            "district": "24",
            "Block": "91",
            "label": "BEST INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22645",
            "district": "24",
            "Block": "91",
            "label": "NEW CAMBRIDGE MATRICULATION HIGHER SECONDARY SCHOOL, MANGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22646",
            "district": "24",
            "Block": "150",
            "label": "GOVT HIGH SCHOOL  PERUNKOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22648",
            "district": "24",
            "Block": "180",
            "label": "ST. ROSSELLOS MATRIC SCHOOL, AYALPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22649",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGH SCHOOL, MELANEELITHANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22654",
            "district": "24",
            "Block": "276",
            "label": "GHS MADATHUPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22655",
            "district": "24",
            "Block": "276",
            "label": "SRI LAKSHMI MATRICULATION SCHOOL ARIYANAYAGIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22656",
            "district": "24",
            "Block": "307",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22659",
            "district": "24",
            "Block": "388",
            "label": "VEERAMARUDU SAHODARARHAL VIDYALAYA NELKATTUMSEVAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22660",
            "district": "24",
            "Block": "388",
            "label": "THANGAPALAM MATRICULATION HSS SUBRAMANIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22727",
            "district": "24",
            "Block": "119",
            "label": "SMA NATIONAL PUBLIC SCHOOL ADAAKKALAPATTANAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22729",
            "district": "24",
            "Block": "180",
            "label": "SRI SWARNA VIDYASHRAM - SHUNMUGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22731",
            "district": "24",
            "Block": "276",
            "label": "VIVEKANANDHA VIDHYALAYA, PANAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22732",
            "district": "24",
            "Block": "307",
            "label": "PIONEER INTERNATIONAL CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22733",
            "district": "24",
            "Block": "307",
            "label": "VEL'S VIDHYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22802",
            "district": "24",
            "Block": "388",
            "label": "TMS WELLDONE MATRICULATION SCHOOL PULIANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22844",
            "district": "24",
            "Block": "91",
            "label": "NALLAMANI VIDHYALAYA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22907",
            "district": "24",
            "Block": "6",
            "label": "GHS THALAISUBRAMANIAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22908",
            "district": "24",
            "Block": "388",
            "label": "GOVERNMENT HIGH SCHOOL, KOODALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23073",
            "district": "24",
            "Block": "276",
            "label": "VIVEKANANDA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23113",
            "district": "24",
            "Block": "119",
            "label": "SR SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23171",
            "district": "24",
            "Block": "119",
            "label": "SIVA SAKTHI VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23195",
            "district": "24",
            "Block": "119",
            "label": "WONDER SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23201",
            "district": "24",
            "Block": "89",
            "label": "GANDHIGRAM TRUST AVVAI ASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23219",
            "district": "24",
            "Block": "91",
            "label": "GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23305",
            "district": "24",
            "Block": "6",
            "label": "GHS KASINATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23327",
            "district": "24",
            "Block": "307",
            "label": "OXFORD PUBLIC SCHOOL, KUTHUKKALVALASAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23328",
            "district": "24",
            "Block": "307",
            "label": "GREEN PARK MATRICULATION SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23329",
            "district": "24",
            "Block": "6",
            "label": "JACKSON MATRICULATION SCHOOL, VENKATESWARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23557",
            "district": "24",
            "Block": "288",
            "label": "DMI ST.JOSEPH GLOBAL SCHOOL AGARAKATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23558",
            "district": "24",
            "Block": "307",
            "label": "IBNU UMAR ISLAMIC  MATRICULATION SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23962",
            "district": "24",
            "Block": "288",
            "label": "BRIGHTON MATRICULATION SCHOOL SAMBAVAR VADAKARAI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23991",
            "district": "24",
            "Block": "307",
            "label": "KATTU BAVA HIGH SCHOOL,TENKASI ",
            "Sch_type": "Partially AidedHigh School"
        },
        {
            "key": "23992",
            "district": "24",
            "Block": "307",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24062",
            "district": "24",
            "Block": "388",
            "label": "Tenkasi District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "25": [
        {
            "key": "430",
            "district": "25",
            "Block": "239",
            "label": "DR. J.C.KUMARAPPA CENTENARY VIDYA MANDIR MATRIC  HIGHER SECONDARY SCHOOL - PERAVURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1370",
            "district": "25",
            "Block": "314",
            "label": "MAXWELL MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1403",
            "district": "25",
            "Block": "42",
            "label": "SIR SIVASWAMI BALAVIDHYALAYA  MATRICULATION HIGHER SECONDARY SCHOOL -THIRUKKATTUPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1455",
            "district": "25",
            "Block": "228",
            "label": "JACK & JILL MATRIC HIGH SCHOOL - KABISTHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1503",
            "district": "25",
            "Block": "313",
            "label": "KAMALA SUBRAMANIAM MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1594",
            "district": "25",
            "Block": "313",
            "label": "PUMS - VALLAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1706",
            "district": "25",
            "Block": "330",
            "label": "PUMS  KANNARAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1843",
            "district": "25",
            "Block": "344",
            "label": "THE NEW LIGHT MATRICULATION SCHOOL-  THIRUVIDAIMARUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1907",
            "district": "25",
            "Block": "228",
            "label": "Abidean Matric High School, Thiruppalathurai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1932",
            "district": "25",
            "Block": "313",
            "label": "FR. ANTONY MATRIC HR.SEC.SCHOOL,VALLAM,THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1983",
            "district": "25",
            "Block": "144",
            "label": "KALYANASUNDARAM CBSE -CHOZHANMALIGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2002",
            "district": "25",
            "Block": "228",
            "label": "AL-MUBEEN MATRICULATION HIGHER SECONDARY SCHOOL -AYYYAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2010",
            "district": "25",
            "Block": "11",
            "label": "DR.V.GENGUSAMY MAT. -MELATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2015",
            "district": "25",
            "Block": "228",
            "label": "STAR LIONS MATRIC HIGHER SECONDARY SCHOOL-AYYAMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2036",
            "district": "25",
            "Block": "336",
            "label": "AMALRAJ MATRIC HSS THIRUVAIYARU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2048",
            "district": "25",
            "Block": "313",
            "label": "LITTLE SCHOLARS MATRICULATION HIGHER SECONDARY SCHOOL- THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2051",
            "district": "25",
            "Block": "336",
            "label": "IMMACULATE MATRIC HS THIRUVAIYARU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2102",
            "district": "25",
            "Block": "216",
            "label": "PUMS-PEIKARUMBANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2105",
            "district": "25",
            "Block": "11",
            "label": "PUMS - SALIAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2211",
            "district": "25",
            "Block": "239",
            "label": "MUVENDAR MATRICULATION HIGHER SECONDARY SCHOOL - PERAVURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2228",
            "district": "25",
            "Block": "11",
            "label": "UKKADAI A.VISWANATHA DEVAR MATRICULATION SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2243",
            "district": "25",
            "Block": "314",
            "label": "T.K.R. MS-AYYANKADAI THERU-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2266",
            "district": "25",
            "Block": "314",
            "label": "MORNING STAR MATRICULATION SCHOOL THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2276",
            "district": "25",
            "Block": "11",
            "label": "SRI LAKSHMI VIDYALAYA MATRICULATION SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2287",
            "district": "25",
            "Block": "313",
            "label": "BEST MATRICULATION HIGHER SECONDARY SCHOOL,  MARIYAMMANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2294",
            "district": "25",
            "Block": "11",
            "label": "FATHIMA MATRIC HIGHER SECONDARY SCHOOL - KOVILUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2298",
            "district": "25",
            "Block": "344",
            "label": "STAR MATRIC HR. SEC. SCHOOL -THIRUBUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2304",
            "district": "25",
            "Block": "313",
            "label": "PUMS - KEELAVASTHACHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2319",
            "district": "25",
            "Block": "345",
            "label": "PUMS-KADUVETTI VIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2322",
            "district": "25",
            "Block": "287",
            "label": "PUMS- UMATHANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2325",
            "district": "25",
            "Block": "313",
            "label": "C.S. MATRICULATION HIGHER SECONDARY SCHOOL, MARIYAMMANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2328",
            "district": "25",
            "Block": "228",
            "label": "MANI MATRIC HSS - KABISTHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2335",
            "district": "25",
            "Block": "216",
            "label": "LONDON KRISHNAMOORTHI MHSS-OND",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2342",
            "district": "25",
            "Block": "157",
            "label": "PUMS - PULAVANCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2379",
            "district": "25",
            "Block": "344",
            "label": "SOWRASTRA MATRIC HS-THIRUBUVANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2434",
            "district": "25",
            "Block": "314",
            "label": "ST.PETERS HSS-VADAKKUVEETHI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2500",
            "district": "25",
            "Block": "42",
            "label": "REV.FR.LXM HSS -THIRUKKATTUPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2517",
            "district": "25",
            "Block": "144",
            "label": "AMMANKOIL AIDED MS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23388",
            "district": "25",
            "Block": "11",
            "label": "INDIAN CITY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "2530",
            "district": "25",
            "Block": "144",
            "label": "PUMS - MANAPADAIVEEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2543",
            "district": "25",
            "Block": "344",
            "label": "PKS MATRICULATION SCHOOL -THIRUNARAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2551",
            "district": "25",
            "Block": "144",
            "label": "PONNIYAH RAMJAYAM PUBLIC SCHOOL-VALAYAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2557",
            "district": "25",
            "Block": "314",
            "label": "GNANAM MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2572",
            "district": "25",
            "Block": "42",
            "label": "OUR LADY OF HEALTH MATRIC HR. SEC SCHOOL -BUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2585",
            "district": "25",
            "Block": "42",
            "label": "PUMS-VALAMBAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2605",
            "district": "25",
            "Block": "228",
            "label": "ADW MS - MELA VALUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2609",
            "district": "25",
            "Block": "144",
            "label": "LITTLE FLOWER MATRICULATION HIGH SCHOOL, KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2622",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PERUMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2624",
            "district": "25",
            "Block": "144",
            "label": "PUMS MP MANDABAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2626",
            "district": "25",
            "Block": "314",
            "label": "CMS - KARANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2635",
            "district": "25",
            "Block": "144",
            "label": "MOTHER CLARAC MATRICULATION HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2652",
            "district": "25",
            "Block": "313",
            "label": "PUMS - REDDYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2657",
            "district": "25",
            "Block": "42",
            "label": "PUMS-PALAYAKARIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2683",
            "district": "25",
            "Block": "313",
            "label": "PUMS - COODALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2711",
            "district": "25",
            "Block": "313",
            "label": "PUMS - NAGATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2715",
            "district": "25",
            "Block": "228",
            "label": "MANI MATRIC HIGH SCHOOL - GANAPATHY AGRAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2731",
            "district": "25",
            "Block": "144",
            "label": "TAN ACADEMY MAT HSS KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2737",
            "district": "25",
            "Block": "314",
            "label": "NAV BHARAT MATRIC HR SEC SCHOOL M.C.ROAD, THANJAVUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2743",
            "district": "25",
            "Block": "144",
            "label": "ST.ANTONYS MAT HR SEC SCHOOL. - SAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2749",
            "district": "25",
            "Block": "144",
            "label": "CHRIST THE KING(BOYS) MAT HSS KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2774",
            "district": "25",
            "Block": "234",
            "label": "FIVE STAR MATRIC HSS PATTUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2797",
            "district": "25",
            "Block": "330",
            "label": "SRI KUMARA GURUBARAR MATRICULATION  HIGHER SECONDARY SCHOOL-THIRUPPANANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2852",
            "district": "25",
            "Block": "344",
            "label": "RAJAMANI MATRICULATION SCHOOL, THIRUNAGESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2861",
            "district": "25",
            "Block": "314",
            "label": "ST.JOHN DE BRITTO AIDED MIDDLE SCHOOL-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2869",
            "district": "25",
            "Block": "313",
            "label": "PUMS - KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2872",
            "district": "25",
            "Block": "144",
            "label": "KARTHI VIDHYALAYA  MAT  HR.SEC. SCHOOL KORANATTU KARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2892",
            "district": "25",
            "Block": "144",
            "label": "ARR MAT HSS KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2925",
            "district": "25",
            "Block": "313",
            "label": "PUMS - THIRUMALAISAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2926",
            "district": "25",
            "Block": "228",
            "label": "ST. BASTIN'S MATRICULATION HIGHER SECONDARY SCHOOL, PAPANASAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2932",
            "district": "25",
            "Block": "11",
            "label": "PUMS - RAILADY AMMAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2965",
            "district": "25",
            "Block": "344",
            "label": "PUMS - SIVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2969",
            "district": "25",
            "Block": "144",
            "label": "MPL MS MADULAMPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2970",
            "district": "25",
            "Block": "42",
            "label": "ADWMS-CHINNAKANGEYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2996",
            "district": "25",
            "Block": "144",
            "label": "PUMS KOVELACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2999",
            "district": "25",
            "Block": "234",
            "label": "ANNAI THERASA  MATRIC SCHOOL- SEMBAALUR  EAST PATTUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3009",
            "district": "25",
            "Block": "313",
            "label": "PUMS - RAVUSAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3010",
            "district": "25",
            "Block": "314",
            "label": "T.E.L.C  MS-MANAMPUCHAVADI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3011",
            "district": "25",
            "Block": "313",
            "label": "PUMS - KULICHAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3014",
            "district": "25",
            "Block": "313",
            "label": "PUMS - KALAIGNAR  NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3024",
            "district": "25",
            "Block": "42",
            "label": "PUMS-PAVANAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3028",
            "district": "25",
            "Block": "11",
            "label": "PUMS - PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3056",
            "district": "25",
            "Block": "228",
            "label": "PUMS - EACHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3075",
            "district": "25",
            "Block": "239",
            "label": "PUMS- VALASAIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3087",
            "district": "25",
            "Block": "144",
            "label": "PUMS - NATHANKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3093",
            "district": "25",
            "Block": "314",
            "label": "MUDAL THARA INDU AIDED MIDDLE SCHOOL-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3095",
            "district": "25",
            "Block": "314",
            "label": "RAILWAYCOLONY AIDED MIDDLE SCHOOL-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3107",
            "district": "25",
            "Block": "11",
            "label": "PUMS - EDAIIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3109",
            "district": "25",
            "Block": "313",
            "label": "PUMS - SURAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3148",
            "district": "25",
            "Block": "144",
            "label": "NATIONAL SCHOOL FOR THE HEARING IMPAIRED -  KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3165",
            "district": "25",
            "Block": "313",
            "label": "PUMS - AYYASAMYPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3167",
            "district": "25",
            "Block": "144",
            "label": "THIRUNAVUKARASU AMS KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3205",
            "district": "25",
            "Block": "144",
            "label": "ST ANNES MATRICULATION HIGHER SECONDARY SCHOOL(GIRLS) KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3243",
            "district": "25",
            "Block": "314",
            "label": "DON BOSCO MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3261",
            "district": "25",
            "Block": "313",
            "label": "PUMS - MAPPILLAINAYAGANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3263",
            "district": "25",
            "Block": "287",
            "label": "KATHERINE MATRIC - CHINNAAVUDAIYARKOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3293",
            "district": "25",
            "Block": "314",
            "label": "SHRI VENKATESWARA MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3300",
            "district": "25",
            "Block": "344",
            "label": "THICOSILKS MATRIC HR SEC SCHOOL-THIRUBUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3308",
            "district": "25",
            "Block": "228",
            "label": "PATTUKOTTAI ALAGIRI MAT.HSS-PAPANASAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3311",
            "district": "25",
            "Block": "314",
            "label": "CMS-PALLIAGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3321",
            "district": "25",
            "Block": "144",
            "label": "MORNING STAR MAT CHOLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3324",
            "district": "25",
            "Block": "314",
            "label": "CMS-OLD HOUSING UNIT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3332",
            "district": "25",
            "Block": "313",
            "label": "AMS- RAMAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3338",
            "district": "25",
            "Block": "144",
            "label": "PUMS-ASOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3346",
            "district": "25",
            "Block": "313",
            "label": "PUMS - AMMANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3357",
            "district": "25",
            "Block": "313",
            "label": "AMS- AMMANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3361",
            "district": "25",
            "Block": "144",
            "label": "Al-Ameen Matriculation Higher Secondary School, Kumbakonam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3363",
            "district": "25",
            "Block": "314",
            "label": "ROYAL MATRIC HIGHER SECONDARY SCHOOL - M.CHAVADI-THANJAVURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3364",
            "district": "25",
            "Block": "42",
            "label": "PUMS-MGM.SANATORIUM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3367",
            "district": "25",
            "Block": "216",
            "label": "PUMS - PULAVANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3377",
            "district": "25",
            "Block": "313",
            "label": "YAGAPPA MATRICULATION HSS-KALAINGNAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3378",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PASUPATHI KOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3381",
            "district": "25",
            "Block": "314",
            "label": "ST. ANTONY'S AIDED MIDDLE SCHOOL-M-CHAVADI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3442",
            "district": "25",
            "Block": "11",
            "label": "PUMS - ARUMALAIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3447",
            "district": "25",
            "Block": "234",
            "label": "OXFORD MATRICULATION HIGHER SECONDARY SCHOOL -PUDUKKOTTAI ULLUR, PATTUKKOTTAI ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3458",
            "district": "25",
            "Block": "287",
            "label": "PUMS -KODIVAYALMARAKKAVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3490",
            "district": "25",
            "Block": "344",
            "label": "PUMS - THIRUVISANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3498",
            "district": "25",
            "Block": "144",
            "label": "PUMS - THIRUPURAMBIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3518",
            "district": "25",
            "Block": "144",
            "label": "VIVEKANANDHA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, THENAMPADUGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3522",
            "district": "25",
            "Block": "287",
            "label": "PUMS -OMAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3538",
            "district": "25",
            "Block": "314",
            "label": "JAINA FREE AIDED MIDDLE SCHOOL-KARANTHAI-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3544",
            "district": "25",
            "Block": "11",
            "label": "PUMS - THIRUBUVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3565",
            "district": "25",
            "Block": "144",
            "label": "PUMS - NADUVAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3591",
            "district": "25",
            "Block": "42",
            "label": "PUMS-NEMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3602",
            "district": "25",
            "Block": "42",
            "label": "PUMS-PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3609",
            "district": "25",
            "Block": "234",
            "label": "IMAM SHAFI MAT HR SEC SCHOOL - ADIRAMPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3618",
            "district": "25",
            "Block": "313",
            "label": "PUMS - MELAMANOJIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3620",
            "district": "25",
            "Block": "239",
            "label": "PUMS -NATTANIKKOTTAI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3621",
            "district": "25",
            "Block": "11",
            "label": "PUMS- SENBAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3632",
            "district": "25",
            "Block": "144",
            "label": "PUMS NAGAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3633",
            "district": "25",
            "Block": "42",
            "label": "RC AMS-MEGALATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3639",
            "district": "25",
            "Block": "344",
            "label": "AL KHAREEM MATRICULATION  SCHOOL-MURUKKANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3643",
            "district": "25",
            "Block": "287",
            "label": "ABU MATRIC HSS- PUDUPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3654",
            "district": "25",
            "Block": "313",
            "label": "AMS - SITHIRAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3658",
            "district": "25",
            "Block": "314",
            "label": "MAKKAL  UZHIYA SANGAM AIDED MIDDLE SCHOOL-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3672",
            "district": "25",
            "Block": "314",
            "label": "D.K.SUBBAIYAH NAIDU MS-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3681",
            "district": "25",
            "Block": "313",
            "label": "PUMS - NANCHIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3683",
            "district": "25",
            "Block": "42",
            "label": "PUMS-PALAYAPATTI SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3696",
            "district": "25",
            "Block": "42",
            "label": "CHELLAM AMS-ORATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3699",
            "district": "25",
            "Block": "11",
            "label": "PUMS - UKKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3706",
            "district": "25",
            "Block": "330",
            "label": "AMS-THIRUKODIKAVAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3749",
            "district": "25",
            "Block": "42",
            "label": "PUMS-THOGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3751",
            "district": "25",
            "Block": "336",
            "label": "PUMS-MAHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3761",
            "district": "25",
            "Block": "144",
            "label": "MPL MS - KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3783",
            "district": "25",
            "Block": "314",
            "label": "SRI SADASIVA AIDED MIDDLE SCHOOL- THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3807",
            "district": "25",
            "Block": "344",
            "label": "ANURADHA MAHA VIDYALAYA  MATRICULATION HIGHER SECONDARY SCHOOL  -NALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3816",
            "district": "25",
            "Block": "345",
            "label": "SRI KRISHNA MATRIC-P.VIDUTHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3817",
            "district": "25",
            "Block": "11",
            "label": "ST. MARCINA'S MAT.HSS-AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3858",
            "district": "25",
            "Block": "216",
            "label": "SRI SRINIVASA  MATRIC HSS-ORATHANADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3860",
            "district": "25",
            "Block": "336",
            "label": "PUMS-VEERASINGAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3870",
            "district": "25",
            "Block": "313",
            "label": "AMS - ST. XAVIER  VALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3876",
            "district": "25",
            "Block": "11",
            "label": "PUMS - VAIYACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3894",
            "district": "25",
            "Block": "330",
            "label": "SRI KUMARA GURUPARA SWAMIGAL MIDDLE SCHOOL - THIRUPPANANDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3901",
            "district": "25",
            "Block": "42",
            "label": "PUMS-KOOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3904",
            "district": "25",
            "Block": "144",
            "label": "NATIONAL VIDYALAYA CBSE HSS - KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3915",
            "district": "25",
            "Block": "313",
            "label": "PUMS - MARIYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3920",
            "district": "25",
            "Block": "144",
            "label": "AA MPL MS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3939",
            "district": "25",
            "Block": "344",
            "label": "PUMS - MANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3941",
            "district": "25",
            "Block": "313",
            "label": "PUMS - THITTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3949",
            "district": "25",
            "Block": "228",
            "label": "SRI. MAHAGANAPATHY MIDDLE SCHOOL - GANAPATHY AGRAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3952",
            "district": "25",
            "Block": "42",
            "label": "VIDHYA MHSS- BUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3957",
            "district": "25",
            "Block": "287",
            "label": "PUMS -RETTAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3986",
            "district": "25",
            "Block": "239",
            "label": "KALAIMAGAL MATRIC HR SEC SCHOOL - THIRUCHITRAMBALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3992",
            "district": "25",
            "Block": "344",
            "label": "PUMS - SATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3994",
            "district": "25",
            "Block": "144",
            "label": "AIDED MS KORANATTU KARUPPUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4010",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PANDARAVADI (NORTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4025",
            "district": "25",
            "Block": "228",
            "label": "RDB MATRICULATION HSS-PAPANASAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4027",
            "district": "25",
            "Block": "344",
            "label": "PUMS - AMMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4033",
            "district": "25",
            "Block": "336",
            "label": "HARIJAN AIDED MS-VELLAMPERAMBUR-E",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4036",
            "district": "25",
            "Block": "314",
            "label": "GIRLS CHRISTIAN AIDED HR SEC SCHOOL-M-CHAVADI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4053",
            "district": "25",
            "Block": "336",
            "label": "PUMS -VILANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4088",
            "district": "25",
            "Block": "344",
            "label": "PUMS - AYYAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4094",
            "district": "25",
            "Block": "11",
            "label": "PUMS - SIKKAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4099",
            "district": "25",
            "Block": "216",
            "label": "PUMS - THENNAMANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4104",
            "district": "25",
            "Block": "157",
            "label": "SRI RAMAVILAS AIDED MIDDLE SCHOOL - ELANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4137",
            "district": "25",
            "Block": "336",
            "label": "SRI RAMA KRISHNA AIDED MIDDLE SCHOOL-THILLAISTHANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4145",
            "district": "25",
            "Block": "144",
            "label": "PUMS - AMMAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4153",
            "district": "25",
            "Block": "314",
            "label": "CMS-CHINNAKADAITHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4179",
            "district": "25",
            "Block": "344",
            "label": "ADWMS - S.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4198",
            "district": "25",
            "Block": "336",
            "label": "PUMS-THIRUPAZHANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4199",
            "district": "25",
            "Block": "228",
            "label": "ST.SABASTIN MS-PAPANASAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4203",
            "district": "25",
            "Block": "314",
            "label": "SRI. GANESA VID. MS- POOKARATHERU-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4215",
            "district": "25",
            "Block": "336",
            "label": "ST.REETA AIDED MIDDLE SCHOOL-THIRUPPANTHURUTHI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4241",
            "district": "25",
            "Block": "216",
            "label": "LITTLE ROSE M H S S- ORATHANADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4246",
            "district": "25",
            "Block": "144",
            "label": "MYDEEN MAT. HSS - KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4298",
            "district": "25",
            "Block": "287",
            "label": "PUMS -ETTIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4307",
            "district": "25",
            "Block": "344",
            "label": "PUMS - THIRUMANGALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4333",
            "district": "25",
            "Block": "144",
            "label": "PUMS-AMMACHATRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4351",
            "district": "25",
            "Block": "216",
            "label": "ST.MARYS AIDED MS-ORATHANADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4417",
            "district": "25",
            "Block": "344",
            "label": "AIDED  MIDDLE SCHOOL - NAGARASAMPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4435",
            "district": "25",
            "Block": "144",
            "label": "PUMS-PULIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4439",
            "district": "25",
            "Block": "228",
            "label": "PUMS THIRUPALATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4479",
            "district": "25",
            "Block": "144",
            "label": "ST AGASTHINAR AMS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4483",
            "district": "25",
            "Block": "234",
            "label": "MPLMS-MUSLIM THERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4484",
            "district": "25",
            "Block": "313",
            "label": "PUMS - SAKKARASAMANTHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4505",
            "district": "25",
            "Block": "239",
            "label": "KALAIMAGAL AIDED MIDDLE SCHOOL- THURAVIKKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4517",
            "district": "25",
            "Block": "314",
            "label": "CMS-VANDIKARATHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4565",
            "district": "25",
            "Block": "216",
            "label": "PUMS - KAKKARAIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4573",
            "district": "25",
            "Block": "11",
            "label": "PUMS - VADAKKUMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4581",
            "district": "25",
            "Block": "345",
            "label": "PUMS-POYYUNDAR KUDIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4583",
            "district": "25",
            "Block": "11",
            "label": "PUMS - RARAMUTHIRAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4585",
            "district": "25",
            "Block": "144",
            "label": "PUMS SOLANMALIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4590",
            "district": "25",
            "Block": "11",
            "label": "PUMS - KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4591",
            "district": "25",
            "Block": "157",
            "label": "PUMS - AVIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4596",
            "district": "25",
            "Block": "157",
            "label": "PUMS - MADUKKUR SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4600",
            "district": "25",
            "Block": "344",
            "label": "PUMS - KURICHIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4625",
            "district": "25",
            "Block": "11",
            "label": "PUMS - SOOLIAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4672",
            "district": "25",
            "Block": "157",
            "label": "PUMS - ANDAMI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4732",
            "district": "25",
            "Block": "344",
            "label": "RAMAKRISHNA AIDED  MIDDLE SCHOOL - NACHIYARKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4740",
            "district": "25",
            "Block": "314",
            "label": "INDIAN MATRIC HIGH SCHOOL - ESWARI NAGAR-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4743",
            "district": "25",
            "Block": "157",
            "label": "PUMS - RAMAMBALPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4746",
            "district": "25",
            "Block": "313",
            "label": "PUMS - THENPERAMPOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4759",
            "district": "25",
            "Block": "234",
            "label": "MPLMS- THANGAVEL NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4772",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PONPETHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4784",
            "district": "25",
            "Block": "228",
            "label": "CRESCENT MATRIC HIGHER SECONDARY SCHOOL -PANDARAVADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4818",
            "district": "25",
            "Block": "344",
            "label": "THIAGARAJAR AIDED  MIDDLE SCHOOL - SANNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4832",
            "district": "25",
            "Block": "336",
            "label": "ADW MS-VENKADASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4835",
            "district": "25",
            "Block": "216",
            "label": "PUMS- NEDUVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4847",
            "district": "25",
            "Block": "239",
            "label": "PUMS- PONNANKANNIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4869",
            "district": "25",
            "Block": "144",
            "label": "MADHA DIFFERENTLY ABLED HR SEC SCHOOL-SAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4896",
            "district": "25",
            "Block": "234",
            "label": "TELC MS-PATTUKKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4903",
            "district": "25",
            "Block": "228",
            "label": "ST. MARY'S MATRICULATION SCHOOL -PASUPATHIKOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4977",
            "district": "25",
            "Block": "144",
            "label": "SRI KALAIMAGAL KENDRA MATRIC HIGHER SECONDARY SCHOOL KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5056",
            "district": "25",
            "Block": "344",
            "label": "AIDED  MIDDLE SCHOOL - VEPPATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23682",
            "district": "25",
            "Block": "144",
            "label": "Town higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5064",
            "district": "25",
            "Block": "336",
            "label": "PUMS-POONAVARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5099",
            "district": "25",
            "Block": "287",
            "label": "PUMS -KATTAYANKADU UKKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5147",
            "district": "25",
            "Block": "157",
            "label": "PUMS - VIKRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5149",
            "district": "25",
            "Block": "228",
            "label": "ALIF MATRICULATION SCHOOL - VALUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5216",
            "district": "25",
            "Block": "314",
            "label": "BHARATHA MADA MS-VANDIKARA THERU-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5218",
            "district": "25",
            "Block": "144",
            "label": "BHARATHA RATHNA PURATCHITHALAIVAR DR.MGR MEMMORIAL MPL MIDDLE SCHOOL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5229",
            "district": "25",
            "Block": "157",
            "label": "PUMS - KADANTHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5231",
            "district": "25",
            "Block": "144",
            "label": "SARASWATHI PADASALA AHSS  KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5239",
            "district": "25",
            "Block": "157",
            "label": "PUMS - SOKKANAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5246",
            "district": "25",
            "Block": "228",
            "label": "PUMS - MELA MANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5270",
            "district": "25",
            "Block": "157",
            "label": "PUMS - KARUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5342",
            "district": "25",
            "Block": "216",
            "label": "PUMS- AMBALAPPATTU (S)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5498",
            "district": "25",
            "Block": "144",
            "label": "PUMS-SATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5533",
            "district": "25",
            "Block": "144",
            "label": "C.P VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL,  KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5611",
            "district": "25",
            "Block": "330",
            "label": "PUMS-MUTTAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5660",
            "district": "25",
            "Block": "144",
            "label": "PATTU MATRIC HSS SAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5689",
            "district": "25",
            "Block": "144",
            "label": "PUMS - THENAMPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5695",
            "district": "25",
            "Block": "216",
            "label": "PUMS-SAMAYANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5705",
            "district": "25",
            "Block": "228",
            "label": "Tansri Ubaidulla Matriculation Higher Secondary School, Rajagiri",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5707",
            "district": "25",
            "Block": "228",
            "label": "KASSIMIA DAWOOD MS - RAJAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5725",
            "district": "25",
            "Block": "228",
            "label": "ANJUMAN MATRIC HIGHER SECONDARY SCHOOL- AYYAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5760",
            "district": "25",
            "Block": "314",
            "label": "SRI SAVITRI VID. MAT. HS-KARANTHAI-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5806",
            "district": "25",
            "Block": "330",
            "label": "PUMS-KEELMANTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5876",
            "district": "25",
            "Block": "239",
            "label": "PUMS - THULUKKAVIDUTHI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5884",
            "district": "25",
            "Block": "330",
            "label": "AMS-KATHIRAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5892",
            "district": "25",
            "Block": "345",
            "label": "PUMS-THIPPAN VIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5938",
            "district": "25",
            "Block": "345",
            "label": "PERIYAR MATRICULATION HIGH SCHOOL-VETTIKKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5940",
            "district": "25",
            "Block": "336",
            "label": "MAHATHMA MATRICULATION SCHOOL, NADUKAVERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6001",
            "district": "25",
            "Block": "228",
            "label": "PUMS - ATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6007",
            "district": "25",
            "Block": "228",
            "label": "PUMS - ELANGARKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6029",
            "district": "25",
            "Block": "287",
            "label": "PUMS -KOLLUKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6083",
            "district": "25",
            "Block": "344",
            "label": "GMS - THEPPERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6195",
            "district": "25",
            "Block": "11",
            "label": "PUMS - ATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6213",
            "district": "25",
            "Block": "336",
            "label": "GMS NADUKAVERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6267",
            "district": "25",
            "Block": "234",
            "label": "PUMS -KOTTAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6278",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PAPANASAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6376",
            "district": "25",
            "Block": "234",
            "label": "PUMS -SANTHANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6378",
            "district": "25",
            "Block": "216",
            "label": "PUMS - MANDALAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6439",
            "district": "25",
            "Block": "216",
            "label": "PUMS - PANCHANATHIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6449",
            "district": "25",
            "Block": "144",
            "label": "PUMS - SENGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6480",
            "district": "25",
            "Block": "336",
            "label": "PUMS-SEMMANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6523",
            "district": "25",
            "Block": "234",
            "label": "PUMS- PAZHANJUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6550",
            "district": "25",
            "Block": "344",
            "label": "PUMS - KALYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6610",
            "district": "25",
            "Block": "144",
            "label": "SRI MATHA MAT HSS KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6623",
            "district": "25",
            "Block": "234",
            "label": "MPLMS- JAYALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6635",
            "district": "25",
            "Block": "287",
            "label": "PUMS- SAMBAIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6670",
            "district": "25",
            "Block": "239",
            "label": "S.E.T. VIDHYADEVI MATRIC HR SEC SCHOOL - ALIVALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6688",
            "district": "25",
            "Block": "344",
            "label": "R.RAJARAMAN  MIDDLE SCHOOL-NARASINGANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6715",
            "district": "25",
            "Block": "234",
            "label": "THE LITTLE BIRD MATRIC-PATTUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6738",
            "district": "25",
            "Block": "330",
            "label": "PASUPATHI MIDDLE SCHOOL-PANDHANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16353",
            "district": "25",
            "Block": "314",
            "label": "VITTAL MATRIC SCHOOL PALLIYAGGRAHARAM-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16354",
            "district": "25",
            "Block": "314",
            "label": "UMA MAHESWARA HSS-KARANTHAI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16355",
            "district": "25",
            "Block": "314",
            "label": "KALYANASUNDARAM HIGHER SECONDARY SCHOOL, THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16356",
            "district": "25",
            "Block": "314",
            "label": "UKKADAI SRI AMBAL HIGH SCHOOL -MELAVEETHI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16357",
            "district": "25",
            "Block": "314",
            "label": "VEERA RAGHAVA HSS-THERKU VEETHI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16358",
            "district": "25",
            "Block": "314",
            "label": "ORIENTAL HSS-THERKKU VEETHI-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16359",
            "district": "25",
            "Block": "314",
            "label": "CHSS-THENKEEL ALANGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16360",
            "district": "25",
            "Block": "314",
            "label": "GHSS (GIRLS) - THANJAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16361",
            "district": "25",
            "Block": "314",
            "label": "RAJAH'S HSS - ARANMANAI VALAGAM-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16362",
            "district": "25",
            "Block": "314",
            "label": "BLAKE AIDED HIGHER SECONDARY SCHOOL - M-CHAVADI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16363",
            "district": "25",
            "Block": "314",
            "label": "CMS-SOWRASTRA THERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16364",
            "district": "25",
            "Block": "314",
            "label": "GMS RAJAJI - THANJAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16365",
            "district": "25",
            "Block": "314",
            "label": "GHSS(BOYS) - MEMBALAM-THANJAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16366",
            "district": "25",
            "Block": "314",
            "label": "GHSS -HEARING IMPAIRED (MEMBALAM)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16367",
            "district": "25",
            "Block": "314",
            "label": "P.V.SELVARAJ (GIRLS) AIDED HR SEC SCHOOL- MEMBALAM-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16368",
            "district": "25",
            "Block": "314",
            "label": "GHSS-VISUALLY IMPAIRED(MEMBALAM)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16369",
            "district": "25",
            "Block": "314",
            "label": "THIRUMAGAL AIDED HR SEC SCHOOL - M.C.ROAD-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16370",
            "district": "25",
            "Block": "314",
            "label": "ALLWIN HSS-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16371",
            "district": "25",
            "Block": "314",
            "label": "PONNAIYAH RAMAJAYAM PUBLIC HR. SEC. SCHOOL - THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16372",
            "district": "25",
            "Block": "314",
            "label": "SPADIX MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16373",
            "district": "25",
            "Block": "314",
            "label": "CHS- NEELAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16374",
            "district": "25",
            "Block": "314",
            "label": "AUXILIUM GIRLS HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16375",
            "district": "25",
            "Block": "314",
            "label": "MAHARISHI VIDYA MANDIR CBSE SCHOOL -THANJAUVR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16376",
            "district": "25",
            "Block": "314",
            "label": "SACRED HEART (GIRLS)HSS - THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16377",
            "district": "25",
            "Block": "314",
            "label": "ST. ANTONYS  HSS-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16378",
            "district": "25",
            "Block": "314",
            "label": "SEVENTH DAY ADVENTIST MATRIC HSS,VANDIKARATHERU-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16379",
            "district": "25",
            "Block": "314",
            "label": "ST. JOSEPH(GIRLS) HSS-POOKARATHERU-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16380",
            "district": "25",
            "Block": "314",
            "label": "THE CHRIST THE KING HSS-A.S.NA-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16381",
            "district": "25",
            "Block": "314",
            "label": "CHSS-ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16382",
            "district": "25",
            "Block": "313",
            "label": "GHS- KALLAPERAMPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16383",
            "district": "25",
            "Block": "313",
            "label": "PUMS - VANNARAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16384",
            "district": "25",
            "Block": "313",
            "label": "GHSS - ALAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16385",
            "district": "25",
            "Block": "313",
            "label": "ST. MARY'S HIGH SCHOOL  - THIRUKKANURPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16386",
            "district": "25",
            "Block": "313",
            "label": "KALAIMAGAL HR SEC SCHOOL VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16387",
            "district": "25",
            "Block": "313",
            "label": "KENDRIYA VIDYALAYA  AF STATION",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16388",
            "district": "25",
            "Block": "313",
            "label": "GHS-PILLAIYARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16389",
            "district": "25",
            "Block": "313",
            "label": "A.A. SUGAR FACT. HS-KURUNGULAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16390",
            "district": "25",
            "Block": "313",
            "label": "GHS - KURUNGULAM EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16391",
            "district": "25",
            "Block": "313",
            "label": "GHS - MATHUR (WEST)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16392",
            "district": "25",
            "Block": "313",
            "label": "GHS - THAZHAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16393",
            "district": "25",
            "Block": "313",
            "label": "THAMARAI INTERNATIONAL -KADAKADAPAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16394",
            "district": "25",
            "Block": "313",
            "label": "G (G)HSS (GIRLS) - MARIYAMMAN KOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16395",
            "district": "25",
            "Block": "313",
            "label": "GBHSS - MARIYAMMAN KOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16396",
            "district": "25",
            "Block": "313",
            "label": "ST.MARY'S HR SEC SCHOOL -MARIAMMANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16397",
            "district": "25",
            "Block": "313",
            "label": "ST.MICHAEL'S HIGH SCHOOL - MADHAKKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16398",
            "district": "25",
            "Block": "313",
            "label": "LITTLESCHOLARS(T)HS-PILOMINANAGER",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16399",
            "district": "25",
            "Block": "313",
            "label": "GHS- EZHUPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16400",
            "district": "25",
            "Block": "313",
            "label": "GHS - N. VALLUNDANPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16401",
            "district": "25",
            "Block": "313",
            "label": "GHSS - MARUNGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16402",
            "district": "25",
            "Block": "313",
            "label": "GHS - KOLLANKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16403",
            "district": "25",
            "Block": "313",
            "label": "GHS - KASANADU PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16404",
            "district": "25",
            "Block": "313",
            "label": "AIDED  MS - KANDITHAMPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16405",
            "district": "25",
            "Block": "313",
            "label": "ST. JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL,  VILAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16406",
            "district": "25",
            "Block": "313",
            "label": "BLOSSOM PUBLIC SCHOOL -SURAKKOTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16407",
            "district": "25",
            "Block": "313",
            "label": "GOVT ADW  HS -  MANOJIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16408",
            "district": "25",
            "Block": "313",
            "label": "ST.MARYS HS - THILLAI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16409",
            "district": "25",
            "Block": "313",
            "label": "GHS - PANAVELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16410",
            "district": "25",
            "Block": "313",
            "label": "ARULNERI HIGH SCHOOL- AMMANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16411",
            "district": "25",
            "Block": "313",
            "label": "GBHSS - VALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16412",
            "district": "25",
            "Block": "313",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL -VALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16413",
            "district": "25",
            "Block": "336",
            "label": "AMS-SIVASAMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16414",
            "district": "25",
            "Block": "336",
            "label": "Sacred Heart Higher Secondary School  Michaelpatty",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16415",
            "district": "25",
            "Block": "336",
            "label": "GHS- VANARANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16416",
            "district": "25",
            "Block": "336",
            "label": "GHS-SATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16417",
            "district": "25",
            "Block": "336",
            "label": "GHS VALAPPAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16418",
            "district": "25",
            "Block": "336",
            "label": "ST.THOMAS RC HIGH SCHOOL -MANATHIDAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16419",
            "district": "25",
            "Block": "336",
            "label": "GHS VARAGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16420",
            "district": "25",
            "Block": "336",
            "label": "PUMS ALLISAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16421",
            "district": "25",
            "Block": "336",
            "label": "GHS MOHAMED BUNDAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16422",
            "district": "25",
            "Block": "336",
            "label": "GHS KANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16423",
            "district": "25",
            "Block": "336",
            "label": "GHS ACHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16424",
            "district": "25",
            "Block": "336",
            "label": "ADW HSS-KEELAPUNALVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16425",
            "district": "25",
            "Block": "336",
            "label": "GHSS - MELATHIRUPPANTHURITHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16426",
            "district": "25",
            "Block": "336",
            "label": "ST.JOSEPH HS-THIRUVAIYARU-E",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16427",
            "district": "25",
            "Block": "336",
            "label": "GHSS(GIRLS) THIRUVAIYARU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16428",
            "district": "25",
            "Block": "336",
            "label": "SR.HSS-THIRUVAIYARU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16429",
            "district": "25",
            "Block": "42",
            "label": "SINNARANI AHS- A.POONDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16430",
            "district": "25",
            "Block": "42",
            "label": "ADWHSS-AGARAPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16431",
            "district": "25",
            "Block": "42",
            "label": "GHS-ACHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16432",
            "district": "25",
            "Block": "42",
            "label": "GHS-INDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16433",
            "district": "25",
            "Block": "42",
            "label": "GHS-KOVILADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16434",
            "district": "25",
            "Block": "42",
            "label": "SRIDIVYA MKR MAT.SCHOOL-SANOORAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16435",
            "district": "25",
            "Block": "42",
            "label": "GHS-CHELLAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16436",
            "district": "25",
            "Block": "42",
            "label": "GHSS-SENGIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16437",
            "district": "25",
            "Block": "42",
            "label": "ADW HS -PUDUKUDDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16438",
            "district": "25",
            "Block": "42",
            "label": "ST PETER PAULS  HS -MUTHANDIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16439",
            "district": "25",
            "Block": "42",
            "label": "GGHSS -BUDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16440",
            "district": "25",
            "Block": "42",
            "label": "GBHSS-BUDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16441",
            "district": "25",
            "Block": "42",
            "label": "GHSS-MANAIYERIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16442",
            "district": "25",
            "Block": "42",
            "label": "ADWHS-MUTHUVEERAKANDIYANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16443",
            "district": "25",
            "Block": "42",
            "label": "GHSS-ELANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16444",
            "district": "25",
            "Block": "42",
            "label": "GHS-RAYAMUNDANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16445",
            "district": "25",
            "Block": "42",
            "label": "GHS-THIRUKKATTUPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16446",
            "district": "25",
            "Block": "42",
            "label": "SIVASWAMI AYYAR CENTENARY GIRLS' HIGH SCHOOL THIRUKKATTUPPALLI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16447",
            "district": "25",
            "Block": "42",
            "label": "SIR SIVASWAMI AYYAR HSS-THIRUKATTUPALLI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16448",
            "district": "25",
            "Block": "216",
            "label": "PUMS - AZHIVAIKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16449",
            "district": "25",
            "Block": "216",
            "label": "GHS - AZHIAVAIKKAL NATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16450",
            "district": "25",
            "Block": "216",
            "label": "GHSS-ILLUPPAITHOPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16451",
            "district": "25",
            "Block": "216",
            "label": "PUMS-KUDIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16452",
            "district": "25",
            "Block": "216",
            "label": "GHS - ADHANAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16453",
            "district": "25",
            "Block": "216",
            "label": "GHSS - PAPPANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16454",
            "district": "25",
            "Block": "216",
            "label": "KASILINGAM MATRIC - PAPPANADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16455",
            "district": "25",
            "Block": "216",
            "label": "MMA  HIGHER SECONDARY SCHOOL-PAPPANADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16456",
            "district": "25",
            "Block": "216",
            "label": "GHS-SOLAPURAM(WEST)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16457",
            "district": "25",
            "Block": "216",
            "label": "GHSS - EACHANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16458",
            "district": "25",
            "Block": "216",
            "label": "GHS - KAKKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16459",
            "district": "25",
            "Block": "216",
            "label": "GHS - KANNANTHANKUDI (EAST)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16460",
            "district": "25",
            "Block": "216",
            "label": "GHS - KANNANTHANGUDI MELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16461",
            "district": "25",
            "Block": "216",
            "label": "GHSS - KANNUKUDI (WEST)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16462",
            "district": "25",
            "Block": "216",
            "label": "GHSS - KARUKKADIPPATTI (WEST)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16463",
            "district": "25",
            "Block": "216",
            "label": "PUMS - NELLUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16464",
            "district": "25",
            "Block": "216",
            "label": "GHS - KAVARAPPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16465",
            "district": "25",
            "Block": "216",
            "label": "GHS  - KULAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16466",
            "district": "25",
            "Block": "216",
            "label": "GHSS - MELAULUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16467",
            "district": "25",
            "Block": "216",
            "label": "GHSS-PANAYAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16468",
            "district": "25",
            "Block": "216",
            "label": "GHS - NADUVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16469",
            "district": "25",
            "Block": "216",
            "label": "GHSS - NEIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16470",
            "district": "25",
            "Block": "216",
            "label": "GHS(G) - OKKANADU KEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16471",
            "district": "25",
            "Block": "216",
            "label": "GHSS  - OKKANADU KEELAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16472",
            "district": "25",
            "Block": "216",
            "label": "GOVT. MPN H S- OKKANADU MELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16473",
            "district": "25",
            "Block": "216",
            "label": "GHSS-URANTHARAYANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16474",
            "district": "25",
            "Block": "216",
            "label": "GHSS -PATCHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16475",
            "district": "25",
            "Block": "216",
            "label": "PUMS - PARITHIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16476",
            "district": "25",
            "Block": "216",
            "label": "D.A. GHSS  - PONNAPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16477",
            "district": "25",
            "Block": "216",
            "label": "GHS-POOVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16478",
            "district": "25",
            "Block": "216",
            "label": "GHSS - POYYUNDARKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16479",
            "district": "25",
            "Block": "216",
            "label": "GHS - ARSUTHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16480",
            "district": "25",
            "Block": "216",
            "label": "GHS - SADAIYARKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16481",
            "district": "25",
            "Block": "216",
            "label": "GHS -SETHURAYANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16482",
            "district": "25",
            "Block": "216",
            "label": "PUMS - THALAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16483",
            "district": "25",
            "Block": "216",
            "label": "GHSS - THEKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16484",
            "district": "25",
            "Block": "216",
            "label": "SRI RAMAVILAS HS-THENNAMANADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16485",
            "district": "25",
            "Block": "216",
            "label": "GHSS - THIRUMANGALAKKOTTAI KEELAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16486",
            "district": "25",
            "Block": "216",
            "label": "GHS -THIRUMANGALAKKOTTAI MELA COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16487",
            "district": "25",
            "Block": "216",
            "label": "NALANDA MATRIC SCHOOL - THIRUMANAGALKOTTAI KEELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16488",
            "district": "25",
            "Block": "216",
            "label": "GHS-VADAKUR VADAKKU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16489",
            "district": "25",
            "Block": "216",
            "label": "GHSS - VADASERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16490",
            "district": "25",
            "Block": "216",
            "label": "GHSS-VANDAIYARIRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16491",
            "district": "25",
            "Block": "216",
            "label": "GHS-VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16492",
            "district": "25",
            "Block": "216",
            "label": "GHS-ORATHANADU PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16493",
            "district": "25",
            "Block": "216",
            "label": "GHSS (BOYS) - ORATHANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16494",
            "district": "25",
            "Block": "216",
            "label": "GHSS(G)-ORATHANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16495",
            "district": "25",
            "Block": "345",
            "label": "GHS-ATHAMBAI (NORTH)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16496",
            "district": "25",
            "Block": "345",
            "label": "PUMS-ATHAMBAI(SOUTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16497",
            "district": "25",
            "Block": "345",
            "label": "PUMS-AKKARAIVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16498",
            "district": "25",
            "Block": "345",
            "label": "GHSS- ANANDAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16499",
            "district": "25",
            "Block": "345",
            "label": "PUMS-SOLAGANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16500",
            "district": "25",
            "Block": "345",
            "label": "GHS-SIVAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16501",
            "district": "25",
            "Block": "345",
            "label": "PUMS-KANDIYANATCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16502",
            "district": "25",
            "Block": "345",
            "label": "PUMS-PILLUVETTUVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16503",
            "district": "25",
            "Block": "345",
            "label": "GHSS-THIRUVONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16504",
            "district": "25",
            "Block": "345",
            "label": "NAVINA HS-PILLUVETTUVIDUTHUI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16505",
            "district": "25",
            "Block": "345",
            "label": "GHS-KAYAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16506",
            "district": "25",
            "Block": "345",
            "label": "GHS-KILAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16507",
            "district": "25",
            "Block": "345",
            "label": "PUMS-KOLLUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16508",
            "district": "25",
            "Block": "345",
            "label": "PUMS-NEYVELI(NORTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16509",
            "district": "25",
            "Block": "345",
            "label": "GHSS-NEYVELI(SOUTH)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16510",
            "district": "25",
            "Block": "345",
            "label": "JOTHI AIDED MS-URANIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16511",
            "district": "25",
            "Block": "345",
            "label": "GHSS-URANIPURAM(VETTUVAKOTTAI)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16512",
            "district": "25",
            "Block": "345",
            "label": "GHS-KATTATHI UNJIYAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16513",
            "district": "25",
            "Block": "345",
            "label": "GOVT MODEL  HR. SEC. SCHOOL -VENGARAI KOTTAIKKADU -614628",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16514",
            "district": "25",
            "Block": "345",
            "label": "GHSS-PINNAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16515",
            "district": "25",
            "Block": "345",
            "label": "RANI  MARIMUTHU MATRIC HS-PINNAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16516",
            "district": "25",
            "Block": "345",
            "label": "GHS-SANKARANATHAR KUDIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16517",
            "district": "25",
            "Block": "345",
            "label": "GHS-SENNIYAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16518",
            "district": "25",
            "Block": "345",
            "label": "GHS-SILLATHUR(NORTH)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16519",
            "district": "25",
            "Block": "345",
            "label": "GHS-THALIGAIVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16520",
            "district": "25",
            "Block": "345",
            "label": "PUMS-THIRUNALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16521",
            "district": "25",
            "Block": "345",
            "label": "PUMS-PATHUPULIVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16522",
            "district": "25",
            "Block": "345",
            "label": "PUMS-VENGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16523",
            "district": "25",
            "Block": "345",
            "label": "GHSS-VETTIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16524",
            "district": "25",
            "Block": "345",
            "label": "PUMS-VETTUVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16525",
            "district": "25",
            "Block": "144",
            "label": "Shri Balaji Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16526",
            "district": "25",
            "Block": "144",
            "label": "AIDED MS DEVANACHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16527",
            "district": "25",
            "Block": "144",
            "label": "MINERVA HSS KADICHAMPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16528",
            "district": "25",
            "Block": "144",
            "label": "PUMS KALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16529",
            "district": "25",
            "Block": "144",
            "label": "MADHA HIGHER  SECONDARY SCHOOL FOR THE DEAF, SAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16530",
            "district": "25",
            "Block": "144",
            "label": "GHSS NEERATHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16531",
            "district": "25",
            "Block": "144",
            "label": "DHANISHLOS SPL SCH MS-MP MANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16532",
            "district": "25",
            "Block": "144",
            "label": "ANNA GHSS-PATTEESWARAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16533",
            "district": "25",
            "Block": "144",
            "label": "GHSS-SUNDARAPERUMALKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16534",
            "district": "25",
            "Block": "144",
            "label": "AIDED MS - MADAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16535",
            "district": "25",
            "Block": "144",
            "label": "GHS - THIPPIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16536",
            "district": "25",
            "Block": "144",
            "label": "GHSS - THIRUPPURAMBIAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16537",
            "district": "25",
            "Block": "144",
            "label": "GHS - UDAIYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16538",
            "district": "25",
            "Block": "144",
            "label": "ADW MS - CHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16539",
            "district": "25",
            "Block": "144",
            "label": "GHSS - CHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16540",
            "district": "25",
            "Block": "144",
            "label": "VALLALAR AHSS - KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16541",
            "district": "25",
            "Block": "144",
            "label": "KAMARAJAR MPL HS - MELA CAUVERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16542",
            "district": "25",
            "Block": "144",
            "label": "NATIVE AHSS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16543",
            "district": "25",
            "Block": "144",
            "label": "GHSS GIRLS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16544",
            "district": "25",
            "Block": "144",
            "label": "AAGHSS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16545",
            "district": "25",
            "Block": "144",
            "label": "K.T. VEEDHI AIDED HS KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16546",
            "district": "25",
            "Block": "144",
            "label": "BANADURAI AHSS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16547",
            "district": "25",
            "Block": "144",
            "label": "ORIENTAL AHSS KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16548",
            "district": "25",
            "Block": "144",
            "label": "TOWN AHSS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16549",
            "district": "25",
            "Block": "144",
            "label": "LITTLE FLOWER HR SEC SCHOOL  KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16550",
            "district": "25",
            "Block": "144",
            "label": "RC MORNING STAR AHS KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16551",
            "district": "25",
            "Block": "144",
            "label": "ARR MPL HSS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16552",
            "district": "25",
            "Block": "144",
            "label": "RANI SUBBAIYYAR MPL MS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16553",
            "district": "25",
            "Block": "144",
            "label": "ST JOSEPH AHSS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16554",
            "district": "25",
            "Block": "144",
            "label": "AJC ENGLISH SCHOOL KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16555",
            "district": "25",
            "Block": "144",
            "label": "GHSS DARASURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16556",
            "district": "25",
            "Block": "144",
            "label": "JAYAM AIDED AMS-SHOLAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16557",
            "district": "25",
            "Block": "144",
            "label": "GHS - SHOLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16558",
            "district": "25",
            "Block": "144",
            "label": "AIDED MS SWAMIMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16559",
            "district": "25",
            "Block": "144",
            "label": "GHSS-SWAMIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16560",
            "district": "25",
            "Block": "228",
            "label": "GHS - ILUPPAKKORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16561",
            "district": "25",
            "Block": "228",
            "label": "GOVINDASAMY MOOPANAR HIGH SCHOOL - KABISTHALAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16562",
            "district": "25",
            "Block": "228",
            "label": "ST. GABRIELS GIRLS HIGHER SECONDARY SCHOOL PASUPATHI KOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16563",
            "district": "25",
            "Block": "228",
            "label": "SRIVIDYASRAM MATRICULATION SCHOOL, VADAKURANGADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16564",
            "district": "25",
            "Block": "228",
            "label": "GHS VANNIYADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16565",
            "district": "25",
            "Block": "228",
            "label": "KASSIMIA HIGHER SECONDARY SCHOOL, RAJAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16566",
            "district": "25",
            "Block": "228",
            "label": "HIDAYATHUN NISWAN GENERAL ARABIC GIRLS HIGHER SECONDARY SCHOOL-RAJAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16567",
            "district": "25",
            "Block": "228",
            "label": "GHS- RAMANUJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16568",
            "district": "25",
            "Block": "228",
            "label": "PUMS CHAKKARAPALLI (SOUTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16569",
            "district": "25",
            "Block": "228",
            "label": "GHS - CHAKKARAPPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16570",
            "district": "25",
            "Block": "228",
            "label": "GHS - VALKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16571",
            "district": "25",
            "Block": "228",
            "label": "PUMS - SOOLAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16572",
            "district": "25",
            "Block": "228",
            "label": "GHSS (GIRLS) - PAPANASAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16573",
            "district": "25",
            "Block": "228",
            "label": "ADW MS - KELA MANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16574",
            "district": "25",
            "Block": "228",
            "label": "GHSS - PULLAPUTHANKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16575",
            "district": "25",
            "Block": "228",
            "label": "GHS - PATTAVARTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16576",
            "district": "25",
            "Block": "228",
            "label": "THE HIGH SCHOOL - UMAYALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16577",
            "district": "25",
            "Block": "228",
            "label": "PUMS UMAPALAPADI KARUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16578",
            "district": "25",
            "Block": "228",
            "label": "SOWKATHUL ISLAM BMS HSS- VALUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16579",
            "district": "25",
            "Block": "228",
            "label": "GHSS - VEERAMANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16580",
            "district": "25",
            "Block": "228",
            "label": "PUMS - AYYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16581",
            "district": "25",
            "Block": "228",
            "label": "GHSS - AYYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16582",
            "district": "25",
            "Block": "228",
            "label": "GHSS - PAPANASAM (BOYS)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16583",
            "district": "25",
            "Block": "11",
            "label": "GHS - ANNAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16584",
            "district": "25",
            "Block": "11",
            "label": "GHS - ARUNTHAVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16585",
            "district": "25",
            "Block": "11",
            "label": "GHS - UTHAMARGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16586",
            "district": "25",
            "Block": "11",
            "label": "AIDED MS - KALANCHERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16587",
            "district": "25",
            "Block": "11",
            "label": "GHS - KAMBARNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16588",
            "district": "25",
            "Block": "11",
            "label": "EDT. HSS N.V. KUDIKKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16589",
            "district": "25",
            "Block": "11",
            "label": "GHSS -  SALIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16590",
            "district": "25",
            "Block": "11",
            "label": "GHSS - POONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16591",
            "district": "25",
            "Block": "11",
            "label": "GHSS - THIRUKARUKKAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16592",
            "district": "25",
            "Block": "11",
            "label": "RC AIDED HSS(G) - AMMAPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16593",
            "district": "25",
            "Block": "11",
            "label": "U.A.A.T HSS - AMMAPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16594",
            "district": "25",
            "Block": "11",
            "label": "GHS AMMAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16595",
            "district": "25",
            "Block": "11",
            "label": "GHSS - MELATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16596",
            "district": "25",
            "Block": "344",
            "label": "PUMS - ARIYACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16597",
            "district": "25",
            "Block": "344",
            "label": "ADWHS- NALLADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16598",
            "district": "25",
            "Block": "344",
            "label": "GHS - VANNAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16599",
            "district": "25",
            "Block": "344",
            "label": "ADWHS - THIRUMALAIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16600",
            "district": "25",
            "Block": "344",
            "label": "PUMS - MANJAMALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16601",
            "district": "25",
            "Block": "344",
            "label": "JAYAMKONDA MATHA AIDED MIDDLE SCHOOL-MATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16602",
            "district": "25",
            "Block": "344",
            "label": "GBHSS   - NACHIYARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16603",
            "district": "25",
            "Block": "344",
            "label": "S.PUDUR  HIGH SCHOOL  - S.PUDUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16604",
            "district": "25",
            "Block": "344",
            "label": "PUMS - MURUGANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16605",
            "district": "25",
            "Block": "344",
            "label": "RKR GHSS - THIRUCHERAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16606",
            "district": "25",
            "Block": "344",
            "label": "MATHAR MANRAM HS-THIRUMANGALAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16607",
            "district": "25",
            "Block": "344",
            "label": "GGHSS - NACHIYARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16608",
            "district": "25",
            "Block": "344",
            "label": "GHS-THUKKATCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16609",
            "district": "25",
            "Block": "344",
            "label": "GHSS- THIRUNAGESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16610",
            "district": "25",
            "Block": "344",
            "label": "AIDED  MIDDLE SCHOOL - ADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16611",
            "district": "25",
            "Block": "344",
            "label": "GHSS - ADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16612",
            "district": "25",
            "Block": "344",
            "label": "SRI KGS  HIGHER SECONDARY SCHOOL- ADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16613",
            "district": "25",
            "Block": "344",
            "label": "RICE CITY MATRIC. HIGHER SECONDARY SCHOOL - ADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16614",
            "district": "25",
            "Block": "344",
            "label": "THE CRESCENT HIGHER SECONDARY SCHOOL - AVANIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16615",
            "district": "25",
            "Block": "344",
            "label": "GHSS - THIRUBUVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16616",
            "district": "25",
            "Block": "344",
            "label": "THIRUVAVADUTHURAI ADHEENAM AMBALAVANA DESIGAR AIDED MIDDLE SCHOOL -THIRUVIDAIMARUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16617",
            "district": "25",
            "Block": "344",
            "label": "THIRUVAVADUTHURAI ADHEENAM HIGHER SECONDARY SCHOOL - THIRVIDAIMARUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16618",
            "district": "25",
            "Block": "344",
            "label": "ST  JOSEPHS  HIGH SCHOOL - AMMANPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16619",
            "district": "25",
            "Block": "330",
            "label": "GHS-ARALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16620",
            "district": "25",
            "Block": "330",
            "label": "RAMAKRISHNA MIDDDLE SCHOOL - KEELAKATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16621",
            "district": "25",
            "Block": "330",
            "label": "AMS  THATHUVANCHERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16622",
            "district": "25",
            "Block": "330",
            "label": "GHS-KONULAMPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16623",
            "district": "25",
            "Block": "330",
            "label": "GHS-KURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16624",
            "district": "25",
            "Block": "330",
            "label": "SARASWATHI MS-MULLANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16625",
            "district": "25",
            "Block": "330",
            "label": "PUMS-PANDHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16626",
            "district": "25",
            "Block": "330",
            "label": "GHSS (GIRLS) - PANDHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16627",
            "district": "25",
            "Block": "330",
            "label": "GHSS(B) -PANDHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16628",
            "district": "25",
            "Block": "330",
            "label": "SRI VENKATESWARA MATRIC  SCHOOL-PANDHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16629",
            "district": "25",
            "Block": "330",
            "label": "PUMS-VETTAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16630",
            "district": "25",
            "Block": "330",
            "label": "GHS-CHITRIDAIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16631",
            "district": "25",
            "Block": "330",
            "label": "KGS AIDED MS-THIRULOGI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16632",
            "district": "25",
            "Block": "330",
            "label": "GHS-KODIYALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16633",
            "district": "25",
            "Block": "330",
            "label": "PUMS - MOZHAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16634",
            "district": "25",
            "Block": "330",
            "label": "KG  AIDED MS - THUGILI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16635",
            "district": "25",
            "Block": "330",
            "label": "GHS - THIRUVAIPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16636",
            "district": "25",
            "Block": "330",
            "label": "GHSS ANAIKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16637",
            "district": "25",
            "Block": "330",
            "label": "SRI KAILAI SUBRAMAIYA DESIKA SWAMIGAL HIGHER SECONDARY SCHOOL-THIRUPPANANDAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16638",
            "district": "25",
            "Block": "234",
            "label": "GHS-ALADIKKUMULAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16639",
            "district": "25",
            "Block": "234",
            "label": "GHS-ENATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16640",
            "district": "25",
            "Block": "234",
            "label": "PUMS- ERIPURAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16641",
            "district": "25",
            "Block": "234",
            "label": "PUMS- ETTUPPULIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16642",
            "district": "25",
            "Block": "234",
            "label": "GHSS- KARAMBAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16643",
            "district": "25",
            "Block": "234",
            "label": "AMS-ST.MICHAEL - MUTHALCHERI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16644",
            "district": "25",
            "Block": "234",
            "label": "GHS-NAMBIVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16645",
            "district": "25",
            "Block": "234",
            "label": "GHSS-NATTUCHALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16646",
            "district": "25",
            "Block": "234",
            "label": "M.G.M- HIGH SCHOOL- VALAVANPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16647",
            "district": "25",
            "Block": "234",
            "label": "PUMS-PALLIKONDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16648",
            "district": "25",
            "Block": "234",
            "label": "LAUREL HIGHER SECONDARY SCHOOL - PALLIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16649",
            "district": "25",
            "Block": "234",
            "label": "PUMS- PANNAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16650",
            "district": "25",
            "Block": "234",
            "label": "PUMS- PARAKKALAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16651",
            "district": "25",
            "Block": "234",
            "label": "PUMS- PONNAVARAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16652",
            "district": "25",
            "Block": "234",
            "label": "GHSS-PUDUKOTTAI ULLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16653",
            "district": "25",
            "Block": "234",
            "label": "PUMS- KEEZHATHOTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16654",
            "district": "25",
            "Block": "234",
            "label": "GHSS- RAJAMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16655",
            "district": "25",
            "Block": "234",
            "label": "GHS- SENDANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16656",
            "district": "25",
            "Block": "234",
            "label": "ARUNODHAYA HSS-SENDANKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16657",
            "district": "25",
            "Block": "234",
            "label": "GHS-SOORAPPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16658",
            "district": "25",
            "Block": "234",
            "label": "MORDEN MATRIC HSS- SOORAPPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16659",
            "district": "25",
            "Block": "234",
            "label": "PUMS -T.MARAVAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16660",
            "district": "25",
            "Block": "234",
            "label": "GHS- T.MELAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16661",
            "district": "25",
            "Block": "234",
            "label": "GHSS- THAMBIKKOTTAI VADAKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16662",
            "district": "25",
            "Block": "234",
            "label": "PUMS- MANJAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16663",
            "district": "25",
            "Block": "234",
            "label": "GHS-SENAGAPADUTHANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16664",
            "district": "25",
            "Block": "234",
            "label": "GHSS- THAMARANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16665",
            "district": "25",
            "Block": "234",
            "label": "PUMS -THOKKALIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16666",
            "district": "25",
            "Block": "234",
            "label": "GHSS-THUVARANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16667",
            "district": "25",
            "Block": "234",
            "label": "ST JOSEPH'S HIGHER SECONDARY SCHOOL SANJAYA  NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16668",
            "district": "25",
            "Block": "234",
            "label": "BRINDHAVAN HIGHER SECONDARY SCHOOL- SUKKIRANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16669",
            "district": "25",
            "Block": "234",
            "label": "PUMS -VENDAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16670",
            "district": "25",
            "Block": "234",
            "label": "ST.FRANCIS XAVIER'S HIGH SCHOOL- ANAIKKADU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16671",
            "district": "25",
            "Block": "234",
            "label": "GHS -NADUVIKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16672",
            "district": "25",
            "Block": "234",
            "label": "GHSS- KAZHUKUPULIKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16673",
            "district": "25",
            "Block": "234",
            "label": "MPLMS-  KANDIYANTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16674",
            "district": "25",
            "Block": "234",
            "label": "MPLMS- PERUMALKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16675",
            "district": "25",
            "Block": "234",
            "label": "MPLMS-PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16676",
            "district": "25",
            "Block": "234",
            "label": "ADWHSS-PATTUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16677",
            "district": "25",
            "Block": "234",
            "label": "GOVERNMENT MODEL HIGHER SEC SCHOOL - PATTUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16678",
            "district": "25",
            "Block": "234",
            "label": "G(G)HSS-PATTUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16679",
            "district": "25",
            "Block": "234",
            "label": "ST. ISABEL GIRLS HIGHER SECONDARY SCHOOL - PATTUKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16680",
            "district": "25",
            "Block": "234",
            "label": "Rethinakumar Memorial Saradha Vidhyalaya Matriculation School, Sunnambukara Street, Pattukkottai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16681",
            "district": "25",
            "Block": "234",
            "label": "Mount Carmel Matriculation Higher Secondary School- Adhirampattinam Road, Pattukkottai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16682",
            "district": "25",
            "Block": "234",
            "label": "ST.THOMAS HIGHER SECONDARY SCHOOL  PATTUKKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16683",
            "district": "25",
            "Block": "234",
            "label": "PUMS- ADHIRAMPATTINAM NADUTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16684",
            "district": "25",
            "Block": "234",
            "label": "PUMS- ADHIRAMPATTINAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16685",
            "district": "25",
            "Block": "234",
            "label": "G(G)HSS-ADIRAMPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16686",
            "district": "25",
            "Block": "234",
            "label": "KHADIR  MOHIDEEN HSS BOYS ADIRAMPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16687",
            "district": "25",
            "Block": "234",
            "label": "KHADIR  MOHIDEEN HSS GIRLS-ADIRAMPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16688",
            "district": "25",
            "Block": "234",
            "label": "A.L MARTRICULATION SCHOOL- ADIRAMPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16689",
            "district": "25",
            "Block": "157",
            "label": "PUMS - ALAMPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16690",
            "district": "25",
            "Block": "157",
            "label": "GHS (GIRLS) - ALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16691",
            "district": "25",
            "Block": "157",
            "label": "GHSS - ALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16692",
            "district": "25",
            "Block": "157",
            "label": "GHS - ATHIVETTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16693",
            "district": "25",
            "Block": "157",
            "label": "GHS - KANYAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16694",
            "district": "25",
            "Block": "157",
            "label": "GHSS- KASANKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16695",
            "district": "25",
            "Block": "157",
            "label": "GHS - KEELAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16696",
            "district": "25",
            "Block": "157",
            "label": "PUMS - MANNANKADU NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16697",
            "district": "25",
            "Block": "157",
            "label": "GHS - MOOTHAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16698",
            "district": "25",
            "Block": "157",
            "label": "GHS - PERIYAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16699",
            "district": "25",
            "Block": "157",
            "label": "GHSS - SIRAMELKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16700",
            "district": "25",
            "Block": "157",
            "label": "SRI VENKATESWARA VIDHYA MANDHIR HR SEC SCHOOL, THALIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16701",
            "district": "25",
            "Block": "157",
            "label": "GHS - VATTAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16702",
            "district": "25",
            "Block": "157",
            "label": "PUMS - VEPPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16703",
            "district": "25",
            "Block": "157",
            "label": "GANDHI MATRIC HSS-VIKRAMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16704",
            "district": "25",
            "Block": "157",
            "label": "WESTERN MATRIC HS - VIKRAMAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16705",
            "district": "25",
            "Block": "157",
            "label": "PUMS - MADUKKUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16706",
            "district": "25",
            "Block": "157",
            "label": "GHSS(GIRLS) -MADUKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16707",
            "district": "25",
            "Block": "157",
            "label": "PUMS - MADUKKUR P.K. ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16708",
            "district": "25",
            "Block": "157",
            "label": "GHSS(BOYS) - MADUKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16709",
            "district": "25",
            "Block": "157",
            "label": "FATHIMA MARIAM - HSS - MADUKUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16710",
            "district": "25",
            "Block": "239",
            "label": "GHS - ENATHIKARAMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16711",
            "district": "25",
            "Block": "239",
            "label": "GHSS - EDAIYATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16712",
            "district": "25",
            "Block": "239",
            "label": "GHS - KALAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16713",
            "district": "25",
            "Block": "239",
            "label": "GHS - KONTRAIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16714",
            "district": "25",
            "Block": "239",
            "label": "GHS- KALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16715",
            "district": "25",
            "Block": "239",
            "label": "GHS - SITHUKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16716",
            "district": "25",
            "Block": "239",
            "label": "ADWHS -  KALLURANIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16717",
            "district": "25",
            "Block": "239",
            "label": "PUMS- KURUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16718",
            "district": "25",
            "Block": "239",
            "label": "PUMS -METTUVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16719",
            "district": "25",
            "Block": "239",
            "label": "INDIAN BANK EMPLOYEES ASSOCIATION  HIGH SCHOOL - KURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16720",
            "district": "25",
            "Block": "239",
            "label": "PUMS - PERIYATHERKUKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16721",
            "district": "25",
            "Block": "239",
            "label": "GHS - PAINGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16722",
            "district": "25",
            "Block": "239",
            "label": "PUMS - PALATHALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16723",
            "district": "25",
            "Block": "239",
            "label": "GHSS - AVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16724",
            "district": "25",
            "Block": "239",
            "label": "GHS - PINNAVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16725",
            "district": "25",
            "Block": "239",
            "label": "PUMS - POOVALUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16726",
            "district": "25",
            "Block": "239",
            "label": "ST.ANNE'S GIRLS HIGH SCHOOL - PUNALVASAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16727",
            "district": "25",
            "Block": "239",
            "label": "PUNITHA.AROKIYA.ANNAI AIDED HSS - PUNALVASAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16728",
            "district": "25",
            "Block": "239",
            "label": "DON BOSCO MATRIC  HR SEC SCHOOL - PUNALVASAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16729",
            "district": "25",
            "Block": "239",
            "label": "GHS - SENGAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16730",
            "district": "25",
            "Block": "239",
            "label": "GHS-SERUVAVIDUTHI NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16731",
            "district": "25",
            "Block": "239",
            "label": "PUMS -POKKANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16732",
            "district": "25",
            "Block": "239",
            "label": "GHSS -THIRUCHITRAMBALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16733",
            "district": "25",
            "Block": "239",
            "label": "SRI VENKATESHWARA VIDHYALAYA CBSE SCHOOL - PERAVURANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16734",
            "district": "25",
            "Block": "239",
            "label": "GHS - VATTADIKOTTAI NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16735",
            "district": "25",
            "Block": "239",
            "label": "GHSS GIRLS- PERAVURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16736",
            "district": "25",
            "Block": "239",
            "label": "GHSS BOYS - PERAVURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16737",
            "district": "25",
            "Block": "239",
            "label": "VR VEERAPPA MEMORIAL HR SEC SCHOOL - PERAVURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16738",
            "district": "25",
            "Block": "239",
            "label": "ST ANNES HIGH SCHOOL - ATHANUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16739",
            "district": "25",
            "Block": "287",
            "label": "GHS -RENDAMPULIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16740",
            "district": "25",
            "Block": "287",
            "label": "PUMS- KALANIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16741",
            "district": "25",
            "Block": "287",
            "label": "PUMS -KALYANARAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16742",
            "district": "25",
            "Block": "287",
            "label": "GHSS -KARISAVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16743",
            "district": "25",
            "Block": "287",
            "label": "GHSS- KURUVIKKARAMBAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16744",
            "district": "25",
            "Block": "287",
            "label": "GHSS- MANAKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16745",
            "district": "25",
            "Block": "287",
            "label": "PUMS -MARUNKAPPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16746",
            "district": "25",
            "Block": "287",
            "label": "GHS -MUTHUKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16747",
            "district": "25",
            "Block": "287",
            "label": "GHS -NADIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16748",
            "district": "25",
            "Block": "287",
            "label": "ADWHS -NADUVIKURCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16749",
            "district": "25",
            "Block": "287",
            "label": "GHSS -PALLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16750",
            "district": "25",
            "Block": "287",
            "label": "PUMS -MALLIPATTINAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16751",
            "district": "25",
            "Block": "287",
            "label": "PUMS -CHINNAMANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16752",
            "district": "25",
            "Block": "287",
            "label": "GHSS -MALLIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16753",
            "district": "25",
            "Block": "287",
            "label": "GHS- SETHUBAVACHATRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16754",
            "district": "25",
            "Block": "287",
            "label": "GHS -PUTHUTHERU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16755",
            "district": "25",
            "Block": "287",
            "label": "GHS UDAYANADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16756",
            "district": "25",
            "Block": "287",
            "label": "PUMS -MUDACHIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16757",
            "district": "25",
            "Block": "287",
            "label": "GHS -VILANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16758",
            "district": "25",
            "Block": "287",
            "label": "GHSS -PERUMAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20985",
            "district": "25",
            "Block": "313",
            "label": "CHOLA INTERNATIONAL -THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20986",
            "district": "25",
            "Block": "313",
            "label": "YAGAPPA INTERNATIONAL. SCHOOL - THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20987",
            "district": "25",
            "Block": "313",
            "label": "CHRIST INTERNATIONAL SCHOOL-NEELAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21033",
            "district": "25",
            "Block": "144",
            "label": "CHRIST MATRICULATION SCHOOL KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21101",
            "district": "25",
            "Block": "313",
            "label": "PONNAIYAH  RAMAJAYAM PUBLIC SCHOOL-SOORAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21102",
            "district": "25",
            "Block": "144",
            "label": "ARR SL VIDYASHRAAM  SENIOR SECONDARY SCHOOL -KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21103",
            "district": "25",
            "Block": "234",
            "label": "BRILLIANT -CBSE-PATTUKKOTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21104",
            "district": "25",
            "Block": "234",
            "label": "BON SECOURS PUBLIC SCHOOL -CBSE-PATTUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21330",
            "district": "25",
            "Block": "234",
            "label": "LAURAL SENIOR SEC. SCHOOL-PALLIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21475",
            "district": "25",
            "Block": "216",
            "label": "GHS - 34 KOVILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21476",
            "district": "25",
            "Block": "345",
            "label": "GHS - KARIYAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21477",
            "district": "25",
            "Block": "345",
            "label": "GHS - UNIJI VIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21478",
            "district": "25",
            "Block": "239",
            "label": "GHS - SORNAKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21539",
            "district": "25",
            "Block": "144",
            "label": "ST. XAVIER HIGH SCHOOL-KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21556",
            "district": "25",
            "Block": "228",
            "label": "ST. ANTONY S HIGH SCHOOL- PASUPATHIKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21610",
            "district": "25",
            "Block": "313",
            "label": "GANDHI MISSION INTERNATIONAL SCHOOL (CBSE)-MATHUR WEST",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21611",
            "district": "25",
            "Block": "144",
            "label": "KAVERY MATRICULATION SCHOOL -MELACAUVERY -KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21649",
            "district": "25",
            "Block": "157",
            "label": "SRI VINAYAKA PUBLIC SCHOOL -MOHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21765",
            "district": "25",
            "Block": "336",
            "label": "SRI SRINIVASA HIGH SCHOOL-THIRUVAIYARU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21766",
            "district": "25",
            "Block": "234",
            "label": "DHARMMABAL VEERAPPAN PUBLIC SCHOOL -POONAVARAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21861",
            "district": "25",
            "Block": "144",
            "label": "CHRIST THE KING (GIRLS) MAT HSS-KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21862",
            "district": "25",
            "Block": "216",
            "label": "SRI SRI GNAN MANDIR MATRIC  HIGH  SCHOOL-VADAKKUR SOUTH -ORATHANADU THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21863",
            "district": "25",
            "Block": "42",
            "label": "YAGAPPA INT RESI. SCHOOL -SENGIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21880",
            "district": "25",
            "Block": "313",
            "label": "RAMYA SATHIANATHAN SENIOR SECONDARY SCHOOL(CBSE)-ALAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21881",
            "district": "25",
            "Block": "313",
            "label": "KAMALA SUBRAMANIAM CBSE SCHOOL  -THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22222",
            "district": "25",
            "Block": "313",
            "label": "RADIANT INTERNATIONAL  SCHOOL CBSE -MADHAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22223",
            "district": "25",
            "Block": "42",
            "label": "SHEMFORD FUTURISTIC SCHOOL-BUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22224",
            "district": "25",
            "Block": "144",
            "label": "THAMARAI INTERNATIONAL SCHOOL-KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22225",
            "district": "25",
            "Block": "11",
            "label": "GHS IRUMPUTHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22226",
            "district": "25",
            "Block": "344",
            "label": "MAHARISHI VIDYA MANDIR CBSE SCHOOL, AMMACHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22227",
            "district": "25",
            "Block": "234",
            "label": "BRINDHAVAN PUBLIC SCHOOL- SUKKIRANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22228",
            "district": "25",
            "Block": "287",
            "label": "ROYAL PARK HIGH SCHOOL -NAVAKKOLLAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22229",
            "district": "25",
            "Block": "287",
            "label": "ATLANTIC SCHOOL-VEERIYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22575",
            "district": "25",
            "Block": "313",
            "label": "BLOSSOM BLUEBELLS ACADEMY -THIRUKKANURPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22576",
            "district": "25",
            "Block": "313",
            "label": "VELAMMAL BODHI CAMPUS  SCHOOL (CBSE)- NILAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22577",
            "district": "25",
            "Block": "216",
            "label": "SHANMUGAM MANDIR MATRIC HSS-PALAMPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22578",
            "district": "25",
            "Block": "216",
            "label": "SEETHARAM INTERNATIONAL  SCHOOL - PALAMPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22579",
            "district": "25",
            "Block": "144",
            "label": "GHS - KEELAKORUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22580",
            "district": "25",
            "Block": "144",
            "label": "EQUITAS GURUKUL MATRICULATION SCHOOL - KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22581",
            "district": "25",
            "Block": "239",
            "label": "DELTA INTERNATIONAL SCHOOL - KURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22582",
            "district": "25",
            "Block": "287",
            "label": "PIONEER SCHOOL-CHOKKANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22583",
            "district": "25",
            "Block": "287",
            "label": "GHS -KUPPATHEVAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22751",
            "district": "25",
            "Block": "330",
            "label": "GHS -KATHIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22916",
            "district": "25",
            "Block": "234",
            "label": "GHS -Adirampattinam Karaiyur Theru",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22917",
            "district": "25",
            "Block": "239",
            "label": "GHS- EDAIYATHI KONAR ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22978",
            "district": "25",
            "Block": "144",
            "label": "KARTHI VIDHYALAYA INTERNATIONAL SCHOOL-KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23139",
            "district": "25",
            "Block": "313",
            "label": "BISHOP DEVADASS AMBROSE VIDYALAYA -THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23249",
            "district": "25",
            "Block": "287",
            "label": "GHS SENTHALAIVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23326",
            "district": "25",
            "Block": "144",
            "label": "VELAMMAL BODHI CAMPUS- KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23330",
            "district": "25",
            "Block": "313",
            "label": "KALAIMAGAL VIDHYA MANDIR SCHOOL -THANJAVUR RURAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23559",
            "district": "25",
            "Block": "157",
            "label": "MAHATHMA GANDHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23560",
            "district": "25",
            "Block": "216",
            "label": "SENTAMIL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23561",
            "district": "25",
            "Block": "234",
            "label": "GOLDEN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23562",
            "district": "25",
            "Block": "234",
            "label": "MOUNT CARMEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23563",
            "district": "25",
            "Block": "313",
            "label": "Government Model School, Thanjavur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23564",
            "district": "25",
            "Block": "313",
            "label": "Thanjavur District Government Model School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23565",
            "district": "25",
            "Block": "345",
            "label": "NALDHAVAN MATRICULATION SCHOOL- URANIPURAM ",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23848",
            "district": "25",
            "Block": "144",
            "label": "PUMS KALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23849",
            "district": "25",
            "Block": "144",
            "label": "KARTHI VIDHYALAYA PUBLIC SCHOOL",
            "Sch_type": "Central GovtMiddle School"
        },
        {
            "key": "23896",
            "district": "25",
            "Block": "216",
            "label": "GHS-VELLUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23912",
            "district": "25",
            "Block": "234",
            "label": "N.R. PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23998",
            "district": "25",
            "Block": "313",
            "label": "HIDHAYATH MATRICULATION SCHOOL - VALLAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23999",
            "district": "25",
            "Block": "313",
            "label": "AMRITA VIDYALAYAM, THANJAVUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24018",
            "district": "25",
            "Block": "336",
            "label": "GHS VARAGOOR",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "26": [
        {
            "key": "753",
            "district": "26",
            "Block": "72",
            "label": "IDEAL MATRIC HIGHER SECONDARY SCHOOL, T.K.PET, GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1739",
            "district": "26",
            "Block": "72",
            "label": "SACRED HEART MHSS KAYYUNNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1775",
            "district": "26",
            "Block": "72",
            "label": "GOOD SHEPHERD MATRIC SCHOOL MASINAGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1838",
            "district": "26",
            "Block": "72",
            "label": "NILGIRI MATRICULATION HIGHER SECONDARY SCHOOL ERUMAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1873",
            "district": "26",
            "Block": "370",
            "label": "DR. KJG MEMORIAL MATRICULATION SCHOOL BALACOLA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2221",
            "district": "26",
            "Block": "72",
            "label": "TEWS MAT HR SEC SCHOOL PANDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2279",
            "district": "26",
            "Block": "72",
            "label": "ST THOMAS MATRIC HSS AYYANKOLLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3415",
            "district": "26",
            "Block": "370",
            "label": "VIVEKANANDA MATRICULATION HIGH SCHOOL M.PALADA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3462",
            "district": "26",
            "Block": "370",
            "label": "NILGIRIS MATRIC HR SEC SCHOOL , LOVEDALE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3486",
            "district": "26",
            "Block": "72",
            "label": "M S S MATRICULATION HIGH SCHOOL, UPPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3856",
            "district": "26",
            "Block": "370",
            "label": "PRIYADHARSHINI MATRIC SCHOOL, BIKKATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3945",
            "district": "26",
            "Block": "53",
            "label": "NILGIRI MATRICULATION SCHOOL, RKS, YELLANALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4005",
            "district": "26",
            "Block": "370",
            "label": "THE OXFORD CONTINENTAL MATRIC HS SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4028",
            "district": "26",
            "Block": "53",
            "label": "TIMBRE TOPS MATRICULATION SCHOOL, COONOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4256",
            "district": "26",
            "Block": "370",
            "label": "ANNAI SARADADEVI MATRICULATION HR. SEC. SCHOOL, ANIKORAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4284",
            "district": "26",
            "Block": "53",
            "label": "MMB MATRIC HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4292",
            "district": "26",
            "Block": "370",
            "label": "SRI RAMAKRISHNA MATRIC SCHOOL, NANJANAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4944",
            "district": "26",
            "Block": "53",
            "label": "HOLY ANGELS MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5396",
            "district": "26",
            "Block": "72",
            "label": "MTS MATRICULATION HIGH SCHOOL PADANTHORAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5670",
            "district": "26",
            "Block": "135",
            "label": "ALPHA G.K. MATRICULATION HIGHER SECONDARY SCHOOL ARAVENU KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6101",
            "district": "26",
            "Block": "53",
            "label": "CHRIST THE KING METRICULATION SCHOOL, KOLAKOMABAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6114",
            "district": "26",
            "Block": "72",
            "label": "ST ANTONYS MAT HSS, RAJAGOPALAPURAM, GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6257",
            "district": "26",
            "Block": "135",
            "label": "PUMS, KAIRBETA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6428",
            "district": "26",
            "Block": "135",
            "label": "HRM MEMORIAL MATRIC SCHOOL HITTAKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6604",
            "district": "26",
            "Block": "72",
            "label": "HOLY CROSS MATRIC HSS DEVARSHOLAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12388",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  ADASHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12389",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ODAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12390",
            "district": "26",
            "Block": "370",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL,  DODDABETTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12391",
            "district": "26",
            "Block": "370",
            "label": "HPF HS INDUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12392",
            "district": "26",
            "Block": "370",
            "label": "ST. ANTONY'S HIGH SCHOOL, LOVEDALE, OOTY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12393",
            "district": "26",
            "Block": "370",
            "label": "KENDRIYA VIDYALAYA , INDUNAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12394",
            "district": "26",
            "Block": "370",
            "label": "UNIQUE PUBLIC MATRICULATION SCHOOL, FERNHILL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12395",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  NANJANAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12396",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KILCOWHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12397",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELCOWHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12398",
            "district": "26",
            "Block": "370",
            "label": "THAKKARBAPA GURUKULAM AIDED MIDDLE SCHOOL NIRGACIMUND",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12399",
            "district": "26",
            "Block": "370",
            "label": "FRANCIS XAVIER AIDED MIDDLE SCHOOL, KURUTHUKULI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12400",
            "district": "26",
            "Block": "370",
            "label": "BRAESIDE  MATRICULATION SCHOOL, NANJANAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12401",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SHOLADA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12402",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  HULLATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12403",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, EKONI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12404",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, CHINNA COONOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12405",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, KADANADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12406",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, DHAVANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12407",
            "district": "26",
            "Block": "370",
            "label": "SATHYA SAI  MATRICULATION HR.SEC.SCHOOL, AGALAR, THUNERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12408",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, THORAIHATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12409",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ANIKORAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12410",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, THUNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12411",
            "district": "26",
            "Block": "370",
            "label": "GURUKULAM MATRICULATION SCHOOL, AGALAR, THUNERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12412",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, EBBANAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12413",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KENTHORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12414",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, T. MANIHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12415",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BACKIANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12416",
            "district": "26",
            "Block": "370",
            "label": "HOLY ANGEL MATRICULATION SCHOOL, THUMMANATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12417",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THUMMANATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12418",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, BILLICOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12419",
            "district": "26",
            "Block": "370",
            "label": "SRI GIRI EESWARA MATRICULATION SCHOOL KAGGUCHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12420",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  KAGGUCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12421",
            "district": "26",
            "Block": "370",
            "label": "MAHATMA GANDHI MATRIC.HR.SEC.SCHOOL, KAGGUCHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12422",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, KOOKALTHORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12423",
            "district": "26",
            "Block": "370",
            "label": "PUMS, B. MANIHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12424",
            "district": "26",
            "Block": "370",
            "label": "PUMS, DEVARSHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12425",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, KILOOR KOKKALADA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12426",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL,  NUNTHALA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12427",
            "district": "26",
            "Block": "370",
            "label": "GHSS KANNERIMANTHANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12428",
            "district": "26",
            "Block": "370",
            "label": "GHSS KATHADIMATTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12429",
            "district": "26",
            "Block": "370",
            "label": "SIVASAILAM CHAMRAJ HR.SEC. SCHOOL, CHAMRAJ ESTATE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12430",
            "district": "26",
            "Block": "370",
            "label": "GHSS KINNAKORAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12431",
            "district": "26",
            "Block": "370",
            "label": "PUMS , MELKUNDAH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12432",
            "district": "26",
            "Block": "370",
            "label": "GHS MULLIMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12433",
            "district": "26",
            "Block": "370",
            "label": "PUMS ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12434",
            "district": "26",
            "Block": "370",
            "label": "PUMS, MULLIGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12435",
            "district": "26",
            "Block": "370",
            "label": "ADW MS, THAKKARBABA NAGAR, EMERALD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12436",
            "district": "26",
            "Block": "370",
            "label": "GHS KALLAKORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12437",
            "district": "26",
            "Block": "370",
            "label": "GHSS EMERALD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12438",
            "district": "26",
            "Block": "370",
            "label": "LALITHA MATRIC HS ITHALAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12439",
            "district": "26",
            "Block": "370",
            "label": "GHSS ITHALAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12440",
            "district": "26",
            "Block": "370",
            "label": "NEW ERA MATRICULATION SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12441",
            "district": "26",
            "Block": "370",
            "label": "MMS, RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12442",
            "district": "26",
            "Block": "370",
            "label": "MMS,THALAIYATTUMUND",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12443",
            "district": "26",
            "Block": "370",
            "label": "SACRED HEART MS, BANDHISHOLA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12444",
            "district": "26",
            "Block": "370",
            "label": "CSI,  HOBART",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12445",
            "district": "26",
            "Block": "370",
            "label": "GHS FOR HEARING IMPAIRED OOTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12446",
            "district": "26",
            "Block": "370",
            "label": "MHS KODAPPAMUND",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12447",
            "district": "26",
            "Block": "370",
            "label": "ST.THERESAS HS FINGER POST OOTY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12448",
            "district": "26",
            "Block": "370",
            "label": "NAZARETH CONVENT HIGH SCHOOL AND JUNIOR COLLEGE, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12449",
            "district": "26",
            "Block": "370",
            "label": "WOODSIDE SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12450",
            "district": "26",
            "Block": "370",
            "label": "CHIRISTUS REX SR.SEC SCHOOL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12451",
            "district": "26",
            "Block": "370",
            "label": "BREEKS ALL INDIA SECONDARY SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12452",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL UDHAGAMANDALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12453",
            "district": "26",
            "Block": "370",
            "label": "SRI SHANTHI VIJAI GIRLS HSS -OOTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12454",
            "district": "26",
            "Block": "370",
            "label": "SACRED HEARTS HSS,OOTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12455",
            "district": "26",
            "Block": "370",
            "label": "BETHLEHEM (G) HSS OOTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12456",
            "district": "26",
            "Block": "370",
            "label": "CSIGELL MEM (G)HSS OOTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12457",
            "district": "26",
            "Block": "370",
            "label": "BREEKS MEMORIAL ANGLO  INDIAN HSS, OOTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12458",
            "district": "26",
            "Block": "370",
            "label": "ST.HILDAS (G) HSS OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12459",
            "district": "26",
            "Block": "370",
            "label": "JSS PUBLIC SCHOOL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12460",
            "district": "26",
            "Block": "370",
            "label": "GOOD SHEPHERED PUBLIC SCHOOL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12461",
            "district": "26",
            "Block": "370",
            "label": "HEBRON SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12462",
            "district": "26",
            "Block": "370",
            "label": "NIRMALA MATRICULATION SCHOOL, FINGERPOST",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12463",
            "district": "26",
            "Block": "370",
            "label": "MPL, (URUDU) MIDDLE SCHOOL GANDHINAGAR II",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12464",
            "district": "26",
            "Block": "370",
            "label": "ST.JOSEPHS HSS OOTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12465",
            "district": "26",
            "Block": "370",
            "label": "CSI (CMM) HR. SEC. SCHOOL, OOTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12466",
            "district": "26",
            "Block": "370",
            "label": "CRESENT CASTLE MATRIC HR.SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12467",
            "district": "26",
            "Block": "370",
            "label": "SRVS MATRICULATION SCHOOL,GREEN FIELD, OOTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12468",
            "district": "26",
            "Block": "370",
            "label": "CRESCENT CASTLE PUBLIC SCHOOL,OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12469",
            "district": "26",
            "Block": "370",
            "label": "PUMS, PYKARA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12470",
            "district": "26",
            "Block": "370",
            "label": "PUMS, T.R. BAZAAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12471",
            "district": "26",
            "Block": "370",
            "label": "ADW GHS HOOKER",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12472",
            "district": "26",
            "Block": "370",
            "label": "GHS NADUVATTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12473",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SHOLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12474",
            "district": "26",
            "Block": "370",
            "label": "PUMS, GLENMORGAN CAMP",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12475",
            "district": "26",
            "Block": "370",
            "label": "PUMS,  KILKUNDAH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12476",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT (GIRLS) HIGH SCHOOL MANJOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12477",
            "district": "26",
            "Block": "370",
            "label": "ALPHONSA SCHOOL, MANJOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12478",
            "district": "26",
            "Block": "370",
            "label": "GHSS MANJOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12479",
            "district": "26",
            "Block": "370",
            "label": "KUNDHA MATRIC SCHOOL,KOTRAKANDI, MANJOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12480",
            "district": "26",
            "Block": "370",
            "label": "GHS BIKKATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12481",
            "district": "26",
            "Block": "370",
            "label": "GHSS YEDAKAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12482",
            "district": "26",
            "Block": "53",
            "label": "PUMS BETTATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12483",
            "district": "26",
            "Block": "53",
            "label": "PUMS ELITHORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12484",
            "district": "26",
            "Block": "53",
            "label": "GHSS YEDAPPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12485",
            "district": "26",
            "Block": "53",
            "label": "PUMS HALAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12486",
            "district": "26",
            "Block": "53",
            "label": "PUMS THUTHURMATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12487",
            "district": "26",
            "Block": "53",
            "label": "PUMS MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12488",
            "district": "26",
            "Block": "53",
            "label": "PUMS ARIHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12489",
            "district": "26",
            "Block": "53",
            "label": "CSI MIDDLE SCHOOL, KOLACOMBAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12490",
            "district": "26",
            "Block": "53",
            "label": "GHS THUTHURMATTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12491",
            "district": "26",
            "Block": "53",
            "label": "GHS MANJACOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12492",
            "district": "26",
            "Block": "53",
            "label": "GHSS MELUR HOSATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12493",
            "district": "26",
            "Block": "53",
            "label": "GOVERNMENT (GIRLS) HIGH SCHOOL, HUBBATHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12494",
            "district": "26",
            "Block": "53",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL HUBBATHALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12495",
            "district": "26",
            "Block": "53",
            "label": "SRI KALAIMAGAL MATRICULATION SCHOOL, SOGATHTHORAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12496",
            "district": "26",
            "Block": "53",
            "label": "REACH MATRIC HSS COONOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12497",
            "district": "26",
            "Block": "53",
            "label": "MUNICIPAL MIDDLE SCHOOL BANDISHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12498",
            "district": "26",
            "Block": "53",
            "label": "MMS ATTADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12499",
            "district": "26",
            "Block": "53",
            "label": "MMS GANDHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12500",
            "district": "26",
            "Block": "53",
            "label": "CSI MS COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12501",
            "district": "26",
            "Block": "53",
            "label": "ST.JOSEPHS MATRICULATION SCHOOL, ALWARPET, COONOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12502",
            "district": "26",
            "Block": "53",
            "label": "SRI SARGURU TRIBAL HS, COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12503",
            "district": "26",
            "Block": "53",
            "label": "MOUNTAIN HOME HSS COONOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12504",
            "district": "26",
            "Block": "53",
            "label": "ARIGNAR ANNA GHSS,COONOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12505",
            "district": "26",
            "Block": "53",
            "label": "ST.ANTONY'S HR. SEC. SCHOOL, COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12506",
            "district": "26",
            "Block": "53",
            "label": "ST.MARYS (G) HSS COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12507",
            "district": "26",
            "Block": "53",
            "label": "SRI SHANTHI VIJAI GIRLS HR SEC SCHOOL COONOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12508",
            "district": "26",
            "Block": "53",
            "label": "STANES ANGLO INDIAN HSS, COONOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12509",
            "district": "26",
            "Block": "53",
            "label": "ST JOSEPHS BOYS ANGLO INDIAN  HSS, COONOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12510",
            "district": "26",
            "Block": "53",
            "label": "ST JOSEPHS CONVENT ANGLO INDIAN GIRLS HSS, COONOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12511",
            "district": "26",
            "Block": "53",
            "label": "PUMS GURRENCY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12512",
            "district": "26",
            "Block": "53",
            "label": "BRINDAVAN PUBLIC HSS WELLINGTON",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12513",
            "district": "26",
            "Block": "53",
            "label": "ST.JOSEPH AMS WELLINGTON COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12514",
            "district": "26",
            "Block": "53",
            "label": "CANTONMENT BOARD HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12515",
            "district": "26",
            "Block": "53",
            "label": "ST. JOSEPHS BOYS HIGH SCHOOL, WELLINGTON BARRACKS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12516",
            "district": "26",
            "Block": "53",
            "label": "HOLY INNOCENTS HS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12517",
            "district": "26",
            "Block": "53",
            "label": "KENDRIYA VIDYALAYA HSS WELLINGTON",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12518",
            "district": "26",
            "Block": "53",
            "label": "ST. ANN'S GIRLS HR. SEC. SCHOOL, ARUVANKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12519",
            "district": "26",
            "Block": "53",
            "label": "MRS. BULLMORE'S MATRICULATION SCHOOL, MOUNT PLEASANT,  COONOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12520",
            "district": "26",
            "Block": "53",
            "label": "ARMY HSS WELLINGTON",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12521",
            "district": "26",
            "Block": "53",
            "label": "CANTONMENT BOARD SECONDARY SCHOOL, CHINNA VANDISHOLAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12522",
            "district": "26",
            "Block": "53",
            "label": "PUMS SOGATHORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12523",
            "district": "26",
            "Block": "53",
            "label": "PUMS DENALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12524",
            "district": "26",
            "Block": "53",
            "label": "GHS YELLANALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12525",
            "district": "26",
            "Block": "53",
            "label": "ST.MICHAEL'S HS, SHANTHOOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12526",
            "district": "26",
            "Block": "53",
            "label": "LAIDLAW MEM HSS KETTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12527",
            "district": "26",
            "Block": "53",
            "label": "CSI HIGHER SECONDARY SCHOOL, KETTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12528",
            "district": "26",
            "Block": "53",
            "label": "THE LAWRENCE SCHOOL, LOVEDALE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12529",
            "district": "26",
            "Block": "53",
            "label": "GHS HULICAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12530",
            "district": "26",
            "Block": "53",
            "label": "SIRUMALAR HIGH SCHOOL, SELAS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12531",
            "district": "26",
            "Block": "53",
            "label": "CSI HIGH SCHOOL, NONSUCH",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12532",
            "district": "26",
            "Block": "53",
            "label": "PUMS KODERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12533",
            "district": "26",
            "Block": "53",
            "label": "PUMS THAMBATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12534",
            "district": "26",
            "Block": "53",
            "label": "PUMS KOLLIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12535",
            "district": "26",
            "Block": "53",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ADHIGARATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12536",
            "district": "26",
            "Block": "53",
            "label": "N S IYAH MEMORIAL HSS-KATARY DAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12537",
            "district": "26",
            "Block": "53",
            "label": "SRI KNM MATRICULATION SCHOOL, KETTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12538",
            "district": "26",
            "Block": "53",
            "label": "PUMS JEGATHALA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12539",
            "district": "26",
            "Block": "53",
            "label": "KENDRIYA VIDYALAYA HSS ARUVANKADU",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12540",
            "district": "26",
            "Block": "53",
            "label": "DEFENCE EMPLOYEES MATRICULATION SCHOOL, ARUVANKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12541",
            "district": "26",
            "Block": "53",
            "label": "CF HSS ARUVANKADU",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12542",
            "district": "26",
            "Block": "135",
            "label": "GTR MS HALLIMOYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12543",
            "district": "26",
            "Block": "135",
            "label": "GHS THENGUMARAHADA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12544",
            "district": "26",
            "Block": "135",
            "label": "PUMS, KERADAMATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12545",
            "district": "26",
            "Block": "135",
            "label": "GHS, MILIDHANE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12546",
            "district": "26",
            "Block": "135",
            "label": "GHSS NEDUGULA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12547",
            "district": "26",
            "Block": "135",
            "label": "HILLFORT MATRICULATION & HIGHER SECONDARY SCHOOL, KEIRCOMBAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12548",
            "district": "26",
            "Block": "135",
            "label": "GOVERNMENT HIGH SCHOOL, KAIRCOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12549",
            "district": "26",
            "Block": "135",
            "label": "PUMS KADASOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12550",
            "district": "26",
            "Block": "135",
            "label": "GHS SHOLURMATTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12551",
            "district": "26",
            "Block": "135",
            "label": "ST.ANTONY'S MATRICULATION SCHOOL, SHOLURMATTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12552",
            "district": "26",
            "Block": "135",
            "label": "GHSS, KIL-KOTAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12553",
            "district": "26",
            "Block": "135",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGH SCHOOL, KARIKKAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12554",
            "district": "26",
            "Block": "135",
            "label": "GHS, KENGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12555",
            "district": "26",
            "Block": "135",
            "label": "GHS KONAVAKORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12556",
            "district": "26",
            "Block": "135",
            "label": "PUMS, GUNDADA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12557",
            "district": "26",
            "Block": "135",
            "label": "GHS, KATTABETU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12558",
            "district": "26",
            "Block": "135",
            "label": "PUMS, SACKATHA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12559",
            "district": "26",
            "Block": "135",
            "label": "GTR MS, ARAVENU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12560",
            "district": "26",
            "Block": "135",
            "label": "GHS, KOTTACOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12561",
            "district": "26",
            "Block": "135",
            "label": "GHS ARAVENU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12562",
            "district": "26",
            "Block": "135",
            "label": "GTR HSS KUNJAPPANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12563",
            "district": "26",
            "Block": "135",
            "label": "PUMS, HORASHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12564",
            "district": "26",
            "Block": "135",
            "label": "ST.ANTONY'S MS, KOTAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12565",
            "district": "26",
            "Block": "135",
            "label": "NALLAYAN MS, KOTAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12566",
            "district": "26",
            "Block": "135",
            "label": "GHS, KANNERIMUKU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12567",
            "district": "26",
            "Block": "135",
            "label": "ST.MARY'S HS, KOTAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12568",
            "district": "26",
            "Block": "135",
            "label": "VISWASHANTHI VIDYALAYA MATRIC SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12569",
            "district": "26",
            "Block": "135",
            "label": "GHSS KOTAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12570",
            "district": "26",
            "Block": "135",
            "label": "CSI HSS KOTAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12571",
            "district": "26",
            "Block": "135",
            "label": "ST.MARYS GIRLS HSS KOTAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12572",
            "district": "26",
            "Block": "135",
            "label": "ST.JUDES PUBLIC SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12573",
            "district": "26",
            "Block": "135",
            "label": "KOTAGIRI PUBLIC SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12574",
            "district": "26",
            "Block": "135",
            "label": "PANDIARAJ MEMORIAL MATRIC HIGHER SECONDARY SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12575",
            "district": "26",
            "Block": "135",
            "label": "GREEN VALLEY MATRICULATION SCHOOL KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12576",
            "district": "26",
            "Block": "135",
            "label": "ST.MARY'S HOME MAT.SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12577",
            "district": "26",
            "Block": "135",
            "label": "RIVERSIDE PUBLIC SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12578",
            "district": "26",
            "Block": "135",
            "label": "SATHIYAKAATHI GLOBAL SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12579",
            "district": "26",
            "Block": "72",
            "label": "GTRMS MUKKATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12580",
            "district": "26",
            "Block": "72",
            "label": "PUMS MAYFIELD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12581",
            "district": "26",
            "Block": "72",
            "label": "PUMS VILLANGORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12582",
            "district": "26",
            "Block": "72",
            "label": "PUMS MUTHIRAKOLLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12583",
            "district": "26",
            "Block": "72",
            "label": "PUMS PATTAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12584",
            "district": "26",
            "Block": "72",
            "label": "PUMS PEARL DIVISION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12585",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL, PAKKANA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12586",
            "district": "26",
            "Block": "72",
            "label": "GHS NELAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12587",
            "district": "26",
            "Block": "72",
            "label": "GHSS BITHERKAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12588",
            "district": "26",
            "Block": "72",
            "label": "GHS KUNTHALADY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12589",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGHER SECONDARY SCHOOL KARGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12590",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SREEMADURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12591",
            "district": "26",
            "Block": "72",
            "label": "ST SEBASTIAN MAT HS,MANVAYAL, SREEMADURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12592",
            "district": "26",
            "Block": "72",
            "label": "PUMS MARADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12593",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL MANNATHIVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12594",
            "district": "26",
            "Block": "72",
            "label": "PUMS KAKKUNDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12595",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT (GIRLS) HIGH SCHOOL ERUMAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12596",
            "district": "26",
            "Block": "72",
            "label": "GHS KAYYUNNI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12597",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  ERUMAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12598",
            "district": "26",
            "Block": "72",
            "label": "GTRMS AYYANKOLLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12599",
            "district": "26",
            "Block": "72",
            "label": "GHSS AMBALAMOOLA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12600",
            "district": "26",
            "Block": "72",
            "label": "GHS AMBALAVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12601",
            "district": "26",
            "Block": "72",
            "label": "PUMS CHERAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12602",
            "district": "26",
            "Block": "72",
            "label": "PUMS NAICKENSHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12603",
            "district": "26",
            "Block": "72",
            "label": "PUMS KARAKOLLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12604",
            "district": "26",
            "Block": "72",
            "label": "GTRMS KAPPALA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12605",
            "district": "26",
            "Block": "72",
            "label": "GHSS CHERAMBADY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12606",
            "district": "26",
            "Block": "72",
            "label": "GHSS KOLAPALLY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12607",
            "district": "26",
            "Block": "72",
            "label": "PUMS CHERANGODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12608",
            "district": "26",
            "Block": "72",
            "label": "PUMS MAYOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12609",
            "district": "26",
            "Block": "72",
            "label": "GTRMS MAVANALLA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12610",
            "district": "26",
            "Block": "72",
            "label": "GRG MEMORIAL HR SEC SCHOOL, VAZHAITHOTTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12611",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MASINAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12612",
            "district": "26",
            "Block": "72",
            "label": "SRI SHANTHI VIJAI HSS MASINAGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12613",
            "district": "26",
            "Block": "72",
            "label": "PUMS KOZHIPALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12614",
            "district": "26",
            "Block": "72",
            "label": "PUMS NANTHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12615",
            "district": "26",
            "Block": "72",
            "label": "PUMS ATHIPALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12616",
            "district": "26",
            "Block": "72",
            "label": "PUMS FIRSTMILE GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12617",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL, PUTHURVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12618",
            "district": "26",
            "Block": "72",
            "label": "PUMS VANDIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12619",
            "district": "26",
            "Block": "72",
            "label": "GTRMS THORAPALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12620",
            "district": "26",
            "Block": "72",
            "label": "ST. MARY'S HIGH SCHOOL UPPER GUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12621",
            "district": "26",
            "Block": "72",
            "label": "SRI RAMAKRISHNA MATRIC HS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12622",
            "district": "26",
            "Block": "72",
            "label": "GTMO HR SEC SCHOOL GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12623",
            "district": "26",
            "Block": "72",
            "label": "MORNING STAR HSS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12624",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL GUDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12625",
            "district": "26",
            "Block": "72",
            "label": "FATIMA GIRLS HIGHER SECONDARY SCHOOL , GUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12626",
            "district": "26",
            "Block": "72",
            "label": "ST THOMAS HSS MT NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12627",
            "district": "26",
            "Block": "72",
            "label": "BHARATH COMMUNITY MATRIC HSS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12628",
            "district": "26",
            "Block": "72",
            "label": "KALAIVANI MAT HSS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12629",
            "district": "26",
            "Block": "72",
            "label": "GTMO MATRIC HSS CHEMBALA, GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12630",
            "district": "26",
            "Block": "72",
            "label": "ST THOMAS ENG HS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12631",
            "district": "26",
            "Block": "72",
            "label": "BRINDAVAN MATRICULATION SCHOOL, ATHIPALY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12632",
            "district": "26",
            "Block": "72",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Kozhikolly",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12633",
            "district": "26",
            "Block": "72",
            "label": "PUMS BHARATHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12634",
            "district": "26",
            "Block": "72",
            "label": "PUMS GANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12635",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL CHOONDY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12636",
            "district": "26",
            "Block": "72",
            "label": "PUMS PERIASHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12637",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL DHARMAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12638",
            "district": "26",
            "Block": "72",
            "label": "PUMS NEWHOPE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12639",
            "district": "26",
            "Block": "72",
            "label": "GHSS OVALLEY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12640",
            "district": "26",
            "Block": "72",
            "label": "NOORUL HUDHA HS YELLAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12641",
            "district": "26",
            "Block": "72",
            "label": "GHS AROTTUPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12642",
            "district": "26",
            "Block": "72",
            "label": "PUMS DEVAN 2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12643",
            "district": "26",
            "Block": "72",
            "label": "GHSS DEVARSHOLA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12644",
            "district": "26",
            "Block": "72",
            "label": "AL HIDHAYA MATRIC SCHOOL, OTTUVAYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12645",
            "district": "26",
            "Block": "72",
            "label": "AL-AMEEN MATRICULATION SCHOOL,  DEVARSHOLAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12646",
            "district": "26",
            "Block": "72",
            "label": "GHS KOTTAMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12647",
            "district": "26",
            "Block": "72",
            "label": "GTRMS PONNENI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12648",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL PULIYAMBARA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12649",
            "district": "26",
            "Block": "72",
            "label": "PUMS UPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12650",
            "district": "26",
            "Block": "72",
            "label": "GHS ATHIKUNNA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12651",
            "district": "26",
            "Block": "72",
            "label": "BHARATH MATHA HSS UPPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12652",
            "district": "26",
            "Block": "72",
            "label": "ST FRANCIS XAVIER(G) HS PANDALUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12653",
            "district": "26",
            "Block": "72",
            "label": "GHSS PANDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12654",
            "district": "26",
            "Block": "72",
            "label": "GHS PADANTHORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12655",
            "district": "26",
            "Block": "72",
            "label": "CSI HIGH SCHOOL, PADANTHORAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12656",
            "district": "26",
            "Block": "72",
            "label": "PUMS DEVALA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12657",
            "district": "26",
            "Block": "72",
            "label": "PUMS DEVALAHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12658",
            "district": "26",
            "Block": "72",
            "label": "PUMS VAZHAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12659",
            "district": "26",
            "Block": "72",
            "label": "GHSS DEVALA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12660",
            "district": "26",
            "Block": "72",
            "label": "HOLY CROSS CONVENT MATRIC DEVALA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20966",
            "district": "26",
            "Block": "53",
            "label": "ST.MICHAEL S MATRIC SCHOOL, SHANTHOOR, KETTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20967",
            "district": "26",
            "Block": "72",
            "label": "SREE SARASWATHI VIVEKANANDA  MAHA VIDHYALAYA MATRIC AYYANKOLLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21220",
            "district": "26",
            "Block": "135",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  ELADA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21470",
            "district": "26",
            "Block": "370",
            "label": "GHS SHOLUR KOTHAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21597",
            "district": "26",
            "Block": "72",
            "label": "IMS MATRICULATION HIGH SCHOOL PAKKANA, GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21864",
            "district": "26",
            "Block": "370",
            "label": "NIZAM BRITISH INTERNATIONAL SCHOOL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21865",
            "district": "26",
            "Block": "370",
            "label": "THE BLUEMOUNTAINS SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21866",
            "district": "26",
            "Block": "72",
            "label": "GLOBAL MATRICULATION SCHOOL DEVALA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21867",
            "district": "26",
            "Block": "135",
            "label": "INTERNATIONAL COMMUNITY SCHOOL KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22132",
            "district": "26",
            "Block": "370",
            "label": "EKALAVYA MODEL RESIDENTIAL HIGHER SECONDARY SCHOOL M.PALADA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22133",
            "district": "26",
            "Block": "72",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Vattakolly",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22471",
            "district": "26",
            "Block": "53",
            "label": "ST. JOSEPH'S ACADEMY, COONOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22472",
            "district": "26",
            "Block": "53",
            "label": "UDHAVI, CENTRE FOR CWSN ALWARPET COONOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22473",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL PONNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22746",
            "district": "26",
            "Block": "135",
            "label": "BEST PUBLIC SCHOOL, KADAKODU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22990",
            "district": "26",
            "Block": "72",
            "label": "GHS KARIYASHOLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22991",
            "district": "26",
            "Block": "135",
            "label": "GOVERNMENT HIGH SCHOOL, SULLIGUDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23046",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL, DEVALA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23164",
            "district": "26",
            "Block": "370",
            "label": "THE OXFORD CONTINENTAL SCHOOL ADASHOLA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23170",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL BOKKAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23566",
            "district": "26",
            "Block": "53",
            "label": "Government Model School, Nilgiris",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23567",
            "district": "26",
            "Block": "53",
            "label": "NILGIRIS DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23761",
            "district": "26",
            "Block": "53",
            "label": "PUMS MELUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23762",
            "district": "26",
            "Block": "53",
            "label": "NILGIRIS DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23763",
            "district": "26",
            "Block": "53",
            "label": "ST JOSEPH'S CONVENT SCHOOLS",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23784",
            "district": "26",
            "Block": "72",
            "label": "PUMS GANDHI NAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24047",
            "district": "26",
            "Block": "370",
            "label": "SRI SHANTHI VIJAI MATRICULATION SCHOOL, OOTY",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "27": [
        {
            "key": "460",
            "district": "27",
            "Block": "319",
            "label": "MARY MATHA MATRIC HIGHER SECONDARY SCHOOL - THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "622",
            "district": "27",
            "Block": "319",
            "label": "T.M.H.N.U. MATRIC HSS - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "642",
            "district": "27",
            "Block": "241",
            "label": "EDWARD MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "681",
            "district": "27",
            "Block": "241",
            "label": "SHRI RENUGA VIDYALAYAM MATRICULATION HR SEC SCHOOL - LAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "764",
            "district": "27",
            "Block": "49",
            "label": "MPL MS, MAIN STREET - CHINNAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "768",
            "district": "27",
            "Block": "31",
            "label": "S.K.A. MAT HSS, AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "794",
            "district": "27",
            "Block": "40",
            "label": "PANKAJAM MIDDLE SCHOOL -  BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "845",
            "district": "27",
            "Block": "241",
            "label": "VICTORY MATRIC HS - GANDHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "880",
            "district": "27",
            "Block": "49",
            "label": "GAYATHRI MATRIC HSS - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "909",
            "district": "27",
            "Block": "40",
            "label": "SOWNDEESWARI MIDDLE SCHOOL - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "912",
            "district": "27",
            "Block": "241",
            "label": "TRIUMPH  MS -  THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "927",
            "district": "27",
            "Block": "49",
            "label": "NALLI MATRIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1003",
            "district": "27",
            "Block": "241",
            "label": "HINDU MS - DEVADANAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1016",
            "district": "27",
            "Block": "194",
            "label": "PUMS - PONNAMMALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1054",
            "district": "27",
            "Block": "194",
            "label": "PUMS - KOVILPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1111",
            "district": "27",
            "Block": "194",
            "label": "PUMS - KAMANKALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1141",
            "district": "27",
            "Block": "194",
            "label": "PUMS - VALIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1305",
            "district": "27",
            "Block": "319",
            "label": "T.K.S. MATRIC HR. SEC. SCHOOL - THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1311",
            "district": "27",
            "Block": "241",
            "label": "PUMS - E.PUTHUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1346",
            "district": "27",
            "Block": "319",
            "label": "BAGYA MATRICULATION SCHOOL - ALLINAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1378",
            "district": "27",
            "Block": "241",
            "label": "SRI VALLI VARATHARAJ MAT HSS  - VARATHARAJ NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1397",
            "district": "27",
            "Block": "194",
            "label": "PUMS -  SOLAITHEVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1409",
            "district": "27",
            "Block": "319",
            "label": "BHARATHI VIDYA MANTHIR MATRIC HSS - NAGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1415",
            "district": "27",
            "Block": "49",
            "label": "PUMS - MUTHULAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1430",
            "district": "27",
            "Block": "319",
            "label": "MADURAI CEOA MATRICULATION  HR SEC SCHOOL -  PALANICHETTIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1433",
            "district": "27",
            "Block": "49",
            "label": "PUMS - AYYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1438",
            "district": "27",
            "Block": "241",
            "label": "ST.ANNES JC MATRICULATION HR. SEC.  SCHOOL -  PERIYAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1439",
            "district": "27",
            "Block": "55",
            "label": "NALANDHA MATRIC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1474",
            "district": "27",
            "Block": "319",
            "label": "SANTINIKETAN MATRIC HSS - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1487",
            "district": "27",
            "Block": "49",
            "label": "PUMS - THENPALANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1514",
            "district": "27",
            "Block": "319",
            "label": "PUMS - MUTHUDEVANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1523",
            "district": "27",
            "Block": "319",
            "label": "HINDU MS - SEELAYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1533",
            "district": "27",
            "Block": "375",
            "label": "SRI KANNIKA PARAMESWARI MIDDLE SCHOOL - KOMBAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1547",
            "district": "27",
            "Block": "40",
            "label": "PITCHANDI MIDDLE SCHOOL -  BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1565",
            "district": "27",
            "Block": "31",
            "label": "PADMA RAMASAMY MAT HSS, S.RENGANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1627",
            "district": "27",
            "Block": "49",
            "label": "GOODSAM MATRIC HSS - SUKKANGALPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1633",
            "district": "27",
            "Block": "31",
            "label": "PUMS - VARATHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1641",
            "district": "27",
            "Block": "241",
            "label": "PUMS -  D.VADIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1662",
            "district": "27",
            "Block": "40",
            "label": "T.V.MS - B. RENGANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1669",
            "district": "27",
            "Block": "241",
            "label": "PUMS - A.MEENAKSHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1709",
            "district": "27",
            "Block": "194",
            "label": "PUMS - UPPUTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1720",
            "district": "27",
            "Block": "194",
            "label": "PUMS - SIRAPPARAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1732",
            "district": "27",
            "Block": "375",
            "label": "PUMS - THEVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1733",
            "district": "27",
            "Block": "319",
            "label": "PUMS - NAGALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1735",
            "district": "27",
            "Block": "31",
            "label": "PUMS - VANDIMOOPANPATTI @ PALAYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1763",
            "district": "27",
            "Block": "319",
            "label": "PUMS - THADICHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1779",
            "district": "27",
            "Block": "55",
            "label": "RAM JAYAM VIDYAA MANDIR MATRIC HR SEC SCHOOL -  CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1785",
            "district": "27",
            "Block": "55",
            "label": "NAGAMANI AMMAL MEMORIAL MATRIC HR SEC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1832",
            "district": "27",
            "Block": "375",
            "label": "THE CRESCENT MATRIC HIGHER SECONDARY SCHOOL - UTHAMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1871",
            "district": "27",
            "Block": "194",
            "label": "PUMS - GANESHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1922",
            "district": "27",
            "Block": "49",
            "label": "ST. FRANCIS ASSISI MATRIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1990",
            "district": "27",
            "Block": "319",
            "label": "VELAMMAL MATRICULATION HR. SEC. SCHOOL, MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1991",
            "district": "27",
            "Block": "319",
            "label": "PAALAM MATRIC HR. SEC.  SCHOOL - UNJAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2016",
            "district": "27",
            "Block": "319",
            "label": "PUMS - ARANMANAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2132",
            "district": "27",
            "Block": "40",
            "label": "PUMS-SOOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2142",
            "district": "27",
            "Block": "55",
            "label": "SRI.SAKTHI.VINAYAKAR MATRIC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2143",
            "district": "27",
            "Block": "49",
            "label": "MPL MS, EAST STREET -  CHINNAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2175",
            "district": "27",
            "Block": "375",
            "label": "BENEDICT MATRIC HIGHER SECONDARY SCHOOL - ANAIMALAYANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2212",
            "district": "27",
            "Block": "319",
            "label": "GOVT MS - THAPPUKUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2249",
            "district": "27",
            "Block": "40",
            "label": "PUMS -  KUNDALNAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2257",
            "district": "27",
            "Block": "49",
            "label": "PUMS - T.PUDHUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2303",
            "district": "27",
            "Block": "49",
            "label": "THAI MATRIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2380",
            "district": "27",
            "Block": "40",
            "label": "MOOVENDAR MIDDLE SCHOOL - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2396",
            "district": "27",
            "Block": "375",
            "label": "SRI VIKASA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL - UTHAMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2400",
            "district": "27",
            "Block": "55",
            "label": "ANNS MATRIC HR. SEC. SCHOOL, CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2414",
            "district": "27",
            "Block": "375",
            "label": "PUMS - GANAMMAN KOVIL STREET, UTHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2445",
            "district": "27",
            "Block": "319",
            "label": "S.B.KALAIMAGAL MS - KUPPINAYAKANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2447",
            "district": "27",
            "Block": "49",
            "label": "MPL MS, KARUNKATTANKULAM - CHINNAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2452",
            "district": "27",
            "Block": "375",
            "label": "SRI ONNU RAMASWAMY MIDDLE SCHOOL - THEVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2542",
            "district": "27",
            "Block": "241",
            "label": "V.K.S.MEMORIAL MS, SEDAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2545",
            "district": "27",
            "Block": "40",
            "label": "PUMS-B.DHARMATHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2553",
            "district": "27",
            "Block": "55",
            "label": "SRI MUKTHI VINAYAGAR MS - CUMBUM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2601",
            "district": "27",
            "Block": "55",
            "label": "BROOKES MATRIC SCHOOL - HIGHWAVYS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2647",
            "district": "27",
            "Block": "375",
            "label": "VIVEKANANDA VIDHYALAYAM MIDDLE SCHOOL - THEVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2667",
            "district": "27",
            "Block": "40",
            "label": "S.K.L.MS - KODANGIPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2681",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, K.LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2697",
            "district": "27",
            "Block": "319",
            "label": "PUMS - KATTUNAYAKKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2707",
            "district": "27",
            "Block": "31",
            "label": "GKMS - KATHIRNARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2757",
            "district": "27",
            "Block": "49",
            "label": "ECN MATRIC SCHOOL - ODAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2768",
            "district": "27",
            "Block": "375",
            "label": "SRI ARAVINDAR BALAR MATRIC HIGHER SECONDARY SCHOOL- UTHAMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2846",
            "district": "27",
            "Block": "319",
            "label": "GGMS - VAYALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2850",
            "district": "27",
            "Block": "49",
            "label": "C.N.M.S. SIVAKAMI MATRIC HSS - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2860",
            "district": "27",
            "Block": "319",
            "label": "SRI PADMANAPAN  MS - ALLINAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2885",
            "district": "27",
            "Block": "40",
            "label": "Z.K.M. MAT HSS - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2911",
            "district": "27",
            "Block": "49",
            "label": "PUMS - THIMMINAYAKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2941",
            "district": "27",
            "Block": "375",
            "label": "Sri N.M.R. SUBBARAMAN MIDDLE SCHOOL - KOHILAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2943",
            "district": "27",
            "Block": "40",
            "label": "THIRUPPATHI.MS - DOMBUCHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2951",
            "district": "27",
            "Block": "319",
            "label": "GKMS - KARUVELNAYAKKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2959",
            "district": "27",
            "Block": "375",
            "label": "PUMS - ANAMALAYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2982",
            "district": "27",
            "Block": "49",
            "label": "PUMS - CHINNAPOTTIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2992",
            "district": "27",
            "Block": "319",
            "label": "PUMS -AMBASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3006",
            "district": "27",
            "Block": "319",
            "label": "AMARAVATHI MS - THIRUCHENDUR, KODANGIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3026",
            "district": "27",
            "Block": "40",
            "label": "PUM S- VALAIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3048",
            "district": "27",
            "Block": "40",
            "label": "15TH WARD MPL MS - BODI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3053",
            "district": "27",
            "Block": "241",
            "label": "PUMS -  NALLAKARUPPANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3064",
            "district": "27",
            "Block": "319",
            "label": "PUMS - UPPARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3067",
            "district": "27",
            "Block": "375",
            "label": "T.H.N.U. MATRICULATION HIGHER SECONDARY SCHOOL - THEVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3086",
            "district": "27",
            "Block": "241",
            "label": "ST.PATRICKS R.C. MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3100",
            "district": "27",
            "Block": "55",
            "label": "GOVT KALLAR HS, NARAYANATHEVANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "3102",
            "district": "27",
            "Block": "49",
            "label": "V.U.ANNA MS - CHINNAMANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3105",
            "district": "27",
            "Block": "375",
            "label": "PUMS - BAZZAR, UTHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3117",
            "district": "27",
            "Block": "319",
            "label": "N.P.V MS - POOMALAIKUNDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3137",
            "district": "27",
            "Block": "194",
            "label": "PUMS - SINGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3144",
            "district": "27",
            "Block": "194",
            "label": "PUMS - THOPPAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3156",
            "district": "27",
            "Block": "194",
            "label": "PUMS - KAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3164",
            "district": "27",
            "Block": "319",
            "label": "PUMS - BALAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3168",
            "district": "27",
            "Block": "375",
            "label": "PUMS - CHOCKALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3171",
            "district": "27",
            "Block": "40",
            "label": "PUMS - AMMAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3178",
            "district": "27",
            "Block": "375",
            "label": "PUMS - KALIMETTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3201",
            "district": "27",
            "Block": "55",
            "label": "VIVEKANANTHA MATRIC HSS - KAMAYAGOUNDANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3215",
            "district": "27",
            "Block": "49",
            "label": "PETCHIYAMMAL.MS - SANKARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3220",
            "district": "27",
            "Block": "241",
            "label": "PUMS  - AZHAGAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3301",
            "district": "27",
            "Block": "241",
            "label": "MAHARISHI VIDYA MANDIR MATRIC SCHOOL - GENGUVARPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3312",
            "district": "27",
            "Block": "40",
            "label": "KAMARAJAR VIDYASALAI MHSS - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3435",
            "district": "27",
            "Block": "319",
            "label": "PUMS - UNJAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3474",
            "district": "27",
            "Block": "40",
            "label": "HAJEE K.L.K.S  ABDUL RAZAK INTERNATIONAL MATRIC SCHOOL, BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3479",
            "district": "27",
            "Block": "55",
            "label": "MAZHALAIYAR MATRIC HR SEC SCHOOL - KEELAGUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3540",
            "district": "27",
            "Block": "241",
            "label": "SANKARANARAYANAN MEMORIAL MIDDLE SCHOOL -  VADUGAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3590",
            "district": "27",
            "Block": "31",
            "label": "PUMS - PULLIMANCOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        { 
            "key":"24134", 
            "district":"27", 
            "Block":"55", 
            "label": "THE ASCENT ACADEMY", 
            "Sch_type": "Un-aided", 
            "Category": "Middle School"},
        {
            "key": "3596",
            "district": "27",
            "Block": "375",
            "label": "PUMS - NAGAYAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3599",
            "district": "27",
            "Block": "49",
            "label": "R.C.MS - CHINNAMANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3630",
            "district": "27",
            "Block": "241",
            "label": "BHARATHIYAR MS - VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3666",
            "district": "27",
            "Block": "241",
            "label": "7TH DAY ADV  MATRIC HR SEC SCHOOL -  T.KALLIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3748",
            "district": "27",
            "Block": "375",
            "label": "St. PERIYANAYAGI MIDDLE SCHOOL -  UTHAMAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3767",
            "district": "27",
            "Block": "31",
            "label": "PUMS - RAJAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3794",
            "district": "27",
            "Block": "319",
            "label": "GKMS - KAKKIVADANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3826",
            "district": "27",
            "Block": "40",
            "label": "SCISM MATRIC HSS  - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3839",
            "district": "27",
            "Block": "55",
            "label": "AL-AZ'HAR MATRICULATION HIGHER SECONDARY  SCHOOL, CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3973",
            "district": "27",
            "Block": "55",
            "label": "ST.MARY'S.MATRIC HR SEC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3980",
            "district": "27",
            "Block": "55",
            "label": "FAIRLANDS  A FOUNDATION SCHOOL - CBSE - C. PUTHUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3999",
            "district": "27",
            "Block": "375",
            "label": "PUMS - KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4002",
            "district": "27",
            "Block": "241",
            "label": "NELLAIAPPER MIDDLE SCHOOL - VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4048",
            "district": "27",
            "Block": "49",
            "label": "NADARKAL URAVINMURAI MS - SUKKANGALPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4064",
            "district": "27",
            "Block": "49",
            "label": "MAYAR RAM MATRIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4128",
            "district": "27",
            "Block": "241",
            "label": "RENGAKRISHNAN MIDDLE SCHOOL - PERIYAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4136",
            "district": "27",
            "Block": "375",
            "label": "St. THERASA'S R.C. MIDDLE SCHOOL - THEVARAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4142",
            "district": "27",
            "Block": "40",
            "label": "S.K.S.MS - DOMBUCHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4229",
            "district": "27",
            "Block": "40",
            "label": "GOVT A.D.W MID SCHOOL - B.DURAIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4232",
            "district": "27",
            "Block": "40",
            "label": "SRI RAM SOLAI MIDDLE SCH -  KAMARAJAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4311",
            "district": "27",
            "Block": "55",
            "label": "ST. ALPHONSE  MS -  UTHAMAPURAM - CUMBUM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4313",
            "district": "27",
            "Block": "40",
            "label": "PUMS - AGAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4332",
            "district": "27",
            "Block": "55",
            "label": "VOC MS -   KEELAGUDALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4397",
            "district": "27",
            "Block": "375",
            "label": "HINDU MIDDLE SCHOOL - PALLAVARAYANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4462",
            "district": "27",
            "Block": "31",
            "label": "A.M.H.N.U. MAT HSS, AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4507",
            "district": "27",
            "Block": "194",
            "label": "PUMS - PONNANPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4549",
            "district": "27",
            "Block": "55",
            "label": "ST.JOSHEP R.C.MS, HANUMANTHANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4553",
            "district": "27",
            "Block": "194",
            "label": "PUMS - MELAPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4567",
            "district": "27",
            "Block": "319",
            "label": "BHARATHI MS - SEELAYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4572",
            "district": "27",
            "Block": "55",
            "label": "VEENA VIDHYALAYA MATRIC SCHOOL, C.PUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4584",
            "district": "27",
            "Block": "40",
            "label": "BUDDHAR MIDDLE SCHOOL - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4587",
            "district": "27",
            "Block": "241",
            "label": "SRI MARKANDEYA WEAVERS MIDDLE SCHOOL - VADUGAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4615",
            "district": "27",
            "Block": "319",
            "label": "KOTTUR MS - KOTTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4819",
            "district": "27",
            "Block": "31",
            "label": "VAILANKANNI MATHA MATRIC HR SEC SCHOOL JAKKAMPATTI AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4986",
            "district": "27",
            "Block": "55",
            "label": "MPL MS - SUNGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5062",
            "district": "27",
            "Block": "241",
            "label": "SIDDHARATHA MATRICULATION  SCHOOL -  KEELAVADAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5124",
            "district": "27",
            "Block": "55",
            "label": "PUMS - KEELAGUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5180",
            "district": "27",
            "Block": "375",
            "label": "SRI SUBRAMANIA BARATHI MIDDLE SCHOOL - THAMMINAYAKANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5197",
            "district": "27",
            "Block": "31",
            "label": "PUMS - MARAVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5217",
            "district": "27",
            "Block": "40",
            "label": "SENTHIL MIDDLE SCHOOL - KEPPURENGANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5230",
            "district": "27",
            "Block": "31",
            "label": "PUMS - SUNDARARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5303",
            "district": "27",
            "Block": "49",
            "label": "MUSLIM MS - CHINNAMANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5346",
            "district": "27",
            "Block": "49",
            "label": "MRV MS - MARKAYANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5407",
            "district": "27",
            "Block": "55",
            "label": "PUMS -  ERAVANGALAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5457",
            "district": "27",
            "Block": "241",
            "label": "3RD MPL MS - VADAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5549",
            "district": "27",
            "Block": "40",
            "label": "NIRMALA MS - SILAMARATHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5572",
            "district": "27",
            "Block": "40",
            "label": "PUMS -  KARIAPPAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5752",
            "district": "27",
            "Block": "241",
            "label": "HAMEEDIA MS -  THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5783",
            "district": "27",
            "Block": "31",
            "label": "K.S..MIDDLE  SCHOOL - BALASAMUDRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5893",
            "district": "27",
            "Block": "55",
            "label": "PUMS - SURULIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5949",
            "district": "27",
            "Block": "375",
            "label": "ALHIKMAH MATRIC HIGHER SECONDARY SCHOOL - PTR.COLONY,UTHAMAPALAYAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5980",
            "district": "27",
            "Block": "241",
            "label": "SEKKILAR MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6132",
            "district": "27",
            "Block": "241",
            "label": "VELLALAR MS -  VADUGAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6175",
            "district": "27",
            "Block": "31",
            "label": "PUMS - NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6215",
            "district": "27",
            "Block": "49",
            "label": "PUMS - KAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6240",
            "district": "27",
            "Block": "319",
            "label": "S.W.P.M. KAMBAR MS - SEELAYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6253",
            "district": "27",
            "Block": "31",
            "label": "KAMMAVAR AIDED MIDDLE SCHOOL -M.SUBBULAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6261",
            "district": "27",
            "Block": "31",
            "label": "PUMS - SITHAIYAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6270",
            "district": "27",
            "Block": "31",
            "label": "PUMS - AUNDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6410",
            "district": "27",
            "Block": "31",
            "label": "PUMS - KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6531",
            "district": "27",
            "Block": "49",
            "label": "PUMS - KARICHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6533",
            "district": "27",
            "Block": "319",
            "label": "GKMS - BOMMAYAKOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6546",
            "district": "27",
            "Block": "241",
            "label": "NAYAGAM MS - T.VENKATACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18469",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, P.DHARMATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18470",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, T.SUBBULAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18471",
            "district": "27",
            "Block": "31",
            "label": "GOVT (ADW) HSS, BOMMINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18472",
            "district": "27",
            "Block": "31",
            "label": "PUMS - THIMMARASANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18473",
            "district": "27",
            "Block": "31",
            "label": "PUMS - T.RAJAGOPALANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18474",
            "district": "27",
            "Block": "31",
            "label": "PUMS - NATCHIYARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18475",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, RENGASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18476",
            "district": "27",
            "Block": "31",
            "label": "PUMS - KEELAMUTHANAMPATTI @ KATHIRAYAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18477",
            "district": "27",
            "Block": "31",
            "label": "GOVT KALLAR HSS, MUTHANAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18478",
            "district": "27",
            "Block": "31",
            "label": "PUMS - KALLUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18479",
            "district": "27",
            "Block": "31",
            "label": "PUMS - MUTHUSANGILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18480",
            "district": "27",
            "Block": "31",
            "label": "PUMS - C.R.CAMP @ ANNAI INDRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18481",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, SHANMUGASUNDARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18482",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, ANUPPAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18483",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, KONDAMANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18484",
            "district": "27",
            "Block": "31",
            "label": "HINDU HSS, JAKKAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key" :"24126", 
            "district" :"27", 
            "Block" :"55", 
            "label": "O.R.school", 
            "Sch_type": "Un-aided", 
            "Category": "Middle School"
        },
        {
            "key": "18485",
            "district": "27",
            "Block": "31",
            "label": "GOVT BOYS HSS, AUNDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18486",
            "district": "27",
            "Block": "31",
            "label": "GOVT GIRLS HSS, AUNDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18487",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, KANNIAPPAPILLAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18488",
            "district": "27",
            "Block": "31",
            "label": "S.K.A. HSS, AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18489",
            "district": "27",
            "Block": "31",
            "label": "GOVT KALLAR HSS, ETHAKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18490",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, MUTHUMARIAMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18491",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, KOTHAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18492",
            "district": "27",
            "Block": "31",
            "label": "GOVT KALLAR HSS, RAJADHANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18493",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, PALACOMBAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18494",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, THEPPAMMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18495",
            "district": "27",
            "Block": "31",
            "label": "GANESHA MS -  SITHARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18496",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, S.KATHIRNARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18497",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, OKKARAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18498",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, ASARIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18499",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, G.USILAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18500",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, THEKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18501",
            "district": "27",
            "Block": "31",
            "label": "PUMS - ERATHIMMAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18502",
            "district": "27",
            "Block": "31",
            "label": "GOVT (ADW) HSS, KUNNUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18503",
            "district": "27",
            "Block": "31",
            "label": "GOVT (ADW) HS, AMMACHIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18504",
            "district": "27",
            "Block": "40",
            "label": "PUMS - KURANGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18505",
            "district": "27",
            "Block": "40",
            "label": "GOVT HSS, BOOTHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18506",
            "district": "27",
            "Block": "40",
            "label": "PURNA VIDYA BHAVAN MAT HSS - KODANGIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18507",
            "district": "27",
            "Block": "40",
            "label": "GKMS - B.MEENATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18508",
            "district": "27",
            "Block": "40",
            "label": "GOVT KALLAR HS -  B.ANAIKARAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18509",
            "district": "27",
            "Block": "40",
            "label": "PUMS,-KARATUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18510",
            "district": "27",
            "Block": "40",
            "label": "GOVT HSS-MELACHOCKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18511",
            "district": "27",
            "Block": "40",
            "label": "PUMS - MUNTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18512",
            "district": "27",
            "Block": "40",
            "label": "PUMS-MANAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18513",
            "district": "27",
            "Block": "40",
            "label": "GOVT HSS-SILAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18514",
            "district": "27",
            "Block": "40",
            "label": "GOVT HSS - SILLAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18515",
            "district": "27",
            "Block": "40",
            "label": "M.P.M. MS -  VISUVASAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18516",
            "district": "27",
            "Block": "40",
            "label": "GRAMA POTHU KALIKADEVI HS - BADRAKALIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18517",
            "district": "27",
            "Block": "40",
            "label": "PATCHAIYAPPA.HS - UPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18518",
            "district": "27",
            "Block": "40",
            "label": "PUMS - KOOLAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18519",
            "district": "27",
            "Block": "40",
            "label": "GKMS - PALARPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18520",
            "district": "27",
            "Block": "40",
            "label": "PUMS -  MANIAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18521",
            "district": "27",
            "Block": "40",
            "label": "7TH WARD MPL HSS - BODI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18522",
            "district": "27",
            "Block": "40",
            "label": "GKMS -  BODI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18523",
            "district": "27",
            "Block": "40",
            "label": "SRI SOWDAMBIKA MIDDLE SCHOOL - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18524",
            "district": "27",
            "Block": "40",
            "label": "Z.K.M. HSS -  BODINAYAKANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18525",
            "district": "27",
            "Block": "40",
            "label": "PANKAJAM GIRLS HSS -  BODINAYAKANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18526",
            "district": "27",
            "Block": "40",
            "label": "NADAR HSS, THIRUMALAPURAM - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18527",
            "district": "27",
            "Block": "40",
            "label": "10TH WARD MPL HS - BODINAYAKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18528",
            "district": "27",
            "Block": "40",
            "label": "KAMARAJ VIDHYALAYA HS - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18529",
            "district": "27",
            "Block": "49",
            "label": "TPVVU GURUKKAL MS -  CHINNAMANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18530",
            "district": "27",
            "Block": "49",
            "label": "SKA HSS - CHINNAMANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18531",
            "district": "27",
            "Block": "49",
            "label": "MUNICIPAL  HS, CHINNAMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18532",
            "district": "27",
            "Block": "49",
            "label": "KVA GIRLS HSS - CHINNAMANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18533",
            "district": "27",
            "Block": "49",
            "label": "GOVT HSS - ERASAKKANAYAKANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18534",
            "district": "27",
            "Block": "49",
            "label": "GANDHIJI VIDHYA BEEDAM HSS- KANNISERVAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18535",
            "district": "27",
            "Block": "49",
            "label": "PACHAIYAPPA HINDU NADAR HSS - KAMATCHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18536",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS -SEEPALAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18537",
            "district": "27",
            "Block": "49",
            "label": "MARIAPPA MEMORIAL MATRIC SCHOOL- KAMATCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18538",
            "district": "27",
            "Block": "49",
            "label": "GOVT HSS -ODAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18539",
            "district": "27",
            "Block": "49",
            "label": "GOVT KALLAR HSS - VELLAIAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18540",
            "district": "27",
            "Block": "49",
            "label": "GOVT KALLAR HSS- MARKEYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18541",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS- PULIKUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18542",
            "district": "27",
            "Block": "49",
            "label": "GOVT HSS- KUTCHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18543",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS- SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18544",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS - RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18545",
            "district": "27",
            "Block": "49",
            "label": "GOVT KALLAR HSS - MUTHAIYANCHETTYPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18546",
            "district": "27",
            "Block": "55",
            "label": "SRI A.L.V. HS, C.PUTHUPATTY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18547",
            "district": "27",
            "Block": "55",
            "label": "K.L.S.S. GOVT HSS, C.PUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18548",
            "district": "27",
            "Block": "55",
            "label": "N.S.K.P. HSS, GUDALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18549",
            "district": "27",
            "Block": "55",
            "label": "GOVT HSS, LOWER CAMP",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18550",
            "district": "27",
            "Block": "55",
            "label": "R. M. GOVT KALLAR HSS, MELAGUDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18551",
            "district": "27",
            "Block": "55",
            "label": "THIRUVALLUVAR HSS, KEELAGUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18552",
            "district": "27",
            "Block": "55",
            "label": "GOVT KALLAR HSS, KARUNAKAMUTHANPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18553",
            "district": "27",
            "Block": "55",
            "label": "GOVT KALLAR HS, KULLAPPAGOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18554",
            "district": "27",
            "Block": "55",
            "label": "GOVT HSS, SURULIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18555",
            "district": "27",
            "Block": "55",
            "label": "GOVT HSS, HIGHWAVYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18556",
            "district": "27",
            "Block": "55",
            "label": "PUMS - MELMANALAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18557",
            "district": "27",
            "Block": "55",
            "label": "PUMS -  KAMAYAKOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18558",
            "district": "27",
            "Block": "55",
            "label": "KASTHURIBAI MS - KAMAYAGOUNDANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18559",
            "district": "27",
            "Block": "55",
            "label": "KASTHURIBAI GIRLS HSS, KAMAYAGOUNDANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18560",
            "district": "27",
            "Block": "55",
            "label": "GOVT HSS, KAMAYAKOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18561",
            "district": "27",
            "Block": "55",
            "label": "ILAHI ORIENTAL ARABIC HS, CUMBUM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18562",
            "district": "27",
            "Block": "55",
            "label": "GOVT KALLAR HSS, UTHAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18563",
            "district": "27",
            "Block": "55",
            "label": "MPL GIRLS HSS, M.A.PURAM, CUMBUM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18564",
            "district": "27",
            "Block": "55",
            "label": "MPL.MS - ALAMARAM, CUMBUM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18565",
            "district": "27",
            "Block": "55",
            "label": "SRI MUTHIAH PILLAI MEMORIAL HSS, CUMBUM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18566",
            "district": "27",
            "Block": "55",
            "label": "C.P.U. HSS, CUMBUM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18567",
            "district": "27",
            "Block": "55",
            "label": "A. R. GOVT GIRLS HSS, CUMBUM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18568",
            "district": "27",
            "Block": "55",
            "label": "S.V.MATRIC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18569",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, RAJENDRANAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18570",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, GANDAMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18571",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, KADAMALAIKUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18572",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, KUMANANTHOZHU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18573",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, MANTHISUNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18574",
            "district": "27",
            "Block": "194",
            "label": "HAYAGREEVA VIDHYALAYA MATRIC HSS KADAMALAIKUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18575",
            "district": "27",
            "Block": "194",
            "label": "G.R.V. HSS, MYLADUMPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18576",
            "district": "27",
            "Block": "194",
            "label": "PUMS -  NARIYOOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18577",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, MURUKKODAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18578",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, MYLADUMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18579",
            "district": "27",
            "Block": "194",
            "label": "GOVT HS, POOSANUTHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18580",
            "district": "27",
            "Block": "194",
            "label": "GOVT HS, KOMBAITHOZHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18581",
            "district": "27",
            "Block": "194",
            "label": "GOVT HS, THUMMAKUNDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18582",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, THANGAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18583",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, VARUSANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18584",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, VAIKKALPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18585",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS -  GENGUVARPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18586",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - G.KALLUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18587",
            "district": "27",
            "Block": "241",
            "label": "ST. PETERS HSS - G.KALLUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18588",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - DEVADANAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18589",
            "district": "27",
            "Block": "241",
            "label": "GOVT KALLAR HS - PULLAKKAPATTTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18590",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS - MANJALAR DAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18591",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS - SATHAKOVILPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18592",
            "district": "27",
            "Block": "241",
            "label": "GOVT MODEL HSS -  SILVARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18593",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - JAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18594",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS - BOMMINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18595",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS -  GULLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18596",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS -  A.VADIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18597",
            "district": "27",
            "Block": "241",
            "label": "SRI MAYAPANDEESWARI MS -  MELMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18598",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS -  VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18599",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - VAIGAIDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18600",
            "district": "27",
            "Block": "241",
            "label": "GOVT KALLAR HSS -  E.PUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18601",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS -  MURUGAMALAI NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18602",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS  -  T.KALLIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18603",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18604",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS -  SARUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18605",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18606",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS, GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18607",
            "district": "27",
            "Block": "241",
            "label": "MUTHALAMMAN HINDU HSS -  VADAPUDUPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18608",
            "district": "27",
            "Block": "241",
            "label": "U.C.MS - KOTTAIMEDU ,VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18609",
            "district": "27",
            "Block": "241",
            "label": "GOVT GIRLS HSS - PERIYAKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18610",
            "district": "27",
            "Block": "241",
            "label": "RAHIM BROS.MS - VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18611",
            "district": "27",
            "Block": "241",
            "label": "7TH WARD MS - VADAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18612",
            "district": "27",
            "Block": "241",
            "label": "SRI SARASWATHI MS -VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18613",
            "district": "27",
            "Block": "241",
            "label": "V.M. GOVT HSS - PERIYAKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18614",
            "district": "27",
            "Block": "241",
            "label": "NADAR MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18615",
            "district": "27",
            "Block": "241",
            "label": "ST. ANNES HSS - PERIYAKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18616",
            "district": "27",
            "Block": "241",
            "label": "C.S.I. MS - THANDUPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18617",
            "district": "27",
            "Block": "241",
            "label": "NIRMALA MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18618",
            "district": "27",
            "Block": "241",
            "label": "BUDDHAR KALA MS -  THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18619",
            "district": "27",
            "Block": "241",
            "label": "10TH WARD MUNICIPAL MIDDLE SCHOOL - THENKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18620",
            "district": "27",
            "Block": "319",
            "label": "KAMMA DHARMA HS - GOVINDANAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18621",
            "district": "27",
            "Block": "319",
            "label": "SRI VARADHA VENKATARAMANA HSS - VENKATACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18622",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, DHARMAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18623",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, KODUVILARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18624",
            "district": "27",
            "Block": "319",
            "label": "S.R.G. GOVT HS, SRIRENGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18625",
            "district": "27",
            "Block": "319",
            "label": "SHRI CHANDRA GUPTA MAURYA INTERNATIONAL SCHOOL, UPPARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18626",
            "district": "27",
            "Block": "319",
            "label": "GOVT KALLAR HSS, ANNANJI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18627",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, KOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18628",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18629",
            "district": "27",
            "Block": "319",
            "label": "PALANIAPPA MEMORIAL HSS - PALANICHETTIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18630",
            "district": "27",
            "Block": "319",
            "label": "VELAMMAL VIDHYA CBSE SCHOOL - MUTHUTHEVANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18631",
            "district": "27",
            "Block": "319",
            "label": "MUTHIAH HS - ALLINAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18632",
            "district": "27",
            "Block": "319",
            "label": "GOVT MODEL  HSS, ALLINAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18633",
            "district": "27",
            "Block": "319",
            "label": "R.C. HS - THENI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18634",
            "district": "27",
            "Block": "319",
            "label": "N.A.KONDURAJA MEMORIAL HS - THENI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18635",
            "district": "27",
            "Block": "319",
            "label": "NADAR SARASWATHI HSS - THENI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18636",
            "district": "27",
            "Block": "319",
            "label": "N.S. GIRLS HSS - THENI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18637",
            "district": "27",
            "Block": "319",
            "label": "P.C. GIRLS HSS - THENI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18638",
            "district": "27",
            "Block": "319",
            "label": "THE LITTLE KINGDOM SENIOR SCHOOL - VEDAPURI ARANMANAIPUTHUR THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18639",
            "district": "27",
            "Block": "375",
            "label": "St. ALOYSIUS BOYS Hr.,SEC.,SCHOOL - ROYAPPANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18640",
            "district": "27",
            "Block": "375",
            "label": "St. AGNES GIRLS Hr.,Sec.,SCHOOL - ROYAPPANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18641",
            "district": "27",
            "Block": "375",
            "label": "SAVARIYAPPA UDAYAR MEMORIAL HIGHER SECONDARY SCHOOL - ROYAPPANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18642",
            "district": "27",
            "Block": "375",
            "label": "AMALA ANNAI HIGHER SECONDARY SCHOOL -  T.SINTHALAICHERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18643",
            "district": "27",
            "Block": "375",
            "label": "HINDU MIDDLE SCHOOL - PANNAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18644",
            "district": "27",
            "Block": "375",
            "label": "GOVT HSS, PANNAIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18645",
            "district": "27",
            "Block": "375",
            "label": "GOVT HSS, MELASINDALAICHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18646",
            "district": "27",
            "Block": "375",
            "label": "SRI KANNIKA PARAMESWARI HIGHER SECONDARY SCHOOL - KOMBAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18647",
            "district": "27",
            "Block": "375",
            "label": "AYYA MATRICULATION  SCHOOL - KOMBAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18648",
            "district": "27",
            "Block": "375",
            "label": "GOVT HSS, THEVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18649",
            "district": "27",
            "Block": "375",
            "label": "SRI RENGASAMY MEMORIAL HIGHER SECONDARY SCHOOL - LAKSHMINAYAKKANPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18650",
            "district": "27",
            "Block": "375",
            "label": "MOHAMED FATHIMA GIRLS HIGH SCHOOL - UTHAMAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18651",
            "district": "27",
            "Block": "375",
            "label": "GOVT MODEL  HSS, UTHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18652",
            "district": "27",
            "Block": "375",
            "label": "GOVERNMENT  HIGH SCHOOL   -  U.AMMAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18653",
            "district": "27",
            "Block": "375",
            "label": "ST. JOSEPH'S R.C. HIGH SCHOOL - UTHAMAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20997",
            "district": "27",
            "Block": "40",
            "label": "THE SPICE VALLEY PUBLIC SCHOOL - BODI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21107",
            "district": "27",
            "Block": "55",
            "label": "JAYTECH INTERNATIONAL SENIOR SECONDARY  SCHOOL C.PUDHUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21169",
            "district": "27",
            "Block": "241",
            "label": "TMHNU.Nadar Saraswathi Girls Matriculation Higher Secondary School VADAPUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21288",
            "district": "27",
            "Block": "319",
            "label": "AANDAL ALAGAR MATRIC HR. SEC. SCHOOL - VAYALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21338",
            "district": "27",
            "Block": "319",
            "label": "THENI KAMMAVAR SANGAM PUBLIC SCHOOL - KODUVILARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21403",
            "district": "27",
            "Block": "40",
            "label": "GOVT HS -  RASINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21612",
            "district": "27",
            "Block": "40",
            "label": "SCISM RURAL ACADEMY MATRICULATION   SCHOOL - RASINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21638",
            "district": "27",
            "Block": "31",
            "label": "ANGEL VIDHYA MANDIR MAT HSS, AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21744",
            "district": "27",
            "Block": "241",
            "label": "TMHNU NADAR SARASWATHI PUBLIC SCHOOL - VADAPUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21745",
            "district": "27",
            "Block": "319",
            "label": "MARY MATHA CMI PUBLIC SCHOOL - THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21746",
            "district": "27",
            "Block": "319",
            "label": "T.M.H.N.U.VIDYALAYA MATRIC HSS - THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21747",
            "district": "27",
            "Block": "49",
            "label": "GAYATHRI GIRLS MATRIC HSS - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21748",
            "district": "27",
            "Block": "40",
            "label": "PURNA VIDYA BHAVAN PUBLIC SCHOOL - KODANGIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22258",
            "district": "27",
            "Block": "31",
            "label": "JAIKRISH VIDHYASHRAM CBSE SCHOOL  - MUTHUKRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22259",
            "district": "27",
            "Block": "40",
            "label": "SRI HAYAGREEVAR VIDHYA MANDIR MATRIC  HR.SEC. SCHOOL, SADAYALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22260",
            "district": "27",
            "Block": "40",
            "label": "G.D. MAT HSS - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22261",
            "district": "27",
            "Block": "55",
            "label": "R R INTERNATIONAL SCHOOL - C .PUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22611",
            "district": "27",
            "Block": "40",
            "label": "R.P. HS - MEENAKSHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22612",
            "district": "27",
            "Block": "40",
            "label": "SCISM SENIOR SECONDARY  SCHOOL (CBSE) -  MELACHOKKANATHAPURAM,  BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22613",
            "district": "27",
            "Block": "49",
            "label": "SHRI VIKASA VIDYALAYA   PUBLIC SCHOOL (CBSE)- CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22614",
            "district": "27",
            "Block": "241",
            "label": "MOUNT ZION ACADEMY CBSE SCHOOL - G.MEENATCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22615",
            "district": "27",
            "Block": "241",
            "label": "SHRI ROSY VIDYAALAYA - LAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22616",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS -  ERUMALAINAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22617",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS -  AMMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22749",
            "district": "27",
            "Block": "55",
            "label": "MAHARISHI VIDHYA MANDHIR  SCHOOL - KULLAPPAGOUNDANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22750",
            "district": "27",
            "Block": "375",
            "label": "LOYOLA INTERNATIONAL PUBLIC SCHOOL - T.SINDALAICHERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22924",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS , APPIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22960",
            "district": "27",
            "Block": "319",
            "label": "SANTINIKETAN PUBLIC SCHOOL - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22961",
            "district": "27",
            "Block": "55",
            "label": "SRI SAKTHI VINAYAGAR INTERNATIONAL (CBSE) SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23007",
            "district": "27",
            "Block": "40",
            "label": "G.D. PUBLIC SCHOOL - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23568",
            "district": "27",
            "Block": "31",
            "label": "LINGA GLOBAL SCHOOL, RENGASAMUTHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23569",
            "district": "27",
            "Block": "49",
            "label": "GOVERNMENT KALLAR RESIDENTIAL SCHOOL (CO ED), VELLAYAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23570",
            "district": "27",
            "Block": "319",
            "label": "P.C.PATTI HINDU NADAR URAVINMURAI R.S. MATRICULATION SCHOOL - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23571",
            "district": "27",
            "Block": "375",
            "label": "GREEN MOUNT PUBLIC SCHOOL - PANNAIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23734",
            "district": "27",
            "Block": "31",
            "label": "THENI DISTRICT GOVERNMENT MODEL SCHOOL - THEKKAMPATTI",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23735",
            "district": "27",
            "Block": "31",
            "label": "THE LITTLE FLOWER MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23755",
            "district": "27",
            "Block": "49",
            "label": "PUMS - KAMARAJAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23756",
            "district": "27",
            "Block": "49",
            "label": "SHREE VIKASAA PUBLIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23918",
            "district": "27",
            "Block": "241",
            "label": "KALVI INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "28": [
        {
            "key": "268",
            "district": "28",
            "Block": "118",
            "label": "ACHARIYA SCHOOL, VANARAMUTTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "269",
            "district": "28",
            "Block": "118",
            "label": "ADI DRAVIDAR AND TRIBAL W ELFARE HS, KARISALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "270",
            "district": "28",
            "Block": "118",
            "label": "GOVT ADW HIGH SCHOOL, THIRUMANGALAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "271",
            "district": "28",
            "Block": "118",
            "label": "BABA MATRICULATION HR.SEC. SCHOOL, KAYATHAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "272",
            "district": "28",
            "Block": "118",
            "label": "GHS, AYYANAROOTHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "273",
            "district": "28",
            "Block": "118",
            "label": "GHS, CHIDAMBARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "274",
            "district": "28",
            "Block": "118",
            "label": "GHSS, KALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "275",
            "district": "28",
            "Block": "118",
            "label": "GHS, NORTH ILANTHAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "276",
            "district": "28",
            "Block": "118",
            "label": "GHSS, KALUGUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "277",
            "district": "28",
            "Block": "118",
            "label": "GHSS, KAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "278",
            "district": "28",
            "Block": "118",
            "label": "GHSS, KAYATHAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "279",
            "district": "28",
            "Block": "118",
            "label": "GHSS, VANARAMUTTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "280",
            "district": "28",
            "Block": "118",
            "label": "HINDU NADAR KAMARAJ HS, SOLAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "281",
            "district": "28",
            "Block": "118",
            "label": "HINDU NADAR MS, CHETTIKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "282",
            "district": "28",
            "Block": "118",
            "label": "KARM GEORGE MS, KADAMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "283",
            "district": "28",
            "Block": "118",
            "label": "MAHA LAKSHMI MS, AKILANDAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "284",
            "district": "28",
            "Block": "118",
            "label": "HINDU NADAR HSS KADAMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "285",
            "district": "28",
            "Block": "118",
            "label": "MOTHER THERESA MAT., KAYATHAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "286",
            "district": "28",
            "Block": "118",
            "label": "PU MS, KAYATHAR SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "287",
            "district": "28",
            "Block": "118",
            "label": "PUMS, KOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "288",
            "district": "28",
            "Block": "118",
            "label": "PUMS, KUMARAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "289",
            "district": "28",
            "Block": "118",
            "label": "PUMS, K. VENKATESWARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "290",
            "district": "28",
            "Block": "118",
            "label": "PUMS, NAGALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "291",
            "district": "28",
            "Block": "118",
            "label": "PUMS PANIKKARKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "292",
            "district": "28",
            "Block": "118",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUNGAVARNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "293",
            "district": "28",
            "Block": "118",
            "label": "PUMS, VAGAITHAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "294",
            "district": "28",
            "Block": "118",
            "label": "PUMS, KARADIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "295",
            "district": "28",
            "Block": "118",
            "label": "KAMMAVAR GIRLS H.S.S.KALUGUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "296",
            "district": "28",
            "Block": "118",
            "label": "RC FATIMA HS, KAYATHAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "297",
            "district": "28",
            "Block": "118",
            "label": "RCMS, PANNIRKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "298",
            "district": "28",
            "Block": "118",
            "label": "RC MS, VELLALANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "299",
            "district": "28",
            "Block": "118",
            "label": "RC SUSAI HSS, KALUGUMALAI .",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "300",
            "district": "28",
            "Block": "118",
            "label": "SREE KRISHNA HS, KURUMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "301",
            "district": "28",
            "Block": "118",
            "label": "ST ALOYSIOUS HIGH SCHOOL, KAMANAYAKKANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "302",
            "district": "28",
            "Block": "118",
            "label": "ST LOUISA GIRLS HSS, KALUGUMALAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "303",
            "district": "28",
            "Block": "118",
            "label": "ST.MADONNA'S MATRIC HIGHER SECONDARY  SCHOOL, KALUGUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "304",
            "district": "28",
            "Block": "118",
            "label": "ST MARYS HIGH SCHOOL, THERKU KONAR KOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "305",
            "district": "28",
            "Block": "118",
            "label": "ST XAVIERS MS, VADAKKU VANDHANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "306",
            "district": "28",
            "Block": "118",
            "label": "TNDTA APPASAMY MS, RAJAPUDHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "307",
            "district": "28",
            "Block": "118",
            "label": "VIMAL MATRIC SCHOOL, KALUGUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "308",
            "district": "28",
            "Block": "138",
            "label": "AV HSS,KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "309",
            "district": "28",
            "Block": "138",
            "label": "AUXILIUM MATRIC HR.SEC.SCHOOL ,KEELA ERAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "310",
            "district": "28",
            "Block": "138",
            "label": "CKT Matric Hr.Sec.School  KUMARAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "311",
            "district": "28",
            "Block": "138",
            "label": "COUNIAN MAT. HR SEC SCHOOL , KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "312",
            "district": "28",
            "Block": "138",
            "label": "EDUSTAR INTERNATIONAL SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "313",
            "district": "28",
            "Block": "138",
            "label": "EVA VALLIMUTHU HS, KOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "314",
            "district": "28",
            "Block": "138",
            "label": "EVEREST MARIAPPA NADAR HSS, KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "315",
            "district": "28",
            "Block": "138",
            "label": "EVEREST MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "316",
            "district": "28",
            "Block": "138",
            "label": "M.B.C.M Govt Girls Hr.Sec. School, ETTAYAPURAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "317",
            "district": "28",
            "Block": "138",
            "label": "G(G)HSS, KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "318",
            "district": "28",
            "Block": "138",
            "label": "GHS, LOYALMILL COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "319",
            "district": "28",
            "Block": "138",
            "label": "GHSS, UTHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "320",
            "district": "28",
            "Block": "138",
            "label": "GHSS, VILLISERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "321",
            "district": "28",
            "Block": "138",
            "label": "GHS, VIJAYAPURI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "322",
            "district": "28",
            "Block": "138",
            "label": "ICM MS, KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "323",
            "district": "28",
            "Block": "138",
            "label": "ILLATHAR MIDDLE SCHOOL , PUDUGRAMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "324",
            "district": "28",
            "Block": "138",
            "label": "JOHN BOSCO MATRIC HSS, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "325",
            "district": "28",
            "Block": "138",
            "label": "KAMARAJ MATRIC HSS, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "326",
            "district": "28",
            "Block": "138",
            "label": "KOVILPATTI NADAR KAMARAJ MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "327",
            "district": "28",
            "Block": "138",
            "label": "Kammavar (Girls) Hr.Sec.School, KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "328",
            "district": "28",
            "Block": "138",
            "label": "SRI KANNA MATRIC HR SEC SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "329",
            "district": "28",
            "Block": "138",
            "label": "KRA VIDHYASHRAM MATRIC HSS, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "330",
            "district": "28",
            "Block": "138",
            "label": "KR SARATHA GOVT HR SEC SCHOOL, NALATINPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "331",
            "district": "28",
            "Block": "138",
            "label": "LAKSHMI SRINIVASA VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "332",
            "district": "28",
            "Block": "138",
            "label": "LAKSHMI MILLS HIGHER SECONDARY SCHOOL KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "333",
            "district": "28",
            "Block": "138",
            "label": "LOYAL TEXTILE MILLS MS, KOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "334",
            "district": "28",
            "Block": "138",
            "label": "Maharisi Vidyasram Matriculation School, Kovilpatti",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "335",
            "district": "28",
            "Block": "138",
            "label": "MARIAPPA DVMS, ETTAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "336",
            "district": "28",
            "Block": "138",
            "label": "MUNICIPAL HS GANDHI NAGAR, KOVILPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "337",
            "district": "28",
            "Block": "138",
            "label": "MUNICIPAL MS, NEW ROAD, KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "338",
            "district": "28",
            "Block": "138",
            "label": "MUNICIPAL MS,BHARATHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "339",
            "district": "28",
            "Block": "138",
            "label": "NADAR HSS, KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "340",
            "district": "28",
            "Block": "138",
            "label": "NADAR M.S.,KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "341",
            "district": "28",
            "Block": "138",
            "label": "PARIMELZHAGAR HMS, ERACHI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "342",
            "district": "28",
            "Block": "138",
            "label": "PARVATHI HS,ILLUPPAIYURANI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "343",
            "district": "28",
            "Block": "138",
            "label": "PUMS,KILAVIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "344",
            "district": "28",
            "Block": "138",
            "label": "PUMS, MELAERAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23377",
            "district": "28",
            "Block": "280",
            "label": "St. John's NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "345",
            "district": "28",
            "Block": "138",
            "label": "PUMS, SURAIKAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "346",
            "district": "28",
            "Block": "138",
            "label": "PUMS, THITTANKULAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "347",
            "district": "28",
            "Block": "138",
            "label": "PUNITHA OHM MATRICULATION SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "348",
            "district": "28",
            "Block": "138",
            "label": "PUMS,MEENATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "349",
            "district": "28",
            "Block": "138",
            "label": "PUMS, ATHIKINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "350",
            "district": "28",
            "Block": "138",
            "label": "PUMS, NORTH SEMAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "351",
            "district": "28",
            "Block": "138",
            "label": "PUMS SINTHALAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "352",
            "district": "28",
            "Block": "138",
            "label": "PUMS,URULAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "353",
            "district": "28",
            "Block": "138",
            "label": "RAJA HSS,THEATRE ROAD, ETTAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "354",
            "district": "28",
            "Block": "138",
            "label": "RC MIDDLE SCHOOL, KASAVANKUNTRU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "355",
            "district": "28",
            "Block": "138",
            "label": "RC MIDDLE SCHOOL, LINGAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "356",
            "district": "28",
            "Block": "138",
            "label": "SDA MATRIC HSS, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "357",
            "district": "28",
            "Block": "138",
            "label": "SENAIAR KALAIMAGAL HS, ERACHI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "358",
            "district": "28",
            "Block": "138",
            "label": "SENGUNTHAR HS, KADALAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "359",
            "district": "28",
            "Block": "138",
            "label": "SENGUNTHAR MS,NADUVIRPATTI, ETTAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "360",
            "district": "28",
            "Block": "138",
            "label": "SFS, NALATINPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "361",
            "district": "28",
            "Block": "138",
            "label": "SRMS MATRICULATION HIGHER SECONDARY SCHOOL, SINTHALAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "362",
            "district": "28",
            "Block": "138",
            "label": "ST. ANDREWS MATRICULATION SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "363",
            "district": "28",
            "Block": "138",
            "label": "ST. JOSEPH'S HSS, KOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "364",
            "district": "28",
            "Block": "138",
            "label": "St.Paul's Matriculation Higher Secondary School, Kovilpatti",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "365",
            "district": "28",
            "Block": "138",
            "label": "TNDTA MIDDLE SCHOOL, KADALAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "366",
            "district": "28",
            "Block": "138",
            "label": "UP MATRICULATION HR.SEC. SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "367",
            "district": "28",
            "Block": "138",
            "label": "VAMR HINDU NADAR MIDDLE SCHOOL, MELA ERAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "368",
            "district": "28",
            "Block": "138",
            "label": "VANIGA VAISIA M.S.,KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "369",
            "district": "28",
            "Block": "138",
            "label": "VEL VELLAICHAMY MS, KEELA IRAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "370",
            "district": "28",
            "Block": "138",
            "label": "VISWAKARMA HS, KOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "371",
            "district": "28",
            "Block": "138",
            "label": "VOC GHSS, KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "432",
            "district": "28",
            "Block": "350",
            "label": "GEETHA MATRIC HSS MUTHYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "600",
            "district": "28",
            "Block": "351",
            "label": "DMNS DR SIVANTHI ADITHANAR MATRIC HR SEC",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "834",
            "district": "28",
            "Block": "217",
            "label": "CMML MS NARAIKINARU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "934",
            "district": "28",
            "Block": "351",
            "label": "CARPENTER STREET MS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1012",
            "district": "28",
            "Block": "351",
            "label": "STAR MATRIC HSS RENGANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1088",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MIDDLE SCHOOL VALLIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1100",
            "district": "28",
            "Block": "297",
            "label": "PUMS VELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1102",
            "district": "28",
            "Block": "297",
            "label": "PUMS ESAVANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1137",
            "district": "28",
            "Block": "320",
            "label": "PUMS KOMBUTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1215",
            "district": "28",
            "Block": "320",
            "label": "SRI RAMAIAH BHAGAVATHER MEMORIAL SENTHIL MURUGAN MIDDLE SCHOOL, TIRUCHENDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1292",
            "district": "28",
            "Block": "320",
            "label": "ST.MARY'S MIDDLE SCHOOL, ADAIKALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1329",
            "district": "28",
            "Block": "8",
            "label": "MEERANIA MS ALAGIAMANAVALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1489",
            "district": "28",
            "Block": "8",
            "label": "GDG MIDDLE SCHOOL ALWARTHIRUNAGARI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1588",
            "district": "28",
            "Block": "320",
            "label": "PUMS KANTHANKUDIERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1656",
            "district": "28",
            "Block": "320",
            "label": "SRI SARAVANA IYER MS, TIRUCHINDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1668",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MS KOLLAMPARAMBU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1670",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS NBK STREET  THOOTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1683",
            "district": "28",
            "Block": "320",
            "label": "CARMEL MIDDLE SCHOOL, ALANTHALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1694",
            "district": "28",
            "Block": "217",
            "label": "PUMS,PULIYAMARATHUARASADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1713",
            "district": "28",
            "Block": "350",
            "label": "PUMS BHARATHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1719",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS SEBATHIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1755",
            "district": "28",
            "Block": "351",
            "label": "ST CHARLES MS, SMPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1874",
            "district": "28",
            "Block": "350",
            "label": "SIVAKASI N. MAT. SCHOOL, MAPPILAIYURANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1934",
            "district": "28",
            "Block": "351",
            "label": "TDTA MS, VADAKKUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1938",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS ALWARTHIRUNAGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2033",
            "district": "28",
            "Block": "8",
            "label": "ST.MARY'S MDL.SCH. PRAKASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2075",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS,  MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2085",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MIDDLE SCHOOL,ODAIPATTIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2087",
            "district": "28",
            "Block": "217",
            "label": "HINDU MIDDLE SCHOOL,SAMINATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2121",
            "district": "28",
            "Block": "280",
            "label": "PUMS, THATCHANVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2133",
            "district": "28",
            "Block": "350",
            "label": "HOLY FAMILY TAMIL MIDDLE SCHOOL CALDWELL COLONY",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2163",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS AGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2189",
            "district": "28",
            "Block": "320",
            "label": "PUMS SELVARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2201",
            "district": "28",
            "Block": "351",
            "label": "BHARAT RATNA KAMARAJAR  MATRIC HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2202",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS ERAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2209",
            "district": "28",
            "Block": "320",
            "label": "ST JOSEPH'S CONVENT MATRIC HR.SEC.SCHOOL, VIRAPANDIANPATNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2231",
            "district": "28",
            "Block": "369",
            "label": "PUMS SATHYA NAGAR, UDANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2256",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS SILVERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2258",
            "district": "28",
            "Block": "351",
            "label": "FATIMA MS, THOOTHUKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2272",
            "district": "28",
            "Block": "350",
            "label": "BMC MATRIC HR.SEC.SCHOOL, THOOTHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2286",
            "district": "28",
            "Block": "351",
            "label": "ST THOMAS MATRIC HSS, INNACIARPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2295",
            "district": "28",
            "Block": "320",
            "label": "CENTRAL MATRIC HR SEC SCHOOL, KAYALPATNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2313",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MS, V. KALUKASALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2318",
            "district": "28",
            "Block": "297",
            "label": "ST ANTONYS RC MS MUKKANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2327",
            "district": "28",
            "Block": "320",
            "label": "STARS' MODEL MATRICULATION HIGHER SECONDARY SCHOOL, KUMARAPURAM - TIRUCHENDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2369",
            "district": "28",
            "Block": "217",
            "label": "PUMS ANAINTHAMADANPATCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2384",
            "district": "28",
            "Block": "350",
            "label": "SRI KALAIMAGAL VIDHYALAYA MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2389",
            "district": "28",
            "Block": "217",
            "label": "PUMS,ELAVELANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2415",
            "district": "28",
            "Block": "320",
            "label": "AROCKIA ANNAI MIDDLE SCHOOL, ADAIKALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2417",
            "district": "28",
            "Block": "350",
            "label": "PUMS DEVAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2450",
            "district": "28",
            "Block": "8",
            "label": "PUMS PEIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2457",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS MADATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2497",
            "district": "28",
            "Block": "297",
            "label": "ST MARYS MS PAZHAYAKAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2498",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS KADAYANODAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2536",
            "district": "28",
            "Block": "320",
            "label": "ANNAMMAL MATRICULATION SCHOOL ARUMUGANERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2565",
            "district": "28",
            "Block": "369",
            "label": "SALMA MATRIC HIGHER SECONDARY SCHOOL, UDANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2584",
            "district": "28",
            "Block": "351",
            "label": "GEETHA MATRIC HR.SEC.SCHOOL POLPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2606",
            "district": "28",
            "Block": "350",
            "label": "RC MS ANTHONIYARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2643",
            "district": "28",
            "Block": "351",
            "label": "SDA MATRIC SCHOOL, SB COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2645",
            "district": "28",
            "Block": "297",
            "label": "HINDU MS ALWARTHOPE WEST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2658",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MM MS ARUMUGAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2675",
            "district": "28",
            "Block": "351",
            "label": "TNDTA MS, TOOVEYPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2684",
            "district": "28",
            "Block": "320",
            "label": "ST JOHN DE-BRITTO ANGLO INDIAN MATRIC SCHOOL VIRA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2730",
            "district": "28",
            "Block": "217",
            "label": "MAHATMA MATRIC HR.SEC SCHOOL, PUTIYAMPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2741",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS ANIYABARANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2769",
            "district": "28",
            "Block": "402",
            "label": "PUMS, AyanVirusampaati",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2793",
            "district": "28",
            "Block": "351",
            "label": "EDUCATIONAL SOCIETY MS, THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2840",
            "district": "28",
            "Block": "350",
            "label": "ICE - KING OF KINGS  MATRICULATION  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2849",
            "district": "28",
            "Block": "217",
            "label": "PRASANNA MATRIC HIGHER SECONDARY  SCHOOL  PUTHIYAMPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2851",
            "district": "28",
            "Block": "280",
            "label": "Sri Kanchi Sankara Bagavathi Vidyalaya Matric Hr.Sec.School, Kommadikottai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2862",
            "district": "28",
            "Block": "351",
            "label": "SAKTHI VIDYALAYA  MATRIC SCHOOL, KAMARAJ NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2880",
            "district": "28",
            "Block": "350",
            "label": "CHANDY MATRIC HIGH SCHOOL,MUTHIAHPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2881",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MS KOMBADI THALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2921",
            "district": "28",
            "Block": "351",
            "label": "EXTENSION MS, NEW COLONY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2931",
            "district": "28",
            "Block": "350",
            "label": "KAMAK MATRIC HR.SEC. SCHOOL,  SANKARAPERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2963",
            "district": "28",
            "Block": "350",
            "label": "SACRED HEART.MAT.S",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2977",
            "district": "28",
            "Block": "280",
            "label": "TDTA MS Kumaran Vila",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3008",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, POOVUDAYARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3098",
            "district": "28",
            "Block": "402",
            "label": "St.Charles MATRICULATION SCHOOL, Vilathikulam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3123",
            "district": "28",
            "Block": "297",
            "label": "HINDHU ADI DRAVIDA MS SIVAGALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3133",
            "district": "28",
            "Block": "257",
            "label": "PUMS MELAKARANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3206",
            "district": "28",
            "Block": "351",
            "label": "HOLY CROSS HOME SCIENCE MATRICULATION SCHOOL, NEW COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3213",
            "district": "28",
            "Block": "217",
            "label": "INDHIRA GANDHI MAT. SCHOOL,OTTAPIDARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3254",
            "district": "28",
            "Block": "111",
            "label": "CAMBRIDGE MATRICULATION SCHOOL, KONGARAYAKURICHI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3274",
            "district": "28",
            "Block": "297",
            "label": "PUMS GANAPATHISAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3291",
            "district": "28",
            "Block": "320",
            "label": "KANCHI SRI SANKARA ACADEMY MHSS, WEST TIRUCHENDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3319",
            "district": "28",
            "Block": "280",
            "label": "HENRIY MATRIC HR.SEC. SCHOOL, SATHANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3334",
            "district": "28",
            "Block": "402",
            "label": "H.N.MS, Kulathur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3358",
            "district": "28",
            "Block": "369",
            "label": "TNDTA MIDDLE SCHOOL KALVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3398",
            "district": "28",
            "Block": "351",
            "label": "ST.PATRICKS MS MELUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3436",
            "district": "28",
            "Block": "369",
            "label": "RC MS, UDANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3550",
            "district": "28",
            "Block": "280",
            "label": "RC MS ,THAILAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3617",
            "district": "28",
            "Block": "217",
            "label": "NMS MARUTHANVALVOO",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3642",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS NALUMAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3678",
            "district": "28",
            "Block": "320",
            "label": "SENTHIL KUMARAN  MATRIC HR.SEC. SCHOOL,TIRUCHENDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3680",
            "district": "28",
            "Block": "257",
            "label": "SEENI MAT HS NAGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3763",
            "district": "28",
            "Block": "8",
            "label": "PUMS, AVARANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3772",
            "district": "28",
            "Block": "297",
            "label": "PUMS MEENACHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3790",
            "district": "28",
            "Block": "351",
            "label": "PAUL MATRIC SCHOOL, KANDHASAMYPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3791",
            "district": "28",
            "Block": "351",
            "label": "X'AN MATRIC HR.SEC SCHOOL, THOOTHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3855",
            "district": "28",
            "Block": "402",
            "label": "SHARON MATRIC HR.SEC.SCHOOL, VILATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3882",
            "district": "28",
            "Block": "350",
            "label": "PUMS THERMALNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3909",
            "district": "28",
            "Block": "297",
            "label": "RC MS G.PATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3926",
            "district": "28",
            "Block": "369",
            "label": "HASSANIYA MIDDLE SCHOOL, KULASEKARANPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3956",
            "district": "28",
            "Block": "280",
            "label": "PUMS Panaivilai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4060",
            "district": "28",
            "Block": "351",
            "label": "THE VIKASA SCHOOL, MILLERPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4127",
            "district": "28",
            "Block": "369",
            "label": "TNDTA MIDDLE SCHOOL MEYYOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4146",
            "district": "28",
            "Block": "369",
            "label": "GOVERNMENT MS UDANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4162",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS KOVANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4175",
            "district": "28",
            "Block": "369",
            "label": "PSM MS KULASEKARANPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4186",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MIDDLE SCHOOL SINNAMADANKUDIERUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4189",
            "district": "28",
            "Block": "369",
            "label": "HOLY GHOST MS MANAPADU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4268",
            "district": "28",
            "Block": "350",
            "label": "SRI VIVEKANANDA MATRIC SCHOOL MULLAKKADU CAMP II",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4275",
            "district": "28",
            "Block": "320",
            "label": "MUHYIDDEEN MATRIC HR.SEC.SCHOOL KAYALPATNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4306",
            "district": "28",
            "Block": "350",
            "label": "SRI KAMAKSHI VIDYALAYA MAT HSS C.G.E COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4335",
            "district": "28",
            "Block": "350",
            "label": "RC MS THALAMUTHU NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4354",
            "district": "28",
            "Block": "351",
            "label": "CRESCENT MATRIC HR.SEC SCHOOL, CHIDAMBARANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4375",
            "district": "28",
            "Block": "217",
            "label": "PUMS MUPPILIVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4420",
            "district": "28",
            "Block": "297",
            "label": "PUMS PADMANABAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4426",
            "district": "28",
            "Block": "369",
            "label": "TNDTA MS SEERKATCHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4434",
            "district": "28",
            "Block": "297",
            "label": "PUMS PUTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4475",
            "district": "28",
            "Block": "402",
            "label": "TNDTA MS, VILATHIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4562",
            "district": "28",
            "Block": "217",
            "label": "RAMANUJA HINDU MS OTTANATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4563",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS K.RAMASAMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4578",
            "district": "28",
            "Block": "320",
            "label": "SUDHANTHIRA VIDHYASALAI MIDDLE SCHOOL, MANAKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4659",
            "district": "28",
            "Block": "350",
            "label": "ISHA VIDHYA MATRIC. HR.SEC. SCHOOL,  KOOTAMPULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4737",
            "district": "28",
            "Block": "369",
            "label": "RMV MIDDLE SCHOOL, SIRUNADARKUDIERUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4758",
            "district": "28",
            "Block": "351",
            "label": "KSHATRIYA VIDHYASALA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4776",
            "district": "28",
            "Block": "8",
            "label": "GRAMA MS  VELLAKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4810",
            "district": "28",
            "Block": "369",
            "label": "PUMS, NAINAPILLAI ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4821",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS,SATHYANAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4857",
            "district": "28",
            "Block": "217",
            "label": "LITTLE FLOWER N&P AKKAYANAYAKKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4924",
            "district": "28",
            "Block": "402",
            "label": "ST.PETER MS, SUBRAMANIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4959",
            "district": "28",
            "Block": "351",
            "label": "APC VEERABAHU MATRIC HR.SEC. SCHOOL, MILLERPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5065",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS, SIVANTHAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5079",
            "district": "28",
            "Block": "369",
            "label": "PUMS, IDAICHIVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5101",
            "district": "28",
            "Block": "369",
            "label": "PUMS MANADUTHANDUPATHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5131",
            "district": "28",
            "Block": "217",
            "label": "PUMS, SAKKAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5135",
            "district": "28",
            "Block": "351",
            "label": "INTER CHRISTIAN MISSION MS BRYANT NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5146",
            "district": "28",
            "Block": "8",
            "label": "ST MARY'S  MIDDLE SCHOOL ,PUNNAIKAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5156",
            "district": "28",
            "Block": "402",
            "label": "N.K.MS, Arunkulam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5159",
            "district": "28",
            "Block": "350",
            "label": "PUMS THIRUVANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5210",
            "district": "28",
            "Block": "297",
            "label": "HINDU MS PANDARAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5244",
            "district": "28",
            "Block": "8",
            "label": "PUMS,PURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5265",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MIDDLE SCHOOL  KARUNKADAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5390",
            "district": "28",
            "Block": "369",
            "label": "TNDTA MIDDLE SCHOOL, VETHAKOTTAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5415",
            "district": "28",
            "Block": "297",
            "label": "SRI SUNDARA VINAYAGAR MS VELLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5509",
            "district": "28",
            "Block": "297",
            "label": "RC MS SEBATHIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5529",
            "district": "28",
            "Block": "217",
            "label": "HINDU NADAR MS,  ATHANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5537",
            "district": "28",
            "Block": "280",
            "label": "PUMS, GNANAIYARKUDIERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5676",
            "district": "28",
            "Block": "257",
            "label": "PUMS LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5696",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MIDDLE SCHOOL,VEPPANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5699",
            "district": "28",
            "Block": "350",
            "label": "ISRAEL MEMORIAL MATRIC SCHOOL KATTALANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5750",
            "district": "28",
            "Block": "351",
            "label": "BELL MATRIC HR. SEC. SCHOOL TOOVEYPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5776",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Kamalapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5789",
            "district": "28",
            "Block": "8",
            "label": "ST. XAVIERS MS, SETHUKKUVAITHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5791",
            "district": "28",
            "Block": "8",
            "label": "RC MS SAVERIYARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5819",
            "district": "28",
            "Block": "350",
            "label": "HINDU MIDDLE SCHOOL MUDIVAITHANENDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5828",
            "district": "28",
            "Block": "297",
            "label": "HAJI MEEYAN ABDUL CADER MS SRIVAIKUNDAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5860",
            "district": "28",
            "Block": "111",
            "label": "ST.LUCIA R.C MIDDLE SCHOOL , SEYDUNGANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5911",
            "district": "28",
            "Block": "217",
            "label": "PUMS,THERKKUVEERA PANDIAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5944",
            "district": "28",
            "Block": "8",
            "label": "PUMS PERUMALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6017",
            "district": "28",
            "Block": "350",
            "label": "INFANT MATRICULATION HIGHER SECONDARY SCHOOL, KRISHNARAJAPURAM MAIN ROAD, THOOTHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6043",
            "district": "28",
            "Block": "369",
            "label": "APARNA MATRIC. HR.SEC.SCHOOL MURUGESAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6052",
            "district": "28",
            "Block": "369",
            "label": "THAYAMMAL MS KULASAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6077",
            "district": "28",
            "Block": "351",
            "label": "JOHNS MATRIC HS, MILLERPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6105",
            "district": "28",
            "Block": "257",
            "label": "SKMS PALLIVASALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6120",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS,PALANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6133",
            "district": "28",
            "Block": "297",
            "label": "PUMS SRIVAIKUNTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6168",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MIDDLE SCHOO,KADATCHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6194",
            "district": "28",
            "Block": "350",
            "label": "SOMASUNDARA MIDDLE SCHOOL VARTHAGAREDDIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6305",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS THEMANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6336",
            "district": "28",
            "Block": "280",
            "label": "RC MS,CHETTIVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6344",
            "district": "28",
            "Block": "297",
            "label": "PUMS PERUNKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6387",
            "district": "28",
            "Block": "8",
            "label": "TNDTA Dr.mATHURAM MS, NAZARETH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6421",
            "district": "28",
            "Block": "351",
            "label": "AUXILIUM MATRIC HR.SEC. SCHOOL THERESPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6436",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MIDDLE SCHOOL MEERANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6437",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MIDDLE SCHOOL SONAGANVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6499",
            "district": "28",
            "Block": "217",
            "label": "TDTA MS, KATCHERITHALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6614",
            "district": "28",
            "Block": "8",
            "label": "PUMS,PALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6632",
            "district": "28",
            "Block": "217",
            "label": "PUMS,ARAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6676",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, KANKANIYARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6684",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, THARUVAI WEST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6704",
            "district": "28",
            "Block": "297",
            "label": "LOABA MATRICULATION HIGH SCHOOL,  ERAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6726",
            "district": "28",
            "Block": "217",
            "label": "RC MS PUTHIYAMPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6731",
            "district": "28",
            "Block": "350",
            "label": "ST.JOSEPH'S.MATRIC HR.SEC.SCHOOL, MANGALAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6735",
            "district": "28",
            "Block": "8",
            "label": "PU MS CAMPBELLABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19285",
            "district": "28",
            "Block": "351",
            "label": "P. THANGAMMAL MEMORIAL HSS POLPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19286",
            "district": "28",
            "Block": "351",
            "label": "GOOD SHEPHERD SCHOOL FOR THE HEARING IMPAIRED, BRYANT NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19287",
            "district": "28",
            "Block": "351",
            "label": "TAGORE MEMORIAL MIDDLE SCHOOL, THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19288",
            "district": "28",
            "Block": "351",
            "label": "ST. IGNATIUS HSS, INNACIARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19289",
            "district": "28",
            "Block": "351",
            "label": "DIRAVIAPURAM TNDTA MS MUTHUKRISHNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19290",
            "district": "28",
            "Block": "351",
            "label": "CORPORATION HSS SAMUELPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19291",
            "district": "28",
            "Block": "351",
            "label": "LITTLE FLOWER RC HS, THERESPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19292",
            "district": "28",
            "Block": "351",
            "label": "CALDWELL HSS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19293",
            "district": "28",
            "Block": "351",
            "label": "ST. JOSEPH GIRLS HSS CRUZPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19294",
            "district": "28",
            "Block": "351",
            "label": "ST. FRANCIS XAVIERS HSS, CHINNAKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19295",
            "district": "28",
            "Block": "351",
            "label": "ST.ALOYSIUS GHSS, CHINNAKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19296",
            "district": "28",
            "Block": "351",
            "label": "DASNEVIS MATHA GIRLS HSS, LIONS TOWN",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19297",
            "district": "28",
            "Block": "351",
            "label": "ST.LASALLE  HIGHER SECONDARY SCHOOL, GEORAGE ROAD, TUTICORIN",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19298",
            "district": "28",
            "Block": "351",
            "label": "KARAPETTAI NADAR BOYS HSS, LIONS TOWN",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19299",
            "district": "28",
            "Block": "351",
            "label": "TNDTA MS SHANMUGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19300",
            "district": "28",
            "Block": "351",
            "label": "KARAPETTAI NADAR GIRLS HIGHER SECONDARY SCHOOL, THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19301",
            "district": "28",
            "Block": "351",
            "label": "HOLY CROSS (G)HSS, VE ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19302",
            "district": "28",
            "Block": "351",
            "label": "HOLY CROSS ANGLO INDIAN HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19303",
            "district": "28",
            "Block": "351",
            "label": "CM HS, TR NAIDU STREET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19304",
            "district": "28",
            "Block": "351",
            "label": "RCHS, RATHINAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19305",
            "district": "28",
            "Block": "351",
            "label": "SAV HSS,MELUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19306",
            "district": "28",
            "Block": "351",
            "label": "VICTORIA GIRLS HSS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19307",
            "district": "28",
            "Block": "351",
            "label": "CM HSS, RENGANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19308",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS ,GIN FACTORY ROAD  THOOTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19309",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS SHUNMUGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19310",
            "district": "28",
            "Block": "351",
            "label": "SUBBIAH VIDYALAYAM GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19311",
            "district": "28",
            "Block": "351",
            "label": "BHARATHIYAR VIDYALAYAM HSS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19312",
            "district": "28",
            "Block": "351",
            "label": "SEENA VANA GHSS, THOOTHUKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19313",
            "district": "28",
            "Block": "351",
            "label": "THANGAM MS, ANNANAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19314",
            "district": "28",
            "Block": "351",
            "label": "ST MARYS BOYS HSS, MILLERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19315",
            "district": "28",
            "Block": "351",
            "label": "AMM SINNAMANI NADAR HS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19316",
            "district": "28",
            "Block": "351",
            "label": "SAKTHI VINAYAGAR HINDU VIDYALAYA HSS CHINDAMBARANAG",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19317",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS, SOUTH NEW STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19318",
            "district": "28",
            "Block": "111",
            "label": "RC MIDDLE SCHOOL KALIYAVOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19319",
            "district": "28",
            "Block": "111",
            "label": "GHSS SAVALAPERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19320",
            "district": "28",
            "Block": "111",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  KEELAPOOVANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19321",
            "district": "28",
            "Block": "111",
            "label": "HINDU MIDDLE SCHOOL CHOKALINGAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19322",
            "district": "28",
            "Block": "111",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SEKKARAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19323",
            "district": "28",
            "Block": "111",
            "label": "GHS KASILINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19324",
            "district": "28",
            "Block": "111",
            "label": "NARAYANAN MS ELLAINAYACKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19325",
            "district": "28",
            "Block": "111",
            "label": "PUMS POTTALURANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19326",
            "district": "28",
            "Block": "111",
            "label": "PUMS VALLANADU SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19327",
            "district": "28",
            "Block": "111",
            "label": "VTVD GHSS  VALLANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19328",
            "district": "28",
            "Block": "111",
            "label": "SARAH MATRICULATION HIGHER SECONDNARY SCHOOL, HAMIDIYANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19329",
            "district": "28",
            "Block": "111",
            "label": "TNDTA MS MANAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19330",
            "district": "28",
            "Block": "111",
            "label": "TNDTA MS ALWARKARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19331",
            "district": "28",
            "Block": "111",
            "label": "PUMS ARAMPANNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19332",
            "district": "28",
            "Block": "111",
            "label": "MEERA MATRICULATION SCHOOL, ARAMPANNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19333",
            "district": "28",
            "Block": "111",
            "label": "GHSS KONGARAYAKURUCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19334",
            "district": "28",
            "Block": "111",
            "label": "PUMS MURAPPANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19335",
            "district": "28",
            "Block": "111",
            "label": "GOVT. H.S.ANAVARATHANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19336",
            "district": "28",
            "Block": "111",
            "label": "MMMS SEYDUNGANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19337",
            "district": "28",
            "Block": "111",
            "label": "HINDU JEYALAKSHMI MS KILAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19338",
            "district": "28",
            "Block": "111",
            "label": "GHSS KARUNKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19339",
            "district": "28",
            "Block": "111",
            "label": "PUMS MELASRIYANTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19340",
            "district": "28",
            "Block": "111",
            "label": "PUMS MANALVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19341",
            "district": "28",
            "Block": "111",
            "label": "GHS  KALVOY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19342",
            "district": "28",
            "Block": "111",
            "label": "GHSS RAMANUJAMPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19343",
            "district": "28",
            "Block": "111",
            "label": "TNDTA MS THATHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19344",
            "district": "28",
            "Block": "297",
            "label": "PUMS PONNANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19345",
            "district": "28",
            "Block": "297",
            "label": "APC VEERABAHU HS PUDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19346",
            "district": "28",
            "Block": "297",
            "label": "MK HS PATMANAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19347",
            "district": "28",
            "Block": "297",
            "label": "MM MAT HSS PATMANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19348",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS PEROOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19349",
            "district": "28",
            "Block": "297",
            "label": "GHSS SIVAKALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19350",
            "district": "28",
            "Block": "297",
            "label": "SMA GHSS SIRUTHONDANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19351",
            "district": "28",
            "Block": "297",
            "label": "GHSS VALAVALLAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19352",
            "district": "28",
            "Block": "297",
            "label": "ST ALOYSIUS MS KORKAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19353",
            "district": "28",
            "Block": "297",
            "label": "GHSS UMARIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19354",
            "district": "28",
            "Block": "297",
            "label": "TDTA GOOD SHEPHERD HS IDAIYERKADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19355",
            "district": "28",
            "Block": "297",
            "label": "PUMS PULVAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19356",
            "district": "28",
            "Block": "297",
            "label": "ST ANTONYS HSS PAZHAIYAKAYAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19357",
            "district": "28",
            "Block": "297",
            "label": "GHSS MUKKANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19358",
            "district": "28",
            "Block": "297",
            "label": "RC MS NADUVAIKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19359",
            "district": "28",
            "Block": "297",
            "label": "POPE MEMORIAL HR.SEC.SCHOOL SAWYERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19360",
            "district": "28",
            "Block": "297",
            "label": "ST.MARYS (G)HSS,  SAWYERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19361",
            "district": "28",
            "Block": "297",
            "label": "AMA HINDU HSS SIVATHAIYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19362",
            "district": "28",
            "Block": "297",
            "label": "GGHSS ERAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19363",
            "district": "28",
            "Block": "297",
            "label": "ST THERESAL MS ERAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19364",
            "district": "28",
            "Block": "297",
            "label": "TUCKER HR SEC. SCHOOL.. PANNAIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19365",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS PANDARAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19366",
            "district": "28",
            "Block": "297",
            "label": "SEVENTH DAY ADVENTIST MATRIC, SRIVAIKUNDAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19367",
            "district": "28",
            "Block": "297",
            "label": "ST JAMES MATRIC HS SRIVAIKUNTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19368",
            "district": "28",
            "Block": "297",
            "label": "SRI KGS B HSS SRIVAIKUNTAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19369",
            "district": "28",
            "Block": "297",
            "label": "SRI A.K.S GOVT. GIRLS HIGHER SECONDARY SCHOOL , SRIVAIKUNTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19370",
            "district": "28",
            "Block": "297",
            "label": "TVRK H VID MAT HSS SRIVAIKUNTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19371",
            "district": "28",
            "Block": "297",
            "label": "ULAGAMMAL ANNI MEMORIAL MS SRIVAIKUNDAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19372",
            "district": "28",
            "Block": "8",
            "label": "PU MS PULIYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19373",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS PALANIAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19374",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MIDDLE SCHOOL, UDAYARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19375",
            "district": "28",
            "Block": "8",
            "label": "PUMS MELAVELLAMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19376",
            "district": "28",
            "Block": "8",
            "label": "GVG TNDTA MS  PILLAIYANMANAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19377",
            "district": "28",
            "Block": "8",
            "label": "ERS MS MUKUPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19378",
            "district": "28",
            "Block": "8",
            "label": "ST.MARK'S HSS  MUKUPERI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19379",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS NEIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19380",
            "district": "28",
            "Block": "8",
            "label": "ST.MARY'S (G) HS, KATCHANAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19381",
            "district": "28",
            "Block": "8",
            "label": "SRI GANESAR HSS PANICKANADARKUDIERUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19382",
            "district": "28",
            "Block": "8",
            "label": "KAMARAJ  HIGHER SECONDARY SCHOOL NALUMAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19383",
            "district": "28",
            "Block": "8",
            "label": "PUMS, KURUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19384",
            "district": "28",
            "Block": "8",
            "label": "ST.LUCIA HS KURUMBUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19385",
            "district": "28",
            "Block": "8",
            "label": "HINDU MS VARANDIAVEL& AGOPALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19386",
            "district": "28",
            "Block": "8",
            "label": "TNDTA HS, MARANTHALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19387",
            "district": "28",
            "Block": "8",
            "label": "PUMS,SOUTH AUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19388",
            "district": "28",
            "Block": "8",
            "label": "RC. ST. MICHAEL'S MIDDLE SCHOOL, SERNTHAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19389",
            "district": "28",
            "Block": "8",
            "label": "ST.JOSEPH HSS PUNNAIKAYAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19390",
            "district": "28",
            "Block": "8",
            "label": "HINDU HSS ALWARTHIRUNAGARI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19391",
            "district": "28",
            "Block": "8",
            "label": "GHSS MAVADIPANNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19392",
            "district": "28",
            "Block": "8",
            "label": "S SHUNMUGASUNDARA NADAR HSS AUTHOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19393",
            "district": "28",
            "Block": "8",
            "label": "GHSS AUTHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19394",
            "district": "28",
            "Block": "8",
            "label": "PUMS, NORTH AUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19395",
            "district": "28",
            "Block": "8",
            "label": "JAMES MEMORIAL MATRIC HR. SEC SCHOOL, PRAKASAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19396",
            "district": "28",
            "Block": "8",
            "label": "DEAF MS THIRUMARAIYOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19397",
            "district": "28",
            "Block": "8",
            "label": "SOLOMON MATRICULATION HIGHER SECONDARY SCHOOL, NAZARETH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19398",
            "district": "28",
            "Block": "8",
            "label": "ST.JOHN'S GIRLS' HIGHER SECONDARY SCHOOL , NAZARETH",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19399",
            "district": "28",
            "Block": "8",
            "label": "MARGOSCHIS HIGHER SECONDARY SCHOOL NAZARETHH",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19400",
            "district": "28",
            "Block": "8",
            "label": "PUMS , AMBALACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19401",
            "district": "28",
            "Block": "8",
            "label": "ASP MS KARUVELAMPADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19402",
            "district": "28",
            "Block": "8",
            "label": "TDTA KURUKALPERI HSS ASIRVATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19403",
            "district": "28",
            "Block": "8",
            "label": "EKA RATCHAGAR SABAI HIGHER SECONDARY SCHOOL, SALAIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19404",
            "district": "28",
            "Block": "320",
            "label": "ASA GOVT BOYS HSS, TIRUCHENDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19405",
            "district": "28",
            "Block": "320",
            "label": "R C HIGH SCHOOL, AMALINAGAR TIRUCHENDUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19406",
            "district": "28",
            "Block": "320",
            "label": "SM GHSS,TIRUCHENDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19407",
            "district": "28",
            "Block": "320",
            "label": "PUMS KEELAKANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19408",
            "district": "28",
            "Block": "320",
            "label": "CENTRAL HR.SEC.SCHOOL, KAYALPATANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19409",
            "district": "28",
            "Block": "320",
            "label": "ZUBAIDA HSS, KAYALPATNAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19410",
            "district": "28",
            "Block": "320",
            "label": "GGHSS,KAYALPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19411",
            "district": "28",
            "Block": "320",
            "label": "PUMS ODAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19412",
            "district": "28",
            "Block": "320",
            "label": "L.K. MATRICULATION HIGHER SECONDARY SCHOOL, KAYALPATNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19413",
            "district": "28",
            "Block": "320",
            "label": "L.K. HR. SEC. SCHOOL, KAYALPATNAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19414",
            "district": "28",
            "Block": "320",
            "label": "PUMS THAIKKA ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19415",
            "district": "28",
            "Block": "320",
            "label": "KAMLAVATI SENIOR SECONDARY SCHOOL, SAHUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19416",
            "district": "28",
            "Block": "320",
            "label": "K A HR SEC SCHOOL,PEYANVILAI ARUMUGANERI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19417",
            "district": "28",
            "Block": "320",
            "label": "ARULNERI HINDU HS THIRUMALAIYAPPAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19418",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MS, RAJAMANIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19419",
            "district": "28",
            "Block": "320",
            "label": "GGHSS,ARUMUGANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19420",
            "district": "28",
            "Block": "320",
            "label": "SSMID.S, MADATHUVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19421",
            "district": "28",
            "Block": "320",
            "label": "SARASWATHI MS, ARUMUGANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19422",
            "district": "28",
            "Block": "320",
            "label": "HIN. HS, ARUMUGANERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19423",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MS, VEERAMANICKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19424",
            "district": "28",
            "Block": "320",
            "label": "ST.JOSEPH HSS, ADAIKALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19425",
            "district": "28",
            "Block": "320",
            "label": "ST. THOMAS HSS, VIRAPANDIYANPATNAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19426",
            "district": "28",
            "Block": "320",
            "label": "HINDU HS, POOCHIKADU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19427",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MIDDLE SCHOOL  JERUSELAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19428",
            "district": "28",
            "Block": "320",
            "label": "PERUNTHALAIVAR KAMARAJAR GOVERNMENT HIGHER SECONDARY SCHOOL,  THERIKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19429",
            "district": "28",
            "Block": "320",
            "label": "GHSS, KAYAMOZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19430",
            "district": "28",
            "Block": "320",
            "label": "PUMS KEELANADUNALUMOOLAIKINARU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19431",
            "district": "28",
            "Block": "320",
            "label": "GOVT HS, NADUNALUMOOLAIKINARU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19432",
            "district": "28",
            "Block": "320",
            "label": "PUMS N.MUTHAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19433",
            "district": "28",
            "Block": "320",
            "label": "ST ANTONYS RC MIDDLE SCHOOL PUTHUKINATHANVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19434",
            "district": "28",
            "Block": "320",
            "label": "TDTA MARY SOUNDARAPANDIAN HIGH SCHOOL, PITCHIVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19435",
            "district": "28",
            "Block": "369",
            "label": "PUMS   PUTHUMANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19436",
            "district": "28",
            "Block": "369",
            "label": "MARY ANN BEST GIRL'S HIGHER SECONDARY SCHOOL, PANDARANCHETTIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19437",
            "district": "28",
            "Block": "369",
            "label": "TDTA HSS, CHRISTIANAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19438",
            "district": "28",
            "Block": "369",
            "label": "ADW MS SUTHANTHIRANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19439",
            "district": "28",
            "Block": "369",
            "label": "KKR TDTA HIGH SCHOOL, PARAMANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19440",
            "district": "28",
            "Block": "369",
            "label": "BAM HSS, VELLALANVILLAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19441",
            "district": "28",
            "Block": "369",
            "label": "ST.ANNES, HIGH SCHOOL KEELARAMASAMIAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19442",
            "district": "28",
            "Block": "369",
            "label": "SI.BA.SIVANTHI ADITANAR HIGH SCHOOL VEPPANKADU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19443",
            "district": "28",
            "Block": "369",
            "label": "AMBROSE HIGHER SECONDARY SCHOOL, MEIGNANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19444",
            "district": "28",
            "Block": "369",
            "label": "ELLIOTT TUXFORD GIRLS HIGHER SECONDARY SCHOOL, MEGNANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19445",
            "district": "28",
            "Block": "369",
            "label": "SENTHIL ANDAVAR MS, SEERKATCHI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19446",
            "district": "28",
            "Block": "369",
            "label": "Anitha Kumaran Matric & Higher Secondary School, Thandupathu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19447",
            "district": "28",
            "Block": "369",
            "label": "SRI RKC HR. SEC. SCHOOL, UDANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19448",
            "district": "28",
            "Block": "369",
            "label": "GHS, VENKATRAMANUJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19449",
            "district": "28",
            "Block": "369",
            "label": "THIRU ARUL HSS, KULASEKARANPATINAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19450",
            "district": "28",
            "Block": "369",
            "label": "VALLIAMMAIYAR GIRLS HR.SEC.SCHOOL KULASEKHARAPATNAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19451",
            "district": "28",
            "Block": "369",
            "label": "ST. MARYS MS MANAPADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19452",
            "district": "28",
            "Block": "369",
            "label": "ST. JOSEPH HSS, MANAPADU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19453",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, THERIPANAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19454",
            "district": "28",
            "Block": "280",
            "label": "AVE MARIA MATRIC HR.SEC.SCHOOL,PANNAMPARAI JUNCTION",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19455",
            "district": "28",
            "Block": "280",
            "label": "TDTA RANJI AARON MEMORIAL HIGHER SECONDARY SCHOOL,ANANDAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19456",
            "district": "28",
            "Block": "280",
            "label": "ST. Micheal's High School, Nochikulam",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19457",
            "district": "28",
            "Block": "280",
            "label": "RC MS .NEDUNKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19458",
            "district": "28",
            "Block": "280",
            "label": "CMS EVANGELICAL HIGH SCHOOL, KALUNGUVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19459",
            "district": "28",
            "Block": "280",
            "label": "GHSS KOMBANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19460",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, SIRAPPUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19461",
            "district": "28",
            "Block": "280",
            "label": "RC MS, CHIDAMBARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19462",
            "district": "28",
            "Block": "280",
            "label": "GHS, PUDUKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19463",
            "district": "28",
            "Block": "280",
            "label": "SRI MUTHARAMMAN HINDU MS , VIJAYARAMAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19464",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, ANBIN NAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19465",
            "district": "28",
            "Block": "280",
            "label": "St.Michael's Higher secondary school, Mudalur",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19466",
            "district": "28",
            "Block": "280",
            "label": "SRI SARATHA SACRED HEART MS, PALLAKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19467",
            "district": "28",
            "Block": "280",
            "label": "SRI PARVATHI MS, PITCHIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19468",
            "district": "28",
            "Block": "280",
            "label": "GHS SUNDANKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19469",
            "district": "28",
            "Block": "280",
            "label": "TNDTA ALL SAINTS MS,POHLIARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19470",
            "district": "28",
            "Block": "280",
            "label": "ST.MARYS HR SEC SCHOOL,  POTHAKALANVILLAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19471",
            "district": "28",
            "Block": "280",
            "label": "SSN., GOVT.HSS KOMMADIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19472",
            "district": "28",
            "Block": "280",
            "label": "Manalmatha Hr.Sec.School, Sokkankudieruppu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19473",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, NALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19474",
            "district": "28",
            "Block": "280",
            "label": "St.Anne's High School, Thattarmadam",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19475",
            "district": "28",
            "Block": "280",
            "label": "TNTDA MS,ATHISAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19476",
            "district": "28",
            "Block": "280",
            "label": "ST JOSEPH'S RC MS KADAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19477",
            "district": "28",
            "Block": "280",
            "label": "St,Antony's Middle School, Puchikadu",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19478",
            "district": "28",
            "Block": "280",
            "label": "PUMS, USARATHUKUDIYERUPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19479",
            "district": "28",
            "Block": "280",
            "label": "PUMS,PUTHANTHARUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19480",
            "district": "28",
            "Block": "280",
            "label": "Little Flower High School, Periyathalai",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19481",
            "district": "28",
            "Block": "280",
            "label": "GHSS PADUKKAPATHU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19482",
            "district": "28",
            "Block": "280",
            "label": "SRI MARIAMMAN HINDU HR. SEC. SCHOOL , SATHANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19483",
            "district": "28",
            "Block": "280",
            "label": "TNDTA RMP PULAMADAN CHETTIAR NATIONAL HIGHER SECONDARY SCHOOL , SATHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19484",
            "district": "28",
            "Block": "280",
            "label": "A E IBRAHIM MiDDLE SCHOOL, SATHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19485",
            "district": "28",
            "Block": "280",
            "label": "SACRED HEART HSS,SATHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19486",
            "district": "28",
            "Block": "280",
            "label": "ST JOSEPH GIRLS HIGHER SECONDARY SCHOOL, SATTANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19487",
            "district": "28",
            "Block": "217",
            "label": "TDTA HIGH SCHOOL, K.KAILASAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19488",
            "district": "28",
            "Block": "217",
            "label": "RC MS PULIYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19489",
            "district": "28",
            "Block": "217",
            "label": "GOVERNMENT HIGH SCHOOL PARIVILLIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19490",
            "district": "28",
            "Block": "217",
            "label": "MUTHUKARUPPAN HARIJAN HSS,SUBBAMMALPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19491",
            "district": "28",
            "Block": "217",
            "label": "T.D.T.A.K.G.M.HR.SEC.SCHOOL, S.KAILASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19492",
            "district": "28",
            "Block": "217",
            "label": "PUMS,OSANOOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19493",
            "district": "28",
            "Block": "217",
            "label": "PUMS,KEELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19494",
            "district": "28",
            "Block": "217",
            "label": "GHSS, PASUVANTHANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19495",
            "district": "28",
            "Block": "217",
            "label": "ST JOSEPHS HR.SEC.SCHOOL, KEELAMUDIMAN",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19496",
            "district": "28",
            "Block": "217",
            "label": "PUMS, VEERANSUNDARALINGAMNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19497",
            "district": "28",
            "Block": "217",
            "label": "V.O.C. GHSS, Ottapidaram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19498",
            "district": "28",
            "Block": "217",
            "label": "TMB MCAVOY RURAL HSS, OTTAPIDARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19499",
            "district": "28",
            "Block": "217",
            "label": "JOHN THE BAPTIST HSS PUTHIYAMPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19500",
            "district": "28",
            "Block": "217",
            "label": "GHSS KURUKKUSALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19501",
            "district": "28",
            "Block": "217",
            "label": "PU MS,KEELASEITHALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19502",
            "district": "28",
            "Block": "217",
            "label": "GHS, EPPODUMVENDRAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19503",
            "district": "28",
            "Block": "217",
            "label": "GHS, VEDANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19504",
            "district": "28",
            "Block": "217",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEPPALODAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19505",
            "district": "28",
            "Block": "217",
            "label": "PUMS, PATTNAMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19506",
            "district": "28",
            "Block": "217",
            "label": "GHS KOLLANKINARU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19507",
            "district": "28",
            "Block": "217",
            "label": "GHSS THARUVAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19508",
            "district": "28",
            "Block": "217",
            "label": "NATARAJAN HSS, KATTUNAYAKANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19509",
            "district": "28",
            "Block": "402",
            "label": "T.V.A.NALLALAGU NADAR HIGHER SECONDARY SCHOOL , PERILOVANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19510",
            "district": "28",
            "Block": "402",
            "label": "GHS Kodangipatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19511",
            "district": "28",
            "Block": "402",
            "label": "T.N.D.T.A. MS, Chithavanayakkanpatti",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19512",
            "district": "28",
            "Block": "402",
            "label": "S.R. MS, AyanBommayapuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19513",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Kalugachalapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19514",
            "district": "28",
            "Block": "402",
            "label": "GHS Padarnthapuli",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19515",
            "district": "28",
            "Block": "402",
            "label": "GHS Ariyanayagipuram",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19516",
            "district": "28",
            "Block": "402",
            "label": "GHSS Sivagnanapuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19517",
            "district": "28",
            "Block": "402",
            "label": "PUMS, SAKKAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19518",
            "district": "28",
            "Block": "402",
            "label": "TNDTA MS Marthandampatti",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19519",
            "district": "28",
            "Block": "402",
            "label": "H.N. MS, Panaiyur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19520",
            "district": "28",
            "Block": "402",
            "label": "GHSS,KULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19521",
            "district": "28",
            "Block": "402",
            "label": "TNDTA MS, V.VEDAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19522",
            "district": "28",
            "Block": "402",
            "label": "St.Paul's HS - V.Vedapatti",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19523",
            "district": "28",
            "Block": "402",
            "label": "GOVT HIGH SCHOOL MELMANTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19524",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Thathaneri",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19525",
            "district": "28",
            "Block": "402",
            "label": "H.G.K.HS  K.Thangammalpuram",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19526",
            "district": "28",
            "Block": "402",
            "label": "ST.ANTONY'S HS, PERIYASAMYPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19527",
            "district": "28",
            "Block": "402",
            "label": "St.Sebastian MS, Vembar",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19528",
            "district": "28",
            "Block": "402",
            "label": "GHSS  Vembar",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19529",
            "district": "28",
            "Block": "402",
            "label": "ST.MARY'S MATRICULATION SCHOOL, VEMBAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19530",
            "district": "28",
            "Block": "402",
            "label": "GHSS VILATHIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19531",
            "district": "28",
            "Block": "402",
            "label": "C.A.R.M.GHSS-Vilathikulam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19532",
            "district": "28",
            "Block": "402",
            "label": "ST. TERESAL'S MS SIPPIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19533",
            "district": "28",
            "Block": "402",
            "label": "ST.LOUIS HSS, KEELA VAIPPAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19534",
            "district": "28",
            "Block": "402",
            "label": "PUMS, K.SUBRAMANIAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19535",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Neeravi Puthupatti",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19536",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Nedunkulam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19537",
            "district": "28",
            "Block": "402",
            "label": "N.H.S. Karisalkulam",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19538",
            "district": "28",
            "Block": "257",
            "label": "HNHS PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19539",
            "district": "28",
            "Block": "257",
            "label": "GHSS Pudur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19540",
            "district": "28",
            "Block": "257",
            "label": "PUMS KULAKATANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19541",
            "district": "28",
            "Block": "257",
            "label": "PUMS METTILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19542",
            "district": "28",
            "Block": "257",
            "label": "P V K S GOVT HIGH SCHOOL, SENNAMAREDDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19543",
            "district": "28",
            "Block": "257",
            "label": "PUMS MUTHUSAMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19544",
            "district": "28",
            "Block": "257",
            "label": "PUMS MANIYAKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19545",
            "district": "28",
            "Block": "257",
            "label": "PUMS KEELAKARANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19546",
            "district": "28",
            "Block": "257",
            "label": "PUMS SIVALARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19547",
            "district": "28",
            "Block": "257",
            "label": "PUMS THAPPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19548",
            "district": "28",
            "Block": "257",
            "label": "PUMS L V PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19549",
            "district": "28",
            "Block": "257",
            "label": "HNHSS N.VEDAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19550",
            "district": "28",
            "Block": "257",
            "label": "TNDTA MS NATHATHUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19551",
            "district": "28",
            "Block": "257",
            "label": "GHSS, BOOTHALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19552",
            "district": "28",
            "Block": "257",
            "label": "PUMS KOVILKUARETTIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19553",
            "district": "28",
            "Block": "257",
            "label": "TDTA JAYARAJ ANNAPACKIAM HIGH SCHOOL, NAGALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19554",
            "district": "28",
            "Block": "257",
            "label": "PUMS VOWALTHOTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19555",
            "district": "28",
            "Block": "257",
            "label": "SKK H HS Nagalapuram",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19556",
            "district": "28",
            "Block": "257",
            "label": "SAN HSS Nagalapuram",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19557",
            "district": "28",
            "Block": "257",
            "label": "PUMS SINGILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19558",
            "district": "28",
            "Block": "257",
            "label": "GHS KADALKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19559",
            "district": "28",
            "Block": "257",
            "label": "PUMS AYANVADAMALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19560",
            "district": "28",
            "Block": "257",
            "label": "MPMS RAMACHANDRAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19561",
            "district": "28",
            "Block": "257",
            "label": "PUMS AYANKARISALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19562",
            "district": "28",
            "Block": "257",
            "label": "PUMS MAVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19563",
            "district": "28",
            "Block": "257",
            "label": "PUMS, MASARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19564",
            "district": "28",
            "Block": "257",
            "label": "HNMS MUTHULAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19565",
            "district": "28",
            "Block": "257",
            "label": "GHS M.KOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19566",
            "district": "28",
            "Block": "257",
            "label": "PUMS MELANAMBIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19567",
            "district": "28",
            "Block": "257",
            "label": "GHSS SENKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19568",
            "district": "28",
            "Block": "257",
            "label": "GHSS VEMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19569",
            "district": "28",
            "Block": "257",
            "label": "NMS VEERAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19570",
            "district": "28",
            "Block": "257",
            "label": "PUMS, KEELA ARUNACHALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19571",
            "district": "28",
            "Block": "350",
            "label": "PUMS UMARIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19572",
            "district": "28",
            "Block": "350",
            "label": "PUMS MELATHATTAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19573",
            "district": "28",
            "Block": "350",
            "label": "VOC GOVT MS THATTAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19574",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS PERURANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19575",
            "district": "28",
            "Block": "350",
            "label": "GHSS MUDIVAITHANENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19576",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS SERVAIKARANMADAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19577",
            "district": "28",
            "Block": "350",
            "label": "TNDTA ABISHEKANATHAR HSS KULAYANKARISAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19578",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS KORAMPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19579",
            "district": "28",
            "Block": "350",
            "label": "DEVAPITCHAI MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19580",
            "district": "28",
            "Block": "350",
            "label": "GOOD SHEPHERD MODEL SCHOOL KORAMPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19581",
            "district": "28",
            "Block": "350",
            "label": "SDR SCHOOL KORAMPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19582",
            "district": "28",
            "Block": "350",
            "label": "TDTA PSP HSS PUTHUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19583",
            "district": "28",
            "Block": "350",
            "label": "GHSS SORISPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19584",
            "district": "28",
            "Block": "350",
            "label": "BMC HIGHER SECONDARY SCHOOL  MILLERPURAM THOOTHUKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19585",
            "district": "28",
            "Block": "350",
            "label": "LUCIA TRANSITIONAL MIDDLE SCHOOL FOR THE INTELLECTUAL DISABLED",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19586",
            "district": "28",
            "Block": "350",
            "label": "ALAGAR PUBLIC SCHOOL THOOTHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19587",
            "district": "28",
            "Block": "350",
            "label": "ST.XAVIER'S HIGH SCHOOL, T.SAVERIARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19588",
            "district": "28",
            "Block": "350",
            "label": "S.P.K.NADAR M.S MAPPILAIYURANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19589",
            "district": "28",
            "Block": "350",
            "label": "ST. ANTONY'S HS SILUVAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19590",
            "district": "28",
            "Block": "350",
            "label": "V.V.D MEMORIAL HSS AROCKIYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19591",
            "district": "28",
            "Block": "350",
            "label": "HARBOUR HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19592",
            "district": "28",
            "Block": "350",
            "label": "ST. ANTONY'S MATRIC. HR.SEC.SCHOOL, MACHADO NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19593",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS MULLAKKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19594",
            "district": "28",
            "Block": "350",
            "label": "K.T.KOSALRAM HIGH SCHOOL, MUTHIAHPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19595",
            "district": "28",
            "Block": "350",
            "label": "GHSS M.THANGAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19596",
            "district": "28",
            "Block": "350",
            "label": "ST.ANTONY'S HS M.SAVARIYARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19597",
            "district": "28",
            "Block": "350",
            "label": "SPIC NAGAR HIGHER SECONDARY SCHOOL MUTHIAH PURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20998",
            "district": "28",
            "Block": "320",
            "label": "PEARL PUBLIC SCHOOL, ARUMUGANERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21039",
            "district": "28",
            "Block": "217",
            "label": "SPRING MATRICULATION SCHOOL, CHANDRAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21134",
            "district": "28",
            "Block": "138",
            "label": "PUMS, IDAISEVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21189",
            "district": "28",
            "Block": "350",
            "label": "PUMS, ATHIMARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21192",
            "district": "28",
            "Block": "138",
            "label": "GHSS, ILAYARASANENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21193",
            "district": "28",
            "Block": "138",
            "label": "GHSS, PILLAIYARNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21226",
            "district": "28",
            "Block": "138",
            "label": "SHYAMALA H.S.S.,ILUPPIYURANI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21411",
            "district": "28",
            "Block": "280",
            "label": "GHS PANNAMPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21412",
            "district": "28",
            "Block": "350",
            "label": "GHS POTTALKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21541",
            "district": "28",
            "Block": "320",
            "label": "THIRUKKUDUMBAM   HIGH SCHOOL , ALANTHALAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21822",
            "district": "28",
            "Block": "138",
            "label": "SHRI PAPER ANANDA MATRICULATION  SCHOOL ILAYARASANENDAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21917",
            "district": "28",
            "Block": "320",
            "label": "WISDOM PUBLIC SCHOOL, VEERAPANDIAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21918",
            "district": "28",
            "Block": "402",
            "label": "SRI AMBAL VIDHYALAYA, AMBALNAGAR VILATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21977",
            "district": "28",
            "Block": "280",
            "label": "MARY IMMACULATE MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21978",
            "district": "28",
            "Block": "351",
            "label": "ST.THOMAS THE APOSTLE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22270",
            "district": "28",
            "Block": "350",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22271",
            "district": "28",
            "Block": "350",
            "label": "SRI LALITHA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22635",
            "district": "28",
            "Block": "111",
            "label": "JOS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22636",
            "district": "28",
            "Block": "297",
            "label": "THE VIKASA INTERNATIONAL SCHOOL, SAWYERPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22637",
            "district": "28",
            "Block": "138",
            "label": "HOLY TRINITY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22638",
            "district": "28",
            "Block": "138",
            "label": "VELS VIDHYALAYA .CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22639",
            "district": "28",
            "Block": "138",
            "label": "VEERABAGU VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22640",
            "district": "28",
            "Block": "217",
            "label": "GHS AKKANAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22641",
            "district": "28",
            "Block": "257",
            "label": "GHS KARUPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22691",
            "district": "28",
            "Block": "297",
            "label": "ATOMIC ENERGY CENTRAL SCHOOL, PAZHAYAKAYAL",
            "Sch_type": "Central Govt",
            "Category": "Middle School"
        },
        {
            "key": "22843",
            "district": "28",
            "Block": "350",
            "label": "SERVITE SCHOOL, MARAVANMADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22848",
            "district": "28",
            "Block": "217",
            "label": "KING OF KINGS SCHOOL - CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22956",
            "district": "28",
            "Block": "297",
            "label": "GHS THOLAPPANPANNAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22983",
            "district": "28",
            "Block": "320",
            "label": "KAMLAVATI HIGHER SECONDARY SCHOOL,SAHUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23002",
            "district": "28",
            "Block": "297",
            "label": "THE VIKASA SCHOOL CBSE, SAWYERPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23040",
            "district": "28",
            "Block": "351",
            "label": "ST.THOMAS  SCHOOL, INNACIARPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23041",
            "district": "28",
            "Block": "351",
            "label": "VICTORIA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23095",
            "district": "28",
            "Block": "369",
            "label": "ESTHER SANTHAM HR SEC SCHOOL MEIGNANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23110",
            "district": "28",
            "Block": "320",
            "label": "ST JOSEPHS SCHOOL VEERAPANDIYANPATNAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23218",
            "district": "28",
            "Block": "138",
            "label": "RAVILLA VIDYALAYA - CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23257",
            "district": "28",
            "Block": "138",
            "label": "GHS ILAMBUVANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23258",
            "district": "28",
            "Block": "350",
            "label": "ST.ANN'S SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23266",
            "district": "28",
            "Block": "369",
            "label": "JAY INTERNATIONAL SCHOOL NANGAIMOZHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23284",
            "district": "28",
            "Block": "138",
            "label": "KARIDHA PUBLIC SCHOOL, ETTAYAPURAM ROAD, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23298",
            "district": "28",
            "Block": "217",
            "label": "CHANDRA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23317",
            "district": "28",
            "Block": "138",
            "label": "KAMARAJ INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23572",
            "district": "28",
            "Block": "138",
            "label": "LAKSHMI MILLS MATRICULATION SCHOOL , INAMMANIACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23573",
            "district": "28",
            "Block": "138",
            "label": "PUNITHA OHM GLOBAL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23574",
            "district": "28",
            "Block": "138",
            "label": "BRINDAVAN'S KIDS UNIVERSITY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23575",
            "district": "28",
            "Block": "217",
            "label": "VELAVAN VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23576",
            "district": "28",
            "Block": "320",
            "label": "KANCHI SANKARA VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23577",
            "district": "28",
            "Block": "402",
            "label": "G K VINAYAKA VIDYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23815",
            "district": "28",
            "Block": "111",
            "label": "Thoothukkudi District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23897",
            "district": "28",
            "Block": "217",
            "label": "HNUP MATRICULATION SCHOOL PUTHIAMPUTHUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23934",
            "district": "28",
            "Block": "257",
            "label": "GHSS Pudur",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24029",
            "district": "28",
            "Block": "350",
            "label": "PUPS K THIRAVIYAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24030",
            "district": "28",
            "Block": "350",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24046",
            "district": "28",
            "Block": "369",
            "label": "AK SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24076",
            "district": "28",
            "Block": "402",
            "label": "PUMS, SAKKAMMALPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24077",
            "district": "28",
            "Block": "402",
            "label": "PUPS, Solaimalaiyanpatti",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "29": [
        {
            "key": "383",
            "district": "29",
            "Block": "357",
            "label": "SRI VIDYA MANDIR MATRIC. HR.SEC.SCHOOL,THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "384",
            "district": "29",
            "Block": "366",
            "label": "HOLY CROSS GIRLS HSS,TEPPAKULAM , TRICHY-2",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "387",
            "district": "29",
            "Block": "367",
            "label": "St. Philomena's Girls Hr. Sec. School, Melapudur,",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1000",
            "district": "29",
            "Block": "367",
            "label": "Mannar Memorial Mat. School, Col. Off. Road.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1184",
            "district": "29",
            "Block": "343",
            "label": "JAMAL MOHAMED COLLEGE AYESHA GIRLS MATRIC HR SEC SCHOOL,KAJANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1241",
            "district": "29",
            "Block": "343",
            "label": "AROCKIAMATHA MATRIC HR SEC SCHOOL, KARUMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1354",
            "district": "29",
            "Block": "343",
            "label": "CHELLAMMAL MATRIC. BOYS HR SEC SCHOOL, VENGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1676",
            "district": "29",
            "Block": "176",
            "label": "PUMS,Yagapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1782",
            "district": "29",
            "Block": "357",
            "label": "SOWDAMBIKAA MATRIC BOYS HR. SEC.SCHOOL, THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1839",
            "district": "29",
            "Block": "15",
            "label": "SEVAI SHANTHI MATRICULATION HIGHER SECONDARY SCHOOL,PETTAVAITHALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2197",
            "district": "29",
            "Block": "343",
            "label": "BHARATHI MATRIC HR SEC SCHOOL, KK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2248",
            "district": "29",
            "Block": "172",
            "label": "DON BOSKO MATRIC HIGH SCHOOL,SOUTH IRUNGALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2265",
            "district": "29",
            "Block": "367",
            "label": "SERVITE MATRICULATION HIGHER SECONDARY SCHOOL,KEELAPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2307",
            "district": "29",
            "Block": "367",
            "label": "ATMRCM Vasavi Vidhyalaya Matriculation School, Cantonment.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2321",
            "district": "29",
            "Block": "366",
            "label": "VISCOUNTESS GOSHEN GOVT. GIRLS MUSLIM HSS, TRICHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2465",
            "district": "29",
            "Block": "171",
            "label": "HOLY CROSS MATRIC HR. SEC. SCHOOL, SOMARASAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2466",
            "district": "29",
            "Block": "343",
            "label": "SANTA MARIA MATRICULATION HIGHER SECONDARY SCHOOL, CRAWFORD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2556",
            "district": "29",
            "Block": "172",
            "label": "ANVARUL MUSLIMEEN M.S",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2580",
            "district": "29",
            "Block": "168",
            "label": "MONTFORT MAT.HSS, MANJAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2590",
            "district": "29",
            "Block": "15",
            "label": "CHINMAYA VIDYALAYA MATRIC HSS SRIRANGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2773",
            "district": "29",
            "Block": "172",
            "label": "SRI SARAVANABHAVA VIDYALAYA MATRIC HR. SEC. SCHOOL,MANNACHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2789",
            "district": "29",
            "Block": "367",
            "label": "ST.JOSEPHS ANGLO INDIAN GIRLS HR. SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2997",
            "district": "29",
            "Block": "381",
            "label": "DE BRITTO MS, THIRUKAVALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3214",
            "district": "29",
            "Block": "258",
            "label": "DALMIA VIDYA MANDIR, DALMIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3234",
            "district": "29",
            "Block": "152",
            "label": "SRI VIVEKANANDA MATRIC SCHOOL UMAR NAGAR SIRUDAIYUR, LALGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3240",
            "district": "29",
            "Block": "357",
            "label": "VIMALA M HSS THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3277",
            "district": "29",
            "Block": "168",
            "label": "LAKSHMI MATRICULATION HR. SEC. SCHOOL.MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3303",
            "district": "29",
            "Block": "168",
            "label": "SRI GURU MATRIC HR. SEC. SCHOOL, MANAPARAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3316",
            "district": "29",
            "Block": "176",
            "label": "PUMS,VADAKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3425",
            "district": "29",
            "Block": "172",
            "label": "M.A.M.MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3464",
            "district": "29",
            "Block": "192",
            "label": "AMALA MATRIC HR SEC SCHOOL, MUSIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3485",
            "district": "29",
            "Block": "367",
            "label": "Al-Jamieathus Sadhik Mat. School, Khajamalai.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3493",
            "district": "29",
            "Block": "168",
            "label": "VINMATHEE MATRIC HSS, MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3593",
            "district": "29",
            "Block": "176",
            "label": "PUMS,D.PUTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3646",
            "district": "29",
            "Block": "317",
            "label": "AIED MS SITTILARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3670",
            "district": "29",
            "Block": "171",
            "label": "SBIOA MATRIC HR. SEC. SCHOOL, AIR PORT K.K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3738",
            "district": "29",
            "Block": "343",
            "label": "OM MARUTHI MATRICULATION HIGH SCHOOL,KARUMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3796",
            "district": "29",
            "Block": "343",
            "label": "PERIYAR CENT MEM MAT HR SEC SCHOOL, PERIYAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3861",
            "district": "29",
            "Block": "343",
            "label": "ST. LITTLE FLOWER MATRIC HIGHER SECONDARY SCHOOL, KATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3862",
            "district": "29",
            "Block": "343",
            "label": "VVJMPV MATRIC HR SEC SCHOOL , POLICE COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3896",
            "district": "29",
            "Block": "15",
            "label": "VIGNESH SRI RENGA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3925",
            "district": "29",
            "Block": "367",
            "label": "St. Arnold's Mat. School, Ponmalaipatti.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3987",
            "district": "29",
            "Block": "258",
            "label": "DALMIA HSS,DALMIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4009",
            "district": "29",
            "Block": "258",
            "label": "CHILD JESUS MATRI.S,PULLAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4054",
            "district": "29",
            "Block": "152",
            "label": "NEST MATRICULATION HSS,LALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4065",
            "district": "29",
            "Block": "343",
            "label": "CSI MATRIC HIGH SCHOOL ,PONMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4224",
            "district": "29",
            "Block": "171",
            "label": "INFANT JESUS MATRIC. HR. SEC. SCHOOL, EDAMALAIPATTI PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4234",
            "district": "29",
            "Block": "381",
            "label": "SAHAYA ANNAI MATRICULATION SCHOOL, AZAD ROAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4321",
            "district": "29",
            "Block": "343",
            "label": "YWCA MAT.HIGH SCHOOL ., ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4326",
            "district": "29",
            "Block": "172",
            "label": "YWCA MAT.,BIKSHANDARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4337",
            "district": "29",
            "Block": "343",
            "label": "HAPF PARAMAHAMSA MAT.HIGH SCHOOL,HAPP",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4414",
            "district": "29",
            "Block": "171",
            "label": "ST. VINCENT MATRIC HIGHER SECONDARY SCHOOL,  N. KUTTAPATTU VANNANKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4442",
            "district": "29",
            "Block": "353",
            "label": "DR G VISWANATHAM PILLAI MATRIC SCHOOL,THOTTIYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4621",
            "district": "29",
            "Block": "366",
            "label": "JESSIE MARTIC HSS, PUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4642",
            "district": "29",
            "Block": "343",
            "label": "BHEL MATRIC HR SEC SCHOOL, KAILASAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4683",
            "district": "29",
            "Block": "366",
            "label": "A.K.K.V. AARUNADU.  MAT. SCHOOL, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4712",
            "district": "29",
            "Block": "317",
            "label": "Rabindra Bharathi Matric School, THATHIENGARPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4757",
            "district": "29",
            "Block": "343",
            "label": "St. Joseph Matriculation Higher Secondary School, VOC Nagar, Thuvakudimalai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4794",
            "district": "29",
            "Block": "152",
            "label": "LALGUDI LIONS MATRICULATION SCHOOL,LALGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4798",
            "district": "29",
            "Block": "366",
            "label": "KINGSLEY MAT.SCHOOL, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4803",
            "district": "29",
            "Block": "343",
            "label": "CHELLAMMAL MAT.HSS CRAWFORD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4822",
            "district": "29",
            "Block": "343",
            "label": "St.ANTHONY'S MAT HIGH SCHOOL ,RENGA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4828",
            "district": "29",
            "Block": "357",
            "label": "RAJ VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL,THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4849",
            "district": "29",
            "Block": "176",
            "label": "ST.ANTONY'S MATRIC SCHOOL, THUVARAKURCHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4874",
            "district": "29",
            "Block": "343",
            "label": "St.George Montessori Higher Secondary School,Anbil Nagar,Trichy",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4906",
            "district": "29",
            "Block": "353",
            "label": "INFANT JESUS MATRICULATION SCHOOL, KATTUPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4913",
            "district": "29",
            "Block": "171",
            "label": "CARE INTERNATIONAL SCHOOL (CBSE), THAYANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4950",
            "district": "29",
            "Block": "317",
            "label": "SB MAT. SCH.SORIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4966",
            "district": "29",
            "Block": "343",
            "label": "INFANT JESUS MAT HIGH SCHOOL, PONMALAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5006",
            "district": "29",
            "Block": "366",
            "label": "Hilda Thomas YWCA Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5017",
            "district": "29",
            "Block": "366",
            "label": "R.DAYANIDHI MEMORIAL VIDYASALA MATRICULATION SCHOOL,KULUMANI ROAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5041",
            "district": "29",
            "Block": "171",
            "label": "ALPHA WISDOM VIDHAYASHRAM SCHOOL (CBSE), K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5058",
            "district": "29",
            "Block": "258",
            "label": "KALAIVANI MATRIC SCHOOL,PULLAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5063",
            "district": "29",
            "Block": "192",
            "label": "SHANTHI Matric..H.S.S MUSIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5103",
            "district": "29",
            "Block": "366",
            "label": "SARASWATHI BALA MANDIR MATRICULATION SCHOOL SRINIVASA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5109",
            "district": "29",
            "Block": "176",
            "label": "PUMS,AATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5111",
            "district": "29",
            "Block": "172",
            "label": "SRI BALA VIDYAMANDIR MATRIC HSS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5186",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KARADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5242",
            "district": "29",
            "Block": "381",
            "label": "R.C. MATRICULATION SCHOOL, VAIYAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5277",
            "district": "29",
            "Block": "367",
            "label": "St. James Mat. Hr. Sec. School, Cantonment",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5304",
            "district": "29",
            "Block": "171",
            "label": "CREA CHILDREN'S ACADEMY MATRIC HIGHER SECONDARY SCHOOL NAGAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5317",
            "district": "29",
            "Block": "353",
            "label": "Dr.V. Gengusamy Naidu  MAT SCHOOL,THOTTIYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5332",
            "district": "29",
            "Block": "258",
            "label": "PUMS,KEELARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5336",
            "district": "29",
            "Block": "317",
            "label": "INTERNATIONAL TAMILAN Matriculation Academy",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5358",
            "district": "29",
            "Block": "366",
            "label": "CARMELS MAT HSS,TRICHY-3",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5372",
            "district": "29",
            "Block": "343",
            "label": "JEGAN MATHA MATRIC HSS SCHOOL,K.K.NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5432",
            "district": "29",
            "Block": "366",
            "label": "SRI JAYENDRA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5476",
            "district": "29",
            "Block": "367",
            "label": "Seventh-Day Adventist Mat.Hr.Sec.School,Beemnagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5571",
            "district": "29",
            "Block": "172",
            "label": "SRI MEIKANDAAR  MATRIC HR. SECONDARY SCHOOL,MANNACHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5654",
            "district": "29",
            "Block": "171",
            "label": "ST.FRANCIS MATRIC. HR. SEC. SCHOOL, UKT MALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5677",
            "district": "29",
            "Block": "152",
            "label": "PENI-EL MAT.HSS,ANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5685",
            "district": "29",
            "Block": "317",
            "label": "SOWDAMBIKAA  MAT. HR. SEC. SCHOOL. THATHIENGARPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5706",
            "district": "29",
            "Block": "366",
            "label": "SRI SANKARA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5747",
            "district": "29",
            "Block": "176",
            "label": "PUMS,PILLUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5821",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ENDAPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5837",
            "district": "29",
            "Block": "343",
            "label": "GOOD SHEPHERD MAT HR SEC SCHOOL , KK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5945",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6103",
            "district": "29",
            "Block": "366",
            "label": "AUROBINDO INTERNATIONAL SCHOOL, WORAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6197",
            "district": "29",
            "Block": "258",
            "label": "PAREE MATRIC  HR. SEC. SCHOOL, VENKATACHALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6300",
            "district": "29",
            "Block": "172",
            "label": "ST.MARY'S MATRIC HSS SCHOOL,TOLGATE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6331",
            "district": "29",
            "Block": "15",
            "label": "SREE VAIJAYANTHEE VIDHYALAYA MATRIC HSS SRIRANGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6381",
            "district": "29",
            "Block": "171",
            "label": "SRI KARAPAGA VINAYAGA MATRIC HSS, K.K. NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6402",
            "district": "29",
            "Block": "367",
            "label": "Shakespeare Mat. School, Palakarai.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6461",
            "district": "29",
            "Block": "152",
            "label": "H.C KOTHARI BALAVIHAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6503",
            "district": "29",
            "Block": "258",
            "label": "PUMS,ALAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6602",
            "district": "29",
            "Block": "366",
            "label": "DHANALAKSHMI SRINIVASAN MAT. WORAIYUR,TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6609",
            "district": "29",
            "Block": "176",
            "label": "VIDIYAL MATRIC.HSS,V.KAIKATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6627",
            "district": "29",
            "Block": "343",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC HIGH SCHOOL, AYYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6645",
            "district": "29",
            "Block": "15",
            "label": "BRINDAVAN VIDYALAYA  T V KOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14064",
            "district": "29",
            "Block": "15",
            "label": "GHS UTHAMARSEELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14065",
            "district": "29",
            "Block": "15",
            "label": "GHSS, THIRUVALARSOLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14066",
            "district": "29",
            "Block": "15",
            "label": "AYYANAR CORPORATION HIGH SCHOOL MELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14067",
            "district": "29",
            "Block": "15",
            "label": "GHS MUTHARASANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14068",
            "district": "29",
            "Block": "15",
            "label": "SRI VENKATESHWARA HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14069",
            "district": "29",
            "Block": "15",
            "label": "G.H.S.S THIRUCHENDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14070",
            "district": "29",
            "Block": "15",
            "label": "PUMS, AMMANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14071",
            "district": "29",
            "Block": "15",
            "label": "MATHURA VIDHYALAYA MATRIC HSS, PERIYAKARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14072",
            "district": "29",
            "Block": "15",
            "label": "SRI VIVEKANANDA VIDHYAVANAM HS THIRUPARAITHURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14073",
            "district": "29",
            "Block": "15",
            "label": "SRI RAMKRISHANA VIDHYALAYA HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14074",
            "district": "29",
            "Block": "15",
            "label": "VIVEKANANDA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14075",
            "district": "29",
            "Block": "15",
            "label": "PUMS, PERUGAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14076",
            "district": "29",
            "Block": "15",
            "label": "PUMS KODIYALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14077",
            "district": "29",
            "Block": "15",
            "label": "GHSS,AYILAPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14078",
            "district": "29",
            "Block": "15",
            "label": "AHSS RATHNA,PETTAVAITHALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14079",
            "district": "29",
            "Block": "15",
            "label": "ST GABRIEL MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14080",
            "district": "29",
            "Block": "15",
            "label": "GHS PETTAVAITHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14081",
            "district": "29",
            "Block": "15",
            "label": "GHS DEVASTHANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14082",
            "district": "29",
            "Block": "15",
            "label": "GHS KULUMANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14083",
            "district": "29",
            "Block": "15",
            "label": "GHS SEERATHOPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14084",
            "district": "29",
            "Block": "15",
            "label": "SRI BHARATI VIDYASHRAM MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14085",
            "district": "29",
            "Block": "15",
            "label": "PUMS,MULLIKARUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14086",
            "district": "29",
            "Block": "15",
            "label": "G.H.S.S ETTARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14087",
            "district": "29",
            "Block": "15",
            "label": "GHS KEERIKKALMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14088",
            "district": "29",
            "Block": "15",
            "label": "AIDED MIDDLE SCHOOL EAST RENGA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14089",
            "district": "29",
            "Block": "15",
            "label": "AIDED MIDDLE SCHOOL O.R.R",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14090",
            "district": "29",
            "Block": "15",
            "label": "THE HIGHER SECONDARY SCHOOL FOR BOYS , SRIRANGAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14091",
            "district": "29",
            "Block": "15",
            "label": "THE GIRLS' HIGHER SECONDARY SCHOOL, SRIRANGAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14092",
            "district": "29",
            "Block": "15",
            "label": "THE KALAIVAANI MATRICULATION SCHOOL,SRIRANGAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14093",
            "district": "29",
            "Block": "15",
            "label": "MPL.MS. DR. RAJAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14094",
            "district": "29",
            "Block": "15",
            "label": "AIDED MIDDLE SCHOOL SHANMUGA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14095",
            "district": "29",
            "Block": "15",
            "label": "MPL.MS. DEVI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14096",
            "district": "29",
            "Block": "15",
            "label": "DR.G.CHITTRAMBALAM  MAT.SCHOOL, SRI RANGAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14097",
            "district": "29",
            "Block": "15",
            "label": "MPL.M.S SRI RENGANATHA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14098",
            "district": "29",
            "Block": "15",
            "label": "NEHRUJI MEMORIAL MPL.M.S",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14099",
            "district": "29",
            "Block": "15",
            "label": "THE HIGH SCHOOL , THIRUVANAIKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14100",
            "district": "29",
            "Block": "15",
            "label": "SRI AKILANDESWARI VIDYALAYA THIRUVANAIKOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14101",
            "district": "29",
            "Block": "15",
            "label": "SRI VAGEESHA VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14102",
            "district": "29",
            "Block": "15",
            "label": "CORPORATION HIGH SCHOOL, TV  KOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14103",
            "district": "29",
            "Block": "152",
            "label": "PUMS,AGALANGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14104",
            "district": "29",
            "Block": "152",
            "label": "PUMS, AGALANGANALLURN.STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14105",
            "district": "29",
            "Block": "152",
            "label": "PUMS.SOUTH CHATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14106",
            "district": "29",
            "Block": "152",
            "label": "PUMS, ARIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14107",
            "district": "29",
            "Block": "152",
            "label": "TELC HIGH SCHOOL,SENGARAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14108",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.ESANAKKORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14109",
            "district": "29",
            "Block": "152",
            "label": "SAINT MARY MIDDLE SCHOOL, PERIYAVARSEELI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14110",
            "district": "29",
            "Block": "152",
            "label": "ADI DIRAVIDAR WELFARE  HIGHER SECONDARY SCHOOL , KEELANBIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14111",
            "district": "29",
            "Block": "152",
            "label": "ST.PHILOMINA'SRCAMS,MELAPERUNGAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14112",
            "district": "29",
            "Block": "152",
            "label": "VALLUVAR A.M.S.KOMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14113",
            "district": "29",
            "Block": "152",
            "label": "ST.ANTONY'S HSS,KONNAIKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14114",
            "district": "29",
            "Block": "152",
            "label": "P.U.MIDDLE SCHOOL, KOOHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14115",
            "district": "29",
            "Block": "152",
            "label": "ST.MARY'S HIGH SCHOOL,PALLIVIDAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14116",
            "district": "29",
            "Block": "152",
            "label": "ST.LEONARDI MATRIC. HIGHER SECONDARY SCHOOL,SAMAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14117",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.MANAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14118",
            "district": "29",
            "Block": "152",
            "label": "G.H.S.S.ANBIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14119",
            "district": "29",
            "Block": "152",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14120",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S. NATHAMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14121",
            "district": "29",
            "Block": "152",
            "label": "G.H.S. NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14122",
            "district": "29",
            "Block": "152",
            "label": "GOVERNMENT HIGH SCHOOL, MANDURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14123",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.PALLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14124",
            "district": "29",
            "Block": "152",
            "label": "G.H.S, PERUVALANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14125",
            "district": "29",
            "Block": "152",
            "label": "GHS, PUDHUR UTHAMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14126",
            "district": "29",
            "Block": "152",
            "label": "AROKIA ANNAI MATRICULATION SCHOOL,PUDURUTHAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14127",
            "district": "29",
            "Block": "152",
            "label": "PUMS, SATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14128",
            "district": "29",
            "Block": "152",
            "label": "INFANT JESUS HSS, S VINAYAGAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14129",
            "district": "29",
            "Block": "152",
            "label": "C.M.K.A.M.S.KATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14130",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.THACHANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14131",
            "district": "29",
            "Block": "152",
            "label": "G.H.S.THALAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14132",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14133",
            "district": "29",
            "Block": "152",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUMANAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14134",
            "district": "29",
            "Block": "152",
            "label": "GHSS, VALADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14135",
            "district": "29",
            "Block": "152",
            "label": "ST.GABRIEL'S M.S GABRIELPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14136",
            "district": "29",
            "Block": "152",
            "label": "ST.ANNES HR. SEC. SCHOOL, T.V NAGAR, LALGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14137",
            "district": "29",
            "Block": "152",
            "label": "G.H.S.S.LALGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14138",
            "district": "29",
            "Block": "152",
            "label": "LNP GIRLS' HSS, LALGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14139",
            "district": "29",
            "Block": "152",
            "label": "DON BOSCO H.S MANAKKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14140",
            "district": "29",
            "Block": "152",
            "label": "SAI VIDHYALAYA (CBSE) SCHOOL, LALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14141",
            "district": "29",
            "Block": "152",
            "label": "Govt. Hr. Sec. School, Poovalur, Lalgudi",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14142",
            "district": "29",
            "Block": "172",
            "label": "GOVT.BOYS HSC, MANNACHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14143",
            "district": "29",
            "Block": "172",
            "label": "GOVT  MODEL GIRLS HSS, MANNACHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14144",
            "district": "29",
            "Block": "172",
            "label": "AMS LALITHA, ULANTHANKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14145",
            "district": "29",
            "Block": "172",
            "label": "SRIMARIAMMAN H.S.S.SAMAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14146",
            "district": "29",
            "Block": "172",
            "label": "GHS, S.KANNANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14147",
            "district": "29",
            "Block": "172",
            "label": "ST.JOHN'S H.S.S IRUNGALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14148",
            "district": "29",
            "Block": "172",
            "label": "ST.XAVIER'S H.S.S, PURATHAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14149",
            "district": "29",
            "Block": "172",
            "label": "ST.THERASA'S AID. HS,KONALAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14150",
            "district": "29",
            "Block": "172",
            "label": "PUMS, 85.SRIDEVI MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14151",
            "district": "29",
            "Block": "172",
            "label": "PUMS,INAMTHIRUPATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14152",
            "district": "29",
            "Block": "172",
            "label": "G.H.S, PERAGAMBI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14153",
            "district": "29",
            "Block": "172",
            "label": "GHS, EDUMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14154",
            "district": "29",
            "Block": "172",
            "label": "GHSS,SIRUGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14155",
            "district": "29",
            "Block": "172",
            "label": "M.A.M SENIOR SECONDARY (CBSE) SCHOOL, SIRUGANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14156",
            "district": "29",
            "Block": "172",
            "label": "ADWHSS, M.R.PALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14157",
            "district": "29",
            "Block": "172",
            "label": "PUMS, VAZHAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14158",
            "district": "29",
            "Block": "172",
            "label": "PUMS, AZHAGIYAMANAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14159",
            "district": "29",
            "Block": "172",
            "label": "GHSS, C.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14160",
            "district": "29",
            "Block": "172",
            "label": "PUMS, INAM KALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14161",
            "district": "29",
            "Block": "172",
            "label": "RAMA NATARAJA HIGH SCHOOL, 94 KARIYAMANICKAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14162",
            "district": "29",
            "Block": "172",
            "label": "PUMS, KOOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14163",
            "district": "29",
            "Block": "172",
            "label": "SRI VIGNESH VIDYALAYA SENIOR SEC. SCHOOL, KOOTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14164",
            "district": "29",
            "Block": "172",
            "label": "PUMS, PACHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14165",
            "district": "29",
            "Block": "172",
            "label": "PUMS,MADHAVAPERUMAL KOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14166",
            "district": "29",
            "Block": "172",
            "label": "PUMS, THINAIPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14167",
            "district": "29",
            "Block": "172",
            "label": "GHSS,OMANTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14168",
            "district": "29",
            "Block": "172",
            "label": "RAJA HS, BIKSHANDARKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14169",
            "district": "29",
            "Block": "172",
            "label": "PUMS, RASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14170",
            "district": "29",
            "Block": "172",
            "label": "GHS,POONAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14171",
            "district": "29",
            "Block": "172",
            "label": "SRV MATRICULATION HR SEC  SCHOOL ,SAMAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14172",
            "district": "29",
            "Block": "172",
            "label": "PUMS, VEERANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14173",
            "district": "29",
            "Block": "172",
            "label": "GOVT ADW M.S.DEVI MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14174",
            "district": "29",
            "Block": "172",
            "label": "GANTHIYA MANYA AMS, THEERAMPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14175",
            "district": "29",
            "Block": "172",
            "label": "PUMS THILLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14176",
            "district": "29",
            "Block": "172",
            "label": "PUMS,VALMALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14177",
            "district": "29",
            "Block": "172",
            "label": "GHSS,THIRUPPANJILI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14178",
            "district": "29",
            "Block": "172",
            "label": "G.H.S.S THIRUVELLARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14179",
            "district": "29",
            "Block": "172",
            "label": "PUMS KALAVAIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14180",
            "district": "29",
            "Block": "172",
            "label": "GOVT. ADW HR. SEC. SCHOOL, THIRUVASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14181",
            "district": "29",
            "Block": "172",
            "label": "PUMS, THODAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14182",
            "district": "29",
            "Block": "172",
            "label": "GHSS, VALAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14183",
            "district": "29",
            "Block": "172",
            "label": "PUMS, SENNAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14184",
            "district": "29",
            "Block": "172",
            "label": "GHSS, SIRUGAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14185",
            "district": "29",
            "Block": "172",
            "label": "GHS, KURUVAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14186",
            "district": "29",
            "Block": "171",
            "label": "ST. PATRICK'S HR. SEC. SCHOOL, ALUNDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14187",
            "district": "29",
            "Block": "171",
            "label": "ST. PATRICK'S ACADAMY (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14188",
            "district": "29",
            "Block": "171",
            "label": "HMY GOVT. HSS INAMKULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14189",
            "district": "29",
            "Block": "171",
            "label": "R V S INTERNATIONAL (CBSE) SCHOOL, INAMKULATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14190",
            "district": "29",
            "Block": "171",
            "label": "GHS, PERIYA ALAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14191",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HR. SEC. SCHOOL, INAM MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14192",
            "district": "29",
            "Block": "171",
            "label": "PUMS, M.G.R NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14193",
            "district": "29",
            "Block": "171",
            "label": "NEHRU AIDED MS MUTHUKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14194",
            "district": "29",
            "Block": "171",
            "label": "PUMS, N.KUTTAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14195",
            "district": "29",
            "Block": "171",
            "label": "ANNAI INDIRA GANDHI MEMORIAL HSS, MUTHUKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14196",
            "district": "29",
            "Block": "171",
            "label": "MARY MATHA HIGH SCHOOL, SOUTH PAGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14197",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, INAM PERIYANAYAGI CHATHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14198",
            "district": "29",
            "Block": "171",
            "label": "PUMS, MATTAPARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14199",
            "district": "29",
            "Block": "171",
            "label": "PUMS, K.KALLIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14200",
            "district": "29",
            "Block": "171",
            "label": "PUMS, PANJAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14201",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HR. SEC. SCHOOL, POONGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14202",
            "district": "29",
            "Block": "171",
            "label": "ST. ANTONY'S RC AMS, NORTH PAGANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14203",
            "district": "29",
            "Block": "171",
            "label": "LAWRENCE MATRIC. HIGH SCHOOL, MULLIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14204",
            "district": "29",
            "Block": "171",
            "label": "ALPHA PLUS MATRIC HR. SEC. SCHOOL, K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14205",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, K K NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14206",
            "district": "29",
            "Block": "171",
            "label": "ORCHARD MATRIC. HR. SEC. SCHOOL, K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14207",
            "district": "29",
            "Block": "171",
            "label": "PUMS, K. SATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14208",
            "district": "29",
            "Block": "171",
            "label": "HOLY FAMILY R C HR. SEC. SCHOOL, AMMAPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14209",
            "district": "29",
            "Block": "171",
            "label": "AMRITA VIDHYALAYAM (CBSE), SOLANGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14210",
            "district": "29",
            "Block": "171",
            "label": "AIDED MS,PALLAKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14211",
            "district": "29",
            "Block": "171",
            "label": "ST. JOAN OF ARC INTER. SCHOOL (CBSE), SUNNAMBUKARANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14212",
            "district": "29",
            "Block": "171",
            "label": "PUMS, PIRATIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14213",
            "district": "29",
            "Block": "171",
            "label": "ST JOSEPH'S HR SEC SCHOOL, PUNGANUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14214",
            "district": "29",
            "Block": "171",
            "label": "S.A.S MATRICULATION HR. SEC. SCHOOL, PUNGANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14215",
            "district": "29",
            "Block": "171",
            "label": "PUMS, SOMARASAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14216",
            "district": "29",
            "Block": "171",
            "label": "ST.JOSEPH'S HR. SEC. SCHOOL, SOMARASAMPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14217",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HR. SEC. SCHOOL, SOMARASAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14218",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, THAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14219",
            "district": "29",
            "Block": "171",
            "label": "MUTHURAJA AMS, ADAVATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14220",
            "district": "29",
            "Block": "171",
            "label": "SRI SIVANANDA BALALAYA CBSE & NIOS SCHOOL, ADAVATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14221",
            "district": "29",
            "Block": "171",
            "label": "RC MIDDLE SCHOOL, UYYAKONDAN THIRUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14222",
            "district": "29",
            "Block": "171",
            "label": "SRI UMA PARAMESHWARI AMS, RAMJEE NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14223",
            "district": "29",
            "Block": "171",
            "label": "KALAIMAGAL AIDED HS, EDAMALAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14224",
            "district": "29",
            "Block": "171",
            "label": "ST.JOSEPH'S HR. SEC. SCHOOL, NAGAMANGALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14225",
            "district": "29",
            "Block": "171",
            "label": "GOOD SAMARITAN HS, NAGAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14226",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KUTHIRAIKUTHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14227",
            "district": "29",
            "Block": "168",
            "label": "ST.XAVIAR MS KOTTAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14228",
            "district": "29",
            "Block": "168",
            "label": "ST.MARY'S HSS, SAMARIA, MANAPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14229",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, THOPPAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14230",
            "district": "29",
            "Block": "168",
            "label": "PUMS, MARAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14231",
            "district": "29",
            "Block": "168",
            "label": "PUMS, K.PERIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14232",
            "district": "29",
            "Block": "168",
            "label": "Infant Jesus HSS, Maravanoor",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14233",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, SAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14234",
            "district": "29",
            "Block": "168",
            "label": "PUMS, MUTHAPUDAIYANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14235",
            "district": "29",
            "Block": "168",
            "label": "ST.JOSEPH HR. SEC. SCHOOL, POTHAMETTUPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14236",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KATTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14237",
            "district": "29",
            "Block": "168",
            "label": "GHSS,KANNUDAYANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14238",
            "district": "29",
            "Block": "168",
            "label": "MONTFORT  SCHOOL (CBSE) , MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14239",
            "district": "29",
            "Block": "168",
            "label": "GADWMS, VENGAIKURICHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14240",
            "district": "29",
            "Block": "168",
            "label": "ST.ANTONY'S HSS, MANJAMPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14241",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, MALAIYADIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14242",
            "district": "29",
            "Block": "168",
            "label": "PUMS, A.KURUMBAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14243",
            "district": "29",
            "Block": "168",
            "label": "GOVT.ADW HSS, PAMPATTIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14244",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KEEZHAPOIGAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14245",
            "district": "29",
            "Block": "168",
            "label": "GOVT.ADW HSS, POIGAIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14246",
            "district": "29",
            "Block": "168",
            "label": "THIAKESAR ALAI HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14247",
            "district": "29",
            "Block": "168",
            "label": "CAUVERY INTERNATIONAL  (CBSE) SCHOOL, MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14248",
            "district": "29",
            "Block": "168",
            "label": "THE ALMIGHTY'S MATRICULATION SCHOOL, MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14249",
            "district": "29",
            "Block": "168",
            "label": "PUMS, F.KEELAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14250",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, ANANDHANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14251",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, ANAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14252",
            "district": "29",
            "Block": "168",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PANNANKOMBU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14253",
            "district": "29",
            "Block": "168",
            "label": "PUMS, VALAIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14254",
            "district": "29",
            "Block": "168",
            "label": "PUMS, PITCHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14255",
            "district": "29",
            "Block": "168",
            "label": "PUMS, PANNAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14256",
            "district": "29",
            "Block": "168",
            "label": "MOHANDOSS MIDDLE SCHOOL, PERUMAMPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14257",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KARUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14258",
            "district": "29",
            "Block": "168",
            "label": "GHSS, AZHAGAGOUNDAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14259",
            "district": "29",
            "Block": "168",
            "label": "GOVT HSS, PUTHANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14260",
            "district": "29",
            "Block": "168",
            "label": "PUMS, VELLAIYAGOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14261",
            "district": "29",
            "Block": "168",
            "label": "PUMS, EDAIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14262",
            "district": "29",
            "Block": "168",
            "label": "ORACLE MAT.SCHOOL, PUTHANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14263",
            "district": "29",
            "Block": "168",
            "label": "THE CHALLENGERS VIDHAYALAYA MATRICULATION SCHOOL, MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14264",
            "district": "29",
            "Block": "168",
            "label": "MUNICIPAL MIDDLE SCHOOL, MANAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14265",
            "district": "29",
            "Block": "168",
            "label": "NATIONAL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14266",
            "district": "29",
            "Block": "168",
            "label": "ST.ANDREW'S HSS, MANAPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14267",
            "district": "29",
            "Block": "168",
            "label": "L.F.G.HSS, MANAPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14268",
            "district": "29",
            "Block": "168",
            "label": "GOVT GIRLS HSS, MANAPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14269",
            "district": "29",
            "Block": "168",
            "label": "GOVT BOYS HSS, MANAPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14270",
            "district": "29",
            "Block": "176",
            "label": "PUMS,THOTTIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14271",
            "district": "29",
            "Block": "176",
            "label": "PUMS,IRATTIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14272",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Kovilpatty",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14273",
            "district": "29",
            "Block": "176",
            "label": "PUMS,VAGUTHALVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14274",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Amanakkampatty",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14275",
            "district": "29",
            "Block": "176",
            "label": "GHS,Kannivadugapatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14276",
            "district": "29",
            "Block": "176",
            "label": "GHS,Kannuthu",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14277",
            "district": "29",
            "Block": "176",
            "label": "GHS,Maniyankurichy",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14278",
            "district": "29",
            "Block": "176",
            "label": "GHS,Kallakampatty",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14279",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KARVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14280",
            "district": "29",
            "Block": "176",
            "label": "GHSS,T.SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14281",
            "district": "29",
            "Block": "176",
            "label": "PUMS,MUDUKKUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14282",
            "district": "29",
            "Block": "176",
            "label": "PUMS,GOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14283",
            "district": "29",
            "Block": "176",
            "label": "GHS,Mettupatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14284",
            "district": "29",
            "Block": "176",
            "label": "PUMS,POLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14285",
            "district": "29",
            "Block": "176",
            "label": "GHS,THENUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14286",
            "district": "29",
            "Block": "176",
            "label": "PUMS,A.PUTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14287",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Uthukkuli",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14288",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ARRIYAKKONPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14289",
            "district": "29",
            "Block": "176",
            "label": "PUMS,VELLAPITCHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14290",
            "district": "29",
            "Block": "176",
            "label": "GHS,VEMBANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14291",
            "district": "29",
            "Block": "176",
            "label": "GHS,Unaiyur",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14292",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KODUMBAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14293",
            "district": "29",
            "Block": "176",
            "label": "PUMS,D.PORUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14294",
            "district": "29",
            "Block": "176",
            "label": "ST.JAMES HSS,Palakurichy",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14295",
            "district": "29",
            "Block": "176",
            "label": "ST.JUSTIN GIRLS HR. SEC.SCHOOL, PALAKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14296",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KALINGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14297",
            "district": "29",
            "Block": "176",
            "label": "GHS,Subbarayapatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14298",
            "district": "29",
            "Block": "176",
            "label": "PUMS,SOKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14299",
            "district": "29",
            "Block": "176",
            "label": "PUMS,A.VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14300",
            "district": "29",
            "Block": "176",
            "label": "GHSS,VALANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14301",
            "district": "29",
            "Block": "176",
            "label": "GHS,T.Edayapatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14302",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Marungapuri",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14303",
            "district": "29",
            "Block": "176",
            "label": "PUMS,THALAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14304",
            "district": "29",
            "Block": "176",
            "label": "Panchayat Union Middle school,T.SOKKANATHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14305",
            "district": "29",
            "Block": "176",
            "label": "PUMS,NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14306",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ADAIKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14307",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14308",
            "district": "29",
            "Block": "176",
            "label": "GHS,Kanjanayakanpatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14309",
            "district": "29",
            "Block": "176",
            "label": "PUMS,IKKARAIGOSIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14310",
            "district": "29",
            "Block": "176",
            "label": "PUMS,PIDARAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14311",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Sevalpatti",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14312",
            "district": "29",
            "Block": "176",
            "label": "PUMS,AKKIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14313",
            "district": "29",
            "Block": "176",
            "label": "GHS,Thethur",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14314",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ATHIKARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14315",
            "district": "29",
            "Block": "176",
            "label": "PUMS,PONNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14316",
            "district": "29",
            "Block": "176",
            "label": "Govt Model Boys Hr. Sec. School,Thuvarankurichy",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14317",
            "district": "29",
            "Block": "176",
            "label": "GHSS GIRLS,Thuvarankurichy",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14318",
            "district": "29",
            "Block": "176",
            "label": "BHARATH MATRICULATION SCHOOL,THURVARANKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14319",
            "district": "29",
            "Block": "192",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL,  AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14320",
            "district": "29",
            "Block": "192",
            "label": "GHSS AMOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14321",
            "district": "29",
            "Block": "192",
            "label": "PUMS MANAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14322",
            "district": "29",
            "Block": "192",
            "label": "PUMS KARUPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14323",
            "district": "29",
            "Block": "192",
            "label": "PUMS KATTUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14324",
            "district": "29",
            "Block": "192",
            "label": "PUMS GUNASEELAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14325",
            "district": "29",
            "Block": "192",
            "label": "GHS KALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14326",
            "district": "29",
            "Block": "192",
            "label": "GHS KOTTATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14327",
            "district": "29",
            "Block": "192",
            "label": "PUMS SOUTH SITHAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14328",
            "district": "29",
            "Block": "192",
            "label": "GHSS NORTH SITHAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14329",
            "district": "29",
            "Block": "192",
            "label": "PUMS THANDALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14330",
            "district": "29",
            "Block": "192",
            "label": "GHS SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14331",
            "district": "29",
            "Block": "192",
            "label": "GHSS THANDALAIPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14332",
            "district": "29",
            "Block": "192",
            "label": "PUMS VELAKKANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14333",
            "district": "29",
            "Block": "192",
            "label": "GHS THINNANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14334",
            "district": "29",
            "Block": "192",
            "label": "GADWHSS THIRUTHALAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14335",
            "district": "29",
            "Block": "192",
            "label": "PUMS MELAKOTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14336",
            "district": "29",
            "Block": "192",
            "label": "GHS THIRUTHIYAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14337",
            "district": "29",
            "Block": "192",
            "label": "AMS VEERAMANIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14338",
            "district": "29",
            "Block": "192",
            "label": "GHS NACHAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14339",
            "district": "29",
            "Block": "192",
            "label": "PUMS NEIVELY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14340",
            "district": "29",
            "Block": "192",
            "label": "PUMS SOUTH NALLIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14341",
            "district": "29",
            "Block": "192",
            "label": "NEHRU HSS, PUTHANAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14342",
            "district": "29",
            "Block": "192",
            "label": "GHSS,PULIVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14343",
            "district": "29",
            "Block": "192",
            "label": "J.F.O.A HSS, PULIVALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14344",
            "district": "29",
            "Block": "192",
            "label": "PUMS PERAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14345",
            "district": "29",
            "Block": "192",
            "label": "GHS SINTHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14346",
            "district": "29",
            "Block": "192",
            "label": "GHSS KARATTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14347",
            "district": "29",
            "Block": "192",
            "label": "GHS.MANBARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14348",
            "district": "29",
            "Block": "192",
            "label": "GHSS MOOVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14349",
            "district": "29",
            "Block": "192",
            "label": "PUMS VELLAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14350",
            "district": "29",
            "Block": "192",
            "label": "GHS VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14351",
            "district": "29",
            "Block": "192",
            "label": "PUMS ANAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14352",
            "district": "29",
            "Block": "192",
            "label": "PUMS KANNUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14353",
            "district": "29",
            "Block": "192",
            "label": "PUMS SANTHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14354",
            "district": "29",
            "Block": "192",
            "label": "PUMS MUSIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14355",
            "district": "29",
            "Block": "192",
            "label": "G(B)HSS MUSIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14356",
            "district": "29",
            "Block": "192",
            "label": "G(G)HSS MUSIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14357",
            "district": "29",
            "Block": "192",
            "label": "AMALA G HSS, MUSIRI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14358",
            "district": "29",
            "Block": "258",
            "label": "GHSS,KALLAGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14359",
            "district": "29",
            "Block": "258",
            "label": "PUMS,THIRANIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14360",
            "district": "29",
            "Block": "258",
            "label": "PUMS,SARADAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14361",
            "district": "29",
            "Block": "258",
            "label": "GHS,SIRUKALAPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14362",
            "district": "29",
            "Block": "258",
            "label": "GHSS,PERUVALAPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14363",
            "district": "29",
            "Block": "258",
            "label": "GHSS,KANAKILIYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14364",
            "district": "29",
            "Block": "258",
            "label": "GHSS,PUDURPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14365",
            "district": "29",
            "Block": "258",
            "label": "ST.PETER'S HSS,VIRAGALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14366",
            "district": "29",
            "Block": "258",
            "label": "ST.LOUIS R.C.M.SC,PULLAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14367",
            "district": "29",
            "Block": "258",
            "label": "GHSS PULLAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14368",
            "district": "29",
            "Block": "258",
            "label": "GHSS,MALVOI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14369",
            "district": "29",
            "Block": "258",
            "label": "GHS,OOTATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14370",
            "district": "29",
            "Block": "258",
            "label": "PUMS,ALUNTHALAIPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14371",
            "district": "29",
            "Block": "258",
            "label": "PUMS,MELARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14372",
            "district": "29",
            "Block": "258",
            "label": "PUMS,MUTHUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14373",
            "district": "29",
            "Block": "258",
            "label": "PUMS,NEIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14374",
            "district": "29",
            "Block": "258",
            "label": "PUMS,THAPPOI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14375",
            "district": "29",
            "Block": "258",
            "label": "PUMS,KUMULUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14376",
            "district": "29",
            "Block": "258",
            "label": "PUMS,REDDIMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14377",
            "district": "29",
            "Block": "258",
            "label": "PUMS,P.K.AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14378",
            "district": "29",
            "Block": "258",
            "label": "ANNAI ERICA HR SEC SCHOOL P K AGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14379",
            "district": "29",
            "Block": "258",
            "label": "RCMS,VANTHALAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14380",
            "district": "29",
            "Block": "258",
            "label": "GHS,VANTHALAI KUDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14381",
            "district": "29",
            "Block": "258",
            "label": "PUMS,VELLANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14382",
            "district": "29",
            "Block": "258",
            "label": "PUMS,RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14383",
            "district": "29",
            "Block": "258",
            "label": "R C MEETPER HS, RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14384",
            "district": "29",
            "Block": "258",
            "label": "VETRI VIDHYALAYA HSS,VELLANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14385",
            "district": "29",
            "Block": "258",
            "label": "NAVA BHARATH VIDYALAYA SENIOR SEC. SCHOOL,E.VELLANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14386",
            "district": "29",
            "Block": "258",
            "label": "GHS, PUNJAI SANGENTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14387",
            "district": "29",
            "Block": "258",
            "label": "R C JAMES MIDDLE SCHOOL, KOVANDAKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14388",
            "district": "29",
            "Block": "258",
            "label": "ST MARY'S MIDDLE SCHOOL,VADUGARPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14389",
            "district": "29",
            "Block": "258",
            "label": "ST JOSEPH'S GIRLS HSS,VADUGARPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14390",
            "district": "29",
            "Block": "258",
            "label": "RC MIDDLE SCHOOL,KALLAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14391",
            "district": "29",
            "Block": "258",
            "label": "ADWHS,KALLAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14392",
            "district": "29",
            "Block": "366",
            "label": "V.O.C.MIDDLE SCHOOL,EAST CHIND",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14393",
            "district": "29",
            "Block": "366",
            "label": "U.D.V HSS, TOWN RAILWAY STATION, TRICHY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14394",
            "district": "29",
            "Block": "366",
            "label": "E.R.HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14395",
            "district": "29",
            "Block": "366",
            "label": "THIRUVALLUVAR MID. SCH",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14396",
            "district": "29",
            "Block": "366",
            "label": "ICSE KAMAKODI VIDHIYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14397",
            "district": "29",
            "Block": "366",
            "label": "RC Middle School Butterworth Road",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14398",
            "district": "29",
            "Block": "366",
            "label": "MATHURAM MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14399",
            "district": "29",
            "Block": "366",
            "label": "VEERAN KALVI KOODAM M.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14400",
            "district": "29",
            "Block": "366",
            "label": "SAVITRI VIDYASALA HINDU GIRLS' HIGHER SECONDARY SCHOOL, TIRUCHIRAPALLI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14401",
            "district": "29",
            "Block": "366",
            "label": "ST.JOSEPH'S COLLEGE HR. SECONDARY SCHOOL, MAINGUARDGATE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14402",
            "district": "29",
            "Block": "366",
            "label": "BAKTHA THAYUMANAVAR M.SCH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14403",
            "district": "29",
            "Block": "366",
            "label": "AMS.THOTTA RAJAGOPAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14404",
            "district": "29",
            "Block": "366",
            "label": "HAZARATH NATHERVALI M.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14405",
            "district": "29",
            "Block": "366",
            "label": "ST.MARYS TOPE M.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14406",
            "district": "29",
            "Block": "366",
            "label": "BISHOP HEBER BOYS HSS, TEPPAKULAM,TRICHY-2",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14407",
            "district": "29",
            "Block": "366",
            "label": "NATIONAL COLLGE HSS, COLLEGE ROAD,TRICHY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14408",
            "district": "29",
            "Block": "366",
            "label": "GOVT GIRLS HSS, TOWN HALL, TRICHY-2",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14409",
            "district": "29",
            "Block": "366",
            "label": "CORPORATION HR SEC SCHOOL, E.B ROAD, TRICHY-8",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14410",
            "district": "29",
            "Block": "366",
            "label": "AMS-ALANGAVILAS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14411",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MIDDLE SCHOOL THARANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14412",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MIDDLE SCHOOL KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14413",
            "district": "29",
            "Block": "366",
            "label": "T.E.L.C.M.SCHOOL KALLA ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14414",
            "district": "29",
            "Block": "366",
            "label": "SAROJAKASTHURIRENGAN M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14415",
            "district": "29",
            "Block": "366",
            "label": "AMS-MADARASA-E- MOHAMEADIA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14416",
            "district": "29",
            "Block": "366",
            "label": "GOVERNMENT SYED MURTHUZA MODEL HIGHER SECONDARY SCHOOL, TRICHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14417",
            "district": "29",
            "Block": "366",
            "label": "MUN.MID.SCH.VARAGANERI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14418",
            "district": "29",
            "Block": "366",
            "label": "HOLY REDEEMER'S GIRLS HSS, PALAKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14419",
            "district": "29",
            "Block": "366",
            "label": "R.C  ST.  STANISLAUS  MIDDLE SCHOOL,PALAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14420",
            "district": "29",
            "Block": "366",
            "label": "H.R.M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14421",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MIDDLE SCHOOL SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14422",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MIDDLE SCHOOL BEEMANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14423",
            "district": "29",
            "Block": "366",
            "label": "KANAGA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14424",
            "district": "29",
            "Block": "366",
            "label": "MAHATMA GANDHI CENTENARY VIDYALAYA, TENNUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14425",
            "district": "29",
            "Block": "366",
            "label": "RAJAJI VIDYALAYA,TENNUR,TRICHY-17",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14426",
            "district": "29",
            "Block": "366",
            "label": "SUBBIAH MEM.MID.SCH,TENNUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14427",
            "district": "29",
            "Block": "366",
            "label": "AMS-ALL SAINTS TENNUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14428",
            "district": "29",
            "Block": "366",
            "label": "TENNUR MID.SCH, TENNUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14429",
            "district": "29",
            "Block": "366",
            "label": "ALL SAINTS HIGH SCHOOL, PUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14430",
            "district": "29",
            "Block": "366",
            "label": "C.E. HSS, VANNARAPET, PUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14431",
            "district": "29",
            "Block": "366",
            "label": "GOVT. GIRLS SCH FOR THE BLIND , NEAR G.H ,TRICHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14432",
            "district": "29",
            "Block": "366",
            "label": "METHODIST GIRLS HR.SEC.SCHOOL, WORAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14433",
            "district": "29",
            "Block": "366",
            "label": "BISHOP HEBER BOYS HSS, PUTHUR,TRICHY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14434",
            "district": "29",
            "Block": "366",
            "label": "SRI RAMAKRISHNA MID.SCH,PUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14435",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MID.SCH,WORIYUREAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14436",
            "district": "29",
            "Block": "366",
            "label": "K A P VISVANATHAM HR SEC SCHOOL THILLAI NAGAR TIRUCHIRAPPALLI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14437",
            "district": "29",
            "Block": "366",
            "label": "DR.G.SAKUNTHALA  MATRICULATION  HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14438",
            "district": "29",
            "Block": "366",
            "label": "CAUVERY MATRICULATION HR. SEC. SCHOOL, BHARATHI NAGAR, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14439",
            "district": "29",
            "Block": "366",
            "label": "R.C.MIDDLE,SCHOOL,WORIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14440",
            "district": "29",
            "Block": "366",
            "label": "MUN.MID.SCHOOL,PANDAMANGALAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14441",
            "district": "29",
            "Block": "366",
            "label": "MUN.MID.SCHOOL,WORAIYUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14442",
            "district": "29",
            "Block": "366",
            "label": "SHRI KASTHURI RANGAN VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14443",
            "district": "29",
            "Block": "366",
            "label": "SESHA IYENGAR MEMORIAL HR. SEC. SCHOOL, WORAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14444",
            "district": "29",
            "Block": "366",
            "label": "R.C.MID.SCHOOL,KALNAYAKKAN ST.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14445",
            "district": "29",
            "Block": "367",
            "label": "ADW Mid. School, Varaganeri",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14446",
            "district": "29",
            "Block": "367",
            "label": "T.S.M. High School, Varaganeri",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14447",
            "district": "29",
            "Block": "367",
            "label": "Ponniah Hr. Sec. School, Hirudayapuram",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14448",
            "district": "29",
            "Block": "367",
            "label": "T.E.L.C. Mid. School, Kemps Town.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14449",
            "district": "29",
            "Block": "367",
            "label": "St. Antony's Mid. School, Duraisamipuram.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14450",
            "district": "29",
            "Block": "367",
            "label": "Sri Yadhu Kula Sangam Mid. School, Palakarai.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14451",
            "district": "29",
            "Block": "367",
            "label": "Selva Damodaran Hr. Sec. School, Palakarai",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14452",
            "district": "29",
            "Block": "367",
            "label": "Corporation High School, Senthaneerpuram.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14453",
            "district": "29",
            "Block": "367",
            "label": "Seven Dolloar's Mid. School, Therasalpuram.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14454",
            "district": "29",
            "Block": "367",
            "label": "St. John Britto Mid. School, T.V.S.Tolgate",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14455",
            "district": "29",
            "Block": "367",
            "label": "John Britto Mat. School, Subramaniyapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14456",
            "district": "29",
            "Block": "367",
            "label": "Vestley Matric. School, T.V.S. Tollgate,",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14457",
            "district": "29",
            "Block": "367",
            "label": "Corp. Mid. School, Subramaniapuram.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14458",
            "district": "29",
            "Block": "367",
            "label": "R.C. Authimulam Mid. School, Subramaniapuram.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14459",
            "district": "29",
            "Block": "367",
            "label": "Seventh Day Adventist MATRICULATION SCHOOL, SUBRAMANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14460",
            "district": "29",
            "Block": "367",
            "label": "Corporation High School, Kamaraj Nagar.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14461",
            "district": "29",
            "Block": "367",
            "label": "AL-HUDHA HR. SEC. SCHOOL,AIRPORT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14462",
            "district": "29",
            "Block": "367",
            "label": "St. Agnes Mid. School, Ponmalaipatti.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14463",
            "district": "29",
            "Block": "367",
            "label": "St.Joseph's Girls' Hr. Sec. School, Ponmalaipatti",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14464",
            "district": "29",
            "Block": "367",
            "label": "DOLOUR'S HIGH SCHOOL FOR THE DEAF,PONMALAIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14465",
            "district": "29",
            "Block": "367",
            "label": "Abbot Marcel R.C. Hr. Sec. School, Sempattu.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14466",
            "district": "29",
            "Block": "367",
            "label": "Annai  Hr. Sec. School, Airport.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14467",
            "district": "29",
            "Block": "367",
            "label": "Adams Mat. Hr. Sec. School, Airport.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14468",
            "district": "29",
            "Block": "367",
            "label": "Infant Jesus High School, Kajamalai Colony,",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14469",
            "district": "29",
            "Block": "367",
            "label": "Seva Sangam Girls Hr. Sec. School, Cantonment",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14470",
            "district": "29",
            "Block": "367",
            "label": "R.C HR. SEC. SCHOOL, CANTONMENT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14471",
            "district": "29",
            "Block": "367",
            "label": "St. John Vestry A.I. Hr. Sec. School, Cantonment.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14472",
            "district": "29",
            "Block": "367",
            "label": "Campion A.I. Hr. Sec. School, Cantonment.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14473",
            "district": "29",
            "Block": "367",
            "label": "G. ADW. Hr. Sec. School, Milaguparai.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14474",
            "district": "29",
            "Block": "367",
            "label": "KAMALA NIKETAN MONTESSORI SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14475",
            "district": "29",
            "Block": "367",
            "label": "St. Anne's Mid. School,",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14476",
            "district": "29",
            "Block": "367",
            "label": "St. Juliana's Mid. School,",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14477",
            "district": "29",
            "Block": "367",
            "label": "B.H.M. High School, Melapudur.",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14478",
            "district": "29",
            "Block": "367",
            "label": "St. Mary's High School, Melapudur,",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14479",
            "district": "29",
            "Block": "367",
            "label": "ST. ANNE'S GIRLS' HR. SEC. SCHOOL, MELAPUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14480",
            "district": "29",
            "Block": "367",
            "label": "Saraswathi Vilas Mid. School, Beemanagar",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14481",
            "district": "29",
            "Block": "343",
            "label": "GHSS , ARASANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14482",
            "district": "29",
            "Block": "343",
            "label": "MONTFORT SCHOOL, KATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14483",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,GUNDUR BURMACOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14484",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANTHALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14485",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,KEELAKALKANDARKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14486",
            "district": "29",
            "Block": "343",
            "label": "GOVT HIGH SCHOOL, M.K.KOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14487",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, KEELA MULLAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14488",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, KILIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14489",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, VEETHIVADANGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14490",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, POLICE COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14491",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, NAVALPATTU SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14492",
            "district": "29",
            "Block": "343",
            "label": "OFT HIGH SCHOOL, OFT",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "14493",
            "district": "29",
            "Block": "343",
            "label": "GOVT BOYS HIGH SCHOOL, ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14494",
            "district": "29",
            "Block": "343",
            "label": "GOVT GIRLS HIGH SCHOOL, ANNA NAGAR, TRICHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14495",
            "district": "29",
            "Block": "343",
            "label": "KENDRIYA VIDHALAYA -I-OFT",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14496",
            "district": "29",
            "Block": "343",
            "label": "OFT HR SEC SCHOOL , OFT ESTATE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14497",
            "district": "29",
            "Block": "343",
            "label": "GOVT HR SEC SCHOOL, THENERIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14498",
            "district": "29",
            "Block": "343",
            "label": "KV- II-HAPP",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14499",
            "district": "29",
            "Block": "343",
            "label": "GOVT HIGH SCHOOL, POOLANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14500",
            "district": "29",
            "Block": "343",
            "label": "ST.PHILOMINAS MID SCHOOL,KATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14501",
            "district": "29",
            "Block": "343",
            "label": "GOVT ADW BOYS HR SEC SCHOOL, KATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23702",
            "district": "29",
            "Block": "192",
            "label": "Saraswati Vidya Shala School ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "14502",
            "district": "29",
            "Block": "343",
            "label": "GOVT ADW GIRLS HR SEC SCHOOL, KATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14503",
            "district": "29",
            "Block": "343",
            "label": "St.Antony's Higher Secondary School, Kattur,Trichy-19",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14504",
            "district": "29",
            "Block": "343",
            "label": "ST.MARY'S HIGH SCHOOL, PATHALAPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14505",
            "district": "29",
            "Block": "343",
            "label": "GOVT ADW HIGHER SECONDARY SCHOOL, SOLAMADEVI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14506",
            "district": "29",
            "Block": "343",
            "label": "NATHAN MID SCHOOL, PERIYASOORIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14507",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNON MID SCHOOL,CHINNASURIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14508",
            "district": "29",
            "Block": "343",
            "label": "GOVT HIGH SCHOOL, THIRUNEDUNGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14509",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,VALAVANDANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14510",
            "district": "29",
            "Block": "343",
            "label": "ST.JOSEPH HIGH SCHOOL, AYYAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14511",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, VENGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14512",
            "district": "29",
            "Block": "343",
            "label": "GOVT HR SEC SCHOOL, THUVAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14513",
            "district": "29",
            "Block": "343",
            "label": "REC.,MID.SCHOOL, THUVAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14514",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, THUVAKUDIMALAI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14515",
            "district": "29",
            "Block": "343",
            "label": "INFANT JESUS GIRLS HR SEC SCHOOL, THUVAKUDI MALAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14516",
            "district": "29",
            "Block": "343",
            "label": "RSK HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14517",
            "district": "29",
            "Block": "343",
            "label": "SARATHA MIDDLE SCHOOL, KAMARAJAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14518",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, KOOTHAIPAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14519",
            "district": "29",
            "Block": "343",
            "label": "ST.JOSEPH MID SCHOOL, KAILASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14520",
            "district": "29",
            "Block": "343",
            "label": "BOILER PLANT MID SCHOOL, KAILASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14521",
            "district": "29",
            "Block": "343",
            "label": "BOILER PLANT GIRLS HR SEC SCHOOL, KAILASAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14522",
            "district": "29",
            "Block": "343",
            "label": "BOILER PLANT BOYS HR SEC SCHOOL, KAILASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14523",
            "district": "29",
            "Block": "343",
            "label": "BHEL TAMIL MEDIUM M S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14524",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL THIRUVERUMBUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14525",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,BAGAVATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14526",
            "district": "29",
            "Block": "343",
            "label": "MUKKULATHORE HR SE SCHOOL, THIRUVERUMBUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14527",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, UKKADAI ARIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14528",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14529",
            "district": "29",
            "Block": "343",
            "label": "R.C.MID.SCHOOL, ARIYAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14530",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14531",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,THANGESWARI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14532",
            "district": "29",
            "Block": "343",
            "label": "GOVT.HIGH.SCHOOL ,AMBIKAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14533",
            "district": "29",
            "Block": "343",
            "label": "SACRED HEART HSS,PONMALAIPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14534",
            "district": "29",
            "Block": "343",
            "label": "ST MARY'S MS MELEKAKONDAR KOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14535",
            "district": "29",
            "Block": "343",
            "label": "HOLY CROSS GIRLS HR SEC SCHOOL, PONMALAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14536",
            "district": "29",
            "Block": "343",
            "label": "BRINDAVAN VIDYALAYA MATRICULATION HR.SEC. SCHOOL, AMBIKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14537",
            "district": "29",
            "Block": "343",
            "label": "THE BRITISH ENGLISH MEDIUM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14538",
            "district": "29",
            "Block": "343",
            "label": "PERIYAR MANIAMMAI GIRLS HR SEC SCHOOL, PERIYAR NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14539",
            "district": "29",
            "Block": "343",
            "label": "LITTLE FLOWER HR SEC SCHOOL,CRAWFORD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14540",
            "district": "29",
            "Block": "343",
            "label": "KAJAMIAN HR SEC SCHOOL , KHAJANAGAR, TRICHY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14541",
            "district": "29",
            "Block": "343",
            "label": "ST.MARY'S M.S. MANNARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14542",
            "district": "29",
            "Block": "343",
            "label": "RAILWAY MIXED HR SEC SCHOOL,PONMALAI",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "14543",
            "district": "29",
            "Block": "343",
            "label": "SAMADH HIGHER SECONDARY SCHOOL, KHAJA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14544",
            "district": "29",
            "Block": "343",
            "label": "EQUITAS GURUKUL MATRIC SCHOOL,KATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14545",
            "district": "29",
            "Block": "353",
            "label": "PUMS,KIDARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14546",
            "district": "29",
            "Block": "353",
            "label": "GHS,NAGAIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14547",
            "district": "29",
            "Block": "353",
            "label": "PUMS,SRIRAMASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14548",
            "district": "29",
            "Block": "353",
            "label": "PUMS,UNNIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14549",
            "district": "29",
            "Block": "353",
            "label": "GHS,THALAIMALAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14550",
            "district": "29",
            "Block": "353",
            "label": "GHSS,ELURPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14551",
            "district": "29",
            "Block": "353",
            "label": "GHS,UDAIYAKULAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14552",
            "district": "29",
            "Block": "353",
            "label": "PUMS KARTHIGAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14553",
            "district": "29",
            "Block": "353",
            "label": "ST JOSEPHS M S,THOLURPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14554",
            "district": "29",
            "Block": "353",
            "label": "GHSS,BALASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14555",
            "district": "29",
            "Block": "353",
            "label": "NEW MULLAI HIGH SCHOOL,THOLURPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14556",
            "district": "29",
            "Block": "353",
            "label": "GHS,ALAGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14557",
            "district": "29",
            "Block": "353",
            "label": "PUMS,THIRUEENGOIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14558",
            "district": "29",
            "Block": "353",
            "label": "LALITHA UNAIDED  HIGH SCHOOL, THIRUEENGOIMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14559",
            "district": "29",
            "Block": "353",
            "label": "GHS,ARANGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23407",
            "district": "29",
            "Block": "357",
            "label": "Vedha Green School",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "14560",
            "district": "29",
            "Block": "353",
            "label": "GHSS,NATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14561",
            "district": "29",
            "Block": "353",
            "label": "GHS,THIRUNARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14562",
            "district": "29",
            "Block": "353",
            "label": "PUMS,SRINIVASANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14563",
            "district": "29",
            "Block": "353",
            "label": "PUMS,KADUVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14564",
            "district": "29",
            "Block": "353",
            "label": "GHSS, KOLAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14565",
            "district": "29",
            "Block": "353",
            "label": "FATHIMA M S,KOLAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14566",
            "district": "29",
            "Block": "353",
            "label": "PUMS,SEELAIPILLAIYARPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14567",
            "district": "29",
            "Block": "353",
            "label": "PUMS,THOTTIYAM-NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14568",
            "district": "29",
            "Block": "353",
            "label": "GBHSS,THOTTIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14569",
            "district": "29",
            "Block": "353",
            "label": "GHSS,THOTTIYAM GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14570",
            "district": "29",
            "Block": "353",
            "label": "ST. MARYS AIDED HS, THOTTIYAM NORTH",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14571",
            "district": "29",
            "Block": "353",
            "label": "MULLAI HR. SEC. SCHOOL, BALASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14572",
            "district": "29",
            "Block": "353",
            "label": "SOWDAAMBIKAA MATRIC HSS BALASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14573",
            "district": "29",
            "Block": "353",
            "label": "ZAMINDARS HS S,KATTUPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14574",
            "district": "29",
            "Block": "353",
            "label": "GHSS, KATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14575",
            "district": "29",
            "Block": "357",
            "label": "PUMS,AMMAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14576",
            "district": "29",
            "Block": "357",
            "label": "ADW M S ,ADHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14577",
            "district": "29",
            "Block": "357",
            "label": "GADWHSS,KANNANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14578",
            "district": "29",
            "Block": "357",
            "label": "GHS,KANNANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14579",
            "district": "29",
            "Block": "357",
            "label": "PUMS SANGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14580",
            "district": "29",
            "Block": "357",
            "label": "PUMS,K.KOTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14581",
            "district": "29",
            "Block": "357",
            "label": "THE KEERAMBUR HS ,KEERAMBUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14582",
            "district": "29",
            "Block": "357",
            "label": "PUMS, KEELAKUNNUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14583",
            "district": "29",
            "Block": "357",
            "label": "GTRHS SEMBULICHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14584",
            "district": "29",
            "Block": "357",
            "label": "PUMS,V.A.SAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14585",
            "district": "29",
            "Block": "357",
            "label": "GEE VEE VIDHYALAYAA MATRIC SCHOOL, SINGALANTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14586",
            "district": "29",
            "Block": "357",
            "label": "GHS,SIKKATHAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14587",
            "district": "29",
            "Block": "357",
            "label": "GADWHS, SIKKATHAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14588",
            "district": "29",
            "Block": "357",
            "label": "GHSS,SENAPPANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14589",
            "district": "29",
            "Block": "357",
            "label": "PUMS,SELLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14590",
            "district": "29",
            "Block": "357",
            "label": "PUMS,SOKKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14591",
            "district": "29",
            "Block": "357",
            "label": "GHS,NADUVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14592",
            "district": "29",
            "Block": "357",
            "label": "GADWHS,NAGALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14593",
            "district": "29",
            "Block": "357",
            "label": "PUMS,NALLAVANNIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14594",
            "district": "29",
            "Block": "357",
            "label": "PUMS,K.PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14595",
            "district": "29",
            "Block": "357",
            "label": "PUMS,KILIYANURPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14596",
            "district": "29",
            "Block": "357",
            "label": "R C M S PERUMALPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14597",
            "district": "29",
            "Block": "357",
            "label": "D N G H S S PERUMALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14598",
            "district": "29",
            "Block": "357",
            "label": "PUMS,PONNUSANGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14599",
            "district": "29",
            "Block": "357",
            "label": "PUMS,MADHURAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14600",
            "district": "29",
            "Block": "357",
            "label": "PUMS,CHITHTHIRAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14601",
            "district": "29",
            "Block": "357",
            "label": "C.S.I.H.S.S.THURAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14602",
            "district": "29",
            "Block": "357",
            "label": "AIDED M S,MARUVATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14603",
            "district": "29",
            "Block": "357",
            "label": "AIDED M S,PULIYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14604",
            "district": "29",
            "Block": "357",
            "label": "SARASWATHI AIDED M S , MURUGUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14605",
            "district": "29",
            "Block": "357",
            "label": "AIDED M S , T.RENGANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14606",
            "district": "29",
            "Block": "357",
            "label": "GTRMS , NAGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14607",
            "district": "29",
            "Block": "357",
            "label": "GTRHSS,CHINNAILUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14608",
            "district": "29",
            "Block": "357",
            "label": "GHSS,SENGATUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14609",
            "district": "29",
            "Block": "357",
            "label": "PUMS,VEERAMACHANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14610",
            "district": "29",
            "Block": "357",
            "label": "NEHRU M S,KAVERIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14611",
            "district": "29",
            "Block": "357",
            "label": "SPG M SCHOOL,THURAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14612",
            "district": "29",
            "Block": "357",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THURAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14613",
            "district": "29",
            "Block": "357",
            "label": "G ADW HSS ,THURAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14614",
            "district": "29",
            "Block": "357",
            "label": "ZAMINDAR'S H S S ,THURAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14615",
            "district": "29",
            "Block": "357",
            "label": "SENGUNTHAR H S S ,THURAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14616",
            "district": "29",
            "Block": "357",
            "label": "MUNICIPAL HIGH SCHOOL ,THURAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14617",
            "district": "29",
            "Block": "357",
            "label": "RAJA HR. SEC. SCHOOL,THURAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14618",
            "district": "29",
            "Block": "357",
            "label": "KALPANA KENDHRIYA ACADEMY (CBSE) SCHOOL,MURUGOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14619",
            "district": "29",
            "Block": "317",
            "label": "GHS PILLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14620",
            "district": "29",
            "Block": "317",
            "label": "PUMS URUDAIAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14621",
            "district": "29",
            "Block": "317",
            "label": "GHS KAARUGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14622",
            "district": "29",
            "Block": "317",
            "label": "AIDED MS KONAPPAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14623",
            "district": "29",
            "Block": "317",
            "label": "PUMS MAHADEVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14624",
            "district": "29",
            "Block": "317",
            "label": "AIDED MS MANAGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14625",
            "district": "29",
            "Block": "317",
            "label": "PUMS AZHAGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14626",
            "district": "29",
            "Block": "317",
            "label": "PUMS AYETHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14627",
            "district": "29",
            "Block": "317",
            "label": "GHSS, PAITHAMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14628",
            "district": "29",
            "Block": "317",
            "label": "GHSS, PAPPAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14629",
            "district": "29",
            "Block": "317",
            "label": "GHS, SERUGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14630",
            "district": "29",
            "Block": "317",
            "label": "GHSS, THUMBALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14631",
            "district": "29",
            "Block": "317",
            "label": "GOVT. ADW HSS THULAIYANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14632",
            "district": "29",
            "Block": "317",
            "label": "PUMS, JEMBUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14633",
            "district": "29",
            "Block": "317",
            "label": "AIED MS URAKKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14634",
            "district": "29",
            "Block": "317",
            "label": "GHSS, VALASIRAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14635",
            "district": "29",
            "Block": "317",
            "label": "PUMS, VALAIYEDUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14636",
            "district": "29",
            "Block": "317",
            "label": "GOVT HIGHER SECONDARY SCHOOL (BOYS) METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14637",
            "district": "29",
            "Block": "317",
            "label": "GHSS(G) METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14638",
            "district": "29",
            "Block": "317",
            "label": "SAMS THATHIENGARPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14639",
            "district": "29",
            "Block": "317",
            "label": "GGHSS THATHIENGARPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14640",
            "district": "29",
            "Block": "317",
            "label": "GHSS(B) THATHIENGARPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14641",
            "district": "29",
            "Block": "373",
            "label": "PUMS, T.PATHERPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14642",
            "district": "29",
            "Block": "373",
            "label": "PUMS, T.MANGAPATTI PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14643",
            "district": "29",
            "Block": "373",
            "label": "GHSS, T.MURUNGAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14644",
            "district": "29",
            "Block": "373",
            "label": "ADWHS, KOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14645",
            "district": "29",
            "Block": "373",
            "label": "PUMS, KOPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14646",
            "district": "29",
            "Block": "373",
            "label": "GOVT HIGH SCHOOL, NAGANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14647",
            "district": "29",
            "Block": "373",
            "label": "SVVHSS, VAIRICHETTIPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14648",
            "district": "29",
            "Block": "373",
            "label": "GHS, SOBANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14649",
            "district": "29",
            "Block": "373",
            "label": "AMS, VENKATACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14650",
            "district": "29",
            "Block": "373",
            "label": "GTRHSS, TOP SENGATTUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14651",
            "district": "29",
            "Block": "373",
            "label": "GTRMS, PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14652",
            "district": "29",
            "Block": "373",
            "label": "GHSS,UPPILIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14653",
            "district": "29",
            "Block": "373",
            "label": "RCMS, KOTTAPALAYAM (BOYS)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14654",
            "district": "29",
            "Block": "373",
            "label": "OLLGHSS, KOTTAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14655",
            "district": "29",
            "Block": "373",
            "label": "GOVT. ADW HS, BALAKRISHNAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14656",
            "district": "29",
            "Block": "373",
            "label": "Government Higher Secondary School, B.METTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14657",
            "district": "29",
            "Block": "373",
            "label": "PUMS, E.KEELAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14658",
            "district": "29",
            "Block": "373",
            "label": "HAMS, OKKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14659",
            "district": "29",
            "Block": "373",
            "label": "AAGHSS, PACHAPERUMALPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14660",
            "district": "29",
            "Block": "373",
            "label": "GHS, NETTAVELAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14661",
            "district": "29",
            "Block": "373",
            "label": "PUMS, ALATHUDAIYANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14662",
            "district": "29",
            "Block": "373",
            "label": "GHS, SIRUNAVALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14663",
            "district": "29",
            "Block": "373",
            "label": "PUMS, KAMATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14664",
            "district": "29",
            "Block": "373",
            "label": "AGMHSS, ERAGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14665",
            "district": "29",
            "Block": "373",
            "label": "AMS, ALAGAPURI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14666",
            "district": "29",
            "Block": "381",
            "label": "PUMS, SUNDAKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14667",
            "district": "29",
            "Block": "381",
            "label": "GHS, KARUMAGOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14668",
            "district": "29",
            "Block": "381",
            "label": "GHSS, ARASUNILAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14669",
            "district": "29",
            "Block": "381",
            "label": "GHSS, ELANGAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14670",
            "district": "29",
            "Block": "381",
            "label": "GHSS, THAVALAIVEERANPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14671",
            "district": "29",
            "Block": "381",
            "label": "PUMS, ELAMANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14672",
            "district": "29",
            "Block": "381",
            "label": "GHS, ENAMPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14673",
            "district": "29",
            "Block": "381",
            "label": "GHS, PUDUVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14674",
            "district": "29",
            "Block": "381",
            "label": "PUMS, ANANKARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14675",
            "district": "29",
            "Block": "381",
            "label": "PUMS, K-VALAYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14676",
            "district": "29",
            "Block": "381",
            "label": "PUMS, THOPPANAYAKKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14677",
            "district": "29",
            "Block": "381",
            "label": "PUMS, KUMARAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14678",
            "district": "29",
            "Block": "381",
            "label": "GHS, SADAYAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14679",
            "district": "29",
            "Block": "381",
            "label": "PUMS, PAMPATTIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14680",
            "district": "29",
            "Block": "381",
            "label": "PUMS, PUDHUMANIYARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14681",
            "district": "29",
            "Block": "381",
            "label": "R.C HSS, PERIYA ANAIKARAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14682",
            "district": "29",
            "Block": "381",
            "label": "PUMS, KALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14683",
            "district": "29",
            "Block": "381",
            "label": "GHSS, NADUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14684",
            "district": "29",
            "Block": "381",
            "label": "GHS, V.POOSARIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14685",
            "district": "29",
            "Block": "381",
            "label": "GHSS, ONTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14686",
            "district": "29",
            "Block": "381",
            "label": "GHSS, N.POOLAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14687",
            "district": "29",
            "Block": "381",
            "label": "PUMS, K.PUDUKKOTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14688",
            "district": "29",
            "Block": "381",
            "label": "LITTLE FLOWER M S, KARUNGULAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14689",
            "district": "29",
            "Block": "381",
            "label": "GHSS, KARUNGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14690",
            "district": "29",
            "Block": "381",
            "label": "PUMS,VAIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14691",
            "district": "29",
            "Block": "381",
            "label": "ARIGNAR ANNA GHSS, VAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14692",
            "district": "29",
            "Block": "381",
            "label": "PUMS, PANNAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14693",
            "district": "29",
            "Block": "381",
            "label": "PUMS  V.CHETTIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14694",
            "district": "29",
            "Block": "381",
            "label": "PUMS, INAMREDDIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20916",
            "district": "29",
            "Block": "15",
            "label": "K N R VIDHYALAYA,(CBSE) SRIRANGAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21030",
            "district": "29",
            "Block": "171",
            "label": "VIKAS VIDHYASHRAM MATRICULATION HIGHER SECONDARY SCHOOL, INAMKULATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21091",
            "district": "29",
            "Block": "171",
            "label": "SAS VIDYALAYA (CBSE), DHEERAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21092",
            "district": "29",
            "Block": "168",
            "label": "SOWMA PUBLIC SCHOOL(CBSE),MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21093",
            "district": "29",
            "Block": "176",
            "label": "SRI HAYAAGREEVAR VIDHYALAYA (CBSE),M.KALLUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21163",
            "district": "29",
            "Block": "343",
            "label": "CHELLAMMAL MATRIC GIRLS HR SEC SCHOOL VENGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21164",
            "district": "29",
            "Block": "357",
            "label": "SOWDAMBIKAA MATRIC GIRLS HR. SEC. SCHOOL,THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21247",
            "district": "29",
            "Block": "366",
            "label": "CARMELS ENGLISH SCHOOL,RAMALINGANAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21279",
            "district": "29",
            "Block": "176",
            "label": "S.R VIDHYALAYA MATRICULATION HR. SEC. SCHOOL, AYYANARPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21280",
            "district": "29",
            "Block": "353",
            "label": "SREE SARRADHA MATRICULATION HS SCHOOL, KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21324",
            "district": "29",
            "Block": "15",
            "label": "VIVEKANANDA VIDYALAYA CBSE,THIRUPPARAITHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21325",
            "district": "29",
            "Block": "171",
            "label": "MOUNT LITERA ZEE HSS (CBSE), UYYAKONDAN THIRUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21326",
            "district": "29",
            "Block": "366",
            "label": "SANTHANAM VIDHYALAYA (CBSE) SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21327",
            "district": "29",
            "Block": "343",
            "label": "CHELLAMMAL CBSE SCHOOL VENGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21328",
            "district": "29",
            "Block": "357",
            "label": "SRM PUBLIC SCHOOL(CBSE),THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21356",
            "district": "29",
            "Block": "367",
            "label": "SEVENTH DAY ADVENTIST (ICSE)- BEEMANAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21372",
            "district": "29",
            "Block": "15",
            "label": "KAMATCHI MATRICULATION SCHOOL, POTHAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21373",
            "district": "29",
            "Block": "15",
            "label": "GHS,POSAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21374",
            "district": "29",
            "Block": "171",
            "label": "GHS (GIRLS), INAMKULATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21375",
            "district": "29",
            "Block": "168",
            "label": "GHS,KARUMPULIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21376",
            "district": "29",
            "Block": "168",
            "label": "GHS,PUTHANATHAM(GIRLS)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21377",
            "district": "29",
            "Block": "317",
            "label": "GOVT HIGH SCHOOL,KARIGALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21378",
            "district": "29",
            "Block": "317",
            "label": "GHS,VELAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21379",
            "district": "29",
            "Block": "381",
            "label": "GHS,ARASUNILAIPALAYAM(GIRLS)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21504",
            "district": "29",
            "Block": "15",
            "label": "ADW HS,KONDAYAMPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21505",
            "district": "29",
            "Block": "15",
            "label": "ADWHS,S.PUDUKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21506",
            "district": "29",
            "Block": "152",
            "label": "ADWHS,MELAVALADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21507",
            "district": "29",
            "Block": "172",
            "label": "GOVT ADWHS ,VENGANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21508",
            "district": "29",
            "Block": "168",
            "label": "GADWHS,SEVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21509",
            "district": "29",
            "Block": "176",
            "label": "ADWHS,PALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21510",
            "district": "29",
            "Block": "192",
            "label": "GADWHS PERUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21511",
            "district": "29",
            "Block": "353",
            "label": "ADWHS,ARIYANAMPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21512",
            "district": "29",
            "Block": "353",
            "label": "GOVT. ADW HS,M.PUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21527",
            "district": "29",
            "Block": "366",
            "label": "FATIMA AIDED HIGH SCHOOL PUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21532",
            "district": "29",
            "Block": "168",
            "label": "ST.IGNATIUS HIGH SCHOOL,AVARAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21759",
            "district": "29",
            "Block": "367",
            "label": "LOVE DALE MATRIC SCHOOL,KAVERI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21760",
            "district": "29",
            "Block": "168",
            "label": "CHELLAMMAL MATRIC HIGHER SECONDARY SCHOOL, THIRUMALAIYANPATTI, MANAPPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21761",
            "district": "29",
            "Block": "168",
            "label": "SRI GURU VIDYALAYA (CBSE),MANAPPARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21762",
            "district": "29",
            "Block": "171",
            "label": "ALPHA CAMBRIDGE INTERNATIONAL. SCHOOL, K.K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21763",
            "district": "29",
            "Block": "171",
            "label": "SBIOA SR. SEC. SCHOOL (CBSE), AIRPORT POST, TRICHY-7",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21878",
            "district": "29",
            "Block": "172",
            "label": "SVR MATRIC SCHOOL,ATHANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21879",
            "district": "29",
            "Block": "172",
            "label": "RITI VIKASH VIDHAYALA,THEERAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21919",
            "district": "29",
            "Block": "15",
            "label": "GHS, POTHAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21920",
            "district": "29",
            "Block": "366",
            "label": "CAUVERY GLOBAL SCHOOL (CBSE),TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21921",
            "district": "29",
            "Block": "366",
            "label": "SRI VIGNESH PUBLIC SCHOOL (CBSE), WORAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21958",
            "district": "29",
            "Block": "168",
            "label": "SARASWATHI VIDHYALAYA MATRICULATION HSS,ANDAVAR KOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21961",
            "district": "29",
            "Block": "176",
            "label": "CHIRUMALAR HIGHER SECONDARY SCHOOL,VELLAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21964",
            "district": "29",
            "Block": "343",
            "label": "SHRINE VAILANKANNI MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22185",
            "district": "29",
            "Block": "176",
            "label": "DMI ST.JOSEPH GLOBAL CBSE SCHOOL,YAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22186",
            "district": "29",
            "Block": "192",
            "label": "RAGHAVENDRA MATRICULATION HIGHER SEC. SCHOOL, KARATTAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22187",
            "district": "29",
            "Block": "192",
            "label": "SHRI JAYENDRA VIDHYALAYA CBSE SCHOOL, VADUGAPATTY, MUSIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22188",
            "district": "29",
            "Block": "343",
            "label": "MATHA MATRICULATION SCHOOL,M.K.KOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22189",
            "district": "29",
            "Block": "357",
            "label": "SRI BALAJI VIDHYALAYA MATRICULATION HR. SEC. SCH., GOVINDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22190",
            "district": "29",
            "Block": "357",
            "label": "ATHAVAN ACADEMY MATRICULATION HS, PONNUSANGAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22191",
            "district": "29",
            "Block": "357",
            "label": "RAJ VIDYA BHAVAN GIRLS MATRICULATION HS, THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22192",
            "district": "29",
            "Block": "317",
            "label": "CHELAMMAL VIDHYAASHRAM Senior SEC. CBSE AYETHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22193",
            "district": "29",
            "Block": "373",
            "label": "RSK INTERNATIONAL (CBSE) SCHOOL,VENKATACHALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22519",
            "district": "29",
            "Block": "152",
            "label": "GHS, LALGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22520",
            "district": "29",
            "Block": "171",
            "label": "GHS, YAGAPUDAIYANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22521",
            "district": "29",
            "Block": "171",
            "label": "SIGMA SCHOOL, MANIKANDAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22522",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, OLAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22523",
            "district": "29",
            "Block": "171",
            "label": "AKARA WORLD SCHOOL (CBSE), K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22524",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, EDAMALAIPATTI PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22525",
            "district": "29",
            "Block": "176",
            "label": "GHS,PALUVANCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22526",
            "district": "29",
            "Block": "176",
            "label": "SETHU MATRICULATION HIGHER SECONDARY SCHOOL, THUVARANKURICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22527",
            "district": "29",
            "Block": "192",
            "label": "GHS PERIYAKODUNTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22528",
            "district": "29",
            "Block": "192",
            "label": "GHS CHINNA KODUNTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22529",
            "district": "29",
            "Block": "343",
            "label": "BVM GLOBAL TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22530",
            "district": "29",
            "Block": "343",
            "label": "KENDRIYA VIDYALAYA GOLDEN ROCK (SR) TRICHY",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "22531",
            "district": "29",
            "Block": "353",
            "label": "SHAANTHI NIKKETHAN MATRICULATION SCHOOL ALAGARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22532",
            "district": "29",
            "Block": "357",
            "label": "GHS, VENGADATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22533",
            "district": "29",
            "Block": "357",
            "label": "MAHATHMA SCHOOL OF EXCELLENCE,MURUGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22534",
            "district": "29",
            "Block": "317",
            "label": "GHS,V.GANESAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22535",
            "district": "29",
            "Block": "373",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRICULATION SCHOOL, UPPILIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22689",
            "district": "29",
            "Block": "258",
            "label": "SRI VETRI VIDHAYALAYA (CBSE) , VELLANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22790",
            "district": "29",
            "Block": "317",
            "label": "Bodhi vidhyalaya CBSE,Annai Nagar,M.Pudhupatti,musiri.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22792",
            "district": "29",
            "Block": "317",
            "label": "VIDHYA BHARATHI VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22793",
            "district": "29",
            "Block": "172",
            "label": "SRV PUBLIC SCHOOL, SAMAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22794",
            "district": "29",
            "Block": "171",
            "label": "SRV PUBLIC SCHOOL, PIRATIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22796",
            "district": "29",
            "Block": "367",
            "label": "St. James Academy(CBSE),TRICHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22797",
            "district": "29",
            "Block": "171",
            "label": "AWNIYA PUBLIC SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22799",
            "district": "29",
            "Block": "366",
            "label": "NR PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22800",
            "district": "29",
            "Block": "357",
            "label": "Sri Krishna Vidhyalaya Matric School, MURUGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22804",
            "district": "29",
            "Block": "343",
            "label": "TIRUCHY PUBLIC SCHOOL ,THUVAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22867",
            "district": "29",
            "Block": "172",
            "label": "RANE VIDYALAYA (CBSE), THEERAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22985",
            "district": "29",
            "Block": "176",
            "label": "GHS Muthalvarpatty",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22999",
            "district": "29",
            "Block": "168",
            "label": "GHS Vadakkuserpatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23091",
            "district": "29",
            "Block": "176",
            "label": "MONTFORT SCHOOL (CBSE) PALAKURICHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23092",
            "district": "29",
            "Block": "176",
            "label": "PONNAR SANKAR KALVIYALYA WORLD SHOOL (CBSE) IRATTIYAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23093",
            "district": "29",
            "Block": "176",
            "label": "ALIF MATRICULATION SCHOOL THUVARANKURICHY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23129",
            "district": "29",
            "Block": "258",
            "label": "CHRIST THE KING PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23135",
            "district": "29",
            "Block": "171",
            "label": "MOUNT HIRA PUBLIC SCHOOL, K.SATHANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23136",
            "district": "29",
            "Block": "343",
            "label": "WISDOM WEALTH INTERNATION ICSE SCHOOL, MORAI CITY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23272",
            "district": "29",
            "Block": "168",
            "label": "GHS KARUTHAKODANGIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23289",
            "district": "29",
            "Block": "172",
            "label": "MAHALAKSHMI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23578",
            "district": "29",
            "Block": "15",
            "label": "SVV PUBLIC SCHOOL ALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23579",
            "district": "29",
            "Block": "171",
            "label": "TIRUCHIRAPPALLI DISTRICT  GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23580",
            "district": "29",
            "Block": "171",
            "label": "MOULANA JAMALI MATRICULATION SCHOOL, INAM KULATHUR, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23581",
            "district": "29",
            "Block": "171",
            "label": "THE INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23582",
            "district": "29",
            "Block": "171",
            "label": "AGACHEVI TRANSITIONAL SCHOOL FOR HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23583",
            "district": "29",
            "Block": "353",
            "label": "Cambridge Matriculation School,Meikkalnayakanpatti",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23584",
            "district": "29",
            "Block": "367",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23585",
            "district": "29",
            "Block": "367",
            "label": "RAJAM KRISHNAMURTHY (CBSE) PUBLIC SCHOOL, KARUMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23871",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KATTUPATTY",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23872",
            "district": "29",
            "Block": "171",
            "label": "TIRUCHIRAPPALLI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23873",
            "district": "29",
            "Block": "171",
            "label": "ACHARIYA SIKSHA MANDIR,K.SATHANUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23883",
            "district": "29",
            "Block": "192",
            "label": "PUMS VELLAKKALPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24000",
            "district": "29",
            "Block": "317",
            "label": "TKV MATRICULATION SCHOOL,THUMBALAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24001",
            "district": "29",
            "Block": "317",
            "label": "V.J.MATRICULATION SCHOOL,SITTILARAI(PO),MUSIRI,TIRUCHIRAPPALLI DISTRICT",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24032",
            "district": "29",
            "Block": "353",
            "label": "SRI SABARI VIDYALA MATRICULATION SCHOOL,THOTTIYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24033",
            "district": "29",
            "Block": "353",
            "label": "SOWDAMBIKAA VIDYALAYA SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24045",
            "district": "29",
            "Block": "367",
            "label": "ST.JOHN'S VESTRY,TRICHY",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24055",
            "district": "29",
            "Block": "381",
            "label": "GHS, PUDUVADI",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "30": [
        {
            "key": "238",
            "district": "30",
            "Block": "174",
            "label": "GHS DEVARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23646",
            "district": "30",
            "Block": "262",
            "label": "Atomic Energy Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "243",
            "district": "30",
            "Block": "174",
            "label": "GHSS VANNICONENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "245",
            "district": "30",
            "Block": "174",
            "label": "GHS VELLAPPANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "246",
            "district": "30",
            "Block": "174",
            "label": "GHS VENKATACHALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "249",
            "district": "30",
            "Block": "174",
            "label": "HINDU MIDDLE SSHOOL CHOKKANACHIYALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "255",
            "district": "30",
            "Block": "174",
            "label": "PUMS PANNEERUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "259",
            "district": "30",
            "Block": "174",
            "label": "SANKARI MATRIC HR. SEC. SCHOOL, DEVARKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "263",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS DEVARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "264",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS MELAILANDAIKUALM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "265",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS MOOVIRUNTHALI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "266",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS NORTH ACHAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "267",
            "district": "30",
            "Block": "174",
            "label": "TIHSS MELAILANDAIKUALM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "463",
            "district": "30",
            "Block": "174",
            "label": "PUMS PALAMADAI UDAYANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "496",
            "district": "30",
            "Block": "46",
            "label": "R.C.MS VEERAVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "558",
            "district": "30",
            "Block": "222",
            "label": "MARY SARGENT HIGHER SECONDARY SCHOOL FOR GIRLS,PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "633",
            "district": "30",
            "Block": "222",
            "label": "Sri Jayendra Swamigal Silver jubilee MHSS  Maharaja Nagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "659",
            "district": "30",
            "Block": "9",
            "label": "TDTA MS- KEELA SIVANTHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "697",
            "district": "30",
            "Block": "46",
            "label": "SIVANTHI MATRICULATION HSS CHERANMAHADEVI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "710",
            "district": "30",
            "Block": "222",
            "label": "SRI LALITHA VIDHYASHRAM MATRICULATION SCHOOL  PERUMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "716",
            "district": "30",
            "Block": "222",
            "label": "PUSHPALATHA MHSS TIRUNELVELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "760",
            "district": "30",
            "Block": "92",
            "label": "NATIONAL MATRIC HR SEC SCHOOL KALAKAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "773",
            "district": "30",
            "Block": "222",
            "label": "MANIVASAKAR ARULNERI MS PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "783",
            "district": "30",
            "Block": "222",
            "label": "HAMEEM MS MELAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "785",
            "district": "30",
            "Block": "204",
            "label": "JN MS SUBRAMANIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "822",
            "district": "30",
            "Block": "385",
            "label": "ST. Annes Metric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "854",
            "district": "30",
            "Block": "229",
            "label": "DEEPA MATRIC HIGHER SECONDARY SCHOOL,MUKKUDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "970",
            "district": "30",
            "Block": "46",
            "label": "ST. ANTONY'S MATRICULATION HSS, KALLIDAIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1037",
            "district": "30",
            "Block": "92",
            "label": "RANI MATRIC HR SEC SCHOOL ERUVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1046",
            "district": "30",
            "Block": "221",
            "label": "TIME MATRIC HIGHER SECONDARY SCHOOL, ARAIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1067",
            "district": "30",
            "Block": "385",
            "label": "KINGS MAT. HSS, PUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1086",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS PERPILANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1089",
            "district": "30",
            "Block": "385",
            "label": "RVR NURSERY & PRIMARY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1091",
            "district": "30",
            "Block": "46",
            "label": "RC MS CHERANMAHADEVI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1114",
            "district": "30",
            "Block": "92",
            "label": "KAILASAPATHI HINDU MS SINGIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1121",
            "district": "30",
            "Block": "92",
            "label": "HINDU MS DEVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1130",
            "district": "30",
            "Block": "385",
            "label": "NEHRU NATIONAL MS VADAKANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1220",
            "district": "30",
            "Block": "222",
            "label": "ST.JOSEPH'S MATRICULATION SCHOOL SENTHILNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1264",
            "district": "30",
            "Block": "221",
            "label": "SARATHA MHS SANKARNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1281",
            "district": "30",
            "Block": "385",
            "label": "ANNAI MATRIC. HR. SEC. SCHOOL , VALLIOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1513",
            "district": "30",
            "Block": "229",
            "label": "BOO VIJESH MAT  MUKKUDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1517",
            "district": "30",
            "Block": "174",
            "label": "PUMS,PATHINALAMPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1624",
            "district": "30",
            "Block": "92",
            "label": "K.A.M.P. MEERANIA MS KALAKAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1632",
            "district": "30",
            "Block": "221",
            "label": "IIPE LAXMI RAMAN MATRIC HIGHER SECONDARY SCHOOL UTHAMAPANDIANKULAM 627351",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1646",
            "district": "30",
            "Block": "92",
            "label": "TDTA MS KEELA SADAYAMANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1685",
            "district": "30",
            "Block": "92",
            "label": "ST JOHNS MATRIC HR SEC SCHOOL PADALAIYARKULAM, KALAKAD.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1686",
            "district": "30",
            "Block": "174",
            "label": "PUMS,NELLAITHIRUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1718",
            "district": "30",
            "Block": "361",
            "label": "STC Branch MS, Sindupoonthurai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1736",
            "district": "30",
            "Block": "262",
            "label": "HAVARD HI-TECH MATRIC HIGHER SECONERY  SCHOOL ULAGARATCHAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1786",
            "district": "30",
            "Block": "9",
            "label": "Aroma Matric HS - Ambasamudram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1811",
            "district": "30",
            "Block": "92",
            "label": "SANTHOSHA VITHYALAYA DONAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1821",
            "district": "30",
            "Block": "174",
            "label": "K.M.P VIDYALAYA MATRIC. HR.SEC.SCHOOL, MANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1830",
            "district": "30",
            "Block": "222",
            "label": "DON BOSCO MHSS-XAVIERCOLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1836",
            "district": "30",
            "Block": "92",
            "label": "PUMS KALAKAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1892",
            "district": "30",
            "Block": "361",
            "label": "Adaikalamatha Mat. HS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1899",
            "district": "30",
            "Block": "385",
            "label": "KEINS MATRIC HR SEC SCHOOL, VALLIOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1903",
            "district": "30",
            "Block": "221",
            "label": "SRI JEYANDRA SARASWATHI SWAMIGAL GOLDEN JMHSS SANKARNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1906",
            "district": "30",
            "Block": "222",
            "label": "AUPET CHINMAYA VID MHSS PALAY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1930",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY MHSS MAIGANAST , PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1935",
            "district": "30",
            "Block": "262",
            "label": "DANIEL THOMAS MATRIC HSS TISAIYANVILLAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1949",
            "district": "30",
            "Block": "174",
            "label": "PUMS THALAYOOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2019",
            "district": "30",
            "Block": "222",
            "label": "BELL ENGLISH MHSS PALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2029",
            "district": "30",
            "Block": "174",
            "label": "PUMS,METTU PIRANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2032",
            "district": "30",
            "Block": "221",
            "label": "ROSEMARY MHSS, V.M.CHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2040",
            "district": "30",
            "Block": "174",
            "label": "TDTAMS,PARVATHIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2047",
            "district": "30",
            "Block": "46",
            "label": "ALMIGHTY MATRICULATION  Hr. Sec. SCHOOL CHERANMAHADEVI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2081",
            "district": "30",
            "Block": "222",
            "label": "MEGDALIN MHSS RAHMATH NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2103",
            "district": "30",
            "Block": "9",
            "label": "EXCELLENT MHS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2109",
            "district": "30",
            "Block": "385",
            "label": "Vivekananda kendra vidyalaya matriculation higher secondary school, Vallioor",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2139",
            "district": "30",
            "Block": "385",
            "label": "Pulliman Matric Higher Secondary School, Panagudi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2155",
            "district": "30",
            "Block": "361",
            "label": "Nivedita Mat. HS, Palaya Pettai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2164",
            "district": "30",
            "Block": "222",
            "label": "GOOD SAMARITTAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2185",
            "district": "30",
            "Block": "222",
            "label": "CORPN NEW MS VANNARPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2194",
            "district": "30",
            "Block": "174",
            "label": "PUMS VADAKKIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2198",
            "district": "30",
            "Block": "204",
            "label": "ST. FRANCIS MATRICULATION  HIGHER SECONDARY SCHOOL, NAMBINAGAR, NANGUNERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2199",
            "district": "30",
            "Block": "222",
            "label": "THE GOLDEN JUBILEE MATRIC HSS MELAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2206",
            "district": "30",
            "Block": "222",
            "label": "C.S.I. MHSS PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2207",
            "district": "30",
            "Block": "222",
            "label": "SRI SATHYA SAI GURUGULAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2269",
            "district": "30",
            "Block": "361",
            "label": "Socratees Mat. HS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2293",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY MHSS MAHARAJANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2297",
            "district": "30",
            "Block": "9",
            "label": "LAKSHMI VIDYA NIKETAN MATRICULATION HIGHER SECONDARY SCHOOL, VAGAIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2315",
            "district": "30",
            "Block": "9",
            "label": "MERIT MHSS,AMBASAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2317",
            "district": "30",
            "Block": "46",
            "label": "THIRU GNANA SAMBANDAR MIDDLE SCHOOL  VEERAVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2349",
            "district": "30",
            "Block": "92",
            "label": "ST XAVIER'S MS XAVIERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2365",
            "district": "30",
            "Block": "204",
            "label": "PUMS PUTHUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2403",
            "district": "30",
            "Block": "174",
            "label": "PUMS,VENKATACHALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2461",
            "district": "30",
            "Block": "46",
            "label": "COMMITTEE MIDDLE SCHOOL CHERANMAHADEVI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2482",
            "district": "30",
            "Block": "222",
            "label": "ST PAULS MATRIC SCHOOL PERUMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2510",
            "district": "30",
            "Block": "222",
            "label": "JOSE MHSS POTHIGAI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2594",
            "district": "30",
            "Block": "92",
            "label": "TDTA MS PERUMALKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2600",
            "district": "30",
            "Block": "92",
            "label": "PUMS ERUVADI CENTRAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2608",
            "district": "30",
            "Block": "92",
            "label": "HINDU MS MAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2621",
            "district": "30",
            "Block": "204",
            "label": "PU MS NANGUNERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2628",
            "district": "30",
            "Block": "174",
            "label": "PUMS,THIRUPANIKARISALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2639",
            "district": "30",
            "Block": "92",
            "label": "MUTHIAH HINDU MS KOVILPATHU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2740",
            "district": "30",
            "Block": "222",
            "label": "SRInGERI SARAdA VIDYALAYA MATRIC HR SEC SCHOOL THIYAGARAJANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2792",
            "district": "30",
            "Block": "385",
            "label": "ST PAUL RC MS KAVALKINARU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2795",
            "district": "30",
            "Block": "385",
            "label": "TDTA MIDDLE SCHOOL KALANTHAPANAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2798",
            "district": "30",
            "Block": "222",
            "label": "JESURAJA HITECH MATRICULATION SCHOOL,PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2848",
            "district": "30",
            "Block": "174",
            "label": "ST ANTONY HSS,ALAVANTHANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2865",
            "district": "30",
            "Block": "361",
            "label": "Thayammai Ammal MS, Tirunelveli",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2922",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS,NORTH VAGAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2934",
            "district": "30",
            "Block": "229",
            "label": "BHARATHI VIDYALAYAM MIDDLE SCHOOL, RENGASAMUDRAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2972",
            "district": "30",
            "Block": "92",
            "label": "JAWAHAR  NATIONAL MS SALAIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3051",
            "district": "30",
            "Block": "222",
            "label": "CORPN MS PERUMAL SANNATHI , PALAYAMKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3143",
            "district": "30",
            "Block": "229",
            "label": "RC MS THALARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3152",
            "district": "30",
            "Block": "92",
            "label": "HINDU MARAVAR MS MALAIYADIPUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3179",
            "district": "30",
            "Block": "361",
            "label": "CORPORATION MIDDLE SCHOOL RAHUMANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3184",
            "district": "30",
            "Block": "174",
            "label": "PUMS,KURICHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3225",
            "district": "30",
            "Block": "229",
            "label": "NEWLIFE MATRIC ACADEMY, PAPPAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3404",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY MHSS PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3443",
            "district": "30",
            "Block": "46",
            "label": "Panchayat  Union Middle School Kallidaikurichi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3494",
            "district": "30",
            "Block": "174",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , DURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3558",
            "district": "30",
            "Block": "92",
            "label": "ALHUDHA MATRIC HR SEC SCHOOL ERUVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3598",
            "district": "30",
            "Block": "46",
            "label": "ST.JOHNS MIDDLE SCHOOL VEERAVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3644",
            "district": "30",
            "Block": "361",
            "label": "Hindu MS, M V Puram, TVL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3662",
            "district": "30",
            "Block": "222",
            "label": "CMS MANAKAVALAM HOSPITAL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3724",
            "district": "30",
            "Block": "46",
            "label": "PUMS VEERAVANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3734",
            "district": "30",
            "Block": "385",
            "label": "VADAKKU HINDU MIDDLE SCHOOL PANAGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3759",
            "district": "30",
            "Block": "46",
            "label": "PUMS MELAUPOORANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3786",
            "district": "30",
            "Block": "46",
            "label": "SRI RAMAKRISHNA MIDDLE SCHOOL CHERANMAHADEVI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3835",
            "district": "30",
            "Block": "222",
            "label": "Sarah Tucker Hr Sec School For Girls, Palayamkottai",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3854",
            "district": "30",
            "Block": "46",
            "label": "LAKSHMIPATHI MS KALLIDAIKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3900",
            "district": "30",
            "Block": "46",
            "label": "SUN MATRICULATION SCHOOL CHERANMAHADEVI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3933",
            "district": "30",
            "Block": "361",
            "label": "Little Flower Mat. HSS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3989",
            "district": "30",
            "Block": "385",
            "label": "LITTLE FLOWER NURSERY PS PANAGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4056",
            "district": "30",
            "Block": "361",
            "label": "Presentation Conv. Mat. HS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4222",
            "district": "30",
            "Block": "385",
            "label": "Balakrishna Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4289",
            "district": "30",
            "Block": "385",
            "label": "St.XAVIER'S MS PERUMANAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4305",
            "district": "30",
            "Block": "385",
            "label": "THRESA MS PUSHPAVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4310",
            "district": "30",
            "Block": "174",
            "label": "TDTAMS,NANCHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4325",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS SAHAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4328",
            "district": "30",
            "Block": "222",
            "label": "RAHMANIA MHSS MELAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4334",
            "district": "30",
            "Block": "92",
            "label": "SA MS NAMBIYARADAIPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4364",
            "district": "30",
            "Block": "385",
            "label": "NALANDHA MATRIC HIGH SCHOOL, VALLIOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4370",
            "district": "30",
            "Block": "385",
            "label": "BHARATH MATRIC SCHOOL, THALAVAIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4403",
            "district": "30",
            "Block": "361",
            "label": "IDEAL MATRICULATION SCHOOL, PETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4494",
            "district": "30",
            "Block": "92",
            "label": "HINDU MS THIRUKURUNGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4510",
            "district": "30",
            "Block": "222",
            "label": "CMS MARY ARDEN MS PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4513",
            "district": "30",
            "Block": "385",
            "label": "TDTA MIDDLE SCHOOL. THIRUMALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4588",
            "district": "30",
            "Block": "361",
            "label": "Valliammai Mat. MS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4599",
            "district": "30",
            "Block": "204",
            "label": "CMS MS AMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4656",
            "district": "30",
            "Block": "222",
            "label": "CHRISTHU RAJA HSS PALAY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4698",
            "district": "30",
            "Block": "262",
            "label": "SACRED HEART MIDDLE SCHOOL T.KALLIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4722",
            "district": "30",
            "Block": "46",
            "label": "HINDU MIDDLE SCHOOL KARUKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4799",
            "district": "30",
            "Block": "262",
            "label": "HEBRON MATRIC HSS .KOODANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4853",
            "district": "30",
            "Block": "9",
            "label": "ST. MARY'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4983",
            "district": "30",
            "Block": "262",
            "label": "KAMARAJ MIDDLE SCHOOL T.KALLIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5003",
            "district": "30",
            "Block": "204",
            "label": "PUMS CHINTHAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5028",
            "district": "30",
            "Block": "385",
            "label": "HOLYCROSS RC MS VALLIOOR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5130",
            "district": "30",
            "Block": "46",
            "label": "THE MIDDLE SCHOOL MELASEVAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5145",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS THALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5171",
            "district": "30",
            "Block": "9",
            "label": "ST.XAVIER'S MS - IRUTHAYAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5178",
            "district": "30",
            "Block": "221",
            "label": "PUMS,KEELATHIDIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5368",
            "district": "30",
            "Block": "92",
            "label": "TDTA MS IDAYANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5460",
            "district": "30",
            "Block": "222",
            "label": "GANESA MOORTHY MS KEELAVEERA RAGAVAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5479",
            "district": "30",
            "Block": "385",
            "label": "ST.JOSEPH'S MS KILAVANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5492",
            "district": "30",
            "Block": "9",
            "label": "HINDU MS - VAGAIGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5586",
            "district": "30",
            "Block": "222",
            "label": "Oxfo Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5617",
            "district": "30",
            "Block": "9",
            "label": "ST. THERASA MS - VKPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5626",
            "district": "30",
            "Block": "221",
            "label": "THIRU THAYUMANASUNDARAM MS, SIVANTHIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5646",
            "district": "30",
            "Block": "46",
            "label": "CHITHRA MATRICULATION  SCHOOL CHERANMAHADEVI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5681",
            "district": "30",
            "Block": "262",
            "label": "ST.ALOYSIUS MS SOUNDARAPANDIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5688",
            "district": "30",
            "Block": "9",
            "label": "PUMS - PAPANASAM, LOWER DAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5691",
            "district": "30",
            "Block": "361",
            "label": "Our Own Modern Mat. HSS, Pettai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5715",
            "district": "30",
            "Block": "92",
            "label": "TDTA MS MANJUVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5722",
            "district": "30",
            "Block": "9",
            "label": "STC BRANCH MS-AMBASAMUDRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5874",
            "district": "30",
            "Block": "46",
            "label": "CHITHRA MATRICULATION HIGHER SECONDARY SCHOOL PATHAMADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5903",
            "district": "30",
            "Block": "204",
            "label": "TDTA MS MEENAKSHINATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5986",
            "district": "30",
            "Block": "46",
            "label": "PUMS KOONIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6118",
            "district": "30",
            "Block": "385",
            "label": "SRM MIDDLE SCHOOL NAKKANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6124",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS VALLIAMMAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6141",
            "district": "30",
            "Block": "46",
            "label": "PUMS PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6142",
            "district": "30",
            "Block": "222",
            "label": "ANGELO MHSS PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6169",
            "district": "30",
            "Block": "204",
            "label": "PU MS PATTARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6227",
            "district": "30",
            "Block": "204",
            "label": "GOVT MS KOONTHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6311",
            "district": "30",
            "Block": "385",
            "label": "PUM SCHOOL ,VALLIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6328",
            "district": "30",
            "Block": "385",
            "label": "HINDU MIDDLE SCHOOL. THIRUMALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6438",
            "district": "30",
            "Block": "385",
            "label": "HINDU MIDDLE SCHOOL CHETTIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6493",
            "district": "30",
            "Block": "229",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,,ANAINTHANADARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6513",
            "district": "30",
            "Block": "9",
            "label": "RC MARAVAR MS- VAIRAVIKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6716",
            "district": "30",
            "Block": "229",
            "label": "THIRUVALLUVAR  MS SATTUPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6736",
            "district": "30",
            "Block": "229",
            "label": "SARATHA MIDDLE SCHOOL,IDAIKAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6741",
            "district": "30",
            "Block": "221",
            "label": "RADASAMY PST .V NATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19613",
            "district": "30",
            "Block": "9",
            "label": "GHS, MANNARKOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19614",
            "district": "30",
            "Block": "9",
            "label": "PUMS - VKPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19615",
            "district": "30",
            "Block": "9",
            "label": "NADAR UNION MS- MUTHALIYARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19616",
            "district": "30",
            "Block": "9",
            "label": "SENAI THALAIVAR HSS - VKPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19617",
            "district": "30",
            "Block": "9",
            "label": "SACRED HEART HS- IRUTHAYAKULAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19618",
            "district": "30",
            "Block": "9",
            "label": "ANDREW MATRIC HS - VKPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19619",
            "district": "30",
            "Block": "9",
            "label": "BRILLIANT N&PS - IRUTHAYAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19620",
            "district": "30",
            "Block": "9",
            "label": "ST. MARY'S HSS- VKPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19621",
            "district": "30",
            "Block": "9",
            "label": "PLWA HSS - VKPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19622",
            "district": "30",
            "Block": "9",
            "label": "AMALI GIRLS HSS- IRUTHAYAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19623",
            "district": "30",
            "Block": "9",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PAPANASAM UPPERDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19624",
            "district": "30",
            "Block": "9",
            "label": "LOTUS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19625",
            "district": "30",
            "Block": "9",
            "label": "PUMS-AMBASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19626",
            "district": "30",
            "Block": "9",
            "label": "MOHAITHEEN FATHIMA MS AMBASAMUDRAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19627",
            "district": "30",
            "Block": "9",
            "label": "ST. JOSEPH'S HS-AMBASAMUDRAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19628",
            "district": "30",
            "Block": "9",
            "label": "A.V.RM.V.GOVT(G)HSS-AMBASAMUDRAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19629",
            "district": "30",
            "Block": "9",
            "label": "A.V.RM.V.MATRIC HSS-AMBASAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19630",
            "district": "30",
            "Block": "9",
            "label": "P.S.V. N&PS-AMBASAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19631",
            "district": "30",
            "Block": "9",
            "label": "VIKASA SCHOOL, AMBASAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19632",
            "district": "30",
            "Block": "9",
            "label": "THEERTHAPATHI HSS-AMBASAMUDRAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19633",
            "district": "30",
            "Block": "9",
            "label": "ST.XAVIERS MS, ADIVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19634",
            "district": "30",
            "Block": "9",
            "label": "REACH MATRIC HSS-CHETTIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19635",
            "district": "30",
            "Block": "9",
            "label": "GHSS, VELLANGULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19636",
            "district": "30",
            "Block": "9",
            "label": "SETHU SUBRAMANIA MS - OORKAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19637",
            "district": "30",
            "Block": "9",
            "label": "SRI KAMARAJ MS -AGASTHIYARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19638",
            "district": "30",
            "Block": "9",
            "label": "CAMBRIDGE MHS - AGASTHIYARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19639",
            "district": "30",
            "Block": "9",
            "label": "CAMBRIDGE INTERNATIONAL CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19640",
            "district": "30",
            "Block": "9",
            "label": "GHS - MANIMUTHAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19641",
            "district": "30",
            "Block": "9",
            "label": "GHS - MANJOLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19642",
            "district": "30",
            "Block": "9",
            "label": "ST.MICHAEL HS- SINGAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19643",
            "district": "30",
            "Block": "9",
            "label": "PUMS - KODARANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19644",
            "district": "30",
            "Block": "46",
            "label": "C.M.S.E.S DAVID MEMORIAL HIGHER SECONDARY SCHOOL, KARISAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19645",
            "district": "30",
            "Block": "46",
            "label": "GGHS CHERANMAHADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19646",
            "district": "30",
            "Block": "46",
            "label": "MUSLIM COM HS CHERANMAHADEVI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19647",
            "district": "30",
            "Block": "46",
            "label": "PERIYAR GHSS, CHERANMAHADEVI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19648",
            "district": "30",
            "Block": "46",
            "label": "MAH.GANDHI MAT.S KALLIDAIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19649",
            "district": "30",
            "Block": "46",
            "label": "GHSS KALLIDAIKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19650",
            "district": "30",
            "Block": "46",
            "label": "TILAK VIDYALAYA HR SEC SCHOOL KALLIDAIKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19651",
            "district": "30",
            "Block": "46",
            "label": "BHARATHIYAR GHSS VEERAVANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19652",
            "district": "30",
            "Block": "46",
            "label": "ST.JOHNS HSS VEERAVANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19653",
            "district": "30",
            "Block": "46",
            "label": "THE RAMASESHIER HIGHER SECONDARY SCHOOL, PATTAMADAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19654",
            "district": "30",
            "Block": "46",
            "label": "GGHSS PATHAMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19655",
            "district": "30",
            "Block": "46",
            "label": "TRINITY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19656",
            "district": "30",
            "Block": "46",
            "label": "TDTA HS MELASEVAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19657",
            "district": "30",
            "Block": "46",
            "label": "PANNAI VENKATARAMIER HS GOPALASAMUDRAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19658",
            "district": "30",
            "Block": "46",
            "label": "SCHOENSTATT MATRICULATION SCHOOL, PIRANCHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19659",
            "district": "30",
            "Block": "46",
            "label": "GOVT HIGH SCHOOL POTTAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19705",
            "district": "30",
            "Block": "92",
            "label": "CMS EVANGELICAL MS MELAPATHAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19706",
            "district": "30",
            "Block": "92",
            "label": "GOVT HIGH SCHOOL PADMANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19707",
            "district": "30",
            "Block": "92",
            "label": "AMIR JAMAL HSS IDAIYANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19708",
            "district": "30",
            "Block": "92",
            "label": "SRI NAMMALVAR MS KALLIKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19709",
            "district": "30",
            "Block": "92",
            "label": "ANNAI THERESA HS MEENAVANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19710",
            "district": "30",
            "Block": "92",
            "label": "K.A.M.P. MEERANIA HSS KALAKAD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19711",
            "district": "30",
            "Block": "92",
            "label": "GOVT HR SEC SCHOOL KALAKAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19712",
            "district": "30",
            "Block": "92",
            "label": "GOMATHI ARULNERI GIRLS HR SEC SCHOOL KALAKAD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19713",
            "district": "30",
            "Block": "92",
            "label": "WALKER HIGHER SECONDARY SCHOOL, DOHNAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19714",
            "district": "30",
            "Block": "92",
            "label": "TVS GOVT HR SEC SCHOOL THIRUGURUNGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19715",
            "district": "30",
            "Block": "92",
            "label": "GOVT(B)  HR SEC SCHOOL, ERUVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19716",
            "district": "30",
            "Block": "92",
            "label": "GOVT (G)  HR SEC SCHOOL,  ERUVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19742",
            "district": "30",
            "Block": "174",
            "label": "ST PETERS HSS,UKIRANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19743",
            "district": "30",
            "Block": "174",
            "label": "NIM HS,THERKUPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19744",
            "district": "30",
            "Block": "174",
            "label": "PUMS,SUBBIAHPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19745",
            "district": "30",
            "Block": "174",
            "label": "GHS,CHETTIKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19746",
            "district": "30",
            "Block": "174",
            "label": "GHS,MELAPILLAIYARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19747",
            "district": "30",
            "Block": "174",
            "label": "GOVT HSS,VADAKKU CHELIYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19748",
            "district": "30",
            "Block": "174",
            "label": "GHSS,GANGAIKONDAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19749",
            "district": "30",
            "Block": "174",
            "label": "GHSS,PALAMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19750",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS,THENKALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19751",
            "district": "30",
            "Block": "174",
            "label": "DR. AMBEDKAR GOVT.(A.D.W) HIGHER SECONDARY SCHOOL, NALLAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19752",
            "district": "30",
            "Block": "174",
            "label": "GOVT(ADW) GIRLS HR SEC SCHOOL, NALLAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19753",
            "district": "30",
            "Block": "174",
            "label": "GHS PALLAMADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19754",
            "district": "30",
            "Block": "174",
            "label": "GHSS,MANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19755",
            "district": "30",
            "Block": "229",
            "label": "SAROJINI MEMMS ,SIRUKKANKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19756",
            "district": "30",
            "Block": "174",
            "label": "G(ADW)HSS,THULUKKARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19757",
            "district": "30",
            "Block": "229",
            "label": "GHSS NADUKALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19758",
            "district": "30",
            "Block": "229",
            "label": "KINGS MAT SCHOOL, KONDANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19759",
            "district": "30",
            "Block": "174",
            "label": "GHSS,SUTHAMALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19760",
            "district": "30",
            "Block": "174",
            "label": "GHS,NARASINGANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19761",
            "district": "30",
            "Block": "204",
            "label": "CMS MS MARUTHAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19762",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS MARUTHAKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19763",
            "district": "30",
            "Block": "204",
            "label": "CMS MS ALWANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19764",
            "district": "30",
            "Block": "204",
            "label": "CMS  MS PARUTHIPADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19765",
            "district": "30",
            "Block": "204",
            "label": "GOVT HS KARANTHANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19766",
            "district": "30",
            "Block": "204",
            "label": "ST.JOSEPH'S MS THAMBUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19767",
            "district": "30",
            "Block": "204",
            "label": "GGHSS NANGUNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19768",
            "district": "30",
            "Block": "204",
            "label": "GOVT (B) HSS NANGUNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19769",
            "district": "30",
            "Block": "204",
            "label": "SRI RAMANUJA MAT HS NANGUNERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19770",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS MOOLAIKARAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19771",
            "district": "30",
            "Block": "204",
            "label": "St. ALOYSIOUS MS CHINTHAMANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19772",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS MUNANJIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19773",
            "district": "30",
            "Block": "204",
            "label": "ST. ANTONY'S HIGH SCHOOL. SOMANATHAPERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19774",
            "district": "30",
            "Block": "204",
            "label": "TDTA MS KALUVOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19775",
            "district": "30",
            "Block": "204",
            "label": "ST.ANTONY'S MS KARANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19776",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS THALAPATHYSAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19777",
            "district": "30",
            "Block": "204",
            "label": "GOVT HIGH SCHOOL THIRUVENGADANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19778",
            "district": "30",
            "Block": "204",
            "label": "TDTA MS PARAPPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19779",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS PARAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19780",
            "district": "30",
            "Block": "204",
            "label": "ST JOHN'S MATRICULATION SCHOOL, PARAPPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19781",
            "district": "30",
            "Block": "204",
            "label": "RC MS THINAIYOORANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19782",
            "district": "30",
            "Block": "204",
            "label": "GOVT HS KARIYANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19783",
            "district": "30",
            "Block": "204",
            "label": "MG RURAL HS VENKATRAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19784",
            "district": "30",
            "Block": "204",
            "label": "NADAR MS PATTARAIKATTIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19785",
            "district": "30",
            "Block": "204",
            "label": "ST. MARY'S  HSS MANNARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19786",
            "district": "30",
            "Block": "204",
            "label": "KENDRA VIDYALAIYA HSS, KENDRA VIDYALAIYA HSS, S.V.NARAYANAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19787",
            "district": "30",
            "Block": "204",
            "label": "GHS, NORTH VIJAYANARAYANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19788",
            "district": "30",
            "Block": "204",
            "label": "RC MS AZHAGAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19789",
            "district": "30",
            "Block": "204",
            "label": "SENTHIL ANDAVAN ARULNERI HIGH SCHOOL VIJAYA ACHAMPADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19790",
            "district": "30",
            "Block": "204",
            "label": "A.V JOSEPH GHSS, ITTAMOZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19791",
            "district": "30",
            "Block": "221",
            "label": "GHS,SEEVALAPERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19792",
            "district": "30",
            "Block": "221",
            "label": "R.C.MS, THONITHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19793",
            "district": "30",
            "Block": "221",
            "label": "HARIHAR MIDDLE SCHOOL , MELAPATTAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19794",
            "district": "30",
            "Block": "221",
            "label": "TDTAMS MANARKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19795",
            "district": "30",
            "Block": "221",
            "label": "PUMS, NADUVAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19796",
            "district": "30",
            "Block": "221",
            "label": "GHSS, BURKITMANAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19797",
            "district": "30",
            "Block": "221",
            "label": "SRI CHELLIAH MS MELAPUTHANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19798",
            "district": "30",
            "Block": "221",
            "label": "TDTA MIDDLE SCHOOL ,MELAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19799",
            "district": "30",
            "Block": "221",
            "label": "SRI SARADA VIDYALAYA HIGH SCHOOL, ARIYAKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19800",
            "district": "30",
            "Block": "221",
            "label": "SRI SARADA MATRIC HIGHER SECONDARY SCHOOL, ARIYAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19801",
            "district": "30",
            "Block": "221",
            "label": "GHS, KUTHUKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19802",
            "district": "30",
            "Block": "221",
            "label": "OASIS MHSS, TUTICORIN ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19803",
            "district": "30",
            "Block": "221",
            "label": "ST.JOSEPH'S HS, V M AROCKIANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19804",
            "district": "30",
            "Block": "221",
            "label": "ST.ANTONY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19805",
            "district": "30",
            "Block": "221",
            "label": "KRGHSS, REDDIARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19806",
            "district": "30",
            "Block": "221",
            "label": "T.D.T.A. JAYARAJ ANNAPACKIAM MATRIC HR. SEC. SCHOOL, TUCKKARAMMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19807",
            "district": "30",
            "Block": "221",
            "label": "TDTA MS,ARAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19808",
            "district": "30",
            "Block": "221",
            "label": "GHSS, MUNNEERPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19809",
            "district": "30",
            "Block": "221",
            "label": "GHS, THARUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19810",
            "district": "30",
            "Block": "221",
            "label": "PUMS, SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19811",
            "district": "30",
            "Block": "221",
            "label": "TDTA MS, OMANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19812",
            "district": "30",
            "Block": "221",
            "label": "TDTA MS, KONGANTHANPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19813",
            "district": "30",
            "Block": "221",
            "label": "TDTA MS, SIVANTHIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19814",
            "district": "30",
            "Block": "221",
            "label": "GOVT ADW MS, PUTHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19815",
            "district": "30",
            "Block": "221",
            "label": "ST.JOSEPH HS, NARANAMMALPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19816",
            "district": "30",
            "Block": "221",
            "label": "PUMS, URUDAYARKUDIYERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19817",
            "district": "30",
            "Block": "221",
            "label": "THIRU TKM KAILASAMPILLAI MS, THATCHANNALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19818",
            "district": "30",
            "Block": "221",
            "label": "BUNGALOW MS, THATCHANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19819",
            "district": "30",
            "Block": "221",
            "label": "VEDIC VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19820",
            "district": "30",
            "Block": "221",
            "label": "ADI DRAVIDA HS, RAMAIANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19821",
            "district": "30",
            "Block": "221",
            "label": "GHS, KAMMALANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19822",
            "district": "30",
            "Block": "221",
            "label": "PONNUSAMY MS, RAJAVALLIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19823",
            "district": "30",
            "Block": "221",
            "label": "SRI AYYACHAMY MS, KARAIYERUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19824",
            "district": "30",
            "Block": "221",
            "label": "GHS, CHATHIRAMKUDIYERUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19825",
            "district": "30",
            "Block": "221",
            "label": "KENBRIDGE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19826",
            "district": "30",
            "Block": "221",
            "label": "Venkadasamy Naidu Hr. Sec. School, Sankarnagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19827",
            "district": "30",
            "Block": "221",
            "label": "SANKAR HR SEC SCHOOL, SANKARNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19828",
            "district": "30",
            "Block": "221",
            "label": "GITA KRISHNMORTHY VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19829",
            "district": "30",
            "Block": "221",
            "label": "ST.ANNES  PHYSICALY HANDICAPPED MIDDLE SCHOOL, ANAIARKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19830",
            "district": "30",
            "Block": "221",
            "label": "PUNCHAYAT MIDDLE SCHOOL, PIRANTHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19831",
            "district": "30",
            "Block": "229",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,POTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19832",
            "district": "30",
            "Block": "229",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, POTHUKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19837",
            "district": "30",
            "Block": "229",
            "label": "PUMS,KUMARASAMIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19838",
            "district": "30",
            "Block": "229",
            "label": "HINDU MS ODAIKARAI THULUKKAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19839",
            "district": "30",
            "Block": "229",
            "label": "HINDU MIDDLE SCHOOL,PAPPAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19840",
            "district": "30",
            "Block": "229",
            "label": "SOKKALAL HSS MUKKUDAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19841",
            "district": "30",
            "Block": "229",
            "label": "ST PAUL HSS SINGAMPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19842",
            "district": "30",
            "Block": "229",
            "label": "GHSS,VADAKKU ARIYANAYAGIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19843",
            "district": "30",
            "Block": "229",
            "label": "HINDU MS HARIKESAVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19844",
            "district": "30",
            "Block": "229",
            "label": "GAJENDRA VARATHAR MS ATHALANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19845",
            "district": "30",
            "Block": "262",
            "label": "ST.ALOYSIUS MIDDLE SCHOOL T.KALLIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19846",
            "district": "30",
            "Block": "262",
            "label": "ST. ALOYSIUS HSS T.KALLIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19847",
            "district": "30",
            "Block": "262",
            "label": "ST.PETER'S MS RAYAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19848",
            "district": "30",
            "Block": "262",
            "label": "SEEMATTI AMMAL MUTHAMMAL REDDIYAR GOVT HR.SECONDARY SCHOOL SAMUGARENGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19849",
            "district": "30",
            "Block": "262",
            "label": "ST.JOSEPH HS PUTHUKUDIYIRUPPU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19850",
            "district": "30",
            "Block": "262",
            "label": "TDTA MS RADHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19851",
            "district": "30",
            "Block": "262",
            "label": "RC PERIYA NAYAGI MIDDLE SCHOOL RADHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19852",
            "district": "30",
            "Block": "262",
            "label": "NVC GOVT HR.SECONDARY SCHOOL RADHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19853",
            "district": "30",
            "Block": "262",
            "label": "ST ANDREWS MATRICULATION HIGHER SECONDARY SCHOOL RADHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19854",
            "district": "30",
            "Block": "262",
            "label": "RAMALINGAM KUMARASWAMY RAMALINGAM MIDDLE SCHOOL,PARAMESHWARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19855",
            "district": "30",
            "Block": "262",
            "label": "ANANDHA MIDDLE SCHOOL UDHAYATHOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19856",
            "district": "30",
            "Block": "262",
            "label": "GHS ATHUKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19857",
            "district": "30",
            "Block": "262",
            "label": "HINDU  MIDDLE SCHOOL  KOODANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19858",
            "district": "30",
            "Block": "262",
            "label": "ST.ANNE'S HIGHER SECOONDARY SCHOOL KOODANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19859",
            "district": "30",
            "Block": "262",
            "label": "MALAVIYA VIDYA KENDRAM KOODANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19860",
            "district": "30",
            "Block": "262",
            "label": "TDTA HS KOODANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19861",
            "district": "30",
            "Block": "262",
            "label": "GHSS KOODANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19862",
            "district": "30",
            "Block": "262",
            "label": "PUMS VIJAYAPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19863",
            "district": "30",
            "Block": "262",
            "label": "BISHOP ROCHE  HSS IDINDAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23364",
            "district": "30",
            "Block": "222",
            "label": "Good Shepherd school",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "19864",
            "district": "30",
            "Block": "262",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SANGANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19865",
            "district": "30",
            "Block": "262",
            "label": "BHARATH MIDDLE SCHOOL VAIRAVIKINARU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19866",
            "district": "30",
            "Block": "262",
            "label": "MUSLIM MS THIRUVAMBALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19867",
            "district": "30",
            "Block": "262",
            "label": "HINDU YADAVA MIDDLE SCHOOL TIRUVAMBALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19868",
            "district": "30",
            "Block": "262",
            "label": "ST.KITHERI HIGH SCHOOL  KOOTHENKUZHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19869",
            "district": "30",
            "Block": "262",
            "label": "PUBLIC SEVAA SANGAM MIDDLE SCHOOL  MUDAVANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19870",
            "district": "30",
            "Block": "262",
            "label": "GHS KASTHURIRENGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19871",
            "district": "30",
            "Block": "262",
            "label": "JOHNY MIDDLE SCHOOL  KUMBIKULAM NORTH",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19872",
            "district": "30",
            "Block": "262",
            "label": "GHS SEELATHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19873",
            "district": "30",
            "Block": "262",
            "label": "RC MS MITTATHARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19874",
            "district": "30",
            "Block": "262",
            "label": "SSMM MS KOTTAIKARUNKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19875",
            "district": "30",
            "Block": "262",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUMARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19876",
            "district": "30",
            "Block": "262",
            "label": "TDTA MIDDLE SCHOOL IDAIYANKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19877",
            "district": "30",
            "Block": "262",
            "label": "TDTA MS KEERAIKARANTHATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19878",
            "district": "30",
            "Block": "262",
            "label": "TDTA MS  SUVISESHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19879",
            "district": "30",
            "Block": "262",
            "label": "STELLA MARIS GIRLS HIGH SCHOOL, TISAIYANVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19880",
            "district": "30",
            "Block": "262",
            "label": "SAMARIAH ST. JOHN'S HR. SEC. SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19881",
            "district": "30",
            "Block": "262",
            "label": "HOLY REDEEMERS HSS TISAYANVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19882",
            "district": "30",
            "Block": "262",
            "label": "SRI RAMAKRISHNA HSS TISAYANVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19883",
            "district": "30",
            "Block": "262",
            "label": "CALDWELL CENTINARY MEMORIAL HSS EDAYANKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19884",
            "district": "30",
            "Block": "262",
            "label": "JAYARRAJESH MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19885",
            "district": "30",
            "Block": "262",
            "label": "GHS SHANMUGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19886",
            "district": "30",
            "Block": "262",
            "label": "TDTA MS MADATHACHAMPADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19887",
            "district": "30",
            "Block": "262",
            "label": "MALAVIYA VIDYA KENDRAM APPUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19888",
            "district": "30",
            "Block": "262",
            "label": "LIONS MATRICULATION HIGHER SECONDARY SCHOOL APPUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19889",
            "district": "30",
            "Block": "262",
            "label": "RC MS ANAIKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19890",
            "district": "30",
            "Block": "262",
            "label": "S M CADER MEERA SAIBO HSS PETTAIKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19891",
            "district": "30",
            "Block": "262",
            "label": "TDTA MIDDLE SCHOOL KARAICHUTHUPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19892",
            "district": "30",
            "Block": "262",
            "label": "TDTA MIDDLE SCHOOL NAVALADY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19893",
            "district": "30",
            "Block": "262",
            "label": "ST XAVIER'S MS AVUDAIYALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19894",
            "district": "30",
            "Block": "262",
            "label": "DAKSHINA  MARA NADAR SANGAM SIVANTHI ADITHANAR HIGHER SECONDARY SCHOOL,NAVALADY NAVALADY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19895",
            "district": "30",
            "Block": "262",
            "label": "PUMS KUNJANVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19896",
            "district": "30",
            "Block": "262",
            "label": "ST JOSEPH MS UVARI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19897",
            "district": "30",
            "Block": "262",
            "label": "SRI SUYAMBULINGASAMY MIDDLE SCHOOL  UVARI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19898",
            "district": "30",
            "Block": "262",
            "label": "ST ANTONY'S MIDDLE SCHOOL  KOOTAPPANAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19899",
            "district": "30",
            "Block": "262",
            "label": "GHSS KUTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19900",
            "district": "30",
            "Block": "262",
            "label": "ST MARY'S HSS UVARI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19901",
            "district": "30",
            "Block": "262",
            "label": "MALAVIYA VIDYA KENDRAM UVARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19943",
            "district": "30",
            "Block": "385",
            "label": "ARULAIAH  MIDDLE SCHOOL KOTTAYADI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19944",
            "district": "30",
            "Block": "385",
            "label": "SA NOBLE MEMORIAL HS, VALLIYOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19945",
            "district": "30",
            "Block": "385",
            "label": "GOVT.HR.SEC.SCHOOL VALLIYOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19946",
            "district": "30",
            "Block": "385",
            "label": "MERCY MATRIC. HIGHER SECONDARY SCHOOL, VALLIYOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19947",
            "district": "30",
            "Block": "385",
            "label": "Fatima Girls Higher Secondary School",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19948",
            "district": "30",
            "Block": "385",
            "label": "CONCORDIA HSS VALLIOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19949",
            "district": "30",
            "Block": "385",
            "label": "HUSSEY MS PANAGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19950",
            "district": "30",
            "Block": "385",
            "label": "GOVERNMENT H S S  PANAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19951",
            "district": "30",
            "Block": "385",
            "label": "SACRED HEART HIGHER SECONDARY SCHOOL PANAGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19952",
            "district": "30",
            "Block": "385",
            "label": "NIRMALA RC MIDDLE SCHOOL THERKU VALLIYOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19953",
            "district": "30",
            "Block": "385",
            "label": "GOVERNMENT HIGH SCHOOL ROSEMIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19954",
            "district": "30",
            "Block": "385",
            "label": "HOLYFAMILY MIDDLE SCHOOL VADAKANGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19955",
            "district": "30",
            "Block": "385",
            "label": "GHS KAVALKINARU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19956",
            "district": "30",
            "Block": "385",
            "label": "ADH HS PERIYANAYAKI PURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19957",
            "district": "30",
            "Block": "385",
            "label": "SACRED HEART HSS KAVALKINARU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19958",
            "district": "30",
            "Block": "385",
            "label": "ST THRESA HSS VADAKANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19959",
            "district": "30",
            "Block": "385",
            "label": "CONCORDIA HSS VADAKANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19960",
            "district": "30",
            "Block": "385",
            "label": "NEHRU NATIONAL HSS VADAKANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19961",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS ANNAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19962",
            "district": "30",
            "Block": "385",
            "label": "GHSS THULUKKARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19963",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS KANNANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19964",
            "district": "30",
            "Block": "385",
            "label": "TDTA MIDDLE SCHOOL ZIONMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19965",
            "district": "30",
            "Block": "385",
            "label": "SRI MUTHARAMMAN MIDDLE SCHOOL SIVASUBRAMANIYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19966",
            "district": "30",
            "Block": "385",
            "label": "RC MS PATHINATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19967",
            "district": "30",
            "Block": "385",
            "label": "GHS KOLIANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19968",
            "district": "30",
            "Block": "385",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SOUNDARALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19969",
            "district": "30",
            "Block": "385",
            "label": "HINDU HS OORALVAIMOZHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19970",
            "district": "30",
            "Block": "385",
            "label": "HINDU ARULNERI MS CHITHAMPARAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19971",
            "district": "30",
            "Block": "385",
            "label": "GHSS PAZHAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19972",
            "district": "30",
            "Block": "385",
            "label": "ALAMERLU VIDYASHARMAM MATRI. HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19973",
            "district": "30",
            "Block": "385",
            "label": "BALIAH MARTHANDAM HR. SEC. SCHOOL., AVARAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19974",
            "district": "30",
            "Block": "385",
            "label": "GHS MATHAGANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19975",
            "district": "30",
            "Block": "385",
            "label": "TDTA HS JACOBPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19976",
            "district": "30",
            "Block": "385",
            "label": "HINDU MIDDLE SCHOOL SANGANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19977",
            "district": "30",
            "Block": "385",
            "label": "G HIGH SCHOOL THERKKU KARUNGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19978",
            "district": "30",
            "Block": "385",
            "label": "LM MIDDLE SCHOOL KANNANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19979",
            "district": "30",
            "Block": "385",
            "label": "LM MIDDLE SCHOOL LEVANGIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19980",
            "district": "30",
            "Block": "385",
            "label": "GOVT HIGH SCHOOL MADAMPILLAITHARMAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19981",
            "district": "30",
            "Block": "385",
            "label": "ST JOSEPH HSS KOOTTAPULI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19982",
            "district": "30",
            "Block": "385",
            "label": "ST ANNES HSS RAJAKRISHNAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19983",
            "district": "30",
            "Block": "385",
            "label": "HINDU HSS KANNANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19984",
            "district": "30",
            "Block": "385",
            "label": "GHSS CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19985",
            "district": "30",
            "Block": "385",
            "label": "ATOMIC ENERGY HSS ANUVIJAYANAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19986",
            "district": "30",
            "Block": "385",
            "label": "KUTTALAM MEMORIAL MS IRUKKANTH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20024",
            "district": "30",
            "Block": "222",
            "label": "MANORANJITHAM MS,KOKKIRAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20025",
            "district": "30",
            "Block": "222",
            "label": "BISHOP SARGENT MS,PALAYAMKOTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20026",
            "district": "30",
            "Block": "222",
            "label": "CATHEDRAL  HSS, PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20027",
            "district": "30",
            "Block": "222",
            "label": "VIVEKANANDA VIDHYASHRAM MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20028",
            "district": "30",
            "Block": "222",
            "label": "BISHOP SARGENT MENTALLY RETARDED",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20029",
            "district": "30",
            "Block": "222",
            "label": "ADITHYA VIDHYA NIKETAN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20030",
            "district": "30",
            "Block": "222",
            "label": "PUSHPALATA VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20031",
            "district": "30",
            "Block": "222",
            "label": "HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20032",
            "district": "30",
            "Block": "222",
            "label": "SRI KANTHIMATHI  AMBAL GIRLS HSS HIGHGROUND",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20033",
            "district": "30",
            "Block": "222",
            "label": "M.N.ABDUR RAHMAN HSS TIRUNELVELI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20034",
            "district": "30",
            "Block": "222",
            "label": "ST.JOHNS HSS PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20035",
            "district": "30",
            "Block": "222",
            "label": "FATIMA ORIENTAL ARABIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20036",
            "district": "30",
            "Block": "222",
            "label": "USBORNE MS PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20037",
            "district": "30",
            "Block": "222",
            "label": "SERVITE MATRIC HIGHER SECONDARY SCHOOL, PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20038",
            "district": "30",
            "Block": "222",
            "label": "CHILD JESUS GIRLS HSS, PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20039",
            "district": "30",
            "Block": "222",
            "label": "ST. IGNATIUS CONVENT HSS, PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20040",
            "district": "30",
            "Block": "222",
            "label": "ST.XAVIERS HSS PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20041",
            "district": "30",
            "Block": "222",
            "label": "KATHIR VIDYALAYA N&PS  PERUMAL PURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20042",
            "district": "30",
            "Block": "222",
            "label": "ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL,JAWAHAR NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20043",
            "district": "30",
            "Block": "222",
            "label": "GOVT.OBSERVATION HOME",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20044",
            "district": "30",
            "Block": "222",
            "label": "FLORENCE SWAISON HSS(D&F),PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20045",
            "district": "30",
            "Block": "222",
            "label": "RAHMANIA HSS MELAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20046",
            "district": "30",
            "Block": "222",
            "label": "ST.THOMAS HS KURICHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20047",
            "district": "30",
            "Block": "222",
            "label": "THE MUSLIM HSS MELAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20048",
            "district": "30",
            "Block": "222",
            "label": "Punitha Suvakkin Matric School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20049",
            "district": "30",
            "Block": "222",
            "label": "QUAIDE MILLETH CORPORATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20050",
            "district": "30",
            "Block": "222",
            "label": "MUSLIM GIRLS HSS MELAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20051",
            "district": "30",
            "Block": "361",
            "label": "GOVERNMENT HR SEC SCHOOL,TIRUNELVELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20052",
            "district": "30",
            "Block": "361",
            "label": "SCHAFFTER HIGHER SECONDARY SCHOOL, TIRUNELVELI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20053",
            "district": "30",
            "Block": "361",
            "label": "THE MDT HINDU COLLEGE HR.SEC SCHOOL,TIRUNELVELI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20054",
            "district": "30",
            "Block": "361",
            "label": "Mpl. (G) HSS,  Meenakshipuram, Tirunelveli Junction",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20055",
            "district": "30",
            "Block": "361",
            "label": "Bharathiar Mpl. HS, Tirunelveli",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20056",
            "district": "30",
            "Block": "361",
            "label": "STC Branch Upper Clapton Middle School,TVL Town",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20057",
            "district": "30",
            "Block": "361",
            "label": "Jawahar Govt. HS, Tirunelveli",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20058",
            "district": "30",
            "Block": "361",
            "label": "Mpl. (G) HSS, Kallanai, TVL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20059",
            "district": "30",
            "Block": "361",
            "label": "Sri Manthiramoorthy HSS, Tirunelveli",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20060",
            "district": "30",
            "Block": "361",
            "label": "Meenakshi Matric. Hr. Sec School, Tvl",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20061",
            "district": "30",
            "Block": "361",
            "label": "LITTLE FLOWER MATRIC HR. SEC. SCHOOL TIRUNELVELI TOWN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20062",
            "district": "30",
            "Block": "361",
            "label": "Gaffoor Middle School, Pettai",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20063",
            "district": "30",
            "Block": "361",
            "label": "Mpl. (G) HSS, Pettai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20064",
            "district": "30",
            "Block": "361",
            "label": "Kamaraj Mpl. HSS, Pettai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20065",
            "district": "30",
            "Block": "361",
            "label": "St. Antony's HS, Pettai",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20066",
            "district": "30",
            "Block": "361",
            "label": "Ooliyasthanam MS, Palayapettai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20067",
            "district": "30",
            "Block": "361",
            "label": "Hindu Middle School, Matha Middle. Street., TVL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20068",
            "district": "30",
            "Block": "361",
            "label": "TDTA MS, Arasaradi Palam, TVL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20920",
            "district": "30",
            "Block": "92",
            "label": "ST JOSEPH MATRICULATION HIGHER SECONDARY SCHOOL KADAMBODUVALVU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20999",
            "district": "30",
            "Block": "222",
            "label": "PUSHPALATHA BRITISH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21040",
            "district": "30",
            "Block": "385",
            "label": "Sri Vidya Mandir Matriculation Girls High School, Vallioor",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21041",
            "district": "30",
            "Block": "385",
            "label": "BALIAH MARTHANDAM MATRICULATION HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21118",
            "district": "30",
            "Block": "221",
            "label": "ROSE MARY PUBLIC SCHOOL VM CHATHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21121",
            "district": "30",
            "Block": "385",
            "label": "S.A.V BALAKRISHNA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21122",
            "district": "30",
            "Block": "385",
            "label": "KINGS  CBSE SCHOOL PUDHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21123",
            "district": "30",
            "Block": "385",
            "label": "JESUS SCHOOL FOR EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21224",
            "district": "30",
            "Block": "204",
            "label": "ADW GHSS KADAMBANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21231",
            "district": "30",
            "Block": "204",
            "label": "REACH MHSS MOOLAIKARAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21233",
            "district": "30",
            "Block": "222",
            "label": "ST ANNE S REHABILITATION CENTRE FOR THE MENTALLY HANDICAPPED",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21289",
            "district": "30",
            "Block": "46",
            "label": "St.JOHN'S MATRICULATION HR.SEC.SCHOOL VEERAVANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21344",
            "district": "30",
            "Block": "222",
            "label": "FRANCIS XAVIER SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21414",
            "district": "30",
            "Block": "174",
            "label": "G(G) HS GANGAIKONDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21415",
            "district": "30",
            "Block": "174",
            "label": "G(G)HS  MANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21416",
            "district": "30",
            "Block": "229",
            "label": "G(G) HS NADUKKALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21542",
            "district": "30",
            "Block": "92",
            "label": "HINDU HS PANDITHANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21619",
            "district": "30",
            "Block": "92",
            "label": "JANAKI KRISHNA VIDYA MANDIR N & PS PADMANERI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21620",
            "district": "30",
            "Block": "204",
            "label": "ST.  ANN'S  MATRICULATION SCHOOL, ELANGULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21621",
            "district": "30",
            "Block": "229",
            "label": "ST FRANCIS DE PAUL PRIMARY ENGLISH MEDIUM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21639",
            "district": "30",
            "Block": "46",
            "label": "SUCCESS CONVENT MATRICULATION SCHOOL, MELASEVAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21823",
            "district": "30",
            "Block": "361",
            "label": "ROSE MARY IDEAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21824",
            "district": "30",
            "Block": "222",
            "label": "AL-MADINAH PUBLIC SCHOOL(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21827",
            "district": "30",
            "Block": "385",
            "label": "KEINS CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21828",
            "district": "30",
            "Block": "385",
            "label": "RMS INTERNATIONAL SCHOOL THANDIARKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21830",
            "district": "30",
            "Block": "262",
            "label": "VSR INTERNATIONAL SCHOOL, TISAYANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21831",
            "district": "30",
            "Block": "262",
            "label": "POTHIHAI PUBLIC MATRIC HIGHER SECONDARY SCHOOL, IDAYANKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21832",
            "district": "30",
            "Block": "262",
            "label": "SRI GOLD STAR MATRIC HIGHER SECONDARY SCHOOL,TISAIYANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21833",
            "district": "30",
            "Block": "221",
            "label": "HOLY ANGELS BCV INTERNATIONAL SCHOOL, CAUSSANELPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21834",
            "district": "30",
            "Block": "221",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL,MUNNERPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21923",
            "district": "30",
            "Block": "174",
            "label": "SRI ARAVIND VIDYALAYA N&PS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21924",
            "district": "30",
            "Block": "174",
            "label": "LITTLE FLOWER PUBLIC SCHOOL GANDHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22273",
            "district": "30",
            "Block": "9",
            "label": "VELS VIDHYALAYA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22274",
            "district": "30",
            "Block": "9",
            "label": "ST ASSISI PUBLIC SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22275",
            "district": "30",
            "Block": "9",
            "label": "AROMA NIKETAN CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22276",
            "district": "30",
            "Block": "46",
            "label": "CARMEL GARDEN MATRICULATION SCHOOL ODAIKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22277",
            "district": "30",
            "Block": "46",
            "label": "THE HOPE MATRICULATION HR.SEC.SCHOOL POOTHATHANKUDIERUPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22278",
            "district": "30",
            "Block": "174",
            "label": "ST JOHN'S MATRICULATION SCHOOL,GANGAIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22279",
            "district": "30",
            "Block": "174",
            "label": "HILLEYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22280",
            "district": "30",
            "Block": "221",
            "label": "RADASAMY HS MELATHIRUVENKATANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22281",
            "district": "30",
            "Block": "229",
            "label": "RUKMANI NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22283",
            "district": "30",
            "Block": "385",
            "label": "JONATHAN MEMORIAL MATRICULATION SCHOOL KAMARAJ NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22647",
            "district": "30",
            "Block": "174",
            "label": "GHS RASTHA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22650",
            "district": "30",
            "Block": "221",
            "label": "SRI JAYENDRA SWAMIGAL VIDHYA KENDRA SCHOOL VM CHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22651",
            "district": "30",
            "Block": "221",
            "label": "MUTHAMIL PUBLIC SCHOOL, MUNNERPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22652",
            "district": "30",
            "Block": "229",
            "label": "STACG HITECH SCHOOL CBSE IDAIKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22653",
            "district": "30",
            "Block": "262",
            "label": "OUR LADY OF SNOWS MATRIC HR. SEC. SCHOOL T.KALLIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22657",
            "district": "30",
            "Block": "385",
            "label": "SACRED HEART PUBLIC SCHOOL PANAGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22658",
            "district": "30",
            "Block": "385",
            "label": "SARASWATHY VIDYALAYA CBSE THERKKU VALLIYOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22661",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY MODEL PUBLIC SCHOOL RAMACHANDRA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22728",
            "district": "30",
            "Block": "174",
            "label": "NAMMA OORU GLOBAL SCHOOLS, GANGAIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22730",
            "district": "30",
            "Block": "204",
            "label": "NELLAI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22734",
            "district": "30",
            "Block": "385",
            "label": "VEDIC VIDYASHRAM CBSE SCHOOL THERKKU VALLIYOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22905",
            "district": "30",
            "Block": "174",
            "label": "GHS ETTANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22906",
            "district": "30",
            "Block": "174",
            "label": "GHS KOOVACHIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23074",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23075",
            "district": "30",
            "Block": "361",
            "label": "MAHATHMA GANDHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23111",
            "district": "30",
            "Block": "174",
            "label": "ST JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23112",
            "district": "30",
            "Block": "361",
            "label": "SOCRATEES MODEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23114",
            "district": "30",
            "Block": "262",
            "label": "HARVARD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23145",
            "district": "30",
            "Block": "385",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Valliyoor",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23156",
            "district": "30",
            "Block": "46",
            "label": "SCAD INTERNATIONAL SCHOOL, CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23161",
            "district": "30",
            "Block": "221",
            "label": "MEENA SANKAR VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23162",
            "district": "30",
            "Block": "385",
            "label": "INDIAN MATRICULATON SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23166",
            "district": "30",
            "Block": "92",
            "label": "MVM CAMBRIDGE VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23173",
            "district": "30",
            "Block": "222",
            "label": "ZAREENA FATHIMA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23189",
            "district": "30",
            "Block": "221",
            "label": "VELS VIDHYALAYA CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23259",
            "district": "30",
            "Block": "204",
            "label": "GHS SHENBAGARAMANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23260",
            "district": "30",
            "Block": "262",
            "label": "ARPUTHAM SAMUEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23280",
            "district": "30",
            "Block": "221",
            "label": "KAMARAJAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23285",
            "district": "30",
            "Block": "221",
            "label": "IIP LAKSHMI RAMAN GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23586",
            "district": "30",
            "Block": "9",
            "label": "AL AMEEN NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23587",
            "district": "30",
            "Block": "92",
            "label": "NELLAI ERUVADI MODEL  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23588",
            "district": "30",
            "Block": "221",
            "label": "Government Model School, Tirunelveli",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23589",
            "district": "30",
            "Block": "222",
            "label": "TIRUNELVELI DISTRICT GOVERNMENT MODEL  SCHOOL ,TIRUNELVELI ",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23900",
            "district": "30",
            "Block": "221",
            "label": "KAMARAJAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23901",
            "district": "30",
            "Block": "221",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23902",
            "district": "30",
            "Block": "222",
            "label": "GOVT SERVICE HOME HSS, PALAI",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23903",
            "district": "30",
            "Block": "222",
            "label": "MITRA SCHOOL FOR AUTISM",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24059",
            "district": "30",
            "Block": "385",
            "label": "GOVERNMENT HR SEC SCHOOL CHETTIKULAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24060",
            "district": "30",
            "Block": "385",
            "label": "ST ANNS SCHOOL (ICSE)",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "31": [
        {
            "key": "426",
            "district": "31",
            "Block": "155",
            "label": "GHS NAYAKANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        { 
            "key": "24123", 
            "district": "31", 
            "Block": "82", 
            "label": "railway mixed higher secondary school", 
            "Sch_type": "Un-aided", 
            "Category": "Higher Secondary School"
        },
        {
            "key": "1472",
            "district": "31",
            "Block": "362",
            "label": "PUMS THALUGAN VATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1497",
            "district": "31",
            "Block": "103",
            "label": "SRI VIJAY SHANTHI JAIN MATRIC HSS, THIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1548",
            "district": "31",
            "Block": "362",
            "label": "AL-AMEEN MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1551",
            "district": "31",
            "Block": "208",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL, HINDUPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1618",
            "district": "31",
            "Block": "362",
            "label": "PUMS,  PAZHAYA ATHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1666",
            "district": "31",
            "Block": "362",
            "label": "PUMS KATHIRIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1681",
            "district": "31",
            "Block": "362",
            "label": "PUMS PICHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1805",
            "district": "31",
            "Block": "362",
            "label": "PUMS JOLLAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1834",
            "district": "31",
            "Block": "362",
            "label": "MUNICIPAL OLD MUSLIM GIRLS' MIDDLE SCHOOL, MOSQUE STREET,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1885",
            "district": "31",
            "Block": "155",
            "label": "GRACIOUS MATRIC SCHOOL, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1897",
            "district": "31",
            "Block": "5",
            "label": "PUMS VILLANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1952",
            "district": "31",
            "Block": "362",
            "label": "PUMS JAMMANNAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1987",
            "district": "31",
            "Block": "362",
            "label": "PUMS MAKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2038",
            "district": "31",
            "Block": "362",
            "label": "PUMS,AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2067",
            "district": "31",
            "Block": "362",
            "label": "MUNICIPAL OLD MUSLIM BOYS' MIDDLE SCHOOL, MOSQUE STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2167",
            "district": "31",
            "Block": "103",
            "label": "PUMS, THATHANKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2193",
            "district": "31",
            "Block": "362",
            "label": "CSI GIRLS MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2196",
            "district": "31",
            "Block": "208",
            "label": "ARSHIYA FATHIMA MATRIC SCHOOL RAMANAICKENPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2223",
            "district": "31",
            "Block": "362",
            "label": "PUMS PASALIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2224",
            "district": "31",
            "Block": "155",
            "label": "IELC HR SEC SCHOOL FOR THE DEAF -AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2252",
            "district": "31",
            "Block": "5",
            "label": "SAINT FRANCIS DE SALES MATRIC HSS, ALANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2253",
            "district": "31",
            "Block": "362",
            "label": "PUMS T. KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2268",
            "district": "31",
            "Block": "362",
            "label": "OUR LADY'S MATRICULATION SCHOOL THIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2274",
            "district": "31",
            "Block": "155",
            "label": "PUMS KADHAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2331",
            "district": "31",
            "Block": "155",
            "label": "SRI SARASWATHI VIDYALAYA MATRIC HSS, PALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2361",
            "district": "31",
            "Block": "155",
            "label": "PUMS CHINNAVARIGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2416",
            "district": "31",
            "Block": "155",
            "label": "PUMS C.V.PATTARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2433",
            "district": "31",
            "Block": "362",
            "label": "PUMS KAVARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2448",
            "district": "31",
            "Block": "362",
            "label": "LIONS MATRIC HSS THIRUPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2454",
            "district": "31",
            "Block": "362",
            "label": "PUMS THIRUMAL NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2689",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KALNARSAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2700",
            "district": "31",
            "Block": "5",
            "label": "PUMS, NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2755",
            "district": "31",
            "Block": "103",
            "label": "PUMS, NARIYANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2808",
            "district": "31",
            "Block": "5",
            "label": "PUMS PUDUR MARIMANIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2826",
            "district": "31",
            "Block": "103",
            "label": "PUMS PULIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2858",
            "district": "31",
            "Block": "362",
            "label": "GOODWILL MATRIC HSS TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2904",
            "district": "31",
            "Block": "103",
            "label": "PUMS CHELLARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2944",
            "district": "31",
            "Block": "103",
            "label": "PUMS SIMMANAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2946",
            "district": "31",
            "Block": "155",
            "label": "BLESSO MATRIC SCHOOL, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3002",
            "district": "31",
            "Block": "362",
            "label": "PUMS KALIYAMMAN KOIL NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3035",
            "district": "31",
            "Block": "362",
            "label": "PUMS, VELANNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3047",
            "district": "31",
            "Block": "82",
            "label": "PUMS, MANDALAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3057",
            "district": "31",
            "Block": "155",
            "label": "PUMS ATHIMAKULAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3070",
            "district": "31",
            "Block": "362",
            "label": "PUMS THATHAVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3083",
            "district": "31",
            "Block": "103",
            "label": "PUMS SAMATHUVAPURAMH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3101",
            "district": "31",
            "Block": "362",
            "label": "PUMS LALAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3161",
            "district": "31",
            "Block": "82",
            "label": "PUMS, OOSINATTAN KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3211",
            "district": "31",
            "Block": "5",
            "label": "BHARATH MATRIC HSS, ALANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3216",
            "district": "31",
            "Block": "103",
            "label": "PUMS, GOUNDAPPANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3270",
            "district": "31",
            "Block": "82",
            "label": "ST.PAULS MHSS CHETTIYAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3299",
            "district": "31",
            "Block": "362",
            "label": "SRI RAMAKRISHNA VIDHYALAYA MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3310",
            "district": "31",
            "Block": "103",
            "label": "PUMS THOKKIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3371",
            "district": "31",
            "Block": "82",
            "label": "BOSTON MATRIC HSS, JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3392",
            "district": "31",
            "Block": "103",
            "label": "PUMS, ARAVAMATRAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3452",
            "district": "31",
            "Block": "362",
            "label": "PUMS SEERANGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3545",
            "district": "31",
            "Block": "362",
            "label": "PUMS ANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3548",
            "district": "31",
            "Block": "155",
            "label": "PUMS PERIYAVARIGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3555",
            "district": "31",
            "Block": "103",
            "label": "PUMS RAGUPATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3663",
            "district": "31",
            "Block": "155",
            "label": "PUMS KUTTAKINTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3700",
            "district": "31",
            "Block": "103",
            "label": "PUMS, KUNICHI MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3843",
            "district": "31",
            "Block": "82",
            "label": "ST.CHARLES MHSS THIRUPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3847",
            "district": "31",
            "Block": "362",
            "label": "PUMS NARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3864",
            "district": "31",
            "Block": "208",
            "label": "SSV MATRICULATION HIGHER SECONDARY SCHOOL, NATRAMPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3868",
            "district": "31",
            "Block": "362",
            "label": "PUMS, KAKANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3872",
            "district": "31",
            "Block": "208",
            "label": "MMS NETHAJI NAGAR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3913",
            "district": "31",
            "Block": "362",
            "label": "PUMS, KEELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3983",
            "district": "31",
            "Block": "362",
            "label": "PUMS PUDHU POONGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4003",
            "district": "31",
            "Block": "155",
            "label": "MMS PANNEER SELVAM NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4020",
            "district": "31",
            "Block": "362",
            "label": "SHEPHERDS MATRIC HSS, MADAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4077",
            "district": "31",
            "Block": "5",
            "label": "PUMS KURUMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4132",
            "district": "31",
            "Block": "82",
            "label": "PUMS ODDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4133",
            "district": "31",
            "Block": "362",
            "label": "PUMS, VAZHUTHALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4141",
            "district": "31",
            "Block": "5",
            "label": "PUMS, PALLIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4192",
            "district": "31",
            "Block": "155",
            "label": "B.S MATRIC SCHOOL, PACHAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4259",
            "district": "31",
            "Block": "155",
            "label": "MMS GOVINDHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4264",
            "district": "31",
            "Block": "103",
            "label": "PUMS, SEVATHUR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4276",
            "district": "31",
            "Block": "103",
            "label": "PUMS NAICKANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4342",
            "district": "31",
            "Block": "362",
            "label": "PUMS KOODAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4391",
            "district": "31",
            "Block": "362",
            "label": "PUMS VINAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4407",
            "district": "31",
            "Block": "5",
            "label": "BRITE MATRIC SCHOOL, MADANANCHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4537",
            "district": "31",
            "Block": "362",
            "label": "PUMS IRUNAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4546",
            "district": "31",
            "Block": "82",
            "label": "PUMS, ELAGIRI VILLAGE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4555",
            "district": "31",
            "Block": "103",
            "label": "PUMS MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4569",
            "district": "31",
            "Block": "155",
            "label": "PUMS EACHAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4574",
            "district": "31",
            "Block": "5",
            "label": "PUMS, MATHAKADAPPA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4690",
            "district": "31",
            "Block": "5",
            "label": "PUMS, INDIRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4691",
            "district": "31",
            "Block": "103",
            "label": "PUMS, KANDHILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4730",
            "district": "31",
            "Block": "103",
            "label": "PUMS, PARADASIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4755",
            "district": "31",
            "Block": "155",
            "label": "AS-SHUKOOR MATRIC SCHOOL, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4762",
            "district": "31",
            "Block": "155",
            "label": "MMS BETHELEHEM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4994",
            "district": "31",
            "Block": "103",
            "label": "PUMS MERKATHIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5018",
            "district": "31",
            "Block": "103",
            "label": "PUMS RAVUTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5035",
            "district": "31",
            "Block": "103",
            "label": "ADWMS ADIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5097",
            "district": "31",
            "Block": "103",
            "label": "GADWMS, NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5104",
            "district": "31",
            "Block": "5",
            "label": "PUUBMS, JAFFARABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5189",
            "district": "31",
            "Block": "362",
            "label": "PUMS POORIGAMANIMITTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5191",
            "district": "31",
            "Block": "103",
            "label": "PUMS, PALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5199",
            "district": "31",
            "Block": "103",
            "label": "PUMS KAKANGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5371",
            "district": "31",
            "Block": "208",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5393",
            "district": "31",
            "Block": "103",
            "label": "PUMS CHITHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5515",
            "district": "31",
            "Block": "5",
            "label": "PUMS OMAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5532",
            "district": "31",
            "Block": "82",
            "label": "EBENEZER MAT. HR.SEC.SCHOOL, YELAGIRI HILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5587",
            "district": "31",
            "Block": "103",
            "label": "PUMS (SSA), KODIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5600",
            "district": "31",
            "Block": "362",
            "label": "AMS RCM KOVILUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5625",
            "district": "31",
            "Block": "103",
            "label": "PUMS, NARAVINTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5709",
            "district": "31",
            "Block": "208",
            "label": "AL-HUDA MATRIC HSS, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5826",
            "district": "31",
            "Block": "155",
            "label": "PUMS NARIYAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5895",
            "district": "31",
            "Block": "5",
            "label": "PUMS, OLD VANIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5923",
            "district": "31",
            "Block": "155",
            "label": "PUMS,KATTUKOLLAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5954",
            "district": "31",
            "Block": "155",
            "label": "SEVENTH DAY ADVENTIST MATRIC HSS , SANDRORKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6201",
            "district": "31",
            "Block": "5",
            "label": "PUMS, PATTHAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6234",
            "district": "31",
            "Block": "155",
            "label": "PUMS METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6263",
            "district": "31",
            "Block": "103",
            "label": "PUMS KALARPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6316",
            "district": "31",
            "Block": "82",
            "label": "ST.CHARLES MATRIC HSS, ATHANAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6325",
            "district": "31",
            "Block": "103",
            "label": "HOLY CROSS MATRIC HSS, AATHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6391",
            "district": "31",
            "Block": "82",
            "label": "VAANI MATRIC HSS, CHETTIYAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6407",
            "district": "31",
            "Block": "208",
            "label": "FATHIMA MATRIC HSS, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6435",
            "district": "31",
            "Block": "5",
            "label": "PUMS, VALAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6494",
            "district": "31",
            "Block": "155",
            "label": "PUMS CHINNAPALLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6540",
            "district": "31",
            "Block": "155",
            "label": "PUMS KANDRAYANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6544",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KATTERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6619",
            "district": "31",
            "Block": "362",
            "label": "YMCA BROWN MEMORIAL MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6636",
            "district": "31",
            "Block": "155",
            "label": "T.ABDUL WAHID MATRIC HSS ,AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6663",
            "district": "31",
            "Block": "82",
            "label": "PUMS, JOLARPET (SOUTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6678",
            "district": "31",
            "Block": "208",
            "label": "O.MOHANRAJ MATRIC SCHOOL, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6698",
            "district": "31",
            "Block": "208",
            "label": "AR RAHMAN MATRICULATION SCHOOL, KHADERPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8865",
            "district": "31",
            "Block": "155",
            "label": "SHRI KALAIMAGAL MATRIC SCHOOL , OOMERABAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8881",
            "district": "31",
            "Block": "155",
            "label": "SAINT ANTONYS HSS UDAYENDIRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8882",
            "district": "31",
            "Block": "5",
            "label": "GHS GOLLAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8883",
            "district": "31",
            "Block": "155",
            "label": "GHSS VADACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8884",
            "district": "31",
            "Block": "155",
            "label": "GHS MELSANANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8885",
            "district": "31",
            "Block": "155",
            "label": "PUMS MALAYAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8886",
            "district": "31",
            "Block": "155",
            "label": "GHSS ARANGALDURUGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8887",
            "district": "31",
            "Block": "155",
            "label": "PUMS KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8888",
            "district": "31",
            "Block": "155",
            "label": "AMS VEERANGKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8889",
            "district": "31",
            "Block": "155",
            "label": "GHSS DEVALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8890",
            "district": "31",
            "Block": "155",
            "label": "HINDU HSS KARUMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8891",
            "district": "31",
            "Block": "155",
            "label": "PUMS PARSANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8892",
            "district": "31",
            "Block": "155",
            "label": "ADWMS VENKATASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8893",
            "district": "31",
            "Block": "155",
            "label": "PUMS KAILASAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8894",
            "district": "31",
            "Block": "155",
            "label": "IQRA MATRIC HIGHER SECONDARY  SCHOOL, OOMERABAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8895",
            "district": "31",
            "Block": "155",
            "label": "MUHAMMADIA AIDED HIGH SCHOOL, OOMERABAD",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8896",
            "district": "31",
            "Block": "155",
            "label": "GHS MITTALAMMELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8897",
            "district": "31",
            "Block": "155",
            "label": "PUMS AYTHAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8898",
            "district": "31",
            "Block": "155",
            "label": "ISLAMIAH JAMA - ATH HIGH SCHOOL NARIYAMBUT",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8908",
            "district": "31",
            "Block": "155",
            "label": "ADWMS,SOMALAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8909",
            "district": "31",
            "Block": "155",
            "label": "PUMS,VADAPUDUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8910",
            "district": "31",
            "Block": "155",
            "label": "ACSMHSS VADAPUDUPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8911",
            "district": "31",
            "Block": "155",
            "label": "PUMS,KILMURUNGAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8912",
            "district": "31",
            "Block": "155",
            "label": "PUMS,VENGILI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8913",
            "district": "31",
            "Block": "155",
            "label": "GHSS MADANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8914",
            "district": "31",
            "Block": "155",
            "label": "Tagore National High School",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8920",
            "district": "31",
            "Block": "155",
            "label": "PUMS,THIRUMALAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8921",
            "district": "31",
            "Block": "155",
            "label": "MMS SANDRORKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8922",
            "district": "31",
            "Block": "155",
            "label": "CONCORDIA  AMS AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8923",
            "district": "31",
            "Block": "155",
            "label": "GHS AMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8924",
            "district": "31",
            "Block": "155",
            "label": "CONCORDIA HSS AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8925",
            "district": "31",
            "Block": "155",
            "label": "HINDU G HSS AMBUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8926",
            "district": "31",
            "Block": "155",
            "label": "HINDU HR SEC SCHOOL AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8927",
            "district": "31",
            "Block": "155",
            "label": "MAZHARUL ULOOM HSS,AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8928",
            "district": "31",
            "Block": "155",
            "label": "ANAIKAR ORIENTAL (ARABIC) HR.SEC.SCHOOL, AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8929",
            "district": "31",
            "Block": "155",
            "label": "HASNATH-E-JARIA GIRLS HR. SEC. SCHOOL AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8930",
            "district": "31",
            "Block": "155",
            "label": "HABIBIA ORIENTAL GHSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8931",
            "district": "31",
            "Block": "155",
            "label": "SRI VIVEKANANDA MATRIC HSS, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8932",
            "district": "31",
            "Block": "155",
            "label": "BETHEL MATRIC HSS AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8933",
            "district": "31",
            "Block": "155",
            "label": "SRI VIDYA VIHAR MHSS,AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8934",
            "district": "31",
            "Block": "155",
            "label": "SHUKOOR MATRIC HSS, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8935",
            "district": "31",
            "Block": "155",
            "label": "GHS NACHARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8936",
            "district": "31",
            "Block": "155",
            "label": "GHS PERIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8937",
            "district": "31",
            "Block": "155",
            "label": "AMBUR KURUNJI MATRIC HSS, PERIYANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8938",
            "district": "31",
            "Block": "155",
            "label": "PUMS,SOLUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8939",
            "district": "31",
            "Block": "155",
            "label": "PUMS,ALANKUPPAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8940",
            "district": "31",
            "Block": "155",
            "label": "HINDU AMS VINNAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8941",
            "district": "31",
            "Block": "155",
            "label": "GHS MINNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8942",
            "district": "31",
            "Block": "155",
            "label": "EMBESSO MATRIC HSS , MARAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8943",
            "district": "31",
            "Block": "5",
            "label": "GHS MADANANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8944",
            "district": "31",
            "Block": "82",
            "label": "SAINT FRANCIS DE SALES MATRIC HSS JANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8945",
            "district": "31",
            "Block": "82",
            "label": "SIGARAM MATRIC HSS, CHETTIYAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8946",
            "district": "31",
            "Block": "362",
            "label": "FORESTMS, THAGARAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8947",
            "district": "31",
            "Block": "362",
            "label": "FOREST MS, PERUMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8948",
            "district": "31",
            "Block": "362",
            "label": "GOV.TRIBLRES.SC,ARASAMARATHUKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8949",
            "district": "31",
            "Block": "362",
            "label": "FOREST HSS PUDURNADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8950",
            "district": "31",
            "Block": "362",
            "label": "PUMS, MELPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8951",
            "district": "31",
            "Block": "362",
            "label": "FOREST HSS NELLIVASALNADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8952",
            "district": "31",
            "Block": "362",
            "label": "GHS PULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8953",
            "district": "31",
            "Block": "5",
            "label": "GHSS GIRISAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8954",
            "district": "31",
            "Block": "5",
            "label": "SRI VIVEKANANDA MATRIC SCHOOL , VELLAKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8955",
            "district": "31",
            "Block": "5",
            "label": "GHSS VELLAKUTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8956",
            "district": "31",
            "Block": "5",
            "label": "GHSS VALLIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8957",
            "district": "31",
            "Block": "5",
            "label": "GHS PERIYAKURUMBTHERU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8958",
            "district": "31",
            "Block": "5",
            "label": "GHS KOTHAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8959",
            "district": "31",
            "Block": "5",
            "label": "GHSS NIMMIYAMBATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8960",
            "district": "31",
            "Block": "5",
            "label": "PUUGMS, JAFFARABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8961",
            "district": "31",
            "Block": "5",
            "label": "ADW HR.SEC SCHOOL ALANGAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8962",
            "district": "31",
            "Block": "5",
            "label": "GGHSS ALANGAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8963",
            "district": "31",
            "Block": "5",
            "label": "BRINTHAVAN MATRIC SCHOOL, ALANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8964",
            "district": "31",
            "Block": "5",
            "label": "PUMS, KALLARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8965",
            "district": "31",
            "Block": "5",
            "label": "GHSS MALAIREDDIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8966",
            "district": "31",
            "Block": "208",
            "label": "PUMS ALINJIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8967",
            "district": "31",
            "Block": "5",
            "label": "PUMS CHINDAGAMANIPENDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8968",
            "district": "31",
            "Block": "208",
            "label": "GHSS THUMBERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8969",
            "district": "31",
            "Block": "208",
            "label": "GHS CHICKANANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8970",
            "district": "31",
            "Block": "208",
            "label": "PUMS JAVADHU RAMA SAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8971",
            "district": "31",
            "Block": "208",
            "label": "PUMS, BYYAPPANAYAKENPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8972",
            "district": "31",
            "Block": "208",
            "label": "PUMS MAMUDIMANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8973",
            "district": "31",
            "Block": "208",
            "label": "GHSS PATCHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8974",
            "district": "31",
            "Block": "208",
            "label": "PUMS KATHARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8975",
            "district": "31",
            "Block": "208",
            "label": "GOVT. HSS. DHASIRIYAPPANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8976",
            "district": "31",
            "Block": "208",
            "label": "GHSS RAMANAICKENPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8977",
            "district": "31",
            "Block": "208",
            "label": "PUMS ANNA NAGAR(M)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8978",
            "district": "31",
            "Block": "208",
            "label": "PUMS GURUBHAVANIGUNDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8979",
            "district": "31",
            "Block": "208",
            "label": "PUMS THEKKUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8980",
            "district": "31",
            "Block": "208",
            "label": "PUMS KODAIYANCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8981",
            "district": "31",
            "Block": "208",
            "label": "GHSS AMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8982",
            "district": "31",
            "Block": "208",
            "label": "THIRU-VI- KA MIDDLE SCHOOL THIRU-VI-KA NAGAR MARUTHUVAR COLONY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8983",
            "district": "31",
            "Block": "208",
            "label": "ISLAMIAH BOYS HSS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8984",
            "district": "31",
            "Block": "208",
            "label": "HINDU HSS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8985",
            "district": "31",
            "Block": "208",
            "label": "MMS MUSLIM GIRLS BASHEERABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8986",
            "district": "31",
            "Block": "208",
            "label": "MMS MUSLIM GIRLS FORT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8987",
            "district": "31",
            "Block": "208",
            "label": "MMS MUSLIM GIRLS KHADERPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8988",
            "district": "31",
            "Block": "208",
            "label": "ISLAMIAH GIRLS HSS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8989",
            "district": "31",
            "Block": "208",
            "label": "KHADERIA HSS VANIYAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8990",
            "district": "31",
            "Block": "208",
            "label": "MMS PERUMALPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8991",
            "district": "31",
            "Block": "208",
            "label": "TVKV HS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8992",
            "district": "31",
            "Block": "208",
            "label": "GOVT MPL  MODEL HIGHER SECONDARY SCHOOL  GANDHI NAGAR VANIYAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8993",
            "district": "31",
            "Block": "208",
            "label": "PUMS AVARANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8994",
            "district": "31",
            "Block": "208",
            "label": "PUMS PULLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8995",
            "district": "31",
            "Block": "208",
            "label": "PUMS KONDAKINDANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8996",
            "district": "31",
            "Block": "208",
            "label": "GHS No. 1. KOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8997",
            "district": "31",
            "Block": "208",
            "label": "GHS THIMMAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8998",
            "district": "31",
            "Block": "208",
            "label": "PUMS VELLANAYAKANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8999",
            "district": "31",
            "Block": "208",
            "label": "GHS NAYANACHERUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9000",
            "district": "31",
            "Block": "208",
            "label": "PUMS NARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9001",
            "district": "31",
            "Block": "208",
            "label": "PUMS ELARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9002",
            "district": "31",
            "Block": "208",
            "label": "GOVT HSS JANGALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9003",
            "district": "31",
            "Block": "208",
            "label": "PUMS ADIPERAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9004",
            "district": "31",
            "Block": "208",
            "label": "GOVT. GIRLS HSS NATRAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9005",
            "district": "31",
            "Block": "208",
            "label": "PUMS ARASANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9006",
            "district": "31",
            "Block": "208",
            "label": "GOVT. BOYS. HSS NATRAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9007",
            "district": "31",
            "Block": "208",
            "label": "BRINDAVAN MATRIC HSS, NATRAMPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9008",
            "district": "31",
            "Block": "208",
            "label": "GHS K BANDARAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9009",
            "district": "31",
            "Block": "208",
            "label": "MMS HINDU  PERIYAPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9010",
            "district": "31",
            "Block": "208",
            "label": "NAYA MADRASA  URDU MIDDLE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9011",
            "district": "31",
            "Block": "208",
            "label": "ADARSH MATRIC.HSS, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9012",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KAVERIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9013",
            "district": "31",
            "Block": "82",
            "label": "PUMS, POONAIKUTTAIPALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9014",
            "district": "31",
            "Block": "82",
            "label": "PUMS, CHINNA MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9015",
            "district": "31",
            "Block": "82",
            "label": "PUMS CHINNAGOUNDANUR (P)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9016",
            "district": "31",
            "Block": "82",
            "label": "GOVT HSS PONNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9017",
            "district": "31",
            "Block": "82",
            "label": "GGHSS JOLARPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9018",
            "district": "31",
            "Block": "82",
            "label": "ST.JOSEPH GIRLS HSS JOLARPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9019",
            "district": "31",
            "Block": "82",
            "label": "AHSS DONBOSCO - JOLARPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9020",
            "district": "31",
            "Block": "82",
            "label": "GOVT HSS VAKKANAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9021",
            "district": "31",
            "Block": "82",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  JOLARPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9022",
            "district": "31",
            "Block": "82",
            "label": "SRI RAMAKRISHNA VIDHYALAYA MHSS JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9023",
            "district": "31",
            "Block": "82",
            "label": "INFANT JESUS MATRIC SCHOOL, JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9024",
            "district": "31",
            "Block": "82",
            "label": "UNIVERSAL MATRIC HSS, JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9025",
            "district": "31",
            "Block": "82",
            "label": "GHSS ATHANAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9026",
            "district": "31",
            "Block": "82",
            "label": "ST.CHARLES AHSS ATHANAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9027",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KATTERI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9028",
            "district": "31",
            "Block": "82",
            "label": "Sri Vinayaka Matriculation School,",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9029",
            "district": "31",
            "Block": "82",
            "label": "GHSS  THAMALERIMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9030",
            "district": "31",
            "Block": "82",
            "label": "PUMS, POOSANIKKAIVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9031",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS THIRIYALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9032",
            "district": "31",
            "Block": "82",
            "label": "DEWAN MOHAMED MEMORIAL MATRIC  HR SEC SCHOOL ,  KETHANDAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9033",
            "district": "31",
            "Block": "82",
            "label": "GHS CHINNAKAMMIYAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9034",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS VETTAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9035",
            "district": "31",
            "Block": "82",
            "label": "PUMS, CHINNAVEPPAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9036",
            "district": "31",
            "Block": "82",
            "label": "PUMS CHINNAKALLUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9037",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KUNICHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9038",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KITTAPAIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9039",
            "district": "31",
            "Block": "82",
            "label": "PUMS, VEERAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9040",
            "district": "31",
            "Block": "82",
            "label": "GOVT HSS VELAKKALNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9041",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS CHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9042",
            "district": "31",
            "Block": "82",
            "label": "PUMS, VEPPALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9043",
            "district": "31",
            "Block": "82",
            "label": "GHSS JAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9044",
            "district": "31",
            "Block": "82",
            "label": "PUMS, SUNNAMBU KUTTAI (IVIL)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9045",
            "district": "31",
            "Block": "82",
            "label": "PUMS, MUTHANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9046",
            "district": "31",
            "Block": "82",
            "label": "GHSS MALLAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9047",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9048",
            "district": "31",
            "Block": "82",
            "label": "PUMS, PUDUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9049",
            "district": "31",
            "Block": "82",
            "label": "GOVT BHSS PUDUPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9050",
            "district": "31",
            "Block": "82",
            "label": "GOVT (G)HSS PUDUPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9051",
            "district": "31",
            "Block": "82",
            "label": "PUMS, OM SAKTHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9052",
            "district": "31",
            "Block": "82",
            "label": "GOVT HIGH SCHOOL AGRAHARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9053",
            "district": "31",
            "Block": "82",
            "label": "PUMS, MOOKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9054",
            "district": "31",
            "Block": "82",
            "label": "PUMS  PUDUR MOOKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9055",
            "district": "31",
            "Block": "82",
            "label": "PUMS ADIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9056",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS PARANDAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9057",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9058",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KURUMBER  COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9059",
            "district": "31",
            "Block": "82",
            "label": "DON BOSCO MATRIC HSS, THIRUPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9060",
            "district": "31",
            "Block": "82",
            "label": "ANT MATRIC SCHOOL, THIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9061",
            "district": "31",
            "Block": "82",
            "label": "PUMS, PULLANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9062",
            "district": "31",
            "Block": "362",
            "label": "GHSS PALNANGKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9063",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS ANNADAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9064",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS ACHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9065",
            "district": "31",
            "Block": "362",
            "label": "GOVT GIRLS HSS MADAVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9066",
            "district": "31",
            "Block": "362",
            "label": "GOVT BOYS HSS MADAVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9067",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS KODUMMAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9068",
            "district": "31",
            "Block": "362",
            "label": "GHS ARUNTHATHIYAR  COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9069",
            "district": "31",
            "Block": "362",
            "label": "GOVT HSS  BOMMIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9070",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS THIMMANAMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9071",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS JAMANAPUDURPOONGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9072",
            "district": "31",
            "Block": "5",
            "label": "PUMS BALAPPALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9073",
            "district": "31",
            "Block": "5",
            "label": "GOVT HR. SEC. SCHOOL POONGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9074",
            "district": "31",
            "Block": "5",
            "label": "GOVT HS MITTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9075",
            "district": "31",
            "Block": "5",
            "label": "GOVT HR SEC SCHOOL MITTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9076",
            "district": "31",
            "Block": "362",
            "label": "GOVT HSS ANDIYAPPANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9077",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS PERUMAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9078",
            "district": "31",
            "Block": "362",
            "label": "GOVT HSS VADUKAMUTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9079",
            "district": "31",
            "Block": "362",
            "label": "AHSS TMS TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9080",
            "district": "31",
            "Block": "362",
            "label": "AHSS RAMAKRISHNA TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9081",
            "district": "31",
            "Block": "362",
            "label": "DOMINIC SAVIO HSS TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9082",
            "district": "31",
            "Block": "362",
            "label": "AGHSS UBAIBAS TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9083",
            "district": "31",
            "Block": "362",
            "label": "MARY IMMACULATE GHSS TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9084",
            "district": "31",
            "Block": "362",
            "label": "GOVT BOYS HSS TIRUPATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9085",
            "district": "31",
            "Block": "362",
            "label": "GOVT MGHSS TIRUPATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9086",
            "district": "31",
            "Block": "362",
            "label": "GOVT GARDEN HS TIRUPATTUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9087",
            "district": "31",
            "Block": "362",
            "label": "AHSS OSMANIA TIRUPATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9088",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS MANDALANAYANAGUNDA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9089",
            "district": "31",
            "Block": "103",
            "label": "GHS GUMMIDIGAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9090",
            "district": "31",
            "Block": "103",
            "label": "GOVT GIRLS HSS GAJALNAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9091",
            "district": "31",
            "Block": "103",
            "label": "IVN GOVT BOYS HR SEC SCHOOL GAJALNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9092",
            "district": "31",
            "Block": "103",
            "label": "VAILANKANNI MATRICULATION SCHOOL VENGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9093",
            "district": "31",
            "Block": "103",
            "label": "JOTHI HS GAJALNAICKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9094",
            "district": "31",
            "Block": "103",
            "label": "PUMS, P.MUTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9095",
            "district": "31",
            "Block": "103",
            "label": "DR CHANDRALEKHA MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9096",
            "district": "31",
            "Block": "103",
            "label": "GOVERNMENT HIGH SCHOOL , VENGALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9097",
            "district": "31",
            "Block": "103",
            "label": "INDIAN MODERN MATRIC SCHOOL, KASINAICKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9098",
            "district": "31",
            "Block": "103",
            "label": "LINGANNAMANI MATRIC HSS, THAYAPPA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9099",
            "district": "31",
            "Block": "103",
            "label": "PUMS CHINNAKASINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9100",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS KASINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9101",
            "district": "31",
            "Block": "103",
            "label": "SHRI AMRITA HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9102",
            "district": "31",
            "Block": "103",
            "label": "PUMS, LAKKINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9103",
            "district": "31",
            "Block": "103",
            "label": "PUMS JALLIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9104",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS PERIYAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9105",
            "district": "31",
            "Block": "103",
            "label": "PUMS TELUNGUMATRAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9106",
            "district": "31",
            "Block": "103",
            "label": "PUMS, MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9107",
            "district": "31",
            "Block": "103",
            "label": "GHS KIZHAKKU BADANAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9108",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS SUNDHARAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9109",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS NATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9110",
            "district": "31",
            "Block": "103",
            "label": "PUMS  KOLKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9111",
            "district": "31",
            "Block": "103",
            "label": "GHSS PERIYAKANNALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9112",
            "district": "31",
            "Block": "103",
            "label": "SWAMI VIVEKANANDHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9113",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS SEVVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9114",
            "district": "31",
            "Block": "103",
            "label": "SRI BALA VIDYALAYA MATRIC SCHOOL, SEVVATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9115",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS KUNICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9116",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS ELAVAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9117",
            "district": "31",
            "Block": "103",
            "label": "SHRI VENKATESWARA MATRIC HR. SEC. SCHOOL, THORANAMPATHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9118",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS THORANAMPATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9119",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS KORATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9120",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS VISHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9121",
            "district": "31",
            "Block": "103",
            "label": "ST. MARY'S MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9122",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS ANGANATHAVALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9123",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS PERAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9124",
            "district": "31",
            "Block": "103",
            "label": "ADWMS KURUMBERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9125",
            "district": "31",
            "Block": "103",
            "label": "GHSS KURUMBERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9126",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS MATRAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20901",
            "district": "31",
            "Block": "155",
            "label": "RADIANT MATRIC SCHOOL, KADAVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20909",
            "district": "31",
            "Block": "82",
            "label": "GREENLAND MATRIC SCHOOL, PUDUPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20951",
            "district": "31",
            "Block": "82",
            "label": "JOLARPET THIRUVALLUVAR MATRIC SCHOOL, JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20954",
            "district": "31",
            "Block": "362",
            "label": "SWAMI VIVEKANADA MATRIC HSS, THIMMANAMUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21009",
            "district": "31",
            "Block": "82",
            "label": "VEDHA MATRIC HSS, T.VEERAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21010",
            "district": "31",
            "Block": "82",
            "label": "C.S MATRIC HSS, JAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21011",
            "district": "31",
            "Block": "82",
            "label": "GREEN VALLEY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21058",
            "district": "31",
            "Block": "362",
            "label": "DAYANANDA VIDYALAYA MATRIC HR. SEC. SCHOOL, KURUSILAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21130",
            "district": "31",
            "Block": "82",
            "label": "PUMS, SANJEEVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21198",
            "district": "31",
            "Block": "82",
            "label": "GHSS KETHANDAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21259",
            "district": "31",
            "Block": "82",
            "label": "PEACE GARDEN MATRIC HSS, YELAGIRI HILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21300",
            "district": "31",
            "Block": "82",
            "label": "SHEMFORD FUTURISTIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21434",
            "district": "31",
            "Block": "5",
            "label": "GHS KANAVAIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21435",
            "district": "31",
            "Block": "5",
            "label": "GGHS NIMMIYAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21436",
            "district": "31",
            "Block": "208",
            "label": "GHS THAGARAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21437",
            "district": "31",
            "Block": "208",
            "label": "GGHS PATCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21438",
            "district": "31",
            "Block": "82",
            "label": "GHS, NEKKUNDHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21439",
            "district": "31",
            "Block": "82",
            "label": "GHS ASHOK NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21440",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS NONDI MARIYAMMAN KOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21441",
            "district": "31",
            "Block": "103",
            "label": "GHS ADIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21494",
            "district": "31",
            "Block": "362",
            "label": "ADWHS JADAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21576",
            "district": "31",
            "Block": "82",
            "label": "Sri Narayani Vidhyalaya matric school",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21679",
            "district": "31",
            "Block": "5",
            "label": "JAYA VAASAVI MATRIC.HR.SEC.SCHOOL, ALANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21767",
            "district": "31",
            "Block": "155",
            "label": "MJR MATRICULATION SCHOOL VEERANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21769",
            "district": "31",
            "Block": "155",
            "label": "FUTURE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21770",
            "district": "31",
            "Block": "155",
            "label": "WISDOM PARK INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21776",
            "district": "31",
            "Block": "103",
            "label": "RAJAS INTERNATIONALS SCHOOLS CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21777",
            "district": "31",
            "Block": "103",
            "label": "SRI VIJAY VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, ADIYUR, TIRUPATTUR..",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21778",
            "district": "31",
            "Block": "103",
            "label": "SREE SHAI SARAN MATRICULATION SCHOOL SEVATHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21779",
            "district": "31",
            "Block": "103",
            "label": "MAHA MATRIC SCHOOL, PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21780",
            "district": "31",
            "Block": "103",
            "label": "DR.THANGAMMA INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21884",
            "district": "31",
            "Block": "208",
            "label": "CONCORDIA HSS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21930",
            "district": "31",
            "Block": "155",
            "label": "V.NALINI VIDYALAYA MATRIC SCHOOL, NARIYAMBUT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22069",
            "district": "31",
            "Block": "155",
            "label": "KSR MATRIC PUBLIC HIGHER SECONDARY SCHOOL, MADHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22070",
            "district": "31",
            "Block": "5",
            "label": "GOVT (B) HR SEC SCHOOL  ALANGAYAM  ,TIRUPATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22071",
            "district": "31",
            "Block": "208",
            "label": "RAJI GARDEN MATRIC HIGHER SECONDARY SCHOOL, NATRAMPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23677",
            "district": "31",
            "Block": "362",
            "label": "Shakespere Matriculation school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23669",
            "district": "31",
            "Block": "362",
            "label": "Sri Nandhanam International School (CBSE)",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "22072",
            "district": "31",
            "Block": "82",
            "label": "DON BOSCO SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22073",
            "district": "31",
            "Block": "103",
            "label": "SRI V.R.V MATRIC HIGHER SECONDARY SCHOOL , PERAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22392",
            "district": "31",
            "Block": "155",
            "label": "S.R.V HI TECH MATRICULATION SCHOOL, MANIYARAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22393",
            "district": "31",
            "Block": "155",
            "label": "VIVEKA MATRICULATION SCHOOL, MARAPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22394",
            "district": "31",
            "Block": "5",
            "label": "KALAM VIDYALAYA MATRICULATION SCHOOL, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22395",
            "district": "31",
            "Block": "5",
            "label": "GOVT HIGH SCHOOL B.NAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22396",
            "district": "31",
            "Block": "208",
            "label": "MUNCIPAL MUSLIM BOYS HIGH SCHOOL GANDHI NAGAR VANIYAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22397",
            "district": "31",
            "Block": "82",
            "label": "GOVT HIGH SCHOOL KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22398",
            "district": "31",
            "Block": "82",
            "label": "GOVT HIGH SCHOOL KONAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22399",
            "district": "31",
            "Block": "362",
            "label": "EKLAVYA MODEL RESIDENTIAL HR.SEC SCHOOL,PUDURNADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22801",
            "district": "31",
            "Block": "103",
            "label": "CSI Public School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22948",
            "district": "31",
            "Block": "82",
            "label": "GHS, CHINNAMOOKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22950",
            "district": "31",
            "Block": "208",
            "label": "MHS, KONAMEDU, VANIYAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23590",
            "district": "31",
            "Block": "82",
            "label": "DEVI VENKATACHALAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23591",
            "district": "31",
            "Block": "103",
            "label": "SRI VIJAY VIDYASHRAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23592",
            "district": "31",
            "Block": "155",
            "label": "KSR MYTIKAS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23593",
            "district": "31",
            "Block": "362",
            "label": "TIRUPATHUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23794",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KATTUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23893",
            "district": "31",
            "Block": "208",
            "label": "PUMS NARAYANAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24014",
            "district": "31",
            "Block": "331",
            "label": "PUMS VINAYAGAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24112",
            "district": "31",
            "Block": "331",
            "label": "TIRUPATHUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "32": [
        {
            "key": "421",
            "district": "32",
            "Block": "223",
            "label": "LITERACY MISSION MATRIC HR SEC SCHOOL, SAMALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "478",
            "district": "32",
            "Block": "371",
            "label": "OXFORD HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "502",
            "district": "32",
            "Block": "363",
            "label": "BHARATH MATRICULATION SCHOOL, VEERAPANDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "650",
            "district": "32",
            "Block": "363",
            "label": "LEARNING HEARTS HS,15 VELAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "677",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UTHUKULI RAILWAY STATION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "690",
            "district": "32",
            "Block": "223",
            "label": "V.A.T TRUST MATRIC HR SEC SCHOOL, GANAPATHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "691",
            "district": "32",
            "Block": "371",
            "label": "RGMHSS UDUMALAIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "692",
            "district": "32",
            "Block": "363",
            "label": "PEM SCHOOL OF EXCELLANCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "693",
            "district": "32",
            "Block": "32",
            "label": "S.K.L PUBLIC MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "694",
            "district": "32",
            "Block": "363",
            "label": "OXFORD MODERN MATRIC SCHOOL, MUTHANAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "699",
            "district": "32",
            "Block": "363",
            "label": "BALABHAVAN GLOBAL MHSS, KARUMARAMPALAYAM, TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "700",
            "district": "32",
            "Block": "32",
            "label": "MIGROS K.I.D.S MATRIC HR SEC SCHOOL, KANIYAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "708",
            "district": "32",
            "Block": "363",
            "label": "PLATO'S ACADEMY MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "721",
            "district": "32",
            "Block": "73",
            "label": "RGM HSS PEDAPPAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "722",
            "district": "32",
            "Block": "104",
            "label": "GLOBAL  MATRIC HR SEC SCHOOL, KANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "723",
            "district": "32",
            "Block": "363",
            "label": "LITTLE FLOWER MAT HSS,ANDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "731",
            "district": "32",
            "Block": "363",
            "label": "TNSS GANDHI VIDHYALAYA MATRIC HR SEC SCHOOL,VEERAPANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "737",
            "district": "32",
            "Block": "363",
            "label": "V A T T MHSS, TIRUPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "739",
            "district": "32",
            "Block": "363",
            "label": "NYRUTHI VIDHYA BHAVAN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "740",
            "district": "32",
            "Block": "363",
            "label": "ST.JOSEPH'S MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "741",
            "district": "32",
            "Block": "32",
            "label": "PALANIAPPA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "744",
            "district": "32",
            "Block": "363",
            "label": "VELAVAN MHS,TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "745",
            "district": "32",
            "Block": "363",
            "label": "CENTURY FOUNDATION MHSS,TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "748",
            "district": "32",
            "Block": "371",
            "label": "SRINIVASA VIDHYALAYA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "750",
            "district": "32",
            "Block": "371",
            "label": "LOURDE MATHA CONVENT MATRIC  HSS KURALKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "756",
            "district": "32",
            "Block": "363",
            "label": "AZAD MHSS,,NACHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "762",
            "district": "32",
            "Block": "223",
            "label": "BHARATHI MATRIC HR SEC SCHOOL, P.VADUGAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "767",
            "district": "32",
            "Block": "392",
            "label": "BHARAT MATRICULATION SCHOOL,OLAPPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "771",
            "district": "32",
            "Block": "371",
            "label": "SRIGVG VISHALAKSHI MHSS UDUMALPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "788",
            "district": "32",
            "Block": "223",
            "label": "PUMS ALLALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "798",
            "district": "32",
            "Block": "223",
            "label": "JAYANTHI MATRIC HR SEC SCHOOL, ARULPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "800",
            "district": "32",
            "Block": "363",
            "label": "THE FRONT LINE ACADEMY MAT HSS,PERUNTHOLUVU ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "801",
            "district": "32",
            "Block": "32",
            "label": "M.S.VIDYALAYA  MAT KARUNAIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "803",
            "district": "32",
            "Block": "104",
            "label": "JAYCEES MATRIC HR SEC SCHOOL, SIVANMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "804",
            "district": "32",
            "Block": "363",
            "label": "VIGNESWARA VIDYALAYA MHSS,TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "807",
            "district": "32",
            "Block": "223",
            "label": "PUMS MANICKAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "809",
            "district": "32",
            "Block": "104",
            "label": "SRI RAJARAJESWARI MATRIC HR SEC SCHOOL, KANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "813",
            "district": "32",
            "Block": "363",
            "label": "SRI SAI MATRIC HR.SEC. SCHOOL , RAM NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "814",
            "district": "32",
            "Block": "363",
            "label": "DHEVA MATRICULATION HR.SEC.SCHOOL,K.CHETTIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "823",
            "district": "32",
            "Block": "363",
            "label": "KGS MHS,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "825",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "836",
            "district": "32",
            "Block": "145",
            "label": "R G MATRIC HR SEC SCHOOL MANOORPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "841",
            "district": "32",
            "Block": "363",
            "label": "Saradha Vidhyalaya Matric HSS, Pandiyan Nagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "844",
            "district": "32",
            "Block": "363",
            "label": "VIDHYAA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL-KULATHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "848",
            "district": "32",
            "Block": "363",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC HR SEC SCHOOL,AMMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "850",
            "district": "32",
            "Block": "363",
            "label": "MMS, ARANMANAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "860",
            "district": "32",
            "Block": "363",
            "label": "Kathiravan Matric. Hr. Sec. School,K.V.R NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "862",
            "district": "32",
            "Block": "32",
            "label": "VENKATESWARA VIDYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "863",
            "district": "32",
            "Block": "363",
            "label": "PUMS ATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "866",
            "district": "32",
            "Block": "154",
            "label": "PUMS METRATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "867",
            "district": "32",
            "Block": "223",
            "label": "PUMS RAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        { 
            "key" :"24116", "district" :"32", 
            "Block" :"363", "label": "Kumaran matriculation school", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        { 
            "key" :"24118", "district" :"32", 
            "Block":"223", "label": "Devathai Pri &Nursary School", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "875",
            "district": "32",
            "Block": "363",
            "label": "VIDHYA VIKASHNI MATRIC.HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "881",
            "district": "32",
            "Block": "223",
            "label": "PUMS ANUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "882",
            "district": "32",
            "Block": "363",
            "label": "JAI SARADHA MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "886",
            "district": "32",
            "Block": "363",
            "label": "PUMS,NEHRU NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "887",
            "district": "32",
            "Block": "250",
            "label": "PUMS MADAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "890",
            "district": "32",
            "Block": "32",
            "label": "T.E.A PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL,AVINASHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "892",
            "district": "32",
            "Block": "223",
            "label": "PUMS PETHAMPOOCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "893",
            "district": "32",
            "Block": "363",
            "label": "PUMS, KUPPANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "900",
            "district": "32",
            "Block": "32",
            "label": "TAKKO MatricSCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "901",
            "district": "32",
            "Block": "363",
            "label": "VIDYA MANDIR MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "903",
            "district": "32",
            "Block": "73",
            "label": "PUMS PONNERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "907",
            "district": "32",
            "Block": "392",
            "label": "JAYAM VIDHYA BHAVAN MATRIC HR.SEC.SCHOOL,VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "914",
            "district": "32",
            "Block": "363",
            "label": "PUMS, THIRU KUMARAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "915",
            "district": "32",
            "Block": "223",
            "label": "LAKSHMI MILLS MIDDLE SCHOOL(AIDED)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "918",
            "district": "32",
            "Block": "223",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL PALLAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "920",
            "district": "32",
            "Block": "223",
            "label": "PUMS MADHESWARANNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "921",
            "district": "32",
            "Block": "154",
            "label": "PUMS,POLARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "923",
            "district": "32",
            "Block": "363",
            "label": "VEERA SIVAJI VIDHYALAYA MATRIC HR .SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "936",
            "district": "32",
            "Block": "190",
            "label": "PUMS ARIKKARANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "938",
            "district": "32",
            "Block": "223",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23387",
            "district": "32",
            "Block": "392",
            "label": "K.K.P. MATRIC HR SEC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23415",
            "district": "32",
            "Block": "145",
            "label": "Shree Venaudaiyar Vidhyalaya School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23419",
            "district": "32",
            "Block": "363",
            "label": "SRI KUMARAN MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "947",
            "district": "32",
            "Block": "32",
            "label": "CAMBRIDGE MATRIC PANTHAMPALAYM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "952",
            "district": "32",
            "Block": "73",
            "label": "PUMS SOMAVARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "954",
            "district": "32",
            "Block": "363",
            "label": "SUBBIAH MATRIC HR.SEC.SCHOOL, TIRUPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "956",
            "district": "32",
            "Block": "363",
            "label": "SUBASH MATRIC HSS, MURUGAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "957",
            "district": "32",
            "Block": "363",
            "label": "PUMS, PALAVANJIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "960",
            "district": "32",
            "Block": "363",
            "label": "KIDS CLUB MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "968",
            "district": "32",
            "Block": "223",
            "label": "PUMS KARAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "974",
            "district": "32",
            "Block": "154",
            "label": "PUMS MADATHUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "990",
            "district": "32",
            "Block": "190",
            "label": "PUMS M.KARUPPANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "995",
            "district": "32",
            "Block": "371",
            "label": "MMS KANAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1001",
            "district": "32",
            "Block": "371",
            "label": "R.V.G. MATRIC HSS KURICHIKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1002",
            "district": "32",
            "Block": "371",
            "label": "R K R GRKS MAT HSS, PERIYAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1006",
            "district": "32",
            "Block": "378",
            "label": "SRI KUMARAN MATRICULATION HIGHER SECONDARY SCHOOL, CHENGAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1011",
            "district": "32",
            "Block": "363",
            "label": "WISDOM MHSS,,IDUVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1013",
            "district": "32",
            "Block": "223",
            "label": "TELC MS EAST PALLADAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1022",
            "district": "32",
            "Block": "363",
            "label": "PUMS ,A CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1024",
            "district": "32",
            "Block": "363",
            "label": "PUMS,T.MANNARAI,TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1030",
            "district": "32",
            "Block": "223",
            "label": "PUMS KUNNANGALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1033",
            "district": "32",
            "Block": "223",
            "label": "PUMS PARUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1040",
            "district": "32",
            "Block": "223",
            "label": "KANNAMMAL NATIONAL MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1042",
            "district": "32",
            "Block": "223",
            "label": "PUMS CHINNIYAGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1045",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1047",
            "district": "32",
            "Block": "363",
            "label": "MMS, KARUMARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1055",
            "district": "32",
            "Block": "73",
            "label": "PUMS V.LINGAMANAICKENPATTI PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1056",
            "district": "32",
            "Block": "223",
            "label": "PUMS SEDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1057",
            "district": "32",
            "Block": "32",
            "label": "SHANTHI VIDHYALAYA MAT CHEYUR ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1062",
            "district": "32",
            "Block": "154",
            "label": "PUMS MADATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1066",
            "district": "32",
            "Block": "392",
            "label": "PUNITHA AMALA ANNAI MHSS,VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1074",
            "district": "32",
            "Block": "145",
            "label": "PUMS GOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1076",
            "district": "32",
            "Block": "104",
            "label": "OXFORD MATRICULATION SCHOOL, NATHAKKADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1078",
            "district": "32",
            "Block": "363",
            "label": "PUMS, BHARATHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1080",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1098",
            "district": "32",
            "Block": "378",
            "label": "GHS, CHENGAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "1103",
            "district": "32",
            "Block": "363",
            "label": "MMS.KADERPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1104",
            "district": "32",
            "Block": "145",
            "label": "PUMS PUTHURUTHRAVATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1106",
            "district": "32",
            "Block": "223",
            "label": "PUMS K.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1107",
            "district": "32",
            "Block": "154",
            "label": "PUMS SAMARAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1109",
            "district": "32",
            "Block": "223",
            "label": "PUMS KALLAMPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1110",
            "district": "32",
            "Block": "363",
            "label": "MMS,PALAYANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1125",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEELAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1129",
            "district": "32",
            "Block": "363",
            "label": "PUMS, SIDCO",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1133",
            "district": "32",
            "Block": "190",
            "label": "PUMS NATHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1136",
            "district": "32",
            "Block": "73",
            "label": "PUMS MURUNGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1145",
            "district": "32",
            "Block": "190",
            "label": "PUMS KURNAICKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1146",
            "district": "32",
            "Block": "145",
            "label": "PUMS MANURPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1148",
            "district": "32",
            "Block": "363",
            "label": "NATIONAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1151",
            "district": "32",
            "Block": "145",
            "label": "PUMS MUTHALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1155",
            "district": "32",
            "Block": "223",
            "label": "PUMS PALLADAM- WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1158",
            "district": "32",
            "Block": "363",
            "label": "VIKAS VIDYALAYA MHSS,KOOLIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1161",
            "district": "32",
            "Block": "145",
            "label": "PUMS VENGIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1170",
            "district": "32",
            "Block": "392",
            "label": "AANOOR VIDYALAYA MATRIC H.S.S, MUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1174",
            "district": "32",
            "Block": "363",
            "label": "PUMS, PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1175",
            "district": "32",
            "Block": "363",
            "label": "MMS,MUTHUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1182",
            "district": "32",
            "Block": "363",
            "label": "PUMS, BHARATHIPURAM,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1185",
            "district": "32",
            "Block": "154",
            "label": "PUMS SALARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1188",
            "district": "32",
            "Block": "363",
            "label": "AVP TRUST MATRICULATION HR SEC SCHOOL, GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1194",
            "district": "32",
            "Block": "145",
            "label": "PUMS THALAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1197",
            "district": "32",
            "Block": "250",
            "label": "PUMS THUTHARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1198",
            "district": "32",
            "Block": "363",
            "label": "PUMS CHERANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1208",
            "district": "32",
            "Block": "363",
            "label": "SRI KALAIMAGAL VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1216",
            "district": "32",
            "Block": "363",
            "label": "SRI KAMATCHI AMMAN MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1222",
            "district": "32",
            "Block": "371",
            "label": "PUMS SELLAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1224",
            "district": "32",
            "Block": "363",
            "label": "MMS GOLDEN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1229",
            "district": "32",
            "Block": "363",
            "label": "PUMS CHINNANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1243",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THENEESWARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1248",
            "district": "32",
            "Block": "57",
            "label": "SINDHU MATRIC HSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1249",
            "district": "32",
            "Block": "363",
            "label": "PUMS SULTHANPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1255",
            "district": "32",
            "Block": "57",
            "label": "VISHNU LAKSHMI MHSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1267",
            "district": "32",
            "Block": "190",
            "label": "PUMS MULANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1268",
            "district": "32",
            "Block": "363",
            "label": "PUMS, ANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1275",
            "district": "32",
            "Block": "57",
            "label": "PONNU MATRIC HSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1282",
            "district": "32",
            "Block": "363",
            "label": "PUMS, SIRUPOOLUVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1297",
            "district": "32",
            "Block": "223",
            "label": "PARK MATRIC HR SEC SCHOOL, CHINNAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1298",
            "district": "32",
            "Block": "223",
            "label": "BLUE BIRD MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1314",
            "district": "32",
            "Block": "145",
            "label": "SWAMY VIVEKANANDA VIDYALAYA MAT.HR.SEC.SCHOOL, KODUVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1328",
            "district": "32",
            "Block": "223",
            "label": "PUMS EAST PALLADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1339",
            "district": "32",
            "Block": "363",
            "label": "PUMS, CHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1360",
            "district": "32",
            "Block": "363",
            "label": "MMS,MANNARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1374",
            "district": "32",
            "Block": "145",
            "label": "PUMS EDAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1398",
            "district": "32",
            "Block": "363",
            "label": "PUMS, VATTAKATTUPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1425",
            "district": "32",
            "Block": "363",
            "label": "PALANIAMMALMGHSS, TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1445",
            "district": "32",
            "Block": "363",
            "label": "MMS. CHANDRAKAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1458",
            "district": "32",
            "Block": "363",
            "label": "PUMS AGRAHARAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1466",
            "district": "32",
            "Block": "363",
            "label": "PUMS KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1476",
            "district": "32",
            "Block": "371",
            "label": "MMS PADDY BAZAAR STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1518",
            "district": "32",
            "Block": "363",
            "label": "MMS, KANGEYAMPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1525",
            "district": "32",
            "Block": "363",
            "label": "BHARATHI KIDS KSHETHRALAYA SCHOOL, TIRUPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1527",
            "district": "32",
            "Block": "363",
            "label": "SRI VASAVI VIDYALAYA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1540",
            "district": "32",
            "Block": "363",
            "label": "KONGU MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1558",
            "district": "32",
            "Block": "378",
            "label": "KONGU MATRICULATION HIGHER SECONDARY SCHOOL, UTHUKULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1560",
            "district": "32",
            "Block": "73",
            "label": "PUMS ADIVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1572",
            "district": "32",
            "Block": "363",
            "label": "PUMS, PONNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1582",
            "district": "32",
            "Block": "250",
            "label": "SRI DEVI MATRIC HR SEC SCHOOL, VELAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1587",
            "district": "32",
            "Block": "57",
            "label": "CENTTWIN MATRIC HSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1601",
            "district": "32",
            "Block": "145",
            "label": "PUMS VERUVEDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1604",
            "district": "32",
            "Block": "57",
            "label": "LIONS MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1606",
            "district": "32",
            "Block": "190",
            "label": "PUMS PATTUTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1636",
            "district": "32",
            "Block": "392",
            "label": "KURINCHI  MATRIC SCHOOL, VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1638",
            "district": "32",
            "Block": "73",
            "label": "PUMS-ILUPPANAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1645",
            "district": "32",
            "Block": "145",
            "label": "PUMS ELLAPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1667",
            "district": "32",
            "Block": "190",
            "label": "PANCHAYAT  UNION  MIDDLE  SCHOOL  SOMANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1675",
            "district": "32",
            "Block": "145",
            "label": "PUMS MUTHUGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1693",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1695",
            "district": "32",
            "Block": "371",
            "label": "PUMS VENJAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1697",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KASTHURIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1727",
            "district": "32",
            "Block": "145",
            "label": "PUMS SURIYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1742",
            "district": "32",
            "Block": "371",
            "label": "PUMS SS PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1764",
            "district": "32",
            "Block": "190",
            "label": "BHARATHI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL,MULANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1768",
            "district": "32",
            "Block": "363",
            "label": "PUMS, KETHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1769",
            "district": "32",
            "Block": "363",
            "label": "PUMS, ANUPPARPALAYAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1776",
            "district": "32",
            "Block": "250",
            "label": "PUMS VAVIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1778",
            "district": "32",
            "Block": "378",
            "label": "KONGU MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1802",
            "district": "32",
            "Block": "363",
            "label": "MMS,VELLIANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1855",
            "district": "32",
            "Block": "223",
            "label": "INFANT JESUS MATRIC HR SEC SCHOOL, SUKKAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1880",
            "district": "32",
            "Block": "154",
            "label": "PUMS,MYVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1888",
            "district": "32",
            "Block": "363",
            "label": "PUMS,SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1895",
            "district": "32",
            "Block": "154",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1910",
            "district": "32",
            "Block": "363",
            "label": "INFANT JESUS MHSS, KUMAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1929",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1977",
            "district": "32",
            "Block": "363",
            "label": "PUMS,PERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2008",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SUNDAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2064",
            "district": "32",
            "Block": "145",
            "label": "PUMS N.KASILINGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2089",
            "district": "32",
            "Block": "363",
            "label": "NIRMALA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2123",
            "district": "32",
            "Block": "378",
            "label": "ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, UTHUKULI RAILWAY STATION",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2129",
            "district": "32",
            "Block": "363",
            "label": "PUMS PERIYAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2144",
            "district": "32",
            "Block": "250",
            "label": "PUMS NACHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2158",
            "district": "32",
            "Block": "104",
            "label": "PUMS KANGAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2160",
            "district": "32",
            "Block": "145",
            "label": "VSV MAT. HR.SEC.SCHOOL METTUKKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2219",
            "district": "32",
            "Block": "371",
            "label": "PUMS CHINNAVEERAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2239",
            "district": "32",
            "Block": "363",
            "label": "SHRI VALLI VIDHYALAYAA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2250",
            "district": "32",
            "Block": "250",
            "label": "PUMS SEMALAIGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2273",
            "district": "32",
            "Block": "73",
            "label": "OXFORD MATRIC HSS-KONGALNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2277",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PERANAICKAN PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2278",
            "district": "32",
            "Block": "32",
            "label": "TIRUPUR PUBLIC MATRIC SCHOOL , RAKYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2283",
            "district": "32",
            "Block": "250",
            "label": "SUBBA NAIDU VENKITTAMMAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2289",
            "district": "32",
            "Block": "371",
            "label": "STELLA MARIS MATRIC HSS,RAGALBAVI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2346",
            "district": "32",
            "Block": "371",
            "label": "PUMS K VALLAKUNDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2347",
            "district": "32",
            "Block": "363",
            "label": "RAJALINGAM KALVI NILAYAM,AIDED MDL SCHOOL, AMARAVATHIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2352",
            "district": "32",
            "Block": "371",
            "label": "MMS EXTENSION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2366",
            "district": "32",
            "Block": "363",
            "label": "PUMS,APPIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2383",
            "district": "32",
            "Block": "104",
            "label": "PUMS SIVIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2393",
            "district": "32",
            "Block": "371",
            "label": "PUMS SUNDAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2401",
            "district": "32",
            "Block": "145",
            "label": "PUMS KALLIVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2408",
            "district": "32",
            "Block": "363",
            "label": "PUMS, BOYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2455",
            "district": "32",
            "Block": "145",
            "label": "PUMS PERIYAKUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2459",
            "district": "32",
            "Block": "371",
            "label": "PUMS KANNAMANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2462",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT HIGH SCHOOL, ARUGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "2463",
            "district": "32",
            "Block": "363",
            "label": "PUMS, MURUGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2494",
            "district": "32",
            "Block": "392",
            "label": "KONGU VELLALAR MHSS, VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2506",
            "district": "32",
            "Block": "363",
            "label": "PUMS, 15 VELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2515",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PARAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2575",
            "district": "32",
            "Block": "363",
            "label": "MMS, BALAMURUGAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2581",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, 16,VELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2629",
            "district": "32",
            "Block": "363",
            "label": "MMS,Thennampalayam,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2648",
            "district": "32",
            "Block": "363",
            "label": "GMS KOOLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2651",
            "district": "32",
            "Block": "223",
            "label": "KATHIRAVAN MATRIC HR.SEC SCHOOL, POOMALUR, MANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2669",
            "district": "32",
            "Block": "363",
            "label": "PUMS,NERUPPERICHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2710",
            "district": "32",
            "Block": "363",
            "label": "PUMS,KALIPALAYAMPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2758",
            "district": "32",
            "Block": "363",
            "label": "M.N. CHICKKANNA CHETTIAR MAT HIGH SCHOOL,, PALAYAKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2828",
            "district": "32",
            "Block": "363",
            "label": "MMS,RAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2838",
            "district": "32",
            "Block": "250",
            "label": "PUMS PUTHERICHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2914",
            "district": "32",
            "Block": "190",
            "label": "KALAIKOVIL VIDHYA MANDIR MATRIC HIGHER SECONDARY SCHOOL, POLARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2933",
            "district": "32",
            "Block": "104",
            "label": "PUMS BHARATHIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2966",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, UTHUKULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2993",
            "district": "32",
            "Block": "378",
            "label": "SARASWATHI KALVI NILAYAM MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3033",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S, VELLAKOVIL-EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3041",
            "district": "32",
            "Block": "371",
            "label": "PUMS ANTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3045",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVARAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3079",
            "district": "32",
            "Block": "73",
            "label": "PUMS MOONGILTHOLUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3096",
            "district": "32",
            "Block": "250",
            "label": "PUMS KOVILPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3147",
            "district": "32",
            "Block": "363",
            "label": "MMS, POOLAVARISUGUMAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3217",
            "district": "32",
            "Block": "363",
            "label": "Annai Matric Tiruppur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3221",
            "district": "32",
            "Block": "73",
            "label": "PUMS KOTTAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3242",
            "district": "32",
            "Block": "223",
            "label": "SWAMY VIVEKANANDHA MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3262",
            "district": "32",
            "Block": "32",
            "label": "AKR ACADEMY SCHOOL  ANAIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3272",
            "district": "32",
            "Block": "145",
            "label": "Vivekanandha Academy Senior Secondary School  (CBSE) .",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3302",
            "district": "32",
            "Block": "371",
            "label": "ANNAI ABIRAMI MATRIC HSS SCHOOL VILAMARATHUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3368",
            "district": "32",
            "Block": "363",
            "label": "SHRI SARASWATHI GIRI MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3379",
            "district": "32",
            "Block": "104",
            "label": "SRI VIDHYA NIKETHAN MATRIC HR SEC SCHOOL, VARATHAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23680",
            "district": "32",
            "Block": "363",
            "label": "Mugi Kids Nursery & PrimarySchool",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "3424",
            "district": "32",
            "Block": "154",
            "label": "PUMS NG PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3427",
            "district": "32",
            "Block": "363",
            "label": "KONGU VELLALAR MHSS, ANGERIPALAYAM ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3512",
            "district": "32",
            "Block": "363",
            "label": "MMS, CHELLAPPAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3527",
            "district": "32",
            "Block": "392",
            "label": "SRI KAMADENU MATRIC  SCHOOL, UTHAMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3559",
            "district": "32",
            "Block": "104",
            "label": "PUMS PALAYAKOTTAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3648",
            "district": "32",
            "Block": "363",
            "label": "MMS,THIRUNEELAKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3675",
            "district": "32",
            "Block": "363",
            "label": "PUMS,MUMMOORTHY NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3770",
            "district": "32",
            "Block": "371",
            "label": "PUMS ORALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3774",
            "district": "32",
            "Block": "371",
            "label": "MMS PALANIANDAVAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3779",
            "district": "32",
            "Block": "32",
            "label": "PUMS VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3810",
            "district": "32",
            "Block": "104",
            "label": "MERCY MATRIC HR SEC SCHOOL, KADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3869",
            "district": "32",
            "Block": "250",
            "label": "PUMS KH COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3947",
            "district": "32",
            "Block": "392",
            "label": "BALA MATRIC HR.SEC SCHOOL , VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3968",
            "district": "32",
            "Block": "363",
            "label": "PUMS, SAMUNDIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4041",
            "district": "32",
            "Block": "363",
            "label": "THIRUMURUGAN MHS,NERUPPERICHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4144",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GOVINDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4338",
            "district": "32",
            "Block": "57",
            "label": "ST AVILA MATRICULATION SCHOOL, DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4345",
            "district": "32",
            "Block": "363",
            "label": "KMCPUBLIC SCHOOL,,PERUMANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4356",
            "district": "32",
            "Block": "371",
            "label": "PUMS KILUVANKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4444",
            "district": "32",
            "Block": "363",
            "label": "MMS SV COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4491",
            "district": "32",
            "Block": "154",
            "label": "PUMS RUTHIRAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4521",
            "district": "32",
            "Block": "363",
            "label": "MMS ,Mettupalayam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4522",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUVAIMUDHALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4575",
            "district": "32",
            "Block": "223",
            "label": "UNIVERSAL MATRIC HR SEC SCHOOL, SEDAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4576",
            "district": "32",
            "Block": "250",
            "label": "PUMS PALANIGOUNDEN PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4589",
            "district": "32",
            "Block": "378",
            "label": "NANJUNDAPURAM RANGASAMY KARUPPANNA NADAR HIGH SCHOOL, KUNNATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "4609",
            "district": "32",
            "Block": "378",
            "label": "GHSS, MORATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4695",
            "district": "32",
            "Block": "73",
            "label": "PUMS,AMANTHAKADAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4697",
            "district": "32",
            "Block": "392",
            "label": "PUMS THENNANKARAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4708",
            "district": "32",
            "Block": "250",
            "label": "PUMS KULLAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4718",
            "district": "32",
            "Block": "104",
            "label": "JAIRUPAA MATRICULATION SCHOOL, THOTTIYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4820",
            "district": "32",
            "Block": "363",
            "label": "BHARATHI VIKAS MAT SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4833",
            "district": "32",
            "Block": "250",
            "label": "KINGSBERRY  MATRICULATION HR SEC SCHOOL, V.KALLIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4844",
            "district": "32",
            "Block": "154",
            "label": "PUMS,THANTHONI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5055",
            "district": "32",
            "Block": "371",
            "label": "MMS RUDRAPPANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5113",
            "district": "32",
            "Block": "250",
            "label": "KALAIMAGAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5128",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,CHINNAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5172",
            "district": "32",
            "Block": "104",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5227",
            "district": "32",
            "Block": "250",
            "label": "PUMS RAMAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5234",
            "district": "32",
            "Block": "250",
            "label": "PUMS MASANALLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5256",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIMMANAYAKKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5440",
            "district": "32",
            "Block": "392",
            "label": "GNANASAMBANDAR MHSS, PUDHUPPAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5461",
            "district": "32",
            "Block": "363",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5486",
            "district": "32",
            "Block": "363",
            "label": "MMS,DEVANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5534",
            "district": "32",
            "Block": "250",
            "label": "PUMS MARUTHURAYAN VALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5563",
            "district": "32",
            "Block": "371",
            "label": "MMS KATCHERI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5713",
            "district": "32",
            "Block": "250",
            "label": "ADW MS KANDIYANKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5772",
            "district": "32",
            "Block": "32",
            "label": "PUMS SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5817",
            "district": "32",
            "Block": "250",
            "label": "PUMS VELLANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5885",
            "district": "32",
            "Block": "32",
            "label": "PUMS GANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5910",
            "district": "32",
            "Block": "371",
            "label": "PUMS PARTHASARATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5917",
            "district": "32",
            "Block": "363",
            "label": "MMS PAPPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5935",
            "district": "32",
            "Block": "250",
            "label": "PUMS E.VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5943",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,RASATHAVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6084",
            "district": "32",
            "Block": "392",
            "label": "A.N.V. VIDHYALAYA  MATRIC  SCHOOL,VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6223",
            "district": "32",
            "Block": "363",
            "label": "PUMS, AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6366",
            "district": "32",
            "Block": "371",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DHALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6446",
            "district": "32",
            "Block": "392",
            "label": "PUMS SUBRAMANIA GOUNDANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6596",
            "district": "32",
            "Block": "32",
            "label": "PUMS AVINASHILINGAM PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6650",
            "district": "32",
            "Block": "371",
            "label": "U.K.P.M MATRICULATION SCHOOL UDUMALPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6659",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,THEETHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6662",
            "district": "32",
            "Block": "32",
            "label": "PUMS ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12104",
            "district": "32",
            "Block": "57",
            "label": "GHSS ALANGIAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12105",
            "district": "32",
            "Block": "57",
            "label": "MMS CHITRAVUTHANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12106",
            "district": "32",
            "Block": "57",
            "label": "PUMS S.KANGAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12107",
            "district": "32",
            "Block": "57",
            "label": "PUMS CHIKKINAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12108",
            "district": "32",
            "Block": "57",
            "label": "PUMS C.KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12109",
            "district": "32",
            "Block": "57",
            "label": "GHS SELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12110",
            "district": "32",
            "Block": "57",
            "label": "PUMS D.KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12111",
            "district": "32",
            "Block": "57",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12112",
            "district": "32",
            "Block": "57",
            "label": "GHSS DHALAVAIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12113",
            "district": "32",
            "Block": "57",
            "label": "ST ANTONYS MIDDLE SCHOOL DHARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12114",
            "district": "32",
            "Block": "57",
            "label": "MMS OPS DHARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12115",
            "district": "32",
            "Block": "57",
            "label": "GHSS DHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12116",
            "district": "32",
            "Block": "57",
            "label": "ALOYSIUS GIRLS HSS DHARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12117",
            "district": "32",
            "Block": "57",
            "label": "CSI GIRLS HSS DHARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12118",
            "district": "32",
            "Block": "57",
            "label": "MMS VALAYAKARA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12119",
            "district": "32",
            "Block": "57",
            "label": "NCP MHSS DHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12120",
            "district": "32",
            "Block": "57",
            "label": "CSI BOYS HSS DHARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12121",
            "district": "32",
            "Block": "57",
            "label": "PONNU GIRLS HS DHARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12122",
            "district": "32",
            "Block": "57",
            "label": "THENMALAR HSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12123",
            "district": "32",
            "Block": "57",
            "label": "SAKTHI MATRICULATION SCHOOL DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12124",
            "district": "32",
            "Block": "57",
            "label": "PUMS PERIYAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12125",
            "district": "32",
            "Block": "57",
            "label": "PUMS GOVINDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12126",
            "district": "32",
            "Block": "57",
            "label": "MSN HSS GOVINDAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12127",
            "district": "32",
            "Block": "57",
            "label": "PUMS GANDHIJI NAGAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12128",
            "district": "32",
            "Block": "57",
            "label": "PUMS KALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12129",
            "district": "32",
            "Block": "57",
            "label": "GHSS KOLATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12130",
            "district": "32",
            "Block": "57",
            "label": "INDHIRA GANDHI MHSS , KOLATHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12131",
            "district": "32",
            "Block": "57",
            "label": "DR NALLINI HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12132",
            "district": "32",
            "Block": "57",
            "label": "MUNICIPAL MIDDLE SCHOOL, KOLINJIWADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12133",
            "district": "32",
            "Block": "57",
            "label": "GHS THERPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12134",
            "district": "32",
            "Block": "57",
            "label": "GHS NANJIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12135",
            "district": "32",
            "Block": "57",
            "label": "PUMS PONNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12136",
            "district": "32",
            "Block": "57",
            "label": "PUMS THOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12137",
            "district": "32",
            "Block": "57",
            "label": "PUMS VEERACHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12204",
            "district": "32",
            "Block": "104",
            "label": "GHS NEIKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12205",
            "district": "32",
            "Block": "104",
            "label": "PUMS MARUDURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12206",
            "district": "32",
            "Block": "104",
            "label": "T A M M GHSS NATHAKKADAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12207",
            "district": "32",
            "Block": "104",
            "label": "GHSS PADIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12208",
            "district": "32",
            "Block": "104",
            "label": "PUMS VARATHAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12209",
            "district": "32",
            "Block": "104",
            "label": "PUMS PARANJERVAZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12210",
            "district": "32",
            "Block": "104",
            "label": "PUMS AVINASIPALAYAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12211",
            "district": "32",
            "Block": "104",
            "label": "PUMS POTHIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12212",
            "district": "32",
            "Block": "104",
            "label": "PUMS SIVANMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12213",
            "district": "32",
            "Block": "104",
            "label": "CARMEL GIRLS HIGHER SECONDARY SCHOOL KANGAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12214",
            "district": "32",
            "Block": "104",
            "label": "GHSS KANGAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12215",
            "district": "32",
            "Block": "104",
            "label": "PUMS VEERANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12228",
            "district": "32",
            "Block": "145",
            "label": "GHS BELLAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12229",
            "district": "32",
            "Block": "145",
            "label": "GHS KETHALREV",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12230",
            "district": "32",
            "Block": "145",
            "label": "PUMS ERAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12231",
            "district": "32",
            "Block": "145",
            "label": "GHS SANKARANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12232",
            "district": "32",
            "Block": "145",
            "label": "AHSS SANTHINIKETHAN HSS KULLAMPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12233",
            "district": "32",
            "Block": "145",
            "label": "GHS NALLIMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12234",
            "district": "32",
            "Block": "145",
            "label": "PUMS KANGAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12235",
            "district": "32",
            "Block": "145",
            "label": "GHSS KUNDADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12236",
            "district": "32",
            "Block": "145",
            "label": "VMCDV HSS THAYAMPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12237",
            "district": "32",
            "Block": "145",
            "label": "KGBV THONDAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12268",
            "district": "32",
            "Block": "190",
            "label": "PUMS ERASINAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12269",
            "district": "32",
            "Block": "190",
            "label": "GHS N.C.G.VALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12270",
            "district": "32",
            "Block": "190",
            "label": "GHSS VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12271",
            "district": "32",
            "Block": "190",
            "label": "GHSS KANNIVAADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12272",
            "district": "32",
            "Block": "190",
            "label": "GHS SESAIYANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12273",
            "district": "32",
            "Block": "190",
            "label": "GHSS MOOLANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12274",
            "district": "32",
            "Block": "190",
            "label": "VANJIAMMAN  MATRIC MULANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12275",
            "district": "32",
            "Block": "190",
            "label": "KGBV MULANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12276",
            "district": "32",
            "Block": "190",
            "label": "MODEL HSS MOOLANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23350",
            "district": "32",
            "Block": "32",
            "label": "SAKTHIDEVI MATRIC KANIYAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12277",
            "district": "32",
            "Block": "190",
            "label": "GHSS ELUGAMVALASU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12278",
            "district": "32",
            "Block": "190",
            "label": "GHS NANJAITHALAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12279",
            "district": "32",
            "Block": "190",
            "label": "GHS PERAMIUM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12280",
            "district": "32",
            "Block": "190",
            "label": "GHS CHINNAKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12359",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, UTHUKULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12360",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SENAPATHI CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12361",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VELLIRAVELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12362",
            "district": "32",
            "Block": "378",
            "label": "GHS SARKAR PERIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12363",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NALLIGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12364",
            "district": "32",
            "Block": "378",
            "label": "SMGHSS SURIYAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12365",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12366",
            "district": "32",
            "Block": "378",
            "label": "GHSS SARAVANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12367",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S, LKC NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12368",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,VELLAKOVIL-WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12369",
            "district": "32",
            "Block": "392",
            "label": "A.A. GOVT.HR.SEC. SCHOOL,  VELLAKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12370",
            "district": "32",
            "Block": "392",
            "label": "PUNITHA  AMALA ANNAI  GIRLS HIGH SCHOOL (Aided)",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12371",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,SORIYANKINATHU PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12372",
            "district": "32",
            "Block": "392",
            "label": "G.H.S, DURAI RAMASAMY NAGAR, VELLAKOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12373",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,V.GANAPATHI PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12374",
            "district": "32",
            "Block": "392",
            "label": "VIVEKANANDA VIDYALAYA MATRIC  HR.SEC.SCH, MUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12375",
            "district": "32",
            "Block": "392",
            "label": "GHSS, MUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12376",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S, MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12377",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,OODAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12378",
            "district": "32",
            "Block": "392",
            "label": "GOVT.HIGH.SCHOOL. OLAPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12379",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S, VEERACHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12380",
            "district": "32",
            "Block": "392",
            "label": "GHS LAKKAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12381",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,KAMBALIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12382",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,M.VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12383",
            "district": "32",
            "Block": "392",
            "label": "GOVT.HIGH.SCHOOL, MANGALAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12384",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,NADUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12385",
            "district": "32",
            "Block": "392",
            "label": "GHSS, PUDHUPPAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12386",
            "district": "32",
            "Block": "392",
            "label": "GHSS, UTHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12387",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,VELAPPANAYAKAN VALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12939",
            "district": "32",
            "Block": "73",
            "label": "GHSS GUDIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12940",
            "district": "32",
            "Block": "73",
            "label": "GHSS POOLAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12941",
            "district": "32",
            "Block": "73",
            "label": "GHS V.VELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12942",
            "district": "32",
            "Block": "73",
            "label": "GHSS RAMACHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12943",
            "district": "32",
            "Block": "73",
            "label": "GHS KONGALNAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12944",
            "district": "32",
            "Block": "73",
            "label": "GHSS PEDAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12969",
            "district": "32",
            "Block": "154",
            "label": "GHSS MADATHUKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12970",
            "district": "32",
            "Block": "154",
            "label": "NATIONAL MATRICULATION HR .SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12971",
            "district": "32",
            "Block": "154",
            "label": "GHS SK PUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12972",
            "district": "32",
            "Block": "154",
            "label": "JSR HSS MADATHUKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12973",
            "district": "32",
            "Block": "154",
            "label": "GHS SOLAMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12974",
            "district": "32",
            "Block": "154",
            "label": "SRI VENKATA KRISHNA HSS,KANIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12975",
            "district": "32",
            "Block": "154",
            "label": "GHSS KARATHOLUVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12976",
            "district": "32",
            "Block": "154",
            "label": "GHS THUNGAVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12977",
            "district": "32",
            "Block": "154",
            "label": "ANUGRAHA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12978",
            "district": "32",
            "Block": "154",
            "label": "VIVEKANANDA VIDYALAYAM HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12979",
            "district": "32",
            "Block": "154",
            "label": "GHS PAPPANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12980",
            "district": "32",
            "Block": "154",
            "label": "PUMS,PERUMALPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12981",
            "district": "32",
            "Block": "154",
            "label": "GHSS KOMARALINGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12982",
            "district": "32",
            "Block": "154",
            "label": "OM SAKTHI Hr Sec School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12983",
            "district": "32",
            "Block": "154",
            "label": "GHS KUPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12984",
            "district": "32",
            "Block": "154",
            "label": "GHS KADATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13021",
            "district": "32",
            "Block": "371",
            "label": "NARAYANASAMY GHSS PERIYAVALAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13022",
            "district": "32",
            "Block": "371",
            "label": "PUMS CHINNAPOOLANKINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13023",
            "district": "32",
            "Block": "371",
            "label": "PUMS BODIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13024",
            "district": "32",
            "Block": "371",
            "label": "VAV INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13025",
            "district": "32",
            "Block": "371",
            "label": "GKN HSS PUNGAMUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13026",
            "district": "32",
            "Block": "371",
            "label": "GOVERNMENT HIGH SCHOOL THIRUMOORTHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13027",
            "district": "32",
            "Block": "371",
            "label": "GHSS JALLIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13028",
            "district": "32",
            "Block": "371",
            "label": "PUMS PALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13029",
            "district": "32",
            "Block": "371",
            "label": "PUMS,MANUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13030",
            "district": "32",
            "Block": "371",
            "label": "GHS,MALAIYANDIPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13031",
            "district": "32",
            "Block": "371",
            "label": "PON NAAVARASU MATRIC SCHOOL, MALAIYANDIGOUNDANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13032",
            "district": "32",
            "Block": "371",
            "label": "PUMS,THUMBALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13033",
            "district": "32",
            "Block": "371",
            "label": "GHSS RAJENDRA ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13034",
            "district": "32",
            "Block": "371",
            "label": "GBHSS UDUMALPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13035",
            "district": "32",
            "Block": "371",
            "label": "BCGGHSS UDUMALPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13036",
            "district": "32",
            "Block": "371",
            "label": "SVG HSS UDUMALPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13037",
            "district": "32",
            "Block": "371",
            "label": "S.K.P.HSS UDUMALPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13038",
            "district": "32",
            "Block": "371",
            "label": "RKR HSS UDUMALPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13039",
            "district": "32",
            "Block": "371",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13040",
            "district": "32",
            "Block": "371",
            "label": "GOVT HIGH SCHOOL, SIVASAKTHI COLONY UDUMALPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13041",
            "district": "32",
            "Block": "371",
            "label": "PMGHSS PULANKINAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13042",
            "district": "32",
            "Block": "371",
            "label": "GGHSS DEVANURPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13043",
            "district": "32",
            "Block": "371",
            "label": "GHSS UDUKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13044",
            "district": "32",
            "Block": "371",
            "label": "SRI VENKATESWARA MAT SCHOOL  MANUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13045",
            "district": "32",
            "Block": "371",
            "label": "SAINIK SCHOOL AMARAVATHI NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13046",
            "district": "32",
            "Block": "371",
            "label": "GHS AMARAVATHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13047",
            "district": "32",
            "Block": "371",
            "label": "GHS KALLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13048",
            "district": "32",
            "Block": "371",
            "label": "SNVGHSS ELAYAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13049",
            "district": "32",
            "Block": "371",
            "label": "RVG HSS KURICHIKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13050",
            "district": "32",
            "Block": "371",
            "label": "SRINIVASA VIDHYALAYA AIDED MIDDLE SCHOOL GANAPATHIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13095",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL SALAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13096",
            "district": "32",
            "Block": "32",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  CHEYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13097",
            "district": "32",
            "Block": "32",
            "label": "GHSS KANUR PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13098",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMBAGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13099",
            "district": "32",
            "Block": "32",
            "label": "GHSS KARUVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13100",
            "district": "32",
            "Block": "32",
            "label": "GOVERNMENT HIGH SCHOOL , THIRUMALAIGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13101",
            "district": "32",
            "Block": "32",
            "label": "GHS KARUKKANKATTU PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13102",
            "district": "32",
            "Block": "32",
            "label": "PUMS NAMBIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13103",
            "district": "32",
            "Block": "32",
            "label": "AVANASHI KALVI NILAYAM HS  M.NADHAMPALAYM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13104",
            "district": "32",
            "Block": "32",
            "label": "PUMS AYEEGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13105",
            "district": "32",
            "Block": "32",
            "label": "GHSS PERIYAYEEPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13106",
            "district": "32",
            "Block": "32",
            "label": "GHS PANCHALINGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13107",
            "district": "32",
            "Block": "32",
            "label": "GHS POTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13108",
            "district": "32",
            "Block": "32",
            "label": "PUMS MONDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13109",
            "district": "32",
            "Block": "32",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13110",
            "district": "32",
            "Block": "32",
            "label": "SRI SELVANAYAGI MATRICULATION SCHOOL , VANJIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13111",
            "district": "32",
            "Block": "32",
            "label": "GOVERNMENT HIGH SCHOOL VANJIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13112",
            "district": "32",
            "Block": "32",
            "label": "PUMS NARIYAMPALLIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13113",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENNIMALAI GOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13114",
            "district": "32",
            "Block": "32",
            "label": "KONGU KALVI NILAYAM HIGHER  SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13115",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THANDUKKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13116",
            "district": "32",
            "Block": "32",
            "label": "PUMS SULLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13117",
            "district": "32",
            "Block": "32",
            "label": "PUMS THIMMANAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13118",
            "district": "32",
            "Block": "32",
            "label": "GHSS THEKKALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13119",
            "district": "32",
            "Block": "32",
            "label": "PUMS THULUKAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13120",
            "district": "32",
            "Block": "32",
            "label": "PUMS KARUNAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13121",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13122",
            "district": "32",
            "Block": "32",
            "label": "PUMS KASIGOUNDANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13123",
            "district": "32",
            "Block": "32",
            "label": "PUMS A.KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13124",
            "district": "32",
            "Block": "32",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13125",
            "district": "32",
            "Block": "32",
            "label": "GOVT  HIGH SCHOOL RAKKIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13126",
            "district": "32",
            "Block": "32",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL AVINASHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13127",
            "district": "32",
            "Block": "32",
            "label": "ST THOMAS GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13128",
            "district": "32",
            "Block": "32",
            "label": "GOVT. BOYS HR SEC SCHOOL AVINASHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13129",
            "district": "32",
            "Block": "32",
            "label": "RAYAR KALVI NILAYAM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13130",
            "district": "32",
            "Block": "32",
            "label": "NANJAPPA NIKETHAN THANNEERPANDHALCOLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13174",
            "district": "32",
            "Block": "223",
            "label": "SCAD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13175",
            "district": "32",
            "Block": "223",
            "label": "GHSS GANAPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13176",
            "district": "32",
            "Block": "223",
            "label": "S L N M HR SEC SCHOOL KARADIVAVI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13177",
            "district": "32",
            "Block": "223",
            "label": "GHSS ARULPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13178",
            "district": "32",
            "Block": "223",
            "label": "GHS KARANAMPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13179",
            "district": "32",
            "Block": "223",
            "label": "GHS KODANGIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13180",
            "district": "32",
            "Block": "223",
            "label": "GHS KAMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13181",
            "district": "32",
            "Block": "223",
            "label": "GHS SEGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13182",
            "district": "32",
            "Block": "223",
            "label": "GHS ARIVOLINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13183",
            "district": "32",
            "Block": "223",
            "label": "GOVERNMENT  GIRLS HIGHER SECONDARY SCHOOL PALLADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13184",
            "district": "32",
            "Block": "223",
            "label": "GOVT BOYS HR SEC SCHOOL PALLADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13185",
            "district": "32",
            "Block": "223",
            "label": "GHS P.VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13186",
            "district": "32",
            "Block": "223",
            "label": "VALLALAR GURUKULAM HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13187",
            "district": "32",
            "Block": "223",
            "label": "GHS POOMALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13188",
            "district": "32",
            "Block": "223",
            "label": "GHS NADUVELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13189",
            "district": "32",
            "Block": "223",
            "label": "MOUNT LITERA ZEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13190",
            "district": "32",
            "Block": "223",
            "label": "A V A T HR SEC SCHOOL SAMALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13191",
            "district": "32",
            "Block": "223",
            "label": "GHS KARUGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13192",
            "district": "32",
            "Block": "223",
            "label": "GHSS SAMIGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13193",
            "district": "32",
            "Block": "223",
            "label": "GHS 63 VELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13194",
            "district": "32",
            "Block": "250",
            "label": "GHSS KODUVAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13195",
            "district": "32",
            "Block": "250",
            "label": "SRI ALAGUMALAI VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13196",
            "district": "32",
            "Block": "250",
            "label": "GHSS V.KALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13197",
            "district": "32",
            "Block": "250",
            "label": "GHS VALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13198",
            "district": "32",
            "Block": "250",
            "label": "GHS KATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13199",
            "district": "32",
            "Block": "250",
            "label": "GHSS KETHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13200",
            "district": "32",
            "Block": "250",
            "label": "GEM INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13201",
            "district": "32",
            "Block": "250",
            "label": "P V K N HR SEC SCHOOL PONGALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13202",
            "district": "32",
            "Block": "250",
            "label": "GHSS PERUNTHOLUVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13203",
            "district": "32",
            "Block": "250",
            "label": "GHSS POLLIKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13204",
            "district": "32",
            "Block": "250",
            "label": "LM MATRICULATION SCHOOL, KULLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13253",
            "district": "32",
            "Block": "363",
            "label": "AVP TRUST NATIONAL MATRIC HR SEC SCHOOL, ATHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13254",
            "district": "32",
            "Block": "363",
            "label": "GHSS, AYYANKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13255",
            "district": "32",
            "Block": "363",
            "label": "PUMS,ETTIVEERAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13256",
            "district": "32",
            "Block": "363",
            "label": "GHS, IDUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13257",
            "district": "32",
            "Block": "363",
            "label": "GHS GURUVAYURAPPAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13258",
            "district": "32",
            "Block": "363",
            "label": "SIVA SAKTHI MHSS,TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13259",
            "district": "32",
            "Block": "363",
            "label": "SAKTHI VIGNESWARA KALVI NILAYAM HR SEC SCHOOL, PONGUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13260",
            "district": "32",
            "Block": "363",
            "label": "GHSS KANAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13261",
            "district": "32",
            "Block": "363",
            "label": "PUMS SOKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13262",
            "district": "32",
            "Block": "363",
            "label": "PUMS,THORAVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13263",
            "district": "32",
            "Block": "363",
            "label": "G BOYS HSS PERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13264",
            "district": "32",
            "Block": "363",
            "label": "AVS CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13265",
            "district": "32",
            "Block": "363",
            "label": "GHS PANDIAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23697",
            "district": "32",
            "Block": "363",
            "label": "JAISAKTHI VIRAJA GURUKULAM (CBSC SCHOOL)",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "13266",
            "district": "32",
            "Block": "363",
            "label": "Murugu Matric Hr.Sec.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13267",
            "district": "32",
            "Block": "363",
            "label": "APS ACADAMY MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13268",
            "district": "32",
            "Block": "363",
            "label": "GHS POOLUVAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13269",
            "district": "32",
            "Block": "363",
            "label": "GHS VAVIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13270",
            "district": "32",
            "Block": "363",
            "label": "PUMS, VALLIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13271",
            "district": "32",
            "Block": "363",
            "label": "TAGORE VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13272",
            "district": "32",
            "Block": "363",
            "label": "GHS PICHAM PALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13273",
            "district": "32",
            "Block": "363",
            "label": "PUMS, RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13274",
            "district": "32",
            "Block": "363",
            "label": "GHSS ANUPPARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13275",
            "district": "32",
            "Block": "363",
            "label": "VETRI VIDYALAYA MATRIC SCHOOL, SANKARAN THOTTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13276",
            "district": "32",
            "Block": "363",
            "label": "SRI THANDAPANI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13277",
            "district": "32",
            "Block": "363",
            "label": "GHSS MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13278",
            "district": "32",
            "Block": "363",
            "label": "PUMS,SUNDAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13279",
            "district": "32",
            "Block": "363",
            "label": "GHSS,IDUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13280",
            "district": "32",
            "Block": "363",
            "label": "GHSS,VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13281",
            "district": "32",
            "Block": "363",
            "label": "GHS KARUPPAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13282",
            "district": "32",
            "Block": "363",
            "label": "PUMS,MUTHANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13283",
            "district": "32",
            "Block": "363",
            "label": "Vivekananda Vidyalaya Matric Hr. Sec, K.Chettipalayam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13284",
            "district": "32",
            "Block": "363",
            "label": "ADW HS,KOVILVAZHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13285",
            "district": "32",
            "Block": "363",
            "label": "GHS K.CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13286",
            "district": "32",
            "Block": "363",
            "label": "PUMS, GENGANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13287",
            "district": "32",
            "Block": "363",
            "label": "GHS MUDHALIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13288",
            "district": "32",
            "Block": "363",
            "label": "ABACUS INT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13289",
            "district": "32",
            "Block": "363",
            "label": "GHSS,VIJAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13290",
            "district": "32",
            "Block": "363",
            "label": "BISHOP UBAGARASWAMY HIGHER SECONDARY SCHOOL, TIRUPPUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13291",
            "district": "32",
            "Block": "363",
            "label": "SHRI MAKESH VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13292",
            "district": "32",
            "Block": "363",
            "label": "MHSS,KUMAR NAGAR,TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13293",
            "district": "32",
            "Block": "363",
            "label": "MHS,NESAWALAR COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13294",
            "district": "32",
            "Block": "363",
            "label": "MHSS,NEW RAMAKRISHNAPURAM, TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13295",
            "district": "32",
            "Block": "363",
            "label": "CHINNASAMY AMMAL BOYS MHSS,TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13296",
            "district": "32",
            "Block": "363",
            "label": "ST.S JOSEPH'S HIGH SCHOOL, KUMARAN ROAD,TIRUPPUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13297",
            "district": "32",
            "Block": "363",
            "label": "PREMA MAT HSS, COLLEGE ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13298",
            "district": "32",
            "Block": "363",
            "label": "KAIRALI VIDHYALAYAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13299",
            "district": "32",
            "Block": "363",
            "label": "JAIVABAI MGHSS -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13300",
            "district": "32",
            "Block": "363",
            "label": "NANJAPPA MPL BOYS HR SEC SCHOOL TIRUPPUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13301",
            "district": "32",
            "Block": "363",
            "label": "ROTARY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13302",
            "district": "32",
            "Block": "363",
            "label": "MMS,Parappalayam,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13303",
            "district": "32",
            "Block": "363",
            "label": "MHS, KARUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13304",
            "district": "32",
            "Block": "363",
            "label": "KSC GOVT HR SEC SCHOOL TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13305",
            "district": "32",
            "Block": "363",
            "label": "M N MURUGAPPA CHETTIYAR GHSS, TIRUPPUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13306",
            "district": "32",
            "Block": "363",
            "label": "MUNICIPAL HIGH SCHOOL,NOYYAL STREET,TIRUPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13307",
            "district": "32",
            "Block": "363",
            "label": "AL-AMEEN HIGH SCHOOL, KANGEYAM ROAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20886",
            "district": "32",
            "Block": "57",
            "label": "AGARAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20915",
            "district": "32",
            "Block": "392",
            "label": "NAVA INTERNATIONAL SCHOOL(CBSE), MUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20965",
            "district": "32",
            "Block": "57",
            "label": "BETHANIYA SPECIAL SCHOOL FOR M.R CHILDREN",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "20969",
            "district": "32",
            "Block": "73",
            "label": "N.V.MATRIC HR,SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20970",
            "district": "32",
            "Block": "371",
            "label": "SHAANTHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20971",
            "district": "32",
            "Block": "32",
            "label": "SRI HAYAGREVAR VIDYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21073",
            "district": "32",
            "Block": "57",
            "label": "AUROBINDO VIDHYALAYA CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21074",
            "district": "32",
            "Block": "57",
            "label": "GREENFIELD INTERNATIONAL SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21077",
            "district": "32",
            "Block": "392",
            "label": "SATHYAM INTERNATIONAL SCHOOL(CBSE), S G VALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21084",
            "district": "32",
            "Block": "371",
            "label": "SRINIVASA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21085",
            "district": "32",
            "Block": "32",
            "label": "VIVEGAA SCHOOL(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21086",
            "district": "32",
            "Block": "32",
            "label": "SPRING MOUNT PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21088",
            "district": "32",
            "Block": "363",
            "label": "KIDS CLUB SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21152",
            "district": "32",
            "Block": "104",
            "label": "SWAMI VIVEKANANDA MATRIC HR SEC SCHOOL, KANGEYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21154",
            "district": "32",
            "Block": "371",
            "label": "SRI SRISTI VIKKAS ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL , PARTHASARATHYPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21155",
            "district": "32",
            "Block": "371",
            "label": "SREE  ADHARSH  MATRICULATION  HR.SEC. SCHOOL, PALLAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21157",
            "district": "32",
            "Block": "223",
            "label": "THIRU JANARTHANA MATRIC HR SEC SCHOOL, KARADIVAVI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21158",
            "district": "32",
            "Block": "363",
            "label": "DHANAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21159",
            "district": "32",
            "Block": "363",
            "label": "EDEN GARDENS MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21184",
            "district": "32",
            "Block": "363",
            "label": "LITTLE KINGDOM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21187",
            "district": "32",
            "Block": "223",
            "label": "THE INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21217",
            "district": "32",
            "Block": "145",
            "label": "GMHSS, KUNDADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21228",
            "district": "32",
            "Block": "57",
            "label": "VEVEAHAM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21245",
            "district": "32",
            "Block": "32",
            "label": "SRI SHAKTHI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21246",
            "district": "32",
            "Block": "363",
            "label": "VIDHYASAGAR INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21269",
            "district": "32",
            "Block": "145",
            "label": "MERIT MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21273",
            "district": "32",
            "Block": "371",
            "label": "RKR GNANODHAYA MATRIC HSS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21274",
            "district": "32",
            "Block": "32",
            "label": "SRI NACHAMMAL VIDYAVANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21275",
            "district": "32",
            "Block": "223",
            "label": "RAJA NATIONAL MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21276",
            "district": "32",
            "Block": "363",
            "label": "MARUTHI EXCEL MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21277",
            "district": "32",
            "Block": "363",
            "label": "MANI PUBLIC ACADEMY MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21309",
            "district": "32",
            "Block": "104",
            "label": "KOVAI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21310",
            "district": "32",
            "Block": "378",
            "label": "VEDANTA ACADEMY,UTHUKULI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21311",
            "district": "32",
            "Block": "378",
            "label": "SRI KUMARAN PUBLIC SENIOR SECONDARY SCHOOL, SENGAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21317",
            "district": "32",
            "Block": "32",
            "label": "THE EARNEST ACADEMY,PAZHANKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21319",
            "district": "32",
            "Block": "363",
            "label": "SMART MODERN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21320",
            "district": "32",
            "Block": "363",
            "label": "FRONTLINE MILLENNIUM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21321",
            "district": "32",
            "Block": "363",
            "label": "A.V.P. TRUST PUBLIC SENIOR SECONDARY SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21362",
            "district": "32",
            "Block": "363",
            "label": "GHS BOMMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21363",
            "district": "32",
            "Block": "363",
            "label": "MHS KUMARANANDAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21463",
            "district": "32",
            "Block": "57",
            "label": "GHS,CHINNAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21464",
            "district": "32",
            "Block": "57",
            "label": "GHS,MANAKKADAVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21465",
            "district": "32",
            "Block": "57",
            "label": "GHS, DHASARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21466",
            "district": "32",
            "Block": "57",
            "label": "GHS, POTTIKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21546",
            "district": "32",
            "Block": "32",
            "label": "SRI RAGHAVENDRA VIDYALAYA HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21547",
            "district": "32",
            "Block": "250",
            "label": "THAITAMIL HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21566",
            "district": "32",
            "Block": "363",
            "label": "MHSS PADMAVATHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21599",
            "district": "32",
            "Block": "154",
            "label": "JSR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21633",
            "district": "32",
            "Block": "57",
            "label": "VEVEAHAM PRIME ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21635",
            "district": "32",
            "Block": "223",
            "label": "YOUNG INDIA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21795",
            "district": "32",
            "Block": "250",
            "label": "ANNAL SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "21796",
            "district": "32",
            "Block": "363",
            "label": "AVP TRUST MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21797",
            "district": "32",
            "Block": "363",
            "label": "GLOBAL DISCOVERY ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21798",
            "district": "32",
            "Block": "363",
            "label": "BRIGHT PUBLIC MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21888",
            "district": "32",
            "Block": "32",
            "label": "SAKTHI VIDHYALAYAA MATRICULATION SCHOOL, SELLAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21947",
            "district": "32",
            "Block": "223",
            "label": "ADHARSH VIDHYALAYA MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21979",
            "district": "32",
            "Block": "392",
            "label": "SRI AANOOR VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, VAIKALMETTUPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22130",
            "district": "32",
            "Block": "378",
            "label": "SRI VETRI VINAYAGA MATRIC SCHOOL, PULANGULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22131",
            "district": "32",
            "Block": "378",
            "label": "AALAYA  ACADEMY, SARKAR PERIYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22144",
            "district": "32",
            "Block": "73",
            "label": "SRI ARUNGARAIAMMAN NALLATHAAI VIDHYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22145",
            "district": "32",
            "Block": "154",
            "label": "UNITED PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22146",
            "district": "32",
            "Block": "371",
            "label": "GOVT ADHIDRAVIDAR WELFARE HIGH SCHOOL AMMAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22148",
            "district": "32",
            "Block": "32",
            "label": "VEDANTA ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22149",
            "district": "32",
            "Block": "32",
            "label": "THE QUEST INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22150",
            "district": "32",
            "Block": "32",
            "label": "BHARATHI VIDHYAA KENDHRA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22151",
            "district": "32",
            "Block": "32",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Avinashi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22152",
            "district": "32",
            "Block": "223",
            "label": "JAYANTHI PUBLIC SENIOR SECONDARY SCHOOL ,ARULPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22153",
            "district": "32",
            "Block": "223",
            "label": "ADHARSH VIDHYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22154",
            "district": "32",
            "Block": "223",
            "label": "SHIVA NIKETAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22155",
            "district": "32",
            "Block": "250",
            "label": "GREEN PARK SMART SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22162",
            "district": "32",
            "Block": "363",
            "label": "MGV GLOBAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22163",
            "district": "32",
            "Block": "363",
            "label": "SRI JAI SARADHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22164",
            "district": "32",
            "Block": "363",
            "label": "SAI KIRUPA SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "22165",
            "district": "32",
            "Block": "363",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23399",
            "district": "32",
            "Block": "250",
            "label": "Sri Ramakrishna Vidhyalaya Matric HR.sec school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22166",
            "district": "32",
            "Block": "363",
            "label": "DKT GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22167",
            "district": "32",
            "Block": "363",
            "label": "KIDS PARK ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22168",
            "district": "32",
            "Block": "363",
            "label": "BHARATHI VIDHYA BHAVAN SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22169",
            "district": "32",
            "Block": "363",
            "label": "BHARATHI VIDHYASRAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "22170",
            "district": "32",
            "Block": "363",
            "label": "SUBBIAH CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22171",
            "district": "32",
            "Block": "363",
            "label": "SHARANALAYA INTERNATIONAL MONTESSORI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22172",
            "district": "32",
            "Block": "363",
            "label": "KIDS CLUB INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22458",
            "district": "32",
            "Block": "57",
            "label": "PRAKRUTHI PUBLIC SCHOOL, PANCHAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22459",
            "district": "32",
            "Block": "57",
            "label": "GOVERNMENT HIGH SCHOOL, AACHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22460",
            "district": "32",
            "Block": "57",
            "label": "SRI KRISHNA VIDHYALAYA SCHOOL, SAKTHI NAGAR,DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22464",
            "district": "32",
            "Block": "104",
            "label": "GHS KEERANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22465",
            "district": "32",
            "Block": "104",
            "label": "GHS THAMMAREDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22470",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT HIGH SCHOOL, KARUMANCHIRAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22485",
            "district": "32",
            "Block": "73",
            "label": "THE INDIAN MODEL SCHOOL, KOTTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22486",
            "district": "32",
            "Block": "371",
            "label": "SHRI VENKATESHWARA VIDYALAYA DEVANURPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22490",
            "district": "32",
            "Block": "223",
            "label": "GHS KALINATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22491",
            "district": "32",
            "Block": "250",
            "label": "ARUDHRA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22493",
            "district": "32",
            "Block": "363",
            "label": "VRIKSHAA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22494",
            "district": "32",
            "Block": "363",
            "label": "MHS K.V.R NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22495",
            "district": "32",
            "Block": "363",
            "label": "THE HOME SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22821",
            "district": "32",
            "Block": "363",
            "label": "PUMS HOUSING UNIT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22822",
            "district": "32",
            "Block": "250",
            "label": "JAI SHRIRAM ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL, AVINASHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22836",
            "district": "32",
            "Block": "371",
            "label": "AMAZON PUBLIC SCHOOL-UDUMALPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22838",
            "district": "32",
            "Block": "363",
            "label": "DREAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22839",
            "district": "32",
            "Block": "363",
            "label": "VIKAS VIDYALAYA JUNIORS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22840",
            "district": "32",
            "Block": "363",
            "label": "MALAR ACE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22841",
            "district": "32",
            "Block": "363",
            "label": "S.K.V.MATRICULATION SCHOOL,MANNARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22901",
            "district": "32",
            "Block": "223",
            "label": "KEY STAGE SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22918",
            "district": "32",
            "Block": "223",
            "label": "GHS ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22927",
            "district": "32",
            "Block": "363",
            "label": "GHS MASCO NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22928",
            "district": "32",
            "Block": "363",
            "label": "G GIRLS HSS PERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22934",
            "district": "32",
            "Block": "73",
            "label": "GHS Pukkulam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22952",
            "district": "32",
            "Block": "104",
            "label": "GHS PERIYAILLIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22958",
            "district": "32",
            "Block": "57",
            "label": "VEVEAHAM MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23032",
            "district": "32",
            "Block": "104",
            "label": "GLOBAL INTERNATIONAL SCHOOL, KANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23077",
            "district": "32",
            "Block": "363",
            "label": "CENTURY FOUNDATION PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23115",
            "district": "32",
            "Block": "371",
            "label": "KENDRIYA VIDYALAYA , UDUMALPET",
            "Sch_type": "Central Govt",
            "Category": "Middle School"
        },
        {
            "key": "23116",
            "district": "32",
            "Block": "32",
            "label": "SELLANDIAMMAN HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23150",
            "district": "32",
            "Block": "223",
            "label": "WHITE CLOUDS WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23151",
            "district": "32",
            "Block": "363",
            "label": "ADVAITA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23169",
            "district": "32",
            "Block": "154",
            "label": "BHARATHI VIDHYA MANDIR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23213",
            "district": "32",
            "Block": "32",
            "label": "SHRI CHENDOOR VIDHYALAYA MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23242",
            "district": "32",
            "Block": "363",
            "label": "GOVERNMENT HIGH SCHOOL , PERICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23243",
            "district": "32",
            "Block": "363",
            "label": "GOVERNMENT HIGH SCHOOL , MANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23244",
            "district": "32",
            "Block": "73",
            "label": "GOVERNMENT HIGH SCHOOL , A.AMMAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23245",
            "district": "32",
            "Block": "73",
            "label": "GOVERNMENT HIGH SCHOOL , PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23262",
            "district": "32",
            "Block": "363",
            "label": "NACHIYAR PADASALA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23301",
            "district": "32",
            "Block": "363",
            "label": "EDMUNDS MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23325",
            "district": "32",
            "Block": "190",
            "label": "VANJIAMMAN VIDYA VIKAS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23415",
            "district": "32",
            "Block": "145",
            "label": "Shree Venaudaiyar Vidhyalaya School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23419",
            "district": "32",
            "Block": "363",
            "label": "SRI KUMARAN MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23594",
            "district": "32",
            "Block": "32",
            "label": "THE SCV CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23595",
            "district": "32",
            "Block": "32",
            "label": "PALANIAPPA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23596",
            "district": "32",
            "Block": "223",
            "label": "VENKATESHWHARA VIDHYA MANDHIR SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23597",
            "district": "32",
            "Block": "363",
            "label": "Majestic Convent Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23598",
            "district": "32",
            "Block": "363",
            "label": "T.N.K. VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23599",
            "district": "32",
            "Block": "363",
            "label": "Frontline NewGen International School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23600",
            "district": "32",
            "Block": "392",
            "label": "Saraswathi Vidhya Mandhir Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23736",
            "district": "32",
            "Block": "32",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23737",
            "district": "32",
            "Block": "32",
            "label": "PUMS SULLIPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23738",
            "district": "32",
            "Block": "32",
            "label": "PUMS SAMATHUVAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23739",
            "district": "32",
            "Block": "32",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23740",
            "district": "32",
            "Block": "32",
            "label": "VEDANTA ACADEMY",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23767",
            "district": "32",
            "Block": "57",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23768",
            "district": "32",
            "Block": "57",
            "label": "PUMS THOPPAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23785",
            "district": "32",
            "Block": "73",
            "label": "RG International Public School",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23807",
            "district": "32",
            "Block": "104",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23808",
            "district": "32",
            "Block": "104",
            "label": "PUMS VEERANAMPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23809",
            "district": "32",
            "Block": "104",
            "label": "GHS KEERANUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23850",
            "district": "32",
            "Block": "145",
            "label": "SRI GURUJI VIDYA MANDHIR MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23882",
            "district": "32",
            "Block": "190",
            "label": "ST. MARY'S MATRICULATION SCHOOL, MALLAMPALAYAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23904",
            "district": "32",
            "Block": "223",
            "label": "INFANT JESUS PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23928",
            "district": "32",
            "Block": "250",
            "label": "PUMS VAVIPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24037",
            "district": "32",
            "Block": "363",
            "label": "PUMS SOKKANUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24038",
            "district": "32",
            "Block": "363",
            "label": "GHSS MANGALAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24039",
            "district": "32",
            "Block": "363",
            "label": "PUMS, NALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24040",
            "district": "32",
            "Block": "363",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24041",
            "district": "32",
            "Block": "363",
            "label": "PUMS HOUSING UNIT",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24042",
            "district": "32",
            "Block": "363",
            "label": "LITTLE STAR MATRICULATION SCHOOL, K.V.R NAGAR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24048",
            "district": "32",
            "Block": "371",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24049",
            "district": "32",
            "Block": "371",
            "label": "Tiruppur District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "33": [
        {
            "key": "372",
            "district": "33",
            "Block": "403",
            "label": "VIJAYANTHA SR.SEC., AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23653",
            "district": "33",
            "Block": "261",
            "label": "Saraswathi Vidyalaya Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "385",
            "district": "33",
            "Block": "364",
            "label": "PUMS - KRISHNA SAMUTHRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "388",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANAIKATTUCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "389",
            "district": "33",
            "Block": "252",
            "label": "SRI SATHYA MATRICULATION SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "391",
            "district": "33",
            "Block": "403",
            "label": "PUMS,POOTHAPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "393",
            "district": "33",
            "Block": "184",
            "label": "BSS SWAMY PRANAVANANDA VIDHYA MANDHIR,MINJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "398",
            "district": "33",
            "Block": "260",
            "label": "NEHRU MATRIC, HSC, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "401",
            "district": "33",
            "Block": "403",
            "label": "EBENEZAR MODERN MATRICULATION. SCHOOL, THIRUMULLAIVOYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "402",
            "district": "33",
            "Block": "252",
            "label": "DHAYA MATRICULATION SCHOOL,AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "403",
            "district": "33",
            "Block": "261",
            "label": "SARASWATHI MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "404",
            "district": "33",
            "Block": "403",
            "label": "OUR ANGEL MATRIC HIGHER SECONDARY SCHOOL,VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "405",
            "district": "33",
            "Block": "252",
            "label": "SRI RANI BAI MATRICULATION SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "407",
            "district": "33",
            "Block": "225",
            "label": "PUMS, SAVATUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "408",
            "district": "33",
            "Block": "403",
            "label": "SRI KRISHNAMMAL MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "409",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENEERKUPPAM (NORTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "411",
            "district": "33",
            "Block": "403",
            "label": "SREE MAHA GANESA VIDYASALA MIDDLE SCHOOL. AMBATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "413",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANJAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "414",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KODIVALASAI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "415",
            "district": "33",
            "Block": "260",
            "label": "DR SIVANTHI ADITANAR MATRIC.HR.SEC.SCHOOL,  PUZHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "417",
            "district": "33",
            "Block": "252",
            "label": "TBM MIDDLE SCHOOL ,POONAMALLEE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "419",
            "district": "33",
            "Block": "338",
            "label": "CHINNI SRIRAMALU CHEETY VIVEKANENDA VIDYALAYA  MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "420",
            "district": "33",
            "Block": "252",
            "label": "ST JOSEPH MATRICULATION SCHOOL AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "422",
            "district": "33",
            "Block": "252",
            "label": "SB VIJAYA REDDIAR MATRIC HR SEC SCHOOL, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "423",
            "district": "33",
            "Block": "403",
            "label": "Velammals Memorial Mat Hr Sec School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "424",
            "district": "33",
            "Block": "87",
            "label": "ANNAI SARASWATHI MATRIC HIGHER SECONDARY SCHOOL.,  MANAVALA  NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "425",
            "district": "33",
            "Block": "253",
            "label": "GHS , PLACEPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "427",
            "district": "33",
            "Block": "338",
            "label": "PUMS, AYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "428",
            "district": "33",
            "Block": "225",
            "label": "PUMS, PANDRAVEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "429",
            "district": "33",
            "Block": "289",
            "label": "PUMS,PADIANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "431",
            "district": "33",
            "Block": "364",
            "label": "PUMS - K K NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "435",
            "district": "33",
            "Block": "403",
            "label": "KARTHIKEYAN MAT HSS,MADHURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "437",
            "district": "33",
            "Block": "252",
            "label": "THANGAMANI MATRIC HR SEC SCHOOL, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "438",
            "district": "33",
            "Block": "260",
            "label": "SKP VIV VID, MADHAVARAM WARD 3",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "439",
            "district": "33",
            "Block": "403",
            "label": "SPARTAN MATRIC HIGHER SECONDARY SCHOOL,MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "441",
            "district": "33",
            "Block": "403",
            "label": "PMR MAT SCH, MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "442",
            "district": "33",
            "Block": "252",
            "label": "D. S. SELVAM MATRICULATION SCHOOL AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "446",
            "district": "33",
            "Block": "403",
            "label": "MGR ADARSH MAT, MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "447",
            "district": "33",
            "Block": "252",
            "label": "JAYA JAYA SANKARA INTERNATIONAL SCHOOL NAZARATHPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "448",
            "district": "33",
            "Block": "338",
            "label": "PUMS, ARANVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "449",
            "district": "33",
            "Block": "403",
            "label": "DAV B SS, MUGAPPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "452",
            "district": "33",
            "Block": "252",
            "label": "DANIEL MATRICULATION SCHOOL, POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "453",
            "district": "33",
            "Block": "252",
            "label": "SUNDAR MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "454",
            "district": "33",
            "Block": "252",
            "label": "ST JOHN'S MATRICULATION  SCHOOL MELMANAMBEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "456",
            "district": "33",
            "Block": "403",
            "label": "BHARATHI MAT HSS,MADHURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "457",
            "district": "33",
            "Block": "403",
            "label": "LBEAAR MATRICULATION, HIGHER SECONDARY SCHOOL, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "458",
            "district": "33",
            "Block": "76",
            "label": "RAN MAT SCH KAVARAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "461",
            "district": "33",
            "Block": "403",
            "label": "PUMS, ADAYALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "462",
            "district": "33",
            "Block": "225",
            "label": "VAARIAR MATRIC HR,SEC.SCHOOL,PODATURPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "464",
            "district": "33",
            "Block": "403",
            "label": "EBENEZER MAT KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "465",
            "district": "33",
            "Block": "403",
            "label": "EBENEZER MARCUS MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "466",
            "district": "33",
            "Block": "252",
            "label": "JAYA MATR SCHOOL, THIRUNINRAVUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "467",
            "district": "33",
            "Block": "403",
            "label": "PUMS,CMDA MADURAVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "471",
            "district": "33",
            "Block": "87",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "473",
            "district": "33",
            "Block": "260",
            "label": "NAVA JYOTHI  VIDYALAYA MATRIC.HR.SEC.SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "474",
            "district": "33",
            "Block": "338",
            "label": "MUN. MS, NORTH RAJA ST, TRL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "475",
            "district": "33",
            "Block": "184",
            "label": "BHARATH MATRIC HIGHER SECONDARY SCHOOL,KOKKUMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "476",
            "district": "33",
            "Block": "338",
            "label": "ST. ANNE'S MATRIC HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "477",
            "district": "33",
            "Block": "252",
            "label": "ST ANTHONY MIDDLE SCH, AVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "479",
            "district": "33",
            "Block": "253",
            "label": "JAI MARUTHI VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "480",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUMAZHISAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "482",
            "district": "33",
            "Block": "261",
            "label": "TMKV GOVT BOYS  HIGHER SECONDARY SCHOOL, ,AMMAYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "483",
            "district": "33",
            "Block": "260",
            "label": "ST. Thomas Silver Jubilee Matriculation school. Mathur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "484",
            "district": "33",
            "Block": "403",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NOLAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "487",
            "district": "33",
            "Block": "87",
            "label": "ST PETER MS PINCHIVAKKAM KANDIGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "488",
            "district": "33",
            "Block": "261",
            "label": "PUMS,KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "489",
            "district": "33",
            "Block": "87",
            "label": "PUMS VENGATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "491",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAVALCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "492",
            "district": "33",
            "Block": "87",
            "label": "RCM MID SCH KALLAMBEDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "493",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL MIDDLE SCHOOL ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "494",
            "district": "33",
            "Block": "87",
            "label": "PUMS VALASAIVETTIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "497",
            "district": "33",
            "Block": "184",
            "label": "MODERN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "498",
            "district": "33",
            "Block": "252",
            "label": "JAWAHARLAL NEHRU VIDYALAYA MATRICULATION SCHOOL KUMANANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "499",
            "district": "33",
            "Block": "403",
            "label": "JOSHUA MODEL MAT HSS, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "501",
            "district": "33",
            "Block": "403",
            "label": "ASSISI MAT HSS RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "503",
            "district": "33",
            "Block": "338",
            "label": "MUN. MS, PERIA KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "504",
            "district": "33",
            "Block": "289",
            "label": "KALAIMAGAL MS,PONDAVAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "505",
            "district": "33",
            "Block": "260",
            "label": "Don Bosco Matriculation Higher secondary school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "506",
            "district": "33",
            "Block": "87",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "507",
            "district": "33",
            "Block": "338",
            "label": "RM JAIN VIDHYASHRAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "508",
            "district": "33",
            "Block": "338",
            "label": "PUMS, KARIKALAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "509",
            "district": "33",
            "Block": "87",
            "label": "PUMS VENGATHUR KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "510",
            "district": "33",
            "Block": "253",
            "label": "PUMS, TB PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "511",
            "district": "33",
            "Block": "364",
            "label": "PUMS-SINGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "512",
            "district": "33",
            "Block": "76",
            "label": "PUMS PATHIRVEDU WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "513",
            "district": "33",
            "Block": "261",
            "label": "PUMS,NEELOTH BALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "514",
            "district": "33",
            "Block": "338",
            "label": "PUMS, KALYANA KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "515",
            "district": "33",
            "Block": "338",
            "label": "PUMS, THOZHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "519",
            "district": "33",
            "Block": "87",
            "label": "SUBBA NAIDU MATRICULATION HIGHER SECONDARY SCHOOL,  MANAVALANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "521",
            "district": "33",
            "Block": "403",
            "label": "SREE SARASWATHI MATRICULATION HIGH SCHOOL, KALLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "522",
            "district": "33",
            "Block": "252",
            "label": "JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA,MATRICULATION SCHOOL,KAMARAJ  NAGAR, AVADI, CHENNAI-600071",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "523",
            "district": "33",
            "Block": "403",
            "label": "SRI SARASWATHI VIDHYA MANDIR MATRIC HR SEC SCHOOL, AYANAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "525",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALINJIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "526",
            "district": "33",
            "Block": "403",
            "label": "CS CENTRAL MAT HSS MANNURPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "529",
            "district": "33",
            "Block": "87",
            "label": "ST MARYS MATRICULATION HIGHER SECONDARY SCHOOL IRULANCHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "530",
            "district": "33",
            "Block": "364",
            "label": "DR VGS NAIDU MATRIC HR SEC SCHOOL -TIRUTTANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "531",
            "district": "33",
            "Block": "87",
            "label": "JACOB MATRIC  SCHOOL.  VENGATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "532",
            "district": "33",
            "Block": "64",
            "label": "SRI LAKSHMI VITHYALAYA MATRIC HR SEC SCHOOL, LACHIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "533",
            "district": "33",
            "Block": "260",
            "label": "ST JOSEPH MATRIC SCHOOL, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "535",
            "district": "33",
            "Block": "225",
            "label": "PUMS, RANGANATHAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "537",
            "district": "33",
            "Block": "76",
            "label": "PUMS - NOCHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "539",
            "district": "33",
            "Block": "252",
            "label": "DAV MATRIC HR SEC SCHOOL , AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "541",
            "district": "33",
            "Block": "64",
            "label": "ST. JOSEPH MATRICULATION SCHOOL, PERIYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "543",
            "district": "33",
            "Block": "225",
            "label": "ST MARY'S MATRICULATION  HIGHER SECONDARY SCHOOL, PALLIPAT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "545",
            "district": "33",
            "Block": "403",
            "label": "ST MARYS MAT SC - KARAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "548",
            "district": "33",
            "Block": "403",
            "label": "PIONEER MAT, VELLAPPANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "550",
            "district": "33",
            "Block": "289",
            "label": "PUMS, ATTANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "552",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "554",
            "district": "33",
            "Block": "403",
            "label": "PUMS, MUGAPAIR SECTOR-2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "555",
            "district": "33",
            "Block": "289",
            "label": "ST.MARYS MATRIC HSS,REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "556",
            "district": "33",
            "Block": "184",
            "label": "ST JAMES MAT SCH, VENPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "560",
            "district": "33",
            "Block": "260",
            "label": "PUMS, SADAYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "563",
            "district": "33",
            "Block": "289",
            "label": "VELAMMAL MATRICULATION HIGHER SECONDARY SCHOOL PONNERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "566",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KAKKALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "567",
            "district": "33",
            "Block": "403",
            "label": "MUN. MID SCH, DR.MOORTHY NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "569",
            "district": "33",
            "Block": "403",
            "label": "RAJIV SCHOOL OF EXCELLENCE MATRIC HIGHER SECONDARY SCHOOL, ALAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "570",
            "district": "33",
            "Block": "403",
            "label": "DAV MATRIC HR SEC SCHOOL, MOGAPPAIR EAST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "571",
            "district": "33",
            "Block": "260",
            "label": "ST THOMAS MAT HSS, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "574",
            "district": "33",
            "Block": "260",
            "label": "ST. THOMAS MATRIC HSS, CHINNA SEKKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "576",
            "district": "33",
            "Block": "403",
            "label": "SRI BHUVANESWARI MAT, PADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "577",
            "district": "33",
            "Block": "403",
            "label": "BALAR GURUKULAM MAT, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "580",
            "district": "33",
            "Block": "403",
            "label": "GOODWILL MATRIC HIGHER SECONDARY SCHOOL, THATHANKUPPAM , VILLIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "581",
            "district": "33",
            "Block": "403",
            "label": "ST JOHNS MATRIC HR SEC SCHOOL, PULIAMBEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "584",
            "district": "33",
            "Block": "184",
            "label": "NATIONAL MATRIC. HR.SEC.SCHOOL,PULICAT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "590",
            "district": "33",
            "Block": "289",
            "label": "CHILDRENS PARADISE MATRIC HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "595",
            "district": "33",
            "Block": "403",
            "label": "SALVATION MAT TMVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "603",
            "district": "33",
            "Block": "64",
            "label": "J.N.N MATRIC HIGHER SECONDARY SCHOOL, KANNIGAIPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "604",
            "district": "33",
            "Block": "76",
            "label": "MAHARAJA AGRASEN MATRIC HIGHER SECONDARY SCHOOL,ARUN NAGAR, GUMMIDIPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "606",
            "district": "33",
            "Block": "403",
            "label": "THE SCHRAM ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "608",
            "district": "33",
            "Block": "403",
            "label": "EBINESAR JAGANATH MARCUS MS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "609",
            "district": "33",
            "Block": "403",
            "label": "LAKSHMI MATRICULATION HIGHER SECONDARY SCHOOL,JAGADAMBIGAI NAGAR,PADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "611",
            "district": "33",
            "Block": "364",
            "label": "SUDANDIRA MATHSS-TRT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "612",
            "district": "33",
            "Block": "184",
            "label": "KAMARAJAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "614",
            "district": "33",
            "Block": "403",
            "label": "SHARON MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "615",
            "district": "33",
            "Block": "403",
            "label": "SIVANTHI MAT HSS,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "616",
            "district": "33",
            "Block": "261",
            "label": "AYYAN VID,VELLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "626",
            "district": "33",
            "Block": "76",
            "label": "SAKUNTHALAMMAL MATRIC SCHOOL THURAPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "629",
            "district": "33",
            "Block": "64",
            "label": "SRI SANKARA MATRIC HIGHER SECONDARY SCHOOL, THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "632",
            "district": "33",
            "Block": "289",
            "label": "VIVEKANANDA MATRIC HIGHER SECONDARY SCHOOL, ATTANTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "636",
            "district": "33",
            "Block": "403",
            "label": "PUMS, KONIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "638",
            "district": "33",
            "Block": "403",
            "label": "PUMS, CHETTIYAR AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "640",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NAZARATHPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "641",
            "district": "33",
            "Block": "76",
            "label": "PUMS L R MEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "644",
            "district": "33",
            "Block": "403",
            "label": "PUMS, MUGAPAIR SECTOR-4",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "645",
            "district": "33",
            "Block": "87",
            "label": "PUMS KONDANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "652",
            "district": "33",
            "Block": "261",
            "label": "VETHATHIRI MAHARISHI HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23417",
            "district": "33",
            "Block": "403",
            "label": "PRS Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "660",
            "district": "33",
            "Block": "403",
            "label": "HOLY CROSS MAT,VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "662",
            "district": "33",
            "Block": "364",
            "label": "PUMS - VEERAKA NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "665",
            "district": "33",
            "Block": "184",
            "label": "PK MAT SCH,PUNGAMBEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "667",
            "district": "33",
            "Block": "338",
            "label": "BHARATHI MATRICULATION SCHOOL  RAJAJIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "668",
            "district": "33",
            "Block": "338",
            "label": "SRI GNANABANU VIDYALAYA  MATRIC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "671",
            "district": "33",
            "Block": "225",
            "label": "ANM MID.SCH, POTHATURPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "678",
            "district": "33",
            "Block": "403",
            "label": "VENKATESHWARA MATRIC SCHOOL, THIRUMULLAIVAYOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "679",
            "district": "33",
            "Block": "403",
            "label": "INFANT JESUS MATRIC HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "684",
            "district": "33",
            "Block": "338",
            "label": "PUMS, ARANVOYAL KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "686",
            "district": "33",
            "Block": "260",
            "label": "AUXILIUM MATRICULATION SCHOOL, ,THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "688",
            "district": "33",
            "Block": "403",
            "label": "PUMS MUGAPPAIR SECTOR - 9",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "695",
            "district": "33",
            "Block": "338",
            "label": "RAJA NATIONAL  MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "698",
            "district": "33",
            "Block": "252",
            "label": "NAZARETH MATRIC HR  SEC SCHOOL, KAMARAJ NAGAR, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "704",
            "district": "33",
            "Block": "364",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION SCHOOL -K.G.KANDIGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "709",
            "district": "33",
            "Block": "87",
            "label": "PUMS PUDUMAVILANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "714",
            "district": "33",
            "Block": "184",
            "label": "ST. JOHN'S MATRICULATION SCHOOL,PONNERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "715",
            "district": "33",
            "Block": "403",
            "label": "SWAMY'S MAT HSS,PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "725",
            "district": "33",
            "Block": "252",
            "label": "GANDHIJI MATRIC HR SEC SCHOOL,  AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "727",
            "district": "33",
            "Block": "403",
            "label": "SIRORATHNAM MAT MANNURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "729",
            "district": "33",
            "Block": "87",
            "label": "PUMS KILNALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "736",
            "district": "33",
            "Block": "403",
            "label": "GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "742",
            "district": "33",
            "Block": "252",
            "label": "ANGEL MATRIC HR SEC SCHOOL,THIRUNINRAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "746",
            "district": "33",
            "Block": "184",
            "label": "EMMANUEL MATRIC HIGHER SECONDARY SCHOOL,ATHIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "749",
            "district": "33",
            "Block": "403",
            "label": "SHRI KRISHNASWAMY MATRICULATION SCHOOL,AYAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "769",
            "district": "33",
            "Block": "184",
            "label": "VIVEKANANDA MATRICULATION  SCHOOL VALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "776",
            "district": "33",
            "Block": "289",
            "label": "SRI KALAIVANI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL.,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "778",
            "district": "33",
            "Block": "403",
            "label": "ST JOHNS HIGHER SECONDARY SCHOOL ,PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "779",
            "district": "33",
            "Block": "252",
            "label": "ST PAULS MATRICULATION SCHOOL, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "780",
            "district": "33",
            "Block": "364",
            "label": "PUMS - PERIYAKADAMBUR H/C",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "784",
            "district": "33",
            "Block": "76",
            "label": "PUMS MADHARPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "787",
            "district": "33",
            "Block": "289",
            "label": "PUMS,NEDUVARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "789",
            "district": "33",
            "Block": "76",
            "label": "PUMS NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "790",
            "district": "33",
            "Block": "76",
            "label": "PUMS PETHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "791",
            "district": "33",
            "Block": "76",
            "label": "PUMS GOPAL REDDY KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "792",
            "district": "33",
            "Block": "87",
            "label": "PUMS SATHARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "795",
            "district": "33",
            "Block": "403",
            "label": "PUMS, ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "796",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VARADHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "799",
            "district": "33",
            "Block": "260",
            "label": "KAVI BHARATHI VID, THIRUVOTRIYUR 40",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "802",
            "district": "33",
            "Block": "403",
            "label": "ST JOSEPH'S MATRIC  HSS,PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "810",
            "district": "33",
            "Block": "403",
            "label": "SREE AADHITHYA MATRICULATION HIGH SCHOOL, VANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "811",
            "district": "33",
            "Block": "403",
            "label": "EXCEL MAT., TMVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "833",
            "district": "33",
            "Block": "403",
            "label": "SWAAMI VIVEKANANDA MATRIC HSS , RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "846",
            "district": "33",
            "Block": "338",
            "label": "JACOB MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "851",
            "district": "33",
            "Block": "87",
            "label": "GANCHIOR MATRICULATION HIGHER SECONDARY SCHOOL KARANAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "857",
            "district": "33",
            "Block": "260",
            "label": "MALLIKARJUNA MATRICS, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "865",
            "district": "33",
            "Block": "338",
            "label": "MUN. MS, NGGO COLONY, TRL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "873",
            "district": "33",
            "Block": "403",
            "label": "ST THOMAS VIDYALAYAM MATRIC HSS  VANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "874",
            "district": "33",
            "Block": "337",
            "label": "SRI SATHYA SAI MAT. SCHOOL, PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "878",
            "district": "33",
            "Block": "403",
            "label": "RAJALAKSHMI VIDHYASHRAM MATRICULATION HIGHER SECONDARY SCHOOL,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "884",
            "district": "33",
            "Block": "338",
            "label": "MUN. MS, LAKSHMIPURAM, TRL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "888",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUZHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "906",
            "district": "33",
            "Block": "403",
            "label": "MUN.MID.SCH ORAKADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "908",
            "district": "33",
            "Block": "289",
            "label": "ST.JOSEPH MATRIC SCHOOL,MONDIAMMAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "911",
            "district": "33",
            "Block": "364",
            "label": "PUMS - TIRUTTANI MAIN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "913",
            "district": "33",
            "Block": "260",
            "label": "REVOOR PADMANABHA CHETTY'S MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "917",
            "district": "33",
            "Block": "260",
            "label": "MUNICIPAL MIDDLE SCHOOL,  ALL INDIA RADIO NAGAR, ENNORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "919",
            "district": "33",
            "Block": "403",
            "label": "ST ANNAL MS,PORUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "925",
            "district": "33",
            "Block": "87",
            "label": "VISHWAKSENA VIDYA VIKAS MATRIC HIGHER SECONDARY SCHOOL, POLLIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "937",
            "district": "33",
            "Block": "261",
            "label": "PUMS,CHANDRAVILASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "951",
            "district": "33",
            "Block": "338",
            "label": "GNANA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "964",
            "district": "33",
            "Block": "184",
            "label": "VENKATESWARA VIDYALAYA  MS,NANDIAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "965",
            "district": "33",
            "Block": "289",
            "label": "ELITE MAT.HSS, M.A.NAGAR, REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "969",
            "district": "33",
            "Block": "260",
            "label": "VEERA SAVARKAR NETHAJI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "971",
            "district": "33",
            "Block": "260",
            "label": "ILAHI MATRICULATION HIGH SCHOOL,REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "977",
            "district": "33",
            "Block": "403",
            "label": "FAITH HOME MID SCH,PORUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "978",
            "district": "33",
            "Block": "403",
            "label": "NEW CENTURY MATRIC.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "985",
            "district": "33",
            "Block": "261",
            "label": "PUMS,CHELLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "991",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAYALANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1005",
            "district": "33",
            "Block": "260",
            "label": "VICTORY MATRIC, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1019",
            "district": "33",
            "Block": "364",
            "label": "ST MARY'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1027",
            "district": "33",
            "Block": "76",
            "label": "PUMS PUDUVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1031",
            "district": "33",
            "Block": "338",
            "label": "SRI RAM VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1032",
            "district": "33",
            "Block": "403",
            "label": "PRIYA MATRICULATION HIGHER SECONDARY SCHOOL, MUGAPAIR WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1043",
            "district": "33",
            "Block": "289",
            "label": "PUMS,SOTHUPERUMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1044",
            "district": "33",
            "Block": "253",
            "label": "PUMS, ATTRAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1061",
            "district": "33",
            "Block": "338",
            "label": "BHARATHIDASAN  MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1069",
            "district": "33",
            "Block": "364",
            "label": "SRI RAJA RAJESWARI MATRICULATION SCHOOL MELKASAWARAJAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1070",
            "district": "33",
            "Block": "403",
            "label": "JANET MATRIC HSS, THIRUVERKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1073",
            "district": "33",
            "Block": "403",
            "label": "GK SHETTY VIV VID JR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1083",
            "district": "33",
            "Block": "403",
            "label": "T.I. MATRICULATION HIGHER SECONDARY SCHOOL AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1084",
            "district": "33",
            "Block": "252",
            "label": "JOY BELL MATRICULATION SCHOOL ,POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1090",
            "district": "33",
            "Block": "76",
            "label": "PUMS SIRUPUZHALPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1093",
            "district": "33",
            "Block": "76",
            "label": "PUMS PERIYAOBULAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1095",
            "district": "33",
            "Block": "87",
            "label": "PUMS THIRUMANIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1097",
            "district": "33",
            "Block": "64",
            "label": "PUMS, SOOLAIMENI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1099",
            "district": "33",
            "Block": "76",
            "label": "PUMS NELVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1101",
            "district": "33",
            "Block": "261",
            "label": "PUMS,GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1108",
            "district": "33",
            "Block": "253",
            "label": "PUMS, ODAPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1117",
            "district": "33",
            "Block": "403",
            "label": "LITTLE HOLY ANGELS M.S,KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1122",
            "district": "33",
            "Block": "337",
            "label": "PUMS, HARICHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1123",
            "district": "33",
            "Block": "364",
            "label": "PUMS - KASINATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1127",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PARIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1128",
            "district": "33",
            "Block": "364",
            "label": "PUMS - MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1131",
            "district": "33",
            "Block": "184",
            "label": "PUMS, NEITHAVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1132",
            "district": "33",
            "Block": "338",
            "label": "PUMS, PERUMALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1134",
            "district": "33",
            "Block": "338",
            "label": "SREE MAHALAKSHMI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1135",
            "district": "33",
            "Block": "76",
            "label": "PUMS KURUVATTUCHERRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1138",
            "district": "33",
            "Block": "364",
            "label": "PUMS - MADDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1139",
            "district": "33",
            "Block": "260",
            "label": "ST JOHN'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1140",
            "district": "33",
            "Block": "87",
            "label": "PUMS POLIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1142",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KEELAPUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1143",
            "district": "33",
            "Block": "260",
            "label": "MUN.MS,TSM NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1144",
            "district": "33",
            "Block": "76",
            "label": "PUMS NETHAJI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1147",
            "district": "33",
            "Block": "225",
            "label": "ANM MS, KONEDAMPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1150",
            "district": "33",
            "Block": "76",
            "label": "PUMS KANNAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1152",
            "district": "33",
            "Block": "260",
            "label": "St.JOSEPH'S ACADEMY MATRICULATION HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1153",
            "district": "33",
            "Block": "252",
            "label": "OXFORD MATRICULATION  HR SEC SCHOOL, THIRUNINRAVUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1156",
            "district": "33",
            "Block": "403",
            "label": "SETHU BHASKARA MATRIC HR SEC SCH KALLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1159",
            "district": "33",
            "Block": "403",
            "label": "ST PAULS HSS, KARAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1160",
            "district": "33",
            "Block": "252",
            "label": "ST CLARET MATRIC HIGHER SECONDARY SCHOOL NADUKUTHAGAI, THIRUNINRAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1165",
            "district": "33",
            "Block": "253",
            "label": "PUMS, NAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1189",
            "district": "33",
            "Block": "289",
            "label": "PUMS,AMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1190",
            "district": "33",
            "Block": "225",
            "label": "SAAISRI MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1192",
            "district": "33",
            "Block": "252",
            "label": "ST JOHNS INTERNATIONAL RESIDENTIAL SCHOOL PAZHANJUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1195",
            "district": "33",
            "Block": "261",
            "label": "PUMS,RAJANAGARAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1199",
            "district": "33",
            "Block": "260",
            "label": "GODSON MATRICULATION HSS , SURAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1201",
            "district": "33",
            "Block": "252",
            "label": "GRACE PARK CONVENT MATRIC HR SEC SCHOOL, THIRUNINRAVUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1213",
            "district": "33",
            "Block": "253",
            "label": "PUMS, ANANTHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1219",
            "district": "33",
            "Block": "289",
            "label": "PUMS,JANAPPAN CHATRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1225",
            "district": "33",
            "Block": "403",
            "label": "CHRIST MATRIC HIGHER SECONDARY SCHOOL, PADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1230",
            "district": "33",
            "Block": "289",
            "label": "PUMS, VELLIVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1235",
            "district": "33",
            "Block": "364",
            "label": "PUMS - B N KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1242",
            "district": "33",
            "Block": "260",
            "label": "MUN. MIDDLE SCHOOL, KATHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1244",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PERAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1247",
            "district": "33",
            "Block": "289",
            "label": "PUMS, THIRUNILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1253",
            "district": "33",
            "Block": "261",
            "label": "ANM MS,AMMAIYARKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1257",
            "district": "33",
            "Block": "64",
            "label": "PUMS, SENNANKARANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1262",
            "district": "33",
            "Block": "338",
            "label": "PUMS, THANEERKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1277",
            "district": "33",
            "Block": "253",
            "label": "PUMS, KUNNAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1279",
            "district": "33",
            "Block": "289",
            "label": "M.B.S.VIVEKANANDA VIDHYALAYA, ARANI NORTH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1280",
            "district": "33",
            "Block": "76",
            "label": "MADANLAL KHEMANI VIVEKANANDA VIDYALAYA , PETHIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1283",
            "district": "33",
            "Block": "403",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEW KANNIYAMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1288",
            "district": "33",
            "Block": "403",
            "label": "PUMS, MELAPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1290",
            "district": "33",
            "Block": "403",
            "label": "SRI VIDHYA NIKETHAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1293",
            "district": "33",
            "Block": "403",
            "label": "MAHARISHI SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1299",
            "district": "33",
            "Block": "260",
            "label": "PADMA SEENIVASAN MEMORIAL VIDYALAYA MATRICULATION HIGH SCHOOL, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1306",
            "district": "33",
            "Block": "403",
            "label": "SRI VENKATESHWARA MATRIC HIGHER SECONDARY SCHOOL,THIRUMULLAIVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1309",
            "district": "33",
            "Block": "260",
            "label": "HOLY CHILD MATRIC HR SEC SCHOOL,REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1312",
            "district": "33",
            "Block": "253",
            "label": "CHENNAI VIDYALAYA MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1323",
            "district": "33",
            "Block": "403",
            "label": "GRACE MATRICULATION HIGHER SECONDARY SCHOOL , KARAMBAKKAM,PORUR,CHENNAI-600116",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1327",
            "district": "33",
            "Block": "225",
            "label": "GOVT.MS, KUMARAJUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1332",
            "district": "33",
            "Block": "338",
            "label": "CALAVALA CUNNAN CHETTY''S HINDU MATRICULATION HIGHER SECONDARY SCHOOL, KAKKALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1335",
            "district": "33",
            "Block": "364",
            "label": "PUMS - VEERA KAVERI RAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1338",
            "district": "33",
            "Block": "261",
            "label": "PUMS,C G N KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1340",
            "district": "33",
            "Block": "252",
            "label": "SRI KRISHNA MOOLCHAND VIVEKANANDHA VIDYALAYA MHSS THIRUNINRAVUR.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1343",
            "district": "33",
            "Block": "403",
            "label": "GREEN VALLEY MAT MUGAPAIR WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1345",
            "district": "33",
            "Block": "64",
            "label": "VICTORIA MATRIC HIGHER SECONDARY SCHOOL, PERIYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1361",
            "district": "33",
            "Block": "403",
            "label": "MR MAT HSS, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1365",
            "district": "33",
            "Block": "64",
            "label": "DAYSPRING MATRICULATION HIGHER SECONDARY SCHOOL, VANIANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1371",
            "district": "33",
            "Block": "403",
            "label": "AIYEESHA RAZZAC MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1388",
            "district": "33",
            "Block": "337",
            "label": "PUMS, LAKSHMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1395",
            "district": "33",
            "Block": "76",
            "label": "MAHARISHI VID MANTHIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1407",
            "district": "33",
            "Block": "403",
            "label": "SRI VISA MAT,MADURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1413",
            "district": "33",
            "Block": "252",
            "label": "AMUTHA MATRICULATION SCHOOL, KRISHNANAGAR, THIRUNINRAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1418",
            "district": "33",
            "Block": "184",
            "label": "S.DYANANTHA MS,POLATCHIAMMANKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1420",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KOTHAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1429",
            "district": "33",
            "Block": "184",
            "label": "ADARSH MAT HR.SEC, THIRUVAYARPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1431",
            "district": "33",
            "Block": "76",
            "label": "R M K MAT HS KAVARAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1449",
            "district": "33",
            "Block": "76",
            "label": "ST MARYS MAT SC - ARAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1454",
            "district": "33",
            "Block": "403",
            "label": "Sathyalok Matriculation Hr. Sec.  School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1475",
            "district": "33",
            "Block": "403",
            "label": "MKM MAT SCHOOL PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1484",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SURAPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1492",
            "district": "33",
            "Block": "261",
            "label": "PUMS,R N KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1498",
            "district": "33",
            "Block": "289",
            "label": "PUMS,PANCHETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1502",
            "district": "33",
            "Block": "364",
            "label": "TKVINAYAGAM MAT - TRT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1504",
            "district": "33",
            "Block": "260",
            "label": "ST.ANTONY'S MATRIC HSS, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1505",
            "district": "33",
            "Block": "260",
            "label": "SARATHI SARASWATHI MHSS, MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1506",
            "district": "33",
            "Block": "403",
            "label": "VAILANKANNI MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1510",
            "district": "33",
            "Block": "403",
            "label": "SBIOA MODEL MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1522",
            "district": "33",
            "Block": "87",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL THODUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1534",
            "district": "33",
            "Block": "260",
            "label": "MANIYAMMAI MATRIC SCHOOL, P.MEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1538",
            "district": "33",
            "Block": "364",
            "label": "GMS - S AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1549",
            "district": "33",
            "Block": "403",
            "label": "SHAMROCK MAT HSS MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1550",
            "district": "33",
            "Block": "76",
            "label": "ELITE MAT.HSS GUMMIDIPOONDI - 601 201",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1552",
            "district": "33",
            "Block": "76",
            "label": "NETHAJI MS PATHIRVEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1573",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL MAT SCHOOL, MU WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1574",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PANAYANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1579",
            "district": "33",
            "Block": "261",
            "label": "PUMS,KRISHNAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1584",
            "district": "33",
            "Block": "64",
            "label": "VIVEKANANDA MATRIC HR SEC SCHOOL, UTHUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1585",
            "district": "33",
            "Block": "184",
            "label": "ISRAEL MISSION MAT HR SEC SCHOOL, MINJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1590",
            "district": "33",
            "Block": "76",
            "label": "PUMS CHINNAOBULAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1591",
            "district": "33",
            "Block": "364",
            "label": "PUMS - SIRUKUMI H/C",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1593",
            "district": "33",
            "Block": "337",
            "label": "PUMS, NALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1596",
            "district": "33",
            "Block": "403",
            "label": "St.MOSES MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23696",
            "district": "33",
            "Block": "252",
            "label": "Govt. Girls Model Hr. Sec. School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1600",
            "district": "33",
            "Block": "260",
            "label": "JAIN VIDYAASHRAM, PUZHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1603",
            "district": "33",
            "Block": "260",
            "label": "BOSCO ACADEMY MAT HSS, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1608",
            "district": "33",
            "Block": "76",
            "label": "PUMS A N KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1610",
            "district": "33",
            "Block": "252",
            "label": "CHRIST MATRIC HIGHER SECONDARY SCHOOL SENNEERKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1617",
            "district": "33",
            "Block": "252",
            "label": "NABI CRESCENT MATRICULATION  SCHOOL, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1623",
            "district": "33",
            "Block": "403",
            "label": "LAKE VIEW MS, KALLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1628",
            "district": "33",
            "Block": "289",
            "label": "DON BOSCO MATRIC HIGHER SECONDARY SCHOOL, KARANODAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1630",
            "district": "33",
            "Block": "403",
            "label": "UCCK MATRIC HR.SEC.SCHOOL,AYANAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1631",
            "district": "33",
            "Block": "364",
            "label": "PUMS - PATTABIRAMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1643",
            "district": "33",
            "Block": "364",
            "label": "PUMS - SRINIVASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1647",
            "district": "33",
            "Block": "260",
            "label": "KATHIVAKKAMMUNICIPAL MIDDLESCHOOL NETTUKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1655",
            "district": "33",
            "Block": "403",
            "label": "C.S.CENTRAL MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1659",
            "district": "33",
            "Block": "76",
            "label": "PUMS EDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1660",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PERANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1671",
            "district": "33",
            "Block": "364",
            "label": "ANM MS-SARASWATHI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1672",
            "district": "33",
            "Block": "403",
            "label": "VASANTHA VIDHYA. KARAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1673",
            "district": "33",
            "Block": "76",
            "label": "PUMS PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1680",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEERARAGAVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1698",
            "district": "33",
            "Block": "261",
            "label": "PUMS,DEVALAMBAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1700",
            "district": "33",
            "Block": "76",
            "label": "PUMS ERUKKUVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1703",
            "district": "33",
            "Block": "261",
            "label": "PUMS,S B KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1707",
            "district": "33",
            "Block": "338",
            "label": "PUMS, KILANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1710",
            "district": "33",
            "Block": "253",
            "label": "PUMS, NELVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1711",
            "district": "33",
            "Block": "289",
            "label": "PUMS, MADHAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1714",
            "district": "33",
            "Block": "260",
            "label": "MUNICIPAL MIDDLE SCHOOL,RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1721",
            "district": "33",
            "Block": "76",
            "label": "PUMS ANNAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1723",
            "district": "33",
            "Block": "337",
            "label": "PUMS, CHINNAMANDALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1725",
            "district": "33",
            "Block": "260",
            "label": "SR PARTHASARATHY N & P, WARD 6 KATHIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1726",
            "district": "33",
            "Block": "76",
            "label": "PUMS POOVALAI AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1729",
            "district": "33",
            "Block": "64",
            "label": "PUMS, THARATCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1734",
            "district": "33",
            "Block": "261",
            "label": "PUMS,AMMANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1746",
            "district": "33",
            "Block": "260",
            "label": "VOC MATRIC HIGHER SECONDARY SCHOOL ENNORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1748",
            "district": "33",
            "Block": "184",
            "label": "Dr.V.GENGUSWAMY NAIDU MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1752",
            "district": "33",
            "Block": "338",
            "label": "KAMARAJ MATRICULATION SCHOOL, TIRUVALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1753",
            "district": "33",
            "Block": "260",
            "label": "SAGAYA MATHA MATRIC, R.K.NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1754",
            "district": "33",
            "Block": "403",
            "label": "KOIL PILLAI MATRICULATION HIGHER SECONDARY SCHOOL,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1757",
            "district": "33",
            "Block": "338",
            "label": "SIDHAARTHA MATRIC HIGHER SECONDARY SCHOOL, SEVVAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1761",
            "district": "33",
            "Block": "403",
            "label": "SATHYA MAT HSS,VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1762",
            "district": "33",
            "Block": "403",
            "label": "KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL, SENTHIL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1781",
            "district": "33",
            "Block": "260",
            "label": "INFANT JESUS MATRIC HR.SEC SCHOOL,LAKSHMIPURAM,KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1783",
            "district": "33",
            "Block": "64",
            "label": "HARIHARA VIDYALAYA MATRICULATION SCHOOL, THAMARAIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1791",
            "district": "33",
            "Block": "338",
            "label": "SRI VENKATESWARA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1793",
            "district": "33",
            "Block": "289",
            "label": "GREEN FIELD MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1801",
            "district": "33",
            "Block": "76",
            "label": "PUMS RAKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1807",
            "district": "33",
            "Block": "252",
            "label": "EMMANUEL MATRICULATION SCHOOL, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1817",
            "district": "33",
            "Block": "403",
            "label": "AMUTHA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1826",
            "district": "33",
            "Block": "252",
            "label": "RCM MATRICULATION SCHOOL, KAMARAJ NAGAR, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1850",
            "district": "33",
            "Block": "403",
            "label": "LEO MAT HSS AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1851",
            "district": "33",
            "Block": "403",
            "label": "KALKI RANGANATHAN MONTFORD MAT SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1866",
            "district": "33",
            "Block": "289",
            "label": "PUMS,ATHIPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1877",
            "district": "33",
            "Block": "87",
            "label": "CHRIST KING MAT. HR .SEC.SCHOOL,MANAVALA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1879",
            "district": "33",
            "Block": "64",
            "label": "PUMS, DR. AMBEDKAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1881",
            "district": "33",
            "Block": "403",
            "label": "ST.MARYS MATRICULATION HIGHER SECONDARY SCHOOL, ATHIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1884",
            "district": "33",
            "Block": "184",
            "label": "ST. MARY'S MAT SCHOOL, MINJUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1890",
            "district": "33",
            "Block": "64",
            "label": "VISWESHWARA MATRICULATION SCHOOL, UTHUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1893",
            "district": "33",
            "Block": "76",
            "label": "PUMS PALLAVADA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1912",
            "district": "33",
            "Block": "76",
            "label": "PUMS VALLAMEDU KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1913",
            "district": "33",
            "Block": "260",
            "label": "SRF VIDYALAYA MATRICULTION SCHOOL, MANALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1914",
            "district": "33",
            "Block": "364",
            "label": "PUMS-CHERUKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1920",
            "district": "33",
            "Block": "403",
            "label": "BALDWIN'S MAT SCH, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1948",
            "district": "33",
            "Block": "403",
            "label": "SINTHEA MODEL MAT SCHOOL,KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1953",
            "district": "33",
            "Block": "76",
            "label": "PUMS THANDALACHERRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1955",
            "district": "33",
            "Block": "403",
            "label": "SACRED HEART MAT.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1956",
            "district": "33",
            "Block": "252",
            "label": "KARTHIK MATRICULATION HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1961",
            "district": "33",
            "Block": "337",
            "label": "VIDYA SETHRA, LAKSHMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1965",
            "district": "33",
            "Block": "87",
            "label": "PUMS KADAMBATHUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1968",
            "district": "33",
            "Block": "338",
            "label": "PUMS, PUNNAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1969",
            "district": "33",
            "Block": "403",
            "label": "MUN.MID.SCH. ,MUTHAPUTHUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1971",
            "district": "33",
            "Block": "64",
            "label": "SREE SAI MATRICULATION SCHOOL , VENGAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1979",
            "district": "33",
            "Block": "403",
            "label": "CHILDREN'S ACADEMY MATRIC HIGH SCHOOL  ,PORUR CHENNAI 600116",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1986",
            "district": "33",
            "Block": "252",
            "label": "BHARATHI MATRICULATION SCHOOL, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1996",
            "district": "33",
            "Block": "252",
            "label": "HOLY CRESENT MATRIC HR SEC SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2013",
            "district": "33",
            "Block": "289",
            "label": "PUMS, OLD ERUMAI VETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2017",
            "district": "33",
            "Block": "403",
            "label": "NABI CRESCENT MATRICULATION SCHOOL, KOLLUMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2027",
            "district": "33",
            "Block": "64",
            "label": "PUMS, AKKARAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2028",
            "district": "33",
            "Block": "289",
            "label": "PUMS, GANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2049",
            "district": "33",
            "Block": "403",
            "label": "BHARATH MATRICULATION HIGHER SECONDARY SCHOOL AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2054",
            "district": "33",
            "Block": "403",
            "label": "BHARATH MAT, TMVOYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2057",
            "district": "33",
            "Block": "184",
            "label": "Smt.Gangadevi Bajranglal Chokhani Vivekananda Vidyalaya Matriculation Hr. Sec. School, Minjur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2074",
            "district": "33",
            "Block": "364",
            "label": "PUMS - SOORIYA NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2078",
            "district": "33",
            "Block": "403",
            "label": "DR VIMALA MS,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2083",
            "district": "33",
            "Block": "260",
            "label": "REDEEMER MATRIC, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2084",
            "district": "33",
            "Block": "289",
            "label": "VELAMMAL INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2088",
            "district": "33",
            "Block": "87",
            "label": "DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL PANNUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2093",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KUDIYANAVAR STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2094",
            "district": "33",
            "Block": "403",
            "label": "SRI RAMAKRISHNA MATRIC HSS,TMVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2115",
            "district": "33",
            "Block": "260",
            "label": "HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2150",
            "district": "33",
            "Block": "260",
            "label": "SRI SATHYA SAI VIDYALA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2165",
            "district": "33",
            "Block": "403",
            "label": "SATHEESH BALAJI MS,MADHANAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2171",
            "district": "33",
            "Block": "64",
            "label": "PUMS, MELMALIGAIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2173",
            "district": "33",
            "Block": "253",
            "label": "PUMS, VELLATHUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2178",
            "district": "33",
            "Block": "403",
            "label": "MUN.MID.SCH MANGALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2181",
            "district": "33",
            "Block": "260",
            "label": "VELAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, SURAPET , CHENNAI -600066",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2205",
            "district": "33",
            "Block": "289",
            "label": "PUMS,MONDIAMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2215",
            "district": "33",
            "Block": "403",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2216",
            "district": "33",
            "Block": "403",
            "label": "GOOD SHEPHERD MAT AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2305",
            "district": "33",
            "Block": "403",
            "label": "MUN.MS, CHOLAMPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2311",
            "district": "33",
            "Block": "337",
            "label": "PUMS, ATHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2312",
            "district": "33",
            "Block": "260",
            "label": "THE BELOVED MATRICULATION HIGHER SECONDARY SCHOOL , MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2336",
            "district": "33",
            "Block": "338",
            "label": "PUMS, AYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2375",
            "district": "33",
            "Block": "364",
            "label": "PUMS - PERIAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2386",
            "district": "33",
            "Block": "337",
            "label": "PUMS, CHINNAMMAPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2387",
            "district": "33",
            "Block": "337",
            "label": "PUMS, CHAKKARAMALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2398",
            "district": "33",
            "Block": "64",
            "label": "PUMS, NEYVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2399",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KARLAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2429",
            "district": "33",
            "Block": "76",
            "label": "PUMS AYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2440",
            "district": "33",
            "Block": "289",
            "label": "PUMS,B.SHANTHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2444",
            "district": "33",
            "Block": "253",
            "label": "PUMS, BEEMANTHOPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2460",
            "district": "33",
            "Block": "76",
            "label": "PUMS KAMMAVARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2468",
            "district": "33",
            "Block": "261",
            "label": "PUMS,SAHASRAPADMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2483",
            "district": "33",
            "Block": "252",
            "label": "GOOD SHEPHERD MATRICULATION  HIGHER SECONDARY  SCHOOL, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2528",
            "district": "33",
            "Block": "403",
            "label": "SAMUEL HARRIS MATRIC HIGHER SECONDARY SCHOOL AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2612",
            "district": "33",
            "Block": "337",
            "label": "PUMS, RANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2614",
            "district": "33",
            "Block": "403",
            "label": "CHRISTURAJA MATRICULATION SCHOOL,KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2616",
            "district": "33",
            "Block": "87",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2627",
            "district": "33",
            "Block": "252",
            "label": "SWAMI VIVEKANANDA GURUKULAM PAPPAN CHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2654",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL MIDDLE SCHOOL GOVARTHANAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2668",
            "district": "33",
            "Block": "64",
            "label": "PUMS, RALLAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2673",
            "district": "33",
            "Block": "64",
            "label": "PUMS, 43 PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2678",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ENNORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2691",
            "district": "33",
            "Block": "64",
            "label": "PUMS, MANJANKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2698",
            "district": "33",
            "Block": "252",
            "label": "CSI MID.SCH,THANDURAI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2702",
            "district": "33",
            "Block": "252",
            "label": "SVT (TELUGU) MIDDLE SCHOOL,KAMARAJ NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2704",
            "district": "33",
            "Block": "403",
            "label": "KV HVF CBSE, AVADY",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2714",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MEPPUR THANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2726",
            "district": "33",
            "Block": "87",
            "label": "PUMS USAIN NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2747",
            "district": "33",
            "Block": "184",
            "label": "G.R.T.M VIVEK VIDHAYALAYA, MALALI NEWTOWN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2763",
            "district": "33",
            "Block": "289",
            "label": "ALPHA MAT HSS,MONDIAMMAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2771",
            "district": "33",
            "Block": "260",
            "label": "PUMS, ANDARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2775",
            "district": "33",
            "Block": "260",
            "label": "DHARAM HINDUJA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2785",
            "district": "33",
            "Block": "403",
            "label": "BHAGYALAKSHMI MATRICULATION HIGH SCHOOL, MADHURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2809",
            "district": "33",
            "Block": "260",
            "label": "VENKATESWARA MATRIC, ERNAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2810",
            "district": "33",
            "Block": "260",
            "label": "MUN MIDDLE SCHOOL, NEHRU NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2847",
            "district": "33",
            "Block": "76",
            "label": "DONBOSCO MS N S NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2907",
            "district": "33",
            "Block": "403",
            "label": "EMMANUEL METHoDIST MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2917",
            "district": "33",
            "Block": "364",
            "label": "PUMS - KARTHIKEYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2920",
            "district": "33",
            "Block": "260",
            "label": "ST. PAUL'S MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2923",
            "district": "33",
            "Block": "252",
            "label": "VAILANKANNI  MATRICULATION HIGHER SECONDARY, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2938",
            "district": "33",
            "Block": "252",
            "label": "SRI RAMAKRISHNA MATRIC . HR .SEC .SCHOOL , PATTABIRAM , CH-72",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2939",
            "district": "33",
            "Block": "260",
            "label": "ST THOMAS MS, SATHUMA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2952",
            "district": "33",
            "Block": "64",
            "label": "DON BOSCO MATRIC  HR SEC SCHOOL, UTHUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2962",
            "district": "33",
            "Block": "64",
            "label": "PUMS, MAGARAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2979",
            "district": "33",
            "Block": "76",
            "label": "PUMS PALIKAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2981",
            "district": "33",
            "Block": "252",
            "label": "SRI ANNAI VIDYASHARAM, MATRIC HIGHER SECONDARY SCHOOL, THIRUVERKADU.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3000",
            "district": "33",
            "Block": "364",
            "label": "PUMS - R K STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3003",
            "district": "33",
            "Block": "403",
            "label": "BUTTERFLY MAT SCH, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3013",
            "district": "33",
            "Block": "184",
            "label": "PUMS, ARANGANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3027",
            "district": "33",
            "Block": "364",
            "label": "PUMS - L N KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3055",
            "district": "33",
            "Block": "338",
            "label": "GOVT. WELFARE MS, PERUMALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3059",
            "district": "33",
            "Block": "64",
            "label": "PUMS, POORIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3061",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ALADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3084",
            "district": "33",
            "Block": "64",
            "label": "PUMS, SEMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3088",
            "district": "33",
            "Block": "338",
            "label": "PUMS, THALAKANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3089",
            "district": "33",
            "Block": "260",
            "label": "DEIVA SIGAMANI MIDDLE SCHOOL, , KALADIPET, THIRUVOTTIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3099",
            "district": "33",
            "Block": "64",
            "label": "PUMS, THAMARAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3110",
            "district": "33",
            "Block": "87",
            "label": "PUMS THENKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3136",
            "district": "33",
            "Block": "364",
            "label": "PUMS- D V PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3146",
            "district": "33",
            "Block": "289",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3149",
            "district": "33",
            "Block": "260",
            "label": "MUNICIPAL. MIDDLE SCHOOL THIRUVOTRIYUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3150",
            "district": "33",
            "Block": "338",
            "label": "PUMS, VISHNUVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3155",
            "district": "33",
            "Block": "184",
            "label": "PUMS, THATHTHAMANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3157",
            "district": "33",
            "Block": "338",
            "label": "TELC MS, PERIA KUPPAM, TRL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3180",
            "district": "33",
            "Block": "184",
            "label": "PUMS,VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3181",
            "district": "33",
            "Block": "252",
            "label": "KALAIMAGAL MATRIC HR SEC SCHOOL, ANNA NAGAR PATTABIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3183",
            "district": "33",
            "Block": "337",
            "label": "PUMS, VEERARAGAVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3207",
            "district": "33",
            "Block": "364",
            "label": "GMS-RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3251",
            "district": "33",
            "Block": "403",
            "label": "HUSSAIN MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3256",
            "district": "33",
            "Block": "260",
            "label": "KINGS MATRIC SCHOOL, LAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3280",
            "district": "33",
            "Block": "338",
            "label": "CARMEL PUBLIC SCH,SEVVAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3287",
            "district": "33",
            "Block": "338",
            "label": "VIVEKANANDA VIDYAKALA ASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3326",
            "district": "33",
            "Block": "260",
            "label": "SRI SAKTHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3370",
            "district": "33",
            "Block": "403",
            "label": "SRE SASSTHA MATRIC HIGHER SECONDARY SCHOOL, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3387",
            "district": "33",
            "Block": "403",
            "label": "PASUMPON AACINI MAT HSS AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3388",
            "district": "33",
            "Block": "289",
            "label": "ST.JOSEPH MS,MONDIAMMAN NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3408",
            "district": "33",
            "Block": "87",
            "label": "CHELLAMMAL VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL MELNALLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3413",
            "district": "33",
            "Block": "184",
            "label": "SARASWATHI MS, MELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3416",
            "district": "33",
            "Block": "252",
            "label": "ST.THOMAS MATRIC HR SEC SCHOOL, THANDURAI, PATTABIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3420",
            "district": "33",
            "Block": "184",
            "label": "SRI AAIMATHAJI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3445",
            "district": "33",
            "Block": "337",
            "label": "PUMS, NABALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3472",
            "district": "33",
            "Block": "184",
            "label": "KALVI POONGA MATRICULATION SCHOOL , KANIYAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3482",
            "district": "33",
            "Block": "260",
            "label": "ST. JOSEPH MAT. HSS, ENNORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3492",
            "district": "33",
            "Block": "184",
            "label": "PUMS,AVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3505",
            "district": "33",
            "Block": "403",
            "label": "SHRI  VEL'S  ESTATE MATRICULATION HR. SEC.SCHOOL,VELLANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3507",
            "district": "33",
            "Block": "87",
            "label": "PUMS ADIGATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3528",
            "district": "33",
            "Block": "260",
            "label": "THE WESLEYS MS, KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3547",
            "district": "33",
            "Block": "76",
            "label": "SHANTHI NIKETHAN MS NEW GUMMIDIPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3554",
            "district": "33",
            "Block": "403",
            "label": "BALAGURU CHETI.VV, VEERAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3562",
            "district": "33",
            "Block": "184",
            "label": "PUMS,PANAPPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3572",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENEERKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3573",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUMANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3619",
            "district": "33",
            "Block": "403",
            "label": "MUN.MID.SCH PATTARAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3625",
            "district": "33",
            "Block": "338",
            "label": "PUMS, PUTLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3635",
            "district": "33",
            "Block": "252",
            "label": "IMMANUEL MATRIC HR SEC SCHOOL PATTABIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3637",
            "district": "33",
            "Block": "64",
            "label": "PUMS, AYALACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3661",
            "district": "33",
            "Block": "252",
            "label": "CHRIST KING MATRIC HIGHER SECONDARY SCHOOL, PATTABIRAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3687",
            "district": "33",
            "Block": "184",
            "label": "PUMS,NEW NANDIAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3689",
            "district": "33",
            "Block": "76",
            "label": "PUMS THANIPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3694",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL MIDDLE SCHOOL KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3695",
            "district": "33",
            "Block": "260",
            "label": "ADW MS, VILANGADUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3702",
            "district": "33",
            "Block": "337",
            "label": "PUMS, PAGASALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3731",
            "district": "33",
            "Block": "225",
            "label": "PUMS, MELAPUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3769",
            "district": "33",
            "Block": "184",
            "label": "PUMS,VELLIVOYAL CHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3851",
            "district": "33",
            "Block": "252",
            "label": "RCM MATRICULATION SCHOOL, OLD AGRAHARAM, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3853",
            "district": "33",
            "Block": "337",
            "label": "PUMS, KANCHIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3917",
            "district": "33",
            "Block": "253",
            "label": "PUMS, RAMANJERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3918",
            "district": "33",
            "Block": "76",
            "label": "ST MARYS HSS CHINTHALAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3937",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PRAYAMBATH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3938",
            "district": "33",
            "Block": "184",
            "label": "EDEN MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23384",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23397",
            "district": "33",
            "Block": "225",
            "label": "Sri Vidya mandir  matric school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "3954",
            "district": "33",
            "Block": "184",
            "label": "PUMS, MADIMAI KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3998",
            "district": "33",
            "Block": "253",
            "label": "PUMS, MANJAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4032",
            "district": "33",
            "Block": "252",
            "label": "ST.JOHNS MATRIC HR SEC SCHOOL, LAKSHMIPURAM, THIRUNINRAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4052",
            "district": "33",
            "Block": "64",
            "label": "PUMS, 82 PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4095",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AGARAMEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4098",
            "district": "33",
            "Block": "76",
            "label": "ST ANTONY MS ROSANAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4101",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAMMAVAR KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4165",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NADUKUTHAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4181",
            "district": "33",
            "Block": "76",
            "label": "GPD REFUGE CAMP MS PETHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4182",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELMANAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4206",
            "district": "33",
            "Block": "225",
            "label": "PUMS, JANGALA PALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4217",
            "district": "33",
            "Block": "184",
            "label": "SON TRUST NUR& PRI SCH, MINJUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4296",
            "district": "33",
            "Block": "338",
            "label": "SRI ARAVINDAR MATRIC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4303",
            "district": "33",
            "Block": "260",
            "label": "SRI RAMDAYAL KHEMKA VV, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4314",
            "district": "33",
            "Block": "260",
            "label": "RUKMANI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4347",
            "district": "33",
            "Block": "184",
            "label": "ST.ANNE'S MATRIC HIGHER SECONDARY SCHOOL, MINJUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4355",
            "district": "33",
            "Block": "260",
            "label": "PNECT KAMARAJ MATRIC SCHOOL, MADHAVARAM WARD 22",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4387",
            "district": "33",
            "Block": "260",
            "label": "VELAMMAL VIDHYASHRAM, SURAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4388",
            "district": "33",
            "Block": "252",
            "label": "ST JOSEPH MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4389",
            "district": "33",
            "Block": "403",
            "label": "SRI VENKATESWARA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4419",
            "district": "33",
            "Block": "76",
            "label": "VIVEKANANDA MATRIC HIGHER SECONDARY SCHOOL, THURAPPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4424",
            "district": "33",
            "Block": "260",
            "label": "ST ANNS MAT HSS, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4430",
            "district": "33",
            "Block": "252",
            "label": "AVL MATRIC HIGHER SECONDARY SCHOOL,KATTUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4457",
            "district": "33",
            "Block": "184",
            "label": "PUMS,SENGAZHAURNEERMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4471",
            "district": "33",
            "Block": "338",
            "label": "GOVT. MODEL SCHOOL, TIRUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "4492",
            "district": "33",
            "Block": "252",
            "label": "THIRUVALLUVAR MID.SCH, NAMBI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4534",
            "district": "33",
            "Block": "260",
            "label": "S.S.S.M ETPS MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4559",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL , MURUGAMPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4566",
            "district": "33",
            "Block": "260",
            "label": "MUN. MIDDLE SCHOOL, MILK COLONY, MADHAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4571",
            "district": "33",
            "Block": "403",
            "label": "ARUL MARY Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4597",
            "district": "33",
            "Block": "261",
            "label": "PUMS,CHETTIVARIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4638",
            "district": "33",
            "Block": "252",
            "label": "JAIGOPAL GARODIA VIVEK VID CBSE SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4648",
            "district": "33",
            "Block": "184",
            "label": "BAALA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, MANALI NEW TOWN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4797",
            "district": "33",
            "Block": "252",
            "label": "INFANT JESUS MATRIC HR SEC SCHOOL, THANDURAI, PATTABIRAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4804",
            "district": "33",
            "Block": "184",
            "label": "PUMS, KATTAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4886",
            "district": "33",
            "Block": "337",
            "label": "PUMS, THOZHUDHAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4984",
            "district": "33",
            "Block": "184",
            "label": "ADW MID SCHOOL, PONNERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5022",
            "district": "33",
            "Block": "260",
            "label": "AYISHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5046",
            "district": "33",
            "Block": "184",
            "label": "PUMS,KORAI KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5105",
            "district": "33",
            "Block": "184",
            "label": "PUMS, THIRUPPALAIVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5125",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAPPANCHATRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5152",
            "district": "33",
            "Block": "337",
            "label": "PUMS, THAZHAVEDU H/C",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5170",
            "district": "33",
            "Block": "252",
            "label": "MAHALASHMI MATRICULATION HSS PARUTHIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5208",
            "district": "33",
            "Block": "403",
            "label": "ST THERASA MAT HSS MANNURPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5212",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PUNNAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5233",
            "district": "33",
            "Block": "184",
            "label": "PUMS ELAVAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5254",
            "district": "33",
            "Block": "184",
            "label": "PUMS,RAJIVGANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5263",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUTHAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5274",
            "district": "33",
            "Block": "76",
            "label": "SRI KALAIMAGAL VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23663",
            "district": "33",
            "Block": "364",
            "label": "New Eden School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23666",
            "district": "33",
            "Block": "338",
            "label": "TVIS Velammal Vidyalaya",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23667",
            "district": "33",
            "Block": "338",
            "label": "R.M.Jain Govt.Girls Hr.Sec.School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23679",
            "district": "33",
            "Block": "260",
            "label": "KBC Government Girls Hr Sec School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5296",
            "district": "33",
            "Block": "225",
            "label": "ANM MS, PUNNIYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5300",
            "district": "33",
            "Block": "403",
            "label": "DAV (G) SR.SEC.SCH, COLLECTOR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5338",
            "district": "33",
            "Block": "403",
            "label": "ST JOHNS MS,TM VOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5362",
            "district": "33",
            "Block": "403",
            "label": "St. Mary's Matriculation Higher Secondary School ,IAF Avadi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5413",
            "district": "33",
            "Block": "338",
            "label": "SHREE NIKETAN MATRIC HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5414",
            "district": "33",
            "Block": "260",
            "label": "RAGHUPATHY RAJA MS, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5436",
            "district": "33",
            "Block": "252",
            "label": "PENGUIN MATRIC HR SEC SCHOOL, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5471",
            "district": "33",
            "Block": "252",
            "label": "HOLY IMMANUEL MATRICULATION SCHOOL AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5478",
            "district": "33",
            "Block": "260",
            "label": "SRI K.C.TOSHNIWAL VIVEKANANDA VIDYALAYA, PERIYA MATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5500",
            "district": "33",
            "Block": "403",
            "label": "RANGASAMY MATRIC  HSS,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5567",
            "district": "33",
            "Block": "364",
            "label": "PUMS - KORAMANAGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5640",
            "district": "33",
            "Block": "184",
            "label": "PUMS,CHINNAKAVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5672",
            "district": "33",
            "Block": "252",
            "label": "CHENNAI PUBLIC SCHOOL THIRUMAZHISAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5824",
            "district": "33",
            "Block": "403",
            "label": "LITTLE HOLY ANGELS'  MATRICULATION HIGHER SECONDARY SCHOOL ,AYAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5868",
            "district": "33",
            "Block": "364",
            "label": "CSI MS-AGOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5958",
            "district": "33",
            "Block": "184",
            "label": "PUMS,PONNERI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5964",
            "district": "33",
            "Block": "260",
            "label": "PUMS, SANMUGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5965",
            "district": "33",
            "Block": "403",
            "label": "SRI RAMA KRISHNA VIDYA MANDIR  MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6002",
            "district": "33",
            "Block": "260",
            "label": "NATIONAL LOTUS MATRIC HR.SEC SCHOOL,REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6014",
            "district": "33",
            "Block": "403",
            "label": "EBENEZER LIVING WORD MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6049",
            "district": "33",
            "Block": "403",
            "label": "NALLI KUPPUSWAMY V V KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6230",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ARIYANVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6348",
            "district": "33",
            "Block": "260",
            "label": "SRI SANKARA VIDYA KENDRA MATRIC HR.SEC.SCHOOL THIRUVOTTIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6420",
            "district": "33",
            "Block": "184",
            "label": "PUMS, LIGHT HOUSE KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6470",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ATHIPATTU REDDYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6570",
            "district": "33",
            "Block": "64",
            "label": "PUMS, ATTHANGIKAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6579",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDHIYASHRAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6747",
            "district": "33",
            "Block": "338",
            "label": "CSI HIGH SCHOOL, IKKADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "6748",
            "district": "33",
            "Block": "338",
            "label": "GOVT.HIGH.SCH.KILAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6749",
            "district": "33",
            "Block": "338",
            "label": "GOVT.HR.SEC.SCHOOL,KAKKALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6750",
            "district": "33",
            "Block": "338",
            "label": "GHS,MELAKONDAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6751",
            "district": "33",
            "Block": "338",
            "label": "GOVT.HIGH.SCHOOL,PAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6752",
            "district": "33",
            "Block": "338",
            "label": "SREE HARI SARADHA VIDYALAYA MATRICULATION SCHOOL , PAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6753",
            "district": "33",
            "Block": "338",
            "label": "MAHAKAVI BHARATHIYAR HSS - KASUVA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6754",
            "district": "33",
            "Block": "338",
            "label": "PUMS, PULLARAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6755",
            "district": "33",
            "Block": "338",
            "label": "GOVT. WEL (B) HSS, SEVVAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6756",
            "district": "33",
            "Block": "338",
            "label": "GOVT. WEL (G) HSS, SEVVAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6757",
            "district": "33",
            "Block": "338",
            "label": "KURUNJI CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6758",
            "district": "33",
            "Block": "338",
            "label": "ABS VIDHYA MANDHIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6759",
            "district": "33",
            "Block": "338",
            "label": "GOVT.HR.SEC.SCHOOL,VELLIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6760",
            "district": "33",
            "Block": "338",
            "label": "GHS,VEPPAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6761",
            "district": "33",
            "Block": "338",
            "label": "GHS VILAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6762",
            "district": "33",
            "Block": "338",
            "label": "CSI GOUDIE HR SEC SCH, TRL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6763",
            "district": "33",
            "Block": "338",
            "label": "KMN&BROS MUNICIPAL HIGHER SEC ,SATHYAMURTHY STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6764",
            "district": "33",
            "Block": "338",
            "label": "DRBCCCH HR.SEC.SCHOOL,TIRUVALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6765",
            "district": "33",
            "Block": "338",
            "label": "MOTHER TERESA MEM SS,PERIYAKUP",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6766",
            "district": "33",
            "Block": "338",
            "label": "MUN. HR . SEC SCHOOL, TRL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6767",
            "district": "33",
            "Block": "338",
            "label": "SRI LAKSHMI HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6768",
            "district": "33",
            "Block": "338",
            "label": "GOVT(G)HR.SEC.SCHOOL,TRL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6769",
            "district": "33",
            "Block": "253",
            "label": "GHS,ALLIKUZHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6770",
            "district": "33",
            "Block": "253",
            "label": "GOVT HSS, SEETHANJERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6771",
            "district": "33",
            "Block": "253",
            "label": "GOVT HIGH SCH, GOONIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6772",
            "district": "33",
            "Block": "253",
            "label": "CHRIST KING HIGH SCH, KATCHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6773",
            "district": "33",
            "Block": "253",
            "label": "GHS , MAMANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6774",
            "district": "33",
            "Block": "253",
            "label": "GOVT HIGH SCH, MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6775",
            "district": "33",
            "Block": "253",
            "label": "GOVT HIGHER SEC SCH, MEYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6776",
            "district": "33",
            "Block": "253",
            "label": "PUMS, MONNAVEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6777",
            "district": "33",
            "Block": "253",
            "label": "GHS,SATHURANGAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6778",
            "district": "33",
            "Block": "253",
            "label": "TELC HR SEC SCH, PANDUR VIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6779",
            "district": "33",
            "Block": "253",
            "label": "GOVT HIGH SCH, PATTRAI P.PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6780",
            "district": "33",
            "Block": "253",
            "label": "GOVT HR SEC SCH, PENNALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6781",
            "district": "33",
            "Block": "253",
            "label": "GOVT HR SEC SCHOOL PONDAVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6782",
            "district": "33",
            "Block": "253",
            "label": "GOVT HR SEC SCH, POONDI VILLAG",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6783",
            "district": "33",
            "Block": "253",
            "label": "GHS,RAMATHANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6784",
            "district": "33",
            "Block": "253",
            "label": "GHS, SENDRAYANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6785",
            "district": "33",
            "Block": "253",
            "label": "GHS,THIRUPACHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6786",
            "district": "33",
            "Block": "253",
            "label": "GHS , BANGARAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6787",
            "district": "33",
            "Block": "253",
            "label": "GHS , THOMUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6788",
            "district": "33",
            "Block": "87",
            "label": "GHS EGATUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6789",
            "district": "33",
            "Block": "87",
            "label": "GHS ERAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6790",
            "district": "33",
            "Block": "87",
            "label": "GOVTGHS KADAMBATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6791",
            "district": "33",
            "Block": "87",
            "label": "GOVTHSS KADAMBATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6792",
            "district": "33",
            "Block": "87",
            "label": "ST JOSEPH (B)HSS KILACHERI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6793",
            "district": "33",
            "Block": "87",
            "label": "SACRED HEART GIRLS HR SEC SCHOOL -  KILACHERI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6794",
            "district": "33",
            "Block": "87",
            "label": "GOVT HIGH SCHOOL KOOVAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6795",
            "district": "33",
            "Block": "87",
            "label": "GOVT.HR.SEC.SCHOOL KOPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6796",
            "district": "33",
            "Block": "87",
            "label": "GOVT HS MELNALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6797",
            "district": "33",
            "Block": "87",
            "label": "GOVT.GIRLS HR.SEC.SCHOOL PERAMBAKKAM-631402",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6798",
            "district": "33",
            "Block": "87",
            "label": "GOVT.BOYS. HR.SEC.SCHOOL PERAMBAKKAM-631402",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6799",
            "district": "33",
            "Block": "87",
            "label": "GHS SENJI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6800",
            "district": "33",
            "Block": "87",
            "label": "GHS THIRUPANTHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6801",
            "district": "33",
            "Block": "87",
            "label": "ST.JOSEPH GIRLS HR SEC SCHOOL, PANNUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6802",
            "district": "33",
            "Block": "87",
            "label": "DONBOSCO HSS PANNUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6803",
            "district": "33",
            "Block": "87",
            "label": "GHS ULUNTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6804",
            "district": "33",
            "Block": "87",
            "label": "GHSS VIDAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6805",
            "district": "33",
            "Block": "87",
            "label": "GHS VAYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6806",
            "district": "33",
            "Block": "87",
            "label": "GGHS MANAVALANAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6807",
            "district": "33",
            "Block": "87",
            "label": "GHSS MANAVALANAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6808",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, ATHIVAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6809",
            "district": "33",
            "Block": "64",
            "label": "GHS, BOOCHIATHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6810",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGHER SECONDARY SCHOOL, GURUVOYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6811",
            "district": "33",
            "Block": "64",
            "label": "GOVT HSS SCHOOL, KANNIGAIPAIR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6812",
            "district": "33",
            "Block": "64",
            "label": "KRUSHNAA VIDHYALAYA NURSERY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6813",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, KOMAKKAMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6814",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, MALANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6815",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, PALAVAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6816",
            "district": "33",
            "Block": "64",
            "label": "GOVT HSS, PERIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6817",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, THIRUKKANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6818",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, THANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6819",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, VADAMADURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6820",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGHER SECONDARY SCHOOL, VENGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6821",
            "district": "33",
            "Block": "64",
            "label": "KOTHANDARAMAN HIGH SCHOOL, UTHUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "6822",
            "district": "33",
            "Block": "64",
            "label": "GOVT B HSS, UTHUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6823",
            "district": "33",
            "Block": "64",
            "label": "GOVT G HSS, UTHUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6824",
            "district": "33",
            "Block": "252",
            "label": "ROUNDTABLE 30 VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6825",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL BANAVEDU THOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6826",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL SEMBARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6827",
            "district": "33",
            "Block": "252",
            "label": "ST.JOHN'S ACADEMY RESIDENTIAL MATRICULATION HIGHER SECONDARY SCHOOL,PALANJUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6828",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL GUDAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6829",
            "district": "33",
            "Block": "252",
            "label": "LCVKS HIGH SCHOOL KATTUPAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6830",
            "district": "33",
            "Block": "252",
            "label": "ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL KOLAPPANCHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6831",
            "district": "33",
            "Block": "252",
            "label": "SRI VIDHYA ACADEMY INTERNATIONAL RESIDENCIAL SCHOOL KOLAPPANCHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6832",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL KOTTAMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6833",
            "district": "33",
            "Block": "252",
            "label": "MAHATMA GANDHI HIGH SCHOOL UTKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6834",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KUTHAMBAKKAM THEN COLONY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6835",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KEELMANAMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6836",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL NAZARATHPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6837",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL NEMILICHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6838",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENNEERKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6839",
            "district": "33",
            "Block": "252",
            "label": "OXFORD MATRICULATION SCHOOL SENNERKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6840",
            "district": "33",
            "Block": "252",
            "label": "ADI DRIVIDA WELFARE HIGH SCHOOL MELMANAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6841",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED POONAMALLEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6842",
            "district": "33",
            "Block": "252",
            "label": "SAROJINI VARADAPPAN GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6843",
            "district": "33",
            "Block": "252",
            "label": "ARIGNAR ANNA GOVERNMENT HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6844",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6845",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL HIGH SCHOOL KONAMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6846",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL HIGH SCHOOL AVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6847",
            "district": "33",
            "Block": "252",
            "label": "IMMACULATE HEART OF MARY'S GIRLS HIGHER SECONDARY SCHOOL, AVADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6848",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6849",
            "district": "33",
            "Block": "252",
            "label": "RCM HSS KAMARAJ NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6850",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6851",
            "district": "33",
            "Block": "252",
            "label": "ANNAI LOURD HSS,PATTABIRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6852",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THANDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6853",
            "district": "33",
            "Block": "252",
            "label": "HOLY INFANT JESUS MATRIC HR SEC SCHOOL PATTABIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6854",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL HIGH SCHOOL PATTABIRAM CHATHRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6855",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THIRUMAZHISAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6856",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THIRUNINRAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6857",
            "district": "33",
            "Block": "252",
            "label": "DASAR HSS, THIRUNINRAVUR.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6858",
            "district": "33",
            "Block": "252",
            "label": "JAYA MATRICULATION SCHOOL, KRISHNAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6859",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , SUNDARASOZHAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6860",
            "district": "33",
            "Block": "252",
            "label": "RMK CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6861",
            "district": "33",
            "Block": "184",
            "label": "GHS - PAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6862",
            "district": "33",
            "Block": "184",
            "label": "GHS,ANUPPAMBATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6863",
            "district": "33",
            "Block": "184",
            "label": "GHS,ATHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6864",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ELIAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6865",
            "district": "33",
            "Block": "184",
            "label": "GHS - PAZAYANAPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6866",
            "district": "33",
            "Block": "184",
            "label": "GOVT HS,MANALI NEW TOWN PHASE 1",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6867",
            "district": "33",
            "Block": "184",
            "label": "GOVT HSS,MANALI NEW TOWN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6868",
            "district": "33",
            "Block": "184",
            "label": "INFANT JESUS MAT HSS,LAKSHMI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6869",
            "district": "33",
            "Block": "184",
            "label": "GHS, MANGODU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6870",
            "district": "33",
            "Block": "184",
            "label": "GHS, GOONANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6871",
            "district": "33",
            "Block": "184",
            "label": "GHS, KATTUPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6872",
            "district": "33",
            "Block": "184",
            "label": "GHS, KOLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6873",
            "district": "33",
            "Block": "184",
            "label": "JOSEPH HS, KOTTAIKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6874",
            "district": "33",
            "Block": "184",
            "label": "GHSS, KATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6875",
            "district": "33",
            "Block": "184",
            "label": "GHS, KANIYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6876",
            "district": "33",
            "Block": "184",
            "label": "GHSS, MEDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6877",
            "district": "33",
            "Block": "184",
            "label": "ANM HIGH SCHOOL, MELUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6878",
            "district": "33",
            "Block": "184",
            "label": "GHS, NALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6879",
            "district": "33",
            "Block": "184",
            "label": "GH GOVT HSS,PERUMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6880",
            "district": "33",
            "Block": "184",
            "label": "GHSS,PULICAT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6881",
            "district": "33",
            "Block": "184",
            "label": "ANM MS,PALLIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6882",
            "district": "33",
            "Block": "184",
            "label": "GHS, SUBBA REDDY PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6883",
            "district": "33",
            "Block": "184",
            "label": "GOVT HIGH SCHOOL  ANNAMALAICHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6884",
            "district": "33",
            "Block": "184",
            "label": "VIDYA MANDIR MATRIC HIGHER SECONDARY SCHOOL,KOKKUMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6885",
            "district": "33",
            "Block": "184",
            "label": "GOVT HSS, THIRUVELLAVOYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6886",
            "district": "33",
            "Block": "184",
            "label": "ADW HS, VALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6887",
            "district": "33",
            "Block": "184",
            "label": "DVS REDDY HSS, MINJUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6888",
            "district": "33",
            "Block": "184",
            "label": "SAHAYAMATHA GIRLS HIGHER SECONDARY SCHOOL, MINJUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6889",
            "district": "33",
            "Block": "184",
            "label": "ADWHS,MINJUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6890",
            "district": "33",
            "Block": "184",
            "label": "GHS,MINJUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6891",
            "district": "33",
            "Block": "184",
            "label": "GOVT (B) HSS, PONNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6892",
            "district": "33",
            "Block": "184",
            "label": "GOVT (G) HSS, PONNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6893",
            "district": "33",
            "Block": "184",
            "label": "UNITY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6894",
            "district": "33",
            "Block": "289",
            "label": "GOVT.HSS,NEW ALAMATHI - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6895",
            "district": "33",
            "Block": "289",
            "label": "GHS, OLD ALAMATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6896",
            "district": "33",
            "Block": "289",
            "label": "GOVT.HS ,ANDARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6897",
            "district": "33",
            "Block": "289",
            "label": "GHS,ATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6898",
            "district": "33",
            "Block": "289",
            "label": "GOVT HSS,BUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6899",
            "district": "33",
            "Block": "289",
            "label": "GHS, CHINNAMPEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6900",
            "district": "33",
            "Block": "289",
            "label": "GHS, NEW ERUMAI VETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6901",
            "district": "33",
            "Block": "289",
            "label": "GHS, JAGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6902",
            "district": "33",
            "Block": "289",
            "label": "GHSS, GNAYERU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6903",
            "district": "33",
            "Block": "289",
            "label": "SHRI GORANTLA RAMALINGAIAH VIVEKANANDA VIDYALAYA THATCHUR KOOTU ROAD, , PANJETTI,, PONNERI TALUK",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6904",
            "district": "33",
            "Block": "289",
            "label": "G.H.S.S,PADIANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6905",
            "district": "33",
            "Block": "289",
            "label": "GOVT HSS(G),SHOLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6906",
            "district": "33",
            "Block": "289",
            "label": "GOVT HSS (Boys) ,SHOLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6907",
            "district": "33",
            "Block": "289",
            "label": "GHS, VICHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6908",
            "district": "33",
            "Block": "289",
            "label": "GOVT (G) HSS, ARANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6909",
            "district": "33",
            "Block": "289",
            "label": "GOVT (B)  HSS, ARANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6910",
            "district": "33",
            "Block": "76",
            "label": "GHS ATHUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6911",
            "district": "33",
            "Block": "76",
            "label": "GOVT HSS ARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6912",
            "district": "33",
            "Block": "76",
            "label": "ST MARYS HSS ARAMBAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6913",
            "district": "33",
            "Block": "76",
            "label": "GOVT HIGH SCH N M KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6914",
            "district": "33",
            "Block": "76",
            "label": "GOVT HS EGUVARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6915",
            "district": "33",
            "Block": "76",
            "label": "GOVT HSS ELAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6916",
            "district": "33",
            "Block": "76",
            "label": "GHS G R KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6917",
            "district": "33",
            "Block": "76",
            "label": "GOVT HSS A N KANDIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6918",
            "district": "33",
            "Block": "76",
            "label": "GHSS KAVARAIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6919",
            "district": "33",
            "Block": "76",
            "label": "R M K RESIDENTAL SCHOOL KAVARAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6920",
            "district": "33",
            "Block": "76",
            "label": "GHS OBASAMUTHRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6921",
            "district": "33",
            "Block": "76",
            "label": "GHSS NEW GUMMIDIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6922",
            "district": "33",
            "Block": "76",
            "label": "GHS POOVALAMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6923",
            "district": "33",
            "Block": "76",
            "label": "GGHSS PATHIRVEDU MADHARPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6924",
            "district": "33",
            "Block": "76",
            "label": "GBHSS PATHIRVEDU MADHARPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6925",
            "district": "33",
            "Block": "76",
            "label": "GHS S R KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6926",
            "district": "33",
            "Block": "76",
            "label": "GHSS SUNNAMBUKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6927",
            "district": "33",
            "Block": "76",
            "label": "GOVT WEL HSS THERVOY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6928",
            "district": "33",
            "Block": "76",
            "label": "ARUL JOTHI MATRICULATION SCHOOL, NEW GUMMIDIPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6929",
            "district": "33",
            "Block": "76",
            "label": "GGHSS GUMMIDIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6930",
            "district": "33",
            "Block": "76",
            "label": "KLK GBHSS GUMMIDIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6931",
            "district": "33",
            "Block": "403",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, AYAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6932",
            "district": "33",
            "Block": "403",
            "label": "NATHAN HS, NERKUNDRAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6933",
            "district": "33",
            "Block": "403",
            "label": "GOVT H.S, NERKUNDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6934",
            "district": "33",
            "Block": "403",
            "label": "SRI AYYAPPA N&P, METTUKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6935",
            "district": "33",
            "Block": "403",
            "label": "DAWN CBSE SCH, NOLAMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6936",
            "district": "33",
            "Block": "403",
            "label": "GOVT HS, PALAVEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6937",
            "district": "33",
            "Block": "403",
            "label": "GOVT HSS, PAMMADUKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6938",
            "district": "33",
            "Block": "403",
            "label": "GOVT HSS, LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6939",
            "district": "33",
            "Block": "403",
            "label": "GHSS,PANDESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6940",
            "district": "33",
            "Block": "403",
            "label": "ADW MS, VELLANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6941",
            "district": "33",
            "Block": "403",
            "label": "GHS, ARIKKAMPEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6942",
            "district": "33",
            "Block": "403",
            "label": "GHS, KOLLUMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6943",
            "district": "33",
            "Block": "403",
            "label": "GHS KARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6944",
            "district": "33",
            "Block": "403",
            "label": "GHSS, MITTAMALLEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6945",
            "district": "33",
            "Block": "403",
            "label": "SIRAGU MONTESSORI  MITTANANALLEE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6946",
            "district": "33",
            "Block": "403",
            "label": "ADW HIGH SCH, MITTAMALLEE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6947",
            "district": "33",
            "Block": "403",
            "label": "KENDRIYA VID OCF, AVADI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6948",
            "district": "33",
            "Block": "403",
            "label": "CRPF KV CBSE, AVADI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6949",
            "district": "33",
            "Block": "403",
            "label": "GHSS SATHIYAMURTHY NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6950",
            "district": "33",
            "Block": "403",
            "label": "ST. THOMAS MATRICULATION SCHOOL, KOVILPATHAGAI, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6951",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL MATRICULATION HIGHER SECONDARY, MOGA-EAST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6952",
            "district": "33",
            "Block": "403",
            "label": "VIJAYANTA HIGHER SEC SCHOOL , AVADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6953",
            "district": "33",
            "Block": "403",
            "label": "MHS,AGRAHARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6954",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDHYASHRAM AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6955",
            "district": "33",
            "Block": "403",
            "label": "SRI RAMAKRISHNA MS,AMBATHUR 8",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6956",
            "district": "33",
            "Block": "403",
            "label": "KV AIRFORCE STATION IAF AVADI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6957",
            "district": "33",
            "Block": "403",
            "label": "PJ GUPTA HS AMBATTUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6958",
            "district": "33",
            "Block": "403",
            "label": "SRM HSS AMBATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6959",
            "district": "33",
            "Block": "403",
            "label": "VALLIAMMAL MAT VENKATAPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6960",
            "district": "33",
            "Block": "403",
            "label": "GOVT (G) HSS AMBATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6961",
            "district": "33",
            "Block": "403",
            "label": "NATHELLA VIDHYODAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6962",
            "district": "33",
            "Block": "403",
            "label": "HIGH SCHOOL T V NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6963",
            "district": "33",
            "Block": "403",
            "label": "HUSSAINY HS HUSSAINABAD",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6964",
            "district": "33",
            "Block": "403",
            "label": "ST JOSEPH MAT H S S AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6965",
            "district": "33",
            "Block": "403",
            "label": "AMBT MUN.HIGH.SCH CHOLOPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6966",
            "district": "33",
            "Block": "403",
            "label": "MUN.HSS KORATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6967",
            "district": "33",
            "Block": "403",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MUGAPAIR WEST - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6968",
            "district": "33",
            "Block": "403",
            "label": "BARATH MS, MUGAPPAIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6969",
            "district": "33",
            "Block": "403",
            "label": "BRITTANIA HS, PADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6970",
            "district": "33",
            "Block": "403",
            "label": "C.S.I EWART MATRICULATION HIGHER SECONDARY SCHOOL, ANNA NAGAR WEST EXTN,CHENNAI -101",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6971",
            "district": "33",
            "Block": "403",
            "label": "GOVT (B) HIGH, MUGAPAIR EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6972",
            "district": "33",
            "Block": "403",
            "label": "GOVT (G) HSS, MUGAPAIR EAST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6973",
            "district": "33",
            "Block": "403",
            "label": "CHENNAI PUBLIC SCHOOL, SECTOR-1",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6974",
            "district": "33",
            "Block": "403",
            "label": "BETHANY HSS, MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6975",
            "district": "33",
            "Block": "403",
            "label": "RAVINDRA BHARATHI GLOBAL SCHOOL ,MOGAPPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6976",
            "district": "33",
            "Block": "403",
            "label": "GHS, KOLADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6977",
            "district": "33",
            "Block": "403",
            "label": "GOVT HR.SEC.SCH,AYANAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6978",
            "district": "33",
            "Block": "403",
            "label": "DURGAPRASAD VIV.VID, AYANAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6979",
            "district": "33",
            "Block": "403",
            "label": "GHS, VELAPPANCHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6980",
            "district": "33",
            "Block": "403",
            "label": "SKDJ HSS, THIRUVERKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6981",
            "district": "33",
            "Block": "403",
            "label": "GOVT HIGHER SECONDARY SCHOOL, ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6982",
            "district": "33",
            "Block": "403",
            "label": "ARULMIGU MEENAKSHI AMMAN HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6983",
            "district": "33",
            "Block": "403",
            "label": "GOVT HSS,MADHURAVOYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6984",
            "district": "33",
            "Block": "403",
            "label": "OUR LADY MATRIC HR SEC SCHOOL ,MADHURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6985",
            "district": "33",
            "Block": "403",
            "label": "GOVT GIRLS HSS,PORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6986",
            "district": "33",
            "Block": "403",
            "label": "GOVT BOYS HSS,PORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6987",
            "district": "33",
            "Block": "403",
            "label": "LAMECH HSS,VALASARAVAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6988",
            "district": "33",
            "Block": "403",
            "label": "SRI VENIYAMMAAL MATRICULATION SCHOOL, RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6989",
            "district": "33",
            "Block": "403",
            "label": "GOVT HSS,RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6990",
            "district": "33",
            "Block": "403",
            "label": "RAMAKRISHNA VIDHYA NIKETHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6991",
            "district": "33",
            "Block": "403",
            "label": "GOVT HS VANAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6992",
            "district": "33",
            "Block": "403",
            "label": "ZAMAR MATRICULATION HR.SEC.SCHOOL, AYAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6993",
            "district": "33",
            "Block": "403",
            "label": "BHAKTAVATSALAM VID,KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6994",
            "district": "33",
            "Block": "403",
            "label": "MUNICIPAL HIGHER SECONDARY SCHOOL,ATHIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6995",
            "district": "33",
            "Block": "403",
            "label": "AVADI MUNICIPAL HIGHER SECONDARY SCHOOL,THIRUMULLAVOYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6996",
            "district": "33",
            "Block": "260",
            "label": "GOVERNMENT HIGH SCHOOL, KATHIRVEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6997",
            "district": "33",
            "Block": "260",
            "label": "GHS, MATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6998",
            "district": "33",
            "Block": "260",
            "label": "IMMANUVEL MS, SURAPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6999",
            "district": "33",
            "Block": "260",
            "label": "PUMS, KOSAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7000",
            "district": "33",
            "Block": "260",
            "label": "ADW (B) HSS, VADAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7001",
            "district": "33",
            "Block": "260",
            "label": "ADW (G) HSS, VADAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7002",
            "district": "33",
            "Block": "260",
            "label": "GOVT (B) HSS, NARAVARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7003",
            "district": "33",
            "Block": "260",
            "label": "GOVT (G) HSS, NARAVARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7004",
            "district": "33",
            "Block": "260",
            "label": "GOVT HSS, MANALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7005",
            "district": "33",
            "Block": "260",
            "label": "Dr. SIVANTHI ADITHANAR GIRLS HIGHER SECONDARY SCHOOL, MANALI , CHENNAI- 68",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7006",
            "district": "33",
            "Block": "260",
            "label": "J.G.CARODIA GOVT HSS, MADHAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7007",
            "district": "33",
            "Block": "260",
            "label": "ST.ANNE'S GIRLS HR.SEC.SCHOOL,MADHAVARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7008",
            "district": "33",
            "Block": "260",
            "label": "MUN HSS, LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7009",
            "district": "33",
            "Block": "260",
            "label": "POPPILIRAJA GOVT HSS, PUZHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7010",
            "district": "33",
            "Block": "260",
            "label": "ST. ANTONY'S MATRIC HIGHER SECONDARY SCHOOL, PUZHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7011",
            "district": "33",
            "Block": "260",
            "label": "GOVT HSS, KATHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7012",
            "district": "33",
            "Block": "260",
            "label": "GOVT HIGH SCHOOL, A.S.NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7013",
            "district": "33",
            "Block": "260",
            "label": "SAKAYA MATHA HS, A.S.NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7014",
            "district": "33",
            "Block": "260",
            "label": "JAI GOPAL CARODIA HSS THIRUVOTRIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7015",
            "district": "33",
            "Block": "260",
            "label": "THIRUTHANGAL NADAR VID, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7016",
            "district": "33",
            "Block": "260",
            "label": "SRI MAHAVEER JAIN HIGHER SECONDARY SCHOOL , THIRUVOTRIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7017",
            "district": "33",
            "Block": "260",
            "label": "VELLAIYAN CHETTIAR HSS, THIRUVOTRIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7018",
            "district": "33",
            "Block": "260",
            "label": "SRI V RAMAKRISHNA HSS, THIRUVOTRIYUR WARD 17",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7019",
            "district": "33",
            "Block": "260",
            "label": "OUR LADY HSS, THIRUVOTRIYUR WARD 22",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7020",
            "district": "33",
            "Block": "260",
            "label": "MUN HIGH SCHOOL, KALADIPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7021",
            "district": "33",
            "Block": "260",
            "label": "VENUS HIGH SCHOOL , THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7022",
            "district": "33",
            "Block": "260",
            "label": "ST PAULS MAHAJ. HSS, KALADIPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7023",
            "district": "33",
            "Block": "260",
            "label": "RAMAKRISHNA MATRIC, S, SATHANK",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7024",
            "district": "33",
            "Block": "260",
            "label": "GOVT GIRLS HIGH SCHOOL, SATHANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7025",
            "district": "33",
            "Block": "260",
            "label": "FATHER JESUS, THIRUVOTRIYUR WARD 44",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7026",
            "district": "33",
            "Block": "260",
            "label": "SMT MOHINI SARAOGI VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7027",
            "district": "33",
            "Block": "260",
            "label": "MHS, ERNAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7028",
            "district": "33",
            "Block": "364",
            "label": "GHSS-BUCHIREDDIPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7029",
            "district": "33",
            "Block": "364",
            "label": "GHSS - K.G.KANDIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7030",
            "district": "33",
            "Block": "364",
            "label": "ST.CHARLES HSS K.G.KANDIGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7031",
            "district": "33",
            "Block": "364",
            "label": "GHSS -MADDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7032",
            "district": "33",
            "Block": "364",
            "label": "GHS - MURUKKAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7033",
            "district": "33",
            "Block": "364",
            "label": "GHS - ISLAM NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7034",
            "district": "33",
            "Block": "364",
            "label": "GHS - AMIRTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7035",
            "district": "33",
            "Block": "364",
            "label": "CSI HIGH SCHOOL - DURAIVARIPET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7036",
            "district": "33",
            "Block": "364",
            "label": "GOVT(B) HSS-TIRUTTANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7037",
            "district": "33",
            "Block": "364",
            "label": "RADHAKRISHNAN MATRICULATION- TIRUTTANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7038",
            "district": "33",
            "Block": "364",
            "label": "GOVT(G) HSS-TIRUTTANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7039",
            "district": "33",
            "Block": "337",
            "label": "GOVT HSS, ARCOT KUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7040",
            "district": "33",
            "Block": "337",
            "label": "GHSS, ARUNGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7041",
            "district": "33",
            "Block": "337",
            "label": "GHS, CHIVADA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7042",
            "district": "33",
            "Block": "337",
            "label": "GHSS, KANAGAMMACHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7043",
            "district": "33",
            "Block": "337",
            "label": "GHS, KAVERIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7044",
            "district": "33",
            "Block": "337",
            "label": "GHS, MANAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7045",
            "district": "33",
            "Block": "337",
            "label": "GHS, NEMILI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7046",
            "district": "33",
            "Block": "337",
            "label": "GOVT ADW HS, PERIYAKALAKATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7047",
            "district": "33",
            "Block": "337",
            "label": "GHSS, POONIMANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7048",
            "district": "33",
            "Block": "337",
            "label": "GHS, THUMBIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7049",
            "district": "33",
            "Block": "337",
            "label": "GHSS, THIRUVALANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7050",
            "district": "33",
            "Block": "337",
            "label": "AISHIKA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7051",
            "district": "33",
            "Block": "225",
            "label": "GHS, KARIMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7052",
            "district": "33",
            "Block": "225",
            "label": "GOVT HS, KEECHALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7053",
            "district": "33",
            "Block": "225",
            "label": "GHS, KESAVARAJAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7054",
            "district": "33",
            "Block": "225",
            "label": "GOVT (G) HS, ATHIMANJERIPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7055",
            "district": "33",
            "Block": "225",
            "label": "GOVT (B) HSS, ATHIMANJERIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7056",
            "district": "33",
            "Block": "225",
            "label": "GHS, SUNDARESAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7057",
            "district": "33",
            "Block": "225",
            "label": "GHS, KONASAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7058",
            "district": "33",
            "Block": "225",
            "label": "GOVTHSS, SORAIKKAIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7059",
            "district": "33",
            "Block": "225",
            "label": "GOVT HS, NEDIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7060",
            "district": "33",
            "Block": "225",
            "label": "GOVT HS, NOCHILI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7061",
            "district": "33",
            "Block": "225",
            "label": "SH HSS, PANDRAVEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7062",
            "district": "33",
            "Block": "225",
            "label": "GHS, NALLAVANAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7063",
            "district": "33",
            "Block": "225",
            "label": "GHS, BOMMARAJUPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7064",
            "district": "33",
            "Block": "225",
            "label": "GOVT HS, VELIAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7065",
            "district": "33",
            "Block": "225",
            "label": "ST MARYS MAT, POTHATURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7066",
            "district": "33",
            "Block": "225",
            "label": "GOVT (G) HSS, POTHATURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7067",
            "district": "33",
            "Block": "225",
            "label": "GOVT (B) HSS, POTHATURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7068",
            "district": "33",
            "Block": "225",
            "label": "GOVT BOYS HSS, PALLIPAT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7069",
            "district": "33",
            "Block": "225",
            "label": "GOVT (G) HSS, PALLIPAT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7070",
            "district": "33",
            "Block": "261",
            "label": "A. M. K GOVT (G) HSS,AMMAYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7071",
            "district": "33",
            "Block": "261",
            "label": "GHS, KONDAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7072",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS ASWARANTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7073",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS, AYYANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7074",
            "district": "33",
            "Block": "261",
            "label": "GOVTHSS, BALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7075",
            "district": "33",
            "Block": "261",
            "label": "GHS,CHANNURMALLAVARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7076",
            "district": "33",
            "Block": "261",
            "label": "SIVARANJINI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7077",
            "district": "33",
            "Block": "261",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,SENGATANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7078",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS,PERIYANAGAPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7079",
            "district": "33",
            "Block": "261",
            "label": "GOVT (G) HSS, R.K.PET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7080",
            "district": "33",
            "Block": "261",
            "label": "GOVT (B) HSS, R.K.PET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7081",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS,RAJANAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7082",
            "district": "33",
            "Block": "261",
            "label": "GOVT HSS,SRIKALIKAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7083",
            "district": "33",
            "Block": "261",
            "label": "GOVT HSS,VANGANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7084",
            "district": "33",
            "Block": "261",
            "label": "GOVT HSS,VEDIYANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7085",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS,VEERAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20891",
            "district": "33",
            "Block": "338",
            "label": "GUILD OF SERVICES BALAVIGHAR SPL SCH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20892",
            "district": "33",
            "Block": "252",
            "label": "SAFA N&P SCHOOL AVADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20894",
            "district": "33",
            "Block": "184",
            "label": "OPPORTUNITY SCHOOL , OLD NAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20895",
            "district": "33",
            "Block": "252",
            "label": "DON GUANELLA SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20896",
            "district": "33",
            "Block": "252",
            "label": "SHRI.M.A.KRISHNASWAMY MATRICULATION HIGHER SECONDARY SCHOOL.AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20897",
            "district": "33",
            "Block": "403",
            "label": "GOOD WORD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20898",
            "district": "33",
            "Block": "260",
            "label": "ST.CHARLES MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20911",
            "district": "33",
            "Block": "364",
            "label": "SHAKTHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20922",
            "district": "33",
            "Block": "76",
            "label": "PUMS SANAPUTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20925",
            "district": "33",
            "Block": "225",
            "label": "CSI MS, NEDUGAL COLONY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20926",
            "district": "33",
            "Block": "338",
            "label": "GRACY SCH FOR MR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20927",
            "district": "33",
            "Block": "403",
            "label": "VASANTHAM SPL SCH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20930",
            "district": "33",
            "Block": "252",
            "label": "OXFORD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20931",
            "district": "33",
            "Block": "252",
            "label": "FAITH SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20933",
            "district": "33",
            "Block": "403",
            "label": "HOPE TRUST AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20934",
            "district": "33",
            "Block": "403",
            "label": "BALAVIGASH SPL SCH AVADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20935",
            "district": "33",
            "Block": "403",
            "label": "CARMEL SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20936",
            "district": "33",
            "Block": "403",
            "label": "JAYAM SPL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20937",
            "district": "33",
            "Block": "403",
            "label": "SRI VENKATESWARA VIDHYALAYA SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20938",
            "district": "33",
            "Block": "403",
            "label": "VEL TECH Dr RR & Dr SR MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20939",
            "district": "33",
            "Block": "403",
            "label": "MANASA SPL SC",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20940",
            "district": "33",
            "Block": "403",
            "label": "SATHYALOK SCH FOR SPL CHILDREN(MR)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21000",
            "district": "33",
            "Block": "64",
            "label": "SUGUNAMMAL  MATRIC SCHOOL VENGAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21001",
            "district": "33",
            "Block": "252",
            "label": "JAYA PUBLIC SCHOOL, Thiruninravur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21003",
            "district": "33",
            "Block": "252",
            "label": "SRI BUDDHA VIDYA MANDIR MATRIC HR SEC SCHOOL, THIRUMAZHISAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21004",
            "district": "33",
            "Block": "289",
            "label": "DR.GUPTA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21005",
            "district": "33",
            "Block": "403",
            "label": "ANNAI TERASA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21006",
            "district": "33",
            "Block": "225",
            "label": "SRI GNANA POORNA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21045",
            "district": "33",
            "Block": "253",
            "label": "VIVEKANANDA VISION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21046",
            "district": "33",
            "Block": "253",
            "label": "Dr.S.Ramakrishnan Memorial CBSE School,Old Thirupachur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21048",
            "district": "33",
            "Block": "403",
            "label": "AIR FORCE SCHOOL AVADI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21145",
            "district": "33",
            "Block": "252",
            "label": "SANA MODEL SCHOOL KATTUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21173",
            "district": "33",
            "Block": "403",
            "label": "PON VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21174",
            "district": "33",
            "Block": "403",
            "label": "DEVI ACAD SENIOR SEC SCH,VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21235",
            "district": "33",
            "Block": "252",
            "label": "THE PUPIL SAVEETHA ECO SCHOOL SENNEERKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21251",
            "district": "33",
            "Block": "403",
            "label": "OCF CENTRAL GOVERMENT SCHOOL",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "21254",
            "district": "33",
            "Block": "252",
            "label": "KARTHIKEYAN MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21255",
            "district": "33",
            "Block": "403",
            "label": "SRI DEVI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21294",
            "district": "33",
            "Block": "253",
            "label": "SHREE NIKETAN PATASALA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21295",
            "district": "33",
            "Block": "260",
            "label": "GODSON PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21296",
            "district": "33",
            "Block": "260",
            "label": "VRUKSHA VIDYAASHRAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21360",
            "district": "33",
            "Block": "338",
            "label": "GOVT. GIRLS HS VELLIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21364",
            "district": "33",
            "Block": "87",
            "label": "GHS PAPPARAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21392",
            "district": "33",
            "Block": "289",
            "label": "GHS,ORAKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21397",
            "district": "33",
            "Block": "403",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, AYAPPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21398",
            "district": "33",
            "Block": "403",
            "label": "GHS , KARLAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21424",
            "district": "33",
            "Block": "261",
            "label": "Govt Girls High school,Vanganoor",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21484",
            "district": "33",
            "Block": "338",
            "label": "ADW HS PUNGATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21563",
            "district": "33",
            "Block": "403",
            "label": "AMHS KOVILPATHAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21578",
            "district": "33",
            "Block": "260",
            "label": "KUMARAN CWSN\tSchool",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21579",
            "district": "33",
            "Block": "260",
            "label": "ANBALAYA CWSN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21632",
            "district": "33",
            "Block": "64",
            "label": "G.T.VIDHYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21696",
            "district": "33",
            "Block": "261",
            "label": "SRI AKSHAYA VIDYALAYA MATRICULATION SCHOOL , AMMAYARKUPPAM ROAD , RKPET , THIRUVALLUR DISTRICT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21697",
            "district": "33",
            "Block": "261",
            "label": "BILLABONG HIGH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21698",
            "district": "33",
            "Block": "403",
            "label": "THE NAZARETH ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21699",
            "district": "33",
            "Block": "403",
            "label": "VIJAYANTA MODEL HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21700",
            "district": "33",
            "Block": "403",
            "label": "ANNAI VIOLET INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21701",
            "district": "33",
            "Block": "403",
            "label": "EBENEZER MARCUS INTERNATIONAL SCHOOL & JR. COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21702",
            "district": "33",
            "Block": "403",
            "label": "ARULMIGU MEENAKSHIAMMAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21703",
            "district": "33",
            "Block": "403",
            "label": "V.N.R. VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21704",
            "district": "33",
            "Block": "403",
            "label": "LA CHATELAINE JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21705",
            "district": "33",
            "Block": "76",
            "label": "R.M.K PATASHAALA CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21706",
            "district": "33",
            "Block": "76",
            "label": "T.J.S MATRIC HR.SEC.SCHOOL,PERUVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21707",
            "district": "33",
            "Block": "184",
            "label": "ST MARKS MATRIC SCH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21708",
            "district": "33",
            "Block": "252",
            "label": "MAHALAKSHMI VIDHYA MANDHIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21709",
            "district": "33",
            "Block": "252",
            "label": "VELAMMAL VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21710",
            "district": "33",
            "Block": "252",
            "label": "VGN CHINMAYA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21711",
            "district": "33",
            "Block": "252",
            "label": "S.S.CITIZEN SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21712",
            "district": "33",
            "Block": "252",
            "label": "JAI HIND MATRICULATION SCHOOL,THIRUMAZHISAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21713",
            "district": "33",
            "Block": "252",
            "label": "SUDHARSANAM VIDYAASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21714",
            "district": "33",
            "Block": "64",
            "label": "J.N.N VIDYALLAYA ,KANNIGAIPER",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21715",
            "district": "33",
            "Block": "87",
            "label": "VISHWAKSENA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21716",
            "district": "33",
            "Block": "253",
            "label": "QALAM INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21717",
            "district": "33",
            "Block": "338",
            "label": "VELAMMAL ACADEMY MATRICULATION SCHOOL PAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21915",
            "district": "33",
            "Block": "252",
            "label": "SPARTAN INTERNATIONAL SCHOOL CHEMBARAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21916",
            "district": "33",
            "Block": "253",
            "label": "ST.XAVIERS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21941",
            "district": "33",
            "Block": "403",
            "label": "NETHRODAYA SPECIAL SCHOOL FOR VI & LD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21942",
            "district": "33",
            "Block": "403",
            "label": "NARENDRA MATRIC SCHOOL,KOVILPATHAGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21943",
            "district": "33",
            "Block": "403",
            "label": "BHAKTAATSALAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21944",
            "district": "33",
            "Block": "403",
            "label": "NAZARETH MHSS, KANNADAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21945",
            "district": "33",
            "Block": "252",
            "label": "RCM HIGH SCHOOL KARAYANCHAVADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21946",
            "district": "33",
            "Block": "338",
            "label": "SRI.R.M.JAIN VIDHYAPEETH MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21952",
            "district": "33",
            "Block": "403",
            "label": "AJAY SPECIAL SCHOOL, COLLECTOR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21953",
            "district": "33",
            "Block": "252",
            "label": "NAZARETH MATRIC HR SEC SCHOOL TNHB, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21973",
            "district": "33",
            "Block": "338",
            "label": "ABS VIDHYAALAYAA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21983",
            "district": "33",
            "Block": "338",
            "label": "VIVEKANANDA VIDHYALAYA MATRIC",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21984",
            "district": "33",
            "Block": "252",
            "label": "SRI RAM VIDHYA MANDIR KOTTAMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21985",
            "district": "33",
            "Block": "252",
            "label": "AL-HIKMA MADARASA & EDUCATIONAL CENTER",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21986",
            "district": "33",
            "Block": "252",
            "label": "VELAMMAL MATRIC HR SEC SCHOOL, PARUTHIPATTU,  AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21987",
            "district": "33",
            "Block": "289",
            "label": "RDS VIDYA MANTHIR NUR & PRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21988",
            "district": "33",
            "Block": "403",
            "label": "GREAT HARVEST VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21989",
            "district": "33",
            "Block": "403",
            "label": "GODSON UDAYA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21990",
            "district": "33",
            "Block": "403",
            "label": "PASUMPON NARAYANA e-TECHNO SCHOOL, AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21991",
            "district": "33",
            "Block": "403",
            "label": "SPARTAN EXCLUSIVE SCHOOL , MOGAPPAIR EAST",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21992",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA OLYMPIAD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21993",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDYALAYA, MELAYANAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21994",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA E-TECHNO SCH , VELLAPAN CHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21995",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA OLYMPIAD SCHOOL, OMSAKTHINAGAR, VANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21996",
            "district": "33",
            "Block": "403",
            "label": "DAYASADAN AGARWAL VIDYALAYA, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21997",
            "district": "33",
            "Block": "403",
            "label": "ADW HS, MADHURAVOYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21998",
            "district": "33",
            "Block": "403",
            "label": "EVERWIN PUBLIC SCHOOL, MADURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21999",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDYALAYA, ALAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22000",
            "district": "33",
            "Block": "403",
            "label": "MADHA PUBLIC SCHOOL, VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22001",
            "district": "33",
            "Block": "260",
            "label": "ST.ANNES MAT SCH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22002",
            "district": "33",
            "Block": "260",
            "label": "GREENFIELD CHENNAI INTERNATIONAL SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22003",
            "district": "33",
            "Block": "260",
            "label": "VELAMMAL GLOBAL INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22004",
            "district": "33",
            "Block": "364",
            "label": "SRI VIDYA NIKETAN MATRIC HIGHER SECONDARY  SCHOOL E.N KANDIGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22005",
            "district": "33",
            "Block": "364",
            "label": "GRT MAHALAKSMI VIDHYALAYA CBSE SCHOOL,PATTABIRAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22006",
            "district": "33",
            "Block": "337",
            "label": "ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22304",
            "district": "33",
            "Block": "87",
            "label": "GHS VENMANAMBUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22305",
            "district": "33",
            "Block": "87",
            "label": "DMI ST.JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22306",
            "district": "33",
            "Block": "87",
            "label": "MOTHER TERESA MEMORIAL RESIDENTIAL SPECIAL SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22307",
            "district": "33",
            "Block": "252",
            "label": "MAHAVEER RAJASTHANI INTERNATIONAL SCHOOL, GUDAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22308",
            "district": "33",
            "Block": "252",
            "label": "VELAMMAL VIDYALAYA SENNEERKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22309",
            "district": "33",
            "Block": "252",
            "label": "SRI GURU AKKASAMY VELAMMAL MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22310",
            "district": "33",
            "Block": "252",
            "label": "RBS NURSERY AND PRIMARY KONAMBEDU, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22311",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL HIGH SCHOOL PATTABIRAM RAILWAY SIDING",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22312",
            "district": "33",
            "Block": "184",
            "label": "GHS, MANALI NEW TOWN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22313",
            "district": "33",
            "Block": "403",
            "label": "GHS, VEERAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22314",
            "district": "33",
            "Block": "403",
            "label": "RAVINDRA BHARATHI CBSE SCHOOL, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22315",
            "district": "33",
            "Block": "403",
            "label": "THE SCHRAM ACADAMY NOLAMBUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22316",
            "district": "33",
            "Block": "403",
            "label": "AMBATTUR MUNICIPAL HIGH SCHOOL KAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22317",
            "district": "33",
            "Block": "403",
            "label": "BRIGHT THE LEARNING CENTER",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22318",
            "district": "33",
            "Block": "403",
            "label": "SANKALP - THE LEARNING CENTER",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22319",
            "district": "33",
            "Block": "403",
            "label": "MUN HIGH SCH, PADI KUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22320",
            "district": "33",
            "Block": "403",
            "label": "SRISHTI SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22321",
            "district": "33",
            "Block": "403",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22322",
            "district": "33",
            "Block": "403",
            "label": "THE SCRAM ACADEMY AYANAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22323",
            "district": "33",
            "Block": "403",
            "label": "ACHARIYA SIKSHA MANDIR , ALAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22324",
            "district": "33",
            "Block": "403",
            "label": "VEDANTA ACADEMY SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22325",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDYALAYA (ANNEXURE) SR.SEC.SCHOOL. MEL AYANAMBAKKAM, CHENNAI 95.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22326",
            "district": "33",
            "Block": "403",
            "label": "MANGALAM VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22327",
            "district": "33",
            "Block": "260",
            "label": "EVERWIN VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22328",
            "district": "33",
            "Block": "260",
            "label": "GHS, CHINNA SEKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22329",
            "district": "33",
            "Block": "260",
            "label": "PADMASHREE  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22330",
            "district": "33",
            "Block": "260",
            "label": "GHS, KANNAPPASAMY NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22331",
            "district": "33",
            "Block": "364",
            "label": "GHS, V C R KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22332",
            "district": "33",
            "Block": "364",
            "label": "GHS, VELANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22687",
            "district": "33",
            "Block": "252",
            "label": "MAHARISHI VIDYA MANDIR SR SEC SCHOOL, MELPAKKAM, AVADI-77",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22718",
            "district": "33",
            "Block": "252",
            "label": "CPS GLOBAL SCHOOL, THIRUMAZISAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22719",
            "district": "33",
            "Block": "252",
            "label": "ADITYA VIDYASHRAM , VEERARAGAVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22720",
            "district": "33",
            "Block": "403",
            "label": "SCOPE GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22721",
            "district": "33",
            "Block": "403",
            "label": "ADW HS, VALASARA VAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22722",
            "district": "33",
            "Block": "260",
            "label": "VELLAMMAL NEW GEN SCHOOL , SURAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22723",
            "district": "33",
            "Block": "260",
            "label": "VELLAMMAL NEW GEN SCHOOL MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22724",
            "district": "33",
            "Block": "260",
            "label": "SRI SATHYA SAI VIDYA VIHAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22725",
            "district": "33",
            "Block": "261",
            "label": "SRI ARUTSAI VETRI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22726",
            "district": "33",
            "Block": "403",
            "label": "ALPHA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22762",
            "district": "33",
            "Block": "403",
            "label": "SRI CHITHANYA SCHOOL, NOLAMBUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22877",
            "district": "33",
            "Block": "403",
            "label": "MUNICIPAL HIGH SCHOOL, KALLIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22878",
            "district": "33",
            "Block": "403",
            "label": "GHS METTUKUPPAM (N)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22879",
            "district": "33",
            "Block": "184",
            "label": "GHS, A.REDDY PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22899",
            "district": "33",
            "Block": "261",
            "label": "GHS, ADHIVARAGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22915",
            "district": "33",
            "Block": "225",
            "label": "K V PATSHALA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22953",
            "district": "33",
            "Block": "403",
            "label": "SRI CHAITANYA SCHOOL, RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23001",
            "district": "33",
            "Block": "403",
            "label": "SRI VIMALA VIDYALAYA CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23039",
            "district": "33",
            "Block": "252",
            "label": "PUSHKARA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23048",
            "district": "33",
            "Block": "76",
            "label": "TJS PUBLIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23079",
            "district": "33",
            "Block": "260",
            "label": "ACHARYA MAHASHRAMAN TERAPANTH JAIN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23117",
            "district": "33",
            "Block": "260",
            "label": "SRI CHAITANYA TECHNO SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23118",
            "district": "33",
            "Block": "403",
            "label": "THE LEO ACADEMY OF EXCELLENCE (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23134",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA E-TECHNO SCHOOL,RAGHAVENDRA NAGAR,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23147",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDYALAYA PORUR CHENNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23148",
            "district": "33",
            "Block": "260",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23190",
            "district": "33",
            "Block": "403",
            "label": "RUDRAPPASAMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23191",
            "district": "33",
            "Block": "260",
            "label": "SRI VENKATESWARA PUBLIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23214",
            "district": "33",
            "Block": "76",
            "label": "ELITE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23215",
            "district": "33",
            "Block": "403",
            "label": "SWAMY'S SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23220",
            "district": "33",
            "Block": "252",
            "label": "SINDHI MODEL SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23234",
            "district": "33",
            "Block": "184",
            "label": "GOVERNMENT HIGH SCHOOL , JAMELAPATH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23239",
            "district": "33",
            "Block": "364",
            "label": "TRS GLOBAL PUBLIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23252",
            "district": "33",
            "Block": "252",
            "label": "JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA HIGHER SECONDARY SCHOOL, AVADI, CHENNAI - 54",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23261",
            "district": "33",
            "Block": "403",
            "label": "D.A.V SECONDARY SCHOOL, MOGAPPAIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23269",
            "district": "33",
            "Block": "403",
            "label": "MARIAN SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23291",
            "district": "33",
            "Block": "252",
            "label": "SRI CHAITANYA TECHNO SCHOOL, POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23309",
            "district": "33",
            "Block": "260",
            "label": "VELAMMAL NEW - GEN SCHOOL , THIRUVOTTIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23334",
            "district": "33",
            "Block": "260",
            "label": "OUR LADY SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23335",
            "district": "33",
            "Block": "403",
            "label": "GREEN VALLEY CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23336",
            "district": "33",
            "Block": "403",
            "label": "M.G.R. ADARSH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23337",
            "district": "33",
            "Block": "252",
            "label": "JOSEPH VIDYA KSHETRA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23338",
            "district": "33",
            "Block": "252",
            "label": "GRACE PARK PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23417",
            "district": "33",
            "Block": "403",
            "label": "PRS Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23601",
            "district": "33",
            "Block": "76",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23602",
            "district": "33",
            "Block": "252",
            "label": "AVPT  SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23603",
            "district": "33",
            "Block": "260",
            "label": "Olive Tree Global School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23604",
            "district": "33",
            "Block": "260",
            "label": "PRIM BUDS GARDEN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23605",
            "district": "33",
            "Block": "261",
            "label": "MY DREAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23606",
            "district": "33",
            "Block": "261",
            "label": "SRI VAARIYAR VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23607",
            "district": "33",
            "Block": "289",
            "label": "SRI KRISH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23608",
            "district": "33",
            "Block": "289",
            "label": "GFC GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23609",
            "district": "33",
            "Block": "337",
            "label": "EVERGREEN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23610",
            "district": "33",
            "Block": "338",
            "label": "TIRUVALLUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23611",
            "district": "33",
            "Block": "403",
            "label": "AACHI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23612",
            "district": "33",
            "Block": "403",
            "label": "KALAIMAGAL VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23613",
            "district": "33",
            "Block": "403",
            "label": "BETHEL GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23614",
            "district": "33",
            "Block": "403",
            "label": "MAR GREGORIOS PUBLIC SCHOOL, MOGAPPAIR , WEST,SECTOR-7",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23615",
            "district": "33",
            "Block": "403",
            "label": "CPS GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23616",
            "district": "33",
            "Block": "403",
            "label": "SV HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23617",
            "district": "33",
            "Block": "403",
            "label": "ABS GLOBAL SMART SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23775",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PUNNAPAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23776",
            "district": "33",
            "Block": "64",
            "label": "PUMS, SEMBEDU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23879",
            "district": "33",
            "Block": "184",
            "label": "BHAGAVAN SRI KRISHNA VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23905",
            "district": "33",
            "Block": "225",
            "label": "TVM MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23906",
            "district": "33",
            "Block": "225",
            "label": "MOTHER TERESA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23930",
            "district": "33",
            "Block": "252",
            "label": "The Pupil(International School)",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23931",
            "district": "33",
            "Block": "252",
            "label": "VELAMMAL ACADEMY",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23932",
            "district": "33",
            "Block": "252",
            "label": "JAI HIND PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23933",
            "district": "33",
            "Block": "253",
            "label": "SRI NARAYANA MATRICULATION SCHOOL , KOLUNDHALUR",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24019",
            "district": "33",
            "Block": "338",
            "label": "TIRUVALLUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24078",
            "district": "33",
            "Block": "403",
            "label": "GOOD SHEPHERDS GLOBAL MATRIC SCHOOL, METTUPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24079",
            "district": "33",
            "Block": "403",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24080",
            "district": "33",
            "Block": "403",
            "label": "ABS GLOBAL SMART SCHOOL ,  ANNANUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24081",
            "district": "33",
            "Block": "403",
            "label": "TI SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24082",
            "district": "33",
            "Block": "403",
            "label": "MAHARISHI VIDYA MANDIR NOLAMBUR",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "34": [
        {
            "key": "382",
            "district": "34",
            "Block": "27",
            "label": "ACS MATRIC HSS, ARNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key" :"24121", "district" :"34", 
            "Block" :"318", 
            "label" : "SRI MAHAVEER MATRICULATION SCHOOL - GUNAMBADI", 
            "Sch_type" : "Un-aided", "Category": "High School"
        },
        {
            "key": "23658",
            "district": "34",
            "Block": "365",
            "label": "Danish Mission Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "397",
            "district": "34",
            "Block": "125",
            "label": "SACRED HEART MATRIC AVOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "406",
            "district": "34",
            "Block": "27",
            "label": "ST MARY'S MATRIC HSS ARNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1368",
            "district": "34",
            "Block": "238",
            "label": "SURAJ MATRICULATION HR SEC SCHOOL MELATHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1393",
            "district": "34",
            "Block": "365",
            "label": "MARY MEMORIAL MATRIC TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1741",
            "district": "34",
            "Block": "27",
            "label": "BEST MATRIC HIGHER SECONDARY SCHOOL  ARNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1766",
            "district": "34",
            "Block": "256",
            "label": "ST. ANTONY'S MATRICULATION SCHOOL KANJI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1854",
            "district": "34",
            "Block": "412",
            "label": "CLUNY MATRIC DEVIGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1865",
            "district": "34",
            "Block": "358",
            "label": "PUMS MARUTHUVAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1875",
            "district": "34",
            "Block": "249",
            "label": "PINKZ PUBLIC SCHOOL, KASTHAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1887",
            "district": "34",
            "Block": "412",
            "label": "VELS VIDHYASHRAM MATRIC HSS, KANNAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1894",
            "district": "34",
            "Block": "238",
            "label": "PUMS MELSATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1915",
            "district": "34",
            "Block": "238",
            "label": "PUMS KADAPPANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1933",
            "district": "34",
            "Block": "48",
            "label": "OXFORD MATRIC SCHOOL CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1966",
            "district": "34",
            "Block": "412",
            "label": "SRI VIJAY MATRIC HR SEC SCHOOL VANNANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2000",
            "district": "34",
            "Block": "238",
            "label": "PUMS VILANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2050",
            "district": "34",
            "Block": "238",
            "label": "PUMS KOZHIPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2076",
            "district": "34",
            "Block": "27",
            "label": "ST. JOSEPH'S MATRIC HR SEC SCHOOL  ARNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2131",
            "district": "34",
            "Block": "358",
            "label": "SRGDS MATRIC HSS VADAANDAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2218",
            "district": "34",
            "Block": "238",
            "label": "CARMEL MATRIC HIGHER SECONDARY SCHOOL KOLLAPPALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2222",
            "district": "34",
            "Block": "238",
            "label": "PUMS NAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2227",
            "district": "34",
            "Block": "365",
            "label": "GANDHI NAGAR MATRIC HSS -SO.KILNACHIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2270",
            "district": "34",
            "Block": "312",
            "label": "VETRI VIKAAS MATRICULATION SCHOOL-VAZHAVACHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2281",
            "district": "34",
            "Block": "238",
            "label": "PUMS SEMMAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2316",
            "district": "34",
            "Block": "312",
            "label": "LOURDU MATHA MATRIC HR SEC SCHOOL THANDRAMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2343",
            "district": "34",
            "Block": "395",
            "label": "PUMS IRUMARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2356",
            "district": "34",
            "Block": "412",
            "label": "PUMS  VANNANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2373",
            "district": "34",
            "Block": "14",
            "label": "PUMS MELNEMELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2374",
            "district": "34",
            "Block": "93",
            "label": "PUMS PATHIYAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2382",
            "district": "34",
            "Block": "27",
            "label": "PUMS POOSIMALAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2392",
            "district": "34",
            "Block": "238",
            "label": "PUMS ARASAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2395",
            "district": "34",
            "Block": "27",
            "label": "PUMS PANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2405",
            "district": "34",
            "Block": "412",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2412",
            "district": "34",
            "Block": "412",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2413",
            "district": "34",
            "Block": "238",
            "label": "PUMS ANMARUDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2418",
            "district": "34",
            "Block": "14",
            "label": "PUMS ALATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2420",
            "district": "34",
            "Block": "412",
            "label": "PUMS AAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2435",
            "district": "34",
            "Block": "238",
            "label": "PUMS KONAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2441",
            "district": "34",
            "Block": "395",
            "label": "PUMS THIRUPPANAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2443",
            "district": "34",
            "Block": "412",
            "label": "PUMS KAMAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2489",
            "district": "34",
            "Block": "365",
            "label": "PUMS SU. ANDAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2492",
            "district": "34",
            "Block": "412",
            "label": "PUMS PALAYA EGAMBARANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2493",
            "district": "34",
            "Block": "14",
            "label": "PUMS MADIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2544",
            "district": "34",
            "Block": "93",
            "label": "PUMS ANAIVADI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2546",
            "district": "34",
            "Block": "365",
            "label": "PUMS MELATHIKKAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2552",
            "district": "34",
            "Block": "44",
            "label": "BARATH MAT  HSS CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2578",
            "district": "34",
            "Block": "48",
            "label": "PUMS SIRUVELIYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2582",
            "district": "34",
            "Block": "365",
            "label": "PUMS CHINNAKALLAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2587",
            "district": "34",
            "Block": "395",
            "label": "PUMS AZHINJALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2588",
            "district": "34",
            "Block": "47",
            "label": "ANSALDO MATRIC MARUTHUVAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2589",
            "district": "34",
            "Block": "365",
            "label": "ADWMS SU.VALAVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2617",
            "district": "34",
            "Block": "412",
            "label": "PUMS KAMAKKURPALAYAM (WEST)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2638",
            "district": "34",
            "Block": "125",
            "label": "ANNAAMALAI MATRIC HR SEC SCHOOL SO PUDHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2644",
            "district": "34",
            "Block": "48",
            "label": "PUMS-KILPUDUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2649",
            "district": "34",
            "Block": "27",
            "label": "PUMS ONDIGUDISAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2661",
            "district": "34",
            "Block": "365",
            "label": "PUMS KALLANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2663",
            "district": "34",
            "Block": "312",
            "label": "RCM MS S.ANDAPATTU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2666",
            "district": "34",
            "Block": "47",
            "label": "PUMS KARUNGALIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2686",
            "district": "34",
            "Block": "238",
            "label": "PUMS THAVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2688",
            "district": "34",
            "Block": "412",
            "label": "PUMS SRINIVASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2694",
            "district": "34",
            "Block": "47",
            "label": "PUMS UTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2701",
            "district": "34",
            "Block": "47",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2703",
            "district": "34",
            "Block": "27",
            "label": "ST.GEMMA MATRIC.HR.SEC.SCHOOL RANDHAMKORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2705",
            "district": "34",
            "Block": "47",
            "label": "PUMS GENGAISOODAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2713",
            "district": "34",
            "Block": "238",
            "label": "PUMS ANAIBOGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2717",
            "district": "34",
            "Block": "48",
            "label": "MMS-BARITHIPURAM (WEST)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2719",
            "district": "34",
            "Block": "47",
            "label": "PUMS SEMMIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2721",
            "district": "34",
            "Block": "47",
            "label": "PUMS MARUTHUVAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2732",
            "district": "34",
            "Block": "365",
            "label": "DR. VGN MATRIC HSS SAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2735",
            "district": "34",
            "Block": "412",
            "label": "PUMS(MUSLIM) KANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2751",
            "district": "34",
            "Block": "48",
            "label": "PUMS THOZHUPPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2766",
            "district": "34",
            "Block": "47",
            "label": "PUMS MATTAPIRAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2777",
            "district": "34",
            "Block": "365",
            "label": "RCM MS ALLIKONDAPATU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2811",
            "district": "34",
            "Block": "365",
            "label": "PUMS NALLAN PILLAI PETRAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2837",
            "district": "34",
            "Block": "27",
            "label": "PUMS MGR NAGAR PAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2845",
            "district": "34",
            "Block": "412",
            "label": "AIM MATRICULATION HSS  AAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2863",
            "district": "34",
            "Block": "412",
            "label": "PUMS PUNGAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2864",
            "district": "34",
            "Block": "412",
            "label": "PUMS ARAIYALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2867",
            "district": "34",
            "Block": "358",
            "label": "PUMS KARUMARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2868",
            "district": "34",
            "Block": "48",
            "label": "PUMS THANDARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2889",
            "district": "34",
            "Block": "44",
            "label": "PUMS KOTTAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2891",
            "district": "34",
            "Block": "412",
            "label": "PUMS MARUSUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2893",
            "district": "34",
            "Block": "44",
            "label": "PUMS MELPENNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2924",
            "district": "34",
            "Block": "14",
            "label": "PUMS PAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2945",
            "district": "34",
            "Block": "27",
            "label": "ADWMS PAPPAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2957",
            "district": "34",
            "Block": "27",
            "label": "MMS SHAFFIULLA ST ARNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2968",
            "district": "34",
            "Block": "14",
            "label": "PUMS ERUMAIVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2983",
            "district": "34",
            "Block": "47",
            "label": "DANIE MATRIC SCHOOL -CHETPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2988",
            "district": "34",
            "Block": "27",
            "label": "MMS SARPANAPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2989",
            "district": "34",
            "Block": "256",
            "label": "PUMS VADAMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2995",
            "district": "34",
            "Block": "238",
            "label": "BAVANI AIDED MIDDLE VEPPAMPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3036",
            "district": "34",
            "Block": "27",
            "label": "MMS THACHUR ROAD ( SS KOIL ST)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3042",
            "district": "34",
            "Block": "365",
            "label": "PUMS VELAYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3074",
            "district": "34",
            "Block": "412",
            "label": "PUMS PERIYA IYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3082",
            "district": "34",
            "Block": "93",
            "label": "PUMS SHOLAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3085",
            "district": "34",
            "Block": "365",
            "label": "PUMS PANDITHAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3090",
            "district": "34",
            "Block": "395",
            "label": "PUMS SOZHAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3097",
            "district": "34",
            "Block": "395",
            "label": "PUMS PILLANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3122",
            "district": "34",
            "Block": "256",
            "label": "PUMS PADIAGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3124",
            "district": "34",
            "Block": "365",
            "label": "PUMS ISUKAZHIKATTERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3125",
            "district": "34",
            "Block": "93",
            "label": "PUMS PAZHANKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3127",
            "district": "34",
            "Block": "93",
            "label": "PUMS VEERALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3140",
            "district": "34",
            "Block": "47",
            "label": "PUMS SANIKAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3142",
            "district": "34",
            "Block": "27",
            "label": "MMS, P A KOIL ST, ARNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3154",
            "district": "34",
            "Block": "412",
            "label": "PUMS KARIPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3173",
            "district": "34",
            "Block": "365",
            "label": "ADWMS ADAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3191",
            "district": "34",
            "Block": "93",
            "label": "GOVT MIDDLE KETTAVARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3197",
            "district": "34",
            "Block": "27",
            "label": "PUMS SUBANRAO PETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3210",
            "district": "34",
            "Block": "256",
            "label": "PUMS KALLARAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3230",
            "district": "34",
            "Block": "387",
            "label": "PUMS ARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3283",
            "district": "34",
            "Block": "387",
            "label": "SENDHAMIZH MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3328",
            "district": "34",
            "Block": "238",
            "label": "PUMS -MELNANDIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3336",
            "district": "34",
            "Block": "256",
            "label": "PUMS VEERANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3353",
            "district": "34",
            "Block": "312",
            "label": "CHRIST JOTHI MATRIC HSS THANIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3354",
            "district": "34",
            "Block": "365",
            "label": "PUMS SU. NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3374",
            "district": "34",
            "Block": "412",
            "label": "DESIA MATRICULATION-KATTUKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3400",
            "district": "34",
            "Block": "48",
            "label": "VIVEGA INTERNATIONAL MATRIC SCHOOL CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3405",
            "district": "34",
            "Block": "365",
            "label": "PUMS KALLERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3410",
            "district": "34",
            "Block": "48",
            "label": "PUMS RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3439",
            "district": "34",
            "Block": "249",
            "label": "SRI RAMAJAYAM MATRIC POLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3446",
            "district": "34",
            "Block": "14",
            "label": "WISDOM MAT HSS CHEYYATRAI VENDRAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3463",
            "district": "34",
            "Block": "48",
            "label": "WISDOM VIDHYASHRAM INTERNATIONAL SCHOOL  CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3473",
            "district": "34",
            "Block": "27",
            "label": "ADWMS PAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3502",
            "district": "34",
            "Block": "44",
            "label": "SRI VIVEKANANDA MATRIC HIGHER SEC SCHOOL-CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3515",
            "district": "34",
            "Block": "387",
            "label": "SRI BHARATHAMATHA MATRICULATION SCHOOL VANDAVASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3523",
            "district": "34",
            "Block": "249",
            "label": "TOWN MATRIC HSS POLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3529",
            "district": "34",
            "Block": "365",
            "label": "PUMS KADAGAMAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3541",
            "district": "34",
            "Block": "256",
            "label": "PUMS THAMARAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3552",
            "district": "34",
            "Block": "365",
            "label": "PUMS NAVAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3569",
            "district": "34",
            "Block": "47",
            "label": "PUMS ALLIYALAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3570",
            "district": "34",
            "Block": "47",
            "label": "PUMS KOZHAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3571",
            "district": "34",
            "Block": "47",
            "label": "RCM MIDDLE THACHAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3576",
            "district": "34",
            "Block": "395",
            "label": "PUMS ABDULLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3577",
            "district": "34",
            "Block": "47",
            "label": "PUMS SADUPPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3580",
            "district": "34",
            "Block": "318",
            "label": "PUMS JAPTHIKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3581",
            "district": "34",
            "Block": "48",
            "label": "ST JOSEPH MATRIC KODANAGAR CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3589",
            "district": "34",
            "Block": "14",
            "label": "PUMS VEERAMBAKKAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3595",
            "district": "34",
            "Block": "395",
            "label": "PUMS UKKAMPERUMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3605",
            "district": "34",
            "Block": "47",
            "label": "PUMS MODAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3608",
            "district": "34",
            "Block": "249",
            "label": "PUMS ATHUVAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3613",
            "district": "34",
            "Block": "27",
            "label": "PUMS THUNTHAREEGAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3614",
            "district": "34",
            "Block": "27",
            "label": "PUMS S.U.VANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3615",
            "district": "34",
            "Block": "47",
            "label": "RCM MIDDLE PATHIYAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3623",
            "district": "34",
            "Block": "395",
            "label": "PUMS BOKKASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3628",
            "district": "34",
            "Block": "48",
            "label": "MMS-KODANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3634",
            "district": "34",
            "Block": "27",
            "label": "PUMS NETHAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3641",
            "district": "34",
            "Block": "365",
            "label": "PUMS ARADAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3645",
            "district": "34",
            "Block": "93",
            "label": "PUMS GENGAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3649",
            "district": "34",
            "Block": "93",
            "label": "PUMS SETTAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3657",
            "district": "34",
            "Block": "395",
            "label": "PUMS PULIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3664",
            "district": "34",
            "Block": "47",
            "label": "PUMS THENVILAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3676",
            "district": "34",
            "Block": "365",
            "label": "PUMS T. VALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3686",
            "district": "34",
            "Block": "365",
            "label": "PUMS THANDARAI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3690",
            "district": "34",
            "Block": "47",
            "label": "PUMS VAMBALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3703",
            "district": "34",
            "Block": "48",
            "label": "PUMS SENGAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3709",
            "district": "34",
            "Block": "27",
            "label": "PUMS SOMANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3713",
            "district": "34",
            "Block": "48",
            "label": "MMS-THIRUVOTHUR (MUSLIM)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3714",
            "district": "34",
            "Block": "387",
            "label": "PUMS VAZHUR AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3716",
            "district": "34",
            "Block": "412",
            "label": "PUMS KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3729",
            "district": "34",
            "Block": "365",
            "label": "PUMS SATHIYA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3732",
            "district": "34",
            "Block": "27",
            "label": "PUMS 12PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3741",
            "district": "34",
            "Block": "249",
            "label": "PUMS EZUVAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3753",
            "district": "34",
            "Block": "48",
            "label": "PUMS-MUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3764",
            "district": "34",
            "Block": "27",
            "label": "MMS ANANTHAPURAM(N) ARNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3773",
            "district": "34",
            "Block": "47",
            "label": "PUMS KILPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3778",
            "district": "34",
            "Block": "256",
            "label": "PUMS ADIVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3781",
            "district": "34",
            "Block": "47",
            "label": "ST. JOSEPH MATRICULATION SUSAINAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3782",
            "district": "34",
            "Block": "312",
            "label": "PUMS -NAVAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3787",
            "district": "34",
            "Block": "365",
            "label": "PUMS ERADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3808",
            "district": "34",
            "Block": "312",
            "label": "PUMS KUBERAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3819",
            "district": "34",
            "Block": "365",
            "label": "SRI RAMAKRISHNA MAT HS TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3828",
            "district": "34",
            "Block": "312",
            "label": "PUMS VEPPUR CHEKKADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3830",
            "district": "34",
            "Block": "358",
            "label": "SHISHYA MATIRC HSS MUNIYANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3844",
            "district": "34",
            "Block": "395",
            "label": "PUMS SUMANGALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3859",
            "district": "34",
            "Block": "256",
            "label": "PUMS NAGAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3891",
            "district": "34",
            "Block": "358",
            "label": "RCM MIDDLE BOODHAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3897",
            "district": "34",
            "Block": "312",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3911",
            "district": "34",
            "Block": "256",
            "label": "PUMS NANDHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4024",
            "district": "34",
            "Block": "312",
            "label": "PUMS MALAYANUR CHEKKADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4062",
            "district": "34",
            "Block": "47",
            "label": "PUMS ATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4063",
            "district": "34",
            "Block": "365",
            "label": "VICTORIYA HINDU MS TIRUVANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4068",
            "district": "34",
            "Block": "395",
            "label": "PUMS PUNNAI PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4075",
            "district": "34",
            "Block": "27",
            "label": "PUMS VELLERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4103",
            "district": "34",
            "Block": "93",
            "label": "PUMS KOOTRAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4109",
            "district": "34",
            "Block": "387",
            "label": "PUMS SENNAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4118",
            "district": "34",
            "Block": "249",
            "label": "PUMS NARANAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4120",
            "district": "34",
            "Block": "44",
            "label": "PUMS PERIYAKALTHAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4125",
            "district": "34",
            "Block": "318",
            "label": "PUMS RAMASAMUDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4147",
            "district": "34",
            "Block": "387",
            "label": "PUMS-KILSATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4149",
            "district": "34",
            "Block": "249",
            "label": "PUMS EDAPIRAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23671",
            "district": "34",
            "Block": "365",
            "label": "Vivekananda Nursery School",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "4152",
            "district": "34",
            "Block": "387",
            "label": "PUMS KOVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4156",
            "district": "34",
            "Block": "238",
            "label": "PUMS VINAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4160",
            "district": "34",
            "Block": "395",
            "label": "PUMS PERUMANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4166",
            "district": "34",
            "Block": "387",
            "label": "PUMS SEMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4188",
            "district": "34",
            "Block": "44",
            "label": "PUMS NEW KUILAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4195",
            "district": "34",
            "Block": "256",
            "label": "PUMS MUNNURMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4196",
            "district": "34",
            "Block": "125",
            "label": "ADWMS KARNAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4197",
            "district": "34",
            "Block": "312",
            "label": "PUMS VEERANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4216",
            "district": "34",
            "Block": "387",
            "label": "KINGSTON MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4280",
            "district": "34",
            "Block": "358",
            "label": "PUMS V.NAMMIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4282",
            "district": "34",
            "Block": "256",
            "label": "PUMS NARASINGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4312",
            "district": "34",
            "Block": "256",
            "label": "PUMS DEVANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4339",
            "district": "34",
            "Block": "249",
            "label": "PUMS PADAVEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4360",
            "district": "34",
            "Block": "365",
            "label": "ADWMS PALAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4383",
            "district": "34",
            "Block": "44",
            "label": "SAHAYAMADHA MATRIC HSS- CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4409",
            "district": "34",
            "Block": "387",
            "label": "PUMS ARIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4413",
            "district": "34",
            "Block": "249",
            "label": "PUMS R.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4425",
            "district": "34",
            "Block": "48",
            "label": "PUMS-PAINKINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4431",
            "district": "34",
            "Block": "365",
            "label": "PUMS SAVALPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4448",
            "district": "34",
            "Block": "256",
            "label": "ST. ARULANANDAR MATRIC SCHOOL  PUDUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4455",
            "district": "34",
            "Block": "249",
            "label": "SRI RAGAVENDIRA MATRIC HR. SEC. KASTHAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4459",
            "district": "34",
            "Block": "387",
            "label": "PUMS KODANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4460",
            "district": "34",
            "Block": "14",
            "label": "PUMS CHEYYATRAIVENDRAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4463",
            "district": "34",
            "Block": "238",
            "label": "RCM MIDDLE SCHOOL MOSAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4468",
            "district": "34",
            "Block": "125",
            "label": "PUMS KADAMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4477",
            "district": "34",
            "Block": "238",
            "label": "PUMS BAGAVANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4486",
            "district": "34",
            "Block": "249",
            "label": "DMMS POLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4490",
            "district": "34",
            "Block": "125",
            "label": "PUMS SO.NAMMIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4495",
            "district": "34",
            "Block": "256",
            "label": "PUMS JAPTHIKARIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4497",
            "district": "34",
            "Block": "48",
            "label": "PUMS VADATHANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4506",
            "district": "34",
            "Block": "125",
            "label": "PUMS ARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4520",
            "district": "34",
            "Block": "412",
            "label": "PUMS ONNUPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4528",
            "district": "34",
            "Block": "358",
            "label": "PUMS USAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4529",
            "district": "34",
            "Block": "48",
            "label": "PUMS MELNAGARAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4544",
            "district": "34",
            "Block": "256",
            "label": "PUMS ORAVANDAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4582",
            "district": "34",
            "Block": "358",
            "label": "PUMS KALASTHAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4592",
            "district": "34",
            "Block": "249",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4593",
            "district": "34",
            "Block": "125",
            "label": "PUMS RAYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4598",
            "district": "34",
            "Block": "47",
            "label": "PUMS KOLAKARAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4602",
            "district": "34",
            "Block": "387",
            "label": "PUMS ULUNDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4606",
            "district": "34",
            "Block": "125",
            "label": "PUMS SIRUNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4627",
            "district": "34",
            "Block": "312",
            "label": "ANNAI VELANKANNI MATRIC MALLIKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4636",
            "district": "34",
            "Block": "44",
            "label": "PUMS UCHIMALAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4645",
            "district": "34",
            "Block": "387",
            "label": "AGASTHIYA VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL VANDAVSI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4646",
            "district": "34",
            "Block": "365",
            "label": "SRI RAMANA MATRIC HS VENGIKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4673",
            "district": "34",
            "Block": "249",
            "label": "PUMS VASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4770",
            "district": "34",
            "Block": "125",
            "label": "ADWMS PANNIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4789",
            "district": "34",
            "Block": "249",
            "label": "PUMS KANNIGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4826",
            "district": "34",
            "Block": "256",
            "label": "PUMS-PERIYERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4898",
            "district": "34",
            "Block": "365",
            "label": "ST. ANTONY MATRIC PAVITHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4899",
            "district": "34",
            "Block": "27",
            "label": "PUMS ADAYAPULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5008",
            "district": "34",
            "Block": "44",
            "label": "PUMS PINJUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5024",
            "district": "34",
            "Block": "47",
            "label": "PUMS OGHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5077",
            "district": "34",
            "Block": "358",
            "label": "PUMS PALAYAMANNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5086",
            "district": "34",
            "Block": "249",
            "label": "PUMS CHINNAPUSHPAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5098",
            "district": "34",
            "Block": "125",
            "label": "PUMS MANAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5114",
            "district": "34",
            "Block": "365",
            "label": "PUMS PAVUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5120",
            "district": "34",
            "Block": "93",
            "label": "AIDED MIDDLE SIRUVALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5121",
            "district": "34",
            "Block": "365",
            "label": "MMS THENIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5127",
            "district": "34",
            "Block": "14",
            "label": "PUMS PERUMBALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5139",
            "district": "34",
            "Block": "358",
            "label": "PUMS SANANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5140",
            "district": "34",
            "Block": "358",
            "label": "PUMS KAMALAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5155",
            "district": "34",
            "Block": "44",
            "label": "PUMS MANMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5183",
            "district": "34",
            "Block": "312",
            "label": "PUMS PUTHUR CHEKKADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5185",
            "district": "34",
            "Block": "238",
            "label": "PUMS SEPTANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5192",
            "district": "34",
            "Block": "44",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5195",
            "district": "34",
            "Block": "365",
            "label": "MMS KANA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5202",
            "district": "34",
            "Block": "44",
            "label": "PUMS K.ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5213",
            "district": "34",
            "Block": "312",
            "label": "PUMS NEDUGAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5214",
            "district": "34",
            "Block": "365",
            "label": "PUMS ANANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5219",
            "district": "34",
            "Block": "80",
            "label": "PUMS THURINJIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5235",
            "district": "34",
            "Block": "125",
            "label": "PUMS GANALAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5249",
            "district": "34",
            "Block": "365",
            "label": "PUMS ANDIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5250",
            "district": "34",
            "Block": "312",
            "label": "PUMS SERPPAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5258",
            "district": "34",
            "Block": "249",
            "label": "PUMS MUKKURUMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5316",
            "district": "34",
            "Block": "412",
            "label": "AIDED MIDDLE KATTUKANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5369",
            "district": "34",
            "Block": "365",
            "label": "PUMS VERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5441",
            "district": "34",
            "Block": "249",
            "label": "ADWMS KELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5504",
            "district": "34",
            "Block": "27",
            "label": "PUMS KANIGILUPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5525",
            "district": "34",
            "Block": "93",
            "label": "ADWMS KADALADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5530",
            "district": "34",
            "Block": "365",
            "label": "MMS TOWN HALL TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5570",
            "district": "34",
            "Block": "358",
            "label": "PUMS CHERIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5577",
            "district": "34",
            "Block": "249",
            "label": "PUMS KURUVIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5583",
            "district": "34",
            "Block": "358",
            "label": "PUMS-PORKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5590",
            "district": "34",
            "Block": "44",
            "label": "PUMS VALAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5601",
            "district": "34",
            "Block": "387",
            "label": "PUMS - THENNANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5632",
            "district": "34",
            "Block": "125",
            "label": "PUMS-ZAMEEN AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5639",
            "district": "34",
            "Block": "249",
            "label": "PUMS KATTIPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5669",
            "district": "34",
            "Block": "44",
            "label": "SRI RAMAKRISHNA MATRIC HSS CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5687",
            "district": "34",
            "Block": "365",
            "label": "PUMS KOTANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5728",
            "district": "34",
            "Block": "312",
            "label": "PUMS- THONDAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5753",
            "district": "34",
            "Block": "395",
            "label": "VIDYA VIKAS MATRIC HR SEC SCHOOL MORANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5777",
            "district": "34",
            "Block": "365",
            "label": "SRI SARASWATHI VIKAS MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5792",
            "district": "34",
            "Block": "358",
            "label": "PUMS VADA ANDAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5804",
            "district": "34",
            "Block": "125",
            "label": "PUMS ARANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5815",
            "district": "34",
            "Block": "125",
            "label": "PUMS OLAIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5840",
            "district": "34",
            "Block": "125",
            "label": "PUMS KALLAYEE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5844",
            "district": "34",
            "Block": "365",
            "label": "PUMS MELCHETTIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5846",
            "district": "34",
            "Block": "125",
            "label": "PUMS EAST VETTAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5851",
            "district": "34",
            "Block": "312",
            "label": "PUMS VIJAYAPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5870",
            "district": "34",
            "Block": "44",
            "label": "PUMS KATTAMADUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5873",
            "district": "34",
            "Block": "365",
            "label": "PUMS MELKACHIRAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5898",
            "district": "34",
            "Block": "365",
            "label": "PUMS ADAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5909",
            "district": "34",
            "Block": "318",
            "label": "PUMS  THELLAR ADK",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5916",
            "district": "34",
            "Block": "125",
            "label": "PUMS GENGANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5932",
            "district": "34",
            "Block": "358",
            "label": "PUMS M.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5937",
            "district": "34",
            "Block": "93",
            "label": "ANMMS THENMAHADEVAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5951",
            "district": "34",
            "Block": "125",
            "label": "PUMS KATHALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5959",
            "district": "34",
            "Block": "93",
            "label": "PUMS PATTIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5961",
            "district": "34",
            "Block": "365",
            "label": "PUMS AZHAGANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5972",
            "district": "34",
            "Block": "312",
            "label": "PUMS T.VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5974",
            "district": "34",
            "Block": "312",
            "label": "PUMS MELPACHAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5976",
            "district": "34",
            "Block": "93",
            "label": "PUMS KAPPALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5989",
            "district": "34",
            "Block": "358",
            "label": "PUMS THURINJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5996",
            "district": "34",
            "Block": "238",
            "label": "PUMS ANATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6032",
            "district": "34",
            "Block": "365",
            "label": "KRISHNA VIDYA MANDIR MAT  HR SEC SCHOOL TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6154",
            "district": "34",
            "Block": "358",
            "label": "MARY IMMACULATE HEART MATRIC HSS KARIYANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6161",
            "district": "34",
            "Block": "48",
            "label": "DR VGN MATRIC HIGH SCHOOL CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6171",
            "district": "34",
            "Block": "318",
            "label": "TTMMS DESUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6172",
            "district": "34",
            "Block": "44",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6196",
            "district": "34",
            "Block": "27",
            "label": "PUMS, ARIYAPPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6198",
            "district": "34",
            "Block": "44",
            "label": "PUMS THAZAIYUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6207",
            "district": "34",
            "Block": "93",
            "label": "PUMS MELVANNIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6222",
            "district": "34",
            "Block": "27",
            "label": "PUMS PAZHANGAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6231",
            "district": "34",
            "Block": "365",
            "label": "PUMS VENGIKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6243",
            "district": "34",
            "Block": "358",
            "label": "PUMS-POYYANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6283",
            "district": "34",
            "Block": "365",
            "label": "CARMEL MATRICULATION SCHOOL, TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6286",
            "district": "34",
            "Block": "48",
            "label": "INDO AMERICAN  MATRIC HR.SEC.SCHOOL CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6295",
            "district": "34",
            "Block": "387",
            "label": "SRI VENKATESWARA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6355",
            "district": "34",
            "Block": "395",
            "label": "PUMS THANDAPPANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6419",
            "district": "34",
            "Block": "387",
            "label": "PUMS AMMAYAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6423",
            "district": "34",
            "Block": "44",
            "label": "ADWMS KAYAMBATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6425",
            "district": "34",
            "Block": "387",
            "label": "PUMS PUNNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6450",
            "district": "34",
            "Block": "365",
            "label": "PUMS KANDIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6458",
            "district": "34",
            "Block": "249",
            "label": "AUXILIUM MATRIC",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6475",
            "district": "34",
            "Block": "249",
            "label": "PUMS KALVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6507",
            "district": "34",
            "Block": "44",
            "label": "PUMS THEETHANDAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6517",
            "district": "34",
            "Block": "312",
            "label": "PUMS VAZHAVACHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6530",
            "district": "34",
            "Block": "312",
            "label": "PUMS(MUSLIM) THIRUVADATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6543",
            "district": "34",
            "Block": "312",
            "label": "PUMS MELTHIRUVADATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6576",
            "district": "34",
            "Block": "358",
            "label": "PUMS UTHIRAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6582",
            "district": "34",
            "Block": "125",
            "label": "MOORTHY MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6586",
            "district": "34",
            "Block": "412",
            "label": "MADRAS MATRIC, KANNAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6608",
            "district": "34",
            "Block": "395",
            "label": "PUMS KALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6618",
            "district": "34",
            "Block": "395",
            "label": "SANGFORD SENIOR SECONDARY SCHOOL ABDULLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6668",
            "district": "34",
            "Block": "256",
            "label": "SRI SARASWATHI VIDHYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6671",
            "district": "34",
            "Block": "365",
            "label": "RAMANA MAHARISHI LOYOLA ACADEMY MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6674",
            "district": "34",
            "Block": "93",
            "label": "PUMS MATTAVETTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6685",
            "district": "34",
            "Block": "365",
            "label": "PUMS NACHANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6709",
            "district": "34",
            "Block": "312",
            "label": "PUMS BONDHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6724",
            "district": "34",
            "Block": "312",
            "label": "PUMS VARAGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6733",
            "district": "34",
            "Block": "238",
            "label": "SIDDHARTHA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9531",
            "district": "34",
            "Block": "395",
            "label": "ADWHS ARASANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9532",
            "district": "34",
            "Block": "395",
            "label": "GHSS AZHIVIDAITHANGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9533",
            "district": "34",
            "Block": "395",
            "label": "GHS BUDERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9534",
            "district": "34",
            "Block": "395",
            "label": "GHSS BRAMMADESAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9535",
            "district": "34",
            "Block": "395",
            "label": "GHSS CHITHATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9536",
            "district": "34",
            "Block": "395",
            "label": "GHS DUSI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9537",
            "district": "34",
            "Block": "395",
            "label": "GHSS MAMANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9538",
            "district": "34",
            "Block": "395",
            "label": "PUMS MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9539",
            "district": "34",
            "Block": "395",
            "label": "GHSS MENALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9540",
            "district": "34",
            "Block": "395",
            "label": "GHS NAMANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9541",
            "district": "34",
            "Block": "395",
            "label": "GHS NATTERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9542",
            "district": "34",
            "Block": "395",
            "label": "GHS PALLAVARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9543",
            "district": "34",
            "Block": "395",
            "label": "GGHSS PERUNGATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9544",
            "district": "34",
            "Block": "395",
            "label": "GHSS(BOYS) PERUNGATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9545",
            "district": "34",
            "Block": "395",
            "label": "GHSS PUNNAI PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9546",
            "district": "34",
            "Block": "395",
            "label": "GHSS RANDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9547",
            "district": "34",
            "Block": "395",
            "label": "GHS SATTUVANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9548",
            "district": "34",
            "Block": "395",
            "label": "GHS THENNAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9549",
            "district": "34",
            "Block": "395",
            "label": "ADWMS VADAILLUPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9550",
            "district": "34",
            "Block": "395",
            "label": "GHSS VADAILLUPPAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9551",
            "district": "34",
            "Block": "395",
            "label": "GHSS VADAMANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9552",
            "district": "34",
            "Block": "395",
            "label": "GBHSS-VEMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9553",
            "district": "34",
            "Block": "395",
            "label": "GHSS(GIRLS) VEMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9554",
            "district": "34",
            "Block": "395",
            "label": "GHS VENGALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9555",
            "district": "34",
            "Block": "395",
            "label": "PUMS THIRUPPANANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9556",
            "district": "34",
            "Block": "48",
            "label": "GHS HOUSING BOARD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9557",
            "district": "34",
            "Block": "48",
            "label": "GHS KAZHAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9558",
            "district": "34",
            "Block": "48",
            "label": "GHSS KORUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9559",
            "district": "34",
            "Block": "48",
            "label": "GHS KORUKKATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9560",
            "district": "34",
            "Block": "48",
            "label": "GHS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9561",
            "district": "34",
            "Block": "48",
            "label": "GHS MELSEESHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9562",
            "district": "34",
            "Block": "48",
            "label": "GHS MELMATTAI VINNAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9563",
            "district": "34",
            "Block": "48",
            "label": "KNNC GHSS MUNUGAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9564",
            "district": "34",
            "Block": "48",
            "label": "GHSS NEDUMBIRAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9565",
            "district": "34",
            "Block": "48",
            "label": "GHSS- PALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9566",
            "district": "34",
            "Block": "48",
            "label": "GHS PAPPANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9567",
            "district": "34",
            "Block": "48",
            "label": "ADWHS PULIYARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9568",
            "district": "34",
            "Block": "48",
            "label": "GHS THIRUMANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9569",
            "district": "34",
            "Block": "48",
            "label": "GHS VADUGAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9570",
            "district": "34",
            "Block": "48",
            "label": "GHSS-VAZHKUDAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9571",
            "district": "34",
            "Block": "48",
            "label": "VICTORY MATRIC, VINNAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9572",
            "district": "34",
            "Block": "48",
            "label": "GHS THIRUVOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9573",
            "district": "34",
            "Block": "48",
            "label": "GHSS(BOYS) CHEYYAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9574",
            "district": "34",
            "Block": "48",
            "label": "GMHSS(GIRLS)- CHEYYAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9575",
            "district": "34",
            "Block": "48",
            "label": "MHS GIRUDHARANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9576",
            "district": "34",
            "Block": "48",
            "label": "ST PAULS HIGH SCHOOL CHEYYAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9577",
            "district": "34",
            "Block": "48",
            "label": "AIMS CBSE SENIOR SECONDARY SCHOOL, CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9578",
            "district": "34",
            "Block": "14",
            "label": "GHSS AKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9579",
            "district": "34",
            "Block": "14",
            "label": "GHS ALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9580",
            "district": "34",
            "Block": "14",
            "label": "GHSS ANAKKAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9581",
            "district": "34",
            "Block": "14",
            "label": "GHS ANAPPATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9582",
            "district": "34",
            "Block": "14",
            "label": "GHS KOOZHAMANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9583",
            "district": "34",
            "Block": "14",
            "label": "GHSS KOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9584",
            "district": "34",
            "Block": "14",
            "label": "GHS METTU ECHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9585",
            "district": "34",
            "Block": "14",
            "label": "PUMS NEDUNGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9586",
            "district": "34",
            "Block": "14",
            "label": "GHSS PURISAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9587",
            "district": "34",
            "Block": "14",
            "label": "GHS THENELAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9588",
            "district": "34",
            "Block": "14",
            "label": "GHS THETHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9589",
            "district": "34",
            "Block": "14",
            "label": "GHSS UKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9590",
            "district": "34",
            "Block": "14",
            "label": "GHSS SENGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9591",
            "district": "34",
            "Block": "238",
            "label": "GHS ALLIYANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9592",
            "district": "34",
            "Block": "238",
            "label": "AUXILIUM MATRICULATION SCHOOL  ANMARUDAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9593",
            "district": "34",
            "Block": "238",
            "label": "GHS AVANIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9594",
            "district": "34",
            "Block": "238",
            "label": "GHS AYALAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9595",
            "district": "34",
            "Block": "238",
            "label": "GHSS(GIRLS)-KOLLAPPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9596",
            "district": "34",
            "Block": "238",
            "label": "GHSS(BOYS) KOLAPPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9597",
            "district": "34",
            "Block": "238",
            "label": "GHS MELACHARI KOTTUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9598",
            "district": "34",
            "Block": "238",
            "label": "GHSS MADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9599",
            "district": "34",
            "Block": "238",
            "label": "GHS MELATHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9600",
            "district": "34",
            "Block": "238",
            "label": "GHS NAMATHODU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9601",
            "district": "34",
            "Block": "238",
            "label": "GHSS NEDUNGUNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9602",
            "district": "34",
            "Block": "238",
            "label": "GHSS(BOYS) PERNAMALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9603",
            "district": "34",
            "Block": "238",
            "label": "GHSS(GIRLS) PERNAMALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9604",
            "district": "34",
            "Block": "238",
            "label": "GHS VALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9605",
            "district": "34",
            "Block": "238",
            "label": "GHS VAYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9606",
            "district": "34",
            "Block": "387",
            "label": "GHS AMUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9607",
            "district": "34",
            "Block": "387",
            "label": "GHS ATHIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9608",
            "district": "34",
            "Block": "387",
            "label": "GHS BADHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9609",
            "district": "34",
            "Block": "387",
            "label": "S.R.I INTERNATIONAL MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9610",
            "district": "34",
            "Block": "387",
            "label": "GHSS IRUMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9611",
            "district": "34",
            "Block": "387",
            "label": "GHSS KILKODUNGALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9612",
            "district": "34",
            "Block": "387",
            "label": "GHS(GIRLS) KILKODUNGALORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9613",
            "district": "34",
            "Block": "387",
            "label": "GHSS KILKOVALAIVEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9614",
            "district": "34",
            "Block": "387",
            "label": "PUMS-KILPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9615",
            "district": "34",
            "Block": "387",
            "label": "PUMS KILSEMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9616",
            "district": "34",
            "Block": "387",
            "label": "PUMS KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9617",
            "district": "34",
            "Block": "387",
            "label": "GHSS MARUDADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9618",
            "district": "34",
            "Block": "387",
            "label": "GHS NELLIYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9619",
            "district": "34",
            "Block": "387",
            "label": "GHS SALAVEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9620",
            "district": "34",
            "Block": "387",
            "label": "GHS SALUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9621",
            "district": "34",
            "Block": "387",
            "label": "GHSS(BOYS)-VANDAVASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9622",
            "district": "34",
            "Block": "387",
            "label": "GHSS(GIRLS)-VANDAVASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9623",
            "district": "34",
            "Block": "387",
            "label": "RCM HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9624",
            "district": "34",
            "Block": "387",
            "label": "SANNATHI HSS VANDAVASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9625",
            "district": "34",
            "Block": "387",
            "label": "SACRED HEART HSS(G)",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9626",
            "district": "34",
            "Block": "387",
            "label": "GHS VELIYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9627",
            "district": "34",
            "Block": "387",
            "label": "JUBILEE GLOBAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9628",
            "district": "34",
            "Block": "318",
            "label": "GGHS C.M.PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9629",
            "district": "34",
            "Block": "318",
            "label": "PUMS-CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9630",
            "district": "34",
            "Block": "318",
            "label": "GHSS(BOYS) DESUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9631",
            "district": "34",
            "Block": "318",
            "label": "GHSS(GIRLS) DESUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9632",
            "district": "34",
            "Block": "318",
            "label": "PUMS GENGAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9633",
            "district": "34",
            "Block": "318",
            "label": "GHS KADAMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9634",
            "district": "34",
            "Block": "318",
            "label": "PUMS KANDAVARATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9635",
            "district": "34",
            "Block": "318",
            "label": "PUMS S.KATTERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9636",
            "district": "34",
            "Block": "318",
            "label": "PUMS KILNANDIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9637",
            "district": "34",
            "Block": "318",
            "label": "GHS KILPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9638",
            "district": "34",
            "Block": "318",
            "label": "PUMS KILVELLIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9639",
            "district": "34",
            "Block": "318",
            "label": "PUMS KILVILLIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9640",
            "district": "34",
            "Block": "318",
            "label": "GHS KODIYALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9641",
            "district": "34",
            "Block": "318",
            "label": "GHS KONDAIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9642",
            "district": "34",
            "Block": "318",
            "label": "PUMS KOOTHAMBATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9643",
            "district": "34",
            "Block": "318",
            "label": "GHS KORAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9644",
            "district": "34",
            "Block": "318",
            "label": "GHSS KUNNAGAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9645",
            "district": "34",
            "Block": "318",
            "label": "PUMS MANJAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9646",
            "district": "34",
            "Block": "318",
            "label": "GHSS MAZHAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9647",
            "district": "34",
            "Block": "318",
            "label": "PUMS MEESANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9648",
            "district": "34",
            "Block": "318",
            "label": "GHS MELPATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9649",
            "district": "34",
            "Block": "318",
            "label": "GHS NADUKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9650",
            "district": "34",
            "Block": "318",
            "label": "GHSS NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9651",
            "district": "34",
            "Block": "318",
            "label": "GHS NERKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9652",
            "district": "34",
            "Block": "318",
            "label": "PUMS PENNATTAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9653",
            "district": "34",
            "Block": "318",
            "label": "GHSS PONNUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9654",
            "district": "34",
            "Block": "318",
            "label": "PUMS SATHIYAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9655",
            "district": "34",
            "Block": "318",
            "label": "PUMS SENAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9656",
            "district": "34",
            "Block": "318",
            "label": "PUMS THAKKANDARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9657",
            "district": "34",
            "Block": "318",
            "label": "GHSS(BOYS)-THELLAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9658",
            "district": "34",
            "Block": "318",
            "label": "GGHS THELLAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9659",
            "district": "34",
            "Block": "318",
            "label": "OXFORD HIGHER SECONDARY SCHOOL, THELLAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9660",
            "district": "34",
            "Block": "318",
            "label": "PUMS THENTHINNALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9661",
            "district": "34",
            "Block": "318",
            "label": "GHSS THEYYAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9662",
            "district": "34",
            "Block": "318",
            "label": "GHS VAYALAMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9663",
            "district": "34",
            "Block": "318",
            "label": "GHS VEDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9664",
            "district": "34",
            "Block": "27",
            "label": "GHS ADHANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9665",
            "district": "34",
            "Block": "27",
            "label": "GHSS AGRAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9666",
            "district": "34",
            "Block": "27",
            "label": "GHSS- IRUMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9667",
            "district": "34",
            "Block": "27",
            "label": "GHS HARIHARAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9668",
            "district": "34",
            "Block": "27",
            "label": "GHS KALPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9669",
            "district": "34",
            "Block": "27",
            "label": "GHS MEYYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9670",
            "district": "34",
            "Block": "27",
            "label": "GHS MORAPANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9671",
            "district": "34",
            "Block": "27",
            "label": "GHSS MULLANDIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9672",
            "district": "34",
            "Block": "27",
            "label": "GHS NESAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9673",
            "district": "34",
            "Block": "27",
            "label": "GHS PAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9674",
            "district": "34",
            "Block": "27",
            "label": "GHS RATTINAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9675",
            "district": "34",
            "Block": "27",
            "label": "GHSS S.V.NAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9676",
            "district": "34",
            "Block": "27",
            "label": "GHSS SEVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9677",
            "district": "34",
            "Block": "27",
            "label": "GHS SIRUMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9678",
            "district": "34",
            "Block": "27",
            "label": "GHS VADUGASATHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9679",
            "district": "34",
            "Block": "27",
            "label": "GHS VELAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9680",
            "district": "34",
            "Block": "27",
            "label": "GHS VETTIYANTHOLUVAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9681",
            "district": "34",
            "Block": "27",
            "label": "GHS VILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9682",
            "district": "34",
            "Block": "27",
            "label": "CSI HSS  ARNI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9683",
            "district": "34",
            "Block": "27",
            "label": "GHSS (B)  ARNI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9684",
            "district": "34",
            "Block": "27",
            "label": "GGMHSS ARNI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9685",
            "district": "34",
            "Block": "27",
            "label": "ST. JOSEPH HSS(GIRLS) ARNI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9686",
            "district": "34",
            "Block": "27",
            "label": "SUB. SASTRIAR HSS ARNI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9687",
            "district": "34",
            "Block": "412",
            "label": "GHS 5PUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9688",
            "district": "34",
            "Block": "412",
            "label": "GHSS DEVIKAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9689",
            "district": "34",
            "Block": "412",
            "label": "GHS(GIRLS) DEVIKAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9690",
            "district": "34",
            "Block": "412",
            "label": "GBHSS KANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9691",
            "district": "34",
            "Block": "412",
            "label": "GHSS(GIRLS) KANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9692",
            "district": "34",
            "Block": "412",
            "label": "GHSS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9693",
            "district": "34",
            "Block": "412",
            "label": "GHS MALAIYAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9694",
            "district": "34",
            "Block": "412",
            "label": "GHS MELNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9695",
            "district": "34",
            "Block": "412",
            "label": "GHS MULLIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9696",
            "district": "34",
            "Block": "412",
            "label": "GHSS NADUKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9697",
            "district": "34",
            "Block": "412",
            "label": "GHSS ONNUPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9698",
            "district": "34",
            "Block": "412",
            "label": "GHS THATCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9699",
            "district": "34",
            "Block": "412",
            "label": "GHS THELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9700",
            "district": "34",
            "Block": "412",
            "label": "GHS VINNAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9701",
            "district": "34",
            "Block": "249",
            "label": "PUMS ALLINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9702",
            "district": "34",
            "Block": "249",
            "label": "GHSS ANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9703",
            "district": "34",
            "Block": "249",
            "label": "GHSS ATHIMOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9704",
            "district": "34",
            "Block": "249",
            "label": "S C GOVT BOYS HR.SEC. SCHOOL KALAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9705",
            "district": "34",
            "Block": "249",
            "label": "GHSS (GIRLS) KALAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9706",
            "district": "34",
            "Block": "249",
            "label": "GHS KALASAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9707",
            "district": "34",
            "Block": "249",
            "label": "ADWHS KALIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9708",
            "district": "34",
            "Block": "249",
            "label": "GHS KANGEYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9709",
            "district": "34",
            "Block": "249",
            "label": "GHS KASTHAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9710",
            "district": "34",
            "Block": "249",
            "label": "GHSS KELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9711",
            "district": "34",
            "Block": "249",
            "label": "GHSS KUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9712",
            "district": "34",
            "Block": "249",
            "label": "GHSS MAMBATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9713",
            "district": "34",
            "Block": "249",
            "label": "GHS PADIYAMBATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9714",
            "district": "34",
            "Block": "249",
            "label": "GHS PERIYAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9715",
            "district": "34",
            "Block": "249",
            "label": "GHS PERUMALPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9716",
            "district": "34",
            "Block": "249",
            "label": "DON BOSCO HSS POLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9717",
            "district": "34",
            "Block": "249",
            "label": "GHSS (BOYS) POLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9718",
            "district": "34",
            "Block": "249",
            "label": "GHSS (GIRLS) POLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9719",
            "district": "34",
            "Block": "249",
            "label": "GHS POTHARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9720",
            "district": "34",
            "Block": "249",
            "label": "GHS RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9721",
            "district": "34",
            "Block": "249",
            "label": "GHSS RENUKONDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9722",
            "district": "34",
            "Block": "249",
            "label": "GHSS SANTHAVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9723",
            "district": "34",
            "Block": "249",
            "label": "GHSS SENGUNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9724",
            "district": "34",
            "Block": "249",
            "label": "GHS THURINJIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9725",
            "district": "34",
            "Block": "249",
            "label": "AKSHAYA VIDHYA. MANDHIR MATRIC HIGHER SECONDARY SCHOOL VASUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9726",
            "district": "34",
            "Block": "249",
            "label": "GHS VELLUR II",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9727",
            "district": "34",
            "Block": "249",
            "label": "OXFORD MATRIC HR. SEC. SCHOOL VENMANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9728",
            "district": "34",
            "Block": "249",
            "label": "PUMS VETTAGIRIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9729",
            "district": "34",
            "Block": "93",
            "label": "GHSS (BOYS) ADAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9730",
            "district": "34",
            "Block": "93",
            "label": "GHSS (GIRLS) ADHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9731",
            "district": "34",
            "Block": "93",
            "label": "PUMS ALANGARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9732",
            "district": "34",
            "Block": "93",
            "label": "GHS ANIYALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9733",
            "district": "34",
            "Block": "93",
            "label": "SRI SARAVANA VIDHYALAYA MATRIC HR SEC SCHOOL ,ELATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9734",
            "district": "34",
            "Block": "93",
            "label": "GHSS KADALADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9735",
            "district": "34",
            "Block": "93",
            "label": "GMHSS (BOYS) KALASAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9736",
            "district": "34",
            "Block": "93",
            "label": "GHSS (GIRLS) KALASAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9737",
            "district": "34",
            "Block": "93",
            "label": "ARAVINDHAR MATRIC KALASAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9738",
            "district": "34",
            "Block": "93",
            "label": "SWAMI VENKATESWARA MATRICULATION HIGHER SECONDARY SCHOOL, KANDHAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9739",
            "district": "34",
            "Block": "93",
            "label": "GHS KANTHAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9740",
            "district": "34",
            "Block": "93",
            "label": "GHS KIDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9741",
            "district": "34",
            "Block": "93",
            "label": "GHS KILKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9742",
            "district": "34",
            "Block": "93",
            "label": "GHSS KILPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9743",
            "district": "34",
            "Block": "93",
            "label": "GHSS LADAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9744",
            "district": "34",
            "Block": "93",
            "label": "GHSS MELARANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9745",
            "district": "34",
            "Block": "93",
            "label": "GHSS MELSHOLANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9746",
            "district": "34",
            "Block": "93",
            "label": "GHS PADAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9747",
            "district": "34",
            "Block": "93",
            "label": "GHS POONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9748",
            "district": "34",
            "Block": "93",
            "label": "GHS THENMAHADEVAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9749",
            "district": "34",
            "Block": "93",
            "label": "ADWMS VEERALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9750",
            "district": "34",
            "Block": "47",
            "label": "DHIVYA MATRIC HSS CHETPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9751",
            "district": "34",
            "Block": "47",
            "label": "DOMINIC SAVIO HSS (BOYS) CHETPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9752",
            "district": "34",
            "Block": "47",
            "label": "GHSS PAZHAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9753",
            "district": "34",
            "Block": "47",
            "label": "ST ANNES HSS (GIRLS) CHETPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9754",
            "district": "34",
            "Block": "47",
            "label": "GHS ETTIVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9755",
            "district": "34",
            "Block": "47",
            "label": "GHS GUDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9756",
            "district": "34",
            "Block": "47",
            "label": "GHS INDIRAVANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9757",
            "district": "34",
            "Block": "47",
            "label": "GHS KILKARIKATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9758",
            "district": "34",
            "Block": "47",
            "label": "GHSS MANDAKOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9759",
            "district": "34",
            "Block": "47",
            "label": "GHSS MANSURABAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9760",
            "district": "34",
            "Block": "47",
            "label": "GHSS NAMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9761",
            "district": "34",
            "Block": "47",
            "label": "GHS PELASUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9762",
            "district": "34",
            "Block": "47",
            "label": "GHSS PERNAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9763",
            "district": "34",
            "Block": "47",
            "label": "GHS RANDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23370",
            "district": "34",
            "Block": "412",
            "label": "S.G.S Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23371",
            "district": "34",
            "Block": "44",
            "label": "Sri Sarathi balamandir matric higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9764",
            "district": "34",
            "Block": "47",
            "label": "GHSS SANARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9765",
            "district": "34",
            "Block": "47",
            "label": "GHSS SEVARAPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9766",
            "district": "34",
            "Block": "47",
            "label": "NIRMALA MADHA HSS (GIRLS) SUSAINAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9767",
            "district": "34",
            "Block": "47",
            "label": "ST JOSEPHS HSS (BOYS) SUSAINAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9768",
            "district": "34",
            "Block": "47",
            "label": "SRI BHAGAVAN NEMINATHA HR SEC SCHOOL  TIRUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9769",
            "district": "34",
            "Block": "47",
            "label": "GHS ULAGAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9770",
            "district": "34",
            "Block": "47",
            "label": "GHSS VADAMATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9771",
            "district": "34",
            "Block": "358",
            "label": "GHS ANANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9772",
            "district": "34",
            "Block": "358",
            "label": "GHS ARPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9773",
            "district": "34",
            "Block": "358",
            "label": "GHS DURGAINAMMIYANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9774",
            "district": "34",
            "Block": "358",
            "label": "GHS ERUMPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9775",
            "district": "34",
            "Block": "358",
            "label": "GHSS INAMKARIYANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9776",
            "district": "34",
            "Block": "358",
            "label": "ATR VIDHYASHRAM INTERNATIONAL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9777",
            "district": "34",
            "Block": "358",
            "label": "GHS KATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9778",
            "district": "34",
            "Block": "358",
            "label": "SNS MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9779",
            "district": "34",
            "Block": "358",
            "label": "GHSS MALLAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9780",
            "district": "34",
            "Block": "358",
            "label": "GHSS (GIRLS) MALLAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9781",
            "district": "34",
            "Block": "358",
            "label": "SRI SAI VIDYA  MANDIR MATRICULATION SCHOOL, MALLAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9782",
            "district": "34",
            "Block": "358",
            "label": "GHSS MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9783",
            "district": "34",
            "Block": "358",
            "label": "GANDHI INTERNATIONAL MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9784",
            "district": "34",
            "Block": "358",
            "label": "GHS MADHALAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9785",
            "district": "34",
            "Block": "358",
            "label": "ST MARYS HS MARUTHUVAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9786",
            "district": "34",
            "Block": "358",
            "label": "GHS MEPPATHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9787",
            "district": "34",
            "Block": "358",
            "label": "GHSS NARTHAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9788",
            "district": "34",
            "Block": "358",
            "label": "DMHS NOOKAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9789",
            "district": "34",
            "Block": "358",
            "label": "GHS SADAYANODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9790",
            "district": "34",
            "Block": "358",
            "label": "PUMS SEELAPANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9791",
            "district": "34",
            "Block": "358",
            "label": "GHSS SORAKOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9792",
            "district": "34",
            "Block": "358",
            "label": "GHS VALLIVAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9793",
            "district": "34",
            "Block": "358",
            "label": "GHSS VEDANTHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9794",
            "district": "34",
            "Block": "358",
            "label": "GHS VELUGANANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9795",
            "district": "34",
            "Block": "125",
            "label": "GHS AVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9796",
            "district": "34",
            "Block": "125",
            "label": "NEW INDIAN MATRIC. HI-TECH HR SEC SCHOOL-OLAIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9797",
            "district": "34",
            "Block": "125",
            "label": "GHS CHELLANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9798",
            "district": "34",
            "Block": "125",
            "label": "GHSS IYNGUNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9799",
            "district": "34",
            "Block": "125",
            "label": "GHS KARIKALAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9800",
            "district": "34",
            "Block": "125",
            "label": "RRHS KARUNGALIKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9801",
            "district": "34",
            "Block": "125",
            "label": "GHS KAZHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9802",
            "district": "34",
            "Block": "125",
            "label": "GHS KEEKALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9803",
            "district": "34",
            "Block": "125",
            "label": "GHSS (BOYS) KILPENNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9804",
            "district": "34",
            "Block": "125",
            "label": "GHSS (GIRLS) KILPENNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9805",
            "district": "34",
            "Block": "125",
            "label": "GHS KOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9806",
            "district": "34",
            "Block": "125",
            "label": "GHS KONALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9807",
            "district": "34",
            "Block": "125",
            "label": "GHSS MEKKALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9808",
            "district": "34",
            "Block": "125",
            "label": "ST PAUL HS NA.GENGAPATTU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9809",
            "district": "34",
            "Block": "125",
            "label": "GHS NARIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9810",
            "district": "34",
            "Block": "125",
            "label": "GHSS RAJANTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9811",
            "district": "34",
            "Block": "125",
            "label": "NATIONAL MATRIC HIGHER SECONDARY  SCHOOL RAJANTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9812",
            "district": "34",
            "Block": "125",
            "label": "AMEEN HSS SO.PUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9813",
            "district": "34",
            "Block": "125",
            "label": "DMHS SOMASIPADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9814",
            "district": "34",
            "Block": "125",
            "label": "GHS SU.POLAKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9815",
            "district": "34",
            "Block": "125",
            "label": "GHS VAIPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9816",
            "district": "34",
            "Block": "125",
            "label": "GHS VALUTHALANGUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9817",
            "district": "34",
            "Block": "125",
            "label": "GHSS VEDANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9818",
            "district": "34",
            "Block": "125",
            "label": "GHS VELANANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9819",
            "district": "34",
            "Block": "125",
            "label": "GHSS (BOYS) VETTAVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9820",
            "district": "34",
            "Block": "125",
            "label": "GHSS (GIRLS) VETTAVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9821",
            "district": "34",
            "Block": "125",
            "label": "SA HSS (GIRLS) VETTAVALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9822",
            "district": "34",
            "Block": "125",
            "label": "SM HSS (BOYS) VETTAVALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9823",
            "district": "34",
            "Block": "125",
            "label": "DR. LAMAYAR MATRIC VETTAVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9824",
            "district": "34",
            "Block": "125",
            "label": "GOOD WILL MATRIC HR.SEC SCHOOL ANUKKUMALAI VETTAVALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9825",
            "district": "34",
            "Block": "125",
            "label": "ST.XAVIER HS ZAMEEN GUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9826",
            "district": "34",
            "Block": "365",
            "label": "TVS ACADEMY MONTESSORI AND ENGLISH MEDIUM SCHOOL, ADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9827",
            "district": "34",
            "Block": "365",
            "label": "SS AIDED HS ADIANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "9828",
            "district": "34",
            "Block": "365",
            "label": "GHS ADIANNAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9829",
            "district": "34",
            "Block": "365",
            "label": "GHSS ANDAMPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9830",
            "district": "34",
            "Block": "365",
            "label": "GHS ANAIPIRANDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9831",
            "district": "34",
            "Block": "365",
            "label": "ADWHS AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9832",
            "district": "34",
            "Block": "365",
            "label": "PUMS AYYAMPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9833",
            "district": "34",
            "Block": "365",
            "label": "TKKMHS CHINNAKANGIANUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "9834",
            "district": "34",
            "Block": "365",
            "label": "GHS DEVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9835",
            "district": "34",
            "Block": "365",
            "label": "GHSS KATTAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9836",
            "district": "34",
            "Block": "365",
            "label": "SRI RAMANA MAHARSHI MATRIC  HR SEC SCHOOL KAVERIYAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9837",
            "district": "34",
            "Block": "365",
            "label": "SMM MODEL HS KILANAIKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9838",
            "district": "34",
            "Block": "365",
            "label": "GHSS KOLAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9839",
            "district": "34",
            "Block": "365",
            "label": "G.P. MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9840",
            "district": "34",
            "Block": "365",
            "label": "SIRUMALAR HS MATHURAMPATU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9841",
            "district": "34",
            "Block": "365",
            "label": "PUMS MALAPPAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9842",
            "district": "34",
            "Block": "365",
            "label": "HEBRON MATRIC HIGHER SECONDARY SCHOOL -  MANJAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9843",
            "district": "34",
            "Block": "365",
            "label": "GHS MEYYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9844",
            "district": "34",
            "Block": "365",
            "label": "SARASWATHI KNOWLEDGE YUVAKENDRA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9845",
            "district": "34",
            "Block": "365",
            "label": "GHSS NALLAVANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9846",
            "district": "34",
            "Block": "365",
            "label": "GHS NARAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9847",
            "district": "34",
            "Block": "365",
            "label": "PUMS NARIYAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9848",
            "district": "34",
            "Block": "365",
            "label": "GHSS PALAYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9849",
            "district": "34",
            "Block": "365",
            "label": "GHS PARAYAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9850",
            "district": "34",
            "Block": "365",
            "label": "PSA HSS (BOYS) PAVITHIRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9851",
            "district": "34",
            "Block": "365",
            "label": "ST ANTONYS HSS (GIRLS) PAVITHIRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9852",
            "district": "34",
            "Block": "365",
            "label": "GHS PERUMANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9853",
            "district": "34",
            "Block": "365",
            "label": "PUMS SO. KILNACHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9854",
            "district": "34",
            "Block": "365",
            "label": "KUMARAN HSS SO.KILNATCHIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9855",
            "district": "34",
            "Block": "365",
            "label": "JEEVA VELU GIRLS MATRICLATION HR SEC SCHOOL THENMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9856",
            "district": "34",
            "Block": "365",
            "label": "G T R HIGH SCHOOL  SU.KILNATCHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9857",
            "district": "34",
            "Block": "365",
            "label": "GHSS SU.VALAVETTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9858",
            "district": "34",
            "Block": "365",
            "label": "RANGAMMAL MEMORIAL HR.SEC.SCHOOL FOR THE HEARING IMPAIRED",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9859",
            "district": "34",
            "Block": "365",
            "label": "PUMS SAMUKKUDAIYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9860",
            "district": "34",
            "Block": "365",
            "label": "GHSS THACHAMPATU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9861",
            "district": "34",
            "Block": "365",
            "label": "GHS THANDARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9862",
            "district": "34",
            "Block": "365",
            "label": "JEEVA VELU INTERNATIONAL SCHOOL - THENMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9863",
            "district": "34",
            "Block": "365",
            "label": "GHS VEDIYAPPANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9864",
            "district": "34",
            "Block": "365",
            "label": "ST ANTONY HS VIRUDUVILANGINAN",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9865",
            "district": "34",
            "Block": "365",
            "label": "ALC HSS SARON",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9866",
            "district": "34",
            "Block": "365",
            "label": "DM HSS TIRUVANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9867",
            "district": "34",
            "Block": "365",
            "label": "TNAP GHSS TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9868",
            "district": "34",
            "Block": "365",
            "label": "MOUNT SAINT JOSEPH MATRIC HSS TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9869",
            "district": "34",
            "Block": "365",
            "label": "MUNICIPAL HS KILNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9870",
            "district": "34",
            "Block": "365",
            "label": "MUNICIPAL HS (GIRLS) TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9871",
            "district": "34",
            "Block": "365",
            "label": "MUNICIPAL  HSS TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9872",
            "district": "34",
            "Block": "365",
            "label": "MUNICIPAL  HSS (GIRLS) TIRUVANNAMALAI -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9873",
            "district": "34",
            "Block": "365",
            "label": "SI GHSS TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9874",
            "district": "34",
            "Block": "365",
            "label": "SRI VDS JAIN HSS TIRUVANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9875",
            "district": "34",
            "Block": "365",
            "label": "ST XAVIERS HS KILNATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9876",
            "district": "34",
            "Block": "365",
            "label": "VISION MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9877",
            "district": "34",
            "Block": "365",
            "label": "KENDRIYA VIDYALAYA GANDHI NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9878",
            "district": "34",
            "Block": "365",
            "label": "M A KADHAR SAIT TIRUVANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "9879",
            "district": "34",
            "Block": "256",
            "label": "GHS AMARNATHAPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9880",
            "district": "34",
            "Block": "256",
            "label": "GHSS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9881",
            "district": "34",
            "Block": "256",
            "label": "GHS C.GENGAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9882",
            "district": "34",
            "Block": "256",
            "label": "ST JOSEPHS HS C.NAMMIANDAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9883",
            "district": "34",
            "Block": "256",
            "label": "GHSS ERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9884",
            "district": "34",
            "Block": "256",
            "label": "GHSS KANJI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9885",
            "district": "34",
            "Block": "256",
            "label": "GHSS(GIRLS) KANJI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9886",
            "district": "34",
            "Block": "256",
            "label": "GHSS KARAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9887",
            "district": "34",
            "Block": "256",
            "label": "GHS KOTTAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9888",
            "district": "34",
            "Block": "256",
            "label": "GHSS- MASHAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9889",
            "district": "34",
            "Block": "256",
            "label": "GHS MELAPUNJAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9890",
            "district": "34",
            "Block": "256",
            "label": "GHS MELNACHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9891",
            "district": "34",
            "Block": "256",
            "label": "GHS MELPADUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9892",
            "district": "34",
            "Block": "256",
            "label": "GHS MUTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9893",
            "district": "34",
            "Block": "256",
            "label": "GHSS PANAIOLAIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9894",
            "district": "34",
            "Block": "256",
            "label": "GHS PERIYAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9895",
            "district": "34",
            "Block": "256",
            "label": "GHSS(BOYS)-PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9896",
            "district": "34",
            "Block": "256",
            "label": "GGMHSS PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9897",
            "district": "34",
            "Block": "256",
            "label": "GHS PUDUR CHENGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9898",
            "district": "34",
            "Block": "256",
            "label": "GHS THORAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9899",
            "district": "34",
            "Block": "256",
            "label": "GHS VASUDEVANPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9900",
            "district": "34",
            "Block": "256",
            "label": "GHS VOIVIDANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9901",
            "district": "34",
            "Block": "44",
            "label": "VIMALA IRUDHAYA MATRICULATION SCHOOL ANWARABATH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9902",
            "district": "34",
            "Block": "44",
            "label": "PUMS ARASANKANNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9903",
            "district": "34",
            "Block": "44",
            "label": "GHSS ARATTAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9904",
            "district": "34",
            "Block": "44",
            "label": "PUMS -AYOTHIYAPATTANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9905",
            "district": "34",
            "Block": "44",
            "label": "PUMS CHE. AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9906",
            "district": "34",
            "Block": "44",
            "label": "GHS CHE.NACHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9907",
            "district": "34",
            "Block": "44",
            "label": "PUMS CHE. TIRUVALLUVARNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9908",
            "district": "34",
            "Block": "44",
            "label": "GHS C.SORPANANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9909",
            "district": "34",
            "Block": "44",
            "label": "GHSS(BOYS) CHENGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9910",
            "district": "34",
            "Block": "44",
            "label": "GHSS(GIRLS) CHENGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9911",
            "district": "34",
            "Block": "44",
            "label": "SAGAYA MADHA HSS(TM) CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9912",
            "district": "34",
            "Block": "44",
            "label": "GHS (MUSLIM) CHENGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9913",
            "district": "34",
            "Block": "44",
            "label": "MAHARISHI  HR SEC SCHOOL CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9914",
            "district": "34",
            "Block": "44",
            "label": "GHS CHENNASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9915",
            "district": "34",
            "Block": "44",
            "label": "GHSS  ELANGUNNI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9916",
            "district": "34",
            "Block": "44",
            "label": "GHSS KANNAKURUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9917",
            "district": "34",
            "Block": "44",
            "label": "GHS KARIMALAIPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9918",
            "district": "34",
            "Block": "44",
            "label": "GHS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9919",
            "district": "34",
            "Block": "44",
            "label": "GHS KUPPANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9920",
            "district": "34",
            "Block": "44",
            "label": "GHS MELCHENGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9921",
            "district": "34",
            "Block": "44",
            "label": "GBHSS-MELPALLIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9922",
            "district": "34",
            "Block": "44",
            "label": "GGHSS MELPALLIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9923",
            "district": "34",
            "Block": "44",
            "label": "PUMS MELPUZHUTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9924",
            "district": "34",
            "Block": "44",
            "label": "ADWHS MELPUZHUTHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9925",
            "district": "34",
            "Block": "44",
            "label": "GHS MELRAVANDAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9926",
            "district": "34",
            "Block": "44",
            "label": "PUMS MELVANAKKAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9927",
            "district": "34",
            "Block": "44",
            "label": "GHS NEEPATHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9928",
            "district": "34",
            "Block": "44",
            "label": "GHS PACHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9929",
            "district": "34",
            "Block": "44",
            "label": "GHSS PARAMANANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9930",
            "district": "34",
            "Block": "44",
            "label": "GHSS PERIYAKOLAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9931",
            "district": "34",
            "Block": "44",
            "label": "PUMS PERUMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9932",
            "district": "34",
            "Block": "44",
            "label": "GHS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9933",
            "district": "34",
            "Block": "44",
            "label": "PUMS THALAVANAICKENPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9934",
            "district": "34",
            "Block": "44",
            "label": "GHS VINNAVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9935",
            "district": "34",
            "Block": "312",
            "label": "PUMS AGARAM PALLIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9936",
            "district": "34",
            "Block": "312",
            "label": "GHS BEEMARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9937",
            "district": "34",
            "Block": "312",
            "label": "GHSS C.REDDIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9938",
            "district": "34",
            "Block": "312",
            "label": "PUMS CHINNIYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9939",
            "district": "34",
            "Block": "312",
            "label": "PUMS DESURPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9940",
            "district": "34",
            "Block": "312",
            "label": "PUMS DEVARADIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9941",
            "district": "34",
            "Block": "312",
            "label": "GHS EDAKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9942",
            "district": "34",
            "Block": "312",
            "label": "GHS EDATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9943",
            "district": "34",
            "Block": "312",
            "label": "CARMEL HSS ELAYANKANNI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9944",
            "district": "34",
            "Block": "312",
            "label": "GOOD SHEPHERD HIGHER SECONDARY SCHOOL  GURUKILAMOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9945",
            "district": "34",
            "Block": "312",
            "label": "GHS KAMBATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9946",
            "district": "34",
            "Block": "312",
            "label": "GHS KEERANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9947",
            "district": "34",
            "Block": "312",
            "label": "GHS KILSIRUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9948",
            "district": "34",
            "Block": "312",
            "label": "PUMS KILVANAKKAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9949",
            "district": "34",
            "Block": "312",
            "label": "GHS MALAMANJANUR PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9950",
            "district": "34",
            "Block": "312",
            "label": "PUMS MELKARIPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9951",
            "district": "34",
            "Block": "312",
            "label": "GHS MELMUTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9952",
            "district": "34",
            "Block": "312",
            "label": "GHSS MOTHAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9953",
            "district": "34",
            "Block": "312",
            "label": "GHS NARAYANAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9954",
            "district": "34",
            "Block": "312",
            "label": "PUMS PERAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9955",
            "district": "34",
            "Block": "312",
            "label": "GHSS PERUNGULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9956",
            "district": "34",
            "Block": "312",
            "label": "KGBVRS PERUNGULATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9957",
            "district": "34",
            "Block": "312",
            "label": "PUMS RADHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9958",
            "district": "34",
            "Block": "312",
            "label": "GHSS RAYANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9959",
            "district": "34",
            "Block": "312",
            "label": "GHS S.GUDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9960",
            "district": "34",
            "Block": "312",
            "label": "GHS SADHAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9961",
            "district": "34",
            "Block": "312",
            "label": "GHSS SATHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9962",
            "district": "34",
            "Block": "312",
            "label": "GHS (GIRLS) SATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9963",
            "district": "34",
            "Block": "312",
            "label": "GHSS (BOYS) THANDARAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9964",
            "district": "34",
            "Block": "312",
            "label": "GHSS (GIRLS) THANDRAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9965",
            "district": "34",
            "Block": "312",
            "label": "BHARATH VIDHYA MANDIR MATRIC HR SEC SCHOOL THANDRAMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9966",
            "district": "34",
            "Block": "312",
            "label": "GHS THARADAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9967",
            "district": "34",
            "Block": "312",
            "label": "GGHSS THANIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9968",
            "district": "34",
            "Block": "312",
            "label": "GBHSS- THANIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9969",
            "district": "34",
            "Block": "312",
            "label": "KAMALAMMAL MATRIC S THANIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9970",
            "district": "34",
            "Block": "312",
            "label": "GHS THENKARIMBALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9971",
            "district": "34",
            "Block": "312",
            "label": "RCM MS UDAYARKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9972",
            "district": "34",
            "Block": "312",
            "label": "GHS (GIRLS) VANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9973",
            "district": "34",
            "Block": "312",
            "label": "GHSS - VANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9974",
            "district": "34",
            "Block": "80",
            "label": "PUMS ALANJANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9975",
            "district": "34",
            "Block": "80",
            "label": "ST JOSEPH HSS ATHIPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9976",
            "district": "34",
            "Block": "80",
            "label": "PUMS BANDIREW",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9977",
            "district": "34",
            "Block": "80",
            "label": "KGBVRS GUNDALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9978",
            "district": "34",
            "Block": "80",
            "label": "TRIBAL HSS GUNIGANTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9979",
            "district": "34",
            "Block": "80",
            "label": "PUMS IRULAMPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9980",
            "district": "34",
            "Block": "80",
            "label": "FOREST HSS JAMUNAMARATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9981",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS JAMMUNAMARATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9982",
            "district": "34",
            "Block": "80",
            "label": "PUMS KALLATHUR(P)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9983",
            "district": "34",
            "Block": "80",
            "label": "PUMS KALLIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9984",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS KALYANAMANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9985",
            "district": "34",
            "Block": "80",
            "label": "PUMS KANAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9986",
            "district": "34",
            "Block": "80",
            "label": "GHS KILAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9987",
            "district": "34",
            "Block": "80",
            "label": "PUMS KILKANAVAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9988",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS KILVILAMUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9989",
            "district": "34",
            "Block": "80",
            "label": "PUMS KOOTTATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9990",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9991",
            "district": "34",
            "Block": "80",
            "label": "KGBVRS AMMATANKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9992",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS MELPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9993",
            "district": "34",
            "Block": "80",
            "label": "PUMS MUTHANATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9994",
            "district": "34",
            "Block": "80",
            "label": "KGBVRS MUTHANATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9995",
            "district": "34",
            "Block": "80",
            "label": "GTR HSS NAMMIYAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9996",
            "district": "34",
            "Block": "80",
            "label": "GTRMS OORGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9997",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS PULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9998",
            "district": "34",
            "Block": "80",
            "label": "KGBVRS PULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9999",
            "district": "34",
            "Block": "80",
            "label": "GTRMS SERAMARATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10000",
            "district": "34",
            "Block": "80",
            "label": "PUMS THANIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10001",
            "district": "34",
            "Block": "80",
            "label": "PUMS THOPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10002",
            "district": "34",
            "Block": "80",
            "label": "PUMS THUMBAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10003",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS VEERAPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10004",
            "district": "34",
            "Block": "80",
            "label": "PUMS VELLIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10005",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS VAZHAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10006",
            "district": "34",
            "Block": "80",
            "label": "GHS VEDAKOLLAIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20912",
            "district": "34",
            "Block": "14",
            "label": "BRIGHT PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20941",
            "district": "34",
            "Block": "93",
            "label": "DON BOSCO MATRICULATION SCHOOL - ADHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20947",
            "district": "34",
            "Block": "125",
            "label": "SKV INTERNATIONAL SCHOOL-KEERANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20948",
            "district": "34",
            "Block": "365",
            "label": "THE PATH GLOBAL PUBLIC SCHOOL  MALAPPAMPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20949",
            "district": "34",
            "Block": "365",
            "label": "AMAZON INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20952",
            "district": "34",
            "Block": "256",
            "label": "SRI SARADHA MATRIC SCHOOL -PUDUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20955",
            "district": "34",
            "Block": "312",
            "label": "SKV MATRICULATION  HR SEC SCHOOL-KOLUNDAMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20956",
            "district": "34",
            "Block": "395",
            "label": "VETRI SIGARAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20957",
            "district": "34",
            "Block": "48",
            "label": "JAMBU MAHARISHI VANNIYAR MATRICULATION HIGHER SECONDARY  SCHOOL PALANTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20958",
            "district": "34",
            "Block": "48",
            "label": "VISWA BARATHI MATRICULATION",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20959",
            "district": "34",
            "Block": "125",
            "label": "SRI ARUNESHWAR MATRIC SCHOOL-VETTAVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21014",
            "district": "34",
            "Block": "238",
            "label": "ST.PAULS MATRIC HIGHER SECONDARY SCHOOL - VAYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21015",
            "district": "34",
            "Block": "27",
            "label": "SHRI BALA VIDHYA MANDIR MATRIC HR SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21016",
            "district": "34",
            "Block": "44",
            "label": "SIDDHARTH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21060",
            "district": "34",
            "Block": "249",
            "label": "ASIAN MATRICULATION SCHOOL, SENGUNAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21148",
            "district": "34",
            "Block": "27",
            "label": "VIKAS VIDHYASHARAM MATRICULATION HR SEC SCHOOL NESAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21149",
            "district": "34",
            "Block": "412",
            "label": "ORANGE INTERNATIONAL MATRIC HSS - VINNAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21179",
            "district": "34",
            "Block": "249",
            "label": "SRI RAMAJAYAM GLOBAL (CBSE)-POLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21180",
            "district": "34",
            "Block": "365",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21201",
            "district": "34",
            "Block": "80",
            "label": "Govt Model Higher Sec school perungattur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21263",
            "district": "34",
            "Block": "318",
            "label": "ARAVIND INTERNATIONAL MATRICULATION SCHOOL - THELLAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21264",
            "district": "34",
            "Block": "27",
            "label": "SRI BHARATHI VIDHYASHRAM MATRIC HR.SEC. SCHOOL, AGRAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21265",
            "district": "34",
            "Block": "125",
            "label": "SAKTHI MATRIC-KUNNANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21305",
            "district": "34",
            "Block": "365",
            "label": "VIGNESH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21448",
            "district": "34",
            "Block": "48",
            "label": "GHS PARASUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21449",
            "district": "34",
            "Block": "14",
            "label": "GHS ELANEERKUNDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21450",
            "district": "34",
            "Block": "387",
            "label": "GHS-ELANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21451",
            "district": "34",
            "Block": "44",
            "label": "GHSS-ANDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21528",
            "district": "34",
            "Block": "249",
            "label": "ELANGO HS-KESAVAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "21545",
            "district": "34",
            "Block": "365",
            "label": "BHAGAVAN HR.SEC.SCHOOL - KILNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21565",
            "district": "34",
            "Block": "27",
            "label": "MUNICIPAL HIGH SCHOOL KANNAPPAN ST POONTHOTAM ARNI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21577",
            "district": "34",
            "Block": "238",
            "label": "SWAMY VIVEKANANDA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21583",
            "district": "34",
            "Block": "47",
            "label": "SRI SANTHA VIDYALAYA MATRIC HSS-GENGAISOODAMANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21673",
            "district": "34",
            "Block": "125",
            "label": "SASTRA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21674",
            "district": "34",
            "Block": "125",
            "label": "LAKSHMI AMMAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21675",
            "district": "34",
            "Block": "47",
            "label": "ANNAI RENUGAMBAL MATRIC HR. SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21676",
            "district": "34",
            "Block": "249",
            "label": "GHS(GIRLS)-KELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21677",
            "district": "34",
            "Block": "412",
            "label": "ORANGE BRITISH ACADEMY SCHOOL-VINNAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21925",
            "district": "34",
            "Block": "47",
            "label": "DHIVYA HIGH SCHOOL-CHETPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21926",
            "district": "34",
            "Block": "93",
            "label": "CHEZHIAN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21954",
            "district": "34",
            "Block": "47",
            "label": "ST.AMALARAKKINI HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21955",
            "district": "34",
            "Block": "395",
            "label": "BON SECOURS SPECIAL SCHOOL FOR SPEECH & HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22082",
            "district": "34",
            "Block": "48",
            "label": "DHRONA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22083",
            "district": "34",
            "Block": "48",
            "label": "VIRUTCHAM INTER PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22084",
            "district": "34",
            "Block": "387",
            "label": "HASHINY INTERNATIONAL MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22085",
            "district": "34",
            "Block": "27",
            "label": "APPLE INTERNATIONAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22086",
            "district": "34",
            "Block": "27",
            "label": "KANNAMMAL INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22087",
            "district": "34",
            "Block": "412",
            "label": "TULIP INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22088",
            "district": "34",
            "Block": "93",
            "label": "ADWHS-KETTAVARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22089",
            "district": "34",
            "Block": "47",
            "label": "CHINMAYA VIDHYALAYA MATRIC HR SEC SCHOOL MANDAKOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22090",
            "district": "34",
            "Block": "125",
            "label": "SAN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22091",
            "district": "34",
            "Block": "44",
            "label": "GHS-ANTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22092",
            "district": "34",
            "Block": "80",
            "label": "GTRHSS-ATTIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22093",
            "district": "34",
            "Block": "80",
            "label": "GTRHS-KOVILANDOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22094",
            "district": "34",
            "Block": "80",
            "label": "GTRHSS-PATTARAIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22411",
            "district": "34",
            "Block": "395",
            "label": "GHS KUTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22412",
            "district": "34",
            "Block": "48",
            "label": "GHS-ENATHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22413",
            "district": "34",
            "Block": "27",
            "label": "GHS-SANGEETHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22414",
            "district": "34",
            "Block": "47",
            "label": "WIN PARK VIDHYALAYA MATRICULATION  SCHOOL, CHETPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22415",
            "district": "34",
            "Block": "358",
            "label": "K-ZONE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22416",
            "district": "34",
            "Block": "365",
            "label": "GHS-THALAYAMPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22417",
            "district": "34",
            "Block": "365",
            "label": "SKP VANITHA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22418",
            "district": "34",
            "Block": "365",
            "label": "SKP VANITHA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22419",
            "district": "34",
            "Block": "365",
            "label": "RS INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22420",
            "district": "34",
            "Block": "44",
            "label": "SRISHTI HIGHER SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22421",
            "district": "34",
            "Block": "44",
            "label": "SIGARAM INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22422",
            "district": "34",
            "Block": "44",
            "label": "SHREE NIVEDHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22423",
            "district": "34",
            "Block": "312",
            "label": "SRI JAYAM MATRIC.HR.SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22424",
            "district": "34",
            "Block": "312",
            "label": "GHS-THENMUDIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22425",
            "district": "34",
            "Block": "80",
            "label": "EKLAVYA MODEL RESIDENTIAL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22780",
            "district": "34",
            "Block": "412",
            "label": "RAMAJEYAM MATRICULATION SCHOOL DEVIKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22781",
            "district": "34",
            "Block": "412",
            "label": "SAFA MATRIC School, Thatchur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22782",
            "district": "34",
            "Block": "395",
            "label": "Chitra Krishnasamy Vidyalaya CBSE,Vembakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22812",
            "district": "34",
            "Block": "395",
            "label": "Naalantha public school (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24127", 
            "district":"34", "Block":"312", 
            "label": "Akshaya N&P School", 
            "Sch_type": "Un-aided", "Category": "Middle School"},
        {
            "key": "22853",
            "district": "34",
            "Block": "358",
            "label": "PVK MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22920",
            "district": "34",
            "Block": "27",
            "label": "GHS-MAMANDOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22921",
            "district": "34",
            "Block": "395",
            "label": "GHS MORANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22922",
            "district": "34",
            "Block": "93",
            "label": "GHS-NAVAPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22923",
            "district": "34",
            "Block": "125",
            "label": "GHS-KATTUMALAIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22989",
            "district": "34",
            "Block": "365",
            "label": "VIGNESH MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23012",
            "district": "34",
            "Block": "125",
            "label": "JAYAM VIDYASHRAM SCHOOL( CBSE )  KILPENNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23058",
            "district": "34",
            "Block": "47",
            "label": "SRI BHARATHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23119",
            "district": "34",
            "Block": "312",
            "label": "THE GREEN MOUNT INTERNATIONAL SCHOOL RADHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23120",
            "district": "34",
            "Block": "358",
            "label": "VIJAY VIDYA MANDIR CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23121",
            "district": "34",
            "Block": "48",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23122",
            "district": "34",
            "Block": "249",
            "label": "VANMUKIL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23123",
            "district": "34",
            "Block": "44",
            "label": "NANDHEESWARA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23124",
            "district": "34",
            "Block": "365",
            "label": "AKSARA VIDHYAMANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23138",
            "district": "34",
            "Block": "365",
            "label": "MY FIRST SCHOOL INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23153",
            "district": "34",
            "Block": "249",
            "label": "SREE YOGARAMS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23154",
            "district": "34",
            "Block": "80",
            "label": "GTRHS ARASUVELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23212",
            "district": "34",
            "Block": "365",
            "label": "SHAZAMMAL VIDHYA MANDHIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23248",
            "district": "34",
            "Block": "27",
            "label": "MHS ANATHAPURAM(SAIDAI)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23316",
            "district": "34",
            "Block": "412",
            "label": "SHRI RAMACHANDRA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23323",
            "district": "34",
            "Block": "365",
            "label": "AQSA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23341",
            "district": "34",
            "Block": "387",
            "label": "SWAMY ABEDHANANDHA VIDHYASHRAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23618",
            "district": "34",
            "Block": "44",
            "label": "A.P.J MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23619",
            "district": "34",
            "Block": "358",
            "label": "SISHYA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23620",
            "district": "34",
            "Block": "365",
            "label": "Government Model School, Tiruvannamalai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23621",
            "district": "34",
            "Block": "365",
            "label": "SRI VIJAY VIDYALAYA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23622",
            "district": "34",
            "Block": "365",
            "label": "SRI VIJAY VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23623",
            "district": "34",
            "Block": "365",
            "label": "TIRUVANNAMALAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23624",
            "district": "34",
            "Block": "387",
            "label": "VIRUTCHAM VIDHYASHRAM INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23749",
            "district": "34",
            "Block": "44",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23750",
            "district": "34",
            "Block": "44",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23751",
            "district": "34",
            "Block": "47",
            "label": "PUMS MARUTHUVAMBADI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23752",
            "district": "34",
            "Block": "47",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23753",
            "district": "34",
            "Block": "48",
            "label": "GHS KUNNATHUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23754",
            "district": "34",
            "Block": "48",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23793",
            "district": "34",
            "Block": "80",
            "label": "AUXILIUM MATRICULATION SCHOOL, JAMUNAMARATHUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23917",
            "district": "34",
            "Block": "238",
            "label": "GHS VAYALUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23997",
            "district": "34",
            "Block": "312",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24043",
            "district": "34",
            "Block": "365",
            "label": "GHS MEYYUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "24044",
            "district": "34",
            "Block": "365",
            "label": "VELAMMAL VIDHYASHRAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24061",
            "district": "34",
            "Block": "387",
            "label": "AGASTHIYA VIDYALAYA",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24067",
            "district": "34",
            "Block": "395",
            "label": "PUMS MATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24093",
            "district": "34",
            "Block": "412",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "35": [
        {
            "key": "586",
            "district": "35",
            "Block": "193",
            "label": "UAMHSS,SARASWATHI,MUTHUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "587",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS, NEW BHARATH MATRIC,TIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "651",
            "district": "35",
            "Block": "334",
            "label": "UAMHSS SAIRAM MATRIC SCHOOL,TTP",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "663",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS RC FATHIMA THIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1017",
            "district": "35",
            "Block": "334",
            "label": "UAMHSS ST.ANTONY'S MATRIC,TTP",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1351",
            "district": "35",
            "Block": "173",
            "label": "UAMHS ST.JOSEPH MATRIC.HIGH SCHOOL,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1363",
            "district": "35",
            "Block": "193",
            "label": "GHSS EDAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1765",
            "district": "35",
            "Block": "193",
            "label": "UAMHSS BRILLIANT ,MUTHUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2119",
            "district": "35",
            "Block": "141",
            "label": "PUMS, VADUKAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2679",
            "district": "35",
            "Block": "141",
            "label": "PUMS, SITHADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2760",
            "district": "35",
            "Block": "141",
            "label": "AMS, THETHIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2908",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS DHARANI MATRIC ,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3063",
            "district": "35",
            "Block": "141",
            "label": "PUMS, ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3456",
            "district": "35",
            "Block": "141",
            "label": "PUMS, SARABOJIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3534",
            "district": "35",
            "Block": "209",
            "label": "UAMHS,A.N.ABDUL RAHIM POTHAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3652",
            "district": "35",
            "Block": "141",
            "label": "PUMS, PARUTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3766",
            "district": "35",
            "Block": "141",
            "label": "AMS, KODAVASAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3846",
            "district": "35",
            "Block": "141",
            "label": "PUMS, SARGUNESWARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3988",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS OXFORD , KOOTHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4085",
            "district": "35",
            "Block": "141",
            "label": "PUMS, RADHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4138",
            "district": "35",
            "Block": "141",
            "label": "PUMS, 18.PUTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4269",
            "district": "35",
            "Block": "173",
            "label": "MMS,GOPALA SAMUDRAM MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4299",
            "district": "35",
            "Block": "141",
            "label": "PUMS, NEMMELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4502",
            "district": "35",
            "Block": "173",
            "label": "MMS MODEL, MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4560",
            "district": "35",
            "Block": "205",
            "label": "PUMS,KOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4603",
            "district": "35",
            "Block": "141",
            "label": "AMS, MANAVALANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4666",
            "district": "35",
            "Block": "173",
            "label": "MMS RAJAMPALAYAM, MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4668",
            "district": "35",
            "Block": "209",
            "label": "UAMHSS OMD,MATRIC HR.SECONDARY SCHOOL, VAZHACHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4709",
            "district": "35",
            "Block": "173",
            "label": "PUMS,EATHAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4747",
            "district": "35",
            "Block": "173",
            "label": "UAMHS INDIA INTERNATIONAL MATRIC, KOOTHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4915",
            "district": "35",
            "Block": "382",
            "label": "PUMS MANICKAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4955",
            "district": "35",
            "Block": "205",
            "label": "PUMS,KURUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4985",
            "district": "35",
            "Block": "193",
            "label": "PUMS, AARIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5039",
            "district": "35",
            "Block": "193",
            "label": "PUMS,THILLAIVILAGAM-SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5061",
            "district": "35",
            "Block": "137",
            "label": "PUMS, IRULNEEKI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5070",
            "district": "35",
            "Block": "141",
            "label": "PUMS, KANGEYANAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5078",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KARPAGA-COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5081",
            "district": "35",
            "Block": "382",
            "label": "PUMS MANALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5091",
            "district": "35",
            "Block": "173",
            "label": "PUMS.THENPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5106",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MELAKANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5129",
            "district": "35",
            "Block": "173",
            "label": "PUMS PERIYAKOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5143",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KUMMATTITHIDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5151",
            "district": "35",
            "Block": "205",
            "label": "PUMS, KOILTHIRUMALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5224",
            "district": "35",
            "Block": "193",
            "label": "PUMS,T-KEELAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5290",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KALLIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5353",
            "district": "35",
            "Block": "193",
            "label": "PUMS,OVARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5356",
            "district": "35",
            "Block": "209",
            "label": "PUMS, MULLAIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5357",
            "district": "35",
            "Block": "173",
            "label": "PUMS.MELANEMMELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5363",
            "district": "35",
            "Block": "205",
            "label": "PUMS,SIRUPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5433",
            "district": "35",
            "Block": "209",
            "label": "PUMS, VIZHALKOTTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5451",
            "district": "35",
            "Block": "173",
            "label": "PUMS.NEMMELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5454",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KARPAGA-KADUVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5459",
            "district": "35",
            "Block": "137",
            "label": "PUMS, SENTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5502",
            "district": "35",
            "Block": "173",
            "label": "PUMS,OHAI PERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5511",
            "district": "35",
            "Block": "334",
            "label": "PUMS THIRUTHURAIPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5519",
            "district": "35",
            "Block": "209",
            "label": "PUMS, KALACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5618",
            "district": "35",
            "Block": "382",
            "label": "PUMS MANAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5619",
            "district": "35",
            "Block": "193",
            "label": "PUMS PETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5711",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KEELAPANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5766",
            "district": "35",
            "Block": "193",
            "label": "PUMS,EDAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5775",
            "district": "35",
            "Block": "193",
            "label": "PUMS KALAIKKARANVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5786",
            "district": "35",
            "Block": "137",
            "label": "PUMS, BALAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5838",
            "district": "35",
            "Block": "193",
            "label": "PUMS,ALKATTUVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5854",
            "district": "35",
            "Block": "193",
            "label": "PUMS,THOLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5861",
            "district": "35",
            "Block": "209",
            "label": "PUMS, MOOVARKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5867",
            "district": "35",
            "Block": "205",
            "label": "PUMS, AATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5901",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SITHAMALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5922",
            "district": "35",
            "Block": "382",
            "label": "PUMS AVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5929",
            "district": "35",
            "Block": "209",
            "label": "PUMS, KARUVAKURICHI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5934",
            "district": "35",
            "Block": "173",
            "label": "PUMS PANDUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5942",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SERUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5946",
            "district": "35",
            "Block": "205",
            "label": "PUMS ,POZHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5977",
            "district": "35",
            "Block": "205",
            "label": "PUMS,PAVATTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6026",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SONAPPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6036",
            "district": "35",
            "Block": "193",
            "label": "PUMS,GOPALASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6037",
            "district": "35",
            "Block": "382",
            "label": "PUMS UTHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6041",
            "district": "35",
            "Block": "193",
            "label": "PUMS,JAMBUVANODAI THARKA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6059",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KANTHARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6086",
            "district": "35",
            "Block": "209",
            "label": "PUMS, RISHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6091",
            "district": "35",
            "Block": "193",
            "label": "PUMS,AZADNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6109",
            "district": "35",
            "Block": "205",
            "label": "PUMS SERUVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6123",
            "district": "35",
            "Block": "193",
            "label": "PUMS,NEW STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6128",
            "district": "35",
            "Block": "382",
            "label": "PUMS VADAKKUPATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6135",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SEKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6164",
            "district": "35",
            "Block": "209",
            "label": "PUMS,VADAKARAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6165",
            "district": "35",
            "Block": "209",
            "label": "PUMS, AGRAPODAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6179",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MAHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6181",
            "district": "35",
            "Block": "382",
            "label": "PUMS UTTHUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6183",
            "district": "35",
            "Block": "137",
            "label": "PUMS, VENGATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6189",
            "district": "35",
            "Block": "137",
            "label": "PUMS, VALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6191",
            "district": "35",
            "Block": "209",
            "label": "PUMS, ARICHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6199",
            "district": "35",
            "Block": "205",
            "label": "PUMS,INJIGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6225",
            "district": "35",
            "Block": "209",
            "label": "PUMS,THENKARAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6237",
            "district": "35",
            "Block": "137",
            "label": "PUMS, PERUGAVALNTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6241",
            "district": "35",
            "Block": "173",
            "label": "PUMS NEDUVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6248",
            "district": "35",
            "Block": "209",
            "label": "PUMS, VADAKKU THOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6254",
            "district": "35",
            "Block": "209",
            "label": "PUMS, KAPPALUTAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6287",
            "district": "35",
            "Block": "205",
            "label": "PUMS,THALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6308",
            "district": "35",
            "Block": "134",
            "label": "PUMS KEERANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6310",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SEENIKUDIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6324",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6333",
            "district": "35",
            "Block": "205",
            "label": "PUMS,ALANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6349",
            "district": "35",
            "Block": "193",
            "label": "PUMS,THILLAIVILAGAM-EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6364",
            "district": "35",
            "Block": "340",
            "label": "MMS ,VIJAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6396",
            "district": "35",
            "Block": "173",
            "label": "PUMS.PAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6418",
            "district": "35",
            "Block": "193",
            "label": "PUMS,UDHAYAMARTHANDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6452",
            "district": "35",
            "Block": "209",
            "label": "AMS,LAKUMIVILAS, NEEDAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6453",
            "district": "35",
            "Block": "334",
            "label": "PUMS CHIDAMBARAKOTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6495",
            "district": "35",
            "Block": "137",
            "label": "PUMS, PERIYAKURUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6514",
            "district": "35",
            "Block": "193",
            "label": "PUMS,MARUTHANGAVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6541",
            "district": "35",
            "Block": "137",
            "label": "PUMS, PAINGATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6571",
            "district": "35",
            "Block": "205",
            "label": "PUMS,VADAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6578",
            "district": "35",
            "Block": "205",
            "label": "AMS,THIRUKKOTTARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6622",
            "district": "35",
            "Block": "340",
            "label": "PUMS, SEMANGALAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6643",
            "district": "35",
            "Block": "193",
            "label": "PUMS,PETHAVELANKOTTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6646",
            "district": "35",
            "Block": "340",
            "label": "PUMS ANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6649",
            "district": "35",
            "Block": "193",
            "label": "PUMS,EKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6687",
            "district": "35",
            "Block": "340",
            "label": "PUMS THENNAVARAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6696",
            "district": "35",
            "Block": "382",
            "label": "PUMS,VEDAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6707",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KUNNALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6734",
            "district": "35",
            "Block": "209",
            "label": "PUMS, DEVANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6746",
            "district": "35",
            "Block": "334",
            "label": "AMS,ARULANANTHA PALLANKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16025",
            "district": "35",
            "Block": "382",
            "label": "PUMS,ARAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16026",
            "district": "35",
            "Block": "382",
            "label": "GHSS, ALANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16027",
            "district": "35",
            "Block": "382",
            "label": "GHSS - AVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16028",
            "district": "35",
            "Block": "382",
            "label": "GHS- CHANDRASEKARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16029",
            "district": "35",
            "Block": "382",
            "label": "GHSS - GOVINDAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16030",
            "district": "35",
            "Block": "382",
            "label": "UAMHSS,YOUTH WELFARE MATRIC HIGHER SECONDARY SCHOOL ,GOVINDAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16031",
            "district": "35",
            "Block": "382",
            "label": "GHSS - HARIDWARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16032",
            "district": "35",
            "Block": "382",
            "label": "PUMS KANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16033",
            "district": "35",
            "Block": "382",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16034",
            "district": "35",
            "Block": "382",
            "label": "UAHS,ST MARYS MATRIC ,KOTTAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16035",
            "district": "35",
            "Block": "382",
            "label": "AMS VIDAIYALKARUPPUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16036",
            "district": "35",
            "Block": "382",
            "label": "GHS - MUNIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16037",
            "district": "35",
            "Block": "382",
            "label": "PUMS NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16038",
            "district": "35",
            "Block": "382",
            "label": "PUMS NARTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16039",
            "district": "35",
            "Block": "382",
            "label": "PUMS PADAGACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16040",
            "district": "35",
            "Block": "382",
            "label": "PUMS PERUNGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16041",
            "district": "35",
            "Block": "382",
            "label": "PUMS  KETHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16042",
            "district": "35",
            "Block": "382",
            "label": "PUMS THENKARAI ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16043",
            "district": "35",
            "Block": "382",
            "label": "PUMS VILATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16044",
            "district": "35",
            "Block": "382",
            "label": "UAMHS, CARMEL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16045",
            "district": "35",
            "Block": "382",
            "label": "GBHSS VALANGAIMAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16046",
            "district": "35",
            "Block": "382",
            "label": "GGHSS - VALANGAIMAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16047",
            "district": "35",
            "Block": "382",
            "label": "UAMHSS  SRI SANKARA  MATRICULATION HIGHER SECONDARY SCHOOL, VALANGAIMAN .",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16048",
            "district": "35",
            "Block": "141",
            "label": "GHS, PALLAVANATTHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16049",
            "district": "35",
            "Block": "141",
            "label": "GHSS MANANTANGUDI AYYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16050",
            "district": "35",
            "Block": "141",
            "label": "GHSS KANDIRAMANICKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16051",
            "district": "35",
            "Block": "141",
            "label": "GHS KOOTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16052",
            "district": "35",
            "Block": "141",
            "label": "GHSS ERAVANCHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16053",
            "district": "35",
            "Block": "141",
            "label": "AHSS, MANJAKKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16054",
            "district": "35",
            "Block": "141",
            "label": "UAHSS, MANJAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16055",
            "district": "35",
            "Block": "141",
            "label": "AMS,MOOLANKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16056",
            "district": "35",
            "Block": "141",
            "label": "UAMHS, MOOLANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16057",
            "district": "35",
            "Block": "141",
            "label": "GHS SENGALIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16058",
            "district": "35",
            "Block": "141",
            "label": "GHSS ENNAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24125", "district":"35", 
            "Block":"340", "label": "K M G Aided Middle School", 
            "Sch_type": "Aided", "Category": "Middle School"
        },
        {
            "key": "16059",
            "district": "35",
            "Block": "141",
            "label": "GHS, THIRUVIDACHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16060",
            "district": "35",
            "Block": "141",
            "label": "GHS THIRUVIZHIMIZHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16061",
            "district": "35",
            "Block": "141",
            "label": "AHS, VADAMATTAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16062",
            "district": "35",
            "Block": "141",
            "label": "AMS,VIKKIRAPANDIYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16063",
            "district": "35",
            "Block": "141",
            "label": "AHSS,VISHNUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16064",
            "district": "35",
            "Block": "141",
            "label": "GBHSS, KUDAVASAL,AGARAOHAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16065",
            "district": "35",
            "Block": "141",
            "label": "GGHSS  KODAVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16066",
            "district": "35",
            "Block": "141",
            "label": "UAHSS SRI MAHADEVA  GURUJI  VIDHYALAYA MATRICULATION HIGHER  SECONDARYSCHOOL KUDAVASAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16067",
            "district": "35",
            "Block": "134",
            "label": "UAMS SHIKSHAYATAN , ARASAVANANKADU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16068",
            "district": "35",
            "Block": "134",
            "label": "AMS,MUGUNTHANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16069",
            "district": "35",
            "Block": "134",
            "label": "AMS PATTUDAIYANIRRIPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16070",
            "district": "35",
            "Block": "134",
            "label": "PUMS, KONDAIYANIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16071",
            "district": "35",
            "Block": "134",
            "label": "PUMS ETTIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16072",
            "district": "35",
            "Block": "134",
            "label": "PUMS VADAKANDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16073",
            "district": "35",
            "Block": "134",
            "label": "PUMS ABIVIRUTHEESWARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16074",
            "district": "35",
            "Block": "134",
            "label": "AHSS,SEMBANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16075",
            "district": "35",
            "Block": "134",
            "label": "PUMS PERUMPUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16076",
            "district": "35",
            "Block": "134",
            "label": "GHS,MANAKKAL AYYAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16077",
            "district": "35",
            "Block": "134",
            "label": "PUMS, VANDAMPALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16078",
            "district": "35",
            "Block": "134",
            "label": "GHS,ENGAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16079",
            "district": "35",
            "Block": "134",
            "label": "GHS,SELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16080",
            "district": "35",
            "Block": "134",
            "label": "PUMS PAVITHRAMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16081",
            "district": "35",
            "Block": "134",
            "label": "UAMS CAMPPAIGN  MATRICULATION  SCHOOL ,PAVITHRAMANICKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16082",
            "district": "35",
            "Block": "134",
            "label": "AMS PANNAIVILAGAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16083",
            "district": "35",
            "Block": "134",
            "label": "GHS,ATHIKKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16084",
            "district": "35",
            "Block": "134",
            "label": "PUMS THIRUVIDAIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16085",
            "district": "35",
            "Block": "134",
            "label": "PUMS AMMAIYAPPAN EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16086",
            "district": "35",
            "Block": "134",
            "label": "PUMS, AMMAIYAPPAN WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16087",
            "district": "35",
            "Block": "134",
            "label": "GHSS,AMMAIYAPPAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16088",
            "district": "35",
            "Block": "134",
            "label": "GHS,KANKODUTHAVANITHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16089",
            "district": "35",
            "Block": "134",
            "label": "PUMS VENNAVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16090",
            "district": "35",
            "Block": "134",
            "label": "PUMS KILARIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16091",
            "district": "35",
            "Block": "134",
            "label": "AMS PATHUR MELKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16092",
            "district": "35",
            "Block": "134",
            "label": "PUMS KULIKKARAI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16093",
            "district": "35",
            "Block": "134",
            "label": "GHSS,KULIKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16094",
            "district": "35",
            "Block": "134",
            "label": "PUMS, CHETTISIMIZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16095",
            "district": "35",
            "Block": "134",
            "label": "PUMS THIRUKKANNAMANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16096",
            "district": "35",
            "Block": "134",
            "label": "PUMS MELARATHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16097",
            "district": "35",
            "Block": "134",
            "label": "GHSS,KATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16098",
            "district": "35",
            "Block": "134",
            "label": "PUMS ANAIVADAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16099",
            "district": "35",
            "Block": "134",
            "label": "PUMS ERUKKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16100",
            "district": "35",
            "Block": "134",
            "label": "PUMS MELATHIRUMATHIKUNNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16101",
            "district": "35",
            "Block": "134",
            "label": "PUMS KODIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16102",
            "district": "35",
            "Block": "134",
            "label": "PUMS MUSIRIAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16103",
            "district": "35",
            "Block": "134",
            "label": "GMS DEVARKANDANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16104",
            "district": "35",
            "Block": "134",
            "label": "GGHSS,KORADACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16105",
            "district": "35",
            "Block": "134",
            "label": "GBHSS ,KORADACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16106",
            "district": "35",
            "Block": "134",
            "label": "UAHS UNITED MATRIC,KORADACHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16107",
            "district": "35",
            "Block": "205",
            "label": "GHS, KEERANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16108",
            "district": "35",
            "Block": "205",
            "label": "ADWHSS, NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16109",
            "district": "35",
            "Block": "205",
            "label": "UAHS,MASH,PAVATTAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16110",
            "district": "35",
            "Block": "205",
            "label": "GHSS , KADUVANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16111",
            "district": "35",
            "Block": "205",
            "label": "PUMS,THIRUMEEACHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16112",
            "district": "35",
            "Block": "205",
            "label": "PUMS, PANDARAVADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16113",
            "district": "35",
            "Block": "205",
            "label": "GHS, KOLLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16114",
            "district": "35",
            "Block": "205",
            "label": "UAMS AL-ISLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16115",
            "district": "35",
            "Block": "205",
            "label": "AMS, POONTHOTTAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16116",
            "district": "35",
            "Block": "205",
            "label": "GHSS, POONTHOTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16117",
            "district": "35",
            "Block": "205",
            "label": "UAMHSS, LALITHAMBIKAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16118",
            "district": "35",
            "Block": "205",
            "label": "PUMS,MAHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16119",
            "district": "35",
            "Block": "205",
            "label": "GHSS SRIVANCHIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16120",
            "district": "35",
            "Block": "205",
            "label": "GHSS,ACHUTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16121",
            "district": "35",
            "Block": "205",
            "label": "AHS, MUDIKONDAN",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16122",
            "district": "35",
            "Block": "205",
            "label": "GHSS   ANAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16123",
            "district": "35",
            "Block": "205",
            "label": "GHS, MAPPILAIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16124",
            "district": "35",
            "Block": "205",
            "label": "GHSS, PANANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16125",
            "district": "35",
            "Block": "205",
            "label": "PUMS,MOONGILGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16126",
            "district": "35",
            "Block": "205",
            "label": "AMS-THANGAM, NALLAMANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16127",
            "district": "35",
            "Block": "205",
            "label": "UAMHSS, VALLALAR GURUKULAM NANNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16128",
            "district": "35",
            "Block": "205",
            "label": "GGHSS, NANNILAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16129",
            "district": "35",
            "Block": "205",
            "label": "GBHSS, NANNILAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16130",
            "district": "35",
            "Block": "205",
            "label": "GHS, SANNANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16131",
            "district": "35",
            "Block": "205",
            "label": "UAHS,SRIVIDHYA  MATRICULATION ,PERALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16132",
            "district": "35",
            "Block": "205",
            "label": "UAMHSS SANKARA PERALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16133",
            "district": "35",
            "Block": "205",
            "label": "GGHSS ,PERALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16134",
            "district": "35",
            "Block": "205",
            "label": "GHSS PERALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16135",
            "district": "35",
            "Block": "340",
            "label": "GHSS, ADIYAKKAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16136",
            "district": "35",
            "Block": "340",
            "label": "GHSS, ALIVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16137",
            "district": "35",
            "Block": "340",
            "label": "ADWHS , AMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16138",
            "district": "35",
            "Block": "340",
            "label": "PUMS, KALLIKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16139",
            "district": "35",
            "Block": "340",
            "label": "PUMS KALYANAMAHADEVI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16140",
            "district": "35",
            "Block": "340",
            "label": "GHS,KOTTARAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16141",
            "district": "35",
            "Block": "340",
            "label": "UAHSS,THE MERIT SENTHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16142",
            "district": "35",
            "Block": "340",
            "label": "PUMS KOODUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16143",
            "district": "35",
            "Block": "340",
            "label": "GHS MANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16144",
            "district": "35",
            "Block": "340",
            "label": "PUMS.ODACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16145",
            "district": "35",
            "Block": "340",
            "label": "GHS PAZHAYAVALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16146",
            "district": "35",
            "Block": "340",
            "label": "GHSS, PUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16147",
            "district": "35",
            "Block": "340",
            "label": "PUMS PUTHUPATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16148",
            "district": "35",
            "Block": "340",
            "label": "GHSS, PULIVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16149",
            "district": "35",
            "Block": "340",
            "label": "UAMS,SRIKANCHI-SANKARA, PULIVALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16150",
            "district": "35",
            "Block": "340",
            "label": "PUMS  PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16151",
            "district": "35",
            "Block": "340",
            "label": "PUMS THANDALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16152",
            "district": "35",
            "Block": "340",
            "label": "PUMS, THIYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16153",
            "district": "35",
            "Block": "340",
            "label": "GHS, VILAMAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16154",
            "district": "35",
            "Block": "340",
            "label": "AMS SRI KRISHNA ,THAPPALAMPULIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16155",
            "district": "35",
            "Block": "340",
            "label": "PUMS THIRUKKARAVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16156",
            "district": "35",
            "Block": "340",
            "label": "PUMS KOMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16157",
            "district": "35",
            "Block": "340",
            "label": "GHSS, THIRUNEIPAIR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16158",
            "district": "35",
            "Block": "340",
            "label": "PUMS TIRUVATHIRAIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16159",
            "district": "35",
            "Block": "340",
            "label": "GHSS, SURANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16160",
            "district": "35",
            "Block": "340",
            "label": "GHS,VADAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16161",
            "district": "35",
            "Block": "340",
            "label": "PUMS  VAIPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16162",
            "district": "35",
            "Block": "340",
            "label": "PUMS VEPPATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16163",
            "district": "35",
            "Block": "340",
            "label": "AHSS, VELUDAYAR ,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16164",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS,KASTURBA ,TIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16165",
            "district": "35",
            "Block": "340",
            "label": "AMS-SS ,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16166",
            "district": "35",
            "Block": "340",
            "label": "ABHSS,VST, TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16167",
            "district": "35",
            "Block": "340",
            "label": "GHSS, KODIKKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16168",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS,SAIRAM,TIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16169",
            "district": "35",
            "Block": "340",
            "label": "UAMHS ,NETHAJI SUBASH BOSE MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16170",
            "district": "35",
            "Block": "340",
            "label": "MMS,GOWRISAMY , THIRUVARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16171",
            "district": "35",
            "Block": "340",
            "label": "AMS, SAIVAPRAGASA,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16172",
            "district": "35",
            "Block": "340",
            "label": "AGHSS,GRM,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16173",
            "district": "35",
            "Block": "340",
            "label": "MUNICIPAL  HIGH SCHOOL , MADAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16174",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS, MEENATCHI MATRIC,TIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16175",
            "district": "35",
            "Block": "340",
            "label": "AMS KIDARAMKONDAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16176",
            "district": "35",
            "Block": "340",
            "label": "UAHSS SHUBHA VIDYALAYA MATRICULATION",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16177",
            "district": "35",
            "Block": "340",
            "label": "UAHSS-CBSE-TRINITY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16178",
            "district": "35",
            "Block": "340",
            "label": "AMS, MOSES",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16179",
            "district": "35",
            "Block": "340",
            "label": "AMS, RAMAN,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16180",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS DEVI MATRIC,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16181",
            "district": "35",
            "Block": "173",
            "label": "PUMS.VENKARAMPERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16182",
            "district": "35",
            "Block": "173",
            "label": "PUMS,CHITHIRAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16183",
            "district": "35",
            "Block": "173",
            "label": "PUMS.ARICHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16184",
            "district": "35",
            "Block": "173",
            "label": "UAMS,SRI VIVEKANANDHA VIDYALAYA  MATRICULATION  SCHOOL , KARIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16185",
            "district": "35",
            "Block": "173",
            "label": "PUMS.KANDITHAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16186",
            "district": "35",
            "Block": "173",
            "label": "GHSS,THIRUVALLUVAR ALANGOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16187",
            "district": "35",
            "Block": "173",
            "label": "GHSS MAHADEVAPPATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16188",
            "district": "35",
            "Block": "173",
            "label": "PUMS.MELATHIRUPPALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16189",
            "district": "35",
            "Block": "173",
            "label": "PUMS, THIRURAMESWARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16190",
            "district": "35",
            "Block": "173",
            "label": "AHS,THIRUMURUGAN,MELAVASAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16191",
            "district": "35",
            "Block": "173",
            "label": "PUMS.MARAVAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16192",
            "district": "35",
            "Block": "173",
            "label": "GHS, THANNEERKUNNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16193",
            "district": "35",
            "Block": "173",
            "label": "PUMS.RAJAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16194",
            "district": "35",
            "Block": "173",
            "label": "GHSS PAINGANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16195",
            "district": "35",
            "Block": "173",
            "label": "AMS VENUDAIYAR, PALAIYANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16196",
            "district": "35",
            "Block": "173",
            "label": "GHSS PARAVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16197",
            "district": "35",
            "Block": "173",
            "label": "GHSS,PULLAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16198",
            "district": "35",
            "Block": "173",
            "label": "GHSS,SERANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16199",
            "district": "35",
            "Block": "173",
            "label": "PUMS.SUNDHARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16200",
            "district": "35",
            "Block": "173",
            "label": "ADWHSS, SAVALAKARAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16201",
            "district": "35",
            "Block": "173",
            "label": "AHS MEENAKSHI MARIYAPPAR, CHOLAPANDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16202",
            "district": "35",
            "Block": "173",
            "label": "GHS,THULASENDRAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16203",
            "district": "35",
            "Block": "173",
            "label": "PUMS,VADAKOVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16204",
            "district": "35",
            "Block": "173",
            "label": "GHS, VADAPATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16205",
            "district": "35",
            "Block": "173",
            "label": "PUMS.BOOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16206",
            "district": "35",
            "Block": "173",
            "label": "PUMS.VADAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16207",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS,NAVA BHARATH MATRIC HR.SEC.SCHOOL ULLIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16208",
            "district": "35",
            "Block": "173",
            "label": "GHSS,ULLIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16209",
            "district": "35",
            "Block": "173",
            "label": "CBSE, ARJ HR.SEC SCHOOL, EDAYAR NATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16210",
            "district": "35",
            "Block": "173",
            "label": "UAMS ESAR MATRIC SCHOOL, LAKSHMANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16211",
            "district": "35",
            "Block": "173",
            "label": "GBHSS, KOOTHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16212",
            "district": "35",
            "Block": "173",
            "label": "PUMS MARAKKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16213",
            "district": "35",
            "Block": "173",
            "label": "AHSS (BOYS) ,MANBA-UL-ULA KOOTHANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16214",
            "district": "35",
            "Block": "173",
            "label": "GGHSS,KOOTHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16215",
            "district": "35",
            "Block": "173",
            "label": "CWSN MS MANOLAYAM, MELAPANANGATTANKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16216",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS SBA MATRIC SCHOOL,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16217",
            "district": "35",
            "Block": "173",
            "label": "AMS JAYALAKSHMI VILAS, MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16218",
            "district": "35",
            "Block": "173",
            "label": "AMS,VANI VILAS, MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16219",
            "district": "35",
            "Block": "173",
            "label": "AHS ADHIPARASAKTHI, MANNARGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16220",
            "district": "35",
            "Block": "173",
            "label": "UAMHS,ASHOKA SISHU VIHAR MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16221",
            "district": "35",
            "Block": "173",
            "label": "AMS,MANIMEKALAI ,MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16222",
            "district": "35",
            "Block": "173",
            "label": "AHSS,NATIONAL, MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16223",
            "district": "35",
            "Block": "173",
            "label": "UAMHS,BARATHI VIDYALAYA, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16224",
            "district": "35",
            "Block": "173",
            "label": "UAMHS SUBASH CHANDRA BOSE MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16225",
            "district": "35",
            "Block": "173",
            "label": "GGHSS MODEL ,MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16226",
            "district": "35",
            "Block": "173",
            "label": "AGHSS ST.JOSEPH, MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16227",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS SAVIOUR JESUS MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16228",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS SRI SHANMUKA , MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16229",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS,BHARATHIDASAN MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16230",
            "district": "35",
            "Block": "173",
            "label": "UAHSS,LORD SEVEN HILLS MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16231",
            "district": "35",
            "Block": "173",
            "label": "UAHS,MANNAI NARAYANASAMY MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16232",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS SRI SHANMUKA , NEW BYE PASS ROAD, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16233",
            "district": "35",
            "Block": "173",
            "label": "AHS ST JOSEPH MANNARGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16234",
            "district": "35",
            "Block": "173",
            "label": "CBSE DHARANI VIDYA MANTHIR  ,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16235",
            "district": "35",
            "Block": "209",
            "label": "UAHSS, MOTHERINDIA, VALACHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16236",
            "district": "35",
            "Block": "209",
            "label": "PUMS,PODAKKUDI SOUTH SSA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16237",
            "district": "35",
            "Block": "209",
            "label": "GBHSS, PODAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16238",
            "district": "35",
            "Block": "209",
            "label": "GGHSS, PODAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16239",
            "district": "35",
            "Block": "209",
            "label": "GHS , KOVILVENNI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16240",
            "district": "35",
            "Block": "209",
            "label": "GHS, EDAKEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16241",
            "district": "35",
            "Block": "209",
            "label": "GHSS, MUNNAVALKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16242",
            "district": "35",
            "Block": "209",
            "label": "GHS, PERAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16243",
            "district": "35",
            "Block": "209",
            "label": "PUMS, KALACHERI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16244",
            "district": "35",
            "Block": "209",
            "label": "GHSS, RAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16245",
            "district": "35",
            "Block": "209",
            "label": "AHSS VADUVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16246",
            "district": "35",
            "Block": "209",
            "label": "UAMHSS,L.F.VISWANATH, EDAMELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16247",
            "district": "35",
            "Block": "209",
            "label": "GHS, PULLAVARAYANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16248",
            "district": "35",
            "Block": "209",
            "label": "GHS, KATTAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16249",
            "district": "35",
            "Block": "209",
            "label": "GHSS, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16250",
            "district": "35",
            "Block": "209",
            "label": "PUMS, THALIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16251",
            "district": "35",
            "Block": "209",
            "label": "GHSS, KARUVAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16252",
            "district": "35",
            "Block": "209",
            "label": "PUMS, VADUVUR THENPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16253",
            "district": "35",
            "Block": "209",
            "label": "PUMS, NALLIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16254",
            "district": "35",
            "Block": "209",
            "label": "PUMS, MELAPOOVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16255",
            "district": "35",
            "Block": "209",
            "label": "GHS, POOVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16256",
            "district": "35",
            "Block": "209",
            "label": "PAHS,MELALAVANTHACHERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16257",
            "district": "35",
            "Block": "209",
            "label": "UAMHSS, NEELAN MATRIC HR.SEC,SCHOOL.NEEDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16258",
            "district": "35",
            "Block": "209",
            "label": "AHSS, NEEDAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16259",
            "district": "35",
            "Block": "209",
            "label": "UAMHSS, ST.JUDE'S MATRIC HIGHER SECONDARY SCHOOL,  NEEDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16260",
            "district": "35",
            "Block": "209",
            "label": "GHS, NEEDAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16261",
            "district": "35",
            "Block": "137",
            "label": "PUMS, ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16262",
            "district": "35",
            "Block": "137",
            "label": "AMS, ST ANNE'S MIDDLE SCHOOL DEVATHANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16263",
            "district": "35",
            "Block": "137",
            "label": "GHSS,KALAPPAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16264",
            "district": "35",
            "Block": "137",
            "label": "PUMS, NARUVALI KALAPPAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16265",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KEZHUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16266",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KARUPPUKILAR GANESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16267",
            "district": "35",
            "Block": "137",
            "label": "GGHSS,KOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16268",
            "district": "35",
            "Block": "137",
            "label": "GHSS,KOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16269",
            "district": "35",
            "Block": "137",
            "label": "PUMS, 83.KULAMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16270",
            "district": "35",
            "Block": "137",
            "label": "GHSS,PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16271",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KUNNIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16272",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16273",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MANNUKUMUNDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16274",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MAVATTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16275",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MELANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16276",
            "district": "35",
            "Block": "137",
            "label": "GHS,MAZHAVARAYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16277",
            "district": "35",
            "Block": "137",
            "label": "PUMS, NOCHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16278",
            "district": "35",
            "Block": "137",
            "label": "PUMS, ORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16279",
            "district": "35",
            "Block": "137",
            "label": "GHSS ,PALAYAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16280",
            "district": "35",
            "Block": "137",
            "label": "GHS,MELAPANAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16281",
            "district": "35",
            "Block": "137",
            "label": "GHSS,PERUGAVAZHNDAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16282",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16283",
            "district": "35",
            "Block": "137",
            "label": "GHS,PALAYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16284",
            "district": "35",
            "Block": "137",
            "label": "PUMS, RENGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16285",
            "district": "35",
            "Block": "137",
            "label": "GHS,SERUVAMANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16286",
            "district": "35",
            "Block": "137",
            "label": "GHS,THENPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16287",
            "district": "35",
            "Block": "137",
            "label": "GHS,THERKUNANALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16288",
            "district": "35",
            "Block": "137",
            "label": "GHSS(B) THIRUMAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16289",
            "district": "35",
            "Block": "137",
            "label": "GGHSS  THIRUMAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16290",
            "district": "35",
            "Block": "137",
            "label": "GHS,THIRUKKALAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16291",
            "district": "35",
            "Block": "137",
            "label": "PUMS, THIRUNELLIKAVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16292",
            "district": "35",
            "Block": "137",
            "label": "GHS,VATTAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16293",
            "district": "35",
            "Block": "137",
            "label": "GHS,VIKIRAPANDIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16294",
            "district": "35",
            "Block": "334",
            "label": "PUMS ANDANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16295",
            "district": "35",
            "Block": "334",
            "label": "PUMS RAJAKOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16296",
            "district": "35",
            "Block": "334",
            "label": "AHS, JOHN PRITO PALLANKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16297",
            "district": "35",
            "Block": "334",
            "label": "AMS,RAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16298",
            "district": "35",
            "Block": "334",
            "label": "GHS, EZHILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16299",
            "district": "35",
            "Block": "334",
            "label": "PUMS KOMALAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16300",
            "district": "35",
            "Block": "334",
            "label": "PUMS THIRUPATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16301",
            "district": "35",
            "Block": "334",
            "label": "PUMS ALIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16302",
            "district": "35",
            "Block": "334",
            "label": "PUMS VARAMBIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16303",
            "district": "35",
            "Block": "334",
            "label": "PUMS KUNNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16304",
            "district": "35",
            "Block": "334",
            "label": "GHSS  NEDUMBALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16305",
            "district": "35",
            "Block": "334",
            "label": "GHSS, ALATHAMPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16306",
            "district": "35",
            "Block": "334",
            "label": "GHSS VILAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16307",
            "district": "35",
            "Block": "334",
            "label": "UAHS VENKATESWARA MATRICULATION SCHOOL KATCHANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16308",
            "district": "35",
            "Block": "334",
            "label": "GHS KATCHANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16309",
            "district": "35",
            "Block": "334",
            "label": "PUMS ARAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16310",
            "district": "35",
            "Block": "334",
            "label": "PUMS ADIRENGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16311",
            "district": "35",
            "Block": "334",
            "label": "PUMS PALAYANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16312",
            "district": "35",
            "Block": "334",
            "label": "GHSS,KATTIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16313",
            "district": "35",
            "Block": "334",
            "label": "PUMS SEKAL KOTTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16314",
            "district": "35",
            "Block": "334",
            "label": "PUMS MANALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16315",
            "district": "35",
            "Block": "334",
            "label": "GHS MANALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16316",
            "district": "35",
            "Block": "334",
            "label": "PUMS SEKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16317",
            "district": "35",
            "Block": "334",
            "label": "PUMS SEKAL MADAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16318",
            "district": "35",
            "Block": "334",
            "label": "PUMS MELAKORUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16319",
            "district": "35",
            "Block": "334",
            "label": "AMS KORUKKAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16320",
            "district": "35",
            "Block": "334",
            "label": "GHSS, THALAIKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16321",
            "district": "35",
            "Block": "334",
            "label": "UAHS SAI SRINIVAS,TTP",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16322",
            "district": "35",
            "Block": "334",
            "label": "GHSS PAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16323",
            "district": "35",
            "Block": "334",
            "label": "PUMS PITCHANKOTTAGAM(N)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16324",
            "district": "35",
            "Block": "334",
            "label": "PUMS MELAMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16325",
            "district": "35",
            "Block": "334",
            "label": "GHS, THIRUTHANGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16326",
            "district": "35",
            "Block": "334",
            "label": "AMS, KEERAKKALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16327",
            "district": "35",
            "Block": "334",
            "label": "UAMHS ST.THERASA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16328",
            "district": "35",
            "Block": "334",
            "label": "AHSS,ST.THERASA,TTP",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16329",
            "district": "35",
            "Block": "334",
            "label": "ADWHSS,ABISEKAKATTALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16330",
            "district": "35",
            "Block": "334",
            "label": "GHSS GIRLS THIRUTHURAIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16331",
            "district": "35",
            "Block": "334",
            "label": "GBHSS,THIRUTHURAIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16332",
            "district": "35",
            "Block": "193",
            "label": "GHS ALANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16333",
            "district": "35",
            "Block": "193",
            "label": "GHSS IDUMBAVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16334",
            "district": "35",
            "Block": "193",
            "label": "GHS, KEEZHAVADIYAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16335",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KALLADIKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16336",
            "district": "35",
            "Block": "193",
            "label": "GHS,JAMBUVANODAI NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16337",
            "district": "35",
            "Block": "193",
            "label": "UAMHS, OMA JAMBUVANODAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16338",
            "district": "35",
            "Block": "193",
            "label": "UAMHSS PALM KALLIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16339",
            "district": "35",
            "Block": "193",
            "label": "GHS,KEELAPERUMAZHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16340",
            "district": "35",
            "Block": "193",
            "label": "GHS MANKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16341",
            "district": "35",
            "Block": "193",
            "label": "GHS MARUTHAVANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16342",
            "district": "35",
            "Block": "193",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VELLANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16343",
            "district": "35",
            "Block": "193",
            "label": "UAMHS SRI OM SAKTHI ,VEPPANCHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16344",
            "district": "35",
            "Block": "193",
            "label": "GHSS THILLAIVILGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16345",
            "district": "35",
            "Block": "193",
            "label": "GHS SENGANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16346",
            "district": "35",
            "Block": "193",
            "label": "GHSS,NACHIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16347",
            "district": "35",
            "Block": "193",
            "label": "UAMHS MILLENNIUM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16348",
            "district": "35",
            "Block": "193",
            "label": "GHS-KARAIYANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16349",
            "district": "35",
            "Block": "193",
            "label": "KAP AGHSS, PERIYANAYAKI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16350",
            "district": "35",
            "Block": "193",
            "label": "GHSS MUTHUPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16351",
            "district": "35",
            "Block": "193",
            "label": "UAMHSS,RAHAMATH, MUTHUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16352",
            "district": "35",
            "Block": "193",
            "label": "UAMHS,WINNER ALANGADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20989",
            "district": "35",
            "Block": "134",
            "label": "KV  KENDRIYA VIDYALAYA THIRUVARUR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21222",
            "district": "35",
            "Block": "209",
            "label": "GHSS,EDAMELAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21223",
            "district": "35",
            "Block": "137",
            "label": "GHSS, RADHANARASIMMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21225",
            "district": "35",
            "Block": "173",
            "label": "AHSS FINDLAY ,MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21232",
            "district": "35",
            "Block": "173",
            "label": "MHSS CO_OPERATIVE URBAN,MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21394",
            "district": "35",
            "Block": "382",
            "label": "GHS ENAMKILIUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21395",
            "district": "35",
            "Block": "382",
            "label": "GHS SITHANVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21472",
            "district": "35",
            "Block": "173",
            "label": "GHS,MOOVANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21473",
            "district": "35",
            "Block": "193",
            "label": "GHS, PANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21474",
            "district": "35",
            "Block": "193",
            "label": "GHS THONDIAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21535",
            "district": "35",
            "Block": "382",
            "label": "AHS NIRMALA MANAKUNDU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21536",
            "district": "35",
            "Block": "141",
            "label": "AHS, PERUMPANNAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21537",
            "district": "35",
            "Block": "340",
            "label": "AHS RC FATHIMA , THIRUVARUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21538",
            "district": "35",
            "Block": "137",
            "label": "AHS , ST ANTONY'S HIGH SCHOOL ADICHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21608",
            "district": "35",
            "Block": "382",
            "label": "UAMS CVS FREEDOM VIDHYALAYA MATRICULATION",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21609",
            "district": "35",
            "Block": "134",
            "label": "CBSE VIVEKANANDAM VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21764",
            "district": "35",
            "Block": "173",
            "label": "UAMS DR ALAMEEN AR ,MATRICULATION SCHOOL  NAHANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22221",
            "district": "35",
            "Block": "141",
            "label": "GHS ,SEETHAKKAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22570",
            "district": "35",
            "Block": "134",
            "label": "UAHSS OYSTER  LEARNING   INITIATIVE  MATRICULATION SCHOOL  ,AMMAIYAPPAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22571",
            "district": "35",
            "Block": "205",
            "label": "GHS,PILLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22572",
            "district": "35",
            "Block": "205",
            "label": "GHS,MENANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22573",
            "district": "35",
            "Block": "173",
            "label": "GHS,VELUKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22574",
            "district": "35",
            "Block": "334",
            "label": "UAMS ,SVS INTERNATIONAL CBSC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22768",
            "district": "35",
            "Block": "340",
            "label": "CBSE RC Fathima public school,Thiruvarur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22769",
            "district": "35",
            "Block": "173",
            "label": "CBSE,DELTA PUBLIC SCHOOL,PANANKATTANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22884",
            "district": "35",
            "Block": "382",
            "label": "GHS Thenkuvalaveli",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22885",
            "district": "35",
            "Block": "205",
            "label": "GHS Ubhayavethanthapuram",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22886",
            "district": "35",
            "Block": "209",
            "label": "GHS Karakottai",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23287",
            "district": "35",
            "Block": "340",
            "label": "CBSE MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23300",
            "district": "35",
            "Block": "137",
            "label": "UAMS KALAIVANI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23625",
            "district": "35",
            "Block": "134",
            "label": "TIRUVARUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23626",
            "district": "35",
            "Block": "173",
            "label": "CBSE CONCORDIA PUBLIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23627",
            "district": "35",
            "Block": "340",
            "label": "UAHS MAULANA ABULKALAM AZAD MATRICULATION , THIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23628",
            "district": "35",
            "Block": "382",
            "label": "UAHS MAHADEVA GURUJI VIDYASHRAM ALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23831",
            "district": "35",
            "Block": "134",
            "label": "PUMS KODIMANGALAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23832",
            "district": "35",
            "Block": "134",
            "label": "TIRUVARUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23833",
            "district": "35",
            "Block": "137",
            "label": "PUMS, ALATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23834",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KURICHI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23835",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KUMARAMANGALAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23836",
            "district": "35",
            "Block": "137",
            "label": "PUMS, RENGANATHAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23842",
            "district": "35",
            "Block": "141",
            "label": "PUMS, ALATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23843",
            "district": "35",
            "Block": "141",
            "label": "BLOOM THE NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23874",
            "district": "35",
            "Block": "173",
            "label": "UAPPS KASTURBA GANDHI MATRICULATION,  PUNAVASAL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24021",
            "district": "35",
            "Block": "340",
            "label": "PUMS THANDALAI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24022",
            "district": "35",
            "Block": "340",
            "label": "GHS,VADAKARAI",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "24056",
            "district": "35",
            "Block": "382",
            "label": "PUMS KANDIYUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24057",
            "district": "35",
            "Block": "382",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24058",
            "district": "35",
            "Block": "382",
            "label": "PUMS NALLUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "36": [
        {
            "key": "564",
            "district": "36",
            "Block": "12",
            "label": "FREEDOM CONCEPT CBSE POIGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "583",
            "district": "36",
            "Block": "74",
            "label": "Dr.KRISHNASWAMI MATRIC SCHOOL, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "628",
            "district": "36",
            "Block": "394",
            "label": "LAKSHMI GARDEN MHSS, VELLORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "634",
            "district": "36",
            "Block": "12",
            "label": "SHILOH MATRIC SCHOOL, ANAICUT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "637",
            "district": "36",
            "Block": "105",
            "label": "SEVENTH DAY ADVENTIST MATRIC HSS ,OTTERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "738",
            "district": "36",
            "Block": "393",
            "label": "V V N K M ENG (CBSE) SCHOOL, ALLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "757",
            "district": "36",
            "Block": "74",
            "label": "ST JOHNS MHSS GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "939",
            "district": "36",
            "Block": "394",
            "label": "GGHSS EVRN MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1212",
            "district": "36",
            "Block": "105",
            "label": "SUN MATRIC HSS , VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1291",
            "district": "36",
            "Block": "84",
            "label": "SRI VIDHYALAKSHMI MATRIC HSS, CHENNANGKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1376",
            "district": "36",
            "Block": "393",
            "label": "HOLY CROSS MATRIC HSS, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1444",
            "district": "36",
            "Block": "74",
            "label": "S.D.A MATRIC HSS, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1541",
            "district": "36",
            "Block": "74",
            "label": "PUMS MUNAFF DIBBO ( U )",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1561",
            "district": "36",
            "Block": "113",
            "label": "ST.MARKS MATRIC HSS, KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1568",
            "district": "36",
            "Block": "393",
            "label": "SNEHA DEEPAM MATRIC HSS, ALAMELUMANGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1614",
            "district": "36",
            "Block": "243",
            "label": "NEW WISDOM MATRIC  HSS, EARIGUTHIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1702",
            "district": "36",
            "Block": "74",
            "label": "GGHSS NADUPET, GUDIYATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1813",
            "district": "36",
            "Block": "113",
            "label": "SHRISHTI MATRIC HSS, BRAMMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1829",
            "district": "36",
            "Block": "393",
            "label": "ETHIRAJ MATRIC HSS, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1842",
            "district": "36",
            "Block": "12",
            "label": "SHRI VARDHAMAN JAIN MATRIC HSS,VIRINCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1905",
            "district": "36",
            "Block": "393",
            "label": "SHANTHINIKETHAN MATRIC HSS, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1937",
            "district": "36",
            "Block": "113",
            "label": "DR.VGN MATRIC SCHOOL, PONNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2141",
            "district": "36",
            "Block": "74",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOONGAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2176",
            "district": "36",
            "Block": "393",
            "label": "OM SAKTHI NARAYANI MATRIC HSS, SRIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2210",
            "district": "36",
            "Block": "113",
            "label": "VIDYANIKETAN MATRIC HSS, GANDHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2282",
            "district": "36",
            "Block": "113",
            "label": "BETHEL MATRIC HSS, VALLIMALAI ROAD ,KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2421",
            "district": "36",
            "Block": "74",
            "label": "PUMS RAJAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2426",
            "district": "36",
            "Block": "105",
            "label": "PUMS KATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3031",
            "district": "36",
            "Block": "74",
            "label": "MD AMS CHINNATHOTTALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3066",
            "district": "36",
            "Block": "243",
            "label": "PUMS MORASAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3071",
            "district": "36",
            "Block": "105",
            "label": "PUMS SALAMANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3158",
            "district": "36",
            "Block": "393",
            "label": "PUMS ANPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3228",
            "district": "36",
            "Block": "113",
            "label": "VANI VIDYALAYA MATRIC. HR. SEC. SCHOOL KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3259",
            "district": "36",
            "Block": "393",
            "label": "KOONA PRESIDENCY MATRIC HSS, KONAVATTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3288",
            "district": "36",
            "Block": "74",
            "label": "HABEEB MATRIC HS SCHOOL, SANTHAPETAI, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3333",
            "district": "36",
            "Block": "74",
            "label": "PUMS LINGUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3384",
            "district": "36",
            "Block": "74",
            "label": "PUMS ERTHANGAL PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3386",
            "district": "36",
            "Block": "74",
            "label": "RAJIV GANDHI MATRIC SCHOOL, GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3409",
            "district": "36",
            "Block": "113",
            "label": "DONBOSCO MATRIC HSS, KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3444",
            "district": "36",
            "Block": "113",
            "label": "WILLIAMS MATRIC HSS, VELLAIKALMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3560",
            "district": "36",
            "Block": "74",
            "label": "PUMS DHANAKONDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3579",
            "district": "36",
            "Block": "74",
            "label": "APS MADARASE-E-NISWAN, MELALATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3758",
            "district": "36",
            "Block": "74",
            "label": "PUMS MELMUTTUKOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3805",
            "district": "36",
            "Block": "113",
            "label": "CLUNY MHSS KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3829",
            "district": "36",
            "Block": "113",
            "label": "SHRI MANU MAKHIJA MEMORIAL SHRISHTI MATRIC SCHOOL, SENGUTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3871",
            "district": "36",
            "Block": "113",
            "label": "SHRISHTI CBSE BRAMMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3888",
            "district": "36",
            "Block": "113",
            "label": "SUNBEAM MATRIC HSS, METTUKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3991",
            "district": "36",
            "Block": "74",
            "label": "PUMS ERIPATTARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4004",
            "district": "36",
            "Block": "12",
            "label": "SHIKSHA KENDRA MHSS PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4026",
            "district": "36",
            "Block": "105",
            "label": "CAMFORD MATRIC HSS, KANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4045",
            "district": "36",
            "Block": "393",
            "label": "SARASWATHI VIDYALAYA MATRIC SCHOOL, ALAMELUMANGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4066",
            "district": "36",
            "Block": "74",
            "label": "PUMS PONNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4090",
            "district": "36",
            "Block": "74",
            "label": "PUMS RAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4102",
            "district": "36",
            "Block": "74",
            "label": "PUMS ANANGANALLORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4116",
            "district": "36",
            "Block": "113",
            "label": "PUMS KAMMAVAR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4161",
            "district": "36",
            "Block": "74",
            "label": "PUMS MELALATHUR (H)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4242",
            "district": "36",
            "Block": "113",
            "label": "Kings Matric HSS Kasam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4265",
            "district": "36",
            "Block": "393",
            "label": "DESIA MATRIC SCHOOL, SASTHRI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4317",
            "district": "36",
            "Block": "74",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOTTARAMADUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4359",
            "district": "36",
            "Block": "113",
            "label": "SUNBEAM CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4378",
            "district": "36",
            "Block": "74",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEEVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4433",
            "district": "36",
            "Block": "105",
            "label": "PUMS PANGALATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4511",
            "district": "36",
            "Block": "12",
            "label": "PUMS OTHIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4514",
            "district": "36",
            "Block": "74",
            "label": "PUMS SENGUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4543",
            "district": "36",
            "Block": "105",
            "label": "PUMS BAGAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4570",
            "district": "36",
            "Block": "74",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MUKKUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4604",
            "district": "36",
            "Block": "113",
            "label": "PUMS L.G.Pudur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4729",
            "district": "36",
            "Block": "394",
            "label": "SAVIO MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4764",
            "district": "36",
            "Block": "394",
            "label": "AMS R.C.M UTHARIAMADHA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4768",
            "district": "36",
            "Block": "243",
            "label": "ADWMS MASIGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4814",
            "district": "36",
            "Block": "105",
            "label": "PUMS ADUKKAMBARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4815",
            "district": "36",
            "Block": "393",
            "label": "HOLY CROSS HSS FOR THE HEARING IMPAIRED -SATHUVACHARI,VELLORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4830",
            "district": "36",
            "Block": "393",
            "label": "SPARK MATRIC HSS, THIRUMALAIKODI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4854",
            "district": "36",
            "Block": "74",
            "label": "PUMS K V PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4867",
            "district": "36",
            "Block": "394",
            "label": "AUMS URDU GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4956",
            "district": "36",
            "Block": "74",
            "label": "PUMS NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4963",
            "district": "36",
            "Block": "12",
            "label": "PUMS  VASANTHANADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5000",
            "district": "36",
            "Block": "243",
            "label": "PUMS MEL KOTHAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5071",
            "district": "36",
            "Block": "74",
            "label": "PUMS SARAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5082",
            "district": "36",
            "Block": "393",
            "label": "PUMS ATHIYURVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5084",
            "district": "36",
            "Block": "113",
            "label": "PUMS Kuppatha Mottur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5087",
            "district": "36",
            "Block": "393",
            "label": "PUMS ABDULLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5089",
            "district": "36",
            "Block": "394",
            "label": "MMS EDA  STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5094",
            "district": "36",
            "Block": "74",
            "label": "PUMS PAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5190",
            "district": "36",
            "Block": "105",
            "label": "PUMS MOTHAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5255",
            "district": "36",
            "Block": "12",
            "label": "PUMS VARADHALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5404",
            "district": "36",
            "Block": "84",
            "label": "LITTLE STARS MATRIC HS  LATHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5426",
            "district": "36",
            "Block": "113",
            "label": "TRINITY MATRIC HSS, GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5465",
            "district": "36",
            "Block": "394",
            "label": "AMS SARASWATHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5474",
            "district": "36",
            "Block": "394",
            "label": "BHARAT MATRIC HSS, KOSAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5481",
            "district": "36",
            "Block": "12",
            "label": "PUMS OTTERIPALLAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5497",
            "district": "36",
            "Block": "113",
            "label": "PUMS BALAMUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5548",
            "district": "36",
            "Block": "113",
            "label": "PUMS PEDDABODINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5569",
            "district": "36",
            "Block": "113",
            "label": "DESIA MATRIC SCHOOL, VANJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5573",
            "district": "36",
            "Block": "12",
            "label": "PUMS ONGAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5581",
            "district": "36",
            "Block": "84",
            "label": "PUMS CHENNARAYANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5584",
            "district": "36",
            "Block": "113",
            "label": "MDMS BALAYANKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5591",
            "district": "36",
            "Block": "243",
            "label": "PUMS PERIYA DHAMAL CHERUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5678",
            "district": "36",
            "Block": "12",
            "label": "HOLY ANGELS MHSS PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5732",
            "district": "36",
            "Block": "74",
            "label": "PUMS SYNAKUNDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5742",
            "district": "36",
            "Block": "105",
            "label": "VDS MATRIC HSS, EDAYANSATHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5780",
            "district": "36",
            "Block": "84",
            "label": "PUMS B.N.PALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5790",
            "district": "36",
            "Block": "113",
            "label": "SWAMY VIVEKANANDA MATRIC  HR SEC SCHOOL, SERKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5793",
            "district": "36",
            "Block": "113",
            "label": "ADWMS Brammapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5823",
            "district": "36",
            "Block": "393",
            "label": "PUMS SADUPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5925",
            "district": "36",
            "Block": "74",
            "label": "PUMS INDRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6006",
            "district": "36",
            "Block": "394",
            "label": "RAMALINGAM MATRIC SCHOOL, SAIDAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6013",
            "district": "36",
            "Block": "74",
            "label": "ST.PETERS MATRIC SCHOOL, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6055",
            "district": "36",
            "Block": "12",
            "label": "PUMS VETTUVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6102",
            "district": "36",
            "Block": "394",
            "label": "MMS KAGITHAPATTARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6152",
            "district": "36",
            "Block": "394",
            "label": "SRI VASAVI MATRIC SCHOOL, VELLORE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6190",
            "district": "36",
            "Block": "12",
            "label": "SRI SAI LAKSHMI MATRIC SCHOOL,  PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6219",
            "district": "36",
            "Block": "243",
            "label": "PUMS CHINNA DHAMAL CHERUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6226",
            "district": "36",
            "Block": "74",
            "label": "PUMS GANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6229",
            "district": "36",
            "Block": "243",
            "label": "PUMS ODDARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23681",
            "district": "36",
            "Block": "105",
            "label": "Madras Matriculation Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6236",
            "district": "36",
            "Block": "113",
            "label": "AMS MSCA Thirupakkuttai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6279",
            "district": "36",
            "Block": "74",
            "label": "PUMS PUTTAVARIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6284",
            "district": "36",
            "Block": "113",
            "label": "PUMS Vandranthangal",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6368",
            "district": "36",
            "Block": "113",
            "label": "POORNA VIDHYALAYA MATRIC SCHOOL, THIRUVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6459",
            "district": "36",
            "Block": "113",
            "label": "PUMS THENPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6460",
            "district": "36",
            "Block": "84",
            "label": "PUMS KAVASAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6468",
            "district": "36",
            "Block": "74",
            "label": "PUMS S MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6476",
            "district": "36",
            "Block": "113",
            "label": "PUMS KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6479",
            "district": "36",
            "Block": "113",
            "label": "PUMS Eranthangal",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6505",
            "district": "36",
            "Block": "12",
            "label": "PUMS MARUTHAVALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6526",
            "district": "36",
            "Block": "12",
            "label": "PUMS KUTCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6559",
            "district": "36",
            "Block": "113",
            "label": "PUMS Kandipedu",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6573",
            "district": "36",
            "Block": "84",
            "label": "PUMS P.K.PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6587",
            "district": "36",
            "Block": "74",
            "label": "LITTLE FLOWER MATRIC SCHOOL , GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6597",
            "district": "36",
            "Block": "243",
            "label": "PUMS SARANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6637",
            "district": "36",
            "Block": "243",
            "label": "KALAIMAGAL MIDDLE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6639",
            "district": "36",
            "Block": "74",
            "label": "MMS GANGADARASAMY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6644",
            "district": "36",
            "Block": "393",
            "label": "VELLORE MATRIC SCHOOL, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8504",
            "district": "36",
            "Block": "113",
            "label": "PUMS THENGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8505",
            "district": "36",
            "Block": "113",
            "label": "PUMS PONNAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8506",
            "district": "36",
            "Block": "113",
            "label": "ANMMS P.N. PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8507",
            "district": "36",
            "Block": "113",
            "label": "GOVT GIRLS HSS PONNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8508",
            "district": "36",
            "Block": "113",
            "label": "GOVT BOYS HSS PONNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8509",
            "district": "36",
            "Block": "113",
            "label": "DECCAN MATRICULATION SCHOOL PONNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8510",
            "district": "36",
            "Block": "113",
            "label": "PUMS KOKKERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8511",
            "district": "36",
            "Block": "113",
            "label": "PUMS THEEYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8512",
            "district": "36",
            "Block": "113",
            "label": "ANMMS MILAGAIKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8513",
            "district": "36",
            "Block": "113",
            "label": "PUMS MELKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8514",
            "district": "36",
            "Block": "113",
            "label": "MDMS MELPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8515",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS VALLIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8516",
            "district": "36",
            "Block": "113",
            "label": "PUMS PERUMALKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8517",
            "district": "36",
            "Block": "113",
            "label": "GHS THATHIREDDIPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8518",
            "district": "36",
            "Block": "113",
            "label": "PUMS MUTHARASIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8519",
            "district": "36",
            "Block": "113",
            "label": "PUMS RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8520",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS VENNAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8521",
            "district": "36",
            "Block": "113",
            "label": "KRISHNA MATRIC HSS THENPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8630",
            "district": "36",
            "Block": "393",
            "label": "PUMS PULIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8631",
            "district": "36",
            "Block": "393",
            "label": "PUMS GURUMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8632",
            "district": "36",
            "Block": "393",
            "label": "PUMS USSOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8633",
            "district": "36",
            "Block": "393",
            "label": "GOVT. GIRLS HR. SEC. SCHOOL, USSOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8634",
            "district": "36",
            "Block": "393",
            "label": "GOVT. BOYS HR. SEC. SCHOOL, USSOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8635",
            "district": "36",
            "Block": "393",
            "label": "ADHITHYA VIDYASHRAM MATRIC SCHOOL, USSOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8636",
            "district": "36",
            "Block": "393",
            "label": "GOVT HS GOVINDA REDDY PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8637",
            "district": "36",
            "Block": "393",
            "label": "GHS MELMANAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8638",
            "district": "36",
            "Block": "393",
            "label": "ADWMS KARUKAMBATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8639",
            "district": "36",
            "Block": "393",
            "label": "PUMS SHENBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8640",
            "district": "36",
            "Block": "393",
            "label": "GADWMS SHENBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8641",
            "district": "36",
            "Block": "393",
            "label": "JANASAKTHI AMS",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8642",
            "district": "36",
            "Block": "393",
            "label": "GHSS KONAVATTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8643",
            "district": "36",
            "Block": "393",
            "label": "GHS SEMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8644",
            "district": "36",
            "Block": "393",
            "label": "GOVT HS ARIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8645",
            "district": "36",
            "Block": "393",
            "label": "HOLY QUEEN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8646",
            "district": "36",
            "Block": "393",
            "label": "PUMS THORAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8647",
            "district": "36",
            "Block": "393",
            "label": "Govt Hr. Sec.School, Thorapadi",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8648",
            "district": "36",
            "Block": "393",
            "label": "PUMS SAINATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8649",
            "district": "36",
            "Block": "393",
            "label": "KRISHNASAMY HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8650",
            "district": "36",
            "Block": "393",
            "label": "SREE JAYAM SCHOOL (ICSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8651",
            "district": "36",
            "Block": "393",
            "label": "PUMS SATHUVACHARI(E)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8652",
            "district": "36",
            "Block": "393",
            "label": "PUMS VOC NAGAR, SATHUVACHARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8653",
            "district": "36",
            "Block": "393",
            "label": "VALLALAR AMS SATHUVACHARI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8654",
            "district": "36",
            "Block": "393",
            "label": "GOVT HSS SATHUVACHARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8655",
            "district": "36",
            "Block": "393",
            "label": "ST.PAUL'S MATRIC HSS, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8656",
            "district": "36",
            "Block": "393",
            "label": "SIDDHARTHA SENIOR SECONDARY SCHOOL,VELLORE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8657",
            "district": "36",
            "Block": "393",
            "label": "SACRED HEART HIGH SCHOOL RANGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8658",
            "district": "36",
            "Block": "393",
            "label": "GADWHSS PERUMUGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8659",
            "district": "36",
            "Block": "393",
            "label": "G ADW HSS, Alamelumangapuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8660",
            "district": "36",
            "Block": "394",
            "label": "MMS BOOSHNAM NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8661",
            "district": "36",
            "Block": "394",
            "label": "MMS ETTIAMMAN KOIL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8662",
            "district": "36",
            "Block": "394",
            "label": "MMS MAIN BAZAAR HINDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8663",
            "district": "36",
            "Block": "394",
            "label": "MMS KALASPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8664",
            "district": "36",
            "Block": "394",
            "label": "MMS AMBEDKAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8665",
            "district": "36",
            "Block": "394",
            "label": "MHS KOSAPET MARKET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8666",
            "district": "36",
            "Block": "394",
            "label": "MHS S.K.M KASPA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8667",
            "district": "36",
            "Block": "394",
            "label": "VKVM GGHSS, VELAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8668",
            "district": "36",
            "Block": "394",
            "label": "MHS SALAVANPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8669",
            "district": "36",
            "Block": "394",
            "label": "GOVT MUNICIPAL GIRLS HSS, THOTTAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8670",
            "district": "36",
            "Block": "394",
            "label": "GOVT MUNICIPAL HSS KAKM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8671",
            "district": "36",
            "Block": "394",
            "label": "AMS CIRCAR MUNDY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8672",
            "district": "36",
            "Block": "394",
            "label": "AMS SARVAJANA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8673",
            "district": "36",
            "Block": "394",
            "label": "AMS SRI RAMANUJAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8674",
            "district": "36",
            "Block": "394",
            "label": "AMS SANKARANPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8675",
            "district": "36",
            "Block": "394",
            "label": "AMS RAMALINGAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8676",
            "district": "36",
            "Block": "394",
            "label": "AMS SRI RAMAKRISHNA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8677",
            "district": "36",
            "Block": "394",
            "label": "AHS DON BOSCO",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8678",
            "district": "36",
            "Block": "394",
            "label": "AHS REV. PONNURANGAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8679",
            "district": "36",
            "Block": "394",
            "label": "AHS HONNEGAR ASHRAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8680",
            "district": "36",
            "Block": "394",
            "label": "VOORHEES AHSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8681",
            "district": "36",
            "Block": "394",
            "label": "AHSS SRI VENKATESHWARA",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8682",
            "district": "36",
            "Block": "394",
            "label": "JOSEPH MATRIC SCHOOL, VASANTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8683",
            "district": "36",
            "Block": "394",
            "label": "GOVT MUSLIM HSS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8684",
            "district": "36",
            "Block": "394",
            "label": "AHSS ST. MARY'S",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8685",
            "district": "36",
            "Block": "394",
            "label": "VIDHYA MANDIR MATRIC HSS, VELLORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8686",
            "district": "36",
            "Block": "105",
            "label": "GHSS KILARASAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8687",
            "district": "36",
            "Block": "105",
            "label": "PUMS NANJUKONDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8688",
            "district": "36",
            "Block": "105",
            "label": "PUMS PALATHUVANNAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8689",
            "district": "36",
            "Block": "105",
            "label": "PUMS THUTHIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8690",
            "district": "36",
            "Block": "105",
            "label": "GHSS CHOLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8691",
            "district": "36",
            "Block": "105",
            "label": "PUMS PALLA EDAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8692",
            "district": "36",
            "Block": "105",
            "label": "GHSS VIRUPATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8693",
            "district": "36",
            "Block": "105",
            "label": "DESIA MATRIC HSS, VIRUPATCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8694",
            "district": "36",
            "Block": "105",
            "label": "MADRAS MHSS BAGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8695",
            "district": "36",
            "Block": "105",
            "label": "PUMS PALAVANSATHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8696",
            "district": "36",
            "Block": "105",
            "label": "GHS EDAYANSATHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8697",
            "district": "36",
            "Block": "105",
            "label": "PUMS METTU EDYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8698",
            "district": "36",
            "Block": "105",
            "label": "ST JOSEPH AHS A .KATTUPADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8699",
            "district": "36",
            "Block": "105",
            "label": "EXCEL MATRIC SCHOOL, ADUKAMPARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8700",
            "district": "36",
            "Block": "105",
            "label": "PUMS THUTHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8701",
            "district": "36",
            "Block": "105",
            "label": "GHSS PENNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8702",
            "district": "36",
            "Block": "105",
            "label": "ANNAI MATRICULATION SCHOOL PENNATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8703",
            "district": "36",
            "Block": "105",
            "label": "PUMS VEPPAMPATTU (H)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8704",
            "district": "36",
            "Block": "105",
            "label": "AIDED RCM MS K KATTUPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8705",
            "district": "36",
            "Block": "105",
            "label": "GHSS KANIYAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8706",
            "district": "36",
            "Block": "105",
            "label": "PUMS SATHUMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8707",
            "district": "36",
            "Block": "105",
            "label": "PUMS ARCOTTON KUDISAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8708",
            "district": "36",
            "Block": "105",
            "label": "PUMS NAICKANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8709",
            "district": "36",
            "Block": "105",
            "label": "GHS MUNJURPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8710",
            "district": "36",
            "Block": "105",
            "label": "PUMS MOTTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8711",
            "district": "36",
            "Block": "105",
            "label": "PUMS KILVALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8712",
            "district": "36",
            "Block": "105",
            "label": "KKS MANI MATRIC HSS ,VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8713",
            "district": "36",
            "Block": "105",
            "label": "GHSS KAMMAVANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8714",
            "district": "36",
            "Block": "105",
            "label": "GHS KAMMASAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8715",
            "district": "36",
            "Block": "12",
            "label": "PUMS  RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8716",
            "district": "36",
            "Block": "12",
            "label": "AGARAM MATRIC SCHOOL, PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8717",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS THIPPASAMUDHRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8718",
            "district": "36",
            "Block": "12",
            "label": "GOVT HSS VIRINCHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8719",
            "district": "36",
            "Block": "12",
            "label": "GHS PICHANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8720",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS DEVICHETTIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8721",
            "district": "36",
            "Block": "12",
            "label": "GOVT ADW HS MAGAMADHUPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8722",
            "district": "36",
            "Block": "12",
            "label": "PUMS BASAVANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8723",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS MELARASAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8724",
            "district": "36",
            "Block": "12",
            "label": "MDAPS VANNANTHANGAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8725",
            "district": "36",
            "Block": "12",
            "label": "PUMS KOTTAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8726",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS ATHIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8727",
            "district": "36",
            "Block": "12",
            "label": "GOVT BOYS HSS ODUGATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8728",
            "district": "36",
            "Block": "12",
            "label": "GOVT GIRLS HSS ODUGATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8729",
            "district": "36",
            "Block": "12",
            "label": "PUMS PINNATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8730",
            "district": "36",
            "Block": "12",
            "label": "PUMS KUMBALKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8731",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS KILKOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8732",
            "district": "36",
            "Block": "12",
            "label": "S.P.A.N.M.SCHOOL ERIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8733",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS SERPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8734",
            "district": "36",
            "Block": "12",
            "label": "PUMS KATHARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8735",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS PEENJAMANDAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8736",
            "district": "36",
            "Block": "12",
            "label": "PUMS JARTHANKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8737",
            "district": "36",
            "Block": "12",
            "label": "PUMS ERIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8738",
            "district": "36",
            "Block": "12",
            "label": "ANWARUL ISLAM AIDED MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8739",
            "district": "36",
            "Block": "12",
            "label": "ST. THERESAL HIGH SCHOOL  PALLIKONDA",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8740",
            "district": "36",
            "Block": "12",
            "label": "GOVT GIRLS HSS PALLIKONDA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8741",
            "district": "36",
            "Block": "12",
            "label": "GOVT BOYS HSS PALLIKONDA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8742",
            "district": "36",
            "Block": "12",
            "label": "PUMS KANTHANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8743",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS KAZHANIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8744",
            "district": "36",
            "Block": "12",
            "label": "ANURAT VIDYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8745",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS ERAIVANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8746",
            "district": "36",
            "Block": "12",
            "label": "PUMS SEDUVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8747",
            "district": "36",
            "Block": "12",
            "label": "ADW MS SEDHUVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8748",
            "district": "36",
            "Block": "12",
            "label": "PUMSCHOOL VELANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8749",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS MOOLAIGATE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8750",
            "district": "36",
            "Block": "12",
            "label": "GOVT GIRLS HSS ANAICUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8751",
            "district": "36",
            "Block": "12",
            "label": "GBHSS ANAICUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8752",
            "district": "36",
            "Block": "12",
            "label": "PUMS THARVAZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8753",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS ELAVAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8754",
            "district": "36",
            "Block": "12",
            "label": "GOVT BOYS HSS POIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8755",
            "district": "36",
            "Block": "12",
            "label": "GOVT GIRLS HSS POIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8756",
            "district": "36",
            "Block": "12",
            "label": "PUMS MADAYAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8757",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS JANGALAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8758",
            "district": "36",
            "Block": "113",
            "label": "PUMS  Jabrapet",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8759",
            "district": "36",
            "Block": "113",
            "label": "AHS ST.XAVIER'S CHRISTIANPET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8760",
            "district": "36",
            "Block": "113",
            "label": "PUMS Katpadi North",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8761",
            "district": "36",
            "Block": "113",
            "label": "PUMS Katpadi South",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8762",
            "district": "36",
            "Block": "113",
            "label": "GGHSS KATPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8763",
            "district": "36",
            "Block": "113",
            "label": "KALVI ULAGAM SIVA HS KILITHANPATTARAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8764",
            "district": "36",
            "Block": "113",
            "label": "Ams Ebenezar",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8765",
            "district": "36",
            "Block": "113",
            "label": "GBHSS KATPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8766",
            "district": "36",
            "Block": "113",
            "label": "PUMS Aruppumedu",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8767",
            "district": "36",
            "Block": "113",
            "label": "PUMS Kalinjur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8768",
            "district": "36",
            "Block": "113",
            "label": "PUMS Kalinjur Mottur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8769",
            "district": "36",
            "Block": "113",
            "label": "JAYABHARATH MATRIC SCHOOL, KALINJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8770",
            "district": "36",
            "Block": "113",
            "label": "BHAGWAN MAHAVEER DAYANIKETAN JAIN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8771",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS VANJUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8772",
            "district": "36",
            "Block": "113",
            "label": "PUMS T.K.Puram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8773",
            "district": "36",
            "Block": "113",
            "label": "Ams Union mission viruthambut",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8774",
            "district": "36",
            "Block": "113",
            "label": "TMKV GGHSS Kangeyanallur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8775",
            "district": "36",
            "Block": "113",
            "label": "TMKV GOVT BOYS HSS KANGEYANALLORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8776",
            "district": "36",
            "Block": "113",
            "label": "PUMS Ashok Nagar",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8777",
            "district": "36",
            "Block": "113",
            "label": "PUMS Old Katpadi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8778",
            "district": "36",
            "Block": "113",
            "label": "PUMS Pallikuppam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8779",
            "district": "36",
            "Block": "113",
            "label": "AUXILIUM HSS GANDHINAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8780",
            "district": "36",
            "Block": "113",
            "label": "DONBOSCO HSS GANDHINAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8781",
            "district": "36",
            "Block": "113",
            "label": "DEVALOIS HSS KASAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8782",
            "district": "36",
            "Block": "113",
            "label": "IDA SCUDDER SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8783",
            "district": "36",
            "Block": "113",
            "label": "KAVITHA MATRIC SCHOOL, KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8784",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS BRAMMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8785",
            "district": "36",
            "Block": "113",
            "label": "KEERTHIGA MATRIC HSS, THIRUPAKKUTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8786",
            "district": "36",
            "Block": "113",
            "label": "GHS Karikiri",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8787",
            "district": "36",
            "Block": "113",
            "label": "GHSS SERKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8788",
            "district": "36",
            "Block": "113",
            "label": "PUMS 66 Puthur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8789",
            "district": "36",
            "Block": "113",
            "label": "PUMS Sevoor",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8790",
            "district": "36",
            "Block": "113",
            "label": "GOVT HR.SEC. SCHOOL, KARNAMBUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8791",
            "district": "36",
            "Block": "113",
            "label": "GGHSS THIRUVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8792",
            "district": "36",
            "Block": "113",
            "label": "GBHSS THIRUVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8793",
            "district": "36",
            "Block": "84",
            "label": "GHSS , GUDIYATHAM RS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8794",
            "district": "36",
            "Block": "84",
            "label": "PUMS SETHUVANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8795",
            "district": "36",
            "Block": "84",
            "label": "SARASWATHI VIDHYALAYA MATRIC HSS , SETHUVANDAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8796",
            "district": "36",
            "Block": "84",
            "label": "GHS DEVARISHIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8797",
            "district": "36",
            "Block": "84",
            "label": "ADWMS NAGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8798",
            "district": "36",
            "Block": "84",
            "label": "PUMS HYDERPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8799",
            "district": "36",
            "Block": "84",
            "label": "GHS , PASUMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8800",
            "district": "36",
            "Block": "84",
            "label": "SGR GHSS KOSAVANPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8801",
            "district": "36",
            "Block": "84",
            "label": "PUMS MELKAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8802",
            "district": "36",
            "Block": "84",
            "label": "GHS , MACHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8803",
            "district": "36",
            "Block": "84",
            "label": "PUMS MOOLAKANGKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8804",
            "district": "36",
            "Block": "84",
            "label": "GHSS , PANAMADANGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8805",
            "district": "36",
            "Block": "84",
            "label": "PUMS PALLAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8806",
            "district": "36",
            "Block": "84",
            "label": "GHS , MELMOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8807",
            "district": "36",
            "Block": "84",
            "label": "SRI KALAIVANI VIDYALAYA MATRIC HSS, MELMAYIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8808",
            "district": "36",
            "Block": "84",
            "label": "PUMS  KILMUTTUKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8809",
            "district": "36",
            "Block": "84",
            "label": "GBHSS KVKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8810",
            "district": "36",
            "Block": "84",
            "label": "GGHSS , KVKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8811",
            "district": "36",
            "Block": "84",
            "label": "SAI GURUJI MATRIC HSS, K.V.KUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8812",
            "district": "36",
            "Block": "84",
            "label": "MKB MATRIC SCHOOL, ARJUNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8813",
            "district": "36",
            "Block": "84",
            "label": "GADWHSS PILLANTHIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8814",
            "district": "36",
            "Block": "84",
            "label": "GHSS, VADUGANTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8815",
            "district": "36",
            "Block": "84",
            "label": "PUMS VELAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8816",
            "district": "36",
            "Block": "84",
            "label": "GHSS SENJI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8817",
            "district": "36",
            "Block": "84",
            "label": "PUMS THONDANTHULASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8818",
            "district": "36",
            "Block": "84",
            "label": "KALVIULAGAM AHS , ARUMBAKKAM MOTTUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8819",
            "district": "36",
            "Block": "84",
            "label": "GGHSS , LATHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8820",
            "district": "36",
            "Block": "84",
            "label": "GBHSS ,LATTERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8821",
            "district": "36",
            "Block": "84",
            "label": "GHS , THIRUMANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8822",
            "district": "36",
            "Block": "74",
            "label": "GHS SEMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8823",
            "district": "36",
            "Block": "74",
            "label": "PUMS UPPARAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8824",
            "district": "36",
            "Block": "74",
            "label": "GHSS KOTTAMITTAH",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8825",
            "district": "36",
            "Block": "74",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOOL THATTAPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8826",
            "district": "36",
            "Block": "74",
            "label": "GHSS AGRAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8827",
            "district": "36",
            "Block": "74",
            "label": "GHSS ERTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8828",
            "district": "36",
            "Block": "74",
            "label": "GHSS VALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8829",
            "district": "36",
            "Block": "74",
            "label": "PUMS ULLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8830",
            "district": "36",
            "Block": "74",
            "label": "GHS SEMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8831",
            "district": "36",
            "Block": "74",
            "label": "ADWHS CHETTIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8832",
            "district": "36",
            "Block": "74",
            "label": "GOVERNMENT HIGH SCHOOL KALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8833",
            "district": "36",
            "Block": "74",
            "label": "GHSS GOODANAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8834",
            "district": "36",
            "Block": "74",
            "label": "PUMS SINGALPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8835",
            "district": "36",
            "Block": "74",
            "label": "GHSS KALLAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8836",
            "district": "36",
            "Block": "74",
            "label": "PUMS KANAVAI MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8837",
            "district": "36",
            "Block": "74",
            "label": "PUMS GUDLAVARIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8838",
            "district": "36",
            "Block": "74",
            "label": "GHS VEERICHETTIPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8839",
            "district": "36",
            "Block": "74",
            "label": "SRI RAMAKRISHNA GARDEN MATRIC SCHOOL, PARADARAMI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8840",
            "district": "36",
            "Block": "74",
            "label": "GBHSS PARADARAMI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8841",
            "district": "36",
            "Block": "74",
            "label": "GGHSS PARADARAMI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8842",
            "district": "36",
            "Block": "74",
            "label": "GURUJI MATRIC SCHOOL, PARADARAMI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8843",
            "district": "36",
            "Block": "74",
            "label": "GHS POOSARIVALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8844",
            "district": "36",
            "Block": "74",
            "label": "GOVT MPL HR SEC SCHOOL GUDIYATTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8845",
            "district": "36",
            "Block": "74",
            "label": "GGHSS NELLOREPET (G)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8846",
            "district": "36",
            "Block": "74",
            "label": "JOTHI HR. SEC. SCHOOL, GUDIYATHAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8847",
            "district": "36",
            "Block": "74",
            "label": "AHSS VALLALAR GUDIYATHAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8848",
            "district": "36",
            "Block": "74",
            "label": "AHSS NATIONAL GUDIYATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8849",
            "district": "36",
            "Block": "74",
            "label": "AHSS THIRUVALLUVAR GUDIYATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8850",
            "district": "36",
            "Block": "74",
            "label": "CRESCENT MATRIC SCHOOL, SEDUKKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8851",
            "district": "36",
            "Block": "74",
            "label": "A. J.  SARASWATHI MATRIC VIDHYALAYA SCHOOL , NELLOREPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8852",
            "district": "36",
            "Block": "74",
            "label": "GOVT.BOYS HR.SEC.SCHOOL, NELLOREPET,GUDIYATHAM,VELLORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8853",
            "district": "36",
            "Block": "74",
            "label": "MADRASA - E - SALEEMA NISWAN, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8854",
            "district": "36",
            "Block": "74",
            "label": "ADWHS OLAKKASI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8855",
            "district": "36",
            "Block": "243",
            "label": "GHS ARAVATLA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8856",
            "district": "36",
            "Block": "243",
            "label": "GHS , PASMARPENTA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8857",
            "district": "36",
            "Block": "243",
            "label": "GHS ERUKKAMBUT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8858",
            "district": "36",
            "Block": "243",
            "label": "GHS MACHAMBUT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8859",
            "district": "36",
            "Block": "243",
            "label": "GHS BALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8860",
            "district": "36",
            "Block": "243",
            "label": "SRI KRISHNA MATRIC SCHOOL, CHINNA DHAMAL CHERUVU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8861",
            "district": "36",
            "Block": "243",
            "label": "GHS PALLALAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8862",
            "district": "36",
            "Block": "243",
            "label": "GHS SATHKAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8863",
            "district": "36",
            "Block": "243",
            "label": "PUMS PERNAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8864",
            "district": "36",
            "Block": "243",
            "label": "ISLAMIAH HSS PERNAMBUT",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8866",
            "district": "36",
            "Block": "243",
            "label": "GGHSS PERNAMBUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8867",
            "district": "36",
            "Block": "243",
            "label": "GADWHSS PERNAMBUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8868",
            "district": "36",
            "Block": "243",
            "label": "RAMIZA ORIENTAL GHS PERNAMBUT",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8869",
            "district": "36",
            "Block": "243",
            "label": "NUSRATHUL ISLAM GHSS, PERNAMBUT",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8870",
            "district": "36",
            "Block": "243",
            "label": "St. Paul's Matric Higher Secondary School, Pernambut",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8871",
            "district": "36",
            "Block": "243",
            "label": "BHARATHI VIDYALAYA NATIONAL INSTITUTE OF OPEN SCHOOLING",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "8872",
            "district": "36",
            "Block": "243",
            "label": "GADWHSS TT MOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8873",
            "district": "36",
            "Block": "243",
            "label": "PUMS CHINTHAKANAVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8874",
            "district": "36",
            "Block": "74",
            "label": "PUMS MORTHANA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8875",
            "district": "36",
            "Block": "243",
            "label": "PUMS REDDYMANGKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8876",
            "district": "36",
            "Block": "243",
            "label": "GHSS ALINJIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8877",
            "district": "36",
            "Block": "243",
            "label": "PUMS MV KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8878",
            "district": "36",
            "Block": "243",
            "label": "SRI VIVEKANANDA VIDHAYALAYA MATRIC HSS , MAILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8879",
            "district": "36",
            "Block": "243",
            "label": "GHSS MAILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8880",
            "district": "36",
            "Block": "243",
            "label": "IELC AIDED MIDDLE SCHOOL MAILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8899",
            "district": "36",
            "Block": "243",
            "label": "GHS KOKKALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8900",
            "district": "36",
            "Block": "243",
            "label": "GHS. ERIGUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8901",
            "district": "36",
            "Block": "243",
            "label": "GHS BATHALAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8902",
            "district": "36",
            "Block": "12",
            "label": "GHS AGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8903",
            "district": "36",
            "Block": "12",
            "label": "GHSS CHINNAPALLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8904",
            "district": "36",
            "Block": "12",
            "label": "GHS MELPALLIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8905",
            "district": "36",
            "Block": "12",
            "label": "GHS ASANAMBUT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8906",
            "district": "36",
            "Block": "12",
            "label": "GHS,GURUVARAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8907",
            "district": "36",
            "Block": "12",
            "label": "ADWMS,VEPANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8915",
            "district": "36",
            "Block": "74",
            "label": "PUMS,VADAKATHIPATI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8916",
            "district": "36",
            "Block": "74",
            "label": "GHS AGARAMCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8917",
            "district": "36",
            "Block": "74",
            "label": "ST.JOHN BLESSO MAT SCHOOL AGARAMCHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8918",
            "district": "36",
            "Block": "74",
            "label": "GHS GOLLAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8919",
            "district": "36",
            "Block": "74",
            "label": "GHS PALLIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20888",
            "district": "36",
            "Block": "12",
            "label": "SRI SAI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20945",
            "district": "36",
            "Block": "74",
            "label": "LALETHA GOVINDHAN MATRIC SCHOOL, ULLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20950",
            "district": "36",
            "Block": "394",
            "label": "IQRA MATRIC SCHOOL, R.N PALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21056",
            "district": "36",
            "Block": "84",
            "label": "BRAMMAAS VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21057",
            "district": "36",
            "Block": "84",
            "label": "VIDHYALAKSHMI  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21239",
            "district": "36",
            "Block": "74",
            "label": "Aadithya Vidhyashram Matric School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21257",
            "district": "36",
            "Block": "12",
            "label": "BHARATH MATRIC HSS, ODUGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21258",
            "district": "36",
            "Block": "12",
            "label": "SRI VIVEKANANDA MATRIC HSS, ANAICUT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21431",
            "district": "36",
            "Block": "393",
            "label": "GHS,  PALAMATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21432",
            "district": "36",
            "Block": "105",
            "label": "GGHS KILARASAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21433",
            "district": "36",
            "Block": "74",
            "label": "GHS GANDHI NAGAR, GUDIYATTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21493",
            "district": "36",
            "Block": "74",
            "label": "ADW HS R.VENKATAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21681",
            "district": "36",
            "Block": "393",
            "label": "SRI NARAYANI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21682",
            "district": "36",
            "Block": "393",
            "label": "SPARK CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21768",
            "district": "36",
            "Block": "243",
            "label": "AHS CONCORDIA, PERNAMBUT",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "21773",
            "district": "36",
            "Block": "113",
            "label": "WISDOM MATRIC HSS, RAGHUPATHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21774",
            "district": "36",
            "Block": "113",
            "label": "KINGSTON MATRIC HSS, KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21775",
            "district": "36",
            "Block": "113",
            "label": "VANI VIDHAYALAYA  SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21781",
            "district": "36",
            "Block": "84",
            "label": "MOUNT LITERA ZEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21782",
            "district": "36",
            "Block": "74",
            "label": "ASHIRWAD'S INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21785",
            "district": "36",
            "Block": "12",
            "label": "THE NAMAKKAL TEACHERS PARK MHSS, SATHYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21927",
            "district": "36",
            "Block": "12",
            "label": "SHIKSHA GREENWOODS ICSE SCHOOL, PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21928",
            "district": "36",
            "Block": "12",
            "label": "KVS NURSERY & PRIMARY SCHOOL PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21929",
            "district": "36",
            "Block": "84",
            "label": "VISION VIDYASHRAM WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21931",
            "district": "36",
            "Block": "394",
            "label": "LUTHERAN SCHOOL HOME FOR THE DEAF & BLIND",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21932",
            "district": "36",
            "Block": "394",
            "label": "GUILD OF SERVICE SCHOOL FOR THE BLIND",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21965",
            "district": "36",
            "Block": "12",
            "label": "THE NAMAKKAL TEACHERS VIDHYASHRAM MATRIC HSS, KAZHANIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22063",
            "district": "36",
            "Block": "113",
            "label": "KINGSTON INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22064",
            "district": "36",
            "Block": "84",
            "label": "ADWGHS AMMANAGKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22065",
            "district": "36",
            "Block": "84",
            "label": "Arya Vidhyasshram International Residential School ( Senior Secondary )",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22066",
            "district": "36",
            "Block": "243",
            "label": "ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL, PALLALAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22067",
            "district": "36",
            "Block": "243",
            "label": "ASH - SHAMS N&P SCHOOL, PERNAMBUT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22068",
            "district": "36",
            "Block": "243",
            "label": "GREEN VALLEY N&P SCHOOL  SATHKAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22380",
            "district": "36",
            "Block": "113",
            "label": "SRI SRINIVASA VIDHYASHARAM CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22385",
            "district": "36",
            "Block": "393",
            "label": "VELAMMAL BODHI CAMPUS ( CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22386",
            "district": "36",
            "Block": "394",
            "label": "GOVT MPL MUSLIM HIGH SCHOOL, R.N.PALAYAM. VELLORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22387",
            "district": "36",
            "Block": "105",
            "label": "DREAMS MATRICULATION SCHOOL, KANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22388",
            "district": "36",
            "Block": "12",
            "label": "SPRING DAYS CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22389",
            "district": "36",
            "Block": "84",
            "label": "GHS  PALLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22390",
            "district": "36",
            "Block": "74",
            "label": "GOVT HIGH SCHOOL KALIYAMMANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22391",
            "district": "36",
            "Block": "243",
            "label": "GHS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22735",
            "district": "36",
            "Block": "12",
            "label": "SRI SANKARA MATRIC HR. SEC. SCHOOL, ODUGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22824",
            "district": "36",
            "Block": "113",
            "label": "PINCUSHION MONTESSORI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22835",
            "district": "36",
            "Block": "113",
            "label": "JEYAMALLI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23043",
            "district": "36",
            "Block": "394",
            "label": "THE TAKSHILAH GLOBAL SCHOOL VELLORE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23144",
            "district": "36",
            "Block": "74",
            "label": "G.E.T PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23200",
            "district": "36",
            "Block": "113",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23256",
            "district": "36",
            "Block": "243",
            "label": "VAANI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23314",
            "district": "36",
            "Block": "113",
            "label": "FIITJEE GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23629",
            "district": "36",
            "Block": "74",
            "label": "EDIFY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23630",
            "district": "36",
            "Block": "84",
            "label": "SREE ABIRAAMI (CBSE ) SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23631",
            "district": "36",
            "Block": "105",
            "label": "MAHARISHI VIDYA MANDIR-NELVOY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23632",
            "district": "36",
            "Block": "113",
            "label": "PRINCE PARK MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23633",
            "district": "36",
            "Block": "113",
            "label": "SHRI PONMATHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23634",
            "district": "36",
            "Block": "393",
            "label": "VELLORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23716",
            "district": "36",
            "Block": "12",
            "label": "Oxford Matriculation School, Odugathur",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23786",
            "district": "36",
            "Block": "74",
            "label": "GHS SEMBEDU",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23787",
            "district": "36",
            "Block": "74",
            "label": "PUMS NATHAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23788",
            "district": "36",
            "Block": "74",
            "label": "ARUMBUGAL ",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23795",
            "district": "36",
            "Block": "84",
            "label": "SRI LAKSHMI VIDYALAYA NURSERY AND PRIMARY SCHOOL MELMOIL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23796",
            "district": "36",
            "Block": "84",
            "label": "SARADAMBAL SRINIVASA MUDALIAR MEMORIAL PADASALA (CBSE)",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23810",
            "district": "36",
            "Block": "105",
            "label": "MERIDIAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23816",
            "district": "36",
            "Block": "113",
            "label": "PUMS Sevoor",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23817",
            "district": "36",
            "Block": "113",
            "label": "KV Special Schools",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23818",
            "district": "36",
            "Block": "113",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24065",
            "district": "36",
            "Block": "393",
            "label": "PUMS ABDULLAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24066",
            "district": "36",
            "Block": "393",
            "label": "VELLORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "37": [
        {
            "key": "379",
            "district": "37",
            "Block": "70",
            "label": "ST.MICHAEL'S MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23650",
            "district": "37",
            "Block": "214",
            "label": "Wisdom Nursery and Primary school",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "23652",
            "district": "37",
            "Block": "404",
            "label": "Railway Mixed High School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "1557",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELLAIYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1687",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1870",
            "district": "37",
            "Block": "100",
            "label": "SRI KRISHNA MATRIC HIGHER SECONDARY SCHOOL PANAMALAI MADHURA, METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2341",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2406",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANUMANDHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3345",
            "district": "37",
            "Block": "100",
            "label": "MAHATMA GANDHI MATRICULATION SCHOOL KANJANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3707",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  T.KOSAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4079",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KANGIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4429",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  S. KOLLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4841",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ODIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4920",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAKARAITHAZHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4943",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANTHILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5083",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5102",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOVILPURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5279",
            "district": "37",
            "Block": "404",
            "label": "RAMAKRISHNA MISSION VIDYALAYA MATRIC HR SEC SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5312",
            "district": "37",
            "Block": "404",
            "label": "THE CLARIBEL MATRIC SCHOOL MAHARAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5320",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAIRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5339",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SITHATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5381",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VASANTHAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5385",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PONPATTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5503",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENKALAVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5564",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THULANGAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5596",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARUNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5701",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENMANIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5731",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SATHIYAKANDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5740",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENGAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5771",
            "district": "37",
            "Block": "214",
            "label": "THAI TAMIL MIDDLE SCHOOL ROSANI TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5797",
            "district": "37",
            "Block": "214",
            "label": "BHARATHI AIDED MIDDLE SCHOOL PANCHALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5831",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KADALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5889",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5966",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VIZHUKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6112",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELMALAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6170",
            "district": "37",
            "Block": "214",
            "label": "SRI KAMATCHI VILAS MIDDLE SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6188",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EDAYANCHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6221",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ARIYALUR THIRUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6247",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUYAVANKADUVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6298",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SALAVATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6303",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EAPPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6337",
            "district": "37",
            "Block": "404",
            "label": "SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL VILUPPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6350",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KURINJIPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6398",
            "district": "37",
            "Block": "189",
            "label": "ST.JOHN BOSCO MATRICULATION SCHOOL KANDACHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6412",
            "district": "37",
            "Block": "404",
            "label": "SRI NATARAJA MIDDLE SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6415",
            "district": "37",
            "Block": "404",
            "label": "SACRED HEART CONVENT ANGLO INDIAN HIGHER SECONDARY SCHOOL  VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6481",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6522",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6528",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EYAKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6535",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AVIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6592",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THORAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6620",
            "district": "37",
            "Block": "404",
            "label": "JOHN DEWEY MATRIC HIGHER SECONDARY SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6721",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10007",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10008",
            "district": "37",
            "Block": "181",
            "label": "RAJA DESINGH MATRIC HIGH  SCHOOL  AVALURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10009",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(G) AVALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10010",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(B) AVALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10011",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   AATHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10012",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDRY SCHOOL DEVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10013",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARADIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10014",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ETHAPPATU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10015",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL EYYIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10016",
            "district": "37",
            "Block": "181",
            "label": "SRI PARAMESWAR M HIGH SCHOOL GENGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10017",
            "district": "37",
            "Block": "181",
            "label": "AIDED MIDDLE SCHOOL  KUDUVAMPOONDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10018",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KALATHAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10019",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL KAPLAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10020",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VALATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10021",
            "district": "37",
            "Block": "181",
            "label": "OUTREACH MATRIC  HIGH SCHOOL VALATHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10022",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KODUKANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10023",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  KOTTAPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10024",
            "district": "37",
            "Block": "181",
            "label": "C.S.I BISHOP SAM PONNIAH  HIGH SCHOOL GNANOTHAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10025",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MANANTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10026",
            "district": "37",
            "Block": "181",
            "label": "GANGA VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, MELMALAYANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10027",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MELMALAYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10028",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10029",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  MELSAVALAMPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10030",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  THEPPIRAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10031",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  METTUVAILAMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10032",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  NARANAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10033",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  NOCHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10034",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  PERIYANOLAMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10035",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VADUKAPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10036",
            "district": "37",
            "Block": "181",
            "label": "SARADHA VIDYALAYA MATRICULATION SCHOOL.VADUGANPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10037",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PERUVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10038",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  PERUVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10039",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  SATHAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10040",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  SEVALAPURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24135", "district":"37", 
            "Block":"404", "label": "Achariya Siksha Mandir", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "10041",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SIRUTHALAIPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10042",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THAZHANGUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10043",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THENPALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10044",
            "district": "37",
            "Block": "181",
            "label": "SACRED HEART HIGH SCHOOL  THURINCHIPOONDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10045",
            "district": "37",
            "Block": "70",
            "label": "IMMACULATE HIGH SCHOOL VELANTHANGAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10046",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  MELPAPPAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10047",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10048",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10049",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTUCHITHAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10050",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  NALLAN PILLAI PETRAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10051",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  THADAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10052",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAZHAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10053",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SO.KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10054",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10055",
            "district": "37",
            "Block": "70",
            "label": "SRI RAMAKRISHNA MIDDLE SCHOOL  SATHIYAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10056",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  SATHIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10057",
            "district": "37",
            "Block": "70",
            "label": "RAJA DESINGH VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL  SATHIYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10058",
            "district": "37",
            "Block": "70",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL (UNAIDED) SATHIYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10059",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  ALAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10060",
            "district": "37",
            "Block": "70",
            "label": "ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL ALAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10061",
            "district": "37",
            "Block": "70",
            "label": "ST. AUGUSTINE HIGH SCHOOL  THANGALKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10062",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ODHIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10063",
            "district": "37",
            "Block": "70",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL MANALAPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10064",
            "district": "37",
            "Block": "70",
            "label": "AIDED NON MINORITY MIDDLE SCHOOL R.NAYAMPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10065",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  DEVADHANAMPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10066",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  KONAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10067",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10068",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THACHAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10069",
            "district": "37",
            "Block": "70",
            "label": "ST.ANTONY'S HIGH SCHOOL  ANAIYERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10070",
            "district": "37",
            "Block": "70",
            "label": "LOURDU ANNAI HIGHER SECONDARY SCHOOL KANAKANKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10071",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  KANAKKANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10072",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  MALAIYARASANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10073",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL MAZHAVANTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10074",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  THUTHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10075",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL (G) ANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10076",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  ANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10077",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KONALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10078",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL OTTAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10079",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  KARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10080",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SITTAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10081",
            "district": "37",
            "Block": "70",
            "label": "SARVA SEVA VIDHYALAYA MATRIC HS SITTAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10082",
            "district": "37",
            "Block": "70",
            "label": "ADIDRAVIDA WELFARE HIGHER SECONDARY SCHOOL KAVARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10083",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL JAYANKONDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10084",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MEENAMBUR (B) URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10085",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10086",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENPUDUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10087",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GINGEE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10088",
            "district": "37",
            "Block": "70",
            "label": "RAJA DESINGH GOVERNMENT BOYS HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10089",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL (G) GINGEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10090",
            "district": "37",
            "Block": "70",
            "label": "AL-HILAL MATRIC HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10091",
            "district": "37",
            "Block": "70",
            "label": "SARADHA MATRIC HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10092",
            "district": "37",
            "Block": "70",
            "label": "CHANAKYA MATRICULATION HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10093",
            "district": "37",
            "Block": "70",
            "label": "ST.MICHAEL'S HIGHER SECONDARY SCHOOL  KRISHNAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10094",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SAKKARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10095",
            "district": "37",
            "Block": "70",
            "label": "KALAIVANI  MATRICULATION HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10096",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT MUSLIM URDU HIGH SCHOOL, GINGEE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10097",
            "district": "37",
            "Block": "70",
            "label": "SHVETA  PADMASANI MATRIC HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10098",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10099",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THALAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10100",
            "district": "37",
            "Block": "384",
            "label": "SACRED HEART HIGHER SECONDARY SCHOOL ANILADY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10101",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KEELMAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10102",
            "district": "37",
            "Block": "384",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL SORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10103",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10104",
            "district": "37",
            "Block": "384",
            "label": "CARE COUNCIL HIGH SCHOOL , PANDIYANKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10105",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL MODAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10106",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAJAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10107",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  UDAIYANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10108",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL KALLAPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10109",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELKALAVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10110",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NEGANUR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10111",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NEGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10112",
            "district": "37",
            "Block": "384",
            "label": "SRI RAAM MATRIC HIGHER SECONDARY SCHOOL NANGILIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10113",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10114",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ILLODU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10115",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL PENNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10116",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARIYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10117",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MELOLAKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10118",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEERANAMUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10119",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EDANEMLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10120",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELATHIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10121",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARUGAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10122",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PALLIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10123",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MELSITHAMUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10124",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL VALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10125",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL KONGARAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10126",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL MELSEVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10127",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THALAVALAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10128",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VELLIMEDUPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10129",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADASIRUVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10130",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEEZHMAVILANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10131",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL DHADHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10132",
            "district": "37",
            "Block": "214",
            "label": "THIRUVALLUVAR HIGHER SECONDARY SCHOOL  KARUVAMBAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10133",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL URAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10134",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10135",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL OLAKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10136",
            "district": "37",
            "Block": "214",
            "label": "SRI THANGAM MATRICULATION SCHOOL. OLAKKUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10137",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KONERIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10138",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEEZHADHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10139",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10140",
            "district": "37",
            "Block": "214",
            "label": "ST.FRANCIS MATRIC HIGHER SECONDARY SCHOOL,ASSISI NAGAR,SARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10141",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NOLAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10142",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEEZHSEVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10143",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEEZHPOOTHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10144",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGH SCHOOL ATCHIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10145",
            "district": "37",
            "Block": "214",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL  PANGOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10146",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ONGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10147",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADAVAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10148",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL AVANIPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10149",
            "district": "37",
            "Block": "214",
            "label": "ST.ANN'S Hr SEC SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10150",
            "district": "37",
            "Block": "214",
            "label": "MONTFORT MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10151",
            "district": "37",
            "Block": "214",
            "label": "TAGORE MATRICULATION HIGHER SECONDARY SCHOOL JAYAPURAM,TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10152",
            "district": "37",
            "Block": "214",
            "label": "ST.JOSEPH OF CLUNY MATRIC HR.SEC.SCHOOL, TINDIVANAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10153",
            "district": "37",
            "Block": "214",
            "label": "ST. PHILOMENA'S GIRLS HIGHER SECONDARY SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10154",
            "district": "37",
            "Block": "214",
            "label": "MUNICIPAL HIGHER SECONDARY SCHOOL KAVERIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10155",
            "district": "37",
            "Block": "214",
            "label": "ST.ANN'S MIDDLE SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10156",
            "district": "37",
            "Block": "214",
            "label": "TAGORE HIGH SCHOOL (CBSE), TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10157",
            "district": "37",
            "Block": "214",
            "label": "MADRAS DIOCESE CRANE MIDDLE SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10158",
            "district": "37",
            "Block": "214",
            "label": "NATIONAL HR SEC SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10159",
            "district": "37",
            "Block": "214",
            "label": "COOSHALCHAND HR SEC SCHOOL, TINDIVANAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10160",
            "district": "37",
            "Block": "214",
            "label": "SRI KANNIGAPARAMESWARI GIRLS HIGH SCHOOL TINDIVANAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10161",
            "district": "37",
            "Block": "214",
            "label": "MUSLIM MIDDLE SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10162",
            "district": "37",
            "Block": "214",
            "label": "SRI MARAGADAMBIGAI HIGH SCHOOL, TINDIVANAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10163",
            "district": "37",
            "Block": "214",
            "label": "CHANAKYA MATRIC HIGHER SECONDARY SCHOOL, PONDY ROAD, TINDIVANAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10164",
            "district": "37",
            "Block": "214",
            "label": "SOLAR MATRICULATION HIGHER SECONDARY SCHOOL.TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10165",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  MURUNGAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10166",
            "district": "37",
            "Block": "214",
            "label": "WALTER SCUDDER HIGHER SECONDARY SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10167",
            "district": "37",
            "Block": "214",
            "label": "SREE NAGAMMAL MIDDLE SCHOOL , TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10168",
            "district": "37",
            "Block": "214",
            "label": "MARY'S MATRICULATION HIGHER SECONDARY SCHOOL TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10169",
            "district": "37",
            "Block": "214",
            "label": "SAINT FRANCIS D' ASSISI MATRICULATION HIGHER SECONDARY SCHOOL TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10170",
            "district": "37",
            "Block": "214",
            "label": "VALARMATHI KARUPAIYA MATRIC SCHOOL, TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10171",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL AGOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10172",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL AVVAIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10173",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ALAGRAMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10174",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  ASOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10175",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL RETTANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10176",
            "district": "37",
            "Block": "164",
            "label": "KENNEDY MATRIC HIGHER SECONDARY SCHOOL RETTANAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10177",
            "district": "37",
            "Block": "164",
            "label": "HOLY ANGEL MATRIC HIGHER SECONDARY SCHOOL  RETTANAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10178",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10179",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MAILAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10180",
            "district": "37",
            "Block": "164",
            "label": "SRI SIVAPRAKASA SWAMIGAL HIGHER SECONDARY SCHOOL MAILAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10181",
            "district": "37",
            "Block": "164",
            "label": "NEHRU MATRIC HIGHER SECONDARY SCHOOL MAILAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10182",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10183",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  CHENDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10184",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THAZHUTHALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10185",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  DEEVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10186",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PATHIRAPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10187",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NEDIMOZHIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10188",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERIYATHACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10189",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEEDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10190",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10191",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  KOLLAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10192",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10193",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GANAPATHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10194",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  KATTUSIVIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10195",
            "district": "37",
            "Block": "164",
            "label": "ST.JOHN'S MATRIC HIGHER SECONDARY SCHOOL CHINNANERKUNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10196",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  KOOTERIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10197",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  KOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10198",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NADUVANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10199",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  NEDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10200",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  MELPERADIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10201",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  PULIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10202",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10203",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SITHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10204",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  CHINNANERKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10205",
            "district": "37",
            "Block": "164",
            "label": "AIDED MIDDLE SCHOOL KALLAKOLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10206",
            "district": "37",
            "Block": "164",
            "label": "SRI RAJARAJESWARI MATRIC HIGH SCHOOL,PELAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10207",
            "district": "37",
            "Block": "164",
            "label": "AIDED MIDDLE SCHOOL MUPPULI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10208",
            "district": "37",
            "Block": "175",
            "label": "ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL SINGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10209",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENPAZAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10210",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL OMANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10211",
            "district": "37",
            "Block": "175",
            "label": "VKM VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10212",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ANNAM PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10213",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10214",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL, KEEL EDAYALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10215",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOLASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10216",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL KOVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10217",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENNERGUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10218",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ENDIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10219",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL VITTALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10220",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUMUKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10221",
            "district": "37",
            "Block": "175",
            "label": "GANDHIJI HIGH SCHOOL KIZHSIVIRI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10222",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T.NALLALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10223",
            "district": "37",
            "Block": "175",
            "label": "GOVERMENT HIGHER SECONDARY SCHOOL  BRAMMADESAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10224",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VANNIPPER",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10225",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VADANERKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10226",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10227",
            "district": "37",
            "Block": "175",
            "label": "SUBHASHREE VIDHYALAYA MATRICULATION SCHOOL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10228",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL ALANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10229",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEPPERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10230",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL OMIPPER",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10231",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10232",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL KANDADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10233",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EKKIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10234",
            "district": "37",
            "Block": "175",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL KAZHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10235",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MARAKKANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10236",
            "district": "37",
            "Block": "175",
            "label": "SRI RAMAKRISHNA MATRIC HIGHER SECONDARY SCHOOL MARAKKANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10237",
            "district": "37",
            "Block": "175",
            "label": "JMJ MATRIC HIGHER SECONDARY SCHOOL MARAKKANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10238",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT (GIRLS) HIGH SCHOOL MARAKKANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10239",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL KAIPANIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10240",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10241",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANICHAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10242",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ANUMANDAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10243",
            "district": "37",
            "Block": "175",
            "label": "BHARATHIYAR MATRIC HR. SEC SCHOOL,  MARAKKANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10244",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL M.PUDUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10245",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10246",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NADUKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10247",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KOONIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10248",
            "district": "37",
            "Block": "175",
            "label": "ALM MATRICULATION HIGHER SECONDARY SCHOOL KOONIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10249",
            "district": "37",
            "Block": "175",
            "label": "AL- RASHEED MATRICULATION SCHOOL KOONIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10250",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL MUDALIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10251",
            "district": "37",
            "Block": "175",
            "label": "SAMINATHA VIDHYALAYA HIGH SCHOOL KEEZHPUTHUPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10252",
            "district": "37",
            "Block": "175",
            "label": "RYAN MATRICULATION SCHOOL NAMBIKKAINALLUR KILPUTHUPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10253",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL THENKODIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10254",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL ULAGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10255",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KILAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10256",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARATTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10257",
            "district": "37",
            "Block": "379",
            "label": "AIDED MIDDLE SCHOOL UPPUVELUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10258",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PUDUKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10259",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T. PARANGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10260",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NALLAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10261",
            "district": "37",
            "Block": "379",
            "label": "ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL KONDAMUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10262",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARUVAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10263",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL THENSIRUVALORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10264",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ADHANAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10265",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL THERKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10266",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KILIYANUR (C)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10267",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KILIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10268",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KONJIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10269",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KAZHUPERUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10270",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOZHUVARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10271",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NESAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10272",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KODUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10273",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THAILAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10274",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL KUNNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10275",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUMPAKKAM (C)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10276",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10277",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KARASANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10278",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL SEMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10279",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10280",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL KATRAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10281",
            "district": "37",
            "Block": "379",
            "label": "ARUNACHALAM AIDED MIDDLE SCHOOL OZHUNDIYAPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10282",
            "district": "37",
            "Block": "379",
            "label": "ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL  PULICHAPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10283",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10284",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL V.PARANGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10285",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIRUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10286",
            "district": "37",
            "Block": "379",
            "label": "ELANGO AMS POMBOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10287",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL THIRUVAKKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10288",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENGAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10289",
            "district": "37",
            "Block": "379",
            "label": "SRI ARAVINDAR VIDHYALAYA  MATRICULATION HIGH SCHOOL  AGASAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10290",
            "district": "37",
            "Block": "379",
            "label": "DMI ST. JOSEPH  MATRICULATION SCHOOL ACHARAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10291",
            "district": "37",
            "Block": "379",
            "label": "UDAVI GENTILLESSE MATRIC HIGHER SECONDARY  SCHOOL,EDAYANCHAVADY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10292",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL BOMMIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10293",
            "district": "37",
            "Block": "379",
            "label": "AMS THIRUCHITRAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10294",
            "district": "37",
            "Block": "379",
            "label": "GANDHI AIDED HSS THIRUCHITRAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10295",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL POOTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10296",
            "district": "37",
            "Block": "379",
            "label": "SWAMI VIVEKANANDHA MIDDLE SCHOOL, KADAPERIKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10297",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERAMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10298",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERIYAMUDALIYAR CHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10299",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL   KOTTAKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10300",
            "district": "37",
            "Block": "379",
            "label": "VETHA HIGH SCHOOL CHINNA KOTTAKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10301",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL KEEZHKOOTHAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10302",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL NALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10303",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT MIDDLE SCHOOL UDAYANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10304",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ATHIYURTHIRUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10305",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  KARUVATCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10306",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL PUDUKARUVATCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10307",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PANAMALAI PETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10308",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL PANAMALAI PETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10309",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SANGEETHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10310",
            "district": "37",
            "Block": "100",
            "label": "ST.MARY'S HIGH SCHOOL  NANGATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10311",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL KALYANAMPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10312",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ANNIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10313",
            "district": "37",
            "Block": "100",
            "label": "OLIVE TREE MATRICULATION HIGHER SECONDARY SCHOOL, ANNIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10314",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  ANNIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10315",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELLERIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10316",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL EZHUSEMPON",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10317",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL MELKARANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10318",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL SE.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10319",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL  KANJANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10320",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL SIRUVALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10321",
            "district": "37",
            "Block": "100",
            "label": "REDWINGS MATRICULATION SCHOOL  KEDAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10322",
            "district": "37",
            "Block": "100",
            "label": "ST. LITTLE FLOWER HIGH SCHOOL KAKKANUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10323",
            "district": "37",
            "Block": "100",
            "label": "SRI VIJAYALAKSHMI MATRICULATION SCHOOL ,KAKKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10324",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOZHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10325",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL  PALLIYANTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10326",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(BOYS)  KEDAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10327",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL (GIRLS) KEDAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10328",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MAMBALAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10329",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL KALPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10330",
            "district": "37",
            "Block": "100",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL THELI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10331",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10332",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  AGARAM SITHAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10333",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL  VENGANTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10334",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL SANIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10335",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL  PERUMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10336",
            "district": "37",
            "Block": "100",
            "label": "SFS MAT.SCHOOL PERUMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10337",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KONUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10338",
            "district": "37",
            "Block": "100",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL  KARUNGALIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10339",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ENNAIYIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10340",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MUTTATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10341",
            "district": "37",
            "Block": "401",
            "label": "WYCKOFF HIGHER SECONDARY SCHOOL MUTTATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10342",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL E-MANDAGAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10343",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ECHANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10344",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENTHIGH SCHOOL ESALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10345",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THENPAIR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10346",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10347",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL CHINNATHACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10348",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUTHAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10349",
            "district": "37",
            "Block": "401",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL V SALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10350",
            "district": "37",
            "Block": "401",
            "label": "ST. PATRICK'S  ACADEMY MATRICULATION SCHOOL V. SALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10351",
            "district": "37",
            "Block": "401",
            "label": "GHS MELAKONDAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10352",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL ASOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10353",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PONNANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10354",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEMBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10355",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ULAGALAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10356",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THUMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10357",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOTTIYAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10358",
            "district": "37",
            "Block": "401",
            "label": "JAI RAMAKRISHNAA VIDYALAYA MATRICULATION SCHOOL ,V.SATHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10359",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL V.SATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10360",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VIKARAVANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10361",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL (GIRLS) VIKARAVANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10362",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  VIKARAVANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10363",
            "district": "37",
            "Block": "401",
            "label": "ST MARY'S MATRIC SCHOOL VIKARAVANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10364",
            "district": "37",
            "Block": "401",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL  AVADAIYARPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10365",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THORAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10366",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10367",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAPPANAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10368",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10369",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL CHINTHAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10370",
            "district": "37",
            "Block": "401",
            "label": "NAAHAR PUBLIC SCHOOL AYYUR AGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10371",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL MUNDIYAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10372",
            "district": "37",
            "Block": "401",
            "label": "RAJSHREE SUGARS RAMAKRISHNA VID MATRIC HIGHER SECONDARY SCHOOL , MUNDIYAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10373",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PANAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10374",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL RADHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10375",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MADURAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10376",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAPPIYAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10377",
            "district": "37",
            "Block": "401",
            "label": "SIGA  HIGHER SECONDARY SCHOOL KAPPIYAMPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10378",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10379",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAGANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10380",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PAGANDAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10381",
            "district": "37",
            "Block": "401",
            "label": "AIDED MIDDLE SCHOOL SINGARAPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10382",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL MUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10383",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL ARASAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10384",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL ATHIYUR THIRUVATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10385",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL ASARANGKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10386",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10387",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHOLAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10388",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10389",
            "district": "37",
            "Block": "130",
            "label": "ADI DRAVIDA WELFARE HIGH SCHOOL  KAPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10390",
            "district": "37",
            "Block": "130",
            "label": "BONNE NEHRU MATRICULATION SCHOOL,KANDAMANADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10391",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  KANDAMANADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10392",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL KALLAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10393",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL MARAGATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10394",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10395",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAVANIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10396",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VALAVANUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10397",
            "district": "37",
            "Block": "130",
            "label": "GOVINDIER AIDED MIDDLE SCHOOL VALAVANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10398",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  VALAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10399",
            "district": "37",
            "Block": "130",
            "label": "S.R.GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ,VALAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10400",
            "district": "37",
            "Block": "130",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION SCHOOL VALAVANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10401",
            "district": "37",
            "Block": "130",
            "label": "SHREEM MATRICULATION SCHOOL VALAVANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10402",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL KONDANGI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10403",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOLIYANUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10404",
            "district": "37",
            "Block": "130",
            "label": "CONIFER MATRICULATION SCHOOL KOLIYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10405",
            "district": "37",
            "Block": "130",
            "label": "SRI SARASWATHI VIDHYALA MATRICULATION SCHOOL KOLIYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10406",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANICHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10407",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KOLIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10408",
            "district": "37",
            "Block": "130",
            "label": "SIVASAKTHI MATRICULATION HIGHER SECONDARY SCHOOL KOLIYANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10409",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SALAIAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10410",
            "district": "37",
            "Block": "130",
            "label": "ADITYA'S VIVEKANAN MATRIC HIGHER SECONDARY SCHOOL DEVANATHA SWAMY NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10411",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SALAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10412",
            "district": "37",
            "Block": "130",
            "label": "RAMAKRISHNA MISSION VIDYALAYA MATRICULATION SCHOOL SALAMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10413",
            "district": "37",
            "Block": "130",
            "label": "BHARATHI AIDED MIDDLE SCHOOL SAGADEVANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10414",
            "district": "37",
            "Block": "130",
            "label": "ROMAN CATHOLIC AIDED MIDDLE SCHOOL SENGADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10415",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL SOZHAGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10416",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL THALAVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10417",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10418",
            "district": "37",
            "Block": "130",
            "label": "SRI JEYEANDRA SARASWATHY VIDYAALAYAA MATRICULATION HIGHER SECONDARY SCHOOL T.MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10419",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUPACHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10420",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL POOTHAMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10421",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THOGAIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10422",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL NANNADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10423",
            "district": "37",
            "Block": "130",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL NARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10424",
            "district": "37",
            "Block": "130",
            "label": "KALAIMAGAL AIDED MIDDLE SCHOOL RAGAVANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10425",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PILLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10426",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PIDAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10427",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL POYYAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10428",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10429",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAZHUDAREDDY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10430",
            "district": "37",
            "Block": "130",
            "label": "S.P.N MATRICULATION HIGHER SECONDARY SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10431",
            "district": "37",
            "Block": "130",
            "label": "ADI DRAVIDA WELFARE HIGHER SECONDARY SCHOOL VAZHUDAREDDY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10432",
            "district": "37",
            "Block": "130",
            "label": "SHANTHINIKETAN  MATRICULATION SCHOOL THIRUPPACHAVADIMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10433",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VIRATTIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10434",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VAZHUDHAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10435",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALITHIRAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10436",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMMANANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10437",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SITHALAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10438",
            "district": "37",
            "Block": "102",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL KODUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10439",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL M.MANDAGAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10440",
            "district": "37",
            "Block": "102",
            "label": "OMANDURAR AIDED MIDDLE SCHOOL KOTHAMPAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10441",
            "district": "37",
            "Block": "102",
            "label": "ANANDHA AIDED MIDDLE SCHOOL VANIAMPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10442",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL V.AGARAM COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10443",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL VADAVAMBALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10444",
            "district": "37",
            "Block": "102",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL P.VILLIANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10445",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENTGIRLS  HIGH SCHOOL SIRUVANTHADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10446",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SIRUVANTHADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10447",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL POOVARASANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10448",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL ARPISAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10449",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL PAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10450",
            "district": "37",
            "Block": "102",
            "label": "VENKATAVARADA AIDED MIDDLE SCHOOL KRISHNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10451",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALINJIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10452",
            "district": "37",
            "Block": "102",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL RAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10453",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL RAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10454",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KONGAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10455",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL L.R.PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10456",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  V.BOODHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10457",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  V.MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10458",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL  KUMALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10459",
            "district": "37",
            "Block": "102",
            "label": "THIRUVALLUVAR AIDED MIDDLE SCHOOL  SESHANGANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10460",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PALLITHENNAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10461",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NAVAMALMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10462",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  C.B.SAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10463",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10464",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10465",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL PERIABABU SAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10471",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH SCHOOL KODUKKAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10472",
            "district": "37",
            "Block": "189",
            "label": "OUR LADY OF FATHIMA MATRICULATION SCHOOL ADICHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10473",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10474",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH SCHOOL NAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10475",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10476",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH  SCHOOL OTTAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10477",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NERKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10478",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL V. PUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10479",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  KANDACHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10480",
            "district": "37",
            "Block": "189",
            "label": "A G R GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL KANDACHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10481",
            "district": "37",
            "Block": "189",
            "label": "ST.MARY HIGH  SCHOOL OTHIYATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10482",
            "district": "37",
            "Block": "189",
            "label": "A R R GOVERNMENT HIGHER SECONDARY SCHOOL KARANAI PERICHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10483",
            "district": "37",
            "Block": "189",
            "label": "ST.XAVIER'S BOYS HIGHER SECONDARY SCHOOL MUGAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10484",
            "district": "37",
            "Block": "189",
            "label": "ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL MUGAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10485",
            "district": "37",
            "Block": "189",
            "label": "ST PETER PAUL SPECIAL HIGHER SECONDARY SCHOOL MUGAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10486",
            "district": "37",
            "Block": "189",
            "label": "ST JOSEPH'S MATRICULATION SCHOOL MUGAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10487",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH  SCHOOL AYANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10488",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH  SCHOOL SENNAKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10489",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEERANGIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10490",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL ALAMPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10491",
            "district": "37",
            "Block": "189",
            "label": "AKD MATRIC HIGHER SECONDARY SCHOOL ALAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10492",
            "district": "37",
            "Block": "189",
            "label": "ROMAN CATHOLIC MIDDLE  SCHOOL KODUNGAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10493",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ARCADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10494",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH SCHOOL PAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10498",
            "district": "37",
            "Block": "189",
            "label": "BALAMANDIR MATRIC HIGHER SECONDARY SCHOOL ARAKANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10499",
            "district": "37",
            "Block": "189",
            "label": "SRI LAKSHMI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, ARAKANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10500",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ARAKANDANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10501",
            "district": "37",
            "Block": "189",
            "label": "AIDED MIDDLE SCHOOL  ARAKANDANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10550",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL ARUNGURUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10551",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL OTTANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10552",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10553",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL PERIYASEVALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10554",
            "district": "37",
            "Block": "342",
            "label": "CHENGALRAYAN CO-OPERATIVE SUGAR MILLS MATRICULATION SCHOOL PERIYASEVALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10555",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SARAVANAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10556",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ANAIVARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10557",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARUMPATTU SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10558",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SITHANANGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10559",
            "district": "37",
            "Block": "342",
            "label": "St.JOHN'S HIGHER  SECONDARY SCHOOL SITHANANGOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10560",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10561",
            "district": "37",
            "Block": "342",
            "label": "ARUTKODI AIDED MIDDLE SCHOOL ARUL NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10562",
            "district": "37",
            "Block": "342",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL THIRUVENNAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10563",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PENNAIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10564",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  AMAVASAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10565",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAVANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10566",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL T.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10567",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERANGIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10568",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  EDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10569",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  SITHALINGAMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10570",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  SITHALINGAMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10571",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ERUVELPATTU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10572",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KARAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10573",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELTHANIYALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10574",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ANATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10575",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ARASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10576",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KANTHALAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10577",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANDARAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10578",
            "district": "37",
            "Block": "342",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL ANDARAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10579",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL  T.EDAIYAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10580",
            "district": "37",
            "Block": "342",
            "label": "CHRIST THE KING MATRICULATION HIGHER SECONDARY SCHOOL, METTUKUPPAM, ELANDURAI VILLAGE- 607209",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10581",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  MANAKKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10582",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHINNASEVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10583",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUVENNAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10584",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  THIRUVENNAINALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10585",
            "district": "37",
            "Block": "342",
            "label": "BONNE NEHRU HIGHER SECONDARY SCHOOL THIRUVENNAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10586",
            "district": "37",
            "Block": "342",
            "label": "GANDHI MEMORIAL HIGHER SECONDARY SCHOOL THIRUVENNAINALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10587",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL  THADUTHATKONDOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10588",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MAZHAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10589",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EMAPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10590",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL  SIRUMADURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10591",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ENATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10592",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIRUVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10593",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SEMMAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10594",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10595",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VALAIYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10899",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARUVEPPILAIAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10928",
            "district": "37",
            "Block": "404",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KEELPERUMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10929",
            "district": "37",
            "Block": "404",
            "label": "GOVERNMENT NEHRUJI MIDDLE SCHOOL VILLUPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10930",
            "district": "37",
            "Block": "404",
            "label": "MUNICIPAL HIGHER SECONDARY SCHOOL B.N.THOPE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10931",
            "district": "37",
            "Block": "404",
            "label": "SACRED HEART CENTRAL SCHOOL  VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10932",
            "district": "37",
            "Block": "404",
            "label": "MRIC RC HIGH SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "10933",
            "district": "37",
            "Block": "404",
            "label": "NEW JOHN DEWEY MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10934",
            "district": "37",
            "Block": "404",
            "label": "MAHATHMA GANDHI HIGH SCHOOL VILLUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10935",
            "district": "37",
            "Block": "404",
            "label": "TAGORE MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10936",
            "district": "37",
            "Block": "404",
            "label": "MUNICIPAL HIGH SCHOOL POONTHOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10937",
            "district": "37",
            "Block": "404",
            "label": "E.S.MATRICULATION HIGHER SECONDARY SCHOOL, VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10938",
            "district": "37",
            "Block": "404",
            "label": "SARADA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10939",
            "district": "37",
            "Block": "404",
            "label": "MUNICIPAL HIGH SCHOOL HOSPITAL ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10940",
            "district": "37",
            "Block": "404",
            "label": "ST JOHN SPECIAL HIGHER SECONDARY SCHOOL FOR DIFFERENTLY ABLED  VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10941",
            "district": "37",
            "Block": "404",
            "label": "ST.XAVIER MIDDLE SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10942",
            "district": "37",
            "Block": "404",
            "label": "ST XAVIER MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10943",
            "district": "37",
            "Block": "404",
            "label": "SRI KAMAKOTI ORIENTAL HIGH SCHOOL VILLUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10944",
            "district": "37",
            "Block": "404",
            "label": "SRI VENKATESWARA MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10945",
            "district": "37",
            "Block": "404",
            "label": "RAMAKRISHNA MIDDLE SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10946",
            "district": "37",
            "Block": "404",
            "label": "VPS MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10947",
            "district": "37",
            "Block": "404",
            "label": "HAJEE MANSOORSHA ORIENTAL ARABIC HIGH SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "10948",
            "district": "37",
            "Block": "404",
            "label": "TELC MIDDLE SCHOOL COURT ROAD VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10949",
            "district": "37",
            "Block": "404",
            "label": "THIRU KAMARAJ MUNICIPAL HIGHER SECONDARY SCHOOL  VILLUPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10950",
            "district": "37",
            "Block": "404",
            "label": "GOVERNMENT MODEL GIRLS HIGHER SECONDARY SCHOOL,VILLUPURAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10951",
            "district": "37",
            "Block": "404",
            "label": "NARAYANS MATRICULATION HIGHER SECONDARY SCHOOL  VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20961",
            "district": "37",
            "Block": "70",
            "label": "SRI DHARANI INTERNATIONAL  SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20962",
            "district": "37",
            "Block": "379",
            "label": "NEW CREATION BILINGUAL SCHOOL KUILAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21061",
            "district": "37",
            "Block": "70",
            "label": "SRI SAIRAM PUBLIC SCHOOL (CBSE) JAYANKONDAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21062",
            "district": "37",
            "Block": "100",
            "label": "VET VIDYAA MANDHIR CBSE SCHOOL KANAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21138",
            "district": "37",
            "Block": "379",
            "label": "NEW  ERA  SECONDARY SCHOOL  KUILAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21150",
            "district": "37",
            "Block": "189",
            "label": "VIVEKANANDA VIDHYALAYA MATRICULATION HIGHER SECONDARY  SCHOOL  MANAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21202",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL EMAPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21227",
            "district": "37",
            "Block": "404",
            "label": "VRP HIGHER SECONDARY SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21306",
            "district": "37",
            "Block": "384",
            "label": "RAJADESING PUBLIC SCHOOL KALAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21452",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL MUNNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21495",
            "district": "37",
            "Block": "379",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL POMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21591",
            "district": "37",
            "Block": "175",
            "label": "SHRI RAM SCHOOL,OMANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21683",
            "district": "37",
            "Block": "164",
            "label": "BWDA MIDDLE SCHOOL KOLLIYANGUNAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21684",
            "district": "37",
            "Block": "164",
            "label": "SKD MATRICULATION SCHOOL SOZHIYASORKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21686",
            "district": "37",
            "Block": "70",
            "label": "ASUVATHAMA MATRIC HIGHER SECONDARY SCHOOL, PALAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21786",
            "district": "37",
            "Block": "379",
            "label": "UNAIDED KUILAPALAYAM HIGHER SECONDARY SCHOOL KUILAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21787",
            "district": "37",
            "Block": "379",
            "label": "ST MARYS HIGH SCHOOL THIRUCHITRAMBALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21788",
            "district": "37",
            "Block": "379",
            "label": "RAGAVENDRA MIDDLE SCHOOL PERIYAMUDALIYAR CHAVADI WARD 1",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21789",
            "district": "37",
            "Block": "379",
            "label": "NIRVANA HIGH SCHOOL NADUKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21794",
            "district": "37",
            "Block": "214",
            "label": "SRI DHARAMCHAND JAIN SCHOOL (CBSE) KARUVAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21933",
            "district": "37",
            "Block": "100",
            "label": "OM SIVA SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL,PANAMALAI PETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21968",
            "district": "37",
            "Block": "401",
            "label": "SEVENTH DAY WHITE MATRICULATION SCHOOL V.SATHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22095",
            "district": "37",
            "Block": "70",
            "label": "SRI VIDHYA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL NARASINGARAYANPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22096",
            "district": "37",
            "Block": "379",
            "label": "SREE MOTHER VIDYASHRAM KILIYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22097",
            "district": "37",
            "Block": "401",
            "label": "K.G.  VIDYASHRAM CENTRAL BOARD SECONDARY EDUCATION SCHOOL ASHOKAPURI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22100",
            "district": "37",
            "Block": "189",
            "label": "SRI LAKSHMI VIDHYAASHRAM SCHOOL ARAKANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22101",
            "district": "37",
            "Block": "189",
            "label": "VIVEKA PUBLIC SCHOOL (CBSE)  ARAKANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22102",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL T.KOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22426",
            "district": "37",
            "Block": "181",
            "label": "HOLY ANGEL MATRICULATION SCHOOL KUDUVAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22427",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL KUNDALAPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22428",
            "district": "37",
            "Block": "401",
            "label": "VELA SPECIAL SCHOOL  LAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22429",
            "district": "37",
            "Block": "130",
            "label": "JOHN DEWEY INTERNATIONAL SCHOOL (CBSE) PANANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22694",
            "district": "37",
            "Block": "130",
            "label": "NIRMAL VIDYA MATRICULATION SCHOOL ASARANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22755",
            "district": "37",
            "Block": "130",
            "label": "E.S LORDS INTERNATIONAL SCHOOL AYYANKOILPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22756",
            "district": "37",
            "Block": "164",
            "label": "HOLY ANGELSCHOOL RETTANAI(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22757",
            "district": "37",
            "Block": "164",
            "label": "GREEN PARADISE RETTANAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22758",
            "district": "37",
            "Block": "175",
            "label": "CHANAKYA VIDYASHRAM (CBSE) MARAKKANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22759",
            "district": "37",
            "Block": "214",
            "label": "DMI ST JOSEPH GLOBAL SCHOOL, KEEZHATHANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22761",
            "district": "37",
            "Block": "384",
            "label": "DENI MATRICULATION SCHOOL, MELKALAVAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22795",
            "district": "37",
            "Block": "342",
            "label": "E.M.SUBRAMANI REDDIYAR VIVEKANANDA VIDYALAYA CBSE SCHOOL ERUVELPATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22874",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL- PERAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22875",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23026",
            "district": "37",
            "Block": "164",
            "label": "K K DHANDAPANI WORLD SENIOR SECONDARY   SCHOOL,MELPERADIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23126",
            "district": "37",
            "Block": "70",
            "label": "SRI RANGAPOOPATHI INTERNATIONAL SCHOOL , ALAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23137",
            "district": "37",
            "Block": "404",
            "label": "AKSHARDHAM CENTRAL SCHOOL - VILUPPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23155",
            "district": "37",
            "Block": "130",
            "label": "SARASWATHI EXCEL MATRICULATION SCHOOL SOZHAGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23196",
            "district": "37",
            "Block": "401",
            "label": "VILLUPURAM VIDYALAYA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23229",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH SCHOOL SU PILRAMPAT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23288",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL KADAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23635",
            "district": "37",
            "Block": "130",
            "label": "Government Model School, Vilupuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23636",
            "district": "37",
            "Block": "189",
            "label": "VIVEKANANDA VIDYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23637",
            "district": "37",
            "Block": "189",
            "label": "PALANIVELU MATRICULATION SCHOOL MELVALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23638",
            "district": "37",
            "Block": "379",
            "label": "KAVIKAMBAN MATRIC SCHOOL THIRUVAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23639",
            "district": "37",
            "Block": "401",
            "label": "VILLUPURAM DISTRICT GOVERNMENT MODEL SCHOOL ",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23640",
            "district": "37",
            "Block": "401",
            "label": "St Josephs School V Sathanur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23783",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23806",
            "district": "37",
            "Block": "100",
            "label": "NILAA MATRICULATION SCHOOL,KOSAPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23878",
            "district": "37",
            "Block": "181",
            "label": "NETHAJI SUBHASH CHANDRA BOSE AVASIYA VIDYALAYA DEVANUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24050",
            "district": "37",
            "Block": "379",
            "label": "AMS THIRUCHITRAMBALAM",
            "Sch_type": "Fully AidedMiddle School"
        },
        {
            "key": "24051",
            "district": "37",
            "Block": "379",
            "label": "KAVI KAMBAN HIGH SCHOOL THIRUVAKKARAI",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24075",
            "district": "37",
            "Block": "401",
            "label": "NATIONAL ACADEMY MATRIC  SCHOOL THUMBUR  ",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "38": [
        {
            "key": "1",
            "district": "38",
            "Block": "264",
            "label": "A.A.G.M HIGH SCHOOL, SANGARAPANDIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "2",
            "district": "38",
            "Block": "264",
            "label": "SRI RAO BAHADUR A.K.D.DHARMARAJA BOYS HR SEC SCHOOL, RAJAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3",
            "district": "38",
            "Block": "264",
            "label": "A.K.D.D.MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4",
            "district": "38",
            "Block": "264",
            "label": "ANANDA VIDYALAYA MATRICULATON HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5",
            "district": "38",
            "Block": "264",
            "label": "CASA DI MIR MATRICULATON HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6",
            "district": "38",
            "Block": "264",
            "label": "CHINMAYA VID P.A.C.R MAT HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7",
            "district": "38",
            "Block": "264",
            "label": "GANDHI MIDDLE SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8",
            "district": "38",
            "Block": "264",
            "label": "GHSS (G), CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGH SCHOOL, AYANKOLLANKONDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGH SCHOOL, MOUNT ZION",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11",
            "district": "38",
            "Block": "264",
            "label": "GHSS (B), CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12",
            "district": "38",
            "Block": "264",
            "label": "GHSS, CHOKKANATHANPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13",
            "district": "38",
            "Block": "264",
            "label": "GHSS, KILAVIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14",
            "district": "38",
            "Block": "264",
            "label": "GHSS, NALLAMANAICKERPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SIVALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGH SCHOOL, THIRUVALLUVAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17",
            "district": "38",
            "Block": "264",
            "label": "GOVT A.D.W HR SEC SCHOOL, SUNDARARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18",
            "district": "38",
            "Block": "264",
            "label": "GOVT HR SEC SCHOOL, SEITHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24124", "district":"38", 
            "Block":"282", "label": "sun indiya matriculation higher secondary school", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "19",
            "district": "38",
            "Block": "264",
            "label": "HARISH MATRIC HSS, SANGAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20",
            "district": "38",
            "Block": "264",
            "label": "K.H.N.U.P.KAMARAJ. HR SEC SCHOOL, KRISHNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21",
            "district": "38",
            "Block": "264",
            "label": "K.P.A. MID SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23698",
            "district": "38",
            "Block": "298",
            "label": "st antony matriculation school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "22",
            "district": "38",
            "Block": "264",
            "label": "LIVING HOPE MATRIC SCHOOL, MOUNT ZION",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23",
            "district": "38",
            "Block": "264",
            "label": "MAHARISHI VID MAT HSS,RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24",
            "district": "38",
            "Block": "264",
            "label": "M.M.AVUDAI AMMAL MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "25",
            "district": "38",
            "Block": "264",
            "label": "M.N.NADAR HIGH SCH, MELAVARAGUNARAMAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "26",
            "district": "38",
            "Block": "264",
            "label": "MURUGAN MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "27",
            "district": "38",
            "Block": "264",
            "label": "N.A ANNAPPARAJA MEMORIAL H S S RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "28",
            "district": "38",
            "Block": "264",
            "label": "N.A PAPPURAJA MEMORIAL MIDDLE SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "29",
            "district": "38",
            "Block": "264",
            "label": "NADAR HR SEC SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "30",
            "district": "38",
            "Block": "264",
            "label": "NADAR HSS, ZAMIN KOLLANKONDAN",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "31",
            "district": "38",
            "Block": "264",
            "label": "NADAR MATRIC HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "32",
            "district": "38",
            "Block": "264",
            "label": "NATARAJA MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "33",
            "district": "38",
            "Block": "264",
            "label": "NAVA BHARATH MATRIC HIGHER SECONDARY SCHOOL, S.RAMALINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "34",
            "district": "38",
            "Block": "264",
            "label": "P.A.C.R AMMANI AMMAL(G) HR SEC SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "35",
            "district": "38",
            "Block": "264",
            "label": "P.A.C.M HR SEC SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "36",
            "district": "38",
            "Block": "264",
            "label": "PALANIAPPA HSS, CHOLAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "37",
            "district": "38",
            "Block": "264",
            "label": "PERUNTHALAIVAR KAMARAJAR HSS MEENATICHIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "38",
            "district": "38",
            "Block": "264",
            "label": "PERUNTHALAIVAR KAMARAJAR MATRICULATON HR SEC SCHOOL, THALAVAIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "39",
            "district": "38",
            "Block": "264",
            "label": "P.P.S.A HINDU MIDDLE SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "40",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S, AMMAIYAPPAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "41",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S,ASILAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "42",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S, DEVADANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "43",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S, GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "44",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S PANCHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "45",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S, SOUTH VENGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "46",
            "district": "38",
            "Block": "264",
            "label": "SRI RAMANA VIDYALAYA MONTESSORI MATRIC HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "47",
            "district": "38",
            "Block": "264",
            "label": "RC HR SEC SCHOOL, MEENACHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "48",
            "district": "38",
            "Block": "264",
            "label": "R.V THEVAR M.G HR SEC SCHOOL, CHOLAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "49",
            "district": "38",
            "Block": "264",
            "label": "SAKKA RAJA KOTTAI MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "50",
            "district": "38",
            "Block": "264",
            "label": "SIVANTHI ATHITHANAR HIGH SCHOOL, KOVILUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "51",
            "district": "38",
            "Block": "264",
            "label": "CHINMAYA VIDYALAYA SLR MAT HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "52",
            "district": "38",
            "Block": "264",
            "label": "SRI KRISHNASAMY MID.S, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "53",
            "district": "38",
            "Block": "264",
            "label": "SRI P.S.KUMARASAMY RAJA MEMORIAL GIRLS HIGH SCHOOL, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "54",
            "district": "38",
            "Block": "264",
            "label": "SRI RAO BAHADUR A.K.D.DHARMARAJA GIRLS HR.SEC.SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "55",
            "district": "38",
            "Block": "264",
            "label": "SSG(G)HSS,RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "56",
            "district": "38",
            "Block": "264",
            "label": "SSG(B)HSS, RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "57",
            "district": "38",
            "Block": "264",
            "label": "S.S.H.N.HR.SEC.SCHOOL, MUHAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "58",
            "district": "38",
            "Block": "264",
            "label": "ST ANTONYS MATRIC SCHOOL, CHETTIYARPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "59",
            "district": "38",
            "Block": "264",
            "label": "ST JOSEPH MID SCH, SUNDARANATCHIYARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "60",
            "district": "38",
            "Block": "264",
            "label": "T.D.T.A.M.S, POTTALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "61",
            "district": "38",
            "Block": "264",
            "label": "T.N.P.M.M.A.N (G).H.S.S, THALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "62",
            "district": "38",
            "Block": "264",
            "label": "T.N.P.M.MARIMUTHU NADAR HR.SEC.SCHOOL, THALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "63",
            "district": "38",
            "Block": "264",
            "label": "VANIGA VYSIAR HIGH SCHOOL, RAJAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "64",
            "district": "38",
            "Block": "264",
            "label": "VIII WARD MUNICIPAL MID SCH, RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "65",
            "district": "38",
            "Block": "298",
            "label": "C.M.S. HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "66",
            "district": "38",
            "Block": "298",
            "label": "GOVT ADW HIGH SCHOOL, IDAYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "67",
            "district": "38",
            "Block": "298",
            "label": "VPMS SESHA MATRICULATION SCHOOL , SRIVILLIPUTHUR ,",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "68",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGH SCHOOL, THOMBAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "69",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PILLAIYARNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "70",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KRISHNANKOVIL - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "71",
            "district": "38",
            "Block": "298",
            "label": "GOVT ADW HIGHER SECONDARY SCHOOL, KARISALKULAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "72",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGH SCHOOL, PANANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "73",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGH SCHOOL, T.MANAGASERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "74",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ULLOORPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "75",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PAPPAYANAICKERPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "76",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KEELARAJAKULARAMAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "77",
            "district": "38",
            "Block": "298",
            "label": "GURUGNANA SAMPANDAR HINDU HSS, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "78",
            "district": "38",
            "Block": "298",
            "label": "JOTHI HIGH SCHOOL,R.REDDIAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "79",
            "district": "38",
            "Block": "298",
            "label": "KAMARAJAR  GOVERNMENT MIDDLE SCHOOL, MALLIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "80",
            "district": "38",
            "Block": "298",
            "label": "P.N.U.P.KAMARAJAR MATRICULATION HR.SEC.SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "81",
            "district": "38",
            "Block": "298",
            "label": "MANGAPURAM HINDU HR SEC SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "82",
            "district": "38",
            "Block": "298",
            "label": "M.N.R.D.GIRLS HSS, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "83",
            "district": "38",
            "Block": "298",
            "label": "KAMMAPATTI MUNICIPAL MIDDLE SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "84",
            "district": "38",
            "Block": "298",
            "label": "MUNICIPAL MIDDLE SCHOOL, KRISHNAN KOVIL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "85",
            "district": "38",
            "Block": "298",
            "label": "METTU STREET MUNICIPAL MIDDLE SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "86",
            "district": "38",
            "Block": "298",
            "label": "MUNICIPAL MIDDLE SCHOOL, PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "87",
            "district": "38",
            "Block": "298",
            "label": "MUNICIPAL TVK HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "88",
            "district": "38",
            "Block": "298",
            "label": "MUNICIPAL MIDDLE SCHOOL, URANIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "89",
            "district": "38",
            "Block": "298",
            "label": "NK GOPALSAMY MIDDLE SCHOOL NACHIYARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "90",
            "district": "38",
            "Block": "298",
            "label": "PASUMPON THEVAR HIGHER SECONDARY SCHOOL, MAMSAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "91",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SAMINATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "92",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, POOVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "93",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONGALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "94",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "95",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AKKARAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "96",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHOKKALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "97",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUMALDEVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "98",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "99",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL INAM KARISALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "100",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, INDRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "101",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.THOTTIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "102",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "103",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NARAIYANKULAM OTHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "104",
            "district": "38",
            "Block": "298",
            "label": "RC.MIDDLE.SCHOOL,PATTAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "105",
            "district": "38",
            "Block": "298",
            "label": "R.K.GOVERNMENT HIGHER SECONDARY SCHOOL, P.RAMACHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "106",
            "district": "38",
            "Block": "298",
            "label": "SACRED HEART GIRLS HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "107",
            "district": "38",
            "Block": "298",
            "label": "SIVANTHIPATTI NADAR GIRLS HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "108",
            "district": "38",
            "Block": "298",
            "label": "SIVANTHIPATTI NADAR HSS, MAMSAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "109",
            "district": "38",
            "Block": "298",
            "label": "SIVARAM HIGH SCHOOL, MAMSAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "110",
            "district": "38",
            "Block": "298",
            "label": "SIVARAM MIDDLE SCHOOL,MAMSAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "111",
            "district": "38",
            "Block": "298",
            "label": "SRIVI LIONS MATRICULATION HSS, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "112",
            "district": "38",
            "Block": "298",
            "label": "ST.JOSEPH RC HR SEC SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "113",
            "district": "38",
            "Block": "298",
            "label": "THIYAGARAJA HIGHER SECONDARY SCHOOL., SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "114",
            "district": "38",
            "Block": "298",
            "label": "VALLUVAR VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "115",
            "district": "38",
            "Block": "298",
            "label": "VENKADASAMY NAIDU MIDDLE SCHOOL, M.P.K.PUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "116",
            "district": "38",
            "Block": "298",
            "label": "VNUP.KALEESWARI HSS, VANNIAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "117",
            "district": "38",
            "Block": "298",
            "label": "V.P.M.M.MATRICULATION HSS, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "118",
            "district": "38",
            "Block": "396",
            "label": "ST.ANTONY'S HIGH SCHOOL, MELANMARAINADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "119",
            "district": "38",
            "Block": "396",
            "label": "BHARAT MIDDILE SCHOOL APPAYANAICKERPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "120",
            "district": "38",
            "Block": "396",
            "label": "GOVERNMENT HIGH SCHOOL, K.CHELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "121",
            "district": "38",
            "Block": "396",
            "label": "GHS K.MADATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "122",
            "district": "38",
            "Block": "396",
            "label": "GHS MAMSAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "123",
            "district": "38",
            "Block": "396",
            "label": "GHS,PANAYADIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "124",
            "district": "38",
            "Block": "396",
            "label": "GHSS,ALANGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "125",
            "district": "38",
            "Block": "396",
            "label": "GHS PULLAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "126",
            "district": "38",
            "Block": "396",
            "label": "GHSS,SANKARAPANDIAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "127",
            "district": "38",
            "Block": "396",
            "label": "GHSS,SEVALPATTI - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "128",
            "district": "38",
            "Block": "396",
            "label": "GHSS,SIPPIPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "129",
            "district": "38",
            "Block": "396",
            "label": "GHS SUBRAMANIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "130",
            "district": "38",
            "Block": "396",
            "label": "GHSS,VEMBAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "131",
            "district": "38",
            "Block": "396",
            "label": "GHSS,VIJAYAKARISALKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "132",
            "district": "38",
            "Block": "396",
            "label": "GHS THULUKANKURUCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "133",
            "district": "38",
            "Block": "396",
            "label": "GOVT HSS KALLAMANAICKERPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "134",
            "district": "38",
            "Block": "396",
            "label": "HINDU MARAVAR PS ,T.KOTTAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "135",
            "district": "38",
            "Block": "396",
            "label": "HINDU MIDDLE SCHOOL, ETHIRKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "136",
            "district": "38",
            "Block": "396",
            "label": "KRTA GHSS,MELATHAYILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "137",
            "district": "38",
            "Block": "396",
            "label": "MAHAMAI MS , GUHANPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "138",
            "district": "38",
            "Block": "396",
            "label": "NADAR GEORGE HS, THAYILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "139",
            "district": "38",
            "Block": "396",
            "label": "NADAR MAHAMAI GIRLS HIGH SCHOOL,ELAYIRAMPANNAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "140",
            "district": "38",
            "Block": "396",
            "label": "NADAR MAHAMAI HSS ELAYIRAMPANNAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "141",
            "district": "38",
            "Block": "396",
            "label": "R.P.N.M.HINDU MIDDLE SCHOOL, KAKKIVADANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "142",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S ELAYIRAMPANNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "143",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S KANGARSEVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "144",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S KOTTAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "145",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S MANJALODAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "146",
            "district": "38",
            "Block": "396",
            "label": "P U M S M.DURAISAMYPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "147",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S KOTHAINATCHIYARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "148",
            "district": "38",
            "Block": "396",
            "label": "P U M S NATHIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "149",
            "district": "38",
            "Block": "396",
            "label": "P U M S PARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "150",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S PERNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "151",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S,RAMUTHEVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "152",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S SOUTH ANAIKUTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "153",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S E.RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "154",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S ETTAKKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "155",
            "district": "38",
            "Block": "396",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AYAN SALVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "156",
            "district": "38",
            "Block": "396",
            "label": "TANCEM MHSS ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "433",
            "district": "38",
            "Block": "295",
            "label": "VINAYAKAR MATRICULATION HR SEC SCHOOL,SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "559",
            "district": "38",
            "Block": "295",
            "label": "GLORY MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "572",
            "district": "38",
            "Block": "28",
            "label": "NOBLE MATRICULATION HIGHER SECONDARY SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "649",
            "district": "38",
            "Block": "110",
            "label": "NAZIA MATRICULATION HR.SEC.SCHOOL, S.KALLUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1803",
            "district": "38",
            "Block": "407",
            "label": "MSPN MUNICIPAL MIDDLE SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1967",
            "district": "38",
            "Block": "295",
            "label": "KPATDR SIVAKASI JAYCEES MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2182",
            "district": "38",
            "Block": "28",
            "label": "SRI SOWDAMBIKA CONVENT MATRICULATION HSSCHOOL, CHETTIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2183",
            "district": "38",
            "Block": "282",
            "label": "KCAD CHIDAMBARAM GNANAGIRI MATRICULATION HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2200",
            "district": "38",
            "Block": "282",
            "label": "OXFORD MATRICULATION SCHOOL, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2247",
            "district": "38",
            "Block": "282",
            "label": "DR. RADHAKRISHNAN MATRIC HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2284",
            "district": "38",
            "Block": "28",
            "label": "SIVANANDA VIDYALAYAM MATRIC HSS AATHIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2291",
            "district": "38",
            "Block": "110",
            "label": "ST.MARYS MATRICULATION SCHOOL KARIAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2345",
            "district": "38",
            "Block": "407",
            "label": "VMGRR SARADHA SM HSS, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2404",
            "district": "38",
            "Block": "282",
            "label": "SRI VENKATESWARA MATRICULATION SCHOOL, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2472",
            "district": "38",
            "Block": "28",
            "label": "SBKKSS THIYAGARAJAN MEMORIAL MATRICULATION SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2484",
            "district": "38",
            "Block": "295",
            "label": "KMKA MATRICULATION HIGHER SECONDARY SCHOOL, THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2631",
            "district": "38",
            "Block": "411",
            "label": "RANGARAO LIONS GIRLS MATRICULATION SCHOOL, WATRAP",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2733",
            "district": "38",
            "Block": "282",
            "label": "LALITHA LAL BAHADUR SHASTRI PUBLIC SCHOOL, SADAYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2866",
            "district": "38",
            "Block": "295",
            "label": "MUTHUMARI MATRICULATION HR SEC SCHOOL, THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2882",
            "district": "38",
            "Block": "295",
            "label": "THE SIVAKASI LIONS MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2896",
            "district": "38",
            "Block": "407",
            "label": "KVS MATRICULATION HSS SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2910",
            "district": "38",
            "Block": "295",
            "label": "LORD P C A A  LIONS MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3032",
            "district": "38",
            "Block": "295",
            "label": "SARVASEVA  MATRICULATION SCHOOL,ERICHANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3212",
            "district": "38",
            "Block": "295",
            "label": "V.N.U.A.P.N.T. MATRICULATION HR SEC SCHOOL, VILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3219",
            "district": "38",
            "Block": "407",
            "label": "DHAMU MEMORIAL MATRICULATION SCHOOL, SEVALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3223",
            "district": "38",
            "Block": "295",
            "label": "ARASAN GANESAN MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3252",
            "district": "38",
            "Block": "295",
            "label": "SUNDARAVEL MATRICULATION HR SEC SCHOOL, THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3269",
            "district": "38",
            "Block": "295",
            "label": "N.P.S.S.R.K. ROTARY MATRICULATION HIGHER SECONDARY SCHOOL, VISWANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3343",
            "district": "38",
            "Block": "295",
            "label": "K.KAMARAJAR MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3438",
            "district": "38",
            "Block": "295",
            "label": "SRI SHENBAGA VINAYAGAR MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3495",
            "district": "38",
            "Block": "295",
            "label": "V.S.K.DURAISWAMYNADAR GIRLS MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3831",
            "district": "38",
            "Block": "206",
            "label": "ASMA MATRICULATION HIGH SCHOOL VEERACHOLAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3972",
            "district": "38",
            "Block": "282",
            "label": "SOUTHSIDE MATRICULATION HIGHER SECONDARY SCHOOL, METTAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4061",
            "district": "38",
            "Block": "295",
            "label": "S.D.A. MATRICULATION HIGH SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4258",
            "district": "38",
            "Block": "295",
            "label": "SRI N M MEHTA JAIN MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4278",
            "district": "38",
            "Block": "295",
            "label": "THIRUTHANGAL LIONS MATRICULATION HR SEC SCHOOL, THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4531",
            "district": "38",
            "Block": "407",
            "label": "P.S.T.M.SEVENTH DAY ADVENTISTS MATRIC HSS VIRUTHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4676",
            "district": "38",
            "Block": "110",
            "label": "SUNDRAM MATRICULATION HR. SEC. SCHOOL KRISHNAPURAM AVIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4704",
            "district": "38",
            "Block": "110",
            "label": "SARVA SEVA MATRICULATION SCHOOL, THONUGAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5744",
            "district": "38",
            "Block": "295",
            "label": "S.N.M.F. MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6273",
            "district": "38",
            "Block": "407",
            "label": "SRI VIDHYA MATRICULATION SCHOOL, P.KUMARALINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6667",
            "district": "38",
            "Block": "295",
            "label": "GOOD SHEPHERD MATRICULATION HIGH SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6679",
            "district": "38",
            "Block": "295",
            "label": "SIVAKASI HINDU NADAR VICTORIYA MIDDLE SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18654",
            "district": "38",
            "Block": "28",
            "label": "P.U.M.S, VILLIPATHIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18655",
            "district": "38",
            "Block": "28",
            "label": "GHSS, SOOLAKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18656",
            "district": "38",
            "Block": "28",
            "label": "GHS FOR HEARING IMPAIRED, SOOLAKKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18657",
            "district": "38",
            "Block": "28",
            "label": "SRI VEERAPPA VIDHYALAYA HR SEC SCHOOL, KULLURSANDAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18658",
            "district": "38",
            "Block": "28",
            "label": "GHSS, PALAVANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18659",
            "district": "38",
            "Block": "28",
            "label": "HINDU HIGH SCHOOL, GOPALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18660",
            "district": "38",
            "Block": "28",
            "label": "CSD MS KOVILANGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18661",
            "district": "38",
            "Block": "28",
            "label": "G H S, KOVILANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18662",
            "district": "38",
            "Block": "28",
            "label": "P.U.M.S., PERIYAKATTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18663",
            "district": "38",
            "Block": "28",
            "label": "ST.JOSEPH'S CARMEL MATRICULATION SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18664",
            "district": "38",
            "Block": "28",
            "label": "BPV SALA  HSS, PALAYAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18665",
            "district": "38",
            "Block": "28",
            "label": "G H S S, SEMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18666",
            "district": "38",
            "Block": "28",
            "label": "VELLALAR MS., PULIYURAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18667",
            "district": "38",
            "Block": "28",
            "label": "NADAR MIDDLE SCHOOL., PULIYURAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18668",
            "district": "38",
            "Block": "28",
            "label": "PUMS, KOOTHIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18669",
            "district": "38",
            "Block": "28",
            "label": "GOVT HR SEC SCHOOL, MALAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18670",
            "district": "38",
            "Block": "28",
            "label": "P U M S AMANAKKUNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18671",
            "district": "38",
            "Block": "28",
            "label": "GHSS, VELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18672",
            "district": "38",
            "Block": "28",
            "label": "GHSS CHETTIKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18673",
            "district": "38",
            "Block": "28",
            "label": "RUKNMSPN HS RAMANAICKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18674",
            "district": "38",
            "Block": "28",
            "label": "GOVERNMENT HIGH SCHOOL, KURUNTHAMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18675",
            "district": "38",
            "Block": "28",
            "label": "VHNKUSKV HIGH SCHOOL, VADHUVARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18676",
            "district": "38",
            "Block": "28",
            "label": "PUMS, CHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18677",
            "district": "38",
            "Block": "28",
            "label": "P U M S, KASILINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18678",
            "district": "38",
            "Block": "28",
            "label": "GHSS (B), PANDALKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18679",
            "district": "38",
            "Block": "28",
            "label": "VIDYA MANDIR MATRICULATION SCHOOL, RAMCO NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18680",
            "district": "38",
            "Block": "28",
            "label": "GHSS (G), PANDALKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18681",
            "district": "38",
            "Block": "28",
            "label": "P U M S., KOPPUCHITAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18682",
            "district": "38",
            "Block": "28",
            "label": "APTSMPS MATRICULATION SCHOOL, PULIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18683",
            "district": "38",
            "Block": "28",
            "label": "MUNICIPAL MIDDLE SCHOOL, MADURAI SALAI, ARUPPUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18684",
            "district": "38",
            "Block": "28",
            "label": "MUNICIPAL MIDDLE SCHOOL, WEST CAR STREET, ARUPPUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18685",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR MIDDLE SCHOOL., CHOKKALINGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18686",
            "district": "38",
            "Block": "28",
            "label": "CSI BOARDING MS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18687",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR PULIYAMPATTI MS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18688",
            "district": "38",
            "Block": "28",
            "label": "VELAYUTHAPURAM SALIYAR MS., ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18689",
            "district": "38",
            "Block": "28",
            "label": "KAMMAVAR RAMANUJAR MS, CHINNAPULIYAMPATTI, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18690",
            "district": "38",
            "Block": "28",
            "label": "HINDU NADAR M S, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18691",
            "district": "38",
            "Block": "28",
            "label": "VELLAKOTTAI SALIAR MIDDLE SCHOOL., ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18692",
            "district": "38",
            "Block": "28",
            "label": "S T R N GHSS, ARUPPUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18693",
            "district": "38",
            "Block": "28",
            "label": "SENGUNTHAR H.S, THIRUNAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18694",
            "district": "38",
            "Block": "28",
            "label": "VELLAIKOTTAI SENGUNDHAR HS., APK",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18695",
            "district": "38",
            "Block": "28",
            "label": "AL AMEEN MUSLIM HSS, ARUPPUKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18696",
            "district": "38",
            "Block": "28",
            "label": "SBK BOYS HSS ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18697",
            "district": "38",
            "Block": "28",
            "label": "S B K GIRLS HR SEC SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18698",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR HSS., ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18699",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR GIRLS HSS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18700",
            "district": "38",
            "Block": "28",
            "label": "CSI GIRLS HSS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18701",
            "district": "38",
            "Block": "28",
            "label": "SALIYAR MAHAJANA HIGHER SECONDARY SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18702",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR TOWN MS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18703",
            "district": "38",
            "Block": "28",
            "label": "SHRI MATHRU VIDYASHRAM MATRICULATION HR SEC SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18704",
            "district": "38",
            "Block": "28",
            "label": "MPL HIGH SCHOOL, WEAVERS COLONY, ARUPPUKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18705",
            "district": "38",
            "Block": "28",
            "label": "SBK INTERNATIONAL SCHOOL(CBSE), ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18706",
            "district": "38",
            "Block": "407",
            "label": "KVS MIDDLE SCHOOL, SENGUNDRAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18707",
            "district": "38",
            "Block": "407",
            "label": "PUMS,MOOLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18708",
            "district": "38",
            "Block": "407",
            "label": "HINDU MIDDLE SCHOOL, P.KUMARALINGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18709",
            "district": "38",
            "Block": "407",
            "label": "GHS, PAVALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18710",
            "district": "38",
            "Block": "407",
            "label": "GHS,VADAMALAIKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18711",
            "district": "38",
            "Block": "407",
            "label": "GHSS CHATRAREDDIAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18712",
            "district": "38",
            "Block": "407",
            "label": "GHS,PERIA PERALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18713",
            "district": "38",
            "Block": "407",
            "label": "PUMS,PELAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18714",
            "district": "38",
            "Block": "407",
            "label": "PUMS,CHINNAMOOPANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18715",
            "district": "38",
            "Block": "407",
            "label": "KKSSN MEMORIAL MIDDLE SCHOOL ROSALPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18716",
            "district": "38",
            "Block": "407",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MEESALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18717",
            "district": "38",
            "Block": "407",
            "label": "GHSS AMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18718",
            "district": "38",
            "Block": "407",
            "label": "PUMS, THATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18719",
            "district": "38",
            "Block": "407",
            "label": "PUMS MARULOOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18720",
            "district": "38",
            "Block": "407",
            "label": "GHS,INAMREDDIAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18721",
            "district": "38",
            "Block": "407",
            "label": "PUMS O.KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18722",
            "district": "38",
            "Block": "407",
            "label": "GHSS, SANKARALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18723",
            "district": "38",
            "Block": "407",
            "label": "ST.XAVIER'S HS K.PUDUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18724",
            "district": "38",
            "Block": "407",
            "label": "GOVT HR SEC SCHOOL THIYAGARAJAPURAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18725",
            "district": "38",
            "Block": "407",
            "label": "GHS,PATTAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18726",
            "district": "38",
            "Block": "407",
            "label": "GHSS, METTUKUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18727",
            "district": "38",
            "Block": "407",
            "label": "HINDU MIDDLE SCHOOL, KADAMBANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18728",
            "district": "38",
            "Block": "407",
            "label": "PUMS KUPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18729",
            "district": "38",
            "Block": "407",
            "label": "PUMS THADANGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18730",
            "district": "38",
            "Block": "407",
            "label": "GHSS, AVUDAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18731",
            "district": "38",
            "Block": "407",
            "label": "MCNUPDA HSS M.CHINNAIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18732",
            "district": "38",
            "Block": "407",
            "label": "PUMS,A.PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18733",
            "district": "38",
            "Block": "407",
            "label": "GHSS, VEERARPATTI APPAYANAYAKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18734",
            "district": "38",
            "Block": "407",
            "label": "GHSS, NADUVAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18735",
            "district": "38",
            "Block": "407",
            "label": "SMG MUNICIPAL MS VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18736",
            "district": "38",
            "Block": "407",
            "label": "MUNICIPAL MUSLIM MIDDLE SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18737",
            "district": "38",
            "Block": "407",
            "label": "MUNICIPAL MIDDLE SCHOOL,RAMAMOORTHY ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18738",
            "district": "38",
            "Block": "407",
            "label": "MUNICIPAL MIDDLE SCHOOL, SIVAN KOVIL ST, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18739",
            "district": "38",
            "Block": "407",
            "label": "K.C.S.DHANUSHKODI NADAR  MUNICIPAL MIDDLE SCHOOL,VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18740",
            "district": "38",
            "Block": "407",
            "label": "KVS MIDDLE SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18741",
            "district": "38",
            "Block": "407",
            "label": "KPMJHS HOWA BIBI MS, VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18742",
            "district": "38",
            "Block": "407",
            "label": "TELC MS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18743",
            "district": "38",
            "Block": "407",
            "label": "HAJEE P SYED MM SCHOOL VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18744",
            "district": "38",
            "Block": "407",
            "label": "KGMS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18745",
            "district": "38",
            "Block": "407",
            "label": "SVA MS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18746",
            "district": "38",
            "Block": "407",
            "label": "K KAMARAJ VIDHYASALA MS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18747",
            "district": "38",
            "Block": "407",
            "label": "THIRUVALLUVAR VIDHYASALA MIDDLE SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18748",
            "district": "38",
            "Block": "407",
            "label": "AP SANKARALINGA NADAR GIRLS MIDDLE SCHOOL VNR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18749",
            "district": "38",
            "Block": "407",
            "label": "VPP HIGHER SECONDARY SCHOOL, Virudhunagar",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18750",
            "district": "38",
            "Block": "407",
            "label": "SSSN GOVT HR SEC SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18751",
            "district": "38",
            "Block": "407",
            "label": "TPNM GIRLS HIGHER SECONDARY SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18752",
            "district": "38",
            "Block": "407",
            "label": "HAJEE P SYED M HSS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18753",
            "district": "38",
            "Block": "407",
            "label": "SOWDAMBIGAI HSS  VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18754",
            "district": "38",
            "Block": "407",
            "label": "KGHSS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18755",
            "district": "38",
            "Block": "407",
            "label": "VKMJHSHB GIRLS HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18756",
            "district": "38",
            "Block": "407",
            "label": "RC HSS VIRUDHUNAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18757",
            "district": "38",
            "Block": "407",
            "label": "KVS HSS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18758",
            "district": "38",
            "Block": "407",
            "label": "P S CHIDAMBARA NADAR SENIOR ENGLISH SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18759",
            "district": "38",
            "Block": "407",
            "label": "VIRUDHUNAGAR MATRICULATION SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18760",
            "district": "38",
            "Block": "407",
            "label": "KSHATRIYA VIDHYASALA CENTENARY SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18761",
            "district": "38",
            "Block": "407",
            "label": "RAO BAHADUR MSPS MUNICIPAL HIGH SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18762",
            "district": "38",
            "Block": "110",
            "label": "KENDRIYA VIDYALAYA, VIRUDHUNAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18763",
            "district": "38",
            "Block": "407",
            "label": "ASPCC MUNICIPAL HS,VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18764",
            "district": "38",
            "Block": "407",
            "label": "SARASWATHI AMMAL LIONS MATRICULATON SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18765",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANGULAM UDAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18766",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL, KURANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18767",
            "district": "38",
            "Block": "110",
            "label": "FROEBEL FRIEDRICH VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, AVIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18768",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, AVIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18769",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARASAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18770",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MUSTAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18771",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELAKKALANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18772",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL P. PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18773",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL V. NANGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18774",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAPPANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18775",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAMBIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18776",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEENATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18777",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL S.KALLUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18778",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL PAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18779",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18780",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL PISINDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18781",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ACHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18782",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL,MANTHOPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18783",
            "district": "38",
            "Block": "110",
            "label": "GOVT MIDDLE SCHOOL  SOLAIKAVUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18784",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELA ALAGIANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18785",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALAGIANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18786",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELATHULUKKANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18787",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THANDIYANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18788",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL THONUGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18789",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18790",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VARALOTTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18791",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MILL COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18792",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL, KALKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18793",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, JOKILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18794",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VAKKANANKUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18795",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL, KALUVANACHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18796",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THOPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18797",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL, CHITHUMOONDRADAIPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18798",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL, CHATIRAM PULIANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18799",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A. THOTTIYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18800",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, T. VEPPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18801",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL MUDUKKANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18802",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL, VALLAPPANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18803",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MURUGAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18804",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, T. CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18805",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SALAIMARAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18806",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18807",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SURANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18808",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHOKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18809",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL E. THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18810",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL KAMBALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18811",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARIAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18812",
            "district": "38",
            "Block": "110",
            "label": "AMALA HIGH SCHOOL KARIAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18813",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KARIAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18814",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MALLANKINAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18815",
            "district": "38",
            "Block": "110",
            "label": "M N M.S.P. SENTHIKUMARA NADAR HIGHER SECONDARY SCHOOL, MALLANKINAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18816",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KARIAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18817",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL KATHALAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18818",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL MUTHURAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18819",
            "district": "38",
            "Block": "321",
            "label": "SBK HIGHER SECONDARY SCHOOL KALLOORANI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18820",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL, SAVVASUPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18821",
            "district": "38",
            "Block": "321",
            "label": "PUMS MELAKANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18822",
            "district": "38",
            "Block": "321",
            "label": "PUMS MADATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18823",
            "district": "38",
            "Block": "321",
            "label": "GANDHI VIDYALAYAM HSS KULASEKARANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18824",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL KULASEKARANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18825",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL MARAVARPERUNGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18826",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL REDDIAPATTI M - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18827",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL THUMMUCHINNAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18828",
            "district": "38",
            "Block": "321",
            "label": "PUMS POTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18829",
            "district": "38",
            "Block": "321",
            "label": "NMSSV MIDDLE SCHOOL THOPPALAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18830",
            "district": "38",
            "Block": "321",
            "label": "PUMS RAJAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18831",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL K CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18832",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL NALLANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18833",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL PARALACHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18834",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL VADAKKUNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18835",
            "district": "38",
            "Block": "321",
            "label": "PUMS SENKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18836",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL POOLANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18837",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL KEELKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18838",
            "district": "38",
            "Block": "321",
            "label": "PUMS ILLUPPAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18839",
            "district": "38",
            "Block": "321",
            "label": "PUMS K VELAYUTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18840",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL VIDATHAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18841",
            "district": "38",
            "Block": "321",
            "label": "PUMS ERRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18842",
            "district": "38",
            "Block": "321",
            "label": "PUMS PUDUPATTI P M",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18843",
            "district": "38",
            "Block": "321",
            "label": "PUMS PULIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18844",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL P THOTTIYANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18845",
            "district": "38",
            "Block": "321",
            "label": "T.U.N.S.V.HSS,THIRUCHULI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18846",
            "district": "38",
            "Block": "321",
            "label": "SETHUPATHI GOVT HIGHER SECONDARY SCHOOL TIRUCHULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18847",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL TAMILPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18848",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL UDAIYANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18849",
            "district": "38",
            "Block": "321",
            "label": "PUMS KARENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18850",
            "district": "38",
            "Block": "206",
            "label": "PUMS THATCHANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18851",
            "district": "38",
            "Block": "206",
            "label": "GOVERNMENT HIGH SCHOOL PILLAIYARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18852",
            "district": "38",
            "Block": "206",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  THIMMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18853",
            "district": "38",
            "Block": "206",
            "label": "GOVT HIGH SCHOOL PULVOIKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18854",
            "district": "38",
            "Block": "206",
            "label": "P.K.M.I. GHSS A MUKKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18855",
            "district": "38",
            "Block": "206",
            "label": "KASTURBA GANDHI BALIKA VIDYALAYA, A.MUKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18856",
            "district": "38",
            "Block": "206",
            "label": "GHS SIRUVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18857",
            "district": "38",
            "Block": "206",
            "label": "GOVERNMENT HIGH SCHOOL, NALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18858",
            "district": "38",
            "Block": "206",
            "label": "GHS ULUTHIMADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18859",
            "district": "38",
            "Block": "206",
            "label": "GHSS KATTANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18860",
            "district": "38",
            "Block": "206",
            "label": "GHS IRUNCHIRAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18861",
            "district": "38",
            "Block": "206",
            "label": "GHSS ULAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18862",
            "district": "38",
            "Block": "206",
            "label": "PUMS CHOTTAMURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18863",
            "district": "38",
            "Block": "206",
            "label": "MARUTHUPANDIAR GOVERNMENT HR SEC SCHOOL, NARIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18864",
            "district": "38",
            "Block": "206",
            "label": "PUMS  MARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18865",
            "district": "38",
            "Block": "206",
            "label": "PUMS. SETHURAYANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18866",
            "district": "38",
            "Block": "206",
            "label": "KASTURBA GANDHI BALIKA VIDYALAYA, NARIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18867",
            "district": "38",
            "Block": "206",
            "label": "PUMS. MANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18868",
            "district": "38",
            "Block": "206",
            "label": "PUMS. T.VELANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18869",
            "district": "38",
            "Block": "206",
            "label": "PUMS. KANAYAMARITHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18870",
            "district": "38",
            "Block": "206",
            "label": "GOVERNMENT HIGH SCHOOL , S.VALLAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18871",
            "district": "38",
            "Block": "206",
            "label": "PUMS MELAPARUTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18872",
            "district": "38",
            "Block": "206",
            "label": "PUMS VARISAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18873",
            "district": "38",
            "Block": "206",
            "label": "PUMS VEERACHOLAN WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18874",
            "district": "38",
            "Block": "206",
            "label": "GHSS VEERACHOLAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18875",
            "district": "38",
            "Block": "206",
            "label": "PUMS OTTANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18876",
            "district": "38",
            "Block": "206",
            "label": "PUMS MINAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18877",
            "district": "38",
            "Block": "206",
            "label": "PUMS CHELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18878",
            "district": "38",
            "Block": "206",
            "label": "PUMS SEMPONNERUNCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18879",
            "district": "38",
            "Block": "206",
            "label": "PUMS. MUTHANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18880",
            "district": "38",
            "Block": "206",
            "label": "PUMS V ALANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18881",
            "district": "38",
            "Block": "206",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  SULLANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18882",
            "district": "38",
            "Block": "206",
            "label": "GHSS ANAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18883",
            "district": "38",
            "Block": "206",
            "label": "KASTURBA GANDHI BALIKA VIDYALAYA, ANAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18884",
            "district": "38",
            "Block": "206",
            "label": "PUMS RENGAIYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18885",
            "district": "38",
            "Block": "206",
            "label": "GHSS, PANAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18886",
            "district": "38",
            "Block": "206",
            "label": "GHSS ILUPPAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18887",
            "district": "38",
            "Block": "206",
            "label": "PUMS AMMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18888",
            "district": "38",
            "Block": "206",
            "label": "PUMS. UDAYASERVAIKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18889",
            "district": "38",
            "Block": "206",
            "label": "GHS MUTHURAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18890",
            "district": "38",
            "Block": "206",
            "label": "PUMS KUMILANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18891",
            "district": "38",
            "Block": "411",
            "label": "T.E.L.C .M.S.RAMASAMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18892",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S KANSAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18893",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S.NEDUNKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18894",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOOMAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18895",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S SETHUNARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18896",
            "district": "38",
            "Block": "411",
            "label": "Govt A.D.W.MIDDLE SCHOOL AGASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18897",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18898",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MAHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18899",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S.THAMBIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18900",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S MEENAKSHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18901",
            "district": "38",
            "Block": "411",
            "label": "KALAIMAGAL HSS, S.RAMACHANDRAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18902",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18903",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THAILAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18904",
            "district": "38",
            "Block": "411",
            "label": "GHS, AYANKARISALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18905",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, S.AMMAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18906",
            "district": "38",
            "Block": "411",
            "label": "GHS, SEELNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18907",
            "district": "38",
            "Block": "411",
            "label": "R.C.HS.,AYANNATHAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18908",
            "district": "38",
            "Block": "411",
            "label": "P U M S KUNNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18909",
            "district": "38",
            "Block": "411",
            "label": "KAMARAJ M.S, KUNNUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18910",
            "district": "38",
            "Block": "411",
            "label": "HINDU H.S.S.  WATRAP",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18911",
            "district": "38",
            "Block": "411",
            "label": "HINDU (G)H.S.WATRAP",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18912",
            "district": "38",
            "Block": "411",
            "label": "NADAR H.S.S  WATRAP",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18913",
            "district": "38",
            "Block": "411",
            "label": "R.C M.S W.PUDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18914",
            "district": "38",
            "Block": "411",
            "label": "SRI RENUGA HINDU HIGH SCHOOL, W.PUDHUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18915",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SUNDARAPANDIAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18916",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S GOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18917",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S AYYANARPURAM COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18918",
            "district": "38",
            "Block": "295",
            "label": "KAMMA KALVI SANGA HR SEC SCHOOL, SITHURAJAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18919",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S.,BOOVANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18920",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.MATHIYASENAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18921",
            "district": "38",
            "Block": "295",
            "label": "TCH MS, MUTHUKUMARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18922",
            "district": "38",
            "Block": "295",
            "label": "S N (JUNIOR) G.H.S.,VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18923",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S KUMILANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18924",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S.,NADAIYANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18925",
            "district": "38",
            "Block": "295",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RENGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18926",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S., MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18927",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S JAMEENSALVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18928",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S PETHULUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18929",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,ALAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18930",
            "district": "38",
            "Block": "295",
            "label": "GHS VADAMALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18931",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S, CHITHAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18932",
            "district": "38",
            "Block": "295",
            "label": "G.H.S PUDUKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18933",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.VELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18934",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.SENGAMALAMNATCHIYARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18935",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,SUKKIRAVARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18936",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,ANAIKUTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18937",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S. SANARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18938",
            "district": "38",
            "Block": "295",
            "label": "MEADOWS HIGHER SECONDARY SCHOOL, KALAIYARKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18939",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S, MEENAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18940",
            "district": "38",
            "Block": "295",
            "label": "GOVT HIGH SCHOOL, PERAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18941",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,ANUPPANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18942",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.URAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18943",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.VADAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18944",
            "district": "38",
            "Block": "295",
            "label": "AVM MARIMUTHU NADAR HR SEC SCHOOL, VILAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18945",
            "district": "38",
            "Block": "295",
            "label": "GOVT HIGHER SECONDARY SCHOOL, MARANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18946",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S KUPPANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18947",
            "district": "38",
            "Block": "295",
            "label": "VEERAMAMUNIVAR HIGH SCHOOL, KRISHNAPERI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18948",
            "district": "38",
            "Block": "295",
            "label": "KAMAK HIGHER SECONDARY SCHOOL, KRISHNAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18949",
            "district": "38",
            "Block": "295",
            "label": "S.A.V. SALA MIDDLE SCHOOL, MUTHALIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18950",
            "district": "38",
            "Block": "295",
            "label": "SRI KRS. MATRICULATION SCHOOL, VISWANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18951",
            "district": "38",
            "Block": "295",
            "label": "C.M.S. MIDDLE SCHOOL, SATCHIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18952",
            "district": "38",
            "Block": "295",
            "label": "HINDU THEVAMAR HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18953",
            "district": "38",
            "Block": "295",
            "label": "MUNICIPAL HR SEC SCHOOL., AMMANKOVILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18954",
            "district": "38",
            "Block": "295",
            "label": "VELANKANNI MATHA HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18955",
            "district": "38",
            "Block": "295",
            "label": "S.R.,G.H.S.S.,THIRUTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18956",
            "district": "38",
            "Block": "295",
            "label": "A  U  MUNICIPAL HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18957",
            "district": "38",
            "Block": "295",
            "label": "S.H.N. GIRLS HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18958",
            "district": "38",
            "Block": "295",
            "label": "S.N.G. GIRLS HIGHER SECONDARY SCHOOL., THIRUTHANGAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18959",
            "district": "38",
            "Block": "295",
            "label": "CORONATION GIRLS HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18960",
            "district": "38",
            "Block": "295",
            "label": "S.C.M.S.GIRLS HIGHER SECONDARY SCHOOL, SATCHIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18961",
            "district": "38",
            "Block": "295",
            "label": "N.P.A.S.P.R.RATHNAVILAS HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18962",
            "district": "38",
            "Block": "295",
            "label": "SIVAKASI HINDUNADARS GIRLS MIDDLE SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18963",
            "district": "38",
            "Block": "295",
            "label": "MUSLIM HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18964",
            "district": "38",
            "Block": "295",
            "label": "SIVAKASI HINDU NADAR VICTORIA HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18965",
            "district": "38",
            "Block": "295",
            "label": "YRTV MAT HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18966",
            "district": "38",
            "Block": "295",
            "label": "KALAIMAGAL HIGHER SECONDARY SCHOOL, THIRUTHANGAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18967",
            "district": "38",
            "Block": "295",
            "label": "SAFIRE MATRICULATION HIGHER SECONDARY  SCHOOL,SIVAKASI- THAYILPATTI ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18968",
            "district": "38",
            "Block": "295",
            "label": "SHNV MATRIC HSS,SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18969",
            "district": "38",
            "Block": "295",
            "label": "SRUTHI VIDHYODHAYA , SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18970",
            "district": "38",
            "Block": "295",
            "label": "CSI HIGH SCHOOL & VTC FOR THE DEAF, SATCHIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18971",
            "district": "38",
            "Block": "295",
            "label": "CSI M&R SCHOOL, SATCHIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18972",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S.,RESERVE LINE SIVAKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18973",
            "district": "38",
            "Block": "295",
            "label": "GOVT GIRLS HR SEC SCHOOL, THIRUTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18974",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S,VISWANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18975",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S., NARANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18976",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S, PALLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18977",
            "district": "38",
            "Block": "295",
            "label": "ANNAVI THOTTAM MUNICIPAL GIRLS HIGH SCHOOL, SIVAKASI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18978",
            "district": "38",
            "Block": "295",
            "label": "GOVT HIGH SCHOOL ANAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18979",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, CHINNAKKAMANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18980",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, METTAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18981",
            "district": "38",
            "Block": "282",
            "label": "HINDU MIDDLE SCHOOL, VENKATACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18982",
            "district": "38",
            "Block": "282",
            "label": "KAMMA MAHAJANA TRUST GIRLS HIGHER SECONDARY SCHOOL, VENKATACHALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18983",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PADANTHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18984",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AYAN CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18985",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAMBIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18986",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.RAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18987",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.CHOKKALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18988",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MUTHARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18989",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SIRUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18990",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, IRUKKANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18991",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, NATHATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18992",
            "district": "38",
            "Block": "282",
            "label": "S.R.GOVERNMENT HIGHER SECONDARY SCHOOL, N.METTUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18993",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, POTHIREDDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18994",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, PERIYA KOLLAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18995",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, THERKUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18996",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, O.METTUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18997",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, KANMAISURANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18998",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, PUDHUSURANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18999",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, N.SUBBAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19000",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SIVANANAINTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19001",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, THOTTILOVANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19002",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19003",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UPPATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19004",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19005",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, MUTHALNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19006",
            "district": "38",
            "Block": "282",
            "label": "MPL. HIGHER SECONDARY SCHOOL, MELAGANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19007",
            "district": "38",
            "Block": "282",
            "label": "MPL. MIDDLE SCHOOL, SATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19008",
            "district": "38",
            "Block": "282",
            "label": "S. GURUSAMY NADAR KRISHNAMMAL MEMORIAL MIDDLE SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19009",
            "district": "38",
            "Block": "282",
            "label": "S.H.N. EDWARD MIDDLE SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19010",
            "district": "38",
            "Block": "282",
            "label": "T.E.L.C. MIDDLE SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19011",
            "district": "38",
            "Block": "282",
            "label": "ST. THERESA HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19012",
            "district": "38",
            "Block": "282",
            "label": "ST. STANISLAUS HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19013",
            "district": "38",
            "Block": "282",
            "label": "AYIRA VYSIA BOYS HR SEC SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19014",
            "district": "38",
            "Block": "282",
            "label": "S.H.N. ETHEL HARVEY GIRLS HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19015",
            "district": "38",
            "Block": "282",
            "label": "S.H.N. EDWARD HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20977",
            "district": "38",
            "Block": "295",
            "label": "MOUNT LITERA ZEE SCHOOL., SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20978",
            "district": "38",
            "Block": "295",
            "label": "V S K D NADAR GURUKUL MATRIC HR SEC SCHOOL, SATCHIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21034",
            "district": "38",
            "Block": "295",
            "label": "LVR MATRICULATION SCHOOL,SENGAMALANACHIARPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21108",
            "district": "38",
            "Block": "28",
            "label": "CHANDRA NATIONAL SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21109",
            "district": "38",
            "Block": "407",
            "label": "KSHATRIYA VIDHYASALA ENGLISH MEDIUM SCHOOL , KOORAIKUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21110",
            "district": "38",
            "Block": "321",
            "label": "MINERVA PUBLIC SCHOOL, MANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21111",
            "district": "38",
            "Block": "321",
            "label": "SRI VISHWAS VIDHYALAYA SCHOOL, PANNAI MOONDRADAIPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21112",
            "district": "38",
            "Block": "264",
            "label": "ARUMUGAM PALANIGURU MODERN SCHOOL CHATRAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21113",
            "district": "38",
            "Block": "264",
            "label": "SRI RAMANA ACADEMY SENIOR SECONDARY CBSE, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21114",
            "district": "38",
            "Block": "298",
            "label": "SRI MAYURA INTERNATIONAL SCH APPAIYANAYAKAR PATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21115",
            "district": "38",
            "Block": "295",
            "label": "AAA INTERNATIONAL SCHOOL.,THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21170",
            "district": "38",
            "Block": "28",
            "label": "ST.FRANCIS DE SALES MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21171",
            "district": "38",
            "Block": "295",
            "label": "ANITHA MATRICULATION HSS ,KONGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21339",
            "district": "38",
            "Block": "407",
            "label": "RAMCO VIDYALAYA, R.R.NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21340",
            "district": "38",
            "Block": "298",
            "label": "MAHARISHI VIDYA MANDIR SECONDARY SCHOOL - CBSE, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21341",
            "district": "38",
            "Block": "298",
            "label": "SATHYA VIDYALAYA CBSE,PILLAYARKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21342",
            "district": "38",
            "Block": "282",
            "label": "SRIKARA VIDHYA MANDHIR SENIOR SECONDARY SCHOOL, SIVANANAINTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21404",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KOOMAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21405",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,M.PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21406",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,KOPPAIYANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21407",
            "district": "38",
            "Block": "396",
            "label": "G.H.S.,MUTHANDIAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21408",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, N.G.O.COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21409",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, K.METTUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21613",
            "district": "38",
            "Block": "264",
            "label": "KALVI THANTHAI KAMARAJAR VIDHYASALA MATRICULATION SCHOOL, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21650",
            "district": "38",
            "Block": "298",
            "label": "PENNINGTON PUBLIC SCHOOL CBSE, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21853",
            "district": "38",
            "Block": "411",
            "label": "VIVEKANANTHA VIDYA MANDIR.,AKKANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21854",
            "district": "38",
            "Block": "411",
            "label": "LINGA GLOBAL SCHOOL.,KRISHNANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21855",
            "district": "38",
            "Block": "396",
            "label": "SP MODERN MATRICULATION SCHOOL ,SEVALPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21856",
            "district": "38",
            "Block": "396",
            "label": "HAYAGRIVAS INTERNATIONAL SCHOOL.PERNAYAKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21857",
            "district": "38",
            "Block": "295",
            "label": "AYYAVU MATRICULATION HIGH SCHOOL, VADAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21858",
            "district": "38",
            "Block": "282",
            "label": "SOUTHSIDE INTERNATIONAL SCHOOL, METTAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21936",
            "district": "38",
            "Block": "28",
            "label": "R.J.MANTRA ENGLISH SCHOOL,CHINNAVALLIKULAM ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21937",
            "district": "38",
            "Block": "282",
            "label": "GVJ INTERNATIONAL SCHOOL.,N.VENKADESWARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21938",
            "district": "38",
            "Block": "298",
            "label": "SACRED HEART CONVENT HIGH SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22262",
            "district": "38",
            "Block": "28",
            "label": "IQRA MATRICULATION SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22263",
            "district": "38",
            "Block": "28",
            "label": "GREEN WISDOM MATRICULATION SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22264",
            "district": "38",
            "Block": "407",
            "label": "WISDOM WEALTH INTERNATIONAL SCHOOL, MARULOOTHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22265",
            "district": "38",
            "Block": "321",
            "label": "SARADHA VIDYALAYA MATRICULATION SCHOOL,  M.REDDIYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22266",
            "district": "38",
            "Block": "298",
            "label": "SRI AYYAN KENDRA VIDYALAYA INTERNATIONAL SCHOOL, KALANGAPERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22618",
            "district": "38",
            "Block": "407",
            "label": "SHRI VISHWAKARMA SEVA VIDYALAYA, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22619",
            "district": "38",
            "Block": "407",
            "label": "G.H.S.,SENNELKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22620",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGH SCHOOL, SIVAKAMIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22621",
            "district": "38",
            "Block": "264",
            "label": "ST.JOSEPH INTERNATIONAL SCHOOL RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22622",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGH SCHOOL, LAKSHMIAPURAMPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22623",
            "district": "38",
            "Block": "298",
            "label": "YASHAS ENGLISH SCHOOL.,MALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22624",
            "district": "38",
            "Block": "411",
            "label": "G.H.S, THULUKKAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22625",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,SATHYANAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22783",
            "district": "38",
            "Block": "28",
            "label": "KALAIMAGAL VIDHYALAYA, PANDALKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22784",
            "district": "38",
            "Block": "110",
            "label": "SRI SABARI NATIONAL SCHOOL, KARIAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22785",
            "district": "38",
            "Block": "282",
            "label": "M.M VIDYASHRAM, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22786",
            "district": "38",
            "Block": "295",
            "label": "SRI KRISHNASAMY INTERNATIONAL SCHOOL, MARANERI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22787",
            "district": "38",
            "Block": "298",
            "label": "SRIVI LIONS INTERNATIONAL SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22788",
            "district": "38",
            "Block": "298",
            "label": "VPMS SELVI SHANKAR(SESHA) VIDYASRAM SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22902",
            "district": "38",
            "Block": "321",
            "label": "GOVERNMENT HIGH SCHOOL, CHITHALAKUNDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22903",
            "district": "38",
            "Block": "411",
            "label": "GHS, KEELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22904",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, MELAPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23127",
            "district": "38",
            "Block": "264",
            "label": "SRI ANNAI VIDYALAYA MATRICULATION SCHOOL, CHETTIYARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23146",
            "district": "38",
            "Block": "396",
            "label": "MADURAI C.E.O.A MATRICULATION SCHOOL, SALVARPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23185",
            "district": "38",
            "Block": "264",
            "label": "MIRASU VEERAPPA NAIDU VIDYALAYA MATRICULATION SCHOOL, CHETTIYARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23186",
            "district": "38",
            "Block": "264",
            "label": "SIVAKAMI HIGH SCHOOL, RAJAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "23197",
            "district": "38",
            "Block": "411",
            "label": "RANGARAO LIONS MATRICULATION HR. SEC SCHOOL, MAHARAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23290",
            "district": "38",
            "Block": "295",
            "label": "R.S.R INTERNATIONAL SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23295",
            "district": "38",
            "Block": "411",
            "label": "GOVT ADW HIGH SCHOOL, KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23343",
            "district": "38",
            "Block": "407",
            "label": "SFS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "23641",
            "district": "38",
            "Block": "282",
            "label": "LITTLE BUDS UNIQUE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23642",
            "district": "38",
            "Block": "295",
            "label": "GOOD SHEPHERD MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23643",
            "district": "38",
            "Block": "295",
            "label": "SIDHARAJAA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23644",
            "district": "38",
            "Block": "298",
            "label": "TELUGU KULALAR SRI SOOLAI VINAYAGAR VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23645",
            "district": "38",
            "Block": "298",
            "label": "HINDU MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23732",
            "district": "38",
            "Block": "28",
            "label": "SRI VENKATESWARAA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23892",
            "district": "38",
            "Block": "206",
            "label": "PUPS. THIRUMANICKANENTHAL",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23957",
            "district": "38",
            "Block": "282",
            "label": "SATTUR KMT VIDHYALAYA",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23976",
            "district": "38",
            "Block": "298",
            "label": "Virudhunagar District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24084",
            "district": "38",
            "Block": "407",
            "label": "PUMS KUPPAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24085",
            "district": "38",
            "Block": "407",
            "label": "PUMS THADANGAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ]
}

const SCHOOL_BY_BLOCK = {
    "1": [
        {
            "key": "4689",
            "district": "2",
            "Block": "1",
            "label": "ST. JOSEPH'S MATRIC HR. SEC. SCHOOL, ACHARAPAKKAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5679",
            "district": "2",
            "Block": "1",
            "label": "PAMELA MARTINEZ PATHWAY MAT. SCHOOL, AGILI VILLAGE.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8117",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLAPUTHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8118",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THEETTALAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8119",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHITHIRAKOODAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8120",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VEDANTHANGAL.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8121",
            "district": "2",
            "Block": "1",
            "label": "SACRED HEART MATRIC HIGH  SCHOOL, VALAIYAPUTHUR, VEDANTHANGAL.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8122",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUMBAKKAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8123",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, L.ENDATHUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8124",
            "district": "2",
            "Block": "1",
            "label": "ADHI PARASAKTHI  NURSERY AND PRIMARY SCHOOL, L.ENDATHUR.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8125",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KILIYANAGAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8126",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PATHIRI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8127",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, RAMAPURAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8128",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEEZHAMUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8129",
            "district": "2",
            "Block": "1",
            "label": "R.C.HIGHER SECONDARY SCHOOL, THANDALAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8130",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GUDALORE.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8131",
            "district": "2",
            "Block": "1",
            "label": "ALFREDO CUOMO HIGHER SECONDARY SCHOOL, SENDIVAKKAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8132",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTUGUDALUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8133",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, KATTUKARANAI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8134",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MATHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8135",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ELAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8136",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ORATHI.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8137",
            "district": "2",
            "Block": "1",
            "label": "RAINBOW NURSERY AND PRIMARY SCHOOL, ORATHI.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8138",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, VADAMANIPAKKAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8139",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THINNALUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8140",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ANANDHAMANGALAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8141",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONGARAIMAMPATTU.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8142",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KALATHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8143",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEEZHATHIVAKKAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8144",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MINNAL CHITHAMUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8145",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, NEDUNGAL.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8146",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MURUNGAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8147",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELIYAMPAKKAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8148",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  ATHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8149",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THOZHUPEDU.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8150",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADAMALAIPUTHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8151",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUMBERKANDIGAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8152",
            "district": "2",
            "Block": "1",
            "label": "DR. JACOBS HITECH SCHOOL, VILANGADU.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8153",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGH SCHOOL, THIMMAPURAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8154",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEETHAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8155",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UNAMALAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8156",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MORAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8157",
            "district": "2",
            "Block": "1",
            "label": "MARVAR GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, ACHARAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8158",
            "district": "2",
            "Block": "1",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, ACHARAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8159",
            "district": "2",
            "Block": "1",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VENGATESAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23430",
            "district": "2",
            "Block": "1",
            "label": "AALAM INTERNATIONAL SCHOOL, THOZHUPEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23430",
            "district": "2",
            "Block": "1",
            "label": "AALAM INTERNATIONAL SCHOOL, THOZHUPEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23430",
            "district": "2",
            "Block": "1",
            "label": "AALAM INTERNATIONAL SCHOOL, THOZHUPEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23430",
            "district": "2",
            "Block": "1",
            "label": "AALAM INTERNATIONAL SCHOOL, THOZHUPEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "2": [
        {
            "key": "392",
            "district": "3",
            "Block": "2",
            "label": "ALL ANGELS MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23648",
            "district": "3",
            "Block": "2",
            "label": "Manthan Vidyashram",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "434",
            "district": "3",
            "Block": "2",
            "label": "ST PATRICKS AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "486",
            "district": "3",
            "Block": "2",
            "label": "FATHIMA CENTRAL SENIOR SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "490",
            "district": "3",
            "Block": "2",
            "label": "VANAVANI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "518",
            "district": "3",
            "Block": "2",
            "label": "ANNAI VELANKANNI MATRICULATION HIGHER SECONDARY SCHOOL HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "597",
            "district": "3",
            "Block": "2",
            "label": "SANKARA SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "763",
            "district": "3",
            "Block": "2",
            "label": "MAK MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "975",
            "district": "3",
            "Block": "2",
            "label": "INFANT JESUS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1461",
            "district": "3",
            "Block": "2",
            "label": "ST FRANCIS SAVIO MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1758",
            "district": "3",
            "Block": "2",
            "label": "GHSS MODEL SAIDAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1840",
            "district": "3",
            "Block": "2",
            "label": "OXFORD MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2023",
            "district": "3",
            "Block": "2",
            "label": "Anna Gem Science Park Mat HSS.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2034",
            "district": "3",
            "Block": "2",
            "label": "KUMARARANI MEENA MUTHIAH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2190",
            "district": "3",
            "Block": "2",
            "label": "GURU NANAK MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2729",
            "district": "3",
            "Block": "2",
            "label": "ALPHA MATRICULATION  HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3072",
            "district": "3",
            "Block": "2",
            "label": "CMS MGR NAGAR II",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3113",
            "district": "3",
            "Block": "2",
            "label": "THIRUVALLUVAR GURUKULAM MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3141",
            "district": "3",
            "Block": "2",
            "label": "SRI SARADA MIDDLE SCHOOL WEST MAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3314",
            "district": "3",
            "Block": "2",
            "label": "ANNAI THERESA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3350",
            "district": "3",
            "Block": "2",
            "label": "C.ANNAPOORANI AMMAL M S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3355",
            "district": "3",
            "Block": "2",
            "label": "SHRI A GANESAN MAT HR SEC SCHOOL, VELACHERY.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3412",
            "district": "3",
            "Block": "2",
            "label": "ST MARYS MATRICULATION  HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3832",
            "district": "3",
            "Block": "2",
            "label": "SIVASAKTHI MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3958",
            "district": "3",
            "Block": "2",
            "label": "CAMBRRIDGE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23411",
            "district": "3",
            "Block": "2",
            "label": "nehru high school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4108",
            "district": "3",
            "Block": "2",
            "label": "CMS METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4113",
            "district": "3",
            "Block": "2",
            "label": "A.C. Middle School Kanagam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4134",
            "district": "3",
            "Block": "2",
            "label": "GANAPATHY NATIONAL MIDDLE SCHOOL, SAIDAPET, CH-15.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4180",
            "district": "3",
            "Block": "2",
            "label": "CMS CART MAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4205",
            "district": "3",
            "Block": "2",
            "label": "BETHEL MAT HSS VELACHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4231",
            "district": "3",
            "Block": "2",
            "label": "GEETHA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4637",
            "district": "3",
            "Block": "2",
            "label": "CSI M SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4655",
            "district": "3",
            "Block": "2",
            "label": "CMS THIDEER NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4701",
            "district": "3",
            "Block": "2",
            "label": "BETA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4707",
            "district": "3",
            "Block": "2",
            "label": "ECI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5313",
            "district": "3",
            "Block": "2",
            "label": "ADVENT CHRISTIAN MIDDLE SCHOOL,  THIRUVANMIYUR, CH-41.",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5659",
            "district": "3",
            "Block": "2",
            "label": "RBI STAFF QUARTERS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6071",
            "district": "3",
            "Block": "2",
            "label": "THE ASHRAM MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6088",
            "district": "3",
            "Block": "2",
            "label": "THE BESANT THEOSOPHICAL HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6548",
            "district": "3",
            "Block": "2",
            "label": "A.C MIDDLE SCHOOL GUINDY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7463",
            "district": "3",
            "Block": "2",
            "label": "GHSS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7464",
            "district": "3",
            "Block": "2",
            "label": "CHSS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7465",
            "district": "3",
            "Block": "2",
            "label": "CHS JAFFERKHANPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7466",
            "district": "3",
            "Block": "2",
            "label": "CHSS WEST MAMBALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7467",
            "district": "3",
            "Block": "2",
            "label": "SRI AHOBILA MATH ORIENTAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7468",
            "district": "3",
            "Block": "2",
            "label": "CHSS BOYS SAIDAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7469",
            "district": "3",
            "Block": "2",
            "label": "CHSS GIRLS SAIDAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7470",
            "district": "3",
            "Block": "2",
            "label": "VIDYA SAGAR B.M.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7471",
            "district": "3",
            "Block": "2",
            "label": "CHSS CIT NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7472",
            "district": "3",
            "Block": "2",
            "label": "ADVENT CHRISTIAN HIGH SCHOOL, KOTTUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7473",
            "district": "3",
            "Block": "2",
            "label": "AMM MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7474",
            "district": "3",
            "Block": "2",
            "label": "KENDRIYA VIDYALAYA, IIT",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7475",
            "district": "3",
            "Block": "2",
            "label": "KENDRIYA VIDYALAYA, CLRI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7476",
            "district": "3",
            "Block": "2",
            "label": "CHENNAI HIGH SCHOOL, KOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7477",
            "district": "3",
            "Block": "2",
            "label": "JAIGOPAL GARODIA GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SAIDAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7478",
            "district": "3",
            "Block": "2",
            "label": "ST ANTONYS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7479",
            "district": "3",
            "Block": "2",
            "label": "OUR LADY OF HEALTH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7480",
            "district": "3",
            "Block": "2",
            "label": "YMCA COLLEGE SPORTS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7481",
            "district": "3",
            "Block": "2",
            "label": "ST. FRANCIS XAVIER HIGH SCHOOL, LITTLEMOUNT,CHENNAI-15",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7482",
            "district": "3",
            "Block": "2",
            "label": "CHRISTIAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7483",
            "district": "3",
            "Block": "2",
            "label": "LIONS CLUB OF GUINDY LABOUR COLONY HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7484",
            "district": "3",
            "Block": "2",
            "label": "CHSS MADUVANKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7485",
            "district": "3",
            "Block": "2",
            "label": "LADY NYE MRT-1 SPECIAL  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7486",
            "district": "3",
            "Block": "2",
            "label": "ARSHA VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7487",
            "district": "3",
            "Block": "2",
            "label": "THE ASHRAM TASSC MODEL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7488",
            "district": "3",
            "Block": "2",
            "label": "ALPHA SCHOOL CIT NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7489",
            "district": "3",
            "Block": "2",
            "label": "AVVAI ILLAM T.V.R GIRLS' HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7490",
            "district": "3",
            "Block": "2",
            "label": "RANI LADY MEYYAMMAI HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7491",
            "district": "3",
            "Block": "2",
            "label": "KUMARA RAJAH MUTHIAH BOYS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7492",
            "district": "3",
            "Block": "2",
            "label": "BALA VIDYA MANDIR ADYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7493",
            "district": "3",
            "Block": "2",
            "label": "ST MICHAELS ACADEMY MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7494",
            "district": "3",
            "Block": "2",
            "label": "ST LOUIS INSTITUTE FOR THE DEAF AND THE BLIND",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7495",
            "district": "3",
            "Block": "2",
            "label": "GHSS VELACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7496",
            "district": "3",
            "Block": "2",
            "label": "ARIGNAR ANNA GOVT HSS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7497",
            "district": "3",
            "Block": "2",
            "label": "ADVENT CHRISTIAN  HIGH  SCHOOL, VELACHERY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7498",
            "district": "3",
            "Block": "2",
            "label": "ST ANTONY'S HIGHER SECONDARY SCHOOL VELACHERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7499",
            "district": "3",
            "Block": "2",
            "label": "ST MARK'S HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21137",
            "district": "3",
            "Block": "2",
            "label": "UNITY PUBLIC SCHOOL KOTTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21809",
            "district": "3",
            "Block": "2",
            "label": "ST. MICHAEL'S  ACADEMY SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22014",
            "district": "3",
            "Block": "2",
            "label": "DAVe BABA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22015",
            "district": "3",
            "Block": "2",
            "label": "ST.PATRICK'S HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22016",
            "district": "3",
            "Block": "2",
            "label": "AGR GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23021",
            "district": "3",
            "Block": "2",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23447",
            "district": "3",
            "Block": "2",
            "label": "A.M.M SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23448",
            "district": "3",
            "Block": "2",
            "label": "Centre of Academic Excellence Saidapet, Chennai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23449",
            "district": "3",
            "Block": "2",
            "label": "K.S.N SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23447",
            "district": "3",
            "Block": "2",
            "label": "A.M.M SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23448",
            "district": "3",
            "Block": "2",
            "label": "Centre of Academic Excellence Saidapet, Chennai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23449",
            "district": "3",
            "Block": "2",
            "label": "K.S.N SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23447",
            "district": "3",
            "Block": "2",
            "label": "A.M.M SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23448",
            "district": "3",
            "Block": "2",
            "label": "Centre of Academic Excellence Saidapet, Chennai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23449",
            "district": "3",
            "Block": "2",
            "label": "K.S.N SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23447",
            "district": "3",
            "Block": "2",
            "label": "A.M.M SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23448",
            "district": "3",
            "Block": "2",
            "label": "Centre of Academic Excellence Saidapet, Chennai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23449",
            "district": "3",
            "Block": "2",
            "label": "K.S.N SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23704",
            "district": "3",
            "Block": "2",
            "label": "ECI MAT HSS",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23705",
            "district": "3",
            "Block": "2",
            "label": "CHENNAI MIDDLE SCHOOL  MADIPAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23706",
            "district": "3",
            "Block": "2",
            "label": "THE PSBB MILLENNIUM SCHOOL Thazhambur",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23707",
            "district": "3",
            "Block": "2",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23708",
            "district": "3",
            "Block": "2",
            "label": "NARAYANA OLYMPIAD SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23709",
            "district": "3",
            "Block": "2",
            "label": "VELS GLOBAL SCHOOL NANDAMBAKKAM",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23710",
            "district": "3",
            "Block": "2",
            "label": "SAN ACDEMY",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "3": [
        {
            "key": "23348",
            "district": "11",
            "Block": "3",
            "label": "Bharathi Vidhya Mandir Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "551",
            "district": "11",
            "Block": "3",
            "label": "GNANADEEPAM MATRIC HIGHER SECONDARY SCHOOL, AGASTHEESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "594",
            "district": "11",
            "Block": "3",
            "label": "CARMEL MATHA MATRIC Hr.Sec.SCHOOL - KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "596",
            "district": "11",
            "Block": "3",
            "label": "E.D.WILLMOTT MEMORIAL S.D.A.MATRIC HSS VETTOORNIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "777",
            "district": "11",
            "Block": "3",
            "label": "PIONEER HSS NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "819",
            "district": "11",
            "Block": "3",
            "label": "S.M.MATRIC SCHOOL,MYLAUDY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "829",
            "district": "11",
            "Block": "3",
            "label": "VIVE.KEN.MAT. HSS-KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "897",
            "district": "11",
            "Block": "3",
            "label": "KUMARI MATRICULATION HR. SEC. SCHOOL, KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "997",
            "district": "11",
            "Block": "3",
            "label": "SNM HINDU VIDYALAYA MATRIC HSS, KRISHNANCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1331",
            "district": "11",
            "Block": "3",
            "label": "PONJESLY PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL - NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1410",
            "district": "11",
            "Block": "3",
            "label": "SACRED HEART MATRIC HR SEC SCHOOL- AZHAGAPPAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1569",
            "district": "11",
            "Block": "3",
            "label": "MALICK DHINAR MATRICULATION  SCHOOL- KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1625",
            "district": "11",
            "Block": "3",
            "label": "ST TRESA'S MATRIC HR.SEC.SCHOOL- VAZHUKKAMPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1845",
            "district": "11",
            "Block": "3",
            "label": "FATIMA CONVENT MATRICULATION SCHOOL-RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1943",
            "district": "11",
            "Block": "3",
            "label": "ROCK FHELLAR MATRIC.SCHOOL,VADASERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1950",
            "district": "11",
            "Block": "3",
            "label": "SREE NARAYANA GURU MHSS KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2062",
            "district": "11",
            "Block": "3",
            "label": "ALPHONSA MATRICULATION HIGHER SECONDARY SCHOOL- KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2147",
            "district": "11",
            "Block": "3",
            "label": "GOOD SHEPHERD. MATRICULATION HIGHER SECONDARY SCHOOL - NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2410",
            "district": "11",
            "Block": "3",
            "label": "ST.FRANCIS MATRIC SCHOOL- SARAKKALVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2727",
            "district": "11",
            "Block": "3",
            "label": "GMS DEVAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2765",
            "district": "11",
            "Block": "3",
            "label": "DVD HSS KOTTAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2954",
            "district": "11",
            "Block": "3",
            "label": "GMS VATTAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2974",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT MIDDLE SCHOOL  SUCHINDRUM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3677",
            "district": "11",
            "Block": "3",
            "label": "GMS KRISHNANCOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3803",
            "district": "11",
            "Block": "3",
            "label": "ST.JOSEPH'S MATRIC HR.SEC SCHOOL - NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3833",
            "district": "11",
            "Block": "3",
            "label": "KASTURBA MIDDLE SCHOOL-THEROOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3841",
            "district": "11",
            "Block": "3",
            "label": "GMS KALIANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3950",
            "district": "11",
            "Block": "3",
            "label": "ALPHA MATRIC  HSS KRISHNANCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3976",
            "district": "11",
            "Block": "3",
            "label": "HEBRON MATRIC.HIGHER SEC.SCHOOL-PAVALAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4239",
            "district": "11",
            "Block": "3",
            "label": "GMS MARAVANKUDIERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4771",
            "district": "11",
            "Block": "3",
            "label": "DVD MATRIC HIGH SCHOOL-NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4837",
            "district": "11",
            "Block": "3",
            "label": "CSI MAT.HSS ,JOSUWA STREET -NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5023",
            "district": "11",
            "Block": "3",
            "label": "ST. TRESA'S MATRIC  SCHOOL  KOTTAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5280",
            "district": "11",
            "Block": "3",
            "label": "GMS POTTAIYADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5970",
            "district": "11",
            "Block": "3",
            "label": "AROCKIA ANNAI M.S-AROCKIAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6095",
            "district": "11",
            "Block": "3",
            "label": "St. STELLA'S MATRICULATION HR.SEC.SCHOOL  ANJUGRAMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6654",
            "district": "11",
            "Block": "3",
            "label": "THE RAJAS INTERNATIONAL SCHOOL,OZHUGINANASERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20093",
            "district": "11",
            "Block": "3",
            "label": "GOVT HR SEC SCHOOL  OTTAYALVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20094",
            "district": "11",
            "Block": "3",
            "label": "GHS MAHADANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20095",
            "district": "11",
            "Block": "3",
            "label": "MARY IMMACULATE HS KANYAKUMARI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20096",
            "district": "11",
            "Block": "3",
            "label": "ST ANTONY'S HSS KANYAKUMARI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20097",
            "district": "11",
            "Block": "3",
            "label": "KANYAKUMARI PUBLIC SCHOOL- KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20098",
            "district": "11",
            "Block": "3",
            "label": "ST. JOSEPH CALASANZ SCHOOL- KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20099",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL ELANTHAYADIVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20100",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, AGASTHEESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20101",
            "district": "11",
            "Block": "3",
            "label": "ST.IGNATIOUS H.S,KOVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20102",
            "district": "11",
            "Block": "3",
            "label": "AMRITA VIDYALAYAM  KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20103",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL  NORTH THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20104",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL KOTTAIYADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20105",
            "district": "11",
            "Block": "3",
            "label": "LMS.H.S.S,SOUTH THAMARAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20106",
            "district": "11",
            "Block": "3",
            "label": "GOOD SHEPHERED MATRIC Hr.Sec. SCHOOL, THERIVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20107",
            "district": "11",
            "Block": "3",
            "label": "SIVA MATRIC HIGHER SECONDARY SCHOOL CHITHENKUDIYIRUPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20108",
            "district": "11",
            "Block": "3",
            "label": "GHS KULASEKARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20109",
            "district": "11",
            "Block": "3",
            "label": "RINGLETAUBE HSS MYLAUDY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20110",
            "district": "11",
            "Block": "3",
            "label": "MOSES MATRIC SCHOOL - MYLAUDY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20111",
            "district": "11",
            "Block": "3",
            "label": "GHSS KUMARAPURAM THOPOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20112",
            "district": "11",
            "Block": "3",
            "label": "GHS RAMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20113",
            "district": "11",
            "Block": "3",
            "label": "GOVT.HIGH SCHOOL , NALLOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20114",
            "district": "11",
            "Block": "3",
            "label": "PEARL MATRIC HIGHER SEC.SCHOOL -THEREKALPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20115",
            "district": "11",
            "Block": "3",
            "label": "ISHA VIDHYA MATRICULATION HIGHER SECONDARY SCHOOL-THEROOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20116",
            "district": "11",
            "Block": "3",
            "label": "GHS ERAVIPUTHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20117",
            "district": "11",
            "Block": "3",
            "label": "S.M.S.M.HSS-SUCHINDRUM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20118",
            "district": "11",
            "Block": "3",
            "label": "GMS POTTALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20119",
            "district": "11",
            "Block": "3",
            "label": "GHS VARIYOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20120",
            "district": "11",
            "Block": "3",
            "label": "ST.JOSEPH'S (G) HS-AZHAGAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20121",
            "district": "11",
            "Block": "3",
            "label": "LMS HSS JAMESTOWN",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20122",
            "district": "11",
            "Block": "3",
            "label": "ST.ANTONY'S HSS AZHAGAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20123",
            "district": "11",
            "Block": "3",
            "label": "GHS KANAGAPPAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20124",
            "district": "11",
            "Block": "3",
            "label": "CHRIST CMI CENTRAL SCHOOL-ANJUGRAMAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20125",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL,  EACHENVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20126",
            "district": "11",
            "Block": "3",
            "label": "GHSS KOTTARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20127",
            "district": "11",
            "Block": "3",
            "label": "CSI HS  FOR  THE DEAF KOTTARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20128",
            "district": "11",
            "Block": "3",
            "label": "Dr .ABDULKALAM INTERNATIONAL SCHOOL  & RESEARCH  ACADEMY,PERIAVIAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20129",
            "district": "11",
            "Block": "3",
            "label": "ANBU ILLAM HR.SEC.SCHOOL FOR SPEACH & HEARING IMPAIRED- NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20130",
            "district": "11",
            "Block": "3",
            "label": "SALVATION ARMY HSS,VETTOORNIMADAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20131",
            "district": "11",
            "Block": "3",
            "label": "SALVATION ARMY MATRIC HSS ,VETTOORNIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20132",
            "district": "11",
            "Block": "3",
            "label": "ADARSH VIDYA KENDRA-NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20133",
            "district": "11",
            "Block": "3",
            "label": "SMRV HR.SEC.SCHOOL,VADASERY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20134",
            "district": "11",
            "Block": "3",
            "label": "GHSS VADASERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20135",
            "district": "11",
            "Block": "3",
            "label": "S.M.R.V. MATRICULATION  SCHOOL , VADASERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20136",
            "district": "11",
            "Block": "3",
            "label": "ST. XAVIER 'S HS KOTTAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20137",
            "district": "11",
            "Block": "3",
            "label": "GOVERNMENT HIGH SCHOOL EZHAKARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20138",
            "district": "11",
            "Block": "3",
            "label": "SADAVADANI S.P G.HSS-EDALAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20139",
            "district": "11",
            "Block": "3",
            "label": "ST. MICHAEL'S HIGH SCHOOL VETHAKARANKUDIYIRUPPU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20140",
            "district": "11",
            "Block": "3",
            "label": "GHS IRULLAPPAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20141",
            "district": "11",
            "Block": "3",
            "label": "GHSS VALLANKUMARANVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20142",
            "district": "11",
            "Block": "3",
            "label": "BISHOP AROCKIASAMY HR.SEC.SCHOOL- MARAVANKUDIERUPPU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20143",
            "district": "11",
            "Block": "3",
            "label": "CREATIVE MATRIC SCHOOL, KEEZHA MARAVANKUDIYERUPPU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20144",
            "district": "11",
            "Block": "3",
            "label": "GHS MELASOORANKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20145",
            "district": "11",
            "Block": "3",
            "label": "ST. ANTONY 'S HSS, KURUSADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20146",
            "district": "11",
            "Block": "3",
            "label": "CONCORDIA HIGH SCHOOL NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20147",
            "district": "11",
            "Block": "3",
            "label": "IELC PUBLIC SCHOOL NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20148",
            "district": "11",
            "Block": "3",
            "label": "St.ALOYSIUS HSS - CHAYAKKARANPARAPPUVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20149",
            "district": "11",
            "Block": "3",
            "label": "KENDRIYA VIDYALAYA NAGERCOIL",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20150",
            "district": "11",
            "Block": "3",
            "label": "SCOTT CHRISTIAN HSS-NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20151",
            "district": "11",
            "Block": "3",
            "label": "ORAL HR SEC SCHOOL FOR THE HEARING IMPAIRED NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20152",
            "district": "11",
            "Block": "3",
            "label": "GHS NESAVALAR ST VADASERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20153",
            "district": "11",
            "Block": "3",
            "label": "K.N.S.K.GHS OZHUGINASERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20154",
            "district": "11",
            "Block": "3",
            "label": "GHS MEENAKSHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20155",
            "district": "11",
            "Block": "3",
            "label": "ST.JOSEPH 'S CONVENT HSS ,NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20156",
            "district": "11",
            "Block": "3",
            "label": "PHILIPS MATRIC SCHOOL, NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20157",
            "district": "11",
            "Block": "3",
            "label": "KDVP GOVT GIRLS MODEL HR SEC SCHOOL, KOTTAR, NAGERCOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20158",
            "district": "11",
            "Block": "3",
            "label": "SLB GOVT.(G) HIGH SCHOOL -NAGERCOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20159",
            "district": "11",
            "Block": "3",
            "label": "St..FRANCIS  HSS,  NAGERCOIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20160",
            "district": "11",
            "Block": "3",
            "label": "GHS VADALIVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20161",
            "district": "11",
            "Block": "3",
            "label": "LITTLE FLOWER (G ) HR .SEC.SCHOOL -RAMANPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20162",
            "district": "11",
            "Block": "3",
            "label": "CARMEL HR.SEC.SCHOOL RAMANPUTHUR ,NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20163",
            "district": "11",
            "Block": "3",
            "label": "SLB GOVT.HR.SEC.SCHOOL-NAGERCOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20164",
            "district": "11",
            "Block": "3",
            "label": "HOME CHURCH GIRLS HIGH SCHOOL - NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20165",
            "district": "11",
            "Block": "3",
            "label": "DUTHIE GIRLS HR.SE.SCHOOL -NAGERCOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20906",
            "district": "11",
            "Block": "3",
            "label": "JOHNS CENTRAL SCHOOL ANJUGRAMAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20921",
            "district": "11",
            "Block": "3",
            "label": "SHEFFIELD INNOVATIVE CBSE SCHOOL ,IRULAPPAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20980",
            "district": "11",
            "Block": "3",
            "label": "MOUNT LITERA ZEE SCHOOL-MYLAUDY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20981",
            "district": "11",
            "Block": "3",
            "label": "OASIS SPECIAL SCHOOL-CHETTIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21875",
            "district": "11",
            "Block": "3",
            "label": "DONGUANELLA SPECIALSCHOOL FOR M.C-S.THAMARAIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21902",
            "district": "11",
            "Block": "3",
            "label": "AVILA SPECIAL SCHOOL-KANYAKUMARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21903",
            "district": "11",
            "Block": "3",
            "label": "BISHOP REMIGIUS SCHOOL. CBSE NAGERCOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22285",
            "district": "11",
            "Block": "3",
            "label": "St.MICHAEL'S HIGH SCHOOL - RAJAVOOR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22770",
            "district": "11",
            "Block": "3",
            "label": "AROCKIA ANNAI ACADEMY (CBSE) SCHOOL-KOTTAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23187",
            "district": "11",
            "Block": "3",
            "label": "AVATAR INTERNATIONAL MODEL SCHOOL -VALAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23711",
            "district": "11",
            "Block": "3",
            "label": "SANTHIDAM BETHANIA MATRICULATION, KANYAKUMARI",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "4": [
        {
            "key": "2566",
            "district": "14",
            "Block": "4",
            "label": "ROYAL MAT SCHOOL THANICHIYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17828",
            "district": "14",
            "Block": "4",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEMBUKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17829",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, T.AYYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17830",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, KONDAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17831",
            "district": "14",
            "Block": "4",
            "label": "PUMS KOIL KUTTI NAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17832",
            "district": "14",
            "Block": "4",
            "label": "PUMS ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17833",
            "district": "14",
            "Block": "4",
            "label": "PUMS A PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17834",
            "district": "14",
            "Block": "4",
            "label": "PUMS KURAVANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17835",
            "district": "14",
            "Block": "4",
            "label": "GOVT BOYS HR SEC SCHOOL, ALANGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17836",
            "district": "14",
            "Block": "4",
            "label": "GOVT GIRLS HR SEC SCHOOL, ALANGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17837",
            "district": "14",
            "Block": "4",
            "label": "PUMS KOTTAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17838",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, MANIYANJI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17839",
            "district": "14",
            "Block": "4",
            "label": "PUMS THANDALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17840",
            "district": "14",
            "Block": "4",
            "label": "GOVT ADW HR SEC SCHOOL, ACHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17841",
            "district": "14",
            "Block": "4",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VAVIDAMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17842",
            "district": "14",
            "Block": "4",
            "label": "NATIONAL CO-OP SUGAR MILLS HR SEC SCHOOL, 15B METTUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17843",
            "district": "14",
            "Block": "4",
            "label": "PUMS URSERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17844",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, ERRAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17845",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, MUDUVARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17846",
            "district": "14",
            "Block": "4",
            "label": "PUMS MANICKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17847",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, VELLAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17848",
            "district": "14",
            "Block": "4",
            "label": "PUMS SARANTHANGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17849",
            "district": "14",
            "Block": "4",
            "label": "PUMS KODANGIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17850",
            "district": "14",
            "Block": "4",
            "label": "PUMS PONDUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17851",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, SENTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17852",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, CHATRAVALLALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17853",
            "district": "14",
            "Block": "4",
            "label": "GOVT MODEL HR SEC SCHOOL, PALAMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17854",
            "district": "14",
            "Block": "4",
            "label": "P.H.N.U.S.P PATHIRAKALIAMMAN MATRIC HR SEC SCHOOL, PALAMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17855",
            "district": "14",
            "Block": "4",
            "label": "PUMS THETHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17856",
            "district": "14",
            "Block": "4",
            "label": "PUMS T.KOZHINJIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17857",
            "district": "14",
            "Block": "4",
            "label": "GOVT HR SEC SCHOOL, T METTUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17858",
            "district": "14",
            "Block": "4",
            "label": "PUMS RAMAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17859",
            "district": "14",
            "Block": "4",
            "label": "PUMS VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17860",
            "district": "14",
            "Block": "4",
            "label": "PUMS DEVASERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22597",
            "district": "14",
            "Block": "4",
            "label": "THAAI MATRIC HR SEC SCHOOL, ALANGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22893",
            "district": "14",
            "Block": "4",
            "label": "GOVT HIGH SCHOOL, AYYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23712",
            "district": "14",
            "Block": "4",
            "label": "PUMS KOTTAIMEDU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23713",
            "district": "14",
            "Block": "4",
            "label": "PUMS THANDALAI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23714",
            "district": "14",
            "Block": "4",
            "label": "PUMS MANICKAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "5": [
        {
            "key": "1897",
            "district": "31",
            "Block": "5",
            "label": "PUMS VILLANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2252",
            "district": "31",
            "Block": "5",
            "label": "SAINT FRANCIS DE SALES MATRIC HSS, ALANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2700",
            "district": "31",
            "Block": "5",
            "label": "PUMS, NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2808",
            "district": "31",
            "Block": "5",
            "label": "PUMS PUDUR MARIMANIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3211",
            "district": "31",
            "Block": "5",
            "label": "BHARATH MATRIC HSS, ALANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4077",
            "district": "31",
            "Block": "5",
            "label": "PUMS KURUMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4141",
            "district": "31",
            "Block": "5",
            "label": "PUMS, PALLIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4407",
            "district": "31",
            "Block": "5",
            "label": "BRITE MATRIC SCHOOL, MADANANCHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4574",
            "district": "31",
            "Block": "5",
            "label": "PUMS, MATHAKADAPPA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4690",
            "district": "31",
            "Block": "5",
            "label": "PUMS, INDIRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5104",
            "district": "31",
            "Block": "5",
            "label": "PUUBMS, JAFFARABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5515",
            "district": "31",
            "Block": "5",
            "label": "PUMS OMAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5895",
            "district": "31",
            "Block": "5",
            "label": "PUMS, OLD VANIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6201",
            "district": "31",
            "Block": "5",
            "label": "PUMS, PATTHAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6435",
            "district": "31",
            "Block": "5",
            "label": "PUMS, VALAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8882",
            "district": "31",
            "Block": "5",
            "label": "GHS GOLLAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8943",
            "district": "31",
            "Block": "5",
            "label": "GHS MADANANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8953",
            "district": "31",
            "Block": "5",
            "label": "GHSS GIRISAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8954",
            "district": "31",
            "Block": "5",
            "label": "SRI VIVEKANANDA MATRIC SCHOOL , VELLAKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8955",
            "district": "31",
            "Block": "5",
            "label": "GHSS VELLAKUTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8956",
            "district": "31",
            "Block": "5",
            "label": "GHSS VALLIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8957",
            "district": "31",
            "Block": "5",
            "label": "GHS PERIYAKURUMBTHERU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8958",
            "district": "31",
            "Block": "5",
            "label": "GHS KOTHAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8959",
            "district": "31",
            "Block": "5",
            "label": "GHSS NIMMIYAMBATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8960",
            "district": "31",
            "Block": "5",
            "label": "PUUGMS, JAFFARABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8961",
            "district": "31",
            "Block": "5",
            "label": "ADW HR.SEC SCHOOL ALANGAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8962",
            "district": "31",
            "Block": "5",
            "label": "GGHSS ALANGAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8963",
            "district": "31",
            "Block": "5",
            "label": "BRINTHAVAN MATRIC SCHOOL, ALANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8964",
            "district": "31",
            "Block": "5",
            "label": "PUMS, KALLARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8965",
            "district": "31",
            "Block": "5",
            "label": "GHSS MALAIREDDIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8967",
            "district": "31",
            "Block": "5",
            "label": "PUMS CHINDAGAMANIPENDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9072",
            "district": "31",
            "Block": "5",
            "label": "PUMS BALAPPALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9073",
            "district": "31",
            "Block": "5",
            "label": "GOVT HR. SEC. SCHOOL POONGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9074",
            "district": "31",
            "Block": "5",
            "label": "GOVT HS MITTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9075",
            "district": "31",
            "Block": "5",
            "label": "GOVT HR SEC SCHOOL MITTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21434",
            "district": "31",
            "Block": "5",
            "label": "GHS KANAVAIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21435",
            "district": "31",
            "Block": "5",
            "label": "GGHS NIMMIYAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21679",
            "district": "31",
            "Block": "5",
            "label": "JAYA VAASAVI MATRIC.HR.SEC.SCHOOL, ALANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22070",
            "district": "31",
            "Block": "5",
            "label": "GOVT (B) HR SEC SCHOOL  ALANGAYAM  ,TIRUPATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22394",
            "district": "31",
            "Block": "5",
            "label": "KALAM VIDYALAYA MATRICULATION SCHOOL, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22395",
            "district": "31",
            "Block": "5",
            "label": "GOVT HIGH SCHOOL B.NAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "6": [
        {
            "key": "730",
            "district": "24",
            "Block": "6",
            "label": "SRI RAMAKRISHNA MATRIC. HR. SEC. SCHOOL - ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1200",
            "district": "24",
            "Block": "6",
            "label": "St. Joseph Mat. HSS, Alangulam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2480",
            "district": "24",
            "Block": "6",
            "label": "PUMS, Ayyanarkulam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2495",
            "district": "24",
            "Block": "6",
            "label": "R.C. MS, Uthumalai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2527",
            "district": "24",
            "Block": "6",
            "label": "JEEVA MONTESSORI MATRIC HR. SEC. SCHOOL,ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2560",
            "district": "24",
            "Block": "6",
            "label": "Harijan MS, Keelakalangal",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2913",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS, Alangulam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3426",
            "district": "24",
            "Block": "6",
            "label": "RC MS, Thuthikulam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3691",
            "district": "24",
            "Block": "6",
            "label": "R.C. MS, Mariathaipuram",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3771",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS, Solaiseri",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4170",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS, Parankuntrapuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4171",
            "district": "24",
            "Block": "6",
            "label": "NMM MS, Kidarakulam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4406",
            "district": "24",
            "Block": "6",
            "label": "Harijan MS, Kankanankinaru",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4523",
            "district": "24",
            "Block": "6",
            "label": "Sri Shanmuga MS, Melamaruthappapuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4721",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS  ALWANTHULUKKAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4921",
            "district": "24",
            "Block": "6",
            "label": "PUMS, Annamalaiputhur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4940",
            "district": "24",
            "Block": "6",
            "label": "PUMS, Muthammalpuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5321",
            "district": "24",
            "Block": "6",
            "label": "Sri Mahalingam MS, Marukalankulam",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5501",
            "district": "24",
            "Block": "6",
            "label": "CMS Arunothaya MS, Nallur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6096",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS, Karuvantha",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6163",
            "district": "24",
            "Block": "6",
            "label": "HINDU THIRU MURUGAN MS PUTHUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6537",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS ODAIMARICHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6677",
            "district": "24",
            "Block": "6",
            "label": "A.V.NAYAGAM MIDDLE SCHOOL, UTHUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19598",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Alangulam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19599",
            "district": "24",
            "Block": "6",
            "label": "HINDU HIGHER SECONDARY SCHOOL, MAYAMANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19600",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Nettur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19601",
            "district": "24",
            "Block": "6",
            "label": "SRI VAIDHYALINGA SWAMY HIGH SCHOOL - ALADIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19602",
            "district": "24",
            "Block": "6",
            "label": "West Tirunelveli HSS, Nallur",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19603",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Maranthai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19604",
            "district": "24",
            "Block": "6",
            "label": "St. ASSISI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19605",
            "district": "24",
            "Block": "6",
            "label": "Muslim HS, Veeranam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19606",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Veeranam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19607",
            "district": "24",
            "Block": "6",
            "label": "GRAMA COMMITTEE HSS, Venkateswarapuram",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19608",
            "district": "24",
            "Block": "6",
            "label": "CET Tennyson HS, Keelakalangal",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19609",
            "district": "24",
            "Block": "6",
            "label": "MCM HR SEC SCHOOL ANNAMALAIPUDUR, TIRUNELVELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19610",
            "district": "24",
            "Block": "6",
            "label": "ST. JOHN'S HSS - VADIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19611",
            "district": "24",
            "Block": "6",
            "label": "V.N.T MIDDLE SCHOOL,KARUVANTHA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19612",
            "district": "24",
            "Block": "6",
            "label": "Govt. HSS, Uthumalai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19833",
            "district": "24",
            "Block": "6",
            "label": "RC MS KUTHAPANCHAN",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19834",
            "district": "24",
            "Block": "6",
            "label": "TDTA MS PUTHUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19835",
            "district": "24",
            "Block": "6",
            "label": "GHSS,MARUTHAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19836",
            "district": "24",
            "Block": "6",
            "label": "HINDU MS UDAIYAMPULI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "21413",
            "district": "24",
            "Block": "6",
            "label": "GHS ,PALAPATHIRARAMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21640",
            "district": "24",
            "Block": "6",
            "label": "SARUKA NURSARY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21839",
            "district": "24",
            "Block": "6",
            "label": "HOLY CROSS MATRICULATION  HSS SCHOOL MARANTHAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21840",
            "district": "24",
            "Block": "6",
            "label": "JESUS LOVES MATRICULATION HSS SCHOOL VENKATESHWARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21922",
            "district": "24",
            "Block": "6",
            "label": "ARCHVEL MATRICULATION SCHOOL ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22272",
            "district": "24",
            "Block": "6",
            "label": "Bharathi Vidhya Mandhir Matriculation Higher Secondary School, Venkateswarapuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22907",
            "district": "24",
            "Block": "6",
            "label": "GHS THALAISUBRAMANIAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23305",
            "district": "24",
            "Block": "6",
            "label": "GHS KASINATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23329",
            "district": "24",
            "Block": "6",
            "label": "JACKSON MATRICULATION SCHOOL, VENKATESWARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "7": [
        {
            "key": "2037",
            "district": "18",
            "Block": "7",
            "label": "LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL CHETTIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6087",
            "district": "18",
            "Block": "7",
            "label": "HAYAGREEVA MATRICULATION   HIGHER SECONDARY SCHOOL NAKKASALEM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14757",
            "district": "18",
            "Block": "7",
            "label": "SARASWATHI VIDYALAYA HIGHER SECONDARY SCHOOL VARISAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14912",
            "district": "18",
            "Block": "7",
            "label": "GHSS  THENUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14913",
            "district": "18",
            "Block": "7",
            "label": "GHS T. KALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14914",
            "district": "18",
            "Block": "7",
            "label": "PUMS PUDUAMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14915",
            "district": "18",
            "Block": "7",
            "label": "GHSS NAKASELAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14916",
            "district": "18",
            "Block": "7",
            "label": "ARIVALAYAM HS NAKKASELAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14917",
            "district": "18",
            "Block": "7",
            "label": "VALLALAR AIDED MIDDLE SCHOOL, CHETTIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14918",
            "district": "18",
            "Block": "7",
            "label": "GHSS CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14919",
            "district": "18",
            "Block": "7",
            "label": "PUMS NATTARMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14920",
            "district": "18",
            "Block": "7",
            "label": "PUMS MARUTHADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14921",
            "district": "18",
            "Block": "7",
            "label": "PUMS,VIJAYAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14922",
            "district": "18",
            "Block": "7",
            "label": "GHS IRUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14923",
            "district": "18",
            "Block": "7",
            "label": "MURUGAN AID. MIS. NARANAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14924",
            "district": "18",
            "Block": "7",
            "label": "PUMS,VARAGUPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14925",
            "district": "18",
            "Block": "7",
            "label": "ADWHSS NATHAKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14926",
            "district": "18",
            "Block": "7",
            "label": "GHSS KARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14927",
            "district": "18",
            "Block": "7",
            "label": "PUMS KANNAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14928",
            "district": "18",
            "Block": "7",
            "label": "PUMS,PUDHUVIRALIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14929",
            "district": "18",
            "Block": "7",
            "label": "PUMS,SIRUVAYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14930",
            "district": "18",
            "Block": "7",
            "label": "PUMS, SIRUGANPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14931",
            "district": "18",
            "Block": "7",
            "label": "PUMS SATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14932",
            "district": "18",
            "Block": "7",
            "label": "NETHAJI AID.MS.ADDAIKAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14933",
            "district": "18",
            "Block": "7",
            "label": "GHSS PADALUR -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14934",
            "district": "18",
            "Block": "7",
            "label": "ST. FR. MAT. PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14935",
            "district": "18",
            "Block": "7",
            "label": "SREE AMBAALS MATRIC HR SEC SCHOOL - PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14936",
            "district": "18",
            "Block": "7",
            "label": "ANNAI HSS PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14937",
            "district": "18",
            "Block": "7",
            "label": "PERIYAR M S, PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14938",
            "district": "18",
            "Block": "7",
            "label": "PUMS, THIRUVILAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14939",
            "district": "18",
            "Block": "7",
            "label": "GHSS KOLAKKANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14940",
            "district": "18",
            "Block": "7",
            "label": "PUMS, THIMMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14941",
            "district": "18",
            "Block": "7",
            "label": "GHS SILLAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14942",
            "district": "18",
            "Block": "7",
            "label": "PUMS, METHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14943",
            "district": "18",
            "Block": "7",
            "label": "PUMS, RAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14944",
            "district": "18",
            "Block": "7",
            "label": "PUMS, NOCHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14945",
            "district": "18",
            "Block": "7",
            "label": "PUMS MAKKAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14946",
            "district": "18",
            "Block": "7",
            "label": "PUMS, PILIMISAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14947",
            "district": "18",
            "Block": "7",
            "label": "PUMS ELANTHANGUZHI (W)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14948",
            "district": "18",
            "Block": "7",
            "label": "PUMS,KEEZHAMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14949",
            "district": "18",
            "Block": "7",
            "label": "PUMS,ALLINAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14950",
            "district": "18",
            "Block": "7",
            "label": "GHS KOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14951",
            "district": "18",
            "Block": "7",
            "label": "GHS ZAMINE ATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14952",
            "district": "18",
            "Block": "7",
            "label": "GHS GUDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14953",
            "district": "18",
            "Block": "7",
            "label": "GHS- ZAMINPERAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14954",
            "district": "18",
            "Block": "7",
            "label": "GHS ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14955",
            "district": "18",
            "Block": "7",
            "label": "RAJAVIGNESH HSS MELAMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14956",
            "district": "18",
            "Block": "7",
            "label": "GHS MELAMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14957",
            "district": "18",
            "Block": "7",
            "label": "GHSS KOOTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21516",
            "district": "18",
            "Block": "7",
            "label": "ADWHS PADALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21755",
            "district": "18",
            "Block": "7",
            "label": "VARADHA WIKAS PUBLIC SCHOOL VARASAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22542",
            "district": "18",
            "Block": "7",
            "label": "GHS THERANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22900",
            "district": "18",
            "Block": "7",
            "label": "GHS KOTTARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23085",
            "district": "18",
            "Block": "7",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Malayappa nagar",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23296",
            "district": "18",
            "Block": "7",
            "label": "SARASWATHI VIDYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23523",
            "district": "18",
            "Block": "7",
            "label": "ANNAI MATRICULATION SCHOOL PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23523",
            "district": "18",
            "Block": "7",
            "label": "ANNAI MATRICULATION SCHOOL PADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23715",
            "district": "18",
            "Block": "7",
            "label": "GHS GUDALUR",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "8": [
        {
            "key": "1329",
            "district": "28",
            "Block": "8",
            "label": "MEERANIA MS ALAGIAMANAVALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1489",
            "district": "28",
            "Block": "8",
            "label": "GDG MIDDLE SCHOOL ALWARTHIRUNAGARI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1938",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS ALWARTHIRUNAGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2033",
            "district": "28",
            "Block": "8",
            "label": "ST.MARY'S MDL.SCH. PRAKASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2450",
            "district": "28",
            "Block": "8",
            "label": "PUMS PEIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2498",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS KADAYANODAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3642",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS NALUMAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3763",
            "district": "28",
            "Block": "8",
            "label": "PUMS, AVARANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4186",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MIDDLE SCHOOL SINNAMADANKUDIERUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4776",
            "district": "28",
            "Block": "8",
            "label": "GRAMA MS  VELLAKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5146",
            "district": "28",
            "Block": "8",
            "label": "ST MARY'S  MIDDLE SCHOOL ,PUNNAIKAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5244",
            "district": "28",
            "Block": "8",
            "label": "PUMS,PURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5265",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MIDDLE SCHOOL  KARUNKADAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5789",
            "district": "28",
            "Block": "8",
            "label": "ST. XAVIERS MS, SETHUKKUVAITHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5791",
            "district": "28",
            "Block": "8",
            "label": "RC MS SAVERIYARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5944",
            "district": "28",
            "Block": "8",
            "label": "PUMS PERUMALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6305",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS THEMANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6387",
            "district": "28",
            "Block": "8",
            "label": "TNDTA Dr.mATHURAM MS, NAZARETH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6436",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MIDDLE SCHOOL MEERANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6614",
            "district": "28",
            "Block": "8",
            "label": "PUMS,PALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6735",
            "district": "28",
            "Block": "8",
            "label": "PU MS CAMPBELLABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19372",
            "district": "28",
            "Block": "8",
            "label": "PU MS PULIYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19373",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS PALANIAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19374",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MIDDLE SCHOOL, UDAYARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19375",
            "district": "28",
            "Block": "8",
            "label": "PUMS MELAVELLAMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19376",
            "district": "28",
            "Block": "8",
            "label": "GVG TNDTA MS  PILLAIYANMANAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19377",
            "district": "28",
            "Block": "8",
            "label": "ERS MS MUKUPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19378",
            "district": "28",
            "Block": "8",
            "label": "ST.MARK'S HSS  MUKUPERI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19379",
            "district": "28",
            "Block": "8",
            "label": "TNDTA MS NEIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19380",
            "district": "28",
            "Block": "8",
            "label": "ST.MARY'S (G) HS, KATCHANAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19381",
            "district": "28",
            "Block": "8",
            "label": "SRI GANESAR HSS PANICKANADARKUDIERUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19382",
            "district": "28",
            "Block": "8",
            "label": "KAMARAJ  HIGHER SECONDARY SCHOOL NALUMAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19383",
            "district": "28",
            "Block": "8",
            "label": "PUMS, KURUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19384",
            "district": "28",
            "Block": "8",
            "label": "ST.LUCIA HS KURUMBUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19385",
            "district": "28",
            "Block": "8",
            "label": "HINDU MS VARANDIAVEL& AGOPALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19386",
            "district": "28",
            "Block": "8",
            "label": "TNDTA HS, MARANTHALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19387",
            "district": "28",
            "Block": "8",
            "label": "PUMS,SOUTH AUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19388",
            "district": "28",
            "Block": "8",
            "label": "RC. ST. MICHAEL'S MIDDLE SCHOOL, SERNTHAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19389",
            "district": "28",
            "Block": "8",
            "label": "ST.JOSEPH HSS PUNNAIKAYAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19390",
            "district": "28",
            "Block": "8",
            "label": "HINDU HSS ALWARTHIRUNAGARI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19391",
            "district": "28",
            "Block": "8",
            "label": "GHSS MAVADIPANNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19392",
            "district": "28",
            "Block": "8",
            "label": "S SHUNMUGASUNDARA NADAR HSS AUTHOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19393",
            "district": "28",
            "Block": "8",
            "label": "GHSS AUTHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19394",
            "district": "28",
            "Block": "8",
            "label": "PUMS, NORTH AUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19395",
            "district": "28",
            "Block": "8",
            "label": "JAMES MEMORIAL MATRIC HR. SEC SCHOOL, PRAKASAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19396",
            "district": "28",
            "Block": "8",
            "label": "DEAF MS THIRUMARAIYOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19397",
            "district": "28",
            "Block": "8",
            "label": "SOLOMON MATRICULATION HIGHER SECONDARY SCHOOL, NAZARETH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19398",
            "district": "28",
            "Block": "8",
            "label": "ST.JOHN'S GIRLS' HIGHER SECONDARY SCHOOL , NAZARETH",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19399",
            "district": "28",
            "Block": "8",
            "label": "MARGOSCHIS HIGHER SECONDARY SCHOOL NAZARETHH",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19400",
            "district": "28",
            "Block": "8",
            "label": "PUMS , AMBALACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19401",
            "district": "28",
            "Block": "8",
            "label": "ASP MS KARUVELAMPADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19402",
            "district": "28",
            "Block": "8",
            "label": "TDTA KURUKALPERI HSS ASIRVATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19403",
            "district": "28",
            "Block": "8",
            "label": "EKA RATCHAGAR SABAI HIGHER SECONDARY SCHOOL, SALAIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        }
    ],
    "9": [
        {
            "key": "659",
            "district": "30",
            "Block": "9",
            "label": "TDTA MS- KEELA SIVANTHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1786",
            "district": "30",
            "Block": "9",
            "label": "Aroma Matric HS - Ambasamudram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2103",
            "district": "30",
            "Block": "9",
            "label": "EXCELLENT MHS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2297",
            "district": "30",
            "Block": "9",
            "label": "LAKSHMI VIDYA NIKETAN MATRICULATION HIGHER SECONDARY SCHOOL, VAGAIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2315",
            "district": "30",
            "Block": "9",
            "label": "MERIT MHSS,AMBASAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4853",
            "district": "30",
            "Block": "9",
            "label": "ST. MARY'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5171",
            "district": "30",
            "Block": "9",
            "label": "ST.XAVIER'S MS - IRUTHAYAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5492",
            "district": "30",
            "Block": "9",
            "label": "HINDU MS - VAGAIGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5617",
            "district": "30",
            "Block": "9",
            "label": "ST. THERASA MS - VKPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5688",
            "district": "30",
            "Block": "9",
            "label": "PUMS - PAPANASAM, LOWER DAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5722",
            "district": "30",
            "Block": "9",
            "label": "STC BRANCH MS-AMBASAMUDRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6513",
            "district": "30",
            "Block": "9",
            "label": "RC MARAVAR MS- VAIRAVIKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19613",
            "district": "30",
            "Block": "9",
            "label": "GHS, MANNARKOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19614",
            "district": "30",
            "Block": "9",
            "label": "PUMS - VKPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19615",
            "district": "30",
            "Block": "9",
            "label": "NADAR UNION MS- MUTHALIYARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19616",
            "district": "30",
            "Block": "9",
            "label": "SENAI THALAIVAR HSS - VKPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19617",
            "district": "30",
            "Block": "9",
            "label": "SACRED HEART HS- IRUTHAYAKULAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19618",
            "district": "30",
            "Block": "9",
            "label": "ANDREW MATRIC HS - VKPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19619",
            "district": "30",
            "Block": "9",
            "label": "BRILLIANT N&PS - IRUTHAYAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19620",
            "district": "30",
            "Block": "9",
            "label": "ST. MARY'S HSS- VKPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19621",
            "district": "30",
            "Block": "9",
            "label": "PLWA HSS - VKPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19622",
            "district": "30",
            "Block": "9",
            "label": "AMALI GIRLS HSS- IRUTHAYAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19623",
            "district": "30",
            "Block": "9",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PAPANASAM UPPERDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19624",
            "district": "30",
            "Block": "9",
            "label": "LOTUS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19625",
            "district": "30",
            "Block": "9",
            "label": "PUMS-AMBASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19626",
            "district": "30",
            "Block": "9",
            "label": "MOHAITHEEN FATHIMA MS AMBASAMUDRAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19627",
            "district": "30",
            "Block": "9",
            "label": "ST. JOSEPH'S HS-AMBASAMUDRAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19628",
            "district": "30",
            "Block": "9",
            "label": "A.V.RM.V.GOVT(G)HSS-AMBASAMUDRAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19629",
            "district": "30",
            "Block": "9",
            "label": "A.V.RM.V.MATRIC HSS-AMBASAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19630",
            "district": "30",
            "Block": "9",
            "label": "P.S.V. N&PS-AMBASAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19631",
            "district": "30",
            "Block": "9",
            "label": "VIKASA SCHOOL, AMBASAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19632",
            "district": "30",
            "Block": "9",
            "label": "THEERTHAPATHI HSS-AMBASAMUDRAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19633",
            "district": "30",
            "Block": "9",
            "label": "ST.XAVIERS MS, ADIVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19634",
            "district": "30",
            "Block": "9",
            "label": "REACH MATRIC HSS-CHETTIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19635",
            "district": "30",
            "Block": "9",
            "label": "GHSS, VELLANGULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19636",
            "district": "30",
            "Block": "9",
            "label": "SETHU SUBRAMANIA MS - OORKAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19637",
            "district": "30",
            "Block": "9",
            "label": "SRI KAMARAJ MS -AGASTHIYARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19638",
            "district": "30",
            "Block": "9",
            "label": "CAMBRIDGE MHS - AGASTHIYARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19639",
            "district": "30",
            "Block": "9",
            "label": "CAMBRIDGE INTERNATIONAL CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19640",
            "district": "30",
            "Block": "9",
            "label": "GHS - MANIMUTHAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19641",
            "district": "30",
            "Block": "9",
            "label": "GHS - MANJOLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19642",
            "district": "30",
            "Block": "9",
            "label": "ST.MICHAEL HS- SINGAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19643",
            "district": "30",
            "Block": "9",
            "label": "PUMS - KODARANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22273",
            "district": "30",
            "Block": "9",
            "label": "VELS VIDHYALAYA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22274",
            "district": "30",
            "Block": "9",
            "label": "ST ASSISI PUBLIC SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22275",
            "district": "30",
            "Block": "9",
            "label": "AROMA NIKETAN CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23586",
            "district": "30",
            "Block": "9",
            "label": "AL AMEEN NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23586",
            "district": "30",
            "Block": "9",
            "label": "AL AMEEN NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "10": [
        {
            "key": "605",
            "district": "8",
            "Block": "10",
            "label": "SRV VIDHYYA VICKAS MATRICULATION SCHOOL , CHITTAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "635",
            "district": "8",
            "Block": "10",
            "label": "SREE SARAVANA NIKETHAN MATRICULATION HIGHER SECONDARY SCHOOL, NERINJIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "674",
            "district": "8",
            "Block": "10",
            "label": "PUMS MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "774",
            "district": "8",
            "Block": "10",
            "label": "MAM EXEL MATRICULATION HIGHER SECONDARY SCHOOL,  GURUVAREDDIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "820",
            "district": "8",
            "Block": "10",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "895",
            "district": "8",
            "Block": "10",
            "label": "PUMS ALANGARIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "910",
            "district": "8",
            "Block": "10",
            "label": "PUMS  VEDIKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "961",
            "district": "8",
            "Block": "10",
            "label": "PUMS CHITTHAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1004",
            "district": "8",
            "Block": "10",
            "label": "PUMS KUNDHUBAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1173",
            "district": "8",
            "Block": "10",
            "label": "PUMS SEMBADAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1186",
            "district": "8",
            "Block": "10",
            "label": "PUMS KOOTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1284",
            "district": "8",
            "Block": "10",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1391",
            "district": "8",
            "Block": "10",
            "label": "ST. THERESA'S MATRICULATION SCHOOL, OLAGADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1640",
            "district": "8",
            "Block": "10",
            "label": "LOYOLA MATRICULATION SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1825",
            "district": "8",
            "Block": "10",
            "label": "PUMS THANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1852",
            "district": "8",
            "Block": "10",
            "label": "PUMS REDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1896",
            "district": "8",
            "Block": "10",
            "label": "PUMS KONARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1908",
            "district": "8",
            "Block": "10",
            "label": "PUMS  JARTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1931",
            "district": "8",
            "Block": "10",
            "label": "TALENT MATRICULATION SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2104",
            "district": "8",
            "Block": "10",
            "label": "PUMS NATHAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2306",
            "district": "8",
            "Block": "10",
            "label": "PUMS MASAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2358",
            "district": "8",
            "Block": "10",
            "label": "PUMS KALPAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2376",
            "district": "8",
            "Block": "10",
            "label": "PUMS CHINNAPARUVACHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2537",
            "district": "8",
            "Block": "10",
            "label": "R.C.MS CHINNAPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3030",
            "district": "8",
            "Block": "10",
            "label": "T.C.M. MATRICULATION SCHOOL, SANDAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4267",
            "district": "8",
            "Block": "10",
            "label": "PUMS MURALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4396",
            "district": "8",
            "Block": "10",
            "label": "PUMS MARAVANKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12012",
            "district": "8",
            "Block": "10",
            "label": "MODEL HIGHER SECONDARY SCHOOL, MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12013",
            "district": "8",
            "Block": "10",
            "label": "GHS UMAREDDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12014",
            "district": "8",
            "Block": "10",
            "label": "GHSS GURUVAREDDIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12015",
            "district": "8",
            "Block": "10",
            "label": "PUMS PALAKUTTAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12016",
            "district": "8",
            "Block": "10",
            "label": "GHS KURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12017",
            "district": "8",
            "Block": "10",
            "label": "ANMGHS MUHASIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12018",
            "district": "8",
            "Block": "10",
            "label": "GHSS NERINJIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12019",
            "district": "8",
            "Block": "10",
            "label": "PUMS PATLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12020",
            "district": "8",
            "Block": "10",
            "label": "S.E.T. HIGHER SECONDARY SCHOOL , POONACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12021",
            "district": "8",
            "Block": "10",
            "label": "ST.IGNATIUS HSS BUDAPADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12022",
            "district": "8",
            "Block": "10",
            "label": "KASTHURBA GANDHI BALIKA VIDYALAYA GIRLS RESIDENTIAL SCHOOL,  KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12023",
            "district": "8",
            "Block": "10",
            "label": "PUMS ANANTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12024",
            "district": "8",
            "Block": "10",
            "label": "GHSS SINGAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12025",
            "district": "8",
            "Block": "10",
            "label": "GHS KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12026",
            "district": "8",
            "Block": "10",
            "label": "GHSS VELLITHIRUPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12027",
            "district": "8",
            "Block": "10",
            "label": "GHSS OLAGADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12028",
            "district": "8",
            "Block": "10",
            "label": "GHS KUPPICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12029",
            "district": "8",
            "Block": "10",
            "label": "GHSS CHENNAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12030",
            "district": "8",
            "Block": "10",
            "label": "GHSS ALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21959",
            "district": "8",
            "Block": "10",
            "label": "PERIYASAMY HIGH SCHOOL, AMMAPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "23067",
            "district": "8",
            "Block": "10",
            "label": "KALAIVANI KALVI NILAYAM MATRICULATION SCHOOL,CHENNAMPATTI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "11": [
        {
            "key": "2010",
            "district": "25",
            "Block": "11",
            "label": "DR.V.GENGUSAMY MAT. -MELATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2105",
            "district": "25",
            "Block": "11",
            "label": "PUMS - SALIAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2228",
            "district": "25",
            "Block": "11",
            "label": "UKKADAI A.VISWANATHA DEVAR MATRICULATION SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2276",
            "district": "25",
            "Block": "11",
            "label": "SRI LAKSHMI VIDYALAYA MATRICULATION SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2294",
            "district": "25",
            "Block": "11",
            "label": "FATHIMA MATRIC HIGHER SECONDARY SCHOOL - KOVILUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2932",
            "district": "25",
            "Block": "11",
            "label": "PUMS - RAILADY AMMAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3028",
            "district": "25",
            "Block": "11",
            "label": "PUMS - PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3107",
            "district": "25",
            "Block": "11",
            "label": "PUMS - EDAIIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3442",
            "district": "25",
            "Block": "11",
            "label": "PUMS - ARUMALAIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3544",
            "district": "25",
            "Block": "11",
            "label": "PUMS - THIRUBUVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3621",
            "district": "25",
            "Block": "11",
            "label": "PUMS- SENBAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3699",
            "district": "25",
            "Block": "11",
            "label": "PUMS - UKKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3817",
            "district": "25",
            "Block": "11",
            "label": "ST. MARCINA'S MAT.HSS-AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3876",
            "district": "25",
            "Block": "11",
            "label": "PUMS - VAIYACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4094",
            "district": "25",
            "Block": "11",
            "label": "PUMS - SIKKAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4573",
            "district": "25",
            "Block": "11",
            "label": "PUMS - VADAKKUMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4583",
            "district": "25",
            "Block": "11",
            "label": "PUMS - RARAMUTHIRAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4590",
            "district": "25",
            "Block": "11",
            "label": "PUMS - KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4625",
            "district": "25",
            "Block": "11",
            "label": "PUMS - SOOLIAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6195",
            "district": "25",
            "Block": "11",
            "label": "PUMS - ATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16583",
            "district": "25",
            "Block": "11",
            "label": "GHS - ANNAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16584",
            "district": "25",
            "Block": "11",
            "label": "GHS - ARUNTHAVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16585",
            "district": "25",
            "Block": "11",
            "label": "GHS - UTHAMARGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16586",
            "district": "25",
            "Block": "11",
            "label": "AIDED MS - KALANCHERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16587",
            "district": "25",
            "Block": "11",
            "label": "GHS - KAMBARNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16588",
            "district": "25",
            "Block": "11",
            "label": "EDT. HSS N.V. KUDIKKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16589",
            "district": "25",
            "Block": "11",
            "label": "GHSS -  SALIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16590",
            "district": "25",
            "Block": "11",
            "label": "GHSS - POONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16591",
            "district": "25",
            "Block": "11",
            "label": "GHSS - THIRUKARUKKAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23388",
            "district": "25",
            "Block": "11",
            "label": "INDIAN CITY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "16592",
            "district": "25",
            "Block": "11",
            "label": "RC AIDED HSS(G) - AMMAPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16593",
            "district": "25",
            "Block": "11",
            "label": "U.A.A.T HSS - AMMAPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16594",
            "district": "25",
            "Block": "11",
            "label": "GHS AMMAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16595",
            "district": "25",
            "Block": "11",
            "label": "GHSS - MELATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22225",
            "district": "25",
            "Block": "11",
            "label": "GHS IRUMPUTHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "12": [
        {
            "key": "564",
            "district": "36",
            "Block": "12",
            "label": "FREEDOM CONCEPT CBSE POIGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "634",
            "district": "36",
            "Block": "12",
            "label": "SHILOH MATRIC SCHOOL, ANAICUT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1842",
            "district": "36",
            "Block": "12",
            "label": "SHRI VARDHAMAN JAIN MATRIC HSS,VIRINCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4004",
            "district": "36",
            "Block": "12",
            "label": "SHIKSHA KENDRA MHSS PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4511",
            "district": "36",
            "Block": "12",
            "label": "PUMS OTHIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4963",
            "district": "36",
            "Block": "12",
            "label": "PUMS  VASANTHANADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5255",
            "district": "36",
            "Block": "12",
            "label": "PUMS VARADHALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5481",
            "district": "36",
            "Block": "12",
            "label": "PUMS OTTERIPALLAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5573",
            "district": "36",
            "Block": "12",
            "label": "PUMS ONGAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5678",
            "district": "36",
            "Block": "12",
            "label": "HOLY ANGELS MHSS PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6055",
            "district": "36",
            "Block": "12",
            "label": "PUMS VETTUVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6190",
            "district": "36",
            "Block": "12",
            "label": "SRI SAI LAKSHMI MATRIC SCHOOL,  PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6505",
            "district": "36",
            "Block": "12",
            "label": "PUMS MARUTHAVALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6526",
            "district": "36",
            "Block": "12",
            "label": "PUMS KUTCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8715",
            "district": "36",
            "Block": "12",
            "label": "PUMS  RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8716",
            "district": "36",
            "Block": "12",
            "label": "AGARAM MATRIC SCHOOL, PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8717",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS THIPPASAMUDHRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8718",
            "district": "36",
            "Block": "12",
            "label": "GOVT HSS VIRINCHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8719",
            "district": "36",
            "Block": "12",
            "label": "GHS PICHANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8720",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS DEVICHETTIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8721",
            "district": "36",
            "Block": "12",
            "label": "GOVT ADW HS MAGAMADHUPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8722",
            "district": "36",
            "Block": "12",
            "label": "PUMS BASAVANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8723",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS MELARASAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8724",
            "district": "36",
            "Block": "12",
            "label": "MDAPS VANNANTHANGAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8725",
            "district": "36",
            "Block": "12",
            "label": "PUMS KOTTAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8726",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS ATHIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8727",
            "district": "36",
            "Block": "12",
            "label": "GOVT BOYS HSS ODUGATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8728",
            "district": "36",
            "Block": "12",
            "label": "GOVT GIRLS HSS ODUGATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8729",
            "district": "36",
            "Block": "12",
            "label": "PUMS PINNATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8730",
            "district": "36",
            "Block": "12",
            "label": "PUMS KUMBALKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8731",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS KILKOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8732",
            "district": "36",
            "Block": "12",
            "label": "S.P.A.N.M.SCHOOL ERIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8733",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS SERPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8734",
            "district": "36",
            "Block": "12",
            "label": "PUMS KATHARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8735",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS PEENJAMANDAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8736",
            "district": "36",
            "Block": "12",
            "label": "PUMS JARTHANKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8737",
            "district": "36",
            "Block": "12",
            "label": "PUMS ERIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8738",
            "district": "36",
            "Block": "12",
            "label": "ANWARUL ISLAM AIDED MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8739",
            "district": "36",
            "Block": "12",
            "label": "ST. THERESAL HIGH SCHOOL  PALLIKONDA",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8740",
            "district": "36",
            "Block": "12",
            "label": "GOVT GIRLS HSS PALLIKONDA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8741",
            "district": "36",
            "Block": "12",
            "label": "GOVT BOYS HSS PALLIKONDA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8742",
            "district": "36",
            "Block": "12",
            "label": "PUMS KANTHANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8743",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS KAZHANIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8744",
            "district": "36",
            "Block": "12",
            "label": "ANURAT VIDYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8745",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS ERAIVANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8746",
            "district": "36",
            "Block": "12",
            "label": "PUMS SEDUVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8747",
            "district": "36",
            "Block": "12",
            "label": "ADW MS SEDHUVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8748",
            "district": "36",
            "Block": "12",
            "label": "PUMSCHOOL VELANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8749",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS MOOLAIGATE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8750",
            "district": "36",
            "Block": "12",
            "label": "GOVT GIRLS HSS ANAICUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8751",
            "district": "36",
            "Block": "12",
            "label": "GBHSS ANAICUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8752",
            "district": "36",
            "Block": "12",
            "label": "PUMS THARVAZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8753",
            "district": "36",
            "Block": "12",
            "label": "GOVT HS ELAVAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8754",
            "district": "36",
            "Block": "12",
            "label": "GOVT BOYS HSS POIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8755",
            "district": "36",
            "Block": "12",
            "label": "GOVT GIRLS HSS POIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8756",
            "district": "36",
            "Block": "12",
            "label": "PUMS MADAYAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8902",
            "district": "36",
            "Block": "12",
            "label": "GHS AGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8903",
            "district": "36",
            "Block": "12",
            "label": "GHSS CHINNAPALLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8904",
            "district": "36",
            "Block": "12",
            "label": "GHS MELPALLIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8905",
            "district": "36",
            "Block": "12",
            "label": "GHS ASANAMBUT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8906",
            "district": "36",
            "Block": "12",
            "label": "GHS,GURUVARAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8907",
            "district": "36",
            "Block": "12",
            "label": "ADWMS,VEPANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20888",
            "district": "36",
            "Block": "12",
            "label": "SRI SAI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21257",
            "district": "36",
            "Block": "12",
            "label": "BHARATH MATRIC HSS, ODUGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21258",
            "district": "36",
            "Block": "12",
            "label": "SRI VIVEKANANDA MATRIC HSS, ANAICUT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21785",
            "district": "36",
            "Block": "12",
            "label": "THE NAMAKKAL TEACHERS PARK MHSS, SATHYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21927",
            "district": "36",
            "Block": "12",
            "label": "SHIKSHA GREENWOODS ICSE SCHOOL, PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21928",
            "district": "36",
            "Block": "12",
            "label": "KVS NURSERY & PRIMARY SCHOOL PALLIKONDA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21965",
            "district": "36",
            "Block": "12",
            "label": "THE NAMAKKAL TEACHERS VIDHYASHRAM MATRIC HSS, KAZHANIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22388",
            "district": "36",
            "Block": "12",
            "label": "SPRING DAYS CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22735",
            "district": "36",
            "Block": "12",
            "label": "SRI SANKARA MATRIC HR. SEC. SCHOOL, ODUGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23716",
            "district": "36",
            "Block": "12",
            "label": "Oxford Matriculation School, Odugathur",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "13": [
        {
            "key": "1592",
            "district": "4",
            "Block": "13",
            "label": "PUMS ARTHANARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2134",
            "district": "4",
            "Block": "13",
            "label": "K.M.G MATRIC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2333",
            "district": "4",
            "Block": "13",
            "label": "PUMS NARASIMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2615",
            "district": "4",
            "Block": "13",
            "label": "SRI LAXMI VIDYA BAVAN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2834",
            "district": "4",
            "Block": "13",
            "label": "PUMS PONGALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2898",
            "district": "4",
            "Block": "13",
            "label": "KANDASAMY MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2984",
            "district": "4",
            "Block": "13",
            "label": "PUMS MANJANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3001",
            "district": "4",
            "Block": "13",
            "label": "PUMS SUBBEGOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3034",
            "district": "4",
            "Block": "13",
            "label": "PUMS VAZHAIKOMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3194",
            "district": "4",
            "Block": "13",
            "label": "PUMS KPM COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3232",
            "district": "4",
            "Block": "13",
            "label": "PUMS, KIZHAVANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3483",
            "district": "4",
            "Block": "13",
            "label": "PUMS CHINNAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3501",
            "district": "4",
            "Block": "13",
            "label": "PUMS KARIANCHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3650",
            "district": "4",
            "Block": "13",
            "label": "PUMS NARIKKALPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3800",
            "district": "4",
            "Block": "13",
            "label": "SHRI VIGNESWARA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4071",
            "district": "4",
            "Block": "13",
            "label": "PUMS GENGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4208",
            "district": "4",
            "Block": "13",
            "label": "SHRI MAHATHMA VIDHYAA MANTHIR MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5418",
            "district": "4",
            "Block": "13",
            "label": "SHRI KRISHNA VIDHYALAYA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6456",
            "district": "4",
            "Block": "13",
            "label": "MARY MATHA MATRICULATION SCHOOL, GANAPATHY PALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6718",
            "district": "4",
            "Block": "13",
            "label": "OXFORD MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12919",
            "district": "4",
            "Block": "13",
            "label": "ANANYA VIDYALAYA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12920",
            "district": "4",
            "Block": "13",
            "label": "GHS PETHANAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12921",
            "district": "4",
            "Block": "13",
            "label": "SRI VAGISVARI VIDYA MANDIR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12922",
            "district": "4",
            "Block": "13",
            "label": "GHSS SOMANDURAI CHITTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12923",
            "district": "4",
            "Block": "13",
            "label": "PUMS THENSANGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12924",
            "district": "4",
            "Block": "13",
            "label": "M C GGHSS KOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12925",
            "district": "4",
            "Block": "13",
            "label": "GOVT BOYS HSS KOTTUR MALAYANDIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12926",
            "district": "4",
            "Block": "13",
            "label": "GHS ALIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12927",
            "district": "4",
            "Block": "13",
            "label": "VRT GGHSS ANAIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12928",
            "district": "4",
            "Block": "13",
            "label": "GHSS, ANAIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12929",
            "district": "4",
            "Block": "13",
            "label": "GHS ATHUPOLLACHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12930",
            "district": "4",
            "Block": "13",
            "label": "PALANIAMMAL HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12931",
            "district": "4",
            "Block": "13",
            "label": "GHSS Diwansapudur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12932",
            "district": "4",
            "Block": "13",
            "label": "FOREST DEPT TRIBAL  RESIDENTIAL MIDDLE SCHOOL TOPSLIP",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12933",
            "district": "4",
            "Block": "13",
            "label": "PUMS SARALAIPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12934",
            "district": "4",
            "Block": "13",
            "label": "FOREST HILL ACADEMY MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12935",
            "district": "4",
            "Block": "13",
            "label": "GHSS VETTAIKARANPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12936",
            "district": "4",
            "Block": "13",
            "label": "GHS SETHUMADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12937",
            "district": "4",
            "Block": "13",
            "label": "PUMS ODAYAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12938",
            "district": "4",
            "Block": "13",
            "label": "ANNAI MATRICULATION SCHOOL, CHINNAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22484",
            "district": "4",
            "Block": "13",
            "label": "GHS PERIYAPODHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22837",
            "district": "4",
            "Block": "13",
            "label": "VIDHYA VISHWALAYA CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22976",
            "district": "4",
            "Block": "13",
            "label": "GHS RAMANAMUDHALIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23236",
            "district": "4",
            "Block": "13",
            "label": "GHS ANGALAKURUCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23460",
            "district": "4",
            "Block": "13",
            "label": "SRINITHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23460",
            "district": "4",
            "Block": "13",
            "label": "SRINITHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "14": [
        {
            "key": "2373",
            "district": "34",
            "Block": "14",
            "label": "PUMS MELNEMELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2418",
            "district": "34",
            "Block": "14",
            "label": "PUMS ALATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2493",
            "district": "34",
            "Block": "14",
            "label": "PUMS MADIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2924",
            "district": "34",
            "Block": "14",
            "label": "PUMS PAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2968",
            "district": "34",
            "Block": "14",
            "label": "PUMS ERUMAIVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3446",
            "district": "34",
            "Block": "14",
            "label": "WISDOM MAT HSS CHEYYATRAI VENDRAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3589",
            "district": "34",
            "Block": "14",
            "label": "PUMS VEERAMBAKKAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4460",
            "district": "34",
            "Block": "14",
            "label": "PUMS CHEYYATRAIVENDRAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5127",
            "district": "34",
            "Block": "14",
            "label": "PUMS PERUMBALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9578",
            "district": "34",
            "Block": "14",
            "label": "GHSS AKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9579",
            "district": "34",
            "Block": "14",
            "label": "GHS ALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9580",
            "district": "34",
            "Block": "14",
            "label": "GHSS ANAKKAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9581",
            "district": "34",
            "Block": "14",
            "label": "GHS ANAPPATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9582",
            "district": "34",
            "Block": "14",
            "label": "GHS KOOZHAMANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9583",
            "district": "34",
            "Block": "14",
            "label": "GHSS KOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9584",
            "district": "34",
            "Block": "14",
            "label": "GHS METTU ECHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9585",
            "district": "34",
            "Block": "14",
            "label": "PUMS NEDUNGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9586",
            "district": "34",
            "Block": "14",
            "label": "GHSS PURISAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9587",
            "district": "34",
            "Block": "14",
            "label": "GHS THENELAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9588",
            "district": "34",
            "Block": "14",
            "label": "GHS THETHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9589",
            "district": "34",
            "Block": "14",
            "label": "GHSS UKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9590",
            "district": "34",
            "Block": "14",
            "label": "GHSS SENGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20912",
            "district": "34",
            "Block": "14",
            "label": "BRIGHT PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21449",
            "district": "34",
            "Block": "14",
            "label": "GHS ELANEERKUNDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "15": [
        {
            "key": "1839",
            "district": "29",
            "Block": "15",
            "label": "SEVAI SHANTHI MATRICULATION HIGHER SECONDARY SCHOOL,PETTAVAITHALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2590",
            "district": "29",
            "Block": "15",
            "label": "CHINMAYA VIDYALAYA MATRIC HSS SRIRANGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3896",
            "district": "29",
            "Block": "15",
            "label": "VIGNESH SRI RENGA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6331",
            "district": "29",
            "Block": "15",
            "label": "SREE VAIJAYANTHEE VIDHYALAYA MATRIC HSS SRIRANGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6645",
            "district": "29",
            "Block": "15",
            "label": "BRINDAVAN VIDYALAYA  T V KOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14064",
            "district": "29",
            "Block": "15",
            "label": "GHS UTHAMARSEELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14065",
            "district": "29",
            "Block": "15",
            "label": "GHSS, THIRUVALARSOLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14066",
            "district": "29",
            "Block": "15",
            "label": "AYYANAR CORPORATION HIGH SCHOOL MELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14067",
            "district": "29",
            "Block": "15",
            "label": "GHS MUTHARASANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14068",
            "district": "29",
            "Block": "15",
            "label": "SRI VENKATESHWARA HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14069",
            "district": "29",
            "Block": "15",
            "label": "G.H.S.S THIRUCHENDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14070",
            "district": "29",
            "Block": "15",
            "label": "PUMS, AMMANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14071",
            "district": "29",
            "Block": "15",
            "label": "MATHURA VIDHYALAYA MATRIC HSS, PERIYAKARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14072",
            "district": "29",
            "Block": "15",
            "label": "SRI VIVEKANANDA VIDHYAVANAM HS THIRUPARAITHURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14073",
            "district": "29",
            "Block": "15",
            "label": "SRI RAMKRISHANA VIDHYALAYA HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14074",
            "district": "29",
            "Block": "15",
            "label": "VIVEKANANDA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14075",
            "district": "29",
            "Block": "15",
            "label": "PUMS, PERUGAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14076",
            "district": "29",
            "Block": "15",
            "label": "PUMS KODIYALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14077",
            "district": "29",
            "Block": "15",
            "label": "GHSS,AYILAPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14078",
            "district": "29",
            "Block": "15",
            "label": "AHSS RATHNA,PETTAVAITHALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14079",
            "district": "29",
            "Block": "15",
            "label": "ST GABRIEL MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14080",
            "district": "29",
            "Block": "15",
            "label": "GHS PETTAVAITHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14081",
            "district": "29",
            "Block": "15",
            "label": "GHS DEVASTHANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14082",
            "district": "29",
            "Block": "15",
            "label": "GHS KULUMANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14083",
            "district": "29",
            "Block": "15",
            "label": "GHS SEERATHOPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14084",
            "district": "29",
            "Block": "15",
            "label": "SRI BHARATI VIDYASHRAM MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14085",
            "district": "29",
            "Block": "15",
            "label": "PUMS,MULLIKARUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14086",
            "district": "29",
            "Block": "15",
            "label": "G.H.S.S ETTARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14087",
            "district": "29",
            "Block": "15",
            "label": "GHS KEERIKKALMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14088",
            "district": "29",
            "Block": "15",
            "label": "AIDED MIDDLE SCHOOL EAST RENGA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14089",
            "district": "29",
            "Block": "15",
            "label": "AIDED MIDDLE SCHOOL O.R.R",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14090",
            "district": "29",
            "Block": "15",
            "label": "THE HIGHER SECONDARY SCHOOL FOR BOYS , SRIRANGAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14091",
            "district": "29",
            "Block": "15",
            "label": "THE GIRLS' HIGHER SECONDARY SCHOOL, SRIRANGAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14092",
            "district": "29",
            "Block": "15",
            "label": "THE KALAIVAANI MATRICULATION SCHOOL,SRIRANGAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14093",
            "district": "29",
            "Block": "15",
            "label": "MPL.MS. DR. RAJAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14094",
            "district": "29",
            "Block": "15",
            "label": "AIDED MIDDLE SCHOOL SHANMUGA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14095",
            "district": "29",
            "Block": "15",
            "label": "MPL.MS. DEVI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14096",
            "district": "29",
            "Block": "15",
            "label": "DR.G.CHITTRAMBALAM  MAT.SCHOOL, SRI RANGAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14097",
            "district": "29",
            "Block": "15",
            "label": "MPL.M.S SRI RENGANATHA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14098",
            "district": "29",
            "Block": "15",
            "label": "NEHRUJI MEMORIAL MPL.M.S",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14099",
            "district": "29",
            "Block": "15",
            "label": "THE HIGH SCHOOL , THIRUVANAIKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14100",
            "district": "29",
            "Block": "15",
            "label": "SRI AKILANDESWARI VIDYALAYA THIRUVANAIKOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14101",
            "district": "29",
            "Block": "15",
            "label": "SRI VAGEESHA VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14102",
            "district": "29",
            "Block": "15",
            "label": "CORPORATION HIGH SCHOOL, TV  KOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20916",
            "district": "29",
            "Block": "15",
            "label": "K N R VIDHYALAYA,(CBSE) SRIRANGAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21324",
            "district": "29",
            "Block": "15",
            "label": "VIVEKANANDA VIDYALAYA CBSE,THIRUPPARAITHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21372",
            "district": "29",
            "Block": "15",
            "label": "KAMATCHI MATRICULATION SCHOOL, POTHAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21373",
            "district": "29",
            "Block": "15",
            "label": "GHS,POSAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21504",
            "district": "29",
            "Block": "15",
            "label": "ADW HS,KONDAYAMPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21505",
            "district": "29",
            "Block": "15",
            "label": "ADWHS,S.PUDUKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21919",
            "district": "29",
            "Block": "15",
            "label": "GHS, POTHAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23578",
            "district": "29",
            "Block": "15",
            "label": "SVV PUBLIC SCHOOL ALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23578",
            "district": "29",
            "Block": "15",
            "label": "SVV PUBLIC SCHOOL ALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "16": [
        {
            "key": "3360",
            "district": "1",
            "Block": "16",
            "label": "ST. MARTIN'S MATRICULATION  SCHOOL, ANDIMADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6319",
            "district": "1",
            "Block": "16",
            "label": "SHRI SOWBAKYA MATRICULATION  HIGHER . SECONDARY SCHOOL ANDIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6431",
            "district": "1",
            "Block": "16",
            "label": "PUMS KEELAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15008",
            "district": "1",
            "Block": "16",
            "label": "GHS OLAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15009",
            "district": "1",
            "Block": "16",
            "label": "PUMS THAVADANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15010",
            "district": "1",
            "Block": "16",
            "label": "GOVT HSS ALAGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15011",
            "district": "1",
            "Block": "16",
            "label": "PUMS CHINNAKARUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15012",
            "district": "1",
            "Block": "16",
            "label": "PUMS AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15013",
            "district": "1",
            "Block": "16",
            "label": "GHSS EDAIYAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15014",
            "district": "1",
            "Block": "16",
            "label": "GOVT.HR.SEC.SCHOOL,AYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15015",
            "district": "1",
            "Block": "16",
            "label": "PUMS KANGUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15016",
            "district": "1",
            "Block": "16",
            "label": "PUMS PATTANAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15017",
            "district": "1",
            "Block": "16",
            "label": "PUMS VILANDHAI -E",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15018",
            "district": "1",
            "Block": "16",
            "label": "GHSS ANDIMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15019",
            "district": "1",
            "Block": "16",
            "label": "GHS (GIRLS) ANDIMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15020",
            "district": "1",
            "Block": "16",
            "label": "GHS KAVARAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15021",
            "district": "1",
            "Block": "16",
            "label": "ST MARY'S MATRICULATION  HIGH SCHOOL  KAVARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15022",
            "district": "1",
            "Block": "16",
            "label": "DON BOSCO HR SEC SCHOOL VARADARAJANPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15023",
            "district": "1",
            "Block": "16",
            "label": "ALANGARA ANNAI(GIRLS) HSS VARADHARAJANPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15024",
            "district": "1",
            "Block": "16",
            "label": "PUMS PERIYAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15025",
            "district": "1",
            "Block": "16",
            "label": "GHS PERIYATHATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15026",
            "district": "1",
            "Block": "16",
            "label": "ST.ANNES. MS KEELANEDUVAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15027",
            "district": "1",
            "Block": "16",
            "label": "PUMS KOVILVALKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15028",
            "district": "1",
            "Block": "16",
            "label": "GHS THIRUKALLAPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15029",
            "district": "1",
            "Block": "16",
            "label": "ADW HSS POOVANIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15030",
            "district": "1",
            "Block": "16",
            "label": "PUMS KOOVATHUR EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15031",
            "district": "1",
            "Block": "16",
            "label": "GHS KOOVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15032",
            "district": "1",
            "Block": "16",
            "label": "PUMS KATTATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15033",
            "district": "1",
            "Block": "16",
            "label": "PUMS Z.KULATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15034",
            "district": "1",
            "Block": "16",
            "label": "PUMS KUVAGAM EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15035",
            "district": "1",
            "Block": "16",
            "label": "GHS KUVAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15036",
            "district": "1",
            "Block": "16",
            "label": "PUMS K.VALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15037",
            "district": "1",
            "Block": "16",
            "label": "GHS KODDUKKUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15038",
            "district": "1",
            "Block": "16",
            "label": "PUMS THERKKUPATI MARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15039",
            "district": "1",
            "Block": "16",
            "label": "GHSS MARUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15040",
            "district": "1",
            "Block": "16",
            "label": "PUMS DEVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15041",
            "district": "1",
            "Block": "16",
            "label": "GHSS ELAIYUR-VARIYANKAVAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15042",
            "district": "1",
            "Block": "16",
            "label": "PUMS Z.MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15043",
            "district": "1",
            "Block": "16",
            "label": "ANNAI LOURD(GIRLS)HSS THENNUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15044",
            "district": "1",
            "Block": "16",
            "label": "GHS NAGAMPANTHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15045",
            "district": "1",
            "Block": "16",
            "label": "PUMS CHINNATHUKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21517",
            "district": "1",
            "Block": "16",
            "label": "ADWHS VILANTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22543",
            "district": "1",
            "Block": "16",
            "label": "GHS SILAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22544",
            "district": "1",
            "Block": "16",
            "label": "GHS ELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23292",
            "district": "1",
            "Block": "16",
            "label": "OHM SAKTHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23426",
            "district": "1",
            "Block": "16",
            "label": "FRANS SCHLOOZ SCHOOL OF EXCELLENCE (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23426",
            "district": "1",
            "Block": "16",
            "label": "FRANS SCHLOOZ SCHOOL OF EXCELLENCE (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23426",
            "district": "1",
            "Block": "16",
            "label": "FRANS SCHLOOZ SCHOOL OF EXCELLENCE (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23426",
            "district": "1",
            "Block": "16",
            "label": "FRANS SCHLOOZ SCHOOL OF EXCELLENCE (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23717",
            "district": "1",
            "Block": "16",
            "label": "PUMS DEVANUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "17": [
        {
            "key": "4273",
            "district": "5",
            "Block": "17",
            "label": "BWDA MATRIC HR SEC SCHOOL,  RASAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5204",
            "district": "5",
            "Block": "17",
            "label": "PUMS KATTUMUTHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5305",
            "district": "5",
            "Block": "17",
            "label": "PUMS C.PAGANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5665",
            "district": "5",
            "Block": "17",
            "label": "PUMS CHITARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5702",
            "district": "5",
            "Block": "17",
            "label": "PUMS MUTHUKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5834",
            "district": "5",
            "Block": "17",
            "label": "PUMS PAITHAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5952",
            "district": "5",
            "Block": "17",
            "label": "PUMS SUNDARAVANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6605",
            "district": "5",
            "Block": "17",
            "label": "PUMS S.K.PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6658",
            "district": "5",
            "Block": "17",
            "label": "MMS MULLIGRAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15189",
            "district": "5",
            "Block": "17",
            "label": "PUMS-REDDIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15190",
            "district": "5",
            "Block": "17",
            "label": "PUMS BANDARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15191",
            "district": "5",
            "Block": "17",
            "label": "GHS ENATHIRIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15192",
            "district": "5",
            "Block": "17",
            "label": "GOVERNMENT HIGH SCHOOL,  KANDARAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15193",
            "district": "5",
            "Block": "17",
            "label": "PUMS KARUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15194",
            "district": "5",
            "Block": "17",
            "label": "GOVERNMENT HIGHER SEC SCHOOL, KOZHIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15195",
            "district": "5",
            "Block": "17",
            "label": "PUMS THATTAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15196",
            "district": "5",
            "Block": "17",
            "label": "GHS MELKUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15197",
            "district": "5",
            "Block": "17",
            "label": "PUMS MELPATTAMPAKKAM GIRLS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15198",
            "district": "5",
            "Block": "17",
            "label": "AHSS, G. CHR. HR. SEC. SCH, MELPATTAMPAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15199",
            "district": "5",
            "Block": "17",
            "label": "GHS, MELPATTAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15200",
            "district": "5",
            "Block": "17",
            "label": "PUMS ORAIYUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15201",
            "district": "5",
            "Block": "17",
            "label": "GHSS ORAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15202",
            "district": "5",
            "Block": "17",
            "label": "PUMS PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15203",
            "district": "5",
            "Block": "17",
            "label": "ST.PETER'S HS, T.SATHIPATTU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15204",
            "district": "5",
            "Block": "17",
            "label": "MOTHER THERESA MATRIC HIGHER SECONDARY SCHOOL - SATHIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15205",
            "district": "5",
            "Block": "17",
            "label": "GHSS, THIRUTHURAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15206",
            "district": "5",
            "Block": "17",
            "label": "GGHS, PUDHUPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15207",
            "district": "5",
            "Block": "17",
            "label": "GOVT BOYS HIGHER SECONDARY SCHOOL ,PUTHUPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15208",
            "district": "5",
            "Block": "17",
            "label": "AMS KILPATHY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23405",
            "district": "5",
            "Block": "17",
            "label": "Danish Mission Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15209",
            "district": "5",
            "Block": "17",
            "label": "Govt Boys Higher Secondary School,  NELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15210",
            "district": "5",
            "Block": "17",
            "label": "GGHSS, NELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15211",
            "district": "5",
            "Block": "17",
            "label": "BALAVIHAR MATRIC HR SEC SCHOOL, NELLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15212",
            "district": "5",
            "Block": "17",
            "label": "AHSS, NELLIKUPPAM (DM)",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15213",
            "district": "5",
            "Block": "17",
            "label": "ST. PAUL MAT. HR. SEC .SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15214",
            "district": "5",
            "Block": "17",
            "label": "ST.DOMINIC SAVIO MAT.HR.SEC. SCH.NELLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15215",
            "district": "5",
            "Block": "17",
            "label": "RR MATRIC HR SEC SCHOOL,NELLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15216",
            "district": "5",
            "Block": "17",
            "label": "MUNICIPAL HIGH SCHOOL,  THIRUKANDESWARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15217",
            "district": "5",
            "Block": "17",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21695",
            "district": "5",
            "Block": "17",
            "label": "PALLAVA INTERNATIONAL CBSE SCHOOL, KEEZHAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22549",
            "district": "5",
            "Block": "17",
            "label": "GHS-MALIGAIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22550",
            "district": "5",
            "Block": "17",
            "label": "EDIFY SCHOOL, VAZHAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23718",
            "district": "5",
            "Block": "17",
            "label": "PUMS PANAPAKKAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "18": [
        {
            "key": "847",
            "district": "19",
            "Block": "18",
            "label": "PONMARI VID MATRIC,POMMADIMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2187",
            "district": "19",
            "Block": "18",
            "label": "INFANTJESUS MAT.HSS.ANNAVASAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5449",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.OORAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6158",
            "district": "19",
            "Block": "18",
            "label": "PSK MATRIC HIGHER SECONDARY SCHOOL, POMMADIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16793",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL,THIRUNALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16794",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MURUGAKONPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16795",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16796",
            "district": "19",
            "Block": "18",
            "label": "ST.IGNATIUS.HS -SATHAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16797",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHIRAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16798",
            "district": "19",
            "Block": "18",
            "label": "GHSS,MALAIKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16799",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ENNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16800",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.MELAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16801",
            "district": "19",
            "Block": "18",
            "label": "ADWHS.ENNAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16802",
            "district": "19",
            "Block": "18",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL,ILLUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16803",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VETTUKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16804",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ERUKKUMANIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16805",
            "district": "19",
            "Block": "18",
            "label": "GOVT HR SEC SCHOOL, RAPPUSAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16806",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.KEELAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16807",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY  SCHOOL,NARTHAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16808",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.KOTHAMANGALAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16809",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.CAUVERYNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16810",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.POONGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16811",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL VADASERIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16812",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY  SCHOOL.SATHIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16813",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.KALADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16814",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.VEERAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16815",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.EDAYAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16816",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16817",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL.KILIKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16818",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.PARAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16819",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.P.PANAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16820",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.KULAVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16821",
            "district": "19",
            "Block": "18",
            "label": "GOVT HR SEC SCHOOL. MANNAVELAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16822",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL.MATHIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16823",
            "district": "19",
            "Block": "18",
            "label": "GHSS.MARUTHANTHALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16824",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL .T.METTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16825",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.PERUNJUNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16826",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.AGARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16827",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.VAYALOGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16828",
            "district": "19",
            "Block": "18",
            "label": "ST.ANNE'S HS MUTHALIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16829",
            "district": "19",
            "Block": "18",
            "label": "GOVERNMENT HIGH SCHOOL.KUDUMIYANMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16830",
            "district": "19",
            "Block": "18",
            "label": "RC.HIGHER SECONDARY SCHOOL,ILLUPPUR.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16831",
            "district": "19",
            "Block": "18",
            "label": "GOVT.HR.SEC.SCHOOL,ILLUPPUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16832",
            "district": "19",
            "Block": "18",
            "label": "ARULMALAR MATRIC SCHOOL,ILLUPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16833",
            "district": "19",
            "Block": "18",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL,ILLUPPUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16834",
            "district": "19",
            "Block": "18",
            "label": "MOTHER TERASA MATRIC.METTUSALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16835",
            "district": "19",
            "Block": "18",
            "label": "VAIRAMS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16836",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ESWARANKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16837",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHITHANNAVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16838",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGHER SECONDARY SCHOOL.ANNAVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16839",
            "district": "19",
            "Block": "18",
            "label": "KOKILA MATRICULATION SCHOOL, ANNAVASAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16840",
            "district": "19",
            "Block": "18",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.KATHAVAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16841",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL.MELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16842",
            "district": "19",
            "Block": "18",
            "label": "GOVT HSS SCHOOL.MUKKANAMALAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16843",
            "district": "19",
            "Block": "18",
            "label": "MAJESTIC MATRIC MUKKANAMALAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21479",
            "district": "19",
            "Block": "18",
            "label": "GHS PULVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21480",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL PERUMANADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22584",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL.IRUNTHIRAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22763",
            "district": "19",
            "Block": "18",
            "label": "ST. JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22913",
            "district": "19",
            "Block": "18",
            "label": "GOVT HIGH SCHOOL MANGUDY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22937",
            "district": "19",
            "Block": "18",
            "label": "GOVERMENT GIRLS HIGH SCHOOL, ANNAVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23286",
            "district": "19",
            "Block": "18",
            "label": "GOVERNMENT HIGH SCHOOL KEZHA PALUVANCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23528",
            "district": "19",
            "Block": "18",
            "label": "M.S.A  MATRICULATION SCHOOL. KEELAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23528",
            "district": "19",
            "Block": "18",
            "label": "M.S.A  MATRICULATION SCHOOL. KEELAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23719",
            "district": "19",
            "Block": "18",
            "label": "SANTHOMA MATRICULLATION SCHOOL VIDIVAKAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23720",
            "district": "19",
            "Block": "18",
            "label": "DURAIRAJ MEMORIAL MATRICULATION SCHOOL PERUMANADU",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "19": [
        {
            "key": "377",
            "district": "4",
            "Block": "19",
            "label": "K.G. MATRICULATION HR SEC SCHOOL, ANNUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "751",
            "district": "4",
            "Block": "19",
            "label": "ST. ANTONY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2549",
            "district": "4",
            "Block": "19",
            "label": "SWAMI VIVEKANANDA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13066",
            "district": "4",
            "Block": "19",
            "label": "GHSS ANAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13067",
            "district": "4",
            "Block": "19",
            "label": "PUMS, AKKARAI SENGAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13068",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDILE SCHOOL, POGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13069",
            "district": "4",
            "Block": "19",
            "label": "GOVERNMENT HIGH SCHOOL, PERIYAPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13070",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUNNATHUR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13071",
            "district": "4",
            "Block": "19",
            "label": "SREE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13072",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13073",
            "district": "4",
            "Block": "19",
            "label": "T.S.A. GOVT HR.SEC.SCHOOL, KATTAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13074",
            "district": "4",
            "Block": "19",
            "label": "SRI BHARATHI MATRICULATION HIGHER SECONDRY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13075",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ORAIKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13076",
            "district": "4",
            "Block": "19",
            "label": "GOVERNMENT HIGH SCHOOL PONNEGOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13077",
            "district": "4",
            "Block": "19",
            "label": "PUMS VADAKKALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13078",
            "district": "4",
            "Block": "19",
            "label": "PUMS KANUVAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13079",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOLC, SELLAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13080",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AAMBOTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13081",
            "district": "4",
            "Block": "19",
            "label": "PUMS, ANNUR METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13082",
            "district": "4",
            "Block": "19",
            "label": "PUMS, OTTERPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13083",
            "district": "4",
            "Block": "19",
            "label": "ST MARY'S CONVENT MATRICULATION HIGHER SECONDARY SCHOOL, ALLIKKARANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13084",
            "district": "4",
            "Block": "19",
            "label": "PUMS, KANJAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13085",
            "district": "4",
            "Block": "19",
            "label": "PUMS, PUTHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13086",
            "district": "4",
            "Block": "19",
            "label": "GHSS, KEMBANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13087",
            "district": "4",
            "Block": "19",
            "label": "PANCAHAYAT UNION MIDDLE SCHOOL, ELLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13088",
            "district": "4",
            "Block": "19",
            "label": "STANES MATRICULATION SCHOOL, ELLAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23686",
            "district": "4",
            "Block": "19",
            "label": "KPR Women Employees Educational Division",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13089",
            "district": "4",
            "Block": "19",
            "label": "PUMS, PILLAIYAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13090",
            "district": "4",
            "Block": "19",
            "label": "PUMS, ANNUR KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13091",
            "district": "4",
            "Block": "19",
            "label": "AMARAR A. MUTHUGOUNDER  GOVERNMENT HIGHER SECONDARY SCHOOL, ANNUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13092",
            "district": "4",
            "Block": "19",
            "label": "GANDHIJI GOVT HR SEC SCHOOL, SOKKAMPALAYAM 641653",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13093",
            "district": "4",
            "Block": "19",
            "label": "K.G. GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13094",
            "district": "4",
            "Block": "19",
            "label": "SRI AKILA VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21316",
            "district": "4",
            "Block": "19",
            "label": "K.G.INTERNATIONALSCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21501",
            "district": "4",
            "Block": "19",
            "label": "GOVT ADIDRAVIDA WELFARE HIGH SCHOOL, PASUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21648",
            "district": "4",
            "Block": "19",
            "label": "SRI AMBAL THULASI PUBLIC SCHOOL SENIOR SECONDARY CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21653",
            "district": "4",
            "Block": "19",
            "label": "NAVABHARATH NATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22147",
            "district": "4",
            "Block": "19",
            "label": "KOVAI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23051",
            "district": "4",
            "Block": "19",
            "label": "NAVA BHARATH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23208",
            "district": "4",
            "Block": "19",
            "label": "WHITE EAGLE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23425",
            "district": "4",
            "Block": "19",
            "label": "BENEVOLENCE INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23425",
            "district": "4",
            "Block": "19",
            "label": "BENEVOLENCE INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23425",
            "district": "4",
            "Block": "19",
            "label": "BENEVOLENCE INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23425",
            "district": "4",
            "Block": "19",
            "label": "BENEVOLENCE INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23425",
            "district": "4",
            "Block": "19",
            "label": "BENEVOLENCE INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23721",
            "district": "4",
            "Block": "19",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23722",
            "district": "4",
            "Block": "19",
            "label": "KOVAI VIDYASHRAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "20": [
        {
            "key": "643",
            "district": "8",
            "Block": "20",
            "label": "PUMS BRAMDESAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "653",
            "district": "8",
            "Block": "20",
            "label": "PUMS KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "657",
            "district": "8",
            "Block": "20",
            "label": "PUMS ANTHIYUR EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "670",
            "district": "8",
            "Block": "20",
            "label": "PUMS KUPPANDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "720",
            "district": "8",
            "Block": "20",
            "label": "PUMS, L.SEMBULICHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "732",
            "district": "8",
            "Block": "20",
            "label": "PUMS  ENNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "747",
            "district": "8",
            "Block": "20",
            "label": "PUMS THATTAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "883",
            "district": "8",
            "Block": "20",
            "label": "MANGALAM MATRICULATION SCHOOL, ANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "935",
            "district": "8",
            "Block": "20",
            "label": "GTRMS KAKAYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "943",
            "district": "8",
            "Block": "20",
            "label": "PUMS OSAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "979",
            "district": "8",
            "Block": "20",
            "label": "PUMS SANTHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "982",
            "district": "8",
            "Block": "20",
            "label": "MANGALAM CENTRAL SCHOOL, ANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1028",
            "district": "8",
            "Block": "20",
            "label": "PUMS A.SEMPULICHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1049",
            "district": "8",
            "Block": "20",
            "label": "PUMS KOOTHAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1191",
            "district": "8",
            "Block": "20",
            "label": "PUMS SELLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1237",
            "district": "8",
            "Block": "20",
            "label": "PUMS KARATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1260",
            "district": "8",
            "Block": "20",
            "label": "PUMS SETHUNAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1310",
            "district": "8",
            "Block": "20",
            "label": "VISHWESVARAIYAH MHSS THOPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1333",
            "district": "8",
            "Block": "20",
            "label": "PUMS ONTHANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1357",
            "district": "8",
            "Block": "20",
            "label": "GTRMS SOLAKANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1401",
            "district": "8",
            "Block": "20",
            "label": "PUMS DHOLLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1555",
            "district": "8",
            "Block": "20",
            "label": "VIVEKANANDHA VIDHYALAYA MHS ANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1556",
            "district": "8",
            "Block": "20",
            "label": "PUMS THOTTAKUDIAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1730",
            "district": "8",
            "Block": "20",
            "label": "PUMS PERIYASENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1787",
            "district": "8",
            "Block": "20",
            "label": "PUMS OOSIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1824",
            "district": "8",
            "Block": "20",
            "label": "PUMS THALAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1989",
            "district": "8",
            "Block": "20",
            "label": "PUMS BEJILPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1994",
            "district": "8",
            "Block": "20",
            "label": "PUMS DHURUSANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2077",
            "district": "8",
            "Block": "20",
            "label": "LITTLE FLOWER MHS KARATTUR MEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2107",
            "district": "8",
            "Block": "20",
            "label": "PUMS SUNDAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2140",
            "district": "8",
            "Block": "20",
            "label": "PUMS THAMMUREDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2232",
            "district": "8",
            "Block": "20",
            "label": "PUMS PUDUMETTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2241",
            "district": "8",
            "Block": "20",
            "label": "PUMS KEELVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2255",
            "district": "8",
            "Block": "20",
            "label": "PUMS EPPATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2427",
            "district": "8",
            "Block": "20",
            "label": "PUMS BEJILATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3172",
            "district": "8",
            "Block": "20",
            "label": "PUMS  ERATTIMINTHANGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4129",
            "district": "8",
            "Block": "20",
            "label": "PUMS , MADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12031",
            "district": "8",
            "Block": "20",
            "label": "GBHSS ANTHIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12032",
            "district": "8",
            "Block": "20",
            "label": "GGHSS ANTHIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12033",
            "district": "8",
            "Block": "20",
            "label": "KASTURIBA GANDHI BALIKA VIDYALAYA,  PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12034",
            "district": "8",
            "Block": "20",
            "label": "GHS THAVITTUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12035",
            "district": "8",
            "Block": "20",
            "label": "GHSS ATHANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12036",
            "district": "8",
            "Block": "20",
            "label": "GTRMS KUTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12037",
            "district": "8",
            "Block": "20",
            "label": "GTRMS KATHIRIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12038",
            "district": "8",
            "Block": "20",
            "label": "PUMS KADAIERATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12039",
            "district": "8",
            "Block": "20",
            "label": "PUMS  SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12040",
            "district": "8",
            "Block": "20",
            "label": "GTRHSS  BARGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12041",
            "district": "8",
            "Block": "20",
            "label": "GHSS OOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12042",
            "district": "8",
            "Block": "20",
            "label": "GHSS  DEVARMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12043",
            "district": "8",
            "Block": "20",
            "label": "GHSS A.SEMBULICHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12044",
            "district": "8",
            "Block": "20",
            "label": "PUMS KATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12045",
            "district": "8",
            "Block": "20",
            "label": "PUMS A.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12046",
            "district": "8",
            "Block": "20",
            "label": "GTRMS KINATHADISOZHAGA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12047",
            "district": "8",
            "Block": "20",
            "label": "ST. MICHAEL'S HSS, MICHAELPALAYAM.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12048",
            "district": "8",
            "Block": "20",
            "label": "GHS PUDHUKADU GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12049",
            "district": "8",
            "Block": "20",
            "label": "GHSS MOONGILPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12050",
            "district": "8",
            "Block": "20",
            "label": "ST.SEBASTIAN'S HIGH SCHOOL NAGALUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12051",
            "district": "8",
            "Block": "20",
            "label": "MANGALAM HSS ANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12052",
            "district": "8",
            "Block": "20",
            "label": "GHS PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12053",
            "district": "8",
            "Block": "20",
            "label": "PUMS SANKARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12054",
            "district": "8",
            "Block": "20",
            "label": "SAKTHI HSS NACHIMUTHUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21216",
            "district": "8",
            "Block": "20",
            "label": "GMHSS,SELLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22455",
            "district": "8",
            "Block": "20",
            "label": "VIVEGAM VIDHYABHAVAN MATRICULATION SCHOOL, VEMPATHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22456",
            "district": "8",
            "Block": "20",
            "label": "GOVERNMENT HIGH SCHOOL, SINTHAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22703",
            "district": "8",
            "Block": "20",
            "label": "KSC PUBLIC SCHOOL, MANJALANAICKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22744",
            "district": "8",
            "Block": "20",
            "label": "GTRHS, KONGADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22869",
            "district": "8",
            "Block": "20",
            "label": "GHS, THAMARAIKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23096",
            "district": "8",
            "Block": "20",
            "label": "NAKKEERAN MATRICULATION SCHOOL, SANDHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "21": [
        {
            "key": "562",
            "district": "21",
            "Block": "21",
            "label": "DR VGN MATRIC HSS, ARAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "687",
            "district": "21",
            "Block": "21",
            "label": "ROYAL MATRIC HSS, THAKKOLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1205",
            "district": "21",
            "Block": "21",
            "label": "BHARATHIDHASANAR MATRIC HR SEC SCHOOL, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1634",
            "district": "21",
            "Block": "21",
            "label": "VIVEKANANDA VIDYALAYA MATRIC HSS,ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1653",
            "district": "21",
            "Block": "21",
            "label": "JOTHI VALLALAR MIDDLE SCHOOL ARAKKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2073",
            "district": "21",
            "Block": "21",
            "label": "MARY'S VISION MHSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2137",
            "district": "21",
            "Block": "21",
            "label": "SMS VIMAL MHSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2750",
            "district": "21",
            "Block": "21",
            "label": "SRI KRISHNA MATRIC HSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2820",
            "district": "21",
            "Block": "21",
            "label": "VALLUVAR MATRIC HSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2929",
            "district": "21",
            "Block": "21",
            "label": "PUMS, SEMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3050",
            "district": "21",
            "Block": "21",
            "label": "PUMS NAGARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3611",
            "district": "21",
            "Block": "21",
            "label": "PUMS, ANAIKATTA PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3802",
            "district": "21",
            "Block": "21",
            "label": "SACRED HEART MHSS ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3812",
            "district": "21",
            "Block": "21",
            "label": "PUMS SEYYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4297",
            "district": "21",
            "Block": "21",
            "label": "RAILWAY MIXED HS ARAKKONAM",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "4503",
            "district": "21",
            "Block": "21",
            "label": "PUMS GEDARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4564",
            "district": "21",
            "Block": "21",
            "label": "ST. XAVIER'S MATRIC SCHOOL, SOGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4958",
            "district": "21",
            "Block": "21",
            "label": "SELVAM MATRIC HSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5468",
            "district": "21",
            "Block": "21",
            "label": "PUMS, MARANKANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5520",
            "district": "21",
            "Block": "21",
            "label": "PUMS KAVANOOR NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5820",
            "district": "21",
            "Block": "21",
            "label": "GABRIELS MIDDLE SCHOOL ARAKKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5956",
            "district": "21",
            "Block": "21",
            "label": "PUMS VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6157",
            "district": "21",
            "Block": "21",
            "label": "PUMS-VEDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6630",
            "district": "21",
            "Block": "21",
            "label": "PUMS-THANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6745",
            "district": "21",
            "Block": "21",
            "label": "CSI MIDDLE AIDED-PARANJI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8398",
            "district": "21",
            "Block": "21",
            "label": "GHS THANIGAI POLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8399",
            "district": "21",
            "Block": "21",
            "label": "KALVIKKOTTAI CHRISTIAN HR.SEC SCHOOL THANIGAI POLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8400",
            "district": "21",
            "Block": "21",
            "label": "PUMS KIZHANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8401",
            "district": "21",
            "Block": "21",
            "label": "GHS  PERUNGALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8402",
            "district": "21",
            "Block": "21",
            "label": "GHSS VALARPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8403",
            "district": "21",
            "Block": "21",
            "label": "GOVT HR SEC SCHOOL MUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8404",
            "district": "21",
            "Block": "21",
            "label": "GHS MULVOY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8405",
            "district": "21",
            "Block": "21",
            "label": "CSI HS KILPAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8406",
            "district": "21",
            "Block": "21",
            "label": "RCM HS ARAKKONAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8407",
            "district": "21",
            "Block": "21",
            "label": "GHSS  ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8408",
            "district": "21",
            "Block": "21",
            "label": "G(G) HSS ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8409",
            "district": "21",
            "Block": "21",
            "label": "G(G)ADWHSS ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8410",
            "district": "21",
            "Block": "21",
            "label": "CSI ST ANDREWS HSS, ARAKKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8411",
            "district": "21",
            "Block": "21",
            "label": "CSI(C)  HSS ARAKKONAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8412",
            "district": "21",
            "Block": "21",
            "label": "MMS ARAKKONAM EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8413",
            "district": "21",
            "Block": "21",
            "label": "MMS GANDHI NAGER ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8414",
            "district": "21",
            "Block": "21",
            "label": "MMS POLATCHI ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8415",
            "district": "21",
            "Block": "21",
            "label": "M A JAIN MMS WINTERPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8416",
            "district": "21",
            "Block": "21",
            "label": "KENDRIYA VIDHYALAYA HSS PERUMOOCHI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8417",
            "district": "21",
            "Block": "21",
            "label": "DHANAM PACHAIYAPPAN MATRIC HSS, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8418",
            "district": "21",
            "Block": "21",
            "label": "PUMS PULIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8419",
            "district": "21",
            "Block": "21",
            "label": "GHSS MOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8420",
            "district": "21",
            "Block": "21",
            "label": "PUMS PERUMUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8421",
            "district": "21",
            "Block": "21",
            "label": "G(B)ADWHSS ARAKKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8422",
            "district": "21",
            "Block": "21",
            "label": "PUMS POIYAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8423",
            "district": "21",
            "Block": "21",
            "label": "DAYANA MAT SCH NEWPET THAKKOLAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8424",
            "district": "21",
            "Block": "21",
            "label": "G(B)HSS THAKKOLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8425",
            "district": "21",
            "Block": "21",
            "label": "G(G)HSS, THAKKOLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8426",
            "district": "21",
            "Block": "21",
            "label": "DONBOSCO HS SAGAYATHOTTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8427",
            "district": "21",
            "Block": "21",
            "label": "KENDRIYA VIDHYALAYA SCHOOL,  THAKKOLAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8428",
            "district": "21",
            "Block": "21",
            "label": "R C M MIDD SCHOOL URIYURKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8445",
            "district": "21",
            "Block": "21",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MELPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8446",
            "district": "21",
            "Block": "21",
            "label": "GHSS KUMPINIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8476",
            "district": "21",
            "Block": "21",
            "label": "PUMS, SOGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8477",
            "district": "21",
            "Block": "21",
            "label": "G(G)HSS GURUVARAJAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8478",
            "district": "21",
            "Block": "21",
            "label": "G(B)HSS GURUVARAJAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8479",
            "district": "21",
            "Block": "21",
            "label": "GBHSS MINNAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8480",
            "district": "21",
            "Block": "21",
            "label": "G(G)HSS NARASINGAPURAM(MINNAL)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8481",
            "district": "21",
            "Block": "21",
            "label": "GOVT HIGH SCHOOL MITTAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8499",
            "district": "21",
            "Block": "21",
            "label": "GOVT HS ANVARTHIKHANPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8500",
            "district": "21",
            "Block": "21",
            "label": "PUMS-KILAVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8501",
            "district": "21",
            "Block": "21",
            "label": "PUMS-ITCHIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8502",
            "district": "21",
            "Block": "21",
            "label": "KINGS & QUEENS CBSE THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21574",
            "district": "21",
            "Block": "21",
            "label": "SNV  MATRIC SCHOOL, THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21771",
            "district": "21",
            "Block": "21",
            "label": "PARAMPARA CBSE SCHOOL, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21772",
            "district": "21",
            "Block": "21",
            "label": "TRS GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22059",
            "district": "21",
            "Block": "21",
            "label": "GOVT ADW HIGH SCHOOL, AMMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22378",
            "district": "21",
            "Block": "21",
            "label": "NAVY CHILDREN'S SCHOOL",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        { 
            "key":"24130", "district":"21", 
            "Block":"21", "label": "JAIG Senior Secondary School ", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "22736",
            "district": "21",
            "Block": "21",
            "label": "HAYAGRIVAR VIDYAASHRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22825",
            "district": "21",
            "Block": "21",
            "label": "JEEVA MATRIC. HR. SEC. SCHOOL, ARAKKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23165",
            "district": "21",
            "Block": "21",
            "label": "AMBARI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23723",
            "district": "21",
            "Block": "21",
            "label": "HAYAGRIVAR VIDYAASHRAM",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "22": [
        {
            "key": "717",
            "district": "19",
            "Block": "22",
            "label": "ANNAI MEENATCHI NACHIAR MATRIC HR SEC SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "824",
            "district": "19",
            "Block": "22",
            "label": "CRESCENT MATRIC SCHOOL, RAJENDRAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "932",
            "district": "19",
            "Block": "22",
            "label": "SELECTION MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1052",
            "district": "19",
            "Block": "22",
            "label": "UMAIYALACHI MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1115",
            "district": "19",
            "Block": "22",
            "label": "ST. JOHN'S MATRIC HR. SEC. SCHOOL, RETHINAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1473",
            "district": "19",
            "Block": "22",
            "label": "THAAYAGAM MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1479",
            "district": "19",
            "Block": "22",
            "label": "NATIONAL MATRIC HR SEC SCHOOL,ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4836",
            "district": "19",
            "Block": "22",
            "label": "MAEIL MATRICULATION SCHOOL,ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4909",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MERPANAIKKADU EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5364",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KODIVAYAL EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5409",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALAPPIRANTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5871",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AYINGUDI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5913",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVANATHANKOTTAI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6042",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAYAKKARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6218",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , EDAIYANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6345",
            "district": "19",
            "Block": "22",
            "label": "NAZREEN MATRIC SCHOOL, RETHINAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6451",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PACHALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6488",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERIYALUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6616",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17183",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL MARAMADAKKI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17184",
            "district": "19",
            "Block": "22",
            "label": "PUNCHAYAT UNION MIDDLE SCHOOL PARAVAKOTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17185",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KURUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17186",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,NARPAVALASENGAMARI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17187",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL, THANTHANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17188",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL, CHIDAMBARAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17189",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVANATHANKOTTAI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17190",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUNNAKURUMBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17191",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL, SILATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17192",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALIYANILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17193",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANNAMALAIYAN KUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17194",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL, POOVAIMANAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17195",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, PERIYALUR EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17196",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEIVATHALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17197",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL MERPANAIKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17198",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , RAMASAMYPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17199",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VALLAVARI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17200",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, VALLAVARI EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17201",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONNAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17202",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUNTHIRAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17203",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL , ERUKKALAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17204",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL , RAJENDRAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17205",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17206",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VAIRIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17207",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RETHINAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17208",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOOTHADIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17209",
            "district": "19",
            "Block": "22",
            "label": "LAUREL HIGHER SECONDARY SCHOOL, KURUMBAKKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17210",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, ARASARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17211",
            "district": "19",
            "Block": "22",
            "label": "ALHIDHAYA JAMATH ORIENTAL ARABIC HIGH SCHOOL, ARASARKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17212",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL MANGALANADU EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17213",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, AMARASIMENDRAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17214",
            "district": "19",
            "Block": "22",
            "label": "GOVT BOYS HR SEC SCHOOL,SUBRAMANIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17215",
            "district": "19",
            "Block": "22",
            "label": "GOVT GIRLS HR SEC SCHOOL, SUBRAMANIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17216",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARUNGULIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17217",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,PERUNGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17218",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,MELAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17219",
            "district": "19",
            "Block": "22",
            "label": "DOCTORS PUBLIC SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17220",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VIGNESWARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17221",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VEERANDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17222",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KAMMANGADU NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17223",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,THIRUNALUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17224",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, THIRUNALLUR SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17225",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL, AYINGUDI SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17226",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17227",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOOKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17228",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,PANCHATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17229",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,PUDUVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17230",
            "district": "19",
            "Block": "22",
            "label": "ST.MARYS R.C HIGH SCHOOL, EGANIVAYAL THENPATHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17231",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, DURAIYARASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17232",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17233",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL ATHANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17234",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THATTANVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17235",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,NAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17236",
            "district": "19",
            "Block": "22",
            "label": "GOVT HR SEC SCHOOL,NAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17237",
            "district": "19",
            "Block": "22",
            "label": "GOVERNMENT MODEL  HIGHER  SECONDARY  SCHOOL , ARANTHANGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17238",
            "district": "19",
            "Block": "22",
            "label": "MUNICIPAL MIDDLE SCHOOL L.N.PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17239",
            "district": "19",
            "Block": "22",
            "label": "IDEAL MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17240",
            "district": "19",
            "Block": "22",
            "label": "CHIRST KING R.C. MIDDLE SCHOOL, ARANTHANGI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17241",
            "district": "19",
            "Block": "22",
            "label": "VESTLEY HR SEC SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17242",
            "district": "19",
            "Block": "22",
            "label": "S.M.A.MIDDLE.SCHOOL,CHITTANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17243",
            "district": "19",
            "Block": "22",
            "label": "SATHYAMOORTHY MIDDLE SCHOOL, ARANTHANGI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17244",
            "district": "19",
            "Block": "22",
            "label": "A.J.J.ORIENTAL ARABIC HR SEC SCHOOL,ARANTHANGI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17245",
            "district": "19",
            "Block": "22",
            "label": "GOVT GIRLS HR SEC SCHOOL,ARANTHANGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17246",
            "district": "19",
            "Block": "22",
            "label": "KAMARAJ HIGH SCHOOL, ARANTHANGI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17247",
            "district": "19",
            "Block": "22",
            "label": "MUNICIPAL MIDDLE SCHOOL WEST, ARANTHANGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17248",
            "district": "19",
            "Block": "22",
            "label": "T.E.L.C MIDDLE SCHOOL,ARANTHANGI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17249",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,SUNAIYAKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17250",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,THOLUVANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17251",
            "district": "19",
            "Block": "22",
            "label": "GOVT HIGH SCHOOL,ARASARKULAM EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17252",
            "district": "19",
            "Block": "22",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MENAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21483",
            "district": "19",
            "Block": "22",
            "label": "GOVT GIRLS HIGH SCHOOL SILATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21558",
            "district": "19",
            "Block": "22",
            "label": "AMIRTHA VIDYA VIKAAS HR. SEC. SCHOOL, KOVILVAYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21752",
            "district": "19",
            "Block": "22",
            "label": "SHIVAANI VIDHYAA MANDIR HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21966",
            "district": "19",
            "Block": "22",
            "label": "ARANTHAI  ANNAI SPEECH AND HEARING IMPAIRMENT SPECIAL SCHOOL ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23355",
            "district": "19",
            "Block": "22",
            "label": "Excellent nursery & primary( private) school",
            "Sch_type": "Un-aided",
            "Category": "Primary School"
        },
        {
            "key": "23356",
            "district": "19",
            "Block": "22",
            "label": "Swamy Vivekananda nursery & primary( private) school",
            "Sch_type": "Un-aided",
            "Category": "Primary School"
        },
        {
            "key": "22233",
            "district": "19",
            "Block": "22",
            "label": "SHEIK FATHIMA GIRLS MATRIC HR. SEC. SCHOOL, ARANTHANGI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22957",
            "district": "19",
            "Block": "22",
            "label": "SAI LAUREL INTERNATIONAL SCHOOL,KURUMBAKKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23724",
            "district": "19",
            "Block": "22",
            "label": "KURUKULAM NURSERY AND PRIMARY SCHOOL  ALANJIRANKADU",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23725",
            "district": "19",
            "Block": "22",
            "label": "MONTFORT SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "23": [
        {
            "key": "1356",
            "district": "12",
            "Block": "23",
            "label": "CRESCENT MATRIC HSS,PALLAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2548",
            "district": "12",
            "Block": "23",
            "label": "ARUMUGAM ACADEMY MATRIC HR.SEC.SCHOOL, ARAVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3390",
            "district": "12",
            "Block": "23",
            "label": "VENUS MATRIC SCHOOL,PALLAPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3556",
            "district": "12",
            "Block": "23",
            "label": "B.S.P.MATRIC  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3977",
            "district": "12",
            "Block": "23",
            "label": "HAJEE MEERA ACADEMY,ESANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4855",
            "district": "12",
            "Block": "23",
            "label": "OXFORD MATRIC SCHOOL,PALLAPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6371",
            "district": "12",
            "Block": "23",
            "label": "G.K.VIDYAMANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6473",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL, THIRUMANICKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6501",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,MALAIKOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6536",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,SEMBAGANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6706",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL, PALLAPATTI URUSU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13894",
            "district": "12",
            "Block": "23",
            "label": "GHSS, MALAIKOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13895",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,ARAVAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13896",
            "district": "12",
            "Block": "23",
            "label": "GOVT GIRLS HIGH SCHOOL,ARAVAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13897",
            "district": "12",
            "Block": "23",
            "label": "GHSS,ARAVAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13898",
            "district": "12",
            "Block": "23",
            "label": "Government High School,Varikkappatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13899",
            "district": "12",
            "Block": "23",
            "label": "GHS,KOVILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13900",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,INANGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13901",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,A.VENKATAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13902",
            "district": "12",
            "Block": "23",
            "label": "GHS,KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13903",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,MOLAIYANDIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13904",
            "district": "12",
            "Block": "23",
            "label": "GHS,SOUNDARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13905",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,PALLAPATTI(NEW)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13906",
            "district": "12",
            "Block": "23",
            "label": "PALLAPATTY HSS,PALLAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13907",
            "district": "12",
            "Block": "23",
            "label": "USWATHUN HASANA ORIENTAL ARABIC,PALLAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13908",
            "district": "12",
            "Block": "23",
            "label": "GHSS,ANDIPATTIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13909",
            "district": "12",
            "Block": "23",
            "label": "P.U.M.SCHOOL,ESANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13910",
            "district": "12",
            "Block": "23",
            "label": "GOVT.HIGHER.SEC.SCHOOL,ESANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13911",
            "district": "12",
            "Block": "23",
            "label": "P.U.MID.SCHOOL,ZAMINATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20972",
            "district": "12",
            "Block": "23",
            "label": "ISHETHRA INTERNATIONAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21757",
            "district": "12",
            "Block": "23",
            "label": "USWATHUN HASANA ISLAMIC INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22512",
            "district": "12",
            "Block": "23",
            "label": "GOVERNMENT HIGH SCHOOL, K . SEETHAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23665",
            "district": "12",
            "Block": "23",
            "label": "Ferdin Public School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23726",
            "district": "12",
            "Block": "23",
            "label": "USWATHUN HASANA ORIENTAL ARABIC,PALLAPATTI",
            "Sch_type": "Fully AidedHigher SecondarySchool"
        }
    ],
    "24": [
        {
            "key": "538",
            "district": "21",
            "Block": "24",
            "label": "KH MATRIC (G) HSS, MELVISHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "588",
            "district": "21",
            "Block": "24",
            "label": "RATHINAGIRI BAGEERATHAN MATRIC HSS, RATHINAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1477",
            "district": "21",
            "Block": "24",
            "label": "HAKEEM MATRIC SCHOOL , MELVISHARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2360",
            "district": "21",
            "Block": "24",
            "label": "ST. PIO MATRIC HIGH  SCHOOL,  UPPUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4168",
            "district": "21",
            "Block": "24",
            "label": "PUMS MANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4343",
            "district": "21",
            "Block": "24",
            "label": "ST.JOSEPH MATRIC HSS, VEPPOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4608",
            "district": "21",
            "Block": "24",
            "label": "PUNCHAYAT UNION MIDDLE SCHOOL,  NARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5251",
            "district": "21",
            "Block": "24",
            "label": "PUNCHAYAT UNION MIDDLE SCHOOL, THENNANDIYALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6166",
            "district": "21",
            "Block": "24",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KILMINNAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6192",
            "district": "21",
            "Block": "24",
            "label": "SRI JAYENDRA SARASWATHI SWAMIGAL MATRIC SCHOOL,MULLUVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6416",
            "district": "21",
            "Block": "24",
            "label": "KHAIRE JAREE MS , MELVISHARAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6581",
            "district": "21",
            "Block": "24",
            "label": "PUMS UPPUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6742",
            "district": "21",
            "Block": "24",
            "label": "PUMS TAJPURA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8572",
            "district": "21",
            "Block": "24",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL , ARCOT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8597",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS SATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8598",
            "district": "21",
            "Block": "24",
            "label": "PALLAVAN MATRIC HIGH SCHOOL, TAJPURA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8599",
            "district": "21",
            "Block": "24",
            "label": "PUMS MUPPADHUVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8600",
            "district": "21",
            "Block": "24",
            "label": "GHS, V.K.MANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8601",
            "district": "21",
            "Block": "24",
            "label": "PUMS ATHITHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8602",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS PUDUPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8603",
            "district": "21",
            "Block": "24",
            "label": "PVMGHSS,CHAKKARAMALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8604",
            "district": "21",
            "Block": "24",
            "label": "GHSS ESSAYANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8605",
            "district": "21",
            "Block": "24",
            "label": "GHS, ARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8606",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS MECHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8607",
            "district": "21",
            "Block": "24",
            "label": "PUMS METTU MULLUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8608",
            "district": "21",
            "Block": "24",
            "label": "PUMS KEERAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8609",
            "district": "21",
            "Block": "24",
            "label": "GHSS, K.VELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8610",
            "district": "21",
            "Block": "24",
            "label": "PUMS ANANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8611",
            "district": "21",
            "Block": "24",
            "label": "PUMS ARAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8612",
            "district": "21",
            "Block": "24",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, POOTTUTHAKKU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8613",
            "district": "21",
            "Block": "24",
            "label": "GOVERNMENT HIGH SCHOOL, MELAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8614",
            "district": "21",
            "Block": "24",
            "label": "PANCHAYAT UNION MMIDDLE SCOOL,  MANGUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8615",
            "district": "21",
            "Block": "24",
            "label": "PUNCHAYAT UNION MIDDLE SCHOOL,  NANDIYALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8616",
            "district": "21",
            "Block": "24",
            "label": "ANJUMAN E MUYEDUL (M) AMS MELVISHARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8617",
            "district": "21",
            "Block": "24",
            "label": "PUmS HANSA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8618",
            "district": "21",
            "Block": "24",
            "label": "ISLAMIAH B HSS MELVISHARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8619",
            "district": "21",
            "Block": "24",
            "label": "ISLAMIAH G HSS MELVISHARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8620",
            "district": "21",
            "Block": "24",
            "label": "KH MATRIC (B) HSS,  THEN NANDIYALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8621",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS AYILAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8622",
            "district": "21",
            "Block": "24",
            "label": "GOVT HS KATHIYAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8623",
            "district": "21",
            "Block": "24",
            "label": "PUMS, SAMBASIVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8624",
            "district": "21",
            "Block": "24",
            "label": "PUMS, THAZHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8625",
            "district": "21",
            "Block": "24",
            "label": "PUMS, RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8626",
            "district": "21",
            "Block": "24",
            "label": "GOVT (HINDU) HR.SEC.SCHOOL RASATHUPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8627",
            "district": "21",
            "Block": "24",
            "label": "ADWMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8628",
            "district": "21",
            "Block": "24",
            "label": "PUMS, PERIYA KUKKUNDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8629",
            "district": "21",
            "Block": "24",
            "label": "PUMS, CHINNA KUKKUNDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21783",
            "district": "21",
            "Block": "24",
            "label": "SMILES INDIA VIDYASHRAM CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21784",
            "district": "21",
            "Block": "24",
            "label": "GLOBAL PUBLIC SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22384",
            "district": "21",
            "Block": "24",
            "label": "INDO AUSTRALIAN ACADEMY OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23310",
            "district": "21",
            "Block": "24",
            "label": "GOVT. ADW.HIGH SCHOOL, VEPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23333",
            "district": "21",
            "Block": "24",
            "label": "MMES PUBLIC SCHOOL, MELVISHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23533",
            "district": "21",
            "Block": "24",
            "label": "RANIPET DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23727",
            "district": "21",
            "Block": "24",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23728",
            "district": "21",
            "Block": "24",
            "label": "MAYFLOWER SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "25": [
        {
            "key": "835",
            "district": "19",
            "Block": "25",
            "label": "SRI SIVAAKAMALAM MATRIC ARIMALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "983",
            "district": "19",
            "Block": "25",
            "label": "SASTHAA MATRICULATION SCHOOL, KEELANILAIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3883",
            "district": "19",
            "Block": "25",
            "label": "KARPAGA VINAYAGA MATRICULATION HIGHER SECONDARY SCHOOL, SIVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6703",
            "district": "19",
            "Block": "25",
            "label": "SRI MANICKAM MAT H S SIVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17151",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEDUNGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17152",
            "district": "19",
            "Block": "25",
            "label": "AYYAKKANNU GHSS, KEELANILAIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17153",
            "district": "19",
            "Block": "25",
            "label": "PRAISE MATRICULATION SCHOOL, KEELANILAIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17154",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THANJURE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17155",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NALLAMBAL SAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17156",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, POONAIYAN KUDIERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17157",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KALANIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17158",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, POSAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17159",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PILIYAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17160",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, PERUNGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17161",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, OTHAIPULIKKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17162",
            "district": "19",
            "Block": "25",
            "label": "JAIHINTH  BALA BHAVAN MATRICULATION SCHOOL, VAIYAPURIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17163",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUVANAIKAVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17164",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THEKKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17165",
            "district": "19",
            "Block": "25",
            "label": "M CT M CC M HIGH SCHOOL, NAMANASAMUDRAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17166",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEENIKANDHA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17167",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, KURUNGALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17168",
            "district": "19",
            "Block": "25",
            "label": "SRI GANESAR MIDDLE SCHOOL, VISHVANATHPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17169",
            "district": "19",
            "Block": "25",
            "label": "ULAGAPPAR HIGHER SECONDARY SCHOOL, RAMACHANDRAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17170",
            "district": "19",
            "Block": "25",
            "label": "SKT GANDHI HIGH SCHOOL, RAYAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17171",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGH SCHOOL, MIRATTUNILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17172",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, IRUMBANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17173",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ARIMALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17174",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, ARIMALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17175",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ONANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17176",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, YEMBAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17177",
            "district": "19",
            "Block": "25",
            "label": "IMAYAM NURSERY PRIMARY SCHOOL, YEMBAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17178",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KALLUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17179",
            "district": "19",
            "Block": "25",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17180",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADAYAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17181",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VADAKKU NALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17182",
            "district": "19",
            "Block": "25",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUNASANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20995",
            "district": "19",
            "Block": "25",
            "label": "AL-MUNAWARA ISLAMIC SCHOOL, SIVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22232",
            "district": "19",
            "Block": "25",
            "label": "MRM INTERNATIONAL CBSE SCHOOL, SIVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23729",
            "district": "19",
            "Block": "25",
            "label": "CARMEL RANI MATRIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "26": [
        {
            "key": "2292",
            "district": "1",
            "Block": "26",
            "label": "THE ADITYA BIRLA MATRICULATION SCHOOL REDDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4415",
            "district": "1",
            "Block": "26",
            "label": "VENUS MATRICULATION  HIGH SCHOOL  ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4634",
            "district": "1",
            "Block": "26",
            "label": "NIRMALA MATRICULATION SCHOOL,  ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4807",
            "district": "1",
            "Block": "26",
            "label": "MONTFORT MATRICULATION  HIGHER SECONDARY SCHOOL  ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5516",
            "district": "1",
            "Block": "26",
            "label": "PUMS AATHIKUDIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14958",
            "district": "1",
            "Block": "26",
            "label": "GHS VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14959",
            "district": "1",
            "Block": "26",
            "label": "PUMS ELUPPAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14960",
            "district": "1",
            "Block": "26",
            "label": "GHSS POYYATHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14961",
            "district": "1",
            "Block": "26",
            "label": "GHS RAYAMPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14962",
            "district": "1",
            "Block": "26",
            "label": "PUMS SENNIVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14963",
            "district": "1",
            "Block": "26",
            "label": "PUMS OTTAKKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14964",
            "district": "1",
            "Block": "26",
            "label": "PUMS O.KOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14965",
            "district": "1",
            "Block": "26",
            "label": "GHS SALAYAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14966",
            "district": "1",
            "Block": "26",
            "label": "GHSS GOVINTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14967",
            "district": "1",
            "Block": "26",
            "label": "GHS THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14968",
            "district": "1",
            "Block": "26",
            "label": "VIDHYA MANDIR PUBLIC SCHOOL THAMARAIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14969",
            "district": "1",
            "Block": "26",
            "label": "RC NIRMALA GANDHI MIDDLE SCHOOL ARIYALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14970",
            "district": "1",
            "Block": "26",
            "label": "NIRMALA (G) HSS ARIYALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14971",
            "district": "1",
            "Block": "26",
            "label": "GHSS ARIYALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14972",
            "district": "1",
            "Block": "26",
            "label": "ST.MARY'S HIGH SCHOOL, ARIYALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14973",
            "district": "1",
            "Block": "26",
            "label": "CSI HSS ARIYALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14974",
            "district": "1",
            "Block": "26",
            "label": "GOVT G HS ARIYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14975",
            "district": "1",
            "Block": "26",
            "label": "KGBV SCHOOL- KARU SENAPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14976",
            "district": "1",
            "Block": "26",
            "label": "PUMS VALAJA NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14977",
            "district": "1",
            "Block": "26",
            "label": "PUMS ASTHINAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14978",
            "district": "1",
            "Block": "26",
            "label": "S K V P KOIL GIRLS HIGH SCHOOL KALLANKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14979",
            "district": "1",
            "Block": "26",
            "label": "GHS MANAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14980",
            "district": "1",
            "Block": "26",
            "label": "GOVT AIDED KRV MIDDLE SCHOOL LINGATHADIMEDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14981",
            "district": "1",
            "Block": "26",
            "label": "ARASUNAGAR MATRICULATION HIGHER SECONDARY SCHOOL  ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14982",
            "district": "1",
            "Block": "26",
            "label": "GHS AYANATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14983",
            "district": "1",
            "Block": "26",
            "label": "ADITYA BIRLA PUBLIC SCHOOL REDDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14984",
            "district": "1",
            "Block": "26",
            "label": "GHS KATTUPRINGIUAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14985",
            "district": "1",
            "Block": "26",
            "label": "PUMS MANNUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14986",
            "district": "1",
            "Block": "26",
            "label": "GHSS K.AMBAPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14987",
            "district": "1",
            "Block": "26",
            "label": "GHS VILANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14988",
            "district": "1",
            "Block": "26",
            "label": "GHS NAGAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14989",
            "district": "1",
            "Block": "26",
            "label": "PARIVALLAL HSS NAGAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14990",
            "district": "1",
            "Block": "26",
            "label": "PUMS MUNIYANKURICHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14991",
            "district": "1",
            "Block": "26",
            "label": "PUMS PUTHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14992",
            "district": "1",
            "Block": "26",
            "label": "PUMS VELIPRINKIUM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14993",
            "district": "1",
            "Block": "26",
            "label": "GHS REDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14994",
            "district": "1",
            "Block": "26",
            "label": "GHS SIRUVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14995",
            "district": "1",
            "Block": "26",
            "label": "PUMS KARU.SENAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14996",
            "district": "1",
            "Block": "26",
            "label": "PUMS KARUVIDACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14997",
            "district": "1",
            "Block": "26",
            "label": "GOVT HR SEC SCHOOL  KARU.POYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14998",
            "district": "1",
            "Block": "26",
            "label": "PUMS EDAYATHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14999",
            "district": "1",
            "Block": "26",
            "label": "GOVT HR SEC SCHOOL PERIYATHIRUKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15000",
            "district": "1",
            "Block": "26",
            "label": "PUMS CHETTITHIRUKKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15001",
            "district": "1",
            "Block": "26",
            "label": "PUMS KALLAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15002",
            "district": "1",
            "Block": "26",
            "label": "GHS ARUNGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15003",
            "district": "1",
            "Block": "26",
            "label": "PUMS ANDIPATTAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15004",
            "district": "1",
            "Block": "26",
            "label": "GHSS SUNDAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15005",
            "district": "1",
            "Block": "26",
            "label": "PUMS P.OTTAKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15006",
            "district": "1",
            "Block": "26",
            "label": "GOVT.MIDDLE.ATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15007",
            "district": "1",
            "Block": "26",
            "label": "PUMS PALLAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20973",
            "district": "1",
            "Block": "26",
            "label": "SRI RAMAKRISHANA PUBLIC SCHOOL VENKATAKRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21191",
            "district": "1",
            "Block": "26",
            "label": "GHSS MODEL SCHOOL ASITHINAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21383",
            "district": "1",
            "Block": "26",
            "label": "GHS THELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21801",
            "district": "1",
            "Block": "26",
            "label": "AKILA BHARATHY MATRICULATION HIGH SCHOOL  POYYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21802",
            "district": "1",
            "Block": "26",
            "label": "ANNAI INDHIRA HIGH SCHOOL VILANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21909",
            "district": "1",
            "Block": "26",
            "label": "NALANDHA HR SEC SCHOOL RAYAMPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22688",
            "district": "1",
            "Block": "26",
            "label": "SABARMATHI VIDHYALAYA PUBLIC SCHOOL ARIYALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23011",
            "district": "1",
            "Block": "26",
            "label": "VINAYAGA PUBLIC SCHOOL MELAKARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23730",
            "district": "1",
            "Block": "26",
            "label": "GHS VELLUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23731",
            "district": "1",
            "Block": "26",
            "label": "GOLDEN GATES GLOBAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "27": [
        {
            "key": "382",
            "district": "34",
            "Block": "27",
            "label": "ACS MATRIC HSS, ARNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "406",
            "district": "34",
            "Block": "27",
            "label": "ST MARY'S MATRIC HSS ARNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1741",
            "district": "34",
            "Block": "27",
            "label": "BEST MATRIC HIGHER SECONDARY SCHOOL  ARNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2076",
            "district": "34",
            "Block": "27",
            "label": "ST. JOSEPH'S MATRIC HR SEC SCHOOL  ARNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2382",
            "district": "34",
            "Block": "27",
            "label": "PUMS POOSIMALAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2395",
            "district": "34",
            "Block": "27",
            "label": "PUMS PANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2649",
            "district": "34",
            "Block": "27",
            "label": "PUMS ONDIGUDISAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2703",
            "district": "34",
            "Block": "27",
            "label": "ST.GEMMA MATRIC.HR.SEC.SCHOOL RANDHAMKORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2837",
            "district": "34",
            "Block": "27",
            "label": "PUMS MGR NAGAR PAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2945",
            "district": "34",
            "Block": "27",
            "label": "ADWMS PAPPAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2957",
            "district": "34",
            "Block": "27",
            "label": "MMS SHAFFIULLA ST ARNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2988",
            "district": "34",
            "Block": "27",
            "label": "MMS SARPANAPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3036",
            "district": "34",
            "Block": "27",
            "label": "MMS THACHUR ROAD ( SS KOIL ST)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3142",
            "district": "34",
            "Block": "27",
            "label": "MMS, P A KOIL ST, ARNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3197",
            "district": "34",
            "Block": "27",
            "label": "PUMS SUBANRAO PETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3473",
            "district": "34",
            "Block": "27",
            "label": "ADWMS PAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3613",
            "district": "34",
            "Block": "27",
            "label": "PUMS THUNTHAREEGAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3614",
            "district": "34",
            "Block": "27",
            "label": "PUMS S.U.VANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3634",
            "district": "34",
            "Block": "27",
            "label": "PUMS NETHAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3709",
            "district": "34",
            "Block": "27",
            "label": "PUMS SOMANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3732",
            "district": "34",
            "Block": "27",
            "label": "PUMS 12PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3764",
            "district": "34",
            "Block": "27",
            "label": "MMS ANANTHAPURAM(N) ARNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4075",
            "district": "34",
            "Block": "27",
            "label": "PUMS VELLERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4899",
            "district": "34",
            "Block": "27",
            "label": "PUMS ADAYAPULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5504",
            "district": "34",
            "Block": "27",
            "label": "PUMS KANIGILUPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6196",
            "district": "34",
            "Block": "27",
            "label": "PUMS, ARIYAPPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6222",
            "district": "34",
            "Block": "27",
            "label": "PUMS PAZHANGAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9664",
            "district": "34",
            "Block": "27",
            "label": "GHS ADHANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9665",
            "district": "34",
            "Block": "27",
            "label": "GHSS AGRAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9666",
            "district": "34",
            "Block": "27",
            "label": "GHSS- IRUMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9667",
            "district": "34",
            "Block": "27",
            "label": "GHS HARIHARAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9668",
            "district": "34",
            "Block": "27",
            "label": "GHS KALPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9669",
            "district": "34",
            "Block": "27",
            "label": "GHS MEYYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9670",
            "district": "34",
            "Block": "27",
            "label": "GHS MORAPANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9671",
            "district": "34",
            "Block": "27",
            "label": "GHSS MULLANDIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9672",
            "district": "34",
            "Block": "27",
            "label": "GHS NESAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9673",
            "district": "34",
            "Block": "27",
            "label": "GHS PAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9674",
            "district": "34",
            "Block": "27",
            "label": "GHS RATTINAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9675",
            "district": "34",
            "Block": "27",
            "label": "GHSS S.V.NAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9676",
            "district": "34",
            "Block": "27",
            "label": "GHSS SEVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9677",
            "district": "34",
            "Block": "27",
            "label": "GHS SIRUMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9678",
            "district": "34",
            "Block": "27",
            "label": "GHS VADUGASATHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9679",
            "district": "34",
            "Block": "27",
            "label": "GHS VELAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9680",
            "district": "34",
            "Block": "27",
            "label": "GHS VETTIYANTHOLUVAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9681",
            "district": "34",
            "Block": "27",
            "label": "GHS VILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9682",
            "district": "34",
            "Block": "27",
            "label": "CSI HSS  ARNI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9683",
            "district": "34",
            "Block": "27",
            "label": "GHSS (B)  ARNI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9684",
            "district": "34",
            "Block": "27",
            "label": "GGMHSS ARNI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9685",
            "district": "34",
            "Block": "27",
            "label": "ST. JOSEPH HSS(GIRLS) ARNI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9686",
            "district": "34",
            "Block": "27",
            "label": "SUB. SASTRIAR HSS ARNI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21015",
            "district": "34",
            "Block": "27",
            "label": "SHRI BALA VIDHYA MANDIR MATRIC HR SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21148",
            "district": "34",
            "Block": "27",
            "label": "VIKAS VIDHYASHARAM MATRICULATION HR SEC SCHOOL NESAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21264",
            "district": "34",
            "Block": "27",
            "label": "SRI BHARATHI VIDHYASHRAM MATRIC HR.SEC. SCHOOL, AGRAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21565",
            "district": "34",
            "Block": "27",
            "label": "MUNICIPAL HIGH SCHOOL KANNAPPAN ST POONTHOTAM ARNI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22085",
            "district": "34",
            "Block": "27",
            "label": "APPLE INTERNATIONAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22086",
            "district": "34",
            "Block": "27",
            "label": "KANNAMMAL INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22413",
            "district": "34",
            "Block": "27",
            "label": "GHS-SANGEETHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22920",
            "district": "34",
            "Block": "27",
            "label": "GHS-MAMANDOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23248",
            "district": "34",
            "Block": "27",
            "label": "MHS ANATHAPURAM(SAIDAI)",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "28": [
        {
            "key": "572",
            "district": "38",
            "Block": "28",
            "label": "NOBLE MATRICULATION HIGHER SECONDARY SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2182",
            "district": "38",
            "Block": "28",
            "label": "SRI SOWDAMBIKA CONVENT MATRICULATION HSSCHOOL, CHETTIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2284",
            "district": "38",
            "Block": "28",
            "label": "SIVANANDA VIDYALAYAM MATRIC HSS AATHIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2472",
            "district": "38",
            "Block": "28",
            "label": "SBKKSS THIYAGARAJAN MEMORIAL MATRICULATION SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18654",
            "district": "38",
            "Block": "28",
            "label": "P.U.M.S, VILLIPATHIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18655",
            "district": "38",
            "Block": "28",
            "label": "GHSS, SOOLAKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18656",
            "district": "38",
            "Block": "28",
            "label": "GHS FOR HEARING IMPAIRED, SOOLAKKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18657",
            "district": "38",
            "Block": "28",
            "label": "SRI VEERAPPA VIDHYALAYA HR SEC SCHOOL, KULLURSANDAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18658",
            "district": "38",
            "Block": "28",
            "label": "GHSS, PALAVANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18659",
            "district": "38",
            "Block": "28",
            "label": "HINDU HIGH SCHOOL, GOPALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18660",
            "district": "38",
            "Block": "28",
            "label": "CSD MS KOVILANGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18661",
            "district": "38",
            "Block": "28",
            "label": "G H S, KOVILANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18662",
            "district": "38",
            "Block": "28",
            "label": "P.U.M.S., PERIYAKATTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18663",
            "district": "38",
            "Block": "28",
            "label": "ST.JOSEPH'S CARMEL MATRICULATION SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18664",
            "district": "38",
            "Block": "28",
            "label": "BPV SALA  HSS, PALAYAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18665",
            "district": "38",
            "Block": "28",
            "label": "G H S S, SEMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18666",
            "district": "38",
            "Block": "28",
            "label": "VELLALAR MS., PULIYURAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18667",
            "district": "38",
            "Block": "28",
            "label": "NADAR MIDDLE SCHOOL., PULIYURAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18668",
            "district": "38",
            "Block": "28",
            "label": "PUMS, KOOTHIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18669",
            "district": "38",
            "Block": "28",
            "label": "GOVT HR SEC SCHOOL, MALAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18670",
            "district": "38",
            "Block": "28",
            "label": "P U M S AMANAKKUNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18671",
            "district": "38",
            "Block": "28",
            "label": "GHSS, VELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18672",
            "district": "38",
            "Block": "28",
            "label": "GHSS CHETTIKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18673",
            "district": "38",
            "Block": "28",
            "label": "RUKNMSPN HS RAMANAICKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18674",
            "district": "38",
            "Block": "28",
            "label": "GOVERNMENT HIGH SCHOOL, KURUNTHAMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18675",
            "district": "38",
            "Block": "28",
            "label": "VHNKUSKV HIGH SCHOOL, VADHUVARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18676",
            "district": "38",
            "Block": "28",
            "label": "PUMS, CHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18677",
            "district": "38",
            "Block": "28",
            "label": "P U M S, KASILINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18678",
            "district": "38",
            "Block": "28",
            "label": "GHSS (B), PANDALKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18679",
            "district": "38",
            "Block": "28",
            "label": "VIDYA MANDIR MATRICULATION SCHOOL, RAMCO NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18680",
            "district": "38",
            "Block": "28",
            "label": "GHSS (G), PANDALKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18681",
            "district": "38",
            "Block": "28",
            "label": "P U M S., KOPPUCHITAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18682",
            "district": "38",
            "Block": "28",
            "label": "APTSMPS MATRICULATION SCHOOL, PULIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18683",
            "district": "38",
            "Block": "28",
            "label": "MUNICIPAL MIDDLE SCHOOL, MADURAI SALAI, ARUPPUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18684",
            "district": "38",
            "Block": "28",
            "label": "MUNICIPAL MIDDLE SCHOOL, WEST CAR STREET, ARUPPUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18685",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR MIDDLE SCHOOL., CHOKKALINGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18686",
            "district": "38",
            "Block": "28",
            "label": "CSI BOARDING MS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18687",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR PULIYAMPATTI MS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18688",
            "district": "38",
            "Block": "28",
            "label": "VELAYUTHAPURAM SALIYAR MS., ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18689",
            "district": "38",
            "Block": "28",
            "label": "KAMMAVAR RAMANUJAR MS, CHINNAPULIYAMPATTI, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18690",
            "district": "38",
            "Block": "28",
            "label": "HINDU NADAR M S, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18691",
            "district": "38",
            "Block": "28",
            "label": "VELLAKOTTAI SALIAR MIDDLE SCHOOL., ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18692",
            "district": "38",
            "Block": "28",
            "label": "S T R N GHSS, ARUPPUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18693",
            "district": "38",
            "Block": "28",
            "label": "SENGUNTHAR H.S, THIRUNAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18694",
            "district": "38",
            "Block": "28",
            "label": "VELLAIKOTTAI SENGUNDHAR HS., APK",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18695",
            "district": "38",
            "Block": "28",
            "label": "AL AMEEN MUSLIM HSS, ARUPPUKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18696",
            "district": "38",
            "Block": "28",
            "label": "SBK BOYS HSS ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18697",
            "district": "38",
            "Block": "28",
            "label": "S B K GIRLS HR SEC SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18698",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR HSS., ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18699",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR GIRLS HSS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18700",
            "district": "38",
            "Block": "28",
            "label": "CSI GIRLS HSS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18701",
            "district": "38",
            "Block": "28",
            "label": "SALIYAR MAHAJANA HIGHER SECONDARY SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18702",
            "district": "38",
            "Block": "28",
            "label": "DEVANGAR TOWN MS, ARUPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18703",
            "district": "38",
            "Block": "28",
            "label": "SHRI MATHRU VIDYASHRAM MATRICULATION HR SEC SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18704",
            "district": "38",
            "Block": "28",
            "label": "MPL HIGH SCHOOL, WEAVERS COLONY, ARUPPUKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18705",
            "district": "38",
            "Block": "28",
            "label": "SBK INTERNATIONAL SCHOOL(CBSE), ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21108",
            "district": "38",
            "Block": "28",
            "label": "CHANDRA NATIONAL SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21170",
            "district": "38",
            "Block": "28",
            "label": "ST.FRANCIS DE SALES MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21936",
            "district": "38",
            "Block": "28",
            "label": "R.J.MANTRA ENGLISH SCHOOL,CHINNAVALLIKULAM ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22262",
            "district": "38",
            "Block": "28",
            "label": "IQRA MATRICULATION SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22263",
            "district": "38",
            "Block": "28",
            "label": "GREEN WISDOM MATRICULATION SCHOOL, ARUPPUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22783",
            "district": "38",
            "Block": "28",
            "label": "KALAIMAGAL VIDHYALAYA, PANDALKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23732",
            "district": "38",
            "Block": "28",
            "label": "SRI VENKATESWARAA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "29": [
        {
            "key": "3189",
            "district": "7",
            "Block": "29",
            "label": "SAKTHIVELAVAN VIDHYALAYA,CHINNALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3340",
            "district": "7",
            "Block": "29",
            "label": "VICTORY MATRICULATION HR. SEC. SCHOOL,CHINNALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3489",
            "district": "7",
            "Block": "29",
            "label": "VIDYA SIKSHAA MATRIC HIGHER SECONDARY SCHOOL, BODIKAMANVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3827",
            "district": "7",
            "Block": "29",
            "label": "LEO MATRIC SCHOOL,ANNAMALAIYAR MILLS COLONY, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3878",
            "district": "7",
            "Block": "29",
            "label": "HOLY CROSS MATRICULATION HIGHER SECONDARY SCHOOL,VAKKAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4780",
            "district": "7",
            "Block": "29",
            "label": "PRISM MATRIC HIGHER SECONDARY SCHOOL, AATHILAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4862",
            "district": "7",
            "Block": "29",
            "label": "CHERAN VIDHYALAYA  MATRIC HIGHER SECONDARY SCHOOL, KALIKKAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13308",
            "district": "7",
            "Block": "29",
            "label": "GTRHSS-PERUMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13309",
            "district": "7",
            "Block": "29",
            "label": "NPR GHSS,AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13310",
            "district": "7",
            "Block": "29",
            "label": "GHS,DEVARAPPANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13311",
            "district": "7",
            "Block": "29",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SANGAREDDYKOTTAI .",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13312",
            "district": "7",
            "Block": "29",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.SINGARAKOTTAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13313",
            "district": "7",
            "Block": "29",
            "label": "GOVT. KALLAR HIGH SCHOOL, NELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13314",
            "district": "7",
            "Block": "29",
            "label": "RVLNA GHSS - SITHAREVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13315",
            "district": "7",
            "Block": "29",
            "label": "PUMS, BODIKAMMANVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13316",
            "district": "7",
            "Block": "29",
            "label": "GHSS, SITHAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13317",
            "district": "7",
            "Block": "29",
            "label": "CSI MIDDLE SCHOOL, PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13318",
            "district": "7",
            "Block": "29",
            "label": "RC SACRED HEART MS-ATHOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13319",
            "district": "7",
            "Block": "29",
            "label": "GHSS, ATHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13320",
            "district": "7",
            "Block": "29",
            "label": "NAGALAKSHMI MATRIC HR SEC SCHOOL,SITHAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13321",
            "district": "7",
            "Block": "29",
            "label": "PUMS, PARAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13322",
            "district": "7",
            "Block": "29",
            "label": "ST.MICHAEL'S MIDDLE SCHOOL, VANNAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13323",
            "district": "7",
            "Block": "29",
            "label": "GANDHIJI MS-MALLAIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13324",
            "district": "7",
            "Block": "29",
            "label": "GHSS,V.KOOTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13325",
            "district": "7",
            "Block": "29",
            "label": "PUMS, J.PUDUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13326",
            "district": "7",
            "Block": "29",
            "label": "PUMS, A.KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13327",
            "district": "7",
            "Block": "29",
            "label": "G.AD.WHSS-MURUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13328",
            "district": "7",
            "Block": "29",
            "label": "PUMS, KEELAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13329",
            "district": "7",
            "Block": "29",
            "label": "DEVANGAR (G) HS,CHINNALAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13330",
            "district": "7",
            "Block": "29",
            "label": "DEVANGAR HIGHER SECONDARY SCHOOL,CHINNALAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13331",
            "district": "7",
            "Block": "29",
            "label": "THAMBITHOTTAM HSS,GANDHIGRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13332",
            "district": "7",
            "Block": "29",
            "label": "ST JOSEPH'S MATRICULATION SCHOOL,CHINNALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13333",
            "district": "7",
            "Block": "29",
            "label": "KASTHURIBA SEVIKASHRAM SPECIAL HIGHER SECONDRAY SCHOOL , GANDHIGRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13334",
            "district": "7",
            "Block": "29",
            "label": "KENDRIYA VIDHYALAYA,GANDHIGRAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13335",
            "district": "7",
            "Block": "29",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,CHETTIAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13336",
            "district": "7",
            "Block": "29",
            "label": "GOVERNMENT HIGH SCHOOL, CHINNALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13337",
            "district": "7",
            "Block": "29",
            "label": "RC MIDDLE SCHOOL, ARIYANELLURE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13338",
            "district": "7",
            "Block": "29",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ,PITHALAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13339",
            "district": "7",
            "Block": "29",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,N.PANJAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13340",
            "district": "7",
            "Block": "29",
            "label": "ST AUGUSTINAR(G)HSS,N.PANJAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13341",
            "district": "7",
            "Block": "29",
            "label": "DR ZAHIR HUSSIAN HSS,PILLAYARNATHAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21027",
            "district": "7",
            "Block": "29",
            "label": "CHERAN GLOBAL MATRIC. HIGH SCHOOL, SEDAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21322",
            "district": "7",
            "Block": "29",
            "label": "LUXOR WORLD SCHOOL, CHINNALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21741",
            "district": "7",
            "Block": "29",
            "label": "RC SAHAYARANI HIGH SCHOOL,CHINNALAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22173",
            "district": "7",
            "Block": "29",
            "label": "ORCHIDS SPECIAL SCHOOL, RATHINAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "30": [
        {
            "key": "1984",
            "district": "22",
            "Block": "30",
            "label": "BHARATHIYAR MATRIC HIGHER SECONDARY SCHOOL, ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3531",
            "district": "22",
            "Block": "30",
            "label": "MALAR MATRIC HR SEC SCHOOL, THULUKKANUR, KALLANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3874",
            "district": "22",
            "Block": "30",
            "label": "SARASWATHI MATRIC SCHOOL, MULLAIVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4220",
            "district": "22",
            "Block": "30",
            "label": "VIVEKANANDA MATRICULATION HR.SEC. SCHOOL,PUDUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5047",
            "district": "22",
            "Block": "30",
            "label": "RASI MATRIC HR. SEC. SCHOOL, MALLIYAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5690",
            "district": "22",
            "Block": "30",
            "label": "SARASWATHI MHSS, AMMAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5768",
            "district": "22",
            "Block": "30",
            "label": "HOLY MOTHER MATRICULATION SCHOOL, KEERIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11398",
            "district": "22",
            "Block": "30",
            "label": "PUMS SIVAGANGAIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11399",
            "district": "22",
            "Block": "30",
            "label": "GHSS KALPAGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11400",
            "district": "22",
            "Block": "30",
            "label": "VANI VIKAS MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11401",
            "district": "22",
            "Block": "30",
            "label": "GHSS THANDAVARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11402",
            "district": "22",
            "Block": "30",
            "label": "GHSS CHOKKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11403",
            "district": "22",
            "Block": "30",
            "label": "PUMS SEELIAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11404",
            "district": "22",
            "Block": "30",
            "label": "GOVT HR SEC SCHOOL MALLIYAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11405",
            "district": "22",
            "Block": "30",
            "label": "PUMS URANDIVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11406",
            "district": "22",
            "Block": "30",
            "label": "PUMS GOVINDARAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11407",
            "district": "22",
            "Block": "30",
            "label": "GHSS KEERIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11408",
            "district": "22",
            "Block": "30",
            "label": "PUMS KOOLAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11409",
            "district": "22",
            "Block": "30",
            "label": "GHS PAITHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11410",
            "district": "22",
            "Block": "30",
            "label": "GBHSS ATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11411",
            "district": "22",
            "Block": "30",
            "label": "GGHSS ATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11412",
            "district": "22",
            "Block": "30",
            "label": "SRI SARADHA MEMORIAL MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11413",
            "district": "22",
            "Block": "30",
            "label": "TSP MATRIC HR. SEC. SCHOOL, VINAYAGAPURAM, ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11414",
            "district": "22",
            "Block": "30",
            "label": "GHS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11415",
            "district": "22",
            "Block": "30",
            "label": "SRI S.R.V MATRICULATION HR. SEC. SCHOOL,NARASINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11416",
            "district": "22",
            "Block": "30",
            "label": "AKCHARA BALABHAVAN (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11417",
            "district": "22",
            "Block": "30",
            "label": "PUMS THENNANGUDIPALYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11418",
            "district": "22",
            "Block": "30",
            "label": "GREEN PARK  MATRIC HSS ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11419",
            "district": "22",
            "Block": "30",
            "label": "A.E.T. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11420",
            "district": "22",
            "Block": "30",
            "label": "PUMS LAKSHMANASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11421",
            "district": "22",
            "Block": "30",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11422",
            "district": "22",
            "Block": "30",
            "label": "GHS RAMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11423",
            "district": "22",
            "Block": "30",
            "label": "PUMS AMMAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11424",
            "district": "22",
            "Block": "30",
            "label": "JAIVINS ACADEMY (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11425",
            "district": "22",
            "Block": "30",
            "label": "GHS KALLANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11426",
            "district": "22",
            "Block": "30",
            "label": "M.M.S.MULLAIVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11427",
            "district": "22",
            "Block": "30",
            "label": "M.M.S.GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11428",
            "district": "22",
            "Block": "30",
            "label": "ST MARY'S RC M.S(AIDED)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11429",
            "district": "22",
            "Block": "30",
            "label": "NATIONAL MIDDLE SCHOOL, ATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11430",
            "district": "22",
            "Block": "30",
            "label": "THIRU CHINNASAMY AYYA MIDDLE SCHOOL, ATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11431",
            "district": "22",
            "Block": "30",
            "label": "C.S.I. HIGH SCHOOL, ATTUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11432",
            "district": "22",
            "Block": "30",
            "label": "ADW HSS AMBEDKAR NAGAR, ATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11433",
            "district": "22",
            "Block": "30",
            "label": "BHARATHIYAR HI TECH INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11434",
            "district": "22",
            "Block": "30",
            "label": "GHS VALAYAMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11435",
            "district": "22",
            "Block": "30",
            "label": "GHSS MANJINI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11436",
            "district": "22",
            "Block": "30",
            "label": "PUMS PUNGAVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21497",
            "district": "22",
            "Block": "30",
            "label": "ADWHS, KOTHAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22114",
            "district": "22",
            "Block": "30",
            "label": "SHRI SWAMY PUBLIC SCHOOL, KALPAGANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22115",
            "district": "22",
            "Block": "30",
            "label": "RASI VIDHYASHRAM (CBSE) SCHOOL, EACHAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        { 
            "key":"24129", "district":"22", 
            "Block":"30", "label": "St. Joseph School", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "22863",
            "district": "22",
            "Block": "30",
            "label": "GHS THAVALAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23108",
            "district": "22",
            "Block": "30",
            "label": "ATTUR PUBLIC MATRICULATION SCHOOL, RAMANAYAKANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23733",
            "district": "22",
            "Block": "30",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "31": [
        {
            "key": "768",
            "district": "27",
            "Block": "31",
            "label": "S.K.A. MAT HSS, AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1565",
            "district": "27",
            "Block": "31",
            "label": "PADMA RAMASAMY MAT HSS, S.RENGANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1633",
            "district": "27",
            "Block": "31",
            "label": "PUMS - VARATHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1735",
            "district": "27",
            "Block": "31",
            "label": "PUMS - VANDIMOOPANPATTI @ PALAYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2707",
            "district": "27",
            "Block": "31",
            "label": "GKMS - KATHIRNARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3590",
            "district": "27",
            "Block": "31",
            "label": "PUMS - PULLIMANCOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3767",
            "district": "27",
            "Block": "31",
            "label": "PUMS - RAJAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4462",
            "district": "27",
            "Block": "31",
            "label": "A.M.H.N.U. MAT HSS, AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4819",
            "district": "27",
            "Block": "31",
            "label": "VAILANKANNI MATHA MATRIC HR SEC SCHOOL JAKKAMPATTI AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5197",
            "district": "27",
            "Block": "31",
            "label": "PUMS - MARAVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5230",
            "district": "27",
            "Block": "31",
            "label": "PUMS - SUNDARARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5783",
            "district": "27",
            "Block": "31",
            "label": "K.S..MIDDLE  SCHOOL - BALASAMUDRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6175",
            "district": "27",
            "Block": "31",
            "label": "PUMS - NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6253",
            "district": "27",
            "Block": "31",
            "label": "KAMMAVAR AIDED MIDDLE SCHOOL -M.SUBBULAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6261",
            "district": "27",
            "Block": "31",
            "label": "PUMS - SITHAIYAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6270",
            "district": "27",
            "Block": "31",
            "label": "PUMS - AUNDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6410",
            "district": "27",
            "Block": "31",
            "label": "PUMS - KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18469",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, P.DHARMATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18470",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, T.SUBBULAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18471",
            "district": "27",
            "Block": "31",
            "label": "GOVT (ADW) HSS, BOMMINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18472",
            "district": "27",
            "Block": "31",
            "label": "PUMS - THIMMARASANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18473",
            "district": "27",
            "Block": "31",
            "label": "PUMS - T.RAJAGOPALANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18474",
            "district": "27",
            "Block": "31",
            "label": "PUMS - NATCHIYARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18475",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, RENGASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18476",
            "district": "27",
            "Block": "31",
            "label": "PUMS - KEELAMUTHANAMPATTI @ KATHIRAYAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18477",
            "district": "27",
            "Block": "31",
            "label": "GOVT KALLAR HSS, MUTHANAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18478",
            "district": "27",
            "Block": "31",
            "label": "PUMS - KALLUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18479",
            "district": "27",
            "Block": "31",
            "label": "PUMS - MUTHUSANGILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18480",
            "district": "27",
            "Block": "31",
            "label": "PUMS - C.R.CAMP @ ANNAI INDRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18481",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, SHANMUGASUNDARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18482",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, ANUPPAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18483",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, KONDAMANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18484",
            "district": "27",
            "Block": "31",
            "label": "HINDU HSS, JAKKAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18485",
            "district": "27",
            "Block": "31",
            "label": "GOVT BOYS HSS, AUNDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18486",
            "district": "27",
            "Block": "31",
            "label": "GOVT GIRLS HSS, AUNDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18487",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, KANNIAPPAPILLAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18488",
            "district": "27",
            "Block": "31",
            "label": "S.K.A. HSS, AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18489",
            "district": "27",
            "Block": "31",
            "label": "GOVT KALLAR HSS, ETHAKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18490",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, MUTHUMARIAMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18491",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, KOTHAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18492",
            "district": "27",
            "Block": "31",
            "label": "GOVT KALLAR HSS, RAJADHANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18493",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, PALACOMBAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18494",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, THEPPAMMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18495",
            "district": "27",
            "Block": "31",
            "label": "GANESHA MS -  SITHARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18496",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, S.KATHIRNARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18497",
            "district": "27",
            "Block": "31",
            "label": "GOVT HSS, OKKARAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18498",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, ASARIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18499",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, G.USILAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18500",
            "district": "27",
            "Block": "31",
            "label": "GOVT HS, THEKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18501",
            "district": "27",
            "Block": "31",
            "label": "PUMS - ERATHIMMAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18502",
            "district": "27",
            "Block": "31",
            "label": "GOVT (ADW) HSS, KUNNUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18503",
            "district": "27",
            "Block": "31",
            "label": "GOVT (ADW) HS, AMMACHIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21638",
            "district": "27",
            "Block": "31",
            "label": "ANGEL VIDHYA MANDIR MAT HSS, AUNDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22258",
            "district": "27",
            "Block": "31",
            "label": "JAIKRISH VIDHYASHRAM CBSE SCHOOL  - MUTHUKRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23568",
            "district": "27",
            "Block": "31",
            "label": "LINGA GLOBAL SCHOOL, RENGASAMUTHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23568",
            "district": "27",
            "Block": "31",
            "label": "LINGA GLOBAL SCHOOL, RENGASAMUTHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23734",
            "district": "27",
            "Block": "31",
            "label": "THENI DISTRICT GOVERNMENT MODEL SCHOOL - THEKKAMPATTI",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23735",
            "district": "27",
            "Block": "31",
            "label": "THE LITTLE FLOWER MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "32": [
        {
            "key": "693",
            "district": "32",
            "Block": "32",
            "label": "S.K.L PUBLIC MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "700",
            "district": "32",
            "Block": "32",
            "label": "MIGROS K.I.D.S MATRIC HR SEC SCHOOL, KANIYAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "741",
            "district": "32",
            "Block": "32",
            "label": "PALANIAPPA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "801",
            "district": "32",
            "Block": "32",
            "label": "M.S.VIDYALAYA  MAT KARUNAIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "862",
            "district": "32",
            "Block": "32",
            "label": "VENKATESWARA VIDYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "890",
            "district": "32",
            "Block": "32",
            "label": "T.E.A PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL,AVINASHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "900",
            "district": "32",
            "Block": "32",
            "label": "TAKKO MatricSCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "947",
            "district": "32",
            "Block": "32",
            "label": "CAMBRIDGE MATRIC PANTHAMPALAYM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1057",
            "district": "32",
            "Block": "32",
            "label": "SHANTHI VIDHYALAYA MAT CHEYUR ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2277",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PERANAICKAN PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2278",
            "district": "32",
            "Block": "32",
            "label": "TIRUPUR PUBLIC MATRIC SCHOOL , RAKYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3045",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVARAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3262",
            "district": "32",
            "Block": "32",
            "label": "AKR ACADEMY SCHOOL  ANAIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3779",
            "district": "32",
            "Block": "32",
            "label": "PUMS VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5772",
            "district": "32",
            "Block": "32",
            "label": "PUMS SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5885",
            "district": "32",
            "Block": "32",
            "label": "PUMS GANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6596",
            "district": "32",
            "Block": "32",
            "label": "PUMS AVINASHILINGAM PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6662",
            "district": "32",
            "Block": "32",
            "label": "PUMS ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13095",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL SALAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13096",
            "district": "32",
            "Block": "32",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  CHEYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13097",
            "district": "32",
            "Block": "32",
            "label": "GHSS KANUR PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13098",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMBAGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13099",
            "district": "32",
            "Block": "32",
            "label": "GHSS KARUVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13100",
            "district": "32",
            "Block": "32",
            "label": "GOVERNMENT HIGH SCHOOL , THIRUMALAIGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13101",
            "district": "32",
            "Block": "32",
            "label": "GHS KARUKKANKATTU PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13102",
            "district": "32",
            "Block": "32",
            "label": "PUMS NAMBIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13103",
            "district": "32",
            "Block": "32",
            "label": "AVANASHI KALVI NILAYAM HS  M.NADHAMPALAYM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13104",
            "district": "32",
            "Block": "32",
            "label": "PUMS AYEEGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13105",
            "district": "32",
            "Block": "32",
            "label": "GHSS PERIYAYEEPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13106",
            "district": "32",
            "Block": "32",
            "label": "GHS PANCHALINGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13107",
            "district": "32",
            "Block": "32",
            "label": "GHS POTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13108",
            "district": "32",
            "Block": "32",
            "label": "PUMS MONDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13109",
            "district": "32",
            "Block": "32",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13110",
            "district": "32",
            "Block": "32",
            "label": "SRI SELVANAYAGI MATRICULATION SCHOOL , VANJIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13111",
            "district": "32",
            "Block": "32",
            "label": "GOVERNMENT HIGH SCHOOL VANJIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13112",
            "district": "32",
            "Block": "32",
            "label": "PUMS NARIYAMPALLIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13113",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENNIMALAI GOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13114",
            "district": "32",
            "Block": "32",
            "label": "KONGU KALVI NILAYAM HIGHER  SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13115",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THANDUKKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13116",
            "district": "32",
            "Block": "32",
            "label": "PUMS SULLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13117",
            "district": "32",
            "Block": "32",
            "label": "PUMS THIMMANAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13118",
            "district": "32",
            "Block": "32",
            "label": "GHSS THEKKALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13119",
            "district": "32",
            "Block": "32",
            "label": "PUMS THULUKAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13120",
            "district": "32",
            "Block": "32",
            "label": "PUMS KARUNAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13121",
            "district": "32",
            "Block": "32",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13122",
            "district": "32",
            "Block": "32",
            "label": "PUMS KASIGOUNDANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13123",
            "district": "32",
            "Block": "32",
            "label": "PUMS A.KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13124",
            "district": "32",
            "Block": "32",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13125",
            "district": "32",
            "Block": "32",
            "label": "GOVT  HIGH SCHOOL RAKKIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13126",
            "district": "32",
            "Block": "32",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL AVINASHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13127",
            "district": "32",
            "Block": "32",
            "label": "ST THOMAS GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13128",
            "district": "32",
            "Block": "32",
            "label": "GOVT. BOYS HR SEC SCHOOL AVINASHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13129",
            "district": "32",
            "Block": "32",
            "label": "RAYAR KALVI NILAYAM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13130",
            "district": "32",
            "Block": "32",
            "label": "NANJAPPA NIKETHAN THANNEERPANDHALCOLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20971",
            "district": "32",
            "Block": "32",
            "label": "SRI HAYAGREVAR VIDYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21085",
            "district": "32",
            "Block": "32",
            "label": "VIVEGAA SCHOOL(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21086",
            "district": "32",
            "Block": "32",
            "label": "SPRING MOUNT PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21245",
            "district": "32",
            "Block": "32",
            "label": "SRI SHAKTHI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23350",
            "district": "32",
            "Block": "32",
            "label": "SAKTHIDEVI MATRIC KANIYAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21274",
            "district": "32",
            "Block": "32",
            "label": "SRI NACHAMMAL VIDYAVANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21317",
            "district": "32",
            "Block": "32",
            "label": "THE EARNEST ACADEMY,PAZHANKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21546",
            "district": "32",
            "Block": "32",
            "label": "SRI RAGHAVENDRA VIDYALAYA HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21888",
            "district": "32",
            "Block": "32",
            "label": "SAKTHI VIDHYALAYAA MATRICULATION SCHOOL, SELLAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22148",
            "district": "32",
            "Block": "32",
            "label": "VEDANTA ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22149",
            "district": "32",
            "Block": "32",
            "label": "THE QUEST INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22150",
            "district": "32",
            "Block": "32",
            "label": "BHARATHI VIDHYAA KENDHRA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22151",
            "district": "32",
            "Block": "32",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Avinashi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23116",
            "district": "32",
            "Block": "32",
            "label": "SELLANDIAMMAN HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23213",
            "district": "32",
            "Block": "32",
            "label": "SHRI CHENDOOR VIDHYALAYA MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23594",
            "district": "32",
            "Block": "32",
            "label": "THE SCV CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23595",
            "district": "32",
            "Block": "32",
            "label": "PALANIAPPA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23594",
            "district": "32",
            "Block": "32",
            "label": "THE SCV CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23595",
            "district": "32",
            "Block": "32",
            "label": "PALANIAPPA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23736",
            "district": "32",
            "Block": "32",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23737",
            "district": "32",
            "Block": "32",
            "label": "PUMS SULLIPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23738",
            "district": "32",
            "Block": "32",
            "label": "PUMS SAMATHUVAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23739",
            "district": "32",
            "Block": "32",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23740",
            "district": "32",
            "Block": "32",
            "label": "VEDANTA ACADEMY",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "33": [
        {
            "key": "1256",
            "district": "19",
            "Block": "33",
            "label": "POPULAR MATRIC HR. SEC. SCHOOL MIMISAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1353",
            "district": "19",
            "Block": "33",
            "label": "ST.JOSEPHS MATRIC SCHOOL AVUDAIYARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2905",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. VILANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3551",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5437",
            "district": "19",
            "Block": "33",
            "label": "NEW SANGGEETH MATRIC HR. SEC. SCHOOL, MIMISAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6448",
            "district": "19",
            "Block": "33",
            "label": "ST.JOESEPH'S MID.SCH. AVUDAIYARKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17285",
            "district": "19",
            "Block": "33",
            "label": "GOVERNMENT HIGH SCHOOL,AMARADAKKI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17286",
            "district": "19",
            "Block": "33",
            "label": "GOVERNMENT HIGH SCHOOL OKKUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17287",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MID SCHOOL   KANNAKKANVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17288",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL ,  THACHAMALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17289",
            "district": "19",
            "Block": "33",
            "label": "S. R.V.HSS, THIRUPPUNAVASAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17290",
            "district": "19",
            "Block": "33",
            "label": "GOVT HIGH SCHOOL .POYYATHANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17291",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MID SCHOOL   AVUDAIYARKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17292",
            "district": "19",
            "Block": "33",
            "label": "GOVT HR SEC SCHOOL AVUDAIYARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17293",
            "district": "19",
            "Block": "33",
            "label": "GOVT.GIRLS HIGH   SCHOOL, AVUDAIYARKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17294",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. SIDDHAKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17295",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. THONDAIMANENDHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17296",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KARANIYANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17297",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. R. PUDUPATTINAM(MUSLIM)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17298",
            "district": "19",
            "Block": "33",
            "label": "GOVT HR SEC SCHOOL GOPALAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17299",
            "district": "19",
            "Block": "33",
            "label": "R.C.HIGH.SCHOOL ,PADIRIGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17300",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MUTHUKUDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17301",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL.ELUNOOTRIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17302",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PIRANTHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17303",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION  MIDDLE SCHOOL. PERUNAVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17304",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. PARAMANDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17305",
            "district": "19",
            "Block": "33",
            "label": "GOVT HIGH SCHOOL PONBETHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17306",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. ARASANAGARIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17307",
            "district": "19",
            "Block": "33",
            "label": "GOVT. HR.SEC. SCHOOL. MIMISAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17308",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. AVANAMPERUNGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17309",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. VELVARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17310",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KEELKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17311",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. PANDIPATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17312",
            "district": "19",
            "Block": "33",
            "label": "MANICKAVASAKAR.H.S.SCHOOL ,PANDIPATHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17313",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KUMULOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17314",
            "district": "19",
            "Block": "33",
            "label": "GOVT. HR.SEC. SCHOOL. KARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17315",
            "district": "19",
            "Block": "33",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KALABAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17316",
            "district": "19",
            "Block": "33",
            "label": "GOVT HIGH SCHOOL THALANUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "34": [
        {
            "key": "1767",
            "district": "22",
            "Block": "34",
            "label": "ROYAL PARK MATRIC HR. SEC. SCHOOL, KUMARIGIRI PET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1958",
            "district": "22",
            "Block": "34",
            "label": "JAY MATRIC HR. SEC. SCHOOL, VAIKKALPATTARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1995",
            "district": "22",
            "Block": "34",
            "label": "A.R.R.S. CBSE SCHOOL, VALASAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3015",
            "district": "22",
            "Block": "34",
            "label": "SRI SWAMY INTERNATIONAL SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3195",
            "district": "22",
            "Block": "34",
            "label": "SHRI SWAMY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3337",
            "district": "22",
            "Block": "34",
            "label": "SRI VIVEKANANDA PATASALAI MATRIC SCHOOL, SATHIYA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4346",
            "district": "22",
            "Block": "34",
            "label": "KAILASH MAANSAROVAR HR. SEC. SCHOOL K.N.COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5295",
            "district": "22",
            "Block": "34",
            "label": "SRI VIDYA MANDIR HR. SEC. SCHOOL, RAMALINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11437",
            "district": "22",
            "Block": "34",
            "label": "GTRMS, ALADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11438",
            "district": "22",
            "Block": "34",
            "label": "GTRHSS ARUNOOTHUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11439",
            "district": "22",
            "Block": "34",
            "label": "PUMS, ANUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11440",
            "district": "22",
            "Block": "34",
            "label": "PUMS, VILAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11441",
            "district": "22",
            "Block": "34",
            "label": "GHSS, KOOTTATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11442",
            "district": "22",
            "Block": "34",
            "label": "GHS, MINNAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11443",
            "district": "22",
            "Block": "34",
            "label": "GHSS, AYOTHIYAPATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11444",
            "district": "22",
            "Block": "34",
            "label": "THANGAM MOUNT LITERA ZEE SCHOOL RAMALINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11445",
            "district": "22",
            "Block": "34",
            "label": "GHS, RAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11446",
            "district": "22",
            "Block": "34",
            "label": "PUMS, PERIYAGOUNDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11447",
            "district": "22",
            "Block": "34",
            "label": "GHS, KARIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11448",
            "district": "22",
            "Block": "34",
            "label": "ST. MARYS HR. SEC. SCHOOL, A.N.MANGALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11449",
            "district": "22",
            "Block": "34",
            "label": "PUMS, KARUMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11450",
            "district": "22",
            "Block": "34",
            "label": "PUMS, SELLIYAMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11451",
            "district": "22",
            "Block": "34",
            "label": "GHSS, METTUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11452",
            "district": "22",
            "Block": "34",
            "label": "PUMS, VEDAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11453",
            "district": "22",
            "Block": "34",
            "label": "GHSS, VEERANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11454",
            "district": "22",
            "Block": "34",
            "label": "GHSS, VELLALAGUNDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11455",
            "district": "22",
            "Block": "34",
            "label": "GHS, D-PERUMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11456",
            "district": "22",
            "Block": "34",
            "label": "HOLY CROSS MATRIC HR. SEC. SCHOOL, K.N. COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11457",
            "district": "22",
            "Block": "34",
            "label": "GBHSS, VALASAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11458",
            "district": "22",
            "Block": "34",
            "label": "VISHWA VIKAASH MATRIC SCHOOL, VALASAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11459",
            "district": "22",
            "Block": "34",
            "label": "GGHSS, VALASAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11460",
            "district": "22",
            "Block": "34",
            "label": "GHSS, SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11461",
            "district": "22",
            "Block": "34",
            "label": "ST.NEVA MILLER MATRICULATION SCHOOL, PARUTHIKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11462",
            "district": "22",
            "Block": "34",
            "label": "ST. GRAHAM MEMORIAL HR. SEC. SCHOOL, VAITHATHANOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11463",
            "district": "22",
            "Block": "34",
            "label": "PUMS, C.THATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11464",
            "district": "22",
            "Block": "34",
            "label": "GHS, ACHANKUTTAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11465",
            "district": "22",
            "Block": "34",
            "label": "PUMS, KUPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11466",
            "district": "22",
            "Block": "34",
            "label": "THE GENE TECH MATRICULATION SCHOOL, KUPPANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11467",
            "district": "22",
            "Block": "34",
            "label": "PUMS, VELLIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11468",
            "district": "22",
            "Block": "34",
            "label": "PUMS, ALLIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11469",
            "district": "22",
            "Block": "34",
            "label": "PUMS, THATHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11470",
            "district": "22",
            "Block": "34",
            "label": "GHS, VAIKKALPATTARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11471",
            "district": "22",
            "Block": "34",
            "label": "PUMS, VARAGAMPADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11472",
            "district": "22",
            "Block": "34",
            "label": "GHSS, UDAYAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11473",
            "district": "22",
            "Block": "34",
            "label": "NOTRE DAME OF HOLY CROSS SCHOOL, GUNDUKALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11474",
            "district": "22",
            "Block": "34",
            "label": "PUMS, KUMARAGIRIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11475",
            "district": "22",
            "Block": "34",
            "label": "JOTHI MATRIC HR. SEC. SCHOOL, K.N. COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11476",
            "district": "22",
            "Block": "34",
            "label": "GHS, K.N. COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21066",
            "district": "22",
            "Block": "34",
            "label": "GLOBAL GATEWAY HI-TECH SCHOOL, ANUPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21595",
            "district": "22",
            "Block": "34",
            "label": "SELVA BHARATHI MATRICULATION  SCHOOL VAIKALPATTARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22443",
            "district": "22",
            "Block": "34",
            "label": "GHS MASINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22847",
            "district": "22",
            "Block": "34",
            "label": "CROWN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23141",
            "district": "22",
            "Block": "34",
            "label": "GTRHS , ADIMALAIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23177",
            "district": "22",
            "Block": "34",
            "label": "VIDHYA PEETHAM I-SCHOOL, MANNARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23275",
            "district": "22",
            "Block": "34",
            "label": "HOLY CROSS INTERNATIONAL SCHOOL, K.N.COLONY, AMMAPET,SALEM-14",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23539",
            "district": "22",
            "Block": "34",
            "label": "A.R.R.S. MATRIC HR. SEC. SCHOOL, VALASAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23540",
            "district": "22",
            "Block": "34",
            "label": "ROYAL PARK SCHOOL, KUMARAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23539",
            "district": "22",
            "Block": "34",
            "label": "A.R.R.S. MATRIC HR. SEC. SCHOOL, VALASAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23540",
            "district": "22",
            "Block": "34",
            "label": "ROYAL PARK SCHOOL, KUMARAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23741",
            "district": "22",
            "Block": "34",
            "label": "S.B.I.O.A. PUBLIC SCHOOL, GUNDUKALLUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "35": [
        {
            "key": "1447",
            "district": "13",
            "Block": "35",
            "label": "ST.PAULS MATRIC. BARGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2756",
            "district": "13",
            "Block": "35",
            "label": "ST.KANAKADASA MHSS AMMERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23402",
            "district": "13",
            "Block": "35",
            "label": "KKP Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "2814",
            "district": "13",
            "Block": "35",
            "label": "SRI VIJAYA VIHAS MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3297",
            "district": "13",
            "Block": "35",
            "label": "VICTORIA MATRIC,HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3352",
            "district": "13",
            "Block": "35",
            "label": "SELVA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3509",
            "district": "13",
            "Block": "35",
            "label": "SRV MATRIC HSS ONDIMAVATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3525",
            "district": "13",
            "Block": "35",
            "label": "MGM HSS PERANDAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3604",
            "district": "13",
            "Block": "35",
            "label": "VAILANKANNI MHSS BARGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4643",
            "district": "13",
            "Block": "35",
            "label": "KINGSLEY GARDEN'S MHSS KANDIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5002",
            "district": "13",
            "Block": "35",
            "label": "KAMESHVER VIDYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5376",
            "district": "13",
            "Block": "35",
            "label": "PUMS NERALAKOTTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5857",
            "district": "13",
            "Block": "35",
            "label": "PUMS PONGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6068",
            "district": "13",
            "Block": "35",
            "label": "C.S.I. MIDDLE SCHOOL, MGR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6111",
            "district": "13",
            "Block": "35",
            "label": "PUMS MAGADEVAGOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6137",
            "district": "13",
            "Block": "35",
            "label": "PUMS DESUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6139",
            "district": "13",
            "Block": "35",
            "label": "PUMS KEEL POONGURTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6173",
            "district": "13",
            "Block": "35",
            "label": "PUMS NALLAPPANAYAKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6444",
            "district": "13",
            "Block": "35",
            "label": "PUMS KULLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6445",
            "district": "13",
            "Block": "35",
            "label": "PUMS ANAKODI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6489",
            "district": "13",
            "Block": "35",
            "label": "PUMS MITTAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6588",
            "district": "13",
            "Block": "35",
            "label": "PUMS PONNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6601",
            "district": "13",
            "Block": "35",
            "label": "PUMS CHETTIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6690",
            "district": "13",
            "Block": "35",
            "label": "PUMS SUNDAGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6693",
            "district": "13",
            "Block": "35",
            "label": "PUMS KARADI GOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6732",
            "district": "13",
            "Block": "35",
            "label": "PUMS MARIMANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20582",
            "district": "13",
            "Block": "35",
            "label": "PUMS AMBALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20583",
            "district": "13",
            "Block": "35",
            "label": "GHSS ANCHOOR-JAGADEVI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20584",
            "district": "13",
            "Block": "35",
            "label": "AES HSS PERIYAPANAMUTLU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20585",
            "district": "13",
            "Block": "35",
            "label": "GHS BALETHOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20586",
            "district": "13",
            "Block": "35",
            "label": "PUMS GENGINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20587",
            "district": "13",
            "Block": "35",
            "label": "PUMS BANDASEEMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20588",
            "district": "13",
            "Block": "35",
            "label": "GHSS BARGUR BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20589",
            "district": "13",
            "Block": "35",
            "label": "GHSS-GIRLS  BARGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20590",
            "district": "13",
            "Block": "35",
            "label": "CONCORDIA HSS  BARGUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20591",
            "district": "13",
            "Block": "35",
            "label": "IELC BLIND BARGUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20592",
            "district": "13",
            "Block": "35",
            "label": "VAILANKANNI HSS BARGUR KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20593",
            "district": "13",
            "Block": "35",
            "label": "GHS BELAVARTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20594",
            "district": "13",
            "Block": "35",
            "label": "GHSS CHINTHAGAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20595",
            "district": "13",
            "Block": "35",
            "label": "PUMS DHINNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20596",
            "district": "13",
            "Block": "35",
            "label": "ST.ANTONY'S HSS ELATHAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20597",
            "district": "13",
            "Block": "35",
            "label": "GHS EMAKKALNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20598",
            "district": "13",
            "Block": "35",
            "label": "PUMS GANGAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20599",
            "district": "13",
            "Block": "35",
            "label": "PUMS GOLRUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20600",
            "district": "13",
            "Block": "35",
            "label": "GHS GUNDIYALNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20601",
            "district": "13",
            "Block": "35",
            "label": "GHSS IKUNDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20602",
            "district": "13",
            "Block": "35",
            "label": "GHS JINJAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20603",
            "district": "13",
            "Block": "35",
            "label": "GHS KALIKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20604",
            "district": "13",
            "Block": "35",
            "label": "PUMS KANAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20605",
            "district": "13",
            "Block": "35",
            "label": "ST.XAVIER MS, KANDIKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20606",
            "district": "13",
            "Block": "35",
            "label": "GHSS KAPPALVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20607",
            "district": "13",
            "Block": "35",
            "label": "GHS KARAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20608",
            "district": "13",
            "Block": "35",
            "label": "GHS KONDAPPANAYANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20609",
            "district": "13",
            "Block": "35",
            "label": "PUMS KONGANSERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20610",
            "district": "13",
            "Block": "35",
            "label": "ANNA ARIVAGAM HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20611",
            "district": "13",
            "Block": "35",
            "label": "PUMS MAJITH GOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20612",
            "district": "13",
            "Block": "35",
            "label": "GHSS MALLAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20613",
            "district": "13",
            "Block": "35",
            "label": "PUMS MARUDEPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20614",
            "district": "13",
            "Block": "35",
            "label": "GHSS MELKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20615",
            "district": "13",
            "Block": "35",
            "label": "GHS MINDIGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20616",
            "district": "13",
            "Block": "35",
            "label": "GHS NACKALPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20617",
            "district": "13",
            "Block": "35",
            "label": "GHS OPPATHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20618",
            "district": "13",
            "Block": "35",
            "label": "GHSS ORAPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20619",
            "district": "13",
            "Block": "35",
            "label": "PUMS PARAVEPPANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20620",
            "district": "13",
            "Block": "35",
            "label": "GHS PERIYAPANAMUTLU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20621",
            "district": "13",
            "Block": "35",
            "label": "GHSS PERUGOPANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20622",
            "district": "13",
            "Block": "35",
            "label": "GHSS GIRLS POCHAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20623",
            "district": "13",
            "Block": "35",
            "label": "GHSS BOYS POCHAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20624",
            "district": "13",
            "Block": "35",
            "label": "GHSS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20625",
            "district": "13",
            "Block": "35",
            "label": "GHSS-GIRLS  SANTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20626",
            "district": "13",
            "Block": "35",
            "label": "GHSS SANTHUR BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20627",
            "district": "13",
            "Block": "35",
            "label": "PUMS SEEMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20628",
            "district": "13",
            "Block": "35",
            "label": "GHSS SIGARALAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20629",
            "district": "13",
            "Block": "35",
            "label": "GHS SOOLAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20630",
            "district": "13",
            "Block": "35",
            "label": "PUMS SRINIVASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20631",
            "district": "13",
            "Block": "35",
            "label": "PUMS THELLABENDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20632",
            "district": "13",
            "Block": "35",
            "label": "GHSS-GIRLS THOGARAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20633",
            "district": "13",
            "Block": "35",
            "label": "GHSS THOGARAPALLI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20634",
            "district": "13",
            "Block": "35",
            "label": "GHSS VARATANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20635",
            "district": "13",
            "Block": "35",
            "label": "PUMS VARAMANAGUNDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20636",
            "district": "13",
            "Block": "35",
            "label": "GHS VENNAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20637",
            "district": "13",
            "Block": "35",
            "label": "PUMS AGAMUDINAGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20638",
            "district": "13",
            "Block": "35",
            "label": "PUMS CHINNAMATTARAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20639",
            "district": "13",
            "Block": "35",
            "label": "PUMS MADHEPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20640",
            "district": "13",
            "Block": "35",
            "label": "PUMS PUDHU MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20641",
            "district": "13",
            "Block": "35",
            "label": "PUMS THATHEMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20642",
            "district": "13",
            "Block": "35",
            "label": "GHS VEPPALAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21172",
            "district": "13",
            "Block": "35",
            "label": "GONZAGA MHSS KATHANPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21421",
            "district": "13",
            "Block": "35",
            "label": "GHS GUTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21631",
            "district": "13",
            "Block": "35",
            "label": "ST. JOSEPH'S INTERNATIONAL SCHOOL, PERIYAPANAMUTLU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22292",
            "district": "13",
            "Block": "35",
            "label": "K.E.T. MATRICULATION PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22672",
            "district": "13",
            "Block": "35",
            "label": "NOTRE DAME ACADEMY MATRIC SCHOOL KUNNANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22767",
            "district": "13",
            "Block": "35",
            "label": "SIVAGAMIAMMAL INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22892",
            "district": "13",
            "Block": "35",
            "label": "GHS ALERAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23016",
            "district": "13",
            "Block": "35",
            "label": "SELVA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23052",
            "district": "13",
            "Block": "35",
            "label": "VAILANKANNI HIGHER SECONDARY SCHOOL BARGUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23076",
            "district": "13",
            "Block": "35",
            "label": "ST. KANAKADASA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23742",
            "district": "13",
            "Block": "35",
            "label": "PUMS SRINIVASAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23743",
            "district": "13",
            "Block": "35",
            "label": "DURAI MODERN SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "36": [
        {
            "key": "3266",
            "district": "7",
            "Block": "36",
            "label": "ANNAI VAILANKANNI MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3815",
            "district": "7",
            "Block": "36",
            "label": "N.S.V.V MATRIC HSS, PATTIVEERANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4191",
            "district": "7",
            "Block": "36",
            "label": "SRI SARADHA VIDHYALAYA MATRIC HR SEC SCHOOL, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5675",
            "district": "7",
            "Block": "36",
            "label": "JEYASEELAN MEM MAT  BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6299",
            "district": "7",
            "Block": "36",
            "label": "MOUNT ZION MATRIC HR.SEC.SCHOOL, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13342",
            "district": "7",
            "Block": "36",
            "label": "PUMS, K.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13343",
            "district": "7",
            "Block": "36",
            "label": "GOVT. KALLAR MIDDLE SCHOOL, G.THUMMALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13344",
            "district": "7",
            "Block": "36",
            "label": "PUMS, M.VADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13345",
            "district": "7",
            "Block": "36",
            "label": "ANNA AIDED MIDDLE SCHOOL, MUTHALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13346",
            "district": "7",
            "Block": "36",
            "label": "GOVT. KALLAR HSS SEVUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13347",
            "district": "7",
            "Block": "36",
            "label": "N.S.V.V.B HSS PATTIVEERANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13348",
            "district": "7",
            "Block": "36",
            "label": "N.S.V.V.G HSS PATTIVEERANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13349",
            "district": "7",
            "Block": "36",
            "label": "LAKSHMI NARAYANA MATRICULATION SCHOOL, PATTIVEERANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13350",
            "district": "7",
            "Block": "36",
            "label": "GOVT KALLAR HSS, MALLANAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13351",
            "district": "7",
            "Block": "36",
            "label": "PUMS, KULIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13352",
            "district": "7",
            "Block": "36",
            "label": "GHSS, KOMBAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13353",
            "district": "7",
            "Block": "36",
            "label": "PUMS, KATTAKAMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13354",
            "district": "7",
            "Block": "36",
            "label": "PUMS, 1ST WARD BATLAGUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13355",
            "district": "7",
            "Block": "36",
            "label": "GOVT HS OLD  BATLAGUNDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13356",
            "district": "7",
            "Block": "36",
            "label": "GOVT BOYS HSS  BATLAGUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13357",
            "district": "7",
            "Block": "36",
            "label": "HOLYCROSS GIRLSHSS  BATLAGUNDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13358",
            "district": "7",
            "Block": "36",
            "label": "MAHALAKSHMI GHSS  BATLAGUNDU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13359",
            "district": "7",
            "Block": "36",
            "label": "CSI HSS, BATLAGUNDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13360",
            "district": "7",
            "Block": "36",
            "label": "PUMS,VERIYAPPANAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13361",
            "district": "7",
            "Block": "36",
            "label": "GHS, VIRALIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13362",
            "district": "7",
            "Block": "36",
            "label": "GHS, ELUVANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13363",
            "district": "7",
            "Block": "36",
            "label": "GOVT. KALLAR HSS, THEPPATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13364",
            "district": "7",
            "Block": "36",
            "label": "PUMS, VALAIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13365",
            "district": "7",
            "Block": "36",
            "label": "GOVT MODEL HSS, VIRUVEEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13366",
            "district": "7",
            "Block": "36",
            "label": "PUMS, NADAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13367",
            "district": "7",
            "Block": "36",
            "label": "GHSS, SEKKAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13368",
            "district": "7",
            "Block": "36",
            "label": "PUMS, SENGATTAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21502",
            "district": "7",
            "Block": "36",
            "label": "GOVT.ADWHS, V.KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21529",
            "district": "7",
            "Block": "36",
            "label": "IQBALIA  HIGH SCHOOL, BATLAGUNDU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21891",
            "district": "7",
            "Block": "36",
            "label": "FIRST STEP PUBLIC SCHOOL, KANAVAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21892",
            "district": "7",
            "Block": "36",
            "label": "APOORVAA PUBLIC SCHOOL (CBSE), M.VADIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21893",
            "district": "7",
            "Block": "36",
            "label": "SWAMI VIVEKANANDA VIDYALAYA, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22496",
            "district": "7",
            "Block": "36",
            "label": "SREE RAMS MATRICULATION SCHOOL, KONNAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22497",
            "district": "7",
            "Block": "36",
            "label": "SRI GURUKRISHNA MATRICULATION SCHOOL, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23206",
            "district": "7",
            "Block": "36",
            "label": "JAYASEELAN PUBLIC SCHOOL, BATLAGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "37": [
        {
            "key": "673",
            "district": "8",
            "Block": "37",
            "label": "PUMS VAIKKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "683",
            "district": "8",
            "Block": "37",
            "label": "PUMS SANKARAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "702",
            "district": "8",
            "Block": "37",
            "label": "PUMS KAVINDAPADIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "840",
            "district": "8",
            "Block": "37",
            "label": "SARASWATHI VIDHYASHRAM MATRIC HIGHER SECONDARY SCHOOL KAVUNDAPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "858",
            "district": "8",
            "Block": "37",
            "label": "PUMS PERUMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "894",
            "district": "8",
            "Block": "37",
            "label": "PUMS JAMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "946",
            "district": "8",
            "Block": "37",
            "label": "PUMS, PERAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "962",
            "district": "8",
            "Block": "37",
            "label": "MMS VARNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23365",
            "district": "8",
            "Block": "37",
            "label": " Sri sakthi supreme ideal vikas matric higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "966",
            "district": "8",
            "Block": "37",
            "label": "PUMS BOMMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "987",
            "district": "8",
            "Block": "37",
            "label": "MMS BHAVANI(EAST)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1020",
            "district": "8",
            "Block": "37",
            "label": "PUMS SIRAIMEETANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1071",
            "district": "8",
            "Block": "37",
            "label": "SSV MHSS SANKARAGOUNDANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1087",
            "district": "8",
            "Block": "37",
            "label": "ADHARSH VIDHYALAYA MHSS PARUVACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1168",
            "district": "8",
            "Block": "37",
            "label": "OPTIMUS PUBLIC SCHOOL SEVAGOUNDANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1226",
            "district": "8",
            "Block": "37",
            "label": "PUMS MINNAVETTUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1238",
            "district": "8",
            "Block": "37",
            "label": "PUMS KADAIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1258",
            "district": "8",
            "Block": "37",
            "label": "PUMS SEVAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1269",
            "district": "8",
            "Block": "37",
            "label": "PUMS VAIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1289",
            "district": "8",
            "Block": "37",
            "label": "ST.MARY'S MHSS KURUPPANAIKKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1301",
            "district": "8",
            "Block": "37",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEMBOOTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1316",
            "district": "8",
            "Block": "37",
            "label": "PUMS THIPPICHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1325",
            "district": "8",
            "Block": "37",
            "label": "TNK MHSS KAVINDAPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1326",
            "district": "8",
            "Block": "37",
            "label": "IDEAL MHS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1341",
            "district": "8",
            "Block": "37",
            "label": "PUMS CHINNAPERICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1372",
            "district": "8",
            "Block": "37",
            "label": "PUMS  ORICHERIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1428",
            "district": "8",
            "Block": "37",
            "label": "MMS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1512",
            "district": "8",
            "Block": "37",
            "label": "PUMS PALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1554",
            "district": "8",
            "Block": "37",
            "label": "PUMS SUKKANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1756",
            "district": "8",
            "Block": "37",
            "label": "PUMS ERUSANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1799",
            "district": "8",
            "Block": "37",
            "label": "ADHARSH VIDHYALAYA PUBLIC SCHOOL, PARUVATCHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1860",
            "district": "8",
            "Block": "37",
            "label": "PUMS  APPAKUDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1891",
            "district": "8",
            "Block": "37",
            "label": "SRI VIDHYA MANDIR MS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23374",
            "district": "8",
            "Block": "37",
            "label": "kamala vijaya vidhyalaya school",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "12055",
            "district": "8",
            "Block": "37",
            "label": "GHS SALANGAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12056",
            "district": "8",
            "Block": "37",
            "label": "GHS ALATHUR THATTARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12057",
            "district": "8",
            "Block": "37",
            "label": "IDEAL HSS PARUVACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12058",
            "district": "8",
            "Block": "37",
            "label": "ADHARSH VIDHYALAYA HSS PARUVACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12059",
            "district": "8",
            "Block": "37",
            "label": "NEW IDEAL HSS EERATTAIKARADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12060",
            "district": "8",
            "Block": "37",
            "label": "GHS PUNNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12061",
            "district": "8",
            "Block": "37",
            "label": "SVVB MATRIC HIGHER SECONDARY SCHOOL, SAKTHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12062",
            "district": "8",
            "Block": "37",
            "label": "GHSS, A.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12063",
            "district": "8",
            "Block": "37",
            "label": "GHSS MYLAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12064",
            "district": "8",
            "Block": "37",
            "label": "GHS URATCHIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12065",
            "district": "8",
            "Block": "37",
            "label": "GHS KUTTIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12066",
            "district": "8",
            "Block": "37",
            "label": "GOVERNMENT VINOBHA HIGHER SECONDARY SCHOOL, THALAVAIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12067",
            "district": "8",
            "Block": "37",
            "label": "SMGHSS  ODATHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12068",
            "district": "8",
            "Block": "37",
            "label": "GHSS PERIYAPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12069",
            "district": "8",
            "Block": "37",
            "label": "GGHSS  KAVINDAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12070",
            "district": "8",
            "Block": "37",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  KAVINDAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12071",
            "district": "8",
            "Block": "37",
            "label": "GHSS, AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12072",
            "district": "8",
            "Block": "37",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, BHAVANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12073",
            "district": "8",
            "Block": "37",
            "label": "GGHSS BHAVANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20960",
            "district": "8",
            "Block": "37",
            "label": "SRI SWAMI VIVEKANANDA INTELLECTUAL DISABLED SCHOOL, BHAVANI",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "22125",
            "district": "8",
            "Block": "37",
            "label": "PSK HI TECH ACADEMY, PARUVACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22457",
            "district": "8",
            "Block": "37",
            "label": "TRIPLEE A MATRIC & PLAY SCHOOL, KURUPPANAICKANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23241",
            "district": "8",
            "Block": "37",
            "label": "GOVERNMENT HIGH SCHOOL, KURUPPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "38": [
        {
            "key": "646",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "733",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUNGAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1059",
            "district": "8",
            "Block": "38",
            "label": "SRC MEMORIAL MATRIC. HR.SEC.SCHOOL,NALLUR(PO),PULIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1228",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THOPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1259",
            "district": "8",
            "Block": "38",
            "label": "MAMAHARISHI EASWARAYA GURUKULAM MATRICULATION HR.SEC.SCHOOL, THABOVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1478",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MADHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1491",
            "district": "8",
            "Block": "38",
            "label": "AMMA MATRICULATION HIGHER SECONDARY SCHOOL, PUNJAI PULIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1507",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, JJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1526",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MALLIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1566",
            "district": "8",
            "Block": "38",
            "label": "HOLY REDEEMER'S MATRIC SCHOOL, BHAVANISAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1771",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANAKKARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1820",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BAGUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1831",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEHRUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1921",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SITHANKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1960",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1988",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARAYEPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2068",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARATTUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2113",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2179",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERIYAKALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4331",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUNGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12074",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ERANGATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12075",
            "district": "8",
            "Block": "38",
            "label": "RC HIGH SCHOOL, VALIPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12076",
            "district": "8",
            "Block": "38",
            "label": "GOVT.HIGHER SEC.SCHOOL,KAVILIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12077",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12078",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGH SCHOOL, KARAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12079",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHURASANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12080",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THAIRPALLAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12081",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VENGANAICKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12082",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGHER SEC.SCHOOL, BHAVANISAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12083",
            "district": "8",
            "Block": "38",
            "label": "GOVT.HIGH SCHOOL, NALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12084",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGHER SEC. SCHOOL, PANAYAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12085",
            "district": "8",
            "Block": "38",
            "label": "KVK GOVT. BOYS HS SCHOOL, P.PULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12086",
            "district": "8",
            "Block": "38",
            "label": "KOM GOVT. GIRLS HS SCHOOL, P.PULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12087",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGH SCHOOL, UTHANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12088",
            "district": "8",
            "Block": "38",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,GODEPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12089",
            "district": "8",
            "Block": "38",
            "label": "GOVT. HIGHER SEC. SCHOOL, DODDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20990",
            "district": "8",
            "Block": "38",
            "label": "SNR VIDHYA NETHRA MATRICULATION HR.SEC.SCHOOL,VINNAPPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22704",
            "district": "8",
            "Block": "38",
            "label": "CHANAKYA INTERNATIONAL SCHOOL, PONMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22705",
            "district": "8",
            "Block": "38",
            "label": "SPRINGDALE PUBLIC SCHOOL, PUNJAI PULIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "39": [
        {
            "key": "2927",
            "district": "5",
            "Block": "39",
            "label": "PUMS THALAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3128",
            "district": "5",
            "Block": "39",
            "label": "PUMS ADHIVARAGANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3177",
            "district": "5",
            "Block": "39",
            "label": "AMS VEERAMUDAIYANATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3603",
            "district": "5",
            "Block": "39",
            "label": "AMS MULLIPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3622",
            "district": "5",
            "Block": "39",
            "label": "PUMS BHUVANAGIRI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3656",
            "district": "5",
            "Block": "39",
            "label": "SARASWATHI MATRICULATION SCHOOL BOOTHAVARAYANPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3720",
            "district": "5",
            "Block": "39",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , BOOTHAVARAYANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3725",
            "district": "5",
            "Block": "39",
            "label": "PUMS LALPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4018",
            "district": "5",
            "Block": "39",
            "label": "PUMS KUMUDIMOOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4031",
            "district": "5",
            "Block": "39",
            "label": "PUMS C.ALAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4037",
            "district": "5",
            "Block": "39",
            "label": "PUMS NELLIKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4135",
            "district": "5",
            "Block": "39",
            "label": "PUMS KARAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4202",
            "district": "5",
            "Block": "39",
            "label": "PUMS, VATHARAYANTHETHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4301",
            "district": "5",
            "Block": "39",
            "label": "PUMS KEEZHAMOONGILADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4472",
            "district": "5",
            "Block": "39",
            "label": "PUGMS PERUMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4527",
            "district": "5",
            "Block": "39",
            "label": "PUMS MELAMOONGILADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4557",
            "district": "5",
            "Block": "39",
            "label": "ADWS THILLAINAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4843",
            "district": "5",
            "Block": "39",
            "label": "PUMS NATHAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4936",
            "district": "5",
            "Block": "39",
            "label": "AMS AMBAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4952",
            "district": "5",
            "Block": "39",
            "label": "PUMS KATHAZHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5337",
            "district": "5",
            "Block": "39",
            "label": "SRI ARUNACHALA  MATRICULATION  HIGHER SECONDARY  SCHOOL,  BHUVANAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5651",
            "district": "5",
            "Block": "39",
            "label": "PUMS ESWARAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5963",
            "district": "5",
            "Block": "39",
            "label": "PUMS PERIYANARKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15543",
            "district": "5",
            "Block": "39",
            "label": "GHSS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15544",
            "district": "5",
            "Block": "39",
            "label": "GHSS MANJAKOLLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15545",
            "district": "5",
            "Block": "39",
            "label": "GHS SATHAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15546",
            "district": "5",
            "Block": "39",
            "label": "PUMS MARUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15547",
            "district": "5",
            "Block": "39",
            "label": "GHSS ERUMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15548",
            "district": "5",
            "Block": "39",
            "label": "GHS B ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15549",
            "district": "5",
            "Block": "39",
            "label": "GHSS C.MUTLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15550",
            "district": "5",
            "Block": "39",
            "label": "SHREE RAGHAVENDRA SCHOOL, CBSE. KEEZHAMOONGILADI,CHIDAMBARAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15551",
            "district": "5",
            "Block": "39",
            "label": "EDISON G AGORAM  MEMORIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15552",
            "district": "5",
            "Block": "39",
            "label": "VALLALAR HIGHER SECONDARY SCHOOL, VALAYAMADEVI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15553",
            "district": "5",
            "Block": "39",
            "label": "GHS PINNALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15554",
            "district": "5",
            "Block": "39",
            "label": "GHS VADAKKUTHITTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15555",
            "district": "5",
            "Block": "39",
            "label": "GHSS MEL BHUVANAGIRI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15556",
            "district": "5",
            "Block": "39",
            "label": "GGHSS, BHUVANAGIRI GIRLS -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23368",
            "district": "5",
            "Block": "39",
            "label": "BEST MATRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "15557",
            "district": "5",
            "Block": "39",
            "label": "BHARATHI MATRICULATION HIGHER SECONDARYSCHOOL,BHUVANAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15558",
            "district": "5",
            "Block": "39",
            "label": "MANGALAM MATRICULATION HIGHER SECONDARY SCHOOL,BHUVANAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15559",
            "district": "5",
            "Block": "39",
            "label": "SRI  RAGHAVENDRA MATRIC HIGHER SECONDARY  SCHOOL-BHUVANAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15560",
            "district": "5",
            "Block": "39",
            "label": "DGM HSS SETHIYATHOPE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15561",
            "district": "5",
            "Block": "39",
            "label": "CHANDRA HR SEC SCHOOL -  SETHIYATHOPE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15562",
            "district": "5",
            "Block": "39",
            "label": "S.D.EADEN MATRICULATION SCHOOL,  SETHIATHOPE.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15563",
            "district": "5",
            "Block": "39",
            "label": "GHS SETHIYATHOPE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21486",
            "district": "5",
            "Block": "39",
            "label": "GOVT(ADW)HR SEC SCHOOL,VANDURAYANPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21694",
            "district": "5",
            "Block": "39",
            "label": "OXFORD ENGLISH SCHOOL-THILLAINAYAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22210",
            "district": "5",
            "Block": "39",
            "label": "REDCE SPECIAL SCHOOL-VANDRAYANPATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22211",
            "district": "5",
            "Block": "39",
            "label": "SRI RAGAVENDRA SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23474",
            "district": "5",
            "Block": "39",
            "label": "SRI KALAIMAGAL VIDHYALAYANURSERY&PRIMARY SCHOOL, MARUDHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23474",
            "district": "5",
            "Block": "39",
            "label": "SRI KALAIMAGAL VIDHYALAYANURSERY&PRIMARY SCHOOL, MARUDHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23744",
            "district": "5",
            "Block": "39",
            "label": "GHSS KRISHNAPURAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23745",
            "district": "5",
            "Block": "39",
            "label": "PUMS NATHAMEDU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23746",
            "district": "5",
            "Block": "39",
            "label": "PUMS MARUDUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "40": [
        {
            "key": "794",
            "district": "27",
            "Block": "40",
            "label": "PANKAJAM MIDDLE SCHOOL -  BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "909",
            "district": "27",
            "Block": "40",
            "label": "SOWNDEESWARI MIDDLE SCHOOL - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1547",
            "district": "27",
            "Block": "40",
            "label": "PITCHANDI MIDDLE SCHOOL -  BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1662",
            "district": "27",
            "Block": "40",
            "label": "T.V.MS - B. RENGANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2132",
            "district": "27",
            "Block": "40",
            "label": "PUMS-SOOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2249",
            "district": "27",
            "Block": "40",
            "label": "PUMS -  KUNDALNAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2380",
            "district": "27",
            "Block": "40",
            "label": "MOOVENDAR MIDDLE SCHOOL - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2545",
            "district": "27",
            "Block": "40",
            "label": "PUMS-B.DHARMATHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2667",
            "district": "27",
            "Block": "40",
            "label": "S.K.L.MS - KODANGIPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2885",
            "district": "27",
            "Block": "40",
            "label": "Z.K.M. MAT HSS - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2943",
            "district": "27",
            "Block": "40",
            "label": "THIRUPPATHI.MS - DOMBUCHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3026",
            "district": "27",
            "Block": "40",
            "label": "PUM S- VALAIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3048",
            "district": "27",
            "Block": "40",
            "label": "15TH WARD MPL MS - BODI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3171",
            "district": "27",
            "Block": "40",
            "label": "PUMS - AMMAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3312",
            "district": "27",
            "Block": "40",
            "label": "KAMARAJAR VIDYASALAI MHSS - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3474",
            "district": "27",
            "Block": "40",
            "label": "HAJEE K.L.K.S  ABDUL RAZAK INTERNATIONAL MATRIC SCHOOL, BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3826",
            "district": "27",
            "Block": "40",
            "label": "SCISM MATRIC HSS  - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4142",
            "district": "27",
            "Block": "40",
            "label": "S.K.S.MS - DOMBUCHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4229",
            "district": "27",
            "Block": "40",
            "label": "GOVT A.D.W MID SCHOOL - B.DURAIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4232",
            "district": "27",
            "Block": "40",
            "label": "SRI RAM SOLAI MIDDLE SCH -  KAMARAJAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4313",
            "district": "27",
            "Block": "40",
            "label": "PUMS - AGAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4584",
            "district": "27",
            "Block": "40",
            "label": "BUDDHAR MIDDLE SCHOOL - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5217",
            "district": "27",
            "Block": "40",
            "label": "SENTHIL MIDDLE SCHOOL - KEPPURENGANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5549",
            "district": "27",
            "Block": "40",
            "label": "NIRMALA MS - SILAMARATHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5572",
            "district": "27",
            "Block": "40",
            "label": "PUMS -  KARIAPPAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18504",
            "district": "27",
            "Block": "40",
            "label": "PUMS - KURANGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18505",
            "district": "27",
            "Block": "40",
            "label": "GOVT HSS, BOOTHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18506",
            "district": "27",
            "Block": "40",
            "label": "PURNA VIDYA BHAVAN MAT HSS - KODANGIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18507",
            "district": "27",
            "Block": "40",
            "label": "GKMS - B.MEENATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18508",
            "district": "27",
            "Block": "40",
            "label": "GOVT KALLAR HS -  B.ANAIKARAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18509",
            "district": "27",
            "Block": "40",
            "label": "PUMS,-KARATUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18510",
            "district": "27",
            "Block": "40",
            "label": "GOVT HSS-MELACHOCKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18511",
            "district": "27",
            "Block": "40",
            "label": "PUMS - MUNTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18512",
            "district": "27",
            "Block": "40",
            "label": "PUMS-MANAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18513",
            "district": "27",
            "Block": "40",
            "label": "GOVT HSS-SILAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18514",
            "district": "27",
            "Block": "40",
            "label": "GOVT HSS - SILLAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18515",
            "district": "27",
            "Block": "40",
            "label": "M.P.M. MS -  VISUVASAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18516",
            "district": "27",
            "Block": "40",
            "label": "GRAMA POTHU KALIKADEVI HS - BADRAKALIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18517",
            "district": "27",
            "Block": "40",
            "label": "PATCHAIYAPPA.HS - UPPUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18518",
            "district": "27",
            "Block": "40",
            "label": "PUMS - KOOLAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18519",
            "district": "27",
            "Block": "40",
            "label": "GKMS - PALARPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18520",
            "district": "27",
            "Block": "40",
            "label": "PUMS -  MANIAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18521",
            "district": "27",
            "Block": "40",
            "label": "7TH WARD MPL HSS - BODI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18522",
            "district": "27",
            "Block": "40",
            "label": "GKMS -  BODI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18523",
            "district": "27",
            "Block": "40",
            "label": "SRI SOWDAMBIKA MIDDLE SCHOOL - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18524",
            "district": "27",
            "Block": "40",
            "label": "Z.K.M. HSS -  BODINAYAKANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18525",
            "district": "27",
            "Block": "40",
            "label": "PANKAJAM GIRLS HSS -  BODINAYAKANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18526",
            "district": "27",
            "Block": "40",
            "label": "NADAR HSS, THIRUMALAPURAM - BODI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18527",
            "district": "27",
            "Block": "40",
            "label": "10TH WARD MPL HS - BODINAYAKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18528",
            "district": "27",
            "Block": "40",
            "label": "KAMARAJ VIDHYALAYA HS - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20997",
            "district": "27",
            "Block": "40",
            "label": "THE SPICE VALLEY PUBLIC SCHOOL - BODI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21403",
            "district": "27",
            "Block": "40",
            "label": "GOVT HS -  RASINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21612",
            "district": "27",
            "Block": "40",
            "label": "SCISM RURAL ACADEMY MATRICULATION   SCHOOL - RASINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21748",
            "district": "27",
            "Block": "40",
            "label": "PURNA VIDYA BHAVAN PUBLIC SCHOOL - KODANGIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22259",
            "district": "27",
            "Block": "40",
            "label": "SRI HAYAGREEVAR VIDHYA MANDIR MATRIC  HR.SEC. SCHOOL, SADAYALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22260",
            "district": "27",
            "Block": "40",
            "label": "G.D. MAT HSS - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22611",
            "district": "27",
            "Block": "40",
            "label": "R.P. HS - MEENAKSHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22612",
            "district": "27",
            "Block": "40",
            "label": "SCISM SENIOR SECONDARY  SCHOOL (CBSE) -  MELACHOKKANATHAPURAM,  BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23007",
            "district": "27",
            "Block": "40",
            "label": "G.D. PUBLIC SCHOOL - BODINAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "41": [
        {
            "key": "1265",
            "district": "20",
            "Block": "41",
            "label": "R.K.SAMY MATRICULATION HIGH SCHOOL, SEYYALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1690",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANJAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1722",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MENNANTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2124",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, POTTITHATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2687",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.VALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2690",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHUVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2699",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3711",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VEERAVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19103",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KAMANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19104",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANJUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19105",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SATHIRAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19106",
            "district": "20",
            "Block": "41",
            "label": "VASAN MATRICULATION HIGHER SECONDARY SCHOOL, SATHIRAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19107",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGH SCHOOL, SEVVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19108",
            "district": "20",
            "Block": "41",
            "label": "GOVERNMENT HIGH SCHOOL, S.KODIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19109",
            "district": "20",
            "Block": "41",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19110",
            "district": "20",
            "Block": "41",
            "label": "NADAR MAHAJANA SANGAM SERMATHAI VASAN HIGH SCHOOL, ARASADIVANDAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22629",
            "district": "20",
            "Block": "41",
            "label": "MEENATCHI MATRIC HIGHER SECONDARY SCHOOL, CHINNA EDAMBADAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23747",
            "district": "20",
            "Block": "41",
            "label": "NETHAJI SUBHASH CHANDRA BOSE AWASIYA VIDYALAYA, SATHIRAKUDI, BOGALUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23748",
            "district": "20",
            "Block": "41",
            "label": "BODHI VIDHYALAYA CBSE SCHOOL, SEYYALUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "42": [
        {
            "key": "1403",
            "district": "25",
            "Block": "42",
            "label": "SIR SIVASWAMI BALAVIDHYALAYA  MATRICULATION HIGHER SECONDARY SCHOOL -THIRUKKATTUPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2500",
            "district": "25",
            "Block": "42",
            "label": "REV.FR.LXM HSS -THIRUKKATTUPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2572",
            "district": "25",
            "Block": "42",
            "label": "OUR LADY OF HEALTH MATRIC HR. SEC SCHOOL -BUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2585",
            "district": "25",
            "Block": "42",
            "label": "PUMS-VALAMBAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2657",
            "district": "25",
            "Block": "42",
            "label": "PUMS-PALAYAKARIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2970",
            "district": "25",
            "Block": "42",
            "label": "ADWMS-CHINNAKANGEYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3024",
            "district": "25",
            "Block": "42",
            "label": "PUMS-PAVANAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3364",
            "district": "25",
            "Block": "42",
            "label": "PUMS-MGM.SANATORIUM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3591",
            "district": "25",
            "Block": "42",
            "label": "PUMS-NEMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3602",
            "district": "25",
            "Block": "42",
            "label": "PUMS-PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3633",
            "district": "25",
            "Block": "42",
            "label": "RC AMS-MEGALATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3683",
            "district": "25",
            "Block": "42",
            "label": "PUMS-PALAYAPATTI SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3696",
            "district": "25",
            "Block": "42",
            "label": "CHELLAM AMS-ORATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3749",
            "district": "25",
            "Block": "42",
            "label": "PUMS-THOGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3901",
            "district": "25",
            "Block": "42",
            "label": "PUMS-KOOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3952",
            "district": "25",
            "Block": "42",
            "label": "VIDHYA MHSS- BUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16429",
            "district": "25",
            "Block": "42",
            "label": "SINNARANI AHS- A.POONDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16430",
            "district": "25",
            "Block": "42",
            "label": "ADWHSS-AGARAPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16431",
            "district": "25",
            "Block": "42",
            "label": "GHS-ACHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16432",
            "district": "25",
            "Block": "42",
            "label": "GHS-INDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16433",
            "district": "25",
            "Block": "42",
            "label": "GHS-KOVILADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16434",
            "district": "25",
            "Block": "42",
            "label": "SRIDIVYA MKR MAT.SCHOOL-SANOORAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16435",
            "district": "25",
            "Block": "42",
            "label": "GHS-CHELLAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16436",
            "district": "25",
            "Block": "42",
            "label": "GHSS-SENGIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16437",
            "district": "25",
            "Block": "42",
            "label": "ADW HS -PUDUKUDDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16438",
            "district": "25",
            "Block": "42",
            "label": "ST PETER PAULS  HS -MUTHANDIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16439",
            "district": "25",
            "Block": "42",
            "label": "GGHSS -BUDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16440",
            "district": "25",
            "Block": "42",
            "label": "GBHSS-BUDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16441",
            "district": "25",
            "Block": "42",
            "label": "GHSS-MANAIYERIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16442",
            "district": "25",
            "Block": "42",
            "label": "ADWHS-MUTHUVEERAKANDIYANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16443",
            "district": "25",
            "Block": "42",
            "label": "GHSS-ELANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16444",
            "district": "25",
            "Block": "42",
            "label": "GHS-RAYAMUNDANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16445",
            "district": "25",
            "Block": "42",
            "label": "GHS-THIRUKKATTUPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16446",
            "district": "25",
            "Block": "42",
            "label": "SIVASWAMI AYYAR CENTENARY GIRLS' HIGH SCHOOL THIRUKKATTUPPALLI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16447",
            "district": "25",
            "Block": "42",
            "label": "SIR SIVASWAMI AYYAR HSS-THIRUKATTUPALLI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21863",
            "district": "25",
            "Block": "42",
            "label": "YAGAPPA INT RESI. SCHOOL -SENGIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22223",
            "district": "25",
            "Block": "42",
            "label": "SHEMFORD FUTURISTIC SCHOOL-BUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "43": [
        {
            "key": "17861",
            "district": "14",
            "Block": "43",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANAMOOPPANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17862",
            "district": "14",
            "Block": "43",
            "label": "GOVT HIGH SCHOOL, CHAKKARAPPANAYAKKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17863",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, VIKKIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17864",
            "district": "14",
            "Block": "43",
            "label": "ST.JOSEPH MATRIC HR SEC SCHOOL, VIKKIRAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17865",
            "district": "14",
            "Block": "43",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NADUMUDALAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17866",
            "district": "14",
            "Block": "43",
            "label": "GOVT.KALLAR.M.S. PANNIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17867",
            "district": "14",
            "Block": "43",
            "label": "ST CLARET'S HIGHER SECONDARY SCHOOL  KARUMATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17868",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, VADAKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17869",
            "district": "14",
            "Block": "43",
            "label": "GOVT HIGH SCHOOL, CHELLAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17870",
            "district": "14",
            "Block": "43",
            "label": "FATIMA CONVENT MATRIC HIGH SCHOOL, CHELLAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17871",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR. SEC. SCHOOL, KOVILANGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17872",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S JOTHIMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17873",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. VALANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17874",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. KURAVAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17875",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. K.NATTAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17876",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, PAPPAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17877",
            "district": "14",
            "Block": "43",
            "label": "GOVT.K.M.S KATTAKARUPPANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17878",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. K.SEMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17879",
            "district": "14",
            "Block": "43",
            "label": "GOVT.KALLAR.M.S. KUPPANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17880",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, NATTAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17881",
            "district": "14",
            "Block": "43",
            "label": "NATANESHWER MATRICULATION SCHOOL, NATTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17882",
            "district": "14",
            "Block": "43",
            "label": "GOVT.KALLAR.M.S. AMBATAYAMPATI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17883",
            "district": "14",
            "Block": "43",
            "label": "P.U.M.S. SINDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17884",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, THUMMAKUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17885",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HR SEC SCHOOL, V.KALLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22241",
            "district": "14",
            "Block": "43",
            "label": "GURUDEV MATRIC  SCHOOL, POOTHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22598",
            "district": "14",
            "Block": "43",
            "label": "GOVT KALLAR HIGH SCHOOL, AYYANARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23036",
            "district": "14",
            "Block": "43",
            "label": "GOVT. KALLAR HIGH SCHOOL, A.PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "44": [
        {
            "key": "2552",
            "district": "34",
            "Block": "44",
            "label": "BARATH MAT  HSS CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2889",
            "district": "34",
            "Block": "44",
            "label": "PUMS KOTTAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2893",
            "district": "34",
            "Block": "44",
            "label": "PUMS MELPENNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3502",
            "district": "34",
            "Block": "44",
            "label": "SRI VIVEKANANDA MATRIC HIGHER SEC SCHOOL-CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4120",
            "district": "34",
            "Block": "44",
            "label": "PUMS PERIYAKALTHAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4188",
            "district": "34",
            "Block": "44",
            "label": "PUMS NEW KUILAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4383",
            "district": "34",
            "Block": "44",
            "label": "SAHAYAMADHA MATRIC HSS- CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4636",
            "district": "34",
            "Block": "44",
            "label": "PUMS UCHIMALAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5008",
            "district": "34",
            "Block": "44",
            "label": "PUMS PINJUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5155",
            "district": "34",
            "Block": "44",
            "label": "PUMS MANMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5192",
            "district": "34",
            "Block": "44",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5202",
            "district": "34",
            "Block": "44",
            "label": "PUMS K.ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5590",
            "district": "34",
            "Block": "44",
            "label": "PUMS VALAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5669",
            "district": "34",
            "Block": "44",
            "label": "SRI RAMAKRISHNA MATRIC HSS CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5870",
            "district": "34",
            "Block": "44",
            "label": "PUMS KATTAMADUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23371",
            "district": "34",
            "Block": "44",
            "label": "Sri Sarathi balamandir matric higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6172",
            "district": "34",
            "Block": "44",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6198",
            "district": "34",
            "Block": "44",
            "label": "PUMS THAZAIYUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6423",
            "district": "34",
            "Block": "44",
            "label": "ADWMS KAYAMBATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6507",
            "district": "34",
            "Block": "44",
            "label": "PUMS THEETHANDAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9901",
            "district": "34",
            "Block": "44",
            "label": "VIMALA IRUDHAYA MATRICULATION SCHOOL ANWARABATH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9902",
            "district": "34",
            "Block": "44",
            "label": "PUMS ARASANKANNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9903",
            "district": "34",
            "Block": "44",
            "label": "GHSS ARATTAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9904",
            "district": "34",
            "Block": "44",
            "label": "PUMS -AYOTHIYAPATTANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9905",
            "district": "34",
            "Block": "44",
            "label": "PUMS CHE. AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9906",
            "district": "34",
            "Block": "44",
            "label": "GHS CHE.NACHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9907",
            "district": "34",
            "Block": "44",
            "label": "PUMS CHE. TIRUVALLUVARNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9908",
            "district": "34",
            "Block": "44",
            "label": "GHS C.SORPANANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9909",
            "district": "34",
            "Block": "44",
            "label": "GHSS(BOYS) CHENGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9910",
            "district": "34",
            "Block": "44",
            "label": "GHSS(GIRLS) CHENGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9911",
            "district": "34",
            "Block": "44",
            "label": "SAGAYA MADHA HSS(TM) CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9912",
            "district": "34",
            "Block": "44",
            "label": "GHS (MUSLIM) CHENGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9913",
            "district": "34",
            "Block": "44",
            "label": "MAHARISHI  HR SEC SCHOOL CHENGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9914",
            "district": "34",
            "Block": "44",
            "label": "GHS CHENNASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9915",
            "district": "34",
            "Block": "44",
            "label": "GHSS  ELANGUNNI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9916",
            "district": "34",
            "Block": "44",
            "label": "GHSS KANNAKURUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9917",
            "district": "34",
            "Block": "44",
            "label": "GHS KARIMALAIPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9918",
            "district": "34",
            "Block": "44",
            "label": "GHS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9919",
            "district": "34",
            "Block": "44",
            "label": "GHS KUPPANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9920",
            "district": "34",
            "Block": "44",
            "label": "GHS MELCHENGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9921",
            "district": "34",
            "Block": "44",
            "label": "GBHSS-MELPALLIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9922",
            "district": "34",
            "Block": "44",
            "label": "GGHSS MELPALLIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9923",
            "district": "34",
            "Block": "44",
            "label": "PUMS MELPUZHUTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9924",
            "district": "34",
            "Block": "44",
            "label": "ADWHS MELPUZHUTHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9925",
            "district": "34",
            "Block": "44",
            "label": "GHS MELRAVANDAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9926",
            "district": "34",
            "Block": "44",
            "label": "PUMS MELVANAKKAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9927",
            "district": "34",
            "Block": "44",
            "label": "GHS NEEPATHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9928",
            "district": "34",
            "Block": "44",
            "label": "GHS PACHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9929",
            "district": "34",
            "Block": "44",
            "label": "GHSS PARAMANANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9930",
            "district": "34",
            "Block": "44",
            "label": "GHSS PERIYAKOLAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9931",
            "district": "34",
            "Block": "44",
            "label": "PUMS PERUMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9932",
            "district": "34",
            "Block": "44",
            "label": "GHS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9933",
            "district": "34",
            "Block": "44",
            "label": "PUMS THALAVANAICKENPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9934",
            "district": "34",
            "Block": "44",
            "label": "GHS VINNAVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21016",
            "district": "34",
            "Block": "44",
            "label": "SIDDHARTH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21451",
            "district": "34",
            "Block": "44",
            "label": "GHSS-ANDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22091",
            "district": "34",
            "Block": "44",
            "label": "GHS-ANTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22420",
            "district": "34",
            "Block": "44",
            "label": "SRISHTI HIGHER SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22421",
            "district": "34",
            "Block": "44",
            "label": "SIGARAM INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22422",
            "district": "34",
            "Block": "44",
            "label": "SHREE NIVEDHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23123",
            "district": "34",
            "Block": "44",
            "label": "NANDHEESWARA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23618",
            "district": "34",
            "Block": "44",
            "label": "A.P.J MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23618",
            "district": "34",
            "Block": "44",
            "label": "A.P.J MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23749",
            "district": "34",
            "Block": "44",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23750",
            "district": "34",
            "Block": "44",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "45": [
        {
            "key": "593",
            "district": "8",
            "Block": "45",
            "label": "PUMS, SANARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "661",
            "district": "8",
            "Block": "45",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MURUNGATHOZHUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "728",
            "district": "8",
            "Block": "45",
            "label": "SHRI GANGA MATRICULATION SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "734",
            "district": "8",
            "Block": "45",
            "label": "PUMS, CHENNIYANGIRIVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "828",
            "district": "8",
            "Block": "45",
            "label": "MOTTAIYAPPAR AMS, VALLIPURATHAMPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "896",
            "district": "8",
            "Block": "45",
            "label": "PUMS, PERUNDURAI R.S",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1426",
            "district": "8",
            "Block": "45",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MELAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1468",
            "district": "8",
            "Block": "45",
            "label": "PUMS,KAVUNDACHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1780",
            "district": "8",
            "Block": "45",
            "label": "KONGU VELLALAR MATRIC HSS,MUTHAIYAN KOVIL,CHENNIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1878",
            "district": "8",
            "Block": "45",
            "label": "VIMALA MATRI. HR SEC.SCHOOL, THOPPUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1900",
            "district": "8",
            "Block": "45",
            "label": "PUMS,CHENNIMALAI TOWN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1928",
            "district": "8",
            "Block": "45",
            "label": "PUMS, THOPPUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2005",
            "district": "8",
            "Block": "45",
            "label": "YOUNG INDIA MATRIC HSS,VEPPILI PIRIVU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2012",
            "district": "8",
            "Block": "45",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MYILADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2021",
            "district": "8",
            "Block": "45",
            "label": "PUMS,KUMARAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2065",
            "district": "8",
            "Block": "45",
            "label": "VIVEKANANTHA MHSS, V.VELLODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2330",
            "district": "8",
            "Block": "45",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EKKATTAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2451",
            "district": "8",
            "Block": "45",
            "label": "PUMS,SIRUKKALANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2573",
            "district": "8",
            "Block": "45",
            "label": "SRI RAJALAKSHMI MATRICULATION HIGHER SECONDARY SCHOOL, KARATTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12090",
            "district": "8",
            "Block": "45",
            "label": "KOMARAPPA SENGUNTHAR GIRLS HS, CHENNIMALAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12091",
            "district": "8",
            "Block": "45",
            "label": "GOVERNMENT HIGH SCHOOL,AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12092",
            "district": "8",
            "Block": "45",
            "label": "GOVERNMENT HIGH SCHOOL KAMARAJNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12093",
            "district": "8",
            "Block": "45",
            "label": "GHS, AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12094",
            "district": "8",
            "Block": "45",
            "label": "GHSS,VELLODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12095",
            "district": "8",
            "Block": "45",
            "label": "KOMARAPPA SENGUNTHAR HSS, M.PIDARIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12096",
            "district": "8",
            "Block": "45",
            "label": "GHS,MUGASIPIDARIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12097",
            "district": "8",
            "Block": "45",
            "label": "GHSS, ENGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12098",
            "district": "8",
            "Block": "45",
            "label": "SHRI MAHAA HIGHER SECONDARY SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12099",
            "district": "8",
            "Block": "45",
            "label": "SHRI GANGA HIGHER SEC SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12100",
            "district": "8",
            "Block": "45",
            "label": "THE UNIQUE ACADEMY SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12101",
            "district": "8",
            "Block": "45",
            "label": "ST.XAVIER'S HIGH SCHOOL, VADUGAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12102",
            "district": "8",
            "Block": "45",
            "label": "GHSS, PASUVAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12103",
            "district": "8",
            "Block": "45",
            "label": "GHSS, THIPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21072",
            "district": "8",
            "Block": "45",
            "label": "SHEMFORD FUTURISTIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21559",
            "district": "8",
            "Block": "45",
            "label": "GREEN GARDEN  MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21654",
            "district": "8",
            "Block": "45",
            "label": "MAHARISHI VIDYA MANDIR CBSE SCHOOL, INGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22706",
            "district": "8",
            "Block": "45",
            "label": "SRI JAI VIKAS PUBLIC CBSE SCHOOL, ULAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22707",
            "district": "8",
            "Block": "45",
            "label": "KONGU INTERNATIONAL SCHOOL(CBSE), MUTHAIAN KOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22882",
            "district": "8",
            "Block": "45",
            "label": "GOVERNMENT HIGH SCHOOL, VOIPADI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "46": [
        {
            "key": "496",
            "district": "30",
            "Block": "46",
            "label": "R.C.MS VEERAVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "697",
            "district": "30",
            "Block": "46",
            "label": "SIVANTHI MATRICULATION HSS CHERANMAHADEVI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "970",
            "district": "30",
            "Block": "46",
            "label": "ST. ANTONY'S MATRICULATION HSS, KALLIDAIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1091",
            "district": "30",
            "Block": "46",
            "label": "RC MS CHERANMAHADEVI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2047",
            "district": "30",
            "Block": "46",
            "label": "ALMIGHTY MATRICULATION  Hr. Sec. SCHOOL CHERANMAHADEVI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2317",
            "district": "30",
            "Block": "46",
            "label": "THIRU GNANA SAMBANDAR MIDDLE SCHOOL  VEERAVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2461",
            "district": "30",
            "Block": "46",
            "label": "COMMITTEE MIDDLE SCHOOL CHERANMAHADEVI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3443",
            "district": "30",
            "Block": "46",
            "label": "Panchayat  Union Middle School Kallidaikurichi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3598",
            "district": "30",
            "Block": "46",
            "label": "ST.JOHNS MIDDLE SCHOOL VEERAVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3724",
            "district": "30",
            "Block": "46",
            "label": "PUMS VEERAVANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3759",
            "district": "30",
            "Block": "46",
            "label": "PUMS MELAUPOORANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3786",
            "district": "30",
            "Block": "46",
            "label": "SRI RAMAKRISHNA MIDDLE SCHOOL CHERANMAHADEVI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3854",
            "district": "30",
            "Block": "46",
            "label": "LAKSHMIPATHI MS KALLIDAIKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3900",
            "district": "30",
            "Block": "46",
            "label": "SUN MATRICULATION SCHOOL CHERANMAHADEVI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4722",
            "district": "30",
            "Block": "46",
            "label": "HINDU MIDDLE SCHOOL KARUKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5130",
            "district": "30",
            "Block": "46",
            "label": "THE MIDDLE SCHOOL MELASEVAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5646",
            "district": "30",
            "Block": "46",
            "label": "CHITHRA MATRICULATION  SCHOOL CHERANMAHADEVI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5874",
            "district": "30",
            "Block": "46",
            "label": "CHITHRA MATRICULATION HIGHER SECONDARY SCHOOL PATHAMADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5986",
            "district": "30",
            "Block": "46",
            "label": "PUMS KOONIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6141",
            "district": "30",
            "Block": "46",
            "label": "PUMS PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19644",
            "district": "30",
            "Block": "46",
            "label": "C.M.S.E.S DAVID MEMORIAL HIGHER SECONDARY SCHOOL, KARISAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19645",
            "district": "30",
            "Block": "46",
            "label": "GGHS CHERANMAHADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19646",
            "district": "30",
            "Block": "46",
            "label": "MUSLIM COM HS CHERANMAHADEVI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19647",
            "district": "30",
            "Block": "46",
            "label": "PERIYAR GHSS, CHERANMAHADEVI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19648",
            "district": "30",
            "Block": "46",
            "label": "MAH.GANDHI MAT.S KALLIDAIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19649",
            "district": "30",
            "Block": "46",
            "label": "GHSS KALLIDAIKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19650",
            "district": "30",
            "Block": "46",
            "label": "TILAK VIDYALAYA HR SEC SCHOOL KALLIDAIKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19651",
            "district": "30",
            "Block": "46",
            "label": "BHARATHIYAR GHSS VEERAVANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19652",
            "district": "30",
            "Block": "46",
            "label": "ST.JOHNS HSS VEERAVANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19653",
            "district": "30",
            "Block": "46",
            "label": "THE RAMASESHIER HIGHER SECONDARY SCHOOL, PATTAMADAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19654",
            "district": "30",
            "Block": "46",
            "label": "GGHSS PATHAMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19655",
            "district": "30",
            "Block": "46",
            "label": "TRINITY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19656",
            "district": "30",
            "Block": "46",
            "label": "TDTA HS MELASEVAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19657",
            "district": "30",
            "Block": "46",
            "label": "PANNAI VENKATARAMIER HS GOPALASAMUDRAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19658",
            "district": "30",
            "Block": "46",
            "label": "SCHOENSTATT MATRICULATION SCHOOL, PIRANCHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19659",
            "district": "30",
            "Block": "46",
            "label": "GOVT HIGH SCHOOL POTTAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21289",
            "district": "30",
            "Block": "46",
            "label": "St.JOHN'S MATRICULATION HR.SEC.SCHOOL VEERAVANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21639",
            "district": "30",
            "Block": "46",
            "label": "SUCCESS CONVENT MATRICULATION SCHOOL, MELASEVAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22276",
            "district": "30",
            "Block": "46",
            "label": "CARMEL GARDEN MATRICULATION SCHOOL ODAIKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22277",
            "district": "30",
            "Block": "46",
            "label": "THE HOPE MATRICULATION HR.SEC.SCHOOL POOTHATHANKUDIERUPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23156",
            "district": "30",
            "Block": "46",
            "label": "SCAD INTERNATIONAL SCHOOL, CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "47": [
        {
            "key": "2588",
            "district": "34",
            "Block": "47",
            "label": "ANSALDO MATRIC MARUTHUVAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2666",
            "district": "34",
            "Block": "47",
            "label": "PUMS KARUNGALIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2694",
            "district": "34",
            "Block": "47",
            "label": "PUMS UTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2701",
            "district": "34",
            "Block": "47",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2705",
            "district": "34",
            "Block": "47",
            "label": "PUMS GENGAISOODAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2719",
            "district": "34",
            "Block": "47",
            "label": "PUMS SEMMIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2721",
            "district": "34",
            "Block": "47",
            "label": "PUMS MARUTHUVAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2766",
            "district": "34",
            "Block": "47",
            "label": "PUMS MATTAPIRAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2983",
            "district": "34",
            "Block": "47",
            "label": "DANIE MATRIC SCHOOL -CHETPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3140",
            "district": "34",
            "Block": "47",
            "label": "PUMS SANIKAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3569",
            "district": "34",
            "Block": "47",
            "label": "PUMS ALLIYALAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3570",
            "district": "34",
            "Block": "47",
            "label": "PUMS KOZHAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3571",
            "district": "34",
            "Block": "47",
            "label": "RCM MIDDLE THACHAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3577",
            "district": "34",
            "Block": "47",
            "label": "PUMS SADUPPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3605",
            "district": "34",
            "Block": "47",
            "label": "PUMS MODAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3615",
            "district": "34",
            "Block": "47",
            "label": "RCM MIDDLE PATHIYAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3664",
            "district": "34",
            "Block": "47",
            "label": "PUMS THENVILAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3690",
            "district": "34",
            "Block": "47",
            "label": "PUMS VAMBALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3773",
            "district": "34",
            "Block": "47",
            "label": "PUMS KILPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3781",
            "district": "34",
            "Block": "47",
            "label": "ST. JOSEPH MATRICULATION SUSAINAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4062",
            "district": "34",
            "Block": "47",
            "label": "PUMS ATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4598",
            "district": "34",
            "Block": "47",
            "label": "PUMS KOLAKARAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5024",
            "district": "34",
            "Block": "47",
            "label": "PUMS OGHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9750",
            "district": "34",
            "Block": "47",
            "label": "DHIVYA MATRIC HSS CHETPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9751",
            "district": "34",
            "Block": "47",
            "label": "DOMINIC SAVIO HSS (BOYS) CHETPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9752",
            "district": "34",
            "Block": "47",
            "label": "GHSS PAZHAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9753",
            "district": "34",
            "Block": "47",
            "label": "ST ANNES HSS (GIRLS) CHETPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9754",
            "district": "34",
            "Block": "47",
            "label": "GHS ETTIVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9755",
            "district": "34",
            "Block": "47",
            "label": "GHS GUDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9756",
            "district": "34",
            "Block": "47",
            "label": "GHS INDIRAVANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9757",
            "district": "34",
            "Block": "47",
            "label": "GHS KILKARIKATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9758",
            "district": "34",
            "Block": "47",
            "label": "GHSS MANDAKOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9759",
            "district": "34",
            "Block": "47",
            "label": "GHSS MANSURABAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9760",
            "district": "34",
            "Block": "47",
            "label": "GHSS NAMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9761",
            "district": "34",
            "Block": "47",
            "label": "GHS PELASUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9762",
            "district": "34",
            "Block": "47",
            "label": "GHSS PERNAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9763",
            "district": "34",
            "Block": "47",
            "label": "GHS RANDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9764",
            "district": "34",
            "Block": "47",
            "label": "GHSS SANARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9765",
            "district": "34",
            "Block": "47",
            "label": "GHSS SEVARAPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9766",
            "district": "34",
            "Block": "47",
            "label": "NIRMALA MADHA HSS (GIRLS) SUSAINAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9767",
            "district": "34",
            "Block": "47",
            "label": "ST JOSEPHS HSS (BOYS) SUSAINAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9768",
            "district": "34",
            "Block": "47",
            "label": "SRI BHAGAVAN NEMINATHA HR SEC SCHOOL  TIRUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9769",
            "district": "34",
            "Block": "47",
            "label": "GHS ULAGAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9770",
            "district": "34",
            "Block": "47",
            "label": "GHSS VADAMATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21583",
            "district": "34",
            "Block": "47",
            "label": "SRI SANTHA VIDYALAYA MATRIC HSS-GENGAISOODAMANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21675",
            "district": "34",
            "Block": "47",
            "label": "ANNAI RENUGAMBAL MATRIC HR. SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21925",
            "district": "34",
            "Block": "47",
            "label": "DHIVYA HIGH SCHOOL-CHETPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21954",
            "district": "34",
            "Block": "47",
            "label": "ST.AMALARAKKINI HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22089",
            "district": "34",
            "Block": "47",
            "label": "CHINMAYA VIDHYALAYA MATRIC HR SEC SCHOOL MANDAKOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22414",
            "district": "34",
            "Block": "47",
            "label": "WIN PARK VIDHYALAYA MATRICULATION  SCHOOL, CHETPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23058",
            "district": "34",
            "Block": "47",
            "label": "SRI BHARATHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23751",
            "district": "34",
            "Block": "47",
            "label": "PUMS MARUTHUVAMBADI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23752",
            "district": "34",
            "Block": "47",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "48": [
        {
            "key": "1933",
            "district": "34",
            "Block": "48",
            "label": "OXFORD MATRIC SCHOOL CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2578",
            "district": "34",
            "Block": "48",
            "label": "PUMS SIRUVELIYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2644",
            "district": "34",
            "Block": "48",
            "label": "PUMS-KILPUDUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2717",
            "district": "34",
            "Block": "48",
            "label": "MMS-BARITHIPURAM (WEST)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2751",
            "district": "34",
            "Block": "48",
            "label": "PUMS THOZHUPPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2868",
            "district": "34",
            "Block": "48",
            "label": "PUMS THANDARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3400",
            "district": "34",
            "Block": "48",
            "label": "VIVEGA INTERNATIONAL MATRIC SCHOOL CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3410",
            "district": "34",
            "Block": "48",
            "label": "PUMS RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3463",
            "district": "34",
            "Block": "48",
            "label": "WISDOM VIDHYASHRAM INTERNATIONAL SCHOOL  CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3581",
            "district": "34",
            "Block": "48",
            "label": "ST JOSEPH MATRIC KODANAGAR CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3628",
            "district": "34",
            "Block": "48",
            "label": "MMS-KODANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3703",
            "district": "34",
            "Block": "48",
            "label": "PUMS SENGAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3713",
            "district": "34",
            "Block": "48",
            "label": "MMS-THIRUVOTHUR (MUSLIM)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3753",
            "district": "34",
            "Block": "48",
            "label": "PUMS-MUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4425",
            "district": "34",
            "Block": "48",
            "label": "PUMS-PAINKINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4497",
            "district": "34",
            "Block": "48",
            "label": "PUMS VADATHANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4529",
            "district": "34",
            "Block": "48",
            "label": "PUMS MELNAGARAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6161",
            "district": "34",
            "Block": "48",
            "label": "DR VGN MATRIC HIGH SCHOOL CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6286",
            "district": "34",
            "Block": "48",
            "label": "INDO AMERICAN  MATRIC HR.SEC.SCHOOL CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9556",
            "district": "34",
            "Block": "48",
            "label": "GHS HOUSING BOARD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9557",
            "district": "34",
            "Block": "48",
            "label": "GHS KAZHAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9558",
            "district": "34",
            "Block": "48",
            "label": "GHSS KORUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9559",
            "district": "34",
            "Block": "48",
            "label": "GHS KORUKKATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9560",
            "district": "34",
            "Block": "48",
            "label": "GHS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9561",
            "district": "34",
            "Block": "48",
            "label": "GHS MELSEESHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9562",
            "district": "34",
            "Block": "48",
            "label": "GHS MELMATTAI VINNAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9563",
            "district": "34",
            "Block": "48",
            "label": "KNNC GHSS MUNUGAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9564",
            "district": "34",
            "Block": "48",
            "label": "GHSS NEDUMBIRAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9565",
            "district": "34",
            "Block": "48",
            "label": "GHSS- PALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9566",
            "district": "34",
            "Block": "48",
            "label": "GHS PAPPANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9567",
            "district": "34",
            "Block": "48",
            "label": "ADWHS PULIYARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9568",
            "district": "34",
            "Block": "48",
            "label": "GHS THIRUMANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9569",
            "district": "34",
            "Block": "48",
            "label": "GHS VADUGAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9570",
            "district": "34",
            "Block": "48",
            "label": "GHSS-VAZHKUDAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9571",
            "district": "34",
            "Block": "48",
            "label": "VICTORY MATRIC, VINNAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9572",
            "district": "34",
            "Block": "48",
            "label": "GHS THIRUVOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9573",
            "district": "34",
            "Block": "48",
            "label": "GHSS(BOYS) CHEYYAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9574",
            "district": "34",
            "Block": "48",
            "label": "GMHSS(GIRLS)- CHEYYAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9575",
            "district": "34",
            "Block": "48",
            "label": "MHS GIRUDHARANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9576",
            "district": "34",
            "Block": "48",
            "label": "ST PAULS HIGH SCHOOL CHEYYAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9577",
            "district": "34",
            "Block": "48",
            "label": "AIMS CBSE SENIOR SECONDARY SCHOOL, CHEYYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20957",
            "district": "34",
            "Block": "48",
            "label": "JAMBU MAHARISHI VANNIYAR MATRICULATION HIGHER SECONDARY  SCHOOL PALANTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20958",
            "district": "34",
            "Block": "48",
            "label": "VISWA BARATHI MATRICULATION",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21448",
            "district": "34",
            "Block": "48",
            "label": "GHS PARASUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22082",
            "district": "34",
            "Block": "48",
            "label": "DHRONA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22083",
            "district": "34",
            "Block": "48",
            "label": "VIRUTCHAM INTER PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22412",
            "district": "34",
            "Block": "48",
            "label": "GHS-ENATHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23121",
            "district": "34",
            "Block": "48",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23753",
            "district": "34",
            "Block": "48",
            "label": "GHS KUNNATHUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23754",
            "district": "34",
            "Block": "48",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "49": [
        {
            "key": "764",
            "district": "27",
            "Block": "49",
            "label": "MPL MS, MAIN STREET - CHINNAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "880",
            "district": "27",
            "Block": "49",
            "label": "GAYATHRI MATRIC HSS - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "927",
            "district": "27",
            "Block": "49",
            "label": "NALLI MATRIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1415",
            "district": "27",
            "Block": "49",
            "label": "PUMS - MUTHULAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1433",
            "district": "27",
            "Block": "49",
            "label": "PUMS - AYYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1487",
            "district": "27",
            "Block": "49",
            "label": "PUMS - THENPALANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1627",
            "district": "27",
            "Block": "49",
            "label": "GOODSAM MATRIC HSS - SUKKANGALPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1922",
            "district": "27",
            "Block": "49",
            "label": "ST. FRANCIS ASSISI MATRIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2143",
            "district": "27",
            "Block": "49",
            "label": "MPL MS, EAST STREET -  CHINNAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2257",
            "district": "27",
            "Block": "49",
            "label": "PUMS - T.PUDHUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2303",
            "district": "27",
            "Block": "49",
            "label": "THAI MATRIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2447",
            "district": "27",
            "Block": "49",
            "label": "MPL MS, KARUNKATTANKULAM - CHINNAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2757",
            "district": "27",
            "Block": "49",
            "label": "ECN MATRIC SCHOOL - ODAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2850",
            "district": "27",
            "Block": "49",
            "label": "C.N.M.S. SIVAKAMI MATRIC HSS - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2911",
            "district": "27",
            "Block": "49",
            "label": "PUMS - THIMMINAYAKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2982",
            "district": "27",
            "Block": "49",
            "label": "PUMS - CHINNAPOTTIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3102",
            "district": "27",
            "Block": "49",
            "label": "V.U.ANNA MS - CHINNAMANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3215",
            "district": "27",
            "Block": "49",
            "label": "PETCHIYAMMAL.MS - SANKARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3599",
            "district": "27",
            "Block": "49",
            "label": "R.C.MS - CHINNAMANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4048",
            "district": "27",
            "Block": "49",
            "label": "NADARKAL URAVINMURAI MS - SUKKANGALPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4064",
            "district": "27",
            "Block": "49",
            "label": "MAYAR RAM MATRIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5303",
            "district": "27",
            "Block": "49",
            "label": "MUSLIM MS - CHINNAMANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5346",
            "district": "27",
            "Block": "49",
            "label": "MRV MS - MARKAYANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6215",
            "district": "27",
            "Block": "49",
            "label": "PUMS - KAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6531",
            "district": "27",
            "Block": "49",
            "label": "PUMS - KARICHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18529",
            "district": "27",
            "Block": "49",
            "label": "TPVVU GURUKKAL MS -  CHINNAMANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18530",
            "district": "27",
            "Block": "49",
            "label": "SKA HSS - CHINNAMANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18531",
            "district": "27",
            "Block": "49",
            "label": "MUNICIPAL  HS, CHINNAMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18532",
            "district": "27",
            "Block": "49",
            "label": "KVA GIRLS HSS - CHINNAMANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18533",
            "district": "27",
            "Block": "49",
            "label": "GOVT HSS - ERASAKKANAYAKANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18534",
            "district": "27",
            "Block": "49",
            "label": "GANDHIJI VIDHYA BEEDAM HSS- KANNISERVAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18535",
            "district": "27",
            "Block": "49",
            "label": "PACHAIYAPPA HINDU NADAR HSS - KAMATCHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18536",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS -SEEPALAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18537",
            "district": "27",
            "Block": "49",
            "label": "MARIAPPA MEMORIAL MATRIC SCHOOL- KAMATCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18538",
            "district": "27",
            "Block": "49",
            "label": "GOVT HSS -ODAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18539",
            "district": "27",
            "Block": "49",
            "label": "GOVT KALLAR HSS - VELLAIAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18540",
            "district": "27",
            "Block": "49",
            "label": "GOVT KALLAR HSS- MARKEYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18541",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS- PULIKUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18542",
            "district": "27",
            "Block": "49",
            "label": "GOVT HSS- KUTCHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18543",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS- SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18544",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS - RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18545",
            "district": "27",
            "Block": "49",
            "label": "GOVT KALLAR HSS - MUTHAIYANCHETTYPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21747",
            "district": "27",
            "Block": "49",
            "label": "GAYATHRI GIRLS MATRIC HSS - CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22613",
            "district": "27",
            "Block": "49",
            "label": "SHRI VIKASA VIDYALAYA   PUBLIC SCHOOL (CBSE)- CHINNAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22924",
            "district": "27",
            "Block": "49",
            "label": "GOVT HS , APPIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23569",
            "district": "27",
            "Block": "49",
            "label": "GOVERNMENT KALLAR RESIDENTIAL SCHOOL (CO ED), VELLAYAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23569",
            "district": "27",
            "Block": "49",
            "label": "GOVERNMENT KALLAR RESIDENTIAL SCHOOL (CO ED), VELLAYAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23755",
            "district": "27",
            "Block": "49",
            "label": "PUMS - KAMARAJAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23756",
            "district": "27",
            "Block": "49",
            "label": "SHREE VIKASAA PUBLIC SCHOOL - CHINNAMANUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "50": [
        {
            "key": "4385",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL V MAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5517",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMMAKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5737",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6024",
            "district": "9",
            "Block": "50",
            "label": "JEEVAN MATRICULATION HIGHER SECONDARY SCHOOL  AMMAIYAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6244",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6321",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMBAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6432",
            "district": "9",
            "Block": "50",
            "label": "BHARATHIDHASAN MATRIC SCHOOL ELIYATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6730",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VETTIPERUMALAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10770",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10771",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL THENGIANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10772",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL PAITHANTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10773",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THOTTIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10774",
            "district": "9",
            "Block": "50",
            "label": "AIDED HIGHER SECONDARY SCHOOL MELNARIYAPPANUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10775",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL KALLANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10776",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANDIANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10777",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL ELAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23386",
            "district": "9",
            "Block": "50",
            "label": "Denish mission middle school",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "10778",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL AMMAYAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10779",
            "district": "9",
            "Block": "50",
            "label": "KURINJIE HIGHER SECONDARY SCHOOL. AMMAIYAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10780",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NAINARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10781",
            "district": "9",
            "Block": "50",
            "label": "ST.PAUL HIGH SCHOOL NAINARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10782",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL CHINNASALEM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10783",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL CHINNASALEM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10784",
            "district": "9",
            "Block": "50",
            "label": "BHARATHI BALAMANDIR MATRIC HIGHER SECONDARY SCHOOL, CHINNASALEM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10785",
            "district": "9",
            "Block": "50",
            "label": "AIDED MIDDLE SCHOOL CHINNASALEM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10786",
            "district": "9",
            "Block": "50",
            "label": "AIDED HIGHER SECONDARY SCHOOL CHINNASALEM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10787",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEELKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10788",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL  KARUNTHALAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10789",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL V ALAMBALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10790",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  HIGHER SECONDARY SCHOOL THAGAMTHERTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10791",
            "district": "9",
            "Block": "50",
            "label": "WELLDONE MATRIC HR.SEC SCHOOL,THAGAMTHEERTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10792",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGH SCHOOL  PAKKAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10793",
            "district": "9",
            "Block": "50",
            "label": "ST.ANN'S HIGH SCHOOL  THENSIRUVALLUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10794",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THOTTAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10795",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  HIGHER SECONDARY SCHOOL GUGAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10796",
            "district": "9",
            "Block": "50",
            "label": "SAKTHI MATRIC HIGHER SECONDARY SCHOOL KANIYAMOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10797",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PETHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10798",
            "district": "9",
            "Block": "50",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL ULAGANKATHAN",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10799",
            "district": "9",
            "Block": "50",
            "label": "BHARATHI HIGH SCHOOL BANGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10800",
            "district": "9",
            "Block": "50",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL  NAMASIVAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10801",
            "district": "9",
            "Block": "50",
            "label": "SRI VIVEKANANDA HIGHER SECONDARY SCHOOL  EARVAIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10802",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  HIGHER SECONDARY SCHOOL KUTHIRAICHANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10803",
            "district": "9",
            "Block": "50",
            "label": "ST. MARY'S HIGH SCHOOL KARANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10804",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MATTIKAIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10805",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  NAGAKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10806",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENKATAMMAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10807",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL VADAKKANANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10808",
            "district": "9",
            "Block": "50",
            "label": "BHARATHI MATRIC HIGHER SECONDARY SCHOOL VADAKKANANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10809",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL GOMUGI DAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23408",
            "district": "9",
            "Block": "50",
            "label": "LITTLE FLOWER HIGHER SECONDARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10810",
            "district": "9",
            "Block": "50",
            "label": "VANMATHI MATRIC HIGHER SECONDARY SCHOOL  VADAKANNANTHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10811",
            "district": "9",
            "Block": "50",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KACHIRAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10812",
            "district": "9",
            "Block": "50",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  KACHIRAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10813",
            "district": "9",
            "Block": "50",
            "label": "AIDED MIDDLE SCHOOL METTUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10814",
            "district": "9",
            "Block": "50",
            "label": "DANISH MISSION HIGHER SECONDARY SCHOOL KARIYALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20963",
            "district": "9",
            "Block": "50",
            "label": "ECR INTERNATIONAL   SCHOOL KANIYAMOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21018",
            "district": "9",
            "Block": "50",
            "label": "MAHA KAVI MATRIC HIGHER SECONDARY SCHOOL , RAYAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21687",
            "district": "9",
            "Block": "50",
            "label": "SEVA MATRICULTION MIDDLE SCHOOL PULUVAPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22104",
            "district": "9",
            "Block": "50",
            "label": "BALAN BALAMANDHIR MATRIC HIGH SCHOOL NAINARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23757",
            "district": "9",
            "Block": "50",
            "label": "AIDED MIDDLE SCHOOL KARANUR",
            "Sch_type": "Fully AidedMiddle School"
        }
    ],
    "51": [
        {
            "key": "5025",
            "district": "2",
            "Block": "51",
            "label": "St.JOSEPH MATRIC,PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5741",
            "district": "2",
            "Block": "51",
            "label": "GVR MATRIC HR. SEC. SCHOOL - KADAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5743",
            "district": "2",
            "Block": "51",
            "label": "KVS MATRICULATION HIGHER SECONDARY SCHOOL, KADAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8256",
            "district": "2",
            "Block": "51",
            "label": "ADHIPARASAKTHI MHSS-MELMARUVATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8257",
            "district": "2",
            "Block": "51",
            "label": "G B PUBLIC SCHOOL - MELMARUVATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8258",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL, SOTHUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8259",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SOTHUPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8260",
            "district": "2",
            "Block": "51",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL ,PORAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8261",
            "district": "2",
            "Block": "51",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL, KILMARUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8262",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL - IRUMBULI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8263",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - POLAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8264",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- POLAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8265",
            "district": "2",
            "Block": "51",
            "label": "BHARATHI MATRICULATION HR SEC SCHOOL SARAVAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8266",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8267",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - PERUKKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8268",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - PARUKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8269",
            "district": "2",
            "Block": "51",
            "label": "GOVT HIGH SCHOOL, INDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8270",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - ARKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8271",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - 23 A KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8272",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - PULIYANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8273",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - NERKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8274",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL- SIRUMAILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8275",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HR SEC SCHOOL, KAYAPAKKAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8276",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - NUGUMBAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8277",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL- PONTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8278",
            "district": "2",
            "Block": "51",
            "label": "SARVASEVA HS PERIYAKALAKADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8279",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL - PUTHIRANKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8280",
            "district": "2",
            "Block": "51",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL- ESUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8281",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8282",
            "district": "2",
            "Block": "51",
            "label": "GOVT HIGH SCHOOL -  ANDARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8283",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- VANNIANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8284",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- PUTHUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8285",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- CHUNAMBEDU - (M)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8286",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- VILLIPAKKAM(M)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8287",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- CHUNAMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8288",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - VEDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8289",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL - KADUKALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8290",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- NAINARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8291",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGH SCHOOL - MUDHALIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8292",
            "district": "2",
            "Block": "51",
            "label": "SRI P K GOVT BOYS HR SEC SCHOOL-KADAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8293",
            "district": "2",
            "Block": "51",
            "label": "ST. MARY MATRIC HS SCHOOL, KADAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8294",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - KADAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8295",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- ALAMBARAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8296",
            "district": "2",
            "Block": "51",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VENNANGUPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8297",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8298",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL SEMPULIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8299",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL - GENGADEVANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8300",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- PANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8301",
            "district": "2",
            "Block": "51",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL- VILAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21054",
            "district": "2",
            "Block": "51",
            "label": "VEDHA VIDHYASHRAM CBSE SCHOOL-MANAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22042",
            "district": "2",
            "Block": "51",
            "label": "ASSEFA VIDHYALAYA MATRIC. HR. SEC.SCHOOL- POORIYAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "52": [
        {
            "key": "468",
            "district": "4",
            "Block": "52",
            "label": "RASAKONDALAR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "639",
            "district": "4",
            "Block": "52",
            "label": "CARMEL GARDEN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "743",
            "district": "4",
            "Block": "52",
            "label": "BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "765",
            "district": "4",
            "Block": "52",
            "label": "ALVERNIA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "852",
            "district": "4",
            "Block": "52",
            "label": "SUGUNA RIP V MATRICULATION  HIGHER.SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1211",
            "district": "4",
            "Block": "52",
            "label": "SREE NARAYANA MISSION MHS SAIBABA COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23351",
            "district": "4",
            "Block": "52",
            "label": "Dharmamurthi sri SRPPS Vidhyalaya mat. School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1239",
            "district": "4",
            "Block": "52",
            "label": "ST. THERESA GIRLS AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1251",
            "district": "4",
            "Block": "52",
            "label": "V.C.V SHISHU VIDYODAYA MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1336",
            "district": "4",
            "Block": "52",
            "label": "CMS MNG STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1412",
            "district": "4",
            "Block": "52",
            "label": "ST. JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL,COIMBATORE-641018",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1494",
            "district": "4",
            "Block": "52",
            "label": "KAMARAJAR MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1651",
            "district": "4",
            "Block": "52",
            "label": "ATHAR JAMATH MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23691",
            "district": "4",
            "Block": "52",
            "label": "velammal bodhi campus",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1859",
            "district": "4",
            "Block": "52",
            "label": "ST MICHAEL'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1911",
            "district": "4",
            "Block": "52",
            "label": "SRI VISWESWARA VIDHYALYA  MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2169",
            "district": "4",
            "Block": "52",
            "label": "CHINMAYA VIDYALA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2233",
            "district": "4",
            "Block": "52",
            "label": "HOLY FAMILY CONVENT MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2534",
            "district": "4",
            "Block": "52",
            "label": "VIVEKALAYA MATRICULATION  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2786",
            "district": "4",
            "Block": "52",
            "label": "MANBAUL ULOOM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2994",
            "district": "4",
            "Block": "52",
            "label": "CMS TATABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3040",
            "district": "4",
            "Block": "52",
            "label": "TRINITY MATRIC H S S",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3330",
            "district": "4",
            "Block": "52",
            "label": "BHARATIYA VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL, R.S.PURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3566",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION MIDDLE SCHOOL KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3919",
            "district": "4",
            "Block": "52",
            "label": "AL-AZHAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3928",
            "district": "4",
            "Block": "52",
            "label": "RAM NAGAR SUBURBAN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3934",
            "district": "4",
            "Block": "52",
            "label": "PANDIYAN MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4525",
            "district": "4",
            "Block": "52",
            "label": "T E LC MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4765",
            "district": "4",
            "Block": "52",
            "label": "P R Sidha Naidu Memorial Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4928",
            "district": "4",
            "Block": "52",
            "label": "SHRI NEHRU VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5001",
            "district": "4",
            "Block": "52",
            "label": "C M S WEDDERBURN PET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5260",
            "district": "4",
            "Block": "52",
            "label": "ST. FRANCIS ANGLO INDIAN GIRLS HIGH SCHOOL (AIDED)",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "5896",
            "district": "4",
            "Block": "52",
            "label": "I.C.C. GIRLS AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5962",
            "district": "4",
            "Block": "52",
            "label": "GOVERNMENT MIDDLE SCHOOL, THIRUMAL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6131",
            "district": "4",
            "Block": "52",
            "label": "ALG MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6252",
            "district": "4",
            "Block": "52",
            "label": "ST. AGNES GIRLS AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6584",
            "district": "4",
            "Block": "52",
            "label": "NIRMALA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12661",
            "district": "4",
            "Block": "52",
            "label": "LISIEUX MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12662",
            "district": "4",
            "Block": "52",
            "label": "T.A. RAMALINGAM CHETTIAR AIDED HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12663",
            "district": "4",
            "Block": "52",
            "label": "SRI AVINASHILINGAM GIRLS HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12664",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGH SCHOOL RAMALINGAM COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12665",
            "district": "4",
            "Block": "52",
            "label": "ANGAPPA EDUCATIONAL TRUST SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12666",
            "district": "4",
            "Block": "52",
            "label": "BISHOP OUBAGARAM PARTIALLY AIDED HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12667",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGHER SEC. SCHOOL R.S. PURAM WEST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12668",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL R.S. PURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12669",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGH SCHOOL FOR DEAF,  R.S.PURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12670",
            "district": "4",
            "Block": "52",
            "label": "ASHIRWAAD SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12671",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION SRP AMMANIAMMAL GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12672",
            "district": "4",
            "Block": "52",
            "label": "COIMBATORE SEVANILAYAM GIRLS AIDED HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12673",
            "district": "4",
            "Block": "52",
            "label": "DEVANGA AIDED HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12674",
            "district": "4",
            "Block": "52",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12675",
            "district": "4",
            "Block": "52",
            "label": "CMS PAPPANAICKEN PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12676",
            "district": "4",
            "Block": "52",
            "label": "MANI AIDED HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12677",
            "district": "4",
            "Block": "52",
            "label": "Y.W.C.A. MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12678",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGH SCHOOL PULIAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12679",
            "district": "4",
            "Block": "52",
            "label": "ST ANTONYS HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12680",
            "district": "4",
            "Block": "52",
            "label": "AIR FORCE SCHOOL,COIMBATORE",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12681",
            "district": "4",
            "Block": "52",
            "label": "VIDHYA NIKETAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12682",
            "district": "4",
            "Block": "52",
            "label": "KRISHNASAMY NAGAR GANGA NAGAR MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12683",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGHER SEC SCHOOL RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12684",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HR SEC SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12685",
            "district": "4",
            "Block": "52",
            "label": "ST MARY'S GIRLS HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12686",
            "district": "4",
            "Block": "52",
            "label": "C S I MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12687",
            "district": "4",
            "Block": "52",
            "label": "R.K.SREERANGAMMAL KALVI NILAYAM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12688",
            "district": "4",
            "Block": "52",
            "label": "STANES ANGLO INDIAN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12689",
            "district": "4",
            "Block": "52",
            "label": "C.S.I GIRLS AIDED HIGHER SECONDARY SCHOOL AVINASHI ROAD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12690",
            "district": "4",
            "Block": "52",
            "label": "G.D.MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12691",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGH SCHOOL ANUPPARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12692",
            "district": "4",
            "Block": "52",
            "label": "THE SUBURBAN HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12693",
            "district": "4",
            "Block": "52",
            "label": "CGHSS RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12694",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGH SCHOOL SIDHAPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12695",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL SIDHAPUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12696",
            "district": "4",
            "Block": "52",
            "label": "ST.MARY'S HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12697",
            "district": "4",
            "Block": "52",
            "label": "BRINDISI MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12698",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL, NORTH COIMBATORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12699",
            "district": "4",
            "Block": "52",
            "label": "SHREE BALDEVDAS KIKANI VIDYAMANDIR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12700",
            "district": "4",
            "Block": "52",
            "label": "SHREE BALDEVDAS KIKANI VIDYAMANDIR AIDED HIGHER SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12701",
            "district": "4",
            "Block": "52",
            "label": "KIKANI VIDHYA MANDIR SENIOR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12702",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGHER SECONDARY SCHOOL OPPANAKARA STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12703",
            "district": "4",
            "Block": "52",
            "label": "PRESENTATION CONVENT GIRLS HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12704",
            "district": "4",
            "Block": "52",
            "label": "CSI BOYS HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12705",
            "district": "4",
            "Block": "52",
            "label": "CITY CORPORATION HIGHER SECONDARY SCHOOL, V.H. ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12706",
            "district": "4",
            "Block": "52",
            "label": "ST.MICHAEL'S HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12707",
            "district": "4",
            "Block": "52",
            "label": "MANBAUL ULOOM Hr SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12708",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION GIRLS HIGH SCOOL SELVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12709",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL SELVAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12710",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION HIGHER SECONDARY SCHOOL OKKILIYAR COLONY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12711",
            "district": "4",
            "Block": "52",
            "label": "CORPORATION MIDDLE SCHOOL  BB STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12712",
            "district": "4",
            "Block": "52",
            "label": "MARANNA GOWDER HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12713",
            "district": "4",
            "Block": "52",
            "label": "CCMA GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, RAJA STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12714",
            "district": "4",
            "Block": "52",
            "label": "MARANNA GOWDER MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12715",
            "district": "4",
            "Block": "52",
            "label": "VEERASAMY MUDALIAR AIDED HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12716",
            "district": "4",
            "Block": "52",
            "label": "ATHAR JAMATH AIDED HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21354",
            "district": "4",
            "Block": "52",
            "label": "STANES ICSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21883",
            "district": "4",
            "Block": "52",
            "label": "SUGUNA INTERNATIONAL  CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22134",
            "district": "4",
            "Block": "52",
            "label": "BHARATHI REHABILITATION CENTRE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22135",
            "district": "4",
            "Block": "52",
            "label": "STANES SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22136",
            "district": "4",
            "Block": "52",
            "label": "JEYAM SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22803",
            "district": "4",
            "Block": "52",
            "label": "PRAKRIYA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23676",
            "district": "4",
            "Block": "52",
            "label": "ADWAITH THOUGHT ACADEMY, Coimbatore",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23132",
            "district": "4",
            "Block": "52",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23152",
            "district": "4",
            "Block": "52",
            "label": "LISIEUX CMI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23160",
            "district": "4",
            "Block": "52",
            "label": "NAVY CHILDREN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23376",
            "district": "4",
            "Block": "52",
            "label": "SRPPS Vidhyalaya Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23163",
            "district": "4",
            "Block": "52",
            "label": "SNV GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23184",
            "district": "4",
            "Block": "52",
            "label": "BHARATHI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23461",
            "district": "4",
            "Block": "52",
            "label": "Government Model School, Coimbatore",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23461",
            "district": "4",
            "Block": "52",
            "label": "Government Model School, Coimbatore",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23758",
            "district": "4",
            "Block": "52",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23759",
            "district": "4",
            "Block": "52",
            "label": "COIMBATORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23760",
            "district": "4",
            "Block": "52",
            "label": "CARMEL GARDEN PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "53": [
        {
            "key": "3945",
            "district": "26",
            "Block": "53",
            "label": "NILGIRI MATRICULATION SCHOOL, RKS, YELLANALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4028",
            "district": "26",
            "Block": "53",
            "label": "TIMBRE TOPS MATRICULATION SCHOOL, COONOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4284",
            "district": "26",
            "Block": "53",
            "label": "MMB MATRIC HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4944",
            "district": "26",
            "Block": "53",
            "label": "HOLY ANGELS MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6101",
            "district": "26",
            "Block": "53",
            "label": "CHRIST THE KING METRICULATION SCHOOL, KOLAKOMABAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12482",
            "district": "26",
            "Block": "53",
            "label": "PUMS BETTATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12483",
            "district": "26",
            "Block": "53",
            "label": "PUMS ELITHORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12484",
            "district": "26",
            "Block": "53",
            "label": "GHSS YEDAPPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12485",
            "district": "26",
            "Block": "53",
            "label": "PUMS HALAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12486",
            "district": "26",
            "Block": "53",
            "label": "PUMS THUTHURMATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12487",
            "district": "26",
            "Block": "53",
            "label": "PUMS MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12488",
            "district": "26",
            "Block": "53",
            "label": "PUMS ARIHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12489",
            "district": "26",
            "Block": "53",
            "label": "CSI MIDDLE SCHOOL, KOLACOMBAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12490",
            "district": "26",
            "Block": "53",
            "label": "GHS THUTHURMATTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12491",
            "district": "26",
            "Block": "53",
            "label": "GHS MANJACOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12492",
            "district": "26",
            "Block": "53",
            "label": "GHSS MELUR HOSATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12493",
            "district": "26",
            "Block": "53",
            "label": "GOVERNMENT (GIRLS) HIGH SCHOOL, HUBBATHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12494",
            "district": "26",
            "Block": "53",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL HUBBATHALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12495",
            "district": "26",
            "Block": "53",
            "label": "SRI KALAIMAGAL MATRICULATION SCHOOL, SOGATHTHORAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12496",
            "district": "26",
            "Block": "53",
            "label": "REACH MATRIC HSS COONOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12497",
            "district": "26",
            "Block": "53",
            "label": "MUNICIPAL MIDDLE SCHOOL BANDISHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12498",
            "district": "26",
            "Block": "53",
            "label": "MMS ATTADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12499",
            "district": "26",
            "Block": "53",
            "label": "MMS GANDHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12500",
            "district": "26",
            "Block": "53",
            "label": "CSI MS COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12501",
            "district": "26",
            "Block": "53",
            "label": "ST.JOSEPHS MATRICULATION SCHOOL, ALWARPET, COONOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12502",
            "district": "26",
            "Block": "53",
            "label": "SRI SARGURU TRIBAL HS, COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12503",
            "district": "26",
            "Block": "53",
            "label": "MOUNTAIN HOME HSS COONOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12504",
            "district": "26",
            "Block": "53",
            "label": "ARIGNAR ANNA GHSS,COONOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12505",
            "district": "26",
            "Block": "53",
            "label": "ST.ANTONY'S HR. SEC. SCHOOL, COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12506",
            "district": "26",
            "Block": "53",
            "label": "ST.MARYS (G) HSS COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12507",
            "district": "26",
            "Block": "53",
            "label": "SRI SHANTHI VIJAI GIRLS HR SEC SCHOOL COONOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12508",
            "district": "26",
            "Block": "53",
            "label": "STANES ANGLO INDIAN HSS, COONOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12509",
            "district": "26",
            "Block": "53",
            "label": "ST JOSEPHS BOYS ANGLO INDIAN  HSS, COONOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12510",
            "district": "26",
            "Block": "53",
            "label": "ST JOSEPHS CONVENT ANGLO INDIAN GIRLS HSS, COONOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12511",
            "district": "26",
            "Block": "53",
            "label": "PUMS GURRENCY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12512",
            "district": "26",
            "Block": "53",
            "label": "BRINDAVAN PUBLIC HSS WELLINGTON",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12513",
            "district": "26",
            "Block": "53",
            "label": "ST.JOSEPH AMS WELLINGTON COONOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12514",
            "district": "26",
            "Block": "53",
            "label": "CANTONMENT BOARD HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12515",
            "district": "26",
            "Block": "53",
            "label": "ST. JOSEPHS BOYS HIGH SCHOOL, WELLINGTON BARRACKS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12516",
            "district": "26",
            "Block": "53",
            "label": "HOLY INNOCENTS HS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12517",
            "district": "26",
            "Block": "53",
            "label": "KENDRIYA VIDYALAYA HSS WELLINGTON",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12518",
            "district": "26",
            "Block": "53",
            "label": "ST. ANN'S GIRLS HR. SEC. SCHOOL, ARUVANKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12519",
            "district": "26",
            "Block": "53",
            "label": "MRS. BULLMORE'S MATRICULATION SCHOOL, MOUNT PLEASANT,  COONOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12520",
            "district": "26",
            "Block": "53",
            "label": "ARMY HSS WELLINGTON",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12521",
            "district": "26",
            "Block": "53",
            "label": "CANTONMENT BOARD SECONDARY SCHOOL, CHINNA VANDISHOLAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12522",
            "district": "26",
            "Block": "53",
            "label": "PUMS SOGATHORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12523",
            "district": "26",
            "Block": "53",
            "label": "PUMS DENALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12524",
            "district": "26",
            "Block": "53",
            "label": "GHS YELLANALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12525",
            "district": "26",
            "Block": "53",
            "label": "ST.MICHAEL'S HS, SHANTHOOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12526",
            "district": "26",
            "Block": "53",
            "label": "LAIDLAW MEM HSS KETTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12527",
            "district": "26",
            "Block": "53",
            "label": "CSI HIGHER SECONDARY SCHOOL, KETTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12528",
            "district": "26",
            "Block": "53",
            "label": "THE LAWRENCE SCHOOL, LOVEDALE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12529",
            "district": "26",
            "Block": "53",
            "label": "GHS HULICAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12530",
            "district": "26",
            "Block": "53",
            "label": "SIRUMALAR HIGH SCHOOL, SELAS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12531",
            "district": "26",
            "Block": "53",
            "label": "CSI HIGH SCHOOL, NONSUCH",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12532",
            "district": "26",
            "Block": "53",
            "label": "PUMS KODERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12533",
            "district": "26",
            "Block": "53",
            "label": "PUMS THAMBATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12534",
            "district": "26",
            "Block": "53",
            "label": "PUMS KOLLIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12535",
            "district": "26",
            "Block": "53",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ADHIGARATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12536",
            "district": "26",
            "Block": "53",
            "label": "N S IYAH MEMORIAL HSS-KATARY DAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12537",
            "district": "26",
            "Block": "53",
            "label": "SRI KNM MATRICULATION SCHOOL, KETTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12538",
            "district": "26",
            "Block": "53",
            "label": "PUMS JEGATHALA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12539",
            "district": "26",
            "Block": "53",
            "label": "KENDRIYA VIDYALAYA HSS ARUVANKADU",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12540",
            "district": "26",
            "Block": "53",
            "label": "DEFENCE EMPLOYEES MATRICULATION SCHOOL, ARUVANKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12541",
            "district": "26",
            "Block": "53",
            "label": "CF HSS ARUVANKADU",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20966",
            "district": "26",
            "Block": "53",
            "label": "ST.MICHAEL S MATRIC SCHOOL, SHANTHOOR, KETTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22471",
            "district": "26",
            "Block": "53",
            "label": "ST. JOSEPH'S ACADEMY, COONOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22472",
            "district": "26",
            "Block": "53",
            "label": "UDHAVI, CENTRE FOR CWSN ALWARPET COONOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23566",
            "district": "26",
            "Block": "53",
            "label": "Government Model School, Nilgiris",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23567",
            "district": "26",
            "Block": "53",
            "label": "NILGIRIS DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23566",
            "district": "26",
            "Block": "53",
            "label": "Government Model School, Nilgiris",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23761",
            "district": "26",
            "Block": "53",
            "label": "PUMS MELUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23762",
            "district": "26",
            "Block": "53",
            "label": "NILGIRIS DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23763",
            "district": "26",
            "Block": "53",
            "label": "ST JOSEPH'S CONVENT SCHOOLS",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "54": [
        {
            "key": "2790",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - KALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2819",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  CHELLANCHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3535",
            "district": "5",
            "Block": "54",
            "label": "ST ANTONY'S MATRICULATION HIGHER SECONDARY SCHOOL - CHINNAKANGANANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3659",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - CHINNA IRUSAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3752",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  M PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3813",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL - DEVANAMPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3953",
            "district": "5",
            "Block": "54",
            "label": "SARVA SEVA MATRICULATION SCHOOL, KANNARAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4059",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL -  PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4089",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  PETHANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4178",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - VAZHISOTHANAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4601",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - RAMAPURAM EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4605",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL - REDDY CHATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4969",
            "district": "5",
            "Block": "54",
            "label": "BHAVANI AMMAL MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL, K.N.PETTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5042",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - ARISIPERIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5241",
            "district": "5",
            "Block": "54",
            "label": "AIDED MIDDLE SCHOOL - SRI RAMAKRISHNA - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5622",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - U.C CHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5729",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL - BROOKEESPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5762",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SANGOLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5883",
            "district": "5",
            "Block": "54",
            "label": "AIDED MIDDLE SCHOOL - SACRED HEART - PUHUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5978",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - KODUKKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5981",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - THIRUMANIKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6155",
            "district": "5",
            "Block": "54",
            "label": "KRISHNASAMY MEMORIAL MATRIC HIGHER SECONDARY SCHOOL,CUDDALORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6216",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SOTHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6262",
            "district": "5",
            "Block": "54",
            "label": "ST.MARY'S MATRICULATION HR.SEC.SCHOOL, CUDDALORE-607001.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6484",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - EDAIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6583",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL MIDDLE SCHOOL - SIMON GARDEN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6615",
            "district": "5",
            "Block": "54",
            "label": "CK SCHOOL OF PRACTICAL KNOWLEDGE MATRICULATION HIGHER SECONDARY SCHOOL - CUDDALORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6700",
            "district": "5",
            "Block": "54",
            "label": "A.R.L.M.MATRICULATION HIGHER SECONDARY SCHOOL, VILVA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15123",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SUTHUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15124",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  SANRORPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15125",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - C.N.PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15126",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL -  C.N.PALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15127",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - THAZHANKUDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15128",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  KARAIKADU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15129",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - KARAIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15130",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - KEEZH AZHINJIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15131",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - KEEZH KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15132",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - RASAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15133",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - PERIYAKATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15134",
            "district": "5",
            "Block": "54",
            "label": "VELLAI THAMARAI MATRICULATION SCHOOL - CHINNA KATTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15135",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  KUTTIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15136",
            "district": "5",
            "Block": "54",
            "label": "KRISHNASAMY MATRICULATION HIGHER SECONDARY SCHOOL OF EXCELLENCE - S.KUMARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15137",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - NADUVEERAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15138",
            "district": "5",
            "Block": "54",
            "label": "AIDED MIDDLE SCHOOL - APPAR- NALLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15139",
            "district": "5",
            "Block": "54",
            "label": "AKSHARA VIDYAASHRAM SENIOR SECONDARY  SCHOOL (CBSE), NATHAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15140",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - PACHAYANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15141",
            "district": "5",
            "Block": "54",
            "label": "ST THOMAS MATRICULATION SCHOOL - KOOTHAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15142",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - PERIYA KANGANANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15143",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - RAMAPURAM WEST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15144",
            "district": "5",
            "Block": "54",
            "label": "AHS - ANNAI VELANKANNI CLUNY- SATHANKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15145",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SEDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15146",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - SEMMANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15147",
            "district": "5",
            "Block": "54",
            "label": "MAHATMA GANDHI INTERNATIONAL SCHOOL- SINGIRIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15148",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - THOOKKANAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15149",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - THIRUVANDIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15150",
            "district": "5",
            "Block": "54",
            "label": "SRI VIDYA KALA KENDRAM MATRICULATION HIGH SCHOOL, THIRUVANTHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15151",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - THOTTAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15152",
            "district": "5",
            "Block": "54",
            "label": "KRISHNASWAMY VIDYA NIKETHAN SENIOR SECONDARY SCHOOL (CBSE) - S.KUMARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15153",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - VANAMADEVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15154",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH  SCHOOL - VARAKALPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15155",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - V.KATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15156",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - VELLAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15157",
            "district": "5",
            "Block": "54",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - VILANGALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15158",
            "district": "5",
            "Block": "54",
            "label": "AIDED MIDDLE SCHOOL ST MARY'S - PUDHUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15159",
            "district": "5",
            "Block": "54",
            "label": "MODERN MATRICULATION HIGHER SECONDARY SCHOOL, VELI SEMMANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15160",
            "district": "5",
            "Block": "54",
            "label": "SAVIOUR JESUS MATRICULATION HIGHER SECONDARY SCHOOL - CUDDALORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15161",
            "district": "5",
            "Block": "54",
            "label": "SRI VARADHAM GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL -  VENUGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15162",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - VANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15163",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - THIRUPATHIRIPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15164",
            "district": "5",
            "Block": "54",
            "label": "MUNICIPAL HIGHER SECONDARY SCHOOL - CUDDALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15165",
            "district": "5",
            "Block": "54",
            "label": "ST JOSEPH'S HIGHER SECONDARY SCHOOL - MANJAKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15166",
            "district": "5",
            "Block": "54",
            "label": "ST JOSEPH'S HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15167",
            "district": "5",
            "Block": "54",
            "label": "SRI PATALESWARAR HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15168",
            "district": "5",
            "Block": "54",
            "label": "ST ANNE'S GIRLS HIGHER SECONDARY SCHOOL - PUDHUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15169",
            "district": "5",
            "Block": "54",
            "label": "ST JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL - KOOTHAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15170",
            "district": "5",
            "Block": "54",
            "label": "LAKSHMI CHORDIA MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL - S.S.R.NAGAR- THIRUPPATHIRIPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15171",
            "district": "5",
            "Block": "54",
            "label": "NEW MILLENNIUM MATRICULATION HIGHER SECONARY SCHOOL - KUMARAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15172",
            "district": "5",
            "Block": "54",
            "label": "SRI VALLIAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, THIRUPPATHIRIPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15173",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL - MANJAKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15174",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT DEAF AND DUMB MIDDLE SCHOOL - CUDDALORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15175",
            "district": "5",
            "Block": "54",
            "label": "SRI LAKSHMI CHORDIA MATRICULATION HIGHER SECONDARY SCHOOL - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15176",
            "district": "5",
            "Block": "54",
            "label": "SRI MAHARISHI VIDHYA MANDHIR MATRICULATION SCHOOL - MANJAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15177",
            "district": "5",
            "Block": "54",
            "label": "ARISTO PUBLIC SCHOOL - THIRUPPATHIRIPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15178",
            "district": "5",
            "Block": "54",
            "label": "ADHIDRAVIDAR WELFARE MIDDLE SCHOOL - SELLANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15179",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - SANKARAN STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15180",
            "district": "5",
            "Block": "54",
            "label": "ST FRANCIS XAVIER MS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15181",
            "district": "5",
            "Block": "54",
            "label": "ST PHILOMINA'S GIRLS HIGH SCHOOL,CUDDALORE PORT",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15182",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL - CUDDALORE PORT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15183",
            "district": "5",
            "Block": "54",
            "label": "ST DAVID'S HIGHER SECONDARY SCHOOL - CUDDALORE PORT",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15184",
            "district": "5",
            "Block": "54",
            "label": "KAMATCHI SHANMUGAM MATRICULATION HIGHER SECONDARY SCHOOL- SIVANANDHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15185",
            "district": "5",
            "Block": "54",
            "label": "SRI SARASWATHI VIDHYAALAYA MATRICULATION HIGHER SECONDARY SCHOOL , CUDDALORE  PORT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15186",
            "district": "5",
            "Block": "54",
            "label": "ST DAVID'S MATRICULATION SCHOOL- CUDALORE PORT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15187",
            "district": "5",
            "Block": "54",
            "label": "LAKSHMI MATRIC  SCHOOL - CUDDALORE PORT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15188",
            "district": "5",
            "Block": "54",
            "label": "A.G.M MIDDLE SCHOOL CUDDALORE PORT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21094",
            "district": "5",
            "Block": "54",
            "label": "THE PSBB MILLENNIUM SCHOOL - PERIYAKATUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21249",
            "district": "5",
            "Block": "54",
            "label": "AURO CHILD INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL -VARAKALPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21692",
            "district": "5",
            "Block": "54",
            "label": "SRI VANI VIDHYALAYA MATRICULATION SCHOOL, THOTTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21693",
            "district": "5",
            "Block": "54",
            "label": "EQUITAS GURUKUL MATRICULATION HIGHER SECONDARY SCHOOL- DEVANAMPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21963",
            "district": "5",
            "Block": "54",
            "label": "OASIS SNEHADEEP HIGH SCHOOL FOR THE SEVERELY DISABLED - VANNIYARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22195",
            "district": "5",
            "Block": "54",
            "label": "ST.ANNE'S SCHOOL - PUDHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22196",
            "district": "5",
            "Block": "54",
            "label": "GLOBAL SPECIAL SCHOOL FOR INTELLECTUAL DISABLED - 4TH CROSS STREET- SUBBURAYALU NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22197",
            "district": "5",
            "Block": "54",
            "label": "MOUNT LITERA ZEE SCHOOL - VANDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22198",
            "district": "5",
            "Block": "54",
            "label": "EZHUCHI INTELLECTUAL DISABLED SPECIAL CARE SCHOOL - KONDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22199",
            "district": "5",
            "Block": "54",
            "label": "SHANTHAM SPECIAL SCHOOL FOR MENTALLY CHALLENGED - B.VADUGAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22200",
            "district": "5",
            "Block": "54",
            "label": "SRI SIVASAKTHI MENTALLY RETARDED SPECIAL CARE SCHOOL - K.N.PETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22201",
            "district": "5",
            "Block": "54",
            "label": "DHAYA SPECIAL SCHOOL FOR INTELLECTUAL DISABLED - VANNIYARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22202",
            "district": "5",
            "Block": "54",
            "label": "CK SCHOOL OF PROGRESSIVE EDUCATION - CHELLANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22548",
            "district": "5",
            "Block": "54",
            "label": "GOVERNMENT HIGH SCHOOL - AZHAGIANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22842",
            "district": "5",
            "Block": "54",
            "label": "V.C.S .SHEMFORD INTERNATIONAL SCHOOL,CAPPER HILLS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23061",
            "district": "5",
            "Block": "54",
            "label": "GREEN TECH MATRICULATION  HIGHER SECONDARY SCHOOL, VELLAPPAKKAM,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23475",
            "district": "5",
            "Block": "54",
            "label": "CUDDALORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23764",
            "district": "5",
            "Block": "54",
            "label": "CUDDALORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23765",
            "district": "5",
            "Block": "54",
            "label": "ARCOT RAMASWAMI LAKSHMANASWAMI MUDHALIYAR HR SEC SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23766",
            "district": "5",
            "Block": "54",
            "label": "ARISTO PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "55": [
        {
            "key": "1439",
            "district": "27",
            "Block": "55",
            "label": "NALANDHA MATRIC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1779",
            "district": "27",
            "Block": "55",
            "label": "RAM JAYAM VIDYAA MANDIR MATRIC HR SEC SCHOOL -  CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1785",
            "district": "27",
            "Block": "55",
            "label": "NAGAMANI AMMAL MEMORIAL MATRIC HR SEC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2142",
            "district": "27",
            "Block": "55",
            "label": "SRI.SAKTHI.VINAYAKAR MATRIC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2400",
            "district": "27",
            "Block": "55",
            "label": "ANNS MATRIC HR. SEC. SCHOOL, CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2553",
            "district": "27",
            "Block": "55",
            "label": "SRI MUKTHI VINAYAGAR MS - CUMBUM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2601",
            "district": "27",
            "Block": "55",
            "label": "BROOKES MATRIC SCHOOL - HIGHWAVYS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3100",
            "district": "27",
            "Block": "55",
            "label": "GOVT KALLAR HS, NARAYANATHEVANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "3201",
            "district": "27",
            "Block": "55",
            "label": "VIVEKANANTHA MATRIC HSS - KAMAYAGOUNDANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3479",
            "district": "27",
            "Block": "55",
            "label": "MAZHALAIYAR MATRIC HR SEC SCHOOL - KEELAGUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3839",
            "district": "27",
            "Block": "55",
            "label": "AL-AZ'HAR MATRICULATION HIGHER SECONDARY  SCHOOL, CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3973",
            "district": "27",
            "Block": "55",
            "label": "ST.MARY'S.MATRIC HR SEC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3980",
            "district": "27",
            "Block": "55",
            "label": "FAIRLANDS  A FOUNDATION SCHOOL - CBSE - C. PUTHUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4311",
            "district": "27",
            "Block": "55",
            "label": "ST. ALPHONSE  MS -  UTHAMAPURAM - CUMBUM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4332",
            "district": "27",
            "Block": "55",
            "label": "VOC MS -   KEELAGUDALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4549",
            "district": "27",
            "Block": "55",
            "label": "ST.JOSHEP R.C.MS, HANUMANTHANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4572",
            "district": "27",
            "Block": "55",
            "label": "VEENA VIDHYALAYA MATRIC SCHOOL, C.PUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4986",
            "district": "27",
            "Block": "55",
            "label": "MPL MS - SUNGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5124",
            "district": "27",
            "Block": "55",
            "label": "PUMS - KEELAGUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5407",
            "district": "27",
            "Block": "55",
            "label": "PUMS -  ERAVANGALAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5893",
            "district": "27",
            "Block": "55",
            "label": "PUMS - SURULIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18546",
            "district": "27",
            "Block": "55",
            "label": "SRI A.L.V. HS, C.PUTHUPATTY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18547",
            "district": "27",
            "Block": "55",
            "label": "K.L.S.S. GOVT HSS, C.PUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18548",
            "district": "27",
            "Block": "55",
            "label": "N.S.K.P. HSS, GUDALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18549",
            "district": "27",
            "Block": "55",
            "label": "GOVT HSS, LOWER CAMP",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18550",
            "district": "27",
            "Block": "55",
            "label": "R. M. GOVT KALLAR HSS, MELAGUDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18551",
            "district": "27",
            "Block": "55",
            "label": "THIRUVALLUVAR HSS, KEELAGUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18552",
            "district": "27",
            "Block": "55",
            "label": "GOVT KALLAR HSS, KARUNAKAMUTHANPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18553",
            "district": "27",
            "Block": "55",
            "label": "GOVT KALLAR HS, KULLAPPAGOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        { 
            "key":"24134", 
            "district":"27", "Block":"55", 
            "label": "THE ASCENT ACADEMY", 
            "Sch_type": "Un-aided", 
            "Category": "Middle School"
        },
        {
            "key": "18554",
            "district": "27",
            "Block": "55",
            "label": "GOVT HSS, SURULIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24126", "district":"27", 
            "Block":"55", "label": "O.R.school", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "18555",
            "district": "27",
            "Block": "55",
            "label": "GOVT HSS, HIGHWAVYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18556",
            "district": "27",
            "Block": "55",
            "label": "PUMS - MELMANALAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18557",
            "district": "27",
            "Block": "55",
            "label": "PUMS -  KAMAYAKOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18558",
            "district": "27",
            "Block": "55",
            "label": "KASTHURIBAI MS - KAMAYAGOUNDANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18559",
            "district": "27",
            "Block": "55",
            "label": "KASTHURIBAI GIRLS HSS, KAMAYAGOUNDANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18560",
            "district": "27",
            "Block": "55",
            "label": "GOVT HSS, KAMAYAKOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18561",
            "district": "27",
            "Block": "55",
            "label": "ILAHI ORIENTAL ARABIC HS, CUMBUM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18562",
            "district": "27",
            "Block": "55",
            "label": "GOVT KALLAR HSS, UTHAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18563",
            "district": "27",
            "Block": "55",
            "label": "MPL GIRLS HSS, M.A.PURAM, CUMBUM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18564",
            "district": "27",
            "Block": "55",
            "label": "MPL.MS - ALAMARAM, CUMBUM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18565",
            "district": "27",
            "Block": "55",
            "label": "SRI MUTHIAH PILLAI MEMORIAL HSS, CUMBUM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18566",
            "district": "27",
            "Block": "55",
            "label": "C.P.U. HSS, CUMBUM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18567",
            "district": "27",
            "Block": "55",
            "label": "A. R. GOVT GIRLS HSS, CUMBUM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18568",
            "district": "27",
            "Block": "55",
            "label": "S.V.MATRIC SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21107",
            "district": "27",
            "Block": "55",
            "label": "JAYTECH INTERNATIONAL SENIOR SECONDARY  SCHOOL C.PUDHUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22261",
            "district": "27",
            "Block": "55",
            "label": "R R INTERNATIONAL SCHOOL - C .PUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22749",
            "district": "27",
            "Block": "55",
            "label": "MAHARISHI VIDHYA MANDHIR  SCHOOL - KULLAPPAGOUNDANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22961",
            "district": "27",
            "Block": "55",
            "label": "SRI SAKTHI VINAYAGAR INTERNATIONAL (CBSE) SCHOOL - CUMBUM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "56": [
        {
            "key": "5735",
            "district": "23",
            "Block": "56",
            "label": "VAIRAM GROUP MATRIC HSS, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17568",
            "district": "23",
            "Block": "56",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, PERIYAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17569",
            "district": "23",
            "Block": "56",
            "label": "PUMS EZHUVANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17570",
            "district": "23",
            "Block": "56",
            "label": "PUMS, NATCHANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17571",
            "district": "23",
            "Block": "56",
            "label": "PUMS BHARATHI VELANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17572",
            "district": "23",
            "Block": "56",
            "label": "PUMS KALLANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17573",
            "district": "23",
            "Block": "56",
            "label": "PUMS D.NAGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17574",
            "district": "23",
            "Block": "56",
            "label": "PUMS MAVIDITHIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17575",
            "district": "23",
            "Block": "56",
            "label": "ST. JOHN DE BRITTO MIDDLE SCHOOL, PULIYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17576",
            "district": "23",
            "Block": "56",
            "label": "GOVT HIGH SCHOOl, PULIYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17577",
            "district": "23",
            "Block": "56",
            "label": "MOUNT SENARIO MATRIC HIGHER SECONDARY SCHOOL, MUPPAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17578",
            "district": "23",
            "Block": "56",
            "label": "GOVT HIGH SCHOOL, MUPPAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17579",
            "district": "23",
            "Block": "56",
            "label": "PUMS. PIDARANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17580",
            "district": "23",
            "Block": "56",
            "label": "PUMS KAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17581",
            "district": "23",
            "Block": "56",
            "label": "SRI SHANMUGA MIDDLE SCHOOL, VELAYUTHAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17582",
            "district": "23",
            "Block": "56",
            "label": "PUMS PARAMBAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17583",
            "district": "23",
            "Block": "56",
            "label": "PUMS PANIPULANVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17584",
            "district": "23",
            "Block": "56",
            "label": "P.S.S HIGH SCHOOL, SHANMUGANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17585",
            "district": "23",
            "Block": "56",
            "label": "PUMS VELIMUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17586",
            "district": "23",
            "Block": "56",
            "label": "ST JOHN'S GIRLS HR SEC SCHOOL DEVAKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17587",
            "district": "23",
            "Block": "56",
            "label": "PUMS ATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17588",
            "district": "23",
            "Block": "56",
            "label": "ST. JOSEPH MIDDLE SCHOOL, THIDAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17589",
            "district": "23",
            "Block": "56",
            "label": "PUMS SELUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17590",
            "district": "23",
            "Block": "56",
            "label": "MUNICIPAL HIGH SCHOOL, DEVAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17591",
            "district": "23",
            "Block": "56",
            "label": "10TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17592",
            "district": "23",
            "Block": "56",
            "label": "16TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17593",
            "district": "23",
            "Block": "56",
            "label": "KVP MATRICULATION SCHOOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17594",
            "district": "23",
            "Block": "56",
            "label": "14TH WARD MUNICIPAL MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17595",
            "district": "23",
            "Block": "56",
            "label": "PETHAL ACHI GIRLS HR SEC SCHOOL, DEVAKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17596",
            "district": "23",
            "Block": "56",
            "label": "INFANT JESUS MATRIC HSS, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17597",
            "district": "23",
            "Block": "56",
            "label": "SRI MURUGANANDHA MIDDLE SCHOOL DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17598",
            "district": "23",
            "Block": "56",
            "label": "MANICKAM MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17599",
            "district": "23",
            "Block": "56",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL, RAMNAGAR, DEVAKOTTAI (EXTN.)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17600",
            "district": "23",
            "Block": "56",
            "label": "ST JOSEPH MATRIC HIGHER SECONDARY SCHOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17601",
            "district": "23",
            "Block": "56",
            "label": "S.R.MID SCHOOL DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17602",
            "district": "23",
            "Block": "56",
            "label": "ZAMINTHAR STREET HIGH SCHOOL DEVAKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17603",
            "district": "23",
            "Block": "56",
            "label": "CHAIRMAN MANICKAVASAGAM MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17604",
            "district": "23",
            "Block": "56",
            "label": "SRI SEVUGAN ANNAMALAI MATRIC SCHOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17605",
            "district": "23",
            "Block": "56",
            "label": "THIRUVALLUVAR ARULNERI MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17606",
            "district": "23",
            "Block": "56",
            "label": "SRINIVASA MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17607",
            "district": "23",
            "Block": "56",
            "label": "MUTHATHAL MIDDLE SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17608",
            "district": "23",
            "Block": "56",
            "label": "DE BRITTO HSS, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17609",
            "district": "23",
            "Block": "56",
            "label": "N.S.M.V.P.S HSS, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17610",
            "district": "23",
            "Block": "56",
            "label": "ST MARY'S GIRLS HR SEC SCHOOL, DEVAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17611",
            "district": "23",
            "Block": "56",
            "label": "LOTUS AN. VENKATACHALAM CHETTIAR ICSE SCHOOL DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17612",
            "district": "23",
            "Block": "56",
            "label": "PUMS ANTHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17613",
            "district": "23",
            "Block": "56",
            "label": "GOVT. HR SEC SCHOOL, THIRUVEGAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17614",
            "district": "23",
            "Block": "56",
            "label": "PUMS KAVATHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17615",
            "district": "23",
            "Block": "56",
            "label": "PUMS SENKARKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17616",
            "district": "23",
            "Block": "56",
            "label": "PUMS ILANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17617",
            "district": "23",
            "Block": "56",
            "label": "SAINT JOSEPH MIDDLE SCHOOL, SARUGANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17618",
            "district": "23",
            "Block": "56",
            "label": "ST. PAUL'S HIGH SCHOOL, SARUGANI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17619",
            "district": "23",
            "Block": "56",
            "label": "ST JOSEPH'S HR SEC SCHOOL, SEKKAKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17620",
            "district": "23",
            "Block": "56",
            "label": "V.N.T.GOVT HR SEC SCHOOL, SHANMUGANATHAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17621",
            "district": "23",
            "Block": "56",
            "label": "RC HIGH SCHOOL, KEELAUCHANI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17622",
            "district": "23",
            "Block": "56",
            "label": "PUMS URUVATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17623",
            "district": "23",
            "Block": "56",
            "label": "PUMS SIRUNALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21333",
            "district": "23",
            "Block": "56",
            "label": "BHAARATH PUBLIC CBSE SCHOOL, AARAVAYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21851",
            "district": "23",
            "Block": "56",
            "label": "PANCHU VALLIAPPA MATRICULATION SCHOOL DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22238",
            "district": "23",
            "Block": "56",
            "label": "ST. JOSEPH INTERNATIONAL SCHOOL,KODIKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22239",
            "district": "23",
            "Block": "56",
            "label": "SHREE CHINNAPPAN VIDHYA MANDHIR MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23549",
            "district": "23",
            "Block": "56",
            "label": "ST.MARY'S MATRICULATION SCHOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23549",
            "district": "23",
            "Block": "56",
            "label": "ST.MARY'S MATRICULATION SCHOOL, DEVAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "57": [
        {
            "key": "1248",
            "district": "32",
            "Block": "57",
            "label": "SINDHU MATRIC HSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1255",
            "district": "32",
            "Block": "57",
            "label": "VISHNU LAKSHMI MHSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1275",
            "district": "32",
            "Block": "57",
            "label": "PONNU MATRIC HSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1587",
            "district": "32",
            "Block": "57",
            "label": "CENTTWIN MATRIC HSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1604",
            "district": "32",
            "Block": "57",
            "label": "LIONS MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4338",
            "district": "32",
            "Block": "57",
            "label": "ST AVILA MATRICULATION SCHOOL, DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12104",
            "district": "32",
            "Block": "57",
            "label": "GHSS ALANGIAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12105",
            "district": "32",
            "Block": "57",
            "label": "MMS CHITRAVUTHANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12106",
            "district": "32",
            "Block": "57",
            "label": "PUMS S.KANGAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12107",
            "district": "32",
            "Block": "57",
            "label": "PUMS CHIKKINAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12108",
            "district": "32",
            "Block": "57",
            "label": "PUMS C.KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12109",
            "district": "32",
            "Block": "57",
            "label": "GHS SELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12110",
            "district": "32",
            "Block": "57",
            "label": "PUMS D.KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12111",
            "district": "32",
            "Block": "57",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12112",
            "district": "32",
            "Block": "57",
            "label": "GHSS DHALAVAIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12113",
            "district": "32",
            "Block": "57",
            "label": "ST ANTONYS MIDDLE SCHOOL DHARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12114",
            "district": "32",
            "Block": "57",
            "label": "MMS OPS DHARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12115",
            "district": "32",
            "Block": "57",
            "label": "GHSS DHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12116",
            "district": "32",
            "Block": "57",
            "label": "ALOYSIUS GIRLS HSS DHARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12117",
            "district": "32",
            "Block": "57",
            "label": "CSI GIRLS HSS DHARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12118",
            "district": "32",
            "Block": "57",
            "label": "MMS VALAYAKARA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12119",
            "district": "32",
            "Block": "57",
            "label": "NCP MHSS DHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12120",
            "district": "32",
            "Block": "57",
            "label": "CSI BOYS HSS DHARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12121",
            "district": "32",
            "Block": "57",
            "label": "PONNU GIRLS HS DHARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12122",
            "district": "32",
            "Block": "57",
            "label": "THENMALAR HSS DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12123",
            "district": "32",
            "Block": "57",
            "label": "SAKTHI MATRICULATION SCHOOL DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12124",
            "district": "32",
            "Block": "57",
            "label": "PUMS PERIYAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12125",
            "district": "32",
            "Block": "57",
            "label": "PUMS GOVINDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12126",
            "district": "32",
            "Block": "57",
            "label": "MSN HSS GOVINDAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12127",
            "district": "32",
            "Block": "57",
            "label": "PUMS GANDHIJI NAGAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12128",
            "district": "32",
            "Block": "57",
            "label": "PUMS KALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12129",
            "district": "32",
            "Block": "57",
            "label": "GHSS KOLATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12130",
            "district": "32",
            "Block": "57",
            "label": "INDHIRA GANDHI MHSS , KOLATHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12131",
            "district": "32",
            "Block": "57",
            "label": "DR NALLINI HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12132",
            "district": "32",
            "Block": "57",
            "label": "MUNICIPAL MIDDLE SCHOOL, KOLINJIWADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12133",
            "district": "32",
            "Block": "57",
            "label": "GHS THERPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12134",
            "district": "32",
            "Block": "57",
            "label": "GHS NANJIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12135",
            "district": "32",
            "Block": "57",
            "label": "PUMS PONNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12136",
            "district": "32",
            "Block": "57",
            "label": "PUMS THOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12137",
            "district": "32",
            "Block": "57",
            "label": "PUMS VEERACHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20886",
            "district": "32",
            "Block": "57",
            "label": "AGARAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20965",
            "district": "32",
            "Block": "57",
            "label": "BETHANIYA SPECIAL SCHOOL FOR M.R CHILDREN",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "21073",
            "district": "32",
            "Block": "57",
            "label": "AUROBINDO VIDHYALAYA CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21074",
            "district": "32",
            "Block": "57",
            "label": "GREENFIELD INTERNATIONAL SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21228",
            "district": "32",
            "Block": "57",
            "label": "VEVEAHAM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21463",
            "district": "32",
            "Block": "57",
            "label": "GHS,CHINNAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21464",
            "district": "32",
            "Block": "57",
            "label": "GHS,MANAKKADAVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21465",
            "district": "32",
            "Block": "57",
            "label": "GHS, DHASARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21466",
            "district": "32",
            "Block": "57",
            "label": "GHS, POTTIKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21633",
            "district": "32",
            "Block": "57",
            "label": "VEVEAHAM PRIME ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22458",
            "district": "32",
            "Block": "57",
            "label": "PRAKRUTHI PUBLIC SCHOOL, PANCHAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22459",
            "district": "32",
            "Block": "57",
            "label": "GOVERNMENT HIGH SCHOOL, AACHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22460",
            "district": "32",
            "Block": "57",
            "label": "SRI KRISHNA VIDHYALAYA SCHOOL, SAKTHI NAGAR,DHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22958",
            "district": "32",
            "Block": "57",
            "label": "VEVEAHAM MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23767",
            "district": "32",
            "Block": "57",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23768",
            "district": "32",
            "Block": "57",
            "label": "PUMS THOPPAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "58": [
        {
            "key": "1234",
            "district": "6",
            "Block": "58",
            "label": "SRI VIJAY VIDYALAYA GIRLS MHSS, GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1772",
            "district": "6",
            "Block": "58",
            "label": "SRI VIDYA MANDIR MATRIC SCHOOL, PIDAMANERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2309",
            "district": "6",
            "Block": "58",
            "label": "PARAMVEER BANASINGH VIDYA ACADEMY OF EXCELLENCE MHSS, AATUKKARANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2524",
            "district": "6",
            "Block": "58",
            "label": "SRI VIJAY VIDYALAYA BOYS MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2780",
            "district": "6",
            "Block": "58",
            "label": "SENTHIL MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2794",
            "district": "6",
            "Block": "58",
            "label": "DON BOSCO MHSS, GUNDALAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3278",
            "district": "6",
            "Block": "58",
            "label": "GOOD SHEPHERD MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3669",
            "district": "6",
            "Block": "58",
            "label": "SEVENTHDAY ADVENTIST MHSS , DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4044",
            "district": "6",
            "Block": "58",
            "label": "DAWN MHSS, S.V. ROAD, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4257",
            "district": "6",
            "Block": "58",
            "label": "SRI VISWABHARATHI MHSS, A.GOLLAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9127",
            "district": "6",
            "Block": "58",
            "label": "PUMS A.GOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9128",
            "district": "6",
            "Block": "58",
            "label": "GHSS ADHAGAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9129",
            "district": "6",
            "Block": "58",
            "label": "PUMS ALIVAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9130",
            "district": "6",
            "Block": "58",
            "label": "PUMS ATTUKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9131",
            "district": "6",
            "Block": "58",
            "label": "GHS BATHIREDDIHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9132",
            "district": "6",
            "Block": "58",
            "label": "MMS FORT URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23690",
            "district": "6",
            "Block": "58",
            "label": "Agni Sarar Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "9133",
            "district": "6",
            "Block": "58",
            "label": "MMS ANNASAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9134",
            "district": "6",
            "Block": "58",
            "label": "MMS IV WARD - S.V.ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9135",
            "district": "6",
            "Block": "58",
            "label": "MMS KUMARASAMI PET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9136",
            "district": "6",
            "Block": "58",
            "label": "MMS MATHIKONPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9137",
            "district": "6",
            "Block": "58",
            "label": "MMS SANDAIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9138",
            "district": "6",
            "Block": "58",
            "label": "GHS KIRUBANANDA Variyar",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9139",
            "district": "6",
            "Block": "58",
            "label": "MHS MUNICIPAL GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9140",
            "district": "6",
            "Block": "58",
            "label": "Adhiyaman GBHSS Dharmapuri",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9141",
            "district": "6",
            "Block": "58",
            "label": "GHSS AVVAIYAR GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9142",
            "district": "6",
            "Block": "58",
            "label": "SRI VIJAY VIDHYASHRAM, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9143",
            "district": "6",
            "Block": "58",
            "label": "PUMS GENGANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9144",
            "district": "6",
            "Block": "58",
            "label": "GHS GULIYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9145",
            "district": "6",
            "Block": "58",
            "label": "PUMS GUNDALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9146",
            "district": "6",
            "Block": "58",
            "label": "GHSS HALE DHARMAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9147",
            "district": "6",
            "Block": "58",
            "label": "FATHIMA AIDED MIDDLE SCHOOL, KADAGATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "9148",
            "district": "6",
            "Block": "58",
            "label": "GHSS KADAGATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9149",
            "district": "6",
            "Block": "58",
            "label": "BAARATH MATRIC SCHOOL, PACHINAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9150",
            "district": "6",
            "Block": "58",
            "label": "PUMS KATTUR MARIAMMAN KOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9151",
            "district": "6",
            "Block": "58",
            "label": "PUMS NARASINGAPURAM KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9152",
            "district": "6",
            "Block": "58",
            "label": "GHSS KONANGINAICKANAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9153",
            "district": "6",
            "Block": "58",
            "label": "GHSS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9154",
            "district": "6",
            "Block": "58",
            "label": "KAMARASAR MATRIC SCHOOL, KRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9155",
            "district": "6",
            "Block": "58",
            "label": "PUMS KUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9156",
            "district": "6",
            "Block": "58",
            "label": "GHS LAKKIYAMPATTI GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9157",
            "district": "6",
            "Block": "58",
            "label": "GHSS LAKKIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9158",
            "district": "6",
            "Block": "58",
            "label": "GHSS FOR THE HEARING IMPAIRED DHARMAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9159",
            "district": "6",
            "Block": "58",
            "label": "JACK & JILL MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9160",
            "district": "6",
            "Block": "58",
            "label": "ROTARY VIVEKANANDA SCHOOL FOR HEARING IMPAIRED, PIDAMANERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9161",
            "district": "6",
            "Block": "58",
            "label": "PACHAMUTHU MHSS, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9162",
            "district": "6",
            "Block": "58",
            "label": "KENDIRA VIDYALAYA CBSE",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9163",
            "district": "6",
            "Block": "58",
            "label": "PUMS LALAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9164",
            "district": "6",
            "Block": "58",
            "label": "GHS M ODDAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9165",
            "district": "6",
            "Block": "58",
            "label": "PUMS MANALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9166",
            "district": "6",
            "Block": "58",
            "label": "PUMS MOOLAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9167",
            "district": "6",
            "Block": "58",
            "label": "SRI SANKARA VIDYALAYA MATRIC SCHOOL, GUNCHEETIPATTI, AKKAMANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9168",
            "district": "6",
            "Block": "58",
            "label": "GHS MOOKKANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9169",
            "district": "6",
            "Block": "58",
            "label": "GHSS MUKKALNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9170",
            "district": "6",
            "Block": "58",
            "label": "PUMS MUTHUGOUNDANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9171",
            "district": "6",
            "Block": "58",
            "label": "PUMS MUTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9172",
            "district": "6",
            "Block": "58",
            "label": "PUMS PACHINAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9173",
            "district": "6",
            "Block": "58",
            "label": "GHS PALLAKOLLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9174",
            "district": "6",
            "Block": "58",
            "label": "PUMS RAJATHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9175",
            "district": "6",
            "Block": "58",
            "label": "PUMS S.KOTTAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9176",
            "district": "6",
            "Block": "58",
            "label": "PUMS A SEKKARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9177",
            "district": "6",
            "Block": "58",
            "label": "CARMEL MATRIC SCHOOL, SEMMANDAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9178",
            "district": "6",
            "Block": "58",
            "label": "PUMS SIKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9179",
            "district": "6",
            "Block": "58",
            "label": "GHSS SOLAIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9180",
            "district": "6",
            "Block": "58",
            "label": "DR. RAMADOSS HR. SEC. SCHOOL, SOLAIKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9181",
            "district": "6",
            "Block": "58",
            "label": "PUMS SOWLUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9182",
            "district": "6",
            "Block": "58",
            "label": "SREE SIVANIVEL HIGH SCHOOL, UNGARANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9183",
            "district": "6",
            "Block": "58",
            "label": "GHSS V MUTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9184",
            "district": "6",
            "Block": "58",
            "label": "GHSS VATHALMALAI PERIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9185",
            "district": "6",
            "Block": "58",
            "label": "PUMS VENKATANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9186",
            "district": "6",
            "Block": "58",
            "label": "PUMS VEPPAMARATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9187",
            "district": "6",
            "Block": "58",
            "label": "PUMS A.NADUHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9188",
            "district": "6",
            "Block": "58",
            "label": "GHS K NADUHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9189",
            "district": "6",
            "Block": "58",
            "label": "PUMS MARAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9190",
            "district": "6",
            "Block": "58",
            "label": "GHS MITTANOOLAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9191",
            "district": "6",
            "Block": "58",
            "label": "GHS NAICKENKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9192",
            "district": "6",
            "Block": "58",
            "label": "PUMS PERIYAKURAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9193",
            "district": "6",
            "Block": "58",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9194",
            "district": "6",
            "Block": "58",
            "label": "PUMS PUZHUTHIKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9195",
            "district": "6",
            "Block": "58",
            "label": "ADWMS RAJAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9196",
            "district": "6",
            "Block": "58",
            "label": "PUMS SOGATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9197",
            "district": "6",
            "Block": "58",
            "label": "VARIYAR HR. SEC. SCHOOL, SOGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9198",
            "district": "6",
            "Block": "58",
            "label": "GHS THIPPIREDDIHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9199",
            "district": "6",
            "Block": "58",
            "label": "PUMS V.GOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9200",
            "district": "6",
            "Block": "58",
            "label": "GHS VELLOLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21059",
            "district": "6",
            "Block": "58",
            "label": "TARUWIN VIDHYA MANDIR SCHOOL, POOSARIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21301",
            "district": "6",
            "Block": "58",
            "label": "DAWN SHIKSHALAYA PUBLIC SCHOOL, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21302",
            "district": "6",
            "Block": "58",
            "label": "THE VIJAY MILLENNIUM SCHOOL, SOGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21303",
            "district": "6",
            "Block": "58",
            "label": "KAMALAM INTERNATIONAL SCHOOL, K. NADUHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21662",
            "district": "6",
            "Block": "58",
            "label": "MKS MATRIC SCHOOL, SOGATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21663",
            "district": "6",
            "Block": "58",
            "label": "AUXILIUM MHSS, SOGATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22074",
            "district": "6",
            "Block": "58",
            "label": "DNV International Academy",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22400",
            "district": "6",
            "Block": "58",
            "label": "SASSI GNANODAYA ACADEMY, NALLANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23477",
            "district": "6",
            "Block": "58",
            "label": "DHARMAPURI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23478",
            "district": "6",
            "Block": "58",
            "label": "Government Model School, Dharmapuri",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23478",
            "district": "6",
            "Block": "58",
            "label": "Government Model School, Dharmapuri",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23769",
            "district": "6",
            "Block": "58",
            "label": "SREE SIVANIVEL VIDHYALAYA MATRICULATION SCHOOL, UNGARANAHALLI, DHARMAPURI",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "59": [
        {
            "key": "2576",
            "district": "7",
            "Block": "59",
            "label": "MVM GIRLS MATRIC HSS, ANGUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2782",
            "district": "7",
            "Block": "59",
            "label": "PRASIDDHI VIDYODAYA, SELAPPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2787",
            "district": "7",
            "Block": "59",
            "label": "SHRI GURUMUKI VIDHYASHRAM,PUDHUKAPPLIAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2883",
            "district": "7",
            "Block": "59",
            "label": "ST.PATRICKS ACADEMY, C.KALANAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3268",
            "district": "7",
            "Block": "59",
            "label": "KK AYYA NADAR GANGAMIRTHAMMAL MATRIC SCHOOL,BALAKRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3422",
            "district": "7",
            "Block": "59",
            "label": "PANNAI MATRICULATION SCHOOL,MULLIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3480",
            "district": "7",
            "Block": "59",
            "label": "KENNEDY MATRICULATION HIGHER SECONDARY SCHOOL, VAZHAKKAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3543",
            "district": "7",
            "Block": "59",
            "label": "CRESENT MAT HS, ANGUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3600",
            "district": "7",
            "Block": "59",
            "label": "HOLYCROSS CONVENT MATRIC HR SEC SCHOOL, RAMAYANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3964",
            "district": "7",
            "Block": "59",
            "label": "SEVENTH DAY ADVENTIST MATRIC HSS,PONNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6090",
            "district": "7",
            "Block": "59",
            "label": "SRI LAKSHMI VIDYALAYA MATRIC HR.SEC.SCHOOL,THADIKOMBU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6669",
            "district": "7",
            "Block": "59",
            "label": "AKSHAYA MATRICULATION HR. SEC. SCHOOL,SELAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13369",
            "district": "7",
            "Block": "59",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13370",
            "district": "7",
            "Block": "59",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,AGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13371",
            "district": "7",
            "Block": "59",
            "label": "ST.PAULS HS,ULAGAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13372",
            "district": "7",
            "Block": "59",
            "label": "GOVT. KALLAR MIDDLE SCHOOL, KOTTURAVARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13373",
            "district": "7",
            "Block": "59",
            "label": "ST.JAMES HSS,A.VELLODU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13374",
            "district": "7",
            "Block": "59",
            "label": "GOVT.HSS,NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13375",
            "district": "7",
            "Block": "59",
            "label": "SOUNDARARAJA VIDYALAYA, PONNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13376",
            "district": "7",
            "Block": "59",
            "label": "ST.ANTONY'S AIDED MIDDLE SCHOOL, PUDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13377",
            "district": "7",
            "Block": "59",
            "label": "GHS,MEENAKSHINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13378",
            "district": "7",
            "Block": "59",
            "label": "PUMS, KALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13379",
            "district": "7",
            "Block": "59",
            "label": "ANGUVILAS HIGHER SECONDARY SCHOOL- ANGU NAGAR, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13380",
            "district": "7",
            "Block": "59",
            "label": "HAJI P C NAINAR MOHAMED ORIENTAL ARABIC HIGH SCHOOL, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13381",
            "district": "7",
            "Block": "59",
            "label": "GOVT. KALLAR HIGHER SECONDARY SCHOOL, CHENNAMANAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13382",
            "district": "7",
            "Block": "59",
            "label": "GHS, CHETTINAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13383",
            "district": "7",
            "Block": "59",
            "label": "PUMS,UNDARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13384",
            "district": "7",
            "Block": "59",
            "label": "INFANT JESUS HS,MARAVAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13385",
            "district": "7",
            "Block": "59",
            "label": "GOVERNMENT HIGH SCHOOL -THADIKOMBU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13386",
            "district": "7",
            "Block": "59",
            "label": "PUMS,SEELAPPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13387",
            "district": "7",
            "Block": "59",
            "label": "SHRI MAHARISHI VIDYA MANDIR, SEELAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13388",
            "district": "7",
            "Block": "59",
            "label": "VIDHYA PARTHI HSS,SEELAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13389",
            "district": "7",
            "Block": "59",
            "label": "PUMS,KODANGINAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13390",
            "district": "7",
            "Block": "59",
            "label": "ST.JOSEPH'S MIDDLE SCHOOL, MULLIPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13391",
            "district": "7",
            "Block": "59",
            "label": "PUMS,THAMARAIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13392",
            "district": "7",
            "Block": "59",
            "label": "CSMA HSS,M.M.KOVILUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13393",
            "district": "7",
            "Block": "59",
            "label": "QUAID-E-MILLETH HIGH SCHOOL,RAJAKKAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13394",
            "district": "7",
            "Block": "59",
            "label": "CHEVALIER ACADEMY MATRICULATION HR. SEC. SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13395",
            "district": "7",
            "Block": "59",
            "label": "PUMS,BALAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13396",
            "district": "7",
            "Block": "59",
            "label": "JEHOVAH REFORMED CHRISTIAN HIGH SCHOOL, SEELOVAMNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13397",
            "district": "7",
            "Block": "59",
            "label": "PUMS,THOTTANUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13398",
            "district": "7",
            "Block": "59",
            "label": "GHSS,NALLAMANAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13399",
            "district": "7",
            "Block": "59",
            "label": "ST.ANTONY'S MIDDLE SCHOOL, IRENDALAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13400",
            "district": "7",
            "Block": "59",
            "label": "PUMS,PALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13401",
            "district": "7",
            "Block": "59",
            "label": "HILLOCK INTERNATIONAL SCHOOL,SIRUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13402",
            "district": "7",
            "Block": "59",
            "label": "GOVERNMENT HIGH SCHOOL ,SIRUMALAI PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20992",
            "district": "7",
            "Block": "59",
            "label": "PARVATHY'S ANUGRAHA INTERNATIONAL SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20993",
            "district": "7",
            "Block": "59",
            "label": "JMJ MATRIC, SIRUMALAIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21089",
            "district": "7",
            "Block": "59",
            "label": "PANNAI PUBLIC SCHOOL(CBSE), MULLIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22174",
            "district": "7",
            "Block": "59",
            "label": "AKV VIDYALAYA, KALLIPATTI, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22175",
            "district": "7",
            "Block": "59",
            "label": "ARVI SPECIAL SCHOOL FOR MR, SEELAPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22498",
            "district": "7",
            "Block": "59",
            "label": "GREEN VALLEY PUBLIC SCHOOL, PALLAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22499",
            "district": "7",
            "Block": "59",
            "label": "GURUKUL SPECIAL SCHOOL FOR MR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22938",
            "district": "7",
            "Block": "59",
            "label": "GHS, NALLAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22994",
            "district": "7",
            "Block": "59",
            "label": "PRNP VIDHYA MANDHIR MATRIC HIGHER SECONDARY SCHOOL, MULLIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23050",
            "district": "7",
            "Block": "59",
            "label": "VIDHYA PARTHI NATIONAL ACADEMY, SEELAPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23263",
            "district": "7",
            "Block": "59",
            "label": "SBC ACADEMY OF EXCELLENCE, SEELAPADI, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23770",
            "district": "7",
            "Block": "59",
            "label": "VELAMMAL BODHI CAMPUS, DINDIGUL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23771",
            "district": "7",
            "Block": "59",
            "label": "TAKSHASHILA VIDYA MANDIR, ANAIPATTI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23772",
            "district": "7",
            "Block": "59",
            "label": "J KAY SRI SANKARA PUBLIC SCHOOL, DINDIGUL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "60": [
        {
            "key": "2784",
            "district": "7",
            "Block": "60",
            "label": "SMBM MATRIC HSS,GTN ROAD, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2799",
            "district": "7",
            "Block": "60",
            "label": "ST.JOSEPH'S MATRICULATION HSS, WARD 13, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3192",
            "district": "7",
            "Block": "60",
            "label": "SAHAYA MATHA MATRIC SCHOOL,DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3208",
            "district": "7",
            "Block": "60",
            "label": "EQUITAS GURUKUL MATRICULATION HIGHER SECONDARY SCHOOL,DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3460",
            "district": "7",
            "Block": "60",
            "label": "SOPHIYA MATRIC SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3539",
            "district": "7",
            "Block": "60",
            "label": "LIONS MATRICULATION HIGHER SECONDARY SCHOOL,DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3912",
            "district": "7",
            "Block": "60",
            "label": "SRI VASAVI MATRICULATION HR. SEC. SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4877",
            "district": "7",
            "Block": "60",
            "label": "GREEN PARK MATRICULATION SCHOOL, BEGAMPUR, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5739",
            "district": "7",
            "Block": "60",
            "label": "ACHYUTA ACADEMY MATRIC HIGHER SECONDARY SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13782",
            "district": "7",
            "Block": "60",
            "label": "HAZRATH AMIRUNNISHA BEGAM ORIENTAL HIGHER SECONDARY SCHOOL,WARD 38, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13783",
            "district": "7",
            "Block": "60",
            "label": "GHSS,PALANI ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13784",
            "district": "7",
            "Block": "60",
            "label": "JOHN PAUL HSS,NEHRUJI NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13785",
            "district": "7",
            "Block": "60",
            "label": "JOHN PAUL ACADEMY CBSE SCHOOL,NEHRUJI NAGAR, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13786",
            "district": "7",
            "Block": "60",
            "label": "ST CHINNAPPAR HS,DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13787",
            "district": "7",
            "Block": "60",
            "label": "SRI VASAVI HSS,WARD 12",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13788",
            "district": "7",
            "Block": "60",
            "label": "GANDHIJI MEMORIAL MIDDLE SCHOOL, 5TH WARD, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13789",
            "district": "7",
            "Block": "60",
            "label": "G(G)HSS,KACHERITHERU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13790",
            "district": "7",
            "Block": "60",
            "label": "ST CECILIAS MS,WARD 13",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13791",
            "district": "7",
            "Block": "60",
            "label": "PUNITHA SOOSAIYAPPAR HSS,DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13792",
            "district": "7",
            "Block": "60",
            "label": "ST. JOSEPH'S GIRLS HSS,WARD13, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13793",
            "district": "7",
            "Block": "60",
            "label": "MSP SOLAI NADAR MEMORIAL HSS,GTN ROAD, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13794",
            "district": "7",
            "Block": "60",
            "label": "OM SHANTHI CBSE SCHOOL,DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13795",
            "district": "7",
            "Block": "60",
            "label": "CSI BOARDING MS,WARD 19, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13796",
            "district": "7",
            "Block": "60",
            "label": "DUDLEY HSS,DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13797",
            "district": "7",
            "Block": "60",
            "label": "ST MARYS HSS,WARD-12",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13798",
            "district": "7",
            "Block": "60",
            "label": "NEHRUJI MEM HSS, DINDIGUL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13799",
            "district": "7",
            "Block": "60",
            "label": "ST FRANCIS XAVIER HSS,WARD-23, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13800",
            "district": "7",
            "Block": "60",
            "label": "GANDHIJI MS,WARD-24",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13801",
            "district": "7",
            "Block": "60",
            "label": "T E L C MS,WARD-18",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13802",
            "district": "7",
            "Block": "60",
            "label": "BHARATHI MIDDLE SCHOOL, WARD-30",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13803",
            "district": "7",
            "Block": "60",
            "label": "RANIMANGAMMAL AIDED MS,WARD-33",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13804",
            "district": "7",
            "Block": "60",
            "label": "THIRU.NAMPERUMAL MIDDLE SCHOOL,WARD-31, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13805",
            "district": "7",
            "Block": "60",
            "label": "SANTHAI ROAD MUNICIPAL MIDDLE SCHOOL,WARD-48, DINDIGUL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13806",
            "district": "7",
            "Block": "60",
            "label": "ARUL JOTHI VALLALAR HIGHER SECONDARY SCHOOL, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13807",
            "district": "7",
            "Block": "60",
            "label": "ST PHILOMINAS MS, WARD-42",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13808",
            "district": "7",
            "Block": "60",
            "label": "ANNAMALAIAR MILLS GIRLS' HR.SEC.SCHOOL, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13809",
            "district": "7",
            "Block": "60",
            "label": "KASUVALLUVAN MIDDLE SCHOOL, DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13810",
            "district": "7",
            "Block": "60",
            "label": "METTUPATTI MUNCP HS, WARD-37",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13811",
            "district": "7",
            "Block": "60",
            "label": "ST XAVIER MS,DINDIGUL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13812",
            "district": "7",
            "Block": "60",
            "label": "SOWRASTRA SRI VARADHARAJA HS,WARD 47, DINDIGUL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13813",
            "district": "7",
            "Block": "60",
            "label": "OUR LADY OF LOURDES GIRLS HIGHER SECONDARY SCHOOL, DINDIGUL-624002",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13814",
            "district": "7",
            "Block": "60",
            "label": "TPKN MATRIC HIGHER SECONDARY SCHOOL,BEGAMPUR, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13815",
            "district": "7",
            "Block": "60",
            "label": "LOURDU MATHA MATRICULATION SCHOOL,BEGAMPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21090",
            "district": "7",
            "Block": "60",
            "label": "ACHYUTA ACADEMY CBSE SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21531",
            "district": "7",
            "Block": "60",
            "label": "SRI SAVITHRI VIDHYASALA HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22510",
            "district": "7",
            "Block": "60",
            "label": "SRI KAMARAJAR MATRICULATION SCHOOL, DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22511",
            "district": "7",
            "Block": "60",
            "label": "DNU SMBM PUBLIC SCHOOL(CBSE), DINDIGUL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23685",
            "district": "7",
            "Block": "60",
            "label": "AUSILIUM HIGH SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        }
    ],
    "61": [
        {
            "key": "1193",
            "district": "22",
            "Block": "61",
            "label": "WISDOM MATRICULATION HR. SEC. SCHOOL, IDAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2153",
            "district": "22",
            "Block": "61",
            "label": "RELIANCE MATRIC HR. SEC. SCHOOL,IDAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2159",
            "district": "22",
            "Block": "61",
            "label": "AMALA MATRIC  HR. SEC. SCHOOL,MORASAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2320",
            "district": "22",
            "Block": "61",
            "label": "S.K.T. MATRIC HR. SEC. SCHOOL, POOLAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4021",
            "district": "22",
            "Block": "61",
            "label": "ST.MARY'S MATRIC SCHOOL, VELLANDIVALASU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11371",
            "district": "22",
            "Block": "61",
            "label": "PUMS ADUVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11372",
            "district": "22",
            "Block": "61",
            "label": "GHS ADAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11373",
            "district": "22",
            "Block": "61",
            "label": "PUMS AMMASIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11374",
            "district": "22",
            "Block": "61",
            "label": "GHS IRUPPALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11375",
            "district": "22",
            "Block": "61",
            "label": "GHSS CHETTIMANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11376",
            "district": "22",
            "Block": "61",
            "label": "MODEL SCHOOL, CHETTIMANKURUCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11377",
            "district": "22",
            "Block": "61",
            "label": "PUMS PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11378",
            "district": "22",
            "Block": "61",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11379",
            "district": "22",
            "Block": "61",
            "label": "GHSS CHITTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11380",
            "district": "22",
            "Block": "61",
            "label": "K.G.B.V. CHITTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11381",
            "district": "22",
            "Block": "61",
            "label": "PUMS KUPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11382",
            "district": "22",
            "Block": "61",
            "label": "GHSS POOLAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11383",
            "district": "22",
            "Block": "61",
            "label": "GHSS KONERIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11384",
            "district": "22",
            "Block": "61",
            "label": "GHS NEDUNKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11385",
            "district": "22",
            "Block": "61",
            "label": "PUMS CHINNAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11386",
            "district": "22",
            "Block": "61",
            "label": "GHSS VELLARIVELLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11387",
            "district": "22",
            "Block": "61",
            "label": "AACHARIYA HR. SEC. SCHOOL, VELLARIVELLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11388",
            "district": "22",
            "Block": "61",
            "label": "GHS KUNJAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11389",
            "district": "22",
            "Block": "61",
            "label": "SHEMPARK PUBLIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11390",
            "district": "22",
            "Block": "61",
            "label": "MMS ALACHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11391",
            "district": "22",
            "Block": "61",
            "label": "MMS KAVANDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11392",
            "district": "22",
            "Block": "61",
            "label": "GGHSS EDAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11393",
            "district": "22",
            "Block": "61",
            "label": "PUMS VALAGOUNDANVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11394",
            "district": "22",
            "Block": "61",
            "label": "GBHSS EDAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11395",
            "district": "22",
            "Block": "61",
            "label": "PUMS MANIYAKKARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11396",
            "district": "22",
            "Block": "61",
            "label": "GHS DADAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11397",
            "district": "22",
            "Block": "61",
            "label": "PUMS VEMBANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21140",
            "district": "22",
            "Block": "61",
            "label": "KALAIMAGAL VIDHYASHRAM MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23420",
            "district": "22",
            "Block": "61",
            "label": "Bharathi vidyalaya nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23404",
            "district": "22",
            "Block": "61",
            "label": "Little Star Nursery Primary and High School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "22862",
            "district": "22",
            "Block": "61",
            "label": "GHS KURUKKALPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23420",
            "district": "22",
            "Block": "61",
            "label": "Bharathi vidyalaya nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23420",
            "district": "22",
            "Block": "61",
            "label": "Bharathi vidyalaya nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23420",
            "district": "22",
            "Block": "61",
            "label": "Bharathi vidyalaya nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23420",
            "district": "22",
            "Block": "61",
            "label": "Bharathi vidyalaya nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23773",
            "district": "22",
            "Block": "61",
            "label": "PUMS PUTHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23774",
            "district": "22",
            "Block": "61",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "62": [
        {
            "key": "376",
            "district": "3",
            "Block": "62",
            "label": "DOVETON  BOYS' HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "444",
            "district": "3",
            "Block": "62",
            "label": "SIVAKASI HINDU NADAR  MATRICULATION HR.SEC.SCHOOLL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "654",
            "district": "3",
            "Block": "62",
            "label": "GOVT HOBART MUSLIM GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "682",
            "district": "3",
            "Block": "62",
            "label": "SACRED HEART MAT HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "945",
            "district": "3",
            "Block": "62",
            "label": "PN DHAWAN ADARSH VIDYALAYA MAT HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1359",
            "district": "3",
            "Block": "62",
            "label": "AGARWAL VIDYALAYA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1488",
            "district": "3",
            "Block": "62",
            "label": "VIDYODAYA GIRLS'HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1578",
            "district": "3",
            "Block": "62",
            "label": "ANITA METHODIST MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1750",
            "district": "3",
            "Block": "62",
            "label": "DOVETON  GIRLS' HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1902",
            "district": "3",
            "Block": "62",
            "label": "UNION CHRISTIAN MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2296",
            "district": "3",
            "Block": "62",
            "label": "VIDYODAYA MATRICULATION HIGHER SECIONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3970",
            "district": "3",
            "Block": "62",
            "label": "MINERVA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4057",
            "district": "3",
            "Block": "62",
            "label": "CSI MONAHAN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4073",
            "district": "3",
            "Block": "62",
            "label": "SEVENTH DAY ADVENTIST MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4530",
            "district": "3",
            "Block": "62",
            "label": "KALAIPOONGA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4617",
            "district": "3",
            "Block": "62",
            "label": "CRESCENT MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4628",
            "district": "3",
            "Block": "62",
            "label": "TERAPANTH JAIN VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4679",
            "district": "3",
            "Block": "62",
            "label": "ST ANTONYS ANGLO INDIAN HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "4970",
            "district": "3",
            "Block": "62",
            "label": "MADRAS CHRISTIAN COLLEGE  MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5175",
            "district": "3",
            "Block": "62",
            "label": "OUR LADY'S MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5455",
            "district": "3",
            "Block": "62",
            "label": "CMS V.V .ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5644",
            "district": "3",
            "Block": "62",
            "label": "CMS RAMA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5694",
            "district": "3",
            "Block": "62",
            "label": "LITTLE FLOWER CONVENT - DEAF HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6027",
            "district": "3",
            "Block": "62",
            "label": "RBCCC MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6034",
            "district": "3",
            "Block": "62",
            "label": "ST AUGUSTINE MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6069",
            "district": "3",
            "Block": "62",
            "label": "DHANAKOTI  MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6138",
            "district": "3",
            "Block": "62",
            "label": "THE MADRAS SEVA SADAN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6150",
            "district": "3",
            "Block": "62",
            "label": "CMS VENKATAMMAL SAMATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6193",
            "district": "3",
            "Block": "62",
            "label": "CMS 1A MODEL SCHOOL ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6233",
            "district": "3",
            "Block": "62",
            "label": "DAV GIRLS SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6297",
            "district": "3",
            "Block": "62",
            "label": "Good Shepherd Matric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6360",
            "district": "3",
            "Block": "62",
            "label": "ST ANTONYS MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6413",
            "district": "3",
            "Block": "62",
            "label": "MARY CLUB WALA JADHAV GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6483",
            "district": "3",
            "Block": "62",
            "label": "CMS 3 AP ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6563",
            "district": "3",
            "Block": "62",
            "label": "SMT VALLIAMMAL RAJABHADAR MIIDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6629",
            "district": "3",
            "Block": "62",
            "label": "SATYA MAT HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6648",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI MIDDLE SCHOOL - SPECIAL SCHOOL FOR MUSCULAR DISTROPHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6723",
            "district": "3",
            "Block": "62",
            "label": "SHRI KRISHNASWAMY MATRICULATION  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7370",
            "district": "3",
            "Block": "62",
            "label": "AUXILIUM GIRLS  higher secondary school",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7371",
            "district": "3",
            "Block": "62",
            "label": "Madras Christian College Higher Secondary School",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7372",
            "district": "3",
            "Block": "62",
            "label": "LADY ANDAL VENKATASUBBARAO MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7373",
            "district": "3",
            "Block": "62",
            "label": "CHS PADAVATTAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7374",
            "district": "3",
            "Block": "62",
            "label": "C.M.S. 127 ANGALAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7375",
            "district": "3",
            "Block": "62",
            "label": "C.M.S. 118 ANGALAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23424",
            "district": "3",
            "Block": "62",
            "label": "Olive International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7376",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI HIGH SCHOOL  STRAHANS ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7377",
            "district": "3",
            "Block": "62",
            "label": "THE SALVATION ARMY MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7378",
            "district": "3",
            "Block": "62",
            "label": "CMS 88 TANA ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7379",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI GIRLS HIGHER SECONDARY SCHOOL -ROTLER STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7380",
            "district": "3",
            "Block": "62",
            "label": "CMS VAIKKAKARAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7381",
            "district": "3",
            "Block": "62",
            "label": "CMS 72 AP ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7382",
            "district": "3",
            "Block": "62",
            "label": "SRI MUTHUKUMARASWAMY DEVASTHANAM P.VENUGOPAL CHETTY HIGHER  SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7383",
            "district": "3",
            "Block": "62",
            "label": "ST. JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7384",
            "district": "3",
            "Block": "62",
            "label": "ST. ANDREWS  HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7385",
            "district": "3",
            "Block": "62",
            "label": "CMS JAGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7386",
            "district": "3",
            "Block": "62",
            "label": "CMS SIVARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7387",
            "district": "3",
            "Block": "62",
            "label": "CMS EGMORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7388",
            "district": "3",
            "Block": "62",
            "label": "PRESIDENCY GIRLS HSS -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7389",
            "district": "3",
            "Block": "62",
            "label": "DON BOSCO MATRIC  HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7390",
            "district": "3",
            "Block": "62",
            "label": "CMS VARADARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7391",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI BOYS HR.SEC.SCHOOL,  NUNGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7392",
            "district": "3",
            "Block": "62",
            "label": "CHENNAI GIRLS HR.SEC.SCHOOL -  NUNGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7393",
            "district": "3",
            "Block": "62",
            "label": "PADMA SESHADRI BALA BHAVAN SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7394",
            "district": "3",
            "Block": "62",
            "label": "ASSUMPTION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7395",
            "district": "3",
            "Block": "62",
            "label": "CHS THOUSAND LIGHTS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7396",
            "district": "3",
            "Block": "62",
            "label": "ASAN MEMORIAL SENIOR SECONDARY SCHOOLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7397",
            "district": "3",
            "Block": "62",
            "label": "ASAN MEMORIAL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7398",
            "district": "3",
            "Block": "62",
            "label": "LITTLE FLOWER CONVENT - BLIND",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7399",
            "district": "3",
            "Block": "62",
            "label": "ST URSULA'S ANGLO-INDIAN  HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7400",
            "district": "3",
            "Block": "62",
            "label": "IMMACULATE HEART OF MARY MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7401",
            "district": "3",
            "Block": "62",
            "label": "DAV BOYS SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7402",
            "district": "3",
            "Block": "62",
            "label": "SRI SARADHA SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7403",
            "district": "3",
            "Block": "62",
            "label": "NATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7404",
            "district": "3",
            "Block": "62",
            "label": "CMS VELLALA TEYNAMPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7405",
            "district": "3",
            "Block": "62",
            "label": "DR AMBEDKAR GOVT HR.SEC.SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7406",
            "district": "3",
            "Block": "62",
            "label": "ST JOSEPH'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21351",
            "district": "3",
            "Block": "62",
            "label": "DOVETON GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21352",
            "district": "3",
            "Block": "62",
            "label": "DOVETON BOYS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21887",
            "district": "3",
            "Block": "62",
            "label": "SIR MUTHA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21939",
            "district": "3",
            "Block": "62",
            "label": "DON BOSCO SCHOOL OF EXCELLENCE EGMORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22012",
            "district": "3",
            "Block": "62",
            "label": "UNION CHRISTIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22845",
            "district": "3",
            "Block": "62",
            "label": "MCC PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23009",
            "district": "3",
            "Block": "62",
            "label": "AGARWAL VIDYALAYA AND JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23083",
            "district": "3",
            "Block": "62",
            "label": "LADY ANDAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23345",
            "district": "3",
            "Block": "62",
            "label": "P.N.D. ADARSH VIDYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23424",
            "district": "3",
            "Block": "62",
            "label": "Olive International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23424",
            "district": "3",
            "Block": "62",
            "label": "Olive International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23424",
            "district": "3",
            "Block": "62",
            "label": "Olive International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23454",
            "district": "3",
            "Block": "62",
            "label": "Government Model School, Chennai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23424",
            "district": "3",
            "Block": "62",
            "label": "Olive International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23454",
            "district": "3",
            "Block": "62",
            "label": "Government Model School, Chennai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        }
    ],
    "63": [
        {
            "key": "2475",
            "district": "17",
            "Block": "63",
            "label": "SHRI VIDHYABHARATHI MATRIC HIGHER SECONDARY SCHOOL, SAKKARAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3564",
            "district": "17",
            "Block": "63",
            "label": "SHRI RENGA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL,RAYARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3588",
            "district": "17",
            "Block": "63",
            "label": "PUMS PACH.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4029",
            "district": "17",
            "Block": "63",
            "label": "SHRI RAMACHANDIRA MATRIC HR SEC SCHOOL,ELACHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11963",
            "district": "17",
            "Block": "63",
            "label": "SENGUNTHAR MATRICULATION HIGHER SECONDARY SCHOOL, KOSAVAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11964",
            "district": "17",
            "Block": "63",
            "label": "MAHENDRA MATRIC HIGHER SECONDARY SCHOOL, KUMARAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11965",
            "district": "17",
            "Block": "63",
            "label": "PUMS SATHINAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11966",
            "district": "17",
            "Block": "63",
            "label": "GHS KUPPANDAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11967",
            "district": "17",
            "Block": "63",
            "label": "VIVEKANANDHA VIDHYA BHAVAN MATRIC HR.SEC.SCHOOL  ELAYAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11968",
            "district": "17",
            "Block": "63",
            "label": "GHSS ULAGAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11969",
            "district": "17",
            "Block": "63",
            "label": "SHRI RENGA VIDYALAYA HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11970",
            "district": "17",
            "Block": "63",
            "label": "T P P GHSS MANALI JEDARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11971",
            "district": "17",
            "Block": "63",
            "label": "STAR MATRIC HIGHER SECONDARY SCHOOL, PERIYAMANALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11972",
            "district": "17",
            "Block": "63",
            "label": "GHSS ELACHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11973",
            "district": "17",
            "Block": "63",
            "label": "SHRI VIDHYABHARATHI HIGH SCHOOL SAKKARAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11974",
            "district": "17",
            "Block": "63",
            "label": "PUMS NALLAKUMARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11975",
            "district": "17",
            "Block": "63",
            "label": "ST.THERASAS MIDDLE SCHOOL  ELANAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11976",
            "district": "17",
            "Block": "63",
            "label": "GHSS MANICKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11977",
            "district": "17",
            "Block": "63",
            "label": "GHS ILUPPILI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11978",
            "district": "17",
            "Block": "63",
            "label": "PUMS KOKKALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11979",
            "district": "17",
            "Block": "63",
            "label": "GHSS PERIYAMANALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11980",
            "district": "17",
            "Block": "63",
            "label": "GHSS VELAGOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11981",
            "district": "17",
            "Block": "63",
            "label": "KONGUNADU MATRIC HSS VELAGOUNDAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11982",
            "district": "17",
            "Block": "63",
            "label": "GHSS BOMMAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22452",
            "district": "17",
            "Block": "63",
            "label": "GHS KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22809",
            "district": "17",
            "Block": "63",
            "label": "KONGUNADU SCHOOL  VELAGOUNDAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23194",
            "district": "17",
            "Block": "63",
            "label": "SHRI RAMACHANDIRA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "64": [
        {
            "key": "532",
            "district": "33",
            "Block": "64",
            "label": "SRI LAKSHMI VITHYALAYA MATRIC HR SEC SCHOOL, LACHIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "541",
            "district": "33",
            "Block": "64",
            "label": "ST. JOSEPH MATRICULATION SCHOOL, PERIYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "603",
            "district": "33",
            "Block": "64",
            "label": "J.N.N MATRIC HIGHER SECONDARY SCHOOL, KANNIGAIPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "629",
            "district": "33",
            "Block": "64",
            "label": "SRI SANKARA MATRIC HIGHER SECONDARY SCHOOL, THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1097",
            "district": "33",
            "Block": "64",
            "label": "PUMS, SOOLAIMENI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1244",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PERAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1257",
            "district": "33",
            "Block": "64",
            "label": "PUMS, SENNANKARANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1345",
            "district": "33",
            "Block": "64",
            "label": "VICTORIA MATRIC HIGHER SECONDARY SCHOOL, PERIYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1365",
            "district": "33",
            "Block": "64",
            "label": "DAYSPRING MATRICULATION HIGHER SECONDARY SCHOOL, VANIANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1574",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PANAYANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1584",
            "district": "33",
            "Block": "64",
            "label": "VIVEKANANDA MATRIC HR SEC SCHOOL, UTHUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1660",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PERANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1729",
            "district": "33",
            "Block": "64",
            "label": "PUMS, THARATCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1783",
            "district": "33",
            "Block": "64",
            "label": "HARIHARA VIDYALAYA MATRICULATION SCHOOL, THAMARAIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1879",
            "district": "33",
            "Block": "64",
            "label": "PUMS, DR. AMBEDKAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1890",
            "district": "33",
            "Block": "64",
            "label": "VISWESHWARA MATRICULATION SCHOOL, UTHUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1971",
            "district": "33",
            "Block": "64",
            "label": "SREE SAI MATRICULATION SCHOOL , VENGAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2027",
            "district": "33",
            "Block": "64",
            "label": "PUMS, AKKARAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2171",
            "district": "33",
            "Block": "64",
            "label": "PUMS, MELMALIGAIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2398",
            "district": "33",
            "Block": "64",
            "label": "PUMS, NEYVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2668",
            "district": "33",
            "Block": "64",
            "label": "PUMS, RALLAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2673",
            "district": "33",
            "Block": "64",
            "label": "PUMS, 43 PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2691",
            "district": "33",
            "Block": "64",
            "label": "PUMS, MANJANKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2952",
            "district": "33",
            "Block": "64",
            "label": "DON BOSCO MATRIC  HR SEC SCHOOL, UTHUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2962",
            "district": "33",
            "Block": "64",
            "label": "PUMS, MAGARAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3059",
            "district": "33",
            "Block": "64",
            "label": "PUMS, POORIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3084",
            "district": "33",
            "Block": "64",
            "label": "PUMS, SEMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3099",
            "district": "33",
            "Block": "64",
            "label": "PUMS, THAMARAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3637",
            "district": "33",
            "Block": "64",
            "label": "PUMS, AYALACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4052",
            "district": "33",
            "Block": "64",
            "label": "PUMS, 82 PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5212",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PUNNAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6570",
            "district": "33",
            "Block": "64",
            "label": "PUMS, ATTHANGIKAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6808",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, ATHIVAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6809",
            "district": "33",
            "Block": "64",
            "label": "GHS, BOOCHIATHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6810",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGHER SECONDARY SCHOOL, GURUVOYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6811",
            "district": "33",
            "Block": "64",
            "label": "GOVT HSS SCHOOL, KANNIGAIPAIR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6812",
            "district": "33",
            "Block": "64",
            "label": "KRUSHNAA VIDHYALAYA NURSERY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6813",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, KOMAKKAMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6814",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, MALANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6815",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, PALAVAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6816",
            "district": "33",
            "Block": "64",
            "label": "GOVT HSS, PERIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6817",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, THIRUKKANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6818",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, THANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6819",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGH SCHOOL, VADAMADURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6820",
            "district": "33",
            "Block": "64",
            "label": "GOVT HIGHER SECONDARY SCHOOL, VENGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6821",
            "district": "33",
            "Block": "64",
            "label": "KOTHANDARAMAN HIGH SCHOOL, UTHUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "6822",
            "district": "33",
            "Block": "64",
            "label": "GOVT B HSS, UTHUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6823",
            "district": "33",
            "Block": "64",
            "label": "GOVT G HSS, UTHUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21000",
            "district": "33",
            "Block": "64",
            "label": "SUGUNAMMAL  MATRIC SCHOOL VENGAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21632",
            "district": "33",
            "Block": "64",
            "label": "G.T.VIDHYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21714",
            "district": "33",
            "Block": "64",
            "label": "J.N.N VIDYALLAYA ,KANNIGAIPER",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23775",
            "district": "33",
            "Block": "64",
            "label": "PUMS, PUNNAPAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23776",
            "district": "33",
            "Block": "64",
            "label": "PUMS, SEMBEDU",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "65": [
        {
            "key": "546",
            "district": "8",
            "Block": "65",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION HS, PATTAKARA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "575",
            "district": "8",
            "Block": "65",
            "label": "SHRI JANANI MATRICULATION HIGHER SECONDARY SCHOOL, ELLAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "579",
            "district": "8",
            "Block": "65",
            "label": "VELLALAR GIRLS MATRICULATION HSS, THINDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "599",
            "district": "8",
            "Block": "65",
            "label": "SRI VASAVI MATRICULATION SCHOOL, ERODE-638316",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "617",
            "district": "8",
            "Block": "65",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, TEACHERSCOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "618",
            "district": "8",
            "Block": "65",
            "label": "PUMS, B.P.AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "620",
            "district": "8",
            "Block": "65",
            "label": "CMS, RAILWAY COLONY -2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "621",
            "district": "8",
            "Block": "65",
            "label": "PUMS METTUNASUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23398",
            "district": "8",
            "Block": "65",
            "label": "CSI Brough Matriculation school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "623",
            "district": "8",
            "Block": "65",
            "label": "PUMS, ATTAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23369",
            "district": "8",
            "Block": "65",
            "label": "Nandha Matric Hr.Sec. School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "630",
            "district": "8",
            "Block": "65",
            "label": "PUMS, MANICKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "647",
            "district": "8",
            "Block": "65",
            "label": "PUMS, EPB NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "658",
            "district": "8",
            "Block": "65",
            "label": "THE INDIAN PUBLIC SCHOOL,SENAPATHYPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "707",
            "district": "8",
            "Block": "65",
            "label": "KALAIMAGAL KALVI NILAYAM GIRLS' MATRICULATION",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "724",
            "district": "8",
            "Block": "65",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KOORAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "766",
            "district": "8",
            "Block": "65",
            "label": "ERODE HINDU KALVI NILAYAM MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "806",
            "district": "8",
            "Block": "65",
            "label": "PUMS, INDIRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "839",
            "district": "8",
            "Block": "65",
            "label": "AL AMEEN MATRICULATION HSS, CAUVERY ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "864",
            "district": "8",
            "Block": "65",
            "label": "SENGUNTHAR VIDHYALAYA MATRICULATION SCHOOL, ERODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "967",
            "district": "8",
            "Block": "65",
            "label": "U.R.C. PALANIAMMAL MATRICULATION HSS, THINDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "986",
            "district": "8",
            "Block": "65",
            "label": "VELALAR VIDYALAYAA SENIOR SECONDARY SCHOOL,THINDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "998",
            "district": "8",
            "Block": "65",
            "label": "PUMS MANAKKATUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1157",
            "district": "8",
            "Block": "65",
            "label": "C.S ACADEMY, KAVANDACHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1203",
            "district": "8",
            "Block": "65",
            "label": "BHARATHI VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL, THINDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1263",
            "district": "8",
            "Block": "65",
            "label": "SRI SARASWATHI VIDHYALAYA Mat.HSS,MAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1271",
            "district": "8",
            "Block": "65",
            "label": "ASHRAM MATRICULATION HSS, KOLLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1300",
            "district": "8",
            "Block": "65",
            "label": "CMS, SKC ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1302",
            "district": "8",
            "Block": "65",
            "label": "KONGU NATIONAL MATRICULATION HSS, NANJANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1303",
            "district": "8",
            "Block": "65",
            "label": "CARMEL MATRICULATION HSS, KOLLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1347",
            "district": "8",
            "Block": "65",
            "label": "PUMS SENGUNTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1348",
            "district": "8",
            "Block": "65",
            "label": "NANDHA CENTRAL SCHOOL, KOORAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1352",
            "district": "8",
            "Block": "65",
            "label": "PUMS, SUKKIRAMANIAVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1367",
            "district": "8",
            "Block": "65",
            "label": "S.V.N. MATRICULATION HSS, CHITTODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1373",
            "district": "8",
            "Block": "65",
            "label": "GEETHAANJALI ALL INDIA SR.SEC. SCHOOL, THINDAL,ERODE-638012",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1379",
            "district": "8",
            "Block": "65",
            "label": "PUMS,JAVULI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1392",
            "district": "8",
            "Block": "65",
            "label": "RAILWAY SENIOR SECONDARY SCHOOL(CBSE), RAILWAY COLONY,ERODE",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1400",
            "district": "8",
            "Block": "65",
            "label": "THE B.V.B.SCHOOL, THERKUPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1434",
            "district": "8",
            "Block": "65",
            "label": "PUMS,PERUMALMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1435",
            "district": "8",
            "Block": "65",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ODAKKATTUVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1459",
            "district": "8",
            "Block": "65",
            "label": "PUMS, ASHOKAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1581",
            "district": "8",
            "Block": "65",
            "label": "A.E.T.MATRICULATION HSS, KATHIRAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1621",
            "district": "8",
            "Block": "65",
            "label": "GRACE MATRIC HSS, GRACE NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1678",
            "district": "8",
            "Block": "65",
            "label": "PUMS,SSP NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1773",
            "district": "8",
            "Block": "65",
            "label": "CMS, CAUVERY ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1774",
            "district": "8",
            "Block": "65",
            "label": "PUMS, ELLAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1797",
            "district": "8",
            "Block": "65",
            "label": "PUMS, VAIRAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1940",
            "district": "8",
            "Block": "65",
            "label": "GRACE INTERNATIONAL SCHOOL, ELAVAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1942",
            "district": "8",
            "Block": "65",
            "label": "KONGU KALVINILAYAM MATRICULATION HSS, RANGAMPALAY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1982",
            "district": "8",
            "Block": "65",
            "label": "PUMS, KONGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2031",
            "district": "8",
            "Block": "65",
            "label": "CHRISTHU JYOTHI MATRICULATION HSS, B.P. AGRAHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2071",
            "district": "8",
            "Block": "65",
            "label": "THONDAR SEER PARAVUVAR THANGAVELANAAR ILAIGAR KALVI NILAYAM MIDDLE SCHOOL, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2149",
            "district": "8",
            "Block": "65",
            "label": "PUMS, THANNEER PANDHAL PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2154",
            "district": "8",
            "Block": "65",
            "label": "CMS, THIRUNAGAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2208",
            "district": "8",
            "Block": "65",
            "label": "INDU INTERNATIONAL SCHOOL, VANNANKATTU VALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2264",
            "district": "8",
            "Block": "65",
            "label": "KONGU VIDHYALAYA MATRICULATION SCHOOL, MANICKAMPALAYAM, ERODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2285",
            "district": "8",
            "Block": "65",
            "label": "AL AMEEN METRICULATION HSS, VILLARASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2329",
            "district": "8",
            "Block": "65",
            "label": "NIRMALA MATHA CONVENT MATRICULATION HSS, MOOLAKA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2372",
            "district": "8",
            "Block": "65",
            "label": "AMS,LALITHA KALVI NILAYAM, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2388",
            "district": "8",
            "Block": "65",
            "label": "SRI SATHYA SAI MATRICULATION HIGHER SECONDARY SCHOOL, CHITTODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2432",
            "district": "8",
            "Block": "65",
            "label": "AMS, RAGUPATHINAIKEN PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2499",
            "district": "8",
            "Block": "65",
            "label": "PUMS MOLAGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2897",
            "district": "8",
            "Block": "65",
            "label": "SRI MARUTHI VIDHYA BHAVAN MATRICULATION HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3224",
            "district": "8",
            "Block": "65",
            "label": "MOTHERS MATRIC HIGHER SECONDARY SCHOOL., KALINGARAYANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6035",
            "district": "8",
            "Block": "65",
            "label": "SRI KRP ACADEMY, CHITTODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12138",
            "district": "8",
            "Block": "65",
            "label": "GHSS, CHITTODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12139",
            "district": "8",
            "Block": "65",
            "label": "GGHSS, CHITHODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12140",
            "district": "8",
            "Block": "65",
            "label": "SEVENTHDAY ADVENTIST MATRICULATION SCHOOL,CHITHODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12141",
            "district": "8",
            "Block": "65",
            "label": "SAKTHI HS, CHITTODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12142",
            "district": "8",
            "Block": "65",
            "label": "GRACE HIGHER SECONDARY SCHOOL, ELAVAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12143",
            "district": "8",
            "Block": "65",
            "label": "GHSS B..P. AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12144",
            "district": "8",
            "Block": "65",
            "label": "MATHARASA HITHAYATHUL ISLAM GIRLS HIGH SCHOOL, B.P. AGRAHARAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12145",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VEERAPPANCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12146",
            "district": "8",
            "Block": "65",
            "label": "GHS, CHINNASEMUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12147",
            "district": "8",
            "Block": "65",
            "label": "GHS, SENGODAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12148",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT HIGH SCHOOL FOR HEARING IMPAIRED, R.N. PUDUR,ERODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12149",
            "district": "8",
            "Block": "65",
            "label": "GHSS, RAMANATHAPURAM PUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12150",
            "district": "8",
            "Block": "65",
            "label": "AHSS, BHARATHI KALVI NILAYAM, NASIYANUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12151",
            "district": "8",
            "Block": "65",
            "label": "VELLALAR GIRLS HIGH SCHOOL , THINDAL-638012",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23403",
            "district": "8",
            "Block": "65",
            "label": "Yuvabharathi global school( CBSE)",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "12152",
            "district": "8",
            "Block": "65",
            "label": "THE ERODE ARIMA SOCIETY TRUST SPECIAL SCHOOL, SENGODAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "12153",
            "district": "8",
            "Block": "65",
            "label": "KONGU ARIVALAYAM,  MR-CHILDREN SCHOOL, SENGODAMPAL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "12154",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THINDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12155",
            "district": "8",
            "Block": "65",
            "label": "SREE AMMAN MATRICULATION HSS,EVK SAMPATH NAGAR, ERODE-11",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12156",
            "district": "8",
            "Block": "65",
            "label": "GHSS, NANJAPPA GOUNDAN VALASU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12157",
            "district": "8",
            "Block": "65",
            "label": "KALAIMAGAL KALVI NILAYAM GIRLS HSS, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12158",
            "district": "8",
            "Block": "65",
            "label": "CHS, E.K.VALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12159",
            "district": "8",
            "Block": "65",
            "label": "AHS, ST MARY'S, ERODE",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12160",
            "district": "8",
            "Block": "65",
            "label": "AHS, ST.RITA GIRLS, RAILWAY COLONY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12161",
            "district": "8",
            "Block": "65",
            "label": "MEENAKSHI SUNDARANAR SENGUNTHAR HR SEC SCHOOL,RANGAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12162",
            "district": "8",
            "Block": "65",
            "label": "DEIVANAI PERUMAL HS, MOLAGOUNDANPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12163",
            "district": "8",
            "Block": "65",
            "label": "GHSS, KASIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12164",
            "district": "8",
            "Block": "65",
            "label": "SRI SAKTHI VIDHYA NIKETHAN MATRIC HSS,RANGAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12165",
            "district": "8",
            "Block": "65",
            "label": "GHSS, KUMALANKUTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12166",
            "district": "8",
            "Block": "65",
            "label": "NANDHI MATRICULATION HSS, CAUVERY STREET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12167",
            "district": "8",
            "Block": "65",
            "label": "V.V.C.R.MURUGESANAR SENGUNTHAR GIRLS HR.SEC.SCHOOL,ERODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12168",
            "district": "8",
            "Block": "65",
            "label": "SIDDHARTHA MATRICULATION HSS, THIRUNAGAR COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12169",
            "district": "8",
            "Block": "65",
            "label": "MPL (GIRLS) KARUNGALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12170",
            "district": "8",
            "Block": "65",
            "label": "MRG GGHSS, VEERAPPANCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12171",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, ERODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12172",
            "district": "8",
            "Block": "65",
            "label": "AHSS, C.S.I. GIRLS, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12173",
            "district": "8",
            "Block": "65",
            "label": "AHSS, C.S.I. BOYS, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12174",
            "district": "8",
            "Block": "65",
            "label": "AHSS, ATM, VAIRAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12175",
            "district": "8",
            "Block": "65",
            "label": "CHSS, RAILWAY COLONY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12176",
            "district": "8",
            "Block": "65",
            "label": "CHSS, KAMARAJ MUNICIPAL BOYS, KARUNGALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12177",
            "district": "8",
            "Block": "65",
            "label": "E.K.M. ABDUL GANI MATHARASA ISLAMIA HIGH SCHOOL,ERODE",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12178",
            "district": "8",
            "Block": "65",
            "label": "MAHAJANA HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12179",
            "district": "8",
            "Block": "65",
            "label": "THE SENGUNTHAR HIGHER SECONDARY SCHOOL, ERODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20902",
            "district": "8",
            "Block": "65",
            "label": "SAKTHI  SPECIAL SCHOO FOR MENTALLY RETARDEDL, MAMARATHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "20903",
            "district": "8",
            "Block": "65",
            "label": "GOKULAM SPECIAL SCHOOL FOR MENTALLY RETARDED, MUTHAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "21143",
            "district": "8",
            "Block": "65",
            "label": "MOUNT CARMEL INTERNATIONAL SCHOOL, KOLLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21634",
            "district": "8",
            "Block": "65",
            "label": "C.E.T. MATRICULATION SCHOOL, VETTUKATTUVALASU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22126",
            "district": "8",
            "Block": "65",
            "label": "AN NOOR MATRICULATION SCHOOL, SUNNAMPU ODAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22127",
            "district": "8",
            "Block": "65",
            "label": "NANDHA CENTRAL CITY SCHOOL, NARAYANAVALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22128",
            "district": "8",
            "Block": "65",
            "label": "THE INDIAN PUBLIC SCHOOL (CAMBRIDGE INTERNATIONAL CAMPUS), SENAPATHY PALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23097",
            "district": "8",
            "Block": "65",
            "label": "SRI CHAITANYA TECHNO SCHOOL, THINDAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23250",
            "district": "8",
            "Block": "65",
            "label": "GOVERNMENT HIGH SCHOOL, PERIYAVALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23331",
            "district": "8",
            "Block": "65",
            "label": "VELAMMAL BODHI CAMPUS, MELTHINDAL, ERODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23481",
            "district": "8",
            "Block": "65",
            "label": "ERODE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23482",
            "district": "8",
            "Block": "65",
            "label": "SIFT MODEL MIDDLE SCHOOL, ERODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23481",
            "district": "8",
            "Block": "65",
            "label": "ERODE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23482",
            "district": "8",
            "Block": "65",
            "label": "SIFT MODEL MIDDLE SCHOOL, ERODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23777",
            "district": "8",
            "Block": "65",
            "label": "VELS GLOBAL SCHOOL,KOLLAMPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23778",
            "district": "8",
            "Block": "65",
            "label": "HINDU GLOBAL SCHOOL , MAMARATHUPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23779",
            "district": "8",
            "Block": "65",
            "label": "AATHICHUDI SCHOOL FOR DYSLEXIA, E.K VALASU",
            "Sch_type": "Un-aidedOthers"
        }
    ],
    "66": [
        {
            "key": "1577",
            "district": "17",
            "Block": "66",
            "label": "PUMS KOOLIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1692",
            "district": "17",
            "Block": "66",
            "label": "PUMS M METTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2453",
            "district": "17",
            "Block": "66",
            "label": "PUMS KALICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3092",
            "district": "17",
            "Block": "66",
            "label": "PUMS VADUGAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4097",
            "district": "17",
            "Block": "66",
            "label": "PUMS THOOSUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4395",
            "district": "17",
            "Block": "66",
            "label": "SRI VIVEKANANDHA VIDHYAALAYA MATRICULATION SCHOOL, ERUMAPATTI, NAMAKKAL DT -637021",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4997",
            "district": "17",
            "Block": "66",
            "label": "PUMS KASTHURIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5220",
            "district": "17",
            "Block": "66",
            "label": "PUMS NAVALADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5226",
            "district": "17",
            "Block": "66",
            "label": "PUMS A VALAVANTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11808",
            "district": "17",
            "Block": "66",
            "label": "BHARATHI HIGHER SECONDARY SCHOOL REDDIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11809",
            "district": "17",
            "Block": "66",
            "label": "BHARATHI VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, REDDIPATTI, NAMAKKAL DT -637002",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11810",
            "district": "17",
            "Block": "66",
            "label": "SRI MAHABHARATHI HIGHER SECONDARY SCHOOL, KOOLIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11811",
            "district": "17",
            "Block": "66",
            "label": "GHSS MUTHUKAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11812",
            "district": "17",
            "Block": "66",
            "label": "GHS BODINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11813",
            "district": "17",
            "Block": "66",
            "label": "GHSS PALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11814",
            "district": "17",
            "Block": "66",
            "label": "ADW HIGH SCH A.PALAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11815",
            "district": "17",
            "Block": "66",
            "label": "GHSS POTTIREDDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11816",
            "district": "17",
            "Block": "66",
            "label": "GHS ALANGANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11817",
            "district": "17",
            "Block": "66",
            "label": "GHS PAVITHRAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11818",
            "district": "17",
            "Block": "66",
            "label": "GHS PAVITHRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11819",
            "district": "17",
            "Block": "66",
            "label": "GHSS VARAGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11820",
            "district": "17",
            "Block": "66",
            "label": "PUMS DEVARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11821",
            "district": "17",
            "Block": "66",
            "label": "PUMS VARATHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11822",
            "district": "17",
            "Block": "66",
            "label": "OXFORD MATRICULATION HIGHER SECONDARY SCHOOL, KAIKATTI, ERUMAPATTI, NAMAKKAL DT -637021",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11823",
            "district": "17",
            "Block": "66",
            "label": "GBHSS ERUMAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11824",
            "district": "17",
            "Block": "66",
            "label": "GGHSS ERUMAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11825",
            "district": "17",
            "Block": "66",
            "label": "GHSS KAVAKKARANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11826",
            "district": "17",
            "Block": "66",
            "label": "GHS JAMBUMADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11827",
            "district": "17",
            "Block": "66",
            "label": "GHSS SEVINTHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21665",
            "district": "17",
            "Block": "66",
            "label": "BHARATHI ACADEMY,  REDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23780",
            "district": "17",
            "Block": "66",
            "label": "SRI CHAITANYA TECHNO SCHOOL, KOOLIPATTY",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "67": [
        {
            "key": "1404",
            "district": "19",
            "Block": "67",
            "label": "VIDYA VIKAS MATRIC HIGHER SEC SCHOOL, PUDUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4823",
            "district": "19",
            "Block": "67",
            "label": "ARULMARY MAT.SCHOOL, GANDARVAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16881",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SOLAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16882",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VIRALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16883",
            "district": "19",
            "Block": "67",
            "label": "ST MARY'S HIGHER SECONDARY  SCHOOL, THACHANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16884",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16885",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANJAPPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16886",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL, KOMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16887",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIYANIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16888",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL, PUDUNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16889",
            "district": "19",
            "Block": "67",
            "label": "PUMS-PALAYAGANDARVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16890",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MID SCHOOL, MEIKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16891",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VEERADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16892",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUDUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16893",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL, KULATHURNAYAKKARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16894",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16895",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MOHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16896",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHAGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16897",
            "district": "19",
            "Block": "67",
            "label": "PUMS. VEMBANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16898",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTUNAVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16899",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16900",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SANGAMVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16901",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,KALLAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16902",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL,VELLALAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16903",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16904",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT HIGH SCHOOL, VELADIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16905",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KENDAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16906",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16907",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MATTANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16908",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT GIRLS HR SEC SCHOOL, GANDARVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16909",
            "district": "19",
            "Block": "67",
            "label": "GOVERNMENT BOYS HR SEC SCHOOL, GANDARVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16910",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GANDARVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16911",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MULLIKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16912",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AKKACHIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16913",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAGATTUVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21105",
            "district": "19",
            "Block": "67",
            "label": "VIDYA VIKAS INTER.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21481",
            "district": "19",
            "Block": "67",
            "label": "GHS-THUVAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21482",
            "district": "19",
            "Block": "67",
            "label": "GHS PALLAVARAYANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21753",
            "district": "19",
            "Block": "67",
            "label": "VIDYA VIKAS BOYS MAT.HR.SECSCHOOL\tPUDUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21981",
            "district": "19",
            "Block": "67",
            "label": "CHRIST THE KING MATRICULATION SCHOOL , GANDARVAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22588",
            "district": "19",
            "Block": "67",
            "label": "GOVT HIGH SCHOOL PUNALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22925",
            "district": "19",
            "Block": "67",
            "label": "GOVT HIGH SCHOOL ATHANGARAIVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23781",
            "district": "19",
            "Block": "67",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "68": [
        {
            "key": "1380",
            "district": "22",
            "Block": "68",
            "label": "SWAMY VIVEKANANDA VIDYALAYA MATRIC SCHOOL, SENDARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2744",
            "district": "22",
            "Block": "68",
            "label": "SARASWATHI MATRIC SCHOOL, ULIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3655",
            "district": "22",
            "Block": "68",
            "label": "LUCKY MATRIC SCHOOL, THAMMAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4893",
            "district": "22",
            "Block": "68",
            "label": "BHARATHI MATRIC HR. SEC. SCHOOL, THAMMAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6061",
            "district": "22",
            "Block": "68",
            "label": "SRI DEVI VIDHYALAYA MATRIC HR. SEC. SCHOOL, ANAIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6289",
            "district": "22",
            "Block": "68",
            "label": "NATIONAL MATRIC HR. SEC. SCHOOL, ANAIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10952",
            "district": "22",
            "Block": "68",
            "label": "PUMS, MOOKAGOUNDANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10953",
            "district": "22",
            "Block": "68",
            "label": "GHS ULIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10954",
            "district": "22",
            "Block": "68",
            "label": "PUMS, NAGIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10955",
            "district": "22",
            "Block": "68",
            "label": "PUMS, THANEERTHOTTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10956",
            "district": "22",
            "Block": "68",
            "label": "VIDYO AMIRTHAS PUBLIC SCHOOL, NAGIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10957",
            "district": "22",
            "Block": "68",
            "label": "PUMS, MOOLAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10958",
            "district": "22",
            "Block": "68",
            "label": "GHSS KONDAIYAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10959",
            "district": "22",
            "Block": "68",
            "label": "GBHS SENTHARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10960",
            "district": "22",
            "Block": "68",
            "label": "GGHSS SENTHARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10961",
            "district": "22",
            "Block": "68",
            "label": "ST.MARY'S MIDDLE SCHOOL, KONERIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10962",
            "district": "22",
            "Block": "68",
            "label": "PUMS, THAMMAMPATTI 12th WARD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10963",
            "district": "22",
            "Block": "68",
            "label": "PUMS, GANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10964",
            "district": "22",
            "Block": "68",
            "label": "GBHSS THAMAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10965",
            "district": "22",
            "Block": "68",
            "label": "GGHSS THAMAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10966",
            "district": "22",
            "Block": "68",
            "label": "PUMS, VALAKKOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10967",
            "district": "22",
            "Block": "68",
            "label": "GOVT HSS GUDAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10968",
            "district": "22",
            "Block": "68",
            "label": "SREE VIDHYA VIKAS MATRIC HR. SEC. SCHOOL, GUDAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10969",
            "district": "22",
            "Block": "68",
            "label": "NEW INDIA MATRIC SCHOOL, GUDAMALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10970",
            "district": "22",
            "Block": "68",
            "label": "PUMS, 74 KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10971",
            "district": "22",
            "Block": "68",
            "label": "GTR HS ODAIKATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10972",
            "district": "22",
            "Block": "68",
            "label": "GHS KADAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10973",
            "district": "22",
            "Block": "68",
            "label": "PUMS, GANGAVALLI 10th WARD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10974",
            "district": "22",
            "Block": "68",
            "label": "GBHSS GANGAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10975",
            "district": "22",
            "Block": "68",
            "label": "GGHSS GANGAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10976",
            "district": "22",
            "Block": "68",
            "label": "SRI SARADHA MANDIR MATRIC HR. SEC. SCHOOL, GANGAVALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10977",
            "district": "22",
            "Block": "68",
            "label": "PUMS, ODHIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10978",
            "district": "22",
            "Block": "68",
            "label": "PUMS, GANGAVALLI MAIN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10979",
            "district": "22",
            "Block": "68",
            "label": "GHSS THEDAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21019",
            "district": "22",
            "Block": "68",
            "label": "AIM MATRIC HR. SEC. SCHOOL, NAGIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21139",
            "district": "22",
            "Block": "68",
            "label": "G.E.T. EXCELLENT MATRIC HR. SEC. SCHOOL, NADUVALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21241",
            "district": "22",
            "Block": "68",
            "label": "KAMARAJAR MATRIC HR. SEC. SCHOOL, THEDAVOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21644",
            "district": "22",
            "Block": "68",
            "label": "KAMARAJAR PUBLIC SCHOOL, THEDAVOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21974",
            "district": "22",
            "Block": "68",
            "label": "SRI VIDHYA BHARATHI MATRIC HR. SEC. SCHOOL, NADUVALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22107",
            "district": "22",
            "Block": "68",
            "label": "G.E.T. SCHOOL OF EXCELLENCE, NADUVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22859",
            "district": "22",
            "Block": "68",
            "label": "GHS NADUVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23143",
            "district": "22",
            "Block": "68",
            "label": "GTR HIGH SCHOOL, KODUNGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23782",
            "district": "22",
            "Block": "68",
            "label": "PUMS, GANDHI NAGAR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "69": [
        {
            "key": "445",
            "district": "3",
            "Block": "69",
            "label": "LITTLE FLOWERS MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "472",
            "district": "3",
            "Block": "69",
            "label": "DON BOSCO MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "542",
            "district": "3",
            "Block": "69",
            "label": "DON BOSCO MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "656",
            "district": "3",
            "Block": "69",
            "label": "SEETHAKKATHI MATRIC HR SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1051",
            "district": "3",
            "Block": "69",
            "label": "ECI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1485",
            "district": "3",
            "Block": "69",
            "label": "ST JOSEPH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1622",
            "district": "3",
            "Block": "69",
            "label": "SRISHTI INTERNATIONAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1936",
            "district": "3",
            "Block": "69",
            "label": "TEMPLE PARK MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1992",
            "district": "3",
            "Block": "69",
            "label": "DR RADHAKRISHNAN MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3549",
            "district": "3",
            "Block": "69",
            "label": "CHENNAI PORT & DOCK EDUCATIONAL TRUST HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3886",
            "district": "3",
            "Block": "69",
            "label": "SACRED HEART MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4046",
            "district": "3",
            "Block": "69",
            "label": "SRI SAYEE VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4329",
            "district": "3",
            "Block": "69",
            "label": "NORTH MADRAS SOCIAL SERVICE MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4910",
            "district": "3",
            "Block": "69",
            "label": "Angels Babyland Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5169",
            "district": "3",
            "Block": "69",
            "label": "NORTH MADRAS MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5273",
            "district": "3",
            "Block": "69",
            "label": "VELANKANNI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5746",
            "district": "3",
            "Block": "69",
            "label": "ST JOSEPH MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5763",
            "district": "3",
            "Block": "69",
            "label": "NETHAJI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5812",
            "district": "3",
            "Block": "69",
            "label": "CMS RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5912",
            "district": "3",
            "Block": "69",
            "label": "CMS CHINNANDIMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6020",
            "district": "3",
            "Block": "69",
            "label": "BCS JAIN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6063",
            "district": "3",
            "Block": "69",
            "label": "OUR LADY'S MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6100",
            "district": "3",
            "Block": "69",
            "label": "ARINGAR ANNA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6126",
            "district": "3",
            "Block": "69",
            "label": "CMS 540 T H ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6200",
            "district": "3",
            "Block": "69",
            "label": "OXFORD MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6255",
            "district": "3",
            "Block": "69",
            "label": "C.S.I MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6326",
            "district": "3",
            "Block": "69",
            "label": "CMS 13 BUDDHA ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6340",
            "district": "3",
            "Block": "69",
            "label": "CMS GPW COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6487",
            "district": "3",
            "Block": "69",
            "label": "CMS CORONATION NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6656",
            "district": "3",
            "Block": "69",
            "label": "SACRED HEART MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7086",
            "district": "3",
            "Block": "69",
            "label": "SRI GURUMURTHY VIDYALAYA HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7087",
            "district": "3",
            "Block": "69",
            "label": "SAVIOUR MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7088",
            "district": "3",
            "Block": "69",
            "label": "F.E.S. Mat. Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7089",
            "district": "3",
            "Block": "69",
            "label": "PADMA PRAKASH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7090",
            "district": "3",
            "Block": "69",
            "label": "ANM MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7091",
            "district": "3",
            "Block": "69",
            "label": "SRI VIVEKANANDA VIDYALAYA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7092",
            "district": "3",
            "Block": "69",
            "label": "CSI BAIN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7093",
            "district": "3",
            "Block": "69",
            "label": "DOVETON MATRIC HR SEC SCHOOL, CHINNA KODUNGAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7094",
            "district": "3",
            "Block": "69",
            "label": "CHS ERUKKANCHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7095",
            "district": "3",
            "Block": "69",
            "label": "CHENNAI HIGH SCHOOL, KODUNGAIYUR, CHENNAI 118",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7096",
            "district": "3",
            "Block": "69",
            "label": "GHS KODUNGAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7097",
            "district": "3",
            "Block": "69",
            "label": "ST JOSEPH'S HSS  ERUKKANCHERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7098",
            "district": "3",
            "Block": "69",
            "label": "DON BOSCO MAT SCHOOL TEACHERS COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7099",
            "district": "3",
            "Block": "69",
            "label": "DAVID SONGS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7100",
            "district": "3",
            "Block": "69",
            "label": "CHSS PATEL NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7101",
            "district": "3",
            "Block": "69",
            "label": "VALLALAR MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7102",
            "district": "3",
            "Block": "69",
            "label": "DANIEL MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7103",
            "district": "3",
            "Block": "69",
            "label": "CHS CPT COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7104",
            "district": "3",
            "Block": "69",
            "label": "CHS ARATHOON ROAD  NEW MARKET FARM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7105",
            "district": "3",
            "Block": "69",
            "label": "SHEBHA MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7106",
            "district": "3",
            "Block": "69",
            "label": "CHENNAI HIGHER SECONDARY SCHOOL, 731, T.H. ROAD, CH-81.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7107",
            "district": "3",
            "Block": "69",
            "label": "TSS NADARGAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7108",
            "district": "3",
            "Block": "69",
            "label": "SOUNDARAPANDI SUPPAMMA (GIRLS) HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7109",
            "district": "3",
            "Block": "69",
            "label": "MURUGA DHANUSHKODI GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7110",
            "district": "3",
            "Block": "69",
            "label": "SIR THEAGARAYA COLLEGE HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7111",
            "district": "3",
            "Block": "69",
            "label": "BHAGYAM CHELLIAH MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7112",
            "district": "3",
            "Block": "69",
            "label": "ARUL JOTHI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7113",
            "district": "3",
            "Block": "69",
            "label": "AXENE EVANGELICAL MISSION HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7114",
            "district": "3",
            "Block": "69",
            "label": "A.E.M. MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7115",
            "district": "3",
            "Block": "69",
            "label": "CHS SEVENWELLS @ HARINARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7116",
            "district": "3",
            "Block": "69",
            "label": "CGHSS BUDDHA ST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7117",
            "district": "3",
            "Block": "69",
            "label": "SRI KARPAGAM MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7118",
            "district": "3",
            "Block": "69",
            "label": "SRI MAGESWARA  MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7119",
            "district": "3",
            "Block": "69",
            "label": "CUGMS WASHERMENPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20907",
            "district": "3",
            "Block": "69",
            "label": "SUFA MATRICULATION HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21007",
            "district": "3",
            "Block": "69",
            "label": "SRI MAGESHWARA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22333",
            "district": "3",
            "Block": "69",
            "label": "VELANKANNI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22852",
            "district": "3",
            "Block": "69",
            "label": "DANIEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22919",
            "district": "3",
            "Block": "69",
            "label": "NARAYANA e - TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23004",
            "district": "3",
            "Block": "69",
            "label": "SMK VIDHYASHRAM & JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23005",
            "district": "3",
            "Block": "69",
            "label": "VELANKANNI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "70": [
        {
            "key": "379",
            "district": "37",
            "Block": "70",
            "label": "ST.MICHAEL'S MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5385",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PONPATTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10045",
            "district": "37",
            "Block": "70",
            "label": "IMMACULATE HIGH SCHOOL VELANTHANGAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10046",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  MELPAPPAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10047",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10048",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10049",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTUCHITHAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10050",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  NALLAN PILLAI PETRAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10051",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  THADAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10052",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAZHAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10053",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SO.KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10054",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10055",
            "district": "37",
            "Block": "70",
            "label": "SRI RAMAKRISHNA MIDDLE SCHOOL  SATHIYAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10056",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  SATHIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10057",
            "district": "37",
            "Block": "70",
            "label": "RAJA DESINGH VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL  SATHIYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10058",
            "district": "37",
            "Block": "70",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL (UNAIDED) SATHIYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10059",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  ALAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10060",
            "district": "37",
            "Block": "70",
            "label": "ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL ALAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10061",
            "district": "37",
            "Block": "70",
            "label": "ST. AUGUSTINE HIGH SCHOOL  THANGALKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10062",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ODHIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10063",
            "district": "37",
            "Block": "70",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL MANALAPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10064",
            "district": "37",
            "Block": "70",
            "label": "AIDED NON MINORITY MIDDLE SCHOOL R.NAYAMPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10065",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  DEVADHANAMPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10066",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  KONAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10067",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10068",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THACHAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10069",
            "district": "37",
            "Block": "70",
            "label": "ST.ANTONY'S HIGH SCHOOL  ANAIYERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10070",
            "district": "37",
            "Block": "70",
            "label": "LOURDU ANNAI HIGHER SECONDARY SCHOOL KANAKANKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10071",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  KANAKKANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10072",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  MALAIYARASANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10073",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL MAZHAVANTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10074",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  THUTHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10075",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL (G) ANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10076",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  ANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10077",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KONALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10078",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL OTTAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10079",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGH SCHOOL  KARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10080",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SITTAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10081",
            "district": "37",
            "Block": "70",
            "label": "SARVA SEVA VIDHYALAYA MATRIC HS SITTAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10082",
            "district": "37",
            "Block": "70",
            "label": "ADIDRAVIDA WELFARE HIGHER SECONDARY SCHOOL KAVARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10083",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL JAYANKONDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10084",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MEENAMBUR (B) URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10085",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10086",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENPUDUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10087",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GINGEE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10088",
            "district": "37",
            "Block": "70",
            "label": "RAJA DESINGH GOVERNMENT BOYS HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10089",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL (G) GINGEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10090",
            "district": "37",
            "Block": "70",
            "label": "AL-HILAL MATRIC HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10091",
            "district": "37",
            "Block": "70",
            "label": "SARADHA MATRIC HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10092",
            "district": "37",
            "Block": "70",
            "label": "CHANAKYA MATRICULATION HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10093",
            "district": "37",
            "Block": "70",
            "label": "ST.MICHAEL'S HIGHER SECONDARY SCHOOL  KRISHNAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10094",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SAKKARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10095",
            "district": "37",
            "Block": "70",
            "label": "KALAIVANI  MATRICULATION HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10096",
            "district": "37",
            "Block": "70",
            "label": "GOVERNMENT MUSLIM URDU HIGH SCHOOL, GINGEE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10097",
            "district": "37",
            "Block": "70",
            "label": "SHVETA  PADMASANI MATRIC HIGHER SECONDARY SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20961",
            "district": "37",
            "Block": "70",
            "label": "SRI DHARANI INTERNATIONAL  SCHOOL GINGEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21061",
            "district": "37",
            "Block": "70",
            "label": "SRI SAIRAM PUBLIC SCHOOL (CBSE) JAYANKONDAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21686",
            "district": "37",
            "Block": "70",
            "label": "ASUVATHAMA MATRIC HIGHER SECONDARY SCHOOL, PALAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22095",
            "district": "37",
            "Block": "70",
            "label": "SRI VIDHYA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL NARASINGARAYANPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23126",
            "district": "37",
            "Block": "70",
            "label": "SRI RANGAPOOPATHI INTERNATIONAL SCHOOL , ALAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23783",
            "district": "37",
            "Block": "70",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "71": [
        {
            "key": "549",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTTUPULLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "602",
            "district": "8",
            "Block": "71",
            "label": "RAMAKRISHNA MIDDLE SCHOOL, OLAPPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "610",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  PERUNTHALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "648",
            "district": "8",
            "Block": "71",
            "label": "SRI VENKATESHWARA VIDHYALAYAA MATRICULATION SCHOOL, THASAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "676",
            "district": "8",
            "Block": "71",
            "label": "MRS MATRICULATION SCHOOL, KOLAPPALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "680",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVVAIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "726",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "772",
            "district": "8",
            "Block": "71",
            "label": "AMALA MATRIC. HIGHER SEC. SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "818",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "876",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, S.GANAPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "899",
            "district": "8",
            "Block": "71",
            "label": "SRI VENKATESHWARA INTERNATIONALSCHOOL,OTHAKUTHIRAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "955",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "963",
            "district": "8",
            "Block": "71",
            "label": "KAMBAN KALVI NILAYAM MATRIC. HS SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1023",
            "district": "8",
            "Block": "71",
            "label": "MMS, (TOWN) GOBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1063",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUTHUVALLIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1178",
            "district": "8",
            "Block": "71",
            "label": "C.K.K. MATRIC HS SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1272",
            "district": "8",
            "Block": "71",
            "label": "BHARATHI VIDHYALAYA  MATRIC HSS, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1317",
            "district": "8",
            "Block": "71",
            "label": "PUMS, PERIYAKORAVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1330",
            "district": "8",
            "Block": "71",
            "label": "SARATHA MATRIC HSS, MODACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1382",
            "district": "8",
            "Block": "71",
            "label": "JAYARAM MUNCIPAL MIDDLE SCHOOL, GOBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1796",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THALAIKKOMBUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1889",
            "district": "8",
            "Block": "71",
            "label": "DIAMOND JUBILEE MATRIC. HS, GOBI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1898",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KATTUVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2026",
            "district": "8",
            "Block": "71",
            "label": "SHREE VIDYALAYA MATRIC HS SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2128",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BOMMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2138",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SAVANDAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2152",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAVANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4209",
            "district": "8",
            "Block": "71",
            "label": "SRI NATIONAL SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4979",
            "district": "8",
            "Block": "71",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NALLAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5206",
            "district": "8",
            "Block": "71",
            "label": "SRI D.S. SARADHA VIDHYALAYA MIDDLE SCHOOL, GOBI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12180",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, P.METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12181",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL, K.METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12182",
            "district": "8",
            "Block": "71",
            "label": "GANDHI KALVI NILAYAM HIGHER SECONDARY SCHOOL,KUGALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12183",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, POLAVAKKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12184",
            "district": "8",
            "Block": "71",
            "label": "SRI VENKATESHWARA VIDHAYALAYAA HIGHER SEC. SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12185",
            "district": "8",
            "Block": "71",
            "label": "MUNICIPAL GIRLS HIGHER SECONDARY SCHOOL, GOBI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12186",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL,  KULLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12187",
            "district": "8",
            "Block": "71",
            "label": "MUNICIPAL HIGHER SEC.SCHOOL, MODACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12188",
            "district": "8",
            "Block": "71",
            "label": "SARATHA HIGHER SECONDARY SCHOOL, MODACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12189",
            "district": "8",
            "Block": "71",
            "label": "PALANIAMMAL GIRLS HS SCHOOL, GOBI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12190",
            "district": "8",
            "Block": "71",
            "label": "GHSS, KARATTADIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12191",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL, KALINGIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12192",
            "district": "8",
            "Block": "71",
            "label": "SHREE GURUKULAM HIGHER SEC. SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12193",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL, ALUKKULI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12194",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGH SCHOOL, MALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12195",
            "district": "8",
            "Block": "71",
            "label": "C.S.I. MIDDLE SCHOOL, SIRUVALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12196",
            "district": "8",
            "Block": "71",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, VELLANKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12197",
            "district": "8",
            "Block": "71",
            "label": "AMALA HIGH SCHOOL, GOBI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12198",
            "district": "8",
            "Block": "71",
            "label": "DIAMOND JUBILEE HIGHER SECONDARY SCHOOL, GOBI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12199",
            "district": "8",
            "Block": "71",
            "label": "MUNICIPAL BOYS HR. SEC. SCHOOL, GOBI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12200",
            "district": "8",
            "Block": "71",
            "label": "VENGAMMAIYAR MH SCHOOL, GOBI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12201",
            "district": "8",
            "Block": "71",
            "label": "ST PAULS MATRIC HIGHER SECONDARY SCHOOL,  PATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12202",
            "district": "8",
            "Block": "71",
            "label": "ST. MARY'S HIGH SCHOOL, KOLAPPALUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12203",
            "district": "8",
            "Block": "71",
            "label": "THAI TAMIL HIGH SCHOOL, KOLAPPALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21075",
            "district": "8",
            "Block": "71",
            "label": "BHARATHI VIDHYALAYA SCHOOL ,GOBI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21076",
            "district": "8",
            "Block": "71",
            "label": "M.R.S. PUBLIC SCHOOL, KOLAPPALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22461",
            "district": "8",
            "Block": "71",
            "label": "GOVERNMENT HIGH SCHOOL, P.VELLALAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22462",
            "district": "8",
            "Block": "71",
            "label": "SHREE GURUKULAM PUBLIC SCHOOL,MOOLAVAIKKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22463",
            "district": "8",
            "Block": "71",
            "label": "GOVERNMENT HIGH SCHOOL, MANIYAKARANPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22881",
            "district": "8",
            "Block": "71",
            "label": "GHS, ODAKKATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "72": [
        {
            "key": "753",
            "district": "26",
            "Block": "72",
            "label": "IDEAL MATRIC HIGHER SECONDARY SCHOOL, T.K.PET, GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1739",
            "district": "26",
            "Block": "72",
            "label": "SACRED HEART MHSS KAYYUNNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1775",
            "district": "26",
            "Block": "72",
            "label": "GOOD SHEPHERD MATRIC SCHOOL MASINAGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1838",
            "district": "26",
            "Block": "72",
            "label": "NILGIRI MATRICULATION HIGHER SECONDARY SCHOOL ERUMAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2221",
            "district": "26",
            "Block": "72",
            "label": "TEWS MAT HR SEC SCHOOL PANDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2279",
            "district": "26",
            "Block": "72",
            "label": "ST THOMAS MATRIC HSS AYYANKOLLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3486",
            "district": "26",
            "Block": "72",
            "label": "M S S MATRICULATION HIGH SCHOOL, UPPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5396",
            "district": "26",
            "Block": "72",
            "label": "MTS MATRICULATION HIGH SCHOOL PADANTHORAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6114",
            "district": "26",
            "Block": "72",
            "label": "ST ANTONYS MAT HSS, RAJAGOPALAPURAM, GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6604",
            "district": "26",
            "Block": "72",
            "label": "HOLY CROSS MATRIC HSS DEVARSHOLAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12579",
            "district": "26",
            "Block": "72",
            "label": "GTRMS MUKKATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12580",
            "district": "26",
            "Block": "72",
            "label": "PUMS MAYFIELD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12581",
            "district": "26",
            "Block": "72",
            "label": "PUMS VILLANGORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12582",
            "district": "26",
            "Block": "72",
            "label": "PUMS MUTHIRAKOLLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12583",
            "district": "26",
            "Block": "72",
            "label": "PUMS PATTAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12584",
            "district": "26",
            "Block": "72",
            "label": "PUMS PEARL DIVISION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12585",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL, PAKKANA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12586",
            "district": "26",
            "Block": "72",
            "label": "GHS NELAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12587",
            "district": "26",
            "Block": "72",
            "label": "GHSS BITHERKAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12588",
            "district": "26",
            "Block": "72",
            "label": "GHS KUNTHALADY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12589",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGHER SECONDARY SCHOOL KARGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12590",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SREEMADURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12591",
            "district": "26",
            "Block": "72",
            "label": "ST SEBASTIAN MAT HS,MANVAYAL, SREEMADURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12592",
            "district": "26",
            "Block": "72",
            "label": "PUMS MARADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12593",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL MANNATHIVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12594",
            "district": "26",
            "Block": "72",
            "label": "PUMS KAKKUNDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12595",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT (GIRLS) HIGH SCHOOL ERUMAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12596",
            "district": "26",
            "Block": "72",
            "label": "GHS KAYYUNNI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12597",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  ERUMAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12598",
            "district": "26",
            "Block": "72",
            "label": "GTRMS AYYANKOLLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12599",
            "district": "26",
            "Block": "72",
            "label": "GHSS AMBALAMOOLA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12600",
            "district": "26",
            "Block": "72",
            "label": "GHS AMBALAVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12601",
            "district": "26",
            "Block": "72",
            "label": "PUMS CHERAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12602",
            "district": "26",
            "Block": "72",
            "label": "PUMS NAICKENSHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12603",
            "district": "26",
            "Block": "72",
            "label": "PUMS KARAKOLLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12604",
            "district": "26",
            "Block": "72",
            "label": "GTRMS KAPPALA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12605",
            "district": "26",
            "Block": "72",
            "label": "GHSS CHERAMBADY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12606",
            "district": "26",
            "Block": "72",
            "label": "GHSS KOLAPALLY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12607",
            "district": "26",
            "Block": "72",
            "label": "PUMS CHERANGODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12608",
            "district": "26",
            "Block": "72",
            "label": "PUMS MAYOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12609",
            "district": "26",
            "Block": "72",
            "label": "GTRMS MAVANALLA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12610",
            "district": "26",
            "Block": "72",
            "label": "GRG MEMORIAL HR SEC SCHOOL, VAZHAITHOTTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12611",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MASINAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12612",
            "district": "26",
            "Block": "72",
            "label": "SRI SHANTHI VIJAI HSS MASINAGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12613",
            "district": "26",
            "Block": "72",
            "label": "PUMS KOZHIPALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12614",
            "district": "26",
            "Block": "72",
            "label": "PUMS NANTHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12615",
            "district": "26",
            "Block": "72",
            "label": "PUMS ATHIPALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12616",
            "district": "26",
            "Block": "72",
            "label": "PUMS FIRSTMILE GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12617",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL, PUTHURVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12618",
            "district": "26",
            "Block": "72",
            "label": "PUMS VANDIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12619",
            "district": "26",
            "Block": "72",
            "label": "GTRMS THORAPALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12620",
            "district": "26",
            "Block": "72",
            "label": "ST. MARY'S HIGH SCHOOL UPPER GUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12621",
            "district": "26",
            "Block": "72",
            "label": "SRI RAMAKRISHNA MATRIC HS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12622",
            "district": "26",
            "Block": "72",
            "label": "GTMO HR SEC SCHOOL GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12623",
            "district": "26",
            "Block": "72",
            "label": "MORNING STAR HSS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12624",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL GUDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12625",
            "district": "26",
            "Block": "72",
            "label": "FATIMA GIRLS HIGHER SECONDARY SCHOOL , GUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12626",
            "district": "26",
            "Block": "72",
            "label": "ST THOMAS HSS MT NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12627",
            "district": "26",
            "Block": "72",
            "label": "BHARATH COMMUNITY MATRIC HSS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12628",
            "district": "26",
            "Block": "72",
            "label": "KALAIVANI MAT HSS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12629",
            "district": "26",
            "Block": "72",
            "label": "GTMO MATRIC HSS CHEMBALA, GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12630",
            "district": "26",
            "Block": "72",
            "label": "ST THOMAS ENG HS GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12631",
            "district": "26",
            "Block": "72",
            "label": "BRINDAVAN MATRICULATION SCHOOL, ATHIPALY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12632",
            "district": "26",
            "Block": "72",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Kozhikolly",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12633",
            "district": "26",
            "Block": "72",
            "label": "PUMS BHARATHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12634",
            "district": "26",
            "Block": "72",
            "label": "PUMS GANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12635",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL CHOONDY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12636",
            "district": "26",
            "Block": "72",
            "label": "PUMS PERIASHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12637",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL DHARMAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12638",
            "district": "26",
            "Block": "72",
            "label": "PUMS NEWHOPE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12639",
            "district": "26",
            "Block": "72",
            "label": "GHSS OVALLEY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12640",
            "district": "26",
            "Block": "72",
            "label": "NOORUL HUDHA HS YELLAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12641",
            "district": "26",
            "Block": "72",
            "label": "GHS AROTTUPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12642",
            "district": "26",
            "Block": "72",
            "label": "PUMS DEVAN 2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12643",
            "district": "26",
            "Block": "72",
            "label": "GHSS DEVARSHOLA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12644",
            "district": "26",
            "Block": "72",
            "label": "AL HIDHAYA MATRIC SCHOOL, OTTUVAYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12645",
            "district": "26",
            "Block": "72",
            "label": "AL-AMEEN MATRICULATION SCHOOL,  DEVARSHOLAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12646",
            "district": "26",
            "Block": "72",
            "label": "GHS KOTTAMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12647",
            "district": "26",
            "Block": "72",
            "label": "GTRMS PONNENI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12648",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL PULIYAMBARA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12649",
            "district": "26",
            "Block": "72",
            "label": "PUMS UPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12650",
            "district": "26",
            "Block": "72",
            "label": "GHS ATHIKUNNA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12651",
            "district": "26",
            "Block": "72",
            "label": "BHARATH MATHA HSS UPPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12652",
            "district": "26",
            "Block": "72",
            "label": "ST FRANCIS XAVIER(G) HS PANDALUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12653",
            "district": "26",
            "Block": "72",
            "label": "GHSS PANDALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12654",
            "district": "26",
            "Block": "72",
            "label": "GHS PADANTHORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12655",
            "district": "26",
            "Block": "72",
            "label": "CSI HIGH SCHOOL, PADANTHORAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12656",
            "district": "26",
            "Block": "72",
            "label": "PUMS DEVALA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12657",
            "district": "26",
            "Block": "72",
            "label": "PUMS DEVALAHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12658",
            "district": "26",
            "Block": "72",
            "label": "PUMS VAZHAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12659",
            "district": "26",
            "Block": "72",
            "label": "GHSS DEVALA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12660",
            "district": "26",
            "Block": "72",
            "label": "HOLY CROSS CONVENT MATRIC DEVALA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20967",
            "district": "26",
            "Block": "72",
            "label": "SREE SARASWATHI VIVEKANANDA  MAHA VIDHYALAYA MATRIC AYYANKOLLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21597",
            "district": "26",
            "Block": "72",
            "label": "IMS MATRICULATION HIGH SCHOOL PAKKANA, GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21866",
            "district": "26",
            "Block": "72",
            "label": "GLOBAL MATRICULATION SCHOOL DEVALA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22133",
            "district": "26",
            "Block": "72",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Vattakolly",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22473",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT HIGH SCHOOL PONNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22990",
            "district": "26",
            "Block": "72",
            "label": "GHS KARIYASHOLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23046",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL, DEVALA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23170",
            "district": "26",
            "Block": "72",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL BOKKAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23784",
            "district": "26",
            "Block": "72",
            "label": "PUMS GANDHI NAGAR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "73": [
        {
            "key": "721",
            "district": "32",
            "Block": "73",
            "label": "RGM HSS PEDAPPAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "903",
            "district": "32",
            "Block": "73",
            "label": "PUMS PONNERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "952",
            "district": "32",
            "Block": "73",
            "label": "PUMS SOMAVARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1055",
            "district": "32",
            "Block": "73",
            "label": "PUMS V.LINGAMANAICKENPATTI PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1136",
            "district": "32",
            "Block": "73",
            "label": "PUMS MURUNGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1560",
            "district": "32",
            "Block": "73",
            "label": "PUMS ADIVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1638",
            "district": "32",
            "Block": "73",
            "label": "PUMS-ILUPPANAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2273",
            "district": "32",
            "Block": "73",
            "label": "OXFORD MATRIC HSS-KONGALNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3079",
            "district": "32",
            "Block": "73",
            "label": "PUMS MOONGILTHOLUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3221",
            "district": "32",
            "Block": "73",
            "label": "PUMS KOTTAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4695",
            "district": "32",
            "Block": "73",
            "label": "PUMS,AMANTHAKADAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12939",
            "district": "32",
            "Block": "73",
            "label": "GHSS GUDIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12940",
            "district": "32",
            "Block": "73",
            "label": "GHSS POOLAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12941",
            "district": "32",
            "Block": "73",
            "label": "GHS V.VELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12942",
            "district": "32",
            "Block": "73",
            "label": "GHSS RAMACHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12943",
            "district": "32",
            "Block": "73",
            "label": "GHS KONGALNAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12944",
            "district": "32",
            "Block": "73",
            "label": "GHSS PEDAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20969",
            "district": "32",
            "Block": "73",
            "label": "N.V.MATRIC HR,SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22144",
            "district": "32",
            "Block": "73",
            "label": "SRI ARUNGARAIAMMAN NALLATHAAI VIDHYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22485",
            "district": "32",
            "Block": "73",
            "label": "THE INDIAN MODEL SCHOOL, KOTTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22934",
            "district": "32",
            "Block": "73",
            "label": "GHS Pukkulam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23244",
            "district": "32",
            "Block": "73",
            "label": "GOVERNMENT HIGH SCHOOL , A.AMMAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23245",
            "district": "32",
            "Block": "73",
            "label": "GOVERNMENT HIGH SCHOOL , PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23785",
            "district": "32",
            "Block": "73",
            "label": "RG International Public School",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "74": [
        {
            "key": "583",
            "district": "36",
            "Block": "74",
            "label": "Dr.KRISHNASWAMI MATRIC SCHOOL, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "757",
            "district": "36",
            "Block": "74",
            "label": "ST JOHNS MHSS GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1444",
            "district": "36",
            "Block": "74",
            "label": "S.D.A MATRIC HSS, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1541",
            "district": "36",
            "Block": "74",
            "label": "PUMS MUNAFF DIBBO ( U )",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1702",
            "district": "36",
            "Block": "74",
            "label": "GGHSS NADUPET, GUDIYATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2141",
            "district": "36",
            "Block": "74",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOONGAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2421",
            "district": "36",
            "Block": "74",
            "label": "PUMS RAJAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3031",
            "district": "36",
            "Block": "74",
            "label": "MD AMS CHINNATHOTTALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3288",
            "district": "36",
            "Block": "74",
            "label": "HABEEB MATRIC HS SCHOOL, SANTHAPETAI, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3333",
            "district": "36",
            "Block": "74",
            "label": "PUMS LINGUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3384",
            "district": "36",
            "Block": "74",
            "label": "PUMS ERTHANGAL PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3386",
            "district": "36",
            "Block": "74",
            "label": "RAJIV GANDHI MATRIC SCHOOL, GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3560",
            "district": "36",
            "Block": "74",
            "label": "PUMS DHANAKONDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3579",
            "district": "36",
            "Block": "74",
            "label": "APS MADARASE-E-NISWAN, MELALATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3758",
            "district": "36",
            "Block": "74",
            "label": "PUMS MELMUTTUKOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3991",
            "district": "36",
            "Block": "74",
            "label": "PUMS ERIPATTARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4066",
            "district": "36",
            "Block": "74",
            "label": "PUMS PONNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4090",
            "district": "36",
            "Block": "74",
            "label": "PUMS RAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4102",
            "district": "36",
            "Block": "74",
            "label": "PUMS ANANGANALLORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4161",
            "district": "36",
            "Block": "74",
            "label": "PUMS MELALATHUR (H)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4317",
            "district": "36",
            "Block": "74",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOTTARAMADUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4378",
            "district": "36",
            "Block": "74",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEEVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4514",
            "district": "36",
            "Block": "74",
            "label": "PUMS SENGUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4570",
            "district": "36",
            "Block": "74",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MUKKUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4854",
            "district": "36",
            "Block": "74",
            "label": "PUMS K V PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4956",
            "district": "36",
            "Block": "74",
            "label": "PUMS NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5071",
            "district": "36",
            "Block": "74",
            "label": "PUMS SARAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5094",
            "district": "36",
            "Block": "74",
            "label": "PUMS PAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5732",
            "district": "36",
            "Block": "74",
            "label": "PUMS SYNAKUNDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5925",
            "district": "36",
            "Block": "74",
            "label": "PUMS INDRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6013",
            "district": "36",
            "Block": "74",
            "label": "ST.PETERS MATRIC SCHOOL, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6226",
            "district": "36",
            "Block": "74",
            "label": "PUMS GANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6279",
            "district": "36",
            "Block": "74",
            "label": "PUMS PUTTAVARIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6468",
            "district": "36",
            "Block": "74",
            "label": "PUMS S MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6587",
            "district": "36",
            "Block": "74",
            "label": "LITTLE FLOWER MATRIC SCHOOL , GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6639",
            "district": "36",
            "Block": "74",
            "label": "MMS GANGADARASAMY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8822",
            "district": "36",
            "Block": "74",
            "label": "GHS SEMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8823",
            "district": "36",
            "Block": "74",
            "label": "PUMS UPPARAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8824",
            "district": "36",
            "Block": "74",
            "label": "GHSS KOTTAMITTAH",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8825",
            "district": "36",
            "Block": "74",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOOL THATTAPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8826",
            "district": "36",
            "Block": "74",
            "label": "GHSS AGRAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8827",
            "district": "36",
            "Block": "74",
            "label": "GHSS ERTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8828",
            "district": "36",
            "Block": "74",
            "label": "GHSS VALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8829",
            "district": "36",
            "Block": "74",
            "label": "PUMS ULLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8830",
            "district": "36",
            "Block": "74",
            "label": "GHS SEMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8831",
            "district": "36",
            "Block": "74",
            "label": "ADWHS CHETTIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8832",
            "district": "36",
            "Block": "74",
            "label": "GOVERNMENT HIGH SCHOOL KALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8833",
            "district": "36",
            "Block": "74",
            "label": "GHSS GOODANAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8834",
            "district": "36",
            "Block": "74",
            "label": "PUMS SINGALPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8835",
            "district": "36",
            "Block": "74",
            "label": "GHSS KALLAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8836",
            "district": "36",
            "Block": "74",
            "label": "PUMS KANAVAI MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8837",
            "district": "36",
            "Block": "74",
            "label": "PUMS GUDLAVARIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8838",
            "district": "36",
            "Block": "74",
            "label": "GHS VEERICHETTIPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8839",
            "district": "36",
            "Block": "74",
            "label": "SRI RAMAKRISHNA GARDEN MATRIC SCHOOL, PARADARAMI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8840",
            "district": "36",
            "Block": "74",
            "label": "GBHSS PARADARAMI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8841",
            "district": "36",
            "Block": "74",
            "label": "GGHSS PARADARAMI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8842",
            "district": "36",
            "Block": "74",
            "label": "GURUJI MATRIC SCHOOL, PARADARAMI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8843",
            "district": "36",
            "Block": "74",
            "label": "GHS POOSARIVALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8844",
            "district": "36",
            "Block": "74",
            "label": "GOVT MPL HR SEC SCHOOL GUDIYATTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8845",
            "district": "36",
            "Block": "74",
            "label": "GGHSS NELLOREPET (G)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8846",
            "district": "36",
            "Block": "74",
            "label": "JOTHI HR. SEC. SCHOOL, GUDIYATHAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8847",
            "district": "36",
            "Block": "74",
            "label": "AHSS VALLALAR GUDIYATHAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8848",
            "district": "36",
            "Block": "74",
            "label": "AHSS NATIONAL GUDIYATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8849",
            "district": "36",
            "Block": "74",
            "label": "AHSS THIRUVALLUVAR GUDIYATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8850",
            "district": "36",
            "Block": "74",
            "label": "CRESCENT MATRIC SCHOOL, SEDUKKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8851",
            "district": "36",
            "Block": "74",
            "label": "A. J.  SARASWATHI MATRIC VIDHYALAYA SCHOOL , NELLOREPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8852",
            "district": "36",
            "Block": "74",
            "label": "GOVT.BOYS HR.SEC.SCHOOL, NELLOREPET,GUDIYATHAM,VELLORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8853",
            "district": "36",
            "Block": "74",
            "label": "MADRASA - E - SALEEMA NISWAN, GUDIYATHAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8854",
            "district": "36",
            "Block": "74",
            "label": "ADWHS OLAKKASI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8874",
            "district": "36",
            "Block": "74",
            "label": "PUMS MORTHANA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8915",
            "district": "36",
            "Block": "74",
            "label": "PUMS,VADAKATHIPATI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8916",
            "district": "36",
            "Block": "74",
            "label": "GHS AGARAMCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8917",
            "district": "36",
            "Block": "74",
            "label": "ST.JOHN BLESSO MAT SCHOOL AGARAMCHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8918",
            "district": "36",
            "Block": "74",
            "label": "GHS GOLLAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8919",
            "district": "36",
            "Block": "74",
            "label": "GHS PALLIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20945",
            "district": "36",
            "Block": "74",
            "label": "LALETHA GOVINDHAN MATRIC SCHOOL, ULLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21239",
            "district": "36",
            "Block": "74",
            "label": "Aadithya Vidhyashram Matric School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21433",
            "district": "36",
            "Block": "74",
            "label": "GHS GANDHI NAGAR, GUDIYATTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21493",
            "district": "36",
            "Block": "74",
            "label": "ADW HS R.VENKATAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21782",
            "district": "36",
            "Block": "74",
            "label": "ASHIRWAD'S INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22390",
            "district": "36",
            "Block": "74",
            "label": "GOVT HIGH SCHOOL KALIYAMMANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23144",
            "district": "36",
            "Block": "74",
            "label": "G.E.T PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23629",
            "district": "36",
            "Block": "74",
            "label": "EDIFY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23629",
            "district": "36",
            "Block": "74",
            "label": "EDIFY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23786",
            "district": "36",
            "Block": "74",
            "label": "GHS SEMBEDU",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23787",
            "district": "36",
            "Block": "74",
            "label": "PUMS NATHAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23788",
            "district": "36",
            "Block": "74",
            "label": "ARUMBUGAL ",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "75": [
        {
            "key": "13403",
            "district": "7",
            "Block": "75",
            "label": "SARASWATHI MS NARASINGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13404",
            "district": "7",
            "Block": "75",
            "label": "GHSS R.VELLODU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13405",
            "district": "7",
            "Block": "75",
            "label": "PUMS, CCC QUARRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13406",
            "district": "7",
            "Block": "75",
            "label": "RANI MEYYAMMAI HSS, CCC QUARRY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13407",
            "district": "7",
            "Block": "75",
            "label": "GHSS, SERVAIKARANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13408",
            "district": "7",
            "Block": "75",
            "label": "PUMS, JEGALAMMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13409",
            "district": "7",
            "Block": "75",
            "label": "VOC HIGH SCHOOL, D.GUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13410",
            "district": "7",
            "Block": "75",
            "label": "PUMS, L.CHETTIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13411",
            "district": "7",
            "Block": "75",
            "label": "GHSS, LANTHAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13412",
            "district": "7",
            "Block": "75",
            "label": "PUMS, RAMAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13413",
            "district": "7",
            "Block": "75",
            "label": "THIRUMURUGAN AIDED MIDDLE SCHOOL, PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13414",
            "district": "7",
            "Block": "75",
            "label": "GHSS, GUZILIAMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13415",
            "district": "7",
            "Block": "75",
            "label": "PUMS, KARIKKALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13416",
            "district": "7",
            "Block": "75",
            "label": "KAMBAN VIDHYALAYA HS, SALIKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13417",
            "district": "7",
            "Block": "75",
            "label": "PUMS, ULLIAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13418",
            "district": "7",
            "Block": "75",
            "label": "PUMS, PALLANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13419",
            "district": "7",
            "Block": "75",
            "label": "PUMS, KOOMBURPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13420",
            "district": "7",
            "Block": "75",
            "label": "PUMS, KONDAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13421",
            "district": "7",
            "Block": "75",
            "label": "GHS, R.P. PILLAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13422",
            "district": "7",
            "Block": "75",
            "label": "PUMS, KILAKUVADUGAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13423",
            "district": "7",
            "Block": "75",
            "label": "PUMS, SUBRAMANIYAPILLAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13424",
            "district": "7",
            "Block": "75",
            "label": "CHINRAJ DHANA GHSS PULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13425",
            "district": "7",
            "Block": "75",
            "label": "PUMS, PUNGAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13426",
            "district": "7",
            "Block": "75",
            "label": "PUMS, CHINNALAGUNAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13427",
            "district": "7",
            "Block": "75",
            "label": "PUMS, R.KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21365",
            "district": "7",
            "Block": "75",
            "label": "GHS,R.PUDUKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21894",
            "district": "7",
            "Block": "75",
            "label": "KARUR VIVEGAM VIDHYALAYA HIGH SCHOOL, D.GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22500",
            "district": "7",
            "Block": "75",
            "label": "HAYAGREEVAR'S KONGUNAD ACADEMY SENIOR SECONDARY SCHOOL, D.GUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22501",
            "district": "7",
            "Block": "75",
            "label": "GHS, K.ANAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "76": [
        {
            "key": "458",
            "district": "33",
            "Block": "76",
            "label": "RAN MAT SCH KAVARAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "512",
            "district": "33",
            "Block": "76",
            "label": "PUMS PATHIRVEDU WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "537",
            "district": "33",
            "Block": "76",
            "label": "PUMS - NOCHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "604",
            "district": "33",
            "Block": "76",
            "label": "MAHARAJA AGRASEN MATRIC HIGHER SECONDARY SCHOOL,ARUN NAGAR, GUMMIDIPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "626",
            "district": "33",
            "Block": "76",
            "label": "SAKUNTHALAMMAL MATRIC SCHOOL THURAPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "641",
            "district": "33",
            "Block": "76",
            "label": "PUMS L R MEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "784",
            "district": "33",
            "Block": "76",
            "label": "PUMS MADHARPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "789",
            "district": "33",
            "Block": "76",
            "label": "PUMS NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "790",
            "district": "33",
            "Block": "76",
            "label": "PUMS PETHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "791",
            "district": "33",
            "Block": "76",
            "label": "PUMS GOPAL REDDY KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1027",
            "district": "33",
            "Block": "76",
            "label": "PUMS PUDUVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1090",
            "district": "33",
            "Block": "76",
            "label": "PUMS SIRUPUZHALPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1093",
            "district": "33",
            "Block": "76",
            "label": "PUMS PERIYAOBULAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1099",
            "district": "33",
            "Block": "76",
            "label": "PUMS NELVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1135",
            "district": "33",
            "Block": "76",
            "label": "PUMS KURUVATTUCHERRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1144",
            "district": "33",
            "Block": "76",
            "label": "PUMS NETHAJI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1150",
            "district": "33",
            "Block": "76",
            "label": "PUMS KANNAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1280",
            "district": "33",
            "Block": "76",
            "label": "MADANLAL KHEMANI VIVEKANANDA VIDYALAYA , PETHIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1395",
            "district": "33",
            "Block": "76",
            "label": "MAHARISHI VID MANTHIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1431",
            "district": "33",
            "Block": "76",
            "label": "R M K MAT HS KAVARAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1449",
            "district": "33",
            "Block": "76",
            "label": "ST MARYS MAT SC - ARAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1550",
            "district": "33",
            "Block": "76",
            "label": "ELITE MAT.HSS GUMMIDIPOONDI - 601 201",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1552",
            "district": "33",
            "Block": "76",
            "label": "NETHAJI MS PATHIRVEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1590",
            "district": "33",
            "Block": "76",
            "label": "PUMS CHINNAOBULAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1608",
            "district": "33",
            "Block": "76",
            "label": "PUMS A N KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1659",
            "district": "33",
            "Block": "76",
            "label": "PUMS EDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1673",
            "district": "33",
            "Block": "76",
            "label": "PUMS PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1700",
            "district": "33",
            "Block": "76",
            "label": "PUMS ERUKKUVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1721",
            "district": "33",
            "Block": "76",
            "label": "PUMS ANNAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1726",
            "district": "33",
            "Block": "76",
            "label": "PUMS POOVALAI AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1801",
            "district": "33",
            "Block": "76",
            "label": "PUMS RAKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1893",
            "district": "33",
            "Block": "76",
            "label": "PUMS PALLAVADA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1912",
            "district": "33",
            "Block": "76",
            "label": "PUMS VALLAMEDU KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1953",
            "district": "33",
            "Block": "76",
            "label": "PUMS THANDALACHERRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2429",
            "district": "33",
            "Block": "76",
            "label": "PUMS AYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2460",
            "district": "33",
            "Block": "76",
            "label": "PUMS KAMMAVARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2847",
            "district": "33",
            "Block": "76",
            "label": "DONBOSCO MS N S NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2979",
            "district": "33",
            "Block": "76",
            "label": "PUMS PALIKAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3547",
            "district": "33",
            "Block": "76",
            "label": "SHANTHI NIKETHAN MS NEW GUMMIDIPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3689",
            "district": "33",
            "Block": "76",
            "label": "PUMS THANIPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3918",
            "district": "33",
            "Block": "76",
            "label": "ST MARYS HSS CHINTHALAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4098",
            "district": "33",
            "Block": "76",
            "label": "ST ANTONY MS ROSANAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4181",
            "district": "33",
            "Block": "76",
            "label": "GPD REFUGE CAMP MS PETHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4419",
            "district": "33",
            "Block": "76",
            "label": "VIVEKANANDA MATRIC HIGHER SECONDARY SCHOOL, THURAPPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5274",
            "district": "33",
            "Block": "76",
            "label": "SRI KALAIMAGAL VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6910",
            "district": "33",
            "Block": "76",
            "label": "GHS ATHUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6911",
            "district": "33",
            "Block": "76",
            "label": "GOVT HSS ARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6912",
            "district": "33",
            "Block": "76",
            "label": "ST MARYS HSS ARAMBAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6913",
            "district": "33",
            "Block": "76",
            "label": "GOVT HIGH SCH N M KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6914",
            "district": "33",
            "Block": "76",
            "label": "GOVT HS EGUVARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6915",
            "district": "33",
            "Block": "76",
            "label": "GOVT HSS ELAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6916",
            "district": "33",
            "Block": "76",
            "label": "GHS G R KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6917",
            "district": "33",
            "Block": "76",
            "label": "GOVT HSS A N KANDIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6918",
            "district": "33",
            "Block": "76",
            "label": "GHSS KAVARAIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6919",
            "district": "33",
            "Block": "76",
            "label": "R M K RESIDENTAL SCHOOL KAVARAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6920",
            "district": "33",
            "Block": "76",
            "label": "GHS OBASAMUTHRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6921",
            "district": "33",
            "Block": "76",
            "label": "GHSS NEW GUMMIDIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6922",
            "district": "33",
            "Block": "76",
            "label": "GHS POOVALAMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6923",
            "district": "33",
            "Block": "76",
            "label": "GGHSS PATHIRVEDU MADHARPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6924",
            "district": "33",
            "Block": "76",
            "label": "GBHSS PATHIRVEDU MADHARPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6925",
            "district": "33",
            "Block": "76",
            "label": "GHS S R KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6926",
            "district": "33",
            "Block": "76",
            "label": "GHSS SUNNAMBUKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6927",
            "district": "33",
            "Block": "76",
            "label": "GOVT WEL HSS THERVOY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6928",
            "district": "33",
            "Block": "76",
            "label": "ARUL JOTHI MATRICULATION SCHOOL, NEW GUMMIDIPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6929",
            "district": "33",
            "Block": "76",
            "label": "GGHSS GUMMIDIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6930",
            "district": "33",
            "Block": "76",
            "label": "KLK GBHSS GUMMIDIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20922",
            "district": "33",
            "Block": "76",
            "label": "PUMS SANAPUTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21705",
            "district": "33",
            "Block": "76",
            "label": "R.M.K PATASHAALA CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21706",
            "district": "33",
            "Block": "76",
            "label": "T.J.S MATRIC HR.SEC.SCHOOL,PERUVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23048",
            "district": "33",
            "Block": "76",
            "label": "TJS PUBLIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23214",
            "district": "33",
            "Block": "76",
            "label": "ELITE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23601",
            "district": "33",
            "Block": "76",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23601",
            "district": "33",
            "Block": "76",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "77": [
        {
            "key": "2060",
            "district": "6",
            "Block": "77",
            "label": "PUMS KOOKADAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2355",
            "district": "6",
            "Block": "77",
            "label": "PUMS NAARIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2397",
            "district": "6",
            "Block": "77",
            "label": "PUMS ERUMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2474",
            "district": "6",
            "Block": "77",
            "label": "PUMS PATTAVARTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2563",
            "district": "6",
            "Block": "77",
            "label": "PUMS KEELANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2613",
            "district": "6",
            "Block": "77",
            "label": "PUMS SORIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2947",
            "district": "6",
            "Block": "77",
            "label": "PUMS KARUPPILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2948",
            "district": "6",
            "Block": "77",
            "label": "PUMS NACHINAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3025",
            "district": "6",
            "Block": "77",
            "label": "PUMS SANGILIVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3114",
            "district": "6",
            "Block": "77",
            "label": "PUMS SAKKILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3292",
            "district": "6",
            "Block": "77",
            "label": "PUMS H EACHAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3306",
            "district": "6",
            "Block": "77",
            "label": "PUMS ACHALVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3470",
            "district": "6",
            "Block": "77",
            "label": "SATHYAM MHSS, MOPPIRIPATTI, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3582",
            "district": "6",
            "Block": "77",
            "label": "PUMS AVALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3597",
            "district": "6",
            "Block": "77",
            "label": "PUMS PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3610",
            "district": "6",
            "Block": "77",
            "label": "MUTHU MASE MATRIC SCHOOL, HARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3898",
            "district": "6",
            "Block": "77",
            "label": "PUMS GANAPATHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3907",
            "district": "6",
            "Block": "77",
            "label": "PUMS PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3966",
            "district": "6",
            "Block": "77",
            "label": "PUMS CHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4011",
            "district": "6",
            "Block": "77",
            "label": "PUMS KOLAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4340",
            "district": "6",
            "Block": "77",
            "label": "PUMS SENGALERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23385",
            "district": "6",
            "Block": "77",
            "label": " Little flower HR SEC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4352",
            "district": "6",
            "Block": "77",
            "label": "PUMS VEPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4412",
            "district": "6",
            "Block": "77",
            "label": "KURINCHI MHSS, CHINNANKUPPAM, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4416",
            "district": "6",
            "Block": "77",
            "label": "PUMS KOKKARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4436",
            "district": "6",
            "Block": "77",
            "label": "PUMS VEERAPPANAIKKANAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4453",
            "district": "6",
            "Block": "77",
            "label": "PUMS ALAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4524",
            "district": "6",
            "Block": "77",
            "label": "PUMS VALLIMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4731",
            "district": "6",
            "Block": "77",
            "label": "PUMS G PAAPPINAICKANVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4781",
            "district": "6",
            "Block": "77",
            "label": "PUMS MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5228",
            "district": "6",
            "Block": "77",
            "label": "PUMS MANTHIKULAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5268",
            "district": "6",
            "Block": "77",
            "label": "PUMS NAIKUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5318",
            "district": "6",
            "Block": "77",
            "label": "PUMS T ANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5397",
            "district": "6",
            "Block": "77",
            "label": "HIMALAYAN MHSS, GOPALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5749",
            "district": "6",
            "Block": "77",
            "label": "P.D.R.VELLACHIAMMAL MHSS, SEKKAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5886",
            "district": "6",
            "Block": "77",
            "label": "PUMS PORUSUMARATHU VALLAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6062",
            "district": "6",
            "Block": "77",
            "label": "PUMS PARAYAPATTIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6174",
            "district": "6",
            "Block": "77",
            "label": "PUMS SUNDAKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6572",
            "district": "6",
            "Block": "77",
            "label": "PUMS SURANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9489",
            "district": "6",
            "Block": "77",
            "label": "GHS A VELAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9490",
            "district": "6",
            "Block": "77",
            "label": "PUMS  C GOBINATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9491",
            "district": "6",
            "Block": "77",
            "label": "GHS CHINNANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9492",
            "district": "6",
            "Block": "77",
            "label": "GHS ELLAPUDIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9493",
            "district": "6",
            "Block": "77",
            "label": "JAYAM VIDHYALAYA MHSS, H.DHODDAMPATTI, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9494",
            "district": "6",
            "Block": "77",
            "label": "GHSS HARUR BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9495",
            "district": "6",
            "Block": "77",
            "label": "GHSS HARUR GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9496",
            "district": "6",
            "Block": "77",
            "label": "ST. MARY'S HR. SEC. SCHOOL, HARUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9497",
            "district": "6",
            "Block": "77",
            "label": "GHSS JAMMANAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9498",
            "district": "6",
            "Block": "77",
            "label": "GHSS K VETRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9499",
            "district": "6",
            "Block": "77",
            "label": "GHS KEERAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9500",
            "district": "6",
            "Block": "77",
            "label": "GHS KELAPPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9501",
            "district": "6",
            "Block": "77",
            "label": "PUMS KOMARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9502",
            "district": "6",
            "Block": "77",
            "label": "PUMS KONGAVEMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9503",
            "district": "6",
            "Block": "77",
            "label": "ARINGAR ANNA GHSS KOTTAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9504",
            "district": "6",
            "Block": "77",
            "label": "GHS M.THATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9505",
            "district": "6",
            "Block": "77",
            "label": "GHS MAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9506",
            "district": "6",
            "Block": "77",
            "label": "SRI SARASWATHY MHSS, ITLAPPATTI, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9507",
            "district": "6",
            "Block": "77",
            "label": "DR. ANNAL AMBEDKAR GOVT. ADWHSS, MARUTHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9508",
            "district": "6",
            "Block": "77",
            "label": "GHS MATHIAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9509",
            "district": "6",
            "Block": "77",
            "label": "GHSS NARIPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9510",
            "district": "6",
            "Block": "77",
            "label": "GTRHSS NARIPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9511",
            "district": "6",
            "Block": "77",
            "label": "GHS PETHATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9512",
            "district": "6",
            "Block": "77",
            "label": "PUMS RAYAPPAN KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9513",
            "district": "6",
            "Block": "77",
            "label": "GTRHS S.THATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9514",
            "district": "6",
            "Block": "77",
            "label": "GHS SATTAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9515",
            "district": "6",
            "Block": "77",
            "label": "GHS SELLAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9516",
            "district": "6",
            "Block": "77",
            "label": "R.V. HR. SEC. SCHOOL, SENDRAYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9517",
            "district": "6",
            "Block": "77",
            "label": "GHS T AMMAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9518",
            "district": "6",
            "Block": "77",
            "label": "GHSS THEERTHAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9519",
            "district": "6",
            "Block": "77",
            "label": "ERK HR. SEC. SCHOOL, ERUMIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9520",
            "district": "6",
            "Block": "77",
            "label": "GHS VEDAKATTAMADUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9521",
            "district": "6",
            "Block": "77",
            "label": "GHS VELANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9522",
            "district": "6",
            "Block": "77",
            "label": "GHSS VEPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9523",
            "district": "6",
            "Block": "77",
            "label": "BHARATHI VIDYALAYA HIGH SCHOOL, VEPPAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9524",
            "district": "6",
            "Block": "77",
            "label": "GHS BAIRNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9525",
            "district": "6",
            "Block": "77",
            "label": "GHS PARAYAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9526",
            "district": "6",
            "Block": "77",
            "label": "INDIAN MHSS, GOPINATHAMPATTI, PARAIYAPPATTI PUDUR, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9527",
            "district": "6",
            "Block": "77",
            "label": "GHS PONNERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9528",
            "district": "6",
            "Block": "77",
            "label": "GHS SIKKALURE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9529",
            "district": "6",
            "Block": "77",
            "label": "GHSS SITLINGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9530",
            "district": "6",
            "Block": "77",
            "label": "GTRHSS VACHATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21262",
            "district": "6",
            "Block": "77",
            "label": "SRI VIJAYYA VIDHYALAYA MHSS, H.DHODDAMPATTI, HARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21304",
            "district": "6",
            "Block": "77",
            "label": "INDIAN PUBLIC SCHOOL , GOBINATHAMPATTI KOOT ROAD, PARAYAPATTI PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21590",
            "district": "6",
            "Block": "77",
            "label": "SREE KUMARAGURU MATRIC SCHOOL, KOKKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21658",
            "district": "6",
            "Block": "77",
            "label": "SRI ARUNACHALA HIGH SCHOOL, MANDHIKULAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21659",
            "district": "6",
            "Block": "77",
            "label": "BHARATH HI-TECH MHSS, PULUTHIYUR, KOKKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22081",
            "district": "6",
            "Block": "77",
            "label": "MULLAI MHSS, MAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22941",
            "district": "6",
            "Block": "77",
            "label": "MAHARISHI VIDYA MANDIR, PULUTHIYUR, KOKKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22942",
            "district": "6",
            "Block": "77",
            "label": "GHS POYYAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22998",
            "district": "6",
            "Block": "77",
            "label": "HARUR INTERNATIONAL SCHOOL, H.DODDAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23789",
            "district": "6",
            "Block": "77",
            "label": "PUMS CHANDRAPURAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "78": [
        {
            "key": "443",
            "district": "13",
            "Block": "78",
            "label": "ACHIEVERS ACADEMY MATRIC.HSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "999",
            "district": "13",
            "Block": "78",
            "label": "CSI CHRIST MATRIC. HSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1416",
            "district": "13",
            "Block": "78",
            "label": "SRI VIJAY VIDYALAYA MHSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1544",
            "district": "13",
            "Block": "78",
            "label": "SRI SHARADA VIDYA MANDIR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1916",
            "district": "13",
            "Block": "78",
            "label": "ALPHA ELITE HIGH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2464",
            "district": "13",
            "Block": "78",
            "label": "SEVENTHDAY HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2803",
            "district": "13",
            "Block": "78",
            "label": "GREEN VALLEY MAT.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2830",
            "district": "13",
            "Block": "78",
            "label": "VAILANKANNI MHSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2902",
            "district": "13",
            "Block": "78",
            "label": "SRI VENKATESWARA MHSS AVALAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3185",
            "district": "13",
            "Block": "78",
            "label": "PARIMALAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3199",
            "district": "13",
            "Block": "78",
            "label": "SAPTHAGIRI MAT.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3365",
            "district": "13",
            "Block": "78",
            "label": "PUMS ACHETTIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3383",
            "district": "13",
            "Block": "78",
            "label": "VISWA BHARATHI MATRIC.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3457",
            "district": "13",
            "Block": "78",
            "label": "YOGI VEMANA MATRIC.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3466",
            "district": "13",
            "Block": "78",
            "label": "PUMS HOSUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3668",
            "district": "13",
            "Block": "78",
            "label": "PUMS BOMMANDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3798",
            "district": "13",
            "Block": "78",
            "label": "SRI BHARATHI VIDYALAYA MHSS ALASANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3801",
            "district": "13",
            "Block": "78",
            "label": "ST.JOSEPH'S MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3820",
            "district": "13",
            "Block": "78",
            "label": "ST.AUGUSTINE MAT.HR SEC SCHOOL ONNALVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3867",
            "district": "13",
            "Block": "78",
            "label": "ST.ANN'S MATRICULATION.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3880",
            "district": "13",
            "Block": "78",
            "label": "PUMS GOLLAPALLI -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3889",
            "district": "13",
            "Block": "78",
            "label": "PUMS NAGONDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3903",
            "district": "13",
            "Block": "78",
            "label": "PUMS MUGULAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3955",
            "district": "13",
            "Block": "78",
            "label": "PUMS MATHIGIRI BOYS -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3967",
            "district": "13",
            "Block": "78",
            "label": "PUMS SEETHARAM NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3981",
            "district": "13",
            "Block": "78",
            "label": "PUMS KUMUDEPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4017",
            "district": "13",
            "Block": "78",
            "label": "PUMS BEDARAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4050",
            "district": "13",
            "Block": "78",
            "label": "PUMS BAGALUR -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4055",
            "district": "13",
            "Block": "78",
            "label": "PUMS BEGAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4074",
            "district": "13",
            "Block": "78",
            "label": "PUMS ARASANATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4126",
            "district": "13",
            "Block": "78",
            "label": "PUMS PUNGANDODDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4164",
            "district": "13",
            "Block": "78",
            "label": "PUMS GOPANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4248",
            "district": "13",
            "Block": "78",
            "label": "BHAGAVANSHREE RAMAKRISHNA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4263",
            "district": "13",
            "Block": "78",
            "label": "SREE SARASWATHI VID. MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4270",
            "district": "13",
            "Block": "78",
            "label": "PUMS RAM NAGAR- URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4327",
            "district": "13",
            "Block": "78",
            "label": "PUMS KELAVARAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4386",
            "district": "13",
            "Block": "78",
            "label": "PUMS POONAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4390",
            "district": "13",
            "Block": "78",
            "label": "PUMS CHENNASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4423",
            "district": "13",
            "Block": "78",
            "label": "PUMS HANUMANTHAPURAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4500",
            "district": "13",
            "Block": "78",
            "label": "PUMS MUGALUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4526",
            "district": "13",
            "Block": "78",
            "label": "PUMS KOTHAGONDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4680",
            "district": "13",
            "Block": "78",
            "label": "PUMS ZEEMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4736",
            "district": "13",
            "Block": "78",
            "label": "PUMS HOSUR -TEL&KAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5026",
            "district": "13",
            "Block": "78",
            "label": "PUMS ALASANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5340",
            "district": "13",
            "Block": "78",
            "label": "PUMS CHENNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5576",
            "district": "13",
            "Block": "78",
            "label": "SHANTHI NIKETHAN MHSS, S.MUDUGANAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5603",
            "district": "13",
            "Block": "78",
            "label": "SRI SATHYA SAI BALAGURUKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5613",
            "district": "13",
            "Block": "78",
            "label": "BETHEL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6009",
            "district": "13",
            "Block": "78",
            "label": "ST.ANNE'S MAT.SCHOOL BAGALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6429",
            "district": "13",
            "Block": "78",
            "label": "BHARATHIYAR MAT.SCHOOL, BALAJI NAGAR, CHINNAELASAGIRI, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20727",
            "district": "13",
            "Block": "78",
            "label": "GHS ANDIVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20728",
            "district": "13",
            "Block": "78",
            "label": "GHSS-GIRLS  BAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20729",
            "district": "13",
            "Block": "78",
            "label": "GHSS-BOYS  BAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20730",
            "district": "13",
            "Block": "78",
            "label": "SHANTHIBAVAN RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20731",
            "district": "13",
            "Block": "78",
            "label": "GHS BHARATHIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20732",
            "district": "13",
            "Block": "78",
            "label": "GHS DASARAPALLIDINNA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20733",
            "district": "13",
            "Block": "78",
            "label": "GHS DEVEERAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20734",
            "district": "13",
            "Block": "78",
            "label": "GOVT URDU HR. SEC. SCHOOL - HOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20735",
            "district": "13",
            "Block": "78",
            "label": "JOHN BOSCO GIRLS HSS HOSUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20736",
            "district": "13",
            "Block": "78",
            "label": "RV GHSS-BOYS HOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20737",
            "district": "13",
            "Block": "78",
            "label": "GOVT. MODEL GIRLS HR SEC SCHOOL  HOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20738",
            "district": "13",
            "Block": "78",
            "label": "BLOSSOM MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20739",
            "district": "13",
            "Block": "78",
            "label": "CAMBRIDGE MAT.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20740",
            "district": "13",
            "Block": "78",
            "label": "DAVID SIRONMANI MAT.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20741",
            "district": "13",
            "Block": "78",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20742",
            "district": "13",
            "Block": "78",
            "label": "SWATHI MHSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20743",
            "district": "13",
            "Block": "78",
            "label": "SIDDARTH VILLAGE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20744",
            "district": "13",
            "Block": "78",
            "label": "SISHYA SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20745",
            "district": "13",
            "Block": "78",
            "label": "ASIAN CHRISTIAN HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20746",
            "district": "13",
            "Block": "78",
            "label": "THE ASHOK LEYLAND SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20747",
            "district": "13",
            "Block": "78",
            "label": "GHSS, MULLAINAGAR, HOSUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20748",
            "district": "13",
            "Block": "78",
            "label": "GHS-GIRLS  HOSUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20749",
            "district": "13",
            "Block": "78",
            "label": "SHANTHI NIKETHAN MATRIC SCHOOL, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20750",
            "district": "13",
            "Block": "78",
            "label": "GHS KOTHAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20751",
            "district": "13",
            "Block": "78",
            "label": "GHSS MACHINAYAKANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20752",
            "district": "13",
            "Block": "78",
            "label": "GHSS MATHIGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20753",
            "district": "13",
            "Block": "78",
            "label": "THE TITAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20754",
            "district": "13",
            "Block": "78",
            "label": "GHS MOOKANDAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20755",
            "district": "13",
            "Block": "78",
            "label": "GHSS NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20756",
            "district": "13",
            "Block": "78",
            "label": "GHS ONNALVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20757",
            "district": "13",
            "Block": "78",
            "label": "GURUDHURONACHARIA HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20758",
            "district": "13",
            "Block": "78",
            "label": "NATIONAL MATRIC.HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20759",
            "district": "13",
            "Block": "78",
            "label": "GHS SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20760",
            "district": "13",
            "Block": "78",
            "label": "GHS THORAPALLI AGRAHARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20761",
            "district": "13",
            "Block": "78",
            "label": "VANAPRASTHA INTERNATIONAL SCHOOL, MUGALAPALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20762",
            "district": "13",
            "Block": "78",
            "label": "MOUNT CARMEL MATRI HR.SEC. SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20763",
            "district": "13",
            "Block": "78",
            "label": "GHSS ZUZUVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20764",
            "district": "13",
            "Block": "78",
            "label": "OPEN ARMS INTERNATIONAL SCHOOL,HOSUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20765",
            "district": "13",
            "Block": "78",
            "label": "GHS CHOODAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20766",
            "district": "13",
            "Block": "78",
            "label": "GHS MUTHALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21042",
            "district": "13",
            "Block": "78",
            "label": "ADVAITH INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21043",
            "district": "13",
            "Block": "78",
            "label": "PARIMALAM MATRIC SCHOOL, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21044",
            "district": "13",
            "Block": "78",
            "label": "CARMEL INTERNANATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21135",
            "district": "13",
            "Block": "78",
            "label": "PUMS THORAPALLI -TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21186",
            "district": "13",
            "Block": "78",
            "label": "NALANDA INTERNATIONAL PUBLIC SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21250",
            "district": "13",
            "Block": "78",
            "label": "ST.JOSEPH'S ACADEMY, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21293",
            "district": "13",
            "Block": "78",
            "label": "SWAMY  VIVEKANANDA MATRIC HSS, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21348",
            "district": "13",
            "Block": "78",
            "label": "SRI GURUKULAM SENIOR SECONDARY SCHOOL, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21349",
            "district": "13",
            "Block": "78",
            "label": "LITERA VALLEY ZEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21570",
            "district": "13",
            "Block": "78",
            "label": "MAHARISHI VIDYA MANDIR HSS HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21805",
            "district": "13",
            "Block": "78",
            "label": "SRI VIJAY VIDHYASHRAM SCHOOL HOSUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21806",
            "district": "13",
            "Block": "78",
            "label": "SREE SHIRDI SAI VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21967",
            "district": "13",
            "Block": "78",
            "label": "NEW BRILLIANT IDEAL MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21976",
            "district": "13",
            "Block": "78",
            "label": "VANAPRASTHA INTERNATIONAL MATRIC HR SEC SCHOOL-THUMMANAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22297",
            "district": "13",
            "Block": "78",
            "label": "SRI CHAITANYA TECHNO SCHOOL DINNUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22298",
            "district": "13",
            "Block": "78",
            "label": "ADITI VIDYA MANDIR MATRICULATION SCHOOL, THIMMASANDRAM, AVALAPALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22299",
            "district": "13",
            "Block": "78",
            "label": "HOSUR PUBLIC  SCHOOL-KARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22676",
            "district": "13",
            "Block": "78",
            "label": "ANNAI ARAVINTHAR SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22868",
            "district": "13",
            "Block": "78",
            "label": "SRI VIJAY VIDYASHRAM BAGALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22891",
            "district": "13",
            "Block": "78",
            "label": "GHS NANDHIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22996",
            "district": "13",
            "Block": "78",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23022",
            "district": "13",
            "Block": "78",
            "label": "GREEN VALLEY SCHOOL, HOSUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23496",
            "district": "13",
            "Block": "78",
            "label": "AGASTHYA VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23497",
            "district": "13",
            "Block": "78",
            "label": "BRUNDAVANA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23498",
            "district": "13",
            "Block": "78",
            "label": "THE OAKRICH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23499",
            "district": "13",
            "Block": "78",
            "label": "ST. XAVIER'S ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23500",
            "district": "13",
            "Block": "78",
            "label": "BMR GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23496",
            "district": "13",
            "Block": "78",
            "label": "AGASTHYA VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23497",
            "district": "13",
            "Block": "78",
            "label": "BRUNDAVANA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23498",
            "district": "13",
            "Block": "78",
            "label": "THE OAKRICH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23499",
            "district": "13",
            "Block": "78",
            "label": "ST. XAVIER'S ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23500",
            "district": "13",
            "Block": "78",
            "label": "BMR GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23790",
            "district": "13",
            "Block": "78",
            "label": "GHSS NALLUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23791",
            "district": "13",
            "Block": "78",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23792",
            "district": "13",
            "Block": "78",
            "label": "NIMAI PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "79": [
        {
            "key": "793",
            "district": "23",
            "Block": "79",
            "label": "PUMS SATHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1119",
            "district": "23",
            "Block": "79",
            "label": "PUMS NENJATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1493",
            "district": "23",
            "Block": "79",
            "label": "PUMS S.KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1689",
            "district": "23",
            "Block": "79",
            "label": "PUMS, S.VANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1810",
            "district": "23",
            "Block": "79",
            "label": "PUMS AIYANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2637",
            "district": "23",
            "Block": "79",
            "label": "PUMS MELAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2674",
            "district": "23",
            "Block": "79",
            "label": "PUMS NAGARAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2739",
            "district": "23",
            "Block": "79",
            "label": "IQRA MATRIC SCHOOL,, ILAIYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2788",
            "district": "23",
            "Block": "79",
            "label": "M N A S MIDDLE SCHOOL SALAIYOOR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2900",
            "district": "23",
            "Block": "79",
            "label": "PUMS MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2949",
            "district": "23",
            "Block": "79",
            "label": "PUMS KEELANETTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3060",
            "district": "23",
            "Block": "79",
            "label": "St.ANNES MATRICULATION SCHOOL, ILAYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3284",
            "district": "23",
            "Block": "79",
            "label": "ST.MARY MID.SCH, SALAIGRAMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3402",
            "district": "23",
            "Block": "79",
            "label": "PUMS KONGAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3536",
            "district": "23",
            "Block": "79",
            "label": "PUMS PUDUR VALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3607",
            "district": "23",
            "Block": "79",
            "label": "PUMS N.ANDAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3745",
            "district": "23",
            "Block": "79",
            "label": "PUMS, A.NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4082",
            "district": "23",
            "Block": "79",
            "label": "ST.J.MID.SCHOOL, NALLOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4446",
            "district": "23",
            "Block": "79",
            "label": "PUMS A. MEIYANENDHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4481",
            "district": "23",
            "Block": "79",
            "label": "NALMEIPER MATRICULATION SCHOOL , VARUNTHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4769",
            "district": "23",
            "Block": "79",
            "label": "St.MARY'S MIDDLE SCHOOL  SOORANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4891",
            "district": "23",
            "Block": "79",
            "label": "PUMS KANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4926",
            "district": "23",
            "Block": "79",
            "label": "PUMS THAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5166",
            "district": "23",
            "Block": "79",
            "label": "MUTHU VINAYAGAR MIDDLE SCHOOL , VADAKKU SAMUTHIRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5167",
            "district": "23",
            "Block": "79",
            "label": "PUMS THIRUVALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5334",
            "district": "23",
            "Block": "79",
            "label": "PUMS KARUNJUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5402",
            "district": "23",
            "Block": "79",
            "label": "PUMS MELATHURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5429",
            "district": "23",
            "Block": "79",
            "label": "I.N.P.T MATRIC HS, ILAIYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6713",
            "district": "23",
            "Block": "79",
            "label": "BHARATHIYAR MATRICULATION SCHOOL SOORANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6739",
            "district": "23",
            "Block": "79",
            "label": "ROMAN CATHOLIC MID SCHOOL KOTTAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17549",
            "district": "23",
            "Block": "79",
            "label": "ST EUGENE HIGH SCHOOL, KOMBADIMADURAI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17550",
            "district": "23",
            "Block": "79",
            "label": "ST. ANTONY'S MIDDLE SCHOOL, KALVELIPOTTAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17551",
            "district": "23",
            "Block": "79",
            "label": "BONIFACE HS , ARIYANDIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17552",
            "district": "23",
            "Block": "79",
            "label": "GOVT. HSS MUNAIVENDRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17553",
            "district": "23",
            "Block": "79",
            "label": "GOVT HIGH SCHOOL  KATCHATHANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17554",
            "district": "23",
            "Block": "79",
            "label": "VEERAMA MUNIVAR.MID.SCHOOL, OCCHANTHATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17555",
            "district": "23",
            "Block": "79",
            "label": "ST.JAMES HSS, SOORANAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17556",
            "district": "23",
            "Block": "79",
            "label": "ST ANTONY'S HIGH SCHOOL, KALLADITHIDAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17557",
            "district": "23",
            "Block": "79",
            "label": "GOVT HIGH SCHOOL ALAVIDANGAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17558",
            "district": "23",
            "Block": "79",
            "label": "GOVT HSS, SALAIGRAMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17559",
            "district": "23",
            "Block": "79",
            "label": "GOVT HIGHER SEC SCHOOL SATHANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17560",
            "district": "23",
            "Block": "79",
            "label": "PUMS MARUTHANGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17561",
            "district": "23",
            "Block": "79",
            "label": "R.C HIGH SCHOOL, KUNDUKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17562",
            "district": "23",
            "Block": "79",
            "label": "PUMS, THUGAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17563",
            "district": "23",
            "Block": "79",
            "label": "HAJEE KK IBRAHIM ALI HR SEC SCHOOL PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17564",
            "district": "23",
            "Block": "79",
            "label": "HAMEEDIA HSS, SALAIYOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17565",
            "district": "23",
            "Block": "79",
            "label": "MELAPALLI VASAL (G) HSS, ILAIYANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17566",
            "district": "23",
            "Block": "79",
            "label": "ILAYANGUDI HSS , ILAYANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17567",
            "district": "23",
            "Block": "79",
            "label": "GOVT (G) HIGH SCHOOL ILAYANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22237",
            "district": "23",
            "Block": "79",
            "label": "ILM PUBLIC SCHOOL ILAYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "80": [
        {
            "key": "5219",
            "district": "34",
            "Block": "80",
            "label": "PUMS THURINJIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9974",
            "district": "34",
            "Block": "80",
            "label": "PUMS ALANJANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9975",
            "district": "34",
            "Block": "80",
            "label": "ST JOSEPH HSS ATHIPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9976",
            "district": "34",
            "Block": "80",
            "label": "PUMS BANDIREW",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9977",
            "district": "34",
            "Block": "80",
            "label": "KGBVRS GUNDALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9978",
            "district": "34",
            "Block": "80",
            "label": "TRIBAL HSS GUNIGANTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9979",
            "district": "34",
            "Block": "80",
            "label": "PUMS IRULAMPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9980",
            "district": "34",
            "Block": "80",
            "label": "FOREST HSS JAMUNAMARATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9981",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS JAMMUNAMARATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9982",
            "district": "34",
            "Block": "80",
            "label": "PUMS KALLATHUR(P)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9983",
            "district": "34",
            "Block": "80",
            "label": "PUMS KALLIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9984",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS KALYANAMANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9985",
            "district": "34",
            "Block": "80",
            "label": "PUMS KANAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9986",
            "district": "34",
            "Block": "80",
            "label": "GHS KILAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9987",
            "district": "34",
            "Block": "80",
            "label": "PUMS KILKANAVAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9988",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS KILVILAMUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9989",
            "district": "34",
            "Block": "80",
            "label": "PUMS KOOTTATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9990",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9991",
            "district": "34",
            "Block": "80",
            "label": "KGBVRS AMMATANKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9992",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS MELPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9993",
            "district": "34",
            "Block": "80",
            "label": "PUMS MUTHANATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9994",
            "district": "34",
            "Block": "80",
            "label": "KGBVRS MUTHANATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9995",
            "district": "34",
            "Block": "80",
            "label": "GTR HSS NAMMIYAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9996",
            "district": "34",
            "Block": "80",
            "label": "GTRMS OORGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9997",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS PULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9998",
            "district": "34",
            "Block": "80",
            "label": "KGBVRS PULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9999",
            "district": "34",
            "Block": "80",
            "label": "GTRMS SERAMARATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10000",
            "district": "34",
            "Block": "80",
            "label": "PUMS THANIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10001",
            "district": "34",
            "Block": "80",
            "label": "PUMS THOPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10002",
            "district": "34",
            "Block": "80",
            "label": "PUMS THUMBAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10003",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS VEERAPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10004",
            "district": "34",
            "Block": "80",
            "label": "PUMS VELLIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10005",
            "district": "34",
            "Block": "80",
            "label": "FOREST MS VAZHAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10006",
            "district": "34",
            "Block": "80",
            "label": "GHS VEDAKOLLAIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21201",
            "district": "34",
            "Block": "80",
            "label": "Govt Model Higher Sec school perungattur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22092",
            "district": "34",
            "Block": "80",
            "label": "GTRHSS-ATTIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22093",
            "district": "34",
            "Block": "80",
            "label": "GTRHS-KOVILANDOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22094",
            "district": "34",
            "Block": "80",
            "label": "GTRHSS-PATTARAIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22425",
            "district": "34",
            "Block": "80",
            "label": "EKLAVYA MODEL RESIDENTIAL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23154",
            "district": "34",
            "Block": "80",
            "label": "GTRHS ARASUVELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23793",
            "district": "34",
            "Block": "80",
            "label": "AUXILIUM MATRICULATION SCHOOL, JAMUNAMARATHUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "81": [
        {
            "key": "2708",
            "district": "1",
            "Block": "81",
            "label": "PUMS - SOORIYAMANAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2890",
            "district": "1",
            "Block": "81",
            "label": "FATIMA MATRICULATION HIGHER SECONDARY SCHOOL  JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3120",
            "district": "1",
            "Block": "81",
            "label": "PUMS-PADAINILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4043",
            "district": "1",
            "Block": "81",
            "label": "MODERN MATRICULATION  HIGHER  SECONDARY  SCHOOL  JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4470",
            "district": "1",
            "Block": "81",
            "label": "PUMS-EDAIKATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4824",
            "district": "1",
            "Block": "81",
            "label": "PUMS-VANAVANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5112",
            "district": "1",
            "Block": "81",
            "label": "RAJAN MIDDLE SCHOOL OKKANATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5194",
            "district": "1",
            "Block": "81",
            "label": "PUMS-MELAKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5245",
            "district": "1",
            "Block": "81",
            "label": "PUMS-NARASINGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5395",
            "district": "1",
            "Block": "81",
            "label": "PUMS-VANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5439",
            "district": "1",
            "Block": "81",
            "label": "PUMS-KAZHUVANTHONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5545",
            "district": "1",
            "Block": "81",
            "label": "PUMS-THAZHUTHAZAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5661",
            "district": "1",
            "Block": "81",
            "label": "PERIYAR MATRICULATION  HSS JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5877",
            "district": "1",
            "Block": "81",
            "label": "ST.SEPASTIAN MIDDLE SCHOOL VIZHAPPALLAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5967",
            "district": "1",
            "Block": "81",
            "label": "GMS-AYUTHAKALAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6023",
            "district": "1",
            "Block": "81",
            "label": "PUMS-PICHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6256",
            "district": "1",
            "Block": "81",
            "label": "PUMS-UDAIYARPALAYAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6343",
            "district": "1",
            "Block": "81",
            "label": "PUMS-PUDHUCHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6585",
            "district": "1",
            "Block": "81",
            "label": "MEENAAKSHI RAMASAMY MATRIC HSS THATHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6675",
            "district": "1",
            "Block": "81",
            "label": "PUMS-SALUPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15046",
            "district": "1",
            "Block": "81",
            "label": "GMHSS JAYANKONDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15047",
            "district": "1",
            "Block": "81",
            "label": "FATHIMA (G) HSS JAYANKONDAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15048",
            "district": "1",
            "Block": "81",
            "label": "GHS (G) JAYANKONDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15049",
            "district": "1",
            "Block": "81",
            "label": "ANNAI TERESA MATRICULATION SCHOOL JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15050",
            "district": "1",
            "Block": "81",
            "label": "GHSS (G) UDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15051",
            "district": "1",
            "Block": "81",
            "label": "NOWFAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15052",
            "district": "1",
            "Block": "81",
            "label": "GHSS UDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15053",
            "district": "1",
            "Block": "81",
            "label": "SRI ANNAI MATRICULATION SCHOOL UDAYARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15054",
            "district": "1",
            "Block": "81",
            "label": "GHS CHINNAVALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15055",
            "district": "1",
            "Block": "81",
            "label": "PUMS-T.KUDIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15056",
            "district": "1",
            "Block": "81",
            "label": "MEENAAKSHI RAMASAMY HSS THATHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15057",
            "district": "1",
            "Block": "81",
            "label": "GHSS T.KEELAVELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15058",
            "district": "1",
            "Block": "81",
            "label": "GHSS T.POTTAKKOLLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15059",
            "district": "1",
            "Block": "81",
            "label": "PUMS-SUNDIPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15060",
            "district": "1",
            "Block": "81",
            "label": "GHS MUTHUSERVAMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15061",
            "district": "1",
            "Block": "81",
            "label": "GHSS MEENSURUTTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15062",
            "district": "1",
            "Block": "81",
            "label": "GHSS (G) MEENSURUTTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15063",
            "district": "1",
            "Block": "81",
            "label": "PUMS-VEERABOGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15064",
            "district": "1",
            "Block": "81",
            "label": "GHSS KALLATHUR THANDALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15065",
            "district": "1",
            "Block": "81",
            "label": "R C FATHIMA HS VADAVEEKKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15066",
            "district": "1",
            "Block": "81",
            "label": "GHS ADW VETHIYARVETTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15067",
            "district": "1",
            "Block": "81",
            "label": "GHS ERAVANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15068",
            "district": "1",
            "Block": "81",
            "label": "GOVT HSS AYYAPPANAYAKKANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15069",
            "district": "1",
            "Block": "81",
            "label": "GHSS MELANIKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15070",
            "district": "1",
            "Block": "81",
            "label": "CSI HSS MAHIMAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15071",
            "district": "1",
            "Block": "81",
            "label": "GHS SENGUNTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15072",
            "district": "1",
            "Block": "81",
            "label": "PUMS-KACHIPERUMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15073",
            "district": "1",
            "Block": "81",
            "label": "RAJA SATHA BAKKIYAM HIGH SCHOOL THULARANKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15074",
            "district": "1",
            "Block": "81",
            "label": "PUMS-KADARANKONDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15075",
            "district": "1",
            "Block": "81",
            "label": "GHSS ULKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15076",
            "district": "1",
            "Block": "81",
            "label": "GHSS GANGAIKONDACHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15077",
            "district": "1",
            "Block": "81",
            "label": "HELAN KELLER SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15078",
            "district": "1",
            "Block": "81",
            "label": "GHSS VANATHIRAYANPATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15079",
            "district": "1",
            "Block": "81",
            "label": "PUMS MEIKKAVALPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15080",
            "district": "1",
            "Block": "81",
            "label": "INDRA PUBLIC HR SEC SCHOOL THALUTHALAIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15081",
            "district": "1",
            "Block": "81",
            "label": "PUMS-EDAIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21248",
            "district": "1",
            "Block": "81",
            "label": "ST.ANDREW S MATRIC SCHOOL-JAYANKONDAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21384",
            "district": "1",
            "Block": "81",
            "label": "GHS KOLLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21551",
            "district": "1",
            "Block": "81",
            "label": "NALLASIRIAR THIRU RAMACHANDRA HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21552",
            "district": "1",
            "Block": "81",
            "label": "MRV RAJA HR SEC SCHOOL PAPPAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21800",
            "district": "1",
            "Block": "81",
            "label": "MEENAKSHI RAMASAMY VIDHYALAYA THANTHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22545",
            "district": "1",
            "Block": "81",
            "label": "N.R.PUBLIC SCHOOL UDAYARPLAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22546",
            "district": "1",
            "Block": "81",
            "label": "GOVERNMENT HIGH SCHOOL T.CHOZHANKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22909",
            "district": "1",
            "Block": "81",
            "label": "GHS DEVAMANGALAM-S",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23094",
            "district": "1",
            "Block": "81",
            "label": "SRI LAKSHMI VIDYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23342",
            "district": "1",
            "Block": "81",
            "label": "JAI HIND INTERNATIOANL VIDHYALAYA SCHOOL GURUVALAPPARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23427",
            "district": "1",
            "Block": "81",
            "label": "PM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23428",
            "district": "1",
            "Block": "81",
            "label": "JAIHINDH INTERNATIONAL VIDHYALAYAV",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23427",
            "district": "1",
            "Block": "81",
            "label": "PM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23428",
            "district": "1",
            "Block": "81",
            "label": "JAIHINDH INTERNATIONAL VIDHYALAYAV",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23427",
            "district": "1",
            "Block": "81",
            "label": "PM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23428",
            "district": "1",
            "Block": "81",
            "label": "JAIHINDH INTERNATIONAL VIDHYALAYAV",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23427",
            "district": "1",
            "Block": "81",
            "label": "PM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23428",
            "district": "1",
            "Block": "81",
            "label": "JAIHINDH INTERNATIONAL VIDHYALAYAV",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "82": [
        {
            "key": "2689",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KALNARSAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        { 
            "key":"24123", "district":"31", 
            "Block":"82", 
            "label": "railway mixed higher secondary school", 
            "Sch_type": "Un-aided", "Category": "Higher Secondary School"
        },
        {
            "key": "3047",
            "district": "31",
            "Block": "82",
            "label": "PUMS, MANDALAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3161",
            "district": "31",
            "Block": "82",
            "label": "PUMS, OOSINATTAN KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3270",
            "district": "31",
            "Block": "82",
            "label": "ST.PAULS MHSS CHETTIYAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3371",
            "district": "31",
            "Block": "82",
            "label": "BOSTON MATRIC HSS, JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3843",
            "district": "31",
            "Block": "82",
            "label": "ST.CHARLES MHSS THIRUPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4132",
            "district": "31",
            "Block": "82",
            "label": "PUMS ODDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4546",
            "district": "31",
            "Block": "82",
            "label": "PUMS, ELAGIRI VILLAGE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5532",
            "district": "31",
            "Block": "82",
            "label": "EBENEZER MAT. HR.SEC.SCHOOL, YELAGIRI HILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6316",
            "district": "31",
            "Block": "82",
            "label": "ST.CHARLES MATRIC HSS, ATHANAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6391",
            "district": "31",
            "Block": "82",
            "label": "VAANI MATRIC HSS, CHETTIYAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6544",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KATTERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6663",
            "district": "31",
            "Block": "82",
            "label": "PUMS, JOLARPET (SOUTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8944",
            "district": "31",
            "Block": "82",
            "label": "SAINT FRANCIS DE SALES MATRIC HSS JANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8945",
            "district": "31",
            "Block": "82",
            "label": "SIGARAM MATRIC HSS, CHETTIYAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9012",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KAVERIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9013",
            "district": "31",
            "Block": "82",
            "label": "PUMS, POONAIKUTTAIPALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9014",
            "district": "31",
            "Block": "82",
            "label": "PUMS, CHINNA MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9015",
            "district": "31",
            "Block": "82",
            "label": "PUMS CHINNAGOUNDANUR (P)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9016",
            "district": "31",
            "Block": "82",
            "label": "GOVT HSS PONNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9017",
            "district": "31",
            "Block": "82",
            "label": "GGHSS JOLARPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9018",
            "district": "31",
            "Block": "82",
            "label": "ST.JOSEPH GIRLS HSS JOLARPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9019",
            "district": "31",
            "Block": "82",
            "label": "AHSS DONBOSCO - JOLARPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9020",
            "district": "31",
            "Block": "82",
            "label": "GOVT HSS VAKKANAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9021",
            "district": "31",
            "Block": "82",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  JOLARPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9022",
            "district": "31",
            "Block": "82",
            "label": "SRI RAMAKRISHNA VIDHYALAYA MHSS JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9023",
            "district": "31",
            "Block": "82",
            "label": "INFANT JESUS MATRIC SCHOOL, JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9024",
            "district": "31",
            "Block": "82",
            "label": "UNIVERSAL MATRIC HSS, JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9025",
            "district": "31",
            "Block": "82",
            "label": "GHSS ATHANAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9026",
            "district": "31",
            "Block": "82",
            "label": "ST.CHARLES AHSS ATHANAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9027",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KATTERI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9028",
            "district": "31",
            "Block": "82",
            "label": "Sri Vinayaka Matriculation School,",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9029",
            "district": "31",
            "Block": "82",
            "label": "GHSS  THAMALERIMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9030",
            "district": "31",
            "Block": "82",
            "label": "PUMS, POOSANIKKAIVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9031",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS THIRIYALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9032",
            "district": "31",
            "Block": "82",
            "label": "DEWAN MOHAMED MEMORIAL MATRIC  HR SEC SCHOOL ,  KETHANDAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9033",
            "district": "31",
            "Block": "82",
            "label": "GHS CHINNAKAMMIYAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9034",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS VETTAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9035",
            "district": "31",
            "Block": "82",
            "label": "PUMS, CHINNAVEPPAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9036",
            "district": "31",
            "Block": "82",
            "label": "PUMS CHINNAKALLUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9037",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KUNICHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9038",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KITTAPAIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9039",
            "district": "31",
            "Block": "82",
            "label": "PUMS, VEERAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9040",
            "district": "31",
            "Block": "82",
            "label": "GOVT HSS VELAKKALNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9041",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS CHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9042",
            "district": "31",
            "Block": "82",
            "label": "PUMS, VEPPALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9043",
            "district": "31",
            "Block": "82",
            "label": "GHSS JAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9044",
            "district": "31",
            "Block": "82",
            "label": "PUMS, SUNNAMBU KUTTAI (IVIL)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9045",
            "district": "31",
            "Block": "82",
            "label": "PUMS, MUTHANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9046",
            "district": "31",
            "Block": "82",
            "label": "GHSS MALLAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9047",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9048",
            "district": "31",
            "Block": "82",
            "label": "PUMS, PUDUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9049",
            "district": "31",
            "Block": "82",
            "label": "GOVT BHSS PUDUPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9050",
            "district": "31",
            "Block": "82",
            "label": "GOVT (G)HSS PUDUPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9051",
            "district": "31",
            "Block": "82",
            "label": "PUMS, OM SAKTHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9052",
            "district": "31",
            "Block": "82",
            "label": "GOVT HIGH SCHOOL AGRAHARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9053",
            "district": "31",
            "Block": "82",
            "label": "PUMS, MOOKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9054",
            "district": "31",
            "Block": "82",
            "label": "PUMS  PUDUR MOOKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9055",
            "district": "31",
            "Block": "82",
            "label": "PUMS ADIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9056",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS PARANDAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9057",
            "district": "31",
            "Block": "82",
            "label": "GOVT HS PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9058",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KURUMBER  COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9059",
            "district": "31",
            "Block": "82",
            "label": "DON BOSCO MATRIC HSS, THIRUPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9060",
            "district": "31",
            "Block": "82",
            "label": "ANT MATRIC SCHOOL, THIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9061",
            "district": "31",
            "Block": "82",
            "label": "PUMS, PULLANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20909",
            "district": "31",
            "Block": "82",
            "label": "GREENLAND MATRIC SCHOOL, PUDUPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20951",
            "district": "31",
            "Block": "82",
            "label": "JOLARPET THIRUVALLUVAR MATRIC SCHOOL, JOLARPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21009",
            "district": "31",
            "Block": "82",
            "label": "VEDHA MATRIC HSS, T.VEERAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21010",
            "district": "31",
            "Block": "82",
            "label": "C.S MATRIC HSS, JAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21011",
            "district": "31",
            "Block": "82",
            "label": "GREEN VALLEY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21130",
            "district": "31",
            "Block": "82",
            "label": "PUMS, SANJEEVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21198",
            "district": "31",
            "Block": "82",
            "label": "GHSS KETHANDAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21259",
            "district": "31",
            "Block": "82",
            "label": "PEACE GARDEN MATRIC HSS, YELAGIRI HILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21300",
            "district": "31",
            "Block": "82",
            "label": "SHEMFORD FUTURISTIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21438",
            "district": "31",
            "Block": "82",
            "label": "GHS, NEKKUNDHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21439",
            "district": "31",
            "Block": "82",
            "label": "GHS ASHOK NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21576",
            "district": "31",
            "Block": "82",
            "label": "Sri Narayani Vidhyalaya matric school",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22072",
            "district": "31",
            "Block": "82",
            "label": "DON BOSCO SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22397",
            "district": "31",
            "Block": "82",
            "label": "GOVT HIGH SCHOOL KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22398",
            "district": "31",
            "Block": "82",
            "label": "GOVT HIGH SCHOOL KONAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22948",
            "district": "31",
            "Block": "82",
            "label": "GHS, CHINNAMOOKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23590",
            "district": "31",
            "Block": "82",
            "label": "DEVI VENKATACHALAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23590",
            "district": "31",
            "Block": "82",
            "label": "DEVI VENKATACHALAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23794",
            "district": "31",
            "Block": "82",
            "label": "PUMS, KATTUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "83": [
        {
            "key": "3993",
            "district": "12",
            "Block": "83",
            "label": "RN MATRIC.HR.SEC.SCHOOL,CHINNADHARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6012",
            "district": "12",
            "Block": "83",
            "label": "CHERAN MATRIC HR SEC SCHOOL, PUNNAMCHATHIRAM, KARUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6385",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL,SALIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6638",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL, KARUDAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13912",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,PANDILINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13913",
            "district": "12",
            "Block": "83",
            "label": "GOVT.HIGH.SCHOOL,KARVAZHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13914",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,ATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13915",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL, PUNNAM CHATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13916",
            "district": "12",
            "Block": "83",
            "label": "GOVT.ADW.H.S.SCHOOL,PUNNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13917",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL, THATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13918",
            "district": "12",
            "Block": "83",
            "label": "AASEE HR SEC SCHOOL,PAVITHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13919",
            "district": "12",
            "Block": "83",
            "label": "G.H.S.PAVITHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13920",
            "district": "12",
            "Block": "83",
            "label": "GOVT.HR.SEC.SCHOOL,K.PARAMATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13921",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL,THULUKKAM PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13922",
            "district": "12",
            "Block": "83",
            "label": "GOVT.HR.SEC.SCHOOL,THENNILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13923",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,M.THOTTAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13924",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID..SCHOOL, KODANTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13925",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,C.GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13926",
            "district": "12",
            "Block": "83",
            "label": "GHS.PERIYATHIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13927",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,ARANGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13928",
            "district": "12",
            "Block": "83",
            "label": "GOVT. BOYS. HR..SEC.SCHOOL,CHINNADHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13929",
            "district": "12",
            "Block": "83",
            "label": "GOVT GIRLS HR SEC SCHOOL,CHINNADHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13930",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,KASIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13931",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, ELLAIMETTUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13932",
            "district": "12",
            "Block": "83",
            "label": "UMAYAL HIGH SCHOOL,EZHIL KUDIL,MUDIGANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13933",
            "district": "12",
            "Block": "83",
            "label": "GOVT HR SEC SCHOOL,THUMBIVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13934",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL,VISWANATHAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13935",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, NANJAIKALAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13936",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, ELAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13937",
            "district": "12",
            "Block": "83",
            "label": "GOVT HR SEC SCHOOL,ELAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13938",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, RAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13939",
            "district": "12",
            "Block": "83",
            "label": "P.U.MID.SCHOOL, NAMBAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21162",
            "district": "12",
            "Block": "83",
            "label": "STAR MATRICULATION HR.SEC. SCHOOL, PAVITHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21355",
            "district": "12",
            "Block": "83",
            "label": "THE INDIAN PUBLIC SCHOOL, POOLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23045",
            "district": "12",
            "Block": "83",
            "label": "VENUS GLOBAL CAMPUS SCHOOL(CBSE),PUNNAMCHATHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23493",
            "district": "12",
            "Block": "83",
            "label": "CHERAN UNIVERSAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23493",
            "district": "12",
            "Block": "83",
            "label": "CHERAN UNIVERSAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "84": [
        {
            "key": "1291",
            "district": "36",
            "Block": "84",
            "label": "SRI VIDHYALAKSHMI MATRIC HSS, CHENNANGKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5404",
            "district": "36",
            "Block": "84",
            "label": "LITTLE STARS MATRIC HS  LATHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5581",
            "district": "36",
            "Block": "84",
            "label": "PUMS CHENNARAYANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5780",
            "district": "36",
            "Block": "84",
            "label": "PUMS B.N.PALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6460",
            "district": "36",
            "Block": "84",
            "label": "PUMS KAVASAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6573",
            "district": "36",
            "Block": "84",
            "label": "PUMS P.K.PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8793",
            "district": "36",
            "Block": "84",
            "label": "GHSS , GUDIYATHAM RS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8794",
            "district": "36",
            "Block": "84",
            "label": "PUMS SETHUVANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8795",
            "district": "36",
            "Block": "84",
            "label": "SARASWATHI VIDHYALAYA MATRIC HSS , SETHUVANDAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8796",
            "district": "36",
            "Block": "84",
            "label": "GHS DEVARISHIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8797",
            "district": "36",
            "Block": "84",
            "label": "ADWMS NAGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8798",
            "district": "36",
            "Block": "84",
            "label": "PUMS HYDERPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8799",
            "district": "36",
            "Block": "84",
            "label": "GHS , PASUMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8800",
            "district": "36",
            "Block": "84",
            "label": "SGR GHSS KOSAVANPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8801",
            "district": "36",
            "Block": "84",
            "label": "PUMS MELKAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8802",
            "district": "36",
            "Block": "84",
            "label": "GHS , MACHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8803",
            "district": "36",
            "Block": "84",
            "label": "PUMS MOOLAKANGKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8804",
            "district": "36",
            "Block": "84",
            "label": "GHSS , PANAMADANGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8805",
            "district": "36",
            "Block": "84",
            "label": "PUMS PALLAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8806",
            "district": "36",
            "Block": "84",
            "label": "GHS , MELMOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8807",
            "district": "36",
            "Block": "84",
            "label": "SRI KALAIVANI VIDYALAYA MATRIC HSS, MELMAYIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8808",
            "district": "36",
            "Block": "84",
            "label": "PUMS  KILMUTTUKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8809",
            "district": "36",
            "Block": "84",
            "label": "GBHSS KVKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8810",
            "district": "36",
            "Block": "84",
            "label": "GGHSS , KVKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8811",
            "district": "36",
            "Block": "84",
            "label": "SAI GURUJI MATRIC HSS, K.V.KUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8812",
            "district": "36",
            "Block": "84",
            "label": "MKB MATRIC SCHOOL, ARJUNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8813",
            "district": "36",
            "Block": "84",
            "label": "GADWHSS PILLANTHIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8814",
            "district": "36",
            "Block": "84",
            "label": "GHSS, VADUGANTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8815",
            "district": "36",
            "Block": "84",
            "label": "PUMS VELAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8816",
            "district": "36",
            "Block": "84",
            "label": "GHSS SENJI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8817",
            "district": "36",
            "Block": "84",
            "label": "PUMS THONDANTHULASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8818",
            "district": "36",
            "Block": "84",
            "label": "KALVIULAGAM AHS , ARUMBAKKAM MOTTUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8819",
            "district": "36",
            "Block": "84",
            "label": "GGHSS , LATHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8820",
            "district": "36",
            "Block": "84",
            "label": "GBHSS ,LATTERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8821",
            "district": "36",
            "Block": "84",
            "label": "GHS , THIRUMANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21056",
            "district": "36",
            "Block": "84",
            "label": "BRAMMAAS VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21057",
            "district": "36",
            "Block": "84",
            "label": "VIDHYALAKSHMI  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21781",
            "district": "36",
            "Block": "84",
            "label": "MOUNT LITERA ZEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21929",
            "district": "36",
            "Block": "84",
            "label": "VISION VIDYASHRAM WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22064",
            "district": "36",
            "Block": "84",
            "label": "ADWGHS AMMANAGKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22065",
            "district": "36",
            "Block": "84",
            "label": "Arya Vidhyasshram International Residential School ( Senior Secondary )",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22389",
            "district": "36",
            "Block": "84",
            "label": "GHS  PALLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23630",
            "district": "36",
            "Block": "84",
            "label": "SREE ABIRAAMI (CBSE ) SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23630",
            "district": "36",
            "Block": "84",
            "label": "SREE ABIRAAMI (CBSE ) SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23795",
            "district": "36",
            "Block": "84",
            "label": "SRI LAKSHMI VIDYALAYA NURSERY AND PRIMARY SCHOOL MELMOIL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23796",
            "district": "36",
            "Block": "84",
            "label": "SARADAMBAL SRINIVASA MUDALIAR MEMORIAL PADASALA (CBSE)",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "85": [
        {
            "key": "1261",
            "district": "17",
            "Block": "85",
            "label": "PUMS CHINNASOLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1580",
            "district": "17",
            "Block": "85",
            "label": "ST.JOSEPH R.C MID SCHOOL PERUNKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1823",
            "district": "17",
            "Block": "85",
            "label": "PUMS SELUR SELLAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1841",
            "district": "17",
            "Block": "85",
            "label": "PUMS CHEYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1868",
            "district": "17",
            "Block": "85",
            "label": "PUMS THIDUMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2044",
            "district": "17",
            "Block": "85",
            "label": "PUMS NETTAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2055",
            "district": "17",
            "Block": "85",
            "label": "PUMS VENGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2436",
            "district": "17",
            "Block": "85",
            "label": "PUMS ZAMIN ELAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2591",
            "district": "17",
            "Block": "85",
            "label": "BRINDHAVAN  MATRICULATION SCHOOL, POTHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3129",
            "district": "17",
            "Block": "85",
            "label": "PUMS KOLAKKATTUPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3153",
            "district": "17",
            "Block": "85",
            "label": "PUMS SULLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3202",
            "district": "17",
            "Block": "85",
            "label": "VIVEKANANDA MATRICULATION HIGHER SECONDARY SCHOOL, PAUNDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3417",
            "district": "17",
            "Block": "85",
            "label": "SRI RAJA VINAYAGA MATRICULATION SCHOOL, VELLATHARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3465",
            "district": "17",
            "Block": "85",
            "label": "PUMS PONMALARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3468",
            "district": "17",
            "Block": "85",
            "label": "RKV MATRIC HR.SEC.SCHOOL, JEDARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3710",
            "district": "17",
            "Block": "85",
            "label": "PUMS K.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3875",
            "district": "17",
            "Block": "85",
            "label": "K.S MANIAM VIDHYA BHAVAN SENIOR SECONDARY SCHOOL  CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4253",
            "district": "17",
            "Block": "85",
            "label": "PUMS V.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4653",
            "district": "17",
            "Block": "85",
            "label": "PUMS VADAKARAIATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5452",
            "district": "17",
            "Block": "85",
            "label": "PUMS VENGAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11998",
            "district": "17",
            "Block": "85",
            "label": "GHSS SOLASIRAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11999",
            "district": "17",
            "Block": "85",
            "label": "SREE AMMAN MATRICULATION HIGHER SECONDARY  SCHOOL, MARAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12000",
            "district": "17",
            "Block": "85",
            "label": "GHS NANJAPPAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12001",
            "district": "17",
            "Block": "85",
            "label": "GHSS KABILARMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12002",
            "district": "17",
            "Block": "85",
            "label": "VIVEKANANDA HIGHER SECONDARY SCHOOL PAUNDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12003",
            "district": "17",
            "Block": "85",
            "label": "RN OXFORD MATRICULATION SCHOOL, MUNIAGOUNDAMPALAYAM, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12004",
            "district": "17",
            "Block": "85",
            "label": "SUN STAR HR SEC SCHOOL, VADAKARAIATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12005",
            "district": "17",
            "Block": "85",
            "label": "GHSS JEDARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12006",
            "district": "17",
            "Block": "85",
            "label": "GHS ANANGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12007",
            "district": "17",
            "Block": "85",
            "label": "GHSS PILIKKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12008",
            "district": "17",
            "Block": "85",
            "label": "GHS KONDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12009",
            "district": "17",
            "Block": "85",
            "label": "GGHSS PANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12010",
            "district": "17",
            "Block": "85",
            "label": "GBHSS PANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12011",
            "district": "17",
            "Block": "85",
            "label": "GHSS POTHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22124",
            "district": "17",
            "Block": "85",
            "label": "CHANAKYA HI-TECH MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22810",
            "district": "17",
            "Block": "85",
            "label": "VIVEKANANDA VIDYALAYAM PAUNDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22811",
            "district": "17",
            "Block": "85",
            "label": "R.N. OXFORD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23797",
            "district": "17",
            "Block": "85",
            "label": "PUMS K.AYYAMPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "86": [
        {
            "key": "808",
            "district": "20",
            "Block": "86",
            "label": "AL-AMEEN MATRICULATION HIGH SCHOOL, VALINOKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "992",
            "district": "20",
            "Block": "86",
            "label": "BANU MATRIC HIGHER SECONDARY SCHOOL, SIKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1113",
            "district": "20",
            "Block": "86",
            "label": "V.V.S.M. NATIONAL ACADEMY MATRIC HIGHER SECONDARY SCHOOL, SAYALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1210",
            "district": "20",
            "Block": "86",
            "label": "INDIAN MATRIC HIGHER SECONDARY SCHOOL, SIKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1322",
            "district": "20",
            "Block": "86",
            "label": "SRI SANGEEDHA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, K.KARUNKULAM, KADALADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1342",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ERVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1469",
            "district": "20",
            "Block": "86",
            "label": "ROJA MATRIC HIGHER SECONDARY SCHOOL, SAYALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1486",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHARAIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1521",
            "district": "20",
            "Block": "86",
            "label": "ELITE MATRIC HIGHER SECONDARY SCHOOL, ERVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1595",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PONNAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1639",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAVAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1661",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHITHIRANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1665",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OPPILAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1688",
            "district": "20",
            "Block": "86",
            "label": "SRI RAMAKRISHNA SRI SARADHA MIDDLE SCHOOL, APPANOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1716",
            "district": "20",
            "Block": "86",
            "label": "NEW MIDDLE SCHOOL, SAYALGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "1717",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ROJMANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1759",
            "district": "20",
            "Block": "86",
            "label": "RC MIDDLE SCHOOL, MOOKKAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1926",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUVIKITHIYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2101",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELASELVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2108",
            "district": "20",
            "Block": "86",
            "label": "I.L.M. MATRICULATION HIGH SCHOOL, NARIPPAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2162",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, S.T.SETHURAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2338",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHEMCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2642",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELASIRUPOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2754",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, POOPANDIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3163",
            "district": "20",
            "Block": "86",
            "label": "NADAR MAHAJANA SANGAM KALVITHANTHAI K.S.SHANMUGA NADAR MIDDLE SCHOOL, SAYALGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3574",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, YADHAVAR KUDI, POTHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3575",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3776",
            "district": "20",
            "Block": "86",
            "label": "AMBIKAVATHY HINDU NADAR MIDDLE SCHOOL, VELLAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4485",
            "district": "20",
            "Block": "86",
            "label": "ANJANEYA MATRICULATION SCHOOL, KANDILAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4816",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, URAIKINARU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4817",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ORIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5558",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5781",
            "district": "20",
            "Block": "86",
            "label": "HINDU NADAR MIDDLE SCHOOL, KOKKARASANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5822",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALLAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5830",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALINOKKAM, ADANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5853",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KEELAKIDARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5858",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELAMUNDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5859",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINNA ERVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5866",
            "district": "20",
            "Block": "86",
            "label": "KSHATRIYA NADAR MIDDLE SCHOOL, KANNIRAJAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5995",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, M.KARISALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19160",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, APPANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19161",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SAYALGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19162",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SAYALGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19163",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, KADUGUSANTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19164",
            "district": "20",
            "Block": "86",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.SATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19165",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT MIDDLE SCHOOL, KADALADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19166",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KADALADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19167",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, KIDATHIRUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19168",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, GURUVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19169",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, S.THARAIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19170",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, ILANCHEMBOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19171",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, T.M.KOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19172",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UCHINATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19173",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, T.MARIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19174",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, MELASELVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19175",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SIKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19176",
            "district": "20",
            "Block": "86",
            "label": "SYED ABUBUCKARI BADHUSA MUNSIF HUSSIN GOVERNMENT HIGHER SECONDARY SCHOOL, ERVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19177",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, EDAMBADAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19178",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGH SCHOOL, VALINOKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19179",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELAKIDARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19180",
            "district": "20",
            "Block": "86",
            "label": "ST. XAVIER CLUNY HIGHER SECONDARY SCHOOL, M.SAVARIARPATTANAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19181",
            "district": "20",
            "Block": "86",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KANNIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19182",
            "district": "20",
            "Block": "86",
            "label": "BHARATHA MATHA HIGH SCHOOL, NARIPPAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21616",
            "district": "20",
            "Block": "86",
            "label": "SARANYA MATRICULATION HIGH SCHOOL, VALINOKKAM VILAKKU, KEELAKIDARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22267",
            "district": "20",
            "Block": "86",
            "label": "THIYAKI DHARMAKKAN AMIRTHAM LITTLE ANGEL MATRICULATION SCHOOL, KANNIRAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22717",
            "district": "20",
            "Block": "86",
            "label": "DON BOSCO SCHOOL OF EXCELLENCE, SAYALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "87": [
        {
            "key": "424",
            "district": "33",
            "Block": "87",
            "label": "ANNAI SARASWATHI MATRIC HIGHER SECONDARY SCHOOL.,  MANAVALA  NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "471",
            "district": "33",
            "Block": "87",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "487",
            "district": "33",
            "Block": "87",
            "label": "ST PETER MS PINCHIVAKKAM KANDIGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "489",
            "district": "33",
            "Block": "87",
            "label": "PUMS VENGATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "492",
            "district": "33",
            "Block": "87",
            "label": "RCM MID SCH KALLAMBEDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "494",
            "district": "33",
            "Block": "87",
            "label": "PUMS VALASAIVETTIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "506",
            "district": "33",
            "Block": "87",
            "label": "PUMS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "509",
            "district": "33",
            "Block": "87",
            "label": "PUMS VENGATHUR KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "519",
            "district": "33",
            "Block": "87",
            "label": "SUBBA NAIDU MATRICULATION HIGHER SECONDARY SCHOOL,  MANAVALANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "529",
            "district": "33",
            "Block": "87",
            "label": "ST MARYS MATRICULATION HIGHER SECONDARY SCHOOL IRULANCHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "531",
            "district": "33",
            "Block": "87",
            "label": "JACOB MATRIC  SCHOOL.  VENGATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "645",
            "district": "33",
            "Block": "87",
            "label": "PUMS KONDANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "709",
            "district": "33",
            "Block": "87",
            "label": "PUMS PUDUMAVILANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "729",
            "district": "33",
            "Block": "87",
            "label": "PUMS KILNALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "792",
            "district": "33",
            "Block": "87",
            "label": "PUMS SATHARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "851",
            "district": "33",
            "Block": "87",
            "label": "GANCHIOR MATRICULATION HIGHER SECONDARY SCHOOL KARANAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "925",
            "district": "33",
            "Block": "87",
            "label": "VISHWAKSENA VIDYA VIKAS MATRIC HIGHER SECONDARY SCHOOL, POLLIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1095",
            "district": "33",
            "Block": "87",
            "label": "PUMS THIRUMANIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1140",
            "district": "33",
            "Block": "87",
            "label": "PUMS POLIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1522",
            "district": "33",
            "Block": "87",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL THODUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1877",
            "district": "33",
            "Block": "87",
            "label": "CHRIST KING MAT. HR .SEC.SCHOOL,MANAVALA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1965",
            "district": "33",
            "Block": "87",
            "label": "PUMS KADAMBATHUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2088",
            "district": "33",
            "Block": "87",
            "label": "DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL PANNUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2616",
            "district": "33",
            "Block": "87",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2726",
            "district": "33",
            "Block": "87",
            "label": "PUMS USAIN NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3110",
            "district": "33",
            "Block": "87",
            "label": "PUMS THENKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3408",
            "district": "33",
            "Block": "87",
            "label": "CHELLAMMAL VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL MELNALLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3507",
            "district": "33",
            "Block": "87",
            "label": "PUMS ADIGATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6788",
            "district": "33",
            "Block": "87",
            "label": "GHS EGATUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6789",
            "district": "33",
            "Block": "87",
            "label": "GHS ERAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6790",
            "district": "33",
            "Block": "87",
            "label": "GOVTGHS KADAMBATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6791",
            "district": "33",
            "Block": "87",
            "label": "GOVTHSS KADAMBATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6792",
            "district": "33",
            "Block": "87",
            "label": "ST JOSEPH (B)HSS KILACHERI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6793",
            "district": "33",
            "Block": "87",
            "label": "SACRED HEART GIRLS HR SEC SCHOOL -  KILACHERI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6794",
            "district": "33",
            "Block": "87",
            "label": "GOVT HIGH SCHOOL KOOVAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6795",
            "district": "33",
            "Block": "87",
            "label": "GOVT.HR.SEC.SCHOOL KOPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6796",
            "district": "33",
            "Block": "87",
            "label": "GOVT HS MELNALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6797",
            "district": "33",
            "Block": "87",
            "label": "GOVT.GIRLS HR.SEC.SCHOOL PERAMBAKKAM-631402",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6798",
            "district": "33",
            "Block": "87",
            "label": "GOVT.BOYS. HR.SEC.SCHOOL PERAMBAKKAM-631402",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6799",
            "district": "33",
            "Block": "87",
            "label": "GHS SENJI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6800",
            "district": "33",
            "Block": "87",
            "label": "GHS THIRUPANTHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6801",
            "district": "33",
            "Block": "87",
            "label": "ST.JOSEPH GIRLS HR SEC SCHOOL, PANNUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6802",
            "district": "33",
            "Block": "87",
            "label": "DONBOSCO HSS PANNUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6803",
            "district": "33",
            "Block": "87",
            "label": "GHS ULUNTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6804",
            "district": "33",
            "Block": "87",
            "label": "GHSS VIDAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6805",
            "district": "33",
            "Block": "87",
            "label": "GHS VAYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6806",
            "district": "33",
            "Block": "87",
            "label": "GGHS MANAVALANAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6807",
            "district": "33",
            "Block": "87",
            "label": "GHSS MANAVALANAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21364",
            "district": "33",
            "Block": "87",
            "label": "GHS PAPPARAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21715",
            "district": "33",
            "Block": "87",
            "label": "VISHWAKSENA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22304",
            "district": "33",
            "Block": "87",
            "label": "GHS VENMANAMBUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22305",
            "district": "33",
            "Block": "87",
            "label": "DMI ST.JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22306",
            "district": "33",
            "Block": "87",
            "label": "MOTHER TERESA MEMORIAL RESIDENTIAL SPECIAL SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "88": [
        {
            "key": "4401",
            "district": "12",
            "Block": "88",
            "label": "KAVERI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4945",
            "district": "12",
            "Block": "88",
            "label": "SRI LAKSHMI MAT.SCHOOL, THARAGAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5424",
            "district": "12",
            "Block": "88",
            "label": "PUMS, ARASAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5975",
            "district": "12",
            "Block": "88",
            "label": "PUMS. D.SEETHAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6177",
            "district": "12",
            "Block": "88",
            "label": "PUMS, POONJOLAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6184",
            "district": "12",
            "Block": "88",
            "label": "PUMS, KAVARAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6206",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MULLIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6266",
            "district": "12",
            "Block": "88",
            "label": "PUMS, KURUMBAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6338",
            "district": "12",
            "Block": "88",
            "label": "PUMS, SERVAIKARANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6354",
            "district": "12",
            "Block": "88",
            "label": "PUMS, KALUTHARIKKAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6465",
            "district": "12",
            "Block": "88",
            "label": "PUMS, CHINTHAMANIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6474",
            "district": "12",
            "Block": "88",
            "label": "PUMS, POOSARIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6485",
            "district": "12",
            "Block": "88",
            "label": "ST. XAVIER'S GOVT. AIDED MID.SCHOOL, SINNANDIPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6557",
            "district": "12",
            "Block": "88",
            "label": "PUMS, THALIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6599",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VEERANAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13972",
            "district": "12",
            "Block": "88",
            "label": "PUMS VILVAMARATHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13973",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VEERIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13974",
            "district": "12",
            "Block": "88",
            "label": "GHSS,KANIYALAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13975",
            "district": "12",
            "Block": "88",
            "label": "GHSS, KURUNIKULATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13976",
            "district": "12",
            "Block": "88",
            "label": "PUMS, PAPPAYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13977",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13978",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13979",
            "district": "12",
            "Block": "88",
            "label": "PUMS, KEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13980",
            "district": "12",
            "Block": "88",
            "label": "PUMS, THALUMBAGOUDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13981",
            "district": "12",
            "Block": "88",
            "label": "GHS, PUDUVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13982",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MAMARATHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13983",
            "district": "12",
            "Block": "88",
            "label": "GHS, THENNILAI-KADAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13984",
            "district": "12",
            "Block": "88",
            "label": "GHS, MANJAPULIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13985",
            "district": "12",
            "Block": "88",
            "label": "MARIST.HR.SEC.SCHOOL, P. UDAYAPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13986",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VARAVANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13987",
            "district": "12",
            "Block": "88",
            "label": "PUMS, ANAIGOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13988",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MYLAMPATTY(BOYS)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13989",
            "district": "12",
            "Block": "88",
            "label": "PUMS, THARAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13990",
            "district": "12",
            "Block": "88",
            "label": "GHSS, THARAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13991",
            "district": "12",
            "Block": "88",
            "label": "SAUDIA ORIENTAL ARABIC HSS, CHINTHAMANIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13992",
            "district": "12",
            "Block": "88",
            "label": "MODEL HR. SEC. SCHOOL, THARAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13993",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VALVARMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13994",
            "district": "12",
            "Block": "88",
            "label": "PUMS, THASIREDDIAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13995",
            "district": "12",
            "Block": "88",
            "label": "ADWHS, MAVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13996",
            "district": "12",
            "Block": "88",
            "label": "PUMS, SENNAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13997",
            "district": "12",
            "Block": "88",
            "label": "GHS, NALLURANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13998",
            "district": "12",
            "Block": "88",
            "label": "PUMS, PALAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13999",
            "district": "12",
            "Block": "88",
            "label": "GHSS, PALAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14000",
            "district": "12",
            "Block": "88",
            "label": "PUMS, E.RAJAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14001",
            "district": "12",
            "Block": "88",
            "label": "PUMS, VALAYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14002",
            "district": "12",
            "Block": "88",
            "label": "PUMS, K. AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14003",
            "district": "12",
            "Block": "88",
            "label": "GHSS, KADAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14004",
            "district": "12",
            "Block": "88",
            "label": "ANNAI LIA HIGHER SECONDARY SCHOOL, SEVAPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14005",
            "district": "12",
            "Block": "88",
            "label": "KGBV, THARAGAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14006",
            "district": "12",
            "Block": "88",
            "label": "PUMS, ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14007",
            "district": "12",
            "Block": "88",
            "label": "PUMS, D. IDAYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14008",
            "district": "12",
            "Block": "88",
            "label": "GHS, E. SUKKAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21548",
            "district": "12",
            "Block": "88",
            "label": "SRI KARUNAIRAGAVAJI VIDHAYALAYA HSS, THARAGAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22184",
            "district": "12",
            "Block": "88",
            "label": "AMUTHASURABHI VIDHYALAYA MATRIC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22514",
            "district": "12",
            "Block": "88",
            "label": "GHS, KADAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22911",
            "district": "12",
            "Block": "88",
            "label": "ST.Joseph's Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22975",
            "district": "12",
            "Block": "88",
            "label": "GOVERNMENT HIGH SCHOOL, SONAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23798",
            "district": "12",
            "Block": "88",
            "label": "PUMS, MAMARATHUPATTY",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23799",
            "district": "12",
            "Block": "88",
            "label": "PUMS, ALATHUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "89": [
        {
            "key": "1075",
            "district": "24",
            "Block": "89",
            "label": "GHS, POTTALPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "1079",
            "district": "24",
            "Block": "89",
            "label": "Adarsh Vidyalaya Matric. Hr, Sec. School, Vasagiri nagar, Kadayam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1605",
            "district": "24",
            "Block": "89",
            "label": "PUMS CHOCKKANATHANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2061",
            "district": "24",
            "Block": "89",
            "label": "SRI RAMANARAI NADAR MS, PUNGAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2473",
            "district": "24",
            "Block": "89",
            "label": "VEERA ULGAMMAL MS, CHEKKADIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3960",
            "district": "24",
            "Block": "89",
            "label": "SRI ATHRIKALA NIALAYA MS  SIVASAILAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4458",
            "district": "24",
            "Block": "89",
            "label": "SRI SAILAPATHY MS, ALWARKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5566",
            "district": "24",
            "Block": "89",
            "label": "SRI MEENAKSHI MS KILA AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5816",
            "district": "24",
            "Block": "89",
            "label": "PUMS RAVANASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5968",
            "district": "24",
            "Block": "89",
            "label": "GNANAM MARAVA MS, GOVINDAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6232",
            "district": "24",
            "Block": "89",
            "label": "PUMS, LAKSHMIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6660",
            "district": "24",
            "Block": "89",
            "label": "TDTA MS, PULAVANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6728",
            "district": "24",
            "Block": "89",
            "label": "RENGANATHA MS , PAPPANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19660",
            "district": "24",
            "Block": "89",
            "label": "ST JOSEPHS HS VEIKKALIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19661",
            "district": "24",
            "Block": "89",
            "label": "GHS, KAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19662",
            "district": "24",
            "Block": "89",
            "label": "CHATRAM BHARATHI GIRLS HS, THERKKU KADAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19663",
            "district": "24",
            "Block": "89",
            "label": "SRI KAILASAM MEMORIAL HIGH SCHOOL THIRUMALAIAPPAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19664",
            "district": "24",
            "Block": "89",
            "label": "CHATRAM BHARATHI HR SEC SCHOOL KADAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19665",
            "district": "24",
            "Block": "89",
            "label": "GHSS, MUDALIYARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19666",
            "district": "24",
            "Block": "89",
            "label": "GANDHIGRAM SANTHI HIGHER SECONDARY SCHOOL FOR THE HEARING IMPAIRED, SIVASAILAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19667",
            "district": "24",
            "Block": "89",
            "label": "SRI PARAMAKALYANI HSS, ALWARKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19668",
            "district": "24",
            "Block": "89",
            "label": "ST ANTONY'S HSS, KALYANIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19669",
            "district": "24",
            "Block": "89",
            "label": "PUMS, PAPPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19670",
            "district": "24",
            "Block": "89",
            "label": "GANAPATHY MS, PAPPANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19671",
            "district": "24",
            "Block": "89",
            "label": "GHS, MYLAPPAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19672",
            "district": "24",
            "Block": "89",
            "label": "KSHATHRIYAR COM.MS VENGADAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19673",
            "district": "24",
            "Block": "89",
            "label": "SSV HSS MATHAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19674",
            "district": "24",
            "Block": "89",
            "label": "THIRU MURUGAN HS VALLIAMMALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19675",
            "district": "24",
            "Block": "89",
            "label": "TDTA MS INTHANKATTALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21618",
            "district": "24",
            "Block": "89",
            "label": "REDEEMER MATRICULATION HSS SCHOOL, CHETTIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21838",
            "district": "24",
            "Block": "89",
            "label": "GOOD SHEPHERD MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22642",
            "district": "24",
            "Block": "89",
            "label": "GHS NALANKATTALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23201",
            "district": "24",
            "Block": "89",
            "label": "GANDHIGRAM TRUST AVVAI ASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "90": [
        {
            "key": "2174",
            "district": "22",
            "Block": "90",
            "label": "GOLDEN RAYS MATRIC SCHOOL, KADAYAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5745",
            "district": "22",
            "Block": "90",
            "label": "NEW INDIA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11017",
            "district": "22",
            "Block": "90",
            "label": "PUMS, ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11018",
            "district": "22",
            "Block": "90",
            "label": "PUMS, VEERIYANTHANDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11019",
            "district": "22",
            "Block": "90",
            "label": "PUMS, V KONGARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11020",
            "district": "22",
            "Block": "90",
            "label": "ST. ANGELA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11021",
            "district": "22",
            "Block": "90",
            "label": "PUMS, VEERATCHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11022",
            "district": "22",
            "Block": "90",
            "label": "PUMS, K N PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11023",
            "district": "22",
            "Block": "90",
            "label": "GHSS,K.MORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11024",
            "district": "22",
            "Block": "90",
            "label": "GHSS, RAMAMOORTHI NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11025",
            "district": "22",
            "Block": "90",
            "label": "PUMS, KANNAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11026",
            "district": "22",
            "Block": "90",
            "label": "K.G.B.V KADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11027",
            "district": "22",
            "Block": "90",
            "label": "PUMS, DANISHPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11028",
            "district": "22",
            "Block": "90",
            "label": "GHSS,PERIYA VADAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11029",
            "district": "22",
            "Block": "90",
            "label": "BETHEL MATRICULATION HR.SEC SCHOOL, DANISHPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11030",
            "district": "22",
            "Block": "90",
            "label": "PUMS, UMBILICKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11031",
            "district": "22",
            "Block": "90",
            "label": "GHS,BOMMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11032",
            "district": "22",
            "Block": "90",
            "label": "PUMS, SUNDAKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11033",
            "district": "22",
            "Block": "90",
            "label": "GHSS,NADUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11034",
            "district": "22",
            "Block": "90",
            "label": "A.M. PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11035",
            "district": "22",
            "Block": "90",
            "label": "PUMS, KADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11036",
            "district": "22",
            "Block": "90",
            "label": "SWAMY VIVEKANANDHA VIDHYALAYA MATRIC HR. SEC. SCHOOL,KADAYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11037",
            "district": "22",
            "Block": "90",
            "label": "PUMS, ELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11038",
            "district": "22",
            "Block": "90",
            "label": "PUMS, THALAVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11039",
            "district": "22",
            "Block": "90",
            "label": "PUMS, GURUVAREDDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11040",
            "district": "22",
            "Block": "90",
            "label": "GHSS,GUNDUKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11041",
            "district": "22",
            "Block": "90",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11042",
            "district": "22",
            "Block": "90",
            "label": "GHS,KONGUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11043",
            "district": "22",
            "Block": "90",
            "label": "PUMS, NALLUR MANIYAKARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11044",
            "district": "22",
            "Block": "90",
            "label": "PUMS, PUDURKARUVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11045",
            "district": "22",
            "Block": "90",
            "label": "PUMS, KARUVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11046",
            "district": "22",
            "Block": "90",
            "label": "PUMS, MARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11047",
            "district": "22",
            "Block": "90",
            "label": "GHS, MOOKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11048",
            "district": "22",
            "Block": "90",
            "label": "GHS, DEEVATTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11049",
            "district": "22",
            "Block": "90",
            "label": "GANGAKAVERI HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11050",
            "district": "22",
            "Block": "90",
            "label": "GANGAKAVERI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11051",
            "district": "22",
            "Block": "90",
            "label": "PUMS, POOSARIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11052",
            "district": "22",
            "Block": "90",
            "label": "MOUNT SHERVAROYS MATRICULATION SCHOOL, POOSARIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11053",
            "district": "22",
            "Block": "90",
            "label": "PUMS, DASASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11054",
            "district": "22",
            "Block": "90",
            "label": "MODEL SCHOOL ,KADAYAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11055",
            "district": "22",
            "Block": "90",
            "label": "GHSS, PANNAPPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11056",
            "district": "22",
            "Block": "90",
            "label": "PUMS, THINNAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11057",
            "district": "22",
            "Block": "90",
            "label": "PUMS, MATTUKARANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11058",
            "district": "22",
            "Block": "90",
            "label": "GHSS,DHARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11059",
            "district": "22",
            "Block": "90",
            "label": "GGHS,DHARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11060",
            "district": "22",
            "Block": "90",
            "label": "PUMS, KOTTAMEDU NEW SCHOOL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11061",
            "district": "22",
            "Block": "90",
            "label": "PUMS, PAPPICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11062",
            "district": "22",
            "Block": "90",
            "label": "GHSS,KANJANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11063",
            "district": "22",
            "Block": "90",
            "label": "PUMS, SEMMANDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11064",
            "district": "22",
            "Block": "90",
            "label": "GHSS,PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23541",
            "district": "22",
            "Block": "90",
            "label": "Government Model School, Salem",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23542",
            "district": "22",
            "Block": "90",
            "label": "SALEM DISTRICT GOVERNMENT MODEL SCHOOL, SALEM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23541",
            "district": "22",
            "Block": "90",
            "label": "Government Model School, Salem",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23542",
            "district": "22",
            "Block": "90",
            "label": "SALEM DISTRICT GOVERNMENT MODEL SCHOOL, SALEM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23800",
            "district": "22",
            "Block": "90",
            "label": "PUMS, ELATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23801",
            "district": "22",
            "Block": "90",
            "label": "PUMS, NALLUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "91": [
        {
            "key": "1350",
            "district": "24",
            "Block": "91",
            "label": "MASOOD THAIKA MIDDLE SCHOOL KADAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1798",
            "district": "24",
            "Block": "91",
            "label": "KING UNIVERSE MATRIC HIGHER SECONDARY SCHOOL, KUMANTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2402",
            "district": "24",
            "Block": "91",
            "label": "SRI SANKARA MIDDLE SCHOOL ,KRISHNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3005",
            "district": "24",
            "Block": "91",
            "label": "SRI KAILASA MS IDAIKAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3121",
            "district": "24",
            "Block": "91",
            "label": "KRISHNA MID.SCHOOL,KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3594",
            "district": "24",
            "Block": "91",
            "label": "PUMS,PUNNAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3647",
            "district": "24",
            "Block": "91",
            "label": "SRI SIVA SAILA NATHA MIDDLE SCHOOL NEDUVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3736",
            "district": "24",
            "Block": "91",
            "label": "PUMS,BALA ARUNACHALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4190",
            "district": "24",
            "Block": "91",
            "label": "P.U.M.S,DURAISAMYPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4432",
            "district": "24",
            "Block": "91",
            "label": "MELAPPALAYAM HINDU MIDDLE SCHOOL-MELAKADAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23400",
            "district": "24",
            "Block": "91",
            "label": "RVS Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4551",
            "district": "24",
            "Block": "91",
            "label": "PUMS,SUNDARESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4719",
            "district": "24",
            "Block": "91",
            "label": "USP MATRIC  HR SEC SCHOOL,KODIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5761",
            "district": "24",
            "Block": "91",
            "label": "MUNICIPAL MS PETTAI ,KADAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6496",
            "district": "24",
            "Block": "91",
            "label": "WISDOM MATRIC HIGHER SECONDARY SCHOOL,KADAYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6575",
            "district": "24",
            "Block": "91",
            "label": "PUDUR NEW  MIDDLE SCHOOL. .KADAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19676",
            "district": "24",
            "Block": "91",
            "label": "M.M.HSS,IDAIKAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19677",
            "district": "24",
            "Block": "91",
            "label": "GHSS,KASIDARMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19678",
            "district": "24",
            "Block": "91",
            "label": "T.M.MS NEDUVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19679",
            "district": "24",
            "Block": "91",
            "label": "GOVT HR  SEC SCHOOL NEDUVAYAL ACHANPUDUR TENKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19680",
            "district": "24",
            "Block": "91",
            "label": "HINDUMARAVA MS KODIKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19681",
            "district": "24",
            "Block": "91",
            "label": "HINDU MS SIVARAMA PETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19682",
            "district": "24",
            "Block": "91",
            "label": "SRI ULAGA HSS M.KRISHNAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19683",
            "district": "24",
            "Block": "91",
            "label": "GHSS(G)KADAYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19684",
            "district": "24",
            "Block": "91",
            "label": "GHSS(B) KADAYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19685",
            "district": "24",
            "Block": "91",
            "label": "DARUSSALAM HSS KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19686",
            "district": "24",
            "Block": "91",
            "label": "HIDHAYATHUL ISLAM HIGHER SECONDARY SCHOOL, KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19687",
            "district": "24",
            "Block": "91",
            "label": "PETTAIMUSLIM HIGHER SECONDARY SCHOOL KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19688",
            "district": "24",
            "Block": "91",
            "label": "RATNA HIGH SCHOOL,KADAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19689",
            "district": "24",
            "Block": "91",
            "label": "MASOOD THAIKA HIGHER SECONDARY SCHOOL  KADAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19690",
            "district": "24",
            "Block": "91",
            "label": "HINDU MS,THIRIKUDAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19691",
            "district": "24",
            "Block": "91",
            "label": "LIONS MAHATMA MAT SC ,MS PURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19692",
            "district": "24",
            "Block": "91",
            "label": "EVEREST MATRIC HIGHER SECONDARY SCHOOL ACHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19693",
            "district": "24",
            "Block": "91",
            "label": "GHS,IDAIKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19694",
            "district": "24",
            "Block": "91",
            "label": "RUKMANI HS,MANGALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19695",
            "district": "24",
            "Block": "91",
            "label": "KING OF KINGS MAT.SCH,MANGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19696",
            "district": "24",
            "Block": "91",
            "label": "CRESCENT MATRICULATION HIGHER SECONDARY SCHOOL,ACHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19697",
            "district": "24",
            "Block": "91",
            "label": "SATHANA VIDYALAYA MATRICULATION SCHOOL, BALAARUNACHALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19698",
            "district": "24",
            "Block": "91",
            "label": "GOVT HS(ADW),VALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19699",
            "district": "24",
            "Block": "91",
            "label": "PUMS,CHIDAMBARAPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19700",
            "district": "24",
            "Block": "91",
            "label": "GHSS,ANAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19701",
            "district": "24",
            "Block": "91",
            "label": "GHS,KULAYANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19702",
            "district": "24",
            "Block": "91",
            "label": "GHS,POIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19703",
            "district": "24",
            "Block": "91",
            "label": "GOVT.HSS,URMELAZHAGIAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19704",
            "district": "24",
            "Block": "91",
            "label": "GOVT HIGH SCHOOL,VELAYUTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21836",
            "district": "24",
            "Block": "91",
            "label": "NALLAMANI MATRIC SCHOOL KODIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21837",
            "district": "24",
            "Block": "91",
            "label": "AL-AZHAR MATRICULATION SCHOOL, MANGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22643",
            "district": "24",
            "Block": "91",
            "label": "BRILLIANT MATRICULATION  KADAYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22644",
            "district": "24",
            "Block": "91",
            "label": "BEST INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22645",
            "district": "24",
            "Block": "91",
            "label": "NEW CAMBRIDGE MATRICULATION HIGHER SECONDARY SCHOOL, MANGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22844",
            "district": "24",
            "Block": "91",
            "label": "NALLAMANI VIDHYALAYA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23219",
            "district": "24",
            "Block": "91",
            "label": "GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "92": [
        {
            "key": "760",
            "district": "30",
            "Block": "92",
            "label": "NATIONAL MATRIC HR SEC SCHOOL KALAKAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1037",
            "district": "30",
            "Block": "92",
            "label": "RANI MATRIC HR SEC SCHOOL ERUVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1114",
            "district": "30",
            "Block": "92",
            "label": "KAILASAPATHI HINDU MS SINGIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1121",
            "district": "30",
            "Block": "92",
            "label": "HINDU MS DEVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1624",
            "district": "30",
            "Block": "92",
            "label": "K.A.M.P. MEERANIA MS KALAKAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1646",
            "district": "30",
            "Block": "92",
            "label": "TDTA MS KEELA SADAYAMANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1685",
            "district": "30",
            "Block": "92",
            "label": "ST JOHNS MATRIC HR SEC SCHOOL PADALAIYARKULAM, KALAKAD.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1811",
            "district": "30",
            "Block": "92",
            "label": "SANTHOSHA VITHYALAYA DONAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1836",
            "district": "30",
            "Block": "92",
            "label": "PUMS KALAKAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2349",
            "district": "30",
            "Block": "92",
            "label": "ST XAVIER'S MS XAVIERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2594",
            "district": "30",
            "Block": "92",
            "label": "TDTA MS PERUMALKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2600",
            "district": "30",
            "Block": "92",
            "label": "PUMS ERUVADI CENTRAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2608",
            "district": "30",
            "Block": "92",
            "label": "HINDU MS MAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2639",
            "district": "30",
            "Block": "92",
            "label": "MUTHIAH HINDU MS KOVILPATHU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2972",
            "district": "30",
            "Block": "92",
            "label": "JAWAHAR  NATIONAL MS SALAIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3152",
            "district": "30",
            "Block": "92",
            "label": "HINDU MARAVAR MS MALAIYADIPUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3558",
            "district": "30",
            "Block": "92",
            "label": "ALHUDHA MATRIC HR SEC SCHOOL ERUVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4334",
            "district": "30",
            "Block": "92",
            "label": "SA MS NAMBIYARADAIPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4494",
            "district": "30",
            "Block": "92",
            "label": "HINDU MS THIRUKURUNGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5368",
            "district": "30",
            "Block": "92",
            "label": "TDTA MS IDAYANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5715",
            "district": "30",
            "Block": "92",
            "label": "TDTA MS MANJUVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19705",
            "district": "30",
            "Block": "92",
            "label": "CMS EVANGELICAL MS MELAPATHAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19706",
            "district": "30",
            "Block": "92",
            "label": "GOVT HIGH SCHOOL PADMANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19707",
            "district": "30",
            "Block": "92",
            "label": "AMIR JAMAL HSS IDAIYANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19708",
            "district": "30",
            "Block": "92",
            "label": "SRI NAMMALVAR MS KALLIKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19709",
            "district": "30",
            "Block": "92",
            "label": "ANNAI THERESA HS MEENAVANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19710",
            "district": "30",
            "Block": "92",
            "label": "K.A.M.P. MEERANIA HSS KALAKAD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19711",
            "district": "30",
            "Block": "92",
            "label": "GOVT HR SEC SCHOOL KALAKAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19712",
            "district": "30",
            "Block": "92",
            "label": "GOMATHI ARULNERI GIRLS HR SEC SCHOOL KALAKAD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19713",
            "district": "30",
            "Block": "92",
            "label": "WALKER HIGHER SECONDARY SCHOOL, DOHNAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19714",
            "district": "30",
            "Block": "92",
            "label": "TVS GOVT HR SEC SCHOOL THIRUGURUNGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19715",
            "district": "30",
            "Block": "92",
            "label": "GOVT(B)  HR SEC SCHOOL, ERUVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19716",
            "district": "30",
            "Block": "92",
            "label": "GOVT (G)  HR SEC SCHOOL,  ERUVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20920",
            "district": "30",
            "Block": "92",
            "label": "ST JOSEPH MATRICULATION HIGHER SECONDARY SCHOOL KADAMBODUVALVU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21542",
            "district": "30",
            "Block": "92",
            "label": "HINDU HS PANDITHANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21619",
            "district": "30",
            "Block": "92",
            "label": "JANAKI KRISHNA VIDYA MANDIR N & PS PADMANERI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23166",
            "district": "30",
            "Block": "92",
            "label": "MVM CAMBRIDGE VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23587",
            "district": "30",
            "Block": "92",
            "label": "NELLAI ERUVADI MODEL  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23587",
            "district": "30",
            "Block": "92",
            "label": "NELLAI ERUVADI MODEL  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "93": [
        {
            "key": "2374",
            "district": "34",
            "Block": "93",
            "label": "PUMS PATHIYAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2544",
            "district": "34",
            "Block": "93",
            "label": "PUMS ANAIVADI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3082",
            "district": "34",
            "Block": "93",
            "label": "PUMS SHOLAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3125",
            "district": "34",
            "Block": "93",
            "label": "PUMS PAZHANKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3127",
            "district": "34",
            "Block": "93",
            "label": "PUMS VEERALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3191",
            "district": "34",
            "Block": "93",
            "label": "GOVT MIDDLE KETTAVARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3645",
            "district": "34",
            "Block": "93",
            "label": "PUMS GENGAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3649",
            "district": "34",
            "Block": "93",
            "label": "PUMS SETTAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4103",
            "district": "34",
            "Block": "93",
            "label": "PUMS KOOTRAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5120",
            "district": "34",
            "Block": "93",
            "label": "AIDED MIDDLE SIRUVALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5525",
            "district": "34",
            "Block": "93",
            "label": "ADWMS KADALADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5937",
            "district": "34",
            "Block": "93",
            "label": "ANMMS THENMAHADEVAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5959",
            "district": "34",
            "Block": "93",
            "label": "PUMS PATTIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5976",
            "district": "34",
            "Block": "93",
            "label": "PUMS KAPPALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6207",
            "district": "34",
            "Block": "93",
            "label": "PUMS MELVANNIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6674",
            "district": "34",
            "Block": "93",
            "label": "PUMS MATTAVETTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9729",
            "district": "34",
            "Block": "93",
            "label": "GHSS (BOYS) ADAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9730",
            "district": "34",
            "Block": "93",
            "label": "GHSS (GIRLS) ADHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9731",
            "district": "34",
            "Block": "93",
            "label": "PUMS ALANGARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9732",
            "district": "34",
            "Block": "93",
            "label": "GHS ANIYALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9733",
            "district": "34",
            "Block": "93",
            "label": "SRI SARAVANA VIDHYALAYA MATRIC HR SEC SCHOOL ,ELATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9734",
            "district": "34",
            "Block": "93",
            "label": "GHSS KADALADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9735",
            "district": "34",
            "Block": "93",
            "label": "GMHSS (BOYS) KALASAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9736",
            "district": "34",
            "Block": "93",
            "label": "GHSS (GIRLS) KALASAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9737",
            "district": "34",
            "Block": "93",
            "label": "ARAVINDHAR MATRIC KALASAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9738",
            "district": "34",
            "Block": "93",
            "label": "SWAMI VENKATESWARA MATRICULATION HIGHER SECONDARY SCHOOL, KANDHAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9739",
            "district": "34",
            "Block": "93",
            "label": "GHS KANTHAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9740",
            "district": "34",
            "Block": "93",
            "label": "GHS KIDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9741",
            "district": "34",
            "Block": "93",
            "label": "GHS KILKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9742",
            "district": "34",
            "Block": "93",
            "label": "GHSS KILPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9743",
            "district": "34",
            "Block": "93",
            "label": "GHSS LADAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9744",
            "district": "34",
            "Block": "93",
            "label": "GHSS MELARANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9745",
            "district": "34",
            "Block": "93",
            "label": "GHSS MELSHOLANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9746",
            "district": "34",
            "Block": "93",
            "label": "GHS PADAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9747",
            "district": "34",
            "Block": "93",
            "label": "GHS POONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9748",
            "district": "34",
            "Block": "93",
            "label": "GHS THENMAHADEVAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9749",
            "district": "34",
            "Block": "93",
            "label": "ADWMS VEERALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20941",
            "district": "34",
            "Block": "93",
            "label": "DON BOSCO MATRICULATION SCHOOL - ADHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21926",
            "district": "34",
            "Block": "93",
            "label": "CHEZHIAN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22088",
            "district": "34",
            "Block": "93",
            "label": "ADWHS-KETTAVARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22922",
            "district": "34",
            "Block": "93",
            "label": "GHS-NAVAPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "94": [
        {
            "key": "3320",
            "district": "23",
            "Block": "94",
            "label": "HOLY SPIRIT MATRIC.HR.SEC.SCHOOL SEEGOORANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4016",
            "district": "23",
            "Block": "94",
            "label": "O M G S MATRIC SCHOOL KALAIYARKOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6642",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL, SUSAIAPPARPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17389",
            "district": "23",
            "Block": "94",
            "label": "PUMS ETTIAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17390",
            "district": "23",
            "Block": "94",
            "label": "PUMS KARUNGALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17391",
            "district": "23",
            "Block": "94",
            "label": "GOVT  HSS KALAIYARKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17392",
            "district": "23",
            "Block": "94",
            "label": "KRISHNANANTHAM VIDYASHRAM MATRICULATION SCHOOL KALAIYARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17393",
            "district": "23",
            "Block": "94",
            "label": "GOVT HSS ,KOLLANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17394",
            "district": "23",
            "Block": "94",
            "label": "PUMS GOWRIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17395",
            "district": "23",
            "Block": "94",
            "label": "PUMS SILANTHAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17396",
            "district": "23",
            "Block": "94",
            "label": "GOVT  HSS SILUKKUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17397",
            "district": "23",
            "Block": "94",
            "label": "SAMBAVIGA HSS RAGINIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17398",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  SEVALPUNJAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17399",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  PERIAOLAIKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17400",
            "district": "23",
            "Block": "94",
            "label": "PUMS, MARAKKATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17401",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  MANGATTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17402",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  MELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17403",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  MELAMARUNGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17404",
            "district": "23",
            "Block": "94",
            "label": "PUMS UDAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17405",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  USILANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17406",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  KANCHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17407",
            "district": "23",
            "Block": "94",
            "label": "PUMS, SATHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17408",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  SIRUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17409",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17410",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  NADAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17411",
            "district": "23",
            "Block": "94",
            "label": "PUMS, PALUVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17412",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  PILLATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17413",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  PERIYAKILUVATCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17414",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  POTTAGAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17415",
            "district": "23",
            "Block": "94",
            "label": "GOVT HR SEC SCHOOL MARAVAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17416",
            "district": "23",
            "Block": "94",
            "label": "PUMS,  VELARENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17417",
            "district": "23",
            "Block": "94",
            "label": "GOVT ADW  HSS MALLAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17418",
            "district": "23",
            "Block": "94",
            "label": "GOVT GIRLS HS PAGANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17419",
            "district": "23",
            "Block": "94",
            "label": "GOVT HIGHER SECONDARY SCHOOL PAGANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17420",
            "district": "23",
            "Block": "94",
            "label": "K M S C HSS NATTARASAN KOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17421",
            "district": "23",
            "Block": "94",
            "label": "S R M HSS NATTARASAN KOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17422",
            "district": "23",
            "Block": "94",
            "label": "ST.TERESAS CARMEL HIGH SCHOOL PAGAIYANJAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17423",
            "district": "23",
            "Block": "94",
            "label": "ULAGAMATHA HIGH SCHOOL SAKKUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17424",
            "district": "23",
            "Block": "94",
            "label": "GHS,K.CHOCKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17425",
            "district": "23",
            "Block": "94",
            "label": "PUMS, NAGARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17426",
            "district": "23",
            "Block": "94",
            "label": "GOVT  HS KARUMANTHAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17427",
            "district": "23",
            "Block": "94",
            "label": "GOVT  HS SETHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17428",
            "district": "23",
            "Block": "94",
            "label": "PUMS, PANANGADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17429",
            "district": "23",
            "Block": "94",
            "label": "PUMS, KALAYARMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17430",
            "district": "23",
            "Block": "94",
            "label": "PUMS, MUDIKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17431",
            "district": "23",
            "Block": "94",
            "label": "GHS, PERIYAKANNANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17432",
            "district": "23",
            "Block": "94",
            "label": "PUMS, KEELAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17433",
            "district": "23",
            "Block": "94",
            "label": "PUMS, SEMBAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17434",
            "district": "23",
            "Block": "94",
            "label": "PUMS, MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17435",
            "district": "23",
            "Block": "94",
            "label": "ROMANCATHALICKkMIDDLESCHOOL, ANDICHURANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17436",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MID SCHOOL OTTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17437",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL, PULIYADITHAMMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17438",
            "district": "23",
            "Block": "94",
            "label": "AUXILIUM MATRIC HR SEC SCHOOL, PULIYADITHAMMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17439",
            "district": "23",
            "Block": "94",
            "label": "SAHAYARANI GIRLS  HR SEC SCHOOL SUSAIAPPARPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17440",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL, PALLITHAMMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17441",
            "district": "23",
            "Block": "94",
            "label": "DON BOSCO  HR SEC  SCHOOL  PALLITHAMMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17442",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL THUVARALANKANMAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17443",
            "district": "23",
            "Block": "94",
            "label": "ROMAN CATHOLIC MID SCHOOL PULIKANMOI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17444",
            "district": "23",
            "Block": "94",
            "label": "SINNAR MANDAR MIDDLE SCHOOL, SENGULIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20996",
            "district": "23",
            "Block": "94",
            "label": "PAUL SANKAR CBSE SCHOOL,NARUPUTHAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21636",
            "district": "23",
            "Block": "94",
            "label": "ST.JOSEPH S PUBLIC SCHOOL,KALAIYARKOI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21841",
            "district": "23",
            "Block": "94",
            "label": "ST.JOSEPH'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21842",
            "district": "23",
            "Block": "94",
            "label": "ST. MICHAEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21843",
            "district": "23",
            "Block": "94",
            "label": "OXFORD MATRICULATION HIGHER SECONDARY SCHOOL SOORAKKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21844",
            "district": "23",
            "Block": "94",
            "label": "SRI SARASWATHY VIKAS VIDYALAYA MATRIC HR SEC SCHOOL, KALAYARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22235",
            "district": "23",
            "Block": "94",
            "label": "BODHI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22594",
            "district": "23",
            "Block": "94",
            "label": "ARIBALA MATRICULATION SCHOOL  PALKULAM MARAVAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22701",
            "district": "23",
            "Block": "94",
            "label": "KARPAGA MATRICULATION  SCHOOL SAKTHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23550",
            "district": "23",
            "Block": "94",
            "label": "OMGS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23550",
            "district": "23",
            "Block": "94",
            "label": "OMGS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23802",
            "district": "23",
            "Block": "94",
            "label": "PUMS, KEELAKOTTAI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "95": [
        {
            "key": "4796",
            "district": "9",
            "Block": "95",
            "label": "RR CAMBRIDGE HIGHER SECONDARY SCHOOL NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6359",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT MIDDLE SCHOOL SIRUVANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6374",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MADUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10699",
            "district": "9",
            "Block": "95",
            "label": "VANMATHI MATRICULATION HIGH SCHOOL EDUTHAVAINATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10700",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EDUTHAVAINATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10701",
            "district": "9",
            "Block": "95",
            "label": "MASS MATRICULATION  SCHOOL EDUTHAVAINATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10702",
            "district": "9",
            "Block": "95",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL PACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10703",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MANMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10704",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KARADICHITHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10705",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THAVADIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10706",
            "district": "9",
            "Block": "95",
            "label": "THIRU MURUGAN AIDED MIDDLE SCHOOL MADHAVACHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23410",
            "district": "9",
            "Block": "95",
            "label": "SIRUMALAR INTERNATIONAL SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "10707",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL MADAVACHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10708",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALRAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10709",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL MATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10710",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL EMAPPAIR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10711",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EAST KALLAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10712",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALLAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10713",
            "district": "9",
            "Block": "95",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL KALLAKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10714",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KALLAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10715",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KALLAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10716",
            "district": "9",
            "Block": "95",
            "label": "BHARATHI MATRIC HIGHER SECONDARY SCHOOL, KALLAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10717",
            "district": "9",
            "Block": "95",
            "label": "MOUNT CARMAL MATRICULATION HIGHER SECONDARY SCHOOL KALLAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10718",
            "district": "9",
            "Block": "95",
            "label": "ST.MARYS MATRICULATION SCHOOL KALLAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10719",
            "district": "9",
            "Block": "95",
            "label": "VIDHYALAKSHMI HIGH SCHOOL KALLAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10720",
            "district": "9",
            "Block": "95",
            "label": "ADI DRAVIDAR  WELFARE MIDDLE SCHOOL SIRUVANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10721",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SALAI MAMANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10722",
            "district": "9",
            "Block": "95",
            "label": "ARASAKUMARI HiGH SCHOOL SIRUVANGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10723",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUVANGUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10724",
            "district": "9",
            "Block": "95",
            "label": "AKT  ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10725",
            "district": "9",
            "Block": "95",
            "label": "SRIRAM AIDED MIDDLE SCHOOL NEELAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10726",
            "district": "9",
            "Block": "95",
            "label": "AKT HIGH SCHOOL NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10727",
            "district": "9",
            "Block": "95",
            "label": "A K T MEMORIAL VIDYA SAAKET  SCHOOL, NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10728",
            "district": "9",
            "Block": "95",
            "label": "ADI DRAVIDAR  WELFARE MIDDLE SCHOOL THENKEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10729",
            "district": "9",
            "Block": "95",
            "label": "MAHABARATHI INTERNATIONAL SCHOOL THENKEERANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10730",
            "district": "9",
            "Block": "95",
            "label": "ADI DRAVIDAR  WELFARE HIGH SCHOOL NIRAIMATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10731",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEERACHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10732",
            "district": "9",
            "Block": "95",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL PERIYASIRUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10733",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL MALAIKOTTALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10734",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL VANAVAREDDY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10735",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10736",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL VILAMBAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10737",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THACHUR KATTU KOTTAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10738",
            "district": "9",
            "Block": "95",
            "label": "OXALISS INTERNATIONAL SCHOOL THACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10739",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PORPADAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10740",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ERAVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10741",
            "district": "9",
            "Block": "95",
            "label": "Dr. RKS MASTERS MATRIC HR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10742",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL THANDALAI PERUVANGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10743",
            "district": "9",
            "Block": "95",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL AGARKOTTALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10744",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10745",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  A. PUTHANTHDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10746",
            "district": "9",
            "Block": "95",
            "label": "KANCHANADEVI MATRICULATION HIGHER SECONDARY SCHOOL ALATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10747",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERIYASIRUVATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10748",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL PERIYASIRUVATUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10749",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL K.ALAMBALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10750",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARIYAPERUMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10751",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10752",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SOMANDARGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10753",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERUMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10754",
            "district": "9",
            "Block": "95",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL  PUKKIRAVARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10755",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL PUKKIRAVARI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10756",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10757",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL MANIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10758",
            "district": "9",
            "Block": "95",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL THARISIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10759",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY  SCHOOL INNADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10760",
            "district": "9",
            "Block": "95",
            "label": "GOOD SHEPHERD HIGH SCHOOL  MELNILAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10761",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL NOCHIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10762",
            "district": "9",
            "Block": "95",
            "label": "MURUGU AIDED MIDDLE SCHOOL MALLIYAMPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10763",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL MELVAZHAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10764",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGHER SECONDARY SCHOOL KOTTAPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10765",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL ARAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10766",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL VARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10767",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL EZHUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10768",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  MIDDLE SCHOOL MELPACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10769",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT EKLAVYA MODEL RESIDENTIAL BOYS HIGHER SECONDARY SCHOOL , VELLIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21017",
            "district": "9",
            "Block": "95",
            "label": "KALAIVANI MATRICULATION  HIGHER SECONDARY  SCHOOL , PORPADAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21454",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL VANIYANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21455",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL ANAIKARAIKOTTALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21592",
            "district": "9",
            "Block": "95",
            "label": "INTACT MATRICULATION  SCHOOL VELLIMALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21685",
            "district": "9",
            "Block": "95",
            "label": "SRI VENKATESHWARA MATRICULATION SCHOOL MATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21970",
            "district": "9",
            "Block": "95",
            "label": "SRI BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL THACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22103",
            "district": "9",
            "Block": "95",
            "label": "GS MATRICULATION SCHOOL ALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22430",
            "district": "9",
            "Block": "95",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Eduthavainatham",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22431",
            "district": "9",
            "Block": "95",
            "label": "GOVERNMENT HIGH SCHOOL THENKEERANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23066",
            "district": "9",
            "Block": "95",
            "label": "JS GLOBAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23304",
            "district": "9",
            "Block": "95",
            "label": "AKT MEMORIAL MATRICULATION GIRLS SCHOOL NEELAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23485",
            "district": "9",
            "Block": "95",
            "label": "KALLAKURICHI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23803",
            "district": "9",
            "Block": "95",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23804",
            "district": "9",
            "Block": "95",
            "label": "KALLAKURICHI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "96": [
        {
            "key": "3411",
            "district": "23",
            "Block": "96",
            "label": "KALAIMAGAL MAT SCHOOL,KALLAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3784",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PALAVANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4251",
            "district": "23",
            "Block": "96",
            "label": "KOVILOOR ANDAVAR MATRIC HR SEC SCHOOL, KOVILOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5133",
            "district": "23",
            "Block": "96",
            "label": "PUMS T.NADUVIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5527",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PATHARAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5936",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PILAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6140",
            "district": "23",
            "Block": "96",
            "label": "PUMS, KALLIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6552",
            "district": "23",
            "Block": "96",
            "label": "MEYYAPPA MIDDLE SCHOOL,KALLUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17793",
            "district": "23",
            "Block": "96",
            "label": "GOVT GIRLS HS KALLAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17794",
            "district": "23",
            "Block": "96",
            "label": "KALLAL MURUGAPPA  HR SEC SCHOOL, KALLAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17795",
            "district": "23",
            "Block": "96",
            "label": "SHANTHI RANI  MATRIC HR SEC SCHOOL, KALLAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17796",
            "district": "23",
            "Block": "96",
            "label": "BRITTO HR.SEC.SCHOOL, KALLAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17797",
            "district": "23",
            "Block": "96",
            "label": "NEHRU.MIDDLE.SCHOOL, ATHANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17798",
            "district": "23",
            "Block": "96",
            "label": "GOVT HS ALANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17799",
            "district": "23",
            "Block": "96",
            "label": "TAGOREMIDDLE.SCHOOL,A.SIRUVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17800",
            "district": "23",
            "Block": "96",
            "label": "ROMANCATHALICKMIDDLESCHOOL, SATHARASANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17801",
            "district": "23",
            "Block": "96",
            "label": "PUMS, KALIPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17802",
            "district": "23",
            "Block": "96",
            "label": "PUMS, KOOTHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17803",
            "district": "23",
            "Block": "96",
            "label": "SETHU  IRANI MATRIC HR SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17804",
            "district": "23",
            "Block": "96",
            "label": "T.R.V.A.HIGH SCHOOL ,PATTAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17805",
            "district": "23",
            "Block": "96",
            "label": "D.K.G.HR.SEC..SCH,KUNDRAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17806",
            "district": "23",
            "Block": "96",
            "label": "PUMS.KURUNTHAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17807",
            "district": "23",
            "Block": "96",
            "label": "ST.SEBASTIAN'S HIGH SCHOOL - KUTHALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17808",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PADATHANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17809",
            "district": "23",
            "Block": "96",
            "label": "PUMS, PEYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17810",
            "district": "23",
            "Block": "96",
            "label": "GOVT HS KOVILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17811",
            "district": "23",
            "Block": "96",
            "label": "R C M HS NADARAJAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17812",
            "district": "23",
            "Block": "96",
            "label": "J V HS NACHIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17813",
            "district": "23",
            "Block": "96",
            "label": "PUMS, N.VAIRAVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17814",
            "district": "23",
            "Block": "96",
            "label": "RC MIDDLE SCHOOL, PANANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17815",
            "district": "23",
            "Block": "96",
            "label": "PUMS, SEVARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17816",
            "district": "23",
            "Block": "96",
            "label": "PUMS, S.R.PATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17817",
            "district": "23",
            "Block": "96",
            "label": "M.K.GHSS, SEMBANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17818",
            "district": "23",
            "Block": "96",
            "label": "SOMASUNDARAM MIDDLE SCHOOL,SIRAVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17819",
            "district": "23",
            "Block": "96",
            "label": "ADW.GOVT.HIGH.SCHOOL, ATHIKARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17820",
            "district": "23",
            "Block": "96",
            "label": "PUMS, THALAKAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17821",
            "district": "23",
            "Block": "96",
            "label": "L.A.R.MID.SCHOOL,MANAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17822",
            "district": "23",
            "Block": "96",
            "label": "GOVT MODEL HIGHER SECONDARY SCHOOL VISALAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17823",
            "district": "23",
            "Block": "96",
            "label": "PUMS, VEPPANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17824",
            "district": "23",
            "Block": "96",
            "label": "GOVT HSS VETRIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17825",
            "district": "23",
            "Block": "96",
            "label": "SRI DHANDAYUTHAPANI HS KANDRAMANICKAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17826",
            "district": "23",
            "Block": "96",
            "label": "M.A.P.G.HIGH.SCHOOL,KANDRAMANICKAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17827",
            "district": "23",
            "Block": "96",
            "label": "PUMS, K.VALAIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21335",
            "district": "23",
            "Block": "96",
            "label": "CHETTINAD PUBLIC SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21845",
            "district": "23",
            "Block": "96",
            "label": "MOUNT ZION SILVER JUBILEE MATRICULATION HR SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23029",
            "district": "23",
            "Block": "96",
            "label": "SRI RAJA VIDYA VIKAAS CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23551",
            "district": "23",
            "Block": "96",
            "label": "MOUNT ZION SILVER JUBILEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23551",
            "district": "23",
            "Block": "96",
            "label": "MOUNT ZION SILVER JUBILEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "97": [
        {
            "key": "17886",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, SENGAPADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17887",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S KARISALKALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17888",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S ULAGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17889",
            "district": "14",
            "Block": "97",
            "label": "KOODAKOVIL NADARS HR SEC SCHOOL, KOODAKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17890",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S THUMBAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17891",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, T. PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17892",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. VALAYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17893",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, T.ARASAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17894",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, SIVARAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17895",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, VILLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17896",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. MARAVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17897",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, KALLIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17898",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S AGATHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17899",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. LALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17900",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. THENAMMANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17901",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, M PULIANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17902",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S M.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17903",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. CHITHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17904",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. MAIYTTAN PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17905",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. AVALSOORAM PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17906",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S K.UNNIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17907",
            "district": "14",
            "Block": "97",
            "label": "GOVT HR SEC SCHOOL, K.VELLAKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17908",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S MELAUPPILIGUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17909",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S T.KOKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17910",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, VEERAPERMUALPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17911",
            "district": "14",
            "Block": "97",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PEIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17912",
            "district": "14",
            "Block": "97",
            "label": "HINDU NADAR HIGH SCHOOL, K SENNAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17913",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S MELAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17914",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S S.P.NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17915",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. MOCHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17916",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S. KURAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17917",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, THIRUMAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17918",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, MARUTHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17919",
            "district": "14",
            "Block": "97",
            "label": "P.U.M.S S.VELLAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21400",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, VADAKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22599",
            "district": "14",
            "Block": "97",
            "label": "GOVT HIGH SCHOOL, CHINNA ULAGANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22600",
            "district": "14",
            "Block": "97",
            "label": "V.T.MANIKANDAN MATRIC SCHOOL, K.VELLAKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23505",
            "district": "14",
            "Block": "97",
            "label": "KOTHARI PUBLIC SCHOOL, SIVARAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23505",
            "district": "14",
            "Block": "97",
            "label": "KOTHARI PUBLIC SCHOOL, SIVARAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "98": [
        {
            "key": "4159",
            "district": "5",
            "Block": "98",
            "label": "PUMS KARKUDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4204",
            "district": "5",
            "Block": "98",
            "label": "PUMS VEENANKENI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4474",
            "district": "5",
            "Block": "98",
            "label": "PUMS PUDHUVIRUDHAGIRIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4535",
            "district": "5",
            "Block": "98",
            "label": "PUMS U.ADHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4540",
            "district": "5",
            "Block": "98",
            "label": "PUMS KO.MAVIDANTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4761",
            "district": "5",
            "Block": "98",
            "label": "SAMI VIVEKANANTHA MATRICULATION SCHOOL-SEPLANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4881",
            "district": "5",
            "Block": "98",
            "label": "PUMS A. VALLIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5040",
            "district": "5",
            "Block": "98",
            "label": "AMS T. PAVAZHANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5221",
            "district": "5",
            "Block": "98",
            "label": "PUMS, A.KURAVANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5248",
            "district": "5",
            "Block": "98",
            "label": "PUMS PALLAKOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5314",
            "district": "5",
            "Block": "98",
            "label": "SIVABHARATHI MATRICULATION HIGHER SECONDARY SCHOOL SIRUVARAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5575",
            "district": "5",
            "Block": "98",
            "label": "PUMS UYAKONDARAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5606",
            "district": "5",
            "Block": "98",
            "label": "PUMS SATHAPPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5730",
            "district": "5",
            "Block": "98",
            "label": "AMS VILAKKAPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5754",
            "district": "5",
            "Block": "98",
            "label": "PUMS AMMERIKOKKANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5855",
            "district": "5",
            "Block": "98",
            "label": "PUMS CHINNAKOTTUMULAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6129",
            "district": "5",
            "Block": "98",
            "label": "PUMS V.SATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6358",
            "district": "5",
            "Block": "98",
            "label": "PUMS- KEEZHAPALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6560",
            "district": "5",
            "Block": "98",
            "label": "PUMS PAZHAAYAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15479",
            "district": "5",
            "Block": "98",
            "label": "GHS KO.ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15480",
            "district": "5",
            "Block": "98",
            "label": "ADW HSS, DHARMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15481",
            "district": "5",
            "Block": "98",
            "label": "AR RAHMATH MATRICULATION SCHOOL PERIYAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15482",
            "district": "5",
            "Block": "98",
            "label": "GHSS IRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15483",
            "district": "5",
            "Block": "98",
            "label": "GHS IRULAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15484",
            "district": "5",
            "Block": "98",
            "label": "GHSS KAMMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15485",
            "district": "5",
            "Block": "98",
            "label": "AMS GOPALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15486",
            "district": "5",
            "Block": "98",
            "label": "ST.THERSA'S HS, KATTUKUNANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15487",
            "district": "5",
            "Block": "98",
            "label": "AMS KAVANUR WEST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15488",
            "district": "5",
            "Block": "98",
            "label": "GHSS C.KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15489",
            "district": "5",
            "Block": "98",
            "label": "GHS -KOTTERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15490",
            "district": "5",
            "Block": "98",
            "label": "PUMS KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15491",
            "district": "5",
            "Block": "98",
            "label": "SACRED HEART HSS, IRUPUKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15492",
            "district": "5",
            "Block": "98",
            "label": "GHS, MELAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15493",
            "district": "5",
            "Block": "98",
            "label": "P.RAMALINGA MDR GHSS, MUDHANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15494",
            "district": "5",
            "Block": "98",
            "label": "GHS - NADIYAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15495",
            "district": "5",
            "Block": "98",
            "label": "GHS , OTTIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15496",
            "district": "5",
            "Block": "98",
            "label": "GHS PERIYAKAPPANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15497",
            "district": "5",
            "Block": "98",
            "label": "AUXILIUM MATCULATION  SCHOOL-V.SATHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15498",
            "district": "5",
            "Block": "98",
            "label": "GHSS SEPLANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15499",
            "district": "5",
            "Block": "98",
            "label": "AMS SIRUVARPPUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15500",
            "district": "5",
            "Block": "98",
            "label": "PUMS THOPPALIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15501",
            "district": "5",
            "Block": "98",
            "label": "PUMS UTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15502",
            "district": "5",
            "Block": "98",
            "label": "GHS -VEPPANKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15503",
            "district": "5",
            "Block": "98",
            "label": "JPA VIDYALAYA  MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15504",
            "district": "5",
            "Block": "98",
            "label": "GHS GANGAIKONDAN COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15505",
            "district": "5",
            "Block": "98",
            "label": "SACRED HEART MATRICULATION SCHOOL, KVR NAGAR,NEYVELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15506",
            "district": "5",
            "Block": "98",
            "label": "PUMS- KOLLIRUPPU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21518",
            "district": "5",
            "Block": "98",
            "label": "ADWGHS-U.MANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21691",
            "district": "5",
            "Block": "98",
            "label": "JAYAPRIYA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL,GOPALAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23805",
            "district": "5",
            "Block": "98",
            "label": "PUMS KUMARAMANGALAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "99": [
        {
            "key": "770",
            "district": "20",
            "Block": "99",
            "label": "RAHMANIA MATRIC HIGHER SECONDARY SCHOOL,KAMUTHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1236",
            "district": "20",
            "Block": "99",
            "label": "KSHATRIYA NADAR MATRIC HIGHER SECONDARY SCHOOL.,KAMUTHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1583",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ACHANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2041",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, S.KALLUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2100",
            "district": "20",
            "Block": "99",
            "label": "HINDU MIDDLE SCHOOL, KAVADIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2170",
            "district": "20",
            "Block": "99",
            "label": "SAINT JOSEPH'S RC MIDDLE SCHOOL, KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2362",
            "district": "20",
            "Block": "99",
            "label": "T.E.L.C. MIDDLE SCHOOL, ARANMANAIMEDU, KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2385",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ABIRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2390",
            "district": "20",
            "Block": "99",
            "label": "S.N.ESMAIL MIDDLE SCHOOL, ABIRAMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2394",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. VEERAMAACHANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2607",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EZHUVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2656",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THALAIVANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2877",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUNAZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3007",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINNAUDAPPANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3166",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UDAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4093",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.THARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4155",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4177",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , SAMYPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4184",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4518",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUNDUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4717",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUSTAQKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4782",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KATHANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4861",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PONTHAMPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5049",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.VEPPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5182",
            "district": "20",
            "Block": "99",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKOTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5969",
            "district": "20",
            "Block": "99",
            "label": "KSHATHRIYA VIDHYA SALA MIDDLE SCHOOL, VALAIYAPOOKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19119",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, PAMMANENDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19120",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, PAKKUVETTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19121",
            "district": "20",
            "Block": "99",
            "label": "KSHATRIYA HINDU NADAR HIGHER SECONDARY SCHOOL, PERUNAZHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19122",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOVILANGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19123",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOTTAIMEDU, KAMUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19124",
            "district": "20",
            "Block": "99",
            "label": "KSHATHRIYA NADAR HIGHER SECONDARY SCHOOL (BOYS), KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19125",
            "district": "20",
            "Block": "99",
            "label": "KSHATHRIYA NADAR HIGHER SECONDARY SCHOOL (GIRLS), KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19126",
            "district": "20",
            "Block": "99",
            "label": "GOWRAVA HIGH SCHOOL, KAMUTHI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19127",
            "district": "20",
            "Block": "99",
            "label": "KALAVIRUTHI HIGHER SECONDARY SCHOOL, KAMUTHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19128",
            "district": "20",
            "Block": "99",
            "label": "IQBAL HIGH SCHOOL, SUNDARAPURAM,KAMUTHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19129",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, T.PUNAVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19130",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19131",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANDALAMANICKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19132",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, VAZHIMARICHAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19133",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGH SCHOOL, T.VALLAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19134",
            "district": "20",
            "Block": "99",
            "label": "MUSLIM HIGHER SECONDARY SCHOOL, ABIRAMAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19135",
            "district": "20",
            "Block": "99",
            "label": "V.N.S. HIGH SCHOOL , ABIRAMAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19136",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, RAMASAMYPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19137",
            "district": "20",
            "Block": "99",
            "label": "SAINT JOHN'S HIGHER SECONDARY SCHOOL, KEELAMUDIMANNARKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19138",
            "district": "20",
            "Block": "99",
            "label": "DEVANGAR HIGHER SECONDARY SCHOOL, NEERAVI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19139",
            "district": "20",
            "Block": "99",
            "label": "GOVERNMENT ADI DRAVIDAR HIGH SCHOOL, SENGAPPADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21036",
            "district": "20",
            "Block": "99",
            "label": "RAHMANIA GARDEN MATRIC HIGH SCHOOL, KAMUTHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21615",
            "district": "20",
            "Block": "99",
            "label": "ST. JAMES MATRICULATION HIGH SCHOOL, PASUMPON",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "100": [
        {
            "key": "1557",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELLAIYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1687",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1870",
            "district": "37",
            "Block": "100",
            "label": "SRI KRISHNA MATRIC HIGHER SECONDARY SCHOOL PANAMALAI MADHURA, METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2341",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2406",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANUMANDHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3345",
            "district": "37",
            "Block": "100",
            "label": "MAHATMA GANDHI MATRICULATION SCHOOL KANJANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3707",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  T.KOSAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4079",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KANGIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5701",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENMANIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5740",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENGAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6221",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ARIYALUR THIRUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10302",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL NALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10303",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT MIDDLE SCHOOL UDAYANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10304",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ATHIYURTHIRUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10305",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  KARUVATCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10306",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL PUDUKARUVATCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10307",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PANAMALAI PETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10308",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL PANAMALAI PETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10309",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SANGEETHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10310",
            "district": "37",
            "Block": "100",
            "label": "ST.MARY'S HIGH SCHOOL  NANGATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10311",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL KALYANAMPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10312",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ANNIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10313",
            "district": "37",
            "Block": "100",
            "label": "OLIVE TREE MATRICULATION HIGHER SECONDARY SCHOOL, ANNIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10314",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  ANNIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10315",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELLERIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10316",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL EZHUSEMPON",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10317",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL MELKARANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10318",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL SE.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10319",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL  KANJANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10320",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL SIRUVALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10321",
            "district": "37",
            "Block": "100",
            "label": "REDWINGS MATRICULATION SCHOOL  KEDAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10322",
            "district": "37",
            "Block": "100",
            "label": "ST. LITTLE FLOWER HIGH SCHOOL KAKKANUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10323",
            "district": "37",
            "Block": "100",
            "label": "SRI VIJAYALAKSHMI MATRICULATION SCHOOL ,KAKKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10324",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOZHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10325",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL  PALLIYANTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10326",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(BOYS)  KEDAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10327",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL (GIRLS) KEDAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10328",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MAMBALAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10329",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL KALPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10330",
            "district": "37",
            "Block": "100",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL THELI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10331",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10332",
            "district": "37",
            "Block": "100",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  AGARAM SITHAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10333",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL  VENGANTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10334",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL SANIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10335",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL  PERUMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10336",
            "district": "37",
            "Block": "100",
            "label": "SFS MAT.SCHOOL PERUMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10337",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KONUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10338",
            "district": "37",
            "Block": "100",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL  KARUNGALIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21062",
            "district": "37",
            "Block": "100",
            "label": "VET VIDYAA MANDHIR CBSE SCHOOL KANAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21933",
            "district": "37",
            "Block": "100",
            "label": "OM SIVA SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL,PANAMALAI PETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22875",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23288",
            "district": "37",
            "Block": "100",
            "label": "GOVERNMENT HIGH SCHOOL KADAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23806",
            "district": "37",
            "Block": "100",
            "label": "NILAA MATRICULATION SCHOOL,KOSAPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "101": [
        {
            "key": "4811",
            "district": "10",
            "Block": "101",
            "label": "Annie Besant MS, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4839",
            "district": "10",
            "Block": "101",
            "label": "Bharathidasan MHSS, Orikkai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5027",
            "district": "10",
            "Block": "101",
            "label": "M.L.M. Mamallan MHSS, Kanchipuram 631502",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23660",
            "district": "10",
            "Block": "101",
            "label": "Melmaruvathur Adhiparasakthi High School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "5310",
            "district": "10",
            "Block": "101",
            "label": "SSKV Mat.HSS., Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5349",
            "district": "10",
            "Block": "101",
            "label": "Pallavan MS, Kolivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5361",
            "district": "10",
            "Block": "101",
            "label": "PUMS,PONNIYAMMAN PATTARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5374",
            "district": "10",
            "Block": "101",
            "label": "PUMS, DAMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23373",
            "district": "10",
            "Block": "101",
            "label": "USHA MATRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23673",
            "district": "10",
            "Block": "101",
            "label": "Shree Sangalpa School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23389",
            "district": "10",
            "Block": "101",
            "label": "kattai koothu gurukul",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "5473",
            "district": "10",
            "Block": "101",
            "label": "Venkateswara MS Orikkai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6065",
            "district": "10",
            "Block": "101",
            "label": "Cholan Mat.HSS,Sembarambakkam-631552",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6692",
            "district": "10",
            "Block": "101",
            "label": "Sree Narayana Guru MHSS, Big Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7546",
            "district": "10",
            "Block": "101",
            "label": "GHSS DAMAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7547",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KILAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7548",
            "district": "10",
            "Block": "101",
            "label": "PUMS MUSARAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7549",
            "district": "10",
            "Block": "101",
            "label": "GHSS, Musaravakkam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7550",
            "district": "10",
            "Block": "101",
            "label": "PUMS,PERUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7551",
            "district": "10",
            "Block": "101",
            "label": "GHS, Vishar",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7552",
            "district": "10",
            "Block": "101",
            "label": "GHSS,THIRUPPUTKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7553",
            "district": "10",
            "Block": "101",
            "label": "PUMS,Muttavakkam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7554",
            "district": "10",
            "Block": "101",
            "label": "PUMS VATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7555",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KOORAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7556",
            "district": "10",
            "Block": "101",
            "label": "PUMS, Kilambi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7557",
            "district": "10",
            "Block": "101",
            "label": "Sri Uma Vid. MHSS, Keelambi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7558",
            "district": "10",
            "Block": "101",
            "label": "Janus Global School, CBSE, Kilambi",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7559",
            "district": "10",
            "Block": "101",
            "label": "PUMS, SIRUKAVERIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7560",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KILKADIRPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7561",
            "district": "10",
            "Block": "101",
            "label": "PUMS, METTUKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7562",
            "district": "10",
            "Block": "101",
            "label": "GHSS AYYANGARKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7563",
            "district": "10",
            "Block": "101",
            "label": "PUMS, VALATHOTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7564",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KALAKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7565",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KURUVIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7566",
            "district": "10",
            "Block": "101",
            "label": "GHS., Periyanatham",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7567",
            "district": "10",
            "Block": "101",
            "label": "PUMS MARIYAMMANNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7568",
            "district": "10",
            "Block": "101",
            "label": "ADWHS ORIKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7569",
            "district": "10",
            "Block": "101",
            "label": "GHS For the Hearing Impaired, ORIKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7570",
            "district": "10",
            "Block": "101",
            "label": "RCM AIDED HS, VIPPEDU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7571",
            "district": "10",
            "Block": "101",
            "label": "PUMS, VISHNU KANCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7572",
            "district": "10",
            "Block": "101",
            "label": "PUMS,THIMMASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7573",
            "district": "10",
            "Block": "101",
            "label": "GHS THIRUKALIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7574",
            "district": "10",
            "Block": "101",
            "label": "PUMS,ANGAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7575",
            "district": "10",
            "Block": "101",
            "label": "GHSS, Avalur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7576",
            "district": "10",
            "Block": "101",
            "label": "Prashanthi Gurukulam CBSE School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7577",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KAMMARASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7578",
            "district": "10",
            "Block": "101",
            "label": "PUMS, KAVANTHANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7579",
            "district": "10",
            "Block": "101",
            "label": "GHSS ARPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7580",
            "district": "10",
            "Block": "101",
            "label": "Grace Carmel Matriculation School, Arpakkam-631603",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7581",
            "district": "10",
            "Block": "101",
            "label": "PUMS,MAGARAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7582",
            "district": "10",
            "Block": "101",
            "label": "PUMS THENAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7583",
            "district": "10",
            "Block": "101",
            "label": "Infant Jesus Mat.HSS, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7584",
            "district": "10",
            "Block": "101",
            "label": "GHS NASARATHPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7585",
            "district": "10",
            "Block": "101",
            "label": "PUMS COLLECTORS COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7586",
            "district": "10",
            "Block": "101",
            "label": "GHS COLLECTORATE COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7587",
            "district": "10",
            "Block": "101",
            "label": "Swami Vivekananda MHSS,Sevilimedu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7588",
            "district": "10",
            "Block": "101",
            "label": "Bharathi MS, NGO Colony Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7589",
            "district": "10",
            "Block": "101",
            "label": "GHS SEVILIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7590",
            "district": "10",
            "Block": "101",
            "label": "MMS OLIMUGAMADUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7591",
            "district": "10",
            "Block": "101",
            "label": "MMS. KVK., Kanchipuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7592",
            "district": "10",
            "Block": "101",
            "label": "PTVS Matriculation school,Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7593",
            "district": "10",
            "Block": "101",
            "label": "PTVS (Aided) HS, Kanchipuram",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7594",
            "district": "10",
            "Block": "101",
            "label": "MariyaAuxilium(Aided)HSS(G),Kanchi",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7595",
            "district": "10",
            "Block": "101",
            "label": "MMS,ALADI KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7596",
            "district": "10",
            "Block": "101",
            "label": "SSKV(G) HSS,KANCHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7597",
            "district": "10",
            "Block": "101",
            "label": "CSI (Aided) MS, Kanchipuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7598",
            "district": "10",
            "Block": "101",
            "label": "SRI DHANDAPANI ORIENTAL AIDED HSS, KANCHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7599",
            "district": "10",
            "Block": "101",
            "label": "CSI (AIDED) HS(G) - KANCHEEPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7600",
            "district": "10",
            "Block": "101",
            "label": "MMS, OKKAPIRANDAN ,KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7601",
            "district": "10",
            "Block": "101",
            "label": "MMS YAGASALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7602",
            "district": "10",
            "Block": "101",
            "label": "GHSS(Girls)., Big Kanchipuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7603",
            "district": "10",
            "Block": "101",
            "label": "Dr.PSS.Mpl.HSS., Kanchipuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7604",
            "district": "10",
            "Block": "101",
            "label": "Anderson (Aided) HSS(B), Kanchi",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7605",
            "district": "10",
            "Block": "101",
            "label": "Khatri Mat.HS., Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7606",
            "district": "10",
            "Block": "101",
            "label": "Rani Annadurai Mpl.HSS(G),Kanchipuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7607",
            "district": "10",
            "Block": "101",
            "label": "MMS MUTHUCETTIYAAR, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7608",
            "district": "10",
            "Block": "101",
            "label": "CSI  MIDDLE SCHOOL, CENTRAL KANCHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7609",
            "district": "10",
            "Block": "101",
            "label": "Thiyagi (Aided) MS, Kanchipuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7610",
            "district": "10",
            "Block": "101",
            "label": "Pachaiyappa's HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7611",
            "district": "10",
            "Block": "101",
            "label": "Victoria Mat.HSS., Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7612",
            "district": "10",
            "Block": "101",
            "label": "MMS, MELPILLAIYAR PALAYAM, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7613",
            "district": "10",
            "Block": "101",
            "label": "PACHAIYAPPA'S BRANCH, KANCHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7614",
            "district": "10",
            "Block": "101",
            "label": "MMS EKAMBARA SANNADHI, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7615",
            "district": "10",
            "Block": "101",
            "label": "BMS GGHSS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7616",
            "district": "10",
            "Block": "101",
            "label": "CSMHSS,Pillayarpalayam, Kanchipuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7617",
            "district": "10",
            "Block": "101",
            "label": "Sundar Mission MHSS, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7618",
            "district": "10",
            "Block": "101",
            "label": "Sri Krishna (Aided) MS, Kanchipuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7619",
            "district": "10",
            "Block": "101",
            "label": "MMS VAIGUNDAM, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7620",
            "district": "10",
            "Block": "101",
            "label": "SRI VANI NILAYAM HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7621",
            "district": "10",
            "Block": "101",
            "label": "Sri Ekambarapuram (Aided) MS, Kanchi",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7622",
            "district": "10",
            "Block": "101",
            "label": "CMS GHSS, Kancheepuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7623",
            "district": "10",
            "Block": "101",
            "label": "A.K.T.MPL HS, KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7624",
            "district": "10",
            "Block": "101",
            "label": "Cholan MHSS,  Kanchipuram-631502",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7625",
            "district": "10",
            "Block": "101",
            "label": "Aruna Matriculation school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7626",
            "district": "10",
            "Block": "101",
            "label": "SRI RAMANUJA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21236",
            "district": "10",
            "Block": "101",
            "label": "Royal Matriculation Higher Secondary School Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21425",
            "district": "10",
            "Block": "101",
            "label": "GHS, Thammanur",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21738",
            "district": "10",
            "Block": "101",
            "label": "Gurukshetra Public School-KONERIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21739",
            "district": "10",
            "Block": "101",
            "label": "SSKV Boys Matric  HrSS Kanchipuram 631501",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21740",
            "district": "10",
            "Block": "101",
            "label": "Dhwarkesh Vidhyashram CBSE School, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21940",
            "district": "10",
            "Block": "101",
            "label": "New Bharathidasan Mat.HSS, Orikkai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21949",
            "district": "10",
            "Block": "101",
            "label": "Kanchi Global Matriculation Hr.Sec.School, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22019",
            "district": "10",
            "Block": "101",
            "label": "ADWHS, Melottivakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22020",
            "district": "10",
            "Block": "101",
            "label": "Brindavan Anglo Vedic International School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22337",
            "district": "10",
            "Block": "101",
            "label": "Infini International CBSE School, Kalakkattur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22338",
            "district": "10",
            "Block": "101",
            "label": "Victoria Matriculation School, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22988",
            "district": "10",
            "Block": "101",
            "label": "Billabong High International School, Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22993",
            "district": "10",
            "Block": "101",
            "label": "Shrishti international school Kanchipuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23174",
            "district": "10",
            "Block": "101",
            "label": "SOORYAN MATRICULATION SCHOOL CHITHERIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23487",
            "district": "10",
            "Block": "101",
            "label": "SRI LAKSHMI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23487",
            "district": "10",
            "Block": "101",
            "label": "SRI LAKSHMI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "102": [
        {
            "key": "10434",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VAZHUDHAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10435",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALITHIRAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10436",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMMANANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10437",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SITHALAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10438",
            "district": "37",
            "Block": "102",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL KODUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10439",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL M.MANDAGAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10440",
            "district": "37",
            "Block": "102",
            "label": "OMANDURAR AIDED MIDDLE SCHOOL KOTHAMPAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10441",
            "district": "37",
            "Block": "102",
            "label": "ANANDHA AIDED MIDDLE SCHOOL VANIAMPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10442",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL V.AGARAM COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10443",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL VADAVAMBALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10444",
            "district": "37",
            "Block": "102",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL P.VILLIANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10445",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENTGIRLS  HIGH SCHOOL SIRUVANTHADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10446",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SIRUVANTHADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10447",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL POOVARASANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10448",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL ARPISAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10449",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL PAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10450",
            "district": "37",
            "Block": "102",
            "label": "VENKATAVARADA AIDED MIDDLE SCHOOL KRISHNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10451",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALINJIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10452",
            "district": "37",
            "Block": "102",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL RAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10453",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL RAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10454",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KONGAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10455",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL L.R.PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10456",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  V.BOODHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10457",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  V.MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10458",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL  KUMALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10459",
            "district": "37",
            "Block": "102",
            "label": "THIRUVALLUVAR AIDED MIDDLE SCHOOL  SESHANGANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10460",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PALLITHENNAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10461",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NAVAMALMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10462",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  C.B.SAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10463",
            "district": "37",
            "Block": "102",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10464",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10465",
            "district": "37",
            "Block": "102",
            "label": "GOVERNMENT HIGH SCHOOL PERIABABU SAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "103": [
        {
            "key": "1497",
            "district": "31",
            "Block": "103",
            "label": "SRI VIJAY SHANTHI JAIN MATRIC HSS, THIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2167",
            "district": "31",
            "Block": "103",
            "label": "PUMS, THATHANKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2755",
            "district": "31",
            "Block": "103",
            "label": "PUMS, NARIYANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2826",
            "district": "31",
            "Block": "103",
            "label": "PUMS PULIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2904",
            "district": "31",
            "Block": "103",
            "label": "PUMS CHELLARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2944",
            "district": "31",
            "Block": "103",
            "label": "PUMS SIMMANAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3083",
            "district": "31",
            "Block": "103",
            "label": "PUMS SAMATHUVAPURAMH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3216",
            "district": "31",
            "Block": "103",
            "label": "PUMS, GOUNDAPPANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3310",
            "district": "31",
            "Block": "103",
            "label": "PUMS THOKKIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3392",
            "district": "31",
            "Block": "103",
            "label": "PUMS, ARAVAMATRAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3555",
            "district": "31",
            "Block": "103",
            "label": "PUMS RAGUPATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3700",
            "district": "31",
            "Block": "103",
            "label": "PUMS, KUNICHI MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4264",
            "district": "31",
            "Block": "103",
            "label": "PUMS, SEVATHUR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4276",
            "district": "31",
            "Block": "103",
            "label": "PUMS NAICKANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4555",
            "district": "31",
            "Block": "103",
            "label": "PUMS MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4691",
            "district": "31",
            "Block": "103",
            "label": "PUMS, KANDHILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4730",
            "district": "31",
            "Block": "103",
            "label": "PUMS, PARADASIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4994",
            "district": "31",
            "Block": "103",
            "label": "PUMS MERKATHIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5018",
            "district": "31",
            "Block": "103",
            "label": "PUMS RAVUTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5035",
            "district": "31",
            "Block": "103",
            "label": "ADWMS ADIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5097",
            "district": "31",
            "Block": "103",
            "label": "GADWMS, NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5191",
            "district": "31",
            "Block": "103",
            "label": "PUMS, PALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5199",
            "district": "31",
            "Block": "103",
            "label": "PUMS KAKANGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5393",
            "district": "31",
            "Block": "103",
            "label": "PUMS CHITHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5587",
            "district": "31",
            "Block": "103",
            "label": "PUMS (SSA), KODIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5625",
            "district": "31",
            "Block": "103",
            "label": "PUMS, NARAVINTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6263",
            "district": "31",
            "Block": "103",
            "label": "PUMS KALARPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6325",
            "district": "31",
            "Block": "103",
            "label": "HOLY CROSS MATRIC HSS, AATHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9088",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS MANDALANAYANAGUNDA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9089",
            "district": "31",
            "Block": "103",
            "label": "GHS GUMMIDIGAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9090",
            "district": "31",
            "Block": "103",
            "label": "GOVT GIRLS HSS GAJALNAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9091",
            "district": "31",
            "Block": "103",
            "label": "IVN GOVT BOYS HR SEC SCHOOL GAJALNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9092",
            "district": "31",
            "Block": "103",
            "label": "VAILANKANNI MATRICULATION SCHOOL VENGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9093",
            "district": "31",
            "Block": "103",
            "label": "JOTHI HS GAJALNAICKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9094",
            "district": "31",
            "Block": "103",
            "label": "PUMS, P.MUTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9095",
            "district": "31",
            "Block": "103",
            "label": "DR CHANDRALEKHA MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9096",
            "district": "31",
            "Block": "103",
            "label": "GOVERNMENT HIGH SCHOOL , VENGALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9097",
            "district": "31",
            "Block": "103",
            "label": "INDIAN MODERN MATRIC SCHOOL, KASINAICKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9098",
            "district": "31",
            "Block": "103",
            "label": "LINGANNAMANI MATRIC HSS, THAYAPPA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9099",
            "district": "31",
            "Block": "103",
            "label": "PUMS CHINNAKASINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9100",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS KASINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9101",
            "district": "31",
            "Block": "103",
            "label": "SHRI AMRITA HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9102",
            "district": "31",
            "Block": "103",
            "label": "PUMS, LAKKINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9103",
            "district": "31",
            "Block": "103",
            "label": "PUMS JALLIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9104",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS PERIYAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9105",
            "district": "31",
            "Block": "103",
            "label": "PUMS TELUNGUMATRAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9106",
            "district": "31",
            "Block": "103",
            "label": "PUMS, MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9107",
            "district": "31",
            "Block": "103",
            "label": "GHS KIZHAKKU BADANAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9108",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS SUNDHARAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9109",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS NATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9110",
            "district": "31",
            "Block": "103",
            "label": "PUMS  KOLKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9111",
            "district": "31",
            "Block": "103",
            "label": "GHSS PERIYAKANNALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9112",
            "district": "31",
            "Block": "103",
            "label": "SWAMI VIVEKANANDHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9113",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS SEVVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9114",
            "district": "31",
            "Block": "103",
            "label": "SRI BALA VIDYALAYA MATRIC SCHOOL, SEVVATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9115",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS KUNICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9116",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS ELAVAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9117",
            "district": "31",
            "Block": "103",
            "label": "SHRI VENKATESWARA MATRIC HR. SEC. SCHOOL, THORANAMPATHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9118",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS THORANAMPATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9119",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS KORATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9120",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS VISHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9121",
            "district": "31",
            "Block": "103",
            "label": "ST. MARY'S MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9122",
            "district": "31",
            "Block": "103",
            "label": "GOVT HS ANGANATHAVALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9123",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS PERAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9124",
            "district": "31",
            "Block": "103",
            "label": "ADWMS KURUMBERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9125",
            "district": "31",
            "Block": "103",
            "label": "GHSS KURUMBERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9126",
            "district": "31",
            "Block": "103",
            "label": "GOVT HSS MATRAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21441",
            "district": "31",
            "Block": "103",
            "label": "GHS ADIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21776",
            "district": "31",
            "Block": "103",
            "label": "RAJAS INTERNATIONALS SCHOOLS CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21777",
            "district": "31",
            "Block": "103",
            "label": "SRI VIJAY VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, ADIYUR, TIRUPATTUR..",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21778",
            "district": "31",
            "Block": "103",
            "label": "SREE SHAI SARAN MATRICULATION SCHOOL SEVATHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21779",
            "district": "31",
            "Block": "103",
            "label": "MAHA MATRIC SCHOOL, PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21780",
            "district": "31",
            "Block": "103",
            "label": "DR.THANGAMMA INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22073",
            "district": "31",
            "Block": "103",
            "label": "SRI V.R.V MATRIC HIGHER SECONDARY SCHOOL , PERAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22801",
            "district": "31",
            "Block": "103",
            "label": "CSI Public School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23591",
            "district": "31",
            "Block": "103",
            "label": "SRI VIJAY VIDYASHRAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23591",
            "district": "31",
            "Block": "103",
            "label": "SRI VIJAY VIDYASHRAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "104": [
        {
            "key": "722",
            "district": "32",
            "Block": "104",
            "label": "GLOBAL  MATRIC HR SEC SCHOOL, KANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "803",
            "district": "32",
            "Block": "104",
            "label": "JAYCEES MATRIC HR SEC SCHOOL, SIVANMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "809",
            "district": "32",
            "Block": "104",
            "label": "SRI RAJARAJESWARI MATRIC HR SEC SCHOOL, KANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1076",
            "district": "32",
            "Block": "104",
            "label": "OXFORD MATRICULATION SCHOOL, NATHAKKADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2158",
            "district": "32",
            "Block": "104",
            "label": "PUMS KANGAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2383",
            "district": "32",
            "Block": "104",
            "label": "PUMS SIVIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2933",
            "district": "32",
            "Block": "104",
            "label": "PUMS BHARATHIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3379",
            "district": "32",
            "Block": "104",
            "label": "SRI VIDHYA NIKETHAN MATRIC HR SEC SCHOOL, VARATHAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3559",
            "district": "32",
            "Block": "104",
            "label": "PUMS PALAYAKOTTAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3810",
            "district": "32",
            "Block": "104",
            "label": "MERCY MATRIC HR SEC SCHOOL, KADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4718",
            "district": "32",
            "Block": "104",
            "label": "JAIRUPAA MATRICULATION SCHOOL, THOTTIYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5172",
            "district": "32",
            "Block": "104",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12204",
            "district": "32",
            "Block": "104",
            "label": "GHS NEIKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12205",
            "district": "32",
            "Block": "104",
            "label": "PUMS MARUDURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12206",
            "district": "32",
            "Block": "104",
            "label": "T A M M GHSS NATHAKKADAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12207",
            "district": "32",
            "Block": "104",
            "label": "GHSS PADIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12208",
            "district": "32",
            "Block": "104",
            "label": "PUMS VARATHAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12209",
            "district": "32",
            "Block": "104",
            "label": "PUMS PARANJERVAZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12210",
            "district": "32",
            "Block": "104",
            "label": "PUMS AVINASIPALAYAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12211",
            "district": "32",
            "Block": "104",
            "label": "PUMS POTHIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12212",
            "district": "32",
            "Block": "104",
            "label": "PUMS SIVANMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12213",
            "district": "32",
            "Block": "104",
            "label": "CARMEL GIRLS HIGHER SECONDARY SCHOOL KANGAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12214",
            "district": "32",
            "Block": "104",
            "label": "GHSS KANGAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12215",
            "district": "32",
            "Block": "104",
            "label": "PUMS VEERANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21152",
            "district": "32",
            "Block": "104",
            "label": "SWAMI VIVEKANANDA MATRIC HR SEC SCHOOL, KANGEYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21309",
            "district": "32",
            "Block": "104",
            "label": "KOVAI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22464",
            "district": "32",
            "Block": "104",
            "label": "GHS KEERANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22465",
            "district": "32",
            "Block": "104",
            "label": "GHS THAMMAREDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22952",
            "district": "32",
            "Block": "104",
            "label": "GHS PERIYAILLIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23032",
            "district": "32",
            "Block": "104",
            "label": "GLOBAL INTERNATIONAL SCHOOL, KANGAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23807",
            "district": "32",
            "Block": "104",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23808",
            "district": "32",
            "Block": "104",
            "label": "PUMS VEERANAMPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23809",
            "district": "32",
            "Block": "104",
            "label": "GHS KEERANUR",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "105": [
        {
            "key": "637",
            "district": "36",
            "Block": "105",
            "label": "SEVENTH DAY ADVENTIST MATRIC HSS ,OTTERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1212",
            "district": "36",
            "Block": "105",
            "label": "SUN MATRIC HSS , VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2426",
            "district": "36",
            "Block": "105",
            "label": "PUMS KATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3071",
            "district": "36",
            "Block": "105",
            "label": "PUMS SALAMANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4026",
            "district": "36",
            "Block": "105",
            "label": "CAMFORD MATRIC HSS, KANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4433",
            "district": "36",
            "Block": "105",
            "label": "PUMS PANGALATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4543",
            "district": "36",
            "Block": "105",
            "label": "PUMS BAGAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4814",
            "district": "36",
            "Block": "105",
            "label": "PUMS ADUKKAMBARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5190",
            "district": "36",
            "Block": "105",
            "label": "PUMS MOTHAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5742",
            "district": "36",
            "Block": "105",
            "label": "VDS MATRIC HSS, EDAYANSATHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8686",
            "district": "36",
            "Block": "105",
            "label": "GHSS KILARASAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8687",
            "district": "36",
            "Block": "105",
            "label": "PUMS NANJUKONDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8688",
            "district": "36",
            "Block": "105",
            "label": "PUMS PALATHUVANNAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8689",
            "district": "36",
            "Block": "105",
            "label": "PUMS THUTHIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8690",
            "district": "36",
            "Block": "105",
            "label": "GHSS CHOLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23681",
            "district": "36",
            "Block": "105",
            "label": "Madras Matriculation Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8691",
            "district": "36",
            "Block": "105",
            "label": "PUMS PALLA EDAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8692",
            "district": "36",
            "Block": "105",
            "label": "GHSS VIRUPATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8693",
            "district": "36",
            "Block": "105",
            "label": "DESIA MATRIC HSS, VIRUPATCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8694",
            "district": "36",
            "Block": "105",
            "label": "MADRAS MHSS BAGAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8695",
            "district": "36",
            "Block": "105",
            "label": "PUMS PALAVANSATHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8696",
            "district": "36",
            "Block": "105",
            "label": "GHS EDAYANSATHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8697",
            "district": "36",
            "Block": "105",
            "label": "PUMS METTU EDYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8698",
            "district": "36",
            "Block": "105",
            "label": "ST JOSEPH AHS A .KATTUPADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8699",
            "district": "36",
            "Block": "105",
            "label": "EXCEL MATRIC SCHOOL, ADUKAMPARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8700",
            "district": "36",
            "Block": "105",
            "label": "PUMS THUTHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8701",
            "district": "36",
            "Block": "105",
            "label": "GHSS PENNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8702",
            "district": "36",
            "Block": "105",
            "label": "ANNAI MATRICULATION SCHOOL PENNATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8703",
            "district": "36",
            "Block": "105",
            "label": "PUMS VEPPAMPATTU (H)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8704",
            "district": "36",
            "Block": "105",
            "label": "AIDED RCM MS K KATTUPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8705",
            "district": "36",
            "Block": "105",
            "label": "GHSS KANIYAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8706",
            "district": "36",
            "Block": "105",
            "label": "PUMS SATHUMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8707",
            "district": "36",
            "Block": "105",
            "label": "PUMS ARCOTTON KUDISAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8708",
            "district": "36",
            "Block": "105",
            "label": "PUMS NAICKANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8709",
            "district": "36",
            "Block": "105",
            "label": "GHS MUNJURPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8710",
            "district": "36",
            "Block": "105",
            "label": "PUMS MOTTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8711",
            "district": "36",
            "Block": "105",
            "label": "PUMS KILVALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8712",
            "district": "36",
            "Block": "105",
            "label": "KKS MANI MATRIC HSS ,VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8713",
            "district": "36",
            "Block": "105",
            "label": "GHSS KAMMAVANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8714",
            "district": "36",
            "Block": "105",
            "label": "GHS KAMMASAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21432",
            "district": "36",
            "Block": "105",
            "label": "GGHS KILARASAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22387",
            "district": "36",
            "Block": "105",
            "label": "DREAMS MATRICULATION SCHOOL, KANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23631",
            "district": "36",
            "Block": "105",
            "label": "MAHARISHI VIDYA MANDIR-NELVOY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23631",
            "district": "36",
            "Block": "105",
            "label": "MAHARISHI VIDYA MANDIR-NELVOY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23810",
            "district": "36",
            "Block": "105",
            "label": "MERIDIAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "106": [
        {
            "key": "3705",
            "district": "23",
            "Block": "106",
            "label": "ILANGO .MID SCHOOL  VENGALORE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5261",
            "district": "23",
            "Block": "106",
            "label": "PUMS DEVANDADAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5721",
            "district": "23",
            "Block": "106",
            "label": "PUMS THIRUPPAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5865",
            "district": "23",
            "Block": "106",
            "label": "PUMS SADAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6214",
            "district": "23",
            "Block": "106",
            "label": "PUMS KALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17624",
            "district": "23",
            "Block": "106",
            "label": "ROMANCATHOLICMIDDLESCHOOL NANAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17625",
            "district": "23",
            "Block": "106",
            "label": "GOVT HSS HANUMANTHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17626",
            "district": "23",
            "Block": "106",
            "label": "PUMS UNJANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17627",
            "district": "23",
            "Block": "106",
            "label": "GOVT.(ADW)HS, UNJANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17628",
            "district": "23",
            "Block": "106",
            "label": "GOVT HS NARANAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17629",
            "district": "23",
            "Block": "106",
            "label": "PUMS MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17630",
            "district": "23",
            "Block": "106",
            "label": "GOVT HIGH SCHOOL SIRUVATCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17631",
            "district": "23",
            "Block": "106",
            "label": "GOVT HSS KANNANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17632",
            "district": "23",
            "Block": "106",
            "label": "PUMS KODIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17633",
            "district": "23",
            "Block": "106",
            "label": "PUMS VADAKEELGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17634",
            "district": "23",
            "Block": "106",
            "label": "PUMS CHITHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17635",
            "district": "23",
            "Block": "106",
            "label": "GOVERNMENT HIGH SCHOOL, T.SIRUVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21914",
            "district": "23",
            "Block": "106",
            "label": "SRI KALAIVANI INTERNATIONAL(ICSE)SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23552",
            "district": "23",
            "Block": "106",
            "label": "INTEL VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23552",
            "district": "23",
            "Block": "106",
            "label": "INTEL VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "107": [
        {
            "key": "540",
            "district": "4",
            "Block": "107",
            "label": "L.E.F. CHRISTIAN MATRICULATION SCHOOL, BEHIND HOUSING UNIT, METTUPALAYAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1232",
            "district": "4",
            "Block": "107",
            "label": "NATIONAL MATRIC  HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1246",
            "district": "4",
            "Block": "107",
            "label": "AMBAL MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1620",
            "district": "4",
            "Block": "107",
            "label": "MADONNA MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2117",
            "district": "4",
            "Block": "107",
            "label": "PUMS KARAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2229",
            "district": "4",
            "Block": "107",
            "label": "SRSI MATRIC. HR SEC SCHOOL, KARAMADAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2467",
            "district": "4",
            "Block": "107",
            "label": "HOLY ANGELS MAT HSS , SELVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2535",
            "district": "4",
            "Block": "107",
            "label": "PUM SCHOOL, IRUMBARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3227",
            "district": "4",
            "Block": "107",
            "label": "PUMS,CHINNATHOTTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3260",
            "district": "4",
            "Block": "107",
            "label": "SRI SARASWATHI VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3624",
            "district": "4",
            "Block": "107",
            "label": "PUMS,JADAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3743",
            "district": "4",
            "Block": "107",
            "label": "PUMS,CHIKKARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3881",
            "district": "4",
            "Block": "107",
            "label": "PASUPATHIAPPA MIDDLE SCHOOL, KARAMADAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4051",
            "district": "4",
            "Block": "107",
            "label": "METRO MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4478",
            "district": "4",
            "Block": "107",
            "label": "PUMS,KUTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4652",
            "district": "4",
            "Block": "107",
            "label": "ROSE GARDEN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4793",
            "district": "4",
            "Block": "107",
            "label": "PUMS,THERAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5297",
            "district": "4",
            "Block": "107",
            "label": "SAN JOSE MATRIC HR SEC SCHOOL, METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5875",
            "district": "4",
            "Block": "107",
            "label": "PUMS,GOBANARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5920",
            "district": "4",
            "Block": "107",
            "label": "PUMS KENDEPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6039",
            "district": "4",
            "Block": "107",
            "label": "SVGV MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6272",
            "district": "4",
            "Block": "107",
            "label": "BRINDHAVANNA VIDHIALAYA SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6594",
            "district": "4",
            "Block": "107",
            "label": "ST.JOSEPH'S MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13131",
            "district": "4",
            "Block": "107",
            "label": "PUMS MANGALAKARAI PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13132",
            "district": "4",
            "Block": "107",
            "label": "TMT KEMPANANJAMMA MML HR SEC SCHOOL  KARAMADAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13133",
            "district": "4",
            "Block": "107",
            "label": "GOVT HR SEC SCHOOL, KARAMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13134",
            "district": "4",
            "Block": "107",
            "label": "PUMS,NEELAMPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13135",
            "district": "4",
            "Block": "107",
            "label": "GHS THOLAMPALYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13136",
            "district": "4",
            "Block": "107",
            "label": "PUMS,PALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13137",
            "district": "4",
            "Block": "107",
            "label": "ROSARI GIRLS HIGH SCHOOL METTUPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13138",
            "district": "4",
            "Block": "107",
            "label": "GHSS METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13139",
            "district": "4",
            "Block": "107",
            "label": "PUMS,SUNDHANTHIRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13140",
            "district": "4",
            "Block": "107",
            "label": "GHS KANNARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13141",
            "district": "4",
            "Block": "107",
            "label": "VIDYAA VIKAS MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13142",
            "district": "4",
            "Block": "107",
            "label": "PUMS,CHINNAKUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13143",
            "district": "4",
            "Block": "107",
            "label": "PUMS,M.KOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13144",
            "district": "4",
            "Block": "107",
            "label": "PUMS,PUDHUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13145",
            "district": "4",
            "Block": "107",
            "label": "GHSS PETHIKUTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13146",
            "district": "4",
            "Block": "107",
            "label": "GHSS VELLIANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13147",
            "district": "4",
            "Block": "107",
            "label": "GHSS S.PUNGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13148",
            "district": "4",
            "Block": "107",
            "label": "GHSS OOMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13149",
            "district": "4",
            "Block": "107",
            "label": "PUMS,LINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13150",
            "district": "4",
            "Block": "107",
            "label": "PUMS ALATHI VATCHINAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13151",
            "district": "4",
            "Block": "107",
            "label": "PUMS MOOLATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13152",
            "district": "4",
            "Block": "107",
            "label": "VIJAYALAKSHMI MATRICULATION HSS, SIRUMUGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13153",
            "district": "4",
            "Block": "107",
            "label": "GOVT HR SEC SCHOOL SIRUMUGAIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13154",
            "district": "4",
            "Block": "107",
            "label": "GHS ILLUPPAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13155",
            "district": "4",
            "Block": "107",
            "label": "ALAGIRI SURESH GHS ALANGOMBU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13156",
            "district": "4",
            "Block": "107",
            "label": "SHREE SARASSWATHI VIDHYAAH MANDHEER SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13157",
            "district": "4",
            "Block": "107",
            "label": "PUMS,BELLEPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13158",
            "district": "4",
            "Block": "107",
            "label": "E.A.B GOVT HR SEC SCHOOL THENPONMUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13159",
            "district": "4",
            "Block": "107",
            "label": "PUMS THEKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13160",
            "district": "4",
            "Block": "107",
            "label": "PUMS DHASANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13161",
            "district": "4",
            "Block": "107",
            "label": "GADW HR SEC SCHOOL WELSPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13162",
            "district": "4",
            "Block": "107",
            "label": "PUMS K.PUNGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13163",
            "district": "4",
            "Block": "107",
            "label": "PUMS,THIMMAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13164",
            "district": "4",
            "Block": "107",
            "label": "GHSS , BUJANGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13165",
            "district": "4",
            "Block": "107",
            "label": "T.DURAISWAMY GOWDER HSS, SEELIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23391",
            "district": "4",
            "Block": "107",
            "label": "SRI RAGAVENDRA VIDYALAYA.",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "13166",
            "district": "4",
            "Block": "107",
            "label": "SRI VINAYAGA VIDHYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13167",
            "district": "4",
            "Block": "107",
            "label": "GHS PARALI POWER HOUSE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13168",
            "district": "4",
            "Block": "107",
            "label": "C.S.I. MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13169",
            "district": "4",
            "Block": "107",
            "label": "G.M.R.C. MIDDLE SCHOOL, METTUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13170",
            "district": "4",
            "Block": "107",
            "label": "CAREWELL MATRIC HR SEC SCHOOL , METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13171",
            "district": "4",
            "Block": "107",
            "label": "MPL GIRLS HSS,METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13172",
            "district": "4",
            "Block": "107",
            "label": "MAHAJANA HSS,METTUPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13173",
            "district": "4",
            "Block": "107",
            "label": "CHRIST THE KING MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20883",
            "district": "4",
            "Block": "107",
            "label": "SATCHIDANANDA JOTHI NIKETHAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20884",
            "district": "4",
            "Block": "107",
            "label": "SRI AMBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21156",
            "district": "4",
            "Block": "107",
            "label": "SHREE SARASSWATHI VIDHYAAH MANDHEER MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21318",
            "district": "4",
            "Block": "107",
            "label": "VIDYAA VIKAS INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21882",
            "district": "4",
            "Block": "107",
            "label": "VIDYAA VIKAS MAT. GIRLS HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22488",
            "district": "4",
            "Block": "107",
            "label": "TRG MATRICULATION SCHOOL, BUJANGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22489",
            "district": "4",
            "Block": "107",
            "label": "MUNICIPAL HIGH SCHOOL MANI NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22748",
            "district": "4",
            "Block": "107",
            "label": "BHARATH VIDHYA NIKETHAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22820",
            "district": "4",
            "Block": "107",
            "label": "VIVIDH INTERNATIONAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22977",
            "district": "4",
            "Block": "107",
            "label": "BHAGWAN MAHAVEER MUNICIPAL HIGH SCHOOL, SANKAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23175",
            "district": "4",
            "Block": "107",
            "label": "KARAMADAI RANGANATHAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23811",
            "district": "4",
            "Block": "107",
            "label": "ST.JOSEPH'S MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23812",
            "district": "4",
            "Block": "107",
            "label": "NAV BHARATH MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23813",
            "district": "4",
            "Block": "107",
            "label": "EXPONICS ACADEMY",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "108": [
        {
            "key": "1050",
            "district": "19",
            "Block": "108",
            "label": "GUTHOOS MATRIC SCHOOL, KARAMBAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1467",
            "district": "19",
            "Block": "108",
            "label": "REENA MERCY MATRIC SCHOOL, KARAMBAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4919",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THEKKIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6519",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL K.THERKUTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17044",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THATTAMANAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17045",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17046",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, INNANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17047",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PATHUTHAKKU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17048",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAPPAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17049",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKOTTAIVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17050",
            "district": "19",
            "Block": "108",
            "label": "GOVT HIGH SCHOOL, KILANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17051",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HR.SEC.SCHOOL, REGUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17052",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HR.SEC.SCHOOL, KALIYARAYANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17053",
            "district": "19",
            "Block": "108",
            "label": "JEVANJOTHI.HR.SEC.SCHOOL, KONAKOLLAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17054",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL, BANTHUVAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17055",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAYT UNION MIDDLE SCHOOL MYLANKONEPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17056",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARAMBAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17057",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEVVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17058",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PILAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17059",
            "district": "19",
            "Block": "108",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL, PILAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17060",
            "district": "19",
            "Block": "108",
            "label": "PUMS.RANGIYANVIDUTHI-NEW",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17061",
            "district": "19",
            "Block": "108",
            "label": "GOVT.BOYS HR.SEC.SCHOOL, KARAMBAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17062",
            "district": "19",
            "Block": "108",
            "label": "GOVT.GIRLS.HR.SEC.SCHOOL,KARAMBAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17063",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOLAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17064",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL, PALLAVARAYANPATHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17065",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ELAIKADIVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17066",
            "district": "19",
            "Block": "108",
            "label": "GHS.THIRUMANANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17067",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARUKKAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17068",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KURUMBIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17069",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VANAKKANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17070",
            "district": "19",
            "Block": "108",
            "label": "GOVT ADW HR SEC SCHOOL MULLANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17071",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADUKKAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17072",
            "district": "19",
            "Block": "108",
            "label": "PUMS.KANNAKKANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17073",
            "district": "19",
            "Block": "108",
            "label": "GHSS.VETTANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17074",
            "district": "19",
            "Block": "108",
            "label": "PUMS.KRISHNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17075",
            "district": "19",
            "Block": "108",
            "label": "PUMS.MEENAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17076",
            "district": "19",
            "Block": "108",
            "label": "GHS.MOLUDAIYANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17077",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HR.SEC.SCHOOL, MALAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17078",
            "district": "19",
            "Block": "108",
            "label": "CHRIST THE KING MATRICULATION SCHOOL,SELVANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17079",
            "district": "19",
            "Block": "108",
            "label": "PUMS, NAINANKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17080",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL, PONNANVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17081",
            "district": "19",
            "Block": "108",
            "label": "PUMS, NAMBANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17082",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL, KEELAPATTI (M)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17083",
            "district": "19",
            "Block": "108",
            "label": "PUMS, ODAPPAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17084",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17085",
            "district": "19",
            "Block": "108",
            "label": "GOVT.HIGH SCHOOL,AMBUKKOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17086",
            "district": "19",
            "Block": "108",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELLALAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17087",
            "district": "19",
            "Block": "108",
            "label": "PUMS, THEETHANIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21540",
            "district": "19",
            "Block": "108",
            "label": "ST.JOSEPH.HIGHER SECONDARY SCHOOL.KOTTAIKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22231",
            "district": "19",
            "Block": "108",
            "label": "BRIGHT  MATRIC HR.SEC. SCHOOL KARAMBAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22589",
            "district": "19",
            "Block": "108",
            "label": "GOVT HIGH SCHOOL  KOLANTHIRANPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "109": [
        {
            "key": "2806",
            "district": "6",
            "Block": "109",
            "label": "ST. PAUL'S MHSS, VELLICHANDHAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4469",
            "district": "6",
            "Block": "109",
            "label": "PUMS GUDDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4773",
            "district": "6",
            "Block": "109",
            "label": "PUMS B GETTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5540",
            "district": "6",
            "Block": "109",
            "label": "PUMS MURUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6016",
            "district": "6",
            "Block": "109",
            "label": "THANAPPA GOUNDER MHSS, KERAKODAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9338",
            "district": "6",
            "Block": "109",
            "label": "PUMS ADDILAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9339",
            "district": "6",
            "Block": "109",
            "label": "PUMS BALLENAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9340",
            "district": "6",
            "Block": "109",
            "label": "GHSS BEGARAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9341",
            "district": "6",
            "Block": "109",
            "label": "PUMS BODARA HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9342",
            "district": "6",
            "Block": "109",
            "label": "GHS MEKKANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9343",
            "district": "6",
            "Block": "109",
            "label": "PUMS BOMMANDAHALLI MODUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9344",
            "district": "6",
            "Block": "109",
            "label": "PUMS CHENNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9345",
            "district": "6",
            "Block": "109",
            "label": "PUMS DHASAMBAIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9346",
            "district": "6",
            "Block": "109",
            "label": "PUMS DHINDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9347",
            "district": "6",
            "Block": "109",
            "label": "PUMS ERRASEEGALA HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9348",
            "district": "6",
            "Block": "109",
            "label": "PUMS GENGUCHETTI PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9349",
            "district": "6",
            "Block": "109",
            "label": "PUMS GOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9350",
            "district": "6",
            "Block": "109",
            "label": "PUMS GUNDALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9351",
            "district": "6",
            "Block": "109",
            "label": "GHSS HANUMANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9352",
            "district": "6",
            "Block": "109",
            "label": "GHSS JAKKASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9353",
            "district": "6",
            "Block": "109",
            "label": "PUMS JAMBOOTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9354",
            "district": "6",
            "Block": "109",
            "label": "PUMS JITTANDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9355",
            "district": "6",
            "Block": "109",
            "label": "PUMS KULI KADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9356",
            "district": "6",
            "Block": "109",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23390",
            "district": "6",
            "Block": "109",
            "label": "MULLAI HIGH SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "9357",
            "district": "6",
            "Block": "109",
            "label": "GHSS KANNIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9358",
            "district": "6",
            "Block": "109",
            "label": "PUMS KARAGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9359",
            "district": "6",
            "Block": "109",
            "label": "GHSS KARIMANGALAM - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9360",
            "district": "6",
            "Block": "109",
            "label": "GHSS KARIMANGALAM - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9361",
            "district": "6",
            "Block": "109",
            "label": "BHARATH MATRIC SCHOOL, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9362",
            "district": "6",
            "Block": "109",
            "label": "KGBV RESIDENTIAL SCHOOL, KARIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9363",
            "district": "6",
            "Block": "109",
            "label": "SHANTHINIKETHAN HR. SEC. SCHOOL, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9364",
            "district": "6",
            "Block": "109",
            "label": "PUMS KATHIRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9365",
            "district": "6",
            "Block": "109",
            "label": "PUMS KERAKODA HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9366",
            "district": "6",
            "Block": "109",
            "label": "PUMS KOTHALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9367",
            "district": "6",
            "Block": "109",
            "label": "GHS KOTTUMARANA HALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9368",
            "district": "6",
            "Block": "109",
            "label": "PUMS KOVILOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9369",
            "district": "6",
            "Block": "109",
            "label": "PUMS KUPPANGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9370",
            "district": "6",
            "Block": "109",
            "label": "GHS MADHAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9371",
            "district": "6",
            "Block": "109",
            "label": "GHSS KAMALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9372",
            "district": "6",
            "Block": "109",
            "label": "GHSS MALLUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9373",
            "district": "6",
            "Block": "109",
            "label": "PUMS MARAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9374",
            "district": "6",
            "Block": "109",
            "label": "GHSS MATLAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9375",
            "district": "6",
            "Block": "109",
            "label": "PUMS MOLAPPANA HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9376",
            "district": "6",
            "Block": "109",
            "label": "PUMS MORASU HALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9377",
            "district": "6",
            "Block": "109",
            "label": "PUMS MOTTALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9378",
            "district": "6",
            "Block": "109",
            "label": "PUMS MUKKULAMSEEGALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9379",
            "district": "6",
            "Block": "109",
            "label": "PUMS NAGANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9380",
            "district": "6",
            "Block": "109",
            "label": "PUMS PARAIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9381",
            "district": "6",
            "Block": "109",
            "label": "PUMS PERIYADHABBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9382",
            "district": "6",
            "Block": "109",
            "label": "GHSS PERIYAMPATTI Boys",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9383",
            "district": "6",
            "Block": "109",
            "label": "SRI ANNAMALAIYAR MATRIC SCHOOL, KALAPPANAHALLI, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9384",
            "district": "6",
            "Block": "109",
            "label": "PUMS PULIKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9385",
            "district": "6",
            "Block": "109",
            "label": "PUMS RAMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9386",
            "district": "6",
            "Block": "109",
            "label": "PUMS SOKKANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9387",
            "district": "6",
            "Block": "109",
            "label": "PUMS SONNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9388",
            "district": "6",
            "Block": "109",
            "label": "PUMS SOTTANDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9389",
            "district": "6",
            "Block": "109",
            "label": "PUMS SUNNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9390",
            "district": "6",
            "Block": "109",
            "label": "GHSS BANDHARAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9391",
            "district": "6",
            "Block": "109",
            "label": "GHSS BOMMAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9392",
            "district": "6",
            "Block": "109",
            "label": "PUMS ELUMICHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9393",
            "district": "6",
            "Block": "109",
            "label": "PUMS KUMBARAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9394",
            "district": "6",
            "Block": "109",
            "label": "PUMS MAHENDRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9395",
            "district": "6",
            "Block": "109",
            "label": "PUMS KANDAGA BILE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21200",
            "district": "6",
            "Block": "109",
            "label": "MODEL SCHOOL KARIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21240",
            "district": "6",
            "Block": "109",
            "label": "SHREE B.C.R. MHSS, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21445",
            "district": "6",
            "Block": "109",
            "label": "GHSS PERIYAMPATTI - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21544",
            "district": "6",
            "Block": "109",
            "label": "SHARON HIGH SCHOOL, MATLAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22080",
            "district": "6",
            "Block": "109",
            "label": "SRI VIJAY VIDYALAYA MATRIC SCHOOL, THANDUKARANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22408",
            "district": "6",
            "Block": "109",
            "label": "GHS THUMBALAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22946",
            "district": "6",
            "Block": "109",
            "label": "GHS GUNDAGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23034",
            "district": "6",
            "Block": "109",
            "label": "SRI RAGHAVENDRA HIGH SCHOOL, KARIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23814",
            "district": "6",
            "Block": "109",
            "label": "PUMS MARAVADI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "110": [
        {
            "key": "649",
            "district": "38",
            "Block": "110",
            "label": "NAZIA MATRICULATION HR.SEC.SCHOOL, S.KALLUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2291",
            "district": "38",
            "Block": "110",
            "label": "ST.MARYS MATRICULATION SCHOOL KARIAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4676",
            "district": "38",
            "Block": "110",
            "label": "SUNDRAM MATRICULATION HR. SEC. SCHOOL KRISHNAPURAM AVIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4704",
            "district": "38",
            "Block": "110",
            "label": "SARVA SEVA MATRICULATION SCHOOL, THONUGAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18762",
            "district": "38",
            "Block": "110",
            "label": "KENDRIYA VIDYALAYA, VIRUDHUNAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18765",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANGULAM UDAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18766",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL, KURANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18767",
            "district": "38",
            "Block": "110",
            "label": "FROEBEL FRIEDRICH VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, AVIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18768",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, AVIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18769",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARASAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18770",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MUSTAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18771",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELAKKALANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18772",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL P. PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18773",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL V. NANGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18774",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAPPANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18775",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAMBIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18776",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEENATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18777",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL S.KALLUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18778",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL PAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18779",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18780",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL PISINDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18781",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ACHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18782",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL,MANTHOPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18783",
            "district": "38",
            "Block": "110",
            "label": "GOVT MIDDLE SCHOOL  SOLAIKAVUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18784",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELA ALAGIANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18785",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALAGIANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18786",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELATHULUKKANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18787",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THANDIYANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18788",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL THONUGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18789",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18790",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VARALOTTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18791",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MILL COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18792",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL, KALKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18793",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, JOKILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18794",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VAKKANANKUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18795",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL, KALUVANACHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18796",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THOPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18797",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL, CHITHUMOONDRADAIPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18798",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL, CHATIRAM PULIANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18799",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A. THOTTIYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18800",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, T. VEPPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18801",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL MUDUKKANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18802",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGH SCHOOL, VALLAPPANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18803",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MURUGAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18804",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, T. CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18805",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SALAIMARAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18806",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18807",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SURANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18808",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHOKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18809",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL E. THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18810",
            "district": "38",
            "Block": "110",
            "label": "GOVT HIGH SCHOOL KAMBALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18811",
            "district": "38",
            "Block": "110",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARIAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18812",
            "district": "38",
            "Block": "110",
            "label": "AMALA HIGH SCHOOL KARIAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18813",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KARIAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18814",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MALLANKINAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18815",
            "district": "38",
            "Block": "110",
            "label": "M N M.S.P. SENTHIKUMARA NADAR HIGHER SECONDARY SCHOOL, MALLANKINAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18816",
            "district": "38",
            "Block": "110",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KARIAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22784",
            "district": "38",
            "Block": "110",
            "label": "SRI SABARI NATIONAL SCHOOL, KARIAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "111": [
        {
            "key": "3254",
            "district": "28",
            "Block": "111",
            "label": "CAMBRIDGE MATRICULATION SCHOOL, KONGARAYAKURICHI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5860",
            "district": "28",
            "Block": "111",
            "label": "ST.LUCIA R.C MIDDLE SCHOOL , SEYDUNGANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19318",
            "district": "28",
            "Block": "111",
            "label": "RC MIDDLE SCHOOL KALIYAVOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19319",
            "district": "28",
            "Block": "111",
            "label": "GHSS SAVALAPERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19320",
            "district": "28",
            "Block": "111",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  KEELAPOOVANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19321",
            "district": "28",
            "Block": "111",
            "label": "HINDU MIDDLE SCHOOL CHOKALINGAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19322",
            "district": "28",
            "Block": "111",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SEKKARAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19323",
            "district": "28",
            "Block": "111",
            "label": "GHS KASILINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19324",
            "district": "28",
            "Block": "111",
            "label": "NARAYANAN MS ELLAINAYACKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19325",
            "district": "28",
            "Block": "111",
            "label": "PUMS POTTALURANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19326",
            "district": "28",
            "Block": "111",
            "label": "PUMS VALLANADU SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19327",
            "district": "28",
            "Block": "111",
            "label": "VTVD GHSS  VALLANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19328",
            "district": "28",
            "Block": "111",
            "label": "SARAH MATRICULATION HIGHER SECONDNARY SCHOOL, HAMIDIYANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19329",
            "district": "28",
            "Block": "111",
            "label": "TNDTA MS MANAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19330",
            "district": "28",
            "Block": "111",
            "label": "TNDTA MS ALWARKARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19331",
            "district": "28",
            "Block": "111",
            "label": "PUMS ARAMPANNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19332",
            "district": "28",
            "Block": "111",
            "label": "MEERA MATRICULATION SCHOOL, ARAMPANNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19333",
            "district": "28",
            "Block": "111",
            "label": "GHSS KONGARAYAKURUCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19334",
            "district": "28",
            "Block": "111",
            "label": "PUMS MURAPPANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19335",
            "district": "28",
            "Block": "111",
            "label": "GOVT. H.S.ANAVARATHANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19336",
            "district": "28",
            "Block": "111",
            "label": "MMMS SEYDUNGANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19337",
            "district": "28",
            "Block": "111",
            "label": "HINDU JEYALAKSHMI MS KILAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19338",
            "district": "28",
            "Block": "111",
            "label": "GHSS KARUNKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19339",
            "district": "28",
            "Block": "111",
            "label": "PUMS MELASRIYANTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19340",
            "district": "28",
            "Block": "111",
            "label": "PUMS MANALVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19341",
            "district": "28",
            "Block": "111",
            "label": "GHS  KALVOY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19342",
            "district": "28",
            "Block": "111",
            "label": "GHSS RAMANUJAMPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19343",
            "district": "28",
            "Block": "111",
            "label": "TNDTA MS THATHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "22635",
            "district": "28",
            "Block": "111",
            "label": "JOS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23815",
            "district": "28",
            "Block": "111",
            "label": "Thoothukkudi District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "112": [
        {
            "key": "2095",
            "district": "12",
            "Block": "112",
            "label": "TNPL MATRICULATION HR.SEC.SCHOOL, KAGITHAAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2783",
            "district": "12",
            "Block": "112",
            "label": "CHERAN MATRIC.HIGH.SCHOOL, KARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3275",
            "district": "12",
            "Block": "112",
            "label": "ST.THERESA MATRIC. HR. SEC. SCHOOL,KARUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3307",
            "district": "12",
            "Block": "112",
            "label": "KARUR VETRI VINAYAKA MATRIC. HR.SEC.SCHOOL, KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3347",
            "district": "12",
            "Block": "112",
            "label": "KONGU VELLALAR MATRIC. HR.SEC.SCHOOL, KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3362",
            "district": "12",
            "Block": "112",
            "label": "SRI RAMAKRISHNA MATRICULATION SCHOOL, MUTHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3373",
            "district": "12",
            "Block": "112",
            "label": "ST.MARY'S MATRIC HIGHER SECONDARY SCHOOL,VANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3504",
            "district": "12",
            "Block": "112",
            "label": "NAVJEEVAN MATRIC SCHOOL,PANCHAMADEVI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3840",
            "district": "12",
            "Block": "112",
            "label": "CHERAN MAT. HR.SEC.SCHOOL, VENNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4110",
            "district": "12",
            "Block": "112",
            "label": "P.U.MIDDLE SCHOOL,PANCHAMADEVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4614",
            "district": "12",
            "Block": "112",
            "label": "JAIRAM VIDYA BHAVAN MATRIC.HR.SEC. SCHOOL,THIRUGAMPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4649",
            "district": "12",
            "Block": "112",
            "label": "BHARANI PARK MATRIC.HR.SEC. SCHOOL, VENNAIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4665",
            "district": "12",
            "Block": "112",
            "label": "VIVEKANANDA  MATRIC HIGHER SECONDARY SCHOOL,PASUPATHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4675",
            "district": "12",
            "Block": "112",
            "label": "GURUDEVAR MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4677",
            "district": "12",
            "Block": "112",
            "label": "BHARANI VIDHYALAYA SCHOOL ,VENNAIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4725",
            "district": "12",
            "Block": "112",
            "label": "TNPL PUBLIC SCHOOL,KAKITHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4787",
            "district": "12",
            "Block": "112",
            "label": "SRI KRISHNA MATRIC.SCHOOL,VELAYUTHAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4884",
            "district": "12",
            "Block": "112",
            "label": "KVBOA MATRIC. HR.SEC. SCHOOL, KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4888",
            "district": "12",
            "Block": "112",
            "label": "P.U.MIDDLE SCHOOL, NOCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4927",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,N.PUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4962",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,MOOLIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5123",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,THANGARAJ,NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5163",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,SEVANTHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5203",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,THIRUMUKKUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5236",
            "district": "12",
            "Block": "112",
            "label": "P.U.MIDDLE SCHOOL,THALAVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5403",
            "district": "12",
            "Block": "112",
            "label": "LITTLE ANGELS' ENGLISH HR.SEC.SCHOOL,KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5446",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL EAST THAVUTTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5456",
            "district": "12",
            "Block": "112",
            "label": "GANDHIYAR MID.SCHOOL,VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5495",
            "district": "12",
            "Block": "112",
            "label": "MUNICIPAL JAYAPRAKASH MIDDLE SCHOOL,KARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5580",
            "district": "12",
            "Block": "112",
            "label": "MPL PASUPATHIPALAYAM MID SCHOOL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5767",
            "district": "12",
            "Block": "112",
            "label": "RAJA AIDED MIDDLE SCHOOL,VEDICHIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5770",
            "district": "12",
            "Block": "112",
            "label": "MPL UMAIYAL MID SCHOOL, KARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5795",
            "district": "12",
            "Block": "112",
            "label": "VOC AIDED MIDDLE SCHOOL,KARUR.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6136",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,P.PUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6145",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,V.PASUPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6151",
            "district": "12",
            "Block": "112",
            "label": "SRI ANNAI VIDHYALAYA MATRICULATION SCHOOL,VENGAMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6205",
            "district": "12",
            "Block": "112",
            "label": "RASAMMAL MATRIC. Hr.Sec.School, VETTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6386",
            "district": "12",
            "Block": "112",
            "label": "PON VIDHYA MANDIR CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6697",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,PERIYAKULATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6722",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,THIRUVALLUVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13827",
            "district": "12",
            "Block": "112",
            "label": "RANGASAMY GOUNDER HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13828",
            "district": "12",
            "Block": "112",
            "label": "P.E.V.R. GOVT.HR.SEC.SCH,NOYYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13829",
            "district": "12",
            "Block": "112",
            "label": "GOVT.BOYS. HR.SEC SCHOOL,PUGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13830",
            "district": "12",
            "Block": "112",
            "label": "GOVT GIRLS HR SEC SCHOOL, PUGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13831",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HIGH.P.THOTTAKURUCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13832",
            "district": "12",
            "Block": "112",
            "label": "LITERA VALLEY ZEE SCHOOL,MANMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13833",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HIGH.SCHOOL,N.PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13834",
            "district": "12",
            "Block": "112",
            "label": "GOVT.GIRLS.HIGHER SECONDARY SCHOOL, VANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13835",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HR.SEC.SCHOOL,VANGAL(B)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13836",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HR.SEC.SCHOOL, MANMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13837",
            "district": "12",
            "Block": "112",
            "label": "SRI SARASWATHI VIDHYA MANDHIR  MATRIC HIGHER SECONDARY SCHOOL, MANMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13838",
            "district": "12",
            "Block": "112",
            "label": "GOVT HIGH SCHOOL,ATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13839",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,VENGAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13840",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID SCHOOL,V.V.G NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13841",
            "district": "12",
            "Block": "112",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PERIYAKULATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13842",
            "district": "12",
            "Block": "112",
            "label": "GOVT.GIRLS HR.SEC SCHOOL,KARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13843",
            "district": "12",
            "Block": "112",
            "label": "MPL (B) HR.SEC.SCHOOL,KARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13844",
            "district": "12",
            "Block": "112",
            "label": "CSI BOY'S HR.SEC SCHOOL,KARUR.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13845",
            "district": "12",
            "Block": "112",
            "label": "CSI GIRL'S HR.SEC SCHOOL,KARUR.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13846",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HIGH SCHOOL,VANGAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13847",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HR.SEC.SCHOOL,NERUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13848",
            "district": "12",
            "Block": "112",
            "label": "P.U.MID.SCHOOL,N.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13849",
            "district": "12",
            "Block": "112",
            "label": "GOVT.HR.SEC.SCHOOL,RANGANATHANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13850",
            "district": "12",
            "Block": "112",
            "label": "ADW MID.SCH,THIRUKKAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13851",
            "district": "12",
            "Block": "112",
            "label": "MPL KUMARAN HIGH SCH,KARUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13852",
            "district": "12",
            "Block": "112",
            "label": "MORNING STAR HIGH SCHOOL, KARUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13853",
            "district": "12",
            "Block": "112",
            "label": "PASUPATHEESWARA MUNICIPAL GIRLS Hr. Sec. School, Karur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13854",
            "district": "12",
            "Block": "112",
            "label": "KONGU  HR.SEC.SCHOOL, VENNAIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13855",
            "district": "12",
            "Block": "112",
            "label": "VIVEKANANDA MIDDLE SCHOOL,PASUPATHYPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13856",
            "district": "12",
            "Block": "112",
            "label": "VIVEKANANTHA BOYS HR.SEC.SCHOOL,PASUPATHIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13857",
            "district": "12",
            "Block": "112",
            "label": "SRI  SARADA  GIRLS HIGHER  SECONDARY  SCHOOL, PASUPATHIPALAYAM, KARUR-4",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13858",
            "district": "12",
            "Block": "112",
            "label": "MUNICIPAL MIDDLE SCHOOL,THIRUMANILAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21370",
            "district": "12",
            "Block": "112",
            "label": "GOVERNMENT HIGH SCHOOL, SOMUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21567",
            "district": "12",
            "Block": "112",
            "label": "MPL KOTTAIMEDU HIGH SCHOOL, KARUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21758",
            "district": "12",
            "Block": "112",
            "label": "EQUITAS GURUKUL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23027",
            "district": "12",
            "Block": "112",
            "label": "SHARVALAKSHA VIDHYAALAYAA,VETTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23217",
            "district": "12",
            "Block": "112",
            "label": "SRI CHAITANYA TECHNO SCHOOL , KARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "113": [
        {
            "key": "1561",
            "district": "36",
            "Block": "113",
            "label": "ST.MARKS MATRIC HSS, KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1813",
            "district": "36",
            "Block": "113",
            "label": "SHRISHTI MATRIC HSS, BRAMMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1937",
            "district": "36",
            "Block": "113",
            "label": "DR.VGN MATRIC SCHOOL, PONNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2210",
            "district": "36",
            "Block": "113",
            "label": "VIDYANIKETAN MATRIC HSS, GANDHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2282",
            "district": "36",
            "Block": "113",
            "label": "BETHEL MATRIC HSS, VALLIMALAI ROAD ,KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3228",
            "district": "36",
            "Block": "113",
            "label": "VANI VIDYALAYA MATRIC. HR. SEC. SCHOOL KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3409",
            "district": "36",
            "Block": "113",
            "label": "DONBOSCO MATRIC HSS, KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3444",
            "district": "36",
            "Block": "113",
            "label": "WILLIAMS MATRIC HSS, VELLAIKALMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3805",
            "district": "36",
            "Block": "113",
            "label": "CLUNY MHSS KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3829",
            "district": "36",
            "Block": "113",
            "label": "SHRI MANU MAKHIJA MEMORIAL SHRISHTI MATRIC SCHOOL, SENGUTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3871",
            "district": "36",
            "Block": "113",
            "label": "SHRISHTI CBSE BRAMMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3888",
            "district": "36",
            "Block": "113",
            "label": "SUNBEAM MATRIC HSS, METTUKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4116",
            "district": "36",
            "Block": "113",
            "label": "PUMS KAMMAVAR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4242",
            "district": "36",
            "Block": "113",
            "label": "Kings Matric HSS Kasam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4359",
            "district": "36",
            "Block": "113",
            "label": "SUNBEAM CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4604",
            "district": "36",
            "Block": "113",
            "label": "PUMS L.G.Pudur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5084",
            "district": "36",
            "Block": "113",
            "label": "PUMS Kuppatha Mottur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5426",
            "district": "36",
            "Block": "113",
            "label": "TRINITY MATRIC HSS, GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5497",
            "district": "36",
            "Block": "113",
            "label": "PUMS BALAMUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5548",
            "district": "36",
            "Block": "113",
            "label": "PUMS PEDDABODINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5569",
            "district": "36",
            "Block": "113",
            "label": "DESIA MATRIC SCHOOL, VANJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5584",
            "district": "36",
            "Block": "113",
            "label": "MDMS BALAYANKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5790",
            "district": "36",
            "Block": "113",
            "label": "SWAMY VIVEKANANDA MATRIC  HR SEC SCHOOL, SERKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5793",
            "district": "36",
            "Block": "113",
            "label": "ADWMS Brammapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6236",
            "district": "36",
            "Block": "113",
            "label": "AMS MSCA Thirupakkuttai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6284",
            "district": "36",
            "Block": "113",
            "label": "PUMS Vandranthangal",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6368",
            "district": "36",
            "Block": "113",
            "label": "POORNA VIDHYALAYA MATRIC SCHOOL, THIRUVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6459",
            "district": "36",
            "Block": "113",
            "label": "PUMS THENPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6476",
            "district": "36",
            "Block": "113",
            "label": "PUMS KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6479",
            "district": "36",
            "Block": "113",
            "label": "PUMS Eranthangal",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6559",
            "district": "36",
            "Block": "113",
            "label": "PUMS Kandipedu",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8504",
            "district": "36",
            "Block": "113",
            "label": "PUMS THENGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8505",
            "district": "36",
            "Block": "113",
            "label": "PUMS PONNAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8506",
            "district": "36",
            "Block": "113",
            "label": "ANMMS P.N. PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8507",
            "district": "36",
            "Block": "113",
            "label": "GOVT GIRLS HSS PONNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8508",
            "district": "36",
            "Block": "113",
            "label": "GOVT BOYS HSS PONNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8509",
            "district": "36",
            "Block": "113",
            "label": "DECCAN MATRICULATION SCHOOL PONNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8510",
            "district": "36",
            "Block": "113",
            "label": "PUMS KOKKERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8511",
            "district": "36",
            "Block": "113",
            "label": "PUMS THEEYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8512",
            "district": "36",
            "Block": "113",
            "label": "ANMMS MILAGAIKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8513",
            "district": "36",
            "Block": "113",
            "label": "PUMS MELKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8514",
            "district": "36",
            "Block": "113",
            "label": "MDMS MELPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8515",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS VALLIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8516",
            "district": "36",
            "Block": "113",
            "label": "PUMS PERUMALKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8517",
            "district": "36",
            "Block": "113",
            "label": "GHS THATHIREDDIPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8518",
            "district": "36",
            "Block": "113",
            "label": "PUMS MUTHARASIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8519",
            "district": "36",
            "Block": "113",
            "label": "PUMS RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8520",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS VENNAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8521",
            "district": "36",
            "Block": "113",
            "label": "KRISHNA MATRIC HSS THENPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8757",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS JANGALAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8758",
            "district": "36",
            "Block": "113",
            "label": "PUMS  Jabrapet",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8759",
            "district": "36",
            "Block": "113",
            "label": "AHS ST.XAVIER'S CHRISTIANPET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8760",
            "district": "36",
            "Block": "113",
            "label": "PUMS Katpadi North",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8761",
            "district": "36",
            "Block": "113",
            "label": "PUMS Katpadi South",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8762",
            "district": "36",
            "Block": "113",
            "label": "GGHSS KATPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8763",
            "district": "36",
            "Block": "113",
            "label": "KALVI ULAGAM SIVA HS KILITHANPATTARAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8764",
            "district": "36",
            "Block": "113",
            "label": "Ams Ebenezar",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8765",
            "district": "36",
            "Block": "113",
            "label": "GBHSS KATPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8766",
            "district": "36",
            "Block": "113",
            "label": "PUMS Aruppumedu",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8767",
            "district": "36",
            "Block": "113",
            "label": "PUMS Kalinjur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8768",
            "district": "36",
            "Block": "113",
            "label": "PUMS Kalinjur Mottur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8769",
            "district": "36",
            "Block": "113",
            "label": "JAYABHARATH MATRIC SCHOOL, KALINJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8770",
            "district": "36",
            "Block": "113",
            "label": "BHAGWAN MAHAVEER DAYANIKETAN JAIN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8771",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS VANJUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8772",
            "district": "36",
            "Block": "113",
            "label": "PUMS T.K.Puram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8773",
            "district": "36",
            "Block": "113",
            "label": "Ams Union mission viruthambut",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8774",
            "district": "36",
            "Block": "113",
            "label": "TMKV GGHSS Kangeyanallur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8775",
            "district": "36",
            "Block": "113",
            "label": "TMKV GOVT BOYS HSS KANGEYANALLORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8776",
            "district": "36",
            "Block": "113",
            "label": "PUMS Ashok Nagar",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8777",
            "district": "36",
            "Block": "113",
            "label": "PUMS Old Katpadi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8778",
            "district": "36",
            "Block": "113",
            "label": "PUMS Pallikuppam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8779",
            "district": "36",
            "Block": "113",
            "label": "AUXILIUM HSS GANDHINAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8780",
            "district": "36",
            "Block": "113",
            "label": "DONBOSCO HSS GANDHINAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8781",
            "district": "36",
            "Block": "113",
            "label": "DEVALOIS HSS KASAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8782",
            "district": "36",
            "Block": "113",
            "label": "IDA SCUDDER SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8783",
            "district": "36",
            "Block": "113",
            "label": "KAVITHA MATRIC SCHOOL, KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8784",
            "district": "36",
            "Block": "113",
            "label": "GOVT HSS BRAMMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8785",
            "district": "36",
            "Block": "113",
            "label": "KEERTHIGA MATRIC HSS, THIRUPAKKUTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8786",
            "district": "36",
            "Block": "113",
            "label": "GHS Karikiri",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8787",
            "district": "36",
            "Block": "113",
            "label": "GHSS SERKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8788",
            "district": "36",
            "Block": "113",
            "label": "PUMS 66 Puthur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8789",
            "district": "36",
            "Block": "113",
            "label": "PUMS Sevoor",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8790",
            "district": "36",
            "Block": "113",
            "label": "GOVT HR.SEC. SCHOOL, KARNAMBUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8791",
            "district": "36",
            "Block": "113",
            "label": "GGHSS THIRUVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8792",
            "district": "36",
            "Block": "113",
            "label": "GBHSS THIRUVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21773",
            "district": "36",
            "Block": "113",
            "label": "WISDOM MATRIC HSS, RAGHUPATHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21774",
            "district": "36",
            "Block": "113",
            "label": "KINGSTON MATRIC HSS, KATPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21775",
            "district": "36",
            "Block": "113",
            "label": "VANI VIDHAYALAYA  SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22063",
            "district": "36",
            "Block": "113",
            "label": "KINGSTON INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22380",
            "district": "36",
            "Block": "113",
            "label": "SRI SRINIVASA VIDHYASHARAM CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22824",
            "district": "36",
            "Block": "113",
            "label": "PINCUSHION MONTESSORI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22835",
            "district": "36",
            "Block": "113",
            "label": "JEYAMALLI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23200",
            "district": "36",
            "Block": "113",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23314",
            "district": "36",
            "Block": "113",
            "label": "FIITJEE GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23632",
            "district": "36",
            "Block": "113",
            "label": "PRINCE PARK MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23633",
            "district": "36",
            "Block": "113",
            "label": "SHRI PONMATHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23632",
            "district": "36",
            "Block": "113",
            "label": "PRINCE PARK MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23633",
            "district": "36",
            "Block": "113",
            "label": "SHRI PONMATHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23816",
            "district": "36",
            "Block": "113",
            "label": "PUMS Sevoor",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23817",
            "district": "36",
            "Block": "113",
            "label": "KV Special Schools",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23818",
            "district": "36",
            "Block": "113",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "114": [
        {
            "key": "4223",
            "district": "2",
            "Block": "114",
            "label": "SRI SANKARA VIDYALAYA MAT HSS, URAPAKKAM-603210",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4631",
            "district": "2",
            "Block": "114",
            "label": "ST ANN'S MHSS, MELAMAIYUR-603002",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4754",
            "district": "2",
            "Block": "114",
            "label": "RKN GYAN JOTHI PUBLIC  SCHOOL, ANUMANTHA PUTHERI.-603002.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4756",
            "district": "2",
            "Block": "114",
            "label": "Neelan Matric Hr Sec School Guduvancheri-603202",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4791",
            "district": "2",
            "Block": "114",
            "label": "JRK MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4852",
            "district": "2",
            "Block": "114",
            "label": "ST JOHN'S MATRIC. HR.SEC.SCHOOL, NANDHIVARAM, GUDUVANCHERI-603202",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5267",
            "district": "2",
            "Block": "114",
            "label": "SRI VISHWA VIDYALAYA  MHSS, Vandalur-600048",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5289",
            "district": "2",
            "Block": "114",
            "label": "SRI. K.CHUNNILAL JAIN VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5298",
            "district": "2",
            "Block": "114",
            "label": "R K M VID MHSS,Chengalpattu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5329",
            "district": "2",
            "Block": "114",
            "label": "ST JOSEPH MAT HSS CHENGELPUT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5348",
            "district": "2",
            "Block": "114",
            "label": "ST.ANNE'S NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5388",
            "district": "2",
            "Block": "114",
            "label": "BRINDAVAN PUBLIC SCHOOL, ATHUR-603101, CHENGALPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5399",
            "district": "2",
            "Block": "114",
            "label": "EMMANUEL MATRICULATION SCHOOL,URAPPAKKAM-603211",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5484",
            "district": "2",
            "Block": "114",
            "label": "Sivananda Saraswathi  MHSS,Kattankulathur-603203",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5703",
            "district": "2",
            "Block": "114",
            "label": "SHRI NATESAN VIDYASALA MATRICULATION HIGHER SECONDARY SCHOOL, MANNIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5992",
            "district": "2",
            "Block": "114",
            "label": "SHALOM MAT HSS, VANDALUR-48",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6081",
            "district": "2",
            "Block": "114",
            "label": "TMS VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6125",
            "district": "2",
            "Block": "114",
            "label": "ANANTHAVALLI MAT HSS, URAPAKKAM-603210",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6144",
            "district": "2",
            "Block": "114",
            "label": "LITTLE JACKY MAT HSS CHENGALPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6335",
            "district": "2",
            "Block": "114",
            "label": "Annai Venankani NPS Nedukundram",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6657",
            "district": "2",
            "Block": "114",
            "label": "Mahindra World School Veerapuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6666",
            "district": "2",
            "Block": "114",
            "label": "ST. MARY'S MATRICULATION HIGHER SECONDARY SCHOOL, CHENGALPATTU-603002",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6725",
            "district": "2",
            "Block": "114",
            "label": "PUMS, UNAMANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7886",
            "district": "2",
            "Block": "114",
            "label": "GHS, Mannivakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7887",
            "district": "2",
            "Block": "114",
            "label": "Deva Annai Mat HS Kilambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7888",
            "district": "2",
            "Block": "114",
            "label": "ADWHSS, KILAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7889",
            "district": "2",
            "Block": "114",
            "label": "GHS, Karanai Puducheri",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7890",
            "district": "2",
            "Block": "114",
            "label": "PUMS, ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7891",
            "district": "2",
            "Block": "114",
            "label": "GHS, KOLAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7892",
            "district": "2",
            "Block": "114",
            "label": "PUMS, PERUMATTUNALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7893",
            "district": "2",
            "Block": "114",
            "label": "GHS ASTHINAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7894",
            "district": "2",
            "Block": "114",
            "label": "Government High School Kumizhi.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7895",
            "district": "2",
            "Block": "114",
            "label": "PUMS, KEERAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7896",
            "district": "2",
            "Block": "114",
            "label": "PUMS, NALLAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7897",
            "district": "2",
            "Block": "114",
            "label": "GHS, KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7898",
            "district": "2",
            "Block": "114",
            "label": "GHS, RATHINAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7899",
            "district": "2",
            "Block": "114",
            "label": "PUMS, MELKALVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7900",
            "district": "2",
            "Block": "114",
            "label": "PUMS, KALVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7901",
            "district": "2",
            "Block": "114",
            "label": "GHS, KAYARAMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7902",
            "district": "2",
            "Block": "114",
            "label": "PUMS, APPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7903",
            "district": "2",
            "Block": "114",
            "label": "PUMS, 81 VENBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7904",
            "district": "2",
            "Block": "114",
            "label": "PUMS, GURUVANMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7905",
            "district": "2",
            "Block": "114",
            "label": "PUMS, REDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7906",
            "district": "2",
            "Block": "114",
            "label": "PUMS, KONGANANCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7907",
            "district": "2",
            "Block": "114",
            "label": "ADWHSS Palur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7908",
            "district": "2",
            "Block": "114",
            "label": "LittleFlower High School,Karumbakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7909",
            "district": "2",
            "Block": "114",
            "label": "St. Paul's MHSS, Thimmavaram-603101",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7910",
            "district": "2",
            "Block": "114",
            "label": "PUMS, MELACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7911",
            "district": "2",
            "Block": "114",
            "label": "PUMS, VILLIYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7912",
            "district": "2",
            "Block": "114",
            "label": "PUMS, ATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7913",
            "district": "2",
            "Block": "114",
            "label": "Bakthavatchalam (Aided) HS, Athur",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7914",
            "district": "2",
            "Block": "114",
            "label": "BRINDAVAN MHSS, Athur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7915",
            "district": "2",
            "Block": "114",
            "label": "RLT ACADEMY ATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7916",
            "district": "2",
            "Block": "114",
            "label": "PUMS, CHETTIPUNIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7917",
            "district": "2",
            "Block": "114",
            "label": "Gnanodaya Unaided HS, Venkatapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7918",
            "district": "2",
            "Block": "114",
            "label": "PUMS, 73 KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7919",
            "district": "2",
            "Block": "114",
            "label": "GHS, KARUNILAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7920",
            "district": "2",
            "Block": "114",
            "label": "PUMS THENMELPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7921",
            "district": "2",
            "Block": "114",
            "label": "PUMS KONDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7922",
            "district": "2",
            "Block": "114",
            "label": "GHSS ANJUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7923",
            "district": "2",
            "Block": "114",
            "label": "PUMS SENNERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7924",
            "district": "2",
            "Block": "114",
            "label": "PUMS PATTARAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7925",
            "district": "2",
            "Block": "114",
            "label": "PUMS PARANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7926",
            "district": "2",
            "Block": "114",
            "label": "ADWMS, OZHALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7927",
            "district": "2",
            "Block": "114",
            "label": "PUMS 230 VALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7928",
            "district": "2",
            "Block": "114",
            "label": "St.Mary's MHSS, Nandhivaram, Guduvancherri-603202",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7929",
            "district": "2",
            "Block": "114",
            "label": "ADWMS, NANDHIVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7930",
            "district": "2",
            "Block": "114",
            "label": "GBHSS, NANDHIVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7931",
            "district": "2",
            "Block": "114",
            "label": "GGHSS, NANDHIVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7932",
            "district": "2",
            "Block": "114",
            "label": "BHARATHIYAR MHSS, GUDUVANCHERY-603202",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7933",
            "district": "2",
            "Block": "114",
            "label": "Bharathiyar MS Guduvancheri",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7934",
            "district": "2",
            "Block": "114",
            "label": "PUMS GUDUVANCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7935",
            "district": "2",
            "Block": "114",
            "label": "VIDYA MANDIR @ ESTANCIA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7936",
            "district": "2",
            "Block": "114",
            "label": "PUMS POTHERI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7937",
            "district": "2",
            "Block": "114",
            "label": "PUMS NINNAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7938",
            "district": "2",
            "Block": "114",
            "label": "ST.JOSEPH'S MATRIC HR.SEC.SCHOOL, MARAIMALAI NAGAR-603209",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7939",
            "district": "2",
            "Block": "114",
            "label": "GHSS MM NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7940",
            "district": "2",
            "Block": "114",
            "label": "PUMS GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7941",
            "district": "2",
            "Block": "114",
            "label": "PUMS KALIVANTHAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7942",
            "district": "2",
            "Block": "114",
            "label": "RSK MS GOKULAPURAM 603204",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7943",
            "district": "2",
            "Block": "114",
            "label": "CSI (Aided) HSS  Melrosapuram",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7944",
            "district": "2",
            "Block": "114",
            "label": "GHS, PERAMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7945",
            "district": "2",
            "Block": "114",
            "label": "DR.MANGALAM(AIDED) HIGH SCHOOL, KATTANKOLATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7946",
            "district": "2",
            "Block": "114",
            "label": "GHS, THIRUKATCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7947",
            "district": "2",
            "Block": "114",
            "label": "GOVT HIGH SCHOOL KATTANKOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7949",
            "district": "2",
            "Block": "114",
            "label": "Arignar Anna Municipal Boys Higher Secondary School Chengalpattu",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7950",
            "district": "2",
            "Block": "114",
            "label": "St.Mary's Girls' Hr.Sec.School,Chengalpattu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7951",
            "district": "2",
            "Block": "114",
            "label": "SEVENTH DAY ADVENTIST MHSS, CHENGALPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7952",
            "district": "2",
            "Block": "114",
            "label": "St.Columbas HSS, Chengalpattu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7953",
            "district": "2",
            "Block": "114",
            "label": "MUNICIPAL MIDDLE SCHOOL, VADAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7954",
            "district": "2",
            "Block": "114",
            "label": "CSI Alison Cassie (Aided) HSS, Chengalpattu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7955",
            "district": "2",
            "Block": "114",
            "label": "Ramakrishna (Aided) HSS(B), VNNagar",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7956",
            "district": "2",
            "Block": "114",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, CHENGALPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7957",
            "district": "2",
            "Block": "114",
            "label": "S.R.K.M.V.GIRLS.HR.SEC.SCHOOL,CPT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7958",
            "district": "2",
            "Block": "114",
            "label": "TELC CORNATION AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7959",
            "district": "2",
            "Block": "114",
            "label": "ST.JOSEPH AIDED HSS,CHENGALPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7960",
            "district": "2",
            "Block": "114",
            "label": "CSI ST. HILDAS & ST. HUGHS Chengalpattu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7961",
            "district": "2",
            "Block": "114",
            "label": "Prasan Vidya  Bala Mandir MHSS, Chengalpet-603001",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7962",
            "district": "2",
            "Block": "114",
            "label": "Jeevam Ganoday (Deaf)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7963",
            "district": "2",
            "Block": "114",
            "label": "PUMS SINGAPERUMAL KOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7964",
            "district": "2",
            "Block": "114",
            "label": "PUMS THIRUTHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7965",
            "district": "2",
            "Block": "114",
            "label": "Iseral Mat.HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7966",
            "district": "2",
            "Block": "114",
            "label": "ECIST MS Singaperumalkoil",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7967",
            "district": "2",
            "Block": "114",
            "label": "GHSS SINGAPERUMAL KOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7968",
            "district": "2",
            "Block": "114",
            "label": "VIDHYA SAGAR GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7969",
            "district": "2",
            "Block": "114",
            "label": "GHSS, Otteri Extension",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7970",
            "district": "2",
            "Block": "114",
            "label": "CRESCENT RESIDENTIAL MATRIC HR SEC SCHOOL, VANDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7971",
            "district": "2",
            "Block": "114",
            "label": "WILLYS INTEGRATED HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7972",
            "district": "2",
            "Block": "114",
            "label": "SARASWATHI MHSS, Urapakkam-603210",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20893",
            "district": "2",
            "Block": "114",
            "label": "St Pauls Academy, Chengalpattu",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20899",
            "district": "2",
            "Block": "114",
            "label": "Guardian Int. School, Melamaiyur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20942",
            "district": "2",
            "Block": "114",
            "label": "Srimathi Sundaravalli Memorial School, Kolapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20943",
            "district": "2",
            "Block": "114",
            "label": "Fatima Matric School, Chettipunniyam-603204",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21050",
            "district": "2",
            "Block": "114",
            "label": "SCAD World School, Pazhaveli",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21237",
            "district": "2",
            "Block": "114",
            "label": "GKM VIDYASHRAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21729",
            "district": "2",
            "Block": "114",
            "label": "Lightworkers Academy",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21730",
            "district": "2",
            "Block": "114",
            "label": "ST.MICHAEL'S NURSERY & PRIMARY SCHHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21731",
            "district": "2",
            "Block": "114",
            "label": "Sivananda Rajaram Senior Secondary School Maraimaai Nagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22031",
            "district": "2",
            "Block": "114",
            "label": "Velammal Vidyashram, Perumattunallur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23423",
            "district": "2",
            "Block": "114",
            "label": "SRI T.S. BALIAH MEMORIAL MATRICULATION HS",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "22032",
            "district": "2",
            "Block": "114",
            "label": "Maharishi Vidya Mandir, Echankaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22033",
            "district": "2",
            "Block": "114",
            "label": "JRK Global School, Kattankolathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22034",
            "district": "2",
            "Block": "114",
            "label": "Velammal Vidhyashram (CBSE), Kalivanthapattu",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22353",
            "district": "2",
            "Block": "114",
            "label": "Sri Krish International School, Rathinamangalam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22354",
            "district": "2",
            "Block": "114",
            "label": "Christwood High School, Venkadamangalam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22355",
            "district": "2",
            "Block": "114",
            "label": "SRM Public School, Nandhivaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22356",
            "district": "2",
            "Block": "114",
            "label": "Loknayak Jayaprakash Narayan Vidyalaya, Guduvancheri",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22709",
            "district": "2",
            "Block": "114",
            "label": "ZION INTERNATIONAL PUBLIC SCHOOL, NEDUNGUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23063",
            "district": "2",
            "Block": "114",
            "label": "ELA GREEN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23064",
            "district": "2",
            "Block": "114",
            "label": "SDA HIGHER SCONDARY SCHOOL,CHENGALPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23105",
            "district": "2",
            "Block": "114",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23159",
            "district": "2",
            "Block": "114",
            "label": "VIKAS MANTRA PUBLIC SCHOOL ANJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23277",
            "district": "2",
            "Block": "114",
            "label": "QUEEN MARYS ACADEMY MATRICULATION SCHOOL PORUNTHAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23423",
            "district": "2",
            "Block": "114",
            "label": "SRI T.S. BALIAH MEMORIAL MATRICULATION HS",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23431",
            "district": "2",
            "Block": "114",
            "label": "VELAMMAL NEWGEN SCHOOL NEDUNGUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23432",
            "district": "2",
            "Block": "114",
            "label": "VIDYA MANDIR SENIOR SECONDARY SCHOOL ESTANCIA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23423",
            "district": "2",
            "Block": "114",
            "label": "SRI T.S. BALIAH MEMORIAL MATRICULATION HS",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23431",
            "district": "2",
            "Block": "114",
            "label": "VELAMMAL NEWGEN SCHOOL NEDUNGUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23432",
            "district": "2",
            "Block": "114",
            "label": "VIDYA MANDIR SENIOR SECONDARY SCHOOL ESTANCIA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23423",
            "district": "2",
            "Block": "114",
            "label": "SRI T.S. BALIAH MEMORIAL MATRICULATION HS",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23431",
            "district": "2",
            "Block": "114",
            "label": "VELAMMAL NEWGEN SCHOOL NEDUNGUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23432",
            "district": "2",
            "Block": "114",
            "label": "VIDYA MANDIR SENIOR SECONDARY SCHOOL ESTANCIA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23423",
            "district": "2",
            "Block": "114",
            "label": "SRI T.S. BALIAH MEMORIAL MATRICULATION HS",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23431",
            "district": "2",
            "Block": "114",
            "label": "VELAMMAL NEWGEN SCHOOL NEDUNGUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23432",
            "district": "2",
            "Block": "114",
            "label": "VIDYA MANDIR SENIOR SECONDARY SCHOOL ESTANCIA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23819",
            "district": "2",
            "Block": "114",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23820",
            "district": "2",
            "Block": "114",
            "label": "CRESCENT SCHOOL, VANDALUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23821",
            "district": "2",
            "Block": "114",
            "label": "Chengalpattu District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "115": [
        {
            "key": "3038",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  NACHIYARPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4076",
            "district": "5",
            "Block": "115",
            "label": "PUMS SRI ADIVARAGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4148",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ANANTHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4441",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SATHAVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4641",
            "district": "5",
            "Block": "115",
            "label": "PUMS UDAIYARGUDI MIXED",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4766",
            "district": "5",
            "Block": "115",
            "label": "AMS VEERANANDAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4883",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EYYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5012",
            "district": "5",
            "Block": "115",
            "label": "VIVEKANANDAN AIDED MIDDLE SCHOOL, MELAKADAMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5262",
            "district": "5",
            "Block": "115",
            "label": "PUMS THIRUCHENNAPURM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5447",
            "district": "5",
            "Block": "115",
            "label": "PUMS AMBUJAVALLIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5505",
            "district": "5",
            "Block": "115",
            "label": "AMS KANDAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5620",
            "district": "5",
            "Block": "115",
            "label": "PUMS M.ADHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5900",
            "district": "5",
            "Block": "115",
            "label": "PUMS SHANDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5947",
            "district": "5",
            "Block": "115",
            "label": "SARASWATHI AIDED MIDDLE SCHOOL, GUNAMANGALAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6631",
            "district": "5",
            "Block": "115",
            "label": "ARUNACHALAM HINDU AIDED MIDDLE SCHOOL, KANATTAMPULIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15625",
            "district": "5",
            "Block": "115",
            "label": "ST.ANTHONY'S HS , ARANTHANGI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15626",
            "district": "5",
            "Block": "115",
            "label": "GHS KOZHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15627",
            "district": "5",
            "Block": "115",
            "label": "GHSS MAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15628",
            "district": "5",
            "Block": "115",
            "label": "RAJIV GANDHI NATIONAL  MAT HR SEC SCHOOL , NATTARMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15629",
            "district": "5",
            "Block": "115",
            "label": "RAJIV GANDHI NATIONAL HIGH SCHOOL, NATTARMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15630",
            "district": "5",
            "Block": "115",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELPALANCHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15631",
            "district": "5",
            "Block": "115",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  SRINEDUNCHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15632",
            "district": "5",
            "Block": "115",
            "label": "AIDED MUSLIM HIGH SCHOOL , AYANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15633",
            "district": "5",
            "Block": "115",
            "label": "GHSS.KANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15634",
            "district": "5",
            "Block": "115",
            "label": "GHSS , KANJANKOLLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15635",
            "district": "5",
            "Block": "115",
            "label": "AMS KALLAIMAGAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15636",
            "district": "5",
            "Block": "115",
            "label": "GHSS(BOYS) , KATTUMANNARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15637",
            "district": "5",
            "Block": "115",
            "label": "P R G HR SEC SCHOOL, KATTUMANNARKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15638",
            "district": "5",
            "Block": "115",
            "label": "GK MAT HSS , KATTUMANNARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15639",
            "district": "5",
            "Block": "115",
            "label": "AIDED HIGH SCHOOL (RC) , UDAIYARKUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15640",
            "district": "5",
            "Block": "115",
            "label": "KALAIMAGAL MATRIC HIGHER SECONDARY  SCHOOL  KATTUMANNARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15641",
            "district": "5",
            "Block": "115",
            "label": "GHSS(GIRLS) , KATTUMANNARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15642",
            "district": "5",
            "Block": "115",
            "label": "MUSLIM HIGH SCH., KOLLUMEDU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15643",
            "district": "5",
            "Block": "115",
            "label": "GHS OMAMPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15644",
            "district": "5",
            "Block": "115",
            "label": "GHS GUNAVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15645",
            "district": "5",
            "Block": "115",
            "label": "GHS , KARUPPERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15646",
            "district": "5",
            "Block": "115",
            "label": "GOVERNMENT HIGH SCHOOL, KURUNGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15647",
            "district": "5",
            "Block": "115",
            "label": "GHS , MANIYAMADOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15648",
            "district": "5",
            "Block": "115",
            "label": "GHS , MOVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15649",
            "district": "5",
            "Block": "115",
            "label": "GHSS , MUTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15650",
            "district": "5",
            "Block": "115",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, REDDIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15651",
            "district": "5",
            "Block": "115",
            "label": "DVC HSS , SRIMUSHNAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15652",
            "district": "5",
            "Block": "115",
            "label": "DHAVA AMUTHAM MHSS, SRIMUSHNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15653",
            "district": "5",
            "Block": "115",
            "label": "GHSS THIRUMUTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21100",
            "district": "5",
            "Block": "115",
            "label": "SBG CBSE VIDYALAYA-THENPATHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21560",
            "district": "5",
            "Block": "115",
            "label": "ARUNMOZHY MATRIC  HIGHER SECONDARY SCHOOL-VEERANANDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22216",
            "district": "5",
            "Block": "115",
            "label": "G.K. PUBLIC SENIOR  SECONDARY SCHOOL, KATTUMANNARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22561",
            "district": "5",
            "Block": "115",
            "label": "GHS-VANAMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22562",
            "district": "5",
            "Block": "115",
            "label": "CS JAIN  MATRICULATION SCHOOL-THIRUMUTTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22940",
            "district": "5",
            "Block": "115",
            "label": "GHS-NATTARMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23053",
            "district": "5",
            "Block": "115",
            "label": "AKSHARA VIDYA MANDIR, CBSE SCHOOL, VILVAKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23172",
            "district": "5",
            "Block": "115",
            "label": "THIRUGNANAM INTERNATIONAL SCHOOL,MELPULIYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23179",
            "district": "5",
            "Block": "115",
            "label": "SBG VIDHYALAYA MATRICULATION SCHOOL, THENPATHY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23822",
            "district": "5",
            "Block": "115",
            "label": "DVC MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "116": [
        {
            "key": "1266",
            "district": "21",
            "Block": "116",
            "label": "PUMS-PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2722",
            "district": "21",
            "Block": "116",
            "label": "PUMS-KUPPAKKALMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3160",
            "district": "21",
            "Block": "116",
            "label": "PUMS-UPPARANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3730",
            "district": "21",
            "Block": "116",
            "label": "BHARATHIDHASANAR MATRIC SCHOOL, KAVERIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4538",
            "district": "21",
            "Block": "116",
            "label": "PUMS-SIRUVALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5237",
            "district": "21",
            "Block": "116",
            "label": "SARASWATHI MATRIC HSS, KAVERIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6498",
            "district": "21",
            "Block": "116",
            "label": "PUMS-MAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8429",
            "district": "21",
            "Block": "116",
            "label": "PUMS, AVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8430",
            "district": "21",
            "Block": "116",
            "label": "RTM HS, KALATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8431",
            "district": "21",
            "Block": "116",
            "label": "PUMS, SANKARANPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8432",
            "district": "21",
            "Block": "116",
            "label": "GHS PERUMPULIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8489",
            "district": "21",
            "Block": "116",
            "label": "GOVT BOYS HSS BANAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8490",
            "district": "21",
            "Block": "116",
            "label": "GOVT GIRLS HSS BANAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8491",
            "district": "21",
            "Block": "116",
            "label": "GOVT HS VEDANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8492",
            "district": "21",
            "Block": "116",
            "label": "GOVT HSS PUDHUPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8493",
            "district": "21",
            "Block": "116",
            "label": "RCM MS-ALAPAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8494",
            "district": "21",
            "Block": "116",
            "label": "GOVT HS CHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8495",
            "district": "21",
            "Block": "116",
            "label": "GOVT BOYS HSS KAVERIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8496",
            "district": "21",
            "Block": "116",
            "label": "GOVT GIRLS HSS KAVERIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8497",
            "district": "21",
            "Block": "116",
            "label": "GOVT HS AYARPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8498",
            "district": "21",
            "Block": "116",
            "label": "GOVT HSS SIRUKARUMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23823",
            "district": "21",
            "Block": "116",
            "label": "BHARATHIDHASANAR PUBLIC SCHOOL, PANAPAKKAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "117": [
        {
            "key": "1804",
            "district": "13",
            "Block": "117",
            "label": "NATIONAL MS VARATTAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2975",
            "district": "13",
            "Block": "117",
            "label": "SRI RAJARAJESWARI VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3674",
            "district": "13",
            "Block": "117",
            "label": "GOPI KRISHNA MATRICULATION HR.SEC SCHOOL DASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4739",
            "district": "13",
            "Block": "117",
            "label": "ROYAL MATRIC HR SEC SCHOOL, ARS NAGAR,KAVERIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6117",
            "district": "13",
            "Block": "117",
            "label": "GREEN VALLEY  INTERNATIONAL MATRIC HIGHER SECONDARY SCHOOL - PANNANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6471",
            "district": "13",
            "Block": "117",
            "label": "THIRU ARUT PRAKASHA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20525",
            "district": "13",
            "Block": "117",
            "label": "GHSS AGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20526",
            "district": "13",
            "Block": "117",
            "label": "MUTHAMIZH GHSS ARASAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20527",
            "district": "13",
            "Block": "117",
            "label": "GOVT.GIRLS HS, ARASAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20528",
            "district": "13",
            "Block": "117",
            "label": "SRI RAMAKRISHNA MATRIC HSS ARASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20529",
            "district": "13",
            "Block": "117",
            "label": "GHS BALEGULI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20530",
            "district": "13",
            "Block": "117",
            "label": "GHS BANNIHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20531",
            "district": "13",
            "Block": "117",
            "label": "GHS BANNIHALLI PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20532",
            "district": "13",
            "Block": "117",
            "label": "GHSS BARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20533",
            "district": "13",
            "Block": "117",
            "label": "PUMS BENDARHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20534",
            "district": "13",
            "Block": "117",
            "label": "GHS CHETTIMARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20535",
            "district": "13",
            "Block": "117",
            "label": "PUMS SATHINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20536",
            "district": "13",
            "Block": "117",
            "label": "GHSS-GIRLS  KAVERIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20537",
            "district": "13",
            "Block": "117",
            "label": "PUMS K.MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20538",
            "district": "13",
            "Block": "117",
            "label": "PUMS KAVERIPATTINAM URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20539",
            "district": "13",
            "Block": "117",
            "label": "GHSS-BOYS  KAVERIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20540",
            "district": "13",
            "Block": "117",
            "label": "PUMS KEELKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20541",
            "district": "13",
            "Block": "117",
            "label": "GHS KOORAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20542",
            "district": "13",
            "Block": "117",
            "label": "PUMS MANI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20543",
            "district": "13",
            "Block": "117",
            "label": "PUMS MARICHETTIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20544",
            "district": "13",
            "Block": "117",
            "label": "ASHOK MISSION MATRIC.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20545",
            "district": "13",
            "Block": "117",
            "label": "PUMS MITTAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20546",
            "district": "13",
            "Block": "117",
            "label": "GHS KADHIRIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20547",
            "district": "13",
            "Block": "117",
            "label": "GHSS MORANAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20548",
            "district": "13",
            "Block": "117",
            "label": "PUMS N.THATTAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20549",
            "district": "13",
            "Block": "117",
            "label": "GHSS NADUPAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20550",
            "district": "13",
            "Block": "117",
            "label": "NATIONAL MATRICULATION SCHOOL - PAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20551",
            "district": "13",
            "Block": "117",
            "label": "GHSS-GIRLS  NAGARASAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20552",
            "district": "13",
            "Block": "117",
            "label": "PR GHSS-BOYS, NAGARASAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20553",
            "district": "13",
            "Block": "117",
            "label": "PUMS NARIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20554",
            "district": "13",
            "Block": "117",
            "label": "GHS NATTANMAIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20555",
            "district": "13",
            "Block": "117",
            "label": "GHSS NEDUNGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20556",
            "district": "13",
            "Block": "117",
            "label": "PBA GHS-GIRLS  PANNANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20557",
            "district": "13",
            "Block": "117",
            "label": "PDB GHSS PANNANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20558",
            "district": "13",
            "Block": "117",
            "label": "PUMS PENNESWARAMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20559",
            "district": "13",
            "Block": "117",
            "label": "GHS PERIYAKARADIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20560",
            "district": "13",
            "Block": "117",
            "label": "PUMS POTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20561",
            "district": "13",
            "Block": "117",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20562",
            "district": "13",
            "Block": "117",
            "label": "GHSS RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20563",
            "district": "13",
            "Block": "117",
            "label": "CAMBRIDGE PUBLIC e-SCHOOL, KAVERIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20564",
            "district": "13",
            "Block": "117",
            "label": "PUMS SAVALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20565",
            "district": "13",
            "Block": "117",
            "label": "GHS SOBANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20566",
            "district": "13",
            "Block": "117",
            "label": "PUMS THERPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20567",
            "district": "13",
            "Block": "117",
            "label": "GHS THIMMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20568",
            "district": "13",
            "Block": "117",
            "label": "GHS THOPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20569",
            "district": "13",
            "Block": "117",
            "label": "GHS VADAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20570",
            "district": "13",
            "Block": "117",
            "label": "NATIONAL HSS VARATTAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20571",
            "district": "13",
            "Block": "117",
            "label": "PUMS VEERAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20572",
            "district": "13",
            "Block": "117",
            "label": "GHSS VELAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20573",
            "district": "13",
            "Block": "117",
            "label": "GHS VILANGAMUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20574",
            "district": "13",
            "Block": "117",
            "label": "PUMS BERUHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20575",
            "district": "13",
            "Block": "117",
            "label": "PUMS DEVARMUKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20576",
            "district": "13",
            "Block": "117",
            "label": "GHS GUNDALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20577",
            "district": "13",
            "Block": "117",
            "label": "GHS JAGADAP",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20578",
            "district": "13",
            "Block": "117",
            "label": "GHS KALVEHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20579",
            "district": "13",
            "Block": "117",
            "label": "GHS KARAGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20580",
            "district": "13",
            "Block": "117",
            "label": "GHS METTUPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20581",
            "district": "13",
            "Block": "117",
            "label": "PUMS THALIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21630",
            "district": "13",
            "Block": "117",
            "label": "SRI VINAYAGA VIDYALAYA MATRIC. SCHOOL, KALKUTTAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21803",
            "district": "13",
            "Block": "117",
            "label": "KS MILLENNIUM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22671",
            "district": "13",
            "Block": "117",
            "label": "GHS DEVEERAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22832",
            "district": "13",
            "Block": "117",
            "label": "RICH MAAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22834",
            "district": "13",
            "Block": "117",
            "label": "GOPI KRISHNA'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23824",
            "district": "13",
            "Block": "117",
            "label": "PUMS PULIYAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "118": [
        {
            "key": "268",
            "district": "28",
            "Block": "118",
            "label": "ACHARIYA SCHOOL, VANARAMUTTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "269",
            "district": "28",
            "Block": "118",
            "label": "ADI DRAVIDAR AND TRIBAL W ELFARE HS, KARISALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "270",
            "district": "28",
            "Block": "118",
            "label": "GOVT ADW HIGH SCHOOL, THIRUMANGALAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "271",
            "district": "28",
            "Block": "118",
            "label": "BABA MATRICULATION HR.SEC. SCHOOL, KAYATHAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "272",
            "district": "28",
            "Block": "118",
            "label": "GHS, AYYANAROOTHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "273",
            "district": "28",
            "Block": "118",
            "label": "GHS, CHIDAMBARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "274",
            "district": "28",
            "Block": "118",
            "label": "GHSS, KALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "275",
            "district": "28",
            "Block": "118",
            "label": "GHS, NORTH ILANTHAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "276",
            "district": "28",
            "Block": "118",
            "label": "GHSS, KALUGUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "277",
            "district": "28",
            "Block": "118",
            "label": "GHSS, KAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "278",
            "district": "28",
            "Block": "118",
            "label": "GHSS, KAYATHAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "279",
            "district": "28",
            "Block": "118",
            "label": "GHSS, VANARAMUTTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "280",
            "district": "28",
            "Block": "118",
            "label": "HINDU NADAR KAMARAJ HS, SOLAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "281",
            "district": "28",
            "Block": "118",
            "label": "HINDU NADAR MS, CHETTIKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "282",
            "district": "28",
            "Block": "118",
            "label": "KARM GEORGE MS, KADAMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "283",
            "district": "28",
            "Block": "118",
            "label": "MAHA LAKSHMI MS, AKILANDAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "284",
            "district": "28",
            "Block": "118",
            "label": "HINDU NADAR HSS KADAMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "285",
            "district": "28",
            "Block": "118",
            "label": "MOTHER THERESA MAT., KAYATHAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "286",
            "district": "28",
            "Block": "118",
            "label": "PU MS, KAYATHAR SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "287",
            "district": "28",
            "Block": "118",
            "label": "PUMS, KOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "288",
            "district": "28",
            "Block": "118",
            "label": "PUMS, KUMARAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "289",
            "district": "28",
            "Block": "118",
            "label": "PUMS, K. VENKATESWARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "290",
            "district": "28",
            "Block": "118",
            "label": "PUMS, NAGALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "291",
            "district": "28",
            "Block": "118",
            "label": "PUMS PANIKKARKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "292",
            "district": "28",
            "Block": "118",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUNGAVARNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "293",
            "district": "28",
            "Block": "118",
            "label": "PUMS, VAGAITHAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "294",
            "district": "28",
            "Block": "118",
            "label": "PUMS, KARADIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "295",
            "district": "28",
            "Block": "118",
            "label": "KAMMAVAR GIRLS H.S.S.KALUGUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "296",
            "district": "28",
            "Block": "118",
            "label": "RC FATIMA HS, KAYATHAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "297",
            "district": "28",
            "Block": "118",
            "label": "RCMS, PANNIRKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "298",
            "district": "28",
            "Block": "118",
            "label": "RC MS, VELLALANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "299",
            "district": "28",
            "Block": "118",
            "label": "RC SUSAI HSS, KALUGUMALAI .",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "300",
            "district": "28",
            "Block": "118",
            "label": "SREE KRISHNA HS, KURUMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "301",
            "district": "28",
            "Block": "118",
            "label": "ST ALOYSIOUS HIGH SCHOOL, KAMANAYAKKANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "302",
            "district": "28",
            "Block": "118",
            "label": "ST LOUISA GIRLS HSS, KALUGUMALAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "303",
            "district": "28",
            "Block": "118",
            "label": "ST.MADONNA'S MATRIC HIGHER SECONDARY  SCHOOL, KALUGUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "304",
            "district": "28",
            "Block": "118",
            "label": "ST MARYS HIGH SCHOOL, THERKU KONAR KOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "305",
            "district": "28",
            "Block": "118",
            "label": "ST XAVIERS MS, VADAKKU VANDHANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "306",
            "district": "28",
            "Block": "118",
            "label": "TNDTA APPASAMY MS, RAJAPUDHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "307",
            "district": "28",
            "Block": "118",
            "label": "VIMAL MATRIC SCHOOL, KALUGUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "119": [
        {
            "key": "1411",
            "district": "24",
            "Block": "119",
            "label": "RAJA MS-KALUNEERKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1464",
            "district": "24",
            "Block": "119",
            "label": "ST ASSISI MATRIC HR.SEC.SCH. PAVOORCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1654",
            "district": "24",
            "Block": "119",
            "label": "JAWAHARLAL MS SURANDAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1704",
            "district": "24",
            "Block": "119",
            "label": "MARAVA MS-KALUNEERKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1857",
            "district": "24",
            "Block": "119",
            "label": "RC MS SURANDAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1925",
            "district": "24",
            "Block": "119",
            "label": "SRI JEYANDRA MATRIC HIGHER SECONDARY SCHOOL,  SURANDAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1957",
            "district": "24",
            "Block": "119",
            "label": "PUMS CHELLATHAYARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1963",
            "district": "24",
            "Block": "119",
            "label": "PUMS THIRAVIANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2195",
            "district": "24",
            "Block": "119",
            "label": "SHALOM MATRICULATION HIGHER SECONDARY SCHOOL, ,PAVOORCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2244",
            "district": "24",
            "Block": "119",
            "label": "A G MAT S SURANDAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2350",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS KURUMBALAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2439",
            "district": "24",
            "Block": "119",
            "label": "PUMS -ARIYAPPAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2696",
            "district": "24",
            "Block": "119",
            "label": "RATNA MS-E.MEENACHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2805",
            "district": "24",
            "Block": "119",
            "label": "SRI MUPPUDATHI AMMAN MAT HR.SEC.SC. ADAIKALAPATANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2916",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS-CHINNANADANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2973",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS PUTHUSURANDAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3151",
            "district": "24",
            "Block": "119",
            "label": "PUMS, VELLAKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3584",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS SIVAGAMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3587",
            "district": "24",
            "Block": "119",
            "label": "K.M.H.MS VINAITHEERTHANADARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3924",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS-RAJAGOBALAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3990",
            "district": "24",
            "Block": "119",
            "label": "PUMS-THUVARANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4163",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS SUBBANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5222",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS  MELAPATTAMUDAIARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5544",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS MELAMEIGNANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5636",
            "district": "24",
            "Block": "119",
            "label": "TDTA St.Paul's MS ADAIKALAPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5842",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS CHENNALTHAPUTHUKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5872",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS-SIVANADANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5979",
            "district": "24",
            "Block": "119",
            "label": "TDTA MS-PETHANADARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6672",
            "district": "24",
            "Block": "119",
            "label": "THIRU SUNDARAVEL Middle School-SOUTH POOLANGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19717",
            "district": "24",
            "Block": "119",
            "label": "GHSS -PULLUKATTUVALASAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19718",
            "district": "24",
            "Block": "119",
            "label": "GOVT HR SEC SCHOOL -VINAITHEERTHANADARPATTI - 627808",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19719",
            "district": "24",
            "Block": "119",
            "label": "PUNITHA ARULAPPAR HSS-AVUDAIYANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19720",
            "district": "24",
            "Block": "119",
            "label": "PAPN GHSS-AVUDAIYANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19721",
            "district": "24",
            "Block": "119",
            "label": "SIVA SAKTHI VIDYALAYA PODIANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19722",
            "district": "24",
            "Block": "119",
            "label": "GHSS AYANKURUMBALAPERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19723",
            "district": "24",
            "Block": "119",
            "label": "GHSS SIVAGURUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19724",
            "district": "24",
            "Block": "119",
            "label": "BAREN BRUCK HSS BUNGALOW SURANDAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19725",
            "district": "24",
            "Block": "119",
            "label": "SERMATHAIVASAN HSS SURANDAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19726",
            "district": "24",
            "Block": "119",
            "label": "GHSS MELAPAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19727",
            "district": "24",
            "Block": "119",
            "label": "AV HS KEELAPAVOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19728",
            "district": "24",
            "Block": "119",
            "label": "NADAR HINDU HS KEELAPAVOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19729",
            "district": "24",
            "Block": "119",
            "label": "GHS AYOTHIYAPURIPATTANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19730",
            "district": "24",
            "Block": "119",
            "label": "GHSS POOLANGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19731",
            "district": "24",
            "Block": "119",
            "label": "QUEENS N&PS POOLANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19732",
            "district": "24",
            "Block": "119",
            "label": "PUMS,GURUSAMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19733",
            "district": "24",
            "Block": "119",
            "label": "HINDU MS-MADATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19734",
            "district": "24",
            "Block": "119",
            "label": "TPS(B)GHSS PAVOORCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19735",
            "district": "24",
            "Block": "119",
            "label": "AVVAIYAR G.GHSS-PAVOORCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19736",
            "district": "24",
            "Block": "119",
            "label": "ANNAI VELANKANNI MATRIC HR.SEC.SCHOOL PAVOORCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19737",
            "district": "24",
            "Block": "119",
            "label": "MALAVIYA VIDYA KENDRAM PAVOORCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19738",
            "district": "24",
            "Block": "119",
            "label": "GHSS,VEERAKERALAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19739",
            "district": "24",
            "Block": "119",
            "label": "ANNA HSS-VEERAKERALAMPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19740",
            "district": "24",
            "Block": "119",
            "label": "ST.ANTONY HSS-VEERAKERALAMPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19741",
            "district": "24",
            "Block": "119",
            "label": "GOVT HIGH SCHOOL BOOBALASAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21290",
            "district": "24",
            "Block": "119",
            "label": "S.R MATRIC HIGHER SECONDARYSCHOOL SURANDAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21526",
            "district": "24",
            "Block": "119",
            "label": "HINDU HS MUTHUKRISHNAPERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21835",
            "district": "24",
            "Block": "119",
            "label": "HINDU HS VELLAIPANEIARYPATTY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22727",
            "district": "24",
            "Block": "119",
            "label": "SMA NATIONAL PUBLIC SCHOOL ADAAKKALAPATTANAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23113",
            "district": "24",
            "Block": "119",
            "label": "SR SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23171",
            "district": "24",
            "Block": "119",
            "label": "SIVA SAKTHI VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23195",
            "district": "24",
            "Block": "119",
            "label": "WONDER SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "120": [
        {
            "key": "2825",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THERKUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4014",
            "district": "5",
            "Block": "120",
            "label": "AIDED MIDDLE SCHOOL- POONTHOTTAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4112",
            "district": "5",
            "Block": "120",
            "label": "AIDED-VALLALAR AIDED MIDDLE SCHOOL- PUDHUPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4185",
            "district": "5",
            "Block": "120",
            "label": "AIDED-RASAMBAL ACHI MIDDLE SCHOOL, PONNAKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4236",
            "district": "5",
            "Block": "120",
            "label": "S.D.SION MATRICULATION HIGHER SECONDARY SCHOOL, BOOTHANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4402",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL- KALIYAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4580",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAVALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4783",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KILLIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4846",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4918",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEEZHANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5021",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL- C.MELAVANNIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5173",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MADURANTHAGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5177",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ODDAKANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5253",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALASAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5472",
            "district": "5",
            "Block": "120",
            "label": "AMS ENNANAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5536",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VADAHARIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5604",
            "district": "5",
            "Block": "120",
            "label": "AMS THANDESWARANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5863",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AYYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6315",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PARATHUR SAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6490",
            "district": "5",
            "Block": "120",
            "label": "SRI VIJAYA MAT. PALAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6534",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANTHAKUMARAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15564",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOODALAIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15565",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- KANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15566",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL-KEERAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15567",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- VILAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15568",
            "district": "5",
            "Block": "120",
            "label": "ADI-DRAVIDA WELFARE GOVERNMENT HIGHER SECONDARY SCHOOL- MATHURANTHAGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15569",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- MAZHAVARAYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15570",
            "district": "5",
            "Block": "120",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL- MUGAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15571",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, T.NEDUNCHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15572",
            "district": "5",
            "Block": "120",
            "label": "THIRUVALLUVAR HIGHER SECONDARY SCHOOL , ORATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15573",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- PALAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15574",
            "district": "5",
            "Block": "120",
            "label": "AIDED-ST.LOURDE'S MIDDLE SCHOOL- SILUVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15575",
            "district": "5",
            "Block": "120",
            "label": "KAMARAJ MATRIC HIGHER SECONDARY SPECIAL SCHOOL, VAYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15576",
            "district": "5",
            "Block": "120",
            "label": "AIDED MIDDLE SCHOOL- PANNAPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15577",
            "district": "5",
            "Block": "120",
            "label": "ADI-DRAVIDA WELFARE MIDDLE SCHOOL- PERUNGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15578",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15579",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- PUDAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15580",
            "district": "5",
            "Block": "120",
            "label": "AIDED-ST.MARY'S MIDDLE SCHOOL- VALISAPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15581",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- RAMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15582",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- SAKKANKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15583",
            "district": "5",
            "Block": "120",
            "label": "RBM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15584",
            "district": "5",
            "Block": "120",
            "label": "AMS GOVINDANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "15585",
            "district": "5",
            "Block": "120",
            "label": "AHS SOZHATHARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15586",
            "district": "5",
            "Block": "120",
            "label": "AIDED-SACRED HEART HIGHER SECONDARY SCHOOL- VADAKUPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15587",
            "district": "5",
            "Block": "120",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VAZHAIKKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15588",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- VEYYALORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20889",
            "district": "5",
            "Block": "120",
            "label": "ATHENA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21098",
            "district": "5",
            "Block": "120",
            "label": "SHRI SAI VIDHYASHARAM CBSE SCHOOL, KANNANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21982",
            "district": "5",
            "Block": "120",
            "label": "KAMARAJ INTERNATIONAL CBSE SCHOOL,VAYALUR.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22212",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- C.VEERASOLAGAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22560",
            "district": "5",
            "Block": "120",
            "label": "GOVERNMENT HIGH SCHOOL- K.ADOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23825",
            "district": "5",
            "Block": "120",
            "label": "KARUPASAMY VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "121": [
        {
            "key": "6332",
            "district": "16",
            "Block": "121",
            "label": "ANNAI VAILANKANNI MATRICULATION HIGH SCHOOL,VETTAIKARANIRUPPU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15729",
            "district": "16",
            "Block": "121",
            "label": "AMS-P.R.PURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15730",
            "district": "16",
            "Block": "121",
            "label": "GHSS-P.R.PURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15731",
            "district": "16",
            "Block": "121",
            "label": "GHS SERUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15732",
            "district": "16",
            "Block": "121",
            "label": "GHSS THIRUPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15733",
            "district": "16",
            "Block": "121",
            "label": "PUMS KARAIMETTUTHIDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15734",
            "district": "16",
            "Block": "121",
            "label": "PUNITHA FATHIMA MATRICULATION SCHOOL THIRUPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15735",
            "district": "16",
            "Block": "121",
            "label": "ST SEBASTIANS HR SEC SCHOOL KAMESWARAM  EAST",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15736",
            "district": "16",
            "Block": "121",
            "label": "PUMS VILUNTHAMAVADI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15737",
            "district": "16",
            "Block": "121",
            "label": "PUMS VIZHUNTHAMAVADI (T)EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15738",
            "district": "16",
            "Block": "121",
            "label": "GHSS-VILUNTHAMAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15739",
            "district": "16",
            "Block": "121",
            "label": "GHS KARAPPIDAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15740",
            "district": "16",
            "Block": "121",
            "label": "GHSS KEEZHAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15741",
            "district": "16",
            "Block": "121",
            "label": "PUMS VENMANACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15742",
            "district": "16",
            "Block": "121",
            "label": "PUMS THATHANTHIRUVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15743",
            "district": "16",
            "Block": "121",
            "label": "GHSS VETTAIKKARANIRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15744",
            "district": "16",
            "Block": "121",
            "label": "AMS-PUDUPALLI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15745",
            "district": "16",
            "Block": "121",
            "label": "GHSS PALAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15746",
            "district": "16",
            "Block": "121",
            "label": "AMS-THANNILAPPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15747",
            "district": "16",
            "Block": "121",
            "label": "GHS GRAMATHUMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15748",
            "district": "16",
            "Block": "121",
            "label": "OUR LADY OF HEALTH AIDED HIGHER SECONDARY SCHOOL, VELANKANNI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15749",
            "district": "16",
            "Block": "121",
            "label": "PUMS VAILANKANNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15750",
            "district": "16",
            "Block": "121",
            "label": "CHRIST THE KING SPECIAL HIGHER SECONDARY SCHOOL VAILANKANNI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15751",
            "district": "16",
            "Block": "121",
            "label": "CHRIST THE KING HS-VAILANKANNI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15752",
            "district": "16",
            "Block": "121",
            "label": "AMS-CHOLAVIDYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15753",
            "district": "16",
            "Block": "121",
            "label": "ST. ANTONY'S AIDED HIGH SCHOOL   KARUNKANNI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15754",
            "district": "16",
            "Block": "121",
            "label": "PUMS THIRUVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15755",
            "district": "16",
            "Block": "121",
            "label": "GHS ETTUKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15756",
            "district": "16",
            "Block": "121",
            "label": "AMS-KEELAVALAKKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15757",
            "district": "16",
            "Block": "121",
            "label": "AMGHSS THIRUKUVALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15758",
            "district": "16",
            "Block": "121",
            "label": "PUMS THIRUKKUVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21390",
            "district": "16",
            "Block": "121",
            "label": "GHS THIRUPOONDI NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21391",
            "district": "16",
            "Block": "121",
            "label": "GHS VILUNTHAMAVADI (E)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22864",
            "district": "16",
            "Block": "121",
            "label": "GOVERNMENT HIGH SCHOOL PERIYATHUMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "122": [
        {
            "key": "2748",
            "district": "13",
            "Block": "122",
            "label": "SRI SARADHA MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2778",
            "district": "13",
            "Block": "122",
            "label": "ST JOSEPHS MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2936",
            "district": "13",
            "Block": "122",
            "label": "HOLY CROSS MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3399",
            "district": "13",
            "Block": "122",
            "label": "PUMS HANUMANTHAPURAM TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3737",
            "district": "13",
            "Block": "122",
            "label": "PUMS EANIMUCHANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3974",
            "district": "13",
            "Block": "122",
            "label": "PUMS ANUSONAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4081",
            "district": "13",
            "Block": "122",
            "label": "SEVENTH DAY MATRIC SCHOOL MARASANDIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4087",
            "district": "13",
            "Block": "122",
            "label": "PUMS H.SETTIPALLI TAM&TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4096",
            "district": "13",
            "Block": "122",
            "label": "PUMS ECHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4139",
            "district": "13",
            "Block": "122",
            "label": "PUMS AGGONDAPALLI TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4140",
            "district": "13",
            "Block": "122",
            "label": "PUMS RAYAKOTTAI URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4274",
            "district": "13",
            "Block": "122",
            "label": "PUMS KANDAGANAPALLI TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4277",
            "district": "13",
            "Block": "122",
            "label": "PUMS KOTTAIULIMANGALAM TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4361",
            "district": "13",
            "Block": "122",
            "label": "PUMS KOWTHALAM TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4454",
            "district": "13",
            "Block": "122",
            "label": "PUMS ULLUKURUKKI URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4519",
            "district": "13",
            "Block": "122",
            "label": "PUMS POONAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4532",
            "district": "13",
            "Block": "122",
            "label": "PUMS GIRIYANAPALLI TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4545",
            "district": "13",
            "Block": "122",
            "label": "PUMS ZEEBI TAM / TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4777",
            "district": "13",
            "Block": "122",
            "label": "PUMS THOTTIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4914",
            "district": "13",
            "Block": "122",
            "label": "PUMS ODAYANDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4948",
            "district": "13",
            "Block": "122",
            "label": "PUMS GULLATTI TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4976",
            "district": "13",
            "Block": "122",
            "label": "PUMS VARAGANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5009",
            "district": "13",
            "Block": "122",
            "label": "PUMS GEDDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5095",
            "district": "13",
            "Block": "122",
            "label": "PUMS THEN DINNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5162",
            "district": "13",
            "Block": "122",
            "label": "PUMS NELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5283",
            "district": "13",
            "Block": "122",
            "label": "PUMS DENKANIKOTTA URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5365",
            "district": "13",
            "Block": "122",
            "label": "PUMS THOTTINAYAKANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5593",
            "district": "13",
            "Block": "122",
            "label": "PUMS J KARUPALLI TELUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5598",
            "district": "13",
            "Block": "122",
            "label": "PUMS KELAMANGALAM URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5718",
            "district": "13",
            "Block": "122",
            "label": "PUMS U.PURAM TAM/TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5930",
            "district": "13",
            "Block": "122",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5987",
            "district": "13",
            "Block": "122",
            "label": "VAILANKANNI MHSS DENKANIKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6185",
            "district": "13",
            "Block": "122",
            "label": "PUMS JAKKERI TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6383",
            "district": "13",
            "Block": "122",
            "label": "NEW GENERATION MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6443",
            "district": "13",
            "Block": "122",
            "label": "PUMS SOOLAKUNTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20767",
            "district": "13",
            "Block": "122",
            "label": "GHS ALASATTY TELUGU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20768",
            "district": "13",
            "Block": "122",
            "label": "GHS BAIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20769",
            "district": "13",
            "Block": "122",
            "label": "GHS BEVANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20770",
            "district": "13",
            "Block": "122",
            "label": "ST THOMAS BRILLIANT MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20771",
            "district": "13",
            "Block": "122",
            "label": "GHS D RATHINAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20772",
            "district": "13",
            "Block": "122",
            "label": "ETERNAL LIGHT PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20773",
            "district": "13",
            "Block": "122",
            "label": "GHSS-GIRLS  DENKANIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20774",
            "district": "13",
            "Block": "122",
            "label": "ST JOSEPHS HIGH SCHOOL AIDED",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20775",
            "district": "13",
            "Block": "122",
            "label": "GHSS BOYS DENKANIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20776",
            "district": "13",
            "Block": "122",
            "label": "KGBV DENKANIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20777",
            "district": "13",
            "Block": "122",
            "label": "GHS DODDABELLUR TELUGU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20778",
            "district": "13",
            "Block": "122",
            "label": "GHS SANTHANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20779",
            "district": "13",
            "Block": "122",
            "label": "GHS-GIRLS  KELAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20780",
            "district": "13",
            "Block": "122",
            "label": "GHSS KELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20781",
            "district": "13",
            "Block": "122",
            "label": "MODEL SCHOOL KELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20782",
            "district": "13",
            "Block": "122",
            "label": "GHS KUNDUMARANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20783",
            "district": "13",
            "Block": "122",
            "label": "GHS LINGEGOWNDAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20784",
            "district": "13",
            "Block": "122",
            "label": "GHS NAGAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20785",
            "district": "13",
            "Block": "122",
            "label": "PUMS NELLUKUNTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20786",
            "district": "13",
            "Block": "122",
            "label": "PUMS NEMILERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20787",
            "district": "13",
            "Block": "122",
            "label": "PUMS PACHAPANATTI TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20788",
            "district": "13",
            "Block": "122",
            "label": "GHSS GIRLS  RAYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20789",
            "district": "13",
            "Block": "122",
            "label": "GHSS-BOYS RAYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20790",
            "district": "13",
            "Block": "122",
            "label": "ETERNAL LIGHT MATRIC.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20791",
            "district": "13",
            "Block": "122",
            "label": "PUMS SAMBANDAKOTTA TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20792",
            "district": "13",
            "Block": "122",
            "label": "PUMS SIDDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20793",
            "district": "13",
            "Block": "122",
            "label": "PUMS SONNE NAYAKKANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20794",
            "district": "13",
            "Block": "122",
            "label": "GHS THIPPASANDIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20795",
            "district": "13",
            "Block": "122",
            "label": "GHSS  ULLUKURUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20796",
            "district": "13",
            "Block": "122",
            "label": "GHS BOMMATHATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20797",
            "district": "13",
            "Block": "122",
            "label": "GHS D.T.DINNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20798",
            "district": "13",
            "Block": "122",
            "label": "GHS KOPPAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20799",
            "district": "13",
            "Block": "122",
            "label": "JOHN BRITTO HSS ANIYALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20800",
            "district": "13",
            "Block": "122",
            "label": "PUMS BOLLAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20801",
            "district": "13",
            "Block": "122",
            "label": "GHS BETTAMUGILALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20802",
            "district": "13",
            "Block": "122",
            "label": "GHSS UNICHETTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20803",
            "district": "13",
            "Block": "122",
            "label": "PUMS THOLVAPETTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22300",
            "district": "13",
            "Block": "122",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Denkanikottai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22301",
            "district": "13",
            "Block": "122",
            "label": "SEVENTH DAY ADVENTIST INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23030",
            "district": "13",
            "Block": "122",
            "label": "MOTHER THERESA PUBLIC SCHOOL, RAYAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23826",
            "district": "13",
            "Block": "122",
            "label": "GHS NAGAMANGALAM",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23827",
            "district": "13",
            "Block": "122",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "123": [
        {
            "key": "4779",
            "district": "16",
            "Block": "123",
            "label": "PUMS RADHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4992",
            "district": "16",
            "Block": "123",
            "label": "PUMS KERALANTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5014",
            "district": "16",
            "Block": "123",
            "label": "PUMS VADAKKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5288",
            "district": "16",
            "Block": "123",
            "label": "GHSS KURUKKATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5292",
            "district": "16",
            "Block": "123",
            "label": "PUMS NAGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5325",
            "district": "16",
            "Block": "123",
            "label": "PUMS KURUMANANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5389",
            "district": "16",
            "Block": "123",
            "label": "PUMS ATHIPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5510",
            "district": "16",
            "Block": "123",
            "label": "PUMS VADAKKUPANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5514",
            "district": "16",
            "Block": "123",
            "label": "PUMS KADAMBANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5716",
            "district": "16",
            "Block": "123",
            "label": "PUMS MOHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5723",
            "district": "16",
            "Block": "123",
            "label": "PUMS IRUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5773",
            "district": "16",
            "Block": "123",
            "label": "PUMS THIRUKKANNANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5787",
            "district": "16",
            "Block": "123",
            "label": "PUMS KOHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5808",
            "district": "16",
            "Block": "123",
            "label": "PUMS THEVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5907",
            "district": "16",
            "Block": "123",
            "label": "PUMS VALLAVINAYAGAN KOTTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5927",
            "district": "16",
            "Block": "123",
            "label": "PUMS KUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6208",
            "district": "16",
            "Block": "123",
            "label": "PUMS VANDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15718",
            "district": "16",
            "Block": "123",
            "label": "GHS VENKIDANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15719",
            "district": "16",
            "Block": "123",
            "label": "GHSS KILVELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15720",
            "district": "16",
            "Block": "123",
            "label": "THIRU ANJUVATTATHAMMAN GIRLS AIDED HIGH SCHOOL, KILVELUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15721",
            "district": "16",
            "Block": "123",
            "label": "GHS KILVELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15722",
            "district": "16",
            "Block": "123",
            "label": "GHSS THEVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15723",
            "district": "16",
            "Block": "123",
            "label": "INFANT JESUS MATRICULATION HIGH SCHOOL THEVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15724",
            "district": "16",
            "Block": "123",
            "label": "GHS KILLUKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15725",
            "district": "16",
            "Block": "123",
            "label": "GHSS ANTHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15726",
            "district": "16",
            "Block": "123",
            "label": "GHS SATTIYAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15727",
            "district": "16",
            "Block": "123",
            "label": "GHS ATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15728",
            "district": "16",
            "Block": "123",
            "label": "VALIVALAM DESIKAR  AIDED HIGHER SECONDARY SCHOOL VALIVALM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21534",
            "district": "16",
            "Block": "123",
            "label": "SRI KALAIMAHAL AIDED HIGH SCHOOL MANALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        }
    ],
    "124": [
        {
            "key": "996",
            "district": "11",
            "Block": "124",
            "label": "SEVENTH DAY ADVENTIST MATRIC HIGH SCHOOL VIRICODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1278",
            "district": "11",
            "Block": "124",
            "label": "SVK MATRICULATION SCHOOL VIRICODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1564",
            "district": "11",
            "Block": "124",
            "label": "BETHLAHEM MATRIC & HR.SEC. SCHOOL,KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1589",
            "district": "11",
            "Block": "124",
            "label": "GMS MANKANTUVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1795",
            "district": "11",
            "Block": "124",
            "label": "DONBOSCO MATRIC HR.SEC.SCHOOL-MIDALAKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1844",
            "district": "11",
            "Block": "124",
            "label": "SGS MHSS KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2151",
            "district": "11",
            "Block": "124",
            "label": "ROSE OF SHARON THOZHICODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2827",
            "district": "11",
            "Block": "124",
            "label": "ST. JHS VALIYAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "2836",
            "district": "11",
            "Block": "124",
            "label": "ST. HELEN HS ENAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "3023",
            "district": "11",
            "Block": "124",
            "label": "HINDU VIDYALAYA MATRIC. HIGHER SECONDARY SCHOOL. MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3612",
            "district": "11",
            "Block": "124",
            "label": "VIDYA JOTHI MHSS MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3982",
            "district": "11",
            "Block": "124",
            "label": "CJM HSS UNNAMALAIKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4249",
            "district": "11",
            "Block": "124",
            "label": "WHITE MEMORIAL MATRIC. HIGHER SECONDARY SCHOOL, MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4252",
            "district": "11",
            "Block": "124",
            "label": "A.P.J.M.MATRIC HR.SEC.S-SOOSAIPURAM, KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4294",
            "district": "11",
            "Block": "124",
            "label": "LUTH. MS VAZHUTHALAMPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4322",
            "district": "11",
            "Block": "124",
            "label": "GOVT MIDDLE SCHOOL, DEVICODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4741",
            "district": "11",
            "Block": "124",
            "label": "KALLADAI SHRI KRISHNA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4848",
            "district": "11",
            "Block": "124",
            "label": "C.S.I HACKER MATRICULATION SCHOOL, PAYANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5080",
            "district": "11",
            "Block": "124",
            "label": "GMS PARAVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5650",
            "district": "11",
            "Block": "124",
            "label": "SACRED HEART MHS  VIRICODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5856",
            "district": "11",
            "Block": "124",
            "label": "GMS THATTANVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5960",
            "district": "11",
            "Block": "124",
            "label": "CARMEL MATRICULATION SCHOOL - MATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6180",
            "district": "11",
            "Block": "124",
            "label": "BARATH MATRICULATION SCHOOL ANANVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6497",
            "district": "11",
            "Block": "124",
            "label": "GMS MANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20338",
            "district": "11",
            "Block": "124",
            "label": "GHSS-NALLOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20339",
            "district": "11",
            "Block": "124",
            "label": "ST Anne's Matric Higher Secondary School, Elavuvilai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20340",
            "district": "11",
            "Block": "124",
            "label": "SACRED HEART INTERNATIONAL SCHOOL-PAMMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20341",
            "district": "11",
            "Block": "124",
            "label": "GMS KADAMANKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20342",
            "district": "11",
            "Block": "124",
            "label": "ST. FRANCIS XAVIER H.S - ALANCHI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20343",
            "district": "11",
            "Block": "124",
            "label": "ST. ALOYSIUS GIRLS HSS VELLIAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20344",
            "district": "11",
            "Block": "124",
            "label": "LMS HSS - MATHICODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20345",
            "district": "11",
            "Block": "124",
            "label": "K.T.P. HIGHER SEC. SCHOOL MIDALAKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20346",
            "district": "11",
            "Block": "124",
            "label": "RINGLE TAUBE MATRIC HIGHER SECONDARY  SCHOOL-MATHICODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20347",
            "district": "11",
            "Block": "124",
            "label": "GHS UDAYA MARTHANDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20348",
            "district": "11",
            "Block": "124",
            "label": "GHSS MATHICODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20349",
            "district": "11",
            "Block": "124",
            "label": "ST. ANTONY'S GIRLS HIGH SCHOOL ,THUNDATHUVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20350",
            "district": "11",
            "Block": "124",
            "label": "ST. BERNADETTE'S HSS MANGALAKUNTU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20351",
            "district": "11",
            "Block": "124",
            "label": "SAINIKS.MATRIC SCHOOL-KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20352",
            "district": "11",
            "Block": "124",
            "label": "GHSS KEEZHKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20353",
            "district": "11",
            "Block": "124",
            "label": "ST. AHSS ENAMPUTHENTHURAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20354",
            "district": "11",
            "Block": "124",
            "label": "ST. JO HS-RAMANTHURAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20355",
            "district": "11",
            "Block": "124",
            "label": "HAM HSS CHENTHARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20356",
            "district": "11",
            "Block": "124",
            "label": "GHS KEEZHKULAM WEST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20357",
            "district": "11",
            "Block": "124",
            "label": "GMS VENCODE NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20358",
            "district": "11",
            "Block": "124",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL VIZHUNTHAYAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20359",
            "district": "11",
            "Block": "124",
            "label": "ST. FRANCIS HSS VENCODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20360",
            "district": "11",
            "Block": "124",
            "label": "OXFORD MATRIC HIGHER SECONDARY SCHOOL,THOLAYAVATTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20361",
            "district": "11",
            "Block": "124",
            "label": "BETHANI MHSS  VENCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20362",
            "district": "11",
            "Block": "124",
            "label": "GOVT MIDDLE SCHOOL, KANJIRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20363",
            "district": "11",
            "Block": "124",
            "label": "GHS KANJIRACODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20364",
            "district": "11",
            "Block": "124",
            "label": "GHS UNNAMALAIKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20365",
            "district": "11",
            "Block": "124",
            "label": "GHSS NATTALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20366",
            "district": "11",
            "Block": "124",
            "label": "ST. JOHN VIANNEY'S GIRLS' HR. SEC. SCHOOL , PALLIYADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20367",
            "district": "11",
            "Block": "124",
            "label": "ST. MSC HS NATTALAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20368",
            "district": "11",
            "Block": "124",
            "label": "ST. AHS ELAVUVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20369",
            "district": "11",
            "Block": "124",
            "label": "CSI SCHOOL FOR THE BLIND, IRENIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20370",
            "district": "11",
            "Block": "124",
            "label": "INFANT JESUS MATRIC HSS MAMOOTTUKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20371",
            "district": "11",
            "Block": "124",
            "label": "N.A.M. MATRICULATION  HR. SEC. SCHOOL,NELVELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20372",
            "district": "11",
            "Block": "124",
            "label": "SREE CHENBAGA MATRICULATION SCHOOL PAYANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20373",
            "district": "11",
            "Block": "124",
            "label": "GHSS KARUNGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20374",
            "district": "11",
            "Block": "124",
            "label": "ST. ANTONY'S HIGH SCHOOL, MULLANGANAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20375",
            "district": "11",
            "Block": "124",
            "label": "CONCORDIA HR.SEC.SCHOOL-POTTETI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20983",
            "district": "11",
            "Block": "124",
            "label": "ARTESIA MATRICULATION HR SEC SCHOOL KAPPUKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21125",
            "district": "11",
            "Block": "124",
            "label": "BETHANY NAVAJEEVAN CBSE SCHOOL-VENCODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21625",
            "district": "11",
            "Block": "124",
            "label": "NANJIL CATHOLIC SCHOOL(CBSE)-VAZHUTHALAMPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21813",
            "district": "11",
            "Block": "124",
            "label": "ST.MARY'S SCHOOL , AMANATTANTHERI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21814",
            "district": "11",
            "Block": "124",
            "label": "BEST SCHOOL (CBSE)-PALOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22288",
            "district": "11",
            "Block": "124",
            "label": "ST.JOSEPH HIGH SCHOOL-MATHIRAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22666",
            "district": "11",
            "Block": "124",
            "label": "BETHLAHEM HILLSIDE INTERNATIONAL SCHOOL-KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22667",
            "district": "11",
            "Block": "124",
            "label": "RPA CENTRAL SCHOOL, MAMOOTUKADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22849",
            "district": "11",
            "Block": "124",
            "label": "BETHLAHEM NEW CBSE CENTRAL SCHOOL, MOOSARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23489",
            "district": "11",
            "Block": "124",
            "label": "ST.JUDE'S HI-TECH SCHOOL- SOUTH NEERVAKUZHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23489",
            "district": "11",
            "Block": "124",
            "label": "ST.JUDE'S HI-TECH SCHOOL- SOUTH NEERVAKUZHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23828",
            "district": "11",
            "Block": "124",
            "label": "ST.ALOYSIUS MATRICULATION SCHOOL-VELLIAVILAI ",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "125": [
        {
            "key": "397",
            "district": "34",
            "Block": "125",
            "label": "SACRED HEART MATRIC AVOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2638",
            "district": "34",
            "Block": "125",
            "label": "ANNAAMALAI MATRIC HR SEC SCHOOL SO PUDHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4196",
            "district": "34",
            "Block": "125",
            "label": "ADWMS KARNAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4468",
            "district": "34",
            "Block": "125",
            "label": "PUMS KADAMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4490",
            "district": "34",
            "Block": "125",
            "label": "PUMS SO.NAMMIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4506",
            "district": "34",
            "Block": "125",
            "label": "PUMS ARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4593",
            "district": "34",
            "Block": "125",
            "label": "PUMS RAYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4606",
            "district": "34",
            "Block": "125",
            "label": "PUMS SIRUNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4770",
            "district": "34",
            "Block": "125",
            "label": "ADWMS PANNIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5098",
            "district": "34",
            "Block": "125",
            "label": "PUMS MANAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5235",
            "district": "34",
            "Block": "125",
            "label": "PUMS GANALAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5632",
            "district": "34",
            "Block": "125",
            "label": "PUMS-ZAMEEN AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5804",
            "district": "34",
            "Block": "125",
            "label": "PUMS ARANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5815",
            "district": "34",
            "Block": "125",
            "label": "PUMS OLAIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5840",
            "district": "34",
            "Block": "125",
            "label": "PUMS KALLAYEE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5846",
            "district": "34",
            "Block": "125",
            "label": "PUMS EAST VETTAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5916",
            "district": "34",
            "Block": "125",
            "label": "PUMS GENGANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5951",
            "district": "34",
            "Block": "125",
            "label": "PUMS KATHALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6582",
            "district": "34",
            "Block": "125",
            "label": "MOORTHY MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9795",
            "district": "34",
            "Block": "125",
            "label": "GHS AVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9796",
            "district": "34",
            "Block": "125",
            "label": "NEW INDIAN MATRIC. HI-TECH HR SEC SCHOOL-OLAIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9797",
            "district": "34",
            "Block": "125",
            "label": "GHS CHELLANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9798",
            "district": "34",
            "Block": "125",
            "label": "GHSS IYNGUNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9799",
            "district": "34",
            "Block": "125",
            "label": "GHS KARIKALAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9800",
            "district": "34",
            "Block": "125",
            "label": "RRHS KARUNGALIKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9801",
            "district": "34",
            "Block": "125",
            "label": "GHS KAZHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9802",
            "district": "34",
            "Block": "125",
            "label": "GHS KEEKALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9803",
            "district": "34",
            "Block": "125",
            "label": "GHSS (BOYS) KILPENNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9804",
            "district": "34",
            "Block": "125",
            "label": "GHSS (GIRLS) KILPENNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9805",
            "district": "34",
            "Block": "125",
            "label": "GHS KOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9806",
            "district": "34",
            "Block": "125",
            "label": "GHS KONALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9807",
            "district": "34",
            "Block": "125",
            "label": "GHSS MEKKALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9808",
            "district": "34",
            "Block": "125",
            "label": "ST PAUL HS NA.GENGAPATTU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9809",
            "district": "34",
            "Block": "125",
            "label": "GHS NARIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9810",
            "district": "34",
            "Block": "125",
            "label": "GHSS RAJANTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9811",
            "district": "34",
            "Block": "125",
            "label": "NATIONAL MATRIC HIGHER SECONDARY  SCHOOL RAJANTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9812",
            "district": "34",
            "Block": "125",
            "label": "AMEEN HSS SO.PUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9813",
            "district": "34",
            "Block": "125",
            "label": "DMHS SOMASIPADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9814",
            "district": "34",
            "Block": "125",
            "label": "GHS SU.POLAKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9815",
            "district": "34",
            "Block": "125",
            "label": "GHS VAIPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9816",
            "district": "34",
            "Block": "125",
            "label": "GHS VALUTHALANGUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9817",
            "district": "34",
            "Block": "125",
            "label": "GHSS VEDANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9818",
            "district": "34",
            "Block": "125",
            "label": "GHS VELANANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9819",
            "district": "34",
            "Block": "125",
            "label": "GHSS (BOYS) VETTAVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9820",
            "district": "34",
            "Block": "125",
            "label": "GHSS (GIRLS) VETTAVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9821",
            "district": "34",
            "Block": "125",
            "label": "SA HSS (GIRLS) VETTAVALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9822",
            "district": "34",
            "Block": "125",
            "label": "SM HSS (BOYS) VETTAVALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9823",
            "district": "34",
            "Block": "125",
            "label": "DR. LAMAYAR MATRIC VETTAVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9824",
            "district": "34",
            "Block": "125",
            "label": "GOOD WILL MATRIC HR.SEC SCHOOL ANUKKUMALAI VETTAVALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9825",
            "district": "34",
            "Block": "125",
            "label": "ST.XAVIER HS ZAMEEN GUDALUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20947",
            "district": "34",
            "Block": "125",
            "label": "SKV INTERNATIONAL SCHOOL-KEERANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20959",
            "district": "34",
            "Block": "125",
            "label": "SRI ARUNESHWAR MATRIC SCHOOL-VETTAVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21265",
            "district": "34",
            "Block": "125",
            "label": "SAKTHI MATRIC-KUNNANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21673",
            "district": "34",
            "Block": "125",
            "label": "SASTRA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21674",
            "district": "34",
            "Block": "125",
            "label": "LAKSHMI AMMAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22090",
            "district": "34",
            "Block": "125",
            "label": "SAN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22923",
            "district": "34",
            "Block": "125",
            "label": "GHS-KATTUMALAIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23012",
            "district": "34",
            "Block": "125",
            "label": "JAYAM VIDYASHRAM SCHOOL( CBSE )  KILPENNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "126": [
        {
            "key": "1214",
            "district": "4",
            "Block": "126",
            "label": "PUMS SINGAIYANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1862",
            "district": "4",
            "Block": "126",
            "label": "PUMS SOKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1970",
            "district": "4",
            "Block": "126",
            "label": "KIDS PARK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2570",
            "district": "4",
            "Block": "126",
            "label": "VIVEK VIDYALAYA MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2583",
            "district": "4",
            "Block": "126",
            "label": "VIGHNESWAR VIDHYA MANDHIR MAT.HR. SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2720",
            "district": "4",
            "Block": "126",
            "label": "PUMS SULAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2960",
            "district": "4",
            "Block": "126",
            "label": "PUMS SIKKALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3342",
            "district": "4",
            "Block": "126",
            "label": "AKSHAYA ACADEMY MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4664",
            "district": "4",
            "Block": "126",
            "label": "PUMS NO.10 MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6320",
            "district": "4",
            "Block": "126",
            "label": "SWAMY CHIDBHAVANANDA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12945",
            "district": "4",
            "Block": "126",
            "label": "GHS ARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12946",
            "district": "4",
            "Block": "126",
            "label": "GHSS SERIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12947",
            "district": "4",
            "Block": "126",
            "label": "GHS ANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12948",
            "district": "4",
            "Block": "126",
            "label": "GHS DEVANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12949",
            "district": "4",
            "Block": "126",
            "label": "PUMS SENDRAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12950",
            "district": "4",
            "Block": "126",
            "label": "PUMS DASANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12951",
            "district": "4",
            "Block": "126",
            "label": "GHS KATTAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12952",
            "district": "4",
            "Block": "126",
            "label": "PUMS KOTHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12953",
            "district": "4",
            "Block": "126",
            "label": "GHSS KALIANNANPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12954",
            "district": "4",
            "Block": "126",
            "label": "PUMS KONDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12955",
            "district": "4",
            "Block": "126",
            "label": "TELC MS KARUNAGARAPURI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12956",
            "district": "4",
            "Block": "126",
            "label": "NOYYAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12957",
            "district": "4",
            "Block": "126",
            "label": "PUMS NALLATTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12958",
            "district": "4",
            "Block": "126",
            "label": "PUMS METTUVAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12959",
            "district": "4",
            "Block": "126",
            "label": "GHS PANAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12960",
            "district": "4",
            "Block": "126",
            "label": "MNM HIGH SCHOOL JAKKARPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12961",
            "district": "4",
            "Block": "126",
            "label": "PUMS PALARPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12962",
            "district": "4",
            "Block": "126",
            "label": "GHSS MUTHUGOUNDENUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12963",
            "district": "4",
            "Block": "126",
            "label": "GHSS VADACHITHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12964",
            "district": "4",
            "Block": "126",
            "label": "GHS SENGUTTAIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12965",
            "district": "4",
            "Block": "126",
            "label": "AIDED MIDDLE SCHOOL SINGARAM PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12966",
            "district": "4",
            "Block": "126",
            "label": "GHSS KINATHUKADAVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12967",
            "district": "4",
            "Block": "126",
            "label": "TELC SR. BERGENDHAL GIRLS HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12968",
            "district": "4",
            "Block": "126",
            "label": "PRESENTATION CON FOR DISABLED",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21315",
            "district": "4",
            "Block": "126",
            "label": "VIVEK VIDYA MANDIR (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23829",
            "district": "4",
            "Block": "126",
            "label": "VIDYARPANA  HOME MATRICULATION SCHOOL  ",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "127": [
        {
            "key": "3188",
            "district": "7",
            "Block": "127",
            "label": "ST THOMAS AQUINAS MATRIC HIGHER SECONDARY SCHOOL,PETHUPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3375",
            "district": "7",
            "Block": "127",
            "label": "JAYARAJ ANNAPACKIAM MATRICULATION SCHOOL, PANNAIKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3431",
            "district": "7",
            "Block": "127",
            "label": "SRI SANKARA MHSS,KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3476",
            "district": "7",
            "Block": "127",
            "label": "BRINDAVAN MHSS,KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3852",
            "district": "7",
            "Block": "127",
            "label": "SAANTHAM MATRICULATION HIGHER SECONDARY SCHOOL,NAIDUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3885",
            "district": "7",
            "Block": "127",
            "label": "ZION MHSS,KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4233",
            "district": "7",
            "Block": "127",
            "label": "VIVEKANANDHA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, PANNAIKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5057",
            "district": "7",
            "Block": "127",
            "label": "ASSEFA VIDHYALAYA MATRICULATION SCHOOL, KAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13428",
            "district": "7",
            "Block": "127",
            "label": "PUMS,GUNDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13429",
            "district": "7",
            "Block": "127",
            "label": "PUMS,PALAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13430",
            "district": "7",
            "Block": "127",
            "label": "PUMS,KOOKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13431",
            "district": "7",
            "Block": "127",
            "label": "RC MIDDLE SCHOOL, KAMARAJAPURAM,KOMBAIKADU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13432",
            "district": "7",
            "Block": "127",
            "label": "GHS,VILPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13433",
            "district": "7",
            "Block": "127",
            "label": "GHSS,POOMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13434",
            "district": "7",
            "Block": "127",
            "label": "GHSS, MANNAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13435",
            "district": "7",
            "Block": "127",
            "label": "PUMS,POLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13436",
            "district": "7",
            "Block": "127",
            "label": "PUMS,MELAKILAVARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13437",
            "district": "7",
            "Block": "127",
            "label": "GHS,KAVUNJI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13438",
            "district": "7",
            "Block": "127",
            "label": "GHS,POONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13439",
            "district": "7",
            "Block": "127",
            "label": "ST. JOSEPH'S HIGH SCHOOL,PERUMALMALAI, KODAIKANAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13440",
            "district": "7",
            "Block": "127",
            "label": "PUMS,KUMBARIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13441",
            "district": "7",
            "Block": "127",
            "label": "GHS,POOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13442",
            "district": "7",
            "Block": "127",
            "label": "GHS(GIRLS), PANNAIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13443",
            "district": "7",
            "Block": "127",
            "label": "GHSS,PANNAIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13444",
            "district": "7",
            "Block": "127",
            "label": "NMHS,THANDIKUDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13445",
            "district": "7",
            "Block": "127",
            "label": "PUMS, MANGALAMCOMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13446",
            "district": "7",
            "Block": "127",
            "label": "PUMS,KUPPAMMALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13447",
            "district": "7",
            "Block": "127",
            "label": "GHS,KILAKKUCHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13448",
            "district": "7",
            "Block": "127",
            "label": "PUMS, NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13449",
            "district": "7",
            "Block": "127",
            "label": "PUMS,PACHALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13450",
            "district": "7",
            "Block": "127",
            "label": "PUMS,VADAKAVUNJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13451",
            "district": "7",
            "Block": "127",
            "label": "ST XAVIER'S HSS, NAIDUPURAM, KODAIKANAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13452",
            "district": "7",
            "Block": "127",
            "label": "CSI TOWN  MIDDLE SCHOOL,KODAIKANAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13453",
            "district": "7",
            "Block": "127",
            "label": "CSI MIDDLE SCHOOL,PACKIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13454",
            "district": "7",
            "Block": "127",
            "label": "GHSS,KODAIKANAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13455",
            "district": "7",
            "Block": "127",
            "label": "ST JOHN'S GHSS KODAIKANAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13456",
            "district": "7",
            "Block": "127",
            "label": "RC TOWN HSS,KODAIKANAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13457",
            "district": "7",
            "Block": "127",
            "label": "ST XAVIER'S HIGHER SECONDARY SCHOOL,SHENBAGANUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13458",
            "district": "7",
            "Block": "127",
            "label": "ST PETERS MHSS,PAMBARPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13459",
            "district": "7",
            "Block": "127",
            "label": "BHARATIYA VIDYA BHAVAN'S GANDHI VIDYASHRAM, INTERNATIONAL RESIDENTIAL PUBLIC SCHOOL(CBSE),KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13460",
            "district": "7",
            "Block": "127",
            "label": "KODAI PHSS,KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13461",
            "district": "7",
            "Block": "127",
            "label": "ST. THOMAS MATRICULATION HR. SEC.SCHOOL,NAIDUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13462",
            "district": "7",
            "Block": "127",
            "label": "PRESENTATION CONVENT MATRICULATION HIGHER SECONDARY SCHOOL, NAIDUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13463",
            "district": "7",
            "Block": "127",
            "label": "ST THERASA HS,PAMBARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13464",
            "district": "7",
            "Block": "127",
            "label": "KODAIKANAL INTERNATIONAL SCHOOL, KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22176",
            "district": "7",
            "Block": "127",
            "label": "SHOLAI SCHOOL CENTRE FOR LEARNING ORGANIC AGRIC & APPROPRIATE TECHNOLOGY BHARATHI ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23100",
            "district": "7",
            "Block": "127",
            "label": "ZION INTERNATIONAL SCHOOL, KODAIKANAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "128": [
        {
            "key": "601",
            "district": "8",
            "Block": "128",
            "label": "ST.A.M.SCHOOL,SAHAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "696",
            "district": "8",
            "Block": "128",
            "label": "SRI SANKARA VID MATRIC HR SEC SCHOOL SIVAGIRI ERODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "705",
            "district": "8",
            "Block": "128",
            "label": "PUMS,PERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "924",
            "district": "8",
            "Block": "128",
            "label": "RAMALINGAM A.M.SCHOOL,KILAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "972",
            "district": "8",
            "Block": "128",
            "label": "PUMS,MALAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1072",
            "district": "8",
            "Block": "128",
            "label": "PUMS.MURUGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1094",
            "district": "8",
            "Block": "128",
            "label": "THAMARAI MATRIC HSS THAMARAIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1304",
            "district": "8",
            "Block": "128",
            "label": "PUMS.VALLIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1315",
            "district": "8",
            "Block": "128",
            "label": "PUMS,NALLASELLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1399",
            "district": "8",
            "Block": "128",
            "label": "PUMS, VALLANTHANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1571",
            "district": "8",
            "Block": "128",
            "label": "PUMS,PERIYAVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1978",
            "district": "8",
            "Block": "128",
            "label": "PUMS,RATHINAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3104",
            "district": "8",
            "Block": "128",
            "label": "PUMS,SIVAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3631",
            "district": "8",
            "Block": "128",
            "label": "PUMS,VADAKKUPUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4954",
            "district": "8",
            "Block": "128",
            "label": "SHREE VALLI MARIC  HSS,ICHIPPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12216",
            "district": "8",
            "Block": "128",
            "label": "GHS,KARAVALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12217",
            "district": "8",
            "Block": "128",
            "label": "GHS,NAGAPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12218",
            "district": "8",
            "Block": "128",
            "label": "GHSS,SALAIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12219",
            "district": "8",
            "Block": "128",
            "label": "GHSS,THAMARAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12220",
            "district": "8",
            "Block": "128",
            "label": "GHSS,PASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12221",
            "district": "8",
            "Block": "128",
            "label": "GHSS,THANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12222",
            "district": "8",
            "Block": "128",
            "label": "SSV AIDED GIRLS HIGH SCHOOL,KODUMUDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12223",
            "district": "8",
            "Block": "128",
            "label": "SSV AIDED HIGHER SECONDARY SCHOOL,KODUMUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12224",
            "district": "8",
            "Block": "128",
            "label": "GGHSS,SIVAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12225",
            "district": "8",
            "Block": "128",
            "label": "GBHSS,SIVAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12226",
            "district": "8",
            "Block": "128",
            "label": "GHSS,VELLOTTAMPARAPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12227",
            "district": "8",
            "Block": "128",
            "label": "GHSS,UNJALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22129",
            "district": "8",
            "Block": "128",
            "label": "KONGU MATRICULATION HIGHER SECONDARY SCHOOL,  KARUMANDAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "129": [
        {
            "key": "2555",
            "district": "22",
            "Block": "129",
            "label": "HOLY ANGELS MATRIC HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3190",
            "district": "22",
            "Block": "129",
            "label": "ST.MARY'S MATRIC HR.SEC.SCHOOL,METTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3394",
            "district": "22",
            "Block": "129",
            "label": "VALLUVAR VASUKI MATRIC HR. SEC. SCHOOL,NATTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3825",
            "district": "22",
            "Block": "129",
            "label": "M.A.M.MATRIC HR.SEC.SCHOOL,MATHAIYANKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4285",
            "district": "22",
            "Block": "129",
            "label": "APPLE PERKS INTERNATIONAL SCHOOL,KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5985",
            "district": "22",
            "Block": "129",
            "label": "MONTFORT MATRIC HR. SEC. SCHOOL, KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11477",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KURUMBANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11478",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KANAMOOCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11479",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HIGH SCHOOL,CHETTIUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11480",
            "district": "22",
            "Block": "129",
            "label": "K.G.B.V. KANNAMOOCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11481",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KAVERIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11482",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KATHIRIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11483",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11484",
            "district": "22",
            "Block": "129",
            "label": "PUMS,GOVINDAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11485",
            "district": "22",
            "Block": "129",
            "label": "PUMS,THARKADU SUNDHARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11486",
            "district": "22",
            "Block": "129",
            "label": "PUMS,TELUNGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11487",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HR. SEC.SCHOOL,  KAVERIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11488",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HIGH SCHOOL, KARUNGALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11489",
            "district": "22",
            "Block": "129",
            "label": "PUMS,KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11490",
            "district": "22",
            "Block": "129",
            "label": "J.B. MATRIC HR. SEC. SCHOOL, KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11491",
            "district": "22",
            "Block": "129",
            "label": "GOVT. GIRLS HR. SEC.SCHOOL, KOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11492",
            "district": "22",
            "Block": "129",
            "label": "NIRMALA HR. SEC. SCHOOL,KOLATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11493",
            "district": "22",
            "Block": "129",
            "label": "PUMS,PORAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11494",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HR. SEC. SCHOOL,SANAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11495",
            "district": "22",
            "Block": "129",
            "label": "PUMS,P.K.NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11496",
            "district": "22",
            "Block": "129",
            "label": "SRI RAMAKRISHNA GURUKULA HIGH SCHOOL, CHINNATHANDA",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11497",
            "district": "22",
            "Block": "129",
            "label": "K.G.B.V. NEETHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11498",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HIGH SCHOOL, NEETHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11499",
            "district": "22",
            "Block": "129",
            "label": "PUMS,MOOLAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11500",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HIGH SCHOOL,MATHAIYANKUTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11501",
            "district": "22",
            "Block": "129",
            "label": "M.A.M.HR.SEC.SCHOOL,MATHAIYANKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11502",
            "district": "22",
            "Block": "129",
            "label": "PUMS,NAGAMPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11503",
            "district": "22",
            "Block": "129",
            "label": "G.V. HR.SEC.SCHOOL,MASILAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11504",
            "district": "22",
            "Block": "129",
            "label": "PUMS,AYYAMPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11505",
            "district": "22",
            "Block": "129",
            "label": "VIVEKANANDHA KALVI NILAYAM MATRIC SCHOOL,KARUNGALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11506",
            "district": "22",
            "Block": "129",
            "label": "PUMS,SETHUKULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11507",
            "district": "22",
            "Block": "129",
            "label": "PUMS,PANNAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11508",
            "district": "22",
            "Block": "129",
            "label": "AIDED KAMALA MIDDLE SCHOOL,METTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11509",
            "district": "22",
            "Block": "129",
            "label": "ST.PHILOMINA'S MIDDLE SCHOOL, METTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11510",
            "district": "22",
            "Block": "129",
            "label": "GOVT. HR. SEC.SCHOOL, METTUR DAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11511",
            "district": "22",
            "Block": "129",
            "label": "ST.MARY'S HR. SEC.SCHOOL, METTUR DAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11512",
            "district": "22",
            "Block": "129",
            "label": "VAIDHEESAWARA HR.SEC.SCHOOL,METTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11513",
            "district": "22",
            "Block": "129",
            "label": "GOVT. GIRLS HIGH SCHOOL, METTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11514",
            "district": "22",
            "Block": "129",
            "label": "M.A.M. HI-TECH MATRIC HR. SEC. SCHOOL, METTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21212",
            "district": "22",
            "Block": "129",
            "label": "MODEL SCHOOL, KOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21498",
            "district": "22",
            "Block": "129",
            "label": "GTR HIGH SCHOOL, RAMANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23109",
            "district": "22",
            "Block": "129",
            "label": "SRI RAMAKRISHNA VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23233",
            "district": "22",
            "Block": "129",
            "label": "GHS CHEKKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "130": [
        {
            "key": "5083",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6481",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10382",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL MUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10383",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL ARASAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10384",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL ATHIYUR THIRUVATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10385",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL ASARANGKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10386",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10387",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHOLAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10388",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10389",
            "district": "37",
            "Block": "130",
            "label": "ADI DRAVIDA WELFARE HIGH SCHOOL  KAPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10390",
            "district": "37",
            "Block": "130",
            "label": "BONNE NEHRU MATRICULATION SCHOOL,KANDAMANADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10391",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  KANDAMANADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10392",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL KALLAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10393",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL MARAGATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10394",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10395",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAVANIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10396",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VALAVANUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10397",
            "district": "37",
            "Block": "130",
            "label": "GOVINDIER AIDED MIDDLE SCHOOL VALAVANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10398",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  VALAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10399",
            "district": "37",
            "Block": "130",
            "label": "S.R.GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ,VALAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10400",
            "district": "37",
            "Block": "130",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION SCHOOL VALAVANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10401",
            "district": "37",
            "Block": "130",
            "label": "SHREEM MATRICULATION SCHOOL VALAVANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10402",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL KONDANGI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10403",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOLIYANUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10404",
            "district": "37",
            "Block": "130",
            "label": "CONIFER MATRICULATION SCHOOL KOLIYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10405",
            "district": "37",
            "Block": "130",
            "label": "SRI SARASWATHI VIDHYALA MATRICULATION SCHOOL KOLIYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10406",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANICHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10407",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KOLIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10408",
            "district": "37",
            "Block": "130",
            "label": "SIVASAKTHI MATRICULATION HIGHER SECONDARY SCHOOL KOLIYANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10409",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SALAIAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10410",
            "district": "37",
            "Block": "130",
            "label": "ADITYA'S VIVEKANAN MATRIC HIGHER SECONDARY SCHOOL DEVANATHA SWAMY NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10411",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SALAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10412",
            "district": "37",
            "Block": "130",
            "label": "RAMAKRISHNA MISSION VIDYALAYA MATRICULATION SCHOOL SALAMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10413",
            "district": "37",
            "Block": "130",
            "label": "BHARATHI AIDED MIDDLE SCHOOL SAGADEVANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10414",
            "district": "37",
            "Block": "130",
            "label": "ROMAN CATHOLIC AIDED MIDDLE SCHOOL SENGADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10415",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL SOZHAGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10416",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL THALAVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10417",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10418",
            "district": "37",
            "Block": "130",
            "label": "SRI JEYEANDRA SARASWATHY VIDYAALAYAA MATRICULATION HIGHER SECONDARY SCHOOL T.MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10419",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUPACHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10420",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL POOTHAMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10421",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THOGAIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10422",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL NANNADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10423",
            "district": "37",
            "Block": "130",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL NARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10424",
            "district": "37",
            "Block": "130",
            "label": "KALAIMAGAL AIDED MIDDLE SCHOOL RAGAVANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10425",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PILLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10426",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PIDAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10427",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGH SCHOOL POYYAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10428",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10429",
            "district": "37",
            "Block": "130",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAZHUDAREDDY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10430",
            "district": "37",
            "Block": "130",
            "label": "S.P.N MATRICULATION HIGHER SECONDARY SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10431",
            "district": "37",
            "Block": "130",
            "label": "ADI DRAVIDA WELFARE HIGHER SECONDARY SCHOOL VAZHUDAREDDY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10432",
            "district": "37",
            "Block": "130",
            "label": "SHANTHINIKETAN  MATRICULATION SCHOOL THIRUPPACHAVADIMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10433",
            "district": "37",
            "Block": "130",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VIRATTIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22429",
            "district": "37",
            "Block": "130",
            "label": "JOHN DEWEY INTERNATIONAL SCHOOL (CBSE) PANANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22694",
            "district": "37",
            "Block": "130",
            "label": "NIRMAL VIDYA MATRICULATION SCHOOL ASARANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22755",
            "district": "37",
            "Block": "130",
            "label": "E.S LORDS INTERNATIONAL SCHOOL AYYANKOILPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23155",
            "district": "37",
            "Block": "130",
            "label": "SARASWATHI EXCEL MATRICULATION SCHOOL SOZHAGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23635",
            "district": "37",
            "Block": "130",
            "label": "Government Model School, Vilupuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23635",
            "district": "37",
            "Block": "130",
            "label": "Government Model School, Vilupuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        }
    ],
    "131": [
        {
            "key": "4394",
            "district": "15",
            "Block": "131",
            "label": "PUMS NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4727",
            "district": "15",
            "Block": "131",
            "label": "PUMS KEERANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4840",
            "district": "15",
            "Block": "131",
            "label": "PUMS THIRUMAYILADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4916",
            "district": "15",
            "Block": "131",
            "label": "SRI  MEENAKSHI AMMAL AMS CHIDAMBARANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4993",
            "district": "15",
            "Block": "131",
            "label": "PUMS SANTHAPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5092",
            "district": "15",
            "Block": "131",
            "label": "PUMS PALAIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5198",
            "district": "15",
            "Block": "131",
            "label": "PUMS VEMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5232",
            "district": "15",
            "Block": "131",
            "label": "VASANTHALA AMS PANNAKKARANKOTTHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5360",
            "district": "15",
            "Block": "131",
            "label": "GHSS THIRUMULLAIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5477",
            "district": "15",
            "Block": "131",
            "label": "PUMS MUTHALAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5674",
            "district": "15",
            "Block": "131",
            "label": "ARULMOZHI AMS THANDAVANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5919",
            "district": "15",
            "Block": "131",
            "label": "SMS AMS SETHIRUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5921",
            "district": "15",
            "Block": "131",
            "label": "PUMS OTHAVANTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6268",
            "district": "15",
            "Block": "131",
            "label": "MOUNT CARMEL MATRICULATION SCHOOL, ERUKKUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6372",
            "district": "15",
            "Block": "131",
            "label": "PUMS KODIYAMPALAIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6433",
            "district": "15",
            "Block": "131",
            "label": "PUMS  KADAIKKAN VINAYAGA NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6482",
            "district": "15",
            "Block": "131",
            "label": "PUMS ERUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6502",
            "district": "15",
            "Block": "131",
            "label": "PUMS ARPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15995",
            "district": "15",
            "Block": "131",
            "label": "SRI RAMAKIRUBA AMS KADAVASAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15996",
            "district": "15",
            "Block": "131",
            "label": "RAHMANIYA HIGH SCHOOL, VADAKAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15997",
            "district": "15",
            "Block": "131",
            "label": "PUMS VETTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15998",
            "district": "15",
            "Block": "131",
            "label": "PUMS KOOZHAIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15999",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, THODUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16000",
            "district": "15",
            "Block": "131",
            "label": "VICTORIAL  MATRICULATION HIGHER SECONDARY SCHOOL, THIRUMULLAIVASAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16001",
            "district": "15",
            "Block": "131",
            "label": "PUMS RADHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16002",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, EDAMANAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16003",
            "district": "15",
            "Block": "131",
            "label": "SIVANANDHA AMS MADHANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16004",
            "district": "15",
            "Block": "131",
            "label": "GOVT ADW HIGHER SECONDARY  SCHOOL MATHANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16005",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ACHALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16006",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, THITTUPADUGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16007",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PUDUPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16008",
            "district": "15",
            "Block": "131",
            "label": "RAMESH MATRICULATION HIGH SCHOOL, PUDUPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16009",
            "district": "15",
            "Block": "131",
            "label": "RAMAKRISHNA AIDED HR SEC SCHOOL, KOTHANDAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16010",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, KODAKKARAMOOLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16011",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, THANDAVANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16012",
            "district": "15",
            "Block": "131",
            "label": "MUTHUSAMI VISWANATHAN AIDED HIGH SCHOOL, MATHIRAVELUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16013",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, VADARANGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16014",
            "district": "15",
            "Block": "131",
            "label": "GMS PANANGATTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16015",
            "district": "15",
            "Block": "131",
            "label": "PUMS OLAIYAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16016",
            "district": "15",
            "Block": "131",
            "label": "PUMS ANANTHAKOOTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16017",
            "district": "15",
            "Block": "131",
            "label": "BHARATH MATRICULATION Hr.SEC. SCHOOL, PUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16018",
            "district": "15",
            "Block": "131",
            "label": "SRINIVASA MATRICULATION  HR.SEC SCHOOL, KOLLIDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16019",
            "district": "15",
            "Block": "131",
            "label": "PUMS PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16020",
            "district": "15",
            "Block": "131",
            "label": "MEENATCHI VIDYALAYA MATRICULATION HSS SCHOOL,   PUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16021",
            "district": "15",
            "Block": "131",
            "label": "GOVERNMENT HIGH SCHOOL, MANALAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16022",
            "district": "15",
            "Block": "131",
            "label": "GHSS THULASENTHIRAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16023",
            "district": "15",
            "Block": "131",
            "label": "VENKATESA AIDED HR SEC SCHOOL AYANKUDIPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16024",
            "district": "15",
            "Block": "131",
            "label": "SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL, KOLLIDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21132",
            "district": "15",
            "Block": "131",
            "label": "PUMS PERAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23830",
            "district": "15",
            "Block": "131",
            "label": "PUMS PUTHUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "132": [
        {
            "key": "1390",
            "district": "17",
            "Block": "132",
            "label": "PUMS ARIYUR SOLAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1414",
            "district": "17",
            "Block": "132",
            "label": "PUMS ARIYUR KIZHAKKU VALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1612",
            "district": "17",
            "Block": "132",
            "label": "PUMS ASAKKATTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1701",
            "district": "17",
            "Block": "132",
            "label": "PUMS NARIYANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1745",
            "district": "17",
            "Block": "132",
            "label": "PUMS KUNDUNINADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1806",
            "district": "17",
            "Block": "132",
            "label": "PUMS BAILNADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2098",
            "district": "17",
            "Block": "132",
            "label": "PUMS NADUVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2110",
            "district": "17",
            "Block": "132",
            "label": "PUMS PERUMPARAPPUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2127",
            "district": "17",
            "Block": "132",
            "label": "PUMS THIRUPULINADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2260",
            "district": "17",
            "Block": "132",
            "label": "PUMS PERAKKARAINADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2354",
            "district": "17",
            "Block": "132",
            "label": "PUMS VASALURPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2512",
            "district": "17",
            "Block": "132",
            "label": "PUMS PUNGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2620",
            "district": "17",
            "Block": "132",
            "label": "PUMS VALAPPURNADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4243",
            "district": "17",
            "Block": "132",
            "label": "DHARMAVIDYALAYA MATRI HIGH SCHOOL KOLLIHILLS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4418",
            "district": "17",
            "Block": "132",
            "label": "HILLDALE MATRICULATION HIGHER SECONDARY SCHOOL, VASALURPATTI,KOLLIMALAI,NAMAKKAL DT -637411",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11789",
            "district": "17",
            "Block": "132",
            "label": "GTR HSS SENGARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11790",
            "district": "17",
            "Block": "132",
            "label": "KGBV NATHUKULIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11791",
            "district": "17",
            "Block": "132",
            "label": "GHS NATHUKULIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11792",
            "district": "17",
            "Block": "132",
            "label": "GHSS ARIYURPUDUVALAVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11793",
            "district": "17",
            "Block": "132",
            "label": "KGBV ARIYUR KILAKKUVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11794",
            "district": "17",
            "Block": "132",
            "label": "MODEL SCHOOL, KOLLIHILLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11795",
            "district": "17",
            "Block": "132",
            "label": "GTR HSS VALAVANTHINADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11796",
            "district": "17",
            "Block": "132",
            "label": "KGBV SELURNADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21461",
            "district": "17",
            "Block": "132",
            "label": "GHS SELURNADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22446",
            "district": "17",
            "Block": "132",
            "label": "GOVT.EKLAVYA MODEL RESIDENTIAL HR.SEC.SCHOOL, SENKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22966",
            "district": "17",
            "Block": "132",
            "label": "GHS, SEPPANKULAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23255",
            "district": "17",
            "Block": "132",
            "label": "GOVERNMENT HIGH SCHOOL, ALATHURNADU",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "133": [
        {
            "key": "3971",
            "district": "22",
            "Block": "133",
            "label": "A.G.N. MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11086",
            "district": "22",
            "Block": "133",
            "label": "PUMS MUTHIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11087",
            "district": "22",
            "Block": "133",
            "label": "PUMS PUDHUPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11088",
            "district": "22",
            "Block": "133",
            "label": "PUMS ARISIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11089",
            "district": "22",
            "Block": "133",
            "label": "PUMS MANICKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11090",
            "district": "22",
            "Block": "133",
            "label": "GHSS SAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11091",
            "district": "22",
            "Block": "133",
            "label": "PUMS V.METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11092",
            "district": "22",
            "Block": "133",
            "label": "PUMS PACHALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11093",
            "district": "22",
            "Block": "133",
            "label": "PUMS MUNIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11094",
            "district": "22",
            "Block": "133",
            "label": "GHSS V.METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11095",
            "district": "22",
            "Block": "133",
            "label": "MODEL SCHOOL,KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11096",
            "district": "22",
            "Block": "133",
            "label": "GHS VELLALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11097",
            "district": "22",
            "Block": "133",
            "label": "GHS KANNIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11098",
            "district": "22",
            "Block": "133",
            "label": "PUMS DEVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11099",
            "district": "22",
            "Block": "133",
            "label": "PUMS RAYANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11100",
            "district": "22",
            "Block": "133",
            "label": "PUMS KOMBAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11101",
            "district": "22",
            "Block": "133",
            "label": "GHS KORANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11102",
            "district": "22",
            "Block": "133",
            "label": "PUMS ALAGAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11103",
            "district": "22",
            "Block": "133",
            "label": "PUMS K.VADUKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11104",
            "district": "22",
            "Block": "133",
            "label": "PUMS K.PALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11105",
            "district": "22",
            "Block": "133",
            "label": "PUMS THONDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11106",
            "district": "22",
            "Block": "133",
            "label": "PUMS KONANKIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11107",
            "district": "22",
            "Block": "133",
            "label": "PUMS ETTIKUTTAI MEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11108",
            "district": "22",
            "Block": "133",
            "label": "GHS KATCHUPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11109",
            "district": "22",
            "Block": "133",
            "label": "PUMS KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11110",
            "district": "22",
            "Block": "133",
            "label": "PUMS RAKKIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11111",
            "district": "22",
            "Block": "133",
            "label": "GGHSS, KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11112",
            "district": "22",
            "Block": "133",
            "label": "GBHSS, KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11113",
            "district": "22",
            "Block": "133",
            "label": "K.G.B.V. KONGANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11114",
            "district": "22",
            "Block": "133",
            "label": "PUMS NACHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11115",
            "district": "22",
            "Block": "133",
            "label": "PUMS VENDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11116",
            "district": "22",
            "Block": "133",
            "label": "PUMS THANGAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11117",
            "district": "22",
            "Block": "133",
            "label": "PUMS KONANGKUTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21065",
            "district": "22",
            "Block": "133",
            "label": "WINNAR VIKAS INTERNATIONAL SCHOOL (CBSE), KORANAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21307",
            "district": "22",
            "Block": "133",
            "label": "APPU ARIVAALAYEM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21911",
            "district": "22",
            "Block": "133",
            "label": "C.M.MATRIC SCHOOL, RANGAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21912",
            "district": "22",
            "Block": "133",
            "label": "UNIVERSAL MATRIC HR. SEC. SCHOOL, KONAKUTTAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23247",
            "district": "22",
            "Block": "133",
            "label": "GHS SANARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23543",
            "district": "22",
            "Block": "133",
            "label": "STANFORD MATRICULATION SCHOOL, ETTIKUTTAI MEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23543",
            "district": "22",
            "Block": "133",
            "label": "STANFORD MATRICULATION SCHOOL, ETTIKUTTAI MEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "134": [
        {
            "key": "6308",
            "district": "35",
            "Block": "134",
            "label": "PUMS KEERANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16067",
            "district": "35",
            "Block": "134",
            "label": "UAMS SHIKSHAYATAN , ARASAVANANKADU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16068",
            "district": "35",
            "Block": "134",
            "label": "AMS,MUGUNTHANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16069",
            "district": "35",
            "Block": "134",
            "label": "AMS PATTUDAIYANIRRIPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16070",
            "district": "35",
            "Block": "134",
            "label": "PUMS, KONDAIYANIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16071",
            "district": "35",
            "Block": "134",
            "label": "PUMS ETTIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16072",
            "district": "35",
            "Block": "134",
            "label": "PUMS VADAKANDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16073",
            "district": "35",
            "Block": "134",
            "label": "PUMS ABIVIRUTHEESWARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16074",
            "district": "35",
            "Block": "134",
            "label": "AHSS,SEMBANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16075",
            "district": "35",
            "Block": "134",
            "label": "PUMS PERUMPUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16076",
            "district": "35",
            "Block": "134",
            "label": "GHS,MANAKKAL AYYAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16077",
            "district": "35",
            "Block": "134",
            "label": "PUMS, VANDAMPALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16078",
            "district": "35",
            "Block": "134",
            "label": "GHS,ENGAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16079",
            "district": "35",
            "Block": "134",
            "label": "GHS,SELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16080",
            "district": "35",
            "Block": "134",
            "label": "PUMS PAVITHRAMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16081",
            "district": "35",
            "Block": "134",
            "label": "UAMS CAMPPAIGN  MATRICULATION  SCHOOL ,PAVITHRAMANICKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16082",
            "district": "35",
            "Block": "134",
            "label": "AMS PANNAIVILAGAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16083",
            "district": "35",
            "Block": "134",
            "label": "GHS,ATHIKKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16084",
            "district": "35",
            "Block": "134",
            "label": "PUMS THIRUVIDAIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16085",
            "district": "35",
            "Block": "134",
            "label": "PUMS AMMAIYAPPAN EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16086",
            "district": "35",
            "Block": "134",
            "label": "PUMS, AMMAIYAPPAN WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16087",
            "district": "35",
            "Block": "134",
            "label": "GHSS,AMMAIYAPPAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16088",
            "district": "35",
            "Block": "134",
            "label": "GHS,KANKODUTHAVANITHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16089",
            "district": "35",
            "Block": "134",
            "label": "PUMS VENNAVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16090",
            "district": "35",
            "Block": "134",
            "label": "PUMS KILARIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16091",
            "district": "35",
            "Block": "134",
            "label": "AMS PATHUR MELKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16092",
            "district": "35",
            "Block": "134",
            "label": "PUMS KULIKKARAI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16093",
            "district": "35",
            "Block": "134",
            "label": "GHSS,KULIKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16094",
            "district": "35",
            "Block": "134",
            "label": "PUMS, CHETTISIMIZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16095",
            "district": "35",
            "Block": "134",
            "label": "PUMS THIRUKKANNAMANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16096",
            "district": "35",
            "Block": "134",
            "label": "PUMS MELARATHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16097",
            "district": "35",
            "Block": "134",
            "label": "GHSS,KATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16098",
            "district": "35",
            "Block": "134",
            "label": "PUMS ANAIVADAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16099",
            "district": "35",
            "Block": "134",
            "label": "PUMS ERUKKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16100",
            "district": "35",
            "Block": "134",
            "label": "PUMS MELATHIRUMATHIKUNNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16101",
            "district": "35",
            "Block": "134",
            "label": "PUMS KODIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16102",
            "district": "35",
            "Block": "134",
            "label": "PUMS MUSIRIAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16103",
            "district": "35",
            "Block": "134",
            "label": "GMS DEVARKANDANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16104",
            "district": "35",
            "Block": "134",
            "label": "GGHSS,KORADACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16105",
            "district": "35",
            "Block": "134",
            "label": "GBHSS ,KORADACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16106",
            "district": "35",
            "Block": "134",
            "label": "UAHS UNITED MATRIC,KORADACHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20989",
            "district": "35",
            "Block": "134",
            "label": "KV  KENDRIYA VIDYALAYA THIRUVARUR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21609",
            "district": "35",
            "Block": "134",
            "label": "CBSE VIVEKANANDAM VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22570",
            "district": "35",
            "Block": "134",
            "label": "UAHSS OYSTER  LEARNING   INITIATIVE  MATRICULATION SCHOOL  ,AMMAIYAPPAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23625",
            "district": "35",
            "Block": "134",
            "label": "TIRUVARUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23831",
            "district": "35",
            "Block": "134",
            "label": "PUMS KODIMANGALAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23832",
            "district": "35",
            "Block": "134",
            "label": "TIRUVARUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "135": [
        {
            "key": "5670",
            "district": "26",
            "Block": "135",
            "label": "ALPHA G.K. MATRICULATION HIGHER SECONDARY SCHOOL ARAVENU KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6257",
            "district": "26",
            "Block": "135",
            "label": "PUMS, KAIRBETA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6428",
            "district": "26",
            "Block": "135",
            "label": "HRM MEMORIAL MATRIC SCHOOL HITTAKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12542",
            "district": "26",
            "Block": "135",
            "label": "GTR MS HALLIMOYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12543",
            "district": "26",
            "Block": "135",
            "label": "GHS THENGUMARAHADA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12544",
            "district": "26",
            "Block": "135",
            "label": "PUMS, KERADAMATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12545",
            "district": "26",
            "Block": "135",
            "label": "GHS, MILIDHANE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12546",
            "district": "26",
            "Block": "135",
            "label": "GHSS NEDUGULA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12547",
            "district": "26",
            "Block": "135",
            "label": "HILLFORT MATRICULATION & HIGHER SECONDARY SCHOOL, KEIRCOMBAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12548",
            "district": "26",
            "Block": "135",
            "label": "GOVERNMENT HIGH SCHOOL, KAIRCOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12549",
            "district": "26",
            "Block": "135",
            "label": "PUMS KADASOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12550",
            "district": "26",
            "Block": "135",
            "label": "GHS SHOLURMATTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12551",
            "district": "26",
            "Block": "135",
            "label": "ST.ANTONY'S MATRICULATION SCHOOL, SHOLURMATTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12552",
            "district": "26",
            "Block": "135",
            "label": "GHSS, KIL-KOTAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12553",
            "district": "26",
            "Block": "135",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL  HIGH SCHOOL, KARIKKAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12554",
            "district": "26",
            "Block": "135",
            "label": "GHS, KENGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12555",
            "district": "26",
            "Block": "135",
            "label": "GHS KONAVAKORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12556",
            "district": "26",
            "Block": "135",
            "label": "PUMS, GUNDADA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12557",
            "district": "26",
            "Block": "135",
            "label": "GHS, KATTABETU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12558",
            "district": "26",
            "Block": "135",
            "label": "PUMS, SACKATHA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12559",
            "district": "26",
            "Block": "135",
            "label": "GTR MS, ARAVENU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12560",
            "district": "26",
            "Block": "135",
            "label": "GHS, KOTTACOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12561",
            "district": "26",
            "Block": "135",
            "label": "GHS ARAVENU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12562",
            "district": "26",
            "Block": "135",
            "label": "GTR HSS KUNJAPPANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12563",
            "district": "26",
            "Block": "135",
            "label": "PUMS, HORASHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12564",
            "district": "26",
            "Block": "135",
            "label": "ST.ANTONY'S MS, KOTAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12565",
            "district": "26",
            "Block": "135",
            "label": "NALLAYAN MS, KOTAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12566",
            "district": "26",
            "Block": "135",
            "label": "GHS, KANNERIMUKU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12567",
            "district": "26",
            "Block": "135",
            "label": "ST.MARY'S HS, KOTAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12568",
            "district": "26",
            "Block": "135",
            "label": "VISWASHANTHI VIDYALAYA MATRIC SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12569",
            "district": "26",
            "Block": "135",
            "label": "GHSS KOTAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12570",
            "district": "26",
            "Block": "135",
            "label": "CSI HSS KOTAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12571",
            "district": "26",
            "Block": "135",
            "label": "ST.MARYS GIRLS HSS KOTAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12572",
            "district": "26",
            "Block": "135",
            "label": "ST.JUDES PUBLIC SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12573",
            "district": "26",
            "Block": "135",
            "label": "KOTAGIRI PUBLIC SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12574",
            "district": "26",
            "Block": "135",
            "label": "PANDIARAJ MEMORIAL MATRIC HIGHER SECONDARY SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12575",
            "district": "26",
            "Block": "135",
            "label": "GREEN VALLEY MATRICULATION SCHOOL KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12576",
            "district": "26",
            "Block": "135",
            "label": "ST.MARY'S HOME MAT.SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12577",
            "district": "26",
            "Block": "135",
            "label": "RIVERSIDE PUBLIC SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12578",
            "district": "26",
            "Block": "135",
            "label": "SATHIYAKAATHI GLOBAL SCHOOL, KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21220",
            "district": "26",
            "Block": "135",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  ELADA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21867",
            "district": "26",
            "Block": "135",
            "label": "INTERNATIONAL COMMUNITY SCHOOL KOTAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22746",
            "district": "26",
            "Block": "135",
            "label": "BEST PUBLIC SCHOOL, KADAKODU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22991",
            "district": "26",
            "Block": "135",
            "label": "GOVERNMENT HIGH SCHOOL, SULLIGUDU",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "136": [
        {
            "key": "17948",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, SOORAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17949",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, PALLAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17950",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, POTTAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17951",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S THONTHILINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17952",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, SOKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17953",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S MANAPPACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17954",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S M.VELLALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17955",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S KUNNARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17956",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, MANGALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17957",
            "district": "14",
            "Block": "136",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OTTAKKOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17958",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S S.MALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17959",
            "district": "14",
            "Block": "136",
            "label": "GOVT GIRLS HR SEC SCHOOL, KOTTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17960",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, KOTTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17961",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, CHOKKALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17962",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S MANALMETUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17963",
            "district": "14",
            "Block": "136",
            "label": "GOVT GIRLS HR SC SCHOOL, KARUNGALAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17964",
            "district": "14",
            "Block": "136",
            "label": "GOVERNMENT Hr. Sec. SCHOOL, KARUNGALAKUDI, Madurai Dist.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17965",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S VANJINAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17966",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, MANAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17967",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, THUMBAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17968",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S THAMARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17969",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S BOOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17970",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S ATTAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17971",
            "district": "14",
            "Block": "136",
            "label": "GOVT HIGH SCHOOL, KODUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17972",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S ETTIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17973",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S SENNAGARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17974",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, MELAVALAVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17975",
            "district": "14",
            "Block": "136",
            "label": "P.U.M.S PATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17976",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, KAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17977",
            "district": "14",
            "Block": "136",
            "label": "GOVT HR SEC SCHOOL, SEKKIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21285",
            "district": "14",
            "Block": "136",
            "label": "ONE TO THRE WONDER CONVENT MATRICULATION SCHOOL, VALAISERIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22779",
            "district": "14",
            "Block": "136",
            "label": "NAVA INDIA CHINESE MATRICULATION SCHOOL, ALAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "137": [
        {
            "key": "5061",
            "district": "35",
            "Block": "137",
            "label": "PUMS, IRULNEEKI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5106",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MELAKANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5143",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KUMMATTITHIDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5459",
            "district": "35",
            "Block": "137",
            "label": "PUMS, SENTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5786",
            "district": "35",
            "Block": "137",
            "label": "PUMS, BALAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6059",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KANTHARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6179",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MAHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6183",
            "district": "35",
            "Block": "137",
            "label": "PUMS, VENGATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6189",
            "district": "35",
            "Block": "137",
            "label": "PUMS, VALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6237",
            "district": "35",
            "Block": "137",
            "label": "PUMS, PERUGAVALNTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6495",
            "district": "35",
            "Block": "137",
            "label": "PUMS, PERIYAKURUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6541",
            "district": "35",
            "Block": "137",
            "label": "PUMS, PAINGATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16261",
            "district": "35",
            "Block": "137",
            "label": "PUMS, ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16262",
            "district": "35",
            "Block": "137",
            "label": "AMS, ST ANNE'S MIDDLE SCHOOL DEVATHANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16263",
            "district": "35",
            "Block": "137",
            "label": "GHSS,KALAPPAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16264",
            "district": "35",
            "Block": "137",
            "label": "PUMS, NARUVALI KALAPPAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16265",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KEZHUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16266",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KARUPPUKILAR GANESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16267",
            "district": "35",
            "Block": "137",
            "label": "GGHSS,KOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16268",
            "district": "35",
            "Block": "137",
            "label": "GHSS,KOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16269",
            "district": "35",
            "Block": "137",
            "label": "PUMS, 83.KULAMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16270",
            "district": "35",
            "Block": "137",
            "label": "GHSS,PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16271",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KUNNIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16272",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16273",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MANNUKUMUNDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16274",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MAVATTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16275",
            "district": "35",
            "Block": "137",
            "label": "PUMS, MELANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16276",
            "district": "35",
            "Block": "137",
            "label": "GHS,MAZHAVARAYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16277",
            "district": "35",
            "Block": "137",
            "label": "PUMS, NOCHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16278",
            "district": "35",
            "Block": "137",
            "label": "PUMS, ORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16279",
            "district": "35",
            "Block": "137",
            "label": "GHSS ,PALAYAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16280",
            "district": "35",
            "Block": "137",
            "label": "GHS,MELAPANAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16281",
            "district": "35",
            "Block": "137",
            "label": "GHSS,PERUGAVAZHNDAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16282",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16283",
            "district": "35",
            "Block": "137",
            "label": "GHS,PALAYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16284",
            "district": "35",
            "Block": "137",
            "label": "PUMS, RENGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16285",
            "district": "35",
            "Block": "137",
            "label": "GHS,SERUVAMANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16286",
            "district": "35",
            "Block": "137",
            "label": "GHS,THENPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16287",
            "district": "35",
            "Block": "137",
            "label": "GHS,THERKUNANALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16288",
            "district": "35",
            "Block": "137",
            "label": "GHSS(B) THIRUMAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16289",
            "district": "35",
            "Block": "137",
            "label": "GGHSS  THIRUMAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16290",
            "district": "35",
            "Block": "137",
            "label": "GHS,THIRUKKALAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16291",
            "district": "35",
            "Block": "137",
            "label": "PUMS, THIRUNELLIKAVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16292",
            "district": "35",
            "Block": "137",
            "label": "GHS,VATTAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16293",
            "district": "35",
            "Block": "137",
            "label": "GHS,VIKIRAPANDIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21223",
            "district": "35",
            "Block": "137",
            "label": "GHSS, RADHANARASIMMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21538",
            "district": "35",
            "Block": "137",
            "label": "AHS , ST ANTONY'S HIGH SCHOOL ADICHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "23300",
            "district": "35",
            "Block": "137",
            "label": "UAMS KALAIVANI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23833",
            "district": "35",
            "Block": "137",
            "label": "PUMS, ALATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23834",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KURICHI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23835",
            "district": "35",
            "Block": "137",
            "label": "PUMS, KUMARAMANGALAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23836",
            "district": "35",
            "Block": "137",
            "label": "PUMS, RENGANATHAPURAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "138": [
        {
            "key": "308",
            "district": "28",
            "Block": "138",
            "label": "AV HSS,KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "309",
            "district": "28",
            "Block": "138",
            "label": "AUXILIUM MATRIC HR.SEC.SCHOOL ,KEELA ERAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "310",
            "district": "28",
            "Block": "138",
            "label": "CKT Matric Hr.Sec.School  KUMARAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "311",
            "district": "28",
            "Block": "138",
            "label": "COUNIAN MAT. HR SEC SCHOOL , KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "312",
            "district": "28",
            "Block": "138",
            "label": "EDUSTAR INTERNATIONAL SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "313",
            "district": "28",
            "Block": "138",
            "label": "EVA VALLIMUTHU HS, KOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "314",
            "district": "28",
            "Block": "138",
            "label": "EVEREST MARIAPPA NADAR HSS, KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "315",
            "district": "28",
            "Block": "138",
            "label": "EVEREST MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "316",
            "district": "28",
            "Block": "138",
            "label": "M.B.C.M Govt Girls Hr.Sec. School, ETTAYAPURAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "317",
            "district": "28",
            "Block": "138",
            "label": "G(G)HSS, KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "318",
            "district": "28",
            "Block": "138",
            "label": "GHS, LOYALMILL COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "319",
            "district": "28",
            "Block": "138",
            "label": "GHSS, UTHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "320",
            "district": "28",
            "Block": "138",
            "label": "GHSS, VILLISERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "321",
            "district": "28",
            "Block": "138",
            "label": "GHS, VIJAYAPURI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "322",
            "district": "28",
            "Block": "138",
            "label": "ICM MS, KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "323",
            "district": "28",
            "Block": "138",
            "label": "ILLATHAR MIDDLE SCHOOL , PUDUGRAMAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "324",
            "district": "28",
            "Block": "138",
            "label": "JOHN BOSCO MATRIC HSS, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "325",
            "district": "28",
            "Block": "138",
            "label": "KAMARAJ MATRIC HSS, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "326",
            "district": "28",
            "Block": "138",
            "label": "KOVILPATTI NADAR KAMARAJ MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "327",
            "district": "28",
            "Block": "138",
            "label": "Kammavar (Girls) Hr.Sec.School, KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "328",
            "district": "28",
            "Block": "138",
            "label": "SRI KANNA MATRIC HR SEC SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "329",
            "district": "28",
            "Block": "138",
            "label": "KRA VIDHYASHRAM MATRIC HSS, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "330",
            "district": "28",
            "Block": "138",
            "label": "KR SARATHA GOVT HR SEC SCHOOL, NALATINPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "331",
            "district": "28",
            "Block": "138",
            "label": "LAKSHMI SRINIVASA VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "332",
            "district": "28",
            "Block": "138",
            "label": "LAKSHMI MILLS HIGHER SECONDARY SCHOOL KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "333",
            "district": "28",
            "Block": "138",
            "label": "LOYAL TEXTILE MILLS MS, KOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "334",
            "district": "28",
            "Block": "138",
            "label": "Maharisi Vidyasram Matriculation School, Kovilpatti",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "335",
            "district": "28",
            "Block": "138",
            "label": "MARIAPPA DVMS, ETTAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "336",
            "district": "28",
            "Block": "138",
            "label": "MUNICIPAL HS GANDHI NAGAR, KOVILPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "337",
            "district": "28",
            "Block": "138",
            "label": "MUNICIPAL MS, NEW ROAD, KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "338",
            "district": "28",
            "Block": "138",
            "label": "MUNICIPAL MS,BHARATHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "339",
            "district": "28",
            "Block": "138",
            "label": "NADAR HSS, KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "340",
            "district": "28",
            "Block": "138",
            "label": "NADAR M.S.,KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "341",
            "district": "28",
            "Block": "138",
            "label": "PARIMELZHAGAR HMS, ERACHI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "342",
            "district": "28",
            "Block": "138",
            "label": "PARVATHI HS,ILLUPPAIYURANI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "343",
            "district": "28",
            "Block": "138",
            "label": "PUMS,KILAVIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "344",
            "district": "28",
            "Block": "138",
            "label": "PUMS, MELAERAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "345",
            "district": "28",
            "Block": "138",
            "label": "PUMS, SURAIKAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "346",
            "district": "28",
            "Block": "138",
            "label": "PUMS, THITTANKULAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "347",
            "district": "28",
            "Block": "138",
            "label": "PUNITHA OHM MATRICULATION SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "348",
            "district": "28",
            "Block": "138",
            "label": "PUMS,MEENATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "349",
            "district": "28",
            "Block": "138",
            "label": "PUMS, ATHIKINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "350",
            "district": "28",
            "Block": "138",
            "label": "PUMS, NORTH SEMAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "351",
            "district": "28",
            "Block": "138",
            "label": "PUMS SINTHALAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "352",
            "district": "28",
            "Block": "138",
            "label": "PUMS,URULAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "353",
            "district": "28",
            "Block": "138",
            "label": "RAJA HSS,THEATRE ROAD, ETTAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "354",
            "district": "28",
            "Block": "138",
            "label": "RC MIDDLE SCHOOL, KASAVANKUNTRU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "355",
            "district": "28",
            "Block": "138",
            "label": "RC MIDDLE SCHOOL, LINGAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "356",
            "district": "28",
            "Block": "138",
            "label": "SDA MATRIC HSS, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "357",
            "district": "28",
            "Block": "138",
            "label": "SENAIAR KALAIMAGAL HS, ERACHI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "358",
            "district": "28",
            "Block": "138",
            "label": "SENGUNTHAR HS, KADALAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "359",
            "district": "28",
            "Block": "138",
            "label": "SENGUNTHAR MS,NADUVIRPATTI, ETTAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "360",
            "district": "28",
            "Block": "138",
            "label": "SFS, NALATINPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "361",
            "district": "28",
            "Block": "138",
            "label": "SRMS MATRICULATION HIGHER SECONDARY SCHOOL, SINTHALAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "362",
            "district": "28",
            "Block": "138",
            "label": "ST. ANDREWS MATRICULATION SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "363",
            "district": "28",
            "Block": "138",
            "label": "ST. JOSEPH'S HSS, KOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "364",
            "district": "28",
            "Block": "138",
            "label": "St.Paul's Matriculation Higher Secondary School, Kovilpatti",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "365",
            "district": "28",
            "Block": "138",
            "label": "TNDTA MIDDLE SCHOOL, KADALAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "366",
            "district": "28",
            "Block": "138",
            "label": "UP MATRICULATION HR.SEC. SCHOOL, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "367",
            "district": "28",
            "Block": "138",
            "label": "VAMR HINDU NADAR MIDDLE SCHOOL, MELA ERAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "368",
            "district": "28",
            "Block": "138",
            "label": "VANIGA VAISIA M.S.,KOVILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "369",
            "district": "28",
            "Block": "138",
            "label": "VEL VELLAICHAMY MS, KEELA IRAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "370",
            "district": "28",
            "Block": "138",
            "label": "VISWAKARMA HS, KOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "371",
            "district": "28",
            "Block": "138",
            "label": "VOC GHSS, KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21134",
            "district": "28",
            "Block": "138",
            "label": "PUMS, IDAISEVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21192",
            "district": "28",
            "Block": "138",
            "label": "GHSS, ILAYARASANENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21193",
            "district": "28",
            "Block": "138",
            "label": "GHSS, PILLAIYARNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21226",
            "district": "28",
            "Block": "138",
            "label": "SHYAMALA H.S.S.,ILUPPIYURANI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21822",
            "district": "28",
            "Block": "138",
            "label": "SHRI PAPER ANANDA MATRICULATION  SCHOOL ILAYARASANENDAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22637",
            "district": "28",
            "Block": "138",
            "label": "HOLY TRINITY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22638",
            "district": "28",
            "Block": "138",
            "label": "VELS VIDHYALAYA .CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22639",
            "district": "28",
            "Block": "138",
            "label": "VEERABAGU VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23218",
            "district": "28",
            "Block": "138",
            "label": "RAVILLA VIDYALAYA - CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23257",
            "district": "28",
            "Block": "138",
            "label": "GHS ILAMBUVANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23284",
            "district": "28",
            "Block": "138",
            "label": "KARIDHA PUBLIC SCHOOL, ETTAYAPURAM ROAD, KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23317",
            "district": "28",
            "Block": "138",
            "label": "KAMARAJ INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23572",
            "district": "28",
            "Block": "138",
            "label": "LAKSHMI MILLS MATRICULATION SCHOOL , INAMMANIACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23573",
            "district": "28",
            "Block": "138",
            "label": "PUNITHA OHM GLOBAL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23574",
            "district": "28",
            "Block": "138",
            "label": "BRINDAVAN'S KIDS UNIVERSITY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23572",
            "district": "28",
            "Block": "138",
            "label": "LAKSHMI MILLS MATRICULATION SCHOOL , INAMMANIACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23573",
            "district": "28",
            "Block": "138",
            "label": "PUNITHA OHM GLOBAL CBSE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23574",
            "district": "28",
            "Block": "138",
            "label": "BRINDAVAN'S KIDS UNIVERSITY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "139": [
        {
            "key": "1082",
            "district": "13",
            "Block": "139",
            "label": "DON BOSCO MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1250",
            "district": "13",
            "Block": "139",
            "label": "TRINITY MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1437",
            "district": "13",
            "Block": "139",
            "label": "SRI VIJAY VIDYALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1770",
            "district": "13",
            "Block": "139",
            "label": "D.K.SAMY MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3910",
            "district": "13",
            "Block": "139",
            "label": "MTV MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4245",
            "district": "13",
            "Block": "139",
            "label": "SRI SAI KRISHNA VIDYAGIRI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4922",
            "district": "13",
            "Block": "139",
            "label": "TCR MATRIC  SCHOOL RAYAKOTTAI ROAD KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5673",
            "district": "13",
            "Block": "139",
            "label": "BHARAT MATRIC HSS, KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6352",
            "district": "13",
            "Block": "139",
            "label": "NALANDA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20455",
            "district": "13",
            "Block": "139",
            "label": "GHS ITTIKKALAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20456",
            "district": "13",
            "Block": "139",
            "label": "PUMS AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20457",
            "district": "13",
            "Block": "139",
            "label": "GHSS ALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20458",
            "district": "13",
            "Block": "139",
            "label": "PUMS BALAGURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20459",
            "district": "13",
            "Block": "139",
            "label": "PUMS BALIGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20460",
            "district": "13",
            "Block": "139",
            "label": "PUMS BELLAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20461",
            "district": "13",
            "Block": "139",
            "label": "GHS BELLARAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20462",
            "district": "13",
            "Block": "139",
            "label": "GHS BETTEPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20463",
            "district": "13",
            "Block": "139",
            "label": "PUMS BOGANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20464",
            "district": "13",
            "Block": "139",
            "label": "D.K.SAMY MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20465",
            "district": "13",
            "Block": "139",
            "label": "GHS CHIKKAPOOVATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20466",
            "district": "13",
            "Block": "139",
            "label": "GHSS CHINNAMELUPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20467",
            "district": "13",
            "Block": "139",
            "label": "PUMS DHASIRIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20468",
            "district": "13",
            "Block": "139",
            "label": "PUMS EKKALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20469",
            "district": "13",
            "Block": "139",
            "label": "GHS GANGALERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20470",
            "district": "13",
            "Block": "139",
            "label": "GHS GIDDAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20471",
            "district": "13",
            "Block": "139",
            "label": "PUMS GOLLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20472",
            "district": "13",
            "Block": "139",
            "label": "GHS GUMMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20473",
            "district": "13",
            "Block": "139",
            "label": "PUMS K.A.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20474",
            "district": "13",
            "Block": "139",
            "label": "GHS JAGIR VENKATAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20475",
            "district": "13",
            "Block": "139",
            "label": "GHSS K.R.P.DAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20476",
            "district": "13",
            "Block": "139",
            "label": "PUMS KALLAKURUKKI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20477",
            "district": "13",
            "Block": "139",
            "label": "GHS KAMMAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20478",
            "district": "13",
            "Block": "139",
            "label": "TES HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20479",
            "district": "13",
            "Block": "139",
            "label": "VIJAY MILLINIUM CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20480",
            "district": "13",
            "Block": "139",
            "label": "PUMS KOTHUR GIRLS (U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20481",
            "district": "13",
            "Block": "139",
            "label": "SVC MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20482",
            "district": "13",
            "Block": "139",
            "label": "GHS MADEPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20483",
            "district": "13",
            "Block": "139",
            "label": "GHS MAHARAJAKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20484",
            "district": "13",
            "Block": "139",
            "label": "GHS MADHINAYANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20485",
            "district": "13",
            "Block": "139",
            "label": "GHS MEKALACHINNAMPALLI (GIRLS)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20486",
            "district": "13",
            "Block": "139",
            "label": "KAMARAJ GHSS  MEKALACHINNAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20487",
            "district": "13",
            "Block": "139",
            "label": "PUMS MELERIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20488",
            "district": "13",
            "Block": "139",
            "label": "GHS MITTAPALLI(KRI)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20489",
            "district": "13",
            "Block": "139",
            "label": "PUMS NARALAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20490",
            "district": "13",
            "Block": "139",
            "label": "PUMS OBELESAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20491",
            "district": "13",
            "Block": "139",
            "label": "PUMS PEDDATHALAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20492",
            "district": "13",
            "Block": "139",
            "label": "CAMBRIDGE MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20493",
            "district": "13",
            "Block": "139",
            "label": "GHS PERIYAKOTTAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20494",
            "district": "13",
            "Block": "139",
            "label": "PUMS PERIYATHAKKEPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20495",
            "district": "13",
            "Block": "139",
            "label": "GHS POTHINAYANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20496",
            "district": "13",
            "Block": "139",
            "label": "PUMS R.POOSARIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20497",
            "district": "13",
            "Block": "139",
            "label": "GHS SOKKADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20498",
            "district": "13",
            "Block": "139",
            "label": "PUMS THUDUGANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20499",
            "district": "13",
            "Block": "139",
            "label": "PUMS THURINJIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20500",
            "district": "13",
            "Block": "139",
            "label": "PUMS VELAGALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20501",
            "district": "13",
            "Block": "139",
            "label": "GHSS KANAGAMUTLU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20502",
            "district": "13",
            "Block": "139",
            "label": "PUMS BANDARAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20503",
            "district": "13",
            "Block": "139",
            "label": "GHS K.POOSARIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20504",
            "district": "13",
            "Block": "139",
            "label": "GHS NEKKUNDHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20505",
            "district": "13",
            "Block": "139",
            "label": "GHS PACHIGANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20506",
            "district": "13",
            "Block": "139",
            "label": "PUMS SEMBADAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20507",
            "district": "13",
            "Block": "139",
            "label": "MMS ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20508",
            "district": "13",
            "Block": "139",
            "label": "MMS DOWLATABAD (U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20509",
            "district": "13",
            "Block": "139",
            "label": "MMS GANDHI ROAD (U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20510",
            "district": "13",
            "Block": "139",
            "label": "MMS OLD PET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20511",
            "district": "13",
            "Block": "139",
            "label": "IELC AIDED MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20512",
            "district": "13",
            "Block": "139",
            "label": "MMS FORT GIRLS (U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20513",
            "district": "13",
            "Block": "139",
            "label": "MMS OLD SUB JAIL ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20514",
            "district": "13",
            "Block": "139",
            "label": "MMS RAJU STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20515",
            "district": "13",
            "Block": "139",
            "label": "MHSS OLDPET KRISHNAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20516",
            "district": "13",
            "Block": "139",
            "label": "R.C.FATIMA BOYS HS, KRISHNAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20517",
            "district": "13",
            "Block": "139",
            "label": "GHSS KRISHNAGIRI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20518",
            "district": "13",
            "Block": "139",
            "label": "GHSS KRISHNAGIRI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20519",
            "district": "13",
            "Block": "139",
            "label": "VAILANKANNI MATRIC HSS KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20520",
            "district": "13",
            "Block": "139",
            "label": "SWAMI VIVEKANANDA MATRIC HSS KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20521",
            "district": "13",
            "Block": "139",
            "label": "EDEN GARDEN ENGLISH SCHOOL KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20522",
            "district": "13",
            "Block": "139",
            "label": "MAHARISHI MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20523",
            "district": "13",
            "Block": "139",
            "label": "KRISHILAND MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20524",
            "district": "13",
            "Block": "139",
            "label": "ST.ANN'S GIRLS HSS KRISHNAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21126",
            "district": "13",
            "Block": "139",
            "label": "VAILANKANNI PUBLIC SCHOOL KRISHNAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21185",
            "district": "13",
            "Block": "139",
            "label": "NALANDA INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21292",
            "district": "13",
            "Block": "139",
            "label": "RIMS MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21346",
            "district": "13",
            "Block": "139",
            "label": "CAMBRIDGE PUBLIC e-SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21347",
            "district": "13",
            "Block": "139",
            "label": "BHARAT INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21420",
            "district": "13",
            "Block": "139",
            "label": "GHS KATTIGANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21628",
            "district": "13",
            "Block": "139",
            "label": "SRI VIJAY VIDYALAYA MATRIC SCHOOL OLDPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21629",
            "district": "13",
            "Block": "139",
            "label": "EXCEL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21807",
            "district": "13",
            "Block": "139",
            "label": "TRINITY ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22670",
            "district": "13",
            "Block": "139",
            "label": "THUVARAGA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22955",
            "district": "13",
            "Block": "139",
            "label": "GHS PEDDANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23501",
            "district": "13",
            "Block": "139",
            "label": "KRISHNAGIRI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23502",
            "district": "13",
            "Block": "139",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL KRISHNAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23502",
            "district": "13",
            "Block": "139",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL KRISHNAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23837",
            "district": "13",
            "Block": "139",
            "label": "SENTHIL PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23838",
            "district": "13",
            "Block": "139",
            "label": "SENTHIL MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23839",
            "district": "13",
            "Block": "139",
            "label": "ST. ANNE'S PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23840",
            "district": "13",
            "Block": "139",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "140": [
        {
            "key": "2022",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KOVAGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2424",
            "district": "12",
            "Block": "140",
            "label": "PUMS, PAPPAKKAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2718",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KUPPAMETTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23657",
            "district": "12",
            "Block": "140",
            "label": "Sri Krishna School ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "2884",
            "district": "12",
            "Block": "140",
            "label": "PUMS, IRUMBOOTHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3423",
            "district": "12",
            "Block": "140",
            "label": "PUMS , KRISHNARAYAPURAM (G)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3467",
            "district": "12",
            "Block": "140",
            "label": "PUMS,KAMBALIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3762",
            "district": "12",
            "Block": "140",
            "label": "SARASWATHY VIDYALAYA  MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4122",
            "district": "12",
            "Block": "140",
            "label": "PUMS, S.MUNAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4404",
            "district": "12",
            "Block": "140",
            "label": "PUMS, METTANKINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4405",
            "district": "12",
            "Block": "140",
            "label": "TANCEM MATRIC SCHOOL MAYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4515",
            "district": "12",
            "Block": "140",
            "label": "PUMS, VADAKKUKALLUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4516",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KEELADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5307",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5333",
            "district": "12",
            "Block": "140",
            "label": "PUMS VAYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5671",
            "district": "12",
            "Block": "140",
            "label": "PUMS, MAHADANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5682",
            "district": "12",
            "Block": "140",
            "label": "HOLY MADONA MATRIC HR.SEC.SCHOOL ,LALAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6330",
            "district": "12",
            "Block": "140",
            "label": "PUMS , KRISHNARAYAPURAM (B)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6347",
            "district": "12",
            "Block": "140",
            "label": "PUMS MANAVASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13940",
            "district": "12",
            "Block": "140",
            "label": "GHSS , VENGAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13941",
            "district": "12",
            "Block": "140",
            "label": "ADWMS , PILLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13942",
            "district": "12",
            "Block": "140",
            "label": "GHSS , LALAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13943",
            "district": "12",
            "Block": "140",
            "label": "PUMS , MATHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13944",
            "district": "12",
            "Block": "140",
            "label": "PUMS , LALAPET WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13945",
            "district": "12",
            "Block": "140",
            "label": "ANNAI INDRA MEMORIAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13946",
            "district": "12",
            "Block": "140",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , PUNAVASIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13947",
            "district": "12",
            "Block": "140",
            "label": "PUMS , METUMAHADANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13948",
            "district": "12",
            "Block": "140",
            "label": "PUMS , KUPPUREDDYPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13949",
            "district": "12",
            "Block": "140",
            "label": "GHSS , PALAYAJAYANKONDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13950",
            "district": "12",
            "Block": "140",
            "label": "PUMS , PICHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13951",
            "district": "12",
            "Block": "140",
            "label": "GHSS , KRISHNARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13952",
            "district": "12",
            "Block": "140",
            "label": "KSRV HIGHER SECONDARY SCHOOL , KOVAGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13953",
            "district": "12",
            "Block": "140",
            "label": "PUMS, MALAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13954",
            "district": "12",
            "Block": "140",
            "label": "GHS , METTU THIRUKAMPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13955",
            "district": "12",
            "Block": "140",
            "label": "GHS MAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13956",
            "district": "12",
            "Block": "140",
            "label": "GHS, KATTALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13957",
            "district": "12",
            "Block": "140",
            "label": "ADWHSS, CHINNAMANAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13958",
            "district": "12",
            "Block": "140",
            "label": "GHS, VEERARAKKIAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13959",
            "district": "12",
            "Block": "140",
            "label": "PUMS, SENGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13960",
            "district": "12",
            "Block": "140",
            "label": "GHSS, CHINNA SENKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13961",
            "district": "12",
            "Block": "140",
            "label": "PUMS, KANNAMUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13962",
            "district": "12",
            "Block": "140",
            "label": "PUMS, PANJAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13963",
            "district": "12",
            "Block": "140",
            "label": "GOVT MODEL  HR SEC  SCHOOL,  PANJAPATTY KARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13964",
            "district": "12",
            "Block": "140",
            "label": "PUMS,POTHURAVUTHANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13965",
            "district": "12",
            "Block": "140",
            "label": "GHS, MUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13966",
            "district": "12",
            "Block": "140",
            "label": "GHS, KATTARIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13967",
            "district": "12",
            "Block": "140",
            "label": "PUMS,KUPPACHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13968",
            "district": "12",
            "Block": "140",
            "label": "PUMS,VELANKATTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13969",
            "district": "12",
            "Block": "140",
            "label": "GHS, KUPPANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13970",
            "district": "12",
            "Block": "140",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , SENGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13971",
            "district": "12",
            "Block": "140",
            "label": "PUMS, GOUNDAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21503",
            "district": "12",
            "Block": "140",
            "label": "ADWHS, NANTHANKOTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22183",
            "district": "12",
            "Block": "140",
            "label": "GHS, K.OTTAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22513",
            "district": "12",
            "Block": "140",
            "label": "GHS, DESIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23149",
            "district": "12",
            "Block": "140",
            "label": "ISHA VIDHYA JUSTDIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23841",
            "district": "12",
            "Block": "140",
            "label": "Karur District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "141": [
        {
            "key": "2119",
            "district": "35",
            "Block": "141",
            "label": "PUMS, VADUKAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2679",
            "district": "35",
            "Block": "141",
            "label": "PUMS, SITHADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2760",
            "district": "35",
            "Block": "141",
            "label": "AMS, THETHIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3063",
            "district": "35",
            "Block": "141",
            "label": "PUMS, ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3456",
            "district": "35",
            "Block": "141",
            "label": "PUMS, SARABOJIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3652",
            "district": "35",
            "Block": "141",
            "label": "PUMS, PARUTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3766",
            "district": "35",
            "Block": "141",
            "label": "AMS, KODAVASAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3846",
            "district": "35",
            "Block": "141",
            "label": "PUMS, SARGUNESWARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4085",
            "district": "35",
            "Block": "141",
            "label": "PUMS, RADHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4138",
            "district": "35",
            "Block": "141",
            "label": "PUMS, 18.PUTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4299",
            "district": "35",
            "Block": "141",
            "label": "PUMS, NEMMELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4603",
            "district": "35",
            "Block": "141",
            "label": "AMS, MANAVALANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5070",
            "district": "35",
            "Block": "141",
            "label": "PUMS, KANGEYANAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16048",
            "district": "35",
            "Block": "141",
            "label": "GHS, PALLAVANATTHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16049",
            "district": "35",
            "Block": "141",
            "label": "GHSS MANANTANGUDI AYYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16050",
            "district": "35",
            "Block": "141",
            "label": "GHSS KANDIRAMANICKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16051",
            "district": "35",
            "Block": "141",
            "label": "GHS KOOTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16052",
            "district": "35",
            "Block": "141",
            "label": "GHSS ERAVANCHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16053",
            "district": "35",
            "Block": "141",
            "label": "AHSS, MANJAKKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16054",
            "district": "35",
            "Block": "141",
            "label": "UAHSS, MANJAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16055",
            "district": "35",
            "Block": "141",
            "label": "AMS,MOOLANKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16056",
            "district": "35",
            "Block": "141",
            "label": "UAMHS, MOOLANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16057",
            "district": "35",
            "Block": "141",
            "label": "GHS SENGALIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16058",
            "district": "35",
            "Block": "141",
            "label": "GHSS ENNAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16059",
            "district": "35",
            "Block": "141",
            "label": "GHS, THIRUVIDACHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16060",
            "district": "35",
            "Block": "141",
            "label": "GHS THIRUVIZHIMIZHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16061",
            "district": "35",
            "Block": "141",
            "label": "AHS, VADAMATTAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16062",
            "district": "35",
            "Block": "141",
            "label": "AMS,VIKKIRAPANDIYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16063",
            "district": "35",
            "Block": "141",
            "label": "AHSS,VISHNUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16064",
            "district": "35",
            "Block": "141",
            "label": "GBHSS, KUDAVASAL,AGARAOHAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16065",
            "district": "35",
            "Block": "141",
            "label": "GGHSS  KODAVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16066",
            "district": "35",
            "Block": "141",
            "label": "UAHSS SRI MAHADEVA  GURUJI  VIDHYALAYA MATRICULATION HIGHER  SECONDARYSCHOOL KUDAVASAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21536",
            "district": "35",
            "Block": "141",
            "label": "AHS, PERUMPANNAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22221",
            "district": "35",
            "Block": "141",
            "label": "GHS ,SEETHAKKAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23842",
            "district": "35",
            "Block": "141",
            "label": "PUMS, ALATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23843",
            "district": "35",
            "Block": "141",
            "label": "BLOOM THE NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "142": [
        {
            "key": "2561",
            "district": "12",
            "Block": "142",
            "label": "SRI KALAIMAGAL MATRIC HR SEC SCHOOL KULITHALAI KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3626",
            "district": "12",
            "Block": "142",
            "label": "SRI VIDYA BHARATHI MATRIC HIGHER SECONDARY. SCHOOL, KULITHALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3866",
            "district": "12",
            "Block": "142",
            "label": "AUROBINDO MATRIC HR SEC SCHOOL NACHALUR KARUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4049",
            "district": "12",
            "Block": "142",
            "label": "NALANTHA MATRICULATION SCHOOL,KARUNGALPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4449",
            "district": "12",
            "Block": "142",
            "label": "MOUNT GIRIS MATRIC HR SEC SCHOOL AYYARMALAI KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4504",
            "district": "12",
            "Block": "142",
            "label": "LITTLE FAIRIES MAT. SCHOOL, KULITHALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5036",
            "district": "12",
            "Block": "142",
            "label": "PUMS, KADAMBARKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5126",
            "district": "12",
            "Block": "142",
            "label": "PUMS, SIVAYAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5259",
            "district": "12",
            "Block": "142",
            "label": "SRI. VIVEKANANDA AIDED MIDDLE SCHOOL, KANDIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5524",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MANATHATTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5653",
            "district": "12",
            "Block": "142",
            "label": "ST. DOMINIC SAVIO MAT. SCHOOL, KULITHALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5700",
            "district": "12",
            "Block": "142",
            "label": "R.C. AMALARAKINI MID. SCHOOL, KULITHALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5710",
            "district": "12",
            "Block": "142",
            "label": "PUMS, SATHIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5788",
            "district": "12",
            "Block": "142",
            "label": "PUMS, BANGALAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5894",
            "district": "12",
            "Block": "142",
            "label": "RENGA AID. MID. SCHOOL, PARALI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5899",
            "district": "12",
            "Block": "142",
            "label": "PUMS, THALIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6454",
            "district": "12",
            "Block": "142",
            "label": "PUMS, THANEERPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6566",
            "district": "12",
            "Block": "142",
            "label": "PUMS, POYYAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6591",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MARUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14009",
            "district": "12",
            "Block": "142",
            "label": "PUMS, KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14010",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MELAKUPPUREDDIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14011",
            "district": "12",
            "Block": "142",
            "label": "THIRU.V.KA.AID.HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14012",
            "district": "12",
            "Block": "142",
            "label": "PUMS, ADINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14013",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MARIYAMMAN KOVIL, KULITHALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14014",
            "district": "12",
            "Block": "142",
            "label": "BHARATHI VIDYALAYA HIGH SCHOOL, KULITHALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14015",
            "district": "12",
            "Block": "142",
            "label": "GHSS, KULITHALAI (B)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14016",
            "district": "12",
            "Block": "142",
            "label": "GHSS, KULITHALAI (G)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14017",
            "district": "12",
            "Block": "142",
            "label": "PUMS, V. PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14018",
            "district": "12",
            "Block": "142",
            "label": "GOVT ADW HSS, KOTTAMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14019",
            "district": "12",
            "Block": "142",
            "label": "GHS, THIMMACHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14020",
            "district": "12",
            "Block": "142",
            "label": "GHS,THIMAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14021",
            "district": "12",
            "Block": "142",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14022",
            "district": "12",
            "Block": "142",
            "label": "PUMS,SUKKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14023",
            "district": "12",
            "Block": "142",
            "label": "PUMS, I.PUDUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14024",
            "district": "12",
            "Block": "142",
            "label": "GHSS, INUNGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14025",
            "district": "12",
            "Block": "142",
            "label": "PUMS, THIRUCHAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14026",
            "district": "12",
            "Block": "142",
            "label": "PUMS, NANGAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14027",
            "district": "12",
            "Block": "142",
            "label": "N.N.R.HIGH SCHOOL, NANGAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14028",
            "district": "12",
            "Block": "142",
            "label": "GHS, NACHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14029",
            "district": "12",
            "Block": "142",
            "label": "PUMS, KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14030",
            "district": "12",
            "Block": "142",
            "label": "PUMS, MADUVILUNDANPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14031",
            "district": "12",
            "Block": "142",
            "label": "GHSS, AYYARMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14032",
            "district": "12",
            "Block": "142",
            "label": "JOHN MAT. H.S., SIVAYAM EAST",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22515",
            "district": "12",
            "Block": "142",
            "label": "SIDDHARTH PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22516",
            "district": "12",
            "Block": "142",
            "label": "SHREE SAI VIDHYALAYA CBSE SCHOOL,V.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22517",
            "district": "12",
            "Block": "142",
            "label": "SITA RAJARAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23844",
            "district": "12",
            "Block": "142",
            "label": "PUMS, NALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23845",
            "district": "12",
            "Block": "142",
            "label": "PUMS, KURICHI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "143": [
        {
            "key": "2940",
            "district": "5",
            "Block": "143",
            "label": "AMS  KOTHAVASAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3578",
            "district": "5",
            "Block": "143",
            "label": "PUMS JAHIR HUSSAIN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3821",
            "district": "5",
            "Block": "143",
            "label": "PUMS VADAKKUKOLAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3895",
            "district": "5",
            "Block": "143",
            "label": "AMS VARAVARA MUNI CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3916",
            "district": "5",
            "Block": "143",
            "label": "PUMS NANTHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4123",
            "district": "5",
            "Block": "143",
            "label": "PUMS N.POOLAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4167",
            "district": "5",
            "Block": "143",
            "label": "ADWMS- VARAHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4365",
            "district": "5",
            "Block": "143",
            "label": "AMS ANNAI SATHIYA",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4716",
            "district": "5",
            "Block": "143",
            "label": "MPLMS MANALANE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4871",
            "district": "5",
            "Block": "143",
            "label": "VENUS MATRICULATION HIGHER SECONDARY SCHOOL,THILLAI NAGAR,CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4949",
            "district": "5",
            "Block": "143",
            "label": "PUMS VALLAMPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5225",
            "district": "5",
            "Block": "143",
            "label": "PUMS PAZHAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5272",
            "district": "5",
            "Block": "143",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL LALPET MAIN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5469",
            "district": "5",
            "Block": "143",
            "label": "MPLMS NORTH CAR STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5531",
            "district": "5",
            "Block": "143",
            "label": "TELC MIDDLE M.ODAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5616",
            "district": "5",
            "Block": "143",
            "label": "PUMS VADAKKUMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5971",
            "district": "5",
            "Block": "143",
            "label": "MPLMS MALAIKATTI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5973",
            "district": "5",
            "Block": "143",
            "label": "AMS SIVAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6040",
            "district": "5",
            "Block": "143",
            "label": "PUMS KADAVACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6187",
            "district": "5",
            "Block": "143",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL KOLLIMALAI MELPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6357",
            "district": "5",
            "Block": "143",
            "label": "IMAM GAZZALI MATRIC HIGHER SECONDARYSCHOOL- LALPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6363",
            "district": "5",
            "Block": "143",
            "label": "NIRMALA MATRICULATION HIGHER SECONDARY SCHOOL,CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6463",
            "district": "5",
            "Block": "143",
            "label": "PUMS SIRAKILANDANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15589",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HR.SEC SCHOOL LALPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15590",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HR.SEC.SCHOOL KUMARATCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15591",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL ELLERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15592",
            "district": "5",
            "Block": "143",
            "label": "GHS NEYVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15593",
            "district": "5",
            "Block": "143",
            "label": "PUMS MEYYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15594",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL KOODUVELICHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15595",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL EDAIYAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15596",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL THIRUNARAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15597",
            "district": "5",
            "Block": "143",
            "label": "GHS PARIVILAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15598",
            "district": "5",
            "Block": "143",
            "label": "ADWSHSS MELAVANNIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15599",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT HIGH SCHOOL KARUPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15600",
            "district": "5",
            "Block": "143",
            "label": "GOVT HIGH SCHOOL,  JAYANKONDAPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15601",
            "district": "5",
            "Block": "143",
            "label": "GHS SIVAPURI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15602",
            "district": "5",
            "Block": "143",
            "label": "PUMS MULLANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15603",
            "district": "5",
            "Block": "143",
            "label": "GHSS VALLATHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15604",
            "district": "5",
            "Block": "143",
            "label": "WILLIAMS MATRICULATION SCHOOL VALLAMPADUGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15605",
            "district": "5",
            "Block": "143",
            "label": "GHS VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15606",
            "district": "5",
            "Block": "143",
            "label": "AMS VAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15607",
            "district": "5",
            "Block": "143",
            "label": "LEELA MEMO MAT ANNAMALAINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15608",
            "district": "5",
            "Block": "143",
            "label": "A.R.G ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL,ANNAMALAI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15609",
            "district": "5",
            "Block": "143",
            "label": "MPLMS KOTHANGUDI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15610",
            "district": "5",
            "Block": "143",
            "label": "AMS TELC CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15611",
            "district": "5",
            "Block": "143",
            "label": "KAMARAJ MATRICULATION HIGHER SECONDARY SCHOOL,CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15612",
            "district": "5",
            "Block": "143",
            "label": "THILLAI MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15613",
            "district": "5",
            "Block": "143",
            "label": "RCT HSS CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15614",
            "district": "5",
            "Block": "143",
            "label": "RANI SEETHAI HSS ANNAMALAI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15615",
            "district": "5",
            "Block": "143",
            "label": "GOVT GIRLS HR SEC SCHOOL-CHIDAMBARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15616",
            "district": "5",
            "Block": "143",
            "label": "GOVT NANDANAR BOYS HSS CHIDAMBARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15617",
            "district": "5",
            "Block": "143",
            "label": "GOVT NANDANAR GIRLS HSS CHIDAMBARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15618",
            "district": "5",
            "Block": "143",
            "label": "ARUMUGA NAVALAR HIGHER SECONDARY SCHOOL CHIDAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15619",
            "district": "5",
            "Block": "143",
            "label": "SRI RAMAKRISHNA VIDYASALA HR SEC SCHOOL-CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15620",
            "district": "5",
            "Block": "143",
            "label": "PACHAIYAPPA'S HIGHER SECONDARY SCHOOL CHIDAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15621",
            "district": "5",
            "Block": "143",
            "label": "SARASU MATRICULATION SCHOOL,PARAMESWARANALLURE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15622",
            "district": "5",
            "Block": "143",
            "label": "SRI NATARAJA MATRIC SCHOOL-C.THADESWARANALLUR CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15623",
            "district": "5",
            "Block": "143",
            "label": "MPLGHS-SAMBANDHAKARA ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15624",
            "district": "5",
            "Block": "143",
            "label": "SRI GURUGNANASAMBANDAR MISSION MATRICULATION  HIGHER SECONDARY SCHOOL, CHIDAMBARAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21099",
            "district": "5",
            "Block": "143",
            "label": "SHEMFORD FUTURISTIC CBSE -C.THANDEWARNALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21282",
            "district": "5",
            "Block": "143",
            "label": "SRI VENKATESWARA MATRICULATION SCHOOL, SIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21283",
            "district": "5",
            "Block": "143",
            "label": "THE MATRICULATION HIGHER SECONDARY SCHOOL, CHIDAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21387",
            "district": "5",
            "Block": "143",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL-LALPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21519",
            "district": "5",
            "Block": "143",
            "label": "ADWHS M.KOLAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22213",
            "district": "5",
            "Block": "143",
            "label": "CHALLENGED CHILDREN AND WOMEN EDUCATION TRUST",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22214",
            "district": "5",
            "Block": "143",
            "label": "G.V. SPECIAL SCHOOL FOR MENTALLY RETARDED",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22215",
            "district": "5",
            "Block": "143",
            "label": "R.N.SUSEELA SPECIAL SCHOOL FOR HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23846",
            "district": "5",
            "Block": "143",
            "label": "GHS VELLUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23847",
            "district": "5",
            "Block": "143",
            "label": "Sri Saraswathi Vidhyalaya",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "144": [
        {
            "key": "1983",
            "district": "25",
            "Block": "144",
            "label": "KALYANASUNDARAM CBSE -CHOZHANMALIGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2517",
            "district": "25",
            "Block": "144",
            "label": "AMMANKOIL AIDED MS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2530",
            "district": "25",
            "Block": "144",
            "label": "PUMS - MANAPADAIVEEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2551",
            "district": "25",
            "Block": "144",
            "label": "PONNIYAH RAMJAYAM PUBLIC SCHOOL-VALAYAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2609",
            "district": "25",
            "Block": "144",
            "label": "LITTLE FLOWER MATRICULATION HIGH SCHOOL, KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2624",
            "district": "25",
            "Block": "144",
            "label": "PUMS MP MANDABAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2635",
            "district": "25",
            "Block": "144",
            "label": "MOTHER CLARAC MATRICULATION HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2731",
            "district": "25",
            "Block": "144",
            "label": "TAN ACADEMY MAT HSS KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2743",
            "district": "25",
            "Block": "144",
            "label": "ST.ANTONYS MAT HR SEC SCHOOL. - SAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2749",
            "district": "25",
            "Block": "144",
            "label": "CHRIST THE KING(BOYS) MAT HSS KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2872",
            "district": "25",
            "Block": "144",
            "label": "KARTHI VIDHYALAYA  MAT  HR.SEC. SCHOOL KORANATTU KARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2892",
            "district": "25",
            "Block": "144",
            "label": "ARR MAT HSS KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2969",
            "district": "25",
            "Block": "144",
            "label": "MPL MS MADULAMPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2996",
            "district": "25",
            "Block": "144",
            "label": "PUMS KOVELACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3087",
            "district": "25",
            "Block": "144",
            "label": "PUMS - NATHANKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3148",
            "district": "25",
            "Block": "144",
            "label": "NATIONAL SCHOOL FOR THE HEARING IMPAIRED -  KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3167",
            "district": "25",
            "Block": "144",
            "label": "THIRUNAVUKARASU AMS KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3205",
            "district": "25",
            "Block": "144",
            "label": "ST ANNES MATRICULATION HIGHER SECONDARY SCHOOL(GIRLS) KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3321",
            "district": "25",
            "Block": "144",
            "label": "MORNING STAR MAT CHOLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23682",
            "district": "25",
            "Block": "144",
            "label": "Town higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3338",
            "district": "25",
            "Block": "144",
            "label": "PUMS-ASOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3361",
            "district": "25",
            "Block": "144",
            "label": "Al-Ameen Matriculation Higher Secondary School, Kumbakonam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3498",
            "district": "25",
            "Block": "144",
            "label": "PUMS - THIRUPURAMBIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3518",
            "district": "25",
            "Block": "144",
            "label": "VIVEKANANDHA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, THENAMPADUGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3565",
            "district": "25",
            "Block": "144",
            "label": "PUMS - NADUVAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3632",
            "district": "25",
            "Block": "144",
            "label": "PUMS NAGAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3761",
            "district": "25",
            "Block": "144",
            "label": "MPL MS - KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3904",
            "district": "25",
            "Block": "144",
            "label": "NATIONAL VIDYALAYA CBSE HSS - KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3920",
            "district": "25",
            "Block": "144",
            "label": "AA MPL MS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3994",
            "district": "25",
            "Block": "144",
            "label": "AIDED MS KORANATTU KARUPPUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4145",
            "district": "25",
            "Block": "144",
            "label": "PUMS - AMMAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4246",
            "district": "25",
            "Block": "144",
            "label": "MYDEEN MAT. HSS - KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4333",
            "district": "25",
            "Block": "144",
            "label": "PUMS-AMMACHATRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4435",
            "district": "25",
            "Block": "144",
            "label": "PUMS-PULIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4479",
            "district": "25",
            "Block": "144",
            "label": "ST AGASTHINAR AMS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4585",
            "district": "25",
            "Block": "144",
            "label": "PUMS SOLANMALIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4869",
            "district": "25",
            "Block": "144",
            "label": "MADHA DIFFERENTLY ABLED HR SEC SCHOOL-SAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4977",
            "district": "25",
            "Block": "144",
            "label": "SRI KALAIMAGAL KENDRA MATRIC HIGHER SECONDARY SCHOOL KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5218",
            "district": "25",
            "Block": "144",
            "label": "BHARATHA RATHNA PURATCHITHALAIVAR DR.MGR MEMMORIAL MPL MIDDLE SCHOOL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5231",
            "district": "25",
            "Block": "144",
            "label": "SARASWATHI PADASALA AHSS  KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5498",
            "district": "25",
            "Block": "144",
            "label": "PUMS-SATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5533",
            "district": "25",
            "Block": "144",
            "label": "C.P VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL,  KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5660",
            "district": "25",
            "Block": "144",
            "label": "PATTU MATRIC HSS SAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5689",
            "district": "25",
            "Block": "144",
            "label": "PUMS - THENAMPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6449",
            "district": "25",
            "Block": "144",
            "label": "PUMS - SENGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6610",
            "district": "25",
            "Block": "144",
            "label": "SRI MATHA MAT HSS KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16525",
            "district": "25",
            "Block": "144",
            "label": "Shri Balaji Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16526",
            "district": "25",
            "Block": "144",
            "label": "AIDED MS DEVANACHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16527",
            "district": "25",
            "Block": "144",
            "label": "MINERVA HSS KADICHAMPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16528",
            "district": "25",
            "Block": "144",
            "label": "PUMS KALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16529",
            "district": "25",
            "Block": "144",
            "label": "MADHA HIGHER  SECONDARY SCHOOL FOR THE DEAF, SAKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16530",
            "district": "25",
            "Block": "144",
            "label": "GHSS NEERATHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16531",
            "district": "25",
            "Block": "144",
            "label": "DHANISHLOS SPL SCH MS-MP MANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16532",
            "district": "25",
            "Block": "144",
            "label": "ANNA GHSS-PATTEESWARAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16533",
            "district": "25",
            "Block": "144",
            "label": "GHSS-SUNDARAPERUMALKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16534",
            "district": "25",
            "Block": "144",
            "label": "AIDED MS - MADAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16535",
            "district": "25",
            "Block": "144",
            "label": "GHS - THIPPIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16536",
            "district": "25",
            "Block": "144",
            "label": "GHSS - THIRUPPURAMBIAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16537",
            "district": "25",
            "Block": "144",
            "label": "GHS - UDAIYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16538",
            "district": "25",
            "Block": "144",
            "label": "ADW MS - CHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16539",
            "district": "25",
            "Block": "144",
            "label": "GHSS - CHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16540",
            "district": "25",
            "Block": "144",
            "label": "VALLALAR AHSS - KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16541",
            "district": "25",
            "Block": "144",
            "label": "KAMARAJAR MPL HS - MELA CAUVERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16542",
            "district": "25",
            "Block": "144",
            "label": "NATIVE AHSS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16543",
            "district": "25",
            "Block": "144",
            "label": "GHSS GIRLS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16544",
            "district": "25",
            "Block": "144",
            "label": "AAGHSS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16545",
            "district": "25",
            "Block": "144",
            "label": "K.T. VEEDHI AIDED HS KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16546",
            "district": "25",
            "Block": "144",
            "label": "BANADURAI AHSS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16547",
            "district": "25",
            "Block": "144",
            "label": "ORIENTAL AHSS KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16548",
            "district": "25",
            "Block": "144",
            "label": "TOWN AHSS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16549",
            "district": "25",
            "Block": "144",
            "label": "LITTLE FLOWER HR SEC SCHOOL  KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16550",
            "district": "25",
            "Block": "144",
            "label": "RC MORNING STAR AHS KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16551",
            "district": "25",
            "Block": "144",
            "label": "ARR MPL HSS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16552",
            "district": "25",
            "Block": "144",
            "label": "RANI SUBBAIYYAR MPL MS KUMBAKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16553",
            "district": "25",
            "Block": "144",
            "label": "ST JOSEPH AHSS KUMBAKONAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16554",
            "district": "25",
            "Block": "144",
            "label": "AJC ENGLISH SCHOOL KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16555",
            "district": "25",
            "Block": "144",
            "label": "GHSS DARASURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16556",
            "district": "25",
            "Block": "144",
            "label": "JAYAM AIDED AMS-SHOLAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16557",
            "district": "25",
            "Block": "144",
            "label": "GHS - SHOLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16558",
            "district": "25",
            "Block": "144",
            "label": "AIDED MS SWAMIMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16559",
            "district": "25",
            "Block": "144",
            "label": "GHSS-SWAMIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21033",
            "district": "25",
            "Block": "144",
            "label": "CHRIST MATRICULATION SCHOOL KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21102",
            "district": "25",
            "Block": "144",
            "label": "ARR SL VIDYASHRAAM  SENIOR SECONDARY SCHOOL -KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21539",
            "district": "25",
            "Block": "144",
            "label": "ST. XAVIER HIGH SCHOOL-KUMBAKONAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21611",
            "district": "25",
            "Block": "144",
            "label": "KAVERY MATRICULATION SCHOOL -MELACAUVERY -KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21861",
            "district": "25",
            "Block": "144",
            "label": "CHRIST THE KING (GIRLS) MAT HSS-KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22224",
            "district": "25",
            "Block": "144",
            "label": "THAMARAI INTERNATIONAL SCHOOL-KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22579",
            "district": "25",
            "Block": "144",
            "label": "GHS - KEELAKORUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22580",
            "district": "25",
            "Block": "144",
            "label": "EQUITAS GURUKUL MATRICULATION SCHOOL - KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22978",
            "district": "25",
            "Block": "144",
            "label": "KARTHI VIDHYALAYA INTERNATIONAL SCHOOL-KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23326",
            "district": "25",
            "Block": "144",
            "label": "VELAMMAL BODHI CAMPUS- KUMBAKONAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23848",
            "district": "25",
            "Block": "144",
            "label": "PUMS KALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23849",
            "district": "25",
            "Block": "144",
            "label": "KARTHI VIDHYALAYA PUBLIC SCHOOL",
            "Sch_type": "Central GovtMiddle School"
        }
    ],
    "145": [
        {
            "key": "836",
            "district": "32",
            "Block": "145",
            "label": "R G MATRIC HR SEC SCHOOL MANOORPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1074",
            "district": "32",
            "Block": "145",
            "label": "PUMS GOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1104",
            "district": "32",
            "Block": "145",
            "label": "PUMS PUTHURUTHRAVATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1146",
            "district": "32",
            "Block": "145",
            "label": "PUMS MANURPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1151",
            "district": "32",
            "Block": "145",
            "label": "PUMS MUTHALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1161",
            "district": "32",
            "Block": "145",
            "label": "PUMS VENGIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1194",
            "district": "32",
            "Block": "145",
            "label": "PUMS THALAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1314",
            "district": "32",
            "Block": "145",
            "label": "SWAMY VIVEKANANDA VIDYALAYA MAT.HR.SEC.SCHOOL, KODUVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1374",
            "district": "32",
            "Block": "145",
            "label": "PUMS EDAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1601",
            "district": "32",
            "Block": "145",
            "label": "PUMS VERUVEDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1645",
            "district": "32",
            "Block": "145",
            "label": "PUMS ELLAPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1675",
            "district": "32",
            "Block": "145",
            "label": "PUMS MUTHUGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1727",
            "district": "32",
            "Block": "145",
            "label": "PUMS SURIYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2064",
            "district": "32",
            "Block": "145",
            "label": "PUMS N.KASILINGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2160",
            "district": "32",
            "Block": "145",
            "label": "VSV MAT. HR.SEC.SCHOOL METTUKKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2401",
            "district": "32",
            "Block": "145",
            "label": "PUMS KALLIVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2455",
            "district": "32",
            "Block": "145",
            "label": "PUMS PERIYAKUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23415",
            "district": "32",
            "Block": "145",
            "label": "Shree Venaudaiyar Vidhyalaya School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "3272",
            "district": "32",
            "Block": "145",
            "label": "Vivekanandha Academy Senior Secondary School  (CBSE) .",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12228",
            "district": "32",
            "Block": "145",
            "label": "GHS BELLAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12229",
            "district": "32",
            "Block": "145",
            "label": "GHS KETHALREV",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12230",
            "district": "32",
            "Block": "145",
            "label": "PUMS ERAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12231",
            "district": "32",
            "Block": "145",
            "label": "GHS SANKARANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12232",
            "district": "32",
            "Block": "145",
            "label": "AHSS SANTHINIKETHAN HSS KULLAMPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12233",
            "district": "32",
            "Block": "145",
            "label": "GHS NALLIMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12234",
            "district": "32",
            "Block": "145",
            "label": "PUMS KANGAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12235",
            "district": "32",
            "Block": "145",
            "label": "GHSS KUNDADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12236",
            "district": "32",
            "Block": "145",
            "label": "VMCDV HSS THAYAMPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12237",
            "district": "32",
            "Block": "145",
            "label": "KGBV THONDAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21217",
            "district": "32",
            "Block": "145",
            "label": "GMHSS, KUNDADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21269",
            "district": "32",
            "Block": "145",
            "label": "MERIT MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23415",
            "district": "32",
            "Block": "145",
            "label": "Shree Venaudaiyar Vidhyalaya School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23415",
            "district": "32",
            "Block": "145",
            "label": "Shree Venaudaiyar Vidhyalaya School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23415",
            "district": "32",
            "Block": "145",
            "label": "Shree Venaudaiyar Vidhyalaya School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23415",
            "district": "32",
            "Block": "145",
            "label": "Shree Venaudaiyar Vidhyalaya School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23850",
            "district": "32",
            "Block": "145",
            "label": "SRI GURUJI VIDYA MANDHIR MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "146": [
        {
            "key": "553",
            "district": "10",
            "Block": "146",
            "label": "Kalashethra MHSS, Iyyappanthangal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "758",
            "district": "10",
            "Block": "146",
            "label": "LALAJI MEMORIAL OMEGA INTERNATIONALSCHOOL  KOLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1334",
            "district": "10",
            "Block": "146",
            "label": "Little Flower MHSS Moulivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2903",
            "district": "10",
            "Block": "146",
            "label": "P S Balabhavan MHSS Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3520",
            "district": "10",
            "Block": "146",
            "label": "The National IT Int. Matric Hr Sec School, Kumananchavadi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4349",
            "district": "10",
            "Block": "146",
            "label": "Masi MHSS, Iyappanthangal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4618",
            "district": "10",
            "Block": "146",
            "label": "Sivanthi MS Kundrathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4632",
            "district": "10",
            "Block": "146",
            "label": "ST. ANNES MAT HSS MADANANDHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4858",
            "district": "10",
            "Block": "146",
            "label": "Little Flower MHSS Kundrathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5285",
            "district": "10",
            "Block": "146",
            "label": "ANNAI THERESA MAT. HR.SEC. SCHOOL, IYYAPANTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5343",
            "district": "10",
            "Block": "146",
            "label": "ROSHNI MATRICULATION SCHOOL, KUTHNOOR KAVANUR (POST)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5383",
            "district": "10",
            "Block": "146",
            "label": "M.S.J.M. HinduVid MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5496",
            "district": "10",
            "Block": "146",
            "label": "Nav Bharath MHSS Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5507",
            "district": "10",
            "Block": "146",
            "label": "PUMS IRANTAMKATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6600",
            "district": "10",
            "Block": "146",
            "label": "Mangadu public school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7772",
            "district": "10",
            "Block": "146",
            "label": "GHS PERIAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7773",
            "district": "10",
            "Block": "146",
            "label": "Madha MATRIC HSS Kundrathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7774",
            "district": "10",
            "Block": "146",
            "label": "GHS SIRUKALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7775",
            "district": "10",
            "Block": "146",
            "label": "Sri Arabindo MHSS, Malayambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7776",
            "district": "10",
            "Block": "146",
            "label": "GHS Malayambakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7777",
            "district": "10",
            "Block": "146",
            "label": "PUMS Kolumanivakkam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7778",
            "district": "10",
            "Block": "146",
            "label": "Panchayat union middle school,PARANIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7779",
            "district": "10",
            "Block": "146",
            "label": "PUMS ANNAI INDRA NAGAR PARANIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7780",
            "district": "10",
            "Block": "146",
            "label": "GHSS IYYAPANTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7781",
            "district": "10",
            "Block": "146",
            "label": "GOVT.ADW.HSS.,MOULIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7782",
            "district": "10",
            "Block": "146",
            "label": "PUMS  MATHANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7783",
            "district": "10",
            "Block": "146",
            "label": "GHS MUGALIVAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7784",
            "district": "10",
            "Block": "146",
            "label": "CVC TRUST MS, KOLAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7785",
            "district": "10",
            "Block": "146",
            "label": "ST FRANCIS INTERNATIONAL SCHOOL KOLAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7786",
            "district": "10",
            "Block": "146",
            "label": "PON VIDHYASHRAM KOLAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7787",
            "district": "10",
            "Block": "146",
            "label": "SARASWATHY VIDHYALAYA MATRICULATION   HIGHER SECONDARY SCHOOL ,GERUGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7788",
            "district": "10",
            "Block": "146",
            "label": "THE PSBB MILLENNIUM SCHOOL GERUGAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7789",
            "district": "10",
            "Block": "146",
            "label": "GHS GERUGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7790",
            "district": "10",
            "Block": "146",
            "label": "ADWHS THANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7791",
            "district": "10",
            "Block": "146",
            "label": "GBHSS KOVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7792",
            "district": "10",
            "Block": "146",
            "label": "PUMS PUDUVATTARAM KOVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7793",
            "district": "10",
            "Block": "146",
            "label": "G(G)HSS, Kovur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7794",
            "district": "10",
            "Block": "146",
            "label": "Sri Krish International School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7795",
            "district": "10",
            "Block": "146",
            "label": "GHSS THIRUMUDIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7796",
            "district": "10",
            "Block": "146",
            "label": "SREE SHANTHI ANAND VIDYALAYA(CBSE),THIRUMUDIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7797",
            "district": "10",
            "Block": "146",
            "label": "PUMS PAZHANTHANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7798",
            "district": "10",
            "Block": "146",
            "label": "RCM HSS(Aided), Naduveerapattu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7799",
            "district": "10",
            "Block": "146",
            "label": "Good Earth School, Naduveerapatu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7800",
            "district": "10",
            "Block": "146",
            "label": "ST JOSEPH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7801",
            "district": "10",
            "Block": "146",
            "label": "SAIRAM MHSS, POONTHANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7802",
            "district": "10",
            "Block": "146",
            "label": "GHS NALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7803",
            "district": "10",
            "Block": "146",
            "label": "GHSS, Somangalam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7804",
            "district": "10",
            "Block": "146",
            "label": "VEDAVALLI VIDYA MANDIR SENIOR SECONDARY SCHOOL, SOMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7805",
            "district": "10",
            "Block": "146",
            "label": "GHS MANIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7806",
            "district": "10",
            "Block": "146",
            "label": "PUMS Karasangal",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7807",
            "district": "10",
            "Block": "146",
            "label": "pums Keezhpadappai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7808",
            "district": "10",
            "Block": "146",
            "label": "pums melpadappai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7809",
            "district": "10",
            "Block": "146",
            "label": "G(B)HSS, Padappai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7810",
            "district": "10",
            "Block": "146",
            "label": "St.MARY MAT SCHOOL,PADAPPAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7811",
            "district": "10",
            "Block": "146",
            "label": "EVEREST VID.MAT,PADAPPAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7812",
            "district": "10",
            "Block": "146",
            "label": "G(G)HSS, PADAPPAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7813",
            "district": "10",
            "Block": "146",
            "label": "PUMS Ezhichur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7814",
            "district": "10",
            "Block": "146",
            "label": "GOVERNMENT HIGH SCHOOL VADAKUPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7815",
            "district": "10",
            "Block": "146",
            "label": "Thriveni Academy Vadakkupattu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7816",
            "district": "10",
            "Block": "146",
            "label": "ADWHS Chennakuppam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7817",
            "district": "10",
            "Block": "146",
            "label": "P.U.M.S , Salamangalam .",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7818",
            "district": "10",
            "Block": "146",
            "label": "pums Athanancheri",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7819",
            "district": "10",
            "Block": "146",
            "label": "PUMS , Serappanancheri .",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7820",
            "district": "10",
            "Block": "146",
            "label": "Bharrathi Vidyashram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7821",
            "district": "10",
            "Block": "146",
            "label": "Ghs, Umaiyalparanacheri",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7822",
            "district": "10",
            "Block": "146",
            "label": "GHS,NATTARASANPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7823",
            "district": "10",
            "Block": "146",
            "label": "PUMS Orathur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7824",
            "district": "10",
            "Block": "146",
            "label": "G.T.M.N GOVT AIDED MIDDLE SCHOOL KAVANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7825",
            "district": "10",
            "Block": "146",
            "label": "Government High School,Madambakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7826",
            "district": "10",
            "Block": "146",
            "label": "PUMS MANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7827",
            "district": "10",
            "Block": "146",
            "label": "GHS,MANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7828",
            "district": "10",
            "Block": "146",
            "label": "Dawn MHSS Pattur Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7829",
            "district": "10",
            "Block": "146",
            "label": "St.Mary's MS Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7830",
            "district": "10",
            "Block": "146",
            "label": "St.Annes Metric School Ragunathapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7831",
            "district": "10",
            "Block": "146",
            "label": "Mukkala Nammalvar Chetty school",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7832",
            "district": "10",
            "Block": "146",
            "label": "Valluvar(Aided)MS,Thirunageswaram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7833",
            "district": "10",
            "Block": "146",
            "label": "GHSS (Girls), Kundrathur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7834",
            "district": "10",
            "Block": "146",
            "label": "GHSS (Boys), Kundrathur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7835",
            "district": "10",
            "Block": "146",
            "label": "Panchayath Union Middle School, Manappakkam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7836",
            "district": "10",
            "Block": "146",
            "label": "DR.MGR HOME AND HIGHER SECONDARY SCHOOL FOR THE SPEECH AND HEARING IMPAIRED MANAPAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7837",
            "district": "10",
            "Block": "146",
            "label": "PUMS  Sikkarayapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7838",
            "district": "10",
            "Block": "146",
            "label": "LOYOLA ACADEMY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7948",
            "district": "10",
            "Block": "146",
            "label": "Loyola Academy CBSE School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21147",
            "district": "10",
            "Block": "146",
            "label": "S. R. N. M. MATRICULATION HIGHER SECONDARY SCHOOL, NANDAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21197",
            "district": "10",
            "Block": "146",
            "label": "GHSS KOLAPPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21426",
            "district": "10",
            "Block": "146",
            "label": "GHS PATTUR MUSLIM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21733",
            "district": "10",
            "Block": "146",
            "label": "Alwin International Public School, Kzheel Padappai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21734",
            "district": "10",
            "Block": "146",
            "label": "Velammal Vidhyashram, Salamangalam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21735",
            "district": "10",
            "Block": "146",
            "label": "Peace on Green Earth Public School, Karimanagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21736",
            "district": "10",
            "Block": "146",
            "label": "Sivanthi Public School, Kundrathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21737",
            "district": "10",
            "Block": "146",
            "label": "BSSK Marticulation School, Varatharajapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22025",
            "district": "10",
            "Block": "146",
            "label": "Annai Theresa Matriculation School, Bharaniputhur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22026",
            "district": "10",
            "Block": "146",
            "label": "NARAYANA E- TECHNO SCHOOL, MS NAGAR, CHENNAI TN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22027",
            "district": "10",
            "Block": "146",
            "label": "VELAMMAL BODHI CAMPUS SCHOOL  KOLAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22028",
            "district": "10",
            "Block": "146",
            "label": "Holy Sai International School, Adhanur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22029",
            "district": "10",
            "Block": "146",
            "label": "RISHS International  School, Mangadu",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22344",
            "district": "10",
            "Block": "146",
            "label": "Sri Ayyappan Vidyal Matric School, Kovur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22345",
            "district": "10",
            "Block": "146",
            "label": "Jain Public School, Thirumudivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22346",
            "district": "10",
            "Block": "146",
            "label": "BUDDING MINDS INTERNATIONAL SCHOOL, Manimangalam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22347",
            "district": "10",
            "Block": "146",
            "label": "Velammal Vidyalaya, Mangadu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22348",
            "district": "10",
            "Block": "146",
            "label": "Maharishi vidhya mandir",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22349",
            "district": "10",
            "Block": "146",
            "label": "Shree Niketan Patasala CBSE School, Varatharajapuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22686",
            "district": "10",
            "Block": "146",
            "label": "SRI CHAITANYA TECHNO SCHOOL, MANAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22743",
            "district": "10",
            "Block": "146",
            "label": "Swamy's school , santhosh nagar Madhanandhapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22745",
            "district": "10",
            "Block": "146",
            "label": "Swamy's school , Gurusamy nagar Madhanandhapuram",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22752",
            "district": "10",
            "Block": "146",
            "label": "Peter International School(CBSE).Kavanur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22754",
            "district": "10",
            "Block": "146",
            "label": "sri chaitanya Techno School Iyyappanthangal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22857",
            "district": "10",
            "Block": "146",
            "label": "Amrita Vidyalayam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22898",
            "district": "10",
            "Block": "146",
            "label": "GHS, THUNDALKAZHANI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23018",
            "district": "10",
            "Block": "146",
            "label": "Tianno vidyashrm",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23025",
            "district": "10",
            "Block": "146",
            "label": "PUMS NAVALUR (PUDHU KUDIYIRUPPU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23104",
            "district": "10",
            "Block": "146",
            "label": "ST JOSEPH MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23221",
            "district": "10",
            "Block": "146",
            "label": "OLOGY TECH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23339",
            "district": "10",
            "Block": "146",
            "label": "VAGISHA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23851",
            "district": "10",
            "Block": "146",
            "label": "PUMS MANGADU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23852",
            "district": "10",
            "Block": "146",
            "label": "Amrita Vidyalayam",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23853",
            "district": "10",
            "Block": "146",
            "label": "LALAJI MEMORIAL OMEGA INTERNATIONAL SCHOOL (CIS)",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23854",
            "district": "10",
            "Block": "146",
            "label": "KANCHIPURAM DISTRICT GOVERNMENT MODEL SCHOOL, MANIMANGALAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23855",
            "district": "10",
            "Block": "146",
            "label": "SSP VIDHYASHRAM, VARADARAJAPURAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "147": [
        {
            "key": "16759",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANNANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16760",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL MELAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16761",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL THEMMAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16762",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16763",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAKKATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16764",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL UDAIYALIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16765",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VALIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16766",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ODUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16767",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOSAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16768",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL UPPILIYAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16769",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENAIYAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16770",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL VAITHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16771",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARANIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16772",
            "district": "19",
            "Block": "147",
            "label": "R.C.RAWUSAPPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16773",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL KULATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16774",
            "district": "19",
            "Block": "147",
            "label": "MUTHUSWAMY VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL KULATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16775",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGH SCHOOL LEKKANAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16776",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ILLUPPAKKUDIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16777",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ANDAKKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16778",
            "district": "19",
            "Block": "147",
            "label": "VSA NURSERY AND PRIMARY SCHOOL ANDAKKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16779",
            "district": "19",
            "Block": "147",
            "label": "ST.ANTONY MIDDLE SCHOOL KILLUKKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16780",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KILLUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16781",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUMALAIRAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16782",
            "district": "19",
            "Block": "147",
            "label": "ST.JOSEPH HIGH SCHOOL THIRUMALAIRAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16783",
            "district": "19",
            "Block": "147",
            "label": "PUNITHA SAHAYAMATHA MIDDLE SCHOOL KEERANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16784",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16785",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16786",
            "district": "19",
            "Block": "147",
            "label": "PUNITHA AROCKIYA ANNAI HIGHER SECONDARY SCHOOL KEERANUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16787",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUNDRANDARKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16788",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VATHANAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16789",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ODUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16790",
            "district": "19",
            "Block": "147",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16791",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALLATHUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16792",
            "district": "19",
            "Block": "147",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAPPUDAIYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        }
    ],
    "148": [
        {
            "key": "2772",
            "district": "5",
            "Block": "148",
            "label": "PUMS THONDAMANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2833",
            "district": "5",
            "Block": "148",
            "label": "PUMS THENKUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2901",
            "district": "5",
            "Block": "148",
            "label": "ST MARY'S MATRICULATION SCHOOL,VADALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2935",
            "district": "5",
            "Block": "148",
            "label": "PUMS RAMANATHANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2971",
            "district": "5",
            "Block": "148",
            "label": "PUMS - THANGALIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3020",
            "district": "5",
            "Block": "148",
            "label": "PUMS VADAKUMELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3115",
            "district": "5",
            "Block": "148",
            "label": "PUMS THAMBIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3131",
            "district": "5",
            "Block": "148",
            "label": "PUMS PARVATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3636",
            "district": "5",
            "Block": "148",
            "label": "PUMS ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3692",
            "district": "5",
            "Block": "148",
            "label": "PUMS MEENAKSHIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3997",
            "district": "5",
            "Block": "148",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  PERIYAKATTUSAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4421",
            "district": "5",
            "Block": "148",
            "label": "PUMS ELLAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4685",
            "district": "5",
            "Block": "148",
            "label": "APS KRISHNANKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4749",
            "district": "5",
            "Block": "148",
            "label": "THIRUMURUGAN MIDDLE SCHOOL., V.PUDUKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5051",
            "district": "5",
            "Block": "148",
            "label": "PUMS MANTHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5088",
            "district": "5",
            "Block": "148",
            "label": "PUMS THIYAGAVALLI 2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5093",
            "district": "5",
            "Block": "148",
            "label": "PUMS SAMATTIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5141",
            "district": "5",
            "Block": "148",
            "label": "PUMS PETHANAYAKKANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5697",
            "district": "5",
            "Block": "148",
            "label": "ANANDHAN MEMORIAL MATRIC HR SEC  SCHOOL -VANNIYARPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15295",
            "district": "5",
            "Block": "148",
            "label": "GHS ADOORAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15296",
            "district": "5",
            "Block": "148",
            "label": "ADWMS ARANGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15297",
            "district": "5",
            "Block": "148",
            "label": "GOVT HR SEC SCHOOL, THEERTHANAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15298",
            "district": "5",
            "Block": "148",
            "label": "AMS ADOORKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15299",
            "district": "5",
            "Block": "148",
            "label": "KANNAN MATRICULATION  SCHOOL,KULLANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15300",
            "district": "5",
            "Block": "148",
            "label": "GHSS KULLANCHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15301",
            "district": "5",
            "Block": "148",
            "label": "GHS MARUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15302",
            "district": "5",
            "Block": "148",
            "label": "GHS POOVANIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15303",
            "district": "5",
            "Block": "148",
            "label": "GHS S.PUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15304",
            "district": "5",
            "Block": "148",
            "label": "GHS NAGAMMAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15305",
            "district": "5",
            "Block": "148",
            "label": "PUMS PERIYAKANNADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15306",
            "district": "5",
            "Block": "148",
            "label": "ADWMS KOTHANDARAMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15307",
            "district": "5",
            "Block": "148",
            "label": "PUMS AYIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15308",
            "district": "5",
            "Block": "148",
            "label": "PUMS KOTHAVACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15309",
            "district": "5",
            "Block": "148",
            "label": "GHS KALKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15310",
            "district": "5",
            "Block": "148",
            "label": "GHS RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15311",
            "district": "5",
            "Block": "148",
            "label": "PUMS GURUVAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15312",
            "district": "5",
            "Block": "148",
            "label": "GHSS ANDARMULLIPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15313",
            "district": "5",
            "Block": "148",
            "label": "GHS PULIYUR KATTUSAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15314",
            "district": "5",
            "Block": "148",
            "label": "ST MARY'S  HIGH SCHOOL-KRISHNANKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15315",
            "district": "5",
            "Block": "148",
            "label": "GHS PONNANGKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15316",
            "district": "5",
            "Block": "148",
            "label": "GHS SANTHAVELIPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15317",
            "district": "5",
            "Block": "148",
            "label": "GHS KO.CHATHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15318",
            "district": "5",
            "Block": "148",
            "label": "GHSS KARUNKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15319",
            "district": "5",
            "Block": "148",
            "label": "GHS METTUKUPPAM (KURINJIPADI)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15320",
            "district": "5",
            "Block": "148",
            "label": "GHSS VENGADAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15321",
            "district": "5",
            "Block": "148",
            "label": "GHS AMBALAVANANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15322",
            "district": "5",
            "Block": "148",
            "label": "GHS KANNITAMILNADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15323",
            "district": "5",
            "Block": "148",
            "label": "GHS K.NELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15324",
            "district": "5",
            "Block": "148",
            "label": "FATHIMA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15325",
            "district": "5",
            "Block": "148",
            "label": "SRI THAMBUSAMY MATRICULATION SCHOOL, ABATHARANAPURAM. VADALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15326",
            "district": "5",
            "Block": "148",
            "label": "ST JOHN HS PARVATHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15327",
            "district": "5",
            "Block": "148",
            "label": "VALLALAR GURUGULAM HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15328",
            "district": "5",
            "Block": "148",
            "label": "GGHSS VADALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15329",
            "district": "5",
            "Block": "148",
            "label": "S.D EADEN MATRICULATION HIGHER SECONDARY  SCHOOL, VADALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15330",
            "district": "5",
            "Block": "148",
            "label": "TRIPURANENI VIDYALAYA MATRICULATION  SCHOOL,VADALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15331",
            "district": "5",
            "Block": "148",
            "label": "GHSS VADALUR PUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15332",
            "district": "5",
            "Block": "148",
            "label": "GHS ABATHARANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15333",
            "district": "5",
            "Block": "148",
            "label": "S.D.S HIGH SCHOOL-SERAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15334",
            "district": "5",
            "Block": "148",
            "label": "ARUNACHALA MATRICULATION HIGHER SECONDARY SCHOOL, KURINJPADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15335",
            "district": "5",
            "Block": "148",
            "label": "ST PAUL MATRICULATION HIGHER SECONDARY  SCHOOL, KURINJIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15336",
            "district": "5",
            "Block": "148",
            "label": "SRIRAM MATRICULATION HIGHER SECONDARY SCHOOL, KURINJIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15337",
            "district": "5",
            "Block": "148",
            "label": "SRI GAYATHRI MATRICULATION SCHOOL,KURINJIPADI.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15338",
            "district": "5",
            "Block": "148",
            "label": "GOVT GIRLS HSS KURINJIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15339",
            "district": "5",
            "Block": "148",
            "label": "G(B)HSS KURINJIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15340",
            "district": "5",
            "Block": "148",
            "label": "S.K.V HSS KURINJIPADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15341",
            "district": "5",
            "Block": "148",
            "label": "SKV THAIYALNAYAKI AMMAL MATRICULATION SCHOOL, KURINJIPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15342",
            "district": "5",
            "Block": "148",
            "label": "JEEVA MATRICULATION  HIGHER SECONDARY  SCHOOL-SUBRAMANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15343",
            "district": "5",
            "Block": "148",
            "label": "SRI RAMALINGAR HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15344",
            "district": "5",
            "Block": "148",
            "label": "GHSS POONDIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15345",
            "district": "5",
            "Block": "148",
            "label": "GHS PERIYAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15346",
            "district": "5",
            "Block": "148",
            "label": "ARULMIGU VALLALAR  HIGH SCHOOL,  VELAVINAYAGARKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15347",
            "district": "5",
            "Block": "148",
            "label": "GHSS KATTIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15348",
            "district": "5",
            "Block": "148",
            "label": "LIFE HELP MATRICULATIONSCHOOL, METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15349",
            "district": "5",
            "Block": "148",
            "label": "GHSS  INDHRA NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21385",
            "district": "5",
            "Block": "148",
            "label": "GHS KUNDIYAMALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21553",
            "district": "5",
            "Block": "148",
            "label": "HOLY CROSS HIGH SCHOOL,  NJV NAGAR  VADAKUTHU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21689",
            "district": "5",
            "Block": "148",
            "label": "DON BOSCO MATRICULATION SCHOOL-ANAYAMPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21690",
            "district": "5",
            "Block": "148",
            "label": "ANNAI MULLAI MATRICULATION HIGHER SECONDARY SCHOOL,-VADAKUTHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22203",
            "district": "5",
            "Block": "148",
            "label": "RATHNA SPECIAL SCHOOL  METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22551",
            "district": "5",
            "Block": "148",
            "label": "SRK INTERNATIONAL  SENIOR SECONDARY SCHOOL-KEEZHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23476",
            "district": "5",
            "Block": "148",
            "label": "JAYAPRIYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23476",
            "district": "5",
            "Block": "148",
            "label": "JAYAPRIYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23856",
            "district": "5",
            "Block": "148",
            "label": "GHSS KARUNKUZHI",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23857",
            "district": "5",
            "Block": "148",
            "label": "VASANTHAM SPECIAL SCHOOL -VADALUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "149": [
        {
            "key": "816",
            "district": "11",
            "Block": "149",
            "label": "JOSEPH MATRIC- SASTHANKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1480",
            "district": "11",
            "Block": "149",
            "label": "CARMEL JYOTHI MATRIC HIGHER SECONDARY SCHOOL, VELLIYAKULAM, COLACHEL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1812",
            "district": "11",
            "Block": "149",
            "label": "CHRISTIAN MATRICULATION HIGHER SECONDARY SCHOOL,  KALLUKOOTTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1962",
            "district": "11",
            "Block": "149",
            "label": "ATHITHIYA MATRIC HSS- AMMANDIVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2192",
            "district": "11",
            "Block": "149",
            "label": "KALAIVANI MATRIC SCHOOL ,CHETHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2490",
            "district": "11",
            "Block": "149",
            "label": "Prof.R.K.Parthasarathy Hindu Vidyalaya Matriculation School Thingalnagar",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2514",
            "district": "11",
            "Block": "149",
            "label": "ST.ANNE'S MATRIC Hr.Sec. SCHOOL, COLACHEL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2619",
            "district": "11",
            "Block": "149",
            "label": "GMS MELKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2632",
            "district": "11",
            "Block": "149",
            "label": "GMS,KOOTUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2670",
            "district": "11",
            "Block": "149",
            "label": "GMS KATTIMANCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2886",
            "district": "11",
            "Block": "149",
            "label": "GMS KALPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3016",
            "district": "11",
            "Block": "149",
            "label": "GMS THOTTICODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3434",
            "district": "11",
            "Block": "149",
            "label": "ADLINE MATRIC HSS-VILLUKURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3450",
            "district": "11",
            "Block": "149",
            "label": "GMS MOONGILVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3965",
            "district": "11",
            "Block": "149",
            "label": "GMS MONDAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4172",
            "district": "11",
            "Block": "149",
            "label": "GMS ALANVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4451",
            "district": "11",
            "Block": "149",
            "label": "ST PETERS MS KADIAPATTANAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4489",
            "district": "11",
            "Block": "149",
            "label": "GMS KUNNANCADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4788",
            "district": "11",
            "Block": "149",
            "label": "LUTHERAN MIDDLE SCHOOL SARALVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4864",
            "district": "11",
            "Block": "149",
            "label": "NAVAJOTHI MATRIC HSS MONDAIKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4980",
            "district": "11",
            "Block": "149",
            "label": "St. MARY'S MATRIC HIGHER SECONDARY SCHOOL, SIMONCOLONY.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5069",
            "district": "11",
            "Block": "149",
            "label": "GMS KALLUKOOTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5490",
            "district": "11",
            "Block": "149",
            "label": "AROCKIA ANNAI MS PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5526",
            "district": "11",
            "Block": "149",
            "label": "GMS-COLACHEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5610",
            "district": "11",
            "Block": "149",
            "label": "GMS SARAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6021",
            "district": "11",
            "Block": "149",
            "label": "ORIENT MATRIC SCHOOL-COLACHEL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6031",
            "district": "11",
            "Block": "149",
            "label": "SREE VIVEKANANDA MATRIC HSS, KOOTTUMANGALAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6107",
            "district": "11",
            "Block": "149",
            "label": "GMS CHETTIYARMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6621",
            "district": "11",
            "Block": "149",
            "label": "MARIA RAFOLS MATRIC Hr.Sec. SCHOOL, PUTHOOR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20192",
            "district": "11",
            "Block": "149",
            "label": "GHSS ALOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20193",
            "district": "11",
            "Block": "149",
            "label": "GHSS KODUPAKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20194",
            "district": "11",
            "Block": "149",
            "label": "CORPUS CHRISTI SCHOOL , PERUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20195",
            "district": "11",
            "Block": "149",
            "label": "GHSS - ERANIEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20196",
            "district": "11",
            "Block": "149",
            "label": "ST.XAVIER'S HIGHER SECONDARY SCHOOL,PATTARIVILLAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20197",
            "district": "11",
            "Block": "149",
            "label": "ST.FRANCIS XAVIER Hr.Sec.School, Manguzhy",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20198",
            "district": "11",
            "Block": "149",
            "label": "GHS THALAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20199",
            "district": "11",
            "Block": "149",
            "label": "GHS VELLICHANTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20200",
            "district": "11",
            "Block": "149",
            "label": "GHSS-AMMANDIVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20201",
            "district": "11",
            "Block": "149",
            "label": "WESLEY MIDDLE SCHOOL UNNANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20202",
            "district": "11",
            "Block": "149",
            "label": "ST.JOSEPH'S H.S- SAREL.",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20203",
            "district": "11",
            "Block": "149",
            "label": "SACRED HEART HIGH SCHOOL, KADIAPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20204",
            "district": "11",
            "Block": "149",
            "label": "SAROJINI MEMORIAL HIGHER SECONDARY SCHOOL ORAPPANAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20205",
            "district": "11",
            "Block": "149",
            "label": "ALL SAINTS HSS MUTTOM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20206",
            "district": "11",
            "Block": "149",
            "label": "ST JOSEPH'S SCHOOL, ATTINKARAI, MANAVALAKURICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20207",
            "district": "11",
            "Block": "149",
            "label": "GHS CHERAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20208",
            "district": "11",
            "Block": "149",
            "label": "GHSS(G)-KADIAPATINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20209",
            "district": "11",
            "Block": "149",
            "label": "BMHSS MANAVALAKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20210",
            "district": "11",
            "Block": "149",
            "label": "DEVASAM HSS MONDAIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20211",
            "district": "11",
            "Block": "149",
            "label": "GHSS ANAKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20212",
            "district": "11",
            "Block": "149",
            "label": "ST. MICHAEL'S HIGH SCHOOL KODIMUNAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20213",
            "district": "11",
            "Block": "149",
            "label": "PROVIDENCE HIGHER SECONDARY SCHOOL,RITAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20214",
            "district": "11",
            "Block": "149",
            "label": "DEVI HSS KUNANKAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20215",
            "district": "11",
            "Block": "149",
            "label": "ST. IGNATIUS HSS KURUMPANAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20216",
            "district": "11",
            "Block": "149",
            "label": "VKP HSS COLACHEL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20217",
            "district": "11",
            "Block": "149",
            "label": "ST. ALEX MS-KOTTILPADU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20218",
            "district": "11",
            "Block": "149",
            "label": "GHS-ELAPPAVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20219",
            "district": "11",
            "Block": "149",
            "label": "ST. MARY'S HSS-COLACHEL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20220",
            "district": "11",
            "Block": "149",
            "label": "ST JAMES HS VANIYAKUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20221",
            "district": "11",
            "Block": "149",
            "label": "GOOD SHEPHERED MATRICULATION SCHOOL,SIMON COLONY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20222",
            "district": "11",
            "Block": "149",
            "label": "RC HSS KONAMKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20223",
            "district": "11",
            "Block": "149",
            "label": "GHS VILLUKURI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20224",
            "district": "11",
            "Block": "149",
            "label": "ST. LAWRENCE HSS MADATHATTUVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20225",
            "district": "11",
            "Block": "149",
            "label": "ST. ALOYSIUS HSS KARANKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20226",
            "district": "11",
            "Block": "149",
            "label": "LMS GHSS NEYYOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20227",
            "district": "11",
            "Block": "149",
            "label": "MYRNA MEMORIAL HSS BETHELPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20228",
            "district": "11",
            "Block": "149",
            "label": "K.J MCILROY MAT.S, NEYYOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20229",
            "district": "11",
            "Block": "149",
            "label": "CSI MATRICULATION HIGH SCHOOL SEYNAMVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20230",
            "district": "11",
            "Block": "149",
            "label": "JOCHEBED MATRICULATION SCHOOL KALLUKOOTTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20982",
            "district": "11",
            "Block": "149",
            "label": "JOTHI NILAYAM,PALAPPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21291",
            "district": "11",
            "Block": "149",
            "label": "ARUNACHALA MATRIC HIGHER SECONDARY SCHOOL, KANAKAMMAL GARDENS, KATTUVILAI, VELLICHANTHAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21622",
            "district": "11",
            "Block": "149",
            "label": "St.Sebastian Matric High School. Madathattuvilai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21818",
            "district": "11",
            "Block": "149",
            "label": "GOLDEN N&P SCHOOL-CHERAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21819",
            "district": "11",
            "Block": "149",
            "label": "ST.JOSEPH'S N&P SCHOOL-KALIMAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21904",
            "district": "11",
            "Block": "149",
            "label": "ADLINE  MATRIC SCHOOL-MADATHATTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21905",
            "district": "11",
            "Block": "149",
            "label": "VINS SCHOOL OF EXCELLENCE-CHUNKANKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22663",
            "district": "11",
            "Block": "149",
            "label": "ST.PAULA MONTAL SCHOOL,MUKKALAMPADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22850",
            "district": "11",
            "Block": "149",
            "label": "MARIA RAFOLS SCHOOL, LEKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23015",
            "district": "11",
            "Block": "149",
            "label": "SREE VIVEKANANDA CBSE SCHOOL,KOOTTUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23490",
            "district": "11",
            "Block": "149",
            "label": "MOTHER THERASA (SCHOOL FOR THE INTELLECTUAL DIASABLES) -KOVILANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23490",
            "district": "11",
            "Block": "149",
            "label": "MOTHER THERASA (SCHOOL FOR THE INTELLECTUAL DIASABLES) -KOVILANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "150": [
        {
            "key": "158",
            "district": "24",
            "Block": "150",
            "label": "A P A C M S PULIYANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "159",
            "district": "24",
            "Block": "150",
            "label": "DR VGN MATRIC HSS THIRUVENKADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "160",
            "district": "24",
            "Block": "150",
            "label": "GNANASUNDARIAMMAL MS SWAMINATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "161",
            "district": "24",
            "Block": "150",
            "label": "GOVT HIGH SCHOOL ALAMANAICKERPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "162",
            "district": "24",
            "Block": "150",
            "label": "GOVERNMENT HIGH SCHOOL, SEVALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "163",
            "district": "24",
            "Block": "150",
            "label": "GOVT HR SEC SCHOOL KALINGAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "164",
            "district": "24",
            "Block": "150",
            "label": "GOPAL NAICKER GOVT HR SEC SCHOOL KURUVIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "165",
            "district": "24",
            "Block": "150",
            "label": "GOVT HR SEC SCHOOL THIRUVENGADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "166",
            "district": "24",
            "Block": "150",
            "label": "GOVT HSS SAYAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "167",
            "district": "24",
            "Block": "150",
            "label": "HINDU MS AYYANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "168",
            "district": "24",
            "Block": "150",
            "label": "HINDU MIDDLE SCHOOL NARANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "169",
            "district": "24",
            "Block": "150",
            "label": "HINDU MIDDLE SCHOOL PARAIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "170",
            "district": "24",
            "Block": "150",
            "label": "HINDU MS VADAKKUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "171",
            "district": "24",
            "Block": "150",
            "label": "KALAIVANI MATRIC HIGHER SECONDARY SCHOOL  THIRUVENKADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "172",
            "district": "24",
            "Block": "150",
            "label": "MAIPARAI HIGH SCHOOL MAIPARAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "173",
            "district": "24",
            "Block": "150",
            "label": "MPM SWAMY MS ALAGANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "174",
            "district": "24",
            "Block": "150",
            "label": "P U MS ARAICHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "175",
            "district": "24",
            "Block": "150",
            "label": "PUMS  AYVAIPULIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "176",
            "district": "24",
            "Block": "150",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KULASEKARAPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "177",
            "district": "24",
            "Block": "150",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KURUNJAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "178",
            "district": "24",
            "Block": "150",
            "label": "PUMS MELA NALANDULA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "179",
            "district": "24",
            "Block": "150",
            "label": "PUMS PALANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "180",
            "district": "24",
            "Block": "150",
            "label": "PUMS UMAIYATHALAIVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "181",
            "district": "24",
            "Block": "150",
            "label": "P U MS VARAGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "182",
            "district": "24",
            "Block": "150",
            "label": "RC MS CHIDAMBARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "183",
            "district": "24",
            "Block": "150",
            "label": "R C M S IILAYARASANANDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "184",
            "district": "24",
            "Block": "150",
            "label": "THIRU SHANMUGIAH MS AVANIKONENTHAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "185",
            "district": "24",
            "Block": "150",
            "label": "SRI SIVARAMASUBBUSWAMY MIDDLE SCHOOL VELLAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "186",
            "district": "24",
            "Block": "150",
            "label": "ST.PAUL'S MATRICULATION SCHOOL,SEVALKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "187",
            "district": "24",
            "Block": "150",
            "label": "TDTA MS SAYAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "188",
            "district": "24",
            "Block": "150",
            "label": "TDTA V.M.MIDDLE SCHOOL, VAGAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "22646",
            "district": "24",
            "Block": "150",
            "label": "GOVT HIGH SCHOOL  PERUNKOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "151": [
        {
            "key": "4700",
            "district": "15",
            "Block": "151",
            "label": "THIRUVAVADUTHURAI ADHEENAM AIDED MS, THIRUVAVADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4738",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   KONERIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4827",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   AASIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4842",
            "district": "15",
            "Block": "151",
            "label": "PUMS NACHINARGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4851",
            "district": "15",
            "Block": "151",
            "label": "PUMS KANCHIVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4887",
            "district": "15",
            "Block": "151",
            "label": "PUMS PORUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4895",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   KAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4973",
            "district": "15",
            "Block": "151",
            "label": "PUMS PAZHAYAKUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4989",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   ALANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4995",
            "district": "15",
            "Block": "151",
            "label": "VELAMMA KALASHETHRA  MATRICULATION SCHOOL  KUTHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5085",
            "district": "15",
            "Block": "151",
            "label": "PUMS KANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5122",
            "district": "15",
            "Block": "151",
            "label": "PUMS KODIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5136",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL    ELANTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5160",
            "district": "15",
            "Block": "151",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   VAZHUVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5184",
            "district": "15",
            "Block": "151",
            "label": "PUMS KOZHAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5207",
            "district": "15",
            "Block": "151",
            "label": "PUMS THIRUVALANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5223",
            "district": "15",
            "Block": "151",
            "label": "SRI SOLEESWARA MS, KUTHALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5287",
            "district": "15",
            "Block": "151",
            "label": "PUMS GANGATHARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5311",
            "district": "15",
            "Block": "151",
            "label": "PUMS KUTTALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5315",
            "district": "15",
            "Block": "151",
            "label": "PUMS KOKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5557",
            "district": "15",
            "Block": "151",
            "label": "PUMS MEKKIRIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5609",
            "district": "15",
            "Block": "151",
            "label": "PUMS KILIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5628",
            "district": "15",
            "Block": "151",
            "label": "GANAPATHY NATIONAL AIDED MIDDLE SCHOOL, KUTHALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5939",
            "district": "15",
            "Block": "151",
            "label": "PUMS MANGAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6291",
            "district": "15",
            "Block": "151",
            "label": "ST.MICHAELS R.C AIDED MIDDLE SCHOOL, PATTAVARTHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6500",
            "district": "15",
            "Block": "151",
            "label": "ADW MS THIRUKKULAMBIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15929",
            "district": "15",
            "Block": "151",
            "label": "GOVT HIGH SCHOOL, AGARATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15930",
            "district": "15",
            "Block": "151",
            "label": "GHSS KILIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15931",
            "district": "15",
            "Block": "151",
            "label": "AL HITHANA MATRICULATION HIGH SCHOOL KILIYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15932",
            "district": "15",
            "Block": "151",
            "label": "GHS PERAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15933",
            "district": "15",
            "Block": "151",
            "label": "GHS VANATHIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15934",
            "district": "15",
            "Block": "151",
            "label": "PUMS KADALANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15935",
            "district": "15",
            "Block": "151",
            "label": "RAJ VIDHYALAYA MAT HR SEC. SCHOOL, KUTHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15936",
            "district": "15",
            "Block": "151",
            "label": "NEW STAR MATRICULATION SCHOOL, KUTHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15937",
            "district": "15",
            "Block": "151",
            "label": "GGHSS,KUTHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15938",
            "district": "15",
            "Block": "151",
            "label": "ST MARY'S MATRICULATION HIGH SCHOOL KUTTALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15939",
            "district": "15",
            "Block": "151",
            "label": "GHSS,KUTHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15940",
            "district": "15",
            "Block": "151",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,THIRUVAVADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15941",
            "district": "15",
            "Block": "151",
            "label": "NISHA MAT HIGHER SECONDARY SCHOOL, THERIZHADUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15942",
            "district": "15",
            "Block": "151",
            "label": "KAMBAR GHSS THERIZHANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15943",
            "district": "15",
            "Block": "151",
            "label": "JAIHIND MATRICULATION HIGHER SECONDARY  SCHOOL , THERIZHANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15944",
            "district": "15",
            "Block": "151",
            "label": "ANNAI VELANKANNI MATHA HSS PERAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15945",
            "district": "15",
            "Block": "151",
            "label": "Dr.G.S.KALYANASUNDARAM MATRIC HSS, PAZHAYAKUDALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15946",
            "district": "15",
            "Block": "151",
            "label": "GHSS KOMAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15947",
            "district": "15",
            "Block": "151",
            "label": "SRI RAMAKRISHNA MATRICULATION HR.SEC.SCHOOL, SIVANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15948",
            "district": "15",
            "Block": "151",
            "label": "GOOD LUCK MATRICULATION HR.SEC SCHOOL, SRIKANDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15949",
            "district": "15",
            "Block": "151",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SRIKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15950",
            "district": "15",
            "Block": "151",
            "label": "GOVT. HIGHER SECONDARY SCHOOL,VEZHAMURITHANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15951",
            "district": "15",
            "Block": "151",
            "label": "KSO HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "22219",
            "district": "15",
            "Block": "151",
            "label": "GOVT HIGH SCHOOL THIRUMANANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22569",
            "district": "15",
            "Block": "151",
            "label": "GOVERNMENT HIGH SCHOOL, THERIZHANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23038",
            "district": "15",
            "Block": "151",
            "label": "RAJ VIDYALAYA INTERNATIONAL SCHOOL,KUTHALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "152": [
        {
            "key": "3234",
            "district": "29",
            "Block": "152",
            "label": "SRI VIVEKANANDA MATRIC SCHOOL UMAR NAGAR SIRUDAIYUR, LALGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4054",
            "district": "29",
            "Block": "152",
            "label": "NEST MATRICULATION HSS,LALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4794",
            "district": "29",
            "Block": "152",
            "label": "LALGUDI LIONS MATRICULATION SCHOOL,LALGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5677",
            "district": "29",
            "Block": "152",
            "label": "PENI-EL MAT.HSS,ANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6461",
            "district": "29",
            "Block": "152",
            "label": "H.C KOTHARI BALAVIHAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14103",
            "district": "29",
            "Block": "152",
            "label": "PUMS,AGALANGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14104",
            "district": "29",
            "Block": "152",
            "label": "PUMS, AGALANGANALLURN.STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14105",
            "district": "29",
            "Block": "152",
            "label": "PUMS.SOUTH CHATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14106",
            "district": "29",
            "Block": "152",
            "label": "PUMS, ARIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14107",
            "district": "29",
            "Block": "152",
            "label": "TELC HIGH SCHOOL,SENGARAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14108",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.ESANAKKORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14109",
            "district": "29",
            "Block": "152",
            "label": "SAINT MARY MIDDLE SCHOOL, PERIYAVARSEELI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14110",
            "district": "29",
            "Block": "152",
            "label": "ADI DIRAVIDAR WELFARE  HIGHER SECONDARY SCHOOL , KEELANBIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14111",
            "district": "29",
            "Block": "152",
            "label": "ST.PHILOMINA'SRCAMS,MELAPERUNGAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14112",
            "district": "29",
            "Block": "152",
            "label": "VALLUVAR A.M.S.KOMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14113",
            "district": "29",
            "Block": "152",
            "label": "ST.ANTONY'S HSS,KONNAIKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14114",
            "district": "29",
            "Block": "152",
            "label": "P.U.MIDDLE SCHOOL, KOOHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14115",
            "district": "29",
            "Block": "152",
            "label": "ST.MARY'S HIGH SCHOOL,PALLIVIDAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14116",
            "district": "29",
            "Block": "152",
            "label": "ST.LEONARDI MATRIC. HIGHER SECONDARY SCHOOL,SAMAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14117",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.MANAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14118",
            "district": "29",
            "Block": "152",
            "label": "G.H.S.S.ANBIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14119",
            "district": "29",
            "Block": "152",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14120",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S. NATHAMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14121",
            "district": "29",
            "Block": "152",
            "label": "G.H.S. NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14122",
            "district": "29",
            "Block": "152",
            "label": "GOVERNMENT HIGH SCHOOL, MANDURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14123",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.PALLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14124",
            "district": "29",
            "Block": "152",
            "label": "G.H.S, PERUVALANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14125",
            "district": "29",
            "Block": "152",
            "label": "GHS, PUDHUR UTHAMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14126",
            "district": "29",
            "Block": "152",
            "label": "AROKIA ANNAI MATRICULATION SCHOOL,PUDURUTHAMANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14127",
            "district": "29",
            "Block": "152",
            "label": "PUMS, SATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14128",
            "district": "29",
            "Block": "152",
            "label": "INFANT JESUS HSS, S VINAYAGAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14129",
            "district": "29",
            "Block": "152",
            "label": "C.M.K.A.M.S.KATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14130",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.THACHANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14131",
            "district": "29",
            "Block": "152",
            "label": "G.H.S.THALAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14132",
            "district": "29",
            "Block": "152",
            "label": "P.U.M.S.THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14133",
            "district": "29",
            "Block": "152",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUMANAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14134",
            "district": "29",
            "Block": "152",
            "label": "GHSS, VALADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14135",
            "district": "29",
            "Block": "152",
            "label": "ST.GABRIEL'S M.S GABRIELPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14136",
            "district": "29",
            "Block": "152",
            "label": "ST.ANNES HR. SEC. SCHOOL, T.V NAGAR, LALGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14137",
            "district": "29",
            "Block": "152",
            "label": "G.H.S.S.LALGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14138",
            "district": "29",
            "Block": "152",
            "label": "LNP GIRLS' HSS, LALGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14139",
            "district": "29",
            "Block": "152",
            "label": "DON BOSCO H.S MANAKKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14140",
            "district": "29",
            "Block": "152",
            "label": "SAI VIDHYALAYA (CBSE) SCHOOL, LALGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14141",
            "district": "29",
            "Block": "152",
            "label": "Govt. Hr. Sec. School, Poovalur, Lalgudi",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21506",
            "district": "29",
            "Block": "152",
            "label": "ADWHS,MELAVALADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22519",
            "district": "29",
            "Block": "152",
            "label": "GHS, LALGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "153": [
        {
            "key": "5431",
            "district": "2",
            "Block": "153",
            "label": "CRESCENT MHSS,CHEYYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8221",
            "district": "2",
            "Block": "153",
            "label": "GIRL GHSS CHEYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8222",
            "district": "2",
            "Block": "153",
            "label": "ST MARYS AIDED  HS,CHEYYUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8223",
            "district": "2",
            "Block": "153",
            "label": "GBHSS CHEYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8224",
            "district": "2",
            "Block": "153",
            "label": "LITTLE FLOWER (G) HSS  CHEYYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8225",
            "district": "2",
            "Block": "153",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL CHEYYUR-603302",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8226",
            "district": "2",
            "Block": "153",
            "label": "ST.MARY'S MATRICULATION SCHOOL, CHEYYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8227",
            "district": "2",
            "Block": "153",
            "label": "SAINT EXUPERY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8228",
            "district": "2",
            "Block": "153",
            "label": "RCMS SENGATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8229",
            "district": "2",
            "Block": "153",
            "label": "PUMS KADUGAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8230",
            "district": "2",
            "Block": "153",
            "label": "PUMS THENPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8231",
            "district": "2",
            "Block": "153",
            "label": "GHSS, THIRUVATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8232",
            "district": "2",
            "Block": "153",
            "label": "PUMS PERIYAVELIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8233",
            "district": "2",
            "Block": "153",
            "label": "St. Joseph HSS, Pavinjur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8234",
            "district": "2",
            "Block": "153",
            "label": "PUMS SEEVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8235",
            "district": "2",
            "Block": "153",
            "label": "PUMS SEEKINANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8236",
            "district": "2",
            "Block": "153",
            "label": "Marg Nava Jothi Seekinankuppam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8237",
            "district": "2",
            "Block": "153",
            "label": "GHS VADAKKUVAYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8238",
            "district": "2",
            "Block": "153",
            "label": "PUMS LATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8239",
            "district": "2",
            "Block": "153",
            "label": "PUMS KALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8240",
            "district": "2",
            "Block": "153",
            "label": "PUMS THONDAMANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8241",
            "district": "2",
            "Block": "153",
            "label": "PUMS THATTAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8242",
            "district": "2",
            "Block": "153",
            "label": "PUMS PARAMESWARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8243",
            "district": "2",
            "Block": "153",
            "label": "GHS PARAMESWARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8244",
            "district": "2",
            "Block": "153",
            "label": "St. Thomas Matric Hr.Sec. SCHOOL , Nerkunapattu.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8245",
            "district": "2",
            "Block": "153",
            "label": "GHSS  KUVATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8246",
            "district": "2",
            "Block": "153",
            "label": "PUMS KADALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8247",
            "district": "2",
            "Block": "153",
            "label": "SWAMI DAYANANDA ROTARY MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8248",
            "district": "2",
            "Block": "153",
            "label": "PUMS NEDUMARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8249",
            "district": "2",
            "Block": "153",
            "label": "PUMS AKKINAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8250",
            "district": "2",
            "Block": "153",
            "label": "GHSS, ANAICUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8251",
            "district": "2",
            "Block": "153",
            "label": "GHS  KODUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8252",
            "district": "2",
            "Block": "153",
            "label": "PUMS MADAIYAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8253",
            "district": "2",
            "Block": "153",
            "label": "RC(AIDED)MS POONCHERY",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8254",
            "district": "2",
            "Block": "153",
            "label": "SACRED HEART HS PARAMANKENIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8255",
            "district": "2",
            "Block": "153",
            "label": "PUMS MUGAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21429",
            "district": "2",
            "Block": "153",
            "label": "GHS ,Neelamangalam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22041",
            "district": "2",
            "Block": "153",
            "label": "Layola Matric School, Cheyyur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23157",
            "district": "2",
            "Block": "153",
            "label": "GOOD SHEPHERD INTERNATIONAL SCHOOL VEPANCHERY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "154": [
        {
            "key": "866",
            "district": "32",
            "Block": "154",
            "label": "PUMS METRATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "921",
            "district": "32",
            "Block": "154",
            "label": "PUMS,POLARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "974",
            "district": "32",
            "Block": "154",
            "label": "PUMS MADATHUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1062",
            "district": "32",
            "Block": "154",
            "label": "PUMS MADATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1107",
            "district": "32",
            "Block": "154",
            "label": "PUMS SAMARAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1185",
            "district": "32",
            "Block": "154",
            "label": "PUMS SALARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1880",
            "district": "32",
            "Block": "154",
            "label": "PUMS,MYVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1895",
            "district": "32",
            "Block": "154",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3424",
            "district": "32",
            "Block": "154",
            "label": "PUMS NG PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4491",
            "district": "32",
            "Block": "154",
            "label": "PUMS RUTHIRAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4844",
            "district": "32",
            "Block": "154",
            "label": "PUMS,THANTHONI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12969",
            "district": "32",
            "Block": "154",
            "label": "GHSS MADATHUKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12970",
            "district": "32",
            "Block": "154",
            "label": "NATIONAL MATRICULATION HR .SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12971",
            "district": "32",
            "Block": "154",
            "label": "GHS SK PUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12972",
            "district": "32",
            "Block": "154",
            "label": "JSR HSS MADATHUKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12973",
            "district": "32",
            "Block": "154",
            "label": "GHS SOLAMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12974",
            "district": "32",
            "Block": "154",
            "label": "SRI VENKATA KRISHNA HSS,KANIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12975",
            "district": "32",
            "Block": "154",
            "label": "GHSS KARATHOLUVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12976",
            "district": "32",
            "Block": "154",
            "label": "GHS THUNGAVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12977",
            "district": "32",
            "Block": "154",
            "label": "ANUGRAHA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12978",
            "district": "32",
            "Block": "154",
            "label": "VIVEKANANDA VIDYALAYAM HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12979",
            "district": "32",
            "Block": "154",
            "label": "GHS PAPPANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12980",
            "district": "32",
            "Block": "154",
            "label": "PUMS,PERUMALPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12981",
            "district": "32",
            "Block": "154",
            "label": "GHSS KOMARALINGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12982",
            "district": "32",
            "Block": "154",
            "label": "OM SAKTHI Hr Sec School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12983",
            "district": "32",
            "Block": "154",
            "label": "GHS KUPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12984",
            "district": "32",
            "Block": "154",
            "label": "GHS KADATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21599",
            "district": "32",
            "Block": "154",
            "label": "JSR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22145",
            "district": "32",
            "Block": "154",
            "label": "UNITED PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23169",
            "district": "32",
            "Block": "154",
            "label": "BHARATHI VIDHYA MANDIR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "155": [
        {
            "key": "426",
            "district": "31",
            "Block": "155",
            "label": "GHS NAYAKANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "1885",
            "district": "31",
            "Block": "155",
            "label": "GRACIOUS MATRIC SCHOOL, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2224",
            "district": "31",
            "Block": "155",
            "label": "IELC HR SEC SCHOOL FOR THE DEAF -AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2274",
            "district": "31",
            "Block": "155",
            "label": "PUMS KADHAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2331",
            "district": "31",
            "Block": "155",
            "label": "SRI SARASWATHI VIDYALAYA MATRIC HSS, PALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2361",
            "district": "31",
            "Block": "155",
            "label": "PUMS CHINNAVARIGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2416",
            "district": "31",
            "Block": "155",
            "label": "PUMS C.V.PATTARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2946",
            "district": "31",
            "Block": "155",
            "label": "BLESSO MATRIC SCHOOL, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3057",
            "district": "31",
            "Block": "155",
            "label": "PUMS ATHIMAKULAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3548",
            "district": "31",
            "Block": "155",
            "label": "PUMS PERIYAVARIGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3663",
            "district": "31",
            "Block": "155",
            "label": "PUMS KUTTAKINTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4003",
            "district": "31",
            "Block": "155",
            "label": "MMS PANNEER SELVAM NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4192",
            "district": "31",
            "Block": "155",
            "label": "B.S MATRIC SCHOOL, PACHAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4259",
            "district": "31",
            "Block": "155",
            "label": "MMS GOVINDHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4569",
            "district": "31",
            "Block": "155",
            "label": "PUMS EACHAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4755",
            "district": "31",
            "Block": "155",
            "label": "AS-SHUKOOR MATRIC SCHOOL, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4762",
            "district": "31",
            "Block": "155",
            "label": "MMS BETHELEHEM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5826",
            "district": "31",
            "Block": "155",
            "label": "PUMS NARIYAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5923",
            "district": "31",
            "Block": "155",
            "label": "PUMS,KATTUKOLLAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5954",
            "district": "31",
            "Block": "155",
            "label": "SEVENTH DAY ADVENTIST MATRIC HSS , SANDRORKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6234",
            "district": "31",
            "Block": "155",
            "label": "PUMS METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6494",
            "district": "31",
            "Block": "155",
            "label": "PUMS CHINNAPALLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6540",
            "district": "31",
            "Block": "155",
            "label": "PUMS KANDRAYANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6636",
            "district": "31",
            "Block": "155",
            "label": "T.ABDUL WAHID MATRIC HSS ,AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8865",
            "district": "31",
            "Block": "155",
            "label": "SHRI KALAIMAGAL MATRIC SCHOOL , OOMERABAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8881",
            "district": "31",
            "Block": "155",
            "label": "SAINT ANTONYS HSS UDAYENDIRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8883",
            "district": "31",
            "Block": "155",
            "label": "GHSS VADACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8884",
            "district": "31",
            "Block": "155",
            "label": "GHS MELSANANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8885",
            "district": "31",
            "Block": "155",
            "label": "PUMS MALAYAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8886",
            "district": "31",
            "Block": "155",
            "label": "GHSS ARANGALDURUGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8887",
            "district": "31",
            "Block": "155",
            "label": "PUMS KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8888",
            "district": "31",
            "Block": "155",
            "label": "AMS VEERANGKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8889",
            "district": "31",
            "Block": "155",
            "label": "GHSS DEVALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8890",
            "district": "31",
            "Block": "155",
            "label": "HINDU HSS KARUMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8891",
            "district": "31",
            "Block": "155",
            "label": "PUMS PARSANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8892",
            "district": "31",
            "Block": "155",
            "label": "ADWMS VENKATASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8893",
            "district": "31",
            "Block": "155",
            "label": "PUMS KAILASAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8894",
            "district": "31",
            "Block": "155",
            "label": "IQRA MATRIC HIGHER SECONDARY  SCHOOL, OOMERABAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8895",
            "district": "31",
            "Block": "155",
            "label": "MUHAMMADIA AIDED HIGH SCHOOL, OOMERABAD",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8896",
            "district": "31",
            "Block": "155",
            "label": "GHS MITTALAMMELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8897",
            "district": "31",
            "Block": "155",
            "label": "PUMS AYTHAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8898",
            "district": "31",
            "Block": "155",
            "label": "ISLAMIAH JAMA - ATH HIGH SCHOOL NARIYAMBUT",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8908",
            "district": "31",
            "Block": "155",
            "label": "ADWMS,SOMALAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8909",
            "district": "31",
            "Block": "155",
            "label": "PUMS,VADAPUDUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8910",
            "district": "31",
            "Block": "155",
            "label": "ACSMHSS VADAPUDUPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8911",
            "district": "31",
            "Block": "155",
            "label": "PUMS,KILMURUNGAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8912",
            "district": "31",
            "Block": "155",
            "label": "PUMS,VENGILI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8913",
            "district": "31",
            "Block": "155",
            "label": "GHSS MADANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8914",
            "district": "31",
            "Block": "155",
            "label": "Tagore National High School",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8920",
            "district": "31",
            "Block": "155",
            "label": "PUMS,THIRUMALAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8921",
            "district": "31",
            "Block": "155",
            "label": "MMS SANDRORKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8922",
            "district": "31",
            "Block": "155",
            "label": "CONCORDIA  AMS AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8923",
            "district": "31",
            "Block": "155",
            "label": "GHS AMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8924",
            "district": "31",
            "Block": "155",
            "label": "CONCORDIA HSS AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8925",
            "district": "31",
            "Block": "155",
            "label": "HINDU G HSS AMBUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8926",
            "district": "31",
            "Block": "155",
            "label": "HINDU HR SEC SCHOOL AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8927",
            "district": "31",
            "Block": "155",
            "label": "MAZHARUL ULOOM HSS,AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8928",
            "district": "31",
            "Block": "155",
            "label": "ANAIKAR ORIENTAL (ARABIC) HR.SEC.SCHOOL, AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8929",
            "district": "31",
            "Block": "155",
            "label": "HASNATH-E-JARIA GIRLS HR. SEC. SCHOOL AMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8930",
            "district": "31",
            "Block": "155",
            "label": "HABIBIA ORIENTAL GHSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8931",
            "district": "31",
            "Block": "155",
            "label": "SRI VIVEKANANDA MATRIC HSS, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8932",
            "district": "31",
            "Block": "155",
            "label": "BETHEL MATRIC HSS AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8933",
            "district": "31",
            "Block": "155",
            "label": "SRI VIDYA VIHAR MHSS,AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8934",
            "district": "31",
            "Block": "155",
            "label": "SHUKOOR MATRIC HSS, AMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8935",
            "district": "31",
            "Block": "155",
            "label": "GHS NACHARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8936",
            "district": "31",
            "Block": "155",
            "label": "GHS PERIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8937",
            "district": "31",
            "Block": "155",
            "label": "AMBUR KURUNJI MATRIC HSS, PERIYANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8938",
            "district": "31",
            "Block": "155",
            "label": "PUMS,SOLUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8939",
            "district": "31",
            "Block": "155",
            "label": "PUMS,ALANKUPPAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8940",
            "district": "31",
            "Block": "155",
            "label": "HINDU AMS VINNAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8941",
            "district": "31",
            "Block": "155",
            "label": "GHS MINNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8942",
            "district": "31",
            "Block": "155",
            "label": "EMBESSO MATRIC HSS , MARAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20901",
            "district": "31",
            "Block": "155",
            "label": "RADIANT MATRIC SCHOOL, KADAVALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21767",
            "district": "31",
            "Block": "155",
            "label": "MJR MATRICULATION SCHOOL VEERANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21769",
            "district": "31",
            "Block": "155",
            "label": "FUTURE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21770",
            "district": "31",
            "Block": "155",
            "label": "WISDOM PARK INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21930",
            "district": "31",
            "Block": "155",
            "label": "V.NALINI VIDYALAYA MATRIC SCHOOL, NARIYAMBUT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22069",
            "district": "31",
            "Block": "155",
            "label": "KSR MATRIC PUBLIC HIGHER SECONDARY SCHOOL, MADHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22392",
            "district": "31",
            "Block": "155",
            "label": "S.R.V HI TECH MATRICULATION SCHOOL, MANIYARAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22393",
            "district": "31",
            "Block": "155",
            "label": "VIVEKA MATRICULATION SCHOOL, MARAPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23592",
            "district": "31",
            "Block": "155",
            "label": "KSR MYTIKAS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23592",
            "district": "31",
            "Block": "155",
            "label": "KSR MYTIKAS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "156": [
        {
            "key": "2007",
            "district": "4",
            "Block": "156",
            "label": "ST.ANN'S MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2254",
            "district": "4",
            "Block": "156",
            "label": "Dr.V.GENGUSWAMY NAIDU MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5641",
            "district": "4",
            "Block": "156",
            "label": "SRI.P.MALLAIYAN MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5988",
            "district": "4",
            "Block": "156",
            "label": "PMG MATRIC HIGHER SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6634",
            "district": "4",
            "Block": "156",
            "label": "KALAIVANI MODEL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12717",
            "district": "4",
            "Block": "156",
            "label": "GHS -VAZHUKKUPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12718",
            "district": "4",
            "Block": "156",
            "label": "PUMS-PACHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12719",
            "district": "4",
            "Block": "156",
            "label": "UNIT MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12720",
            "district": "4",
            "Block": "156",
            "label": "PUMS-PALATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12721",
            "district": "4",
            "Block": "156",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL OTHAKKALMANDAPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12722",
            "district": "4",
            "Block": "156",
            "label": "GHSS MALUMICHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12723",
            "district": "4",
            "Block": "156",
            "label": "GHSJJ NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12724",
            "district": "4",
            "Block": "156",
            "label": "PUMS-SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12725",
            "district": "4",
            "Block": "156",
            "label": "PUMS-OKKILIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12726",
            "district": "4",
            "Block": "156",
            "label": "SREE SAKTHI MATRIC HR SECSCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12727",
            "district": "4",
            "Block": "156",
            "label": "GHSS -CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12728",
            "district": "4",
            "Block": "156",
            "label": "ZION MODEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12729",
            "district": "4",
            "Block": "156",
            "label": "GLOBAL PATHWAYS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12730",
            "district": "4",
            "Block": "156",
            "label": "AVATAR PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12731",
            "district": "4",
            "Block": "156",
            "label": "PUMS-BODIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12732",
            "district": "4",
            "Block": "156",
            "label": "RAVEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12733",
            "district": "4",
            "Block": "156",
            "label": "VEDANTA ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12734",
            "district": "4",
            "Block": "156",
            "label": "PUMS-IDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12735",
            "district": "4",
            "Block": "156",
            "label": "ELGI MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12736",
            "district": "4",
            "Block": "156",
            "label": "GHSS-VELLALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12737",
            "district": "4",
            "Block": "156",
            "label": "NIRMALA MATHA CONVENT MATRIC HR SEC SCHOOL, VELLALORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12738",
            "district": "4",
            "Block": "156",
            "label": "NACHIMUTHU GOUNDER RUKMANI AMMAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12739",
            "district": "4",
            "Block": "156",
            "label": "LINGAN VIDYALAYAM MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "12740",
            "district": "4",
            "Block": "156",
            "label": "ACC MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12741",
            "district": "4",
            "Block": "156",
            "label": "PUMS-ARIVOLI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12742",
            "district": "4",
            "Block": "156",
            "label": "GHS-KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23689",
            "district": "4",
            "Block": "156",
            "label": "Sri Meenakshi Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "12743",
            "district": "4",
            "Block": "156",
            "label": "GHSS MADUKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12744",
            "district": "4",
            "Block": "156",
            "label": "GHS MARAPPALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12745",
            "district": "4",
            "Block": "156",
            "label": "PUMS-ETTIMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12746",
            "district": "4",
            "Block": "156",
            "label": "WESTERN GHATS INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12747",
            "district": "4",
            "Block": "156",
            "label": "GTRHS MAVUTHAMPATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12748",
            "district": "4",
            "Block": "156",
            "label": "PUMS RANGASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12749",
            "district": "4",
            "Block": "156",
            "label": "PUMS CHINNANAVAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12750",
            "district": "4",
            "Block": "156",
            "label": "GHSS-PICHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12751",
            "district": "4",
            "Block": "156",
            "label": "PUMS-THIRUMALAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12752",
            "district": "4",
            "Block": "156",
            "label": "SRI L.DHARMMA KANNAN VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12753",
            "district": "4",
            "Block": "156",
            "label": "GHS KUMITIPATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21078",
            "district": "4",
            "Block": "156",
            "label": "GEDEE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21312",
            "district": "4",
            "Block": "156",
            "label": "AMRITA VIDYALAYAM SENIOR SECONDARY SCHOOL, ETTIMADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21358",
            "district": "4",
            "Block": "156",
            "label": "GHS MYLERIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21874",
            "district": "4",
            "Block": "156",
            "label": "BRINDHAVAN VIDHYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21889",
            "district": "4",
            "Block": "156",
            "label": "L.K.MAHA VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22137",
            "district": "4",
            "Block": "156",
            "label": "THE SAMHITA  ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22138",
            "district": "4",
            "Block": "156",
            "label": "SREE VISHNU VIDHYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22474",
            "district": "4",
            "Block": "156",
            "label": "VIAN VEENAI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22475",
            "district": "4",
            "Block": "156",
            "label": "GOVERNMENT HIGH SCHOOL-KANDEGOUNDEN CHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22696",
            "district": "4",
            "Block": "156",
            "label": "NIRMALA MATHA CONVENT SCHOOL (ICSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22935",
            "district": "4",
            "Block": "156",
            "label": "AATHREIYA SCHOOL FOR EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22954",
            "district": "4",
            "Block": "156",
            "label": "GHS OTHAKKALMANDAPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23281",
            "district": "4",
            "Block": "156",
            "label": "NEHRU INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23312",
            "district": "4",
            "Block": "156",
            "label": "THAAMARAI WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23462",
            "district": "4",
            "Block": "156",
            "label": "FAITH MODEL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23462",
            "district": "4",
            "Block": "156",
            "label": "FAITH MODEL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23858",
            "district": "4",
            "Block": "156",
            "label": "PUMS RANGASAMUTHIRAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23859",
            "district": "4",
            "Block": "156",
            "label": "ELGI SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23860",
            "district": "4",
            "Block": "156",
            "label": "VIAN VEENAI PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "157": [
        {
            "key": "2342",
            "district": "25",
            "Block": "157",
            "label": "PUMS - PULAVANCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4104",
            "district": "25",
            "Block": "157",
            "label": "SRI RAMAVILAS AIDED MIDDLE SCHOOL - ELANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4591",
            "district": "25",
            "Block": "157",
            "label": "PUMS - AVIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4596",
            "district": "25",
            "Block": "157",
            "label": "PUMS - MADUKKUR SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4672",
            "district": "25",
            "Block": "157",
            "label": "PUMS - ANDAMI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4743",
            "district": "25",
            "Block": "157",
            "label": "PUMS - RAMAMBALPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5147",
            "district": "25",
            "Block": "157",
            "label": "PUMS - VIKRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5229",
            "district": "25",
            "Block": "157",
            "label": "PUMS - KADANTHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5239",
            "district": "25",
            "Block": "157",
            "label": "PUMS - SOKKANAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5270",
            "district": "25",
            "Block": "157",
            "label": "PUMS - KARUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16689",
            "district": "25",
            "Block": "157",
            "label": "PUMS - ALAMPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16690",
            "district": "25",
            "Block": "157",
            "label": "GHS (GIRLS) - ALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16691",
            "district": "25",
            "Block": "157",
            "label": "GHSS - ALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16692",
            "district": "25",
            "Block": "157",
            "label": "GHS - ATHIVETTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16693",
            "district": "25",
            "Block": "157",
            "label": "GHS - KANYAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16694",
            "district": "25",
            "Block": "157",
            "label": "GHSS- KASANKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16695",
            "district": "25",
            "Block": "157",
            "label": "GHS - KEELAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16696",
            "district": "25",
            "Block": "157",
            "label": "PUMS - MANNANKADU NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16697",
            "district": "25",
            "Block": "157",
            "label": "GHS - MOOTHAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16698",
            "district": "25",
            "Block": "157",
            "label": "GHS - PERIYAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16699",
            "district": "25",
            "Block": "157",
            "label": "GHSS - SIRAMELKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16700",
            "district": "25",
            "Block": "157",
            "label": "SRI VENKATESWARA VIDHYA MANDHIR HR SEC SCHOOL, THALIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16701",
            "district": "25",
            "Block": "157",
            "label": "GHS - VATTAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16702",
            "district": "25",
            "Block": "157",
            "label": "PUMS - VEPPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16703",
            "district": "25",
            "Block": "157",
            "label": "GANDHI MATRIC HSS-VIKRAMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16704",
            "district": "25",
            "Block": "157",
            "label": "WESTERN MATRIC HS - VIKRAMAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16705",
            "district": "25",
            "Block": "157",
            "label": "PUMS - MADUKKUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16706",
            "district": "25",
            "Block": "157",
            "label": "GHSS(GIRLS) -MADUKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16707",
            "district": "25",
            "Block": "157",
            "label": "PUMS - MADUKKUR P.K. ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16708",
            "district": "25",
            "Block": "157",
            "label": "GHSS(BOYS) - MADUKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16709",
            "district": "25",
            "Block": "157",
            "label": "FATHIMA MARIAM - HSS - MADUKUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21649",
            "district": "25",
            "Block": "157",
            "label": "SRI VINAYAKA PUBLIC SCHOOL -MOHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23559",
            "district": "25",
            "Block": "157",
            "label": "MAHATHMA GANDHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23559",
            "district": "25",
            "Block": "157",
            "label": "MAHATHMA GANDHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "158": [
        {
            "key": "1010",
            "district": "14",
            "Block": "158",
            "label": "SARACENS MATRIC HR SEC SCHOOL, MELAMADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1375",
            "district": "14",
            "Block": "158",
            "label": "MRR.MAVMM MATRIC HR SEC SCHOOL, SATHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1501",
            "district": "14",
            "Block": "158",
            "label": "TAGORE VIDYALAYAM MATRIC HR SEC SCHOOL, SADASIVA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1946",
            "district": "14",
            "Block": "158",
            "label": "FUSCO'S MATRIC HIGH SCHOOL, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2004",
            "district": "14",
            "Block": "158",
            "label": "MODERN MAT.S OTHAKADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2186",
            "district": "14",
            "Block": "158",
            "label": "MAHATMA MONTESSORI MATRIC HR SEC SCHOOL, K.K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2188",
            "district": "14",
            "Block": "158",
            "label": "ANNAMALAIYAAR MATRIC HR SEC SCHOOL, ANNA NAGAR EAST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2571",
            "district": "14",
            "Block": "158",
            "label": "BPM MATRIC HIGH SCHOOL, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2854",
            "district": "14",
            "Block": "158",
            "label": "ARULMALAR MATRIC HR SEC SCHOOL, K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3248",
            "district": "14",
            "Block": "158",
            "label": "KALYANI MATRIC HR SEC SCHOOL, SAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23354",
            "district": "14",
            "Block": "158",
            "label": "Chethana Mat. Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4084",
            "district": "14",
            "Block": "158",
            "label": "KASTHURIBA GANDHI MATRICULATION  SCHOOL, MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4619",
            "district": "14",
            "Block": "158",
            "label": "LAKSHMI MATRIC HR SEC SCHOOL, VEERAPANCHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4873",
            "district": "14",
            "Block": "158",
            "label": "NOYES MATRIC HR SEC SCHOOL,NARIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4965",
            "district": "14",
            "Block": "158",
            "label": "CROWN MATRIC HR SEC SCHOOL, GORIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5013",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S.MEENAKSHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5282",
            "district": "14",
            "Block": "158",
            "label": "FUSCO'S MATRIC HR SEC SCHOOL, YAGAPPA NAGAR,VANDIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6106",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. MELAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6210",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S THATCHANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6367",
            "district": "14",
            "Block": "158",
            "label": "AKNU S SUNDAR MATRIC HR SEC SCHOOL, KALIKAPPAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17978",
            "district": "14",
            "Block": "158",
            "label": "ADI.D.M.S CHEMPIANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17979",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S ARUMBANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17980",
            "district": "14",
            "Block": "158",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17981",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S CHINNA MANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17982",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, MANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17983",
            "district": "14",
            "Block": "158",
            "label": "GOVT ADW HIGH SCHOOL, KURUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17984",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. KALLANTHIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17985",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, MATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17986",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, MAYANDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17987",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. CHITTAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17988",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. KODIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17989",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. MANGALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17990",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. UTHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17991",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, VANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17992",
            "district": "14",
            "Block": "158",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUMOHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17993",
            "district": "14",
            "Block": "158",
            "label": "T.V.S. LAKSHMI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17994",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S KARUPPAYURANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17995",
            "district": "14",
            "Block": "158",
            "label": "APPAR HR SEC SCHOOL, KARUPPAYURANI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17996",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. KABIR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17997",
            "district": "14",
            "Block": "158",
            "label": "LIONS MATRIC HR SEC SCHOOL,  KARUPPAYURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17998",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, ANDARKOTTARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17999",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, VARICHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18000",
            "district": "14",
            "Block": "158",
            "label": "GOVT ADW HR SEC SCHOOL, ELAMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18001",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S KONDAPETHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18002",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. L.K.B.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18003",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL. SAKKIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18004",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S SAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18005",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S. KARSERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18006",
            "district": "14",
            "Block": "158",
            "label": "P.U.M.S.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18007",
            "district": "14",
            "Block": "158",
            "label": "GOVT HR SEC SCHOOL, KALIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18008",
            "district": "14",
            "Block": "158",
            "label": "FES.MATRICULATION SCHOOL-GOMATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18009",
            "district": "14",
            "Block": "158",
            "label": "GOVT. MODEL GIRLS HR SEC SCHOOL, Y.OTHAKKADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18010",
            "district": "14",
            "Block": "158",
            "label": "GOVT BOYS HR SEC SCHOOL, Y.OTHAKKADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18011",
            "district": "14",
            "Block": "158",
            "label": "Y.O N.U.M.S OTHAKADAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18012",
            "district": "14",
            "Block": "158",
            "label": "RAGHAV.PUBLIC MATRICULATION SCHOOL-Y.OTHAKKADAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18013",
            "district": "14",
            "Block": "158",
            "label": "MAHARISHI VIDYA MANDIR MATRIC  SCHOOL, K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23367",
            "district": "14",
            "Block": "158",
            "label": "K KANDASAMY KOTHANAR HR.SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18014",
            "district": "14",
            "Block": "158",
            "label": "COR.M.S SATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18015",
            "district": "14",
            "Block": "158",
            "label": "THANAM MIDDLE SCHOOL, GANDHI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18016",
            "district": "14",
            "Block": "158",
            "label": "AMBIKA MATRIC HR SEC SCHOOL, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18017",
            "district": "14",
            "Block": "158",
            "label": "CORPORATION ELANGO HR SEC SCHOOL, SHENOY NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18018",
            "district": "14",
            "Block": "158",
            "label": "TALLAKULAM SENGUNTHAR URAVINMURAI HIGH SCHOOL, TALLAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18019",
            "district": "14",
            "Block": "158",
            "label": "AMERICAN COLLEGE HR SEC SCHOOL, TALLAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18020",
            "district": "14",
            "Block": "158",
            "label": "CORPORATION HIGH SCHOOL, TALLAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18021",
            "district": "14",
            "Block": "158",
            "label": "NEHRU VIDYASALAI HR SEC SCHOOL, VETHAVALLI NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18022",
            "district": "14",
            "Block": "158",
            "label": "KATIE WILCOX MATRIC HR SEC SCHOOL, NARIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18023",
            "district": "14",
            "Block": "158",
            "label": "O.C.P.M GIRLS HR SEC SCHOOL, TALLAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18024",
            "district": "14",
            "Block": "158",
            "label": "SAIRAM MATRIC HR SEC SCHOOL, GORIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18025",
            "district": "14",
            "Block": "158",
            "label": "CORPORATION PONMUDIYAR GIRLS HR SEC SCHOOL, GORIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21401",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, RAJAKKOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22243",
            "district": "14",
            "Block": "158",
            "label": "R.C HIGH SCHOOL, KATHAKINARU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22244",
            "district": "14",
            "Block": "158",
            "label": "GOVT HIGH SCHOOL, POIGAIKARAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22245",
            "district": "14",
            "Block": "158",
            "label": "VALLABA VID CBSE S, THIRUVIZHANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22246",
            "district": "14",
            "Block": "158",
            "label": "P.N. MATRICULATION HIGHER SECONDARY SCHOOL- MANGALAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22602",
            "district": "14",
            "Block": "158",
            "label": "MAHATMA GLOBAL GATEWAY CBSE SCHOOL, VEERAPANCHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22603",
            "district": "14",
            "Block": "158",
            "label": "MAHATMA GLOBAL GATEWAY INTERNATION S, VEERAPANCHAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22764",
            "district": "14",
            "Block": "158",
            "label": "GOVT. SPECIAL SCHOOL FOR VI, K.K.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22765",
            "district": "14",
            "Block": "158",
            "label": "SPARKS VIDYALA SPECIAL S, SATHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22883",
            "district": "14",
            "Block": "158",
            "label": "OLR SPECIAL SCHOOL, VANDIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22997",
            "district": "14",
            "Block": "158",
            "label": "MAHAJANA VIDYALAYA MAT. S, UTHANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23192",
            "district": "14",
            "Block": "158",
            "label": "KENDIRIYA VIDYALAYA ITBP SCHOOL, IDAYAPATTI",
            "Sch_type": "Central Govt",
            "Category": "Middle School"
        },
        {
            "key": "23664",
            "district": "14",
            "Block": "158",
            "label": "Ellora International Kids School",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "23506",
            "district": "14",
            "Block": "158",
            "label": "THE INDIAN PUBLIC SCHOOL, KARUPPAYURANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23507",
            "district": "14",
            "Block": "158",
            "label": "MPF INTERNATIONAL SCHOOL, MAYILANGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23506",
            "district": "14",
            "Block": "158",
            "label": "THE INDIAN PUBLIC SCHOOL, KARUPPAYURANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23507",
            "district": "14",
            "Block": "158",
            "label": "MPF INTERNATIONAL SCHOOL, MAYILANGUNDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "159": [
        {
            "key": "613",
            "district": "14",
            "Block": "159",
            "label": "GOOD SHEPHERD MATRIC HR SEC SCHOOL, KANAGAVEL COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "994",
            "district": "14",
            "Block": "159",
            "label": "SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, MAHABOOBPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2014",
            "district": "14",
            "Block": "159",
            "label": "S.R.W.W.O MATRIC HIGH SCHOOL, RAILWAY COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2035",
            "district": "14",
            "Block": "159",
            "label": "ANANTH MEMORIAL MATRIC HR SEC SCHOOL ARAPPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2725",
            "district": "14",
            "Block": "159",
            "label": "ST.BRITTO MATRIC HIGH SCHOOL, GNANAOLIVUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3237",
            "district": "14",
            "Block": "159",
            "label": "SIVAKASI NADARS MATRIC HR SEC SCHOOL, ELLIS NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3322",
            "district": "14",
            "Block": "159",
            "label": "CHANDLER MATRIC HR SEC SCHOOL, PONNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6302",
            "district": "14",
            "Block": "159",
            "label": "CAPRON HALL GIRLS HR SEC SCHOOL, PONNAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6714",
            "district": "14",
            "Block": "159",
            "label": "OUR LADY MATRIC HR SEC SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18380",
            "district": "14",
            "Block": "159",
            "label": "COR.M.S SHENOY NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18381",
            "district": "14",
            "Block": "159",
            "label": "SUBBARAYALU MEMORIAL  MIDDLE SCHOOL, ALWARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18382",
            "district": "14",
            "Block": "159",
            "label": "COR.M.S KARUMBALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18383",
            "district": "14",
            "Block": "159",
            "label": "COR.M.S RAMNAD A.R.LINE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18384",
            "district": "14",
            "Block": "159",
            "label": "JEYABHARATH M.S PALAM ST.ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18385",
            "district": "14",
            "Block": "159",
            "label": "MANOHARA M.S SELLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18386",
            "district": "14",
            "Block": "159",
            "label": "ST.JOHN DE BRITTO MS, THATHTHANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18387",
            "district": "14",
            "Block": "159",
            "label": "MLWA HR SEC SCHOOL, PONNAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18388",
            "district": "14",
            "Block": "159",
            "label": "MANGAYARKARASI HR SEC SCHOOL, PONNAGRAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18389",
            "district": "14",
            "Block": "159",
            "label": "VIKAASA MATRIC Hr.Sec. SCHOOL, PONNAGRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18390",
            "district": "14",
            "Block": "159",
            "label": "T.E.L.C. JEYARAJ ANNAPACKIAM HIGH SCHOOL, PONNAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18391",
            "district": "14",
            "Block": "159",
            "label": "SRI MEENAKSHI MIDDLE SCHOOL, SHUNMUGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18392",
            "district": "14",
            "Block": "159",
            "label": "GEORGE M.S RAJENDRA ST. MADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18393",
            "district": "14",
            "Block": "159",
            "label": "C.C.P Middle School,  RAJENDRA MAIN ROAD.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18394",
            "district": "14",
            "Block": "159",
            "label": "CORPORATION VELLIVEETHIAR GIRLS HR SEC SCHOOL, PONNAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18395",
            "district": "14",
            "Block": "159",
            "label": "MAD.MUTHU M.S AZHAGARADI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18396",
            "district": "14",
            "Block": "159",
            "label": "CORPORATION SETHUPATHI PANDITHURAI BOYS HR SEC SCHOOL, NEW JAIL ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18397",
            "district": "14",
            "Block": "159",
            "label": "MUKKULATHOR MIDDLE SCHOOL, MELAPONNAGARAM MAIN ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18398",
            "district": "14",
            "Block": "159",
            "label": "BALA M.S MELAPONNANAGRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18399",
            "district": "14",
            "Block": "159",
            "label": "HOLY FAMILY GIRLS HR SEC SCHOOL, GNANAOLIVUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18400",
            "district": "14",
            "Block": "159",
            "label": "ST.BRITTO HR SEC SCHOOL, GNANAOLIVUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18401",
            "district": "14",
            "Block": "159",
            "label": "Y.M.C.C. MIDDLE SCHOOL, NEW JAIL ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18402",
            "district": "14",
            "Block": "159",
            "label": "SRI VIDYALAYAM MATRIC HSS, S.S.COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18403",
            "district": "14",
            "Block": "159",
            "label": "GOVT GIRLS HR SEC SCHOOL, MAHABOOBPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18404",
            "district": "14",
            "Block": "159",
            "label": "AZHAGHU SUNDHRAM MEM.M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18405",
            "district": "14",
            "Block": "159",
            "label": "MADURAI CITY MIDDLE SCHOOL  ANSARINAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18406",
            "district": "14",
            "Block": "159",
            "label": "MADURA COLLEGE HR SEC SCHOOL, RMS ROAD, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18407",
            "district": "14",
            "Block": "159",
            "label": "RAILWAY  HSS, RAILWAY COLONY",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18408",
            "district": "14",
            "Block": "159",
            "label": "UNION CHRISTIAN HR SEC SCHOOL, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18409",
            "district": "14",
            "Block": "159",
            "label": "CORPORATION MIDDLE SCHOOL, WEST GATE NO 2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18410",
            "district": "14",
            "Block": "159",
            "label": "MANGAYARKARASI MIDDLE SCHOOL, MANINAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18411",
            "district": "14",
            "Block": "159",
            "label": "SETUPATI HR SEC SCHOOL, NORTHVELI STREET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18412",
            "district": "14",
            "Block": "159",
            "label": "HOLY ANGEL HR SEC SCHOOL, KANAGAVEL COLONY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18413",
            "district": "14",
            "Block": "159",
            "label": "VEERAMAMUNIVAR VIDYASALAI MIDDLE SCHOOL, KANAGAVEL COLONY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18414",
            "district": "14",
            "Block": "159",
            "label": "SAHAYAMATHA MIDDLE SCHOOL, M.K.PURAM, JAIHINDUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18415",
            "district": "14",
            "Block": "159",
            "label": "PREM VIDYA SALAI SOLAI ALAGU P",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18416",
            "district": "14",
            "Block": "159",
            "label": "SRI MURUGAN MIDDLE SCHOOL, JEEVA NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18417",
            "district": "14",
            "Block": "159",
            "label": "FATIMA MIDDLE SCHOOL, KASTHURIBAI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21357",
            "district": "14",
            "Block": "159",
            "label": "SEVENTH DAY ADV.H.S  ICSE S,KENNET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23130",
            "district": "14",
            "Block": "159",
            "label": "TRIVENI SCHOOL, PONNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "160": [
        {
            "key": "712",
            "district": "14",
            "Block": "160",
            "label": "SRI VIDYA BHAVAN MATRIC HIGHER SECONDARY SCHOOL, TEPPAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "761",
            "district": "14",
            "Block": "160",
            "label": "AMALI MATRIC HR SEC SCHOOL, NEW RAMNAD ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "781",
            "district": "14",
            "Block": "160",
            "label": "SOURASHTRA GIRLS HR SEC SCHOOL, ANUPPANADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "948",
            "district": "14",
            "Block": "160",
            "label": "SRI SARADA VIDYAVANAM MATRIC GIRLS HR SEC SCHOOL, SIMMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1294",
            "district": "14",
            "Block": "160",
            "label": "ROSE MATRIC HR SEC SCHOOL, KANSAMETTU STREET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1396",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION MASATHIYAR GIRLS HR SEC SCHOOL, MANJANAKARA STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1822",
            "district": "14",
            "Block": "160",
            "label": "SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, ANUPPANADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3126",
            "district": "14",
            "Block": "160",
            "label": "SRIMAN NAYAKIYAR VIDYA MANDIR MATRIC HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4499",
            "district": "14",
            "Block": "160",
            "label": "NADAR VIDHYASALA MIDDLE SCHOOL, SOUTH GATE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4850",
            "district": "14",
            "Block": "160",
            "label": "PALANIAPPA MIDDLE SCHOOL,  CMR ROAD, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5387",
            "district": "14",
            "Block": "160",
            "label": "MADURAI MEENAKSHI MATRIC HR SEC SCHOOL, PETCHIAMMAN PADITHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5647",
            "district": "14",
            "Block": "160",
            "label": "ST.JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL, OLD KUYAVARPALAYAM ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5811",
            "district": "14",
            "Block": "160",
            "label": "L.V BALU SAMY IYER& SONS M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6202",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION MIDDLE SCHOOL  SANDAIPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6411",
            "district": "14",
            "Block": "160",
            "label": "PREM MATRIC HIGH SCHOOL, TEPPAKKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18418",
            "district": "14",
            "Block": "160",
            "label": "GUHAN MATRIC HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18419",
            "district": "14",
            "Block": "160",
            "label": "R.C MIDDLE SCHOOL , ROSARY CHURCH, TOWNHALL ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18420",
            "district": "14",
            "Block": "160",
            "label": "RAJAPARAMESWARI MIDDLE SCHOOL, ANNANAGAR,  VANDIYUR MAIN ROAD , MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18421",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION KASTHURIBAI GANDHI GIRLS HR SEC SCHOOL, SIMMAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18422",
            "district": "14",
            "Block": "160",
            "label": "D M & R GIRLS' HIGH SCHOOL NORTH MASI STREET MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18423",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION AVVAI GIRLS HR SEC SCHOOL, EAST AVANI MOOLA STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18424",
            "district": "14",
            "Block": "160",
            "label": "AYIRA VAISYA HR SEC SCHOOL,  VELLIAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18425",
            "district": "14",
            "Block": "160",
            "label": "SOURASHTRA  (CO-ED) HR SEC SCHOOL, NETHAJI ROAD, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18426",
            "district": "14",
            "Block": "160",
            "label": "DIOCESE  OF MADURAI AND RAMNAD MIDDLE SCHOOL, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18427",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION MARAIMALAI ADIGALAR BOYS HIGH SCHOOL, SOUTH GATE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18428",
            "district": "14",
            "Block": "160",
            "label": "PANDIA VELLALA COR.M.S",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18429",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION  EVRN GIRLS HR SEC SCHOOL, SOUTH VELI STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18430",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION HR SEC SCHOOL, SUNDHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18431",
            "district": "14",
            "Block": "160",
            "label": "THIAGARAJAR HR SEC SCHOOL, VASANTHA NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18432",
            "district": "14",
            "Block": "160",
            "label": "GURUKULAM HIGH SCHOOL, SUBRAMANIAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18433",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION KAMBAR HR SEC SCHOOL, SUBRAMANIAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18434",
            "district": "14",
            "Block": "160",
            "label": "ST.JOSEPH'S GIRLS HR SEC SCHOOL, OLD KUYAVARPALAYAM ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18435",
            "district": "14",
            "Block": "160",
            "label": "NADAR HR SEC SCHOOL, SOUTH GATE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18436",
            "district": "14",
            "Block": "160",
            "label": "SINGARATOPE COR.M.S",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18437",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION BHARATHIDASANAR HR SEC SCHOOL, NEW MAHALIPATTI ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23366",
            "district": "14",
            "Block": "160",
            "label": "DEVASAKAYAM CHETTIYAR MIDDLE SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "18438",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION N.M.S.M HIGH SCHOOL, EAST GATE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18439",
            "district": "14",
            "Block": "160",
            "label": "R.C MIDDLE SCHOOL, EAST GATE, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18440",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION THIRUVALLUVAR MAHAL HIGH SCHOOL, MADURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18441",
            "district": "14",
            "Block": "160",
            "label": "V.H.N.GIRLS HR SEC SCHOOL, MUNICHALAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18442",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION UMARUPULAVAR HIGH SCHOOL, PERIYAPALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18443",
            "district": "14",
            "Block": "160",
            "label": "M.A.V.M.M HR SEC SCHOOL, YANAIKKAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18444",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION HIGH SCHOOL, MUNICHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18445",
            "district": "14",
            "Block": "160",
            "label": "RAJA DHANALAKSHMI M.S.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18446",
            "district": "14",
            "Block": "160",
            "label": "MEENAKSHIPURAM MIDDLE SCHOOL  SREENIVASA PERUMALKOIL STREET, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18447",
            "district": "14",
            "Block": "160",
            "label": "SOURASHTRA HR SEC SCHOOL, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18448",
            "district": "14",
            "Block": "160",
            "label": "SRI P.V MATRIC HR SEC SCHOOL, KAMARAJAR SALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18449",
            "district": "14",
            "Block": "160",
            "label": "M.K.R.AYYA NADAR JEYALAKSHMI AMMAL HR SEC SCHOOL TEPPAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18450",
            "district": "14",
            "Block": "160",
            "label": "NIRMALA GIRLS HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18451",
            "district": "14",
            "Block": "160",
            "label": "GOMATHI M.S BABU NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18452",
            "district": "14",
            "Block": "160",
            "label": "R.C FATIMA M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18453",
            "district": "14",
            "Block": "160",
            "label": "THIAGARAJAR MODEL HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18454",
            "district": "14",
            "Block": "160",
            "label": "V.M.J HR.S.S",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18455",
            "district": "14",
            "Block": "160",
            "label": "SRI MEENAKSHI SUNDARESWARAR GIRLS HR SEC SCHOOL, TEPPAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18456",
            "district": "14",
            "Block": "160",
            "label": "MOTHER THERESA MATRIC HR SEC SCHOOL, MELA ANUPPANADY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18457",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION HIGH SCHOOL, ANUPPANADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18458",
            "district": "14",
            "Block": "160",
            "label": "ST.PATRICK'S MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18459",
            "district": "14",
            "Block": "160",
            "label": "CORPORATION MANIMEGALAI GIRLS HIGH SCHOOL, KAMARAJAR SALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18460",
            "district": "14",
            "Block": "160",
            "label": "VHN BOYS HR SEC SCHOOL, KUYAVARPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18461",
            "district": "14",
            "Block": "160",
            "label": "OM SARAVANA BHAVA THIRUMURGAN MIDDLE SCHOOL,  EAST MADURAI STATION ROAD , MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18462",
            "district": "14",
            "Block": "160",
            "label": "ANNAI THERESA MATRIC HIGH SCHOOL, BALARENGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18463",
            "district": "14",
            "Block": "160",
            "label": "CHANDRA MIDDLE SCHOOL, MELA ANUPPANDI, MADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18464",
            "district": "14",
            "Block": "160",
            "label": "ST.MARY'S  HR SEC SCHOOL, EAST VELI ST.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18465",
            "district": "14",
            "Block": "160",
            "label": "PNU A.P.T.DORAIRAJ HIGHER SEC SCHOOL, MELAANUPPANADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18466",
            "district": "14",
            "Block": "160",
            "label": "DR.T.THIRUGNANAM HIGH SCHOOL, EAST SANTHAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18467",
            "district": "14",
            "Block": "160",
            "label": "MANU GIRLS HR SEC SCHOOL, KEERAITHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18468",
            "district": "14",
            "Block": "160",
            "label": "SUNDARAMBAL M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21287",
            "district": "14",
            "Block": "160",
            "label": "SRI VANI VIDHYALAYA MATRIC HR SEC SCHOOL, SUBRAMANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23019",
            "district": "14",
            "Block": "160",
            "label": "PNU Dr.T.MUTHUSAMY HIGHER SECONDARY SCHOOL, VAZHAITHOPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "161": [
        {
            "key": "669",
            "district": "14",
            "Block": "161",
            "label": "S.E.V MATRIC HR SEC SCHOOL, NARAYANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1227",
            "district": "14",
            "Block": "161",
            "label": "XAVIER MATRIC HR SEC SCHOOL, IYER BUNGALOW",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1648",
            "district": "14",
            "Block": "161",
            "label": "KARPAGAM MATRIC HR SEC SCHOOL, PARAVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1760",
            "district": "14",
            "Block": "161",
            "label": "ST.JOSEPH MATRIC HR SEC SCHOOL, KOODAL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1985",
            "district": "14",
            "Block": "161",
            "label": "FATIMA MATRIC HR SEC SCHOOL, AATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2025",
            "district": "14",
            "Block": "161",
            "label": "C.E.O.A. MATRIC HR SEC SCHOOL, A KOSAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2066",
            "district": "14",
            "Block": "161",
            "label": "C.E.O.A. MATRIC HIGHER SECONDARY SCHOOL, MEENAMBALPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2323",
            "district": "14",
            "Block": "161",
            "label": "SDH JAIN VIDYALAYA MATRIC HR SEC SCHOOL, THIRUPPALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2859",
            "district": "14",
            "Block": "161",
            "label": "ROYAL VIDYALAYA MATRIC HR SEC SCHOOL, VILANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3187",
            "district": "14",
            "Block": "161",
            "label": "SIDDHU MATRIC HR SEC SCHOOL, VISWANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3235",
            "district": "14",
            "Block": "161",
            "label": "BHARAT VIDYA MANDIR MATRIC HIGH SCHOOL, IYER BUNGALOW",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3433",
            "district": "14",
            "Block": "161",
            "label": "LUCY PERRY NOBLE GIRLS HR SEC SCHOOL, RATCHANYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3477",
            "district": "14",
            "Block": "161",
            "label": "ST.MARY'S MATRIC HR SEC SCHOOL, S.ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3792",
            "district": "14",
            "Block": "161",
            "label": "ROTARY LAHARRY MATRIC HR SEC SCHOOL, BIBI KULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3948",
            "district": "14",
            "Block": "161",
            "label": "CATHY MATRIC HR SEC SCHOOL, KURINJI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4038",
            "district": "14",
            "Block": "161",
            "label": "SPARTANNS MATRIC HIGH SCHOOL, NAGANAKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4227",
            "district": "14",
            "Block": "161",
            "label": "P.S.Y MATRIC HR SEC SCHOOL, NARAYANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4290",
            "district": "14",
            "Block": "161",
            "label": "LE CHATELIER MATRIC HR SEC SCHOOL, CHINNACHOKKIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5005",
            "district": "14",
            "Block": "161",
            "label": "ST.MICHAEL MATRIC HR SEC SCHOOL, P&T NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5038",
            "district": "14",
            "Block": "161",
            "label": "VIBGYOR MATRIC HIGH SCHOOL, K.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5354",
            "district": "14",
            "Block": "161",
            "label": "MARUTHU RUKMANI MATRIC HR SEC SCHOOL, P&T NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5421",
            "district": "14",
            "Block": "161",
            "label": "MARY ANN MATRIC HR SEC SCHOOL, K PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5448",
            "district": "14",
            "Block": "161",
            "label": "MARY IMMACULATE MATRIC HIGH SCHOOL, APPATHURAINAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5758",
            "district": "14",
            "Block": "161",
            "label": "CHRIST THE KING MATRIC HR SEC SCHOOL, VILANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5994",
            "district": "14",
            "Block": "161",
            "label": "VELAMMAL MEMORIAL MATRIC HR SEC SCHOOL,THIRUPPALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6309",
            "district": "14",
            "Block": "161",
            "label": "E.B.G MATRIC HR SEC SCHOOL, MOONDRUMAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6369",
            "district": "14",
            "Block": "161",
            "label": "BASINGER GARDENS MATRIC SCHOOL, P&T NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18026",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18027",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18028",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18029",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, M.CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18030",
            "district": "14",
            "Block": "161",
            "label": "MADURAI CRESCENT MATRIC HR SEC SCHOOL, KALLAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18031",
            "district": "14",
            "Block": "161",
            "label": "DON BOSCO HR SEC SCHOOL, THIRUMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18032",
            "district": "14",
            "Block": "161",
            "label": "AUXILIUM MATRIC SCHOOL, THIRUMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18033",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, OOMACHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18034",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, CHINNAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18035",
            "district": "14",
            "Block": "161",
            "label": "IAB (BLIND) HR SEC SCHOOL, SUNDARARAJANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18036",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANNANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18037",
            "district": "14",
            "Block": "161",
            "label": "MADONNA MATRICULATION HIGHER SECONDARY SCHOOL, KANNANENTHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18038",
            "district": "14",
            "Block": "161",
            "label": "SRI GOPALAKRISHNA HIGHER SECONDARY SCHOOL,SIRUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18039",
            "district": "14",
            "Block": "161",
            "label": "AUXILIUM  HIGHER SECONDARY SCHOOL, SIRUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18040",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, KARUVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18041",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. SIKKANTHAR SAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18042",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. KOILPAPPAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18043",
            "district": "14",
            "Block": "161",
            "label": "M.P.R. PUBLIC HIGHER SECONDARY SCHOOL-KAVANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18044",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, KULAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18045",
            "district": "14",
            "Block": "161",
            "label": "GOVT ADW HIGH SCHOOL, ELUMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18046",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, MANJAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18047",
            "district": "14",
            "Block": "161",
            "label": "PSYECHI MAT.H.S ATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18048",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, POTHUMBU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18049",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, SIRUVALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18050",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S.  THENUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18051",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. NARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18052",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, THIRUPPALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18053",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, NAGANAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18054",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18055",
            "district": "14",
            "Block": "161",
            "label": "SRI RAM N.M.P.S KACHARAMPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18056",
            "district": "14",
            "Block": "161",
            "label": "SRIRAM NALLAMANI YADAVA GIRLS HR SEC SCHOOL,THIRUPPALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18057",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. VAIRAVANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18058",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. SAMBAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18059",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. VELICHANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18060",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S. MUDAKATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18061",
            "district": "14",
            "Block": "161",
            "label": "Sree Maha Vidhyalayam Matric School, Anaiyur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18062",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, ANAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18063",
            "district": "14",
            "Block": "161",
            "label": "P.U.M.S MILAGARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18064",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, MALAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18065",
            "district": "14",
            "Block": "161",
            "label": "THE NALLAMANI MATRIC Hr. Sec. SCHOOL, SAMAYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18066",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, SAMAYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18067",
            "district": "14",
            "Block": "161",
            "label": "ST.JOSEPH HIGH SCHOOL, SAMAYANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18068",
            "district": "14",
            "Block": "161",
            "label": "GOVT HR SEC SCHOOL, PARAVAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18069",
            "district": "14",
            "Block": "161",
            "label": "ST.JOSEPH'S SCHOOL FOR THE BLIND,  PARAVAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18070",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, SATHIYAMOORTHY NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18071",
            "district": "14",
            "Block": "161",
            "label": "SREE MAHA MATRIC HR SEC SCHOOL, VAITHIYANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18072",
            "district": "14",
            "Block": "161",
            "label": "ADHYAPANA CBSE HS, VILANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18073",
            "district": "14",
            "Block": "161",
            "label": "SRI RAMANA MAHARISHI MATRICULATION SCHOOL-KOODALNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18074",
            "district": "14",
            "Block": "161",
            "label": "ALLWIN MATRIC HR SEC SCHOOL, KOODAL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18075",
            "district": "14",
            "Block": "161",
            "label": "SREE VISALAKSHI MILLS HIGH SCHOOL, VISALAKSHI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18076",
            "district": "14",
            "Block": "161",
            "label": "ST.ANT.M.S KOODALNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18077",
            "district": "14",
            "Block": "161",
            "label": "ST.ANTONY'S GIRLS HR SEC SCHOOL, KOODAL NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18078",
            "district": "14",
            "Block": "161",
            "label": "KESWICK PUBLIC SCHOOL, KOODALNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18079",
            "district": "14",
            "Block": "161",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VILANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18080",
            "district": "14",
            "Block": "161",
            "label": "SHANTHI VID.M.S  PALLAR PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18081",
            "district": "14",
            "Block": "161",
            "label": "DHANAPAUL HR SEC SCHOOL, MULLAINAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18082",
            "district": "14",
            "Block": "161",
            "label": "R.C MIDDLE SCHOOL, K.PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18083",
            "district": "14",
            "Block": "161",
            "label": "AL-AMEEN HR SEC SCHOOL, K.PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18084",
            "district": "14",
            "Block": "161",
            "label": "PHOENIX MATRIC HR SEC SCHOOL, K PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18085",
            "district": "14",
            "Block": "161",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, SURYA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18086",
            "district": "14",
            "Block": "161",
            "label": "LOURDU ANNAI GIRLS HR SEC SCHOOL, K.PUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18087",
            "district": "14",
            "Block": "161",
            "label": "DE NOBILI MATRIC HR SEC SCHOOL, K PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18088",
            "district": "14",
            "Block": "161",
            "label": "MAHATMA MONTESSORI MATRIC HR SEC.SCHOOL, BABA BUILDING, SURVEYOR COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18089",
            "district": "14",
            "Block": "161",
            "label": "MAHATMA MONT CBSE K.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18090",
            "district": "14",
            "Block": "161",
            "label": "BALMANDIRAM HR SEC SCHOOL, MADURAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18091",
            "district": "14",
            "Block": "161",
            "label": "Y.M.C.A KAMAK Hr. Sec. SCHOOL FOR DEAF, VISHALAKSHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18092",
            "district": "14",
            "Block": "161",
            "label": "SEVENTHDAY MATRIC HR SEC SCHOOL, CHINNACHOKKIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18093",
            "district": "14",
            "Block": "161",
            "label": "GURUKUL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18094",
            "district": "14",
            "Block": "161",
            "label": "JOTHI HIGHER SECONDARY SCHOOL, NARIMEDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18095",
            "district": "14",
            "Block": "161",
            "label": "CORPORATION KAKKAIPADINIYAR GIRLS HR SEC SCHOOL, CHOKKIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18096",
            "district": "14",
            "Block": "161",
            "label": "Y.W.C.A MAT.HR.S.S VALLABAI RD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18097",
            "district": "14",
            "Block": "161",
            "label": "KENDRA VIDYALAYA HIGHER SECONDARY SCHOOL, NARIMEDU MADURAI 2",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18098",
            "district": "14",
            "Block": "161",
            "label": "COR.M.S KRISHNAPURAM COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18099",
            "district": "14",
            "Block": "161",
            "label": "DHANAPAL M.S B.B KULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18100",
            "district": "14",
            "Block": "161",
            "label": "CORPORATION PANDIAN NEDUNCHEZHIAN  HR SEC SCHOOL, AR LINE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18101",
            "district": "14",
            "Block": "161",
            "label": "CORPORATION BHARATHIAR HIGH SCHOOL, SELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18102",
            "district": "14",
            "Block": "161",
            "label": "RAJAJI M.S MEENAMBALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18103",
            "district": "14",
            "Block": "161",
            "label": "THIRU VI KA CORPORATION HR SEC SCHOOL, THATHANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20887",
            "district": "14",
            "Block": "161",
            "label": "MAHARISHI VIDYAMANDIR S, KOILPAPPAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21877",
            "district": "14",
            "Block": "161",
            "label": "VIKAASA WORLD Hr. Sec. SCHOOL, VEERAPANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22604",
            "district": "14",
            "Block": "161",
            "label": "JAIN VIDYALAYA  SCHOOL, THIRUPPALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22791",
            "district": "14",
            "Block": "161",
            "label": "MADURAI PUBLIC SCHOOL, PETCHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22965",
            "district": "14",
            "Block": "161",
            "label": "GOVT HIGH SCHOOL, OORMECHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23003",
            "district": "14",
            "Block": "161",
            "label": "DELHI WORLD PUBLIC SCHOOL, PARAVAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23071",
            "district": "14",
            "Block": "161",
            "label": "JANNAH PUBLIC SCHOOL, PARAYATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23072",
            "district": "14",
            "Block": "161",
            "label": "VEDIC VIDYASHRAM SCHOOL, THIRUPPALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23508",
            "district": "14",
            "Block": "161",
            "label": "VIKAASA JUBILEE SCHOOL, LOURDHU NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23508",
            "district": "14",
            "Block": "161",
            "label": "VIKAASA JUBILEE SCHOOL, LOURDHU NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "162": [
        {
            "key": "5698",
            "district": "2",
            "Block": "162",
            "label": "DR V.GENGUSAMY NAIDU MS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5897",
            "district": "2",
            "Block": "162",
            "label": "BLUE STAR NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8160",
            "district": "2",
            "Block": "162",
            "label": "GHS, SITHANDIMANDAPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8161",
            "district": "2",
            "Block": "162",
            "label": "PUMS MAMANDUR THENPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8162",
            "district": "2",
            "Block": "162",
            "label": "PUMS MAMANDUR VADAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8163",
            "district": "2",
            "Block": "162",
            "label": "PUMS PAZHAMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8164",
            "district": "2",
            "Block": "162",
            "label": "GHS BUKKATHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8165",
            "district": "2",
            "Block": "162",
            "label": "PUMS NELVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8166",
            "district": "2",
            "Block": "162",
            "label": "GOVERNMENT HIGH SCHOOL ONAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8167",
            "district": "2",
            "Block": "162",
            "label": "PUMS PUZHUTHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8168",
            "district": "2",
            "Block": "162",
            "label": "GHSS , KUNNANKULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8169",
            "district": "2",
            "Block": "162",
            "label": "PUMS VAIYAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8170",
            "district": "2",
            "Block": "162",
            "label": "PUMS PAZHAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8171",
            "district": "2",
            "Block": "162",
            "label": "PUMS PADALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8172",
            "district": "2",
            "Block": "162",
            "label": "MCSM GOVERNMENT HIGHER SECONDARY SCHOOL PADALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8173",
            "district": "2",
            "Block": "162",
            "label": "PUMS LNPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8174",
            "district": "2",
            "Block": "162",
            "label": "PUMS ARAYAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8175",
            "district": "2",
            "Block": "162",
            "label": "ADWMS SANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8176",
            "district": "2",
            "Block": "162",
            "label": "GHS VALLUVAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8177",
            "district": "2",
            "Block": "162",
            "label": "PUMS VEDAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8178",
            "district": "2",
            "Block": "162",
            "label": "GHS PAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8179",
            "district": "2",
            "Block": "162",
            "label": "ADWMS DEVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8180",
            "district": "2",
            "Block": "162",
            "label": "PUMS SILAVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8181",
            "district": "2",
            "Block": "162",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8182",
            "district": "2",
            "Block": "162",
            "label": "PUMS KINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8183",
            "district": "2",
            "Block": "162",
            "label": "ST JOSEPH AIDED HS,KK PUDHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8184",
            "district": "2",
            "Block": "162",
            "label": "GHS BOODUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8185",
            "district": "2",
            "Block": "162",
            "label": "PUMS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8186",
            "district": "2",
            "Block": "162",
            "label": "GHSS VEERANAKUNNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8187",
            "district": "2",
            "Block": "162",
            "label": "PUMS MUNNITHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8188",
            "district": "2",
            "Block": "162",
            "label": "PUMS MUDUKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8189",
            "district": "2",
            "Block": "162",
            "label": "VKM (Aided) HS, Vilvarayanallur",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8190",
            "district": "2",
            "Block": "162",
            "label": "PUMS ARUNGUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8191",
            "district": "2",
            "Block": "162",
            "label": "PUMS KAVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8192",
            "district": "2",
            "Block": "162",
            "label": "PUMS ATHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8193",
            "district": "2",
            "Block": "162",
            "label": "GOVERNMENT HIGH SCHOOL ZAMIN ENDATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8194",
            "district": "2",
            "Block": "162",
            "label": "VENUGOPALASAMY TRUST NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8195",
            "district": "2",
            "Block": "162",
            "label": "MUTHUMALLA MS SIRUNALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8196",
            "district": "2",
            "Block": "162",
            "label": "PUMS ZAMEENPERUVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8197",
            "district": "2",
            "Block": "162",
            "label": "PUMS MARUVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8198",
            "district": "2",
            "Block": "162",
            "label": "PUMS PERIYAVENMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8199",
            "district": "2",
            "Block": "162",
            "label": "PUMS KATTUDEVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8200",
            "district": "2",
            "Block": "162",
            "label": "PUMS NALLAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8201",
            "district": "2",
            "Block": "162",
            "label": "DR R ARULAPPA HIGHER SECONDARY SCHOOL NEERPAIR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8202",
            "district": "2",
            "Block": "162",
            "label": "PUMS JAMINBUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8203",
            "district": "2",
            "Block": "162",
            "label": "GHSS( ADW ) IRUMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8204",
            "district": "2",
            "Block": "162",
            "label": "PTNVKS MS MADURANTAKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8205",
            "district": "2",
            "Block": "162",
            "label": "GGHSS MADURANTHAKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8206",
            "district": "2",
            "Block": "162",
            "label": "SRI AHOBILA MUTT ORIENTAL HIGH SCHOOL MADURANTHAKAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8207",
            "district": "2",
            "Block": "162",
            "label": "HINDU CORNATION MS MADURANTAGAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8208",
            "district": "2",
            "Block": "162",
            "label": "HINDU HR.SEC SCHOOL,MADURANTHAGAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8209",
            "district": "2",
            "Block": "162",
            "label": "ST JOSEPH MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8210",
            "district": "2",
            "Block": "162",
            "label": "CSI TOWN GIRLS MS MADURANTAKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8211",
            "district": "2",
            "Block": "162",
            "label": "CSI HS MADURANTAGAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8212",
            "district": "2",
            "Block": "162",
            "label": "MMS MOCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8213",
            "district": "2",
            "Block": "162",
            "label": "RC AIDED HIGH SCHOOL, MADURANTHAGAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8214",
            "district": "2",
            "Block": "162",
            "label": "GURUKULAM GIRLS HS MADURANTHAGAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8215",
            "district": "2",
            "Block": "162",
            "label": "GURUKULAM BOYS HS MADURANTHAGAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8216",
            "district": "2",
            "Block": "162",
            "label": "vivegananda vidyalaya mat hss",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8217",
            "district": "2",
            "Block": "162",
            "label": "GHSS KARUNKUZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8218",
            "district": "2",
            "Block": "162",
            "label": "PUMS KARUNKUZHI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8219",
            "district": "2",
            "Block": "162",
            "label": "CSI MS KARUNGUZHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8220",
            "district": "2",
            "Block": "162",
            "label": "NEW RIVER MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20878",
            "district": "2",
            "Block": "162",
            "label": "SUBHAM VIDHYALAYAA SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21008",
            "district": "2",
            "Block": "162",
            "label": "VM Vidya Kendra Mat.School, Madurantakam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21053",
            "district": "2",
            "Block": "162",
            "label": "Prasan Vidya Mandir, Vadapathi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22363",
            "district": "2",
            "Block": "162",
            "label": "Sai Shakthi Vidhya Mandhir School, Meyyur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22364",
            "district": "2",
            "Block": "162",
            "label": "GHS 150 ESSUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22897",
            "district": "2",
            "Block": "162",
            "label": "GHS Thottanaval",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23014",
            "district": "2",
            "Block": "162",
            "label": "RC High School Thatchur",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "23246",
            "district": "2",
            "Block": "162",
            "label": "GHS MALAIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23302",
            "district": "2",
            "Block": "162",
            "label": "KARPAGA VINAYAGA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23433",
            "district": "2",
            "Block": "162",
            "label": "VIVEKANANDA VIDYALAYA MADURANDAGAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23433",
            "district": "2",
            "Block": "162",
            "label": "VIVEKANANDA VIDYALAYA MADURANDAGAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23433",
            "district": "2",
            "Block": "162",
            "label": "VIVEKANANDA VIDYALAYA MADURANDAGAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23433",
            "district": "2",
            "Block": "162",
            "label": "VIVEKANANDA VIDYALAYA MADURANDAGAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23861",
            "district": "2",
            "Block": "162",
            "label": "PUMS NELVOY",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23862",
            "district": "2",
            "Block": "162",
            "label": "PUMS PAZHAYANUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23863",
            "district": "2",
            "Block": "162",
            "label": "PUMS PUDUPATTU",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23864",
            "district": "2",
            "Block": "162",
            "label": "PUMS KUNNATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23865",
            "district": "2",
            "Block": "162",
            "label": "SRI S. C AGARWAL RT 95 VIVEKANANDA VIDYASHRAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "163": [
        {
            "key": "2214",
            "district": "22",
            "Block": "163",
            "label": "JAYAM MATRIC. HR. SEC. SCHOOL, NALLANAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3978",
            "district": "22",
            "Block": "163",
            "label": "SWAMY VIVEKANANDAR MATRIC HR. SEC. SCHOOL,KANCHAMALAYUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4859",
            "district": "22",
            "Block": "163",
            "label": "SRI AMMAN MATRIC HR. SEC. SCHOOL, VAIKUNTHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6653",
            "district": "22",
            "Block": "163",
            "label": "VINAYAGA  MATRIC HR. SEC. SCHOOL, KANNANTHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11065",
            "district": "22",
            "Block": "163",
            "label": "PUMS VELLAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11066",
            "district": "22",
            "Block": "163",
            "label": "GHSS, VAIKUNTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11067",
            "district": "22",
            "Block": "163",
            "label": "PUMS, UNJAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11068",
            "district": "22",
            "Block": "163",
            "label": "GHSS ALAGAPPAMPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11069",
            "district": "22",
            "Block": "163",
            "label": "KURINJI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11070",
            "district": "22",
            "Block": "163",
            "label": "PUMS EDANGANASALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11071",
            "district": "22",
            "Block": "163",
            "label": "PUMS, E.METTUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11072",
            "district": "22",
            "Block": "163",
            "label": "PUMS, RASIGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11073",
            "district": "22",
            "Block": "163",
            "label": "PUMS, KADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11074",
            "district": "22",
            "Block": "163",
            "label": "PUMS, NALLANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11075",
            "district": "22",
            "Block": "163",
            "label": "G.H.S.S., K.K.NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11076",
            "district": "22",
            "Block": "163",
            "label": "JOTHI VIDHYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11077",
            "district": "22",
            "Block": "163",
            "label": "GHS, VALAYACHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11078",
            "district": "22",
            "Block": "163",
            "label": "PUMS THAPPAKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11079",
            "district": "22",
            "Block": "163",
            "label": "PUMS METTUTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11080",
            "district": "22",
            "Block": "163",
            "label": "G.B.H.S.S  MAGUDANCHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11081",
            "district": "22",
            "Block": "163",
            "label": "G.G.H.S.S. MAGUDANCHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11082",
            "district": "22",
            "Block": "163",
            "label": "K.G.B.V. MAGUDANCHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11083",
            "district": "22",
            "Block": "163",
            "label": "LOTUS MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11084",
            "district": "22",
            "Block": "163",
            "label": "PUMS, GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11085",
            "district": "22",
            "Block": "163",
            "label": "PUMS, KANDARKULAMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21020",
            "district": "22",
            "Block": "163",
            "label": "DHEERAN VIDHYAALAYAA MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21207",
            "district": "22",
            "Block": "163",
            "label": "MODEL SCHOOL, MAGUDANCHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21670",
            "district": "22",
            "Block": "163",
            "label": "DHEERAN ACADEMY (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21671",
            "district": "22",
            "Block": "163",
            "label": "LOTUS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22438",
            "district": "22",
            "Block": "163",
            "label": "GHS MATTAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22860",
            "district": "22",
            "Block": "163",
            "label": "GHS KANNANTHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23866",
            "district": "22",
            "Block": "163",
            "label": "PUMS, KADAYAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "164": [
        {
            "key": "5966",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VIZHUKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10171",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL AGOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10172",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL AVVAIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10173",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ALAGRAMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10174",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  ASOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10175",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL RETTANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10176",
            "district": "37",
            "Block": "164",
            "label": "KENNEDY MATRIC HIGHER SECONDARY SCHOOL RETTANAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10177",
            "district": "37",
            "Block": "164",
            "label": "HOLY ANGEL MATRIC HIGHER SECONDARY SCHOOL  RETTANAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10178",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10179",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MAILAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10180",
            "district": "37",
            "Block": "164",
            "label": "SRI SIVAPRAKASA SWAMIGAL HIGHER SECONDARY SCHOOL MAILAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10181",
            "district": "37",
            "Block": "164",
            "label": "NEHRU MATRIC HIGHER SECONDARY SCHOOL MAILAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10182",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10183",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  CHENDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10184",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THAZHUTHALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10185",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  DEEVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10186",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PATHIRAPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10187",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NEDIMOZHIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10188",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERIYATHACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10189",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEEDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10190",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10191",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  KOLLAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10192",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10193",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GANAPATHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10194",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  KATTUSIVIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10195",
            "district": "37",
            "Block": "164",
            "label": "ST.JOHN'S MATRIC HIGHER SECONDARY SCHOOL CHINNANERKUNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10196",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  KOOTERIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10197",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  KOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10198",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NADUVANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10199",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  NEDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10200",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  MELPERADIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10201",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  PULIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10202",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10203",
            "district": "37",
            "Block": "164",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SITHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10204",
            "district": "37",
            "Block": "164",
            "label": "GOVERNMENT HIGH SCHOOL  CHINNANERKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10205",
            "district": "37",
            "Block": "164",
            "label": "AIDED MIDDLE SCHOOL KALLAKOLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10206",
            "district": "37",
            "Block": "164",
            "label": "SRI RAJARAJESWARI MATRIC HIGH SCHOOL,PELAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10207",
            "district": "37",
            "Block": "164",
            "label": "AIDED MIDDLE SCHOOL MUPPULI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21683",
            "district": "37",
            "Block": "164",
            "label": "BWDA MIDDLE SCHOOL KOLLIYANGUNAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21684",
            "district": "37",
            "Block": "164",
            "label": "SKD MATRICULATION SCHOOL SOZHIYASORKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22756",
            "district": "37",
            "Block": "164",
            "label": "HOLY ANGELSCHOOL RETTANAI(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22757",
            "district": "37",
            "Block": "164",
            "label": "GREEN PARADISE RETTANAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23026",
            "district": "37",
            "Block": "164",
            "label": "K K DHANDAPANI WORLD SENIOR SECONDARY   SCHOOL,MELPERADIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "165": [
        {
            "key": "565",
            "district": "17",
            "Block": "165",
            "label": "SRI VINAYAGA MATRIC HIGHER SECONDARY SCHOOL,VAIYAPPAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1221",
            "district": "17",
            "Block": "165",
            "label": "PUMS PARUTHIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1453",
            "district": "17",
            "Block": "165",
            "label": "PUMS KALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1519",
            "district": "17",
            "Block": "165",
            "label": "AKV MATRICULATION HIGHER SECONDARY SCHOOL , SURIYAGOUNDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2011",
            "district": "17",
            "Block": "165",
            "label": "PUMS EAST MUNJANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2059",
            "district": "17",
            "Block": "165",
            "label": "PUMS   MAMUNDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2180",
            "district": "17",
            "Block": "165",
            "label": "VIDYAA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL, KOOTHANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2800",
            "district": "17",
            "Block": "165",
            "label": "PUMS PALLAKKULI AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3318",
            "district": "17",
            "Block": "165",
            "label": "PUMS KURUKKALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4882",
            "district": "17",
            "Block": "165",
            "label": "PUMS THUTHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5717",
            "district": "17",
            "Block": "165",
            "label": "MAHENDRA MATRICULATION HIGHER SECONDARY SCHOOL,KALIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11955",
            "district": "17",
            "Block": "165",
            "label": "SHRI VINAYAGA HR SEC SCHOOL    PILLANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11956",
            "district": "17",
            "Block": "165",
            "label": "GHS  SAPPAYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11957",
            "district": "17",
            "Block": "165",
            "label": "GOVT GIRLS HR SEC SCHOOL MALLASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11958",
            "district": "17",
            "Block": "165",
            "label": "GOVT BOYS HR SEC SCHOOL MALLASAMUTHRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11959",
            "district": "17",
            "Block": "165",
            "label": "AKV PUBLIC SCHOOL, SENIOR SECONDARY - CBSE, SURIYAGOUNDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11960",
            "district": "17",
            "Block": "165",
            "label": "GOVERNMENT HIGH SCHOOL,KOTTAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11961",
            "district": "17",
            "Block": "165",
            "label": "GOVERNMENT HR SEC SCHOOL VAIYAPPAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11962",
            "district": "17",
            "Block": "165",
            "label": "GOVERNMENT HR SEC SCHOOL RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21215",
            "district": "17",
            "Block": "165",
            "label": "MODEL SCHOOL, RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21308",
            "district": "17",
            "Block": "165",
            "label": "MAHENDRA INTERNATIONAL SCHOOL, KALIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21667",
            "district": "17",
            "Block": "165",
            "label": "SRI VINAYAGA INTERNATIONAL SCHOOL, VAIYAPPAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "166": [
        {
            "key": "3058",
            "district": "23",
            "Block": "166",
            "label": "PUMS SOMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5419",
            "district": "23",
            "Block": "166",
            "label": "BABA MATRIC SCHOOL MANAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17445",
            "district": "23",
            "Block": "166",
            "label": "PUMS ARASAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17446",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL CHINNAKANNANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17447",
            "district": "23",
            "Block": "166",
            "label": "SACRED HEART MIDDLE SCHOOL IDAIKATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17448",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL IDAIKATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17449",
            "district": "23",
            "Block": "166",
            "label": "PUMS KEELAMELKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17450",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL KATTIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17451",
            "district": "23",
            "Block": "166",
            "label": "PUMS KEELAPPASALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17452",
            "district": "23",
            "Block": "166",
            "label": "GOOD WILL MATRIC SHOOL MANAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17453",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL VETHIYARENDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17454",
            "district": "23",
            "Block": "166",
            "label": "PUMS KEELAPIDAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17455",
            "district": "23",
            "Block": "166",
            "label": "PUMS KUVALAIVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17456",
            "district": "23",
            "Block": "166",
            "label": "CEYRAC HS, MOONGILOORANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17457",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL MOONGILOORANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17458",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL MELANETTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17459",
            "district": "23",
            "Block": "166",
            "label": "PUMS MELAPIDAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17460",
            "district": "23",
            "Block": "166",
            "label": "PUMS VILAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17461",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL MELAPASALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17462",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL MILAGANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17463",
            "district": "23",
            "Block": "166",
            "label": "PUMS THUTHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17464",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL PERIYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17465",
            "district": "23",
            "Block": "166",
            "label": "ST. CHARLES MATRICULATION SCHOOL, PERIYAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17466",
            "district": "23",
            "Block": "166",
            "label": "PUMS S.PUDUKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17467",
            "district": "23",
            "Block": "166",
            "label": "PUMS NATHAPURAKKI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17468",
            "district": "23",
            "Block": "166",
            "label": "PUMS SEIKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17469",
            "district": "23",
            "Block": "166",
            "label": "GHS KALKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17470",
            "district": "23",
            "Block": "166",
            "label": "PUMS VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17471",
            "district": "23",
            "Block": "166",
            "label": "PUMS PAPPANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17472",
            "district": "23",
            "Block": "166",
            "label": "PUMS KULAIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17473",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL PARAYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17474",
            "district": "23",
            "Block": "166",
            "label": "PUMS AVARANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17475",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL KOMBUKARANENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17476",
            "district": "23",
            "Block": "166",
            "label": "GOVT HIGH SCHOOL VELLIKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17477",
            "district": "23",
            "Block": "166",
            "label": "GOVT HR SEC SCHOOL VEMBATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17478",
            "district": "23",
            "Block": "166",
            "label": "PUMS V.PUDUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17479",
            "district": "23",
            "Block": "166",
            "label": "PUMS VILATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17480",
            "district": "23",
            "Block": "166",
            "label": "PUMS RAJAGAMBEERAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17481",
            "district": "23",
            "Block": "166",
            "label": "ST MARYS HSS RAJAGAMBEERAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17482",
            "district": "23",
            "Block": "166",
            "label": "PUMS THANJAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17483",
            "district": "23",
            "Block": "166",
            "label": "M.K.N MID SCHOOL T.PUDUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17484",
            "district": "23",
            "Block": "166",
            "label": "PUMS BURMACOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17485",
            "district": "23",
            "Block": "166",
            "label": "SEVENTH DAY ADVENTIST  MAT HSS SCHOOL MANAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17486",
            "district": "23",
            "Block": "166",
            "label": "OVC HSS MANAMADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17487",
            "district": "23",
            "Block": "166",
            "label": "R.C.St.CECILIAL MIDDLE.SCHOOL,MANAMADURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17488",
            "district": "23",
            "Block": "166",
            "label": "ST.JOSEPH'S MATRIC HSS SCHOOL MANAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17489",
            "district": "23",
            "Block": "166",
            "label": "C.S.I HIGH SCHOOL MANAMADURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17490",
            "district": "23",
            "Block": "166",
            "label": "GOVT GIRLS HSS MANAMADURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17491",
            "district": "23",
            "Block": "166",
            "label": "CSI DARLING SELVABAI DAVID HIGH SCHOOL FOR THE DEAF, MANAMADURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "21852",
            "district": "23",
            "Block": "166",
            "label": "GOODWILL MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23867",
            "district": "23",
            "Block": "166",
            "label": "PUMS THUTHIKULAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23868",
            "district": "23",
            "Block": "166",
            "label": "PUMS VELUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23869",
            "district": "23",
            "Block": "166",
            "label": "PUMS VILATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23870",
            "district": "23",
            "Block": "166",
            "label": "BABA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "167": [
        {
            "key": "752",
            "district": "19",
            "Block": "167",
            "label": "M.H. MATRIC HR. SEC. SCHOOL, KOTTAIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1048",
            "district": "19",
            "Block": "167",
            "label": "CRESCENT MAT. SCHOOL, AMMAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5941",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PIRATHABIRAMANPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6072",
            "district": "19",
            "Block": "167",
            "label": "SRI JAGADEESHWARA MAT. HR. SEC. SCHOOL, MANAMELKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17253",
            "district": "19",
            "Block": "167",
            "label": "GOVT HIGH SCHOOL, EDAIYATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17254",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SINGAVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17255",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, KARAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17256",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHELLANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17257",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, JEGATHAPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17258",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, KOLENTHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17259",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANJAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17260",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VADAKKU AMMAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17261",
            "district": "19",
            "Block": "167",
            "label": "GOVT. GIRLS HR. SEC. SCHOOL, MANAMELKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17262",
            "district": "19",
            "Block": "167",
            "label": "GOVT. BOYS HR. SEC. SCHOOL, MANAMELKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17263",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELASTHANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17264",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HR. SEC. SCHOOL, AMBALAVANANENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17265",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NILAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17266",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HR. SEC. SCHOOL, PERUMARUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17267",
            "district": "19",
            "Block": "167",
            "label": "ST.MARY'S MIDDLE SCHOOL, MOOVANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "17268",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARKKAMALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17269",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17270",
            "district": "19",
            "Block": "167",
            "label": "ST. JOSEPH HR. SEC. SCHOOL, VICHOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17271",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, KRISHNAJIPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17272",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEDIYANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17273",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HIGH SCHOOL, GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17274",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PALAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17275",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HR. SEC. SCHOOL, KOTTAIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17276",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL(MC), KOTTAIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17277",
            "district": "19",
            "Block": "167",
            "label": "GOVT. GIRLS. HIGH SCHOOL, KOTTAIPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17278",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL BOYS, AMMAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17279",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVUDAIYARPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17280",
            "district": "19",
            "Block": "167",
            "label": "GOVT. GIRLS HR. SEC. SCHOOL, AMMAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17281",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THANDALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17282",
            "district": "19",
            "Block": "167",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARAKATHIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17283",
            "district": "19",
            "Block": "167",
            "label": "GOVT. HR. SEC. SCHOOL, KATTUMAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17284",
            "district": "19",
            "Block": "167",
            "label": "AL-HUDA MATRIC HIGH SCHOOL,AZHAGANVAYAL.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22590",
            "district": "19",
            "Block": "167",
            "label": "GOVT HIGH SCHOOL PONNAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22591",
            "district": "19",
            "Block": "167",
            "label": "GOVT HIGH SCHOOL PUDUKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "168": [
        {
            "key": "2580",
            "district": "29",
            "Block": "168",
            "label": "MONTFORT MAT.HSS, MANJAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3277",
            "district": "29",
            "Block": "168",
            "label": "LAKSHMI MATRICULATION HR. SEC. SCHOOL.MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3303",
            "district": "29",
            "Block": "168",
            "label": "SRI GURU MATRIC HR. SEC. SCHOOL, MANAPARAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3493",
            "district": "29",
            "Block": "168",
            "label": "VINMATHEE MATRIC HSS, MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14226",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KUTHIRAIKUTHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14227",
            "district": "29",
            "Block": "168",
            "label": "ST.XAVIAR MS KOTTAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14228",
            "district": "29",
            "Block": "168",
            "label": "ST.MARY'S HSS, SAMARIA, MANAPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14229",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, THOPPAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14230",
            "district": "29",
            "Block": "168",
            "label": "PUMS, MARAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14231",
            "district": "29",
            "Block": "168",
            "label": "PUMS, K.PERIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14232",
            "district": "29",
            "Block": "168",
            "label": "Infant Jesus HSS, Maravanoor",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14233",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, SAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14234",
            "district": "29",
            "Block": "168",
            "label": "PUMS, MUTHAPUDAIYANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14235",
            "district": "29",
            "Block": "168",
            "label": "ST.JOSEPH HR. SEC. SCHOOL, POTHAMETTUPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14236",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KATTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14237",
            "district": "29",
            "Block": "168",
            "label": "GHSS,KANNUDAYANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14238",
            "district": "29",
            "Block": "168",
            "label": "MONTFORT  SCHOOL (CBSE) , MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14239",
            "district": "29",
            "Block": "168",
            "label": "GADWMS, VENGAIKURICHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14240",
            "district": "29",
            "Block": "168",
            "label": "ST.ANTONY'S HSS, MANJAMPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14241",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, MALAIYADIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14242",
            "district": "29",
            "Block": "168",
            "label": "PUMS, A.KURUMBAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14243",
            "district": "29",
            "Block": "168",
            "label": "GOVT.ADW HSS, PAMPATTIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14244",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KEEZHAPOIGAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14245",
            "district": "29",
            "Block": "168",
            "label": "GOVT.ADW HSS, POIGAIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14246",
            "district": "29",
            "Block": "168",
            "label": "THIAKESAR ALAI HR.SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14247",
            "district": "29",
            "Block": "168",
            "label": "CAUVERY INTERNATIONAL  (CBSE) SCHOOL, MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14248",
            "district": "29",
            "Block": "168",
            "label": "THE ALMIGHTY'S MATRICULATION SCHOOL, MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14249",
            "district": "29",
            "Block": "168",
            "label": "PUMS, F.KEELAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14250",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, ANANDHANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14251",
            "district": "29",
            "Block": "168",
            "label": "GOVT HS, ANAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14252",
            "district": "29",
            "Block": "168",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PANNANKOMBU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14253",
            "district": "29",
            "Block": "168",
            "label": "PUMS, VALAIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14254",
            "district": "29",
            "Block": "168",
            "label": "PUMS, PITCHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14255",
            "district": "29",
            "Block": "168",
            "label": "PUMS, PANNAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14256",
            "district": "29",
            "Block": "168",
            "label": "MOHANDOSS MIDDLE SCHOOL, PERUMAMPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14257",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KARUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14258",
            "district": "29",
            "Block": "168",
            "label": "GHSS, AZHAGAGOUNDAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14259",
            "district": "29",
            "Block": "168",
            "label": "GOVT HSS, PUTHANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14260",
            "district": "29",
            "Block": "168",
            "label": "PUMS, VELLAIYAGOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14261",
            "district": "29",
            "Block": "168",
            "label": "PUMS, EDAIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14262",
            "district": "29",
            "Block": "168",
            "label": "ORACLE MAT.SCHOOL, PUTHANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14263",
            "district": "29",
            "Block": "168",
            "label": "THE CHALLENGERS VIDHAYALAYA MATRICULATION SCHOOL, MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14264",
            "district": "29",
            "Block": "168",
            "label": "MUNICIPAL MIDDLE SCHOOL, MANAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14265",
            "district": "29",
            "Block": "168",
            "label": "NATIONAL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14266",
            "district": "29",
            "Block": "168",
            "label": "ST.ANDREW'S HSS, MANAPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14267",
            "district": "29",
            "Block": "168",
            "label": "L.F.G.HSS, MANAPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14268",
            "district": "29",
            "Block": "168",
            "label": "GOVT GIRLS HSS, MANAPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14269",
            "district": "29",
            "Block": "168",
            "label": "GOVT BOYS HSS, MANAPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21092",
            "district": "29",
            "Block": "168",
            "label": "SOWMA PUBLIC SCHOOL(CBSE),MANAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21375",
            "district": "29",
            "Block": "168",
            "label": "GHS,KARUMPULIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21376",
            "district": "29",
            "Block": "168",
            "label": "GHS,PUTHANATHAM(GIRLS)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21508",
            "district": "29",
            "Block": "168",
            "label": "GADWHS,SEVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21532",
            "district": "29",
            "Block": "168",
            "label": "ST.IGNATIUS HIGH SCHOOL,AVARAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21760",
            "district": "29",
            "Block": "168",
            "label": "CHELLAMMAL MATRIC HIGHER SECONDARY SCHOOL, THIRUMALAIYANPATTI, MANAPPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21761",
            "district": "29",
            "Block": "168",
            "label": "SRI GURU VIDYALAYA (CBSE),MANAPPARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21958",
            "district": "29",
            "Block": "168",
            "label": "SARASWATHI VIDHYALAYA MATRICULATION HSS,ANDAVAR KOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22999",
            "district": "29",
            "Block": "168",
            "label": "GHS Vadakkuserpatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23272",
            "district": "29",
            "Block": "168",
            "label": "GHS KARUTHAKODANGIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23871",
            "district": "29",
            "Block": "168",
            "label": "PUMS, KATTUPATTY",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "169": [
        {
            "key": "832",
            "district": "20",
            "Block": "169",
            "label": "ALLWIN MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "928",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL ACADEMY MONTESSORI MATRIC  HIGHER SECONDARY SCHOOL, PATTANAMKATHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "933",
            "district": "20",
            "Block": "169",
            "label": "KALAIVANI MATRICULATION SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1029",
            "district": "20",
            "Block": "169",
            "label": "NABEESA AMMAL MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1036",
            "district": "20",
            "Block": "169",
            "label": "THE INTERNATIONAL MATRICULATION SCHOOL, IRUMENI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1081",
            "district": "20",
            "Block": "169",
            "label": "RAJA MATRIC HIGHER SECONDARY SCHOOL, KUNJARVALASAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1096",
            "district": "20",
            "Block": "169",
            "label": "HOLY ISLAND LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL, RAMESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1166",
            "district": "20",
            "Block": "169",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, RAMESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1167",
            "district": "20",
            "Block": "169",
            "label": "MOHAMED DASTAGIR MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1169",
            "district": "20",
            "Block": "169",
            "label": "PURATCHI THALAIVAR DR MGR MATRIC HIGHER SECONDARY SCHOOL, UCHIPULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1179",
            "district": "20",
            "Block": "169",
            "label": "UNITED MATRICULATION HIGH SCHOOL, PATTANAMKATHAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1451",
            "district": "20",
            "Block": "169",
            "label": "KING OF KINGS MATRIC HIGHER SECONDARY SCHOOL, MEYYAMPULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1463",
            "district": "20",
            "Block": "169",
            "label": "NAZIYA MATRIC HIGHER SECONDARY SCHOOL, ALAGANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1490",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL ACADEMY MATRIC HIGHER SECONDARY SCHOOL, UCHIPULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1524",
            "district": "20",
            "Block": "169",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL, PAMBAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1657",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL MATRICULATION HIGHER SECONDARY SCHOOL,  RAMESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2135",
            "district": "20",
            "Block": "169",
            "label": "ST. ANNE'S MATRICULATION HIGHER SECONDARY SCHOOL, AKKALMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2511",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL ACADEMY ICSE HIGHER SECONDARY SCHOOL,PATTANAMKATHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2522",
            "district": "20",
            "Block": "169",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3959",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NARAIYOORANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5602",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ERAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6464",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SOGAYANTHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19241",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RAMESWARAM NO.I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19242",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, RAMESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19243",
            "district": "20",
            "Block": "169",
            "label": "SRI PARVATHAVARTHINI AMMAN GIRLS HIGHER SECONDARY SCHOOL, RAMESWARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19244",
            "district": "20",
            "Block": "169",
            "label": "KENDRIYA VIDYALAYA HIGH SCHOOL, RAMESWARAM",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "19245",
            "district": "20",
            "Block": "169",
            "label": "VIVEKANANDA VIDYALAYA MIDDLE SCHOOL, RAMESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19246",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GANDHI NAGAR, MANDAPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19247",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, KARAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19248",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEYYAMPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19249",
            "district": "20",
            "Block": "169",
            "label": "KAMARAJ MIDDLE SCHOOL, THANGACHIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19250",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THANGACHIMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19251",
            "district": "20",
            "Block": "169",
            "label": "HOLY CROSS GIRLS HIGHER SECONDARY SCHOOL, THANGACHIMADAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19252",
            "district": "20",
            "Block": "169",
            "label": "PUNITHA YAGAPPAR  HIGHER SECONDARY SCHOOL, THANGACHIMADAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19253",
            "district": "20",
            "Block": "169",
            "label": "PRINCE OF PEACE MIDDLE SCHOOL, THANGACHIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19254",
            "district": "20",
            "Block": "169",
            "label": "ST. ANTONY'S MIDDLE SCHOOL, PAMBAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19255",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PAMBAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19256",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , CHINNAPPALAM, PAMBAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19257",
            "district": "20",
            "Block": "169",
            "label": "PERUNTHALAIVAR KAMARAJAR MIDDLE SCHOOL, PAMBAN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19258",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PIRAPPANVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19259",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, SUNDARAMUDAYAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19260",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , THARAVAITHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19261",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VEDHALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19262",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, IRUMENI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19263",
            "district": "20",
            "Block": "169",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL, VERKOTTU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19264",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALAGANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19265",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, KALKINATRUVALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19266",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUYAVANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19267",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, KUMBARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19268",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PUDUMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19269",
            "district": "20",
            "Block": "169",
            "label": "BAHRUDIN BOYS GOVERNMENT HIGHER SECONDARY SCHOOL, PANAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19270",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PANAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19271",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PATTANAMKATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19272",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, RETTAIYURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19273",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SATHANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19274",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THERBOGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19275",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19276",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UCHIPULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19277",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, PERUNGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19278",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL,VALANTHARAVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19279",
            "district": "20",
            "Block": "169",
            "label": "ARABI OLLIYULLA HIGH SCHOOL PUDUVALASAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19280",
            "district": "20",
            "Block": "169",
            "label": "KENDRIYA VIDYALAYA HIGHER SECONDARY SCHOOL, MANDAPAM CAMP",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19281",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANDAPAM CAMP",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19282",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, MANDAPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19283",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KADUKKAIVALASAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19284",
            "district": "20",
            "Block": "169",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,DHANUSKODI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22269",
            "district": "20",
            "Block": "169",
            "label": "NATIONAL ACADEMY CENTRAL SCHOOL, PATTANAMKATHAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22632",
            "district": "20",
            "Block": "169",
            "label": "CHRIST THE KING INTERNATIONAL SCHOOL, MEYYAMPULI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22633",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, RAMESWARAM ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22634",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL, VANIYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22695",
            "district": "20",
            "Block": "169",
            "label": "GOVERNMENT HIGH SCHOOL (GIRLS), PUDUMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22716",
            "district": "20",
            "Block": "169",
            "label": "AMRITA VIDYALAYAM, ARIYANGUNDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23055",
            "district": "20",
            "Block": "169",
            "label": "MOHAMED SATHAK KABEER PUBLIC SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23056",
            "district": "20",
            "Block": "169",
            "label": "AL QALAM INTERNATIONAL SCHOOL, PERUNGULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23315",
            "district": "20",
            "Block": "169",
            "label": "DR. ILAYARAJA GLOBAL ACADEMY, PIRAPPANVALASAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23530",
            "district": "20",
            "Block": "169",
            "label": "RAJA PUBLIC SCHOOL, KUNJARVALASAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23531",
            "district": "20",
            "Block": "169",
            "label": "RAMAKRISHNA MATH VIVEKANANDA VIDYALAYA CBSE SCHOOL, NAGACHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23530",
            "district": "20",
            "Block": "169",
            "label": "RAJA PUBLIC SCHOOL, KUNJARVALASAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23531",
            "district": "20",
            "Block": "169",
            "label": "RAMAKRISHNA MATH VIVEKANANDA VIDYALAYA CBSE SCHOOL, NAGACHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "170": [
        {
            "key": "3108",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3985",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THONDANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4006",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOZHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4106",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PATTAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4114",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ,  POTHIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4626",
            "district": "5",
            "Block": "170",
            "label": "SRI GNANAGURU VIDYALAYA MATRICULATION HIGHER  SECONDARY SCHOOL,  TITTAGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4720",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VALLIMATHURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5328",
            "district": "5",
            "Block": "170",
            "label": "SARASWATHI ARIVALAYAM HIGHER SECONDARY  SCHOOL , KALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5528",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5931",
            "district": "5",
            "Block": "170",
            "label": "ANNAI TERESA MATRICULATION HIGHER SECONDARY SCHOOL , KEELAKALPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6051",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AVATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6186",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL (MUSLIM) , PASAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6361",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SEVVERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6612",
            "district": "5",
            "Block": "170",
            "label": "PUMS , NAVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15432",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT BOYS' HIGHER SECONDARY SCHOOL, THOZHUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15433",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL,THOLUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15434",
            "district": "5",
            "Block": "170",
            "label": "OPR HIGH SCHOOL, THOZHUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15435",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL, EZHUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15436",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KANDAMATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15437",
            "district": "5",
            "Block": "170",
            "label": "ANDAVAR HIGH SCHOOL , KALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15438",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , KEELAKALPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15439",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KEEZHORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15440",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL ,  KORAKKAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15441",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , SIRUPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15442",
            "district": "5",
            "Block": "170",
            "label": "TAGORE  MATRICULATION SCHOOL, SIRUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15443",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL , PANAIYANTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15444",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PULIKARAMBALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15445",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANGALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15446",
            "district": "5",
            "Block": "170",
            "label": "ADI DRAVIDA WELFARE HIGHER SECONDARY SCHOOL , MA-PODAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15447",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MA.PODAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15448",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , RETTAKKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15449",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VADAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15450",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VINAYAGANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15451",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , AVINANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15452",
            "district": "5",
            "Block": "170",
            "label": "SRI VINAYAGA MATRICULATION SCHOOL, POTHIRAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15453",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KOTTARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15454",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL , VAIYANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15455",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15456",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, E.KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15457",
            "district": "5",
            "Block": "170",
            "label": "ADI DRAVIDA WELFARE MIDDLE SCHOOL , KAZHUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15458",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KAZHUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15459",
            "district": "5",
            "Block": "170",
            "label": "SRI VENKATESWARA MATRICULATION HIGHER SECONDARY  SCHOOL, KAZHUTHUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15460",
            "district": "5",
            "Block": "170",
            "label": "Panchayat Union Middle School,Alambadi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15461",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL,KORAKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15462",
            "district": "5",
            "Block": "170",
            "label": "NATIONAL MATRICULATION SCHOOL, THOLUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15463",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VAGAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15464",
            "district": "5",
            "Block": "170",
            "label": "GOVT. BOYS' HIGHER SECONDARY SCHOOL , TITTAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15465",
            "district": "5",
            "Block": "170",
            "label": "GOVT.GIRLS' HIGHER SECONDARY SCHOOL, TITTAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15466",
            "district": "5",
            "Block": "170",
            "label": "DR NAVALAR NEDUNCHEZHIYAN MATRIC HIGHER SECONDARY SCHOOL, TITTAGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15467",
            "district": "5",
            "Block": "170",
            "label": "INDIAN MATRICULATION HIGHER SECONDARY SCHOOL, TITTAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15468",
            "district": "5",
            "Block": "170",
            "label": "PUMS T. ELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15469",
            "district": "5",
            "Block": "170",
            "label": "AIDED MIDDLE SCHOOL, POYANAPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15470",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ADARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15471",
            "district": "5",
            "Block": "170",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA , THOZHUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15472",
            "district": "5",
            "Block": "170",
            "label": "SRI  KALAIMAGAL MATRICULATION SCHOOL-ADARI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15473",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL,ORANGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15474",
            "district": "5",
            "Block": "170",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, LAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15475",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL, THATCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15476",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL, SIRUMULAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15477",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL, VENGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15478",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL.EDAICHERUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20917",
            "district": "5",
            "Block": "170",
            "label": "SATHIYA SAAI PUBLIC SCHOOL, PASAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21032",
            "district": "5",
            "Block": "170",
            "label": "DR AKP OXBRIDGE MATRIC HIGHER SECONDARY SCHOOL,  SIRUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21221",
            "district": "5",
            "Block": "170",
            "label": "MANGALORE MODEL SCHOOL, M.PODAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21281",
            "district": "5",
            "Block": "170",
            "label": "SATHYA SAAI MATRICULATION HIGHER SECONDARY SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21471",
            "district": "5",
            "Block": "170",
            "label": "GOVERNMENT HIGH SCHOOL ,  RAMANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21554",
            "district": "5",
            "Block": "170",
            "label": "GOKULAM HIGH SCHOOL,VENGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22207",
            "district": "5",
            "Block": "170",
            "label": "Rainbow International Matric. Hr.Sec.School, Keelakalpoondi.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22208",
            "district": "5",
            "Block": "170",
            "label": "SRI VENKATESWARA CBSE SCHOOL, KAZHUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22556",
            "district": "5",
            "Block": "170",
            "label": "GREEN PARK MATRIC HIGHER SECONDARY  SCHOOL,ELUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22557",
            "district": "5",
            "Block": "170",
            "label": "GREEN PARK INTERNATIONAL SCHOOL, ELUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22558",
            "district": "5",
            "Block": "170",
            "label": "STEPHEN HIGH SCHOOL-KORAKKAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22833",
            "district": "5",
            "Block": "170",
            "label": "ASWIN'S MATRIC  SCHOOL,MANGALUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "171": [
        {
            "key": "2465",
            "district": "29",
            "Block": "171",
            "label": "HOLY CROSS MATRIC HR. SEC. SCHOOL, SOMARASAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3670",
            "district": "29",
            "Block": "171",
            "label": "SBIOA MATRIC HR. SEC. SCHOOL, AIR PORT K.K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4224",
            "district": "29",
            "Block": "171",
            "label": "INFANT JESUS MATRIC. HR. SEC. SCHOOL, EDAMALAIPATTI PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4414",
            "district": "29",
            "Block": "171",
            "label": "ST. VINCENT MATRIC HIGHER SECONDARY SCHOOL,  N. KUTTAPATTU VANNANKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4913",
            "district": "29",
            "Block": "171",
            "label": "CARE INTERNATIONAL SCHOOL (CBSE), THAYANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5041",
            "district": "29",
            "Block": "171",
            "label": "ALPHA WISDOM VIDHAYASHRAM SCHOOL (CBSE), K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5304",
            "district": "29",
            "Block": "171",
            "label": "CREA CHILDREN'S ACADEMY MATRIC HIGHER SECONDARY SCHOOL NAGAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5654",
            "district": "29",
            "Block": "171",
            "label": "ST.FRANCIS MATRIC. HR. SEC. SCHOOL, UKT MALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6381",
            "district": "29",
            "Block": "171",
            "label": "SRI KARAPAGA VINAYAGA MATRIC HSS, K.K. NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14186",
            "district": "29",
            "Block": "171",
            "label": "ST. PATRICK'S HR. SEC. SCHOOL, ALUNDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14187",
            "district": "29",
            "Block": "171",
            "label": "ST. PATRICK'S ACADAMY (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14188",
            "district": "29",
            "Block": "171",
            "label": "HMY GOVT. HSS INAMKULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14189",
            "district": "29",
            "Block": "171",
            "label": "R V S INTERNATIONAL (CBSE) SCHOOL, INAMKULATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14190",
            "district": "29",
            "Block": "171",
            "label": "GHS, PERIYA ALAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14191",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HR. SEC. SCHOOL, INAM MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14192",
            "district": "29",
            "Block": "171",
            "label": "PUMS, M.G.R NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14193",
            "district": "29",
            "Block": "171",
            "label": "NEHRU AIDED MS MUTHUKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14194",
            "district": "29",
            "Block": "171",
            "label": "PUMS, N.KUTTAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14195",
            "district": "29",
            "Block": "171",
            "label": "ANNAI INDIRA GANDHI MEMORIAL HSS, MUTHUKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14196",
            "district": "29",
            "Block": "171",
            "label": "MARY MATHA HIGH SCHOOL, SOUTH PAGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14197",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, INAM PERIYANAYAGI CHATHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14198",
            "district": "29",
            "Block": "171",
            "label": "PUMS, MATTAPARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14199",
            "district": "29",
            "Block": "171",
            "label": "PUMS, K.KALLIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14200",
            "district": "29",
            "Block": "171",
            "label": "PUMS, PANJAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14201",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HR. SEC. SCHOOL, POONGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14202",
            "district": "29",
            "Block": "171",
            "label": "ST. ANTONY'S RC AMS, NORTH PAGANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14203",
            "district": "29",
            "Block": "171",
            "label": "LAWRENCE MATRIC. HIGH SCHOOL, MULLIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14204",
            "district": "29",
            "Block": "171",
            "label": "ALPHA PLUS MATRIC HR. SEC. SCHOOL, K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14205",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, K K NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14206",
            "district": "29",
            "Block": "171",
            "label": "ORCHARD MATRIC. HR. SEC. SCHOOL, K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14207",
            "district": "29",
            "Block": "171",
            "label": "PUMS, K. SATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14208",
            "district": "29",
            "Block": "171",
            "label": "HOLY FAMILY R C HR. SEC. SCHOOL, AMMAPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14209",
            "district": "29",
            "Block": "171",
            "label": "AMRITA VIDHYALAYAM (CBSE), SOLANGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14210",
            "district": "29",
            "Block": "171",
            "label": "AIDED MS,PALLAKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14211",
            "district": "29",
            "Block": "171",
            "label": "ST. JOAN OF ARC INTER. SCHOOL (CBSE), SUNNAMBUKARANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14212",
            "district": "29",
            "Block": "171",
            "label": "PUMS, PIRATIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14213",
            "district": "29",
            "Block": "171",
            "label": "ST JOSEPH'S HR SEC SCHOOL, PUNGANUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14214",
            "district": "29",
            "Block": "171",
            "label": "S.A.S MATRICULATION HR. SEC. SCHOOL, PUNGANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14215",
            "district": "29",
            "Block": "171",
            "label": "PUMS, SOMARASAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14216",
            "district": "29",
            "Block": "171",
            "label": "ST.JOSEPH'S HR. SEC. SCHOOL, SOMARASAMPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14217",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HR. SEC. SCHOOL, SOMARASAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14218",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, THAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14219",
            "district": "29",
            "Block": "171",
            "label": "MUTHURAJA AMS, ADAVATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14220",
            "district": "29",
            "Block": "171",
            "label": "SRI SIVANANDA BALALAYA CBSE & NIOS SCHOOL, ADAVATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14221",
            "district": "29",
            "Block": "171",
            "label": "RC MIDDLE SCHOOL, UYYAKONDAN THIRUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14222",
            "district": "29",
            "Block": "171",
            "label": "SRI UMA PARAMESHWARI AMS, RAMJEE NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14223",
            "district": "29",
            "Block": "171",
            "label": "KALAIMAGAL AIDED HS, EDAMALAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14224",
            "district": "29",
            "Block": "171",
            "label": "ST.JOSEPH'S HR. SEC. SCHOOL, NAGAMANGALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14225",
            "district": "29",
            "Block": "171",
            "label": "GOOD SAMARITAN HS, NAGAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21030",
            "district": "29",
            "Block": "171",
            "label": "VIKAS VIDHYASHRAM MATRICULATION HIGHER SECONDARY SCHOOL, INAMKULATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21091",
            "district": "29",
            "Block": "171",
            "label": "SAS VIDYALAYA (CBSE), DHEERAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21325",
            "district": "29",
            "Block": "171",
            "label": "MOUNT LITERA ZEE HSS (CBSE), UYYAKONDAN THIRUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21374",
            "district": "29",
            "Block": "171",
            "label": "GHS (GIRLS), INAMKULATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21762",
            "district": "29",
            "Block": "171",
            "label": "ALPHA CAMBRIDGE INTERNATIONAL. SCHOOL, K.K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21763",
            "district": "29",
            "Block": "171",
            "label": "SBIOA SR. SEC. SCHOOL (CBSE), AIRPORT POST, TRICHY-7",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22520",
            "district": "29",
            "Block": "171",
            "label": "GHS, YAGAPUDAIYANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22521",
            "district": "29",
            "Block": "171",
            "label": "SIGMA SCHOOL, MANIKANDAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22522",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, OLAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22523",
            "district": "29",
            "Block": "171",
            "label": "AKARA WORLD SCHOOL (CBSE), K K NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22524",
            "district": "29",
            "Block": "171",
            "label": "GOVT. HIGH SCHOOL, EDAMALAIPATTI PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22794",
            "district": "29",
            "Block": "171",
            "label": "SRV PUBLIC SCHOOL, PIRATIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22797",
            "district": "29",
            "Block": "171",
            "label": "AWNIYA PUBLIC SCHOOL, AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23135",
            "district": "29",
            "Block": "171",
            "label": "MOUNT HIRA PUBLIC SCHOOL, K.SATHANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23579",
            "district": "29",
            "Block": "171",
            "label": "TIRUCHIRAPPALLI DISTRICT  GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23580",
            "district": "29",
            "Block": "171",
            "label": "MOULANA JAMALI MATRICULATION SCHOOL, INAM KULATHUR, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23581",
            "district": "29",
            "Block": "171",
            "label": "THE INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23582",
            "district": "29",
            "Block": "171",
            "label": "AGACHEVI TRANSITIONAL SCHOOL FOR HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23579",
            "district": "29",
            "Block": "171",
            "label": "TIRUCHIRAPPALLI DISTRICT  GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23580",
            "district": "29",
            "Block": "171",
            "label": "MOULANA JAMALI MATRICULATION SCHOOL, INAM KULATHUR, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23581",
            "district": "29",
            "Block": "171",
            "label": "THE INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23582",
            "district": "29",
            "Block": "171",
            "label": "AGACHEVI TRANSITIONAL SCHOOL FOR HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23872",
            "district": "29",
            "Block": "171",
            "label": "TIRUCHIRAPPALLI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23873",
            "district": "29",
            "Block": "171",
            "label": "ACHARIYA SIKSHA MANDIR,K.SATHANUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "172": [
        {
            "key": "2248",
            "district": "29",
            "Block": "172",
            "label": "DON BOSKO MATRIC HIGH SCHOOL,SOUTH IRUNGALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2556",
            "district": "29",
            "Block": "172",
            "label": "ANVARUL MUSLIMEEN M.S",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2773",
            "district": "29",
            "Block": "172",
            "label": "SRI SARAVANABHAVA VIDYALAYA MATRIC HR. SEC. SCHOOL,MANNACHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3425",
            "district": "29",
            "Block": "172",
            "label": "M.A.M.MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4326",
            "district": "29",
            "Block": "172",
            "label": "YWCA MAT.,BIKSHANDARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5111",
            "district": "29",
            "Block": "172",
            "label": "SRI BALA VIDYAMANDIR MATRIC HSS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5571",
            "district": "29",
            "Block": "172",
            "label": "SRI MEIKANDAAR  MATRIC HR. SECONDARY SCHOOL,MANNACHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6300",
            "district": "29",
            "Block": "172",
            "label": "ST.MARY'S MATRIC HSS SCHOOL,TOLGATE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14142",
            "district": "29",
            "Block": "172",
            "label": "GOVT.BOYS HSC, MANNACHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14143",
            "district": "29",
            "Block": "172",
            "label": "GOVT  MODEL GIRLS HSS, MANNACHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14144",
            "district": "29",
            "Block": "172",
            "label": "AMS LALITHA, ULANTHANKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14145",
            "district": "29",
            "Block": "172",
            "label": "SRIMARIAMMAN H.S.S.SAMAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14146",
            "district": "29",
            "Block": "172",
            "label": "GHS, S.KANNANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14147",
            "district": "29",
            "Block": "172",
            "label": "ST.JOHN'S H.S.S IRUNGALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14148",
            "district": "29",
            "Block": "172",
            "label": "ST.XAVIER'S H.S.S, PURATHAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14149",
            "district": "29",
            "Block": "172",
            "label": "ST.THERASA'S AID. HS,KONALAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14150",
            "district": "29",
            "Block": "172",
            "label": "PUMS, 85.SRIDEVI MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14151",
            "district": "29",
            "Block": "172",
            "label": "PUMS,INAMTHIRUPATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14152",
            "district": "29",
            "Block": "172",
            "label": "G.H.S, PERAGAMBI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14153",
            "district": "29",
            "Block": "172",
            "label": "GHS, EDUMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14154",
            "district": "29",
            "Block": "172",
            "label": "GHSS,SIRUGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14155",
            "district": "29",
            "Block": "172",
            "label": "M.A.M SENIOR SECONDARY (CBSE) SCHOOL, SIRUGANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14156",
            "district": "29",
            "Block": "172",
            "label": "ADWHSS, M.R.PALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14157",
            "district": "29",
            "Block": "172",
            "label": "PUMS, VAZHAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14158",
            "district": "29",
            "Block": "172",
            "label": "PUMS, AZHAGIYAMANAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14159",
            "district": "29",
            "Block": "172",
            "label": "GHSS, C.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14160",
            "district": "29",
            "Block": "172",
            "label": "PUMS, INAM KALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14161",
            "district": "29",
            "Block": "172",
            "label": "RAMA NATARAJA HIGH SCHOOL, 94 KARIYAMANICKAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14162",
            "district": "29",
            "Block": "172",
            "label": "PUMS, KOOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14163",
            "district": "29",
            "Block": "172",
            "label": "SRI VIGNESH VIDYALAYA SENIOR SEC. SCHOOL, KOOTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14164",
            "district": "29",
            "Block": "172",
            "label": "PUMS, PACHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14165",
            "district": "29",
            "Block": "172",
            "label": "PUMS,MADHAVAPERUMAL KOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14166",
            "district": "29",
            "Block": "172",
            "label": "PUMS, THINAIPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14167",
            "district": "29",
            "Block": "172",
            "label": "GHSS,OMANTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14168",
            "district": "29",
            "Block": "172",
            "label": "RAJA HS, BIKSHANDARKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14169",
            "district": "29",
            "Block": "172",
            "label": "PUMS, RASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14170",
            "district": "29",
            "Block": "172",
            "label": "GHS,POONAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14171",
            "district": "29",
            "Block": "172",
            "label": "SRV MATRICULATION HR SEC  SCHOOL ,SAMAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14172",
            "district": "29",
            "Block": "172",
            "label": "PUMS, VEERANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14173",
            "district": "29",
            "Block": "172",
            "label": "GOVT ADW M.S.DEVI MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14174",
            "district": "29",
            "Block": "172",
            "label": "GANTHIYA MANYA AMS, THEERAMPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14175",
            "district": "29",
            "Block": "172",
            "label": "PUMS THILLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14176",
            "district": "29",
            "Block": "172",
            "label": "PUMS,VALMALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14177",
            "district": "29",
            "Block": "172",
            "label": "GHSS,THIRUPPANJILI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14178",
            "district": "29",
            "Block": "172",
            "label": "G.H.S.S THIRUVELLARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14179",
            "district": "29",
            "Block": "172",
            "label": "PUMS KALAVAIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14180",
            "district": "29",
            "Block": "172",
            "label": "GOVT. ADW HR. SEC. SCHOOL, THIRUVASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14181",
            "district": "29",
            "Block": "172",
            "label": "PUMS, THODAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14182",
            "district": "29",
            "Block": "172",
            "label": "GHSS, VALAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14183",
            "district": "29",
            "Block": "172",
            "label": "PUMS, SENNAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14184",
            "district": "29",
            "Block": "172",
            "label": "GHSS, SIRUGAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14185",
            "district": "29",
            "Block": "172",
            "label": "GHS, KURUVAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21507",
            "district": "29",
            "Block": "172",
            "label": "GOVT ADWHS ,VENGANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21878",
            "district": "29",
            "Block": "172",
            "label": "SVR MATRIC SCHOOL,ATHANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21879",
            "district": "29",
            "Block": "172",
            "label": "RITI VIKASH VIDHAYALA,THEERAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22793",
            "district": "29",
            "Block": "172",
            "label": "SRV PUBLIC SCHOOL, SAMAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22867",
            "district": "29",
            "Block": "172",
            "label": "RANE VIDYALAYA (CBSE), THEERAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23289",
            "district": "29",
            "Block": "172",
            "label": "MAHALAKSHMI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "173": [
        {
            "key": "1351",
            "district": "35",
            "Block": "173",
            "label": "UAMHS ST.JOSEPH MATRIC.HIGH SCHOOL,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2908",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS DHARANI MATRIC ,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3988",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS OXFORD , KOOTHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4269",
            "district": "35",
            "Block": "173",
            "label": "MMS,GOPALA SAMUDRAM MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4502",
            "district": "35",
            "Block": "173",
            "label": "MMS MODEL, MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4666",
            "district": "35",
            "Block": "173",
            "label": "MMS RAJAMPALAYAM, MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4709",
            "district": "35",
            "Block": "173",
            "label": "PUMS,EATHAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4747",
            "district": "35",
            "Block": "173",
            "label": "UAMHS INDIA INTERNATIONAL MATRIC, KOOTHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5091",
            "district": "35",
            "Block": "173",
            "label": "PUMS.THENPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5129",
            "district": "35",
            "Block": "173",
            "label": "PUMS PERIYAKOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5357",
            "district": "35",
            "Block": "173",
            "label": "PUMS.MELANEMMELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5451",
            "district": "35",
            "Block": "173",
            "label": "PUMS.NEMMELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5502",
            "district": "35",
            "Block": "173",
            "label": "PUMS,OHAI PERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5934",
            "district": "35",
            "Block": "173",
            "label": "PUMS PANDUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6241",
            "district": "35",
            "Block": "173",
            "label": "PUMS NEDUVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6396",
            "district": "35",
            "Block": "173",
            "label": "PUMS.PAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16180",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS DEVI MATRIC,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16181",
            "district": "35",
            "Block": "173",
            "label": "PUMS.VENKARAMPERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16182",
            "district": "35",
            "Block": "173",
            "label": "PUMS,CHITHIRAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16183",
            "district": "35",
            "Block": "173",
            "label": "PUMS.ARICHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16184",
            "district": "35",
            "Block": "173",
            "label": "UAMS,SRI VIVEKANANDHA VIDYALAYA  MATRICULATION  SCHOOL , KARIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16185",
            "district": "35",
            "Block": "173",
            "label": "PUMS.KANDITHAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16186",
            "district": "35",
            "Block": "173",
            "label": "GHSS,THIRUVALLUVAR ALANGOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16187",
            "district": "35",
            "Block": "173",
            "label": "GHSS MAHADEVAPPATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16188",
            "district": "35",
            "Block": "173",
            "label": "PUMS.MELATHIRUPPALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16189",
            "district": "35",
            "Block": "173",
            "label": "PUMS, THIRURAMESWARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16190",
            "district": "35",
            "Block": "173",
            "label": "AHS,THIRUMURUGAN,MELAVASAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16191",
            "district": "35",
            "Block": "173",
            "label": "PUMS.MARAVAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16192",
            "district": "35",
            "Block": "173",
            "label": "GHS, THANNEERKUNNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16193",
            "district": "35",
            "Block": "173",
            "label": "PUMS.RAJAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16194",
            "district": "35",
            "Block": "173",
            "label": "GHSS PAINGANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16195",
            "district": "35",
            "Block": "173",
            "label": "AMS VENUDAIYAR, PALAIYANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16196",
            "district": "35",
            "Block": "173",
            "label": "GHSS PARAVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16197",
            "district": "35",
            "Block": "173",
            "label": "GHSS,PULLAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16198",
            "district": "35",
            "Block": "173",
            "label": "GHSS,SERANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16199",
            "district": "35",
            "Block": "173",
            "label": "PUMS.SUNDHARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16200",
            "district": "35",
            "Block": "173",
            "label": "ADWHSS, SAVALAKARAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16201",
            "district": "35",
            "Block": "173",
            "label": "AHS MEENAKSHI MARIYAPPAR, CHOLAPANDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16202",
            "district": "35",
            "Block": "173",
            "label": "GHS,THULASENDRAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16203",
            "district": "35",
            "Block": "173",
            "label": "PUMS,VADAKOVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16204",
            "district": "35",
            "Block": "173",
            "label": "GHS, VADAPATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16205",
            "district": "35",
            "Block": "173",
            "label": "PUMS.BOOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16206",
            "district": "35",
            "Block": "173",
            "label": "PUMS.VADAPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16207",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS,NAVA BHARATH MATRIC HR.SEC.SCHOOL ULLIKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16208",
            "district": "35",
            "Block": "173",
            "label": "GHSS,ULLIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16209",
            "district": "35",
            "Block": "173",
            "label": "CBSE, ARJ HR.SEC SCHOOL, EDAYAR NATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16210",
            "district": "35",
            "Block": "173",
            "label": "UAMS ESAR MATRIC SCHOOL, LAKSHMANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16211",
            "district": "35",
            "Block": "173",
            "label": "GBHSS, KOOTHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16212",
            "district": "35",
            "Block": "173",
            "label": "PUMS MARAKKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16213",
            "district": "35",
            "Block": "173",
            "label": "AHSS (BOYS) ,MANBA-UL-ULA KOOTHANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16214",
            "district": "35",
            "Block": "173",
            "label": "GGHSS,KOOTHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16215",
            "district": "35",
            "Block": "173",
            "label": "CWSN MS MANOLAYAM, MELAPANANGATTANKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16216",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS SBA MATRIC SCHOOL,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16217",
            "district": "35",
            "Block": "173",
            "label": "AMS JAYALAKSHMI VILAS, MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16218",
            "district": "35",
            "Block": "173",
            "label": "AMS,VANI VILAS, MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16219",
            "district": "35",
            "Block": "173",
            "label": "AHS ADHIPARASAKTHI, MANNARGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16220",
            "district": "35",
            "Block": "173",
            "label": "UAMHS,ASHOKA SISHU VIHAR MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16221",
            "district": "35",
            "Block": "173",
            "label": "AMS,MANIMEKALAI ,MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16222",
            "district": "35",
            "Block": "173",
            "label": "AHSS,NATIONAL, MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16223",
            "district": "35",
            "Block": "173",
            "label": "UAMHS,BARATHI VIDYALAYA, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16224",
            "district": "35",
            "Block": "173",
            "label": "UAMHS SUBASH CHANDRA BOSE MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16225",
            "district": "35",
            "Block": "173",
            "label": "GGHSS MODEL ,MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16226",
            "district": "35",
            "Block": "173",
            "label": "AGHSS ST.JOSEPH, MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16227",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS SAVIOUR JESUS MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16228",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS SRI SHANMUKA , MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16229",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS,BHARATHIDASAN MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16230",
            "district": "35",
            "Block": "173",
            "label": "UAHSS,LORD SEVEN HILLS MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16231",
            "district": "35",
            "Block": "173",
            "label": "UAHS,MANNAI NARAYANASAMY MATRIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16232",
            "district": "35",
            "Block": "173",
            "label": "UAMHSS SRI SHANMUKA , NEW BYE PASS ROAD, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16233",
            "district": "35",
            "Block": "173",
            "label": "AHS ST JOSEPH MANNARGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16234",
            "district": "35",
            "Block": "173",
            "label": "CBSE DHARANI VIDYA MANTHIR  ,MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21225",
            "district": "35",
            "Block": "173",
            "label": "AHSS FINDLAY ,MANNARGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21232",
            "district": "35",
            "Block": "173",
            "label": "MHSS CO_OPERATIVE URBAN,MANNARGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21472",
            "district": "35",
            "Block": "173",
            "label": "GHS,MOOVANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21764",
            "district": "35",
            "Block": "173",
            "label": "UAMS DR ALAMEEN AR ,MATRICULATION SCHOOL  NAHANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22573",
            "district": "35",
            "Block": "173",
            "label": "GHS,VELUKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22769",
            "district": "35",
            "Block": "173",
            "label": "CBSE,DELTA PUBLIC SCHOOL,PANANKATTANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23626",
            "district": "35",
            "Block": "173",
            "label": "CBSE CONCORDIA PUBLIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23626",
            "district": "35",
            "Block": "173",
            "label": "CBSE CONCORDIA PUBLIC, MANNARGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23874",
            "district": "35",
            "Block": "173",
            "label": "UAPPS KASTURBA GANDHI MATRICULATION,  PUNAVASAL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "174": [
        {
            "key": "238",
            "district": "30",
            "Block": "174",
            "label": "GHS DEVARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "243",
            "district": "30",
            "Block": "174",
            "label": "GHSS VANNICONENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "245",
            "district": "30",
            "Block": "174",
            "label": "GHS VELLAPPANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "246",
            "district": "30",
            "Block": "174",
            "label": "GHS VENKATACHALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "249",
            "district": "30",
            "Block": "174",
            "label": "HINDU MIDDLE SSHOOL CHOKKANACHIYALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "255",
            "district": "30",
            "Block": "174",
            "label": "PUMS PANNEERUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "259",
            "district": "30",
            "Block": "174",
            "label": "SANKARI MATRIC HR. SEC. SCHOOL, DEVARKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "263",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS DEVARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "264",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS MELAILANDAIKUALM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "265",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS MOOVIRUNTHALI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "266",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS NORTH ACHAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "267",
            "district": "30",
            "Block": "174",
            "label": "TIHSS MELAILANDAIKUALM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "463",
            "district": "30",
            "Block": "174",
            "label": "PUMS PALAMADAI UDAYANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1517",
            "district": "30",
            "Block": "174",
            "label": "PUMS,PATHINALAMPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1686",
            "district": "30",
            "Block": "174",
            "label": "PUMS,NELLAITHIRUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1821",
            "district": "30",
            "Block": "174",
            "label": "K.M.P VIDYALAYA MATRIC. HR.SEC.SCHOOL, MANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1949",
            "district": "30",
            "Block": "174",
            "label": "PUMS THALAYOOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2029",
            "district": "30",
            "Block": "174",
            "label": "PUMS,METTU PIRANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2040",
            "district": "30",
            "Block": "174",
            "label": "TDTAMS,PARVATHIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2194",
            "district": "30",
            "Block": "174",
            "label": "PUMS VADAKKIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2403",
            "district": "30",
            "Block": "174",
            "label": "PUMS,VENKATACHALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2628",
            "district": "30",
            "Block": "174",
            "label": "PUMS,THIRUPANIKARISALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2848",
            "district": "30",
            "Block": "174",
            "label": "ST ANTONY HSS,ALAVANTHANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2922",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS,NORTH VAGAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3184",
            "district": "30",
            "Block": "174",
            "label": "PUMS,KURICHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3494",
            "district": "30",
            "Block": "174",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , DURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4310",
            "district": "30",
            "Block": "174",
            "label": "TDTAMS,NANCHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19742",
            "district": "30",
            "Block": "174",
            "label": "ST PETERS HSS,UKIRANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19743",
            "district": "30",
            "Block": "174",
            "label": "NIM HS,THERKUPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19744",
            "district": "30",
            "Block": "174",
            "label": "PUMS,SUBBIAHPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19745",
            "district": "30",
            "Block": "174",
            "label": "GHS,CHETTIKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19746",
            "district": "30",
            "Block": "174",
            "label": "GHS,MELAPILLAIYARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19747",
            "district": "30",
            "Block": "174",
            "label": "GOVT HSS,VADAKKU CHELIYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19748",
            "district": "30",
            "Block": "174",
            "label": "GHSS,GANGAIKONDAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19749",
            "district": "30",
            "Block": "174",
            "label": "GHSS,PALAMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19750",
            "district": "30",
            "Block": "174",
            "label": "TDTA MS,THENKALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19751",
            "district": "30",
            "Block": "174",
            "label": "DR. AMBEDKAR GOVT.(A.D.W) HIGHER SECONDARY SCHOOL, NALLAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19752",
            "district": "30",
            "Block": "174",
            "label": "GOVT(ADW) GIRLS HR SEC SCHOOL, NALLAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19753",
            "district": "30",
            "Block": "174",
            "label": "GHS PALLAMADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19754",
            "district": "30",
            "Block": "174",
            "label": "GHSS,MANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19756",
            "district": "30",
            "Block": "174",
            "label": "G(ADW)HSS,THULUKKARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19759",
            "district": "30",
            "Block": "174",
            "label": "GHSS,SUTHAMALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19760",
            "district": "30",
            "Block": "174",
            "label": "GHS,NARASINGANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21414",
            "district": "30",
            "Block": "174",
            "label": "G(G) HS GANGAIKONDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21415",
            "district": "30",
            "Block": "174",
            "label": "G(G)HS  MANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21923",
            "district": "30",
            "Block": "174",
            "label": "SRI ARAVIND VIDYALAYA N&PS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21924",
            "district": "30",
            "Block": "174",
            "label": "LITTLE FLOWER PUBLIC SCHOOL GANDHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22278",
            "district": "30",
            "Block": "174",
            "label": "ST JOHN'S MATRICULATION SCHOOL,GANGAIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22279",
            "district": "30",
            "Block": "174",
            "label": "HILLEYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22647",
            "district": "30",
            "Block": "174",
            "label": "GHS RASTHA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22728",
            "district": "30",
            "Block": "174",
            "label": "NAMMA OORU GLOBAL SCHOOLS, GANGAIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22905",
            "district": "30",
            "Block": "174",
            "label": "GHS ETTANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22906",
            "district": "30",
            "Block": "174",
            "label": "GHS KOOVACHIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23111",
            "district": "30",
            "Block": "174",
            "label": "ST JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "175": [
        {
            "key": "5503",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENKALAVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6298",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SALAVATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6721",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10208",
            "district": "37",
            "Block": "175",
            "label": "ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL SINGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10209",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENPAZAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10210",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL OMANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10211",
            "district": "37",
            "Block": "175",
            "label": "VKM VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10212",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ANNAM PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10213",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10214",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL, KEEL EDAYALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10215",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOLASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10216",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL KOVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10217",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENNERGUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10218",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ENDIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10219",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL VITTALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10220",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUMUKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10221",
            "district": "37",
            "Block": "175",
            "label": "GANDHIJI HIGH SCHOOL KIZHSIVIRI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10222",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T.NALLALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10223",
            "district": "37",
            "Block": "175",
            "label": "GOVERMENT HIGHER SECONDARY SCHOOL  BRAMMADESAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10224",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VANNIPPER",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10225",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VADANERKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10226",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10227",
            "district": "37",
            "Block": "175",
            "label": "SUBHASHREE VIDHYALAYA MATRICULATION SCHOOL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10228",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL ALANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10229",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEPPERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10230",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL OMIPPER",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10231",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10232",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL KANDADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10233",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EKKIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10234",
            "district": "37",
            "Block": "175",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL KAZHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10235",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MARAKKANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10236",
            "district": "37",
            "Block": "175",
            "label": "SRI RAMAKRISHNA MATRIC HIGHER SECONDARY SCHOOL MARAKKANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10237",
            "district": "37",
            "Block": "175",
            "label": "JMJ MATRIC HIGHER SECONDARY SCHOOL MARAKKANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10238",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT (GIRLS) HIGH SCHOOL MARAKKANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10239",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL KAIPANIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10240",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10241",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANICHAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10242",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ANUMANDAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10243",
            "district": "37",
            "Block": "175",
            "label": "BHARATHIYAR MATRIC HR. SEC SCHOOL,  MARAKKANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10244",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL M.PUDUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10245",
            "district": "37",
            "Block": "175",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10246",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NADUKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10247",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KOONIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10248",
            "district": "37",
            "Block": "175",
            "label": "ALM MATRICULATION HIGHER SECONDARY SCHOOL KOONIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10249",
            "district": "37",
            "Block": "175",
            "label": "AL- RASHEED MATRICULATION SCHOOL KOONIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10250",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL MUDALIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10251",
            "district": "37",
            "Block": "175",
            "label": "SAMINATHA VIDHYALAYA HIGH SCHOOL KEEZHPUTHUPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10252",
            "district": "37",
            "Block": "175",
            "label": "RYAN MATRICULATION SCHOOL NAMBIKKAINALLUR KILPUTHUPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21452",
            "district": "37",
            "Block": "175",
            "label": "GOVERNMENT HIGH SCHOOL MUNNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21591",
            "district": "37",
            "Block": "175",
            "label": "SHRI RAM SCHOOL,OMANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22758",
            "district": "37",
            "Block": "175",
            "label": "CHANAKYA VIDYASHRAM (CBSE) MARAKKANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "176": [
        {
            "key": "1676",
            "district": "29",
            "Block": "176",
            "label": "PUMS,Yagapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3316",
            "district": "29",
            "Block": "176",
            "label": "PUMS,VADAKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3593",
            "district": "29",
            "Block": "176",
            "label": "PUMS,D.PUTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4849",
            "district": "29",
            "Block": "176",
            "label": "ST.ANTONY'S MATRIC SCHOOL, THUVARAKURCHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5109",
            "district": "29",
            "Block": "176",
            "label": "PUMS,AATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5186",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KARADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5747",
            "district": "29",
            "Block": "176",
            "label": "PUMS,PILLUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5821",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ENDAPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5945",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6609",
            "district": "29",
            "Block": "176",
            "label": "VIDIYAL MATRIC.HSS,V.KAIKATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14270",
            "district": "29",
            "Block": "176",
            "label": "PUMS,THOTTIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14271",
            "district": "29",
            "Block": "176",
            "label": "PUMS,IRATTIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14272",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Kovilpatty",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14273",
            "district": "29",
            "Block": "176",
            "label": "PUMS,VAGUTHALVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14274",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Amanakkampatty",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14275",
            "district": "29",
            "Block": "176",
            "label": "GHS,Kannivadugapatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14276",
            "district": "29",
            "Block": "176",
            "label": "GHS,Kannuthu",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14277",
            "district": "29",
            "Block": "176",
            "label": "GHS,Maniyankurichy",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14278",
            "district": "29",
            "Block": "176",
            "label": "GHS,Kallakampatty",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14279",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KARVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14280",
            "district": "29",
            "Block": "176",
            "label": "GHSS,T.SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14281",
            "district": "29",
            "Block": "176",
            "label": "PUMS,MUDUKKUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14282",
            "district": "29",
            "Block": "176",
            "label": "PUMS,GOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14283",
            "district": "29",
            "Block": "176",
            "label": "GHS,Mettupatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14284",
            "district": "29",
            "Block": "176",
            "label": "PUMS,POLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14285",
            "district": "29",
            "Block": "176",
            "label": "GHS,THENUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14286",
            "district": "29",
            "Block": "176",
            "label": "PUMS,A.PUTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14287",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Uthukkuli",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14288",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ARRIYAKKONPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14289",
            "district": "29",
            "Block": "176",
            "label": "PUMS,VELLAPITCHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14290",
            "district": "29",
            "Block": "176",
            "label": "GHS,VEMBANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14291",
            "district": "29",
            "Block": "176",
            "label": "GHS,Unaiyur",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14292",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KODUMBAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14293",
            "district": "29",
            "Block": "176",
            "label": "PUMS,D.PORUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14294",
            "district": "29",
            "Block": "176",
            "label": "ST.JAMES HSS,Palakurichy",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14295",
            "district": "29",
            "Block": "176",
            "label": "ST.JUSTIN GIRLS HR. SEC.SCHOOL, PALAKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14296",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KALINGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14297",
            "district": "29",
            "Block": "176",
            "label": "GHS,Subbarayapatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14298",
            "district": "29",
            "Block": "176",
            "label": "PUMS,SOKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14299",
            "district": "29",
            "Block": "176",
            "label": "PUMS,A.VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14300",
            "district": "29",
            "Block": "176",
            "label": "GHSS,VALANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14301",
            "district": "29",
            "Block": "176",
            "label": "GHS,T.Edayapatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14302",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Marungapuri",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14303",
            "district": "29",
            "Block": "176",
            "label": "PUMS,THALAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14304",
            "district": "29",
            "Block": "176",
            "label": "Panchayat Union Middle school,T.SOKKANATHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14305",
            "district": "29",
            "Block": "176",
            "label": "PUMS,NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14306",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ADAIKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14307",
            "district": "29",
            "Block": "176",
            "label": "PUMS,KARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14308",
            "district": "29",
            "Block": "176",
            "label": "GHS,Kanjanayakanpatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14309",
            "district": "29",
            "Block": "176",
            "label": "PUMS,IKKARAIGOSIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14310",
            "district": "29",
            "Block": "176",
            "label": "PUMS,PIDARAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14311",
            "district": "29",
            "Block": "176",
            "label": "GHSS,Sevalpatti",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14312",
            "district": "29",
            "Block": "176",
            "label": "PUMS,AKKIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14313",
            "district": "29",
            "Block": "176",
            "label": "GHS,Thethur",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14314",
            "district": "29",
            "Block": "176",
            "label": "PUMS,ATHIKARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14315",
            "district": "29",
            "Block": "176",
            "label": "PUMS,PONNAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14316",
            "district": "29",
            "Block": "176",
            "label": "Govt Model Boys Hr. Sec. School,Thuvarankurichy",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14317",
            "district": "29",
            "Block": "176",
            "label": "GHSS GIRLS,Thuvarankurichy",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14318",
            "district": "29",
            "Block": "176",
            "label": "BHARATH MATRICULATION SCHOOL,THURVARANKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21093",
            "district": "29",
            "Block": "176",
            "label": "SRI HAYAAGREEVAR VIDHYALAYA (CBSE),M.KALLUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21279",
            "district": "29",
            "Block": "176",
            "label": "S.R VIDHYALAYA MATRICULATION HR. SEC. SCHOOL, AYYANARPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21509",
            "district": "29",
            "Block": "176",
            "label": "ADWHS,PALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21961",
            "district": "29",
            "Block": "176",
            "label": "CHIRUMALAR HIGHER SECONDARY SCHOOL,VELLAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22185",
            "district": "29",
            "Block": "176",
            "label": "DMI ST.JOSEPH GLOBAL CBSE SCHOOL,YAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22525",
            "district": "29",
            "Block": "176",
            "label": "GHS,PALUVANCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22526",
            "district": "29",
            "Block": "176",
            "label": "SETHU MATRICULATION HIGHER SECONDARY SCHOOL, THUVARANKURICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22985",
            "district": "29",
            "Block": "176",
            "label": "GHS Muthalvarpatty",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23091",
            "district": "29",
            "Block": "176",
            "label": "MONTFORT SCHOOL (CBSE) PALAKURICHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23092",
            "district": "29",
            "Block": "176",
            "label": "PONNAR SANKAR KALVIYALYA WORLD SHOOL (CBSE) IRATTIYAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23093",
            "district": "29",
            "Block": "176",
            "label": "ALIF MATRICULATION SCHOOL THUVARANKURICHY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "177": [
        {
            "key": "3799",
            "district": "13",
            "Block": "177",
            "label": "SUNFLOWER MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5924",
            "district": "13",
            "Block": "177",
            "label": "PUMS KOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6211",
            "district": "13",
            "Block": "177",
            "label": "PUMS KUMARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6409",
            "district": "13",
            "Block": "177",
            "label": "SHRI VENKATESWARA VID. MHSS MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6556",
            "district": "13",
            "Block": "177",
            "label": "PUMS MADHAMPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6569",
            "district": "13",
            "Block": "177",
            "label": "PUMS OLAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20844",
            "district": "13",
            "Block": "177",
            "label": "PUMS AMMANKOILPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20845",
            "district": "13",
            "Block": "177",
            "label": "GHSS ANANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20846",
            "district": "13",
            "Block": "177",
            "label": "GHSS ATHIGANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20847",
            "district": "13",
            "Block": "177",
            "label": "GHSS GERIGEPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20848",
            "district": "13",
            "Block": "177",
            "label": "GHS GUNDEPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20849",
            "district": "13",
            "Block": "177",
            "label": "GHS K.ETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20850",
            "district": "13",
            "Block": "177",
            "label": "GHS KALARPATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20851",
            "district": "13",
            "Block": "177",
            "label": "PUMS KANJANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20852",
            "district": "13",
            "Block": "177",
            "label": "GHSS KANNANDAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20853",
            "district": "13",
            "Block": "177",
            "label": "PUMS KARUVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20854",
            "district": "13",
            "Block": "177",
            "label": "GHS KODAMANDAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20855",
            "district": "13",
            "Block": "177",
            "label": "GHS KODIPATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20856",
            "district": "13",
            "Block": "177",
            "label": "GHSS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20857",
            "district": "13",
            "Block": "177",
            "label": "GHS-GIRLS  KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20858",
            "district": "13",
            "Block": "177",
            "label": "GHSS M.NADUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20859",
            "district": "13",
            "Block": "177",
            "label": "PUMS M.PALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20860",
            "district": "13",
            "Block": "177",
            "label": "GHSS MADARAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20861",
            "district": "13",
            "Block": "177",
            "label": "PUMS MALAYANDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20862",
            "district": "13",
            "Block": "177",
            "label": "GHSS MATHUR-BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20863",
            "district": "13",
            "Block": "177",
            "label": "GHSS-GIRLS  MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20864",
            "district": "13",
            "Block": "177",
            "label": "KALAIMAGAL KALALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20865",
            "district": "13",
            "Block": "177",
            "label": "BHARANI INTERNATIONAL MODERN SCHOOL KANNANDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20866",
            "district": "13",
            "Block": "177",
            "label": "PUMS MUTHAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20867",
            "district": "13",
            "Block": "177",
            "label": "GHS ODDAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20868",
            "district": "13",
            "Block": "177",
            "label": "GHS RAMAKRISHNAMPATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20869",
            "district": "13",
            "Block": "177",
            "label": "PUMS RANGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20870",
            "district": "13",
            "Block": "177",
            "label": "GHSS SAMALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20871",
            "district": "13",
            "Block": "177",
            "label": "GHS SOOLAGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20872",
            "district": "13",
            "Block": "177",
            "label": "GHS VALIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20873",
            "district": "13",
            "Block": "177",
            "label": "PUMS VEERACHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20874",
            "district": "13",
            "Block": "177",
            "label": "PUMS ANDERIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20875",
            "district": "13",
            "Block": "177",
            "label": "GADWHSS ATHIPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20876",
            "district": "13",
            "Block": "177",
            "label": "GHS PASANTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20877",
            "district": "13",
            "Block": "177",
            "label": "GHS VELAVALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21804",
            "district": "13",
            "Block": "177",
            "label": "SRI VETRI VIKAS MATRIC. HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22303",
            "district": "13",
            "Block": "177",
            "label": "SRI SWAMY VIVEKANANDHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22680",
            "district": "13",
            "Block": "177",
            "label": "GUNA MATRIC HR SEC SCHOOL, GENDIGAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22681",
            "district": "13",
            "Block": "177",
            "label": "GHS SALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22682",
            "district": "13",
            "Block": "177",
            "label": "UDHAYAAM MATRIC HR SEC SCHOOL, SIVAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23062",
            "district": "13",
            "Block": "177",
            "label": "GURUKULAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23875",
            "district": "13",
            "Block": "177",
            "label": "GHSS KUNNATHUR",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "178": [
        {
            "key": "5269",
            "district": "15",
            "Block": "178",
            "label": "VIVEKANANDHA MATRIC SCHOOL MANALMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5335",
            "district": "15",
            "Block": "178",
            "label": "TELC MIDDLE SCHOOL,MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5438",
            "district": "15",
            "Block": "178",
            "label": "GEETHA MATRICULATION SCHOOL CHENJI KALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5592",
            "district": "15",
            "Block": "178",
            "label": "PUMS KESINGAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5605",
            "district": "15",
            "Block": "178",
            "label": "PUMS VAKKARAMARY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5607",
            "district": "15",
            "Block": "178",
            "label": "PUMS NIDUR NEIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5693",
            "district": "15",
            "Block": "178",
            "label": "MOTHER TERESA MATRICULATION SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5720",
            "district": "15",
            "Block": "178",
            "label": "KALAIVANI MATRICULATION SCHOOL, KADALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5890",
            "district": "15",
            "Block": "178",
            "label": "PUMS  MULAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5906",
            "district": "15",
            "Block": "178",
            "label": "AMS SERUTHIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6048",
            "district": "15",
            "Block": "178",
            "label": "VAITHEESWARA MATRICULATION HIGH SCHOOL  ATHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6056",
            "district": "15",
            "Block": "178",
            "label": "P.U.M.S SETHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6073",
            "district": "15",
            "Block": "178",
            "label": "AMS THIRUCHITRAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6122",
            "district": "15",
            "Block": "178",
            "label": "PUMS - MANAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6317",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   MANNAMPANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6506",
            "district": "15",
            "Block": "178",
            "label": "KRISHNA AIDED MIDDLE SCHOOL,ARUVAPPADI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6529",
            "district": "15",
            "Block": "178",
            "label": "PUMS PALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6626",
            "district": "15",
            "Block": "178",
            "label": "AMS SITHARKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6673",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL CHOZHAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15817",
            "district": "15",
            "Block": "178",
            "label": "UMA GIRLS HIGH SCHOOL THIRUCHITRAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15818",
            "district": "15",
            "Block": "178",
            "label": "GHS KADALANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15819",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL MANALMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15820",
            "district": "15",
            "Block": "178",
            "label": "PUMS- ELUPPAPATTU.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15821",
            "district": "15",
            "Block": "178",
            "label": "GHSS MANALMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15822",
            "district": "15",
            "Block": "178",
            "label": "GHSS (G) MANALMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15823",
            "district": "15",
            "Block": "178",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL MANALMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15824",
            "district": "15",
            "Block": "178",
            "label": "GHS ATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15825",
            "district": "15",
            "Block": "178",
            "label": "PUMS, KALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15826",
            "district": "15",
            "Block": "178",
            "label": "SRI KAMATCHI AMS KANNIYANATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15827",
            "district": "15",
            "Block": "178",
            "label": "GHSS KORUKKAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15828",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15829",
            "district": "15",
            "Block": "178",
            "label": "GHS THIRUVALAPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15830",
            "district": "15",
            "Block": "178",
            "label": "GHS SITHAMALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15831",
            "district": "15",
            "Block": "178",
            "label": "AIDED MIDDLE SCHOOL, ELANTHOPE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15832",
            "district": "15",
            "Block": "178",
            "label": "GHS VILLIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15833",
            "district": "15",
            "Block": "178",
            "label": "GHS MELANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15834",
            "district": "15",
            "Block": "178",
            "label": "GOVT GIRLS HR SEC SCHOOL NIDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15835",
            "district": "15",
            "Block": "178",
            "label": "NASRUL MUSLIMEEN (MATRIC) HR SEC SCHOOL NIDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15836",
            "district": "15",
            "Block": "178",
            "label": "AL HAJEE MATRIC HIGH   SCHOOL NIDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15837",
            "district": "15",
            "Block": "178",
            "label": "GHS NIDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15838",
            "district": "15",
            "Block": "178",
            "label": "GHSS ANANTHATHANDAVAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15839",
            "district": "15",
            "Block": "178",
            "label": "SRI KALYANEESWARI AIDED MIDDLE SCHOOL MOZHAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15840",
            "district": "15",
            "Block": "178",
            "label": "PUMS THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15841",
            "district": "15",
            "Block": "178",
            "label": "GHSS THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15842",
            "district": "15",
            "Block": "178",
            "label": "RAJ MATRIC HR SEC SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15843",
            "district": "15",
            "Block": "178",
            "label": "Thiruarasen Middle School, Kazhukanimuttam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15844",
            "district": "15",
            "Block": "178",
            "label": "GHS MAPPADUGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15845",
            "district": "15",
            "Block": "178",
            "label": "AGRA MATRIC SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15846",
            "district": "15",
            "Block": "178",
            "label": "AIDED MIDDLE SCHOOL, MALLIYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15847",
            "district": "15",
            "Block": "178",
            "label": "MMRA GHS MOOVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15848",
            "district": "15",
            "Block": "178",
            "label": "MEKANA INTERNATIONAL MATRICULATION Hr.Sec MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15849",
            "district": "15",
            "Block": "178",
            "label": "EVEREST KENBRIDGE CBSE SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15850",
            "district": "15",
            "Block": "178",
            "label": "SRI GURU GNANASAMBANDAR HSS, DHARUMAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15851",
            "district": "15",
            "Block": "178",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   MARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15852",
            "district": "15",
            "Block": "178",
            "label": "MUNICIPAL HIGH SCHOOL, THIRUINDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15853",
            "district": "15",
            "Block": "178",
            "label": "GURUMOORTHY MIDDLE SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15854",
            "district": "15",
            "Block": "178",
            "label": "SRI KANCHI SANKARA VIDYALAYA MATRICULATION  HR.SEC  SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15855",
            "district": "15",
            "Block": "178",
            "label": "TGN MPL HR SEC SCHOOL. MAYILADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15856",
            "district": "15",
            "Block": "178",
            "label": "GOVT G HSS MAYILADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15857",
            "district": "15",
            "Block": "178",
            "label": "SILVER JUBLEE MAT HS SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15858",
            "district": "15",
            "Block": "178",
            "label": "ARIVALAYAM MATRICULATION HIGHER SECONDARY SCHOOL SCHOOL, MYD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15859",
            "district": "15",
            "Block": "178",
            "label": "ROTARY CLUB MATRICULATION HIGHER SECONDARY SCHOOL MAYILADUTHUARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15860",
            "district": "15",
            "Block": "178",
            "label": "DBTR HSS MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15861",
            "district": "15",
            "Block": "178",
            "label": "AZAD GIRLS MAT HR SEC SCHOOL. MAYILADUTHUIRAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15862",
            "district": "15",
            "Block": "178",
            "label": "MAYURA MAT HIGH SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15863",
            "district": "15",
            "Block": "178",
            "label": "KITTAPPA MHSS MAYILADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15864",
            "district": "15",
            "Block": "178",
            "label": "ARC.KAMATCHI.MATRIC.HR.SEC.SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15865",
            "district": "15",
            "Block": "178",
            "label": "ST.PAUL'S GIRLS HIGHER SECONDARY SCHOOL, MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15866",
            "district": "15",
            "Block": "178",
            "label": "ST ANTONYS HIGH SCHOOL, KORNAD",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15867",
            "district": "15",
            "Block": "178",
            "label": "RAJ MATRIC HR SEC  SCHOOL, KORANADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15868",
            "district": "15",
            "Block": "178",
            "label": "BALA SARASWATHI MAT SCHOOL, KORNAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15869",
            "district": "15",
            "Block": "178",
            "label": "GURUGNANASAMBANDAR MISSION MATRIC HIGHER SECONDARY SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15870",
            "district": "15",
            "Block": "178",
            "label": "ST XAVIOR HIGH SCHOOL. MAYILADUTHURAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15871",
            "district": "15",
            "Block": "178",
            "label": "MNA MS MAYILADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15872",
            "district": "15",
            "Block": "178",
            "label": "KALAIMAHAL MATRICULATION  HR SEC SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20905",
            "district": "15",
            "Block": "178",
            "label": "GOOD SAMARITAN MATRICULATION HIGHER SECONDARY SCHOOL MAYILADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20974",
            "district": "15",
            "Block": "178",
            "label": "IDEAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21131",
            "district": "15",
            "Block": "178",
            "label": "PUMS PULAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22217",
            "district": "15",
            "Block": "178",
            "label": "KAMALALAYAM N&P,PUTHAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22566",
            "district": "15",
            "Block": "178",
            "label": "GOVERNMENT HIGH SCHOOL KEEZHATHUKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22567",
            "district": "15",
            "Block": "178",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Pallavarayanpettai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22568",
            "district": "15",
            "Block": "178",
            "label": "GOLDEN PUBLIC CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22775",
            "district": "15",
            "Block": "178",
            "label": "VEETA VARSITY CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22776",
            "district": "15",
            "Block": "178",
            "label": "ARK GLOBAL  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22865",
            "district": "15",
            "Block": "178",
            "label": "GOVERNMENT HIGH SCHOOL THALANCHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23876",
            "district": "15",
            "Block": "178",
            "label": "GHSS KORUKKAI",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23877",
            "district": "15",
            "Block": "178",
            "label": "MAYILADUTHURAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "179": [
        {
            "key": "4207",
            "district": "22",
            "Block": "179",
            "label": "SRI RAGHAVENDRA MATRIC HR SEC SCHOOL MECHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6511",
            "district": "22",
            "Block": "179",
            "label": "SRI SARASWATHI  VIDHYALAYA MATRIC HR SEC SCHOOL,KUTTAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6607",
            "district": "22",
            "Block": "179",
            "label": "MEENAMPARK MATRIC HR. SEC. SCHOOL,MECHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11515",
            "district": "22",
            "Block": "179",
            "label": "PUMS,VALATHASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11516",
            "district": "22",
            "Block": "179",
            "label": "GHSS CHINTHAMANIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11517",
            "district": "22",
            "Block": "179",
            "label": "GHSS M.N.PATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11518",
            "district": "22",
            "Block": "179",
            "label": "G.H.S ARANGANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23684",
            "district": "22",
            "Block": "179",
            "label": "RP matric higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11519",
            "district": "22",
            "Block": "179",
            "label": "PKM GHSS MADHANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11520",
            "district": "22",
            "Block": "179",
            "label": "SRI SARASWATHI VIDHYALAYA HR. SEC. SCHOOL, KUTTAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11521",
            "district": "22",
            "Block": "179",
            "label": "SRI VIDHYA BHARATHI MATRIC HR. SEC. SCHOOL, MALAIADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11522",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, MALLAPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11523",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, VIRUTHASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11524",
            "district": "22",
            "Block": "179",
            "label": "GHSS KULLAMUDAIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11525",
            "district": "22",
            "Block": "179",
            "label": "PUMS,AMARATHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11526",
            "district": "22",
            "Block": "179",
            "label": "JEMS INTERNATIONAL MATRIC HR. SEC. SCHOOL,SAVADIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11527",
            "district": "22",
            "Block": "179",
            "label": "GHS BUKKAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11528",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, AMARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11529",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, THETHIGIRIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11530",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, KATCHARAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11531",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, KARUMBUSALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11532",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, ERUMAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11533",
            "district": "22",
            "Block": "179",
            "label": "GHSS VELLAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11534",
            "district": "22",
            "Block": "179",
            "label": "WESTERN GATE MAT. SCHOOL, UPPARAPPATTI KATTU VALAVU, METTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11535",
            "district": "22",
            "Block": "179",
            "label": "SONASUN HI TECH MATRIC HR. SEC. SCHOOL,VELLAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11536",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, PALLAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11537",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, THIPPARATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11538",
            "district": "22",
            "Block": "179",
            "label": "GHS MALLIKUNDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11539",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, M.PALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11540",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, VANNIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11541",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, NARIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11542",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, KEERAIKARANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11543",
            "district": "22",
            "Block": "179",
            "label": "GHS KOONANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11544",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, PARAKKALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11545",
            "district": "22",
            "Block": "179",
            "label": "GBHSS, MECHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11546",
            "district": "22",
            "Block": "179",
            "label": "GGHSS MECHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11547",
            "district": "22",
            "Block": "179",
            "label": "PUM SCHOOL, THONGALANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21141",
            "district": "22",
            "Block": "179",
            "label": "SRI RAAM VIDYALAYA MATRIC HR. SEC. SCHOOL, POTTANERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21646",
            "district": "22",
            "Block": "179",
            "label": "SRI RAAM VIDYALAYA PUBLIC SCHOOL, POTTANERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23544",
            "district": "22",
            "Block": "179",
            "label": "MEENAMPARK VIDHYALAYA SCHOOL, PERIYASATHAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23544",
            "district": "22",
            "Block": "179",
            "label": "MEENAMPARK VIDHYALAYA SCHOOL, PERIYASATHAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "180": [
        {
            "key": "157",
            "district": "24",
            "Block": "180",
            "label": "AMALA ANNAI HIGH SCHOOL, RENGASAMDURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "189",
            "district": "24",
            "Block": "180",
            "label": "VEERAPPA HINDU MIDDLE SCHOOL,MALAYANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "239",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGH SCHOOL, PERIYA KOVILANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "240",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NADUVAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "241",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SENDAMARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "242",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THIRUMALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "244",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VELLALANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "247",
            "district": "24",
            "Block": "180",
            "label": "HINDU HARIJAN MIDDLE SCHOOL, KURUKKALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "248",
            "district": "24",
            "Block": "180",
            "label": "HINDU NADAR MIDDLE SCHOOL, KADAYALURUTTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "250",
            "district": "24",
            "Block": "180",
            "label": "MADATHIAMMAL MIDDLE SCHOOL, OOTHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "251",
            "district": "24",
            "Block": "180",
            "label": "PRAKASH MATRICULATION SCHOOL, PANAVADALICHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "252",
            "district": "24",
            "Block": "180",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINNA KOVILANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "253",
            "district": "24",
            "Block": "180",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KO.MARUTHAPPAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "254",
            "district": "24",
            "Block": "180",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KULASEKARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "256",
            "district": "24",
            "Block": "180",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SHUNMUGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "257",
            "district": "24",
            "Block": "180",
            "label": "RAMAR MIDDLE SCHOOL, VELLAPPANADAROOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "258",
            "district": "24",
            "Block": "180",
            "label": "RC MIDDLE SCHOOL, KEELANEELITHANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "260",
            "district": "24",
            "Block": "180",
            "label": "SHRI BHARATH KANNA MATRICULATION SCHOOL. PERIASAMIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "261",
            "district": "24",
            "Block": "180",
            "label": "ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL, SENDAMARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "262",
            "district": "24",
            "Block": "180",
            "label": "SUCCESS MATRICULATION HIGHER SECONDARY SCHOOL, THIRUMALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21417",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGH SCHOOL, PANDIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21418",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGH SCHOOL, VENDRILINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21543",
            "district": "24",
            "Block": "180",
            "label": "ST PETER HIGH SCHOOL SENDAMARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22648",
            "district": "24",
            "Block": "180",
            "label": "ST. ROSSELLOS MATRIC SCHOOL, AYALPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22649",
            "district": "24",
            "Block": "180",
            "label": "GOVERNMENT HIGH SCHOOL, MELANEELITHANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22729",
            "district": "24",
            "Block": "180",
            "label": "SRI SWARNA VIDYASHRAM - SHUNMUGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "181": [
        {
            "key": "5102",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOVILPURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5831",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KADALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5889",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6112",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELMALAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6528",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EYAKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6592",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THORAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10007",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10008",
            "district": "37",
            "Block": "181",
            "label": "RAJA DESINGH MATRIC HIGH  SCHOOL  AVALURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10009",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(G) AVALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10010",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(B) AVALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10011",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   AATHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10012",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDRY SCHOOL DEVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10013",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARADIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10014",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ETHAPPATU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10015",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL EYYIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10016",
            "district": "37",
            "Block": "181",
            "label": "SRI PARAMESWAR M HIGH SCHOOL GENGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10017",
            "district": "37",
            "Block": "181",
            "label": "AIDED MIDDLE SCHOOL  KUDUVAMPOONDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10018",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KALATHAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10019",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL KAPLAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10020",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VALATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10021",
            "district": "37",
            "Block": "181",
            "label": "OUTREACH MATRIC  HIGH SCHOOL VALATHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10022",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KODUKANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10023",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  KOTTAPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10024",
            "district": "37",
            "Block": "181",
            "label": "C.S.I BISHOP SAM PONNIAH  HIGH SCHOOL GNANOTHAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10025",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MANANTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10026",
            "district": "37",
            "Block": "181",
            "label": "GANGA VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, MELMALAYANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10027",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MELMALAYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10028",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10029",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  MELSAVALAMPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10030",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  THEPPIRAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10031",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  METTUVAILAMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10032",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  NARANAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10033",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  NOCHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10034",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  PERIYANOLAMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10035",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VADUKAPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10036",
            "district": "37",
            "Block": "181",
            "label": "SARADHA VIDYALAYA MATRICULATION SCHOOL.VADUGANPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10037",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PERUVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10038",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  PERUVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10039",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGH SCHOOL  SATHAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10040",
            "district": "37",
            "Block": "181",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  SEVALAPURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10041",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SIRUTHALAIPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10042",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THAZHANGUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10043",
            "district": "37",
            "Block": "181",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THENPALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10044",
            "district": "37",
            "Block": "181",
            "label": "SACRED HEART HIGH SCHOOL  THURINCHIPOONDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22426",
            "district": "37",
            "Block": "181",
            "label": "HOLY ANGEL MATRICULATION SCHOOL KUDUVAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23878",
            "district": "37",
            "Block": "181",
            "label": "NETHAJI SUBHASH CHANDRA BOSE AVASIYA VIDYALAYA DEVANUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "182": [
        {
            "key": "1800",
            "district": "11",
            "Block": "182",
            "label": "KIDS OXFORD MATRIC HR SEC SCHOOL KUZHITHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1951",
            "district": "11",
            "Block": "182",
            "label": "THE WINNER'S RESIDENTIAL MATRIC HIGHER SECONDARY SCHOOL, KILATHOOR, KALIYAL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1998",
            "district": "11",
            "Block": "182",
            "label": "CHRISTHURAJA MA H S S MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2348",
            "district": "11",
            "Block": "182",
            "label": "NATIONAL MAT.S. ARUMANAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2391",
            "district": "11",
            "Block": "182",
            "label": "DEVAKALA NILAYAM MATRIC HSS-MARUTHANCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2479",
            "district": "11",
            "Block": "182",
            "label": "MOUNT CARMEL MATRIC HIGHER SECONDARY SCHOOL, MUZHUCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2625",
            "district": "11",
            "Block": "182",
            "label": "Abraham James Mem Matric HSS Gnaramvilai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3685",
            "district": "11",
            "Block": "182",
            "label": "GMS-PERAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3708",
            "district": "11",
            "Block": "182",
            "label": "GMS (GIRLS) ARUMANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3715",
            "district": "11",
            "Block": "182",
            "label": "G M S (GIRLS) KUZHITHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3908",
            "district": "11",
            "Block": "182",
            "label": "FRIENDS MATRICULATION HIGHER SECONDARY SCHOOL PANAMKALAI, KALIAKKAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4091",
            "district": "11",
            "Block": "182",
            "label": "GMS-VANNIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4183",
            "district": "11",
            "Block": "182",
            "label": "GOVT. L M  M S ARUMANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4650",
            "district": "11",
            "Block": "182",
            "label": "ST. GABRIALS R.C. MIDDLE SCHOOL, THICKURICHY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4795",
            "district": "11",
            "Block": "182",
            "label": "ST.JOSEPH'S M S.C.M.S KULAPPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4880",
            "district": "11",
            "Block": "182",
            "label": "GOVT M V MIDDLE S MARTHANDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4990",
            "district": "11",
            "Block": "182",
            "label": "R C M S MELPALAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5512",
            "district": "11",
            "Block": "182",
            "label": "GOOD SHEPHERD.MAT.HSS-MARTHANDAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5953",
            "district": "11",
            "Block": "182",
            "label": "SHREE VIDHYADHIRAJA VIDYA VIKAS MATRIC SCHOOL KUZHITHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6388",
            "district": "11",
            "Block": "182",
            "label": "CHRIST THE KING MATRIC HSS, KANNUMAMOODU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6524",
            "district": "11",
            "Block": "182",
            "label": "GMS PURAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20287",
            "district": "11",
            "Block": "182",
            "label": "GHS CHEMMANKALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20288",
            "district": "11",
            "Block": "182",
            "label": "GHS MELPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20289",
            "district": "11",
            "Block": "182",
            "label": "GHSS THIKKURICHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20290",
            "district": "11",
            "Block": "182",
            "label": "SACRED HEART H S PACODE",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20291",
            "district": "11",
            "Block": "182",
            "label": "ABRAHAM MEMORIAL .HSS-MARUNTHENCODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20292",
            "district": "11",
            "Block": "182",
            "label": "ROYAL MAT H S S  PACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20293",
            "district": "11",
            "Block": "182",
            "label": "SREE KRISHNA VIDYALAYA MATRIC HIGH SCHOOL, ALAMPADI, CHOOZHIKKONAM, MARUTHANCODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20294",
            "district": "11",
            "Block": "182",
            "label": "SACRED HEART CONVENT MATRIC. HR.SEC.SCHOOL ,KAZHUVANTHITTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20295",
            "district": "11",
            "Block": "182",
            "label": "Holy Angels' Matriculation School, Melpuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20296",
            "district": "11",
            "Block": "182",
            "label": "G H S  KALIAKKAVILAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20297",
            "district": "11",
            "Block": "182",
            "label": "G H S MECODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20298",
            "district": "11",
            "Block": "182",
            "label": "T. C. K. H. S. S PADANTHALUMOODU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20299",
            "district": "11",
            "Block": "182",
            "label": "SACR H MAT HSS PADANTHALUMOODU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20300",
            "district": "11",
            "Block": "182",
            "label": "ST JOSEPH'S HSS THIRUTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20301",
            "district": "11",
            "Block": "182",
            "label": "G H S S VILAVANCODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20302",
            "district": "11",
            "Block": "182",
            "label": "G H S S (BOYS)MARTHANDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20303",
            "district": "11",
            "Block": "182",
            "label": "G H S S GIRLS MARTHANDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20304",
            "district": "11",
            "Block": "182",
            "label": "L.M.S HIGHER SECONDARY SCHOOL FOR BOYS,MARTHANDAM.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20305",
            "district": "11",
            "Block": "182",
            "label": "L M S GIRLS H S S MARTHANDAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20306",
            "district": "11",
            "Block": "182",
            "label": "MJKM MSC.HR.SEC. SCHOOL, CHRISTURAJAPURAM -MARTHANDAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20307",
            "district": "11",
            "Block": "182",
            "label": "GHSS-PALUKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20308",
            "district": "11",
            "Block": "182",
            "label": "ST JAMES H S PARAKUNNU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20309",
            "district": "11",
            "Block": "182",
            "label": "ST. FRANCIS NURSERY AND PRIMARY SCHOOL, MEZHACODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20310",
            "district": "11",
            "Block": "182",
            "label": "B.R.MEMORIAL MATRICULATION SCHOOL, PALUKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20311",
            "district": "11",
            "Block": "182",
            "label": "G LMMS MALAICODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20312",
            "district": "11",
            "Block": "182",
            "label": "M NM HS EDAICODE",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20313",
            "district": "11",
            "Block": "182",
            "label": "ST MARYS HSS MELPALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20314",
            "district": "11",
            "Block": "182",
            "label": "AIDED HIGHER SECONDARY SCHOOL, MOTTAKALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20315",
            "district": "11",
            "Block": "182",
            "label": "PRIVATE HSS KALLUPALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20316",
            "district": "11",
            "Block": "182",
            "label": "HF HSS MUKKUTTUKAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20317",
            "district": "11",
            "Block": "182",
            "label": "SMILE ST ANTONY MAT. S KURACODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20318",
            "district": "11",
            "Block": "182",
            "label": "GBFM HS DEVICODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20319",
            "district": "11",
            "Block": "182",
            "label": "NEHRU MEMMORIAL .M.S-KARODE,ARUMANAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20320",
            "district": "11",
            "Block": "182",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  KADAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20321",
            "district": "11",
            "Block": "182",
            "label": "GMS-CHITTAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20322",
            "district": "11",
            "Block": "182",
            "label": "ADI  DRA TRI RES HSS PATHUKANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20323",
            "district": "11",
            "Block": "182",
            "label": "SACRED HEART MIDDLE SCHOOL ,KADAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20324",
            "district": "11",
            "Block": "182",
            "label": "HOLY FAMILY H S ARUKANI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20325",
            "district": "11",
            "Block": "182",
            "label": "ST.THOMAS HIGH SCHOOL-NETTA",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20326",
            "district": "11",
            "Block": "182",
            "label": "ST MARYS HSS KALIYAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20327",
            "district": "11",
            "Block": "182",
            "label": "ST.MARY'S CENTRAL SCHOOL .KALIYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20328",
            "district": "11",
            "Block": "182",
            "label": "G S V M S MALACODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20329",
            "district": "11",
            "Block": "182",
            "label": "PPM H S S ANDUCODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20330",
            "district": "11",
            "Block": "182",
            "label": "CHRIST INTERNATIONAL SCHOOL-MALAMARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20331",
            "district": "11",
            "Block": "182",
            "label": "GHS- VELLAMCODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20332",
            "district": "11",
            "Block": "182",
            "label": "GHSS-ARUMANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20333",
            "district": "11",
            "Block": "182",
            "label": "HSS-ARUMANAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20334",
            "district": "11",
            "Block": "182",
            "label": "KAMARAJ MEMORIAL HSS-PANTHALUVILAI, MANJALUMOODU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20335",
            "district": "11",
            "Block": "182",
            "label": "NM VIDYA KENDRA (CBSE)- CHITHARAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20336",
            "district": "11",
            "Block": "182",
            "label": "ARUNODAYA VIDHAYALAYA MATRIC SCHOOL-MANJALUMOODU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20337",
            "district": "11",
            "Block": "182",
            "label": "GHS- PULIYOORSALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21624",
            "district": "11",
            "Block": "182",
            "label": "N.V.K.S.VIDYALAYA VETTUVENNI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21815",
            "district": "11",
            "Block": "182",
            "label": "HOLY TRINITY INTERNATIONAL SCHOOL-MELPALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22664",
            "district": "11",
            "Block": "182",
            "label": "MAR IVANIOS CBSE SCHOOL,KUZHITHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22665",
            "district": "11",
            "Block": "182",
            "label": "GOVERNMENT HIGH SCHOOL,UTHIRAMCODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23225",
            "district": "11",
            "Block": "182",
            "label": "CHRIST THE KING SCHOOL- KANNUMAMOODU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "183": [
        {
            "key": "527",
            "district": "14",
            "Block": "183",
            "label": "SRI SUNDARESWARA VIDYA SALA MATRIC HR SEC SCHOOL, AATUKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1446",
            "district": "14",
            "Block": "183",
            "label": "SUBRAMANIA BHARATHI MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3138",
            "district": "14",
            "Block": "183",
            "label": "LAKSHMI MATRIC HR SEC SCHOOL, THIRUVATHAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3429",
            "district": "14",
            "Block": "183",
            "label": "SRI BALAN EVERGREEN MATRIC HIGH SCHOOL, KEELAVALAVU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3488",
            "district": "14",
            "Block": "183",
            "label": "VETRIVEL VIDYASALA MATRIC HIGH SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4287",
            "district": "14",
            "Block": "183",
            "label": "SHRI RAM MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4946",
            "district": "14",
            "Block": "183",
            "label": "SARASWATHI VIDHAYALAYA MATRIC HIGH SCHOOL, M SUBRAMANIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6515",
            "district": "14",
            "Block": "183",
            "label": "ASIAN MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18104",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KARAIYIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18105",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, E.MALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18106",
            "district": "14",
            "Block": "183",
            "label": "GOVT HIGH SCHOOL, PAPPAKUDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18107",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELAVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18108",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, SARUGUVALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18109",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VADAKKUVALAIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18110",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAVINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18111",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PATHINETTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18112",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, THERKKUTHERU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18113",
            "district": "14",
            "Block": "183",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, CHINNASURAKKUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18114",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, A.VALLALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18115",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KIDARIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18116",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18117",
            "district": "14",
            "Block": "183",
            "label": "LATHA MATHAVAN MATRIC HR SEC SCHOOL, KIDARIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18118",
            "district": "14",
            "Block": "183",
            "label": "ARULMIGU SUNDARARASA HIGH SCHOOL, ALAGARKOIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18119",
            "district": "14",
            "Block": "183",
            "label": "MAHATMA MONTESSORI MATRIC HIGHER SECONDARY SCHOOL, ALAGARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18120",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18121",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARITTAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18122",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, S.KALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18123",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLARIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18124",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THERKAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18125",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SUNNAMBOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18126",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VEPPADAPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18127",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, THIRUVATHAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18128",
            "district": "14",
            "Block": "183",
            "label": "CSI MS KOTTAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18129",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, KOTTANATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18130",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PALAIYURPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18131",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VANNAMPARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18132",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, THANIAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18133",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, VELLALURE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18134",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, URANGANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18135",
            "district": "14",
            "Block": "183",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURICHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18136",
            "district": "14",
            "Block": "183",
            "label": "GOVT HIGH SCHOOL, SEMMANIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18137",
            "district": "14",
            "Block": "183",
            "label": "MILTON MATRIC HR SEC SCHOOL,MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18138",
            "district": "14",
            "Block": "183",
            "label": "MEENAKSHI HIGH SCHOOL, NONDIKOVILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18139",
            "district": "14",
            "Block": "183",
            "label": "GOVT HR SEC SCHOOL, MELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18140",
            "district": "14",
            "Block": "183",
            "label": "AL-AMEEN URDHU TAMIL HIGH SCHOOL, MELUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18141",
            "district": "14",
            "Block": "183",
            "label": "MUNICIPAL HIGH SCHOOL, MELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18142",
            "district": "14",
            "Block": "183",
            "label": "TAMILARASI MIDDLE SCHOOL, MELUR.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18143",
            "district": "14",
            "Block": "183",
            "label": "GOVT GIRLS HR SEC SCHOOL, MELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18144",
            "district": "14",
            "Block": "183",
            "label": "SAKTHI VIDHYALAYA MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18145",
            "district": "14",
            "Block": "183",
            "label": "ABUL KALAM AZAD M.S.  MELUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18146",
            "district": "14",
            "Block": "183",
            "label": "SYDAI SA DURAISAMY MATRIC.HR SEC SCHOOL,MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18147",
            "district": "14",
            "Block": "183",
            "label": "JAYARAJ ANNAPACKIAM CSI HIGH SCHOOL, MELUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18148",
            "district": "14",
            "Block": "183",
            "label": "R.C.MIDDLE SCHOOL, MELUR.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21286",
            "district": "14",
            "Block": "183",
            "label": "R.V.  MATRICULATION HIGHER SECONDARY SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21336",
            "district": "14",
            "Block": "183",
            "label": "SRI LAKSHMI NARAYANA VID CBSE S, A.VALAYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22247",
            "district": "14",
            "Block": "183",
            "label": "ARRAHMAAN INTERNATIONAL SCHOOL, CHINNASOORAKUNDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22248",
            "district": "14",
            "Block": "183",
            "label": "JAZZ PUBLIC MATRIC HR SEC SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22605",
            "district": "14",
            "Block": "183",
            "label": "MAHATMA MONTESSORI SCHOOL, ALAGARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22774",
            "district": "14",
            "Block": "183",
            "label": "EDUSTAR MODEL SCHOOL, MELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22964",
            "district": "14",
            "Block": "183",
            "label": "GOVT HIGH SCHOOL, PULIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23318",
            "district": "14",
            "Block": "183",
            "label": "GOVT HIGH SCHOOL, KEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "184": [
        {
            "key": "393",
            "district": "33",
            "Block": "184",
            "label": "BSS SWAMY PRANAVANANDA VIDHYA MANDHIR,MINJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "475",
            "district": "33",
            "Block": "184",
            "label": "BHARATH MATRIC HIGHER SECONDARY SCHOOL,KOKKUMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "497",
            "district": "33",
            "Block": "184",
            "label": "MODERN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "556",
            "district": "33",
            "Block": "184",
            "label": "ST JAMES MAT SCH, VENPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "584",
            "district": "33",
            "Block": "184",
            "label": "NATIONAL MATRIC. HR.SEC.SCHOOL,PULICAT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "612",
            "district": "33",
            "Block": "184",
            "label": "KAMARAJAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "665",
            "district": "33",
            "Block": "184",
            "label": "PK MAT SCH,PUNGAMBEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "714",
            "district": "33",
            "Block": "184",
            "label": "ST. JOHN'S MATRICULATION SCHOOL,PONNERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "746",
            "district": "33",
            "Block": "184",
            "label": "EMMANUEL MATRIC HIGHER SECONDARY SCHOOL,ATHIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "769",
            "district": "33",
            "Block": "184",
            "label": "VIVEKANANDA MATRICULATION  SCHOOL VALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "964",
            "district": "33",
            "Block": "184",
            "label": "VENKATESWARA VIDYALAYA  MS,NANDIAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1131",
            "district": "33",
            "Block": "184",
            "label": "PUMS, NEITHAVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1418",
            "district": "33",
            "Block": "184",
            "label": "S.DYANANTHA MS,POLATCHIAMMANKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1429",
            "district": "33",
            "Block": "184",
            "label": "ADARSH MAT HR.SEC, THIRUVAYARPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1585",
            "district": "33",
            "Block": "184",
            "label": "ISRAEL MISSION MAT HR SEC SCHOOL, MINJUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1748",
            "district": "33",
            "Block": "184",
            "label": "Dr.V.GENGUSWAMY NAIDU MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1884",
            "district": "33",
            "Block": "184",
            "label": "ST. MARY'S MAT SCHOOL, MINJUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2057",
            "district": "33",
            "Block": "184",
            "label": "Smt.Gangadevi Bajranglal Chokhani Vivekananda Vidyalaya Matriculation Hr. Sec. School, Minjur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2678",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ENNORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2747",
            "district": "33",
            "Block": "184",
            "label": "G.R.T.M VIVEK VIDHAYALAYA, MALALI NEWTOWN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3013",
            "district": "33",
            "Block": "184",
            "label": "PUMS, ARANGANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3061",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ALADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3155",
            "district": "33",
            "Block": "184",
            "label": "PUMS, THATHTHAMANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3180",
            "district": "33",
            "Block": "184",
            "label": "PUMS,VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3413",
            "district": "33",
            "Block": "184",
            "label": "SARASWATHI MS, MELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3420",
            "district": "33",
            "Block": "184",
            "label": "SRI AAIMATHAJI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3472",
            "district": "33",
            "Block": "184",
            "label": "KALVI POONGA MATRICULATION SCHOOL , KANIYAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3492",
            "district": "33",
            "Block": "184",
            "label": "PUMS,AVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3562",
            "district": "33",
            "Block": "184",
            "label": "PUMS,PANAPPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3687",
            "district": "33",
            "Block": "184",
            "label": "PUMS,NEW NANDIAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3769",
            "district": "33",
            "Block": "184",
            "label": "PUMS,VELLIVOYAL CHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3938",
            "district": "33",
            "Block": "184",
            "label": "EDEN MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3954",
            "district": "33",
            "Block": "184",
            "label": "PUMS, MADIMAI KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4217",
            "district": "33",
            "Block": "184",
            "label": "SON TRUST NUR& PRI SCH, MINJUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4347",
            "district": "33",
            "Block": "184",
            "label": "ST.ANNE'S MATRIC HIGHER SECONDARY SCHOOL, MINJUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4457",
            "district": "33",
            "Block": "184",
            "label": "PUMS,SENGAZHAURNEERMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4648",
            "district": "33",
            "Block": "184",
            "label": "BAALA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, MANALI NEW TOWN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4804",
            "district": "33",
            "Block": "184",
            "label": "PUMS, KATTAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4984",
            "district": "33",
            "Block": "184",
            "label": "ADW MID SCHOOL, PONNERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5046",
            "district": "33",
            "Block": "184",
            "label": "PUMS,KORAI KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5105",
            "district": "33",
            "Block": "184",
            "label": "PUMS, THIRUPPALAIVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5233",
            "district": "33",
            "Block": "184",
            "label": "PUMS ELAVAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5254",
            "district": "33",
            "Block": "184",
            "label": "PUMS,RAJIVGANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5640",
            "district": "33",
            "Block": "184",
            "label": "PUMS,CHINNAKAVANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5958",
            "district": "33",
            "Block": "184",
            "label": "PUMS,PONNERI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6230",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ARIYANVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6420",
            "district": "33",
            "Block": "184",
            "label": "PUMS, LIGHT HOUSE KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6470",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ATHIPATTU REDDYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6861",
            "district": "33",
            "Block": "184",
            "label": "GHS - PAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6862",
            "district": "33",
            "Block": "184",
            "label": "GHS,ANUPPAMBATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6863",
            "district": "33",
            "Block": "184",
            "label": "GHS,ATHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6864",
            "district": "33",
            "Block": "184",
            "label": "PUMS,ELIAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6865",
            "district": "33",
            "Block": "184",
            "label": "GHS - PAZAYANAPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6866",
            "district": "33",
            "Block": "184",
            "label": "GOVT HS,MANALI NEW TOWN PHASE 1",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6867",
            "district": "33",
            "Block": "184",
            "label": "GOVT HSS,MANALI NEW TOWN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6868",
            "district": "33",
            "Block": "184",
            "label": "INFANT JESUS MAT HSS,LAKSHMI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6869",
            "district": "33",
            "Block": "184",
            "label": "GHS, MANGODU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6870",
            "district": "33",
            "Block": "184",
            "label": "GHS, GOONANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6871",
            "district": "33",
            "Block": "184",
            "label": "GHS, KATTUPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6872",
            "district": "33",
            "Block": "184",
            "label": "GHS, KOLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6873",
            "district": "33",
            "Block": "184",
            "label": "JOSEPH HS, KOTTAIKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6874",
            "district": "33",
            "Block": "184",
            "label": "GHSS, KATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6875",
            "district": "33",
            "Block": "184",
            "label": "GHS, KANIYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6876",
            "district": "33",
            "Block": "184",
            "label": "GHSS, MEDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6877",
            "district": "33",
            "Block": "184",
            "label": "ANM HIGH SCHOOL, MELUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6878",
            "district": "33",
            "Block": "184",
            "label": "GHS, NALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6879",
            "district": "33",
            "Block": "184",
            "label": "GH GOVT HSS,PERUMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6880",
            "district": "33",
            "Block": "184",
            "label": "GHSS,PULICAT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6881",
            "district": "33",
            "Block": "184",
            "label": "ANM MS,PALLIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6882",
            "district": "33",
            "Block": "184",
            "label": "GHS, SUBBA REDDY PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6883",
            "district": "33",
            "Block": "184",
            "label": "GOVT HIGH SCHOOL  ANNAMALAICHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6884",
            "district": "33",
            "Block": "184",
            "label": "VIDYA MANDIR MATRIC HIGHER SECONDARY SCHOOL,KOKKUMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6885",
            "district": "33",
            "Block": "184",
            "label": "GOVT HSS, THIRUVELLAVOYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6886",
            "district": "33",
            "Block": "184",
            "label": "ADW HS, VALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6887",
            "district": "33",
            "Block": "184",
            "label": "DVS REDDY HSS, MINJUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6888",
            "district": "33",
            "Block": "184",
            "label": "SAHAYAMATHA GIRLS HIGHER SECONDARY SCHOOL, MINJUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6889",
            "district": "33",
            "Block": "184",
            "label": "ADWHS,MINJUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6890",
            "district": "33",
            "Block": "184",
            "label": "GHS,MINJUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6891",
            "district": "33",
            "Block": "184",
            "label": "GOVT (B) HSS, PONNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6892",
            "district": "33",
            "Block": "184",
            "label": "GOVT (G) HSS, PONNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6893",
            "district": "33",
            "Block": "184",
            "label": "UNITY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20894",
            "district": "33",
            "Block": "184",
            "label": "OPPORTUNITY SCHOOL , OLD NAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21707",
            "district": "33",
            "Block": "184",
            "label": "ST MARKS MATRIC SCH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22312",
            "district": "33",
            "Block": "184",
            "label": "GHS, MANALI NEW TOWN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22879",
            "district": "33",
            "Block": "184",
            "label": "GHS, A.REDDY PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23234",
            "district": "33",
            "Block": "184",
            "label": "GOVERNMENT HIGH SCHOOL , JAMELAPATH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23879",
            "district": "33",
            "Block": "184",
            "label": "BHAGAVAN SRI KRISHNA VIVEKANANDA VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "185": [
        {
            "key": "675",
            "district": "8",
            "Block": "185",
            "label": "PUMS,SAMINATHAPURAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "711",
            "district": "8",
            "Block": "185",
            "label": "PUMS, METTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "885",
            "district": "8",
            "Block": "185",
            "label": "PUMS,VETTUKATUVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "931",
            "district": "8",
            "Block": "185",
            "label": "PUMS, A.UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "953",
            "district": "8",
            "Block": "185",
            "label": "PUMS,PONNATHA VALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "981",
            "district": "8",
            "Block": "185",
            "label": "PUMS CHILLANGATTUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1035",
            "district": "8",
            "Block": "185",
            "label": "PUMS,PUDHUVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1041",
            "district": "8",
            "Block": "185",
            "label": "PUMS,SENAPATHYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1609",
            "district": "8",
            "Block": "185",
            "label": "PUMS,KAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2043",
            "district": "8",
            "Block": "185",
            "label": "PUMS,RASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2478",
            "district": "8",
            "Block": "185",
            "label": "NAVARASAM MATRI HSS,PALLIYUTHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2871",
            "district": "8",
            "Block": "185",
            "label": "ISHA VIDHYA MATRICULATION HIGHER SECONDARY SCHOOL,PERUMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3218",
            "district": "8",
            "Block": "185",
            "label": "JAYCEES MATRI HSS,POONDURAI ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3238",
            "district": "8",
            "Block": "185",
            "label": "RAJENDRAN MATRIC HSS,VADUGAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4348",
            "district": "8",
            "Block": "185",
            "label": "AGASTYA ACADEMY CBSE VEERAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4422",
            "district": "8",
            "Block": "185",
            "label": "LIONS M.HR.S.SCHOOL, PALANIGOUNDENVALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5031",
            "district": "8",
            "Block": "185",
            "label": "RD INTERNATIONAL SCHOOL, GATUPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6053",
            "district": "8",
            "Block": "185",
            "label": "P.K.P.SWAMY MATRIC HSS,KALYANIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12238",
            "district": "8",
            "Block": "185",
            "label": "GBHSS, MODAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12239",
            "district": "8",
            "Block": "185",
            "label": "GGHSS, MODAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12240",
            "district": "8",
            "Block": "185",
            "label": "SWAMY MATRIC HSS,MODAKKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12241",
            "district": "8",
            "Block": "185",
            "label": "SHRI VETHATHIRI VIDHYALAYA Met. SCHOOL,SAMYNATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12242",
            "district": "8",
            "Block": "185",
            "label": "GHSS,ELUMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12243",
            "district": "8",
            "Block": "185",
            "label": "PUMS,ANANTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12244",
            "district": "8",
            "Block": "185",
            "label": "GV ACADEMY,ELUMATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12245",
            "district": "8",
            "Block": "185",
            "label": "PUMS,KARUNDEVANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12246",
            "district": "8",
            "Block": "185",
            "label": "PUMS,KALATHUMINNAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12247",
            "district": "8",
            "Block": "185",
            "label": "GHSS,GANAPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12248",
            "district": "8",
            "Block": "185",
            "label": "PUMS, NAGARATCHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12249",
            "district": "8",
            "Block": "185",
            "label": "PUMS, MUTHUGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12250",
            "district": "8",
            "Block": "185",
            "label": "KURINCHI VENTHAN KALVI NILAYAM, CHINNYAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12251",
            "district": "8",
            "Block": "185",
            "label": "PRASANTHI VIDYA BHAVAN MATRIC SCHOOL, CHINNYAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12252",
            "district": "8",
            "Block": "185",
            "label": "PUMS , MANNATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12253",
            "district": "8",
            "Block": "185",
            "label": "GHSS, MUGASI ANUMANPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12254",
            "district": "8",
            "Block": "185",
            "label": "GHSS,ARACHALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12255",
            "district": "8",
            "Block": "185",
            "label": "PUMS, ANAIKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12256",
            "district": "8",
            "Block": "185",
            "label": "GHSS,LAKKAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12257",
            "district": "8",
            "Block": "185",
            "label": "PUMS,POONDURAISEMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12258",
            "district": "8",
            "Block": "185",
            "label": "PUMS,KILIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12259",
            "district": "8",
            "Block": "185",
            "label": "PUMS,ELAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12260",
            "district": "8",
            "Block": "185",
            "label": "PUMS,NATHAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12261",
            "district": "8",
            "Block": "185",
            "label": "GHS,KASPAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12262",
            "district": "8",
            "Block": "185",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, AVAL POONDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12263",
            "district": "8",
            "Block": "185",
            "label": "KASTHURBAGRAM HIGH SCHOOL, KASTHURBAGRAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12264",
            "district": "8",
            "Block": "185",
            "label": "GHSS,MINNAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12265",
            "district": "8",
            "Block": "185",
            "label": "GHS,VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12266",
            "district": "8",
            "Block": "185",
            "label": "GMS,VILAKETHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12267",
            "district": "8",
            "Block": "185",
            "label": "SARVA SEVA MIDDLE SCHOOL, VINOBHAGRAMAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20904",
            "district": "8",
            "Block": "185",
            "label": "BHARATHIYAR SPECIAL SCHOOL FOR MENTALLY RETARTED, SAVADIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "21153",
            "district": "8",
            "Block": "185",
            "label": "GREENPARK MATRIC HIGHER SECONDARY SCHOOL, KUPPAGOUNDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21467",
            "district": "8",
            "Block": "185",
            "label": "GHS, KANAGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22871",
            "district": "8",
            "Block": "185",
            "label": "GHS, KANDIKKATTUVALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23182",
            "district": "8",
            "Block": "185",
            "label": "RAJENDRAN ACADEMY FOR LEARNING, VADUGAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23183",
            "district": "8",
            "Block": "185",
            "label": "THE GREENS SCHOOL, KANAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23332",
            "district": "8",
            "Block": "185",
            "label": "THE NAVARRASAM ACADEMY, ARACHALUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23483",
            "district": "8",
            "Block": "185",
            "label": "AMRITA VIDYALAYAM, PUNJAILAKKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23483",
            "district": "8",
            "Block": "185",
            "label": "AMRITA VIDYALAYAM, PUNJAILAKKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23880",
            "district": "8",
            "Block": "185",
            "label": "THE VELS ACADEMY, MUTHUGOUNDANPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "186": [
        {
            "key": "1394",
            "district": "17",
            "Block": "186",
            "label": "PUMS ARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1500",
            "district": "17",
            "Block": "186",
            "label": "PUMS VALLIYAPPAMPATTIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1515",
            "district": "17",
            "Block": "186",
            "label": "PUMS THOLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1531",
            "district": "17",
            "Block": "186",
            "label": "PUMS LADDUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1553",
            "district": "17",
            "Block": "186",
            "label": "KKMS NANSEI EDAIYAR(AIDED)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1586",
            "district": "17",
            "Block": "186",
            "label": "PUMS MANAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1649",
            "district": "17",
            "Block": "186",
            "label": "PUMS PARALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1691",
            "district": "17",
            "Block": "186",
            "label": "PUMS RAMUDAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1744",
            "district": "17",
            "Block": "186",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1924",
            "district": "17",
            "Block": "186",
            "label": "PUMS NEIKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2604",
            "district": "17",
            "Block": "186",
            "label": "KAAVERI VIDDHYA BHAVAN MATRIC HR.SEC.SCHOOL, OLAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2987",
            "district": "17",
            "Block": "186",
            "label": "RASI MATRICULATION  SCHOOL, MOHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3475",
            "district": "17",
            "Block": "186",
            "label": "SHINEHILL ACADEMY MATRIC HIGHER SECONDARY SCHOOL,VALAYAPATTI (PO), NAMAKKAL DT -637020",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3481",
            "district": "17",
            "Block": "186",
            "label": "KALAIMAGAL MATRIC HR SEC SCHOOL ,MOHANUR ,NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4669",
            "district": "17",
            "Block": "186",
            "label": "APS RCMS PETTAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5999",
            "district": "17",
            "Block": "186",
            "label": "LITTLE ANGELS MATRICULATION SCHOOL, ANIYAPURAM, NAMAKKAL.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6341",
            "district": "17",
            "Block": "186",
            "label": "SAROJINI VIDHYALAYA MATRIC HR SEC SCHOOL, KUMARIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11797",
            "district": "17",
            "Block": "186",
            "label": "GHSS PALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11798",
            "district": "17",
            "Block": "186",
            "label": "GHSS ANIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11799",
            "district": "17",
            "Block": "186",
            "label": "LITTLE ANGELS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11800",
            "district": "17",
            "Block": "186",
            "label": "THE SCSM MATRICULATION HIGHER SECONDARY SCHOOL. MOHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11801",
            "district": "17",
            "Block": "186",
            "label": "GOVT MODEL GIRLS HSS MOHANUR -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11802",
            "district": "17",
            "Block": "186",
            "label": "GOVT  BOYS HSS MOHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11803",
            "district": "17",
            "Block": "186",
            "label": "VETRI VIDYALAYA MATRICULATION SCHOOL, ALAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11804",
            "district": "17",
            "Block": "186",
            "label": "GHS ORUVANDURPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11805",
            "district": "17",
            "Block": "186",
            "label": "GHS ANDAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11806",
            "district": "17",
            "Block": "186",
            "label": "GHSS VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11807",
            "district": "17",
            "Block": "186",
            "label": "GHSS  N.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21151",
            "district": "17",
            "Block": "186",
            "label": "THE MODERN ACADEMY MATRIC HIGHER SECONDARY SCHOOL,N.PUDUPATTI, TRICHY ROAD, NAMAKKAL -637020",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21596",
            "district": "17",
            "Block": "186",
            "label": "PARK VIEW ACADEMY, ANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22447",
            "district": "17",
            "Block": "186",
            "label": "SHRI SENTHUR INTERNATIONAL SCHOOL (CBSE) MOHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22967",
            "district": "17",
            "Block": "186",
            "label": "GOVT HIGH SCHOOL, S.VAZHAVANTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23881",
            "district": "17",
            "Block": "186",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "187": [
        {
            "key": "2508",
            "district": "6",
            "Block": "187",
            "label": "SRI VINAYAGA VIDHYALAYA MHSS, SILLARAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3073",
            "district": "6",
            "Block": "187",
            "label": "PUMS SAMANDAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3222",
            "district": "6",
            "Block": "187",
            "label": "PUMS KELAVALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3245",
            "district": "6",
            "Block": "187",
            "label": "PUMS NALLAGUDLAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3430",
            "district": "6",
            "Block": "187",
            "label": "PUMS JAGGUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4427",
            "district": "6",
            "Block": "187",
            "label": "PUMS GUNDALAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4450",
            "district": "6",
            "Block": "187",
            "label": "PUMS PUDUREDDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5107",
            "district": "6",
            "Block": "187",
            "label": "PUMS RANIMOOKANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5301",
            "district": "6",
            "Block": "187",
            "label": "SRI SANKARA MHSS,KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5443",
            "district": "6",
            "Block": "187",
            "label": "PUMS KURUBARAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5513",
            "district": "6",
            "Block": "187",
            "label": "PUMS VAGUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5827",
            "district": "6",
            "Block": "187",
            "label": "PUMS MANIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5835",
            "district": "6",
            "Block": "187",
            "label": "PUMS AMBALAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5852",
            "district": "6",
            "Block": "187",
            "label": "PUMS ALAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5878",
            "district": "6",
            "Block": "187",
            "label": "PUMS CHINNAMURUKKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6025",
            "district": "6",
            "Block": "187",
            "label": "PUMS VADAGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6182",
            "district": "6",
            "Block": "187",
            "label": "PUMS KONGARAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6242",
            "district": "6",
            "Block": "187",
            "label": "PUMS SUNKARAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6275",
            "district": "6",
            "Block": "187",
            "label": "I.V.L. MHSS, IRUMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6737",
            "district": "6",
            "Block": "187",
            "label": "AMRUTHA MHSS, KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9396",
            "district": "6",
            "Block": "187",
            "label": "GHSS BUDDIREDDIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9397",
            "district": "6",
            "Block": "187",
            "label": "MARUTHAM MHSS, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9398",
            "district": "6",
            "Block": "187",
            "label": "GHSS CINTHALPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9399",
            "district": "6",
            "Block": "187",
            "label": "GHSS M DODDAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9400",
            "district": "6",
            "Block": "187",
            "label": "PUMS E.AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23421",
            "district": "6",
            "Block": "187",
            "label": "Pughal Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "9401",
            "district": "6",
            "Block": "187",
            "label": "GHSS IRUMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9402",
            "district": "6",
            "Block": "187",
            "label": "GHSS KADATHUR - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9403",
            "district": "6",
            "Block": "187",
            "label": "GHSS KADATHUR - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9404",
            "district": "6",
            "Block": "187",
            "label": "KALAIMAGAL MHSS, KADATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9405",
            "district": "6",
            "Block": "187",
            "label": "SWAMY VIVEKANANDA MATRIC SCHOOL, KADATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9406",
            "district": "6",
            "Block": "187",
            "label": "PUMS KADAYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9407",
            "district": "6",
            "Block": "187",
            "label": "GHSS KAMBAINALLUR - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9408",
            "district": "6",
            "Block": "187",
            "label": "GHSS KAMBAINALLUR - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9409",
            "district": "6",
            "Block": "187",
            "label": "OXFORD MATRIC SCHOOL, SEMMANAHALLI, KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9410",
            "district": "6",
            "Block": "187",
            "label": "SACRED HEART MATRIC SCHOOL, NELLIMARATHUPATTI, KELAVALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9411",
            "district": "6",
            "Block": "187",
            "label": "GHS LINGANAICKANAHALLY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9412",
            "district": "6",
            "Block": "187",
            "label": "GHSS MORAPPUR - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9413",
            "district": "6",
            "Block": "187",
            "label": "GHSS MORAPPUR - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9414",
            "district": "6",
            "Block": "187",
            "label": "VISHWA BHARATHI MHSS, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9415",
            "district": "6",
            "Block": "187",
            "label": "PUMS OBLINAICKANAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9416",
            "district": "6",
            "Block": "187",
            "label": "GHSS ODASALPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9417",
            "district": "6",
            "Block": "187",
            "label": "GHS PAPPICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9418",
            "district": "6",
            "Block": "187",
            "label": "PUMS PERIYURVATHALMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9419",
            "district": "6",
            "Block": "187",
            "label": "GHSS R.GOPINATHAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9420",
            "district": "6",
            "Block": "187",
            "label": "GHSS RAMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9421",
            "district": "6",
            "Block": "187",
            "label": "PUMS REGADAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9422",
            "district": "6",
            "Block": "187",
            "label": "GHSS JALIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9423",
            "district": "6",
            "Block": "187",
            "label": "GHS SANTHAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9424",
            "district": "6",
            "Block": "187",
            "label": "PUMS SEKKANDAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9425",
            "district": "6",
            "Block": "187",
            "label": "GHS T.AYYAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23700",
            "district": "6",
            "Block": "187",
            "label": "jayasree vidhyalaya matric school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "9426",
            "district": "6",
            "Block": "187",
            "label": "PUMS THALANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9427",
            "district": "6",
            "Block": "187",
            "label": "ST. JOSEPH'S HIGH SCHOOL, THENKARAIKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9428",
            "district": "6",
            "Block": "187",
            "label": "GHSS THONGANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9429",
            "district": "6",
            "Block": "187",
            "label": "PUMS VETHARAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9430",
            "district": "6",
            "Block": "187",
            "label": "GHS DASIRAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9431",
            "district": "6",
            "Block": "187",
            "label": "KONGU MHSS, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9432",
            "district": "6",
            "Block": "187",
            "label": "GHS ELAVADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9433",
            "district": "6",
            "Block": "187",
            "label": "PUMS HUNISINAHALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9434",
            "district": "6",
            "Block": "187",
            "label": "GHS KANDAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9435",
            "district": "6",
            "Block": "187",
            "label": "GHS KETHUREDDIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9436",
            "district": "6",
            "Block": "187",
            "label": "GHSS   NATHAMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9437",
            "district": "6",
            "Block": "187",
            "label": "GHSS   NAVALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9438",
            "district": "6",
            "Block": "187",
            "label": "GHS POLAIYAMPALLY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9439",
            "district": "6",
            "Block": "187",
            "label": "GHS SILLARAHALLY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9440",
            "district": "6",
            "Block": "187",
            "label": "ACHIEVERS ACADEMY MHSS, SUNGARAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9441",
            "district": "6",
            "Block": "187",
            "label": "GHS THIPPAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9442",
            "district": "6",
            "Block": "187",
            "label": "GHS K.EACHCHAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20932",
            "district": "6",
            "Block": "187",
            "label": "SHRI GURU RAGHAVENDRA MATRIC SCHOOL, SILLARAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21260",
            "district": "6",
            "Block": "187",
            "label": "SRI RAM MHSS, BOOMISAMUTHIRAM, KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21446",
            "district": "6",
            "Block": "187",
            "label": "GHS MUTHANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22409",
            "district": "6",
            "Block": "187",
            "label": "GHS SENKUTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22410",
            "district": "6",
            "Block": "187",
            "label": "GHS VEPPILAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22880",
            "district": "6",
            "Block": "187",
            "label": "SRIRAM PUBLIC SCHOOL, BHOOMISAMUTHIRAM, KAMBAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22981",
            "district": "6",
            "Block": "187",
            "label": "AVVAI VIDHYASHRAM PUBLIC SCHOOL, POLAIYAMPALLI, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22982",
            "district": "6",
            "Block": "187",
            "label": "GREEN PARK INTERNATIONAL SCHOOL, KADATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22986",
            "district": "6",
            "Block": "187",
            "label": "GREEN PARK MHSS, KADATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23057",
            "district": "6",
            "Block": "187",
            "label": "AVVAI MHSS, JADAYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23087",
            "district": "6",
            "Block": "187",
            "label": "KONGU SCHOOL OF EXCELLENCE, MORAPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23421",
            "district": "6",
            "Block": "187",
            "label": "Pughal Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23421",
            "district": "6",
            "Block": "187",
            "label": "Pughal Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23421",
            "district": "6",
            "Block": "187",
            "label": "Pughal Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23421",
            "district": "6",
            "Block": "187",
            "label": "Pughal Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        }
    ],
    "188": [
        {
            "key": "1034",
            "district": "20",
            "Block": "188",
            "label": "SRI KANNA MATRICULATION HIGHER SECONDARY SCHOOL, MUDUKULATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1254",
            "district": "20",
            "Block": "188",
            "label": "KAMARAJAR MATRICULATION HIGHER SECONDARY SCHOOL, MUDUKULATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2056",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , S.R.N.PALANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3043",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , S.KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3119",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELAKODUMALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3719",
            "district": "20",
            "Block": "188",
            "label": "R.C MIDDLE SCHOOL , KODARENDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4726",
            "district": "20",
            "Block": "188",
            "label": "SARAVANA MATRICULATION HIGH SCHOOL, KAKKOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5059",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , SELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5119",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5138",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MEESAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5188",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , SAMBAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5247",
            "district": "20",
            "Block": "188",
            "label": "R.C MIDDLE SCHOOL, M.MICHAELPATTANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5621",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL , POOSERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5667",
            "district": "20",
            "Block": "188",
            "label": "THE CRESCENT MATRICULATION HIGHER SECONDARY  SCHOOL, KOLUNDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5928",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANAISERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6176",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VENNEERVAIKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19140",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALANGANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19141",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KADAMBODAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19142",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KAKKOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19143",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KEELATHOOVAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19144",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL , KEERANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19145",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOLUNDURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19146",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELATHOOVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19147",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUDUKULATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19148",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MUDUKULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19149",
            "district": "20",
            "Block": "188",
            "label": "PALLIVASAL HIGHER SECONDARY SCHOOL, MUDUKULATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19150",
            "district": "20",
            "Block": "188",
            "label": "T.E.L.C. HIGH SCHOOL, MUDUKULATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19151",
            "district": "20",
            "Block": "188",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PERUNGARUNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19152",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL , PUZHUTHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19153",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL, SELVANAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19154",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL, SEYYAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19155",
            "district": "20",
            "Block": "188",
            "label": "ROWTHER SAHIB HIGHER SECONDARY SCHOOL, THERIRUVELI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19156",
            "district": "20",
            "Block": "188",
            "label": "SACRED HEART HIGHER SECONDARY SCHOOL, THIRUVARANGAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19157",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH  SCHOOL , ULAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19158",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL, VALANADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19159",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL , VILANGULATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22926",
            "district": "20",
            "Block": "188",
            "label": "GOVERNMENT HIGH SCHOOL, VENGALAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "189": [
        {
            "key": "4429",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  S. KOLLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4841",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ODIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4920",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAKARAITHAZHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4943",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANTHILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5339",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SITHATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5381",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VASANTHAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5596",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARUNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5731",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SATHIYAKANDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6247",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUYAVANKADUVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6398",
            "district": "37",
            "Block": "189",
            "label": "ST.JOHN BOSCO MATRICULATION SCHOOL KANDACHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6522",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10471",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH SCHOOL KODUKKAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10472",
            "district": "37",
            "Block": "189",
            "label": "OUR LADY OF FATHIMA MATRICULATION SCHOOL ADICHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10473",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10474",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH SCHOOL NAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10475",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10476",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH  SCHOOL OTTAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10477",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NERKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10478",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL V. PUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10479",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  KANDACHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10480",
            "district": "37",
            "Block": "189",
            "label": "A G R GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL KANDACHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10481",
            "district": "37",
            "Block": "189",
            "label": "ST.MARY HIGH  SCHOOL OTHIYATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10482",
            "district": "37",
            "Block": "189",
            "label": "A R R GOVERNMENT HIGHER SECONDARY SCHOOL KARANAI PERICHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10483",
            "district": "37",
            "Block": "189",
            "label": "ST.XAVIER'S BOYS HIGHER SECONDARY SCHOOL MUGAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10484",
            "district": "37",
            "Block": "189",
            "label": "ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL MUGAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10485",
            "district": "37",
            "Block": "189",
            "label": "ST PETER PAUL SPECIAL HIGHER SECONDARY SCHOOL MUGAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10486",
            "district": "37",
            "Block": "189",
            "label": "ST JOSEPH'S MATRICULATION SCHOOL MUGAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10487",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH  SCHOOL AYANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10488",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH  SCHOOL SENNAKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10489",
            "district": "37",
            "Block": "189",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEERANGIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10490",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL ALAMPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10491",
            "district": "37",
            "Block": "189",
            "label": "AKD MATRIC HIGHER SECONDARY SCHOOL ALAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10492",
            "district": "37",
            "Block": "189",
            "label": "ROMAN CATHOLIC MIDDLE  SCHOOL KODUNGAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10493",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ARCADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10494",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH SCHOOL PAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10498",
            "district": "37",
            "Block": "189",
            "label": "BALAMANDIR MATRIC HIGHER SECONDARY SCHOOL ARAKANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10499",
            "district": "37",
            "Block": "189",
            "label": "SRI LAKSHMI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL, ARAKANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10500",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ARAKANDANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10501",
            "district": "37",
            "Block": "189",
            "label": "AIDED MIDDLE SCHOOL  ARAKANDANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21150",
            "district": "37",
            "Block": "189",
            "label": "VIVEKANANDA VIDHYALAYA MATRICULATION HIGHER SECONDARY  SCHOOL  MANAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22100",
            "district": "37",
            "Block": "189",
            "label": "SRI LAKSHMI VIDHYAASHRAM SCHOOL ARAKANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22101",
            "district": "37",
            "Block": "189",
            "label": "VIVEKA PUBLIC SCHOOL (CBSE)  ARAKANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23229",
            "district": "37",
            "Block": "189",
            "label": "GOVERNMENT HIGH SCHOOL SU PILRAMPAT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23636",
            "district": "37",
            "Block": "189",
            "label": "VIVEKANANDA VIDYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23637",
            "district": "37",
            "Block": "189",
            "label": "PALANIVELU MATRICULATION SCHOOL MELVALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23636",
            "district": "37",
            "Block": "189",
            "label": "VIVEKANANDA VIDYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23637",
            "district": "37",
            "Block": "189",
            "label": "PALANIVELU MATRICULATION SCHOOL MELVALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "190": [
        {
            "key": "936",
            "district": "32",
            "Block": "190",
            "label": "PUMS ARIKKARANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "990",
            "district": "32",
            "Block": "190",
            "label": "PUMS M.KARUPPANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1133",
            "district": "32",
            "Block": "190",
            "label": "PUMS NATHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1145",
            "district": "32",
            "Block": "190",
            "label": "PUMS KURNAICKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1267",
            "district": "32",
            "Block": "190",
            "label": "PUMS MULANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1606",
            "district": "32",
            "Block": "190",
            "label": "PUMS PATTUTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1667",
            "district": "32",
            "Block": "190",
            "label": "PANCHAYAT  UNION  MIDDLE  SCHOOL  SOMANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1764",
            "district": "32",
            "Block": "190",
            "label": "BHARATHI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL,MULANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2914",
            "district": "32",
            "Block": "190",
            "label": "KALAIKOVIL VIDHYA MANDIR MATRIC HIGHER SECONDARY SCHOOL, POLARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12268",
            "district": "32",
            "Block": "190",
            "label": "PUMS ERASINAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12269",
            "district": "32",
            "Block": "190",
            "label": "GHS N.C.G.VALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12270",
            "district": "32",
            "Block": "190",
            "label": "GHSS VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12271",
            "district": "32",
            "Block": "190",
            "label": "GHSS KANNIVAADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12272",
            "district": "32",
            "Block": "190",
            "label": "GHS SESAIYANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12273",
            "district": "32",
            "Block": "190",
            "label": "GHSS MOOLANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12274",
            "district": "32",
            "Block": "190",
            "label": "VANJIAMMAN  MATRIC MULANUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12275",
            "district": "32",
            "Block": "190",
            "label": "KGBV MULANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12276",
            "district": "32",
            "Block": "190",
            "label": "MODEL HSS MOOLANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12277",
            "district": "32",
            "Block": "190",
            "label": "GHSS ELUGAMVALASU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12278",
            "district": "32",
            "Block": "190",
            "label": "GHS NANJAITHALAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12279",
            "district": "32",
            "Block": "190",
            "label": "GHS PERAMIUM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12280",
            "district": "32",
            "Block": "190",
            "label": "GHS CHINNAKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23325",
            "district": "32",
            "Block": "190",
            "label": "VANJIAMMAN VIDYA VIKAS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23882",
            "district": "32",
            "Block": "190",
            "label": "ST. MARY'S MATRICULATION SCHOOL, MALLAMPALAYAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "191": [
        {
            "key": "1008",
            "district": "11",
            "Block": "191",
            "label": "ST. ANTONY'S MATRIC HIGHER SECONDARY SCHOOL, MANAVILA KIRATHOOR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1231",
            "district": "11",
            "Block": "191",
            "label": "SAHAYAMATHA MATRICULATION SCHOOL VAVARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1276",
            "district": "11",
            "Block": "191",
            "label": "SREE KRISHNA HINDU VIDYALAYA M.H.S.S. KANCHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1705",
            "district": "11",
            "Block": "191",
            "label": "SR MAT SCHOOL KANJAMPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1828",
            "district": "11",
            "Block": "191",
            "label": "M.M.K.M. H.S. VARUTHATTU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "2018",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  ADAIKKAKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2069",
            "district": "11",
            "Block": "191",
            "label": "ST MARY'S MATRIC. HR. SEC. SCHOOL  MARIAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2091",
            "district": "11",
            "Block": "191",
            "label": "JANET MATRIC HIGHER SECONDARY SCHOOL  MUNCHIRAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2184",
            "district": "11",
            "Block": "191",
            "label": "MARGREGORIOS MATRIC HSS KIRATHOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2438",
            "district": "11",
            "Block": "191",
            "label": "GMS MADICHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2487",
            "district": "11",
            "Block": "191",
            "label": "ABC MATRIC H.S.S. KOLLEMCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2519",
            "district": "11",
            "Block": "191",
            "label": "BADHARUL ISLAM MAT SCHOOL  KALIAKAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3069",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  KIRATHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3076",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  PAINKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3080",
            "district": "11",
            "Block": "191",
            "label": "CONCORDIA LUTHERAN HIGH SCHOOL,  KALPARAPOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "3304",
            "district": "11",
            "Block": "191",
            "label": "K.A.B.D. MATRIC. HR. SEC. SCHOOL, PAINKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3359",
            "district": "11",
            "Block": "191",
            "label": "ST JUDES MS VALLAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3735",
            "district": "11",
            "Block": "191",
            "label": "GMS LEKSHMI PUTHUKKADAI, NITHIRAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3740",
            "district": "11",
            "Block": "191",
            "label": "GOVTMIDDLE SCHOOL, PARTHIVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3760",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL-PUDUKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3935",
            "district": "11",
            "Block": "191",
            "label": "ST JOSEPH MIDDLE SCHOOL  ERAVIPUTHENTHURAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4008",
            "district": "11",
            "Block": "191",
            "label": "GOVT MIDDLE SCHOOL  PORANCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4035",
            "district": "11",
            "Block": "191",
            "label": "MUNCHIRAI PUNITHA AROCKIYAMATHA MAT. HSS PUTHUKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4069",
            "district": "11",
            "Block": "191",
            "label": "G M S KOLLEMCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4230",
            "district": "11",
            "Block": "191",
            "label": "MAYA  KRISHNA SWAMY  VIDYALAYA, ANANTHANAGER, ATHENCODE.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4752",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  MUKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4805",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL  MUNCHIRAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4889",
            "district": "11",
            "Block": "191",
            "label": "GMS CHATHENCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5016",
            "district": "11",
            "Block": "191",
            "label": "GOVT MS POOTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5030",
            "district": "11",
            "Block": "191",
            "label": "ST. ANTONY'S M.S.C. MIDDLE SCHOOL METHUKUMMEL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5074",
            "district": "11",
            "Block": "191",
            "label": "GOVT MIDDLLE SCHOOL, KUNNATHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5662",
            "district": "11",
            "Block": "191",
            "label": "ST. THOMAS M.S.C. MIDDLE SCHOOL MANKUZHI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5757",
            "district": "11",
            "Block": "191",
            "label": "GOVT.M.S-UTHACHIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6000",
            "district": "11",
            "Block": "191",
            "label": "OUR LADY OF MERCY EM  KARUNYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6293",
            "district": "11",
            "Block": "191",
            "label": "JOHN BONAL MATRICULATION SCHOOL, MARTHANDANTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6313",
            "district": "11",
            "Block": "191",
            "label": "ST.JUDE'S MATRICULATION SCHOOL-CHINNATHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6590",
            "district": "11",
            "Block": "191",
            "label": "LITTLE FLOWER MATRICULATION HIGHER SECONDARY SCHOOL ,PUSHPAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6711",
            "district": "11",
            "Block": "191",
            "label": "ST MATHEWS MS ANNIKKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20376",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE  SCHOOL , VENKANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20377",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT  HIGHER SECONDARY  SCHOOL ,KOLLENCODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20378",
            "district": "11",
            "Block": "191",
            "label": "ST JOSEPH HIGH SCHOOL,  SILUVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20379",
            "district": "11",
            "Block": "191",
            "label": "MAR GREGORIOS M.S.C. HIGH SCHOOL KIRATHOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20380",
            "district": "11",
            "Block": "191",
            "label": "ST STEPHEN HIGH SCHOOL  SOUTHPALAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20381",
            "district": "11",
            "Block": "191",
            "label": "SREEDEVI GIRLS Higher Secondary  School  KOLLAMCODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20382",
            "district": "11",
            "Block": "191",
            "label": "ST ALOYSIUS HSS MARTHANDANTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20383",
            "district": "11",
            "Block": "191",
            "label": "RTM LMS HSS VENKANCHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20384",
            "district": "11",
            "Block": "191",
            "label": "SHREE VR V V MATRIC HIGH SCHOOL KOLLEMCODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20385",
            "district": "11",
            "Block": "191",
            "label": "ST NICHOLOSE HS  NEERODITHURAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20386",
            "district": "11",
            "Block": "191",
            "label": "GOVT HSS EZHUDESAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20387",
            "district": "11",
            "Block": "191",
            "label": "ST JOSEPH HS KARUNYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20388",
            "district": "11",
            "Block": "191",
            "label": "PIUS XI  HSS  THOOTHOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20389",
            "district": "11",
            "Block": "191",
            "label": "JAYAMATHA MATRICULATION SCHOOL CHRISTURAJAPURAM NITHRAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20390",
            "district": "11",
            "Block": "191",
            "label": "ST LITTLE FLOWER MHS KANJAMPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20391",
            "district": "11",
            "Block": "191",
            "label": "GOVT ADW HSS KALINGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20392",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT HIGH SCHOOL  ANANTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20393",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  THENGAPATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20394",
            "district": "11",
            "Block": "191",
            "label": "AMSI HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20395",
            "district": "11",
            "Block": "191",
            "label": "AL AMEEN MATRICULATION SCHOOL, THENGAIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20396",
            "district": "11",
            "Block": "191",
            "label": "GPVMS  MANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20397",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL PALLIKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20398",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL MUNCHIRAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20399",
            "district": "11",
            "Block": "191",
            "label": "ST MARYS  MS  PUTHUKDADAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20400",
            "district": "11",
            "Block": "191",
            "label": "ST FRANCIS HSS  VAVARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20401",
            "district": "11",
            "Block": "191",
            "label": "GOVERNMENT MIDDLE SCHOOL, KALIAKKAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20402",
            "district": "11",
            "Block": "191",
            "label": "Sacred Heart Middle School Kamarajnagar",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20403",
            "district": "11",
            "Block": "191",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL VALANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20404",
            "district": "11",
            "Block": "191",
            "label": "ST.EPHREM'S M.S.C  HSS, SOORIACODE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20405",
            "district": "11",
            "Block": "191",
            "label": "SREE BHAGAVATHY VIDYALAYA MATRIC HR. SEC. SCHOOL MEKKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20406",
            "district": "11",
            "Block": "191",
            "label": "GOVT HS MARAYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20407",
            "district": "11",
            "Block": "191",
            "label": "ST ANTONYS HS KAPPUKADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20408",
            "district": "11",
            "Block": "191",
            "label": "CSI VV HSS IRENIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20409",
            "district": "11",
            "Block": "191",
            "label": "CSI VV (G) HS IRENIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20410",
            "district": "11",
            "Block": "191",
            "label": "SRI VIGNESWARA MHSS KAPPUKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20411",
            "district": "11",
            "Block": "191",
            "label": "C.S.I. GNANASIROMONY MATRICULATION SCHOOL IRENIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20412",
            "district": "11",
            "Block": "191",
            "label": "SRI KRISHNA MATRICULATION  SCHOOL-THAVITTAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20413",
            "district": "11",
            "Block": "191",
            "label": "GOVT HS PALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20414",
            "district": "11",
            "Block": "191",
            "label": "GHS ATHENCODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20415",
            "district": "11",
            "Block": "191",
            "label": "CHRIST THE KING HS PIRACODE",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20910",
            "district": "11",
            "Block": "191",
            "label": "ST.JOHNS MATRIC SCHOOL- THOOTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20984",
            "district": "11",
            "Block": "191",
            "label": "ST. MARRY'S SCHOOL AND HOME FOR MENTALLY CHALLENGED CHILDREN KARUNAIILLAM-NAMBALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21588",
            "district": "11",
            "Block": "191",
            "label": "NOBLE PUBLIC SCHOOL, KALIAKKAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21626",
            "district": "11",
            "Block": "191",
            "label": "ST.XAVIER ENGLISH SCHOOL-PALAVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21811",
            "district": "11",
            "Block": "191",
            "label": "ST. STEPHEN'S MATRIC SCHOOL, SOUTH PALAVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21812",
            "district": "11",
            "Block": "191",
            "label": "KABD CBSE SCHOOL-PAINKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22289",
            "district": "11",
            "Block": "191",
            "label": "LITTLE FLOWER CENTRAL SCHOOL-PUSHPAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22290",
            "district": "11",
            "Block": "191",
            "label": "ST.MARY'S CBSE SCHOOL-MARIAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23683",
            "district": "11",
            "Block": "191",
            "label": "Holy Angels International School,",
            "Sch_type": "Unaided",
            "Category": "High School"
        }
    ],
    "192": [
        {
            "key": "3464",
            "district": "29",
            "Block": "192",
            "label": "AMALA MATRIC HR SEC SCHOOL, MUSIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23702",
            "district": "29",
            "Block": "192",
            "label": "Saraswati Vidya Shala School ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "5063",
            "district": "29",
            "Block": "192",
            "label": "SHANTHI Matric..H.S.S MUSIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14319",
            "district": "29",
            "Block": "192",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL,  AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14320",
            "district": "29",
            "Block": "192",
            "label": "GHSS AMOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14321",
            "district": "29",
            "Block": "192",
            "label": "PUMS MANAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14322",
            "district": "29",
            "Block": "192",
            "label": "PUMS KARUPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14323",
            "district": "29",
            "Block": "192",
            "label": "PUMS KATTUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14324",
            "district": "29",
            "Block": "192",
            "label": "PUMS GUNASEELAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14325",
            "district": "29",
            "Block": "192",
            "label": "GHS KALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14326",
            "district": "29",
            "Block": "192",
            "label": "GHS KOTTATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14327",
            "district": "29",
            "Block": "192",
            "label": "PUMS SOUTH SITHAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14328",
            "district": "29",
            "Block": "192",
            "label": "GHSS NORTH SITHAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14329",
            "district": "29",
            "Block": "192",
            "label": "PUMS THANDALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14330",
            "district": "29",
            "Block": "192",
            "label": "GHS SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14331",
            "district": "29",
            "Block": "192",
            "label": "GHSS THANDALAIPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14332",
            "district": "29",
            "Block": "192",
            "label": "PUMS VELAKKANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14333",
            "district": "29",
            "Block": "192",
            "label": "GHS THINNANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14334",
            "district": "29",
            "Block": "192",
            "label": "GADWHSS THIRUTHALAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14335",
            "district": "29",
            "Block": "192",
            "label": "PUMS MELAKOTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14336",
            "district": "29",
            "Block": "192",
            "label": "GHS THIRUTHIYAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14337",
            "district": "29",
            "Block": "192",
            "label": "AMS VEERAMANIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14338",
            "district": "29",
            "Block": "192",
            "label": "GHS NACHAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14339",
            "district": "29",
            "Block": "192",
            "label": "PUMS NEIVELY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14340",
            "district": "29",
            "Block": "192",
            "label": "PUMS SOUTH NALLIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14341",
            "district": "29",
            "Block": "192",
            "label": "NEHRU HSS, PUTHANAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14342",
            "district": "29",
            "Block": "192",
            "label": "GHSS,PULIVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14343",
            "district": "29",
            "Block": "192",
            "label": "J.F.O.A HSS, PULIVALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14344",
            "district": "29",
            "Block": "192",
            "label": "PUMS PERAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14345",
            "district": "29",
            "Block": "192",
            "label": "GHS SINTHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14346",
            "district": "29",
            "Block": "192",
            "label": "GHSS KARATTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14347",
            "district": "29",
            "Block": "192",
            "label": "GHS.MANBARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14348",
            "district": "29",
            "Block": "192",
            "label": "GHSS MOOVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14349",
            "district": "29",
            "Block": "192",
            "label": "PUMS VELLAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14350",
            "district": "29",
            "Block": "192",
            "label": "GHS VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14351",
            "district": "29",
            "Block": "192",
            "label": "PUMS ANAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14352",
            "district": "29",
            "Block": "192",
            "label": "PUMS KANNUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14353",
            "district": "29",
            "Block": "192",
            "label": "PUMS SANTHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14354",
            "district": "29",
            "Block": "192",
            "label": "PUMS MUSIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14355",
            "district": "29",
            "Block": "192",
            "label": "G(B)HSS MUSIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14356",
            "district": "29",
            "Block": "192",
            "label": "G(G)HSS MUSIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14357",
            "district": "29",
            "Block": "192",
            "label": "AMALA G HSS, MUSIRI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21510",
            "district": "29",
            "Block": "192",
            "label": "GADWHS PERUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22186",
            "district": "29",
            "Block": "192",
            "label": "RAGHAVENDRA MATRICULATION HIGHER SEC. SCHOOL, KARATTAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22187",
            "district": "29",
            "Block": "192",
            "label": "SHRI JAYENDRA VIDHYALAYA CBSE SCHOOL, VADUGAPATTY, MUSIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22527",
            "district": "29",
            "Block": "192",
            "label": "GHS PERIYAKODUNTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22528",
            "district": "29",
            "Block": "192",
            "label": "GHS CHINNA KODUNTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23883",
            "district": "29",
            "Block": "192",
            "label": "PUMS VELLAKKALPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "193": [
        {
            "key": "586",
            "district": "35",
            "Block": "193",
            "label": "UAMHSS,SARASWATHI,MUTHUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1363",
            "district": "35",
            "Block": "193",
            "label": "GHSS EDAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1765",
            "district": "35",
            "Block": "193",
            "label": "UAMHSS BRILLIANT ,MUTHUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4985",
            "district": "35",
            "Block": "193",
            "label": "PUMS, AARIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5039",
            "district": "35",
            "Block": "193",
            "label": "PUMS,THILLAIVILAGAM-SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5078",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KARPAGA-COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5224",
            "district": "35",
            "Block": "193",
            "label": "PUMS,T-KEELAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5290",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KALLIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5353",
            "district": "35",
            "Block": "193",
            "label": "PUMS,OVARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5454",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KARPAGA-KADUVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5619",
            "district": "35",
            "Block": "193",
            "label": "PUMS PETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5711",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KEELAPANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5766",
            "district": "35",
            "Block": "193",
            "label": "PUMS,EDAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5775",
            "district": "35",
            "Block": "193",
            "label": "PUMS KALAIKKARANVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5838",
            "district": "35",
            "Block": "193",
            "label": "PUMS,ALKATTUVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5854",
            "district": "35",
            "Block": "193",
            "label": "PUMS,THOLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6036",
            "district": "35",
            "Block": "193",
            "label": "PUMS,GOPALASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6041",
            "district": "35",
            "Block": "193",
            "label": "PUMS,JAMBUVANODAI THARKA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6091",
            "district": "35",
            "Block": "193",
            "label": "PUMS,AZADNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6123",
            "district": "35",
            "Block": "193",
            "label": "PUMS,NEW STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6324",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6349",
            "district": "35",
            "Block": "193",
            "label": "PUMS,THILLAIVILAGAM-EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6418",
            "district": "35",
            "Block": "193",
            "label": "PUMS,UDHAYAMARTHANDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6514",
            "district": "35",
            "Block": "193",
            "label": "PUMS,MARUTHANGAVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6643",
            "district": "35",
            "Block": "193",
            "label": "PUMS,PETHAVELANKOTTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6649",
            "district": "35",
            "Block": "193",
            "label": "PUMS,EKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6707",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KUNNALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16332",
            "district": "35",
            "Block": "193",
            "label": "GHS ALANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16333",
            "district": "35",
            "Block": "193",
            "label": "GHSS IDUMBAVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16334",
            "district": "35",
            "Block": "193",
            "label": "GHS, KEEZHAVADIYAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16335",
            "district": "35",
            "Block": "193",
            "label": "PUMS,KALLADIKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16336",
            "district": "35",
            "Block": "193",
            "label": "GHS,JAMBUVANODAI NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16337",
            "district": "35",
            "Block": "193",
            "label": "UAMHS, OMA JAMBUVANODAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16338",
            "district": "35",
            "Block": "193",
            "label": "UAMHSS PALM KALLIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16339",
            "district": "35",
            "Block": "193",
            "label": "GHS,KEELAPERUMAZHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16340",
            "district": "35",
            "Block": "193",
            "label": "GHS MANKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16341",
            "district": "35",
            "Block": "193",
            "label": "GHS MARUTHAVANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16342",
            "district": "35",
            "Block": "193",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VELLANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16343",
            "district": "35",
            "Block": "193",
            "label": "UAMHS SRI OM SAKTHI ,VEPPANCHERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16344",
            "district": "35",
            "Block": "193",
            "label": "GHSS THILLAIVILGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16345",
            "district": "35",
            "Block": "193",
            "label": "GHS SENGANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16346",
            "district": "35",
            "Block": "193",
            "label": "GHSS,NACHIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16347",
            "district": "35",
            "Block": "193",
            "label": "UAMHS MILLENNIUM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16348",
            "district": "35",
            "Block": "193",
            "label": "GHS-KARAIYANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16349",
            "district": "35",
            "Block": "193",
            "label": "KAP AGHSS, PERIYANAYAKI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16350",
            "district": "35",
            "Block": "193",
            "label": "GHSS MUTHUPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16351",
            "district": "35",
            "Block": "193",
            "label": "UAMHSS,RAHAMATH, MUTHUPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16352",
            "district": "35",
            "Block": "193",
            "label": "UAMHS,WINNER ALANGADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21473",
            "district": "35",
            "Block": "193",
            "label": "GHS, PANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21474",
            "district": "35",
            "Block": "193",
            "label": "GHS THONDIAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "194": [
        {
            "key": "1016",
            "district": "27",
            "Block": "194",
            "label": "PUMS - PONNAMMALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1054",
            "district": "27",
            "Block": "194",
            "label": "PUMS - KOVILPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1111",
            "district": "27",
            "Block": "194",
            "label": "PUMS - KAMANKALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1141",
            "district": "27",
            "Block": "194",
            "label": "PUMS - VALIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1397",
            "district": "27",
            "Block": "194",
            "label": "PUMS -  SOLAITHEVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1709",
            "district": "27",
            "Block": "194",
            "label": "PUMS - UPPUTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1720",
            "district": "27",
            "Block": "194",
            "label": "PUMS - SIRAPPARAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1871",
            "district": "27",
            "Block": "194",
            "label": "PUMS - GANESHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3137",
            "district": "27",
            "Block": "194",
            "label": "PUMS - SINGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3144",
            "district": "27",
            "Block": "194",
            "label": "PUMS - THOPPAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3156",
            "district": "27",
            "Block": "194",
            "label": "PUMS - KAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4507",
            "district": "27",
            "Block": "194",
            "label": "PUMS - PONNANPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4553",
            "district": "27",
            "Block": "194",
            "label": "PUMS - MELAPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18569",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, RAJENDRANAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18570",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, GANDAMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18571",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, KADAMALAIKUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18572",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, KUMANANTHOZHU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18573",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, MANTHISUNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18574",
            "district": "27",
            "Block": "194",
            "label": "HAYAGREEVA VIDHYALAYA MATRIC HSS KADAMALAIKUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18575",
            "district": "27",
            "Block": "194",
            "label": "G.R.V. HSS, MYLADUMPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18576",
            "district": "27",
            "Block": "194",
            "label": "PUMS -  NARIYOOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18577",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, MURUKKODAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18578",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, MYLADUMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18579",
            "district": "27",
            "Block": "194",
            "label": "GOVT HS, POOSANUTHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18580",
            "district": "27",
            "Block": "194",
            "label": "GOVT HS, KOMBAITHOZHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18581",
            "district": "27",
            "Block": "194",
            "label": "GOVT HS, THUMMAKUNDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18582",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, THANGAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18583",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, VARUSANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18584",
            "district": "27",
            "Block": "194",
            "label": "GOVT HSS, VAIKKALPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        }
    ],
    "195": [
        {
            "key": "412",
            "district": "3",
            "Block": "195",
            "label": "ST.SAVIO MATRICULATION HIGHER  SECONDARY SCHOOL, VELACHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "485",
            "district": "3",
            "Block": "195",
            "label": "VIDYA RATNA P.T.S MATRIC.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "879",
            "district": "3",
            "Block": "195",
            "label": "MP ANAND MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "889",
            "district": "3",
            "Block": "195",
            "label": "MWA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1209",
            "district": "3",
            "Block": "195",
            "label": "BHARATH SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1313",
            "district": "3",
            "Block": "195",
            "label": "SRI SANKARA VIDYASHRAMAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1337",
            "district": "3",
            "Block": "195",
            "label": "MANUELMONY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1381",
            "district": "3",
            "Block": "195",
            "label": "SARASWATHY VENKATARAMAN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1537",
            "district": "3",
            "Block": "195",
            "label": "CHETTINAD VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1737",
            "district": "3",
            "Block": "195",
            "label": "SRI SANKARA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1743",
            "district": "3",
            "Block": "195",
            "label": "CSI MONAHAN GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24133", "district":"3", 
            "Block":"195", "label": "Sprouts Montessori", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "2072",
            "district": "3",
            "Block": "195",
            "label": "SRI VENUGOPAL VIDYALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2148",
            "district": "3",
            "Block": "195",
            "label": "SRI MYLAI KARPAGAVALLI MATRICUATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2458",
            "district": "3",
            "Block": "195",
            "label": "CMS THIRUVALLUVARPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2685",
            "district": "3",
            "Block": "195",
            "label": "CMS NS GARDEN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2842",
            "district": "3",
            "Block": "195",
            "label": "CMS PROF SANJEEVI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2855",
            "district": "3",
            "Block": "195",
            "label": "M.Ct.M  CHIDAMBARAM CHETTYAR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3116",
            "district": "3",
            "Block": "195",
            "label": "CMS MK AMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3209",
            "district": "3",
            "Block": "195",
            "label": "ST JOHNS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3296",
            "district": "3",
            "Block": "195",
            "label": "ST. ANTONY'S GIRLS HR .SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3376",
            "district": "3",
            "Block": "195",
            "label": "CMS GANDHIGRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3454",
            "district": "3",
            "Block": "195",
            "label": "ST.JOHN'S MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3789",
            "district": "3",
            "Block": "195",
            "label": "ROSARY MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3899",
            "district": "3",
            "Block": "195",
            "label": "DAVID MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3962",
            "district": "3",
            "Block": "195",
            "label": "ST.BEDE'S A.I HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4440",
            "district": "3",
            "Block": "195",
            "label": "PS MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23661",
            "district": "3",
            "Block": "195",
            "label": "Besant Lvr Matriculation Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4658",
            "district": "3",
            "Block": "195",
            "label": "BALA SARAVANA VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4686",
            "district": "3",
            "Block": "195",
            "label": "MOUNT CARMEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4710",
            "district": "3",
            "Block": "195",
            "label": "AKSHAYAH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4728",
            "district": "3",
            "Block": "195",
            "label": "MONTFORT ACADEMY MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5518",
            "district": "3",
            "Block": "195",
            "label": "CSI MIDDLE SCHOOL,PUDUPAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5657",
            "district": "3",
            "Block": "195",
            "label": "CMS DAMODARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5664",
            "district": "3",
            "Block": "195",
            "label": "ST FRANCIS XAVIER'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6008",
            "district": "3",
            "Block": "195",
            "label": "BESANT ARUNDALE SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6280",
            "district": "3",
            "Block": "195",
            "label": "LADY WILLINGTON HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6379",
            "district": "3",
            "Block": "195",
            "label": "DAV PUBLIC SCHOOL VELACHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6406",
            "district": "3",
            "Block": "195",
            "label": "ST.LAZARUS MIDDLE SCH00L",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6521",
            "district": "3",
            "Block": "195",
            "label": "S.R.Kalyanaraman Memorial P.S Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6664",
            "district": "3",
            "Block": "195",
            "label": "CSI MIDDLE SCHOOL,ADAM STREET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6695",
            "district": "3",
            "Block": "195",
            "label": "KUMARA RAJAH MUTHIAH MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7500",
            "district": "3",
            "Block": "195",
            "label": "SACRED HEART GIRLS HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7501",
            "district": "3",
            "Block": "195",
            "label": "GANAPATHY IYER GIRLS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7502",
            "district": "3",
            "Block": "195",
            "label": "WESLEY HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7503",
            "district": "3",
            "Block": "195",
            "label": "GOPALAPURAM BOYS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7504",
            "district": "3",
            "Block": "195",
            "label": "GOVT HSS NANDANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7505",
            "district": "3",
            "Block": "195",
            "label": "CHSS ALWARPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7506",
            "district": "3",
            "Block": "195",
            "label": "THE CHILDREN'S  GARDEN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7507",
            "district": "3",
            "Block": "195",
            "label": "KESARI HGHER SECONDARY SCHOOL MYLAPORE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7508",
            "district": "3",
            "Block": "195",
            "label": "VIDYA MANDIR SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7509",
            "district": "3",
            "Block": "195",
            "label": "SAVITRIAMMAL ORIENTAL HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7510",
            "district": "3",
            "Block": "195",
            "label": "P.S HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7511",
            "district": "3",
            "Block": "195",
            "label": "PS SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7512",
            "district": "3",
            "Block": "195",
            "label": "ALL SAINTS' MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7513",
            "district": "3",
            "Block": "195",
            "label": "RAMAKRISHNA MISSION RESIDENTIAL HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7514",
            "district": "3",
            "Block": "195",
            "label": "KALEIDOSCOPE LEARNING CENTER",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7515",
            "district": "3",
            "Block": "195",
            "label": "CHS KP ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7516",
            "district": "3",
            "Block": "195",
            "label": "CSI  HR .SEC.SCHOOL FOR THE DEAF",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7517",
            "district": "3",
            "Block": "195",
            "label": "ST FRANCIS XAVIER MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7518",
            "district": "3",
            "Block": "195",
            "label": "LADY SIVASWAMI AYYAR GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7519",
            "district": "3",
            "Block": "195",
            "label": "DOMINIC SAVIO MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7520",
            "district": "3",
            "Block": "195",
            "label": "SIR SIVASAMI KALALAYA SENIOR SECONDARY SCHOOL,MYLAPORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7521",
            "district": "3",
            "Block": "195",
            "label": "CHS CANAL BANK ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7522",
            "district": "3",
            "Block": "195",
            "label": "ST RAPHAEL'S GIRLS HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7523",
            "district": "3",
            "Block": "195",
            "label": "SRI RAJALAKSHMI MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7524",
            "district": "3",
            "Block": "195",
            "label": "SIR SIVASWAMI KALALAYA HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7525",
            "district": "3",
            "Block": "195",
            "label": "RANI MEYYAMMAI GIRLS' HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7526",
            "district": "3",
            "Block": "195",
            "label": "RAJAH MUTHIAH HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7527",
            "district": "3",
            "Block": "195",
            "label": "CHETTINAD HARI SHREE VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7528",
            "district": "3",
            "Block": "195",
            "label": "CMS SANTHOME",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7529",
            "district": "3",
            "Block": "195",
            "label": "SANTHOME HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7530",
            "district": "3",
            "Block": "195",
            "label": "SISHYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7531",
            "district": "3",
            "Block": "195",
            "label": "CHS KAMARAJ AVENUE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7532",
            "district": "3",
            "Block": "195",
            "label": "St. John's English School & Jr College",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7533",
            "district": "3",
            "Block": "195",
            "label": "OLCOTT MEMORIAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7534",
            "district": "3",
            "Block": "195",
            "label": "ST BRITTO'S ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7535",
            "district": "3",
            "Block": "195",
            "label": "SRI RAJALAKSHMI MATRIC HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7536",
            "district": "3",
            "Block": "195",
            "label": "CHSS VELACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7537",
            "district": "3",
            "Block": "195",
            "label": "THE SPASTIC SOCIETY OF TAMIL NADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7538",
            "district": "3",
            "Block": "195",
            "label": "CHSS THARAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7539",
            "district": "3",
            "Block": "195",
            "label": "ARVIND MARTICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7540",
            "district": "3",
            "Block": "195",
            "label": "EVAANS MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7541",
            "district": "3",
            "Block": "195",
            "label": "CHSS THIRUVANMIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7542",
            "district": "3",
            "Block": "195",
            "label": "THE HINDU SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7543",
            "district": "3",
            "Block": "195",
            "label": "CHILDREN'S PARADISE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7544",
            "district": "3",
            "Block": "195",
            "label": "SWABODHINI SCHOOL FOR  SPECIAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7545",
            "district": "3",
            "Block": "195",
            "label": "CSI ST.EBBAS GIRLS HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21175",
            "district": "3",
            "Block": "195",
            "label": "ST. JOHN S SR SEC. SCHOOL & JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22017",
            "district": "3",
            "Block": "195",
            "label": "ST.BEDE'S ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22018",
            "district": "3",
            "Block": "195",
            "label": "ISWARI PRASAD DATTATREYA SPECIAL SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "22336",
            "district": "3",
            "Block": "195",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22685",
            "district": "3",
            "Block": "195",
            "label": "D.A.V. BABA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22971",
            "district": "3",
            "Block": "195",
            "label": "NAVADISHA MONTESSORI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23006",
            "district": "3",
            "Block": "195",
            "label": "NARAYANA OLYMPIAD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "196": [
        {
            "key": "5264",
            "district": "16",
            "Block": "196",
            "label": "PUMS KEECHANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5614",
            "district": "16",
            "Block": "196",
            "label": "MMS KADAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15654",
            "district": "16",
            "Block": "196",
            "label": "GHSS, AZHIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15655",
            "district": "16",
            "Block": "196",
            "label": "GHS ELANKADAMBANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15656",
            "district": "16",
            "Block": "196",
            "label": "GHSS MUTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15657",
            "district": "16",
            "Block": "196",
            "label": "PUMS SANGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15658",
            "district": "16",
            "Block": "196",
            "label": "PUMS,PORAVACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15659",
            "district": "16",
            "Block": "196",
            "label": "PUMS ANTHANAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15660",
            "district": "16",
            "Block": "196",
            "label": "KUMARAN AMS MANJAKOLLAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15661",
            "district": "16",
            "Block": "196",
            "label": "GHSS SIKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15662",
            "district": "16",
            "Block": "196",
            "label": "GHS VADAKKUPOIGAINALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15663",
            "district": "16",
            "Block": "196",
            "label": "GHSS AKKARAIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15664",
            "district": "16",
            "Block": "196",
            "label": "GHS PAPPAKOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15665",
            "district": "16",
            "Block": "196",
            "label": "GHS PUTHUCHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15666",
            "district": "16",
            "Block": "196",
            "label": "PUMS VERGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15667",
            "district": "16",
            "Block": "196",
            "label": "CHIDAMBARANAR AMS ORATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15668",
            "district": "16",
            "Block": "196",
            "label": "PUMS THERKKU POIGAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15669",
            "district": "16",
            "Block": "196",
            "label": "AMS ST Antonys Therkku Poigainallur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15670",
            "district": "16",
            "Block": "196",
            "label": "GHS,VADAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15671",
            "district": "16",
            "Block": "196",
            "label": "PUMS KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15672",
            "district": "16",
            "Block": "196",
            "label": "GHS KURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15673",
            "district": "16",
            "Block": "196",
            "label": "PUMS VADUGACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15674",
            "district": "16",
            "Block": "196",
            "label": "GHS, SEMBIYANMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15675",
            "district": "16",
            "Block": "196",
            "label": "NATIONAL AIDED HIGHER SECONDARY SCHOOL NAGORE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15676",
            "district": "16",
            "Block": "196",
            "label": "MMS MIYAH STREET NAGORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15677",
            "district": "16",
            "Block": "196",
            "label": "MMS PATTINACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15678",
            "district": "16",
            "Block": "196",
            "label": "GHOUTHIA AIDED HIGHER SECONDARY SCHOOL NAGORE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15679",
            "district": "16",
            "Block": "196",
            "label": "Nagore Crescent Matriculation Higher Secondary School for Girls",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15680",
            "district": "16",
            "Block": "196",
            "label": "CHINMAYA VIDYALAYA HSS CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15681",
            "district": "16",
            "Block": "196",
            "label": "MMS NAMBIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15682",
            "district": "16",
            "Block": "196",
            "label": "NAGORE MODERN MATRICULATION HIGHER SECONDARY SCHOOL, NAGORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15683",
            "district": "16",
            "Block": "196",
            "label": "GHS NAGORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15684",
            "district": "16",
            "Block": "196",
            "label": "MMS NELLUKKADAI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15685",
            "district": "16",
            "Block": "196",
            "label": "SRIMATHY PADMAVATHI MATRIC HSS VELIPPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15686",
            "district": "16",
            "Block": "196",
            "label": "NATARAJAN DHAMAYANTHI AIDED HIGHER SECONDARY SCHOOL VELIPPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15687",
            "district": "16",
            "Block": "196",
            "label": "J.JEYA MATRICULATION HIGHER SECONDARY SCHOOL COOKS ROAD VELIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15688",
            "district": "16",
            "Block": "196",
            "label": "Aided Nadarajan Damayanthi High School, Nagapattinam.",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15689",
            "district": "16",
            "Block": "196",
            "label": "ST. ANTONYS AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15690",
            "district": "16",
            "Block": "196",
            "label": "AMS.ST.ANTONYS LOURDU, NAGAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15691",
            "district": "16",
            "Block": "196",
            "label": "NATIONAL AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15692",
            "district": "16",
            "Block": "196",
            "label": "NEHRU MATRICULATION HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15693",
            "district": "16",
            "Block": "196",
            "label": "MGHSS NAGAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15694",
            "district": "16",
            "Block": "196",
            "label": "GSPJ ADHARSH MATRICULATION HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15695",
            "district": "16",
            "Block": "196",
            "label": "CSI AIDED HIGH SCHOOL NAGAPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15696",
            "district": "16",
            "Block": "196",
            "label": "CSI AIDED HIGHER SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15697",
            "district": "16",
            "Block": "196",
            "label": "MMS KALLUKARA STREET.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15698",
            "district": "16",
            "Block": "196",
            "label": "AMRITA VIDYALAYAM SENIOR SECONDARY SCHOOL NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15699",
            "district": "16",
            "Block": "196",
            "label": "AJC PUBLIC SCHOOL CBSE NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15700",
            "district": "16",
            "Block": "196",
            "label": "ST. MICHAEL'S AKADEMY KARUVELANKADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21388",
            "district": "16",
            "Block": "196",
            "label": "GHS KALASAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21520",
            "district": "16",
            "Block": "196",
            "label": "ADW HS AZHINJAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21568",
            "district": "16",
            "Block": "196",
            "label": "KUMARAN AIDED HIGH SCHOOL MANJAKOLLAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21652",
            "district": "16",
            "Block": "196",
            "label": "EGS PILLAY MATRICULATION HIGH SCHOOL KADAMBADI NAGAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23010",
            "district": "16",
            "Block": "196",
            "label": "SIR ISSAC NEWTON MATRIC HR,SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23106",
            "district": "16",
            "Block": "196",
            "label": "E.G.S.P.J INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23107",
            "district": "16",
            "Block": "196",
            "label": "SIR ISSAC NEWTON CBSE  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23251",
            "district": "16",
            "Block": "196",
            "label": "GOVERNMENT HIGH SCHOOL SAMANTHANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23518",
            "district": "16",
            "Block": "196",
            "label": "NAGAPATTINAM DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23884",
            "district": "16",
            "Block": "196",
            "label": "GHS KURICHI",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "23885",
            "district": "16",
            "Block": "196",
            "label": "NAGAPATTINAM DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "197": [
        {
            "key": "4200",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUNGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4261",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4480",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4829",
            "district": "20",
            "Block": "197",
            "label": "HAMEETHIA MIDDLE SCHOOL, GANGAIKONDAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4960",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUMMUDISATHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5331",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AYANSITHANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5523",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5551",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UDAYAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5637",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THETHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5794",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.C.V.MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5902",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6046",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6060",
            "district": "20",
            "Block": "197",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THALAIYADIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19111",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NAINARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19112",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL,  S.SIRUVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19113",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL, KULATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19114",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KARADARNTHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19115",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL,  ATTANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19116",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL,  P.KODIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19117",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, POTTAGAVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19118",
            "district": "20",
            "Block": "197",
            "label": "GOVERNMENT HIGH SCHOOL,  KILIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "198": [
        {
            "key": "2623",
            "district": "6",
            "Block": "198",
            "label": "PUMS BOMMASAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2706",
            "district": "6",
            "Block": "198",
            "label": "PUMS KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3258",
            "district": "6",
            "Block": "198",
            "label": "PUMS MALAIYURKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3315",
            "district": "6",
            "Block": "198",
            "label": "PUMS APPANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3428",
            "district": "6",
            "Block": "198",
            "label": "UNITY MHSS, VETHALAIKARANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3902",
            "district": "6",
            "Block": "198",
            "label": "VALLALAR ARIVALAYAM MATRIC SCHOOL, VALLUVAR NAGAR, DHARMAPURI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4105",
            "district": "6",
            "Block": "198",
            "label": "PUMS E.K.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4250",
            "district": "6",
            "Block": "198",
            "label": "VALLALAR ARIVALAYAM MIDDLE SCHOOL, KURINJI NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4392",
            "district": "6",
            "Block": "198",
            "label": "PUMS ANUMANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4488",
            "district": "6",
            "Block": "198",
            "label": "PUMS EARUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4547",
            "district": "6",
            "Block": "198",
            "label": "PUMS THADANGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4556",
            "district": "6",
            "Block": "198",
            "label": "PUMS PAGALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4579",
            "district": "6",
            "Block": "198",
            "label": "PUMS MALLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4834",
            "district": "6",
            "Block": "198",
            "label": "PUMS KONANGIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5072",
            "district": "6",
            "Block": "198",
            "label": "PUMS CHIKKAGOUNDANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5144",
            "district": "6",
            "Block": "198",
            "label": "PUMS THENGAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5148",
            "district": "6",
            "Block": "198",
            "label": "PUMS KALEGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5240",
            "district": "6",
            "Block": "198",
            "label": "PUMS RAMARKOODAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5294",
            "district": "6",
            "Block": "198",
            "label": "PUMS DHINNAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5306",
            "district": "6",
            "Block": "198",
            "label": "PUMS NEKKUNDHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5373",
            "district": "6",
            "Block": "198",
            "label": "PUMS OMALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5599",
            "district": "6",
            "Block": "198",
            "label": "PUMS MERKATHIYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5612",
            "district": "6",
            "Block": "198",
            "label": "PUMS GETTUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5629",
            "district": "6",
            "Block": "198",
            "label": "PUMS SOLIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5841",
            "district": "6",
            "Block": "198",
            "label": "PUMS KAMALANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5948",
            "district": "6",
            "Block": "198",
            "label": "PUMS GANAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6251",
            "district": "6",
            "Block": "198",
            "label": "PUMS DHANDUKARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6462",
            "district": "6",
            "Block": "198",
            "label": "PUMS MEL BOORUKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6539",
            "district": "6",
            "Block": "198",
            "label": "PUMS PAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6553",
            "district": "6",
            "Block": "198",
            "label": "PUMS B K VALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6561",
            "district": "6",
            "Block": "198",
            "label": "PUMS SAMICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6686",
            "district": "6",
            "Block": "198",
            "label": "PUMS ERRAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9201",
            "district": "6",
            "Block": "198",
            "label": "AAGHSS ADHIYAMANKOTTAI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9202",
            "district": "6",
            "Block": "198",
            "label": "GHSS ADHIYAMANKOTTAI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9203",
            "district": "6",
            "Block": "198",
            "label": "NEW HORIZON SENIORS ACADEMY MHSS, CHETTIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9204",
            "district": "6",
            "Block": "198",
            "label": "GHSS B AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9205",
            "district": "6",
            "Block": "198",
            "label": "GHSS BANDAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9206",
            "district": "6",
            "Block": "198",
            "label": "GHS BEDRAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9207",
            "district": "6",
            "Block": "198",
            "label": "PUMS BOOTHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9208",
            "district": "6",
            "Block": "198",
            "label": "PUMS EACHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9209",
            "district": "6",
            "Block": "198",
            "label": "GHSS ELAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9210",
            "district": "6",
            "Block": "198",
            "label": "PUMS EASALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9211",
            "district": "6",
            "Block": "198",
            "label": "GHSS ERRABAIYANAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9212",
            "district": "6",
            "Block": "198",
            "label": "GHS GETTUHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9213",
            "district": "6",
            "Block": "198",
            "label": "GHS GOOLIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23418",
            "district": "6",
            "Block": "198",
            "label": "National Child Labour Project, Special Training  Center",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "9214",
            "district": "6",
            "Block": "198",
            "label": "GHSS INDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9215",
            "district": "6",
            "Block": "198",
            "label": "PUMS KAMARAJNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9216",
            "district": "6",
            "Block": "198",
            "label": "ST. THOMAS GIRLS HIGH SCHOOL, KOVILUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9217",
            "district": "6",
            "Block": "198",
            "label": "ST JOHNS  HIGH SCHOOL, KOVILUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9218",
            "district": "6",
            "Block": "198",
            "label": "GHSS LALIGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9219",
            "district": "6",
            "Block": "198",
            "label": "GHSS MANIYATHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9220",
            "district": "6",
            "Block": "198",
            "label": "PUMS MUNDASU PURAVADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9221",
            "district": "6",
            "Block": "198",
            "label": "PUMS NADAPPANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9222",
            "district": "6",
            "Block": "198",
            "label": "GHSS NALLAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9223",
            "district": "6",
            "Block": "198",
            "label": "GHS NATHATHAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9224",
            "district": "6",
            "Block": "198",
            "label": "PUMS OSHAHALLIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9225",
            "district": "6",
            "Block": "198",
            "label": "GHSS PALAYAMPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9226",
            "district": "6",
            "Block": "198",
            "label": "PUMS PALLAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9227",
            "district": "6",
            "Block": "198",
            "label": "PUMS CHINNAIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9228",
            "district": "6",
            "Block": "198",
            "label": "GHS PANGUNATHAMKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9229",
            "district": "6",
            "Block": "198",
            "label": "GHS POOVALMADUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9230",
            "district": "6",
            "Block": "198",
            "label": "GHS SANTHARAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23668",
            "district": "6",
            "Block": "198",
            "label": "Sri Jansi High School ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "9231",
            "district": "6",
            "Block": "198",
            "label": "GHS SOMENAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9232",
            "district": "6",
            "Block": "198",
            "label": "GHS T GANIGARAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9233",
            "district": "6",
            "Block": "198",
            "label": "SRI SIDDHI VINAYAKA MHSS, THADANGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9234",
            "district": "6",
            "Block": "198",
            "label": "PUMS THALAVAIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9235",
            "district": "6",
            "Block": "198",
            "label": "GHSS THOPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9236",
            "district": "6",
            "Block": "198",
            "label": "PUMS UMMIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9237",
            "district": "6",
            "Block": "198",
            "label": "GHSS  VENKATTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9238",
            "district": "6",
            "Block": "198",
            "label": "AMALA HR. SEC. SCHOOL, VIMALAPURI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9239",
            "district": "6",
            "Block": "198",
            "label": "GHS AVVAI NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9240",
            "district": "6",
            "Block": "198",
            "label": "GHS BARIGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9241",
            "district": "6",
            "Block": "198",
            "label": "GHS DHATHANAYAKANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9242",
            "district": "6",
            "Block": "198",
            "label": "GHS KOMATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9243",
            "district": "6",
            "Block": "198",
            "label": "GHS NAGARKOODAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9244",
            "district": "6",
            "Block": "198",
            "label": "GHS NARTHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9245",
            "district": "6",
            "Block": "198",
            "label": "GHS PALAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9246",
            "district": "6",
            "Block": "198",
            "label": "GHS RAJAGOLLAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9247",
            "district": "6",
            "Block": "198",
            "label": "PUMS SIVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9248",
            "district": "6",
            "Block": "198",
            "label": "GHS THAMMANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21012",
            "district": "6",
            "Block": "198",
            "label": "ISHA VIDHYA INFOSYS MHSS, SAMYCHETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21013",
            "district": "6",
            "Block": "198",
            "label": "AKSHAYA MHSS, NATHAHALLI, INDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21442",
            "district": "6",
            "Block": "198",
            "label": "GHS B.AGRAHARAM GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21443",
            "district": "6",
            "Block": "198",
            "label": "GHS INDUR GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21444",
            "district": "6",
            "Block": "198",
            "label": "GHS BALAJANGAMANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21661",
            "district": "6",
            "Block": "198",
            "label": "SRI RAMACHANDRA MATRIC SCHOOL, DHOMBARAKAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22075",
            "district": "6",
            "Block": "198",
            "label": "SENTHIL MHSS, ADHIYAMANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22076",
            "district": "6",
            "Block": "198",
            "label": "SENTHIL PUBLIC SCHOOL, ADHIYAMANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23418",
            "district": "6",
            "Block": "198",
            "label": "National Child Labour Project, Special Training  Center",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23418",
            "district": "6",
            "Block": "198",
            "label": "National Child Labour Project, Special Training  Center",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23418",
            "district": "6",
            "Block": "198",
            "label": "National Child Labour Project, Special Training  Center",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23418",
            "district": "6",
            "Block": "198",
            "label": "National Child Labour Project, Special Training  Center",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23886",
            "district": "6",
            "Block": "198",
            "label": "PUMS PAPPAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "199": [
        {
            "key": "4509",
            "district": "5",
            "Block": "199",
            "label": "PUMS MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4654",
            "district": "5",
            "Block": "199",
            "label": "PUMS , KOTHATTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4662",
            "district": "5",
            "Block": "199",
            "label": "PUMS KANDAPPANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4763",
            "district": "5",
            "Block": "199",
            "label": "PUMS SEVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5615",
            "district": "5",
            "Block": "199",
            "label": "PUMS T.PUDAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5708",
            "district": "5",
            "Block": "199",
            "label": "PUMS PENNADAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5756",
            "district": "5",
            "Block": "199",
            "label": "PUMS A.MARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5782",
            "district": "5",
            "Block": "199",
            "label": "ADWS ILANGIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5809",
            "district": "5",
            "Block": "199",
            "label": "PUMS VEPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5814",
            "district": "5",
            "Block": "199",
            "label": "PUMS ADHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5829",
            "district": "5",
            "Block": "199",
            "label": "PUMS NARASINGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6115",
            "district": "5",
            "Block": "199",
            "label": "PUMS PENNADAM EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6312",
            "district": "5",
            "Block": "199",
            "label": "PUMS PASIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6342",
            "district": "5",
            "Block": "199",
            "label": "PUMS SENGAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6389",
            "district": "5",
            "Block": "199",
            "label": "PUMS PA. KOTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6509",
            "district": "5",
            "Block": "199",
            "label": "PUMS VANNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6574",
            "district": "5",
            "Block": "199",
            "label": "PUMS ERAPPAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23694",
            "district": "5",
            "Block": "199",
            "label": "Jayasakthi Nursery & Primary School",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "15390",
            "district": "5",
            "Block": "199",
            "label": "ARUNA HIGHER SECONDARY SCHOOL  , ERAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15391",
            "district": "5",
            "Block": "199",
            "label": "SHREE AMBIKA VIDHYALAYA  MATRICULATION SCHOOL ERAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15392",
            "district": "5",
            "Block": "199",
            "label": "PUMS SATHANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15393",
            "district": "5",
            "Block": "199",
            "label": "GHSS THOLAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15394",
            "district": "5",
            "Block": "199",
            "label": "GHSS POOLAMPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15395",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PELANDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15396",
            "district": "5",
            "Block": "199",
            "label": "ADW GHS THEEVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15397",
            "district": "5",
            "Block": "199",
            "label": "PUMS KILIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15398",
            "district": "5",
            "Block": "199",
            "label": "GHS VADAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15399",
            "district": "5",
            "Block": "199",
            "label": "PUMS CHINNAKOSAPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15400",
            "district": "5",
            "Block": "199",
            "label": "PUMS MURUGANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15401",
            "district": "5",
            "Block": "199",
            "label": "AMS VENKARUMBUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15402",
            "district": "5",
            "Block": "199",
            "label": "PUMS MARUTHATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15403",
            "district": "5",
            "Block": "199",
            "label": "ADW GHS SIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15404",
            "district": "5",
            "Block": "199",
            "label": "GHS MANNAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15405",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT HIGH SCHOOL , A.CHITTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15406",
            "district": "5",
            "Block": "199",
            "label": "ADWS NARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15407",
            "district": "5",
            "Block": "199",
            "label": "AMS A.AGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15408",
            "district": "5",
            "Block": "199",
            "label": "GHSS VEPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15409",
            "district": "5",
            "Block": "199",
            "label": "AYYANAR MA TRIC HR SEC  SCHOOL,  VEPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15410",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT (MODEL ) HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15411",
            "district": "5",
            "Block": "199",
            "label": "GHS KEEZHAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15412",
            "district": "5",
            "Block": "199",
            "label": "ADWHSS GIRLS SEPPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15413",
            "district": "5",
            "Block": "199",
            "label": "ADWHSS BOYS SEPPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15414",
            "district": "5",
            "Block": "199",
            "label": "PUMS NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15415",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15416",
            "district": "5",
            "Block": "199",
            "label": "PUMS VALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15417",
            "district": "5",
            "Block": "199",
            "label": "PUMS NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15418",
            "district": "5",
            "Block": "199",
            "label": "GHSS KOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15419",
            "district": "5",
            "Block": "199",
            "label": "GHS - SATHIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15420",
            "district": "5",
            "Block": "199",
            "label": "PUMS VARAMBANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15421",
            "district": "5",
            "Block": "199",
            "label": "GHS PERIYANESALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15422",
            "district": "5",
            "Block": "199",
            "label": "AMS KO.KOTHANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15423",
            "district": "5",
            "Block": "199",
            "label": "ADWS MALIGAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15424",
            "district": "5",
            "Block": "199",
            "label": "PUMS PENNADAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15425",
            "district": "5",
            "Block": "199",
            "label": "BABY MATRICULATION SCHOOL PENNADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15426",
            "district": "5",
            "Block": "199",
            "label": "GOVERNMENT ( GIRLS)HIGHER SECONDARY SCHOOL, PENNADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15427",
            "district": "5",
            "Block": "199",
            "label": "GHSS BOYS PENNADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15428",
            "district": "5",
            "Block": "199",
            "label": "LOTUS INTERNATIONAL MATRIC.HR.SEC. SCHOOL, PENNADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15429",
            "district": "5",
            "Block": "199",
            "label": "BHARATHI MATRICULATION SCHOOL PENNADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15430",
            "district": "5",
            "Block": "199",
            "label": "KGBV , PERIYANESALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15431",
            "district": "5",
            "Block": "199",
            "label": "PUMS SOZHANNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21031",
            "district": "5",
            "Block": "199",
            "label": "BHAVANI VIDYASHRAM MATRICULATION HR SEC  SCHOOL KANDAPPANKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21097",
            "district": "5",
            "Block": "199",
            "label": "PRIME INTERNATIONAL SCHOOL VEPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21386",
            "district": "5",
            "Block": "199",
            "label": "GGHS-NALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22205",
            "district": "5",
            "Block": "199",
            "label": "GHS-PE.POOVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22206",
            "district": "5",
            "Block": "199",
            "label": "SRI BALAJI HIGHER SECONDARY SCHOOL,  NALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22555",
            "district": "5",
            "Block": "199",
            "label": "SRI RAMAKRISHNA MATRIC HIGHER SECONDARY SCHOOL - N.NARAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23042",
            "district": "5",
            "Block": "199",
            "label": "JAYAPRIYA VIDYALAYA MATRICULATION SCHOOL, THIRUPPAYAR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23887",
            "district": "5",
            "Block": "199",
            "label": "GHSS VEPPUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23888",
            "district": "5",
            "Block": "199",
            "label": "PUMS NALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23889",
            "district": "5",
            "Block": "199",
            "label": "GHSS KOVILUR",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "200": [
        {
            "key": "1274",
            "district": "17",
            "Block": "200",
            "label": "PUMS ARIYAGOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1423",
            "district": "17",
            "Block": "200",
            "label": "PUMS NARAIKINARUPIRIVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1440",
            "district": "17",
            "Block": "200",
            "label": "PUMS CHINNA VARAGURCOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1508",
            "district": "17",
            "Block": "200",
            "label": "PUMS VELLAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1528",
            "district": "17",
            "Block": "200",
            "label": "PUMS NAMAGIRIPETTAI-I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2236",
            "district": "17",
            "Block": "200",
            "label": "PUMS MOOLAPALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2371",
            "district": "17",
            "Block": "200",
            "label": "PUMS ESWARAMURTHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2599",
            "district": "17",
            "Block": "200",
            "label": "PUMS THANDAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2603",
            "district": "17",
            "Block": "200",
            "label": "SRI VANI MATRICULATION HIGHER SECONDARY SCHOOL THOPPAPPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2641",
            "district": "17",
            "Block": "200",
            "label": "PUMS KARIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2660",
            "district": "17",
            "Block": "200",
            "label": "PUMS PERIYACOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3022",
            "district": "17",
            "Block": "200",
            "label": "PUMS ODUVANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3279",
            "district": "17",
            "Block": "200",
            "label": "KPM HI TECH MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3418",
            "district": "17",
            "Block": "200",
            "label": "TRINITY VIDHYALAYA MATRICULATION SCHOOL, MULLUKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4452",
            "district": "17",
            "Block": "200",
            "label": "PUMS KONGALAMMAN KOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4622",
            "district": "17",
            "Block": "200",
            "label": "PUMS ONPATHAMPALIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4865",
            "district": "17",
            "Block": "200",
            "label": "KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL, NAMAGIRIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5054",
            "district": "17",
            "Block": "200",
            "label": "PUMS VELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5068",
            "district": "17",
            "Block": "200",
            "label": "PUMS NARAIKINARU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5278",
            "district": "17",
            "Block": "200",
            "label": "PUMS SEERAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5908",
            "district": "17",
            "Block": "200",
            "label": "PUMS URAMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6362",
            "district": "17",
            "Block": "200",
            "label": "SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL, AYILPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11879",
            "district": "17",
            "Block": "200",
            "label": "GHS R PUDUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11880",
            "district": "17",
            "Block": "200",
            "label": "GHS THANNEERPANDALKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11881",
            "district": "17",
            "Block": "200",
            "label": "GBHSS NAMAGIRIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11882",
            "district": "17",
            "Block": "200",
            "label": "GGHSS NAMAGIRIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11883",
            "district": "17",
            "Block": "200",
            "label": "GHSS THO JEDARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11884",
            "district": "17",
            "Block": "200",
            "label": "GHS AYILPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11885",
            "district": "17",
            "Block": "200",
            "label": "GHSS UDAIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11886",
            "district": "17",
            "Block": "200",
            "label": "SRI SWAMY MATRICULATION HIGHER SECONDARY SCHOOL, SAMBAPALIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11887",
            "district": "17",
            "Block": "200",
            "label": "SRI RAM VINAYAKA SCHOOL, PILLIPPAKKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11888",
            "district": "17",
            "Block": "200",
            "label": "GTR (G) HSS MULLUKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11889",
            "district": "17",
            "Block": "200",
            "label": "GTR BOYS HR SEC SCHOOL  MULLUKKURCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11890",
            "district": "17",
            "Block": "200",
            "label": "GHSS THIMMANNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11891",
            "district": "17",
            "Block": "200",
            "label": "GHSS MANGALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21267",
            "district": "17",
            "Block": "200",
            "label": "GREEN WORLD EXCEL SCHOOL MOOLAPALLIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21462",
            "district": "17",
            "Block": "200",
            "label": "GHS SINGILIYANCOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "201": [
        {
            "key": "1462",
            "district": "17",
            "Block": "201",
            "label": "PUMS MARUTHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2332",
            "district": "17",
            "Block": "201",
            "label": "KONGUNADU MATRIC HIGHER SECONDARY SCHOOL, NALLIPALAYAM, NAMAKKAL DT - 637003",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2857",
            "district": "17",
            "Block": "201",
            "label": "SURABI MATRICULATION HIGHER SECONDARY SCHOOL, NGGO'S COLONY, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3021",
            "district": "17",
            "Block": "201",
            "label": "PUMS KAVETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3369",
            "district": "17",
            "Block": "201",
            "label": "TRINITY INTERNATIONAL SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4302",
            "district": "17",
            "Block": "201",
            "label": "FATHIMA RC MIDDLE SCHOOL NAMAKKAL(AIDED)",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5174",
            "district": "17",
            "Block": "201",
            "label": "PUMS KONDICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5645",
            "district": "17",
            "Block": "201",
            "label": "NAMAKKAL KONGU MATRIC HIGHER SECONDARY SCHOOL , KEERAMBUR , NAMAKKAL DT -637207",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6269",
            "district": "17",
            "Block": "201",
            "label": "GMS VAGURAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6441",
            "district": "17",
            "Block": "201",
            "label": "TRINITY ACADEMY MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6729",
            "district": "17",
            "Block": "201",
            "label": "GREEN PARK INTERNATIONAL SCHOOL BODHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11748",
            "district": "17",
            "Block": "201",
            "label": "PUMS KATHAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11749",
            "district": "17",
            "Block": "201",
            "label": "GHS MARURPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11750",
            "district": "17",
            "Block": "201",
            "label": "PUMS MUDALAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23395",
            "district": "17",
            "Block": "201",
            "label": "Anna-Nehru Matric Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11751",
            "district": "17",
            "Block": "201",
            "label": "MMS RAMAPURAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11752",
            "district": "17",
            "Block": "201",
            "label": "PUMS MUDALAIPATTIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11753",
            "district": "17",
            "Block": "201",
            "label": "NAMMALAVAR AIDED MIDDLE SCHOOL,NAMAKKAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11754",
            "district": "17",
            "Block": "201",
            "label": "MHS FORT NAMAKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11755",
            "district": "17",
            "Block": "201",
            "label": "SBM AIDED HIGH SCHOOL NAMAKKAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11756",
            "district": "17",
            "Block": "201",
            "label": "SELVAM MATRIC HIGHER SECONDARY SCHOOL, 3/1143 TRICHY ROAD, NAMAKKAL DT - 637001",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11757",
            "district": "17",
            "Block": "201",
            "label": "MARUTHI VIDDYALAYA  MATRICULATION SCHOOL, 135/1 PATHI NAGAR, SALEM ROAD, NAMAKKAL DT -637001",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11758",
            "district": "17",
            "Block": "201",
            "label": "GOVT HR SEC SCHOOL NAMAKKAL (SOUTH)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11759",
            "district": "17",
            "Block": "201",
            "label": "GGHSS  NAMAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11760",
            "district": "17",
            "Block": "201",
            "label": "KANDASWAMI KANDAR MATRICULATION HIGHER SECONDARY SCHOOL, GANDHI NAGAR, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11761",
            "district": "17",
            "Block": "201",
            "label": "JACK & JILL MATRICULATION SCHOOL,RAMAPURAM PUDUR, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11762",
            "district": "17",
            "Block": "201",
            "label": "SELVAM HIGHER SECONDARY SCHOOL MUDALAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11763",
            "district": "17",
            "Block": "201",
            "label": "GHSS  NAMAKKAL (NORTH )",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11764",
            "district": "17",
            "Block": "201",
            "label": "GHS AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11765",
            "district": "17",
            "Block": "201",
            "label": "GREEN PARK MATRICULATION HIGHER SECONDARY SCHOOL BODHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11766",
            "district": "17",
            "Block": "201",
            "label": "SRI VINAYAGA  HIGHER SECONDARY  SCHOOL PORASAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11767",
            "district": "17",
            "Block": "201",
            "label": "GHS THALIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11768",
            "district": "17",
            "Block": "201",
            "label": "PUMS THINDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11769",
            "district": "17",
            "Block": "201",
            "label": "GHSS ERNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11770",
            "district": "17",
            "Block": "201",
            "label": "GHS KEERAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11771",
            "district": "17",
            "Block": "201",
            "label": "PUMS KEELSATHAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11772",
            "district": "17",
            "Block": "201",
            "label": "GHSS KONUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11773",
            "district": "17",
            "Block": "201",
            "label": "The Navodaya Academy Senior Secondary School - CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11774",
            "district": "17",
            "Block": "201",
            "label": "GHS THOTTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11775",
            "district": "17",
            "Block": "201",
            "label": "DR.M.S. UDAYAMURTHY SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11776",
            "district": "17",
            "Block": "201",
            "label": "KURINJI MATRIC HIGHER SECONDARY SCHOOL KAVETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11777",
            "district": "17",
            "Block": "201",
            "label": "KURINJI HSS KAVETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11778",
            "district": "17",
            "Block": "201",
            "label": "GHS BODHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11779",
            "district": "17",
            "Block": "201",
            "label": "KURINJI SENIOR SECONDARY SCHOOL KAVETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11780",
            "district": "17",
            "Block": "201",
            "label": "GHS PERIYAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11781",
            "district": "17",
            "Block": "201",
            "label": "THE SPECTRUM ACADEMY MATRICULATION SCHOOL, 6/258 TRICHY ROAD, NAMAKKAL DT -637001",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11782",
            "district": "17",
            "Block": "201",
            "label": "JEIVIKASS HR.SEC SCHOOL, MOHANUR ROAD, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11783",
            "district": "17",
            "Block": "201",
            "label": "NATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11784",
            "district": "17",
            "Block": "201",
            "label": "PGP MATRICULATION HIGHER SECONDARY SCHOOL, PALANI NAGAR, VETTAMBADI, NAMAKKAL DT -637405",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11785",
            "district": "17",
            "Block": "201",
            "label": "PGP INTERNATIONAL SCHOOL ,VETTAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11786",
            "district": "17",
            "Block": "201",
            "label": "GHS VETTAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11787",
            "district": "17",
            "Block": "201",
            "label": "PUMS CHINNAVEPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11788",
            "district": "17",
            "Block": "201",
            "label": "CHRIST KING MATRICULATION HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21666",
            "district": "17",
            "Block": "201",
            "label": "THAQWA MATRICULATION SCHOOL , NALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22120",
            "district": "17",
            "Block": "201",
            "label": "SPECTRUM LIFE SCHOOL, NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22445",
            "district": "17",
            "Block": "201",
            "label": "PARK PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23254",
            "district": "17",
            "Block": "201",
            "label": "GOVERNMENT HIGH SCHOOL, CHINNAMUTHALAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23890",
            "district": "17",
            "Block": "201",
            "label": "Namakkal District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "202": [
        {
            "key": "495",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGH SCHOOL, PATTIMANIAKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "826",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "877",
            "district": "8",
            "Block": "202",
            "label": "KOMARASAMY GOUNDER MATRIC HSS, VETTAIYAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "904",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUMANATHAM PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1007",
            "district": "8",
            "Block": "202",
            "label": "KUMUTHA MATRIC HIGHER SEC SCHOOL, NAMBIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1319",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, L.SANARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1421",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PILIAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1436",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GANDHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1441",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLAKOILPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1570",
            "district": "8",
            "Block": "202",
            "label": "SHANGUINE MATRIC HIGHER SECONDARY SCHOOL, KURUMANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1602",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, IRUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1626",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUTHIRAIKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1747",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, E.CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1749",
            "district": "8",
            "Block": "202",
            "label": "PUMS, ELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1919",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OTTARKARATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1980",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  N.VELLALAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2096",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EMMAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2099",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2363",
            "district": "8",
            "Block": "202",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4635",
            "district": "8",
            "Block": "202",
            "label": "GWMS,E.RAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5209",
            "district": "8",
            "Block": "202",
            "label": "AALAYAM MATRICULATION SCHOOL, NAMBIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6276",
            "district": "8",
            "Block": "202",
            "label": "P.K. MATRICULATION SCHOOL, SELLIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12281",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGH SCHOOL, VETTAIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12282",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, SAVAKATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12283",
            "district": "8",
            "Block": "202",
            "label": "GHSS, KADATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12284",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGH SCHOOL, P.KARATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12285",
            "district": "8",
            "Block": "202",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOODAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12286",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGHER SEC. SCHOOL, KURUMANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12287",
            "district": "8",
            "Block": "202",
            "label": "GOVT. BOYS HIGHER SEC. SCHOOL, NAMBIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12288",
            "district": "8",
            "Block": "202",
            "label": "GOVT. GIRLS HIGHER SEC. SCHOOL, NAMBIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12289",
            "district": "8",
            "Block": "202",
            "label": "KAMARAJ HIGHER SEC. SCHOOL, NAMBIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12290",
            "district": "8",
            "Block": "202",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MALAYAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12291",
            "district": "8",
            "Block": "202",
            "label": "GOVT. MODEL HIGHER SEC.SCHOOL, GETTICHEVIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12292",
            "district": "8",
            "Block": "202",
            "label": "KASTURIBA GANDHI BALIKA VIDYALAYA, SOKKUMARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12293",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGHER SEC. SCHOOL, VEMANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12294",
            "district": "8",
            "Block": "202",
            "label": "GOVT. HIGH SCHOOL, SEMMAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12295",
            "district": "8",
            "Block": "202",
            "label": "MODEL HIGHER SEC. SCHOOL, PATTIMANIYAKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21025",
            "district": "8",
            "Block": "202",
            "label": "SHREE VIKAS MATRICULATION SCHOOL, K.METTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22466",
            "district": "8",
            "Block": "202",
            "label": "GOVERNMENT HIGH SCHOOL, KUMMAKKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22467",
            "district": "8",
            "Block": "202",
            "label": "GOVERNMENT HIGH SCHOOL, POTHAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22870",
            "district": "8",
            "Block": "202",
            "label": "GHS, SUNDAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23891",
            "district": "8",
            "Block": "202",
            "label": "EDISON INTERNATIONAL SCHOOL, VEMANDAMPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "203": [
        {
            "key": "1974",
            "district": "22",
            "Block": "203",
            "label": "SRI SAKTHI MATRICULATION SCHOOL,VANAVASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2300",
            "district": "22",
            "Block": "203",
            "label": "SRI NACHIYAR MATRIC SCHOOL, MALAYAMPALAYAM, JALAGANDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2456",
            "district": "22",
            "Block": "203",
            "label": "MINERRVA MATRIC HIGHER SECONDARY SCHOOL, KATTAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2870",
            "district": "22",
            "Block": "203",
            "label": "ISHA VIDHYA RAMANIYAM MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4212",
            "district": "22",
            "Block": "203",
            "label": "MALCO VIDYALAYA MATRIC HR. SEC. SCHOOL, METTUR DAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6640",
            "district": "22",
            "Block": "203",
            "label": "KALAIMAGAL MATRIC HR. SEC. SCHOOL,NANGAVALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11332",
            "district": "22",
            "Block": "203",
            "label": "GHS KUNJANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11333",
            "district": "22",
            "Block": "203",
            "label": "RAMESH VIDHAYASHRAM MATRIC HR. SEC. SCHOOL, KUNJANDIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11334",
            "district": "22",
            "Block": "203",
            "label": "GHSS PUDUCHAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11335",
            "district": "22",
            "Block": "203",
            "label": "NALANDA MATRICULATION SCHOOL, BHARATHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11336",
            "district": "22",
            "Block": "203",
            "label": "PUMS ANDIKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11337",
            "district": "22",
            "Block": "203",
            "label": "PUMS VEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11338",
            "district": "22",
            "Block": "203",
            "label": "GHS KONUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11339",
            "district": "22",
            "Block": "203",
            "label": "GHSS SANDAIDHANAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11340",
            "district": "22",
            "Block": "203",
            "label": "PUMS VEERAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11341",
            "district": "22",
            "Block": "203",
            "label": "PUMS PERIYA VANAVASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11342",
            "district": "22",
            "Block": "203",
            "label": "PUMS SAPPANIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11343",
            "district": "22",
            "Block": "203",
            "label": "GBHSS VANAVASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11344",
            "district": "22",
            "Block": "203",
            "label": "PUMS KARATTUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11345",
            "district": "22",
            "Block": "203",
            "label": "PUMS NANGAVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11346",
            "district": "22",
            "Block": "203",
            "label": "GGHSS NANGAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11347",
            "district": "22",
            "Block": "203",
            "label": "GHS CHINNASORAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11348",
            "district": "22",
            "Block": "203",
            "label": "PUMS PERIYASORAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11349",
            "district": "22",
            "Block": "203",
            "label": "PUMS SEERANGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11350",
            "district": "22",
            "Block": "203",
            "label": "PUMS PONNAPPAN VALAVU A.V.COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11351",
            "district": "22",
            "Block": "203",
            "label": "PUMS CHINNANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11352",
            "district": "22",
            "Block": "203",
            "label": "PUMS KUPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11353",
            "district": "22",
            "Block": "203",
            "label": "PUMS SOORAPPALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11354",
            "district": "22",
            "Block": "203",
            "label": "PUMS NORACHIVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11355",
            "district": "22",
            "Block": "203",
            "label": "PUMS KATTINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11356",
            "district": "22",
            "Block": "203",
            "label": "PUMS SOURIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11357",
            "district": "22",
            "Block": "203",
            "label": "GHSS, SAVURIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11358",
            "district": "22",
            "Block": "203",
            "label": "PUMS KAPPARATTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11359",
            "district": "22",
            "Block": "203",
            "label": "PUMS PODAIYAN THERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11360",
            "district": "22",
            "Block": "203",
            "label": "PUMS VANGALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11361",
            "district": "22",
            "Block": "203",
            "label": "GGHSS JALAKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11362",
            "district": "22",
            "Block": "203",
            "label": "GBHSS JALAKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11363",
            "district": "22",
            "Block": "203",
            "label": "PUMS S. PUDUREDDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11364",
            "district": "22",
            "Block": "203",
            "label": "PUMS THENGALVARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11365",
            "district": "22",
            "Block": "203",
            "label": "PUMS CHINNAKKAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11366",
            "district": "22",
            "Block": "203",
            "label": "GHS SALEM CAMP",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11367",
            "district": "22",
            "Block": "203",
            "label": "JAWAHAR HS, KARUMALAIKOODAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11368",
            "district": "22",
            "Block": "203",
            "label": "J.P. MATRICULATION SCHOOL, ANNALMIN NAGAR, METTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11369",
            "district": "22",
            "Block": "203",
            "label": "PUMS THURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11370",
            "district": "22",
            "Block": "203",
            "label": "K.G.B.V. NANGAVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21211",
            "district": "22",
            "Block": "203",
            "label": "MODEL SCHOOL, NANGAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22113",
            "district": "22",
            "Block": "203",
            "label": "VEDANTA VIDYALAYA  METTUR DAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23232",
            "district": "22",
            "Block": "203",
            "label": "GHS MOOLAKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "204": [
        {
            "key": "785",
            "district": "30",
            "Block": "204",
            "label": "JN MS SUBRAMANIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2198",
            "district": "30",
            "Block": "204",
            "label": "ST. FRANCIS MATRICULATION  HIGHER SECONDARY SCHOOL, NAMBINAGAR, NANGUNERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2365",
            "district": "30",
            "Block": "204",
            "label": "PUMS PUTHUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2621",
            "district": "30",
            "Block": "204",
            "label": "PU MS NANGUNERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4599",
            "district": "30",
            "Block": "204",
            "label": "CMS MS AMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5003",
            "district": "30",
            "Block": "204",
            "label": "PUMS CHINTHAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5903",
            "district": "30",
            "Block": "204",
            "label": "TDTA MS MEENAKSHINATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6169",
            "district": "30",
            "Block": "204",
            "label": "PU MS PATTARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6227",
            "district": "30",
            "Block": "204",
            "label": "GOVT MS KOONTHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19761",
            "district": "30",
            "Block": "204",
            "label": "CMS MS MARUTHAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19762",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS MARUTHAKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19763",
            "district": "30",
            "Block": "204",
            "label": "CMS MS ALWANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19764",
            "district": "30",
            "Block": "204",
            "label": "CMS  MS PARUTHIPADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19765",
            "district": "30",
            "Block": "204",
            "label": "GOVT HS KARANTHANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19766",
            "district": "30",
            "Block": "204",
            "label": "ST.JOSEPH'S MS THAMBUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19767",
            "district": "30",
            "Block": "204",
            "label": "GGHSS NANGUNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19768",
            "district": "30",
            "Block": "204",
            "label": "GOVT (B) HSS NANGUNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19769",
            "district": "30",
            "Block": "204",
            "label": "SRI RAMANUJA MAT HS NANGUNERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19770",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS MOOLAIKARAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19771",
            "district": "30",
            "Block": "204",
            "label": "St. ALOYSIOUS MS CHINTHAMANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19772",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS MUNANJIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19773",
            "district": "30",
            "Block": "204",
            "label": "ST. ANTONY'S HIGH SCHOOL. SOMANATHAPERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19774",
            "district": "30",
            "Block": "204",
            "label": "TDTA MS KALUVOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19775",
            "district": "30",
            "Block": "204",
            "label": "ST.ANTONY'S MS KARANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19776",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS THALAPATHYSAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19777",
            "district": "30",
            "Block": "204",
            "label": "GOVT HIGH SCHOOL THIRUVENGADANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19778",
            "district": "30",
            "Block": "204",
            "label": "TDTA MS PARAPPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19779",
            "district": "30",
            "Block": "204",
            "label": "GOVT HSS PARAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19780",
            "district": "30",
            "Block": "204",
            "label": "ST JOHN'S MATRICULATION SCHOOL, PARAPPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19781",
            "district": "30",
            "Block": "204",
            "label": "RC MS THINAIYOORANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19782",
            "district": "30",
            "Block": "204",
            "label": "GOVT HS KARIYANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19783",
            "district": "30",
            "Block": "204",
            "label": "MG RURAL HS VENKATRAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19784",
            "district": "30",
            "Block": "204",
            "label": "NADAR MS PATTARAIKATTIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19785",
            "district": "30",
            "Block": "204",
            "label": "ST. MARY'S  HSS MANNARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19786",
            "district": "30",
            "Block": "204",
            "label": "KENDRA VIDYALAIYA HSS, KENDRA VIDYALAIYA HSS, S.V.NARAYANAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19787",
            "district": "30",
            "Block": "204",
            "label": "GHS, NORTH VIJAYANARAYANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19788",
            "district": "30",
            "Block": "204",
            "label": "RC MS AZHAGAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19789",
            "district": "30",
            "Block": "204",
            "label": "SENTHIL ANDAVAN ARULNERI HIGH SCHOOL VIJAYA ACHAMPADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19790",
            "district": "30",
            "Block": "204",
            "label": "A.V JOSEPH GHSS, ITTAMOZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21224",
            "district": "30",
            "Block": "204",
            "label": "ADW GHSS KADAMBANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21231",
            "district": "30",
            "Block": "204",
            "label": "REACH MHSS MOOLAIKARAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21620",
            "district": "30",
            "Block": "204",
            "label": "ST.  ANN'S  MATRICULATION SCHOOL, ELANGULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22730",
            "district": "30",
            "Block": "204",
            "label": "NELLAI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23259",
            "district": "30",
            "Block": "204",
            "label": "GHS SHENBAGARAMANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "205": [
        {
            "key": "4560",
            "district": "35",
            "Block": "205",
            "label": "PUMS,KOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4955",
            "district": "35",
            "Block": "205",
            "label": "PUMS,KURUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5151",
            "district": "35",
            "Block": "205",
            "label": "PUMS, KOILTHIRUMALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5363",
            "district": "35",
            "Block": "205",
            "label": "PUMS,SIRUPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5867",
            "district": "35",
            "Block": "205",
            "label": "PUMS, AATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5946",
            "district": "35",
            "Block": "205",
            "label": "PUMS ,POZHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5977",
            "district": "35",
            "Block": "205",
            "label": "PUMS,PAVATTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6109",
            "district": "35",
            "Block": "205",
            "label": "PUMS SERUVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6199",
            "district": "35",
            "Block": "205",
            "label": "PUMS,INJIGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6287",
            "district": "35",
            "Block": "205",
            "label": "PUMS,THALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6333",
            "district": "35",
            "Block": "205",
            "label": "PUMS,ALANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6571",
            "district": "35",
            "Block": "205",
            "label": "PUMS,VADAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6578",
            "district": "35",
            "Block": "205",
            "label": "AMS,THIRUKKOTTARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16107",
            "district": "35",
            "Block": "205",
            "label": "GHS, KEERANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16108",
            "district": "35",
            "Block": "205",
            "label": "ADWHSS, NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16109",
            "district": "35",
            "Block": "205",
            "label": "UAHS,MASH,PAVATTAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16110",
            "district": "35",
            "Block": "205",
            "label": "GHSS , KADUVANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16111",
            "district": "35",
            "Block": "205",
            "label": "PUMS,THIRUMEEACHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16112",
            "district": "35",
            "Block": "205",
            "label": "PUMS, PANDARAVADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16113",
            "district": "35",
            "Block": "205",
            "label": "GHS, KOLLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16114",
            "district": "35",
            "Block": "205",
            "label": "UAMS AL-ISLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16115",
            "district": "35",
            "Block": "205",
            "label": "AMS, POONTHOTTAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16116",
            "district": "35",
            "Block": "205",
            "label": "GHSS, POONTHOTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16117",
            "district": "35",
            "Block": "205",
            "label": "UAMHSS, LALITHAMBIKAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16118",
            "district": "35",
            "Block": "205",
            "label": "PUMS,MAHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16119",
            "district": "35",
            "Block": "205",
            "label": "GHSS SRIVANCHIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16120",
            "district": "35",
            "Block": "205",
            "label": "GHSS,ACHUTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16121",
            "district": "35",
            "Block": "205",
            "label": "AHS, MUDIKONDAN",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16122",
            "district": "35",
            "Block": "205",
            "label": "GHSS   ANAIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16123",
            "district": "35",
            "Block": "205",
            "label": "GHS, MAPPILAIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16124",
            "district": "35",
            "Block": "205",
            "label": "GHSS, PANANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16125",
            "district": "35",
            "Block": "205",
            "label": "PUMS,MOONGILGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16126",
            "district": "35",
            "Block": "205",
            "label": "AMS-THANGAM, NALLAMANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16127",
            "district": "35",
            "Block": "205",
            "label": "UAMHSS, VALLALAR GURUKULAM NANNI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16128",
            "district": "35",
            "Block": "205",
            "label": "GGHSS, NANNILAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16129",
            "district": "35",
            "Block": "205",
            "label": "GBHSS, NANNILAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16130",
            "district": "35",
            "Block": "205",
            "label": "GHS, SANNANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16131",
            "district": "35",
            "Block": "205",
            "label": "UAHS,SRIVIDHYA  MATRICULATION ,PERALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16132",
            "district": "35",
            "Block": "205",
            "label": "UAMHSS SANKARA PERALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16133",
            "district": "35",
            "Block": "205",
            "label": "GGHSS ,PERALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16134",
            "district": "35",
            "Block": "205",
            "label": "GHSS PERALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22571",
            "district": "35",
            "Block": "205",
            "label": "GHS,PILLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22572",
            "district": "35",
            "Block": "205",
            "label": "GHS,MENANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22885",
            "district": "35",
            "Block": "205",
            "label": "GHS Ubhayavethanthapuram",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "206": [
        {
            "key": "3831",
            "district": "38",
            "Block": "206",
            "label": "ASMA MATRICULATION HIGH SCHOOL VEERACHOLAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18850",
            "district": "38",
            "Block": "206",
            "label": "PUMS THATCHANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18851",
            "district": "38",
            "Block": "206",
            "label": "GOVERNMENT HIGH SCHOOL PILLAIYARKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18852",
            "district": "38",
            "Block": "206",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  THIMMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18853",
            "district": "38",
            "Block": "206",
            "label": "GOVT HIGH SCHOOL PULVOIKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18854",
            "district": "38",
            "Block": "206",
            "label": "P.K.M.I. GHSS A MUKKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18855",
            "district": "38",
            "Block": "206",
            "label": "KASTURBA GANDHI BALIKA VIDYALAYA, A.MUKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18856",
            "district": "38",
            "Block": "206",
            "label": "GHS SIRUVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18857",
            "district": "38",
            "Block": "206",
            "label": "GOVERNMENT HIGH SCHOOL, NALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18858",
            "district": "38",
            "Block": "206",
            "label": "GHS ULUTHIMADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18859",
            "district": "38",
            "Block": "206",
            "label": "GHSS KATTANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18860",
            "district": "38",
            "Block": "206",
            "label": "GHS IRUNCHIRAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18861",
            "district": "38",
            "Block": "206",
            "label": "GHSS ULAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18862",
            "district": "38",
            "Block": "206",
            "label": "PUMS CHOTTAMURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18863",
            "district": "38",
            "Block": "206",
            "label": "MARUTHUPANDIAR GOVERNMENT HR SEC SCHOOL, NARIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18864",
            "district": "38",
            "Block": "206",
            "label": "PUMS  MARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18865",
            "district": "38",
            "Block": "206",
            "label": "PUMS. SETHURAYANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18866",
            "district": "38",
            "Block": "206",
            "label": "KASTURBA GANDHI BALIKA VIDYALAYA, NARIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18867",
            "district": "38",
            "Block": "206",
            "label": "PUMS. MANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18868",
            "district": "38",
            "Block": "206",
            "label": "PUMS. T.VELANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18869",
            "district": "38",
            "Block": "206",
            "label": "PUMS. KANAYAMARITHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18870",
            "district": "38",
            "Block": "206",
            "label": "GOVERNMENT HIGH SCHOOL , S.VALLAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18871",
            "district": "38",
            "Block": "206",
            "label": "PUMS MELAPARUTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18872",
            "district": "38",
            "Block": "206",
            "label": "PUMS VARISAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18873",
            "district": "38",
            "Block": "206",
            "label": "PUMS VEERACHOLAN WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18874",
            "district": "38",
            "Block": "206",
            "label": "GHSS VEERACHOLAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18875",
            "district": "38",
            "Block": "206",
            "label": "PUMS OTTANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18876",
            "district": "38",
            "Block": "206",
            "label": "PUMS MINAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18877",
            "district": "38",
            "Block": "206",
            "label": "PUMS CHELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18878",
            "district": "38",
            "Block": "206",
            "label": "PUMS SEMPONNERUNCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18879",
            "district": "38",
            "Block": "206",
            "label": "PUMS. MUTHANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18880",
            "district": "38",
            "Block": "206",
            "label": "PUMS V ALANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18881",
            "district": "38",
            "Block": "206",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  SULLANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18882",
            "district": "38",
            "Block": "206",
            "label": "GHSS ANAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18883",
            "district": "38",
            "Block": "206",
            "label": "KASTURBA GANDHI BALIKA VIDYALAYA, ANAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18884",
            "district": "38",
            "Block": "206",
            "label": "PUMS RENGAIYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18885",
            "district": "38",
            "Block": "206",
            "label": "GHSS, PANAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18886",
            "district": "38",
            "Block": "206",
            "label": "GHSS ILUPPAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18887",
            "district": "38",
            "Block": "206",
            "label": "PUMS AMMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18888",
            "district": "38",
            "Block": "206",
            "label": "PUMS. UDAYASERVAIKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18889",
            "district": "38",
            "Block": "206",
            "label": "GHS MUTHURAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18890",
            "district": "38",
            "Block": "206",
            "label": "PUMS KUMILANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23892",
            "district": "38",
            "Block": "206",
            "label": "PUPS. THIRUMANICKANENTHAL",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "207": [
        {
            "key": "3250",
            "district": "7",
            "Block": "207",
            "label": "RAMSUN'S MATRIC HIGER SECONDARY SCHOOL ,MEYYAMPATTY,NATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3506",
            "district": "7",
            "Block": "207",
            "label": "RC MATRICULATION HIGHER SECONDARY SCHOOL,NATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4007",
            "district": "7",
            "Block": "207",
            "label": "SFS MATRICULATION SCHOOL ,SENDURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4377",
            "district": "7",
            "Block": "207",
            "label": "SHRI MEENAKSHI MATRIC SCHOOL,N.KOVILPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4967",
            "district": "7",
            "Block": "207",
            "label": "KALLALAGAR MATRICULATION SCHOOL,AYYANARPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13465",
            "district": "7",
            "Block": "207",
            "label": "GHS,PILLAYARNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13466",
            "district": "7",
            "Block": "207",
            "label": "PUMS, MAMARATHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13467",
            "district": "7",
            "Block": "207",
            "label": "GOVT.HSS. ,SENDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13468",
            "district": "7",
            "Block": "207",
            "label": "SARVASEVA MIDDLE SCHOOL, METTUPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13469",
            "district": "7",
            "Block": "207",
            "label": "GHS, KUDAGIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13470",
            "district": "7",
            "Block": "207",
            "label": "GHS,MANAKATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13471",
            "district": "7",
            "Block": "207",
            "label": "PUMS, SIRANKKATTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13472",
            "district": "7",
            "Block": "207",
            "label": "GOVT.HSS.,KOSUKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13473",
            "district": "7",
            "Block": "207",
            "label": "PUMS, SORIPARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13474",
            "district": "7",
            "Block": "207",
            "label": "SARVASEVA HSS,AYYANARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13475",
            "district": "7",
            "Block": "207",
            "label": "SARVA SEVA SPARROWS NEST MATRICULATION SCHOOL,AYYANARPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13476",
            "district": "7",
            "Block": "207",
            "label": "SARVA SEVA HIGH SCHOOL ,K.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13477",
            "district": "7",
            "Block": "207",
            "label": "GHSS, ARAVANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13478",
            "district": "7",
            "Block": "207",
            "label": "PUMS, N.KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13479",
            "district": "7",
            "Block": "207",
            "label": "SARVA SEVA HIGH SCHOOL ,ANDIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13480",
            "district": "7",
            "Block": "207",
            "label": "PUMS, NALLAKANDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13481",
            "district": "7",
            "Block": "207",
            "label": "PUMS, ODUGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13482",
            "district": "7",
            "Block": "207",
            "label": "SARVA SEVA MIDDLE SCHOOL,ETTAYAMPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13483",
            "district": "7",
            "Block": "207",
            "label": "GOVT HSS,SIRUGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13484",
            "district": "7",
            "Block": "207",
            "label": "GOVT HSS ,SAMUTHRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13485",
            "district": "7",
            "Block": "207",
            "label": "PUMS,NADUVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13486",
            "district": "7",
            "Block": "207",
            "label": "RCMS, AVICHIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13487",
            "district": "7",
            "Block": "207",
            "label": "PUMS, N. PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13488",
            "district": "7",
            "Block": "207",
            "label": "PUMS P.MALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13489",
            "district": "7",
            "Block": "207",
            "label": "GHS,KUTTUPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13490",
            "district": "7",
            "Block": "207",
            "label": "PUMS,SERVEEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13491",
            "district": "7",
            "Block": "207",
            "label": "DURAIKAMALAM GOVT MODEL HIGHER SECONDARY SCHOOL, N.KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13492",
            "district": "7",
            "Block": "207",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, NATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13493",
            "district": "7",
            "Block": "207",
            "label": "LANDIS MATRIC HIGHER SECONDARY SCHOOL,NATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13494",
            "district": "7",
            "Block": "207",
            "label": "RC HIGHER SECONDARY SCHOOL,NATHAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13495",
            "district": "7",
            "Block": "207",
            "label": "GHS, MULAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13496",
            "district": "7",
            "Block": "207",
            "label": "PUMS, PUNNAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13497",
            "district": "7",
            "Block": "207",
            "label": "GHS, VELAYUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13498",
            "district": "7",
            "Block": "207",
            "label": "PUMS, SATHAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13499",
            "district": "7",
            "Block": "207",
            "label": "PUMS, VILAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13500",
            "district": "7",
            "Block": "207",
            "label": "GHS, KOMANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13501",
            "district": "7",
            "Block": "207",
            "label": "PUMS, PATTANAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13502",
            "district": "7",
            "Block": "207",
            "label": "PUMS, KASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13503",
            "district": "7",
            "Block": "207",
            "label": "GHSS, VATHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13504",
            "district": "7",
            "Block": "207",
            "label": "GHS, LINGAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13505",
            "district": "7",
            "Block": "207",
            "label": "PUMS P.THETHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13506",
            "district": "7",
            "Block": "207",
            "label": "PENIEL HSS,VEMPARALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21366",
            "district": "7",
            "Block": "207",
            "label": "GHS, KOTTAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21367",
            "district": "7",
            "Block": "207",
            "label": "GHS, ULUPPAGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21368",
            "district": "7",
            "Block": "207",
            "label": "GHS, PARALIPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22177",
            "district": "7",
            "Block": "207",
            "label": "UDHAYAM SPECIAL SCHOOL, VELAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22178",
            "district": "7",
            "Block": "207",
            "label": "GHS, MOONGIL PATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22502",
            "district": "7",
            "Block": "207",
            "label": "GHS, CHITHIRAIKOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22503",
            "district": "7",
            "Block": "207",
            "label": "GHS, PERIYUR PATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22504",
            "district": "7",
            "Block": "207",
            "label": "RAMSUN'S INTERNATIONAL SCHOOL, NATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23176",
            "district": "7",
            "Block": "207",
            "label": "PENIEL SCHOOL(CBSE), VEMPARALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "208": [
        {
            "key": "1551",
            "district": "31",
            "Block": "208",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL, HINDUPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2196",
            "district": "31",
            "Block": "208",
            "label": "ARSHIYA FATHIMA MATRIC SCHOOL RAMANAICKENPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3864",
            "district": "31",
            "Block": "208",
            "label": "SSV MATRICULATION HIGHER SECONDARY SCHOOL, NATRAMPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3872",
            "district": "31",
            "Block": "208",
            "label": "MMS NETHAJI NAGAR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5371",
            "district": "31",
            "Block": "208",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5709",
            "district": "31",
            "Block": "208",
            "label": "AL-HUDA MATRIC HSS, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6407",
            "district": "31",
            "Block": "208",
            "label": "FATHIMA MATRIC HSS, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6678",
            "district": "31",
            "Block": "208",
            "label": "O.MOHANRAJ MATRIC SCHOOL, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6698",
            "district": "31",
            "Block": "208",
            "label": "AR RAHMAN MATRICULATION SCHOOL, KHADERPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8966",
            "district": "31",
            "Block": "208",
            "label": "PUMS ALINJIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8968",
            "district": "31",
            "Block": "208",
            "label": "GHSS THUMBERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8969",
            "district": "31",
            "Block": "208",
            "label": "GHS CHICKANANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8970",
            "district": "31",
            "Block": "208",
            "label": "PUMS JAVADHU RAMA SAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8971",
            "district": "31",
            "Block": "208",
            "label": "PUMS, BYYAPPANAYAKENPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8972",
            "district": "31",
            "Block": "208",
            "label": "PUMS MAMUDIMANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8973",
            "district": "31",
            "Block": "208",
            "label": "GHSS PATCHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8974",
            "district": "31",
            "Block": "208",
            "label": "PUMS KATHARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8975",
            "district": "31",
            "Block": "208",
            "label": "GOVT. HSS. DHASIRIYAPPANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8976",
            "district": "31",
            "Block": "208",
            "label": "GHSS RAMANAICKENPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8977",
            "district": "31",
            "Block": "208",
            "label": "PUMS ANNA NAGAR(M)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8978",
            "district": "31",
            "Block": "208",
            "label": "PUMS GURUBHAVANIGUNDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8979",
            "district": "31",
            "Block": "208",
            "label": "PUMS THEKKUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8980",
            "district": "31",
            "Block": "208",
            "label": "PUMS KODAIYANCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8981",
            "district": "31",
            "Block": "208",
            "label": "GHSS AMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8982",
            "district": "31",
            "Block": "208",
            "label": "THIRU-VI- KA MIDDLE SCHOOL THIRU-VI-KA NAGAR MARUTHUVAR COLONY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8983",
            "district": "31",
            "Block": "208",
            "label": "ISLAMIAH BOYS HSS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8984",
            "district": "31",
            "Block": "208",
            "label": "HINDU HSS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8985",
            "district": "31",
            "Block": "208",
            "label": "MMS MUSLIM GIRLS BASHEERABAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8986",
            "district": "31",
            "Block": "208",
            "label": "MMS MUSLIM GIRLS FORT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8987",
            "district": "31",
            "Block": "208",
            "label": "MMS MUSLIM GIRLS KHADERPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8988",
            "district": "31",
            "Block": "208",
            "label": "ISLAMIAH GIRLS HSS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8989",
            "district": "31",
            "Block": "208",
            "label": "KHADERIA HSS VANIYAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8990",
            "district": "31",
            "Block": "208",
            "label": "MMS PERUMALPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8991",
            "district": "31",
            "Block": "208",
            "label": "TVKV HS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8992",
            "district": "31",
            "Block": "208",
            "label": "GOVT MPL  MODEL HIGHER SECONDARY SCHOOL  GANDHI NAGAR VANIYAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8993",
            "district": "31",
            "Block": "208",
            "label": "PUMS AVARANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8994",
            "district": "31",
            "Block": "208",
            "label": "PUMS PULLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8995",
            "district": "31",
            "Block": "208",
            "label": "PUMS KONDAKINDANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8996",
            "district": "31",
            "Block": "208",
            "label": "GHS No. 1. KOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8997",
            "district": "31",
            "Block": "208",
            "label": "GHS THIMMAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8998",
            "district": "31",
            "Block": "208",
            "label": "PUMS VELLANAYAKANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8999",
            "district": "31",
            "Block": "208",
            "label": "GHS NAYANACHERUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9000",
            "district": "31",
            "Block": "208",
            "label": "PUMS NARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9001",
            "district": "31",
            "Block": "208",
            "label": "PUMS ELARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9002",
            "district": "31",
            "Block": "208",
            "label": "GOVT HSS JANGALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9003",
            "district": "31",
            "Block": "208",
            "label": "PUMS ADIPERAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9004",
            "district": "31",
            "Block": "208",
            "label": "GOVT. GIRLS HSS NATRAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9005",
            "district": "31",
            "Block": "208",
            "label": "PUMS ARASANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9006",
            "district": "31",
            "Block": "208",
            "label": "GOVT. BOYS. HSS NATRAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9007",
            "district": "31",
            "Block": "208",
            "label": "BRINDAVAN MATRIC HSS, NATRAMPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9008",
            "district": "31",
            "Block": "208",
            "label": "GHS K BANDARAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9009",
            "district": "31",
            "Block": "208",
            "label": "MMS HINDU  PERIYAPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9010",
            "district": "31",
            "Block": "208",
            "label": "NAYA MADRASA  URDU MIDDLE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9011",
            "district": "31",
            "Block": "208",
            "label": "ADARSH MATRIC.HSS, VANIYAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21436",
            "district": "31",
            "Block": "208",
            "label": "GHS THAGARAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21437",
            "district": "31",
            "Block": "208",
            "label": "GGHS PATCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21884",
            "district": "31",
            "Block": "208",
            "label": "CONCORDIA HSS VANIYAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22071",
            "district": "31",
            "Block": "208",
            "label": "RAJI GARDEN MATRIC HIGHER SECONDARY SCHOOL, NATRAMPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22396",
            "district": "31",
            "Block": "208",
            "label": "MUNCIPAL MUSLIM BOYS HIGH SCHOOL GANDHI NAGAR VANIYAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22950",
            "district": "31",
            "Block": "208",
            "label": "MHS, KONAMEDU, VANIYAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23893",
            "district": "31",
            "Block": "208",
            "label": "PUMS NARAYANAPURAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "209": [
        {
            "key": "3534",
            "district": "35",
            "Block": "209",
            "label": "UAMHS,A.N.ABDUL RAHIM POTHAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4668",
            "district": "35",
            "Block": "209",
            "label": "UAMHSS OMD,MATRIC HR.SECONDARY SCHOOL, VAZHACHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5356",
            "district": "35",
            "Block": "209",
            "label": "PUMS, MULLAIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5433",
            "district": "35",
            "Block": "209",
            "label": "PUMS, VIZHALKOTTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5519",
            "district": "35",
            "Block": "209",
            "label": "PUMS, KALACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5861",
            "district": "35",
            "Block": "209",
            "label": "PUMS, MOOVARKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5901",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SITHAMALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5929",
            "district": "35",
            "Block": "209",
            "label": "PUMS, KARUVAKURICHI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5942",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SERUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6026",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SONAPPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6086",
            "district": "35",
            "Block": "209",
            "label": "PUMS, RISHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6135",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SEKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6164",
            "district": "35",
            "Block": "209",
            "label": "PUMS,VADAKARAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6165",
            "district": "35",
            "Block": "209",
            "label": "PUMS, AGRAPODAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6191",
            "district": "35",
            "Block": "209",
            "label": "PUMS, ARICHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6225",
            "district": "35",
            "Block": "209",
            "label": "PUMS,THENKARAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6248",
            "district": "35",
            "Block": "209",
            "label": "PUMS, VADAKKU THOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6254",
            "district": "35",
            "Block": "209",
            "label": "PUMS, KAPPALUTAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6310",
            "district": "35",
            "Block": "209",
            "label": "PUMS, SEENIKUDIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6452",
            "district": "35",
            "Block": "209",
            "label": "AMS,LAKUMIVILAS, NEEDAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6734",
            "district": "35",
            "Block": "209",
            "label": "PUMS, DEVANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16235",
            "district": "35",
            "Block": "209",
            "label": "UAHSS, MOTHERINDIA, VALACHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16236",
            "district": "35",
            "Block": "209",
            "label": "PUMS,PODAKKUDI SOUTH SSA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16237",
            "district": "35",
            "Block": "209",
            "label": "GBHSS, PODAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16238",
            "district": "35",
            "Block": "209",
            "label": "GGHSS, PODAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16239",
            "district": "35",
            "Block": "209",
            "label": "GHS , KOVILVENNI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16240",
            "district": "35",
            "Block": "209",
            "label": "GHS, EDAKEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16241",
            "district": "35",
            "Block": "209",
            "label": "GHSS, MUNNAVALKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16242",
            "district": "35",
            "Block": "209",
            "label": "GHS, PERAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16243",
            "district": "35",
            "Block": "209",
            "label": "PUMS, KALACHERI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16244",
            "district": "35",
            "Block": "209",
            "label": "GHSS, RAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16245",
            "district": "35",
            "Block": "209",
            "label": "AHSS VADUVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16246",
            "district": "35",
            "Block": "209",
            "label": "UAMHSS,L.F.VISWANATH, EDAMELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16247",
            "district": "35",
            "Block": "209",
            "label": "GHS, PULLAVARAYANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16248",
            "district": "35",
            "Block": "209",
            "label": "GHS, KATTAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16249",
            "district": "35",
            "Block": "209",
            "label": "GHSS, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16250",
            "district": "35",
            "Block": "209",
            "label": "PUMS, THALIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16251",
            "district": "35",
            "Block": "209",
            "label": "GHSS, KARUVAKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16252",
            "district": "35",
            "Block": "209",
            "label": "PUMS, VADUVUR THENPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16253",
            "district": "35",
            "Block": "209",
            "label": "PUMS, NALLIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16254",
            "district": "35",
            "Block": "209",
            "label": "PUMS, MELAPOOVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16255",
            "district": "35",
            "Block": "209",
            "label": "GHS, POOVANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16256",
            "district": "35",
            "Block": "209",
            "label": "PAHS,MELALAVANTHACHERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16257",
            "district": "35",
            "Block": "209",
            "label": "UAMHSS, NEELAN MATRIC HR.SEC,SCHOOL.NEEDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16258",
            "district": "35",
            "Block": "209",
            "label": "AHSS, NEEDAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16259",
            "district": "35",
            "Block": "209",
            "label": "UAMHSS, ST.JUDE'S MATRIC HIGHER SECONDARY SCHOOL,  NEEDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16260",
            "district": "35",
            "Block": "209",
            "label": "GHS, NEEDAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21222",
            "district": "35",
            "Block": "209",
            "label": "GHSS,EDAMELAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22886",
            "district": "35",
            "Block": "209",
            "label": "GHS Karakottai",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "210": [
        {
            "key": "958",
            "district": "21",
            "Block": "210",
            "label": "SRI SARASWATHI MATRIC HSS , REDDIVALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1863",
            "district": "21",
            "Block": "210",
            "label": "SHIRNE VELANKANNI MATRIC SCHOOL, PARAMESWARAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1869",
            "district": "21",
            "Block": "210",
            "label": "DR. NATARAJAN MATRIC HSS, VETTANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2157",
            "district": "21",
            "Block": "210",
            "label": "VIVEKANANDHA MATRIC.HR.SEC.SCHOOL SAYANAPURAM-NEMILI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5050",
            "district": "21",
            "Block": "210",
            "label": "BHARATH VIDYA MATRIC SCHOOL, SENDAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5118",
            "district": "21",
            "Block": "210",
            "label": "SENTHIL KUMARAN PS, GANAPATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6147",
            "district": "21",
            "Block": "210",
            "label": "DR. V.S. ISAAC MATRIC SCHOOL, ATTUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6701",
            "district": "21",
            "Block": "210",
            "label": "PUMS, PADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8433",
            "district": "21",
            "Block": "210",
            "label": "PUMS, POIGAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8434",
            "district": "21",
            "Block": "210",
            "label": "GHSS MELAPULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8435",
            "district": "21",
            "Block": "210",
            "label": "PUMS NEDUMPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8436",
            "district": "21",
            "Block": "210",
            "label": "PUMS, THURAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8437",
            "district": "21",
            "Block": "210",
            "label": "GHS ULIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8438",
            "district": "21",
            "Block": "210",
            "label": "PUMS, PERAPPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8439",
            "district": "21",
            "Block": "210",
            "label": "GHS KILVEEDHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8440",
            "district": "21",
            "Block": "210",
            "label": "GOVT HS MELERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8441",
            "district": "21",
            "Block": "210",
            "label": "GHSS MAHENDIRAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8442",
            "district": "21",
            "Block": "210",
            "label": "PUMS, VELITHANGIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8443",
            "district": "21",
            "Block": "210",
            "label": "GHS KATTUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8444",
            "district": "21",
            "Block": "210",
            "label": "G ADW HS ELATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8447",
            "district": "21",
            "Block": "210",
            "label": "GOVERMENT HIGH SCHOOL, CHITTERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8448",
            "district": "21",
            "Block": "210",
            "label": "GHSS NAGAVEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8449",
            "district": "21",
            "Block": "210",
            "label": "PUMS, KIZHANTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8450",
            "district": "21",
            "Block": "210",
            "label": "PUMS, SIRUNAMALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8451",
            "district": "21",
            "Block": "210",
            "label": "GHSS PALLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8452",
            "district": "21",
            "Block": "210",
            "label": "PUMS, MANAMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8453",
            "district": "21",
            "Block": "210",
            "label": "GHS VETTANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8454",
            "district": "21",
            "Block": "210",
            "label": "PUMS, REDDIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8455",
            "district": "21",
            "Block": "210",
            "label": "PUMS, PILLAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8456",
            "district": "21",
            "Block": "210",
            "label": "GHS KADAMBANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8457",
            "district": "21",
            "Block": "210",
            "label": "PUMS, KILVENPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8458",
            "district": "21",
            "Block": "210",
            "label": "GHSS THIRUMALPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8459",
            "district": "21",
            "Block": "210",
            "label": "GHS, PALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8460",
            "district": "21",
            "Block": "210",
            "label": "PUMS, S.KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8461",
            "district": "21",
            "Block": "210",
            "label": "PUMS, ASANELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8462",
            "district": "21",
            "Block": "210",
            "label": "GHS KILVENKATAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8463",
            "district": "21",
            "Block": "210",
            "label": "GHSS SAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8464",
            "district": "21",
            "Block": "210",
            "label": "GHSS SENDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8465",
            "district": "21",
            "Block": "210",
            "label": "PUMS, ATTUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8466",
            "district": "21",
            "Block": "210",
            "label": "GHS GANAPATHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8467",
            "district": "21",
            "Block": "210",
            "label": "PUMS, MURUNGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8468",
            "district": "21",
            "Block": "210",
            "label": "GHS PARAMESHWARAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8469",
            "district": "21",
            "Block": "210",
            "label": "PUMS, ARIGILAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8470",
            "district": "21",
            "Block": "210",
            "label": "G( B)HSS PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8471",
            "district": "21",
            "Block": "210",
            "label": "GOVT MODEL HIGHER SECONDARY SCHOOL , PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8472",
            "district": "21",
            "Block": "210",
            "label": "PUMS, PUNNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8473",
            "district": "21",
            "Block": "210",
            "label": "G(G)HSS NEMILI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8474",
            "district": "21",
            "Block": "210",
            "label": "G(B)HSS NEMILI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21573",
            "district": "21",
            "Block": "210",
            "label": "CENTUM NURSARY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22060",
            "district": "21",
            "Block": "210",
            "label": "GOVT HIGH SCHOOL, JAGIRTHANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22379",
            "district": "21",
            "Block": "210",
            "label": "GOVT HIGH SCHOOL MELKALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22951",
            "district": "21",
            "Block": "210",
            "label": "GGHS, THIRUMALPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23534",
            "district": "21",
            "Block": "210",
            "label": "Nalanda Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23534",
            "district": "21",
            "Block": "210",
            "label": "Nalanda Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "211": [
        {
            "key": "5067",
            "district": "5",
            "Block": "211",
            "label": "KALAIMAGAL AIDED MIDDLE SCHOOL, MOOLAKUPPAM @KATTUKOLLAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5238",
            "district": "5",
            "Block": "211",
            "label": "NLC MIDDLE SCHOOL BLOCK- 9  NEYVELI -TS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6162",
            "district": "5",
            "Block": "211",
            "label": "ST. ANTONY'S MATRICULATION HIGHER SECONDARY SCHOOL  BLOCK-29 NEYVELI- TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6322",
            "district": "5",
            "Block": "211",
            "label": "JAWAHAR HIGHER SECONDARY SCHOOL, BLOCK-17 NEYVELI -TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15274",
            "district": "5",
            "Block": "211",
            "label": "ARUL JOTHI AIDED MIDDLE SCHOOL  VADAKUTHU ( EAST)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15275",
            "district": "5",
            "Block": "211",
            "label": "NLC MIDDLE SCHOOL BLOCK- 20  NEYVELI-TS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15276",
            "district": "5",
            "Block": "211",
            "label": "DANISH MISSION MIDDDLE SCHOOL BLOCK- 19 NEYVELI-TS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15277",
            "district": "5",
            "Block": "211",
            "label": "THOLKAPPIYANAR MIDDLE SCHOOL BLOCK -16 NEYVELI-TS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15278",
            "district": "5",
            "Block": "211",
            "label": "JAWAHAR MATRICULATION HIGHER SECONDARY SCHOOL BLOCK -17 NEYVELI - TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15279",
            "district": "5",
            "Block": "211",
            "label": "JUNIOR JAWAHAR MATRICULATION  SCHOOL, BLOCK- 6 NEYVELI - TS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15280",
            "district": "5",
            "Block": "211",
            "label": "MODEL JAWAHAR MATRICULATION SCHOOL, BLOCK 28 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15281",
            "district": "5",
            "Block": "211",
            "label": "LITTLE JAWAHAR MATRICULATION SCHOOL, MANDARAKUPPAM NEYVELI-2",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15282",
            "district": "5",
            "Block": "211",
            "label": "ST. PAUL'S MATRICULATION HIGHER SECONDARY SCHOOL , BLOCK -4 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15283",
            "district": "5",
            "Block": "211",
            "label": "ST. JOSEPH OF CLUNY MATRICULATION  HIGHER SECONDARY SCHOOL  BLOCK-25 NEYVELI -TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15284",
            "district": "5",
            "Block": "211",
            "label": "SRI AUROBINDO VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, BLOCK-19 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15285",
            "district": "5",
            "Block": "211",
            "label": "SEVENTH DAY ADVENTIST  MATRICULATION HIGHER SECONDARY SCHOOL BLOCK-13 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15286",
            "district": "5",
            "Block": "211",
            "label": "TAGORE MATRICULATION HIGHER SECONDARY SCHOOL BLOCK-29 NEYVELI-TS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15287",
            "district": "5",
            "Block": "211",
            "label": "N M J MATRICULATION HIGH SCHOOL BLOCK-12 NEYVELI -TS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15288",
            "district": "5",
            "Block": "211",
            "label": "ST. JOSEPH'S HIGH SCHOOL  BLOCK-4, NEYVELI-TS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15289",
            "district": "5",
            "Block": "211",
            "label": "NLC GIRLS HIGHER SECONDARY SCHOOL  BLOCK-11 NEYVELI -TS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15290",
            "district": "5",
            "Block": "211",
            "label": "NLC BOYS HIGHER SECONDARY SCHOOL  BLOCK-10, NEYVELI -TS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15291",
            "district": "5",
            "Block": "211",
            "label": "NLC HIGH SCHOOL BLOCK-26 NEYVELI-TS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15292",
            "district": "5",
            "Block": "211",
            "label": "NLC HIGHER SECONDARY SCHOOL MANDARAKUPPAM,.NEYVELI.-607802",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15293",
            "district": "5",
            "Block": "211",
            "label": "NLC GIRLS HIGH SCHOOL  MANDARAKUPPAM  NEYVELI- 607802..",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15294",
            "district": "5",
            "Block": "211",
            "label": "VALLALAR MATRICULATION HIGHER SECONDARY  SCHOOL, KOLLUKARANKUTTAI, MARUNGUR(PO)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21329",
            "district": "5",
            "Block": "211",
            "label": "KENDRIYA VIDYALAYA HIGHER SECONDARY SCHOOL(CBSE)  BLOCK - 3 NEYVELI-TS",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23270",
            "district": "5",
            "Block": "211",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "212": [
        {
            "key": "2937",
            "district": "7",
            "Block": "212",
            "label": "KAVIYAN MATRIC HIGH SCHOOL AMMAYANAYAKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4907",
            "district": "7",
            "Block": "212",
            "label": "HNUPR MATRICULATION HR. SEC SCHOOL, NILAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6220",
            "district": "7",
            "Block": "212",
            "label": "SOURASHTRA MIDDLE SCHOOL ,NILAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13507",
            "district": "7",
            "Block": "212",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,GULLICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13508",
            "district": "7",
            "Block": "212",
            "label": "PUMS, GUNDALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13509",
            "district": "7",
            "Block": "212",
            "label": "GOVT KALLAR HIGH SCHOOL SITHARKALNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13510",
            "district": "7",
            "Block": "212",
            "label": "GOVT.KR.HSS,VILAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13511",
            "district": "7",
            "Block": "212",
            "label": "PUMS, PILLAIYARNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13512",
            "district": "7",
            "Block": "212",
            "label": "PUMS,SIRUNAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13513",
            "district": "7",
            "Block": "212",
            "label": "GOVT.KR.HSS,ANAIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13514",
            "district": "7",
            "Block": "212",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,E.KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13515",
            "district": "7",
            "Block": "212",
            "label": "GOVT.KALLAR MIDDLE SCHOOL, MUSUVANUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13516",
            "district": "7",
            "Block": "212",
            "label": "PUMS,VEELINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13517",
            "district": "7",
            "Block": "212",
            "label": "PUMS, S.THUMMALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13518",
            "district": "7",
            "Block": "212",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,CHINNAMANAYAKKANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13519",
            "district": "7",
            "Block": "212",
            "label": "KCM.MATRIC SCHOOL, NILAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13520",
            "district": "7",
            "Block": "212",
            "label": "GOVT. HIGHER SECONDARY SCHOOL, NILAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13521",
            "district": "7",
            "Block": "212",
            "label": "HNUPR GIRLS HSS,NILAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13522",
            "district": "7",
            "Block": "212",
            "label": "ST.JOSEPHS MIDDLE SCHOOL,,SILUKKUVARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13523",
            "district": "7",
            "Block": "212",
            "label": "MUTHURAJA MIDDLE SCHOOL, KARIAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13524",
            "district": "7",
            "Block": "212",
            "label": "RC.HSS,SILUKKUVARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13525",
            "district": "7",
            "Block": "212",
            "label": "SWAMI VIVEKANANDA HIGHER SECONDARY SCHOOL, SILUKKUVARPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13526",
            "district": "7",
            "Block": "212",
            "label": "RC.HSS,MICHEALPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13527",
            "district": "7",
            "Block": "212",
            "label": "GOVT.KR.HSS,KAMUPILLAICHATHRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13528",
            "district": "7",
            "Block": "212",
            "label": "PUMS, J.OOTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13529",
            "district": "7",
            "Block": "212",
            "label": "PUMS, ADAIKALAMATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13530",
            "district": "7",
            "Block": "212",
            "label": "PUMS, SAKKAYANAYAKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13531",
            "district": "7",
            "Block": "212",
            "label": "DIRAVIAM GHSS,KAMALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13532",
            "district": "7",
            "Block": "212",
            "label": "GOVT.ADW HIGHER SECONDARY SCHOOL, ALAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13533",
            "district": "7",
            "Block": "212",
            "label": "RC MIDDLE SCHOOL, AMMAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13534",
            "district": "7",
            "Block": "212",
            "label": "GHSS,AMMAYANAYAKANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13535",
            "district": "7",
            "Block": "212",
            "label": "PUMS, POTTICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13536",
            "district": "7",
            "Block": "212",
            "label": "GOVT. KALLAR MIDDLE SCHOOL, PALLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13537",
            "district": "7",
            "Block": "212",
            "label": "GHSS,PALLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13538",
            "district": "7",
            "Block": "212",
            "label": "RC MS KALLADIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13539",
            "district": "7",
            "Block": "212",
            "label": "PUMS, SADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13540",
            "district": "7",
            "Block": "212",
            "label": "GHSS, MATTAPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13541",
            "district": "7",
            "Block": "212",
            "label": "GOVT. KALLAR MIDDLE SCHOOL, RAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21485",
            "district": "7",
            "Block": "212",
            "label": "GOVT KALLAR HIGH SCHOOL E AVARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21530",
            "district": "7",
            "Block": "212",
            "label": "NADAR HS,NILAKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21602",
            "district": "7",
            "Block": "212",
            "label": "RADIANCE PUBLIC SCHOOL(CBSE), KOZHINCHIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21876",
            "district": "7",
            "Block": "212",
            "label": "DAMIEN PUBLIC SENIOR SECONDARY SCHOOL(CBSE), DIRAVIAM NAGAR, NILAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22179",
            "district": "7",
            "Block": "212",
            "label": "PUDHU UDHAYAM SPECIAL SCHOOL FOR MR, MURUGATHOORANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22505",
            "district": "7",
            "Block": "212",
            "label": "GHS,KOZHINJIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "213": [
        {
            "key": "2895",
            "district": "7",
            "Block": "213",
            "label": "AKSHAYA ACADEMY MATRIC, KOSAVAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3295",
            "district": "7",
            "Block": "213",
            "label": "SRI KRISHNA MATRICULATION Hr. SEC. SCHOOL, ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3441",
            "district": "7",
            "Block": "213",
            "label": "ST.JAMES MATRIC SCHOOL,ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3478",
            "district": "7",
            "Block": "213",
            "label": "HOLY CROSS MATRICULATION HIGHER SECONDARY SCHOOL,ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3487",
            "district": "7",
            "Block": "213",
            "label": "KALVI MATRIC HR. SEC. SCHOOL, KAPPILIYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3533",
            "district": "7",
            "Block": "213",
            "label": "CHRISTIAN MATRICULATION HSS,ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3877",
            "district": "7",
            "Block": "213",
            "label": "BFHV MATRICULATION HSS,ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4047",
            "district": "7",
            "Block": "213",
            "label": "AKSHAYA MATRICULATION HSS,LAKKAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4371",
            "district": "7",
            "Block": "213",
            "label": "SPM OXFORD MATRICULATION HSS,KALANJIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13542",
            "district": "7",
            "Block": "213",
            "label": "PUMS,K.KEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13543",
            "district": "7",
            "Block": "213",
            "label": "GHSS,ODAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13544",
            "district": "7",
            "Block": "213",
            "label": "PUMS,VERIYAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13545",
            "district": "7",
            "Block": "213",
            "label": "PUMS,PERIYAKULIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13546",
            "district": "7",
            "Block": "213",
            "label": "GHSS,MUTHUNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13547",
            "district": "7",
            "Block": "213",
            "label": "VIVEKANANDA VIDYALAYA HIGHER SECONDARY SCHOOL,KALANJIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13548",
            "district": "7",
            "Block": "213",
            "label": "PUMS, SALAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13549",
            "district": "7",
            "Block": "213",
            "label": "AKSHAYA ACADEMY CBSE SCHOOL,LAKKAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13550",
            "district": "7",
            "Block": "213",
            "label": "PUMS,KOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13551",
            "district": "7",
            "Block": "213",
            "label": "PUMS,GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13552",
            "district": "7",
            "Block": "213",
            "label": "RC LOYALA MS,ODDANCHATRAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "13553",
            "district": "7",
            "Block": "213",
            "label": "CSI MS, THUMMICHAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13554",
            "district": "7",
            "Block": "213",
            "label": "K.R GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, ODDANCHATHRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13555",
            "district": "7",
            "Block": "213",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, ODDANCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13556",
            "district": "7",
            "Block": "213",
            "label": "PUMS,SAMIYARPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13557",
            "district": "7",
            "Block": "213",
            "label": "PUMS,SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13558",
            "district": "7",
            "Block": "213",
            "label": "RC FATHIMA HS,VIRUPATCHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13559",
            "district": "7",
            "Block": "213",
            "label": "GHS, CHINNAKARATTUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13560",
            "district": "7",
            "Block": "213",
            "label": "GHSS,PERIYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13561",
            "district": "7",
            "Block": "213",
            "label": "GHS, KAVERIAMMAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13562",
            "district": "7",
            "Block": "213",
            "label": "PUMS,THANGACHIAMMAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13563",
            "district": "7",
            "Block": "213",
            "label": "PUMS, AMBILIKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13564",
            "district": "7",
            "Block": "213",
            "label": "SNHSS,AMBILIKAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13565",
            "district": "7",
            "Block": "213",
            "label": "HINDU AIDED MIDDLE SCHOOL, CHINNAMANDAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13566",
            "district": "7",
            "Block": "213",
            "label": "PUMS,PALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13567",
            "district": "7",
            "Block": "213",
            "label": "GHS,PARAPPALARU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13568",
            "district": "7",
            "Block": "213",
            "label": "GHSS,MARKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13569",
            "district": "7",
            "Block": "213",
            "label": "PUMS, JAVADHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13570",
            "district": "7",
            "Block": "213",
            "label": "NEHRUJI GHSS,IDAYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13571",
            "district": "7",
            "Block": "213",
            "label": "HINDU AIDED MS-KODANGIPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13572",
            "district": "7",
            "Block": "213",
            "label": "GHS, PULIYAMARATHUKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13573",
            "district": "7",
            "Block": "213",
            "label": "GHSS, P.CHATRAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13574",
            "district": "7",
            "Block": "213",
            "label": "SEENIVASAN MEM.GHSS-GOPALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13575",
            "district": "7",
            "Block": "213",
            "label": "PUMS, VEERALAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13576",
            "district": "7",
            "Block": "213",
            "label": "TRIUMPH ACADEMY MATRICULATION SCHOOL, D.PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23068",
            "district": "7",
            "Block": "213",
            "label": "SRI KRISHNA SCHOOL, ODDANCHATHRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23069",
            "district": "7",
            "Block": "213",
            "label": "BUDS VIDYASHRAM CBSE SCHOOL, ODDANCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23894",
            "district": "7",
            "Block": "213",
            "label": "PUMS,SAMATHUVAPURAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "214": [
        {
            "key": "5320",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAIRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5771",
            "district": "37",
            "Block": "214",
            "label": "THAI TAMIL MIDDLE SCHOOL ROSANI TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5797",
            "district": "37",
            "Block": "214",
            "label": "BHARATHI AIDED MIDDLE SCHOOL PANCHALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6170",
            "district": "37",
            "Block": "214",
            "label": "SRI KAMATCHI VILAS MIDDLE SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6303",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EAPPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10128",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VELLIMEDUPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10129",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADASIRUVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10130",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEEZHMAVILANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10131",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL DHADHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10132",
            "district": "37",
            "Block": "214",
            "label": "THIRUVALLUVAR HIGHER SECONDARY SCHOOL  KARUVAMBAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10133",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL URAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10134",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10135",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL OLAKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10136",
            "district": "37",
            "Block": "214",
            "label": "SRI THANGAM MATRICULATION SCHOOL. OLAKKUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10137",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KONERIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10138",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEEZHADHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10139",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10140",
            "district": "37",
            "Block": "214",
            "label": "ST.FRANCIS MATRIC HIGHER SECONDARY SCHOOL,ASSISI NAGAR,SARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10141",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NOLAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10142",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEEZHSEVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10143",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEEZHPOOTHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10144",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGH SCHOOL ATCHIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10145",
            "district": "37",
            "Block": "214",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL  PANGOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10146",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ONGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10147",
            "district": "37",
            "Block": "214",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADAVAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10148",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL AVANIPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10149",
            "district": "37",
            "Block": "214",
            "label": "ST.ANN'S Hr SEC SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10150",
            "district": "37",
            "Block": "214",
            "label": "MONTFORT MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10151",
            "district": "37",
            "Block": "214",
            "label": "TAGORE MATRICULATION HIGHER SECONDARY SCHOOL JAYAPURAM,TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10152",
            "district": "37",
            "Block": "214",
            "label": "ST.JOSEPH OF CLUNY MATRIC HR.SEC.SCHOOL, TINDIVANAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10153",
            "district": "37",
            "Block": "214",
            "label": "ST. PHILOMENA'S GIRLS HIGHER SECONDARY SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10154",
            "district": "37",
            "Block": "214",
            "label": "MUNICIPAL HIGHER SECONDARY SCHOOL KAVERIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10155",
            "district": "37",
            "Block": "214",
            "label": "ST.ANN'S MIDDLE SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10156",
            "district": "37",
            "Block": "214",
            "label": "TAGORE HIGH SCHOOL (CBSE), TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10157",
            "district": "37",
            "Block": "214",
            "label": "MADRAS DIOCESE CRANE MIDDLE SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10158",
            "district": "37",
            "Block": "214",
            "label": "NATIONAL HR SEC SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10159",
            "district": "37",
            "Block": "214",
            "label": "COOSHALCHAND HR SEC SCHOOL, TINDIVANAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10160",
            "district": "37",
            "Block": "214",
            "label": "SRI KANNIGAPARAMESWARI GIRLS HIGH SCHOOL TINDIVANAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10161",
            "district": "37",
            "Block": "214",
            "label": "MUSLIM MIDDLE SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10162",
            "district": "37",
            "Block": "214",
            "label": "SRI MARAGADAMBIGAI HIGH SCHOOL, TINDIVANAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10163",
            "district": "37",
            "Block": "214",
            "label": "CHANAKYA MATRIC HIGHER SECONDARY SCHOOL, PONDY ROAD, TINDIVANAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10164",
            "district": "37",
            "Block": "214",
            "label": "SOLAR MATRICULATION HIGHER SECONDARY SCHOOL.TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10165",
            "district": "37",
            "Block": "214",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  MURUNGAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10166",
            "district": "37",
            "Block": "214",
            "label": "WALTER SCUDDER HIGHER SECONDARY SCHOOL TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10167",
            "district": "37",
            "Block": "214",
            "label": "SREE NAGAMMAL MIDDLE SCHOOL , TINDIVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10168",
            "district": "37",
            "Block": "214",
            "label": "MARY'S MATRICULATION HIGHER SECONDARY SCHOOL TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10169",
            "district": "37",
            "Block": "214",
            "label": "SAINT FRANCIS D' ASSISI MATRICULATION HIGHER SECONDARY SCHOOL TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10170",
            "district": "37",
            "Block": "214",
            "label": "VALARMATHI KARUPAIYA MATRIC SCHOOL, TINDIVANAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21794",
            "district": "37",
            "Block": "214",
            "label": "SRI DHARAMCHAND JAIN SCHOOL (CBSE) KARUVAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23650",
            "district": "37",
            "Block": "214",
            "label": "Wisdom Nursery and Primary school",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "22759",
            "district": "37",
            "Block": "214",
            "label": "DMI ST JOSEPH GLOBAL SCHOOL, KEEZHATHANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "215": [
        {
            "key": "2875",
            "district": "22",
            "Block": "215",
            "label": "SOUTH INDIAN MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23656",
            "district": "22",
            "Block": "215",
            "label": "BVM Global @Salem",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "3348",
            "district": "22",
            "Block": "215",
            "label": "KONGU MATRIC HR. SEC. SCHOOL,VELLAKALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4778",
            "district": "22",
            "Block": "215",
            "label": "KALAIMAGAL MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11548",
            "district": "22",
            "Block": "215",
            "label": "GHSS OMALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11549",
            "district": "22",
            "Block": "215",
            "label": "VELASAMY CHETTIYAR HR. SEC. SCHOOL, OMALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11550",
            "district": "22",
            "Block": "215",
            "label": "FATHIMA GIRLS HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11551",
            "district": "22",
            "Block": "215",
            "label": "GREEN HOME MATRIC HR. SEC. SCHOOL,NATIONAL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11552",
            "district": "22",
            "Block": "215",
            "label": "FATIMA MATRICULATION SCHOOL OMALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11553",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.PERAMACHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11554",
            "district": "22",
            "Block": "215",
            "label": "WISDOM GATES MATRICULATION HR. SEC. SCHOOL, VELAGOUNDANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11555",
            "district": "22",
            "Block": "215",
            "label": "GHS KAMALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11556",
            "district": "22",
            "Block": "215",
            "label": "ST.NICHOLAS MID SCH R.C.CHETTIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11557",
            "district": "22",
            "Block": "215",
            "label": "JOHN BRITTO MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11558",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.POTTIAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11559",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.ARANMANAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11560",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.THUMBIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11561",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.KENDAPERIANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11562",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.THONNAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11563",
            "district": "22",
            "Block": "215",
            "label": "NORTH PARK MATRIC SCHOOL,THINNAPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11564",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.SARAKKAPPILLAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11565",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.REDDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11566",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.KOMALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11567",
            "district": "22",
            "Block": "215",
            "label": "ADW MS THATHIAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11568",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S..P.KALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11569",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.KARUPPANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11570",
            "district": "22",
            "Block": "215",
            "label": "PERARIGNAR ANNA G.H.S.S, THOLASAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11571",
            "district": "22",
            "Block": "215",
            "label": "GHSS M.CHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11572",
            "district": "22",
            "Block": "215",
            "label": "GHS PERIYAKADAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11573",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.REDDIPATTI KATTUVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11574",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.PACHANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11575",
            "district": "22",
            "Block": "215",
            "label": "GHSS,PANAKATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11576",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S KULLAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11577",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S METTUPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11578",
            "district": "22",
            "Block": "215",
            "label": "SKV HI TECH MATRICULATION HR.SEC.SCHOOL ,KARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11579",
            "district": "22",
            "Block": "215",
            "label": "GHSS,KARUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11580",
            "district": "22",
            "Block": "215",
            "label": "SSRM HR. SEC. SCHOOL, KARUMBALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11581",
            "district": "22",
            "Block": "215",
            "label": "RAAJAM MATRIC HR. SEC. SCHOOL, KARUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11582",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.SANGEETHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11583",
            "district": "22",
            "Block": "215",
            "label": "ADWHSS MATTUKARANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11584",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.CHELLAPILLAIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11585",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.P.NALLAGOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11586",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.BOOMINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11587",
            "district": "22",
            "Block": "215",
            "label": "ST.JOSEPH HR. SEC. SCHOOL, PAGALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11588",
            "district": "22",
            "Block": "215",
            "label": "MUKUNDA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11589",
            "district": "22",
            "Block": "215",
            "label": "GHSS PAGALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11590",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.PUDHURKADAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11591",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.KANDAMPITCHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11592",
            "district": "22",
            "Block": "215",
            "label": "GHSS MUTHUNAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11593",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.SEMMANKOODAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11594",
            "district": "22",
            "Block": "215",
            "label": "GHS PALAKUTTAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11595",
            "district": "22",
            "Block": "215",
            "label": "GHS SAKKARA CHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11596",
            "district": "22",
            "Block": "215",
            "label": "VIVEKANANDA KENDRIYA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11597",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.SPULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11598",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.VATTAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11599",
            "district": "22",
            "Block": "215",
            "label": "GHSS THEKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11600",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.THATHIANGARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11601",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.MOONGILPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11602",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.SAMINAICKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11603",
            "district": "22",
            "Block": "215",
            "label": "GHSS VELLALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11604",
            "district": "22",
            "Block": "215",
            "label": "P.U.M.S.MANGKUPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11605",
            "district": "22",
            "Block": "215",
            "label": "KIZHAR PALANIYANDI PILLAI AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11606",
            "district": "22",
            "Block": "215",
            "label": "GOLDEN SPARK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20964",
            "district": "22",
            "Block": "215",
            "label": "SREE THULASI VIKKAS MATRIC SCHOOL, THOLASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21460",
            "district": "22",
            "Block": "215",
            "label": "GHS KOTTA GOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22116",
            "district": "22",
            "Block": "215",
            "label": "ADW HS, SIKKANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22117",
            "district": "22",
            "Block": "215",
            "label": "G.R.MATRIC HR. SEC. SCHOOL, CHELLAPILLAIKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23545",
            "district": "22",
            "Block": "215",
            "label": "SRI SAI GURU PUBLIC SCHOOL, VELAGOUNDANPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23545",
            "district": "22",
            "Block": "215",
            "label": "SRI SAI GURU PUBLIC SCHOOL, VELAGOUNDANPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23895",
            "district": "22",
            "Block": "215",
            "label": "KONGU SRV INTERNATIONAL SCHOOL, VELLAKKALPATTI",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "216": [
        {
            "key": "2102",
            "district": "25",
            "Block": "216",
            "label": "PUMS-PEIKARUMBANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2335",
            "district": "25",
            "Block": "216",
            "label": "LONDON KRISHNAMOORTHI MHSS-OND",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3367",
            "district": "25",
            "Block": "216",
            "label": "PUMS - PULAVANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3858",
            "district": "25",
            "Block": "216",
            "label": "SRI SRINIVASA  MATRIC HSS-ORATHANADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4099",
            "district": "25",
            "Block": "216",
            "label": "PUMS - THENNAMANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4241",
            "district": "25",
            "Block": "216",
            "label": "LITTLE ROSE M H S S- ORATHANADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4351",
            "district": "25",
            "Block": "216",
            "label": "ST.MARYS AIDED MS-ORATHANADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4565",
            "district": "25",
            "Block": "216",
            "label": "PUMS - KAKKARAIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4835",
            "district": "25",
            "Block": "216",
            "label": "PUMS- NEDUVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5342",
            "district": "25",
            "Block": "216",
            "label": "PUMS- AMBALAPPATTU (S)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5695",
            "district": "25",
            "Block": "216",
            "label": "PUMS-SAMAYANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6378",
            "district": "25",
            "Block": "216",
            "label": "PUMS - MANDALAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6439",
            "district": "25",
            "Block": "216",
            "label": "PUMS - PANCHANATHIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16448",
            "district": "25",
            "Block": "216",
            "label": "PUMS - AZHIVAIKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16449",
            "district": "25",
            "Block": "216",
            "label": "GHS - AZHIAVAIKKAL NATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16450",
            "district": "25",
            "Block": "216",
            "label": "GHSS-ILLUPPAITHOPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16451",
            "district": "25",
            "Block": "216",
            "label": "PUMS-KUDIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16452",
            "district": "25",
            "Block": "216",
            "label": "GHS - ADHANAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16453",
            "district": "25",
            "Block": "216",
            "label": "GHSS - PAPPANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16454",
            "district": "25",
            "Block": "216",
            "label": "KASILINGAM MATRIC - PAPPANADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16455",
            "district": "25",
            "Block": "216",
            "label": "MMA  HIGHER SECONDARY SCHOOL-PAPPANADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16456",
            "district": "25",
            "Block": "216",
            "label": "GHS-SOLAPURAM(WEST)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16457",
            "district": "25",
            "Block": "216",
            "label": "GHSS - EACHANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16458",
            "district": "25",
            "Block": "216",
            "label": "GHS - KAKKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16459",
            "district": "25",
            "Block": "216",
            "label": "GHS - KANNANTHANKUDI (EAST)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16460",
            "district": "25",
            "Block": "216",
            "label": "GHS - KANNANTHANGUDI MELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16461",
            "district": "25",
            "Block": "216",
            "label": "GHSS - KANNUKUDI (WEST)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16462",
            "district": "25",
            "Block": "216",
            "label": "GHSS - KARUKKADIPPATTI (WEST)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16463",
            "district": "25",
            "Block": "216",
            "label": "PUMS - NELLUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16464",
            "district": "25",
            "Block": "216",
            "label": "GHS - KAVARAPPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16465",
            "district": "25",
            "Block": "216",
            "label": "GHS  - KULAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16466",
            "district": "25",
            "Block": "216",
            "label": "GHSS - MELAULUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16467",
            "district": "25",
            "Block": "216",
            "label": "GHSS-PANAYAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16468",
            "district": "25",
            "Block": "216",
            "label": "GHS - NADUVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16469",
            "district": "25",
            "Block": "216",
            "label": "GHSS - NEIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16470",
            "district": "25",
            "Block": "216",
            "label": "GHS(G) - OKKANADU KEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16471",
            "district": "25",
            "Block": "216",
            "label": "GHSS  - OKKANADU KEELAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16472",
            "district": "25",
            "Block": "216",
            "label": "GOVT. MPN H S- OKKANADU MELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16473",
            "district": "25",
            "Block": "216",
            "label": "GHSS-URANTHARAYANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16474",
            "district": "25",
            "Block": "216",
            "label": "GHSS -PATCHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16475",
            "district": "25",
            "Block": "216",
            "label": "PUMS - PARITHIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16476",
            "district": "25",
            "Block": "216",
            "label": "D.A. GHSS  - PONNAPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16477",
            "district": "25",
            "Block": "216",
            "label": "GHS-POOVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16478",
            "district": "25",
            "Block": "216",
            "label": "GHSS - POYYUNDARKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16479",
            "district": "25",
            "Block": "216",
            "label": "GHS - ARSUTHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16480",
            "district": "25",
            "Block": "216",
            "label": "GHS - SADAIYARKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16481",
            "district": "25",
            "Block": "216",
            "label": "GHS -SETHURAYANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16482",
            "district": "25",
            "Block": "216",
            "label": "PUMS - THALAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16483",
            "district": "25",
            "Block": "216",
            "label": "GHSS - THEKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16484",
            "district": "25",
            "Block": "216",
            "label": "SRI RAMAVILAS HS-THENNAMANADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16485",
            "district": "25",
            "Block": "216",
            "label": "GHSS - THIRUMANGALAKKOTTAI KEELAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16486",
            "district": "25",
            "Block": "216",
            "label": "GHS -THIRUMANGALAKKOTTAI MELA COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16487",
            "district": "25",
            "Block": "216",
            "label": "NALANDA MATRIC SCHOOL - THIRUMANAGALKOTTAI KEELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16488",
            "district": "25",
            "Block": "216",
            "label": "GHS-VADAKUR VADAKKU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16489",
            "district": "25",
            "Block": "216",
            "label": "GHSS - VADASERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16490",
            "district": "25",
            "Block": "216",
            "label": "GHSS-VANDAIYARIRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16491",
            "district": "25",
            "Block": "216",
            "label": "GHS-VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16492",
            "district": "25",
            "Block": "216",
            "label": "GHS-ORATHANADU PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16493",
            "district": "25",
            "Block": "216",
            "label": "GHSS (BOYS) - ORATHANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16494",
            "district": "25",
            "Block": "216",
            "label": "GHSS(G)-ORATHANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21475",
            "district": "25",
            "Block": "216",
            "label": "GHS - 34 KOVILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21862",
            "district": "25",
            "Block": "216",
            "label": "SRI SRI GNAN MANDIR MATRIC  HIGH  SCHOOL-VADAKKUR SOUTH -ORATHANADU THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22577",
            "district": "25",
            "Block": "216",
            "label": "SHANMUGAM MANDIR MATRIC HSS-PALAMPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22578",
            "district": "25",
            "Block": "216",
            "label": "SEETHARAM INTERNATIONAL  SCHOOL - PALAMPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23560",
            "district": "25",
            "Block": "216",
            "label": "SENTAMIL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23560",
            "district": "25",
            "Block": "216",
            "label": "SENTAMIL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23896",
            "district": "25",
            "Block": "216",
            "label": "GHS-VELLUR",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "217": [
        {
            "key": "834",
            "district": "28",
            "Block": "217",
            "label": "CMML MS NARAIKINARU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1668",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MS KOLLAMPARAMBU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1694",
            "district": "28",
            "Block": "217",
            "label": "PUMS,PULIYAMARATHUARASADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2085",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MIDDLE SCHOOL,ODAIPATTIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2087",
            "district": "28",
            "Block": "217",
            "label": "HINDU MIDDLE SCHOOL,SAMINATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2313",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MS, V. KALUKASALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2369",
            "district": "28",
            "Block": "217",
            "label": "PUMS ANAINTHAMADANPATCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2389",
            "district": "28",
            "Block": "217",
            "label": "PUMS,ELAVELANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2730",
            "district": "28",
            "Block": "217",
            "label": "MAHATMA MATRIC HR.SEC SCHOOL, PUTIYAMPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2849",
            "district": "28",
            "Block": "217",
            "label": "PRASANNA MATRIC HIGHER SECONDARY  SCHOOL  PUTHIYAMPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2881",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MS KOMBADI THALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3213",
            "district": "28",
            "Block": "217",
            "label": "INDHIRA GANDHI MAT. SCHOOL,OTTAPIDARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3617",
            "district": "28",
            "Block": "217",
            "label": "NMS MARUTHANVALVOO",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4375",
            "district": "28",
            "Block": "217",
            "label": "PUMS MUPPILIVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4562",
            "district": "28",
            "Block": "217",
            "label": "RAMANUJA HINDU MS OTTANATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4857",
            "district": "28",
            "Block": "217",
            "label": "LITTLE FLOWER N&P AKKAYANAYAKKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5131",
            "district": "28",
            "Block": "217",
            "label": "PUMS, SAKKAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5529",
            "district": "28",
            "Block": "217",
            "label": "HINDU NADAR MS,  ATHANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5696",
            "district": "28",
            "Block": "217",
            "label": "TNDTA MIDDLE SCHOOL,VEPPANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5911",
            "district": "28",
            "Block": "217",
            "label": "PUMS,THERKKUVEERA PANDIAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6499",
            "district": "28",
            "Block": "217",
            "label": "TDTA MS, KATCHERITHALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6632",
            "district": "28",
            "Block": "217",
            "label": "PUMS,ARAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6726",
            "district": "28",
            "Block": "217",
            "label": "RC MS PUTHIYAMPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19487",
            "district": "28",
            "Block": "217",
            "label": "TDTA HIGH SCHOOL, K.KAILASAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19488",
            "district": "28",
            "Block": "217",
            "label": "RC MS PULIYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19489",
            "district": "28",
            "Block": "217",
            "label": "GOVERNMENT HIGH SCHOOL PARIVILLIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19490",
            "district": "28",
            "Block": "217",
            "label": "MUTHUKARUPPAN HARIJAN HSS,SUBBAMMALPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19491",
            "district": "28",
            "Block": "217",
            "label": "T.D.T.A.K.G.M.HR.SEC.SCHOOL, S.KAILASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19492",
            "district": "28",
            "Block": "217",
            "label": "PUMS,OSANOOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19493",
            "district": "28",
            "Block": "217",
            "label": "PUMS,KEELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19494",
            "district": "28",
            "Block": "217",
            "label": "GHSS, PASUVANTHANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19495",
            "district": "28",
            "Block": "217",
            "label": "ST JOSEPHS HR.SEC.SCHOOL, KEELAMUDIMAN",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19496",
            "district": "28",
            "Block": "217",
            "label": "PUMS, VEERANSUNDARALINGAMNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19497",
            "district": "28",
            "Block": "217",
            "label": "V.O.C. GHSS, Ottapidaram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19498",
            "district": "28",
            "Block": "217",
            "label": "TMB MCAVOY RURAL HSS, OTTAPIDARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19499",
            "district": "28",
            "Block": "217",
            "label": "JOHN THE BAPTIST HSS PUTHIYAMPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19500",
            "district": "28",
            "Block": "217",
            "label": "GHSS KURUKKUSALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19501",
            "district": "28",
            "Block": "217",
            "label": "PU MS,KEELASEITHALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19502",
            "district": "28",
            "Block": "217",
            "label": "GHS, EPPODUMVENDRAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19503",
            "district": "28",
            "Block": "217",
            "label": "GHS, VEDANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19504",
            "district": "28",
            "Block": "217",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEPPALODAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19505",
            "district": "28",
            "Block": "217",
            "label": "PUMS, PATTNAMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19506",
            "district": "28",
            "Block": "217",
            "label": "GHS KOLLANKINARU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19507",
            "district": "28",
            "Block": "217",
            "label": "GHSS THARUVAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19508",
            "district": "28",
            "Block": "217",
            "label": "NATARAJAN HSS, KATTUNAYAKANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21039",
            "district": "28",
            "Block": "217",
            "label": "SPRING MATRICULATION SCHOOL, CHANDRAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22640",
            "district": "28",
            "Block": "217",
            "label": "GHS AKKANAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22848",
            "district": "28",
            "Block": "217",
            "label": "KING OF KINGS SCHOOL - CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23298",
            "district": "28",
            "Block": "217",
            "label": "CHANDRA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23575",
            "district": "28",
            "Block": "217",
            "label": "VELAVAN VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23575",
            "district": "28",
            "Block": "217",
            "label": "VELAVAN VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23897",
            "district": "28",
            "Block": "217",
            "label": "HNUP MATRICULATION SCHOOL PUTHIAMPUTHUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "218": [
        {
            "key": "631",
            "district": "6",
            "Block": "218",
            "label": "DHARMAPURI DIST. CO-OP SURGAR MILLS MATRIC SCHOOL, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1853",
            "district": "6",
            "Block": "218",
            "label": "St. LUCY'S MHSS, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2280",
            "district": "6",
            "Block": "218",
            "label": "SRI VIDYA MANDIR MHSS, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2378",
            "district": "6",
            "Block": "218",
            "label": "PUMS KADAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2486",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHICKAMARANDAHALLI PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2533",
            "district": "6",
            "Block": "218",
            "label": "Dr. KRISHNA VIKAS MHSS, MARANDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2562",
            "district": "6",
            "Block": "218",
            "label": "PUMS SEERIYANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2593",
            "district": "6",
            "Block": "218",
            "label": "PUMS VEPPALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2595",
            "district": "6",
            "Block": "218",
            "label": "PUMS ENDAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2602",
            "district": "6",
            "Block": "218",
            "label": "PUMS ERRANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2611",
            "district": "6",
            "Block": "218",
            "label": "PUMS PEDHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2646",
            "district": "6",
            "Block": "218",
            "label": "PUMS PADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2676",
            "district": "6",
            "Block": "218",
            "label": "PUMS PERUNGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2709",
            "district": "6",
            "Block": "218",
            "label": "PUMS KATTAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2753",
            "district": "6",
            "Block": "218",
            "label": "PUMS GIDDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2764",
            "district": "6",
            "Block": "218",
            "label": "PUMS SOODANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2770",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHIKKATHORANABETTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2812",
            "district": "6",
            "Block": "218",
            "label": "PUMS SAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2816",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHIKKARTHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2832",
            "district": "6",
            "Block": "218",
            "label": "PUMS ATHIMUTLU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2843",
            "district": "6",
            "Block": "218",
            "label": "PUMS THIMMAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2912",
            "district": "6",
            "Block": "218",
            "label": "PUMS PERIYANURPUTHUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2976",
            "district": "6",
            "Block": "218",
            "label": "PUMS SEENGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2990",
            "district": "6",
            "Block": "218",
            "label": "PUMS KADATHIKOLLU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3012",
            "district": "6",
            "Block": "218",
            "label": "PUMS ERRAGUNDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3139",
            "district": "6",
            "Block": "218",
            "label": "PUMS KADUKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3162",
            "district": "6",
            "Block": "218",
            "label": "PUMS SAKLINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3335",
            "district": "6",
            "Block": "218",
            "label": "PUMS BALAPPANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3496",
            "district": "6",
            "Block": "218",
            "label": "PUMS VEDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3583",
            "district": "6",
            "Block": "218",
            "label": "PUMS GUTHALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3733",
            "district": "6",
            "Block": "218",
            "label": "PUMS DODDARADHANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3750",
            "district": "6",
            "Block": "218",
            "label": "PUMS THIRUMALVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3754",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3936",
            "district": "6",
            "Block": "218",
            "label": "PUMS CHENNAPPANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4083",
            "district": "6",
            "Block": "218",
            "label": "PUMS NALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4115",
            "district": "6",
            "Block": "218",
            "label": "PUMS ULAGANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4151",
            "district": "6",
            "Block": "218",
            "label": "WISDOMLAND MATRIC SCHOOL, KARAGATHAHALLI, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4176",
            "district": "6",
            "Block": "218",
            "label": "PUMS BELAMARANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4194",
            "district": "6",
            "Block": "218",
            "label": "PUMS JERTHALAV",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4219",
            "district": "6",
            "Block": "218",
            "label": "PUMS PALACODE  URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4447",
            "district": "6",
            "Block": "218",
            "label": "SRI BHARATHI VIDYALAYA ACADEMY MHSS,  MARANDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4660",
            "district": "6",
            "Block": "218",
            "label": "PUMS GUMMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4892",
            "district": "6",
            "Block": "218",
            "label": "PUMS GENGANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5652",
            "district": "6",
            "Block": "218",
            "label": "JAI SAKTHI MHSS, KARTHARAPATTI, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6472",
            "district": "6",
            "Block": "218",
            "label": "PUMS KANSALBELU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9315",
            "district": "6",
            "Block": "218",
            "label": "GHSS AMANIMALLAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9316",
            "district": "6",
            "Block": "218",
            "label": "GHSS BELARAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9317",
            "district": "6",
            "Block": "218",
            "label": "GHSS BELLUHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9318",
            "district": "6",
            "Block": "218",
            "label": "GHS BOOGANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9319",
            "district": "6",
            "Block": "218",
            "label": "GHS BOPPIDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9320",
            "district": "6",
            "Block": "218",
            "label": "GHS GOLASANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9321",
            "district": "6",
            "Block": "218",
            "label": "GHSS KAMMALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9322",
            "district": "6",
            "Block": "218",
            "label": "KGBV RESIDENTIAL SCHOOL, AMANIMALLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9323",
            "district": "6",
            "Block": "218",
            "label": "GHS KOVILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9324",
            "district": "6",
            "Block": "218",
            "label": "GHSS MARANDAHALLI - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9325",
            "district": "6",
            "Block": "218",
            "label": "GHSS MARANDAHALLI - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9326",
            "district": "6",
            "Block": "218",
            "label": "GHS MATTIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9327",
            "district": "6",
            "Block": "218",
            "label": "GHSS PALACODE - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9328",
            "district": "6",
            "Block": "218",
            "label": "GHSS PALACODE - GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9329",
            "district": "6",
            "Block": "218",
            "label": "GHSS  PANJAPPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9330",
            "district": "6",
            "Block": "218",
            "label": "GOVT.THANTHAI PERIYAR JUVANILE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9331",
            "district": "6",
            "Block": "218",
            "label": "GHSS PULIKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9332",
            "district": "6",
            "Block": "218",
            "label": "GHS SASTHIRAMUTULU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9333",
            "district": "6",
            "Block": "218",
            "label": "GHS SEKKODI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9334",
            "district": "6",
            "Block": "218",
            "label": "SACRED HEART HR. SEC. SCHOOL, SELLIYAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9335",
            "district": "6",
            "Block": "218",
            "label": "GHSS THIRUMALVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9336",
            "district": "6",
            "Block": "218",
            "label": "PUMS NAMMANDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9337",
            "district": "6",
            "Block": "218",
            "label": "GHS SOMANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21199",
            "district": "6",
            "Block": "218",
            "label": "MODEL SCHOOL - PALACODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21660",
            "district": "6",
            "Block": "218",
            "label": "ST. AROKIA ANNAI SCHOOL, SELLIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22078",
            "district": "6",
            "Block": "218",
            "label": "GHS AGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22079",
            "district": "6",
            "Block": "218",
            "label": "GHS KANNUKARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22405",
            "district": "6",
            "Block": "218",
            "label": "SRI VENKATESHWARA  MHSS, CHIKKADORANABETTAM, MARANDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22406",
            "district": "6",
            "Block": "218",
            "label": "RADHA MATRIC SCHOOL, VELLICHANDHAI, PALACODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22407",
            "district": "6",
            "Block": "218",
            "label": "GHS VALAITHOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22944",
            "district": "6",
            "Block": "218",
            "label": "GHS EACHAMPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22945",
            "district": "6",
            "Block": "218",
            "label": "GHS JOGIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23479",
            "district": "6",
            "Block": "218",
            "label": "NEW GENERATION N&P SCHOOL, PANJAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23479",
            "district": "6",
            "Block": "218",
            "label": "NEW GENERATION N&P SCHOOL, PANJAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23898",
            "district": "6",
            "Block": "218",
            "label": "PUMS NALLAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23899",
            "district": "6",
            "Block": "218",
            "label": "GHS AGARAM",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "219": [
        {
            "key": "3397",
            "district": "7",
            "Block": "219",
            "label": "PRG VN MAT SCH,NEIKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3455",
            "district": "7",
            "Block": "219",
            "label": "DEVI MATRICULATION HIGHER SECONDARY SCHOOL,K. G. VALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4308",
            "district": "7",
            "Block": "219",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, AYAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4961",
            "district": "7",
            "Block": "219",
            "label": "CRESCENT MATRICULATION HR.SEC.SCHOOL,NEIKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13577",
            "district": "7",
            "Block": "219",
            "label": "ST JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL, JAWAHAR NAGAR, PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13578",
            "district": "7",
            "Block": "219",
            "label": "PUMS,KOTHAIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13579",
            "district": "7",
            "Block": "219",
            "label": "VINAYAK VIDYALAYA HSS, KANAKKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13580",
            "district": "7",
            "Block": "219",
            "label": "VANAN MS, KALIKKANAICKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13581",
            "district": "7",
            "Block": "219",
            "label": "JRC HIGHER SECONDARY SCHOOL, SHANMUGANATHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13582",
            "district": "7",
            "Block": "219",
            "label": "PUMS, VARATHAMANATHI ANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13583",
            "district": "7",
            "Block": "219",
            "label": "GHSS,BALASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13584",
            "district": "7",
            "Block": "219",
            "label": "VELAN VIKAS MAT SCH, PM SHED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13585",
            "district": "7",
            "Block": "219",
            "label": "PUMS,THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13586",
            "district": "7",
            "Block": "219",
            "label": "PUMS, K.VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13587",
            "district": "7",
            "Block": "219",
            "label": "GHS,A.KALAYAMPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13588",
            "district": "7",
            "Block": "219",
            "label": "GHS,CHINNAGANDHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13589",
            "district": "7",
            "Block": "219",
            "label": "SARASWATHI HIGH SCHOOL, AMARAPOONDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13590",
            "district": "7",
            "Block": "219",
            "label": "PUMS, GOMBAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13591",
            "district": "7",
            "Block": "219",
            "label": "GHS,KANAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13592",
            "district": "7",
            "Block": "219",
            "label": "ITO HIGHER SECONDARY SCHOOL,AYAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13593",
            "district": "7",
            "Block": "219",
            "label": "GHS,AYAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13594",
            "district": "7",
            "Block": "219",
            "label": "PUMS, OBULAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13595",
            "district": "7",
            "Block": "219",
            "label": "PUMS,PERUMALPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13596",
            "district": "7",
            "Block": "219",
            "label": "PUMS,R.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13597",
            "district": "7",
            "Block": "219",
            "label": "PUMS, PETHANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13598",
            "district": "7",
            "Block": "219",
            "label": "GURUVAPPA HSS,NEIKARAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13599",
            "district": "7",
            "Block": "219",
            "label": "RENUGADEVI HSS,NEIKARAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13600",
            "district": "7",
            "Block": "219",
            "label": "RIPS,NEIKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13601",
            "district": "7",
            "Block": "219",
            "label": "PUMS,PERIYAKALAYAMPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13602",
            "district": "7",
            "Block": "219",
            "label": "PUMS, VIJAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13603",
            "district": "7",
            "Block": "219",
            "label": "PUMS, AKKAMANAICKENPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13604",
            "district": "7",
            "Block": "219",
            "label": "PUMS, KUTHIRAIYARU ANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13605",
            "district": "7",
            "Block": "219",
            "label": "PUMS,LAKSHMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13606",
            "district": "7",
            "Block": "219",
            "label": "PUMS, ANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13607",
            "district": "7",
            "Block": "219",
            "label": "PUMS,V.P.PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13608",
            "district": "7",
            "Block": "219",
            "label": "PUMS, KUPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13609",
            "district": "7",
            "Block": "219",
            "label": "GHSS,PAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13610",
            "district": "7",
            "Block": "219",
            "label": "PUMS,KAVALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13611",
            "district": "7",
            "Block": "219",
            "label": "PUMS, PONTHUPULLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23375",
            "district": "7",
            "Block": "219",
            "label": "RAINBOW NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "21028",
            "district": "7",
            "Block": "219",
            "label": "SREE GUNA MATRIC, AMARAPOONDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21160",
            "district": "7",
            "Block": "219",
            "label": "SWAMY MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21161",
            "district": "7",
            "Block": "219",
            "label": "BHAARATH VIDHYA BHAVAN MAT.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21278",
            "district": "7",
            "Block": "219",
            "label": "ST.PAUL S MATRICULATION.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22180",
            "district": "7",
            "Block": "219",
            "label": "AWARD SPECIAL SCHOOL FOR MR, SIVAGIRIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22506",
            "district": "7",
            "Block": "219",
            "label": "HAYAGRIVAR MATRIC HIGHER SECONDARY SCHOOL, RUKKUVARPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22995",
            "district": "7",
            "Block": "219",
            "label": "BHAARATH PUBLIC SCHOOL, PALANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23140",
            "district": "7",
            "Block": "219",
            "label": "BRILLIANT KIDS GLOBAL SCHOOL(CBSE), KANAKKANPATTY, PALANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23193",
            "district": "7",
            "Block": "219",
            "label": "HOLY CROSS SCHOOL (CBSE), PALANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "220": [
        {
            "key": "3382",
            "district": "7",
            "Block": "220",
            "label": "ST. PETER'S MATRICULATION SCHOOL,PALANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3811",
            "district": "7",
            "Block": "220",
            "label": "KARTHIK VIDHYA MANDIR MATRIC HIGHER SECONDARY SCHOOL, PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4058",
            "district": "7",
            "Block": "220",
            "label": "AKSHAYA ACADEMY MATRIC HSS,PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4998",
            "district": "7",
            "Block": "220",
            "label": "ARULMIGU PALANIANDAVAR MATRICULATION HSS,PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6116",
            "district": "7",
            "Block": "220",
            "label": "KENNEDY MATRICULATION SCHOOL, PALANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13816",
            "district": "7",
            "Block": "220",
            "label": "C.S.I WAKEMAN MS,PALANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13817",
            "district": "7",
            "Block": "220",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PALANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13818",
            "district": "7",
            "Block": "220",
            "label": "LITTLE FLOWER HIGH SCHOOL,PALANI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13819",
            "district": "7",
            "Block": "220",
            "label": "MATHANISHA MUSLIM HS,PALANI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13820",
            "district": "7",
            "Block": "220",
            "label": "MUNICIPAL BAZZAR MS,PALANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13821",
            "district": "7",
            "Block": "220",
            "label": "MMS,SHANMUGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13822",
            "district": "7",
            "Block": "220",
            "label": "STANDARD MATRICULATION SCHOOL,PALANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13823",
            "district": "7",
            "Block": "220",
            "label": "AKSHAYA ACADEMY CBSE SCHOOL,PALANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13824",
            "district": "7",
            "Block": "220",
            "label": "MUNICIPAL MS,ADIVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13825",
            "district": "7",
            "Block": "220",
            "label": "MUNICIPAL(B) HSS,PALANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13826",
            "district": "7",
            "Block": "220",
            "label": "THIRUVALLUVAR MS,PALANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        }
    ],
    "221": [
        {
            "key": "1046",
            "district": "30",
            "Block": "221",
            "label": "TIME MATRIC HIGHER SECONDARY SCHOOL, ARAIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1264",
            "district": "30",
            "Block": "221",
            "label": "SARATHA MHS SANKARNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1632",
            "district": "30",
            "Block": "221",
            "label": "IIPE LAXMI RAMAN MATRIC HIGHER SECONDARY SCHOOL UTHAMAPANDIANKULAM 627351",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1903",
            "district": "30",
            "Block": "221",
            "label": "SRI JEYANDRA SARASWATHI SWAMIGAL GOLDEN JMHSS SANKARNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2032",
            "district": "30",
            "Block": "221",
            "label": "ROSEMARY MHSS, V.M.CHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5178",
            "district": "30",
            "Block": "221",
            "label": "PUMS,KEELATHIDIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5626",
            "district": "30",
            "Block": "221",
            "label": "THIRU THAYUMANASUNDARAM MS, SIVANTHIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6741",
            "district": "30",
            "Block": "221",
            "label": "RADASAMY PST .V NATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19791",
            "district": "30",
            "Block": "221",
            "label": "GHS,SEEVALAPERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19792",
            "district": "30",
            "Block": "221",
            "label": "R.C.MS, THONITHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19793",
            "district": "30",
            "Block": "221",
            "label": "HARIHAR MIDDLE SCHOOL , MELAPATTAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19794",
            "district": "30",
            "Block": "221",
            "label": "TDTAMS MANARKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19795",
            "district": "30",
            "Block": "221",
            "label": "PUMS, NADUVAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19796",
            "district": "30",
            "Block": "221",
            "label": "GHSS, BURKITMANAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19797",
            "district": "30",
            "Block": "221",
            "label": "SRI CHELLIAH MS MELAPUTHANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19798",
            "district": "30",
            "Block": "221",
            "label": "TDTA MIDDLE SCHOOL ,MELAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19799",
            "district": "30",
            "Block": "221",
            "label": "SRI SARADA VIDYALAYA HIGH SCHOOL, ARIYAKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19800",
            "district": "30",
            "Block": "221",
            "label": "SRI SARADA MATRIC HIGHER SECONDARY SCHOOL, ARIYAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19801",
            "district": "30",
            "Block": "221",
            "label": "GHS, KUTHUKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19802",
            "district": "30",
            "Block": "221",
            "label": "OASIS MHSS, TUTICORIN ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19803",
            "district": "30",
            "Block": "221",
            "label": "ST.JOSEPH'S HS, V M AROCKIANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19804",
            "district": "30",
            "Block": "221",
            "label": "ST.ANTONY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19805",
            "district": "30",
            "Block": "221",
            "label": "KRGHSS, REDDIARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19806",
            "district": "30",
            "Block": "221",
            "label": "T.D.T.A. JAYARAJ ANNAPACKIAM MATRIC HR. SEC. SCHOOL, TUCKKARAMMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19807",
            "district": "30",
            "Block": "221",
            "label": "TDTA MS,ARAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19808",
            "district": "30",
            "Block": "221",
            "label": "GHSS, MUNNEERPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19809",
            "district": "30",
            "Block": "221",
            "label": "GHS, THARUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19810",
            "district": "30",
            "Block": "221",
            "label": "PUMS, SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19811",
            "district": "30",
            "Block": "221",
            "label": "TDTA MS, OMANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19812",
            "district": "30",
            "Block": "221",
            "label": "TDTA MS, KONGANTHANPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19813",
            "district": "30",
            "Block": "221",
            "label": "TDTA MS, SIVANTHIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19814",
            "district": "30",
            "Block": "221",
            "label": "GOVT ADW MS, PUTHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19815",
            "district": "30",
            "Block": "221",
            "label": "ST.JOSEPH HS, NARANAMMALPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19816",
            "district": "30",
            "Block": "221",
            "label": "PUMS, URUDAYARKUDIYERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19817",
            "district": "30",
            "Block": "221",
            "label": "THIRU TKM KAILASAMPILLAI MS, THATCHANNALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19818",
            "district": "30",
            "Block": "221",
            "label": "BUNGALOW MS, THATCHANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19819",
            "district": "30",
            "Block": "221",
            "label": "VEDIC VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19820",
            "district": "30",
            "Block": "221",
            "label": "ADI DRAVIDA HS, RAMAIANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19821",
            "district": "30",
            "Block": "221",
            "label": "GHS, KAMMALANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19822",
            "district": "30",
            "Block": "221",
            "label": "PONNUSAMY MS, RAJAVALLIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19823",
            "district": "30",
            "Block": "221",
            "label": "SRI AYYACHAMY MS, KARAIYERUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19824",
            "district": "30",
            "Block": "221",
            "label": "GHS, CHATHIRAMKUDIYERUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19825",
            "district": "30",
            "Block": "221",
            "label": "KENBRIDGE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19826",
            "district": "30",
            "Block": "221",
            "label": "Venkadasamy Naidu Hr. Sec. School, Sankarnagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19827",
            "district": "30",
            "Block": "221",
            "label": "SANKAR HR SEC SCHOOL, SANKARNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19828",
            "district": "30",
            "Block": "221",
            "label": "GITA KRISHNMORTHY VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19829",
            "district": "30",
            "Block": "221",
            "label": "ST.ANNES  PHYSICALY HANDICAPPED MIDDLE SCHOOL, ANAIARKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19830",
            "district": "30",
            "Block": "221",
            "label": "PUNCHAYAT MIDDLE SCHOOL, PIRANTHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21118",
            "district": "30",
            "Block": "221",
            "label": "ROSE MARY PUBLIC SCHOOL VM CHATHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21833",
            "district": "30",
            "Block": "221",
            "label": "HOLY ANGELS BCV INTERNATIONAL SCHOOL, CAUSSANELPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21834",
            "district": "30",
            "Block": "221",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL,MUNNERPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22280",
            "district": "30",
            "Block": "221",
            "label": "RADASAMY HS MELATHIRUVENKATANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22650",
            "district": "30",
            "Block": "221",
            "label": "SRI JAYENDRA SWAMIGAL VIDHYA KENDRA SCHOOL VM CHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22651",
            "district": "30",
            "Block": "221",
            "label": "MUTHAMIL PUBLIC SCHOOL, MUNNERPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23161",
            "district": "30",
            "Block": "221",
            "label": "MEENA SANKAR VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23189",
            "district": "30",
            "Block": "221",
            "label": "VELS VIDHYALAYA CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23280",
            "district": "30",
            "Block": "221",
            "label": "KAMARAJAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23285",
            "district": "30",
            "Block": "221",
            "label": "IIP LAKSHMI RAMAN GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23588",
            "district": "30",
            "Block": "221",
            "label": "Government Model School, Tirunelveli",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23588",
            "district": "30",
            "Block": "221",
            "label": "Government Model School, Tirunelveli",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23900",
            "district": "30",
            "Block": "221",
            "label": "KAMARAJAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23901",
            "district": "30",
            "Block": "221",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "222": [
        {
            "key": "558",
            "district": "30",
            "Block": "222",
            "label": "MARY SARGENT HIGHER SECONDARY SCHOOL FOR GIRLS,PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "633",
            "district": "30",
            "Block": "222",
            "label": "Sri Jayendra Swamigal Silver jubilee MHSS  Maharaja Nagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "710",
            "district": "30",
            "Block": "222",
            "label": "SRI LALITHA VIDHYASHRAM MATRICULATION SCHOOL  PERUMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "716",
            "district": "30",
            "Block": "222",
            "label": "PUSHPALATHA MHSS TIRUNELVELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "773",
            "district": "30",
            "Block": "222",
            "label": "MANIVASAKAR ARULNERI MS PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "783",
            "district": "30",
            "Block": "222",
            "label": "HAMEEM MS MELAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1220",
            "district": "30",
            "Block": "222",
            "label": "ST.JOSEPH'S MATRICULATION SCHOOL SENTHILNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1830",
            "district": "30",
            "Block": "222",
            "label": "DON BOSCO MHSS-XAVIERCOLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1906",
            "district": "30",
            "Block": "222",
            "label": "AUPET CHINMAYA VID MHSS PALAY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1930",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY MHSS MAIGANAST , PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2019",
            "district": "30",
            "Block": "222",
            "label": "BELL ENGLISH MHSS PALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2081",
            "district": "30",
            "Block": "222",
            "label": "MEGDALIN MHSS RAHMATH NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2164",
            "district": "30",
            "Block": "222",
            "label": "GOOD SAMARITTAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2185",
            "district": "30",
            "Block": "222",
            "label": "CORPN NEW MS VANNARPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2199",
            "district": "30",
            "Block": "222",
            "label": "THE GOLDEN JUBILEE MATRIC HSS MELAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2206",
            "district": "30",
            "Block": "222",
            "label": "C.S.I. MHSS PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2207",
            "district": "30",
            "Block": "222",
            "label": "SRI SATHYA SAI GURUGULAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2293",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY MHSS MAHARAJANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2482",
            "district": "30",
            "Block": "222",
            "label": "ST PAULS MATRIC SCHOOL PERUMALPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2510",
            "district": "30",
            "Block": "222",
            "label": "JOSE MHSS POTHIGAI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2740",
            "district": "30",
            "Block": "222",
            "label": "SRInGERI SARAdA VIDYALAYA MATRIC HR SEC SCHOOL THIYAGARAJANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2798",
            "district": "30",
            "Block": "222",
            "label": "JESURAJA HITECH MATRICULATION SCHOOL,PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3051",
            "district": "30",
            "Block": "222",
            "label": "CORPN MS PERUMAL SANNATHI , PALAYAMKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3404",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY MHSS PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3662",
            "district": "30",
            "Block": "222",
            "label": "CMS MANAKAVALAM HOSPITAL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3835",
            "district": "30",
            "Block": "222",
            "label": "Sarah Tucker Hr Sec School For Girls, Palayamkottai",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4328",
            "district": "30",
            "Block": "222",
            "label": "RAHMANIA MHSS MELAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4510",
            "district": "30",
            "Block": "222",
            "label": "CMS MARY ARDEN MS PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4656",
            "district": "30",
            "Block": "222",
            "label": "CHRISTHU RAJA HSS PALAY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5460",
            "district": "30",
            "Block": "222",
            "label": "GANESA MOORTHY MS KEELAVEERA RAGAVAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5586",
            "district": "30",
            "Block": "222",
            "label": "Oxfo Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6142",
            "district": "30",
            "Block": "222",
            "label": "ANGELO MHSS PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20024",
            "district": "30",
            "Block": "222",
            "label": "MANORANJITHAM MS,KOKKIRAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20025",
            "district": "30",
            "Block": "222",
            "label": "BISHOP SARGENT MS,PALAYAMKOTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20026",
            "district": "30",
            "Block": "222",
            "label": "CATHEDRAL  HSS, PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20027",
            "district": "30",
            "Block": "222",
            "label": "VIVEKANANDA VIDHYASHRAM MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20028",
            "district": "30",
            "Block": "222",
            "label": "BISHOP SARGENT MENTALLY RETARDED",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20029",
            "district": "30",
            "Block": "222",
            "label": "ADITHYA VIDHYA NIKETAN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20030",
            "district": "30",
            "Block": "222",
            "label": "PUSHPALATA VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20031",
            "district": "30",
            "Block": "222",
            "label": "HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20032",
            "district": "30",
            "Block": "222",
            "label": "SRI KANTHIMATHI  AMBAL GIRLS HSS HIGHGROUND",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20033",
            "district": "30",
            "Block": "222",
            "label": "M.N.ABDUR RAHMAN HSS TIRUNELVELI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20034",
            "district": "30",
            "Block": "222",
            "label": "ST.JOHNS HSS PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20035",
            "district": "30",
            "Block": "222",
            "label": "FATIMA ORIENTAL ARABIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20036",
            "district": "30",
            "Block": "222",
            "label": "USBORNE MS PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20037",
            "district": "30",
            "Block": "222",
            "label": "SERVITE MATRIC HIGHER SECONDARY SCHOOL, PALAYAMKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20038",
            "district": "30",
            "Block": "222",
            "label": "CHILD JESUS GIRLS HSS, PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20039",
            "district": "30",
            "Block": "222",
            "label": "ST. IGNATIUS CONVENT HSS, PALAYAMKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20040",
            "district": "30",
            "Block": "222",
            "label": "ST.XAVIERS HSS PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20041",
            "district": "30",
            "Block": "222",
            "label": "KATHIR VIDYALAYA N&PS  PERUMAL PURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20042",
            "district": "30",
            "Block": "222",
            "label": "ST.JOSEPH'S GIRLS HIGHER SECONDARY SCHOOL,JAWAHAR NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20043",
            "district": "30",
            "Block": "222",
            "label": "GOVT.OBSERVATION HOME",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20044",
            "district": "30",
            "Block": "222",
            "label": "FLORENCE SWAISON HSS(D&F),PALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20045",
            "district": "30",
            "Block": "222",
            "label": "RAHMANIA HSS MELAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23364",
            "district": "30",
            "Block": "222",
            "label": "Good Shepherd school",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "20046",
            "district": "30",
            "Block": "222",
            "label": "ST.THOMAS HS KURICHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20047",
            "district": "30",
            "Block": "222",
            "label": "THE MUSLIM HSS MELAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20048",
            "district": "30",
            "Block": "222",
            "label": "Punitha Suvakkin Matric School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20049",
            "district": "30",
            "Block": "222",
            "label": "QUAIDE MILLETH CORPORATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20050",
            "district": "30",
            "Block": "222",
            "label": "MUSLIM GIRLS HSS MELAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20999",
            "district": "30",
            "Block": "222",
            "label": "PUSHPALATHA BRITISH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21233",
            "district": "30",
            "Block": "222",
            "label": "ST ANNE S REHABILITATION CENTRE FOR THE MENTALLY HANDICAPPED",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21344",
            "district": "30",
            "Block": "222",
            "label": "FRANCIS XAVIER SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21824",
            "district": "30",
            "Block": "222",
            "label": "AL-MADINAH PUBLIC SCHOOL(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22661",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY MODEL PUBLIC SCHOOL RAMACHANDRA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23074",
            "district": "30",
            "Block": "222",
            "label": "ROSE MARY SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23173",
            "district": "30",
            "Block": "222",
            "label": "ZAREENA FATHIMA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23589",
            "district": "30",
            "Block": "222",
            "label": "TIRUNELVELI DISTRICT GOVERNMENT MODEL  SCHOOL ,TIRUNELVELI ",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23589",
            "district": "30",
            "Block": "222",
            "label": "TIRUNELVELI DISTRICT GOVERNMENT MODEL  SCHOOL ,TIRUNELVELI ",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23902",
            "district": "30",
            "Block": "222",
            "label": "GOVT SERVICE HOME HSS, PALAI",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23903",
            "district": "30",
            "Block": "222",
            "label": "MITRA SCHOOL FOR AUTISM",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "223": [
        {
            "key": "421",
            "district": "32",
            "Block": "223",
            "label": "LITERACY MISSION MATRIC HR SEC SCHOOL, SAMALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "690",
            "district": "32",
            "Block": "223",
            "label": "V.A.T TRUST MATRIC HR SEC SCHOOL, GANAPATHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "762",
            "district": "32",
            "Block": "223",
            "label": "BHARATHI MATRIC HR SEC SCHOOL, P.VADUGAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "788",
            "district": "32",
            "Block": "223",
            "label": "PUMS ALLALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "798",
            "district": "32",
            "Block": "223",
            "label": "JAYANTHI MATRIC HR SEC SCHOOL, ARULPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "807",
            "district": "32",
            "Block": "223",
            "label": "PUMS MANICKAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "867",
            "district": "32",
            "Block": "223",
            "label": "PUMS RAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "881",
            "district": "32",
            "Block": "223",
            "label": "PUMS ANUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "892",
            "district": "32",
            "Block": "223",
            "label": "PUMS PETHAMPOOCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "915",
            "district": "32",
            "Block": "223",
            "label": "LAKSHMI MILLS MIDDLE SCHOOL(AIDED)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "918",
            "district": "32",
            "Block": "223",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL PALLAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "920",
            "district": "32",
            "Block": "223",
            "label": "PUMS MADHESWARANNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "938",
            "district": "32",
            "Block": "223",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "968",
            "district": "32",
            "Block": "223",
            "label": "PUMS KARAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1013",
            "district": "32",
            "Block": "223",
            "label": "TELC MS EAST PALLADAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1030",
            "district": "32",
            "Block": "223",
            "label": "PUMS KUNNANGALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1033",
            "district": "32",
            "Block": "223",
            "label": "PUMS PARUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1040",
            "district": "32",
            "Block": "223",
            "label": "KANNAMMAL NATIONAL MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1042",
            "district": "32",
            "Block": "223",
            "label": "PUMS CHINNIYAGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1056",
            "district": "32",
            "Block": "223",
            "label": "PUMS SEDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1106",
            "district": "32",
            "Block": "223",
            "label": "PUMS K.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1109",
            "district": "32",
            "Block": "223",
            "label": "PUMS KALLAMPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1155",
            "district": "32",
            "Block": "223",
            "label": "PUMS PALLADAM- WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1297",
            "district": "32",
            "Block": "223",
            "label": "PARK MATRIC HR SEC SCHOOL, CHINNAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1298",
            "district": "32",
            "Block": "223",
            "label": "BLUE BIRD MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1328",
            "district": "32",
            "Block": "223",
            "label": "PUMS EAST PALLADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1855",
            "district": "32",
            "Block": "223",
            "label": "INFANT JESUS MATRIC HR SEC SCHOOL, SUKKAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2651",
            "district": "32",
            "Block": "223",
            "label": "KATHIRAVAN MATRIC HR.SEC SCHOOL, POOMALUR, MANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3242",
            "district": "32",
            "Block": "223",
            "label": "SWAMY VIVEKANANDHA MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4575",
            "district": "32",
            "Block": "223",
            "label": "UNIVERSAL MATRIC HR SEC SCHOOL, SEDAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13174",
            "district": "32",
            "Block": "223",
            "label": "SCAD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13175",
            "district": "32",
            "Block": "223",
            "label": "GHSS GANAPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13176",
            "district": "32",
            "Block": "223",
            "label": "S L N M HR SEC SCHOOL KARADIVAVI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13177",
            "district": "32",
            "Block": "223",
            "label": "GHSS ARULPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13178",
            "district": "32",
            "Block": "223",
            "label": "GHS KARANAMPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13179",
            "district": "32",
            "Block": "223",
            "label": "GHS KODANGIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13180",
            "district": "32",
            "Block": "223",
            "label": "GHS KAMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13181",
            "district": "32",
            "Block": "223",
            "label": "GHS SEGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13182",
            "district": "32",
            "Block": "223",
            "label": "GHS ARIVOLINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13183",
            "district": "32",
            "Block": "223",
            "label": "GOVERNMENT  GIRLS HIGHER SECONDARY SCHOOL PALLADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13184",
            "district": "32",
            "Block": "223",
            "label": "GOVT BOYS HR SEC SCHOOL PALLADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13185",
            "district": "32",
            "Block": "223",
            "label": "GHS P.VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13186",
            "district": "32",
            "Block": "223",
            "label": "VALLALAR GURUKULAM HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13187",
            "district": "32",
            "Block": "223",
            "label": "GHS POOMALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13188",
            "district": "32",
            "Block": "223",
            "label": "GHS NADUVELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13189",
            "district": "32",
            "Block": "223",
            "label": "MOUNT LITERA ZEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13190",
            "district": "32",
            "Block": "223",
            "label": "A V A T HR SEC SCHOOL SAMALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13191",
            "district": "32",
            "Block": "223",
            "label": "GHS KARUGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13192",
            "district": "32",
            "Block": "223",
            "label": "GHSS SAMIGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13193",
            "district": "32",
            "Block": "223",
            "label": "GHS 63 VELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21157",
            "district": "32",
            "Block": "223",
            "label": "THIRU JANARTHANA MATRIC HR SEC SCHOOL, KARADIVAVI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21187",
            "district": "32",
            "Block": "223",
            "label": "THE INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21275",
            "district": "32",
            "Block": "223",
            "label": "RAJA NATIONAL MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21635",
            "district": "32",
            "Block": "223",
            "label": "YOUNG INDIA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21947",
            "district": "32",
            "Block": "223",
            "label": "ADHARSH VIDHYALAYA MATRIC HR SEC SCHOOL, PALLADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22152",
            "district": "32",
            "Block": "223",
            "label": "JAYANTHI PUBLIC SENIOR SECONDARY SCHOOL ,ARULPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22153",
            "district": "32",
            "Block": "223",
            "label": "ADHARSH VIDHYALAYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22154",
            "district": "32",
            "Block": "223",
            "label": "SHIVA NIKETAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22490",
            "district": "32",
            "Block": "223",
            "label": "GHS KALINATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22901",
            "district": "32",
            "Block": "223",
            "label": "KEY STAGE SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22918",
            "district": "32",
            "Block": "223",
            "label": "GHS ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23150",
            "district": "32",
            "Block": "223",
            "label": "WHITE CLOUDS WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23596",
            "district": "32",
            "Block": "223",
            "label": "VENKATESHWHARA VIDHYA MANDHIR SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        { 
            "key":"24118", "district":"32", 
            "Block":"223", 
            "label": "Devathai Pri &Nursary School", 
            "Sch_type": "Un-aided", 
            "Category": "Middle School"
        },
        {
            "key": "23596",
            "district": "32",
            "Block": "223",
            "label": "VENKATESHWHARA VIDHYA MANDHIR SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23904",
            "district": "32",
            "Block": "223",
            "label": "INFANT JESUS PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "224": [
        {
            "key": "1481",
            "district": "17",
            "Block": "224",
            "label": "PUMS ALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1529",
            "district": "17",
            "Block": "224",
            "label": "PUMS ELANTHAKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1543",
            "district": "17",
            "Block": "224",
            "label": "PUMS THOTTIKARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1944",
            "district": "17",
            "Block": "224",
            "label": "PUMS KADACHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2203",
            "district": "17",
            "Block": "224",
            "label": "SSRI VALLIAPPA VIDHYALAYAM MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2368",
            "district": "17",
            "Block": "224",
            "label": "PUMS PUDUPALAYAM CAUVERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2419",
            "district": "17",
            "Block": "224",
            "label": "PUMS  VILANKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2650",
            "district": "17",
            "Block": "224",
            "label": "PUMS SANARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3679",
            "district": "17",
            "Block": "224",
            "label": "PUMS T.VASUKI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        { 
            "key":"24119", "district":"17", 
            "Block":"224", "label": "Sbg nursery and primary school", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "3768",
            "district": "17",
            "Block": "224",
            "label": "PUMS ALAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3836",
            "district": "17",
            "Block": "224",
            "label": "MMS NARAYANA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4237",
            "district": "17",
            "Block": "224",
            "label": "MMS WEST COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4875",
            "district": "17",
            "Block": "224",
            "label": "PUMS VEERAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5142",
            "district": "17",
            "Block": "224",
            "label": "JAI KANNAMMAL KALVI NILAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5380",
            "district": "17",
            "Block": "224",
            "label": "THE AVS MATRICULATION SCHOOL, KOMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6011",
            "district": "17",
            "Block": "224",
            "label": "VIGHYAAN VIKASH MATRICULATION SCHOOL KOMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23379",
            "district": "17",
            "Block": "224",
            "label": "Annai sellammal matriculation school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "6067",
            "district": "17",
            "Block": "224",
            "label": "CSI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6327",
            "district": "17",
            "Block": "224",
            "label": "SPB MATRIC HSS SPB COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6593",
            "district": "17",
            "Block": "224",
            "label": "JKK NATTRAJA MATRIC HSS, KUMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11917",
            "district": "17",
            "Block": "224",
            "label": "HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL KUMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11918",
            "district": "17",
            "Block": "224",
            "label": "GHSS KULLANAICKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11919",
            "district": "17",
            "Block": "224",
            "label": "GHSS(BOYS) KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11920",
            "district": "17",
            "Block": "224",
            "label": "RELIANCE MATRIC HR SEC SCHOOL, KUPPANDAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11921",
            "district": "17",
            "Block": "224",
            "label": "SSM LAKSHMIAMMAL MATRIC HSS, KUMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11922",
            "district": "17",
            "Block": "224",
            "label": "POORNAMATHI MATRIC HIGHER SECONDARY SCHOOL, KUMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11923",
            "district": "17",
            "Block": "224",
            "label": "Govt. Model Girls Hr.Sec. School, KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11924",
            "district": "17",
            "Block": "224",
            "label": "JKK RANGAMMAL GIRLS HSS KUMARAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11925",
            "district": "17",
            "Block": "224",
            "label": "ROYAL INTERNATIONAL  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11926",
            "district": "17",
            "Block": "224",
            "label": "MHS BUDHAR STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11927",
            "district": "17",
            "Block": "224",
            "label": "SSM CENTRAL SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11928",
            "district": "17",
            "Block": "224",
            "label": "PUMS V.METTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11929",
            "district": "17",
            "Block": "224",
            "label": "EINSSTEIN MATRIC HIGHER SECONDARY SCHOOL, KATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11930",
            "district": "17",
            "Block": "224",
            "label": "MUNIRAJAH MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11931",
            "district": "17",
            "Block": "224",
            "label": "NEHRU MEMORIAL SAMPOORANIAMMAL HR SEC SCHOOL FOR DIFFERENTLY ABLED, ETHIRMEDU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11932",
            "district": "17",
            "Block": "224",
            "label": "GHS VEMANKATTUVALASU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11933",
            "district": "17",
            "Block": "224",
            "label": "GHS ARUVANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11934",
            "district": "17",
            "Block": "224",
            "label": "GHS KALIYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11935",
            "district": "17",
            "Block": "224",
            "label": "RATHNA MATRICULATION SCHOOL PALLIPALAYAM AGRAHARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11936",
            "district": "17",
            "Block": "224",
            "label": "GHSS(BOYS) PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11937",
            "district": "17",
            "Block": "224",
            "label": "GHS VEDIYARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11938",
            "district": "17",
            "Block": "224",
            "label": "GHS MAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11939",
            "district": "17",
            "Block": "224",
            "label": "GHSS VEPPADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11940",
            "district": "17",
            "Block": "224",
            "label": "VANI VIDYALAYA MATRIC HSS UPPUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11941",
            "district": "17",
            "Block": "224",
            "label": "GHS E.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11942",
            "district": "17",
            "Block": "224",
            "label": "GHSS PALLAKKAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11943",
            "district": "17",
            "Block": "224",
            "label": "GHSS SOWDHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11944",
            "district": "17",
            "Block": "224",
            "label": "KRP MATRIC HSS PACHAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23414",
            "district": "17",
            "Block": "224",
            "label": "Sri Akilandeswari nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "11945",
            "district": "17",
            "Block": "224",
            "label": "SANKAR HIGHER SECONDARY SCHOOL SANKARI WEST",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11946",
            "district": "17",
            "Block": "224",
            "label": "GHS PADAIVEEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11947",
            "district": "17",
            "Block": "224",
            "label": "SPK MATRIC HSS KADACHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11948",
            "district": "17",
            "Block": "224",
            "label": "SRRI SPK PUBLIC SENIOR SECONDARY SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11949",
            "district": "17",
            "Block": "224",
            "label": "SPB  HIGH SCHOOL(AIDED)",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11950",
            "district": "17",
            "Block": "224",
            "label": "VALLABHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "11951",
            "district": "17",
            "Block": "224",
            "label": "GHSS(GIRLS) PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11952",
            "district": "17",
            "Block": "224",
            "label": "GHS CAUVERY R S",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11953",
            "district": "17",
            "Block": "224",
            "label": "GHSS PAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11954",
            "district": "17",
            "Block": "224",
            "label": "GHSS KOKKARAYANPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21268",
            "district": "17",
            "Block": "224",
            "label": "SRK MATRICULATION SCHOOL, PALLAKKAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22123",
            "district": "17",
            "Block": "224",
            "label": "GREEN LAND MATRICULATION SCHOOL, METTUKADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22449",
            "district": "17",
            "Block": "224",
            "label": "MHS CHINNAPPANAYAKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22450",
            "district": "17",
            "Block": "224",
            "label": "SRI VANI INTERNATIONAL SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22451",
            "district": "17",
            "Block": "224",
            "label": "VIGNESH VIDHYAA BHAVAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22826",
            "district": "17",
            "Block": "224",
            "label": "AVS GIRLS MATRICULATION HIGHER SECONDARY SCHOOL, KOMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22969",
            "district": "17",
            "Block": "224",
            "label": "GHS AVATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23178",
            "district": "17",
            "Block": "224",
            "label": "SRI RAGAVENDRA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23283",
            "district": "17",
            "Block": "224",
            "label": "K.S.INDIAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23414",
            "district": "17",
            "Block": "224",
            "label": "Sri Akilandeswari nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23414",
            "district": "17",
            "Block": "224",
            "label": "Sri Akilandeswari nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23414",
            "district": "17",
            "Block": "224",
            "label": "Sri Akilandeswari nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23519",
            "district": "17",
            "Block": "224",
            "label": "EXCEL PUBLIC SCHOOL (CBSE), PALLAKKAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23414",
            "district": "17",
            "Block": "224",
            "label": "Sri Akilandeswari nursery and primary school",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "23519",
            "district": "17",
            "Block": "224",
            "label": "EXCEL PUBLIC SCHOOL (CBSE), PALLAKKAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "225": [
        {
            "key": "407",
            "district": "33",
            "Block": "225",
            "label": "PUMS, SAVATUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "414",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KODIVALASAI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "428",
            "district": "33",
            "Block": "225",
            "label": "PUMS, PANDRAVEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "462",
            "district": "33",
            "Block": "225",
            "label": "VAARIAR MATRIC HR,SEC.SCHOOL,PODATURPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "535",
            "district": "33",
            "Block": "225",
            "label": "PUMS, RANGANATHAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "543",
            "district": "33",
            "Block": "225",
            "label": "ST MARY'S MATRICULATION  HIGHER SECONDARY SCHOOL, PALLIPAT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "552",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "566",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KAKKALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "671",
            "district": "33",
            "Block": "225",
            "label": "ANM MID.SCH, POTHATURPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1142",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KEELAPUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1147",
            "district": "33",
            "Block": "225",
            "label": "ANM MS, KONEDAMPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1190",
            "district": "33",
            "Block": "225",
            "label": "SAAISRI MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1327",
            "district": "33",
            "Block": "225",
            "label": "GOVT.MS, KUMARAJUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1420",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KOTHAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2093",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KUDIYANAVAR STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2399",
            "district": "33",
            "Block": "225",
            "label": "PUMS, KARLAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3731",
            "district": "33",
            "Block": "225",
            "label": "PUMS, MELAPUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4206",
            "district": "33",
            "Block": "225",
            "label": "PUMS, JANGALA PALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5296",
            "district": "33",
            "Block": "225",
            "label": "ANM MS, PUNNIYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7051",
            "district": "33",
            "Block": "225",
            "label": "GHS, KARIMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7052",
            "district": "33",
            "Block": "225",
            "label": "GOVT HS, KEECHALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7053",
            "district": "33",
            "Block": "225",
            "label": "GHS, KESAVARAJAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7054",
            "district": "33",
            "Block": "225",
            "label": "GOVT (G) HS, ATHIMANJERIPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7055",
            "district": "33",
            "Block": "225",
            "label": "GOVT (B) HSS, ATHIMANJERIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7056",
            "district": "33",
            "Block": "225",
            "label": "GHS, SUNDARESAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7057",
            "district": "33",
            "Block": "225",
            "label": "GHS, KONASAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7058",
            "district": "33",
            "Block": "225",
            "label": "GOVTHSS, SORAIKKAIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7059",
            "district": "33",
            "Block": "225",
            "label": "GOVT HS, NEDIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7060",
            "district": "33",
            "Block": "225",
            "label": "GOVT HS, NOCHILI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7061",
            "district": "33",
            "Block": "225",
            "label": "SH HSS, PANDRAVEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7062",
            "district": "33",
            "Block": "225",
            "label": "GHS, NALLAVANAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7063",
            "district": "33",
            "Block": "225",
            "label": "GHS, BOMMARAJUPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7064",
            "district": "33",
            "Block": "225",
            "label": "GOVT HS, VELIAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7065",
            "district": "33",
            "Block": "225",
            "label": "ST MARYS MAT, POTHATURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7066",
            "district": "33",
            "Block": "225",
            "label": "GOVT (G) HSS, POTHATURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7067",
            "district": "33",
            "Block": "225",
            "label": "GOVT (B) HSS, POTHATURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7068",
            "district": "33",
            "Block": "225",
            "label": "GOVT BOYS HSS, PALLIPAT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7069",
            "district": "33",
            "Block": "225",
            "label": "GOVT (G) HSS, PALLIPAT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20925",
            "district": "33",
            "Block": "225",
            "label": "CSI MS, NEDUGAL COLONY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21006",
            "district": "33",
            "Block": "225",
            "label": "SRI GNANA POORNA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23397",
            "district": "33",
            "Block": "225",
            "label": "Sri Vidya mandir  matric school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "22915",
            "district": "33",
            "Block": "225",
            "label": "K V PATSHALA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23905",
            "district": "33",
            "Block": "225",
            "label": "TVM MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23906",
            "district": "33",
            "Block": "225",
            "label": "MOTHER TERESA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "226": [
        {
            "key": "2985",
            "district": "22",
            "Block": "226",
            "label": "SALEM SRI SOWDESWARI MATRIC HR. SEC. SCHOOL, KONDALAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3491",
            "district": "22",
            "Block": "226",
            "label": "SRI VIDHYA BHARATI MATRIC HR. SEC. SCHOOL, NEIKARAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11118",
            "district": "22",
            "Block": "226",
            "label": "PUMS,RANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11119",
            "district": "22",
            "Block": "226",
            "label": "GOVT MODEL HSS JARI KONDALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11120",
            "district": "22",
            "Block": "226",
            "label": "GOVT BOYS HSS JARI KONDALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11121",
            "district": "22",
            "Block": "226",
            "label": "SRI VIDYA MANDIR HR. SEC. SCHOOL, KONDALAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11122",
            "district": "22",
            "Block": "226",
            "label": "PUMS,MALANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11123",
            "district": "22",
            "Block": "226",
            "label": "GOVT HSS NEIKARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11124",
            "district": "22",
            "Block": "226",
            "label": "PUMS,KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11125",
            "district": "22",
            "Block": "226",
            "label": "PUMS,GANDHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11126",
            "district": "22",
            "Block": "226",
            "label": "GOLDEN CHOICE MATRIC HR. SEC. SCHOOL, DASANAICKENPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11127",
            "district": "22",
            "Block": "226",
            "label": "GHSS,DASANAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11128",
            "district": "22",
            "Block": "226",
            "label": "K.G.B.V. SCHOOL, NILAVARAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11129",
            "district": "22",
            "Block": "226",
            "label": "PUMS,THAMMANAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11130",
            "district": "22",
            "Block": "226",
            "label": "panchayat union middle school vaniyampady",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11131",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EARVADI VANIYAMBADI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11132",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VALAKUTTAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11133",
            "district": "22",
            "Block": "226",
            "label": "PUMS,PARAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11134",
            "district": "22",
            "Block": "226",
            "label": "PUMS,MECHERIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11135",
            "district": "22",
            "Block": "226",
            "label": "PUMS,KOZHINCHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11136",
            "district": "22",
            "Block": "226",
            "label": "GHSS,NAZHIKKALPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11137",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SANTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11138",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11139",
            "district": "22",
            "Block": "226",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11140",
            "district": "22",
            "Block": "226",
            "label": "VEE GEE VIKAS PUBLIC SCHOOL,MALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11141",
            "district": "22",
            "Block": "226",
            "label": "PUMS,S-ATTAYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11142",
            "district": "22",
            "Block": "226",
            "label": "GOVT HIGH SCHOOL GAJJALNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11143",
            "district": "22",
            "Block": "226",
            "label": "PUMS,JARUGUMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11144",
            "district": "22",
            "Block": "226",
            "label": "VEDHHA VIKKAS MATRIC HR. SEC. SCHOOL, PANAMARATHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11145",
            "district": "22",
            "Block": "226",
            "label": "GOVT HR SEC SCHOOL, PANAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11146",
            "district": "22",
            "Block": "226",
            "label": "CHRISTOPHER MATRIC HR. SEC. SCHOOL, PANAMARATHUPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11147",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,THIPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11148",
            "district": "22",
            "Block": "226",
            "label": "GOVERNMENT HIGHER SECONDERY SCHOOL,KULLAPPANAICKANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11149",
            "district": "22",
            "Block": "226",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL, KONAMADUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11150",
            "district": "22",
            "Block": "226",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,ADIMALAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21208",
            "district": "22",
            "Block": "226",
            "label": "MODEL SCHOOL , PANAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23396",
            "district": "22",
            "Block": "226",
            "label": "Diamond Ray's Mat HrSec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21244",
            "district": "22",
            "Block": "226",
            "label": "SPS MATRIC HR. SEC. SCHOOL, NEIKKARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21458",
            "district": "22",
            "Block": "226",
            "label": "GHS NILAVARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21913",
            "district": "22",
            "Block": "226",
            "label": "VEDHHA VIKASS HR. SEC. SCHOOL, SANDHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22112",
            "district": "22",
            "Block": "226",
            "label": "ST. JOHN'S MATRICULATION SCHOOL,VENGAMPATTI MALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22439",
            "district": "22",
            "Block": "226",
            "label": "GHS PARANATTAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22772",
            "district": "22",
            "Block": "226",
            "label": "ELFS PUBLIC SCHOOL ,VENGAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22773",
            "district": "22",
            "Block": "226",
            "label": "SREE JOTHI VIDYALAYA MATRIC SCHOOL, VENGAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22861",
            "district": "22",
            "Block": "226",
            "label": "GHS KAMMALAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23320",
            "district": "22",
            "Block": "226",
            "label": "VEDHHA VIKASS PUBLIC SCHOOL ,SANTHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23907",
            "district": "22",
            "Block": "226",
            "label": "PUMS,KATTUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23908",
            "district": "22",
            "Block": "226",
            "label": "VEDHA VIKKAS HR. SEC. SCHOOL, SANTHIYUR",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "227": [
        {
            "key": "23347",
            "district": "5",
            "Block": "227",
            "label": "Balavihar Matric Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4923",
            "district": "5",
            "Block": "227",
            "label": "MUNICIPAL  MIDDLE SCHOOL  GANDHIROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5010",
            "district": "5",
            "Block": "227",
            "label": "MUNICIPAL MIDDLE  SCHOOL SEERANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5052",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VISOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5467",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOLVEGAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5633",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL MANAMTHAVIZHNTHAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5843",
            "district": "5",
            "Block": "227",
            "label": "MUNICIPAL MIDDLE  SCHOOL MELAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5845",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL NADUKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5957",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL NATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15218",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL ANGUCHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15219",
            "district": "5",
            "Block": "227",
            "label": "AIDED  MIDDLE  SCHOOL R.C MOZHANDIKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15220",
            "district": "5",
            "Block": "227",
            "label": "KURINJI MATRICULATION HIGH SCHOOL, AZHAGAPPASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15221",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL PAVAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15222",
            "district": "5",
            "Block": "227",
            "label": "R.K .GOVERNMENT HIGHER SECONDARY SCHOOL KADAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15223",
            "district": "5",
            "Block": "227",
            "label": "St .  PAUL PUBLIC SCHOOL, CBSE  PANIKANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15224",
            "district": "5",
            "Block": "227",
            "label": "RAJAGURU MATRIC HR SEC.SCHOOL-KADAMPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15225",
            "district": "5",
            "Block": "227",
            "label": "MODEL SCHOOL,PANRUTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15226",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL KARUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15227",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL-KATTUKOODALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15228",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL KEEZHAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15229",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL KEEZHKANGEYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15230",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL KUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15231",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15232",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL KEEZHMAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15233",
            "district": "5",
            "Block": "227",
            "label": "ST.MARYS MATRICULATION HIGH  SCHOOL L.N.PURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15234",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL MALIGAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15235",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MARUNGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15236",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL MELIRUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15237",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  SIRUGRAMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15238",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL S.EARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15239",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL SIRUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15240",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL THAZHAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15241",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL THIRUVAMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15242",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL VEERASINGANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15243",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEERAPERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15244",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL VADAKKU MELMAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15245",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL METTUKUPPAM (PANRUTI)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15246",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT GIRLS  HIGHER SECONDARY SCHOOL PERPERIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15247",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL PERPERIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15248",
            "district": "5",
            "Block": "227",
            "label": "D.KALIYAPERUMAL MEMORIAL MATRICULATION SCHOOL. PERPERIYANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15249",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL SOORAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15250",
            "district": "5",
            "Block": "227",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL, SOORAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15251",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL POONGUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15252",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERIYAKATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15253",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT ADIDIRAVIDAR WELFARE HIGHER SECONDARY SCHOOL, SEMAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15254",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGH SCHOOL  SEMMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15255",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL BADRAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15256",
            "district": "5",
            "Block": "227",
            "label": "AIDED  MIDDLE  SCHOOL  R.C PANICKANNKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15257",
            "district": "5",
            "Block": "227",
            "label": "AIDED  MIDDLE  SCHOOL A.V PANRUTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15258",
            "district": "5",
            "Block": "227",
            "label": "SREE SARADHA VIDHYALAYA  MATRICULATION  SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15259",
            "district": "5",
            "Block": "227",
            "label": "RADHIKA  MATRICULATION HIGH SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15260",
            "district": "5",
            "Block": "227",
            "label": "MUNICIPAL HIGH  SCHOOL THIRUVATHIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15261",
            "district": "5",
            "Block": "227",
            "label": "AIDED HIGH SCHOOL D.M.PANRUTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15262",
            "district": "5",
            "Block": "227",
            "label": "SRI.P.MUTHAIYAR HR.SEC.SCHOOL, PANRUTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15263",
            "district": "5",
            "Block": "227",
            "label": "RATHANA  MATRIC.HIGHER .SECONDARY.SCHOOL, PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15264",
            "district": "5",
            "Block": "227",
            "label": "AIDED SHREE.A.SUBBARAYA CHETTIAR'S GIRLS HIGHER SECONDARY SCHOOL- PANRUTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15265",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  PANRUTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15266",
            "district": "5",
            "Block": "227",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION HR SEC SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15267",
            "district": "5",
            "Block": "227",
            "label": "JOHN DEWEY MATRICULATION  HR SEC SCHOOL  PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15268",
            "district": "5",
            "Block": "227",
            "label": "THE NEW JOHN DEWEY MATRIC HIGH SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15269",
            "district": "5",
            "Block": "227",
            "label": "SHREE MAEDHA MATRICULATION  HIGH SCHOOL ,  PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15270",
            "district": "5",
            "Block": "227",
            "label": "THIRUVALLUVAR ,MATRICULATION HIGHER SEC  SCHOOL PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15271",
            "district": "5",
            "Block": "227",
            "label": "ST. ANNE'S MATRIC HIGHER SEC  SCHOOL ,THATTANCHAVADI,PANRUTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15272",
            "district": "5",
            "Block": "227",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHETTIPATTARAI  COLONY PANRUTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15273",
            "district": "5",
            "Block": "227",
            "label": "KASTHURIBA GANDHI BALIGA VIDHYALAYA SCHOOL KADAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22931",
            "district": "5",
            "Block": "227",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL PANRUTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23188",
            "district": "5",
            "Block": "227",
            "label": "SPDS JAYANTHI VIDYAPEEDAM ,KADAMPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "228": [
        {
            "key": "1455",
            "district": "25",
            "Block": "228",
            "label": "JACK & JILL MATRIC HIGH SCHOOL - KABISTHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1907",
            "district": "25",
            "Block": "228",
            "label": "Abidean Matric High School, Thiruppalathurai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2002",
            "district": "25",
            "Block": "228",
            "label": "AL-MUBEEN MATRICULATION HIGHER SECONDARY SCHOOL -AYYYAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2015",
            "district": "25",
            "Block": "228",
            "label": "STAR LIONS MATRIC HIGHER SECONDARY SCHOOL-AYYAMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2328",
            "district": "25",
            "Block": "228",
            "label": "MANI MATRIC HSS - KABISTHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2605",
            "district": "25",
            "Block": "228",
            "label": "ADW MS - MELA VALUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2622",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PERUMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2715",
            "district": "25",
            "Block": "228",
            "label": "MANI MATRIC HIGH SCHOOL - GANAPATHY AGRAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2926",
            "district": "25",
            "Block": "228",
            "label": "ST. BASTIN'S MATRICULATION HIGHER SECONDARY SCHOOL, PAPANASAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3056",
            "district": "25",
            "Block": "228",
            "label": "PUMS - EACHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3308",
            "district": "25",
            "Block": "228",
            "label": "PATTUKOTTAI ALAGIRI MAT.HSS-PAPANASAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3378",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PASUPATHI KOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3949",
            "district": "25",
            "Block": "228",
            "label": "SRI. MAHAGANAPATHY MIDDLE SCHOOL - GANAPATHY AGRAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4010",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PANDARAVADI (NORTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4025",
            "district": "25",
            "Block": "228",
            "label": "RDB MATRICULATION HSS-PAPANASAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4199",
            "district": "25",
            "Block": "228",
            "label": "ST.SABASTIN MS-PAPANASAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4439",
            "district": "25",
            "Block": "228",
            "label": "PUMS THIRUPALATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4772",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PONPETHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4784",
            "district": "25",
            "Block": "228",
            "label": "CRESCENT MATRIC HIGHER SECONDARY SCHOOL -PANDARAVADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4903",
            "district": "25",
            "Block": "228",
            "label": "ST. MARY'S MATRICULATION SCHOOL -PASUPATHIKOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5149",
            "district": "25",
            "Block": "228",
            "label": "ALIF MATRICULATION SCHOOL - VALUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5246",
            "district": "25",
            "Block": "228",
            "label": "PUMS - MELA MANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5705",
            "district": "25",
            "Block": "228",
            "label": "Tansri Ubaidulla Matriculation Higher Secondary School, Rajagiri",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5707",
            "district": "25",
            "Block": "228",
            "label": "KASSIMIA DAWOOD MS - RAJAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5725",
            "district": "25",
            "Block": "228",
            "label": "ANJUMAN MATRIC HIGHER SECONDARY SCHOOL- AYYAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6001",
            "district": "25",
            "Block": "228",
            "label": "PUMS - ATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6007",
            "district": "25",
            "Block": "228",
            "label": "PUMS - ELANGARKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6278",
            "district": "25",
            "Block": "228",
            "label": "PUMS - PAPANASAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16560",
            "district": "25",
            "Block": "228",
            "label": "GHS - ILUPPAKKORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16561",
            "district": "25",
            "Block": "228",
            "label": "GOVINDASAMY MOOPANAR HIGH SCHOOL - KABISTHALAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16562",
            "district": "25",
            "Block": "228",
            "label": "ST. GABRIELS GIRLS HIGHER SECONDARY SCHOOL PASUPATHI KOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16563",
            "district": "25",
            "Block": "228",
            "label": "SRIVIDYASRAM MATRICULATION SCHOOL, VADAKURANGADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16564",
            "district": "25",
            "Block": "228",
            "label": "GHS VANNIYADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16565",
            "district": "25",
            "Block": "228",
            "label": "KASSIMIA HIGHER SECONDARY SCHOOL, RAJAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16566",
            "district": "25",
            "Block": "228",
            "label": "HIDAYATHUN NISWAN GENERAL ARABIC GIRLS HIGHER SECONDARY SCHOOL-RAJAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16567",
            "district": "25",
            "Block": "228",
            "label": "GHS- RAMANUJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16568",
            "district": "25",
            "Block": "228",
            "label": "PUMS CHAKKARAPALLI (SOUTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16569",
            "district": "25",
            "Block": "228",
            "label": "GHS - CHAKKARAPPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16570",
            "district": "25",
            "Block": "228",
            "label": "GHS - VALKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16571",
            "district": "25",
            "Block": "228",
            "label": "PUMS - SOOLAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16572",
            "district": "25",
            "Block": "228",
            "label": "GHSS (GIRLS) - PAPANASAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16573",
            "district": "25",
            "Block": "228",
            "label": "ADW MS - KELA MANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16574",
            "district": "25",
            "Block": "228",
            "label": "GHSS - PULLAPUTHANKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16575",
            "district": "25",
            "Block": "228",
            "label": "GHS - PATTAVARTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16576",
            "district": "25",
            "Block": "228",
            "label": "THE HIGH SCHOOL - UMAYALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16577",
            "district": "25",
            "Block": "228",
            "label": "PUMS UMAPALAPADI KARUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16578",
            "district": "25",
            "Block": "228",
            "label": "SOWKATHUL ISLAM BMS HSS- VALUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16579",
            "district": "25",
            "Block": "228",
            "label": "GHSS - VEERAMANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16580",
            "district": "25",
            "Block": "228",
            "label": "PUMS - AYYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16581",
            "district": "25",
            "Block": "228",
            "label": "GHSS - AYYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16582",
            "district": "25",
            "Block": "228",
            "label": "GHSS - PAPANASAM (BOYS)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21556",
            "district": "25",
            "Block": "228",
            "label": "ST. ANTONY S HIGH SCHOOL- PASUPATHIKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "229": [
        {
            "key": "854",
            "district": "30",
            "Block": "229",
            "label": "DEEPA MATRIC HIGHER SECONDARY SCHOOL,MUKKUDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1513",
            "district": "30",
            "Block": "229",
            "label": "BOO VIJESH MAT  MUKKUDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2934",
            "district": "30",
            "Block": "229",
            "label": "BHARATHI VIDYALAYAM MIDDLE SCHOOL, RENGASAMUDRAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3143",
            "district": "30",
            "Block": "229",
            "label": "RC MS THALARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3225",
            "district": "30",
            "Block": "229",
            "label": "NEWLIFE MATRIC ACADEMY, PAPPAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6493",
            "district": "30",
            "Block": "229",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,,ANAINTHANADARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6716",
            "district": "30",
            "Block": "229",
            "label": "THIRUVALLUVAR  MS SATTUPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6736",
            "district": "30",
            "Block": "229",
            "label": "SARATHA MIDDLE SCHOOL,IDAIKAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19755",
            "district": "30",
            "Block": "229",
            "label": "SAROJINI MEMMS ,SIRUKKANKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19757",
            "district": "30",
            "Block": "229",
            "label": "GHSS NADUKALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19758",
            "district": "30",
            "Block": "229",
            "label": "KINGS MAT SCHOOL, KONDANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19831",
            "district": "30",
            "Block": "229",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,POTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19832",
            "district": "30",
            "Block": "229",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, POTHUKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19837",
            "district": "30",
            "Block": "229",
            "label": "PUMS,KUMARASAMIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19838",
            "district": "30",
            "Block": "229",
            "label": "HINDU MS ODAIKARAI THULUKKAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19839",
            "district": "30",
            "Block": "229",
            "label": "HINDU MIDDLE SCHOOL,PAPPAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19840",
            "district": "30",
            "Block": "229",
            "label": "SOKKALAL HSS MUKKUDAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19841",
            "district": "30",
            "Block": "229",
            "label": "ST PAUL HSS SINGAMPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19842",
            "district": "30",
            "Block": "229",
            "label": "GHSS,VADAKKU ARIYANAYAGIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19843",
            "district": "30",
            "Block": "229",
            "label": "HINDU MS HARIKESAVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19844",
            "district": "30",
            "Block": "229",
            "label": "GAJENDRA VARATHAR MS ATHALANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21416",
            "district": "30",
            "Block": "229",
            "label": "G(G) HS NADUKKALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21621",
            "district": "30",
            "Block": "229",
            "label": "ST FRANCIS DE PAUL PRIMARY ENGLISH MEDIUM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22281",
            "district": "30",
            "Block": "229",
            "label": "RUKMANI NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22652",
            "district": "30",
            "Block": "229",
            "label": "STACG HITECH SCHOOL CBSE IDAIKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "230": [
        {
            "key": "2337",
            "district": "6",
            "Block": "230",
            "label": "ANNAI INDIA MHSS, MALLAPURAM, BOMMIDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3233",
            "district": "6",
            "Block": "230",
            "label": "STANLEY MHSS, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3893",
            "district": "6",
            "Block": "230",
            "label": "VENGANAR VAJRAM MHSS, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5382",
            "district": "6",
            "Block": "230",
            "label": "SRI VENKATESWARA MHSS, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6555",
            "district": "6",
            "Block": "230",
            "label": "PUMS AJJAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9443",
            "district": "6",
            "Block": "230",
            "label": "PUMS A AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9444",
            "district": "6",
            "Block": "230",
            "label": "PUMS A PALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9445",
            "district": "6",
            "Block": "230",
            "label": "GHSS  ADIGARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9446",
            "district": "6",
            "Block": "230",
            "label": "GHS ALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9447",
            "district": "6",
            "Block": "230",
            "label": "GTRMS ARASANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9448",
            "district": "6",
            "Block": "230",
            "label": "GHSS B.MALLAPURAM GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9449",
            "district": "6",
            "Block": "230",
            "label": "GHSS B.MALLAPURAM BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9450",
            "district": "6",
            "Block": "230",
            "label": "AMG MHSS, NADUR, BOMMIDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9451",
            "district": "6",
            "Block": "230",
            "label": "CARMEL HR. SEC. SCHOOL, B. PALLIPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9452",
            "district": "6",
            "Block": "230",
            "label": "ST.MARY'S MATRIC SCHOOL, B.PALLIPATTI, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9453",
            "district": "6",
            "Block": "230",
            "label": "GHSS B.THURINJIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9454",
            "district": "6",
            "Block": "230",
            "label": "GHSS BAIRNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9455",
            "district": "6",
            "Block": "230",
            "label": "PUMS BOTHAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9456",
            "district": "6",
            "Block": "230",
            "label": "GTRMS ELANTHAKOTTAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9457",
            "district": "6",
            "Block": "230",
            "label": "PUMS GUNDALMADUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9458",
            "district": "6",
            "Block": "230",
            "label": "KGV GHSS H.PUDUPATTI-BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9459",
            "district": "6",
            "Block": "230",
            "label": "GTRHS KALASAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9460",
            "district": "6",
            "Block": "230",
            "label": "GHSS KALIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9461",
            "district": "6",
            "Block": "230",
            "label": "GTRMS KALLATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9462",
            "district": "6",
            "Block": "230",
            "label": "PUMS KATHIRIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9463",
            "district": "6",
            "Block": "230",
            "label": "GHS KOMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9464",
            "district": "6",
            "Block": "230",
            "label": "GTRMS MANKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9465",
            "district": "6",
            "Block": "230",
            "label": "GTRMS MANNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9466",
            "district": "6",
            "Block": "230",
            "label": "GHSS MENASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9467",
            "district": "6",
            "Block": "230",
            "label": "GHS MOLAYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9468",
            "district": "6",
            "Block": "230",
            "label": "SREE VETRI VIKAS MHSS, MOLAYANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9469",
            "district": "6",
            "Block": "230",
            "label": "PKV GHSS MOOKAREDDIPATTI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9470",
            "district": "6",
            "Block": "230",
            "label": "BLESSING MIDDLE SCHOOL, MULLERIKADU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9471",
            "district": "6",
            "Block": "230",
            "label": "PUMS MULLIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9472",
            "district": "6",
            "Block": "230",
            "label": "GTRMS NOCHIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9473",
            "district": "6",
            "Block": "230",
            "label": "PUMS ODDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9474",
            "district": "6",
            "Block": "230",
            "label": "PUMS PAPPAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9475",
            "district": "6",
            "Block": "230",
            "label": "GHSS PAPPIREDDIPATTI - BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9476",
            "district": "6",
            "Block": "230",
            "label": "GHSS PAPPIREDDIPATTI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9477",
            "district": "6",
            "Block": "230",
            "label": "GTRMS PERERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9478",
            "district": "6",
            "Block": "230",
            "label": "PUMS PERERIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9479",
            "district": "6",
            "Block": "230",
            "label": "GHS S.AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9480",
            "district": "6",
            "Block": "230",
            "label": "PUMS SALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9481",
            "district": "6",
            "Block": "230",
            "label": "PUMS SELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9482",
            "district": "6",
            "Block": "230",
            "label": "GTRHSS SITHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9483",
            "district": "6",
            "Block": "230",
            "label": "GHS VASIGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9484",
            "district": "6",
            "Block": "230",
            "label": "GTRMS VELLAMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9485",
            "district": "6",
            "Block": "230",
            "label": "GHS VENKATASAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9486",
            "district": "6",
            "Block": "230",
            "label": "GHS LAKSHMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9487",
            "district": "6",
            "Block": "230",
            "label": "GHS PILPARUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9488",
            "district": "6",
            "Block": "230",
            "label": "PUMS SAMIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21261",
            "district": "6",
            "Block": "230",
            "label": "VAAGAI VIDHYALAYA MHSS, H.PUDUPATTI, PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21447",
            "district": "6",
            "Block": "230",
            "label": "GHS B.THURINJIPATTI GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21951",
            "district": "6",
            "Block": "230",
            "label": "SIGARAM MHSS, PAPPAMBADI , PAPPIREDDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23049",
            "district": "6",
            "Block": "230",
            "label": "GOLDEN GATES MHSS, BAIRNATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "231": [
        {
            "key": "410",
            "district": "20",
            "Block": "231",
            "label": "ST.MARY'S MATRICULATION HIGH SCHOOL, PARTHIBANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1009",
            "district": "20",
            "Block": "231",
            "label": "DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1116",
            "district": "20",
            "Block": "231",
            "label": "BHARATHIYAR MIDDLE SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1324",
            "district": "20",
            "Block": "231",
            "label": "YADAVA MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1362",
            "district": "20",
            "Block": "231",
            "label": "V.O.C.MATRICULATION HIGHER SECONDARY SCHOOL, N.VALAYANENTHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1385",
            "district": "20",
            "Block": "231",
            "label": "HARISH VARMA MATRICULATION HIGHER SECONDARY SCHOOL, ARIYANENDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1964",
            "district": "20",
            "Block": "231",
            "label": "AGNES MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2030",
            "district": "20",
            "Block": "231",
            "label": "SOURASHTRA DESIA MIDDLE SCHOOL, SOMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2122",
            "district": "20",
            "Block": "231",
            "label": "SUNDARAM MIDDLE SCHOOL, ARIYANENDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2326",
            "district": "20",
            "Block": "231",
            "label": "T.E.L.C. MIDDLE SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2928",
            "district": "20",
            "Block": "231",
            "label": "DR.SURESH MATRICULATION HIGH SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3349",
            "district": "20",
            "Block": "231",
            "label": "PARAMAKUDI LIONS MATRICULATION HIGHER SECONDARY SCHOOL, THELICHATHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3356",
            "district": "20",
            "Block": "231",
            "label": "AYIRA VAISYA MATRICULATION HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3823",
            "district": "20",
            "Block": "231",
            "label": "KALIDASS MIDDLE SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6394",
            "district": "20",
            "Block": "231",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION SCHOOL, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19071",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, KALAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19072",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, M.NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19073",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19074",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUDUKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19075",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, PIDARISERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19076",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THELICHATHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19077",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELAPERUNGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19078",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SUDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19079",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUMARAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19080",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUTHURAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19081",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELAIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19082",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, PERUMALKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19083",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19084",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELAPERUNGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19085",
            "district": "20",
            "Block": "231",
            "label": "CRESCENT HIGH SCHOOL, PARTHIBANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19086",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PARTHIBANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19087",
            "district": "20",
            "Block": "231",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PARALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19088",
            "district": "20",
            "Block": "231",
            "label": "RC MIDDLE SCHOOL, MELAKAVANOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19089",
            "district": "20",
            "Block": "231",
            "label": "S.R.V.S. MIDDLE SCHOOL, LAKSHMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19090",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT HIGH SCHOOL, KAMUDAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19091",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE MIDDLE SCHOOL, PAMBUVILUNTHAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19092",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE MIDDLE  SCHOOL, NILAYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19093",
            "district": "20",
            "Block": "231",
            "label": "MUNICIPAL MIDDLE SCHOOL,  MANJALPATTANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19094",
            "district": "20",
            "Block": "231",
            "label": "S.N.V. GOVERNMENT  MODEL HIGHER SECONDARY SCHOOL, EMANESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19095",
            "district": "20",
            "Block": "231",
            "label": "K.A. WEST MUSLIM HIGH SCHOOL, PARAMAKUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19096",
            "district": "20",
            "Block": "231",
            "label": "K.J. EAST MUSLIM HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19097",
            "district": "20",
            "Block": "231",
            "label": "SOURASHTRA HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19098",
            "district": "20",
            "Block": "231",
            "label": "ALANGARA MATHA HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19099",
            "district": "20",
            "Block": "231",
            "label": "AYIRA VAISYA HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19100",
            "district": "20",
            "Block": "231",
            "label": "R.S. GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19101",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, PARAMAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19102",
            "district": "20",
            "Block": "231",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, KATTUPARAMAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21035",
            "district": "20",
            "Block": "231",
            "label": "SOURASHTRA MATRICULATION HIGH SCHOOL, EMANESWARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21860",
            "district": "20",
            "Block": "231",
            "label": "MOUNT LITERA ZEE CBSE SCHOOL, KAMUDAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22626",
            "district": "20",
            "Block": "231",
            "label": "UDAIYAR MATRICULATION SCHOOL, MELAKAVANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22627",
            "district": "20",
            "Block": "231",
            "label": "GANAPATHY CHETTIAR VIDYALAYA, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22628",
            "district": "20",
            "Block": "231",
            "label": "DR. ABDUL KALAM PUBLIC SCHOOL, PUTHU NAGAR, PARAMAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23240",
            "district": "20",
            "Block": "231",
            "label": "MUNICIPAL HIGH SCHOOL, EMANESWARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23324",
            "district": "20",
            "Block": "231",
            "label": "KAVINA INTERNATIONAL SCHOOL, PAMBOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "232": [
        {
            "key": "1252",
            "district": "17",
            "Block": "232",
            "label": "KONGU MATRIC HSS VELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1295",
            "district": "17",
            "Block": "232",
            "label": "PUMS JANGAMANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1448",
            "district": "17",
            "Block": "232",
            "label": "PUMS SULTHANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1496",
            "district": "17",
            "Block": "232",
            "label": "PUMS VAVIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23659",
            "district": "17",
            "Block": "232",
            "label": "Sri Vidhya Lakshmi Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "1652",
            "district": "17",
            "Block": "232",
            "label": "SIVABAKIYAM MUTHUSAMY MATRIC HSS MADESAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1975",
            "district": "17",
            "Block": "232",
            "label": "GANDHI MATRICULATION HIGHER SECONDARY SCHOOL, KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2079",
            "district": "17",
            "Block": "232",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2080",
            "district": "17",
            "Block": "232",
            "label": "MALAR MATRIC HSS PARAMATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2225",
            "district": "17",
            "Block": "232",
            "label": "SKV MATRIC HIGHER SECONDARY SCHOOL, M. KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2314",
            "district": "17",
            "Block": "232",
            "label": "KANDASWAMI KANDAR MATRIC HR.SEC.SCHOOL, VELUR,NAMAKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2381",
            "district": "17",
            "Block": "232",
            "label": "SKV VIDHYAASHRAM SENIOR SECONDARY SCHOOL M.KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2598",
            "district": "17",
            "Block": "232",
            "label": "PUMS VEERANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4080",
            "district": "17",
            "Block": "232",
            "label": "PUMS BOMMAKKAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4482",
            "district": "17",
            "Block": "232",
            "label": "PUMS NALLIYAMPALAYAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4748",
            "district": "17",
            "Block": "232",
            "label": "VIB MATRICULATION SCHOOL PEECHAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5686",
            "district": "17",
            "Block": "232",
            "label": "PUMS PILLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11983",
            "district": "17",
            "Block": "232",
            "label": "SKV HIGHER SECONDARY SCHOOL, M.KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11984",
            "district": "17",
            "Block": "232",
            "label": "VIB HIGHER SECONDARY SCHOOL, PEECHAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11985",
            "district": "17",
            "Block": "232",
            "label": "GHSS KANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11986",
            "district": "17",
            "Block": "232",
            "label": "GHSS KARICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11987",
            "district": "17",
            "Block": "232",
            "label": "GHS S.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11988",
            "district": "17",
            "Block": "232",
            "label": "GHS K.KOLANDHAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11989",
            "district": "17",
            "Block": "232",
            "label": "GHS CHETTIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11990",
            "district": "17",
            "Block": "232",
            "label": "GHS KONDARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11991",
            "district": "17",
            "Block": "232",
            "label": "GHS VILLIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11992",
            "district": "17",
            "Block": "232",
            "label": "PUMS PILLAIKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11993",
            "district": "17",
            "Block": "232",
            "label": "SRI RAMAMOORTHI HIGH SCHOOL VASANTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11994",
            "district": "17",
            "Block": "232",
            "label": "GOVT GIRLS HR SEC SCHOOL, PARAMATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11995",
            "district": "17",
            "Block": "232",
            "label": "GBHSS PARAMATHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11996",
            "district": "17",
            "Block": "232",
            "label": "GIRLS HIGHER SECONDARY SCHOOL (AIDED), VELUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11997",
            "district": "17",
            "Block": "232",
            "label": "KANDASWAMI KANDAR'S HIGHER SECONDARY SCHOOL VELUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21230",
            "district": "17",
            "Block": "232",
            "label": "SKV GIRLS MATRIC HR SEC SCHOOL, M. KANDAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22453",
            "district": "17",
            "Block": "232",
            "label": "MALAR PUBLIC SCHOOL, PARAMATHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22454",
            "district": "17",
            "Block": "232",
            "label": "GOVERNMENT HIGH SCHOOL KUPPUCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23909",
            "district": "17",
            "Block": "232",
            "label": "PUMS MARAVAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "233": [
        {
            "key": "4972",
            "district": "5",
            "Block": "233",
            "label": "PUMS GUMATHPALLI MIDDLE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5211",
            "district": "5",
            "Block": "233",
            "label": "PUMS KILLAIPATTINAVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5341",
            "district": "5",
            "Block": "233",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SILAMBIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5391",
            "district": "5",
            "Block": "233",
            "label": "PUMS PICHAVARAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5425",
            "district": "5",
            "Block": "233",
            "label": "PUMS UTHAMASOZHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5585",
            "district": "5",
            "Block": "233",
            "label": "PUMS PUNJAIMAHATHUVAZHKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5589",
            "district": "5",
            "Block": "233",
            "label": "PUMS KOVILAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5813",
            "district": "5",
            "Block": "233",
            "label": "PUMS THILLAIVIDANGAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6134",
            "district": "5",
            "Block": "233",
            "label": "ISHA VIDHYA MATRICULATION HIGHER SECONDARY.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6274",
            "district": "5",
            "Block": "233",
            "label": "PUMS PONNANTHITTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6510",
            "district": "5",
            "Block": "233",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL - ADHIVARAGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6532",
            "district": "5",
            "Block": "233",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ARIYAGOSHTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6625",
            "district": "5",
            "Block": "233",
            "label": "PUMS THACHAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6680",
            "district": "5",
            "Block": "233",
            "label": "THIRUVALLUVAR AMS VILLIYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15507",
            "district": "5",
            "Block": "233",
            "label": "PUMS C. PUDUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15508",
            "district": "5",
            "Block": "233",
            "label": "PUMS K. PANCHANGKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15509",
            "district": "5",
            "Block": "233",
            "label": "PUMS KARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15510",
            "district": "5",
            "Block": "233",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL -  SAMMANDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15511",
            "district": "5",
            "Block": "233",
            "label": "RC. HIGH SCHOOL,  MANIKOLLAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15512",
            "district": "5",
            "Block": "233",
            "label": "GOVERNMENT MODEL  HIGHER SECONDARY SCHOOL - B.MUTLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15513",
            "district": "5",
            "Block": "233",
            "label": "JAWAHAR MATRICULATION HIGHER SECONDARY SCHOOL,  B.MUTLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15514",
            "district": "5",
            "Block": "233",
            "label": "ATCHAYA MANTHIR HIGHER SECONDARY SCHOOL-B.MUTLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15515",
            "district": "5",
            "Block": "233",
            "label": "PUMS PERIYAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15516",
            "district": "5",
            "Block": "233",
            "label": "SRI BARANI VIDHYALAYA HIGHER SECONDARY SCHOOL -PERIYAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15517",
            "district": "5",
            "Block": "233",
            "label": "PUMS SENDHIRAKILLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15518",
            "district": "5",
            "Block": "233",
            "label": "GHSS SAMIYARPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15519",
            "district": "5",
            "Block": "233",
            "label": "GOVERNMENT HIGH SCHOOL - AYEEPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15520",
            "district": "5",
            "Block": "233",
            "label": "GHS VELANGIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15521",
            "district": "5",
            "Block": "233",
            "label": "ADIDRAVIDAR WELFARE MIDDLE SCHOOL - ADHIVARAGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15522",
            "district": "5",
            "Block": "233",
            "label": "GHS KAVARAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15523",
            "district": "5",
            "Block": "233",
            "label": "PUMS KEEZHAMANAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15524",
            "district": "5",
            "Block": "233",
            "label": "PUMS KOTHANGKUDI HINDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15525",
            "district": "5",
            "Block": "233",
            "label": "GHS KOTHATTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15526",
            "district": "5",
            "Block": "233",
            "label": "PUMS MEETHIKUDI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15527",
            "district": "5",
            "Block": "233",
            "label": "ADWMS NANJAIMAHATHUVAZHKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15528",
            "district": "5",
            "Block": "233",
            "label": "PUMS PALLIPADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15529",
            "district": "5",
            "Block": "233",
            "label": "MUSTHAFA MATRICULATION HIGHER SECONDARY SCHOOL PALLIPADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15530",
            "district": "5",
            "Block": "233",
            "label": "GHSS- KODIPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15531",
            "district": "5",
            "Block": "233",
            "label": "GHSS T.S.PETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15532",
            "district": "5",
            "Block": "233",
            "label": "GHSS KILLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15533",
            "district": "5",
            "Block": "233",
            "label": "PUMS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15534",
            "district": "5",
            "Block": "233",
            "label": "PUMS KALAIGNAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15535",
            "district": "5",
            "Block": "233",
            "label": "SARVA SEVA MATRIC KILLAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15536",
            "district": "5",
            "Block": "233",
            "label": "GHS MUDASALVODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15537",
            "district": "5",
            "Block": "233",
            "label": "AIDED HIGHER SECONDARY SCHOOL SEVAMANDIR GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15538",
            "district": "5",
            "Block": "233",
            "label": "GHSS PARANGIPETTAI GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15539",
            "district": "5",
            "Block": "233",
            "label": "GOVT HIGHER SECONDARY SCHOOL PARANGIPETTAI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15540",
            "district": "5",
            "Block": "233",
            "label": "SEVAMANDIR MATRICULATION HIGHER SECONDARY SCHOOL, PARANGIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15541",
            "district": "5",
            "Block": "233",
            "label": "KALIMA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15542",
            "district": "5",
            "Block": "233",
            "label": "MUNA AUSTRALIAN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22209",
            "district": "5",
            "Block": "233",
            "label": "BABA VIDHYALAYA MATRICULATION HIGHER SECONDARY  SCHOOL, PARANGIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22559",
            "district": "5",
            "Block": "233",
            "label": "GHS-POOVALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23910",
            "district": "5",
            "Block": "233",
            "label": "PUMS MGR NAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23911",
            "district": "5",
            "Block": "233",
            "label": "GREEN OLIVE MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "234": [
        {
            "key": "2774",
            "district": "25",
            "Block": "234",
            "label": "FIVE STAR MATRIC HSS PATTUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2999",
            "district": "25",
            "Block": "234",
            "label": "ANNAI THERASA  MATRIC SCHOOL- SEMBAALUR  EAST PATTUKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3447",
            "district": "25",
            "Block": "234",
            "label": "OXFORD MATRICULATION HIGHER SECONDARY SCHOOL -PUDUKKOTTAI ULLUR, PATTUKKOTTAI ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3609",
            "district": "25",
            "Block": "234",
            "label": "IMAM SHAFI MAT HR SEC SCHOOL - ADIRAMPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4483",
            "district": "25",
            "Block": "234",
            "label": "MPLMS-MUSLIM THERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4759",
            "district": "25",
            "Block": "234",
            "label": "MPLMS- THANGAVEL NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4896",
            "district": "25",
            "Block": "234",
            "label": "TELC MS-PATTUKKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6267",
            "district": "25",
            "Block": "234",
            "label": "PUMS -KOTTAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6376",
            "district": "25",
            "Block": "234",
            "label": "PUMS -SANTHANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6523",
            "district": "25",
            "Block": "234",
            "label": "PUMS- PAZHANJUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6623",
            "district": "25",
            "Block": "234",
            "label": "MPLMS- JAYALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6715",
            "district": "25",
            "Block": "234",
            "label": "THE LITTLE BIRD MATRIC-PATTUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16638",
            "district": "25",
            "Block": "234",
            "label": "GHS-ALADIKKUMULAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16639",
            "district": "25",
            "Block": "234",
            "label": "GHS-ENATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16640",
            "district": "25",
            "Block": "234",
            "label": "PUMS- ERIPURAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16641",
            "district": "25",
            "Block": "234",
            "label": "PUMS- ETTUPPULIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16642",
            "district": "25",
            "Block": "234",
            "label": "GHSS- KARAMBAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16643",
            "district": "25",
            "Block": "234",
            "label": "AMS-ST.MICHAEL - MUTHALCHERI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16644",
            "district": "25",
            "Block": "234",
            "label": "GHS-NAMBIVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16645",
            "district": "25",
            "Block": "234",
            "label": "GHSS-NATTUCHALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16646",
            "district": "25",
            "Block": "234",
            "label": "M.G.M- HIGH SCHOOL- VALAVANPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16647",
            "district": "25",
            "Block": "234",
            "label": "PUMS-PALLIKONDAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16648",
            "district": "25",
            "Block": "234",
            "label": "LAUREL HIGHER SECONDARY SCHOOL - PALLIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16649",
            "district": "25",
            "Block": "234",
            "label": "PUMS- PANNAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16650",
            "district": "25",
            "Block": "234",
            "label": "PUMS- PARAKKALAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16651",
            "district": "25",
            "Block": "234",
            "label": "PUMS- PONNAVARAYANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16652",
            "district": "25",
            "Block": "234",
            "label": "GHSS-PUDUKOTTAI ULLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16653",
            "district": "25",
            "Block": "234",
            "label": "PUMS- KEEZHATHOTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16654",
            "district": "25",
            "Block": "234",
            "label": "GHSS- RAJAMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16655",
            "district": "25",
            "Block": "234",
            "label": "GHS- SENDANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16656",
            "district": "25",
            "Block": "234",
            "label": "ARUNODHAYA HSS-SENDANKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16657",
            "district": "25",
            "Block": "234",
            "label": "GHS-SOORAPPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16658",
            "district": "25",
            "Block": "234",
            "label": "MORDEN MATRIC HSS- SOORAPPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16659",
            "district": "25",
            "Block": "234",
            "label": "PUMS -T.MARAVAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16660",
            "district": "25",
            "Block": "234",
            "label": "GHS- T.MELAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16661",
            "district": "25",
            "Block": "234",
            "label": "GHSS- THAMBIKKOTTAI VADAKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16662",
            "district": "25",
            "Block": "234",
            "label": "PUMS- MANJAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16663",
            "district": "25",
            "Block": "234",
            "label": "GHS-SENAGAPADUTHANKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16664",
            "district": "25",
            "Block": "234",
            "label": "GHSS- THAMARANKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16665",
            "district": "25",
            "Block": "234",
            "label": "PUMS -THOKKALIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16666",
            "district": "25",
            "Block": "234",
            "label": "GHSS-THUVARANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16667",
            "district": "25",
            "Block": "234",
            "label": "ST JOSEPH'S HIGHER SECONDARY SCHOOL SANJAYA  NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16668",
            "district": "25",
            "Block": "234",
            "label": "BRINDHAVAN HIGHER SECONDARY SCHOOL- SUKKIRANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16669",
            "district": "25",
            "Block": "234",
            "label": "PUMS -VENDAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16670",
            "district": "25",
            "Block": "234",
            "label": "ST.FRANCIS XAVIER'S HIGH SCHOOL- ANAIKKADU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16671",
            "district": "25",
            "Block": "234",
            "label": "GHS -NADUVIKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16672",
            "district": "25",
            "Block": "234",
            "label": "GHSS- KAZHUKUPULIKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16673",
            "district": "25",
            "Block": "234",
            "label": "MPLMS-  KANDIYANTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16674",
            "district": "25",
            "Block": "234",
            "label": "MPLMS- PERUMALKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16675",
            "district": "25",
            "Block": "234",
            "label": "MPLMS-PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16676",
            "district": "25",
            "Block": "234",
            "label": "ADWHSS-PATTUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16677",
            "district": "25",
            "Block": "234",
            "label": "GOVERNMENT MODEL HIGHER SEC SCHOOL - PATTUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16678",
            "district": "25",
            "Block": "234",
            "label": "G(G)HSS-PATTUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16679",
            "district": "25",
            "Block": "234",
            "label": "ST. ISABEL GIRLS HIGHER SECONDARY SCHOOL - PATTUKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16680",
            "district": "25",
            "Block": "234",
            "label": "Rethinakumar Memorial Saradha Vidhyalaya Matriculation School, Sunnambukara Street, Pattukkottai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16681",
            "district": "25",
            "Block": "234",
            "label": "Mount Carmel Matriculation Higher Secondary School- Adhirampattinam Road, Pattukkottai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16682",
            "district": "25",
            "Block": "234",
            "label": "ST.THOMAS HIGHER SECONDARY SCHOOL  PATTUKKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16683",
            "district": "25",
            "Block": "234",
            "label": "PUMS- ADHIRAMPATTINAM NADUTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16684",
            "district": "25",
            "Block": "234",
            "label": "PUMS- ADHIRAMPATTINAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16685",
            "district": "25",
            "Block": "234",
            "label": "G(G)HSS-ADIRAMPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16686",
            "district": "25",
            "Block": "234",
            "label": "KHADIR  MOHIDEEN HSS BOYS ADIRAMPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16687",
            "district": "25",
            "Block": "234",
            "label": "KHADIR  MOHIDEEN HSS GIRLS-ADIRAMPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16688",
            "district": "25",
            "Block": "234",
            "label": "A.L MARTRICULATION SCHOOL- ADIRAMPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21103",
            "district": "25",
            "Block": "234",
            "label": "BRILLIANT -CBSE-PATTUKKOTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21104",
            "district": "25",
            "Block": "234",
            "label": "BON SECOURS PUBLIC SCHOOL -CBSE-PATTUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21330",
            "district": "25",
            "Block": "234",
            "label": "LAURAL SENIOR SEC. SCHOOL-PALLIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21766",
            "district": "25",
            "Block": "234",
            "label": "DHARMMABAL VEERAPPAN PUBLIC SCHOOL -POONAVARAYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22227",
            "district": "25",
            "Block": "234",
            "label": "BRINDHAVAN PUBLIC SCHOOL- SUKKIRANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22916",
            "district": "25",
            "Block": "234",
            "label": "GHS -Adirampattinam Karaiyur Theru",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23561",
            "district": "25",
            "Block": "234",
            "label": "GOLDEN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23562",
            "district": "25",
            "Block": "234",
            "label": "MOUNT CARMEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23561",
            "district": "25",
            "Block": "234",
            "label": "GOLDEN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23562",
            "district": "25",
            "Block": "234",
            "label": "MOUNT CARMEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23912",
            "district": "25",
            "Block": "234",
            "label": "N.R. PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "235": [
        {
            "key": "2680",
            "district": "6",
            "Block": "235",
            "label": "PUMS K.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2779",
            "district": "6",
            "Block": "235",
            "label": "PUMS CHINNAPERMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2802",
            "district": "6",
            "Block": "235",
            "label": "PUMS SIDUMANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2950",
            "district": "6",
            "Block": "235",
            "label": "PUMS NALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2980",
            "district": "6",
            "Block": "235",
            "label": "PUMS KOMBADIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2998",
            "district": "6",
            "Block": "235",
            "label": "PUMS K.AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3134",
            "district": "6",
            "Block": "235",
            "label": "PUMS ARAKASANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3159",
            "district": "6",
            "Block": "235",
            "label": "PUMS VANNATHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3170",
            "district": "6",
            "Block": "235",
            "label": "PUMS ONDIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3175",
            "district": "6",
            "Block": "235",
            "label": "PUMS NAGADASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3182",
            "district": "6",
            "Block": "235",
            "label": "PUMS AJJANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3351",
            "district": "6",
            "Block": "235",
            "label": "PUMS ODDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3419",
            "district": "6",
            "Block": "235",
            "label": "PUMS PUDUKARAMBU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3440",
            "district": "6",
            "Block": "235",
            "label": "PUMS GURUKALAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3513",
            "district": "6",
            "Block": "235",
            "label": "BRINDHAVAN MATRIC SCHOOL, SENGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3557",
            "district": "6",
            "Block": "235",
            "label": "PUMS SIGARALAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3627",
            "district": "6",
            "Block": "235",
            "label": "PUMS VATHIMARATHAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3660",
            "district": "6",
            "Block": "235",
            "label": "PUMS MAKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3697",
            "district": "6",
            "Block": "235",
            "label": "PUMS A.PAUPARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3756",
            "district": "6",
            "Block": "235",
            "label": "PUMS A.ETTIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3931",
            "district": "6",
            "Block": "235",
            "label": "PUMS VELAMANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3996",
            "district": "6",
            "Block": "235",
            "label": "PARAMVEER MHSS, PAUPARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4092",
            "district": "6",
            "Block": "235",
            "label": "PUMS PODUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4169",
            "district": "6",
            "Block": "235",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4357",
            "district": "6",
            "Block": "235",
            "label": "PUMS PERIYATHOTTAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4373",
            "district": "6",
            "Block": "235",
            "label": "PUMS GOWRICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4487",
            "district": "6",
            "Block": "235",
            "label": "PUMS KODIHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4508",
            "district": "6",
            "Block": "235",
            "label": "PUMS DIGILODU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4577",
            "district": "6",
            "Block": "235",
            "label": "PUMS MANJARAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4594",
            "district": "6",
            "Block": "235",
            "label": "PUMS MANJARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4699",
            "district": "6",
            "Block": "235",
            "label": "PUMS BIKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4724",
            "district": "6",
            "Block": "235",
            "label": "PUMS VATTUVANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4885",
            "district": "6",
            "Block": "235",
            "label": "PUMS HOGENAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4975",
            "district": "6",
            "Block": "235",
            "label": "PUMS EAMANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5011",
            "district": "6",
            "Block": "235",
            "label": "PUMS ELUMALMANDHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5048",
            "district": "6",
            "Block": "235",
            "label": "PUMS M.THANDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5157",
            "district": "6",
            "Block": "235",
            "label": "PUMS VELAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5164",
            "district": "6",
            "Block": "235",
            "label": "YESU RAJA MHSS, PAUPARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5196",
            "district": "6",
            "Block": "235",
            "label": "PUMS KOOKUTTAMARUDHAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5539",
            "district": "6",
            "Block": "235",
            "label": "PUMS KATTUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5552",
            "district": "6",
            "Block": "235",
            "label": "PUMS RAJAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5597",
            "district": "6",
            "Block": "235",
            "label": "PUMS GIDDANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5608",
            "district": "6",
            "Block": "235",
            "label": "PUMS PUDUR SOLAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5832",
            "district": "6",
            "Block": "235",
            "label": "PUMS KENDAYANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5849",
            "district": "6",
            "Block": "235",
            "label": "PUMS JANGAMAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6209",
            "district": "6",
            "Block": "235",
            "label": "PUMS KOVALLIKOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6467",
            "district": "6",
            "Block": "235",
            "label": "PUMS ANJEHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6491",
            "district": "6",
            "Block": "235",
            "label": "PUMS SUNJALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6520",
            "district": "6",
            "Block": "235",
            "label": "PUMS PANAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6589",
            "district": "6",
            "Block": "235",
            "label": "PUMS AARALKUNDHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6655",
            "district": "6",
            "Block": "235",
            "label": "PUMS BALINJERAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6689",
            "district": "6",
            "Block": "235",
            "label": "PUMS SIDUVAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6710",
            "district": "6",
            "Block": "235",
            "label": "PUMS POOVANKADU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9249",
            "district": "6",
            "Block": "235",
            "label": "PUMS ALAMARATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9250",
            "district": "6",
            "Block": "235",
            "label": "GHS ATHIMARATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9251",
            "district": "6",
            "Block": "235",
            "label": "PUMS BADHARAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9252",
            "district": "6",
            "Block": "235",
            "label": "PUMS BOOTHINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9253",
            "district": "6",
            "Block": "235",
            "label": "PUMS CHINNAPALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9254",
            "district": "6",
            "Block": "235",
            "label": "PUMS CHINNAPPANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9255",
            "district": "6",
            "Block": "235",
            "label": "GHS CHINNAVATHALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9256",
            "district": "6",
            "Block": "235",
            "label": "GHS DASAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9257",
            "district": "6",
            "Block": "235",
            "label": "PUMS DHONNAGUDDAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9258",
            "district": "6",
            "Block": "235",
            "label": "GHS EARIMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9259",
            "district": "6",
            "Block": "235",
            "label": "GHSS ERIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9260",
            "district": "6",
            "Block": "235",
            "label": "GHS ERRAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9261",
            "district": "6",
            "Block": "235",
            "label": "GHS ETTIKUTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9262",
            "district": "6",
            "Block": "235",
            "label": "PUMS ETTIKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9263",
            "district": "6",
            "Block": "235",
            "label": "PUMS GODUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9264",
            "district": "6",
            "Block": "235",
            "label": "GHS HALEPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9265",
            "district": "6",
            "Block": "235",
            "label": "GHSS KALAPPAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9266",
            "district": "6",
            "Block": "235",
            "label": "GHS KARIAPPANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9267",
            "district": "6",
            "Block": "235",
            "label": "PUMS KONDAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9268",
            "district": "6",
            "Block": "235",
            "label": "PUMS KOOTHAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9269",
            "district": "6",
            "Block": "235",
            "label": "GHSS KULLANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9270",
            "district": "6",
            "Block": "235",
            "label": "PUMS M.SANJEEVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9271",
            "district": "6",
            "Block": "235",
            "label": "GHSS MANGARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9272",
            "district": "6",
            "Block": "235",
            "label": "PUMS MANGURUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9273",
            "district": "6",
            "Block": "235",
            "label": "PUMS MALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9274",
            "district": "6",
            "Block": "235",
            "label": "GHS MANJANAIKANAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9275",
            "district": "6",
            "Block": "235",
            "label": "PUMS MOONGILMADUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9276",
            "district": "6",
            "Block": "235",
            "label": "PUMS NAGAMARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9277",
            "district": "6",
            "Block": "235",
            "label": "PUMS NALLAPPANAYAKKANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9278",
            "district": "6",
            "Block": "235",
            "label": "SRI VISALI VIDYALAYA MATRIC SCHOOL, AATHANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9279",
            "district": "6",
            "Block": "235",
            "label": "GHSS NERUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9280",
            "district": "6",
            "Block": "235",
            "label": "PUMS PALIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9281",
            "district": "6",
            "Block": "235",
            "label": "GHS PALLIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9282",
            "district": "6",
            "Block": "235",
            "label": "TSSGHSS PAUPARAPATTI - MODEL BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9283",
            "district": "6",
            "Block": "235",
            "label": "GHSS GIRLS PAUPARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9284",
            "district": "6",
            "Block": "235",
            "label": "KGBV RESIDENTIAL SCHOOL, PAUPPARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9285",
            "district": "6",
            "Block": "235",
            "label": "PUMS PAVALANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9286",
            "district": "6",
            "Block": "235",
            "label": "GHSS PENNAGARAM Boys",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9287",
            "district": "6",
            "Block": "235",
            "label": "GHSS PENNAGARAM GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9288",
            "district": "6",
            "Block": "235",
            "label": "SRI VINAYAGA MHSS, PENNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9289",
            "district": "6",
            "Block": "235",
            "label": "APPOLLO MHSS, PENNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9290",
            "district": "6",
            "Block": "235",
            "label": "GHS PERIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9291",
            "district": "6",
            "Block": "235",
            "label": "GHSS PERUMBALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9292",
            "district": "6",
            "Block": "235",
            "label": "ADHIYAMAN HIGH SCHOOL, RAMAKONDAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9293",
            "district": "6",
            "Block": "235",
            "label": "GHS POOCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9294",
            "district": "6",
            "Block": "235",
            "label": "PUMS PUDUNAGAMARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9295",
            "district": "6",
            "Block": "235",
            "label": "GHS PUDUBALASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9296",
            "district": "6",
            "Block": "235",
            "label": "GHS PUDUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9297",
            "district": "6",
            "Block": "235",
            "label": "GHS RAMAKONDAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9298",
            "district": "6",
            "Block": "235",
            "label": "PUMS SAMATHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9299",
            "district": "6",
            "Block": "235",
            "label": "GHS KOTTAYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9300",
            "district": "6",
            "Block": "235",
            "label": "GHS SEELANAYAKKANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9301",
            "district": "6",
            "Block": "235",
            "label": "GHSS SELLAMUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9302",
            "district": "6",
            "Block": "235",
            "label": "PUMS SITLAKARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9303",
            "district": "6",
            "Block": "235",
            "label": "PUMS TEAKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9304",
            "district": "6",
            "Block": "235",
            "label": "GHS THIRUMALVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9305",
            "district": "6",
            "Block": "235",
            "label": "PUMS THITHIOPPANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9306",
            "district": "6",
            "Block": "235",
            "label": "PUMS THOTLANAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9307",
            "district": "6",
            "Block": "235",
            "label": "PUMS VARAGOORANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9308",
            "district": "6",
            "Block": "235",
            "label": "GHS ALAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9309",
            "district": "6",
            "Block": "235",
            "label": "GHS CHINNAMBALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9310",
            "district": "6",
            "Block": "235",
            "label": "MODEL HSS CHINNAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9311",
            "district": "6",
            "Block": "235",
            "label": "PUMS KADAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9312",
            "district": "6",
            "Block": "235",
            "label": "PUMS NAGANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9313",
            "district": "6",
            "Block": "235",
            "label": "GHSS OOTTAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9314",
            "district": "6",
            "Block": "235",
            "label": "GHSS P.GOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21589",
            "district": "6",
            "Block": "235",
            "label": "SIVAM INTERNATIONAL SCHOOL, THITHIYOPANAHALLI, PENNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22077",
            "district": "6",
            "Block": "235",
            "label": "THE SPARTANS SCHOOL OF EXCELLENCE MATRIC SCHOOL, PARUVATHANAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22401",
            "district": "6",
            "Block": "235",
            "label": "VIVEKANANDA VIDYALAYA MATRIC SCHOOL, JANGAMAIYANOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22402",
            "district": "6",
            "Block": "235",
            "label": "LOTUS MATRIC SCHOOL, PENNAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22403",
            "district": "6",
            "Block": "235",
            "label": "NETAJI SUBHASH CHANDRA BOSE AVASIYA VIDYALAYA, PENNAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22404",
            "district": "6",
            "Block": "235",
            "label": "AASAN HIGH SCHOOL, KOTTAYUR, SATHIYANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22943",
            "district": "6",
            "Block": "235",
            "label": "GHS JELMARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23008",
            "district": "6",
            "Block": "235",
            "label": "VIVEKANANDA MATRIC SCHOOL, PUDHUR SOLAPPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23086",
            "district": "6",
            "Block": "235",
            "label": "NEWTONS APPLE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23913",
            "district": "6",
            "Block": "235",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23914",
            "district": "6",
            "Block": "235",
            "label": "PUMS MAKKANUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23915",
            "district": "6",
            "Block": "235",
            "label": "PUMS KADAMADAI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "236": [
        {
            "key": "685",
            "district": "18",
            "Block": "236",
            "label": "ST JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL ELAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1499",
            "district": "18",
            "Block": "236",
            "label": "ANNAI PARUVATHAMMAL MATRICULATION HIGH SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2334",
            "district": "18",
            "Block": "236",
            "label": "ARUTHRAA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3809",
            "district": "18",
            "Block": "236",
            "label": "PANIMALAR MATRICULATION HIGHER SECONDARY SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4866",
            "district": "18",
            "Block": "236",
            "label": "GOLDEN GATES MATRIC HIGHER SECONDARY SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5350",
            "district": "18",
            "Block": "236",
            "label": "CHRISTIAN MATRIC HR.SEC SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14828",
            "district": "18",
            "Block": "236",
            "label": "GHSS PERAMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14829",
            "district": "18",
            "Block": "236",
            "label": "MOULANA HSS-PERAMBALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14830",
            "district": "18",
            "Block": "236",
            "label": "THANTHAI HANS ROEVER HSS-PERAMBALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14831",
            "district": "18",
            "Block": "236",
            "label": "TELC MID SCH THURAIMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14832",
            "district": "18",
            "Block": "236",
            "label": "ST.DOMINIC'S GIRLS HIGHER SECONDARY SCHOOL, PERAMBALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14833",
            "district": "18",
            "Block": "236",
            "label": "PANIMALAR HSS-PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14834",
            "district": "18",
            "Block": "236",
            "label": "SRI SARADHA DEVI GIRLS HR. SEC. SCHOOL - PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14835",
            "district": "18",
            "Block": "236",
            "label": "SRI RAMAKRISHNA GIRLS MATRICULATION HR.SEC SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14836",
            "district": "18",
            "Block": "236",
            "label": "THANTHAI ROEVER MATRICULATION HIGH SCHOOL  PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14837",
            "district": "18",
            "Block": "236",
            "label": "DHANALAKSHMI SRINIVASAN HSS-PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14838",
            "district": "18",
            "Block": "236",
            "label": "DHANALAKSHMI SRINIVASAN MATRICULATION HIGHER SECONDARY SCHOOL, PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14839",
            "district": "18",
            "Block": "236",
            "label": "KEN-VIDYALA-PERAMBALUR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14840",
            "district": "18",
            "Block": "236",
            "label": "GOWTHAMABUTHAR-PER",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14841",
            "district": "18",
            "Block": "236",
            "label": "HANS-ROVER-CBSE-PER",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14842",
            "district": "18",
            "Block": "236",
            "label": "ALMIGHTY VIDHYALAYA PUBLIC  SCHOOL SIRUVACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14843",
            "district": "18",
            "Block": "236",
            "label": "PUMS NOCHIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14844",
            "district": "18",
            "Block": "236",
            "label": "PUMS,K.ERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14845",
            "district": "18",
            "Block": "236",
            "label": "PUMS,ARANARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14846",
            "district": "18",
            "Block": "236",
            "label": "RCMS-PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14847",
            "district": "18",
            "Block": "236",
            "label": "GHS-THAMBIRANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14848",
            "district": "18",
            "Block": "236",
            "label": "GHSS-KAVULPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14849",
            "district": "18",
            "Block": "236",
            "label": "GHSS CHATHIRAMANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14850",
            "district": "18",
            "Block": "236",
            "label": "GHSS_KURUMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14851",
            "district": "18",
            "Block": "236",
            "label": "GHSS-SIRUVACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14852",
            "district": "18",
            "Block": "236",
            "label": "GHS-VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14853",
            "district": "18",
            "Block": "236",
            "label": "PUMS,BOMMANAPADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14854",
            "district": "18",
            "Block": "236",
            "label": "PUMS,AYYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14855",
            "district": "18",
            "Block": "236",
            "label": "GHS_KALPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14856",
            "district": "18",
            "Block": "236",
            "label": "PUMS, ARUMADAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14857",
            "district": "18",
            "Block": "236",
            "label": "PUMS-ESANAI(N)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14858",
            "district": "18",
            "Block": "236",
            "label": "GHSS ESANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14859",
            "district": "18",
            "Block": "236",
            "label": "PUMS,KONERIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14860",
            "district": "18",
            "Block": "236",
            "label": "GHS LADAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14861",
            "district": "18",
            "Block": "236",
            "label": "ADWHS-LADAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14862",
            "district": "18",
            "Block": "236",
            "label": "GHSS_MELAPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14863",
            "district": "18",
            "Block": "236",
            "label": "PUMS THIRUPEYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14864",
            "district": "18",
            "Block": "236",
            "label": "GHSS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14865",
            "district": "18",
            "Block": "236",
            "label": "MRV-AMMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14866",
            "district": "18",
            "Block": "236",
            "label": "ADWHSS-KALARAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14867",
            "district": "18",
            "Block": "236",
            "label": "TRHS-INDIRA NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14868",
            "district": "18",
            "Block": "236",
            "label": "GHSS-ELAMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14869",
            "district": "18",
            "Block": "236",
            "label": "PUMS, SENJERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14870",
            "district": "18",
            "Block": "236",
            "label": "ADWHS-ALAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14871",
            "district": "18",
            "Block": "236",
            "label": "GHS-SENGUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21381",
            "district": "18",
            "Block": "236",
            "label": "GHS VADAKKUMADEVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21514",
            "district": "18",
            "Block": "236",
            "label": "ADWHS BOMMANAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21515",
            "district": "18",
            "Block": "236",
            "label": "ADWHS EACHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21587",
            "district": "18",
            "Block": "236",
            "label": "GREENPARK MATRICULATION HIGHER SECONDARY SCHOOL SIRUVACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21605",
            "district": "18",
            "Block": "236",
            "label": "MARAGATHAAM MATRIC HR SEC SCHOOL PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21754",
            "district": "18",
            "Block": "236",
            "label": "GOLDEN GATES VIDHYASHRAM SR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22540",
            "district": "18",
            "Block": "236",
            "label": "RAMAKRISHNA VIDHYABHAVAN CBSE PERAMBALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22973",
            "district": "18",
            "Block": "236",
            "label": "GHS (G) PERAMBALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23013",
            "district": "18",
            "Block": "236",
            "label": "SRI RAMAKRISHNA BOYS MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23524",
            "district": "18",
            "Block": "236",
            "label": "MALAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23525",
            "district": "18",
            "Block": "236",
            "label": "SRI MUTHAIYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23526",
            "district": "18",
            "Block": "236",
            "label": "AADHAV PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "23524",
            "district": "18",
            "Block": "236",
            "label": "MALAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23525",
            "district": "18",
            "Block": "236",
            "label": "SRI MUTHAIYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23526",
            "district": "18",
            "Block": "236",
            "label": "AADHAV PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "23916",
            "district": "18",
            "Block": "236",
            "label": "GHSS AMMAPALAYAM",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "237": [
        {
            "key": "375",
            "district": "3",
            "Block": "237",
            "label": "RAILWAY BALA BHAVAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "534",
            "district": "3",
            "Block": "237",
            "label": "ST MARYS BOYS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "557",
            "district": "3",
            "Block": "237",
            "label": "BALAJE  VIDYASHRAM MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "582",
            "district": "3",
            "Block": "237",
            "label": "KULAPATHI Dr.S.Balakrishnan Joshi Gurrukulam Matriculation HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "898",
            "district": "3",
            "Block": "237",
            "label": "EVERWIN MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1511",
            "district": "3",
            "Block": "237",
            "label": "DHANISH MATRIC. HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1619",
            "district": "3",
            "Block": "237",
            "label": "DAVIDSON MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2090",
            "district": "3",
            "Block": "237",
            "label": "PERIYAR MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2166",
            "district": "3",
            "Block": "237",
            "label": "ST MARY'S MATRICULATION GIRLS HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2259",
            "district": "3",
            "Block": "237",
            "label": "VEERA SAVARAKAR NETAJI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2359",
            "district": "3",
            "Block": "237",
            "label": "RADIANCE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2531",
            "district": "3",
            "Block": "237",
            "label": "ST JOSEPH AI HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2804",
            "district": "3",
            "Block": "237",
            "label": "RAILWAY MIXED HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2807",
            "district": "3",
            "Block": "237",
            "label": "ANNAI VAILANKANNI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2813",
            "district": "3",
            "Block": "237",
            "label": "BHARATHA VIDYALA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3204",
            "district": "3",
            "Block": "237",
            "label": "GRACE MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3249",
            "district": "3",
            "Block": "237",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3273",
            "district": "3",
            "Block": "237",
            "label": "SREE AYYAPPA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3366",
            "district": "3",
            "Block": "237",
            "label": "SRI PADMA SARANGAPANI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3437",
            "district": "3",
            "Block": "237",
            "label": "LOURDES MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3451",
            "district": "3",
            "Block": "237",
            "label": "THANGAM MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3459",
            "district": "3",
            "Block": "237",
            "label": "JAIGOPAL GARODIA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3775",
            "district": "3",
            "Block": "237",
            "label": "PONNUSWAMY MAT HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3946",
            "district": "3",
            "Block": "237",
            "label": "ANGEL MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3984",
            "district": "3",
            "Block": "237",
            "label": "KAMARAJ MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4214",
            "district": "3",
            "Block": "237",
            "label": "DANISH MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4647",
            "district": "3",
            "Block": "237",
            "label": "ST MOSES MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4734",
            "district": "3",
            "Block": "237",
            "label": "BETHEL MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4917",
            "district": "3",
            "Block": "237",
            "label": "DONBOSCO MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5015",
            "district": "3",
            "Block": "237",
            "label": "ST JOHNS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5032",
            "district": "3",
            "Block": "237",
            "label": "VATSALYA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5319",
            "district": "3",
            "Block": "237",
            "label": "CHANDABAI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5323",
            "district": "3",
            "Block": "237",
            "label": "GOODWILL HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "5427",
            "district": "3",
            "Block": "237",
            "label": "JRET RAGHAVA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5714",
            "district": "3",
            "Block": "237",
            "label": "SINGARAM PILLAI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5738",
            "district": "3",
            "Block": "237",
            "label": "KALIGI  RANGANATHAN  MONTFORD MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6038",
            "district": "3",
            "Block": "237",
            "label": "DON BOSCO MATRIC . HR. SEC.  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6104",
            "district": "3",
            "Block": "237",
            "label": "TAHA CONVENT MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6149",
            "district": "3",
            "Block": "237",
            "label": "DON BOSCO MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6469",
            "district": "3",
            "Block": "237",
            "label": "VIVEK VIDYALAYA MID SCH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6708",
            "district": "3",
            "Block": "237",
            "label": "JOY HIL MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7230",
            "district": "3",
            "Block": "237",
            "label": "GNANODHAYA GIRLS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7231",
            "district": "3",
            "Block": "237",
            "label": "DRBCC HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7232",
            "district": "3",
            "Block": "237",
            "label": "GHS BV COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7233",
            "district": "3",
            "Block": "237",
            "label": "ANNAI VAILANKANNI HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7234",
            "district": "3",
            "Block": "237",
            "label": "JAMALIA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7235",
            "district": "3",
            "Block": "237",
            "label": "CMS KC GARDEN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7236",
            "district": "3",
            "Block": "237",
            "label": "MODEL MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7237",
            "district": "3",
            "Block": "237",
            "label": "CMS GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7238",
            "district": "3",
            "Block": "237",
            "label": "SRI BALA VIDYALAYA  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7239",
            "district": "3",
            "Block": "237",
            "label": "ST.MARK'S MATRICULATION SCHOOL SEMBIUM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7240",
            "district": "3",
            "Block": "237",
            "label": "DON BOSCO HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7241",
            "district": "3",
            "Block": "237",
            "label": "CMS MADUMANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7242",
            "district": "3",
            "Block": "237",
            "label": "CMS VASANTHA GARADEN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7243",
            "district": "3",
            "Block": "237",
            "label": "CHSS BUNDER GARDEN ST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7244",
            "district": "3",
            "Block": "237",
            "label": "LOURDES GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7245",
            "district": "3",
            "Block": "237",
            "label": "JRET GIRLS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7246",
            "district": "3",
            "Block": "237",
            "label": "C.S.I MID.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7247",
            "district": "3",
            "Block": "237",
            "label": "RAILWAY COLONY HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7248",
            "district": "3",
            "Block": "237",
            "label": "SRI CHAMPALAL  PAGARIYA JAIN HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7249",
            "district": "3",
            "Block": "237",
            "label": "SDC VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7250",
            "district": "3",
            "Block": "237",
            "label": "CSI PUTHUIR SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7251",
            "district": "3",
            "Block": "237",
            "label": "CMS RANGASAI ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7252",
            "district": "3",
            "Block": "237",
            "label": "CHS SOMAIYA RAJAH ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7253",
            "district": "3",
            "Block": "237",
            "label": "CGHSS MKT ST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7254",
            "district": "3",
            "Block": "237",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL  GKM COLONY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7255",
            "district": "3",
            "Block": "237",
            "label": "CHSS PALAVAYAL ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7256",
            "district": "3",
            "Block": "237",
            "label": "PRCA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7257",
            "district": "3",
            "Block": "237",
            "label": "KANNADA SANGHA HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7258",
            "district": "3",
            "Block": "237",
            "label": "ICF SILVER JUBILEE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7259",
            "district": "3",
            "Block": "237",
            "label": "KARUNA SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7260",
            "district": "3",
            "Block": "237",
            "label": "RAILWAY COLONY MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7261",
            "district": "3",
            "Block": "237",
            "label": "DR SALAI GOVINDARAJAN GIRLS MATRIC HIGH SCHOOL, AYANAVARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7262",
            "district": "3",
            "Block": "237",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7263",
            "district": "3",
            "Block": "237",
            "label": "CHENNAI HIGH SCHOOL NAMMALWARPET CHENNAI-12",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7264",
            "district": "3",
            "Block": "237",
            "label": "CMS SUNDARAM STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7265",
            "district": "3",
            "Block": "237",
            "label": "CHSS KOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7266",
            "district": "3",
            "Block": "237",
            "label": "SRI BALAJI MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7267",
            "district": "3",
            "Block": "237",
            "label": "ST. JOSEPH OF ANNECY MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7268",
            "district": "3",
            "Block": "237",
            "label": "CMS VILLIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7269",
            "district": "3",
            "Block": "237",
            "label": "JOHN GABRIEL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7270",
            "district": "3",
            "Block": "237",
            "label": "SINGARAM PILLAI BOYS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7271",
            "district": "3",
            "Block": "237",
            "label": "SKDT HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7272",
            "district": "3",
            "Block": "237",
            "label": "GOVT HS VILLIWAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7273",
            "district": "3",
            "Block": "237",
            "label": "GOVT GIRLS HSS VILLIWAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7274",
            "district": "3",
            "Block": "237",
            "label": "JOHN WILLIAMS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7275",
            "district": "3",
            "Block": "237",
            "label": "SINGARAM PILLAI GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7276",
            "district": "3",
            "Block": "237",
            "label": "OUR  LADY OF VELANGANNI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21146",
            "district": "3",
            "Block": "237",
            "label": "RAMAKRISHNA MATRIC H.S. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21808",
            "district": "3",
            "Block": "237",
            "label": "EVERWIN VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22008",
            "district": "3",
            "Block": "237",
            "label": "KRM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22009",
            "district": "3",
            "Block": "237",
            "label": "THE TEMPLE-SCHOOL OF ENLIGHTENMENT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22334",
            "district": "3",
            "Block": "237",
            "label": "N.S.P. VIDHYAPEETAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22984",
            "district": "3",
            "Block": "237",
            "label": "ARYA SAMAJ HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23081",
            "district": "3",
            "Block": "237",
            "label": "KULAPATI DR.S.BALAKRISHNA JOSHI GURRUKULAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23082",
            "district": "3",
            "Block": "237",
            "label": "SHRI KRISHNASWAMY VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23207",
            "district": "3",
            "Block": "237",
            "label": "ICF VIDYANIKETAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "238": [
        {
            "key": "1368",
            "district": "34",
            "Block": "238",
            "label": "SURAJ MATRICULATION HR SEC SCHOOL MELATHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1894",
            "district": "34",
            "Block": "238",
            "label": "PUMS MELSATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1915",
            "district": "34",
            "Block": "238",
            "label": "PUMS KADAPPANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2000",
            "district": "34",
            "Block": "238",
            "label": "PUMS VILANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2050",
            "district": "34",
            "Block": "238",
            "label": "PUMS KOZHIPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2218",
            "district": "34",
            "Block": "238",
            "label": "CARMEL MATRIC HIGHER SECONDARY SCHOOL KOLLAPPALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2222",
            "district": "34",
            "Block": "238",
            "label": "PUMS NAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2281",
            "district": "34",
            "Block": "238",
            "label": "PUMS SEMMAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2392",
            "district": "34",
            "Block": "238",
            "label": "PUMS ARASAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2413",
            "district": "34",
            "Block": "238",
            "label": "PUMS ANMARUDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2435",
            "district": "34",
            "Block": "238",
            "label": "PUMS KONAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2686",
            "district": "34",
            "Block": "238",
            "label": "PUMS THAVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2713",
            "district": "34",
            "Block": "238",
            "label": "PUMS ANAIBOGI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2995",
            "district": "34",
            "Block": "238",
            "label": "BAVANI AIDED MIDDLE VEPPAMPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3328",
            "district": "34",
            "Block": "238",
            "label": "PUMS -MELNANDIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4156",
            "district": "34",
            "Block": "238",
            "label": "PUMS VINAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4463",
            "district": "34",
            "Block": "238",
            "label": "RCM MIDDLE SCHOOL MOSAVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4477",
            "district": "34",
            "Block": "238",
            "label": "PUMS BAGAVANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5185",
            "district": "34",
            "Block": "238",
            "label": "PUMS SEPTANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5996",
            "district": "34",
            "Block": "238",
            "label": "PUMS ANATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6733",
            "district": "34",
            "Block": "238",
            "label": "SIDDHARTHA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9591",
            "district": "34",
            "Block": "238",
            "label": "GHS ALLIYANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9592",
            "district": "34",
            "Block": "238",
            "label": "AUXILIUM MATRICULATION SCHOOL  ANMARUDAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9593",
            "district": "34",
            "Block": "238",
            "label": "GHS AVANIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9594",
            "district": "34",
            "Block": "238",
            "label": "GHS AYALAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9595",
            "district": "34",
            "Block": "238",
            "label": "GHSS(GIRLS)-KOLLAPPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9596",
            "district": "34",
            "Block": "238",
            "label": "GHSS(BOYS) KOLAPPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9597",
            "district": "34",
            "Block": "238",
            "label": "GHS MELACHARI KOTTUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9598",
            "district": "34",
            "Block": "238",
            "label": "GHSS MADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9599",
            "district": "34",
            "Block": "238",
            "label": "GHS MELATHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9600",
            "district": "34",
            "Block": "238",
            "label": "GHS NAMATHODU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9601",
            "district": "34",
            "Block": "238",
            "label": "GHSS NEDUNGUNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9602",
            "district": "34",
            "Block": "238",
            "label": "GHSS(BOYS) PERNAMALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9603",
            "district": "34",
            "Block": "238",
            "label": "GHSS(GIRLS) PERNAMALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9604",
            "district": "34",
            "Block": "238",
            "label": "GHS VALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9605",
            "district": "34",
            "Block": "238",
            "label": "GHS VAYALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21014",
            "district": "34",
            "Block": "238",
            "label": "ST.PAULS MATRIC HIGHER SECONDARY SCHOOL - VAYALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21577",
            "district": "34",
            "Block": "238",
            "label": "SWAMY VIVEKANANDA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23917",
            "district": "34",
            "Block": "238",
            "label": "GHS VAYALUR",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "239": [
        {
            "key": "430",
            "district": "25",
            "Block": "239",
            "label": "DR. J.C.KUMARAPPA CENTENARY VIDYA MANDIR MATRIC  HIGHER SECONDARY SCHOOL - PERAVURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2211",
            "district": "25",
            "Block": "239",
            "label": "MUVENDAR MATRICULATION HIGHER SECONDARY SCHOOL - PERAVURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3075",
            "district": "25",
            "Block": "239",
            "label": "PUMS- VALASAIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3620",
            "district": "25",
            "Block": "239",
            "label": "PUMS -NATTANIKKOTTAI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3986",
            "district": "25",
            "Block": "239",
            "label": "KALAIMAGAL MATRIC HR SEC SCHOOL - THIRUCHITRAMBALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4505",
            "district": "25",
            "Block": "239",
            "label": "KALAIMAGAL AIDED MIDDLE SCHOOL- THURAVIKKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4847",
            "district": "25",
            "Block": "239",
            "label": "PUMS- PONNANKANNIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5876",
            "district": "25",
            "Block": "239",
            "label": "PUMS - THULUKKAVIDUTHI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6670",
            "district": "25",
            "Block": "239",
            "label": "S.E.T. VIDHYADEVI MATRIC HR SEC SCHOOL - ALIVALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16710",
            "district": "25",
            "Block": "239",
            "label": "GHS - ENATHIKARAMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16711",
            "district": "25",
            "Block": "239",
            "label": "GHSS - EDAIYATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16712",
            "district": "25",
            "Block": "239",
            "label": "GHS - KALAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16713",
            "district": "25",
            "Block": "239",
            "label": "GHS - KONTRAIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16714",
            "district": "25",
            "Block": "239",
            "label": "GHS- KALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16715",
            "district": "25",
            "Block": "239",
            "label": "GHS - SITHUKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16716",
            "district": "25",
            "Block": "239",
            "label": "ADWHS -  KALLURANIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16717",
            "district": "25",
            "Block": "239",
            "label": "PUMS- KURUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16718",
            "district": "25",
            "Block": "239",
            "label": "PUMS -METTUVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16719",
            "district": "25",
            "Block": "239",
            "label": "INDIAN BANK EMPLOYEES ASSOCIATION  HIGH SCHOOL - KURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16720",
            "district": "25",
            "Block": "239",
            "label": "PUMS - PERIYATHERKUKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16721",
            "district": "25",
            "Block": "239",
            "label": "GHS - PAINGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16722",
            "district": "25",
            "Block": "239",
            "label": "PUMS - PALATHALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16723",
            "district": "25",
            "Block": "239",
            "label": "GHSS - AVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16724",
            "district": "25",
            "Block": "239",
            "label": "GHS - PINNAVASAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16725",
            "district": "25",
            "Block": "239",
            "label": "PUMS - POOVALUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16726",
            "district": "25",
            "Block": "239",
            "label": "ST.ANNE'S GIRLS HIGH SCHOOL - PUNALVASAL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16727",
            "district": "25",
            "Block": "239",
            "label": "PUNITHA.AROKIYA.ANNAI AIDED HSS - PUNALVASAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16728",
            "district": "25",
            "Block": "239",
            "label": "DON BOSCO MATRIC  HR SEC SCHOOL - PUNALVASAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16729",
            "district": "25",
            "Block": "239",
            "label": "GHS - SENGAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16730",
            "district": "25",
            "Block": "239",
            "label": "GHS-SERUVAVIDUTHI NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16731",
            "district": "25",
            "Block": "239",
            "label": "PUMS -POKKANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16732",
            "district": "25",
            "Block": "239",
            "label": "GHSS -THIRUCHITRAMBALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16733",
            "district": "25",
            "Block": "239",
            "label": "SRI VENKATESHWARA VIDHYALAYA CBSE SCHOOL - PERAVURANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16734",
            "district": "25",
            "Block": "239",
            "label": "GHS - VATTADIKOTTAI NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16735",
            "district": "25",
            "Block": "239",
            "label": "GHSS GIRLS- PERAVURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16736",
            "district": "25",
            "Block": "239",
            "label": "GHSS BOYS - PERAVURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16737",
            "district": "25",
            "Block": "239",
            "label": "VR VEERAPPA MEMORIAL HR SEC SCHOOL - PERAVURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16738",
            "district": "25",
            "Block": "239",
            "label": "ST ANNES HIGH SCHOOL - ATHANUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21478",
            "district": "25",
            "Block": "239",
            "label": "GHS - SORNAKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22581",
            "district": "25",
            "Block": "239",
            "label": "DELTA INTERNATIONAL SCHOOL - KURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22917",
            "district": "25",
            "Block": "239",
            "label": "GHS- EDAIYATHI KONAR ST",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "240": [
        {
            "key": "455",
            "district": "3",
            "Block": "240",
            "label": "HYDER GARDEN MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "470",
            "district": "3",
            "Block": "240",
            "label": "VEERA SAVARKAR NETHAJI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "516",
            "district": "3",
            "Block": "240",
            "label": "DON BOSCO MAT SCHOOL MKB NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "517",
            "district": "3",
            "Block": "240",
            "label": "SEVENTH DAY ADVENTIST MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2823",
            "district": "3",
            "Block": "240",
            "label": "VISWA BHARATHI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2909",
            "district": "3",
            "Block": "240",
            "label": "BAYNES MEM BAPTIST CHURCH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2991",
            "district": "3",
            "Block": "240",
            "label": "WISDOM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3004",
            "district": "3",
            "Block": "240",
            "label": "C.S.I MIDDLE SCHOOL MM ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3111",
            "district": "3",
            "Block": "240",
            "label": "C.S.I. MIDDLE SCHOOL GM ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3323",
            "district": "3",
            "Block": "240",
            "label": "H.U.C. MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3592",
            "district": "3",
            "Block": "240",
            "label": "ST PETERS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3777",
            "district": "3",
            "Block": "240",
            "label": "C.S.I. MIDDLE SCHOOL (CHOOLAI)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4304",
            "district": "3",
            "Block": "240",
            "label": "St. MARK's MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4428",
            "district": "3",
            "Block": "240",
            "label": "TERAPANTH JAIN VIDYALAYA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4610",
            "district": "3",
            "Block": "240",
            "label": "ANAIKAR MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4674",
            "district": "3",
            "Block": "240",
            "label": "PRINCESS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4813",
            "district": "3",
            "Block": "240",
            "label": "KR MONTFORD MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5483",
            "district": "3",
            "Block": "240",
            "label": "SRI LAKSHMI NARAYANA MATRICULATION HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5649",
            "district": "3",
            "Block": "240",
            "label": "MERCURY MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5658",
            "district": "3",
            "Block": "240",
            "label": "CMS MH ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5748",
            "district": "3",
            "Block": "240",
            "label": "SRI LGG MAT.HR.SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6047",
            "district": "3",
            "Block": "240",
            "label": "ST ALOYSIUS AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6265",
            "district": "3",
            "Block": "240",
            "label": "ST JOSEPH'S AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6598",
            "district": "3",
            "Block": "240",
            "label": "V.O.C VIDIYALAYAA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7174",
            "district": "3",
            "Block": "240",
            "label": "CMS MOORTHINGAR ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7175",
            "district": "3",
            "Block": "240",
            "label": "CHENNAI HIGHER SECONDARY SCHOOL  KALYANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7176",
            "district": "3",
            "Block": "240",
            "label": "DON BOSCO HSS VYASARPADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7177",
            "district": "3",
            "Block": "240",
            "label": "SMT  NDJA VIV VID JR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7178",
            "district": "3",
            "Block": "240",
            "label": "GHSS MKB NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7179",
            "district": "3",
            "Block": "240",
            "label": "CMS NEW KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7180",
            "district": "3",
            "Block": "240",
            "label": "CHS GANESAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7181",
            "district": "3",
            "Block": "240",
            "label": "CMS GOKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7182",
            "district": "3",
            "Block": "240",
            "label": "CGHSS MH ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7183",
            "district": "3",
            "Block": "240",
            "label": "SKNS PMC VIV VID JR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7184",
            "district": "3",
            "Block": "240",
            "label": "CMS EKANGIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7185",
            "district": "3",
            "Block": "240",
            "label": "CHS COOKS ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7186",
            "district": "3",
            "Block": "240",
            "label": "CMS H&S FACTORY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7187",
            "district": "3",
            "Block": "240",
            "label": "ADW BHSS KANNIGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7188",
            "district": "3",
            "Block": "240",
            "label": "ADW GHSS KANNIGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7189",
            "district": "3",
            "Block": "240",
            "label": "CHS AMMAIAMMAL ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7190",
            "district": "3",
            "Block": "240",
            "label": "CMS DECOSTER ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7191",
            "district": "3",
            "Block": "240",
            "label": "CUMS PERAMBUR BARRACKS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7192",
            "district": "3",
            "Block": "240",
            "label": "CHRISTIAN HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7193",
            "district": "3",
            "Block": "240",
            "label": "CHS THIRUVENGADA SAMY STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7194",
            "district": "3",
            "Block": "240",
            "label": "ADW HS PULIANTHOPE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7195",
            "district": "3",
            "Block": "240",
            "label": "C.M.S. 130 AMMAN KOIL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7196",
            "district": "3",
            "Block": "240",
            "label": "THE SAIVITE MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7197",
            "district": "3",
            "Block": "240",
            "label": "SRI RAMAKRISHNA MATH VIVEKANANDHA CENTENARY GIRLS' HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7198",
            "district": "3",
            "Block": "240",
            "label": "DR GURUSWAMY MUDALIAR TTV HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7199",
            "district": "3",
            "Block": "240",
            "label": "TTV HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7200",
            "district": "3",
            "Block": "240",
            "label": "GANESH BAI GALADA JAIN GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7201",
            "district": "3",
            "Block": "240",
            "label": "CMS K K LANE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7202",
            "district": "3",
            "Block": "240",
            "label": "The Hindu Union Committee HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7203",
            "district": "3",
            "Block": "240",
            "label": "DON BOSCO HS VEPERY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7204",
            "district": "3",
            "Block": "240",
            "label": "PT LEE Chenglavaraya Naicker HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7205",
            "district": "3",
            "Block": "240",
            "label": "C.M.S. VK PILLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7206",
            "district": "3",
            "Block": "240",
            "label": "C.M.S. 246 MINT STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7207",
            "district": "3",
            "Block": "240",
            "label": "SREE THIRUVOTEESWARAR FREE HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7208",
            "district": "3",
            "Block": "240",
            "label": "ST MATTHIAS' ANGLO INDIAN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7209",
            "district": "3",
            "Block": "240",
            "label": "GSSV JAIN VIDHYALAYA JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7210",
            "district": "3",
            "Block": "240",
            "label": "C.M.S. 87 ANNAPILLAI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7211",
            "district": "3",
            "Block": "240",
            "label": "THE MADRAS PROGRESSIVE UNION HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7212",
            "district": "3",
            "Block": "240",
            "label": "THE HINDU THEOLOGICAL  HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7213",
            "district": "3",
            "Block": "240",
            "label": "CHS WALLTAX ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7214",
            "district": "3",
            "Block": "240",
            "label": "SNCP ARYA SAMAJ HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7215",
            "district": "3",
            "Block": "240",
            "label": "MOONGIBAI GOENKA GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7216",
            "district": "3",
            "Block": "240",
            "label": "SHREE AG JAIN HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7217",
            "district": "3",
            "Block": "240",
            "label": "Motilal Fomra Sanatana Dharma Hss",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7218",
            "district": "3",
            "Block": "240",
            "label": "Shree K.Sugunibai Sanatana Dharma Girls HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7219",
            "district": "3",
            "Block": "240",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7220",
            "district": "3",
            "Block": "240",
            "label": "SRI BADALCHAND SCC JAIN VID MS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7221",
            "district": "3",
            "Block": "240",
            "label": "SHRI SANATANA DHARMA VIDYALAYA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7222",
            "district": "3",
            "Block": "240",
            "label": "SRI J T SURANA JAIN VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7223",
            "district": "3",
            "Block": "240",
            "label": "PT MISSION HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7224",
            "district": "3",
            "Block": "240",
            "label": "SMD HINDU HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7225",
            "district": "3",
            "Block": "240",
            "label": "SRI A.B. PAREKH  GUJARATI VIDYAMANDIR HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7226",
            "district": "3",
            "Block": "240",
            "label": "MANILAL M MEHTA GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7227",
            "district": "3",
            "Block": "240",
            "label": "SSV HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7228",
            "district": "3",
            "Block": "240",
            "label": "ZPH GUJ KKM MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7229",
            "district": "3",
            "Block": "240",
            "label": "CHS NP KOIL ST AT SHARMA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20988",
            "district": "3",
            "Block": "240",
            "label": "INDIRA VIVEKANANDA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21350",
            "district": "3",
            "Block": "240",
            "label": "SEVENTH DAY ADVENTIST HR. SEC SCHOOL (ICSE & ISE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21523",
            "district": "3",
            "Block": "240",
            "label": "M P DEVADAS HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22007",
            "district": "3",
            "Block": "240",
            "label": "SRI SUSHWANI MATHA JAIN VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23080",
            "district": "3",
            "Block": "240",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23276",
            "district": "3",
            "Block": "240",
            "label": "RAASHIDH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23346",
            "district": "3",
            "Block": "240",
            "label": "THE SHRI RAM UNIVERSAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "241": [
        {
            "key": "642",
            "district": "27",
            "Block": "241",
            "label": "EDWARD MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "681",
            "district": "27",
            "Block": "241",
            "label": "SHRI RENUGA VIDYALAYAM MATRICULATION HR SEC SCHOOL - LAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "845",
            "district": "27",
            "Block": "241",
            "label": "VICTORY MATRIC HS - GANDHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "912",
            "district": "27",
            "Block": "241",
            "label": "TRIUMPH  MS -  THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1003",
            "district": "27",
            "Block": "241",
            "label": "HINDU MS - DEVADANAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1311",
            "district": "27",
            "Block": "241",
            "label": "PUMS - E.PUTHUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1378",
            "district": "27",
            "Block": "241",
            "label": "SRI VALLI VARATHARAJ MAT HSS  - VARATHARAJ NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1438",
            "district": "27",
            "Block": "241",
            "label": "ST.ANNES JC MATRICULATION HR. SEC.  SCHOOL -  PERIYAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1641",
            "district": "27",
            "Block": "241",
            "label": "PUMS -  D.VADIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1669",
            "district": "27",
            "Block": "241",
            "label": "PUMS - A.MEENAKSHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2542",
            "district": "27",
            "Block": "241",
            "label": "V.K.S.MEMORIAL MS, SEDAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3053",
            "district": "27",
            "Block": "241",
            "label": "PUMS -  NALLAKARUPPANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3086",
            "district": "27",
            "Block": "241",
            "label": "ST.PATRICKS R.C. MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3220",
            "district": "27",
            "Block": "241",
            "label": "PUMS  - AZHAGAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3301",
            "district": "27",
            "Block": "241",
            "label": "MAHARISHI VIDYA MANDIR MATRIC SCHOOL - GENGUVARPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3540",
            "district": "27",
            "Block": "241",
            "label": "SANKARANARAYANAN MEMORIAL MIDDLE SCHOOL -  VADUGAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3630",
            "district": "27",
            "Block": "241",
            "label": "BHARATHIYAR MS - VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3666",
            "district": "27",
            "Block": "241",
            "label": "7TH DAY ADV  MATRIC HR SEC SCHOOL -  T.KALLIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4002",
            "district": "27",
            "Block": "241",
            "label": "NELLAIAPPER MIDDLE SCHOOL - VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4128",
            "district": "27",
            "Block": "241",
            "label": "RENGAKRISHNAN MIDDLE SCHOOL - PERIYAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4587",
            "district": "27",
            "Block": "241",
            "label": "SRI MARKANDEYA WEAVERS MIDDLE SCHOOL - VADUGAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5062",
            "district": "27",
            "Block": "241",
            "label": "SIDDHARATHA MATRICULATION  SCHOOL -  KEELAVADAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5457",
            "district": "27",
            "Block": "241",
            "label": "3RD MPL MS - VADAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5752",
            "district": "27",
            "Block": "241",
            "label": "HAMEEDIA MS -  THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5980",
            "district": "27",
            "Block": "241",
            "label": "SEKKILAR MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6132",
            "district": "27",
            "Block": "241",
            "label": "VELLALAR MS -  VADUGAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6546",
            "district": "27",
            "Block": "241",
            "label": "NAYAGAM MS - T.VENKATACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18585",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS -  GENGUVARPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18586",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - G.KALLUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18587",
            "district": "27",
            "Block": "241",
            "label": "ST. PETERS HSS - G.KALLUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18588",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - DEVADANAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18589",
            "district": "27",
            "Block": "241",
            "label": "GOVT KALLAR HS - PULLAKKAPATTTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18590",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS - MANJALAR DAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18591",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS - SATHAKOVILPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18592",
            "district": "27",
            "Block": "241",
            "label": "GOVT MODEL HSS -  SILVARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18593",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - JAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18594",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS - BOMMINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18595",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS -  GULLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18596",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS -  A.VADIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18597",
            "district": "27",
            "Block": "241",
            "label": "SRI MAYAPANDEESWARI MS -  MELMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18598",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS -  VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18599",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - VAIGAIDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18600",
            "district": "27",
            "Block": "241",
            "label": "GOVT KALLAR HSS -  E.PUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18601",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS -  MURUGAMALAI NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18602",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS  -  T.KALLIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18603",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - THAMARAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18604",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS -  SARUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18605",
            "district": "27",
            "Block": "241",
            "label": "GOVT HSS - LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18606",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS, GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18607",
            "district": "27",
            "Block": "241",
            "label": "MUTHALAMMAN HINDU HSS -  VADAPUDUPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18608",
            "district": "27",
            "Block": "241",
            "label": "U.C.MS - KOTTAIMEDU ,VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18609",
            "district": "27",
            "Block": "241",
            "label": "GOVT GIRLS HSS - PERIYAKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18610",
            "district": "27",
            "Block": "241",
            "label": "RAHIM BROS.MS - VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18611",
            "district": "27",
            "Block": "241",
            "label": "7TH WARD MS - VADAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18612",
            "district": "27",
            "Block": "241",
            "label": "SRI SARASWATHI MS -VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18613",
            "district": "27",
            "Block": "241",
            "label": "V.M. GOVT HSS - PERIYAKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18614",
            "district": "27",
            "Block": "241",
            "label": "NADAR MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18615",
            "district": "27",
            "Block": "241",
            "label": "ST. ANNES HSS - PERIYAKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18616",
            "district": "27",
            "Block": "241",
            "label": "C.S.I. MS - THANDUPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18617",
            "district": "27",
            "Block": "241",
            "label": "NIRMALA MS - THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18618",
            "district": "27",
            "Block": "241",
            "label": "BUDDHAR KALA MS -  THENKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18619",
            "district": "27",
            "Block": "241",
            "label": "10TH WARD MUNICIPAL MIDDLE SCHOOL - THENKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21169",
            "district": "27",
            "Block": "241",
            "label": "TMHNU.Nadar Saraswathi Girls Matriculation Higher Secondary School VADAPUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21744",
            "district": "27",
            "Block": "241",
            "label": "TMHNU NADAR SARASWATHI PUBLIC SCHOOL - VADAPUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22614",
            "district": "27",
            "Block": "241",
            "label": "MOUNT ZION ACADEMY CBSE SCHOOL - G.MEENATCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22615",
            "district": "27",
            "Block": "241",
            "label": "SHRI ROSY VIDYAALAYA - LAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22616",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS -  ERUMALAINAYAKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22617",
            "district": "27",
            "Block": "241",
            "label": "GOVT HS -  AMMAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23918",
            "district": "27",
            "Block": "241",
            "label": "KALVI INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "242": [
        {
            "key": "400",
            "district": "4",
            "Block": "242",
            "label": "SRI KRISHNAA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "561",
            "district": "4",
            "Block": "242",
            "label": "IDEAL MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "624",
            "district": "4",
            "Block": "242",
            "label": "S.V.NAGAR VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "735",
            "district": "4",
            "Block": "242",
            "label": "DR. P.G.V MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "786",
            "district": "4",
            "Block": "242",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SAMICHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "821",
            "district": "4",
            "Block": "242",
            "label": "JAYCEE HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "827",
            "district": "4",
            "Block": "242",
            "label": "CHINKUMAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1065",
            "district": "4",
            "Block": "242",
            "label": "PUMS EDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1077",
            "district": "4",
            "Block": "242",
            "label": "EXCEL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1172",
            "district": "4",
            "Block": "242",
            "label": "SRI A.RAMASAMY MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1196",
            "district": "4",
            "Block": "242",
            "label": "PUMS KASTHURINAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1358",
            "district": "4",
            "Block": "242",
            "label": "AVB MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1482",
            "district": "4",
            "Block": "242",
            "label": "BHARATIYA VIDYA BHAVAN MATRIC HIGHER SECONDARY SCHOOL, AJJANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1483",
            "district": "4",
            "Block": "242",
            "label": "PUMS KALAPPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1536",
            "district": "4",
            "Block": "242",
            "label": "VIDYA VIKASINI MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1545",
            "district": "4",
            "Block": "242",
            "label": "BRINDAVAN VIDHYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1644",
            "district": "4",
            "Block": "242",
            "label": "ST.PAULS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1684",
            "district": "4",
            "Block": "242",
            "label": "PUMS VEERAKERALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1728",
            "district": "4",
            "Block": "242",
            "label": "PUMS KONDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1818",
            "district": "4",
            "Block": "242",
            "label": "KEARTIMAN ACADEMY MATRIC &HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1835",
            "district": "4",
            "Block": "242",
            "label": "YUVABHARATHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1872",
            "district": "4",
            "Block": "242",
            "label": "VEL INTERNATIONAL PUBLIC SCHOOL, KNG PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1909",
            "district": "4",
            "Block": "242",
            "label": "VIDYA VANAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1954",
            "district": "4",
            "Block": "242",
            "label": "GANDHI ADIGAL KALVI NIRUVANAM MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2024",
            "district": "4",
            "Block": "242",
            "label": "GSR MATRIC HR SEC SCHOOL KAMATCHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2126",
            "district": "4",
            "Block": "242",
            "label": "PUMS SUBRAMANIAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2136",
            "district": "4",
            "Block": "242",
            "label": "ST JOHN'S MATRIC HR SEC SCHOOL, PRESS COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2299",
            "district": "4",
            "Block": "242",
            "label": "BAPTIST ACADEMY MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2301",
            "district": "4",
            "Block": "242",
            "label": "ANGAPPA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2485",
            "district": "4",
            "Block": "242",
            "label": "PUMS BETTADHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2539",
            "district": "4",
            "Block": "242",
            "label": "HOLY CROSS MATRICULATION HR SEC SCHOOL. KANUVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2586",
            "district": "4",
            "Block": "242",
            "label": "PUMS RAKKIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2592",
            "district": "4",
            "Block": "242",
            "label": "PUMS THIPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2712",
            "district": "4",
            "Block": "242",
            "label": "PUMS PAPPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2723",
            "district": "4",
            "Block": "242",
            "label": "PUMS MATHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2746",
            "district": "4",
            "Block": "242",
            "label": "PUMS PERIYANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2776",
            "district": "4",
            "Block": "242",
            "label": "INFANT JESUS CONVENT MATRIC HSS,THEKKUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2879",
            "district": "4",
            "Block": "242",
            "label": "SRI RANGANATHAR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3186",
            "district": "4",
            "Block": "242",
            "label": "PUMS ONNIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3389",
            "district": "4",
            "Block": "242",
            "label": "IMMACULATE HEART OF MARY MATHSS PRESS COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3448",
            "district": "4",
            "Block": "242",
            "label": "PUMS APPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3723",
            "district": "4",
            "Block": "242",
            "label": "PUMS SOMAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3739",
            "district": "4",
            "Block": "242",
            "label": "PUMS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3845",
            "district": "4",
            "Block": "242",
            "label": "ANNAI VIOLET MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4218",
            "district": "4",
            "Block": "242",
            "label": "PUMS 24 VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4670",
            "district": "4",
            "Block": "242",
            "label": "VIVEKAM MATRIC HR SEC SCHOOL NO.4.VEERAPANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4775",
            "district": "4",
            "Block": "242",
            "label": "BISHOP FRANCIS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4800",
            "district": "4",
            "Block": "242",
            "label": "PUMS THOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4996",
            "district": "4",
            "Block": "242",
            "label": "INFANT JESUS CONVENT HIGHER SECONDARY SCHOOL FOR HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5179",
            "district": "4",
            "Block": "242",
            "label": "PUMS HOUSING UNIT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5266",
            "district": "4",
            "Block": "242",
            "label": "HARI OM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5420",
            "district": "4",
            "Block": "242",
            "label": "LAUREL MATRIC .HR .SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5668",
            "district": "4",
            "Block": "242",
            "label": "MGM MATRICULATION HR SEC SCHOOL, K.N.G.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5778",
            "district": "4",
            "Block": "242",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GOVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5779",
            "district": "4",
            "Block": "242",
            "label": "PUMS THUDIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6033",
            "district": "4",
            "Block": "242",
            "label": "PMA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6665",
            "district": "4",
            "Block": "242",
            "label": "AGGARWAL MATRICULATION HR SEC SCHOOL, K.N.G. PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6683",
            "district": "4",
            "Block": "242",
            "label": "SRI KRISHNA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12754",
            "district": "4",
            "Block": "242",
            "label": "GTRMS PALAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12755",
            "district": "4",
            "Block": "242",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12756",
            "district": "4",
            "Block": "242",
            "label": "PUMS SELVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12757",
            "district": "4",
            "Block": "242",
            "label": "TAT KALANILAYAM AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12758",
            "district": "4",
            "Block": "242",
            "label": "SRI K.RANGASAMY NAIDU HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12759",
            "district": "4",
            "Block": "242",
            "label": "PIONEER MILLS HIGHER SECONDARY SCHOOL JOTHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12760",
            "district": "4",
            "Block": "242",
            "label": "SRI RAMAKRISHNA MISSION VIDYALAYA HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12761",
            "district": "4",
            "Block": "242",
            "label": "GKD MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12762",
            "district": "4",
            "Block": "242",
            "label": "SRI RAMAKRISHNA MISSION VIDYALAYA SWAMI SHIVANANDA HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12763",
            "district": "4",
            "Block": "242",
            "label": "PUMS VINOBAVE ARIVOLINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12764",
            "district": "4",
            "Block": "242",
            "label": "GOVT HIGH SCHOOL NO 4 VEERAPANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12765",
            "district": "4",
            "Block": "242",
            "label": "THAMBU HIGHER SECONDARY SCHOOL PRESSCOLONY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12766",
            "district": "4",
            "Block": "242",
            "label": "GHS NARASIMMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12767",
            "district": "4",
            "Block": "242",
            "label": "GGHS ASOKAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12768",
            "district": "4",
            "Block": "242",
            "label": "GHSS ASOKAPURAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12769",
            "district": "4",
            "Block": "242",
            "label": "DR.DASARATHAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12770",
            "district": "4",
            "Block": "242",
            "label": "ST JOHN BOSCO MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12771",
            "district": "4",
            "Block": "242",
            "label": "V.C.SUBAIA MEENAKSHIAMMA MATRIC HR. SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12772",
            "district": "4",
            "Block": "242",
            "label": "THAI LAXMI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12773",
            "district": "4",
            "Block": "242",
            "label": "VIDYA VIKASINI OPPORTUNITY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12774",
            "district": "4",
            "Block": "242",
            "label": "GHSS PANNIMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12775",
            "district": "4",
            "Block": "242",
            "label": "GHS KANUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12776",
            "district": "4",
            "Block": "242",
            "label": "JAYAM FOUNDATION MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12777",
            "district": "4",
            "Block": "242",
            "label": "GGHSS CHINNATHADAGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12778",
            "district": "4",
            "Block": "242",
            "label": "GBHSS CHINNATHADAGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12779",
            "district": "4",
            "Block": "242",
            "label": "PUMS DHOOMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12780",
            "district": "4",
            "Block": "242",
            "label": "GTRHSS ANAIKATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12781",
            "district": "4",
            "Block": "242",
            "label": "CHAVARA VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12782",
            "district": "4",
            "Block": "242",
            "label": "THE PSBB MILLENNIUM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12783",
            "district": "4",
            "Block": "242",
            "label": "SRI SATHYA SAAI VIDHYA NIKETAN MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12784",
            "district": "4",
            "Block": "242",
            "label": "GHSS SUNDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12785",
            "district": "4",
            "Block": "242",
            "label": "HOLY ANGELS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12786",
            "district": "4",
            "Block": "242",
            "label": "GHSS 49,KAVUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12787",
            "district": "4",
            "Block": "242",
            "label": "IRCS  ( HI ) SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12788",
            "district": "4",
            "Block": "242",
            "label": "WVS SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12789",
            "district": "4",
            "Block": "242",
            "label": "NAIRS VIDHYA MANDHIR (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20908",
            "district": "4",
            "Block": "242",
            "label": "YUVA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20991",
            "district": "4",
            "Block": "242",
            "label": "BENGLEN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21253",
            "district": "4",
            "Block": "242",
            "label": "SAI VIDHYA NIKETHAN MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22139",
            "district": "4",
            "Block": "242",
            "label": "NOVA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22140",
            "district": "4",
            "Block": "242",
            "label": "BAPTIST ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22141",
            "district": "4",
            "Block": "242",
            "label": "YOUCAN SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22476",
            "district": "4",
            "Block": "242",
            "label": "AKSHARAM INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22477",
            "district": "4",
            "Block": "242",
            "label": "THE OOTY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22478",
            "district": "4",
            "Block": "242",
            "label": "THE FIRST SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22823",
            "district": "4",
            "Block": "242",
            "label": "THE UNITED PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23211",
            "district": "4",
            "Block": "242",
            "label": "CHETTINAD VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23216",
            "district": "4",
            "Block": "242",
            "label": "AKSHARA ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23340",
            "district": "4",
            "Block": "242",
            "label": "ANGAPPA ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23344",
            "district": "4",
            "Block": "242",
            "label": "AKSHAYA ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23463",
            "district": "4",
            "Block": "242",
            "label": "IMMACULATE INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23464",
            "district": "4",
            "Block": "242",
            "label": "AVANI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23465",
            "district": "4",
            "Block": "242",
            "label": "BHARATHIYA VIDYA BHAVAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23463",
            "district": "4",
            "Block": "242",
            "label": "IMMACULATE INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23464",
            "district": "4",
            "Block": "242",
            "label": "AVANI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23465",
            "district": "4",
            "Block": "242",
            "label": "BHARATHIYA VIDYA BHAVAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23919",
            "district": "4",
            "Block": "242",
            "label": "AVILA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23920",
            "district": "4",
            "Block": "242",
            "label": "ADITYA VIDYASHRAM - GURUGRAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "243": [
        {
            "key": "1614",
            "district": "36",
            "Block": "243",
            "label": "NEW WISDOM MATRIC  HSS, EARIGUTHIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3066",
            "district": "36",
            "Block": "243",
            "label": "PUMS MORASAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4768",
            "district": "36",
            "Block": "243",
            "label": "ADWMS MASIGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5000",
            "district": "36",
            "Block": "243",
            "label": "PUMS MEL KOTHAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5591",
            "district": "36",
            "Block": "243",
            "label": "PUMS PERIYA DHAMAL CHERUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6219",
            "district": "36",
            "Block": "243",
            "label": "PUMS CHINNA DHAMAL CHERUVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6229",
            "district": "36",
            "Block": "243",
            "label": "PUMS ODDARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6597",
            "district": "36",
            "Block": "243",
            "label": "PUMS SARANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6637",
            "district": "36",
            "Block": "243",
            "label": "KALAIMAGAL MIDDLE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8855",
            "district": "36",
            "Block": "243",
            "label": "GHS ARAVATLA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8856",
            "district": "36",
            "Block": "243",
            "label": "GHS , PASMARPENTA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8857",
            "district": "36",
            "Block": "243",
            "label": "GHS ERUKKAMBUT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8858",
            "district": "36",
            "Block": "243",
            "label": "GHS MACHAMBUT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8859",
            "district": "36",
            "Block": "243",
            "label": "GHS BALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8860",
            "district": "36",
            "Block": "243",
            "label": "SRI KRISHNA MATRIC SCHOOL, CHINNA DHAMAL CHERUVU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8861",
            "district": "36",
            "Block": "243",
            "label": "GHS PALLALAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8862",
            "district": "36",
            "Block": "243",
            "label": "GHS SATHKAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8863",
            "district": "36",
            "Block": "243",
            "label": "PUMS PERNAMBUT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8864",
            "district": "36",
            "Block": "243",
            "label": "ISLAMIAH HSS PERNAMBUT",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8866",
            "district": "36",
            "Block": "243",
            "label": "GGHSS PERNAMBUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8867",
            "district": "36",
            "Block": "243",
            "label": "GADWHSS PERNAMBUT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8868",
            "district": "36",
            "Block": "243",
            "label": "RAMIZA ORIENTAL GHS PERNAMBUT",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8869",
            "district": "36",
            "Block": "243",
            "label": "NUSRATHUL ISLAM GHSS, PERNAMBUT",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8870",
            "district": "36",
            "Block": "243",
            "label": "St. Paul's Matric Higher Secondary School, Pernambut",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8871",
            "district": "36",
            "Block": "243",
            "label": "BHARATHI VIDYALAYA NATIONAL INSTITUTE OF OPEN SCHOOLING",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "8872",
            "district": "36",
            "Block": "243",
            "label": "GADWHSS TT MOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8873",
            "district": "36",
            "Block": "243",
            "label": "PUMS CHINTHAKANAVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8875",
            "district": "36",
            "Block": "243",
            "label": "PUMS REDDYMANGKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8876",
            "district": "36",
            "Block": "243",
            "label": "GHSS ALINJIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8877",
            "district": "36",
            "Block": "243",
            "label": "PUMS MV KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8878",
            "district": "36",
            "Block": "243",
            "label": "SRI VIVEKANANDA VIDHAYALAYA MATRIC HSS , MAILPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8879",
            "district": "36",
            "Block": "243",
            "label": "GHSS MAILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8880",
            "district": "36",
            "Block": "243",
            "label": "IELC AIDED MIDDLE SCHOOL MAILPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8899",
            "district": "36",
            "Block": "243",
            "label": "GHS KOKKALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8900",
            "district": "36",
            "Block": "243",
            "label": "GHS. ERIGUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8901",
            "district": "36",
            "Block": "243",
            "label": "GHS BATHALAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21768",
            "district": "36",
            "Block": "243",
            "label": "AHS CONCORDIA, PERNAMBUT",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "22066",
            "district": "36",
            "Block": "243",
            "label": "ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL, PALLALAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22067",
            "district": "36",
            "Block": "243",
            "label": "ASH - SHAMS N&P SCHOOL, PERNAMBUT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22068",
            "district": "36",
            "Block": "243",
            "label": "GREEN VALLEY N&P SCHOOL  SATHKAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22391",
            "district": "36",
            "Block": "243",
            "label": "GHS MGR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23256",
            "district": "36",
            "Block": "243",
            "label": "VAANI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "244": [
        {
            "key": "598",
            "district": "8",
            "Block": "244",
            "label": "VIJAY VIKAS MATRIC HIGHER SECONDARY SCHOOL,KAMBULIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "672",
            "district": "8",
            "Block": "244",
            "label": "KONGU VELLALAR KALVI NIRUVANAM MATRIC HIGHER SECONDARY SCHOOL,KANJI KOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23422",
            "district": "8",
            "Block": "244",
            "label": "Brainstorm High school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "871",
            "district": "8",
            "Block": "244",
            "label": "PUMS, VEERANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "980",
            "district": "8",
            "Block": "244",
            "label": "KONGU VELLALAR MATRIC HIGHER SECONDARY SCHOOL, PERUNDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "993",
            "district": "8",
            "Block": "244",
            "label": "PUMS, PERIYAVEERASANGILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1171",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERUNDURAI SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1180",
            "district": "8",
            "Block": "244",
            "label": "SHRI VIDYAA BHAVAN MATRICULATION SCHOOL,PULAVARPALAYAM, VIJAYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23360",
            "district": "8",
            "Block": "244",
            "label": "SRI ARUL NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "1217",
            "district": "8",
            "Block": "244",
            "label": "PUMS,LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1287",
            "district": "8",
            "Block": "244",
            "label": "PUMS, AYEEGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1539",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERUNDURAI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1635",
            "district": "8",
            "Block": "244",
            "label": "PUMS,CINNAMALLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1682",
            "district": "8",
            "Block": "244",
            "label": "PUMS,KARUMANDISELLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1794",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERUNDURAI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1808",
            "district": "8",
            "Block": "244",
            "label": "ROOTS MATRIC HIGHER  SECONDARY SCHOOL, MOONGILPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1827",
            "district": "8",
            "Block": "244",
            "label": "PUMS,SELLAPPAGOUNDANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1876",
            "district": "8",
            "Block": "244",
            "label": "KALAIVANI KALVI NILAYAM THINGALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1904",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERIYAVETUVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1927",
            "district": "8",
            "Block": "244",
            "label": "PUMS,TANNERPANDALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1945",
            "district": "8",
            "Block": "244",
            "label": "KARUNYA VIDHYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL,PERUNDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2001",
            "district": "8",
            "Block": "244",
            "label": "PUMS,PERUNDURAI WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2009",
            "district": "8",
            "Block": "244",
            "label": "PUMS,CINNIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2116",
            "district": "8",
            "Block": "244",
            "label": "PUMS NEELA GOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2145",
            "district": "8",
            "Block": "244",
            "label": "SAGAR INTERNATIONAL SENIOR SECONDARY SCHOOL,SOLIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2191",
            "district": "8",
            "Block": "244",
            "label": "PUMS, VETTAIYANKINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2367",
            "district": "8",
            "Block": "244",
            "label": "ST.JOSEPH'S R.C. MIDDLE SCHOOL,KALLIYAMPUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2520",
            "district": "8",
            "Block": "244",
            "label": "PUMS, PAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2526",
            "district": "8",
            "Block": "244",
            "label": "PUMS, KAVUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2745",
            "district": "8",
            "Block": "244",
            "label": "BHARATHI MATRIC HIGHER SECONDARY SCHOOL,SHANMUGAPURAM, VIJAYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3563",
            "district": "8",
            "Block": "244",
            "label": "APEX SCHOOL FOR HEARING IMPAIRED, SLATERPURAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "23687",
            "district": "8",
            "Block": "244",
            "label": "Appichimar vidhya mandir school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4324",
            "district": "8",
            "Block": "244",
            "label": "VVIS SENIOR SECONDARY SCHOOL,KAMBULIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4533",
            "district": "8",
            "Block": "244",
            "label": "SHREE SWAMI VIVEKANANDHA MATRIC HIGHER SECONDARY SCHOOL, K.S.PALAYAM, PERUNDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12296",
            "district": "8",
            "Block": "244",
            "label": "GHSS,VIJAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12297",
            "district": "8",
            "Block": "244",
            "label": "MAHIDHAR ACADEMY HIGH SCHOOL,NANDAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12298",
            "district": "8",
            "Block": "244",
            "label": "GHSS,NALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12299",
            "district": "8",
            "Block": "244",
            "label": "GHS, THIRUVACHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12300",
            "district": "8",
            "Block": "244",
            "label": "MPD GOVERNMENT HIGHER SECONDARY SCHOOL,THINGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12301",
            "district": "8",
            "Block": "244",
            "label": "GHS,KAIKOLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12302",
            "district": "8",
            "Block": "244",
            "label": "GHS,PANDIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12303",
            "district": "8",
            "Block": "244",
            "label": "GHSS,THUDUPATHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12304",
            "district": "8",
            "Block": "244",
            "label": "GGHSS, PERUNDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12305",
            "district": "8",
            "Block": "244",
            "label": "GHSS KANJIKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12306",
            "district": "8",
            "Block": "244",
            "label": "GHS,PETHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12307",
            "district": "8",
            "Block": "244",
            "label": "GBHSS,PERUNDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12308",
            "district": "8",
            "Block": "244",
            "label": "GHS,THOPPUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12309",
            "district": "8",
            "Block": "244",
            "label": "GMHSS,SEENAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21468",
            "district": "8",
            "Block": "244",
            "label": "GHS, SINGANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21469",
            "district": "8",
            "Block": "244",
            "label": "GHS, NITCHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21895",
            "district": "8",
            "Block": "244",
            "label": "THE RICHMOND MATRICULATION HIGHER SECONDARY SCHOOL, SEENAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21896",
            "district": "8",
            "Block": "244",
            "label": "THE RICHMOND PUBLIC SCHOOL, SEENAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23023",
            "district": "8",
            "Block": "244",
            "label": "BHARATHI INTERNATIONAL SCHOOL, VIJAYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23422",
            "district": "8",
            "Block": "244",
            "label": "Brainstorm High school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23422",
            "district": "8",
            "Block": "244",
            "label": "Brainstorm High school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23422",
            "district": "8",
            "Block": "244",
            "label": "Brainstorm High school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23484",
            "district": "8",
            "Block": "244",
            "label": "ERODE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23422",
            "district": "8",
            "Block": "244",
            "label": "Brainstorm High school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23921",
            "district": "8",
            "Block": "244",
            "label": "ERODE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "245": [
        {
            "key": "23352",
            "district": "4",
            "Block": "245",
            "label": "APG Matric Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23662",
            "district": "4",
            "Block": "245",
            "label": "St Mary's Matriculation School Edayarpalayam Coimbatore",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "23357",
            "district": "4",
            "Block": "245",
            "label": "Shri Meenakshi Matric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "524",
            "district": "4",
            "Block": "245",
            "label": "SREE NARAYANA MISSION MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "627",
            "district": "4",
            "Block": "245",
            "label": "GURUKULAM HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23392",
            "district": "4",
            "Block": "245",
            "label": "APG Matriculation Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "664",
            "district": "4",
            "Block": "245",
            "label": "SBOA MATRICULATION HR SEC SCHOOL,CHOKKAMPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "689",
            "district": "4",
            "Block": "245",
            "label": "MOTHER LAAND MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1021",
            "district": "4",
            "Block": "245",
            "label": "CMS VIDYA MANDIR MATRIC HR SEC SCHOOL, MANIAKARAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1064",
            "district": "4",
            "Block": "245",
            "label": "VASAVI VIDYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1092",
            "district": "4",
            "Block": "245",
            "label": "PRESENTATION CONVENT MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1320",
            "district": "4",
            "Block": "245",
            "label": "ASHRAM MATRICULATION HR SEC SCHOOL KOVAIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1406",
            "district": "4",
            "Block": "245",
            "label": "SRI SOWDESWARI VIDYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1562",
            "district": "4",
            "Block": "245",
            "label": "CMS MATRICULATION HR SEC SCHOOL, GANAPATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2082",
            "district": "4",
            "Block": "245",
            "label": "INFANT JESUS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2130",
            "district": "4",
            "Block": "245",
            "label": "SANGAMAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2204",
            "district": "4",
            "Block": "245",
            "label": "ELLAPPA MATRICULATION SCHOOL, KUNIAMUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2230",
            "district": "4",
            "Block": "245",
            "label": "T V SEKHARAN MEMORIAL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2235",
            "district": "4",
            "Block": "245",
            "label": "MEWS MATRICULATION SCHOOL POTHANUR MAIN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2364",
            "district": "4",
            "Block": "245",
            "label": "EINSTEIN PUBLIC MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2471",
            "district": "4",
            "Block": "245",
            "label": "MJ VINCENT MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2579",
            "district": "4",
            "Block": "245",
            "label": "MILTON MATRICULATION SCHOOL, CHOKKAMPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2640",
            "district": "4",
            "Block": "245",
            "label": "PUMS PERUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2767",
            "district": "4",
            "Block": "245",
            "label": "CMS RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3049",
            "district": "4",
            "Block": "245",
            "label": "KPM MATRICULATION HR SEC SCHOOL, EACHANARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3327",
            "district": "4",
            "Block": "245",
            "label": "RUKMANI KANNAN VIDYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3568",
            "district": "4",
            "Block": "245",
            "label": "PUMS NOYYALPALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3804",
            "district": "4",
            "Block": "245",
            "label": "S.E.S MATRICULATION HR SEC SCHOOL, GANAPATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3914",
            "district": "4",
            "Block": "245",
            "label": "EQUITAS GURUKUL MATRIC.HR.SEC .SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3929",
            "district": "4",
            "Block": "245",
            "label": "SINDHI VIDYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4548",
            "district": "4",
            "Block": "245",
            "label": "PUMS ECHANARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4633",
            "district": "4",
            "Block": "245",
            "label": "ST.JOSEPH'S MIDDLE SCHOOL, PODANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5522",
            "district": "4",
            "Block": "245",
            "label": "CMS SANGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5554",
            "district": "4",
            "Block": "245",
            "label": "PSG HIGH SCHOOL, VEDAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5712",
            "district": "4",
            "Block": "245",
            "label": "PUMS METTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6178",
            "district": "4",
            "Block": "245",
            "label": "PUMS KONAVAIKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6228",
            "district": "4",
            "Block": "245",
            "label": "SRI VAIDEESWARA VIDYALAYAM AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6282",
            "district": "4",
            "Block": "245",
            "label": "PANDIT NEHRU MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6339",
            "district": "4",
            "Block": "245",
            "label": "Kasturi Matriculation Hr.Sec.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6727",
            "district": "4",
            "Block": "245",
            "label": "NISE ACADEMY, POTHANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12790",
            "district": "4",
            "Block": "245",
            "label": "RAILWAY HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12791",
            "district": "4",
            "Block": "245",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12792",
            "district": "4",
            "Block": "245",
            "label": "GHSS KURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12793",
            "district": "4",
            "Block": "245",
            "label": "NIRMALAMATHA CONVENT MAT HR SEC SCHOOL, KUNIAMUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12794",
            "district": "4",
            "Block": "245",
            "label": "SRI VISWESWARA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12795",
            "district": "4",
            "Block": "245",
            "label": "V.S.SENGOTTAIAH MEMORIAL AIDED HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12796",
            "district": "4",
            "Block": "245",
            "label": "GREEN GARDEN MATRICULATION SCHOOL , KURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12797",
            "district": "4",
            "Block": "245",
            "label": "MOUNT CARMEL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12798",
            "district": "4",
            "Block": "245",
            "label": "GHS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12799",
            "district": "4",
            "Block": "245",
            "label": "SETHU VIDYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12800",
            "district": "4",
            "Block": "245",
            "label": "GHSS SUNDAKKAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12801",
            "district": "4",
            "Block": "245",
            "label": "THAVATHIRU SANDHALINGA ADIKALAR AIDED HIGHER SECONDARY SCHOOL, PERUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12802",
            "district": "4",
            "Block": "245",
            "label": "GHSS KUNIYAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12803",
            "district": "4",
            "Block": "245",
            "label": "GHSS KULATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12804",
            "district": "4",
            "Block": "245",
            "label": "V.L.B JANAKIAMMAL AIDED GIRLS HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12805",
            "district": "4",
            "Block": "245",
            "label": "AIDED HIGH SCHOOL CBM SAKUNDHALA VIDHYALAYA",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12806",
            "district": "4",
            "Block": "245",
            "label": "RKV Senior Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12807",
            "district": "4",
            "Block": "245",
            "label": "SARASWATHI RAMACHANDRAN VIDYALAYA MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12808",
            "district": "4",
            "Block": "245",
            "label": "NS MATRICULATION HR SEC SCHOOL, KUNIYAMUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12809",
            "district": "4",
            "Block": "245",
            "label": "AMRIT CENTRE FOR SPECIAL NEEDS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12810",
            "district": "4",
            "Block": "245",
            "label": "V.V.VANI VIDYALAYA MATRIC.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12811",
            "district": "4",
            "Block": "245",
            "label": "SHIRUSHTI VIDHYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12812",
            "district": "4",
            "Block": "245",
            "label": "SFV GHSS SEERANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12813",
            "district": "4",
            "Block": "245",
            "label": "CHS PAPPANAICKENPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12814",
            "district": "4",
            "Block": "245",
            "label": "CHS KOVILMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12815",
            "district": "4",
            "Block": "245",
            "label": "CHSS VENKITTAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12816",
            "district": "4",
            "Block": "245",
            "label": "AVILA CONVENT MATRICULATION HR SEC SCHOOL, VELANDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12817",
            "district": "4",
            "Block": "245",
            "label": "SAIBABA VID.MDL SCH.SB KOIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12818",
            "district": "4",
            "Block": "245",
            "label": "CHS KUPPAKONAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12819",
            "district": "4",
            "Block": "245",
            "label": "ST THOMAS AIDED HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12820",
            "district": "4",
            "Block": "245",
            "label": "CHSS RATHINAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12821",
            "district": "4",
            "Block": "245",
            "label": "GHS GANDHIMANAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12822",
            "district": "4",
            "Block": "245",
            "label": "GHSS, GANAPATHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12823",
            "district": "4",
            "Block": "245",
            "label": "CMS AIDED HIGH SCHOOL (MALAYALAM), GANAPATHY, COIMBATORE 641006",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12824",
            "district": "4",
            "Block": "245",
            "label": "ELSIE MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12825",
            "district": "4",
            "Block": "245",
            "label": "CHSS MANIYAKARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12826",
            "district": "4",
            "Block": "245",
            "label": "THE CAMFORD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12827",
            "district": "4",
            "Block": "245",
            "label": "KOVAI KALAIMAGAL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20953",
            "district": "4",
            "Block": "245",
            "label": "THE LITTLE KINGDOM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21079",
            "district": "4",
            "Block": "245",
            "label": "RATHINAM INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21181",
            "district": "4",
            "Block": "245",
            "label": "AMRITA VIDYALAYAM CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21313",
            "district": "4",
            "Block": "245",
            "label": "ORISON ACADEMY SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21359",
            "district": "4",
            "Block": "245",
            "label": "GHS SUGUNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21873",
            "district": "4",
            "Block": "245",
            "label": "C.S ACADEMY CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22479",
            "district": "4",
            "Block": "245",
            "label": "PEEPAL PRODIGY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22929",
            "district": "4",
            "Block": "245",
            "label": "GHS EDAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22930",
            "district": "4",
            "Block": "245",
            "label": "CORPORATION HIGH SCHOOL, RAMASAMY NAGAR, NALLAMPALAYAM ROAD.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23088",
            "district": "4",
            "Block": "245",
            "label": "SIDHAR GNANA PEEDAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23099",
            "district": "4",
            "Block": "245",
            "label": "NIRMALA MATHA CONVENT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23235",
            "district": "4",
            "Block": "245",
            "label": "GHS KUNIYAMUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23466",
            "district": "4",
            "Block": "245",
            "label": "PRISTINE NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23467",
            "district": "4",
            "Block": "245",
            "label": "CS ACADEMY INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23468",
            "district": "4",
            "Block": "245",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23466",
            "district": "4",
            "Block": "245",
            "label": "PRISTINE NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23467",
            "district": "4",
            "Block": "245",
            "label": "CS ACADEMY INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23468",
            "district": "4",
            "Block": "245",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23922",
            "district": "4",
            "Block": "245",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23923",
            "district": "4",
            "Block": "245",
            "label": "SINDHI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23924",
            "district": "4",
            "Block": "245",
            "label": "THE HARVEE SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "246": [
        {
            "key": "3290",
            "district": "22",
            "Block": "246",
            "label": "RAJ MATRIC HR. SEC. SCHOOL, PUTHIRAGOUNDAMPALAYAM, ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4753",
            "district": "22",
            "Block": "246",
            "label": "GNANADEEPAM MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5154",
            "district": "22",
            "Block": "246",
            "label": "PUMS-MANNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10980",
            "district": "22",
            "Block": "246",
            "label": "GHS NEIYAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10981",
            "district": "22",
            "Block": "246",
            "label": "GHSS THUMBAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10982",
            "district": "22",
            "Block": "246",
            "label": "ST. MICHEAL'S MATRIC SCHOOL,THUMBAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10983",
            "district": "22",
            "Block": "246",
            "label": "PUMS-MAMANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10984",
            "district": "22",
            "Block": "246",
            "label": "PUMS-ELUPULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10985",
            "district": "22",
            "Block": "246",
            "label": "PUMS-PAPPANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10986",
            "district": "22",
            "Block": "246",
            "label": "PUMS-EDAYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10987",
            "district": "22",
            "Block": "246",
            "label": "GHSS PANAIMADAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10988",
            "district": "22",
            "Block": "246",
            "label": "GHS THANDANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10989",
            "district": "22",
            "Block": "246",
            "label": "PUMS-SEKKADIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10990",
            "district": "22",
            "Block": "246",
            "label": "PUMS-A.KOMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10991",
            "district": "22",
            "Block": "246",
            "label": "GHS KOTTAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10992",
            "district": "22",
            "Block": "246",
            "label": "PUMS-KALLERIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10993",
            "district": "22",
            "Block": "246",
            "label": "PUMS-YETHAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10994",
            "district": "22",
            "Block": "246",
            "label": "GGHS YETHAPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10995",
            "district": "22",
            "Block": "246",
            "label": "GHSS YETHAPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10996",
            "district": "22",
            "Block": "246",
            "label": "EKALAIVA MODEL HR. SEC. SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10997",
            "district": "22",
            "Block": "246",
            "label": "PUMS-VAITHIYAGOUNDANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10998",
            "district": "22",
            "Block": "246",
            "label": "GHS PERIYAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10999",
            "district": "22",
            "Block": "246",
            "label": "K.G.B.V. PERIYAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11000",
            "district": "22",
            "Block": "246",
            "label": "PUMS-CHINNAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11001",
            "district": "22",
            "Block": "246",
            "label": "GHS THAMAYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11002",
            "district": "22",
            "Block": "246",
            "label": "GHS ARIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11003",
            "district": "22",
            "Block": "246",
            "label": "GBHSS PETHANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11004",
            "district": "22",
            "Block": "246",
            "label": "PUMS-PALANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11005",
            "district": "22",
            "Block": "246",
            "label": "GGHSS PETHANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11006",
            "district": "22",
            "Block": "246",
            "label": "PUMS-OLAPPADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11007",
            "district": "22",
            "Block": "246",
            "label": "PUMS-THALAVAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11008",
            "district": "22",
            "Block": "246",
            "label": "PUMS-PALANIYAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11009",
            "district": "22",
            "Block": "246",
            "label": "GHS GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11010",
            "district": "22",
            "Block": "246",
            "label": "GTRMS THALVALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11011",
            "district": "22",
            "Block": "246",
            "label": "GTRMS-PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11012",
            "district": "22",
            "Block": "246",
            "label": "ASISI MIDDLE SCHOOL, KARUMANDURAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "11013",
            "district": "22",
            "Block": "246",
            "label": "GTR HR. SEC. SCHOOL, KARUMANDHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11014",
            "district": "22",
            "Block": "246",
            "label": "GTR HIGH SCHOOL, THEKKAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11015",
            "district": "22",
            "Block": "246",
            "label": "GTRHSS, KARIYAKOILVALAVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11016",
            "district": "22",
            "Block": "246",
            "label": "GTRMS-SOOLANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21206",
            "district": "22",
            "Block": "246",
            "label": "MODEL SCHOOL , PETHANAIKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21242",
            "district": "22",
            "Block": "246",
            "label": "ANNAI MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21243",
            "district": "22",
            "Block": "246",
            "label": "PRAPAVATHY MATRIC HIGHER SECONDARY SCHOOL, KARUMANDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21457",
            "district": "22",
            "Block": "246",
            "label": "GHS UMAYALPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21496",
            "district": "22",
            "Block": "246",
            "label": "GTR HIGH SCHOOL, PAGUDUPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21594",
            "district": "22",
            "Block": "246",
            "label": "BMS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22108",
            "district": "22",
            "Block": "246",
            "label": "VIDHYA VIDYALAYA MATRICULATION SCHOOL, YETHAPPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22109",
            "district": "22",
            "Block": "246",
            "label": "SRI AMITH VIDYALAYAA MATRIC SCHOOL ABINAVAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22110",
            "district": "22",
            "Block": "246",
            "label": "SHRI SWAMY MATRIC SCHOOL, PETHANAICKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22111",
            "district": "22",
            "Block": "246",
            "label": "ST. JOSEPH INTERNATIONAL SCHOOL, PERUNCHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22437",
            "district": "22",
            "Block": "246",
            "label": "GHS VELLALAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23142",
            "district": "22",
            "Block": "246",
            "label": "GTR HIGH SCHOOL, KUNNUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "247": [
        {
            "key": "830",
            "district": "4",
            "Block": "247",
            "label": "SUBASH VIDHYA MANDIR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1124",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ERIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1575",
            "district": "4",
            "Block": "247",
            "label": "PUMS KONDEGOUNDEN PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1708",
            "district": "4",
            "Block": "247",
            "label": "PUMS THOPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2237",
            "district": "4",
            "Block": "247",
            "label": "PUMS VADAKKIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2470",
            "district": "4",
            "Block": "247",
            "label": "PKD MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2513",
            "district": "4",
            "Block": "247",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2630",
            "district": "4",
            "Block": "247",
            "label": "PUMS ACHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3226",
            "district": "4",
            "Block": "247",
            "label": "SWASTIK MAT SCHOOL, CHINNERIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3294",
            "district": "4",
            "Block": "247",
            "label": "CHANGAVI VIDHYAA MANDIR MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3401",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, R.PONNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3403",
            "district": "4",
            "Block": "247",
            "label": "VISHWA SISHYA VIDYODAYA SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3406",
            "district": "4",
            "Block": "247",
            "label": "BHARATH VIDHYA NIKKETAN MHSS ACHIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3503",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THAPPATTAIKILAVAN PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3517",
            "district": "4",
            "Block": "247",
            "label": "PUMS ALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3698",
            "district": "4",
            "Block": "247",
            "label": "Sri Chinthamani Memorial Matric HSS, Thippampatti",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3905",
            "district": "4",
            "Block": "247",
            "label": "PUMS JALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4157",
            "district": "4",
            "Block": "247",
            "label": "PUMS ZAMINKALIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4374",
            "district": "4",
            "Block": "247",
            "label": "PUMS T.NALLIGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4411",
            "district": "4",
            "Block": "247",
            "label": "MUTHUSAMY GOUNDER MATRICULATION HIGHER SECONDARY  SCHOOL R.GOPALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5020",
            "district": "4",
            "Block": "247",
            "label": "KESHAV VIDHYA MANDIR MHSS POLLACH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6373",
            "district": "4",
            "Block": "247",
            "label": "SIWALIK MATRIC.HR.SEC. SCHOOL, POLLACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6397",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOLLUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6595",
            "district": "4",
            "Block": "247",
            "label": "DISHA A LIFE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6712",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12985",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  BODIPLAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12986",
            "district": "4",
            "Block": "247",
            "label": "GHS DEVAMBADI KALIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12987",
            "district": "4",
            "Block": "247",
            "label": "PUMS JAMIN MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12988",
            "district": "4",
            "Block": "247",
            "label": "CENTRAL HIGH SCHOOL A NAGORE",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12989",
            "district": "4",
            "Block": "247",
            "label": "JK PUBLIC HIGHER SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12990",
            "district": "4",
            "Block": "247",
            "label": "GHSS PURAVIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12991",
            "district": "4",
            "Block": "247",
            "label": "GOVERNMENT HIGH SCHOOL PULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12992",
            "district": "4",
            "Block": "247",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAMAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12993",
            "district": "4",
            "Block": "247",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  MANNUR RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12994",
            "district": "4",
            "Block": "247",
            "label": "PUMS VADAKKUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12995",
            "district": "4",
            "Block": "247",
            "label": "PUMS P.NAGORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12996",
            "district": "4",
            "Block": "247",
            "label": "RISE MATRICULATION SCHOOL SAMIYANDIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12997",
            "district": "4",
            "Block": "247",
            "label": "GGHS NEGAMAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12998",
            "district": "4",
            "Block": "247",
            "label": "GHSS Negamam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12999",
            "district": "4",
            "Block": "247",
            "label": "GOVERNMENT HIGH SCHOOL GOLLAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21083",
            "district": "4",
            "Block": "247",
            "label": "ARB INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22818",
            "district": "4",
            "Block": "247",
            "label": "P.A.INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22819",
            "district": "4",
            "Block": "247",
            "label": "MAHARISHI INTERATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23237",
            "district": "4",
            "Block": "247",
            "label": "GHS R.GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23469",
            "district": "4",
            "Block": "247",
            "label": "ARAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23469",
            "district": "4",
            "Block": "247",
            "label": "ARAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23925",
            "district": "4",
            "Block": "247",
            "label": "PUMS ALAMPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "248": [
        {
            "key": "591",
            "district": "4",
            "Block": "248",
            "label": "R.V.S MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "754",
            "district": "4",
            "Block": "248",
            "label": "VIDYA NETHRRA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "916",
            "district": "4",
            "Block": "248",
            "label": "P.L.M.School,POLLACHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1567",
            "district": "4",
            "Block": "248",
            "label": "PUMS RANGASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1858",
            "district": "4",
            "Block": "248",
            "label": "MUNICIPAL MIDDLE SCHOOL,,ABT ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2168",
            "district": "4",
            "Block": "248",
            "label": "GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2213",
            "district": "4",
            "Block": "248",
            "label": "PUMS, KUNJIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2491",
            "district": "4",
            "Block": "248",
            "label": "MMS VENKATRAMANAN RD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2518",
            "district": "4",
            "Block": "248",
            "label": "OUR LADY OF LOURDES MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2521",
            "district": "4",
            "Block": "248",
            "label": "MUNICIPAL MIDDLE SCHOOL RAMAKRISHNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2574",
            "district": "4",
            "Block": "248",
            "label": "MMS,VSRA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2762",
            "district": "4",
            "Block": "248",
            "label": "MMS, MARAPETTAI ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2781",
            "district": "4",
            "Block": "248",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SULEESWARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2918",
            "district": "4",
            "Block": "248",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL, KARATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3044",
            "district": "4",
            "Block": "248",
            "label": "PUMS,UNJAVELAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3054",
            "district": "4",
            "Block": "248",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KOLARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3130",
            "district": "4",
            "Block": "248",
            "label": "PUMS,NAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3203",
            "district": "4",
            "Block": "248",
            "label": "MUNICIPAL MIDDLE SCHOOL KOTTUR ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3236",
            "district": "4",
            "Block": "248",
            "label": "AROKIAMATHA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3247",
            "district": "4",
            "Block": "248",
            "label": "BHARATIYA VIDYA MANDIR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3530",
            "district": "4",
            "Block": "248",
            "label": "AROKIAMATHA MATRICULATION SCHOOL, POLLACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3726",
            "district": "4",
            "Block": "248",
            "label": "MMS, BALAGOPALAPURAM ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3755",
            "district": "4",
            "Block": "248",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUNDALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3795",
            "district": "4",
            "Block": "248",
            "label": "VISWADEEPTHI MAT HSS, UNJAVELAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3824",
            "district": "4",
            "Block": "248",
            "label": "NACHIAR VIDYALAYAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3923",
            "district": "4",
            "Block": "248",
            "label": "ADITYA VIDHYA MANDIR MATRIC. HR. SEC. SCHOOL, SULESWARANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4072",
            "district": "4",
            "Block": "248",
            "label": "PUMS SEMBAGOUNDARCOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4124",
            "district": "4",
            "Block": "248",
            "label": "PUMS,SRINIVASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4266",
            "district": "4",
            "Block": "248",
            "label": "PUMS,AMBARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4319",
            "district": "4",
            "Block": "248",
            "label": "PUMS,VAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4541",
            "district": "4",
            "Block": "248",
            "label": "MMS,VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13000",
            "district": "4",
            "Block": "248",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,GOMANGALAMPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13001",
            "district": "4",
            "Block": "248",
            "label": "VIDYA NETHRRA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13002",
            "district": "4",
            "Block": "248",
            "label": "GHSS KANJAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13003",
            "district": "4",
            "Block": "248",
            "label": "PALANIGOUNDER HIGHER SECONDARY SCHOOL, POLLACHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13004",
            "district": "4",
            "Block": "248",
            "label": "NGNG HSS REDDIARUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13005",
            "district": "4",
            "Block": "248",
            "label": "GHSS SINGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13006",
            "district": "4",
            "Block": "248",
            "label": "GOVERNMENT HIGH SCHOOL,S.MALAYANDIPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13007",
            "district": "4",
            "Block": "248",
            "label": "ADW HSS THONDAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13008",
            "district": "4",
            "Block": "248",
            "label": "SHENBAGAM MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13009",
            "district": "4",
            "Block": "248",
            "label": "VIVEKANANTHAA VITHYALAYAA MATRIC SCHOOL, KUNCHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13010",
            "district": "4",
            "Block": "248",
            "label": "GHSS , ZAMIN UTHUKULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13011",
            "district": "4",
            "Block": "248",
            "label": "TMT. RUKMANIAMMAL HSS, JOTHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13012",
            "district": "4",
            "Block": "248",
            "label": "SVGHSS SAMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13013",
            "district": "4",
            "Block": "248",
            "label": "MARIAMMAL GIRLS HIGHER SECONDARY SCHOOL, POLLACHI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13014",
            "district": "4",
            "Block": "248",
            "label": "SHANTINIKETAN MATRICULATION SCHOOL, POLLACHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13015",
            "district": "4",
            "Block": "248",
            "label": "SAMATHUR RAMA IYYANKAR MUNICIPAL HR SEC SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13016",
            "district": "4",
            "Block": "248",
            "label": "SRI LATHANGI VIDHYA MANDIR MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13017",
            "district": "4",
            "Block": "248",
            "label": "MGHSS POLLACHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13018",
            "district": "4",
            "Block": "248",
            "label": "MUNICIPAL BOYS HR. SEC. SCHOOL, POLLACHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13019",
            "district": "4",
            "Block": "248",
            "label": "GOVT GIRLS HR.SC.SCHOOL, POLLACHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13020",
            "district": "4",
            "Block": "248",
            "label": "SHAANTHI MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21272",
            "district": "4",
            "Block": "248",
            "label": "SHRI VINAYAGAR MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21361",
            "district": "4",
            "Block": "248",
            "label": "GHS MAKKINAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21600",
            "district": "4",
            "Block": "248",
            "label": "CARMEL JYOTHI CMI PUBLIC SCHOOL, UNJAVELAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21601",
            "district": "4",
            "Block": "248",
            "label": "VINAYAGA VIDYODHAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21647",
            "district": "4",
            "Block": "248",
            "label": "HONEY BUNCH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23470",
            "district": "4",
            "Block": "248",
            "label": "SHAANTHI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23470",
            "district": "4",
            "Block": "248",
            "label": "SHAANTHI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23926",
            "district": "4",
            "Block": "248",
            "label": "GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23927",
            "district": "4",
            "Block": "248",
            "label": "NACHIAR - THE WORLD SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "249": [
        {
            "key": "1875",
            "district": "34",
            "Block": "249",
            "label": "PINKZ PUBLIC SCHOOL, KASTHAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3439",
            "district": "34",
            "Block": "249",
            "label": "SRI RAMAJAYAM MATRIC POLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3523",
            "district": "34",
            "Block": "249",
            "label": "TOWN MATRIC HSS POLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3608",
            "district": "34",
            "Block": "249",
            "label": "PUMS ATHUVAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3741",
            "district": "34",
            "Block": "249",
            "label": "PUMS EZUVAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4118",
            "district": "34",
            "Block": "249",
            "label": "PUMS NARANAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4149",
            "district": "34",
            "Block": "249",
            "label": "PUMS EDAPIRAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4339",
            "district": "34",
            "Block": "249",
            "label": "PUMS PADAVEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4413",
            "district": "34",
            "Block": "249",
            "label": "PUMS R.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4455",
            "district": "34",
            "Block": "249",
            "label": "SRI RAGAVENDIRA MATRIC HR. SEC. KASTHAMBADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4486",
            "district": "34",
            "Block": "249",
            "label": "DMMS POLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4592",
            "district": "34",
            "Block": "249",
            "label": "PUMS GANESAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4673",
            "district": "34",
            "Block": "249",
            "label": "PUMS VASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4789",
            "district": "34",
            "Block": "249",
            "label": "PUMS KANNIGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5086",
            "district": "34",
            "Block": "249",
            "label": "PUMS CHINNAPUSHPAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5258",
            "district": "34",
            "Block": "249",
            "label": "PUMS MUKKURUMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5441",
            "district": "34",
            "Block": "249",
            "label": "ADWMS KELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5577",
            "district": "34",
            "Block": "249",
            "label": "PUMS KURUVIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5639",
            "district": "34",
            "Block": "249",
            "label": "PUMS KATTIPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6458",
            "district": "34",
            "Block": "249",
            "label": "AUXILIUM MATRIC",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6475",
            "district": "34",
            "Block": "249",
            "label": "PUMS KALVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9701",
            "district": "34",
            "Block": "249",
            "label": "PUMS ALLINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9702",
            "district": "34",
            "Block": "249",
            "label": "GHSS ANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9703",
            "district": "34",
            "Block": "249",
            "label": "GHSS ATHIMOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9704",
            "district": "34",
            "Block": "249",
            "label": "S C GOVT BOYS HR.SEC. SCHOOL KALAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9705",
            "district": "34",
            "Block": "249",
            "label": "GHSS (GIRLS) KALAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9706",
            "district": "34",
            "Block": "249",
            "label": "GHS KALASAMUDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9707",
            "district": "34",
            "Block": "249",
            "label": "ADWHS KALIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9708",
            "district": "34",
            "Block": "249",
            "label": "GHS KANGEYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9709",
            "district": "34",
            "Block": "249",
            "label": "GHS KASTHAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9710",
            "district": "34",
            "Block": "249",
            "label": "GHSS KELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9711",
            "district": "34",
            "Block": "249",
            "label": "GHSS KUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9712",
            "district": "34",
            "Block": "249",
            "label": "GHSS MAMBATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9713",
            "district": "34",
            "Block": "249",
            "label": "GHS PADIYAMBATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9714",
            "district": "34",
            "Block": "249",
            "label": "GHS PERIYAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9715",
            "district": "34",
            "Block": "249",
            "label": "GHS PERUMALPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9716",
            "district": "34",
            "Block": "249",
            "label": "DON BOSCO HSS POLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9717",
            "district": "34",
            "Block": "249",
            "label": "GHSS (BOYS) POLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9718",
            "district": "34",
            "Block": "249",
            "label": "GHSS (GIRLS) POLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9719",
            "district": "34",
            "Block": "249",
            "label": "GHS POTHARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9720",
            "district": "34",
            "Block": "249",
            "label": "GHS RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9721",
            "district": "34",
            "Block": "249",
            "label": "GHSS RENUKONDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9722",
            "district": "34",
            "Block": "249",
            "label": "GHSS SANTHAVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9723",
            "district": "34",
            "Block": "249",
            "label": "GHSS SENGUNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9724",
            "district": "34",
            "Block": "249",
            "label": "GHS THURINJIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9725",
            "district": "34",
            "Block": "249",
            "label": "AKSHAYA VIDHYA. MANDHIR MATRIC HIGHER SECONDARY SCHOOL VASUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9726",
            "district": "34",
            "Block": "249",
            "label": "GHS VELLUR II",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9727",
            "district": "34",
            "Block": "249",
            "label": "OXFORD MATRIC HR. SEC. SCHOOL VENMANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9728",
            "district": "34",
            "Block": "249",
            "label": "PUMS VETTAGIRIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21060",
            "district": "34",
            "Block": "249",
            "label": "ASIAN MATRICULATION SCHOOL, SENGUNAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21179",
            "district": "34",
            "Block": "249",
            "label": "SRI RAMAJAYAM GLOBAL (CBSE)-POLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21528",
            "district": "34",
            "Block": "249",
            "label": "ELANGO HS-KESAVAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "21676",
            "district": "34",
            "Block": "249",
            "label": "GHS(GIRLS)-KELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23122",
            "district": "34",
            "Block": "249",
            "label": "VANMUKIL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23153",
            "district": "34",
            "Block": "249",
            "label": "SREE YOGARAMS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "250": [
        {
            "key": "887",
            "district": "32",
            "Block": "250",
            "label": "PUMS MADAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1197",
            "district": "32",
            "Block": "250",
            "label": "PUMS THUTHARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1582",
            "district": "32",
            "Block": "250",
            "label": "SRI DEVI MATRIC HR SEC SCHOOL, VELAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1776",
            "district": "32",
            "Block": "250",
            "label": "PUMS VAVIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2144",
            "district": "32",
            "Block": "250",
            "label": "PUMS NACHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2250",
            "district": "32",
            "Block": "250",
            "label": "PUMS SEMALAIGOUNDENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2283",
            "district": "32",
            "Block": "250",
            "label": "SUBBA NAIDU VENKITTAMMAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2838",
            "district": "32",
            "Block": "250",
            "label": "PUMS PUTHERICHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3096",
            "district": "32",
            "Block": "250",
            "label": "PUMS KOVILPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3869",
            "district": "32",
            "Block": "250",
            "label": "PUMS KH COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4576",
            "district": "32",
            "Block": "250",
            "label": "PUMS PALANIGOUNDEN PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4708",
            "district": "32",
            "Block": "250",
            "label": "PUMS KULLAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4833",
            "district": "32",
            "Block": "250",
            "label": "KINGSBERRY  MATRICULATION HR SEC SCHOOL, V.KALLIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5113",
            "district": "32",
            "Block": "250",
            "label": "KALAIMAGAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5227",
            "district": "32",
            "Block": "250",
            "label": "PUMS RAMAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5234",
            "district": "32",
            "Block": "250",
            "label": "PUMS MASANALLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5534",
            "district": "32",
            "Block": "250",
            "label": "PUMS MARUTHURAYAN VALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5713",
            "district": "32",
            "Block": "250",
            "label": "ADW MS KANDIYANKOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5817",
            "district": "32",
            "Block": "250",
            "label": "PUMS VELLANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5935",
            "district": "32",
            "Block": "250",
            "label": "PUMS E.VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13194",
            "district": "32",
            "Block": "250",
            "label": "GHSS KODUVAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13195",
            "district": "32",
            "Block": "250",
            "label": "SRI ALAGUMALAI VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13196",
            "district": "32",
            "Block": "250",
            "label": "GHSS V.KALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13197",
            "district": "32",
            "Block": "250",
            "label": "GHS VALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13198",
            "district": "32",
            "Block": "250",
            "label": "GHS KATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13199",
            "district": "32",
            "Block": "250",
            "label": "GHSS KETHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13200",
            "district": "32",
            "Block": "250",
            "label": "GEM INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13201",
            "district": "32",
            "Block": "250",
            "label": "P V K N HR SEC SCHOOL PONGALUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13202",
            "district": "32",
            "Block": "250",
            "label": "GHSS PERUNTHOLUVU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13203",
            "district": "32",
            "Block": "250",
            "label": "GHSS POLLIKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23399",
            "district": "32",
            "Block": "250",
            "label": "Sri Ramakrishna Vidhyalaya Matric HR.sec school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13204",
            "district": "32",
            "Block": "250",
            "label": "LM MATRICULATION SCHOOL, KULLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21547",
            "district": "32",
            "Block": "250",
            "label": "THAITAMIL HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21795",
            "district": "32",
            "Block": "250",
            "label": "ANNAL SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "22155",
            "district": "32",
            "Block": "250",
            "label": "GREEN PARK SMART SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22491",
            "district": "32",
            "Block": "250",
            "label": "ARUDHRA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22822",
            "district": "32",
            "Block": "250",
            "label": "JAI SHRIRAM ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL, AVINASHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23928",
            "district": "32",
            "Block": "250",
            "label": "PUMS VAVIPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "251": [
        {
            "key": "1058",
            "district": "19",
            "Block": "251",
            "label": "IDHAYA MATRICULATION HIGHER SECONDARY SCHOOL , KARAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1383",
            "district": "19",
            "Block": "251",
            "label": "AMALA ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, PON-PUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1677",
            "district": "19",
            "Block": "251",
            "label": "ST.JOSEPH MATRICULATION SCHOOL, VENTHANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1833",
            "district": "19",
            "Block": "251",
            "label": "NEHRU MATRIC HIGHER SECONDARY SCHOOL, M.USILAMPATTI,MELATHANIYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1883",
            "district": "19",
            "Block": "251",
            "label": "MEIYAPPA  VALLIAMMAI  KANCHI  SANKARA VIDYALAYA MATRICULATION  SCHOOL,VALAYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4744",
            "district": "19",
            "Block": "251",
            "label": "LIONS MATRICULATION HIGHER SECONDARY SCHOOL, PONNAMARAVATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5434",
            "district": "19",
            "Block": "251",
            "label": "CHIDAMBARAM MATRICULATION HIGHER SECONDARY, VALAYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5704",
            "district": "19",
            "Block": "251",
            "label": "SRI AMBAL MATRICULATION SCHOOL, KOPPANAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17119",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, OLIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17120",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MARAVAMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17121",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17122",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SHOOL KONNAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17123",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NAGARAPPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17124",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17125",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALAVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17126",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANDIYANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17127",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PON.PUDUPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17128",
            "district": "19",
            "Block": "251",
            "label": "V.V.HR SEC SCHOOL.PONNAMARAVAT",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17129",
            "district": "19",
            "Block": "251",
            "label": "GOVT G.H.S.PON.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17130",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGH SCHOOL, THIRUKKALAMPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17131",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGH SCHOOL, KARUPPUKUDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17132",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PICHAKALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17133",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGH SCHOOL, VARPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17134",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17135",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EDAIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17136",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARASAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17137",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NERINJIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17138",
            "district": "19",
            "Block": "251",
            "label": "M.A.K GOVERNMENT HIGHER SECONDARY SCHOOL, KARAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17139",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MULLIPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17140",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELATHANIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17141",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, R.PALAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17142",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17143",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMBOOTHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17144",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEELATHANIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17145",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELASIVAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17146",
            "district": "19",
            "Block": "251",
            "label": "M.NARAYANAN CHETTIAR HIGH SCHOOL,KOPPANAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17147",
            "district": "19",
            "Block": "251",
            "label": "KALAIMAGAL KALLOORI GIRLS HIGH SCHOOL,KOPPANAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17148",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17149",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MYLAPORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17150",
            "district": "19",
            "Block": "251",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTAYANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23202",
            "district": "19",
            "Block": "251",
            "label": "CT INTERNATIONAL SCHOOL,KESARAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23929",
            "district": "19",
            "Block": "251",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NALLUR",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "252": [
        {
            "key": "388",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANAIKATTUCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "389",
            "district": "33",
            "Block": "252",
            "label": "SRI SATHYA MATRICULATION SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "402",
            "district": "33",
            "Block": "252",
            "label": "DHAYA MATRICULATION SCHOOL,AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "405",
            "district": "33",
            "Block": "252",
            "label": "SRI RANI BAI MATRICULATION SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "409",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENEERKUPPAM (NORTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "417",
            "district": "33",
            "Block": "252",
            "label": "TBM MIDDLE SCHOOL ,POONAMALLEE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "420",
            "district": "33",
            "Block": "252",
            "label": "ST JOSEPH MATRICULATION SCHOOL AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "422",
            "district": "33",
            "Block": "252",
            "label": "SB VIJAYA REDDIAR MATRIC HR SEC SCHOOL, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "437",
            "district": "33",
            "Block": "252",
            "label": "THANGAMANI MATRIC HR SEC SCHOOL, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "442",
            "district": "33",
            "Block": "252",
            "label": "D. S. SELVAM MATRICULATION SCHOOL AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "447",
            "district": "33",
            "Block": "252",
            "label": "JAYA JAYA SANKARA INTERNATIONAL SCHOOL NAZARATHPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "452",
            "district": "33",
            "Block": "252",
            "label": "DANIEL MATRICULATION SCHOOL, POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "453",
            "district": "33",
            "Block": "252",
            "label": "SUNDAR MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "454",
            "district": "33",
            "Block": "252",
            "label": "ST JOHN'S MATRICULATION  SCHOOL MELMANAMBEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "466",
            "district": "33",
            "Block": "252",
            "label": "JAYA MATR SCHOOL, THIRUNINRAVUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "477",
            "district": "33",
            "Block": "252",
            "label": "ST ANTHONY MIDDLE SCH, AVADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "480",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUMAZHISAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "491",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAVALCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "493",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL MIDDLE SCHOOL ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "498",
            "district": "33",
            "Block": "252",
            "label": "JAWAHARLAL NEHRU VIDYALAYA MATRICULATION SCHOOL KUMANANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "522",
            "district": "33",
            "Block": "252",
            "label": "JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA,MATRICULATION SCHOOL,KAMARAJ  NAGAR, AVADI, CHENNAI-600071",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "539",
            "district": "33",
            "Block": "252",
            "label": "DAV MATRIC HR SEC SCHOOL , AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "640",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NAZARATHPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "698",
            "district": "33",
            "Block": "252",
            "label": "NAZARETH MATRIC HR  SEC SCHOOL, KAMARAJ NAGAR, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "725",
            "district": "33",
            "Block": "252",
            "label": "GANDHIJI MATRIC HR SEC SCHOOL,  AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "742",
            "district": "33",
            "Block": "252",
            "label": "ANGEL MATRIC HR SEC SCHOOL,THIRUNINRAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "779",
            "district": "33",
            "Block": "252",
            "label": "ST PAULS MATRICULATION SCHOOL, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "796",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VARADHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "991",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAYALANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1084",
            "district": "33",
            "Block": "252",
            "label": "JOY BELL MATRICULATION SCHOOL ,POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1127",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PARIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1153",
            "district": "33",
            "Block": "252",
            "label": "OXFORD MATRICULATION  HR SEC SCHOOL, THIRUNINRAVUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1160",
            "district": "33",
            "Block": "252",
            "label": "ST CLARET MATRIC HIGHER SECONDARY SCHOOL NADUKUTHAGAI, THIRUNINRAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1192",
            "district": "33",
            "Block": "252",
            "label": "ST JOHNS INTERNATIONAL RESIDENTIAL SCHOOL PAZHANJUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1201",
            "district": "33",
            "Block": "252",
            "label": "GRACE PARK CONVENT MATRIC HR SEC SCHOOL, THIRUNINRAVUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1340",
            "district": "33",
            "Block": "252",
            "label": "SRI KRISHNA MOOLCHAND VIVEKANANDHA VIDYALAYA MHSS THIRUNINRAVUR.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1413",
            "district": "33",
            "Block": "252",
            "label": "AMUTHA MATRICULATION SCHOOL, KRISHNANAGAR, THIRUNINRAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1610",
            "district": "33",
            "Block": "252",
            "label": "CHRIST MATRIC HIGHER SECONDARY SCHOOL SENNEERKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1617",
            "district": "33",
            "Block": "252",
            "label": "NABI CRESCENT MATRICULATION  SCHOOL, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1680",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEERARAGAVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1807",
            "district": "33",
            "Block": "252",
            "label": "EMMANUEL MATRICULATION SCHOOL, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1826",
            "district": "33",
            "Block": "252",
            "label": "RCM MATRICULATION SCHOOL, KAMARAJ NAGAR, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1956",
            "district": "33",
            "Block": "252",
            "label": "KARTHIK MATRICULATION HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1986",
            "district": "33",
            "Block": "252",
            "label": "BHARATHI MATRICULATION SCHOOL, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1996",
            "district": "33",
            "Block": "252",
            "label": "HOLY CRESENT MATRIC HR SEC SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2483",
            "district": "33",
            "Block": "252",
            "label": "GOOD SHEPHERD MATRICULATION  HIGHER SECONDARY  SCHOOL, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2627",
            "district": "33",
            "Block": "252",
            "label": "SWAMI VIVEKANANDA GURUKULAM PAPPAN CHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2654",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL MIDDLE SCHOOL GOVARTHANAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2698",
            "district": "33",
            "Block": "252",
            "label": "CSI MID.SCH,THANDURAI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2702",
            "district": "33",
            "Block": "252",
            "label": "SVT (TELUGU) MIDDLE SCHOOL,KAMARAJ NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2714",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MEPPUR THANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2923",
            "district": "33",
            "Block": "252",
            "label": "VAILANKANNI  MATRICULATION HIGHER SECONDARY, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2938",
            "district": "33",
            "Block": "252",
            "label": "SRI RAMAKRISHNA MATRIC . HR .SEC .SCHOOL , PATTABIRAM , CH-72",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2981",
            "district": "33",
            "Block": "252",
            "label": "SRI ANNAI VIDYASHARAM, MATRIC HIGHER SECONDARY SCHOOL, THIRUVERKADU.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3181",
            "district": "33",
            "Block": "252",
            "label": "KALAIMAGAL MATRIC HR SEC SCHOOL, ANNA NAGAR PATTABIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3416",
            "district": "33",
            "Block": "252",
            "label": "ST.THOMAS MATRIC HR SEC SCHOOL, THANDURAI, PATTABIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3572",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENEERKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3573",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUMANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3635",
            "district": "33",
            "Block": "252",
            "label": "IMMANUEL MATRIC HR SEC SCHOOL PATTABIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3661",
            "district": "33",
            "Block": "252",
            "label": "CHRIST KING MATRIC HIGHER SECONDARY SCHOOL, PATTABIRAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3694",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL MIDDLE SCHOOL KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3851",
            "district": "33",
            "Block": "252",
            "label": "RCM MATRICULATION SCHOOL, OLD AGRAHARAM, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3937",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PRAYAMBATH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4032",
            "district": "33",
            "Block": "252",
            "label": "ST.JOHNS MATRIC HR SEC SCHOOL, LAKSHMIPURAM, THIRUNINRAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4095",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AGARAMEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4101",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAMMAVAR KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4165",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NADUKUTHAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4182",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELMANAMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4388",
            "district": "33",
            "Block": "252",
            "label": "ST JOSEPH MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4430",
            "district": "33",
            "Block": "252",
            "label": "AVL MATRIC HIGHER SECONDARY SCHOOL,KATTUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4492",
            "district": "33",
            "Block": "252",
            "label": "THIRUVALLUVAR MID.SCH, NAMBI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4638",
            "district": "33",
            "Block": "252",
            "label": "JAIGOPAL GARODIA VIVEK VID CBSE SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4797",
            "district": "33",
            "Block": "252",
            "label": "INFANT JESUS MATRIC HR SEC SCHOOL, THANDURAI, PATTABIRAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5125",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAPPANCHATRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5170",
            "district": "33",
            "Block": "252",
            "label": "MAHALASHMI MATRICULATION HSS PARUTHIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23696",
            "district": "33",
            "Block": "252",
            "label": "Govt. Girls Model Hr. Sec. School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5263",
            "district": "33",
            "Block": "252",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUTHAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5436",
            "district": "33",
            "Block": "252",
            "label": "PENGUIN MATRIC HR SEC SCHOOL, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5471",
            "district": "33",
            "Block": "252",
            "label": "HOLY IMMANUEL MATRICULATION SCHOOL AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5672",
            "district": "33",
            "Block": "252",
            "label": "CHENNAI PUBLIC SCHOOL THIRUMAZHISAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6824",
            "district": "33",
            "Block": "252",
            "label": "ROUNDTABLE 30 VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6825",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL BANAVEDU THOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6826",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL SEMBARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6827",
            "district": "33",
            "Block": "252",
            "label": "ST.JOHN'S ACADEMY RESIDENTIAL MATRICULATION HIGHER SECONDARY SCHOOL,PALANJUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6828",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL GUDAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6829",
            "district": "33",
            "Block": "252",
            "label": "LCVKS HIGH SCHOOL KATTUPAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6830",
            "district": "33",
            "Block": "252",
            "label": "ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL KOLAPPANCHERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6831",
            "district": "33",
            "Block": "252",
            "label": "SRI VIDHYA ACADEMY INTERNATIONAL RESIDENCIAL SCHOOL KOLAPPANCHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6832",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL KOTTAMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6833",
            "district": "33",
            "Block": "252",
            "label": "MAHATMA GANDHI HIGH SCHOOL UTKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6834",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KUTHAMBAKKAM THEN COLONY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6835",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KEELMANAMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6836",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL NAZARATHPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6837",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGH SCHOOL NEMILICHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6838",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENNEERKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6839",
            "district": "33",
            "Block": "252",
            "label": "OXFORD MATRICULATION SCHOOL SENNERKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6840",
            "district": "33",
            "Block": "252",
            "label": "ADI DRIVIDA WELFARE HIGH SCHOOL MELMANAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6841",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL FOR THE VISUALLY IMPAIRED POONAMALLEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6842",
            "district": "33",
            "Block": "252",
            "label": "SAROJINI VARADAPPAN GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6843",
            "district": "33",
            "Block": "252",
            "label": "ARIGNAR ANNA GOVERNMENT HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6844",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6845",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL HIGH SCHOOL KONAMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6846",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL HIGH SCHOOL AVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6847",
            "district": "33",
            "Block": "252",
            "label": "IMMACULATE HEART OF MARY'S GIRLS HIGHER SECONDARY SCHOOL, AVADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6848",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6849",
            "district": "33",
            "Block": "252",
            "label": "RCM HSS KAMARAJ NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6850",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6851",
            "district": "33",
            "Block": "252",
            "label": "ANNAI LOURD HSS,PATTABIRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6852",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THANDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6853",
            "district": "33",
            "Block": "252",
            "label": "HOLY INFANT JESUS MATRIC HR SEC SCHOOL PATTABIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6854",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL HIGH SCHOOL PATTABIRAM CHATHRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6855",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THIRUMAZHISAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6856",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THIRUNINRAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6857",
            "district": "33",
            "Block": "252",
            "label": "DASAR HSS, THIRUNINRAVUR.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6858",
            "district": "33",
            "Block": "252",
            "label": "JAYA MATRICULATION SCHOOL, KRISHNAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6859",
            "district": "33",
            "Block": "252",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL , SUNDARASOZHAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6860",
            "district": "33",
            "Block": "252",
            "label": "RMK CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20892",
            "district": "33",
            "Block": "252",
            "label": "SAFA N&P SCHOOL AVADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20895",
            "district": "33",
            "Block": "252",
            "label": "DON GUANELLA SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20896",
            "district": "33",
            "Block": "252",
            "label": "SHRI.M.A.KRISHNASWAMY MATRICULATION HIGHER SECONDARY SCHOOL.AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20930",
            "district": "33",
            "Block": "252",
            "label": "OXFORD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20931",
            "district": "33",
            "Block": "252",
            "label": "FAITH SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21001",
            "district": "33",
            "Block": "252",
            "label": "JAYA PUBLIC SCHOOL, Thiruninravur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21003",
            "district": "33",
            "Block": "252",
            "label": "SRI BUDDHA VIDYA MANDIR MATRIC HR SEC SCHOOL, THIRUMAZHISAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21145",
            "district": "33",
            "Block": "252",
            "label": "SANA MODEL SCHOOL KATTUPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21235",
            "district": "33",
            "Block": "252",
            "label": "THE PUPIL SAVEETHA ECO SCHOOL SENNEERKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21254",
            "district": "33",
            "Block": "252",
            "label": "KARTHIKEYAN MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21708",
            "district": "33",
            "Block": "252",
            "label": "MAHALAKSHMI VIDHYA MANDHIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21709",
            "district": "33",
            "Block": "252",
            "label": "VELAMMAL VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21710",
            "district": "33",
            "Block": "252",
            "label": "VGN CHINMAYA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21711",
            "district": "33",
            "Block": "252",
            "label": "S.S.CITIZEN SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21712",
            "district": "33",
            "Block": "252",
            "label": "JAI HIND MATRICULATION SCHOOL,THIRUMAZHISAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21713",
            "district": "33",
            "Block": "252",
            "label": "SUDHARSANAM VIDYAASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21915",
            "district": "33",
            "Block": "252",
            "label": "SPARTAN INTERNATIONAL SCHOOL CHEMBARAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21945",
            "district": "33",
            "Block": "252",
            "label": "RCM HIGH SCHOOL KARAYANCHAVADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21953",
            "district": "33",
            "Block": "252",
            "label": "NAZARETH MATRIC HR SEC SCHOOL TNHB, AVADI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21984",
            "district": "33",
            "Block": "252",
            "label": "SRI RAM VIDHYA MANDIR KOTTAMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21985",
            "district": "33",
            "Block": "252",
            "label": "AL-HIKMA MADARASA & EDUCATIONAL CENTER",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21986",
            "district": "33",
            "Block": "252",
            "label": "VELAMMAL MATRIC HR SEC SCHOOL, PARUTHIPATTU,  AVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22307",
            "district": "33",
            "Block": "252",
            "label": "MAHAVEER RAJASTHANI INTERNATIONAL SCHOOL, GUDAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22308",
            "district": "33",
            "Block": "252",
            "label": "VELAMMAL VIDYALAYA SENNEERKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22309",
            "district": "33",
            "Block": "252",
            "label": "SRI GURU AKKASAMY VELAMMAL MATRIC HIGHER SECONDARY SCHOOL POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22310",
            "district": "33",
            "Block": "252",
            "label": "RBS NURSERY AND PRIMARY KONAMBEDU, AVADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22311",
            "district": "33",
            "Block": "252",
            "label": "AVADI MUNICIPAL HIGH SCHOOL PATTABIRAM RAILWAY SIDING",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22687",
            "district": "33",
            "Block": "252",
            "label": "MAHARISHI VIDYA MANDIR SR SEC SCHOOL, MELPAKKAM, AVADI-77",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22718",
            "district": "33",
            "Block": "252",
            "label": "CPS GLOBAL SCHOOL, THIRUMAZISAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22719",
            "district": "33",
            "Block": "252",
            "label": "ADITYA VIDYASHRAM , VEERARAGAVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23039",
            "district": "33",
            "Block": "252",
            "label": "PUSHKARA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23220",
            "district": "33",
            "Block": "252",
            "label": "SINDHI MODEL SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23252",
            "district": "33",
            "Block": "252",
            "label": "JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA HIGHER SECONDARY SCHOOL, AVADI, CHENNAI - 54",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23291",
            "district": "33",
            "Block": "252",
            "label": "SRI CHAITANYA TECHNO SCHOOL, POONAMALLEE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23337",
            "district": "33",
            "Block": "252",
            "label": "JOSEPH VIDYA KSHETRA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23338",
            "district": "33",
            "Block": "252",
            "label": "GRACE PARK PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23602",
            "district": "33",
            "Block": "252",
            "label": "AVPT  SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23602",
            "district": "33",
            "Block": "252",
            "label": "AVPT  SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23930",
            "district": "33",
            "Block": "252",
            "label": "The Pupil(International School)",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23931",
            "district": "33",
            "Block": "252",
            "label": "VELAMMAL ACADEMY",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23932",
            "district": "33",
            "Block": "252",
            "label": "JAI HIND PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "253": [
        {
            "key": "425",
            "district": "33",
            "Block": "253",
            "label": "GHS , PLACEPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "479",
            "district": "33",
            "Block": "253",
            "label": "JAI MARUTHI VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "510",
            "district": "33",
            "Block": "253",
            "label": "PUMS, TB PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1044",
            "district": "33",
            "Block": "253",
            "label": "PUMS, ATTRAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1108",
            "district": "33",
            "Block": "253",
            "label": "PUMS, ODAPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1165",
            "district": "33",
            "Block": "253",
            "label": "PUMS, NAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1213",
            "district": "33",
            "Block": "253",
            "label": "PUMS, ANANTHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1277",
            "district": "33",
            "Block": "253",
            "label": "PUMS, KUNNAVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1312",
            "district": "33",
            "Block": "253",
            "label": "CHENNAI VIDYALAYA MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1710",
            "district": "33",
            "Block": "253",
            "label": "PUMS, NELVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2173",
            "district": "33",
            "Block": "253",
            "label": "PUMS, VELLATHUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2444",
            "district": "33",
            "Block": "253",
            "label": "PUMS, BEEMANTHOPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3917",
            "district": "33",
            "Block": "253",
            "label": "PUMS, RAMANJERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3998",
            "district": "33",
            "Block": "253",
            "label": "PUMS, MANJAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6769",
            "district": "33",
            "Block": "253",
            "label": "GHS,ALLIKUZHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6770",
            "district": "33",
            "Block": "253",
            "label": "GOVT HSS, SEETHANJERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6771",
            "district": "33",
            "Block": "253",
            "label": "GOVT HIGH SCH, GOONIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6772",
            "district": "33",
            "Block": "253",
            "label": "CHRIST KING HIGH SCH, KATCHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6773",
            "district": "33",
            "Block": "253",
            "label": "GHS , MAMANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6774",
            "district": "33",
            "Block": "253",
            "label": "GOVT HIGH SCH, MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6775",
            "district": "33",
            "Block": "253",
            "label": "GOVT HIGHER SEC SCH, MEYYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6776",
            "district": "33",
            "Block": "253",
            "label": "PUMS, MONNAVEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6777",
            "district": "33",
            "Block": "253",
            "label": "GHS,SATHURANGAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6778",
            "district": "33",
            "Block": "253",
            "label": "TELC HR SEC SCH, PANDUR VIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6779",
            "district": "33",
            "Block": "253",
            "label": "GOVT HIGH SCH, PATTRAI P.PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6780",
            "district": "33",
            "Block": "253",
            "label": "GOVT HR SEC SCH, PENNALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6781",
            "district": "33",
            "Block": "253",
            "label": "GOVT HR SEC SCHOOL PONDAVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6782",
            "district": "33",
            "Block": "253",
            "label": "GOVT HR SEC SCH, POONDI VILLAG",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6783",
            "district": "33",
            "Block": "253",
            "label": "GHS,RAMATHANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6784",
            "district": "33",
            "Block": "253",
            "label": "GHS, SENDRAYANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6785",
            "district": "33",
            "Block": "253",
            "label": "GHS,THIRUPACHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6786",
            "district": "33",
            "Block": "253",
            "label": "GHS , BANGARAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6787",
            "district": "33",
            "Block": "253",
            "label": "GHS , THOMUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21045",
            "district": "33",
            "Block": "253",
            "label": "VIVEKANANDA VISION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21046",
            "district": "33",
            "Block": "253",
            "label": "Dr.S.Ramakrishnan Memorial CBSE School,Old Thirupachur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21294",
            "district": "33",
            "Block": "253",
            "label": "SHREE NIKETAN PATASALA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21716",
            "district": "33",
            "Block": "253",
            "label": "QALAM INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21916",
            "district": "33",
            "Block": "253",
            "label": "ST.XAVIERS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23933",
            "district": "33",
            "Block": "253",
            "label": "SRI NARAYANA MATRICULATION SCHOOL , KOLUNDHALUR",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "254": [
        {
            "key": "1530",
            "district": "17",
            "Block": "254",
            "label": "KAMARAAJAR MATRICULATION SCHOOL,THALAMBADY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2042",
            "district": "17",
            "Block": "254",
            "label": "SRI SAKTHI MATRIC HR.SEC SCHOOL, PUDANCHANTHAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2092",
            "district": "17",
            "Block": "254",
            "label": "VIVEKANANDA VIDYALLAYA MATRIC HR SEC SCHOOL, PUDUCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2423",
            "district": "17",
            "Block": "254",
            "label": "PUMS LAKKAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2449",
            "district": "17",
            "Block": "254",
            "label": "PUMS KULLAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2529",
            "district": "17",
            "Block": "254",
            "label": "PUMS VEPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2633",
            "district": "17",
            "Block": "254",
            "label": "SHANMUGANANDA AIDED MIDDLE SCHOOL AMMAPALAYAMPUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3857",
            "district": "17",
            "Block": "254",
            "label": "PAAVAI VIDHYASHRAM SENIOR SECONDARY SCHOOL, PACHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3863",
            "district": "17",
            "Block": "254",
            "label": "PUMS KATHIRANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4221",
            "district": "17",
            "Block": "254",
            "label": "PUMS KANNURPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5630",
            "district": "17",
            "Block": "254",
            "label": "PUMS MIN  ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6159",
            "district": "17",
            "Block": "254",
            "label": "RGR INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6296",
            "district": "17",
            "Block": "254",
            "label": "PUMS SEVAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6329",
            "district": "17",
            "Block": "254",
            "label": "R.G.R MATRICULATION HIGHER SECONDARY SCHOOL, PUDUCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11837",
            "district": "17",
            "Block": "254",
            "label": "GHSS ELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11838",
            "district": "17",
            "Block": "254",
            "label": "RAINBOW MATRICULATION SCHOOL, PUDUCHATRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11839",
            "district": "17",
            "Block": "254",
            "label": "GHSS PUDUCHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11840",
            "district": "17",
            "Block": "254",
            "label": "ADW HR SEC SCHOOL, KALANGANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11841",
            "district": "17",
            "Block": "254",
            "label": "GHSS KARAIKURICHIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11842",
            "district": "17",
            "Block": "254",
            "label": "GHSS PACHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11843",
            "district": "17",
            "Block": "254",
            "label": "GHSS THIRUMALAIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11844",
            "district": "17",
            "Block": "254",
            "label": "GHSS SELLAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11845",
            "district": "17",
            "Block": "254",
            "label": "KAMARAJAR HIGHER SECONDARY SCHOOL BOMMAIKUTTAIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20914",
            "district": "17",
            "Block": "254",
            "label": "CHANDRA CHELLAPPAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21069",
            "district": "17",
            "Block": "254",
            "label": "THE CHILDPLAN ACADEMY (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21266",
            "district": "17",
            "Block": "254",
            "label": "SRI GURUKULAM MATRICULATION SCHOOL, REDDIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22121",
            "district": "17",
            "Block": "254",
            "label": "GNANODAYA INTERNATIONAL SCHOOL(CBSE) AK SAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22448",
            "district": "17",
            "Block": "254",
            "label": "GHS THATHATHIRIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23471",
            "district": "4",
            "Block": "254",
            "label": "COIMBATORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23520",
            "district": "17",
            "Block": "254",
            "label": "PAAVAI MATRICULATION HIGHER SECONDARY SCHOOL, PACHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23520",
            "district": "17",
            "Block": "254",
            "label": "PAAVAI MATRICULATION HIGHER SECONDARY SCHOOL, PACHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "255": [
        {
            "key": "1105",
            "district": "19",
            "Block": "255",
            "label": "S.F.S. MATRIC HR.SEC.SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1273",
            "district": "19",
            "Block": "255",
            "label": "GUTHOOS MATRIC.HR SECONDARY SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1993",
            "district": "19",
            "Block": "255",
            "label": "SRI VENKATESWARA MAT.HR.SEC.SCHOOL, THIRUKOKARNAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4467",
            "district": "19",
            "Block": "255",
            "label": "THE LITTLE ANGELS JAYARANI MATRICULATION HIGHER SECONDARY  SCHOOL, PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5308",
            "district": "19",
            "Block": "255",
            "label": "MOUNTZION MATRIC  HR SEC SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6078",
            "district": "19",
            "Block": "255",
            "label": "SUDHARSAN VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL ,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6224",
            "district": "19",
            "Block": "255",
            "label": "BHARATH MATRIC. HR.SEC.SCHOOL, THIRUVAPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16914",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KARUPPUDAIYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16915",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALLUKKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16916",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SIRUNANGUPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16917",
            "district": "19",
            "Block": "255",
            "label": "LITTLE.FLOWER UPPER PRIMARY,T.M.R.SAMUTHIRAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16918",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUMMUPPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16919",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT MODEL HR SECONDARY SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16920",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGH SCHOOL,MULLUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16921",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MOOKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16922",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL M.KULAVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16923",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGHER SECONDARY SCHOOL,A.MATHUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16924",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KAMMANGADU.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16925",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MELAPPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16926",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,MANAVIDUTHI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16927",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGH SCHOOL,BALAN NAGAR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16928",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,ELLAIPPATTI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16929",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUNGONDANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16930",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL PERUNGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16931",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PUTHAMBUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16932",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELAVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16933",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,SAMMATTIVIDUTHI.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16934",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,RAJAKULATHUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16935",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,SEMBATTUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16936",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VANNARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16937",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL,VARAPPUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16938",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SOUTHCHETTIYAPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16939",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VADAVALAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16940",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL, KAYAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16941",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,RAJAPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16942",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGH SCHOOL, VAGAVASAL.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16943",
            "district": "19",
            "Block": "255",
            "label": "MAI SKOOL,VAGAVASAL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16944",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VALAVAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16945",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,ASHOKNAGAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16946",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,THAILANAGAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16947",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ADANAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16948",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ADANAKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16949",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SOTHUPALAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16950",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GANAPATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16951",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SOUTH THONDAIMANOORANI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16952",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUPPAYANPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16953",
            "district": "19",
            "Block": "255",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,AGARAPPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16954",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL, MACHUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16955",
            "district": "19",
            "Block": "255",
            "label": "GOVT GIRLS HR.SEC SCHOOL SANDAIPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16956",
            "district": "19",
            "Block": "255",
            "label": "GOVT UPPER PRIMARY SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16957",
            "district": "19",
            "Block": "255",
            "label": "TELC HIGHER SECONDARY SCHOOL, PUDUKKOTTAI,",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16958",
            "district": "19",
            "Block": "255",
            "label": "GOVERNMENT MIDDLE SCHOOL FOR HEARING IMPAIRED,PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16959",
            "district": "19",
            "Block": "255",
            "label": "TELC MIDDLE SCHOOL, PUDUKKOTTAI,",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16960",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL,KOILPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16961",
            "district": "19",
            "Block": "255",
            "label": "GOVT GIRLS HIGH SCHOOL, THIRUKKOKARANAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16962",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGHER SECONDARY SCHOOL, THIRUKKOKARANAM.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16963",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL.MID.S.SANDAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16964",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL HIGH SCHOOL,THIRUVAPPUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16965",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL HIGH SCHOOL, RAJAGOPALAPURAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16966",
            "district": "19",
            "Block": "255",
            "label": "HASANATHULJARIA MIDDLE SCHOOL PUDUKKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16967",
            "district": "19",
            "Block": "255",
            "label": "SAMSKRITA.VID.ORI.HIGH SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16968",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL HIGH SCHOOL, KAMARAJAPURAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16969",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL,BOSENAGAR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16970",
            "district": "19",
            "Block": "255",
            "label": "SRI.SUBBARAMAIYER MIDDLE SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16971",
            "district": "19",
            "Block": "255",
            "label": "RANEES' GOVT GIRLS HR.SEC SCHOOL,PUDUKKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16972",
            "district": "19",
            "Block": "255",
            "label": "RANEES GOVT GIRLS HIGH SCHOOL, PUDUKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16973",
            "district": "19",
            "Block": "255",
            "label": "GOVT.MIDDLE SCHOOL FOR THE VISUALLY IMPAIRED,PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16974",
            "district": "19",
            "Block": "255",
            "label": "RC MIDDLE SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16975",
            "district": "19",
            "Block": "255",
            "label": "SRI KULAPATHI BALAIAH HR SEC SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16976",
            "district": "19",
            "Block": "255",
            "label": "ST.MARY'S HIGHER SEC SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16977",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGH SCHOOL, GANDHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16978",
            "district": "19",
            "Block": "255",
            "label": "SACRED HEART GIRLS HR.SEC SCHOOL, PUDUKKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16979",
            "district": "19",
            "Block": "255",
            "label": "NIZAM ORIENTAL  ARABIC HIGH SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16980",
            "district": "19",
            "Block": "255",
            "label": "SRI RAJARAJESWARI MATRIC  SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16981",
            "district": "19",
            "Block": "255",
            "label": "SRI BRAGATHANBAL GOVT HR SEC SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16982",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL,SANTHANATHAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16983",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL, PILLAITHANEERPANTHAL.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16984",
            "district": "19",
            "Block": "255",
            "label": "VAIRAMS MATRIC HIGHER SEC SCHOOL, PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16985",
            "district": "19",
            "Block": "255",
            "label": "NEHRU MATRIC SCHOOL,PUDUKKOTTAI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16986",
            "district": "19",
            "Block": "255",
            "label": "MUNICIPAL MIDDLE SCHOOL,MALAIYEEDU.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20975",
            "district": "19",
            "Block": "255",
            "label": "BHARATHI VIDYALAYA MATRIC SCHOOL, THIRUGOKARNAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22912",
            "district": "19",
            "Block": "255",
            "label": "GOVT HIGH SCHOOL MANTHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23078",
            "district": "19",
            "Block": "255",
            "label": "TAAGORE MATRICULATION SCHOOL, ADAPPANVAYAL NORTH, PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23203",
            "district": "19",
            "Block": "255",
            "label": "SOUTH SPRING INTERNATIONAL SCHOOL,PERUNKONDANVIDUTHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23529",
            "district": "19",
            "Block": "255",
            "label": "DELHI PUBLIC SCHOOL , PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23529",
            "district": "19",
            "Block": "255",
            "label": "DELHI PUBLIC SCHOOL , PUDUKKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "256": [
        {
            "key": "1766",
            "district": "34",
            "Block": "256",
            "label": "ST. ANTONY'S MATRICULATION SCHOOL KANJI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2989",
            "district": "34",
            "Block": "256",
            "label": "PUMS VADAMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3122",
            "district": "34",
            "Block": "256",
            "label": "PUMS PADIAGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3210",
            "district": "34",
            "Block": "256",
            "label": "PUMS KALLARAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3336",
            "district": "34",
            "Block": "256",
            "label": "PUMS VEERANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3541",
            "district": "34",
            "Block": "256",
            "label": "PUMS THAMARAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3778",
            "district": "34",
            "Block": "256",
            "label": "PUMS ADIVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3859",
            "district": "34",
            "Block": "256",
            "label": "PUMS NAGAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3911",
            "district": "34",
            "Block": "256",
            "label": "PUMS NANDHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4195",
            "district": "34",
            "Block": "256",
            "label": "PUMS MUNNURMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4282",
            "district": "34",
            "Block": "256",
            "label": "PUMS NARASINGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4312",
            "district": "34",
            "Block": "256",
            "label": "PUMS DEVANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4448",
            "district": "34",
            "Block": "256",
            "label": "ST. ARULANANDAR MATRIC SCHOOL  PUDUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4495",
            "district": "34",
            "Block": "256",
            "label": "PUMS JAPTHIKARIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4544",
            "district": "34",
            "Block": "256",
            "label": "PUMS ORAVANDAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4826",
            "district": "34",
            "Block": "256",
            "label": "PUMS-PERIYERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6668",
            "district": "34",
            "Block": "256",
            "label": "SRI SARASWATHI VIDHYALAYA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9879",
            "district": "34",
            "Block": "256",
            "label": "GHS AMARNATHAPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9880",
            "district": "34",
            "Block": "256",
            "label": "GHSS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9881",
            "district": "34",
            "Block": "256",
            "label": "GHS C.GENGAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9882",
            "district": "34",
            "Block": "256",
            "label": "ST JOSEPHS HS C.NAMMIANDAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9883",
            "district": "34",
            "Block": "256",
            "label": "GHSS ERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9884",
            "district": "34",
            "Block": "256",
            "label": "GHSS KANJI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9885",
            "district": "34",
            "Block": "256",
            "label": "GHSS(GIRLS) KANJI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9886",
            "district": "34",
            "Block": "256",
            "label": "GHSS KARAPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9887",
            "district": "34",
            "Block": "256",
            "label": "GHS KOTTAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9888",
            "district": "34",
            "Block": "256",
            "label": "GHSS- MASHAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9889",
            "district": "34",
            "Block": "256",
            "label": "GHS MELAPUNJAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9890",
            "district": "34",
            "Block": "256",
            "label": "GHS MELNACHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9891",
            "district": "34",
            "Block": "256",
            "label": "GHS MELPADUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9892",
            "district": "34",
            "Block": "256",
            "label": "GHS MUTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9893",
            "district": "34",
            "Block": "256",
            "label": "GHSS PANAIOLAIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9894",
            "district": "34",
            "Block": "256",
            "label": "GHS PERIYAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9895",
            "district": "34",
            "Block": "256",
            "label": "GHSS(BOYS)-PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9896",
            "district": "34",
            "Block": "256",
            "label": "GGMHSS PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9897",
            "district": "34",
            "Block": "256",
            "label": "GHS PUDUR CHENGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9898",
            "district": "34",
            "Block": "256",
            "label": "GHS THORAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9899",
            "district": "34",
            "Block": "256",
            "label": "GHS VASUDEVANPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9900",
            "district": "34",
            "Block": "256",
            "label": "GHS VOIVIDANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20952",
            "district": "34",
            "Block": "256",
            "label": "SRI SARADHA MATRIC SCHOOL -PUDUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "257": [
        {
            "key": "3133",
            "district": "28",
            "Block": "257",
            "label": "PUMS MELAKARANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3680",
            "district": "28",
            "Block": "257",
            "label": "SEENI MAT HS NAGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5676",
            "district": "28",
            "Block": "257",
            "label": "PUMS LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6105",
            "district": "28",
            "Block": "257",
            "label": "SKMS PALLIVASALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19538",
            "district": "28",
            "Block": "257",
            "label": "HNHS PUDUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19539",
            "district": "28",
            "Block": "257",
            "label": "GHSS Pudur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19540",
            "district": "28",
            "Block": "257",
            "label": "PUMS KULAKATANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19541",
            "district": "28",
            "Block": "257",
            "label": "PUMS METTILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19542",
            "district": "28",
            "Block": "257",
            "label": "P V K S GOVT HIGH SCHOOL, SENNAMAREDDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19543",
            "district": "28",
            "Block": "257",
            "label": "PUMS MUTHUSAMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19544",
            "district": "28",
            "Block": "257",
            "label": "PUMS MANIYAKARANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19545",
            "district": "28",
            "Block": "257",
            "label": "PUMS KEELAKARANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19546",
            "district": "28",
            "Block": "257",
            "label": "PUMS SIVALARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19547",
            "district": "28",
            "Block": "257",
            "label": "PUMS THAPPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19548",
            "district": "28",
            "Block": "257",
            "label": "PUMS L V PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19549",
            "district": "28",
            "Block": "257",
            "label": "HNHSS N.VEDAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19550",
            "district": "28",
            "Block": "257",
            "label": "TNDTA MS NATHATHUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19551",
            "district": "28",
            "Block": "257",
            "label": "GHSS, BOOTHALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19552",
            "district": "28",
            "Block": "257",
            "label": "PUMS KOVILKUARETTIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19553",
            "district": "28",
            "Block": "257",
            "label": "TDTA JAYARAJ ANNAPACKIAM HIGH SCHOOL, NAGALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19554",
            "district": "28",
            "Block": "257",
            "label": "PUMS VOWALTHOTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19555",
            "district": "28",
            "Block": "257",
            "label": "SKK H HS Nagalapuram",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19556",
            "district": "28",
            "Block": "257",
            "label": "SAN HSS Nagalapuram",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19557",
            "district": "28",
            "Block": "257",
            "label": "PUMS SINGILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19558",
            "district": "28",
            "Block": "257",
            "label": "GHS KADALKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19559",
            "district": "28",
            "Block": "257",
            "label": "PUMS AYANVADAMALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19560",
            "district": "28",
            "Block": "257",
            "label": "MPMS RAMACHANDRAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19561",
            "district": "28",
            "Block": "257",
            "label": "PUMS AYANKARISALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19562",
            "district": "28",
            "Block": "257",
            "label": "PUMS MAVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19563",
            "district": "28",
            "Block": "257",
            "label": "PUMS, MASARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19564",
            "district": "28",
            "Block": "257",
            "label": "HNMS MUTHULAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19565",
            "district": "28",
            "Block": "257",
            "label": "GHS M.KOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19566",
            "district": "28",
            "Block": "257",
            "label": "PUMS MELANAMBIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19567",
            "district": "28",
            "Block": "257",
            "label": "GHSS SENKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19568",
            "district": "28",
            "Block": "257",
            "label": "GHSS VEMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19569",
            "district": "28",
            "Block": "257",
            "label": "NMS VEERAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19570",
            "district": "28",
            "Block": "257",
            "label": "PUMS, KEELA ARUNACHALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22641",
            "district": "28",
            "Block": "257",
            "label": "GHS KARUPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23934",
            "district": "28",
            "Block": "257",
            "label": "GHSS Pudur",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "258": [
        {
            "key": "3214",
            "district": "29",
            "Block": "258",
            "label": "DALMIA VIDYA MANDIR, DALMIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3987",
            "district": "29",
            "Block": "258",
            "label": "DALMIA HSS,DALMIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4009",
            "district": "29",
            "Block": "258",
            "label": "CHILD JESUS MATRI.S,PULLAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5058",
            "district": "29",
            "Block": "258",
            "label": "KALAIVANI MATRIC SCHOOL,PULLAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5332",
            "district": "29",
            "Block": "258",
            "label": "PUMS,KEELARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6197",
            "district": "29",
            "Block": "258",
            "label": "PAREE MATRIC  HR. SEC. SCHOOL, VENKATACHALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6503",
            "district": "29",
            "Block": "258",
            "label": "PUMS,ALAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14358",
            "district": "29",
            "Block": "258",
            "label": "GHSS,KALLAGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14359",
            "district": "29",
            "Block": "258",
            "label": "PUMS,THIRANIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14360",
            "district": "29",
            "Block": "258",
            "label": "PUMS,SARADAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14361",
            "district": "29",
            "Block": "258",
            "label": "GHS,SIRUKALAPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14362",
            "district": "29",
            "Block": "258",
            "label": "GHSS,PERUVALAPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14363",
            "district": "29",
            "Block": "258",
            "label": "GHSS,KANAKILIYANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14364",
            "district": "29",
            "Block": "258",
            "label": "GHSS,PUDURPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14365",
            "district": "29",
            "Block": "258",
            "label": "ST.PETER'S HSS,VIRAGALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14366",
            "district": "29",
            "Block": "258",
            "label": "ST.LOUIS R.C.M.SC,PULLAMBADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14367",
            "district": "29",
            "Block": "258",
            "label": "GHSS PULLAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14368",
            "district": "29",
            "Block": "258",
            "label": "GHSS,MALVOI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14369",
            "district": "29",
            "Block": "258",
            "label": "GHS,OOTATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14370",
            "district": "29",
            "Block": "258",
            "label": "PUMS,ALUNTHALAIPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14371",
            "district": "29",
            "Block": "258",
            "label": "PUMS,MELARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14372",
            "district": "29",
            "Block": "258",
            "label": "PUMS,MUTHUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14373",
            "district": "29",
            "Block": "258",
            "label": "PUMS,NEIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14374",
            "district": "29",
            "Block": "258",
            "label": "PUMS,THAPPOI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14375",
            "district": "29",
            "Block": "258",
            "label": "PUMS,KUMULUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14376",
            "district": "29",
            "Block": "258",
            "label": "PUMS,REDDIMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14377",
            "district": "29",
            "Block": "258",
            "label": "PUMS,P.K.AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14378",
            "district": "29",
            "Block": "258",
            "label": "ANNAI ERICA HR SEC SCHOOL P K AGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14379",
            "district": "29",
            "Block": "258",
            "label": "RCMS,VANTHALAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14380",
            "district": "29",
            "Block": "258",
            "label": "GHS,VANTHALAI KUDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14381",
            "district": "29",
            "Block": "258",
            "label": "PUMS,VELLANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14382",
            "district": "29",
            "Block": "258",
            "label": "PUMS,RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14383",
            "district": "29",
            "Block": "258",
            "label": "R C MEETPER HS, RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14384",
            "district": "29",
            "Block": "258",
            "label": "VETRI VIDHYALAYA HSS,VELLANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14385",
            "district": "29",
            "Block": "258",
            "label": "NAVA BHARATH VIDYALAYA SENIOR SEC. SCHOOL,E.VELLANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14386",
            "district": "29",
            "Block": "258",
            "label": "GHS, PUNJAI SANGENTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14387",
            "district": "29",
            "Block": "258",
            "label": "R C JAMES MIDDLE SCHOOL, KOVANDAKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14388",
            "district": "29",
            "Block": "258",
            "label": "ST MARY'S MIDDLE SCHOOL,VADUGARPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14389",
            "district": "29",
            "Block": "258",
            "label": "ST JOSEPH'S GIRLS HSS,VADUGARPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14390",
            "district": "29",
            "Block": "258",
            "label": "RC MIDDLE SCHOOL,KALLAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14391",
            "district": "29",
            "Block": "258",
            "label": "ADWHS,KALLAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22689",
            "district": "29",
            "Block": "258",
            "label": "SRI VETRI VIDHAYALAYA (CBSE) , VELLANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23129",
            "district": "29",
            "Block": "258",
            "label": "CHRIST THE KING PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "259": [
        {
            "key": "374",
            "district": "3",
            "Block": "259",
            "label": "BALALOK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "378",
            "district": "3",
            "Block": "259",
            "label": "Shri M Ct Muthiah Memorial Matriculation Hr Sec School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "390",
            "district": "3",
            "Block": "259",
            "label": "MORNING STAR MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "418",
            "district": "3",
            "Block": "259",
            "label": "ST. JOHN'S MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "528",
            "district": "3",
            "Block": "259",
            "label": "SUNSHINE ACADEMY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "573",
            "district": "3",
            "Block": "259",
            "label": "MAR THOMA MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "868",
            "district": "3",
            "Block": "259",
            "label": "VALLIAMMAL MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "926",
            "district": "3",
            "Block": "259",
            "label": "ALLWYN MATRIC HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "944",
            "district": "3",
            "Block": "259",
            "label": "DANIEL THOMAS MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "976",
            "district": "3",
            "Block": "259",
            "label": "JGVV MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "988",
            "district": "3",
            "Block": "259",
            "label": "GOOD HOPE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1053",
            "district": "3",
            "Block": "259",
            "label": "BENTINCK HR.SEC.SCHOOL FOR GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1223",
            "district": "3",
            "Block": "259",
            "label": "MOHAMED SATHAK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1233",
            "district": "3",
            "Block": "259",
            "label": "PADMA SARANGAPANI MATRIC HR.SE.SCHOOL, VIRUGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1452",
            "district": "3",
            "Block": "259",
            "label": "SBOA (M) HSS ANNA NAGAR WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1542",
            "district": "3",
            "Block": "259",
            "label": "J.M.Matriculation Girls Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1615",
            "district": "3",
            "Block": "259",
            "label": "NATIONAL STAR MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1784",
            "district": "3",
            "Block": "259",
            "label": "C.S.I. EWART MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1792",
            "district": "3",
            "Block": "259",
            "label": "ST. VINCENT'S MATRICULATION HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1947",
            "district": "3",
            "Block": "259",
            "label": "SINDHI MODEL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2251",
            "district": "3",
            "Block": "259",
            "label": "ANNA ADARSH MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2469",
            "district": "3",
            "Block": "259",
            "label": "TAGORE MAT HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2477",
            "district": "3",
            "Block": "259",
            "label": "DANIEL THOMAS MAT HSS KOYAMBEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2509",
            "district": "3",
            "Block": "259",
            "label": "KANCHEEPURAM ARULMIGU EGAMBARANATHAR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2672",
            "district": "3",
            "Block": "259",
            "label": "MARY CLUBWALA JADHAV SPECIAL HIGHER SECONDARY SCHOOL FOR THE HEARING IMPAIRED",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2888",
            "district": "3",
            "Block": "259",
            "label": "ALAGAPPA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24131", "district":"3", 
            "Block":"259", "label": "Al-Qalam Islamic School - Madarasa", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "2899",
            "district": "3",
            "Block": "259",
            "label": "T T A MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3246",
            "district": "3",
            "Block": "259",
            "label": "PADMA SARANGAPANI MATRIC HR.SEC.SCHOOL,VILLIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3264",
            "district": "3",
            "Block": "259",
            "label": "GRACE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3510",
            "district": "3",
            "Block": "259",
            "label": "ICI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3842",
            "district": "3",
            "Block": "259",
            "label": "DAV MAT HSS GILL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3943",
            "district": "3",
            "Block": "259",
            "label": "AMBAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4013",
            "district": "3",
            "Block": "259",
            "label": "CSI BAIN MATRICULATION & HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4715",
            "district": "3",
            "Block": "259",
            "label": "MES RAZEENA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4953",
            "district": "3",
            "Block": "259",
            "label": "PONNAMMAL MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5271",
            "district": "3",
            "Block": "259",
            "label": "DR BMS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5464",
            "district": "3",
            "Block": "259",
            "label": "VALLAL SABAPATHY MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5466",
            "district": "3",
            "Block": "259",
            "label": "SRI VENKATESWARA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5656",
            "district": "3",
            "Block": "259",
            "label": "MARY IMMACULATE MS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5736",
            "district": "3",
            "Block": "259",
            "label": "CSI MS TANA ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6249",
            "district": "3",
            "Block": "259",
            "label": "CMS ARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6264",
            "district": "3",
            "Block": "259",
            "label": "SRI ARANGIAH NAIDU MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6427",
            "district": "3",
            "Block": "259",
            "label": "RAMACHANDRA MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6547",
            "district": "3",
            "Block": "259",
            "label": "C. M. S PULLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6628",
            "district": "3",
            "Block": "259",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6694",
            "district": "3",
            "Block": "259",
            "label": "C.M.S VELLALA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7277",
            "district": "3",
            "Block": "259",
            "label": "I.C.F. HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7278",
            "district": "3",
            "Block": "259",
            "label": "W. P. A. SOUNDARAPANDIAN HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7279",
            "district": "3",
            "Block": "259",
            "label": "NIRMAL HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7280",
            "district": "3",
            "Block": "259",
            "label": "SRI ARANGIAH NAIDU HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7281",
            "district": "3",
            "Block": "259",
            "label": "THE TARAPORE AND LOGANATHAN HR. SEC. SCHOOL FOR GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7282",
            "district": "3",
            "Block": "259",
            "label": "CSI JESSIE MOSES MAT. HR. SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7283",
            "district": "3",
            "Block": "259",
            "label": "CHINMAYA VIDYALAYA ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7284",
            "district": "3",
            "Block": "259",
            "label": "KENDRIYA VIDYALAYA ANNA NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7285",
            "district": "3",
            "Block": "259",
            "label": "JAIVEL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7286",
            "district": "3",
            "Block": "259",
            "label": "SBOA SCHOOL & JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7287",
            "district": "3",
            "Block": "259",
            "label": "Shri Krishnaswamy Matric Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7288",
            "district": "3",
            "Block": "259",
            "label": "CHSS KOYAMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7289",
            "district": "3",
            "Block": "259",
            "label": "CHSS VIRUGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7290",
            "district": "3",
            "Block": "259",
            "label": "AV MEIYAPPAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7291",
            "district": "3",
            "Block": "259",
            "label": "CHINMAYA VIDYALAYA SR.SEC.SCHOOL ,VIRUGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7292",
            "district": "3",
            "Block": "259",
            "label": "Smt. M.J.V HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7293",
            "district": "3",
            "Block": "259",
            "label": "CHINMAYA VIDYALAYA HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7294",
            "district": "3",
            "Block": "259",
            "label": "Shri Krishnaswamy Matric Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7295",
            "district": "3",
            "Block": "259",
            "label": "THIRU VI KA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7296",
            "district": "3",
            "Block": "259",
            "label": "C.M.S GUJJI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7297",
            "district": "3",
            "Block": "259",
            "label": "CHENNAI GIRLS HIGHER SECONDARY SCHOOL PULLA AVENUE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7298",
            "district": "3",
            "Block": "259",
            "label": "CMS AMINJIKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7299",
            "district": "3",
            "Block": "259",
            "label": "RAJESWARI MAT HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7300",
            "district": "3",
            "Block": "259",
            "label": "CHENNAI HIGH SCHOOL KILPAUK",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7301",
            "district": "3",
            "Block": "259",
            "label": "KOLA SARASWATHI VAISHNAV SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7302",
            "district": "3",
            "Block": "259",
            "label": "KASTHURIBAI GANDHI HIGH SCHOOL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7303",
            "district": "3",
            "Block": "259",
            "label": "THE SECRETARIAT COLONY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7304",
            "district": "3",
            "Block": "259",
            "label": "B.V.BHAVAN'S RAJAJI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7305",
            "district": "3",
            "Block": "259",
            "label": "CHINMAYA VIDYALAYA KILPAUK",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7306",
            "district": "3",
            "Block": "259",
            "label": "SINDHI MODEL SR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7307",
            "district": "3",
            "Block": "259",
            "label": "CHENNAI BOYS HIGHER SECONDARY SCHOOL G.KOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7308",
            "district": "3",
            "Block": "259",
            "label": "BALAR KALVI NILAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "7309",
            "district": "3",
            "Block": "259",
            "label": "CSI MS PERUMALPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7310",
            "district": "3",
            "Block": "259",
            "label": "CSI MS SUNDRAM ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7311",
            "district": "3",
            "Block": "259",
            "label": "ST. PAULS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7312",
            "district": "3",
            "Block": "259",
            "label": "SIR MCTM HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7313",
            "district": "3",
            "Block": "259",
            "label": "LADY M.CT.M GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7314",
            "district": "3",
            "Block": "259",
            "label": "E.L.M.FABRICIUS HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7315",
            "district": "3",
            "Block": "259",
            "label": "TELC MAGDALENE GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7316",
            "district": "3",
            "Block": "259",
            "label": "SHRI B.K.KOTHARI MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7317",
            "district": "3",
            "Block": "259",
            "label": "CHSS  SUBBARAYAN ST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7318",
            "district": "3",
            "Block": "259",
            "label": "KERALA VIDYALAYAM HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7319",
            "district": "3",
            "Block": "259",
            "label": "ST GEORGE'S AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7320",
            "district": "3",
            "Block": "259",
            "label": "SHERWOOD HALL SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7321",
            "district": "3",
            "Block": "259",
            "label": "CHENNAI HIGH SCHOOL MCNICHOLS ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7322",
            "district": "3",
            "Block": "259",
            "label": "CHS MMDA II",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7323",
            "district": "3",
            "Block": "259",
            "label": "KOLA PERUMAL CHETTY VAISHNAV SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7324",
            "district": "3",
            "Block": "259",
            "label": "DANIEL THOMAS GIRLS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7325",
            "district": "3",
            "Block": "259",
            "label": "CMS MMDA I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7326",
            "district": "3",
            "Block": "259",
            "label": "CHS THIRUVALLUVARPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7327",
            "district": "3",
            "Block": "259",
            "label": "CHSS CHOOLAIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7328",
            "district": "3",
            "Block": "259",
            "label": "L M DHADHA SR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7329",
            "district": "3",
            "Block": "259",
            "label": "KENDRIYA VIDYALAYA GILL NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7330",
            "district": "3",
            "Block": "259",
            "label": "CMS APPASAMI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7331",
            "district": "3",
            "Block": "259",
            "label": "CMS KUTTY ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7332",
            "district": "3",
            "Block": "259",
            "label": "MALAYALA VIDYALAYAM HS",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7333",
            "district": "3",
            "Block": "259",
            "label": "ARPUTHARAJ MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21490",
            "district": "3",
            "Block": "259",
            "label": "ADW HIGH SCHOOL THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21491",
            "district": "3",
            "Block": "259",
            "label": "ADW VIRUGAMBAKKAM HIGH SCHOOL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22010",
            "district": "3",
            "Block": "259",
            "label": "SBOA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22011",
            "district": "3",
            "Block": "259",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22335",
            "district": "3",
            "Block": "259",
            "label": "MAHALASHMI VIDHYA MANDHIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22962",
            "district": "3",
            "Block": "259",
            "label": "CSI EWART GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22970",
            "district": "3",
            "Block": "259",
            "label": "C.S.I. BAIN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23264",
            "district": "3",
            "Block": "259",
            "label": "ST CHRISTOPHER'S ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "260": [
        {
            "key": "398",
            "district": "33",
            "Block": "260",
            "label": "NEHRU MATRIC, HSC, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "413",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MANJAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "415",
            "district": "33",
            "Block": "260",
            "label": "DR SIVANTHI ADITANAR MATRIC.HR.SEC.SCHOOL,  PUZHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "438",
            "district": "33",
            "Block": "260",
            "label": "SKP VIV VID, MADHAVARAM WARD 3",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "473",
            "district": "33",
            "Block": "260",
            "label": "NAVA JYOTHI  VIDYALAYA MATRIC.HR.SEC.SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "483",
            "district": "33",
            "Block": "260",
            "label": "ST. Thomas Silver Jubilee Matriculation school. Mathur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "505",
            "district": "33",
            "Block": "260",
            "label": "Don Bosco Matriculation Higher secondary school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "525",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALINJIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "533",
            "district": "33",
            "Block": "260",
            "label": "ST JOSEPH MATRIC SCHOOL, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "560",
            "district": "33",
            "Block": "260",
            "label": "PUMS, SADAYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "571",
            "district": "33",
            "Block": "260",
            "label": "ST THOMAS MAT HSS, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "574",
            "district": "33",
            "Block": "260",
            "label": "ST. THOMAS MATRIC HSS, CHINNA SEKKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "686",
            "district": "33",
            "Block": "260",
            "label": "AUXILIUM MATRICULATION SCHOOL, ,THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "799",
            "district": "33",
            "Block": "260",
            "label": "KAVI BHARATHI VID, THIRUVOTRIYUR 40",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "857",
            "district": "33",
            "Block": "260",
            "label": "MALLIKARJUNA MATRICS, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "888",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUZHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "913",
            "district": "33",
            "Block": "260",
            "label": "REVOOR PADMANABHA CHETTY'S MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "917",
            "district": "33",
            "Block": "260",
            "label": "MUNICIPAL MIDDLE SCHOOL,  ALL INDIA RADIO NAGAR, ENNORE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "969",
            "district": "33",
            "Block": "260",
            "label": "VEERA SAVARKAR NETHAJI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "971",
            "district": "33",
            "Block": "260",
            "label": "ILAHI MATRICULATION HIGH SCHOOL,REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1005",
            "district": "33",
            "Block": "260",
            "label": "VICTORY MATRIC, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1139",
            "district": "33",
            "Block": "260",
            "label": "ST JOHN'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1143",
            "district": "33",
            "Block": "260",
            "label": "MUN.MS,TSM NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1152",
            "district": "33",
            "Block": "260",
            "label": "St.JOSEPH'S ACADEMY MATRICULATION HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1199",
            "district": "33",
            "Block": "260",
            "label": "GODSON MATRICULATION HSS , SURAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1242",
            "district": "33",
            "Block": "260",
            "label": "MUN. MIDDLE SCHOOL, KATHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1299",
            "district": "33",
            "Block": "260",
            "label": "PADMA SEENIVASAN MEMORIAL VIDYALAYA MATRICULATION HIGH SCHOOL, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1309",
            "district": "33",
            "Block": "260",
            "label": "HOLY CHILD MATRIC HR SEC SCHOOL,REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1484",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SURAPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23679",
            "district": "33",
            "Block": "260",
            "label": "KBC Government Girls Hr Sec School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1504",
            "district": "33",
            "Block": "260",
            "label": "ST.ANTONY'S MATRIC HSS, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1505",
            "district": "33",
            "Block": "260",
            "label": "SARATHI SARASWATHI MHSS, MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1534",
            "district": "33",
            "Block": "260",
            "label": "MANIYAMMAI MATRIC SCHOOL, P.MEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1600",
            "district": "33",
            "Block": "260",
            "label": "JAIN VIDYAASHRAM, PUZHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1603",
            "district": "33",
            "Block": "260",
            "label": "BOSCO ACADEMY MAT HSS, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1647",
            "district": "33",
            "Block": "260",
            "label": "KATHIVAKKAMMUNICIPAL MIDDLESCHOOL NETTUKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1714",
            "district": "33",
            "Block": "260",
            "label": "MUNICIPAL MIDDLE SCHOOL,RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1725",
            "district": "33",
            "Block": "260",
            "label": "SR PARTHASARATHY N & P, WARD 6 KATHIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1746",
            "district": "33",
            "Block": "260",
            "label": "VOC MATRIC HIGHER SECONDARY SCHOOL ENNORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1753",
            "district": "33",
            "Block": "260",
            "label": "SAGAYA MATHA MATRIC, R.K.NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1781",
            "district": "33",
            "Block": "260",
            "label": "INFANT JESUS MATRIC HR.SEC SCHOOL,LAKSHMIPURAM,KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1913",
            "district": "33",
            "Block": "260",
            "label": "SRF VIDYALAYA MATRICULTION SCHOOL, MANALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2083",
            "district": "33",
            "Block": "260",
            "label": "REDEEMER MATRIC, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2115",
            "district": "33",
            "Block": "260",
            "label": "HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2150",
            "district": "33",
            "Block": "260",
            "label": "SRI SATHYA SAI VIDYALA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2181",
            "district": "33",
            "Block": "260",
            "label": "VELAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, SURAPET , CHENNAI -600066",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2312",
            "district": "33",
            "Block": "260",
            "label": "THE BELOVED MATRICULATION HIGHER SECONDARY SCHOOL , MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2771",
            "district": "33",
            "Block": "260",
            "label": "PUMS, ANDARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2775",
            "district": "33",
            "Block": "260",
            "label": "DHARAM HINDUJA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2809",
            "district": "33",
            "Block": "260",
            "label": "VENKATESWARA MATRIC, ERNAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2810",
            "district": "33",
            "Block": "260",
            "label": "MUN MIDDLE SCHOOL, NEHRU NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2920",
            "district": "33",
            "Block": "260",
            "label": "ST. PAUL'S MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2939",
            "district": "33",
            "Block": "260",
            "label": "ST THOMAS MS, SATHUMA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3089",
            "district": "33",
            "Block": "260",
            "label": "DEIVA SIGAMANI MIDDLE SCHOOL, , KALADIPET, THIRUVOTTIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3149",
            "district": "33",
            "Block": "260",
            "label": "MUNICIPAL. MIDDLE SCHOOL THIRUVOTRIYUR WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3256",
            "district": "33",
            "Block": "260",
            "label": "KINGS MATRIC SCHOOL, LAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3326",
            "district": "33",
            "Block": "260",
            "label": "SRI SAKTHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3482",
            "district": "33",
            "Block": "260",
            "label": "ST. JOSEPH MAT. HSS, ENNORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3528",
            "district": "33",
            "Block": "260",
            "label": "THE WESLEYS MS, KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3695",
            "district": "33",
            "Block": "260",
            "label": "ADW MS, VILANGADUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4303",
            "district": "33",
            "Block": "260",
            "label": "SRI RAMDAYAL KHEMKA VV, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4314",
            "district": "33",
            "Block": "260",
            "label": "RUKMANI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4355",
            "district": "33",
            "Block": "260",
            "label": "PNECT KAMARAJ MATRIC SCHOOL, MADHAVARAM WARD 22",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4387",
            "district": "33",
            "Block": "260",
            "label": "VELAMMAL VIDHYASHRAM, SURAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4424",
            "district": "33",
            "Block": "260",
            "label": "ST ANNS MAT HSS, MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4534",
            "district": "33",
            "Block": "260",
            "label": "S.S.S.M ETPS MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4559",
            "district": "33",
            "Block": "260",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL , MURUGAMPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4566",
            "district": "33",
            "Block": "260",
            "label": "MUN. MIDDLE SCHOOL, MILK COLONY, MADHAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5022",
            "district": "33",
            "Block": "260",
            "label": "AYISHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5414",
            "district": "33",
            "Block": "260",
            "label": "RAGHUPATHY RAJA MS, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5478",
            "district": "33",
            "Block": "260",
            "label": "SRI K.C.TOSHNIWAL VIVEKANANDA VIDYALAYA, PERIYA MATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5964",
            "district": "33",
            "Block": "260",
            "label": "PUMS, SANMUGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6002",
            "district": "33",
            "Block": "260",
            "label": "NATIONAL LOTUS MATRIC HR.SEC SCHOOL,REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6348",
            "district": "33",
            "Block": "260",
            "label": "SRI SANKARA VIDYA KENDRA MATRIC HR.SEC.SCHOOL THIRUVOTTIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6996",
            "district": "33",
            "Block": "260",
            "label": "GOVERNMENT HIGH SCHOOL, KATHIRVEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6997",
            "district": "33",
            "Block": "260",
            "label": "GHS, MATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6998",
            "district": "33",
            "Block": "260",
            "label": "IMMANUVEL MS, SURAPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6999",
            "district": "33",
            "Block": "260",
            "label": "PUMS, KOSAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7000",
            "district": "33",
            "Block": "260",
            "label": "ADW (B) HSS, VADAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7001",
            "district": "33",
            "Block": "260",
            "label": "ADW (G) HSS, VADAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7002",
            "district": "33",
            "Block": "260",
            "label": "GOVT (B) HSS, NARAVARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7003",
            "district": "33",
            "Block": "260",
            "label": "GOVT (G) HSS, NARAVARIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7004",
            "district": "33",
            "Block": "260",
            "label": "GOVT HSS, MANALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7005",
            "district": "33",
            "Block": "260",
            "label": "Dr. SIVANTHI ADITHANAR GIRLS HIGHER SECONDARY SCHOOL, MANALI , CHENNAI- 68",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7006",
            "district": "33",
            "Block": "260",
            "label": "J.G.CARODIA GOVT HSS, MADHAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7007",
            "district": "33",
            "Block": "260",
            "label": "ST.ANNE'S GIRLS HR.SEC.SCHOOL,MADHAVARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7008",
            "district": "33",
            "Block": "260",
            "label": "MUN HSS, LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7009",
            "district": "33",
            "Block": "260",
            "label": "POPPILIRAJA GOVT HSS, PUZHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7010",
            "district": "33",
            "Block": "260",
            "label": "ST. ANTONY'S MATRIC HIGHER SECONDARY SCHOOL, PUZHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7011",
            "district": "33",
            "Block": "260",
            "label": "GOVT HSS, KATHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7012",
            "district": "33",
            "Block": "260",
            "label": "GOVT HIGH SCHOOL, A.S.NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7013",
            "district": "33",
            "Block": "260",
            "label": "SAKAYA MATHA HS, A.S.NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7014",
            "district": "33",
            "Block": "260",
            "label": "JAI GOPAL CARODIA HSS THIRUVOTRIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7015",
            "district": "33",
            "Block": "260",
            "label": "THIRUTHANGAL NADAR VID, THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7016",
            "district": "33",
            "Block": "260",
            "label": "SRI MAHAVEER JAIN HIGHER SECONDARY SCHOOL , THIRUVOTRIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7017",
            "district": "33",
            "Block": "260",
            "label": "VELLAIYAN CHETTIAR HSS, THIRUVOTRIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7018",
            "district": "33",
            "Block": "260",
            "label": "SRI V RAMAKRISHNA HSS, THIRUVOTRIYUR WARD 17",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7019",
            "district": "33",
            "Block": "260",
            "label": "OUR LADY HSS, THIRUVOTRIYUR WARD 22",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7020",
            "district": "33",
            "Block": "260",
            "label": "MUN HIGH SCHOOL, KALADIPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7021",
            "district": "33",
            "Block": "260",
            "label": "VENUS HIGH SCHOOL , THIRUVOTRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7022",
            "district": "33",
            "Block": "260",
            "label": "ST PAULS MAHAJ. HSS, KALADIPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7023",
            "district": "33",
            "Block": "260",
            "label": "RAMAKRISHNA MATRIC, S, SATHANK",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7024",
            "district": "33",
            "Block": "260",
            "label": "GOVT GIRLS HIGH SCHOOL, SATHANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7025",
            "district": "33",
            "Block": "260",
            "label": "FATHER JESUS, THIRUVOTRIYUR WARD 44",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7026",
            "district": "33",
            "Block": "260",
            "label": "SMT MOHINI SARAOGI VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7027",
            "district": "33",
            "Block": "260",
            "label": "MHS, ERNAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20898",
            "district": "33",
            "Block": "260",
            "label": "ST.CHARLES MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21295",
            "district": "33",
            "Block": "260",
            "label": "GODSON PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21296",
            "district": "33",
            "Block": "260",
            "label": "VRUKSHA VIDYAASHRAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21578",
            "district": "33",
            "Block": "260",
            "label": "KUMARAN CWSN\tSchool",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21579",
            "district": "33",
            "Block": "260",
            "label": "ANBALAYA CWSN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22001",
            "district": "33",
            "Block": "260",
            "label": "ST.ANNES MAT SCH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22002",
            "district": "33",
            "Block": "260",
            "label": "GREENFIELD CHENNAI INTERNATIONAL SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22003",
            "district": "33",
            "Block": "260",
            "label": "VELAMMAL GLOBAL INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22327",
            "district": "33",
            "Block": "260",
            "label": "EVERWIN VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22328",
            "district": "33",
            "Block": "260",
            "label": "GHS, CHINNA SEKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22329",
            "district": "33",
            "Block": "260",
            "label": "PADMASHREE  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22330",
            "district": "33",
            "Block": "260",
            "label": "GHS, KANNAPPASAMY NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22722",
            "district": "33",
            "Block": "260",
            "label": "VELLAMMAL NEW GEN SCHOOL , SURAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22723",
            "district": "33",
            "Block": "260",
            "label": "VELLAMMAL NEW GEN SCHOOL MADHAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22724",
            "district": "33",
            "Block": "260",
            "label": "SRI SATHYA SAI VIDYA VIHAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23079",
            "district": "33",
            "Block": "260",
            "label": "ACHARYA MAHASHRAMAN TERAPANTH JAIN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23117",
            "district": "33",
            "Block": "260",
            "label": "SRI CHAITANYA TECHNO SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23148",
            "district": "33",
            "Block": "260",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23191",
            "district": "33",
            "Block": "260",
            "label": "SRI VENKATESWARA PUBLIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23309",
            "district": "33",
            "Block": "260",
            "label": "VELAMMAL NEW - GEN SCHOOL , THIRUVOTTIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23334",
            "district": "33",
            "Block": "260",
            "label": "OUR LADY SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23603",
            "district": "33",
            "Block": "260",
            "label": "Olive Tree Global School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23604",
            "district": "33",
            "Block": "260",
            "label": "PRIM BUDS GARDEN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23603",
            "district": "33",
            "Block": "260",
            "label": "Olive Tree Global School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23604",
            "district": "33",
            "Block": "260",
            "label": "PRIM BUDS GARDEN",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "261": [
        {
            "key": "403",
            "district": "33",
            "Block": "261",
            "label": "SARASWATHI MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "482",
            "district": "33",
            "Block": "261",
            "label": "TMKV GOVT BOYS  HIGHER SECONDARY SCHOOL, ,AMMAYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "488",
            "district": "33",
            "Block": "261",
            "label": "PUMS,KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "513",
            "district": "33",
            "Block": "261",
            "label": "PUMS,NEELOTH BALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "616",
            "district": "33",
            "Block": "261",
            "label": "AYYAN VID,VELLATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "652",
            "district": "33",
            "Block": "261",
            "label": "VETHATHIRI MAHARISHI HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "937",
            "district": "33",
            "Block": "261",
            "label": "PUMS,CHANDRAVILASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "985",
            "district": "33",
            "Block": "261",
            "label": "PUMS,CHELLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1101",
            "district": "33",
            "Block": "261",
            "label": "PUMS,GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1195",
            "district": "33",
            "Block": "261",
            "label": "PUMS,RAJANAGARAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1253",
            "district": "33",
            "Block": "261",
            "label": "ANM MS,AMMAIYARKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1338",
            "district": "33",
            "Block": "261",
            "label": "PUMS,C G N KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1492",
            "district": "33",
            "Block": "261",
            "label": "PUMS,R N KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1579",
            "district": "33",
            "Block": "261",
            "label": "PUMS,KRISHNAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1698",
            "district": "33",
            "Block": "261",
            "label": "PUMS,DEVALAMBAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1703",
            "district": "33",
            "Block": "261",
            "label": "PUMS,S B KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1734",
            "district": "33",
            "Block": "261",
            "label": "PUMS,AMMANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2468",
            "district": "33",
            "Block": "261",
            "label": "PUMS,SAHASRAPADMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4597",
            "district": "33",
            "Block": "261",
            "label": "PUMS,CHETTIVARIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7070",
            "district": "33",
            "Block": "261",
            "label": "A. M. K GOVT (G) HSS,AMMAYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7071",
            "district": "33",
            "Block": "261",
            "label": "GHS, KONDAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7072",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS ASWARANTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7073",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS, AYYANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7074",
            "district": "33",
            "Block": "261",
            "label": "GOVTHSS, BALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7075",
            "district": "33",
            "Block": "261",
            "label": "GHS,CHANNURMALLAVARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7076",
            "district": "33",
            "Block": "261",
            "label": "SIVARANJINI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7077",
            "district": "33",
            "Block": "261",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,SENGATANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7078",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS,PERIYANAGAPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7079",
            "district": "33",
            "Block": "261",
            "label": "GOVT (G) HSS, R.K.PET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7080",
            "district": "33",
            "Block": "261",
            "label": "GOVT (B) HSS, R.K.PET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7081",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS,RAJANAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7082",
            "district": "33",
            "Block": "261",
            "label": "GOVT HSS,SRIKALIKAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7083",
            "district": "33",
            "Block": "261",
            "label": "GOVT HSS,VANGANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7084",
            "district": "33",
            "Block": "261",
            "label": "GOVT HSS,VEDIYANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7085",
            "district": "33",
            "Block": "261",
            "label": "GOVT HS,VEERAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21424",
            "district": "33",
            "Block": "261",
            "label": "Govt Girls High school,Vanganoor",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21696",
            "district": "33",
            "Block": "261",
            "label": "SRI AKSHAYA VIDYALAYA MATRICULATION SCHOOL , AMMAYARKUPPAM ROAD , RKPET , THIRUVALLUR DISTRICT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21697",
            "district": "33",
            "Block": "261",
            "label": "BILLABONG HIGH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22725",
            "district": "33",
            "Block": "261",
            "label": "SRI ARUTSAI VETRI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22899",
            "district": "33",
            "Block": "261",
            "label": "GHS, ADHIVARAGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23605",
            "district": "33",
            "Block": "261",
            "label": "MY DREAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23653",
            "district": "33",
            "Block": "261",
            "label": "Saraswathi Vidyalaya Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23606",
            "district": "33",
            "Block": "261",
            "label": "SRI VAARIYAR VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23605",
            "district": "33",
            "Block": "261",
            "label": "MY DREAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23606",
            "district": "33",
            "Block": "261",
            "label": "SRI VAARIYAR VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "262": [
        {
            "key": "1736",
            "district": "30",
            "Block": "262",
            "label": "HAVARD HI-TECH MATRIC HIGHER SECONERY  SCHOOL ULAGARATCHAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1935",
            "district": "30",
            "Block": "262",
            "label": "DANIEL THOMAS MATRIC HSS TISAIYANVILLAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23646",
            "district": "30",
            "Block": "262",
            "label": "Atomic Energy Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4698",
            "district": "30",
            "Block": "262",
            "label": "SACRED HEART MIDDLE SCHOOL T.KALLIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4799",
            "district": "30",
            "Block": "262",
            "label": "HEBRON MATRIC HSS .KOODANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4983",
            "district": "30",
            "Block": "262",
            "label": "KAMARAJ MIDDLE SCHOOL T.KALLIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5681",
            "district": "30",
            "Block": "262",
            "label": "ST.ALOYSIUS MS SOUNDARAPANDIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19845",
            "district": "30",
            "Block": "262",
            "label": "ST.ALOYSIUS MIDDLE SCHOOL T.KALLIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19846",
            "district": "30",
            "Block": "262",
            "label": "ST. ALOYSIUS HSS T.KALLIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19847",
            "district": "30",
            "Block": "262",
            "label": "ST.PETER'S MS RAYAPPAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19848",
            "district": "30",
            "Block": "262",
            "label": "SEEMATTI AMMAL MUTHAMMAL REDDIYAR GOVT HR.SECONDARY SCHOOL SAMUGARENGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19849",
            "district": "30",
            "Block": "262",
            "label": "ST.JOSEPH HS PUTHUKUDIYIRUPPU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19850",
            "district": "30",
            "Block": "262",
            "label": "TDTA MS RADHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19851",
            "district": "30",
            "Block": "262",
            "label": "RC PERIYA NAYAGI MIDDLE SCHOOL RADHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19852",
            "district": "30",
            "Block": "262",
            "label": "NVC GOVT HR.SECONDARY SCHOOL RADHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19853",
            "district": "30",
            "Block": "262",
            "label": "ST ANDREWS MATRICULATION HIGHER SECONDARY SCHOOL RADHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19854",
            "district": "30",
            "Block": "262",
            "label": "RAMALINGAM KUMARASWAMY RAMALINGAM MIDDLE SCHOOL,PARAMESHWARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19855",
            "district": "30",
            "Block": "262",
            "label": "ANANDHA MIDDLE SCHOOL UDHAYATHOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19856",
            "district": "30",
            "Block": "262",
            "label": "GHS ATHUKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19857",
            "district": "30",
            "Block": "262",
            "label": "HINDU  MIDDLE SCHOOL  KOODANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19858",
            "district": "30",
            "Block": "262",
            "label": "ST.ANNE'S HIGHER SECOONDARY SCHOOL KOODANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19859",
            "district": "30",
            "Block": "262",
            "label": "MALAVIYA VIDYA KENDRAM KOODANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19860",
            "district": "30",
            "Block": "262",
            "label": "TDTA HS KOODANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19861",
            "district": "30",
            "Block": "262",
            "label": "GHSS KOODANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19862",
            "district": "30",
            "Block": "262",
            "label": "PUMS VIJAYAPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19863",
            "district": "30",
            "Block": "262",
            "label": "BISHOP ROCHE  HSS IDINDAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19864",
            "district": "30",
            "Block": "262",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SANGANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19865",
            "district": "30",
            "Block": "262",
            "label": "BHARATH MIDDLE SCHOOL VAIRAVIKINARU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19866",
            "district": "30",
            "Block": "262",
            "label": "MUSLIM MS THIRUVAMBALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19867",
            "district": "30",
            "Block": "262",
            "label": "HINDU YADAVA MIDDLE SCHOOL TIRUVAMBALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19868",
            "district": "30",
            "Block": "262",
            "label": "ST.KITHERI HIGH SCHOOL  KOOTHENKUZHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19869",
            "district": "30",
            "Block": "262",
            "label": "PUBLIC SEVAA SANGAM MIDDLE SCHOOL  MUDAVANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19870",
            "district": "30",
            "Block": "262",
            "label": "GHS KASTHURIRENGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19871",
            "district": "30",
            "Block": "262",
            "label": "JOHNY MIDDLE SCHOOL  KUMBIKULAM NORTH",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19872",
            "district": "30",
            "Block": "262",
            "label": "GHS SEELATHIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19873",
            "district": "30",
            "Block": "262",
            "label": "RC MS MITTATHARKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19874",
            "district": "30",
            "Block": "262",
            "label": "SSMM MS KOTTAIKARUNKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19875",
            "district": "30",
            "Block": "262",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUMARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19876",
            "district": "30",
            "Block": "262",
            "label": "TDTA MIDDLE SCHOOL IDAIYANKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19877",
            "district": "30",
            "Block": "262",
            "label": "TDTA MS KEERAIKARANTHATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19878",
            "district": "30",
            "Block": "262",
            "label": "TDTA MS  SUVISESHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19879",
            "district": "30",
            "Block": "262",
            "label": "STELLA MARIS GIRLS HIGH SCHOOL, TISAIYANVILAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19880",
            "district": "30",
            "Block": "262",
            "label": "SAMARIAH ST. JOHN'S HR. SEC. SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19881",
            "district": "30",
            "Block": "262",
            "label": "HOLY REDEEMERS HSS TISAYANVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19882",
            "district": "30",
            "Block": "262",
            "label": "SRI RAMAKRISHNA HSS TISAYANVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19883",
            "district": "30",
            "Block": "262",
            "label": "CALDWELL CENTINARY MEMORIAL HSS EDAYANKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19884",
            "district": "30",
            "Block": "262",
            "label": "JAYARRAJESH MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19885",
            "district": "30",
            "Block": "262",
            "label": "GHS SHANMUGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19886",
            "district": "30",
            "Block": "262",
            "label": "TDTA MS MADATHACHAMPADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19887",
            "district": "30",
            "Block": "262",
            "label": "MALAVIYA VIDYA KENDRAM APPUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19888",
            "district": "30",
            "Block": "262",
            "label": "LIONS MATRICULATION HIGHER SECONDARY SCHOOL APPUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19889",
            "district": "30",
            "Block": "262",
            "label": "RC MS ANAIKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19890",
            "district": "30",
            "Block": "262",
            "label": "S M CADER MEERA SAIBO HSS PETTAIKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19891",
            "district": "30",
            "Block": "262",
            "label": "TDTA MIDDLE SCHOOL KARAICHUTHUPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19892",
            "district": "30",
            "Block": "262",
            "label": "TDTA MIDDLE SCHOOL NAVALADY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19893",
            "district": "30",
            "Block": "262",
            "label": "ST XAVIER'S MS AVUDAIYALPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19894",
            "district": "30",
            "Block": "262",
            "label": "DAKSHINA  MARA NADAR SANGAM SIVANTHI ADITHANAR HIGHER SECONDARY SCHOOL,NAVALADY NAVALADY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19895",
            "district": "30",
            "Block": "262",
            "label": "PUMS KUNJANVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19896",
            "district": "30",
            "Block": "262",
            "label": "ST JOSEPH MS UVARI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19897",
            "district": "30",
            "Block": "262",
            "label": "SRI SUYAMBULINGASAMY MIDDLE SCHOOL  UVARI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19898",
            "district": "30",
            "Block": "262",
            "label": "ST ANTONY'S MIDDLE SCHOOL  KOOTAPPANAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19899",
            "district": "30",
            "Block": "262",
            "label": "GHSS KUTTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19900",
            "district": "30",
            "Block": "262",
            "label": "ST MARY'S HSS UVARI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19901",
            "district": "30",
            "Block": "262",
            "label": "MALAVIYA VIDYA KENDRAM UVARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21830",
            "district": "30",
            "Block": "262",
            "label": "VSR INTERNATIONAL SCHOOL, TISAYANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21831",
            "district": "30",
            "Block": "262",
            "label": "POTHIHAI PUBLIC MATRIC HIGHER SECONDARY SCHOOL, IDAYANKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21832",
            "district": "30",
            "Block": "262",
            "label": "SRI GOLD STAR MATRIC HIGHER SECONDARY SCHOOL,TISAIYANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22653",
            "district": "30",
            "Block": "262",
            "label": "OUR LADY OF SNOWS MATRIC HR. SEC. SCHOOL T.KALLIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23114",
            "district": "30",
            "Block": "262",
            "label": "HARVARD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23260",
            "district": "30",
            "Block": "262",
            "label": "ARPUTHAM SAMUEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "263": [
        {
            "key": "842",
            "district": "11",
            "Block": "263",
            "label": "HOLY MOTHER MATRIC SCHOOL, PARVATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "853",
            "district": "11",
            "Block": "263",
            "label": "EVANS MATRIC HR. SEC. SCHOOL, NGO COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1181",
            "district": "11",
            "Block": "263",
            "label": "SRI RAMJI MATRIC HSS, GANAPATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1366",
            "district": "11",
            "Block": "263",
            "label": "BELFIELD MATRIC HSS, ASARIPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1731",
            "district": "11",
            "Block": "263",
            "label": "ST.MARY'S MS PILLAITHOPPU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "1837",
            "district": "11",
            "Block": "263",
            "label": "SACCER MATRICULATION SCHOOL, EAST PULLUVILAI, ERUMBUKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1882",
            "district": "11",
            "Block": "263",
            "label": "CMC MATRIC HR. SEC. SCHOOL-THAMMATHUKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1901",
            "district": "11",
            "Block": "263",
            "label": "LMS MIDDLE SCHOOL EATHAMOZHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2006",
            "district": "11",
            "Block": "263",
            "label": "OUR LADY OF MIRACLE BETHANY MATRIC HIGHER SECONDARY SCHOOL, ANNAI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2118",
            "district": "11",
            "Block": "263",
            "label": "GMS THAMMATHUKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2217",
            "district": "11",
            "Block": "263",
            "label": "SWAMY VIVEKANANDA MATRICULATION SCHOOL ATHIKATTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2339",
            "district": "11",
            "Block": "263",
            "label": "ST. ANTONY'S MS CHENBAGARAMANPUTHENTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2422",
            "district": "11",
            "Block": "263",
            "label": "GMS CHOTHAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2431",
            "district": "11",
            "Block": "263",
            "label": "GMS PILLAYARVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2437",
            "district": "11",
            "Block": "263",
            "label": "GMS KATTADITHATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2501",
            "district": "11",
            "Block": "263",
            "label": "GMS KIDANGANKARAIVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2554",
            "district": "11",
            "Block": "263",
            "label": "ISED MATRIC HSS PARAKKAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2567",
            "district": "11",
            "Block": "263",
            "label": "ST. MARY'S MIDDLE SCHOOL POZHIKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2596",
            "district": "11",
            "Block": "263",
            "label": "GMS ASARIPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2664",
            "district": "11",
            "Block": "263",
            "label": "ST. JUDE'S MIDDLE SCHOOL MELA ASRIPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2796",
            "district": "11",
            "Block": "263",
            "label": "ST. MARY'S HS KESAVANPUTHENTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "3029",
            "district": "11",
            "Block": "263",
            "label": "GMS MELAKRISHNANPUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3062",
            "district": "11",
            "Block": "263",
            "label": "GMS ERUMPUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3118",
            "district": "11",
            "Block": "263",
            "label": "GMS PARAKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3718",
            "district": "11",
            "Block": "263",
            "label": "HOLY INFANT JESUS MIDDLE SCHOOL, PUTHENTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5176",
            "district": "11",
            "Block": "263",
            "label": "ST.FRANCIS MATRIC.HR.SEC.SCHOOL,VELLALANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6290",
            "district": "11",
            "Block": "263",
            "label": "GNANA VIDYA MANDIR, KEELAKATTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6422",
            "district": "11",
            "Block": "263",
            "label": "ST JOSEPH HS RAJAKMANGALAMTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "6740",
            "district": "11",
            "Block": "263",
            "label": "BHARATH ADVANCED MATRICULATION HSS, KOIYANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20166",
            "district": "11",
            "Block": "263",
            "label": "GHS PARAKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20167",
            "district": "11",
            "Block": "263",
            "label": "GHSS THENGAMPUTHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20168",
            "district": "11",
            "Block": "263",
            "label": "LITTLE FLOWER HS-MANAKUDY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20169",
            "district": "11",
            "Block": "263",
            "label": "LMPC HIGHER SECONDARY SCHOOL, PUTHALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20170",
            "district": "11",
            "Block": "263",
            "label": "KANNAN MATRIC HIGHER SECONDARY SCHOOL, THENGAMPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20171",
            "district": "11",
            "Block": "263",
            "label": "BELIEF CENTRAL SCHOOL-THENGAMPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20172",
            "district": "11",
            "Block": "263",
            "label": "GHS-PUTHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20173",
            "district": "11",
            "Block": "263",
            "label": "GHSS,SOORANKUDY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20174",
            "district": "11",
            "Block": "263",
            "label": "GHSS-SUNDAPATTIVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20175",
            "district": "11",
            "Block": "263",
            "label": "LMS HSS ZIONPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20176",
            "district": "11",
            "Block": "263",
            "label": "GHS PARVATHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20177",
            "district": "11",
            "Block": "263",
            "label": "GHS PUTHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20178",
            "district": "11",
            "Block": "263",
            "label": "GHSS PERUVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20179",
            "district": "11",
            "Block": "263",
            "label": "ST.JOSEPH's HR.SEC. SCHOOL, ASARIPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20180",
            "district": "11",
            "Block": "263",
            "label": "SHANTHI NILAYAM SPECIAL SCHOOL-PARUTHIVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20181",
            "district": "11",
            "Block": "263",
            "label": "GHSS EATHAMOZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20182",
            "district": "11",
            "Block": "263",
            "label": "GHSS-RAJAKKAMANAGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20183",
            "district": "11",
            "Block": "263",
            "label": "AVD HSS GANAPATHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20184",
            "district": "11",
            "Block": "263",
            "label": "GHS ALANKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20185",
            "district": "11",
            "Block": "263",
            "label": "GHSS MONIKETTIPOTTAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20186",
            "district": "11",
            "Block": "263",
            "label": "St. Jude Thaddaeus Higher Secondary School, Pallam",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20187",
            "district": "11",
            "Block": "263",
            "label": "GHSS ANANDANADARKUDY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20188",
            "district": "11",
            "Block": "263",
            "label": "LMS GIRLS HIGH SCHOOL SANTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20189",
            "district": "11",
            "Block": "263",
            "label": "DR. SAMUEL HSS SANTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20190",
            "district": "11",
            "Block": "263",
            "label": "GOVERNMENT HIGH SCHOOL, VEMBANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20191",
            "district": "11",
            "Block": "263",
            "label": "ST. ROSSELLO'S SCHOOL, SADAYALPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21820",
            "district": "11",
            "Block": "263",
            "label": "GARDENCOURT CAMBRIDGE SCHOOL-KATTADITHATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21821",
            "district": "11",
            "Block": "263",
            "label": "PADMASHREE VIDYALAYA-GANAPATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21906",
            "district": "11",
            "Block": "263",
            "label": "EVANS SCHOOL OF EXCELLENCE (CBSE), CTM PURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22286",
            "district": "11",
            "Block": "263",
            "label": "LITTLE CHAMPION CBSE SCHOOL, PAMBANVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22662",
            "district": "11",
            "Block": "263",
            "label": "ZION INTERNATIONAL SCHOOL-ALAMPARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22690",
            "district": "11",
            "Block": "263",
            "label": "STELLA MARY'S CBSE SCHOOL-VELLAMODI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22771",
            "district": "11",
            "Block": "263",
            "label": "VINMEEN SCHOOL, PARVATHIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23267",
            "district": "11",
            "Block": "263",
            "label": "PONJESLY PUBLIC SCHOOL -ALMAPARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "264": [
        {
            "key": "1",
            "district": "38",
            "Block": "264",
            "label": "A.A.G.M HIGH SCHOOL, SANGARAPANDIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "2",
            "district": "38",
            "Block": "264",
            "label": "SRI RAO BAHADUR A.K.D.DHARMARAJA BOYS HR SEC SCHOOL, RAJAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3",
            "district": "38",
            "Block": "264",
            "label": "A.K.D.D.MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4",
            "district": "38",
            "Block": "264",
            "label": "ANANDA VIDYALAYA MATRICULATON HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5",
            "district": "38",
            "Block": "264",
            "label": "CASA DI MIR MATRICULATON HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6",
            "district": "38",
            "Block": "264",
            "label": "CHINMAYA VID P.A.C.R MAT HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7",
            "district": "38",
            "Block": "264",
            "label": "GANDHI MIDDLE SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8",
            "district": "38",
            "Block": "264",
            "label": "GHSS (G), CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGH SCHOOL, AYANKOLLANKONDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGH SCHOOL, MOUNT ZION",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11",
            "district": "38",
            "Block": "264",
            "label": "GHSS (B), CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12",
            "district": "38",
            "Block": "264",
            "label": "GHSS, CHOKKANATHANPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13",
            "district": "38",
            "Block": "264",
            "label": "GHSS, KILAVIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14",
            "district": "38",
            "Block": "264",
            "label": "GHSS, NALLAMANAICKERPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SIVALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGH SCHOOL, THIRUVALLUVAR NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17",
            "district": "38",
            "Block": "264",
            "label": "GOVT A.D.W HR SEC SCHOOL, SUNDARARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18",
            "district": "38",
            "Block": "264",
            "label": "GOVT HR SEC SCHOOL, SEITHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19",
            "district": "38",
            "Block": "264",
            "label": "HARISH MATRIC HSS, SANGAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20",
            "district": "38",
            "Block": "264",
            "label": "K.H.N.U.P.KAMARAJ. HR SEC SCHOOL, KRISHNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21",
            "district": "38",
            "Block": "264",
            "label": "K.P.A. MID SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "22",
            "district": "38",
            "Block": "264",
            "label": "LIVING HOPE MATRIC SCHOOL, MOUNT ZION",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23",
            "district": "38",
            "Block": "264",
            "label": "MAHARISHI VID MAT HSS,RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24",
            "district": "38",
            "Block": "264",
            "label": "M.M.AVUDAI AMMAL MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "25",
            "district": "38",
            "Block": "264",
            "label": "M.N.NADAR HIGH SCH, MELAVARAGUNARAMAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "26",
            "district": "38",
            "Block": "264",
            "label": "MURUGAN MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "27",
            "district": "38",
            "Block": "264",
            "label": "N.A ANNAPPARAJA MEMORIAL H S S RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "28",
            "district": "38",
            "Block": "264",
            "label": "N.A PAPPURAJA MEMORIAL MIDDLE SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "29",
            "district": "38",
            "Block": "264",
            "label": "NADAR HR SEC SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "30",
            "district": "38",
            "Block": "264",
            "label": "NADAR HSS, ZAMIN KOLLANKONDAN",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "31",
            "district": "38",
            "Block": "264",
            "label": "NADAR MATRIC HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "32",
            "district": "38",
            "Block": "264",
            "label": "NATARAJA MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "33",
            "district": "38",
            "Block": "264",
            "label": "NAVA BHARATH MATRIC HIGHER SECONDARY SCHOOL, S.RAMALINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "34",
            "district": "38",
            "Block": "264",
            "label": "P.A.C.R AMMANI AMMAL(G) HR SEC SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "35",
            "district": "38",
            "Block": "264",
            "label": "P.A.C.M HR SEC SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "36",
            "district": "38",
            "Block": "264",
            "label": "PALANIAPPA HSS, CHOLAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "37",
            "district": "38",
            "Block": "264",
            "label": "PERUNTHALAIVAR KAMARAJAR HSS MEENATICHIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "38",
            "district": "38",
            "Block": "264",
            "label": "PERUNTHALAIVAR KAMARAJAR MATRICULATON HR SEC SCHOOL, THALAVAIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "39",
            "district": "38",
            "Block": "264",
            "label": "P.P.S.A HINDU MIDDLE SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "40",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S, AMMAIYAPPAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "41",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S,ASILAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "42",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S, DEVADANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "43",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S, GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "44",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S PANCHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "45",
            "district": "38",
            "Block": "264",
            "label": "P.U.M.S, SOUTH VENGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "46",
            "district": "38",
            "Block": "264",
            "label": "SRI RAMANA VIDYALAYA MONTESSORI MATRIC HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "47",
            "district": "38",
            "Block": "264",
            "label": "RC HR SEC SCHOOL, MEENACHIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "48",
            "district": "38",
            "Block": "264",
            "label": "R.V THEVAR M.G HR SEC SCHOOL, CHOLAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "49",
            "district": "38",
            "Block": "264",
            "label": "SAKKA RAJA KOTTAI MID SCH, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "50",
            "district": "38",
            "Block": "264",
            "label": "SIVANTHI ATHITHANAR HIGH SCHOOL, KOVILUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "51",
            "district": "38",
            "Block": "264",
            "label": "CHINMAYA VIDYALAYA SLR MAT HSS, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "52",
            "district": "38",
            "Block": "264",
            "label": "SRI KRISHNASAMY MID.S, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "53",
            "district": "38",
            "Block": "264",
            "label": "SRI P.S.KUMARASAMY RAJA MEMORIAL GIRLS HIGH SCHOOL, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "54",
            "district": "38",
            "Block": "264",
            "label": "SRI RAO BAHADUR A.K.D.DHARMARAJA GIRLS HR.SEC.SCHOOL, RAJAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "55",
            "district": "38",
            "Block": "264",
            "label": "SSG(G)HSS,RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "56",
            "district": "38",
            "Block": "264",
            "label": "SSG(B)HSS, RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "57",
            "district": "38",
            "Block": "264",
            "label": "S.S.H.N.HR.SEC.SCHOOL, MUHAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "58",
            "district": "38",
            "Block": "264",
            "label": "ST ANTONYS MATRIC SCHOOL, CHETTIYARPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "59",
            "district": "38",
            "Block": "264",
            "label": "ST JOSEPH MID SCH, SUNDARANATCHIYARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "60",
            "district": "38",
            "Block": "264",
            "label": "T.D.T.A.M.S, POTTALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "61",
            "district": "38",
            "Block": "264",
            "label": "T.N.P.M.M.A.N (G).H.S.S, THALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "62",
            "district": "38",
            "Block": "264",
            "label": "T.N.P.M.MARIMUTHU NADAR HR.SEC.SCHOOL, THALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "63",
            "district": "38",
            "Block": "264",
            "label": "VANIGA VYSIAR HIGH SCHOOL, RAJAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "64",
            "district": "38",
            "Block": "264",
            "label": "VIII WARD MUNICIPAL MID SCH, RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21112",
            "district": "38",
            "Block": "264",
            "label": "ARUMUGAM PALANIGURU MODERN SCHOOL CHATRAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21113",
            "district": "38",
            "Block": "264",
            "label": "SRI RAMANA ACADEMY SENIOR SECONDARY CBSE, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21613",
            "district": "38",
            "Block": "264",
            "label": "KALVI THANTHAI KAMARAJAR VIDHYASALA MATRICULATION SCHOOL, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22620",
            "district": "38",
            "Block": "264",
            "label": "GOVERNMENT HIGH SCHOOL, SIVAKAMIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22621",
            "district": "38",
            "Block": "264",
            "label": "ST.JOSEPH INTERNATIONAL SCHOOL RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23127",
            "district": "38",
            "Block": "264",
            "label": "SRI ANNAI VIDYALAYA MATRICULATION SCHOOL, CHETTIYARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23185",
            "district": "38",
            "Block": "264",
            "label": "MIRASU VEERAPPA NAIDU VIDYALAYA MATRICULATION SCHOOL, CHETTIYARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23186",
            "district": "38",
            "Block": "264",
            "label": "SIVAKAMI HIGH SCHOOL, RAJAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        }
    ],
    "265": [
        {
            "key": "1997",
            "district": "20",
            "Block": "265",
            "label": "HOLY ANGELS MATRICULATION HIGHER SECONDARY SCHOOL, R.S.MANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2267",
            "district": "20",
            "Block": "265",
            "label": "WINNER'S MATRICULATION HIGH SCHOOL, R.S.MANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4473",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  APPIRAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4550",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RADHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4682",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SETHIDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4991",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KOTTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5442",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALINTHIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5579",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  GOVINDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5642",
            "district": "20",
            "Block": "265",
            "label": "V.K.RAMAIAH MIDDLE SCHOOL, KAVANAKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5850",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.MANAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5955",
            "district": "20",
            "Block": "265",
            "label": "JOTHI MIDDLE SCHOOL,  AYNGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6121",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  MELPANAIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6204",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19057",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, R.S.MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19058",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, R.S.MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19059",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19060",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGH SCHOOL, SHOLANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19061",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT  MODEL HIGHER SECONDARY SCHOOL, THIRUPPALAIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19062",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UPPOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19063",
            "district": "20",
            "Block": "265",
            "label": "SRI AMIRTA MATRICULATION SCHOOL, UPPOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19064",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGH SCHOOL, VETTUKKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19065",
            "district": "20",
            "Block": "265",
            "label": "ST.XAVIER'S HIGH SCHOOL, SAVERIYARPATTANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19066",
            "district": "20",
            "Block": "265",
            "label": "ST.MICHAEL'S HIGHER SECONDARY SCHOOL, SENGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19067",
            "district": "20",
            "Block": "265",
            "label": "SACRED HEART HIGH SCHOOL,  IRUDAYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19068",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ANANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19069",
            "district": "20",
            "Block": "265",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOODALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19070",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SANAVELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21521",
            "district": "20",
            "Block": "265",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, VADAVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "266": [
        {
            "key": "719",
            "district": "20",
            "Block": "266",
            "label": "VIVEKANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, DEVIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "859",
            "district": "20",
            "Block": "266",
            "label": "CRESCENT MATRICULATION HIGH SCHOOL, VELIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "942",
            "district": "20",
            "Block": "266",
            "label": "BUKHARIYA MATRICULATION HIGHER SECONDARY SCHOOL, DEVIPATTINAM SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "973",
            "district": "20",
            "Block": "266",
            "label": "HOUSING BOARD H.O.W.A.MATRICULATION HIGH SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "984",
            "district": "20",
            "Block": "266",
            "label": "LOUIS LEVAIL MATRICULATION SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1015",
            "district": "20",
            "Block": "266",
            "label": "INFANT JESUS MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1025",
            "district": "20",
            "Block": "266",
            "label": "SYED AMMAL MATRIC (BOYS) HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1349",
            "district": "20",
            "Block": "266",
            "label": "SRI KUMARAN MIDDLE SCHOOL , RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1427",
            "district": "20",
            "Block": "266",
            "label": "SCHWARTZ MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1650",
            "district": "20",
            "Block": "266",
            "label": "A.V.M.S. MATRICULATION HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1674",
            "district": "20",
            "Block": "266",
            "label": "SRI SARASWATHI MIDDLE SCHOOL, VELIPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1699",
            "district": "20",
            "Block": "266",
            "label": "POPE JOHN PAUL II MIDDLE SCHOOL, PASUMPON NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1751",
            "district": "20",
            "Block": "266",
            "label": "VELUMANICKAM MONTESSORI MATRICULATION HIGHER SECONDARY SCHOOL, VANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1815",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4067",
            "district": "20",
            "Block": "266",
            "label": "VALLUVAN MIDDLE SCHOOL , VELIPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5187",
            "district": "20",
            "Block": "266",
            "label": "SRI SETHUPATHI MIDDLE SCHOOL , RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19183",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, DEVIPATTINAM SOUTH",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19184",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KALUGOORANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19185",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL, PALANIVALASAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19186",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL, SAMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19187",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL, SAKKARAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19188",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , VAIRAVANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19189",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL , ATHIYOOTHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19190",
            "district": "20",
            "Block": "266",
            "label": "MOHAMEDIA HIGHER SECONDARY SCHOOL, CHITHARKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19191",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, R.KAVANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19192",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KARUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19193",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , KATTOORANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19194",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , MADHAVANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19195",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PUTHENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19196",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PULLANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19197",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL , PERAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19198",
            "district": "20",
            "Block": "266",
            "label": "ABCS KENBRIDGE HR. SEC. SCHOOL, SINGANENDAL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19199",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL , THORUVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19200",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , RAJASOORIYAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19201",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VANNIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23412",
            "district": "20",
            "Block": "266",
            "label": "Mannar Sethupathy Matriculation Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19202",
            "district": "20",
            "Block": "266",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NARANAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19203",
            "district": "20",
            "Block": "266",
            "label": "RAJAH'S HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19204",
            "district": "20",
            "Block": "266",
            "label": "ST.JOSEPH 'S HIGHER SECONDARY SCHOOL , RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19205",
            "district": "20",
            "Block": "266",
            "label": "RAJA M.DHINKAR HIGH SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19206",
            "district": "20",
            "Block": "266",
            "label": "D.D.VINAYAGAR HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19207",
            "district": "20",
            "Block": "266",
            "label": "SYED AMMAL HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19208",
            "district": "20",
            "Block": "266",
            "label": "SYED AMMAL MATRIC (GIRLS) HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19209",
            "district": "20",
            "Block": "266",
            "label": "COLUMBU ALIM HIGH SCHOOL , VELIPPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19210",
            "district": "20",
            "Block": "266",
            "label": "VALLAL PARI MUNICIPAL MIDDLE SCHOOL , VELIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19211",
            "district": "20",
            "Block": "266",
            "label": "MUNICIPAL GIRLS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19212",
            "district": "20",
            "Block": "266",
            "label": "ARIGNAR ANNA MUNICIPAL MIDDLE SCHOOL , RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19213",
            "district": "20",
            "Block": "266",
            "label": "SCHWARTZ BOYS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19214",
            "district": "20",
            "Block": "266",
            "label": "ST. ANDREW'S GIRLS HIGHER SECONDARY SCHOOL, RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21116",
            "district": "20",
            "Block": "266",
            "label": "AMRITA VIDYALAYAM, R.S.MADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21617",
            "district": "20",
            "Block": "266",
            "label": "SHIFAN NOOR GLOBAL ACADEMY, KOPPERIMADAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21651",
            "district": "20",
            "Block": "266",
            "label": "KRISHNA INTERNATIONAL SCHOOL, ECR DEVIPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22268",
            "district": "20",
            "Block": "266",
            "label": "M.G. PUBLIC CBSE SCHOOL, PERAVOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22630",
            "district": "20",
            "Block": "266",
            "label": "GOVERNMENT HIGH SCHOOL, DEVIPATTINAM SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23054",
            "district": "20",
            "Block": "266",
            "label": "SYED AMMAL PUBLIC SCHOOL, PERAVOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23532",
            "district": "20",
            "Block": "266",
            "label": "RAMANATHAPURAM DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        }
    ],
    "267": [
        {
            "key": "1790",
            "district": "17",
            "Block": "267",
            "label": "SRI VIDYA MANDIR MATRICULATION HIGHER  SECONDARY SCHOOL,GURUSAMIPALAYAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2220",
            "district": "17",
            "Block": "267",
            "label": "AVM AIDED MIDDLE SCHOOL,RASIPURAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2238",
            "district": "17",
            "Block": "267",
            "label": "SRI GANAPATHI VILAS GOVERNMENT AIDED MIDDLE SCHOOL,PATTANAM MUNIYAPPAM PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2525",
            "district": "17",
            "Block": "267",
            "label": "VIDHYA NIKETHAN MATRIC HIGHER SECONDARY SCHOOL,RASIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2610",
            "district": "17",
            "Block": "267",
            "label": "MMS BARATHIDHASAN SALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2636",
            "district": "17",
            "Block": "267",
            "label": "PUMS BODINAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2692",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL,POOSARIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2742",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL, ANAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2752",
            "district": "17",
            "Block": "267",
            "label": "SRI VIDYA MANDIR MATRIC HR.SEC.SCHOOL,RASIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2761",
            "district": "17",
            "Block": "267",
            "label": "MMS-KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2801",
            "district": "17",
            "Block": "267",
            "label": "SRI BHARATHIYA VIDYA MANDIR MATRIC HR SEC SCHOOL,GURUSAMIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2930",
            "district": "17",
            "Block": "267",
            "label": "PUMS PON.KALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3106",
            "district": "17",
            "Block": "267",
            "label": "SRI AMMAN MATRIC HIGH SCHOOL,MUTHUKALIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3396",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL , KOONAVELAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3471",
            "district": "17",
            "Block": "267",
            "label": "MMS-V.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3561",
            "district": "17",
            "Block": "267",
            "label": "PUMS MUTHUKALIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3585",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL,GOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4078",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL,KURUKKAPURAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4131",
            "district": "17",
            "Block": "267",
            "label": "THIRUVENKATAVILAS AIDED MIDDLE SCHOOL,RASIPURAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4173",
            "district": "17",
            "Block": "267",
            "label": "ARULMEGU VENKATESWARA AIDED MIDDLE SCHOOL,ANDAGALOREGATE.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4213",
            "district": "17",
            "Block": "267",
            "label": "R C ST.MARY'S MIDDLE SCHOOL, KAKKAVERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4687",
            "district": "17",
            "Block": "267",
            "label": "ANANDA VIDYALAYA MATRICULATION  HIGHER  SECONDARY SCHOOL,  R .PATTANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4934",
            "district": "17",
            "Block": "267",
            "label": "PU MIDDLE SCHOOL,85R.KOMARAPALAYAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11846",
            "district": "17",
            "Block": "267",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL-R.PATTANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11847",
            "district": "17",
            "Block": "267",
            "label": "RASI INTERNATIONAL SCHOOL(CBSE), RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11848",
            "district": "17",
            "Block": "267",
            "label": "SHRI VIDHYA BHARATHI MODERN HR SEC(CBSE) SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11849",
            "district": "17",
            "Block": "267",
            "label": "GHS CHANDRASEKARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11850",
            "district": "17",
            "Block": "267",
            "label": "R.C.SACRED HEART AIDED HR.SEC.SCHOOL,RASIPURAM.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11851",
            "district": "17",
            "Block": "267",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ANNASALAI, RASIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11852",
            "district": "17",
            "Block": "267",
            "label": "GHSS-SIVANANDA SALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11853",
            "district": "17",
            "Block": "267",
            "label": "NATIONAL GIRLS HIGHER SECONDARY SCHOOL, RASIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11854",
            "district": "17",
            "Block": "267",
            "label": "SRI VASAVI VIDYALAYA  MATRIC HR SEC SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11855",
            "district": "17",
            "Block": "267",
            "label": "SRV GIRLS HIGHER SECONDARY SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11856",
            "district": "17",
            "Block": "267",
            "label": "SRV BOYS HIGHER SECONDARY SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11857",
            "district": "17",
            "Block": "267",
            "label": "SRV EXCEL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11858",
            "district": "17",
            "Block": "267",
            "label": "GOVT HIGH SCHOOL,KUNAVELAMPATTY PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11859",
            "district": "17",
            "Block": "267",
            "label": "SENGUNTHAR MAHAJANA GIRLS HIGH SCHOOL,GURUSAMIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "23406",
            "district": "17",
            "Block": "267",
            "label": "SRP International School (CBSE)",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "11860",
            "district": "17",
            "Block": "267",
            "label": "SENGUNTHAR MAHAJANA GOVERNMENT AIDED HIGHER SECONDARY SCHOOL, GURUSAMIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11861",
            "district": "17",
            "Block": "267",
            "label": "NATIONAL MATRICULATION SCHOOL, SINGALANDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11862",
            "district": "17",
            "Block": "267",
            "label": "GHSS SINGALANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11863",
            "district": "17",
            "Block": "267",
            "label": "GHSS VADUGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21070",
            "district": "17",
            "Block": "267",
            "label": "VETRI VIKAAS PUBLIC SCHOOL (SENIOR SECONDARY),RASIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21669",
            "district": "17",
            "Block": "267",
            "label": "SRI VIDYA MANDIR MATRICULATION SCHOOL,KURUKKAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22122",
            "district": "17",
            "Block": "267",
            "label": "SRV INTERNATIONAL SCHOOL,PATTANAM ROAD,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22806",
            "district": "17",
            "Block": "267",
            "label": "SRV INNOVATIVE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22807",
            "district": "17",
            "Block": "267",
            "label": "SRI VIDYA MANDIR INTERNATIONAL (CBSE) SCHOOL,RASIPURAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22808",
            "district": "17",
            "Block": "267",
            "label": "SRV PUBLIC SCHOOL, RASIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "268": [
        {
            "key": "3325",
            "district": "7",
            "Block": "268",
            "label": "SSM ACADEMY AKKARAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3638",
            "district": "7",
            "Block": "268",
            "label": "HAJI MU. MAT.SCH,SRIRAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13612",
            "district": "7",
            "Block": "268",
            "label": "PUMS,PUDUETTAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13613",
            "district": "7",
            "Block": "268",
            "label": "GNANAM MS,KAMBILINAICKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13614",
            "district": "7",
            "Block": "268",
            "label": "GHSS,K.PUDUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13615",
            "district": "7",
            "Block": "268",
            "label": "PUMS,GURUNATHANAICKNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13616",
            "district": "7",
            "Block": "268",
            "label": "PUMS,NAVAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13617",
            "district": "7",
            "Block": "268",
            "label": "PUMS,M.ASARIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13618",
            "district": "7",
            "Block": "268",
            "label": "GHSS,SULLERUMBU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13619",
            "district": "7",
            "Block": "268",
            "label": "PUMS,KATHIRAYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13620",
            "district": "7",
            "Block": "268",
            "label": "PUMS,SILVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13621",
            "district": "7",
            "Block": "268",
            "label": "GKHSS,KONDAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13622",
            "district": "7",
            "Block": "268",
            "label": "GHS, S.VADIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13623",
            "district": "7",
            "Block": "268",
            "label": "PUMS,THEPPAKULATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13624",
            "district": "7",
            "Block": "268",
            "label": "PUMS,KATHIRANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13625",
            "district": "7",
            "Block": "268",
            "label": "PUMS,K.ELLAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13626",
            "district": "7",
            "Block": "268",
            "label": "GMS, KOTTAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13627",
            "district": "7",
            "Block": "268",
            "label": "PUMS,T.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13628",
            "district": "7",
            "Block": "268",
            "label": "GHS,T.PANNAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13629",
            "district": "7",
            "Block": "268",
            "label": "PUMS,SRIRAMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13630",
            "district": "7",
            "Block": "268",
            "label": "PUMS,BOLIYAMMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13631",
            "district": "7",
            "Block": "268",
            "label": "PUMS,VELLAMADATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13632",
            "district": "7",
            "Block": "268",
            "label": "RADHASAMY MMS,RAJAPUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13633",
            "district": "7",
            "Block": "268",
            "label": "GHSS,THIRUMALAIRAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13634",
            "district": "7",
            "Block": "268",
            "label": "PUMS,ADALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13635",
            "district": "7",
            "Block": "268",
            "label": "PUMS,PANDRIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13636",
            "district": "7",
            "Block": "268",
            "label": "GHS,KARISALPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13637",
            "district": "7",
            "Block": "268",
            "label": "GHSS,DHARMATHUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13638",
            "district": "7",
            "Block": "268",
            "label": "PUMS,D.KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13639",
            "district": "7",
            "Block": "268",
            "label": "PUMS,KUYAVANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13640",
            "district": "7",
            "Block": "268",
            "label": "THIRUMURUGAN MS,KASAVANAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13641",
            "district": "7",
            "Block": "268",
            "label": "GHSS,KONNUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13642",
            "district": "7",
            "Block": "268",
            "label": "MRGGHSS,KANNIVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13643",
            "district": "7",
            "Block": "268",
            "label": "GOVT. BOYS HIGHER SECONDARY SCHOOL,KANNIVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13644",
            "district": "7",
            "Block": "268",
            "label": "ST.MICHEAL'S HS,AMMAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13645",
            "district": "7",
            "Block": "268",
            "label": "ARUPADAIYAPPAN HIGH SCHOOL, ERNAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13646",
            "district": "7",
            "Block": "268",
            "label": "MEENAKSHI HSS,MANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13647",
            "district": "7",
            "Block": "268",
            "label": "MEENAKSHI MATRICULATION  HIGHER SECONDARY SCHOOL, MANGARAI PIRIVU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13648",
            "district": "7",
            "Block": "268",
            "label": "PUMS,PALAM RAJAKKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13649",
            "district": "7",
            "Block": "268",
            "label": "ST.FRANCIS XAVIER HSS,KUTTATHUAVARAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13650",
            "district": "7",
            "Block": "268",
            "label": "SSM MATRICULATION HIGHER SECONDARY SCHOOL, AKKARAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13651",
            "district": "7",
            "Block": "268",
            "label": "LOYOLA HSS,HANUMANTHARAYANKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21323",
            "district": "7",
            "Block": "268",
            "label": "VIVEKANANDAR VIDHYA MANDIR, SULLERUMBU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23935",
            "district": "7",
            "Block": "268",
            "label": "VIVEKANANDHA PUBLIC SCHOOL, KUNJANAMPATTI",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23936",
            "district": "7",
            "Block": "268",
            "label": "VIDVIDA ACADEMY, SRIRAMAPURAM, DINDIGUL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "269": [
        {
            "key": "5034",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANGIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6113",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADUVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6245",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL JAMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6408",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEELATHENUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6558",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVARADIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10466",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10467",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL ATHIYANTHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10495",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL MANALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10496",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT BOYS  HIGHER SECONDARY SCHOOL MANALURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10497",
            "district": "9",
            "Block": "269",
            "label": "DEEPALAKSHMI MATRICULATION SCHOOL MANALURPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10651",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL JAMBADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10652",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL SEERPADANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10653",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARKAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10654",
            "district": "9",
            "Block": "269",
            "label": "ST VINNARASI HIGH SCHOOL IRUDAYAMPATTU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10655",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10656",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10657",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL THOZHUVANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10658",
            "district": "9",
            "Block": "269",
            "label": "UNAIDED ROMAN CATHOLIC MIDDLE SCHOOL KANANKADU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10659",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAMAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10660",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL PERIYAKOLLIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10661",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEERPANANTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10662",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELAYANARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10663",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERIYAMANIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10664",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALLIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10665",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIRUPANAIYUR THAKKA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10666",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL KADAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10667",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10668",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EDUTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10669",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EDUTHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10670",
            "district": "9",
            "Block": "269",
            "label": "RAMANA MATRICULATION SCHOOL VANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10671",
            "district": "9",
            "Block": "269",
            "label": "SAINT DONBOSCO  HIGHER SECONDARY SCHOOL  VANABURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10672",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10673",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KADUVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10674",
            "district": "9",
            "Block": "269",
            "label": "KULANDAI YESU HIGH SCHOOL  MAIYANUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10675",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10676",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ARIYALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10677",
            "district": "9",
            "Block": "269",
            "label": "KASTHURIBA GANDHI BALIGA VIDYALAYA RESIDENTIAL SCHOOL VANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10678",
            "district": "9",
            "Block": "269",
            "label": "ADHI DIRAVIDAR WELFARE MIDDLE SCHOOL ENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10679",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AVIRIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10680",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL LA.GUDALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10681",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KEELPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10682",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NOOROLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10683",
            "district": "9",
            "Block": "269",
            "label": "ROMAN CATHOLIC MIDDLE SCHOOL MELAPAZHANGUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10684",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT MODEL SCHOOL SITHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10685",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAVANDUR HINDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10686",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PASAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10687",
            "district": "9",
            "Block": "269",
            "label": "ADHI DIRAVIDAR WELFARE MIDDLE SCHOOL PASAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10688",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL RISHIVANDIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10689",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT GIRLS  HIGH SCHOOL RISHIVANDIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10690",
            "district": "9",
            "Block": "269",
            "label": "KASTHURIBA GANDHI BALIGA VIDYALAYA RESIDENTIAL SCHOOL RISHIVANDIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10691",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PRIVIDAYAMPAT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10692",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MANDAGAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10693",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10694",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALLIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10695",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SITHERIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10696",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SOOLANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10697",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAZHAYASIRUVANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10698",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THONDANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22889",
            "district": "9",
            "Block": "269",
            "label": "GOVERNMENT HIGH SCHOOL MURUKKAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23937",
            "district": "9",
            "Block": "269",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23938",
            "district": "9",
            "Block": "269",
            "label": "TIRUMALA MATRIC SCHOOL VANAPURAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "270": [
        {
            "key": "381",
            "district": "3",
            "Block": "270",
            "label": "MARIA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "386",
            "district": "3",
            "Block": "270",
            "label": "ST MARYS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "394",
            "district": "3",
            "Block": "270",
            "label": "NORTHWICK GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "469",
            "district": "3",
            "Block": "270",
            "label": "TST RAJAH GIRLS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "849",
            "district": "3",
            "Block": "270",
            "label": "ST. COLUMBAN'S A I Hr Sec School",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "941",
            "district": "3",
            "Block": "270",
            "label": "ST FRANCIS XAVIER AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1457",
            "district": "3",
            "Block": "270",
            "label": "MODEL MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1470",
            "district": "3",
            "Block": "270",
            "label": "T.M.M.S SHUNMUGA SUNDARAM & A.P. SAMY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1563",
            "district": "3",
            "Block": "270",
            "label": "KALAIMAGAL VIDYALAYA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2240",
            "district": "3",
            "Block": "270",
            "label": "SRI VENKATESWARA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2503",
            "district": "3",
            "Block": "270",
            "label": "KAMARAJ MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3414",
            "district": "3",
            "Block": "270",
            "label": "ST THOMAS MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3793",
            "district": "3",
            "Block": "270",
            "label": "MEASI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3932",
            "district": "3",
            "Block": "270",
            "label": "DR RSM GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4344",
            "district": "3",
            "Block": "270",
            "label": "ARUMUGA NADAR GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4536",
            "district": "3",
            "Block": "270",
            "label": "C.S.I. MIDDLE SCHOOL (MINT RAJAGOPAL)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5655",
            "district": "3",
            "Block": "270",
            "label": "OUR LADYS CENTRE MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5990",
            "district": "3",
            "Block": "270",
            "label": "St KEVIN'S AI HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6146",
            "district": "3",
            "Block": "270",
            "label": "BISHOP CORRIE AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6356",
            "district": "3",
            "Block": "270",
            "label": "ST.ANNES MATRIC. HIGHER SEC.  SCHOOL,CH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6538",
            "district": "3",
            "Block": "270",
            "label": "B.SUBBARAYAN MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7120",
            "district": "3",
            "Block": "270",
            "label": "CMS VINAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7121",
            "district": "3",
            "Block": "270",
            "label": "CMS KUMMALAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7122",
            "district": "3",
            "Block": "270",
            "label": "CMS PUDUMANI KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7123",
            "district": "3",
            "Block": "270",
            "label": "GOVT. CHILDREN HOME FOR BOYS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7124",
            "district": "3",
            "Block": "270",
            "label": "SRI SARADHA DEVI MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7125",
            "district": "3",
            "Block": "270",
            "label": "CHSS APPASAMY LANE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7126",
            "district": "3",
            "Block": "270",
            "label": "KC SANKARALINGA NADAR HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7127",
            "district": "3",
            "Block": "270",
            "label": "PAK PALANISAMY HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23655",
            "district": "3",
            "Block": "270",
            "label": "Kalaimagal School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7128",
            "district": "3",
            "Block": "270",
            "label": "SRI. RAMA KRISHNA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7129",
            "district": "3",
            "Block": "270",
            "label": "CMS DORAI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7130",
            "district": "3",
            "Block": "270",
            "label": "ST THERESA'S GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7131",
            "district": "3",
            "Block": "270",
            "label": "VALLAL ETTIYAPPA NAICKER HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7132",
            "district": "3",
            "Block": "270",
            "label": "CBHS SN CHETTY ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7133",
            "district": "3",
            "Block": "270",
            "label": "CSI RAJAGOPAL HS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7134",
            "district": "3",
            "Block": "270",
            "label": "ST PETERS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7135",
            "district": "3",
            "Block": "270",
            "label": "SHREE GK JAIN HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7136",
            "district": "3",
            "Block": "270",
            "label": "ST ANNE'S GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7137",
            "district": "3",
            "Block": "270",
            "label": "KAMARAJ MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "7138",
            "district": "3",
            "Block": "270",
            "label": "KAMALA VINAYAGAR AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7139",
            "district": "3",
            "Block": "270",
            "label": "VALLAL SI ALAGARSAMY HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7140",
            "district": "3",
            "Block": "270",
            "label": "DHANALAKSHMI HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7141",
            "district": "3",
            "Block": "270",
            "label": "CMS SANJEEVIRAYAN KOIL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7142",
            "district": "3",
            "Block": "270",
            "label": "CHS MANIGANDAN ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7143",
            "district": "3",
            "Block": "270",
            "label": "C U BOYS HS WASHERMENPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7144",
            "district": "3",
            "Block": "270",
            "label": "TRI JAGAN MOHAN MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "7145",
            "district": "3",
            "Block": "270",
            "label": "KANNAPPA NAYANAR AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7146",
            "district": "3",
            "Block": "270",
            "label": "ST. ROQUE'S MATRIC SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7147",
            "district": "3",
            "Block": "270",
            "label": "C T HS ANJANEYA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7148",
            "district": "3",
            "Block": "270",
            "label": "C.M.S.SEVEN WELLS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7149",
            "district": "3",
            "Block": "270",
            "label": "GOVT MUSLIM HS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7150",
            "district": "3",
            "Block": "270",
            "label": "ST ANNE'S GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7151",
            "district": "3",
            "Block": "270",
            "label": "ST.GABRIEL'S HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7152",
            "district": "3",
            "Block": "270",
            "label": "CUGMS BROADWAY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7153",
            "district": "3",
            "Block": "270",
            "label": "DOMINIC SAVIO MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7154",
            "district": "3",
            "Block": "270",
            "label": "C.M.S.RAMASAMY ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7155",
            "district": "3",
            "Block": "270",
            "label": "C.M.S.SALAI VINAYAGAR KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7156",
            "district": "3",
            "Block": "270",
            "label": "DR DHARMAMBAL AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7157",
            "district": "3",
            "Block": "270",
            "label": "GOVT MUSLIM GIRLS HS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7158",
            "district": "3",
            "Block": "270",
            "label": "K. RAMIAH CHETTY ARC GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7159",
            "district": "3",
            "Block": "270",
            "label": "ANDERSON RAJAGOPAL GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7160",
            "district": "3",
            "Block": "270",
            "label": "MSB MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7161",
            "district": "3",
            "Block": "270",
            "label": "CUGMS ANGAPPA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7162",
            "district": "3",
            "Block": "270",
            "label": "ST. MARY'S AI HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7163",
            "district": "3",
            "Block": "270",
            "label": "THE MUTHIALPET HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7164",
            "district": "3",
            "Block": "270",
            "label": "ANDERSON DAY GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7165",
            "district": "3",
            "Block": "270",
            "label": "P.NAGAMMA AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7166",
            "district": "3",
            "Block": "270",
            "label": "ST. AGNES AIDED MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7167",
            "district": "3",
            "Block": "270",
            "label": "SKPD BOYS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7168",
            "district": "3",
            "Block": "270",
            "label": "KTCT GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7169",
            "district": "3",
            "Block": "270",
            "label": "HAJEE ESSA ABBA SAIT'S HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7170",
            "district": "3",
            "Block": "270",
            "label": "SHREE JTCJ MISSION HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7171",
            "district": "3",
            "Block": "270",
            "label": "PACHAIYAPPAS COLLEGE HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7172",
            "district": "3",
            "Block": "270",
            "label": "CHS CB ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7173",
            "district": "3",
            "Block": "270",
            "label": "C.M.S. PADAVETTAMMAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22683",
            "district": "3",
            "Block": "270",
            "label": "A.A.PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22692",
            "district": "3",
            "Block": "270",
            "label": "NARAYANA E - TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22972",
            "district": "3",
            "Block": "270",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23456",
            "district": "3",
            "Block": "270",
            "label": "SANA SMART MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23456",
            "district": "3",
            "Block": "270",
            "label": "SANA SMART MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24103",
            "district": "3",
            "Block": "270",
            "label": "ST ANNE'S GIRLS HSS",
            "Sch_type": "Partially AidedHigher Secondary School"
        },
        {
            "key": "24109",
            "district": "3",
            "Block": "270",
            "label": "SRI CHAITANYA TECHNO SCHOOL ROYAPURAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24110",
            "district": "3",
            "Block": "270",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "271": [
        {
            "key": "2759",
            "district": "23",
            "Block": "271",
            "label": "PUMS VELLIYANKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2953",
            "district": "23",
            "Block": "271",
            "label": "PUMS VARAPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3461",
            "district": "23",
            "Block": "271",
            "label": "PUMS KONDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3606",
            "district": "23",
            "Block": "271",
            "label": "PUMS KIZHAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3921",
            "district": "23",
            "Block": "271",
            "label": "PUMS K.PUDUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5542",
            "district": "23",
            "Block": "271",
            "label": "PUMS GANAPATHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6477",
            "district": "23",
            "Block": "271",
            "label": "PUMS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17778",
            "district": "23",
            "Block": "271",
            "label": "PUMS ARIYANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17779",
            "district": "23",
            "Block": "271",
            "label": "RMSA MODEL HSS, KATTUKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17780",
            "district": "23",
            "Block": "271",
            "label": "PUMS KURUMBALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17781",
            "district": "23",
            "Block": "271",
            "label": "PUMS DHARMAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17782",
            "district": "23",
            "Block": "271",
            "label": "PUMS NAGAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17783",
            "district": "23",
            "Block": "271",
            "label": "GOVT HR SEC SCHOOL MUSUNDAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17784",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS  KARISALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17785",
            "district": "23",
            "Block": "271",
            "label": "PUMS URATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17786",
            "district": "23",
            "Block": "271",
            "label": "PUMS MELAVANNARIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17787",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS KATTUKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17788",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS PULUTHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17789",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS ULAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17790",
            "district": "23",
            "Block": "271",
            "label": "KGBV ULAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17791",
            "district": "23",
            "Block": "271",
            "label": "GOVT HS VALASAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17792",
            "district": "23",
            "Block": "271",
            "label": "GOVT HSS V.PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23939",
            "district": "23",
            "Block": "271",
            "label": "PUMS GANAPATHIPATTI",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "272": [
        {
            "key": "2357",
            "district": "23",
            "Block": "272",
            "label": "MARUTHA MALAIYAN MAT SCHOOL KARIKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2853",
            "district": "23",
            "Block": "272",
            "label": "THE LEADERS MATRICULATION HR SEC SCHOOL KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2873",
            "district": "23",
            "Block": "272",
            "label": "KAMBAN KARPAKAM MATRIC HR SEC SCHOOL , KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3065",
            "district": "23",
            "Block": "272",
            "label": "CHIDAMBARAM VISALAKSHI  MATRICULATION  SCHOOL KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3229",
            "district": "23",
            "Block": "272",
            "label": "C.V.C.T.V MEENAKSHI ACHI MATRICULATION SCHOOL, KANADUKATHAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3344",
            "district": "23",
            "Block": "272",
            "label": "MUTHIAH ALAGAPPA MATRIC HR SEC SCHOOL, KOTTAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3391",
            "district": "23",
            "Block": "272",
            "label": "ST MICHAEL MATRIC HR SEC SCHOOL , ARIYAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3519",
            "district": "23",
            "Block": "272",
            "label": "SHRI VIDHYAA GIRI MATRICULATION HIGHER SECONDARY SCHOOL, PUDUVAYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3701",
            "district": "23",
            "Block": "272",
            "label": "PUMS MITHRANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4015",
            "district": "23",
            "Block": "272",
            "label": "PUMS V.SOORAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4260",
            "district": "23",
            "Block": "272",
            "label": "MAHARISHI.VIDYA.MANDIR MATRIC.HR.SEC.SCHOOL, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4684",
            "district": "23",
            "Block": "272",
            "label": "SRI SUBBAIAH AMBALAM MATRIC HR SEC SCHOOL , KOTTAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4939",
            "district": "23",
            "Block": "272",
            "label": "T.V.MID.SCH, NEMATHANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5355",
            "district": "23",
            "Block": "272",
            "label": "PUMS CHINNAVENGAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5378",
            "district": "23",
            "Block": "272",
            "label": "PUMS AMBAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5521",
            "district": "23",
            "Block": "272",
            "label": "PUMS PULIYANKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6066",
            "district": "23",
            "Block": "272",
            "label": "PUMS A.VELANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6070",
            "district": "23",
            "Block": "272",
            "label": "SRI SARATHA VIDHALAYA MATRIC SCHOOL, AMARAVATHIPUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17636",
            "district": "23",
            "Block": "272",
            "label": "GOVT HSS, O.SIRUVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17637",
            "district": "23",
            "Block": "272",
            "label": "CHELLAPPAN VIDYA MANDIR KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17638",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, THIRUVELANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17639",
            "district": "23",
            "Block": "272",
            "label": "M.C.T.M.C.C HR SEC, SCHOOL KANADUKATHAN",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17640",
            "district": "23",
            "Block": "272",
            "label": "SELVA VINAYAGAR HIGH SCHOOL, KOTHAMANGALAM.",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17641",
            "district": "23",
            "Block": "272",
            "label": "SEETHAI ACHI MIDDLE SCHOOL PALLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17642",
            "district": "23",
            "Block": "272",
            "label": "A.C HSS, PALLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17643",
            "district": "23",
            "Block": "272",
            "label": "R.M.M.GIRLS.HIGH SCH PALLATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17644",
            "district": "23",
            "Block": "272",
            "label": "AL.V.A.M.PUMS , KOTTAIYUR.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17645",
            "district": "23",
            "Block": "272",
            "label": "C.C. (G) HSS, KOTTAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17646",
            "district": "23",
            "Block": "272",
            "label": "T.A.C.GOVT.HR.SEC SCHOOL ,  KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17647",
            "district": "23",
            "Block": "272",
            "label": "M.M. SCHOOL, KALANIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17648",
            "district": "23",
            "Block": "272",
            "label": "PUMS NESAVALAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17649",
            "district": "23",
            "Block": "272",
            "label": "SRI VAIRAVAR MATRIC HIGH SCHOOL , KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17650",
            "district": "23",
            "Block": "272",
            "label": "PRNC MIDDLE SCHOOL KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17651",
            "district": "23",
            "Block": "272",
            "label": "B.MPL MID SCHOOL BARATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17652",
            "district": "23",
            "Block": "272",
            "label": "MPL MID SCHOOL SOUTH STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17653",
            "district": "23",
            "Block": "272",
            "label": "V.O.C MPL MID SCHOOL KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17654",
            "district": "23",
            "Block": "272",
            "label": "M.V GOVT HSS, KARAIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17655",
            "district": "23",
            "Block": "272",
            "label": "GOVT (G) HSS, MUTHUPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17656",
            "district": "23",
            "Block": "272",
            "label": "S.M (G) HSS, KARAIKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17657",
            "district": "23",
            "Block": "272",
            "label": "S.M.S.V.HR SEC SCHOOL , KARAIKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17658",
            "district": "23",
            "Block": "272",
            "label": "ALAGAPPA MODEL HSS, KARAIKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17659",
            "district": "23",
            "Block": "272",
            "label": "ANNAI VELANGANNI MATRIC SCHOOL , KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17660",
            "district": "23",
            "Block": "272",
            "label": "AZAD.MATRIC HIGH SCHOOL, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17661",
            "district": "23",
            "Block": "272",
            "label": "SAHAYAMATHA MATRICULATION HIGHER SECONDARY SCHOOL,KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17662",
            "district": "23",
            "Block": "272",
            "label": "SRI RAGHAVENDRA MATRIC HR SEC SCHOOL, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17663",
            "district": "23",
            "Block": "272",
            "label": "R.H. HIGHER SECONDARY  SCHOOL. ( RESIDENTIAL  HANDICAPED ),KARIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17664",
            "district": "23",
            "Block": "272",
            "label": "ALANGUDIYAR STREET MUNICIPAL HIGH SCHOOL ,   KARAIKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17665",
            "district": "23",
            "Block": "272",
            "label": "L.F.R.C HIGHER SECONDARY SCHOOL, KARAIKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17666",
            "district": "23",
            "Block": "272",
            "label": "SRI KALAIVANI VIDHYALAYA MATRIC HSS, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17667",
            "district": "23",
            "Block": "272",
            "label": "SCGR HSS AMARAVATHIPUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17668",
            "district": "23",
            "Block": "272",
            "label": "PUMS DEVAKOTTAI SALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17669",
            "district": "23",
            "Block": "272",
            "label": "GOVT HSS, ARIYAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17670",
            "district": "23",
            "Block": "272",
            "label": "INFANT JESUS MAT SCOOL ARIYAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17671",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL ILUPPAIKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17672",
            "district": "23",
            "Block": "272",
            "label": "ALAGAPPA MATRIC HSS, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17673",
            "district": "23",
            "Block": "272",
            "label": "KENDRIYA VIDHYALAYA SCHOOL, KARAIKUDI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17674",
            "district": "23",
            "Block": "272",
            "label": "C.A.M. HIGH SCHOOL ,KANDANUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17675",
            "district": "23",
            "Block": "272",
            "label": "R.C HSS, PUDUVAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17676",
            "district": "23",
            "Block": "272",
            "label": "SRI SARASWATHI  VIDYASALAI GIRLS HR SEC SCHOOL PUDUVAYAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17677",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, SAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17678",
            "district": "23",
            "Block": "272",
            "label": "SRI KALAIMAGAL VIDYALAYA MHSS PUDHUVAYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17679",
            "district": "23",
            "Block": "272",
            "label": "Shangaranandha Gurugulam (CBSE) School-Puduvayal",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17680",
            "district": "23",
            "Block": "272",
            "label": "PUMS PETHATCHIKUDIIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17681",
            "district": "23",
            "Block": "272",
            "label": "GOVT HSS, PEERKALAIKADU -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17682",
            "district": "23",
            "Block": "272",
            "label": "PUMS AYENIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17683",
            "district": "23",
            "Block": "272",
            "label": "Pums Vilarikkadu Panampatti",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17684",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, SIRUGAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17685",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, PERIYAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17686",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, JEYANKONDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17687",
            "district": "23",
            "Block": "272",
            "label": "GOVT HIGH SCHOOL, SITHIVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17688",
            "district": "23",
            "Block": "272",
            "label": "GOVT HSS, MITHIRAVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17689",
            "district": "23",
            "Block": "272",
            "label": "PUMS N.PALAIUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21334",
            "district": "23",
            "Block": "272",
            "label": "THE LEADERS ACADEMY KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21399",
            "district": "23",
            "Block": "272",
            "label": "GHS, POYYAVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21564",
            "district": "23",
            "Block": "272",
            "label": "RAMANATHAN CHETTIAR MUNICIPAL HIGH SCHOOL  KARAIKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21846",
            "district": "23",
            "Block": "272",
            "label": "SARAVANA MATRICULATION MIDDLE SCHOOL MANACHAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21847",
            "district": "23",
            "Block": "272",
            "label": "SHREE RAAJA RAJAN SCHOOL  AMARAVATHI PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21848",
            "district": "23",
            "Block": "272",
            "label": "ALAGAPPA  ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21849",
            "district": "23",
            "Block": "272",
            "label": "ALAGAPPA GIRLS MAT.HSS. SCHOOL, KARAIKUDI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21850",
            "district": "23",
            "Block": "272",
            "label": "KARAIKUDI MAHARISHI VIDYA MANDIR MT.HR.SEC.  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21975",
            "district": "23",
            "Block": "272",
            "label": "VIDHYA GIRI MATRICULATION HIGHER SECONDARY SCHOOL, KARAIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22595",
            "district": "23",
            "Block": "272",
            "label": "GHS,SAKKAVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "273": [
        {
            "key": "1154",
            "district": "22",
            "Block": "273",
            "label": "ST. VINCENT PALLOTI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2324",
            "district": "22",
            "Block": "273",
            "label": "MODERN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2344",
            "district": "22",
            "Block": "273",
            "label": "GLAZE BROOKE MATRIC HR. SEC. SCHOOL, N.M.ADIVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2821",
            "district": "22",
            "Block": "273",
            "label": "P.V. MATRIC HR. SEC. SCHOOL, SIVADHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3289",
            "district": "22",
            "Block": "273",
            "label": "ST. JOSEPH MATRICULATION SCHOOL SURAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3822",
            "district": "22",
            "Block": "273",
            "label": "N.P.R. MATRIC HR. SEC. SCHOOL , NATTAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3834",
            "district": "22",
            "Block": "273",
            "label": "SENGUNTHAR MATRIC  HR. SEC. SCHOOL, SANYASIKUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3969",
            "district": "22",
            "Block": "273",
            "label": "SRI VIDYA MANDIR SR. SEC. SCHOOL (CBSE), MEIYYANOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4845",
            "district": "22",
            "Block": "273",
            "label": "GLAZE BROOKE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5344",
            "district": "22",
            "Block": "273",
            "label": "S.R.K. MATRIC HR. SEC. SCHOOL,KANDAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6074",
            "district": "22",
            "Block": "273",
            "label": "CHINMAYA VIDYALAYA MATRIC HIGH SCHOOL,SOLAMPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6403",
            "district": "22",
            "Block": "273",
            "label": "A.R. MATRIC HR.SEC.SCHOOL, PERUMALMALAIADIVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11238",
            "district": "22",
            "Block": "273",
            "label": "PUMS,SIRCARKOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11239",
            "district": "22",
            "Block": "273",
            "label": "GHSS,SARKARKOLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11240",
            "district": "22",
            "Block": "273",
            "label": "PUMS, MAJURAKOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11241",
            "district": "22",
            "Block": "273",
            "label": "PUMS, THIRUMALAIGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11242",
            "district": "22",
            "Block": "273",
            "label": "GHS,VEDUGATHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11243",
            "district": "22",
            "Block": "273",
            "label": "PUMS, AYYAMPERUMAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11244",
            "district": "22",
            "Block": "273",
            "label": "SRI PALANIAPPA MATRIC SCHOOL,MALLAMOOPAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11245",
            "district": "22",
            "Block": "273",
            "label": "SRI GAYATHRI HR. SEC. SCHOOL, THALAVAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11246",
            "district": "22",
            "Block": "273",
            "label": "PUMS, JAGIRREDDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11247",
            "district": "22",
            "Block": "273",
            "label": "VAGGISVARI VIDYALAYA (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11248",
            "district": "22",
            "Block": "273",
            "label": "GHSS,JAGIR AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11249",
            "district": "22",
            "Block": "273",
            "label": "SENTHIL PUBLIC SCHOOL (CBSE) JAGIR AMPALAYAMMAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11250",
            "district": "22",
            "Block": "273",
            "label": "PUMS, NARASOTHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11251",
            "district": "22",
            "Block": "273",
            "label": "MOTHERS VIDHYALAYA MATRIC HR. SEC. SCHOOL,NARASOTHIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11252",
            "district": "22",
            "Block": "273",
            "label": "GHS,ALAGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11253",
            "district": "22",
            "Block": "273",
            "label": "NSK VAISHINAVI SMART MATRICULATION  SCHOOL GORIMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11254",
            "district": "22",
            "Block": "273",
            "label": "GHS,CHETTICHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11255",
            "district": "22",
            "Block": "273",
            "label": "PUMS, CHINNAKOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11256",
            "district": "22",
            "Block": "273",
            "label": "PUMS, VINAYAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11257",
            "district": "22",
            "Block": "273",
            "label": "MOUNT MARY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11258",
            "district": "22",
            "Block": "273",
            "label": "EMERALD VALLEY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11259",
            "district": "22",
            "Block": "273",
            "label": "SHARON HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11260",
            "district": "22",
            "Block": "273",
            "label": "SRI SESHAAS INTERNATIONAL PUBLIC SCHOOL,YERCAUD FOOTHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11261",
            "district": "22",
            "Block": "273",
            "label": "GHS, KONDAPPANAICKENPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11262",
            "district": "22",
            "Block": "273",
            "label": "PUMS,KANNANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11263",
            "district": "22",
            "Block": "273",
            "label": "PUMS, THAMARAINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11264",
            "district": "22",
            "Block": "273",
            "label": "PUMS CHINNATHIRUPATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11265",
            "district": "22",
            "Block": "273",
            "label": "ST. BASIL MATRIC SCHOOL, CHINNATHIRUPATHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11266",
            "district": "22",
            "Block": "273",
            "label": "GHSS,KANNANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11267",
            "district": "22",
            "Block": "273",
            "label": "PUMS,MITTAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11268",
            "district": "22",
            "Block": "273",
            "label": "PUMS,KOMBAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11269",
            "district": "22",
            "Block": "273",
            "label": "GHS ALAGAPURAM PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11270",
            "district": "22",
            "Block": "273",
            "label": "PUMS,SANNIYASIGUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11271",
            "district": "22",
            "Block": "273",
            "label": "ST. THOMAS MATRIC SCHOOL, SANNIYASIGUNDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11272",
            "district": "22",
            "Block": "273",
            "label": "GHS, ERUMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11273",
            "district": "22",
            "Block": "273",
            "label": "PUMS, BHARATHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11274",
            "district": "22",
            "Block": "273",
            "label": "GHSS, MANIYANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11275",
            "district": "22",
            "Block": "273",
            "label": "MMS,BODINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11276",
            "district": "22",
            "Block": "273",
            "label": "SRI RAMALINGA VALLALAR HR. SEC. SCHOOL, SURAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11277",
            "district": "22",
            "Block": "273",
            "label": "DHAARUS SALAAM MATRIC HR. SEC. SCHOOL SALEM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11278",
            "district": "22",
            "Block": "273",
            "label": "SBK MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11279",
            "district": "22",
            "Block": "273",
            "label": "SRI RAMAKRISHNA SARADA HR. SEC. SCHOOL (AIDED)",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11280",
            "district": "22",
            "Block": "273",
            "label": "NEELAMBAL SUBRAMANIAM HR. SEC. SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11281",
            "district": "22",
            "Block": "273",
            "label": "ST. JOSEPH GIRLS HR. SEC. SCHOOL (AIDED),SURAMANGALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11282",
            "district": "22",
            "Block": "273",
            "label": "PUMS, KUDUMIANTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11283",
            "district": "22",
            "Block": "273",
            "label": "GHS FOR DEAF SURAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11284",
            "district": "22",
            "Block": "273",
            "label": "PUMS,SELATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11285",
            "district": "22",
            "Block": "273",
            "label": "PUMS, SIVADHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11286",
            "district": "22",
            "Block": "273",
            "label": "GHSS,SIVADHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11287",
            "district": "22",
            "Block": "273",
            "label": "PUMS,ANDIPATI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11288",
            "district": "22",
            "Block": "273",
            "label": "PUMS, KANDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11289",
            "district": "22",
            "Block": "273",
            "label": "EQUITAS GURUKUL MATRIC HR. SEC. SCHOOL, SURAMANGALAM, KANDAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11290",
            "district": "22",
            "Block": "273",
            "label": "GHS SEELANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20882",
            "district": "22",
            "Block": "273",
            "label": "CLUNY VIDYA NIKETHAN SCHOOL,ERUMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21021",
            "district": "22",
            "Block": "273",
            "label": "SRI GAYATHRI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23381",
            "district": "22",
            "Block": "273",
            "label": "Sugam Matriculation Hr.Sec.School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21562",
            "district": "22",
            "Block": "273",
            "label": "MUNICIPAL HIGH SCHOOL, OLD SURAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22440",
            "district": "22",
            "Block": "273",
            "label": "GHS KAMINAICKENPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22441",
            "district": "22",
            "Block": "273",
            "label": "SRI CHAITANYA TECHNO SCHOOL,PERUMAL MALAI ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22442",
            "district": "22",
            "Block": "273",
            "label": "THE INDIAN PUBLIC SCHOOL, CHETTICHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23265",
            "district": "22",
            "Block": "273",
            "label": "GOLDEN GATES EARTH SCHOOL, KONDAPPANAICKENPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23268",
            "district": "22",
            "Block": "273",
            "label": "SRI VIDYA MANDIR SCHOOL, KANNANKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23321",
            "district": "22",
            "Block": "273",
            "label": "SRK GLOBAL SCHOOL ,KANDHAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23546",
            "district": "22",
            "Block": "273",
            "label": "EMERALD VALLEY PUBLIC SCHOOL, KONDAPPANAIKANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23546",
            "district": "22",
            "Block": "273",
            "label": "EMERALD VALLEY PUBLIC SCHOOL, KONDAPPANAIKANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23940",
            "district": "22",
            "Block": "273",
            "label": "NARAYANA E-TECHNO SCHOOL, REDDIYUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "274": [
        {
            "key": "2263",
            "district": "22",
            "Block": "274",
            "label": "SRI SARADA BALAMANDIR. BOYS MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2271",
            "district": "22",
            "Block": "274",
            "label": "BALA BARATHI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2488",
            "district": "22",
            "Block": "274",
            "label": "JAYARANI MATRIC HR. SEC. SCHOOL, NETHIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2504",
            "district": "22",
            "Block": "274",
            "label": "C.S.I. GOOD SHEPHERD MATRIC HR. SEC. SCHOOL,FORT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3267",
            "district": "22",
            "Block": "274",
            "label": "SRI SARADA MATRIC HR. SEC. SCHOOL FOR GIRLS,FAIRLANDS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3814",
            "district": "22",
            "Block": "274",
            "label": "YUVA BHARATHI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4271",
            "district": "22",
            "Block": "274",
            "label": "VINAYAKA VIDYALAYA MATRIC HR. SEC. SCHOOL,FAIRLANDS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4288",
            "district": "22",
            "Block": "274",
            "label": "ST JOSEPH'S MATRIC HR. SEC. SCHOOL, SALEM-6",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4366",
            "district": "22",
            "Block": "274",
            "label": "HOLY FLOWER MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4376",
            "district": "22",
            "Block": "274",
            "label": "BALA GURUKULAM MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5463",
            "district": "22",
            "Block": "274",
            "label": "ANDERSON MATRIC HR. SEC. SCHOOL,YERCAUD MAIN ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6392",
            "district": "22",
            "Block": "274",
            "label": "SRI VIDYA MANDIR SR. SEC. SCHOOL, SHIVAJI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6611",
            "district": "22",
            "Block": "274",
            "label": "PUSHPA MATRIC SCHOOL,GUGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6617",
            "district": "22",
            "Block": "274",
            "label": "SRI VASAVI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11684",
            "district": "22",
            "Block": "274",
            "label": "SRI SARADA VIDYALAYA HR. SEC. SCHOOL FOR GIRLS (AIDED)",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11685",
            "district": "22",
            "Block": "274",
            "label": "HOLY ANGELS MATRIC HR. SEC. SCHOOL,FAIRLANDS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11686",
            "district": "22",
            "Block": "274",
            "label": "ST.JOHN'S MATRIC HR. SEC. SCHOOL,NEW  ALAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11687",
            "district": "22",
            "Block": "274",
            "label": "ANDERSON HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11688",
            "district": "22",
            "Block": "274",
            "label": "JAYALAKSHMI VIDYALAYAM HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11689",
            "district": "22",
            "Block": "274",
            "label": "NEW INDIA DISCOVERY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11690",
            "district": "22",
            "Block": "274",
            "label": "MPL HIGH SCHOOL PUTHUMARIYAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11691",
            "district": "22",
            "Block": "274",
            "label": "K M G H S S MANAKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11692",
            "district": "22",
            "Block": "274",
            "label": "BHARATHI VIDYALAYA HR. SEC. SCHOOL,MARAVANERI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11693",
            "district": "22",
            "Block": "274",
            "label": "C.S.I. HOBART GIRLS HIGH SCHOOL,HASTHAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11694",
            "district": "22",
            "Block": "274",
            "label": "L.E.F. EDEN GARDEN MATRIC SCHOOL,HASTHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11695",
            "district": "22",
            "Block": "274",
            "label": "CLUNY MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11696",
            "district": "22",
            "Block": "274",
            "label": "AMS CHANDRA DEVAPRASAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11697",
            "district": "22",
            "Block": "274",
            "label": "GOVT HIGH SCHOOL AR LINES",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11698",
            "district": "22",
            "Block": "274",
            "label": "MHS SAGADEVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11699",
            "district": "22",
            "Block": "274",
            "label": "THE LITTLE FLOWER HR. SEC. SCHOOL, SALEM-7",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11700",
            "district": "22",
            "Block": "274",
            "label": "ST. PAUL'S HR. SEC. SCHOOL, SALEM-7",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11701",
            "district": "22",
            "Block": "274",
            "label": "MMS ARISIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11702",
            "district": "22",
            "Block": "274",
            "label": "ST. MARY'S GIRLS HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11703",
            "district": "22",
            "Block": "274",
            "label": "THE LITTLE FLOWER MATRICULATION SCHOOL,FOUR ROADS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11704",
            "district": "22",
            "Block": "274",
            "label": "MMS. NARASIMMAN SALAI ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11705",
            "district": "22",
            "Block": "274",
            "label": "JAYARAKKINI AIDED MS, SHEVAPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11706",
            "district": "22",
            "Block": "274",
            "label": "GOKULANATHA HINDU MAHAJANA BOYS HR.SEC.SALEM 1",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11707",
            "district": "22",
            "Block": "274",
            "label": "MANURKULA DEVANGA HIGH SCHOOL,SHEVAPET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11708",
            "district": "22",
            "Block": "274",
            "label": "VASAVI HR SEC SCHOOL, SHEVAPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11709",
            "district": "22",
            "Block": "274",
            "label": "MHS SHEVAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11710",
            "district": "22",
            "Block": "274",
            "label": "CORNATION AIDED MS, SELLAKUTTIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11711",
            "district": "22",
            "Block": "274",
            "label": "AMS SOURASHTRA VIDYALAYA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11712",
            "district": "22",
            "Block": "274",
            "label": "GGHSS SALEM 1",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11713",
            "district": "22",
            "Block": "274",
            "label": "CSI HIGHER SEC SCHOOL-FORT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11714",
            "district": "22",
            "Block": "274",
            "label": "MBHSS FORT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11715",
            "district": "22",
            "Block": "274",
            "label": "MMS CAR STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11716",
            "district": "22",
            "Block": "274",
            "label": "TIPPU ISLAMIYA HIGH SCHOOL,SALEM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11717",
            "district": "22",
            "Block": "274",
            "label": "TIPPU ISLAMIYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11718",
            "district": "22",
            "Block": "274",
            "label": "MMS. THEETTUKKAL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11719",
            "district": "22",
            "Block": "274",
            "label": "SRI SATHYA SAI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11720",
            "district": "22",
            "Block": "274",
            "label": "MMS. ELLAPPAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11721",
            "district": "22",
            "Block": "274",
            "label": "MBHSS, AMMAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11722",
            "district": "22",
            "Block": "274",
            "label": "MGHSS AMMAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11723",
            "district": "22",
            "Block": "274",
            "label": "MHS, VAIYAPURI STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11724",
            "district": "22",
            "Block": "274",
            "label": "MBHSS PAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11725",
            "district": "22",
            "Block": "274",
            "label": "JAY MATRICULATION SCHOOL, KITCHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11726",
            "district": "22",
            "Block": "274",
            "label": "SINDHI HINDU HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11727",
            "district": "22",
            "Block": "274",
            "label": "MGHSS PAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11728",
            "district": "22",
            "Block": "274",
            "label": "MMS ERUMAPALAYAM No.2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11729",
            "district": "22",
            "Block": "274",
            "label": "MMS PACHAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11730",
            "district": "22",
            "Block": "274",
            "label": "ANGAYAR KANNI AIDED MS  - AMMAPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11731",
            "district": "22",
            "Block": "274",
            "label": "SOURASHTRA HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11732",
            "district": "22",
            "Block": "274",
            "label": "MHS PUDHU STREET KITCHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11733",
            "district": "22",
            "Block": "274",
            "label": "NATIONAL HIGH SCHOOL K PALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "11734",
            "district": "22",
            "Block": "274",
            "label": "J.R. CAMBRIDGE SCHOOL, NARAYANA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11735",
            "district": "22",
            "Block": "274",
            "label": "AMS CSI AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11736",
            "district": "22",
            "Block": "274",
            "label": "MPL GHSS, GUGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11737",
            "district": "22",
            "Block": "274",
            "label": "MMS - KALIAMMAN KOIL ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11738",
            "district": "22",
            "Block": "274",
            "label": "AMS SRI GURUSWAMY-GUGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11739",
            "district": "22",
            "Block": "274",
            "label": "SEVENTH DAY ADVENTIST MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11740",
            "district": "22",
            "Block": "274",
            "label": "SRI VIDYA MANDIR SEC. SCHOOL, SHEVAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11741",
            "district": "22",
            "Block": "274",
            "label": "MMS.ANNATHANAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23692",
            "district": "22",
            "Block": "274",
            "label": "Silver Bell Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "11742",
            "district": "22",
            "Block": "274",
            "label": "JAYARANI GIRL'S AIDED HR SEC  SCHOOL,NETHIMEDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11743",
            "district": "22",
            "Block": "274",
            "label": "THE GUGAI HR SEC SCHOOL -GUGAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11744",
            "district": "22",
            "Block": "274",
            "label": "GOKULA VILAS  AIDED MS,SANJEEVARAYANPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "11745",
            "district": "22",
            "Block": "274",
            "label": "MMS. SANJEEVARAYANPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11746",
            "district": "22",
            "Block": "274",
            "label": "MMS, THADAGAPATTI, METTU ST ,SALEM-6",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11747",
            "district": "22",
            "Block": "274",
            "label": "VEERALAKSHMI VIDYALAYA HIGH SCHOOL,GUGAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20924",
            "district": "22",
            "Block": "274",
            "label": "GOVT BLIND MIDDLE SCHOOL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21067",
            "district": "22",
            "Block": "274",
            "label": "HOLY ANGELS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21068",
            "district": "22",
            "Block": "274",
            "label": "JAIRAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21972",
            "district": "22",
            "Block": "274",
            "label": "JAIRAM HIGHER SECONDARY SCHOOL, CHINNATHIRUPATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22119",
            "district": "22",
            "Block": "274",
            "label": "ST. JOHN'S NATIONAL ACADEMY, ALAGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22444",
            "district": "22",
            "Block": "274",
            "label": "MHS., KALARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23547",
            "district": "22",
            "Block": "274",
            "label": "ST. MARY'S PUBLIC SCHOOL, ARISIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23547",
            "district": "22",
            "Block": "274",
            "label": "ST. MARY'S PUBLIC SCHOOL, ARISIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23941",
            "district": "22",
            "Block": "274",
            "label": "SILVERLINES MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23942",
            "district": "22",
            "Block": "274",
            "label": "JOTHI CHANDARAN VIDYAALAYAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "275": [
        {
            "key": "3281",
            "district": "7",
            "Block": "275",
            "label": "ST.ANNES MATRIC SCHOOL,VEMBARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4785",
            "district": "7",
            "Block": "275",
            "label": "NEHRUJI MAT SCHOOL,THIRUMALAIKENI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4870",
            "district": "7",
            "Block": "275",
            "label": "VICTORY MATRIC SCHOOL, KAMBILIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13652",
            "district": "7",
            "Block": "275",
            "label": "CARMEL MATRICULATION SCHOOL, NOCHIODAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13653",
            "district": "7",
            "Block": "275",
            "label": "GHS,KOOVAKURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13654",
            "district": "7",
            "Block": "275",
            "label": "PUMS, VADAKATTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13655",
            "district": "7",
            "Block": "275",
            "label": "RC AMALA ANNAI MS, THAVASIMADAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13656",
            "district": "7",
            "Block": "275",
            "label": "PUMS, EMAKKALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13657",
            "district": "7",
            "Block": "275",
            "label": "PUMS, KONAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13658",
            "district": "7",
            "Block": "275",
            "label": "GHSS,SHANARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13659",
            "district": "7",
            "Block": "275",
            "label": "PUMS, KANNIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13660",
            "district": "7",
            "Block": "275",
            "label": "GHS,ANJUKULIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13661",
            "district": "7",
            "Block": "275",
            "label": "GHS,AVILIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13662",
            "district": "7",
            "Block": "275",
            "label": "RC CONVENT MS-KOSAVAPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13663",
            "district": "7",
            "Block": "275",
            "label": "ST.ANTONY'S HSS,KOSAVAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13664",
            "district": "7",
            "Block": "275",
            "label": "PUMS, M.PANNAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13665",
            "district": "7",
            "Block": "275",
            "label": "HINDU AIDED MS ,THEETHAMPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13666",
            "district": "7",
            "Block": "275",
            "label": "ST.BRITTO HS,PUGAIYILAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13667",
            "district": "7",
            "Block": "275",
            "label": "GHS,SILUVATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13668",
            "district": "7",
            "Block": "275",
            "label": "ST.MARCELIN HSS,VANGAMANOOTHU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13669",
            "district": "7",
            "Block": "275",
            "label": "PUMS, K.AMMAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13670",
            "district": "7",
            "Block": "275",
            "label": "PUMS, NILAPATTYPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13671",
            "district": "7",
            "Block": "275",
            "label": "GHSS,KAMBILIAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13672",
            "district": "7",
            "Block": "275",
            "label": "PUMS, KATTUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13673",
            "district": "7",
            "Block": "275",
            "label": "PUMS, SADAIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13674",
            "district": "7",
            "Block": "275",
            "label": "PUMS, ALAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13675",
            "district": "7",
            "Block": "275",
            "label": "PUMS, S.VALLAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13676",
            "district": "7",
            "Block": "275",
            "label": "GHSS,SENGURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13677",
            "district": "7",
            "Block": "275",
            "label": "PUMS, MARKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13678",
            "district": "7",
            "Block": "275",
            "label": "SRI NARASIMMAN AMS, V.METTUPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13679",
            "district": "7",
            "Block": "275",
            "label": "GHS, MARUNOOTHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13680",
            "district": "7",
            "Block": "275",
            "label": "PUMS, POOVAKIZHAVANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13681",
            "district": "7",
            "Block": "275",
            "label": "PUMS, J.MANIYAKKARANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13682",
            "district": "7",
            "Block": "275",
            "label": "GHS,T.RAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13683",
            "district": "7",
            "Block": "275",
            "label": "PUMS, SEDIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13684",
            "district": "7",
            "Block": "275",
            "label": "GGHSS,GOPALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13685",
            "district": "7",
            "Block": "275",
            "label": "GHSS,VEMBARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13686",
            "district": "7",
            "Block": "275",
            "label": "PUMS, K.AYYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13687",
            "district": "7",
            "Block": "275",
            "label": "PUMS, PERUMAL KOVILPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13688",
            "district": "7",
            "Block": "275",
            "label": "PUMS, KANAVAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13689",
            "district": "7",
            "Block": "275",
            "label": "GHS,SAKKILIANKODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22507",
            "district": "7",
            "Block": "275",
            "label": "GHS, VEERACHINNAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22856",
            "district": "7",
            "Block": "275",
            "label": "DLV PUBLIC SCHOOL, KANAVAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23943",
            "district": "7",
            "Block": "275",
            "label": "UNITY ACADEMY MATRICULATION SCHOOL, RAJAKKAPATTY",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "276": [
        {
            "key": "190",
            "district": "24",
            "Block": "276",
            "label": "36 GST HSS, SANKARANKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "191",
            "district": "24",
            "Block": "276",
            "label": "AMC Govt  Hr. Sec. School, Karivalamvanthanallur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "192",
            "district": "24",
            "Block": "276",
            "label": "ANGEL HS, SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "193",
            "district": "24",
            "Block": "276",
            "label": "ANGEL MATRICULATION SCHOOL SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "194",
            "district": "24",
            "Block": "276",
            "label": "ANNAMALAI MS, SENNIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "195",
            "district": "24",
            "Block": "276",
            "label": "AV HSS , SANKARANKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "196",
            "district": "24",
            "Block": "276",
            "label": "AVK INTERNATIONAL RESIDENTIAL SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "197",
            "district": "24",
            "Block": "276",
            "label": "AVK MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "198",
            "district": "24",
            "Block": "276",
            "label": "GOMATHI AMBAL GHSS( BOYS), SANKARANKOVIL(MODEL)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "199",
            "district": "24",
            "Block": "276",
            "label": "GHSS VEERASIGAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "200",
            "district": "24",
            "Block": "276",
            "label": "Sri Gomathi Ambal Matric Hr. Sec. School, Sankarankovil",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "201",
            "district": "24",
            "Block": "276",
            "label": "GOVT GIRLS HSS, SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "202",
            "district": "24",
            "Block": "276",
            "label": "HARIJAN MS, NOCHIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "203",
            "district": "24",
            "Block": "276",
            "label": "HINDU MS ACHAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "204",
            "district": "24",
            "Block": "276",
            "label": "HINDU MS ,  PANNAIYOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "205",
            "district": "24",
            "Block": "276",
            "label": "HNUC HSS, ARIYANAYAGIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "206",
            "district": "24",
            "Block": "276",
            "label": "IMAM GAZZALI (RAH) ORIENTAL MATRICULATION SCHOOL SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "207",
            "district": "24",
            "Block": "276",
            "label": "JOTHI HR.SEC.SCHOOL, SOLAICHERI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "208",
            "district": "24",
            "Block": "276",
            "label": "MANIMUTHU MS , RAMALINGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "209",
            "district": "24",
            "Block": "276",
            "label": "MMS EAST SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "210",
            "district": "24",
            "Block": "276",
            "label": "MMS , GANDHI NAGAR , SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "211",
            "district": "24",
            "Block": "276",
            "label": "MMS KALAIGNAR SALI SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "212",
            "district": "24",
            "Block": "276",
            "label": "MMS KRISHNASAMI NAGAR SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "213",
            "district": "24",
            "Block": "276",
            "label": "MMS WEST,SANKARANKOVIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "214",
            "district": "24",
            "Block": "276",
            "label": "PUMS, IRUMANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "215",
            "district": "24",
            "Block": "276",
            "label": "PUMS , MANALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "216",
            "district": "24",
            "Block": "276",
            "label": "PUMS PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "217",
            "district": "24",
            "Block": "276",
            "label": "PUMS, SENTHATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "218",
            "district": "24",
            "Block": "276",
            "label": "PUMS, THIRUVETTANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "219",
            "district": "24",
            "Block": "276",
            "label": "PUMS VADANATHAM PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "220",
            "district": "24",
            "Block": "276",
            "label": "PUMS, VEERIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "221",
            "district": "24",
            "Block": "276",
            "label": "PUMS, VEPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "222",
            "district": "24",
            "Block": "276",
            "label": "RC MS, SANKARANKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "223",
            "district": "24",
            "Block": "276",
            "label": "SENGUNTHAR MIDDLE SCHOOL, SANKARANKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "224",
            "district": "24",
            "Block": "276",
            "label": "SENGUNTHAR HS, SUBBULAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "225",
            "district": "24",
            "Block": "276",
            "label": "SEVENTHDAY MATRIC HSS, SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "226",
            "district": "24",
            "Block": "276",
            "label": "S.N.R. HR.SEC.SCHOOL SRIGOMATHIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "227",
            "district": "24",
            "Block": "276",
            "label": "SRI MAHALAKSHMI MATRIC. HR. SEC. SCHOOL, SANKARANKOVIL.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "228",
            "district": "24",
            "Block": "276",
            "label": "SRI MATHA MATRIC SCHOOL, KARIVALAMVANDANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "229",
            "district": "24",
            "Block": "276",
            "label": "ST. JOSEPH MATRIC HSS, SANKARANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "230",
            "district": "24",
            "Block": "276",
            "label": "TDTA MS ARUNACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "231",
            "district": "24",
            "Block": "276",
            "label": "TDTA MS, MANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "232",
            "district": "24",
            "Block": "276",
            "label": "TDTA MS , PURAKUDAIYANPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "233",
            "district": "24",
            "Block": "276",
            "label": "TDTA MS, SUBBULAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "234",
            "district": "24",
            "Block": "276",
            "label": "SRI VAIYAPURI VIDYALAYA MATRIC HR SEC SCHOOL Sankarankovil",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "235",
            "district": "24",
            "Block": "276",
            "label": "VIVEKANANDA SILVER JUBILEE MATRIC HR.SEC.SCHOOL, VEERASIGAMANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "236",
            "district": "24",
            "Block": "276",
            "label": "VOC MS, KUVALAIKANNI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "237",
            "district": "24",
            "Block": "276",
            "label": "VVS HS, SANKARANKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21119",
            "district": "24",
            "Block": "276",
            "label": "VELS PUBLIC SCHOOL VADAKKUPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22654",
            "district": "24",
            "Block": "276",
            "label": "GHS MADATHUPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22655",
            "district": "24",
            "Block": "276",
            "label": "SRI LAKSHMI MATRICULATION SCHOOL ARIYANAYAGIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22731",
            "district": "24",
            "Block": "276",
            "label": "VIVEKANANDHA VIDHYALAYA, PANAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23073",
            "district": "24",
            "Block": "276",
            "label": "VIVEKANANDA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "277": [
        {
            "key": "5556",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADASIRUVALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6743",
            "district": "9",
            "Block": "277",
            "label": "JAYAM MATRIC HIGHER SECONDARY SCHOOL, SANKARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10596",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10597",
            "district": "9",
            "Block": "277",
            "label": "CHRIST SPECIAL HIGH SCHOOL SANKARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10598",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT GIRLS HIGHER  SECONDARY SCHOOL SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10599",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10600",
            "district": "9",
            "Block": "277",
            "label": "ST.JOSEPH ACADEMY  MATRIC HIGHER SECONDARY SCHOOL SANKARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10601",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTU VANNANJUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10602",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  DEVAPANDALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10603",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  DEVAPANDALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10604",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  S.KULATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10605",
            "district": "9",
            "Block": "277",
            "label": "ADAIKALA ANNAI HIGHER SECONDARY SCHOOL VIRIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10606",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SOUNDARAVALLI PALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10607",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIYAGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10608",
            "district": "9",
            "Block": "277",
            "label": "ALAGAPPA AIDED MIDDLE SCHOOL POOTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10609",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL PAVALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10610",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMBARAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10611",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL  POIKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10612",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL ARASAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10613",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ARASAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10614",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOSAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10615",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL  SESHASAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10616",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NEDUMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10617",
            "district": "9",
            "Block": "277",
            "label": "BHARATHI MATRICULATION SCHOOL, NEDUMANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10618",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL PALAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10619",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MANJAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10620",
            "district": "9",
            "Block": "277",
            "label": "OXFORD MATRIC. HIGHER SECONDARY SCHOOL VADASEMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10621",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MURARBAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10622",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT GIRLS HIGH SCHOOL MURARBAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10623",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SOLAMPATTU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10624",
            "district": "9",
            "Block": "277",
            "label": "UNAIDED ROMAN CATHALIC MIDDLE SCHOOL  SOLAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10625",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL LAKKINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10626",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAVATHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10627",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RANGAPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10628",
            "district": "9",
            "Block": "277",
            "label": "ANNAI TERESA MATRIC HIGHER SECONDARY SCHOOL  RANGAPPANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10629",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PUDHUPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10630",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MOONGILTHURAIPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10631",
            "district": "9",
            "Block": "277",
            "label": "THE KCSM MATRIC HIGHER SECONDARY SCHOOL MOONKILTHURAIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10632",
            "district": "9",
            "Block": "277",
            "label": "ST. DONBOSCO MATRIC HIGHER SECONDARY SCHOOL  MOONGILTHURAIPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10633",
            "district": "9",
            "Block": "277",
            "label": "ST.MARY'S MATRICULATION SCHOOL, PORUVALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10634",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PORASAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10635",
            "district": "9",
            "Block": "277",
            "label": "ROMAN CATHALIC MIDDLE SCHOOL ARULAMPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10636",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL (URUDHU)VADAPONPARAPPI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10637",
            "district": "9",
            "Block": "277",
            "label": "SARVODAYA MATRIC HIGHER SECONDARY SCHOOL, VADAPONPARAPPI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10638",
            "district": "9",
            "Block": "277",
            "label": "AIDED MIDDLE SCHOOL VADAKEERANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10639",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  BRAMMAKUNDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10640",
            "district": "9",
            "Block": "277",
            "label": "ST.MICHAEL'S HIGH SCHOOL MICHAELPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10641",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MANALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10642",
            "district": "9",
            "Block": "277",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL POUNJIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10643",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SELLAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10644",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL PERUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10645",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL CHINNAPALAPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10646",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL MATTAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10647",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL POTTIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10648",
            "district": "9",
            "Block": "277",
            "label": "GOOD SHEPHERD HIGHER SECONDARY SCHOOL SERAPATTU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10649",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH  SCHOOL  PUDHUPALAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10650",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL MIDDLE SCHOOL PARANGINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21203",
            "district": "9",
            "Block": "277",
            "label": "MODEL HIGHER SECONDARY SCHOOL MOOLAKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21453",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT HIGH SCHOOL URANGANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21790",
            "district": "9",
            "Block": "277",
            "label": "NEW POWER MATRIC HIGHER SECONDARY SCHOOL SANKARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21791",
            "district": "9",
            "Block": "277",
            "label": "KALAIMAHAL  MATRIC HIGHER SECONDARY SCHOOL,  MANJAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21792",
            "district": "9",
            "Block": "277",
            "label": "CHAKRA VIDYALAYA CBSE SCHOOL VADASEMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21793",
            "district": "9",
            "Block": "277",
            "label": "VIVEKANANDA HIGHER SECONDARY SCHOOL SOLAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21969",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL MOOLAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22760",
            "district": "9",
            "Block": "277",
            "label": "SUBAM INTERNATIONAL (CBSE) SCHOOL VADAPONPARAPPI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23167",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL WELFARE HIGH SCHOOL KILAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23168",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PACHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23306",
            "district": "9",
            "Block": "277",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGH SCHOOL PARANGINATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23944",
            "district": "9",
            "Block": "277",
            "label": "ST. JOSEPH ACADEMY INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "278": [
        {
            "key": "2736",
            "district": "22",
            "Block": "278",
            "label": "BRINDAVAN MATRIC SCHOOL THEVOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3198",
            "district": "22",
            "Block": "278",
            "label": "PSF MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3484",
            "district": "22",
            "Block": "278",
            "label": "Shri Vidya Saagar Matric School, Nagichettipatti",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4019",
            "district": "22",
            "Block": "278",
            "label": "AKASH VIDHYA BHAVAN MATRIC HR SEC SCHOOL, SANKARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6661",
            "district": "22",
            "Block": "278",
            "label": "PSG MATRICULATION HIGHER SECONDARY SCHOOL SANKARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11607",
            "district": "22",
            "Block": "278",
            "label": "PUMS SANKARI IIIRD WARD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11608",
            "district": "22",
            "Block": "278",
            "label": "PUMS NAGICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11609",
            "district": "22",
            "Block": "278",
            "label": "PUMS OONJAKORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11610",
            "district": "22",
            "Block": "278",
            "label": "GHSS NATTUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11611",
            "district": "22",
            "Block": "278",
            "label": "GHSS B SANKARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11612",
            "district": "22",
            "Block": "278",
            "label": "GHSS G SANKARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11613",
            "district": "22",
            "Block": "278",
            "label": "SRE VIDYASAAGAR HR. SEC. SCHOOL, NAGICHETTIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11614",
            "district": "22",
            "Block": "278",
            "label": "GHS AKKAMAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11615",
            "district": "22",
            "Block": "278",
            "label": "GHSS THEVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11616",
            "district": "22",
            "Block": "278",
            "label": "PUMS OLAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11617",
            "district": "22",
            "Block": "278",
            "label": "GHS ARA KULLAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11618",
            "district": "22",
            "Block": "278",
            "label": "GHSS ARA CHETTIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11619",
            "district": "22",
            "Block": "278",
            "label": "PUMS A.REDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11620",
            "district": "22",
            "Block": "278",
            "label": "PUMS MOTHAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11621",
            "district": "22",
            "Block": "278",
            "label": "GHS CHINNAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11622",
            "district": "22",
            "Block": "278",
            "label": "PUMS CHETTIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11623",
            "district": "22",
            "Block": "278",
            "label": "PUMS MANJAKKALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11624",
            "district": "22",
            "Block": "278",
            "label": "PUMS DEVANNAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11625",
            "district": "22",
            "Block": "278",
            "label": "K.G.B.V. DEVANNAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11626",
            "district": "22",
            "Block": "278",
            "label": "PUMS IRUGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11627",
            "district": "22",
            "Block": "278",
            "label": "PUMS OKKILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11628",
            "district": "22",
            "Block": "278",
            "label": "PUMS VATRAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11629",
            "district": "22",
            "Block": "278",
            "label": "PUMS KAVERIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11630",
            "district": "22",
            "Block": "278",
            "label": "PUMS KAIKOLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11631",
            "district": "22",
            "Block": "278",
            "label": "PUMS PONNAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11632",
            "district": "22",
            "Block": "278",
            "label": "GHS KATHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11633",
            "district": "22",
            "Block": "278",
            "label": "PUMS VENGIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11634",
            "district": "22",
            "Block": "278",
            "label": "P.U.M.S.PULLAGOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11635",
            "district": "22",
            "Block": "278",
            "label": "GHS VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21213",
            "district": "22",
            "Block": "278",
            "label": "MODEL SCHOOL , SANKARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23548",
            "district": "22",
            "Block": "278",
            "label": "SRI KONGU MATRICULATION SCHOOL, SANKARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23548",
            "district": "22",
            "Block": "278",
            "label": "SRI KONGU MATRICULATION SCHOOL, SANKARI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23945",
            "district": "22",
            "Block": "278",
            "label": "PUMS VENGIPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "279": [
        {
            "key": "755",
            "district": "4",
            "Block": "279",
            "label": "ADWAITH G.N.S MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "782",
            "district": "4",
            "Block": "279",
            "label": "PERKS MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23380",
            "district": "4",
            "Block": "279",
            "label": "Jay Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "1120",
            "district": "4",
            "Block": "279",
            "label": "LITTLE FLOWER MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1162",
            "district": "4",
            "Block": "279",
            "label": "ANNAI VELANKANNI MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1218",
            "district": "4",
            "Block": "279",
            "label": "ARM MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1405",
            "district": "4",
            "Block": "279",
            "label": "G.RAMASWAMY NAIDU MATRIC.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1424",
            "district": "4",
            "Block": "279",
            "label": "CMS UPPILIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1495",
            "district": "4",
            "Block": "279",
            "label": "SRI RAMAKRISHNA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1867",
            "district": "4",
            "Block": "279",
            "label": "VENKATALAKSHMI MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1981",
            "district": "4",
            "Block": "279",
            "label": "GRG MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2003",
            "district": "4",
            "Block": "279",
            "label": "SRI JAYENDRA SARASWATHY VIDYALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2058",
            "district": "4",
            "Block": "279",
            "label": "HINDUSTHAN MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2532",
            "district": "4",
            "Block": "279",
            "label": "SRI RAMAKRISHNA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2547",
            "district": "4",
            "Block": "279",
            "label": "CMS KRISHNARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2550",
            "district": "4",
            "Block": "279",
            "label": "KALAIMAGAL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2569",
            "district": "4",
            "Block": "279",
            "label": "VICTORY VIDHYALAYA MAT. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2894",
            "district": "4",
            "Block": "279",
            "label": "A B C MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3078",
            "district": "4",
            "Block": "279",
            "label": "CMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3200",
            "district": "4",
            "Block": "279",
            "label": "N M MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3231",
            "district": "4",
            "Block": "279",
            "label": "G.R.DAMODARAN MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3305",
            "district": "4",
            "Block": "279",
            "label": "RUBY MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3671",
            "district": "4",
            "Block": "279",
            "label": "PUMS KOTTAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3818",
            "district": "4",
            "Block": "279",
            "label": "PERIYANAYAKI AMMAN  MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3944",
            "district": "4",
            "Block": "279",
            "label": "THAVATHIRU KANDASAMY SWAMIGAL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3975",
            "district": "4",
            "Block": "279",
            "label": "CMS MASAKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4034",
            "district": "4",
            "Block": "279",
            "label": "NATIONAL MODEL MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4291",
            "district": "4",
            "Block": "279",
            "label": "SHREE VISHNU VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4316",
            "district": "4",
            "Block": "279",
            "label": "PUMS NEHRU NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4362",
            "district": "4",
            "Block": "279",
            "label": "CMS.NEELIKKONAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4663",
            "district": "4",
            "Block": "279",
            "label": "PUMS VEERIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4947",
            "district": "4",
            "Block": "279",
            "label": "ST.XAVIER'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4978",
            "district": "4",
            "Block": "279",
            "label": "SREE DHARMASASTHA MAT. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5076",
            "district": "4",
            "Block": "279",
            "label": "ST JOSEPH'S MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5984",
            "district": "4",
            "Block": "279",
            "label": "C R R MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6334",
            "district": "4",
            "Block": "279",
            "label": "THE BRIDGEWOODS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6527",
            "district": "4",
            "Block": "279",
            "label": "PUMS CHINNAMETTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12828",
            "district": "4",
            "Block": "279",
            "label": "GANDHI CENTENARY MEMORIAL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12829",
            "district": "4",
            "Block": "279",
            "label": "THIYAGI N.G.RAMASWAMY MEMORIAL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12830",
            "district": "4",
            "Block": "279",
            "label": "SRI GOPAL NAIDU HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12831",
            "district": "4",
            "Block": "279",
            "label": "KASTHURBA GANDHI ORAL SCHOOL HEART FOR THE HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12832",
            "district": "4",
            "Block": "279",
            "label": "CRESCENT MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12833",
            "district": "4",
            "Block": "279",
            "label": "CHS VARADHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12834",
            "district": "4",
            "Block": "279",
            "label": "PSG SARVAJANA HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12835",
            "district": "4",
            "Block": "279",
            "label": "CHSS,PEELAMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12836",
            "district": "4",
            "Block": "279",
            "label": "PSGR KRISHNAMMAL GIRLS.HR.SEC.SCHOOL PEELAMEDU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12837",
            "district": "4",
            "Block": "279",
            "label": "PSG PUBLIC SCHOOLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12838",
            "district": "4",
            "Block": "279",
            "label": "ST JOSEPH'S BOYS AIDED MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12839",
            "district": "4",
            "Block": "279",
            "label": "ST.PHILOMENA'S HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12840",
            "district": "4",
            "Block": "279",
            "label": "CHSS UDAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12841",
            "district": "4",
            "Block": "279",
            "label": "KENDRIYA VIDYALAYA PULIAKULAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12842",
            "district": "4",
            "Block": "279",
            "label": "GEETHANJALEE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12843",
            "district": "4",
            "Block": "279",
            "label": "C.M.S SIHS COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12844",
            "district": "4",
            "Block": "279",
            "label": "RAJALAKSHMI AIDED HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12845",
            "district": "4",
            "Block": "279",
            "label": "SNVNV GOVERNMENT BOYS HIGH SCHOOL SINGANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12846",
            "district": "4",
            "Block": "279",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL SINGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12847",
            "district": "4",
            "Block": "279",
            "label": "NADAR HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12848",
            "district": "4",
            "Block": "279",
            "label": "GOVERNMENT BOYS HIGHER SECONARY SCHOOL ONDIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12849",
            "district": "4",
            "Block": "279",
            "label": "R.C GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ONDIPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12850",
            "district": "4",
            "Block": "279",
            "label": "A R N MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12851",
            "district": "4",
            "Block": "279",
            "label": "ISLAMIYAH MAT.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12852",
            "district": "4",
            "Block": "279",
            "label": "SPBN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12853",
            "district": "4",
            "Block": "279",
            "label": "BVM GLOBAL CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12854",
            "district": "4",
            "Block": "279",
            "label": "VIVEKANANDA VIDYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12855",
            "district": "4",
            "Block": "279",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12856",
            "district": "4",
            "Block": "279",
            "label": "GHSS SSKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12857",
            "district": "4",
            "Block": "279",
            "label": "K.V. MATRIC HR SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12858",
            "district": "4",
            "Block": "279",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12859",
            "district": "4",
            "Block": "279",
            "label": "SWATHANTHRA HR.SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12860",
            "district": "4",
            "Block": "279",
            "label": "GHSS VELLAMADAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12861",
            "district": "4",
            "Block": "279",
            "label": "PUMS KEERANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12862",
            "district": "4",
            "Block": "279",
            "label": "PUMS-CHINNAVEDAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12863",
            "district": "4",
            "Block": "279",
            "label": "PUMS UDAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12864",
            "district": "4",
            "Block": "279",
            "label": "T R A HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12865",
            "district": "4",
            "Block": "279",
            "label": "COIMBATORE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12866",
            "district": "4",
            "Block": "279",
            "label": "KAUMARAM SUSHILA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12867",
            "district": "4",
            "Block": "279",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12868",
            "district": "4",
            "Block": "279",
            "label": "PUMS CHERAN MAANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12869",
            "district": "4",
            "Block": "279",
            "label": "R J MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12870",
            "district": "4",
            "Block": "279",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VILANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12871",
            "district": "4",
            "Block": "279",
            "label": "VIDHYA NIKETAN MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12872",
            "district": "4",
            "Block": "279",
            "label": "PUMS SHAJAHAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12873",
            "district": "4",
            "Block": "279",
            "label": "VIMAL JYOTHI CONVENT MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12874",
            "district": "4",
            "Block": "279",
            "label": "ELANGO MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12875",
            "district": "4",
            "Block": "279",
            "label": "GOVERNMENT HIGH SCHOOL, SARAVANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12876",
            "district": "4",
            "Block": "279",
            "label": "VIVEKAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12877",
            "district": "4",
            "Block": "279",
            "label": "LISIEUX CMI PUBLIC SCHOOL, SARAVANAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12878",
            "district": "4",
            "Block": "279",
            "label": "GHS IDIGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12879",
            "district": "4",
            "Block": "279",
            "label": "PUMS VATTAMALAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12880",
            "district": "4",
            "Block": "279",
            "label": "VCV GHSS VELLAKINAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12881",
            "district": "4",
            "Block": "279",
            "label": "CRAYONS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12882",
            "district": "4",
            "Block": "279",
            "label": "MANCHESTER INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12883",
            "district": "4",
            "Block": "279",
            "label": "CHANDRA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12884",
            "district": "4",
            "Block": "279",
            "label": "GHSS KALAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12885",
            "district": "4",
            "Block": "279",
            "label": "PSGG KANYAGURUKULAM HR. SEC. SCHOOL FOR GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12886",
            "district": "4",
            "Block": "279",
            "label": "SRI KK NAIDU HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12887",
            "district": "4",
            "Block": "279",
            "label": "SRIMATHI D. PADMAVATHI AMMAL HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12888",
            "district": "4",
            "Block": "279",
            "label": "SUGUNA PIP SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12889",
            "district": "4",
            "Block": "279",
            "label": "THE IDEAL MOUNT LITERA ZEE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12890",
            "district": "4",
            "Block": "279",
            "label": "SRI VIVEKANANDA MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12891",
            "district": "4",
            "Block": "279",
            "label": "CHANDRAKANTHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12892",
            "district": "4",
            "Block": "279",
            "label": "ANAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12893",
            "district": "4",
            "Block": "279",
            "label": "PUMS-VELLANIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20885",
            "district": "4",
            "Block": "279",
            "label": "GRD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20968",
            "district": "4",
            "Block": "279",
            "label": "KAUMARAM PRASHANTHI  ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21047",
            "district": "4",
            "Block": "279",
            "label": "REEDS WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21080",
            "district": "4",
            "Block": "279",
            "label": "ALCHEMY PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21081",
            "district": "4",
            "Block": "279",
            "label": "DOBBS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21082",
            "district": "4",
            "Block": "279",
            "label": "THE NGP SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21128",
            "district": "4",
            "Block": "279",
            "label": "SAMSKAARA ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21182",
            "district": "4",
            "Block": "279",
            "label": "NATIONAL MODEL SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21314",
            "district": "4",
            "Block": "279",
            "label": "SRI JAYENDRA SARASWATHY VIDYALAYA   SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21869",
            "district": "4",
            "Block": "279",
            "label": "SNS ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21870",
            "district": "4",
            "Block": "279",
            "label": "NAGARATHINAM INTERNATIONAL SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21871",
            "district": "4",
            "Block": "279",
            "label": "ADITHYA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21872",
            "district": "4",
            "Block": "279",
            "label": "DON BOSCO SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22142",
            "district": "4",
            "Block": "279",
            "label": "GITHANJALI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22143",
            "district": "4",
            "Block": "279",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Ondipudur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22480",
            "district": "4",
            "Block": "279",
            "label": "JEEVANS NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22481",
            "district": "4",
            "Block": "279",
            "label": "ADITHYA PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22813",
            "district": "4",
            "Block": "279",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22814",
            "district": "4",
            "Block": "279",
            "label": "EINSTEIN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22815",
            "district": "4",
            "Block": "279",
            "label": "BRILLIANT VIDYA BHAVAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22816",
            "district": "4",
            "Block": "279",
            "label": "THE AALAM INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22817",
            "district": "4",
            "Block": "279",
            "label": "INDIAN PUBLIC CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22979",
            "district": "4",
            "Block": "279",
            "label": "SSVM SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22980",
            "district": "4",
            "Block": "279",
            "label": "PERKS PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23059",
            "district": "4",
            "Block": "279",
            "label": "SRI RAMAKRISHNA CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23060",
            "district": "4",
            "Block": "279",
            "label": "RAK'S PALLIKKOODAM SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23089",
            "district": "4",
            "Block": "279",
            "label": "RAK'S PALLIKKOODAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23090",
            "district": "4",
            "Block": "279",
            "label": "MANCHESTER INTERNATIONAL SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23098",
            "district": "4",
            "Block": "279",
            "label": "KOVAI VIDYA MANDHIR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23180",
            "district": "4",
            "Block": "279",
            "label": "ADITHYA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23282",
            "district": "4",
            "Block": "279",
            "label": "VIBGYOR HIGH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23308",
            "district": "4",
            "Block": "279",
            "label": "VIDHYA NIKETAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23946",
            "district": "4",
            "Block": "279",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23947",
            "district": "4",
            "Block": "279",
            "label": "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23948",
            "district": "4",
            "Block": "279",
            "label": "SRI RAMAKRISHNA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23949",
            "district": "4",
            "Block": "279",
            "label": "PSG MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23950",
            "district": "4",
            "Block": "279",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23951",
            "district": "4",
            "Block": "279",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "23952",
            "district": "4",
            "Block": "279",
            "label": "CHANDRAMARI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23953",
            "district": "4",
            "Block": "279",
            "label": "SAMASHTI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23954",
            "district": "4",
            "Block": "279",
            "label": "HINDUSTHAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "280": [
        {
            "key": "2121",
            "district": "28",
            "Block": "280",
            "label": "PUMS, THATCHANVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2851",
            "district": "28",
            "Block": "280",
            "label": "Sri Kanchi Sankara Bagavathi Vidyalaya Matric Hr.Sec.School, Kommadikottai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2977",
            "district": "28",
            "Block": "280",
            "label": "TDTA MS Kumaran Vila",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3008",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, POOVUDAYARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3319",
            "district": "28",
            "Block": "280",
            "label": "HENRIY MATRIC HR.SEC. SCHOOL, SATHANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3550",
            "district": "28",
            "Block": "280",
            "label": "RC MS ,THAILAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3956",
            "district": "28",
            "Block": "280",
            "label": "PUMS Panaivilai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4821",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS,SATHYANAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5537",
            "district": "28",
            "Block": "280",
            "label": "PUMS, GNANAIYARKUDIERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6120",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS,PALANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6168",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MIDDLE SCHOO,KADATCHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6336",
            "district": "28",
            "Block": "280",
            "label": "RC MS,CHETTIVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6676",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, KANKANIYARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6684",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, THARUVAI WEST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19453",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, THERIPANAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19454",
            "district": "28",
            "Block": "280",
            "label": "AVE MARIA MATRIC HR.SEC.SCHOOL,PANNAMPARAI JUNCTION",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19455",
            "district": "28",
            "Block": "280",
            "label": "TDTA RANJI AARON MEMORIAL HIGHER SECONDARY SCHOOL,ANANDAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19456",
            "district": "28",
            "Block": "280",
            "label": "ST. Micheal's High School, Nochikulam",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19457",
            "district": "28",
            "Block": "280",
            "label": "RC MS .NEDUNKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19458",
            "district": "28",
            "Block": "280",
            "label": "CMS EVANGELICAL HIGH SCHOOL, KALUNGUVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19459",
            "district": "28",
            "Block": "280",
            "label": "GHSS KOMBANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19460",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, SIRAPPUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19461",
            "district": "28",
            "Block": "280",
            "label": "RC MS, CHIDAMBARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19462",
            "district": "28",
            "Block": "280",
            "label": "GHS, PUDUKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19463",
            "district": "28",
            "Block": "280",
            "label": "SRI MUTHARAMMAN HINDU MS , VIJAYARAMAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19464",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, ANBIN NAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19465",
            "district": "28",
            "Block": "280",
            "label": "St.Michael's Higher secondary school, Mudalur",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19466",
            "district": "28",
            "Block": "280",
            "label": "SRI SARATHA SACRED HEART MS, PALLAKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19467",
            "district": "28",
            "Block": "280",
            "label": "SRI PARVATHI MS, PITCHIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19468",
            "district": "28",
            "Block": "280",
            "label": "GHS SUNDANKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19469",
            "district": "28",
            "Block": "280",
            "label": "TNDTA ALL SAINTS MS,POHLIARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19470",
            "district": "28",
            "Block": "280",
            "label": "ST.MARYS HR SEC SCHOOL,  POTHAKALANVILLAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19471",
            "district": "28",
            "Block": "280",
            "label": "SSN., GOVT.HSS KOMMADIKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23377",
            "district": "28",
            "Block": "280",
            "label": "St. John's NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Primary School"
        },
        {
            "key": "19472",
            "district": "28",
            "Block": "280",
            "label": "Manalmatha Hr.Sec.School, Sokkankudieruppu",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19473",
            "district": "28",
            "Block": "280",
            "label": "TNDTA MS, NALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19474",
            "district": "28",
            "Block": "280",
            "label": "St.Anne's High School, Thattarmadam",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19475",
            "district": "28",
            "Block": "280",
            "label": "TNTDA MS,ATHISAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19476",
            "district": "28",
            "Block": "280",
            "label": "ST JOSEPH'S RC MS KADAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19477",
            "district": "28",
            "Block": "280",
            "label": "St,Antony's Middle School, Puchikadu",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19478",
            "district": "28",
            "Block": "280",
            "label": "PUMS, USARATHUKUDIYERUPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19479",
            "district": "28",
            "Block": "280",
            "label": "PUMS,PUTHANTHARUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19480",
            "district": "28",
            "Block": "280",
            "label": "Little Flower High School, Periyathalai",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19481",
            "district": "28",
            "Block": "280",
            "label": "GHSS PADUKKAPATHU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19482",
            "district": "28",
            "Block": "280",
            "label": "SRI MARIAMMAN HINDU HR. SEC. SCHOOL , SATHANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19483",
            "district": "28",
            "Block": "280",
            "label": "TNDTA RMP PULAMADAN CHETTIAR NATIONAL HIGHER SECONDARY SCHOOL , SATHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19484",
            "district": "28",
            "Block": "280",
            "label": "A E IBRAHIM MiDDLE SCHOOL, SATHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19485",
            "district": "28",
            "Block": "280",
            "label": "SACRED HEART HSS,SATHANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19486",
            "district": "28",
            "Block": "280",
            "label": "ST JOSEPH GIRLS HIGHER SECONDARY SCHOOL, SATTANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21411",
            "district": "28",
            "Block": "280",
            "label": "GHS PANNAMPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21977",
            "district": "28",
            "Block": "280",
            "label": "MARY IMMACULATE MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "281": [
        {
            "key": "481",
            "district": "8",
            "Block": "281",
            "label": "BHARATHI MATRICULATION SCHOOL, KEMPANAICKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "706",
            "district": "8",
            "Block": "281",
            "label": "SRI RAGAVENDARA MATRICULATION HIGHER SECONDARY SCHOOL,  SATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "713",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONAMOOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "759",
            "district": "8",
            "Block": "281",
            "label": "THE NEST MATRICULATION HIGHER SECONDARY SCHOOL, PUTHUVADAVALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "775",
            "district": "8",
            "Block": "281",
            "label": "BANNARIAMMAN VIDYA NIKETAN MATRIC, ALATHUKOMBAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "838",
            "district": "8",
            "Block": "281",
            "label": "MUNICIPAL MIDDLE SSCHOOL, SATHYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "855",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PALAYAKALAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "902",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SATHUMUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "922",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  PUDHUKUYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "950",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1014",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NANJAPPA GOUNEN PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1038",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KALIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1039",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUNDIPOMMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1060",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,   KONDAPPANAIKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1085",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RAMAPAYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1118",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, EKKATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1164",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ALATHUKOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1176",
            "district": "8",
            "Block": "281",
            "label": "RANK INTERNATIONAL SCHOOL, ARIYAPPAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1187",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  ARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1202",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  IRUTTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1204",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KARALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1240",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KULLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1296",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOMBAITHOTTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1307",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PASUVAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1386",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1389",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANAIKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1408",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADAGANALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1616",
            "district": "8",
            "Block": "281",
            "label": "SARU MATRIC HIGHER SECONDARY SCHOOL, KOTTUVERAMPALAYAM,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1777",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  PERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1918",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KURUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1941",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UKKARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1976",
            "district": "8",
            "Block": "281",
            "label": "BANNARIAMMAN PUBLIC SCHOOL(CBSE),SATHYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2146",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2340",
            "district": "8",
            "Block": "281",
            "label": "LITTLE FLOWER MATRIC HIGHER SECONDARY SCHOOL, SATHYAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2505",
            "district": "8",
            "Block": "281",
            "label": "GTRMS, NAGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3017",
            "district": "8",
            "Block": "281",
            "label": "SOUNDRAM VIDYALAYA MATRICULATION SCHOOL, RAJAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4368",
            "district": "8",
            "Block": "281",
            "label": "ST. JAMES MATRIC HS SCHOOL, SATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4381",
            "district": "8",
            "Block": "281",
            "label": "ST. JOSEPH'S MATRICULATION HIGH SCHOOL, SATHYAMANGALAM.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4671",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAVALAKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4808",
            "district": "8",
            "Block": "281",
            "label": "SRT UNIVERSAL MATRIC HIGHER SECONDARY SCHOOL, KONAMOOLAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4911",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIKIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5594",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERIYASALATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12310",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL, VEDARNAGAR,PUTHUVADAVALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12311",
            "district": "8",
            "Block": "281",
            "label": "KASTHURIBA HIGHER SECONDARY SCHOOL, RAJANNAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12312",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL , CHIKKARASAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12313",
            "district": "8",
            "Block": "281",
            "label": "GOVT. HIGH SCHOOL, INDIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12314",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL, MAKKINANKOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12315",
            "district": "8",
            "Block": "281",
            "label": "GOVT. HIGH SCHOOL, IKKARAINEGAMAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12316",
            "district": "8",
            "Block": "281",
            "label": "KASTURIBA GANDHI BALIKA VIDHYALAYA, VEDARNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12317",
            "district": "8",
            "Block": "281",
            "label": "BSC, GOVERNMENT HIGHER SECONDARY SCHOOL, KEMBANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12318",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MALLIAMMANDURGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12319",
            "district": "8",
            "Block": "281",
            "label": "GTRMS, BATHRIBADUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12320",
            "district": "8",
            "Block": "281",
            "label": "GOVT. HIGHER SEC. SCHOOL, BASUVANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12321",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KALKADAMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12322",
            "district": "8",
            "Block": "281",
            "label": "ST.JOHN DE BRITTO GIRLS HIGH SCHOOL, SATHIYAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12323",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SATHYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12324",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT GIRLS MODEL HIGHER SECONDARY SCHOOL, SATHYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12325",
            "district": "8",
            "Block": "281",
            "label": "MUNICIPAL HIGH SCHOOL, RANGASAMUDHIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12326",
            "district": "8",
            "Block": "281",
            "label": "SRI RAGAVENDRA HR SEC. SCHOOL, SATHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12327",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL, MILLMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21218",
            "district": "8",
            "Block": "281",
            "label": "GOVT. MODEL HIGHER SECONDARY SCHOOL, SATHYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21252",
            "district": "8",
            "Block": "281",
            "label": "SR NACHIMUTHU GOUNDER MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21657",
            "district": "8",
            "Block": "281",
            "label": "SANTHOME INTERNATIONAL SCHOOL, SENBAGAPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21960",
            "district": "8",
            "Block": "281",
            "label": "ROMAN CATHOLIC HIGH SCHOOL, GUNDRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22468",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL, GUJJAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22872",
            "district": "8",
            "Block": "281",
            "label": "GOVERNMENT HIGH SCHOOL,MAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23955",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KOMARAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23956",
            "district": "8",
            "Block": "281",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "282": [
        {
            "key": "2183",
            "district": "38",
            "Block": "282",
            "label": "KCAD CHIDAMBARAM GNANAGIRI MATRICULATION HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2200",
            "district": "38",
            "Block": "282",
            "label": "OXFORD MATRICULATION SCHOOL, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2247",
            "district": "38",
            "Block": "282",
            "label": "DR. RADHAKRISHNAN MATRIC HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2404",
            "district": "38",
            "Block": "282",
            "label": "SRI VENKATESWARA MATRICULATION SCHOOL, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2733",
            "district": "38",
            "Block": "282",
            "label": "LALITHA LAL BAHADUR SHASTRI PUBLIC SCHOOL, SADAYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3972",
            "district": "38",
            "Block": "282",
            "label": "SOUTHSIDE MATRICULATION HIGHER SECONDARY SCHOOL, METTAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18979",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, CHINNAKKAMANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18980",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, METTAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18981",
            "district": "38",
            "Block": "282",
            "label": "HINDU MIDDLE SCHOOL, VENKATACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18982",
            "district": "38",
            "Block": "282",
            "label": "KAMMA MAHAJANA TRUST GIRLS HIGHER SECONDARY SCHOOL, VENKATACHALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18983",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PADANTHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18984",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AYAN CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18985",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAMBIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18986",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, A.RAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18987",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.CHOKKALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18988",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MUTHARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18989",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SIRUKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18990",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, IRUKKANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18991",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, NATHATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        { 
            "key":"24124", "district":"38", 
            "Block":"282", "label": "sun indiya matriculation higher secondary school", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "18992",
            "district": "38",
            "Block": "282",
            "label": "S.R.GOVERNMENT HIGHER SECONDARY SCHOOL, N.METTUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18993",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, POTHIREDDIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18994",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, PERIYA KOLLAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18995",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, THERKUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18996",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, O.METTUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18997",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, KANMAISURANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18998",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, PUDHUSURANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18999",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, N.SUBBAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19000",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SIVANANAINTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19001",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, THOTTILOVANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19002",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19003",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UPPATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19004",
            "district": "38",
            "Block": "282",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19005",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, MUTHALNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19006",
            "district": "38",
            "Block": "282",
            "label": "MPL. HIGHER SECONDARY SCHOOL, MELAGANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19007",
            "district": "38",
            "Block": "282",
            "label": "MPL. MIDDLE SCHOOL, SATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19008",
            "district": "38",
            "Block": "282",
            "label": "S. GURUSAMY NADAR KRISHNAMMAL MEMORIAL MIDDLE SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19009",
            "district": "38",
            "Block": "282",
            "label": "S.H.N. EDWARD MIDDLE SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19010",
            "district": "38",
            "Block": "282",
            "label": "T.E.L.C. MIDDLE SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19011",
            "district": "38",
            "Block": "282",
            "label": "ST. THERESA HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19012",
            "district": "38",
            "Block": "282",
            "label": "ST. STANISLAUS HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19013",
            "district": "38",
            "Block": "282",
            "label": "AYIRA VYSIA BOYS HR SEC SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19014",
            "district": "38",
            "Block": "282",
            "label": "S.H.N. ETHEL HARVEY GIRLS HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19015",
            "district": "38",
            "Block": "282",
            "label": "S.H.N. EDWARD HIGHER SECONDARY SCHOOL, SATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21342",
            "district": "38",
            "Block": "282",
            "label": "SRIKARA VIDHYA MANDHIR SENIOR SECONDARY SCHOOL, SIVANANAINTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21408",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, N.G.O.COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21409",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, K.METTUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21858",
            "district": "38",
            "Block": "282",
            "label": "SOUTHSIDE INTERNATIONAL SCHOOL, METTAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21937",
            "district": "38",
            "Block": "282",
            "label": "GVJ INTERNATIONAL SCHOOL.,N.VENKADESWARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22785",
            "district": "38",
            "Block": "282",
            "label": "M.M VIDYASHRAM, SATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22904",
            "district": "38",
            "Block": "282",
            "label": "GOVERNMENT HIGH SCHOOL, MELAPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23641",
            "district": "38",
            "Block": "282",
            "label": "LITTLE BUDS UNIQUE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23641",
            "district": "38",
            "Block": "282",
            "label": "LITTLE BUDS UNIQUE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23957",
            "district": "38",
            "Block": "282",
            "label": "SATTUR KMT VIDHYALAYA",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "283": [
        {
            "key": "2351",
            "district": "14",
            "Block": "283",
            "label": "R.C.M.S. P.SETTIAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2841",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S. E.KOTTAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2844",
            "district": "14",
            "Block": "283",
            "label": "BHARATHIYAR MATRICULATION  HIGHER SECONDARY  SCHOOL, ELUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3331",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, UTHAPURAM - I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3780",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S. ATHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4957",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, UTHAPURAM - II",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18149",
            "district": "14",
            "Block": "283",
            "label": "GOVT HR SEC SCHOOL, SEDAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18150",
            "district": "14",
            "Block": "283",
            "label": "GOVT KALLAR HR SEC SCHOOL, POOSALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18151",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, CHINNAKATTALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18152",
            "district": "14",
            "Block": "283",
            "label": "GOVERNMENT KALLAR HR SEC  SCHOOL, PERUNGAMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18153",
            "district": "14",
            "Block": "283",
            "label": "GOVT BOYS HR SEC SCHOOL, ELUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18154",
            "district": "14",
            "Block": "283",
            "label": "GOVT GIRLS HR SEC SCHOOL, ELUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18155",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S. E.GOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18156",
            "district": "14",
            "Block": "283",
            "label": "VIVEKANANDA MIDDILE SCHOOL, ELUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18157",
            "district": "14",
            "Block": "283",
            "label": "GOVT HR SEC SCHOOL, M. KALLUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18158",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, CHELLAYEPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18159",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, SEELANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18160",
            "district": "14",
            "Block": "283",
            "label": "G. K. MIDDLE SCHOOL MELATHIRUMANICKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18161",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, ATHANKARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18162",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, T.RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18163",
            "district": "14",
            "Block": "283",
            "label": "Govt.ADW. Middle School, VADAKATHIYANPATTI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18164",
            "district": "14",
            "Block": "283",
            "label": "GOVT KALLAR HR SEC SCHOOL, THADAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18165",
            "district": "14",
            "Block": "283",
            "label": "RM P S RAMIAH NADAR HIGHER SECONDARY SCHOOL  ATHIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18166",
            "district": "14",
            "Block": "283",
            "label": "Rm.P.S.RAMAIAH NADAR MATRICULATION SCHOOL, ATHIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18167",
            "district": "14",
            "Block": "283",
            "label": "PARASAKTHI HIGHER SECONDARY SCHOOL  KOTTAIPATTI S",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18168",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, V.RAMASAMYPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18169",
            "district": "14",
            "Block": "283",
            "label": "SRI.LAKSHMI.N.M.S.VANDARI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18170",
            "district": "14",
            "Block": "283",
            "label": "GOVT HR SEC SCHOOL, SAPTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18171",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S PALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18172",
            "district": "14",
            "Block": "283",
            "label": "GOVT ADW HIGH SCHOOL, SANGARALINGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18173",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S, S.PAPINAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18174",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S,  MUTHUNAGAIAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18175",
            "district": "14",
            "Block": "283",
            "label": "P.U.M.S. NAGAIAHPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21167",
            "district": "14",
            "Block": "283",
            "label": "THIRUVALLUVAR MATRIC HIGHER SECONARY  SCHOOL, SOOLAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22249",
            "district": "14",
            "Block": "283",
            "label": "VISWA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL., ELUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22606",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL THULLUKUTTINAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22963",
            "district": "14",
            "Block": "283",
            "label": "GOVT HIGH SCHOOL, T.KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "284": [
        {
            "key": "3586",
            "district": "15",
            "Block": "284",
            "label": "PUMS MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4968",
            "district": "15",
            "Block": "284",
            "label": "PUMS ALAVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5161",
            "district": "15",
            "Block": "284",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL    KIDANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5428",
            "district": "15",
            "Block": "284",
            "label": "SVS AMS ERUKKATANCHERY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5582",
            "district": "15",
            "Block": "284",
            "label": "ST ANTONYS MS NATHAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5624",
            "district": "15",
            "Block": "284",
            "label": "PUMS SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6518",
            "district": "15",
            "Block": "284",
            "label": "PUMS  KUMARAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6551",
            "district": "15",
            "Block": "284",
            "label": "PUMS MARUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15873",
            "district": "15",
            "Block": "284",
            "label": "GHS,VAITHIYANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15874",
            "district": "15",
            "Block": "284",
            "label": "GHS,ERUMAL THOKKALAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15875",
            "district": "15",
            "Block": "284",
            "label": "AMS MELAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15876",
            "district": "15",
            "Block": "284",
            "label": "ROYAL MATRICULATION Hr. SEC. SCHOOL, SANKIRUPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15877",
            "district": "15",
            "Block": "284",
            "label": "ALAGU JOTHI ACADAMY  MELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15878",
            "district": "15",
            "Block": "284",
            "label": "AMS PONSAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15879",
            "district": "15",
            "Block": "284",
            "label": "ANNAI KASTHURIBA AIDED HIGH SCHOOL, NATARAJAPILLAI CHAVADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15880",
            "district": "15",
            "Block": "284",
            "label": "PUMS THALACHANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15881",
            "district": "15",
            "Block": "284",
            "label": "PUMS KURANGUPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15882",
            "district": "15",
            "Block": "284",
            "label": "TELC MS KARAIMEDU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "15883",
            "district": "15",
            "Block": "284",
            "label": "GHSS,KEELAPERUMPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15884",
            "district": "15",
            "Block": "284",
            "label": "GHS,ARUPATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15885",
            "district": "15",
            "Block": "284",
            "label": "PUMS MARUTHAMPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15886",
            "district": "15",
            "Block": "284",
            "label": "GHS CHINNANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15887",
            "district": "15",
            "Block": "284",
            "label": "PUMS AKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15888",
            "district": "15",
            "Block": "284",
            "label": "GGHS, AKKUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15889",
            "district": "15",
            "Block": "284",
            "label": "GHSS, AKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15890",
            "district": "15",
            "Block": "284",
            "label": "ORIENTAL ARABIC AIDED HR SEC SCHOOL, AKKUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15891",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRICULATION HIGHER SECONDARY SCHOOL, AKKUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15892",
            "district": "15",
            "Block": "284",
            "label": "GGHSS,SEMBANARKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15893",
            "district": "15",
            "Block": "284",
            "label": "NAPAC MATRICULATION HSS,SEMBANARKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15894",
            "district": "15",
            "Block": "284",
            "label": "AMS PARASALUR SEMBANARKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15895",
            "district": "15",
            "Block": "284",
            "label": "SAMBANDAM AIDED HIGHER SECONDARY SCHOOL, SEMBANARKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15896",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRICULATION HIGHER SECONDARY SCHOOL, SEMBANARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15897",
            "district": "15",
            "Block": "284",
            "label": "HAJA SARAMMAL MATRICULATION HSS, VADAGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15898",
            "district": "15",
            "Block": "284",
            "label": "GHS,VADAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15899",
            "district": "15",
            "Block": "284",
            "label": "GMS KEEZHMATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15900",
            "district": "15",
            "Block": "284",
            "label": "GHSS,THIRUKKADAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15901",
            "district": "15",
            "Block": "284",
            "label": "PUMS THIRUKKADAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15902",
            "district": "15",
            "Block": "284",
            "label": "SRI GURUGNANA SAMBANDAR MISSION MATRICULATION HIGH SCHOOL, THIRUKKADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15903",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRICULATION HR SEC SCHOOL,THIRUKKADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15904",
            "district": "15",
            "Block": "284",
            "label": "PUMS VEPPANCHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15905",
            "district": "15",
            "Block": "284",
            "label": "SRI SRI VIDYA MANDIR MATRICULATION SCHOOL, ANAIKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15906",
            "district": "15",
            "Block": "284",
            "label": "PUMS ELUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15907",
            "district": "15",
            "Block": "284",
            "label": "PUMS HARIHRANKOODAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15908",
            "district": "15",
            "Block": "284",
            "label": "GHSS,SANKARANPANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15909",
            "district": "15",
            "Block": "284",
            "label": "AL-AMAN(M) MHHS, SANKARANPANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15910",
            "district": "15",
            "Block": "284",
            "label": "HAMEEDIYA AIDED HR SEC SCHOOL, THIRUKKALACHERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15911",
            "district": "15",
            "Block": "284",
            "label": "AIYAS(M)HS, THIRUKKALACHERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15912",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRICULATION  HR.SEC SCHOOL AYAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15913",
            "district": "15",
            "Block": "284",
            "label": "PUMS THIRUVIDAIKAZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15914",
            "district": "15",
            "Block": "284",
            "label": "VALLIAMMAI GHSS, THILLAIYADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15915",
            "district": "15",
            "Block": "284",
            "label": "ANDAVAR AMS PORAYAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15916",
            "district": "15",
            "Block": "284",
            "label": "TELC  THALTHAKUMI GIRLS HIGH SCHOOL, PORAYAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15917",
            "district": "15",
            "Block": "284",
            "label": "TELC BJM BOYS AIDED HIGHER SECONDARY SCHOOL,THARANGAMPADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15918",
            "district": "15",
            "Block": "284",
            "label": "ST THERESA'S AIDED GIRLS HIGHER SECONDARY SCHOOL, THARANGAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15919",
            "district": "15",
            "Block": "284",
            "label": "THAVASU MUTHU NADAR AIDED HR SEC SCHOOL, PORAYAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15920",
            "district": "15",
            "Block": "284",
            "label": "SHARMILA GODDESS (M)HSS, PORAYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15921",
            "district": "15",
            "Block": "284",
            "label": "NIVEDHA MATRICULATION HIGHER SECONDARY SCHOOL, PORAYAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15922",
            "district": "15",
            "Block": "284",
            "label": "HOPE FOUNDATION MATRICULATION HIGH SCHOOL, THARANGAMPADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15923",
            "district": "15",
            "Block": "284",
            "label": "GHS,CHANDIRAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15924",
            "district": "15",
            "Block": "284",
            "label": "PUMS NARASINGANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15925",
            "district": "15",
            "Block": "284",
            "label": "SOWRIRAJAN  HIGH SCHOOL, THIRUVILAIYATTAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15926",
            "district": "15",
            "Block": "284",
            "label": "KALAIMAHAL MATRIC SCHOOL THIRUVILAIYATTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15927",
            "district": "15",
            "Block": "284",
            "label": "PUMS KARUGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15928",
            "district": "15",
            "Block": "284",
            "label": "GHS,NALLADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21393",
            "district": "15",
            "Block": "284",
            "label": "GHS PERUMALPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21524",
            "district": "15",
            "Block": "284",
            "label": "THIRUVENKADAM AIDED HIGH SCHOOL KEEZHAIYUR, SEMBANARKOVIL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21607",
            "district": "15",
            "Block": "284",
            "label": "ABDUL KALAM MATRICULATION SCHOOL,E.SATHANOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21950",
            "district": "15",
            "Block": "284",
            "label": "THAMARAI MATRIC HR.SEC. SCHOOL, SEMBANARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22218",
            "district": "15",
            "Block": "284",
            "label": "GOVT HIGH SCHOOL NALLUCHERY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        { 
            "key":"24117", "district":"15", 
            "Block":"284", "label": "sri vivekanandha nursary and primary school", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "23199",
            "district": "15",
            "Block": "284",
            "label": "SREE KRISHNA MATRICULATION HS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23958",
            "district": "15",
            "Block": "284",
            "label": "PUMS MATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23959",
            "district": "15",
            "Block": "284",
            "label": "GHS PERUMALPETTAI",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "285": [
        {
            "key": "1318",
            "district": "17",
            "Block": "285",
            "label": "PUMS PACHUDAYAMPATTI PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1509",
            "district": "17",
            "Block": "285",
            "label": "PUMS K.VELLALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1516",
            "district": "17",
            "Block": "285",
            "label": "PUMS MALAIVEPPANKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1613",
            "district": "17",
            "Block": "285",
            "label": "PUMS THUTHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1724",
            "district": "17",
            "Block": "285",
            "label": "PUMS PALLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2411",
            "district": "17",
            "Block": "285",
            "label": "PUMS VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2446",
            "district": "17",
            "Block": "285",
            "label": "PUMS NADUCOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2874",
            "district": "17",
            "Block": "285",
            "label": "SBM AIDED MIDDLE SCHOOL SENDAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3068",
            "district": "17",
            "Block": "285",
            "label": "PUMS THIRUMALAIGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3309",
            "district": "17",
            "Block": "285",
            "label": "KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL,KALKURICHI, BELUKURICHI, NAMAKKAL -637402",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6353",
            "district": "17",
            "Block": "285",
            "label": "VETHALOGA VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, SENDAMANGALAM, NAMAKKAL DT -637409",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6399",
            "district": "17",
            "Block": "285",
            "label": "BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL, KALAPPANAICKENPATTI,NAMAKKAL DT -637404",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11828",
            "district": "17",
            "Block": "285",
            "label": "GGHSS SENDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11829",
            "district": "17",
            "Block": "285",
            "label": "VIVEKA MATRICULATION SCHOOL, PONNAMAPUDUR,SENDAMANGALAM, NAMAKKAL DT -637404",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11830",
            "district": "17",
            "Block": "285",
            "label": "GGHSS KALAPPANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11831",
            "district": "17",
            "Block": "285",
            "label": "GBHSS KALAPPANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11832",
            "district": "17",
            "Block": "285",
            "label": "GHSS BELUKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11833",
            "district": "17",
            "Block": "285",
            "label": "BRINDAVAN MATRICULATION HIGHER SECONDARY SCHOOL, THUTHIKULAM, NAMAKKAL DT-637404",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11834",
            "district": "17",
            "Block": "285",
            "label": "GHS RAMANATHAPURAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11835",
            "district": "17",
            "Block": "285",
            "label": "GBHSS SENDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11836",
            "district": "17",
            "Block": "285",
            "label": "GHS POTTANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22805",
            "district": "17",
            "Block": "285",
            "label": "VETHALOGA VIDHYALAYA  CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "286": [
        {
            "key": "5066",
            "district": "1",
            "Block": "286",
            "label": "TSN MATRICULATION SCHOOL DALAVOI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5643",
            "district": "1",
            "Block": "286",
            "label": "ST.THERESA MATRICULATION  HIGHER SECONDARY SCHOOL SENDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14872",
            "district": "1",
            "Block": "286",
            "label": "GHS ANGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14873",
            "district": "1",
            "Block": "286",
            "label": "GHS SANNASINALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14874",
            "district": "1",
            "Block": "286",
            "label": "GHSS-DALAVOI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14875",
            "district": "1",
            "Block": "286",
            "label": "PUMS ALATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14876",
            "district": "1",
            "Block": "286",
            "label": "VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL ALATHIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14877",
            "district": "1",
            "Block": "286",
            "label": "GOVT.HR.SEC.SCHOOL,MULLUKKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14878",
            "district": "1",
            "Block": "286",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14879",
            "district": "1",
            "Block": "286",
            "label": "PUMS MANAKKUDAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14880",
            "district": "1",
            "Block": "286",
            "label": "PUMS MULLAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14881",
            "district": "1",
            "Block": "286",
            "label": "ST PHILOMINAL HR SEC SCHOOL KULUMUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14882",
            "district": "1",
            "Block": "286",
            "label": "GHS-MANAPATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14883",
            "district": "1",
            "Block": "286",
            "label": "AIDED MID SCH MUDUKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14884",
            "district": "1",
            "Block": "286",
            "label": "PUMS KURICHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14885",
            "district": "1",
            "Block": "286",
            "label": "GHSS-KURUCHIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14886",
            "district": "1",
            "Block": "286",
            "label": "ANNAI HIGH SCHOOL MARAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14887",
            "district": "1",
            "Block": "286",
            "label": "KALAIMAGAL -HR-SEC-SCHOOL -THULAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14888",
            "district": "1",
            "Block": "286",
            "label": "PUMS SIRUKALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14889",
            "district": "1",
            "Block": "286",
            "label": "GHS-NALLAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14890",
            "district": "1",
            "Block": "286",
            "label": "VETRIVINAYAGA HR SEC SCHOOL NALLAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14891",
            "district": "1",
            "Block": "286",
            "label": "GHSS-PONPARAPPI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14892",
            "district": "1",
            "Block": "286",
            "label": "PUMS PONPARAPPI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14893",
            "district": "1",
            "Block": "286",
            "label": "PUMS KEELAMALIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14894",
            "district": "1",
            "Block": "286",
            "label": "AIDED MID NAGALKUZHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14895",
            "district": "1",
            "Block": "286",
            "label": "GOVT HSS PARANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14896",
            "district": "1",
            "Block": "286",
            "label": "AIDED MID KUMIZHIYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14897",
            "district": "1",
            "Block": "286",
            "label": "PUMS PALAYAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14898",
            "district": "1",
            "Block": "286",
            "label": "GHSS-IRUMBULIKURUCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14899",
            "district": "1",
            "Block": "286",
            "label": "AIDED MID VEERAKKAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14900",
            "district": "1",
            "Block": "286",
            "label": "GHS-SIRUKADAMPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14901",
            "district": "1",
            "Block": "286",
            "label": "GHSS-ANANDAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14902",
            "district": "1",
            "Block": "286",
            "label": "GHS-UNJINI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14903",
            "district": "1",
            "Block": "286",
            "label": "PUMS MARUVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14904",
            "district": "1",
            "Block": "286",
            "label": "GHS-PERIYAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14905",
            "district": "1",
            "Block": "286",
            "label": "PUMS VANJINAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14906",
            "district": "1",
            "Block": "286",
            "label": "PUMS CHOKKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14907",
            "district": "1",
            "Block": "286",
            "label": "PUMS NAKKAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14908",
            "district": "1",
            "Block": "286",
            "label": "GHSS MODEL SCHOOL-SENDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14909",
            "district": "1",
            "Block": "286",
            "label": "GHSS-SENDURAI(GIRLS)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14910",
            "district": "1",
            "Block": "286",
            "label": "ARIGNAR ANNA HR SEC SCHOOL SENDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14911",
            "district": "1",
            "Block": "286",
            "label": "GHS-CHOLANKUDIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21382",
            "district": "1",
            "Block": "286",
            "label": "GHS NAMAGUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22541",
            "district": "1",
            "Block": "286",
            "label": "GHS AYANTHATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23960",
            "district": "1",
            "Block": "286",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23961",
            "district": "1",
            "Block": "286",
            "label": "RAMCO VIDYALAYA DALAVOI",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "287": [
        {
            "key": "2322",
            "district": "25",
            "Block": "287",
            "label": "PUMS- UMATHANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3263",
            "district": "25",
            "Block": "287",
            "label": "KATHERINE MATRIC - CHINNAAVUDAIYARKOIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3458",
            "district": "25",
            "Block": "287",
            "label": "PUMS -KODIVAYALMARAKKAVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3522",
            "district": "25",
            "Block": "287",
            "label": "PUMS -OMAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3643",
            "district": "25",
            "Block": "287",
            "label": "ABU MATRIC HSS- PUDUPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3957",
            "district": "25",
            "Block": "287",
            "label": "PUMS -RETTAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4298",
            "district": "25",
            "Block": "287",
            "label": "PUMS -ETTIVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5099",
            "district": "25",
            "Block": "287",
            "label": "PUMS -KATTAYANKADU UKKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6029",
            "district": "25",
            "Block": "287",
            "label": "PUMS -KOLLUKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6635",
            "district": "25",
            "Block": "287",
            "label": "PUMS- SAMBAIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16739",
            "district": "25",
            "Block": "287",
            "label": "GHS -RENDAMPULIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16740",
            "district": "25",
            "Block": "287",
            "label": "PUMS- KALANIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16741",
            "district": "25",
            "Block": "287",
            "label": "PUMS -KALYANARAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16742",
            "district": "25",
            "Block": "287",
            "label": "GHSS -KARISAVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16743",
            "district": "25",
            "Block": "287",
            "label": "GHSS- KURUVIKKARAMBAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16744",
            "district": "25",
            "Block": "287",
            "label": "GHSS- MANAKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16745",
            "district": "25",
            "Block": "287",
            "label": "PUMS -MARUNKAPPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16746",
            "district": "25",
            "Block": "287",
            "label": "GHS -MUTHUKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16747",
            "district": "25",
            "Block": "287",
            "label": "GHS -NADIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16748",
            "district": "25",
            "Block": "287",
            "label": "ADWHS -NADUVIKURCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16749",
            "district": "25",
            "Block": "287",
            "label": "GHSS -PALLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16750",
            "district": "25",
            "Block": "287",
            "label": "PUMS -MALLIPATTINAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16751",
            "district": "25",
            "Block": "287",
            "label": "PUMS -CHINNAMANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16752",
            "district": "25",
            "Block": "287",
            "label": "GHSS -MALLIPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16753",
            "district": "25",
            "Block": "287",
            "label": "GHS- SETHUBAVACHATRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16754",
            "district": "25",
            "Block": "287",
            "label": "GHS -PUTHUTHERU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16755",
            "district": "25",
            "Block": "287",
            "label": "GHS UDAYANADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16756",
            "district": "25",
            "Block": "287",
            "label": "PUMS -MUDACHIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16757",
            "district": "25",
            "Block": "287",
            "label": "GHS -VILANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16758",
            "district": "25",
            "Block": "287",
            "label": "GHSS -PERUMAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22228",
            "district": "25",
            "Block": "287",
            "label": "ROYAL PARK HIGH SCHOOL -NAVAKKOLLAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22229",
            "district": "25",
            "Block": "287",
            "label": "ATLANTIC SCHOOL-VEERIYANKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22582",
            "district": "25",
            "Block": "287",
            "label": "PIONEER SCHOOL-CHOKKANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22583",
            "district": "25",
            "Block": "287",
            "label": "GHS -KUPPATHEVAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23249",
            "district": "25",
            "Block": "287",
            "label": "GHS SENTHALAIVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "288": [
        {
            "key": "1939",
            "district": "24",
            "Block": "288",
            "label": "SHRIRAM VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL ELATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23361",
            "district": "24",
            "Block": "288",
            "label": "ANNAI THERASA METRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "2407",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL,THERKUMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2597",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL KAMBILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2956",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL,KARKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3380",
            "district": "24",
            "Block": "288",
            "label": "VANCHI GOVERNMENT MIDDLE SCHOOL SHENGOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3453",
            "district": "24",
            "Block": "288",
            "label": "AG Matric Higher Secondary School, Shengottai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3722",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL MEKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4369",
            "district": "24",
            "Block": "288",
            "label": "SPECTRUM MATRIC HIGHER SECONDARY SCHOOL KATHIRKANTHAM E-VILAKKU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4616",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL,KACHERI COMPOUND,SHENGOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5205",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL ELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5805",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT MIDDLE SCHOOL POOLANKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6417",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PULIYARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19902",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGH SCHOOL,MELASHENGOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19903",
            "district": "24",
            "Block": "288",
            "label": "Sri Moolam Sastiyaptha Poorthi Sattanatha Karayalar Government Boys Higher Secondary School, Shencottai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19904",
            "district": "24",
            "Block": "288",
            "label": "SRI RAMA MANDIRAM GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, Shengottai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19905",
            "district": "24",
            "Block": "288",
            "label": "Sri Jayendra Vidyalaya Matric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19906",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGH SCHOOL KATTALAIKUDIYARUPPU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19907",
            "district": "24",
            "Block": "288",
            "label": "PULARI MATRICULATION SCHOOL,  PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19908",
            "district": "24",
            "Block": "288",
            "label": "LAKSHMI HARIHARA HIGH SCHOOL ELATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19909",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SEEVANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19910",
            "district": "24",
            "Block": "288",
            "label": "PUNITHA ARULANANTHAR HIGHER SECONDARY SCHOOL AGARAKATTU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19911",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL AYIKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19912",
            "district": "24",
            "Block": "288",
            "label": "SIVA SARASWATHI VIDYALAYA HIGHER SECONDARY SCHOOL AYIKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19913",
            "district": "24",
            "Block": "288",
            "label": "HINDU NADAR URAVIN MURAI COMMITTEE DR.SIVANTHI ADITANAR MIDDLE SCHOOL SAMBAVAR VADAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19914",
            "district": "24",
            "Block": "288",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SAMBAVAR VADAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23557",
            "district": "24",
            "Block": "288",
            "label": "DMI ST.JOSEPH GLOBAL SCHOOL AGARAKATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23557",
            "district": "24",
            "Block": "288",
            "label": "DMI ST.JOSEPH GLOBAL SCHOOL AGARAKATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23962",
            "district": "24",
            "Block": "288",
            "label": "BRIGHTON MATRICULATION SCHOOL SAMBAVAR VADAKARAI",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "289": [
        {
            "key": "429",
            "district": "33",
            "Block": "289",
            "label": "PUMS,PADIANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "504",
            "district": "33",
            "Block": "289",
            "label": "KALAIMAGAL MS,PONDAVAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "550",
            "district": "33",
            "Block": "289",
            "label": "PUMS, ATTANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "555",
            "district": "33",
            "Block": "289",
            "label": "ST.MARYS MATRIC HSS,REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "563",
            "district": "33",
            "Block": "289",
            "label": "VELAMMAL MATRICULATION HIGHER SECONDARY SCHOOL PONNERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "590",
            "district": "33",
            "Block": "289",
            "label": "CHILDRENS PARADISE MATRIC HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "632",
            "district": "33",
            "Block": "289",
            "label": "VIVEKANANDA MATRIC HIGHER SECONDARY SCHOOL, ATTANTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "776",
            "district": "33",
            "Block": "289",
            "label": "SRI KALAIVANI VIDHYALAYA MATRIC HIGHER SECONDARY SCHOOL.,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "787",
            "district": "33",
            "Block": "289",
            "label": "PUMS,NEDUVARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "908",
            "district": "33",
            "Block": "289",
            "label": "ST.JOSEPH MATRIC SCHOOL,MONDIAMMAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "965",
            "district": "33",
            "Block": "289",
            "label": "ELITE MAT.HSS, M.A.NAGAR, REDHILLS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1043",
            "district": "33",
            "Block": "289",
            "label": "PUMS,SOTHUPERUMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1189",
            "district": "33",
            "Block": "289",
            "label": "PUMS,AMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1219",
            "district": "33",
            "Block": "289",
            "label": "PUMS,JANAPPAN CHATRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1230",
            "district": "33",
            "Block": "289",
            "label": "PUMS, VELLIVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1247",
            "district": "33",
            "Block": "289",
            "label": "PUMS, THIRUNILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1279",
            "district": "33",
            "Block": "289",
            "label": "M.B.S.VIVEKANANDA VIDHYALAYA, ARANI NORTH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1498",
            "district": "33",
            "Block": "289",
            "label": "PUMS,PANCHETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1628",
            "district": "33",
            "Block": "289",
            "label": "DON BOSCO MATRIC HIGHER SECONDARY SCHOOL, KARANODAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1711",
            "district": "33",
            "Block": "289",
            "label": "PUMS, MADHAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1793",
            "district": "33",
            "Block": "289",
            "label": "GREEN FIELD MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1866",
            "district": "33",
            "Block": "289",
            "label": "PUMS,ATHIPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2013",
            "district": "33",
            "Block": "289",
            "label": "PUMS, OLD ERUMAI VETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2028",
            "district": "33",
            "Block": "289",
            "label": "PUMS, GANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2084",
            "district": "33",
            "Block": "289",
            "label": "VELAMMAL INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2205",
            "district": "33",
            "Block": "289",
            "label": "PUMS,MONDIAMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2440",
            "district": "33",
            "Block": "289",
            "label": "PUMS,B.SHANTHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2763",
            "district": "33",
            "Block": "289",
            "label": "ALPHA MAT HSS,MONDIAMMAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3146",
            "district": "33",
            "Block": "289",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3388",
            "district": "33",
            "Block": "289",
            "label": "ST.JOSEPH MS,MONDIAMMAN NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6894",
            "district": "33",
            "Block": "289",
            "label": "GOVT.HSS,NEW ALAMATHI - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6895",
            "district": "33",
            "Block": "289",
            "label": "GHS, OLD ALAMATHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6896",
            "district": "33",
            "Block": "289",
            "label": "GOVT.HS ,ANDARKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6897",
            "district": "33",
            "Block": "289",
            "label": "GHS,ATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6898",
            "district": "33",
            "Block": "289",
            "label": "GOVT HSS,BUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6899",
            "district": "33",
            "Block": "289",
            "label": "GHS, CHINNAMPEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6900",
            "district": "33",
            "Block": "289",
            "label": "GHS, NEW ERUMAI VETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6901",
            "district": "33",
            "Block": "289",
            "label": "GHS, JAGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6902",
            "district": "33",
            "Block": "289",
            "label": "GHSS, GNAYERU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6903",
            "district": "33",
            "Block": "289",
            "label": "SHRI GORANTLA RAMALINGAIAH VIVEKANANDA VIDYALAYA THATCHUR KOOTU ROAD, , PANJETTI,, PONNERI TALUK",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6904",
            "district": "33",
            "Block": "289",
            "label": "G.H.S.S,PADIANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6905",
            "district": "33",
            "Block": "289",
            "label": "GOVT HSS(G),SHOLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6906",
            "district": "33",
            "Block": "289",
            "label": "GOVT HSS (Boys) ,SHOLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6907",
            "district": "33",
            "Block": "289",
            "label": "GHS, VICHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6908",
            "district": "33",
            "Block": "289",
            "label": "GOVT (G) HSS, ARANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6909",
            "district": "33",
            "Block": "289",
            "label": "GOVT (B)  HSS, ARANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21004",
            "district": "33",
            "Block": "289",
            "label": "DR.GUPTA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21392",
            "district": "33",
            "Block": "289",
            "label": "GHS,ORAKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21987",
            "district": "33",
            "Block": "289",
            "label": "RDS VIDYA MANTHIR NUR & PRI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23607",
            "district": "33",
            "Block": "289",
            "label": "SRI KRISH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23608",
            "district": "33",
            "Block": "289",
            "label": "GFC GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23607",
            "district": "33",
            "Block": "289",
            "label": "SRI KRISH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23608",
            "district": "33",
            "Block": "289",
            "label": "GFC GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "290": [
        {
            "key": "1848",
            "district": "21",
            "Block": "290",
            "label": "SRI DIVYA CHAITANYA MATRIC HSS SHOLINGHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2906",
            "district": "21",
            "Block": "290",
            "label": "PUMS, PALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3542",
            "district": "21",
            "Block": "290",
            "label": "QUEEN MARY'S MATRIC. HR. SEC. SCHOOL, KATTRAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4023",
            "district": "21",
            "Block": "290",
            "label": "PUMS-CHINAPARAVATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4107",
            "district": "21",
            "Block": "290",
            "label": "PUMS VENGUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4384",
            "district": "21",
            "Block": "290",
            "label": "SARASWATHI VELU MATRIC HSS, SHOLINGHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4786",
            "district": "21",
            "Block": "290",
            "label": "SKMS JAMBUKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5299",
            "district": "21",
            "Block": "290",
            "label": "PUMS MARUDHALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5377",
            "district": "21",
            "Block": "290",
            "label": "PUMS THALANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5423",
            "district": "21",
            "Block": "290",
            "label": "PUMS - SHOLINGHUR MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5462",
            "district": "21",
            "Block": "290",
            "label": "PUMS KUPPAKKALMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5506",
            "district": "21",
            "Block": "290",
            "label": "PUMS THANIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5541",
            "district": "21",
            "Block": "290",
            "label": "C.S.I. MARY MCLEAN MIDDLE SCHOOL, SHOLINGHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5574",
            "district": "21",
            "Block": "290",
            "label": "PUMS G.C. KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5666",
            "district": "21",
            "Block": "290",
            "label": "UNITY MATRIC HSS, PANDIYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6212",
            "district": "21",
            "Block": "290",
            "label": "PUMS-KUNNATHUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6405",
            "district": "21",
            "Block": "290",
            "label": "SENGUNTHAR AIDED MIDDLE SCHOOL SHOLINGHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6719",
            "district": "21",
            "Block": "290",
            "label": "PUMS-AYIPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8475",
            "district": "21",
            "Block": "290",
            "label": "GHS GANGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8482",
            "district": "21",
            "Block": "290",
            "label": "GHSS GUDALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8483",
            "district": "21",
            "Block": "290",
            "label": "GANDHIJI HS KARIKKAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8484",
            "district": "21",
            "Block": "290",
            "label": "CSI HS THALIKAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8485",
            "district": "21",
            "Block": "290",
            "label": "GHS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8486",
            "district": "21",
            "Block": "290",
            "label": "GOVT HS POLIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8487",
            "district": "21",
            "Block": "290",
            "label": "GOVT ADW HS AYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8488",
            "district": "21",
            "Block": "290",
            "label": "GHS SURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8503",
            "district": "21",
            "Block": "290",
            "label": "CSI AIDED HS PARAVATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8522",
            "district": "21",
            "Block": "290",
            "label": "PUMS THAGARAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8523",
            "district": "21",
            "Block": "290",
            "label": "PUMS SENGALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8524",
            "district": "21",
            "Block": "290",
            "label": "GOVT HSS RENDADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8525",
            "district": "21",
            "Block": "290",
            "label": "PUMS DHALAVAIPATTADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8526",
            "district": "21",
            "Block": "290",
            "label": "PUMS PERUNKANCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8527",
            "district": "21",
            "Block": "290",
            "label": "GOVT HSS KODAIKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8528",
            "district": "21",
            "Block": "290",
            "label": "SM AIDED MS KONDAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8529",
            "district": "21",
            "Block": "290",
            "label": "GOVT BOYS HSS SHOLINGHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8530",
            "district": "21",
            "Block": "290",
            "label": "TMT. ETHIRAJAMMAL MUDALIANDAR GOVT. MODEL GIRLS HR. SEC. SCHOOL. SHOLINGHUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8531",
            "district": "21",
            "Block": "290",
            "label": "GOODLET HSS SHOLINGHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8532",
            "district": "21",
            "Block": "290",
            "label": "ASWINI MATRIC HSS, SHOLINGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8533",
            "district": "21",
            "Block": "290",
            "label": "GOVT HS PULIVALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8534",
            "district": "21",
            "Block": "290",
            "label": "ANMMS V.K. THANGAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8535",
            "district": "21",
            "Block": "290",
            "label": "PUMS KATTRAMPAKKAM MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8536",
            "district": "21",
            "Block": "290",
            "label": "GOVT HR SEC SCHOOL NEELAKANDARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8537",
            "district": "21",
            "Block": "290",
            "label": "PUMS VANGHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8538",
            "district": "21",
            "Block": "290",
            "label": "GOVT HSS OZHUGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8539",
            "district": "21",
            "Block": "290",
            "label": "PUMS KOLATHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8540",
            "district": "21",
            "Block": "290",
            "label": "GOVT HS VELAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21571",
            "district": "21",
            "Block": "290",
            "label": "VIDYA PEETAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21678",
            "district": "21",
            "Block": "290",
            "label": "VALLIYAMMAI MATRIC SCHOOL, SHOLINGHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22381",
            "district": "21",
            "Block": "290",
            "label": "SKV INTERNATIONAL PUBLIC SCHOOL ( CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22382",
            "district": "21",
            "Block": "290",
            "label": "GOVT HIGH SCHOOL MELVEERANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22741",
            "district": "21",
            "Block": "290",
            "label": "VCS HI TECH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22766",
            "district": "21",
            "Block": "290",
            "label": "SKV MATRICULATION PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23535",
            "district": "21",
            "Block": "290",
            "label": "MONISHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23535",
            "district": "21",
            "Block": "290",
            "label": "MONISHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23963",
            "district": "21",
            "Block": "290",
            "label": "SARASWATHI VIDHYALAYA MATRICULATION SCHOOL MELVENKATAPURAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "291": [
        {
            "key": "3193",
            "district": "13",
            "Block": "291",
            "label": "VAILANKANNI MHSS SHOOLAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5181",
            "district": "13",
            "Block": "291",
            "label": "PUMS NALLARALAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5322",
            "district": "13",
            "Block": "291",
            "label": "PUMS KOTTANGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5417",
            "district": "13",
            "Block": "291",
            "label": "PUMS  BELLATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5553",
            "district": "13",
            "Block": "291",
            "label": "PUMS VEMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5724",
            "district": "13",
            "Block": "291",
            "label": "Er.PERUMAL MANIMEKALAI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5818",
            "district": "13",
            "Block": "291",
            "label": "PUMS BASTHALAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6030",
            "district": "13",
            "Block": "291",
            "label": "PUMS BEERPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6306",
            "district": "13",
            "Block": "291",
            "label": "PUMS MARANDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6314",
            "district": "13",
            "Block": "291",
            "label": "PUMS KADHIREPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6377",
            "district": "13",
            "Block": "291",
            "label": "PUMS MADHARASANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6380",
            "district": "13",
            "Block": "291",
            "label": "PUMS BEELALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6404",
            "district": "13",
            "Block": "291",
            "label": "PUMS CHENNAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6466",
            "district": "13",
            "Block": "291",
            "label": "PUMS B.CHENNASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6564",
            "district": "13",
            "Block": "291",
            "label": "PUMS PUNNAGARAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6702",
            "district": "13",
            "Block": "291",
            "label": "PUMS CHEMBARASANAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20668",
            "district": "13",
            "Block": "291",
            "label": "GHS ADDAGURUKKI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20669",
            "district": "13",
            "Block": "291",
            "label": "PUMS AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20670",
            "district": "13",
            "Block": "291",
            "label": "PUMS ALUSONAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20671",
            "district": "13",
            "Block": "291",
            "label": "PUMS ARUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20672",
            "district": "13",
            "Block": "291",
            "label": "PUMS ATHIMUGAM-URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20673",
            "district": "13",
            "Block": "291",
            "label": "JNM GHS ATHIMUGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20674",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  BERIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20675",
            "district": "13",
            "Block": "291",
            "label": "SRI VIDYA BHARATHI MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20676",
            "district": "13",
            "Block": "291",
            "label": "GHSS BUKKASAGARAM -TEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20677",
            "district": "13",
            "Block": "291",
            "label": "PUMS CHAPPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20678",
            "district": "13",
            "Block": "291",
            "label": "GHS CHINNADINNUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20679",
            "district": "13",
            "Block": "291",
            "label": "PUMS D.KOTHAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20680",
            "district": "13",
            "Block": "291",
            "label": "GHS DEVASANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20681",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGH SCHOOL,  DODDEGOUNDAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20682",
            "district": "13",
            "Block": "291",
            "label": "GHSS ENUSONAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20683",
            "district": "13",
            "Block": "291",
            "label": "PUMS GEJJALANDODDI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20684",
            "district": "13",
            "Block": "291",
            "label": "PUMS GUDISADANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20685",
            "district": "13",
            "Block": "291",
            "label": "GHSS HALESEEBAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20686",
            "district": "13",
            "Block": "291",
            "label": "PUMS HANUMANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20687",
            "district": "13",
            "Block": "291",
            "label": "PUMS HOSAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20688",
            "district": "13",
            "Block": "291",
            "label": "GHSS IMMEDINAYAKANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20689",
            "district": "13",
            "Block": "291",
            "label": "PUMS KADATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20690",
            "district": "13",
            "Block": "291",
            "label": "GHSS KALINGAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20691",
            "district": "13",
            "Block": "291",
            "label": "GHS KAMANDODDI -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20692",
            "district": "13",
            "Block": "291",
            "label": "PUMS KANALATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20693",
            "district": "13",
            "Block": "291",
            "label": "PUMS KATTIGANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20694",
            "district": "13",
            "Block": "291",
            "label": "PUMS KOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20695",
            "district": "13",
            "Block": "291",
            "label": "PUMS KOTTAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20696",
            "district": "13",
            "Block": "291",
            "label": "PUMS KUMBALAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20697",
            "district": "13",
            "Block": "291",
            "label": "GHS MORASAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20698",
            "district": "13",
            "Block": "291",
            "label": "GHS MUDUGURIKI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20699",
            "district": "13",
            "Block": "291",
            "label": "PUMS NALLAGANAKOTHAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20700",
            "district": "13",
            "Block": "291",
            "label": "PUMS NARASEEPURAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20701",
            "district": "13",
            "Block": "291",
            "label": "GHS NERIGAM -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20702",
            "district": "13",
            "Block": "291",
            "label": "PUMS PALAVATHIMMANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20703",
            "district": "13",
            "Block": "291",
            "label": "PUMS PANNAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20704",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGH SCHOOL, PATHAKOTTA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20705",
            "district": "13",
            "Block": "291",
            "label": "RAJAJI MEMORIAL GHS PERANDAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20706",
            "district": "13",
            "Block": "291",
            "label": "TVS ACADEMY M.BATHALAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20707",
            "district": "13",
            "Block": "291",
            "label": "PUMS PULIYARASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20708",
            "district": "13",
            "Block": "291",
            "label": "PUMS SHOOLAGIRI URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20709",
            "district": "13",
            "Block": "291",
            "label": "GHSS-GIRLS  SHOOLAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20710",
            "district": "13",
            "Block": "291",
            "label": "GHSS BOYS SHOOLAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20711",
            "district": "13",
            "Block": "291",
            "label": "KGBV SHOOLAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20712",
            "district": "13",
            "Block": "291",
            "label": "JOHN MARIE MEMORIAL MATRIC SCHOOL, SHOOLAGIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20713",
            "district": "13",
            "Block": "291",
            "label": "NEW LITTLE FLOWER MHSS SHOOLAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20714",
            "district": "13",
            "Block": "291",
            "label": "MODEL HSS SHOOLAGIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20715",
            "district": "13",
            "Block": "291",
            "label": "GHS ULAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20716",
            "district": "13",
            "Block": "291",
            "label": "GHS UNGATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20717",
            "district": "13",
            "Block": "291",
            "label": "GHS A.SETTIPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20718",
            "district": "13",
            "Block": "291",
            "label": "PUMS CHIMPALTHIRADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20719",
            "district": "13",
            "Block": "291",
            "label": "PUMS DORIPALLI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20720",
            "district": "13",
            "Block": "291",
            "label": "GHS MORANAPALLI -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20721",
            "district": "13",
            "Block": "291",
            "label": "PUMS SAMANAPALLI -TAM-TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20722",
            "district": "13",
            "Block": "291",
            "label": "GHS VENKATESAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20723",
            "district": "13",
            "Block": "291",
            "label": "PUMS PEDDASIGARALAPALLI-URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20724",
            "district": "13",
            "Block": "291",
            "label": "PUMS THIYAGARASANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20725",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, UDHANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20726",
            "district": "13",
            "Block": "291",
            "label": "SARASWATHY VIDYALAYA MATRIC SCHOOL, UDDANAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21422",
            "district": "13",
            "Block": "291",
            "label": "GHS BERIGAI - URDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22293",
            "district": "13",
            "Block": "291",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Athimugam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22294",
            "district": "13",
            "Block": "291",
            "label": "NTR INTERNATIONAL SCHOOL, BUKKASAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22295",
            "district": "13",
            "Block": "291",
            "label": "ST.MARY'S ENGLISH SCHOOL - ICSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22296",
            "district": "13",
            "Block": "291",
            "label": "GOVERNMENT HIGH SCHOOL, SANAMAVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22674",
            "district": "13",
            "Block": "291",
            "label": "GHS ERRANDAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22675",
            "district": "13",
            "Block": "291",
            "label": "GHS KATTINAYAKANADODDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23181",
            "district": "13",
            "Block": "291",
            "label": "STANFORD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23964",
            "district": "13",
            "Block": "291",
            "label": "PUMS AGARAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "292": [
        {
            "key": "17748",
            "district": "23",
            "Block": "292",
            "label": "PUMS VENGAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17749",
            "district": "23",
            "Block": "292",
            "label": "GOVT BOYS HSS SINGAMPUNARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17750",
            "district": "23",
            "Block": "292",
            "label": "RM  RM GOVT GIRLS HR SEC  SCHOOL SINGAMPUNARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17751",
            "district": "23",
            "Block": "292",
            "label": "GOVT BOYS HIGH SCHOOL A KALAPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17752",
            "district": "23",
            "Block": "292",
            "label": "AVM MATRIC HSS SINGAMPUNARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17753",
            "district": "23",
            "Block": "292",
            "label": "GOVT GIRLS HIGH SCHOOL A KALAPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17754",
            "district": "23",
            "Block": "292",
            "label": "SRI SEVUGAMOORTHY MATRIC SCHOOL, SINGAMPUNARI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17755",
            "district": "23",
            "Block": "292",
            "label": "PUMS KAPPARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17756",
            "district": "23",
            "Block": "292",
            "label": "PUMS VETTAIANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17757",
            "district": "23",
            "Block": "292",
            "label": "PV MATRIC HSS SINGAMPUNARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17758",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL ANIAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17759",
            "district": "23",
            "Block": "292",
            "label": "PUMS SIRUMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17760",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL CHELLIAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17761",
            "district": "23",
            "Block": "292",
            "label": "PUMS MARUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17762",
            "district": "23",
            "Block": "292",
            "label": "GOVT HSS ERIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17763",
            "district": "23",
            "Block": "292",
            "label": "PUMS S.MAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17764",
            "district": "23",
            "Block": "292",
            "label": "PUMS MELAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17765",
            "district": "23",
            "Block": "292",
            "label": "VALLAL PARI HSS PIRANMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17766",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL MALLAKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17767",
            "district": "23",
            "Block": "292",
            "label": "PUMS S KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17768",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL M SOORAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17769",
            "district": "23",
            "Block": "292",
            "label": "GOVT HSS SS KOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17770",
            "district": "23",
            "Block": "292",
            "label": "PUMS VADAVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17771",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGHER SECONDARY SCHOOL KIRUNGAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17772",
            "district": "23",
            "Block": "292",
            "label": "PUMS ERUMAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17773",
            "district": "23",
            "Block": "292",
            "label": "PUMS JEYANKONDANELAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17774",
            "district": "23",
            "Block": "292",
            "label": "GOVT HIGH SCHOOL ARALIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17775",
            "district": "23",
            "Block": "292",
            "label": "PUMS KALLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17776",
            "district": "23",
            "Block": "292",
            "label": "GOVT HSS A MURAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17777",
            "district": "23",
            "Block": "292",
            "label": "PUMS S.SEVALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22240",
            "district": "23",
            "Block": "292",
            "label": "GLOBAL INTERNATIONAL SCHOOL, M. KOVILPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23965",
            "district": "23",
            "Block": "292",
            "label": "GANDHI MATRICULATION SHOOL ERIYUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "293": [
        {
            "key": "2824",
            "district": "15",
            "Block": "293",
            "label": "PUMS MANIGRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4938",
            "district": "15",
            "Block": "293",
            "label": "MMS OOZHIYAKARANTHOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5412",
            "district": "15",
            "Block": "293",
            "label": "PUMS KAVERIPOOMPATTINAM (FISHER)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5847",
            "district": "15",
            "Block": "293",
            "label": "TRMCT .MS. KANNIYAGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6542",
            "district": "15",
            "Block": "293",
            "label": "PUMS ALLIVILAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15952",
            "district": "15",
            "Block": "293",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   THITTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15953",
            "district": "15",
            "Block": "293",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   SATTANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15954",
            "district": "15",
            "Block": "293",
            "label": "DAGGSM  VTP MS .THENPATHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15955",
            "district": "15",
            "Block": "293",
            "label": "BEST MATRICULATION HR SEC SCHOOL SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15956",
            "district": "15",
            "Block": "293",
            "label": "VIVEKANANDA MATRIC. HSS SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15957",
            "district": "15",
            "Block": "293",
            "label": "GOOD SAMARITAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15958",
            "district": "15",
            "Block": "293",
            "label": "MPL HSS SIRKALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15959",
            "district": "15",
            "Block": "293",
            "label": "SHYAMALA GIRLS AIDED HR SEC SCHOOL SIRKALI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15960",
            "district": "15",
            "Block": "293",
            "label": "LMC AIDED HR SEC SCHOOL SIRKALI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15961",
            "district": "15",
            "Block": "293",
            "label": "S M HINDU AIDED HR SEC SCHOOL SIRKALI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15962",
            "district": "15",
            "Block": "293",
            "label": "ST JOSEPH MATRICULATION  HR SEC SCHOOL, P.K. ROAD, SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15963",
            "district": "15",
            "Block": "293",
            "label": "SMH MS SOUTH ST SIRKAZHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15964",
            "district": "15",
            "Block": "293",
            "label": "MUNICIPAL MIDDLE SCHOOL PANAKATTANKUDISALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15965",
            "district": "15",
            "Block": "293",
            "label": "S M HINDU MATRICULATION HIGH SCHOOL, SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15966",
            "district": "15",
            "Block": "293",
            "label": "EZHILMALAR MHS SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15967",
            "district": "15",
            "Block": "293",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL   SENTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15968",
            "district": "15",
            "Block": "293",
            "label": "KAM AIDED HIGH SCHOOL KOVILPATHU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15969",
            "district": "15",
            "Block": "293",
            "label": "GHS AGANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15970",
            "district": "15",
            "Block": "293",
            "label": "PUMS VALLUVAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15971",
            "district": "15",
            "Block": "293",
            "label": "ADW GHSS KONDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15972",
            "district": "15",
            "Block": "293",
            "label": "PUMS PUNGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15973",
            "district": "15",
            "Block": "293",
            "label": "GHSS VAITHEESWARANKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15974",
            "district": "15",
            "Block": "293",
            "label": "G(G)HSS VAITHEESWARANKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15975",
            "district": "15",
            "Block": "293",
            "label": "SRI GURUGNANASAMBANDAR MISSION - SRI MUTHAIAH MATRIC. HR. SEC. SCHOOL, VAITHEESWARANKOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15976",
            "district": "15",
            "Block": "293",
            "label": "SRI MUTHURAJAM MATRICULATION SCHOOL KARKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15977",
            "district": "15",
            "Block": "293",
            "label": "ADW GHSS KARAIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15978",
            "district": "15",
            "Block": "293",
            "label": "GHS MELACHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15979",
            "district": "15",
            "Block": "293",
            "label": "ANNAI SARATHA VIDHYA MATRIC  SCHOOL, KARAIMEDU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15980",
            "district": "15",
            "Block": "293",
            "label": "PUMS KATHIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15981",
            "district": "15",
            "Block": "293",
            "label": "GHSS NANGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15982",
            "district": "15",
            "Block": "293",
            "label": "GHS THIRUVALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15983",
            "district": "15",
            "Block": "293",
            "label": "GHS THIRUNAGARI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15984",
            "district": "15",
            "Block": "293",
            "label": "PUMS ANNAPPANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15985",
            "district": "15",
            "Block": "293",
            "label": "GHSS KONAYAMPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15986",
            "district": "15",
            "Block": "293",
            "label": "GHS AGARAPERUNTHOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15987",
            "district": "15",
            "Block": "293",
            "label": "PUMS NIMELI NEPPATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15988",
            "district": "15",
            "Block": "293",
            "label": "SSD BOYS AIDED HIGER SECONDARY SCHOOL, THIRUVENGADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15989",
            "district": "15",
            "Block": "293",
            "label": "SSD GIRLS HIGH SCHOOL, THIRUVENGADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "15990",
            "district": "15",
            "Block": "293",
            "label": "HOLY CROSS MATRIC SCHOOL THIRUVENGADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15991",
            "district": "15",
            "Block": "293",
            "label": "PUMS MANGAIMADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15992",
            "district": "15",
            "Block": "293",
            "label": "GHS KAVERIPOOMPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15993",
            "district": "15",
            "Block": "293",
            "label": "SRINIVASA AIDED HSS MELAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15994",
            "district": "15",
            "Block": "293",
            "label": "GHS VANAGIRIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22220",
            "district": "15",
            "Block": "293",
            "label": "LIONS CLUB MATRICULATION SCHOOL VILANTHIDASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22699",
            "district": "15",
            "Block": "293",
            "label": "SUBHAM VIDYA MANDIR SIRKALI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22700",
            "district": "15",
            "Block": "293",
            "label": "SHRI NATARAJAN MEMORIAL PUBLIC SCHOOL KARAIMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23966",
            "district": "15",
            "Block": "293",
            "label": "PUMS PUNGANUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "294": [
        {
            "key": "4379",
            "district": "23",
            "Block": "294",
            "label": "OXFORD MATRIC HSS  T.PUDUR SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5347",
            "district": "23",
            "Block": "294",
            "label": "21 ST CENTURY  MATRIC HSS SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5485",
            "district": "23",
            "Block": "294",
            "label": "ST JUSTIN'S MATRIC HSS,SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17317",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS ALAVAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17318",
            "district": "23",
            "Block": "294",
            "label": "PUMS AMMATCHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17319",
            "district": "23",
            "Block": "294",
            "label": "PUMS ARASANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17320",
            "district": "23",
            "Block": "294",
            "label": "PUMS AR USILAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17321",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS ARASANOOR THIRUMANJOLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17322",
            "district": "23",
            "Block": "294",
            "label": "PUMS MUTHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17323",
            "district": "23",
            "Block": "294",
            "label": "PUMS KUTTITHINNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17324",
            "district": "23",
            "Block": "294",
            "label": "PUMS MAHASIVANENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17325",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS ALAGAMANAGARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17326",
            "district": "23",
            "Block": "294",
            "label": "PUMS IDAYAMELUR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17327",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS IDAYAMELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17328",
            "district": "23",
            "Block": "294",
            "label": "VIKNESHWARA VIDYALAYA  MATRICULATION SCHOOL IDAYAMELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17329",
            "district": "23",
            "Block": "294",
            "label": "MUTHU MATRICULATION IDAYAMELUR COLONY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17330",
            "district": "23",
            "Block": "294",
            "label": "PUMS KOOTURAVUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17331",
            "district": "23",
            "Block": "294",
            "label": "SARVASEVA HIGH SCHOOL VAHULATHUPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17332",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL KATTANIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17333",
            "district": "23",
            "Block": "294",
            "label": "PUMS KANNARIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17334",
            "district": "23",
            "Block": "294",
            "label": "SAKTHI HIGH SCHOOL JOTHI NAGAR PADAMATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17335",
            "district": "23",
            "Block": "294",
            "label": "PUMS SUNDARANADAPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17336",
            "district": "23",
            "Block": "294",
            "label": "PUMS KANJIRANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17337",
            "district": "23",
            "Block": "294",
            "label": "GOVT MODEL HIGHER SECONDARY SCHOOL KEELAPOONGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17338",
            "district": "23",
            "Block": "294",
            "label": "PUMS VEERAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17339",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL KUMARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17340",
            "district": "23",
            "Block": "294",
            "label": "PUMS URULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17341",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL SITHALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17342",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS MANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17343",
            "district": "23",
            "Block": "294",
            "label": "PUMS MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17344",
            "district": "23",
            "Block": "294",
            "label": "PUMS MELAPOONGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17345",
            "district": "23",
            "Block": "294",
            "label": "SRI KARPAGA MATHA MIDDLE SCHOOL MADAGUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17346",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGHER SECONDARY  SCHOOL V MALAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17347",
            "district": "23",
            "Block": "294",
            "label": "SWAMY VIVEKANANTHA MATRIC HR SEC  SCHOOL METTUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17348",
            "district": "23",
            "Block": "294",
            "label": "PUMS NAMANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17349",
            "district": "23",
            "Block": "294",
            "label": "PUMS NALUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17350",
            "district": "23",
            "Block": "294",
            "label": "SC HSS OKKUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17351",
            "district": "23",
            "Block": "294",
            "label": "PUMS OKKUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17352",
            "district": "23",
            "Block": "294",
            "label": "PUMS ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17353",
            "district": "23",
            "Block": "294",
            "label": "ST JOSEPH MATRIC SCHOOL O PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17354",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL PADAMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17355",
            "district": "23",
            "Block": "294",
            "label": "PUMS PILLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17356",
            "district": "23",
            "Block": "294",
            "label": "PUMS PIRAVALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17357",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL MELASALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17358",
            "district": "23",
            "Block": "294",
            "label": "PUMS SOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17359",
            "district": "23",
            "Block": "294",
            "label": "SHUDDHANANDA BHARATI DESIYA VIDYALAYAM  HIGH SCHOOL SOLAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17360",
            "district": "23",
            "Block": "294",
            "label": "SHRI RAMANA VIKASS HIGHER SECONDARY SCHOOL,CHOLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17361",
            "district": "23",
            "Block": "294",
            "label": "PUMS KOMALIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17362",
            "district": "23",
            "Block": "294",
            "label": "PUMS PASANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17363",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGHER  SECONDARY  SCHOOL ,  SAKKANTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17364",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS THAMARAKKI SOUTH",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17365",
            "district": "23",
            "Block": "294",
            "label": "PUMS THAMARAKKI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17366",
            "district": "23",
            "Block": "294",
            "label": "PUMS THIRUMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17367",
            "district": "23",
            "Block": "294",
            "label": "GOVT HIGH SCHOOL KEELAKANDANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17368",
            "district": "23",
            "Block": "294",
            "label": "ANNAI SRI VEERAMAKALIAMMAN MATRIC HR SEC SCHOOL MELAVANIYANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17369",
            "district": "23",
            "Block": "294",
            "label": "KARPURASUNDARAPANDIAN RAMALAKSHMI HR SEC SCHOOL SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17370",
            "district": "23",
            "Block": "294",
            "label": "MOTHER THERASA MATRIC SCHOOL SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17371",
            "district": "23",
            "Block": "294",
            "label": "GOVT HSS MARUTHUPANDIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17372",
            "district": "23",
            "Block": "294",
            "label": "ST ANNYS MATRTIC  SCHOOL KANJIRANGAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "17373",
            "district": "23",
            "Block": "294",
            "label": "GOVT GIRLS HSS, SIVAGANGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17374",
            "district": "23",
            "Block": "294",
            "label": "ST FRANCIS MID SCHOOL BRITTO NAGAR SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "17375",
            "district": "23",
            "Block": "294",
            "label": "ALICE MILLER GIRLS HS SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17376",
            "district": "23",
            "Block": "294",
            "label": "SWAMI VIVEKANANDA HIGH SCHOOL SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17377",
            "district": "23",
            "Block": "294",
            "label": "MANNAR MIDDLE SCHOOL, SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17378",
            "district": "23",
            "Block": "294",
            "label": "MANNAR HR SEC SCHOOL, SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17379",
            "district": "23",
            "Block": "294",
            "label": "Rajakumari Rajarajeshwai kalanilaya MIDDLE SCHOOL SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17380",
            "district": "23",
            "Block": "294",
            "label": "ST. JOSEPH ' S HR SEC SCHOOL SIVAGANGAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17381",
            "district": "23",
            "Block": "294",
            "label": "MMS 48 COLONY SIVAGANGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17382",
            "district": "23",
            "Block": "294",
            "label": "ST JUSTIN'S GIRLS HSS,SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17383",
            "district": "23",
            "Block": "294",
            "label": "AR NATESAN CHETTIYAR MIDDLE SCHOOL SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17384",
            "district": "23",
            "Block": "294",
            "label": "ROMAN CATHOLIC MID SCHOOL SIVAGANGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17385",
            "district": "23",
            "Block": "294",
            "label": "NAMBIKKAI MANAVALARCHI SCHOOL SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17386",
            "district": "23",
            "Block": "294",
            "label": "KENDIRIYA VIDYALAYA SIVAGANGAI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17387",
            "district": "23",
            "Block": "294",
            "label": "ST MICHAEL MATRIC HSS SCHOOL SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17388",
            "district": "23",
            "Block": "294",
            "label": "AL-HUDHA ISLAMIC INTERNATIONAL MATRICULATION HIGHER SECONDARY SCHOOL SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20918",
            "district": "23",
            "Block": "294",
            "label": "MONTFORT  CBSE SCHOOL,SUNDARANADAPPU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20976",
            "district": "23",
            "Block": "294",
            "label": "ST. XAVIER PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21332",
            "district": "23",
            "Block": "294",
            "label": "NEHRUJI CENTRAL HIGHER SECONDARY SCHOOL CBSE. IDAYAMELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21396",
            "district": "23",
            "Block": "294",
            "label": "GHS MUDIKANDAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22234",
            "district": "23",
            "Block": "294",
            "label": "MOUNT LITERA ZEE SCHOOL, SIVAGANGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22592",
            "district": "23",
            "Block": "294",
            "label": "PRIST UNIVERSITY PUBLIC SCHOOL,ARASANOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22593",
            "district": "23",
            "Block": "294",
            "label": "ST. ANNE'S KARUNALAYA RESIDENTIAL SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22702",
            "district": "23",
            "Block": "294",
            "label": "SASTRA ENGLISH SCHOOL GANDHINAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22933",
            "district": "23",
            "Block": "294",
            "label": "GHS ILUPPAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23209",
            "district": "23",
            "Block": "294",
            "label": "SRI THIYAGARAJA MATRICULATION SCHOOL, PERUNGUDI.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23210",
            "district": "23",
            "Block": "294",
            "label": "KENDRIYA VIDYALAYA ITBP , ILLUPPAIKUDI .",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23553",
            "district": "23",
            "Block": "294",
            "label": "SIVAGANGAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23554",
            "district": "23",
            "Block": "294",
            "label": "PANNAI MARIVENI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23967",
            "district": "23",
            "Block": "294",
            "label": "PUMS MUTHUPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23968",
            "district": "23",
            "Block": "294",
            "label": "PUMS MATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23969",
            "district": "23",
            "Block": "294",
            "label": "PUMS ANNANAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23970",
            "district": "23",
            "Block": "294",
            "label": "PUMS PILLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23971",
            "district": "23",
            "Block": "294",
            "label": "SIVAGANGAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "23972",
            "district": "23",
            "Block": "294",
            "label": "SCHOOL OF SCHOLARS",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "295": [
        {
            "key": "433",
            "district": "38",
            "Block": "295",
            "label": "VINAYAKAR MATRICULATION HR SEC SCHOOL,SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "559",
            "district": "38",
            "Block": "295",
            "label": "GLORY MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1967",
            "district": "38",
            "Block": "295",
            "label": "KPATDR SIVAKASI JAYCEES MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2484",
            "district": "38",
            "Block": "295",
            "label": "KMKA MATRICULATION HIGHER SECONDARY SCHOOL, THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2866",
            "district": "38",
            "Block": "295",
            "label": "MUTHUMARI MATRICULATION HR SEC SCHOOL, THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2882",
            "district": "38",
            "Block": "295",
            "label": "THE SIVAKASI LIONS MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2910",
            "district": "38",
            "Block": "295",
            "label": "LORD P C A A  LIONS MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3032",
            "district": "38",
            "Block": "295",
            "label": "SARVASEVA  MATRICULATION SCHOOL,ERICHANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3212",
            "district": "38",
            "Block": "295",
            "label": "V.N.U.A.P.N.T. MATRICULATION HR SEC SCHOOL, VILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3223",
            "district": "38",
            "Block": "295",
            "label": "ARASAN GANESAN MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3252",
            "district": "38",
            "Block": "295",
            "label": "SUNDARAVEL MATRICULATION HR SEC SCHOOL, THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3269",
            "district": "38",
            "Block": "295",
            "label": "N.P.S.S.R.K. ROTARY MATRICULATION HIGHER SECONDARY SCHOOL, VISWANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3343",
            "district": "38",
            "Block": "295",
            "label": "K.KAMARAJAR MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3438",
            "district": "38",
            "Block": "295",
            "label": "SRI SHENBAGA VINAYAGAR MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3495",
            "district": "38",
            "Block": "295",
            "label": "V.S.K.DURAISWAMYNADAR GIRLS MATRICULATION HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4061",
            "district": "38",
            "Block": "295",
            "label": "S.D.A. MATRICULATION HIGH SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4258",
            "district": "38",
            "Block": "295",
            "label": "SRI N M MEHTA JAIN MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4278",
            "district": "38",
            "Block": "295",
            "label": "THIRUTHANGAL LIONS MATRICULATION HR SEC SCHOOL, THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5744",
            "district": "38",
            "Block": "295",
            "label": "S.N.M.F. MATRICULATION HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6667",
            "district": "38",
            "Block": "295",
            "label": "GOOD SHEPHERD MATRICULATION HIGH SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6679",
            "district": "38",
            "Block": "295",
            "label": "SIVAKASI HINDU NADAR VICTORIYA MIDDLE SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18916",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S GOUNDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18917",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S AYYANARPURAM COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18918",
            "district": "38",
            "Block": "295",
            "label": "KAMMA KALVI SANGA HR SEC SCHOOL, SITHURAJAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18919",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S.,BOOVANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18920",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.MATHIYASENAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18921",
            "district": "38",
            "Block": "295",
            "label": "TCH MS, MUTHUKUMARAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18922",
            "district": "38",
            "Block": "295",
            "label": "S N (JUNIOR) G.H.S.,VELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18923",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S KUMILANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18924",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S.,NADAIYANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18925",
            "district": "38",
            "Block": "295",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, RENGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18926",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S., MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18927",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S JAMEENSALVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18928",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S PETHULUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18929",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,ALAMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18930",
            "district": "38",
            "Block": "295",
            "label": "GHS VADAMALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18931",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S, CHITHAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18932",
            "district": "38",
            "Block": "295",
            "label": "G.H.S PUDUKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18933",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.VELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18934",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.SENGAMALAMNATCHIYARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18935",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,SUKKIRAVARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18936",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,ANAIKUTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18937",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S. SANARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18938",
            "district": "38",
            "Block": "295",
            "label": "MEADOWS HIGHER SECONDARY SCHOOL, KALAIYARKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18939",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S, MEENAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18940",
            "district": "38",
            "Block": "295",
            "label": "GOVT HIGH SCHOOL, PERAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18941",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,ANUPPANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18942",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.URAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18943",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S.VADAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18944",
            "district": "38",
            "Block": "295",
            "label": "AVM MARIMUTHU NADAR HR SEC SCHOOL, VILAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18945",
            "district": "38",
            "Block": "295",
            "label": "GOVT HIGHER SECONDARY SCHOOL, MARANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18946",
            "district": "38",
            "Block": "295",
            "label": "P.U.M.S KUPPANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18947",
            "district": "38",
            "Block": "295",
            "label": "VEERAMAMUNIVAR HIGH SCHOOL, KRISHNAPERI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18948",
            "district": "38",
            "Block": "295",
            "label": "KAMAK HIGHER SECONDARY SCHOOL, KRISHNAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18949",
            "district": "38",
            "Block": "295",
            "label": "S.A.V. SALA MIDDLE SCHOOL, MUTHALIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18950",
            "district": "38",
            "Block": "295",
            "label": "SRI KRS. MATRICULATION SCHOOL, VISWANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18951",
            "district": "38",
            "Block": "295",
            "label": "C.M.S. MIDDLE SCHOOL, SATCHIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18952",
            "district": "38",
            "Block": "295",
            "label": "HINDU THEVAMAR HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18953",
            "district": "38",
            "Block": "295",
            "label": "MUNICIPAL HR SEC SCHOOL., AMMANKOVILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18954",
            "district": "38",
            "Block": "295",
            "label": "VELANKANNI MATHA HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18955",
            "district": "38",
            "Block": "295",
            "label": "S.R.,G.H.S.S.,THIRUTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18956",
            "district": "38",
            "Block": "295",
            "label": "A  U  MUNICIPAL HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18957",
            "district": "38",
            "Block": "295",
            "label": "S.H.N. GIRLS HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18958",
            "district": "38",
            "Block": "295",
            "label": "S.N.G. GIRLS HIGHER SECONDARY SCHOOL., THIRUTHANGAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18959",
            "district": "38",
            "Block": "295",
            "label": "CORONATION GIRLS HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18960",
            "district": "38",
            "Block": "295",
            "label": "S.C.M.S.GIRLS HIGHER SECONDARY SCHOOL, SATCHIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18961",
            "district": "38",
            "Block": "295",
            "label": "N.P.A.S.P.R.RATHNAVILAS HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18962",
            "district": "38",
            "Block": "295",
            "label": "SIVAKASI HINDUNADARS GIRLS MIDDLE SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18963",
            "district": "38",
            "Block": "295",
            "label": "MUSLIM HIGHER SECONDARY SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18964",
            "district": "38",
            "Block": "295",
            "label": "SIVAKASI HINDU NADAR VICTORIA HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18965",
            "district": "38",
            "Block": "295",
            "label": "YRTV MAT HR SEC SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18966",
            "district": "38",
            "Block": "295",
            "label": "KALAIMAGAL HIGHER SECONDARY SCHOOL, THIRUTHANGAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18967",
            "district": "38",
            "Block": "295",
            "label": "SAFIRE MATRICULATION HIGHER SECONDARY  SCHOOL,SIVAKASI- THAYILPATTI ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18968",
            "district": "38",
            "Block": "295",
            "label": "SHNV MATRIC HSS,SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18969",
            "district": "38",
            "Block": "295",
            "label": "SRUTHI VIDHYODHAYA , SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18970",
            "district": "38",
            "Block": "295",
            "label": "CSI HIGH SCHOOL & VTC FOR THE DEAF, SATCHIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18971",
            "district": "38",
            "Block": "295",
            "label": "CSI M&R SCHOOL, SATCHIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18972",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S.,RESERVE LINE SIVAKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18973",
            "district": "38",
            "Block": "295",
            "label": "GOVT GIRLS HR SEC SCHOOL, THIRUTHANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18974",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S,VISWANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18975",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S., NARANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18976",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.S, PALLAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18977",
            "district": "38",
            "Block": "295",
            "label": "ANNAVI THOTTAM MUNICIPAL GIRLS HIGH SCHOOL, SIVAKASI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18978",
            "district": "38",
            "Block": "295",
            "label": "GOVT HIGH SCHOOL ANAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20977",
            "district": "38",
            "Block": "295",
            "label": "MOUNT LITERA ZEE SCHOOL., SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20978",
            "district": "38",
            "Block": "295",
            "label": "V S K D NADAR GURUKUL MATRIC HR SEC SCHOOL, SATCHIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21034",
            "district": "38",
            "Block": "295",
            "label": "LVR MATRICULATION SCHOOL,SENGAMALANACHIARPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21115",
            "district": "38",
            "Block": "295",
            "label": "AAA INTERNATIONAL SCHOOL.,THIRUTHANGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21171",
            "district": "38",
            "Block": "295",
            "label": "ANITHA MATRICULATION HSS ,KONGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21405",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,M.PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21406",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,KOPPAIYANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21857",
            "district": "38",
            "Block": "295",
            "label": "AYYAVU MATRICULATION HIGH SCHOOL, VADAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22625",
            "district": "38",
            "Block": "295",
            "label": "G.H.S.,SATHYANAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22786",
            "district": "38",
            "Block": "295",
            "label": "SRI KRISHNASAMY INTERNATIONAL SCHOOL, MARANERI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23290",
            "district": "38",
            "Block": "295",
            "label": "R.S.R INTERNATIONAL SCHOOL, SIVAKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23642",
            "district": "38",
            "Block": "295",
            "label": "GOOD SHEPHERD MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23643",
            "district": "38",
            "Block": "295",
            "label": "SIDHARAJAA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23642",
            "district": "38",
            "Block": "295",
            "label": "GOOD SHEPHERD MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23643",
            "district": "38",
            "Block": "295",
            "label": "SIDHARAJAA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "296": [
        {
            "key": "4831",
            "district": "10",
            "Block": "296",
            "label": "St.Mary's MHSS Sriperumbudur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7721",
            "district": "10",
            "Block": "296",
            "label": "GHS, PITCHIVAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23699",
            "district": "10",
            "Block": "296",
            "label": "st.vincent matriculation school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7722",
            "district": "10",
            "Block": "296",
            "label": "PUMS O M MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7723",
            "district": "10",
            "Block": "296",
            "label": "GHS, EDAYARPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7724",
            "district": "10",
            "Block": "296",
            "label": "PUMS EGANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7725",
            "district": "10",
            "Block": "296",
            "label": "PUMS KOOTHAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7726",
            "district": "10",
            "Block": "296",
            "label": "GHSS, MADURAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7727",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL,KEERANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7728",
            "district": "10",
            "Block": "296",
            "label": "PUMS,PODAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7729",
            "district": "10",
            "Block": "296",
            "label": "GHSS, MOLACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7730",
            "district": "10",
            "Block": "296",
            "label": "MORNING STAR MATRICULATION SCHOOL, MOLACHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7731",
            "district": "10",
            "Block": "296",
            "label": "PUMS S V CHATRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7732",
            "district": "10",
            "Block": "296",
            "label": "MAHARISHI INTL.RESI.,SANTHAVELLORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7733",
            "district": "10",
            "Block": "296",
            "label": "GHS, SANTHAVELLORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7734",
            "district": "10",
            "Block": "296",
            "label": "D.A.V.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7735",
            "district": "10",
            "Block": "296",
            "label": "GHS, KUNNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7736",
            "district": "10",
            "Block": "296",
            "label": "PUMS ECHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7737",
            "district": "10",
            "Block": "296",
            "label": "PUMS - THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7738",
            "district": "10",
            "Block": "296",
            "label": "PUMS MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7739",
            "district": "10",
            "Block": "296",
            "label": "PUMS PONDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23413",
            "district": "10",
            "Block": "296",
            "label": "ST.THOMAS MATRICULATION  SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7740",
            "district": "10",
            "Block": "296",
            "label": "PUMS VADAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7741",
            "district": "10",
            "Block": "296",
            "label": "ADWMS- KILOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7742",
            "district": "10",
            "Block": "296",
            "label": "MARTHOMA PUPLIC NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7743",
            "district": "10",
            "Block": "296",
            "label": "PUMS AYAKOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7744",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL SENGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7745",
            "district": "10",
            "Block": "296",
            "label": "PUMS NEMILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7746",
            "district": "10",
            "Block": "296",
            "label": "ST.ANNES (AIDED) HIGH SCHOOL, VALARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7747",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL, MEVALURKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7748",
            "district": "10",
            "Block": "296",
            "label": "APPOLLO VIDYASHRAM MEVALURKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7749",
            "district": "10",
            "Block": "296",
            "label": "GHSS 101 ZAMIN THANDALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7750",
            "district": "10",
            "Block": "296",
            "label": "PUMS-IRUNKATTUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7751",
            "district": "10",
            "Block": "296",
            "label": "PUMS PENNALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7752",
            "district": "10",
            "Block": "296",
            "label": "PUMS PUDUPPER",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7753",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL VENGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7754",
            "district": "10",
            "Block": "296",
            "label": "PUMS PILLAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7755",
            "district": "10",
            "Block": "296",
            "label": "GHS, KOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7756",
            "district": "10",
            "Block": "296",
            "label": "GHS, MAGANYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7757",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGH SCHOOL GUNDUPERUMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7758",
            "district": "10",
            "Block": "296",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL. PANRUTTI - 631 604.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7759",
            "district": "10",
            "Block": "296",
            "label": "PUMS, ERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7760",
            "district": "10",
            "Block": "296",
            "label": "L. F. H. S. S (Aided) THERESAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7761",
            "district": "10",
            "Block": "296",
            "label": "P. S. TEMPLE GREEN VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7762",
            "district": "10",
            "Block": "296",
            "label": "TSK GOVERNMENT HIGHER SECONDARY SCHOOL MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7763",
            "district": "10",
            "Block": "296",
            "label": "P.U.M.S.GOVT.EMPLOYERS QUARTERS.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7764",
            "district": "10",
            "Block": "296",
            "label": "PUMS SPR SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7765",
            "district": "10",
            "Block": "296",
            "label": "J J GBHSS SRIPERUMBUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7766",
            "district": "10",
            "Block": "296",
            "label": "GHSS (GIRLS) SRIPERUMBUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7767",
            "district": "10",
            "Block": "296",
            "label": "CSI HIGH SCHOOL SRIPERUMBUDUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7768",
            "district": "10",
            "Block": "296",
            "label": "VIVEKANANDA VIDYALAYA MATRIC . HR. SEC. SCHOOL, SRIPERUMBUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7769",
            "district": "10",
            "Block": "296",
            "label": "HAYAGRIVA VIDHYASHRAM SENIOR SECONDARY SCHOOL. SRIPERUMBUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7770",
            "district": "10",
            "Block": "296",
            "label": "ST.JOSEPH'S RESIDENTIAL SCHOOL SRIPERUMBUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7771",
            "district": "10",
            "Block": "296",
            "label": "KSHETRA VIDYALAYA,MADUVANKARAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21049",
            "district": "10",
            "Block": "296",
            "label": "RYAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21298",
            "district": "10",
            "Block": "296",
            "label": "Maharashi Vidha Mandir School, Irunkattukottai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21525",
            "district": "10",
            "Block": "296",
            "label": "RCM (Aided) HS,Chellampattidai",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22022",
            "district": "10",
            "Block": "296",
            "label": "COSMOPOLITAN MODEL VIDYALYA, SENGADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22023",
            "district": "10",
            "Block": "296",
            "label": "LOYOLA INTERNATIONAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22024",
            "district": "10",
            "Block": "296",
            "label": "Montfort School, Theresapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22341",
            "district": "10",
            "Block": "296",
            "label": "GHS KATRAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22342",
            "district": "10",
            "Block": "296",
            "label": "The Schram Academy, Irumgattukottai",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22343",
            "district": "10",
            "Block": "296",
            "label": "Swami Vivekananda Vidya Peedam, Sriperumbudur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23973",
            "district": "10",
            "Block": "296",
            "label": "ST. VINCENT'S NURSERY AND PRIMARY SCHOOL, MOLASUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23974",
            "district": "10",
            "Block": "296",
            "label": "PUMS MAMBAKKAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23975",
            "district": "10",
            "Block": "296",
            "label": "ST.ANTHONY'S SCHOOL, KUNNAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "297": [
        {
            "key": "1100",
            "district": "28",
            "Block": "297",
            "label": "PUMS VELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1102",
            "district": "28",
            "Block": "297",
            "label": "PUMS ESAVANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1719",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS SEBATHIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2163",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS AGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2202",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS ERAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2318",
            "district": "28",
            "Block": "297",
            "label": "ST ANTONYS RC MS MUKKANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2497",
            "district": "28",
            "Block": "297",
            "label": "ST MARYS MS PAZHAYAKAYAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2645",
            "district": "28",
            "Block": "297",
            "label": "HINDU MS ALWARTHOPE WEST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2658",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MM MS ARUMUGAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2741",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS ANIYABARANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3123",
            "district": "28",
            "Block": "297",
            "label": "HINDHU ADI DRAVIDA MS SIVAGALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3274",
            "district": "28",
            "Block": "297",
            "label": "PUMS GANAPATHISAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3772",
            "district": "28",
            "Block": "297",
            "label": "PUMS MEENACHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3909",
            "district": "28",
            "Block": "297",
            "label": "RC MS G.PATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4162",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS KOVANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4420",
            "district": "28",
            "Block": "297",
            "label": "PUMS PADMANABAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4434",
            "district": "28",
            "Block": "297",
            "label": "PUMS PUTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5210",
            "district": "28",
            "Block": "297",
            "label": "HINDU MS PANDARAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5415",
            "district": "28",
            "Block": "297",
            "label": "SRI SUNDARA VINAYAGAR MS VELLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5509",
            "district": "28",
            "Block": "297",
            "label": "RC MS SEBATHIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5828",
            "district": "28",
            "Block": "297",
            "label": "HAJI MEEYAN ABDUL CADER MS SRIVAIKUNDAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6133",
            "district": "28",
            "Block": "297",
            "label": "PUMS SRIVAIKUNTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6344",
            "district": "28",
            "Block": "297",
            "label": "PUMS PERUNKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6704",
            "district": "28",
            "Block": "297",
            "label": "LOABA MATRICULATION HIGH SCHOOL,  ERAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19344",
            "district": "28",
            "Block": "297",
            "label": "PUMS PONNANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19345",
            "district": "28",
            "Block": "297",
            "label": "APC VEERABAHU HS PUDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19346",
            "district": "28",
            "Block": "297",
            "label": "MK HS PATMANAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19347",
            "district": "28",
            "Block": "297",
            "label": "MM MAT HSS PATMANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19348",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS PEROOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19349",
            "district": "28",
            "Block": "297",
            "label": "GHSS SIVAKALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19350",
            "district": "28",
            "Block": "297",
            "label": "SMA GHSS SIRUTHONDANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19351",
            "district": "28",
            "Block": "297",
            "label": "GHSS VALAVALLAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19352",
            "district": "28",
            "Block": "297",
            "label": "ST ALOYSIUS MS KORKAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19353",
            "district": "28",
            "Block": "297",
            "label": "GHSS UMARIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19354",
            "district": "28",
            "Block": "297",
            "label": "TDTA GOOD SHEPHERD HS IDAIYERKADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19355",
            "district": "28",
            "Block": "297",
            "label": "PUMS PULVAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19356",
            "district": "28",
            "Block": "297",
            "label": "ST ANTONYS HSS PAZHAIYAKAYAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19357",
            "district": "28",
            "Block": "297",
            "label": "GHSS MUKKANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19358",
            "district": "28",
            "Block": "297",
            "label": "RC MS NADUVAIKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19359",
            "district": "28",
            "Block": "297",
            "label": "POPE MEMORIAL HR.SEC.SCHOOL SAWYERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19360",
            "district": "28",
            "Block": "297",
            "label": "ST.MARYS (G)HSS,  SAWYERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19361",
            "district": "28",
            "Block": "297",
            "label": "AMA HINDU HSS SIVATHAIYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19362",
            "district": "28",
            "Block": "297",
            "label": "GGHSS ERAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19363",
            "district": "28",
            "Block": "297",
            "label": "ST THERESAL MS ERAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19364",
            "district": "28",
            "Block": "297",
            "label": "TUCKER HR SEC. SCHOOL.. PANNAIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19365",
            "district": "28",
            "Block": "297",
            "label": "TNDTA MS PANDARAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19366",
            "district": "28",
            "Block": "297",
            "label": "SEVENTH DAY ADVENTIST MATRIC, SRIVAIKUNDAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19367",
            "district": "28",
            "Block": "297",
            "label": "ST JAMES MATRIC HS SRIVAIKUNTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19368",
            "district": "28",
            "Block": "297",
            "label": "SRI KGS B HSS SRIVAIKUNTAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19369",
            "district": "28",
            "Block": "297",
            "label": "SRI A.K.S GOVT. GIRLS HIGHER SECONDARY SCHOOL , SRIVAIKUNTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19370",
            "district": "28",
            "Block": "297",
            "label": "TVRK H VID MAT HSS SRIVAIKUNTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19371",
            "district": "28",
            "Block": "297",
            "label": "ULAGAMMAL ANNI MEMORIAL MS SRIVAIKUNDAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "22636",
            "district": "28",
            "Block": "297",
            "label": "THE VIKASA INTERNATIONAL SCHOOL, SAWYERPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22691",
            "district": "28",
            "Block": "297",
            "label": "ATOMIC ENERGY CENTRAL SCHOOL, PAZHAYAKAYAL",
            "Sch_type": "Central Govt",
            "Category": "Middle School"
        },
        {
            "key": "22956",
            "district": "28",
            "Block": "297",
            "label": "GHS THOLAPPANPANNAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23002",
            "district": "28",
            "Block": "297",
            "label": "THE VIKASA SCHOOL CBSE, SAWYERPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "298": [
        {
            "key": "65",
            "district": "38",
            "Block": "298",
            "label": "C.M.S. HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "66",
            "district": "38",
            "Block": "298",
            "label": "GOVT ADW HIGH SCHOOL, IDAYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "67",
            "district": "38",
            "Block": "298",
            "label": "VPMS SESHA MATRICULATION SCHOOL , SRIVILLIPUTHUR ,",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "68",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGH SCHOOL, THOMBAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "69",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PILLAIYARNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "70",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KRISHNANKOVIL - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "71",
            "district": "38",
            "Block": "298",
            "label": "GOVT ADW HIGHER SECONDARY SCHOOL, KARISALKULAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "72",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGH SCHOOL, PANANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "73",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGH SCHOOL, T.MANAGASERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "74",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ULLOORPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "75",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PAPPAYANAICKERPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "76",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KEELARAJAKULARAMAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "77",
            "district": "38",
            "Block": "298",
            "label": "GURUGNANA SAMPANDAR HINDU HSS, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "78",
            "district": "38",
            "Block": "298",
            "label": "JOTHI HIGH SCHOOL,R.REDDIAPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "23698",
            "district": "38",
            "Block": "298",
            "label": "st antony matriculation school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "79",
            "district": "38",
            "Block": "298",
            "label": "KAMARAJAR  GOVERNMENT MIDDLE SCHOOL, MALLIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "80",
            "district": "38",
            "Block": "298",
            "label": "P.N.U.P.KAMARAJAR MATRICULATION HR.SEC.SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "81",
            "district": "38",
            "Block": "298",
            "label": "MANGAPURAM HINDU HR SEC SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "82",
            "district": "38",
            "Block": "298",
            "label": "M.N.R.D.GIRLS HSS, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "83",
            "district": "38",
            "Block": "298",
            "label": "KAMMAPATTI MUNICIPAL MIDDLE SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "84",
            "district": "38",
            "Block": "298",
            "label": "MUNICIPAL MIDDLE SCHOOL, KRISHNAN KOVIL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "85",
            "district": "38",
            "Block": "298",
            "label": "METTU STREET MUNICIPAL MIDDLE SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "86",
            "district": "38",
            "Block": "298",
            "label": "MUNICIPAL MIDDLE SCHOOL, PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "87",
            "district": "38",
            "Block": "298",
            "label": "MUNICIPAL TVK HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "88",
            "district": "38",
            "Block": "298",
            "label": "MUNICIPAL MIDDLE SCHOOL, URANIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "89",
            "district": "38",
            "Block": "298",
            "label": "NK GOPALSAMY MIDDLE SCHOOL NACHIYARPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "90",
            "district": "38",
            "Block": "298",
            "label": "PASUMPON THEVAR HIGHER SECONDARY SCHOOL, MAMSAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "91",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SAMINATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "92",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, POOVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "93",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONGALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "94",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "95",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AKKARAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "96",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHOKKALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "97",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PERUMALDEVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "98",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "99",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL INAM KARISALKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "100",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, INDRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "101",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, K.THOTTIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "102",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NAGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "103",
            "district": "38",
            "Block": "298",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NARAIYANKULAM OTHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "104",
            "district": "38",
            "Block": "298",
            "label": "RC.MIDDLE.SCHOOL,PATTAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "105",
            "district": "38",
            "Block": "298",
            "label": "R.K.GOVERNMENT HIGHER SECONDARY SCHOOL, P.RAMACHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "106",
            "district": "38",
            "Block": "298",
            "label": "SACRED HEART GIRLS HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "107",
            "district": "38",
            "Block": "298",
            "label": "SIVANTHIPATTI NADAR GIRLS HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "108",
            "district": "38",
            "Block": "298",
            "label": "SIVANTHIPATTI NADAR HSS, MAMSAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "109",
            "district": "38",
            "Block": "298",
            "label": "SIVARAM HIGH SCHOOL, MAMSAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "110",
            "district": "38",
            "Block": "298",
            "label": "SIVARAM MIDDLE SCHOOL,MAMSAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "111",
            "district": "38",
            "Block": "298",
            "label": "SRIVI LIONS MATRICULATION HSS, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "112",
            "district": "38",
            "Block": "298",
            "label": "ST.JOSEPH RC HR SEC SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "113",
            "district": "38",
            "Block": "298",
            "label": "THIYAGARAJA HIGHER SECONDARY SCHOOL., SRIVILLIPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "114",
            "district": "38",
            "Block": "298",
            "label": "VALLUVAR VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "115",
            "district": "38",
            "Block": "298",
            "label": "VENKADASAMY NAIDU MIDDLE SCHOOL, M.P.K.PUDUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "116",
            "district": "38",
            "Block": "298",
            "label": "VNUP.KALEESWARI HSS, VANNIAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "117",
            "district": "38",
            "Block": "298",
            "label": "V.P.M.M.MATRICULATION HSS, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21114",
            "district": "38",
            "Block": "298",
            "label": "SRI MAYURA INTERNATIONAL SCH APPAIYANAYAKAR PATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21340",
            "district": "38",
            "Block": "298",
            "label": "MAHARISHI VIDYA MANDIR SECONDARY SCHOOL - CBSE, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21341",
            "district": "38",
            "Block": "298",
            "label": "SATHYA VIDYALAYA CBSE,PILLAYARKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21650",
            "district": "38",
            "Block": "298",
            "label": "PENNINGTON PUBLIC SCHOOL CBSE, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21938",
            "district": "38",
            "Block": "298",
            "label": "SACRED HEART CONVENT HIGH SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22266",
            "district": "38",
            "Block": "298",
            "label": "SRI AYYAN KENDRA VIDYALAYA INTERNATIONAL SCHOOL, KALANGAPERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22622",
            "district": "38",
            "Block": "298",
            "label": "GOVERNMENT HIGH SCHOOL, LAKSHMIAPURAMPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22623",
            "district": "38",
            "Block": "298",
            "label": "YASHAS ENGLISH SCHOOL.,MALLI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22787",
            "district": "38",
            "Block": "298",
            "label": "SRIVI LIONS INTERNATIONAL SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22788",
            "district": "38",
            "Block": "298",
            "label": "VPMS SELVI SHANKAR(SESHA) VIDYASRAM SCHOOL, SRIVILLIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23644",
            "district": "38",
            "Block": "298",
            "label": "TELUGU KULALAR SRI SOOLAI VINAYAGAR VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23645",
            "district": "38",
            "Block": "298",
            "label": "HINDU MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23644",
            "district": "38",
            "Block": "298",
            "label": "TELUGU KULALAR SRI SOOLAI VINAYAGAR VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23645",
            "district": "38",
            "Block": "298",
            "label": "HINDU MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23976",
            "district": "38",
            "Block": "298",
            "label": "Virudhunagar District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "299": [
        {
            "key": "4240",
            "district": "2",
            "Block": "299",
            "label": "Mother Matriculation Higher Secondary School, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4262",
            "district": "2",
            "Block": "299",
            "label": "SREE IYAPPA MHSS, SOZHANGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4281",
            "district": "2",
            "Block": "299",
            "label": "TAGORE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4323",
            "district": "2",
            "Block": "299",
            "label": "Peniel Matric HSS, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23649",
            "district": "2",
            "Block": "299",
            "label": "RV matriculation school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4393",
            "district": "2",
            "Block": "299",
            "label": "kalaimagal Vidhya Mandir MHSS  madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4657",
            "district": "2",
            "Block": "299",
            "label": "Christ The King Matriculation HSS, Medavakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4661",
            "district": "2",
            "Block": "299",
            "label": "SRI SANKARA MAT SCHOOL THURAIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4692",
            "district": "2",
            "Block": "299",
            "label": "Sacred Heart MHSS,Nanmangalam.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23358",
            "district": "2",
            "Block": "299",
            "label": "St.Paul’s matriculation higher secondary school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23353",
            "district": "2",
            "Block": "299",
            "label": "RV Matriculation school",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4696",
            "district": "2",
            "Block": "299",
            "label": "Sri S. B.S.C VIVEKANANDA VIDYALAYA, MUDICHUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4751",
            "district": "2",
            "Block": "299",
            "label": "ST ANTONY'S MATRIC HR. SEC. SCHOOL, KOTTIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4802",
            "district": "2",
            "Block": "299",
            "label": "Sri Selva Vignesh, Pozhichalur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4809",
            "district": "2",
            "Block": "299",
            "label": "St John's  Matriculation  Higher  Secondary  School  ,Perungalathur.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4860",
            "district": "2",
            "Block": "299",
            "label": "MARYA NIVAS MHSS, POZHICHALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4878",
            "district": "2",
            "Block": "299",
            "label": "EBENEZER MATRIC HIGH SCHOOL, NEW PERUNGALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4951",
            "district": "2",
            "Block": "299",
            "label": "ANNAI VIOLET MATRICULATION HIGHER SECONDARY SCHOOL CHITLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4964",
            "district": "2",
            "Block": "299",
            "label": "MANUELMONY MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5037",
            "district": "2",
            "Block": "299",
            "label": "LYDIA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5060",
            "district": "2",
            "Block": "299",
            "label": "SRI SESHADRI MHSS CHROMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5073",
            "district": "2",
            "Block": "299",
            "label": "NAPPINNAI MAT SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5276",
            "district": "2",
            "Block": "299",
            "label": "Rosily MHSS Chitlapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5284",
            "district": "2",
            "Block": "299",
            "label": "St.Peter's Matriculation Higher Secondary School,Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5286",
            "district": "2",
            "Block": "299",
            "label": "Brilliant MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5293",
            "district": "2",
            "Block": "299",
            "label": "ST GEORGE MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5324",
            "district": "2",
            "Block": "299",
            "label": "Rajkumar Sulochana MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5326",
            "district": "2",
            "Block": "299",
            "label": "ELLEN SHARMA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5351",
            "district": "2",
            "Block": "299",
            "label": "ZION MAT HSS MADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5375",
            "district": "2",
            "Block": "299",
            "label": "Loyola MHSS Chitlabakkam ch-64",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5401",
            "district": "2",
            "Block": "299",
            "label": "JOSHUA MHSS ,OLD PERUNGALATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5408",
            "district": "2",
            "Block": "299",
            "label": "Infant Jesus Mat. Sch, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5411",
            "district": "2",
            "Block": "299",
            "label": "St.Antony Matric HSS, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5491",
            "district": "2",
            "Block": "299",
            "label": "LAXMI NARAYANA MATRIC SCHOOL,CHENNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5535",
            "district": "2",
            "Block": "299",
            "label": "St John's MS Madambakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5634",
            "district": "2",
            "Block": "299",
            "label": "Jeppiaar Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23409",
            "district": "2",
            "Block": "299",
            "label": "The Indian Public School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "5638",
            "district": "2",
            "Block": "299",
            "label": "St. JOHN'S  MATRIC. HR.SEC.SCHOOL, DURGA NAGAR,TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5751",
            "district": "2",
            "Block": "299",
            "label": "ZION MATRICULATION HIGHER SECONDARY  SCHOOL,  SEMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5764",
            "district": "2",
            "Block": "299",
            "label": "AALFA MATRIC HR SEC SCHOOL PEERKANKARANAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5800",
            "district": "2",
            "Block": "299",
            "label": "PROF. S.A. MAT. HR. SEC. SCHOOL ,Semmanchery-119",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6022",
            "district": "2",
            "Block": "299",
            "label": "ST PIUS MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6028",
            "district": "2",
            "Block": "299",
            "label": "Kings MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6050",
            "district": "2",
            "Block": "299",
            "label": "MONTFORT MHSS, PERUNGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6076",
            "district": "2",
            "Block": "299",
            "label": "NSN MHSS CHITLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6277",
            "district": "2",
            "Block": "299",
            "label": "SHRIRAM MATRICULATION SCHOOL, THIRUNEERMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6365",
            "district": "2",
            "Block": "299",
            "label": "BHARATH DASS MHSS PERUNGUDI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6370",
            "district": "2",
            "Block": "299",
            "label": "VANI MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6384",
            "district": "2",
            "Block": "299",
            "label": "A.V.G. VIDYALAYA MATRIC.HIGHER SECONDARY SCHOOL,KOVILAMBAKKAM .",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6613",
            "district": "2",
            "Block": "299",
            "label": "ZIGMA MHSS, MEDAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6633",
            "district": "2",
            "Block": "299",
            "label": "SACRED HEART MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6705",
            "district": "2",
            "Block": "299",
            "label": "VGP  PHILOMINAL  MATRICULATION  SCHOOL , INJAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8302",
            "district": "2",
            "Block": "299",
            "label": "ADWHSS, Paduvanchery",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8303",
            "district": "2",
            "Block": "299",
            "label": "PUMS VENGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8304",
            "district": "2",
            "Block": "299",
            "label": "GHS COWLBAZAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8305",
            "district": "2",
            "Block": "299",
            "label": "PUMS Mootaikkaranchavadi",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8306",
            "district": "2",
            "Block": "299",
            "label": "ST.JOSEPHS HR.SEC.SCHOOL,VETTUVANKENI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8307",
            "district": "2",
            "Block": "299",
            "label": "ALM MAT HSS INJAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8308",
            "district": "2",
            "Block": "299",
            "label": "ADVENT CHRISTIAN HIGHSCHOOL  INJAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8309",
            "district": "2",
            "Block": "299",
            "label": "Pon Vidyashram (CBSE),Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8310",
            "district": "2",
            "Block": "299",
            "label": "PRIM ROSE School,Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8311",
            "district": "2",
            "Block": "299",
            "label": "PUMS JALLADIANPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8312",
            "district": "2",
            "Block": "299",
            "label": "Baynes MBC MHSS, Jaladianpet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8313",
            "district": "2",
            "Block": "299",
            "label": "GHS, KARAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8314",
            "district": "2",
            "Block": "299",
            "label": "Ramana Vidyalaya, Sholinganallur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8315",
            "district": "2",
            "Block": "299",
            "label": "GHSS,KOVILAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8316",
            "district": "2",
            "Block": "299",
            "label": "Himayam Matric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8317",
            "district": "2",
            "Block": "299",
            "label": "ST..MARY'S  MATRIC  HR. SEC. SCHOOL KOTTIVAKKAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8318",
            "district": "2",
            "Block": "299",
            "label": "YMCA (Aided) HSS, Kottivakkam",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8319",
            "district": "2",
            "Block": "299",
            "label": "NELLAI NADAR MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8320",
            "district": "2",
            "Block": "299",
            "label": "PUMS MADIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8321",
            "district": "2",
            "Block": "299",
            "label": "HOLY PRINCE MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8322",
            "district": "2",
            "Block": "299",
            "label": "Prince MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8323",
            "district": "2",
            "Block": "299",
            "label": "SAI RAM VIDYALAYA SENIOR SECONDARY SCHOOL(CBSE), MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8324",
            "district": "2",
            "Block": "299",
            "label": "Sai MHSS Madipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8325",
            "district": "2",
            "Block": "299",
            "label": "pole star matric hr sec school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8326",
            "district": "2",
            "Block": "299",
            "label": "GHS NANMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8327",
            "district": "2",
            "Block": "299",
            "label": "VIDHYA MATRIC.HR.SEC.SCHOOL,JALLADIANPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8328",
            "district": "2",
            "Block": "299",
            "label": "BORN SCHOLARS  MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8329",
            "district": "2",
            "Block": "299",
            "label": "GHSS Medavakkam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8330",
            "district": "2",
            "Block": "299",
            "label": "St.Joseph MHSS,Medavakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8331",
            "district": "2",
            "Block": "299",
            "label": "GHSS, MOOVARASAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8332",
            "district": "2",
            "Block": "299",
            "label": "PUMS, ANNAI INDRA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8333",
            "district": "2",
            "Block": "299",
            "label": "PUMS NEELANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8334",
            "district": "2",
            "Block": "299",
            "label": "BROTHER HOOD MAT HSS NEELANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8335",
            "district": "2",
            "Block": "299",
            "label": "Sunbeam Matriculation Hr.sec.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8336",
            "district": "2",
            "Block": "299",
            "label": "IID ELIM MAT SCHOOL  METUKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8337",
            "district": "2",
            "Block": "299",
            "label": "VIJAY NURSERY & PRIMARY SCHOOL, METTUKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8338",
            "district": "2",
            "Block": "299",
            "label": "GHSS, OKKIYAM THURAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8339",
            "district": "2",
            "Block": "299",
            "label": "CAUVERY MATRICULATION HIGHER SECONDARY SCHOOL,THURAIPAKKAM, CHENNAI - 97",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8340",
            "district": "2",
            "Block": "299",
            "label": "AKSHARA MAT  HSS OKKIAM THURAIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8341",
            "district": "2",
            "Block": "299",
            "label": "LITTLE ANGELS MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8342",
            "district": "2",
            "Block": "299",
            "label": "GHSS, KANNAGI NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8343",
            "district": "2",
            "Block": "299",
            "label": "HOPE FOUNDATION MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8344",
            "district": "2",
            "Block": "299",
            "label": "PUMS OTTIYAMPAKKAAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8345",
            "district": "2",
            "Block": "299",
            "label": "ADW High School, Palavakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8346",
            "district": "2",
            "Block": "299",
            "label": "DR.AL MUDALIYAR MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8347",
            "district": "2",
            "Block": "299",
            "label": "PUMS  Pozhichalur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8348",
            "district": "2",
            "Block": "299",
            "label": "Sri Sarada Eashwar Vidya Mandir MHSS ,Pozhichalur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8349",
            "district": "2",
            "Block": "299",
            "label": "PUMS, NOOKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8350",
            "district": "2",
            "Block": "299",
            "label": "PUMS Semmancherry",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8351",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,SEMMANCHERRY(SCB)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8352",
            "district": "2",
            "Block": "299",
            "label": "ST PAUL'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8353",
            "district": "2",
            "Block": "299",
            "label": "Alpha Nursery & Primary School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8354",
            "district": "2",
            "Block": "299",
            "label": "MAYFIELD RESIDENTIAL SCHOOL SITHALAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8355",
            "district": "2",
            "Block": "299",
            "label": "GOVT HIGH SCHOOL SITHALAPAKKAM.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8356",
            "district": "2",
            "Block": "299",
            "label": "PUMS THIRUSULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8357",
            "district": "2",
            "Block": "299",
            "label": "K.V No:1,AFS, Madambakkam",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8358",
            "district": "2",
            "Block": "299",
            "label": "KENDRIYA VIDYALAYA No:2,AFS ,Selaiyur",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8359",
            "district": "2",
            "Block": "299",
            "label": "GHSS, THIRUVANCHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8360",
            "district": "2",
            "Block": "299",
            "label": "GHS UTHANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8361",
            "district": "2",
            "Block": "299",
            "label": "SHRI SHAKTHI VIDYALAYA NURSERY AND PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8362",
            "district": "2",
            "Block": "299",
            "label": "Sudananda Vidyalaya,Uthandi",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8363",
            "district": "2",
            "Block": "299",
            "label": "PUMS Santhoshapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8364",
            "district": "2",
            "Block": "299",
            "label": "St.Paul's Matriculation School, Vijayanagaram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8365",
            "district": "2",
            "Block": "299",
            "label": "ABHIJAY NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8366",
            "district": "2",
            "Block": "299",
            "label": "SRI SUBA BALA BHAVAN NURSERY & PRIMARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8367",
            "district": "2",
            "Block": "299",
            "label": "GHS, CHITLAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8368",
            "district": "2",
            "Block": "299",
            "label": "JANS MATRICULATION HIGHER SECONDARY SCHOOL CHITLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8369",
            "district": "2",
            "Block": "299",
            "label": "OLIVE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8370",
            "district": "2",
            "Block": "299",
            "label": "GOVT. ADW HR.SEC.SCHOOL, MEENAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8371",
            "district": "2",
            "Block": "299",
            "label": "Infant Jesus Matriculation High School, Pazhavanthangal",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8372",
            "district": "2",
            "Block": "299",
            "label": "GHSS MADAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8373",
            "district": "2",
            "Block": "299",
            "label": "IDPL Matric HSS,Nandambakkam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8374",
            "district": "2",
            "Block": "299",
            "label": "JACINTH MS, Narayanapuram, Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8375",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, PALLIKARANAI, CHENNAI 600100",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8376",
            "district": "2",
            "Block": "299",
            "label": "PUMS PUDUKUDIYIRUPPU PALLIKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8377",
            "district": "2",
            "Block": "299",
            "label": "SHREE VIDYA MANDIR MATRIC. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8378",
            "district": "2",
            "Block": "299",
            "label": "Kalaimagal Kalasalai Middle School, Peerkankaranai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8379",
            "district": "2",
            "Block": "299",
            "label": "GHSS PEERKANKARANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8380",
            "district": "2",
            "Block": "299",
            "label": "St.Annes Matric HSS, Perungalathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8381",
            "district": "2",
            "Block": "299",
            "label": "GHSS, old Perungalathur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8382",
            "district": "2",
            "Block": "299",
            "label": "Abacus Montessori School, Perungudi, Chennai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8383",
            "district": "2",
            "Block": "299",
            "label": "SANGFORD N&P SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8384",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERUNGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8385",
            "district": "2",
            "Block": "299",
            "label": "SHANTHINIKETAN MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8386",
            "district": "2",
            "Block": "299",
            "label": "Advent Christian Middle School, Sembakkam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8387",
            "district": "2",
            "Block": "299",
            "label": "ST. MARYS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8388",
            "district": "2",
            "Block": "299",
            "label": "ALPHA MATRICULATION HR. SEC. SCHOOL, SEMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8389",
            "district": "2",
            "Block": "299",
            "label": "Boaz Public School,Sembakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8390",
            "district": "2",
            "Block": "299",
            "label": "M.A.V. VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8391",
            "district": "2",
            "Block": "299",
            "label": "GHSS SHOLINGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8392",
            "district": "2",
            "Block": "299",
            "label": "PUMS SHOLINGANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8393",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT HIGH SCHOOL,KUDUMIYANDITHOPPU, CHENNAI 119",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8394",
            "district": "2",
            "Block": "299",
            "label": "JMKS GHS LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8395",
            "district": "2",
            "Block": "299",
            "label": "PUMS THIRUNEERMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8396",
            "district": "2",
            "Block": "299",
            "label": "Sri Vidhyaalayaa Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8397",
            "district": "2",
            "Block": "299",
            "label": "SRIMATHI SUNDARAVALLI MEMORIAL SCHOOL CHROMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20923",
            "district": "2",
            "Block": "299",
            "label": "GOVERNMENT  SCHOOL FOR THE HEARING IMPAIRED,,TAMBARAM SANATORIUM,CHENNAI 600047",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20946",
            "district": "2",
            "Block": "299",
            "label": "BVM Global School, Seevaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21002",
            "district": "2",
            "Block": "299",
            "label": "Annie matric. Hr. Sec.school,Mudichur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21055",
            "district": "2",
            "Block": "299",
            "label": "NSN Memorial Sr.Sec. school(CBSE) , Chitlapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21127",
            "district": "2",
            "Block": "299",
            "label": "Dr.C.L.Metha Sishya OMR School, Thoraipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21176",
            "district": "2",
            "Block": "299",
            "label": "GT Aloha Vidhya Mandir",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21177",
            "district": "2",
            "Block": "299",
            "label": "Sreevatsa Viswanathan Vivekananda Vidyalaya Senior Secondary School, Chitlapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21234",
            "district": "2",
            "Block": "299",
            "label": "Sri Kanchi Mahaswami Vidya Mandir, Sembakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21256",
            "district": "2",
            "Block": "299",
            "label": "Mount Carmel Mat. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21299",
            "district": "2",
            "Block": "299",
            "label": "THE PSBB MILLENNIUM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21430",
            "district": "2",
            "Block": "299",
            "label": "GHS, Perumbakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21584",
            "district": "2",
            "Block": "299",
            "label": "Srujana Montessori N & PS, Kovilambakkam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21585",
            "district": "2",
            "Block": "299",
            "label": "Gate Way International School, Neelankarai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21586",
            "district": "2",
            "Block": "299",
            "label": "The lords international school, perungalathur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21718",
            "district": "2",
            "Block": "299",
            "label": "VAELS International school,INJAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21719",
            "district": "2",
            "Block": "299",
            "label": "St.John's public School,Jaladianpet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21720",
            "district": "2",
            "Block": "299",
            "label": "Narayana Group of Schools,Neelankarai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21721",
            "district": "2",
            "Block": "299",
            "label": "St.John's International school,Neelangarai",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21722",
            "district": "2",
            "Block": "299",
            "label": "Sri Shankara NPS, Neelangarai",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21723",
            "district": "2",
            "Block": "299",
            "label": "Bharathi Vidyalaya  Senior Secondary School, Perumbakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21724",
            "district": "2",
            "Block": "299",
            "label": "BVM Global School,Perumbakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21725",
            "district": "2",
            "Block": "299",
            "label": "Army Public School,Nandambakkam",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21726",
            "district": "2",
            "Block": "299",
            "label": "Sri Sankara Balavidyalaya,Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21727",
            "district": "2",
            "Block": "299",
            "label": "SAN Academy,Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22043",
            "district": "2",
            "Block": "299",
            "label": "Akshar Arbol International School Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22044",
            "district": "2",
            "Block": "299",
            "label": "HINDUSTAN INTERNATIONAL SCHOOL KARAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22045",
            "district": "2",
            "Block": "299",
            "label": "SHRADDHA Children Academy, Kottivakkam Kuppam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22046",
            "district": "2",
            "Block": "299",
            "label": "AL QAMAR  ACADEMY, Kottivakkam Kuppam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22047",
            "district": "2",
            "Block": "299",
            "label": "RAMACHANDRAA PUBLIC SCHOOL, KOTTIVAKKAM KUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22048",
            "district": "2",
            "Block": "299",
            "label": "Ravindra Bharathi Global School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22049",
            "district": "2",
            "Block": "299",
            "label": "Velammal New Gen School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22050",
            "district": "2",
            "Block": "299",
            "label": "APL Global School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22051",
            "district": "2",
            "Block": "299",
            "label": "PUMS, Ezhil Nagar",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22052",
            "district": "2",
            "Block": "299",
            "label": "St. Johns Universal School, Palavakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22053",
            "district": "2",
            "Block": "299",
            "label": "Seed Academy School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22054",
            "district": "2",
            "Block": "299",
            "label": "BVM International School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22055",
            "district": "2",
            "Block": "299",
            "label": "SKB  VIDHYASHRAM School, Perungalathur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22056",
            "district": "2",
            "Block": "299",
            "label": "Trileaves International School, Sembakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22057",
            "district": "2",
            "Block": "299",
            "label": "Trileaves Global School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22058",
            "district": "2",
            "Block": "299",
            "label": "Babaji Vidhyashram, Sholinganallur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22365",
            "district": "2",
            "Block": "299",
            "label": "AKG Public School, Jalladianpet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22366",
            "district": "2",
            "Block": "299",
            "label": "Sri Chaitanya Techno Scool, Kovilamabakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22367",
            "district": "2",
            "Block": "299",
            "label": "Sri Chaitanya Techno School, Thuraipakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22368",
            "district": "2",
            "Block": "299",
            "label": "Al - Fajr International School, Kottivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22369",
            "district": "2",
            "Block": "299",
            "label": "HORIZON INTERNATIONAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22370",
            "district": "2",
            "Block": "299",
            "label": "Ravindra Bharathi Global School, Mudichur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22371",
            "district": "2",
            "Block": "299",
            "label": "Sheshavalli Public School, Mudichur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22372",
            "district": "2",
            "Block": "299",
            "label": "SRI CHAITHANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22373",
            "district": "2",
            "Block": "299",
            "label": "NETHAJI VIDHYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22374",
            "district": "2",
            "Block": "299",
            "label": "NPS International School, Perumbakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22375",
            "district": "2",
            "Block": "299",
            "label": "SHREE BHARATH VIDYAASHRAM , THIRUVANCHERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22376",
            "district": "2",
            "Block": "299",
            "label": "Grace International School, Chitlapakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22377",
            "district": "2",
            "Block": "299",
            "label": "Velammal Vidyalaya School, Sholinganallur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22714",
            "district": "2",
            "Block": "299",
            "label": "JAGANNATH VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22715",
            "district": "2",
            "Block": "299",
            "label": "NARAYANA E TECHNO SCHOOL SHOLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22827",
            "district": "2",
            "Block": "299",
            "label": "Marg Vidhyalaya CBSE school Pallikaranai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22828",
            "district": "2",
            "Block": "299",
            "label": "SAN Academy, Kamakoti Nagar,Pallikaranai.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22992",
            "district": "2",
            "Block": "299",
            "label": "Alpha International School , Sembakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23017",
            "district": "2",
            "Block": "299",
            "label": "Sharanalaya Montessori ,Akkarai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23020",
            "district": "2",
            "Block": "299",
            "label": "JS Global School Sholinganallur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23065",
            "district": "2",
            "Block": "299",
            "label": "RAVINDRA BHARATHI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23101",
            "district": "2",
            "Block": "299",
            "label": "SRICHIATANYA TECHNO SCHOOL, PERUNGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23222",
            "district": "2",
            "Block": "299",
            "label": "CAMPUS K SCHOOL, SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23223",
            "district": "2",
            "Block": "299",
            "label": "JEPPIAAR SCHOOL CHEMMENCHERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23224",
            "district": "2",
            "Block": "299",
            "label": "SRI CHAITANYA TECHNO SCHOOL, PALAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23231",
            "district": "2",
            "Block": "299",
            "label": "GHSS PERUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23271",
            "district": "2",
            "Block": "299",
            "label": "NARAYANA OLYMPIAD SCHOOL,MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23278",
            "district": "2",
            "Block": "299",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23293",
            "district": "2",
            "Block": "299",
            "label": "SUJAY PUBLIC SCHOOL MEDAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23307",
            "district": "2",
            "Block": "299",
            "label": "VISTA BILLABONG HIGH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23311",
            "district": "2",
            "Block": "299",
            "label": "THE BAY INTERNATIONAL SCHOOL &  JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23349",
            "district": "2",
            "Block": "299",
            "label": "Gateway the Complete School",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "23434",
            "district": "2",
            "Block": "299",
            "label": "SHREE NIKETAN PATASALA AGARAMTHEN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23435",
            "district": "2",
            "Block": "299",
            "label": "FIIT JEE GLOBAL SCHOOL  VENGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23436",
            "district": "2",
            "Block": "299",
            "label": "VELAMMAL NEWGEN SCHOOL, MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23437",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR KOVILAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23438",
            "district": "2",
            "Block": "299",
            "label": "ANNAI ARUL PUBLIC SCHOOL, MADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23439",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR, THIRUNEERMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23440",
            "district": "2",
            "Block": "299",
            "label": "THE INTERNATIONAL VILLAGE SCHOOL SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23441",
            "district": "2",
            "Block": "299",
            "label": "GATEWAY THE COMPLETE SCHOOL, SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23434",
            "district": "2",
            "Block": "299",
            "label": "SHREE NIKETAN PATASALA AGARAMTHEN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23435",
            "district": "2",
            "Block": "299",
            "label": "FIIT JEE GLOBAL SCHOOL  VENGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23436",
            "district": "2",
            "Block": "299",
            "label": "VELAMMAL NEWGEN SCHOOL, MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23437",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR KOVILAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23438",
            "district": "2",
            "Block": "299",
            "label": "ANNAI ARUL PUBLIC SCHOOL, MADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23439",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR, THIRUNEERMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23440",
            "district": "2",
            "Block": "299",
            "label": "THE INTERNATIONAL VILLAGE SCHOOL SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23441",
            "district": "2",
            "Block": "299",
            "label": "GATEWAY THE COMPLETE SCHOOL, SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23434",
            "district": "2",
            "Block": "299",
            "label": "SHREE NIKETAN PATASALA AGARAMTHEN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23435",
            "district": "2",
            "Block": "299",
            "label": "FIIT JEE GLOBAL SCHOOL  VENGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23436",
            "district": "2",
            "Block": "299",
            "label": "VELAMMAL NEWGEN SCHOOL, MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23437",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR KOVILAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23438",
            "district": "2",
            "Block": "299",
            "label": "ANNAI ARUL PUBLIC SCHOOL, MADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23439",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR, THIRUNEERMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23440",
            "district": "2",
            "Block": "299",
            "label": "THE INTERNATIONAL VILLAGE SCHOOL SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23441",
            "district": "2",
            "Block": "299",
            "label": "GATEWAY THE COMPLETE SCHOOL, SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23434",
            "district": "2",
            "Block": "299",
            "label": "SHREE NIKETAN PATASALA AGARAMTHEN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23435",
            "district": "2",
            "Block": "299",
            "label": "FIIT JEE GLOBAL SCHOOL  VENGAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23436",
            "district": "2",
            "Block": "299",
            "label": "VELAMMAL NEWGEN SCHOOL, MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23437",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR KOVILAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23438",
            "district": "2",
            "Block": "299",
            "label": "ANNAI ARUL PUBLIC SCHOOL, MADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23439",
            "district": "2",
            "Block": "299",
            "label": "MAHARISHI VIDYA MANDIR, THIRUNEERMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23440",
            "district": "2",
            "Block": "299",
            "label": "THE INTERNATIONAL VILLAGE SCHOOL SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23441",
            "district": "2",
            "Block": "299",
            "label": "GATEWAY THE COMPLETE SCHOOL, SHOLLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23977",
            "district": "2",
            "Block": "299",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23978",
            "district": "2",
            "Block": "299",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23979",
            "district": "2",
            "Block": "299",
            "label": "ORCHIDS THE INTERNATIONAL SCHOOL,PERUMBAKKAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23980",
            "district": "2",
            "Block": "299",
            "label": "DAV SARADA ESHWAR VIDYALAYA POZHICHALUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "300": [
        {
            "key": "416",
            "district": "2",
            "Block": "300",
            "label": "St.Don Bosco Matric HS,Chrompet",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4039",
            "district": "2",
            "Block": "300",
            "label": "St 'Joseph MHSS Butt road",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4279",
            "district": "2",
            "Block": "300",
            "label": "Indra Gandhi MS Adambakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4320",
            "district": "2",
            "Block": "300",
            "label": "HOLY FAMILY CONVENT MATRIC HIGHER SECONDARY SCHOOL, KEELKATTALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4607",
            "district": "2",
            "Block": "300",
            "label": "ALWIN MEMORIAL PUBLIC SC,SELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4623",
            "district": "2",
            "Block": "300",
            "label": "MONTFORT MATRICULATION HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4640",
            "district": "2",
            "Block": "300",
            "label": "Marian Mat.HSS, St.Thomas Mount",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4703",
            "district": "2",
            "Block": "300",
            "label": "HEBA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4711",
            "district": "2",
            "Block": "300",
            "label": "HOLY QUEEN MATRIC. HR. SEC. SCHOOL, NAGALKENI, CHROMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4713",
            "district": "2",
            "Block": "300",
            "label": "SHIKSHAA MATRIC HR. SEC. SCHOOL,  HASTHINAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4897",
            "district": "2",
            "Block": "300",
            "label": "ST. MARY MATRIC HR SEC SCHOOL, ZAMIN PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4900",
            "district": "2",
            "Block": "300",
            "label": "HILTON MHSS, PALAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23383",
            "district": "2",
            "Block": "300",
            "label": "SDMS Jain Vidhyalaya Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4902",
            "district": "2",
            "Block": "300",
            "label": "SANTHOSH VID MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4929",
            "district": "2",
            "Block": "300",
            "label": "Vyasa Vid.MHSS,Puzhuthivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4935",
            "district": "2",
            "Block": "300",
            "label": "St.Sebastian  MHSS Pallavaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4941",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARA VIDYALAYA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4982",
            "district": "2",
            "Block": "300",
            "label": "ANNAI VELANKANI MHSS, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4988",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARAVID.MHSS, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5134",
            "district": "2",
            "Block": "300",
            "label": "NADAR SANGA MATRIC. HR. SEC .SCHOOL, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5257",
            "district": "2",
            "Block": "300",
            "label": "St.Peters Mat.HSS, St.Thomas Mount",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5275",
            "district": "2",
            "Block": "300",
            "label": "ST MARKS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5309",
            "district": "2",
            "Block": "300",
            "label": "New Prince MHSS Ullagaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5327",
            "district": "2",
            "Block": "300",
            "label": "GOOD SHEPHERD MATRICULATION HIGHER SECONDARY SCHOOL, OLD PALLAVARAM, CHENNAI- 117",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5352",
            "district": "2",
            "Block": "300",
            "label": "Anna Mat Hr School, East Tambaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5384",
            "district": "2",
            "Block": "300",
            "label": "National Mat.Hr.Sec.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5392",
            "district": "2",
            "Block": "300",
            "label": "KARPAGA VIGNESWARA VIDYALAYA M. H. S. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5400",
            "district": "2",
            "Block": "300",
            "label": "S.C.S MHSS, Nehru Ngr,chrompet,ch-44",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5416",
            "district": "2",
            "Block": "300",
            "label": "VIDHYA MANDIR MS,  EAST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5444",
            "district": "2",
            "Block": "300",
            "label": "GK Shetty Hindu Vid MHSS Adambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5470",
            "district": "2",
            "Block": "300",
            "label": "Indian Matric HSS, Tambaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5482",
            "district": "2",
            "Block": "300",
            "label": "ST.VINCENT PALLOTTI MHSS ZAMIN PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5562",
            "district": "2",
            "Block": "300",
            "label": "Rajkumar Suloch MHSS Nanganallur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5683",
            "district": "2",
            "Block": "300",
            "label": "N.S.N Matric Hr.Sec,School Chrompet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5727",
            "district": "2",
            "Block": "300",
            "label": "ZION MAT HSS SELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5733",
            "district": "2",
            "Block": "300",
            "label": "Sitadevi Garodia Hindu Vidyalaya M.H.S.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5983",
            "district": "2",
            "Block": "300",
            "label": "D.A.V School Adambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5991",
            "district": "2",
            "Block": "300",
            "label": "SRI VENKATESWARA VID MATRIC SCHOOL, SELAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5993",
            "district": "2",
            "Block": "300",
            "label": "P.M.S  MATRICULATION HIGHER SECONDARY SCHOOL  NANGANALLUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6010",
            "district": "2",
            "Block": "300",
            "label": "MCC CAMPUS MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6019",
            "district": "2",
            "Block": "300",
            "label": "SACRED HEART MATRIC HIGHER SECONDARY SCHOOL, ANAKAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6057",
            "district": "2",
            "Block": "300",
            "label": "Emmanuel MHSS Pammal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6079",
            "district": "2",
            "Block": "300",
            "label": "SMT RAMKUWAR DEVI FOMRA VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6089",
            "district": "2",
            "Block": "300",
            "label": "EMMANUEL NURSERY & PRIMARY SCHOOL, ALANDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6143",
            "district": "2",
            "Block": "300",
            "label": "KAVITHA MHSS, PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6318",
            "district": "2",
            "Block": "300",
            "label": "VKK Ammaniammal Mat.HS, Pammal",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6346",
            "district": "2",
            "Block": "300",
            "label": "New Prince MHSS Adambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6382",
            "district": "2",
            "Block": "300",
            "label": "ALANDUR MUNICIPEL MIDDLE SCHOOL ADAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6434",
            "district": "2",
            "Block": "300",
            "label": "christ king matriculation school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6478",
            "district": "2",
            "Block": "300",
            "label": "Modern senior secondary school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6641",
            "district": "2",
            "Block": "300",
            "label": "Hindu Religious  Aided MS, Alandur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6651",
            "district": "2",
            "Block": "300",
            "label": "Sri Ramakrishna Matric HSS, Tambaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6652",
            "district": "2",
            "Block": "300",
            "label": "SRI RAMANA VID.MS,WEST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6681",
            "district": "2",
            "Block": "300",
            "label": "SIR & LADY  M VENKATASUBBA RAO MHSS, TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6744",
            "district": "2",
            "Block": "300",
            "label": "MMS URDU PALLAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8028",
            "district": "2",
            "Block": "300",
            "label": "UNCLE SARGUNAR MHSS ANAKAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8029",
            "district": "2",
            "Block": "300",
            "label": "SHRINE THERESA MATRICULATION SCHOOL, ANAKAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8030",
            "district": "2",
            "Block": "300",
            "label": "GHSS ANAKAPATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8031",
            "district": "2",
            "Block": "300",
            "label": "VELS VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, ANAKAPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8032",
            "district": "2",
            "Block": "300",
            "label": "THANTHAI PERIYAR GOVT HR SEC SCHOOL PUZHUDHIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8033",
            "district": "2",
            "Block": "300",
            "label": "St. Thomas Mat HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8034",
            "district": "2",
            "Block": "300",
            "label": "PUMS PASUMPON NAGAR PAMMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8035",
            "district": "2",
            "Block": "300",
            "label": "CSI JMA MIDDLE SCHOOL PAMMAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8036",
            "district": "2",
            "Block": "300",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MOONGILERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8037",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARA MATRIC. HR.SEC. SCHOOL, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8038",
            "district": "2",
            "Block": "300",
            "label": "ABTM MIDDLE SCHOOL,NAZARATHPURAM,ST. THOMAS MOUNT",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8039",
            "district": "2",
            "Block": "300",
            "label": "DR M G R CANTONMENT HS SCHOOL ST THOMAS. MOUNT",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8040",
            "district": "2",
            "Block": "300",
            "label": "RCM Aided HS., St. Thomas Mount",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8041",
            "district": "2",
            "Block": "300",
            "label": "CSI MS MOUNT",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8042",
            "district": "2",
            "Block": "300",
            "label": "CSI. ST. THOMAS HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8043",
            "district": "2",
            "Block": "300",
            "label": "ST.HELEN'S GIRLS  HSS, ST.THOMAS MOUNT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8044",
            "district": "2",
            "Block": "300",
            "label": "CIVIL AVIATION MS MEENAMBAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8045",
            "district": "2",
            "Block": "300",
            "label": "C. S. I ST. THOMAS MATRICULATION HIGHER SECONDARY SCHOOL, ST. THOMAS MOUNT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8046",
            "district": "2",
            "Block": "300",
            "label": "MMS Keelkattalai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8047",
            "district": "2",
            "Block": "300",
            "label": "THIRUVALLUVAR MIDDLE SCHOOL, ADAMBAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8048",
            "district": "2",
            "Block": "300",
            "label": "Kendriya Vidyalaya,DGOA,Alandur",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8049",
            "district": "2",
            "Block": "300",
            "label": "VAILANKANNI SILVER JUBILEE MATRIC.HR.SEC.SCHOOL, MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8050",
            "district": "2",
            "Block": "300",
            "label": "Hindu Col.Chellammal vid,Alandur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8051",
            "district": "2",
            "Block": "300",
            "label": "Sri Venkateswara Hindu Colony Middle School, Nanganallur",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8052",
            "district": "2",
            "Block": "300",
            "label": "G(B)HSS,NANGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8053",
            "district": "2",
            "Block": "300",
            "label": "JAIGOPAL GARODIA GGHSS , NANGANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8054",
            "district": "2",
            "Block": "300",
            "label": "Prince MHSS Nanganallur",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8055",
            "district": "2",
            "Block": "300",
            "label": "MONTFORT HSS ALANDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8056",
            "district": "2",
            "Block": "300",
            "label": "Municipal High School, Alandur",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8057",
            "district": "2",
            "Block": "300",
            "label": "AJS NIDHI HSS, Alandur",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8058",
            "district": "2",
            "Block": "300",
            "label": "ST. BRITTO'S MAT.HR.SEC. SCHOOL, ADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8059",
            "district": "2",
            "Block": "300",
            "label": "ST.THERESA  GIRLS HSS, PALLAVARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8060",
            "district": "2",
            "Block": "300",
            "label": "NATIONAL MS PALLAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8061",
            "district": "2",
            "Block": "300",
            "label": "MMA GHSS, Pallavaram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8062",
            "district": "2",
            "Block": "300",
            "label": "PAM MS PALLAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8063",
            "district": "2",
            "Block": "300",
            "label": "MPL HSS ZAMIN PALLAVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8064",
            "district": "2",
            "Block": "300",
            "label": "ST.FRANCIS XAVIER NUR & PRI SCHOOL, PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8065",
            "district": "2",
            "Block": "300",
            "label": "SRI SATHYA SAI INSTITUTE OF EDUCARE, CHROMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8066",
            "district": "2",
            "Block": "300",
            "label": "MPL HSS HASTHINAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8067",
            "district": "2",
            "Block": "300",
            "label": "GOVERNMENT (ADW) HIGHER SECONDARY SCHOOL, NAGALKENI, CHROMEPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8068",
            "district": "2",
            "Block": "300",
            "label": "SAVIOUR JESUS MATRICULATION HIGHER SECONDARY SCHOOL,CHROMEPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8069",
            "district": "2",
            "Block": "300",
            "label": "VENKATESWARA MS CHROMEPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8070",
            "district": "2",
            "Block": "300",
            "label": "Neelaram Mat.HSS,Chrompet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8071",
            "district": "2",
            "Block": "300",
            "label": "CES MIDDLE SCHOOL,  CHROMPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8072",
            "district": "2",
            "Block": "300",
            "label": "SRI AIYASWAMY AIYAR HS CHROMPET",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8073",
            "district": "2",
            "Block": "300",
            "label": "GOVT. BOYS HR. SEC. SCHOOL, CHROMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8074",
            "district": "2",
            "Block": "300",
            "label": "MBN GGHSS CHROMEPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8075",
            "district": "2",
            "Block": "300",
            "label": "Srimathi Lash Mem.Mat.HS, Chromepet",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8076",
            "district": "2",
            "Block": "300",
            "label": "HOLY ANGELS GIRLS HIGH SCHOOL CHROMEPET",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8077",
            "district": "2",
            "Block": "300",
            "label": "Holy Angels MHSS chrompet,ch-44",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8078",
            "district": "2",
            "Block": "300",
            "label": "ARINGNAR ANNA CANTONMENT HIGH SCHOOL PALLAVARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8079",
            "district": "2",
            "Block": "300",
            "label": "MPL HSS ZAMIN ROYAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8080",
            "district": "2",
            "Block": "300",
            "label": "C.S.I ST.STEPHEN'S MATRICULATION SCHOOL PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8081",
            "district": "2",
            "Block": "300",
            "label": "Modern Matric School,Chrompet",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8082",
            "district": "2",
            "Block": "300",
            "label": "ST.MARK'S MATRICULATION HIGHER SECONDARY SCHOOL, CHROMEPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8083",
            "district": "2",
            "Block": "300",
            "label": "Green Valley Matric HS, Chrompet",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8084",
            "district": "2",
            "Block": "300",
            "label": "Royal Matric HSS,Hastinapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8085",
            "district": "2",
            "Block": "300",
            "label": "GGN International School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8086",
            "district": "2",
            "Block": "300",
            "label": "Sri Sankara Global Academy, Keelkatalai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8087",
            "district": "2",
            "Block": "300",
            "label": "DHAYA JOTHY MATRIC HR SEC SCHOOL ,OLD PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8088",
            "district": "2",
            "Block": "300",
            "label": "VELS VIDYASHRAM, PALLAVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8089",
            "district": "2",
            "Block": "300",
            "label": "ADWMS KADAPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8090",
            "district": "2",
            "Block": "300",
            "label": "Seventh-Day Adventist Matric. High school, Tambaram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8091",
            "district": "2",
            "Block": "300",
            "label": "VALLUVAR GURUKULAM HSS ,TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8092",
            "district": "2",
            "Block": "300",
            "label": "GURUKULAM MAT HSS TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8093",
            "district": "2",
            "Block": "300",
            "label": "Avvai (Aided) MS, Tambaram",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8094",
            "district": "2",
            "Block": "300",
            "label": "THE MADRAS SEVA SADAN (AIDED) HSS, TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8095",
            "district": "2",
            "Block": "300",
            "label": "JAIGOPAL GARODIA NATIONAL HSS, EAST TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8096",
            "district": "2",
            "Block": "300",
            "label": "CHRIST KING MS, TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8097",
            "district": "2",
            "Block": "300",
            "label": "MPB GOVT GIRLS HR SEC SCHOOL EAST TAMBARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8098",
            "district": "2",
            "Block": "300",
            "label": "MUNICIPAL HSS, SELAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8099",
            "district": "2",
            "Block": "300",
            "label": "MMS VINOBA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8100",
            "district": "2",
            "Block": "300",
            "label": "CORLEY HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8101",
            "district": "2",
            "Block": "300",
            "label": "ST. MARYS MHSS, EAST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8102",
            "district": "2",
            "Block": "300",
            "label": "CSI MS TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8103",
            "district": "2",
            "Block": "300",
            "label": "MMS IRUMBULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8104",
            "district": "2",
            "Block": "300",
            "label": "RAJARAM MIDDLE SCHOOL, TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8105",
            "district": "2",
            "Block": "300",
            "label": "Prof ACK Memorial telugu school west tambaram",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8106",
            "district": "2",
            "Block": "300",
            "label": "MCC RSL HSS WEST TAMBARAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8107",
            "district": "2",
            "Block": "300",
            "label": "Anandjain vidyalaya MHSS tambaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8108",
            "district": "2",
            "Block": "300",
            "label": "MMS Kannadiyarpalayam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8109",
            "district": "2",
            "Block": "300",
            "label": "CHRIST KING GIRLS HR. SEC. SCHOOL EAST TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8110",
            "district": "2",
            "Block": "300",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL, TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8111",
            "district": "2",
            "Block": "300",
            "label": "BABY (AIDED)HIGH SCHOOL TAMBARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8112",
            "district": "2",
            "Block": "300",
            "label": "SHREE GUGANS SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8113",
            "district": "2",
            "Block": "300",
            "label": "C S I CORLEY MATRIC SCHOOL,EAST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8114",
            "district": "2",
            "Block": "300",
            "label": "ST JOHN'S MATRIC  SCHOOL,EAST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8115",
            "district": "2",
            "Block": "300",
            "label": "MPL HS OLD TAMBARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8116",
            "district": "2",
            "Block": "300",
            "label": "SHRI VENKATESHWARA HSS, Madipakkam",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21052",
            "district": "2",
            "Block": "300",
            "label": "RAVINDRA BHARATHI  GLOBAL KEELKATTALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21136",
            "district": "2",
            "Block": "300",
            "label": "Sunshine Chennai Senior SS, Puzhuthivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21142",
            "district": "2",
            "Block": "300",
            "label": "KENDRIYA VIDHYALAYA MEENAMBAKKAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21144",
            "district": "2",
            "Block": "300",
            "label": "ST. DOMINICS ANGLO - INDIAN HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21728",
            "district": "2",
            "Block": "300",
            "label": "Narayana E-techno schools",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24132", "district":"2", 
            "Block":"300", "label": "Vels Global School", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "21897",
            "district": "2",
            "Block": "300",
            "label": "Agurchand Manmull Jain School, Meenambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21898",
            "district": "2",
            "Block": "300",
            "label": "Apex Ponvidyashram School,  Puzhuthivakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21899",
            "district": "2",
            "Block": "300",
            "label": "New Prince Shri Bhavani Vidyashram, Ullagaram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21900",
            "district": "2",
            "Block": "300",
            "label": "PRINCE SRIVARI SENIOR SECONDARY SCHOOL , MADIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22035",
            "district": "2",
            "Block": "300",
            "label": "GOVT ADW HIGH SCHOOL, ANAKAPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22036",
            "district": "2",
            "Block": "300",
            "label": "Mahalakshmi Vidya Mandir School, Pammal",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22037",
            "district": "2",
            "Block": "300",
            "label": "Knowledge Academy School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22038",
            "district": "2",
            "Block": "300",
            "label": "Hindustan International School, Mount",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23678",
            "district": "2",
            "Block": "300",
            "label": "Saratha Mtriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "22039",
            "district": "2",
            "Block": "300",
            "label": "PRINCE SRIVARI SENIOR SECONDARY SCHOOL NANGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22040",
            "district": "2",
            "Block": "300",
            "label": "Sri Chaitanya Techno School, Chrompet",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22358",
            "district": "2",
            "Block": "300",
            "label": "Noble Academy",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22359",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARA GLOBAL ACADEMY, PAMMAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22360",
            "district": "2",
            "Block": "300",
            "label": "UNITY MATRIC SCHOOL, ADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22361",
            "district": "2",
            "Block": "300",
            "label": "Shikshaa Public School, Hasthinapuram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22362",
            "district": "2",
            "Block": "300",
            "label": "Arul Jothi Public CBSE School, Old Pallavaram",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22777",
            "district": "2",
            "Block": "300",
            "label": "ST. ANTONY'S R.C. HIGH SCHOOL, SENGALANEERMALAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22778",
            "district": "2",
            "Block": "300",
            "label": "Baalyaa School (NIOS) Keelkattalai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22798",
            "district": "2",
            "Block": "300",
            "label": "NEW PRINCE SHRI BHAVANI VIDYASHRAM, ADAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22846",
            "district": "2",
            "Block": "300",
            "label": "ST.THOMAS ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23102",
            "district": "2",
            "Block": "300",
            "label": "VELS VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23158",
            "district": "2",
            "Block": "300",
            "label": "GURUKULAM VIDYASHRAM WEST TAMBARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23279",
            "district": "2",
            "Block": "300",
            "label": "SAN ACADEMY  TAMBARAM WEST",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23981",
            "district": "2",
            "Block": "300",
            "label": "AMETHYST INTERNATIONAL SCHOOL OTTIYAMBAKKAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23982",
            "district": "2",
            "Block": "300",
            "label": "SRI SANKARA VIDYALAYA",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "301": [
        {
            "key": "2114",
            "district": "4",
            "Block": "301",
            "label": "PUMS PERIYAKUYILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2496",
            "district": "4",
            "Block": "301",
            "label": "PUMS PAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2958",
            "district": "4",
            "Block": "301",
            "label": "PUMS BOGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3077",
            "district": "4",
            "Block": "301",
            "label": "PUMS KAMMALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3313",
            "district": "4",
            "Block": "301",
            "label": "ANNAI ARIVAGAM MATRICULATION SCHOOL SENJERIPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23378",
            "district": "4",
            "Block": "301",
            "label": "Sree lakshmi vidhya mandir matriculation school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "3395",
            "district": "4",
            "Block": "301",
            "label": "SRI DHAYANANDAPURI MATRIC HIGHER SECONDARY SCHOOL, DASANAICKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3546",
            "district": "4",
            "Block": "301",
            "label": "PUMS SENJERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4255",
            "district": "4",
            "Block": "301",
            "label": "KALAIMAGAL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4806",
            "district": "4",
            "Block": "301",
            "label": "PUMS POORANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4863",
            "district": "4",
            "Block": "301",
            "label": "VENKITARAJ MATRICULATION SCHOOL,SULTHANPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6512",
            "district": "4",
            "Block": "301",
            "label": "PUMS SELAKKARICHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13205",
            "district": "4",
            "Block": "301",
            "label": "SRI VENKATESHWARA HIGH SCHOOL APPANAICKENPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13206",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGH SCHOOL PONNAKKANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13207",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EDAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13208",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13209",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGH SCHOOL AKKANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13210",
            "district": "4",
            "Block": "301",
            "label": "SRI RAMASWAMY NAIDU VIDYALAYAM HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13211",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENJERIMALAIYADIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13212",
            "district": "4",
            "Block": "301",
            "label": "THAVATHIRU MARIMUTHU ADIGALAR MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13213",
            "district": "4",
            "Block": "301",
            "label": "PUMS S.AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13214",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL J.KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13215",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENJERIPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13216",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGH SCHOOL JALLIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13217",
            "district": "4",
            "Block": "301",
            "label": "PUMS ODAKKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13218",
            "district": "4",
            "Block": "301",
            "label": "SOKKAN CHETTIAR MALLAMMAL HIGHER SECONDARY SCHOOL VADAMBACHERI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13219",
            "district": "4",
            "Block": "301",
            "label": "GOVERNMENT HIGH SCHOOL SULTHANPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22156",
            "district": "4",
            "Block": "301",
            "label": "SHASWI INTERNATIONAL SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22157",
            "district": "4",
            "Block": "301",
            "label": "SRI DHAYANANDAPURI MODEL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23983",
            "district": "4",
            "Block": "301",
            "label": "WEST PAARK INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "302": [
        {
            "key": "440",
            "district": "4",
            "Block": "302",
            "label": "A.R.C MHSS - KARUMATHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "450",
            "district": "4",
            "Block": "302",
            "label": "RATHNAVEL SUBRAMANIAM MATRICULATION HIGHER SECONDARY SCHOOL, SULUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "500",
            "district": "4",
            "Block": "302",
            "label": "VIJAYA VIDHYALAYA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "536",
            "district": "4",
            "Block": "302",
            "label": "KENNEDY MAT HSS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "703",
            "district": "4",
            "Block": "302",
            "label": "MICHAEL JOB MATRICULATION.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "817",
            "district": "4",
            "Block": "302",
            "label": "PUMS S.L.V.NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1355",
            "district": "4",
            "Block": "302",
            "label": "M. NANJAPPA CHETTIAR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24137", "district":"4", 
            "Block":"302", "label": "ITERACY MISSION INTERNATIONAL School", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "23401",
            "district": "4",
            "Block": "302",
            "label": "Kathir Vidhyashram Matriculation higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1417",
            "district": "4",
            "Block": "302",
            "label": "PUM SCHOOL, UTHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1442",
            "district": "4",
            "Block": "302",
            "label": "KOVAI MARUTHAMALAI MATRICULATION SCHOOL.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1599",
            "district": "4",
            "Block": "302",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELACHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1663",
            "district": "4",
            "Block": "302",
            "label": "AMIRTHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1972",
            "district": "4",
            "Block": "302",
            "label": "PUMS K.RAYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2039",
            "district": "4",
            "Block": "302",
            "label": "KALAIVANI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2246",
            "district": "4",
            "Block": "302",
            "label": "PUMS KAMACHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2540",
            "district": "4",
            "Block": "302",
            "label": "SARASWATHI VIDYALAYA MATRIC SCHOOL CHINTHAMANI PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2671",
            "district": "4",
            "Block": "302",
            "label": "PUMS THOTTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2728",
            "district": "4",
            "Block": "302",
            "label": "K.M.M. MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2829",
            "district": "4",
            "Block": "302",
            "label": "PUMS NALLAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3103",
            "district": "4",
            "Block": "302",
            "label": "PUMS AATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3282",
            "district": "4",
            "Block": "302",
            "label": "SRI KAMACHIAMMAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3285",
            "district": "4",
            "Block": "302",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SUBBARAYANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3339",
            "district": "4",
            "Block": "302",
            "label": "SRI SUGA BRAHMA MAHARISHI VIDYALAYAM MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3788",
            "district": "4",
            "Block": "302",
            "label": "PUMS KARAVALI MATHAPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4117",
            "district": "4",
            "Block": "302",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADUGANKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4300",
            "district": "4",
            "Block": "302",
            "label": "PUMS SENGATHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4350",
            "district": "4",
            "Block": "302",
            "label": "PUMS M.GANAPATHYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4733",
            "district": "4",
            "Block": "302",
            "label": "PUMS VENKITTAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4838",
            "district": "4",
            "Block": "302",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5302",
            "district": "4",
            "Block": "302",
            "label": "PUMS KADUVETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13220",
            "district": "4",
            "Block": "302",
            "label": "GHS RASIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13221",
            "district": "4",
            "Block": "302",
            "label": "PUMS ATHAPPAGOUNDANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13222",
            "district": "4",
            "Block": "302",
            "label": "GHSS,IRUGUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13223",
            "district": "4",
            "Block": "302",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, SULUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13224",
            "district": "4",
            "Block": "302",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, SULUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13225",
            "district": "4",
            "Block": "302",
            "label": "GHSS KANGEYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13226",
            "district": "4",
            "Block": "302",
            "label": "KENDRIYA VIDYALAYA",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13227",
            "district": "4",
            "Block": "302",
            "label": "GHS KALANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13228",
            "district": "4",
            "Block": "302",
            "label": "ANUGRAHA MANDHIR CBSE SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13229",
            "district": "4",
            "Block": "302",
            "label": "GHSS KANNAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13230",
            "district": "4",
            "Block": "302",
            "label": "AIDED KADRI MILLS HIGHER SECONDARY SCHOOL OTTARPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13231",
            "district": "4",
            "Block": "302",
            "label": "GHS PALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13232",
            "district": "4",
            "Block": "302",
            "label": "VIVEKANANDA KALVI NILAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13233",
            "district": "4",
            "Block": "302",
            "label": "VIJAYA VIDYALAYA  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13234",
            "district": "4",
            "Block": "302",
            "label": "GHS PATTANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13235",
            "district": "4",
            "Block": "302",
            "label": "S K N GOVT HR SEC SCHOOL, PEEDAMPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13236",
            "district": "4",
            "Block": "302",
            "label": "AIDED ST MARYS BOYS HIGH SCHOOL KARUMATHAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "13237",
            "district": "4",
            "Block": "302",
            "label": "ST.JOSEPHS GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13238",
            "district": "4",
            "Block": "302",
            "label": "KONGU VELLALAR MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13239",
            "district": "4",
            "Block": "302",
            "label": "A.R.C MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13240",
            "district": "4",
            "Block": "302",
            "label": "HOLY ROSARY MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23362",
            "district": "4",
            "Block": "302",
            "label": "Kathir Vidyaa Mandhir Secondary School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13241",
            "district": "4",
            "Block": "302",
            "label": "KOVAI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13242",
            "district": "4",
            "Block": "302",
            "label": "GOVERNMENT HIGH SCHOOL SEMMANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13243",
            "district": "4",
            "Block": "302",
            "label": "PUMS KITTAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13244",
            "district": "4",
            "Block": "302",
            "label": "K K VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13245",
            "district": "4",
            "Block": "302",
            "label": "K K VIDHYALAYA HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13246",
            "district": "4",
            "Block": "302",
            "label": "GOVT HR SEC SCHOOL VAGARAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13247",
            "district": "4",
            "Block": "302",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13248",
            "district": "4",
            "Block": "302",
            "label": "GHS SELLAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13249",
            "district": "4",
            "Block": "302",
            "label": "GHSS,ARASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13250",
            "district": "4",
            "Block": "302",
            "label": "GRD-CPF MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13251",
            "district": "4",
            "Block": "302",
            "label": "GHSS CHINNIAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13252",
            "district": "4",
            "Block": "302",
            "label": "PUMS MUTHUGOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20879",
            "district": "4",
            "Block": "302",
            "label": "SSVM WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21026",
            "district": "4",
            "Block": "302",
            "label": "MDN FUTURE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21087",
            "district": "4",
            "Block": "302",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21183",
            "district": "4",
            "Block": "302",
            "label": "PARK GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21957",
            "district": "4",
            "Block": "302",
            "label": "TIRUPUR HR SEC SCHOOL FOR THE DEAF",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22158",
            "district": "4",
            "Block": "302",
            "label": "SRI KANDHA VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22159",
            "district": "4",
            "Block": "302",
            "label": "HOLY FAMILY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22160",
            "district": "4",
            "Block": "302",
            "label": "SRI VIVEKANANDHA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22161",
            "district": "4",
            "Block": "302",
            "label": "YELLOW TRAIN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22492",
            "district": "4",
            "Block": "302",
            "label": "GOVERNMENT HIGH SCHOOL KADAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22854",
            "district": "4",
            "Block": "302",
            "label": "KONGU PUBLIC SCHOOL, KARUMATHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22936",
            "district": "4",
            "Block": "302",
            "label": "GHS NEELAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23238",
            "district": "4",
            "Block": "302",
            "label": "MATER DEI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23472",
            "district": "4",
            "Block": "302",
            "label": "SBIOA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23472",
            "district": "4",
            "Block": "302",
            "label": "SBIOA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23984",
            "district": "4",
            "Block": "302",
            "label": "PUMS UNJAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23985",
            "district": "4",
            "Block": "302",
            "label": "PUMS KURUMBAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23986",
            "district": "4",
            "Block": "302",
            "label": "YELLOW TRAIN SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "23987",
            "district": "4",
            "Block": "302",
            "label": "ZEN KIDZ MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23988",
            "district": "4",
            "Block": "302",
            "label": "SRI SUKA BRAHMA MAHARISHI VIDYA MANDIR INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23989",
            "district": "4",
            "Block": "302",
            "label": "AIR FORCE SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "303": [
        {
            "key": "2876",
            "district": "14",
            "Block": "303",
            "label": "M.S.R  MATRICULATION  HIGHER  SECONDARY  SCHOOL, T KALLUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4742",
            "district": "14",
            "Block": "303",
            "label": "K.K.G MATRICULATION  HIGHER  SECONDARY  SCHOOL, P.THOTTIAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5684",
            "district": "14",
            "Block": "303",
            "label": "LORD VENKATESHWARA MATRICULATION  HIGHER SECONDARY  SCHOOL, T KALLUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17920",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, APPAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17921",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, A.THOTTIAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17922",
            "district": "14",
            "Block": "303",
            "label": "GOVT MODEL HR SEC SCHOOL, T.KALLUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17923",
            "district": "14",
            "Block": "303",
            "label": "GANDHINIKETAN G VENKATACHALAPATHY HR SEC SCHOOL, T.KALLUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17924",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, VANNIVELAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17925",
            "district": "14",
            "Block": "303",
            "label": "GOVT HR SEC SCHOOL, T.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17926",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S  PULIAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17927",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S  KARAIKENI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17928",
            "district": "14",
            "Block": "303",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SOLAIPATTI L",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17929",
            "district": "14",
            "Block": "303",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMMAPATTI V",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17930",
            "district": "14",
            "Block": "303",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THOTTIAPATTI P",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17931",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S CHINNAPOOLAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17932",
            "district": "14",
            "Block": "303",
            "label": "SRI KANDASAMY VIDHYALAYAM HR SEC SCHOOL, PAPPUNAYAKKANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17933",
            "district": "14",
            "Block": "303",
            "label": "GOVT. ADW MIDDLE SCHOOL, S.KEELAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17934",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S S.PARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17935",
            "district": "14",
            "Block": "303",
            "label": "SRI M K V SALA HIGHER SECONDARY SCHOOL  MELAPATTI S",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17936",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S  KOOVALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17937",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S. THUMANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17938",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S  KUMARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17939",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S SILAIMALAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17940",
            "district": "14",
            "Block": "303",
            "label": "GOVT HR SEC SCHOOL, M.SUBBULAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17941",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S CHINNACHITTULOTTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17942",
            "district": "14",
            "Block": "303",
            "label": "P.U.M.S MURUGANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17943",
            "district": "14",
            "Block": "303",
            "label": "SRI V.K.V SALA M.S GOPINAYAKANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17944",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, GOPINAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17945",
            "district": "14",
            "Block": "303",
            "label": "ADI.M.S  CHOKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17946",
            "district": "14",
            "Block": "303",
            "label": "GOVT GIRLS HR SEC SCHOOL, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17947",
            "district": "14",
            "Block": "303",
            "label": "GANDHIJI MEMORIAL GOVT BOYS HR SEC SCHOOL, PERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21166",
            "district": "14",
            "Block": "303",
            "label": "SAMABHARATHAM MATRICULATION  HIGHER SECONDARY  SCHOOL, KILANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22242",
            "district": "14",
            "Block": "303",
            "label": "HINDU AIDED HIGHSCHOOL, SALISANTHAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22601",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL.V.CHATRAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23319",
            "district": "14",
            "Block": "303",
            "label": "GOVT HIGH SCHOOL, SANTHAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "304": [
        {
            "key": "395",
            "district": "3",
            "Block": "304",
            "label": "GURUJI SRI MURALIDHARA SWAMIGAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "396",
            "district": "3",
            "Block": "304",
            "label": "FATHIMA BASHEER MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "399",
            "district": "3",
            "Block": "304",
            "label": "ANJUMAN MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "451",
            "district": "3",
            "Block": "304",
            "label": "AVM RAJESWARI  MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "459",
            "district": "3",
            "Block": "304",
            "label": "KARTHIKEYAN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "544",
            "district": "3",
            "Block": "304",
            "label": "SIR M. VENKATASUBBA RAO MAT. HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "585",
            "district": "3",
            "Block": "304",
            "label": "VELANKANNI MATRIC HSS ASHOK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "589",
            "district": "3",
            "Block": "304",
            "label": "VIDYANIKEDAN MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "812",
            "district": "3",
            "Block": "304",
            "label": "VIDYAKSHETHRAM MATRIC SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "815",
            "district": "3",
            "Block": "304",
            "label": "SRI KRISHNASWAMY MHSS KK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "861",
            "district": "3",
            "Block": "304",
            "label": "HOLY ANGELS ANGLO INDIAN HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1177",
            "district": "3",
            "Block": "304",
            "label": "LITTLE OXFORD MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1402",
            "district": "3",
            "Block": "304",
            "label": "BABA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1535",
            "district": "3",
            "Block": "304",
            "label": "JUSTICE BASHEER AHMD MEM.MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1658",
            "district": "3",
            "Block": "304",
            "label": "NAVABHARATH MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1788",
            "district": "3",
            "Block": "304",
            "label": "Sri Ramakrishna Mission Sarada Vidyalaya Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1816",
            "district": "3",
            "Block": "304",
            "label": "SRI SITARAM VIDYALAYA MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1864",
            "district": "3",
            "Block": "304",
            "label": "FATIMA MATRICULATION HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2172",
            "district": "3",
            "Block": "304",
            "label": "SRM NIGHTINGALE MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2226",
            "district": "3",
            "Block": "304",
            "label": "VADAPALANI MAT HR SEC SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2634",
            "district": "3",
            "Block": "304",
            "label": "SHAKESPEARE MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2818",
            "district": "3",
            "Block": "304",
            "label": "CLARENCE MAT HR SEC SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2835",
            "district": "3",
            "Block": "304",
            "label": "CSI St.Augustine Matric School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2915",
            "district": "3",
            "Block": "304",
            "label": "JRK MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2919",
            "district": "3",
            "Block": "304",
            "label": "THAI SATHYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3317",
            "district": "3",
            "Block": "304",
            "label": "JANAKI RAMACHANDIRAN MATRIC HIGHER  SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3432",
            "district": "3",
            "Block": "304",
            "label": "RAMAKRISHNA MISSION MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3673",
            "district": "3",
            "Block": "304",
            "label": "SUNDARAM MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4012",
            "district": "3",
            "Block": "304",
            "label": "SPRINGFIELD MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4226",
            "district": "3",
            "Block": "304",
            "label": "STELLA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4295",
            "district": "3",
            "Block": "304",
            "label": "SRI TARACHAND GALADA JAIN MAT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4694",
            "district": "3",
            "Block": "304",
            "label": "LITTLE FLOWER MAT HSS ASHOK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4901",
            "district": "3",
            "Block": "304",
            "label": "LOYOLA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4908",
            "district": "3",
            "Block": "304",
            "label": "SRI CHILD FRUIT MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5386",
            "district": "3",
            "Block": "304",
            "label": "RAMALINGA MISSION MIDDLE SCH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5719",
            "district": "3",
            "Block": "304",
            "label": "JG HINDU VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5774",
            "district": "3",
            "Block": "304",
            "label": "SEVENTH DAY ADVENTIST MATRIC HIGH SCHOOL ,KODAMBAKKAM CHENNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5891",
            "district": "3",
            "Block": "304",
            "label": "AMBATTUR LIONS CLUB MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5982",
            "district": "3",
            "Block": "304",
            "label": "LITTLE FLOWER MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5998",
            "district": "3",
            "Block": "304",
            "label": "SHRI B S MOOTHA SR SEC SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6110",
            "district": "3",
            "Block": "304",
            "label": "BALA VIDHYA MATRIC SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6167",
            "district": "3",
            "Block": "304",
            "label": "CMS PRAKASAM ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6395",
            "district": "3",
            "Block": "304",
            "label": "GRT MAHALAKSHMI VID MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6414",
            "district": "3",
            "Block": "304",
            "label": "DR MGR JANAKI MAT SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7407",
            "district": "3",
            "Block": "304",
            "label": "GHSS MMDA ARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7408",
            "district": "3",
            "Block": "304",
            "label": "ST JOSEPH HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7409",
            "district": "3",
            "Block": "304",
            "label": "JG GOVT GIRL'S HIGHER SECONDARY SCHOOL,CHOOLAIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7410",
            "district": "3",
            "Block": "304",
            "label": "SATHYAMURTHY HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7411",
            "district": "3",
            "Block": "304",
            "label": "CMS ERIKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7412",
            "district": "3",
            "Block": "304",
            "label": "Al hira model  school",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7413",
            "district": "3",
            "Block": "304",
            "label": "CHS VANNIA TEYNAMPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7414",
            "district": "3",
            "Block": "304",
            "label": "THE GROVE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7415",
            "district": "3",
            "Block": "304",
            "label": "KESARI HR SEC SCHOOL,  T NAGAR,  CHENNAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7416",
            "district": "3",
            "Block": "304",
            "label": "C A S DR P VENKATARAMANA RAO  HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7417",
            "district": "3",
            "Block": "304",
            "label": "CMS TRUSTPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7418",
            "district": "3",
            "Block": "304",
            "label": "SARASWATHI VIDYALAYA SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7419",
            "district": "3",
            "Block": "304",
            "label": "Metro English medium school",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7420",
            "district": "3",
            "Block": "304",
            "label": "MCN HIGHER SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7421",
            "district": "3",
            "Block": "304",
            "label": "CMS KAMARAJAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7422",
            "district": "3",
            "Block": "304",
            "label": "CHS RANGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7423",
            "district": "3",
            "Block": "304",
            "label": "CMS GANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7424",
            "district": "3",
            "Block": "304",
            "label": "M.G.R HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7425",
            "district": "3",
            "Block": "304",
            "label": "CHENNAI HIGHER SECONDARY SCHOOL PULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7426",
            "district": "3",
            "Block": "304",
            "label": "KARNATAKA SANGHA HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7427",
            "district": "3",
            "Block": "304",
            "label": "CHATRAPATHI SHIVAJI DAV SEC SH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7428",
            "district": "3",
            "Block": "304",
            "label": "PCKG GOVT HSS KODAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7429",
            "district": "3",
            "Block": "304",
            "label": "DURGA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7430",
            "district": "3",
            "Block": "304",
            "label": "CHENNAI HIGH SCHOOL KANNIYAPPA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7431",
            "district": "3",
            "Block": "304",
            "label": "GHSS PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7432",
            "district": "3",
            "Block": "304",
            "label": "KENDRIYA VIDYALAYA  ASHOK NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7433",
            "district": "3",
            "Block": "304",
            "label": "GOVT GIRLS HR SEC SCHOOL ASHOK NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7434",
            "district": "3",
            "Block": "304",
            "label": "VRUKSHA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7435",
            "district": "3",
            "Block": "304",
            "label": "JAWAHAR HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7436",
            "district": "3",
            "Block": "304",
            "label": "DR. K.K. NIRMALA GIRLS HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7437",
            "district": "3",
            "Block": "304",
            "label": "ANJUHAM HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7438",
            "district": "3",
            "Block": "304",
            "label": "SRI RKM HR SEC SCHOOL MAIN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7439",
            "district": "3",
            "Block": "304",
            "label": "SHRINE VELANKANNI  SS SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7440",
            "district": "3",
            "Block": "304",
            "label": "SRI RKM SV MIDDLE SCH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7441",
            "district": "3",
            "Block": "304",
            "label": "SRI RKM BOYS HR SEC SCHOOL SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7442",
            "district": "3",
            "Block": "304",
            "label": "SHRI M B JAIN HR SEC SCH",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7443",
            "district": "3",
            "Block": "304",
            "label": "SMT VASANTBEN CHANDUBHAI SHAH MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7444",
            "district": "3",
            "Block": "304",
            "label": "SREE NARAYANA MISSION SS SCH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7445",
            "district": "3",
            "Block": "304",
            "label": "CHS KANNAMMAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7446",
            "district": "3",
            "Block": "304",
            "label": "SRI R K M SARADA VIDYALAYA  GIRLS HR SEC SCHOOL T NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7447",
            "district": "3",
            "Block": "304",
            "label": "GUNTUR SUBBIA GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7448",
            "district": "3",
            "Block": "304",
            "label": "GOVT GIRLS HIGH SCH TNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7449",
            "district": "3",
            "Block": "304",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7450",
            "district": "3",
            "Block": "304",
            "label": "PADMA SESHADRI BALA BHAVAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7451",
            "district": "3",
            "Block": "304",
            "label": "ST ANNES MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7452",
            "district": "3",
            "Block": "304",
            "label": "VANI VIDYALAYA SENIOR SECONDARY & JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7453",
            "district": "3",
            "Block": "304",
            "label": "C D NAYAGAM THEAGARAYANAGAR HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7454",
            "district": "3",
            "Block": "304",
            "label": "SRI RKM MODEL HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7455",
            "district": "3",
            "Block": "304",
            "label": "KAVERI HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7456",
            "district": "3",
            "Block": "304",
            "label": "GENERAL CARIAPPA HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7457",
            "district": "3",
            "Block": "304",
            "label": "AVICHI HSS SALIGRAMAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7458",
            "district": "3",
            "Block": "304",
            "label": "CHSS NESAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7459",
            "district": "3",
            "Block": "304",
            "label": "JAWAHAR VIDYALAYA SR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7460",
            "district": "3",
            "Block": "304",
            "label": "ANNAI VALAN KANNI MAT SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7461",
            "district": "3",
            "Block": "304",
            "label": "JG GOVT GIRLS HSS VIRUGAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7462",
            "district": "3",
            "Block": "304",
            "label": "CMS VADAPALANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20890",
            "district": "3",
            "Block": "304",
            "label": "MAHATMA GANDHI VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22013",
            "district": "3",
            "Block": "304",
            "label": "TRINITY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22684",
            "district": "3",
            "Block": "304",
            "label": "PEACE ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22959",
            "district": "3",
            "Block": "304",
            "label": "SHRINE VAILANKANNI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23990",
            "district": "3",
            "Block": "304",
            "label": "SANDIPANI VIDYALAYA",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "305": [
        {
            "key": "4651",
            "district": "1",
            "Block": "305",
            "label": "PUMS-CHOLAMADEVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14695",
            "district": "1",
            "Block": "305",
            "label": "PUMS MANAGATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        { 
            "key":"24136", "district":"1", 
            "Block":"305", "label": "mangayarkarsai manila nadunilai palli ariyalur district", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "14696",
            "district": "1",
            "Block": "305",
            "label": "PUMS NACHIYARPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14697",
            "district": "1",
            "Block": "305",
            "label": "GOWDHAMA BUDDHAR HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14698",
            "district": "1",
            "Block": "305",
            "label": "TPEVR GOVT HIGH SCHOOL ULLIYAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14699",
            "district": "1",
            "Block": "305",
            "label": "PUMS KORAIKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14700",
            "district": "1",
            "Block": "305",
            "label": "GHS-VENMANKONDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14701",
            "district": "1",
            "Block": "305",
            "label": "GHS-TENKACHI PERUMAL NATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14702",
            "district": "1",
            "Block": "305",
            "label": "PUMS THADAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14703",
            "district": "1",
            "Block": "305",
            "label": "GHSS-T.PALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14704",
            "district": "1",
            "Block": "305",
            "label": "GHS-NAYAGANAIPRIYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14705",
            "district": "1",
            "Block": "305",
            "label": "PUMS-ANAIKUDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14706",
            "district": "1",
            "Block": "305",
            "label": "PUMS-PORPATHINTHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14707",
            "district": "1",
            "Block": "305",
            "label": "GHS-SILAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14708",
            "district": "1",
            "Block": "305",
            "label": "PUMS-EDANKANNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14709",
            "district": "1",
            "Block": "305",
            "label": "GHSS-KODALIKARUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14710",
            "district": "1",
            "Block": "305",
            "label": "PUMS KODALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14711",
            "district": "1",
            "Block": "305",
            "label": "GHSS-UDHAYANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14712",
            "district": "1",
            "Block": "305",
            "label": "PUMS-VEMBUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14713",
            "district": "1",
            "Block": "305",
            "label": "PUMS-THENNAVANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14714",
            "district": "1",
            "Block": "305",
            "label": "PUMS-KADUVETTANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14715",
            "district": "1",
            "Block": "305",
            "label": "GHSS-Z.SUTHAMALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14716",
            "district": "1",
            "Block": "305",
            "label": "GHSS-VIKKIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14717",
            "district": "1",
            "Block": "305",
            "label": "PUMS-KADAMPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14718",
            "district": "1",
            "Block": "305",
            "label": "GHS-GUNAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14719",
            "district": "1",
            "Block": "305",
            "label": "GHS-SATHAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14720",
            "district": "1",
            "Block": "305",
            "label": "PUMS-MUTTUVANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14721",
            "district": "1",
            "Block": "305",
            "label": "PUMS-GOVINTHAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14722",
            "district": "1",
            "Block": "305",
            "label": "PUMS-KASANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14723",
            "district": "1",
            "Block": "305",
            "label": "BHARATHA MATHA RC HR SEC SCHOOL NADUVALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14724",
            "district": "1",
            "Block": "305",
            "label": "PUMS-ARANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14725",
            "district": "1",
            "Block": "305",
            "label": "GHSS-THIRUPURANTHAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14726",
            "district": "1",
            "Block": "305",
            "label": "PUMS-ARULMOZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14727",
            "district": "1",
            "Block": "305",
            "label": "GHSS-KARAIKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14728",
            "district": "1",
            "Block": "305",
            "label": "PUMS-MATHANATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21533",
            "district": "1",
            "Block": "305",
            "label": "ST. MICHEALS AIDED HIGH  SCHOOL KEELAMICHAELPATTY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21549",
            "district": "1",
            "Block": "305",
            "label": "CHOCKALINGAM VEMBU AMMAL HIGH SCHOOL SRIPURANTHAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21910",
            "district": "1",
            "Block": "305",
            "label": "JAYAM HIGH SCHOOL SUNDARESAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22536",
            "district": "1",
            "Block": "305",
            "label": "GOKILAMBAL MATRICULATION HIGHER SECONDARY SCHOOL KUZHAVADAIYAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22537",
            "district": "1",
            "Block": "305",
            "label": "GOKILAMBAL CBSE SCHOOL KUZHAVADAIYAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "306": [
        {
            "key": "1163",
            "district": "14",
            "Block": "306",
            "label": "THAAI MATRIC HIGHER SECONDARY SCHOOL, T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1856",
            "district": "14",
            "Block": "306",
            "label": "VENKATASALAPATHY MATRIC HIGH SCHOOL, T VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3329",
            "district": "14",
            "Block": "306",
            "label": "FUSCO'S MATRIC HR SEC SCHOOL, T. VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3469",
            "district": "14",
            "Block": "306",
            "label": "VIVEKANANDA MATRIC HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6045",
            "district": "14",
            "Block": "306",
            "label": "M.V.M.KALAIVANI MATRIC HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18342",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S. KULASEKARANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18343",
            "district": "14",
            "Block": "306",
            "label": "MADURA SUGARS GOVT HR SEC SCHOOL.PANDIARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18344",
            "district": "14",
            "Block": "306",
            "label": "GOVERNMENT MIDDLE SCHOOL PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18345",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S SEMINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18346",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S.KUTLADAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18347",
            "district": "14",
            "Block": "306",
            "label": "GOVT HIGH SCHOOL, KATCHAIKATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18348",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S. T.ANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18349",
            "district": "14",
            "Block": "306",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KATTAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18350",
            "district": "14",
            "Block": "306",
            "label": "GOVT HIGH SCHOOL, METTUNEERATHAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18351",
            "district": "14",
            "Block": "306",
            "label": "GOVT GIRLS HR SEC SCHOOL, T.VADIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18352",
            "district": "14",
            "Block": "306",
            "label": "GOVT BOYS HR SEC SCHOOL, T.VADIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18353",
            "district": "14",
            "Block": "306",
            "label": "ST CHARLES HIGH SCHOOL, T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18354",
            "district": "14",
            "Block": "306",
            "label": "GOVT ADW HR SEC SCHOOL, NACHIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18355",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S IRUMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18356",
            "district": "14",
            "Block": "306",
            "label": "GOVT HIGH SCHOOL, KARUPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18357",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S MANNADI MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18358",
            "district": "14",
            "Block": "306",
            "label": "GOVT KALLAR HR SEC SCHOOL, AYYAPPANAIYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18359",
            "district": "14",
            "Block": "306",
            "label": "GOVT HR SEC SCHOOL, T.MULLIPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18360",
            "district": "14",
            "Block": "306",
            "label": "GOVT HIGH SCHOOL, KADUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18361",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S KELAMATTAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18362",
            "district": "14",
            "Block": "306",
            "label": "GOVT KALLAR HR SEC SCHOOL, MELLAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18363",
            "district": "14",
            "Block": "306",
            "label": "KERAN MATRIC.SCHOOL, MALAYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18364",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S.KATCHIRAYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18365",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S PETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18366",
            "district": "14",
            "Block": "306",
            "label": "GOVT GIRLS HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18367",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S.SHOLAVANDHAN,CHATHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18368",
            "district": "14",
            "Block": "306",
            "label": "R.C.M.SHOLAVANDHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18369",
            "district": "14",
            "Block": "306",
            "label": "KAMARAJAR MATRIC HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18370",
            "district": "14",
            "Block": "306",
            "label": "AS GOVT HR SEC SCHOOL, SHOLAVANDAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18371",
            "district": "14",
            "Block": "306",
            "label": "KAMARAJAR.MID.S.SHOLAVANDHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18372",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S.THIRUVEDAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18373",
            "district": "14",
            "Block": "306",
            "label": "VIVEKANANDA HR SEC SCHOOL, THIRUVEDAKAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18374",
            "district": "14",
            "Block": "306",
            "label": "NARENDRA MATRICULATION  SCHOOL, THIRUVEDAKAM WEST",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18375",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S NEDUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18376",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S THIRUVALAVAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18377",
            "district": "14",
            "Block": "306",
            "label": "MOUNT LITERA ZEE SCHOOL, RAYAPURAM, MADURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18378",
            "district": "14",
            "Block": "306",
            "label": "R.C.M.S.ROYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18379",
            "district": "14",
            "Block": "306",
            "label": "P.U.M.S. C.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22257",
            "district": "14",
            "Block": "306",
            "label": "THAAI SCHOOL, T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23509",
            "district": "14",
            "Block": "306",
            "label": "NAM VIDHYA MANDIR MATRICULATION SCHOOL, PAALPANNAI ROAD. T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23509",
            "district": "14",
            "Block": "306",
            "label": "NAM VIDHYA MANDIR MATRICULATION SCHOOL, PAALPANNAI ROAD. T.VADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "307": [
        {
            "key": "701",
            "district": "24",
            "Block": "307",
            "label": "HILTON MATRIC HR SEC SCHOOL, AYIRAPERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1018",
            "district": "24",
            "Block": "307",
            "label": "BHARATH MONTESSORI MATRIC HR SEC SCHOOL, ILANJI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1285",
            "district": "24",
            "Block": "307",
            "label": "JOY MATRIC. HR. SEC, SCHOOL , VADAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1999",
            "district": "24",
            "Block": "307",
            "label": "ST.JOHNS MATRIC HR SEC SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2045",
            "district": "24",
            "Block": "307",
            "label": "ST.JOSEPH MAT.HSS, PANPOLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2070",
            "district": "24",
            "Block": "307",
            "label": "AG MATRIC HSS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2111",
            "district": "24",
            "Block": "307",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2310",
            "district": "24",
            "Block": "307",
            "label": "PUMS, KARISALKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3112",
            "district": "24",
            "Block": "307",
            "label": "PARASAKTHI M HSS,COURTALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3500",
            "district": "24",
            "Block": "307",
            "label": "SUBASH MATRIC HSS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3508",
            "district": "24",
            "Block": "307",
            "label": "VIVEKANANDA MIDDLE SCHOOL MATHALAMPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3704",
            "district": "24",
            "Block": "307",
            "label": "HINDU MIDDLE SCHOOL KASIMAJORPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3728",
            "district": "24",
            "Block": "307",
            "label": "PUMS THIRUCHITRAMBALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3887",
            "district": "24",
            "Block": "307",
            "label": "OXFORD MATRIC. HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4086",
            "district": "24",
            "Block": "307",
            "label": "TDTA MS, KAKAIYANUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4154",
            "district": "24",
            "Block": "307",
            "label": "M.M. MS VISWANTHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4235",
            "district": "24",
            "Block": "307",
            "label": "MKVK MATRIC HSS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4341",
            "district": "24",
            "Block": "307",
            "label": "TDTA PRIMARY SCHOOL VALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4792",
            "district": "24",
            "Block": "307",
            "label": "MPL,13th WARD MS,TENKASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5090",
            "district": "24",
            "Block": "307",
            "label": "KATTU BAVA MIDDLE SCHOOL , TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5100",
            "district": "24",
            "Block": "307",
            "label": "MUSTAFIAH MIDDLE SCHOOL TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5153",
            "district": "24",
            "Block": "307",
            "label": "CMS MACWHIRTER MIDDLE SCHOOL TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5555",
            "district": "24",
            "Block": "307",
            "label": "MPL 9th WARD MS.TENKSI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19915",
            "district": "24",
            "Block": "307",
            "label": "GHSS VADAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19916",
            "district": "24",
            "Block": "307",
            "label": "MUSLIM HS VADAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19917",
            "district": "24",
            "Block": "307",
            "label": "HUSSAINIYA MUSLIM HIGH SCHOOL VADAKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19918",
            "district": "24",
            "Block": "307",
            "label": "RKV MS PANPOLI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19919",
            "district": "24",
            "Block": "307",
            "label": "GHSS, PANPOLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19920",
            "district": "24",
            "Block": "307",
            "label": "A.G.HS, KANAKAPILLAIVALASAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19921",
            "district": "24",
            "Block": "307",
            "label": "St. MARY'S HS KUTHUKALVALASAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19922",
            "district": "24",
            "Block": "307",
            "label": "GHS, VETHAMPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19923",
            "district": "24",
            "Block": "307",
            "label": "ARAMBAJOTHI MIDDLE SCHOOL ILANJI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19924",
            "district": "24",
            "Block": "307",
            "label": "RAMASWAMY PILLAI HR. SEC. SCHOOL., ILANJI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19925",
            "district": "24",
            "Block": "307",
            "label": "SYED RESI.HSS,ILANJI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19926",
            "district": "24",
            "Block": "307",
            "label": "P S T K D GIRLS HR SEC SCHOOL, COURTALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19927",
            "district": "24",
            "Block": "307",
            "label": "GHSS,MELAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19928",
            "district": "24",
            "Block": "307",
            "label": "EMMANUEL MATRIC HR SEC SCHOOL, MELAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19929",
            "district": "24",
            "Block": "307",
            "label": "MPL, 7th WARD MS,TENKASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19930",
            "district": "24",
            "Block": "307",
            "label": "ICI Govt Model HSS,TENKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19931",
            "district": "24",
            "Block": "307",
            "label": "MANJAMMAL HSS(G),TENKASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19932",
            "district": "24",
            "Block": "307",
            "label": "PONNAMBALAM MIDDLE SCHOOL TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19933",
            "district": "24",
            "Block": "307",
            "label": "St.  MICHAEL'S HSS(G) TENKASI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19934",
            "district": "24",
            "Block": "307",
            "label": "NEHRU HSS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19935",
            "district": "24",
            "Block": "307",
            "label": "HAMEETHIYA MS,TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "19936",
            "district": "24",
            "Block": "307",
            "label": "VEERAMAMUNIVAR RC HSS,TENKASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19937",
            "district": "24",
            "Block": "307",
            "label": "HINDU HSS,KEELAPULIUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19938",
            "district": "24",
            "Block": "307",
            "label": "HINDU HSS(G),KEELAPULIUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19939",
            "district": "24",
            "Block": "307",
            "label": "GHSS SUNDARAPANDIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19940",
            "district": "24",
            "Block": "307",
            "label": "SRI RAM MATRIC HR SEC SCHOOL, VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19941",
            "district": "24",
            "Block": "307",
            "label": "ANNAI THERESA R C HSS VALLAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19942",
            "district": "24",
            "Block": "307",
            "label": "NATIONAL PUBLIC SCHOOL, VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20979",
            "district": "24",
            "Block": "307",
            "label": "BHARATH VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21120",
            "district": "24",
            "Block": "307",
            "label": "ESAKKI VIDHYASHRAM CBSE SCHOOL TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21829",
            "district": "24",
            "Block": "307",
            "label": "St. JOSEPH PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22282",
            "district": "24",
            "Block": "307",
            "label": "TREASURE ILAND INTERNATIONAL SCHOOL MAVADIKKALTHOPPU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22656",
            "district": "24",
            "Block": "307",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22732",
            "district": "24",
            "Block": "307",
            "label": "PIONEER INTERNATIONAL CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22733",
            "district": "24",
            "Block": "307",
            "label": "VEL'S VIDHYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23327",
            "district": "24",
            "Block": "307",
            "label": "OXFORD PUBLIC SCHOOL, KUTHUKKALVALASAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23328",
            "district": "24",
            "Block": "307",
            "label": "GREEN PARK MATRICULATION SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23558",
            "district": "24",
            "Block": "307",
            "label": "IBNU UMAR ISLAMIC  MATRICULATION SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        { 
            "key":"24120", "district":"24", 
            "Block":"307", "label": "Sri Parasakthi Vidyalaya - CBSE School Tenkasi", 
            "Sch_type": "Un-aided", "Category": "Higher Secondary School"
        },
        {
            "key": "23558",
            "district": "24",
            "Block": "307",
            "label": "IBNU UMAR ISLAMIC  MATRICULATION SCHOOL, TENKASI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23991",
            "district": "24",
            "Block": "307",
            "label": "KATTU BAVA HIGH SCHOOL,TENKASI ",
            "Sch_type": "Partially AidedHigh School"
        },
        {
            "key": "23992",
            "district": "24",
            "Block": "307",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "308": [
        {
            "key": "4868",
            "district": "16",
            "Block": "308",
            "label": "PUMS THALAINAYAR AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5075",
            "district": "16",
            "Block": "308",
            "label": "PUMS THIRUVIDAIMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5200",
            "district": "16",
            "Block": "308",
            "label": "PUMS VADUGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5508",
            "district": "16",
            "Block": "308",
            "label": "PUMS ARUNTHAVAMPULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5559",
            "district": "16",
            "Block": "308",
            "label": "PUMS MARACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5798",
            "district": "16",
            "Block": "308",
            "label": "PUMS KOLAPPADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6156",
            "district": "16",
            "Block": "308",
            "label": "PUMS SANTHANAMTHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15759",
            "district": "16",
            "Block": "308",
            "label": "GHS AYMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15760",
            "district": "16",
            "Block": "308",
            "label": "VGHSS MANAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15761",
            "district": "16",
            "Block": "308",
            "label": "PUMS MANAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15762",
            "district": "16",
            "Block": "308",
            "label": "ST. XAVIER'S HIGHER SECONDARY SCHOOL NEERMULAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15763",
            "district": "16",
            "Block": "308",
            "label": "PUMS NEERMULAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15764",
            "district": "16",
            "Block": "308",
            "label": "GHS KOTHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15765",
            "district": "16",
            "Block": "308",
            "label": "GHS SITHAIMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15766",
            "district": "16",
            "Block": "308",
            "label": "GHS PANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15767",
            "district": "16",
            "Block": "308",
            "label": "GHS KOLAPPADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15768",
            "district": "16",
            "Block": "308",
            "label": "GHSS UMBALACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15769",
            "district": "16",
            "Block": "308",
            "label": "PUMS PRINJUMOOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15770",
            "district": "16",
            "Block": "308",
            "label": "GHSS THALAINAYAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15771",
            "district": "16",
            "Block": "308",
            "label": "PUMS ORADIYAMBULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15772",
            "district": "16",
            "Block": "308",
            "label": "GHS ORADIYAMBULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15773",
            "district": "16",
            "Block": "308",
            "label": "PUMS THALAINAYAR 4 SETHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15774",
            "district": "16",
            "Block": "308",
            "label": "GHS KOVILPATHU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15775",
            "district": "16",
            "Block": "308",
            "label": "GHSS VELLAPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15776",
            "district": "16",
            "Block": "308",
            "label": "GHS VELLAPALLAM(FC)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15777",
            "district": "16",
            "Block": "308",
            "label": "SRINIVASANAR AIDED HIGH SCHOOL NALUVEDAPATHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15778",
            "district": "16",
            "Block": "308",
            "label": "GHS MOOKATCHI THERU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15779",
            "district": "16",
            "Block": "308",
            "label": "AIDED MIDDLE SCHOOL AVARIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15780",
            "district": "16",
            "Block": "308",
            "label": "GHS KALLIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22697",
            "district": "16",
            "Block": "308",
            "label": "SIVASAKTHI INTERNATIONAL  SCHOOL, THALAINAYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "309": [
        {
            "key": "578",
            "district": "22",
            "Block": "309",
            "label": "SWAMI VIVEKANANDA MATRIC SCHOOL, VEERAGANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2662",
            "district": "22",
            "Block": "309",
            "label": "JAYA BHARATH MATRIC HR. SEC. SCHOOL,VADA CHENNIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4367",
            "district": "22",
            "Block": "309",
            "label": "BHARATHI VIDHYALAYA MATRIC HR. SEC. SCHOOL, SIRUVACHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4542",
            "district": "22",
            "Block": "309",
            "label": "SRI VENKATESWARA MATRIC SCHOOL, NATHAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11151",
            "district": "22",
            "Block": "309",
            "label": "GHS, SADHASIVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11152",
            "district": "22",
            "Block": "309",
            "label": "PUMS, SARVOY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11153",
            "district": "22",
            "Block": "309",
            "label": "LITTLE FLOWER MATRIC HR SEC SCHOOL, NATHAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11154",
            "district": "22",
            "Block": "309",
            "label": "GHS, SARVOYPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11155",
            "district": "22",
            "Block": "309",
            "label": "GHS, VADA CHENNIMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11156",
            "district": "22",
            "Block": "309",
            "label": "GHSS, KATTUKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11157",
            "district": "22",
            "Block": "309",
            "label": "PUMS, MANIVILUNDAN NORTH PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11158",
            "district": "22",
            "Block": "309",
            "label": "PUMS, MUTTALPOOMARATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11159",
            "district": "22",
            "Block": "309",
            "label": "PUMS, MANIVILUNDAN NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11160",
            "district": "22",
            "Block": "309",
            "label": "MARUTHI HIGHER SECONDARY SCHOOL, M.V.SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11161",
            "district": "22",
            "Block": "309",
            "label": "JAYAM ACADEMY (CBSE), M.V.SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "11162",
            "district": "22",
            "Block": "309",
            "label": "JAYAM MATRIC HR. SEC. SCHOOL, SAMIYAR KINARU, ATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11163",
            "district": "22",
            "Block": "309",
            "label": "PUMS, SIRUVACHUR 4TH WARD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11164",
            "district": "22",
            "Block": "309",
            "label": "CLASSIC MATRIC SCHOOL, SIRUVACHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11165",
            "district": "22",
            "Block": "309",
            "label": "GHSS, SIRUVACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11166",
            "district": "22",
            "Block": "309",
            "label": "PUMS, VEPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11167",
            "district": "22",
            "Block": "309",
            "label": "GHSS, UNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11168",
            "district": "22",
            "Block": "309",
            "label": "GHS, PUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11169",
            "district": "22",
            "Block": "309",
            "label": "GHS, NAVAKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11170",
            "district": "22",
            "Block": "309",
            "label": "NEHRU MATRICULATION SCHOOL, NAVAKKURICHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11171",
            "district": "22",
            "Block": "309",
            "label": "PUMS, PATTUTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11172",
            "district": "22",
            "Block": "309",
            "label": "GHSS, DEVIYAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11173",
            "district": "22",
            "Block": "309",
            "label": "TAGORE MATRIC HR. SEC. SCHOOL, DEVIYAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11174",
            "district": "22",
            "Block": "309",
            "label": "BHARATHIYAR GIRLS HR. SEC. SCHOOL, DEVIYAKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11175",
            "district": "22",
            "Block": "309",
            "label": "PAAVENDHAR MATRIC HR. SEC. SCHOOL, M.V.SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11176",
            "district": "22",
            "Block": "309",
            "label": "TAGORE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11177",
            "district": "22",
            "Block": "309",
            "label": "PUMS, VADAKUMARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11178",
            "district": "22",
            "Block": "309",
            "label": "GHSS, SATHAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11179",
            "district": "22",
            "Block": "309",
            "label": "PUMS, PUNALVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11180",
            "district": "22",
            "Block": "309",
            "label": "GHS, NAVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11181",
            "district": "22",
            "Block": "309",
            "label": "PUMS, VEDHANAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11182",
            "district": "22",
            "Block": "309",
            "label": "GBHSS, THALAIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11183",
            "district": "22",
            "Block": "309",
            "label": "GGHSS, THALAIVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11184",
            "district": "22",
            "Block": "309",
            "label": "PUMS, NATHAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11185",
            "district": "22",
            "Block": "309",
            "label": "SRI VENKATESWARA HR. SEC. SCHOOL, NATHAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11186",
            "district": "22",
            "Block": "309",
            "label": "PUMS, PERIYERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11187",
            "district": "22",
            "Block": "309",
            "label": "MUTHAMIZHL HIGHER SECONDARY SCHOOL, PERIYERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11188",
            "district": "22",
            "Block": "309",
            "label": "SRI RAJA MURUGA CBSE SCHOOL, PERIYERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11189",
            "district": "22",
            "Block": "309",
            "label": "GHSS, ARAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11190",
            "district": "22",
            "Block": "309",
            "label": "GGHS, ARAGALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11191",
            "district": "22",
            "Block": "309",
            "label": "BHARATHI VIDYA MANDIR MATRIC SCHOOL, SITERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11192",
            "district": "22",
            "Block": "309",
            "label": "GHS, SITHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11193",
            "district": "22",
            "Block": "309",
            "label": "GHS, GOVINDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11194",
            "district": "22",
            "Block": "309",
            "label": "PUMS, THENKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11195",
            "district": "22",
            "Block": "309",
            "label": "GBHSS, VEERAGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11196",
            "district": "22",
            "Block": "309",
            "label": "GGHSS, VEERAGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11197",
            "district": "22",
            "Block": "309",
            "label": "SRI RAGAVENDRA MATRIC HR. SEC. SCHOOL, VEERAGANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11198",
            "district": "22",
            "Block": "309",
            "label": "PUMS, THITTACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11199",
            "district": "22",
            "Block": "309",
            "label": "GHS, LATHUVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11200",
            "district": "22",
            "Block": "309",
            "label": "GHS, VELLAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11201",
            "district": "22",
            "Block": "309",
            "label": "PUMS, ELUPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11202",
            "district": "22",
            "Block": "309",
            "label": "SRI RAMAKRISHNA MATRICULATION SCHOOL, ELUPPANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11203",
            "district": "22",
            "Block": "309",
            "label": "GHS, VEPPAMPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11204",
            "district": "22",
            "Block": "309",
            "label": "GHS, PULIYANKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11205",
            "district": "22",
            "Block": "309",
            "label": "GHS, EAST RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11206",
            "district": "22",
            "Block": "309",
            "label": "PUMS, KAVARPANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23297",
            "district": "22",
            "Block": "309",
            "label": "MARUTHI MATRICULATION SCHOOL, MANIVIZHUNDHAN SOUTH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "310": [
        {
            "key": "655",
            "district": "8",
            "Block": "310",
            "label": "MARIA DEEPTHI MATRIC HIGH SCHOOL PANAKAHALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "718",
            "district": "8",
            "Block": "310",
            "label": "PUMS NEITHALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "837",
            "district": "8",
            "Block": "310",
            "label": "PUMS BALAPPADUGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "959",
            "district": "8",
            "Block": "310",
            "label": "PUMS  METTALAVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1456",
            "district": "8",
            "Block": "310",
            "label": "PUMS MALLANGULI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1611",
            "district": "8",
            "Block": "310",
            "label": "PUMS DODDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1886",
            "district": "8",
            "Block": "310",
            "label": "PUMS AREPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1917",
            "district": "8",
            "Block": "310",
            "label": "PUMS KADUBASUVANMALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1973",
            "district": "8",
            "Block": "310",
            "label": "PUMS CHIKKANANDHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2052",
            "district": "8",
            "Block": "310",
            "label": "PUMS KOTTADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2097",
            "district": "8",
            "Block": "310",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BHARATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2112",
            "district": "8",
            "Block": "310",
            "label": "PUMS KALBANDIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4611",
            "district": "8",
            "Block": "310",
            "label": "JSS MATRICULATION SCHOOL THALAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5825",
            "district": "8",
            "Block": "310",
            "label": "PUMS HASANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12328",
            "district": "8",
            "Block": "310",
            "label": "GHS GERMALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12329",
            "district": "8",
            "Block": "310",
            "label": "GOVERNMENT TRIBAL RESIDENTIAL HIGHER SECONDARY SCHOOL HASANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12330",
            "district": "8",
            "Block": "310",
            "label": "GTRHS GEDDESAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12331",
            "district": "8",
            "Block": "310",
            "label": "GTRMS BEJELATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12332",
            "district": "8",
            "Block": "310",
            "label": "GTRHS THALAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12333",
            "district": "8",
            "Block": "310",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THALAVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12334",
            "district": "8",
            "Block": "310",
            "label": "GHSS THALAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12335",
            "district": "8",
            "Block": "310",
            "label": "GHS KADATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12336",
            "district": "8",
            "Block": "310",
            "label": "PUMS DODDAKAJANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12337",
            "district": "8",
            "Block": "310",
            "label": "DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL, DODDAGAJANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12338",
            "district": "8",
            "Block": "310",
            "label": "GHS THIGANARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12339",
            "district": "8",
            "Block": "310",
            "label": "KASTURIBA GANDHI BALIKA VIDYALAYA, KUNNANPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12340",
            "district": "8",
            "Block": "310",
            "label": "GHS CHIKKAHALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12341",
            "district": "8",
            "Block": "310",
            "label": "GHSS KOTTAMALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12342",
            "district": "8",
            "Block": "310",
            "label": "PUMS ARALAVADY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12343",
            "district": "8",
            "Block": "310",
            "label": "GOVERNMENT TRIBAL WELFARE MIDDLE SCHOOL, KANAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12344",
            "district": "8",
            "Block": "310",
            "label": "ST JOSEPH'S HIGHER SECONDARY SCHOOL, SOOSAIPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12345",
            "district": "8",
            "Block": "310",
            "label": "ST ANN'S HIGH SCHOOL THIGANARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12346",
            "district": "8",
            "Block": "310",
            "label": "GHSS PANAGAHALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21219",
            "district": "8",
            "Block": "310",
            "label": "GMHSS, MALLANGULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21270",
            "district": "8",
            "Block": "310",
            "label": "KCT MATRICULATION SCHOOL, RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22469",
            "district": "8",
            "Block": "310",
            "label": "GOVERNMENT HIGH SCHOOL, SUJJILKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22873",
            "district": "8",
            "Block": "310",
            "label": "GHS, KUNNANPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "311": [
        {
            "key": "2507",
            "district": "13",
            "Block": "311",
            "label": "INECA MAT.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4100",
            "district": "13",
            "Block": "311",
            "label": "PUMS SITHANDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4158",
            "district": "13",
            "Block": "311",
            "label": "PUMS SEENKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4174",
            "district": "13",
            "Block": "311",
            "label": "PUMS SARAKAPALLY -TAM-TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4318",
            "district": "13",
            "Block": "311",
            "label": "PUMS UNISENATHAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4399",
            "district": "13",
            "Block": "311",
            "label": "PUMS MEKALAGOWNUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4408",
            "district": "13",
            "Block": "311",
            "label": "PUMS ANNIYALAM -TAM-TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4437",
            "district": "13",
            "Block": "311",
            "label": "PUMS BELPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4445",
            "district": "13",
            "Block": "311",
            "label": "PUMS MAVANATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4461",
            "district": "13",
            "Block": "311",
            "label": "PUMS BENNANGUR -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4465",
            "district": "13",
            "Block": "311",
            "label": "PUMS CHOODASANDIRAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4496",
            "district": "13",
            "Block": "311",
            "label": "PUMS KEMPAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4498",
            "district": "13",
            "Block": "311",
            "label": "PUMS BENNANGUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4501",
            "district": "13",
            "Block": "311",
            "label": "PUMS BELUR -TEL - ENG",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4512",
            "district": "13",
            "Block": "311",
            "label": "PUMS VEERASETTIYERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4552",
            "district": "13",
            "Block": "311",
            "label": "PUMS BETTAPALLY(U)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4568",
            "district": "13",
            "Block": "311",
            "label": "PUMS MARAGATTA DODDY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4595",
            "district": "13",
            "Block": "311",
            "label": "PUMS DODDABILIMUDIRAI -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4613",
            "district": "13",
            "Block": "311",
            "label": "PUMS BINNAMANGALAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4629",
            "district": "13",
            "Block": "311",
            "label": "PUMS ULIBANDA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4639",
            "district": "13",
            "Block": "311",
            "label": "PUMS AGALAKOTTA -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4723",
            "district": "13",
            "Block": "311",
            "label": "PUMS CHINNAUBBANUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5044",
            "district": "13",
            "Block": "311",
            "label": "PUMS SALIVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5045",
            "district": "13",
            "Block": "311",
            "label": "PUMS DEVARULIMANGALAM -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5359",
            "district": "13",
            "Block": "311",
            "label": "POORNA KAMALA VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5538",
            "district": "13",
            "Block": "311",
            "label": "PUMS T.KOTHANUR -TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5565",
            "district": "13",
            "Block": "311",
            "label": "PUMS KATCHUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5802",
            "district": "13",
            "Block": "311",
            "label": "PUMS THIMMENATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5882",
            "district": "13",
            "Block": "311",
            "label": "PUMS KADAGANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5904",
            "district": "13",
            "Block": "311",
            "label": "PUMS ARUPALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6203",
            "district": "13",
            "Block": "311",
            "label": "PUMS NOGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6217",
            "district": "13",
            "Block": "311",
            "label": "PUMS A.PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6258",
            "district": "13",
            "Block": "311",
            "label": "PUMS MALLAHALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6603",
            "district": "13",
            "Block": "311",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20804",
            "district": "13",
            "Block": "311",
            "label": "GHSS ANCHETTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20805",
            "district": "13",
            "Block": "311",
            "label": "SANTA MARIA ACADEMY  INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20806",
            "district": "13",
            "Block": "311",
            "label": "GHS ANDEVANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20807",
            "district": "13",
            "Block": "311",
            "label": "GHS B.LAKKASANDIRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20808",
            "district": "13",
            "Block": "311",
            "label": "GHSS BALATHOTTANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20809",
            "district": "13",
            "Block": "311",
            "label": "PUMS BALATHOTTANAPALLY -URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20810",
            "district": "13",
            "Block": "311",
            "label": "PUMS BILIGUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20811",
            "district": "13",
            "Block": "311",
            "label": "PUMS CHAKKALAPALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20812",
            "district": "13",
            "Block": "311",
            "label": "GHS D.KOTHANUR -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20813",
            "district": "13",
            "Block": "311",
            "label": "PUMS DODDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20814",
            "district": "13",
            "Block": "311",
            "label": "GHS DODDAMANCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20815",
            "district": "13",
            "Block": "311",
            "label": "GHS GERATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20816",
            "district": "13",
            "Block": "311",
            "label": "GHS JAWALAGIRI -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20817",
            "district": "13",
            "Block": "311",
            "label": "GHS KAKKADASAM -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20818",
            "district": "13",
            "Block": "311",
            "label": "PUMS NOORODHUSAMIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20819",
            "district": "13",
            "Block": "311",
            "label": "IVDP NETHAJI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20820",
            "district": "13",
            "Block": "311",
            "label": "GHS KUNDUKOTTA -TEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20821",
            "district": "13",
            "Block": "311",
            "label": "GHSS MADHAGONDAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20822",
            "district": "13",
            "Block": "311",
            "label": "OUR LADY'S HSS MADHAKONDAPALLI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20823",
            "district": "13",
            "Block": "311",
            "label": "MATHAKONDAPALLI MODEL SCHOOL, MATHAKONDAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20824",
            "district": "13",
            "Block": "311",
            "label": "SHEMFORD SENIOR SECONDARY SCHOOL, MADHAGONDAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20825",
            "district": "13",
            "Block": "311",
            "label": "PUMS MANCHUGONDAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20826",
            "district": "13",
            "Block": "311",
            "label": "GHSS NATRAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20827",
            "district": "13",
            "Block": "311",
            "label": "GHSS PEDDABELAGONDAPALLY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20828",
            "district": "13",
            "Block": "311",
            "label": "ST.JOSEPH'S HSS SESURAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20829",
            "district": "13",
            "Block": "311",
            "label": "GHS T.SHULAKUNTA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20830",
            "district": "13",
            "Block": "311",
            "label": "GHSS THALLY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20831",
            "district": "13",
            "Block": "311",
            "label": "GHS-GIRLS  THALLY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20832",
            "district": "13",
            "Block": "311",
            "label": "GHS THALLY -URUDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20833",
            "district": "13",
            "Block": "311",
            "label": "KGBV THALLY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20834",
            "district": "13",
            "Block": "311",
            "label": "GHSS URIGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20835",
            "district": "13",
            "Block": "311",
            "label": "DE PAUL HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20836",
            "district": "13",
            "Block": "311",
            "label": "GHS ALENATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20837",
            "district": "13",
            "Block": "311",
            "label": "GHS BOOTHATTIKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20838",
            "district": "13",
            "Block": "311",
            "label": "GHS BIKKANAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20839",
            "district": "13",
            "Block": "311",
            "label": "THOOYA SAGAYA MATHA AIDED MIDDLE SCHOOL-DASARAPALLY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20840",
            "district": "13",
            "Block": "311",
            "label": "GHS GUMMALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20841",
            "district": "13",
            "Block": "311",
            "label": "GHS KARADIKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20842",
            "district": "13",
            "Block": "311",
            "label": "PUMS MARUPALLY -TAM-TEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20843",
            "district": "13",
            "Block": "311",
            "label": "GHS THAGGATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21196",
            "district": "13",
            "Block": "311",
            "label": "MODEL HSS THALLY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21423",
            "district": "13",
            "Block": "311",
            "label": "GGHS PEDDABELAGONDAPALLI - GIRLS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22302",
            "district": "13",
            "Block": "311",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- kakkadasam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22677",
            "district": "13",
            "Block": "311",
            "label": "GURUKULAM GLOBAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22678",
            "district": "13",
            "Block": "311",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22679",
            "district": "13",
            "Block": "311",
            "label": "GHS PANDURANGAN DODDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23273",
            "district": "13",
            "Block": "311",
            "label": "GHS T.BELALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23274",
            "district": "13",
            "Block": "311",
            "label": "GHS KODAGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23503",
            "district": "13",
            "Block": "311",
            "label": "SRI BALAGANGADARA SWAMIJI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23503",
            "district": "13",
            "Block": "311",
            "label": "SRI BALAGANGADARA SWAMIJI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23993",
            "district": "13",
            "Block": "311",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "23994",
            "district": "13",
            "Block": "311",
            "label": "SRI BALAGANGADARA SWAMIJI PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23995",
            "district": "13",
            "Block": "311",
            "label": "UK PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23996",
            "district": "13",
            "Block": "311",
            "label": "INDIAN PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "312": [
        {
            "key": "2270",
            "district": "34",
            "Block": "312",
            "label": "VETRI VIKAAS MATRICULATION SCHOOL-VAZHAVACHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2316",
            "district": "34",
            "Block": "312",
            "label": "LOURDU MATHA MATRIC HR SEC SCHOOL THANDRAMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2663",
            "district": "34",
            "Block": "312",
            "label": "RCM MS S.ANDAPATTU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        { 
            "key":"24127", "district":"34", 
            "Block":"312", "label": "Akshaya N&P School", 
            "Sch_type": "Un-aided", "Category": "Middle School"
        },
        {
            "key": "3353",
            "district": "34",
            "Block": "312",
            "label": "CHRIST JOTHI MATRIC HSS THANIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3782",
            "district": "34",
            "Block": "312",
            "label": "PUMS -NAVAKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3808",
            "district": "34",
            "Block": "312",
            "label": "PUMS KUBERAPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3828",
            "district": "34",
            "Block": "312",
            "label": "PUMS VEPPUR CHEKKADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3897",
            "district": "34",
            "Block": "312",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4024",
            "district": "34",
            "Block": "312",
            "label": "PUMS MALAYANUR CHEKKADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4197",
            "district": "34",
            "Block": "312",
            "label": "PUMS VEERANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4627",
            "district": "34",
            "Block": "312",
            "label": "ANNAI VELANKANNI MATRIC MALLIKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5183",
            "district": "34",
            "Block": "312",
            "label": "PUMS PUTHUR CHEKKADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5213",
            "district": "34",
            "Block": "312",
            "label": "PUMS NEDUGAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5250",
            "district": "34",
            "Block": "312",
            "label": "PUMS SERPPAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5728",
            "district": "34",
            "Block": "312",
            "label": "PUMS- THONDAMANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5851",
            "district": "34",
            "Block": "312",
            "label": "PUMS VIJAYAPPANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5972",
            "district": "34",
            "Block": "312",
            "label": "PUMS T.VELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5974",
            "district": "34",
            "Block": "312",
            "label": "PUMS MELPACHAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6517",
            "district": "34",
            "Block": "312",
            "label": "PUMS VAZHAVACHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6530",
            "district": "34",
            "Block": "312",
            "label": "PUMS(MUSLIM) THIRUVADATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6543",
            "district": "34",
            "Block": "312",
            "label": "PUMS MELTHIRUVADATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6709",
            "district": "34",
            "Block": "312",
            "label": "PUMS BONDHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6724",
            "district": "34",
            "Block": "312",
            "label": "PUMS VARAGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9935",
            "district": "34",
            "Block": "312",
            "label": "PUMS AGARAM PALLIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9936",
            "district": "34",
            "Block": "312",
            "label": "GHS BEEMARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9937",
            "district": "34",
            "Block": "312",
            "label": "GHSS C.REDDIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9938",
            "district": "34",
            "Block": "312",
            "label": "PUMS CHINNIYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9939",
            "district": "34",
            "Block": "312",
            "label": "PUMS DESURPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9940",
            "district": "34",
            "Block": "312",
            "label": "PUMS DEVARADIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9941",
            "district": "34",
            "Block": "312",
            "label": "GHS EDAKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9942",
            "district": "34",
            "Block": "312",
            "label": "GHS EDATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9943",
            "district": "34",
            "Block": "312",
            "label": "CARMEL HSS ELAYANKANNI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9944",
            "district": "34",
            "Block": "312",
            "label": "GOOD SHEPHERD HIGHER SECONDARY SCHOOL  GURUKILAMOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9945",
            "district": "34",
            "Block": "312",
            "label": "GHS KAMBATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9946",
            "district": "34",
            "Block": "312",
            "label": "GHS KEERANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9947",
            "district": "34",
            "Block": "312",
            "label": "GHS KILSIRUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9948",
            "district": "34",
            "Block": "312",
            "label": "PUMS KILVANAKKAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9949",
            "district": "34",
            "Block": "312",
            "label": "GHS MALAMANJANUR PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9950",
            "district": "34",
            "Block": "312",
            "label": "PUMS MELKARIPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9951",
            "district": "34",
            "Block": "312",
            "label": "GHS MELMUTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9952",
            "district": "34",
            "Block": "312",
            "label": "GHSS MOTHAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9953",
            "district": "34",
            "Block": "312",
            "label": "GHS NARAYANAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9954",
            "district": "34",
            "Block": "312",
            "label": "PUMS PERAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9955",
            "district": "34",
            "Block": "312",
            "label": "GHSS PERUNGULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9956",
            "district": "34",
            "Block": "312",
            "label": "KGBVRS PERUNGULATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9957",
            "district": "34",
            "Block": "312",
            "label": "PUMS RADHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9958",
            "district": "34",
            "Block": "312",
            "label": "GHSS RAYANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9959",
            "district": "34",
            "Block": "312",
            "label": "GHS S.GUDALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9960",
            "district": "34",
            "Block": "312",
            "label": "GHS SADHAKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9961",
            "district": "34",
            "Block": "312",
            "label": "GHSS SATHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9962",
            "district": "34",
            "Block": "312",
            "label": "GHS (GIRLS) SATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9963",
            "district": "34",
            "Block": "312",
            "label": "GHSS (BOYS) THANDARAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9964",
            "district": "34",
            "Block": "312",
            "label": "GHSS (GIRLS) THANDRAMPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9965",
            "district": "34",
            "Block": "312",
            "label": "BHARATH VIDHYA MANDIR MATRIC HR SEC SCHOOL THANDRAMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9966",
            "district": "34",
            "Block": "312",
            "label": "GHS THARADAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9967",
            "district": "34",
            "Block": "312",
            "label": "GGHSS THANIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9968",
            "district": "34",
            "Block": "312",
            "label": "GBHSS- THANIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9969",
            "district": "34",
            "Block": "312",
            "label": "KAMALAMMAL MATRIC S THANIPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9970",
            "district": "34",
            "Block": "312",
            "label": "GHS THENKARIMBALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9971",
            "district": "34",
            "Block": "312",
            "label": "RCM MS UDAYARKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "9972",
            "district": "34",
            "Block": "312",
            "label": "GHS (GIRLS) VANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9973",
            "district": "34",
            "Block": "312",
            "label": "GHSS - VANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20955",
            "district": "34",
            "Block": "312",
            "label": "SKV MATRICULATION  HR SEC SCHOOL-KOLUNDAMPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22423",
            "district": "34",
            "Block": "312",
            "label": "SRI JAYAM MATRIC.HR.SEC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22424",
            "district": "34",
            "Block": "312",
            "label": "GHS-THENMUDIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23119",
            "district": "34",
            "Block": "312",
            "label": "THE GREEN MOUNT INTERNATIONAL SCHOOL RADHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23997",
            "district": "34",
            "Block": "312",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "313": [
        {
            "key": "1503",
            "district": "25",
            "Block": "313",
            "label": "KAMALA SUBRAMANIAM MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1594",
            "district": "25",
            "Block": "313",
            "label": "PUMS - VALLAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1932",
            "district": "25",
            "Block": "313",
            "label": "FR. ANTONY MATRIC HR.SEC.SCHOOL,VALLAM,THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2048",
            "district": "25",
            "Block": "313",
            "label": "LITTLE SCHOLARS MATRICULATION HIGHER SECONDARY SCHOOL- THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2287",
            "district": "25",
            "Block": "313",
            "label": "BEST MATRICULATION HIGHER SECONDARY SCHOOL,  MARIYAMMANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2304",
            "district": "25",
            "Block": "313",
            "label": "PUMS - KEELAVASTHACHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2325",
            "district": "25",
            "Block": "313",
            "label": "C.S. MATRICULATION HIGHER SECONDARY SCHOOL, MARIYAMMANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2652",
            "district": "25",
            "Block": "313",
            "label": "PUMS - REDDYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2683",
            "district": "25",
            "Block": "313",
            "label": "PUMS - COODALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2711",
            "district": "25",
            "Block": "313",
            "label": "PUMS - NAGATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2869",
            "district": "25",
            "Block": "313",
            "label": "PUMS - KATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2925",
            "district": "25",
            "Block": "313",
            "label": "PUMS - THIRUMALAISAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3009",
            "district": "25",
            "Block": "313",
            "label": "PUMS - RAVUSAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3011",
            "district": "25",
            "Block": "313",
            "label": "PUMS - KULICHAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3014",
            "district": "25",
            "Block": "313",
            "label": "PUMS - KALAIGNAR  NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3109",
            "district": "25",
            "Block": "313",
            "label": "PUMS - SURAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3165",
            "district": "25",
            "Block": "313",
            "label": "PUMS - AYYASAMYPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3261",
            "district": "25",
            "Block": "313",
            "label": "PUMS - MAPPILLAINAYAGANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3332",
            "district": "25",
            "Block": "313",
            "label": "AMS- RAMAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3346",
            "district": "25",
            "Block": "313",
            "label": "PUMS - AMMANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3357",
            "district": "25",
            "Block": "313",
            "label": "AMS- AMMANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3377",
            "district": "25",
            "Block": "313",
            "label": "YAGAPPA MATRICULATION HSS-KALAINGNAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3618",
            "district": "25",
            "Block": "313",
            "label": "PUMS - MELAMANOJIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3654",
            "district": "25",
            "Block": "313",
            "label": "AMS - SITHIRAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3681",
            "district": "25",
            "Block": "313",
            "label": "PUMS - NANCHIKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3870",
            "district": "25",
            "Block": "313",
            "label": "AMS - ST. XAVIER  VALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3915",
            "district": "25",
            "Block": "313",
            "label": "PUMS - MARIYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3941",
            "district": "25",
            "Block": "313",
            "label": "PUMS - THITTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4484",
            "district": "25",
            "Block": "313",
            "label": "PUMS - SAKKARASAMANTHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4746",
            "district": "25",
            "Block": "313",
            "label": "PUMS - THENPERAMPOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16382",
            "district": "25",
            "Block": "313",
            "label": "GHS- KALLAPERAMPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16383",
            "district": "25",
            "Block": "313",
            "label": "PUMS - VANNARAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16384",
            "district": "25",
            "Block": "313",
            "label": "GHSS - ALAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16385",
            "district": "25",
            "Block": "313",
            "label": "ST. MARY'S HIGH SCHOOL  - THIRUKKANURPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16386",
            "district": "25",
            "Block": "313",
            "label": "KALAIMAGAL HR SEC SCHOOL VALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16387",
            "district": "25",
            "Block": "313",
            "label": "KENDRIYA VIDYALAYA  AF STATION",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16388",
            "district": "25",
            "Block": "313",
            "label": "GHS-PILLAIYARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16389",
            "district": "25",
            "Block": "313",
            "label": "A.A. SUGAR FACT. HS-KURUNGULAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16390",
            "district": "25",
            "Block": "313",
            "label": "GHS - KURUNGULAM EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16391",
            "district": "25",
            "Block": "313",
            "label": "GHS - MATHUR (WEST)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16392",
            "district": "25",
            "Block": "313",
            "label": "GHS - THAZHAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16393",
            "district": "25",
            "Block": "313",
            "label": "THAMARAI INTERNATIONAL -KADAKADAPAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16394",
            "district": "25",
            "Block": "313",
            "label": "G (G)HSS (GIRLS) - MARIYAMMAN KOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16395",
            "district": "25",
            "Block": "313",
            "label": "GBHSS - MARIYAMMAN KOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16396",
            "district": "25",
            "Block": "313",
            "label": "ST.MARY'S HR SEC SCHOOL -MARIAMMANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16397",
            "district": "25",
            "Block": "313",
            "label": "ST.MICHAEL'S HIGH SCHOOL - MADHAKKOTTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16398",
            "district": "25",
            "Block": "313",
            "label": "LITTLESCHOLARS(T)HS-PILOMINANAGER",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16399",
            "district": "25",
            "Block": "313",
            "label": "GHS- EZHUPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16400",
            "district": "25",
            "Block": "313",
            "label": "GHS - N. VALLUNDANPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16401",
            "district": "25",
            "Block": "313",
            "label": "GHSS - MARUNGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16402",
            "district": "25",
            "Block": "313",
            "label": "GHS - KOLLANKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16403",
            "district": "25",
            "Block": "313",
            "label": "GHS - KASANADU PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16404",
            "district": "25",
            "Block": "313",
            "label": "AIDED  MS - KANDITHAMPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16405",
            "district": "25",
            "Block": "313",
            "label": "ST. JOSEPHS MATRICULATION HIGHER SECONDARY SCHOOL,  VILAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16406",
            "district": "25",
            "Block": "313",
            "label": "BLOSSOM PUBLIC SCHOOL -SURAKKOTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16407",
            "district": "25",
            "Block": "313",
            "label": "GOVT ADW  HS -  MANOJIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16408",
            "district": "25",
            "Block": "313",
            "label": "ST.MARYS HS - THILLAI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16409",
            "district": "25",
            "Block": "313",
            "label": "GHS - PANAVELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16410",
            "district": "25",
            "Block": "313",
            "label": "ARULNERI HIGH SCHOOL- AMMANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16411",
            "district": "25",
            "Block": "313",
            "label": "GBHSS - VALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16412",
            "district": "25",
            "Block": "313",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL -VALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20985",
            "district": "25",
            "Block": "313",
            "label": "CHOLA INTERNATIONAL -THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20986",
            "district": "25",
            "Block": "313",
            "label": "YAGAPPA INTERNATIONAL. SCHOOL - THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20987",
            "district": "25",
            "Block": "313",
            "label": "CHRIST INTERNATIONAL SCHOOL-NEELAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21101",
            "district": "25",
            "Block": "313",
            "label": "PONNAIYAH  RAMAJAYAM PUBLIC SCHOOL-SOORAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21610",
            "district": "25",
            "Block": "313",
            "label": "GANDHI MISSION INTERNATIONAL SCHOOL (CBSE)-MATHUR WEST",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21880",
            "district": "25",
            "Block": "313",
            "label": "RAMYA SATHIANATHAN SENIOR SECONDARY SCHOOL(CBSE)-ALAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21881",
            "district": "25",
            "Block": "313",
            "label": "KAMALA SUBRAMANIAM CBSE SCHOOL  -THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22222",
            "district": "25",
            "Block": "313",
            "label": "RADIANT INTERNATIONAL  SCHOOL CBSE -MADHAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22575",
            "district": "25",
            "Block": "313",
            "label": "BLOSSOM BLUEBELLS ACADEMY -THIRUKKANURPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22576",
            "district": "25",
            "Block": "313",
            "label": "VELAMMAL BODHI CAMPUS  SCHOOL (CBSE)- NILAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23139",
            "district": "25",
            "Block": "313",
            "label": "BISHOP DEVADASS AMBROSE VIDYALAYA -THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23330",
            "district": "25",
            "Block": "313",
            "label": "KALAIMAGAL VIDHYA MANDIR SCHOOL -THANJAVUR RURAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23563",
            "district": "25",
            "Block": "313",
            "label": "Government Model School, Thanjavur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23564",
            "district": "25",
            "Block": "313",
            "label": "Thanjavur District Government Model School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23563",
            "district": "25",
            "Block": "313",
            "label": "Government Model School, Thanjavur",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23564",
            "district": "25",
            "Block": "313",
            "label": "Thanjavur District Government Model School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23998",
            "district": "25",
            "Block": "313",
            "label": "HIDHAYATH MATRICULATION SCHOOL - VALLAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "23999",
            "district": "25",
            "Block": "313",
            "label": "AMRITA VIDYALAYAM, THANJAVUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "314": [
        {
            "key": "1370",
            "district": "25",
            "Block": "314",
            "label": "MAXWELL MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2243",
            "district": "25",
            "Block": "314",
            "label": "T.K.R. MS-AYYANKADAI THERU-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2266",
            "district": "25",
            "Block": "314",
            "label": "MORNING STAR MATRICULATION SCHOOL THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2434",
            "district": "25",
            "Block": "314",
            "label": "ST.PETERS HSS-VADAKKUVEETHI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2557",
            "district": "25",
            "Block": "314",
            "label": "GNANAM MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2626",
            "district": "25",
            "Block": "314",
            "label": "CMS - KARANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2737",
            "district": "25",
            "Block": "314",
            "label": "NAV BHARAT MATRIC HR SEC SCHOOL M.C.ROAD, THANJAVUR.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2861",
            "district": "25",
            "Block": "314",
            "label": "ST.JOHN DE BRITTO AIDED MIDDLE SCHOOL-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3010",
            "district": "25",
            "Block": "314",
            "label": "T.E.L.C  MS-MANAMPUCHAVADI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3093",
            "district": "25",
            "Block": "314",
            "label": "MUDAL THARA INDU AIDED MIDDLE SCHOOL-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3095",
            "district": "25",
            "Block": "314",
            "label": "RAILWAYCOLONY AIDED MIDDLE SCHOOL-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3243",
            "district": "25",
            "Block": "314",
            "label": "DON BOSCO MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3293",
            "district": "25",
            "Block": "314",
            "label": "SHRI VENKATESWARA MATRIC HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3311",
            "district": "25",
            "Block": "314",
            "label": "CMS-PALLIAGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3324",
            "district": "25",
            "Block": "314",
            "label": "CMS-OLD HOUSING UNIT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3363",
            "district": "25",
            "Block": "314",
            "label": "ROYAL MATRIC HIGHER SECONDARY SCHOOL - M.CHAVADI-THANJAVURI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3381",
            "district": "25",
            "Block": "314",
            "label": "ST. ANTONY'S AIDED MIDDLE SCHOOL-M-CHAVADI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3538",
            "district": "25",
            "Block": "314",
            "label": "JAINA FREE AIDED MIDDLE SCHOOL-KARANTHAI-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3658",
            "district": "25",
            "Block": "314",
            "label": "MAKKAL  UZHIYA SANGAM AIDED MIDDLE SCHOOL-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3672",
            "district": "25",
            "Block": "314",
            "label": "D.K.SUBBAIYAH NAIDU MS-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3783",
            "district": "25",
            "Block": "314",
            "label": "SRI SADASIVA AIDED MIDDLE SCHOOL- THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4036",
            "district": "25",
            "Block": "314",
            "label": "GIRLS CHRISTIAN AIDED HR SEC SCHOOL-M-CHAVADI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4153",
            "district": "25",
            "Block": "314",
            "label": "CMS-CHINNAKADAITHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4203",
            "district": "25",
            "Block": "314",
            "label": "SRI. GANESA VID. MS- POOKARATHERU-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4517",
            "district": "25",
            "Block": "314",
            "label": "CMS-VANDIKARATHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4740",
            "district": "25",
            "Block": "314",
            "label": "INDIAN MATRIC HIGH SCHOOL - ESWARI NAGAR-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5216",
            "district": "25",
            "Block": "314",
            "label": "BHARATHA MADA MS-VANDIKARA THERU-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5760",
            "district": "25",
            "Block": "314",
            "label": "SRI SAVITRI VID. MAT. HS-KARANTHAI-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16353",
            "district": "25",
            "Block": "314",
            "label": "VITTAL MATRIC SCHOOL PALLIYAGGRAHARAM-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16354",
            "district": "25",
            "Block": "314",
            "label": "UMA MAHESWARA HSS-KARANTHAI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16355",
            "district": "25",
            "Block": "314",
            "label": "KALYANASUNDARAM HIGHER SECONDARY SCHOOL, THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16356",
            "district": "25",
            "Block": "314",
            "label": "UKKADAI SRI AMBAL HIGH SCHOOL -MELAVEETHI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16357",
            "district": "25",
            "Block": "314",
            "label": "VEERA RAGHAVA HSS-THERKU VEETHI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16358",
            "district": "25",
            "Block": "314",
            "label": "ORIENTAL HSS-THERKKU VEETHI-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16359",
            "district": "25",
            "Block": "314",
            "label": "CHSS-THENKEEL ALANGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16360",
            "district": "25",
            "Block": "314",
            "label": "GHSS (GIRLS) - THANJAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16361",
            "district": "25",
            "Block": "314",
            "label": "RAJAH'S HSS - ARANMANAI VALAGAM-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16362",
            "district": "25",
            "Block": "314",
            "label": "BLAKE AIDED HIGHER SECONDARY SCHOOL - M-CHAVADI-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16363",
            "district": "25",
            "Block": "314",
            "label": "CMS-SOWRASTRA THERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16364",
            "district": "25",
            "Block": "314",
            "label": "GMS RAJAJI - THANJAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16365",
            "district": "25",
            "Block": "314",
            "label": "GHSS(BOYS) - MEMBALAM-THANJAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16366",
            "district": "25",
            "Block": "314",
            "label": "GHSS -HEARING IMPAIRED (MEMBALAM)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16367",
            "district": "25",
            "Block": "314",
            "label": "P.V.SELVARAJ (GIRLS) AIDED HR SEC SCHOOL- MEMBALAM-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16368",
            "district": "25",
            "Block": "314",
            "label": "GHSS-VISUALLY IMPAIRED(MEMBALAM)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16369",
            "district": "25",
            "Block": "314",
            "label": "THIRUMAGAL AIDED HR SEC SCHOOL - M.C.ROAD-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16370",
            "district": "25",
            "Block": "314",
            "label": "ALLWIN HSS-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16371",
            "district": "25",
            "Block": "314",
            "label": "PONNAIYAH RAMAJAYAM PUBLIC HR. SEC. SCHOOL - THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16372",
            "district": "25",
            "Block": "314",
            "label": "SPADIX MATRICULATION HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16373",
            "district": "25",
            "Block": "314",
            "label": "CHS- NEELAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16374",
            "district": "25",
            "Block": "314",
            "label": "AUXILIUM GIRLS HIGHER SECONDARY SCHOOL-THANJAVUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16375",
            "district": "25",
            "Block": "314",
            "label": "MAHARISHI VIDYA MANDIR CBSE SCHOOL -THANJAUVR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16376",
            "district": "25",
            "Block": "314",
            "label": "SACRED HEART (GIRLS)HSS - THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16377",
            "district": "25",
            "Block": "314",
            "label": "ST. ANTONYS  HSS-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16378",
            "district": "25",
            "Block": "314",
            "label": "SEVENTH DAY ADVENTIST MATRIC HSS,VANDIKARATHERU-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16379",
            "district": "25",
            "Block": "314",
            "label": "ST. JOSEPH(GIRLS) HSS-POOKARATHERU-THANJAVUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16380",
            "district": "25",
            "Block": "314",
            "label": "THE CHRIST THE KING HSS-A.S.NA-THANJAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16381",
            "district": "25",
            "Block": "314",
            "label": "CHSS-ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        }
    ],
    "315": [
        {
            "key": "3241",
            "district": "12",
            "Block": "315",
            "label": "CHETTINAD RANI MEYYAMMAI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3276",
            "district": "12",
            "Block": "315",
            "label": "MALAR MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3341",
            "district": "12",
            "Block": "315",
            "label": "N.R.M.G & R MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4400",
            "district": "12",
            "Block": "315",
            "label": "LORD'S PARK MATRIC. HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4612",
            "district": "12",
            "Block": "315",
            "label": "ST.ANTONY'S MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4624",
            "district": "12",
            "Block": "315",
            "label": "VELAMMAL VIDHYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4678",
            "district": "12",
            "Block": "315",
            "label": "SRI SANKARA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23363",
            "district": "12",
            "Block": "315",
            "label": "SHRI GURU BRAMMA VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "4714",
            "district": "12",
            "Block": "315",
            "label": "SRI MEENATCHI MATRIC SCHOOL,KARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4745",
            "district": "12",
            "Block": "315",
            "label": "GURU VIDHYALAYA MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5281",
            "district": "12",
            "Block": "315",
            "label": "STAR SCHOOL CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5345",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,ACHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5379",
            "district": "12",
            "Block": "315",
            "label": "LITTLE FLOWER MATRIC  HR. SEC. SCHOOL,MANAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5475",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,MACHANNA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5489",
            "district": "12",
            "Block": "315",
            "label": "VENUS MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5926",
            "district": "12",
            "Block": "315",
            "label": "SRINIVASA VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6054",
            "district": "12",
            "Block": "315",
            "label": "PANCHAYAT.UNION MIDDLE .SCHOOL,RETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6058",
            "district": "12",
            "Block": "315",
            "label": "PANCHAYAT.UNION.MIDDLE.SCHOOL,GOVINDHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6260",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,CHINNAMOOKANAKURUCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6455",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,KUMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13859",
            "district": "12",
            "Block": "315",
            "label": "GOVT.HR.SEC.SCHOOL,K.P.THALAIPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13860",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT.HIGH SCHOOL, ANDANKOIL EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13861",
            "district": "12",
            "Block": "315",
            "label": "G.HIGH SCHOOL, KOYAMPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13862",
            "district": "12",
            "Block": "315",
            "label": "P.U.MID.SCHOOL,SUKKALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13863",
            "district": "12",
            "Block": "315",
            "label": "GOVT.HIGH.SCHOOL, PALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13864",
            "district": "12",
            "Block": "315",
            "label": "P.A.VIDHYABHAVAN HR.SEC.SCHOOL,KAKKAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13865",
            "district": "12",
            "Block": "315",
            "label": "VELAMMAL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13866",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,KODANKIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13867",
            "district": "12",
            "Block": "315",
            "label": "P.U.MID.SCHOOL,RAYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13868",
            "district": "12",
            "Block": "315",
            "label": "G.H.SCHOOL, T.SELLANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13869",
            "district": "12",
            "Block": "315",
            "label": "ADW HIGH SCHOOL, SANAPRETTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13870",
            "district": "12",
            "Block": "315",
            "label": "ST.MARY'S AIDED HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13871",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,GANDHI GRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13872",
            "district": "12",
            "Block": "315",
            "label": "GOVT.HR.SEC.SCHOOL,THANTHONI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13873",
            "district": "12",
            "Block": "315",
            "label": "ST.THERASA GIRLS.HIGHER SECONDARY SCHOOL, KARUR-4",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13874",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,P. VELLALAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13875",
            "district": "12",
            "Block": "315",
            "label": "DR.M.A.M.RAMASAMY CHETTIAR GHS",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13876",
            "district": "12",
            "Block": "315",
            "label": "RANI MEYYAMMAI HR. SEC. SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13877",
            "district": "12",
            "Block": "315",
            "label": "CHETTINAD VIDYA MANDIR CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13878",
            "district": "12",
            "Block": "315",
            "label": "Government Higher Secondary School ,Uppidamangalam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13879",
            "district": "12",
            "Block": "315",
            "label": "G.H.S,KARRUPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13880",
            "district": "12",
            "Block": "315",
            "label": "ASHRAM HR. SEC. SCHOOL,MANAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13881",
            "district": "12",
            "Block": "315",
            "label": "GOVT. HR. SEC SCHOOL, MANAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13882",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THAMMANAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13883",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,PAGANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13884",
            "district": "12",
            "Block": "315",
            "label": "BHARATHI HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13885",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,JALLIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13886",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY.SCHOOL,VELLIYANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13887",
            "district": "12",
            "Block": "315",
            "label": "G.HR.SEC.SCHOOL,VELIYANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13888",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,ALLALIGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13889",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13890",
            "district": "12",
            "Block": "315",
            "label": "P.U.M.SCHOOL,BOMMANUTHUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13891",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,JEGATHABI -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13892",
            "district": "12",
            "Block": "315",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,PORANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13893",
            "district": "12",
            "Block": "315",
            "label": "KARUR SARASWATHI VIDHYALAYA HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23000",
            "district": "12",
            "Block": "315",
            "label": "SHRI VIJAYALAKSHMI  VIDHYALAYAA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23033",
            "district": "12",
            "Block": "315",
            "label": "VIJAY VIDHYA MANDIR ,EMOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23044",
            "district": "12",
            "Block": "315",
            "label": "KARUR SARASWATHI VIDHYA MANDHIRR, THULASIKODUMBU, KARUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23494",
            "district": "12",
            "Block": "315",
            "label": "KARUR PUBLIC SCHOOL(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23495",
            "district": "12",
            "Block": "315",
            "label": "THE ADRIAN LOYAL SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23494",
            "district": "12",
            "Block": "315",
            "label": "KARUR PUBLIC SCHOOL(CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23495",
            "district": "12",
            "Block": "315",
            "label": "THE ADRIAN LOYAL SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "316": [
        {
            "key": "1387",
            "district": "22",
            "Block": "316",
            "label": "SRI VIDHYA MANDIR MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3145",
            "district": "22",
            "Block": "316",
            "label": "VETHATHIRI MAHARISHI MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11291",
            "district": "22",
            "Block": "316",
            "label": "GHS M.OLAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11292",
            "district": "22",
            "Block": "316",
            "label": "PUMS MALLIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11293",
            "district": "22",
            "Block": "316",
            "label": "PUMS RASANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11294",
            "district": "22",
            "Block": "316",
            "label": "GHSS AMARAGUNDHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11295",
            "district": "22",
            "Block": "316",
            "label": "PUMS ALAKUSAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11296",
            "district": "22",
            "Block": "316",
            "label": "GHSS T.KONAGAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11297",
            "district": "22",
            "Block": "316",
            "label": "PUMS PAVALATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11298",
            "district": "22",
            "Block": "316",
            "label": "PUMS PARAKKALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11299",
            "district": "22",
            "Block": "316",
            "label": "PUMS ARUNACHALAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11300",
            "district": "22",
            "Block": "316",
            "label": "PUMS M.G.R COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11301",
            "district": "22",
            "Block": "316",
            "label": "PUMS MOTTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11302",
            "district": "22",
            "Block": "316",
            "label": "PUMS THARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11303",
            "district": "22",
            "Block": "316",
            "label": "SENGUNTHAR MATRIC HR. SEC. SCHOOL, THARAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11304",
            "district": "22",
            "Block": "316",
            "label": "St.CHARLES MATRIC SCHOOL, THARAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11305",
            "district": "22",
            "Block": "316",
            "label": "GGHSS THARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11306",
            "district": "22",
            "Block": "316",
            "label": "SENGUNDHAR MAHAJANA HR. SEC. SCHOOL, THARAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11307",
            "district": "22",
            "Block": "316",
            "label": "SRI JOTHI HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11308",
            "district": "22",
            "Block": "316",
            "label": "VANNIYAKULA SATHIRIYAR HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11309",
            "district": "22",
            "Block": "316",
            "label": "PUMS ARURPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11310",
            "district": "22",
            "Block": "316",
            "label": "PUMS PALAKARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11311",
            "district": "22",
            "Block": "316",
            "label": "GHS SEDAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11312",
            "district": "22",
            "Block": "316",
            "label": "K.G.B.V.  CHINNAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11313",
            "district": "22",
            "Block": "316",
            "label": "PUMS KATHIMARANVALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11314",
            "district": "22",
            "Block": "316",
            "label": "GHSS RAMIREDDIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11315",
            "district": "22",
            "Block": "316",
            "label": "GHS VENNANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11316",
            "district": "22",
            "Block": "316",
            "label": "PUMS  ARIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11317",
            "district": "22",
            "Block": "316",
            "label": "PUMS NACHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11318",
            "district": "22",
            "Block": "316",
            "label": "SREE VARI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11319",
            "district": "22",
            "Block": "316",
            "label": "PUMS EDAIYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11320",
            "district": "22",
            "Block": "316",
            "label": "PUMS A.P.VATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11321",
            "district": "22",
            "Block": "316",
            "label": "PUMS MATAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11322",
            "district": "22",
            "Block": "316",
            "label": "PUMS VANICHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11323",
            "district": "22",
            "Block": "316",
            "label": "PUMS LACHUMAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11324",
            "district": "22",
            "Block": "316",
            "label": "PUMS SENGODANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11325",
            "district": "22",
            "Block": "316",
            "label": "GHSS CHINNAPILLAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11326",
            "district": "22",
            "Block": "316",
            "label": "GHSS, THUTTAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11327",
            "district": "22",
            "Block": "316",
            "label": "PUMS KONDAKARANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11328",
            "district": "22",
            "Block": "316",
            "label": "GHSS CHINNAPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11329",
            "district": "22",
            "Block": "316",
            "label": "PUMS KRISHNAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11330",
            "district": "22",
            "Block": "316",
            "label": "PUMS KOTTAIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11331",
            "district": "22",
            "Block": "316",
            "label": "PUMS PERIYAKARUKKALVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20913",
            "district": "22",
            "Block": "316",
            "label": "SENGUNTHAR PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21022",
            "district": "22",
            "Block": "316",
            "label": "SALEM HI-TECH MATRIC SCHOOL, SELVAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21023",
            "district": "22",
            "Block": "316",
            "label": "SRM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21133",
            "district": "22",
            "Block": "316",
            "label": "PUMS KANGANIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21210",
            "district": "22",
            "Block": "316",
            "label": "MODEL SCHOOL, THARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        }
    ],
    "317": [
        {
            "key": "3646",
            "district": "29",
            "Block": "317",
            "label": "AIED MS SITTILARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4712",
            "district": "29",
            "Block": "317",
            "label": "Rabindra Bharathi Matric School, THATHIENGARPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4950",
            "district": "29",
            "Block": "317",
            "label": "SB MAT. SCH.SORIAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5336",
            "district": "29",
            "Block": "317",
            "label": "INTERNATIONAL TAMILAN Matriculation Academy",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5685",
            "district": "29",
            "Block": "317",
            "label": "SOWDAMBIKAA  MAT. HR. SEC. SCHOOL. THATHIENGARPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14619",
            "district": "29",
            "Block": "317",
            "label": "GHS PILLAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14620",
            "district": "29",
            "Block": "317",
            "label": "PUMS URUDAIAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14621",
            "district": "29",
            "Block": "317",
            "label": "GHS KAARUGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14622",
            "district": "29",
            "Block": "317",
            "label": "AIDED MS KONAPPAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14623",
            "district": "29",
            "Block": "317",
            "label": "PUMS MAHADEVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14624",
            "district": "29",
            "Block": "317",
            "label": "AIDED MS MANAGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14625",
            "district": "29",
            "Block": "317",
            "label": "PUMS AZHAGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14626",
            "district": "29",
            "Block": "317",
            "label": "PUMS AYETHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14627",
            "district": "29",
            "Block": "317",
            "label": "GHSS, PAITHAMPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14628",
            "district": "29",
            "Block": "317",
            "label": "GHSS, PAPPAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14629",
            "district": "29",
            "Block": "317",
            "label": "GHS, SERUGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14630",
            "district": "29",
            "Block": "317",
            "label": "GHSS, THUMBALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14631",
            "district": "29",
            "Block": "317",
            "label": "GOVT. ADW HSS THULAIYANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14632",
            "district": "29",
            "Block": "317",
            "label": "PUMS, JEMBUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14633",
            "district": "29",
            "Block": "317",
            "label": "AIED MS URAKKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14634",
            "district": "29",
            "Block": "317",
            "label": "GHSS, VALASIRAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14635",
            "district": "29",
            "Block": "317",
            "label": "PUMS, VALAIYEDUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14636",
            "district": "29",
            "Block": "317",
            "label": "GOVT HIGHER SECONDARY SCHOOL (BOYS) METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14637",
            "district": "29",
            "Block": "317",
            "label": "GHSS(G) METTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14638",
            "district": "29",
            "Block": "317",
            "label": "SAMS THATHIENGARPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14639",
            "district": "29",
            "Block": "317",
            "label": "GGHSS THATHIENGARPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14640",
            "district": "29",
            "Block": "317",
            "label": "GHSS(B) THATHIENGARPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21377",
            "district": "29",
            "Block": "317",
            "label": "GOVT HIGH SCHOOL,KARIGALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21378",
            "district": "29",
            "Block": "317",
            "label": "GHS,VELAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22192",
            "district": "29",
            "Block": "317",
            "label": "CHELAMMAL VIDHYAASHRAM Senior SEC. CBSE AYETHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22534",
            "district": "29",
            "Block": "317",
            "label": "GHS,V.GANESAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22790",
            "district": "29",
            "Block": "317",
            "label": "Bodhi vidhyalaya CBSE,Annai Nagar,M.Pudhupatti,musiri.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22792",
            "district": "29",
            "Block": "317",
            "label": "VIDHYA BHARATHI VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24000",
            "district": "29",
            "Block": "317",
            "label": "TKV MATRICULATION SCHOOL,THUMBALAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24001",
            "district": "29",
            "Block": "317",
            "label": "V.J.MATRICULATION SCHOOL,SITTILARAI(PO),MUSIRI,TIRUCHIRAPPALLI DISTRICT",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "318": [
        {
            "key": "3580",
            "district": "34",
            "Block": "318",
            "label": "PUMS JAPTHIKARANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4125",
            "district": "34",
            "Block": "318",
            "label": "PUMS RAMASAMUDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5909",
            "district": "34",
            "Block": "318",
            "label": "PUMS  THELLAR ADK",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6171",
            "district": "34",
            "Block": "318",
            "label": "TTMMS DESUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "9628",
            "district": "34",
            "Block": "318",
            "label": "GGHS C.M.PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9629",
            "district": "34",
            "Block": "318",
            "label": "PUMS-CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9630",
            "district": "34",
            "Block": "318",
            "label": "GHSS(BOYS) DESUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9631",
            "district": "34",
            "Block": "318",
            "label": "GHSS(GIRLS) DESUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9632",
            "district": "34",
            "Block": "318",
            "label": "PUMS GENGAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9633",
            "district": "34",
            "Block": "318",
            "label": "GHS KADAMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9634",
            "district": "34",
            "Block": "318",
            "label": "PUMS KANDAVARATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9635",
            "district": "34",
            "Block": "318",
            "label": "PUMS S.KATTERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9636",
            "district": "34",
            "Block": "318",
            "label": "PUMS KILNANDIYAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9637",
            "district": "34",
            "Block": "318",
            "label": "GHS KILPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9638",
            "district": "34",
            "Block": "318",
            "label": "PUMS KILVELLIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9639",
            "district": "34",
            "Block": "318",
            "label": "PUMS KILVILLIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9640",
            "district": "34",
            "Block": "318",
            "label": "GHS KODIYALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9641",
            "district": "34",
            "Block": "318",
            "label": "GHS KONDAIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9642",
            "district": "34",
            "Block": "318",
            "label": "PUMS KOOTHAMBATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9643",
            "district": "34",
            "Block": "318",
            "label": "GHS KORAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9644",
            "district": "34",
            "Block": "318",
            "label": "GHSS KUNNAGAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9645",
            "district": "34",
            "Block": "318",
            "label": "PUMS MANJAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9646",
            "district": "34",
            "Block": "318",
            "label": "GHSS MAZHAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9647",
            "district": "34",
            "Block": "318",
            "label": "PUMS MEESANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9648",
            "district": "34",
            "Block": "318",
            "label": "GHS MELPATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9649",
            "district": "34",
            "Block": "318",
            "label": "GHS NADUKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9650",
            "district": "34",
            "Block": "318",
            "label": "GHSS NALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9651",
            "district": "34",
            "Block": "318",
            "label": "GHS NERKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9652",
            "district": "34",
            "Block": "318",
            "label": "PUMS PENNATTAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9653",
            "district": "34",
            "Block": "318",
            "label": "GHSS PONNUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9654",
            "district": "34",
            "Block": "318",
            "label": "PUMS SATHIYAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9655",
            "district": "34",
            "Block": "318",
            "label": "PUMS SENAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9656",
            "district": "34",
            "Block": "318",
            "label": "PUMS THAKKANDARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9657",
            "district": "34",
            "Block": "318",
            "label": "GHSS(BOYS)-THELLAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9658",
            "district": "34",
            "Block": "318",
            "label": "GGHS THELLAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9659",
            "district": "34",
            "Block": "318",
            "label": "OXFORD HIGHER SECONDARY SCHOOL, THELLAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9660",
            "district": "34",
            "Block": "318",
            "label": "PUMS THENTHINNALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9661",
            "district": "34",
            "Block": "318",
            "label": "GHSS THEYYAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9662",
            "district": "34",
            "Block": "318",
            "label": "GHS VAYALAMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9663",
            "district": "34",
            "Block": "318",
            "label": "GHS VEDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        { 
            "key":"24121", "district":"34", "Block":"318", 
            "label": "SRI MAHAVEER MATRICULATION SCHOOL - GUNAMBADI", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "21263",
            "district": "34",
            "Block": "318",
            "label": "ARAVIND INTERNATIONAL MATRICULATION SCHOOL - THELLAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "319": [
        {
            "key": "460",
            "district": "27",
            "Block": "319",
            "label": "MARY MATHA MATRIC HIGHER SECONDARY SCHOOL - THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "622",
            "district": "27",
            "Block": "319",
            "label": "T.M.H.N.U. MATRIC HSS - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1305",
            "district": "27",
            "Block": "319",
            "label": "T.K.S. MATRIC HR. SEC. SCHOOL - THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1346",
            "district": "27",
            "Block": "319",
            "label": "BAGYA MATRICULATION SCHOOL - ALLINAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1409",
            "district": "27",
            "Block": "319",
            "label": "BHARATHI VIDYA MANTHIR MATRIC HSS - NAGALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1430",
            "district": "27",
            "Block": "319",
            "label": "MADURAI CEOA MATRICULATION  HR SEC SCHOOL -  PALANICHETTIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1474",
            "district": "27",
            "Block": "319",
            "label": "SANTINIKETAN MATRIC HSS - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1514",
            "district": "27",
            "Block": "319",
            "label": "PUMS - MUTHUDEVANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1523",
            "district": "27",
            "Block": "319",
            "label": "HINDU MS - SEELAYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1733",
            "district": "27",
            "Block": "319",
            "label": "PUMS - NAGALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1763",
            "district": "27",
            "Block": "319",
            "label": "PUMS - THADICHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1990",
            "district": "27",
            "Block": "319",
            "label": "VELAMMAL MATRICULATION HR. SEC. SCHOOL, MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1991",
            "district": "27",
            "Block": "319",
            "label": "PAALAM MATRIC HR. SEC.  SCHOOL - UNJAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2016",
            "district": "27",
            "Block": "319",
            "label": "PUMS - ARANMANAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2212",
            "district": "27",
            "Block": "319",
            "label": "GOVT MS - THAPPUKUNDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2445",
            "district": "27",
            "Block": "319",
            "label": "S.B.KALAIMAGAL MS - KUPPINAYAKANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2681",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, K.LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2697",
            "district": "27",
            "Block": "319",
            "label": "PUMS - KATTUNAYAKKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2846",
            "district": "27",
            "Block": "319",
            "label": "GGMS - VAYALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2860",
            "district": "27",
            "Block": "319",
            "label": "SRI PADMANAPAN  MS - ALLINAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2951",
            "district": "27",
            "Block": "319",
            "label": "GKMS - KARUVELNAYAKKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2992",
            "district": "27",
            "Block": "319",
            "label": "PUMS -AMBASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3006",
            "district": "27",
            "Block": "319",
            "label": "AMARAVATHI MS - THIRUCHENDUR, KODANGIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3064",
            "district": "27",
            "Block": "319",
            "label": "PUMS - UPPARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3117",
            "district": "27",
            "Block": "319",
            "label": "N.P.V MS - POOMALAIKUNDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3164",
            "district": "27",
            "Block": "319",
            "label": "PUMS - BALAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3435",
            "district": "27",
            "Block": "319",
            "label": "PUMS - UNJAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3794",
            "district": "27",
            "Block": "319",
            "label": "GKMS - KAKKIVADANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4567",
            "district": "27",
            "Block": "319",
            "label": "BHARATHI MS - SEELAYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4615",
            "district": "27",
            "Block": "319",
            "label": "KOTTUR MS - KOTTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6240",
            "district": "27",
            "Block": "319",
            "label": "S.W.P.M. KAMBAR MS - SEELAYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6533",
            "district": "27",
            "Block": "319",
            "label": "GKMS - BOMMAYAKOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18620",
            "district": "27",
            "Block": "319",
            "label": "KAMMA DHARMA HS - GOVINDANAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18621",
            "district": "27",
            "Block": "319",
            "label": "SRI VARADHA VENKATARAMANA HSS - VENKATACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18622",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, DHARMAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18623",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, KODUVILARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18624",
            "district": "27",
            "Block": "319",
            "label": "S.R.G. GOVT HS, SRIRENGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18625",
            "district": "27",
            "Block": "319",
            "label": "SHRI CHANDRA GUPTA MAURYA INTERNATIONAL SCHOOL, UPPARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18626",
            "district": "27",
            "Block": "319",
            "label": "GOVT KALLAR HSS, ANNANJI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18627",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, KOTTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18628",
            "district": "27",
            "Block": "319",
            "label": "GOVT HSS, VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18629",
            "district": "27",
            "Block": "319",
            "label": "PALANIAPPA MEMORIAL HSS - PALANICHETTIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18630",
            "district": "27",
            "Block": "319",
            "label": "VELAMMAL VIDHYA CBSE SCHOOL - MUTHUTHEVANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18631",
            "district": "27",
            "Block": "319",
            "label": "MUTHIAH HS - ALLINAGARAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18632",
            "district": "27",
            "Block": "319",
            "label": "GOVT MODEL  HSS, ALLINAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18633",
            "district": "27",
            "Block": "319",
            "label": "R.C. HS - THENI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18634",
            "district": "27",
            "Block": "319",
            "label": "N.A.KONDURAJA MEMORIAL HS - THENI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18635",
            "district": "27",
            "Block": "319",
            "label": "NADAR SARASWATHI HSS - THENI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18636",
            "district": "27",
            "Block": "319",
            "label": "N.S. GIRLS HSS - THENI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18637",
            "district": "27",
            "Block": "319",
            "label": "P.C. GIRLS HSS - THENI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18638",
            "district": "27",
            "Block": "319",
            "label": "THE LITTLE KINGDOM SENIOR SCHOOL - VEDAPURI ARANMANAIPUTHUR THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21288",
            "district": "27",
            "Block": "319",
            "label": "AANDAL ALAGAR MATRIC HR. SEC. SCHOOL - VAYALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21338",
            "district": "27",
            "Block": "319",
            "label": "THENI KAMMAVAR SANGAM PUBLIC SCHOOL - KODUVILARPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21745",
            "district": "27",
            "Block": "319",
            "label": "MARY MATHA CMI PUBLIC SCHOOL - THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21746",
            "district": "27",
            "Block": "319",
            "label": "T.M.H.N.U.VIDYALAYA MATRIC HSS - THENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22960",
            "district": "27",
            "Block": "319",
            "label": "SANTINIKETAN PUBLIC SCHOOL - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23570",
            "district": "27",
            "Block": "319",
            "label": "P.C.PATTI HINDU NADAR URAVINMURAI R.S. MATRICULATION SCHOOL - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23570",
            "district": "27",
            "Block": "319",
            "label": "P.C.PATTI HINDU NADAR URAVINMURAI R.S. MATRICULATION SCHOOL - MUTHUTHEVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "320": [
        {
            "key": "1088",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MIDDLE SCHOOL VALLIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1137",
            "district": "28",
            "Block": "320",
            "label": "PUMS KOMBUTHURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1215",
            "district": "28",
            "Block": "320",
            "label": "SRI RAMAIAH BHAGAVATHER MEMORIAL SENTHIL MURUGAN MIDDLE SCHOOL, TIRUCHENDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1292",
            "district": "28",
            "Block": "320",
            "label": "ST.MARY'S MIDDLE SCHOOL, ADAIKALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1588",
            "district": "28",
            "Block": "320",
            "label": "PUMS KANTHANKUDIERUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1656",
            "district": "28",
            "Block": "320",
            "label": "SRI SARAVANA IYER MS, TIRUCHINDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1683",
            "district": "28",
            "Block": "320",
            "label": "CARMEL MIDDLE SCHOOL, ALANTHALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2189",
            "district": "28",
            "Block": "320",
            "label": "PUMS SELVARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2209",
            "district": "28",
            "Block": "320",
            "label": "ST JOSEPH'S CONVENT MATRIC HR.SEC.SCHOOL, VIRAPANDIANPATNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2295",
            "district": "28",
            "Block": "320",
            "label": "CENTRAL MATRIC HR SEC SCHOOL, KAYALPATNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2327",
            "district": "28",
            "Block": "320",
            "label": "STARS' MODEL MATRICULATION HIGHER SECONDARY SCHOOL, KUMARAPURAM - TIRUCHENDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2415",
            "district": "28",
            "Block": "320",
            "label": "AROCKIA ANNAI MIDDLE SCHOOL, ADAIKALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2536",
            "district": "28",
            "Block": "320",
            "label": "ANNAMMAL MATRICULATION SCHOOL ARUMUGANERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2684",
            "district": "28",
            "Block": "320",
            "label": "ST JOHN DE-BRITTO ANGLO INDIAN MATRIC SCHOOL VIRA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3291",
            "district": "28",
            "Block": "320",
            "label": "KANCHI SRI SANKARA ACADEMY MHSS, WEST TIRUCHENDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3678",
            "district": "28",
            "Block": "320",
            "label": "SENTHIL KUMARAN  MATRIC HR.SEC. SCHOOL,TIRUCHENDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4275",
            "district": "28",
            "Block": "320",
            "label": "MUHYIDDEEN MATRIC HR.SEC.SCHOOL KAYALPATNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4578",
            "district": "28",
            "Block": "320",
            "label": "SUDHANTHIRA VIDHYASALAI MIDDLE SCHOOL, MANAKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6437",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MIDDLE SCHOOL SONAGANVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19404",
            "district": "28",
            "Block": "320",
            "label": "ASA GOVT BOYS HSS, TIRUCHENDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19405",
            "district": "28",
            "Block": "320",
            "label": "R C HIGH SCHOOL, AMALINAGAR TIRUCHENDUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19406",
            "district": "28",
            "Block": "320",
            "label": "SM GHSS,TIRUCHENDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19407",
            "district": "28",
            "Block": "320",
            "label": "PUMS KEELAKANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19408",
            "district": "28",
            "Block": "320",
            "label": "CENTRAL HR.SEC.SCHOOL, KAYALPATANAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19409",
            "district": "28",
            "Block": "320",
            "label": "ZUBAIDA HSS, KAYALPATNAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19410",
            "district": "28",
            "Block": "320",
            "label": "GGHSS,KAYALPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19411",
            "district": "28",
            "Block": "320",
            "label": "PUMS ODAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19412",
            "district": "28",
            "Block": "320",
            "label": "L.K. MATRICULATION HIGHER SECONDARY SCHOOL, KAYALPATNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19413",
            "district": "28",
            "Block": "320",
            "label": "L.K. HR. SEC. SCHOOL, KAYALPATNAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19414",
            "district": "28",
            "Block": "320",
            "label": "PUMS THAIKKA ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19415",
            "district": "28",
            "Block": "320",
            "label": "KAMLAVATI SENIOR SECONDARY SCHOOL, SAHUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19416",
            "district": "28",
            "Block": "320",
            "label": "K A HR SEC SCHOOL,PEYANVILAI ARUMUGANERI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19417",
            "district": "28",
            "Block": "320",
            "label": "ARULNERI HINDU HS THIRUMALAIYAPPAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19418",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MS, RAJAMANIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19419",
            "district": "28",
            "Block": "320",
            "label": "GGHSS,ARUMUGANERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19420",
            "district": "28",
            "Block": "320",
            "label": "SSMID.S, MADATHUVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19421",
            "district": "28",
            "Block": "320",
            "label": "SARASWATHI MS, ARUMUGANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19422",
            "district": "28",
            "Block": "320",
            "label": "HIN. HS, ARUMUGANERI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19423",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MS, VEERAMANICKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19424",
            "district": "28",
            "Block": "320",
            "label": "ST.JOSEPH HSS, ADAIKALAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19425",
            "district": "28",
            "Block": "320",
            "label": "ST. THOMAS HSS, VIRAPANDIYANPATNAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19426",
            "district": "28",
            "Block": "320",
            "label": "HINDU HS, POOCHIKADU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19427",
            "district": "28",
            "Block": "320",
            "label": "TNDTA MIDDLE SCHOOL  JERUSELAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19428",
            "district": "28",
            "Block": "320",
            "label": "PERUNTHALAIVAR KAMARAJAR GOVERNMENT HIGHER SECONDARY SCHOOL,  THERIKUDIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19429",
            "district": "28",
            "Block": "320",
            "label": "GHSS, KAYAMOZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19430",
            "district": "28",
            "Block": "320",
            "label": "PUMS KEELANADUNALUMOOLAIKINARU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19431",
            "district": "28",
            "Block": "320",
            "label": "GOVT HS, NADUNALUMOOLAIKINARU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19432",
            "district": "28",
            "Block": "320",
            "label": "PUMS N.MUTHAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19433",
            "district": "28",
            "Block": "320",
            "label": "ST ANTONYS RC MIDDLE SCHOOL PUTHUKINATHANVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19434",
            "district": "28",
            "Block": "320",
            "label": "TDTA MARY SOUNDARAPANDIAN HIGH SCHOOL, PITCHIVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20998",
            "district": "28",
            "Block": "320",
            "label": "PEARL PUBLIC SCHOOL, ARUMUGANERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21541",
            "district": "28",
            "Block": "320",
            "label": "THIRUKKUDUMBAM   HIGH SCHOOL , ALANTHALAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21917",
            "district": "28",
            "Block": "320",
            "label": "WISDOM PUBLIC SCHOOL, VEERAPANDIAPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22983",
            "district": "28",
            "Block": "320",
            "label": "KAMLAVATI HIGHER SECONDARY SCHOOL,SAHUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23110",
            "district": "28",
            "Block": "320",
            "label": "ST JOSEPHS SCHOOL VEERAPANDIYANPATNAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23576",
            "district": "28",
            "Block": "320",
            "label": "KANCHI SANKARA VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23576",
            "district": "28",
            "Block": "320",
            "label": "KANCHI SANKARA VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "321": [
        {
            "key": "18817",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL KATHALAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18818",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL MUTHURAMALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18819",
            "district": "38",
            "Block": "321",
            "label": "SBK HIGHER SECONDARY SCHOOL KALLOORANI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18820",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL, SAVVASUPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18821",
            "district": "38",
            "Block": "321",
            "label": "PUMS MELAKANDAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18822",
            "district": "38",
            "Block": "321",
            "label": "PUMS MADATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18823",
            "district": "38",
            "Block": "321",
            "label": "GANDHI VIDYALAYAM HSS KULASEKARANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18824",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL KULASEKARANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18825",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL MARAVARPERUNGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18826",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL REDDIAPATTI M - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18827",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL THUMMUCHINNAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18828",
            "district": "38",
            "Block": "321",
            "label": "PUMS POTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18829",
            "district": "38",
            "Block": "321",
            "label": "NMSSV MIDDLE SCHOOL THOPPALAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18830",
            "district": "38",
            "Block": "321",
            "label": "PUMS RAJAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18831",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL K CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18832",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL NALLANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18833",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL PARALACHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18834",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL VADAKKUNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18835",
            "district": "38",
            "Block": "321",
            "label": "PUMS SENKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18836",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL POOLANGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18837",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL KEELKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18838",
            "district": "38",
            "Block": "321",
            "label": "PUMS ILLUPPAIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18839",
            "district": "38",
            "Block": "321",
            "label": "PUMS K VELAYUTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18840",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL VIDATHAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18841",
            "district": "38",
            "Block": "321",
            "label": "PUMS ERRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18842",
            "district": "38",
            "Block": "321",
            "label": "PUMS PUDUPATTI P M",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18843",
            "district": "38",
            "Block": "321",
            "label": "PUMS PULIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18844",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL P THOTTIYANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18845",
            "district": "38",
            "Block": "321",
            "label": "T.U.N.S.V.HSS,THIRUCHULI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18846",
            "district": "38",
            "Block": "321",
            "label": "SETHUPATHI GOVT HIGHER SECONDARY SCHOOL TIRUCHULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18847",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGHER SECONDARY SCHOOL TAMILPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18848",
            "district": "38",
            "Block": "321",
            "label": "GOVT HIGH SCHOOL UDAIYANAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18849",
            "district": "38",
            "Block": "321",
            "label": "PUMS KARENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21110",
            "district": "38",
            "Block": "321",
            "label": "MINERVA PUBLIC SCHOOL, MANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21111",
            "district": "38",
            "Block": "321",
            "label": "SRI VISHWAS VIDHYALAYA SCHOOL, PANNAI MOONDRADAIPPU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22265",
            "district": "38",
            "Block": "321",
            "label": "SARADHA VIDYALAYA MATRICULATION SCHOOL,  M.REDDIYAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22902",
            "district": "38",
            "Block": "321",
            "label": "GOVERNMENT HIGH SCHOOL, CHITHALAKUNDU",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "322": [
        {
            "key": "4244",
            "district": "2",
            "Block": "322",
            "label": "ST MARY'S MATRIC HR.SEC SCHOOL, Mamallapuram-603104",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4382",
            "district": "2",
            "Block": "322",
            "label": "BLESSINGS MATRIC HR.SEC.SCHOOL KEERAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5394",
            "district": "2",
            "Block": "322",
            "label": "SEVENTH DAY ADVENTIST MHSS PUDUPATTINAM, KALPAKKAM-603 102",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5445",
            "district": "2",
            "Block": "322",
            "label": "INFANT JESUS MHSS, PUDUPATTINAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7973",
            "district": "2",
            "Block": "322",
            "label": "PUMS-PUDUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7974",
            "district": "2",
            "Block": "322",
            "label": "HAWISCHAR HAPPY HIGH SCHOOL, KEELAVEEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7975",
            "district": "2",
            "Block": "322",
            "label": "CGBVMS-NENMELI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7976",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KEERAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7977",
            "district": "2",
            "Block": "322",
            "label": "GHS SOGANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7978",
            "district": "2",
            "Block": "322",
            "label": "SADHGURU GLOBAL MATRIC HSS, SOGANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7979",
            "district": "2",
            "Block": "322",
            "label": "PP GHSS PON VILAINTHA KALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7980",
            "district": "2",
            "Block": "322",
            "label": "PUMS-PONPATHARKOODAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7981",
            "district": "2",
            "Block": "322",
            "label": "PUMS-ANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7982",
            "district": "2",
            "Block": "322",
            "label": "GOVT HR SEC SCHOOL PANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7983",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KILAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7984",
            "district": "2",
            "Block": "322",
            "label": "GVMS-MUDAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7985",
            "district": "2",
            "Block": "322",
            "label": "GHSS ( BOYS ), THIRUKALUKUNDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7986",
            "district": "2",
            "Block": "322",
            "label": "GHS KUZHIPANTHANDALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7987",
            "district": "2",
            "Block": "322",
            "label": "PUMS-VADAKADAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7988",
            "district": "2",
            "Block": "322",
            "label": "ANNAI VELLAN KANNI MATRICULATION SCHOOL PERUMALERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7989",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KOKKILAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7990",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KADAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7991",
            "district": "2",
            "Block": "322",
            "label": "PUMS-PATTIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7992",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KUNNAVAKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7993",
            "district": "2",
            "Block": "322",
            "label": "GHS VEERAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7994",
            "district": "2",
            "Block": "322",
            "label": "PUMS-MULLIKOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7995",
            "district": "2",
            "Block": "322",
            "label": "GHS NATHAMKARIYACHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7996",
            "district": "2",
            "Block": "322",
            "label": "ADWHSS MANAMAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7997",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7998",
            "district": "2",
            "Block": "322",
            "label": "ATOMIC ENERGY CENTRAL SCHOOL, ANUPURAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7999",
            "district": "2",
            "Block": "322",
            "label": "GHSS VENGAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8000",
            "district": "2",
            "Block": "322",
            "label": "PUMS-SADRAS",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8001",
            "district": "2",
            "Block": "322",
            "label": "GHSS SADHURANGAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8002",
            "district": "2",
            "Block": "322",
            "label": "KENDRIYA VIDYALAYA NO.2, EAST SADRAS, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8003",
            "district": "2",
            "Block": "322",
            "label": "ATOMIC ENERGY CENTRAL SCHOOL -NO. 2 ,EAST SADRAS, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "8004",
            "district": "2",
            "Block": "322",
            "label": "PUMS-VITTILAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8005",
            "district": "2",
            "Block": "322",
            "label": "GHS-SOORADIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8006",
            "district": "2",
            "Block": "322",
            "label": "ADWHSS NERUMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8007",
            "district": "2",
            "Block": "322",
            "label": "PUMS-IRUMBULICHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8008",
            "district": "2",
            "Block": "322",
            "label": "PUMS-BOMMARASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8009",
            "district": "2",
            "Block": "322",
            "label": "PUMS-AYAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8010",
            "district": "2",
            "Block": "322",
            "label": "PUMS-UYYALIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8011",
            "district": "2",
            "Block": "322",
            "label": "GHSS VAYALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8012",
            "district": "2",
            "Block": "322",
            "label": "ADWMS-PS NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8013",
            "district": "2",
            "Block": "322",
            "label": "ANNAMALAI MS-DESUMUGIPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8014",
            "district": "2",
            "Block": "322",
            "label": "PUMS-NALVARPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8015",
            "district": "2",
            "Block": "322",
            "label": "PUMS-AMMANAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8016",
            "district": "2",
            "Block": "322",
            "label": "PUMS-KARUMARAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8017",
            "district": "2",
            "Block": "322",
            "label": "V S MHSS Thirukalukundram",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8018",
            "district": "2",
            "Block": "322",
            "label": "Aruna MHSS,Thirukazhukundram-603109",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8019",
            "district": "2",
            "Block": "322",
            "label": "PTVS HS THIRUKAZHUKUNDRAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8020",
            "district": "2",
            "Block": "322",
            "label": "PUMS-THIRUKAZHUKUNDRAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8021",
            "district": "2",
            "Block": "322",
            "label": "GGHSS THIRUKAZHUKUNDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8022",
            "district": "2",
            "Block": "322",
            "label": "VS MATRICULATION SCHOOL MAMALLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8023",
            "district": "2",
            "Block": "322",
            "label": "PUMS-MAMALLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8024",
            "district": "2",
            "Block": "322",
            "label": "GHSS MAMALLAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8025",
            "district": "2",
            "Block": "322",
            "label": "ATOMIC ENERGY CENTRAL SCHOOL -NO.1, PUDUPATTINAM, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "8026",
            "district": "2",
            "Block": "322",
            "label": "GHSS PUDHUPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8027",
            "district": "2",
            "Block": "322",
            "label": "KENDRIYA VIDYALAYA NO.1, PUDUPATTINAM, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20880",
            "district": "2",
            "Block": "322",
            "label": "SREE GOKULAM PUBLIC SCHOOL, NENMELI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20900",
            "district": "2",
            "Block": "322",
            "label": "SRS VIDYA MANDIR , VADAKADAMBADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20929",
            "district": "2",
            "Block": "322",
            "label": "V S STARS PUBLIC SCHOOL THIRUKAZHUKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20944",
            "district": "2",
            "Block": "322",
            "label": "JEEVA MEMORIAL PUBLIC  CBSE SENIOR  SECONDARY SCHOOL , PALLAEGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21051",
            "district": "2",
            "Block": "322",
            "label": "BLESSINGS INTERNATIONAL SCHOOL , KEERAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21238",
            "district": "2",
            "Block": "322",
            "label": "PATHASHAALA PALAR CENTRE FOR LEARNING KFI, VALLIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21428",
            "district": "2",
            "Block": "322",
            "label": "GHS SALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21582",
            "district": "2",
            "Block": "322",
            "label": "Raniammal Matric School, Narasankuppam 603109",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21885",
            "district": "2",
            "Block": "322",
            "label": "SHINING STAR MATRICULATION SCHOOL, VASUVASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21886",
            "district": "2",
            "Block": "322",
            "label": "Excellent NPS, Pudhupattinam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21948",
            "district": "2",
            "Block": "322",
            "label": "ATOMIC ENERGY HIGHER SECONDARY SCHOOL, EAST SADRAS, KALPAKKAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22357",
            "district": "2",
            "Block": "322",
            "label": "ST MARY'S ACADEMY , MAMALLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22896",
            "district": "2",
            "Block": "322",
            "label": "GHS VALLIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23103",
            "district": "2",
            "Block": "322",
            "label": "GURU SHREE SHANTIVIJAI JAIN GURUKUL  NALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24002",
            "district": "2",
            "Block": "322",
            "label": "SELVAGAM SANTHANALAKSHMI NOBLE MATRICULATION SCHOOL, THIRUKKAZHUKUNDRAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24003",
            "district": "2",
            "Block": "322",
            "label": "MAAMALLAN SCHOOL POONCHERI",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "323": [
        {
            "key": "4872",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ARUTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5201",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOZHINDRAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5243",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAZHUMALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5627",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VADAMARUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5784",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NORTH STREET TIRUKOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5879",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL  POOMARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6098",
            "district": "9",
            "Block": "323",
            "label": "VASAVI AIDED  MIDDLE SCHOOL  SOUTH STREET TIRUKOILUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6127",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KADIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6250",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAZHANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6285",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KODIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6294",
            "district": "9",
            "Block": "323",
            "label": "AIDED MIDDLE SCHOOL  MEMALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10468",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VILANDAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10469",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KULADEEPAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10470",
            "district": "9",
            "Block": "323",
            "label": "St.AROCKIA ANNAI HIGH SCHOOL T.ATHIPAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10502",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAKKUNEMILI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10503",
            "district": "9",
            "Block": "323",
            "label": "SRI BARATHI MATRIC  SCHOOL  VADAKKUNEMILI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10504",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ATHANDAMARUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10505",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  MUDHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10506",
            "district": "9",
            "Block": "323",
            "label": "SRI RAMAKRISHNA MATRICULATION SCHOOL AVI KOLAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10507",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  KATTUPAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10508",
            "district": "9",
            "Block": "323",
            "label": "AIDED MIDDLE SCHOOL  ELRAMPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10509",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VADAMALAIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10510",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  THIRUPALA PANDHAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10511",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THURINJIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10512",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  PONNIYANDHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10513",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOMALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10514",
            "district": "9",
            "Block": "323",
            "label": "SRI MARUTHI VIDHYASHRAM MATRIC HIGH SCHOOL  KOMALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10515",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  CHOLAVANDIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10516",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL THAGADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10517",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  KOOVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10518",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL THIMMACHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10519",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PADIYANDHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10520",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOLAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10521",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NEDUMUDAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10522",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MADAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10523",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOLL  EDAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10524",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  AVI.PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10525",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELATHALANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10526",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANAGANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10527",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIVANARTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10528",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  KEEZHATHAZHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10529",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  G.ARIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10530",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  G.ARIYUR (MODEL)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10531",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  AMMANKOLLAIMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10532",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KACHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10533",
            "district": "9",
            "Block": "323",
            "label": "KASTUBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL TIRUPALAPANDHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10534",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT HIGH SCHOOL  VENGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10535",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10536",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THULAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10537",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MOHALAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10538",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVIYAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10539",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SANGIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10540",
            "district": "9",
            "Block": "323",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PILLAIYAR PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10541",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL TIRUKOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10542",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL TIRUKOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10543",
            "district": "9",
            "Block": "323",
            "label": "SRI.GNAN.MATRIC.HIGHER SECONDARY SCHOOL  TIRUKOILUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10544",
            "district": "9",
            "Block": "323",
            "label": "UNAIDED MIDDLE SCHOOL (BOARDING WELFARE) TIRUKOVILUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10545",
            "district": "9",
            "Block": "323",
            "label": "DANISH MISSION (GIRLS) BOARDING HIGHER SECONDARY SCHOOL  SILOAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10546",
            "district": "9",
            "Block": "323",
            "label": "DANISH  MISSION  MIDDLE SCHOOL  KEELAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10547",
            "district": "9",
            "Block": "323",
            "label": "DANISH MISSION  HIGH SCHOOL  SANDHAPET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10548",
            "district": "9",
            "Block": "323",
            "label": "SRI VIDYA MANDIR SENIOR SECONDARY SCHOOL TIRUKOILUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10549",
            "district": "9",
            "Block": "323",
            "label": "SRI SAI VIDHYALAYA MATRICULATION SCHOOL SANDAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21063",
            "district": "9",
            "Block": "323",
            "label": "SRI SIKSHA KENDRA INTERNATIONAL SCHOOL KUCHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22098",
            "district": "9",
            "Block": "323",
            "label": "NEW VIDYA  MANDIR MATRICLULATION SCHOOL KULADEEPAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22099",
            "district": "9",
            "Block": "323",
            "label": "ST. ANTONY MATRICULATION SCHOOL T.ATHIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22876",
            "district": "9",
            "Block": "323",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  G.ARIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23125",
            "district": "9",
            "Block": "323",
            "label": "SRI SARADHA VIDHYASHRAM ,T.KEERANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "324": [
        {
            "key": "1364",
            "district": "14",
            "Block": "324",
            "label": "ST FRANCIS MATRIC HR SEC SCHOOL, THIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3091",
            "district": "14",
            "Block": "324",
            "label": "KAZHUVATHEVAR MEMORIAL MATRIC HR SEC SCH, K.P.OTHAPPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3271",
            "district": "14",
            "Block": "324",
            "label": "PKN MATRIC HR SEC SCHOOL, TIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3927",
            "district": "14",
            "Block": "324",
            "label": "SARAVANA'S VIDHYA MANDIR MATRIC HR SEC SCHOOL, KANGEYANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5796",
            "district": "14",
            "Block": "324",
            "label": "ARIMA NAMALVAR MATRICLUATION SCHOOL, THIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6004",
            "district": "14",
            "Block": "324",
            "label": "LINGA MATRIC HIGH SCHOOL, TIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18176",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S. CHEKKANURANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18177",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HR SEC SCHOOL, CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18178",
            "district": "14",
            "Block": "324",
            "label": "KEREN MATRICULATION HIGHER SECONDARY SCHOOL CHECKANURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18179",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S MAVILIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18180",
            "district": "14",
            "Block": "324",
            "label": "GOVT HIGH SCHOOL, K.MEENAKSHIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18181",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HIGH SCHOOL, KARADIKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18182",
            "district": "14",
            "Block": "324",
            "label": "SRI MEENAKSHI GIRLS HR SEC SCHOOL, KARADIKKAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18183",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S.   UCHAPPATI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18184",
            "district": "14",
            "Block": "324",
            "label": "THIAGARAJAR MILLS HR SEC SCHOOL, KAPPALUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18185",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HR SEC SCHOOL, KAPPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18186",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HR SEC SCHOOL, MELAURAPPANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18187",
            "district": "14",
            "Block": "324",
            "label": "JOTHI DHARMAN ASSISI HR SEC SCHOOL, SATHANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18188",
            "district": "14",
            "Block": "324",
            "label": "KSHATRIYA NADARS HIGH SCHOOL, SATHANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "23703",
            "district": "14",
            "Block": "324",
            "label": "Aarushi Global School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "18189",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S KANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18190",
            "district": "14",
            "Block": "324",
            "label": "GOVT HR SEC SCHOOL, VAGAIKULAM P",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18191",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S GANGEIYANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18192",
            "district": "14",
            "Block": "324",
            "label": "GOVT MODEL HR SEC SCHOOL, P.AMMAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18193",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S KILAVANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18194",
            "district": "14",
            "Block": "324",
            "label": "GOVT HR SEC SCHOOL, ACHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18195",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S. KATRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18196",
            "district": "14",
            "Block": "324",
            "label": "R.N.M.S. T.PUDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18197",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S .S.MEENAKSHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18198",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S S.VALAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18199",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S. CHITHIREDDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18200",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S THANGALACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18201",
            "district": "14",
            "Block": "324",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  RAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18202",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S.ALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18203",
            "district": "14",
            "Block": "324",
            "label": "TEDDY HIGHER SECONDARY SCHOOL, ALAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18204",
            "district": "14",
            "Block": "324",
            "label": "GOVT HR SEC SCHOOL, MELAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18205",
            "district": "14",
            "Block": "324",
            "label": "FRANCOIS MEYER MATRIC SCHOOL, ALAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18206",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S. KARISALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18207",
            "district": "14",
            "Block": "324",
            "label": "MEPCO SCHLENK MATRIC HR SEC SCHOOL,  MARAVANKULAM, THIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18208",
            "district": "14",
            "Block": "324",
            "label": "GOVT HIGH SCHOOL, VIDATHAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18209",
            "district": "14",
            "Block": "324",
            "label": "P.U.M.S MAIKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18210",
            "district": "14",
            "Block": "324",
            "label": "GOVT HR SEC SCHOOL. THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18211",
            "district": "14",
            "Block": "324",
            "label": "PKN HR SEC SCHOOL, THIRUMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18212",
            "district": "14",
            "Block": "324",
            "label": "AL AMEEN MUSLIM HR SEC SCHOOL, THIRUMALGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18213",
            "district": "14",
            "Block": "324",
            "label": "PKN GIRLS HR SEC SCHOOL THIRUMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18214",
            "district": "14",
            "Block": "324",
            "label": "GOVT GIRLS HR SEC SCHOOL, THIRUMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18215",
            "district": "14",
            "Block": "324",
            "label": "C.S.I.HIGH.SCHOOL, THIRUMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20919",
            "district": "14",
            "Block": "324",
            "label": "P.K.N VIDYALAYA CBSE SCHOOL, THIRUMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21487",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR HIGH SCHOOL, KINNIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21751",
            "district": "14",
            "Block": "324",
            "label": "KEREN PUBLIC SCHOOL, CHEKKANURANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21956",
            "district": "14",
            "Block": "324",
            "label": "GOVT KALLAR GIRLS HR SEC SCHOOL, CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22607",
            "district": "14",
            "Block": "324",
            "label": "ST.JOSEPH MATRICULATION SCHOOL, THIRALI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23035",
            "district": "14",
            "Block": "324",
            "label": "GOVT. KALLAR HIGH SCHOOL, K.PULIYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23313",
            "district": "14",
            "Block": "324",
            "label": "NRM DREAM INTERNATIONAL SCHOOL, SATHANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23510",
            "district": "14",
            "Block": "324",
            "label": "GOVT.KALLAR GIRLS RESIDENTIAL SCHOOL., CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23511",
            "district": "14",
            "Block": "324",
            "label": "GOVT.KALLAR BOYS RESIDENTIAL SCHOOL., CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23510",
            "district": "14",
            "Block": "324",
            "label": "GOVT.KALLAR GIRLS RESIDENTIAL SCHOOL., CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23511",
            "district": "14",
            "Block": "324",
            "label": "GOVT.KALLAR BOYS RESIDENTIAL SCHOOL., CHECKANURANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        }
    ],
    "325": [
        {
            "key": "4702",
            "district": "16",
            "Block": "325",
            "label": "PUMS-THIRUPPAYATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4706",
            "district": "16",
            "Block": "325",
            "label": "PUMS-KANGALANCHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4894",
            "district": "16",
            "Block": "325",
            "label": "PUMS-VADAKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5291",
            "district": "16",
            "Block": "325",
            "label": "SRI NADESAIYHA GOVT AMS SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5499",
            "district": "16",
            "Block": "325",
            "label": "SMT CRESCENT MATRICULATION SCHOOL AUDALAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5734",
            "district": "16",
            "Block": "325",
            "label": "PUMS PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5769",
            "district": "16",
            "Block": "325",
            "label": "PUMS KOTTARAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5801",
            "district": "16",
            "Block": "325",
            "label": "PUMS-THIRUCHENKATTANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5880",
            "district": "16",
            "Block": "325",
            "label": "PUMS PAKKAMKOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5950",
            "district": "16",
            "Block": "325",
            "label": "GMS VAZHMANGALAM,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6292",
            "district": "16",
            "Block": "325",
            "label": "PUMS ATHALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6307",
            "district": "16",
            "Block": "325",
            "label": "PUMS - PANDARAVADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6508",
            "district": "16",
            "Block": "325",
            "label": "PUMS KUTHALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6717",
            "district": "16",
            "Block": "325",
            "label": "PUMS VOWALADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15701",
            "district": "16",
            "Block": "325",
            "label": "GHS GANAPATHIPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15702",
            "district": "16",
            "Block": "325",
            "label": "GHSS THITTACHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15703",
            "district": "16",
            "Block": "325",
            "label": "GHS THITTACHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15704",
            "district": "16",
            "Block": "325",
            "label": "GHSS PURAGRAMAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15705",
            "district": "16",
            "Block": "325",
            "label": "GHSS THIRUMARUGAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15706",
            "district": "16",
            "Block": "325",
            "label": "GHS KEEZHAPOLAGAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15707",
            "district": "16",
            "Block": "325",
            "label": "GHS MARUNGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15708",
            "district": "16",
            "Block": "325",
            "label": "AL-AMAN CRESCENT MATRIC HR SEC SCHOOL ATHALAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15709",
            "district": "16",
            "Block": "325",
            "label": "AKR MATRIC HR.SEC.SCHOOL ENANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15710",
            "district": "16",
            "Block": "325",
            "label": "GHSS ENANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15711",
            "district": "16",
            "Block": "325",
            "label": "GHSS THIRUKKANNAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15712",
            "district": "16",
            "Block": "325",
            "label": "GHSS AMBAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15713",
            "district": "16",
            "Block": "325",
            "label": "PUMS PORAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15714",
            "district": "16",
            "Block": "325",
            "label": "ST.JOSEPH AMS SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15715",
            "district": "16",
            "Block": "325",
            "label": "GHS VAZHGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15716",
            "district": "16",
            "Block": "325",
            "label": "ST.ANNES AMS SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15717",
            "district": "16",
            "Block": "325",
            "label": "GHS NARIMANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21389",
            "district": "16",
            "Block": "325",
            "label": "GHS PANANGUDI SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23047",
            "district": "16",
            "Block": "325",
            "label": "NALLANDA MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24004",
            "district": "16",
            "Block": "325",
            "label": "GHS GANAPATHIPURAM",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "326": [
        {
            "key": "905",
            "district": "19",
            "Block": "326",
            "label": "M.R.P MATRIC SCHOOL, P.ALAGAPURI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1245",
            "district": "19",
            "Block": "326",
            "label": "SRI LAKSHMI NARAYANA MATRIC SCHOOL, KURUMBAPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1270",
            "district": "19",
            "Block": "326",
            "label": "ST.JOSEPH MATRIC SCHOOL, VIRACHILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1520",
            "district": "19",
            "Block": "326",
            "label": "ST.JOSEPH MATRIC SCHOOL, THIRUMAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2120",
            "district": "19",
            "Block": "326",
            "label": "ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, N.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5132",
            "district": "19",
            "Block": "326",
            "label": "KING JESUS MATRIC SCHOOL, THIRUMAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5588",
            "district": "19",
            "Block": "326",
            "label": "ST.SEBASTIAR MATRIC SCHOOL, KATTUBAVAPALLIVASAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17088",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,PILLAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17089",
            "district": "19",
            "Block": "326",
            "label": "G.G.H.S.S.P.ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17090",
            "district": "19",
            "Block": "326",
            "label": "PICHAIYAPPA MIDDLE SCHOOL UNAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17091",
            "district": "19",
            "Block": "326",
            "label": "SRI SIVAKAMI AMBAL HIGH SCHOOL, RANGIEM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17092",
            "district": "19",
            "Block": "326",
            "label": "SRI.PR.M.MEYYAPPA CHETTIYAR MIDDLE SCHOOL ,VIRACHILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17093",
            "district": "19",
            "Block": "326",
            "label": "VALLUVAR MIDDLE SCHOOL, KULIPIRAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17094",
            "district": "19",
            "Block": "326",
            "label": "M.CT.RM HIGHER SEC.SCHOOL, KULIPIRAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17095",
            "district": "19",
            "Block": "326",
            "label": "CHRISTURAJA HIGH SCHOOL, IRUTHAYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17096",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MALAIKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17097",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALAIYANVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17098",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KULATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17099",
            "district": "19",
            "Block": "326",
            "label": "GOVTHIGH SCHOOL,THULAIYANUR.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17100",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17101",
            "district": "19",
            "Block": "326",
            "label": "GHSS LEMBALAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17102",
            "district": "19",
            "Block": "326",
            "label": "SRI SARASWATHI HIGHER SECONDARY SCHOOL, KONAPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17103",
            "district": "19",
            "Block": "326",
            "label": "GOVT.GIRLS.HR.SEC.SCHOOL, THIRUMAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17104",
            "district": "19",
            "Block": "326",
            "label": "GOVT.HR.SEC.SCHOOL, THIRUMAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17105",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL S.NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17106",
            "district": "19",
            "Block": "326",
            "label": "R.C. MIDDLE SCHOOL, NEIVASAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "17107",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17108",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VENGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17109",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THERKUPUDUVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17110",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17111",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17112",
            "district": "19",
            "Block": "326",
            "label": "RAMANATHAN CHETTIAR HR.SEC.SCHOOL, NACHANDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17113",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTUBAVA PALLIVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17114",
            "district": "19",
            "Block": "326",
            "label": "SM.MAR.ALAMELARUNACHALAM HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17115",
            "district": "19",
            "Block": "326",
            "label": "SCM, MIDDLE SCHOOL, KURUVIKONDANAPTTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17116",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SETHURAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17117",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, V.LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17118",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PILAKKUDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20994",
            "district": "19",
            "Block": "326",
            "label": "MOUNTOLIVE MATRIC HR.SEC.SCHOOL,MOONGITHAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21331",
            "district": "19",
            "Block": "326",
            "label": "MOUNTZION.INT.LENA VILAKKU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24005",
            "district": "19",
            "Block": "326",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PULIVALAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "327": [
        {
            "key": "6271",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AYANVELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6525",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DEVIYANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10890",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10891",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PACHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10892",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KILAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10893",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KALAMARUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10894",
            "district": "9",
            "Block": "327",
            "label": "ARUNA HIGHER SECONDARY SCHOOL KORATAN KURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10895",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PILLAIYARKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10896",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGH SCHOOL VELUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10897",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUNARANKUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10898",
            "district": "9",
            "Block": "327",
            "label": "AIDED MIDDLE SCHOOL MADAPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10900",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGH SCHOOL IRUNDAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10901",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KORATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10902",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOOVAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10903",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10904",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T.ORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10905",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PADUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10906",
            "district": "9",
            "Block": "327",
            "label": "VEDANTHA VID MATRICULATION SCHOOL PADUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10907",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERIYAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10908",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIMMIREDDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10909",
            "district": "9",
            "Block": "327",
            "label": "DON BOSCO HIGHER SECONDARY SCHOOL GEDILAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10910",
            "district": "9",
            "Block": "327",
            "label": "S S V HIGHER SECONDARY SCHOOL SENTHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10911",
            "district": "9",
            "Block": "327",
            "label": "DANISH MISSION HIGH SCHOOL PARIKKAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10912",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SEMMANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10913",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THIRUNAVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10914",
            "district": "9",
            "Block": "327",
            "label": "KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL THIRUNAVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10915",
            "district": "9",
            "Block": "327",
            "label": "ISHA VIDYA MATRICULATION HIGHER SECONDARY SCHOOL TIRUNAVALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10916",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ESWARAKANDANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10917",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SOMASIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10918",
            "district": "9",
            "Block": "327",
            "label": "AIDED MIDDLE SCHOOL EAST MARUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10919",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARINATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10920",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENGURICHI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10921",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENGURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10922",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NAINAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10923",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PU.MAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10924",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELUPPAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10925",
            "district": "9",
            "Block": "327",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KALLAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10926",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SENDANADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10927",
            "district": "9",
            "Block": "327",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL , SENTHANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21205",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL VELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21593",
            "district": "9",
            "Block": "327",
            "label": "J S CENTRAL SCHOOL MADAPPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21971",
            "district": "9",
            "Block": "327",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL KALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22106",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGH SCHOOL NANNARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22436",
            "district": "9",
            "Block": "327",
            "label": "GOVERNMENT HIGH SCHOOL PANDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "24006",
            "district": "9",
            "Block": "327",
            "label": "JUBILATION MATRICULATION SCHOOL KALAMARUDUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "328": [
        {
            "key": "891",
            "district": "14",
            "Block": "328",
            "label": "ST.MARY OF LEUCA MATRIC HR SEC SCHOOL, BASTIAN NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1598",
            "district": "14",
            "Block": "328",
            "label": "YADAVA MATRIC HR SEC SCHOOL, SUBRAMANIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1789",
            "district": "14",
            "Block": "328",
            "label": "SBOA MATRIC HR SEC SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1814",
            "district": "14",
            "Block": "328",
            "label": "DOLPHIN MATRIC HR SEC SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1861",
            "district": "14",
            "Block": "328",
            "label": "K.N.U NACHIAPPANADAR GURUVAMMAL MATRIC HIGH SCHOOL, VILLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2020",
            "district": "14",
            "Block": "328",
            "label": "B.M.S VIDHYALAYAM MATRIC HIGHER SECONDARY SCHOOL, AVANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2245",
            "district": "14",
            "Block": "328",
            "label": "GRACE MATRIC HR SEC SCHOOL, JAIHINDPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2290",
            "district": "14",
            "Block": "328",
            "label": "ROYAL PUBLIC MATRIC HIGH  SCHOOL, BETHANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2442",
            "district": "14",
            "Block": "328",
            "label": "GOOD LUCK MATRIC HIGH SCHOOL, VILLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2523",
            "district": "14",
            "Block": "328",
            "label": "ST.JOHN PETER MATRIC HIGH SCHOOL, JAIHINDPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2538",
            "district": "14",
            "Block": "328",
            "label": "SETHURAJAN PADMA MATRIC HR SEC SCHOOL, JAINHINDPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2541",
            "district": "14",
            "Block": "328",
            "label": "ST.CHARLES MATRIC HR SEC SCHOOL, THIRUVALLUVAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2716",
            "district": "14",
            "Block": "328",
            "label": "M.T.PKN MATRIC HIGHER SECONDARY  SCHOOL, PASUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2791",
            "district": "14",
            "Block": "328",
            "label": "ANUSHA VIDYALAYA MATRIC HIGH SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2856",
            "district": "14",
            "Block": "328",
            "label": "AMUDHAM MATRIC HR SEC SCHOOL, PERUNGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3385",
            "district": "14",
            "Block": "328",
            "label": "AMRITA VIDYALAYAM MATRICULATION SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3407",
            "district": "14",
            "Block": "328",
            "label": "SENGUNDHAR MATRIC HIGH SCHOOL, CHINTHAMANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3449",
            "district": "14",
            "Block": "328",
            "label": "RAJAN MATRIC HR SEC SCHOOL, VILLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23674",
            "district": "14",
            "Block": "328",
            "label": "Harveypatti higher secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3511",
            "district": "14",
            "Block": "328",
            "label": "SEVENTH DAY ADVENTIST MATRIC HR SEC SCHOOL, Jeevanagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3521",
            "district": "14",
            "Block": "328",
            "label": "V.K.K PLAY GROUP MATRIC HR SEC SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3785",
            "district": "14",
            "Block": "328",
            "label": "LITTLE DIAMONDS MATRIC HIGH SCHOOL, VILLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3797",
            "district": "14",
            "Block": "328",
            "label": "AMALA MATRICULATION HIGHER SECONDARY SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3849",
            "district": "14",
            "Block": "328",
            "label": "KRISHNAMAL RAMASUBBAIYER SCHOOL, TVR NAGAR, MADURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3930",
            "district": "14",
            "Block": "328",
            "label": "HDI JAIN MATRIC HR SEC SCHOOL, PASUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4201",
            "district": "14",
            "Block": "328",
            "label": "INDIRA GANDHI MEMORIAL MATRIC HR SEC SCHOOL, TIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4336",
            "district": "14",
            "Block": "328",
            "label": "DEVASAKAYAM MATRIC HR SEC SCHOOL, PASUMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4667",
            "district": "14",
            "Block": "328",
            "label": "S.P.J MATRIC HR SEC SCHOOL, AVANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5117",
            "district": "14",
            "Block": "328",
            "label": "PRAJNA MATRIC HR SEC SCHOOL, THIRUPARANKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5165",
            "district": "14",
            "Block": "328",
            "label": "ZION GOOD SHEPHERDS MATRIC HR SEC SCHOOL, AVANIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5398",
            "district": "14",
            "Block": "328",
            "label": "ST.JOHN'S MATRIC HR SEC SCHOOL, KOCHADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18216",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, KODIMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18217",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, THUVARIMAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18218",
            "district": "14",
            "Block": "328",
            "label": "BLESSING MATRIC HR SEC SCHOOL, ATCHAMPATHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18219",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  VILACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18220",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S   NAGAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18221",
            "district": "14",
            "Block": "328",
            "label": "M N U JAYARAJ NADAR HR SEC SCHOOL  NAGAMALAIPUDUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18222",
            "district": "14",
            "Block": "328",
            "label": "SIRUMALAR GIRLS HR SEC SCHOOL, NAGAMALAI PUDUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18223",
            "district": "14",
            "Block": "328",
            "label": "BEN MATRIC HIGH SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18224",
            "district": "14",
            "Block": "328",
            "label": "M.N.U JAYARAJ ANNAPACKIAM MATRIC HR SEC SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18225",
            "district": "14",
            "Block": "328",
            "label": "AKSHARA MATRIC HR SEC SCHOOL, KARADIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18226",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, VADAPALANJI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18227",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  THENPALANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18228",
            "district": "14",
            "Block": "328",
            "label": "PALKALAI NAGAR MS PALKALAINAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18229",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S VELLAI PARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18230",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL,VEDARPULIYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18231",
            "district": "14",
            "Block": "328",
            "label": "ST.CHARLES GIRLS HR SEC SCHOOL, THIRUVALLUVAR NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18232",
            "district": "14",
            "Block": "328",
            "label": "GOVT KALLAR HIGH SCHOOL, THANAKKANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18233",
            "district": "14",
            "Block": "328",
            "label": "GOVT. KALLAR MIDDLE SCHOOL,  KEELAKUILKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18234",
            "district": "14",
            "Block": "328",
            "label": "GOVT.KALLAR MIDDLE SCHOOL, VADIVELKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18235",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, VIRAGANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18236",
            "district": "14",
            "Block": "328",
            "label": "LEONARD SPL FOR THE HEARING IM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18237",
            "district": "14",
            "Block": "328",
            "label": "VELAMMAL MATRIC HR SEC SCHOOL, VIRAGANOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18238",
            "district": "14",
            "Block": "328",
            "label": "GOVT HR SEC SCHOOL, S PULIANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18239",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  PANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18240",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  CHINTHAMANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18241",
            "district": "14",
            "Block": "328",
            "label": "VELAMMAL VIDYALAYA, ANUPPANADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18242",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, SAMANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18243",
            "district": "14",
            "Block": "328",
            "label": "IDHAYAM RAJENDRAN RESIDENTIAL SCHOOL, SAMANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18244",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  PERIYAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18245",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  PRASANNA COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18246",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, AYANPAPPAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18247",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  CHINNAUDAIPPU THIRUPPARANKUNRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18248",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, VALAYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18249",
            "district": "14",
            "Block": "328",
            "label": "ST.ANNES GIRLS HR SEC SCHOOL, PERUNGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18250",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, PERUNKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18251",
            "district": "14",
            "Block": "328",
            "label": "N.K.KUPPIEN RATHNAMANI VIDYALAYA MIDDLE SCHOOL, KAITHARI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18252",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, NILAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18253",
            "district": "14",
            "Block": "328",
            "label": "AIDED MIDDLE SCHOOL, KOOTHIYARKUNDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18254",
            "district": "14",
            "Block": "328",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SOORAKKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18255",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  PARAMBUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18256",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, PERIYA ALANGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18257",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S NEDUMADURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18258",
            "district": "14",
            "Block": "328",
            "label": "GOVT HR SEC SCHOOL, PARAPATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18259",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18260",
            "district": "14",
            "Block": "328",
            "label": "PUMS SOLANKURUNI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18261",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18262",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, VIRATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18263",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  NEDUNKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18264",
            "district": "14",
            "Block": "328",
            "label": "GOVT HR SEC SCHOOL, AVANIAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18265",
            "district": "14",
            "Block": "328",
            "label": "P.U.M.S  AVANIAPURAM    1",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18266",
            "district": "14",
            "Block": "328",
            "label": "P.N.U.A.S NADAR MATRIC HR SEC SCHOOL, AVANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18267",
            "district": "14",
            "Block": "328",
            "label": "PASUMALAI HR SEC SCHOOL, PASUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18268",
            "district": "14",
            "Block": "328",
            "label": "CSI GIRLS HR SEC SCHOOL, PASUMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18269",
            "district": "14",
            "Block": "328",
            "label": "PANSY VIDYALAYA MATRIC HR SEC SCHOOL, THIRUPARANKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18270",
            "district": "14",
            "Block": "328",
            "label": "GOVT HR SEC SCHOOL, THIRUPRANKUNDRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18271",
            "district": "14",
            "Block": "328",
            "label": "JOTHI M.S CHANDRA PALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18272",
            "district": "14",
            "Block": "328",
            "label": "ARULMIGU ANDAVAR SUBRAMANIA SWAMY GIRLS HR SEC SCHOOL, THIRUPARANKUNDRAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18273",
            "district": "14",
            "Block": "328",
            "label": "MUTHUTHEVAR MUKKULATHORE HR SEC SCHOOL, THIRUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18274",
            "district": "14",
            "Block": "328",
            "label": "AMALA PETHANNEL M.S THIRUNAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18275",
            "district": "14",
            "Block": "328",
            "label": "MALLEGAI MATRIC HIGH SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18276",
            "district": "14",
            "Block": "328",
            "label": "SITALAKSHMI GIRLS HR SEC SCHOOL, THIRUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18277",
            "district": "14",
            "Block": "328",
            "label": "CS RAMACHARY MEMORIAL MATRIC HR SEC SCHOOL, THIRUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18278",
            "district": "14",
            "Block": "328",
            "label": "SAVITHABAI (KOVAI) HR SEC SCHOOL, THIRUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18279",
            "district": "14",
            "Block": "328",
            "label": "SARAH MIDLE SCHOOL, THIRUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18280",
            "district": "14",
            "Block": "328",
            "label": "COR.M.S VILLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18281",
            "district": "14",
            "Block": "328",
            "label": "GOVT. ORTHO S VILLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18282",
            "district": "14",
            "Block": "328",
            "label": "K.V.T MATRIC HR SEC SCHOOL, SUBRAMANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18283",
            "district": "14",
            "Block": "328",
            "label": "NEETHIRAJAN BHARATHI HIGH SCHOOL, JAIHINDUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18284",
            "district": "14",
            "Block": "328",
            "label": "NETHAJI MIDDLE SCHOOL, JAIHINDPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18285",
            "district": "14",
            "Block": "328",
            "label": "COR.M.S JAIHINDUPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18286",
            "district": "14",
            "Block": "328",
            "label": "K.A.C.A ARUNACHALAM HR SEC SCHOOL, JAIHINDPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18287",
            "district": "14",
            "Block": "328",
            "label": "SRI AYYANAR MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18288",
            "district": "14",
            "Block": "328",
            "label": "T.V.S HR SEC SCHOOL, LAKSHMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18289",
            "district": "14",
            "Block": "328",
            "label": "COR.M.S VEERAMUDAIYAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18290",
            "district": "14",
            "Block": "328",
            "label": "THE TVS SCHOOL, TVS NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18291",
            "district": "14",
            "Block": "328",
            "label": "GOVT.KALLAR.H.S, MUTHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18292",
            "district": "14",
            "Block": "328",
            "label": "CORPORATION MIDDLE SCHOOL, PASUMALAI SUB STATION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18293",
            "district": "14",
            "Block": "328",
            "label": "RUBY MATRIC HR SEC SCHOOL, PALANGANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18294",
            "district": "14",
            "Block": "328",
            "label": "VISAKAN  SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18295",
            "district": "14",
            "Block": "328",
            "label": "COR.M.S MADAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18296",
            "district": "14",
            "Block": "328",
            "label": "CORPORATION NSSB GIRLS HR SEC SCHOOL, PALANGANATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18297",
            "district": "14",
            "Block": "328",
            "label": "R.C HIGH SCHOOL, PALANGANATHAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18298",
            "district": "14",
            "Block": "328",
            "label": "SWATHI MATRICULATION SCHOOL, PALANGANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18299",
            "district": "14",
            "Block": "328",
            "label": "SRI AUROBINDO MIRA MATRIC HR SEC SCHOOL, NEHRU NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18300",
            "district": "14",
            "Block": "328",
            "label": "JEEVANA SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18301",
            "district": "14",
            "Block": "328",
            "label": "KNPMM PILLAIMAR SANGAM HR SEC SCHOOL, SAMMATTIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18302",
            "district": "14",
            "Block": "328",
            "label": "INFANT JESUS M.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18303",
            "district": "14",
            "Block": "328",
            "label": "RAJAM VIDYALAYAM MIDDLE SCHOOL, PONMENI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18304",
            "district": "14",
            "Block": "328",
            "label": "A.L.M MATRIC HIGH SCHOOL, Ponmeni, ARASARADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18305",
            "district": "14",
            "Block": "328",
            "label": "KENDRA VIDYALAYA HIGHER SECONDARY SCHOOL, THIRUPARANKUNDRAM",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18306",
            "district": "14",
            "Block": "328",
            "label": "K.G.V SALA HIGH SCHOOL, VIRATTIPATHU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18307",
            "district": "14",
            "Block": "328",
            "label": "SEVENTHDAY ADVENTIST MATRIC HR SEC SCHOOL, KOCHADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18308",
            "district": "14",
            "Block": "328",
            "label": "SRI SADHANA MATRIC HR SEC SCHOOL, VIRATTIPATHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18309",
            "district": "14",
            "Block": "328",
            "label": "QUEEN MIRA INTERN S KOCHADAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21106",
            "district": "14",
            "Block": "328",
            "label": "OM SADHANA CENTRAL CBSE S, VIRATTIPATH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21129",
            "district": "14",
            "Block": "328",
            "label": "ST.ASSISI WORL  IGCSE SCH, THUVARIMAN",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21337",
            "district": "14",
            "Block": "328",
            "label": "VELAMMAL CBSE.H.S VIRAGANOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21402",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, THOPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21557",
            "district": "14",
            "Block": "328",
            "label": "PALLOTTI HIGHER  SECONDARY  SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21561",
            "district": "14",
            "Block": "328",
            "label": "MALLI VIDHYALAYA HIGH SCHOOL, PERUNGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21749",
            "district": "14",
            "Block": "328",
            "label": "K.M.R INTERNATIONAL CBSE SCHOOL, ATCHAMPATHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21750",
            "district": "14",
            "Block": "328",
            "label": "K.C VIDYALAY CBSE HIGH SCHOOL, ELIYARPATHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22250",
            "district": "14",
            "Block": "328",
            "label": "SRI AUROBINDO MIRA UNIVERSAL SCHOOL, KEELAMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22251",
            "district": "14",
            "Block": "328",
            "label": "S.B.O.A.  SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22252",
            "district": "14",
            "Block": "328",
            "label": "K.L.N. VIDYALAYA CBSE Hr. Sec. SCHOOL, VIRAGANOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22253",
            "district": "14",
            "Block": "328",
            "label": "THE INDIAN 3C MATRICULATION SCHOOL. , VILACHERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22254",
            "district": "14",
            "Block": "328",
            "label": "SWAMI VIVEKANANDA VID. CBSE S, HARVIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22608",
            "district": "14",
            "Block": "328",
            "label": "VIKASA PROGRASSIVE SCHOOL, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22609",
            "district": "14",
            "Block": "328",
            "label": "NMS VIJAYALAKSHMI SANJEEVIMALAYAN KALVIYAGAM, NAGAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22789",
            "district": "14",
            "Block": "328",
            "label": "EVERGREEN VIDHYAASHRAM CBSE SCHOOL, AVANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22829",
            "district": "14",
            "Block": "328",
            "label": "ELITE PUBLIC CBSE SCHOOL, PULIYANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22830",
            "district": "14",
            "Block": "328",
            "label": "NAGASIVA VIDYALAYA SCHOOL, SAMANATHAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22831",
            "district": "14",
            "Block": "328",
            "label": "DOLPHIN ELITE CBSE S, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22888",
            "district": "14",
            "Block": "328",
            "label": "GOVT HIGH SCHOOL, KARUVELAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23131",
            "district": "14",
            "Block": "328",
            "label": "ASHVATTHA SCHOOL, THANATHAVAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23512",
            "district": "14",
            "Block": "328",
            "label": "Government Model School, Madurai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23513",
            "district": "14",
            "Block": "328",
            "label": "MADURAI DISTRICT GOVERNMENT MODEL SCHOOL, THOPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23514",
            "district": "14",
            "Block": "328",
            "label": "MAHARISHI VIDYA MANDIR, THANAKKANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23515",
            "district": "14",
            "Block": "328",
            "label": "ST. ANNE'S MATRICULATION SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23516",
            "district": "14",
            "Block": "328",
            "label": "RIGHT WAY ISLAMIC MATRICULATION SCHOOL., AVANIYAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23512",
            "district": "14",
            "Block": "328",
            "label": "Government Model School, Madurai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23513",
            "district": "14",
            "Block": "328",
            "label": "MADURAI DISTRICT GOVERNMENT MODEL SCHOOL, THOPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23514",
            "district": "14",
            "Block": "328",
            "label": "MAHARISHI VIDYA MANDIR, THANAKKANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23515",
            "district": "14",
            "Block": "328",
            "label": "ST. ANNE'S MATRICULATION SCHOOL, PONMENI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23516",
            "district": "14",
            "Block": "328",
            "label": "RIGHT WAY ISLAMIC MATRICULATION SCHOOL., AVANIYAPURAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24007",
            "district": "14",
            "Block": "328",
            "label": "THE AKSHAYA ACADEMY CAMPUS SCHOOL, THIRUPARAMKUNDRAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "329": [
        {
            "key": "4644",
            "district": "2",
            "Block": "329",
            "label": "Ramaniyam Sankara HSS,Toraipakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4876",
            "district": "2",
            "Block": "329",
            "label": "BUVANA KRISHNAN MAT.HR.SEC.SCHOOL,KELAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4932",
            "district": "2",
            "Block": "329",
            "label": "EVERGREEN MATRICULATION HIGHER S ECONDARY SCHOOL, THIRUPORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4999",
            "district": "2",
            "Block": "329",
            "label": "AARUPADAI VEEDU MATRICULATION HIGHER SECONDARY SCHOOL, THIRUPORUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5004",
            "district": "2",
            "Block": "329",
            "label": "ST. MARY'S MHSS, KELAMBAKKAM-603103",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5550",
            "district": "2",
            "Block": "329",
            "label": "BHARATHA VIDYALAYA MATRICULATION HR SEC SCHOOL, THANDALAM-TIRUPORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5663",
            "district": "2",
            "Block": "329",
            "label": "ADWMS Melakottaiyur",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5680",
            "district": "2",
            "Block": "329",
            "label": "ST JOSEPH'S MATRICULATION SCHOOL,MELAKOTTAIYUR-600127",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6153",
            "district": "2",
            "Block": "329",
            "label": "SRI VANI VID. MAT.HSS, THIRUPORUR-603110",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6390",
            "district": "2",
            "Block": "329",
            "label": "MURRAY RABINDRA Matriculation SCHOOL, PUTHUPAKKAM-603103",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6492",
            "district": "2",
            "Block": "329",
            "label": "PUMS KAYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6567",
            "district": "2",
            "Block": "329",
            "label": "PUMS KOTTAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6699",
            "district": "2",
            "Block": "329",
            "label": "SUDDHANANDA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL , AMMAPETTAI.603108",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7839",
            "district": "2",
            "Block": "329",
            "label": "PUMS KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7840",
            "district": "2",
            "Block": "329",
            "label": "ACMS PONMAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7841",
            "district": "2",
            "Block": "329",
            "label": "GHSS MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7842",
            "district": "2",
            "Block": "329",
            "label": "MAHARISHI VIDYA MANDIR SR SEC SCHOOL - POLACHERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7843",
            "district": "2",
            "Block": "329",
            "label": "Ansaldo HS(Unaided), Thazhambur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23372",
            "district": "2",
            "Block": "329",
            "label": "Sushil Hari International Residency school",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "7844",
            "district": "2",
            "Block": "329",
            "label": "PUMS NAVALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7845",
            "district": "2",
            "Block": "329",
            "label": "PADMA ADARSH HIGHER SECONDARY SCHOOL - VANIYANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7846",
            "district": "2",
            "Block": "329",
            "label": "PUMS KANATHUR REDDY KUPPAM COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7847",
            "district": "2",
            "Block": "329",
            "label": "ST. LOURDES MATRICULATION SCHOOL, MUTTUKKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7848",
            "district": "2",
            "Block": "329",
            "label": "PUMS KAZHIPATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7849",
            "district": "2",
            "Block": "329",
            "label": "GATE WAY INTERNATIONAL, KAZHIPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7850",
            "district": "2",
            "Block": "329",
            "label": "PADMA SESHADRI BALA BHAVAN SENIOR SECONDARY SCHOOL,SIRUSERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7851",
            "district": "2",
            "Block": "329",
            "label": "GHSS KELAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7852",
            "district": "2",
            "Block": "329",
            "label": "SATHANA SPECIAL SCHOOL FOR DEAF",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7853",
            "district": "2",
            "Block": "329",
            "label": "ST. FRANCIS DE SALES MHSS ,  KELAMBAKKAM-603 103",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7854",
            "district": "2",
            "Block": "329",
            "label": "Panchayat Union Middle School, PADUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7855",
            "district": "2",
            "Block": "329",
            "label": "ST JOSEPH HSS KOVALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7856",
            "district": "2",
            "Block": "329",
            "label": "Govt Higher Secondary  School,KOVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7857",
            "district": "2",
            "Block": "329",
            "label": "GURUKULAM A TRUST CHILDREN INITIATIVE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7858",
            "district": "2",
            "Block": "329",
            "label": "ADWHSS THAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7859",
            "district": "2",
            "Block": "329",
            "label": "JAGANNATH VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7860",
            "district": "2",
            "Block": "329",
            "label": "PUMS PERUNTHANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7861",
            "district": "2",
            "Block": "329",
            "label": "GHS ANUMANTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7862",
            "district": "2",
            "Block": "329",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , SIRUNKUNDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7863",
            "district": "2",
            "Block": "329",
            "label": "GHSS NELLIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7864",
            "district": "2",
            "Block": "329",
            "label": "CEE DEE YES DAV SC AMMAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7865",
            "district": "2",
            "Block": "329",
            "label": "PUMS VENBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7866",
            "district": "2",
            "Block": "329",
            "label": "GHS ILLALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7867",
            "district": "2",
            "Block": "329",
            "label": "Government Higher  Secondary School - NEMMELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7868",
            "district": "2",
            "Block": "329",
            "label": "PUMS THANDALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7869",
            "district": "2",
            "Block": "329",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  SEMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7870",
            "district": "2",
            "Block": "329",
            "label": "GOVERNMENT HIGH SCHOOL  KARUMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7871",
            "district": "2",
            "Block": "329",
            "label": "PUMS MELAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7872",
            "district": "2",
            "Block": "329",
            "label": "GHS ORAGADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7873",
            "district": "2",
            "Block": "329",
            "label": "PUMS MULLIPPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7874",
            "district": "2",
            "Block": "329",
            "label": "PUMS RAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7875",
            "district": "2",
            "Block": "329",
            "label": "GHSS MANAMPATHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7876",
            "district": "2",
            "Block": "329",
            "label": "Panchayat Union Middle School - KUNNAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7877",
            "district": "2",
            "Block": "329",
            "label": "PUMS KANNAGAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7878",
            "district": "2",
            "Block": "329",
            "label": "PUMS AMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7879",
            "district": "2",
            "Block": "329",
            "label": "ADWMS ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7880",
            "district": "2",
            "Block": "329",
            "label": "Adi Dravida Welfare High School - PATTIPULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7881",
            "district": "2",
            "Block": "329",
            "label": "GHS PAIYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7882",
            "district": "2",
            "Block": "329",
            "label": "R.C ST JOSEPH'S HIGH SCHOOL KOOTHAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7883",
            "district": "2",
            "Block": "329",
            "label": "Panchayat Union Middle School - SALAVANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7884",
            "district": "2",
            "Block": "329",
            "label": "GBHSS THIRUPORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7885",
            "district": "2",
            "Block": "329",
            "label": "GHSS(G) THIRUPORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21427",
            "district": "2",
            "Block": "329",
            "label": "GHS(G), Kelambakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21492",
            "district": "2",
            "Block": "329",
            "label": "ADWHS, Pudupakkam",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21581",
            "district": "2",
            "Block": "329",
            "label": "Velammal Vidhyashram, Mambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21732",
            "district": "2",
            "Block": "329",
            "label": "VELS VIDYASHRAM SENIOR SECONDARY SCHOOL, THALAMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22030",
            "district": "2",
            "Block": "329",
            "label": "Velammal New Gen School, Kelambakkam",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22350",
            "district": "2",
            "Block": "329",
            "label": "GGHS, Manampathy",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22351",
            "district": "2",
            "Block": "329",
            "label": "HLC International School, Karanai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22352",
            "district": "2",
            "Block": "329",
            "label": "GHS, Siruthavoor",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22708",
            "district": "2",
            "Block": "329",
            "label": "POLICE PUBLIC SCHOOL, MELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22710",
            "district": "2",
            "Block": "329",
            "label": "BILLABONG HIGH INTERNATIONAL SCHOOL, THAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22711",
            "district": "2",
            "Block": "329",
            "label": "HINDUSTAN INTERNATIONAL SCHOOL PADUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22712",
            "district": "2",
            "Block": "329",
            "label": "SBOA HIGH SCHOOL POLACHERRY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22713",
            "district": "2",
            "Block": "329",
            "label": "MOUNT LITERAZEE SCHOOL NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22894",
            "district": "2",
            "Block": "329",
            "label": "Athena Global School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22895",
            "district": "2",
            "Block": "329",
            "label": "Government Eklavya Model Residential School - Pattipulam",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22947",
            "district": "2",
            "Block": "329",
            "label": "HIRANANDANI UPSCALE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23024",
            "district": "2",
            "Block": "329",
            "label": "LITTLE FLOCK NURSERY AND PRIMARY SCHOOL, ANUMANTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23198",
            "district": "2",
            "Block": "329",
            "label": "COLOURS INDIAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23204",
            "district": "2",
            "Block": "329",
            "label": "THE SCHOOL - KFI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23205",
            "district": "2",
            "Block": "329",
            "label": "EDUBBA SCHOOL, POLACHERY ROAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23227",
            "district": "2",
            "Block": "329",
            "label": "KC HIGH SCHOOL NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23303",
            "district": "2",
            "Block": "329",
            "label": "SHRADDHA CHILDRENS ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23442",
            "district": "2",
            "Block": "329",
            "label": "ADWHS MELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23443",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL NEW GEN SCHOOL - NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23444",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL VIDHYASHRAM PADUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23445",
            "district": "2",
            "Block": "329",
            "label": "VELLORE INTERNATIONAL RESIDENTIAL SCHOOL  KAYAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23446",
            "district": "2",
            "Block": "329",
            "label": "JAGANNATH VIDYALAYA MATRICULATION SCHOOL. THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23442",
            "district": "2",
            "Block": "329",
            "label": "ADWHS MELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23443",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL NEW GEN SCHOOL - NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23444",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL VIDHYASHRAM PADUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23445",
            "district": "2",
            "Block": "329",
            "label": "VELLORE INTERNATIONAL RESIDENTIAL SCHOOL  KAYAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23446",
            "district": "2",
            "Block": "329",
            "label": "JAGANNATH VIDYALAYA MATRICULATION SCHOOL. THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23442",
            "district": "2",
            "Block": "329",
            "label": "ADWHS MELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23443",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL NEW GEN SCHOOL - NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23444",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL VIDHYASHRAM PADUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23445",
            "district": "2",
            "Block": "329",
            "label": "VELLORE INTERNATIONAL RESIDENTIAL SCHOOL  KAYAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23446",
            "district": "2",
            "Block": "329",
            "label": "JAGANNATH VIDYALAYA MATRICULATION SCHOOL. THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23442",
            "district": "2",
            "Block": "329",
            "label": "ADWHS MELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23443",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL NEW GEN SCHOOL - NAVALUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23444",
            "district": "2",
            "Block": "329",
            "label": "VELAMMAL VIDHYASHRAM PADUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23445",
            "district": "2",
            "Block": "329",
            "label": "VELLORE INTERNATIONAL RESIDENTIAL SCHOOL  KAYAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23446",
            "district": "2",
            "Block": "329",
            "label": "JAGANNATH VIDYALAYA MATRICULATION SCHOOL. THANDALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24008",
            "district": "2",
            "Block": "329",
            "label": "PUMS KOLATHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24009",
            "district": "2",
            "Block": "329",
            "label": "Athena Global School",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24010",
            "district": "2",
            "Block": "329",
            "label": "VIJAY VIDHYASHRAM, PADUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24011",
            "district": "2",
            "Block": "329",
            "label": "MILTON BILLABONG INTERNATIONAL SCHOOL, THAIYUR ,KELAMBAKKAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24012",
            "district": "2",
            "Block": "329",
            "label": "VELLORE INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24013",
            "district": "2",
            "Block": "329",
            "label": "CHETTINAD SARVALOKAA EDUCATION INTRENATIONAL SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "330": [
        {
            "key": "1706",
            "district": "25",
            "Block": "330",
            "label": "PUMS  KANNARAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2797",
            "district": "25",
            "Block": "330",
            "label": "SRI KUMARA GURUBARAR MATRICULATION  HIGHER SECONDARY SCHOOL-THIRUPPANANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3706",
            "district": "25",
            "Block": "330",
            "label": "AMS-THIRUKODIKAVAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3894",
            "district": "25",
            "Block": "330",
            "label": "SRI KUMARA GURUPARA SWAMIGAL MIDDLE SCHOOL - THIRUPPANANDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5611",
            "district": "25",
            "Block": "330",
            "label": "PUMS-MUTTAKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5806",
            "district": "25",
            "Block": "330",
            "label": "PUMS-KEELMANTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5884",
            "district": "25",
            "Block": "330",
            "label": "AMS-KATHIRAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6738",
            "district": "25",
            "Block": "330",
            "label": "PASUPATHI MIDDLE SCHOOL-PANDHANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16619",
            "district": "25",
            "Block": "330",
            "label": "GHS-ARALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16620",
            "district": "25",
            "Block": "330",
            "label": "RAMAKRISHNA MIDDDLE SCHOOL - KEELAKATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16621",
            "district": "25",
            "Block": "330",
            "label": "AMS  THATHUVANCHERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16622",
            "district": "25",
            "Block": "330",
            "label": "GHS-KONULAMPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16623",
            "district": "25",
            "Block": "330",
            "label": "GHS-KURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16624",
            "district": "25",
            "Block": "330",
            "label": "SARASWATHI MS-MULLANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16625",
            "district": "25",
            "Block": "330",
            "label": "PUMS-PANDHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16626",
            "district": "25",
            "Block": "330",
            "label": "GHSS (GIRLS) - PANDHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16627",
            "district": "25",
            "Block": "330",
            "label": "GHSS(B) -PANDHANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16628",
            "district": "25",
            "Block": "330",
            "label": "SRI VENKATESWARA MATRIC  SCHOOL-PANDHANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16629",
            "district": "25",
            "Block": "330",
            "label": "PUMS-VETTAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16630",
            "district": "25",
            "Block": "330",
            "label": "GHS-CHITRIDAIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16631",
            "district": "25",
            "Block": "330",
            "label": "KGS AIDED MS-THIRULOGI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16632",
            "district": "25",
            "Block": "330",
            "label": "GHS-KODIYALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16633",
            "district": "25",
            "Block": "330",
            "label": "PUMS - MOZHAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16634",
            "district": "25",
            "Block": "330",
            "label": "KG  AIDED MS - THUGILI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16635",
            "district": "25",
            "Block": "330",
            "label": "GHS - THIRUVAIPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16636",
            "district": "25",
            "Block": "330",
            "label": "GHSS ANAIKKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16637",
            "district": "25",
            "Block": "330",
            "label": "SRI KAILAI SUBRAMAIYA DESIKA SWAMIGAL HIGHER SECONDARY SCHOOL-THIRUPPANANDAL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22751",
            "district": "25",
            "Block": "330",
            "label": "GHS -KATHIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "331": [
        {
            "key": "17690",
            "district": "23",
            "Block": "331",
            "label": "PUMS ALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17691",
            "district": "23",
            "Block": "331",
            "label": "PUMS AIYINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17692",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL E SENBAGAPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17693",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL SEVVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17694",
            "district": "23",
            "Block": "331",
            "label": "PUMS MITHILAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17695",
            "district": "23",
            "Block": "331",
            "label": "PUMS K.ILAYATHANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17696",
            "district": "23",
            "Block": "331",
            "label": "VIVEKANANDA KALVI KAZHAGAM  MIDDLE SCHOOL AAVINIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17697",
            "district": "23",
            "Block": "331",
            "label": "SMS HSS KEELASEVALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17698",
            "district": "23",
            "Block": "331",
            "label": "RM M MAT SCHOOL KILASELVALPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17699",
            "district": "23",
            "Block": "331",
            "label": "PUMS PIRAMANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17700",
            "district": "23",
            "Block": "331",
            "label": "PUMS MELAIYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17701",
            "district": "23",
            "Block": "331",
            "label": "SPD.MID.SCHOOL KANDAVARAYANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17702",
            "district": "23",
            "Block": "331",
            "label": "PUMS KARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17703",
            "district": "23",
            "Block": "331",
            "label": "PUMS MANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17704",
            "district": "23",
            "Block": "331",
            "label": "PUMS KARUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17705",
            "district": "23",
            "Block": "331",
            "label": "PUMS DEVARAMBOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17706",
            "district": "23",
            "Block": "331",
            "label": "PLAZAA  MATRICULATION  SCHOOL ,   PUDHUKATTAMBUR.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17707",
            "district": "23",
            "Block": "331",
            "label": "PUMS KANAKKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17708",
            "district": "23",
            "Block": "331",
            "label": "PUMS V.MALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17709",
            "district": "23",
            "Block": "331",
            "label": "GOVT HSS S VELANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17710",
            "district": "23",
            "Block": "331",
            "label": "PUMS KONNATHANPATI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17711",
            "district": "23",
            "Block": "331",
            "label": "GOVT HSS KOTTAIYIRUPPU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17712",
            "district": "23",
            "Block": "331",
            "label": "PUMS THIRUVIDAIYARPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17713",
            "district": "23",
            "Block": "331",
            "label": "K.V.MID SCHOOL SIRUKOODALPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17714",
            "district": "23",
            "Block": "331",
            "label": "PUMS VANCHINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17715",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL MAHIBALANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17716",
            "district": "23",
            "Block": "331",
            "label": "PUMS NEDUMARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17717",
            "district": "23",
            "Block": "331",
            "label": "VT GIRLS HIGH SCHOOL A.THEKKUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "17718",
            "district": "23",
            "Block": "331",
            "label": "SVK HSS A THEKKUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17719",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL THIRUKKALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17720",
            "district": "23",
            "Block": "331",
            "label": "PUMS CHAMANTHANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17721",
            "district": "23",
            "Block": "331",
            "label": "GOVT HSS THIRUKKOSTIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17722",
            "district": "23",
            "Block": "331",
            "label": "PUMS THUVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17723",
            "district": "23",
            "Block": "331",
            "label": "SSARAVANA MATRIC SCHOOL THUVAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17724",
            "district": "23",
            "Block": "331",
            "label": "PUMS M.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17725",
            "district": "23",
            "Block": "331",
            "label": "PUMS VANIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17726",
            "district": "23",
            "Block": "331",
            "label": "PUMS SEVINIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17727",
            "district": "23",
            "Block": "331",
            "label": "TM GOVT HSS POOLANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17728",
            "district": "23",
            "Block": "331",
            "label": "AVM Public Matriculation Higher Secondary School,Sevvoor",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17729",
            "district": "23",
            "Block": "331",
            "label": "PUMS SUNNAMBIRUPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17730",
            "district": "23",
            "Block": "331",
            "label": "PUMS MARKKANDEYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17731",
            "district": "23",
            "Block": "331",
            "label": "PUMS CHUNDAKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17732",
            "district": "23",
            "Block": "331",
            "label": "PUMS K.VAIRAVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17733",
            "district": "23",
            "Block": "331",
            "label": "SATHAPPA GOVT HSS NERKKUPPAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17734",
            "district": "23",
            "Block": "331",
            "label": "PUMS N.VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17735",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL T PUTHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17736",
            "district": "23",
            "Block": "331",
            "label": "LIMRA MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17737",
            "district": "23",
            "Block": "331",
            "label": "N.M.GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL ,THIRUPPATHUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17738",
            "district": "23",
            "Block": "331",
            "label": "CHRISTHURAJA MATRIC HIGHER SECONDARY SCHOOL K.VAIRAVANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17739",
            "district": "23",
            "Block": "331",
            "label": "MUSLIM PULAVAR MID SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17740",
            "district": "23",
            "Block": "331",
            "label": "R.C.FATHIMA MIDDLE SCHOOL , THIRUPPATHUR. .",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17741",
            "district": "23",
            "Block": "331",
            "label": "BABA AMIR BADHUSHA MATRIC HR SEC SCHOOL, THIRUPPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17742",
            "district": "23",
            "Block": "331",
            "label": "TELC TRANSATIONAL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17743",
            "district": "23",
            "Block": "331",
            "label": "A.P.GOVT.(B)HR.SEC.SCHOOL THIRUPPATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17744",
            "district": "23",
            "Block": "331",
            "label": "ARUMUGAM PILLAI SEETHAI AMMAL MATRIC  HR SEC SCHOOL, THIRUPPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17745",
            "district": "23",
            "Block": "331",
            "label": "TELC BLIND MIDDLE SCHOOL TPR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17746",
            "district": "23",
            "Block": "331",
            "label": "GOVT HIGH SCHOOL THENMAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17747",
            "district": "23",
            "Block": "331",
            "label": "INDIRAGANDHI MATRICULATION SCHOOL THENMAPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22596",
            "district": "23",
            "Block": "331",
            "label": "SKS CBSE SCHOOL,THIRUPPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23555",
            "district": "23",
            "Block": "331",
            "label": "BSR GREEN PARK INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23555",
            "district": "23",
            "Block": "331",
            "label": "BSR GREEN PARK INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24014",
            "district": "31",
            "Block": "331",
            "label": "PUMS VINAYAGAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24015",
            "district": "23",
            "Block": "331",
            "label": "PUMS NEDUMARAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24016",
            "district": "23",
            "Block": "331",
            "label": "PUMS M.PUDUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24112",
            "district": "31",
            "Block": "331",
            "label": "TIRUPATHUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "332": [
        {
            "key": "1419",
            "district": "20",
            "Block": "332",
            "label": "HAMEEDIAH MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1465",
            "district": "20",
            "Block": "332",
            "label": "ISLAMIAH MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1819",
            "district": "20",
            "Block": "332",
            "label": "P.S.S.J. NADAR MATRICULATION SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2106",
            "district": "20",
            "Block": "332",
            "label": "MUHAIIDHEENIA MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2559",
            "district": "20",
            "Block": "332",
            "label": "PEARL MATRICULATION HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3526",
            "district": "20",
            "Block": "332",
            "label": "VIJI VIDYALAYA MATRICULATION SCHOOL, THIRU UTHARAKOSAMANGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3567",
            "district": "20",
            "Block": "332",
            "label": "SATHAKATHUN JARIA MIDDLE SCHOOL, KILAKKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3850",
            "district": "20",
            "Block": "332",
            "label": "DHEENIAH MATRICULATION  HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4586",
            "district": "20",
            "Block": "332",
            "label": "N.M.S. S.V. MIDDLE SCHOOL, MAYAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5839",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, CHINNAPALAIYARENDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5887",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUTHUKKALVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6504",
            "district": "20",
            "Block": "332",
            "label": "NADAR MIDDLE SCHOOL, MAVILANTHOPE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19215",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , ALANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19216",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , EKKAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19217",
            "district": "20",
            "Block": "332",
            "label": "C.S.I. MIDDLE SCHOOL, KILAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19218",
            "district": "20",
            "Block": "332",
            "label": "ISLAMIAH HIGH SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19219",
            "district": "20",
            "Block": "332",
            "label": "HAMEEDIAH BOYS HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19220",
            "district": "20",
            "Block": "332",
            "label": "HAMEEDIA GIRLS HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19221",
            "district": "20",
            "Block": "332",
            "label": "KHYRATHUL JALALIYA HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19222",
            "district": "20",
            "Block": "332",
            "label": "MAKHDHOOMIA HIGHER SECONDARY SCHOOL, KILAKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19223",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , L.KARUNGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19224",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, PANAIYADIYENDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19225",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THIRU UTHRAKOSAMANGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19226",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, VELANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19227",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BHARATHINAGAR,MAYAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19228",
            "district": "20",
            "Block": "332",
            "label": "SRISETHUMANICKAM HIGH SCHOOL, MELASEETHAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19229",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, KANCHIRANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19230",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, KALIMANGUNDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19231",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19232",
            "district": "20",
            "Block": "332",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELAPUDUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19233",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PERIYAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19234",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, REGUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19235",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGH SCHOOL, PUDUKOIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19236",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THIRUPPULLANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19237",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THINAIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19238",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VANNANGUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19239",
            "district": "20",
            "Block": "332",
            "label": "N.M.S. HIGH SCHOOL, METHALODAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19240",
            "district": "20",
            "Block": "332",
            "label": "ST. JOSEPH'S HIGHER SECONDARY SCHOOL, MUTHUPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21037",
            "district": "20",
            "Block": "332",
            "label": "SRI SARASWATHI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL, REGUNATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21038",
            "district": "20",
            "Block": "332",
            "label": "SRI KRISHNA MATRICULATION SCHOOL, VANNANGUNDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21117",
            "district": "20",
            "Block": "332",
            "label": "KANNADIVAPPA INTERNATIONAL SCHOOL, KANJIRANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21522",
            "district": "20",
            "Block": "332",
            "label": "GOVERNMENT ADI DRAVIDAR WELFARE HIGH SCHOOL, PAZHANCHIRAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22631",
            "district": "20",
            "Block": "332",
            "label": "AL BAYYINAH MATRIC SCHOOL, KILAKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24017",
            "district": "20",
            "Block": "332",
            "label": "RAMANATHAPURAM DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "333": [
        {
            "key": "4558",
            "district": "23",
            "Block": "333",
            "label": "PUMS KATTAMANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5367",
            "district": "23",
            "Block": "333",
            "label": "ARULMIGU THANDEESWARAR MATRIC. HIGH SCHOOL,THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17492",
            "district": "23",
            "Block": "333",
            "label": "SYED ISMAIL AVULIYA MIDDLE SCHOOL THIRUPPUVANAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "17493",
            "district": "23",
            "Block": "333",
            "label": "PUMS THIRUPPUVANAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17494",
            "district": "23",
            "Block": "333",
            "label": "PUMS THIRUPPUVANAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17495",
            "district": "23",
            "Block": "333",
            "label": "ANNAI SIVA PACKIYAM MEMORIAL HR. SEC. SCHOOL, THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17496",
            "district": "23",
            "Block": "333",
            "label": "GOVT GIRLS HSS THIRUPPUVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17497",
            "district": "23",
            "Block": "333",
            "label": "PUMS THIRUPPUVANAM PALAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17498",
            "district": "23",
            "Block": "333",
            "label": "KAMARAJAR MATRIC  HR SEC SCHOOL THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17499",
            "district": "23",
            "Block": "333",
            "label": "ST MARY'S MATRIC HR SEC  THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17500",
            "district": "23",
            "Block": "333",
            "label": "VELAMMAL MATRIC HR SEC  THIRUPPUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17501",
            "district": "23",
            "Block": "333",
            "label": "PUMS MADAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17502",
            "district": "23",
            "Block": "333",
            "label": "GOVT BOYS HSS THIRUPPUVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17503",
            "district": "23",
            "Block": "333",
            "label": "ARIYAVA MONTESSORI MATRIC. HR. SEC. SCHOOL, MADAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17504",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGHER SECONDARY  SCHOOL ALLINAGARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17505",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL POOVANTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17506",
            "district": "23",
            "Block": "333",
            "label": "MANIMARAN MATRICULATION SCHOOL  POOVANTHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17507",
            "district": "23",
            "Block": "333",
            "label": "PUMS KALUGARKADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17508",
            "district": "23",
            "Block": "333",
            "label": "PUMS ENATHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17509",
            "district": "23",
            "Block": "333",
            "label": "PUMS KANAKANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17510",
            "district": "23",
            "Block": "333",
            "label": "PUMS MELARANGIAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17511",
            "district": "23",
            "Block": "333",
            "label": "PUMS KELERANGIAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17512",
            "district": "23",
            "Block": "333",
            "label": "PUMS VAYALCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17513",
            "district": "23",
            "Block": "333",
            "label": "PUMS PRAMANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17514",
            "district": "23",
            "Block": "333",
            "label": "PUMS MARANADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17515",
            "district": "23",
            "Block": "333",
            "label": "PUMS MALAVARAYANENTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17516",
            "district": "23",
            "Block": "333",
            "label": "PUMS PASIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17517",
            "district": "23",
            "Block": "333",
            "label": "GOVT HSS KEELADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17518",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL MANALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17519",
            "district": "23",
            "Block": "333",
            "label": "ALAGUMALAR MHSS MANALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17520",
            "district": "23",
            "Block": "333",
            "label": "PUMS MELAVELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17521",
            "district": "23",
            "Block": "333",
            "label": "PUMS KONTHAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17522",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL ILANTHAIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17523",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL D.KARISALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17524",
            "district": "23",
            "Block": "333",
            "label": "PUMS KAZHUVANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17525",
            "district": "23",
            "Block": "333",
            "label": "PUMS POTTAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17526",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL KANCHERANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17527",
            "district": "23",
            "Block": "333",
            "label": "PUMS CHOTTATHATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17528",
            "district": "23",
            "Block": "333",
            "label": "PUMS MUKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17529",
            "district": "23",
            "Block": "333",
            "label": "PUMS SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17530",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL THAVATHARENTHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17531",
            "district": "23",
            "Block": "333",
            "label": "PUMS KANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17532",
            "district": "23",
            "Block": "333",
            "label": "PUMS THOOTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17533",
            "district": "23",
            "Block": "333",
            "label": "PUMS THIRUPPACHETHI SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17534",
            "district": "23",
            "Block": "333",
            "label": "GOVT HSS THIRUPPACHETHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17535",
            "district": "23",
            "Block": "333",
            "label": "GOVT HSS PALAYANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17536",
            "district": "23",
            "Block": "333",
            "label": "PUMS VANNIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17537",
            "district": "23",
            "Block": "333",
            "label": "VELAMMAL RESIDENTIAL SCHOOL LADANENDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17538",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL LADANENTHAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17539",
            "district": "23",
            "Block": "333",
            "label": "PUMS SANGANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17540",
            "district": "23",
            "Block": "333",
            "label": "PALANI ANDAVAR MIDDLE SCHOOL PAPPKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17541",
            "district": "23",
            "Block": "333",
            "label": "PUMS KURUNTHANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17542",
            "district": "23",
            "Block": "333",
            "label": "PUMS T.PAPANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17543",
            "district": "23",
            "Block": "333",
            "label": "PUMS MUDHUVANTHIDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17544",
            "district": "23",
            "Block": "333",
            "label": "PUMS T.VELANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17545",
            "district": "23",
            "Block": "333",
            "label": "ROMAN CATHOLIC.1 MIDDLE SCHOOL MICHALPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17546",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL KALLURANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17547",
            "district": "23",
            "Block": "333",
            "label": "ST MARY'S HIGH SCHOOL MICHAELPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "17548",
            "district": "23",
            "Block": "333",
            "label": "PUMS KILATHARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22236",
            "district": "23",
            "Block": "333",
            "label": "BRITISH INTERNATIONAL SCHOOL CBSE, MADAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22932",
            "district": "23",
            "Block": "333",
            "label": "GOVT HIGH SCHOOL  KALIYANTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23556",
            "district": "23",
            "Block": "333",
            "label": "VIRUDHUNAGAR HINDU NADAR THOLANDI MANICKAM NADAR JANAKI AMMAL GIRLS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23556",
            "district": "23",
            "Block": "333",
            "label": "VIRUDHUNAGAR HINDU NADAR THOLANDI MANICKAM NADAR JANAKI AMMAL GIRLS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "334": [
        {
            "key": "651",
            "district": "35",
            "Block": "334",
            "label": "UAMHSS SAIRAM MATRIC SCHOOL,TTP",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1017",
            "district": "35",
            "Block": "334",
            "label": "UAMHSS ST.ANTONY'S MATRIC,TTP",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5511",
            "district": "35",
            "Block": "334",
            "label": "PUMS THIRUTHURAIPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6453",
            "district": "35",
            "Block": "334",
            "label": "PUMS CHIDAMBARAKOTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6746",
            "district": "35",
            "Block": "334",
            "label": "AMS,ARULANANTHA PALLANKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16294",
            "district": "35",
            "Block": "334",
            "label": "PUMS ANDANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16295",
            "district": "35",
            "Block": "334",
            "label": "PUMS RAJAKOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16296",
            "district": "35",
            "Block": "334",
            "label": "AHS, JOHN PRITO PALLANKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16297",
            "district": "35",
            "Block": "334",
            "label": "AMS,RAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16298",
            "district": "35",
            "Block": "334",
            "label": "GHS, EZHILUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16299",
            "district": "35",
            "Block": "334",
            "label": "PUMS KOMALAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16300",
            "district": "35",
            "Block": "334",
            "label": "PUMS THIRUPATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16301",
            "district": "35",
            "Block": "334",
            "label": "PUMS ALIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16302",
            "district": "35",
            "Block": "334",
            "label": "PUMS VARAMBIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16303",
            "district": "35",
            "Block": "334",
            "label": "PUMS KUNNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16304",
            "district": "35",
            "Block": "334",
            "label": "GHSS  NEDUMBALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16305",
            "district": "35",
            "Block": "334",
            "label": "GHSS, ALATHAMPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16306",
            "district": "35",
            "Block": "334",
            "label": "GHSS VILAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16307",
            "district": "35",
            "Block": "334",
            "label": "UAHS VENKATESWARA MATRICULATION SCHOOL KATCHANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16308",
            "district": "35",
            "Block": "334",
            "label": "GHS KATCHANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16309",
            "district": "35",
            "Block": "334",
            "label": "PUMS ARAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16310",
            "district": "35",
            "Block": "334",
            "label": "PUMS ADIRENGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16311",
            "district": "35",
            "Block": "334",
            "label": "PUMS PALAYANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16312",
            "district": "35",
            "Block": "334",
            "label": "GHSS,KATTIMEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16313",
            "district": "35",
            "Block": "334",
            "label": "PUMS SEKAL KOTTAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16314",
            "district": "35",
            "Block": "334",
            "label": "PUMS MANALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16315",
            "district": "35",
            "Block": "334",
            "label": "GHS MANALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16316",
            "district": "35",
            "Block": "334",
            "label": "PUMS SEKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16317",
            "district": "35",
            "Block": "334",
            "label": "PUMS SEKAL MADAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16318",
            "district": "35",
            "Block": "334",
            "label": "PUMS MELAKORUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16319",
            "district": "35",
            "Block": "334",
            "label": "AMS KORUKKAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16320",
            "district": "35",
            "Block": "334",
            "label": "GHSS, THALAIKKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16321",
            "district": "35",
            "Block": "334",
            "label": "UAHS SAI SRINIVAS,TTP",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16322",
            "district": "35",
            "Block": "334",
            "label": "GHSS PAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16323",
            "district": "35",
            "Block": "334",
            "label": "PUMS PITCHANKOTTAGAM(N)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16324",
            "district": "35",
            "Block": "334",
            "label": "PUMS MELAMARUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16325",
            "district": "35",
            "Block": "334",
            "label": "GHS, THIRUTHANGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16326",
            "district": "35",
            "Block": "334",
            "label": "AMS, KEERAKKALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16327",
            "district": "35",
            "Block": "334",
            "label": "UAMHS ST.THERASA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16328",
            "district": "35",
            "Block": "334",
            "label": "AHSS,ST.THERASA,TTP",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16329",
            "district": "35",
            "Block": "334",
            "label": "ADWHSS,ABISEKAKATTALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16330",
            "district": "35",
            "Block": "334",
            "label": "GHSS GIRLS THIRUTHURAIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16331",
            "district": "35",
            "Block": "334",
            "label": "GBHSS,THIRUTHURAIPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22574",
            "district": "35",
            "Block": "334",
            "label": "UAMS ,SVS INTERNATIONAL CBSC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "335": [
        {
            "key": "930",
            "district": "20",
            "Block": "335",
            "label": "ISLAMIC MODEL MATRICULATION HIGHER SECONDARY SCHOOL, THONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1422",
            "district": "20",
            "Block": "335",
            "label": "AL-HILAL MATRICULATION HIGHER SECONDARY SCHOOL , THONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2262",
            "district": "20",
            "Block": "335",
            "label": "RAJAN MATRICULATION HIGH SCHOOL, THIRUVADANAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4493",
            "district": "20",
            "Block": "335",
            "label": "CREATIVE MATRICULATION HIGHER SECONDARY SCHOOL, PULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19016",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MUGIZHTHAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19017",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, M.V.PATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19018",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MULLIMUNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19019",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PADANAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19020",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PALANGULAM-2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19021",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, PANDUKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19022",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PANNAVAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19023",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PASIPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19024",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, S.P.PATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19025",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANKOLLANPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19026",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19027",
            "district": "20",
            "Block": "335",
            "label": "R.C. MIDDLE SCHOOL, KURUMILANKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19028",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KODIPANGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19029",
            "district": "20",
            "Block": "335",
            "label": "AMALA ANNAI HIGHER SECONDARY SCHOOL, KARANKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19030",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, KAVALAIVENDRAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19031",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19032",
            "district": "20",
            "Block": "335",
            "label": "SIRUMALAR HIGHER SECONDARY  SCHOOL, ANDAVOORANI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19033",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ANJUKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19034",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, THALIRMARUNGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19035",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, M.R PATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19036",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19037",
            "district": "20",
            "Block": "335",
            "label": "MUNAWWARA MIDDLE SCHOOL , THONDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19038",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19039",
            "district": "20",
            "Block": "335",
            "label": "S.M. GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, THONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19040",
            "district": "20",
            "Block": "335",
            "label": "WILHELM HIGH SCHOOL , THONDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19041",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THINAIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19042",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, N.PANANCHAYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19043",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THIRUVADANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19044",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, THIRUVADANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19045",
            "district": "20",
            "Block": "335",
            "label": "ST. NORBERT R.C. HIGHER SECONDARY SCHOOL, THIRUVETRIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19046",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, VATTANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19047",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, NAMBUTHALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19048",
            "district": "20",
            "Block": "335",
            "label": "ST. FRANCIS HIGHER SECONDARY SCHOOL, C.K.MANGALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19049",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL, THINAIKATHANVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19050",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANGALAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19051",
            "district": "20",
            "Block": "335",
            "label": "ST.ANTONY'S MIDDLE SCHOOL,NAGARIKATHTHAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19052",
            "district": "20",
            "Block": "335",
            "label": "ST.MARY'S MIDDLE SCHOOL,  ORIKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19053",
            "district": "20",
            "Block": "335",
            "label": "ST. ARULANANDAR HIGHER SECONDARY SCHOOL, ORIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19054",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ILUPPAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19055",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, N.M.MANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19056",
            "district": "20",
            "Block": "335",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VISUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21410",
            "district": "20",
            "Block": "335",
            "label": "GOVERNMENT HIGH SCHOOL,  CHOLAGANPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21614",
            "district": "20",
            "Block": "335",
            "label": "ST. NORBERT R.C. MATRICULATION HIGH SCHOOL, THIRUVETRIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21859",
            "district": "20",
            "Block": "335",
            "label": "AMEER SULTHAN ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL, THONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "336": [
        {
            "key": "2036",
            "district": "25",
            "Block": "336",
            "label": "AMALRAJ MATRIC HSS THIRUVAIYARU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2051",
            "district": "25",
            "Block": "336",
            "label": "IMMACULATE MATRIC HS THIRUVAIYARU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3751",
            "district": "25",
            "Block": "336",
            "label": "PUMS-MAHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3860",
            "district": "25",
            "Block": "336",
            "label": "PUMS-VEERASINGAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4033",
            "district": "25",
            "Block": "336",
            "label": "HARIJAN AIDED MS-VELLAMPERAMBUR-E",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4053",
            "district": "25",
            "Block": "336",
            "label": "PUMS -VILANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4137",
            "district": "25",
            "Block": "336",
            "label": "SRI RAMA KRISHNA AIDED MIDDLE SCHOOL-THILLAISTHANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4198",
            "district": "25",
            "Block": "336",
            "label": "PUMS-THIRUPAZHANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4215",
            "district": "25",
            "Block": "336",
            "label": "ST.REETA AIDED MIDDLE SCHOOL-THIRUPPANTHURUTHI.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4832",
            "district": "25",
            "Block": "336",
            "label": "ADW MS-VENKADASAMUDRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5064",
            "district": "25",
            "Block": "336",
            "label": "PUMS-POONAVARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5940",
            "district": "25",
            "Block": "336",
            "label": "MAHATHMA MATRICULATION SCHOOL, NADUKAVERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6213",
            "district": "25",
            "Block": "336",
            "label": "GMS NADUKAVERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6480",
            "district": "25",
            "Block": "336",
            "label": "PUMS-SEMMANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16413",
            "district": "25",
            "Block": "336",
            "label": "AMS-SIVASAMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16414",
            "district": "25",
            "Block": "336",
            "label": "Sacred Heart Higher Secondary School  Michaelpatty",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16415",
            "district": "25",
            "Block": "336",
            "label": "GHS- VANARANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16416",
            "district": "25",
            "Block": "336",
            "label": "GHS-SATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16417",
            "district": "25",
            "Block": "336",
            "label": "GHS VALAPPAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16418",
            "district": "25",
            "Block": "336",
            "label": "ST.THOMAS RC HIGH SCHOOL -MANATHIDAL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16419",
            "district": "25",
            "Block": "336",
            "label": "GHS VARAGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16420",
            "district": "25",
            "Block": "336",
            "label": "PUMS ALLISAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16421",
            "district": "25",
            "Block": "336",
            "label": "GHS MOHAMED BUNDAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16422",
            "district": "25",
            "Block": "336",
            "label": "GHS KANDIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16423",
            "district": "25",
            "Block": "336",
            "label": "GHS ACHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16424",
            "district": "25",
            "Block": "336",
            "label": "ADW HSS-KEELAPUNALVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16425",
            "district": "25",
            "Block": "336",
            "label": "GHSS - MELATHIRUPPANTHURITHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16426",
            "district": "25",
            "Block": "336",
            "label": "ST.JOSEPH HS-THIRUVAIYARU-E",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16427",
            "district": "25",
            "Block": "336",
            "label": "GHSS(GIRLS) THIRUVAIYARU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16428",
            "district": "25",
            "Block": "336",
            "label": "SR.HSS-THIRUVAIYARU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21765",
            "district": "25",
            "Block": "336",
            "label": "SRI SRINIVASA HIGH SCHOOL-THIRUVAIYARU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24018",
            "district": "25",
            "Block": "336",
            "label": "GHS VARAGOOR",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "337": [
        {
            "key": "874",
            "district": "33",
            "Block": "337",
            "label": "SRI SATHYA SAI MAT. SCHOOL, PUDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1122",
            "district": "33",
            "Block": "337",
            "label": "PUMS, HARICHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1388",
            "district": "33",
            "Block": "337",
            "label": "PUMS, LAKSHMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1593",
            "district": "33",
            "Block": "337",
            "label": "PUMS, NALLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1723",
            "district": "33",
            "Block": "337",
            "label": "PUMS, CHINNAMANDALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1961",
            "district": "33",
            "Block": "337",
            "label": "VIDYA SETHRA, LAKSHMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2311",
            "district": "33",
            "Block": "337",
            "label": "PUMS, ATHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2386",
            "district": "33",
            "Block": "337",
            "label": "PUMS, CHINNAMMAPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2387",
            "district": "33",
            "Block": "337",
            "label": "PUMS, CHAKKARAMALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2612",
            "district": "33",
            "Block": "337",
            "label": "PUMS, RANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3183",
            "district": "33",
            "Block": "337",
            "label": "PUMS, VEERARAGAVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3445",
            "district": "33",
            "Block": "337",
            "label": "PUMS, NABALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3702",
            "district": "33",
            "Block": "337",
            "label": "PUMS, PAGASALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3853",
            "district": "33",
            "Block": "337",
            "label": "PUMS, KANCHIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4886",
            "district": "33",
            "Block": "337",
            "label": "PUMS, THOZHUDHAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5152",
            "district": "33",
            "Block": "337",
            "label": "PUMS, THAZHAVEDU H/C",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7039",
            "district": "33",
            "Block": "337",
            "label": "GOVT HSS, ARCOT KUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7040",
            "district": "33",
            "Block": "337",
            "label": "GHSS, ARUNGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7041",
            "district": "33",
            "Block": "337",
            "label": "GHS, CHIVADA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7042",
            "district": "33",
            "Block": "337",
            "label": "GHSS, KANAGAMMACHATRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7043",
            "district": "33",
            "Block": "337",
            "label": "GHS, KAVERIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7044",
            "district": "33",
            "Block": "337",
            "label": "GHS, MANAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7045",
            "district": "33",
            "Block": "337",
            "label": "GHS, NEMILI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7046",
            "district": "33",
            "Block": "337",
            "label": "GOVT ADW HS, PERIYAKALAKATTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7047",
            "district": "33",
            "Block": "337",
            "label": "GHSS, POONIMANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7048",
            "district": "33",
            "Block": "337",
            "label": "GHS, THUMBIKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7049",
            "district": "33",
            "Block": "337",
            "label": "GHSS, THIRUVALANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7050",
            "district": "33",
            "Block": "337",
            "label": "AISHIKA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22006",
            "district": "33",
            "Block": "337",
            "label": "ST. JOSEPH'S MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23609",
            "district": "33",
            "Block": "337",
            "label": "EVERGREEN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23609",
            "district": "33",
            "Block": "337",
            "label": "EVERGREEN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "338": [
        {
            "key": "419",
            "district": "33",
            "Block": "338",
            "label": "CHINNI SRIRAMALU CHEETY VIVEKANENDA VIDYALAYA  MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "427",
            "district": "33",
            "Block": "338",
            "label": "PUMS, AYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "448",
            "district": "33",
            "Block": "338",
            "label": "PUMS, ARANVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "474",
            "district": "33",
            "Block": "338",
            "label": "MUN. MS, NORTH RAJA ST, TRL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "476",
            "district": "33",
            "Block": "338",
            "label": "ST. ANNE'S MATRIC HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "503",
            "district": "33",
            "Block": "338",
            "label": "MUN. MS, PERIA KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "507",
            "district": "33",
            "Block": "338",
            "label": "RM JAIN VIDHYASHRAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "508",
            "district": "33",
            "Block": "338",
            "label": "PUMS, KARIKALAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "514",
            "district": "33",
            "Block": "338",
            "label": "PUMS, KALYANA KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "515",
            "district": "33",
            "Block": "338",
            "label": "PUMS, THOZHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "667",
            "district": "33",
            "Block": "338",
            "label": "BHARATHI MATRICULATION SCHOOL  RAJAJIPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "668",
            "district": "33",
            "Block": "338",
            "label": "SRI GNANABANU VIDYALAYA  MATRIC  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "684",
            "district": "33",
            "Block": "338",
            "label": "PUMS, ARANVOYAL KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "695",
            "district": "33",
            "Block": "338",
            "label": "RAJA NATIONAL  MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "846",
            "district": "33",
            "Block": "338",
            "label": "JACOB MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "865",
            "district": "33",
            "Block": "338",
            "label": "MUN. MS, NGGO COLONY, TRL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "884",
            "district": "33",
            "Block": "338",
            "label": "MUN. MS, LAKSHMIPURAM, TRL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "951",
            "district": "33",
            "Block": "338",
            "label": "GNANA VIDYALAYA MATRICULATION  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1031",
            "district": "33",
            "Block": "338",
            "label": "SRI RAM VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1061",
            "district": "33",
            "Block": "338",
            "label": "BHARATHIDASAN  MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1132",
            "district": "33",
            "Block": "338",
            "label": "PUMS, PERUMALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1134",
            "district": "33",
            "Block": "338",
            "label": "SREE MAHALAKSHMI VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1262",
            "district": "33",
            "Block": "338",
            "label": "PUMS, THANEERKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1332",
            "district": "33",
            "Block": "338",
            "label": "CALAVALA CUNNAN CHETTY''S HINDU MATRICULATION HIGHER SECONDARY SCHOOL, KAKKALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1707",
            "district": "33",
            "Block": "338",
            "label": "PUMS, KILANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1752",
            "district": "33",
            "Block": "338",
            "label": "KAMARAJ MATRICULATION SCHOOL, TIRUVALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1757",
            "district": "33",
            "Block": "338",
            "label": "SIDHAARTHA MATRIC HIGHER SECONDARY SCHOOL, SEVVAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1791",
            "district": "33",
            "Block": "338",
            "label": "SRI VENKATESWARA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1968",
            "district": "33",
            "Block": "338",
            "label": "PUMS, PUNNAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2336",
            "district": "33",
            "Block": "338",
            "label": "PUMS, AYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3055",
            "district": "33",
            "Block": "338",
            "label": "GOVT. WELFARE MS, PERUMALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3088",
            "district": "33",
            "Block": "338",
            "label": "PUMS, THALAKANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3150",
            "district": "33",
            "Block": "338",
            "label": "PUMS, VISHNUVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3157",
            "district": "33",
            "Block": "338",
            "label": "TELC MS, PERIA KUPPAM, TRL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3280",
            "district": "33",
            "Block": "338",
            "label": "CARMEL PUBLIC SCH,SEVVAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3287",
            "district": "33",
            "Block": "338",
            "label": "VIVEKANANDA VIDYAKALA ASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3625",
            "district": "33",
            "Block": "338",
            "label": "PUMS, PUTLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4296",
            "district": "33",
            "Block": "338",
            "label": "SRI ARAVINDAR MATRIC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4471",
            "district": "33",
            "Block": "338",
            "label": "GOVT. MODEL SCHOOL, TIRUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "5413",
            "district": "33",
            "Block": "338",
            "label": "SHREE NIKETAN MATRIC HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6747",
            "district": "33",
            "Block": "338",
            "label": "CSI HIGH SCHOOL, IKKADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "6748",
            "district": "33",
            "Block": "338",
            "label": "GOVT.HIGH.SCH.KILAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6749",
            "district": "33",
            "Block": "338",
            "label": "GOVT.HR.SEC.SCHOOL,KAKKALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6750",
            "district": "33",
            "Block": "338",
            "label": "GHS,MELAKONDAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6751",
            "district": "33",
            "Block": "338",
            "label": "GOVT.HIGH.SCHOOL,PAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6752",
            "district": "33",
            "Block": "338",
            "label": "SREE HARI SARADHA VIDYALAYA MATRICULATION SCHOOL , PAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23666",
            "district": "33",
            "Block": "338",
            "label": "TVIS Velammal Vidyalaya",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23667",
            "district": "33",
            "Block": "338",
            "label": "R.M.Jain Govt.Girls Hr.Sec.School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6753",
            "district": "33",
            "Block": "338",
            "label": "MAHAKAVI BHARATHIYAR HSS - KASUVA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6754",
            "district": "33",
            "Block": "338",
            "label": "PUMS, PULLARAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6755",
            "district": "33",
            "Block": "338",
            "label": "GOVT. WEL (B) HSS, SEVVAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6756",
            "district": "33",
            "Block": "338",
            "label": "GOVT. WEL (G) HSS, SEVVAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6757",
            "district": "33",
            "Block": "338",
            "label": "KURUNJI CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6758",
            "district": "33",
            "Block": "338",
            "label": "ABS VIDHYA MANDHIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6759",
            "district": "33",
            "Block": "338",
            "label": "GOVT.HR.SEC.SCHOOL,VELLIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6760",
            "district": "33",
            "Block": "338",
            "label": "GHS,VEPPAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6761",
            "district": "33",
            "Block": "338",
            "label": "GHS VILAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6762",
            "district": "33",
            "Block": "338",
            "label": "CSI GOUDIE HR SEC SCH, TRL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6763",
            "district": "33",
            "Block": "338",
            "label": "KMN&BROS MUNICIPAL HIGHER SEC ,SATHYAMURTHY STREET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6764",
            "district": "33",
            "Block": "338",
            "label": "DRBCCCH HR.SEC.SCHOOL,TIRUVALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6765",
            "district": "33",
            "Block": "338",
            "label": "MOTHER TERESA MEM SS,PERIYAKUP",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6766",
            "district": "33",
            "Block": "338",
            "label": "MUN. HR . SEC SCHOOL, TRL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6767",
            "district": "33",
            "Block": "338",
            "label": "SRI LAKSHMI HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6768",
            "district": "33",
            "Block": "338",
            "label": "GOVT(G)HR.SEC.SCHOOL,TRL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20891",
            "district": "33",
            "Block": "338",
            "label": "GUILD OF SERVICES BALAVIGHAR SPL SCH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20926",
            "district": "33",
            "Block": "338",
            "label": "GRACY SCH FOR MR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21360",
            "district": "33",
            "Block": "338",
            "label": "GOVT. GIRLS HS VELLIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21484",
            "district": "33",
            "Block": "338",
            "label": "ADW HS PUNGATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21717",
            "district": "33",
            "Block": "338",
            "label": "VELAMMAL ACADEMY MATRICULATION SCHOOL PAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21946",
            "district": "33",
            "Block": "338",
            "label": "SRI.R.M.JAIN VIDHYAPEETH MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21973",
            "district": "33",
            "Block": "338",
            "label": "ABS VIDHYAALAYAA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21983",
            "district": "33",
            "Block": "338",
            "label": "VIVEKANANDA VIDHYALAYA MATRIC",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23610",
            "district": "33",
            "Block": "338",
            "label": "TIRUVALLUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24019",
            "district": "33",
            "Block": "338",
            "label": "TIRUVALLUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "339": [
        {
            "key": "380",
            "district": "19",
            "Block": "339",
            "label": "MARY IMMACULATE MATRIC SCHOOL,K.RASIAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "831",
            "district": "19",
            "Block": "339",
            "label": "ADR MATRIC HR.SEC.SCHOOL,KAIKURICHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1369",
            "district": "19",
            "Block": "339",
            "label": "ABIRAMI MATRICULATION SCHOOL ALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4912",
            "district": "19",
            "Block": "339",
            "label": "MODERN MATRIC HR.SEC. SCHOOL ,ALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16987",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PATCHIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16988",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAPPANVIDUTHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16989",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,K.V.KOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16990",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KUMMANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16991",
            "district": "19",
            "Block": "339",
            "label": "ST.ANTONYS MIDDLE SCHOOL,ARASADIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16992",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,SIKKAPPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16993",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16994",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KEELATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16995",
            "district": "19",
            "Block": "339",
            "label": "ST.IGNATIOUS MATRIC HR.SEC.SCHOOL,VELLAKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16996",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VADAKKUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16997",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,VADAKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16998",
            "district": "19",
            "Block": "339",
            "label": "THANGAM MATRICULATION HIGHER SECONDARY  SCHOOL,VADAKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16999",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,PULLANVIDUTHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17000",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,THIRUVARANKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17001",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,NEDUVASAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17002",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,L.N.PURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17003",
            "district": "19",
            "Block": "339",
            "label": "GOVT BOYS HIGHER SECONDARY SCHOOL,KEERAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17004",
            "district": "19",
            "Block": "339",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL,KEERAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17005",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KASIMPUTHUPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17006",
            "district": "19",
            "Block": "339",
            "label": "GOVT.HIGH SCHOOL,KULAMANGALAM-NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17007",
            "district": "19",
            "Block": "339",
            "label": "GOVT.HIGH SCHOOL,KULAMANGALAM-SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17008",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,SENTHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17009",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,MANGADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17010",
            "district": "19",
            "Block": "339",
            "label": "PUMS, KOTHAMANGALAM PALAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17011",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL,KOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17012",
            "district": "19",
            "Block": "339",
            "label": "GOVT.GIRLS HR.SEC.SCHOOL KOTHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17013",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,SOORANVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17014",
            "district": "19",
            "Block": "339",
            "label": "SRI SUBA BHARATHI MATRIC SCHOOL,KALLALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17015",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,PALLATHIVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17016",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17017",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ,KALYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17018",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL,KOTHAKKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17019",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,MANJANVIDUTHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17020",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,MANGANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17021",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,VEPPANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17022",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL IMMANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17023",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUKKATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17024",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,METTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17025",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,KAIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17026",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL POOVARASAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17027",
            "district": "19",
            "Block": "339",
            "label": "RTA.GOVT.HIGHER SECONDARY SCHOOL,VALLATHIRAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17028",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATHAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17029",
            "district": "19",
            "Block": "339",
            "label": "ST.JOSEPH HR.SEC.SCHOOL,VENKADAKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17030",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,VENNAVALKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17031",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,ARAYAPPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17032",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL K.RASIAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17033",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SERIYALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17034",
            "district": "19",
            "Block": "339",
            "label": "P.P.M HIGH SCHOOL,KARAMBAKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "17035",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PANANKULAM-NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17036",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17037",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL S.KULAVAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17038",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT HIGH SCHOOL KEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "17039",
            "district": "19",
            "Block": "339",
            "label": "PUNITHA ARPUTHA MATHA MIDDLE SCHOOL,ALANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "17040",
            "district": "19",
            "Block": "339",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ALANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "17041",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL,ALANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17042",
            "district": "19",
            "Block": "339",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL,ALANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "17043",
            "district": "19",
            "Block": "339",
            "label": "HOLY CROSS MATRIC HIGHER SECONDARY SCHOOL,ALANGUDI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24020",
            "district": "19",
            "Block": "339",
            "label": "PUDUKKOTTAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "340": [
        {
            "key": "587",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS, NEW BHARATH MATRIC,TIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24125", "district":"35", 
            "Block":"340", "label": "K M G Aided Middle School", 
            "Sch_type": "Aided", "Category": "Middle School"
        },
        {
            "key": "663",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS RC FATHIMA THIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6364",
            "district": "35",
            "Block": "340",
            "label": "MMS ,VIJAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6622",
            "district": "35",
            "Block": "340",
            "label": "PUMS, SEMANGALAM.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6646",
            "district": "35",
            "Block": "340",
            "label": "PUMS ANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6687",
            "district": "35",
            "Block": "340",
            "label": "PUMS THENNAVARAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16135",
            "district": "35",
            "Block": "340",
            "label": "GHSS, ADIYAKKAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16136",
            "district": "35",
            "Block": "340",
            "label": "GHSS, ALIVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16137",
            "district": "35",
            "Block": "340",
            "label": "ADWHS , AMOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16138",
            "district": "35",
            "Block": "340",
            "label": "PUMS, KALLIKKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16139",
            "district": "35",
            "Block": "340",
            "label": "PUMS KALYANAMAHADEVI.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16140",
            "district": "35",
            "Block": "340",
            "label": "GHS,KOTTARAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16141",
            "district": "35",
            "Block": "340",
            "label": "UAHSS,THE MERIT SENTHAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16142",
            "district": "35",
            "Block": "340",
            "label": "PUMS KOODUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16143",
            "district": "35",
            "Block": "340",
            "label": "GHS MANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16144",
            "district": "35",
            "Block": "340",
            "label": "PUMS.ODACHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16145",
            "district": "35",
            "Block": "340",
            "label": "GHS PAZHAYAVALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16146",
            "district": "35",
            "Block": "340",
            "label": "GHSS, PUDHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16147",
            "district": "35",
            "Block": "340",
            "label": "PUMS PUTHUPATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16148",
            "district": "35",
            "Block": "340",
            "label": "GHSS, PULIVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16149",
            "district": "35",
            "Block": "340",
            "label": "UAMS,SRIKANCHI-SANKARA, PULIVALAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "16150",
            "district": "35",
            "Block": "340",
            "label": "PUMS  PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16151",
            "district": "35",
            "Block": "340",
            "label": "PUMS THANDALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16152",
            "district": "35",
            "Block": "340",
            "label": "PUMS, THIYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16153",
            "district": "35",
            "Block": "340",
            "label": "GHS, VILAMAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16154",
            "district": "35",
            "Block": "340",
            "label": "AMS SRI KRISHNA ,THAPPALAMPULIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16155",
            "district": "35",
            "Block": "340",
            "label": "PUMS THIRUKKARAVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16156",
            "district": "35",
            "Block": "340",
            "label": "PUMS KOMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16157",
            "district": "35",
            "Block": "340",
            "label": "GHSS, THIRUNEIPAIR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16158",
            "district": "35",
            "Block": "340",
            "label": "PUMS TIRUVATHIRAIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16159",
            "district": "35",
            "Block": "340",
            "label": "GHSS, SURANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16160",
            "district": "35",
            "Block": "340",
            "label": "GHS,VADAKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16161",
            "district": "35",
            "Block": "340",
            "label": "PUMS  VAIPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16162",
            "district": "35",
            "Block": "340",
            "label": "PUMS VEPPATHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16163",
            "district": "35",
            "Block": "340",
            "label": "AHSS, VELUDAYAR ,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16164",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS,KASTURBA ,TIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16165",
            "district": "35",
            "Block": "340",
            "label": "AMS-SS ,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16166",
            "district": "35",
            "Block": "340",
            "label": "ABHSS,VST, TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16167",
            "district": "35",
            "Block": "340",
            "label": "GHSS, KODIKKALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16168",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS,SAIRAM,TIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16169",
            "district": "35",
            "Block": "340",
            "label": "UAMHS ,NETHAJI SUBASH BOSE MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16170",
            "district": "35",
            "Block": "340",
            "label": "MMS,GOWRISAMY , THIRUVARUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16171",
            "district": "35",
            "Block": "340",
            "label": "AMS, SAIVAPRAGASA,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16172",
            "district": "35",
            "Block": "340",
            "label": "AGHSS,GRM,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16173",
            "district": "35",
            "Block": "340",
            "label": "MUNICIPAL  HIGH SCHOOL , MADAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16174",
            "district": "35",
            "Block": "340",
            "label": "UAMHSS, MEENATCHI MATRIC,TIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16175",
            "district": "35",
            "Block": "340",
            "label": "AMS KIDARAMKONDAN",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16176",
            "district": "35",
            "Block": "340",
            "label": "UAHSS SHUBHA VIDYALAYA MATRICULATION",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16177",
            "district": "35",
            "Block": "340",
            "label": "UAHSS-CBSE-TRINITY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16178",
            "district": "35",
            "Block": "340",
            "label": "AMS, MOSES",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16179",
            "district": "35",
            "Block": "340",
            "label": "AMS, RAMAN,TIRUVARUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21537",
            "district": "35",
            "Block": "340",
            "label": "AHS RC FATHIMA , THIRUVARUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22768",
            "district": "35",
            "Block": "340",
            "label": "CBSE RC Fathima public school,Thiruvarur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23287",
            "district": "35",
            "Block": "340",
            "label": "CBSE MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23627",
            "district": "35",
            "Block": "340",
            "label": "UAHS MAULANA ABULKALAM AZAD MATRICULATION , THIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23627",
            "district": "35",
            "Block": "340",
            "label": "UAHS MAULANA ABULKALAM AZAD MATRICULATION , THIRUVARUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24021",
            "district": "35",
            "Block": "340",
            "label": "PUMS THANDALAI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24022",
            "district": "35",
            "Block": "340",
            "label": "GHS,VADAKARAI",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "341": [
        {
            "key": "1286",
            "district": "11",
            "Block": "341",
            "label": "ST. THOMAS MARTHOMA MATRICULATION HIGHER SECONDARY SCHOOL, KOLLARAI, KULASEKHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23359",
            "district": "11",
            "Block": "341",
            "label": "Hari Sree Matriculation Higher Secondary school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1450",
            "district": "11",
            "Block": "341",
            "label": "MOUNT CARMEL MATRIC HIGHER SECONDARY SCHOOL KOLVEL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1559",
            "district": "11",
            "Block": "341",
            "label": "ISLAMIC  MODEL M.S-KULASEKHARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1740",
            "district": "11",
            "Block": "341",
            "label": "S.R.K.B.V. MAT. HSS., KULASEKHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2125",
            "district": "11",
            "Block": "341",
            "label": "GMS. ANDOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2242",
            "district": "11",
            "Block": "341",
            "label": "GMS. THOTTAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2288",
            "district": "11",
            "Block": "341",
            "label": "Calvary Matriculation Hr. Sec. School, Kolvel",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2502",
            "district": "11",
            "Block": "341",
            "label": "SEVENTH DAY M.HSS., PULIYERANKI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2618",
            "district": "11",
            "Block": "341",
            "label": "GMS. BARATHAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2682",
            "district": "11",
            "Block": "341",
            "label": "GOVT. TRMS MANALODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "2693",
            "district": "11",
            "Block": "341",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL KUTTAIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3039",
            "district": "11",
            "Block": "341",
            "label": "GMS. MUTHALAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3081",
            "district": "11",
            "Block": "341",
            "label": "GMS UNNIYOORKONAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3135",
            "district": "11",
            "Block": "341",
            "label": "GMS KUTTIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3372",
            "district": "11",
            "Block": "341",
            "label": "CSI MIDDLE SCHOOL MARAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3524",
            "district": "11",
            "Block": "341",
            "label": "LITTEL FLOWER M.S.C.M.S. ANAKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "3892",
            "district": "11",
            "Block": "341",
            "label": "GMS MATHAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3963",
            "district": "11",
            "Block": "341",
            "label": "LITTLE FLOWER MATRICULATION SCHOOL CHETTICHARVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4040",
            "district": "11",
            "Block": "341",
            "label": "Vedavyasa Matriculation Higher Secondary School Vellicode",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4143",
            "district": "11",
            "Block": "341",
            "label": "ST. SAHAYAMATHA M.S. EANCHACODE",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4187",
            "district": "11",
            "Block": "341",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL PUTHANKADAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4210",
            "district": "11",
            "Block": "341",
            "label": "ST. THOMAS MSCMS THEMANOOR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4272",
            "district": "11",
            "Block": "341",
            "label": "John Paul ll Matric Hr. Sec. School Kulasekharam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4309",
            "district": "11",
            "Block": "341",
            "label": "C.S.I MATRIC.HR.SEC.SCHOOL,KULASEKHARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4372",
            "district": "11",
            "Block": "341",
            "label": "GMS. KANNANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4693",
            "district": "11",
            "Block": "341",
            "label": "N.V.K.S. HIGHER SEC. SCHOOL  ATTOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5480",
            "district": "11",
            "Block": "341",
            "label": "GOOD SHEPHRED MS. VIRALIKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5635",
            "district": "11",
            "Block": "341",
            "label": "GMS. ITTAGAVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5726",
            "district": "11",
            "Block": "341",
            "label": "SMR NATIONAL SCHOOL, PULIERRANGY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6440",
            "district": "11",
            "Block": "341",
            "label": "EXCEL CENTRAL SCHOOL THIRUVATTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20258",
            "district": "11",
            "Block": "341",
            "label": "GHS VILLUNNIKONAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20259",
            "district": "11",
            "Block": "341",
            "label": "YETTACODE HSS. YETTACODE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20260",
            "district": "11",
            "Block": "341",
            "label": "GHS. CHERUKOLE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20261",
            "district": "11",
            "Block": "341",
            "label": "GHS.POOVANCODE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20262",
            "district": "11",
            "Block": "341",
            "label": "M.V. H.S. CHENKODI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20263",
            "district": "11",
            "Block": "341",
            "label": "GHS THACHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20264",
            "district": "11",
            "Block": "341",
            "label": "GHS. CHERUPPALOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20265",
            "district": "11",
            "Block": "341",
            "label": "GHS. THIRUVARAMBU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20266",
            "district": "11",
            "Block": "341",
            "label": "GHS. THIRUVATTAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20267",
            "district": "11",
            "Block": "341",
            "label": "ARUNACHALAM HSS., THIRUVATTAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20268",
            "district": "11",
            "Block": "341",
            "label": "GHS KESAVAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20269",
            "district": "11",
            "Block": "341",
            "label": "DEVASAM H.S. THIRPARAPPU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20270",
            "district": "11",
            "Block": "341",
            "label": "GOVT. TRIBAL RESIDENTIAL HSS PECHIPPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20271",
            "district": "11",
            "Block": "341",
            "label": "ST. PETER'S HSS. CHEKKAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20272",
            "district": "11",
            "Block": "341",
            "label": "GHS. PONMANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20273",
            "district": "11",
            "Block": "341",
            "label": "GOVT HIGH SCHOOL MANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20274",
            "district": "11",
            "Block": "341",
            "label": "GHSS. KULASEKARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20275",
            "district": "11",
            "Block": "341",
            "label": "GHS. VEEYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20276",
            "district": "11",
            "Block": "341",
            "label": "GHS. ERAVIPUTHOORKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20277",
            "district": "11",
            "Block": "341",
            "label": "GHSS KATTATHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20278",
            "district": "11",
            "Block": "341",
            "label": "ST. JOSEPH'S HIGH SCHOOL MULAVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20279",
            "district": "11",
            "Block": "341",
            "label": "ST. JOSEPH'S HIGH SCHOOL PILANKALAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20280",
            "district": "11",
            "Block": "341",
            "label": "AIDED HS. KATTATHURAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20281",
            "district": "11",
            "Block": "341",
            "label": "BETHEL BLOOMS N&PS-AZHAHIAMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20282",
            "district": "11",
            "Block": "341",
            "label": "GHS. THIRUNANTHIKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20283",
            "district": "11",
            "Block": "341",
            "label": "ST. URSULA'S GIRLS HSS KULASEKHARAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20284",
            "district": "11",
            "Block": "341",
            "label": "SBJ VIDYA BHAVAN INTERNATIONAL SCHOOL-PULIYERANGI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20285",
            "district": "11",
            "Block": "341",
            "label": "ST. ANTONY'S HS. SURULACODE",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20286",
            "district": "11",
            "Block": "341",
            "label": "GHS KOTHAYAR LOWER CAMP",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21188",
            "district": "11",
            "Block": "341",
            "label": "EXCEL GLOBAL SCHOOL-THIRUVATTAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21623",
            "district": "11",
            "Block": "341",
            "label": "ARKA-THE GREEN SCHOOL-ARUVIKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21816",
            "district": "11",
            "Block": "341",
            "label": "TRINITY CENTRAL SCHOOL-CHITHRENCODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21817",
            "district": "11",
            "Block": "341",
            "label": "SRK INTERNATIONAL SCHOOL-KULASEKARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21907",
            "district": "11",
            "Block": "341",
            "label": "ASSISI VIDHYALAYA SCHOOL FOR D.ABLED-CHENAMCODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22851",
            "district": "11",
            "Block": "341",
            "label": "SDA ENGLISH HIGH SCHOOL, PULIERRANGHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23084",
            "district": "11",
            "Block": "341",
            "label": "THRAYAMBIKA VIDYA MANDIR, PADANILAM, KULASEKHARAM.",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23491",
            "district": "11",
            "Block": "341",
            "label": "GTR HIGH SCHOOL -MANALODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23491",
            "district": "11",
            "Block": "341",
            "label": "GTR HIGH SCHOOL -MANALODAI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "342": [
        {
            "key": "5564",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THULANGAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10550",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL ARUNGURUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10551",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL OTTANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10552",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10553",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL PERIYASEVALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10554",
            "district": "37",
            "Block": "342",
            "label": "CHENGALRAYAN CO-OPERATIVE SUGAR MILLS MATRICULATION SCHOOL PERIYASEVALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10555",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SARAVANAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10556",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  ANAIVARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10557",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARUMPATTU SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10558",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SITHANANGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10559",
            "district": "37",
            "Block": "342",
            "label": "St.JOHN'S HIGHER  SECONDARY SCHOOL SITHANANGOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10560",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MAMANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10561",
            "district": "37",
            "Block": "342",
            "label": "ARUTKODI AIDED MIDDLE SCHOOL ARUL NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10562",
            "district": "37",
            "Block": "342",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL THIRUVENNAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10563",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PENNAIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10564",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  AMAVASAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10565",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAVANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10566",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL T.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10567",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PERANGIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10568",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  EDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10569",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  SITHALINGAMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10570",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL  SITHALINGAMADAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10571",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ERUVELPATTU COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10572",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KARAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10573",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELTHANIYALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10574",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ANATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10575",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ARASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10576",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KANTHALAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10577",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANDARAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10578",
            "district": "37",
            "Block": "342",
            "label": "KASTURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL ANDARAYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10579",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL  T.EDAIYAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10580",
            "district": "37",
            "Block": "342",
            "label": "CHRIST THE KING MATRICULATION HIGHER SECONDARY SCHOOL, METTUKUPPAM, ELANDURAI VILLAGE- 607209",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10581",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  MANAKKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10582",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHINNASEVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10583",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THIRUVENNAINALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10584",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  THIRUVENNAINALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10585",
            "district": "37",
            "Block": "342",
            "label": "BONNE NEHRU HIGHER SECONDARY SCHOOL THIRUVENNAINALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10586",
            "district": "37",
            "Block": "342",
            "label": "GANDHI MEMORIAL HIGHER SECONDARY SCHOOL THIRUVENNAINALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10587",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL  THADUTHATKONDOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10588",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MAZHAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10589",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL EMAPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10590",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL  SIRUMADURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10591",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ENATHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10592",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIRUVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10593",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SEMMAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10594",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  MELAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10595",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  VALAIYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10899",
            "district": "37",
            "Block": "342",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARUVEPPILAIAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21202",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL EMAPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22102",
            "district": "37",
            "Block": "342",
            "label": "GOVERNMENT HIGH SCHOOL T.KOLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22795",
            "district": "37",
            "Block": "342",
            "label": "E.M.SUBRAMANI REDDIYAR VIVEKANANDA VIDYALAYA CBSE SCHOOL ERUVELPATTU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "343": [
        {
            "key": "1184",
            "district": "29",
            "Block": "343",
            "label": "JAMAL MOHAMED COLLEGE AYESHA GIRLS MATRIC HR SEC SCHOOL,KAJANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1241",
            "district": "29",
            "Block": "343",
            "label": "AROCKIAMATHA MATRIC HR SEC SCHOOL, KARUMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1354",
            "district": "29",
            "Block": "343",
            "label": "CHELLAMMAL MATRIC. BOYS HR SEC SCHOOL, VENGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2197",
            "district": "29",
            "Block": "343",
            "label": "BHARATHI MATRIC HR SEC SCHOOL, KK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2466",
            "district": "29",
            "Block": "343",
            "label": "SANTA MARIA MATRICULATION HIGHER SECONDARY SCHOOL, CRAWFORD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3738",
            "district": "29",
            "Block": "343",
            "label": "OM MARUTHI MATRICULATION HIGH SCHOOL,KARUMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3796",
            "district": "29",
            "Block": "343",
            "label": "PERIYAR CENT MEM MAT HR SEC SCHOOL, PERIYAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3861",
            "district": "29",
            "Block": "343",
            "label": "ST. LITTLE FLOWER MATRIC HIGHER SECONDARY SCHOOL, KATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3862",
            "district": "29",
            "Block": "343",
            "label": "VVJMPV MATRIC HR SEC SCHOOL , POLICE COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4065",
            "district": "29",
            "Block": "343",
            "label": "CSI MATRIC HIGH SCHOOL ,PONMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4321",
            "district": "29",
            "Block": "343",
            "label": "YWCA MAT.HIGH SCHOOL ., ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4337",
            "district": "29",
            "Block": "343",
            "label": "HAPF PARAMAHAMSA MAT.HIGH SCHOOL,HAPP",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4642",
            "district": "29",
            "Block": "343",
            "label": "BHEL MATRIC HR SEC SCHOOL, KAILASAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4757",
            "district": "29",
            "Block": "343",
            "label": "St. Joseph Matriculation Higher Secondary School, VOC Nagar, Thuvakudimalai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4803",
            "district": "29",
            "Block": "343",
            "label": "CHELLAMMAL MAT.HSS CRAWFORD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4822",
            "district": "29",
            "Block": "343",
            "label": "St.ANTHONY'S MAT HIGH SCHOOL ,RENGA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4874",
            "district": "29",
            "Block": "343",
            "label": "St.George Montessori Higher Secondary School,Anbil Nagar,Trichy",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4966",
            "district": "29",
            "Block": "343",
            "label": "INFANT JESUS MAT HIGH SCHOOL, PONMALAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5372",
            "district": "29",
            "Block": "343",
            "label": "JEGAN MATHA MATRIC HSS SCHOOL,K.K.NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5837",
            "district": "29",
            "Block": "343",
            "label": "GOOD SHEPHERD MAT HR SEC SCHOOL , KK NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6627",
            "district": "29",
            "Block": "343",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC HIGH SCHOOL, AYYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14481",
            "district": "29",
            "Block": "343",
            "label": "GHSS , ARASANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14482",
            "district": "29",
            "Block": "343",
            "label": "MONTFORT SCHOOL, KATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14483",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,GUNDUR BURMACOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14484",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANTHALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14485",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,KEELAKALKANDARKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14486",
            "district": "29",
            "Block": "343",
            "label": "GOVT HIGH SCHOOL, M.K.KOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14487",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, KEELA MULLAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14488",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, KILIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14489",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, VEETHIVADANGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14490",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, POLICE COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14491",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, NAVALPATTU SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14492",
            "district": "29",
            "Block": "343",
            "label": "OFT HIGH SCHOOL, OFT",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "14493",
            "district": "29",
            "Block": "343",
            "label": "GOVT BOYS HIGH SCHOOL, ANNA NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14494",
            "district": "29",
            "Block": "343",
            "label": "GOVT GIRLS HIGH SCHOOL, ANNA NAGAR, TRICHY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14495",
            "district": "29",
            "Block": "343",
            "label": "KENDRIYA VIDHALAYA -I-OFT",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14496",
            "district": "29",
            "Block": "343",
            "label": "OFT HR SEC SCHOOL , OFT ESTATE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14497",
            "district": "29",
            "Block": "343",
            "label": "GOVT HR SEC SCHOOL, THENERIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14498",
            "district": "29",
            "Block": "343",
            "label": "KV- II-HAPP",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14499",
            "district": "29",
            "Block": "343",
            "label": "GOVT HIGH SCHOOL, POOLANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14500",
            "district": "29",
            "Block": "343",
            "label": "ST.PHILOMINAS MID SCHOOL,KATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14501",
            "district": "29",
            "Block": "343",
            "label": "GOVT ADW BOYS HR SEC SCHOOL, KATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14502",
            "district": "29",
            "Block": "343",
            "label": "GOVT ADW GIRLS HR SEC SCHOOL, KATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14503",
            "district": "29",
            "Block": "343",
            "label": "St.Antony's Higher Secondary School, Kattur,Trichy-19",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14504",
            "district": "29",
            "Block": "343",
            "label": "ST.MARY'S HIGH SCHOOL, PATHALAPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14505",
            "district": "29",
            "Block": "343",
            "label": "GOVT ADW HIGHER SECONDARY SCHOOL, SOLAMADEVI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14506",
            "district": "29",
            "Block": "343",
            "label": "NATHAN MID SCHOOL, PERIYASOORIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14507",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNON MID SCHOOL,CHINNASURIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14508",
            "district": "29",
            "Block": "343",
            "label": "GOVT HIGH SCHOOL, THIRUNEDUNGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14509",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,VALAVANDANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14510",
            "district": "29",
            "Block": "343",
            "label": "ST.JOSEPH HIGH SCHOOL, AYYAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14511",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, VENGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14512",
            "district": "29",
            "Block": "343",
            "label": "GOVT HR SEC SCHOOL, THUVAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14513",
            "district": "29",
            "Block": "343",
            "label": "REC.,MID.SCHOOL, THUVAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14514",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, THUVAKUDIMALAI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14515",
            "district": "29",
            "Block": "343",
            "label": "INFANT JESUS GIRLS HR SEC SCHOOL, THUVAKUDI MALAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14516",
            "district": "29",
            "Block": "343",
            "label": "RSK HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14517",
            "district": "29",
            "Block": "343",
            "label": "SARATHA MIDDLE SCHOOL, KAMARAJAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14518",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, KOOTHAIPAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14519",
            "district": "29",
            "Block": "343",
            "label": "ST.JOSEPH MID SCHOOL, KAILASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14520",
            "district": "29",
            "Block": "343",
            "label": "BOILER PLANT MID SCHOOL, KAILASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14521",
            "district": "29",
            "Block": "343",
            "label": "BOILER PLANT GIRLS HR SEC SCHOOL, KAILASAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14522",
            "district": "29",
            "Block": "343",
            "label": "BOILER PLANT BOYS HR SEC SCHOOL, KAILASAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14523",
            "district": "29",
            "Block": "343",
            "label": "BHEL TAMIL MEDIUM M S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14524",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL THIRUVERUMBUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14525",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,BAGAVATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14526",
            "district": "29",
            "Block": "343",
            "label": "MUKKULATHORE HR SE SCHOOL, THIRUVERUMBUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14527",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL, UKKADAI ARIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14528",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ARIYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14529",
            "district": "29",
            "Block": "343",
            "label": "R.C.MID.SCHOOL, ARIYAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14530",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14531",
            "district": "29",
            "Block": "343",
            "label": "PANCHAYAT UNION MID SCHOOL,THANGESWARI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14532",
            "district": "29",
            "Block": "343",
            "label": "GOVT.HIGH.SCHOOL ,AMBIKAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14533",
            "district": "29",
            "Block": "343",
            "label": "SACRED HEART HSS,PONMALAIPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14534",
            "district": "29",
            "Block": "343",
            "label": "ST MARY'S MS MELEKAKONDAR KOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14535",
            "district": "29",
            "Block": "343",
            "label": "HOLY CROSS GIRLS HR SEC SCHOOL, PONMALAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14536",
            "district": "29",
            "Block": "343",
            "label": "BRINDAVAN VIDYALAYA MATRICULATION HR.SEC. SCHOOL, AMBIKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14537",
            "district": "29",
            "Block": "343",
            "label": "THE BRITISH ENGLISH MEDIUM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14538",
            "district": "29",
            "Block": "343",
            "label": "PERIYAR MANIAMMAI GIRLS HR SEC SCHOOL, PERIYAR NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14539",
            "district": "29",
            "Block": "343",
            "label": "LITTLE FLOWER HR SEC SCHOOL,CRAWFORD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14540",
            "district": "29",
            "Block": "343",
            "label": "KAJAMIAN HR SEC SCHOOL , KHAJANAGAR, TRICHY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14541",
            "district": "29",
            "Block": "343",
            "label": "ST.MARY'S M.S. MANNARPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14542",
            "district": "29",
            "Block": "343",
            "label": "RAILWAY MIXED HR SEC SCHOOL,PONMALAI",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "14543",
            "district": "29",
            "Block": "343",
            "label": "SAMADH HIGHER SECONDARY SCHOOL, KHAJA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14544",
            "district": "29",
            "Block": "343",
            "label": "EQUITAS GURUKUL MATRIC SCHOOL,KATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21163",
            "district": "29",
            "Block": "343",
            "label": "CHELLAMMAL MATRIC GIRLS HR SEC SCHOOL VENGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21327",
            "district": "29",
            "Block": "343",
            "label": "CHELLAMMAL CBSE SCHOOL VENGUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21964",
            "district": "29",
            "Block": "343",
            "label": "SHRINE VAILANKANNI MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22188",
            "district": "29",
            "Block": "343",
            "label": "MATHA MATRICULATION SCHOOL,M.K.KOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22529",
            "district": "29",
            "Block": "343",
            "label": "BVM GLOBAL TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22530",
            "district": "29",
            "Block": "343",
            "label": "KENDRIYA VIDYALAYA GOLDEN ROCK (SR) TRICHY",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "22804",
            "district": "29",
            "Block": "343",
            "label": "TIRUCHY PUBLIC SCHOOL ,THUVAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23136",
            "district": "29",
            "Block": "343",
            "label": "WISDOM WEALTH INTERNATION ICSE SCHOOL, MORAI CITY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "344": [
        {
            "key": "1843",
            "district": "25",
            "Block": "344",
            "label": "THE NEW LIGHT MATRICULATION SCHOOL-  THIRUVIDAIMARUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2298",
            "district": "25",
            "Block": "344",
            "label": "STAR MATRIC HR. SEC. SCHOOL -THIRUBUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2379",
            "district": "25",
            "Block": "344",
            "label": "SOWRASTRA MATRIC HS-THIRUBUVANAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2543",
            "district": "25",
            "Block": "344",
            "label": "PKS MATRICULATION SCHOOL -THIRUNARAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2852",
            "district": "25",
            "Block": "344",
            "label": "RAJAMANI MATRICULATION SCHOOL, THIRUNAGESWARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2965",
            "district": "25",
            "Block": "344",
            "label": "PUMS - SIVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3300",
            "district": "25",
            "Block": "344",
            "label": "THICOSILKS MATRIC HR SEC SCHOOL-THIRUBUVANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3490",
            "district": "25",
            "Block": "344",
            "label": "PUMS - THIRUVISANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3639",
            "district": "25",
            "Block": "344",
            "label": "AL KHAREEM MATRICULATION  SCHOOL-MURUKKANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3807",
            "district": "25",
            "Block": "344",
            "label": "ANURADHA MAHA VIDYALAYA  MATRICULATION HIGHER SECONDARY SCHOOL  -NALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3939",
            "district": "25",
            "Block": "344",
            "label": "PUMS - MANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3992",
            "district": "25",
            "Block": "344",
            "label": "PUMS - SATHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4027",
            "district": "25",
            "Block": "344",
            "label": "PUMS - AMMANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4088",
            "district": "25",
            "Block": "344",
            "label": "PUMS - AYYAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4179",
            "district": "25",
            "Block": "344",
            "label": "ADWMS - S.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4307",
            "district": "25",
            "Block": "344",
            "label": "PUMS - THIRUMANGALAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4417",
            "district": "25",
            "Block": "344",
            "label": "AIDED  MIDDLE SCHOOL - NAGARASAMPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4600",
            "district": "25",
            "Block": "344",
            "label": "PUMS - KURICHIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4732",
            "district": "25",
            "Block": "344",
            "label": "RAMAKRISHNA AIDED  MIDDLE SCHOOL - NACHIYARKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4818",
            "district": "25",
            "Block": "344",
            "label": "THIAGARAJAR AIDED  MIDDLE SCHOOL - SANNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5056",
            "district": "25",
            "Block": "344",
            "label": "AIDED  MIDDLE SCHOOL - VEPPATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6083",
            "district": "25",
            "Block": "344",
            "label": "GMS - THEPPERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6550",
            "district": "25",
            "Block": "344",
            "label": "PUMS - KALYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6688",
            "district": "25",
            "Block": "344",
            "label": "R.RAJARAMAN  MIDDLE SCHOOL-NARASINGANPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16596",
            "district": "25",
            "Block": "344",
            "label": "PUMS - ARIYACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16597",
            "district": "25",
            "Block": "344",
            "label": "ADWHS- NALLADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16598",
            "district": "25",
            "Block": "344",
            "label": "GHS - VANNAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16599",
            "district": "25",
            "Block": "344",
            "label": "ADWHS - THIRUMALAIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16600",
            "district": "25",
            "Block": "344",
            "label": "PUMS - MANJAMALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16601",
            "district": "25",
            "Block": "344",
            "label": "JAYAMKONDA MATHA AIDED MIDDLE SCHOOL-MATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16602",
            "district": "25",
            "Block": "344",
            "label": "GBHSS   - NACHIYARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16603",
            "district": "25",
            "Block": "344",
            "label": "S.PUDUR  HIGH SCHOOL  - S.PUDUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16604",
            "district": "25",
            "Block": "344",
            "label": "PUMS - MURUGANKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16605",
            "district": "25",
            "Block": "344",
            "label": "RKR GHSS - THIRUCHERAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16606",
            "district": "25",
            "Block": "344",
            "label": "MATHAR MANRAM HS-THIRUMANGALAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "16607",
            "district": "25",
            "Block": "344",
            "label": "GGHSS - NACHIYARKOIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16608",
            "district": "25",
            "Block": "344",
            "label": "GHS-THUKKATCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16609",
            "district": "25",
            "Block": "344",
            "label": "GHSS- THIRUNAGESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16610",
            "district": "25",
            "Block": "344",
            "label": "AIDED  MIDDLE SCHOOL - ADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16611",
            "district": "25",
            "Block": "344",
            "label": "GHSS - ADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16612",
            "district": "25",
            "Block": "344",
            "label": "SRI KGS  HIGHER SECONDARY SCHOOL- ADUTHURAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16613",
            "district": "25",
            "Block": "344",
            "label": "RICE CITY MATRIC. HIGHER SECONDARY SCHOOL - ADUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16614",
            "district": "25",
            "Block": "344",
            "label": "THE CRESCENT HIGHER SECONDARY SCHOOL - AVANIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16615",
            "district": "25",
            "Block": "344",
            "label": "GHSS - THIRUBUVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16616",
            "district": "25",
            "Block": "344",
            "label": "THIRUVAVADUTHURAI ADHEENAM AMBALAVANA DESIGAR AIDED MIDDLE SCHOOL -THIRUVIDAIMARUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16617",
            "district": "25",
            "Block": "344",
            "label": "THIRUVAVADUTHURAI ADHEENAM HIGHER SECONDARY SCHOOL - THIRVIDAIMARUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16618",
            "district": "25",
            "Block": "344",
            "label": "ST  JOSEPHS  HIGH SCHOOL - AMMANPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22226",
            "district": "25",
            "Block": "344",
            "label": "MAHARISHI VIDYA MANDIR CBSE SCHOOL, AMMACHATRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "345": [
        {
            "key": "2319",
            "district": "25",
            "Block": "345",
            "label": "PUMS-KADUVETTI VIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3816",
            "district": "25",
            "Block": "345",
            "label": "SRI KRISHNA MATRIC-P.VIDUTHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4581",
            "district": "25",
            "Block": "345",
            "label": "PUMS-POYYUNDAR KUDIKKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5892",
            "district": "25",
            "Block": "345",
            "label": "PUMS-THIPPAN VIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5938",
            "district": "25",
            "Block": "345",
            "label": "PERIYAR MATRICULATION HIGH SCHOOL-VETTIKKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16495",
            "district": "25",
            "Block": "345",
            "label": "GHS-ATHAMBAI (NORTH)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16496",
            "district": "25",
            "Block": "345",
            "label": "PUMS-ATHAMBAI(SOUTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16497",
            "district": "25",
            "Block": "345",
            "label": "PUMS-AKKARAIVATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16498",
            "district": "25",
            "Block": "345",
            "label": "GHSS- ANANDAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16499",
            "district": "25",
            "Block": "345",
            "label": "PUMS-SOLAGANKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16500",
            "district": "25",
            "Block": "345",
            "label": "GHS-SIVAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16501",
            "district": "25",
            "Block": "345",
            "label": "PUMS-KANDIYANATCHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16502",
            "district": "25",
            "Block": "345",
            "label": "PUMS-PILLUVETTUVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16503",
            "district": "25",
            "Block": "345",
            "label": "GHSS-THIRUVONAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16504",
            "district": "25",
            "Block": "345",
            "label": "NAVINA HS-PILLUVETTUVIDUTHUI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16505",
            "district": "25",
            "Block": "345",
            "label": "GHS-KAYAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16506",
            "district": "25",
            "Block": "345",
            "label": "GHS-KILAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16507",
            "district": "25",
            "Block": "345",
            "label": "PUMS-KOLLUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16508",
            "district": "25",
            "Block": "345",
            "label": "PUMS-NEYVELI(NORTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16509",
            "district": "25",
            "Block": "345",
            "label": "GHSS-NEYVELI(SOUTH)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16510",
            "district": "25",
            "Block": "345",
            "label": "JOTHI AIDED MS-URANIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16511",
            "district": "25",
            "Block": "345",
            "label": "GHSS-URANIPURAM(VETTUVAKOTTAI)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16512",
            "district": "25",
            "Block": "345",
            "label": "GHS-KATTATHI UNJIYAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16513",
            "district": "25",
            "Block": "345",
            "label": "GOVT MODEL  HR. SEC. SCHOOL -VENGARAI KOTTAIKKADU -614628",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16514",
            "district": "25",
            "Block": "345",
            "label": "GHSS-PINNAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16515",
            "district": "25",
            "Block": "345",
            "label": "RANI  MARIMUTHU MATRIC HS-PINNAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16516",
            "district": "25",
            "Block": "345",
            "label": "GHS-SANKARANATHAR KUDIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16517",
            "district": "25",
            "Block": "345",
            "label": "GHS-SENNIYAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16518",
            "district": "25",
            "Block": "345",
            "label": "GHS-SILLATHUR(NORTH)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16519",
            "district": "25",
            "Block": "345",
            "label": "GHS-THALIGAIVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16520",
            "district": "25",
            "Block": "345",
            "label": "PUMS-THIRUNALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16521",
            "district": "25",
            "Block": "345",
            "label": "PUMS-PATHUPULIVIDUTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16522",
            "district": "25",
            "Block": "345",
            "label": "PUMS-VENGARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16523",
            "district": "25",
            "Block": "345",
            "label": "GHSS-VETTIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16524",
            "district": "25",
            "Block": "345",
            "label": "PUMS-VETTUVAKKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21476",
            "district": "25",
            "Block": "345",
            "label": "GHS - KARIYAVIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21477",
            "district": "25",
            "Block": "345",
            "label": "GHS - UNIJI VIDUTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23565",
            "district": "25",
            "Block": "345",
            "label": "NALDHAVAN MATRICULATION SCHOOL- URANIPURAM ",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23565",
            "district": "25",
            "Block": "345",
            "label": "NALDHAVAN MATRICULATION SCHOOL- URANIPURAM ",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "346": [
        {
            "key": "2516",
            "district": "1",
            "Block": "346",
            "label": "SWAMI GIRLS MATRIC HR SEC SCHOOL KEELAPALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4750",
            "district": "1",
            "Block": "346",
            "label": "PUMS SATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4856",
            "district": "1",
            "Block": "346",
            "label": "PUMS KALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5422",
            "district": "1",
            "Block": "346",
            "label": "PUMS THIRUVENGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15082",
            "district": "1",
            "Block": "346",
            "label": "GHSS THIRUMAZHAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15083",
            "district": "1",
            "Block": "346",
            "label": "GHS KANDIRATHEETHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15084",
            "district": "1",
            "Block": "346",
            "label": "PUMS VILUPPANANKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15085",
            "district": "1",
            "Block": "346",
            "label": "PUMS PALAYAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15086",
            "district": "1",
            "Block": "346",
            "label": "PUMS-KEELAESANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15087",
            "district": "1",
            "Block": "346",
            "label": "VEERAMAMUNIVAR MIDDLE.SCHOOL ELAKKURICHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15088",
            "district": "1",
            "Block": "346",
            "label": "GOVERNMENT MODEL HR.SEC SCHOOL ELAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15089",
            "district": "1",
            "Block": "346",
            "label": "PUMS SENGARAYANKATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15090",
            "district": "1",
            "Block": "346",
            "label": "PUMS ALAGIYAMANAVALAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15091",
            "district": "1",
            "Block": "346",
            "label": "UC MIDDLESCHOOL (AIDED) PUDUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15092",
            "district": "1",
            "Block": "346",
            "label": "ST. IGNATIUS KULAMANICKAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15093",
            "district": "1",
            "Block": "346",
            "label": "GHSS ELANDAIKUDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15094",
            "district": "1",
            "Block": "346",
            "label": "GHSS KAMARASAVALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15095",
            "district": "1",
            "Block": "346",
            "label": "GHS RAMANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15096",
            "district": "1",
            "Block": "346",
            "label": "GHSS KOVILUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15097",
            "district": "1",
            "Block": "346",
            "label": "GHSS KURUVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15098",
            "district": "1",
            "Block": "346",
            "label": "GHS THOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15099",
            "district": "1",
            "Block": "346",
            "label": "PUMS CHINNAPATTAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15100",
            "district": "1",
            "Block": "346",
            "label": "PUMS VILAGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15101",
            "district": "1",
            "Block": "346",
            "label": "GHS VENGANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15102",
            "district": "1",
            "Block": "346",
            "label": "PUMS KORATHAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15103",
            "district": "1",
            "Block": "346",
            "label": "PUMS ANGIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15104",
            "district": "1",
            "Block": "346",
            "label": "GHS SANNAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15105",
            "district": "1",
            "Block": "346",
            "label": "GHS PALINGANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15106",
            "district": "1",
            "Block": "346",
            "label": "PUMS KARAYAVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15107",
            "district": "1",
            "Block": "346",
            "label": "GHSS KEELAKAVATTANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15108",
            "district": "1",
            "Block": "346",
            "label": "PUMS SENAPATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15109",
            "district": "1",
            "Block": "346",
            "label": "GHS KEELAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15110",
            "district": "1",
            "Block": "346",
            "label": "PUMS POONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15111",
            "district": "1",
            "Block": "346",
            "label": "ST. IGNATIUS HIGH SCHOOL  KOKKUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15112",
            "district": "1",
            "Block": "346",
            "label": "GHS VARANAVASI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15113",
            "district": "1",
            "Block": "346",
            "label": "GHSS KEELAPALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15114",
            "district": "1",
            "Block": "346",
            "label": "ARUNACHALA MATRICULATION SCHOOL KEELAPALUVUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15115",
            "district": "1",
            "Block": "346",
            "label": "PUMS PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15116",
            "district": "1",
            "Block": "346",
            "label": "GHS VETRIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15117",
            "district": "1",
            "Block": "346",
            "label": "PUMS THIRUPEYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15118",
            "district": "1",
            "Block": "346",
            "label": "PUMS VADUGAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15119",
            "district": "1",
            "Block": "346",
            "label": "GHSS KEELAKOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15120",
            "district": "1",
            "Block": "346",
            "label": "GHSS THIRUMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15121",
            "district": "1",
            "Block": "346",
            "label": "PUMS VETTAKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15122",
            "district": "1",
            "Block": "346",
            "label": "GHS KOVIL ESANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21799",
            "district": "1",
            "Block": "346",
            "label": "SWAMI MATRIC HR SEC SCHOOL KEELAPALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22547",
            "district": "1",
            "Block": "346",
            "label": "GHS AYANSUTHAMALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22910",
            "district": "1",
            "Block": "346",
            "label": "GHS ERAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23429",
            "district": "1",
            "Block": "346",
            "label": "ARIYALUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24023",
            "district": "1",
            "Block": "346",
            "label": "GHSS KOVILUR",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24024",
            "district": "1",
            "Block": "346",
            "label": "PUMS PUTHUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24025",
            "district": "1",
            "Block": "346",
            "label": "PUMS THIRUPEYAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24026",
            "district": "1",
            "Block": "346",
            "label": "PUMS VADUGAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24027",
            "district": "1",
            "Block": "346",
            "label": "ARIYALUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "347": [
        {
            "key": "3746",
            "district": "9",
            "Block": "347",
            "label": "AIDED MIDDLE SCHOOL THIMMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5116",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL UDHAYAMAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5807",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL CHINNAMAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5810",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PORASAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6549",
            "district": "9",
            "Block": "347",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL MADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6568",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VENGAIVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10815",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PRITHIVIMANGALAM (HINDU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10816",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PRITHIVIMANGALAM (COLONY)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10817",
            "district": "9",
            "Block": "347",
            "label": "MOUNT PARK HIGHER SECONDARY SCHOOL,  THIYAGADURGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10818",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL THIYAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10819",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VILAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10820",
            "district": "9",
            "Block": "347",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL KUDIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10821",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10822",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KUTHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10823",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EYYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10824",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL GURUPEEDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10825",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT MIDDLE SCHOOL ERANJI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10826",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ASAKALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10827",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL S.OHAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10828",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NAGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10829",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VIRUGAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10830",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL VIRUGAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10831",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PUDU UCHIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10832",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL KONGARAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10833",
            "district": "9",
            "Block": "347",
            "label": "KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL MUDIYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10834",
            "district": "9",
            "Block": "347",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10835",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL PEELAMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10836",
            "district": "9",
            "Block": "347",
            "label": "KASTHURBA GANDHI BALIKA VIDHALAYA RESIDENTIAL SCHOOL PALLAGACHERRY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10837",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL PALLAGACHERRY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10838",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL THIYAGADURGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10839",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL THIYAGADURGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10840",
            "district": "9",
            "Block": "347",
            "label": "VASAVI MATRICULATION SCHOOL THIYAGADURGAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "10841",
            "district": "9",
            "Block": "347",
            "label": "MOUNT PARK SPECIAL ACADEMY  MATRIC HIGHER SECONDARY SCHOOL PERIYAMAMPATTU , THIYAGADURGAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22105",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL SITHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22432",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL  VADATHORASALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22433",
            "district": "9",
            "Block": "347",
            "label": "MOUNT PARK PUBLIC SENIOR SECONDARY  SCHOOL (CBSE) VADATHORASALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22434",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL PANAYANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22435",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL NINNAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23228",
            "district": "9",
            "Block": "347",
            "label": "GOVERNMENT HIGH SCHOOL MUDIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "348": [
        {
            "key": "3094",
            "district": "12",
            "Block": "348",
            "label": "SERVITE MAT.SCHOOL,THOGAIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3239",
            "district": "12",
            "Block": "348",
            "label": "OMSAKTHI MATRICULATION SCHOOL A. NADUPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14033",
            "district": "12",
            "Block": "348",
            "label": "GHSS, G.UDAIYAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14034",
            "district": "12",
            "Block": "348",
            "label": "Panchayat union middle school Muthalaipatti",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14035",
            "district": "12",
            "Block": "348",
            "label": "panchayat union middle school, veeramalaikoundanpatty",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14036",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, NEITHALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14037",
            "district": "12",
            "Block": "348",
            "label": "BAGAVATHY GOVERNMENT AIDED HIGH SCHOOL, KAVANDAMPATTY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14038",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, CHINNAPANAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14039",
            "district": "12",
            "Block": "348",
            "label": "Adi-Dravida Welfare High School,NEITHALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14040",
            "district": "12",
            "Block": "348",
            "label": "G.H.S.SCHOOL, RACHANDAR THIRUMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14041",
            "district": "12",
            "Block": "348",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  ARCHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14042",
            "district": "12",
            "Block": "348",
            "label": "PUMS, INAM  PULUTHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14043",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGH SCHOOL, BALASAMUTHIRAPATTI, KARUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14044",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL, KAVALKARANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14045",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL, VADASERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14046",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL,A.NADUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14047",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,KEELAVELIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14048",
            "district": "12",
            "Block": "348",
            "label": "ST.ANNES HIGH SCHOOL, KALLADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14049",
            "district": "12",
            "Block": "348",
            "label": "ST.FATHIMA AIDED MID.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14050",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL, PILLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14051",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, NADAKKAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14052",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THOGAIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14053",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL,MAHALIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14054",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, MOOTAKKAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14055",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL, A.UDAYAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14056",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, NALLAKAVUNDAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14057",
            "district": "12",
            "Block": "348",
            "label": "G.H.SCHOOL,  NAGANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14058",
            "district": "12",
            "Block": "348",
            "label": "P.U.M.SCHOOL, CHINNAREDDIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14059",
            "district": "12",
            "Block": "348",
            "label": "GOVT.A.D.W.H.SCHOOL,THELUNGAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14060",
            "district": "12",
            "Block": "348",
            "label": "PUMS,Chinnaiyampalaiyam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14061",
            "district": "12",
            "Block": "348",
            "label": "Panchayat union middle school Sepalapatti",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14062",
            "district": "12",
            "Block": "348",
            "label": "Panchayat union middle school, PURASAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14063",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21371",
            "district": "12",
            "Block": "348",
            "label": "GGHS,RACHANDAR THIRUMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22518",
            "district": "12",
            "Block": "348",
            "label": "GHS,BOMMANAYAKKANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22974",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGH SCHOOL, PATHIRIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23299",
            "district": "12",
            "Block": "348",
            "label": "GOVERNMENT HIGH SCHOOL, KALLAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "24028",
            "district": "12",
            "Block": "348",
            "label": "S.V.C PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "349": [
        {
            "key": "436",
            "district": "4",
            "Block": "349",
            "label": "GRAHAM STAINES MATRIC HIGHER SECONDARY SCHOOL,SIKKARAYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "520",
            "district": "4",
            "Block": "349",
            "label": "SRI SOWDESWARI VIDYALAYA MODEL MATRIC Hr SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "619",
            "district": "4",
            "Block": "349",
            "label": "SRI SARGURU MATRICULATION  SCHOOL  THEETIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23672",
            "district": "4",
            "Block": "349",
            "label": "K.G Public Matric Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "872",
            "district": "4",
            "Block": "349",
            "label": "CHINMAYA VIDHYALAYA CBSE SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1183",
            "district": "4",
            "Block": "349",
            "label": "CHINMAYA VIDYALAYA MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1321",
            "district": "4",
            "Block": "349",
            "label": "ISHA VIDHYA MATRIC HIGHER SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1377",
            "district": "4",
            "Block": "349",
            "label": "PREMIER VIDYAA VIKASH MATRIC HIGHER SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1923",
            "district": "4",
            "Block": "349",
            "label": "NAGINI VIDYALAYA MATRIC HR SEC SCHOOL, KALIKKANAICKENPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2161",
            "district": "4",
            "Block": "349",
            "label": "SRI RAGAVENDRA VIDYALAYA MATRIC. HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2261",
            "district": "4",
            "Block": "349",
            "label": "EVANGELINE MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2275",
            "district": "4",
            "Block": "349",
            "label": "SREE RAMAKRISHNA VIDHYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2377",
            "district": "4",
            "Block": "349",
            "label": "PUMS IRUTTUPALLAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3651",
            "district": "4",
            "Block": "349",
            "label": "PRG MDL SCHOOL, THENNAMANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3838",
            "district": "4",
            "Block": "349",
            "label": "DELHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4211",
            "district": "4",
            "Block": "349",
            "label": "PUMS KALAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5759",
            "district": "4",
            "Block": "349",
            "label": "PUMS ARUMUGAGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5862",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PACHAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5869",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADIVELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5914",
            "district": "4",
            "Block": "349",
            "label": "PUMS PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5933",
            "district": "4",
            "Block": "349",
            "label": "PUMS BOLUVAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6691",
            "district": "4",
            "Block": "349",
            "label": "SRI NARAYANA MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12894",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12895",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BOMMANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23701",
            "district": "4",
            "Block": "349",
            "label": "sri venkatachalatathy vidyalaya matric hr. sec. school",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12896",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAVALLI SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12897",
            "district": "4",
            "Block": "349",
            "label": "MARUTHAMALAI SUBRAMANIYA SWAMY DEVASTHANA HIGHER SECONDARY SCHOOL , VADAVALLI, CBE-641041.",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12898",
            "district": "4",
            "Block": "349",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KALVEERAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12899",
            "district": "4",
            "Block": "349",
            "label": "R.P.M.VIDYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12900",
            "district": "4",
            "Block": "349",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KALIKKANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12901",
            "district": "4",
            "Block": "349",
            "label": "GGHSS THONDAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12902",
            "district": "4",
            "Block": "349",
            "label": "GBHSS THONDAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12903",
            "district": "4",
            "Block": "349",
            "label": "GHS DEVARAYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12904",
            "district": "4",
            "Block": "349",
            "label": "GHSS NARASIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12905",
            "district": "4",
            "Block": "349",
            "label": "PUMS VIRALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12906",
            "district": "4",
            "Block": "349",
            "label": "GHS SEMMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12907",
            "district": "4",
            "Block": "349",
            "label": "GTR HS MUTTATHUVAYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12908",
            "district": "4",
            "Block": "349",
            "label": "ISHA HOME SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12909",
            "district": "4",
            "Block": "349",
            "label": "GHSS MADVARAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12910",
            "district": "4",
            "Block": "349",
            "label": "CHINMAYA INTERNATIONAL RESIDENTIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12911",
            "district": "4",
            "Block": "349",
            "label": "PUMS ALANDURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12912",
            "district": "4",
            "Block": "349",
            "label": "GHSS ALANDURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12913",
            "district": "4",
            "Block": "349",
            "label": "GHS NADHEGOUNDENPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12914",
            "district": "4",
            "Block": "349",
            "label": "GHSS SENNANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12915",
            "district": "4",
            "Block": "349",
            "label": "AIDED MID SCHOOL KUPPANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12916",
            "district": "4",
            "Block": "349",
            "label": "GHS MADHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12917",
            "district": "4",
            "Block": "349",
            "label": "GHSS THEETHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12918",
            "district": "4",
            "Block": "349",
            "label": "PANCHYAT UNION MIDDLE SCHOOL  PERUR CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21598",
            "district": "4",
            "Block": "349",
            "label": "CMC INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21642",
            "district": "4",
            "Block": "349",
            "label": "ADHYAYANA INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21868",
            "district": "4",
            "Block": "349",
            "label": "KARUNYA CHIRISTIAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22482",
            "district": "4",
            "Block": "349",
            "label": "IDHAZHL NATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22483",
            "district": "4",
            "Block": "349",
            "label": "VISHWANKAR PUBLIC SCHOOL POOLUVAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23473",
            "district": "4",
            "Block": "349",
            "label": "KIKANI GLOBAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23473",
            "district": "4",
            "Block": "349",
            "label": "KIKANI GLOBAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "350": [
        {
            "key": "432",
            "district": "28",
            "Block": "350",
            "label": "GEETHA MATRIC HSS MUTHYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1713",
            "district": "28",
            "Block": "350",
            "label": "PUMS BHARATHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1874",
            "district": "28",
            "Block": "350",
            "label": "SIVAKASI N. MAT. SCHOOL, MAPPILAIYURANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2133",
            "district": "28",
            "Block": "350",
            "label": "HOLY FAMILY TAMIL MIDDLE SCHOOL CALDWELL COLONY",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2256",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS SILVERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2272",
            "district": "28",
            "Block": "350",
            "label": "BMC MATRIC HR.SEC.SCHOOL, THOOTHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2384",
            "district": "28",
            "Block": "350",
            "label": "SRI KALAIMAGAL VIDHYALAYA MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2417",
            "district": "28",
            "Block": "350",
            "label": "PUMS DEVAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2457",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS MADATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2606",
            "district": "28",
            "Block": "350",
            "label": "RC MS ANTHONIYARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2840",
            "district": "28",
            "Block": "350",
            "label": "ICE - KING OF KINGS  MATRICULATION  HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2880",
            "district": "28",
            "Block": "350",
            "label": "CHANDY MATRIC HIGH SCHOOL,MUTHIAHPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2931",
            "district": "28",
            "Block": "350",
            "label": "KAMAK MATRIC HR.SEC. SCHOOL,  SANKARAPERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2963",
            "district": "28",
            "Block": "350",
            "label": "SACRED HEART.MAT.S",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3882",
            "district": "28",
            "Block": "350",
            "label": "PUMS THERMALNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4268",
            "district": "28",
            "Block": "350",
            "label": "SRI VIVEKANANDA MATRIC SCHOOL MULLAKKADU CAMP II",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4306",
            "district": "28",
            "Block": "350",
            "label": "SRI KAMAKSHI VIDYALAYA MAT HSS C.G.E COLONY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4335",
            "district": "28",
            "Block": "350",
            "label": "RC MS THALAMUTHU NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4563",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS K.RAMASAMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4659",
            "district": "28",
            "Block": "350",
            "label": "ISHA VIDHYA MATRIC. HR.SEC. SCHOOL,  KOOTAMPULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5159",
            "district": "28",
            "Block": "350",
            "label": "PUMS THIRUVANANTHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5699",
            "district": "28",
            "Block": "350",
            "label": "ISRAEL MEMORIAL MATRIC SCHOOL KATTALANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "5819",
            "district": "28",
            "Block": "350",
            "label": "HINDU MIDDLE SCHOOL MUDIVAITHANENDAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6017",
            "district": "28",
            "Block": "350",
            "label": "INFANT MATRICULATION HIGHER SECONDARY SCHOOL, KRISHNARAJAPURAM MAIN ROAD, THOOTHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6194",
            "district": "28",
            "Block": "350",
            "label": "SOMASUNDARA MIDDLE SCHOOL VARTHAGAREDDIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6731",
            "district": "28",
            "Block": "350",
            "label": "ST.JOSEPH'S.MATRIC HR.SEC.SCHOOL, MANGALAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19571",
            "district": "28",
            "Block": "350",
            "label": "PUMS UMARIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19572",
            "district": "28",
            "Block": "350",
            "label": "PUMS MELATHATTAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19573",
            "district": "28",
            "Block": "350",
            "label": "VOC GOVT MS THATTAPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19574",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS PERURANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19575",
            "district": "28",
            "Block": "350",
            "label": "GHSS MUDIVAITHANENDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19576",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS SERVAIKARANMADAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19577",
            "district": "28",
            "Block": "350",
            "label": "TNDTA ABISHEKANATHAR HSS KULAYANKARISAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19578",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS KORAMPALLAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19579",
            "district": "28",
            "Block": "350",
            "label": "DEVAPITCHAI MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19580",
            "district": "28",
            "Block": "350",
            "label": "GOOD SHEPHERD MODEL SCHOOL KORAMPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19581",
            "district": "28",
            "Block": "350",
            "label": "SDR SCHOOL KORAMPALLAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19582",
            "district": "28",
            "Block": "350",
            "label": "TDTA PSP HSS PUTHUKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19583",
            "district": "28",
            "Block": "350",
            "label": "GHSS SORISPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19584",
            "district": "28",
            "Block": "350",
            "label": "BMC HIGHER SECONDARY SCHOOL  MILLERPURAM THOOTHUKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19585",
            "district": "28",
            "Block": "350",
            "label": "LUCIA TRANSITIONAL MIDDLE SCHOOL FOR THE INTELLECTUAL DISABLED",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19586",
            "district": "28",
            "Block": "350",
            "label": "ALAGAR PUBLIC SCHOOL THOOTHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19587",
            "district": "28",
            "Block": "350",
            "label": "ST.XAVIER'S HIGH SCHOOL, T.SAVERIARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19588",
            "district": "28",
            "Block": "350",
            "label": "S.P.K.NADAR M.S MAPPILAIYURANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19589",
            "district": "28",
            "Block": "350",
            "label": "ST. ANTONY'S HS SILUVAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19590",
            "district": "28",
            "Block": "350",
            "label": "V.V.D MEMORIAL HSS AROCKIYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19591",
            "district": "28",
            "Block": "350",
            "label": "HARBOUR HR SEC SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19592",
            "district": "28",
            "Block": "350",
            "label": "ST. ANTONY'S MATRIC. HR.SEC.SCHOOL, MACHADO NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19593",
            "district": "28",
            "Block": "350",
            "label": "TNDTA MS MULLAKKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19594",
            "district": "28",
            "Block": "350",
            "label": "K.T.KOSALRAM HIGH SCHOOL, MUTHIAHPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19595",
            "district": "28",
            "Block": "350",
            "label": "GHSS M.THANGAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19596",
            "district": "28",
            "Block": "350",
            "label": "ST.ANTONY'S HS M.SAVARIYARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19597",
            "district": "28",
            "Block": "350",
            "label": "SPIC NAGAR HIGHER SECONDARY SCHOOL MUTHIAH PURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21189",
            "district": "28",
            "Block": "350",
            "label": "PUMS, ATHIMARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21412",
            "district": "28",
            "Block": "350",
            "label": "GHS POTTALKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22270",
            "district": "28",
            "Block": "350",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22271",
            "district": "28",
            "Block": "350",
            "label": "SRI LALITHA VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22843",
            "district": "28",
            "Block": "350",
            "label": "SERVITE SCHOOL, MARAVANMADAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23258",
            "district": "28",
            "Block": "350",
            "label": "ST.ANN'S SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24029",
            "district": "28",
            "Block": "350",
            "label": "PUPS K THIRAVIYAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24030",
            "district": "28",
            "Block": "350",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "351": [
        {
            "key": "600",
            "district": "28",
            "Block": "351",
            "label": "DMNS DR SIVANTHI ADITHANAR MATRIC HR SEC",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "934",
            "district": "28",
            "Block": "351",
            "label": "CARPENTER STREET MS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1012",
            "district": "28",
            "Block": "351",
            "label": "STAR MATRIC HSS RENGANATHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1670",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS NBK STREET  THOOTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1755",
            "district": "28",
            "Block": "351",
            "label": "ST CHARLES MS, SMPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1934",
            "district": "28",
            "Block": "351",
            "label": "TDTA MS, VADAKKUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2075",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS,  MELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2201",
            "district": "28",
            "Block": "351",
            "label": "BHARAT RATNA KAMARAJAR  MATRIC HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2258",
            "district": "28",
            "Block": "351",
            "label": "FATIMA MS, THOOTHUKUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2286",
            "district": "28",
            "Block": "351",
            "label": "ST THOMAS MATRIC HSS, INNACIARPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2584",
            "district": "28",
            "Block": "351",
            "label": "GEETHA MATRIC HR.SEC.SCHOOL POLPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2643",
            "district": "28",
            "Block": "351",
            "label": "SDA MATRIC SCHOOL, SB COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2675",
            "district": "28",
            "Block": "351",
            "label": "TNDTA MS, TOOVEYPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2793",
            "district": "28",
            "Block": "351",
            "label": "EDUCATIONAL SOCIETY MS, THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2862",
            "district": "28",
            "Block": "351",
            "label": "SAKTHI VIDYALAYA  MATRIC SCHOOL, KAMARAJ NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2921",
            "district": "28",
            "Block": "351",
            "label": "EXTENSION MS, NEW COLONY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3206",
            "district": "28",
            "Block": "351",
            "label": "HOLY CROSS HOME SCIENCE MATRICULATION SCHOOL, NEW COLONY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3398",
            "district": "28",
            "Block": "351",
            "label": "ST.PATRICKS MS MELUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3790",
            "district": "28",
            "Block": "351",
            "label": "PAUL MATRIC SCHOOL, KANDHASAMYPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3791",
            "district": "28",
            "Block": "351",
            "label": "X'AN MATRIC HR.SEC SCHOOL, THOOTHUKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4060",
            "district": "28",
            "Block": "351",
            "label": "THE VIKASA SCHOOL, MILLERPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4354",
            "district": "28",
            "Block": "351",
            "label": "CRESCENT MATRIC HR.SEC SCHOOL, CHIDAMBARANAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4758",
            "district": "28",
            "Block": "351",
            "label": "KSHATRIYA VIDHYASALA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4959",
            "district": "28",
            "Block": "351",
            "label": "APC VEERABAHU MATRIC HR.SEC. SCHOOL, MILLERPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5065",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS, SIVANTHAKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5135",
            "district": "28",
            "Block": "351",
            "label": "INTER CHRISTIAN MISSION MS BRYANT NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5750",
            "district": "28",
            "Block": "351",
            "label": "BELL MATRIC HR. SEC. SCHOOL TOOVEYPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6077",
            "district": "28",
            "Block": "351",
            "label": "JOHNS MATRIC HS, MILLERPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6421",
            "district": "28",
            "Block": "351",
            "label": "AUXILIUM MATRIC HR.SEC. SCHOOL THERESPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19285",
            "district": "28",
            "Block": "351",
            "label": "P. THANGAMMAL MEMORIAL HSS POLPETTAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19286",
            "district": "28",
            "Block": "351",
            "label": "GOOD SHEPHERD SCHOOL FOR THE HEARING IMPAIRED, BRYANT NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19287",
            "district": "28",
            "Block": "351",
            "label": "TAGORE MEMORIAL MIDDLE SCHOOL, THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19288",
            "district": "28",
            "Block": "351",
            "label": "ST. IGNATIUS HSS, INNACIARPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19289",
            "district": "28",
            "Block": "351",
            "label": "DIRAVIAPURAM TNDTA MS MUTHUKRISHNAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19290",
            "district": "28",
            "Block": "351",
            "label": "CORPORATION HSS SAMUELPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19291",
            "district": "28",
            "Block": "351",
            "label": "LITTLE FLOWER RC HS, THERESPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19292",
            "district": "28",
            "Block": "351",
            "label": "CALDWELL HSS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19293",
            "district": "28",
            "Block": "351",
            "label": "ST. JOSEPH GIRLS HSS CRUZPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19294",
            "district": "28",
            "Block": "351",
            "label": "ST. FRANCIS XAVIERS HSS, CHINNAKOVIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19295",
            "district": "28",
            "Block": "351",
            "label": "ST.ALOYSIUS GHSS, CHINNAKOIL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19296",
            "district": "28",
            "Block": "351",
            "label": "DASNEVIS MATHA GIRLS HSS, LIONS TOWN",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19297",
            "district": "28",
            "Block": "351",
            "label": "ST.LASALLE  HIGHER SECONDARY SCHOOL, GEORAGE ROAD, TUTICORIN",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19298",
            "district": "28",
            "Block": "351",
            "label": "KARAPETTAI NADAR BOYS HSS, LIONS TOWN",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19299",
            "district": "28",
            "Block": "351",
            "label": "TNDTA MS SHANMUGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19300",
            "district": "28",
            "Block": "351",
            "label": "KARAPETTAI NADAR GIRLS HIGHER SECONDARY SCHOOL, THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19301",
            "district": "28",
            "Block": "351",
            "label": "HOLY CROSS (G)HSS, VE ROAD",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19302",
            "district": "28",
            "Block": "351",
            "label": "HOLY CROSS ANGLO INDIAN HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19303",
            "district": "28",
            "Block": "351",
            "label": "CM HS, TR NAIDU STREET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19304",
            "district": "28",
            "Block": "351",
            "label": "RCHS, RATHINAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19305",
            "district": "28",
            "Block": "351",
            "label": "SAV HSS,MELUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19306",
            "district": "28",
            "Block": "351",
            "label": "VICTORIA GIRLS HSS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19307",
            "district": "28",
            "Block": "351",
            "label": "CM HSS, RENGANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19308",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS ,GIN FACTORY ROAD  THOOTHUKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19309",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS SHUNMUGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19310",
            "district": "28",
            "Block": "351",
            "label": "SUBBIAH VIDYALAYAM GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19311",
            "district": "28",
            "Block": "351",
            "label": "BHARATHIYAR VIDYALAYAM HSS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19312",
            "district": "28",
            "Block": "351",
            "label": "SEENA VANA GHSS, THOOTHUKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19313",
            "district": "28",
            "Block": "351",
            "label": "THANGAM MS, ANNANAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19314",
            "district": "28",
            "Block": "351",
            "label": "ST MARYS BOYS HSS, MILLERPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19315",
            "district": "28",
            "Block": "351",
            "label": "AMM SINNAMANI NADAR HS THOOTHUKUDI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19316",
            "district": "28",
            "Block": "351",
            "label": "SAKTHI VINAYAGAR HINDU VIDYALAYA HSS CHINDAMBARANAG",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19317",
            "district": "28",
            "Block": "351",
            "label": "MUNICIPAL MS, SOUTH NEW STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21978",
            "district": "28",
            "Block": "351",
            "label": "ST.THOMAS THE APOSTLE MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23040",
            "district": "28",
            "Block": "351",
            "label": "ST.THOMAS  SCHOOL, INNACIARPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23041",
            "district": "28",
            "Block": "351",
            "label": "VICTORIA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "352": [
        {
            "key": "607",
            "district": "7",
            "Block": "352",
            "label": "RENUGADEVI MATRIC HIGHER SECONDARY SCHOOL, VAGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4121",
            "district": "7",
            "Block": "352",
            "label": "VIVEKANANDA HINDU MATRICULATION SCHOOL, NEELAGOUNDANPATTI.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13690",
            "district": "7",
            "Block": "352",
            "label": "PUMS,KALUTHARIKIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13691",
            "district": "7",
            "Block": "352",
            "label": "GHS,KOOTHAMPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13692",
            "district": "7",
            "Block": "352",
            "label": "GHS,KARIYAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13693",
            "district": "7",
            "Block": "352",
            "label": "PUMS,PERUMALKOVILVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13694",
            "district": "7",
            "Block": "352",
            "label": "C.S.I HIGH SCHOOL,KALLIMANDAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13695",
            "district": "7",
            "Block": "352",
            "label": "THIRUPPATHI ARULNERI HR.SEC.SCHOOL,KALLIMANDAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13696",
            "district": "7",
            "Block": "352",
            "label": "BRINDAVAN MATRICULATION SCHOOL,KALLIMANDHAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13697",
            "district": "7",
            "Block": "352",
            "label": "PUMS,KAPPALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13698",
            "district": "7",
            "Block": "352",
            "label": "PUMS,KOTHAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13699",
            "district": "7",
            "Block": "352",
            "label": "JRC H.SC,KOTHAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13700",
            "district": "7",
            "Block": "352",
            "label": "GOVT.H.SCHOOL,PORULUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13701",
            "district": "7",
            "Block": "352",
            "label": "PUMS, PUNGAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13702",
            "district": "7",
            "Block": "352",
            "label": "GHS,POOLAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13703",
            "district": "7",
            "Block": "352",
            "label": "SANKAR PONNAR HSS, THUMBALAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13704",
            "district": "7",
            "Block": "352",
            "label": "PUMS,VILVATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13705",
            "district": "7",
            "Block": "352",
            "label": "GOVT.H.SC,MANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13706",
            "district": "7",
            "Block": "352",
            "label": "MAHESH KARTHIK MATRIC HIGHER SECONDARY SCHOOL,AKKARAIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13707",
            "district": "7",
            "Block": "352",
            "label": "GOVT.HSS,THOPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13708",
            "district": "7",
            "Block": "352",
            "label": "GURUGULAM MIDDLE SCHOOL, KEERANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13709",
            "district": "7",
            "Block": "352",
            "label": "GHSS,KEERANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13710",
            "district": "7",
            "Block": "352",
            "label": "C G M HSS,KORIKKADAVU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13711",
            "district": "7",
            "Block": "352",
            "label": "GHSS,THALAIYUTHU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13712",
            "district": "7",
            "Block": "352",
            "label": "GHS,SAMINATHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13713",
            "district": "7",
            "Block": "352",
            "label": "GHS,PERICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13714",
            "district": "7",
            "Block": "352",
            "label": "PUMS,ATHIMARATHUVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13715",
            "district": "7",
            "Block": "352",
            "label": "GHSS,KOKKARAKKAL VALASU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13716",
            "district": "7",
            "Block": "352",
            "label": "MANIMEKALAI ELANGO HR SEC SCHOOL,DEVATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13717",
            "district": "7",
            "Block": "352",
            "label": "PUMS,PODUVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13718",
            "district": "7",
            "Block": "352",
            "label": "GHSS,MANJANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21369",
            "district": "7",
            "Block": "352",
            "label": "GHS, MELKARAIPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22508",
            "district": "7",
            "Block": "352",
            "label": "SHRI VIDHYA MANDHIR(CBSE), PUSHPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23028",
            "district": "7",
            "Block": "352",
            "label": "SASTI KENDRALAYA INTERNATIONAL PUBLIC SCHOOL, PULIYAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24031",
            "district": "7",
            "Block": "352",
            "label": "PONMALAR PUBLIC SCHOOL, KEERANUR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "353": [
        {
            "key": "4442",
            "district": "29",
            "Block": "353",
            "label": "DR G VISWANATHAM PILLAI MATRIC SCHOOL,THOTTIYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4906",
            "district": "29",
            "Block": "353",
            "label": "INFANT JESUS MATRICULATION SCHOOL, KATTUPUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5317",
            "district": "29",
            "Block": "353",
            "label": "Dr.V. Gengusamy Naidu  MAT SCHOOL,THOTTIYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14545",
            "district": "29",
            "Block": "353",
            "label": "PUMS,KIDARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14546",
            "district": "29",
            "Block": "353",
            "label": "GHS,NAGAIYANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14547",
            "district": "29",
            "Block": "353",
            "label": "PUMS,SRIRAMASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14548",
            "district": "29",
            "Block": "353",
            "label": "PUMS,UNNIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14549",
            "district": "29",
            "Block": "353",
            "label": "GHS,THALAIMALAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14550",
            "district": "29",
            "Block": "353",
            "label": "GHSS,ELURPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14551",
            "district": "29",
            "Block": "353",
            "label": "GHS,UDAIYAKULAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14552",
            "district": "29",
            "Block": "353",
            "label": "PUMS KARTHIGAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14553",
            "district": "29",
            "Block": "353",
            "label": "ST JOSEPHS M S,THOLURPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14554",
            "district": "29",
            "Block": "353",
            "label": "GHSS,BALASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14555",
            "district": "29",
            "Block": "353",
            "label": "NEW MULLAI HIGH SCHOOL,THOLURPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14556",
            "district": "29",
            "Block": "353",
            "label": "GHS,ALAGARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14557",
            "district": "29",
            "Block": "353",
            "label": "PUMS,THIRUEENGOIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14558",
            "district": "29",
            "Block": "353",
            "label": "LALITHA UNAIDED  HIGH SCHOOL, THIRUEENGOIMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14559",
            "district": "29",
            "Block": "353",
            "label": "GHS,ARANGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14560",
            "district": "29",
            "Block": "353",
            "label": "GHSS,NATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14561",
            "district": "29",
            "Block": "353",
            "label": "GHS,THIRUNARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14562",
            "district": "29",
            "Block": "353",
            "label": "PUMS,SRINIVASANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14563",
            "district": "29",
            "Block": "353",
            "label": "PUMS,KADUVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14564",
            "district": "29",
            "Block": "353",
            "label": "GHSS, KOLAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14565",
            "district": "29",
            "Block": "353",
            "label": "FATHIMA M S,KOLAKKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14566",
            "district": "29",
            "Block": "353",
            "label": "PUMS,SEELAIPILLAIYARPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14567",
            "district": "29",
            "Block": "353",
            "label": "PUMS,THOTTIYAM-NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14568",
            "district": "29",
            "Block": "353",
            "label": "GBHSS,THOTTIYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14569",
            "district": "29",
            "Block": "353",
            "label": "GHSS,THOTTIYAM GIRLS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14570",
            "district": "29",
            "Block": "353",
            "label": "ST. MARYS AIDED HS, THOTTIYAM NORTH",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14571",
            "district": "29",
            "Block": "353",
            "label": "MULLAI HR. SEC. SCHOOL, BALASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14572",
            "district": "29",
            "Block": "353",
            "label": "SOWDAAMBIKAA MATRIC HSS BALASAMUTHIRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14573",
            "district": "29",
            "Block": "353",
            "label": "ZAMINDARS HS S,KATTUPUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14574",
            "district": "29",
            "Block": "353",
            "label": "GHSS, KATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21280",
            "district": "29",
            "Block": "353",
            "label": "SREE SARRADHA MATRICULATION HS SCHOOL, KOLATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21511",
            "district": "29",
            "Block": "353",
            "label": "ADWHS,ARIYANAMPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21512",
            "district": "29",
            "Block": "353",
            "label": "GOVT. ADW HS,M.PUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22531",
            "district": "29",
            "Block": "353",
            "label": "SHAANTHI NIKKETHAN MATRICULATION SCHOOL ALAGARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23583",
            "district": "29",
            "Block": "353",
            "label": "Cambridge Matriculation School,Meikkalnayakanpatti",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23583",
            "district": "29",
            "Block": "353",
            "label": "Cambridge Matriculation School,Meikkalnayakanpatti",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24032",
            "district": "29",
            "Block": "353",
            "label": "SRI SABARI VIDYALA MATRICULATION SCHOOL,THOTTIYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24033",
            "district": "29",
            "Block": "353",
            "label": "SOWDAMBIKAA VIDYALAYA SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "354": [
        {
            "key": "805",
            "district": "11",
            "Block": "354",
            "label": "RUBEN MATRIC. HR. SEC. SCHOOL - THADIKARANKONAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "856",
            "district": "11",
            "Block": "354",
            "label": "ST. FRANCIS ASSISI MATRIC SCHOOL- THITTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1206",
            "district": "11",
            "Block": "354",
            "label": "CAROL MATRICULATION HIGHER SECONDARY SCHOOL - THITTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1532",
            "district": "11",
            "Block": "354",
            "label": "ISLAMIC SCHOOL-THITTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1959",
            "district": "11",
            "Block": "354",
            "label": "ANNE'S MATRIC SCHOOL ARALKURUSADY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2558",
            "district": "11",
            "Block": "354",
            "label": "LUTH M.S. MARUGAL-THALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2695",
            "district": "11",
            "Block": "354",
            "label": "RCMS-ARAL KURUSADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2967",
            "district": "11",
            "Block": "354",
            "label": "RAMALEKSHMI VIDYALAYA MATRICULATION SCHOOL, THOVALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3244",
            "district": "11",
            "Block": "354",
            "label": "GMS KEERIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3537",
            "district": "11",
            "Block": "354",
            "label": "GMS-ESANTHIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3744",
            "district": "11",
            "Block": "354",
            "label": "GMS-PARVATHIPUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4030",
            "district": "11",
            "Block": "354",
            "label": "GMS-THERISANAMKOPPU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4130",
            "district": "11",
            "Block": "354",
            "label": "BAS MAT SCHL-CHENBAGARAMANPUTHOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4735",
            "district": "11",
            "Block": "354",
            "label": "ZION JEBA THOTTAM MATRICULATION SCHOOL-ESANTHIMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5836",
            "district": "11",
            "Block": "354",
            "label": "G.A.D.W.M.S.UDAIYADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6160",
            "district": "11",
            "Block": "354",
            "label": "Holy Family Middle School, Ettamadai",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20069",
            "district": "11",
            "Block": "354",
            "label": "GHSS-THOVALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20070",
            "district": "11",
            "Block": "354",
            "label": "GHSS-MADHAVALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20071",
            "district": "11",
            "Block": "354",
            "label": "GHSS-CHENBAGARAMANPUTHOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20072",
            "district": "11",
            "Block": "354",
            "label": "GHS-VELLAMADAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20073",
            "district": "11",
            "Block": "354",
            "label": "GHS-THIRUPATHISARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20074",
            "district": "11",
            "Block": "354",
            "label": "ANNAI CHELLAMMAL CBSE SCHOOLTHIRUPATHISARAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20075",
            "district": "11",
            "Block": "354",
            "label": "GHSS-THAZHAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20076",
            "district": "11",
            "Block": "354",
            "label": "GHSS-BOOTHAPANDY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20077",
            "district": "11",
            "Block": "354",
            "label": "GHSS-THITTUVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20078",
            "district": "11",
            "Block": "354",
            "label": "BGCSI MATRICULATION SCHOOL, THITTUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20079",
            "district": "11",
            "Block": "354",
            "label": "PJ GHS (G)-BHOOTHAPANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20080",
            "district": "11",
            "Block": "354",
            "label": "GHSS-ARUMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20081",
            "district": "11",
            "Block": "354",
            "label": "GHS-POTTIYOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20082",
            "district": "11",
            "Block": "354",
            "label": "GHSS-KURATHIYARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20083",
            "district": "11",
            "Block": "354",
            "label": "GOVT ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL VAZHAYATHUVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20084",
            "district": "11",
            "Block": "354",
            "label": "C.W.H.S-THADIKARANKONAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20085",
            "district": "11",
            "Block": "354",
            "label": "GHS-ANANTHAPURAM, KADUKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20086",
            "district": "11",
            "Block": "354",
            "label": "GHS-KATTUPUTHOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20087",
            "district": "11",
            "Block": "354",
            "label": "GHS-ERACHAKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20088",
            "district": "11",
            "Block": "354",
            "label": "GHS-ARAL PERUMALPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20089",
            "district": "11",
            "Block": "354",
            "label": "GHSS-ARALVAIMOZHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20090",
            "district": "11",
            "Block": "354",
            "label": "RAJA'S MATRIC HR.SEC.SCHOOL - ARALVOIMOZHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20091",
            "district": "11",
            "Block": "354",
            "label": "GHS-ARAL KUMARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20092",
            "district": "11",
            "Block": "354",
            "label": "COMERIN INTERNATIONAL ARALVOIMOZHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21810",
            "district": "11",
            "Block": "354",
            "label": "LHL SCHOOL - VISUVASAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22284",
            "district": "11",
            "Block": "354",
            "label": "ST.JOSEPH INTERNATIONAL SCHOOL-ARALVAIMOZHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23492",
            "district": "11",
            "Block": "354",
            "label": "KOWSHIKA KENDRIYA VIDHYALAYA, DERISANAMCOPE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23492",
            "district": "11",
            "Block": "354",
            "label": "KOWSHIKA KENDRIYA VIDHYALAYA, DERISANAMCOPE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24034",
            "district": "11",
            "Block": "354",
            "label": "Kanniyakumari District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "355": [
        {
            "key": "929",
            "district": "11",
            "Block": "355",
            "label": "VICTORY MATRIC HIGHER SECONDARY SCHOOL,MYLODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1308",
            "district": "11",
            "Block": "355",
            "label": "ST.MICHAEL'S MATRIC HIGHER SECONDARY SCHOOL,KATTATHURAI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1846",
            "district": "11",
            "Block": "355",
            "label": "DEVI MATRIC SCHOOL RAMANPARAMBU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2177",
            "district": "11",
            "Block": "355",
            "label": "GMS THIRUVITHANCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2234",
            "district": "11",
            "Block": "355",
            "label": "CSI PUBLIC MATRIC HR SEC SCHOOL, AZHAGIAMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2428",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT MIDDILE SCHOOL,,KANCHIKUZHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2476",
            "district": "11",
            "Block": "355",
            "label": "CONGREGATIONAL CHRISTIAN MATRICULATION SCHOOL ,VALVACHAGOSTAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2815",
            "district": "11",
            "Block": "355",
            "label": "Smt.R.P.Rajalakshmi Hindu Vidyalaya Mat.Hr.Sec.School,Thuckalay",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3052",
            "district": "11",
            "Block": "355",
            "label": "RC MIDDLE SCHOOL VELLICODE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3196",
            "district": "11",
            "Block": "355",
            "label": "HINDU VIDYALAYA MATRIC HSS KAPPIYARAI,KARUNGAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3253",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT MIDDILE SCHOOL BRAMMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3616",
            "district": "11",
            "Block": "355",
            "label": "GMS-ALENCODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3653",
            "district": "11",
            "Block": "355",
            "label": "GMS PILAVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3684",
            "district": "11",
            "Block": "355",
            "label": "UMA MATRIC HIGHER SECONDARY SCHOOL, ALWARCOIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3693",
            "district": "11",
            "Block": "355",
            "label": "GMS,ERANIEL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3747",
            "district": "11",
            "Block": "355",
            "label": "GMS KOTTIKODE",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3806",
            "district": "11",
            "Block": "355",
            "label": "AMALA MATRIC -THUCKALAY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3848",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT MIDDILE SCHOOL PATHIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3865",
            "district": "11",
            "Block": "355",
            "label": "ALDRIN MATRIC HIGHER SECONDARY SCHOOL, KANDANVILAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3879",
            "district": "11",
            "Block": "355",
            "label": "ST ANTONY'S MATRICULATION SCHOOL, CHEMPARUTHIVILAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3906",
            "district": "11",
            "Block": "355",
            "label": "RCHS CHEMPARUTHIVILAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "3942",
            "district": "11",
            "Block": "355",
            "label": "A.G. MATRICULATION SCHOOL,CHERIKADAI,PALLIYADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3961",
            "district": "11",
            "Block": "355",
            "label": "ST.JOHNS MATRIC HIGHER SECONDARY SCHOOL, PALLIYADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3979",
            "district": "11",
            "Block": "355",
            "label": "GMS VILAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4000",
            "district": "11",
            "Block": "355",
            "label": "GMS THUCKALAY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4042",
            "district": "11",
            "Block": "355",
            "label": "GMS KOTTAMAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4119",
            "district": "11",
            "Block": "355",
            "label": "V. N. MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL-MOOLACHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4193",
            "district": "11",
            "Block": "355",
            "label": "GMS PUTHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4225",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT MIDDILE SCHOOL,,PULIYOORKURICHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4283",
            "district": "11",
            "Block": "355",
            "label": "CARMAL MATRIC SCHOOL,  MANNALIKARAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4293",
            "district": "11",
            "Block": "355",
            "label": "MOTHER THERESA MATRIC HIGHER SECONDARY SCHOOL, MYLACODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4363",
            "district": "11",
            "Block": "355",
            "label": "ST. JOSEPH'S MIDDLE SCHOOL, KALKURICHY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4398",
            "district": "11",
            "Block": "355",
            "label": "ST. THERESA'S MIDDLE SCHOOL KANDANVILAI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4410",
            "district": "11",
            "Block": "355",
            "label": "ST ANTONY'S MS VATTAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4466",
            "district": "11",
            "Block": "355",
            "label": "CSI MATRICULATION SCHOOL, MOOLACHEL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4620",
            "district": "11",
            "Block": "355",
            "label": "ISLAMIC MODEL MATRIC Hr. Sec. School  THIRUVITHANCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4630",
            "district": "11",
            "Block": "355",
            "label": "MALAVIYA VIDYA KENDRAM, KUMARAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4942",
            "district": "11",
            "Block": "355",
            "label": "SREERAM MATRIC HIGHER SECONDARY SCHOOL,PARASERI.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4981",
            "district": "11",
            "Block": "355",
            "label": "VICTORY PUBLIC SCHOOL CBSE ALANCODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23382",
            "district": "11",
            "Block": "355",
            "label": "May Flower Matric Hr Sec School",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5493",
            "district": "11",
            "Block": "355",
            "label": "LITTLE MEADS METRIC SCHOOL PALIYADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6288",
            "district": "11",
            "Block": "355",
            "label": "SREE RAMAKRISHNA MATRIC HR.SEC SCHOOL,PARAICODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6323",
            "district": "11",
            "Block": "355",
            "label": "ST JOSEPH'S MATRICULATION HIGHER SECONDARY SCHOOL, MULAGUMOODU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6393",
            "district": "11",
            "Block": "355",
            "label": "ST JOSEPH'S HSS MULAGUMOODU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6430",
            "district": "11",
            "Block": "355",
            "label": "OXFORD MARTIC KUMARAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20231",
            "district": "11",
            "Block": "355",
            "label": "GHS VATHIYARKONAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20232",
            "district": "11",
            "Block": "355",
            "label": "St.Joseph's M.S.C.H.S.S,Chellamkonam",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20233",
            "district": "11",
            "Block": "355",
            "label": "RC HIGH SCHOOL PALLIYADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20234",
            "district": "11",
            "Block": "355",
            "label": "CHRISTUCOIL LMS HR SEC SCHOOL PALLIYADI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20235",
            "district": "11",
            "Block": "355",
            "label": "KARUNALYAM SCHOOL MENTALLY RETARDED -KALLUVILAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20236",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,KALKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20237",
            "district": "11",
            "Block": "355",
            "label": "GOVT HSS-THUCKALAY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20238",
            "district": "11",
            "Block": "355",
            "label": "AMALA CONVENT(G) H.S.S,THUCKALAY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20239",
            "district": "11",
            "Block": "355",
            "label": "GOVERNMENT HIGH SCHOOL, MOOLACHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20240",
            "district": "11",
            "Block": "355",
            "label": "SKVMS MUTTAIKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20241",
            "district": "11",
            "Block": "355",
            "label": "CARMAL GIRLS HSS MANALIKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20242",
            "district": "11",
            "Block": "355",
            "label": "LMS HSS KADAMALAIKUNTU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20243",
            "district": "11",
            "Block": "355",
            "label": "GHS KUMARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20244",
            "district": "11",
            "Block": "355",
            "label": "ST. MARY GORETTY HSS  MANALIKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20245",
            "district": "11",
            "Block": "355",
            "label": "BG ARIVU ILLAM KOTTICODU",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20246",
            "district": "11",
            "Block": "355",
            "label": "GHS(GIRLS),ERANIEL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20247",
            "district": "11",
            "Block": "355",
            "label": "GHS PEYANKUZHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20248",
            "district": "11",
            "Block": "355",
            "label": "GHSS.KANDANVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20249",
            "district": "11",
            "Block": "355",
            "label": "HACKER MEMORIAL HSS ,NEYYOOR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20250",
            "district": "11",
            "Block": "355",
            "label": "GMS KODIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20251",
            "district": "11",
            "Block": "355",
            "label": "GHSS-THRIVITHANCODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20252",
            "district": "11",
            "Block": "355",
            "label": "GHSS-KANNATTUVILAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20253",
            "district": "11",
            "Block": "355",
            "label": "INFANT JESUS GIRLS HIGH SCHOOL -  MULAGUMOODU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20254",
            "district": "11",
            "Block": "355",
            "label": "VIVEKANANDA MAT Hr.Sec.SCHOOL-VAIKUNDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20255",
            "district": "11",
            "Block": "355",
            "label": "SREE RAMJI VITHIYA KENDRAA THICKANAMCODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23654",
            "district": "11",
            "Block": "355",
            "label": "jennifer matriculation higher secondary school ",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "20256",
            "district": "11",
            "Block": "355",
            "label": "MOUNT LITERA ZEE SCHOOL -MYLODE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20257",
            "district": "11",
            "Block": "355",
            "label": "GHS PERUNCHILAMBU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21908",
            "district": "11",
            "Block": "355",
            "label": "OXFORD MATRICULATION SCHOOL-AZHAGIAMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22287",
            "district": "11",
            "Block": "355",
            "label": "THE PACKIANATH PUBLIC SCHOOL- KATTATHURAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "356": [
        {
            "key": "666",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "869",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SAIBANPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "940",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KONDAYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1026",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KANAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1576",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SINGIRIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1629",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UNJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1664",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GANAPATHIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2665",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VINOBANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4380",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL BUNGALOWPUDUR-PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4760",
            "district": "8",
            "Block": "356",
            "label": "MONTFORT SCHOOL, D.G.PUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5560",
            "district": "8",
            "Block": "356",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VANIPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12347",
            "district": "8",
            "Block": "356",
            "label": "JOHN ARTHUR HS,GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12348",
            "district": "8",
            "Block": "356",
            "label": "KASTURIBA GANDHI BALIKA VIDYALAYA, NANJAIPULIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12349",
            "district": "8",
            "Block": "356",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KONGARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12350",
            "district": "8",
            "Block": "356",
            "label": "GOVT, HIGHER SEC. SCHOOL, KALLIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12351",
            "district": "8",
            "Block": "356",
            "label": "GOVT. HIGH SCHOOL, VALAYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12352",
            "district": "8",
            "Block": "356",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, ELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12353",
            "district": "8",
            "Block": "356",
            "label": "GOVT.HIGHER SEC.SCHOOL, BUNGALOWPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12354",
            "district": "8",
            "Block": "356",
            "label": "GOVERNMENT. HIGHER SECONDARY. SCHOOL, KASIPALAYAM(GOBI)",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12355",
            "district": "8",
            "Block": "356",
            "label": "GOVT. GIRLS HIGHER SEC SCHOOL, T.N.PALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12356",
            "district": "8",
            "Block": "356",
            "label": "SRI PARIYUR AMMAN HIGHER SEC. SCHOOL, T.N.PALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12357",
            "district": "8",
            "Block": "356",
            "label": "GOVT. HIGHER SEC, SCHOOL, D.G.PUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12358",
            "district": "8",
            "Block": "356",
            "label": "ST. FRANCIS XAVIER HR SEC SCHOOL, PERIYA KODIVERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21271",
            "district": "8",
            "Block": "356",
            "label": "KONGU VIDYALAYA MATRICULATION,VARAPPALLAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21655",
            "district": "8",
            "Block": "356",
            "label": "SARATHA INTERNATIONAL SCHOOL, KODIVERY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21656",
            "district": "8",
            "Block": "356",
            "label": "BEST MATRICULATION SCHOOL, KALLIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "357": [
        {
            "key": "383",
            "district": "29",
            "Block": "357",
            "label": "SRI VIDYA MANDIR MATRIC. HR.SEC.SCHOOL,THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1782",
            "district": "29",
            "Block": "357",
            "label": "SOWDAMBIKAA MATRIC BOYS HR. SEC.SCHOOL, THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3240",
            "district": "29",
            "Block": "357",
            "label": "VIMALA M HSS THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4828",
            "district": "29",
            "Block": "357",
            "label": "RAJ VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL,THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14575",
            "district": "29",
            "Block": "357",
            "label": "PUMS,AMMAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14576",
            "district": "29",
            "Block": "357",
            "label": "ADW M S ,ADHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14577",
            "district": "29",
            "Block": "357",
            "label": "GADWHSS,KANNANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14578",
            "district": "29",
            "Block": "357",
            "label": "GHS,KANNANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14579",
            "district": "29",
            "Block": "357",
            "label": "PUMS SANGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14580",
            "district": "29",
            "Block": "357",
            "label": "PUMS,K.KOTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14581",
            "district": "29",
            "Block": "357",
            "label": "THE KEERAMBUR HS ,KEERAMBUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14582",
            "district": "29",
            "Block": "357",
            "label": "PUMS, KEELAKUNNUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14583",
            "district": "29",
            "Block": "357",
            "label": "GTRHS SEMBULICHAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14584",
            "district": "29",
            "Block": "357",
            "label": "PUMS,V.A.SAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14585",
            "district": "29",
            "Block": "357",
            "label": "GEE VEE VIDHYALAYAA MATRIC SCHOOL, SINGALANTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14586",
            "district": "29",
            "Block": "357",
            "label": "GHS,SIKKATHAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14587",
            "district": "29",
            "Block": "357",
            "label": "GADWHS, SIKKATHAMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14588",
            "district": "29",
            "Block": "357",
            "label": "GHSS,SENAPPANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14589",
            "district": "29",
            "Block": "357",
            "label": "PUMS,SELLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14590",
            "district": "29",
            "Block": "357",
            "label": "PUMS,SOKKANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14591",
            "district": "29",
            "Block": "357",
            "label": "GHS,NADUVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14592",
            "district": "29",
            "Block": "357",
            "label": "GADWHS,NAGALAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14593",
            "district": "29",
            "Block": "357",
            "label": "PUMS,NALLAVANNIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14594",
            "district": "29",
            "Block": "357",
            "label": "PUMS,K.PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14595",
            "district": "29",
            "Block": "357",
            "label": "PUMS,KILIYANURPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14596",
            "district": "29",
            "Block": "357",
            "label": "R C M S PERUMALPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14597",
            "district": "29",
            "Block": "357",
            "label": "D N G H S S PERUMALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14598",
            "district": "29",
            "Block": "357",
            "label": "PUMS,PONNUSANGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14599",
            "district": "29",
            "Block": "357",
            "label": "PUMS,MADHURAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14600",
            "district": "29",
            "Block": "357",
            "label": "PUMS,CHITHTHIRAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14601",
            "district": "29",
            "Block": "357",
            "label": "C.S.I.H.S.S.THURAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14602",
            "district": "29",
            "Block": "357",
            "label": "AIDED M S,MARUVATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14603",
            "district": "29",
            "Block": "357",
            "label": "AIDED M S,PULIYAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14604",
            "district": "29",
            "Block": "357",
            "label": "SARASWATHI AIDED M S , MURUGUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14605",
            "district": "29",
            "Block": "357",
            "label": "AIDED M S , T.RENGANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14606",
            "district": "29",
            "Block": "357",
            "label": "GTRMS , NAGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14607",
            "district": "29",
            "Block": "357",
            "label": "GTRHSS,CHINNAILUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14608",
            "district": "29",
            "Block": "357",
            "label": "GHSS,SENGATUPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14609",
            "district": "29",
            "Block": "357",
            "label": "PUMS,VEERAMACHANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14610",
            "district": "29",
            "Block": "357",
            "label": "NEHRU M S,KAVERIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14611",
            "district": "29",
            "Block": "357",
            "label": "SPG M SCHOOL,THURAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14612",
            "district": "29",
            "Block": "357",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, THURAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14613",
            "district": "29",
            "Block": "357",
            "label": "G ADW HSS ,THURAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14614",
            "district": "29",
            "Block": "357",
            "label": "ZAMINDAR'S H S S ,THURAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23407",
            "district": "29",
            "Block": "357",
            "label": "Vedha Green School",
            "Sch_type": "Unaided",
            "Category": "Others"
        },
        {
            "key": "14615",
            "district": "29",
            "Block": "357",
            "label": "SENGUNTHAR H S S ,THURAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14616",
            "district": "29",
            "Block": "357",
            "label": "MUNICIPAL HIGH SCHOOL ,THURAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14617",
            "district": "29",
            "Block": "357",
            "label": "RAJA HR. SEC. SCHOOL,THURAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14618",
            "district": "29",
            "Block": "357",
            "label": "KALPANA KENDHRIYA ACADEMY (CBSE) SCHOOL,MURUGOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21164",
            "district": "29",
            "Block": "357",
            "label": "SOWDAMBIKAA MATRIC GIRLS HR. SEC. SCHOOL,THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21328",
            "district": "29",
            "Block": "357",
            "label": "SRM PUBLIC SCHOOL(CBSE),THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22189",
            "district": "29",
            "Block": "357",
            "label": "SRI BALAJI VIDHYALAYA MATRICULATION HR. SEC. SCH., GOVINDAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22190",
            "district": "29",
            "Block": "357",
            "label": "ATHAVAN ACADEMY MATRICULATION HS, PONNUSANGAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22191",
            "district": "29",
            "Block": "357",
            "label": "RAJ VIDYA BHAVAN GIRLS MATRICULATION HS, THURAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22532",
            "district": "29",
            "Block": "357",
            "label": "GHS, VENGADATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22533",
            "district": "29",
            "Block": "357",
            "label": "MAHATHMA SCHOOL OF EXCELLENCE,MURUGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22800",
            "district": "29",
            "Block": "357",
            "label": "Sri Krishna Vidhyalaya Matric School, MURUGUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "358": [
        {
            "key": "1865",
            "district": "34",
            "Block": "358",
            "label": "PUMS MARUTHUVAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2131",
            "district": "34",
            "Block": "358",
            "label": "SRGDS MATRIC HSS VADAANDAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2867",
            "district": "34",
            "Block": "358",
            "label": "PUMS KARUMARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3830",
            "district": "34",
            "Block": "358",
            "label": "SHISHYA MATIRC HSS MUNIYANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3891",
            "district": "34",
            "Block": "358",
            "label": "RCM MIDDLE BOODHAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4280",
            "district": "34",
            "Block": "358",
            "label": "PUMS V.NAMMIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4528",
            "district": "34",
            "Block": "358",
            "label": "PUMS USAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4582",
            "district": "34",
            "Block": "358",
            "label": "PUMS KALASTHAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5077",
            "district": "34",
            "Block": "358",
            "label": "PUMS PALAYAMANNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5139",
            "district": "34",
            "Block": "358",
            "label": "PUMS SANANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5140",
            "district": "34",
            "Block": "358",
            "label": "PUMS KAMALAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5570",
            "district": "34",
            "Block": "358",
            "label": "PUMS CHERIYANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5583",
            "district": "34",
            "Block": "358",
            "label": "PUMS-PORKUNAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5792",
            "district": "34",
            "Block": "358",
            "label": "PUMS VADA ANDAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5932",
            "district": "34",
            "Block": "358",
            "label": "PUMS M.PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5989",
            "district": "34",
            "Block": "358",
            "label": "PUMS THURINJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6154",
            "district": "34",
            "Block": "358",
            "label": "MARY IMMACULATE HEART MATRIC HSS KARIYANDAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6243",
            "district": "34",
            "Block": "358",
            "label": "PUMS-POYYANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6576",
            "district": "34",
            "Block": "358",
            "label": "PUMS UTHIRAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9771",
            "district": "34",
            "Block": "358",
            "label": "GHS ANANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9772",
            "district": "34",
            "Block": "358",
            "label": "GHS ARPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9773",
            "district": "34",
            "Block": "358",
            "label": "GHS DURGAINAMMIYANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9774",
            "district": "34",
            "Block": "358",
            "label": "GHS ERUMPOONDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9775",
            "district": "34",
            "Block": "358",
            "label": "GHSS INAMKARIYANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9776",
            "district": "34",
            "Block": "358",
            "label": "ATR VIDHYASHRAM INTERNATIONAL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9777",
            "district": "34",
            "Block": "358",
            "label": "GHS KATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9778",
            "district": "34",
            "Block": "358",
            "label": "SNS MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9779",
            "district": "34",
            "Block": "358",
            "label": "GHSS MALLAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9780",
            "district": "34",
            "Block": "358",
            "label": "GHSS (GIRLS) MALLAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9781",
            "district": "34",
            "Block": "358",
            "label": "SRI SAI VIDYA  MANDIR MATRICULATION SCHOOL, MALLAVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9782",
            "district": "34",
            "Block": "358",
            "label": "GHSS MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9783",
            "district": "34",
            "Block": "358",
            "label": "GANDHI INTERNATIONAL MATRIC",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9784",
            "district": "34",
            "Block": "358",
            "label": "GHS MADHALAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9785",
            "district": "34",
            "Block": "358",
            "label": "ST MARYS HS MARUTHUVAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9786",
            "district": "34",
            "Block": "358",
            "label": "GHS MEPPATHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9787",
            "district": "34",
            "Block": "358",
            "label": "GHSS NARTHAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9788",
            "district": "34",
            "Block": "358",
            "label": "DMHS NOOKAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9789",
            "district": "34",
            "Block": "358",
            "label": "GHS SADAYANODAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9790",
            "district": "34",
            "Block": "358",
            "label": "PUMS SEELAPANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9791",
            "district": "34",
            "Block": "358",
            "label": "GHSS SORAKOLATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9792",
            "district": "34",
            "Block": "358",
            "label": "GHS VALLIVAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9793",
            "district": "34",
            "Block": "358",
            "label": "GHSS VEDANTHAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9794",
            "district": "34",
            "Block": "358",
            "label": "GHS VELUGANANDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22415",
            "district": "34",
            "Block": "358",
            "label": "K-ZONE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22853",
            "district": "34",
            "Block": "358",
            "label": "PVK MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23120",
            "district": "34",
            "Block": "358",
            "label": "VIJAY VIDYA MANDIR CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23619",
            "district": "34",
            "Block": "358",
            "label": "SISHYA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23619",
            "district": "34",
            "Block": "358",
            "label": "SISHYA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "359": [
        {
            "key": "1637",
            "district": "21",
            "Block": "359",
            "label": "ARCOT SRI MAHALAKSHMI MATRIC HSS, VILAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2046",
            "district": "21",
            "Block": "359",
            "label": "PUMS ANAIMALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2577",
            "district": "21",
            "Block": "359",
            "label": "PUMS VELLAMBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2659",
            "district": "21",
            "Block": "359",
            "label": "PUMS PAZHAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2738",
            "district": "21",
            "Block": "359",
            "label": "PUMS MELNELLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2961",
            "district": "21",
            "Block": "359",
            "label": "PUMS KALAVAI PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2964",
            "district": "21",
            "Block": "359",
            "label": "PUMS RAGHUNATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3018",
            "district": "21",
            "Block": "359",
            "label": "PUMS MELAPALANTHAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3019",
            "district": "21",
            "Block": "359",
            "label": "PUMS MELPUDUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3046",
            "district": "21",
            "Block": "359",
            "label": "PUMS VEMBI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3176",
            "district": "21",
            "Block": "359",
            "label": "PUMS SORAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3497",
            "district": "21",
            "Block": "359",
            "label": "PUMS NAMBARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3516",
            "district": "21",
            "Block": "359",
            "label": "PUMS PARADHARAMI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3640",
            "district": "21",
            "Block": "359",
            "label": "PUMS CHENNASAMUTHRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3721",
            "district": "21",
            "Block": "359",
            "label": "PUMS KUTTIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3727",
            "district": "21",
            "Block": "359",
            "label": "PUMS AAYIRAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3890",
            "district": "21",
            "Block": "359",
            "label": "PUMS GUNDALERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3995",
            "district": "21",
            "Block": "359",
            "label": "PUMS PUNGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4315",
            "district": "21",
            "Block": "359",
            "label": "MOTHER MATRIC SCHOOL, KALAVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4438",
            "district": "21",
            "Block": "359",
            "label": "PUMS CHENGANAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4539",
            "district": "21",
            "Block": "359",
            "label": "PUMS KANIYANUR PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5561",
            "district": "21",
            "Block": "359",
            "label": "PUMS VARAGUR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5833",
            "district": "21",
            "Block": "359",
            "label": "YOGI VEMANA MATRIC SCHOOL, THIMIRI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6351",
            "district": "21",
            "Block": "359",
            "label": "PUMS VENKATAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8582",
            "district": "21",
            "Block": "359",
            "label": "LORD MACAULAY MATRIC HSS, CHINNASALAMANATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8583",
            "district": "21",
            "Block": "359",
            "label": "GHSS KAVANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8584",
            "district": "21",
            "Block": "359",
            "label": "AAGHSS DHAMARAIPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8585",
            "district": "21",
            "Block": "359",
            "label": "GHS MAZHAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8586",
            "district": "21",
            "Block": "359",
            "label": "GHS KANIYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8587",
            "district": "21",
            "Block": "359",
            "label": "GHS IRUNGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8588",
            "district": "21",
            "Block": "359",
            "label": "GHSS PENNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8589",
            "district": "21",
            "Block": "359",
            "label": "GHSS MAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8590",
            "district": "21",
            "Block": "359",
            "label": "GHSS VALAPANDAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8591",
            "district": "21",
            "Block": "359",
            "label": "GBHSS THIMIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8592",
            "district": "21",
            "Block": "359",
            "label": "GGHSS THIMIRI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8593",
            "district": "21",
            "Block": "359",
            "label": "AAGHSS VILAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8594",
            "district": "21",
            "Block": "359",
            "label": "GGHSS KALAVAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8595",
            "district": "21",
            "Block": "359",
            "label": "GBHSS KALAVAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8596",
            "district": "21",
            "Block": "359",
            "label": "GHSS VALAYATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21575",
            "district": "21",
            "Block": "359",
            "label": "DR.VGN MATRIC SCHOOL, KALAVAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22062",
            "district": "21",
            "Block": "359",
            "label": "Dr.S.R.K VIDHYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22383",
            "district": "21",
            "Block": "359",
            "label": "SRI RAMAKRISHNA VIDHYALAYA NATIONAL INSTITUTE OF OPEN SCHOOLING",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22738",
            "district": "21",
            "Block": "359",
            "label": "THE KRISAR ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22739",
            "district": "21",
            "Block": "359",
            "label": "VEDHA VIDHYA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22740",
            "district": "21",
            "Block": "359",
            "label": "SRI JAYAM NAMAKKAL MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23536",
            "district": "21",
            "Block": "359",
            "label": "Mahalakshmi Vidhyashram School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23536",
            "district": "21",
            "Block": "359",
            "label": "Mahalakshmi Vidhyashram School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24035",
            "district": "21",
            "Block": "359",
            "label": "GHSS MAMBAKKAM",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "360": [
        {
            "key": "1607",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, V.MOREPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2156",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SIRUMOLASI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2308",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, T.KOLLAPATTI WARD 30",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2370",
            "district": "17",
            "Block": "360",
            "label": "R .VIDYUT MATRIC  HIGHER SEC SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2409",
            "district": "17",
            "Block": "360",
            "label": "SENGUNTHAR KALVI NILAYAM WARD 6",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2425",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, VALRASAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2564",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, SEETHARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2568",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KEELERIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2655",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL  MIDDLE SCHOOL, SANARPALAYAM WARD 29",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2822",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, MALAYADIVARAM WARD 24",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2955",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, SURIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3169",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL MIDDLE SCHOOL, ARIGNAR ANNA  NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3499",
            "district": "17",
            "Block": "360",
            "label": "OVVAI KALVI NILAYAM MIDDLE SCHOOL TIRUCHENGODE",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3553",
            "district": "17",
            "Block": "360",
            "label": "VIDYAA VIKAS INTERNATIONAL SCHOOL,CBSE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3601",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ,NARAYANIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3712",
            "district": "17",
            "Block": "360",
            "label": "VISHNU VIDHYALAYA MATRICULATION SCHOOL, ETTIMADAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3742",
            "district": "17",
            "Block": "360",
            "label": "MAHADEVA VIDHYALAYAM MIDDLE SCHOOL, WARD 17",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4930",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, V PETHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4933",
            "district": "17",
            "Block": "360",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UPPILIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4937",
            "district": "17",
            "Block": "360",
            "label": "KSR MATRIC HIGHER SECONDARY SCHOOL, THOKKAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5803",
            "district": "17",
            "Block": "360",
            "label": "AVVAI KSR MATRICULATION SCHOOL, TIRUCHENGODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5864",
            "district": "17",
            "Block": "360",
            "label": "KALAIMAGAL KALVI NILAYAM MIDDLE SCHOOL SALAPALAYAM WARD 32",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6647",
            "district": "17",
            "Block": "360",
            "label": "VALARAIGATE VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11892",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THANNEERPANDALPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11893",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGH SCHOOL, ANANGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11894",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGH SCHOOL, PATLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11895",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ERAYAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11896",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGH SCHOOL, MOLASI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11897",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VITTAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11898",
            "district": "17",
            "Block": "360",
            "label": "A.S.S MATRICULATION HIGHER SECONDARY SCHOOL, KUCHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11899",
            "district": "17",
            "Block": "360",
            "label": "KSR AKSHARA ACADEMY  THOKKAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11900",
            "district": "17",
            "Block": "360",
            "label": "GHSS DEVANANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11901",
            "district": "17",
            "Block": "360",
            "label": "VETRI O.S.S. HIGH SCHOOL, ANANGOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11902",
            "district": "17",
            "Block": "360",
            "label": "GOVT GIRLS HIGHER SEC. SCHOOL, TIRUCHENGODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11903",
            "district": "17",
            "Block": "360",
            "label": "GOVT BOYS HIGHER SECONDARY SCHOOL,TIRUCHENGODE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11904",
            "district": "17",
            "Block": "360",
            "label": "MAHADEVA VIDYALAYAM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11905",
            "district": "17",
            "Block": "360",
            "label": "SAINT ANTONY HIGHER SECONDARY SCHOOL, WARD 5",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11906",
            "district": "17",
            "Block": "360",
            "label": "NEW OXFORD MATRICULATION SCHOOL, KARATTUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11907",
            "district": "17",
            "Block": "360",
            "label": "SEVENTH-DAY ADVENTIST MATRICULATION HIGHER SECONDARY SCHOOL,TIRUCHENGODE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11908",
            "district": "17",
            "Block": "360",
            "label": "HOLY ANGELS MATRIC HR.SEC.SCHOOL NEIKARAPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11909",
            "district": "17",
            "Block": "360",
            "label": "VRIKSHA GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11910",
            "district": "17",
            "Block": "360",
            "label": "VIDYAA VIKAS MATRIC HR. SEC. SCHOOL, VARAHOORAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11911",
            "district": "17",
            "Block": "360",
            "label": "VIDYAA VIKAS GIRLS HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11912",
            "district": "17",
            "Block": "360",
            "label": "GOVT. HIGH SCHOOL, ANDIPALYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11913",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, CHITTALANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11914",
            "district": "17",
            "Block": "360",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOZHIKKALNATHAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11915",
            "district": "17",
            "Block": "360",
            "label": "SPM HIGHER SECONDARY SCHOOL KOZHIKKALNATHAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11916",
            "district": "17",
            "Block": "360",
            "label": "NALANDAS HIGHER SECONDARY SCHOOL, CHINNATHAMBIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21024",
            "district": "17",
            "Block": "360",
            "label": "MEDHA MATRICULATION SCHOOL, PUDHUPULIYAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21071",
            "district": "17",
            "Block": "360",
            "label": "THE MERIDIAN INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21664",
            "district": "17",
            "Block": "360",
            "label": "BARANI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22968",
            "district": "17",
            "Block": "360",
            "label": "GOVT HIGH SCHOOL, KARUMAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23253",
            "district": "17",
            "Block": "360",
            "label": "MUNICIPAL HIGH SCHOOL, NESAVALAR COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23521",
            "district": "17",
            "Block": "360",
            "label": "THE JPS ACADEMY MATRICULATION SCHOOL, TIRUCHENGODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23521",
            "district": "17",
            "Block": "360",
            "label": "THE JPS ACADEMY MATRICULATION SCHOOL, TIRUCHENGODE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24036",
            "district": "17",
            "Block": "360",
            "label": "G GLOBAL SCHOOL, RAJAGOUNDAMPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "361": [
        {
            "key": "1718",
            "district": "30",
            "Block": "361",
            "label": "STC Branch MS, Sindupoonthurai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1892",
            "district": "30",
            "Block": "361",
            "label": "Adaikalamatha Mat. HS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2155",
            "district": "30",
            "Block": "361",
            "label": "Nivedita Mat. HS, Palaya Pettai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2269",
            "district": "30",
            "Block": "361",
            "label": "Socratees Mat. HS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2865",
            "district": "30",
            "Block": "361",
            "label": "Thayammai Ammal MS, Tirunelveli",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3179",
            "district": "30",
            "Block": "361",
            "label": "CORPORATION MIDDLE SCHOOL RAHUMANPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3644",
            "district": "30",
            "Block": "361",
            "label": "Hindu MS, M V Puram, TVL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3933",
            "district": "30",
            "Block": "361",
            "label": "Little Flower Mat. HSS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4056",
            "district": "30",
            "Block": "361",
            "label": "Presentation Conv. Mat. HS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4403",
            "district": "30",
            "Block": "361",
            "label": "IDEAL MATRICULATION SCHOOL, PETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4588",
            "district": "30",
            "Block": "361",
            "label": "Valliammai Mat. MS, Tvl",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5691",
            "district": "30",
            "Block": "361",
            "label": "Our Own Modern Mat. HSS, Pettai",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20051",
            "district": "30",
            "Block": "361",
            "label": "GOVERNMENT HR SEC SCHOOL,TIRUNELVELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20052",
            "district": "30",
            "Block": "361",
            "label": "SCHAFFTER HIGHER SECONDARY SCHOOL, TIRUNELVELI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20053",
            "district": "30",
            "Block": "361",
            "label": "THE MDT HINDU COLLEGE HR.SEC SCHOOL,TIRUNELVELI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20054",
            "district": "30",
            "Block": "361",
            "label": "Mpl. (G) HSS,  Meenakshipuram, Tirunelveli Junction",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20055",
            "district": "30",
            "Block": "361",
            "label": "Bharathiar Mpl. HS, Tirunelveli",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20056",
            "district": "30",
            "Block": "361",
            "label": "STC Branch Upper Clapton Middle School,TVL Town",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20057",
            "district": "30",
            "Block": "361",
            "label": "Jawahar Govt. HS, Tirunelveli",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20058",
            "district": "30",
            "Block": "361",
            "label": "Mpl. (G) HSS, Kallanai, TVL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20059",
            "district": "30",
            "Block": "361",
            "label": "Sri Manthiramoorthy HSS, Tirunelveli",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20060",
            "district": "30",
            "Block": "361",
            "label": "Meenakshi Matric. Hr. Sec School, Tvl",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20061",
            "district": "30",
            "Block": "361",
            "label": "LITTLE FLOWER MATRIC HR. SEC. SCHOOL TIRUNELVELI TOWN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20062",
            "district": "30",
            "Block": "361",
            "label": "Gaffoor Middle School, Pettai",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "20063",
            "district": "30",
            "Block": "361",
            "label": "Mpl. (G) HSS, Pettai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20064",
            "district": "30",
            "Block": "361",
            "label": "Kamaraj Mpl. HSS, Pettai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20065",
            "district": "30",
            "Block": "361",
            "label": "St. Antony's HS, Pettai",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20066",
            "district": "30",
            "Block": "361",
            "label": "Ooliyasthanam MS, Palayapettai",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20067",
            "district": "30",
            "Block": "361",
            "label": "Hindu Middle School, Matha Middle. Street., TVL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20068",
            "district": "30",
            "Block": "361",
            "label": "TDTA MS, Arasaradi Palam, TVL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21823",
            "district": "30",
            "Block": "361",
            "label": "ROSE MARY IDEAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23075",
            "district": "30",
            "Block": "361",
            "label": "MAHATHMA GANDHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23112",
            "district": "30",
            "Block": "361",
            "label": "SOCRATEES MODEL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "362": [
        {
            "key": "1472",
            "district": "31",
            "Block": "362",
            "label": "PUMS THALUGAN VATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1548",
            "district": "31",
            "Block": "362",
            "label": "AL-AMEEN MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1618",
            "district": "31",
            "Block": "362",
            "label": "PUMS,  PAZHAYA ATHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1666",
            "district": "31",
            "Block": "362",
            "label": "PUMS KATHIRIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1681",
            "district": "31",
            "Block": "362",
            "label": "PUMS PICHANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1805",
            "district": "31",
            "Block": "362",
            "label": "PUMS JOLLAGOUNDANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1834",
            "district": "31",
            "Block": "362",
            "label": "MUNICIPAL OLD MUSLIM GIRLS' MIDDLE SCHOOL, MOSQUE STREET,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23677",
            "district": "31",
            "Block": "362",
            "label": "Shakespere Matriculation school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23669",
            "district": "31",
            "Block": "362",
            "label": "Sri Nandhanam International School (CBSE)",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "1952",
            "district": "31",
            "Block": "362",
            "label": "PUMS JAMMANNAPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1987",
            "district": "31",
            "Block": "362",
            "label": "PUMS MAKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2038",
            "district": "31",
            "Block": "362",
            "label": "PUMS,AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2067",
            "district": "31",
            "Block": "362",
            "label": "MUNICIPAL OLD MUSLIM BOYS' MIDDLE SCHOOL, MOSQUE STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2193",
            "district": "31",
            "Block": "362",
            "label": "CSI GIRLS MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2223",
            "district": "31",
            "Block": "362",
            "label": "PUMS PASALIKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2253",
            "district": "31",
            "Block": "362",
            "label": "PUMS T. KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2268",
            "district": "31",
            "Block": "362",
            "label": "OUR LADY'S MATRICULATION SCHOOL THIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2433",
            "district": "31",
            "Block": "362",
            "label": "PUMS KAVARAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2448",
            "district": "31",
            "Block": "362",
            "label": "LIONS MATRIC HSS THIRUPATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2454",
            "district": "31",
            "Block": "362",
            "label": "PUMS THIRUMAL NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2858",
            "district": "31",
            "Block": "362",
            "label": "GOODWILL MATRIC HSS TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3002",
            "district": "31",
            "Block": "362",
            "label": "PUMS KALIYAMMAN KOIL NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3035",
            "district": "31",
            "Block": "362",
            "label": "PUMS, VELANNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3070",
            "district": "31",
            "Block": "362",
            "label": "PUMS THATHAVALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3101",
            "district": "31",
            "Block": "362",
            "label": "PUMS LALAPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3299",
            "district": "31",
            "Block": "362",
            "label": "SRI RAMAKRISHNA VIDHYALAYA MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3452",
            "district": "31",
            "Block": "362",
            "label": "PUMS SEERANGAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3545",
            "district": "31",
            "Block": "362",
            "label": "PUMS ANERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3847",
            "district": "31",
            "Block": "362",
            "label": "PUMS NARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3868",
            "district": "31",
            "Block": "362",
            "label": "PUMS, KAKANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3913",
            "district": "31",
            "Block": "362",
            "label": "PUMS, KEELUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3983",
            "district": "31",
            "Block": "362",
            "label": "PUMS PUDHU POONGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4020",
            "district": "31",
            "Block": "362",
            "label": "SHEPHERDS MATRIC HSS, MADAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4133",
            "district": "31",
            "Block": "362",
            "label": "PUMS, VAZHUTHALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4342",
            "district": "31",
            "Block": "362",
            "label": "PUMS KOODAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4391",
            "district": "31",
            "Block": "362",
            "label": "PUMS VINAYAGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4537",
            "district": "31",
            "Block": "362",
            "label": "PUMS IRUNAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5189",
            "district": "31",
            "Block": "362",
            "label": "PUMS POORIGAMANIMITTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5600",
            "district": "31",
            "Block": "362",
            "label": "AMS RCM KOVILUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6619",
            "district": "31",
            "Block": "362",
            "label": "YMCA BROWN MEMORIAL MATRIC HSS, TIRUPATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8946",
            "district": "31",
            "Block": "362",
            "label": "FORESTMS, THAGARAKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8947",
            "district": "31",
            "Block": "362",
            "label": "FOREST MS, PERUMPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8948",
            "district": "31",
            "Block": "362",
            "label": "GOV.TRIBLRES.SC,ARASAMARATHUKOLLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8949",
            "district": "31",
            "Block": "362",
            "label": "FOREST HSS PUDURNADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8950",
            "district": "31",
            "Block": "362",
            "label": "PUMS, MELPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8951",
            "district": "31",
            "Block": "362",
            "label": "FOREST HSS NELLIVASALNADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8952",
            "district": "31",
            "Block": "362",
            "label": "GHS PULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9062",
            "district": "31",
            "Block": "362",
            "label": "GHSS PALNANGKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9063",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS ANNADAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9064",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS ACHAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9065",
            "district": "31",
            "Block": "362",
            "label": "GOVT GIRLS HSS MADAVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9066",
            "district": "31",
            "Block": "362",
            "label": "GOVT BOYS HSS MADAVALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9067",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS KODUMMAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9068",
            "district": "31",
            "Block": "362",
            "label": "GHS ARUNTHATHIYAR  COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9069",
            "district": "31",
            "Block": "362",
            "label": "GOVT HSS  BOMMIKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9070",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS THIMMANAMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9071",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS JAMANAPUDURPOONGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9076",
            "district": "31",
            "Block": "362",
            "label": "GOVT HSS ANDIYAPPANOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9077",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS PERUMAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9078",
            "district": "31",
            "Block": "362",
            "label": "GOVT HSS VADUKAMUTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9079",
            "district": "31",
            "Block": "362",
            "label": "AHSS TMS TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9080",
            "district": "31",
            "Block": "362",
            "label": "AHSS RAMAKRISHNA TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9081",
            "district": "31",
            "Block": "362",
            "label": "DOMINIC SAVIO HSS TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9082",
            "district": "31",
            "Block": "362",
            "label": "AGHSS UBAIBAS TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9083",
            "district": "31",
            "Block": "362",
            "label": "MARY IMMACULATE GHSS TIRUPATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9084",
            "district": "31",
            "Block": "362",
            "label": "GOVT BOYS HSS TIRUPATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9085",
            "district": "31",
            "Block": "362",
            "label": "GOVT MGHSS TIRUPATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9086",
            "district": "31",
            "Block": "362",
            "label": "GOVT GARDEN HS TIRUPATTUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9087",
            "district": "31",
            "Block": "362",
            "label": "AHSS OSMANIA TIRUPATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20954",
            "district": "31",
            "Block": "362",
            "label": "SWAMI VIVEKANADA MATRIC HSS, THIMMANAMUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21058",
            "district": "31",
            "Block": "362",
            "label": "DAYANANDA VIDYALAYA MATRIC HR. SEC. SCHOOL, KURUSILAPATTU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21440",
            "district": "31",
            "Block": "362",
            "label": "GOVT HS NONDI MARIYAMMAN KOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21494",
            "district": "31",
            "Block": "362",
            "label": "ADWHS JADAYANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22399",
            "district": "31",
            "Block": "362",
            "label": "EKLAVYA MODEL RESIDENTIAL HR.SEC SCHOOL,PUDURNADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23593",
            "district": "31",
            "Block": "362",
            "label": "TIRUPATHUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        }
    ],
    "363": [
        {
            "key": "502",
            "district": "32",
            "Block": "363",
            "label": "BHARATH MATRICULATION SCHOOL, VEERAPANDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "650",
            "district": "32",
            "Block": "363",
            "label": "LEARNING HEARTS HS,15 VELAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "692",
            "district": "32",
            "Block": "363",
            "label": "PEM SCHOOL OF EXCELLANCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "694",
            "district": "32",
            "Block": "363",
            "label": "OXFORD MODERN MATRIC SCHOOL, MUTHANAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "699",
            "district": "32",
            "Block": "363",
            "label": "BALABHAVAN GLOBAL MHSS, KARUMARAMPALAYAM, TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "708",
            "district": "32",
            "Block": "363",
            "label": "PLATO'S ACADEMY MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "723",
            "district": "32",
            "Block": "363",
            "label": "LITTLE FLOWER MAT HSS,ANDIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "731",
            "district": "32",
            "Block": "363",
            "label": "TNSS GANDHI VIDHYALAYA MATRIC HR SEC SCHOOL,VEERAPANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "737",
            "district": "32",
            "Block": "363",
            "label": "V A T T MHSS, TIRUPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "739",
            "district": "32",
            "Block": "363",
            "label": "NYRUTHI VIDHYA BHAVAN MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "740",
            "district": "32",
            "Block": "363",
            "label": "ST.JOSEPH'S MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "744",
            "district": "32",
            "Block": "363",
            "label": "VELAVAN MHS,TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "745",
            "district": "32",
            "Block": "363",
            "label": "CENTURY FOUNDATION MHSS,TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "756",
            "district": "32",
            "Block": "363",
            "label": "AZAD MHSS,,NACHIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "800",
            "district": "32",
            "Block": "363",
            "label": "THE FRONT LINE ACADEMY MAT HSS,PERUNTHOLUVU ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23680",
            "district": "32",
            "Block": "363",
            "label": "Mugi Kids Nursery & PrimarySchool",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "804",
            "district": "32",
            "Block": "363",
            "label": "VIGNESWARA VIDYALAYA MHSS,TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "813",
            "district": "32",
            "Block": "363",
            "label": "SRI SAI MATRIC HR.SEC. SCHOOL , RAM NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "814",
            "district": "32",
            "Block": "363",
            "label": "DHEVA MATRICULATION HR.SEC.SCHOOL,K.CHETTIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "823",
            "district": "32",
            "Block": "363",
            "label": "KGS MHS,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "841",
            "district": "32",
            "Block": "363",
            "label": "Saradha Vidhyalaya Matric HSS, Pandiyan Nagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "844",
            "district": "32",
            "Block": "363",
            "label": "VIDHYAA VIKAS MATRICULATION HIGHER SECONDARY SCHOOL-KULATHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "848",
            "district": "32",
            "Block": "363",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRIC HR SEC SCHOOL,AMMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "850",
            "district": "32",
            "Block": "363",
            "label": "MMS, ARANMANAIPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "860",
            "district": "32",
            "Block": "363",
            "label": "Kathiravan Matric. Hr. Sec. School,K.V.R NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "863",
            "district": "32",
            "Block": "363",
            "label": "PUMS ATHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "875",
            "district": "32",
            "Block": "363",
            "label": "VIDHYA VIKASHNI MATRIC.HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "882",
            "district": "32",
            "Block": "363",
            "label": "JAI SARADHA MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "886",
            "district": "32",
            "Block": "363",
            "label": "PUMS,NEHRU NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "893",
            "district": "32",
            "Block": "363",
            "label": "PUMS, KUPPANDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "901",
            "district": "32",
            "Block": "363",
            "label": "VIDYA MANDIR MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "914",
            "district": "32",
            "Block": "363",
            "label": "PUMS, THIRU KUMARAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "923",
            "district": "32",
            "Block": "363",
            "label": "VEERA SIVAJI VIDHYALAYA MATRIC HR .SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "954",
            "district": "32",
            "Block": "363",
            "label": "SUBBIAH MATRIC HR.SEC.SCHOOL, TIRUPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "956",
            "district": "32",
            "Block": "363",
            "label": "SUBASH MATRIC HSS, MURUGAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "957",
            "district": "32",
            "Block": "363",
            "label": "PUMS, PALAVANJIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "960",
            "district": "32",
            "Block": "363",
            "label": "KIDS CLUB MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1011",
            "district": "32",
            "Block": "363",
            "label": "WISDOM MHSS,,IDUVAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1022",
            "district": "32",
            "Block": "363",
            "label": "PUMS ,A CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1024",
            "district": "32",
            "Block": "363",
            "label": "PUMS,T.MANNARAI,TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1047",
            "district": "32",
            "Block": "363",
            "label": "MMS, KARUMARAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1078",
            "district": "32",
            "Block": "363",
            "label": "PUMS, BHARATHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1103",
            "district": "32",
            "Block": "363",
            "label": "MMS.KADERPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1110",
            "district": "32",
            "Block": "363",
            "label": "MMS,PALAYANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1129",
            "district": "32",
            "Block": "363",
            "label": "PUMS, SIDCO",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1148",
            "district": "32",
            "Block": "363",
            "label": "NATIONAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1158",
            "district": "32",
            "Block": "363",
            "label": "VIKAS VIDYALAYA MHSS,KOOLIPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1174",
            "district": "32",
            "Block": "363",
            "label": "PUMS, PALLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1175",
            "district": "32",
            "Block": "363",
            "label": "MMS,MUTHUPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1182",
            "district": "32",
            "Block": "363",
            "label": "PUMS, BHARATHIPURAM,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1188",
            "district": "32",
            "Block": "363",
            "label": "AVP TRUST MATRICULATION HR SEC SCHOOL, GANDHI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1198",
            "district": "32",
            "Block": "363",
            "label": "PUMS CHERANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1208",
            "district": "32",
            "Block": "363",
            "label": "SRI KALAIMAGAL VIDHYALAYA MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1216",
            "district": "32",
            "Block": "363",
            "label": "SRI KAMATCHI AMMAN MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1224",
            "district": "32",
            "Block": "363",
            "label": "MMS GOLDEN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1229",
            "district": "32",
            "Block": "363",
            "label": "PUMS CHINNANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1249",
            "district": "32",
            "Block": "363",
            "label": "PUMS SULTHANPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1268",
            "district": "32",
            "Block": "363",
            "label": "PUMS, ANDIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1282",
            "district": "32",
            "Block": "363",
            "label": "PUMS, SIRUPOOLUVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1339",
            "district": "32",
            "Block": "363",
            "label": "PUMS, CHANDRAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1360",
            "district": "32",
            "Block": "363",
            "label": "MMS,MANNARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1398",
            "district": "32",
            "Block": "363",
            "label": "PUMS, VATTAKATTUPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1425",
            "district": "32",
            "Block": "363",
            "label": "PALANIAMMALMGHSS, TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1445",
            "district": "32",
            "Block": "363",
            "label": "MMS. CHANDRAKAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1458",
            "district": "32",
            "Block": "363",
            "label": "PUMS AGRAHARAPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1466",
            "district": "32",
            "Block": "363",
            "label": "PUMS KANCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1518",
            "district": "32",
            "Block": "363",
            "label": "MMS, KANGEYAMPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1525",
            "district": "32",
            "Block": "363",
            "label": "BHARATHI KIDS KSHETHRALAYA SCHOOL, TIRUPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1527",
            "district": "32",
            "Block": "363",
            "label": "SRI VASAVI VIDYALAYA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1540",
            "district": "32",
            "Block": "363",
            "label": "KONGU MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1572",
            "district": "32",
            "Block": "363",
            "label": "PUMS, PONNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1768",
            "district": "32",
            "Block": "363",
            "label": "PUMS, KETHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1769",
            "district": "32",
            "Block": "363",
            "label": "PUMS, ANUPPARPALAYAMPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1802",
            "district": "32",
            "Block": "363",
            "label": "MMS,VELLIANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1888",
            "district": "32",
            "Block": "363",
            "label": "PUMS,SAMATHUVAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1910",
            "district": "32",
            "Block": "363",
            "label": "INFANT JESUS MHSS, KUMAR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1977",
            "district": "32",
            "Block": "363",
            "label": "PUMS,PERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2089",
            "district": "32",
            "Block": "363",
            "label": "NIRMALA MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2129",
            "district": "32",
            "Block": "363",
            "label": "PUMS PERIYAR COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2239",
            "district": "32",
            "Block": "363",
            "label": "SHRI VALLI VIDHYALAYAA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2347",
            "district": "32",
            "Block": "363",
            "label": "RAJALINGAM KALVI NILAYAM,AIDED MDL SCHOOL, AMARAVATHIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2366",
            "district": "32",
            "Block": "363",
            "label": "PUMS,APPIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2408",
            "district": "32",
            "Block": "363",
            "label": "PUMS, BOYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2463",
            "district": "32",
            "Block": "363",
            "label": "PUMS, MURUGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2506",
            "district": "32",
            "Block": "363",
            "label": "PUMS, 15 VELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2575",
            "district": "32",
            "Block": "363",
            "label": "MMS, BALAMURUGAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2629",
            "district": "32",
            "Block": "363",
            "label": "MMS,Thennampalayam,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2648",
            "district": "32",
            "Block": "363",
            "label": "GMS KOOLIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2669",
            "district": "32",
            "Block": "363",
            "label": "PUMS,NERUPPERICHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2710",
            "district": "32",
            "Block": "363",
            "label": "PUMS,KALIPALAYAMPUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2758",
            "district": "32",
            "Block": "363",
            "label": "M.N. CHICKKANNA CHETTIAR MAT HIGH SCHOOL,, PALAYAKADU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2828",
            "district": "32",
            "Block": "363",
            "label": "MMS,RAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3147",
            "district": "32",
            "Block": "363",
            "label": "MMS, POOLAVARISUGUMAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3217",
            "district": "32",
            "Block": "363",
            "label": "Annai Matric Tiruppur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3368",
            "district": "32",
            "Block": "363",
            "label": "SHRI SARASWATHI GIRI MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3427",
            "district": "32",
            "Block": "363",
            "label": "KONGU VELLALAR MHSS, ANGERIPALAYAM ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3512",
            "district": "32",
            "Block": "363",
            "label": "MMS, CHELLAPPAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3648",
            "district": "32",
            "Block": "363",
            "label": "MMS,THIRUNEELAKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3675",
            "district": "32",
            "Block": "363",
            "label": "PUMS,MUMMOORTHY NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3968",
            "district": "32",
            "Block": "363",
            "label": "PUMS, SAMUNDIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4041",
            "district": "32",
            "Block": "363",
            "label": "THIRUMURUGAN MHS,NERUPPERICHAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4345",
            "district": "32",
            "Block": "363",
            "label": "KMCPUBLIC SCHOOL,,PERUMANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4444",
            "district": "32",
            "Block": "363",
            "label": "MMS SV COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4521",
            "district": "32",
            "Block": "363",
            "label": "MMS ,Mettupalayam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4820",
            "district": "32",
            "Block": "363",
            "label": "BHARATHI VIKAS MAT SCHOOL,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5461",
            "district": "32",
            "Block": "363",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5486",
            "district": "32",
            "Block": "363",
            "label": "MMS,DEVANGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5917",
            "district": "32",
            "Block": "363",
            "label": "MMS PAPPANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6223",
            "district": "32",
            "Block": "363",
            "label": "PUMS, AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13253",
            "district": "32",
            "Block": "363",
            "label": "AVP TRUST NATIONAL MATRIC HR SEC SCHOOL, ATHUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13254",
            "district": "32",
            "Block": "363",
            "label": "GHSS, AYYANKALIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13255",
            "district": "32",
            "Block": "363",
            "label": "PUMS,ETTIVEERAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13256",
            "district": "32",
            "Block": "363",
            "label": "GHS, IDUVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13257",
            "district": "32",
            "Block": "363",
            "label": "GHS GURUVAYURAPPAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13258",
            "district": "32",
            "Block": "363",
            "label": "SIVA SAKTHI MHSS,TIRUPPUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13259",
            "district": "32",
            "Block": "363",
            "label": "SAKTHI VIGNESWARA KALVI NILAYAM HR SEC SCHOOL, PONGUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13260",
            "district": "32",
            "Block": "363",
            "label": "GHSS KANAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13261",
            "district": "32",
            "Block": "363",
            "label": "PUMS SOKKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13262",
            "district": "32",
            "Block": "363",
            "label": "PUMS,THORAVALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13263",
            "district": "32",
            "Block": "363",
            "label": "G BOYS HSS PERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13264",
            "district": "32",
            "Block": "363",
            "label": "AVS CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13265",
            "district": "32",
            "Block": "363",
            "label": "GHS PANDIAN NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13266",
            "district": "32",
            "Block": "363",
            "label": "Murugu Matric Hr.Sec.School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13267",
            "district": "32",
            "Block": "363",
            "label": "APS ACADAMY MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13268",
            "district": "32",
            "Block": "363",
            "label": "GHS POOLUVAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13269",
            "district": "32",
            "Block": "363",
            "label": "GHS VAVIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13270",
            "district": "32",
            "Block": "363",
            "label": "PUMS, VALLIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13271",
            "district": "32",
            "Block": "363",
            "label": "TAGORE VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13272",
            "district": "32",
            "Block": "363",
            "label": "GHS PICHAM PALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13273",
            "district": "32",
            "Block": "363",
            "label": "PUMS, RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13274",
            "district": "32",
            "Block": "363",
            "label": "GHSS ANUPPARPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13275",
            "district": "32",
            "Block": "363",
            "label": "VETRI VIDYALAYA MATRIC SCHOOL, SANKARAN THOTTAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13276",
            "district": "32",
            "Block": "363",
            "label": "SRI THANDAPANI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13277",
            "district": "32",
            "Block": "363",
            "label": "GHSS MANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13278",
            "district": "32",
            "Block": "363",
            "label": "PUMS,SUNDAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13279",
            "district": "32",
            "Block": "363",
            "label": "GHSS,IDUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13280",
            "district": "32",
            "Block": "363",
            "label": "GHSS,VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13281",
            "district": "32",
            "Block": "363",
            "label": "GHS KARUPPAGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13282",
            "district": "32",
            "Block": "363",
            "label": "PUMS,MUTHANAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13283",
            "district": "32",
            "Block": "363",
            "label": "Vivekananda Vidyalaya Matric Hr. Sec, K.Chettipalayam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13284",
            "district": "32",
            "Block": "363",
            "label": "ADW HS,KOVILVAZHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13285",
            "district": "32",
            "Block": "363",
            "label": "GHS K.CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13286",
            "district": "32",
            "Block": "363",
            "label": "PUMS, GENGANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13287",
            "district": "32",
            "Block": "363",
            "label": "GHS MUDHALIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13288",
            "district": "32",
            "Block": "363",
            "label": "ABACUS INT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13289",
            "district": "32",
            "Block": "363",
            "label": "GHSS,VIJAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13290",
            "district": "32",
            "Block": "363",
            "label": "BISHOP UBAGARASWAMY HIGHER SECONDARY SCHOOL, TIRUPPUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13291",
            "district": "32",
            "Block": "363",
            "label": "SHRI MAKESH VIDYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13292",
            "district": "32",
            "Block": "363",
            "label": "MHSS,KUMAR NAGAR,TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13293",
            "district": "32",
            "Block": "363",
            "label": "MHS,NESAWALAR COLONY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13294",
            "district": "32",
            "Block": "363",
            "label": "MHSS,NEW RAMAKRISHNAPURAM, TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13295",
            "district": "32",
            "Block": "363",
            "label": "CHINNASAMY AMMAL BOYS MHSS,TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13296",
            "district": "32",
            "Block": "363",
            "label": "ST.S JOSEPH'S HIGH SCHOOL, KUMARAN ROAD,TIRUPPUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "13297",
            "district": "32",
            "Block": "363",
            "label": "PREMA MAT HSS, COLLEGE ROAD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13298",
            "district": "32",
            "Block": "363",
            "label": "KAIRALI VIDHYALAYAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13299",
            "district": "32",
            "Block": "363",
            "label": "JAIVABAI MGHSS -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13300",
            "district": "32",
            "Block": "363",
            "label": "NANJAPPA MPL BOYS HR SEC SCHOOL TIRUPPUR.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13301",
            "district": "32",
            "Block": "363",
            "label": "ROTARY MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13302",
            "district": "32",
            "Block": "363",
            "label": "MMS,Parappalayam,",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13303",
            "district": "32",
            "Block": "363",
            "label": "MHS, KARUVAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13304",
            "district": "32",
            "Block": "363",
            "label": "KSC GOVT HR SEC SCHOOL TIRUPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13305",
            "district": "32",
            "Block": "363",
            "label": "M N MURUGAPPA CHETTIYAR GHSS, TIRUPPUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13306",
            "district": "32",
            "Block": "363",
            "label": "MUNICIPAL HIGH SCHOOL,NOYYAL STREET,TIRUPPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13307",
            "district": "32",
            "Block": "363",
            "label": "AL-AMEEN HIGH SCHOOL, KANGEYAM ROAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21088",
            "district": "32",
            "Block": "363",
            "label": "KIDS CLUB SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21158",
            "district": "32",
            "Block": "363",
            "label": "DHANAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21159",
            "district": "32",
            "Block": "363",
            "label": "EDEN GARDENS MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21184",
            "district": "32",
            "Block": "363",
            "label": "LITTLE KINGDOM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21246",
            "district": "32",
            "Block": "363",
            "label": "VIDHYASAGAR INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21276",
            "district": "32",
            "Block": "363",
            "label": "MARUTHI EXCEL MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21277",
            "district": "32",
            "Block": "363",
            "label": "MANI PUBLIC ACADEMY MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21319",
            "district": "32",
            "Block": "363",
            "label": "SMART MODERN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21320",
            "district": "32",
            "Block": "363",
            "label": "FRONTLINE MILLENNIUM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21321",
            "district": "32",
            "Block": "363",
            "label": "A.V.P. TRUST PUBLIC SENIOR SECONDARY SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21362",
            "district": "32",
            "Block": "363",
            "label": "GHS BOMMANAICKENPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21363",
            "district": "32",
            "Block": "363",
            "label": "MHS KUMARANANDAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21566",
            "district": "32",
            "Block": "363",
            "label": "MHSS PADMAVATHIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21796",
            "district": "32",
            "Block": "363",
            "label": "AVP TRUST MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21797",
            "district": "32",
            "Block": "363",
            "label": "GLOBAL DISCOVERY ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21798",
            "district": "32",
            "Block": "363",
            "label": "BRIGHT PUBLIC MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22162",
            "district": "32",
            "Block": "363",
            "label": "MGV GLOBAL ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22163",
            "district": "32",
            "Block": "363",
            "label": "SRI JAI SARADHA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22164",
            "district": "32",
            "Block": "363",
            "label": "SAI KIRUPA SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "22165",
            "district": "32",
            "Block": "363",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22166",
            "district": "32",
            "Block": "363",
            "label": "DKT GLOBAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22167",
            "district": "32",
            "Block": "363",
            "label": "KIDS PARK ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22168",
            "district": "32",
            "Block": "363",
            "label": "BHARATHI VIDHYA BHAVAN SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22169",
            "district": "32",
            "Block": "363",
            "label": "BHARATHI VIDHYASRAM",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "22170",
            "district": "32",
            "Block": "363",
            "label": "SUBBIAH CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22171",
            "district": "32",
            "Block": "363",
            "label": "SHARANALAYA INTERNATIONAL MONTESSORI SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22172",
            "district": "32",
            "Block": "363",
            "label": "KIDS CLUB INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22493",
            "district": "32",
            "Block": "363",
            "label": "VRIKSHAA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22494",
            "district": "32",
            "Block": "363",
            "label": "MHS K.V.R NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22495",
            "district": "32",
            "Block": "363",
            "label": "THE HOME SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22821",
            "district": "32",
            "Block": "363",
            "label": "PUMS HOUSING UNIT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22838",
            "district": "32",
            "Block": "363",
            "label": "DREAM PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22839",
            "district": "32",
            "Block": "363",
            "label": "VIKAS VIDYALAYA JUNIORS MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22840",
            "district": "32",
            "Block": "363",
            "label": "MALAR ACE PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22841",
            "district": "32",
            "Block": "363",
            "label": "S.K.V.MATRICULATION SCHOOL,MANNARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22927",
            "district": "32",
            "Block": "363",
            "label": "GHS MASCO NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22928",
            "district": "32",
            "Block": "363",
            "label": "G GIRLS HSS PERUMANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23077",
            "district": "32",
            "Block": "363",
            "label": "CENTURY FOUNDATION PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23151",
            "district": "32",
            "Block": "363",
            "label": "ADVAITA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23242",
            "district": "32",
            "Block": "363",
            "label": "GOVERNMENT HIGH SCHOOL , PERICHIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23243",
            "district": "32",
            "Block": "363",
            "label": "GOVERNMENT HIGH SCHOOL , MANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23262",
            "district": "32",
            "Block": "363",
            "label": "NACHIYAR PADASALA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23419",
            "district": "32",
            "Block": "363",
            "label": "SRI KUMARAN MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23301",
            "district": "32",
            "Block": "363",
            "label": "EDMUNDS MEMORIAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23419",
            "district": "32",
            "Block": "363",
            "label": "SRI KUMARAN MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23419",
            "district": "32",
            "Block": "363",
            "label": "SRI KUMARAN MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23419",
            "district": "32",
            "Block": "363",
            "label": "SRI KUMARAN MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23597",
            "district": "32",
            "Block": "363",
            "label": "Majestic Convent Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23598",
            "district": "32",
            "Block": "363",
            "label": "T.N.K. VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23599",
            "district": "32",
            "Block": "363",
            "label": "Frontline NewGen International School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        { 
            "key":"24116", "district":"32", "Block":"363", 
            "label": "Kumaran matriculation school", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "23419",
            "district": "32",
            "Block": "363",
            "label": "SRI KUMARAN MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23597",
            "district": "32",
            "Block": "363",
            "label": "Majestic Convent Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23598",
            "district": "32",
            "Block": "363",
            "label": "T.N.K. VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23599",
            "district": "32",
            "Block": "363",
            "label": "Frontline NewGen International School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23697",
            "district": "32",
            "Block": "363",
            "label": "JAISAKTHI VIRAJA GURUKULAM (CBSC SCHOOL)",
            "Sch_type": "Unaided",
            "Category": "Middle School"
        },
        {
            "key": "24037",
            "district": "32",
            "Block": "363",
            "label": "PUMS SOKKANUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24038",
            "district": "32",
            "Block": "363",
            "label": "GHSS MANGALAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24039",
            "district": "32",
            "Block": "363",
            "label": "PUMS, NALLUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24040",
            "district": "32",
            "Block": "363",
            "label": "AMRITA VIDYALAYAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24041",
            "district": "32",
            "Block": "363",
            "label": "PUMS HOUSING UNIT",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24042",
            "district": "32",
            "Block": "363",
            "label": "LITTLE STAR MATRICULATION SCHOOL, K.V.R NAGAR",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "364": [
        {
            "key": "385",
            "district": "33",
            "Block": "364",
            "label": "PUMS - KRISHNA SAMUTHRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "431",
            "district": "33",
            "Block": "364",
            "label": "PUMS - K K NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "511",
            "district": "33",
            "Block": "364",
            "label": "PUMS-SINGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "530",
            "district": "33",
            "Block": "364",
            "label": "DR VGS NAIDU MATRIC HR SEC SCHOOL -TIRUTTANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "611",
            "district": "33",
            "Block": "364",
            "label": "SUDANDIRA MATHSS-TRT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "662",
            "district": "33",
            "Block": "364",
            "label": "PUMS - VEERAKA NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "704",
            "district": "33",
            "Block": "364",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION SCHOOL -K.G.KANDIGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "780",
            "district": "33",
            "Block": "364",
            "label": "PUMS - PERIYAKADAMBUR H/C",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "911",
            "district": "33",
            "Block": "364",
            "label": "PUMS - TIRUTTANI MAIN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1019",
            "district": "33",
            "Block": "364",
            "label": "ST MARY'S MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1069",
            "district": "33",
            "Block": "364",
            "label": "SRI RAJA RAJESWARI MATRICULATION SCHOOL MELKASAWARAJAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1123",
            "district": "33",
            "Block": "364",
            "label": "PUMS - KASINATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1128",
            "district": "33",
            "Block": "364",
            "label": "PUMS - MGR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1138",
            "district": "33",
            "Block": "364",
            "label": "PUMS - MADDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1235",
            "district": "33",
            "Block": "364",
            "label": "PUMS - B N KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1335",
            "district": "33",
            "Block": "364",
            "label": "PUMS - VEERA KAVERI RAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1502",
            "district": "33",
            "Block": "364",
            "label": "TKVINAYAGAM MAT - TRT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1538",
            "district": "33",
            "Block": "364",
            "label": "GMS - S AGRAHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1591",
            "district": "33",
            "Block": "364",
            "label": "PUMS - SIRUKUMI H/C",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1631",
            "district": "33",
            "Block": "364",
            "label": "PUMS - PATTABIRAMAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1643",
            "district": "33",
            "Block": "364",
            "label": "PUMS - SRINIVASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1671",
            "district": "33",
            "Block": "364",
            "label": "ANM MS-SARASWATHI NAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1914",
            "district": "33",
            "Block": "364",
            "label": "PUMS-CHERUKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2074",
            "district": "33",
            "Block": "364",
            "label": "PUMS - SOORIYA NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2375",
            "district": "33",
            "Block": "364",
            "label": "PUMS - PERIAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2917",
            "district": "33",
            "Block": "364",
            "label": "PUMS - KARTHIKEYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3000",
            "district": "33",
            "Block": "364",
            "label": "PUMS - R K STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3027",
            "district": "33",
            "Block": "364",
            "label": "PUMS - L N KANDIGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3136",
            "district": "33",
            "Block": "364",
            "label": "PUMS- D V PURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3207",
            "district": "33",
            "Block": "364",
            "label": "GMS-RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5567",
            "district": "33",
            "Block": "364",
            "label": "PUMS - KORAMANAGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5868",
            "district": "33",
            "Block": "364",
            "label": "CSI MS-AGOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7028",
            "district": "33",
            "Block": "364",
            "label": "GHSS-BUCHIREDDIPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7029",
            "district": "33",
            "Block": "364",
            "label": "GHSS - K.G.KANDIGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7030",
            "district": "33",
            "Block": "364",
            "label": "ST.CHARLES HSS K.G.KANDIGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7031",
            "district": "33",
            "Block": "364",
            "label": "GHSS -MADDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7032",
            "district": "33",
            "Block": "364",
            "label": "GHS - MURUKKAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7033",
            "district": "33",
            "Block": "364",
            "label": "GHS - ISLAM NAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7034",
            "district": "33",
            "Block": "364",
            "label": "GHS - AMIRTHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7035",
            "district": "33",
            "Block": "364",
            "label": "CSI HIGH SCHOOL - DURAIVARIPET",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7036",
            "district": "33",
            "Block": "364",
            "label": "GOVT(B) HSS-TIRUTTANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7037",
            "district": "33",
            "Block": "364",
            "label": "RADHAKRISHNAN MATRICULATION- TIRUTTANI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7038",
            "district": "33",
            "Block": "364",
            "label": "GOVT(G) HSS-TIRUTTANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20911",
            "district": "33",
            "Block": "364",
            "label": "SHAKTHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22004",
            "district": "33",
            "Block": "364",
            "label": "SRI VIDYA NIKETAN MATRIC HIGHER SECONDARY  SCHOOL E.N KANDIGAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22005",
            "district": "33",
            "Block": "364",
            "label": "GRT MAHALAKSMI VIDHYALAYA CBSE SCHOOL,PATTABIRAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22331",
            "district": "33",
            "Block": "364",
            "label": "GHS, V C R KANDIGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22332",
            "district": "33",
            "Block": "364",
            "label": "GHS, VELANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23663",
            "district": "33",
            "Block": "364",
            "label": "New Eden School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23239",
            "district": "33",
            "Block": "364",
            "label": "TRS GLOBAL PUBLIC SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "365": [
        {
            "key": "1393",
            "district": "34",
            "Block": "365",
            "label": "MARY MEMORIAL MATRIC TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2227",
            "district": "34",
            "Block": "365",
            "label": "GANDHI NAGAR MATRIC HSS -SO.KILNACHIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2489",
            "district": "34",
            "Block": "365",
            "label": "PUMS SU. ANDAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2546",
            "district": "34",
            "Block": "365",
            "label": "PUMS MELATHIKKAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2582",
            "district": "34",
            "Block": "365",
            "label": "PUMS CHINNAKALLAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2589",
            "district": "34",
            "Block": "365",
            "label": "ADWMS SU.VALAVETTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2661",
            "district": "34",
            "Block": "365",
            "label": "PUMS KALLANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2732",
            "district": "34",
            "Block": "365",
            "label": "DR. VGN MATRIC HSS SAMUDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2777",
            "district": "34",
            "Block": "365",
            "label": "RCM MS ALLIKONDAPATU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "2811",
            "district": "34",
            "Block": "365",
            "label": "PUMS NALLAN PILLAI PETRAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3042",
            "district": "34",
            "Block": "365",
            "label": "PUMS VELAYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3085",
            "district": "34",
            "Block": "365",
            "label": "PUMS PANDITHAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3124",
            "district": "34",
            "Block": "365",
            "label": "PUMS ISUKAZHIKATTERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3173",
            "district": "34",
            "Block": "365",
            "label": "ADWMS ADAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3354",
            "district": "34",
            "Block": "365",
            "label": "PUMS SU. NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3405",
            "district": "34",
            "Block": "365",
            "label": "PUMS KALLERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3529",
            "district": "34",
            "Block": "365",
            "label": "PUMS KADAGAMAN",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3552",
            "district": "34",
            "Block": "365",
            "label": "PUMS NAVAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3641",
            "district": "34",
            "Block": "365",
            "label": "PUMS ARADAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3676",
            "district": "34",
            "Block": "365",
            "label": "PUMS T. VALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3686",
            "district": "34",
            "Block": "365",
            "label": "PUMS THANDARAI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3729",
            "district": "34",
            "Block": "365",
            "label": "PUMS SATHIYA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3787",
            "district": "34",
            "Block": "365",
            "label": "PUMS ERADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3819",
            "district": "34",
            "Block": "365",
            "label": "SRI RAMAKRISHNA MAT HS TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4063",
            "district": "34",
            "Block": "365",
            "label": "VICTORIYA HINDU MS TIRUVANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4360",
            "district": "34",
            "Block": "365",
            "label": "ADWMS PALAYANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4431",
            "district": "34",
            "Block": "365",
            "label": "PUMS SAVALPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4646",
            "district": "34",
            "Block": "365",
            "label": "SRI RAMANA MATRIC HS VENGIKAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4898",
            "district": "34",
            "Block": "365",
            "label": "ST. ANTONY MATRIC PAVITHIRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5114",
            "district": "34",
            "Block": "365",
            "label": "PUMS PAVUPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5121",
            "district": "34",
            "Block": "365",
            "label": "MMS THENIMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5195",
            "district": "34",
            "Block": "365",
            "label": "MMS KANA NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5214",
            "district": "34",
            "Block": "365",
            "label": "PUMS ANANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5249",
            "district": "34",
            "Block": "365",
            "label": "PUMS ANDIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5369",
            "district": "34",
            "Block": "365",
            "label": "PUMS VERAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5530",
            "district": "34",
            "Block": "365",
            "label": "MMS TOWN HALL TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5687",
            "district": "34",
            "Block": "365",
            "label": "PUMS KOTANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5777",
            "district": "34",
            "Block": "365",
            "label": "SRI SARASWATHI VIKAS MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5844",
            "district": "34",
            "Block": "365",
            "label": "PUMS MELCHETTIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5873",
            "district": "34",
            "Block": "365",
            "label": "PUMS MELKACHIRAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5898",
            "district": "34",
            "Block": "365",
            "label": "PUMS ADAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5961",
            "district": "34",
            "Block": "365",
            "label": "PUMS AZHAGANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6032",
            "district": "34",
            "Block": "365",
            "label": "KRISHNA VIDYA MANDIR MAT  HR SEC SCHOOL TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6231",
            "district": "34",
            "Block": "365",
            "label": "PUMS VENGIKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6283",
            "district": "34",
            "Block": "365",
            "label": "CARMEL MATRICULATION SCHOOL, TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6450",
            "district": "34",
            "Block": "365",
            "label": "PUMS KANDIYANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6671",
            "district": "34",
            "Block": "365",
            "label": "RAMANA MAHARISHI LOYOLA ACADEMY MATRICULATION HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6685",
            "district": "34",
            "Block": "365",
            "label": "PUMS NACHANANDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9826",
            "district": "34",
            "Block": "365",
            "label": "TVS ACADEMY MONTESSORI AND ENGLISH MEDIUM SCHOOL, ADAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9827",
            "district": "34",
            "Block": "365",
            "label": "SS AIDED HS ADIANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "9828",
            "district": "34",
            "Block": "365",
            "label": "GHS ADIANNAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9829",
            "district": "34",
            "Block": "365",
            "label": "GHSS ANDAMPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9830",
            "district": "34",
            "Block": "365",
            "label": "GHS ANAIPIRANDAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9831",
            "district": "34",
            "Block": "365",
            "label": "ADWHS AYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9832",
            "district": "34",
            "Block": "365",
            "label": "PUMS AYYAMPALAYAM PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9833",
            "district": "34",
            "Block": "365",
            "label": "TKKMHS CHINNAKANGIANUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "9834",
            "district": "34",
            "Block": "365",
            "label": "GHS DEVANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9835",
            "district": "34",
            "Block": "365",
            "label": "GHSS KATTAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9836",
            "district": "34",
            "Block": "365",
            "label": "SRI RAMANA MAHARSHI MATRIC  HR SEC SCHOOL KAVERIYAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9837",
            "district": "34",
            "Block": "365",
            "label": "SMM MODEL HS KILANAIKARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9838",
            "district": "34",
            "Block": "365",
            "label": "GHSS KOLAKKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9839",
            "district": "34",
            "Block": "365",
            "label": "G.P. MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9840",
            "district": "34",
            "Block": "365",
            "label": "SIRUMALAR HS MATHURAMPATU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9841",
            "district": "34",
            "Block": "365",
            "label": "PUMS MALAPPAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9842",
            "district": "34",
            "Block": "365",
            "label": "HEBRON MATRIC HIGHER SECONDARY SCHOOL -  MANJAMPOONDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9843",
            "district": "34",
            "Block": "365",
            "label": "GHS MEYYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9844",
            "district": "34",
            "Block": "365",
            "label": "SARASWATHI KNOWLEDGE YUVAKENDRA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9845",
            "district": "34",
            "Block": "365",
            "label": "GHSS NALLAVANPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9846",
            "district": "34",
            "Block": "365",
            "label": "GHS NARAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9847",
            "district": "34",
            "Block": "365",
            "label": "PUMS NARIYAPPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9848",
            "district": "34",
            "Block": "365",
            "label": "GHSS PALAYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9849",
            "district": "34",
            "Block": "365",
            "label": "GHS PARAYAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9850",
            "district": "34",
            "Block": "365",
            "label": "PSA HSS (BOYS) PAVITHIRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9851",
            "district": "34",
            "Block": "365",
            "label": "ST ANTONYS HSS (GIRLS) PAVITHIRAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9852",
            "district": "34",
            "Block": "365",
            "label": "GHS PERUMANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9853",
            "district": "34",
            "Block": "365",
            "label": "PUMS SO. KILNACHIPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9854",
            "district": "34",
            "Block": "365",
            "label": "KUMARAN HSS SO.KILNATCHIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9855",
            "district": "34",
            "Block": "365",
            "label": "JEEVA VELU GIRLS MATRICLATION HR SEC SCHOOL THENMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9856",
            "district": "34",
            "Block": "365",
            "label": "G T R HIGH SCHOOL  SU.KILNATCHIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9857",
            "district": "34",
            "Block": "365",
            "label": "GHSS SU.VALAVETTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9858",
            "district": "34",
            "Block": "365",
            "label": "RANGAMMAL MEMORIAL HR.SEC.SCHOOL FOR THE HEARING IMPAIRED",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9859",
            "district": "34",
            "Block": "365",
            "label": "PUMS SAMUKKUDAIYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9860",
            "district": "34",
            "Block": "365",
            "label": "GHSS THACHAMPATU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9861",
            "district": "34",
            "Block": "365",
            "label": "GHS THANDARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9862",
            "district": "34",
            "Block": "365",
            "label": "JEEVA VELU INTERNATIONAL SCHOOL - THENMATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9863",
            "district": "34",
            "Block": "365",
            "label": "GHS VEDIYAPPANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9864",
            "district": "34",
            "Block": "365",
            "label": "ST ANTONY HS VIRUDUVILANGINAN",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9865",
            "district": "34",
            "Block": "365",
            "label": "ALC HSS SARON",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9866",
            "district": "34",
            "Block": "365",
            "label": "DM HSS TIRUVANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9867",
            "district": "34",
            "Block": "365",
            "label": "TNAP GHSS TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9868",
            "district": "34",
            "Block": "365",
            "label": "MOUNT SAINT JOSEPH MATRIC HSS TIRUVANNAMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9869",
            "district": "34",
            "Block": "365",
            "label": "MUNICIPAL HS KILNATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23658",
            "district": "34",
            "Block": "365",
            "label": "Danish Mission Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "9870",
            "district": "34",
            "Block": "365",
            "label": "MUNICIPAL HS (GIRLS) TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9871",
            "district": "34",
            "Block": "365",
            "label": "MUNICIPAL  HSS TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9872",
            "district": "34",
            "Block": "365",
            "label": "MUNICIPAL  HSS (GIRLS) TIRUVANNAMALAI -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9873",
            "district": "34",
            "Block": "365",
            "label": "SI GHSS TIRUVANNAMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9874",
            "district": "34",
            "Block": "365",
            "label": "SRI VDS JAIN HSS TIRUVANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9875",
            "district": "34",
            "Block": "365",
            "label": "ST XAVIERS HS KILNATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9876",
            "district": "34",
            "Block": "365",
            "label": "VISION MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9877",
            "district": "34",
            "Block": "365",
            "label": "KENDRIYA VIDYALAYA GANDHI NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9878",
            "district": "34",
            "Block": "365",
            "label": "M A KADHAR SAIT TIRUVANNAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20948",
            "district": "34",
            "Block": "365",
            "label": "THE PATH GLOBAL PUBLIC SCHOOL  MALAPPAMPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20949",
            "district": "34",
            "Block": "365",
            "label": "AMAZON INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21180",
            "district": "34",
            "Block": "365",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21305",
            "district": "34",
            "Block": "365",
            "label": "VIGNESH INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21545",
            "district": "34",
            "Block": "365",
            "label": "BHAGAVAN HR.SEC.SCHOOL - KILNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22416",
            "district": "34",
            "Block": "365",
            "label": "GHS-THALAYAMPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22417",
            "district": "34",
            "Block": "365",
            "label": "SKP VANITHA MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22418",
            "district": "34",
            "Block": "365",
            "label": "SKP VANITHA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22419",
            "district": "34",
            "Block": "365",
            "label": "RS INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22989",
            "district": "34",
            "Block": "365",
            "label": "VIGNESH MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23124",
            "district": "34",
            "Block": "365",
            "label": "AKSARA VIDHYAMANDIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23138",
            "district": "34",
            "Block": "365",
            "label": "MY FIRST SCHOOL INTERNATIONAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23212",
            "district": "34",
            "Block": "365",
            "label": "SHAZAMMAL VIDHYA MANDHIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23671",
            "district": "34",
            "Block": "365",
            "label": "Vivekananda Nursery School",
            "Sch_type": "Unaided",
            "Category": "Primary school"
        },
        {
            "key": "23323",
            "district": "34",
            "Block": "365",
            "label": "AQSA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        // {
        //     "key": "23620",
        //     "district": "34",
        //     "Block": "365",
        //     "label": "Government Model School, Tiruvannamalai",
        //     "Sch_type": "Government",
        //     "Category": "Higher Secondary School"
        // },
        // {
        //     "key": "23621",
        //     "district": "34",
        //     "Block": "365",
        //     "label": "SRI VIJAY VIDYALAYA MAT HR SEC SCHOOL",
        //     "Sch_type": "Un-aided",
        //     "Category": "Higher Secondary School"
        // },
        // {
        //     "key": "23622",
        //     "district": "34",
        //     "Block": "365",
        //     "label": "SRI VIJAY VIDYASHRAM",
        //     "Sch_type": "Un-aided",
        //     "Category": "Middle School"
        // },
        // {
        //     "key": "23623",
        //     "district": "34",
        //     "Block": "365",
        //     "label": "TIRUVANNAMALAI DISTRICT GOVERNMENT MODEL SCHOOL",
        //     "Sch_type": "Government",
        //     "Category": "Higher Secondary School"
        // },
        {
            "key": "23620",
            "district": "34",
            "Block": "365",
            "label": "Government Model School, Tiruvannamalai",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23621",
            "district": "34",
            "Block": "365",
            "label": "SRI VIJAY VIDYALAYA MAT HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23622",
            "district": "34",
            "Block": "365",
            "label": "SRI VIJAY VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23623",
            "district": "34",
            "Block": "365",
            "label": "TIRUVANNAMALAI DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24043",
            "district": "34",
            "Block": "365",
            "label": "GHS MEYYUR",
            "Sch_type": "GovernmentHigh School"
        },
        {
            "key": "24044",
            "district": "34",
            "Block": "365",
            "label": "VELAMMAL VIDHYASHRAM",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "366": [
        {
            "key": "384",
            "district": "29",
            "Block": "366",
            "label": "HOLY CROSS GIRLS HSS,TEPPAKULAM , TRICHY-2",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2321",
            "district": "29",
            "Block": "366",
            "label": "VISCOUNTESS GOSHEN GOVT. GIRLS MUSLIM HSS, TRICHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4621",
            "district": "29",
            "Block": "366",
            "label": "JESSIE MARTIC HSS, PUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4683",
            "district": "29",
            "Block": "366",
            "label": "A.K.K.V. AARUNADU.  MAT. SCHOOL, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4798",
            "district": "29",
            "Block": "366",
            "label": "KINGSLEY MAT.SCHOOL, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5006",
            "district": "29",
            "Block": "366",
            "label": "Hilda Thomas YWCA Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5017",
            "district": "29",
            "Block": "366",
            "label": "R.DAYANIDHI MEMORIAL VIDYASALA MATRICULATION SCHOOL,KULUMANI ROAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5103",
            "district": "29",
            "Block": "366",
            "label": "SARASWATHI BALA MANDIR MATRICULATION SCHOOL SRINIVASA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5358",
            "district": "29",
            "Block": "366",
            "label": "CARMELS MAT HSS,TRICHY-3",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5432",
            "district": "29",
            "Block": "366",
            "label": "SRI JAYENDRA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5706",
            "district": "29",
            "Block": "366",
            "label": "SRI SANKARA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6103",
            "district": "29",
            "Block": "366",
            "label": "AUROBINDO INTERNATIONAL SCHOOL, WORAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6602",
            "district": "29",
            "Block": "366",
            "label": "DHANALAKSHMI SRINIVASAN MAT. WORAIYUR,TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14392",
            "district": "29",
            "Block": "366",
            "label": "V.O.C.MIDDLE SCHOOL,EAST CHIND",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14393",
            "district": "29",
            "Block": "366",
            "label": "U.D.V HSS, TOWN RAILWAY STATION, TRICHY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14394",
            "district": "29",
            "Block": "366",
            "label": "E.R.HR.SEC.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14395",
            "district": "29",
            "Block": "366",
            "label": "THIRUVALLUVAR MID. SCH",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14396",
            "district": "29",
            "Block": "366",
            "label": "ICSE KAMAKODI VIDHIYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14397",
            "district": "29",
            "Block": "366",
            "label": "RC Middle School Butterworth Road",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14398",
            "district": "29",
            "Block": "366",
            "label": "MATHURAM MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14399",
            "district": "29",
            "Block": "366",
            "label": "VEERAN KALVI KOODAM M.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14400",
            "district": "29",
            "Block": "366",
            "label": "SAVITRI VIDYASALA HINDU GIRLS' HIGHER SECONDARY SCHOOL, TIRUCHIRAPALLI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14401",
            "district": "29",
            "Block": "366",
            "label": "ST.JOSEPH'S COLLEGE HR. SECONDARY SCHOOL, MAINGUARDGATE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14402",
            "district": "29",
            "Block": "366",
            "label": "BAKTHA THAYUMANAVAR M.SCH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14403",
            "district": "29",
            "Block": "366",
            "label": "AMS.THOTTA RAJAGOPAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14404",
            "district": "29",
            "Block": "366",
            "label": "HAZARATH NATHERVALI M.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14405",
            "district": "29",
            "Block": "366",
            "label": "ST.MARYS TOPE M.SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14406",
            "district": "29",
            "Block": "366",
            "label": "BISHOP HEBER BOYS HSS, TEPPAKULAM,TRICHY-2",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14407",
            "district": "29",
            "Block": "366",
            "label": "NATIONAL COLLGE HSS, COLLEGE ROAD,TRICHY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14408",
            "district": "29",
            "Block": "366",
            "label": "GOVT GIRLS HSS, TOWN HALL, TRICHY-2",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14409",
            "district": "29",
            "Block": "366",
            "label": "CORPORATION HR SEC SCHOOL, E.B ROAD, TRICHY-8",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14410",
            "district": "29",
            "Block": "366",
            "label": "AMS-ALANGAVILAS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14411",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MIDDLE SCHOOL THARANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14412",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MIDDLE SCHOOL KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14413",
            "district": "29",
            "Block": "366",
            "label": "T.E.L.C.M.SCHOOL KALLA ST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14414",
            "district": "29",
            "Block": "366",
            "label": "SAROJAKASTHURIRENGAN M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14415",
            "district": "29",
            "Block": "366",
            "label": "AMS-MADARASA-E- MOHAMEADIA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14416",
            "district": "29",
            "Block": "366",
            "label": "GOVERNMENT SYED MURTHUZA MODEL HIGHER SECONDARY SCHOOL, TRICHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14417",
            "district": "29",
            "Block": "366",
            "label": "MUN.MID.SCH.VARAGANERI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14418",
            "district": "29",
            "Block": "366",
            "label": "HOLY REDEEMER'S GIRLS HSS, PALAKARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14419",
            "district": "29",
            "Block": "366",
            "label": "R.C  ST.  STANISLAUS  MIDDLE SCHOOL,PALAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14420",
            "district": "29",
            "Block": "366",
            "label": "H.R.M.S",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14421",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MIDDLE SCHOOL SENGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14422",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MIDDLE SCHOOL BEEMANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14423",
            "district": "29",
            "Block": "366",
            "label": "KANAGA MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14424",
            "district": "29",
            "Block": "366",
            "label": "MAHATMA GANDHI CENTENARY VIDYALAYA, TENNUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14425",
            "district": "29",
            "Block": "366",
            "label": "RAJAJI VIDYALAYA,TENNUR,TRICHY-17",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14426",
            "district": "29",
            "Block": "366",
            "label": "SUBBIAH MEM.MID.SCH,TENNUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14427",
            "district": "29",
            "Block": "366",
            "label": "AMS-ALL SAINTS TENNUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14428",
            "district": "29",
            "Block": "366",
            "label": "TENNUR MID.SCH, TENNUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14429",
            "district": "29",
            "Block": "366",
            "label": "ALL SAINTS HIGH SCHOOL, PUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14430",
            "district": "29",
            "Block": "366",
            "label": "C.E. HSS, VANNARAPET, PUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14431",
            "district": "29",
            "Block": "366",
            "label": "GOVT. GIRLS SCH FOR THE BLIND , NEAR G.H ,TRICHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14432",
            "district": "29",
            "Block": "366",
            "label": "METHODIST GIRLS HR.SEC.SCHOOL, WORAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14433",
            "district": "29",
            "Block": "366",
            "label": "BISHOP HEBER BOYS HSS, PUTHUR,TRICHY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14434",
            "district": "29",
            "Block": "366",
            "label": "SRI RAMAKRISHNA MID.SCH,PUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14435",
            "district": "29",
            "Block": "366",
            "label": "MUNICIPAL MID.SCH,WORIYUREAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14436",
            "district": "29",
            "Block": "366",
            "label": "K A P VISVANATHAM HR SEC SCHOOL THILLAI NAGAR TIRUCHIRAPPALLI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14437",
            "district": "29",
            "Block": "366",
            "label": "DR.G.SAKUNTHALA  MATRICULATION  HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14438",
            "district": "29",
            "Block": "366",
            "label": "CAUVERY MATRICULATION HR. SEC. SCHOOL, BHARATHI NAGAR, TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14439",
            "district": "29",
            "Block": "366",
            "label": "R.C.MIDDLE,SCHOOL,WORIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14440",
            "district": "29",
            "Block": "366",
            "label": "MUN.MID.SCHOOL,PANDAMANGALAM NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14441",
            "district": "29",
            "Block": "366",
            "label": "MUN.MID.SCHOOL,WORAIYUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14442",
            "district": "29",
            "Block": "366",
            "label": "SHRI KASTHURI RANGAN VIDHYALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14443",
            "district": "29",
            "Block": "366",
            "label": "SESHA IYENGAR MEMORIAL HR. SEC. SCHOOL, WORAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14444",
            "district": "29",
            "Block": "366",
            "label": "R.C.MID.SCHOOL,KALNAYAKKAN ST.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21247",
            "district": "29",
            "Block": "366",
            "label": "CARMELS ENGLISH SCHOOL,RAMALINGANAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21326",
            "district": "29",
            "Block": "366",
            "label": "SANTHANAM VIDHYALAYA (CBSE) SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21527",
            "district": "29",
            "Block": "366",
            "label": "FATIMA AIDED HIGH SCHOOL PUTHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21920",
            "district": "29",
            "Block": "366",
            "label": "CAUVERY GLOBAL SCHOOL (CBSE),TRICHY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21921",
            "district": "29",
            "Block": "366",
            "label": "SRI VIGNESH PUBLIC SCHOOL (CBSE), WORAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22799",
            "district": "29",
            "Block": "366",
            "label": "NR PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "367": [
        {
            "key": "387",
            "district": "29",
            "Block": "367",
            "label": "St. Philomena's Girls Hr. Sec. School, Melapudur,",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1000",
            "district": "29",
            "Block": "367",
            "label": "Mannar Memorial Mat. School, Col. Off. Road.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2265",
            "district": "29",
            "Block": "367",
            "label": "SERVITE MATRICULATION HIGHER SECONDARY SCHOOL,KEELAPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2307",
            "district": "29",
            "Block": "367",
            "label": "ATMRCM Vasavi Vidhyalaya Matriculation School, Cantonment.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2789",
            "district": "29",
            "Block": "367",
            "label": "ST.JOSEPHS ANGLO INDIAN GIRLS HR. SEC.SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3485",
            "district": "29",
            "Block": "367",
            "label": "Al-Jamieathus Sadhik Mat. School, Khajamalai.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3925",
            "district": "29",
            "Block": "367",
            "label": "St. Arnold's Mat. School, Ponmalaipatti.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5277",
            "district": "29",
            "Block": "367",
            "label": "St. James Mat. Hr. Sec. School, Cantonment",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5476",
            "district": "29",
            "Block": "367",
            "label": "Seventh-Day Adventist Mat.Hr.Sec.School,Beemnagar",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6402",
            "district": "29",
            "Block": "367",
            "label": "Shakespeare Mat. School, Palakarai.",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14445",
            "district": "29",
            "Block": "367",
            "label": "ADW Mid. School, Varaganeri",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14446",
            "district": "29",
            "Block": "367",
            "label": "T.S.M. High School, Varaganeri",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14447",
            "district": "29",
            "Block": "367",
            "label": "Ponniah Hr. Sec. School, Hirudayapuram",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14448",
            "district": "29",
            "Block": "367",
            "label": "T.E.L.C. Mid. School, Kemps Town.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14449",
            "district": "29",
            "Block": "367",
            "label": "St. Antony's Mid. School, Duraisamipuram.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14450",
            "district": "29",
            "Block": "367",
            "label": "Sri Yadhu Kula Sangam Mid. School, Palakarai.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14451",
            "district": "29",
            "Block": "367",
            "label": "Selva Damodaran Hr. Sec. School, Palakarai",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14452",
            "district": "29",
            "Block": "367",
            "label": "Corporation High School, Senthaneerpuram.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14453",
            "district": "29",
            "Block": "367",
            "label": "Seven Dolloar's Mid. School, Therasalpuram.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14454",
            "district": "29",
            "Block": "367",
            "label": "St. John Britto Mid. School, T.V.S.Tolgate",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14455",
            "district": "29",
            "Block": "367",
            "label": "John Britto Mat. School, Subramaniyapuram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14456",
            "district": "29",
            "Block": "367",
            "label": "Vestley Matric. School, T.V.S. Tollgate,",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14457",
            "district": "29",
            "Block": "367",
            "label": "Corp. Mid. School, Subramaniapuram.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14458",
            "district": "29",
            "Block": "367",
            "label": "R.C. Authimulam Mid. School, Subramaniapuram.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14459",
            "district": "29",
            "Block": "367",
            "label": "Seventh Day Adventist MATRICULATION SCHOOL, SUBRAMANIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14460",
            "district": "29",
            "Block": "367",
            "label": "Corporation High School, Kamaraj Nagar.",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14461",
            "district": "29",
            "Block": "367",
            "label": "AL-HUDHA HR. SEC. SCHOOL,AIRPORT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14462",
            "district": "29",
            "Block": "367",
            "label": "St. Agnes Mid. School, Ponmalaipatti.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14463",
            "district": "29",
            "Block": "367",
            "label": "St.Joseph's Girls' Hr. Sec. School, Ponmalaipatti",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14464",
            "district": "29",
            "Block": "367",
            "label": "DOLOUR'S HIGH SCHOOL FOR THE DEAF,PONMALAIPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14465",
            "district": "29",
            "Block": "367",
            "label": "Abbot Marcel R.C. Hr. Sec. School, Sempattu.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14466",
            "district": "29",
            "Block": "367",
            "label": "Annai  Hr. Sec. School, Airport.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14467",
            "district": "29",
            "Block": "367",
            "label": "Adams Mat. Hr. Sec. School, Airport.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14468",
            "district": "29",
            "Block": "367",
            "label": "Infant Jesus High School, Kajamalai Colony,",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14469",
            "district": "29",
            "Block": "367",
            "label": "Seva Sangam Girls Hr. Sec. School, Cantonment",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14470",
            "district": "29",
            "Block": "367",
            "label": "R.C HR. SEC. SCHOOL, CANTONMENT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14471",
            "district": "29",
            "Block": "367",
            "label": "St. John Vestry A.I. Hr. Sec. School, Cantonment.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14472",
            "district": "29",
            "Block": "367",
            "label": "Campion A.I. Hr. Sec. School, Cantonment.",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14473",
            "district": "29",
            "Block": "367",
            "label": "G. ADW. Hr. Sec. School, Milaguparai.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14474",
            "district": "29",
            "Block": "367",
            "label": "KAMALA NIKETAN MONTESSORI SCHOOL (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14475",
            "district": "29",
            "Block": "367",
            "label": "St. Anne's Mid. School,",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14476",
            "district": "29",
            "Block": "367",
            "label": "St. Juliana's Mid. School,",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14477",
            "district": "29",
            "Block": "367",
            "label": "B.H.M. High School, Melapudur.",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "14478",
            "district": "29",
            "Block": "367",
            "label": "St. Mary's High School, Melapudur,",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14479",
            "district": "29",
            "Block": "367",
            "label": "ST. ANNE'S GIRLS' HR. SEC. SCHOOL, MELAPUDUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14480",
            "district": "29",
            "Block": "367",
            "label": "Saraswathi Vilas Mid. School, Beemanagar",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21356",
            "district": "29",
            "Block": "367",
            "label": "SEVENTH DAY ADVENTIST (ICSE)- BEEMANAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21759",
            "district": "29",
            "Block": "367",
            "label": "LOVE DALE MATRIC SCHOOL,KAVERI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22796",
            "district": "29",
            "Block": "367",
            "label": "St. James Academy(CBSE),TRICHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23584",
            "district": "29",
            "Block": "367",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23585",
            "district": "29",
            "Block": "367",
            "label": "RAJAM KRISHNAMURTHY (CBSE) PUBLIC SCHOOL, KARUMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23584",
            "district": "29",
            "Block": "367",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23585",
            "district": "29",
            "Block": "367",
            "label": "RAJAM KRISHNAMURTHY (CBSE) PUBLIC SCHOOL, KARUMANDAPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24045",
            "district": "29",
            "Block": "367",
            "label": "ST.JOHN'S VESTRY,TRICHY",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "368": [
        {
            "key": "843",
            "district": "3",
            "Block": "368",
            "label": "CHS CHINTADRIPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "1149",
            "district": "3",
            "Block": "368",
            "label": "CAMBRIDGE MAT HS",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1344",
            "district": "3",
            "Block": "368",
            "label": "THE CLARKE SCHOOL FOR THE DEAF",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1432",
            "district": "3",
            "Block": "368",
            "label": "EMMA FOULGER MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1847",
            "district": "3",
            "Block": "368",
            "label": "EVE MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2053",
            "district": "3",
            "Block": "368",
            "label": "CHRIST CHURCH ANGLO-INDIAN HIGHER SECONDARY SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2353",
            "district": "3",
            "Block": "368",
            "label": "CMS BALAJI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3286",
            "district": "3",
            "Block": "368",
            "label": "GILL ADARSH MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3629",
            "district": "3",
            "Block": "368",
            "label": "CMS KRISHNAMPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3940",
            "district": "3",
            "Block": "368",
            "label": "D AHMED ALI PARPIA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4330",
            "district": "3",
            "Block": "368",
            "label": "REX MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4774",
            "district": "3",
            "Block": "368",
            "label": "SREE VENKATESHWARAR MATRIC. HR.SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4801",
            "district": "3",
            "Block": "368",
            "label": "M.O.P VAISHNAV MIDDLE SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6018",
            "district": "3",
            "Block": "368",
            "label": "ST. WILLIAMS AIHS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "6075",
            "district": "3",
            "Block": "368",
            "label": "CMS TRIPLICANE HIGH ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6624",
            "district": "3",
            "Block": "368",
            "label": "Y.M.C.A BOYS TOWN HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7334",
            "district": "3",
            "Block": "368",
            "label": "CMS ELLISPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7335",
            "district": "3",
            "Block": "368",
            "label": "AIWC HIGH SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7336",
            "district": "3",
            "Block": "368",
            "label": "CUMS THAYARSAHIB STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7337",
            "district": "3",
            "Block": "368",
            "label": "GOVT. MUSLIM HIGHER SECONDARY SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7338",
            "district": "3",
            "Block": "368",
            "label": "Govt Madarasa - I - Asam HSS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7339",
            "district": "3",
            "Block": "368",
            "label": "KENDRIYA VIDYALAYA HSS",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7340",
            "district": "3",
            "Block": "368",
            "label": "C KALYANAM GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7341",
            "district": "3",
            "Block": "368",
            "label": "CHINDADRIPET HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7342",
            "district": "3",
            "Block": "368",
            "label": "CHINTADRIPET MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7343",
            "district": "3",
            "Block": "368",
            "label": "CMS NARASINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7344",
            "district": "3",
            "Block": "368",
            "label": "CHS GOYATHOPE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7345",
            "district": "3",
            "Block": "368",
            "label": "RBANC HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7346",
            "district": "3",
            "Block": "368",
            "label": "CMS THYPOON ALI KHAN STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7347",
            "district": "3",
            "Block": "368",
            "label": "CMS BANGARU STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7348",
            "district": "3",
            "Block": "368",
            "label": "H.H.MANDAL MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7349",
            "district": "3",
            "Block": "368",
            "label": "DEVI NAVASAKTHI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7350",
            "district": "3",
            "Block": "368",
            "label": "THE MUSLIM HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7351",
            "district": "3",
            "Block": "368",
            "label": "SRI JNSJV MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7352",
            "district": "3",
            "Block": "368",
            "label": "MURTHUZAVIYA ORIENTAL HSS",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7353",
            "district": "3",
            "Block": "368",
            "label": "CSI KELLETT HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7354",
            "district": "3",
            "Block": "368",
            "label": "CMS BELLS ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7355",
            "district": "3",
            "Block": "368",
            "label": "CSI MIDDLE SCHOOL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7356",
            "district": "3",
            "Block": "368",
            "label": "THE HINDU SENIOR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7357",
            "district": "3",
            "Block": "368",
            "label": "THE HINDU HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7358",
            "district": "3",
            "Block": "368",
            "label": "CMS SOORAPPA STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7359",
            "district": "3",
            "Block": "368",
            "label": "N.K.T NATIONAL GIRLS HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7360",
            "district": "3",
            "Block": "368",
            "label": "TAMILNAD MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "7361",
            "district": "3",
            "Block": "368",
            "label": "N.K.T NATIONAL BOYS HS",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "7362",
            "district": "3",
            "Block": "368",
            "label": "CMS VR PILLAI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7363",
            "district": "3",
            "Block": "368",
            "label": "CHS IRUSAPPA STREET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7364",
            "district": "3",
            "Block": "368",
            "label": "GOVT. MODEL GHSS TRIPLICANE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7365",
            "district": "3",
            "Block": "368",
            "label": "CUMS 10 PETERS ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7366",
            "district": "3",
            "Block": "368",
            "label": "CMS BEGUM SAHIB STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7367",
            "district": "3",
            "Block": "368",
            "label": "CHSS LLOYDS ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7368",
            "district": "3",
            "Block": "368",
            "label": "C.S.I St. Ebba's Matriculation  Hr. Sec. School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7369",
            "district": "3",
            "Block": "368",
            "label": "CHS VP KOIL ST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22693",
            "district": "3",
            "Block": "368",
            "label": "SSH MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "369": [
        {
            "key": "2231",
            "district": "28",
            "Block": "369",
            "label": "PUMS SATHYA NAGAR, UDANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2565",
            "district": "28",
            "Block": "369",
            "label": "SALMA MATRIC HIGHER SECONDARY SCHOOL, UDANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3358",
            "district": "28",
            "Block": "369",
            "label": "TNDTA MIDDLE SCHOOL KALVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3436",
            "district": "28",
            "Block": "369",
            "label": "RC MS, UDANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3926",
            "district": "28",
            "Block": "369",
            "label": "HASSANIYA MIDDLE SCHOOL, KULASEKARANPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4127",
            "district": "28",
            "Block": "369",
            "label": "TNDTA MIDDLE SCHOOL MEYYOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4146",
            "district": "28",
            "Block": "369",
            "label": "GOVERNMENT MS UDANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4175",
            "district": "28",
            "Block": "369",
            "label": "PSM MS KULASEKARANPATTINAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4189",
            "district": "28",
            "Block": "369",
            "label": "HOLY GHOST MS MANAPADU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4426",
            "district": "28",
            "Block": "369",
            "label": "TNDTA MS SEERKATCHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4737",
            "district": "28",
            "Block": "369",
            "label": "RMV MIDDLE SCHOOL, SIRUNADARKUDIERUPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4810",
            "district": "28",
            "Block": "369",
            "label": "PUMS, NAINAPILLAI ST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5079",
            "district": "28",
            "Block": "369",
            "label": "PUMS, IDAICHIVILAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5101",
            "district": "28",
            "Block": "369",
            "label": "PUMS MANADUTHANDUPATHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5390",
            "district": "28",
            "Block": "369",
            "label": "TNDTA MIDDLE SCHOOL, VETHAKOTTAVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6043",
            "district": "28",
            "Block": "369",
            "label": "APARNA MATRIC. HR.SEC.SCHOOL MURUGESAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6052",
            "district": "28",
            "Block": "369",
            "label": "THAYAMMAL MS KULASAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19435",
            "district": "28",
            "Block": "369",
            "label": "PUMS   PUTHUMANAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19436",
            "district": "28",
            "Block": "369",
            "label": "MARY ANN BEST GIRL'S HIGHER SECONDARY SCHOOL, PANDARANCHETTIVILAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19437",
            "district": "28",
            "Block": "369",
            "label": "TDTA HSS, CHRISTIANAGARAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19438",
            "district": "28",
            "Block": "369",
            "label": "ADW MS SUTHANTHIRANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19439",
            "district": "28",
            "Block": "369",
            "label": "KKR TDTA HIGH SCHOOL, PARAMANKURICHI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19440",
            "district": "28",
            "Block": "369",
            "label": "BAM HSS, VELLALANVILLAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19441",
            "district": "28",
            "Block": "369",
            "label": "ST.ANNES, HIGH SCHOOL KEELARAMASAMIAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19442",
            "district": "28",
            "Block": "369",
            "label": "SI.BA.SIVANTHI ADITANAR HIGH SCHOOL VEPPANKADU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19443",
            "district": "28",
            "Block": "369",
            "label": "AMBROSE HIGHER SECONDARY SCHOOL, MEIGNANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19444",
            "district": "28",
            "Block": "369",
            "label": "ELLIOTT TUXFORD GIRLS HIGHER SECONDARY SCHOOL, MEGNANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19445",
            "district": "28",
            "Block": "369",
            "label": "SENTHIL ANDAVAR MS, SEERKATCHI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19446",
            "district": "28",
            "Block": "369",
            "label": "Anitha Kumaran Matric & Higher Secondary School, Thandupathu",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19447",
            "district": "28",
            "Block": "369",
            "label": "SRI RKC HR. SEC. SCHOOL, UDANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19448",
            "district": "28",
            "Block": "369",
            "label": "GHS, VENKATRAMANUJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19449",
            "district": "28",
            "Block": "369",
            "label": "THIRU ARUL HSS, KULASEKARANPATINAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19450",
            "district": "28",
            "Block": "369",
            "label": "VALLIAMMAIYAR GIRLS HR.SEC.SCHOOL KULASEKHARAPATNAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19451",
            "district": "28",
            "Block": "369",
            "label": "ST. MARYS MS MANAPADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19452",
            "district": "28",
            "Block": "369",
            "label": "ST. JOSEPH HSS, MANAPADU",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23095",
            "district": "28",
            "Block": "369",
            "label": "ESTHER SANTHAM HR SEC SCHOOL MEIGNANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23266",
            "district": "28",
            "Block": "369",
            "label": "JAY INTERNATIONAL SCHOOL NANGAIMOZHI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24046",
            "district": "28",
            "Block": "369",
            "label": "AK SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "370": [
        {
            "key": "1873",
            "district": "26",
            "Block": "370",
            "label": "DR. KJG MEMORIAL MATRICULATION SCHOOL BALACOLA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3415",
            "district": "26",
            "Block": "370",
            "label": "VIVEKANANDA MATRICULATION HIGH SCHOOL M.PALADA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3462",
            "district": "26",
            "Block": "370",
            "label": "NILGIRIS MATRIC HR SEC SCHOOL , LOVEDALE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3856",
            "district": "26",
            "Block": "370",
            "label": "PRIYADHARSHINI MATRIC SCHOOL, BIKKATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4005",
            "district": "26",
            "Block": "370",
            "label": "THE OXFORD CONTINENTAL MATRIC HS SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4256",
            "district": "26",
            "Block": "370",
            "label": "ANNAI SARADADEVI MATRICULATION HR. SEC. SCHOOL, ANIKORAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4292",
            "district": "26",
            "Block": "370",
            "label": "SRI RAMAKRISHNA MATRIC SCHOOL, NANJANAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12388",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  ADASHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12389",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ODAIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12390",
            "district": "26",
            "Block": "370",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL,  DODDABETTA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12391",
            "district": "26",
            "Block": "370",
            "label": "HPF HS INDUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12392",
            "district": "26",
            "Block": "370",
            "label": "ST. ANTONY'S HIGH SCHOOL, LOVEDALE, OOTY",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "12393",
            "district": "26",
            "Block": "370",
            "label": "KENDRIYA VIDYALAYA , INDUNAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12394",
            "district": "26",
            "Block": "370",
            "label": "UNIQUE PUBLIC MATRICULATION SCHOOL, FERNHILL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12395",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  NANJANAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12396",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KILCOWHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12397",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, MELCOWHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12398",
            "district": "26",
            "Block": "370",
            "label": "THAKKARBAPA GURUKULAM AIDED MIDDLE SCHOOL NIRGACIMUND",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12399",
            "district": "26",
            "Block": "370",
            "label": "FRANCIS XAVIER AIDED MIDDLE SCHOOL, KURUTHUKULI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12400",
            "district": "26",
            "Block": "370",
            "label": "BRAESIDE  MATRICULATION SCHOOL, NANJANAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12401",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SHOLADA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12402",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  HULLATHY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12403",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, EKONI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12404",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, CHINNA COONOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12405",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, KADANADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12406",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, DHAVANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12407",
            "district": "26",
            "Block": "370",
            "label": "SATHYA SAI  MATRICULATION HR.SEC.SCHOOL, AGALAR, THUNERI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12408",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, THORAIHATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12409",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  ANIKORAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12410",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, THUNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12411",
            "district": "26",
            "Block": "370",
            "label": "GURUKULAM MATRICULATION SCHOOL, AGALAR, THUNERI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12412",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, EBBANAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12413",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KENTHORAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12414",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, T. MANIHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12415",
            "district": "26",
            "Block": "370",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, BACKIANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12416",
            "district": "26",
            "Block": "370",
            "label": "HOLY ANGEL MATRICULATION SCHOOL, THUMMANATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12417",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THUMMANATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12418",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, BILLICOMBAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12419",
            "district": "26",
            "Block": "370",
            "label": "SRI GIRI EESWARA MATRICULATION SCHOOL KAGGUCHI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12420",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL,  KAGGUCHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12421",
            "district": "26",
            "Block": "370",
            "label": "MAHATMA GANDHI MATRIC.HR.SEC.SCHOOL, KAGGUCHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12422",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, KOOKALTHORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12423",
            "district": "26",
            "Block": "370",
            "label": "PUMS, B. MANIHATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12424",
            "district": "26",
            "Block": "370",
            "label": "PUMS, DEVARSHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12425",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL, KILOOR KOKKALADA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12426",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGH SCHOOL,  NUNTHALA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12427",
            "district": "26",
            "Block": "370",
            "label": "GHSS KANNERIMANTHANAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12428",
            "district": "26",
            "Block": "370",
            "label": "GHSS KATHADIMATTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12429",
            "district": "26",
            "Block": "370",
            "label": "SIVASAILAM CHAMRAJ HR.SEC. SCHOOL, CHAMRAJ ESTATE",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12430",
            "district": "26",
            "Block": "370",
            "label": "GHSS KINNAKORAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12431",
            "district": "26",
            "Block": "370",
            "label": "PUMS , MELKUNDAH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12432",
            "district": "26",
            "Block": "370",
            "label": "GHS MULLIMALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12433",
            "district": "26",
            "Block": "370",
            "label": "PUMS ANNANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12434",
            "district": "26",
            "Block": "370",
            "label": "PUMS, MULLIGOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12435",
            "district": "26",
            "Block": "370",
            "label": "ADW MS, THAKKARBABA NAGAR, EMERALD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12436",
            "district": "26",
            "Block": "370",
            "label": "GHS KALLAKORAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12437",
            "district": "26",
            "Block": "370",
            "label": "GHSS EMERALD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12438",
            "district": "26",
            "Block": "370",
            "label": "LALITHA MATRIC HS ITHALAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12439",
            "district": "26",
            "Block": "370",
            "label": "GHSS ITHALAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12440",
            "district": "26",
            "Block": "370",
            "label": "NEW ERA MATRICULATION SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12441",
            "district": "26",
            "Block": "370",
            "label": "MMS, RAMAKRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12442",
            "district": "26",
            "Block": "370",
            "label": "MMS,THALAIYATTUMUND",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12443",
            "district": "26",
            "Block": "370",
            "label": "SACRED HEART MS, BANDHISHOLA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12444",
            "district": "26",
            "Block": "370",
            "label": "CSI,  HOBART",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "12445",
            "district": "26",
            "Block": "370",
            "label": "GHS FOR HEARING IMPAIRED OOTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12446",
            "district": "26",
            "Block": "370",
            "label": "MHS KODAPPAMUND",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12447",
            "district": "26",
            "Block": "370",
            "label": "ST.THERESAS HS FINGER POST OOTY",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12448",
            "district": "26",
            "Block": "370",
            "label": "NAZARETH CONVENT HIGH SCHOOL AND JUNIOR COLLEGE, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12449",
            "district": "26",
            "Block": "370",
            "label": "WOODSIDE SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12450",
            "district": "26",
            "Block": "370",
            "label": "CHIRISTUS REX SR.SEC SCHOOL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12451",
            "district": "26",
            "Block": "370",
            "label": "BREEKS ALL INDIA SECONDARY SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12452",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL UDHAGAMANDALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12453",
            "district": "26",
            "Block": "370",
            "label": "SRI SHANTHI VIJAI GIRLS HSS -OOTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12454",
            "district": "26",
            "Block": "370",
            "label": "SACRED HEARTS HSS,OOTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12455",
            "district": "26",
            "Block": "370",
            "label": "BETHLEHEM (G) HSS OOTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12456",
            "district": "26",
            "Block": "370",
            "label": "CSIGELL MEM (G)HSS OOTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12457",
            "district": "26",
            "Block": "370",
            "label": "BREEKS MEMORIAL ANGLO  INDIAN HSS, OOTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12458",
            "district": "26",
            "Block": "370",
            "label": "ST.HILDAS (G) HSS OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12459",
            "district": "26",
            "Block": "370",
            "label": "JSS PUBLIC SCHOOL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12460",
            "district": "26",
            "Block": "370",
            "label": "GOOD SHEPHERED PUBLIC SCHOOL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12461",
            "district": "26",
            "Block": "370",
            "label": "HEBRON SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12462",
            "district": "26",
            "Block": "370",
            "label": "NIRMALA MATRICULATION SCHOOL, FINGERPOST",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12463",
            "district": "26",
            "Block": "370",
            "label": "MPL, (URUDU) MIDDLE SCHOOL GANDHINAGAR II",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12464",
            "district": "26",
            "Block": "370",
            "label": "ST.JOSEPHS HSS OOTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12465",
            "district": "26",
            "Block": "370",
            "label": "CSI (CMM) HR. SEC. SCHOOL, OOTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12466",
            "district": "26",
            "Block": "370",
            "label": "CRESENT CASTLE MATRIC HR.SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12467",
            "district": "26",
            "Block": "370",
            "label": "SRVS MATRICULATION SCHOOL,GREEN FIELD, OOTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12468",
            "district": "26",
            "Block": "370",
            "label": "CRESCENT CASTLE PUBLIC SCHOOL,OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12469",
            "district": "26",
            "Block": "370",
            "label": "PUMS, PYKARA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12470",
            "district": "26",
            "Block": "370",
            "label": "PUMS, T.R. BAZAAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12471",
            "district": "26",
            "Block": "370",
            "label": "ADW GHS HOOKER",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12472",
            "district": "26",
            "Block": "370",
            "label": "GHS NADUVATTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12473",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SHOLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12474",
            "district": "26",
            "Block": "370",
            "label": "PUMS, GLENMORGAN CAMP",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12475",
            "district": "26",
            "Block": "370",
            "label": "PUMS,  KILKUNDAH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12476",
            "district": "26",
            "Block": "370",
            "label": "GOVERNMENT (GIRLS) HIGH SCHOOL MANJOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12477",
            "district": "26",
            "Block": "370",
            "label": "ALPHONSA SCHOOL, MANJOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "12478",
            "district": "26",
            "Block": "370",
            "label": "GHSS MANJOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12479",
            "district": "26",
            "Block": "370",
            "label": "KUNDHA MATRIC SCHOOL,KOTRAKANDI, MANJOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "12480",
            "district": "26",
            "Block": "370",
            "label": "GHS BIKKATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12481",
            "district": "26",
            "Block": "370",
            "label": "GHSS YEDAKAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21470",
            "district": "26",
            "Block": "370",
            "label": "GHS SHOLUR KOTHAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21864",
            "district": "26",
            "Block": "370",
            "label": "NIZAM BRITISH INTERNATIONAL SCHOOL, OOTY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21865",
            "district": "26",
            "Block": "370",
            "label": "THE BLUEMOUNTAINS SCHOOL OOTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22132",
            "district": "26",
            "Block": "370",
            "label": "EKALAVYA MODEL RESIDENTIAL HIGHER SECONDARY SCHOOL M.PALADA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23164",
            "district": "26",
            "Block": "370",
            "label": "THE OXFORD CONTINENTAL SCHOOL ADASHOLA",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24047",
            "district": "26",
            "Block": "370",
            "label": "SRI SHANTHI VIJAI MATRICULATION SCHOOL, OOTY",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "371": [
        {
            "key": "478",
            "district": "32",
            "Block": "371",
            "label": "OXFORD HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "691",
            "district": "32",
            "Block": "371",
            "label": "RGMHSS UDUMALAIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "748",
            "district": "32",
            "Block": "371",
            "label": "SRINIVASA VIDHYALAYA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "750",
            "district": "32",
            "Block": "371",
            "label": "LOURDE MATHA CONVENT MATRIC  HSS KURALKUTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "771",
            "district": "32",
            "Block": "371",
            "label": "SRIGVG VISHALAKSHI MHSS UDUMALPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "995",
            "district": "32",
            "Block": "371",
            "label": "MMS KANAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1001",
            "district": "32",
            "Block": "371",
            "label": "R.V.G. MATRIC HSS KURICHIKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1002",
            "district": "32",
            "Block": "371",
            "label": "R K R GRKS MAT HSS, PERIYAKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1222",
            "district": "32",
            "Block": "371",
            "label": "PUMS SELLAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1476",
            "district": "32",
            "Block": "371",
            "label": "MMS PADDY BAZAAR STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1695",
            "district": "32",
            "Block": "371",
            "label": "PUMS VENJAMADAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1742",
            "district": "32",
            "Block": "371",
            "label": "PUMS SS PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2219",
            "district": "32",
            "Block": "371",
            "label": "PUMS CHINNAVEERAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2289",
            "district": "32",
            "Block": "371",
            "label": "STELLA MARIS MATRIC HSS,RAGALBAVI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2346",
            "district": "32",
            "Block": "371",
            "label": "PUMS K VALLAKUNDAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2352",
            "district": "32",
            "Block": "371",
            "label": "MMS EXTENSION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2393",
            "district": "32",
            "Block": "371",
            "label": "PUMS SUNDAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2459",
            "district": "32",
            "Block": "371",
            "label": "PUMS KANNAMANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3041",
            "district": "32",
            "Block": "371",
            "label": "PUMS ANTHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3302",
            "district": "32",
            "Block": "371",
            "label": "ANNAI ABIRAMI MATRIC HSS SCHOOL VILAMARATHUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3770",
            "district": "32",
            "Block": "371",
            "label": "PUMS ORALPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3774",
            "district": "32",
            "Block": "371",
            "label": "MMS PALANIANDAVAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4356",
            "district": "32",
            "Block": "371",
            "label": "PUMS KILUVANKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5055",
            "district": "32",
            "Block": "371",
            "label": "MMS RUDRAPPANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5563",
            "district": "32",
            "Block": "371",
            "label": "MMS KATCHERI STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5910",
            "district": "32",
            "Block": "371",
            "label": "PUMS PARTHASARATHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6366",
            "district": "32",
            "Block": "371",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL DHALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6650",
            "district": "32",
            "Block": "371",
            "label": "U.K.P.M MATRICULATION SCHOOL UDUMALPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13021",
            "district": "32",
            "Block": "371",
            "label": "NARAYANASAMY GHSS PERIYAVALAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13022",
            "district": "32",
            "Block": "371",
            "label": "PUMS CHINNAPOOLANKINAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13023",
            "district": "32",
            "Block": "371",
            "label": "PUMS BODIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13024",
            "district": "32",
            "Block": "371",
            "label": "VAV INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13025",
            "district": "32",
            "Block": "371",
            "label": "GKN HSS PUNGAMUTHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13026",
            "district": "32",
            "Block": "371",
            "label": "GOVERNMENT HIGH SCHOOL THIRUMOORTHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13027",
            "district": "32",
            "Block": "371",
            "label": "GHSS JALLIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13028",
            "district": "32",
            "Block": "371",
            "label": "PUMS PALLAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13029",
            "district": "32",
            "Block": "371",
            "label": "PUMS,MANUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13030",
            "district": "32",
            "Block": "371",
            "label": "GHS,MALAIYANDIPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13031",
            "district": "32",
            "Block": "371",
            "label": "PON NAAVARASU MATRIC SCHOOL, MALAIYANDIGOUNDANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13032",
            "district": "32",
            "Block": "371",
            "label": "PUMS,THUMBALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13033",
            "district": "32",
            "Block": "371",
            "label": "GHSS RAJENDRA ROAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13034",
            "district": "32",
            "Block": "371",
            "label": "GBHSS UDUMALPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13035",
            "district": "32",
            "Block": "371",
            "label": "BCGGHSS UDUMALPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13036",
            "district": "32",
            "Block": "371",
            "label": "SVG HSS UDUMALPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13037",
            "district": "32",
            "Block": "371",
            "label": "S.K.P.HSS UDUMALPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13038",
            "district": "32",
            "Block": "371",
            "label": "RKR HSS UDUMALPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13039",
            "district": "32",
            "Block": "371",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13040",
            "district": "32",
            "Block": "371",
            "label": "GOVT HIGH SCHOOL, SIVASAKTHI COLONY UDUMALPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13041",
            "district": "32",
            "Block": "371",
            "label": "PMGHSS PULANKINAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13042",
            "district": "32",
            "Block": "371",
            "label": "GGHSS DEVANURPUDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13043",
            "district": "32",
            "Block": "371",
            "label": "GHSS UDUKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13044",
            "district": "32",
            "Block": "371",
            "label": "SRI VENKATESWARA MAT SCHOOL  MANUPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13045",
            "district": "32",
            "Block": "371",
            "label": "SAINIK SCHOOL AMARAVATHI NAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13046",
            "district": "32",
            "Block": "371",
            "label": "GHS AMARAVATHINAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13047",
            "district": "32",
            "Block": "371",
            "label": "GHS KALLAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13048",
            "district": "32",
            "Block": "371",
            "label": "SNVGHSS ELAYAMUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13049",
            "district": "32",
            "Block": "371",
            "label": "RVG HSS KURICHIKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13050",
            "district": "32",
            "Block": "371",
            "label": "SRINIVASA VIDHYALAYA AIDED MIDDLE SCHOOL GANAPATHIPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20970",
            "district": "32",
            "Block": "371",
            "label": "SHAANTHI MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21084",
            "district": "32",
            "Block": "371",
            "label": "SRINIVASA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21154",
            "district": "32",
            "Block": "371",
            "label": "SRI SRISTI VIKKAS ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL , PARTHASARATHYPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21155",
            "district": "32",
            "Block": "371",
            "label": "SREE  ADHARSH  MATRICULATION  HR.SEC. SCHOOL, PALLAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21273",
            "district": "32",
            "Block": "371",
            "label": "RKR GNANODHAYA MATRIC HSS SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22146",
            "district": "32",
            "Block": "371",
            "label": "GOVT ADHIDRAVIDAR WELFARE HIGH SCHOOL AMMAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22486",
            "district": "32",
            "Block": "371",
            "label": "SHRI VENKATESHWARA VIDYALAYA DEVANURPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22836",
            "district": "32",
            "Block": "371",
            "label": "AMAZON PUBLIC SCHOOL-UDUMALPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23115",
            "district": "32",
            "Block": "371",
            "label": "KENDRIYA VIDYALAYA , UDUMALPET",
            "Sch_type": "Central Govt",
            "Category": "Middle School"
        },
        {
            "key": "24048",
            "district": "32",
            "Block": "371",
            "label": "PUMS KAMARAJ NAGAR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24049",
            "district": "32",
            "Block": "371",
            "label": "Tiruppur District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "372": [
        {
            "key": "10842",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERIYAKURUKKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10843",
            "district": "9",
            "Block": "372",
            "label": "OM GURU GANESA MATRICULATION SCHOOL KATTUEDAIYAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10844",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL KATTUEDAIYAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10845",
            "district": "9",
            "Block": "372",
            "label": "ST.JOSEPH HSS, ERAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10846",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T PALLAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10847",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOOVADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10848",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NATHAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10849",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KALSIRUNAGALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10850",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUNJARAM (H)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10851",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KATTUSELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10852",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOOTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10853",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ELLAIGIRAMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10854",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ELAVANASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10855",
            "district": "9",
            "Block": "372",
            "label": "SRI VINAYAGA MATRICULATION HIGHER SECONDARY SCHOOL  ELAVANASUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10856",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL  ELAVANASUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10857",
            "district": "9",
            "Block": "372",
            "label": "BHARATH MATRICULATION  SCHOOL ELAVANASUR KOTTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10858",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VADAKURUMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10859",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ANGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10860",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT MIDDLE SCHOOL PARINTHAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10861",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T.THAKKA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10862",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL NEDUMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10863",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EMAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10864",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  SIRUPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10865",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PUGAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10866",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL M.KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10867",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THAMAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10868",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KILIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10869",
            "district": "9",
            "Block": "372",
            "label": "SRI RAMAKRISHNA VIDYALAYA GURUKULAM MATRIC HIGHER SECONDARY SCHOOL EDAIKKAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10870",
            "district": "9",
            "Block": "372",
            "label": "KASTHURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL A. KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10871",
            "district": "9",
            "Block": "372",
            "label": "KASTHURBA GANDHI BALIKA VIDHYALAYA RESIDENTIAL SCHOOL KOOTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10872",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PALI PUTHUCOLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10873",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY  SCHOOL  PALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10874",
            "district": "9",
            "Block": "372",
            "label": "BUTTERFLY MATRICULATION SCHOOL SHEIK HUSSAINPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10875",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KANAIYAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10876",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ASANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10877",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PU.KONALAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10878",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PU.KILLANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10879",
            "district": "9",
            "Block": "372",
            "label": "PARKAVAN MATRICULATION HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10880",
            "district": "9",
            "Block": "372",
            "label": "RAVLATHUL JANNAH ORIENTAL HIGH SCHOOL  ULUNDURPET",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "10881",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  R.R.KUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10882",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VELLAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10883",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ULUNDURPET(HINDU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10884",
            "district": "9",
            "Block": "372",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ULUNDANDAR KOIL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10885",
            "district": "9",
            "Block": "372",
            "label": "BESCHI HIGHER SECONDARY SCHOOL  ULUNDURPET",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10886",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT GIRLS MODEL HIGHER SECONDARY SCHOOL ULUNDURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10887",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT  BOYS HIGHER SECONDARY SCHOOL  ULUNDURPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10888",
            "district": "9",
            "Block": "372",
            "label": "SRI SARADA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL  ULUNDURPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10889",
            "district": "9",
            "Block": "372",
            "label": "BEULAH  MATRICULATION SCHOOL ULUNDURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21064",
            "district": "9",
            "Block": "372",
            "label": "SRI RAMANA PUBLIC SCHOOL(CBSE), KATTUSELLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21204",
            "district": "9",
            "Block": "372",
            "label": "MODEL SCHOOL - GOVERNMENT HIGHER SECONDARY SCHOOL  A.KUMARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21456",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL SIKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21641",
            "district": "9",
            "Block": "372",
            "label": "CHORDIA JAIN SCHOOL OLAYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21934",
            "district": "9",
            "Block": "372",
            "label": "SRI RAMAKRISHNA VIDYA MANDIR INTERNATIONAL SCHOOL, ULUNDURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21935",
            "district": "9",
            "Block": "372",
            "label": "RPK MIDDLE SCHOOL ULUNDURPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22887",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL PINNALVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22987",
            "district": "9",
            "Block": "372",
            "label": "ST. ANNE'S GIRLS HIGHER SECONDARY  SCHOOL ERAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23230",
            "district": "9",
            "Block": "372",
            "label": "GOVERNMENT HIGH SCHOOL ADHAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23486",
            "district": "9",
            "Block": "372",
            "label": "SRI GNANAMBIGAA VIDYALAYA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23486",
            "district": "9",
            "Block": "372",
            "label": "SRI GNANAMBIGAA VIDYALAYA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "373": [
        {
            "key": "14641",
            "district": "29",
            "Block": "373",
            "label": "PUMS, T.PATHERPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14642",
            "district": "29",
            "Block": "373",
            "label": "PUMS, T.MANGAPATTI PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14643",
            "district": "29",
            "Block": "373",
            "label": "GHSS, T.MURUNGAPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14644",
            "district": "29",
            "Block": "373",
            "label": "ADWHS, KOPPAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14645",
            "district": "29",
            "Block": "373",
            "label": "PUMS, KOPPAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14646",
            "district": "29",
            "Block": "373",
            "label": "GOVT HIGH SCHOOL, NAGANALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14647",
            "district": "29",
            "Block": "373",
            "label": "SVVHSS, VAIRICHETTIPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14648",
            "district": "29",
            "Block": "373",
            "label": "GHS, SOBANAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14649",
            "district": "29",
            "Block": "373",
            "label": "AMS, VENKATACHALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14650",
            "district": "29",
            "Block": "373",
            "label": "GTRHSS, TOP SENGATTUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14651",
            "district": "29",
            "Block": "373",
            "label": "GTRMS, PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14652",
            "district": "29",
            "Block": "373",
            "label": "GHSS,UPPILIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14653",
            "district": "29",
            "Block": "373",
            "label": "RCMS, KOTTAPALAYAM (BOYS)",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14654",
            "district": "29",
            "Block": "373",
            "label": "OLLGHSS, KOTTAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14655",
            "district": "29",
            "Block": "373",
            "label": "GOVT. ADW HS, BALAKRISHNAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14656",
            "district": "29",
            "Block": "373",
            "label": "Government Higher Secondary School, B.METTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14657",
            "district": "29",
            "Block": "373",
            "label": "PUMS, E.KEELAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14658",
            "district": "29",
            "Block": "373",
            "label": "HAMS, OKKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14659",
            "district": "29",
            "Block": "373",
            "label": "AAGHSS, PACHAPERUMALPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14660",
            "district": "29",
            "Block": "373",
            "label": "GHS, NETTAVELAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14661",
            "district": "29",
            "Block": "373",
            "label": "PUMS, ALATHUDAIYANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14662",
            "district": "29",
            "Block": "373",
            "label": "GHS, SIRUNAVALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14663",
            "district": "29",
            "Block": "373",
            "label": "PUMS, KAMATCHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14664",
            "district": "29",
            "Block": "373",
            "label": "AGMHSS, ERAGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14665",
            "district": "29",
            "Block": "373",
            "label": "AMS, ALAGAPURI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "22193",
            "district": "29",
            "Block": "373",
            "label": "RSK INTERNATIONAL (CBSE) SCHOOL,VENKATACHALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22535",
            "district": "29",
            "Block": "373",
            "label": "SRI RAMAKRISHNA VIDYALAYA MATRICULATION SCHOOL, UPPILIYAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "374": [
        {
            "key": "3837",
            "district": "14",
            "Block": "374",
            "label": "SEVENTHDAY ADVENTIST MATRICULATION HIGHER SECONDARY SCHOOL, USILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5450",
            "district": "14",
            "Block": "374",
            "label": "PONMANI MATRIC HR SEC SCHOOL, USILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18310",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S. UTHAPPANAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18311",
            "district": "14",
            "Block": "374",
            "label": "JAYASEELAN MEMORIAL MATRIC SCHOOL, UTHAPPANAYAKKANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18312",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S, U.VADIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18313",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HR SEC SCHOOL, VELLAIMALAIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18314",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S KAMARAJAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18315",
            "district": "14",
            "Block": "374",
            "label": "A RATHINASAMY NADAR MEMORIAL HIGHER SECONDARY SCHOOL UTHAPPANAYAKKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18316",
            "district": "14",
            "Block": "374",
            "label": "GOVT.K.M.S. KALLUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18317",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HR SEC SCHOOL, K.PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18318",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, SULIOCHANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18319",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18320",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S.SEEMANUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18321",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HR SEC SCHOOL, A.POOCHIPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23693",
            "district": "14",
            "Block": "374",
            "label": "Sathya Vidhyalaya International School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "18322",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S. MELAEDAYAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18323",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S CHETTIAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18324",
            "district": "14",
            "Block": "374",
            "label": "GOVT HIGH SCHOOL, THOTTAPPANAYAKKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18325",
            "district": "14",
            "Block": "374",
            "label": "GOVERNMENT KALLAR HIGH SCHOOL  NALLUTHEVANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18326",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S POTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18327",
            "district": "14",
            "Block": "374",
            "label": "GOVERNMENT KALLAR HIGH SCHOOL  VAGURANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18328",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S AYOTHIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18329",
            "district": "14",
            "Block": "374",
            "label": "GOVT K.M.S ERUMARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18330",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S ALLIKUNDAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18331",
            "district": "14",
            "Block": "374",
            "label": "GOVT. K.M.S MANUTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18332",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S JOTHILNAICKANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18333",
            "district": "14",
            "Block": "374",
            "label": "NADAR SARASWATHI MATRICULATION HR SEC SCHOOL, USILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18334",
            "district": "14",
            "Block": "374",
            "label": "R C LITTLE FLOWER GIRLS HIGHER SECONDARY SCHOOL MELAPUDUR USILAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18335",
            "district": "14",
            "Block": "374",
            "label": "P.U.M.S  BAJAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18336",
            "district": "14",
            "Block": "374",
            "label": "GOVT HR SEC SCHOOL, USILAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18337",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, KARUKATTANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18338",
            "district": "14",
            "Block": "374",
            "label": "T.E.L.C.M.S   USILAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18339",
            "district": "14",
            "Block": "374",
            "label": "T E L C GIRLS' HIGHER SECONDARY SCHOOL USILAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18340",
            "district": "14",
            "Block": "374",
            "label": "T.E.L.C M.S  KEELAPUDUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18341",
            "district": "14",
            "Block": "374",
            "label": "NADAR SARASWATHI HIGHER SECONDARY SCHOOL  USILAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21168",
            "district": "14",
            "Block": "374",
            "label": "JAYASEELAN MATRIC HR SEC SCHOOL, MATHARAI, MUTHUPANDIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21488",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, VADUGAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21489",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, MEIKILARPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21637",
            "district": "14",
            "Block": "374",
            "label": "AACHI INTERNATIONAL  SCHOOL, USILAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22255",
            "district": "14",
            "Block": "374",
            "label": "KEREN PUBLIC SCHOOL,  KARAYANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22256",
            "district": "14",
            "Block": "374",
            "label": "KEREN MATRIC SCHOOL, KARAYANPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22610",
            "district": "14",
            "Block": "374",
            "label": "GOVT KALLAR HIGH SCHOOL, KEERIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23037",
            "district": "14",
            "Block": "374",
            "label": "GOVT. KALLAR HIGH SCHOOL, V.PERUMALPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23517",
            "district": "14",
            "Block": "374",
            "label": "GREEN PARK VIDYALAYA NURSURY AND PRIMARY SCHOOL, J.J. NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23517",
            "district": "14",
            "Block": "374",
            "label": "GREEN PARK VIDYALAYA NURSURY AND PRIMARY SCHOOL, J.J. NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "375": [
        {
            "key": "1533",
            "district": "27",
            "Block": "375",
            "label": "SRI KANNIKA PARAMESWARI MIDDLE SCHOOL - KOMBAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1732",
            "district": "27",
            "Block": "375",
            "label": "PUMS - THEVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1832",
            "district": "27",
            "Block": "375",
            "label": "THE CRESCENT MATRIC HIGHER SECONDARY SCHOOL - UTHAMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2175",
            "district": "27",
            "Block": "375",
            "label": "BENEDICT MATRIC HIGHER SECONDARY SCHOOL - ANAIMALAYANPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2396",
            "district": "27",
            "Block": "375",
            "label": "SRI VIKASA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL - UTHAMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2414",
            "district": "27",
            "Block": "375",
            "label": "PUMS - GANAMMAN KOVIL STREET, UTHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2452",
            "district": "27",
            "Block": "375",
            "label": "SRI ONNU RAMASWAMY MIDDLE SCHOOL - THEVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2647",
            "district": "27",
            "Block": "375",
            "label": "VIVEKANANDA VIDHYALAYAM MIDDLE SCHOOL - THEVARAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2768",
            "district": "27",
            "Block": "375",
            "label": "SRI ARAVINDAR BALAR MATRIC HIGHER SECONDARY SCHOOL- UTHAMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2941",
            "district": "27",
            "Block": "375",
            "label": "Sri N.M.R. SUBBARAMAN MIDDLE SCHOOL - KOHILAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2959",
            "district": "27",
            "Block": "375",
            "label": "PUMS - ANAMALAYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3067",
            "district": "27",
            "Block": "375",
            "label": "T.H.N.U. MATRICULATION HIGHER SECONDARY SCHOOL - THEVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3105",
            "district": "27",
            "Block": "375",
            "label": "PUMS - BAZZAR, UTHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3168",
            "district": "27",
            "Block": "375",
            "label": "PUMS - CHOCKALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3178",
            "district": "27",
            "Block": "375",
            "label": "PUMS - KALIMETTUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3596",
            "district": "27",
            "Block": "375",
            "label": "PUMS - NAGAYAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3748",
            "district": "27",
            "Block": "375",
            "label": "St. PERIYANAYAGI MIDDLE SCHOOL -  UTHAMAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3999",
            "district": "27",
            "Block": "375",
            "label": "PUMS - KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4136",
            "district": "27",
            "Block": "375",
            "label": "St. THERASA'S R.C. MIDDLE SCHOOL - THEVARAM.",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4397",
            "district": "27",
            "Block": "375",
            "label": "HINDU MIDDLE SCHOOL - PALLAVARAYANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5180",
            "district": "27",
            "Block": "375",
            "label": "SRI SUBRAMANIA BARATHI MIDDLE SCHOOL - THAMMINAYAKANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5949",
            "district": "27",
            "Block": "375",
            "label": "ALHIKMAH MATRIC HIGHER SECONDARY SCHOOL - PTR.COLONY,UTHAMAPALAYAM.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18639",
            "district": "27",
            "Block": "375",
            "label": "St. ALOYSIUS BOYS Hr.,SEC.,SCHOOL - ROYAPPANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18640",
            "district": "27",
            "Block": "375",
            "label": "St. AGNES GIRLS Hr.,Sec.,SCHOOL - ROYAPPANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18641",
            "district": "27",
            "Block": "375",
            "label": "SAVARIYAPPA UDAYAR MEMORIAL HIGHER SECONDARY SCHOOL - ROYAPPANPATTY",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18642",
            "district": "27",
            "Block": "375",
            "label": "AMALA ANNAI HIGHER SECONDARY SCHOOL -  T.SINTHALAICHERY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18643",
            "district": "27",
            "Block": "375",
            "label": "HINDU MIDDLE SCHOOL - PANNAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18644",
            "district": "27",
            "Block": "375",
            "label": "GOVT HSS, PANNAIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18645",
            "district": "27",
            "Block": "375",
            "label": "GOVT HSS, MELASINDALAICHERY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18646",
            "district": "27",
            "Block": "375",
            "label": "SRI KANNIKA PARAMESWARI HIGHER SECONDARY SCHOOL - KOMBAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18647",
            "district": "27",
            "Block": "375",
            "label": "AYYA MATRICULATION  SCHOOL - KOMBAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18648",
            "district": "27",
            "Block": "375",
            "label": "GOVT HSS, THEVARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18649",
            "district": "27",
            "Block": "375",
            "label": "SRI RENGASAMY MEMORIAL HIGHER SECONDARY SCHOOL - LAKSHMINAYAKKANPATTY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18650",
            "district": "27",
            "Block": "375",
            "label": "MOHAMED FATHIMA GIRLS HIGH SCHOOL - UTHAMAPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18651",
            "district": "27",
            "Block": "375",
            "label": "GOVT MODEL  HSS, UTHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18652",
            "district": "27",
            "Block": "375",
            "label": "GOVERNMENT  HIGH SCHOOL   -  U.AMMAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18653",
            "district": "27",
            "Block": "375",
            "label": "ST. JOSEPH'S R.C. HIGH SCHOOL - UTHAMAPALAYAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "22750",
            "district": "27",
            "Block": "375",
            "label": "LOYOLA INTERNATIONAL PUBLIC SCHOOL - T.SINDALAICHERY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23571",
            "district": "27",
            "Block": "375",
            "label": "GREEN MOUNT PUBLIC SCHOOL - PANNAIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23571",
            "district": "27",
            "Block": "375",
            "label": "GREEN MOUNT PUBLIC SCHOOL - PANNAIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "376": [
        {
            "key": "547",
            "district": "13",
            "Block": "376",
            "label": "SRI VIDYA MANDIR MHSS UTHANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1849",
            "district": "13",
            "Block": "376",
            "label": "VIDYA VIKAS MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3265",
            "district": "13",
            "Block": "376",
            "label": "PUMS MUSILIKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3665",
            "district": "13",
            "Block": "376",
            "label": "PUMS MOONGILERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3667",
            "district": "13",
            "Block": "376",
            "label": "PUMS KOTTARAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3688",
            "district": "13",
            "Block": "376",
            "label": "PUMS ELAVAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4111",
            "district": "13",
            "Block": "376",
            "label": "PUMS PULIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4254",
            "district": "13",
            "Block": "376",
            "label": "PUMS U.REDDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4456",
            "district": "13",
            "Block": "376",
            "label": "PUMS VELLAKUTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4464",
            "district": "13",
            "Block": "376",
            "label": "PUMS BADAPPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4705",
            "district": "13",
            "Block": "376",
            "label": "PUMS NAPPIRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4825",
            "district": "13",
            "Block": "376",
            "label": "ADHIYAMAN MHSS UTHANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4974",
            "district": "13",
            "Block": "376",
            "label": "PUMS ODDAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5007",
            "district": "13",
            "Block": "376",
            "label": "PUMS VEPPALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5168",
            "district": "13",
            "Block": "376",
            "label": "PUMS THURINJIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5494",
            "district": "13",
            "Block": "376",
            "label": "PUMS GENGAPIRAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5595",
            "district": "13",
            "Block": "376",
            "label": "PUMS KADAVANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5848",
            "district": "13",
            "Block": "376",
            "label": "PUMS PERUMALNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6015",
            "district": "13",
            "Block": "376",
            "label": "SIVA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6082",
            "district": "13",
            "Block": "376",
            "label": "PUMS CHINNAKARAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6094",
            "district": "13",
            "Block": "376",
            "label": "CHRIST MHSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6130",
            "district": "13",
            "Block": "376",
            "label": "CHRIST MATRIC SCHOOL, SINGARAPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6235",
            "district": "13",
            "Block": "376",
            "label": "PUMS SINGARAPETTAI URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6281",
            "district": "13",
            "Block": "376",
            "label": "PUMS JOTHINAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6447",
            "district": "13",
            "Block": "376",
            "label": "PUMS MARAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6554",
            "district": "13",
            "Block": "376",
            "label": "PUMS KODALAVALASAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6562",
            "district": "13",
            "Block": "376",
            "label": "PUMS KATTANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20416",
            "district": "13",
            "Block": "376",
            "label": "GHS A.PALLATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20417",
            "district": "13",
            "Block": "376",
            "label": "PUMS ANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20418",
            "district": "13",
            "Block": "376",
            "label": "GHSS ATHIPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20419",
            "district": "13",
            "Block": "376",
            "label": "GHS CHENNAPPANAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20420",
            "district": "13",
            "Block": "376",
            "label": "GHS P.ETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20421",
            "district": "13",
            "Block": "376",
            "label": "GHS GOVINDAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20422",
            "district": "13",
            "Block": "376",
            "label": "GHS HANUMANTHEERTHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20423",
            "district": "13",
            "Block": "376",
            "label": "GHSS KALLAVI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20424",
            "district": "13",
            "Block": "376",
            "label": "GHS- GIRLS KALLAVI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20425",
            "district": "13",
            "Block": "376",
            "label": "WISDOM MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20426",
            "district": "13",
            "Block": "376",
            "label": "SRI BALAVIDHYA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20427",
            "district": "13",
            "Block": "376",
            "label": "GHSS KARAPATTU - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20428",
            "district": "13",
            "Block": "376",
            "label": "GHSS KEELKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20429",
            "district": "13",
            "Block": "376",
            "label": "GHS KEELMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20430",
            "district": "13",
            "Block": "376",
            "label": "PUMS KETHUNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20431",
            "district": "13",
            "Block": "376",
            "label": "PUMS KOMMAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20432",
            "district": "13",
            "Block": "376",
            "label": "GHS KONAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20433",
            "district": "13",
            "Block": "376",
            "label": "PUMS KONDAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20434",
            "district": "13",
            "Block": "376",
            "label": "GHS KOTTUKARAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20435",
            "district": "13",
            "Block": "376",
            "label": "PUMS LAKKAMPATTI COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20436",
            "district": "13",
            "Block": "376",
            "label": "GHSS MAGANURPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20437",
            "district": "13",
            "Block": "376",
            "label": "GHSS MITTAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20438",
            "district": "13",
            "Block": "376",
            "label": "MARY WARD MATRIC HSS MITTAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20439",
            "district": "13",
            "Block": "376",
            "label": "BALA MANDIR MATRICULATION SCHOOL B.PUDUR, MITTAPALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20440",
            "district": "13",
            "Block": "376",
            "label": "GHS NAICKANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20441",
            "district": "13",
            "Block": "376",
            "label": "PUMS NALLAVAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20442",
            "district": "13",
            "Block": "376",
            "label": "GHSS PERIYATHALLAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20443",
            "district": "13",
            "Block": "376",
            "label": "GHS PUNGANAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20444",
            "district": "13",
            "Block": "376",
            "label": "GHSS SINGARAPETTAI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20445",
            "district": "13",
            "Block": "376",
            "label": "GHSS- GIRLS  SINGARAPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20446",
            "district": "13",
            "Block": "376",
            "label": "MOTHERS MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20447",
            "district": "13",
            "Block": "376",
            "label": "GHS THIRUVANAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20448",
            "district": "13",
            "Block": "376",
            "label": "GHS UPPARAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20449",
            "district": "13",
            "Block": "376",
            "label": "GHSS UTHANGARAI BOYS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20450",
            "district": "13",
            "Block": "376",
            "label": "GHSS-GIRLS UTHANGARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20451",
            "district": "13",
            "Block": "376",
            "label": "PUMS CHENGALNEERPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20452",
            "district": "13",
            "Block": "376",
            "label": "GHS EGGUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20453",
            "district": "13",
            "Block": "376",
            "label": "PUMS P.NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20454",
            "district": "13",
            "Block": "376",
            "label": "GHS N.VELLALAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21194",
            "district": "13",
            "Block": "376",
            "label": "GHSS   PAVAKKAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21345",
            "district": "13",
            "Block": "376",
            "label": "DHEERAN CHINNAMALAI INTERNATIONAL RESIDENTIAL SCHOOL, UTHANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21419",
            "district": "13",
            "Block": "376",
            "label": "GHS M.VELLALAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21627",
            "district": "13",
            "Block": "376",
            "label": "LOURDU MATHA MATRICULATION SCHOOL VISUVASAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22291",
            "district": "13",
            "Block": "376",
            "label": "R.P.S MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22668",
            "district": "13",
            "Block": "376",
            "label": "ADHIYAMAN PUBLIC SCHOOL, UTHANGARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22669",
            "district": "13",
            "Block": "376",
            "label": "SRS MATRICULATION HR SEC SCHOOL, ELACHOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22890",
            "district": "13",
            "Block": "376",
            "label": "GHS CHANDRAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23504",
            "district": "13",
            "Block": "376",
            "label": "OXFORD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23504",
            "district": "13",
            "Block": "376",
            "label": "OXFORD INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "377": [
        {
            "key": "5366",
            "district": "10",
            "Block": "377",
            "label": "Servasev Vidhyalaya Matriculation Higher Secondary School, Manithottam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5692",
            "district": "10",
            "Block": "377",
            "label": "SERVASEV VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, KUNNAVAKKAM-603107",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7674",
            "district": "10",
            "Block": "377",
            "label": "PUMS, KARUVEPPAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7675",
            "district": "10",
            "Block": "377",
            "label": "GHSS, Kaliyampoondi",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7676",
            "district": "10",
            "Block": "377",
            "label": "PUMS, AZHISOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7677",
            "district": "10",
            "Block": "377",
            "label": "PUMS, ELANAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7678",
            "district": "10",
            "Block": "377",
            "label": "GOVT MODEL HR SEC SCHOOL, PERUNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7679",
            "district": "10",
            "Block": "377",
            "label": "R.C MIDDLE SCHOOL , MANAMPATHY KANDIGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7680",
            "district": "10",
            "Block": "377",
            "label": "GHSS,MANAMPATHY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7681",
            "district": "10",
            "Block": "377",
            "label": "PUMS, VISOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7682",
            "district": "10",
            "Block": "377",
            "label": "ST.JOSEPH (AIDED)HS, R.N.KANDIGAI, UTHIRAMERUR TK",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7683",
            "district": "10",
            "Block": "377",
            "label": "PUMS, PENNALUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7684",
            "district": "10",
            "Block": "377",
            "label": "GHS,KATTUPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7685",
            "district": "10",
            "Block": "377",
            "label": "PUMS, ARASANIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7686",
            "district": "10",
            "Block": "377",
            "label": "PUMS, AGARAMDULY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7687",
            "district": "10",
            "Block": "377",
            "label": "PUMS KAVANURPUDHUCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7688",
            "district": "10",
            "Block": "377",
            "label": "GHSS,KAMMALAMPOONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7689",
            "district": "10",
            "Block": "377",
            "label": "Asefa HS-kattukollai",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7690",
            "district": "10",
            "Block": "377",
            "label": "Meenakshi Ammal Matric Hr Sec School Perunkozhi ,UTHIRAMERUR 603406",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7691",
            "district": "10",
            "Block": "377",
            "label": "Sarva Seva HS, Chinnamakulam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7692",
            "district": "10",
            "Block": "377",
            "label": "PUMS, MARUTHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7693",
            "district": "10",
            "Block": "377",
            "label": "GHSS THIRUPULIVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7694",
            "district": "10",
            "Block": "377",
            "label": "GHSS NEIYADUPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7695",
            "district": "10",
            "Block": "377",
            "label": "PUMS MALAYANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7696",
            "district": "10",
            "Block": "377",
            "label": "PEERY MHSS THOTTANAVAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7697",
            "district": "10",
            "Block": "377",
            "label": "GOVT ADW HR SEC SCHOOL RETTAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7698",
            "district": "10",
            "Block": "377",
            "label": "PUMS, KUNNAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7699",
            "district": "10",
            "Block": "377",
            "label": "GHS ANAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7700",
            "district": "10",
            "Block": "377",
            "label": "PUMS VAYALAKKAVUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7701",
            "district": "10",
            "Block": "377",
            "label": "GHS THIRUMUKKUDAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7702",
            "district": "10",
            "Block": "377",
            "label": "PUMS, PINAYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7703",
            "district": "10",
            "Block": "377",
            "label": "GHS,SEETANANCHERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7704",
            "district": "10",
            "Block": "377",
            "label": "PUMS, SATHANANCHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7705",
            "district": "10",
            "Block": "377",
            "label": "GHSS,ORAKKATTUPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7706",
            "district": "10",
            "Block": "377",
            "label": "AMALA ANNAI (AIDED) Higher Sec School,MAMBAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7707",
            "district": "10",
            "Block": "377",
            "label": "PUMS, EDAMACHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7708",
            "district": "10",
            "Block": "377",
            "label": "PUMS THIRUVANTHAVAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7709",
            "district": "10",
            "Block": "377",
            "label": "GHSS,SALAVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7710",
            "district": "10",
            "Block": "377",
            "label": "PUMS, OZHAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7711",
            "district": "10",
            "Block": "377",
            "label": "ASEFA HS, MEYYURODAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7712",
            "district": "10",
            "Block": "377",
            "label": "GBHSS,UTHIRAMERUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7713",
            "district": "10",
            "Block": "377",
            "label": "PANCHAYAT UNION MODEL MIDDLE SCHOOL, UTHIRAMERUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7714",
            "district": "10",
            "Block": "377",
            "label": "GGHSS,UTHIRAMERUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7715",
            "district": "10",
            "Block": "377",
            "label": "PALARNESAN AIDED MIDDLE SCHOOL - UTHIRAMERUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7716",
            "district": "10",
            "Block": "377",
            "label": "PUMS, VADANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7717",
            "district": "10",
            "Block": "377",
            "label": "RAMAKRISHNA MISSION HIGH SCHOOL, MALLIANKARANAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7718",
            "district": "10",
            "Block": "377",
            "label": "LOYOLA HSS, KUPPAYANALLUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7719",
            "district": "10",
            "Block": "377",
            "label": "PUMS , VEDAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7720",
            "district": "10",
            "Block": "377",
            "label": "PUMS, 1-3 WARD, UTHIRAMERUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21297",
            "district": "10",
            "Block": "377",
            "label": "MEENAKSHI AMMAL GLOBAL SCHOOL PERUNGOZHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21901",
            "district": "10",
            "Block": "377",
            "label": "Adithyaa Matriculation School, Perunagar",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "378": [
        {
            "key": "677",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, UTHUKULI RAILWAY STATION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "825",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, ATHIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1006",
            "district": "32",
            "Block": "378",
            "label": "SRI KUMARAN MATRICULATION HIGHER SECONDARY SCHOOL, CHENGAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1045",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1080",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1098",
            "district": "32",
            "Block": "378",
            "label": "GHS, CHENGAPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "1125",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEELAGOUNDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1243",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THENEESWARANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1558",
            "district": "32",
            "Block": "378",
            "label": "KONGU MATRICULATION HIGHER SECONDARY SCHOOL, UTHUKULI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1693",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KADAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1697",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,  KASTHURIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1778",
            "district": "32",
            "Block": "378",
            "label": "KONGU MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1929",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2008",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SUNDAKKAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2123",
            "district": "32",
            "Block": "378",
            "label": "ANNAI MATRICULATION HIGHER SECONDARY SCHOOL, UTHUKULI RAILWAY STATION",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2462",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT HIGH SCHOOL, ARUGAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "2515",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PARAPPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2581",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, 16,VELAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2966",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, UTHUKULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2993",
            "district": "32",
            "Block": "378",
            "label": "SARASWATHI KALVI NILAYAM MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4144",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, GOVINDAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4522",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIRUVAIMUDHALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4589",
            "district": "32",
            "Block": "378",
            "label": "NANJUNDAPURAM RANGASAMY KARUPPANNA NADAR HIGH SCHOOL, KUNNATHUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "4609",
            "district": "32",
            "Block": "378",
            "label": "GHSS, MORATTUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5256",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THIMMANAYAKKANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12359",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, UTHUKULI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12360",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, SENAPATHI CHETTIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12361",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VELLIRAVELI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12362",
            "district": "32",
            "Block": "378",
            "label": "GHS SARKAR PERIYAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12363",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NALLIGOUNDANPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12364",
            "district": "32",
            "Block": "378",
            "label": "SMGHSS SURIYAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12365",
            "district": "32",
            "Block": "378",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, PAPPAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12366",
            "district": "32",
            "Block": "378",
            "label": "GHSS SARAVANAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21310",
            "district": "32",
            "Block": "378",
            "label": "VEDANTA ACADEMY,UTHUKULI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21311",
            "district": "32",
            "Block": "378",
            "label": "SRI KUMARAN PUBLIC SENIOR SECONDARY SCHOOL, SENGAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22130",
            "district": "32",
            "Block": "378",
            "label": "SRI VETRI VINAYAGA MATRIC SCHOOL, PULANGULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22131",
            "district": "32",
            "Block": "378",
            "label": "AALAYA  ACADEMY, SARKAR PERIYAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22470",
            "district": "32",
            "Block": "378",
            "label": "GOVERNMENT HIGH SCHOOL, KARUMANCHIRAI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "379": [
        {
            "key": "6188",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EDAYANCHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10253",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL THENKODIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10254",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL ULAGAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10255",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KILAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10256",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KARATTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10257",
            "district": "37",
            "Block": "379",
            "label": "AIDED MIDDLE SCHOOL UPPUVELUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10258",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PUDUKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10259",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T. PARANGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10260",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NALLAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10261",
            "district": "37",
            "Block": "379",
            "label": "ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL KONDAMUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10262",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARUVAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10263",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL THENSIRUVALORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10264",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ADHANAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10265",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL THERKUNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10266",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KILIYANUR (C)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10267",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KILIYANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10268",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KONJIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10269",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KAZHUPERUMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10270",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KOZHUVARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10271",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NESAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10272",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KODUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10273",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THAILAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10274",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL KUNNAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10275",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERUMPAKKAM (C)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10276",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ERAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10277",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KARASANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10278",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL SEMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10279",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RANGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10280",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL KATRAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10281",
            "district": "37",
            "Block": "379",
            "label": "ARUNACHALAM AIDED MIDDLE SCHOOL OZHUNDIYAPATTU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10282",
            "district": "37",
            "Block": "379",
            "label": "ADI DRAVIDAR WELFARE HIGHER SECONDARY SCHOOL  PULICHAPALLAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10283",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10284",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL V.PARANGANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10285",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SIRUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10286",
            "district": "37",
            "Block": "379",
            "label": "ELANGO AMS POMBOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10287",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL THIRUVAKKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10288",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SENGAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10289",
            "district": "37",
            "Block": "379",
            "label": "SRI ARAVINDAR VIDHYALAYA  MATRICULATION HIGH SCHOOL  AGASAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10290",
            "district": "37",
            "Block": "379",
            "label": "DMI ST. JOSEPH  MATRICULATION SCHOOL ACHARAMPATTU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10291",
            "district": "37",
            "Block": "379",
            "label": "UDAVI GENTILLESSE MATRIC HIGHER SECONDARY  SCHOOL,EDAYANCHAVADY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10292",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL BOMMIYARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10293",
            "district": "37",
            "Block": "379",
            "label": "AMS THIRUCHITRAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10294",
            "district": "37",
            "Block": "379",
            "label": "GANDHI AIDED HSS THIRUCHITRAMBALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10295",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL POOTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10296",
            "district": "37",
            "Block": "379",
            "label": "SWAMI VIVEKANANDHA MIDDLE SCHOOL, KADAPERIKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10297",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERAMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10298",
            "district": "37",
            "Block": "379",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PERIYAMUDALIYAR CHAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10299",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL   KOTTAKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10300",
            "district": "37",
            "Block": "379",
            "label": "VETHA HIGH SCHOOL CHINNA KOTTAKUPPAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10301",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL KEEZHKOOTHAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20962",
            "district": "37",
            "Block": "379",
            "label": "NEW CREATION BILINGUAL SCHOOL KUILAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21138",
            "district": "37",
            "Block": "379",
            "label": "NEW  ERA  SECONDARY SCHOOL  KUILAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21495",
            "district": "37",
            "Block": "379",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL POMBUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21786",
            "district": "37",
            "Block": "379",
            "label": "UNAIDED KUILAPALAYAM HIGHER SECONDARY SCHOOL KUILAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21787",
            "district": "37",
            "Block": "379",
            "label": "ST MARYS HIGH SCHOOL THIRUCHITRAMBALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21788",
            "district": "37",
            "Block": "379",
            "label": "RAGAVENDRA MIDDLE SCHOOL PERIYAMUDALIYAR CHAVADI WARD 1",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21789",
            "district": "37",
            "Block": "379",
            "label": "NIRVANA HIGH SCHOOL NADUKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22096",
            "district": "37",
            "Block": "379",
            "label": "SREE MOTHER VIDYASHRAM KILIYANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22874",
            "district": "37",
            "Block": "379",
            "label": "GOVERNMENT HIGH SCHOOL- PERAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23638",
            "district": "37",
            "Block": "379",
            "label": "KAVIKAMBAN MATRIC SCHOOL THIRUVAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23638",
            "district": "37",
            "Block": "379",
            "label": "KAVIKAMBAN MATRIC SCHOOL THIRUVAKKARAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24050",
            "district": "37",
            "Block": "379",
            "label": "AMS THIRUCHITRAMBALAM",
            "Sch_type": "Fully AidedMiddle School"
        },
        {
            "key": "24051",
            "district": "37",
            "Block": "379",
            "label": "KAVI KAMBAN HIGH SCHOOL THIRUVAKKARAI",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "380": [
        {
            "key": "2839",
            "district": "7",
            "Block": "380",
            "label": "SAKTHISAI MATRICULATION SCHOOL,THANGAMAPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13719",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KULATHOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13720",
            "district": "7",
            "Block": "380",
            "label": "PUMS,MALAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13721",
            "district": "7",
            "Block": "380",
            "label": "GHS,PAGANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13722",
            "district": "7",
            "Block": "380",
            "label": "GHSS THENNAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13723",
            "district": "7",
            "Block": "380",
            "label": "PUMS, ANDIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13724",
            "district": "7",
            "Block": "380",
            "label": "PUMS, P.KOSAVAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13725",
            "district": "7",
            "Block": "380",
            "label": "GHS,PADIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13726",
            "district": "7",
            "Block": "380",
            "label": "PUMS, VELLAPOMMANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13727",
            "district": "7",
            "Block": "380",
            "label": "GHS,VELVARKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13728",
            "district": "7",
            "Block": "380",
            "label": "PUMS, SINGARAKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13729",
            "district": "7",
            "Block": "380",
            "label": "GHS, K.PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13730",
            "district": "7",
            "Block": "380",
            "label": "PUMS VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13731",
            "district": "7",
            "Block": "380",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, VADAMADURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13732",
            "district": "7",
            "Block": "380",
            "label": "GBHSS VADAMADURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13733",
            "district": "7",
            "Block": "380",
            "label": "PUMS, V.CHITOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13734",
            "district": "7",
            "Block": "380",
            "label": "AHS, KALAIMAGAL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13735",
            "district": "7",
            "Block": "380",
            "label": "PUMS G.KURUMBAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13736",
            "district": "7",
            "Block": "380",
            "label": "PUMS, PILATHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13737",
            "district": "7",
            "Block": "380",
            "label": "PUMS,MAMMANIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13738",
            "district": "7",
            "Block": "380",
            "label": "GHS,KOMBERIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13739",
            "district": "7",
            "Block": "380",
            "label": "GHS,VALAVICHETTIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13740",
            "district": "7",
            "Block": "380",
            "label": "PUMS, SUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13741",
            "district": "7",
            "Block": "380",
            "label": "PUMS, SITHUVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13742",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KOLLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13743",
            "district": "7",
            "Block": "380",
            "label": "PUMS,PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13744",
            "district": "7",
            "Block": "380",
            "label": "GHSS,AYYALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13745",
            "district": "7",
            "Block": "380",
            "label": "PUMS,A.KOMBAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13746",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KUPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13747",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KINATHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13748",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KARUVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13749",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KAKKAYANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21603",
            "district": "7",
            "Block": "380",
            "label": "MOTHER THERASA ACADEMY MATRICULATION SCHOOL, MOREPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21743",
            "district": "7",
            "Block": "380",
            "label": "SHRI JAY RAJ VIDYA MANDIR INTERNATIONAL SCHOOL, VELVARKOTTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22181",
            "district": "7",
            "Block": "380",
            "label": "BIGDREAMERS ACADEMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22182",
            "district": "7",
            "Block": "380",
            "label": "AKSHARA SPECIAL SCHOOL FOR MR,VADAMADURAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22509",
            "district": "7",
            "Block": "380",
            "label": "GHS, THANGAMMAPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22939",
            "district": "7",
            "Block": "380",
            "label": "GHS, V.KURUNTHAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "24052",
            "district": "7",
            "Block": "380",
            "label": "PUMS, ANDIPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24053",
            "district": "7",
            "Block": "380",
            "label": "PUMS,KOLLAPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24054",
            "district": "7",
            "Block": "380",
            "label": "DINDIGUL DISTRICT GOVERNMENT MODEL SCHOOL, DINDIGUL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "381": [
        {
            "key": "2997",
            "district": "29",
            "Block": "381",
            "label": "DE BRITTO MS, THIRUKAVALUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4234",
            "district": "29",
            "Block": "381",
            "label": "SAHAYA ANNAI MATRICULATION SCHOOL, AZAD ROAD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5242",
            "district": "29",
            "Block": "381",
            "label": "R.C. MATRICULATION SCHOOL, VAIYAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14666",
            "district": "29",
            "Block": "381",
            "label": "PUMS, SUNDAKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14667",
            "district": "29",
            "Block": "381",
            "label": "GHS, KARUMAGOUNDANPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14668",
            "district": "29",
            "Block": "381",
            "label": "GHSS, ARASUNILAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14669",
            "district": "29",
            "Block": "381",
            "label": "GHSS, ELANGAKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14670",
            "district": "29",
            "Block": "381",
            "label": "GHSS, THAVALAIVEERANPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14671",
            "district": "29",
            "Block": "381",
            "label": "PUMS, ELAMANAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14672",
            "district": "29",
            "Block": "381",
            "label": "GHS, ENAMPUDHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14673",
            "district": "29",
            "Block": "381",
            "label": "GHS, PUDUVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14674",
            "district": "29",
            "Block": "381",
            "label": "PUMS, ANANKARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14675",
            "district": "29",
            "Block": "381",
            "label": "PUMS, K-VALAYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14676",
            "district": "29",
            "Block": "381",
            "label": "PUMS, THOPPANAYAKKANPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14677",
            "district": "29",
            "Block": "381",
            "label": "PUMS, KUMARAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14678",
            "district": "29",
            "Block": "381",
            "label": "GHS, SADAYAMPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14679",
            "district": "29",
            "Block": "381",
            "label": "PUMS, PAMPATTIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14680",
            "district": "29",
            "Block": "381",
            "label": "PUMS, PUDHUMANIYARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14681",
            "district": "29",
            "Block": "381",
            "label": "R.C HSS, PERIYA ANAIKARAIPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14682",
            "district": "29",
            "Block": "381",
            "label": "PUMS, KALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14683",
            "district": "29",
            "Block": "381",
            "label": "GHSS, NADUPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14684",
            "district": "29",
            "Block": "381",
            "label": "GHS, V.POOSARIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14685",
            "district": "29",
            "Block": "381",
            "label": "GHSS, ONTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14686",
            "district": "29",
            "Block": "381",
            "label": "GHSS, N.POOLAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14687",
            "district": "29",
            "Block": "381",
            "label": "PUMS, K.PUDUKKOTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14688",
            "district": "29",
            "Block": "381",
            "label": "LITTLE FLOWER M S, KARUNGULAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "14689",
            "district": "29",
            "Block": "381",
            "label": "GHSS, KARUNGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14690",
            "district": "29",
            "Block": "381",
            "label": "PUMS,VAIYAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14691",
            "district": "29",
            "Block": "381",
            "label": "ARIGNAR ANNA GHSS, VAIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14692",
            "district": "29",
            "Block": "381",
            "label": "PUMS, PANNAPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14693",
            "district": "29",
            "Block": "381",
            "label": "PUMS  V.CHETTIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14694",
            "district": "29",
            "Block": "381",
            "label": "PUMS, INAMREDDIYAPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21379",
            "district": "29",
            "Block": "381",
            "label": "GHS,ARASUNILAIPALAYAM(GIRLS)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "24055",
            "district": "29",
            "Block": "381",
            "label": "GHS, PUDUVADI",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "382": [
        {
            "key": "4915",
            "district": "35",
            "Block": "382",
            "label": "PUMS MANICKAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5081",
            "district": "35",
            "Block": "382",
            "label": "PUMS MANALOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5618",
            "district": "35",
            "Block": "382",
            "label": "PUMS MANAKKAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5922",
            "district": "35",
            "Block": "382",
            "label": "PUMS AVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6037",
            "district": "35",
            "Block": "382",
            "label": "PUMS UTHANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6128",
            "district": "35",
            "Block": "382",
            "label": "PUMS VADAKKUPATTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6181",
            "district": "35",
            "Block": "382",
            "label": "PUMS UTTHUKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6696",
            "district": "35",
            "Block": "382",
            "label": "PUMS,VEDAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16025",
            "district": "35",
            "Block": "382",
            "label": "PUMS,ARAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16026",
            "district": "35",
            "Block": "382",
            "label": "GHSS, ALANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16027",
            "district": "35",
            "Block": "382",
            "label": "GHSS - AVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16028",
            "district": "35",
            "Block": "382",
            "label": "GHS- CHANDRASEKARAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16029",
            "district": "35",
            "Block": "382",
            "label": "GHSS - GOVINDAKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16030",
            "district": "35",
            "Block": "382",
            "label": "UAMHSS,YOUTH WELFARE MATRIC HIGHER SECONDARY SCHOOL ,GOVINDAKUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16031",
            "district": "35",
            "Block": "382",
            "label": "GHSS - HARIDWARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16032",
            "district": "35",
            "Block": "382",
            "label": "PUMS KANDIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16033",
            "district": "35",
            "Block": "382",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16034",
            "district": "35",
            "Block": "382",
            "label": "UAHS,ST MARYS MATRIC ,KOTTAIYUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16035",
            "district": "35",
            "Block": "382",
            "label": "AMS VIDAIYALKARUPPUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "16036",
            "district": "35",
            "Block": "382",
            "label": "GHS - MUNIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16037",
            "district": "35",
            "Block": "382",
            "label": "PUMS NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16038",
            "district": "35",
            "Block": "382",
            "label": "PUMS NARTHANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16039",
            "district": "35",
            "Block": "382",
            "label": "PUMS PADAGACHERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16040",
            "district": "35",
            "Block": "382",
            "label": "PUMS PERUNGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16041",
            "district": "35",
            "Block": "382",
            "label": "PUMS  KETHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16042",
            "district": "35",
            "Block": "382",
            "label": "PUMS THENKARAI ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16043",
            "district": "35",
            "Block": "382",
            "label": "PUMS VILATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16044",
            "district": "35",
            "Block": "382",
            "label": "UAMHS, CARMEL MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16045",
            "district": "35",
            "Block": "382",
            "label": "GBHSS VALANGAIMAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16046",
            "district": "35",
            "Block": "382",
            "label": "GGHSS - VALANGAIMAN",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16047",
            "district": "35",
            "Block": "382",
            "label": "UAMHSS  SRI SANKARA  MATRICULATION HIGHER SECONDARY SCHOOL, VALANGAIMAN .",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21394",
            "district": "35",
            "Block": "382",
            "label": "GHS ENAMKILIUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21395",
            "district": "35",
            "Block": "382",
            "label": "GHS SITHANVALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21535",
            "district": "35",
            "Block": "382",
            "label": "AHS NIRMALA MANAKUNDU",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "21608",
            "district": "35",
            "Block": "382",
            "label": "UAMS CVS FREEDOM VIDHYALAYA MATRICULATION",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22884",
            "district": "35",
            "Block": "382",
            "label": "GHS Thenkuvalaveli",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23628",
            "district": "35",
            "Block": "382",
            "label": "UAHS MAHADEVA GURUJI VIDYASHRAM ALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23628",
            "district": "35",
            "Block": "382",
            "label": "UAHS MAHADEVA GURUJI VIDYASHRAM ALANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24056",
            "district": "35",
            "Block": "382",
            "label": "PUMS KANDIYUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24057",
            "district": "35",
            "Block": "382",
            "label": "PUMS KOTTAIYUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24058",
            "district": "35",
            "Block": "382",
            "label": "PUMS NALLUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "383": [
        {
            "key": "3393",
            "district": "22",
            "Block": "383",
            "label": "THIAGARAJAR MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3421",
            "district": "22",
            "Block": "383",
            "label": "HOLY CHILDREN MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3873",
            "district": "22",
            "Block": "383",
            "label": "ST. MICHAEL'S MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3884",
            "district": "22",
            "Block": "383",
            "label": "SRI THIAGARAJAR MEMORIAL MATRICULATION SCHOOL,BELUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4286",
            "district": "22",
            "Block": "383",
            "label": "VAIGAI MATRIC. HR.SEC SCHOOL VALAPPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4358",
            "district": "22",
            "Block": "383",
            "label": "SARASWATHI MATRIC HR. SEC. SCHOOL, VALAPADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5330",
            "district": "22",
            "Block": "383",
            "label": "VENUS VIDYALAYA MATRICULATION SCHOOL,VEPPILAIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11636",
            "district": "22",
            "Block": "383",
            "label": "PUMS, MALAIYALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11637",
            "district": "22",
            "Block": "383",
            "label": "GHSS, THIRUMANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11638",
            "district": "22",
            "Block": "383",
            "label": "GHS, VEPPILAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11639",
            "district": "22",
            "Block": "383",
            "label": "PUMS, PONNARAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11640",
            "district": "22",
            "Block": "383",
            "label": "PUMS, V.MANNARPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11641",
            "district": "22",
            "Block": "383",
            "label": "GHS, VILARIPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11642",
            "district": "22",
            "Block": "383",
            "label": "GHSS, SINGIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11643",
            "district": "22",
            "Block": "383",
            "label": "SREE GOKULAM MATRIC HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11644",
            "district": "22",
            "Block": "383",
            "label": "GGHSS, VALAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11645",
            "district": "22",
            "Block": "383",
            "label": "GOVT BOYS HSS, VALAPPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11646",
            "district": "22",
            "Block": "383",
            "label": "PUMS, KAVARKALPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11647",
            "district": "22",
            "Block": "383",
            "label": "GHS, SESANCHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11648",
            "district": "22",
            "Block": "383",
            "label": "PUMS, MUTHAMPATTY COLONY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11649",
            "district": "22",
            "Block": "383",
            "label": "GMS, MUTHAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11650",
            "district": "22",
            "Block": "383",
            "label": "PUMS, MANNAICKENPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11651",
            "district": "22",
            "Block": "383",
            "label": "GHS THUKKIYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11652",
            "district": "22",
            "Block": "383",
            "label": "GHS ATHANURPATTY",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11653",
            "district": "22",
            "Block": "383",
            "label": "GGHSS, BELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11654",
            "district": "22",
            "Block": "383",
            "label": "GBHSS, BELUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11655",
            "district": "22",
            "Block": "383",
            "label": "SRI SAKTHI VIKAAS MATRIC HR. SEC. SCHOOL,BELUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11656",
            "district": "22",
            "Block": "383",
            "label": "GHS, NEERMULLIKUTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11657",
            "district": "22",
            "Block": "383",
            "label": "GHS, C.N.PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11658",
            "district": "22",
            "Block": "383",
            "label": "PUMS, KURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11659",
            "district": "22",
            "Block": "383",
            "label": "PUMS, KONANCHETTIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11660",
            "district": "22",
            "Block": "383",
            "label": "PUMS, ARANGANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11661",
            "district": "22",
            "Block": "383",
            "label": "GTR HR. SEC. SCHOOL, VELLIGOUNDANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11662",
            "district": "22",
            "Block": "383",
            "label": "PUMS, CHANDRAPILLAIVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21499",
            "district": "22",
            "Block": "383",
            "label": "G.T.R. HIGH SCHOOL, PERIYAKUTTIMADUVU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21672",
            "district": "22",
            "Block": "383",
            "label": "HERITAGE VIDHYALAYA  SCHOOL, KAVARKALPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22118",
            "district": "22",
            "Block": "383",
            "label": "KALAIMAGAL VIDHYA MANDIR MATRIC HR. SEC. SCHOOL, SESANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "384": [
        {
            "key": "6350",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KURINJIPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6535",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL AVIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10098",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10099",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THALAVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10100",
            "district": "37",
            "Block": "384",
            "label": "SACRED HEART HIGHER SECONDARY SCHOOL ANILADY",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10101",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KEELMAMPATTU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10102",
            "district": "37",
            "Block": "384",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL SORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10103",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10104",
            "district": "37",
            "Block": "384",
            "label": "CARE COUNCIL HIGH SCHOOL , PANDIYANKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10105",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL MODAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10106",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAJAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10107",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  UDAIYANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10108",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL KALLAPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10109",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELKALAVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10110",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NEGANUR PUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10111",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL NEGANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10112",
            "district": "37",
            "Block": "384",
            "label": "SRI RAAM MATRIC HIGHER SECONDARY SCHOOL NANGILIKONDAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10113",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KADAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10114",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ILLODU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10115",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL PENNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10116",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARIYANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10117",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MELOLAKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10118",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL VEERANAMUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10119",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL EDANEMLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10120",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MELATHIPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10121",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ARUGAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10122",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PALLIKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10123",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL MELSITHAMUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10124",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL VALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10125",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL KONGARAPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10126",
            "district": "37",
            "Block": "384",
            "label": "GOVERNMENT HIGH SCHOOL MELSEVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10127",
            "district": "37",
            "Block": "384",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  THALAVALAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21306",
            "district": "37",
            "Block": "384",
            "label": "RAJADESING PUBLIC SCHOOL KALAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22761",
            "district": "37",
            "Block": "384",
            "label": "DENI MATRICULATION SCHOOL, MELKALAVAI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "385": [
        {
            "key": "822",
            "district": "30",
            "Block": "385",
            "label": "ST. Annes Metric Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1067",
            "district": "30",
            "Block": "385",
            "label": "KINGS MAT. HSS, PUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1086",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS PERPILANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1089",
            "district": "30",
            "Block": "385",
            "label": "RVR NURSERY & PRIMARY",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1130",
            "district": "30",
            "Block": "385",
            "label": "NEHRU NATIONAL MS VADAKANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1281",
            "district": "30",
            "Block": "385",
            "label": "ANNAI MATRIC. HR. SEC. SCHOOL , VALLIOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1899",
            "district": "30",
            "Block": "385",
            "label": "KEINS MATRIC HR SEC SCHOOL, VALLIOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2109",
            "district": "30",
            "Block": "385",
            "label": "Vivekananda kendra vidyalaya matriculation higher secondary school, Vallioor",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2139",
            "district": "30",
            "Block": "385",
            "label": "Pulliman Matric Higher Secondary School, Panagudi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2792",
            "district": "30",
            "Block": "385",
            "label": "ST PAUL RC MS KAVALKINARU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2795",
            "district": "30",
            "Block": "385",
            "label": "TDTA MIDDLE SCHOOL KALANTHAPANAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3734",
            "district": "30",
            "Block": "385",
            "label": "VADAKKU HINDU MIDDLE SCHOOL PANAGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3989",
            "district": "30",
            "Block": "385",
            "label": "LITTLE FLOWER NURSERY PS PANAGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4222",
            "district": "30",
            "Block": "385",
            "label": "Balakrishna Matriculation Higher Secondary School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4289",
            "district": "30",
            "Block": "385",
            "label": "St.XAVIER'S MS PERUMANAL",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4305",
            "district": "30",
            "Block": "385",
            "label": "THRESA MS PUSHPAVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4325",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS SAHAYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4364",
            "district": "30",
            "Block": "385",
            "label": "NALANDHA MATRIC HIGH SCHOOL, VALLIOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4370",
            "district": "30",
            "Block": "385",
            "label": "BHARATH MATRIC SCHOOL, THALAVAIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4513",
            "district": "30",
            "Block": "385",
            "label": "TDTA MIDDLE SCHOOL. THIRUMALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5028",
            "district": "30",
            "Block": "385",
            "label": "HOLYCROSS RC MS VALLIOOR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "5145",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS THALAVAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5479",
            "district": "30",
            "Block": "385",
            "label": "ST.JOSEPH'S MS KILAVANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6118",
            "district": "30",
            "Block": "385",
            "label": "SRM MIDDLE SCHOOL NAKKANERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6124",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS VALLIAMMAIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6311",
            "district": "30",
            "Block": "385",
            "label": "PUM SCHOOL ,VALLIYOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6328",
            "district": "30",
            "Block": "385",
            "label": "HINDU MIDDLE SCHOOL. THIRUMALAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6438",
            "district": "30",
            "Block": "385",
            "label": "HINDU MIDDLE SCHOOL CHETTIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19943",
            "district": "30",
            "Block": "385",
            "label": "ARULAIAH  MIDDLE SCHOOL KOTTAYADI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19944",
            "district": "30",
            "Block": "385",
            "label": "SA NOBLE MEMORIAL HS, VALLIYOOR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19945",
            "district": "30",
            "Block": "385",
            "label": "GOVT.HR.SEC.SCHOOL VALLIYOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19946",
            "district": "30",
            "Block": "385",
            "label": "MERCY MATRIC. HIGHER SECONDARY SCHOOL, VALLIYOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19947",
            "district": "30",
            "Block": "385",
            "label": "Fatima Girls Higher Secondary School",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19948",
            "district": "30",
            "Block": "385",
            "label": "CONCORDIA HSS VALLIOOR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19949",
            "district": "30",
            "Block": "385",
            "label": "HUSSEY MS PANAGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19950",
            "district": "30",
            "Block": "385",
            "label": "GOVERNMENT H S S  PANAGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19951",
            "district": "30",
            "Block": "385",
            "label": "SACRED HEART HIGHER SECONDARY SCHOOL PANAGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19952",
            "district": "30",
            "Block": "385",
            "label": "NIRMALA RC MIDDLE SCHOOL THERKU VALLIYOOR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19953",
            "district": "30",
            "Block": "385",
            "label": "GOVERNMENT HIGH SCHOOL ROSEMIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19954",
            "district": "30",
            "Block": "385",
            "label": "HOLYFAMILY MIDDLE SCHOOL VADAKANGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19955",
            "district": "30",
            "Block": "385",
            "label": "GHS KAVALKINARU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19956",
            "district": "30",
            "Block": "385",
            "label": "ADH HS PERIYANAYAKI PURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19957",
            "district": "30",
            "Block": "385",
            "label": "SACRED HEART HSS KAVALKINARU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19958",
            "district": "30",
            "Block": "385",
            "label": "ST THRESA HSS VADAKANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19959",
            "district": "30",
            "Block": "385",
            "label": "CONCORDIA HSS VADAKANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19960",
            "district": "30",
            "Block": "385",
            "label": "NEHRU NATIONAL HSS VADAKANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19961",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS ANNAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19962",
            "district": "30",
            "Block": "385",
            "label": "GHSS THULUKKARPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19963",
            "district": "30",
            "Block": "385",
            "label": "TDTA MS KANNANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19964",
            "district": "30",
            "Block": "385",
            "label": "TDTA MIDDLE SCHOOL ZIONMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19965",
            "district": "30",
            "Block": "385",
            "label": "SRI MUTHARAMMAN MIDDLE SCHOOL SIVASUBRAMANIYAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19966",
            "district": "30",
            "Block": "385",
            "label": "RC MS PATHINATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19967",
            "district": "30",
            "Block": "385",
            "label": "GHS KOLIANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19968",
            "district": "30",
            "Block": "385",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL SOUNDARALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19969",
            "district": "30",
            "Block": "385",
            "label": "HINDU HS OORALVAIMOZHY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19970",
            "district": "30",
            "Block": "385",
            "label": "HINDU ARULNERI MS CHITHAMPARAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "19971",
            "district": "30",
            "Block": "385",
            "label": "GHSS PAZHAVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19972",
            "district": "30",
            "Block": "385",
            "label": "ALAMERLU VIDYASHARMAM MATRI. HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19973",
            "district": "30",
            "Block": "385",
            "label": "BALIAH MARTHANDAM HR. SEC. SCHOOL., AVARAIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19974",
            "district": "30",
            "Block": "385",
            "label": "GHS MATHAGANERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19975",
            "district": "30",
            "Block": "385",
            "label": "TDTA HS JACOBPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19976",
            "district": "30",
            "Block": "385",
            "label": "HINDU MIDDLE SCHOOL SANGANAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19977",
            "district": "30",
            "Block": "385",
            "label": "G HIGH SCHOOL THERKKU KARUNGULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19978",
            "district": "30",
            "Block": "385",
            "label": "LM MIDDLE SCHOOL KANNANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19979",
            "district": "30",
            "Block": "385",
            "label": "LM MIDDLE SCHOOL LEVANGIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19980",
            "district": "30",
            "Block": "385",
            "label": "GOVT HIGH SCHOOL MADAMPILLAITHARMAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19981",
            "district": "30",
            "Block": "385",
            "label": "ST JOSEPH HSS KOOTTAPULI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19982",
            "district": "30",
            "Block": "385",
            "label": "ST ANNES HSS RAJAKRISHNAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19983",
            "district": "30",
            "Block": "385",
            "label": "HINDU HSS KANNANKULAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19984",
            "district": "30",
            "Block": "385",
            "label": "GHSS CHETTIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19985",
            "district": "30",
            "Block": "385",
            "label": "ATOMIC ENERGY HSS ANUVIJAYANAGAR",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19986",
            "district": "30",
            "Block": "385",
            "label": "KUTTALAM MEMORIAL MS IRUKKANTH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21040",
            "district": "30",
            "Block": "385",
            "label": "Sri Vidya Mandir Matriculation Girls High School, Vallioor",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21041",
            "district": "30",
            "Block": "385",
            "label": "BALIAH MARTHANDAM MATRICULATION HIGHER SECONDARY  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21121",
            "district": "30",
            "Block": "385",
            "label": "S.A.V BALAKRISHNA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21122",
            "district": "30",
            "Block": "385",
            "label": "KINGS  CBSE SCHOOL PUDHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21123",
            "district": "30",
            "Block": "385",
            "label": "JESUS SCHOOL FOR EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21827",
            "district": "30",
            "Block": "385",
            "label": "KEINS CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21828",
            "district": "30",
            "Block": "385",
            "label": "RMS INTERNATIONAL SCHOOL THANDIARKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22283",
            "district": "30",
            "Block": "385",
            "label": "JONATHAN MEMORIAL MATRICULATION SCHOOL KAMARAJ NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22657",
            "district": "30",
            "Block": "385",
            "label": "SACRED HEART PUBLIC SCHOOL PANAGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22658",
            "district": "30",
            "Block": "385",
            "label": "SARASWATHY VIDYALAYA CBSE THERKKU VALLIYOOR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22734",
            "district": "30",
            "Block": "385",
            "label": "VEDIC VIDYASHRAM CBSE SCHOOL THERKKU VALLIYOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23145",
            "district": "30",
            "Block": "385",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Valliyoor",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23162",
            "district": "30",
            "Block": "385",
            "label": "INDIAN MATRICULATON SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24059",
            "district": "30",
            "Block": "385",
            "label": "GOVERNMENT HR SEC SCHOOL CHETTIKULAM",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24060",
            "district": "30",
            "Block": "385",
            "label": "ST ANNS SCHOOL (ICSE)",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "386": [
        {
            "key": "2942",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT MIDDLE SCHOOL ,SHOLAYAR EST.",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2978",
            "district": "4",
            "Block": "386",
            "label": "AMS INJIPARAI LD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2986",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYAT UNION MIDDLE SCHOOLSIRUKUNDRA L D",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3037",
            "district": "4",
            "Block": "386",
            "label": "AIDED MIDDLE SCHOOL-KARAMALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3298",
            "district": "4",
            "Block": "386",
            "label": "BEULA MATRICULATION HR. SEC. SCHOOL, VALPARAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3532",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL URULIKKAL UD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3682",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL PANNIMADE I",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4443",
            "district": "4",
            "Block": "386",
            "label": "PUMS SELLALIPARAI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5053",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL NALLAMUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13051",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH  UNION MIDDLE SCHOOL VELLONIE LOWER DIVISION",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13052",
            "district": "4",
            "Block": "386",
            "label": "GTRMS VALPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13053",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL MURUGALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13054",
            "district": "4",
            "Block": "386",
            "label": "PANCHAYATH UNION MIDDLE SCHOOL PACHAMALAI NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13055",
            "district": "4",
            "Block": "386",
            "label": "AIDED MIDDLE SCHOOL  MUDIS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13056",
            "district": "4",
            "Block": "386",
            "label": "SACRED HEART MATRICULATION HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13057",
            "district": "4",
            "Block": "386",
            "label": "GGHSS VALPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13058",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGH SCHOOL ROTTIKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13059",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGH SCHOOL CINCHONA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13060",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SHOLAYAR DAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13061",
            "district": "4",
            "Block": "386",
            "label": "GHSS ATTAKATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13062",
            "district": "4",
            "Block": "386",
            "label": "GHS KADAMPARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13063",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, VALPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13064",
            "district": "4",
            "Block": "386",
            "label": "THUYA IRUDAYA GIRLS HR SEC SCHOOL, VALPARAI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13065",
            "district": "4",
            "Block": "386",
            "label": "Netaji Subhash Chandra Bose Avasiya Vidyalaya- Valparai",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "22487",
            "district": "4",
            "Block": "386",
            "label": "GOVERNMENT HIGH SCHOOL WATER FALLS EAST",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "387": [
        {
            "key": "3230",
            "district": "34",
            "Block": "387",
            "label": "PUMS ARASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3283",
            "district": "34",
            "Block": "387",
            "label": "SENDHAMIZH MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3515",
            "district": "34",
            "Block": "387",
            "label": "SRI BHARATHAMATHA MATRICULATION SCHOOL VANDAVASI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3714",
            "district": "34",
            "Block": "387",
            "label": "PUMS VAZHUR AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4109",
            "district": "34",
            "Block": "387",
            "label": "PUMS SENNAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4147",
            "district": "34",
            "Block": "387",
            "label": "PUMS-KILSATHAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4152",
            "district": "34",
            "Block": "387",
            "label": "PUMS KOVALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4166",
            "district": "34",
            "Block": "387",
            "label": "PUMS SEMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4216",
            "district": "34",
            "Block": "387",
            "label": "KINGSTON MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4409",
            "district": "34",
            "Block": "387",
            "label": "PUMS ARIYATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4459",
            "district": "34",
            "Block": "387",
            "label": "PUMS KODANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4602",
            "district": "34",
            "Block": "387",
            "label": "PUMS ULUNDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4645",
            "district": "34",
            "Block": "387",
            "label": "AGASTHIYA VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL VANDAVSI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5601",
            "district": "34",
            "Block": "387",
            "label": "PUMS - THENNANGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6295",
            "district": "34",
            "Block": "387",
            "label": "SRI VENKATESWARA MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6419",
            "district": "34",
            "Block": "387",
            "label": "PUMS AMMAYAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6425",
            "district": "34",
            "Block": "387",
            "label": "PUMS PUNNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9606",
            "district": "34",
            "Block": "387",
            "label": "GHS AMUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9607",
            "district": "34",
            "Block": "387",
            "label": "GHS ATHIPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9608",
            "district": "34",
            "Block": "387",
            "label": "GHS BADHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9609",
            "district": "34",
            "Block": "387",
            "label": "S.R.I INTERNATIONAL MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9610",
            "district": "34",
            "Block": "387",
            "label": "GHSS IRUMBEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9611",
            "district": "34",
            "Block": "387",
            "label": "GHSS KILKODUNGALORE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9612",
            "district": "34",
            "Block": "387",
            "label": "GHS(GIRLS) KILKODUNGALORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9613",
            "district": "34",
            "Block": "387",
            "label": "GHSS KILKOVALAIVEDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9614",
            "district": "34",
            "Block": "387",
            "label": "PUMS-KILPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9615",
            "district": "34",
            "Block": "387",
            "label": "PUMS KILSEMBEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9616",
            "district": "34",
            "Block": "387",
            "label": "PUMS KOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9617",
            "district": "34",
            "Block": "387",
            "label": "GHSS MARUDADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9618",
            "district": "34",
            "Block": "387",
            "label": "GHS NELLIYANKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9619",
            "district": "34",
            "Block": "387",
            "label": "GHS SALAVEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9620",
            "district": "34",
            "Block": "387",
            "label": "GHS SALUKKAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9621",
            "district": "34",
            "Block": "387",
            "label": "GHSS(BOYS)-VANDAVASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9622",
            "district": "34",
            "Block": "387",
            "label": "GHSS(GIRLS)-VANDAVASI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9623",
            "district": "34",
            "Block": "387",
            "label": "RCM HIGH SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "9624",
            "district": "34",
            "Block": "387",
            "label": "SANNATHI HSS VANDAVASI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9625",
            "district": "34",
            "Block": "387",
            "label": "SACRED HEART HSS(G)",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9626",
            "district": "34",
            "Block": "387",
            "label": "GHS VELIYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9627",
            "district": "34",
            "Block": "387",
            "label": "JUBILEE GLOBAL MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21450",
            "district": "34",
            "Block": "387",
            "label": "GHS-ELANGADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22084",
            "district": "34",
            "Block": "387",
            "label": "HASHINY INTERNATIONAL MATRIC HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23341",
            "district": "34",
            "Block": "387",
            "label": "SWAMY ABEDHANANDHA VIDHYASHRAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23624",
            "district": "34",
            "Block": "387",
            "label": "VIRUTCHAM VIDHYASHRAM INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23624",
            "district": "34",
            "Block": "387",
            "label": "VIRUTCHAM VIDHYASHRAM INTERNATIONAL PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24061",
            "district": "34",
            "Block": "387",
            "label": "AGASTHIYA VIDYALAYA",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "388": [
        {
            "key": "797",
            "district": "24",
            "Block": "388",
            "label": "S.M.M MS, SUBRAMANIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "989",
            "district": "24",
            "Block": "388",
            "label": "SDA MATRIC HR SEC SCHOOL,T.N.PUTHUKUDI,PULIANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1068",
            "district": "24",
            "Block": "388",
            "label": "TDTA MS, T.N. PUTHUGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1112",
            "district": "24",
            "Block": "388",
            "label": "PARAMANANTHA MS, PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "1679",
            "district": "24",
            "Block": "388",
            "label": "R.M.M.S VASUDEVANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "1696",
            "district": "24",
            "Block": "388",
            "label": "SWAMI RENUGAL MS, VISWANATHAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2063",
            "district": "24",
            "Block": "388",
            "label": "VIVEKA MAT.HSS, SIVAGIRI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2086",
            "district": "24",
            "Block": "388",
            "label": "NEW CRESCENT MATRIC HSS, PULIANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2481",
            "district": "24",
            "Block": "388",
            "label": "RAMALINGAM MS, PULIANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2878",
            "district": "24",
            "Block": "388",
            "label": "S.R.P MS, SIVAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3717",
            "district": "24",
            "Block": "388",
            "label": "PUJMS, VASUDEVANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3757",
            "district": "24",
            "Block": "388",
            "label": "GURUSAMY MS, RAMANATHAPURAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4150",
            "district": "24",
            "Block": "388",
            "label": "SENAITHALAIVAR MS, VASUDEVANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "4476",
            "district": "24",
            "Block": "388",
            "label": "MPL MS, THAIKKA ST, PULIANGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4561",
            "district": "24",
            "Block": "388",
            "label": "PACKIATHAI MS, CHINTHAMANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5033",
            "district": "24",
            "Block": "388",
            "label": "PUJMS, NAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5405",
            "district": "24",
            "Block": "388",
            "label": "RAJASINGH MS, THERKKU CHATRAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6064",
            "district": "24",
            "Block": "388",
            "label": "SM MS, THALAIVANKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6375",
            "district": "24",
            "Block": "388",
            "label": "ANDIYAPPA MS, PULIANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6516",
            "district": "24",
            "Block": "388",
            "label": "T.S.M MS, PULIANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19987",
            "district": "24",
            "Block": "388",
            "label": "ARUNODHAYA MS, VISWANATHAPERI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19988",
            "district": "24",
            "Block": "388",
            "label": "KAMMAWAR HINDU HIGH SCHOOL,VISWANATHAPERI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19989",
            "district": "24",
            "Block": "388",
            "label": "BALA VINAYAGAR HS, SIVAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19990",
            "district": "24",
            "Block": "388",
            "label": "STELLA MARYS HSS,SIVAGIRI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19991",
            "district": "24",
            "Block": "388",
            "label": "RC HS,DEVIPATTINAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19992",
            "district": "24",
            "Block": "388",
            "label": "PANCHAYAT UNION JAWAHAR MIDDLE SCHOOL , SIVAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19993",
            "district": "24",
            "Block": "388",
            "label": "COMMITTEE NADAR HS,SIVAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19994",
            "district": "24",
            "Block": "388",
            "label": "SIVAGIRI SENAITHALAIVAR HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19995",
            "district": "24",
            "Block": "388",
            "label": "GOVT MS, ARUGANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19996",
            "district": "24",
            "Block": "388",
            "label": "RC MS, DURAISAMIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19997",
            "district": "24",
            "Block": "388",
            "label": "GHSS,THENMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19998",
            "district": "24",
            "Block": "388",
            "label": "THIRU C P ATHITHANAR GIRLS HIGH SCHOOL RAYAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19999",
            "district": "24",
            "Block": "388",
            "label": "R.H.N.U.THIRU.SI.BA.SIVANTHI ATHITHANAR HSS RAYAGIRI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20000",
            "district": "24",
            "Block": "388",
            "label": "N.C HSS,RAMANATHAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20001",
            "district": "24",
            "Block": "388",
            "label": "MP GOVT HSS,NELKATTUM SEVAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20002",
            "district": "24",
            "Block": "388",
            "label": "DHARANI MATRICULATION HIGHER SECONDARY SCHOOL, DHARANINAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20003",
            "district": "24",
            "Block": "388",
            "label": "NADAR URAVINMURAI KAMARAJAR GIRLS HIGH SCHOOL VASUDEVANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20004",
            "district": "24",
            "Block": "388",
            "label": "MOHAIDEENIA HS,VASUDEVANALLUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20005",
            "district": "24",
            "Block": "388",
            "label": "GHSS,VASUDEVANALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20006",
            "district": "24",
            "Block": "388",
            "label": "PUMS, MALAIYADIKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20007",
            "district": "24",
            "Block": "388",
            "label": "RC MS, CHINTHAMANI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "20008",
            "district": "24",
            "Block": "388",
            "label": "H.N.U.M HS,CINTHAMANI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20009",
            "district": "24",
            "Block": "388",
            "label": "KANNA HSS(GIRLS),CINTHAMANI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20010",
            "district": "24",
            "Block": "388",
            "label": "SEENIAMMAL HS,CHINTHAMANI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "20011",
            "district": "24",
            "Block": "388",
            "label": "SRI KANNA MATRIC .HSS, CHINTHAMANI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20012",
            "district": "24",
            "Block": "388",
            "label": "MOHAIDEENANDAVAR HS,PULIANGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20013",
            "district": "24",
            "Block": "388",
            "label": "AVS HS,PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20014",
            "district": "24",
            "Block": "388",
            "label": "GOVT GIRLS HR SEC SCHOOL PULIANGUDI TENKASI DISTRICT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20015",
            "district": "24",
            "Block": "388",
            "label": "PULIANGUDI SENAITHALAIVAR HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20016",
            "district": "24",
            "Block": "388",
            "label": "RC HS,PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "20017",
            "district": "24",
            "Block": "388",
            "label": "GHSS(B),PULIANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20018",
            "district": "24",
            "Block": "388",
            "label": "QUAIDE MILLATH HSS PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20019",
            "district": "24",
            "Block": "388",
            "label": "H.N.U.C.HSS.T.N.PUTHUKUDI - PULIANGUDI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20020",
            "district": "24",
            "Block": "388",
            "label": "SUYAMBULINGAM HSS,PULIANGUDI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20021",
            "district": "24",
            "Block": "388",
            "label": "PK GHSS,MULLIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20022",
            "district": "24",
            "Block": "388",
            "label": "ST.MARYS MAT. SCHOOL, MULLIKULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20023",
            "district": "24",
            "Block": "388",
            "label": "CMML HS,VADAMALAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "21124",
            "district": "24",
            "Block": "388",
            "label": "KANNA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21343",
            "district": "24",
            "Block": "388",
            "label": "DHARANI INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21825",
            "district": "24",
            "Block": "388",
            "label": "ANNAI MEENAKSHI PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21826",
            "district": "24",
            "Block": "388",
            "label": "SVC SAI NIKETAN SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22659",
            "district": "24",
            "Block": "388",
            "label": "VEERAMARUDU SAHODARARHAL VIDYALAYA NELKATTUMSEVAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22660",
            "district": "24",
            "Block": "388",
            "label": "THANGAPALAM MATRICULATION HSS SUBRAMANIAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22802",
            "district": "24",
            "Block": "388",
            "label": "TMS WELLDONE MATRICULATION SCHOOL PULIANGUDI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22908",
            "district": "24",
            "Block": "388",
            "label": "GOVERNMENT HIGH SCHOOL, KOODALOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "24062",
            "district": "24",
            "Block": "388",
            "label": "Tenkasi District Government Model School",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "389": [
        {
            "key": "4790",
            "district": "16",
            "Block": "389",
            "label": "ELAKKUVANAR AMS,VOIMEDU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4905",
            "district": "16",
            "Block": "389",
            "label": "AMS-PUSHPAVANAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5019",
            "district": "16",
            "Block": "389",
            "label": "RV SHARMA AMS, THETHAKUDI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5043",
            "district": "16",
            "Block": "389",
            "label": "NAKKEERANAR AMS,PANCHANATHIKULAM WEST",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5096",
            "district": "16",
            "Block": "389",
            "label": "SRI. THAYUMANAVAR VIDYALAYA AMS VEDARANYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5108",
            "district": "16",
            "Block": "389",
            "label": "PUMS, PERIYAKUTHAKAI EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5115",
            "district": "16",
            "Block": "389",
            "label": "PUMS,PICHAKATTALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5150",
            "district": "16",
            "Block": "389",
            "label": "PUMS KURAVAPULAM WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5158",
            "district": "16",
            "Block": "389",
            "label": "PUMS, CHETTITHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5193",
            "district": "16",
            "Block": "389",
            "label": "PUMS SEMBAGARAYANALLUR NORTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5370",
            "district": "16",
            "Block": "389",
            "label": "AMS,KATHARIPULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5406",
            "district": "16",
            "Block": "389",
            "label": "PUMS,THENNADAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5430",
            "district": "16",
            "Block": "389",
            "label": "PUMS THANIKOTTAGAM SOUTH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5458",
            "district": "16",
            "Block": "389",
            "label": "PUMS,VOIMEDU WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5487",
            "district": "16",
            "Block": "389",
            "label": "PUMS,THOPPUTHURAI(MUSILIM)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5488",
            "district": "16",
            "Block": "389",
            "label": "PUMS,PERIYAKUTHAGAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5543",
            "district": "16",
            "Block": "389",
            "label": "PUMS,MOOLAKKARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5546",
            "district": "16",
            "Block": "389",
            "label": "PUMS,PATTINATHERU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5568",
            "district": "16",
            "Block": "389",
            "label": "THENI AMS,RAJAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5578",
            "district": "16",
            "Block": "389",
            "label": "PUMS THIRD STREET, VEDARANIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5648",
            "district": "16",
            "Block": "389",
            "label": "PUMS, POOSARIKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5785",
            "district": "16",
            "Block": "389",
            "label": "RAMAMADAM AMS-PERIYAKUTHAGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5799",
            "district": "16",
            "Block": "389",
            "label": "PUMS, VELLIKIDANKU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5881",
            "district": "16",
            "Block": "389",
            "label": "PUMS-THIYAGARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5888",
            "district": "16",
            "Block": "389",
            "label": "PUMS,NEIVILAKKU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5905",
            "district": "16",
            "Block": "389",
            "label": "ANANTHARASU AMS, MARAIGNAYANALLUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6003",
            "district": "16",
            "Block": "389",
            "label": "PUMS, AYAKKARANPULAM 2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6085",
            "district": "16",
            "Block": "389",
            "label": "SUBBAIYA AMS, PAYATHAVARANKADU",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6093",
            "district": "16",
            "Block": "389",
            "label": "PUMS, SINGANKUTTHAKAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6108",
            "district": "16",
            "Block": "389",
            "label": "PUMS CHETTIYAKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6119",
            "district": "16",
            "Block": "389",
            "label": "PUMS GOVIDANKADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6148",
            "district": "16",
            "Block": "389",
            "label": "SEETHALAKSHMI AMS, ARAKARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6238",
            "district": "16",
            "Block": "389",
            "label": "PUMS, KAILAVANAMPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6545",
            "district": "16",
            "Block": "389",
            "label": "GANESA AMS, CHETTIPULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15781",
            "district": "16",
            "Block": "389",
            "label": "GHSS PUSHPAVANAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15782",
            "district": "16",
            "Block": "389",
            "label": "GHSS-SEMBODAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15783",
            "district": "16",
            "Block": "389",
            "label": "SEETHALAKSHMI AIDED HIGH SCHOOL,KURAVAPULAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "15784",
            "district": "16",
            "Block": "389",
            "label": "POINT CALIMERE INTERNATIONAL SCHOOL, KURAVAPULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15785",
            "district": "16",
            "Block": "389",
            "label": "GHSS KADINALVAYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15786",
            "district": "16",
            "Block": "389",
            "label": "GHSS  KARUPPAMPULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15787",
            "district": "16",
            "Block": "389",
            "label": "SK GHSS THETHAKUDI SOUTH",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15788",
            "district": "16",
            "Block": "389",
            "label": "AMS, MUTHALIYAR THOPPU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15789",
            "district": "16",
            "Block": "389",
            "label": "ADW HS, VETTIYAKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15790",
            "district": "16",
            "Block": "389",
            "label": "SKS GHSS VEDARANYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15791",
            "district": "16",
            "Block": "389",
            "label": "G(GIRLS) HSS VEDARANYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15792",
            "district": "16",
            "Block": "389",
            "label": "ST ANTONY'S MATRICULATION HR SEC SCHOOL VEDARANYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15793",
            "district": "16",
            "Block": "389",
            "label": "SWAMI VIVEKANANDHA MATRICULATION SCHOOL ANDARKADU VEDARANYAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "15794",
            "district": "16",
            "Block": "389",
            "label": "GHSS THOPPUTHURAI -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15795",
            "district": "16",
            "Block": "389",
            "label": "QUAIDE MILLATH MATRICULATION HIGHER SECONDARY SCHOOL, THOPPUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15796",
            "district": "16",
            "Block": "389",
            "label": "GHS ARUKATTUTHURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15797",
            "district": "16",
            "Block": "389",
            "label": "AL-NOOR INDIAN MATRICULATION HIGHER SECONDARY SCHOOL, THETHAKUDI SOUTH THOPPUTHURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15798",
            "district": "16",
            "Block": "389",
            "label": "KGKG GIRLS AIDED HR. SEC. SCHOOL ,VEDARANYAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15799",
            "district": "16",
            "Block": "389",
            "label": "GHSS KODIYAKARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15800",
            "district": "16",
            "Block": "389",
            "label": "GHSS THAGATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15801",
            "district": "16",
            "Block": "389",
            "label": "GHSS THANIKOTTAGAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15802",
            "district": "16",
            "Block": "389",
            "label": "GHSS SARABOJIRAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15803",
            "district": "16",
            "Block": "389",
            "label": "GHS MARUTHUR SOUTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15804",
            "district": "16",
            "Block": "389",
            "label": "GHSS PANCHANATHIKULAM EAST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15805",
            "district": "16",
            "Block": "389",
            "label": "R.NATESANAR GOVT. HR.SEC.SCHOOL, AYAKARANPULAM 2",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15806",
            "district": "16",
            "Block": "389",
            "label": "GHS SIRUTHALAIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15807",
            "district": "16",
            "Block": "389",
            "label": "GHS KOVILKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15808",
            "district": "16",
            "Block": "389",
            "label": "MAHATMA GANDHI AMS MUTHALIYAR KUTHAGAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15809",
            "district": "16",
            "Block": "389",
            "label": "GOVT GIRLS HR SEC SCHOOL,AYAKKARANPULAM -3",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15810",
            "district": "16",
            "Block": "389",
            "label": "GHS AYAKKARANPULAM 3",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15811",
            "district": "16",
            "Block": "389",
            "label": "KANTHAVILAS AMS, VADAMAZHAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15812",
            "district": "16",
            "Block": "389",
            "label": "GHSS KARIYAPATTINAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15813",
            "district": "16",
            "Block": "389",
            "label": "GHS NAGAKKUDAIYAN",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15814",
            "district": "16",
            "Block": "389",
            "label": "GHSS KATHARIPULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15815",
            "district": "16",
            "Block": "389",
            "label": "AMS, KARUPPANKADU",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15816",
            "district": "16",
            "Block": "389",
            "label": "GHS MARUTHUR NORTH",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21284",
            "district": "16",
            "Block": "389",
            "label": "RVS BHARATH MATRICULATION HIGHER SECONDARY SCHOOL AYAKARANPULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21555",
            "district": "16",
            "Block": "389",
            "label": "VICTORY HIGHER SECONDARY SCHOOL PANCHANATHIKULAM WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21606",
            "district": "16",
            "Block": "389",
            "label": "SARADHAMBAL VIDYASHRAM MATRICULATION HIGHER SEC. SCHOOL THETHAKUDI SOUTH",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22563",
            "district": "16",
            "Block": "389",
            "label": "GOVERNMENT HIGH SCHOOL, ADHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22564",
            "district": "16",
            "Block": "389",
            "label": "GOVERNMENT HIGH SCHOOL, RAJANKATTALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22565",
            "district": "16",
            "Block": "389",
            "label": "GOVERNMENT HIGH SCHOOL PANNAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22698",
            "district": "16",
            "Block": "389",
            "label": "AGARAM MATRICULATION SCHOOL KARUPPAMPULAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22866",
            "district": "16",
            "Block": "389",
            "label": "GOVERNMENT HIGH SCHOOL PIRANTHIYANKARAI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "390": [
        {
            "key": "2831",
            "district": "7",
            "Block": "390",
            "label": "INFANT JESUS MATRICULATION SCHOOL,VEDASANDUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "13750",
            "district": "7",
            "Block": "390",
            "label": "PUMS,ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13751",
            "district": "7",
            "Block": "390",
            "label": "PUMS,PALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13752",
            "district": "7",
            "Block": "390",
            "label": "GOVT. HSS, ALAGAPURI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13753",
            "district": "7",
            "Block": "390",
            "label": "PUMS, RENGANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13754",
            "district": "7",
            "Block": "390",
            "label": "GOVT MODEL HR SEC SCHOOL, KASIPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13755",
            "district": "7",
            "Block": "390",
            "label": "NEHURU AIDED MS.KARATTUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13756",
            "district": "7",
            "Block": "390",
            "label": "GHS, KOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13757",
            "district": "7",
            "Block": "390",
            "label": "PUMS,MINUKKAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13758",
            "district": "7",
            "Block": "390",
            "label": "PUMS,NAGAKKONANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13759",
            "district": "7",
            "Block": "390",
            "label": "PUMS,VEDASANDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13760",
            "district": "7",
            "Block": "390",
            "label": "PUMS,NAGAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13761",
            "district": "7",
            "Block": "390",
            "label": "PUMS,SENANKOTTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13762",
            "district": "7",
            "Block": "390",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, VEDASANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13763",
            "district": "7",
            "Block": "390",
            "label": "GOVT BOYS HR SEC SCHOOL, VEDASANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13764",
            "district": "7",
            "Block": "390",
            "label": "BVM MATRICULATION, VEDASANDUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "13765",
            "district": "7",
            "Block": "390",
            "label": "GHS,VIRUTHALAIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13766",
            "district": "7",
            "Block": "390",
            "label": "PUMS,KOVAKKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13767",
            "district": "7",
            "Block": "390",
            "label": "GHSS,NAGAIYAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13768",
            "district": "7",
            "Block": "390",
            "label": "PUMS,VAIVESPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13769",
            "district": "7",
            "Block": "390",
            "label": "PUMS,KOVILUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13770",
            "district": "7",
            "Block": "390",
            "label": "GHSS,K.RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13771",
            "district": "7",
            "Block": "390",
            "label": "GHS,USILAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13772",
            "district": "7",
            "Block": "390",
            "label": "GHS,CHELLAKUTTIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13773",
            "district": "7",
            "Block": "390",
            "label": "GHS,NALLAMANARKOTTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13774",
            "district": "7",
            "Block": "390",
            "label": "GHS,DEVANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13775",
            "district": "7",
            "Block": "390",
            "label": "PUMS,POOTHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13776",
            "district": "7",
            "Block": "390",
            "label": "ST.FRANCIS XAVIER'S R.C. MIDDLE SCHOOL, POOTHAMPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "13777",
            "district": "7",
            "Block": "390",
            "label": "GHS,MARAMBADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "13778",
            "district": "7",
            "Block": "390",
            "label": "LITTLE FLOWER GIRL'S HSS, MARAMBADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "13779",
            "district": "7",
            "Block": "390",
            "label": "PUMS,THOTTANAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13780",
            "district": "7",
            "Block": "390",
            "label": "PUMS,ERIYODU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "13781",
            "district": "7",
            "Block": "390",
            "label": "GHSS, ERIYODU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21029",
            "district": "7",
            "Block": "390",
            "label": "BEDFORD ACADEMY MATRIC HIGHER SECONDARY SCHOOL, MINUKKAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21604",
            "district": "7",
            "Block": "390",
            "label": "VIDHYODHAYAA MATRICULATION SCHOOL, THOPPUPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21742",
            "district": "7",
            "Block": "390",
            "label": "SHRI MAHA VIDHYALAYA HS, THANEERPANDAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23070",
            "district": "7",
            "Block": "390",
            "label": "AKSHARA PUBLIC SCHOOL, VELLANAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23480",
            "district": "7",
            "Block": "390",
            "label": "SREE SARASWATHY ACADEMY MATRICULATION SCHOOL, SREERAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23480",
            "district": "7",
            "Block": "390",
            "label": "SREE SARASWATHY ACADEMY MATRICULATION SCHOOL, SREERAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24063",
            "district": "7",
            "Block": "390",
            "label": "PUMS,PALAPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24064",
            "district": "7",
            "Block": "390",
            "label": "PUMS,KOVILUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "391": [
        {
            "key": "2653",
            "district": "22",
            "Block": "391",
            "label": "SRI VIDYA MANDIR CBSE SCHOOL, SALEM STEEL PLANT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2724",
            "district": "22",
            "Block": "391",
            "label": "SRI VIDYA MANDIR MATRIC HR. SEC. SCHOOL, ELAMPILLAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4247",
            "district": "22",
            "Block": "391",
            "label": "VIVEKANANDA BALA MANDIR MATRIC SCHOOL, ATTAYAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6080",
            "district": "22",
            "Block": "391",
            "label": "SRI VIDYA MANDIR HR. SEC. SCHOOL (MATRIC), STEEL PLANT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11207",
            "district": "22",
            "Block": "391",
            "label": "PUMS M.G.R. NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11208",
            "district": "22",
            "Block": "391",
            "label": "PUMS CHINNAPOOSALIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23393",
            "district": "22",
            "Block": "391",
            "label": "THE OXFORD MATRIC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "11209",
            "district": "22",
            "Block": "391",
            "label": "PUMS ELAGUVAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11210",
            "district": "22",
            "Block": "391",
            "label": "GHSS MURUNGAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11211",
            "district": "22",
            "Block": "391",
            "label": "PUMS NAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11212",
            "district": "22",
            "Block": "391",
            "label": "SHALOM CONVENT MATRIC SCHOOL, PUDHU ROAD,PERUMAMPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11213",
            "district": "22",
            "Block": "391",
            "label": "PUMS KOTHANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11214",
            "district": "22",
            "Block": "391",
            "label": "PUMS S.K. VALAVU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11215",
            "district": "22",
            "Block": "391",
            "label": "GHS, PERIYAPUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11216",
            "district": "22",
            "Block": "391",
            "label": "GHS, ATTAVANAIPOOLAVARI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11217",
            "district": "22",
            "Block": "391",
            "label": "PUMS UTHAMACHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11218",
            "district": "22",
            "Block": "391",
            "label": "V.M. ANNAPOORANA MATRIC SCHOOL, UTHAMASOLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11219",
            "district": "22",
            "Block": "391",
            "label": "GHS PERUMAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11220",
            "district": "22",
            "Block": "391",
            "label": "GGHSS,  ELAMPILLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11221",
            "district": "22",
            "Block": "391",
            "label": "GBHSS, ELAMPILLAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11222",
            "district": "22",
            "Block": "391",
            "label": "PUMS REDDIMANIYAKARANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11223",
            "district": "22",
            "Block": "391",
            "label": "PUMS K.PUDHUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11224",
            "district": "22",
            "Block": "391",
            "label": "GHSS, VEMBADITHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11225",
            "district": "22",
            "Block": "391",
            "label": "K.G.B.V. ELAMPILLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11226",
            "district": "22",
            "Block": "391",
            "label": "PUMS RAKKIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11227",
            "district": "22",
            "Block": "391",
            "label": "VIDHYAA VAASHINI HR. SEC. SCHOOL, RAJAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11228",
            "district": "22",
            "Block": "391",
            "label": "PUMS THANAKUTTYPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11229",
            "district": "22",
            "Block": "391",
            "label": "GBHSS ATTAYAMPATTY",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11230",
            "district": "22",
            "Block": "391",
            "label": "M.N.S. GOVT. GIRLS HR. SEC. SCHOOL, ATTAYAMPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11231",
            "district": "22",
            "Block": "391",
            "label": "SRI VIDHYAA MANDIR HR. SEC. SCHOOL, PETHAMPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11232",
            "district": "22",
            "Block": "391",
            "label": "GHS, CHINNASEERAGAPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11233",
            "district": "22",
            "Block": "391",
            "label": "PUMS PERIYASEERAGAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11234",
            "district": "22",
            "Block": "391",
            "label": "PUMS VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11235",
            "district": "22",
            "Block": "391",
            "label": "GHSS VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11236",
            "district": "22",
            "Block": "391",
            "label": "PUMS PALAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11237",
            "district": "22",
            "Block": "391",
            "label": "PUMS ERUSANAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21209",
            "district": "22",
            "Block": "391",
            "label": "MODEL SCHOOL, VEERAPANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21459",
            "district": "22",
            "Block": "391",
            "label": "GHS,KEERAIPAPPAMPADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21645",
            "district": "22",
            "Block": "391",
            "label": "JEEVA PUBLIC SCHOOL, SEVAMPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22855",
            "district": "22",
            "Block": "391",
            "label": "MAHARISHI VIDYA MANDIR (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        }
    ],
    "392": [
        {
            "key": "767",
            "district": "32",
            "Block": "392",
            "label": "BHARAT MATRICULATION SCHOOL,OLAPPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "907",
            "district": "32",
            "Block": "392",
            "label": "JAYAM VIDHYA BHAVAN MATRIC HR.SEC.SCHOOL,VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1066",
            "district": "32",
            "Block": "392",
            "label": "PUNITHA AMALA ANNAI MHSS,VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1170",
            "district": "32",
            "Block": "392",
            "label": "AANOOR VIDYALAYA MATRIC H.S.S, MUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1636",
            "district": "32",
            "Block": "392",
            "label": "KURINCHI  MATRIC SCHOOL, VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2494",
            "district": "32",
            "Block": "392",
            "label": "KONGU VELLALAR MHSS, VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3033",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S, VELLAKOVIL-EAST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3527",
            "district": "32",
            "Block": "392",
            "label": "SRI KAMADENU MATRIC  SCHOOL, UTHAMAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3947",
            "district": "32",
            "Block": "392",
            "label": "BALA MATRIC HR.SEC SCHOOL , VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4697",
            "district": "32",
            "Block": "392",
            "label": "PUMS THENNANKARAIPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5128",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,CHINNAMUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5440",
            "district": "32",
            "Block": "392",
            "label": "GNANASAMBANDAR MHSS, PUDHUPPAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5943",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,RASATHAVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6084",
            "district": "32",
            "Block": "392",
            "label": "A.N.V. VIDHYALAYA  MATRIC  SCHOOL,VELLAKOVIL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6446",
            "district": "32",
            "Block": "392",
            "label": "PUMS SUBRAMANIA GOUNDANVALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6659",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,THEETHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12367",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S, LKC NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12368",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,VELLAKOVIL-WEST",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12369",
            "district": "32",
            "Block": "392",
            "label": "A.A. GOVT.HR.SEC. SCHOOL,  VELLAKOVIL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12370",
            "district": "32",
            "Block": "392",
            "label": "PUNITHA  AMALA ANNAI  GIRLS HIGH SCHOOL (Aided)",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "12371",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,SORIYANKINATHU PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23387",
            "district": "32",
            "Block": "392",
            "label": "K.K.P. MATRIC HR SEC SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12372",
            "district": "32",
            "Block": "392",
            "label": "G.H.S, DURAI RAMASAMY NAGAR, VELLAKOVIL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12373",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,V.GANAPATHI PALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12374",
            "district": "32",
            "Block": "392",
            "label": "VIVEKANANDA VIDYALAYA MATRIC  HR.SEC.SCH, MUTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12375",
            "district": "32",
            "Block": "392",
            "label": "GHSS, MUTHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12376",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S, MUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12377",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,OODAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12378",
            "district": "32",
            "Block": "392",
            "label": "GOVT.HIGH.SCHOOL. OLAPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12379",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S, VEERACHOLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12380",
            "district": "32",
            "Block": "392",
            "label": "GHS LAKKAMANAICKENPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12381",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,KAMBALIYAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12382",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,M.VELAYUTHAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12383",
            "district": "32",
            "Block": "392",
            "label": "GOVT.HIGH.SCHOOL, MANGALAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "12384",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,NADUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "12385",
            "district": "32",
            "Block": "392",
            "label": "GHSS, PUDHUPPAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12386",
            "district": "32",
            "Block": "392",
            "label": "GHSS, UTHAMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "12387",
            "district": "32",
            "Block": "392",
            "label": "P.U.M.S,VELAPPANAYAKAN VALASU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20915",
            "district": "32",
            "Block": "392",
            "label": "NAVA INTERNATIONAL SCHOOL(CBSE), MUTHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21077",
            "district": "32",
            "Block": "392",
            "label": "SATHYAM INTERNATIONAL SCHOOL(CBSE), S G VALASU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21979",
            "district": "32",
            "Block": "392",
            "label": "SRI AANOOR VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, VAIKALMETTUPUDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23600",
            "district": "32",
            "Block": "392",
            "label": "Saraswathi Vidhya Mandhir Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23600",
            "district": "32",
            "Block": "392",
            "label": "Saraswathi Vidhya Mandhir Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "393": [
        {
            "key": "738",
            "district": "36",
            "Block": "393",
            "label": "V V N K M ENG (CBSE) SCHOOL, ALLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1376",
            "district": "36",
            "Block": "393",
            "label": "HOLY CROSS MATRIC HSS, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1568",
            "district": "36",
            "Block": "393",
            "label": "SNEHA DEEPAM MATRIC HSS, ALAMELUMANGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1829",
            "district": "36",
            "Block": "393",
            "label": "ETHIRAJ MATRIC HSS, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1905",
            "district": "36",
            "Block": "393",
            "label": "SHANTHINIKETHAN MATRIC HSS, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2176",
            "district": "36",
            "Block": "393",
            "label": "OM SAKTHI NARAYANI MATRIC HSS, SRIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3158",
            "district": "36",
            "Block": "393",
            "label": "PUMS ANPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3259",
            "district": "36",
            "Block": "393",
            "label": "KOONA PRESIDENCY MATRIC HSS, KONAVATTAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4045",
            "district": "36",
            "Block": "393",
            "label": "SARASWATHI VIDYALAYA MATRIC SCHOOL, ALAMELUMANGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4265",
            "district": "36",
            "Block": "393",
            "label": "DESIA MATRIC SCHOOL, SASTHRI NAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4815",
            "district": "36",
            "Block": "393",
            "label": "HOLY CROSS HSS FOR THE HEARING IMPAIRED -SATHUVACHARI,VELLORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4830",
            "district": "36",
            "Block": "393",
            "label": "SPARK MATRIC HSS, THIRUMALAIKODI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5082",
            "district": "36",
            "Block": "393",
            "label": "PUMS ATHIYURVELI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5087",
            "district": "36",
            "Block": "393",
            "label": "PUMS ABDULLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5823",
            "district": "36",
            "Block": "393",
            "label": "PUMS SADUPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6644",
            "district": "36",
            "Block": "393",
            "label": "VELLORE MATRIC SCHOOL, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8630",
            "district": "36",
            "Block": "393",
            "label": "PUMS PULIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8631",
            "district": "36",
            "Block": "393",
            "label": "PUMS GURUMALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8632",
            "district": "36",
            "Block": "393",
            "label": "PUMS USSOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8633",
            "district": "36",
            "Block": "393",
            "label": "GOVT. GIRLS HR. SEC. SCHOOL, USSOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8634",
            "district": "36",
            "Block": "393",
            "label": "GOVT. BOYS HR. SEC. SCHOOL, USSOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8635",
            "district": "36",
            "Block": "393",
            "label": "ADHITHYA VIDYASHRAM MATRIC SCHOOL, USSOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8636",
            "district": "36",
            "Block": "393",
            "label": "GOVT HS GOVINDA REDDY PALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8637",
            "district": "36",
            "Block": "393",
            "label": "GHS MELMANAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8638",
            "district": "36",
            "Block": "393",
            "label": "ADWMS KARUKAMBATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8639",
            "district": "36",
            "Block": "393",
            "label": "PUMS SHENBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8640",
            "district": "36",
            "Block": "393",
            "label": "GADWMS SHENBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8641",
            "district": "36",
            "Block": "393",
            "label": "JANASAKTHI AMS",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "8642",
            "district": "36",
            "Block": "393",
            "label": "GHSS KONAVATTAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8643",
            "district": "36",
            "Block": "393",
            "label": "GHS SEMBEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8644",
            "district": "36",
            "Block": "393",
            "label": "GOVT HS ARIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8645",
            "district": "36",
            "Block": "393",
            "label": "HOLY QUEEN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8646",
            "district": "36",
            "Block": "393",
            "label": "PUMS THORAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8647",
            "district": "36",
            "Block": "393",
            "label": "Govt Hr. Sec.School, Thorapadi",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8648",
            "district": "36",
            "Block": "393",
            "label": "PUMS SAINATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8649",
            "district": "36",
            "Block": "393",
            "label": "KRISHNASAMY HSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8650",
            "district": "36",
            "Block": "393",
            "label": "SREE JAYAM SCHOOL (ICSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8651",
            "district": "36",
            "Block": "393",
            "label": "PUMS SATHUVACHARI(E)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8652",
            "district": "36",
            "Block": "393",
            "label": "PUMS VOC NAGAR, SATHUVACHARI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8653",
            "district": "36",
            "Block": "393",
            "label": "VALLALAR AMS SATHUVACHARI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8654",
            "district": "36",
            "Block": "393",
            "label": "GOVT HSS SATHUVACHARI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8655",
            "district": "36",
            "Block": "393",
            "label": "ST.PAUL'S MATRIC HSS, SATHUVACHARI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8656",
            "district": "36",
            "Block": "393",
            "label": "SIDDHARTHA SENIOR SECONDARY SCHOOL,VELLORE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "8657",
            "district": "36",
            "Block": "393",
            "label": "SACRED HEART HIGH SCHOOL RANGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8658",
            "district": "36",
            "Block": "393",
            "label": "GADWHSS PERUMUGAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8659",
            "district": "36",
            "Block": "393",
            "label": "G ADW HSS, Alamelumangapuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21431",
            "district": "36",
            "Block": "393",
            "label": "GHS,  PALAMATHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21681",
            "district": "36",
            "Block": "393",
            "label": "SRI NARAYANI VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21682",
            "district": "36",
            "Block": "393",
            "label": "SPARK CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22385",
            "district": "36",
            "Block": "393",
            "label": "VELAMMAL BODHI CAMPUS ( CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23634",
            "district": "36",
            "Block": "393",
            "label": "VELLORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24065",
            "district": "36",
            "Block": "393",
            "label": "PUMS ABDULLAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24066",
            "district": "36",
            "Block": "393",
            "label": "VELLORE DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "394": [
        {
            "key": "628",
            "district": "36",
            "Block": "394",
            "label": "LAKSHMI GARDEN MHSS, VELLORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "939",
            "district": "36",
            "Block": "394",
            "label": "GGHSS EVRN MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4729",
            "district": "36",
            "Block": "394",
            "label": "SAVIO MATRICULATION  SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "4764",
            "district": "36",
            "Block": "394",
            "label": "AMS R.C.M UTHARIAMADHA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4867",
            "district": "36",
            "Block": "394",
            "label": "AUMS URDU GIRLS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5089",
            "district": "36",
            "Block": "394",
            "label": "MMS EDA  STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5465",
            "district": "36",
            "Block": "394",
            "label": "AMS SARASWATHI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5474",
            "district": "36",
            "Block": "394",
            "label": "BHARAT MATRIC HSS, KOSAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6006",
            "district": "36",
            "Block": "394",
            "label": "RAMALINGAM MATRIC SCHOOL, SAIDAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6102",
            "district": "36",
            "Block": "394",
            "label": "MMS KAGITHAPATTARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6152",
            "district": "36",
            "Block": "394",
            "label": "SRI VASAVI MATRIC SCHOOL, VELLORE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8660",
            "district": "36",
            "Block": "394",
            "label": "MMS BOOSHNAM NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8661",
            "district": "36",
            "Block": "394",
            "label": "MMS ETTIAMMAN KOIL STREET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8662",
            "district": "36",
            "Block": "394",
            "label": "MMS MAIN BAZAAR HINDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8663",
            "district": "36",
            "Block": "394",
            "label": "MMS KALASPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8664",
            "district": "36",
            "Block": "394",
            "label": "MMS AMBEDKAR NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8665",
            "district": "36",
            "Block": "394",
            "label": "MHS KOSAPET MARKET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8666",
            "district": "36",
            "Block": "394",
            "label": "MHS S.K.M KASPA",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8667",
            "district": "36",
            "Block": "394",
            "label": "VKVM GGHSS, VELAPADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8668",
            "district": "36",
            "Block": "394",
            "label": "MHS SALAVANPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8669",
            "district": "36",
            "Block": "394",
            "label": "GOVT MUNICIPAL GIRLS HSS, THOTTAPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8670",
            "district": "36",
            "Block": "394",
            "label": "GOVT MUNICIPAL HSS KAKM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8671",
            "district": "36",
            "Block": "394",
            "label": "AMS CIRCAR MUNDY",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8672",
            "district": "36",
            "Block": "394",
            "label": "AMS SARVAJANA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8673",
            "district": "36",
            "Block": "394",
            "label": "AMS SRI RAMANUJAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8674",
            "district": "36",
            "Block": "394",
            "label": "AMS SANKARANPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8675",
            "district": "36",
            "Block": "394",
            "label": "AMS RAMALINGAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8676",
            "district": "36",
            "Block": "394",
            "label": "AMS SRI RAMAKRISHNA",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8677",
            "district": "36",
            "Block": "394",
            "label": "AHS DON BOSCO",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8678",
            "district": "36",
            "Block": "394",
            "label": "AHS REV. PONNURANGAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8679",
            "district": "36",
            "Block": "394",
            "label": "AHS HONNEGAR ASHRAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "8680",
            "district": "36",
            "Block": "394",
            "label": "VOORHEES AHSS",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8681",
            "district": "36",
            "Block": "394",
            "label": "AHSS SRI VENKATESHWARA",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8682",
            "district": "36",
            "Block": "394",
            "label": "JOSEPH MATRIC SCHOOL, VASANTHAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8683",
            "district": "36",
            "Block": "394",
            "label": "GOVT MUSLIM HSS",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8684",
            "district": "36",
            "Block": "394",
            "label": "AHSS ST. MARY'S",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8685",
            "district": "36",
            "Block": "394",
            "label": "VIDHYA MANDIR MATRIC HSS, VELLORE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20950",
            "district": "36",
            "Block": "394",
            "label": "IQRA MATRIC SCHOOL, R.N PALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21931",
            "district": "36",
            "Block": "394",
            "label": "LUTHERAN SCHOOL HOME FOR THE DEAF & BLIND",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21932",
            "district": "36",
            "Block": "394",
            "label": "GUILD OF SERVICE SCHOOL FOR THE BLIND",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "22386",
            "district": "36",
            "Block": "394",
            "label": "GOVT MPL MUSLIM HIGH SCHOOL, R.N.PALAYAM. VELLORE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23043",
            "district": "36",
            "Block": "394",
            "label": "THE TAKSHILAH GLOBAL SCHOOL VELLORE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "395": [
        {
            "key": "2343",
            "district": "34",
            "Block": "395",
            "label": "PUMS IRUMARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2441",
            "district": "34",
            "Block": "395",
            "label": "PUMS THIRUPPANAMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2587",
            "district": "34",
            "Block": "395",
            "label": "PUMS AZHINJALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3090",
            "district": "34",
            "Block": "395",
            "label": "PUMS SOZHAVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3097",
            "district": "34",
            "Block": "395",
            "label": "PUMS PILLANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3576",
            "district": "34",
            "Block": "395",
            "label": "PUMS ABDULLAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3595",
            "district": "34",
            "Block": "395",
            "label": "PUMS UKKAMPERUMPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3623",
            "district": "34",
            "Block": "395",
            "label": "PUMS BOKKASAMUTHIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3657",
            "district": "34",
            "Block": "395",
            "label": "PUMS PULIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3844",
            "district": "34",
            "Block": "395",
            "label": "PUMS SUMANGALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4068",
            "district": "34",
            "Block": "395",
            "label": "PUMS PUNNAI PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4160",
            "district": "34",
            "Block": "395",
            "label": "PUMS PERUMANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5753",
            "district": "34",
            "Block": "395",
            "label": "VIDYA VIKAS MATRIC HR SEC SCHOOL MORANAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6355",
            "district": "34",
            "Block": "395",
            "label": "PUMS THANDAPPANTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6608",
            "district": "34",
            "Block": "395",
            "label": "PUMS KALPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6618",
            "district": "34",
            "Block": "395",
            "label": "SANGFORD SENIOR SECONDARY SCHOOL ABDULLAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9531",
            "district": "34",
            "Block": "395",
            "label": "ADWHS ARASANKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9532",
            "district": "34",
            "Block": "395",
            "label": "GHSS AZHIVIDAITHANGI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9533",
            "district": "34",
            "Block": "395",
            "label": "GHS BUDERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9534",
            "district": "34",
            "Block": "395",
            "label": "GHSS BRAMMADESAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9535",
            "district": "34",
            "Block": "395",
            "label": "GHSS CHITHATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9536",
            "district": "34",
            "Block": "395",
            "label": "GHS DUSI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9537",
            "district": "34",
            "Block": "395",
            "label": "GHSS MAMANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9538",
            "district": "34",
            "Block": "395",
            "label": "PUMS MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9539",
            "district": "34",
            "Block": "395",
            "label": "GHSS MENALLUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9540",
            "district": "34",
            "Block": "395",
            "label": "GHS NAMANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9541",
            "district": "34",
            "Block": "395",
            "label": "GHS NATTERI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9542",
            "district": "34",
            "Block": "395",
            "label": "GHS PALLAVARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9543",
            "district": "34",
            "Block": "395",
            "label": "GGHSS PERUNGATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9544",
            "district": "34",
            "Block": "395",
            "label": "GHSS(BOYS) PERUNGATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9545",
            "district": "34",
            "Block": "395",
            "label": "GHSS PUNNAI PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9546",
            "district": "34",
            "Block": "395",
            "label": "GHSS RANDAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9547",
            "district": "34",
            "Block": "395",
            "label": "GHS SATTUVANTHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9548",
            "district": "34",
            "Block": "395",
            "label": "GHS THENNAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9549",
            "district": "34",
            "Block": "395",
            "label": "ADWMS VADAILLUPPAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "9550",
            "district": "34",
            "Block": "395",
            "label": "GHSS VADAILLUPPAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9551",
            "district": "34",
            "Block": "395",
            "label": "GHSS VADAMANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9552",
            "district": "34",
            "Block": "395",
            "label": "GBHSS-VEMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9553",
            "district": "34",
            "Block": "395",
            "label": "GHSS(GIRLS) VEMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9554",
            "district": "34",
            "Block": "395",
            "label": "GHS VENGALATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9555",
            "district": "34",
            "Block": "395",
            "label": "PUMS THIRUPPANANGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20956",
            "district": "34",
            "Block": "395",
            "label": "VETRI SIGARAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21955",
            "district": "34",
            "Block": "395",
            "label": "BON SECOURS SPECIAL SCHOOL FOR SPEECH & HEARING IMPAIRED",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22411",
            "district": "34",
            "Block": "395",
            "label": "GHS KUTHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22782",
            "district": "34",
            "Block": "395",
            "label": "Chitra Krishnasamy Vidyalaya CBSE,Vembakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22812",
            "district": "34",
            "Block": "395",
            "label": "Naalantha public school (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22921",
            "district": "34",
            "Block": "395",
            "label": "GHS MORANAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "24067",
            "district": "34",
            "Block": "395",
            "label": "PUMS MATHUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "396": [
        {
            "key": "118",
            "district": "38",
            "Block": "396",
            "label": "ST.ANTONY'S HIGH SCHOOL, MELANMARAINADU",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "119",
            "district": "38",
            "Block": "396",
            "label": "BHARAT MIDDILE SCHOOL APPAYANAICKERPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "120",
            "district": "38",
            "Block": "396",
            "label": "GOVERNMENT HIGH SCHOOL, K.CHELLAIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "121",
            "district": "38",
            "Block": "396",
            "label": "GHS K.MADATHUPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "122",
            "district": "38",
            "Block": "396",
            "label": "GHS MAMSAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "123",
            "district": "38",
            "Block": "396",
            "label": "GHS,PANAYADIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "124",
            "district": "38",
            "Block": "396",
            "label": "GHSS,ALANGULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "125",
            "district": "38",
            "Block": "396",
            "label": "GHS PULLAGOUNDANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "126",
            "district": "38",
            "Block": "396",
            "label": "GHSS,SANKARAPANDIAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "127",
            "district": "38",
            "Block": "396",
            "label": "GHSS,SEVALPATTI - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "128",
            "district": "38",
            "Block": "396",
            "label": "GHSS,SIPPIPARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "129",
            "district": "38",
            "Block": "396",
            "label": "GHS SUBRAMANIYAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "130",
            "district": "38",
            "Block": "396",
            "label": "GHSS,VEMBAKOTTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "131",
            "district": "38",
            "Block": "396",
            "label": "GHSS,VIJAYAKARISALKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "132",
            "district": "38",
            "Block": "396",
            "label": "GHS THULUKANKURUCHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "133",
            "district": "38",
            "Block": "396",
            "label": "GOVT HSS KALLAMANAICKERPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "134",
            "district": "38",
            "Block": "396",
            "label": "HINDU MARAVAR PS ,T.KOTTAIYUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "135",
            "district": "38",
            "Block": "396",
            "label": "HINDU MIDDLE SCHOOL, ETHIRKOTTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "136",
            "district": "38",
            "Block": "396",
            "label": "KRTA GHSS,MELATHAYILPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "137",
            "district": "38",
            "Block": "396",
            "label": "MAHAMAI MS , GUHANPARAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "138",
            "district": "38",
            "Block": "396",
            "label": "NADAR GEORGE HS, THAYILPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "139",
            "district": "38",
            "Block": "396",
            "label": "NADAR MAHAMAI GIRLS HIGH SCHOOL,ELAYIRAMPANNAI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "140",
            "district": "38",
            "Block": "396",
            "label": "NADAR MAHAMAI HSS ELAYIRAMPANNAI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "141",
            "district": "38",
            "Block": "396",
            "label": "R.P.N.M.HINDU MIDDLE SCHOOL, KAKKIVADANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "142",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S ELAYIRAMPANNAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "143",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S KANGARSEVAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "144",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S KOTTAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "145",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S MANJALODAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "146",
            "district": "38",
            "Block": "396",
            "label": "P U M S M.DURAISAMYPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "147",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S KOTHAINATCHIYARPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "148",
            "district": "38",
            "Block": "396",
            "label": "P U M S NATHIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "149",
            "district": "38",
            "Block": "396",
            "label": "P U M S PARAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "150",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S PERNAICKENPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "151",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S,RAMUTHEVANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "152",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S SOUTH ANAIKUTTAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "153",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S E.RAMANATHAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "154",
            "district": "38",
            "Block": "396",
            "label": "P.U.M.S ETTAKKAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "155",
            "district": "38",
            "Block": "396",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, AYAN SALVARPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "156",
            "district": "38",
            "Block": "396",
            "label": "TANCEM MHSS ALANGULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21407",
            "district": "38",
            "Block": "396",
            "label": "G.H.S.,MUTHANDIAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21855",
            "district": "38",
            "Block": "396",
            "label": "SP MODERN MATRICULATION SCHOOL ,SEVALPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21856",
            "district": "38",
            "Block": "396",
            "label": "HAYAGRIVAS INTERNATIONAL SCHOOL.PERNAYAKANPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23146",
            "district": "38",
            "Block": "396",
            "label": "MADURAI C.E.O.A MATRICULATION SCHOOL, SALVARPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "397": [
        {
            "key": "1471",
            "district": "17",
            "Block": "397",
            "label": "PUMS NACHIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1597",
            "district": "17",
            "Block": "397",
            "label": "PUMS R.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1642",
            "district": "17",
            "Block": "397",
            "label": "PUMS NADUPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1712",
            "district": "17",
            "Block": "397",
            "label": "PUMS KUTTALADAMPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1738",
            "district": "17",
            "Block": "397",
            "label": "PUMS MASAKALIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1809",
            "district": "17",
            "Block": "397",
            "label": "PUMS AKKARAIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3257",
            "district": "17",
            "Block": "397",
            "label": "MERLION MATRIC HR.SEC.SCHOOL, NO-3, KOMARAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3514",
            "district": "17",
            "Block": "397",
            "label": "SRI VENKATESHWARAA MATRICULATION HIGHER SECONDARY SCHOOL,VENNANDUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4228",
            "district": "17",
            "Block": "397",
            "label": "JVM MATRICULATION HR. SEC. SCHOOL,R.PUDUPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11864",
            "district": "17",
            "Block": "397",
            "label": "GHS MINNAKKAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11865",
            "district": "17",
            "Block": "397",
            "label": "GHS NO-3 KOMARAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11866",
            "district": "17",
            "Block": "397",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL ALAVAIPATTI VENNANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11867",
            "district": "17",
            "Block": "397",
            "label": "GHSS (G)ALAVAIPATTI VENNANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11868",
            "district": "17",
            "Block": "397",
            "label": "THILLAIYADI VALLIYAMMAI  AIDED GIRLS HIGH SCHOOL, VENNANDUR",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11869",
            "district": "17",
            "Block": "397",
            "label": "SRI VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL, NACHIPATTY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11870",
            "district": "17",
            "Block": "397",
            "label": "GHS O.SOWDHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11871",
            "district": "17",
            "Block": "397",
            "label": "KALAIMAGAL MATRICULATION SCHOOL, PALANTHINIPATTI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11872",
            "district": "17",
            "Block": "397",
            "label": "GHSS ATHANUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11873",
            "district": "17",
            "Block": "397",
            "label": "GHS ATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11874",
            "district": "17",
            "Block": "397",
            "label": "VETRI VHSS B KEERANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11875",
            "district": "17",
            "Block": "397",
            "label": "VETRI VHSS G KEERANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11876",
            "district": "17",
            "Block": "397",
            "label": "GANDHI KALVI NILAYAM HIGH SCHOOL, THENGALPALAYAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "11877",
            "district": "17",
            "Block": "397",
            "label": "GHSS R.PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11878",
            "district": "17",
            "Block": "397",
            "label": "SRV HITECH MATRIC HIGHER SECONDARY SCHOOL,MASAKALIPATTI, RASIPURAM TK, NAMAKKAL DT -637401",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21229",
            "district": "17",
            "Block": "397",
            "label": "VIDHYA NIKETHAN INTEL MATRIC HIGHER SECONDARY SCHOOL,THENGALPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21668",
            "district": "17",
            "Block": "397",
            "label": "MAHARISHI VIDYA MANDIR  (CBSE), MASAKALIPATTI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23522",
            "district": "17",
            "Block": "397",
            "label": "VIDHYA NIKETHAN INTEL PUBLIC SCHOOL, THENGALPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23522",
            "district": "17",
            "Block": "397",
            "label": "VIDHYA NIKETHAN INTEL PUBLIC SCHOOL, THENGALPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "398": [
        {
            "key": "4681",
            "district": "13",
            "Block": "398",
            "label": "SRI SARASWATHI VID.MHSS, KUNDARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4688",
            "district": "13",
            "Block": "398",
            "label": "VAILANKANNI MHSS, VEPPANAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4890",
            "district": "13",
            "Block": "398",
            "label": "PUMS EGUDATHAMPALLI URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4931",
            "district": "13",
            "Block": "398",
            "label": "PUMS JINKALURE  URUDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4987",
            "district": "13",
            "Block": "398",
            "label": "PUMS VEPPANAPALLI - URDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5137",
            "district": "13",
            "Block": "398",
            "label": "PUMS KUPPACHIPARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5410",
            "district": "13",
            "Block": "398",
            "label": "PUMS VIRUPPASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5623",
            "district": "13",
            "Block": "398",
            "label": "PUMS THIMMASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6259",
            "district": "13",
            "Block": "398",
            "label": "PUMS NACHIKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6304",
            "district": "13",
            "Block": "398",
            "label": "PUMS  BALANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6401",
            "district": "13",
            "Block": "398",
            "label": "PUMS  KURIYANAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6426",
            "district": "13",
            "Block": "398",
            "label": "PUMS  AVALNATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6577",
            "district": "13",
            "Block": "398",
            "label": "PUMS JEDUKOTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6682",
            "district": "13",
            "Block": "398",
            "label": "CRESCENT MHSS KURUBARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6720",
            "district": "13",
            "Block": "398",
            "label": "PUMS  BOMMARASANAPALLI (TELUGU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20643",
            "district": "13",
            "Block": "398",
            "label": "PUMS  BADDHIMADUGU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20644",
            "district": "13",
            "Block": "398",
            "label": "GOVT HSS, CHENNASANDIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20645",
            "district": "13",
            "Block": "398",
            "label": "PUMS CHIGARAMANAKAPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20646",
            "district": "13",
            "Block": "398",
            "label": "GOVT HS, ENNEGOLLUPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20647",
            "district": "13",
            "Block": "398",
            "label": "PUMS GANGASANDIRAM(URUDU)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20648",
            "district": "13",
            "Block": "398",
            "label": "PUMS HALEKUNDANI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20649",
            "district": "13",
            "Block": "398",
            "label": "GOVT HS, KORALNATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20650",
            "district": "13",
            "Block": "398",
            "label": "GHSS, KUNDARAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20651",
            "district": "13",
            "Block": "398",
            "label": "SRI SATHYA SAI MATRIC.HR SEC SCHOOL, KUNDARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20652",
            "district": "13",
            "Block": "398",
            "label": "GOVT HSS KURUBARAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20653",
            "district": "13",
            "Block": "398",
            "label": "KGBV POLUPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20654",
            "district": "13",
            "Block": "398",
            "label": "GHSS, MANAVARANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20655",
            "district": "13",
            "Block": "398",
            "label": "PUMS MARASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20656",
            "district": "13",
            "Block": "398",
            "label": "PUMS NEDUSALAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20657",
            "district": "13",
            "Block": "398",
            "label": "PUMS RAMASANDIRAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20658",
            "district": "13",
            "Block": "398",
            "label": "GOVT.HS,THEERTHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20659",
            "district": "13",
            "Block": "398",
            "label": "GOVT.HS, V.MADEPALLI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20660",
            "district": "13",
            "Block": "398",
            "label": "SHRI MARUTHI MATRIC HIGHER SECONDARY SCHOOL V.MADEPALLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20661",
            "district": "13",
            "Block": "398",
            "label": "GOVT. HSS-BOYS  VEPPANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20662",
            "district": "13",
            "Block": "398",
            "label": "GHSS-GIRLS, VEPPANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20663",
            "district": "13",
            "Block": "398",
            "label": "GOVT HS, CHINNAKOTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20664",
            "district": "13",
            "Block": "398",
            "label": "PUMS KODIPALLI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20665",
            "district": "13",
            "Block": "398",
            "label": "PUMS NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "20666",
            "district": "13",
            "Block": "398",
            "label": "GOVT HS, NEDUMARTHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "20667",
            "district": "13",
            "Block": "398",
            "label": "GOVT.HS, NERALAGIRI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21195",
            "district": "13",
            "Block": "398",
            "label": "MODEL HSS VEPPANAPALLI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22673",
            "district": "13",
            "Block": "398",
            "label": "SRI SARASWATHI VIDYALAYA , KUNDARAPALLI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24068",
            "district": "13",
            "Block": "398",
            "label": "PUMS NALLUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "399": [
        {
            "key": "4812",
            "district": "18",
            "Block": "399",
            "label": "SWAMI VIVEKANANDA MATRIC HR. SEC. SCHOOL, ARUMBAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14780",
            "district": "18",
            "Block": "399",
            "label": "PUMS VELLUVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14781",
            "district": "18",
            "Block": "399",
            "label": "GHS KARIYANOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14782",
            "district": "18",
            "Block": "399",
            "label": "GHSS KAI.KALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14783",
            "district": "18",
            "Block": "399",
            "label": "MAHATHMA MATRICULATION HIGH SCHOOL KAIKALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14784",
            "district": "18",
            "Block": "399",
            "label": "GHS METTUPPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14785",
            "district": "18",
            "Block": "399",
            "label": "PUMS-V.KALATHUR(G)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14786",
            "district": "18",
            "Block": "399",
            "label": "GHSS V. KALATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14787",
            "district": "18",
            "Block": "399",
            "label": "HIDAYATH MATRICULATION  HIGH SCHOOL, V.KALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14788",
            "district": "18",
            "Block": "399",
            "label": "ST MARY'S HSS  AGARAM V.KALATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14789",
            "district": "18",
            "Block": "399",
            "label": "GHSS NERKUNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14790",
            "district": "18",
            "Block": "399",
            "label": "T.ROVER.H.S.NERUKUNAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14791",
            "district": "18",
            "Block": "399",
            "label": "GHSS PASUMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14792",
            "district": "18",
            "Block": "399",
            "label": "ADWHS PASUMBALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14793",
            "district": "18",
            "Block": "399",
            "label": "PUMS, PILLANGULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14794",
            "district": "18",
            "Block": "399",
            "label": "PUMS, SIRUNILA",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14795",
            "district": "18",
            "Block": "399",
            "label": "GHSS VALIKANDAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14796",
            "district": "18",
            "Block": "399",
            "label": "ST.JOHN.M.SCHOOL,THIRUVALANDURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14797",
            "district": "18",
            "Block": "399",
            "label": "GHSS RANJANKUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14798",
            "district": "18",
            "Block": "399",
            "label": "PUMS-AYYANPERIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14799",
            "district": "18",
            "Block": "399",
            "label": "GHS VENPAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14800",
            "district": "18",
            "Block": "399",
            "label": "PUMS N.PUDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14801",
            "district": "18",
            "Block": "399",
            "label": "GHS NEIKUPAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14802",
            "district": "18",
            "Block": "399",
            "label": "GHS NOOTHAPUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14803",
            "district": "18",
            "Block": "399",
            "label": "RC.M.S,ERAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14804",
            "district": "18",
            "Block": "399",
            "label": "TELC M S,ERAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14805",
            "district": "18",
            "Block": "399",
            "label": "NEHRU HSS ERAIYUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14806",
            "district": "18",
            "Block": "399",
            "label": "GHSS ANUKKUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14807",
            "district": "18",
            "Block": "399",
            "label": "GHSS ARUMBAVUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14808",
            "district": "18",
            "Block": "399",
            "label": "SHANTHI NIKETHAN MATRICULATION HIGHER SECONDARY SCHOOL  ARUMBAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14809",
            "district": "18",
            "Block": "399",
            "label": "SRI RAGAVENDRA HR. SEC. SCHOOL, ARUMBAVUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14810",
            "district": "18",
            "Block": "399",
            "label": "PUMS UDUMBIYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14811",
            "district": "18",
            "Block": "399",
            "label": "PUMS KALLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14812",
            "district": "18",
            "Block": "399",
            "label": "EDEN GARDENS MATRIC HR. SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14813",
            "district": "18",
            "Block": "399",
            "label": "EDEN GARDENS HR.SEC.SCHOOL UDUMBIAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14814",
            "district": "18",
            "Block": "399",
            "label": "PUMS, MOHAMEDPATTINAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14815",
            "district": "18",
            "Block": "399",
            "label": "PUMS,BRAMMADESAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14816",
            "district": "18",
            "Block": "399",
            "label": "GHSS POOLAMBADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14817",
            "district": "18",
            "Block": "399",
            "label": "ST.MARY M.S, THONDAMANDURAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14818",
            "district": "18",
            "Block": "399",
            "label": "PUMS, VIJAYAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14819",
            "district": "18",
            "Block": "399",
            "label": "GHS THONDAMANDURAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14820",
            "district": "18",
            "Block": "399",
            "label": "GHSS VENKALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14821",
            "district": "18",
            "Block": "399",
            "label": "SHRI MATHURA MATRIC  SCHOOL KRISHNAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14822",
            "district": "18",
            "Block": "399",
            "label": "ANNAI MATRICULATION  HIGH SCHOOL  VENGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14823",
            "district": "18",
            "Block": "399",
            "label": "GHSS  VEPPANTHATTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14824",
            "district": "18",
            "Block": "399",
            "label": "PUMS, A.METTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14825",
            "district": "18",
            "Block": "399",
            "label": "GHS GOUNDERPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14826",
            "district": "18",
            "Block": "399",
            "label": "RC. M.SCHOOL.FATHIMAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14827",
            "district": "18",
            "Block": "399",
            "label": "LFHSS ANNAMANGALAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21165",
            "district": "18",
            "Block": "399",
            "label": "LABBAIKUDIKADU WISDOM MATRIC HR.SEC.SCHOOL AYANPERAIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22194",
            "district": "18",
            "Block": "399",
            "label": "GTRHS MALAIYALAPPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22539",
            "district": "18",
            "Block": "399",
            "label": "GHS PERIYAMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23031",
            "district": "18",
            "Block": "399",
            "label": "IDEAL MATRICULATION SCHOOL , V.KALATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23133",
            "district": "18",
            "Block": "399",
            "label": "ST JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23527",
            "district": "18",
            "Block": "399",
            "label": "PERAMBALUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24069",
            "district": "18",
            "Block": "399",
            "label": "St MARY'S MATRIC HR SEC SCHOOL  V.KALATHUR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24070",
            "district": "18",
            "Block": "399",
            "label": "PUMS, VIJAYAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24071",
            "district": "18",
            "Block": "399",
            "label": "ST JOSEPH GLOBAL SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24072",
            "district": "18",
            "Block": "399",
            "label": "PERAMBALUR DISTRICT GOVERNMENT MODEL SCHOOL",
            "Sch_type": "GovernmentHigher Secondary School"
        },
        {
            "key": "24073",
            "district": "18",
            "Block": "399",
            "label": "St.MARY'S PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24074",
            "district": "18",
            "Block": "399",
            "label": "SPRING FIELD PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "400": [
        {
            "key": "4879",
            "district": "18",
            "Block": "400",
            "label": "ST.ANDREWS MATRICULATION HIGHER SECONDARY SCHOOL THIRUMANDURAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14729",
            "district": "18",
            "Block": "400",
            "label": "PUMS THIRUMANDURAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14730",
            "district": "18",
            "Block": "400",
            "label": "GHS VADAKKALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14731",
            "district": "18",
            "Block": "400",
            "label": "GHSS OGALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14732",
            "district": "18",
            "Block": "400",
            "label": "GHSS - S.ADUTHURAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14733",
            "district": "18",
            "Block": "400",
            "label": "GHS KILUMATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14734",
            "district": "18",
            "Block": "400",
            "label": "GHS ATHIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14735",
            "district": "18",
            "Block": "400",
            "label": "PUMS AGARAM SEEGOOR-E",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14736",
            "district": "18",
            "Block": "400",
            "label": "Aided MS, Vayalur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14737",
            "district": "18",
            "Block": "400",
            "label": "PUMS VASISTAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14738",
            "district": "18",
            "Block": "400",
            "label": "PUMS MELAKALINGARANANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14739",
            "district": "18",
            "Block": "400",
            "label": "GHSS KEELAPERAMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14740",
            "district": "18",
            "Block": "400",
            "label": "GMS VELVIMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14741",
            "district": "18",
            "Block": "400",
            "label": "PUMS-VAYALAPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14742",
            "district": "18",
            "Block": "400",
            "label": "PUMS V.KEERANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14743",
            "district": "18",
            "Block": "400",
            "label": "PUMS KALLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14744",
            "district": "18",
            "Block": "400",
            "label": "PUMS-OLAIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14745",
            "district": "18",
            "Block": "400",
            "label": "GHSS VEPPUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14746",
            "district": "18",
            "Block": "400",
            "label": "RC STJOHN HS PERUMATHUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "14747",
            "district": "18",
            "Block": "400",
            "label": "PUMS-LABBAIKUDIKADU-W",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14748",
            "district": "18",
            "Block": "400",
            "label": "SALAMATH M SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "14749",
            "district": "18",
            "Block": "400",
            "label": "GGHSS LABBAIKUDIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14750",
            "district": "18",
            "Block": "400",
            "label": "GHSS(B)-LABBAIKUDIKADU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14751",
            "district": "18",
            "Block": "400",
            "label": "HAJI ABDULA SHA MATRICULATION HIGH SCHOOL PENNAKONAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "14752",
            "district": "18",
            "Block": "400",
            "label": "GHSS THUNGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14753",
            "district": "18",
            "Block": "400",
            "label": "PANDIAN AIDED.MID.S.NALLARIKAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14754",
            "district": "18",
            "Block": "400",
            "label": "Chidambaram MS",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14755",
            "district": "18",
            "Block": "400",
            "label": "PUMS KOTHAVASAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14756",
            "district": "18",
            "Block": "400",
            "label": "GHS VARAGOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14758",
            "district": "18",
            "Block": "400",
            "label": "GHS PARAVAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14759",
            "district": "18",
            "Block": "400",
            "label": "TELC, Aykudi",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "14760",
            "district": "18",
            "Block": "400",
            "label": "GHS ELUMUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14761",
            "district": "18",
            "Block": "400",
            "label": "GHS KARUGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14762",
            "district": "18",
            "Block": "400",
            "label": "PUMS ANDHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14763",
            "district": "18",
            "Block": "400",
            "label": "ST.PAUL  MATRICULATION HIGHER SECONDARY SCHOOL  ANTHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14764",
            "district": "18",
            "Block": "400",
            "label": "GHS  ASUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14765",
            "district": "18",
            "Block": "400",
            "label": "VAANPUGAL VALLUVAR HSS OTHIYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14766",
            "district": "18",
            "Block": "400",
            "label": "GHS OTHIYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14767",
            "district": "18",
            "Block": "400",
            "label": "GBHSS KUNNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14768",
            "district": "18",
            "Block": "400",
            "label": "GGHSS KUNNAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14769",
            "district": "18",
            "Block": "400",
            "label": "GHS - PERIYAMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14770",
            "district": "18",
            "Block": "400",
            "label": "GHSS-PERALI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14771",
            "district": "18",
            "Block": "400",
            "label": "GHSS MARUVATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14772",
            "district": "18",
            "Block": "400",
            "label": "KASTHURBA GANDHI BALIKA VIDHYALAYA  SCHOOL  PERALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14773",
            "district": "18",
            "Block": "400",
            "label": "PUMS SITHALI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14774",
            "district": "18",
            "Block": "400",
            "label": "GHS- PEELVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14775",
            "district": "18",
            "Block": "400",
            "label": "GHSS KEELAPULIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14776",
            "district": "18",
            "Block": "400",
            "label": "SWAMY VIVEKANANDA MATRICULATION HIGHER SECONDARY SCHOOL KEELAPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "14777",
            "district": "18",
            "Block": "400",
            "label": "PUMS SIRUGUDAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "14778",
            "district": "18",
            "Block": "400",
            "label": "GHS MURUKKANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "14779",
            "district": "18",
            "Block": "400",
            "label": "GHS KADUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21190",
            "district": "18",
            "Block": "400",
            "label": "GHSS MODEL SCHOOL KILUMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21380",
            "district": "18",
            "Block": "400",
            "label": "GHS NANNAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21513",
            "district": "18",
            "Block": "400",
            "label": "ADWHS OGALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21550",
            "district": "18",
            "Block": "400",
            "label": "VETRI HIGH SCHOOL AGARAM SEEGOOR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21756",
            "district": "18",
            "Block": "400",
            "label": "IMMANUVEL HIGH SCHOOL KUNNAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22538",
            "district": "18",
            "Block": "400",
            "label": "GHS PUDHUVETTAKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22858",
            "district": "18",
            "Block": "400",
            "label": "GANDHIRAM HIGH SCHOOL PUDHUVETTAKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23294",
            "district": "18",
            "Block": "400",
            "label": "MAHATMA PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        }
    ],
    "401": [
        {
            "key": "10339",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ENNAIYIRAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10340",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MUTTATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10341",
            "district": "37",
            "Block": "401",
            "label": "WYCKOFF HIGHER SECONDARY SCHOOL MUTTATHUR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10342",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL E-MANDAGAPET",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10343",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ECHANKUPPAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10344",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENTHIGH SCHOOL ESALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10345",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THENPAIR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10346",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL T PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10347",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL CHINNATHACHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10348",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KUTHAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10349",
            "district": "37",
            "Block": "401",
            "label": "ADI DRAVIDAR WELFARE HIGH SCHOOL V SALAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10350",
            "district": "37",
            "Block": "401",
            "label": "ST. PATRICK'S  ACADEMY MATRICULATION SCHOOL V. SALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10351",
            "district": "37",
            "Block": "401",
            "label": "GHS MELAKONDAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10352",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL ASOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10353",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PONNANKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10354",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VEMBI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10355",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ULAGALAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10356",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THUMBUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10357",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  KOTTIYAMPOONDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10358",
            "district": "37",
            "Block": "401",
            "label": "JAI RAMAKRISHNAA VIDYALAYA MATRICULATION SCHOOL ,V.SATHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10359",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL V.SATHANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10360",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VIKARAVANDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10361",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL (GIRLS) VIKARAVANDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10362",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL  VIKARAVANDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10363",
            "district": "37",
            "Block": "401",
            "label": "ST MARY'S MATRIC SCHOOL VIKARAVANDI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10364",
            "district": "37",
            "Block": "401",
            "label": "ADI DRAVIDAR WELFARE MIDDLE SCHOOL  AVADAIYARPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10365",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THORAVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10366",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL  PANAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10367",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAPPANAPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10368",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL ORATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10369",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL CHINTHAMANI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10370",
            "district": "37",
            "Block": "401",
            "label": "NAAHAR PUBLIC SCHOOL AYYUR AGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10371",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL MUNDIYAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10372",
            "district": "37",
            "Block": "401",
            "label": "RAJSHREE SUGARS RAMAKRISHNA VID MATRIC HIGHER SECONDARY SCHOOL , MUNDIYAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10373",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PANAYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10374",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL RADHAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10375",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MADURAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10376",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KAPPIYAMPULIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10377",
            "district": "37",
            "Block": "401",
            "label": "SIGA  HIGHER SECONDARY SCHOOL KAPPIYAMPULIYUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10378",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10379",
            "district": "37",
            "Block": "401",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL PAGANDAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10380",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL PAGANDAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10381",
            "district": "37",
            "Block": "401",
            "label": "AIDED MIDDLE SCHOOL SINGARAPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21968",
            "district": "37",
            "Block": "401",
            "label": "SEVENTH DAY WHITE MATRICULATION SCHOOL V.SATHANUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22097",
            "district": "37",
            "Block": "401",
            "label": "K.G.  VIDYASHRAM CENTRAL BOARD SECONDARY EDUCATION SCHOOL ASHOKAPURI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22427",
            "district": "37",
            "Block": "401",
            "label": "GOVERNMENT HIGH SCHOOL KUNDALAPULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22428",
            "district": "37",
            "Block": "401",
            "label": "VELA SPECIAL SCHOOL  LAKSHMIPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23196",
            "district": "37",
            "Block": "401",
            "label": "VILLUPURAM VIDYALAYA INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23639",
            "district": "37",
            "Block": "401",
            "label": "VILLUPURAM DISTRICT GOVERNMENT MODEL SCHOOL ",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23640",
            "district": "37",
            "Block": "401",
            "label": "St Josephs School V Sathanur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23639",
            "district": "37",
            "Block": "401",
            "label": "VILLUPURAM DISTRICT GOVERNMENT MODEL SCHOOL ",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23640",
            "district": "37",
            "Block": "401",
            "label": "St Josephs School V Sathanur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24075",
            "district": "37",
            "Block": "401",
            "label": "NATIONAL ACADEMY MATRIC  SCHOOL THUMBUR  ",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "402": [
        {
            "key": "2769",
            "district": "28",
            "Block": "402",
            "label": "PUMS, AyanVirusampaati",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3098",
            "district": "28",
            "Block": "402",
            "label": "St.Charles MATRICULATION SCHOOL, Vilathikulam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3334",
            "district": "28",
            "Block": "402",
            "label": "H.N.MS, Kulathur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3855",
            "district": "28",
            "Block": "402",
            "label": "SHARON MATRIC HR.SEC.SCHOOL, VILATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4475",
            "district": "28",
            "Block": "402",
            "label": "TNDTA MS, VILATHIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4924",
            "district": "28",
            "Block": "402",
            "label": "ST.PETER MS, SUBRAMANIAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5156",
            "district": "28",
            "Block": "402",
            "label": "N.K.MS, Arunkulam",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5776",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Kamalapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19509",
            "district": "28",
            "Block": "402",
            "label": "T.V.A.NALLALAGU NADAR HIGHER SECONDARY SCHOOL , PERILOVANPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19510",
            "district": "28",
            "Block": "402",
            "label": "GHS Kodangipatti",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19511",
            "district": "28",
            "Block": "402",
            "label": "T.N.D.T.A. MS, Chithavanayakkanpatti",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19512",
            "district": "28",
            "Block": "402",
            "label": "S.R. MS, AyanBommayapuram",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19513",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Kalugachalapuram",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19514",
            "district": "28",
            "Block": "402",
            "label": "GHS Padarnthapuli",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19515",
            "district": "28",
            "Block": "402",
            "label": "GHS Ariyanayagipuram",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19516",
            "district": "28",
            "Block": "402",
            "label": "GHSS Sivagnanapuram",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19517",
            "district": "28",
            "Block": "402",
            "label": "PUMS, SAKKAMMALPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19518",
            "district": "28",
            "Block": "402",
            "label": "TNDTA MS Marthandampatti",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19519",
            "district": "28",
            "Block": "402",
            "label": "H.N. MS, Panaiyur",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19520",
            "district": "28",
            "Block": "402",
            "label": "GHSS,KULATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19521",
            "district": "28",
            "Block": "402",
            "label": "TNDTA MS, V.VEDAPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19522",
            "district": "28",
            "Block": "402",
            "label": "St.Paul's HS - V.Vedapatti",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19523",
            "district": "28",
            "Block": "402",
            "label": "GOVT HIGH SCHOOL MELMANTHAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "19524",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Thathaneri",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19525",
            "district": "28",
            "Block": "402",
            "label": "H.G.K.HS  K.Thangammalpuram",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "19526",
            "district": "28",
            "Block": "402",
            "label": "ST.ANTONY'S HS, PERIYASAMYPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "19527",
            "district": "28",
            "Block": "402",
            "label": "St.Sebastian MS, Vembar",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19528",
            "district": "28",
            "Block": "402",
            "label": "GHSS  Vembar",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19529",
            "district": "28",
            "Block": "402",
            "label": "ST.MARY'S MATRICULATION SCHOOL, VEMBAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "19530",
            "district": "28",
            "Block": "402",
            "label": "GHSS VILATHIKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19531",
            "district": "28",
            "Block": "402",
            "label": "C.A.R.M.GHSS-Vilathikulam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19532",
            "district": "28",
            "Block": "402",
            "label": "ST. TERESAL'S MS SIPPIKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "19533",
            "district": "28",
            "Block": "402",
            "label": "ST.LOUIS HSS, KEELA VAIPPAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "19534",
            "district": "28",
            "Block": "402",
            "label": "PUMS, K.SUBRAMANIAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19535",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Neeravi Puthupatti",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19536",
            "district": "28",
            "Block": "402",
            "label": "PUMS, Nedunkulam",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "19537",
            "district": "28",
            "Block": "402",
            "label": "N.H.S. Karisalkulam",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "21918",
            "district": "28",
            "Block": "402",
            "label": "SRI AMBAL VIDHYALAYA, AMBALNAGAR VILATHIKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23577",
            "district": "28",
            "Block": "402",
            "label": "G K VINAYAKA VIDYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23577",
            "district": "28",
            "Block": "402",
            "label": "G K VINAYAKA VIDYALAYA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24076",
            "district": "28",
            "Block": "402",
            "label": "PUMS, SAKKAMMALPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24077",
            "district": "28",
            "Block": "402",
            "label": "PUPS, Solaimalaiyanpatti",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "403": [
        {
            "key": "372",
            "district": "33",
            "Block": "403",
            "label": "VIJAYANTHA SR.SEC., AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "391",
            "district": "33",
            "Block": "403",
            "label": "PUMS,POOTHAPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "401",
            "district": "33",
            "Block": "403",
            "label": "EBENEZAR MODERN MATRICULATION. SCHOOL, THIRUMULLAIVOYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "404",
            "district": "33",
            "Block": "403",
            "label": "OUR ANGEL MATRIC HIGHER SECONDARY SCHOOL,VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "408",
            "district": "33",
            "Block": "403",
            "label": "SRI KRISHNAMMAL MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "411",
            "district": "33",
            "Block": "403",
            "label": "SREE MAHA GANESA VIDYASALA MIDDLE SCHOOL. AMBATTUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "423",
            "district": "33",
            "Block": "403",
            "label": "Velammals Memorial Mat Hr Sec School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "435",
            "district": "33",
            "Block": "403",
            "label": "KARTHIKEYAN MAT HSS,MADHURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "439",
            "district": "33",
            "Block": "403",
            "label": "SPARTAN MATRIC HIGHER SECONDARY SCHOOL,MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "441",
            "district": "33",
            "Block": "403",
            "label": "PMR MAT SCH, MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "446",
            "district": "33",
            "Block": "403",
            "label": "MGR ADARSH MAT, MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "449",
            "district": "33",
            "Block": "403",
            "label": "DAV B SS, MUGAPPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "456",
            "district": "33",
            "Block": "403",
            "label": "BHARATHI MAT HSS,MADHURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "457",
            "district": "33",
            "Block": "403",
            "label": "LBEAAR MATRICULATION, HIGHER SECONDARY SCHOOL, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "461",
            "district": "33",
            "Block": "403",
            "label": "PUMS, ADAYALAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "464",
            "district": "33",
            "Block": "403",
            "label": "EBENEZER MAT KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "465",
            "district": "33",
            "Block": "403",
            "label": "EBENEZER MARCUS MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "467",
            "district": "33",
            "Block": "403",
            "label": "PUMS,CMDA MADURAVOYAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "484",
            "district": "33",
            "Block": "403",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NOLAMBUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "499",
            "district": "33",
            "Block": "403",
            "label": "JOSHUA MODEL MAT HSS, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "501",
            "district": "33",
            "Block": "403",
            "label": "ASSISI MAT HSS RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "521",
            "district": "33",
            "Block": "403",
            "label": "SREE SARASWATHI MATRICULATION HIGH SCHOOL, KALLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "523",
            "district": "33",
            "Block": "403",
            "label": "SRI SARASWATHI VIDHYA MANDIR MATRIC HR SEC SCHOOL, AYANAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "526",
            "district": "33",
            "Block": "403",
            "label": "CS CENTRAL MAT HSS MANNURPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "545",
            "district": "33",
            "Block": "403",
            "label": "ST MARYS MAT SC - KARAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "548",
            "district": "33",
            "Block": "403",
            "label": "PIONEER MAT, VELLAPPANCHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "554",
            "district": "33",
            "Block": "403",
            "label": "PUMS, MUGAPAIR SECTOR-2",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "567",
            "district": "33",
            "Block": "403",
            "label": "MUN. MID SCH, DR.MOORTHY NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "569",
            "district": "33",
            "Block": "403",
            "label": "RAJIV SCHOOL OF EXCELLENCE MATRIC HIGHER SECONDARY SCHOOL, ALAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "570",
            "district": "33",
            "Block": "403",
            "label": "DAV MATRIC HR SEC SCHOOL, MOGAPPAIR EAST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "576",
            "district": "33",
            "Block": "403",
            "label": "SRI BHUVANESWARI MAT, PADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "577",
            "district": "33",
            "Block": "403",
            "label": "BALAR GURUKULAM MAT, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "580",
            "district": "33",
            "Block": "403",
            "label": "GOODWILL MATRIC HIGHER SECONDARY SCHOOL, THATHANKUPPAM , VILLIVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "581",
            "district": "33",
            "Block": "403",
            "label": "ST JOHNS MATRIC HR SEC SCHOOL, PULIAMBEDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "595",
            "district": "33",
            "Block": "403",
            "label": "SALVATION MAT TMVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "606",
            "district": "33",
            "Block": "403",
            "label": "THE SCHRAM ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "608",
            "district": "33",
            "Block": "403",
            "label": "EBINESAR JAGANATH MARCUS MS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "609",
            "district": "33",
            "Block": "403",
            "label": "LAKSHMI MATRICULATION HIGHER SECONDARY SCHOOL,JAGADAMBIGAI NAGAR,PADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "614",
            "district": "33",
            "Block": "403",
            "label": "SHARON MATRIC HR.SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "615",
            "district": "33",
            "Block": "403",
            "label": "SIVANTHI MAT HSS,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "636",
            "district": "33",
            "Block": "403",
            "label": "PUMS, KONIMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "638",
            "district": "33",
            "Block": "403",
            "label": "PUMS, CHETTIYAR AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "644",
            "district": "33",
            "Block": "403",
            "label": "PUMS, MUGAPAIR SECTOR-4",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "660",
            "district": "33",
            "Block": "403",
            "label": "HOLY CROSS MAT,VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "678",
            "district": "33",
            "Block": "403",
            "label": "VENKATESHWARA MATRIC SCHOOL, THIRUMULLAIVAYOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "679",
            "district": "33",
            "Block": "403",
            "label": "INFANT JESUS MATRIC HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "688",
            "district": "33",
            "Block": "403",
            "label": "PUMS MUGAPPAIR SECTOR - 9",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "715",
            "district": "33",
            "Block": "403",
            "label": "SWAMY'S MAT HSS,PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "727",
            "district": "33",
            "Block": "403",
            "label": "SIRORATHNAM MAT MANNURPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "736",
            "district": "33",
            "Block": "403",
            "label": "GOOD SHEPHERD MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "749",
            "district": "33",
            "Block": "403",
            "label": "SHRI KRISHNASWAMY MATRICULATION SCHOOL,AYAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "778",
            "district": "33",
            "Block": "403",
            "label": "ST JOHNS HIGHER SECONDARY SCHOOL ,PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "795",
            "district": "33",
            "Block": "403",
            "label": "PUMS, ALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "802",
            "district": "33",
            "Block": "403",
            "label": "ST JOSEPH'S MATRIC  HSS,PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "810",
            "district": "33",
            "Block": "403",
            "label": "SREE AADHITHYA MATRICULATION HIGH SCHOOL, VANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "811",
            "district": "33",
            "Block": "403",
            "label": "EXCEL MAT., TMVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "833",
            "district": "33",
            "Block": "403",
            "label": "SWAAMI VIVEKANANDA MATRIC HSS , RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "873",
            "district": "33",
            "Block": "403",
            "label": "ST THOMAS VIDYALAYAM MATRIC HSS  VANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "878",
            "district": "33",
            "Block": "403",
            "label": "RAJALAKSHMI VIDHYASHRAM MATRICULATION HIGHER SECONDARY SCHOOL,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "906",
            "district": "33",
            "Block": "403",
            "label": "MUN.MID.SCH ORAKADAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "919",
            "district": "33",
            "Block": "403",
            "label": "ST ANNAL MS,PORUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "977",
            "district": "33",
            "Block": "403",
            "label": "FAITH HOME MID SCH,PORUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "978",
            "district": "33",
            "Block": "403",
            "label": "NEW CENTURY MATRIC.HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1032",
            "district": "33",
            "Block": "403",
            "label": "PRIYA MATRICULATION HIGHER SECONDARY SCHOOL, MUGAPAIR WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1070",
            "district": "33",
            "Block": "403",
            "label": "JANET MATRIC HSS, THIRUVERKADU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1073",
            "district": "33",
            "Block": "403",
            "label": "GK SHETTY VIV VID JR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1083",
            "district": "33",
            "Block": "403",
            "label": "T.I. MATRICULATION HIGHER SECONDARY SCHOOL AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1117",
            "district": "33",
            "Block": "403",
            "label": "LITTLE HOLY ANGELS M.S,KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1156",
            "district": "33",
            "Block": "403",
            "label": "SETHU BHASKARA MATRIC HR SEC SCH KALLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1159",
            "district": "33",
            "Block": "403",
            "label": "ST PAULS HSS, KARAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1225",
            "district": "33",
            "Block": "403",
            "label": "CHRIST MATRIC HIGHER SECONDARY SCHOOL, PADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1283",
            "district": "33",
            "Block": "403",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, NEW KANNIYAMMAN NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1288",
            "district": "33",
            "Block": "403",
            "label": "PUMS, MELAPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1290",
            "district": "33",
            "Block": "403",
            "label": "SRI VIDHYA NIKETHAN MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1293",
            "district": "33",
            "Block": "403",
            "label": "MAHARISHI SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1306",
            "district": "33",
            "Block": "403",
            "label": "SRI VENKATESHWARA MATRIC HIGHER SECONDARY SCHOOL,THIRUMULLAIVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1323",
            "district": "33",
            "Block": "403",
            "label": "GRACE MATRICULATION HIGHER SECONDARY SCHOOL , KARAMBAKKAM,PORUR,CHENNAI-600116",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1343",
            "district": "33",
            "Block": "403",
            "label": "GREEN VALLEY MAT MUGAPAIR WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1361",
            "district": "33",
            "Block": "403",
            "label": "MR MAT HSS, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1371",
            "district": "33",
            "Block": "403",
            "label": "AIYEESHA RAZZAC MATRIC HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1407",
            "district": "33",
            "Block": "403",
            "label": "SRI VISA MAT,MADURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "1454",
            "district": "33",
            "Block": "403",
            "label": "Sathyalok Matriculation Hr. Sec.  School",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1475",
            "district": "33",
            "Block": "403",
            "label": "MKM MAT SCHOOL PORUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1506",
            "district": "33",
            "Block": "403",
            "label": "VAILANKANNI MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1510",
            "district": "33",
            "Block": "403",
            "label": "SBIOA MODEL MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1549",
            "district": "33",
            "Block": "403",
            "label": "SHAMROCK MAT HSS MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1573",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL MAT SCHOOL, MU WEST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1596",
            "district": "33",
            "Block": "403",
            "label": "St.MOSES MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1623",
            "district": "33",
            "Block": "403",
            "label": "LAKE VIEW MS, KALLIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1630",
            "district": "33",
            "Block": "403",
            "label": "UCCK MATRIC HR.SEC.SCHOOL,AYANAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1655",
            "district": "33",
            "Block": "403",
            "label": "C.S.CENTRAL MATRIC HR.SEC.SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1672",
            "district": "33",
            "Block": "403",
            "label": "VASANTHA VIDHYA. KARAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1754",
            "district": "33",
            "Block": "403",
            "label": "KOIL PILLAI MATRICULATION HIGHER SECONDARY SCHOOL,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1761",
            "district": "33",
            "Block": "403",
            "label": "SATHYA MAT HSS,VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1762",
            "district": "33",
            "Block": "403",
            "label": "KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL, SENTHIL NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1817",
            "district": "33",
            "Block": "403",
            "label": "AMUTHA MAT HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1850",
            "district": "33",
            "Block": "403",
            "label": "LEO MAT HSS AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1851",
            "district": "33",
            "Block": "403",
            "label": "KALKI RANGANATHAN MONTFORD MAT SCH",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1881",
            "district": "33",
            "Block": "403",
            "label": "ST.MARYS MATRICULATION HIGHER SECONDARY SCHOOL, ATHIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1920",
            "district": "33",
            "Block": "403",
            "label": "BALDWIN'S MAT SCH, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1948",
            "district": "33",
            "Block": "403",
            "label": "SINTHEA MODEL MAT SCHOOL,KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1955",
            "district": "33",
            "Block": "403",
            "label": "SACRED HEART MAT.HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23417",
            "district": "33",
            "Block": "403",
            "label": "PRS Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23384",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1969",
            "district": "33",
            "Block": "403",
            "label": "MUN.MID.SCH. ,MUTHAPUTHUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "1979",
            "district": "33",
            "Block": "403",
            "label": "CHILDREN'S ACADEMY MATRIC HIGH SCHOOL  ,PORUR CHENNAI 600116",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2017",
            "district": "33",
            "Block": "403",
            "label": "NABI CRESCENT MATRICULATION SCHOOL, KOLLUMEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2049",
            "district": "33",
            "Block": "403",
            "label": "BHARATH MATRICULATION HIGHER SECONDARY SCHOOL AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2054",
            "district": "33",
            "Block": "403",
            "label": "BHARATH MAT, TMVOYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2078",
            "district": "33",
            "Block": "403",
            "label": "DR VIMALA MS,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2094",
            "district": "33",
            "Block": "403",
            "label": "SRI RAMAKRISHNA MATRIC HSS,TMVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2165",
            "district": "33",
            "Block": "403",
            "label": "SATHEESH BALAJI MS,MADHANAKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2178",
            "district": "33",
            "Block": "403",
            "label": "MUN.MID.SCH MANGALAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2215",
            "district": "33",
            "Block": "403",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2216",
            "district": "33",
            "Block": "403",
            "label": "GOOD SHEPHERD MAT AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2305",
            "district": "33",
            "Block": "403",
            "label": "MUN.MS, CHOLAMPEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2528",
            "district": "33",
            "Block": "403",
            "label": "SAMUEL HARRIS MATRIC HIGHER SECONDARY SCHOOL AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2614",
            "district": "33",
            "Block": "403",
            "label": "CHRISTURAJA MATRICULATION SCHOOL,KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2704",
            "district": "33",
            "Block": "403",
            "label": "KV HVF CBSE, AVADY",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2785",
            "district": "33",
            "Block": "403",
            "label": "BHAGYALAKSHMI MATRICULATION HIGH SCHOOL, MADHURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2907",
            "district": "33",
            "Block": "403",
            "label": "EMMANUEL METHoDIST MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3003",
            "district": "33",
            "Block": "403",
            "label": "BUTTERFLY MAT SCH, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3251",
            "district": "33",
            "Block": "403",
            "label": "HUSSAIN MEMORIAL MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3370",
            "district": "33",
            "Block": "403",
            "label": "SRE SASSTHA MATRIC HIGHER SECONDARY SCHOOL, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3387",
            "district": "33",
            "Block": "403",
            "label": "PASUMPON AACINI MAT HSS AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3505",
            "district": "33",
            "Block": "403",
            "label": "SHRI  VEL'S  ESTATE MATRICULATION HR. SEC.SCHOOL,VELLANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3554",
            "district": "33",
            "Block": "403",
            "label": "BALAGURU CHETI.VV, VEERAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "3619",
            "district": "33",
            "Block": "403",
            "label": "MUN.MID.SCH PATTARAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4389",
            "district": "33",
            "Block": "403",
            "label": "SRI VENKATESWARA MATRIC HSS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4571",
            "district": "33",
            "Block": "403",
            "label": "ARUL MARY Matriculation School",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5208",
            "district": "33",
            "Block": "403",
            "label": "ST THERASA MAT HSS MANNURPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5300",
            "district": "33",
            "Block": "403",
            "label": "DAV (G) SR.SEC.SCH, COLLECTOR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5338",
            "district": "33",
            "Block": "403",
            "label": "ST JOHNS MS,TM VOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5362",
            "district": "33",
            "Block": "403",
            "label": "St. Mary's Matriculation Higher Secondary School ,IAF Avadi",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5500",
            "district": "33",
            "Block": "403",
            "label": "RANGASAMY MATRIC  HSS,",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5824",
            "district": "33",
            "Block": "403",
            "label": "LITTLE HOLY ANGELS'  MATRICULATION HIGHER SECONDARY SCHOOL ,AYAPPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5965",
            "district": "33",
            "Block": "403",
            "label": "SRI RAMA KRISHNA VIDYA MANDIR  MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6014",
            "district": "33",
            "Block": "403",
            "label": "EBENEZER LIVING WORD MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6049",
            "district": "33",
            "Block": "403",
            "label": "NALLI KUPPUSWAMY V V KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6579",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDHIYASHRAM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6931",
            "district": "33",
            "Block": "403",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL, AYAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6932",
            "district": "33",
            "Block": "403",
            "label": "NATHAN HS, NERKUNDRAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6933",
            "district": "33",
            "Block": "403",
            "label": "GOVT H.S, NERKUNDRAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6934",
            "district": "33",
            "Block": "403",
            "label": "SRI AYYAPPA N&P, METTUKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6935",
            "district": "33",
            "Block": "403",
            "label": "DAWN CBSE SCH, NOLAMBUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6936",
            "district": "33",
            "Block": "403",
            "label": "GOVT HS, PALAVEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6937",
            "district": "33",
            "Block": "403",
            "label": "GOVT HSS, PAMMADUKULAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6938",
            "district": "33",
            "Block": "403",
            "label": "GOVT HSS, LAKSHMIPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6939",
            "district": "33",
            "Block": "403",
            "label": "GHSS,PANDESWARAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6940",
            "district": "33",
            "Block": "403",
            "label": "ADW MS, VELLANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6941",
            "district": "33",
            "Block": "403",
            "label": "GHS, ARIKKAMPEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6942",
            "district": "33",
            "Block": "403",
            "label": "GHS, KOLLUMEDU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6943",
            "district": "33",
            "Block": "403",
            "label": "GHS KARAMBAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6944",
            "district": "33",
            "Block": "403",
            "label": "GHSS, MITTAMALLEE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6945",
            "district": "33",
            "Block": "403",
            "label": "SIRAGU MONTESSORI  MITTANANALLEE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6946",
            "district": "33",
            "Block": "403",
            "label": "ADW HIGH SCH, MITTAMALLEE",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6947",
            "district": "33",
            "Block": "403",
            "label": "KENDRIYA VID OCF, AVADI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6948",
            "district": "33",
            "Block": "403",
            "label": "CRPF KV CBSE, AVADI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6949",
            "district": "33",
            "Block": "403",
            "label": "GHSS SATHIYAMURTHY NAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6950",
            "district": "33",
            "Block": "403",
            "label": "ST. THOMAS MATRICULATION SCHOOL, KOVILPATHAGAI, AVADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6951",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL MATRICULATION HIGHER SECONDARY, MOGA-EAST",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6952",
            "district": "33",
            "Block": "403",
            "label": "VIJAYANTA HIGHER SEC SCHOOL , AVADI",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6953",
            "district": "33",
            "Block": "403",
            "label": "MHS,AGRAHARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6954",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDHYASHRAM AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6955",
            "district": "33",
            "Block": "403",
            "label": "SRI RAMAKRISHNA MS,AMBATHUR 8",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6956",
            "district": "33",
            "Block": "403",
            "label": "KV AIRFORCE STATION IAF AVADI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6957",
            "district": "33",
            "Block": "403",
            "label": "PJ GUPTA HS AMBATTUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6958",
            "district": "33",
            "Block": "403",
            "label": "SRM HSS AMBATTUR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6959",
            "district": "33",
            "Block": "403",
            "label": "VALLIAMMAL MAT VENKATAPUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6960",
            "district": "33",
            "Block": "403",
            "label": "GOVT (G) HSS AMBATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6961",
            "district": "33",
            "Block": "403",
            "label": "NATHELLA VIDHYODAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6962",
            "district": "33",
            "Block": "403",
            "label": "HIGH SCHOOL T V NAGAR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6963",
            "district": "33",
            "Block": "403",
            "label": "HUSSAINY HS HUSSAINABAD",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6964",
            "district": "33",
            "Block": "403",
            "label": "ST JOSEPH MAT H S S AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6965",
            "district": "33",
            "Block": "403",
            "label": "AMBT MUN.HIGH.SCH CHOLOPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6966",
            "district": "33",
            "Block": "403",
            "label": "MUN.HSS KORATTUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6967",
            "district": "33",
            "Block": "403",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MUGAPAIR WEST - MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6968",
            "district": "33",
            "Block": "403",
            "label": "BARATH MS, MUGAPPAIR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6969",
            "district": "33",
            "Block": "403",
            "label": "BRITTANIA HS, PADI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "6970",
            "district": "33",
            "Block": "403",
            "label": "C.S.I EWART MATRICULATION HIGHER SECONDARY SCHOOL, ANNA NAGAR WEST EXTN,CHENNAI -101",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6971",
            "district": "33",
            "Block": "403",
            "label": "GOVT (B) HIGH, MUGAPAIR EAST",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6972",
            "district": "33",
            "Block": "403",
            "label": "GOVT (G) HSS, MUGAPAIR EAST",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6973",
            "district": "33",
            "Block": "403",
            "label": "CHENNAI PUBLIC SCHOOL, SECTOR-1",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6974",
            "district": "33",
            "Block": "403",
            "label": "BETHANY HSS, MUGAPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6975",
            "district": "33",
            "Block": "403",
            "label": "RAVINDRA BHARATHI GLOBAL SCHOOL ,MOGAPPAIR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6976",
            "district": "33",
            "Block": "403",
            "label": "GHS, KOLADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6977",
            "district": "33",
            "Block": "403",
            "label": "GOVT HR.SEC.SCH,AYANAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6978",
            "district": "33",
            "Block": "403",
            "label": "DURGAPRASAD VIV.VID, AYANAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "6979",
            "district": "33",
            "Block": "403",
            "label": "GHS, VELAPPANCHAVADI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6980",
            "district": "33",
            "Block": "403",
            "label": "SKDJ HSS, THIRUVERKADU",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6981",
            "district": "33",
            "Block": "403",
            "label": "GOVT HIGHER SECONDARY SCHOOL, ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6982",
            "district": "33",
            "Block": "403",
            "label": "ARULMIGU MEENAKSHI AMMAN HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6983",
            "district": "33",
            "Block": "403",
            "label": "GOVT HSS,MADHURAVOYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6984",
            "district": "33",
            "Block": "403",
            "label": "OUR LADY MATRIC HR SEC SCHOOL ,MADHURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6985",
            "district": "33",
            "Block": "403",
            "label": "GOVT GIRLS HSS,PORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6986",
            "district": "33",
            "Block": "403",
            "label": "GOVT BOYS HSS,PORUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6987",
            "district": "33",
            "Block": "403",
            "label": "LAMECH HSS,VALASARAVAKKAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6988",
            "district": "33",
            "Block": "403",
            "label": "SRI VENIYAMMAAL MATRICULATION SCHOOL, RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6989",
            "district": "33",
            "Block": "403",
            "label": "GOVT HSS,RAMAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6990",
            "district": "33",
            "Block": "403",
            "label": "RAMAKRISHNA VIDHYA NIKETHAN",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6991",
            "district": "33",
            "Block": "403",
            "label": "GOVT HS VANAGARAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "6992",
            "district": "33",
            "Block": "403",
            "label": "ZAMAR MATRICULATION HR.SEC.SCHOOL, AYAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6993",
            "district": "33",
            "Block": "403",
            "label": "BHAKTAVATSALAM VID,KORATTUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6994",
            "district": "33",
            "Block": "403",
            "label": "MUNICIPAL HIGHER SECONDARY SCHOOL,ATHIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6995",
            "district": "33",
            "Block": "403",
            "label": "AVADI MUNICIPAL HIGHER SECONDARY SCHOOL,THIRUMULLAVOYAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20897",
            "district": "33",
            "Block": "403",
            "label": "GOOD WORD PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20927",
            "district": "33",
            "Block": "403",
            "label": "VASANTHAM SPL SCH",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20933",
            "district": "33",
            "Block": "403",
            "label": "HOPE TRUST AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20934",
            "district": "33",
            "Block": "403",
            "label": "BALAVIGASH SPL SCH AVADI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20935",
            "district": "33",
            "Block": "403",
            "label": "CARMEL SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20936",
            "district": "33",
            "Block": "403",
            "label": "JAYAM SPL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "20937",
            "district": "33",
            "Block": "403",
            "label": "SRI VENKATESWARA VIDHYALAYA SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20938",
            "district": "33",
            "Block": "403",
            "label": "VEL TECH Dr RR & Dr SR MAT HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20939",
            "district": "33",
            "Block": "403",
            "label": "MANASA SPL SC",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "20940",
            "district": "33",
            "Block": "403",
            "label": "SATHYALOK SCH FOR SPL CHILDREN(MR)",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21005",
            "district": "33",
            "Block": "403",
            "label": "ANNAI TERASA MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21048",
            "district": "33",
            "Block": "403",
            "label": "AIR FORCE SCHOOL AVADI",
            "Sch_type": "Central Govt",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21173",
            "district": "33",
            "Block": "403",
            "label": "PON VIDYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21174",
            "district": "33",
            "Block": "403",
            "label": "DEVI ACAD SENIOR SEC SCH,VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21251",
            "district": "33",
            "Block": "403",
            "label": "OCF CENTRAL GOVERMENT SCHOOL",
            "Sch_type": "Central Govt",
            "Category": "High School"
        },
        {
            "key": "21255",
            "district": "33",
            "Block": "403",
            "label": "SRI DEVI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21397",
            "district": "33",
            "Block": "403",
            "label": "GOVT GIRLS HIGHER SECONDARY SCHOOL, AYAPPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21398",
            "district": "33",
            "Block": "403",
            "label": "GHS , KARLAPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21563",
            "district": "33",
            "Block": "403",
            "label": "AMHS KOVILPATHAGAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21698",
            "district": "33",
            "Block": "403",
            "label": "THE NAZARETH ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21699",
            "district": "33",
            "Block": "403",
            "label": "VIJAYANTA MODEL HR SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21700",
            "district": "33",
            "Block": "403",
            "label": "ANNAI VIOLET INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21701",
            "district": "33",
            "Block": "403",
            "label": "EBENEZER MARCUS INTERNATIONAL SCHOOL & JR. COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21702",
            "district": "33",
            "Block": "403",
            "label": "ARULMIGU MEENAKSHIAMMAN PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21703",
            "district": "33",
            "Block": "403",
            "label": "V.N.R. VIVEKANANDA VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21704",
            "district": "33",
            "Block": "403",
            "label": "LA CHATELAINE JUNIOR COLLEGE",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21941",
            "district": "33",
            "Block": "403",
            "label": "NETHRODAYA SPECIAL SCHOOL FOR VI & LD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21942",
            "district": "33",
            "Block": "403",
            "label": "NARENDRA MATRIC SCHOOL,KOVILPATHAGAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21943",
            "district": "33",
            "Block": "403",
            "label": "BHAKTAATSALAM SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21944",
            "district": "33",
            "Block": "403",
            "label": "NAZARETH MHSS, KANNADAPALAYAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21952",
            "district": "33",
            "Block": "403",
            "label": "AJAY SPECIAL SCHOOL, COLLECTOR NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21988",
            "district": "33",
            "Block": "403",
            "label": "GREAT HARVEST VIDYALAYA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21989",
            "district": "33",
            "Block": "403",
            "label": "GODSON UDAYA MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21990",
            "district": "33",
            "Block": "403",
            "label": "PASUMPON NARAYANA e-TECHNO SCHOOL, AMBATTUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21991",
            "district": "33",
            "Block": "403",
            "label": "SPARTAN EXCLUSIVE SCHOOL , MOGAPPAIR EAST",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21992",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA OLYMPIAD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21993",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDYALAYA, MELAYANAMBAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21994",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA E-TECHNO SCH , VELLAPAN CHAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21995",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA OLYMPIAD SCHOOL, OMSAKTHINAGAR, VANAGARAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21996",
            "district": "33",
            "Block": "403",
            "label": "DAYASADAN AGARWAL VIDYALAYA, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21997",
            "district": "33",
            "Block": "403",
            "label": "ADW HS, MADHURAVOYAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21998",
            "district": "33",
            "Block": "403",
            "label": "EVERWIN PUBLIC SCHOOL, MADURAVOYAL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21999",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDYALAYA, ALAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22000",
            "district": "33",
            "Block": "403",
            "label": "MADHA PUBLIC SCHOOL, VALASARAVAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22313",
            "district": "33",
            "Block": "403",
            "label": "GHS, VEERAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22314",
            "district": "33",
            "Block": "403",
            "label": "RAVINDRA BHARATHI CBSE SCHOOL, NERKUNDRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22315",
            "district": "33",
            "Block": "403",
            "label": "THE SCHRAM ACADAMY NOLAMBUR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22316",
            "district": "33",
            "Block": "403",
            "label": "AMBATTUR MUNICIPAL HIGH SCHOOL KAMARAJAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22317",
            "district": "33",
            "Block": "403",
            "label": "BRIGHT THE LEARNING CENTER",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22318",
            "district": "33",
            "Block": "403",
            "label": "SANKALP - THE LEARNING CENTER",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22319",
            "district": "33",
            "Block": "403",
            "label": "MUN HIGH SCH, PADI KUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22320",
            "district": "33",
            "Block": "403",
            "label": "SRISHTI SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22321",
            "district": "33",
            "Block": "403",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22322",
            "district": "33",
            "Block": "403",
            "label": "THE SCRAM ACADEMY AYANAMPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22323",
            "district": "33",
            "Block": "403",
            "label": "ACHARIYA SIKSHA MANDIR , ALAPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22324",
            "district": "33",
            "Block": "403",
            "label": "VEDANTA ACADEMY SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22325",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDYALAYA (ANNEXURE) SR.SEC.SCHOOL. MEL AYANAMBAKKAM, CHENNAI 95.",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22326",
            "district": "33",
            "Block": "403",
            "label": "MANGALAM VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22720",
            "district": "33",
            "Block": "403",
            "label": "SCOPE GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22721",
            "district": "33",
            "Block": "403",
            "label": "ADW HS, VALASARA VAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22726",
            "district": "33",
            "Block": "403",
            "label": "ALPHA CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22762",
            "district": "33",
            "Block": "403",
            "label": "SRI CHITHANYA SCHOOL, NOLAMBUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22877",
            "district": "33",
            "Block": "403",
            "label": "MUNICIPAL HIGH SCHOOL, KALLIKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22878",
            "district": "33",
            "Block": "403",
            "label": "GHS METTUKUPPAM (N)",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22953",
            "district": "33",
            "Block": "403",
            "label": "SRI CHAITANYA SCHOOL, RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23001",
            "district": "33",
            "Block": "403",
            "label": "SRI VIMALA VIDYALAYA CBSE",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23118",
            "district": "33",
            "Block": "403",
            "label": "THE LEO ACADEMY OF EXCELLENCE (CBSE)",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23134",
            "district": "33",
            "Block": "403",
            "label": "NARAYANA E-TECHNO SCHOOL,RAGHAVENDRA NAGAR,RAMAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23147",
            "district": "33",
            "Block": "403",
            "label": "VELAMMAL VIDYALAYA PORUR CHENNAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23190",
            "district": "33",
            "Block": "403",
            "label": "RUDRAPPASAMY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23215",
            "district": "33",
            "Block": "403",
            "label": "SWAMY'S SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23261",
            "district": "33",
            "Block": "403",
            "label": "D.A.V SECONDARY SCHOOL, MOGAPPAIR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23269",
            "district": "33",
            "Block": "403",
            "label": "MARIAN SCHOOL OF EXCELLENCE",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23335",
            "district": "33",
            "Block": "403",
            "label": "GREEN VALLEY CENTRAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23336",
            "district": "33",
            "Block": "403",
            "label": "M.G.R. ADARSH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23417",
            "district": "33",
            "Block": "403",
            "label": "PRS Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23417",
            "district": "33",
            "Block": "403",
            "label": "PRS Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23417",
            "district": "33",
            "Block": "403",
            "label": "PRS Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23611",
            "district": "33",
            "Block": "403",
            "label": "AACHI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23612",
            "district": "33",
            "Block": "403",
            "label": "KALAIMAGAL VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23613",
            "district": "33",
            "Block": "403",
            "label": "BETHEL GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23614",
            "district": "33",
            "Block": "403",
            "label": "MAR GREGORIOS PUBLIC SCHOOL, MOGAPPAIR , WEST,SECTOR-7",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23615",
            "district": "33",
            "Block": "403",
            "label": "CPS GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23616",
            "district": "33",
            "Block": "403",
            "label": "SV HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23617",
            "district": "33",
            "Block": "403",
            "label": "ABS GLOBAL SMART SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23417",
            "district": "33",
            "Block": "403",
            "label": "PRS Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23611",
            "district": "33",
            "Block": "403",
            "label": "AACHI GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23612",
            "district": "33",
            "Block": "403",
            "label": "KALAIMAGAL VIDHYASHRAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23613",
            "district": "33",
            "Block": "403",
            "label": "BETHEL GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23614",
            "district": "33",
            "Block": "403",
            "label": "MAR GREGORIOS PUBLIC SCHOOL, MOGAPPAIR , WEST,SECTOR-7",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23615",
            "district": "33",
            "Block": "403",
            "label": "CPS GLOBAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23616",
            "district": "33",
            "Block": "403",
            "label": "SV HIGH SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23617",
            "district": "33",
            "Block": "403",
            "label": "ABS GLOBAL SMART SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24078",
            "district": "33",
            "Block": "403",
            "label": "GOOD SHEPHERDS GLOBAL MATRIC SCHOOL, METTUPALAYAM",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24079",
            "district": "33",
            "Block": "403",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24080",
            "district": "33",
            "Block": "403",
            "label": "ABS GLOBAL SMART SCHOOL ,  ANNANUR",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24081",
            "district": "33",
            "Block": "403",
            "label": "TI SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24082",
            "district": "33",
            "Block": "403",
            "label": "MAHARISHI VIDYA MANDIR NOLAMBUR",
            "Sch_type": "Un-aidedHigher Secondary School"
        }
    ],
    "404": [
        {
            "key": "5279",
            "district": "37",
            "Block": "404",
            "label": "RAMAKRISHNA MISSION VIDYALAYA MATRIC HR SEC SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5312",
            "district": "37",
            "Block": "404",
            "label": "THE CLARIBEL MATRIC SCHOOL MAHARAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6337",
            "district": "37",
            "Block": "404",
            "label": "SARASWATHI MATRICULATION HIGHER SECONDARY SCHOOL VILUPPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6412",
            "district": "37",
            "Block": "404",
            "label": "SRI NATARAJA MIDDLE SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "23652",
            "district": "37",
            "Block": "404",
            "label": "Railway Mixed High School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "6415",
            "district": "37",
            "Block": "404",
            "label": "SACRED HEART CONVENT ANGLO INDIAN HIGHER SECONDARY SCHOOL  VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6620",
            "district": "37",
            "Block": "404",
            "label": "JOHN DEWEY MATRIC HIGHER SECONDARY SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10928",
            "district": "37",
            "Block": "404",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KEELPERUMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24135", "district":"37", 
            "Block":"404", "label": "Achariya Siksha Mandir", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "10929",
            "district": "37",
            "Block": "404",
            "label": "GOVERNMENT NEHRUJI MIDDLE SCHOOL VILLUPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "10930",
            "district": "37",
            "Block": "404",
            "label": "MUNICIPAL HIGHER SECONDARY SCHOOL B.N.THOPE",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10931",
            "district": "37",
            "Block": "404",
            "label": "SACRED HEART CENTRAL SCHOOL  VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10932",
            "district": "37",
            "Block": "404",
            "label": "MRIC RC HIGH SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "10933",
            "district": "37",
            "Block": "404",
            "label": "NEW JOHN DEWEY MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10934",
            "district": "37",
            "Block": "404",
            "label": "MAHATHMA GANDHI HIGH SCHOOL VILLUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10935",
            "district": "37",
            "Block": "404",
            "label": "TAGORE MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10936",
            "district": "37",
            "Block": "404",
            "label": "MUNICIPAL HIGH SCHOOL POONTHOTTAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10937",
            "district": "37",
            "Block": "404",
            "label": "E.S.MATRICULATION HIGHER SECONDARY SCHOOL, VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10938",
            "district": "37",
            "Block": "404",
            "label": "SARADA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10939",
            "district": "37",
            "Block": "404",
            "label": "MUNICIPAL HIGH SCHOOL HOSPITAL ROAD",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "10940",
            "district": "37",
            "Block": "404",
            "label": "ST JOHN SPECIAL HIGHER SECONDARY SCHOOL FOR DIFFERENTLY ABLED  VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10941",
            "district": "37",
            "Block": "404",
            "label": "ST.XAVIER MIDDLE SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10942",
            "district": "37",
            "Block": "404",
            "label": "ST XAVIER MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10943",
            "district": "37",
            "Block": "404",
            "label": "SRI KAMAKOTI ORIENTAL HIGH SCHOOL VILLUPURAM",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "10944",
            "district": "37",
            "Block": "404",
            "label": "SRI VENKATESWARA MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10945",
            "district": "37",
            "Block": "404",
            "label": "RAMAKRISHNA MIDDLE SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10946",
            "district": "37",
            "Block": "404",
            "label": "VPS MATRICULATION SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "10947",
            "district": "37",
            "Block": "404",
            "label": "HAJEE MANSOORSHA ORIENTAL ARABIC HIGH SCHOOL VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "10948",
            "district": "37",
            "Block": "404",
            "label": "TELC MIDDLE SCHOOL COURT ROAD VILLUPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "10949",
            "district": "37",
            "Block": "404",
            "label": "THIRU KAMARAJ MUNICIPAL HIGHER SECONDARY SCHOOL  VILLUPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10950",
            "district": "37",
            "Block": "404",
            "label": "GOVERNMENT MODEL GIRLS HIGHER SECONDARY SCHOOL,VILLUPURAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "10951",
            "district": "37",
            "Block": "404",
            "label": "NARAYANS MATRICULATION HIGHER SECONDARY SCHOOL  VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21227",
            "district": "37",
            "Block": "404",
            "label": "VRP HIGHER SECONDARY SCHOOL VILLUPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23137",
            "district": "37",
            "Block": "404",
            "label": "AKSHARDHAM CENTRAL SCHOOL - VILUPPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        }
    ],
    "405": [
        {
            "key": "949",
            "district": "19",
            "Block": "405",
            "label": "PARUMALA ST.GREGORIOS MATRIC  SCHOOL, MATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1207",
            "district": "19",
            "Block": "405",
            "label": "VIVEGA MATRIC HIGHER SECONDARY SCHOOL. VIRALIMALAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3132",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL KULAVAIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5547",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL MEBOOTHAGUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5631",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL , PORUVAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16844",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, LANCHAMEDU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16845",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL, KALKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16846",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KODUMBALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16847",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYT UNION MIDDLE SCHOOL PILIPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16848",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16849",
            "district": "19",
            "Block": "405",
            "label": "ST.THOMAS HIGHER SECONDARY SCHOOL  NAZARETH",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16850",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL,LAKSHMANANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16851",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANDAIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16852",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MANGUDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16853",
            "district": "19",
            "Block": "405",
            "label": "ST.NORBERT R.C. MIDDLE SCHOOL. MATHUR",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "16854",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(SPL) MATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16855",
            "district": "19",
            "Block": "405",
            "label": "SACRED HEART SCHOOL CBSE  MATHUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16856",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL MEENAVELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16857",
            "district": "19",
            "Block": "405",
            "label": "PREMANANDA HIGH SCHOOL MELAPATCHKUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16858",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NAMBAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16859",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT MODEL HIGHER SECONDARY SCHOOL, KAVARAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16860",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL NEERPALANI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16861",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. SEETHAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16862",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL SEVALPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16863",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL RAJAGIRI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16864",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL, RAJALIPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "16865",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL ,SOORIYUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16866",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. THENGAITHINNIPPATTY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16867",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THENNAMPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16868",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL THERAVOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16869",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VELLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16870",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NARIAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16871",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL VILLAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16872",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, VIRALIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16873",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL(B) VIRALIMALAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16874",
            "district": "19",
            "Block": "405",
            "label": "ST TERESA'S GIRLS HIGH SCHOOL VIRALIMALAI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "16875",
            "district": "19",
            "Block": "405",
            "label": "PROVIDENCE CONVENT MATRICULATION SCHOOL, VIRALIMALAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "16876",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL. RAMAKKAVUNDANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16877",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYT UNION MIDDLE SCHOOL SERALAPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16878",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, AVOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "16879",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL NADUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "16880",
            "district": "19",
            "Block": "405",
            "label": "PANCHAYAT UNION MIDDLE SCHOOL, THONDAIMANNALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21980",
            "district": "19",
            "Block": "405",
            "label": "CHELLAMMAL MATRICULATION HIGHER SECONDARY SCHOOL, KUNNATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22230",
            "district": "19",
            "Block": "405",
            "label": "SFS. PUBLIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22585",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL AKKALNAYAKKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22586",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL MALAMPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22587",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL ATHIPALLAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22914",
            "district": "19",
            "Block": "405",
            "label": "GOVERNMENT HIGH SCHOOL PAKKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "406": [
        {
            "key": "373",
            "district": "5",
            "Block": "406",
            "label": "INFANT PREPARATORY MATRICULATION HIGHER SECONDARY SCHOOL-VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3951",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL ALICHIKUDI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4238",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL KODUKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4904",
            "district": "5",
            "Block": "406",
            "label": "MUNICIPAL MIDDLE SCHOOL SOUTH FORT STREET-VRIDHACHALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5435",
            "district": "5",
            "Block": "406",
            "label": "MUNICIPAL MIDDLE SCHOOL BOODHAMUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5755",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL SEMBALAKURICHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5915",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL RAJAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5918",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL MANGALAMPETTAI ( MUSLIM )",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6044",
            "district": "5",
            "Block": "406",
            "label": "HINDU AIDED MIDDLE SCHOOL IRUSALAKUPPAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6239",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL M.AGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6424",
            "district": "5",
            "Block": "406",
            "label": "FATIMA MATRICULATION HIGHER SECONDARY SCHOOL VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6442",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL T.V. PUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6580",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL KUPPANATHAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6606",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL SATHIYAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15350",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- MUGASAPARUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15351",
            "district": "5",
            "Block": "406",
            "label": "ST. PAUL MATRICULATION HIGHER SECONDARY SCHOOL, MANGALAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15352",
            "district": "5",
            "Block": "406",
            "label": "ANNAI HATHEEJA GIRLS HIGH SCHOOL,  MANGALAMPETTAI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15353",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL MANGALAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15354",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL- MANGALAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15355",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL PULIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15356",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL- ALADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15357",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL MATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15358",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL PERIYAVADAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23670",
            "district": "5",
            "Block": "406",
            "label": "BVB Matriculation Hr.Sec.School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "15359",
            "district": "5",
            "Block": "406",
            "label": "SENTHIL MATRICULATION HIGHER SECONDARY SCHOOL PERIYAVADAVADI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15360",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL KO-PUVANUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15361",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL PUDHUKOORAIPETTAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15362",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL- EDAICHITHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15363",
            "district": "5",
            "Block": "406",
            "label": "ST. AGNES HIGH SCHOOL VEERAREDDIKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15364",
            "district": "5",
            "Block": "406",
            "label": "V.E.T. HIGHER SECONDARY SCHOOL ERUMANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15365",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL ERUMANUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15366",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL CHINNAVADAVADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15367",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL M. PATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15368",
            "district": "5",
            "Block": "406",
            "label": "AIDED MIDDLE SCHOOL  PUDUVILANGULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15369",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL PARAVALUR (SOUTH)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15370",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL THORAVALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15371",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL KARUVEPPILANKURICHI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15372",
            "district": "5",
            "Block": "406",
            "label": "PANCHAYAT UNION MIDDLE  SCHOOL VILANKATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15373",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL RAJENDRAPATTINAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15374",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL VANNANKUDIKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "15375",
            "district": "5",
            "Block": "406",
            "label": "DANISH MISSION MIDDLE  SCHOOL KASPA",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "15376",
            "district": "5",
            "Block": "406",
            "label": "KRISHNASAMY AIDED MIDDLE SCHOOL NATCHIYARPETTAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "15377",
            "district": "5",
            "Block": "406",
            "label": "MUNICIPAL MIDDLE SCHOOL PUDUPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "15378",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT MODEL GIRLS HIGHER SECONDARY SCHOOL ,VRIDDHACHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15379",
            "district": "5",
            "Block": "406",
            "label": "BABY MATRICULATION SCHOOL VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15380",
            "district": "5",
            "Block": "406",
            "label": "SRI SARASWATHI VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15381",
            "district": "5",
            "Block": "406",
            "label": "SAKTHI MATRICULATION HIGHER SECONDARY SCHOOL, PERIYAR NAGAR,VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15382",
            "district": "5",
            "Block": "406",
            "label": "DANISH MISSION HIGHER SECONDARY SCHOOL VRIDDHACHALAM",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15383",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT BOYS HIGHER SECONDARY SCHOOL VRIDDHACHALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15384",
            "district": "5",
            "Block": "406",
            "label": "SRI SARADA VIDYALAYA MATRICULATION SCHOOL, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15385",
            "district": "5",
            "Block": "406",
            "label": "DEVI MATRIC HR SEC  SCHOOL-VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15386",
            "district": "5",
            "Block": "406",
            "label": "NIRMALA CREATIVE MATRICULATION SCHOOL- ANNA NAGAR, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "15387",
            "district": "5",
            "Block": "406",
            "label": "MAHARISHI VIDYA MANDIR  MATRICULATION HIGHER SECONDARY SCHOOL, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15388",
            "district": "5",
            "Block": "406",
            "label": "KSR HI-TECH CBSE SCHOOL- VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "15389",
            "district": "5",
            "Block": "406",
            "label": "R.C.AIDED MIDDLE SCHOOL KOVILANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "21095",
            "district": "5",
            "Block": "406",
            "label": "JAYAPRIYA VIDYALAYA SENIOR SECONDARY SCHOOL  CBSE, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21096",
            "district": "5",
            "Block": "406",
            "label": "SRI VIDHYA MANDHIR MATRICULATION SCHOOL,  VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21688",
            "district": "5",
            "Block": "406",
            "label": "ANTONY PUBLIC  SENIOR SEC SCHOOL-CUDDALORE MAIN ROAD ,PUDHUKURAIPETTAI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21890",
            "district": "5",
            "Block": "406",
            "label": "VIRUTHAI VIKAS MATRICULATION  HIGHER SECONDARY SCHOOL -MANALUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21962",
            "district": "5",
            "Block": "406",
            "label": "P.M.S. HIGHER SECONDARY SCHOOL FOR THE SPEECH & HEARING IMPAIRED KONANKUPPAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22204",
            "district": "5",
            "Block": "406",
            "label": "JESSICA SPECIAL SCHOOL FOR THE INTELLECTUAL DISABLED- ALADI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22552",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL-KO.PAVAZHANGUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22553",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL SATHUKOODAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22554",
            "district": "5",
            "Block": "406",
            "label": "VIRUDHAI  VIDHYALAYA MATRICULATION HIGHER SECONDARY SCHOOL, VRIDDHACHALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24083",
            "district": "5",
            "Block": "406",
            "label": "GOVERNMENT HIGH SCHOOL MATHUR",
            "Sch_type": "GovernmentHigh School"
        }
    ],
    "407": [
        {
            "key": "1803",
            "district": "38",
            "Block": "407",
            "label": "MSPN MUNICIPAL MIDDLE SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2345",
            "district": "38",
            "Block": "407",
            "label": "VMGRR SARADHA SM HSS, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2896",
            "district": "38",
            "Block": "407",
            "label": "KVS MATRICULATION HSS SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3219",
            "district": "38",
            "Block": "407",
            "label": "DHAMU MEMORIAL MATRICULATION SCHOOL, SEVALPATTI",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4531",
            "district": "38",
            "Block": "407",
            "label": "P.S.T.M.SEVENTH DAY ADVENTISTS MATRIC HSS VIRUTHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6273",
            "district": "38",
            "Block": "407",
            "label": "SRI VIDHYA MATRICULATION SCHOOL, P.KUMARALINGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18706",
            "district": "38",
            "Block": "407",
            "label": "KVS MIDDLE SCHOOL, SENGUNDRAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18707",
            "district": "38",
            "Block": "407",
            "label": "PUMS,MOOLIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18708",
            "district": "38",
            "Block": "407",
            "label": "HINDU MIDDLE SCHOOL, P.KUMARALINGAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18709",
            "district": "38",
            "Block": "407",
            "label": "GHS, PAVALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18710",
            "district": "38",
            "Block": "407",
            "label": "GHS,VADAMALAIKURICHI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18711",
            "district": "38",
            "Block": "407",
            "label": "GHSS CHATRAREDDIAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18712",
            "district": "38",
            "Block": "407",
            "label": "GHS,PERIA PERALI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18713",
            "district": "38",
            "Block": "407",
            "label": "PUMS,PELAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18714",
            "district": "38",
            "Block": "407",
            "label": "PUMS,CHINNAMOOPANPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18715",
            "district": "38",
            "Block": "407",
            "label": "KKSSN MEMORIAL MIDDLE SCHOOL ROSALPATTI",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "18716",
            "district": "38",
            "Block": "407",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MEESALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18717",
            "district": "38",
            "Block": "407",
            "label": "GHSS AMATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18718",
            "district": "38",
            "Block": "407",
            "label": "PUMS, THATHAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18719",
            "district": "38",
            "Block": "407",
            "label": "PUMS MARULOOTHU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18720",
            "district": "38",
            "Block": "407",
            "label": "GHS,INAMREDDIAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18721",
            "district": "38",
            "Block": "407",
            "label": "PUMS O.KOVILPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18722",
            "district": "38",
            "Block": "407",
            "label": "GHSS, SANKARALINGAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18723",
            "district": "38",
            "Block": "407",
            "label": "ST.XAVIER'S HS K.PUDUR",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18724",
            "district": "38",
            "Block": "407",
            "label": "GOVT HR SEC SCHOOL THIYAGARAJAPURAM -MODEL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18725",
            "district": "38",
            "Block": "407",
            "label": "GHS,PATTAMPUDUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18726",
            "district": "38",
            "Block": "407",
            "label": "GHSS, METTUKUNDU",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18727",
            "district": "38",
            "Block": "407",
            "label": "HINDU MIDDLE SCHOOL, KADAMBANKULAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18728",
            "district": "38",
            "Block": "407",
            "label": "PUMS KUPPAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18729",
            "district": "38",
            "Block": "407",
            "label": "PUMS THADANGAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18730",
            "district": "38",
            "Block": "407",
            "label": "GHSS, AVUDAIYAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18731",
            "district": "38",
            "Block": "407",
            "label": "MCNUPDA HSS M.CHINNAIYAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18732",
            "district": "38",
            "Block": "407",
            "label": "PUMS,A.PUDHUPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18733",
            "district": "38",
            "Block": "407",
            "label": "GHSS, VEERARPATTI APPAYANAYAKENPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18734",
            "district": "38",
            "Block": "407",
            "label": "GHSS, NADUVAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18735",
            "district": "38",
            "Block": "407",
            "label": "SMG MUNICIPAL MS VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18736",
            "district": "38",
            "Block": "407",
            "label": "MUNICIPAL MUSLIM MIDDLE SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18737",
            "district": "38",
            "Block": "407",
            "label": "MUNICIPAL MIDDLE SCHOOL,RAMAMOORTHY ROAD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18738",
            "district": "38",
            "Block": "407",
            "label": "MUNICIPAL MIDDLE SCHOOL, SIVAN KOVIL ST, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18739",
            "district": "38",
            "Block": "407",
            "label": "K.C.S.DHANUSHKODI NADAR  MUNICIPAL MIDDLE SCHOOL,VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18740",
            "district": "38",
            "Block": "407",
            "label": "KVS MIDDLE SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18741",
            "district": "38",
            "Block": "407",
            "label": "KPMJHS HOWA BIBI MS, VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18742",
            "district": "38",
            "Block": "407",
            "label": "TELC MS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18743",
            "district": "38",
            "Block": "407",
            "label": "HAJEE P SYED MM SCHOOL VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18744",
            "district": "38",
            "Block": "407",
            "label": "KGMS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18745",
            "district": "38",
            "Block": "407",
            "label": "SVA MS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18746",
            "district": "38",
            "Block": "407",
            "label": "K KAMARAJ VIDHYASALA MS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18747",
            "district": "38",
            "Block": "407",
            "label": "THIRUVALLUVAR VIDHYASALA MIDDLE SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18748",
            "district": "38",
            "Block": "407",
            "label": "AP SANKARALINGA NADAR GIRLS MIDDLE SCHOOL VNR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18749",
            "district": "38",
            "Block": "407",
            "label": "VPP HIGHER SECONDARY SCHOOL, Virudhunagar",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18750",
            "district": "38",
            "Block": "407",
            "label": "SSSN GOVT HR SEC SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18751",
            "district": "38",
            "Block": "407",
            "label": "TPNM GIRLS HIGHER SECONDARY SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18752",
            "district": "38",
            "Block": "407",
            "label": "HAJEE P SYED M HSS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18753",
            "district": "38",
            "Block": "407",
            "label": "SOWDAMBIGAI HSS  VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18754",
            "district": "38",
            "Block": "407",
            "label": "KGHSS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18755",
            "district": "38",
            "Block": "407",
            "label": "VKMJHSHB GIRLS HR SEC SCHOOL",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18756",
            "district": "38",
            "Block": "407",
            "label": "RC HSS VIRUDHUNAGAR",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18757",
            "district": "38",
            "Block": "407",
            "label": "KVS HSS VIRUDHUNAGAR",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18758",
            "district": "38",
            "Block": "407",
            "label": "P S CHIDAMBARA NADAR SENIOR ENGLISH SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18759",
            "district": "38",
            "Block": "407",
            "label": "VIRUDHUNAGAR MATRICULATION SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "18760",
            "district": "38",
            "Block": "407",
            "label": "KSHATRIYA VIDHYASALA CENTENARY SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18761",
            "district": "38",
            "Block": "407",
            "label": "RAO BAHADUR MSPS MUNICIPAL HIGH SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18763",
            "district": "38",
            "Block": "407",
            "label": "ASPCC MUNICIPAL HS,VIRUDHUNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18764",
            "district": "38",
            "Block": "407",
            "label": "SARASWATHI AMMAL LIONS MATRICULATON SCHOOL, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21109",
            "district": "38",
            "Block": "407",
            "label": "KSHATRIYA VIDHYASALA ENGLISH MEDIUM SCHOOL , KOORAIKUNDU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21339",
            "district": "38",
            "Block": "407",
            "label": "RAMCO VIDYALAYA, R.R.NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22264",
            "district": "38",
            "Block": "407",
            "label": "WISDOM WEALTH INTERNATIONAL SCHOOL, MARULOOTHU",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22618",
            "district": "38",
            "Block": "407",
            "label": "SHRI VISHWAKARMA SEVA VIDYALAYA, VIRUDHUNAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22619",
            "district": "38",
            "Block": "407",
            "label": "G.H.S.,SENNELKUDI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23343",
            "district": "38",
            "Block": "407",
            "label": "SFS MAT SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Others"
        },
        {
            "key": "24084",
            "district": "38",
            "Block": "407",
            "label": "PUMS KUPPAMPATTI",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24085",
            "district": "38",
            "Block": "407",
            "label": "PUMS THADANGAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "408": [
        {
            "key": "568",
            "district": "21",
            "Block": "408",
            "label": "GRACE MATRIC HSS, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "592",
            "district": "21",
            "Block": "408",
            "label": "SRI RAMAKRISHNA MATRIC HSS, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1126",
            "district": "21",
            "Block": "408",
            "label": "MANGALAMBAL AIDED MS WALAJAH",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "1384",
            "district": "21",
            "Block": "408",
            "label": "GANGAADARAA MATRIC HSS, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1443",
            "district": "21",
            "Block": "408",
            "label": "OUR LADY'S MATRIC SCHOOL, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "1460",
            "district": "21",
            "Block": "408",
            "label": "SRI VENKATESWARA MATRIC SCHOOL, WALAJAPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2302",
            "district": "21",
            "Block": "408",
            "label": "GANDHI MIS VI AIDED M S WALAJAPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "2430",
            "district": "21",
            "Block": "408",
            "label": "CNR MATRIC SCHOOL, WALAJA",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "2677",
            "district": "21",
            "Block": "408",
            "label": "MMS SRINIVASANPET RANIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2817",
            "district": "21",
            "Block": "408",
            "label": "NATIONAL MATRIC SCHOOL, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "2887",
            "district": "21",
            "Block": "408",
            "label": "GANGADHARA MU AIDED MS RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "3174",
            "district": "21",
            "Block": "408",
            "label": "THIRUMATHI LAKSHMI LOGANATHAN MATRIC SCHOOL, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "3765",
            "district": "21",
            "Block": "408",
            "label": "MMS MARKET  WALAJAH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3922",
            "district": "21",
            "Block": "408",
            "label": "VEDANIKETHAN MATRIC HSS, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "4022",
            "district": "21",
            "Block": "408",
            "label": "THAMIZH THENRAL THIRU VI-KA MIDDLE SCHOOL RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4070",
            "district": "21",
            "Block": "408",
            "label": "SEVENTHDAY ADVENTIST MATRIC SCHOOL, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "4554",
            "district": "21",
            "Block": "408",
            "label": "GANGADHARA AMS ARCOT",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "4767",
            "district": "21",
            "Block": "408",
            "label": "MMS KARAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4925",
            "district": "21",
            "Block": "408",
            "label": "AMS SRI KRISHNA VIDHYALAYA RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5029",
            "district": "21",
            "Block": "408",
            "label": "SRI ANDAL AMS ARCOT",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "5215",
            "district": "21",
            "Block": "408",
            "label": "LFC GIRLS AIDED HSS RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5765",
            "district": "21",
            "Block": "408",
            "label": "SHANTHINIKETHAN MATRICULATION SCHOOL  ARCOT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5997",
            "district": "21",
            "Block": "408",
            "label": "BEATTIE MEMORIAL MATRIC SCHOOL, RANIPET",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6005",
            "district": "21",
            "Block": "408",
            "label": "ANNAI SARADA MATRIC HSS, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8569",
            "district": "21",
            "Block": "408",
            "label": "MMS ANNA NAGAR ARCOT",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8570",
            "district": "21",
            "Block": "408",
            "label": "MHS THOPPUKANA NORTH ARCOT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8571",
            "district": "21",
            "Block": "408",
            "label": "GOVT BOYS HSS ARCOT",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8573",
            "district": "21",
            "Block": "408",
            "label": "GVC AIDED HSS ARCOT",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8574",
            "district": "21",
            "Block": "408",
            "label": "GOVT  HIGHER SECONDARY SCHOOL , RANIPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8575",
            "district": "21",
            "Block": "408",
            "label": "GOVT ADW HSS KARAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8576",
            "district": "21",
            "Block": "408",
            "label": "GOVT THEERAN SATH MS RANIPET",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8577",
            "district": "21",
            "Block": "408",
            "label": "VRV AIDED HSS RANIPET",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8578",
            "district": "21",
            "Block": "408",
            "label": "MMS MIXED  WALAJAH",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8579",
            "district": "21",
            "Block": "408",
            "label": "GOVT GIRLS HSS WALAJAPET",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8580",
            "district": "21",
            "Block": "408",
            "label": "MHS THOPUKANA SOUTH ARCOT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8581",
            "district": "21",
            "Block": "408",
            "label": "GOVT BOYS HSS VANNIVEDU WALAJA",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22737",
            "district": "21",
            "Block": "408",
            "label": "S.S.S.MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23537",
            "district": "21",
            "Block": "408",
            "label": "MERCURY MATRICULATION SCHOOL, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23537",
            "district": "21",
            "Block": "408",
            "label": "MERCURY MATRICULATION SCHOOL, ARCOT",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24086",
            "district": "21",
            "Block": "408",
            "label": "RANIPET DISTRICT GOVERNMENT MODEL SCHOOL ",
            "Sch_type": "GovernmentHigher Secondary School"
        }
    ],
    "409": [
        {
            "key": "625",
            "district": "21",
            "Block": "409",
            "label": "HINDU VIDYALAYA - CBSE SCHOOL SIPCOT",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "870",
            "district": "21",
            "Block": "409",
            "label": "CSI VELLORE DIOCESE MATRIC HSS , SIPCOT",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1546",
            "district": "21",
            "Block": "409",
            "label": "VEDAVALLI VIDYALAYA SENIOR SECONDARY SCHOOL, WALAJAPET",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1715",
            "district": "21",
            "Block": "409",
            "label": "RISHI MATRIC HSS, SEEKARAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2734",
            "district": "21",
            "Block": "409",
            "label": "ANNE MARIE MATRIC HSS, THIRUPARKADAL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3255",
            "district": "21",
            "Block": "409",
            "label": "PUMS V.C.MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4001",
            "district": "21",
            "Block": "409",
            "label": "VKV MATRIC SCHOOL, VANNIVEDU",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "5110",
            "district": "21",
            "Block": "409",
            "label": "PUMS NARASINGAPURAM / AMMOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "6092",
            "district": "21",
            "Block": "409",
            "label": "SUNBEAM SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6099",
            "district": "21",
            "Block": "409",
            "label": "SUNBEAM MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6246",
            "district": "21",
            "Block": "409",
            "label": "LITTLE FLOWER CONVENT MATRIC HSS AMMOOR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6400",
            "district": "21",
            "Block": "409",
            "label": "BRIGHT MINDS VIDYODAYA",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6457",
            "district": "21",
            "Block": "409",
            "label": "PUMS RAJIVGANDHI NAGAR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8541",
            "district": "21",
            "Block": "409",
            "label": "PUMS VASUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8542",
            "district": "21",
            "Block": "409",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8543",
            "district": "21",
            "Block": "409",
            "label": "GOVT. HR.SEC.SCHOOL VALLUVAMPAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8544",
            "district": "21",
            "Block": "409",
            "label": "PUMS SENGADU MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8545",
            "district": "21",
            "Block": "409",
            "label": "GOVT HSS AMMOOR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8546",
            "district": "21",
            "Block": "409",
            "label": "PUMS KALPUDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8547",
            "district": "21",
            "Block": "409",
            "label": "GOVT HSS LALAPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8548",
            "district": "21",
            "Block": "409",
            "label": "L R SCUDDER HS EKAMBARANALLORE",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "8549",
            "district": "21",
            "Block": "409",
            "label": "PUMS SEEKARAJAPURAM MOTTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8550",
            "district": "21",
            "Block": "409",
            "label": "PUMS NARASINGAPURAM / THIRUVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8551",
            "district": "21",
            "Block": "409",
            "label": "SRI RAMAKRISHNA BHEL HR SEC SCHOOL, RANIPET,VELLORE",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8552",
            "district": "21",
            "Block": "409",
            "label": "DAV  BHEL HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8553",
            "district": "21",
            "Block": "409",
            "label": "ST JOHNS MHS SEEKARAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8554",
            "district": "21",
            "Block": "409",
            "label": "PUMS THENGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8555",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS SIPCOT",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8556",
            "district": "21",
            "Block": "409",
            "label": "GOVT H S S, PULLIYANKANNU.",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8557",
            "district": "21",
            "Block": "409",
            "label": "AMS VANAPADI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8558",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS CHETTITHANGAL",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8559",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS VANNIVEDU MOTTUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8560",
            "district": "21",
            "Block": "409",
            "label": "BALSAM ACADEMY",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "8561",
            "district": "21",
            "Block": "409",
            "label": "PM AIDED MS ANANTHALAI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "8562",
            "district": "21",
            "Block": "409",
            "label": "PUMS THENKADAPENTHANGAL",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8563",
            "district": "21",
            "Block": "409",
            "label": "PUMS CHENNASAMUTHARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8564",
            "district": "21",
            "Block": "409",
            "label": "GOVT HR. SEC. SCHOOL POONDI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "8565",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS BAGAVELI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8566",
            "district": "21",
            "Block": "409",
            "label": "GOVT HS SUMAITHANGI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "8567",
            "district": "21",
            "Block": "409",
            "label": "PUMS KADAPERI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "8568",
            "district": "21",
            "Block": "409",
            "label": "SD GOVT HSS THIRUPARKADAL",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21178",
            "district": "21",
            "Block": "409",
            "label": "VEDAVALLI VIDYALAYA (NPS) SENIOR SECONDARY SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21569",
            "district": "21",
            "Block": "409",
            "label": "VEDAVALLI HIGHER SEC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21680",
            "district": "21",
            "Block": "409",
            "label": "THE GEEKAY WORLD SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22061",
            "district": "21",
            "Block": "409",
            "label": "CHRIST THE KING CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22742",
            "district": "21",
            "Block": "409",
            "label": "THE GEEKAY WORLD (IGCSE)",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22747",
            "district": "21",
            "Block": "409",
            "label": "WISDOM MATRICULATION SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22949",
            "district": "21",
            "Block": "409",
            "label": "GHS, GUDIMALLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23226",
            "district": "21",
            "Block": "409",
            "label": "GHS (URDU), KALMELKUPPAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23538",
            "district": "21",
            "Block": "409",
            "label": "LEADERS VIDHYASHRAM CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23538",
            "district": "21",
            "Block": "409",
            "label": "LEADERS VIDHYASHRAM CBSE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24087",
            "district": "21",
            "Block": "409",
            "label": "PUMS VASUR",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24088",
            "district": "21",
            "Block": "409",
            "label": "PUMS KRISHNAPURAM",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24089",
            "district": "21",
            "Block": "409",
            "label": "PUMS THENGAL",
            "Sch_type": "GovernmentMiddle School"
        },
        {
            "key": "24090",
            "district": "21",
            "Block": "409",
            "label": "WISDOM MATRICULATION SCHOOL, PADIYAMPAKKAM",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24091",
            "district": "21",
            "Block": "409",
            "label": "THE OASIS PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "410": [
        {
            "key": "4971",
            "district": "10",
            "Block": "410",
            "label": "M C A MATRIC HR SEC SCHOOL, PALAYASEEVARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "5252",
            "district": "10",
            "Block": "410",
            "label": "PUMS, PUTHAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5453",
            "district": "10",
            "Block": "410",
            "label": "St.Joseph Matriculation Higher Secondary School, Walajapad",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6097",
            "district": "10",
            "Block": "410",
            "label": "Agathiya Matriculation HSS, Walajabad 631605",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "6301",
            "district": "10",
            "Block": "410",
            "label": "Devi Matriculation School,Vengudi,Walajabad 631605",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "6486",
            "district": "10",
            "Block": "410",
            "label": "RCMMS OTTANTHANGAL",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "6565",
            "district": "10",
            "Block": "410",
            "label": "PUMS AYMICHERY",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7627",
            "district": "10",
            "Block": "410",
            "label": "GHS, VAIYAVOOR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7628",
            "district": "10",
            "Block": "410",
            "label": "PUMS MUTHIYALPETTAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7629",
            "district": "10",
            "Block": "410",
            "label": "PUMS VILLIVALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7630",
            "district": "10",
            "Block": "410",
            "label": "GHSS, NAICKENPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7631",
            "district": "10",
            "Block": "410",
            "label": "GGHSS,EKANAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7632",
            "district": "10",
            "Block": "410",
            "label": "PUMS POOSIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7633",
            "district": "10",
            "Block": "410",
            "label": "PUMS KEEL OTTIVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7634",
            "district": "10",
            "Block": "410",
            "label": "THIRU KANNABIRAN MS PULIAMBAKKAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7635",
            "district": "10",
            "Block": "410",
            "label": "PUMS NATHANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7636",
            "district": "10",
            "Block": "410",
            "label": "VSA GHS, UTHUKKADU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7637",
            "district": "10",
            "Block": "410",
            "label": "PUMS KALIYANOOR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7638",
            "district": "10",
            "Block": "410",
            "label": "Good Shephered Matriculation School, Vaiyavoor",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "7639",
            "district": "10",
            "Block": "410",
            "label": "GHS, ENATHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7640",
            "district": "10",
            "Block": "410",
            "label": "PUMS SITTIYAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7641",
            "district": "10",
            "Block": "410",
            "label": "GHSS, THENNERI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7642",
            "district": "10",
            "Block": "410",
            "label": "ADWMS THALAYAMPATTU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7643",
            "district": "10",
            "Block": "410",
            "label": "GHSS, SINGADIVAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7644",
            "district": "10",
            "Block": "410",
            "label": "PUMS ATTUPUTHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7645",
            "district": "10",
            "Block": "410",
            "label": "PUMS KOOTHIRAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7646",
            "district": "10",
            "Block": "410",
            "label": "GHS KARAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7647",
            "district": "10",
            "Block": "410",
            "label": "PUMS SIRUVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7648",
            "district": "10",
            "Block": "410",
            "label": "ADW HSS, INJAMBAKKAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7649",
            "district": "10",
            "Block": "410",
            "label": "PUMS VELIYUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7650",
            "district": "10",
            "Block": "410",
            "label": "CN(AIDED) MS,UVERICHATTRAM",
            "Sch_type": "Partially Aided",
            "Category": "Middle School"
        },
        {
            "key": "7651",
            "district": "10",
            "Block": "410",
            "label": "GHSS, GOVINDAVADI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7652",
            "district": "10",
            "Block": "410",
            "label": "RAMAKRISHNA VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL PADUNELLI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7653",
            "district": "10",
            "Block": "410",
            "label": "GHSS, PULLALUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7654",
            "district": "10",
            "Block": "410",
            "label": "PUMS SAMANTHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7655",
            "district": "10",
            "Block": "410",
            "label": "PUMS KOTTAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7656",
            "district": "10",
            "Block": "410",
            "label": "GHS, PURISAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7657",
            "district": "10",
            "Block": "410",
            "label": "PUMS VALATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7658",
            "district": "10",
            "Block": "410",
            "label": "PUMS KATTUPATTUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7659",
            "district": "10",
            "Block": "410",
            "label": "GHSS, PARANDUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7660",
            "district": "10",
            "Block": "410",
            "label": "PUMS NEERVALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7661",
            "district": "10",
            "Block": "410",
            "label": "PUMS ALAPAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7662",
            "district": "10",
            "Block": "410",
            "label": "PUMS KUNNAVAKKAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7663",
            "district": "10",
            "Block": "410",
            "label": "A.P.N.VIDHYALAYA MATRICULATION HR SEC SCHOOL, VARANAVASI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7664",
            "district": "10",
            "Block": "410",
            "label": "GHS, ULLAVUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7665",
            "district": "10",
            "Block": "410",
            "label": "GHS, DEVERIAMPAKKAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "7666",
            "district": "10",
            "Block": "410",
            "label": "PUMS PALAYASEEVARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7667",
            "district": "10",
            "Block": "410",
            "label": "PUMS, SANKARAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "7668",
            "district": "10",
            "Block": "410",
            "label": "RCM AIDED HS, WALAJABAD",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "7669",
            "district": "10",
            "Block": "410",
            "label": "DSSN(AIDED) MS,WALAJABAD",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "7670",
            "district": "10",
            "Block": "410",
            "label": "ARIGNAR ANNA GOVT BOYS HR SEC SCHOOL WALAJABAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23394",
            "district": "10",
            "Block": "410",
            "label": "GOOD SHEPHARD MATRICULATION SCHOOL",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "7671",
            "district": "10",
            "Block": "410",
            "label": "GGHSS, WALAJABAD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7672",
            "district": "10",
            "Block": "410",
            "label": "W.T.Masilamani Mudaliyar Hr Sec School",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "7673",
            "district": "10",
            "Block": "410",
            "label": "GHSS, IYYAMPETTAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20881",
            "district": "10",
            "Block": "410",
            "label": "SRI JAYENDRA GOLDEN JUBILEE SCHOOL,ENATHUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "20928",
            "district": "10",
            "Block": "410",
            "label": "Sri Sairam Inter. Matric school, Valluvapakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21572",
            "district": "10",
            "Block": "410",
            "label": "Jei Mathajee CBSE School, Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "21580",
            "district": "10",
            "Block": "410",
            "label": "Beta Public School, Injambakkam",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21643",
            "district": "10",
            "Block": "410",
            "label": "Saraswathi Vidyalaya Matric School, Enathur",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22021",
            "district": "10",
            "Block": "410",
            "label": "AMIRTHAM MATRIC SCHOOL,VENGUDI",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22339",
            "district": "10",
            "Block": "410",
            "label": "Mahatma Gandhi Matric School, Kaliyanur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22340",
            "district": "10",
            "Block": "410",
            "label": "Kanchi Vani Vidyalaya, Enathur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22753",
            "district": "10",
            "Block": "410",
            "label": "Asian International Residential School. Palayaseevaram",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23488",
            "district": "10",
            "Block": "410",
            "label": "VELAMMAL BODHI CAMPUS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23488",
            "district": "10",
            "Block": "410",
            "label": "VELAMMAL BODHI CAMPUS",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24092",
            "district": "10",
            "Block": "410",
            "label": "PUMS ALAPAKKAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "411": [
        {
            "key": "2631",
            "district": "38",
            "Block": "411",
            "label": "RANGARAO LIONS GIRLS MATRICULATION SCHOOL, WATRAP",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "18891",
            "district": "38",
            "Block": "411",
            "label": "T.E.L.C .M.S.RAMASAMIPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18892",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S KANSAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18893",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S.NEDUNKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18894",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, KOOMAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18895",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S SETHUNARAYANAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18896",
            "district": "38",
            "Block": "411",
            "label": "Govt A.D.W.MIDDLE SCHOOL AGASAMPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18897",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18898",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MAHARAJAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18899",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S.THAMBIPATTI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18900",
            "district": "38",
            "Block": "411",
            "label": "P.U.M.S MEENAKSHIPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18901",
            "district": "38",
            "Block": "411",
            "label": "KALAIMAGAL HSS, S.RAMACHANDRAPURAM",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18902",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, MELAGOPALAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18903",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, THAILAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18904",
            "district": "38",
            "Block": "411",
            "label": "GHS, AYANKARISALKULAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18905",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, S.AMMAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18906",
            "district": "38",
            "Block": "411",
            "label": "GHS, SEELNAICKANPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "18907",
            "district": "38",
            "Block": "411",
            "label": "R.C.HS.,AYANNATHAMPATTI",
            "Sch_type": "Partially Aided",
            "Category": "High School"
        },
        {
            "key": "18908",
            "district": "38",
            "Block": "411",
            "label": "P U M S KUNNUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "18909",
            "district": "38",
            "Block": "411",
            "label": "KAMARAJ M.S, KUNNUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18910",
            "district": "38",
            "Block": "411",
            "label": "HINDU H.S.S.  WATRAP",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18911",
            "district": "38",
            "Block": "411",
            "label": "HINDU (G)H.S.WATRAP",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18912",
            "district": "38",
            "Block": "411",
            "label": "NADAR H.S.S  WATRAP",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "18913",
            "district": "38",
            "Block": "411",
            "label": "R.C M.S W.PUDUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "18914",
            "district": "38",
            "Block": "411",
            "label": "SRI RENUGA HINDU HIGH SCHOOL, W.PUDHUPATTI",
            "Sch_type": "Fully Aided",
            "Category": "High School"
        },
        {
            "key": "18915",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT HIGHER SECONDARY SCHOOL, SUNDARAPANDIAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21404",
            "district": "38",
            "Block": "411",
            "label": "GOVERNMENT GIRLS HIGHER SECONDARY SCHOOL, KOOMAPATTI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21853",
            "district": "38",
            "Block": "411",
            "label": "VIVEKANANTHA VIDYA MANDIR.,AKKANAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "21854",
            "district": "38",
            "Block": "411",
            "label": "LINGA GLOBAL SCHOOL.,KRISHNANKOVIL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "22624",
            "district": "38",
            "Block": "411",
            "label": "G.H.S, THULUKKAPATTI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "22903",
            "district": "38",
            "Block": "411",
            "label": "GHS, KEELAKOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23197",
            "district": "38",
            "Block": "411",
            "label": "RANGARAO LIONS MATRICULATION HR. SEC SCHOOL, MAHARAJAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23295",
            "district": "38",
            "Block": "411",
            "label": "GOVT ADW HIGH SCHOOL, KOTTAIYUR",
            "Sch_type": "Government",
            "Category": "High School"
        }
    ],
    "412": [
        {
            "key": "1854",
            "district": "34",
            "Block": "412",
            "label": "CLUNY MATRIC DEVIGAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1887",
            "district": "34",
            "Block": "412",
            "label": "VELS VIDHYASHRAM MATRIC HSS, KANNAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "1966",
            "district": "34",
            "Block": "412",
            "label": "SRI VIJAY MATRIC HR SEC SCHOOL VANNANKULAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2356",
            "district": "34",
            "Block": "412",
            "label": "PUMS  VANNANKULAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2405",
            "district": "34",
            "Block": "412",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2412",
            "district": "34",
            "Block": "412",
            "label": "PUMS PUDUPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2420",
            "district": "34",
            "Block": "412",
            "label": "PUMS AAGARAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2443",
            "district": "34",
            "Block": "412",
            "label": "PUMS KAMAKKUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2492",
            "district": "34",
            "Block": "412",
            "label": "PUMS PALAYA EGAMBARANALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2617",
            "district": "34",
            "Block": "412",
            "label": "PUMS KAMAKKURPALAYAM (WEST)",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2688",
            "district": "34",
            "Block": "412",
            "label": "PUMS SRINIVASAPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2735",
            "district": "34",
            "Block": "412",
            "label": "PUMS(MUSLIM) KANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2845",
            "district": "34",
            "Block": "412",
            "label": "AIM MATRICULATION HSS  AAGARAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "2863",
            "district": "34",
            "Block": "412",
            "label": "PUMS PUNGAMBADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2864",
            "district": "34",
            "Block": "412",
            "label": "PUMS ARAIYALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "2891",
            "district": "34",
            "Block": "412",
            "label": "PUMS MARUSUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3074",
            "district": "34",
            "Block": "412",
            "label": "PUMS PERIYA IYYAMPALAYAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "3154",
            "district": "34",
            "Block": "412",
            "label": "PUMS KARIPPUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23370",
            "district": "34",
            "Block": "412",
            "label": "S.G.S Matriculation School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "3374",
            "district": "34",
            "Block": "412",
            "label": "DESIA MATRICULATION-KATTUKANUR",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "3716",
            "district": "34",
            "Block": "412",
            "label": "PUMS KOLATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "4520",
            "district": "34",
            "Block": "412",
            "label": "PUMS ONNUPURAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "5316",
            "district": "34",
            "Block": "412",
            "label": "AIDED MIDDLE KATTUKANUR",
            "Sch_type": "Fully Aided",
            "Category": "Middle School"
        },
        {
            "key": "6586",
            "district": "34",
            "Block": "412",
            "label": "MADRAS MATRIC, KANNAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "9687",
            "district": "34",
            "Block": "412",
            "label": "GHS 5PUTHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9688",
            "district": "34",
            "Block": "412",
            "label": "GHSS DEVIKAPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9689",
            "district": "34",
            "Block": "412",
            "label": "GHS(GIRLS) DEVIKAPURAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9690",
            "district": "34",
            "Block": "412",
            "label": "GBHSS KANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9691",
            "district": "34",
            "Block": "412",
            "label": "GHSS(GIRLS) KANNAMANGALAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9692",
            "district": "34",
            "Block": "412",
            "label": "GHSS KUNNATHUR",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9693",
            "district": "34",
            "Block": "412",
            "label": "GHS MALAIYAMPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9694",
            "district": "34",
            "Block": "412",
            "label": "GHS MELNAGAR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9695",
            "district": "34",
            "Block": "412",
            "label": "GHS MULLIPATTU",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9696",
            "district": "34",
            "Block": "412",
            "label": "GHSS NADUKUPPAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9697",
            "district": "34",
            "Block": "412",
            "label": "GHSS ONNUPURAM",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "9698",
            "district": "34",
            "Block": "412",
            "label": "GHS THATCHUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9699",
            "district": "34",
            "Block": "412",
            "label": "GHS THELLUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "9700",
            "district": "34",
            "Block": "412",
            "label": "GHS VINNAMANGALAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "21149",
            "district": "34",
            "Block": "412",
            "label": "ORANGE INTERNATIONAL MATRIC HSS - VINNAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21677",
            "district": "34",
            "Block": "412",
            "label": "ORANGE BRITISH ACADEMY SCHOOL-VINNAMANGALAM",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22087",
            "district": "34",
            "Block": "412",
            "label": "TULIP INTERNATIONAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "22780",
            "district": "34",
            "Block": "412",
            "label": "RAMAJEYAM MATRICULATION SCHOOL DEVIKAPURAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "22781",
            "district": "34",
            "Block": "412",
            "label": "SAFA MATRIC School, Thatchur",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23316",
            "district": "34",
            "Block": "412",
            "label": "SHRI RAMACHANDRA SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24093",
            "district": "34",
            "Block": "412",
            "label": "PUMS AMMAPALAYAM",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "413": [
        {
            "key": "4353",
            "district": "22",
            "Block": "413",
            "label": "OUR LADY OF VELANKANNI MATRIC SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11663",
            "district": "22",
            "Block": "413",
            "label": "PUMS SENGADU",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "23416",
            "district": "22",
            "Block": "413",
            "label": "Brightsun Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "11664",
            "district": "22",
            "Block": "413",
            "label": "PUMS, THALAICHOLAI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11665",
            "district": "22",
            "Block": "413",
            "label": "PUMS.GUNDUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11666",
            "district": "22",
            "Block": "413",
            "label": "ST. JOSEPH BOYS HR. SEC. SCHOOL, YERCAUD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11667",
            "district": "22",
            "Block": "413",
            "label": "SACRED HEART ANGLO INDIAN GIRLS HR. SEC. SCHOOL, YERCAUD",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11668",
            "district": "22",
            "Block": "413",
            "label": "GHSS YERCAUD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11669",
            "district": "22",
            "Block": "413",
            "label": "NAZARETH GIRLS HR. SEC. SCHOOL, YERCAUD",
            "Sch_type": "Partially Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11670",
            "district": "22",
            "Block": "413",
            "label": "MONTFORT ANGLO INDIAN HR. SEC. SCHOOL, YERCAUD",
            "Sch_type": "Fully Aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11671",
            "district": "22",
            "Block": "413",
            "label": "ST.CHARLES SCHOOL,YERCAUD",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "11672",
            "district": "22",
            "Block": "413",
            "label": "PUMS, MULUVI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11673",
            "district": "22",
            "Block": "413",
            "label": "GHS NAGALUR",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11674",
            "district": "22",
            "Block": "413",
            "label": "PUMS, PATTIPADI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11675",
            "district": "22",
            "Block": "413",
            "label": "PUMS, KOLAGUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11676",
            "district": "22",
            "Block": "413",
            "label": "GHS VELLAKKADAI",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "11677",
            "district": "22",
            "Block": "413",
            "label": "PUMS, NALLUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11678",
            "district": "22",
            "Block": "413",
            "label": "PUMS, MARAMANGALAM",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11679",
            "district": "22",
            "Block": "413",
            "label": "MONTFORT COMMUNITY HR. SEC. SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11680",
            "district": "22",
            "Block": "413",
            "label": "G.T.R. MIDDLE SCH VAZHAVANTHI",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11681",
            "district": "22",
            "Block": "413",
            "label": "SEVAROYS VALLY HR. SEC. SCHOOL, VALAVANTHI",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "11682",
            "district": "22",
            "Block": "413",
            "label": "PUMS.CHINNA MATHUR",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "11683",
            "district": "22",
            "Block": "413",
            "label": "K.G.B.V. YERCAUD",
            "Sch_type": "Government",
            "Category": "Middle School"
        },
        {
            "key": "21214",
            "district": "22",
            "Block": "413",
            "label": "MODEL SCHOOL, YERCAUD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21353",
            "district": "22",
            "Block": "413",
            "label": "MONTFORT SCHOOL (ICSE), YERCAUD",
            "Sch_type": "Un-aided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "21500",
            "district": "22",
            "Block": "413",
            "label": "GTR HIGH SCHOOL, SEMMANATHAM",
            "Sch_type": "Government",
            "Category": "High School"
        },
        {
            "key": "23128",
            "district": "22",
            "Block": "413",
            "label": "EKALAVYA MODEL RESIDENTIAL SCHOOL, YERCAUD",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23322",
            "district": "22",
            "Block": "413",
            "label": "SACRED HEART SCHOOL ,YERCAUD",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23416",
            "district": "22",
            "Block": "413",
            "label": "Brightsun Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23416",
            "district": "22",
            "Block": "413",
            "label": "Brightsun Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23416",
            "district": "22",
            "Block": "413",
            "label": "Brightsun Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23416",
            "district": "22",
            "Block": "413",
            "label": "Brightsun Matric School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "24094",
            "district": "22",
            "Block": "413",
            "label": "PUMS, NALLUR",
            "Sch_type": "GovernmentMiddle School"
        }
    ],
    "414": [
        {
            "key": "23450",
            "district": "3",
            "Block": "414",
            "label": "ORCHIDS THE INTERNATIONAL SCHOOL, THURAIPAKKAM",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23451",
            "district": "3",
            "Block": "414",
            "label": "SAIRAM LEOMUTHU PUBLIC SCHOOL VENGAIVASAL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "23452",
            "district": "3",
            "Block": "414",
            "label": "ADHITHYA PUBLIC SCHOOL, SHOLINGANALLUR",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "23695",
            "district": "3",
            "Block": "414",
            "label": "Nehru Govt. Hr. Sec. School",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        { 
            "key":"24122", "district":"3", 
            "Block":"414", "label": "Sun shine Chennai senior secondary school", 
            "Sch_type": "Un-aided", "Category": "Higher Secondary School"
        },
    ],
    "415": [
        {
            "key": "23453",
            "district": "3",
            "Block": "415",
            "label": "JAIGOPAL GARODIA VIVEKANANDA VIDYALAYA, ANNA NAGAR",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        { 
            "key":"24128", "district":"3", 
            "Block":"415", "label": "Pon Vidyashram Group of Senior Secondary Schools", 
            "Sch_type": "Un-aided", "Category": "High School"
        },
        {
            "key": "23647",
            "district": "3",
            "Block": "415",
            "label": "Sir Ramaswami Mudaliar Higher Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23675",
            "district": "3",
            "Block": "415",
            "label": "Vinod Vijay Vidyalaya Matric Higher secondary school ",
            "Sch_type": "Unaided",
            "Category": "Higher Secondary School"
        },
        {
            "key": "24095",
            "district": "3",
            "Block": "415",
            "label": "MAHARISHI VIDYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24096",
            "district": "3",
            "Block": "415",
            "label": "ST JOHNS MAT HSS",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24097",
            "district": "3",
            "Block": "415",
            "label": "Shri Krishnaswamy Matric Hr. Sec. School",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24098",
            "district": "3",
            "Block": "415",
            "label": "NARAYANA E-TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "416": [
        {
            "key": "23455",
            "district": "3",
            "Block": "416",
            "label": "AVM RAJESWARI - THE SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24099",
            "district": "3",
            "Block": "416",
            "label": "SRI CHAITANYA TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "417": [
        {
            "key": "23457",
            "district": "3",
            "Block": "417",
            "label": "MANOJ KIRAN SPECIAL SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "High School"
        },
        {
            "key": "24100",
            "district": "3",
            "Block": "417",
            "label": "SRIVATSA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "418": [
        {
            "key": "23458",
            "district": "3",
            "Block": "418",
            "label": "CHENNAI DISTRICT GOVERNMENT MODEL SCHOOL, CHENNAI",
            "Sch_type": "Government",
            "Category": "Higher Secondary School"
        },
        {
            "key": "23651",
            "district": "3",
            "Block": "418",
            "label": "Sri Ram Dayal Khemka Vivekananda Vidyalaya Senior Secondary School",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23688",
            "district": "3",
            "Block": "418",
            "label": " kids park vidyashram school",
            "Sch_type": "Unaided",
            "Category": "High School"
        },
        {
            "key": "23459",
            "district": "3",
            "Block": "418",
            "label": "SRIRAM VIDHYA MANDIR SCHOOL",
            "Sch_type": "Un-aided",
            "Category": "Middle School"
        },
        {
            "key": "24101",
            "district": "3",
            "Block": "418",
            "label": "NARAYANA e - TECHNO SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        },
        {
            "key": "24102",
            "district": "3",
            "Block": "418",
            "label": "VELANKANNI PUBLIC SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ],
    "419": [
        {
            "key": "24104",
            "district": "3",
            "Block": "419",
            "label": "CSI MIDDLE SCHOOL",
            "Sch_type": "Fully AidedMiddle School"
        },
        {
            "key": "24105",
            "district": "3",
            "Block": "419",
            "label": "Good Shepherd Matric Higher Secondary School",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24106",
            "district": "3",
            "Block": "419",
            "label": "Mahila Vidyalaya Nursery & Primary School",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24113",
            "district": "3",
            "Block": "419",
            "label": "M.CT.M CHIDAMBARAM CHETTYAR SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24114",
            "district": "3",
            "Block": "419",
            "label": "SIR SIVASWAMI KALALAYA MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedHigh School"
        }
    ],
    "420": [
        {
            "key": "24107",
            "district": "3",
            "Block": "420",
            "label": "LITTLE LAMBS MATRICULATION SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        },
        {
            "key": "24108",
            "district": "3",
            "Block": "420",
            "label": "EVERWIN VIDHYASHRAM",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24111",
            "district": "3",
            "Block": "420",
            "label": "MAHARISHI VIDYA MANDIR",
            "Sch_type": "Un-aidedHigher Secondary School"
        },
        {
            "key": "24115",
            "district": "3",
            "Block": "420",
            "label": "NARAYANA e-TECHNO SCHOOL",
            "Sch_type": "Un-aidedMiddle School"
        }
    ]
}

const BLOCKS =  [
    {
        "key": "1",
        "district": "2",
        "label": "Acharapakkam"
    },
    {
        "key": "2",
        "district": "3",
        "label": "Adyar"
    },
    {
        "key": "3",
        "district": "11",
        "label": "Agastheeswaram"
    },
    {
        "key": "4",
        "district": "14",
        "label": "Alanganallur"
    },
    {
        "key": "5",
        "district": "31",
        "label": "Alangayam"
    },
    {
        "key": "6",
        "district": "24",
        "label": "Alangulam"
    },
    {
        "key": "7",
        "district": "18",
        "label": "Alathur"
    },
    {
        "key": "8",
        "district": "28",
        "label": "Alwarthirunagar"
    },
    {
        "key": "9",
        "district": "30",
        "label": "Ambasamudram"
    },
    {
        "key": "10",
        "district": "8",
        "label": "Ammapettai-Erode"
    },
    {
        "key": "11",
        "district": "25",
        "label": "Ammapettai-TNJ"
    },
    {
        "key": "12",
        "district": "36",
        "label": "Anaicut"
    },
    {
        "key": "13",
        "district": "4",
        "label": "Anaimalai"
    },
    {
        "key": "14",
        "district": "34",
        "label": "Anakkavur"
    },
    {
        "key": "15",
        "district": "29",
        "label": "Andhanallur"
    },
    {
        "key": "16",
        "district": "1",
        "label": "Andimadam"
    },
    {
        "key": "17",
        "district": "5",
        "label": "Annagrammam"
    },
    {
        "key": "18",
        "district": "19",
        "label": "Annavasal"
    },
    {
        "key": "19",
        "district": "4",
        "label": "Annur"
    },
    {
        "key": "20",
        "district": "8",
        "label": "Anthiyur"
    },
    {
        "key": "21",
        "district": "21",
        "label": "Arakkonam"
    },
    {
        "key": "22",
        "district": "19",
        "label": "Aranthangi"
    },
    {
        "key": "23",
        "district": "12",
        "label": "Aravakurichi"
    },
    {
        "key": "24",
        "district": "21",
        "label": "Arcot"
    },
    {
        "key": "25",
        "district": "19",
        "label": "Arimalam"
    },
    {
        "key": "26",
        "district": "1",
        "label": "Ariyalur"
    },
    {
        "key": "27",
        "district": "34",
        "label": "Arni"
    },
    {
        "key": "28",
        "district": "38",
        "label": "Aruppukottai"
    },
    {
        "key": "29",
        "district": "7",
        "label": "Athoor"
    },
    {
        "key": "30",
        "district": "22",
        "label": "Attur"
    },
    {
        "key": "31",
        "district": "27",
        "label": "Aundipatty"
    },
    {
        "key": "32",
        "district": "32",
        "label": "Avinashi"
    },
    {
        "key": "33",
        "district": "19",
        "label": "Avudaiyarkoil"
    },
    {
        "key": "34",
        "district": "22",
        "label": "Ayothiyapattinam"
    },
    {
        "key": "35",
        "district": "13",
        "label": "Bargur"
    },
    {
        "key": "36",
        "district": "7",
        "label": "Batlagundu"
    },
    {
        "key": "37",
        "district": "8",
        "label": "Bhavani"
    },
    {
        "key": "38",
        "district": "8",
        "label": "Bhavani sagar"
    },
    {
        "key": "39",
        "district": "5",
        "label": "Bhuvanagiri"
    },
    {
        "key": "40",
        "district": "27",
        "label": "Bodinayakanur"
    },
    {
        "key": "41",
        "district": "20",
        "label": "Bogalur"
    },
    {
        "key": "42",
        "district": "25",
        "label": "Budalur"
    },
    {
        "key": "43",
        "district": "14",
        "label": "Chellampatti"
    },
    {
        "key": "44",
        "district": "34",
        "label": "Chengam"
    },
    {
        "key": "45",
        "district": "8",
        "label": "Chennimalai"
    },
    {
        "key": "46",
        "district": "30",
        "label": "Cheranmahadevi"
    },
    {
        "key": "47",
        "district": "34",
        "label": "Chetpet"
    },
    {
        "key": "48",
        "district": "34",
        "label": "Cheyyar"
    },
    {
        "key": "49",
        "district": "27",
        "label": "Chinnamanur"
    },
    {
        "key": "50",
        "district": "9",
        "label": "Chinnasalem"
    },
    {
        "key": "51",
        "district": "2",
        "label": "Chithamur"
    },
    {
        "key": "52",
        "district": "4",
        "label": "Coimbatore city"
    },
    {
        "key": "53",
        "district": "26",
        "label": "Coonoor"
    },
    {
        "key": "54",
        "district": "5",
        "label": "Cuddalore"
    },
    {
        "key": "55",
        "district": "27",
        "label": "Cumbum"
    },
    {
        "key": "56",
        "district": "23",
        "label": "Devakottai"
    },
    {
        "key": "57",
        "district": "32",
        "label": "Dharapuram"
    },
    {
        "key": "58",
        "district": "6",
        "label": "Dharmapuri"
    },
    {
        "key": "59",
        "district": "7",
        "label": "Dindigul - Rural"
    },
    {
        "key": "60",
        "district": "7",
        "label": "Dindigul -Urban"
    },
    {
        "key": "61",
        "district": "22",
        "label": "Edappadi"
    },
    {
        "key": "62",
        "district": "3",
        "label": "Egmore"
    },
    {
        "key": "63",
        "district": "17",
        "label": "Elachipalayam"
    },
    {
        "key": "64",
        "district": "33",
        "label": "Ellapuram"
    },
    {
        "key": "65",
        "district": "8",
        "label": "Erode"
    },
    {
        "key": "66",
        "district": "17",
        "label": "Erumapatti"
    },
    {
        "key": "67",
        "district": "19",
        "label": "Gandarvakkottai"
    },
    {
        "key": "68",
        "district": "22",
        "label": "Gangavalli"
    },
    {
        "key": "69",
        "district": "3",
        "label": "George town"
    },
    {
        "key": "70",
        "district": "37",
        "label": "Gingee"
    },
    {
        "key": "71",
        "district": "8",
        "label": "Gobi"
    },
    {
        "key": "72",
        "district": "26",
        "label": "Gudalur"
    },
    {
        "key": "73",
        "district": "32",
        "label": "Gudimangalam"
    },
    {
        "key": "74",
        "district": "36",
        "label": "Gudiyatham"
    },
    {
        "key": "75",
        "district": "7",
        "label": "Gujiliamparai"
    },
    {
        "key": "76",
        "district": "33",
        "label": "Gummidipoondi"
    },
    {
        "key": "77",
        "district": "6",
        "label": "Harur"
    },
    {
        "key": "78",
        "district": "13",
        "label": "Hosur"
    },
    {
        "key": "79",
        "district": "23",
        "label": "Ilayangudi"
    },
    {
        "key": "80",
        "district": "34",
        "label": "Jawadhu Hills"
    },
    {
        "key": "81",
        "district": "1",
        "label": "Jayankondam"
    },
    {
        "key": "82",
        "district": "31",
        "label": "Jolarpet"
    },
    {
        "key": "83",
        "district": "12",
        "label": "K.Paramathi"
    },
    {
        "key": "84",
        "district": "36",
        "label": "K.V.Kuppam"
    },
    {
        "key": "85",
        "district": "17",
        "label": "Kabilarmalai"
    },
    {
        "key": "86",
        "district": "20",
        "label": "Kadaladi"
    },
    {
        "key": "87",
        "district": "33",
        "label": "Kadambathur"
    },
    {
        "key": "88",
        "district": "12",
        "label": "Kadavoor"
    },
    {
        "key": "89",
        "district": "24",
        "label": "Kadayam"
    },
    {
        "key": "90",
        "district": "22",
        "label": "Kadayampatti"
    },
    {
        "key": "91",
        "district": "24",
        "label": "Kadayanallur"
    },
    {
        "key": "92",
        "district": "30",
        "label": "Kalakad"
    },
    {
        "key": "93",
        "district": "34",
        "label": "Kalasapakkam"
    },
    {
        "key": "94",
        "district": "23",
        "label": "Kalayarkoil"
    },
    {
        "key": "95",
        "district": "9",
        "label": "Kallakurichi"
    },
    {
        "key": "96",
        "district": "23",
        "label": "Kallal"
    },
    {
        "key": "97",
        "district": "14",
        "label": "Kallikudi"
    },
    {
        "key": "98",
        "district": "5",
        "label": "Kammapuram"
    },
    {
        "key": "99",
        "district": "20",
        "label": "Kamuthi"
    },
    {
        "key": "100",
        "district": "37",
        "label": "Kanai"
    },
    {
        "key": "101",
        "district": "10",
        "label": "Kancheepuram"
    },
    {
        "key": "102",
        "district": "37",
        "label": "Kandamangalam"
    },
    {
        "key": "103",
        "district": "31",
        "label": "Kandhili"
    },
    {
        "key": "104",
        "district": "32",
        "label": "Kangayam"
    },
    {
        "key": "105",
        "district": "36",
        "label": "Kaniyambadi"
    },
    {
        "key": "106",
        "district": "23",
        "label": "Kannangudi"
    },
    {
        "key": "107",
        "district": "4",
        "label": "Karamadai"
    },
    {
        "key": "108",
        "district": "19",
        "label": "Karambakkudi"
    },
    {
        "key": "109",
        "district": "6",
        "label": "Karimangalam"
    },
    {
        "key": "110",
        "district": "38",
        "label": "Kariyapatti"
    },
    {
        "key": "111",
        "district": "28",
        "label": "Karunkulam"
    },
    {
        "key": "112",
        "district": "12",
        "label": "Karur"
    },
    {
        "key": "113",
        "district": "36",
        "label": "Katpadi"
    },
    {
        "key": "114",
        "district": "2",
        "label": "Kattankolathur"
    },
    {
        "key": "115",
        "district": "5",
        "label": "Kattumannarkoil"
    },
    {
        "key": "116",
        "district": "21",
        "label": "Kaveripakkam"
    },
    {
        "key": "117",
        "district": "13",
        "label": "Kaveripatinam"
    },
    {
        "key": "118",
        "district": "28",
        "label": "Kayathar"
    },
    {
        "key": "119",
        "district": "24",
        "label": "Keelapavoor"
    },
    {
        "key": "120",
        "district": "5",
        "label": "Keerapalayam"
    },
    {
        "key": "121",
        "district": "16",
        "label": "Keezhaiyur"
    },
    {
        "key": "122",
        "district": "13",
        "label": "Kelamangalam"
    },
    {
        "key": "123",
        "district": "16",
        "label": "Kelvelur"
    },
    {
        "key": "124",
        "district": "11",
        "label": "Killiyoor"
    },
    {
        "key": "125",
        "district": "34",
        "label": "Kilpennathur"
    },
    {
        "key": "126",
        "district": "4",
        "label": "Kinathukkadavu"
    },
    {
        "key": "127",
        "district": "7",
        "label": "Kodaikanal"
    },
    {
        "key": "128",
        "district": "8",
        "label": "Kodumudi"
    },
    {
        "key": "129",
        "district": "22",
        "label": "Kolathur"
    },
    {
        "key": "130",
        "district": "37",
        "label": "Koliyanur"
    },
    {
        "key": "131",
        "district": "15",
        "label": "kollidam"
    },
    {
        "key": "132",
        "district": "17",
        "label": "Kollihills"
    },
    {
        "key": "133",
        "district": "22",
        "label": "Konganapuram"
    },
    {
        "key": "134",
        "district": "35",
        "label": "Koradachery"
    },
    {
        "key": "135",
        "district": "26",
        "label": "Kotagiri"
    },
    {
        "key": "136",
        "district": "14",
        "label": "Kottampatti"
    },
    {
        "key": "137",
        "district": "35",
        "label": "Kottur"
    },
    {
        "key": "138",
        "district": "28",
        "label": "Kovilpatti"
    },
    {
        "key": "139",
        "district": "13",
        "label": "Krishnagiri"
    },
    {
        "key": "140",
        "district": "12",
        "label": "Krishnarayapuram"
    },
    {
        "key": "141",
        "district": "35",
        "label": "Kudavasal"
    },
    {
        "key": "142",
        "district": "12",
        "label": "Kulithalai"
    },
    {
        "key": "143",
        "district": "5",
        "label": "Kumaratchi"
    },
    {
        "key": "144",
        "district": "25",
        "label": "Kumbakonam"
    },
    {
        "key": "145",
        "district": "32",
        "label": "Kundadam"
    },
    {
        "key": "146",
        "district": "10",
        "label": "Kundrathur"
    },
    {
        "key": "147",
        "district": "19",
        "label": "Kunnandarkoil"
    },
    {
        "key": "148",
        "district": "5",
        "label": "Kurinjipadi"
    },
    {
        "key": "149",
        "district": "11",
        "label": "Kurunthancode"
    },
    {
        "key": "150",
        "district": "24",
        "label": "Kuruvikulam"
    },
    {
        "key": "151",
        "district": "15",
        "label": "Kuthalam"
    },
    {
        "key": "152",
        "district": "29",
        "label": "Lalgudi"
    },
    {
        "key": "153",
        "district": "2",
        "label": "Lathur"
    },
    {
        "key": "154",
        "district": "32",
        "label": "Madathukulam"
    },
    {
        "key": "155",
        "district": "31",
        "label": "Madhanur"
    },
    {
        "key": "156",
        "district": "4",
        "label": "Madukkarai"
    },
    {
        "key": "157",
        "district": "25",
        "label": "Madukkur"
    },
    {
        "key": "158",
        "district": "14",
        "label": "Madurai East"
    },
    {
        "key": "159",
        "district": "14",
        "label": "Madurai North"
    },
    {
        "key": "160",
        "district": "14",
        "label": "Madurai South"
    },
    {
        "key": "161",
        "district": "14",
        "label": "Madurai West"
    },
    {
        "key": "162",
        "district": "2",
        "label": "Madurantagam"
    },
    {
        "key": "163",
        "district": "22",
        "label": "Magudanchavadi"
    },
    {
        "key": "164",
        "district": "37",
        "label": "Mailam"
    },
    {
        "key": "165",
        "district": "17",
        "label": "Mallasamudram"
    },
    {
        "key": "166",
        "district": "23",
        "label": "Manamadurai"
    },
    {
        "key": "167",
        "district": "19",
        "label": "Manamelkudi"
    },
    {
        "key": "168",
        "district": "29",
        "label": "Manapparai"
    },
    {
        "key": "169",
        "district": "20",
        "label": "Mandapam"
    },
    {
        "key": "170",
        "district": "5",
        "label": "Mangalore"
    },
    {
        "key": "171",
        "district": "29",
        "label": "Manikandam"
    },
    {
        "key": "172",
        "district": "29",
        "label": "Mannachanallur"
    },
    {
        "key": "173",
        "district": "35",
        "label": "Mannargudi"
    },
    {
        "key": "174",
        "district": "30",
        "label": "Manur"
    },
    {
        "key": "175",
        "district": "37",
        "label": "Marakkanam"
    },
    {
        "key": "176",
        "district": "29",
        "label": "Marungapuri"
    },
    {
        "key": "177",
        "district": "13",
        "label": "Mathur"
    },
    {
        "key": "178",
        "district": "15",
        "label": "Mayiladudurai"
    },
    {
        "key": "179",
        "district": "22",
        "label": "Mecheri"
    },
    {
        "key": "180",
        "district": "24",
        "label": "Melaneelithanallur"
    },
    {
        "key": "181",
        "district": "37",
        "label": "Melmalayanur"
    },
    {
        "key": "182",
        "district": "11",
        "label": "Melpuram"
    },
    {
        "key": "183",
        "district": "14",
        "label": "Melur"
    },
    {
        "key": "184",
        "district": "33",
        "label": "Minjur"
    },
    {
        "key": "185",
        "district": "8",
        "label": "Modakurichi"
    },
    {
        "key": "186",
        "district": "17",
        "label": "Mohanur"
    },
    {
        "key": "187",
        "district": "6",
        "label": "Morappur"
    },
    {
        "key": "188",
        "district": "20",
        "label": "Mudukulathur"
    },
    {
        "key": "189",
        "district": "37",
        "label": "Mugaiyur"
    },
    {
        "key": "190",
        "district": "32",
        "label": "Mulanur"
    },
    {
        "key": "191",
        "district": "11",
        "label": "Munchirai"
    },
    {
        "key": "192",
        "district": "29",
        "label": "Musiri"
    },
    {
        "key": "193",
        "district": "35",
        "label": "Muthupettai"
    },
    {
        "key": "194",
        "district": "27",
        "label": "Myladumparai"
    },
    {
        "key": "195",
        "district": "3",
        "label": "Mylapore"
    },
    {
        "key": "196",
        "district": "16",
        "label": "Nagapattinam"
    },
    {
        "key": "197",
        "district": "20",
        "label": "Nainarkoil"
    },
    {
        "key": "198",
        "district": "6",
        "label": "Nallampalli"
    },
    {
        "key": "199",
        "district": "5",
        "label": "Nallur"
    },
    {
        "key": "200",
        "district": "17",
        "label": "Namagiripet"
    },
    {
        "key": "201",
        "district": "17",
        "label": "Namakkal"
    },
    {
        "key": "202",
        "district": "8",
        "label": "Nambiyur"
    },
    {
        "key": "203",
        "district": "22",
        "label": "Nangavalli"
    },
    {
        "key": "204",
        "district": "30",
        "label": "Nanguneri"
    },
    {
        "key": "205",
        "district": "35",
        "label": "Nannilam"
    },
    {
        "key": "206",
        "district": "38",
        "label": "Narikudi"
    },
    {
        "key": "207",
        "district": "7",
        "label": "Natham"
    },
    {
        "key": "208",
        "district": "31",
        "label": "Natrampalli"
    },
    {
        "key": "209",
        "district": "35",
        "label": "Needamangalam"
    },
    {
        "key": "210",
        "district": "21",
        "label": "Nemili"
    },
    {
        "key": "211",
        "district": "5",
        "label": "Neyveli"
    },
    {
        "key": "212",
        "district": "7",
        "label": "Nilakottai"
    },
    {
        "key": "213",
        "district": "7",
        "label": "Oddanchathram"
    },
    {
        "key": "214",
        "district": "37",
        "label": "Olakkur"
    },
    {
        "key": "215",
        "district": "22",
        "label": "Omalur"
    },
    {
        "key": "216",
        "district": "25",
        "label": "Orathanadu"
    },
    {
        "key": "217",
        "district": "28",
        "label": "Ottapidaram"
    },
    {
        "key": "218",
        "district": "6",
        "label": "Palacode"
    },
    {
        "key": "219",
        "district": "7",
        "label": "Palani - Rural"
    },
    {
        "key": "220",
        "district": "7",
        "label": "Palani - Urban"
    },
    {
        "key": "221",
        "district": "30",
        "label": "Palay-Rural"
    },
    {
        "key": "222",
        "district": "30",
        "label": "Palay-Urban"
    },
    {
        "key": "223",
        "district": "32",
        "label": "Palladam"
    },
    {
        "key": "224",
        "district": "17",
        "label": "Pallipalayam"
    },
    {
        "key": "225",
        "district": "33",
        "label": "Pallipat"
    },
    {
        "key": "226",
        "district": "22",
        "label": "Panamarathupatti"
    },
    {
        "key": "227",
        "district": "5",
        "label": "Panruti"
    },
    {
        "key": "228",
        "district": "25",
        "label": "Papanasam"
    },
    {
        "key": "229",
        "district": "30",
        "label": "Pappakudi"
    },
    {
        "key": "230",
        "district": "6",
        "label": "Pappireddipatti"
    },
    {
        "key": "231",
        "district": "20",
        "label": "Paramakudi"
    },
    {
        "key": "232",
        "district": "17",
        "label": "Paramathi"
    },
    {
        "key": "233",
        "district": "5",
        "label": "Parangipettai"
    },
    {
        "key": "234",
        "district": "25",
        "label": "Pattukkottai"
    },
    {
        "key": "235",
        "district": "6",
        "label": "Pennagaram"
    },
    {
        "key": "236",
        "district": "18",
        "label": "Perambalur"
    },
    {
        "key": "237",
        "district": "3",
        "label": "Perambur"
    },
    {
        "key": "238",
        "district": "34",
        "label": "Peranamallur"
    },
    {
        "key": "239",
        "district": "25",
        "label": "Peravurani"
    },
    {
        "key": "240",
        "district": "3",
        "label": "Periamedu"
    },
    {
        "key": "241",
        "district": "27",
        "label": "Periyakulam"
    },
    {
        "key": "242",
        "district": "4",
        "label": "Periyanaickan Palayam"
    },
    {
        "key": "243",
        "district": "36",
        "label": "Pernambut"
    },
    {
        "key": "244",
        "district": "8",
        "label": "Perundurai"
    },
    {
        "key": "245",
        "district": "4",
        "label": "Perur"
    },
    {
        "key": "246",
        "district": "22",
        "label": "Pethanaickenpalayam"
    },
    {
        "key": "247",
        "district": "4",
        "label": "Pollachi north"
    },
    {
        "key": "248",
        "district": "4",
        "label": "Pollachi south"
    },
    {
        "key": "249",
        "district": "34",
        "label": "Polur"
    },
    {
        "key": "250",
        "district": "32",
        "label": "Pongalur"
    },
    {
        "key": "251",
        "district": "19",
        "label": "Ponnamaravathy"
    },
    {
        "key": "252",
        "district": "33",
        "label": "Poonamallee"
    },
    {
        "key": "253",
        "district": "33",
        "label": "Poondi"
    },
    {
        "key": "254",
        "district": "17",
        "label": "Puduchatram"
    },
    {
        "key": "255",
        "district": "19",
        "label": "Pudukkottai"
    },
    {
        "key": "256",
        "district": "34",
        "label": "Pudupalayam"
    },
    {
        "key": "257",
        "district": "28",
        "label": "Pudur"
    },
    {
        "key": "258",
        "district": "29",
        "label": "Pullambadi"
    },
    {
        "key": "259",
        "district": "3",
        "label": "Purasaiwalkkam"
    },
    {
        "key": "260",
        "district": "33",
        "label": "Puzhal"
    },
    {
        "key": "261",
        "district": "33",
        "label": "R.K.Pet"
    },
    {
        "key": "262",
        "district": "30",
        "label": "Radhapuram"
    },
    {
        "key": "263",
        "district": "11",
        "label": "Rajakkamangalam"
    },
    {
        "key": "264",
        "district": "38",
        "label": "Rajapalayam"
    },
    {
        "key": "265",
        "district": "20",
        "label": "Rajasingamangalam"
    },
    {
        "key": "266",
        "district": "20",
        "label": "Ramanathapuram"
    },
    {
        "key": "267",
        "district": "17",
        "label": "Rasipuram"
    },
    {
        "key": "268",
        "district": "7",
        "label": "Reddiyarchatram"
    },
    {
        "key": "269",
        "district": "9",
        "label": "Rishivanthiyam"
    },
    {
        "key": "270",
        "district": "3",
        "label": "Royapuram"
    },
    {
        "key": "271",
        "district": "23",
        "label": "S.Pudur"
    },
    {
        "key": "272",
        "district": "23",
        "label": "Sakkottai"
    },
    {
        "key": "273",
        "district": "22",
        "label": "Salem-Rural"
    },
    {
        "key": "274",
        "district": "22",
        "label": "Salem-Urban"
    },
    {
        "key": "275",
        "district": "7",
        "label": "Sanarpatty"
    },
    {
        "key": "276",
        "district": "24",
        "label": "Sankarankovil"
    },
    {
        "key": "277",
        "district": "9",
        "label": "Sankarapuram"
    },
    {
        "key": "278",
        "district": "22",
        "label": "Sankari"
    },
    {
        "key": "279",
        "district": "4",
        "label": "Sarkar Samakkulam"
    },
    {
        "key": "280",
        "district": "28",
        "label": "Sathankulam"
    },
    {
        "key": "281",
        "district": "8",
        "label": "Sathiyamangalam"
    },
    {
        "key": "282",
        "district": "38",
        "label": "Sattur"
    },
    {
        "key": "283",
        "district": "14",
        "label": "Sedapatti"
    },
    {
        "key": "284",
        "district": "15",
        "label": "Sembanarkoil"
    },
    {
        "key": "285",
        "district": "17",
        "label": "Sendamangalam"
    },
    {
        "key": "286",
        "district": "1",
        "label": "Sendurai"
    },
    {
        "key": "287",
        "district": "25",
        "label": "Sethubavachatram"
    },
    {
        "key": "288",
        "district": "24",
        "label": "Shenkottai"
    },
    {
        "key": "289",
        "district": "33",
        "label": "Sholavaram"
    },
    {
        "key": "290",
        "district": "21",
        "label": "Sholingar"
    },
    {
        "key": "291",
        "district": "13",
        "label": "Shoolagiri"
    },
    {
        "key": "292",
        "district": "23",
        "label": "Singampunari"
    },
    {
        "key": "293",
        "district": "15",
        "label": "Sirkali"
    },
    {
        "key": "294",
        "district": "23",
        "label": "Sivagangai"
    },
    {
        "key": "295",
        "district": "38",
        "label": "Sivakasi"
    },
    {
        "key": "296",
        "district": "10",
        "label": "Sriperumbudur"
    },
    {
        "key": "297",
        "district": "28",
        "label": "Srivaikundam"
    },
    {
        "key": "298",
        "district": "38",
        "label": "Srivilliputhur"
    },
    {
        "key": "299",
        "district": "2",
        "label": "St.Thomas Mount(Rural)"
    },
    {
        "key": "300",
        "district": "2",
        "label": "St.Thomas Mount(Urban)"
    },
    {
        "key": "301",
        "district": "4",
        "label": "Sulthanpet"
    },
    {
        "key": "302",
        "district": "4",
        "label": "Sulur"
    },
    {
        "key": "303",
        "district": "14",
        "label": "T.kallupatti"
    },
    {
        "key": "304",
        "district": "3",
        "label": "T.Nagar"
    },
    {
        "key": "305",
        "district": "1",
        "label": "T.Palur"
    },
    {
        "key": "306",
        "district": "14",
        "label": "T.vadipatti"
    },
    {
        "key": "307",
        "district": "24",
        "label": "Tenkasi"
    },
    {
        "key": "308",
        "district": "16",
        "label": "Thalainayar"
    },
    {
        "key": "309",
        "district": "22",
        "label": "Thalaivasal"
    },
    {
        "key": "310",
        "district": "8",
        "label": "Thalavady"
    },
    {
        "key": "311",
        "district": "13",
        "label": "Thally"
    },
    {
        "key": "312",
        "district": "34",
        "label": "Thandrampet"
    },
    {
        "key": "313",
        "district": "25",
        "label": "Thanjavur(Rural)"
    },
    {
        "key": "314",
        "district": "25",
        "label": "Thanjavur(Urban)"
    },
    {
        "key": "315",
        "district": "12",
        "label": "Thanthoni"
    },
    {
        "key": "316",
        "district": "22",
        "label": "Tharamangalam"
    },
    {
        "key": "317",
        "district": "29",
        "label": "Thathiengarpet"
    },
    {
        "key": "318",
        "district": "34",
        "label": "Thellar"
    },
    {
        "key": "319",
        "district": "27",
        "label": "Theni"
    },
    {
        "key": "320",
        "district": "28",
        "label": "Thiruchenthur"
    },
    {
        "key": "321",
        "district": "38",
        "label": "Thiruchuli"
    },
    {
        "key": "322",
        "district": "2",
        "label": "Thirukalukundram"
    },
    {
        "key": "323",
        "district": "9",
        "label": "Thirukoilur"
    },
    {
        "key": "324",
        "district": "14",
        "label": "Thirumangalam"
    },
    {
        "key": "325",
        "district": "16",
        "label": "Thirumarugal"
    },
    {
        "key": "326",
        "district": "19",
        "label": "Thirumayam"
    },
    {
        "key": "327",
        "district": "9",
        "label": "Thirunavalur"
    },
    {
        "key": "328",
        "district": "14",
        "label": "Thiruparamkundram"
    },
    {
        "key": "329",
        "district": "2",
        "label": "Thiruporur"
    },
    {
        "key": "330",
        "district": "25",
        "label": "Thiruppanandal"
    },
    {
        "key": "331",
        "district": "23",
        "label": "Thiruppatur"
    },
    {
        "key": "332",
        "district": "20",
        "label": "Thiruppullani"
    },
    {
        "key": "333",
        "district": "23",
        "label": "Thiruppuvanam"
    },
    {
        "key": "334",
        "district": "35",
        "label": "Thiruthuraipoondi"
    },
    {
        "key": "335",
        "district": "20",
        "label": "Thiruvadanai"
    },
    {
        "key": "336",
        "district": "25",
        "label": "Thiruvaiyar"
    },
    {
        "key": "337",
        "district": "33",
        "label": "Thiruvalangadu"
    },
    {
        "key": "338",
        "district": "33",
        "label": "Thiruvallur"
    },
    {
        "key": "339",
        "district": "19",
        "label": "Thiruvarankulam"
    },
    {
        "key": "340",
        "district": "35",
        "label": "Thiruvarur"
    },
    {
        "key": "341",
        "district": "11",
        "label": "Thiruvattar"
    },
    {
        "key": "342",
        "district": "37",
        "label": "Thiruvennainallur"
    },
    {
        "key": "343",
        "district": "29",
        "label": "Thiruverumbur"
    },
    {
        "key": "344",
        "district": "25",
        "label": "Thiruvidaimarudhur"
    },
    {
        "key": "345",
        "district": "25",
        "label": "Thiruvonam"
    },
    {
        "key": "346",
        "district": "1",
        "label": "Thiurmanur"
    },
    {
        "key": "347",
        "district": "9",
        "label": "Thiyagadurgam"
    },
    {
        "key": "348",
        "district": "12",
        "label": "Thogaimalai"
    },
    {
        "key": "349",
        "district": "4",
        "label": "Thondamuthur"
    },
    {
        "key": "350",
        "district": "28",
        "label": "Thoothukudi Rural"
    },
    {
        "key": "351",
        "district": "28",
        "label": "Thoothukudi Urban"
    },
    {
        "key": "352",
        "district": "7",
        "label": "Thoppampatty"
    },
    {
        "key": "353",
        "district": "29",
        "label": "Thottiyam"
    },
    {
        "key": "354",
        "district": "11",
        "label": "Thovalai"
    },
    {
        "key": "355",
        "district": "11",
        "label": "Thuckalay"
    },
    {
        "key": "356",
        "district": "8",
        "label": "Thuckanaicken Palayam"
    },
    {
        "key": "357",
        "district": "29",
        "label": "Thuraiyur"
    },
    {
        "key": "358",
        "district": "34",
        "label": "Thurinjapuram"
    },
    {
        "key": "359",
        "district": "21",
        "label": "Timiri"
    },
    {
        "key": "360",
        "district": "17",
        "label": "Tiruchengode"
    },
    {
        "key": "361",
        "district": "30",
        "label": "Tirunelveli Urban"
    },
    {
        "key": "362",
        "district": "31",
        "label": "Tirupathur"
    },
    {
        "key": "363",
        "district": "32",
        "label": "Tiruppur"
    },
    {
        "key": "364",
        "district": "33",
        "label": "Tiruttani"
    },
    {
        "key": "365",
        "district": "34",
        "label": "Tiruvannamalai"
    },
    {
        "key": "366",
        "district": "29",
        "label": "Trichy-Urban"
    },
    {
        "key": "367",
        "district": "29",
        "label": "Trichy-West"
    },
    {
        "key": "368",
        "district": "3",
        "label": "Triplicane"
    },
    {
        "key": "369",
        "district": "28",
        "label": "Udangudi"
    },
    {
        "key": "370",
        "district": "26",
        "label": "Udhagamandalam"
    },
    {
        "key": "371",
        "district": "32",
        "label": "Udumalpet"
    },
    {
        "key": "372",
        "district": "9",
        "label": "Ulundurpet"
    },
    {
        "key": "373",
        "district": "29",
        "label": "Uppiliyapuram"
    },
    {
        "key": "374",
        "district": "14",
        "label": "Usilampatti"
    },
    {
        "key": "375",
        "district": "27",
        "label": "Uthamapalayam"
    },
    {
        "key": "376",
        "district": "13",
        "label": "Uthangarai"
    },
    {
        "key": "377",
        "district": "10",
        "label": "Uthiramerur"
    },
    {
        "key": "378",
        "district": "32",
        "label": "Uthukuli"
    },
    {
        "key": "379",
        "district": "37",
        "label": "Vaanur"
    },
    {
        "key": "380",
        "district": "7",
        "label": "Vadamadurai"
    },
    {
        "key": "381",
        "district": "29",
        "label": "Vaiyampatti"
    },
    {
        "key": "382",
        "district": "35",
        "label": "Valangaiman"
    },
    {
        "key": "383",
        "district": "22",
        "label": "Valappadi"
    },
    {
        "key": "384",
        "district": "37",
        "label": "Vallam"
    },
    {
        "key": "385",
        "district": "30",
        "label": "Valliyoor"
    },
    {
        "key": "386",
        "district": "4",
        "label": "Valparai"
    },
    {
        "key": "387",
        "district": "34",
        "label": "Vandavasi"
    },
    {
        "key": "388",
        "district": "24",
        "label": "Vasudevanallur"
    },
    {
        "key": "389",
        "district": "16",
        "label": "Vedaranyam"
    },
    {
        "key": "390",
        "district": "7",
        "label": "Vedasandur"
    },
    {
        "key": "391",
        "district": "22",
        "label": "Veerapandi"
    },
    {
        "key": "392",
        "district": "32",
        "label": "Vellakovil"
    },
    {
        "key": "393",
        "district": "36",
        "label": "Vellore Rural"
    },
    {
        "key": "394",
        "district": "36",
        "label": "Vellore Urban"
    },
    {
        "key": "395",
        "district": "34",
        "label": "Vembakkam"
    },
    {
        "key": "396",
        "district": "38",
        "label": "Vembakottai"
    },
    {
        "key": "397",
        "district": "17",
        "label": "Vennandur"
    },
    {
        "key": "398",
        "district": "13",
        "label": "Veppanapalli"
    },
    {
        "key": "399",
        "district": "18",
        "label": "Veppanthattai"
    },
    {
        "key": "400",
        "district": "18",
        "label": "Veppur"
    },
    {
        "key": "401",
        "district": "37",
        "label": "Vikaravandi"
    },
    {
        "key": "402",
        "district": "28",
        "label": "Vilathikulam"
    },
    {
        "key": "403",
        "district": "33",
        "label": "Villivakkam"
    },
    {
        "key": "404",
        "district": "37",
        "label": "Viluppuram"
    },
    {
        "key": "405",
        "district": "19",
        "label": "Viralimalai"
    },
    {
        "key": "406",
        "district": "5",
        "label": "Virudhachalam"
    },
    {
        "key": "407",
        "district": "38",
        "label": "Virudhunagar"
    },
    {
        "key": "408",
        "district": "21",
        "label": "Walaja East"
    },
    {
        "key": "409",
        "district": "21",
        "label": "Walaja West"
    },
    {
        "key": "410",
        "district": "10",
        "label": "Walajabad"
    },
    {
        "key": "411",
        "district": "38",
        "label": "Watrap"
    },
    {
        "key": "412",
        "district": "34",
        "label": "West Arni"
    },
    {
        "key": "413",
        "district": "22",
        "label": "Yercaud"
    },
    { key:"414", district:"3", label:"Alandur"},
    { key:"415", district:"3", label:"Anna Nagar"},
    { key:"416", district:"3", label:"Kodambakkam"},
    { key:"417", district:"3", label:"Thiru Vi Ka Nagar"},
    { key:"418", district:"3", label:"Tondiarpet"},
    { key: "419", district:"3", label: "Teynampet"},
    { key: "420", district:"3", label: "Thiruvotriyur"},
    {
        "key":'OTH',
        "label":'Others'
    }
]

const BLOCKS_BY_DISTRICT={
    "1": [
        {
            "key": "16",
            "district": "1",
            "label": "Andimadam"
        },
        {
            "key": "26",
            "district": "1",
            "label": "Ariyalur"
        },
        {
            "key": "81",
            "district": "1",
            "label": "Jayankondam"
        },
        {
            "key": "286",
            "district": "1",
            "label": "Sendurai"
        },
        {
            "key": "305",
            "district": "1",
            "label": "T.Palur"
        },
        {
            "key": "346",
            "district": "1",
            "label": "Thiurmanur"
        }
    ],
    "2": [
        {
            "key": "1",
            "district": "2",
            "label": "Acharapakkam"
        },
        {
            "key": "51",
            "district": "2",
            "label": "Chithamur"
        },
        {
            "key": "114",
            "district": "2",
            "label": "Kattankolathur"
        },
        {
            "key": "153",
            "district": "2",
            "label": "Lathur"
        },
        {
            "key": "162",
            "district": "2",
            "label": "Madurantagam"
        },
        {
            "key": "299",
            "district": "2",
            "label": "St.Thomas Mount(Rural)"
        },
        {
            "key": "300",
            "district": "2",
            "label": "St.Thomas Mount(Urban)"
        },
        {
            "key": "322",
            "district": "2",
            "label": "Thirukalukundram"
        },
        {
            "key": "329",
            "district": "2",
            "label": "Thiruporur"
        }
    ],
    "3": [
        {
            "key": "2",
            "district": "3",
            "label": "Adyar"
        },
        {
            "key": "62",
            "district": "3",
            "label": "Egmore"
        },
        {
            "key": "69",
            "district": "3",
            "label": "George town"
        },
        {
            "key": "195",
            "district": "3",
            "label": "Mylapore"
        },
        {
            "key": "237",
            "district": "3",
            "label": "Perambur"
        },
        {
            "key": "240",
            "district": "3",
            "label": "Periamedu"
        },
        {
            "key": "259",
            "district": "3",
            "label": "Purasaiwalkkam"
        },
        {
            "key": "270",
            "district": "3",
            "label": "Royapuram"
        },
        {
            "key": "304",
            "district": "3",
            "label": "T.Nagar"
        },
        {
            "key": "368",
            "district": "3",
            "label": "Triplicane"
        },
        { key:"414", district:"3", label:"Alandur"},
        { key:"415", district:"3", label:"Anna Nagar"},
        { key:"416", district:"3", label:"Kodambakkam"},
        { key:"417", district:"3", label:"Thiru Vi Ka Nagar"},
        { key:"418", district:"3", label:"Tondiarpet"},
        { key: "419", district:"3", label: "Teynampet"},
        { key: "420", district:"3", label: "Thiruvotriyur"}
    ],
    "4": [
        {
            "key": "13",
            "district": "4",
            "label": "Anaimalai"
        },
        {
            "key": "19",
            "district": "4",
            "label": "Annur"
        },
        {
            "key": "52",
            "district": "4",
            "label": "Coimbatore city"
        },
        {
            "key": "107",
            "district": "4",
            "label": "Karamadai"
        },
        {
            "key": "126",
            "district": "4",
            "label": "Kinathukkadavu"
        },
        {
            "key": "156",
            "district": "4",
            "label": "Madukkarai"
        },
        {
            "key": "242",
            "district": "4",
            "label": "Periyanaickan Palayam"
        },
        {
            "key": "245",
            "district": "4",
            "label": "Perur"
        },
        {
            "key": "247",
            "district": "4",
            "label": "Pollachi north"
        },
        {
            "key": "248",
            "district": "4",
            "label": "Pollachi south"
        },
        {
            "key": "279",
            "district": "4",
            "label": "Sarkar Samakkulam"
        },
        {
            "key": "301",
            "district": "4",
            "label": "Sulthanpet"
        },
        {
            "key": "302",
            "district": "4",
            "label": "Sulur"
        },
        {
            "key": "349",
            "district": "4",
            "label": "Thondamuthur"
        },
        {
            "key": "386",
            "district": "4",
            "label": "Valparai"
        }
    ],
    "5": [
        {
            "key": "17",
            "district": "5",
            "label": "Annagrammam"
        },
        {
            "key": "39",
            "district": "5",
            "label": "Bhuvanagiri"
        },
        {
            "key": "54",
            "district": "5",
            "label": "Cuddalore"
        },
        {
            "key": "98",
            "district": "5",
            "label": "Kammapuram"
        },
        {
            "key": "115",
            "district": "5",
            "label": "Kattumannarkoil"
        },
        {
            "key": "120",
            "district": "5",
            "label": "Keerapalayam"
        },
        {
            "key": "143",
            "district": "5",
            "label": "Kumaratchi"
        },
        {
            "key": "148",
            "district": "5",
            "label": "Kurinjipadi"
        },
        {
            "key": "170",
            "district": "5",
            "label": "Mangalore"
        },
        {
            "key": "199",
            "district": "5",
            "label": "Nallur"
        },
        {
            "key": "211",
            "district": "5",
            "label": "Neyveli"
        },
        {
            "key": "227",
            "district": "5",
            "label": "Panruti"
        },
        {
            "key": "233",
            "district": "5",
            "label": "Parangipettai"
        },
        {
            "key": "406",
            "district": "5",
            "label": "Virudhachalam"
        }
    ],
    "6": [
        {
            "key": "58",
            "district": "6",
            "label": "Dharmapuri"
        },
        {
            "key": "77",
            "district": "6",
            "label": "Harur"
        },
        {
            "key": "109",
            "district": "6",
            "label": "Karimangalam"
        },
        {
            "key": "187",
            "district": "6",
            "label": "Morappur"
        },
        {
            "key": "198",
            "district": "6",
            "label": "Nallampalli"
        },
        {
            "key": "218",
            "district": "6",
            "label": "Palacode"
        },
        {
            "key": "230",
            "district": "6",
            "label": "Pappireddipatti"
        },
        {
            "key": "235",
            "district": "6",
            "label": "Pennagaram"
        }
    ],
    "7": [
        {
            "key": "29",
            "district": "7",
            "label": "Athoor"
        },
        {
            "key": "36",
            "district": "7",
            "label": "Batlagundu"
        },
        {
            "key": "59",
            "district": "7",
            "label": "Dindigul - Rural"
        },
        {
            "key": "60",
            "district": "7",
            "label": "Dindigul -Urban"
        },
        {
            "key": "75",
            "district": "7",
            "label": "Gujiliamparai"
        },
        {
            "key": "127",
            "district": "7",
            "label": "Kodaikanal"
        },
        {
            "key": "207",
            "district": "7",
            "label": "Natham"
        },
        {
            "key": "212",
            "district": "7",
            "label": "Nilakottai"
        },
        {
            "key": "213",
            "district": "7",
            "label": "Oddanchathram"
        },
        {
            "key": "219",
            "district": "7",
            "label": "Palani - Rural"
        },
        {
            "key": "220",
            "district": "7",
            "label": "Palani - Urban"
        },
        {
            "key": "268",
            "district": "7",
            "label": "Reddiyarchatram"
        },
        {
            "key": "275",
            "district": "7",
            "label": "Sanarpatty"
        },
        {
            "key": "352",
            "district": "7",
            "label": "Thoppampatty"
        },
        {
            "key": "380",
            "district": "7",
            "label": "Vadamadurai"
        },
        {
            "key": "390",
            "district": "7",
            "label": "Vedasandur"
        }
    ],
    "8": [
        {
            "key": "10",
            "district": "8",
            "label": "Ammapettai-Erode"
        },
        {
            "key": "20",
            "district": "8",
            "label": "Anthiyur"
        },
        {
            "key": "37",
            "district": "8",
            "label": "Bhavani"
        },
        {
            "key": "38",
            "district": "8",
            "label": "Bhavani sagar"
        },
        {
            "key": "45",
            "district": "8",
            "label": "Chennimalai"
        },
        {
            "key": "65",
            "district": "8",
            "label": "Erode"
        },
        {
            "key": "71",
            "district": "8",
            "label": "Gobi"
        },
        {
            "key": "128",
            "district": "8",
            "label": "Kodumudi"
        },
        {
            "key": "185",
            "district": "8",
            "label": "Modakurichi"
        },
        {
            "key": "202",
            "district": "8",
            "label": "Nambiyur"
        },
        {
            "key": "244",
            "district": "8",
            "label": "Perundurai"
        },
        {
            "key": "281",
            "district": "8",
            "label": "Sathiyamangalam"
        },
        {
            "key": "310",
            "district": "8",
            "label": "Thalavady"
        },
        {
            "key": "356",
            "district": "8",
            "label": "Thuckanaicken Palayam"
        }
    ],
    "9": [
        {
            "key": "50",
            "district": "9",
            "label": "Chinnasalem"
        },
        {
            "key": "95",
            "district": "9",
            "label": "Kallakurichi"
        },
        {
            "key": "269",
            "district": "9",
            "label": "Rishivanthiyam"
        },
        {
            "key": "277",
            "district": "9",
            "label": "Sankarapuram"
        },
        {
            "key": "323",
            "district": "9",
            "label": "Thirukoilur"
        },
        {
            "key": "327",
            "district": "9",
            "label": "Thirunavalur"
        },
        {
            "key": "347",
            "district": "9",
            "label": "Thiyagadurgam"
        },
        {
            "key": "372",
            "district": "9",
            "label": "Ulundurpet"
        }
    ],
    "10": [
        {
            "key": "101",
            "district": "10",
            "label": "Kancheepuram"
        },
        {
            "key": "146",
            "district": "10",
            "label": "Kundrathur"
        },
        {
            "key": "296",
            "district": "10",
            "label": "Sriperumbudur"
        },
        {
            "key": "377",
            "district": "10",
            "label": "Uthiramerur"
        },
        {
            "key": "410",
            "district": "10",
            "label": "Walajabad"
        }
    ],
    "11": [
        {
            "key": "3",
            "district": "11",
            "label": "Agastheeswaram"
        },
        {
            "key": "124",
            "district": "11",
            "label": "Killiyoor"
        },
        {
            "key": "149",
            "district": "11",
            "label": "Kurunthancode"
        },
        {
            "key": "182",
            "district": "11",
            "label": "Melpuram"
        },
        {
            "key": "191",
            "district": "11",
            "label": "Munchirai"
        },
        {
            "key": "263",
            "district": "11",
            "label": "Rajakkamangalam"
        },
        {
            "key": "341",
            "district": "11",
            "label": "Thiruvattar"
        },
        {
            "key": "354",
            "district": "11",
            "label": "Thovalai"
        },
        {
            "key": "355",
            "district": "11",
            "label": "Thuckalay"
        }
    ],
    "12": [
        {
            "key": "23",
            "district": "12",
            "label": "Aravakurichi"
        },
        {
            "key": "83",
            "district": "12",
            "label": "K.Paramathi"
        },
        {
            "key": "88",
            "district": "12",
            "label": "Kadavoor"
        },
        {
            "key": "112",
            "district": "12",
            "label": "Karur"
        },
        {
            "key": "140",
            "district": "12",
            "label": "Krishnarayapuram"
        },
        {
            "key": "142",
            "district": "12",
            "label": "Kulithalai"
        },
        {
            "key": "315",
            "district": "12",
            "label": "Thanthoni"
        },
        {
            "key": "348",
            "district": "12",
            "label": "Thogaimalai"
        }
    ],
    "13": [
        {
            "key": "35",
            "district": "13",
            "label": "Bargur"
        },
        {
            "key": "78",
            "district": "13",
            "label": "Hosur"
        },
        {
            "key": "117",
            "district": "13",
            "label": "Kaveripatinam"
        },
        {
            "key": "122",
            "district": "13",
            "label": "Kelamangalam"
        },
        {
            "key": "139",
            "district": "13",
            "label": "Krishnagiri"
        },
        {
            "key": "177",
            "district": "13",
            "label": "Mathur"
        },
        {
            "key": "291",
            "district": "13",
            "label": "Shoolagiri"
        },
        {
            "key": "311",
            "district": "13",
            "label": "Thally"
        },
        {
            "key": "376",
            "district": "13",
            "label": "Uthangarai"
        },
        {
            "key": "398",
            "district": "13",
            "label": "Veppanapalli"
        }
    ],
    "14": [
        {
            "key": "4",
            "district": "14",
            "label": "Alanganallur"
        },
        {
            "key": "43",
            "district": "14",
            "label": "Chellampatti"
        },
        {
            "key": "97",
            "district": "14",
            "label": "Kallikudi"
        },
        {
            "key": "136",
            "district": "14",
            "label": "Kottampatti"
        },
        {
            "key": "158",
            "district": "14",
            "label": "Madurai East"
        },
        {
            "key": "159",
            "district": "14",
            "label": "Madurai North"
        },
        {
            "key": "160",
            "district": "14",
            "label": "Madurai South"
        },
        {
            "key": "161",
            "district": "14",
            "label": "Madurai West"
        },
        {
            "key": "183",
            "district": "14",
            "label": "Melur"
        },
        {
            "key": "283",
            "district": "14",
            "label": "Sedapatti"
        },
        {
            "key": "303",
            "district": "14",
            "label": "T.kallupatti"
        },
        {
            "key": "306",
            "district": "14",
            "label": "T.vadipatti"
        },
        {
            "key": "324",
            "district": "14",
            "label": "Thirumangalam"
        },
        {
            "key": "328",
            "district": "14",
            "label": "Thiruparamkundram"
        },
        {
            "key": "374",
            "district": "14",
            "label": "Usilampatti"
        }
    ],
    "15": [
        {
            "key": "131",
            "district": "15",
            "label": "kollidam"
        },
        {
            "key": "151",
            "district": "15",
            "label": "Kuthalam"
        },
        {
            "key": "178",
            "district": "15",
            "label": "Mayiladudurai"
        },
        {
            "key": "284",
            "district": "15",
            "label": "Sembanarkoil"
        },
        {
            "key": "293",
            "district": "15",
            "label": "Sirkali"
        }
    ],
    "16": [
        {
            "key": "121",
            "district": "16",
            "label": "Keezhaiyur"
        },
        {
            "key": "123",
            "district": "16",
            "label": "Kelvelur"
        },
        {
            "key": "196",
            "district": "16",
            "label": "Nagapattinam"
        },
        {
            "key": "308",
            "district": "16",
            "label": "Thalainayar"
        },
        {
            "key": "325",
            "district": "16",
            "label": "Thirumarugal"
        },
        {
            "key": "389",
            "district": "16",
            "label": "Vedaranyam"
        }
    ],
    "17": [
        {
            "key": "63",
            "district": "17",
            "label": "Elachipalayam"
        },
        {
            "key": "66",
            "district": "17",
            "label": "Erumapatti"
        },
        {
            "key": "85",
            "district": "17",
            "label": "Kabilarmalai"
        },
        {
            "key": "132",
            "district": "17",
            "label": "Kollihills"
        },
        {
            "key": "165",
            "district": "17",
            "label": "Mallasamudram"
        },
        {
            "key": "186",
            "district": "17",
            "label": "Mohanur"
        },
        {
            "key": "200",
            "district": "17",
            "label": "Namagiripet"
        },
        {
            "key": "201",
            "district": "17",
            "label": "Namakkal"
        },
        {
            "key": "224",
            "district": "17",
            "label": "Pallipalayam"
        },
        {
            "key": "232",
            "district": "17",
            "label": "Paramathi"
        },
        {
            "key": "254",
            "district": "17",
            "label": "Puduchatram"
        },
        {
            "key": "267",
            "district": "17",
            "label": "Rasipuram"
        },
        {
            "key": "285",
            "district": "17",
            "label": "Sendamangalam"
        },
        {
            "key": "360",
            "district": "17",
            "label": "Tiruchengode"
        },
        {
            "key": "397",
            "district": "17",
            "label": "Vennandur"
        }
    ],
    "18": [
        {
            "key": "7",
            "district": "18",
            "label": "Alathur"
        },
        {
            "key": "236",
            "district": "18",
            "label": "Perambalur"
        },
        {
            "key": "399",
            "district": "18",
            "label": "Veppanthattai"
        },
        {
            "key": "400",
            "district": "18",
            "label": "Veppur"
        }
    ],
    "19": [
        {
            "key": "18",
            "district": "19",
            "label": "Annavasal"
        },
        {
            "key": "22",
            "district": "19",
            "label": "Aranthangi"
        },
        {
            "key": "25",
            "district": "19",
            "label": "Arimalam"
        },
        {
            "key": "33",
            "district": "19",
            "label": "Avudaiyarkoil"
        },
        {
            "key": "67",
            "district": "19",
            "label": "Gandarvakkottai"
        },
        {
            "key": "108",
            "district": "19",
            "label": "Karambakkudi"
        },
        {
            "key": "147",
            "district": "19",
            "label": "Kunnandarkoil"
        },
        {
            "key": "167",
            "district": "19",
            "label": "Manamelkudi"
        },
        {
            "key": "251",
            "district": "19",
            "label": "Ponnamaravathy"
        },
        {
            "key": "255",
            "district": "19",
            "label": "Pudukkottai"
        },
        {
            "key": "326",
            "district": "19",
            "label": "Thirumayam"
        },
        {
            "key": "339",
            "district": "19",
            "label": "Thiruvarankulam"
        },
        {
            "key": "405",
            "district": "19",
            "label": "Viralimalai"
        }
    ],
    "20": [
        {
            "key": "41",
            "district": "20",
            "label": "Bogalur"
        },
        {
            "key": "86",
            "district": "20",
            "label": "Kadaladi"
        },
        {
            "key": "99",
            "district": "20",
            "label": "Kamuthi"
        },
        {
            "key": "169",
            "district": "20",
            "label": "Mandapam"
        },
        {
            "key": "188",
            "district": "20",
            "label": "Mudukulathur"
        },
        {
            "key": "197",
            "district": "20",
            "label": "Nainarkoil"
        },
        {
            "key": "231",
            "district": "20",
            "label": "Paramakudi"
        },
        {
            "key": "265",
            "district": "20",
            "label": "Rajasingamangalam"
        },
        {
            "key": "266",
            "district": "20",
            "label": "Ramanathapuram"
        },
        {
            "key": "332",
            "district": "20",
            "label": "Thiruppullani"
        },
        {
            "key": "335",
            "district": "20",
            "label": "Thiruvadanai"
        }
    ],
    "21": [
        {
            "key": "21",
            "district": "21",
            "label": "Arakkonam"
        },
        {
            "key": "24",
            "district": "21",
            "label": "Arcot"
        },
        {
            "key": "116",
            "district": "21",
            "label": "Kaveripakkam"
        },
        {
            "key": "210",
            "district": "21",
            "label": "Nemili"
        },
        {
            "key": "290",
            "district": "21",
            "label": "Sholingar"
        },
        {
            "key": "359",
            "district": "21",
            "label": "Timiri"
        },
        {
            "key": "408",
            "district": "21",
            "label": "Walaja East"
        },
        {
            "key": "409",
            "district": "21",
            "label": "Walaja West"
        }
    ],
    "22": [
        {
            "key": "30",
            "district": "22",
            "label": "Attur"
        },
        {
            "key": "34",
            "district": "22",
            "label": "Ayothiyapattinam"
        },
        {
            "key": "61",
            "district": "22",
            "label": "Edappadi"
        },
        {
            "key": "68",
            "district": "22",
            "label": "Gangavalli"
        },
        {
            "key": "90",
            "district": "22",
            "label": "Kadayampatti"
        },
        {
            "key": "129",
            "district": "22",
            "label": "Kolathur"
        },
        {
            "key": "133",
            "district": "22",
            "label": "Konganapuram"
        },
        {
            "key": "163",
            "district": "22",
            "label": "Magudanchavadi"
        },
        {
            "key": "179",
            "district": "22",
            "label": "Mecheri"
        },
        {
            "key": "203",
            "district": "22",
            "label": "Nangavalli"
        },
        {
            "key": "215",
            "district": "22",
            "label": "Omalur"
        },
        {
            "key": "226",
            "district": "22",
            "label": "Panamarathupatti"
        },
        {
            "key": "246",
            "district": "22",
            "label": "Pethanaickenpalayam"
        },
        {
            "key": "273",
            "district": "22",
            "label": "Salem-Rural"
        },
        {
            "key": "274",
            "district": "22",
            "label": "Salem-Urban"
        },
        {
            "key": "278",
            "district": "22",
            "label": "Sankari"
        },
        {
            "key": "309",
            "district": "22",
            "label": "Thalaivasal"
        },
        {
            "key": "316",
            "district": "22",
            "label": "Tharamangalam"
        },
        {
            "key": "383",
            "district": "22",
            "label": "Valappadi"
        },
        {
            "key": "391",
            "district": "22",
            "label": "Veerapandi"
        },
        {
            "key": "413",
            "district": "22",
            "label": "Yercaud"
        }
    ],
    "23": [
        {
            "key": "56",
            "district": "23",
            "label": "Devakottai"
        },
        {
            "key": "79",
            "district": "23",
            "label": "Ilayangudi"
        },
        {
            "key": "94",
            "district": "23",
            "label": "Kalayarkoil"
        },
        {
            "key": "96",
            "district": "23",
            "label": "Kallal"
        },
        {
            "key": "106",
            "district": "23",
            "label": "Kannangudi"
        },
        {
            "key": "166",
            "district": "23",
            "label": "Manamadurai"
        },
        {
            "key": "271",
            "district": "23",
            "label": "S.Pudur"
        },
        {
            "key": "272",
            "district": "23",
            "label": "Sakkottai"
        },
        {
            "key": "292",
            "district": "23",
            "label": "Singampunari"
        },
        {
            "key": "294",
            "district": "23",
            "label": "Sivagangai"
        },
        {
            "key": "331",
            "district": "23",
            "label": "Thiruppatur"
        },
        {
            "key": "333",
            "district": "23",
            "label": "Thiruppuvanam"
        }
    ],
    "24": [
        {
            "key": "6",
            "district": "24",
            "label": "Alangulam"
        },
        {
            "key": "89",
            "district": "24",
            "label": "Kadayam"
        },
        {
            "key": "91",
            "district": "24",
            "label": "Kadayanallur"
        },
        {
            "key": "119",
            "district": "24",
            "label": "Keelapavoor"
        },
        {
            "key": "150",
            "district": "24",
            "label": "Kuruvikulam"
        },
        {
            "key": "180",
            "district": "24",
            "label": "Melaneelithanallur"
        },
        {
            "key": "276",
            "district": "24",
            "label": "Sankarankovil"
        },
        {
            "key": "288",
            "district": "24",
            "label": "Shenkottai"
        },
        {
            "key": "307",
            "district": "24",
            "label": "Tenkasi"
        },
        {
            "key": "388",
            "district": "24",
            "label": "Vasudevanallur"
        }
    ],
    "25": [
        {
            "key": "11",
            "district": "25",
            "label": "Ammapettai-TNJ"
        },
        {
            "key": "42",
            "district": "25",
            "label": "Budalur"
        },
        {
            "key": "144",
            "district": "25",
            "label": "Kumbakonam"
        },
        {
            "key": "157",
            "district": "25",
            "label": "Madukkur"
        },
        {
            "key": "216",
            "district": "25",
            "label": "Orathanadu"
        },
        {
            "key": "228",
            "district": "25",
            "label": "Papanasam"
        },
        {
            "key": "234",
            "district": "25",
            "label": "Pattukkottai"
        },
        {
            "key": "239",
            "district": "25",
            "label": "Peravurani"
        },
        {
            "key": "287",
            "district": "25",
            "label": "Sethubavachatram"
        },
        {
            "key": "313",
            "district": "25",
            "label": "Thanjavur(Rural)"
        },
        {
            "key": "314",
            "district": "25",
            "label": "Thanjavur(Urban)"
        },
        {
            "key": "330",
            "district": "25",
            "label": "Thiruppanandal"
        },
        {
            "key": "336",
            "district": "25",
            "label": "Thiruvaiyar"
        },
        {
            "key": "344",
            "district": "25",
            "label": "Thiruvidaimarudhur"
        },
        {
            "key": "345",
            "district": "25",
            "label": "Thiruvonam"
        }
    ],
    "26": [
        {
            "key": "53",
            "district": "26",
            "label": "Coonoor"
        },
        {
            "key": "72",
            "district": "26",
            "label": "Gudalur"
        },
        {
            "key": "135",
            "district": "26",
            "label": "Kotagiri"
        },
        {
            "key": "370",
            "district": "26",
            "label": "Udhagamandalam"
        }
    ],
    "27": [
        {
            "key": "31",
            "district": "27",
            "label": "Aundipatty"
        },
        {
            "key": "40",
            "district": "27",
            "label": "Bodinayakanur"
        },
        {
            "key": "49",
            "district": "27",
            "label": "Chinnamanur"
        },
        {
            "key": "55",
            "district": "27",
            "label": "Cumbum"
        },
        {
            "key": "194",
            "district": "27",
            "label": "Myladumparai"
        },
        {
            "key": "241",
            "district": "27",
            "label": "Periyakulam"
        },
        {
            "key": "319",
            "district": "27",
            "label": "Theni"
        },
        {
            "key": "375",
            "district": "27",
            "label": "Uthamapalayam"
        }
    ],
    "28": [
        {
            "key": "8",
            "district": "28",
            "label": "Alwarthirunagar"
        },
        {
            "key": "111",
            "district": "28",
            "label": "Karunkulam"
        },
        {
            "key": "118",
            "district": "28",
            "label": "Kayathar"
        },
        {
            "key": "138",
            "district": "28",
            "label": "Kovilpatti"
        },
        {
            "key": "217",
            "district": "28",
            "label": "Ottapidaram"
        },
        {
            "key": "257",
            "district": "28",
            "label": "Pudur"
        },
        {
            "key": "280",
            "district": "28",
            "label": "Sathankulam"
        },
        {
            "key": "297",
            "district": "28",
            "label": "Srivaikundam"
        },
        {
            "key": "320",
            "district": "28",
            "label": "Thiruchenthur"
        },
        {
            "key": "350",
            "district": "28",
            "label": "Thoothukudi Rural"
        },
        {
            "key": "351",
            "district": "28",
            "label": "Thoothukudi Urban"
        },
        {
            "key": "369",
            "district": "28",
            "label": "Udangudi"
        },
        {
            "key": "402",
            "district": "28",
            "label": "Vilathikulam"
        }
    ],
    "29": [
        {
            "key": "15",
            "district": "29",
            "label": "Andhanallur"
        },
        {
            "key": "152",
            "district": "29",
            "label": "Lalgudi"
        },
        {
            "key": "168",
            "district": "29",
            "label": "Manapparai"
        },
        {
            "key": "171",
            "district": "29",
            "label": "Manikandam"
        },
        {
            "key": "172",
            "district": "29",
            "label": "Mannachanallur"
        },
        {
            "key": "176",
            "district": "29",
            "label": "Marungapuri"
        },
        {
            "key": "192",
            "district": "29",
            "label": "Musiri"
        },
        {
            "key": "258",
            "district": "29",
            "label": "Pullambadi"
        },
        {
            "key": "317",
            "district": "29",
            "label": "Thathiengarpet"
        },
        {
            "key": "343",
            "district": "29",
            "label": "Thiruverumbur"
        },
        {
            "key": "353",
            "district": "29",
            "label": "Thottiyam"
        },
        {
            "key": "357",
            "district": "29",
            "label": "Thuraiyur"
        },
        {
            "key": "366",
            "district": "29",
            "label": "Trichy-Urban"
        },
        {
            "key": "367",
            "district": "29",
            "label": "Trichy-West"
        },
        {
            "key": "373",
            "district": "29",
            "label": "Uppiliyapuram"
        },
        {
            "key": "381",
            "district": "29",
            "label": "Vaiyampatti"
        }
    ],
    "30": [
        {
            "key": "9",
            "district": "30",
            "label": "Ambasamudram"
        },
        {
            "key": "46",
            "district": "30",
            "label": "Cheranmahadevi"
        },
        {
            "key": "92",
            "district": "30",
            "label": "Kalakad"
        },
        {
            "key": "174",
            "district": "30",
            "label": "Manur"
        },
        {
            "key": "204",
            "district": "30",
            "label": "Nanguneri"
        },
        {
            "key": "221",
            "district": "30",
            "label": "Palay-Rural"
        },
        {
            "key": "222",
            "district": "30",
            "label": "Palay-Urban"
        },
        {
            "key": "229",
            "district": "30",
            "label": "Pappakudi"
        },
        {
            "key": "262",
            "district": "30",
            "label": "Radhapuram"
        },
        {
            "key": "361",
            "district": "30",
            "label": "Tirunelveli Urban"
        },
        {
            "key": "385",
            "district": "30",
            "label": "Valliyoor"
        }
    ],
    "31": [
        {
            "key": "5",
            "district": "31",
            "label": "Alangayam"
        },
        {
            "key": "82",
            "district": "31",
            "label": "Jolarpet"
        },
        {
            "key": "103",
            "district": "31",
            "label": "Kandhili"
        },
        {
            "key": "155",
            "district": "31",
            "label": "Madhanur"
        },
        {
            "key": "208",
            "district": "31",
            "label": "Natrampalli"
        },
        {
            "key": "362",
            "district": "31",
            "label": "Tirupathur"
        }
    ],
    "32": [
        {
            "key": "32",
            "district": "32",
            "label": "Avinashi"
        },
        {
            "key": "57",
            "district": "32",
            "label": "Dharapuram"
        },
        {
            "key": "73",
            "district": "32",
            "label": "Gudimangalam"
        },
        {
            "key": "104",
            "district": "32",
            "label": "Kangayam"
        },
        {
            "key": "145",
            "district": "32",
            "label": "Kundadam"
        },
        {
            "key": "154",
            "district": "32",
            "label": "Madathukulam"
        },
        {
            "key": "190",
            "district": "32",
            "label": "Mulanur"
        },
        {
            "key": "223",
            "district": "32",
            "label": "Palladam"
        },
        {
            "key": "250",
            "district": "32",
            "label": "Pongalur"
        },
        {
            "key": "363",
            "district": "32",
            "label": "Tiruppur"
        },
        {
            "key": "371",
            "district": "32",
            "label": "Udumalpet"
        },
        {
            "key": "378",
            "district": "32",
            "label": "Uthukuli"
        },
        {
            "key": "392",
            "district": "32",
            "label": "Vellakovil"
        }
    ],
    "33": [
        {
            "key": "64",
            "district": "33",
            "label": "Ellapuram"
        },
        {
            "key": "76",
            "district": "33",
            "label": "Gummidipoondi"
        },
        {
            "key": "87",
            "district": "33",
            "label": "Kadambathur"
        },
        {
            "key": "184",
            "district": "33",
            "label": "Minjur"
        },
        {
            "key": "225",
            "district": "33",
            "label": "Pallipat"
        },
        {
            "key": "252",
            "district": "33",
            "label": "Poonamallee"
        },
        {
            "key": "253",
            "district": "33",
            "label": "Poondi"
        },
        {
            "key": "260",
            "district": "33",
            "label": "Puzhal"
        },
        {
            "key": "261",
            "district": "33",
            "label": "R.K.Pet"
        },
        {
            "key": "289",
            "district": "33",
            "label": "Sholavaram"
        },
        {
            "key": "337",
            "district": "33",
            "label": "Thiruvalangadu"
        },
        {
            "key": "338",
            "district": "33",
            "label": "Thiruvallur"
        },
        {
            "key": "364",
            "district": "33",
            "label": "Tiruttani"
        },
        {
            "key": "403",
            "district": "33",
            "label": "Villivakkam"
        }
    ],
    "34": [
        {
            "key": "14",
            "district": "34",
            "label": "Anakkavur"
        },
        {
            "key": "27",
            "district": "34",
            "label": "Arni"
        },
        {
            "key": "44",
            "district": "34",
            "label": "Chengam"
        },
        {
            "key": "47",
            "district": "34",
            "label": "Chetpet"
        },
        {
            "key": "48",
            "district": "34",
            "label": "Cheyyar"
        },
        {
            "key": "80",
            "district": "34",
            "label": "Jawadhu Hills"
        },
        {
            "key": "93",
            "district": "34",
            "label": "Kalasapakkam"
        },
        {
            "key": "125",
            "district": "34",
            "label": "Kilpennathur"
        },
        {
            "key": "238",
            "district": "34",
            "label": "Peranamallur"
        },
        {
            "key": "249",
            "district": "34",
            "label": "Polur"
        },
        {
            "key": "256",
            "district": "34",
            "label": "Pudupalayam"
        },
        {
            "key": "312",
            "district": "34",
            "label": "Thandrampet"
        },
        {
            "key": "318",
            "district": "34",
            "label": "Thellar"
        },
        {
            "key": "358",
            "district": "34",
            "label": "Thurinjapuram"
        },
        {
            "key": "365",
            "district": "34",
            "label": "Tiruvannamalai"
        },
        {
            "key": "387",
            "district": "34",
            "label": "Vandavasi"
        },
        {
            "key": "395",
            "district": "34",
            "label": "Vembakkam"
        },
        {
            "key": "412",
            "district": "34",
            "label": "West Arni"
        }
    ],
    "35": [
        {
            "key": "134",
            "district": "35",
            "label": "Koradachery"
        },
        {
            "key": "137",
            "district": "35",
            "label": "Kottur"
        },
        {
            "key": "141",
            "district": "35",
            "label": "Kudavasal"
        },
        {
            "key": "173",
            "district": "35",
            "label": "Mannargudi"
        },
        {
            "key": "193",
            "district": "35",
            "label": "Muthupettai"
        },
        {
            "key": "205",
            "district": "35",
            "label": "Nannilam"
        },
        {
            "key": "209",
            "district": "35",
            "label": "Needamangalam"
        },
        {
            "key": "334",
            "district": "35",
            "label": "Thiruthuraipoondi"
        },
        {
            "key": "340",
            "district": "35",
            "label": "Thiruvarur"
        },
        {
            "key": "382",
            "district": "35",
            "label": "Valangaiman"
        }
    ],
    "36": [
        {
            "key": "12",
            "district": "36",
            "label": "Anaicut"
        },
        {
            "key": "74",
            "district": "36",
            "label": "Gudiyatham"
        },
        {
            "key": "84",
            "district": "36",
            "label": "K.V.Kuppam"
        },
        {
            "key": "105",
            "district": "36",
            "label": "Kaniyambadi"
        },
        {
            "key": "113",
            "district": "36",
            "label": "Katpadi"
        },
        {
            "key": "243",
            "district": "36",
            "label": "Pernambut"
        },
        {
            "key": "393",
            "district": "36",
            "label": "Vellore Rural"
        },
        {
            "key": "394",
            "district": "36",
            "label": "Vellore Urban"
        }
    ],
    "37": [
        {
            "key": "70",
            "district": "37",
            "label": "Gingee"
        },
        {
            "key": "100",
            "district": "37",
            "label": "Kanai"
        },
        {
            "key": "102",
            "district": "37",
            "label": "Kandamangalam"
        },
        {
            "key": "130",
            "district": "37",
            "label": "Koliyanur"
        },
        {
            "key": "164",
            "district": "37",
            "label": "Mailam"
        },
        {
            "key": "175",
            "district": "37",
            "label": "Marakkanam"
        },
        {
            "key": "181",
            "district": "37",
            "label": "Melmalayanur"
        },
        {
            "key": "189",
            "district": "37",
            "label": "Mugaiyur"
        },
        {
            "key": "214",
            "district": "37",
            "label": "Olakkur"
        },
        {
            "key": "342",
            "district": "37",
            "label": "Thiruvennainallur"
        },
        {
            "key": "379",
            "district": "37",
            "label": "Vaanur"
        },
        {
            "key": "384",
            "district": "37",
            "label": "Vallam"
        },
        {
            "key": "401",
            "district": "37",
            "label": "Vikaravandi"
        },
        {
            "key": "404",
            "district": "37",
            "label": "Viluppuram"
        }
    ],
    "38": [
        {
            "key": "28",
            "district": "38",
            "label": "Aruppukottai"
        },
        {
            "key": "110",
            "district": "38",
            "label": "Kariyapatti"
        },
        {
            "key": "206",
            "district": "38",
            "label": "Narikudi"
        },
        {
            "key": "264",
            "district": "38",
            "label": "Rajapalayam"
        },
        {
            "key": "282",
            "district": "38",
            "label": "Sattur"
        },
        {
            "key": "295",
            "district": "38",
            "label": "Sivakasi"
        },
        {
            "key": "298",
            "district": "38",
            "label": "Srivilliputhur"
        },
        {
            "key": "321",
            "district": "38",
            "label": "Thiruchuli"
        },
        {
            "key": "396",
            "district": "38",
            "label": "Vembakottai"
        },
        {
            "key": "407",
            "district": "38",
            "label": "Virudhunagar"
        },
        {
            "key": "411",
            "district": "38",
            "label": "Watrap"
        }
    ]
}

module.exports = {
    ALL_SCHOOL,
    SCHOOL_BY_DISTRICT,
    SCHOOL_BY_BLOCK,
    BLOCKS,
    BLOCKS_BY_DISTRICT
}